export const citiesAr = 
[
    {
        "country": "China",
        "cities": [
            {
                "value": 444,
                "label": "Guangzhou",
                "label_ar": "قوانغتشو",
                "label_fr": "Guangzhou"
            },
            {
                "value": 445,
                "label": "Fuzhou",
                "label_ar": "فوتشو",
                "label_fr": "Fuzhou"
            },
            {
                "value": 446,
                "label": "Beijing",
                "label_ar": "بكين",
                "label_fr": "Pékin"
            },
            {
                "value": 447,
                "label": "Baotou",
                "label_ar": "باوتو",
                "label_fr": "Baotou"
            },
            {
                "value": 448,
                "label": "Hohhot",
                "label_ar": "هوهوت",
                "label_fr": "Hohhot"
            },
            {
                "value": 449,
                "label": "Guiyang",
                "label_ar": "قوييانغ",
                "label_fr": "Guiyang"
            },
            {
                "value": 450,
                "label": "Yinchuan",
                "label_ar": "ينتشوان",
                "label_fr": "Yinchuan"
            },
            {
                "value": 451,
                "label": "Nanjing",
                "label_ar": "نانجينغ",
                "label_fr": "Nanjing"
            },
            {
                "value": 452,
                "label": "Changzhou",
                "label_ar": "تشانغتشو",
                "label_fr": "Changzhou"
            },
            {
                "value": 453,
                "label": "Chuzhou",
                "label_ar": "تشوتشو",
                "label_fr": "Chuzhou"
            },
            {
                "value": 454,
                "label": "Hefei",
                "label_ar": "خفى",
                "label_fr": "Hefei"
            },
            {
                "value": 455,
                "label": "Jinan",
                "label_ar": "جينان",
                "label_fr": "Jinan"
            },
            {
                "value": 456,
                "label": "Qingdao",
                "label_ar": "تشينغداو",
                "label_fr": "Qingdao"
            },
            {
                "value": 457,
                "label": "Harbin",
                "label_ar": "هاربين",
                "label_fr": "Harbin"
            },
            {
                "value": 458,
                "label": "Zhaodong",
                "label_ar": "تشاودونغ",
                "label_fr": "Zhaodong"
            },
            {
                "value": 459,
                "label": "Taiyuan",
                "label_ar": "تاييوان",
                "label_fr": "Taiyuan"
            },
            {
                "value": 460,
                "label": "Xi'an",
                "label_ar": "شيان",
                "label_fr": "Xi'an"
            },
            {
                "value": 461,
                "label": "Xianyang",
                "label_ar": "شيانيانغ",
                "label_fr": "Xianyang"
            },
            {
                "value": 462,
                "label": "Shenzhen",
                "label_ar": "شنتشن",
                "label_fr": "Shenzhen"
            },
            {
                "value": 463,
                "label": "Nanning",
                "label_ar": "ناننينغ",
                "label_fr": "Nanning"
            },
            {
                "value": 464,
                "label": "Zhengzhou",
                "label_ar": "تشنغتشو",
                "label_fr": "Zhengzhou"
            },
            {
                "value": 465,
                "label": "Xinxiang",
                "label_ar": "شينشيانغ",
                "label_fr": "Xinxiang"
            },
            {
                "value": 466,
                "label": "Luohe",
                "label_ar": "لوهي",
                "label_fr": "Luohe"
            },
            {
                "value": 467,
                "label": "Luoyang",
                "label_ar": "لويانغ",
                "label_fr": "Luoyang"
            },
            {
                "value": 468,
                "label": "Chaoyang",
                "label_ar": "تشاويانغ",
                "label_fr": "Chaoyang"
            },
            {
                "value": 469,
                "label": "Xingyi",
                "label_ar": "Xingyi",
                "label_fr": "Xingyi"
            },
            {
                "value": 470,
                "label": "Foshan",
                "label_ar": "فوشان",
                "label_fr": "Foshan"
            },
            {
                "value": 471,
                "label": "Haikou",
                "label_ar": "هايكو",
                "label_fr": "Haikou"
            },
            {
                "value": 472,
                "label": "Chengdu",
                "label_ar": "تشنغدو",
                "label_fr": "Chengdu"
            },
            {
                "value": 473,
                "label": "Dongguan",
                "label_ar": "دونغقوان",
                "label_fr": "Dongguan"
            },
            {
                "value": 474,
                "label": "Mingzhou",
                "label_ar": "مينجزهو",
                "label_fr": "Mingzhou"
            },
            {
                "value": 475,
                "label": "Chongqing",
                "label_ar": "تشونغتشينغ",
                "label_fr": "Chongqing"
            },
            {
                "value": 476,
                "label": "Zhuhai",
                "label_ar": "تشوهاى",
                "label_fr": "Zhuhai"
            },
            {
                "value": 477,
                "label": "Kunming",
                "label_ar": "كونمينغ",
                "label_fr": "Kunming"
            },
            {
                "value": 478,
                "label": "Wuhan",
                "label_ar": "ووهان",
                "label_fr": "Wuhan"
            },
            {
                "value": 479,
                "label": "Xiling",
                "label_ar": "شيلينغ",
                "label_fr": "Xiling"
            },
            {
                "value": 480,
                "label": "Huizhou",
                "label_ar": "هويتشو",
                "label_fr": "Huizhou"
            },
            {
                "value": 481,
                "label": "Jiangmen",
                "label_ar": "جيانغمن",
                "label_fr": "Jiangmen"
            },
            {
                "value": 482,
                "label": "Shantou",
                "label_ar": "شانتو",
                "label_fr": "Shantou"
            },
            {
                "value": 483,
                "label": "Changxiacun",
                "label_ar": "تشانغشياكون",
                "label_fr": "Changxiacun"
            },
            {
                "value": 484,
                "label": "Zhongshan",
                "label_ar": "تشونغشان",
                "label_fr": "Zhongshan"
            },
            {
                "value": 485,
                "label": "Lhasa",
                "label_ar": "لاسا",
                "label_fr": "Lhassa"
            },
            {
                "value": 486,
                "label": "Nanchang",
                "label_ar": "نانتشانغ",
                "label_fr": "Nanchang"
            },
            {
                "value": 487,
                "label": "Tianjin",
                "label_ar": "تيانجين",
                "label_fr": "Tianjin"
            },
            {
                "value": 488,
                "label": "Shanghai",
                "label_ar": "شنغهاي",
                "label_fr": "Shanghai"
            },
            {
                "value": 489,
                "label": "Hebei",
                "label_ar": "خبى",
                "label_fr": "Hebei"
            },
            {
                "value": 490,
                "label": "Shijiazhuang",
                "label_ar": "شيجياتشوانغ",
                "label_fr": "Shijiazhuang"
            },
            {
                "value": 491,
                "label": "Quanzhou",
                "label_ar": "تشيوانتشو",
                "label_fr": "Quanzhou"
            },
            {
                "value": 492,
                "label": "Putian",
                "label_ar": "بوتيان",
                "label_fr": "Putian"
            },
            {
                "value": 493,
                "label": "Xiamen",
                "label_ar": "شيامن",
                "label_fr": "Xiamen"
            },
            {
                "value": 494,
                "label": "Chengyang",
                "label_ar": "تشنغيانغ",
                "label_fr": "Chengyang"
            },
            {
                "value": 495,
                "label": "Zhangzhou",
                "label_ar": "تشانغتشو",
                "label_fr": "Zhangzhou"
            },
            {
                "value": 496,
                "label": "Sanming",
                "label_ar": "سانمينغ",
                "label_fr": "Sanming"
            },
            {
                "value": 497,
                "label": "Nanping",
                "label_ar": "نانبينغ",
                "label_fr": "Nanping"
            },
            {
                "value": 498,
                "label": "Baoding",
                "label_ar": "باودينغ",
                "label_fr": "Baoding"
            },
            {
                "value": 499,
                "label": "Langfang",
                "label_ar": "لانغفانغ",
                "label_fr": "Langfang"
            },
            {
                "value": 500,
                "label": "Yantai",
                "label_ar": "يانتاى",
                "label_fr": "Yantai"
            },
            {
                "value": 501,
                "label": "Binzhou",
                "label_ar": "بينزهو",
                "label_fr": "Binzhou"
            },
            {
                "value": 502,
                "label": "Lanzhou",
                "label_ar": "لانتشو",
                "label_fr": "Lanzhou"
            },
            {
                "value": 503,
                "label": "Yueqing",
                "label_ar": "يويهتشينغ",
                "label_fr": "Yueqing"
            },
            {
                "value": 504,
                "label": "Zhongxin",
                "label_ar": "تشونغ شين",
                "label_fr": "Zhongxin"
            },
            {
                "value": 505,
                "label": "Zhoushan",
                "label_ar": "تشوشان",
                "label_fr": "Zhoushan"
            },
            {
                "value": 506,
                "label": "Hangzhou",
                "label_ar": "هانغتشو",
                "label_fr": "Hangzhou"
            },
            {
                "value": 507,
                "label": "Ningbo",
                "label_ar": "نينغبو",
                "label_fr": "Ningbo"
            },
            {
                "value": 508,
                "label": "Wenzhou",
                "label_ar": "ونزهو",
                "label_fr": "Wenzhou"
            },
            {
                "value": 509,
                "label": "Changchun",
                "label_ar": "تشانغتشون",
                "label_fr": "Changchun"
            },
            {
                "value": 510,
                "label": "Fuyang",
                "label_ar": "فويانغ",
                "label_fr": "Fuyang"
            },
            {
                "value": 511,
                "label": "Jieshou",
                "label_ar": "جيشو",
                "label_fr": "Jieshou"
            },
            {
                "value": 512,
                "label": "Anqing",
                "label_ar": "انكينغ",
                "label_fr": "Anqing"
            },
            {
                "value": 513,
                "label": "Wuhu",
                "label_ar": "ووهو",
                "label_fr": "Wuhu"
            },
            {
                "value": 514,
                "label": "Shishi",
                "label_ar": "شيشي",
                "label_fr": "Shishi"
            },
            {
                "value": 515,
                "label": "Shishi",
                "label_ar": "شيشي",
                "label_fr": "Shishi"
            },
            {
                "value": 516,
                "label": "Weitang",
                "label_ar": "ويتانغ",
                "label_fr": "Weitang"
            },
            {
                "value": 517,
                "label": "Shenyang",
                "label_ar": "شنيانغ",
                "label_fr": "Shenyang"
            },
            {
                "value": 518,
                "label": "Changsha",
                "label_ar": "تشانغشا",
                "label_fr": "Changsha"
            },
            {
                "value": 519,
                "label": "Yongjiawan",
                "label_ar": "يونغجياوان",
                "label_fr": "Yongjiawan"
            },
            {
                "value": 520,
                "label": "Lengshuijiang",
                "label_ar": "Lengshuijiang",
                "label_fr": "Lengshuijiang"
            },
            {
                "value": 521,
                "label": "Shijiazhuang",
                "label_ar": "شيجياتشوانغ",
                "label_fr": "Shijiazhuang"
            },
            {
                "value": 522,
                "label": "Xuchang",
                "label_ar": "شوتشانغ",
                "label_fr": "Xuchang"
            },
            {
                "value": 523,
                "label": "Suzhou",
                "label_ar": "سوتشو",
                "label_fr": "Suzhou"
            },
            {
                "value": 524,
                "label": "Xuzhou",
                "label_ar": "سوزهو",
                "label_fr": "Xuzhou"
            },
            {
                "value": 525,
                "label": "Taizhou",
                "label_ar": "تايتشو",
                "label_fr": "Taizhou"
            },
            {
                "value": 526,
                "label": "Nanyang",
                "label_ar": "نانيانغ",
                "label_fr": "Nanyang"
            },
            {
                "value": 527,
                "label": "Xinhua",
                "label_ar": "شينخوا",
                "label_fr": "Xinhua"
            },
            {
                "value": 528,
                "label": "Ürümqi",
                "label_ar": "أورومكي",
                "label_fr": "Ürümqi"
            },
            {
                "value": 529,
                "label": "Yan'an Beilu",
                "label_ar": "يانان بيلو",
                "label_fr": "Yan'an Beilu"
            },
            {
                "value": 530,
                "label": "Baotao",
                "label_ar": "باوتاو",
                "label_fr": "Baotao"
            },
            {
                "value": 531,
                "label": "Macao",
                "label_ar": "ماكاو",
                "label_fr": "Macao"
            },
            {
                "value": 532,
                "label": "Wuxi",
                "label_ar": "وشى",
                "label_fr": "Wuxi"
            },
            {
                "value": 533,
                "label": "Yangzhou",
                "label_ar": "يانغتشو",
                "label_fr": "Yangzhou"
            },
            {
                "value": 534,
                "label": "Baiyin",
                "label_ar": "بايين",
                "label_fr": "Baiyin"
            },
            {
                "value": 535,
                "label": "Tongren",
                "label_ar": "تونغرين",
                "label_fr": "Tongren"
            },
            {
                "value": 536,
                "label": "Kunshan",
                "label_ar": "كونشان",
                "label_fr": "Kunshan"
            },
            {
                "value": 537,
                "label": "Zhangjiagang",
                "label_ar": "تشانغجياجانج",
                "label_fr": "Zhangjiagang"
            },
            {
                "value": 538,
                "label": "Jiangyin",
                "label_ar": "جيانجين",
                "label_fr": "Jiangyin"
            },
            {
                "value": 539,
                "label": "Zhenjiang",
                "label_ar": "تشنجيانغ",
                "label_fr": "Zhenjiang"
            },
            {
                "value": 540,
                "label": "Zhoukou",
                "label_ar": "تشوكو",
                "label_fr": "Zhoukou"
            },
            {
                "value": 541,
                "label": "Anyang",
                "label_ar": "انيانغ",
                "label_fr": "Anyang"
            },
            {
                "value": 542,
                "label": "Dalian",
                "label_ar": "داليان",
                "label_fr": "Dalian"
            },
            {
                "value": 543,
                "label": "Tongcun",
                "label_ar": "تونغكون",
                "label_fr": "Tongcun"
            },
            {
                "value": 544,
                "label": "Shihezi",
                "label_ar": "شيهيزي",
                "label_fr": "Shihezi"
            },
            {
                "value": 545,
                "label": "Xining",
                "label_ar": "شينينغ",
                "label_fr": "Xining"
            },
            {
                "value": 546,
                "label": "Zhangye",
                "label_ar": "زانغي",
                "label_fr": "Zhangye"
            },
            {
                "value": 547,
                "label": "Qingyang",
                "label_ar": "تشينغيانغ",
                "label_fr": "Qingyang"
            },
            {
                "value": 548,
                "label": "Wangsu",
                "label_ar": "وانجسو",
                "label_fr": "Wangsu"
            },
            {
                "value": 549,
                "label": "Anshun",
                "label_ar": "انشون",
                "label_fr": "Anshun"
            },
            {
                "value": 550,
                "label": "Kaili",
                "label_ar": "كايلي",
                "label_fr": "Kaili"
            },
            {
                "value": 551,
                "label": "Bijie",
                "label_ar": "بيجي",
                "label_fr": "Bijie"
            },
            {
                "value": 552,
                "label": "Shuigang",
                "label_ar": "شويغانغ",
                "label_fr": "Shuigang"
            },
            {
                "value": 553,
                "label": "Jianyuan",
                "label_ar": "جيانيوان",
                "label_fr": "Jianyuan"
            },
            {
                "value": 554,
                "label": "Jinlong",
                "label_ar": "جين لونغ",
                "label_fr": "Jinlong"
            },
            {
                "value": 555,
                "label": "Jingdezhen",
                "label_ar": "جينغدتشن",
                "label_fr": "Jingdezhen"
            },
            {
                "value": 556,
                "label": "Yichang",
                "label_ar": "ييتشانغ",
                "label_fr": "Yichang"
            },
            {
                "value": 557,
                "label": "Xiangfan",
                "label_ar": "شيانغفان",
                "label_fr": "Xiangfan"
            },
            {
                "value": 558,
                "label": "Jiayuan",
                "label_ar": "جيايوان",
                "label_fr": "Jiayuan"
            },
            {
                "value": 559,
                "label": "Shashi",
                "label_ar": "شاشي",
                "label_fr": "Shashi"
            },
            {
                "value": 560,
                "label": "Yingjie",
                "label_ar": "ينججي",
                "label_fr": "Yingjie"
            },
            {
                "value": 561,
                "label": "Huangshi",
                "label_ar": "هوانغشي",
                "label_fr": "Huangshi"
            },
            {
                "value": 562,
                "label": "Jinhua",
                "label_ar": "جينهوا",
                "label_fr": "Jinhua"
            },
            {
                "value": 563,
                "label": "Zhengyuan",
                "label_ar": "Zhengyuan",
                "label_fr": "Zhengyuan"
            },
            {
                "value": 564,
                "label": "Langli",
                "label_ar": "لانجلي",
                "label_fr": "Langli"
            },
            {
                "value": 565,
                "label": "Hengyang",
                "label_ar": "هنغيانغ",
                "label_fr": "Hengyang"
            },
            {
                "value": 566,
                "label": "Jianning",
                "label_ar": "جيانينج",
                "label_fr": "Jianning"
            },
            {
                "value": 567,
                "label": "Xiangtan",
                "label_ar": "شيانغتان",
                "label_fr": "Xiangtan"
            },
            {
                "value": 568,
                "label": "Rongxing",
                "label_ar": "رونجكسينج",
                "label_fr": "Rongxing"
            },
            {
                "value": 569,
                "label": "Xincao",
                "label_ar": "زينكاو",
                "label_fr": "Xincao"
            },
            {
                "value": 570,
                "label": "Jinda",
                "label_ar": "جيندا",
                "label_fr": "Jinda"
            },
            {
                "value": 571,
                "label": "Nanlong",
                "label_ar": "نان لونغ",
                "label_fr": "Nanlong"
            },
            {
                "value": 572,
                "label": "Xiangcheng",
                "label_ar": "شيانغتشنغ",
                "label_fr": "Xiangcheng"
            },
            {
                "value": 573,
                "label": "Nanma",
                "label_ar": "نانما",
                "label_fr": "Nanma"
            },
            {
                "value": 574,
                "label": "Zhongxiang",
                "label_ar": "تشونغشيانغ",
                "label_fr": "Zhongxiang"
            },
            {
                "value": 575,
                "label": "Pudong",
                "label_ar": "بودونغ",
                "label_fr": "Pudong"
            },
            {
                "value": 576,
                "label": "Shuitou",
                "label_ar": "شويتو",
                "label_fr": "Shuitou"
            },
            {
                "value": 577,
                "label": "Zhenxing",
                "label_ar": "Zhenxing",
                "label_fr": "Zhenxing"
            },
            {
                "value": 578,
                "label": "Jinjiang",
                "label_ar": "جينجيانغ",
                "label_fr": "Jinjiang"
            },
            {
                "value": 579,
                "label": "Longxun",
                "label_ar": "Longxun",
                "label_fr": "Longxun"
            },
            {
                "value": 580,
                "label": "Guanqiao",
                "label_ar": "Guanqiao",
                "label_fr": "Guanqiao"
            },
            {
                "value": 581,
                "label": "Jingfeng",
                "label_ar": "جينغفنغ",
                "label_fr": "Jingfeng"
            },
            {
                "value": 582,
                "label": "Shijing",
                "label_ar": "شيجينغ",
                "label_fr": "Shijing"
            },
            {
                "value": 583,
                "label": "Tangbian",
                "label_ar": "تانجبيان",
                "label_fr": "Tangbian"
            },
            {
                "value": 584,
                "label": "Jiangchuanlu",
                "label_ar": "جيانتشوانلو",
                "label_fr": "Jiangchuanlu"
            },
            {
                "value": 585,
                "label": "Jiaoyun",
                "label_ar": "جياويون",
                "label_fr": "Jiaoyun"
            },
            {
                "value": 586,
                "label": "Guizhoumanzuxiang",
                "label_ar": "Guizhoumanzuxiang",
                "label_fr": "Guizhoumanzuxiang"
            },
            {
                "value": 587,
                "label": "Qingzhen",
                "label_ar": "Qingzhen",
                "label_fr": "Qingzhen"
            },
            {
                "value": 588,
                "label": "Changde",
                "label_ar": "تشانغده",
                "label_fr": "Changde"
            },
            {
                "value": 589,
                "label": "Xianning",
                "label_ar": "شياننينغ",
                "label_fr": "Xianning"
            },
            {
                "value": 590,
                "label": "Jiaozhou",
                "label_ar": "جياوزو",
                "label_fr": "Jiaozhou"
            },
            {
                "value": 591,
                "label": "Weifang",
                "label_ar": "ويفانغ",
                "label_fr": "Weifang"
            },
            {
                "value": 592,
                "label": "Tai'an",
                "label_ar": "تايآن",
                "label_fr": "Tai'an"
            },
            {
                "value": 593,
                "label": "Luoxi",
                "label_ar": "Luoxi",
                "label_fr": "Luoxi"
            },
            {
                "value": 594,
                "label": "Guoji",
                "label_ar": "Guoji",
                "label_fr": "Guoji"
            },
            {
                "value": 595,
                "label": "Guangdong",
                "label_ar": "قوانغدونغ",
                "label_fr": "Guangdong"
            },
            {
                "value": 596,
                "label": "Sijiqing",
                "label_ar": "سيجيكينج",
                "label_fr": "Sijiqing"
            },
            {
                "value": 597,
                "label": "Huzhou",
                "label_ar": "هوتشو",
                "label_fr": "Huzhou"
            },
            {
                "value": 598,
                "label": "Panjin Shi",
                "label_ar": "بانجين شي",
                "label_fr": "Panjin Shi"
            },
            {
                "value": 599,
                "label": "Daqing",
                "label_ar": "داتشينغ",
                "label_fr": "Daqing"
            },
            {
                "value": 600,
                "label": "Jilin City",
                "label_ar": "مدينة جيلين",
                "label_fr": "Ville de Jilin"
            },
            {
                "value": 601,
                "label": "Lianyungang",
                "label_ar": "ليانيونقانغ",
                "label_fr": "Lianyungang"
            },
            {
                "value": 602,
                "label": "Yancheng",
                "label_ar": "يانتشنغ",
                "label_fr": "Yancheng"
            },
            {
                "value": 603,
                "label": "Yuehu",
                "label_ar": "يويهو",
                "label_fr": "Yuehu"
            },
            {
                "value": 604,
                "label": "Kunshan",
                "label_ar": "كونشان",
                "label_fr": "Kunshan"
            },
            {
                "value": 605,
                "label": "Taicang",
                "label_ar": "تايكانغ",
                "label_fr": "Taicang"
            },
            {
                "value": 606,
                "label": "Lianshui",
                "label_ar": "ليانشوي",
                "label_fr": "Lianshui"
            },
            {
                "value": 607,
                "label": "Nantong",
                "label_ar": "نانتونغ",
                "label_fr": "Nantong"
            },
            {
                "value": 608,
                "label": "Jiaotong",
                "label_ar": "جياوتونغ",
                "label_fr": "Jiaotong"
            },
            {
                "value": 609,
                "label": "Changshu City",
                "label_ar": "مدينة تشانغشو",
                "label_fr": "Ville de Changshu"
            },
            {
                "value": 610,
                "label": "Xingxiangcun",
                "label_ar": "Xingxiangcun",
                "label_fr": "Xingxiangcun"
            },
            {
                "value": 611,
                "label": "Jinsha",
                "label_ar": "جينشا",
                "label_fr": "Jinsha"
            },
            {
                "value": 612,
                "label": "Jiangyan",
                "label_ar": "جيانغيان",
                "label_fr": "Jiangyan"
            },
            {
                "value": 613,
                "label": "Chaigoubu",
                "label_ar": "شايغوبو",
                "label_fr": "Chaigoubu"
            },
            {
                "value": 614,
                "label": "Ma'anshan",
                "label_ar": "معنشان",
                "label_fr": "Ma'anshan"
            },
            {
                "value": 615,
                "label": "Huainan",
                "label_ar": "هواينان",
                "label_fr": "Huainan"
            },
            {
                "value": 616,
                "label": "Haibei",
                "label_ar": "هايبي",
                "label_fr": "Haibei"
            },
            {
                "value": 617,
                "label": "Shenlong",
                "label_ar": "شينلونج",
                "label_fr": "Shenlong"
            },
            {
                "value": 618,
                "label": "Nangxian",
                "label_ar": "نانغسيان",
                "label_fr": "Nangxian"
            },
            {
                "value": 619,
                "label": "Rongsheng",
                "label_ar": "رونغ شنغ",
                "label_fr": "Rongsheng"
            },
            {
                "value": 620,
                "label": "Changfeng",
                "label_ar": "تشانغفنغ",
                "label_fr": "Changfeng"
            },
            {
                "value": 621,
                "label": "Chengqiao",
                "label_ar": "تشينجكياو",
                "label_fr": "Chengqiao"
            },
            {
                "value": 622,
                "label": "Jiafu",
                "label_ar": "جيافو",
                "label_fr": "Jiafu"
            },
            {
                "value": 623,
                "label": "Shenzhou",
                "label_ar": "شنتشو",
                "label_fr": "Shenzhou"
            },
            {
                "value": 624,
                "label": "Shantou",
                "label_ar": "شانتو",
                "label_fr": "Shantou"
            },
            {
                "value": 625,
                "label": "Qingyuan",
                "label_ar": "تشينغيوان",
                "label_fr": "Qingyuan"
            },
            {
                "value": 626,
                "label": "Gyari",
                "label_ar": "جياري",
                "label_fr": "Gyari"
            },
            {
                "value": 627,
                "label": "Xinshijie",
                "label_ar": "Xinshijie",
                "label_fr": "Xinshijie"
            },
            {
                "value": 628,
                "label": "Zhaoqing",
                "label_ar": "تشاوتشينغ",
                "label_fr": "Zhaoqing"
            },
            {
                "value": 629,
                "label": "Zhanjiang",
                "label_ar": "تشانجيانغ",
                "label_fr": "Zhanjiang"
            },
            {
                "value": 630,
                "label": "Kuicheng",
                "label_ar": "كويشنغ",
                "label_fr": "Kuicheng"
            },
            {
                "value": 631,
                "label": "Taoyuan",
                "label_ar": "تاويوان",
                "label_fr": "Taoyuan"
            },
            {
                "value": 632,
                "label": "Jincheng",
                "label_ar": "جينتشنغ",
                "label_fr": "Jincheng"
            },
            {
                "value": 633,
                "label": "Caishen",
                "label_ar": "كايشن",
                "label_fr": "Caishen"
            },
            {
                "value": 634,
                "label": "Shiyan",
                "label_ar": "شيان",
                "label_fr": "Shiyan"
            },
            {
                "value": 635,
                "label": "Liaoyang",
                "label_ar": "لياويانغ",
                "label_fr": "Liaoyang"
            },
            {
                "value": 636,
                "label": "Xingtai",
                "label_ar": "شينغتاى",
                "label_fr": "Xingtai"
            },
            {
                "value": 637,
                "label": "Wenchang",
                "label_ar": "وينتشانغ",
                "label_fr": "Wenchang"
            },
            {
                "value": 638,
                "label": "Wanning",
                "label_ar": "يتضاءل",
                "label_fr": "Wanning"
            },
            {
                "value": 639,
                "label": "Qionghai",
                "label_ar": "Qionghai",
                "label_fr": "Qionghai"
            },
            {
                "value": 640,
                "label": "Huilongba",
                "label_ar": "هويلونغبا",
                "label_fr": "Huilongba"
            },
            {
                "value": 641,
                "label": "Dingcheng",
                "label_ar": "دينغتشنغ",
                "label_fr": "Dingcheng"
            },
            {
                "value": 642,
                "label": "Baodian",
                "label_ar": "باوديان",
                "label_fr": "Baodian"
            },
            {
                "value": 643,
                "label": "Wuzhishan",
                "label_ar": "ووجيشان",
                "label_fr": "Wuzhishan"
            },
            {
                "value": 644,
                "label": "Chengmai",
                "label_ar": "تشنغماي",
                "label_fr": "Chengmai"
            },
            {
                "value": 645,
                "label": "Yinggen",
                "label_ar": "ينجين",
                "label_fr": "Yinggen"
            },
            {
                "value": 646,
                "label": "Ledong",
                "label_ar": "ليدونج",
                "label_fr": "Ledong"
            },
            {
                "value": 647,
                "label": "Lincheng",
                "label_ar": "لينتشنغ",
                "label_fr": "Lincheng"
            },
            {
                "value": 648,
                "label": "Baisha",
                "label_ar": "بيشة",
                "label_fr": "Baisha"
            },
            {
                "value": 649,
                "label": "Changjiang",
                "label_ar": "تشانغجيانغ",
                "label_fr": "Changjiang"
            },
            {
                "value": 650,
                "label": "Dongfang",
                "label_ar": "دونغفانغ",
                "label_fr": "Dongfang"
            },
            {
                "value": 651,
                "label": "Changjian",
                "label_ar": "تشانغجيان",
                "label_fr": "Changjian"
            },
            {
                "value": 652,
                "label": "Jinmao",
                "label_ar": "جينماو",
                "label_fr": "Jinmao"
            },
            {
                "value": 653,
                "label": "Yangpu",
                "label_ar": "يانغبو",
                "label_fr": "Yangpu"
            },
            {
                "value": 654,
                "label": "Baipo",
                "label_ar": "بايبو",
                "label_fr": "Baipo"
            },
            {
                "value": 655,
                "label": "Jiefang",
                "label_ar": "جيفانغ",
                "label_fr": "Jiefang"
            },
            {
                "value": 656,
                "label": "Danzhou",
                "label_ar": "دانتشو",
                "label_fr": "Danzhou"
            },
            {
                "value": 657,
                "label": "Lingshui",
                "label_ar": "Lingshui",
                "label_fr": "Lingshui"
            },
            {
                "value": 658,
                "label": "Haidian",
                "label_ar": "هايديان",
                "label_fr": "Haidian"
            },
            {
                "value": 659,
                "label": "Sanya",
                "label_ar": "سانيا",
                "label_fr": "Sanya"
            },
            {
                "value": 660,
                "label": "Rongjiang",
                "label_ar": "رونغجيانغ",
                "label_fr": "Rongjiang"
            },
            {
                "value": 661,
                "label": "Longyan",
                "label_ar": "لونجيان",
                "label_fr": "Longyan"
            },
            {
                "value": 662,
                "label": "Jinghe",
                "label_ar": "جينغخه",
                "label_fr": "Jinghe"
            },
            {
                "value": 663,
                "label": "Zhangjiakou",
                "label_ar": "تشانغجياكو",
                "label_fr": "Zhangjiakou"
            },
            {
                "value": 664,
                "label": "Renqiu",
                "label_ar": "رينكيو",
                "label_fr": "Renqiu"
            },
            {
                "value": 665,
                "label": "Yaocheng",
                "label_ar": "ياوتشنغ",
                "label_fr": "Yaocheng"
            },
            {
                "value": 666,
                "label": "Kaifeng",
                "label_ar": "كايفنغ",
                "label_fr": "Kaifeng"
            },
            {
                "value": 667,
                "label": "Hebi",
                "label_ar": "حبي",
                "label_fr": "Hebi"
            },
            {
                "value": 668,
                "label": "Jiaozuo",
                "label_ar": "جياوزو",
                "label_fr": "Jiaozuo"
            },
            {
                "value": 669,
                "label": "Pingdingshan",
                "label_ar": "بينغدينغشان",
                "label_fr": "Pingdingshan"
            },
            {
                "value": 670,
                "label": "Anshan",
                "label_ar": "آنشان",
                "label_fr": "Anshan"
            },
            {
                "value": 671,
                "label": "Dandong",
                "label_ar": "داندونغ",
                "label_fr": "Dandong"
            },
            {
                "value": 672,
                "label": "Haitang",
                "label_ar": "هايتانج",
                "label_fr": "Haitang"
            },
            {
                "value": 673,
                "label": "Tongchuan",
                "label_ar": "تونغتشوان",
                "label_fr": "Tongchuan"
            },
            {
                "value": 674,
                "label": "Ankang",
                "label_ar": "انكانغ",
                "label_fr": "Ankang"
            },
            {
                "value": 675,
                "label": "Guozhen",
                "label_ar": "Guozhen",
                "label_fr": "Guozhen"
            },
            {
                "value": 676,
                "label": "Shangluo",
                "label_ar": "Shangluo",
                "label_fr": "Shangluo"
            },
            {
                "value": 677,
                "label": "Xijing",
                "label_ar": "شيجينغ",
                "label_fr": "Xijing"
            },
            {
                "value": 678,
                "label": "Weinan",
                "label_ar": "وينان",
                "label_fr": "Weinan"
            },
            {
                "value": 679,
                "label": "Yulin",
                "label_ar": "يولين",
                "label_fr": "Yulin"
            },
            {
                "value": 680,
                "label": "Yining",
                "label_ar": "ينينج",
                "label_fr": "Yining"
            },
            {
                "value": 681,
                "label": "Dingxi",
                "label_ar": "دينغكسي",
                "label_fr": "Dingxi"
            },
            {
                "value": 682,
                "label": "Wuwei",
                "label_ar": "ووي",
                "label_fr": "Wuwei"
            },
            {
                "value": 683,
                "label": "Dawukou",
                "label_ar": "Dawukou",
                "label_fr": "Dawukou"
            },
            {
                "value": 684,
                "label": "Lishui",
                "label_ar": "ليشوي",
                "label_fr": "Lishui"
            },
            {
                "value": 685,
                "label": "Quzhou",
                "label_ar": "تشوتشو",
                "label_fr": "Quzhou"
            },
            {
                "value": 686,
                "label": "Hejiang",
                "label_ar": "هجيانغ",
                "label_fr": "Hejiang"
            },
            {
                "value": 687,
                "label": "Handan",
                "label_ar": "هاندان",
                "label_fr": "Handan"
            },
            {
                "value": 688,
                "label": "Qinhuangdao",
                "label_ar": "تشينهوانغداو",
                "label_fr": "Qinhuangdao"
            },
            {
                "value": 689,
                "label": "Hengshui",
                "label_ar": "هنغشوي",
                "label_fr": "Hengshui"
            },
            {
                "value": 690,
                "label": "Longxin",
                "label_ar": "Longxin",
                "label_fr": "Longxin"
            },
            {
                "value": 691,
                "label": "Wen'an",
                "label_ar": "وينان",
                "label_fr": "Wen'an"
            },
            {
                "value": 692,
                "label": "Yichun",
                "label_ar": "ييتشون",
                "label_fr": "Yichun"
            },
            {
                "value": 693,
                "label": "Heihe",
                "label_ar": "هيهي",
                "label_fr": "Heihe"
            },
            {
                "value": 694,
                "label": "Jiaxing",
                "label_ar": "جياشينغ",
                "label_fr": "Jiaxing"
            },
            {
                "value": 695,
                "label": "Korla",
                "label_ar": "كورلا",
                "label_fr": "Korla"
            },
            {
                "value": 696,
                "label": "Kuytun",
                "label_ar": "Kuytun",
                "label_fr": "Kuytun"
            },
            {
                "value": 697,
                "label": "Di'an",
                "label_ar": "ديان",
                "label_fr": "Di'an"
            },
            {
                "value": 698,
                "label": "Yu'an",
                "label_ar": "يوان",
                "label_fr": "Yuan"
            },
            {
                "value": 699,
                "label": "Mengzhou",
                "label_ar": "مينجزو",
                "label_fr": "Mengzhou"
            },
            {
                "value": 700,
                "label": "Hulu",
                "label_ar": "هولو",
                "label_fr": "Hulu"
            },
            {
                "value": 701,
                "label": "Yizhou",
                "label_ar": "ييتشو",
                "label_fr": "Yizhou"
            },
            {
                "value": 702,
                "label": "Shuliang",
                "label_ar": "شوليانج",
                "label_fr": "Shuliang"
            },
            {
                "value": 703,
                "label": "Shancheng",
                "label_ar": "شانتشنغ",
                "label_fr": "Shancheng"
            },
            {
                "value": 704,
                "label": "Fushun",
                "label_ar": "فوشون",
                "label_fr": "Fushun"
            },
            {
                "value": 705,
                "label": "Dashiqiao",
                "label_ar": "داشيكياو",
                "label_fr": "Dashiqiao"
            },
            {
                "value": 706,
                "label": "Laonian",
                "label_ar": "اللاونيان",
                "label_fr": "Laonien"
            },
            {
                "value": 707,
                "label": "Shengli",
                "label_ar": "شنغلي",
                "label_fr": "Shengli"
            },
            {
                "value": 708,
                "label": "Wenquan",
                "label_ar": "Wenquan",
                "label_fr": "Wenquan"
            },
            {
                "value": 709,
                "label": "Zhiye",
                "label_ar": "زيي",
                "label_fr": "Zhiye"
            },
            {
                "value": 710,
                "label": "Lingzhi",
                "label_ar": "لينجزي",
                "label_fr": "Lingzhi"
            },
            {
                "value": 711,
                "label": "Zhongtang",
                "label_ar": "تشونغتانغ",
                "label_fr": "Zhongtang"
            },
            {
                "value": 712,
                "label": "Gucheng",
                "label_ar": "جوتشينج",
                "label_fr": "Gucheng"
            },
            {
                "value": 713,
                "label": "Xinhua",
                "label_ar": "شينخوا",
                "label_fr": "Xinhua"
            },
            {
                "value": 714,
                "label": "Ninghe",
                "label_ar": "نينجي",
                "label_fr": "Ninghe"
            },
            {
                "value": 715,
                "label": "Dangyang",
                "label_ar": "Dangyang",
                "label_fr": "Dangyang"
            },
            {
                "value": 716,
                "label": "Yizhong",
                "label_ar": "ييشونغ",
                "label_fr": "Yizhong"
            },
            {
                "value": 717,
                "label": "Jizhou",
                "label_ar": "جيزو",
                "label_fr": "Jizhou"
            },
            {
                "value": 718,
                "label": "Tianbao",
                "label_ar": "تيانباو",
                "label_fr": "Tianbao"
            },
            {
                "value": 719,
                "label": "Jinghai",
                "label_ar": "جينغهاي",
                "label_fr": "Jinghai"
            },
            {
                "value": 720,
                "label": "Julong",
                "label_ar": "Julong",
                "label_fr": "Julong"
            },
            {
                "value": 721,
                "label": "Jiaqin",
                "label_ar": "جياكين",
                "label_fr": "Jiaqin"
            },
            {
                "value": 722,
                "label": "Jiayue",
                "label_ar": "جيايو",
                "label_fr": "Jiayue"
            },
            {
                "value": 723,
                "label": "Dabaizhuang",
                "label_ar": "Dabaizhuang",
                "label_fr": "Dabaizhuang"
            },
            {
                "value": 724,
                "label": "Juchuan",
                "label_ar": "جوتشوان",
                "label_fr": "Juchuan"
            },
            {
                "value": 725,
                "label": "Hexi",
                "label_ar": "هيكسي",
                "label_fr": "Hexi"
            },
            {
                "value": 726,
                "label": "Jinnan",
                "label_ar": "جنان",
                "label_fr": "Jinnan"
            },
            {
                "value": 727,
                "label": "Hangu",
                "label_ar": "هانغو",
                "label_fr": "Hangu"
            },
            {
                "value": 728,
                "label": "Nankai",
                "label_ar": "نانكاى",
                "label_fr": "Nankai"
            },
            {
                "value": 729,
                "label": "Hedong",
                "label_ar": "هيدونج",
                "label_fr": "Hedong"
            },
            {
                "value": 730,
                "label": "Yanglou",
                "label_ar": "يانجلو",
                "label_fr": "Yanglou"
            },
            {
                "value": 731,
                "label": "Huawei",
                "label_ar": "هواوي",
                "label_fr": "Huawei"
            },
            {
                "value": 732,
                "label": "Hanting",
                "label_ar": "هانتينج",
                "label_fr": "Hanting"
            },
            {
                "value": 733,
                "label": "Tianshi",
                "label_ar": "تيانشي",
                "label_fr": "Tianshi"
            },
            {
                "value": 734,
                "label": "Baiyu",
                "label_ar": "بايو",
                "label_fr": "Baiyu"
            },
            {
                "value": 735,
                "label": "Bohai",
                "label_ar": "بوهاي",
                "label_fr": "Bohai"
            },
            {
                "value": 736,
                "label": "Rujia",
                "label_ar": "روجيا",
                "label_fr": "Rujia"
            },
            {
                "value": 737,
                "label": "Tongwang",
                "label_ar": "تونغوانغ",
                "label_fr": "Tongwang"
            },
            {
                "value": 738,
                "label": "Meijiang",
                "label_ar": "ميجيانغ",
                "label_fr": "Meijiang"
            },
            {
                "value": 739,
                "label": "Dagang",
                "label_ar": "Dagang",
                "label_fr": "Dagang"
            },
            {
                "value": 740,
                "label": "Baodi",
                "label_ar": "باودي",
                "label_fr": "Baodi"
            },
            {
                "value": 741,
                "label": "Daqiuzhuang",
                "label_ar": "داتشوانغ",
                "label_fr": "Daqiuzhuang"
            },
            {
                "value": 742,
                "label": "Yuxi",
                "label_ar": "يوشي",
                "label_fr": "Yuxi"
            },
            {
                "value": 743,
                "label": "Zicheng",
                "label_ar": "تسيشنغ",
                "label_fr": "Zicheng"
            },
            {
                "value": 744,
                "label": "Shaoxing",
                "label_ar": "شاوشينغ",
                "label_fr": "Shaoxing"
            },
            {
                "value": 745,
                "label": "Zhoushan",
                "label_ar": "تشوشان",
                "label_fr": "Zhoushan"
            },
            {
                "value": 746,
                "label": "Xiaoshan",
                "label_ar": "شياوشان",
                "label_fr": "Xiaoshan"
            },
            {
                "value": 747,
                "label": "Linhai",
                "label_ar": "لينهاي",
                "label_fr": "Linhai"
            },
            {
                "value": 748,
                "label": "Cixi",
                "label_ar": "تسيشي",
                "label_fr": "Cixi"
            },
            {
                "value": 749,
                "label": "Jinchuan",
                "label_ar": "جينتشوان",
                "label_fr": "Jinchuan"
            },
            {
                "value": 750,
                "label": "Zhaobaoshan",
                "label_ar": "تشوباوشان",
                "label_fr": "Zhaobaoshan"
            },
            {
                "value": 751,
                "label": "Tiangang",
                "label_ar": "تيانجانج",
                "label_fr": "Tiangang"
            },
            {
                "value": 752,
                "label": "Beilun",
                "label_ar": "بيلون",
                "label_fr": "Beilun"
            },
            {
                "value": 753,
                "label": "Zhangqi",
                "label_ar": "Zhangqi",
                "label_fr": "Zhangqi"
            },
            {
                "value": 754,
                "label": "Zhenghai",
                "label_ar": "تشنغهاي",
                "label_fr": "Zhenghai"
            },
            {
                "value": 755,
                "label": "Cicheng",
                "label_ar": "سيتشنغ",
                "label_fr": "Cicheng"
            },
            {
                "value": 756,
                "label": "Lishu",
                "label_ar": "ليشو",
                "label_fr": "Lishu"
            },
            {
                "value": 757,
                "label": "Chengbei",
                "label_ar": "تشنغبي",
                "label_fr": "Chengbei"
            },
            {
                "value": 758,
                "label": "Heyi",
                "label_ar": "مرحبا انا",
                "label_fr": "Salut, je"
            },
            {
                "value": 759,
                "label": "Xikou",
                "label_ar": "Xikou",
                "label_fr": "Xikou"
            },
            {
                "value": 760,
                "label": "Jiangkou",
                "label_ar": "جيانغكو",
                "label_fr": "Jiangkou"
            },
            {
                "value": 761,
                "label": "Shunshui",
                "label_ar": "شونشوي",
                "label_fr": "Shunshui"
            },
            {
                "value": 762,
                "label": "Simen",
                "label_ar": "سيمين",
                "label_fr": "Simen"
            },
            {
                "value": 763,
                "label": "Yuyao",
                "label_ar": "يوياو",
                "label_fr": "Yuyao"
            },
            {
                "value": 764,
                "label": "Lanjiang",
                "label_ar": "لانجيانغ",
                "label_fr": "Lanjiang"
            },
            {
                "value": 765,
                "label": "Jiangdong",
                "label_ar": "جيانجدونج",
                "label_fr": "Jiangdong"
            },
            {
                "value": 766,
                "label": "Gaotang",
                "label_ar": "جاوتانج",
                "label_fr": "Gaotang"
            },
            {
                "value": 767,
                "label": "Xiangshan",
                "label_ar": "شيانغشان",
                "label_fr": "Xiangshan"
            },
            {
                "value": 768,
                "label": "Shipu",
                "label_ar": "شيبو",
                "label_fr": "Shipu"
            },
            {
                "value": 769,
                "label": "Jinyi",
                "label_ar": "جيني",
                "label_fr": "Jinyi"
            },
            {
                "value": 770,
                "label": "Chengzhong",
                "label_ar": "تشنغ تشونغ",
                "label_fr": "Chengzhong"
            },
            {
                "value": 771,
                "label": "Yinzhou",
                "label_ar": "يينزهو",
                "label_fr": "Yinzhou"
            },
            {
                "value": 772,
                "label": "Luoyang",
                "label_ar": "لويانغ",
                "label_fr": "Luoyang"
            },
            {
                "value": 773,
                "label": "Mapai",
                "label_ar": "ماباي",
                "label_fr": "Mapai"
            },
            {
                "value": 774,
                "label": "Cangnan",
                "label_ar": "كانغنان",
                "label_fr": "Cangnan"
            },
            {
                "value": 775,
                "label": "Jinxiangzhen",
                "label_ar": "جينشيانغزين",
                "label_fr": "Jinxiangzhen"
            },
            {
                "value": 776,
                "label": "Yingjia",
                "label_ar": "ينججيا",
                "label_fr": "Yingjia"
            },
            {
                "value": 777,
                "label": "Pingyang",
                "label_ar": "بينغيانغ",
                "label_fr": "Pingyang"
            },
            {
                "value": 778,
                "label": "Rui'an",
                "label_ar": "روان",
                "label_fr": "Rui'an"
            },
            {
                "value": 779,
                "label": "Tianfu",
                "label_ar": "تيانفو",
                "label_fr": "Tianfu"
            },
            {
                "value": 780,
                "label": "Shangtang",
                "label_ar": "Shangtang",
                "label_fr": "Shangtang"
            },
            {
                "value": 781,
                "label": "Yongjia",
                "label_ar": "يونغجيا",
                "label_fr": "Yongjia"
            },
            {
                "value": 782,
                "label": "Tiancheng",
                "label_ar": "تيانتشينغ",
                "label_fr": "Tiancheng"
            },
            {
                "value": 783,
                "label": "Hongqiao",
                "label_ar": "هونغكياو",
                "label_fr": "Hongqiao"
            },
            {
                "value": 784,
                "label": "Furong",
                "label_ar": "فورونج",
                "label_fr": "Furong"
            },
            {
                "value": 785,
                "label": "Wenxing",
                "label_ar": "وينكسينج",
                "label_fr": "Wenxing"
            },
            {
                "value": 786,
                "label": "Mingxi",
                "label_ar": "مينجكسي",
                "label_fr": "Mingxi"
            },
            {
                "value": 787,
                "label": "Jinshan",
                "label_ar": "جينشان",
                "label_fr": "Jinshan"
            },
            {
                "value": 788,
                "label": "Changtu",
                "label_ar": "تشانغتو",
                "label_fr": "Changtu"
            },
            {
                "value": 789,
                "label": "Anzi",
                "label_ar": "عنزي",
                "label_fr": "Anzi"
            },
            {
                "value": 790,
                "label": "Xianren",
                "label_ar": "زيانرين",
                "label_fr": "Xianren"
            },
            {
                "value": 791,
                "label": "Zhongxing",
                "label_ar": "تشونغشينغ",
                "label_fr": "Zhongxing"
            },
            {
                "value": 792,
                "label": "Guanli",
                "label_ar": "Guanli",
                "label_fr": "Guanli"
            },
            {
                "value": 793,
                "label": "Yucai",
                "label_ar": "يوكاي",
                "label_fr": "Yucai"
            },
            {
                "value": 794,
                "label": "Xianjiang",
                "label_ar": "شيانجيانغ",
                "label_fr": "Xianjiang"
            },
            {
                "value": 795,
                "label": "Aojiang",
                "label_ar": "أوجيانغ",
                "label_fr": "Aojiang"
            },
            {
                "value": 796,
                "label": "Dongtou",
                "label_ar": "Dongtou",
                "label_fr": "Dongtou"
            },
            {
                "value": 797,
                "label": "Rongjiang",
                "label_ar": "رونغجيانغ",
                "label_fr": "Rongjiang"
            },
            {
                "value": 798,
                "label": "Jinmen",
                "label_ar": "جينمن",
                "label_fr": "Jinmen"
            },
            {
                "value": 799,
                "label": "Qiantang",
                "label_ar": "تشيانتانج",
                "label_fr": "Qiantang"
            },
            {
                "value": 800,
                "label": "Baojiang",
                "label_ar": "باوجيانغ",
                "label_fr": "Baojiang"
            },
            {
                "value": 801,
                "label": "Huling",
                "label_ar": "هولنج",
                "label_fr": "Huling"
            },
            {
                "value": 802,
                "label": "Liushi",
                "label_ar": "ليوشي",
                "label_fr": "Liushi"
            },
            {
                "value": 803,
                "label": "Yuecheng",
                "label_ar": "يويتشنغ",
                "label_fr": "Yuecheng"
            },
            {
                "value": 804,
                "label": "Hongyun",
                "label_ar": "هونغ يون",
                "label_fr": "Hongyun"
            },
            {
                "value": 805,
                "label": "Longhua",
                "label_ar": "لونغهوا",
                "label_fr": "Longhua"
            },
            {
                "value": 806,
                "label": "Yajin",
                "label_ar": "ياجين",
                "label_fr": "Yajin"
            },
            {
                "value": 807,
                "label": "Simcun",
                "label_ar": "سيمكون",
                "label_fr": "Simcun"
            },
            {
                "value": 808,
                "label": "Longgang",
                "label_ar": "لونجانج",
                "label_fr": "Longgang"
            },
            {
                "value": 809,
                "label": "Yingdu",
                "label_ar": "ينجدو",
                "label_fr": "Yingdu"
            },
            {
                "value": 810,
                "label": "Wansong",
                "label_ar": "وانسونغ",
                "label_fr": "Wansong"
            },
            {
                "value": 811,
                "label": "Yuele",
                "label_ar": "يويل",
                "label_fr": "Yuele"
            },
            {
                "value": 812,
                "label": "Nanjiang",
                "label_ar": "نانجيانغ",
                "label_fr": "Nanjiang"
            },
            {
                "value": 813,
                "label": "Longhu",
                "label_ar": "لونغو",
                "label_fr": "Longhu"
            },
            {
                "value": 814,
                "label": "Ningyi",
                "label_ar": "نينجي",
                "label_fr": "Ningyi"
            },
            {
                "value": 815,
                "label": "Fengling",
                "label_ar": "فنغلينج",
                "label_fr": "Fengling"
            },
            {
                "value": 816,
                "label": "Wuzhou",
                "label_ar": "وتشو",
                "label_fr": "Wuzhou"
            },
            {
                "value": 817,
                "label": "Xinchen",
                "label_ar": "شينتشين",
                "label_fr": "Xinchen"
            },
            {
                "value": 818,
                "label": "Jinghu",
                "label_ar": "جينغو",
                "label_fr": "Jinghu"
            },
            {
                "value": 819,
                "label": "Fangzhuang",
                "label_ar": "فانغ تشوانغ",
                "label_fr": "Fangzhuang"
            },
            {
                "value": 820,
                "label": "Yinfang",
                "label_ar": "ينفانغ",
                "label_fr": "Yinfang"
            },
            {
                "value": 821,
                "label": "Cili",
                "label_ar": "سيلي",
                "label_fr": "Cili"
            },
            {
                "value": 822,
                "label": "Angu",
                "label_ar": "أنغو",
                "label_fr": "Angu"
            },
            {
                "value": 823,
                "label": "Feiyun",
                "label_ar": "Feiyun",
                "label_fr": "Feiyun"
            },
            {
                "value": 824,
                "label": "Wanquan",
                "label_ar": "Wanquan",
                "label_fr": "Wanquan"
            },
            {
                "value": 825,
                "label": "Kunyang",
                "label_ar": "كونيانج",
                "label_fr": "Kunyang"
            },
            {
                "value": 826,
                "label": "Shibei",
                "label_ar": "شيبى",
                "label_fr": "Shibei"
            },
            {
                "value": 827,
                "label": "Jiangnan",
                "label_ar": "جيانغنان",
                "label_fr": "Jiangnan"
            },
            {
                "value": 828,
                "label": "Yujing",
                "label_ar": "يوجينغ",
                "label_fr": "Yujing"
            },
            {
                "value": 829,
                "label": "Yishan",
                "label_ar": "يشان",
                "label_fr": "Yishan"
            },
            {
                "value": 830,
                "label": "Xuefeng",
                "label_ar": "Xuefeng",
                "label_fr": "Xuefeng"
            },
            {
                "value": 831,
                "label": "Feilong",
                "label_ar": "فيلونج",
                "label_fr": "Feilong"
            },
            {
                "value": 832,
                "label": "Shangrao",
                "label_ar": "شانغراو",
                "label_fr": "Shangrao"
            },
            {
                "value": 833,
                "label": "Xuexiao",
                "label_ar": "Xuexiao",
                "label_fr": "Xuexiao"
            },
            {
                "value": 834,
                "label": "Yuzhen",
                "label_ar": "يوزين",
                "label_fr": "Yuzhen"
            },
            {
                "value": 835,
                "label": "Huangbao",
                "label_ar": "هوانغباو",
                "label_fr": "Huangbao"
            },
            {
                "value": 836,
                "label": "Longquan",
                "label_ar": "Longquan",
                "label_fr": "Longquan"
            },
            {
                "value": 837,
                "label": "Pizhou",
                "label_ar": "بيتشو",
                "label_fr": "Pizhou"
            },
            {
                "value": 838,
                "label": "Songyang",
                "label_ar": "سونغيانغ",
                "label_fr": "Songyang"
            },
            {
                "value": 839,
                "label": "Qingtian",
                "label_ar": "كينجتيان",
                "label_fr": "Qingtian"
            },
            {
                "value": 840,
                "label": "Chenguang",
                "label_ar": "تشنغوانغ",
                "label_fr": "Chenguang"
            },
            {
                "value": 841,
                "label": "Kaiyuan",
                "label_ar": "كايوان",
                "label_fr": "Kaiyuan"
            },
            {
                "value": 842,
                "label": "Dongsheng",
                "label_ar": "دونغ شنغ",
                "label_fr": "Dongsheng"
            },
            {
                "value": 843,
                "label": "Jinyun",
                "label_ar": "جينيون",
                "label_fr": "Jinyun"
            },
            {
                "value": 844,
                "label": "Zhongshan",
                "label_ar": "تشونغشان",
                "label_fr": "Zhongshan"
            },
            {
                "value": 845,
                "label": "Miaogao",
                "label_ar": "مياوجاو",
                "label_fr": "Miaogao"
            },
            {
                "value": 846,
                "label": "Yuanli",
                "label_ar": "يوانلي",
                "label_fr": "Yuanli"
            },
            {
                "value": 847,
                "label": "Shinian",
                "label_ar": "شينيان",
                "label_fr": "Shinian"
            },
            {
                "value": 848,
                "label": "Qingfeng Chengguanzhen",
                "label_ar": "كينجفينج تشينجوانزين",
                "label_fr": "Qingfeng Chengguanzhen"
            },
            {
                "value": 849,
                "label": "Liu`an",
                "label_ar": "ليوان",
                "label_fr": "Liuʻan"
            },
            {
                "value": 850,
                "label": "Yulong",
                "label_ar": "يولونغ",
                "label_fr": "Yulong"
            },
            {
                "value": 851,
                "label": "Haixing",
                "label_ar": "هايكسينغ",
                "label_fr": "Haixing"
            },
            {
                "value": 852,
                "label": "Sanjiaocheng",
                "label_ar": "سانجياوتشنغ",
                "label_fr": "Sanjiaocheng"
            },
            {
                "value": 853,
                "label": "Pinghu",
                "label_ar": "بينغو",
                "label_fr": "Pinghu"
            },
            {
                "value": 854,
                "label": "Jinling",
                "label_ar": "جينلينغ",
                "label_fr": "Jinling"
            },
            {
                "value": 855,
                "label": "Fengming",
                "label_ar": "فينغمينغ",
                "label_fr": "Fengming"
            },
            {
                "value": 856,
                "label": "Tongxiang",
                "label_ar": "تونجكسيانج",
                "label_fr": "Tongxiang"
            },
            {
                "value": 857,
                "label": "Puyuan",
                "label_ar": "بويوان",
                "label_fr": "Puyuan"
            },
            {
                "value": 858,
                "label": "Dingqiao",
                "label_ar": "Dingqiao",
                "label_fr": "Dingqiao"
            },
            {
                "value": 859,
                "label": "Yanjiang",
                "label_ar": "يانجيانغ",
                "label_fr": "Yanjiang"
            },
            {
                "value": 860,
                "label": "Wutong",
                "label_ar": "ووتونج",
                "label_fr": "Wutong"
            },
            {
                "value": 861,
                "label": "Pingchuan",
                "label_ar": "بينغتشوان",
                "label_fr": "Pingchuan"
            },
            {
                "value": 862,
                "label": "Dushu",
                "label_ar": "دوشو",
                "label_fr": "Dushu"
            },
            {
                "value": 863,
                "label": "Nanxun",
                "label_ar": "نانكسون",
                "label_fr": "Nanxun"
            },
            {
                "value": 864,
                "label": "Wuxing",
                "label_ar": "ووكسينج",
                "label_fr": "Wuxing"
            },
            {
                "value": 865,
                "label": "Yangzhou",
                "label_ar": "يانغتشو",
                "label_fr": "Yangzhou"
            },
            {
                "value": 866,
                "label": "Hongyuan",
                "label_ar": "هونغ يوان",
                "label_fr": "Hongyuan"
            },
            {
                "value": 867,
                "label": "Anji",
                "label_ar": "انجي",
                "label_fr": "Anji"
            },
            {
                "value": 868,
                "label": "Shangying",
                "label_ar": "شانغيينغ",
                "label_fr": "Shangying"
            },
            {
                "value": 869,
                "label": "Deqing",
                "label_ar": "ديكينج",
                "label_fr": "Deqing"
            },
            {
                "value": 870,
                "label": "Digang",
                "label_ar": "Digang",
                "label_fr": "Digang"
            },
            {
                "value": 871,
                "label": "Sanguan",
                "label_ar": "سانجوان",
                "label_fr": "Sanguan"
            },
            {
                "value": 872,
                "label": "Yuantong",
                "label_ar": "يوانتونغ",
                "label_fr": "Yuantong"
            },
            {
                "value": 873,
                "label": "Changxin",
                "label_ar": "تشانغشين",
                "label_fr": "Changxin"
            },
            {
                "value": 874,
                "label": "Huating",
                "label_ar": "ينفخ",
                "label_fr": "Huating"
            },
            {
                "value": 875,
                "label": "Putuoshan",
                "label_ar": "بوتوشان",
                "label_fr": "Putuoshan"
            },
            {
                "value": 876,
                "label": "Jinyuan",
                "label_ar": "جين يوان",
                "label_fr": "Jinyuan"
            },
            {
                "value": 877,
                "label": "Dinghai",
                "label_ar": "Dinghai",
                "label_fr": "Dinghai"
            },
            {
                "value": 878,
                "label": "Xiangnan",
                "label_ar": "شيانغنان",
                "label_fr": "Xiangnan"
            },
            {
                "value": 879,
                "label": "Putuo",
                "label_ar": "بوتو",
                "label_fr": "Putuo"
            },
            {
                "value": 880,
                "label": "Xintian",
                "label_ar": "كسينتيان",
                "label_fr": "Xintian"
            },
            {
                "value": 881,
                "label": "Donghuxu",
                "label_ar": "دونغوشو",
                "label_fr": "Donghuxu"
            },
            {
                "value": 882,
                "label": "Zhuji",
                "label_ar": "Zhuji",
                "label_fr": "Zhuji"
            },
            {
                "value": 883,
                "label": "Jingcheng",
                "label_ar": "جينغتشنغ",
                "label_fr": "Jingcheng"
            },
            {
                "value": 884,
                "label": "Jiangtian",
                "label_ar": "جيانغتيان",
                "label_fr": "Jiangtian"
            },
            {
                "value": 885,
                "label": "Xingchang",
                "label_ar": "Xingchang",
                "label_fr": "Xingchang"
            },
            {
                "value": 886,
                "label": "Jindou",
                "label_ar": "جيندو",
                "label_fr": "Jindou"
            },
            {
                "value": 887,
                "label": "Xinchang",
                "label_ar": "شينتشانغ",
                "label_fr": "Xinchang"
            },
            {
                "value": 888,
                "label": "Baiyun",
                "label_ar": "بايون",
                "label_fr": "Baiyun"
            },
            {
                "value": 889,
                "label": "Qianqing",
                "label_ar": "تشيانكينغ",
                "label_fr": "Qianqing"
            },
            {
                "value": 890,
                "label": "Tianchang",
                "label_ar": "تيانتشانغ",
                "label_fr": "Tianchang"
            },
            {
                "value": 891,
                "label": "Tianchi",
                "label_ar": "تيانتشي",
                "label_fr": "Tianchi"
            },
            {
                "value": 892,
                "label": "Luzhou",
                "label_ar": "لوتشو",
                "label_fr": "Luzhou"
            },
            {
                "value": 893,
                "label": "Qinjiang",
                "label_ar": "تشينجيانغ",
                "label_fr": "Qinjiang"
            },
            {
                "value": 894,
                "label": "Tianzhu",
                "label_ar": "تيانتشو",
                "label_fr": "Tianzhu"
            },
            {
                "value": 895,
                "label": "Chengguan",
                "label_ar": "شينجوان",
                "label_fr": "Chengguan"
            },
            {
                "value": 896,
                "label": "Jinhong",
                "label_ar": "جينهونغ",
                "label_fr": "Jinhong"
            },
            {
                "value": 897,
                "label": "Huaqiao",
                "label_ar": "هواشياو",
                "label_fr": "Huaqiao"
            },
            {
                "value": 898,
                "label": "Maotai",
                "label_ar": "ماوتاي",
                "label_fr": "Maotai"
            },
            {
                "value": 899,
                "label": "Hezhu",
                "label_ar": "حزهو",
                "label_fr": "Hezhu"
            },
            {
                "value": 900,
                "label": "Dahai",
                "label_ar": "داهاي",
                "label_fr": "Dahai"
            },
            {
                "value": 901,
                "label": "Shanhu",
                "label_ar": "شانهو",
                "label_fr": "Shanhu"
            },
            {
                "value": 902,
                "label": "Changle",
                "label_ar": "Changle",
                "label_fr": "Changle"
            },
            {
                "value": 903,
                "label": "Guoshang",
                "label_ar": "Guoshang",
                "label_fr": "Guoshang"
            },
            {
                "value": 904,
                "label": "Dongshen",
                "label_ar": "دونغشين",
                "label_fr": "Dongshen"
            },
            {
                "value": 905,
                "label": "Shangbu",
                "label_ar": "شانغبو",
                "label_fr": "Shangbu"
            },
            {
                "value": 906,
                "label": "Zhedong",
                "label_ar": "Zhedong",
                "label_fr": "Zhedong"
            },
            {
                "value": 907,
                "label": "Boxing",
                "label_ar": "ملاكمة",
                "label_fr": "Boxe"
            },
            {
                "value": 908,
                "label": "Tianyuan",
                "label_ar": "تيانيوان",
                "label_fr": "Tianyuan"
            },
            {
                "value": 909,
                "label": "Guodian",
                "label_ar": "جوديان",
                "label_fr": "Guodian"
            },
            {
                "value": 910,
                "label": "Linping",
                "label_ar": "لينبينج",
                "label_fr": "Linping"
            },
            {
                "value": 911,
                "label": "Meicheng",
                "label_ar": "ميتشنغ",
                "label_fr": "Meicheng"
            },
            {
                "value": 912,
                "label": "Jiyang",
                "label_ar": "جيانغ",
                "label_fr": "Jiyang"
            },
            {
                "value": 913,
                "label": "Tonglu",
                "label_ar": "تونجلو",
                "label_fr": "Tonglu"
            },
            {
                "value": 914,
                "label": "Fuchunjiang",
                "label_ar": "فوشونجيانغ",
                "label_fr": "Fuchunjiang"
            },
            {
                "value": 915,
                "label": "Qiandaohu",
                "label_ar": "Qiandaohu",
                "label_fr": "Qiandaohu"
            },
            {
                "value": 916,
                "label": "Yuhang",
                "label_ar": "يوهانغ",
                "label_fr": "Yuhang"
            },
            {
                "value": 917,
                "label": "Changsheng",
                "label_ar": "تشانغشنغ",
                "label_fr": "Changsheng"
            },
            {
                "value": 918,
                "label": "Honglin",
                "label_ar": "هونغ لين",
                "label_fr": "Honglin"
            },
            {
                "value": 919,
                "label": "Xiaoheshan",
                "label_ar": "شياوشان",
                "label_fr": "Xiaoheshan"
            },
            {
                "value": 920,
                "label": "Binjiang",
                "label_ar": "بينجيانغ",
                "label_fr": "Binjiang"
            },
            {
                "value": 921,
                "label": "Yijin",
                "label_ar": "ييجين",
                "label_fr": "Yijin"
            },
            {
                "value": 922,
                "label": "Xunxian",
                "label_ar": "Xunxian",
                "label_fr": "Xunxian"
            },
            {
                "value": 923,
                "label": "Qianshan",
                "label_ar": "تشيانشان",
                "label_fr": "Qianshan"
            },
            {
                "value": 924,
                "label": "Zhongzhou",
                "label_ar": "تشونغتشو",
                "label_fr": "Zhongzhou"
            },
            {
                "value": 925,
                "label": "Chongxian",
                "label_ar": "تشونغسيان",
                "label_fr": "Chongxian"
            },
            {
                "value": 926,
                "label": "Gongchang",
                "label_ar": "قونغتشانغ",
                "label_fr": "Gongchang"
            },
            {
                "value": 927,
                "label": "Huangyan",
                "label_ar": "هوانغيان",
                "label_fr": "Huangyan"
            },
            {
                "value": 928,
                "label": "Jiaojiang",
                "label_ar": "جياوجيانغ",
                "label_fr": "Jiaojiang"
            },
            {
                "value": 929,
                "label": "Wenling",
                "label_ar": "ونلينغ",
                "label_fr": "Wenling"
            },
            {
                "value": 930,
                "label": "Xindu",
                "label_ar": "شيندو",
                "label_fr": "Xindu"
            },
            {
                "value": 931,
                "label": "Sili",
                "label_ar": "سيلي",
                "label_fr": "Sili"
            },
            {
                "value": 932,
                "label": "Luqiao",
                "label_ar": "Luqiao",
                "label_fr": "Luqiao"
            },
            {
                "value": 933,
                "label": "Baoshan",
                "label_ar": "باوشان",
                "label_fr": "Baoshan"
            },
            {
                "value": 934,
                "label": "Yanjing",
                "label_ar": "يانجينغ",
                "label_fr": "Yanjing"
            },
            {
                "value": 935,
                "label": "Jinqingzhen",
                "label_ar": "Jinqingzhen",
                "label_fr": "Jinqingzhen"
            },
            {
                "value": 936,
                "label": "Gamlung",
                "label_ar": "جاملونج",
                "label_fr": "Gamlung"
            },
            {
                "value": 937,
                "label": "Yiwu",
                "label_ar": "ييوو",
                "label_fr": "Yiwu"
            },
            {
                "value": 938,
                "label": "Dongyang",
                "label_ar": "دونغيانغ",
                "label_fr": "Dongyang"
            },
            {
                "value": 939,
                "label": "Yongkang",
                "label_ar": "يونغ كانغ",
                "label_fr": "Yongkang"
            },
            {
                "value": 940,
                "label": "Lanxi",
                "label_ar": "لانكسي",
                "label_fr": "Lanxi"
            },
            {
                "value": 941,
                "label": "Wuyi",
                "label_ar": "وويى",
                "label_fr": "Wuyi"
            },
            {
                "value": 942,
                "label": "Wanjia",
                "label_ar": "وانجيا",
                "label_fr": "Wanjia"
            },
            {
                "value": 943,
                "label": "Fotang",
                "label_ar": "فوتانغ",
                "label_fr": "Fotang"
            },
            {
                "value": 944,
                "label": "Yuhai",
                "label_ar": "يوهاي",
                "label_fr": "Yuhai"
            },
            {
                "value": 945,
                "label": "Yiting",
                "label_ar": "يتينغ",
                "label_fr": "Yiting"
            },
            {
                "value": 946,
                "label": "Puyang",
                "label_ar": "بويانغ",
                "label_fr": "Puyang"
            },
            {
                "value": 947,
                "label": "Longfeng",
                "label_ar": "لونغ فنغ",
                "label_fr": "Longfeng"
            },
            {
                "value": 948,
                "label": "Yueliangwan",
                "label_ar": "يويليانجوان",
                "label_fr": "Yueliangwan"
            },
            {
                "value": 949,
                "label": "Renhe",
                "label_ar": "رينهي",
                "label_fr": "Renhe"
            },
            {
                "value": 950,
                "label": "Yangfen",
                "label_ar": "يانغفن",
                "label_fr": "Yangfen"
            },
            {
                "value": 951,
                "label": "Youjia",
                "label_ar": "يوجيا",
                "label_fr": "Youjia"
            },
            {
                "value": 952,
                "label": "Hanshang",
                "label_ar": "هانشانغ",
                "label_fr": "Hanshang"
            },
            {
                "value": 953,
                "label": "Jindu",
                "label_ar": "جيندو",
                "label_fr": "Jindu"
            },
            {
                "value": 954,
                "label": "Junping",
                "label_ar": "Junping",
                "label_fr": "Junping"
            },
            {
                "value": 955,
                "label": "Aoma",
                "label_ar": "أوما",
                "label_fr": "Aoma"
            },
            {
                "value": 956,
                "label": "Yinliang",
                "label_ar": "ينليانج",
                "label_fr": "Yinliang"
            },
            {
                "value": 957,
                "label": "Lijing",
                "label_ar": "بكين",
                "label_fr": "Lijing"
            },
            {
                "value": 958,
                "label": "Renhou",
                "label_ar": "Renhou",
                "label_fr": "Renhou"
            },
            {
                "value": 959,
                "label": "Wangshang",
                "label_ar": "وانجشانج",
                "label_fr": "Wangshang"
            },
            {
                "value": 960,
                "label": "Pan'an",
                "label_ar": "بانان",
                "label_fr": "Pan'an"
            },
            {
                "value": 961,
                "label": "Longchuan",
                "label_ar": "لونغتشوان",
                "label_fr": "Longchuan"
            },
            {
                "value": 962,
                "label": "Hengzhou",
                "label_ar": "هنغتشو",
                "label_fr": "Hengzhou"
            },
            {
                "value": 963,
                "label": "Lianyuan",
                "label_ar": "ليانيوان",
                "label_fr": "Lianyuan"
            },
            {
                "value": 964,
                "label": "Jinlun",
                "label_ar": "جينلون",
                "label_fr": "Jinlun"
            },
            {
                "value": 965,
                "label": "Qiaodou",
                "label_ar": "كياودو",
                "label_fr": "Qiaodou"
            },
            {
                "value": 966,
                "label": "Shizhu",
                "label_ar": "شيزو",
                "label_fr": "Shizhu"
            },
            {
                "value": 967,
                "label": "Huajie",
                "label_ar": "هواجي",
                "label_fr": "Huajie"
            },
            {
                "value": 968,
                "label": "Xixi",
                "label_ar": "شيشي",
                "label_fr": "Xixi"
            },
            {
                "value": 969,
                "label": "Hengdian",
                "label_ar": "هنغديان",
                "label_fr": "Hengdian"
            },
            {
                "value": 970,
                "label": "Dongcheng",
                "label_ar": "دونغتشنغ",
                "label_fr": "Dongcheng"
            },
            {
                "value": 971,
                "label": "Dongdu",
                "label_ar": "دونجدو",
                "label_fr": "Dongdu"
            },
            {
                "value": 972,
                "label": "Fusheng",
                "label_ar": "فوشينغ",
                "label_fr": "Fusheng"
            },
            {
                "value": 973,
                "label": "Yongjin",
                "label_ar": "يونغجين",
                "label_fr": "Yongjin"
            },
            {
                "value": 974,
                "label": "Youyi",
                "label_ar": "Youyi",
                "label_fr": "Youyi"
            },
            {
                "value": 975,
                "label": "Yuchi",
                "label_ar": "يوشي",
                "label_fr": "Yuchi"
            },
            {
                "value": 976,
                "label": "Haiyang",
                "label_ar": "هاييانغ",
                "label_fr": "Haiyang"
            },
            {
                "value": 977,
                "label": "Tashi",
                "label_ar": "تاشي",
                "label_fr": "Tashi"
            },
            {
                "value": 978,
                "label": "Jiya",
                "label_ar": "جيا",
                "label_fr": "Jiya"
            },
            {
                "value": 979,
                "label": "Zhangqiu",
                "label_ar": "تشانغكيو",
                "label_fr": "Zhangqiu"
            },
            {
                "value": 980,
                "label": "Shangdong",
                "label_ar": "شانغدونغ",
                "label_fr": "Shangdong"
            },
            {
                "value": 981,
                "label": "Zoucheng",
                "label_ar": "زوتشنغ",
                "label_fr": "Zoucheng"
            },
            {
                "value": 982,
                "label": "Jining",
                "label_ar": "جيننج",
                "label_fr": "Jining"
            },
            {
                "value": 983,
                "label": "Linyi",
                "label_ar": "ليني",
                "label_fr": "Linyi"
            },
            {
                "value": 984,
                "label": "Feixian",
                "label_ar": "فيكسيان",
                "label_fr": "Feixian"
            },
            {
                "value": 985,
                "label": "Yishui",
                "label_ar": "يشوي",
                "label_fr": "Yishui"
            },
            {
                "value": 986,
                "label": "Zaozhuang",
                "label_ar": "Zaozhuang",
                "label_fr": "Zaozhuang"
            },
            {
                "value": 987,
                "label": "Zibo",
                "label_ar": "تسيبو",
                "label_fr": "Zibo"
            },
            {
                "value": 988,
                "label": "Laiwu",
                "label_ar": "Laiwu",
                "label_fr": "Laiwu"
            },
            {
                "value": 989,
                "label": "Jiyang",
                "label_ar": "جيانغ",
                "label_fr": "Jiyang"
            },
            {
                "value": 990,
                "label": "Yayu",
                "label_ar": "يايو",
                "label_fr": "Yayu"
            },
            {
                "value": 991,
                "label": "Zhenzhuquan",
                "label_ar": "Zhenzhuquan",
                "label_fr": "Zhenzhuquan"
            },
            {
                "value": 992,
                "label": "Changzhi",
                "label_ar": "تشانغتشى",
                "label_fr": "Changzhi"
            },
            {
                "value": 993,
                "label": "Changping",
                "label_ar": "تشانغبينغ",
                "label_fr": "Changping"
            },
            {
                "value": 994,
                "label": "Daxing",
                "label_ar": "داشينغ",
                "label_fr": "Daxing"
            },
            {
                "value": 995,
                "label": "Fuling",
                "label_ar": "فولينغ",
                "label_fr": "Fuling"
            },
            {
                "value": 996,
                "label": "Xiangyuan",
                "label_ar": "شيانغ يوان",
                "label_fr": "Xiangyuan"
            },
            {
                "value": 997,
                "label": "Shiji",
                "label_ar": "شيجي",
                "label_fr": "Shiji"
            },
            {
                "value": 998,
                "label": "Changshan",
                "label_ar": "تشانغشان",
                "label_fr": "Changshan"
            },
            {
                "value": 999,
                "label": "Shangzhou",
                "label_ar": "شانغتشو",
                "label_fr": "Shangzhou"
            },
            {
                "value": 1000,
                "label": "Kaihua",
                "label_ar": "كيهوا",
                "label_fr": "Kaihua"
            },
            {
                "value": 1001,
                "label": "Jiangshan",
                "label_ar": "جيانغشان",
                "label_fr": "Jiangshan"
            },
            {
                "value": 1002,
                "label": "Longzhou",
                "label_ar": "لونغتشو",
                "label_fr": "Longzhou"
            },
            {
                "value": 1003,
                "label": "Citai",
                "label_ar": "سيتاي",
                "label_fr": "Citai"
            },
            {
                "value": 1004,
                "label": "Jinyang",
                "label_ar": "جينيانغ",
                "label_fr": "Jinyang"
            },
            {
                "value": 1005,
                "label": "Yanhai",
                "label_ar": "يانهاي",
                "label_fr": "Yanhai"
            },
            {
                "value": 1006,
                "label": "Xintai",
                "label_ar": "زينتاى",
                "label_fr": "Xintai"
            },
            {
                "value": 1007,
                "label": "Yinjiang",
                "label_ar": "ينجيانغ",
                "label_fr": "Yinjiang"
            },
            {
                "value": 1008,
                "label": "Guxiang",
                "label_ar": "Guxiang",
                "label_fr": "Guxiang"
            },
            {
                "value": 1009,
                "label": "Yindian",
                "label_ar": "ينديان",
                "label_fr": "Yindian"
            },
            {
                "value": 1010,
                "label": "Yiwu",
                "label_ar": "ييوو",
                "label_fr": "Yiwu"
            },
            {
                "value": 1011,
                "label": "Qujiang",
                "label_ar": "تشوجيانغ",
                "label_fr": "Qujiang"
            },
            {
                "value": 1012,
                "label": "Juhua",
                "label_ar": "جوهوا",
                "label_fr": "Juhua"
            },
            {
                "value": 1013,
                "label": "Zhicheng",
                "label_ar": "تشيتشنغ",
                "label_fr": "Zhicheng"
            },
            {
                "value": 1014,
                "label": "Ningde",
                "label_ar": "نينغده",
                "label_fr": "Ningde"
            },
            {
                "value": 1015,
                "label": "Meizhou",
                "label_ar": "ميتشو",
                "label_fr": "Meizhou"
            },
            {
                "value": 1016,
                "label": "Shaowu",
                "label_ar": "شاوو",
                "label_fr": "Shaowu"
            },
            {
                "value": 1017,
                "label": "Zhanghou",
                "label_ar": "Zhanghou",
                "label_fr": "Zhanghou"
            },
            {
                "value": 1018,
                "label": "Nan'an Qu",
                "label_ar": "نانان تشو",
                "label_fr": "Nan'an Qu"
            },
            {
                "value": 1019,
                "label": "Xuexi",
                "label_ar": "Xuexi",
                "label_fr": "Xuexi"
            },
            {
                "value": 1020,
                "label": "Hanjiang",
                "label_ar": "هانجيانغ",
                "label_fr": "Hanjiang"
            },
            {
                "value": 1021,
                "label": "Huaihe",
                "label_ar": "هوايخه",
                "label_fr": "Huaihe"
            },
            {
                "value": 1022,
                "label": "Bengbu",
                "label_ar": "بنجبو",
                "label_fr": "Bengbu"
            },
            {
                "value": 1023,
                "label": "Dangshan",
                "label_ar": "دانغشان",
                "label_fr": "Dangshan"
            },
            {
                "value": 1024,
                "label": "Shecheng",
                "label_ar": "شيشنغ",
                "label_fr": "Shecheng"
            },
            {
                "value": 1025,
                "label": "Huangshan",
                "label_ar": "هوانغشان",
                "label_fr": "Huangshan"
            },
            {
                "value": 1026,
                "label": "Huaibei",
                "label_ar": "هوايبي",
                "label_fr": "Huaibei"
            },
            {
                "value": 1027,
                "label": "Suixi",
                "label_ar": "Suixi",
                "label_fr": "Suixi"
            },
            {
                "value": 1028,
                "label": "Tongling",
                "label_ar": "تونغلينغ",
                "label_fr": "Tongling"
            },
            {
                "value": 1029,
                "label": "Xuancheng",
                "label_ar": "شوانتشنغ",
                "label_fr": "Xuancheng"
            },
            {
                "value": 1030,
                "label": "Huoshan",
                "label_ar": "هووشان",
                "label_fr": "Huoshan"
            },
            {
                "value": 1031,
                "label": "Chizhou",
                "label_ar": "تشيتشو",
                "label_fr": "Chizhou"
            },
            {
                "value": 1032,
                "label": "Bozhou",
                "label_ar": "Bozhou",
                "label_fr": "Bozhou"
            },
            {
                "value": 1033,
                "label": "Boshan",
                "label_ar": "بوشان",
                "label_fr": "Boshan"
            },
            {
                "value": 1034,
                "label": "Weihai",
                "label_ar": "ويهاي",
                "label_fr": "Weihai"
            },
            {
                "value": 1035,
                "label": "Quanzhou",
                "label_ar": "تشيوانتشو",
                "label_fr": "Quanzhou"
            },
            {
                "value": 1036,
                "label": "Qi'ao",
                "label_ar": "تشياو",
                "label_fr": "Qi'ao"
            },
            {
                "value": 1037,
                "label": "Lingcheng",
                "label_ar": "Lingcheng",
                "label_fr": "Lingcheng"
            },
            {
                "value": 1038,
                "label": "Xinhuang",
                "label_ar": "شينهوانغ",
                "label_fr": "Xinhuang"
            },
            {
                "value": 1039,
                "label": "Gaiyu",
                "label_ar": "غايو",
                "label_fr": "Gaiyu"
            },
            {
                "value": 1040,
                "label": "Hanzhong",
                "label_ar": "هانتشونغ",
                "label_fr": "Hanzhong"
            },
            {
                "value": 1041,
                "label": "Yan'an",
                "label_ar": "يانان",
                "label_fr": "Yan'an"
            },
            {
                "value": 1042,
                "label": "Fugu",
                "label_ar": "فوجو",
                "label_fr": "Fugu"
            },
            {
                "value": 1043,
                "label": "Longnan",
                "label_ar": "لونغنان",
                "label_fr": "Longnan"
            },
            {
                "value": 1044,
                "label": "Xiushui",
                "label_ar": "شيوشوي",
                "label_fr": "Xiushui"
            },
            {
                "value": 1045,
                "label": "Puyang",
                "label_ar": "بويانغ",
                "label_fr": "Puyang"
            },
            {
                "value": 1046,
                "label": "Xiaogan",
                "label_ar": "شياوجان",
                "label_fr": "Xiaogan"
            },
            {
                "value": 1047,
                "label": "Qiqihar",
                "label_ar": "تشيتشيهار",
                "label_fr": "Qiqihar"
            },
            {
                "value": 1048,
                "label": "Lung",
                "label_ar": "رئة",
                "label_fr": "Poumon"
            },
            {
                "value": 1049,
                "label": "Sichuan",
                "label_ar": "سيتشوان",
                "label_fr": "Sichuan"
            },
            {
                "value": 1050,
                "label": "Leshan",
                "label_ar": "ليشان",
                "label_fr": "Leshan"
            },
            {
                "value": 1051,
                "label": "Bazhou",
                "label_ar": "باتشو",
                "label_fr": "Bazhou"
            },
            {
                "value": 1052,
                "label": "Mianyang",
                "label_ar": "ميانيانغ",
                "label_fr": "Mianyang"
            },
            {
                "value": 1053,
                "label": "Meishan",
                "label_ar": "ميشان",
                "label_fr": "Meishan"
            },
            {
                "value": 1054,
                "label": "Suining",
                "label_ar": "شوان",
                "label_fr": "Suining"
            },
            {
                "value": 1055,
                "label": "Youshi",
                "label_ar": "يوشي",
                "label_fr": "Youshi"
            },
            {
                "value": 1056,
                "label": "Baoxing",
                "label_ar": "باوكسينج",
                "label_fr": "Baoxing"
            },
            {
                "value": 1057,
                "label": "Rongshan",
                "label_ar": "رونغشان",
                "label_fr": "Rongshan"
            },
            {
                "value": 1058,
                "label": "Tangtou",
                "label_ar": "تانجتو",
                "label_fr": "Tangtou"
            },
            {
                "value": 1059,
                "label": "Nanhai",
                "label_ar": "نانهاي",
                "label_fr": "Nanhai"
            },
            {
                "value": 1060,
                "label": "Nanzhuang",
                "label_ar": "نانتشوانغ",
                "label_fr": "Nanzhuang"
            },
            {
                "value": 1061,
                "label": "Danzao",
                "label_ar": "دانزاو",
                "label_fr": "Danzao"
            },
            {
                "value": 1062,
                "label": "Chaozhou",
                "label_ar": "تشاوتشو",
                "label_fr": "Chaozhou"
            },
            {
                "value": 1063,
                "label": "Nanfang",
                "label_ar": "نانفانغ",
                "label_fr": "Nanfang"
            },
            {
                "value": 1064,
                "label": "Jinli",
                "label_ar": "جينلي",
                "label_fr": "Jinli"
            },
            {
                "value": 1065,
                "label": "Heping",
                "label_ar": "Heping",
                "label_fr": "Heping"
            },
            {
                "value": 1066,
                "label": "Lantang",
                "label_ar": "لانتانغ",
                "label_fr": "Lantang"
            },
            {
                "value": 1067,
                "label": "Lianping",
                "label_ar": "ليانبينج",
                "label_fr": "Lianping"
            },
            {
                "value": 1068,
                "label": "Puning",
                "label_ar": "بونينغ",
                "label_fr": "Punition"
            },
            {
                "value": 1069,
                "label": "Puning",
                "label_ar": "بونينغ",
                "label_fr": "Punition"
            },
            {
                "value": 1070,
                "label": "Jiexi",
                "label_ar": "جيكسي",
                "label_fr": "Jiexi"
            },
            {
                "value": 1071,
                "label": "Huicheng",
                "label_ar": "هويتشنغ",
                "label_fr": "Huicheng"
            },
            {
                "value": 1072,
                "label": "Fengshun",
                "label_ar": "فنغشون",
                "label_fr": "Fengshun"
            },
            {
                "value": 1073,
                "label": "Pingyuan",
                "label_ar": "بينغيوان",
                "label_fr": "Pingyuan"
            },
            {
                "value": 1074,
                "label": "Shaoguan",
                "label_ar": "شاوقوان",
                "label_fr": "Shaoguan"
            },
            {
                "value": 1075,
                "label": "Jiangwan",
                "label_ar": "جيانغوان",
                "label_fr": "Jiangwan"
            },
            {
                "value": 1076,
                "label": "Licheng",
                "label_ar": "ليتشنغ",
                "label_fr": "Licheng"
            },
            {
                "value": 1077,
                "label": "Xindi",
                "label_ar": "شيندي",
                "label_fr": "Xindi"
            },
            {
                "value": 1078,
                "label": "Xiangxi",
                "label_ar": "شيانغشى",
                "label_fr": "Xiangxi"
            },
            {
                "value": 1079,
                "label": "Luohu",
                "label_ar": "لوهو",
                "label_fr": "Luohu"
            },
            {
                "value": 1080,
                "label": "Renmin",
                "label_ar": "الرينمين",
                "label_fr": "Renmin"
            },
            {
                "value": 1081,
                "label": "Dawang",
                "label_ar": "Dawang",
                "label_fr": "Dawang"
            },
            {
                "value": 1082,
                "label": "Luofang",
                "label_ar": "لوفانغ",
                "label_fr": "Luofang"
            },
            {
                "value": 1083,
                "label": "Liannan",
                "label_ar": "ليانان",
                "label_fr": "Liannan"
            },
            {
                "value": 1084,
                "label": "Jieyang",
                "label_ar": "جييانغ",
                "label_fr": "Jieyang"
            },
            {
                "value": 1085,
                "label": "Xunyi Chengguanzhen",
                "label_ar": "Xunyi Chengguanzhen",
                "label_fr": "Xunyi Chengguanzhen"
            },
            {
                "value": 1086,
                "label": "Shunde",
                "label_ar": "شوند",
                "label_fr": "Shunde"
            },
            {
                "value": 1087,
                "label": "Yangjiang",
                "label_ar": "يانغجيانغ",
                "label_fr": "Yangjiang"
            },
            {
                "value": 1088,
                "label": "Nanzhao",
                "label_ar": "Nanzhao",
                "label_fr": "Nanzhao"
            },
            {
                "value": 1089,
                "label": "Chaohu",
                "label_ar": "تشاوهو",
                "label_fr": "Chaohu"
            },
            {
                "value": 1090,
                "label": "Zijin",
                "label_ar": "تسيجين",
                "label_fr": "Zijin"
            },
            {
                "value": 1091,
                "label": "Heyuan",
                "label_ar": "هيوان",
                "label_fr": "Heyuan"
            },
            {
                "value": 1092,
                "label": "Gaozhou",
                "label_ar": "جاوتشو",
                "label_fr": "Gaozhou"
            },
            {
                "value": 1093,
                "label": "Maoming",
                "label_ar": "ماومينغ",
                "label_fr": "Maoming"
            },
            {
                "value": 1094,
                "label": "Gaoji",
                "label_ar": "جاوجي",
                "label_fr": "Gaoji"
            },
            {
                "value": 1095,
                "label": "Suqian",
                "label_ar": "سوكيان",
                "label_fr": "Suqian"
            },
            {
                "value": 1096,
                "label": "Sha'an",
                "label_ar": "شان",
                "label_fr": "Sha'an"
            },
            {
                "value": 1097,
                "label": "Xiaojiang",
                "label_ar": "شياوجيانغ",
                "label_fr": "Xiaojiang"
            },
            {
                "value": 1098,
                "label": "Ruili",
                "label_ar": "رويلي",
                "label_fr": "Ruili"
            },
            {
                "value": 1099,
                "label": "Tangxia",
                "label_ar": "تانغشيا",
                "label_fr": "Tangxia"
            },
            {
                "value": 1100,
                "label": "Baixiang",
                "label_ar": "بايكسيانج",
                "label_fr": "Baixiang"
            },
            {
                "value": 1101,
                "label": "Huangdu",
                "label_ar": "هوانغدو",
                "label_fr": "Huangdu"
            },
            {
                "value": 1102,
                "label": "Daishan",
                "label_ar": "ديشان",
                "label_fr": "Daishan"
            },
            {
                "value": 1103,
                "label": "Daidong",
                "label_ar": "ديدونغ",
                "label_fr": "Daidong"
            },
            {
                "value": 1104,
                "label": "Daju",
                "label_ar": "داجو",
                "label_fr": "Daju"
            },
            {
                "value": 1105,
                "label": "Maoba",
                "label_ar": "ماوبا",
                "label_fr": "Maoba"
            },
            {
                "value": 1106,
                "label": "Qushan",
                "label_ar": "قوشان",
                "label_fr": "Qushan"
            },
            {
                "value": 1107,
                "label": "Caiyuan",
                "label_ar": "كايوان",
                "label_fr": "Caiyuan"
            },
            {
                "value": 1108,
                "label": "Zhang'ancun",
                "label_ar": "Zhang'ancun",
                "label_fr": "Zhang'ancun"
            },
            {
                "value": 1109,
                "label": "Cezi",
                "label_ar": "Cezi",
                "label_fr": "Cezi"
            },
            {
                "value": 1110,
                "label": "Zhanmao",
                "label_ar": "زانماو",
                "label_fr": "Zhanmao"
            },
            {
                "value": 1111,
                "label": "Luomen",
                "label_ar": "لومين",
                "label_fr": "Luomen"
            },
            {
                "value": 1112,
                "label": "Luhua",
                "label_ar": "لوهوا",
                "label_fr": "Luhua"
            },
            {
                "value": 1113,
                "label": "Huanghua",
                "label_ar": "هوانغوا",
                "label_fr": "Huanghua"
            },
            {
                "value": 1114,
                "label": "Baixin",
                "label_ar": "بايكسين",
                "label_fr": "Baixin"
            },
            {
                "value": 1115,
                "label": "Ximeng",
                "label_ar": "Ximeng",
                "label_fr": "Ximeng"
            },
            {
                "value": 1116,
                "label": "Suzhuang",
                "label_ar": "سوزهوانغ",
                "label_fr": "Suzhuang"
            },
            {
                "value": 1117,
                "label": "Jiguang",
                "label_ar": "جيغوانغ",
                "label_fr": "Jiguang"
            },
            {
                "value": 1118,
                "label": "Zhenhua",
                "label_ar": "زينهوا",
                "label_fr": "Zhenhua"
            },
            {
                "value": 1119,
                "label": "Jingning Chengguanzhen",
                "label_ar": "جينغ نينج تشينجوانزين",
                "label_fr": "Jingning Chengguanzhen"
            },
            {
                "value": 1120,
                "label": "Jinhe",
                "label_ar": "جينهي",
                "label_fr": "Jinhe"
            },
            {
                "value": 1121,
                "label": "Lake",
                "label_ar": "بحيرة",
                "label_fr": "Lac"
            },
            {
                "value": 1122,
                "label": "Huafeng",
                "label_ar": "Huafeng",
                "label_fr": "Huafeng"
            },
            {
                "value": 1123,
                "label": "Haijing",
                "label_ar": "هايجينغ",
                "label_fr": "Haijing"
            },
            {
                "value": 1124,
                "label": "Ninghai",
                "label_ar": "نينغهاى",
                "label_fr": "Ninghai"
            },
            {
                "value": 1125,
                "label": "Xianrenqiao",
                "label_ar": "Xianrenqiao",
                "label_fr": "Xianrenqiao"
            },
            {
                "value": 1126,
                "label": "Junpu",
                "label_ar": "جونبو",
                "label_fr": "Junpu"
            },
            {
                "value": 1127,
                "label": "Nanxi",
                "label_ar": "نانشى",
                "label_fr": "Nanxi"
            },
            {
                "value": 1128,
                "label": "Dongli",
                "label_ar": "Dongli",
                "label_fr": "Dongli"
            },
            {
                "value": 1129,
                "label": "Zhili",
                "label_ar": "تشيلي",
                "label_fr": "Zhili"
            },
            {
                "value": 1130,
                "label": "Yalan",
                "label_ar": "يالان",
                "label_fr": "Yalan"
            },
            {
                "value": 1131,
                "label": "Sanmen",
                "label_ar": "سانمن",
                "label_fr": "Sanmen"
            },
            {
                "value": 1132,
                "label": "Tiantai Chengguanzhen",
                "label_ar": "تيانتاى تشينجوانزين",
                "label_fr": "Tiantai Chengguanzhen"
            },
            {
                "value": 1133,
                "label": "Yuhuan",
                "label_ar": "يوهوان",
                "label_fr": "Yuhuan"
            },
            {
                "value": 1134,
                "label": "Qingma",
                "label_ar": "تشينغما",
                "label_fr": "Qingma"
            },
            {
                "value": 1135,
                "label": "Gucheng",
                "label_ar": "جوتشينج",
                "label_fr": "Gucheng"
            },
            {
                "value": 1136,
                "label": "Puqing",
                "label_ar": "بوكينج",
                "label_fr": "Puqing"
            },
            {
                "value": 1137,
                "label": "Shashan",
                "label_ar": "ششان",
                "label_fr": "Shashan"
            },
            {
                "value": 1138,
                "label": "Donghai",
                "label_ar": "دونغهاي",
                "label_fr": "Donghai"
            },
            {
                "value": 1139,
                "label": "Xiasha",
                "label_ar": "شياشا",
                "label_fr": "Xiasha"
            },
            {
                "value": 1140,
                "label": "Beichan",
                "label_ar": "بيشان",
                "label_fr": "Beichan"
            },
            {
                "value": 1141,
                "label": "Wanbao",
                "label_ar": "وانباو",
                "label_fr": "Wanbao"
            },
            {
                "value": 1142,
                "label": "Shangye",
                "label_ar": "شانجى",
                "label_fr": "Shangye"
            },
            {
                "value": 1143,
                "label": "Penglai",
                "label_ar": "بنغلاي",
                "label_fr": "Penglai"
            },
            {
                "value": 1144,
                "label": "Gaoting",
                "label_ar": "القياس",
                "label_fr": "Gaoting"
            },
            {
                "value": 1145,
                "label": "Fudao",
                "label_ar": "فوداو",
                "label_fr": "Fudao"
            },
            {
                "value": 1146,
                "label": "Daixi",
                "label_ar": "دايكسي",
                "label_fr": "Daixi"
            },
            {
                "value": 1147,
                "label": "Pengshan",
                "label_ar": "بنغشان",
                "label_fr": "Pengshan"
            },
            {
                "value": 1148,
                "label": "Shilou",
                "label_ar": "شيلو",
                "label_fr": "Shilou"
            },
            {
                "value": 1149,
                "label": "Xinjian",
                "label_ar": "شينجيان",
                "label_fr": "Xinjian"
            },
            {
                "value": 1150,
                "label": "Panxi",
                "label_ar": "بانكسي",
                "label_fr": "Panxi"
            },
            {
                "value": 1151,
                "label": "Xiandu",
                "label_ar": "زياندو",
                "label_fr": "Xiandu"
            },
            {
                "value": 1152,
                "label": "Qingquan",
                "label_ar": "تشينغتشوان",
                "label_fr": "Qingquan"
            },
            {
                "value": 1153,
                "label": "Huimin",
                "label_ar": "هويمين",
                "label_fr": "Huimin"
            },
            {
                "value": 1154,
                "label": "Zhapu",
                "label_ar": "زابو",
                "label_fr": "Zhapu"
            },
            {
                "value": 1155,
                "label": "Maodun",
                "label_ar": "مودون",
                "label_fr": "Maodun"
            },
            {
                "value": 1156,
                "label": "Qiaoting",
                "label_ar": "Qiaoting",
                "label_fr": "Qiaoting"
            },
            {
                "value": 1157,
                "label": "Ziyang",
                "label_ar": "زيانج",
                "label_fr": "Ziyang"
            },
            {
                "value": 1158,
                "label": "Qingshan",
                "label_ar": "تشينغشان",
                "label_fr": "Qingshan"
            },
            {
                "value": 1159,
                "label": "Guangming",
                "label_ar": "قوانغمينغ",
                "label_fr": "Guangming"
            },
            {
                "value": 1160,
                "label": "Fangxian Chengguanzhen",
                "label_ar": "Fangxian Chengguanzhen",
                "label_fr": "Fangxian Chengguanzhen"
            },
            {
                "value": 1161,
                "label": "Gushi",
                "label_ar": "جوشي",
                "label_fr": "Gushi"
            },
            {
                "value": 1162,
                "label": "Huzhen",
                "label_ar": "هوتشين",
                "label_fr": "Huzhen"
            },
            {
                "value": 1163,
                "label": "Shuhong",
                "label_ar": "شو هونغ",
                "label_fr": "Shuhong"
            },
            {
                "value": 1164,
                "label": "Wuyang",
                "label_ar": "ويانغ",
                "label_fr": "Wuyang"
            },
            {
                "value": 1165,
                "label": "Hushan",
                "label_ar": "حوشان",
                "label_fr": "Hushan"
            },
            {
                "value": 1166,
                "label": "Deyang",
                "label_ar": "ديانغ",
                "label_fr": "Deyang"
            },
            {
                "value": 1167,
                "label": "Neijiang",
                "label_ar": "نيجيانغ",
                "label_fr": "Neijiang"
            },
            {
                "value": 1168,
                "label": "Nanchong",
                "label_ar": "نانتشونغ",
                "label_fr": "Nanchong"
            },
            {
                "value": 1169,
                "label": "Yucheng",
                "label_ar": "يوتشنغ",
                "label_fr": "Yucheng"
            },
            {
                "value": 1170,
                "label": "Dazhou",
                "label_ar": "داتشو",
                "label_fr": "Dazhou"
            },
            {
                "value": 1171,
                "label": "Xichang",
                "label_ar": "شيتشانغ",
                "label_fr": "Xichang"
            },
            {
                "value": 1172,
                "label": "Taoshan",
                "label_ar": "تاوشان",
                "label_fr": "Taoshan"
            },
            {
                "value": 1173,
                "label": "Xiabancheng",
                "label_ar": "شيابانتشنغ",
                "label_fr": "Xiabancheng"
            },
            {
                "value": 1174,
                "label": "Dazu",
                "label_ar": "دازو",
                "label_fr": "Dazu"
            },
            {
                "value": 1175,
                "label": "Gunan",
                "label_ar": "جونان",
                "label_fr": "Gunan"
            },
            {
                "value": 1176,
                "label": "Heliang",
                "label_ar": "هيليانج",
                "label_fr": "Heliang"
            },
            {
                "value": 1177,
                "label": "Futian",
                "label_ar": "فوتيان",
                "label_fr": "Futian"
            },
            {
                "value": 1178,
                "label": "Liangping",
                "label_ar": "ليانغبينغ",
                "label_fr": "Liangping"
            },
            {
                "value": 1179,
                "label": "Daotou",
                "label_ar": "داوتو",
                "label_fr": "Daotou"
            },
            {
                "value": 1180,
                "label": "Wuxia",
                "label_ar": "وشيا",
                "label_fr": "Wuxia"
            },
            {
                "value": 1181,
                "label": "Wushan",
                "label_ar": "وشان",
                "label_fr": "Wushan"
            },
            {
                "value": 1182,
                "label": "Houxiangcheng",
                "label_ar": "هوشيانغتشنغ",
                "label_fr": "Houxiangcheng"
            },
            {
                "value": 1183,
                "label": "Xihu",
                "label_ar": "Xihu",
                "label_fr": "Xihu"
            },
            {
                "value": 1184,
                "label": "Lushang",
                "label_ar": "لوشانغ",
                "label_fr": "Lushang"
            },
            {
                "value": 1185,
                "label": "Xiaogang",
                "label_ar": "شياوجانغ",
                "label_fr": "Xiaogang"
            },
            {
                "value": 1186,
                "label": "Weibo",
                "label_ar": "ويبو",
                "label_fr": "Weibo"
            },
            {
                "value": 1187,
                "label": "Daqiao",
                "label_ar": "دقياو",
                "label_fr": "Daqiao"
            },
            {
                "value": 1188,
                "label": "Yinxin",
                "label_ar": "ينكسين",
                "label_fr": "Yinxin"
            },
            {
                "value": 1189,
                "label": "Kangda",
                "label_ar": "كانجدا",
                "label_fr": "Kangda"
            },
            {
                "value": 1190,
                "label": "Andong",
                "label_ar": "أندونج",
                "label_fr": "Andong"
            },
            {
                "value": 1191,
                "label": "Guancheng",
                "label_ar": "Guancheng",
                "label_fr": "Guancheng"
            },
            {
                "value": 1192,
                "label": "Moushan",
                "label_ar": "موشان",
                "label_fr": "Moushan"
            },
            {
                "value": 1193,
                "label": "Qitai",
                "label_ar": "Qitai",
                "label_fr": "Qitai"
            },
            {
                "value": 1194,
                "label": "Jinghua",
                "label_ar": "جينغهوا",
                "label_fr": "Jinghua"
            },
            {
                "value": 1195,
                "label": "Huayuan",
                "label_ar": "هوايوان",
                "label_fr": "Huayuan"
            },
            {
                "value": 1196,
                "label": "Hongtang",
                "label_ar": "هونغتانغ",
                "label_fr": "Hongtang"
            },
            {
                "value": 1197,
                "label": "Qiaoxia",
                "label_ar": "كياوكسيا",
                "label_fr": "Qiaoxie"
            },
            {
                "value": 1198,
                "label": "Shangwu",
                "label_ar": "شانغوو",
                "label_fr": "Shangwu"
            },
            {
                "value": 1199,
                "label": "Shengping",
                "label_ar": "Shengping",
                "label_fr": "Shengping"
            },
            {
                "value": 1200,
                "label": "Jinhu",
                "label_ar": "جينهو",
                "label_fr": "Jinhu"
            },
            {
                "value": 1201,
                "label": "Shangwang",
                "label_ar": "شانغوانغ",
                "label_fr": "Shangwang"
            },
            {
                "value": 1202,
                "label": "Taoshan",
                "label_ar": "تاوشان",
                "label_fr": "Taoshan"
            },
            {
                "value": 1203,
                "label": "Baotian",
                "label_ar": "باوتيان",
                "label_fr": "Baotian"
            },
            {
                "value": 1204,
                "label": "Hai'an",
                "label_ar": "هايان",
                "label_fr": "Hai'an"
            },
            {
                "value": 1205,
                "label": "Xinqiao",
                "label_ar": "شينتشياو",
                "label_fr": "Xinqiao"
            },
            {
                "value": 1206,
                "label": "Yongqiang",
                "label_ar": "يونغ تشيانغ",
                "label_fr": "Yongqiang"
            },
            {
                "value": 1207,
                "label": "Mayu",
                "label_ar": "مايو",
                "label_fr": "Mayu"
            },
            {
                "value": 1208,
                "label": "Wenhou",
                "label_ar": "وينهو",
                "label_fr": "Wenhou"
            },
            {
                "value": 1209,
                "label": "Tengjiao",
                "label_ar": "تنجياو",
                "label_fr": "Tengjiao"
            },
            {
                "value": 1210,
                "label": "Xiaozheng",
                "label_ar": "شياوتشينغ",
                "label_fr": "Xiaozheng"
            },
            {
                "value": 1211,
                "label": "Meiyu",
                "label_ar": "ميو",
                "label_fr": "Meiyu"
            },
            {
                "value": 1212,
                "label": "Yingqian",
                "label_ar": "ينجقيان",
                "label_fr": "Yingqian"
            },
            {
                "value": 1213,
                "label": "Shangma",
                "label_ar": "Shangma",
                "label_fr": "Shangma"
            },
            {
                "value": 1214,
                "label": "Qiaozhen",
                "label_ar": "Qiaozhen",
                "label_fr": "Qiaozhen"
            },
            {
                "value": 1215,
                "label": "Hongshun",
                "label_ar": "هونغشون",
                "label_fr": "Hongshun"
            },
            {
                "value": 1216,
                "label": "Ruifeng",
                "label_ar": "رويفينج",
                "label_fr": "Ruifeng"
            },
            {
                "value": 1217,
                "label": "Xianqiao",
                "label_ar": "Xianqiao",
                "label_fr": "Xianqiao"
            },
            {
                "value": 1218,
                "label": "Dungdo",
                "label_ar": "دونغدو",
                "label_fr": "Dungdo"
            },
            {
                "value": 1219,
                "label": "Gushan",
                "label_ar": "جوشان",
                "label_fr": "Gushan"
            },
            {
                "value": 1220,
                "label": "Changqing",
                "label_ar": "تشانغكينغ",
                "label_fr": "Changqing"
            },
            {
                "value": 1221,
                "label": "Oujiang",
                "label_ar": "وجيانغ",
                "label_fr": "Oujiang"
            },
            {
                "value": 1222,
                "label": "Baoyu",
                "label_ar": "باويو",
                "label_fr": "Baoyu"
            },
            {
                "value": 1223,
                "label": "Guantian",
                "label_ar": "Guantian",
                "label_fr": "Guantian"
            },
            {
                "value": 1224,
                "label": "Caozhai",
                "label_ar": "كاوزاي",
                "label_fr": "Caozhai"
            },
            {
                "value": 1225,
                "label": "Xiaoshun",
                "label_ar": "شياوشون",
                "label_fr": "Xiaoshun"
            },
            {
                "value": 1226,
                "label": "Tongbai",
                "label_ar": "تونغباي",
                "label_fr": "Tongbai"
            },
            {
                "value": 1227,
                "label": "Lijiang",
                "label_ar": "ليجيانغ",
                "label_fr": "Lijiang"
            },
            {
                "value": 1228,
                "label": "Yuxi",
                "label_ar": "يوشي",
                "label_fr": "Yuxi"
            },
            {
                "value": 1229,
                "label": "Chengjian",
                "label_ar": "شينجيان",
                "label_fr": "Chengjian"
            },
            {
                "value": 1230,
                "label": "Chuxiong",
                "label_ar": "تشوشيونغ",
                "label_fr": "Chuxiong"
            },
            {
                "value": 1231,
                "label": "Lanping",
                "label_ar": "Lanping",
                "label_fr": "Lanping"
            },
            {
                "value": 1232,
                "label": "Zhaotong",
                "label_ar": "Zhaotong",
                "label_fr": "Zhaotong"
            },
            {
                "value": 1233,
                "label": "Gejiu",
                "label_ar": "جيجيو",
                "label_fr": "Gejiu"
            },
            {
                "value": 1234,
                "label": "Yisa",
                "label_ar": "ييسا",
                "label_fr": "Yisa"
            },
            {
                "value": 1235,
                "label": "Simao",
                "label_ar": "سيماو",
                "label_fr": "Simao"
            },
            {
                "value": 1236,
                "label": "Jingdong",
                "label_ar": "Jingdong",
                "label_fr": "Jingdong"
            },
            {
                "value": 1237,
                "label": "Wenshang",
                "label_ar": "ونشانغ",
                "label_fr": "Wenshang"
            },
            {
                "value": 1238,
                "label": "Wenshan",
                "label_ar": "وينشان",
                "label_fr": "Wenshan"
            },
            {
                "value": 1239,
                "label": "Dali",
                "label_ar": "دالي",
                "label_fr": "Dali"
            },
            {
                "value": 1240,
                "label": "Qujing",
                "label_ar": "Qujing",
                "label_fr": "Qujing"
            },
            {
                "value": 1241,
                "label": "Xinning",
                "label_ar": "شيننينغ",
                "label_fr": "Xinning"
            },
            {
                "value": 1242,
                "label": "Dujiacun",
                "label_ar": "دوجياكون",
                "label_fr": "Dujiacun"
            },
            {
                "value": 1243,
                "label": "Yanta",
                "label_ar": "يانتا",
                "label_fr": "Yanta"
            },
            {
                "value": 1244,
                "label": "Tianhai",
                "label_ar": "تيانهاي",
                "label_fr": "Tianhai"
            },
            {
                "value": 1245,
                "label": "Zhixing",
                "label_ar": "زيكسينغ",
                "label_fr": "Zhixing"
            },
            {
                "value": 1246,
                "label": "Henghe",
                "label_ar": "هنغ",
                "label_fr": "Henghe"
            },
            {
                "value": 1247,
                "label": "Ruixing",
                "label_ar": "رويكسينج",
                "label_fr": "Ruixing"
            },
            {
                "value": 1248,
                "label": "Meixi",
                "label_ar": "ميكسي",
                "label_fr": "Meixi"
            },
            {
                "value": 1249,
                "label": "Gongli",
                "label_ar": "جونجلي",
                "label_fr": "Gongli"
            },
            {
                "value": 1250,
                "label": "Tongyu",
                "label_ar": "تونجيو",
                "label_fr": "Tongyu"
            },
            {
                "value": 1251,
                "label": "Sanjiang",
                "label_ar": "سانجيانغ",
                "label_fr": "Sanjiang"
            },
            {
                "value": 1252,
                "label": "Tingpang",
                "label_ar": "تينغبانغ",
                "label_fr": "Tingpang"
            },
            {
                "value": 1253,
                "label": "Sanyou",
                "label_ar": "سانيو",
                "label_fr": "Sanyou"
            },
            {
                "value": 1254,
                "label": "Aizhou",
                "label_ar": "ايتشو",
                "label_fr": "Aizhou"
            },
            {
                "value": 1255,
                "label": "Jundu",
                "label_ar": "جوندو",
                "label_fr": "Jundu"
            },
            {
                "value": 1256,
                "label": "Dipu",
                "label_ar": "ديبو",
                "label_fr": "Dipu"
            },
            {
                "value": 1257,
                "label": "Shuangtang",
                "label_ar": "شوانغ تانغ",
                "label_fr": "Shuangtang"
            },
            {
                "value": 1258,
                "label": "Taimen",
                "label_ar": "تايمن",
                "label_fr": "Taimen"
            },
            {
                "value": 1259,
                "label": "Jiaotou",
                "label_ar": "جياوتو",
                "label_fr": "Jiaotou"
            },
            {
                "value": 1260,
                "label": "Xiazhi",
                "label_ar": "شيازي",
                "label_fr": "Xiazhi"
            },
            {
                "value": 1261,
                "label": "Zhujiajian",
                "label_ar": "Zhujiajian",
                "label_fr": "Zhujiajian"
            },
            {
                "value": 1262,
                "label": "Taohua",
                "label_ar": "تاوهوا",
                "label_fr": "Taohua"
            },
            {
                "value": 1263,
                "label": "Liuheng",
                "label_ar": "ليوهينج",
                "label_fr": "Liuheng"
            },
            {
                "value": 1264,
                "label": "Mingdu",
                "label_ar": "مينجدو",
                "label_fr": "Mingdu"
            },
            {
                "value": 1265,
                "label": "Shenjiamen",
                "label_ar": "Shenjiamen",
                "label_fr": "Shenjiamen"
            },
            {
                "value": 1266,
                "label": "Longshan",
                "label_ar": "لونغشان",
                "label_fr": "Longshan"
            },
            {
                "value": 1267,
                "label": "Dengbu",
                "label_ar": "دينجبو",
                "label_fr": "Dengbu"
            },
            {
                "value": 1268,
                "label": "Xiushan",
                "label_ar": "شيوشان",
                "label_fr": "Xiushan"
            },
            {
                "value": 1269,
                "label": "Daizhong",
                "label_ar": "دايزونغ",
                "label_fr": "Daizhong"
            },
            {
                "value": 1270,
                "label": "Longteng",
                "label_ar": "لونجتينج",
                "label_fr": "Longteng"
            },
            {
                "value": 1271,
                "label": "Beiyuan",
                "label_ar": "بييوان",
                "label_fr": "Beiyuan"
            },
            {
                "value": 1272,
                "label": "Taili",
                "label_ar": "تايلي",
                "label_fr": "Taili"
            },
            {
                "value": 1273,
                "label": "Choucheng",
                "label_ar": "تشوتشنغ",
                "label_fr": "Choucheng"
            },
            {
                "value": 1274,
                "label": "Lucheng",
                "label_ar": "لوتشنغ",
                "label_fr": "Lucheng"
            },
            {
                "value": 1275,
                "label": "Chengxiang",
                "label_ar": "تشنغشيانغ",
                "label_fr": "Chengxiang"
            },
            {
                "value": 1276,
                "label": "Dongying",
                "label_ar": "دونجينج",
                "label_fr": "Dongying"
            },
            {
                "value": 1277,
                "label": "Gangcun",
                "label_ar": "جانجكون",
                "label_fr": "Gangcun"
            },
            {
                "value": 1278,
                "label": "Sailing",
                "label_ar": "إبحار",
                "label_fr": "Voile"
            },
            {
                "value": 1279,
                "label": "Shifan",
                "label_ar": "شيفان",
                "label_fr": "Shifan"
            },
            {
                "value": 1280,
                "label": "Yaohua",
                "label_ar": "ياوهوا",
                "label_fr": "Yaohua"
            },
            {
                "value": 1281,
                "label": "Jingyuan",
                "label_ar": "جينغيوان",
                "label_fr": "Jingyuan"
            },
            {
                "value": 1282,
                "label": "Fengtai",
                "label_ar": "فنغتاى",
                "label_fr": "Fengtai"
            },
            {
                "value": 1283,
                "label": "Gaocheng",
                "label_ar": "جاوتشينج",
                "label_fr": "Gaocheng"
            },
            {
                "value": 1284,
                "label": "Gu'an",
                "label_ar": "جوان",
                "label_fr": "Gu'an"
            },
            {
                "value": 1285,
                "label": "Shuangshipu",
                "label_ar": "Shuangshipu",
                "label_fr": "Shuangshipu"
            },
            {
                "value": 1286,
                "label": "Li'ao",
                "label_ar": "Li'ao",
                "label_fr": "Li'ao"
            },
            {
                "value": 1287,
                "label": "Xi'ao",
                "label_ar": "Xi'ao",
                "label_fr": "Xi'ao"
            },
            {
                "value": 1288,
                "label": "Tang Xian",
                "label_ar": "تانغ شيان",
                "label_fr": "Tang Xian"
            },
            {
                "value": 1289,
                "label": "Tara",
                "label_ar": "تارا",
                "label_fr": "Tara"
            },
            {
                "value": 1290,
                "label": "Li'an",
                "label_ar": "ليان",
                "label_fr": "Li'an"
            },
            {
                "value": 1291,
                "label": "Hu'an",
                "label_ar": "هوان",
                "label_fr": "Hu'an"
            },
            {
                "value": 1292,
                "label": "Datang",
                "label_ar": "داتانغ",
                "label_fr": "Datang"
            },
            {
                "value": 1293,
                "label": "Tumu",
                "label_ar": "تومو",
                "label_fr": "Tumu"
            },
            {
                "value": 1294,
                "label": "Shuyi",
                "label_ar": "شوي",
                "label_fr": "Shuyi"
            },
            {
                "value": 1295,
                "label": "Gaoxin",
                "label_ar": "غاوكسين",
                "label_fr": "Gaoxin"
            },
            {
                "value": 1296,
                "label": "Bo'ao",
                "label_ar": "بواو",
                "label_fr": "Bo'ao"
            },
            {
                "value": 1297,
                "label": "Baocheng",
                "label_ar": "باوتشنغ",
                "label_fr": "Baocheng"
            },
            {
                "value": 1298,
                "label": "Haifu",
                "label_ar": "هايفو",
                "label_fr": "Haïfa"
            },
            {
                "value": 1299,
                "label": "Xiuying",
                "label_ar": "Xiuying",
                "label_fr": "Xiuying"
            },
            {
                "value": 1300,
                "label": "Xuanzhou",
                "label_ar": "شوانتشو",
                "label_fr": "Xuanzhou"
            },
            {
                "value": 1301,
                "label": "Gongjiang",
                "label_ar": "غونغجيانغ",
                "label_fr": "Gongjiang"
            },
            {
                "value": 1302,
                "label": "Huayuji",
                "label_ar": "هوايوجي",
                "label_fr": "Huayuji"
            },
            {
                "value": 1303,
                "label": "Qu'an",
                "label_ar": "تشيوان",
                "label_fr": "Qu'an"
            },
            {
                "value": 1304,
                "label": "Fuhao",
                "label_ar": "Fuhao",
                "label_fr": "Fuhao"
            },
            {
                "value": 1305,
                "label": "Weiyang",
                "label_ar": "وييانغ",
                "label_fr": "Weiyang"
            },
            {
                "value": 1306,
                "label": "Laohekou",
                "label_ar": "لاوهيكو",
                "label_fr": "Laohekou"
            },
            {
                "value": 1307,
                "label": "Nanshan",
                "label_ar": "نانشان",
                "label_fr": "Nanshan"
            },
            {
                "value": 1308,
                "label": "Chen Xian",
                "label_ar": "تشن زيان",
                "label_fr": "Chen Xian"
            },
            {
                "value": 1309,
                "label": "Siting",
                "label_ar": "الجلوس",
                "label_fr": "Emplacement"
            },
            {
                "value": 1310,
                "label": "Wuhou",
                "label_ar": "ووهو",
                "label_fr": "Wuhou"
            },
            {
                "value": 1311,
                "label": "Ji'an",
                "label_ar": "جيان",
                "label_fr": "Ji'an"
            },
            {
                "value": 1312,
                "label": "Fuxin",
                "label_ar": "فوكسين",
                "label_fr": "Fuxin"
            },
            {
                "value": 1313,
                "label": "Xiqu",
                "label_ar": "زيك",
                "label_fr": "Xiqu"
            },
            {
                "value": 1314,
                "label": "Wangluo",
                "label_ar": "وانجلو",
                "label_fr": "Wangluo"
            },
            {
                "value": 1315,
                "label": "Erqi",
                "label_ar": "عرقى",
                "label_fr": "Erqi"
            },
            {
                "value": 1316,
                "label": "Donghong",
                "label_ar": "دونغهونغ",
                "label_fr": "Donghong"
            },
            {
                "value": 1317,
                "label": "Gaobeidian",
                "label_ar": "Gaobeidian",
                "label_fr": "Gaobéidien"
            },
            {
                "value": 1318,
                "label": "Lianhua",
                "label_ar": "ليانهوا",
                "label_fr": "Lianhua"
            },
            {
                "value": 1319,
                "label": "Chenzhou",
                "label_ar": "تشنتشو",
                "label_fr": "Chenzhou"
            },
            {
                "value": 1320,
                "label": "Dajie",
                "label_ar": "دجي",
                "label_fr": "Dajie"
            },
            {
                "value": 1321,
                "label": "Chenghua",
                "label_ar": "Chenghua",
                "label_fr": "Chenghua"
            },
            {
                "value": 1322,
                "label": "Dongfeng",
                "label_ar": "دونغفنغ",
                "label_fr": "Dongfeng"
            },
            {
                "value": 1323,
                "label": "Zhu'ang",
                "label_ar": "Zhu'ang",
                "label_fr": "Zhu'ang"
            },
            {
                "value": 1324,
                "label": "Maji",
                "label_ar": "ماجي",
                "label_fr": "Maji"
            },
            {
                "value": 1325,
                "label": "Songling",
                "label_ar": "سونجلينج",
                "label_fr": "Songling"
            },
            {
                "value": 1326,
                "label": "Chum",
                "label_ar": "الصاحب",
                "label_fr": "Ami"
            },
            {
                "value": 1327,
                "label": "Cenyang",
                "label_ar": "سنيانغ",
                "label_fr": "Cenyang"
            },
            {
                "value": 1328,
                "label": "Hanshan",
                "label_ar": "هانشان",
                "label_fr": "Hanshan"
            },
            {
                "value": 1329,
                "label": "Xiaomi",
                "label_ar": "شاومى",
                "label_fr": "Xiaomi"
            },
            {
                "value": 1330,
                "label": "Luohu",
                "label_ar": "لوهو",
                "label_fr": "Luohu"
            },
            {
                "value": 1331,
                "label": "Hainan",
                "label_ar": "هاينان",
                "label_fr": "Hainan"
            },
            {
                "value": 1332,
                "label": "Guangxi",
                "label_ar": "قوانغشى",
                "label_fr": "Guangxi"
            },
            {
                "value": 1333,
                "label": "Changle",
                "label_ar": "Changle",
                "label_fr": "Changle"
            },
            {
                "value": 1334,
                "label": "Linfen",
                "label_ar": "لينفن",
                "label_fr": "Linfen"
            },
            {
                "value": 1335,
                "label": "Yuanping",
                "label_ar": "اليوانبينغ",
                "label_fr": "Yuanping"
            },
            {
                "value": 1336,
                "label": "Jiazhuang",
                "label_ar": "جياتشوانغ",
                "label_fr": "Jiazhuang"
            },
            {
                "value": 1337,
                "label": "Heibei",
                "label_ar": "هيبي",
                "label_fr": "Heibei"
            },
            {
                "value": 1338,
                "label": "Liaocheng",
                "label_ar": "ياوتشنغ",
                "label_fr": "Liaocheng"
            },
            {
                "value": 1339,
                "label": "Huaihua",
                "label_ar": "هوايهوا",
                "label_fr": "Huaihua"
            },
            {
                "value": 1340,
                "label": "Rizhao",
                "label_ar": "ريتشاو",
                "label_fr": "Rizhao"
            },
            {
                "value": 1341,
                "label": "Fuzhou",
                "label_ar": "فوتشو",
                "label_fr": "Fuzhou"
            },
            {
                "value": 1342,
                "label": "Duyun",
                "label_ar": "Duyun",
                "label_fr": "Duyun"
            },
            {
                "value": 1343,
                "label": "Tianshui",
                "label_ar": "تيانشوي",
                "label_fr": "Tianshui"
            },
            {
                "value": 1344,
                "label": "Guli",
                "label_ar": "جولي",
                "label_fr": "Guli"
            },
            {
                "value": 1345,
                "label": "Jinhai",
                "label_ar": "جينهاي",
                "label_fr": "Jinhai"
            },
            {
                "value": 1346,
                "label": "Chaoyang",
                "label_ar": "تشاويانغ",
                "label_fr": "Chaoyang"
            },
            {
                "value": 1347,
                "label": "Taixing",
                "label_ar": "Taixing",
                "label_fr": "Taixing"
            },
            {
                "value": 1348,
                "label": "Fengxiang",
                "label_ar": "فنغشيانغ",
                "label_fr": "Fengxiang"
            },
            {
                "value": 1349,
                "label": "Jinpan",
                "label_ar": "جينبان",
                "label_fr": "Jinpan"
            },
            {
                "value": 1350,
                "label": "Yizheng",
                "label_ar": "يتشنغ",
                "label_fr": "Yizheng"
            },
            {
                "value": 1351,
                "label": "Yunfu",
                "label_ar": "يونفو",
                "label_fr": "Yunfu"
            },
            {
                "value": 1352,
                "label": "Xinhui",
                "label_ar": "شينهوي",
                "label_fr": "Xinhui"
            },
            {
                "value": 1353,
                "label": "Wenlan",
                "label_ar": "وينلان",
                "label_fr": "Wenlan"
            },
            {
                "value": 1354,
                "label": "Tieli",
                "label_ar": "تييلي",
                "label_fr": "Tieli"
            },
            {
                "value": 1355,
                "label": "Taihe",
                "label_ar": "Taihe",
                "label_fr": "Taihe"
            },
            {
                "value": 1356,
                "label": "Liuzhou",
                "label_ar": "ليوتشو",
                "label_fr": "Liuzhou"
            },
            {
                "value": 1357,
                "label": "Hechi",
                "label_ar": "هيتشي",
                "label_fr": "Hechi"
            },
            {
                "value": 1358,
                "label": "Yunyang",
                "label_ar": "يونيانغ",
                "label_fr": "Yunyang"
            },
            {
                "value": 1359,
                "label": "Zhuxi Chengguanzhen",
                "label_ar": "Zhuxi Chengguanzhen",
                "label_fr": "Zhuxi Chengguanzhen"
            },
            {
                "value": 1360,
                "label": "Heze",
                "label_ar": "هيز",
                "label_fr": "Heze"
            },
            {
                "value": 1361,
                "label": "Shiqiaopu",
                "label_ar": "شيقياوبو",
                "label_fr": "Shiqiaopu"
            },
            {
                "value": 1362,
                "label": "Jiangbei",
                "label_ar": "جيانغبى",
                "label_fr": "Jiangbei"
            },
            {
                "value": 1363,
                "label": "Shanzhuang",
                "label_ar": "شانتشوانغ",
                "label_fr": "Shanzhuang"
            },
            {
                "value": 1364,
                "label": "Danyang",
                "label_ar": "دانيانغ",
                "label_fr": "Danyang"
            },
            {
                "value": 1365,
                "label": "Huai'an",
                "label_ar": "هوايان",
                "label_fr": "Huai'an"
            },
            {
                "value": 1366,
                "label": "Yantan",
                "label_ar": "يانتان",
                "label_fr": "Yantan"
            },
            {
                "value": 1367,
                "label": "Guangchang",
                "label_ar": "جوانجشانج",
                "label_fr": "Guangchang"
            },
            {
                "value": 1368,
                "label": "Xiguan",
                "label_ar": "شيغوان",
                "label_fr": "Xiguan"
            },
            {
                "value": 1369,
                "label": "Xigu",
                "label_ar": "شيغو",
                "label_fr": "Xigu"
            },
            {
                "value": 1370,
                "label": "Anying",
                "label_ar": "أي شيء",
                "label_fr": "Anying"
            },
            {
                "value": 1371,
                "label": "Xiangtong",
                "label_ar": "شيانغتونغ",
                "label_fr": "Xiangtong"
            },
            {
                "value": 1372,
                "label": "Guixi",
                "label_ar": "Guixi",
                "label_fr": "Guixi"
            },
            {
                "value": 1373,
                "label": "Xidian",
                "label_ar": "شيديان",
                "label_fr": "Xidian"
            },
            {
                "value": 1374,
                "label": "Pingliang",
                "label_ar": "بينجليانج",
                "label_fr": "Pingliang"
            },
            {
                "value": 1375,
                "label": "Pinghu",
                "label_ar": "بينغو",
                "label_fr": "Pinghu"
            },
            {
                "value": 1376,
                "label": "Changhe",
                "label_ar": "تشانغهي",
                "label_fr": "Changhe"
            },
            {
                "value": 1377,
                "label": "Yangshan",
                "label_ar": "يانغشان",
                "label_fr": "Yangshan"
            },
            {
                "value": 1378,
                "label": "Suji",
                "label_ar": "سوجي",
                "label_fr": "Suji"
            },
            {
                "value": 1379,
                "label": "Baizhang",
                "label_ar": "Baizhang",
                "label_fr": "Baizhang"
            },
            {
                "value": 1380,
                "label": "Shuangxi",
                "label_ar": "شوانغشي",
                "label_fr": "Shuangxi"
            },
            {
                "value": 1381,
                "label": "Yanming",
                "label_ar": "يانمينغ",
                "label_fr": "Yanming"
            },
            {
                "value": 1382,
                "label": "Yiqiao",
                "label_ar": "Yiqiao",
                "label_fr": "Yiqiao"
            },
            {
                "value": 1383,
                "label": "Xinxing",
                "label_ar": "شينشينغ",
                "label_fr": "Xinxing"
            },
            {
                "value": 1384,
                "label": "Dabao",
                "label_ar": "داباو",
                "label_fr": "Dabao"
            },
            {
                "value": 1385,
                "label": "Sanyuanpu",
                "label_ar": "سانيوانبو",
                "label_fr": "Sanyuanpu"
            },
            {
                "value": 1386,
                "label": "Gaobu",
                "label_ar": "Gaobu",
                "label_fr": "Gaobu"
            },
            {
                "value": 1387,
                "label": "Linghu",
                "label_ar": "لينغو",
                "label_fr": "Linghu"
            },
            {
                "value": 1388,
                "label": "Qingtong",
                "label_ar": "تشينغتونغ",
                "label_fr": "Qingtong"
            },
            {
                "value": 1389,
                "label": "Shuanglin",
                "label_ar": "شوانغلين",
                "label_fr": "Shuanglin"
            },
            {
                "value": 1390,
                "label": "Dampu",
                "label_ar": "دامبو",
                "label_fr": "Dampu"
            },
            {
                "value": 1391,
                "label": "Qi'an",
                "label_ar": "تشيان",
                "label_fr": "Qi'an"
            },
            {
                "value": 1392,
                "label": "Gouzhuang",
                "label_ar": "جوزهوانغ",
                "label_fr": "Gouzhuang"
            },
            {
                "value": 1393,
                "label": "Changsheng",
                "label_ar": "تشانغشنغ",
                "label_fr": "Changsheng"
            },
            {
                "value": 1394,
                "label": "Qianyu",
                "label_ar": "Qianyu",
                "label_fr": "Qianyu"
            },
            {
                "value": 1395,
                "label": "Datong",
                "label_ar": "داتونغ",
                "label_fr": "Datong"
            },
            {
                "value": 1396,
                "label": "Sandu",
                "label_ar": "ساندو",
                "label_fr": "Sandu"
            },
            {
                "value": 1397,
                "label": "Anjiang",
                "label_ar": "انجيانغ",
                "label_fr": "Anjiang"
            },
            {
                "value": 1398,
                "label": "Huangjue",
                "label_ar": "هوانغجو",
                "label_fr": "Huangjue"
            },
            {
                "value": 1399,
                "label": "Name",
                "label_ar": "اسم",
                "label_fr": "Nom"
            },
            {
                "value": 1400,
                "label": "Linqi",
                "label_ar": "لينكي",
                "label_fr": "Linqi"
            },
            {
                "value": 1401,
                "label": "Zhanghe",
                "label_ar": "تشانغهي",
                "label_fr": "Zhanghe"
            },
            {
                "value": 1402,
                "label": "Zheshang",
                "label_ar": "تشيشانغ",
                "label_fr": "Zheshang"
            },
            {
                "value": 1403,
                "label": "Huadian",
                "label_ar": "هواديان",
                "label_fr": "Huadian"
            },
            {
                "value": 1404,
                "label": "Kangcun",
                "label_ar": "كانغكون",
                "label_fr": "Kangcun"
            },
            {
                "value": 1405,
                "label": "Zhangshan",
                "label_ar": "تشانغشان",
                "label_fr": "Zhangshan"
            },
            {
                "value": 1406,
                "label": "Shouchang",
                "label_ar": "شوتشانغ",
                "label_fr": "Shouchang"
            },
            {
                "value": 1407,
                "label": "Qiantan",
                "label_ar": "قيانتان",
                "label_fr": "Qiantan"
            },
            {
                "value": 1408,
                "label": "Hongpan",
                "label_ar": "هونغبان",
                "label_fr": "Hongpan"
            },
            {
                "value": 1409,
                "label": "Lin'an",
                "label_ar": "لينان",
                "label_fr": "Lin'an"
            },
            {
                "value": 1410,
                "label": "Dingzhou",
                "label_ar": "دينجزو",
                "label_fr": "Dingzhou"
            },
            {
                "value": 1411,
                "label": "Jiuzong",
                "label_ar": "جيوزونغ",
                "label_fr": "Jiuzong"
            },
            {
                "value": 1412,
                "label": "Yuting",
                "label_ar": "يوتينغ",
                "label_fr": "Yuting"
            },
            {
                "value": 1413,
                "label": "Guali",
                "label_ar": "جوالي",
                "label_fr": "Guali"
            },
            {
                "value": 1414,
                "label": "Jianci",
                "label_ar": "جيانشي",
                "label_fr": "Jianci"
            },
            {
                "value": 1415,
                "label": "Kaiyun",
                "label_ar": "كايون",
                "label_fr": "Kaiyun"
            },
            {
                "value": 1416,
                "label": "Chunhua",
                "label_ar": "تشونهوا",
                "label_fr": "Chunhua"
            },
            {
                "value": 1417,
                "label": "Hongwei",
                "label_ar": "هونغوي",
                "label_fr": "Hongwei"
            },
            {
                "value": 1418,
                "label": "Shuguang",
                "label_ar": "شوغوانغ",
                "label_fr": "Shuguang"
            },
            {
                "value": 1419,
                "label": "Diankou",
                "label_ar": "ديانكو",
                "label_fr": "Diankou"
            },
            {
                "value": 1420,
                "label": "Lidong",
                "label_ar": "ليدونغ",
                "label_fr": "Lidong"
            },
            {
                "value": 1421,
                "label": "Fengqing",
                "label_ar": "فينجكينج",
                "label_fr": "Fengqing"
            },
            {
                "value": 1422,
                "label": "Fengqiao",
                "label_ar": "فنغتشياو",
                "label_fr": "Fengqiao"
            },
            {
                "value": 1423,
                "label": "Fengqi",
                "label_ar": "فنغكي",
                "label_fr": "Fengqi"
            },
            {
                "value": 1424,
                "label": "Rongle",
                "label_ar": "رونجل",
                "label_fr": "Rongle"
            },
            {
                "value": 1425,
                "label": "Huangzhai",
                "label_ar": "هوانغزاي",
                "label_fr": "Huangzhai"
            },
            {
                "value": 1426,
                "label": "Wuning",
                "label_ar": "ونينغ",
                "label_fr": "Wuning"
            },
            {
                "value": 1427,
                "label": "Yahao",
                "label_ar": "ياهاو",
                "label_fr": "Yahao"
            },
            {
                "value": 1428,
                "label": "Jifeng",
                "label_ar": "جيفنغ",
                "label_fr": "Jifeng"
            },
            {
                "value": 1429,
                "label": "Ancheng",
                "label_ar": "أنشنغ",
                "label_fr": "Ancheng"
            },
            {
                "value": 1430,
                "label": "Shangfang",
                "label_ar": "Shangfang",
                "label_fr": "Shangfang"
            },
            {
                "value": 1431,
                "label": "Jiahu",
                "label_ar": "جياهو",
                "label_fr": "Jiahu"
            },
            {
                "value": 1432,
                "label": "Jinpu",
                "label_ar": "جينبو",
                "label_fr": "Jinpu"
            },
            {
                "value": 1433,
                "label": "Potang",
                "label_ar": "بوتانج",
                "label_fr": "Potang"
            },
            {
                "value": 1434,
                "label": "Shangjiang",
                "label_ar": "شانغجيانغ",
                "label_fr": "Shangjiang"
            },
            {
                "value": 1435,
                "label": "Xingqiao",
                "label_ar": "Xingqiao",
                "label_fr": "Xingqiao"
            },
            {
                "value": 1436,
                "label": "Doujiang",
                "label_ar": "دوجيانغ",
                "label_fr": "Doujiang"
            },
            {
                "value": 1437,
                "label": "Nyingchi County",
                "label_ar": "مقاطعة نينغتشي",
                "label_fr": "Comté de Nyingchi"
            },
            {
                "value": 1438,
                "label": "Yangxunqiao",
                "label_ar": "Yangxunqiao",
                "label_fr": "Yangxunqiao"
            },
            {
                "value": 1439,
                "label": "Tongmin",
                "label_ar": "تونغمين",
                "label_fr": "Tongmin"
            },
            {
                "value": 1440,
                "label": "Lianghu",
                "label_ar": "Lianghu",
                "label_fr": "Lianghu"
            },
            {
                "value": 1441,
                "label": "Sundun",
                "label_ar": "سوندون",
                "label_fr": "Sundun"
            },
            {
                "value": 1442,
                "label": "Santiaolou",
                "label_ar": "سانتياولو",
                "label_fr": "Santiaolou"
            },
            {
                "value": 1443,
                "label": "Guilin",
                "label_ar": "قويلين",
                "label_fr": "Guilin"
            },
            {
                "value": 1444,
                "label": "Guigang",
                "label_ar": "Guigang",
                "label_fr": "Guigang"
            },
            {
                "value": 1445,
                "label": "Qinzhou",
                "label_ar": "تشينتشو",
                "label_fr": "Qinzhou"
            },
            {
                "value": 1446,
                "label": "Laibin",
                "label_ar": "لايبين",
                "label_fr": "Laibin"
            },
            {
                "value": 1447,
                "label": "Shenwan",
                "label_ar": "شنوان",
                "label_fr": "Shenwan"
            },
            {
                "value": 1448,
                "label": "Zhenzhou",
                "label_ar": "Zhenzhou",
                "label_fr": "Zhenzhou"
            },
            {
                "value": 1449,
                "label": "Zhongdian",
                "label_ar": "تشونغديان",
                "label_fr": "Zhongdian"
            },
            {
                "value": 1450,
                "label": "Yidong",
                "label_ar": "ييدونغ",
                "label_fr": "Yidong"
            },
            {
                "value": 1451,
                "label": "Minhang",
                "label_ar": "مينهانج",
                "label_fr": "Minhang"
            },
            {
                "value": 1452,
                "label": "Longmao",
                "label_ar": "لونغماو",
                "label_fr": "Longmao"
            },
            {
                "value": 1453,
                "label": "Linjiang",
                "label_ar": "لينجيانغ",
                "label_fr": "Linjiang"
            },
            {
                "value": 1454,
                "label": "Taiping",
                "label_ar": "تايبينغ",
                "label_fr": "Taiping"
            },
            {
                "value": 1455,
                "label": "Mingde",
                "label_ar": "مينجد",
                "label_fr": "Mingde"
            },
            {
                "value": 1456,
                "label": "Xingchen",
                "label_ar": "Xingchen",
                "label_fr": "Xingchen"
            },
            {
                "value": 1457,
                "label": "Jiading",
                "label_ar": "جيادينج",
                "label_fr": "Jiading"
            },
            {
                "value": 1458,
                "label": "Zhujiajiao",
                "label_ar": "Zhujiajiao",
                "label_fr": "Zhujiajiao"
            },
            {
                "value": 1459,
                "label": "Zhima",
                "label_ar": "زيما",
                "label_fr": "Zhima"
            },
            {
                "value": 1460,
                "label": "Zhongnan",
                "label_ar": "تشونغنان",
                "label_fr": "Zhongnan"
            },
            {
                "value": 1461,
                "label": "Tongji",
                "label_ar": "تونغجي",
                "label_fr": "Tongji"
            },
            {
                "value": 1462,
                "label": "Xinzha",
                "label_ar": "شينزها",
                "label_fr": "Xinzha"
            },
            {
                "value": 1463,
                "label": "Enshi",
                "label_ar": "إنشي",
                "label_fr": "Enshi"
            },
            {
                "value": 1464,
                "label": "Taxi",
                "label_ar": "سيارة اجره",
                "label_fr": "Taxi"
            },
            {
                "value": 1465,
                "label": "Zhengda",
                "label_ar": "تشنغدا",
                "label_fr": "Zhengda"
            },
            {
                "value": 1466,
                "label": "Xuhui",
                "label_ar": "كسوهوي",
                "label_fr": "Xuhui"
            },
            {
                "value": 1467,
                "label": "Xiangru",
                "label_ar": "Xiangru",
                "label_fr": "Xiangru"
            },
            {
                "value": 1468,
                "label": "Longjin",
                "label_ar": "لونجين",
                "label_fr": "Longjin"
            },
            {
                "value": 1469,
                "label": "Changzhong",
                "label_ar": "تشانغتشونغ",
                "label_fr": "Changzhong"
            },
            {
                "value": 1470,
                "label": "Ejia",
                "label_ar": "إيجيا",
                "label_fr": "Ejia"
            },
            {
                "value": 1471,
                "label": "Huadong",
                "label_ar": "هوادونغ",
                "label_fr": "Huadong"
            },
            {
                "value": 1472,
                "label": "Donghe",
                "label_ar": "Donghe",
                "label_fr": "Donghe"
            },
            {
                "value": 1473,
                "label": "Dazhong",
                "label_ar": "داتشونغ",
                "label_fr": "Dazhong"
            },
            {
                "value": 1474,
                "label": "Taipingyan",
                "label_ar": "تايبينجيان",
                "label_fr": "Taipingyan"
            },
            {
                "value": 1475,
                "label": "Jintan",
                "label_ar": "جينتان",
                "label_fr": "Jintan"
            },
            {
                "value": 1476,
                "label": "Licheng",
                "label_ar": "ليتشنغ",
                "label_fr": "Licheng"
            },
            {
                "value": 1477,
                "label": "Miyun",
                "label_ar": "مييون",
                "label_fr": "Miyun"
            },
            {
                "value": 1478,
                "label": "Lingcheng",
                "label_ar": "Lingcheng",
                "label_fr": "Lingcheng"
            },
            {
                "value": 1479,
                "label": "Jingmen",
                "label_ar": "جينجمين",
                "label_fr": "Jingmen"
            },
            {
                "value": 1480,
                "label": "Kashgar",
                "label_ar": "كاشغر",
                "label_fr": "Kashgar"
            },
            {
                "value": 1481,
                "label": "Tianzhen",
                "label_ar": "تيانجين",
                "label_fr": "Tianzhen"
            },
            {
                "value": 1482,
                "label": "Huquan",
                "label_ar": "Huquan",
                "label_fr": "Huquan"
            },
            {
                "value": 1483,
                "label": "Wuling",
                "label_ar": "وولينغ",
                "label_fr": "Wuling"
            },
            {
                "value": 1484,
                "label": "Chengdong",
                "label_ar": "تشنغدونغ",
                "label_fr": "Chengdong"
            },
            {
                "value": 1485,
                "label": "Xinrong",
                "label_ar": "شينرونغ",
                "label_fr": "Xinrong"
            },
            {
                "value": 1486,
                "label": "Yungang",
                "label_ar": "يونغانغ",
                "label_fr": "Yungang"
            },
            {
                "value": 1487,
                "label": "Pingwang",
                "label_ar": "بينغوانغ",
                "label_fr": "Pingwang"
            },
            {
                "value": 1488,
                "label": "Niunaichang",
                "label_ar": "Niunaichang",
                "label_fr": "Niunaichang"
            },
            {
                "value": 1489,
                "label": "Shouyang",
                "label_ar": "شويانغ",
                "label_fr": "Shouyang"
            },
            {
                "value": 1490,
                "label": "Xiyang",
                "label_ar": "شييانغ",
                "label_fr": "Xiyang"
            },
            {
                "value": 1491,
                "label": "Heshun",
                "label_ar": "هيشون",
                "label_fr": "Heshun"
            },
            {
                "value": 1492,
                "label": "Liaoyang",
                "label_ar": "لياويانغ",
                "label_fr": "Liaoyang"
            },
            {
                "value": 1493,
                "label": "Jicheng",
                "label_ar": "جيتشنغ",
                "label_fr": "Jicheng"
            },
            {
                "value": 1494,
                "label": "Nandu",
                "label_ar": "ناندو",
                "label_fr": "Nandu"
            },
            {
                "value": 1495,
                "label": "Jiexiu",
                "label_ar": "جييكسيو",
                "label_fr": "Jiexiu"
            },
            {
                "value": 1496,
                "label": "Zhaoyu",
                "label_ar": "تشاويو",
                "label_fr": "Zhaoyu"
            },
            {
                "value": 1497,
                "label": "Pingyao County",
                "label_ar": "مقاطعة Pingyao",
                "label_fr": "Comté de Pingyao"
            },
            {
                "value": 1498,
                "label": "Mingxing",
                "label_ar": "مينجكسينج",
                "label_fr": "Mingxing"
            },
            {
                "value": 1499,
                "label": "Lingshi",
                "label_ar": "Lingshi",
                "label_fr": "Lingshi"
            },
            {
                "value": 1500,
                "label": "Linjiang",
                "label_ar": "لينجيانغ",
                "label_fr": "Linjiang"
            },
            {
                "value": 1501,
                "label": "Gaoping",
                "label_ar": "Gaoping",
                "label_fr": "Gaoping"
            },
            {
                "value": 1502,
                "label": "Bagong",
                "label_ar": "باجونج",
                "label_fr": "Bagong"
            },
            {
                "value": 1503,
                "label": "Chongwen",
                "label_ar": "تشونغوين",
                "label_fr": "Chongwen"
            },
            {
                "value": 1504,
                "label": "Gongyi",
                "label_ar": "Gongyi",
                "label_fr": "Gongyi"
            },
            {
                "value": 1505,
                "label": "Fangshan",
                "label_ar": "فانغشان",
                "label_fr": "Fangshan"
            },
            {
                "value": 1506,
                "label": "Dongcun",
                "label_ar": "دونغكون",
                "label_fr": "Dongcun"
            },
            {
                "value": 1507,
                "label": "Xingxian",
                "label_ar": "Xingxian",
                "label_fr": "Xingxian"
            },
            {
                "value": 1508,
                "label": "Zhongyang",
                "label_ar": "تشونغيانغ",
                "label_fr": "Zhongyang"
            },
            {
                "value": 1509,
                "label": "Jiaokou",
                "label_ar": "جياوكو",
                "label_fr": "Jiaokou"
            },
            {
                "value": 1510,
                "label": "Liulin",
                "label_ar": "ليولين",
                "label_fr": "Liulin"
            },
            {
                "value": 1511,
                "label": "Linzhou",
                "label_ar": "ينتشو",
                "label_fr": "Linzhou"
            },
            {
                "value": 1512,
                "label": "Fenyang",
                "label_ar": "فينيانغ",
                "label_fr": "Fenyang"
            },
            {
                "value": 1513,
                "label": "Fengcheng",
                "label_ar": "فنغتشنغ",
                "label_fr": "Fengcheng"
            },
            {
                "value": 1514,
                "label": "Xiezhou",
                "label_ar": "Xiezhou",
                "label_fr": "Xiezhou"
            },
            {
                "value": 1515,
                "label": "Hejin",
                "label_ar": "هجين",
                "label_fr": "Hejin"
            },
            {
                "value": 1516,
                "label": "Pinglu",
                "label_ar": "بينغلو",
                "label_fr": "Pinglu"
            },
            {
                "value": 1517,
                "label": "Ruicheng",
                "label_ar": "رويشنغ",
                "label_fr": "Ruicheng"
            },
            {
                "value": 1518,
                "label": "Xiedian",
                "label_ar": "Xiedian",
                "label_fr": "Xiedian"
            },
            {
                "value": 1519,
                "label": "Wenxi",
                "label_ar": "ونشى",
                "label_fr": "Wenxi"
            },
            {
                "value": 1520,
                "label": "Yaofeng",
                "label_ar": "Yaofeng",
                "label_fr": "Yaofeng"
            },
            {
                "value": 1521,
                "label": "Kouqian",
                "label_ar": "Kouqian",
                "label_fr": "Kouqian"
            },
            {
                "value": 1522,
                "label": "Wutai",
                "label_ar": "Wutai",
                "label_fr": "Wutai"
            },
            {
                "value": 1523,
                "label": "Yangquan",
                "label_ar": "يانجكوان",
                "label_fr": "Yangquan"
            },
            {
                "value": 1524,
                "label": "Xiaoqu",
                "label_ar": "شياوك",
                "label_fr": "Xiaoqu"
            },
            {
                "value": 1525,
                "label": "Yijing",
                "label_ar": "يجينغ",
                "label_fr": "Yijing"
            },
            {
                "value": 1526,
                "label": "Xinghua",
                "label_ar": "شينغهوا",
                "label_fr": "Xinghua"
            },
            {
                "value": 1527,
                "label": "Haozhuang",
                "label_ar": "Haozhuang",
                "label_fr": "Haozhuang"
            },
            {
                "value": 1528,
                "label": "Loufan",
                "label_ar": "لوفان",
                "label_fr": "Loufan"
            },
            {
                "value": 1529,
                "label": "Yangqu",
                "label_ar": "يانغكو",
                "label_fr": "Yangqu"
            },
            {
                "value": 1530,
                "label": "Gujiao",
                "label_ar": "جوجياو",
                "label_fr": "Gujiao"
            },
            {
                "value": 1531,
                "label": "Qingyuan",
                "label_ar": "تشينغيوان",
                "label_fr": "Qingyuan"
            },
            {
                "value": 1532,
                "label": "Luzhou",
                "label_ar": "لوتشو",
                "label_fr": "Luzhou"
            },
            {
                "value": 1533,
                "label": "Laiyi",
                "label_ar": "لايي",
                "label_fr": "Laiyi"
            },
            {
                "value": 1534,
                "label": "Jiamusi",
                "label_ar": "جياموسى",
                "label_fr": "Jiamusi"
            },
            {
                "value": 1535,
                "label": "Qinghai",
                "label_ar": "تشينغهاي",
                "label_fr": "Qinghai"
            },
            {
                "value": 1536,
                "label": "Tongliao",
                "label_ar": "تونغلياو",
                "label_fr": "Tongliao"
            },
            {
                "value": 1537,
                "label": "Sanhe",
                "label_ar": "سانهي",
                "label_fr": "Sanhe"
            },
            {
                "value": 1538,
                "label": "Tangdukou",
                "label_ar": "تانجدوكو",
                "label_fr": "Tangdukou"
            },
            {
                "value": 1539,
                "label": "Wu'an",
                "label_ar": "ووان",
                "label_fr": "Wu'an"
            },
            {
                "value": 1540,
                "label": "Xianlin",
                "label_ar": "شيانلين",
                "label_fr": "Xianlin"
            },
            {
                "value": 1541,
                "label": "Jingzhong",
                "label_ar": "جينغتشونغ",
                "label_fr": "Jingzhong"
            },
            {
                "value": 1542,
                "label": "Baishan",
                "label_ar": "بيشان",
                "label_fr": "Baishan"
            },
            {
                "value": 1543,
                "label": "Qingnian",
                "label_ar": "كينغنيان",
                "label_fr": "Qingnian"
            },
            {
                "value": 1544,
                "label": "Yintian",
                "label_ar": "ينتيان",
                "label_fr": "Yintian"
            },
            {
                "value": 1545,
                "label": "Huangshi",
                "label_ar": "هوانغشي",
                "label_fr": "Huangshi"
            },
            {
                "value": 1546,
                "label": "Huludao",
                "label_ar": "هولوداو",
                "label_fr": "Huludao"
            },
            {
                "value": 1547,
                "label": "Fenggang",
                "label_ar": "Fenggang",
                "label_fr": "Fenggang"
            },
            {
                "value": 1548,
                "label": "Sui'an",
                "label_ar": "سويان",
                "label_fr": "Sui'an"
            },
            {
                "value": 1549,
                "label": "Putian",
                "label_ar": "بوتيان",
                "label_fr": "Putian"
            },
            {
                "value": 1550,
                "label": "Shima",
                "label_ar": "شيما",
                "label_fr": "Shima"
            },
            {
                "value": 1551,
                "label": "Jiangyou",
                "label_ar": "جيانغيو",
                "label_fr": "Jiangyou"
            },
            {
                "value": 1552,
                "label": "Yangling",
                "label_ar": "يانجلينج",
                "label_fr": "Yangling"
            },
            {
                "value": 1553,
                "label": "Xinjiang",
                "label_ar": "شينجيانغ",
                "label_fr": "Xinjiang"
            },
            {
                "value": 1554,
                "label": "Zhumadian",
                "label_ar": "Zhumadian",
                "label_fr": "Zhumadian"
            },
            {
                "value": 1555,
                "label": "Meizhou",
                "label_ar": "ميتشو",
                "label_fr": "Meizhou"
            },
            {
                "value": 1556,
                "label": "Pingxiang",
                "label_ar": "بينغشيانغ",
                "label_fr": "Pingxiang"
            },
            {
                "value": 1557,
                "label": "Suzhou",
                "label_ar": "سوتشو",
                "label_fr": "Suzhou"
            },
            {
                "value": 1558,
                "label": "Xucheng",
                "label_ar": "Xucheng",
                "label_fr": "Xucheng"
            },
            {
                "value": 1559,
                "label": "Linbei",
                "label_ar": "لينبي",
                "label_fr": "Linbei"
            },
            {
                "value": 1560,
                "label": "Jiedong",
                "label_ar": "جيدونغ",
                "label_fr": "Jiedong"
            },
            {
                "value": 1561,
                "label": "Hangong",
                "label_ar": "هانغونغ",
                "label_fr": "Hangong"
            },
            {
                "value": 1562,
                "label": "Jiehe",
                "label_ar": "جيهي",
                "label_fr": "Jiehe"
            },
            {
                "value": 1563,
                "label": "Ansu",
                "label_ar": "أنسو",
                "label_fr": "Ansu"
            },
            {
                "value": 1564,
                "label": "Huge",
                "label_ar": "ضخم",
                "label_fr": "Énorme"
            },
            {
                "value": 1565,
                "label": "Gujiang",
                "label_ar": "Gujiang",
                "label_fr": "Gujiang"
            },
            {
                "value": 1566,
                "label": "Lin'e",
                "label_ar": "خط",
                "label_fr": "Ligne"
            },
            {
                "value": 1567,
                "label": "Mingzhu",
                "label_ar": "مينجزو",
                "label_fr": "Mingzhu"
            },
            {
                "value": 1568,
                "label": "Youjiang",
                "label_ar": "يوجيانغ",
                "label_fr": "Youjiang"
            },
            {
                "value": 1569,
                "label": "Tianli",
                "label_ar": "تيانلي",
                "label_fr": "Tianli"
            },
            {
                "value": 1570,
                "label": "Baise City",
                "label_ar": "مدينة بايسه",
                "label_fr": "Ville de Baïse"
            },
            {
                "value": 1571,
                "label": "Longlin",
                "label_ar": "لونجلين",
                "label_fr": "Palangre"
            },
            {
                "value": 1572,
                "label": "Jingxi",
                "label_ar": "جينغشى",
                "label_fr": "Jingxi"
            },
            {
                "value": 1573,
                "label": "Napo",
                "label_ar": "نابو",
                "label_fr": "Napo"
            },
            {
                "value": 1574,
                "label": "Tianyang",
                "label_ar": "تيانيانغ",
                "label_fr": "Tianyang"
            },
            {
                "value": 1575,
                "label": "Tiandong",
                "label_ar": "تياندونغ",
                "label_fr": "Tiandong"
            },
            {
                "value": 1576,
                "label": "Tieshan",
                "label_ar": "تيشان",
                "label_fr": "Tieshan"
            },
            {
                "value": 1577,
                "label": "Beihai",
                "label_ar": "بيهاي",
                "label_fr": "Beihai"
            },
            {
                "value": 1578,
                "label": "Qianling",
                "label_ar": "تشيانلينج",
                "label_fr": "Qianling"
            },
            {
                "value": 1579,
                "label": "Guang'an",
                "label_ar": "جوانجان",
                "label_fr": "Guang'an"
            },
            {
                "value": 1580,
                "label": "Yibin",
                "label_ar": "ييبين",
                "label_fr": "Yibin"
            },
            {
                "value": 1581,
                "label": "Lincang",
                "label_ar": "لينكانج",
                "label_fr": "Lincang"
            },
            {
                "value": 1582,
                "label": "Zunyi",
                "label_ar": "Zunyi",
                "label_fr": "Zunyi"
            },
            {
                "value": 1583,
                "label": "Zhongxiang",
                "label_ar": "تشونغشيانغ",
                "label_fr": "Zhongxiang"
            },
            {
                "value": 1584,
                "label": "Yanjiao",
                "label_ar": "يانجياو",
                "label_fr": "Yanjiao"
            },
            {
                "value": 1585,
                "label": "Yanji",
                "label_ar": "يانجي",
                "label_fr": "Yanji"
            },
            {
                "value": 1586,
                "label": "Qike",
                "label_ar": "Qike",
                "label_fr": "Qike"
            },
            {
                "value": 1587,
                "label": "Nenjiang",
                "label_ar": "نينجيانغ",
                "label_fr": "Nenjiang"
            },
            {
                "value": 1588,
                "label": "Huanggang",
                "label_ar": "هوانغقانغ",
                "label_fr": "Huanggang"
            },
            {
                "value": 1589,
                "label": "Hupi",
                "label_ar": "هوبي",
                "label_fr": "Hupi"
            },
            {
                "value": 1590,
                "label": "Xinyang",
                "label_ar": "شينيانغ",
                "label_fr": "Xinyang"
            },
            {
                "value": 1591,
                "label": "Minjiang",
                "label_ar": "مينجيانغ",
                "label_fr": "Minjiang"
            },
            {
                "value": 1592,
                "label": "Sanmao",
                "label_ar": "سانماو",
                "label_fr": "Sanmao"
            },
            {
                "value": 1593,
                "label": "Yanchang",
                "label_ar": "يانتشانغ",
                "label_fr": "Yanchang"
            },
            {
                "value": 1594,
                "label": "Jiahe Chengguanzhen",
                "label_ar": "جياهي تشينجوانزين",
                "label_fr": "Jiahe Chengguanzhen"
            },
            {
                "value": 1595,
                "label": "Baile",
                "label_ar": "بايلي",
                "label_fr": "Baile"
            },
            {
                "value": 1596,
                "label": "Xibu",
                "label_ar": "Xibu",
                "label_fr": "Xibu"
            },
            {
                "value": 1597,
                "label": "Doumen",
                "label_ar": "دومين",
                "label_fr": "Doumen"
            },
            {
                "value": 1598,
                "label": "Huangze",
                "label_ar": "هوانغزي",
                "label_fr": "Huangze"
            },
            {
                "value": 1599,
                "label": "Keqiao",
                "label_ar": "Keqiao",
                "label_fr": "Keqiao"
            },
            {
                "value": 1600,
                "label": "Linnan",
                "label_ar": "لينان",
                "label_fr": "Linnan"
            },
            {
                "value": 1601,
                "label": "Xingwang",
                "label_ar": "Xingwang",
                "label_fr": "Xingwang"
            },
            {
                "value": 1602,
                "label": "Liangzhu",
                "label_ar": "Liangzhu",
                "label_fr": "Liangzhu"
            },
            {
                "value": 1603,
                "label": "Yunting",
                "label_ar": "يونتينغ",
                "label_fr": "Yunting"
            },
            {
                "value": 1604,
                "label": "Meiyuan",
                "label_ar": "مييوان",
                "label_fr": "Meiyuan"
            },
            {
                "value": 1605,
                "label": "Lishang",
                "label_ar": "ليشانغ",
                "label_fr": "Lishang"
            },
            {
                "value": 1606,
                "label": "Shangsi",
                "label_ar": "Shangsi",
                "label_fr": "Shangsi"
            },
            {
                "value": 1607,
                "label": "Lida",
                "label_ar": "ليدا",
                "label_fr": "Lida"
            },
            {
                "value": 1608,
                "label": "Jiangnan",
                "label_ar": "جيانغنان",
                "label_fr": "Jiangnan"
            },
            {
                "value": 1609,
                "label": "Xingguang",
                "label_ar": "Xingguang",
                "label_fr": "Xingguang"
            },
            {
                "value": 1610,
                "label": "Jingdu",
                "label_ar": "جينغدو",
                "label_fr": "Jingdu"
            },
            {
                "value": 1611,
                "label": "Nanyuan",
                "label_ar": "نانيوان",
                "label_fr": "Nanyuan"
            },
            {
                "value": 1612,
                "label": "Shuozhou",
                "label_ar": "شوزو",
                "label_fr": "Shuozhou"
            },
            {
                "value": 1613,
                "label": "Xieqiao",
                "label_ar": "Xieqiao",
                "label_fr": "Xieqiao"
            },
            {
                "value": 1614,
                "label": "Chang'an",
                "label_ar": "تشانغآن",
                "label_fr": "Chang'an"
            },
            {
                "value": 1615,
                "label": "Xucun",
                "label_ar": "Xucun",
                "label_fr": "Xucun"
            },
            {
                "value": 1616,
                "label": "Zhouwangmiao",
                "label_ar": "Zhouwangmiao",
                "label_fr": "Zhouwangmiao"
            },
            {
                "value": 1617,
                "label": "Youchegang",
                "label_ar": "Youchegang",
                "label_fr": "Youchegang"
            },
            {
                "value": 1618,
                "label": "Chengdong",
                "label_ar": "تشنغدونغ",
                "label_fr": "Chengdong"
            },
            {
                "value": 1619,
                "label": "Donglin",
                "label_ar": "Donglin",
                "label_fr": "Donglin"
            },
            {
                "value": 1620,
                "label": "Weitang",
                "label_ar": "ويتانغ",
                "label_fr": "Weitang"
            },
            {
                "value": 1621,
                "label": "Fengnan",
                "label_ar": "فنغنان",
                "label_fr": "Fengnan"
            },
            {
                "value": 1622,
                "label": "Lize",
                "label_ar": "ليز",
                "label_fr": "Lize"
            },
            {
                "value": 1623,
                "label": "Xitang",
                "label_ar": "Xitang",
                "label_fr": "Xitang"
            },
            {
                "value": 1624,
                "label": "Xiadianmiao",
                "label_ar": "Xiadianmiao",
                "label_fr": "Xiadianmiao"
            },
            {
                "value": 1625,
                "label": "Dashun",
                "label_ar": "داشون",
                "label_fr": "Dashun"
            },
            {
                "value": 1626,
                "label": "Ganyao",
                "label_ar": "جانياو",
                "label_fr": "Ganyao"
            },
            {
                "value": 1627,
                "label": "Fanjing",
                "label_ar": "فانجينغ",
                "label_fr": "Fanjing"
            },
            {
                "value": 1628,
                "label": "Huimin",
                "label_ar": "هويمين",
                "label_fr": "Huimin"
            },
            {
                "value": 1629,
                "label": "Yaozhuang",
                "label_ar": "ياوزوانج",
                "label_fr": "Yaozhuang"
            },
            {
                "value": 1630,
                "label": "Taozhuang",
                "label_ar": "Taozhuang",
                "label_fr": "Taozhuang"
            },
            {
                "value": 1631,
                "label": "Yifu",
                "label_ar": "ييفو",
                "label_fr": "Yifu"
            },
            {
                "value": 1632,
                "label": "Yuhui",
                "label_ar": "يوهوي",
                "label_fr": "Yuhui"
            },
            {
                "value": 1633,
                "label": "Tianning",
                "label_ar": "تياننينغ",
                "label_fr": "Tianning"
            },
            {
                "value": 1634,
                "label": "Yangmiao",
                "label_ar": "Yangmiao",
                "label_fr": "Yangmiao"
            },
            {
                "value": 1635,
                "label": "Hongxi",
                "label_ar": "هونغشي",
                "label_fr": "Hongxi"
            },
            {
                "value": 1636,
                "label": "Dayun",
                "label_ar": "دايون",
                "label_fr": "Dayun"
            },
            {
                "value": 1637,
                "label": "Dingzha",
                "label_ar": "Dingzha",
                "label_fr": "Dingzha"
            },
            {
                "value": 1638,
                "label": "Sizhou",
                "label_ar": "سيتشو",
                "label_fr": "Sizhou"
            },
            {
                "value": 1639,
                "label": "Yucheng",
                "label_ar": "يوتشنغ",
                "label_fr": "Yucheng"
            },
            {
                "value": 1640,
                "label": "Gaozhao",
                "label_ar": "غاوزهاو",
                "label_fr": "Gaozhao"
            },
            {
                "value": 1641,
                "label": "Xinao",
                "label_ar": "زيناو",
                "label_fr": "Xinao"
            },
            {
                "value": 1642,
                "label": "Lanhai",
                "label_ar": "لانهاي",
                "label_fr": "Lanhai"
            },
            {
                "value": 1643,
                "label": "Jinde",
                "label_ar": "جيندي",
                "label_fr": "Jinde"
            },
            {
                "value": 1644,
                "label": "Ruiting",
                "label_ar": "التجديف",
                "label_fr": "Recrutement"
            },
            {
                "value": 1645,
                "label": "Anke",
                "label_ar": "أنكه",
                "label_fr": "Anke"
            },
            {
                "value": 1646,
                "label": "Haizhou",
                "label_ar": "هيتشو",
                "label_fr": "Haizhou"
            },
            {
                "value": 1647,
                "label": "Guangtai",
                "label_ar": "جوانجتاي",
                "label_fr": "Guangtai"
            },
            {
                "value": 1648,
                "label": "Jielong",
                "label_ar": "جيلونج",
                "label_fr": "Jielong"
            },
            {
                "value": 1649,
                "label": "Hualong",
                "label_ar": "هوالونغ",
                "label_fr": "Hualong"
            },
            {
                "value": 1650,
                "label": "Huayang",
                "label_ar": "هوايانغ",
                "label_fr": "Huayang"
            },
            {
                "value": 1651,
                "label": "Xiashi",
                "label_ar": "زياشي",
                "label_fr": "Xiashi"
            },
            {
                "value": 1652,
                "label": "Ziyu",
                "label_ar": "Ziyu",
                "label_fr": "Ziyu"
            },
            {
                "value": 1653,
                "label": "Jinlu",
                "label_ar": "جينلو",
                "label_fr": "Jinlu"
            },
            {
                "value": 1654,
                "label": "Tianhong",
                "label_ar": "تيانهونغ",
                "label_fr": "Tianhong"
            },
            {
                "value": 1655,
                "label": "Mingjia",
                "label_ar": "مينجيا",
                "label_fr": "Mingjia"
            },
            {
                "value": 1656,
                "label": "Jingtian",
                "label_ar": "جينغتيان",
                "label_fr": "Jingtian"
            },
            {
                "value": 1657,
                "label": "Yunling",
                "label_ar": "يونلينغ",
                "label_fr": "Yunling"
            },
            {
                "value": 1658,
                "label": "Xiangsheng",
                "label_ar": "شيانغ شنغ",
                "label_fr": "Xiangsheng"
            },
            {
                "value": 1659,
                "label": "Cengang",
                "label_ar": "Cengang",
                "label_fr": "Cengang"
            },
            {
                "value": 1660,
                "label": "Dongchang",
                "label_ar": "Dongchang",
                "label_fr": "Dongchang"
            },
            {
                "value": 1661,
                "label": "Yungcun",
                "label_ar": "يونغكون",
                "label_fr": "Yungcun"
            },
            {
                "value": 1662,
                "label": "Zhongtian",
                "label_ar": "تشونغتيان",
                "label_fr": "Zhongtian"
            },
            {
                "value": 1663,
                "label": "Longhua",
                "label_ar": "لونغهوا",
                "label_fr": "Longhua"
            },
            {
                "value": 1664,
                "label": "Jiadi",
                "label_ar": "جيادي",
                "label_fr": "Jiadi"
            },
            {
                "value": 1665,
                "label": "Haicheng",
                "label_ar": "هايتشنغ",
                "label_fr": "Haicheng"
            },
            {
                "value": 1666,
                "label": "Fengshang",
                "label_ar": "فنغشانغ",
                "label_fr": "Fengshang"
            },
            {
                "value": 1667,
                "label": "Huangxuan",
                "label_ar": "هوانغشوان",
                "label_fr": "Huangxuan"
            },
            {
                "value": 1668,
                "label": "Hexun",
                "label_ar": "هيكسون",
                "label_fr": "Hexun"
            },
            {
                "value": 1669,
                "label": "Baziqiang",
                "label_ar": "بازيكيانغ",
                "label_fr": "Baziqiang"
            },
            {
                "value": 1670,
                "label": "Baiyi",
                "label_ar": "بايي",
                "label_fr": "Baiyi"
            },
            {
                "value": 1671,
                "label": "Yuehua",
                "label_ar": "يويهوا",
                "label_fr": "Yuehua"
            },
            {
                "value": 1672,
                "label": "Fengjiang",
                "label_ar": "فنغجيانغ",
                "label_fr": "Fengjiang"
            },
            {
                "value": 1673,
                "label": "Zhiyuan",
                "label_ar": "زيوان",
                "label_fr": "Zhiyuan"
            },
            {
                "value": 1674,
                "label": "Jinma",
                "label_ar": "جينما",
                "label_fr": "Jinma"
            },
            {
                "value": 1675,
                "label": "Chengfeng",
                "label_ar": "تشنغفنغ",
                "label_fr": "Chengfeng"
            },
            {
                "value": 1676,
                "label": "Anzhou",
                "label_ar": "انزو",
                "label_fr": "Anzhou"
            },
            {
                "value": 1677,
                "label": "Shangzhang",
                "label_ar": "Shangzhang",
                "label_fr": "Shangzhang"
            },
            {
                "value": 1678,
                "label": "Aishang",
                "label_ar": "ايشانغ",
                "label_fr": "Aishang"
            },
            {
                "value": 1679,
                "label": "Huangjia",
                "label_ar": "هوانغجيا",
                "label_fr": "Huangjia"
            },
            {
                "value": 1680,
                "label": "Oucheng",
                "label_ar": "أوتشينغ",
                "label_fr": "Oucheng"
            },
            {
                "value": 1681,
                "label": "Wangfu",
                "label_ar": "وانغفو",
                "label_fr": "Wangfu"
            },
            {
                "value": 1682,
                "label": "Pengyu",
                "label_ar": "Pengyu",
                "label_fr": "Pengyu"
            },
            {
                "value": 1683,
                "label": "Zhengjiang",
                "label_ar": "Zhengjiang",
                "label_fr": "Zhengjiang"
            },
            {
                "value": 1684,
                "label": "Guangtong",
                "label_ar": "جوانجتونج",
                "label_fr": "Guangtong"
            },
            {
                "value": 1685,
                "label": "Dengzhou",
                "label_ar": "دينجزو",
                "label_fr": "Dengzhou"
            },
            {
                "value": 1686,
                "label": "Dehui",
                "label_ar": "دهوي",
                "label_fr": "Dehui"
            },
            {
                "value": 1687,
                "label": "Yinhai",
                "label_ar": "ينهاي",
                "label_fr": "Yinhai"
            },
            {
                "value": 1688,
                "label": "Libang",
                "label_ar": "ليبانج",
                "label_fr": "Libang"
            },
            {
                "value": 1689,
                "label": "Anbang",
                "label_ar": "أنبانج",
                "label_fr": "Anbang"
            },
            {
                "value": 1690,
                "label": "Jinwo",
                "label_ar": "جينو",
                "label_fr": "Jinwo"
            },
            {
                "value": 1691,
                "label": "Weidun",
                "label_ar": "Weidun",
                "label_fr": "Weidun"
            },
            {
                "value": 1692,
                "label": "Shi'er",
                "label_ar": "شيير",
                "label_fr": "Shi'er"
            },
            {
                "value": 1693,
                "label": "Lincheng",
                "label_ar": "لينتشنغ",
                "label_fr": "Lincheng"
            },
            {
                "value": 1694,
                "label": "Yuanheng",
                "label_ar": "يوانهينغ",
                "label_fr": "Yuanheng"
            },
            {
                "value": 1695,
                "label": "Defeng",
                "label_ar": "ديفنغ",
                "label_fr": "Defeng"
            },
            {
                "value": 1696,
                "label": "Jielong",
                "label_ar": "جيلونج",
                "label_fr": "Jielong"
            },
            {
                "value": 1697,
                "label": "Tianma",
                "label_ar": "تيانما",
                "label_fr": "Tianma"
            },
            {
                "value": 1698,
                "label": "Guangyuan",
                "label_ar": "قوانغيوان",
                "label_fr": "Guangyuan"
            },
            {
                "value": 1699,
                "label": "Caiyuan",
                "label_ar": "كايوان",
                "label_fr": "Caiyuan"
            },
            {
                "value": 1700,
                "label": "Suxi",
                "label_ar": "Suxi",
                "label_fr": "Suxi"
            },
            {
                "value": 1701,
                "label": "Yuyuan",
                "label_ar": "يويوان",
                "label_fr": "Yuyuan"
            },
            {
                "value": 1702,
                "label": "Yongchuan",
                "label_ar": "يونغتشوان",
                "label_fr": "Yongchuan"
            },
            {
                "value": 1703,
                "label": "Mingjing",
                "label_ar": "مينغجينغ",
                "label_fr": "Mingjing"
            },
            {
                "value": 1704,
                "label": "Zhongqiao",
                "label_ar": "Zhongqiao",
                "label_fr": "Zhongqiao"
            },
            {
                "value": 1705,
                "label": "Xiangyi",
                "label_ar": "شيانجي",
                "label_fr": "Xiangyi"
            },
            {
                "value": 1706,
                "label": "Shuangcheng",
                "label_ar": "شوانغتشينغ",
                "label_fr": "Shuangcheng"
            },
            {
                "value": 1707,
                "label": "Deyuan",
                "label_ar": "ديوان",
                "label_fr": "Deyuan"
            },
            {
                "value": 1708,
                "label": "Maodian",
                "label_ar": "Maodian",
                "label_fr": "Maodian"
            },
            {
                "value": 1709,
                "label": "Bangke",
                "label_ar": "بانجكي",
                "label_fr": "Bangke"
            },
            {
                "value": 1710,
                "label": "Jinding",
                "label_ar": "جيندينج",
                "label_fr": "Jinding"
            },
            {
                "value": 1711,
                "label": "Yifeng",
                "label_ar": "يفينغ",
                "label_fr": "Yifeng"
            },
            {
                "value": 1712,
                "label": "Jianyang",
                "label_ar": "جيانيانغ",
                "label_fr": "Jianyang"
            },
            {
                "value": 1713,
                "label": "Shengdu",
                "label_ar": "شينغدو",
                "label_fr": "Shengdu"
            },
            {
                "value": 1714,
                "label": "Lingdong",
                "label_ar": "لينجدونج",
                "label_fr": "Lingdong"
            },
            {
                "value": 1715,
                "label": "Shu'an",
                "label_ar": "شوان",
                "label_fr": "Shu'an"
            },
            {
                "value": 1716,
                "label": "Boli",
                "label_ar": "بولي",
                "label_fr": "Boli"
            },
            {
                "value": 1717,
                "label": "Yutou",
                "label_ar": "يوتو",
                "label_fr": "Yutou"
            },
            {
                "value": 1718,
                "label": "Xiakou",
                "label_ar": "شياكو",
                "label_fr": "Xiakou"
            },
            {
                "value": 1719,
                "label": "Qinghu",
                "label_ar": "تشينغهو",
                "label_fr": "Qinghu"
            },
            {
                "value": 1720,
                "label": "Tanshi",
                "label_ar": "تنشي",
                "label_fr": "Tanshi"
            },
            {
                "value": 1721,
                "label": "Fenglin",
                "label_ar": "فنجلين",
                "label_fr": "Fenglin"
            },
            {
                "value": 1722,
                "label": "Siduxu",
                "label_ar": "Siduxu",
                "label_fr": "Siduxu"
            },
            {
                "value": 1723,
                "label": "Dachen",
                "label_ar": "داتشن",
                "label_fr": "Dachen"
            },
            {
                "value": 1724,
                "label": "Bao'an",
                "label_ar": "باوان",
                "label_fr": "Bao'an"
            },
            {
                "value": 1725,
                "label": "Xiangjiang",
                "label_ar": "شيانغجيانغ",
                "label_fr": "Xiangjiang"
            },
            {
                "value": 1726,
                "label": "Bole",
                "label_ar": "بولي",
                "label_fr": "Fût"
            },
            {
                "value": 1727,
                "label": "Qingxin",
                "label_ar": "تشينغشين",
                "label_fr": "Qingxin"
            },
            {
                "value": 1728,
                "label": "Longtai",
                "label_ar": "لونغتاي",
                "label_fr": "Longtai"
            },
            {
                "value": 1729,
                "label": "Cuntou",
                "label_ar": "كونتو",
                "label_fr": "Cuntou"
            },
            {
                "value": 1730,
                "label": "Majin",
                "label_ar": "ماجين",
                "label_fr": "Majin"
            },
            {
                "value": 1731,
                "label": "Xintangbian",
                "label_ar": "Xintangbian",
                "label_fr": "Xintangbian"
            },
            {
                "value": 1732,
                "label": "Hecun",
                "label_ar": "هيكون",
                "label_fr": "Hecun"
            },
            {
                "value": 1733,
                "label": "Kecheng",
                "label_ar": "Kecheng",
                "label_fr": "Kecheng"
            },
            {
                "value": 1734,
                "label": "Jiangwang",
                "label_ar": "جيانغوانغ",
                "label_fr": "Jiangwang"
            },
            {
                "value": 1735,
                "label": "Xiaosi",
                "label_ar": "شياوسي",
                "label_fr": "Xiaosi"
            },
            {
                "value": 1736,
                "label": "Qingshuiwan",
                "label_ar": "Qingshuiwan",
                "label_fr": "Qingshuiwan"
            },
            {
                "value": 1737,
                "label": "Zhonggongmiao",
                "label_ar": "Zhonggongmiao",
                "label_fr": "Zhonggongmiao"
            },
            {
                "value": 1738,
                "label": "Jinshadi",
                "label_ar": "جينشادي",
                "label_fr": "Jinshadi"
            },
            {
                "value": 1739,
                "label": "Chunjiang",
                "label_ar": "تشونجيانغ",
                "label_fr": "Chunjiang"
            },
            {
                "value": 1740,
                "label": "Baidu",
                "label_ar": "بايدو",
                "label_fr": "Baidu"
            },
            {
                "value": 1741,
                "label": "Fangqiao",
                "label_ar": "فانجكياو",
                "label_fr": "Fangqiao"
            },
            {
                "value": 1742,
                "label": "Dongxin",
                "label_ar": "دونجكسين",
                "label_fr": "Dongxin"
            },
            {
                "value": 1743,
                "label": "Qijiashan",
                "label_ar": "Qijiashan",
                "label_fr": "Qijiashan"
            },
            {
                "value": 1744,
                "label": "Yuanyang",
                "label_ar": "يوانيانغ",
                "label_fr": "Yuanyang"
            },
            {
                "value": 1745,
                "label": "Zhouxiang",
                "label_ar": "Zhouxiang",
                "label_fr": "Zhouxiang"
            },
            {
                "value": 1746,
                "label": "Shengshan",
                "label_ar": "شنغشان",
                "label_fr": "Shengshan"
            },
            {
                "value": 1747,
                "label": "Xingfeng",
                "label_ar": "شينغفنغ",
                "label_fr": "Xingfeng"
            },
            {
                "value": 1748,
                "label": "Jiuba",
                "label_ar": "جيوبا",
                "label_fr": "Jiuba"
            },
            {
                "value": 1749,
                "label": "Lianshan",
                "label_ar": "ليانشان",
                "label_fr": "Lianshan"
            },
            {
                "value": 1750,
                "label": "Xinzhou",
                "label_ar": "شينتشو",
                "label_fr": "Xinzhou"
            },
            {
                "value": 1751,
                "label": "Banxi",
                "label_ar": "بانكسي",
                "label_fr": "Banxi"
            },
            {
                "value": 1752,
                "label": "Dayan",
                "label_ar": "ديان",
                "label_fr": "Dayan"
            },
            {
                "value": 1753,
                "label": "Daxie",
                "label_ar": "داكسي",
                "label_fr": "Daxie"
            },
            {
                "value": 1754,
                "label": "Xizhou",
                "label_ar": "Xizhou",
                "label_fr": "Xizhou"
            },
            {
                "value": 1755,
                "label": "Dancheng",
                "label_ar": "دانتشنغ",
                "label_fr": "Dancheng"
            },
            {
                "value": 1756,
                "label": "Shiqi",
                "label_ar": "شيكي",
                "label_fr": "Shiqi"
            },
            {
                "value": 1757,
                "label": "Kandun",
                "label_ar": "كاندون",
                "label_fr": "Kandun"
            },
            {
                "value": 1758,
                "label": "Kuangyan",
                "label_ar": "كوانجيان",
                "label_fr": "Kuangyan"
            },
            {
                "value": 1759,
                "label": "Lingxi",
                "label_ar": "Lingxi",
                "label_fr": "Lingxi"
            },
            {
                "value": 1760,
                "label": "Baishi",
                "label_ar": "بايشي",
                "label_fr": "Baishi"
            },
            {
                "value": 1761,
                "label": "Feihong",
                "label_ar": "فيهونغ",
                "label_fr": "Feihong"
            },
            {
                "value": 1762,
                "label": "Yangda",
                "label_ar": "يانغدا",
                "label_fr": "Yangda"
            },
            {
                "value": 1763,
                "label": "Jiahao",
                "label_ar": "جياو",
                "label_fr": "Jiahao"
            },
            {
                "value": 1764,
                "label": "Daxing",
                "label_ar": "داشينغ",
                "label_fr": "Daxing"
            },
            {
                "value": 1765,
                "label": "Huatan",
                "label_ar": "هواتان",
                "label_fr": "Huatan"
            },
            {
                "value": 1766,
                "label": "Fuyang",
                "label_ar": "فويانغ",
                "label_fr": "Fuyang"
            },
            {
                "value": 1767,
                "label": "Xushan",
                "label_ar": "زوشان",
                "label_fr": "Xushan"
            },
            {
                "value": 1768,
                "label": "Kang Xian",
                "label_ar": "كانغ شيان",
                "label_fr": "Kang Xian"
            },
            {
                "value": 1769,
                "label": "Qiaoyuan",
                "label_ar": "كياويوان",
                "label_fr": "Qiaoyuan"
            },
            {
                "value": 1770,
                "label": "Zhongyin",
                "label_ar": "Zhongyin",
                "label_fr": "Zhongyin"
            },
            {
                "value": 1771,
                "label": "Yuetai",
                "label_ar": "يويتاي",
                "label_fr": "Yuetai"
            },
            {
                "value": 1772,
                "label": "Qingtian",
                "label_ar": "كينجتيان",
                "label_fr": "Qingtian"
            },
            {
                "value": 1773,
                "label": "Kuishan",
                "label_ar": "كيشان",
                "label_fr": "Kuishan"
            },
            {
                "value": 1774,
                "label": "Mudanjiang",
                "label_ar": "مودانجيانغ",
                "label_fr": "Mudanjiang"
            },
            {
                "value": 1775,
                "label": "Xigazê",
                "label_ar": "Xigazê",
                "label_fr": "Xigazê"
            },
            {
                "value": 1776,
                "label": "Shangnan",
                "label_ar": "Shangnan",
                "label_fr": "Shangnan"
            },
            {
                "value": 1777,
                "label": "Chamdo",
                "label_ar": "شامدو",
                "label_fr": "Chamdo"
            },
            {
                "value": 1778,
                "label": "Nagqu",
                "label_ar": "Nagqu",
                "label_fr": "Nagqu"
            },
            {
                "value": 1779,
                "label": "Dawa",
                "label_ar": "الدعوة",
                "label_fr": "Dawa"
            },
            {
                "value": 1780,
                "label": "Baoluo",
                "label_ar": "باولو",
                "label_fr": "Baoluo"
            },
            {
                "value": 1781,
                "label": "Shanghu",
                "label_ar": "شانغو",
                "label_fr": "Shanghu"
            },
            {
                "value": 1782,
                "label": "Yanggu",
                "label_ar": "يانغو",
                "label_fr": "Yanggu"
            },
            {
                "value": 1783,
                "label": "Kumul",
                "label_ar": "كومول",
                "label_fr": "Kumul"
            },
            {
                "value": 1784,
                "label": "Turpan Diqu",
                "label_ar": "توربان ديكو",
                "label_fr": "Turpan Diqu"
            },
            {
                "value": 1785,
                "label": "Yili",
                "label_ar": "ييلي",
                "label_fr": "Yili"
            },
            {
                "value": 1786,
                "label": "Bole",
                "label_ar": "بولي",
                "label_fr": "Fût"
            },
            {
                "value": 1787,
                "label": "Aksu",
                "label_ar": "أكسو",
                "label_fr": "Aksu"
            },
            {
                "value": 1788,
                "label": "Hetian",
                "label_ar": "هيتيان",
                "label_fr": "Hetian"
            },
            {
                "value": 1789,
                "label": "Bamao",
                "label_ar": "باماو",
                "label_fr": "Bamao"
            },
            {
                "value": 1790,
                "label": "Jimo",
                "label_ar": "جيمو",
                "label_fr": "Jimo"
            },
            {
                "value": 1791,
                "label": "Beijie",
                "label_ar": "بيجي",
                "label_fr": "Beijie"
            },
            {
                "value": 1792,
                "label": "Jindi",
                "label_ar": "جيندي",
                "label_fr": "Jindi"
            },
            {
                "value": 1793,
                "label": "Zhizhong",
                "label_ar": "تشيزونج",
                "label_fr": "Zhizhong"
            },
            {
                "value": 1794,
                "label": "Zhongwei",
                "label_ar": "Zhongwei",
                "label_fr": "Zhongwei"
            },
            {
                "value": 1795,
                "label": "Wuzhong",
                "label_ar": "Wuzhong",
                "label_fr": "Wuzhong"
            },
            {
                "value": 1796,
                "label": "Guyuan",
                "label_ar": "جويوان",
                "label_fr": "Guyuan"
            },
            {
                "value": 1797,
                "label": "Chendu",
                "label_ar": "تشيندو",
                "label_fr": "Chendu"
            },
            {
                "value": 1798,
                "label": "Xiangfeng",
                "label_ar": "شيانغفنغ",
                "label_fr": "Xiangfeng"
            },
            {
                "value": 1799,
                "label": "Bailicun",
                "label_ar": "بيليكون",
                "label_fr": "Bailicun"
            },
            {
                "value": 1800,
                "label": "Huazhou",
                "label_ar": "هوازو",
                "label_fr": "Huazhou"
            },
            {
                "value": 1801,
                "label": "Ruihu",
                "label_ar": "رويهو",
                "label_fr": "Ruihu"
            },
            {
                "value": 1802,
                "label": "Qiaolian",
                "label_ar": "كياوليان",
                "label_fr": "Qiaolian"
            },
            {
                "value": 1803,
                "label": "Huiyang",
                "label_ar": "هوييانغ",
                "label_fr": "Huiyang"
            },
            {
                "value": 1804,
                "label": "Hechi",
                "label_ar": "هيتشي",
                "label_fr": "Hechi"
            },
            {
                "value": 1805,
                "label": "Shennan",
                "label_ar": "شينان",
                "label_fr": "Shennan"
            },
            {
                "value": 1806,
                "label": "Shuangqiao",
                "label_ar": "Shuangqiao",
                "label_fr": "Shuangqiao"
            },
            {
                "value": 1807,
                "label": "Shangqiu",
                "label_ar": "شانغكيو",
                "label_fr": "Shangqiu"
            },
            {
                "value": 1808,
                "label": "Sanmenxia",
                "label_ar": "سانمينشيا",
                "label_fr": "Sanmenxia"
            },
            {
                "value": 1809,
                "label": "Xiantao",
                "label_ar": "زيانتاو",
                "label_fr": "Xiantao"
            },
            {
                "value": 1810,
                "label": "Jingzhou",
                "label_ar": "جينغتشو",
                "label_fr": "Jingzhou"
            },
            {
                "value": 1811,
                "label": "Ezhou",
                "label_ar": "إيزو",
                "label_fr": "Ezhou"
            },
            {
                "value": 1812,
                "label": "Jinzhou",
                "label_ar": "جينتشو",
                "label_fr": "Jinzhou"
            },
            {
                "value": 1813,
                "label": "Suihua",
                "label_ar": "سويوا",
                "label_fr": "Suihua"
            },
            {
                "value": 1814,
                "label": "Danjiang",
                "label_ar": "دانجيانغ",
                "label_fr": "Danjiang"
            },
            {
                "value": 1815,
                "label": "Santai",
                "label_ar": "سانتاي",
                "label_fr": "Santai"
            },
            {
                "value": 1816,
                "label": "Taiping",
                "label_ar": "تايبينغ",
                "label_fr": "Taiping"
            },
            {
                "value": 1817,
                "label": "Yongzhou",
                "label_ar": "يونغتشو",
                "label_fr": "Yongzhou"
            },
            {
                "value": 1818,
                "label": "Taocheng",
                "label_ar": "تاوتشينج",
                "label_fr": "Taocheng"
            },
            {
                "value": 1819,
                "label": "Sanpailou",
                "label_ar": "Sanpailou",
                "label_fr": "Sanpailou"
            },
            {
                "value": 1820,
                "label": "Yangzhong",
                "label_ar": "يانغ تشونغ",
                "label_fr": "Yangzhong"
            },
            {
                "value": 1821,
                "label": "Yicheng",
                "label_ar": "ييتشنغ",
                "label_fr": "Yicheng"
            },
            {
                "value": 1822,
                "label": "Fengxian",
                "label_ar": "فينجسيان",
                "label_fr": "Fengxian"
            },
            {
                "value": 1823,
                "label": "Yuhuayuan",
                "label_ar": "يوهوايوان",
                "label_fr": "Yuhuayuan"
            },
            {
                "value": 1824,
                "label": "Jing'an",
                "label_ar": "جينجان",
                "label_fr": "Jing'an"
            },
            {
                "value": 1825,
                "label": "Zhengdi",
                "label_ar": "Zhengdi",
                "label_fr": "Zhengdi"
            },
            {
                "value": 1826,
                "label": "Haihong",
                "label_ar": "هايهونغ",
                "label_fr": "Haihong"
            },
            {
                "value": 1827,
                "label": "Luwan",
                "label_ar": "لوان",
                "label_fr": "Luwan"
            },
            {
                "value": 1828,
                "label": "Huashan",
                "label_ar": "هواشان",
                "label_fr": "Huashan"
            },
            {
                "value": 1829,
                "label": "Zhongtu",
                "label_ar": "Zhongtu",
                "label_fr": "Zhongtu"
            },
            {
                "value": 1830,
                "label": "Dongxi",
                "label_ar": "دونغكسي",
                "label_fr": "Dongxi"
            },
            {
                "value": 1831,
                "label": "Dongcheng",
                "label_ar": "دونغتشنغ",
                "label_fr": "Dongcheng"
            },
            {
                "value": 1832,
                "label": "Lidu",
                "label_ar": "ليدو",
                "label_fr": "Lidu"
            },
            {
                "value": 1833,
                "label": "Shanrui",
                "label_ar": "شانروي",
                "label_fr": "Shanrui"
            },
            {
                "value": 1834,
                "label": "Yuanpu",
                "label_ar": "يوانبو",
                "label_fr": "Yuanpu"
            },
            {
                "value": 1835,
                "label": "Huajiashan",
                "label_ar": "هواجياشان",
                "label_fr": "Huajiashan"
            },
            {
                "value": 1836,
                "label": "Tianyi",
                "label_ar": "تيان يى",
                "label_fr": "Tianyi"
            },
            {
                "value": 1837,
                "label": "Tianchang",
                "label_ar": "تيانتشانغ",
                "label_fr": "Tianchang"
            },
            {
                "value": 1838,
                "label": "Beiying",
                "label_ar": "بينغ",
                "label_fr": "Beiying"
            },
            {
                "value": 1839,
                "label": "Nanhan",
                "label_ar": "نانهان",
                "label_fr": "Nanhan"
            },
            {
                "value": 1840,
                "label": "Jinci",
                "label_ar": "جينشي",
                "label_fr": "Jinci"
            },
            {
                "value": 1841,
                "label": "Chaicun",
                "label_ar": "تشايكون",
                "label_fr": "Chaicun"
            },
            {
                "value": 1842,
                "label": "Huagong",
                "label_ar": "هواغونغ",
                "label_fr": "Huagong"
            },
            {
                "value": 1843,
                "label": "Dahuaishu",
                "label_ar": "داهوايشو",
                "label_fr": "Dahuaishu"
            },
            {
                "value": 1844,
                "label": "Huozhou",
                "label_ar": "هووتشو",
                "label_fr": "Huozhou"
            },
            {
                "value": 1845,
                "label": "Xincheng",
                "label_ar": "شينتشنغ",
                "label_fr": "Xincheng"
            },
            {
                "value": 1846,
                "label": "Yicheng",
                "label_ar": "ييتشنغ",
                "label_fr": "Yicheng"
            },
            {
                "value": 1847,
                "label": "Changning",
                "label_ar": "تشانغنينغ",
                "label_fr": "Changning"
            },
            {
                "value": 1848,
                "label": "Qingyang",
                "label_ar": "تشينغيانغ",
                "label_fr": "Qingyang"
            },
            {
                "value": 1849,
                "label": "Pucheng",
                "label_ar": "بوتشنغ",
                "label_fr": "Pucheng"
            },
            {
                "value": 1850,
                "label": "Kouquan",
                "label_ar": "كوكوان",
                "label_fr": "Kouquan"
            },
            {
                "value": 1851,
                "label": "Beixiao",
                "label_ar": "بيكسياو",
                "label_fr": "Beixiao"
            },
            {
                "value": 1852,
                "label": "Yuanqu",
                "label_ar": "يوانكو",
                "label_fr": "Yuanqu"
            },
            {
                "value": 1853,
                "label": "Yuncheng",
                "label_ar": "يونتشنغ",
                "label_fr": "Yuncheng"
            },
            {
                "value": 1854,
                "label": "Siyang",
                "label_ar": "سيانغ",
                "label_fr": "Siyang"
            },
            {
                "value": 1855,
                "label": "Benxi",
                "label_ar": "بنشي",
                "label_fr": "Benxi"
            },
            {
                "value": 1856,
                "label": "Wuzhen",
                "label_ar": "Wuzhen",
                "label_fr": "Wuzhen"
            },
            {
                "value": 1857,
                "label": "Tieling",
                "label_ar": "تيلنج",
                "label_fr": "Tieling"
            },
            {
                "value": 1858,
                "label": "Langzhong",
                "label_ar": "لانغزونغ",
                "label_fr": "Langzhong"
            },
            {
                "value": 1859,
                "label": "Yuzi",
                "label_ar": "يوزي",
                "label_fr": "Yuzi"
            },
            {
                "value": 1860,
                "label": "Jishu",
                "label_ar": "جيشو",
                "label_fr": "Jishu"
            },
            {
                "value": 1861,
                "label": "Nanda",
                "label_ar": "ناندا",
                "label_fr": "Nanda"
            },
            {
                "value": 1862,
                "label": "Huamei",
                "label_ar": "هوامي",
                "label_fr": "Huamei"
            },
            {
                "value": 1863,
                "label": "Yanta",
                "label_ar": "يانتا",
                "label_fr": "Yanta"
            },
            {
                "value": 1864,
                "label": "Tangxi",
                "label_ar": "تانغشي",
                "label_fr": "Tangxi"
            },
            {
                "value": 1865,
                "label": "Huaxing",
                "label_ar": "هواكسينج",
                "label_fr": "Huaxing"
            },
            {
                "value": 1866,
                "label": "Tancheng",
                "label_ar": "تانتشنغ",
                "label_fr": "Tancheng"
            },
            {
                "value": 1867,
                "label": "Xiazhuang",
                "label_ar": "شيازوانغ",
                "label_fr": "Xiazhuang"
            },
            {
                "value": 1868,
                "label": "Liu'an",
                "label_ar": "ليوان",
                "label_fr": "Liu'an"
            },
            {
                "value": 1869,
                "label": "Liucheng",
                "label_ar": "ليوتشنغ",
                "label_fr": "Liucheng"
            },
            {
                "value": 1870,
                "label": "Guigang",
                "label_ar": "Guigang",
                "label_fr": "Guigang"
            },
            {
                "value": 1871,
                "label": "Bobai",
                "label_ar": "بوباي",
                "label_fr": "Bobai"
            },
            {
                "value": 1872,
                "label": "Yongning",
                "label_ar": "يونغ نينغ",
                "label_fr": "Yongning"
            },
            {
                "value": 1873,
                "label": "Hezhou",
                "label_ar": "هتشو",
                "label_fr": "Hezhou"
            },
            {
                "value": 1874,
                "label": "Hecheng",
                "label_ar": "هيتشنغ",
                "label_fr": "Hecheng"
            },
            {
                "value": 1875,
                "label": "Zhongzhou",
                "label_ar": "تشونغتشو",
                "label_fr": "Zhongzhou"
            },
            {
                "value": 1876,
                "label": "Jinyin",
                "label_ar": "جينين",
                "label_fr": "Jinyin"
            },
            {
                "value": 1877,
                "label": "Niulanshan",
                "label_ar": "نيولانشان",
                "label_fr": "Niulanshan"
            },
            {
                "value": 1878,
                "label": "Huairou",
                "label_ar": "هوايرو",
                "label_fr": "Huairou"
            },
            {
                "value": 1879,
                "label": "Qiandao",
                "label_ar": "Qiandao",
                "label_fr": "Qiandao"
            },
            {
                "value": 1880,
                "label": "Huangyuan Chengguanzhen",
                "label_ar": "Huangyuan Chengguanzhen",
                "label_fr": "Huangyuan Chengguanzhen"
            },
            {
                "value": 1881,
                "label": "Xishan",
                "label_ar": "شيشان",
                "label_fr": "Xishan"
            },
            {
                "value": 1882,
                "label": "Beiyuan",
                "label_ar": "بييوان",
                "label_fr": "Beiyuan"
            },
            {
                "value": 1883,
                "label": "Beixi",
                "label_ar": "بيكسي",
                "label_fr": "Beixi"
            },
            {
                "value": 1884,
                "label": "Xingcheng",
                "label_ar": "Xingcheng",
                "label_fr": "Xingcheng"
            },
            {
                "value": 1885,
                "label": "Zhongyuan",
                "label_ar": "تشونغ يوان",
                "label_fr": "Zhongyuan"
            },
            {
                "value": 1886,
                "label": "Pingyi",
                "label_ar": "بينجي",
                "label_fr": "Pingyi"
            },
            {
                "value": 1887,
                "label": "Shijingxiang",
                "label_ar": "شيجينغشيانغ",
                "label_fr": "Shijingxiang"
            },
            {
                "value": 1888,
                "label": "Guili",
                "label_ar": "جيلي",
                "label_fr": "Guili"
            },
            {
                "value": 1889,
                "label": "Huai'an",
                "label_ar": "هوايان",
                "label_fr": "Huai'an"
            },
            {
                "value": 1890,
                "label": "Shenyan",
                "label_ar": "شنيان",
                "label_fr": "Shenyan"
            },
            {
                "value": 1891,
                "label": "Dianzi",
                "label_ar": "ديانزي",
                "label_fr": "Dianzi"
            },
            {
                "value": 1892,
                "label": "Lintong",
                "label_ar": "لينتونج",
                "label_fr": "Lintong"
            },
            {
                "value": 1893,
                "label": "Hebei",
                "label_ar": "خبى",
                "label_fr": "Hebei"
            },
            {
                "value": 1894,
                "label": "Huangdao",
                "label_ar": "هوانغداو",
                "label_fr": "Huangdao"
            },
            {
                "value": 1895,
                "label": "Baotou",
                "label_ar": "باوتو",
                "label_fr": "Baotou"
            },
            {
                "value": 1896,
                "label": "Hailar",
                "label_ar": "هيلار",
                "label_fr": "Hailar"
            },
            {
                "value": 1897,
                "label": "Fendou",
                "label_ar": "فيندو",
                "label_fr": "Fendou"
            },
            {
                "value": 1898,
                "label": "Edong",
                "label_ar": "ايدونج",
                "label_fr": "Edong"
            },
            {
                "value": 1899,
                "label": "Xingye",
                "label_ar": "Xingye",
                "label_fr": "Xingye"
            },
            {
                "value": 1900,
                "label": "Hezuo",
                "label_ar": "هيزو",
                "label_fr": "Hezuo"
            },
            {
                "value": 1901,
                "label": "Yingchuan",
                "label_ar": "ينغتشوان",
                "label_fr": "Yingchuan"
            },
            {
                "value": 1902,
                "label": "Zhangshu",
                "label_ar": "تشانغشو",
                "label_fr": "Zhangshu"
            },
            {
                "value": 1903,
                "label": "Nanjin",
                "label_ar": "نانجين",
                "label_fr": "Nanjin"
            },
            {
                "value": 1904,
                "label": "Qiongshan",
                "label_ar": "تشيونغشان",
                "label_fr": "Qiongshan"
            },
            {
                "value": 1905,
                "label": "Jiaying",
                "label_ar": "جياينج",
                "label_fr": "Jiaying"
            },
            {
                "value": 1906,
                "label": "Mengzi",
                "label_ar": "مينجزي",
                "label_fr": "Mengzi"
            },
            {
                "value": 1907,
                "label": "Panzhihua",
                "label_ar": "بانتشيهوا",
                "label_fr": "Panzhihua"
            },
            {
                "value": 1908,
                "label": "Zigong",
                "label_ar": "تسيغونغ",
                "label_fr": "Zigong"
            },
            {
                "value": 1909,
                "label": "Danjiangkou",
                "label_ar": "دانجيانجكو",
                "label_fr": "Danjiangkou"
            },
            {
                "value": 1910,
                "label": "Anda",
                "label_ar": "أندا",
                "label_fr": "Anda"
            },
            {
                "value": 1911,
                "label": "Hulan",
                "label_ar": "هولان",
                "label_fr": "Hulan"
            },
            {
                "value": 1912,
                "label": "Hekou",
                "label_ar": "هيكو",
                "label_fr": "Hekou"
            },
            {
                "value": 1913,
                "label": "Xuanwu",
                "label_ar": "شوانوو",
                "label_fr": "Xuanwu"
            },
            {
                "value": 1914,
                "label": "Shengfang",
                "label_ar": "Shengfang",
                "label_fr": "Shengfang"
            },
            {
                "value": 1915,
                "label": "Huabei",
                "label_ar": "Huabei",
                "label_fr": "Huabei"
            },
            {
                "value": 1916,
                "label": "Huangzhou",
                "label_ar": "هوانغتشو",
                "label_fr": "Huangzhou"
            },
            {
                "value": 1917,
                "label": "Zhangjiagang",
                "label_ar": "تشانغجياجانج",
                "label_fr": "Zhangjiagang"
            },
            {
                "value": 1918,
                "label": "Wanxi",
                "label_ar": "وانشى",
                "label_fr": "Wanxi"
            },
            {
                "value": 1919,
                "label": "Cangzhou",
                "label_ar": "تسانغتشو",
                "label_fr": "Cangzhou"
            },
            {
                "value": 1920,
                "label": "Zhengding",
                "label_ar": "تشنغدينغ",
                "label_fr": "Zhengding"
            },
            {
                "value": 1921,
                "label": "Luocheng",
                "label_ar": "لوشنغ",
                "label_fr": "Luocheng"
            },
            {
                "value": 1922,
                "label": "Yunnan",
                "label_ar": "يونان",
                "label_fr": "Yunnan"
            },
            {
                "value": 1923,
                "label": "Baliqiao",
                "label_ar": "باليكياو",
                "label_fr": "Baliqiao"
            },
            {
                "value": 1924,
                "label": "Jiaxin",
                "label_ar": "جياكسين",
                "label_fr": "Jiaxin"
            },
            {
                "value": 1925,
                "label": "Shangxi",
                "label_ar": "Shangxi",
                "label_fr": "Shangxi"
            },
            {
                "value": 1926,
                "label": "Xiuyu",
                "label_ar": "Xiuyu",
                "label_fr": "Xiuyu"
            },
            {
                "value": 1927,
                "label": "Chengxiang",
                "label_ar": "تشنغشيانغ",
                "label_fr": "Chengxiang"
            },
            {
                "value": 1928,
                "label": "Xianyou",
                "label_ar": "Xianyou",
                "label_fr": "Xianyou"
            },
            {
                "value": 1929,
                "label": "Guyong",
                "label_ar": "جويونج",
                "label_fr": "Guyong"
            },
            {
                "value": 1930,
                "label": "Qidu",
                "label_ar": "Qidu",
                "label_fr": "Qidu"
            },
            {
                "value": 1931,
                "label": "Shuangcheng",
                "label_ar": "شوانغتشينغ",
                "label_fr": "Shuangcheng"
            },
            {
                "value": 1932,
                "label": "Xiapu",
                "label_ar": "شيابو",
                "label_fr": "Xiapu"
            },
            {
                "value": 1933,
                "label": "Fuding",
                "label_ar": "فودينج",
                "label_fr": "Fuding"
            },
            {
                "value": 1934,
                "label": "Ping'an",
                "label_ar": "بينجان",
                "label_fr": "Ping'an"
            },
            {
                "value": 1935,
                "label": "Jin Jiang",
                "label_ar": "جين جيانغ",
                "label_fr": "Jin Jiang"
            },
            {
                "value": 1936,
                "label": "Luqiao",
                "label_ar": "Luqiao",
                "label_fr": "Luqiao"
            },
            {
                "value": 1937,
                "label": "Anxi",
                "label_ar": "Anxi",
                "label_fr": "Anxiété"
            },
            {
                "value": 1938,
                "label": "Longmen",
                "label_ar": "لونجمين",
                "label_fr": "Longmen"
            },
            {
                "value": 1939,
                "label": "Hongshan",
                "label_ar": "هونغشان",
                "label_fr": "Hongshan"
            },
            {
                "value": 1940,
                "label": "Taoxi",
                "label_ar": "تاوكسي",
                "label_fr": "Taoxi"
            },
            {
                "value": 1941,
                "label": "Changting",
                "label_ar": "تشانغتينغ",
                "label_fr": "Changting"
            },
            {
                "value": 1942,
                "label": "Lianjiang",
                "label_ar": "ليانجيانغ",
                "label_fr": "Lianjiang"
            },
            {
                "value": 1943,
                "label": "Yanshi",
                "label_ar": "يانشي",
                "label_fr": "Yanshi"
            },
            {
                "value": 1944,
                "label": "Dayang",
                "label_ar": "دايانغ",
                "label_fr": "Dayang"
            },
            {
                "value": 1945,
                "label": "Dongxiao",
                "label_ar": "Dongxiao",
                "label_fr": "Dongxiao"
            },
            {
                "value": 1946,
                "label": "Xinmin",
                "label_ar": "شينمين",
                "label_fr": "Xinmin"
            },
            {
                "value": 1947,
                "label": "Zhangjiajie",
                "label_ar": "تشانغجياجيه",
                "label_fr": "Zhangjiajie"
            },
            {
                "value": 1948,
                "label": "Side",
                "label_ar": "جانب",
                "label_fr": "Côté"
            },
            {
                "value": 1949,
                "label": "Liaoyuan",
                "label_ar": "لياويوان",
                "label_fr": "Liaoyuan"
            },
            {
                "value": 1950,
                "label": "Dongliu",
                "label_ar": "Dongliu",
                "label_fr": "Dongliu"
            },
            {
                "value": 1951,
                "label": "Shifang",
                "label_ar": "شيفانغ",
                "label_fr": "Shifang"
            },
            {
                "value": 1952,
                "label": "Caoxi",
                "label_ar": "كاوكسي",
                "label_fr": "Caoxi"
            },
            {
                "value": 1953,
                "label": "Xin'an",
                "label_ar": "شينان",
                "label_fr": "Xin'an"
            },
            {
                "value": 1954,
                "label": "Longdong",
                "label_ar": "لونجدونج",
                "label_fr": "Longdong"
            },
            {
                "value": 1955,
                "label": "Shangbao",
                "label_ar": "شانغباو",
                "label_fr": "Shangbao"
            },
            {
                "value": 1956,
                "label": "Gaoqiao",
                "label_ar": "جاوتشياو",
                "label_fr": "Gaoqiao"
            },
            {
                "value": 1957,
                "label": "Hongyangshu",
                "label_ar": "هونغيانغشو",
                "label_fr": "Hongyangshu"
            },
            {
                "value": 1958,
                "label": "Lugouqiao",
                "label_ar": "لوغوتشياو",
                "label_fr": "Lugouqiao"
            },
            {
                "value": 1959,
                "label": "Tianshan",
                "label_ar": "تيانشان",
                "label_fr": "Tianshan"
            },
            {
                "value": 1960,
                "label": "Jinqiao",
                "label_ar": "جينكياو",
                "label_fr": "Jinqiao"
            },
            {
                "value": 1961,
                "label": "Yongjiu",
                "label_ar": "يونغجيو",
                "label_fr": "Yongjiu"
            },
            {
                "value": 1962,
                "label": "Huaxia",
                "label_ar": "هواشيا",
                "label_fr": "Huaxia"
            },
            {
                "value": 1963,
                "label": "Xingyuan",
                "label_ar": "شينغ يوان",
                "label_fr": "Xingyuan"
            },
            {
                "value": 1964,
                "label": "Kunlun",
                "label_ar": "كونلون",
                "label_fr": "Kunlun"
            },
            {
                "value": 1965,
                "label": "Shangzhi",
                "label_ar": "Shangzhi",
                "label_fr": "Shangzhi"
            },
            {
                "value": 1966,
                "label": "Keshan",
                "label_ar": "كيشان",
                "label_fr": "Keshan"
            },
            {
                "value": 1967,
                "label": "Tailai",
                "label_ar": "تايلي",
                "label_fr": "Tailai"
            },
            {
                "value": 1968,
                "label": "Longjiang",
                "label_ar": "لونغجيانغ",
                "label_fr": "Longjiang"
            },
            {
                "value": 1969,
                "label": "Gannan",
                "label_ar": "قنان",
                "label_fr": "Gannan"
            },
            {
                "value": 1970,
                "label": "Hailin",
                "label_ar": "هايلين",
                "label_fr": "Hailin"
            },
            {
                "value": 1971,
                "label": "Dongning",
                "label_ar": "دونغنينغ",
                "label_fr": "Dongning"
            },
            {
                "value": 1972,
                "label": "Muling",
                "label_ar": "مولينج",
                "label_fr": "Muling"
            },
            {
                "value": 1973,
                "label": "Linkou",
                "label_ar": "لينكو",
                "label_fr": "Linkou"
            },
            {
                "value": 1974,
                "label": "Ning'an",
                "label_ar": "نينغان",
                "label_fr": "Ning'an"
            },
            {
                "value": 1975,
                "label": "Pengcheng",
                "label_ar": "بنغشنغ",
                "label_fr": "Pengcheng"
            },
            {
                "value": 1976,
                "label": "Tangyuan",
                "label_ar": "تانجيوان",
                "label_fr": "Tangyuan"
            },
            {
                "value": 1977,
                "label": "Huanan",
                "label_ar": "هوانان",
                "label_fr": "Huanan"
            },
            {
                "value": 1978,
                "label": "Daqing",
                "label_ar": "داتشينغ",
                "label_fr": "Daqing"
            },
            {
                "value": 1979,
                "label": "Qinggang",
                "label_ar": "تشينغغانغ",
                "label_fr": "Qinggang"
            },
            {
                "value": 1980,
                "label": "Mishan",
                "label_ar": "ميشان",
                "label_fr": "Mishan"
            },
            {
                "value": 1981,
                "label": "Huixin",
                "label_ar": "هويشين",
                "label_fr": "Huixin"
            },
            {
                "value": 1982,
                "label": "Shuangyashan",
                "label_ar": "شوانجياشان",
                "label_fr": "Shuangyashan"
            },
            {
                "value": 1983,
                "label": "Baoqing",
                "label_ar": "باوكينج",
                "label_fr": "Baoqing"
            },
            {
                "value": 1984,
                "label": "Hegang",
                "label_ar": "هيغانغ",
                "label_fr": "Hegang"
            },
            {
                "value": 1985,
                "label": "Mingshui",
                "label_ar": "مينغشوي",
                "label_fr": "Mingshui"
            },
            {
                "value": 1986,
                "label": "Beishan",
                "label_ar": "بيشان",
                "label_fr": "Beishan"
            },
            {
                "value": 1987,
                "label": "Liuhe",
                "label_ar": "ليوهي",
                "label_fr": "Liuhe"
            },
            {
                "value": 1988,
                "label": "Jinshi",
                "label_ar": "جينشي",
                "label_fr": "Jinshi"
            },
            {
                "value": 1989,
                "label": "Xinyi",
                "label_ar": "شيني",
                "label_fr": "Xinyi"
            },
            {
                "value": 1990,
                "label": "Fengzhou",
                "label_ar": "فنغتشو",
                "label_fr": "Fengzhou"
            },
            {
                "value": 1991,
                "label": "Chengnan",
                "label_ar": "تشنغنان",
                "label_fr": "Chengnan"
            },
            {
                "value": 1992,
                "label": "Renrong",
                "label_ar": "رينرونج",
                "label_fr": "Renrong"
            },
            {
                "value": 1993,
                "label": "Heyuan",
                "label_ar": "هيوان",
                "label_fr": "Heyuan"
            },
            {
                "value": 1994,
                "label": "Longgong",
                "label_ar": "لونغ قونغ",
                "label_fr": "Longgong"
            },
            {
                "value": 1995,
                "label": "Shancheng",
                "label_ar": "شانتشنغ",
                "label_fr": "Shancheng"
            },
            {
                "value": 1996,
                "label": "Buxin",
                "label_ar": "بوكسين",
                "label_fr": "Buxin"
            },
            {
                "value": 1997,
                "label": "Jingbei",
                "label_ar": "جينغبى",
                "label_fr": "Jingbei"
            },
            {
                "value": 1998,
                "label": "Bibo",
                "label_ar": "بيبو",
                "label_fr": "Bibo"
            },
            {
                "value": 1999,
                "label": "Jintian",
                "label_ar": "جينتيان",
                "label_fr": "Jintian"
            },
            {
                "value": 2000,
                "label": "Huali",
                "label_ar": "هوالي",
                "label_fr": "Huali"
            },
            {
                "value": 2001,
                "label": "Luoling",
                "label_ar": "Luoling",
                "label_fr": "Luoling"
            },
            {
                "value": 2002,
                "label": "Cuiyuan",
                "label_ar": "كوييوان",
                "label_fr": "Cuiyuan"
            },
            {
                "value": 2003,
                "label": "Beidou",
                "label_ar": "بيدو",
                "label_fr": "Beidou"
            },
            {
                "value": 2004,
                "label": "Cuizhu",
                "label_ar": "كويزو",
                "label_fr": "Cuizhu"
            },
            {
                "value": 2005,
                "label": "Cuibei",
                "label_ar": "كويبي",
                "label_fr": "Cuibei"
            },
            {
                "value": 2006,
                "label": "Shuitian",
                "label_ar": "شويتيان",
                "label_fr": "Shuitian"
            },
            {
                "value": 2007,
                "label": "Shuiku",
                "label_ar": "شويكو",
                "label_fr": "Shuiku"
            },
            {
                "value": 2008,
                "label": "Sungang",
                "label_ar": "سونغانغ",
                "label_fr": "Sungang"
            },
            {
                "value": 2009,
                "label": "Liyuan",
                "label_ar": "ليوان",
                "label_fr": "Liyuan"
            },
            {
                "value": 2010,
                "label": "Caopu",
                "label_ar": "كاوبو",
                "label_fr": "Caopu"
            },
            {
                "value": 2011,
                "label": "Yuanling",
                "label_ar": "يوانلينج",
                "label_fr": "Yuanling"
            },
            {
                "value": 2012,
                "label": "Hongling",
                "label_ar": "هونغلينغ",
                "label_fr": "Hongling"
            },
            {
                "value": 2013,
                "label": "Baihua",
                "label_ar": "بايهوا",
                "label_fr": "Baihua"
            },
            {
                "value": 2014,
                "label": "Huaxin",
                "label_ar": "هوا شين",
                "label_fr": "Huaxin"
            },
            {
                "value": 2015,
                "label": "Guiyuan",
                "label_ar": "جويوان",
                "label_fr": "Guiyuan"
            },
            {
                "value": 2016,
                "label": "Binhe",
                "label_ar": "بينهي",
                "label_fr": "Binhe"
            },
            {
                "value": 2017,
                "label": "Nanhu",
                "label_ar": "نانهو",
                "label_fr": "Nanhu"
            },
            {
                "value": 2018,
                "label": "Xingang",
                "label_ar": "شينغانغ",
                "label_fr": "Xingang"
            },
            {
                "value": 2019,
                "label": "Shangbu",
                "label_ar": "شانغبو",
                "label_fr": "Shangbu"
            },
            {
                "value": 2020,
                "label": "Tongyuan",
                "label_ar": "تونغيوان",
                "label_fr": "Tongyuan"
            },
            {
                "value": 2021,
                "label": "Fumin",
                "label_ar": "فومين",
                "label_fr": "Fumin"
            },
            {
                "value": 2022,
                "label": "Shixia",
                "label_ar": "شيكسيا",
                "label_fr": "Shixia"
            },
            {
                "value": 2023,
                "label": "Xinsha",
                "label_ar": "Xinsha",
                "label_fr": "Xinsha"
            },
            {
                "value": 2024,
                "label": "Shangsha",
                "label_ar": "Shangsha",
                "label_fr": "Shangsha"
            },
            {
                "value": 2025,
                "label": "Zhongfu",
                "label_ar": "Zhongfu",
                "label_fr": "Zhongfu"
            },
            {
                "value": 2026,
                "label": "Yitian",
                "label_ar": "يتيان",
                "label_fr": "Yitian"
            },
            {
                "value": 2027,
                "label": "Fuqiang",
                "label_ar": "فوكيانغ",
                "label_fr": "Fuqiang"
            },
            {
                "value": 2028,
                "label": "Jinglong",
                "label_ar": "جينجلونج",
                "label_fr": "Jinglong"
            },
            {
                "value": 2029,
                "label": "Huaqiang",
                "label_ar": "هوا تشيانغ",
                "label_fr": "Huaqiang"
            },
            {
                "value": 2030,
                "label": "Meilian",
                "label_ar": "ميليان",
                "label_fr": "Meilian"
            },
            {
                "value": 2031,
                "label": "Jinglian",
                "label_ar": "جينجليان",
                "label_fr": "Jinglian"
            },
            {
                "value": 2032,
                "label": "Shiling",
                "label_ar": "شيلينج",
                "label_fr": "Shiling"
            },
            {
                "value": 2033,
                "label": "Meilin",
                "label_ar": "ميلين",
                "label_fr": "Meilin"
            },
            {
                "value": 2034,
                "label": "Yaoluoping",
                "label_ar": "Yaoluoping",
                "label_fr": "Yaoluoping"
            },
            {
                "value": 2035,
                "label": "Sanjiu",
                "label_ar": "سانجيو",
                "label_fr": "Sanjiu"
            },
            {
                "value": 2036,
                "label": "Zhuxiang",
                "label_ar": "تشوكسيانغ",
                "label_fr": "Zhuxiang"
            },
            {
                "value": 2037,
                "label": "Zhulin",
                "label_ar": "زولين",
                "label_fr": "Zhulin"
            },
            {
                "value": 2038,
                "label": "Zhuyuan",
                "label_ar": "Zhuyuan",
                "label_fr": "Zhuyuan"
            },
            {
                "value": 2039,
                "label": "Yunfeng",
                "label_ar": "يونفنغ",
                "label_fr": "Yunfeng"
            },
            {
                "value": 2040,
                "label": "Shizheng",
                "label_ar": "شيزنغ",
                "label_fr": "Shizheng"
            },
            {
                "value": 2041,
                "label": "Jinzhong",
                "label_ar": "جينتشونغ",
                "label_fr": "Jinzhong"
            },
            {
                "value": 2042,
                "label": "Shuiwu",
                "label_ar": "شويوو",
                "label_fr": "Shuiwu"
            },
            {
                "value": 2043,
                "label": "Zongxoi",
                "label_ar": "زونجكسوي",
                "label_fr": "Zongxoi"
            },
            {
                "value": 2044,
                "label": "Dianzhong",
                "label_ar": "Dianzhong",
                "label_fr": "Dianzhong"
            },
            {
                "value": 2045,
                "label": "Shizhong",
                "label_ar": "شيزونغ",
                "label_fr": "Shizhong"
            },
            {
                "value": 2046,
                "label": "Dezhou",
                "label_ar": "دزو",
                "label_fr": "Dezhou"
            },
            {
                "value": 2047,
                "label": "Baiyashi",
                "label_ar": "بياشي",
                "label_fr": "Baiyashi"
            },
            {
                "value": 2048,
                "label": "Minzhu",
                "label_ar": "مينزو",
                "label_fr": "Minzhu"
            },
            {
                "value": 2049,
                "label": "Xiping",
                "label_ar": "Xiping",
                "label_fr": "Xiping"
            },
            {
                "value": 2050,
                "label": "Danfeng",
                "label_ar": "دانفنغ",
                "label_fr": "Danfeng"
            },
            {
                "value": 2051,
                "label": "Ganzhou",
                "label_ar": "قانتشو",
                "label_fr": "Ganzhou"
            },
            {
                "value": 2052,
                "label": "Yangcun",
                "label_ar": "يانغكون",
                "label_fr": "Yangcun"
            },
            {
                "value": 2053,
                "label": "Minzu",
                "label_ar": "مينزو",
                "label_fr": "Minzu"
            },
            {
                "value": 2054,
                "label": "Junlin",
                "label_ar": "جونلين",
                "label_fr": "Junlin"
            },
            {
                "value": 2055,
                "label": "Xinguang",
                "label_ar": "Xinguang",
                "label_fr": "Xinguang"
            },
            {
                "value": 2056,
                "label": "Jinxing",
                "label_ar": "جينشينغ",
                "label_fr": "Jinxing"
            },
            {
                "value": 2057,
                "label": "Ganjiang",
                "label_ar": "جانجيانغ",
                "label_fr": "Ganjiang"
            },
            {
                "value": 2058,
                "label": "Liudao",
                "label_ar": "ليوداو",
                "label_fr": "Liudao"
            },
            {
                "value": 2059,
                "label": "Shamen",
                "label_ar": "شامين",
                "label_fr": "Shamen"
            },
            {
                "value": 2060,
                "label": "Huanzhen",
                "label_ar": "هوانتشن",
                "label_fr": "Huanzhen"
            },
            {
                "value": 2061,
                "label": "Shuanglong",
                "label_ar": "Shuanglong",
                "label_fr": "Shuanglong"
            },
            {
                "value": 2062,
                "label": "Wenlin",
                "label_ar": "وينلين",
                "label_fr": "Wenlin"
            },
            {
                "value": 2063,
                "label": "Biyang",
                "label_ar": "بيانج",
                "label_fr": "Biyang"
            },
            {
                "value": 2064,
                "label": "Nantang",
                "label_ar": "نانتانغ",
                "label_fr": "Nantang"
            },
            {
                "value": 2065,
                "label": "Lecheng",
                "label_ar": "ليتشنغ",
                "label_fr": "Lecheng"
            },
            {
                "value": 2066,
                "label": "Yandang",
                "label_ar": "ياندانغ",
                "label_fr": "Yandang"
            },
            {
                "value": 2067,
                "label": "Xin'anjiang",
                "label_ar": "شينجيانغ",
                "label_fr": "Xin'anjiang"
            },
            {
                "value": 2068,
                "label": "Huanghu",
                "label_ar": "هوانغو",
                "label_fr": "Huanghu"
            },
            {
                "value": 2069,
                "label": "Jiuguan",
                "label_ar": "جيوجوان",
                "label_fr": "Jiuguan"
            },
            {
                "value": 2070,
                "label": "Deyue",
                "label_ar": "ديو",
                "label_fr": "Deyue"
            },
            {
                "value": 2071,
                "label": "Yingtai",
                "label_ar": "ينجتاى",
                "label_fr": "Yingtai"
            },
            {
                "value": 2072,
                "label": "Chengxi",
                "label_ar": "Chengxi",
                "label_fr": "Chengxi"
            },
            {
                "value": 2073,
                "label": "Luobu",
                "label_ar": "لوبو",
                "label_fr": "Luobu"
            },
            {
                "value": 2074,
                "label": "Houzhai",
                "label_ar": "هوزاي",
                "label_fr": "Houzhai"
            },
            {
                "value": 2075,
                "label": "Eshan",
                "label_ar": "ايشان",
                "label_fr": "Eshan"
            },
            {
                "value": 2076,
                "label": "Huayue",
                "label_ar": "هوايو",
                "label_fr": "Huayue"
            },
            {
                "value": 2077,
                "label": "Xiyue",
                "label_ar": "شيويه",
                "label_fr": "Xiyue"
            },
            {
                "value": 2078,
                "label": "Nanmen",
                "label_ar": "نانمين",
                "label_fr": "Nanmen"
            },
            {
                "value": 2079,
                "label": "Fenshang",
                "label_ar": "فنشانغ",
                "label_fr": "Fenshang"
            },
            {
                "value": 2080,
                "label": "Jishou",
                "label_ar": "جيشو",
                "label_fr": "Jishou"
            },
            {
                "value": 2081,
                "label": "Xiatangyunxiang",
                "label_ar": "Xiatangyunxiang",
                "label_fr": "Xiatangyunxiang"
            },
            {
                "value": 2082,
                "label": "Yalongwan",
                "label_ar": "يالونغوان",
                "label_fr": "Yalongwan"
            },
            {
                "value": 2083,
                "label": "Yangce",
                "label_ar": "يانغتشي",
                "label_fr": "Yangce"
            },
            {
                "value": 2084,
                "label": "Longxing",
                "label_ar": "لونجكسينج",
                "label_fr": "Longxing"
            },
            {
                "value": 2085,
                "label": "Liujian",
                "label_ar": "ليوجيان",
                "label_fr": "Liujian"
            },
            {
                "value": 2086,
                "label": "Tangchang",
                "label_ar": "تانجتشانج",
                "label_fr": "Tangchang"
            },
            {
                "value": 2087,
                "label": "Gaiwei",
                "label_ar": "جايوي",
                "label_fr": "Gaiwei"
            },
            {
                "value": 2088,
                "label": "Xinyou",
                "label_ar": "شينيو",
                "label_fr": "Xinyou"
            },
            {
                "value": 2089,
                "label": "Bantou",
                "label_ar": "بانتو",
                "label_fr": "Bantou"
            },
            {
                "value": 2090,
                "label": "Daji",
                "label_ar": "دجي",
                "label_fr": "Daji"
            },
            {
                "value": 2091,
                "label": "Duwei",
                "label_ar": "Duwei",
                "label_fr": "Duwei"
            },
            {
                "value": 2092,
                "label": "Jiaowei",
                "label_ar": "جياووي",
                "label_fr": "Jiaowei"
            },
            {
                "value": 2093,
                "label": "Chenyang",
                "label_ar": "تشينيانغ",
                "label_fr": "Chenyang"
            },
            {
                "value": 2094,
                "label": "Bangtou",
                "label_ar": "بانغتو",
                "label_fr": "Bangtou"
            },
            {
                "value": 2095,
                "label": "Fengshan",
                "label_ar": "فنغشان",
                "label_fr": "Fengshan"
            },
            {
                "value": 2096,
                "label": "Baitang",
                "label_ar": "بيتانغ",
                "label_fr": "Baitang"
            },
            {
                "value": 2097,
                "label": "Sanjiangkou",
                "label_ar": "Sanjiangkou",
                "label_fr": "Sanjiangkou"
            },
            {
                "value": 2098,
                "label": "Lihou",
                "label_ar": "ليهو",
                "label_fr": "Lihou"
            },
            {
                "value": 2099,
                "label": "Fengting",
                "label_ar": "فينجتينج",
                "label_fr": "Fengting"
            },
            {
                "value": 2100,
                "label": "Fengcheng",
                "label_ar": "فنغتشنغ",
                "label_fr": "Fengcheng"
            },
            {
                "value": 2101,
                "label": "Rongjin",
                "label_ar": "رونغجين",
                "label_fr": "Rongjin"
            },
            {
                "value": 2102,
                "label": "Luoyuan",
                "label_ar": "لويوان",
                "label_fr": "Luoyuan"
            },
            {
                "value": 2103,
                "label": "Minjie",
                "label_ar": "مينجي",
                "label_fr": "Minjie"
            },
            {
                "value": 2104,
                "label": "Tangcheng",
                "label_ar": "تانجتشينج",
                "label_fr": "Tangcheng"
            },
            {
                "value": 2105,
                "label": "Jinying",
                "label_ar": "جينينغ",
                "label_fr": "Jinying"
            },
            {
                "value": 2106,
                "label": "Su'ao",
                "label_ar": "Su'ao",
                "label_fr": "Su'ao"
            },
            {
                "value": 2107,
                "label": "Tancheng",
                "label_ar": "تانتشنغ",
                "label_fr": "Tancheng"
            },
            {
                "value": 2108,
                "label": "Changzheng",
                "label_ar": "تشانغشنغ",
                "label_fr": "Changzheng"
            },
            {
                "value": 2109,
                "label": "Shule",
                "label_ar": "شول",
                "label_fr": "Shule"
            },
            {
                "value": 2110,
                "label": "Qianjiang",
                "label_ar": "تشيانجيانغ",
                "label_fr": "Qianjiang"
            },
            {
                "value": 2111,
                "label": "Xinzheng",
                "label_ar": "شينتشنغ",
                "label_fr": "Xinzheng"
            },
            {
                "value": 2112,
                "label": "Daye",
                "label_ar": "داي",
                "label_fr": "Daye"
            },
            {
                "value": 2113,
                "label": "Jiaoyuan",
                "label_ar": "جياويوان",
                "label_fr": "Jiaoyuan"
            },
            {
                "value": 2114,
                "label": "Jiangjin Qu",
                "label_ar": "جيانغجين تشو",
                "label_fr": "Jiangjin Qu"
            },
            {
                "value": 2115,
                "label": "Du'an",
                "label_ar": "دوان",
                "label_fr": "Du'an"
            },
            {
                "value": 2116,
                "label": "Tengzhou",
                "label_ar": "تنجزو",
                "label_fr": "Tengzhou"
            },
            {
                "value": 2117,
                "label": "Karamay",
                "label_ar": "كاراماي",
                "label_fr": "Karamay"
            },
            {
                "value": 2118,
                "label": "Wuyishan",
                "label_ar": "Wuyishan",
                "label_fr": "Wuyishan"
            },
            {
                "value": 2119,
                "label": "Shejiazhuang",
                "label_ar": "شيجياتشوانغ",
                "label_fr": "Shejiazhuang"
            },
            {
                "value": 2120,
                "label": "Shaoguang",
                "label_ar": "شاوقوانغ",
                "label_fr": "Shaoguang"
            },
            {
                "value": 2121,
                "label": "Jinbi",
                "label_ar": "جينبي",
                "label_fr": "Jinbi"
            },
            {
                "value": 2122,
                "label": "Shanwei",
                "label_ar": "شانوي",
                "label_fr": "Shanwei"
            },
            {
                "value": 2123,
                "label": "Jingshan",
                "label_ar": "جينغشان",
                "label_fr": "Jingshan"
            },
            {
                "value": 2124,
                "label": "Zhancheng",
                "label_ar": "زانتشنغ",
                "label_fr": "Zhancheng"
            },
            {
                "value": 2125,
                "label": "Anbu",
                "label_ar": "أنبو",
                "label_fr": "Anbu"
            },
            {
                "value": 2126,
                "label": "Huigang",
                "label_ar": "هويجانج",
                "label_fr": "Huigang"
            },
            {
                "value": 2127,
                "label": "Tianle",
                "label_ar": "تيانل",
                "label_fr": "Tianle"
            },
            {
                "value": 2128,
                "label": "Xibei",
                "label_ar": "Xibei",
                "label_fr": "Xibei"
            },
            {
                "value": 2129,
                "label": "Beilin",
                "label_ar": "بيلين",
                "label_fr": "Beilin"
            },
            {
                "value": 2130,
                "label": "Lingnga",
                "label_ar": "Lingnga",
                "label_fr": "Lingnga"
            },
            {
                "value": 2131,
                "label": "Jixi",
                "label_ar": "جيشي",
                "label_fr": "Jixi"
            },
            {
                "value": 2132,
                "label": "Caijing",
                "label_ar": "كايجينغ",
                "label_fr": "Caijing"
            },
            {
                "value": 2133,
                "label": "Boji",
                "label_ar": "بوجي",
                "label_fr": "Boji"
            },
            {
                "value": 2134,
                "label": "Baicheng",
                "label_ar": "بايشنغ",
                "label_fr": "Baicheng"
            },
            {
                "value": 2135,
                "label": "Wuhai",
                "label_ar": "ووهاي",
                "label_fr": "Wuhai"
            },
            {
                "value": 2136,
                "label": "Niaojiang",
                "label_ar": "نياوجيانغ",
                "label_fr": "Niaojiang"
            },
            {
                "value": 2137,
                "label": "Taishan",
                "label_ar": "تايشان",
                "label_fr": "Taishan"
            },
            {
                "value": 2138,
                "label": "Yuzhong Chengguanzhen",
                "label_ar": "Yuzhong Chengguanzhen",
                "label_fr": "Yuzhong Chengguanzhen"
            },
            {
                "value": 2139,
                "label": "Dachuan",
                "label_ar": "داتشوان",
                "label_fr": "Dachuan"
            },
            {
                "value": 2140,
                "label": "Zhongfang",
                "label_ar": "تشونغفانغ",
                "label_fr": "Zhongfang"
            },
            {
                "value": 2141,
                "label": "Nanning",
                "label_ar": "ناننينغ",
                "label_fr": "Nanning"
            },
            {
                "value": 2142,
                "label": "Haocheng",
                "label_ar": "Haocheng",
                "label_fr": "Haocheng"
            },
            {
                "value": 2143,
                "label": "Goushan",
                "label_ar": "جوشان",
                "label_fr": "Goushan"
            },
            {
                "value": 2144,
                "label": "Lujiazhi",
                "label_ar": "لوجيازى",
                "label_fr": "Lujiazhi"
            },
            {
                "value": 2145,
                "label": "Fuchun",
                "label_ar": "فوشون",
                "label_fr": "Fuchun"
            },
            {
                "value": 2146,
                "label": "Jiangjia",
                "label_ar": "جيانغجيا",
                "label_fr": "Jiangjia"
            },
            {
                "value": 2147,
                "label": "Yangqitan",
                "label_ar": "Yangqitan",
                "label_fr": "Yangqitan"
            },
            {
                "value": 2148,
                "label": "Mingzhou",
                "label_ar": "مينجزهو",
                "label_fr": "Mingzhou"
            },
            {
                "value": 2149,
                "label": "Daoxu",
                "label_ar": "دوكسو",
                "label_fr": "Daoxu"
            },
            {
                "value": 2150,
                "label": "Jielong",
                "label_ar": "جيلونج",
                "label_fr": "Jielong"
            },
            {
                "value": 2151,
                "label": "Qianjin",
                "label_ar": "تشيانجين",
                "label_fr": "Qianjin"
            },
            {
                "value": 2152,
                "label": "Changchao",
                "label_ar": "تشانغشاو",
                "label_fr": "Changchao"
            },
            {
                "value": 2153,
                "label": "Hengjie",
                "label_ar": "هينجي",
                "label_fr": "Hengjie"
            },
            {
                "value": 2154,
                "label": "Mayao",
                "label_ar": "ماياو",
                "label_fr": "Mayao"
            },
            {
                "value": 2155,
                "label": "Sanzhang",
                "label_ar": "سانشانج",
                "label_fr": "Sanzhang"
            },
            {
                "value": 2156,
                "label": "Jinming",
                "label_ar": "جينمينغ",
                "label_fr": "Jinming"
            },
            {
                "value": 2157,
                "label": "Lianshi",
                "label_ar": "ليانشي",
                "label_fr": "Lianshi"
            },
            {
                "value": 2158,
                "label": "Sanlian",
                "label_ar": "سانليان",
                "label_fr": "Sanlian"
            },
            {
                "value": 2159,
                "label": "Hualin",
                "label_ar": "هوالين",
                "label_fr": "Hualin"
            },
            {
                "value": 2160,
                "label": "Zhenxi",
                "label_ar": "زينشي",
                "label_fr": "Zhenxi"
            },
            {
                "value": 2161,
                "label": "Yuxiang",
                "label_ar": "يوشيانغ",
                "label_fr": "Yuxiang"
            },
            {
                "value": 2162,
                "label": "Hechun",
                "label_ar": "هيشون",
                "label_fr": "Hechun"
            },
            {
                "value": 2163,
                "label": "Ruiying",
                "label_ar": "رويينج",
                "label_fr": "Ruiying"
            },
            {
                "value": 2164,
                "label": "Xianchang",
                "label_ar": "شيانتشانغ",
                "label_fr": "Xianchang"
            },
            {
                "value": 2165,
                "label": "Zhongji",
                "label_ar": "تشونغجي",
                "label_fr": "Zhongji"
            },
            {
                "value": 2166,
                "label": "Fanzhong",
                "label_ar": "فان تشونغ",
                "label_fr": "Fanzhong"
            },
            {
                "value": 2167,
                "label": "Longxiang",
                "label_ar": "لونغشيانغ",
                "label_fr": "Longxiang"
            },
            {
                "value": 2168,
                "label": "Yanguan",
                "label_ar": "يانغوان",
                "label_fr": "Yanguan"
            },
            {
                "value": 2169,
                "label": "Zhijiang",
                "label_ar": "تشيجيانغ",
                "label_fr": "Zhijiang"
            },
            {
                "value": 2170,
                "label": "Yiyang",
                "label_ar": "ييانغ",
                "label_fr": "Yiyang"
            },
            {
                "value": 2171,
                "label": "Huazhong",
                "label_ar": "هواتشونغ",
                "label_fr": "Huazhong"
            },
            {
                "value": 2172,
                "label": "Yinshan",
                "label_ar": "ينشان",
                "label_fr": "Yinshan"
            },
            {
                "value": 2173,
                "label": "Cangshang",
                "label_ar": "كانغشانغ",
                "label_fr": "Cangshang"
            },
            {
                "value": 2174,
                "label": "Banyou",
                "label_ar": "بانيو",
                "label_fr": "Banyou"
            },
            {
                "value": 2175,
                "label": "Jindian",
                "label_ar": "جينديان",
                "label_fr": "Jindian"
            },
            {
                "value": 2176,
                "label": "Mawei",
                "label_ar": "ماوي",
                "label_fr": "Mawei"
            },
            {
                "value": 2177,
                "label": "Anqiu",
                "label_ar": "أنكيو",
                "label_fr": "Anqiu"
            },
            {
                "value": 2178,
                "label": "Shouguang",
                "label_ar": "شوقوانغ",
                "label_fr": "Shouguang"
            },
            {
                "value": 2179,
                "label": "Linqu",
                "label_ar": "لينكو",
                "label_fr": "Linqu"
            },
            {
                "value": 2180,
                "label": "Kuiju",
                "label_ar": "كويجو",
                "label_fr": "Kuiju"
            },
            {
                "value": 2181,
                "label": "Dingtao",
                "label_ar": "دينغتاو",
                "label_fr": "Dingtao"
            },
            {
                "value": 2182,
                "label": "Xiaoxi",
                "label_ar": "شياوكسي",
                "label_fr": "Xiaoxi"
            },
            {
                "value": 2183,
                "label": "Luozhuang",
                "label_ar": "لوزوانج",
                "label_fr": "Luozhuang"
            },
            {
                "value": 2184,
                "label": "Xuezhuang",
                "label_ar": "Xuezhuang",
                "label_fr": "Xuezhuang"
            },
            {
                "value": 2185,
                "label": "Mengyin",
                "label_ar": "مينجين",
                "label_fr": "Mengyin"
            },
            {
                "value": 2186,
                "label": "Mengshan",
                "label_ar": "منغشان",
                "label_fr": "Mengshan"
            },
            {
                "value": 2187,
                "label": "Zhangzhuang",
                "label_ar": "تشانغزوانغ",
                "label_fr": "Zhangzhuang"
            },
            {
                "value": 2188,
                "label": "Yin'an",
                "label_ar": "ينان",
                "label_fr": "Yin'an"
            },
            {
                "value": 2189,
                "label": "Bianzhuang",
                "label_ar": "بيانزوانغ",
                "label_fr": "Bianzhuang"
            },
            {
                "value": 2190,
                "label": "Zhangzhai",
                "label_ar": "تشانغزاي",
                "label_fr": "Zhangzhai"
            },
            {
                "value": 2191,
                "label": "Jiangguantun",
                "label_ar": "جيانغوانتون",
                "label_fr": "Jiangguantun"
            },
            {
                "value": 2192,
                "label": "Linqing",
                "label_ar": "لينكينغ",
                "label_fr": "Linqing"
            },
            {
                "value": 2193,
                "label": "Dong'e",
                "label_ar": "دونغ",
                "label_fr": "Dong'e"
            },
            {
                "value": 2194,
                "label": "Chiping",
                "label_ar": "تشيبينج",
                "label_fr": "Écaillage"
            },
            {
                "value": 2195,
                "label": "Nanguo",
                "label_ar": "نانغو",
                "label_fr": "Nanguo"
            },
            {
                "value": 2196,
                "label": "Baying",
                "label_ar": "باينغ",
                "label_fr": "Baying"
            },
            {
                "value": 2197,
                "label": "Linxia Chengguanzhen",
                "label_ar": "لينشيا تشينجوانزين",
                "label_fr": "Linxia Chengguanzhen"
            },
            {
                "value": 2198,
                "label": "Luorong",
                "label_ar": "لورونج",
                "label_fr": "Luorong"
            },
            {
                "value": 2199,
                "label": "Liujiang",
                "label_ar": "ليوجيانغ",
                "label_fr": "Liujiang"
            },
            {
                "value": 2200,
                "label": "Daojiang",
                "label_ar": "داوجيانغ",
                "label_fr": "Daojiang"
            },
            {
                "value": 2201,
                "label": "Liudong",
                "label_ar": "ليودونغ",
                "label_fr": "Liudong"
            },
            {
                "value": 2202,
                "label": "Jinguang",
                "label_ar": "جينجوانغ",
                "label_fr": "Jinguang"
            },
            {
                "value": 2203,
                "label": "Anhu",
                "label_ar": "انهو",
                "label_fr": "Anhu"
            },
            {
                "value": 2204,
                "label": "Lingang",
                "label_ar": "Lingang",
                "label_fr": "Lingang"
            },
            {
                "value": 2205,
                "label": "Jiaoyu",
                "label_ar": "جياويو",
                "label_fr": "Jiaoyu"
            },
            {
                "value": 2206,
                "label": "Zuxi",
                "label_ar": "زوكسي",
                "label_fr": "Zuxi"
            },
            {
                "value": 2207,
                "label": "Xiangsi",
                "label_ar": "شيانغسي",
                "label_fr": "Xiangsi"
            },
            {
                "value": 2208,
                "label": "Jianzhong",
                "label_ar": "جيانتشونغ",
                "label_fr": "Jianzhong"
            },
            {
                "value": 2209,
                "label": "Jinzao",
                "label_ar": "جينزاو",
                "label_fr": "Jinzao"
            },
            {
                "value": 2210,
                "label": "Nan'an",
                "label_ar": "نانان",
                "label_fr": "Nan'an"
            },
            {
                "value": 2211,
                "label": "Xizhang",
                "label_ar": "Xizhang",
                "label_fr": "Xizhang"
            },
            {
                "value": 2212,
                "label": "Jondag",
                "label_ar": "جونداج",
                "label_fr": "Jondag"
            },
            {
                "value": 2213,
                "label": "Xiaojiahe",
                "label_ar": "شياو جياه",
                "label_fr": "Xiaojiahe"
            },
            {
                "value": 2214,
                "label": "Yandao",
                "label_ar": "يانداو",
                "label_fr": "Yandao"
            },
            {
                "value": 2215,
                "label": "Chadianzi",
                "label_ar": "تشاديانزي",
                "label_fr": "Chadianzi"
            },
            {
                "value": 2216,
                "label": "Zhongyicun",
                "label_ar": "Zhongyicun",
                "label_fr": "Zhongyicun"
            },
            {
                "value": 2217,
                "label": "Jialing",
                "label_ar": "جيالينغ",
                "label_fr": "Jialing"
            },
            {
                "value": 2218,
                "label": "Jianxing",
                "label_ar": "جيانشينغ",
                "label_fr": "Jianxing"
            },
            {
                "value": 2219,
                "label": "Xichong",
                "label_ar": "Xichong",
                "label_fr": "Xichong"
            },
            {
                "value": 2220,
                "label": "Sanyi",
                "label_ar": "ساني",
                "label_fr": "Sanyi"
            },
            {
                "value": 2221,
                "label": "Hongyan",
                "label_ar": "هونغيان",
                "label_fr": "Hongyan"
            },
            {
                "value": 2222,
                "label": "Base",
                "label_ar": "يتمركز",
                "label_fr": "Base"
            },
            {
                "value": 2223,
                "label": "Yongxing",
                "label_ar": "يونغشينغ",
                "label_fr": "Yongxing"
            },
            {
                "value": 2224,
                "label": "Nanhe",
                "label_ar": "نانهي",
                "label_fr": "Nanhe"
            },
            {
                "value": 2225,
                "label": "Naxi Yaozuxiang",
                "label_ar": "Naxi Yaozuxiang",
                "label_fr": "Naxi Yaozuxiang"
            },
            {
                "value": 2226,
                "label": "Dongxing",
                "label_ar": "دونغشينغ",
                "label_fr": "Dongxing"
            },
            {
                "value": 2227,
                "label": "Rongxian",
                "label_ar": "رونغسيان",
                "label_fr": "Rongxian"
            },
            {
                "value": 2228,
                "label": "Nanying",
                "label_ar": "نانيينج",
                "label_fr": "Nanying"
            },
            {
                "value": 2229,
                "label": "Huaying",
                "label_ar": "Huaying",
                "label_fr": "Huaying"
            },
            {
                "value": 2230,
                "label": "Huagongchang",
                "label_ar": "Huagongchang",
                "label_fr": "Huagongchang"
            },
            {
                "value": 2231,
                "label": "Gongjiao",
                "label_ar": "Gongjiao",
                "label_fr": "Gongjiao"
            },
            {
                "value": 2232,
                "label": "Huanghou",
                "label_ar": "هوانغو",
                "label_fr": "Huanghou"
            },
            {
                "value": 2233,
                "label": "Zhongjian",
                "label_ar": "Zhongjian",
                "label_fr": "Zhongjian"
            },
            {
                "value": 2234,
                "label": "Xingxing",
                "label_ar": "Xingxing",
                "label_fr": "Xingxing"
            },
            {
                "value": 2235,
                "label": "Shanhe",
                "label_ar": "شانهي",
                "label_fr": "Shanhe"
            },
            {
                "value": 2236,
                "label": "Liwu",
                "label_ar": "ليوو",
                "label_fr": "Liwu"
            },
            {
                "value": 2237,
                "label": "Shanshui",
                "label_ar": "شانشوي",
                "label_fr": "Shanshui"
            },
            {
                "value": 2238,
                "label": "Shizhu'an",
                "label_ar": "شيزوان",
                "label_fr": "Shizhu'an"
            },
            {
                "value": 2239,
                "label": "Anguo",
                "label_ar": "أنغو",
                "label_fr": "Anguo"
            },
            {
                "value": 2240,
                "label": "Yanjiao",
                "label_ar": "يانجياو",
                "label_fr": "Yanjiao"
            },
            {
                "value": 2241,
                "label": "Linze",
                "label_ar": "لينز",
                "label_fr": "Linze"
            },
            {
                "value": 2242,
                "label": "Duoba",
                "label_ar": "دوبا",
                "label_fr": "Duoba"
            },
            {
                "value": 2243,
                "label": "Yingcai",
                "label_ar": "ينجكاي",
                "label_fr": "Yingcai"
            },
            {
                "value": 2244,
                "label": "Hongchang",
                "label_ar": "هونغتشانغ",
                "label_fr": "Hongchang"
            },
            {
                "value": 2245,
                "label": "Jingjiang",
                "label_ar": "جينجيانغ",
                "label_fr": "Jingjiang"
            },
            {
                "value": 2246,
                "label": "Taikang",
                "label_ar": "تايكانغ",
                "label_fr": "Taikang"
            },
            {
                "value": 2247,
                "label": "Zhaozhou",
                "label_ar": "تشاوتشو",
                "label_fr": "Zhaozhou"
            },
            {
                "value": 2248,
                "label": "Lindian",
                "label_ar": "لينديان",
                "label_fr": "Lindian"
            },
            {
                "value": 2249,
                "label": "Zhonglin",
                "label_ar": "تشونغ لين",
                "label_fr": "Zhonglin"
            },
            {
                "value": 2250,
                "label": "Pingyin",
                "label_ar": "بينجين",
                "label_fr": "Pingyin"
            },
            {
                "value": 2251,
                "label": "Nanxianglong",
                "label_ar": "Nanxianglong",
                "label_fr": "Nanxianglong"
            },
            {
                "value": 2252,
                "label": "Chengxian Chengguanzhen",
                "label_ar": "Chengxian Chengguanzhen",
                "label_fr": "Chengxian Chengguanzhen"
            },
            {
                "value": 2253,
                "label": "Yuhong",
                "label_ar": "يوهونغ",
                "label_fr": "Yuhong"
            },
            {
                "value": 2254,
                "label": "Yuhan",
                "label_ar": "يوهان",
                "label_fr": "Yuhan"
            },
            {
                "value": 2255,
                "label": "Xiayuan",
                "label_ar": "شيايوان",
                "label_fr": "Xiayuan"
            },
            {
                "value": 2256,
                "label": "Dongjiaying",
                "label_ar": "Dongjiaying",
                "label_fr": "Dongjiaying"
            },
            {
                "value": 2257,
                "label": "Songyuan",
                "label_ar": "سونغيوان",
                "label_fr": "Songyuan"
            },
            {
                "value": 2258,
                "label": "Dasha",
                "label_ar": "داشا",
                "label_fr": "Dasha"
            },
            {
                "value": 2259,
                "label": "Guohe",
                "label_ar": "Guohe",
                "label_fr": "Guohe"
            },
            {
                "value": 2260,
                "label": "Yuanchang",
                "label_ar": "Yuanchang",
                "label_fr": "Yuanchang"
            },
            {
                "value": 2261,
                "label": "Hangbu",
                "label_ar": "هانغبو",
                "label_fr": "Hangbu"
            },
            {
                "value": 2262,
                "label": "Zhangjiang",
                "label_ar": "تشانغجيانغ",
                "label_fr": "Zhangjiang"
            },
            {
                "value": 2263,
                "label": "Longjian",
                "label_ar": "لونجيان",
                "label_fr": "Longjian"
            },
            {
                "value": 2264,
                "label": "Zaoyang",
                "label_ar": "زاويانغ",
                "label_fr": "Zaoyang"
            },
            {
                "value": 2265,
                "label": "Shensha",
                "label_ar": "شنشا",
                "label_fr": "Shensha"
            },
            {
                "value": 2266,
                "label": "Xijiao",
                "label_ar": "Xijiao",
                "label_fr": "Xijiao"
            },
            {
                "value": 2267,
                "label": "Xijiao",
                "label_ar": "Xijiao",
                "label_fr": "Xijiao"
            },
            {
                "value": 2268,
                "label": "Chuansha",
                "label_ar": "تشوانشا",
                "label_fr": "Chuansha"
            },
            {
                "value": 2269,
                "label": "Gongyuan",
                "label_ar": "Gongyuan",
                "label_fr": "Gongyuan"
            },
            {
                "value": 2270,
                "label": "Fendou",
                "label_ar": "فيندو",
                "label_fr": "Fendou"
            },
            {
                "value": 2271,
                "label": "Dongshi",
                "label_ar": "دونغشي",
                "label_fr": "Dongshi"
            },
            {
                "value": 2272,
                "label": "Nongkeng",
                "label_ar": "نونغكينغ",
                "label_fr": "Nongkeng"
            },
            {
                "value": 2273,
                "label": "Huacheng",
                "label_ar": "هواتشنغ",
                "label_fr": "Huacheng"
            },
            {
                "value": 2274,
                "label": "Qingzhou",
                "label_ar": "تشينغتشو",
                "label_fr": "Qingzhou"
            },
            {
                "value": 2275,
                "label": "Fangzi",
                "label_ar": "فانغزي",
                "label_fr": "Fangzi"
            },
            {
                "value": 2276,
                "label": "Taishan",
                "label_ar": "تايشان",
                "label_fr": "Taishan"
            },
            {
                "value": 2277,
                "label": "Haiyu",
                "label_ar": "هايو",
                "label_fr": "Haiyu"
            },
            {
                "value": 2278,
                "label": "Shouchun",
                "label_ar": "شوشون",
                "label_fr": "Shouchun"
            },
            {
                "value": 2279,
                "label": "Wuhai",
                "label_ar": "ووهاي",
                "label_fr": "Wuhai"
            },
            {
                "value": 2280,
                "label": "Chifeng",
                "label_ar": "تشيفنغ",
                "label_fr": "Chifeng"
            },
            {
                "value": 2281,
                "label": "Hetao",
                "label_ar": "هيتاو",
                "label_fr": "Hetao"
            },
            {
                "value": 2282,
                "label": "Shekou",
                "label_ar": "شيكو",
                "label_fr": "Shekou"
            },
            {
                "value": 2283,
                "label": "Qianhai",
                "label_ar": "Qianhai",
                "label_fr": "Qianhai"
            },
            {
                "value": 2284,
                "label": "Lu'an",
                "label_ar": "لوان",
                "label_fr": "Lu'an"
            },
            {
                "value": 2285,
                "label": "Yancheng",
                "label_ar": "يانتشنغ",
                "label_fr": "Yancheng"
            },
            {
                "value": 2286,
                "label": "Jinghong",
                "label_ar": "جينغهونغ",
                "label_fr": "Jinghong"
            },
            {
                "value": 2287,
                "label": "Ning'er",
                "label_ar": "نينغ ير",
                "label_fr": "Ning'er"
            },
            {
                "value": 2288,
                "label": "Zhuozhou",
                "label_ar": "تشوتشو",
                "label_fr": "Zhuozhou"
            },
            {
                "value": 2289,
                "label": "Luquan",
                "label_ar": "Luquan",
                "label_fr": "Luquan"
            },
            {
                "value": 2290,
                "label": "Li'an",
                "label_ar": "ليان",
                "label_fr": "Li'an"
            },
            {
                "value": 2291,
                "label": "Suqian",
                "label_ar": "سوكيان",
                "label_fr": "Suqian"
            },
            {
                "value": 2292,
                "label": "Dabu",
                "label_ar": "دابو",
                "label_fr": "Dabu"
            },
            {
                "value": 2293,
                "label": "Luoyang",
                "label_ar": "لويانغ",
                "label_fr": "Luoyang"
            },
            {
                "value": 2294,
                "label": "Feiyun",
                "label_ar": "Feiyun",
                "label_fr": "Feiyun"
            }
        ]
    },
    {
        "country": "Japan",
        "cities": [
            {
                "value": 2487,
                "label": "Komae-shi",
                "label_ar": "كوماي شي",
                "label_fr": "Komae-shi"
            },
            {
                "value": 2692,
                "label": "Tagajo-shi",
                "label_ar": "تاجاجو شي",
                "label_fr": "Tagajo-shi"
            },
            {
                "value": 2872,
                "label": "Ebetsu",
                "label_ar": "إبيتسو",
                "label_fr": "Ebetsu"
            },
            {
                "value": 3189,
                "label": "Budo",
                "label_ar": "بودو",
                "label_fr": "Budo"
            },
            {
                "value": 2368,
                "label": "Seto",
                "label_ar": "سيتو",
                "label_fr": "Seto"
            },
            {
                "value": 2753,
                "label": "Aizu-Wakamatsu",
                "label_ar": "ايزو واكاماتسو",
                "label_fr": "Aizu-Wakamatsu"
            },
            {
                "value": 3276,
                "label": "Tokiwamachi-tokiwa",
                "label_ar": "Tokiwamachi-tokiwa",
                "label_fr": "Tokiwamachi-tokiwa"
            },
            {
                "value": 3172,
                "label": "Kyodo",
                "label_ar": "كيودو",
                "label_fr": "Kyodo"
            },
            {
                "value": 3059,
                "label": "Kikuchi",
                "label_ar": "كيكوتشي",
                "label_fr": "Kikuchi"
            },
            {
                "value": 2723,
                "label": "Iwakuni",
                "label_ar": "إيواكوني",
                "label_fr": "Iwakuni"
            },
            {
                "value": 2365,
                "label": "Kariya",
                "label_ar": "كارييا",
                "label_fr": "Kariya"
            },
            {
                "value": 2751,
                "label": "Shiroi",
                "label_ar": "شيرو",
                "label_fr": "Shiroi"
            },
            {
                "value": 2366,
                "label": "Toyohashi",
                "label_ar": "تويوهاشي",
                "label_fr": "Toyohashi"
            },
            {
                "value": 2669,
                "label": "Taga",
                "label_ar": "تاجا",
                "label_fr": "Taga"
            },
            {
                "value": 3036,
                "label": "Kurobeshin",
                "label_ar": "كوروبيشين",
                "label_fr": "Kurobeshin"
            },
            {
                "value": 3332,
                "label": "Kasumigaseki",
                "label_ar": "كاسوميغاسيكي",
                "label_fr": "Kasumigaseki"
            },
            {
                "value": 2691,
                "label": "Ube",
                "label_ar": "أوبي",
                "label_fr": "Ube"
            },
            {
                "value": 2809,
                "label": "Kurogi",
                "label_ar": "كوروغي",
                "label_fr": "Kurogi"
            },
            {
                "value": 3144,
                "label": "Hayashino",
                "label_ar": "هاياشينو",
                "label_fr": "Hayashino"
            },
            {
                "value": 3238,
                "label": "Nirasaki",
                "label_ar": "نيراساكي",
                "label_fr": "Nirasaki"
            },
            {
                "value": 2325,
                "label": "Hamamatsu",
                "label_ar": "هاماماتسو",
                "label_fr": "Hamamatsu"
            },
            {
                "value": 3231,
                "label": "Naitobokujo",
                "label_ar": "نايتوبوكوجو",
                "label_fr": "Naitobokujo"
            },
            {
                "value": 2867,
                "label": "Tobo",
                "label_ar": "ل بو",
                "label_fr": "Tobo"
            },
            {
                "value": 2870,
                "label": "Omuta",
                "label_ar": "أوموتا",
                "label_fr": "Omuta"
            },
            {
                "value": 3487,
                "label": "Obuse",
                "label_ar": "متعجرف",
                "label_fr": "Obuse"
            },
            {
                "value": 2430,
                "label": "Hita",
                "label_ar": "اضرب",
                "label_fr": "Frapper un"
            },
            {
                "value": 2548,
                "label": "Ayase",
                "label_ar": "أيأسه",
                "label_fr": "Ayase"
            },
            {
                "value": 2816,
                "label": "Sendai",
                "label_ar": "سينداي",
                "label_fr": "Sendai"
            },
            {
                "value": 3158,
                "label": "Omori",
                "label_ar": "أوموري",
                "label_fr": "Omori"
            },
            {
                "value": 2429,
                "label": "Wakayama",
                "label_ar": "واكاياما",
                "label_fr": "Wakayama"
            },
            {
                "value": 2946,
                "label": "Shobusawa",
                "label_ar": "شوبوساوا",
                "label_fr": "Shobusawa"
            },
            {
                "value": 3418,
                "label": "Shizunai-misono",
                "label_ar": "شيزوناي ميسونو",
                "label_fr": "Shizunai-misono"
            },
            {
                "value": 2344,
                "label": "Osaka",
                "label_ar": "أوساكا",
                "label_fr": "Osaka"
            },
            {
                "value": 2346,
                "label": "Yakuin-Horibata",
                "label_ar": "ياكوين هوريباتا",
                "label_fr": "Yakuin-Horibata"
            },
            {
                "value": 2499,
                "label": "Takahama",
                "label_ar": "تاكاهاما",
                "label_fr": "Takahama"
            },
            {
                "value": 2787,
                "label": "Oyamato",
                "label_ar": "أوياماتو",
                "label_fr": "Oyamato"
            },
            {
                "value": 3173,
                "label": "Shiroishi",
                "label_ar": "شيروشي",
                "label_fr": "Shiroishi"
            },
            {
                "value": 2434,
                "label": "Shiraoka",
                "label_ar": "شيراوكا",
                "label_fr": "Shiraoka"
            },
            {
                "value": 2929,
                "label": "Obayashi",
                "label_ar": "أوباياشي",
                "label_fr": "Obayashi"
            },
            {
                "value": 2471,
                "label": "Fujisawa",
                "label_ar": "فوجيساوا",
                "label_fr": "Fujisawa"
            },
            {
                "value": 2935,
                "label": "Sumiyoshi",
                "label_ar": "سوميوشي",
                "label_fr": "Sumiyoshi"
            },
            {
                "value": 3188,
                "label": "Haijima",
                "label_ar": "هيجيما",
                "label_fr": "Haijima"
            },
            {
                "value": 3388,
                "label": "Mimasaka",
                "label_ar": "ميماساكا",
                "label_fr": "Mimasaka"
            },
            {
                "value": 3171,
                "label": "Tateyama",
                "label_ar": "تاتياما",
                "label_fr": "Tateyama"
            },
            {
                "value": 3216,
                "label": "Kayano",
                "label_ar": "كايانو",
                "label_fr": "Kayano"
            },
            {
                "value": 2453,
                "label": "Kakegawa",
                "label_ar": "كاكيجاوا",
                "label_fr": "Kakegawa"
            },
            {
                "value": 2369,
                "label": "Konan",
                "label_ar": "كونان",
                "label_fr": "Konan"
            },
            {
                "value": 3004,
                "label": "Yunoshima",
                "label_ar": "يونوشيما",
                "label_fr": "Yunoshima"
            },
            {
                "value": 3473,
                "label": "Shinkawa",
                "label_ar": "شينكاوا",
                "label_fr": "Shinkawa"
            },
            {
                "value": 2340,
                "label": "Itami",
                "label_ar": "إيتامي",
                "label_fr": "Itami"
            },
            {
                "value": 2354,
                "label": "Sugito",
                "label_ar": "سوجيتو",
                "label_fr": "Sugito"
            },
            {
                "value": 2827,
                "label": "Hidaka",
                "label_ar": "هيداكا",
                "label_fr": "Hidaka"
            },
            {
                "value": 2953,
                "label": "Omiya",
                "label_ar": "أوميا",
                "label_fr": "Omiya"
            },
            {
                "value": 2794,
                "label": "Ueno-ebisumachi",
                "label_ar": "أوينو إبيسوماتشي",
                "label_fr": "Ueno-ebisumachi"
            },
            {
                "value": 2862,
                "label": "Hojo",
                "label_ar": "هوجو",
                "label_fr": "Hojo"
            },
            {
                "value": 2718,
                "label": "Misawa",
                "label_ar": "ميساوا",
                "label_fr": "Misawa"
            },
            {
                "value": 2701,
                "label": "Omihachiman",
                "label_ar": "Omihachiman",
                "label_fr": "Omihachiman"
            },
            {
                "value": 3185,
                "label": "Sagae",
                "label_ar": "ساغي",
                "label_fr": "Sagae"
            },
            {
                "value": 2727,
                "label": "Misato",
                "label_ar": "ميساتو",
                "label_fr": "Misato"
            },
            {
                "value": 2991,
                "label": "Mutsu",
                "label_ar": "موتسو",
                "label_fr": "Mutsu"
            },
            {
                "value": 2982,
                "label": "Marugame",
                "label_ar": "ماروجيم",
                "label_fr": "Marugame"
            },
            {
                "value": 2383,
                "label": "Kagawa",
                "label_ar": "كاغاوا",
                "label_fr": "Kagawa"
            },
            {
                "value": 2978,
                "label": "Gosen",
                "label_ar": "جوسن",
                "label_fr": "Gosen"
            },
            {
                "value": 2523,
                "label": "Yoshikawa",
                "label_ar": "يوشيكاوا",
                "label_fr": "Yoshikawa"
            },
            {
                "value": 2371,
                "label": "Odawara",
                "label_ar": "أوداوارا",
                "label_fr": "Odawara"
            },
            {
                "value": 2531,
                "label": "Miyakonojo",
                "label_ar": "مياكونوجو",
                "label_fr": "Miyakonojo"
            },
            {
                "value": 3491,
                "label": "Fushimi",
                "label_ar": "فوشيمي",
                "label_fr": "Fushimi"
            },
            {
                "value": 2614,
                "label": "Hino",
                "label_ar": "هينو",
                "label_fr": "Hino"
            },
            {
                "value": 3087,
                "label": "Goya",
                "label_ar": "جويا",
                "label_fr": "Goya"
            },
            {
                "value": 3304,
                "label": "Koori",
                "label_ar": "كوري",
                "label_fr": "Koori"
            },
            {
                "value": 2370,
                "label": "Nasa",
                "label_ar": "ناسا",
                "label_fr": "Nasa"
            },
            {
                "value": 2648,
                "label": "Masuda",
                "label_ar": "ماسودا",
                "label_fr": "Masuda"
            },
            {
                "value": 2842,
                "label": "Yamawaki",
                "label_ar": "ياماواكي",
                "label_fr": "Yamawaki"
            },
            {
                "value": 2387,
                "label": "Iwakura",
                "label_ar": "إيواكورا",
                "label_fr": "Iwakura"
            },
            {
                "value": 2516,
                "label": "Kami-nagarekawacho",
                "label_ar": "كامي ناجاريكاتشو",
                "label_fr": "Kami-nagarekawacho"
            },
            {
                "value": 3223,
                "label": "Higashi-takadama",
                "label_ar": "هيغاشي تاكاداما",
                "label_fr": "Higashi-takadama"
            },
            {
                "value": 2732,
                "label": "Hirosaki",
                "label_ar": "هيروساكي",
                "label_fr": "Hirosaki"
            },
            {
                "value": 2671,
                "label": "Kaizuka",
                "label_ar": "كايزوكا",
                "label_fr": "Kaizuka"
            },
            {
                "value": 2479,
                "label": "Kamakura",
                "label_ar": "كاماكورا",
                "label_fr": "Kamakura"
            },
            {
                "value": 2927,
                "label": "Kita",
                "label_ar": "كيتا",
                "label_fr": "Kita"
            },
            {
                "value": 3113,
                "label": "Toki",
                "label_ar": "توكي",
                "label_fr": "Toki"
            },
            {
                "value": 2426,
                "label": "Kukichuo",
                "label_ar": "كوكيشو",
                "label_fr": "Kukichuo"
            },
            {
                "value": 2618,
                "label": "Iwade",
                "label_ar": "إيواد",
                "label_fr": "Iwade"
            },
            {
                "value": 2560,
                "label": "Noshiro",
                "label_ar": "نوشيرو",
                "label_fr": "Noshiro"
            },
            {
                "value": 2887,
                "label": "Tachibana",
                "label_ar": "تاتشيبانا",
                "label_fr": "Tachibana"
            },
            {
                "value": 3067,
                "label": "Harigaya",
                "label_ar": "هاريجايا",
                "label_fr": "Harigaya"
            },
            {
                "value": 3168,
                "label": "Umeda",
                "label_ar": "أوميدا",
                "label_fr": "Umeda"
            },
            {
                "value": 2308,
                "label": "Koga",
                "label_ar": "كوجا",
                "label_fr": "Koga"
            },
            {
                "value": 2899,
                "label": "Okaya",
                "label_ar": "اكايا",
                "label_fr": "Okaya"
            },
            {
                "value": 3008,
                "label": "Uruma",
                "label_ar": "أوروما",
                "label_fr": "Uruma"
            },
            {
                "value": 2538,
                "label": "Shimoda",
                "label_ar": "شيمودا",
                "label_fr": "Shimoda"
            },
            {
                "value": 3481,
                "label": "Hamana",
                "label_ar": "حمانا",
                "label_fr": "Hamana"
            },
            {
                "value": 2895,
                "label": "Shimotsucho-kominami",
                "label_ar": "شيموتسوتشو كومينامي",
                "label_fr": "Shimotsucho-kominami"
            },
            {
                "value": 3220,
                "label": "Shioya",
                "label_ar": "شيويا",
                "label_fr": "Shioya"
            },
            {
                "value": 3088,
                "label": "Kaminokawa",
                "label_ar": "كامينوكاوا",
                "label_fr": "Kaminokawa"
            },
            {
                "value": 2665,
                "label": "Toyooka",
                "label_ar": "تويوكا",
                "label_fr": "Toyooka"
            },
            {
                "value": 3448,
                "label": "Maki",
                "label_ar": "ماكي",
                "label_fr": "Maki"
            },
            {
                "value": 3486,
                "label": "Iiyama",
                "label_ar": "إياما",
                "label_fr": "Iiyama"
            },
            {
                "value": 2785,
                "label": "Gojo",
                "label_ar": "Gojo",
                "label_fr": "Gojo"
            },
            {
                "value": 2592,
                "label": "Tondabayashi",
                "label_ar": "تونداباياشي",
                "label_fr": "Tondabayashi"
            },
            {
                "value": 2841,
                "label": "Komatsu",
                "label_ar": "كوماتسو",
                "label_fr": "Komatsu"
            },
            {
                "value": 2643,
                "label": "Kaigandori",
                "label_ar": "كايجاندوري",
                "label_fr": "Kaigandori"
            },
            {
                "value": 2608,
                "label": "Kashiwara",
                "label_ar": "كاشيوارا",
                "label_fr": "Kashiwara"
            },
            {
                "value": 2413,
                "label": "Minami",
                "label_ar": "مينامي",
                "label_fr": "Minami"
            },
            {
                "value": 2301,
                "label": "Ibaraki",
                "label_ar": "إيباراكي",
                "label_fr": "Ibaraki"
            },
            {
                "value": 3117,
                "label": "Kazuno",
                "label_ar": "كازونو",
                "label_fr": "Kazuno"
            },
            {
                "value": 3207,
                "label": "Mitsukaido",
                "label_ar": "ميتسوكايدو",
                "label_fr": "Mitsukaido"
            },
            {
                "value": 3096,
                "label": "Ikuno",
                "label_ar": "ايكونو",
                "label_fr": "Ikuno"
            },
            {
                "value": 2593,
                "label": "Matsumoto",
                "label_ar": "ماتسوموتو",
                "label_fr": "Matsumoto"
            },
            {
                "value": 2779,
                "label": "Hagi",
                "label_ar": "حاجي",
                "label_fr": "Hagi"
            },
            {
                "value": 2678,
                "label": "Oyama",
                "label_ar": "وياما",
                "label_fr": "Oyama"
            },
            {
                "value": 2882,
                "label": "Sonobe",
                "label_ar": "سونوبي",
                "label_fr": "Sonobe"
            },
            {
                "value": 2644,
                "label": "Hitoyoshi",
                "label_ar": "هيتويوشي",
                "label_fr": "Hitoyoshi"
            },
            {
                "value": 2511,
                "label": "Mizunami",
                "label_ar": "ميزونامي",
                "label_fr": "Mizunami"
            },
            {
                "value": 2795,
                "label": "Kaizu",
                "label_ar": "كايزو",
                "label_fr": "Kaizu"
            },
            {
                "value": 3012,
                "label": "Yubari",
                "label_ar": "يوباري",
                "label_fr": "Yubari"
            },
            {
                "value": 2896,
                "label": "Muroto-misakicho",
                "label_ar": "Muroto-Misakicho",
                "label_fr": "Muroto-misakicho"
            },
            {
                "value": 2997,
                "label": "Kesennuma",
                "label_ar": "كيسنوما",
                "label_fr": "Kesennuma"
            },
            {
                "value": 2485,
                "label": "Isehara",
                "label_ar": "Isehara",
                "label_fr": "Isehara"
            },
            {
                "value": 2622,
                "label": "Kasumigaura",
                "label_ar": "كاسوميجاورا",
                "label_fr": "Kasumigaura"
            },
            {
                "value": 2801,
                "label": "Konosu",
                "label_ar": "كونوسو",
                "label_fr": "Konosu"
            },
            {
                "value": 3114,
                "label": "Takemiya",
                "label_ar": "Takemiya",
                "label_fr": "Takemiya"
            },
            {
                "value": 2328,
                "label": "Tsuchiura",
                "label_ar": "تسوتشيورا",
                "label_fr": "Tsuchiura"
            },
            {
                "value": 3120,
                "label": "Ninohe",
                "label_ar": "نينوهي",
                "label_fr": "Ninohe"
            },
            {
                "value": 3363,
                "label": "Yabuki",
                "label_ar": "يابوكي",
                "label_fr": "Yabuki"
            },
            {
                "value": 2352,
                "label": "Setagaya-ku",
                "label_ar": "Setagaya-ku",
                "label_fr": "Setagaya-ku"
            },
            {
                "value": 2625,
                "label": "Fukui-shi",
                "label_ar": "فوكوي شي",
                "label_fr": "Fukui-shi"
            },
            {
                "value": 2573,
                "label": "Asahi",
                "label_ar": "اساهي",
                "label_fr": "Asahi"
            },
            {
                "value": 2745,
                "label": "Yamato-Takada",
                "label_ar": "ياماتو تاكادا",
                "label_fr": "Yamato-Takada"
            },
            {
                "value": 2642,
                "label": "Bibai",
                "label_ar": "بيبي",
                "label_fr": "Bibai"
            },
            {
                "value": 2562,
                "label": "Kazo",
                "label_ar": "كازو",
                "label_fr": "Kazo"
            },
            {
                "value": 3445,
                "label": "Morohongo",
                "label_ar": "Morohongo",
                "label_fr": "Morohongo"
            },
            {
                "value": 2575,
                "label": "Hirokawa",
                "label_ar": "هيروكاوا",
                "label_fr": "Hirokawa"
            },
            {
                "value": 2449,
                "label": "Shiojiri",
                "label_ar": "شيوجيري",
                "label_fr": "Shiojiri"
            },
            {
                "value": 2875,
                "label": "Komatsu",
                "label_ar": "كوماتسو",
                "label_fr": "Komatsu"
            },
            {
                "value": 2885,
                "label": "Koyo",
                "label_ar": "كويو",
                "label_fr": "Koyo"
            },
            {
                "value": 2582,
                "label": "Tottori-shi",
                "label_ar": "توتوري شي",
                "label_fr": "Tottori-shi"
            },
            {
                "value": 2492,
                "label": "Kunitachi",
                "label_ar": "كونيتاشي",
                "label_fr": "Kunitachi"
            },
            {
                "value": 2821,
                "label": "Inagi-shi",
                "label_ar": "إناجي شي",
                "label_fr": "Inagi-shi"
            },
            {
                "value": 2943,
                "label": "Misakicho",
                "label_ar": "ميساكيشو",
                "label_fr": "Misakicho"
            },
            {
                "value": 3116,
                "label": "Kasaoka",
                "label_ar": "كاساوكا",
                "label_fr": "Kasaoka"
            },
            {
                "value": 2500,
                "label": "Kiyosu",
                "label_ar": "كيوسو",
                "label_fr": "Kiyosu"
            },
            {
                "value": 2433,
                "label": "Yasu-shi",
                "label_ar": "ياسو شي",
                "label_fr": "Yasu-shi"
            },
            {
                "value": 2591,
                "label": "Mitake",
                "label_ar": "ميتاكي",
                "label_fr": "Mitake"
            },
            {
                "value": 2786,
                "label": "Gujo",
                "label_ar": "جوجو",
                "label_fr": "Gujo"
            },
            {
                "value": 2755,
                "label": "Chuo",
                "label_ar": "تشو",
                "label_fr": "Chuo"
            },
            {
                "value": 3450,
                "label": "Kasai",
                "label_ar": "كاساي",
                "label_fr": "Kasaï"
            },
            {
                "value": 3361,
                "label": "Kamimura",
                "label_ar": "كاميمورا",
                "label_fr": "Kamimura"
            },
            {
                "value": 3420,
                "label": "Yoshida",
                "label_ar": "يوشيدا",
                "label_fr": "Yoshida"
            },
            {
                "value": 3465,
                "label": "Kaisei",
                "label_ar": "كايسي",
                "label_fr": "Kaisei"
            },
            {
                "value": 2491,
                "label": "None-mura",
                "label_ar": "لا شيء مورا",
                "label_fr": "Aucun-mura"
            },
            {
                "value": 2930,
                "label": "Natsumi",
                "label_ar": "ناتسومي",
                "label_fr": "Natsumi"
            },
            {
                "value": 3065,
                "label": "Rankoshi-cho",
                "label_ar": "رانكوشي تشو",
                "label_fr": "Rankoshi-cho"
            },
            {
                "value": 3514,
                "label": "Ozu",
                "label_ar": "أوزو",
                "label_fr": "Ozu"
            },
            {
                "value": 3303,
                "label": "Mima",
                "label_ar": "ميما",
                "label_fr": "Mima"
            },
            {
                "value": 3322,
                "label": "Fuchucho",
                "label_ar": "فوتشوتشو",
                "label_fr": "Fuchucho"
            },
            {
                "value": 2706,
                "label": "Musa",
                "label_ar": "موسى",
                "label_fr": "Musa"
            },
            {
                "value": 2567,
                "label": "Hekinan",
                "label_ar": "هيكينان",
                "label_fr": "Hekinan"
            },
            {
                "value": 3412,
                "label": "Naebocho",
                "label_ar": "نيبوتشو",
                "label_fr": "Naebocho"
            },
            {
                "value": 2629,
                "label": "Iwaki",
                "label_ar": "إيواكي",
                "label_fr": "Iwaki"
            },
            {
                "value": 2817,
                "label": "Innoshima Hara-cho",
                "label_ar": "إينوشيما هارا شو",
                "label_fr": "Innoshima Hara-cho"
            },
            {
                "value": 2956,
                "label": "Tamagawa",
                "label_ar": "تاماغاوا",
                "label_fr": "Tamagawa"
            },
            {
                "value": 2746,
                "label": "Osaka",
                "label_ar": "أوساكا",
                "label_fr": "Osaka"
            },
            {
                "value": 2967,
                "label": "Minami",
                "label_ar": "مينامي",
                "label_fr": "Minami"
            },
            {
                "value": 2450,
                "label": "Ueda, Nagano",
                "label_ar": "أويدا ، ناغانو",
                "label_fr": "Ueda, Nagano"
            },
            {
                "value": 2743,
                "label": "Kamigori",
                "label_ar": "كاميغوري",
                "label_fr": "Kamigori"
            },
            {
                "value": 3201,
                "label": "Otsukacho",
                "label_ar": "أوتسوكاتشو",
                "label_fr": "Otsukacho"
            },
            {
                "value": 3258,
                "label": "Tatsuno",
                "label_ar": "تاتسونو",
                "label_fr": "Tatsuno"
            },
            {
                "value": 2710,
                "label": "Nomura",
                "label_ar": "نومورا",
                "label_fr": "Nomura"
            },
            {
                "value": 3180,
                "label": "Kikugawa",
                "label_ar": "كيكوجاوا",
                "label_fr": "Kikugawa"
            },
            {
                "value": 2876,
                "label": "Kinokawa",
                "label_ar": "كينوكاوا",
                "label_fr": "Kinokawa"
            },
            {
                "value": 3525,
                "label": "Tokamachi",
                "label_ar": "توكاماتشي",
                "label_fr": "Tokamachi"
            },
            {
                "value": 3479,
                "label": "Tawaramoto",
                "label_ar": "تاواراموتو",
                "label_fr": "Tawaramoto"
            },
            {
                "value": 3027,
                "label": "Otaru",
                "label_ar": "أوتارو",
                "label_fr": "Otaru"
            },
            {
                "value": 2422,
                "label": "Ōita",
                "label_ar": "أويتا",
                "label_fr": "Ōita"
            },
            {
                "value": 2791,
                "label": "Tono",
                "label_ar": "تونو",
                "label_fr": "Tono"
            },
            {
                "value": 2934,
                "label": "Daimon",
                "label_ar": "ديمون",
                "label_fr": "Daimon"
            },
            {
                "value": 3270,
                "label": "Higashi-iwashiro",
                "label_ar": "هيغاشي إواشيرو",
                "label_fr": "Higashi-iwashiro"
            },
            {
                "value": 3290,
                "label": "Arai",
                "label_ar": "آراي",
                "label_fr": "Arai"
            },
            {
                "value": 3459,
                "label": "Toyonomachi-toyono",
                "label_ar": "تويونوماتشي تويونو",
                "label_fr": "Toyonomachi-toyono"
            },
            {
                "value": 3467,
                "label": "Satocho-sato",
                "label_ar": "ساتوشو ساتو",
                "label_fr": "Satocho-sato"
            },
            {
                "value": 3228,
                "label": "Tamachi",
                "label_ar": "تاماتشي",
                "label_fr": "Tamachi"
            },
            {
                "value": 2428,
                "label": "Yashio-shi",
                "label_ar": "ياشيو شي",
                "label_fr": "Yashio-shi"
            },
            {
                "value": 2663,
                "label": "Miki",
                "label_ar": "ميكي",
                "label_fr": "Miki"
            },
            {
                "value": 2972,
                "label": "Nakaichi",
                "label_ar": "ناكايتشي",
                "label_fr": "Nakaichi"
            },
            {
                "value": 3319,
                "label": "Tashima",
                "label_ar": "تاشيما",
                "label_fr": "Tashima"
            },
            {
                "value": 3311,
                "label": "Kagoya",
                "label_ar": "كاغويا",
                "label_fr": "Kagoya"
            },
            {
                "value": 2719,
                "label": "Suwa",
                "label_ar": "Suwa",
                "label_fr": "Suwa"
            },
            {
                "value": 3181,
                "label": "Atami",
                "label_ar": "أتامي",
                "label_fr": "Atami"
            },
            {
                "value": 2909,
                "label": "Ginza",
                "label_ar": "جينزا",
                "label_fr": "Ginza"
            },
            {
                "value": 2396,
                "label": "Sendai",
                "label_ar": "سينداي",
                "label_fr": "Sendai"
            },
            {
                "value": 2331,
                "label": "Tsukuba",
                "label_ar": "تسوكوبا",
                "label_fr": "Tsukuba"
            },
            {
                "value": 2824,
                "label": "Nose",
                "label_ar": "أنف",
                "label_fr": "Nez"
            },
            {
                "value": 2631,
                "label": "Waseda",
                "label_ar": "واسيدا",
                "label_fr": "Waseda"
            },
            {
                "value": 3190,
                "label": "Mitsui",
                "label_ar": "ميتسوي",
                "label_fr": "Mitsui"
            },
            {
                "value": 3406,
                "label": "Kusai",
                "label_ar": "كوساي",
                "label_fr": "Kusai"
            },
            {
                "value": 2384,
                "label": "Fussa",
                "label_ar": "فوسا",
                "label_fr": "Fussa"
            },
            {
                "value": 3164,
                "label": "Kasamatsucho",
                "label_ar": "كاساماتسوشو",
                "label_fr": "Kasamatsucho"
            },
            {
                "value": 2298,
                "label": "Hiroshima",
                "label_ar": "هيروشيما",
                "label_fr": "Hiroshima"
            },
            {
                "value": 3199,
                "label": "Muroran",
                "label_ar": "موروران",
                "label_fr": "Muroran"
            },
            {
                "value": 3047,
                "label": "Sakata",
                "label_ar": "ساكاتا",
                "label_fr": "Sakata"
            },
            {
                "value": 2851,
                "label": "Chiran",
                "label_ar": "شيران",
                "label_fr": "Chiran"
            },
            {
                "value": 3392,
                "label": "Shirakawa-machi",
                "label_ar": "شيراكاوا ماتشي",
                "label_fr": "Shirakawa-machi"
            },
            {
                "value": 2464,
                "label": "Yamagata-shi",
                "label_ar": "ياماغاتا شي",
                "label_fr": "Yamagata-shi"
            },
            {
                "value": 2998,
                "label": "Sumiyoshi",
                "label_ar": "سوميوشي",
                "label_fr": "Sumiyoshi"
            },
            {
                "value": 2540,
                "label": "Fukuroi",
                "label_ar": "فوكوروي",
                "label_fr": "Fukuroi"
            },
            {
                "value": 2553,
                "label": "Tochigi",
                "label_ar": "توتشيغي",
                "label_fr": "Tochigi"
            },
            {
                "value": 3409,
                "label": "Hama",
                "label_ar": "حماة",
                "label_fr": "Hama"
            },
            {
                "value": 2329,
                "label": "Wakamatsucho",
                "label_ar": "واكاماتسوشو",
                "label_fr": "Wakamatsucho"
            },
            {
                "value": 2357,
                "label": "Kanazawa",
                "label_ar": "كانازاوا",
                "label_fr": "Kanazawa"
            },
            {
                "value": 2551,
                "label": "Takatsuki",
                "label_ar": "تاكاتسوكي",
                "label_fr": "Takatsuki"
            },
            {
                "value": 3506,
                "label": "Hagi",
                "label_ar": "حاجي",
                "label_fr": "Hagi"
            },
            {
                "value": 2993,
                "label": "Takasu",
                "label_ar": "تاكاسو",
                "label_fr": "Takasu"
            },
            {
                "value": 2303,
                "label": "Suita",
                "label_ar": "سويتا",
                "label_fr": "Suita"
            },
            {
                "value": 2654,
                "label": "Hatsukaichi",
                "label_ar": "هاتسوكيتشي",
                "label_fr": "Hatsukaichi"
            },
            {
                "value": 2856,
                "label": "Shisui",
                "label_ar": "شيسوي",
                "label_fr": "Shisui"
            },
            {
                "value": 2907,
                "label": "Sakurai",
                "label_ar": "ساكوراي",
                "label_fr": "Sakurai"
            },
            {
                "value": 2717,
                "label": "Hachinohe",
                "label_ar": "هاشينوهي",
                "label_fr": "Hachinohe"
            },
            {
                "value": 2807,
                "label": "Hanyu",
                "label_ar": "هانيو",
                "label_fr": "Hanyu"
            },
            {
                "value": 2769,
                "label": "Ichinomotocho",
                "label_ar": "إيتشينوموتوتشو",
                "label_fr": "Ichinomotocho"
            },
            {
                "value": 2639,
                "label": "Tokoname",
                "label_ar": "توكونام",
                "label_fr": "Tokoname"
            },
            {
                "value": 3405,
                "label": "Miyata",
                "label_ar": "مياتا",
                "label_fr": "Miyata"
            },
            {
                "value": 2358,
                "label": "Maruyama",
                "label_ar": "ماروياما",
                "label_fr": "Maruyama"
            },
            {
                "value": 2926,
                "label": "Kinoshita",
                "label_ar": "كينوشيتا",
                "label_fr": "Kinoshita"
            },
            {
                "value": 2803,
                "label": "Sanno",
                "label_ar": "سانو",
                "label_fr": "Sanno"
            },
            {
                "value": 3275,
                "label": "Temma",
                "label_ar": "تيما",
                "label_fr": "Temma"
            },
            {
                "value": 3512,
                "label": "Toyota",
                "label_ar": "تويوتا",
                "label_fr": "Toyota"
            },
            {
                "value": 2373,
                "label": "Hiratsuka",
                "label_ar": "هيراتسوكا",
                "label_fr": "Hiratsuka"
            },
            {
                "value": 2847,
                "label": "Hachimori",
                "label_ar": "هاشيموري",
                "label_fr": "Hachimori"
            },
            {
                "value": 2702,
                "label": "Hikone",
                "label_ar": "هيكون",
                "label_fr": "Hikone"
            },
            {
                "value": 3128,
                "label": "Godo",
                "label_ar": "تذهب تفعل",
                "label_fr": "Va faire"
            },
            {
                "value": 3198,
                "label": "Tanaka",
                "label_ar": "تاناكا",
                "label_fr": "Tanaka"
            },
            {
                "value": 3273,
                "label": "Kahoku",
                "label_ar": "كاهوكو",
                "label_fr": "Kahoku"
            },
            {
                "value": 2517,
                "label": "Fukuyama",
                "label_ar": "فوكوياما",
                "label_fr": "Fukuyama"
            },
            {
                "value": 3076,
                "label": "Yaita",
                "label_ar": "ييتا",
                "label_fr": "Yaita"
            },
            {
                "value": 3102,
                "label": "Hachimantai",
                "label_ar": "هاتشيمانتاي",
                "label_fr": "Hachimantai"
            },
            {
                "value": 2349,
                "label": "Kishiwada",
                "label_ar": "كيشيوادا",
                "label_fr": "Kishiwada"
            },
            {
                "value": 2976,
                "label": "Chikusei",
                "label_ar": "شيكوسي",
                "label_fr": "Chikusei"
            },
            {
                "value": 2477,
                "label": "Zama",
                "label_ar": "زاما",
                "label_fr": "Zama"
            },
            {
                "value": 3364,
                "label": "Machiya",
                "label_ar": "مكية",
                "label_fr": "Machiya"
            },
            {
                "value": 3507,
                "label": "Kamikita-kita",
                "label_ar": "كاميكيتا كيتا",
                "label_fr": "Kamikita-kita"
            },
            {
                "value": 2603,
                "label": "Tamana",
                "label_ar": "تامانا",
                "label_fr": "Tamana"
            },
            {
                "value": 3511,
                "label": "Kazuka",
                "label_ar": "كازوكا",
                "label_fr": "Kazuka"
            },
            {
                "value": 3320,
                "label": "Yomogita",
                "label_ar": "يوموجيتا",
                "label_fr": "Yomogita"
            },
            {
                "value": 2738,
                "label": "Nishi-Tokyo-shi",
                "label_ar": "نيشي طوكيو شي",
                "label_fr": "Nishi-Tokyo-shi"
            },
            {
                "value": 2861,
                "label": "Minamata",
                "label_ar": "ميناماتا",
                "label_fr": "Minamata"
            },
            {
                "value": 2871,
                "label": "Yachimata",
                "label_ar": "Yachimata",
                "label_fr": "Yachimata"
            },
            {
                "value": 2863,
                "label": "Nakano",
                "label_ar": "ناكانو",
                "label_fr": "Nakano"
            },
            {
                "value": 2336,
                "label": "Kawaguchi",
                "label_ar": "كاواجوتشي",
                "label_fr": "Kawaguchi"
            },
            {
                "value": 3122,
                "label": "Sukagawa",
                "label_ar": "سوكاغاوا",
                "label_fr": "Sukagawa"
            },
            {
                "value": 3147,
                "label": "Mori",
                "label_ar": "موري",
                "label_fr": "Mori"
            },
            {
                "value": 3321,
                "label": "Ichimura",
                "label_ar": "إيشيمورا",
                "label_fr": "Ichimura"
            },
            {
                "value": 3496,
                "label": "Noboribetsu",
                "label_ar": "نوبوريبيتسو",
                "label_fr": "Noboribetsu"
            },
            {
                "value": 2747,
                "label": "Kan'onjicho",
                "label_ar": "Kan'onjicho",
                "label_fr": "Kan'onjicho"
            },
            {
                "value": 3217,
                "label": "Aisai",
                "label_ar": "أيساي",
                "label_fr": "Aisai"
            },
            {
                "value": 2890,
                "label": "Arita",
                "label_ar": "اريتا",
                "label_fr": "Arita"
            },
            {
                "value": 2460,
                "label": "Yamatomachi-mitsuai",
                "label_ar": "ياماتوماتشي ميتسواي",
                "label_fr": "Yamatomachi-mitsuai"
            },
            {
                "value": 2616,
                "label": "Matsubara",
                "label_ar": "ماتسوبارا",
                "label_fr": "Matsubara"
            },
            {
                "value": 2598,
                "label": "Tokai",
                "label_ar": "توكاي",
                "label_fr": "Tokai"
            },
            {
                "value": 2837,
                "label": "Tanushimarumachi-sugawara",
                "label_ar": "تانوشيماروماتشي سوغاوارا",
                "label_fr": "Tanushimarumachi-sugawara"
            },
            {
                "value": 3289,
                "label": "Fujita",
                "label_ar": "فوجيتا",
                "label_fr": "Fujita"
            },
            {
                "value": 2440,
                "label": "Anjo",
                "label_ar": "أنجو",
                "label_fr": "Anjo"
            },
            {
                "value": 3219,
                "label": "Higashicho",
                "label_ar": "هيجاشيشو",
                "label_fr": "Higashicho"
            },
            {
                "value": 3328,
                "label": "Kariya",
                "label_ar": "كارييا",
                "label_fr": "Kariya"
            },
            {
                "value": 3094,
                "label": "Settsu",
                "label_ar": "سيتسو",
                "label_fr": "Settsu"
            },
            {
                "value": 2451,
                "label": "Yokotemachi",
                "label_ar": "يوكوتيماتشي",
                "label_fr": "Yokotemachi"
            },
            {
                "value": 2646,
                "label": "Saku",
                "label_ar": "ساكو",
                "label_fr": "Saku"
            },
            {
                "value": 3426,
                "label": "Mombetsu",
                "label_ar": "مومبيتسو",
                "label_fr": "Mombetsu"
            },
            {
                "value": 3032,
                "label": "Mitsuke",
                "label_ar": "ميتسوكي",
                "label_fr": "Mitsuke"
            },
            {
                "value": 3432,
                "label": "Imaicho",
                "label_ar": "Imaicho",
                "label_fr": "Imaicho"
            },
            {
                "value": 2415,
                "label": "Kameyama",
                "label_ar": "كامياما",
                "label_fr": "Kameyama"
            },
            {
                "value": 2566,
                "label": "Suzuka",
                "label_ar": "سوزوكا",
                "label_fr": "Suzuka"
            },
            {
                "value": 3212,
                "label": "Senboku",
                "label_ar": "سينبوكو",
                "label_fr": "Senboku"
            },
            {
                "value": 2698,
                "label": "Warabi-shi",
                "label_ar": "الورابي شي",
                "label_fr": "Warabi-shi"
            },
            {
                "value": 3029,
                "label": "Tsurumi",
                "label_ar": "تسورومي",
                "label_fr": "Tsurumi"
            },
            {
                "value": 3291,
                "label": "Chatan",
                "label_ar": "شاتان",
                "label_fr": "Chatan"
            },
            {
                "value": 2508,
                "label": "Takasaki",
                "label_ar": "تاكاساكي",
                "label_fr": "Takasaki"
            },
            {
                "value": 2607,
                "label": "Himeji",
                "label_ar": "هيميجي",
                "label_fr": "Himeji"
            },
            {
                "value": 3265,
                "label": "Kamikawa",
                "label_ar": "كاميكاوا",
                "label_fr": "Kamikawa"
            },
            {
                "value": 2461,
                "label": "Niigata",
                "label_ar": "نيجاتا",
                "label_fr": "Niigata"
            },
            {
                "value": 3255,
                "label": "Nishiko",
                "label_ar": "نيشيكو",
                "label_fr": "Nishiko"
            },
            {
                "value": 2632,
                "label": "Takaoka",
                "label_ar": "تاكاوكا",
                "label_fr": "Takaoka"
            },
            {
                "value": 3373,
                "label": "Fukiage-fujimi",
                "label_ar": "فوكياج-فوجيمي",
                "label_fr": "Fukiage-fujimi"
            },
            {
                "value": 3233,
                "label": "Kitagata",
                "label_ar": "كيتاجاتا",
                "label_fr": "Kitagata"
            },
            {
                "value": 2952,
                "label": "Suda",
                "label_ar": "سودا",
                "label_fr": "Suda"
            },
            {
                "value": 3057,
                "label": "Haibara-akanedai",
                "label_ar": "Haibara-akanedai",
                "label_fr": "Haibara-akanedai"
            },
            {
                "value": 3520,
                "label": "Sakura",
                "label_ar": "ساكورا",
                "label_fr": "Sakura"
            },
            {
                "value": 3430,
                "label": "Honjo",
                "label_ar": "هونجو",
                "label_fr": "Honjo"
            },
            {
                "value": 3484,
                "label": "Gotsucho",
                "label_ar": "جوتسوتشو",
                "label_fr": "Gotsucho"
            },
            {
                "value": 2999,
                "label": "Bando",
                "label_ar": "باندو",
                "label_fr": "Bando"
            },
            {
                "value": 3339,
                "label": "Musashimurayama",
                "label_ar": "موساشيموراياما",
                "label_fr": "Musashimurayama"
            },
            {
                "value": 2445,
                "label": "Bakurocho",
                "label_ar": "باكوروشو",
                "label_fr": "Bakurocho"
            },
            {
                "value": 2729,
                "label": "Iwanuma",
                "label_ar": "إيوانوما",
                "label_fr": "Iwanuma"
            },
            {
                "value": 2721,
                "label": "Daido",
                "label_ar": "دايدو",
                "label_fr": "Daido"
            },
            {
                "value": 2883,
                "label": "Kaseda-shirakame",
                "label_ar": "كاسيدا شراكامي",
                "label_fr": "Kaseda-shirakame"
            },
            {
                "value": 2462,
                "label": "Machi",
                "label_ar": "ماشي",
                "label_fr": "Machi"
            },
            {
                "value": 2333,
                "label": "Yokohama",
                "label_ar": "يوكوهاما",
                "label_fr": "Yokohama"
            },
            {
                "value": 2410,
                "label": "Saijo",
                "label_ar": "سايجو",
                "label_fr": "Saijo"
            },
            {
                "value": 2975,
                "label": "Ninomiya",
                "label_ar": "نينومييا",
                "label_fr": "Ninomiya"
            },
            {
                "value": 2509,
                "label": "Naganohara",
                "label_ar": "ناغانوهارا",
                "label_fr": "Naganohara"
            },
            {
                "value": 2587,
                "label": "Takarazuka",
                "label_ar": "تاكارازوكا",
                "label_fr": "Takarazuka"
            },
            {
                "value": 3178,
                "label": "Yakumo",
                "label_ar": "ياكومو",
                "label_fr": "Yakumo"
            },
            {
                "value": 2341,
                "label": "Kobe",
                "label_ar": "كوبي",
                "label_fr": "Kobe"
            },
            {
                "value": 3314,
                "label": "Iju",
                "label_ar": "ايجو",
                "label_fr": "Iju"
            },
            {
                "value": 3452,
                "label": "Youkaichi",
                "label_ar": "Youkaichi",
                "label_fr": "Youkaichi"
            },
            {
                "value": 2637,
                "label": "Ureshino",
                "label_ar": "اوريشينو",
                "label_fr": "Ureshino"
            },
            {
                "value": 2700,
                "label": "Hinode",
                "label_ar": "هينود",
                "label_fr": "Hinode"
            },
            {
                "value": 2917,
                "label": "Tamatsukuri",
                "label_ar": "تاماتسوكوري",
                "label_fr": "Tamatsukuri"
            },
            {
                "value": 3130,
                "label": "Shinjuku",
                "label_ar": "شينجوكو",
                "label_fr": "Shinjuku"
            },
            {
                "value": 3150,
                "label": "Saidaiji-naka",
                "label_ar": "سيداجي ناكا",
                "label_fr": "Saidaiji-naka"
            },
            {
                "value": 3480,
                "label": "Yoichi",
                "label_ar": "يويتشي",
                "label_fr": "Yoichi"
            },
            {
                "value": 2513,
                "label": "Sakuracho",
                "label_ar": "ساكوراشو",
                "label_fr": "Sakuracho"
            },
            {
                "value": 2699,
                "label": "Fukushima-shi",
                "label_ar": "فوكوشيما شي",
                "label_fr": "Fukushima-shi"
            },
            {
                "value": 2762,
                "label": "Imari",
                "label_ar": "إماري",
                "label_fr": "Imari"
            },
            {
                "value": 3346,
                "label": "Kanda",
                "label_ar": "كاندا",
                "label_fr": "Kanda"
            },
            {
                "value": 2683,
                "label": "Katori-shi",
                "label_ar": "كاتوري شي",
                "label_fr": "Katori-shi"
            },
            {
                "value": 3092,
                "label": "Kahoku",
                "label_ar": "كاهوكو",
                "label_fr": "Kahoku"
            },
            {
                "value": 3225,
                "label": "Higashiura",
                "label_ar": "هيجاشيورا",
                "label_fr": "Higashiura"
            },
            {
                "value": 3197,
                "label": "Tadotsu",
                "label_ar": "تادوتسو",
                "label_fr": "Tadotsu"
            },
            {
                "value": 2442,
                "label": "Hanabatacho",
                "label_ar": "هاناباتاشو",
                "label_fr": "Hanabatacho"
            },
            {
                "value": 3521,
                "label": "Asahi",
                "label_ar": "اساهي",
                "label_fr": "Asahi"
            },
            {
                "value": 3174,
                "label": "Mashiko",
                "label_ar": "ماشيكو",
                "label_fr": "Mashiko"
            },
            {
                "value": 2954,
                "label": "Minato",
                "label_ar": "ميناتو",
                "label_fr": "Minato"
            },
            {
                "value": 2314,
                "label": "Gotemba",
                "label_ar": "جوتيمبا",
                "label_fr": "Gotemba"
            },
            {
                "value": 2382,
                "label": "Ochikawa",
                "label_ar": "أوشيكاوا",
                "label_fr": "Ochikawa"
            },
            {
                "value": 2376,
                "label": "Yamashitacho",
                "label_ar": "ياماشيتاتشو",
                "label_fr": "Yamashitacho"
            },
            {
                "value": 2630,
                "label": "Higashiyama",
                "label_ar": "هيجاشياما",
                "label_fr": "Higashiyama"
            },
            {
                "value": 2638,
                "label": "Tsushima",
                "label_ar": "تسوشيما",
                "label_fr": "Tsushima"
            },
            {
                "value": 2798,
                "label": "Kokura",
                "label_ar": "كوكورا",
                "label_fr": "Kokura"
            },
            {
                "value": 2962,
                "label": "Yamada",
                "label_ar": "يامادا",
                "label_fr": "Yamada"
            },
            {
                "value": 3492,
                "label": "Kitaibaraki",
                "label_ar": "كيتاباراكي",
                "label_fr": "Kitaibaraki"
            },
            {
                "value": 3307,
                "label": "Kataoka",
                "label_ar": "كاتاوكا",
                "label_fr": "Kataoka"
            },
            {
                "value": 2327,
                "label": "Hachiōji",
                "label_ar": "هاتشيوجي",
                "label_fr": "Hachiōji"
            },
            {
                "value": 3474,
                "label": "Yokaichiba",
                "label_ar": "يوكيتشيبا",
                "label_fr": "Yokaichiba"
            },
            {
                "value": 3306,
                "label": "Nishimachi",
                "label_ar": "نيشيماتشي",
                "label_fr": "Nishimachi"
            },
            {
                "value": 2311,
                "label": "Shibukawa",
                "label_ar": "شيبوكاوا",
                "label_fr": "Shibukawa"
            },
            {
                "value": 2400,
                "label": "Sagamihara-shi",
                "label_ar": "ساغاميهارا شي",
                "label_fr": "Sagamihara-shi"
            },
            {
                "value": 2361,
                "label": "Nishio",
                "label_ar": "نيشيو",
                "label_fr": "Nishio"
            },
            {
                "value": 2754,
                "label": "Mibu",
                "label_ar": "ميبو",
                "label_fr": "Mibu"
            },
            {
                "value": 3324,
                "label": "Takaiwa",
                "label_ar": "تاكايوا",
                "label_fr": "Takaiwa"
            },
            {
                "value": 3143,
                "label": "Katsumada",
                "label_ar": "كاتسومادا",
                "label_fr": "Katsumada"
            },
            {
                "value": 2455,
                "label": "Yotsukaido",
                "label_ar": "يوتسوكايدو",
                "label_fr": "Yotsukaido"
            },
            {
                "value": 2908,
                "label": "Nishihara",
                "label_ar": "نيشيهارا",
                "label_fr": "Nishihara"
            },
            {
                "value": 3211,
                "label": "Nikaho",
                "label_ar": "نيكاهو",
                "label_fr": "Nikaho"
            },
            {
                "value": 2726,
                "label": "Matsusaka",
                "label_ar": "ماتسوساكا",
                "label_fr": "Matsusaka"
            },
            {
                "value": 3353,
                "label": "Tsukumiura",
                "label_ar": "تسوكوميورا",
                "label_fr": "Tsukumiura"
            },
            {
                "value": 3031,
                "label": "Ina",
                "label_ar": "في",
                "label_fr": "Dans un"
            },
            {
                "value": 2942,
                "label": "Honcho",
                "label_ar": "هونشو",
                "label_fr": "Honcho"
            },
            {
                "value": 2653,
                "label": "Mine",
                "label_ar": "الخاص بي",
                "label_fr": "Mien"
            },
            {
                "value": 2835,
                "label": "Nakano",
                "label_ar": "ناكانو",
                "label_fr": "Nakano"
            },
            {
                "value": 3042,
                "label": "Ichinoseki",
                "label_ar": "ايتشينوسيكي",
                "label_fr": "Ichinoseki"
            },
            {
                "value": 2302,
                "label": "Urayasu",
                "label_ar": "أوراياسو",
                "label_fr": "Urayasu"
            },
            {
                "value": 2707,
                "label": "Takanawa",
                "label_ar": "تاكاناوا",
                "label_fr": "Takanawa"
            },
            {
                "value": 2734,
                "label": "Nita",
                "label_ar": "نيتا",
                "label_fr": "Nita"
            },
            {
                "value": 2733,
                "label": "Nomimachi",
                "label_ar": "نوميماتشي",
                "label_fr": "Nomimachi"
            },
            {
                "value": 2739,
                "label": "Yasugicho",
                "label_ar": "ياسوجيتشو",
                "label_fr": "Yasugicho"
            },
            {
                "value": 2995,
                "label": "Kii-nagashimaku-nagashima",
                "label_ar": "كي-ناغاشيماكو-ناغاشيما",
                "label_fr": "Kii-nagashimaku-nagashima"
            },
            {
                "value": 2776,
                "label": "Omiya-ku",
                "label_ar": "أوميا كو",
                "label_fr": "Omiya-ku"
            },
            {
                "value": 3044,
                "label": "Takikawa",
                "label_ar": "تاكيكاوا",
                "label_fr": "Takikawa"
            },
            {
                "value": 3149,
                "label": "Katsuyama",
                "label_ar": "كاتسوياما",
                "label_fr": "Katsuyama"
            },
            {
                "value": 3177,
                "label": "Towada",
                "label_ar": "تووادا",
                "label_fr": "Towada"
            },
            {
                "value": 2826,
                "label": "Gundo",
                "label_ar": "جوندو",
                "label_fr": "Gundo"
            },
            {
                "value": 2963,
                "label": "Onna",
                "label_ar": "أونا",
                "label_fr": "Onna"
            },
            {
                "value": 3456,
                "label": "Kanegasaki",
                "label_ar": "كانيغاساكي",
                "label_fr": "Kanegasaki"
            },
            {
                "value": 2652,
                "label": "Tainoura",
                "label_ar": "تاينورة",
                "label_fr": "Tainoura"
            },
            {
                "value": 3397,
                "label": "Seiro",
                "label_ar": "سيرو",
                "label_fr": "Seiro"
            },
            {
                "value": 2465,
                "label": "Hanamaki",
                "label_ar": "هاناماكي",
                "label_fr": "Hanamaki"
            },
            {
                "value": 2689,
                "label": "Naka",
                "label_ar": "ناكا",
                "label_fr": "Naka"
            },
            {
                "value": 2782,
                "label": "Isahaya",
                "label_ar": "إشعياء",
                "label_fr": "Isahaya"
            },
            {
                "value": 2802,
                "label": "Okuura",
                "label_ar": "Okuura",
                "label_fr": "Okuura"
            },
            {
                "value": 2647,
                "label": "Minato",
                "label_ar": "ميناتو",
                "label_fr": "Minato"
            },
            {
                "value": 3043,
                "label": "Sanjo",
                "label_ar": "سانجو",
                "label_fr": "Sanjo"
            },
            {
                "value": 3316,
                "label": "Daisen",
                "label_ar": "دايسن",
                "label_fr": "Daisen"
            },
            {
                "value": 2600,
                "label": "Kumamoto",
                "label_ar": "كوماموتو",
                "label_fr": "Kumamoto"
            },
            {
                "value": 3034,
                "label": "Akasaka",
                "label_ar": "أكاساكا",
                "label_fr": "Akasaka"
            },
            {
                "value": 3090,
                "label": "Marunouchi",
                "label_ar": "مارونوتشي",
                "label_fr": "Marunouchi"
            },
            {
                "value": 3341,
                "label": "Anesaki",
                "label_ar": "أنيساكي",
                "label_fr": "Anesaki"
            },
            {
                "value": 2888,
                "label": "Minoshima",
                "label_ar": "مينوشيما",
                "label_fr": "Minoshima"
            },
            {
                "value": 2705,
                "label": "Koyama",
                "label_ar": "كوياما",
                "label_fr": "Koyama"
            },
            {
                "value": 3091,
                "label": "Kitamoto",
                "label_ar": "كيتاموتو",
                "label_fr": "Kitamoto"
            },
            {
                "value": 3293,
                "label": "Oarai",
                "label_ar": "Oarai",
                "label_fr": "Oarai"
            },
            {
                "value": 3501,
                "label": "Ino",
                "label_ar": "انا لا",
                "label_fr": "Ino"
            },
            {
                "value": 3268,
                "label": "Aso",
                "label_ar": "اسو",
                "label_fr": "Aso"
            },
            {
                "value": 3429,
                "label": "Esashi",
                "label_ar": "إيساشي",
                "label_fr": "Esashi"
            },
            {
                "value": 2869,
                "label": "Ikaruga",
                "label_ar": "إيكاروجا",
                "label_fr": "Ikaruga"
            },
            {
                "value": 3301,
                "label": "Shikamaku-miya",
                "label_ar": "شيكاماكو ميا",
                "label_fr": "Shikamaku-miya"
            },
            {
                "value": 3010,
                "label": "Tomioka",
                "label_ar": "توميوكا",
                "label_fr": "Tomioka"
            },
            {
                "value": 2819,
                "label": "Nakada",
                "label_ar": "نقادة",
                "label_fr": "Nakada"
            },
            {
                "value": 3408,
                "label": "Kusunoki",
                "label_ar": "كوسونوكي",
                "label_fr": "Kusunoki"
            },
            {
                "value": 3247,
                "label": "Otani",
                "label_ar": "اوتاني",
                "label_fr": "Otani"
            },
            {
                "value": 2313,
                "label": "Yamakita",
                "label_ar": "ياماكيتا",
                "label_fr": "Yamakita"
            },
            {
                "value": 3020,
                "label": "Hokota",
                "label_ar": "هوكوتا",
                "label_fr": "Hokota"
            },
            {
                "value": 3370,
                "label": "Onojo",
                "label_ar": "أونوجو",
                "label_fr": "Onojo"
            },
            {
                "value": 3208,
                "label": "Minowa",
                "label_ar": "مينوا",
                "label_fr": "Minowa"
            },
            {
                "value": 3030,
                "label": "Yokoo",
                "label_ar": "يوكو",
                "label_fr": "Yokoo"
            },
            {
                "value": 3441,
                "label": "Kotohira",
                "label_ar": "كوتوهيرا",
                "label_fr": "Kotohira"
            },
            {
                "value": 3323,
                "label": "Tamura",
                "label_ar": "تامورا",
                "label_fr": "Tamura"
            },
            {
                "value": 3367,
                "label": "Muikamachi",
                "label_ar": "Muikamachi",
                "label_fr": "Muikamachi"
            },
            {
                "value": 2307,
                "label": "Isesaki",
                "label_ar": "إيزيساكي",
                "label_fr": "Isesaki"
            },
            {
                "value": 2858,
                "label": "Tomo",
                "label_ar": "تومو",
                "label_fr": "Tomo"
            },
            {
                "value": 3037,
                "label": "Wakasa",
                "label_ar": "واكاسا",
                "label_fr": "Wakasa"
            },
            {
                "value": 2714,
                "label": "Horyuji",
                "label_ar": "هوريوجي",
                "label_fr": "Horyuji"
            },
            {
                "value": 3285,
                "label": "Nakagomi",
                "label_ar": "ناكاجومي",
                "label_fr": "Nakagomi"
            },
            {
                "value": 3366,
                "label": "Sekicho-furumaya",
                "label_ar": "سيكيشو فورومايا",
                "label_fr": "Sekicho-furumaya"
            },
            {
                "value": 2772,
                "label": "Tsukubamirai",
                "label_ar": "تسوكوباميراي",
                "label_fr": "Tsukubamirai"
            },
            {
                "value": 2873,
                "label": "Kiyose",
                "label_ar": "كيوس",
                "label_fr": "Kiyose"
            },
            {
                "value": 2568,
                "label": "Usa",
                "label_ar": "الولايات المتحدة الأمريكية",
                "label_fr": "Etats-Unis"
            },
            {
                "value": 3214,
                "label": "Nanto",
                "label_ar": "نانتو",
                "label_fr": "Nanto"
            },
            {
                "value": 3261,
                "label": "Sabae",
                "label_ar": "Sabae",
                "label_fr": "Sabae"
            },
            {
                "value": 2780,
                "label": "Yonezawa",
                "label_ar": "يونيزاوا",
                "label_fr": "Yonezawa"
            },
            {
                "value": 2589,
                "label": "Kakogawa",
                "label_ar": "كاكوجاوا",
                "label_fr": "Kakogawa"
            },
            {
                "value": 2716,
                "label": "Anabuki",
                "label_ar": "أنابوكي",
                "label_fr": "Anabuki"
            },
            {
                "value": 3234,
                "label": "Shimabara",
                "label_ar": "شيمابارا",
                "label_fr": "Shimabara"
            },
            {
                "value": 3371,
                "label": "Tsuhako",
                "label_ar": "تسوهاكو",
                "label_fr": "Tsuhako"
            },
            {
                "value": 3468,
                "label": "Kami-kawakami",
                "label_ar": "كامي كاواكامي",
                "label_fr": "Kami-kawakami"
            },
            {
                "value": 2503,
                "label": "Okegawa",
                "label_ar": "Okegawa",
                "label_fr": "Okegawa"
            },
            {
                "value": 2526,
                "label": "Edogawa-ku",
                "label_ar": "إيدوجاوا كو",
                "label_fr": "Edogawa-ku"
            },
            {
                "value": 2695,
                "label": "Kuroishi",
                "label_ar": "كوروشي",
                "label_fr": "Kuroishi"
            },
            {
                "value": 2775,
                "label": "Kisarazu",
                "label_ar": "كيسارازو",
                "label_fr": "Kisarazu"
            },
            {
                "value": 2703,
                "label": "Muko",
                "label_ar": "موكو",
                "label_fr": "Muko"
            },
            {
                "value": 3045,
                "label": "Otsuki",
                "label_ar": "أوتسوكي",
                "label_fr": "Otsuki"
            },
            {
                "value": 3274,
                "label": "Inami",
                "label_ar": "إينامي",
                "label_fr": "Inami"
            },
            {
                "value": 3184,
                "label": "Yaese",
                "label_ar": "ييس",
                "label_fr": "Yaese"
            },
            {
                "value": 2466,
                "label": "Hashimoto",
                "label_ar": "هاشيموتو",
                "label_fr": "Hashimoto"
            },
            {
                "value": 2532,
                "label": "Shirokane",
                "label_ar": "شيروكان",
                "label_fr": "Shirokane"
            },
            {
                "value": 3133,
                "label": "Higashikawa",
                "label_ar": "هيجاشيكاوا",
                "label_fr": "Higashikawa"
            },
            {
                "value": 3294,
                "label": "Shin",
                "label_ar": "قصبة",
                "label_fr": "Tibia"
            },
            {
                "value": 2685,
                "label": "Aomori",
                "label_ar": "أوموري",
                "label_fr": "Aomori"
            },
            {
                "value": 2937,
                "label": "Toranomon",
                "label_ar": "تورانومون",
                "label_fr": "Toranomon"
            },
            {
                "value": 3399,
                "label": "Kanada",
                "label_ar": "كنادا",
                "label_fr": "Kanada"
            },
            {
                "value": 3414,
                "label": "Misakimachi-konami",
                "label_ar": "ميساكيماشي-كونامي",
                "label_fr": "Misakimachi-konami"
            },
            {
                "value": 2494,
                "label": "Miyazaki-shi",
                "label_ar": "ميازاكي شي",
                "label_fr": "Miyazaki-shi"
            },
            {
                "value": 2488,
                "label": "Asaka",
                "label_ar": "أساكا",
                "label_fr": "Asaka"
            },
            {
                "value": 2334,
                "label": "Yamanashi",
                "label_ar": "ياماناشي",
                "label_fr": "Yamanashi"
            },
            {
                "value": 2579,
                "label": "Asahikawa",
                "label_ar": "اساهيكاوا",
                "label_fr": "Asahikawa"
            },
            {
                "value": 2690,
                "label": "Niihama",
                "label_ar": "نييهاما",
                "label_fr": "Niihama"
            },
            {
                "value": 2380,
                "label": "Kurume",
                "label_ar": "كورومي",
                "label_fr": "Kurume"
            },
            {
                "value": 2321,
                "label": "Chita",
                "label_ar": "تشيتا",
                "label_fr": "Chita"
            },
            {
                "value": 2742,
                "label": "Uozu",
                "label_ar": "أوزو",
                "label_fr": "Uozu"
            },
            {
                "value": 3035,
                "label": "Miyama",
                "label_ar": "مياما",
                "label_fr": "Miyama"
            },
            {
                "value": 3162,
                "label": "Ena",
                "label_ar": "اينا",
                "label_fr": "Ena"
            },
            {
                "value": 3313,
                "label": "Murai",
                "label_ar": "موراي",
                "label_fr": "Murai"
            },
            {
                "value": 3330,
                "label": "Mitai",
                "label_ar": "ميتاي",
                "label_fr": "Mitai"
            },
            {
                "value": 3439,
                "label": "Odori",
                "label_ar": "أودوري",
                "label_fr": "Odori"
            },
            {
                "value": 2855,
                "label": "Yoshida-kasugacho",
                "label_ar": "يوشيدا كاسوغاتشو",
                "label_fr": "Yoshida-kasugacho"
            },
            {
                "value": 2945,
                "label": "Yamatomachi",
                "label_ar": "ياماتوماتشي",
                "label_fr": "Yamatomachi"
            },
            {
                "value": 3463,
                "label": "Kitsuregawa",
                "label_ar": "Kitsuregawa",
                "label_fr": "Kitsuregawa"
            },
            {
                "value": 2304,
                "label": "Funabashi",
                "label_ar": "فوناباشي",
                "label_fr": "Funabashi"
            },
            {
                "value": 2377,
                "label": "Fukuoka",
                "label_ar": "فوكوكا",
                "label_fr": "Fukuoka"
            },
            {
                "value": 2539,
                "label": "Shimada",
                "label_ar": "شيمادا",
                "label_fr": "Shimada"
            },
            {
                "value": 2597,
                "label": "Maebaru",
                "label_ar": "ميبارو",
                "label_fr": "Maebaru"
            },
            {
                "value": 2541,
                "label": "Makinohara",
                "label_ar": "ماكينوهارا",
                "label_fr": "Makinohara"
            },
            {
                "value": 3025,
                "label": "Obama",
                "label_ar": "أوباما",
                "label_fr": "Obama"
            },
            {
                "value": 2427,
                "label": "Fujimino",
                "label_ar": "فوجيمينو",
                "label_fr": "Fujimino"
            },
            {
                "value": 2515,
                "label": "Moriya",
                "label_ar": "موريا",
                "label_fr": "Moriya"
            },
            {
                "value": 2765,
                "label": "Takeo",
                "label_ar": "اتخاذ س",
                "label_fr": "Takeo"
            },
            {
                "value": 2534,
                "label": "Shimizumachi",
                "label_ar": "شيميزوماتشي",
                "label_fr": "Shimizumachi"
            },
            {
                "value": 2914,
                "label": "Hiranuma",
                "label_ar": "هيرانوما",
                "label_fr": "Hiranuma"
            },
            {
                "value": 2965,
                "label": "Hodogayacho",
                "label_ar": "هودوجاياتشو",
                "label_fr": "Hodogayacho"
            },
            {
                "value": 3440,
                "label": "Nagashima",
                "label_ar": "ناغاشيما",
                "label_fr": "Nagashima"
            },
            {
                "value": 2378,
                "label": "Kasuga",
                "label_ar": "كاسوجا",
                "label_fr": "Kasuga"
            },
            {
                "value": 2910,
                "label": "Kojima",
                "label_ar": "كوجيما",
                "label_fr": "Kojima"
            },
            {
                "value": 3167,
                "label": "Saikai",
                "label_ar": "سايكاي",
                "label_fr": "Saikai"
            },
            {
                "value": 3464,
                "label": "Ishige",
                "label_ar": "إيشيجي",
                "label_fr": "Ishige"
            },
            {
                "value": 2330,
                "label": "Yachiyo",
                "label_ar": "ياتشيو",
                "label_fr": "Yachiyo"
            },
            {
                "value": 2528,
                "label": "Mizuko",
                "label_ar": "ميزوكو",
                "label_fr": "Mizuko"
            },
            {
                "value": 2971,
                "label": "Kodamacho-kodamaminami",
                "label_ar": "كوداماتشو كودامامينامي",
                "label_fr": "Kodamacho-kodamaminami"
            },
            {
                "value": 2315,
                "label": "Nisshin",
                "label_ar": "نيشين",
                "label_fr": "Nisshin"
            },
            {
                "value": 2650,
                "label": "Yokkaichi",
                "label_ar": "يوكايتشي",
                "label_fr": "Yokkaichi"
            },
            {
                "value": 3055,
                "label": "Kitanagoya",
                "label_ar": "كيتاناغويا",
                "label_fr": "Kitanagoya"
            },
            {
                "value": 3369,
                "label": "Ishii",
                "label_ar": "إيشي",
                "label_fr": "Ishii"
            },
            {
                "value": 2521,
                "label": "Fukayacho",
                "label_ar": "فوكاياشو",
                "label_fr": "Fukayacho"
            },
            {
                "value": 2496,
                "label": "Yugawara",
                "label_ar": "يوجاوارا",
                "label_fr": "Yugawara"
            },
            {
                "value": 2797,
                "label": "Yatsushiro",
                "label_ar": "ياتسوشيرو",
                "label_fr": "Yatsushiro"
            },
            {
                "value": 2922,
                "label": "Yodogawa",
                "label_ar": "يودوجاوا",
                "label_fr": "Yodogawa"
            },
            {
                "value": 3022,
                "label": "Nonoichi",
                "label_ar": "نونويتشي",
                "label_fr": "Nonoichi"
            },
            {
                "value": 2545,
                "label": "Fujimi",
                "label_ar": "فوجيمي",
                "label_fr": "Fujimi"
            },
            {
                "value": 2977,
                "label": "Hinata",
                "label_ar": "هيناتا",
                "label_fr": "Hinata"
            },
            {
                "value": 2866,
                "label": "Shinmachi",
                "label_ar": "شينماتشي",
                "label_fr": "Shinmachi"
            },
            {
                "value": 3077,
                "label": "Hidakacho-ebara",
                "label_ar": "هيداكاتشو إيبارا",
                "label_fr": "Hidakacho-ebara"
            },
            {
                "value": 3229,
                "label": "Nara",
                "label_ar": "نارا",
                "label_fr": "Nara"
            },
            {
                "value": 3368,
                "label": "Hikawa",
                "label_ar": "هيكاوا",
                "label_fr": "Hikawa"
            },
            {
                "value": 3340,
                "label": "Yoneyamacho-nishino",
                "label_ar": "يونياماتشو نيشينو",
                "label_fr": "Yoneyamacho-nishino"
            },
            {
                "value": 2838,
                "label": "Azumino",
                "label_ar": "أزومينو",
                "label_fr": "Azumino"
            },
            {
                "value": 2634,
                "label": "Narita",
                "label_ar": "ناريتا",
                "label_fr": "Narita"
            },
            {
                "value": 2651,
                "label": "Ebisu",
                "label_ar": "إيبيسو",
                "label_fr": "Ebisu"
            },
            {
                "value": 3107,
                "label": "Yamazakicho-nakabirose",
                "label_ar": "Yamazakicho-nakabirose",
                "label_fr": "Yamazakicho-nakabirose"
            },
            {
                "value": 3060,
                "label": "Nogata",
                "label_ar": "نوجاتا",
                "label_fr": "Nogata"
            },
            {
                "value": 2811,
                "label": "Uenohara",
                "label_ar": "أوينوهارا",
                "label_fr": "Uenohara"
            },
            {
                "value": 2720,
                "label": "Kashiwazaki",
                "label_ar": "كاشيوازاكي",
                "label_fr": "Kashiwazaki"
            },
            {
                "value": 2760,
                "label": "Gifu",
                "label_ar": "جيفو",
                "label_fr": "Gifu"
            },
            {
                "value": 3151,
                "label": "Kamogatacho-kamogata",
                "label_ar": "كاموجاتاشو كاموجاتا",
                "label_fr": "Kamogatacho-kamogata"
            },
            {
                "value": 3108,
                "label": "Togitsu",
                "label_ar": "توجيتسو",
                "label_fr": "Togitsu"
            },
            {
                "value": 3493,
                "label": "Onoda",
                "label_ar": "أونودا",
                "label_fr": "Onoda"
            },
            {
                "value": 2770,
                "label": "Sawara",
                "label_ar": "سوارا",
                "label_fr": "Sawara"
            },
            {
                "value": 2944,
                "label": "Nakahata",
                "label_ar": "ناكاهاتا",
                "label_fr": "Nakahata"
            },
            {
                "value": 2893,
                "label": "Anan",
                "label_ar": "عنان",
                "label_fr": "Anan"
            },
            {
                "value": 3343,
                "label": "Kiyama",
                "label_ar": "كياما",
                "label_fr": "Kiyama"
            },
            {
                "value": 2436,
                "label": "Hitachi",
                "label_ar": "هيتاشي",
                "label_fr": "Hitachi"
            },
            {
                "value": 2757,
                "label": "Futtsu",
                "label_ar": "فوتسو",
                "label_fr": "Futtsu"
            },
            {
                "value": 2397,
                "label": "Ōtsu",
                "label_ar": "أوتسو",
                "label_fr": "Ōtsu"
            },
            {
                "value": 2320,
                "label": "Moriguchi",
                "label_ar": "موريجوتشي",
                "label_fr": "Moriguchi"
            },
            {
                "value": 2572,
                "label": "Chichibu",
                "label_ar": "تشيتشيبو",
                "label_fr": "Chichibu"
            },
            {
                "value": 3252,
                "label": "Yanagawa",
                "label_ar": "ياناغاوا",
                "label_fr": "Yanagawa"
            },
            {
                "value": 3112,
                "label": "Fujiidera",
                "label_ar": "فوجيديرا",
                "label_fr": "Fujiidera"
            },
            {
                "value": 3296,
                "label": "Oshima",
                "label_ar": "أوشيما",
                "label_fr": "Oshima"
            },
            {
                "value": 2473,
                "label": "Kamirenjaku",
                "label_ar": "كاميرينجاكو",
                "label_fr": "Kamirenjaku"
            },
            {
                "value": 2474,
                "label": "Ishioka",
                "label_ar": "إيشيوكا",
                "label_fr": "Ishioka"
            },
            {
                "value": 3504,
                "label": "Nagaoka",
                "label_ar": "ناجاوكا",
                "label_fr": "Nagaoka"
            },
            {
                "value": 3471,
                "label": "Futoo",
                "label_ar": "فوتو",
                "label_fr": "Futoo"
            },
            {
                "value": 3295,
                "label": "Tsuru",
                "label_ar": "تسورو",
                "label_fr": "Tsuru"
            },
            {
                "value": 2472,
                "label": "Koto",
                "label_ar": "كوتو",
                "label_fr": "Koto"
            },
            {
                "value": 2829,
                "label": "Nada",
                "label_ar": "ندى",
                "label_fr": "Nada"
            },
            {
                "value": 3003,
                "label": "Shobara",
                "label_ar": "شبارة",
                "label_fr": "Shobara"
            },
            {
                "value": 3523,
                "label": "Kamibun",
                "label_ar": "كاميبون",
                "label_fr": "Kamibun"
            },
            {
                "value": 3095,
                "label": "Shingu",
                "label_ar": "شينغو",
                "label_fr": "Shingu"
            },
            {
                "value": 3362,
                "label": "Saiki",
                "label_ar": "سايكي",
                "label_fr": "Saiki"
            },
            {
                "value": 3253,
                "label": "Kurihara",
                "label_ar": "كوريهارا",
                "label_fr": "Kurihara"
            },
            {
                "value": 2297,
                "label": "Tokyo",
                "label_ar": "طوكيو",
                "label_fr": "Tokyo"
            },
            {
                "value": 2347,
                "label": "Toyonaka",
                "label_ar": "تويوناكا",
                "label_fr": "Toyonaka"
            },
            {
                "value": 2585,
                "label": "Koriyama",
                "label_ar": "كورياما",
                "label_fr": "Koriyama"
            },
            {
                "value": 2799,
                "label": "Hakata",
                "label_ar": "هاكاتا",
                "label_fr": "Hakata"
            },
            {
                "value": 2481,
                "label": "Nerima-ku",
                "label_ar": "نيريما كو",
                "label_fr": "Nerima-ku"
            },
            {
                "value": 3049,
                "label": "Tomisato",
                "label_ar": "طماطم",
                "label_fr": "Tomisato"
            },
            {
                "value": 3449,
                "label": "Yanagi",
                "label_ar": "ياناجي",
                "label_fr": "Yanagi"
            },
            {
                "value": 2362,
                "label": "Ichinomiya",
                "label_ar": "ايتشينوميا",
                "label_fr": "Ichinomiya"
            },
            {
                "value": 3176,
                "label": "Tsuchiara",
                "label_ar": "تسوتشيارا",
                "label_fr": "Tsuchiara"
            },
            {
                "value": 2338,
                "label": "Shizuoka",
                "label_ar": "شيزوكا",
                "label_fr": "Shizuoka"
            },
            {
                "value": 2595,
                "label": "Onomichi",
                "label_ar": "أونوميتشي",
                "label_fr": "Onomichi"
            },
            {
                "value": 2417,
                "label": "Machi",
                "label_ar": "ماشي",
                "label_fr": "Machi"
            },
            {
                "value": 2712,
                "label": "Ohara",
                "label_ar": "أوهارا",
                "label_fr": "Ohara"
            },
            {
                "value": 3156,
                "label": "Murata",
                "label_ar": "موراتا",
                "label_fr": "Murata"
            },
            {
                "value": 2475,
                "label": "Ryugasaki",
                "label_ar": "ريوغازاكي",
                "label_fr": "Ryugasaki"
            },
            {
                "value": 2860,
                "label": "Kaita",
                "label_ar": "كايتا",
                "label_fr": "Kaita"
            },
            {
                "value": 3105,
                "label": "Sado",
                "label_ar": "سادو",
                "label_fr": "Sado"
            },
            {
                "value": 3469,
                "label": "Teine-ku",
                "label_ar": "تيني كو",
                "label_fr": "Teine-ku"
            },
            {
                "value": 2626,
                "label": "Itō",
                "label_ar": "It",
                "label_fr": "Itō"
            },
            {
                "value": 3187,
                "label": "Ueki",
                "label_ar": "يوكي",
                "label_fr": "Ueki"
            },
            {
                "value": 3400,
                "label": "Sumiyocho-kawauchi",
                "label_ar": "سوميوشو كاواوتشي",
                "label_fr": "Sumiyocho-kawauchi"
            },
            {
                "value": 3245,
                "label": "Mochida",
                "label_ar": "موشيدا",
                "label_fr": "Mochida"
            },
            {
                "value": 2655,
                "label": "Mino",
                "label_ar": "مينو",
                "label_fr": "Mino"
            },
            {
                "value": 2898,
                "label": "Motomiya",
                "label_ar": "Motomiya",
                "label_fr": "Motomiya"
            },
            {
                "value": 3129,
                "label": "Gushikawa",
                "label_ar": "غوشيكاوا",
                "label_fr": "Gushikawa"
            },
            {
                "value": 3002,
                "label": "Tagawa",
                "label_ar": "تاجاوا",
                "label_fr": "Tagawa"
            },
            {
                "value": 3280,
                "label": "Matsuto",
                "label_ar": "ماتسوتو",
                "label_fr": "Matsuto"
            },
            {
                "value": 3210,
                "label": "Nichinan",
                "label_ar": "نيتشينان",
                "label_fr": "Nichinan"
            },
            {
                "value": 3461,
                "label": "Wakimachi",
                "label_ar": "واكيماشي",
                "label_fr": "Wakimachi"
            },
            {
                "value": 2441,
                "label": "Bandaicho",
                "label_ar": "بانديشو",
                "label_fr": "Bandaicho"
            },
            {
                "value": 3015,
                "label": "Taga",
                "label_ar": "تاجا",
                "label_fr": "Taga"
            },
            {
                "value": 3040,
                "label": "Mizusawa",
                "label_ar": "ميزوساوا",
                "label_fr": "Mizusawa"
            },
            {
                "value": 3204,
                "label": "Hayashi",
                "label_ar": "هاياشي",
                "label_fr": "Hayashi"
            },
            {
                "value": 2444,
                "label": "Obihiro",
                "label_ar": "اوبيهيرو",
                "label_fr": "Obihiro"
            },
            {
                "value": 2416,
                "label": "Taiji",
                "label_ar": "Taiji",
                "label_fr": "Taiji"
            },
            {
                "value": 3279,
                "label": "Yamauchi",
                "label_ar": "ياموتشي",
                "label_fr": "Yamauchi"
            },
            {
                "value": 3318,
                "label": "Shinagawa",
                "label_ar": "شيناغاوا",
                "label_fr": "Shinagawa"
            },
            {
                "value": 2559,
                "label": "Tendo",
                "label_ar": "تيندو",
                "label_fr": "Tendo"
            },
            {
                "value": 3308,
                "label": "Kikyo",
                "label_ar": "كيكيو",
                "label_fr": "Kikyo"
            },
            {
                "value": 2849,
                "label": "Katta",
                "label_ar": "كاتا",
                "label_fr": "Katta"
            },
            {
                "value": 3393,
                "label": "Komaba",
                "label_ar": "كومابا",
                "label_fr": "Komaba"
            },
            {
                "value": 3447,
                "label": "Itoigawa",
                "label_ar": "إتويجاوا",
                "label_fr": "Itoigawa"
            },
            {
                "value": 2730,
                "label": "Izu",
                "label_ar": "ايزو",
                "label_fr": "Izu"
            },
            {
                "value": 2904,
                "label": "Takashima",
                "label_ar": "تاكاشيما",
                "label_fr": "Takashima"
            },
            {
                "value": 3038,
                "label": "Abira",
                "label_ar": "عبيرا",
                "label_fr": "Abira"
            },
            {
                "value": 3519,
                "label": "Watari",
                "label_ar": "واتري",
                "label_fr": "Watari"
            },
            {
                "value": 2372,
                "label": "Minamiashigara",
                "label_ar": "ميناماشيجارا",
                "label_fr": "Minamiashigara"
            },
            {
                "value": 2656,
                "label": "Akashi",
                "label_ar": "اكاشي",
                "label_fr": "Akashi"
            },
            {
                "value": 3458,
                "label": "Mitsushimacho-osaki",
                "label_ar": "ميتسوشيماشو-أوساكي",
                "label_fr": "Mitsushimacho-osaki"
            },
            {
                "value": 2360,
                "label": "Obu",
                "label_ar": "أوبو",
                "label_fr": "Obu"
            },
            {
                "value": 2704,
                "label": "Hachiman-cho",
                "label_ar": "Hachiman-cho",
                "label_fr": "Hachiman-cho"
            },
            {
                "value": 2906,
                "label": "Higashiomi-shi",
                "label_ar": "هيغاشيومي شي",
                "label_fr": "Higashiomi-shi"
            },
            {
                "value": 2316,
                "label": "Nishinomiya",
                "label_ar": "نيشينوميا",
                "label_fr": "Nishinomiya"
            },
            {
                "value": 2420,
                "label": "Kawagoe",
                "label_ar": "Kawagoe",
                "label_fr": "Kawagoe"
            },
            {
                "value": 2814,
                "label": "Showa",
                "label_ar": "شوا",
                "label_fr": "Showa"
            },
            {
                "value": 3283,
                "label": "Shimo-iino",
                "label_ar": "شيمو إينو",
                "label_fr": "Shimo-iino"
            },
            {
                "value": 3089,
                "label": "Miyoshi",
                "label_ar": "ميوشي",
                "label_fr": "Miyoshi"
            },
            {
                "value": 3358,
                "label": "Nayoro",
                "label_ar": "نايورو",
                "label_fr": "Nayoro"
            },
            {
                "value": 2921,
                "label": "Kokubu-matsuki",
                "label_ar": "كوكوبو ماتسوكي",
                "label_fr": "Kokubu-matsuki"
            },
            {
                "value": 2736,
                "label": "Maebashi",
                "label_ar": "مايباشي",
                "label_fr": "Maebashi"
            },
            {
                "value": 3141,
                "label": "Ochiai",
                "label_ar": "أوشياي",
                "label_fr": "Ochiai"
            },
            {
                "value": 3125,
                "label": "Nihommatsu",
                "label_ar": "نيهوماتسو",
                "label_fr": "Nihommatsu"
            },
            {
                "value": 2825,
                "label": "Izumo",
                "label_ar": "ايزومو",
                "label_fr": "Izumo"
            },
            {
                "value": 3183,
                "label": "Toshima Mura",
                "label_ar": "توشيما مورا",
                "label_fr": "Toshima Mura"
            },
            {
                "value": 3518,
                "label": "Kasumicho",
                "label_ar": "كاسوميتشو",
                "label_fr": "Kasumicho"
            },
            {
                "value": 3250,
                "label": "Tashiro",
                "label_ar": "تاشيرو",
                "label_fr": "Tashiro"
            },
            {
                "value": 2688,
                "label": "Suginomecho",
                "label_ar": "سوجينوميكو",
                "label_fr": "Suginomecho"
            },
            {
                "value": 3193,
                "label": "Rifu",
                "label_ar": "ريفو",
                "label_fr": "Rifu"
            },
            {
                "value": 2342,
                "label": "Nara",
                "label_ar": "نارا",
                "label_fr": "Nara"
            },
            {
                "value": 3402,
                "label": "Fukuchi",
                "label_ar": "فوكوتشي",
                "label_fr": "Fukuchi"
            },
            {
                "value": 2356,
                "label": "Yamaguchi",
                "label_ar": "ياماغوتشي",
                "label_fr": "Yamaguchi"
            },
            {
                "value": 2546,
                "label": "Wako",
                "label_ar": "واكو",
                "label_fr": "Wako"
            },
            {
                "value": 3423,
                "label": "Oyama",
                "label_ar": "وياما",
                "label_fr": "Oyama"
            },
            {
                "value": 3298,
                "label": "Jissen",
                "label_ar": "جيسن",
                "label_fr": "Jissen"
            },
            {
                "value": 3075,
                "label": "Mihara",
                "label_ar": "ميهارا",
                "label_fr": "Mihara"
            },
            {
                "value": 3517,
                "label": "Hirakawa",
                "label_ar": "هيراكاوا",
                "label_fr": "Hirakawa"
            },
            {
                "value": 3349,
                "label": "Kakuda",
                "label_ar": "كاكودا",
                "label_fr": "Kakuda"
            },
            {
                "value": 2309,
                "label": "Ichihara",
                "label_ar": "إيشيهارا",
                "label_fr": "Ichihara"
            },
            {
                "value": 3011,
                "label": "Inashiki",
                "label_ar": "إيناشيكي",
                "label_fr": "Inashiki"
            },
            {
                "value": 3202,
                "label": "Okuma",
                "label_ar": "أوكوما",
                "label_fr": "Okuma"
            },
            {
                "value": 2675,
                "label": "Tatsunocho-tominaga",
                "label_ar": "Tatsunocho-tominaga",
                "label_fr": "Tatsunocho-tominaga"
            },
            {
                "value": 2536,
                "label": "Fujinomiya",
                "label_ar": "فوجينوميا",
                "label_fr": "Fujinomiya"
            },
            {
                "value": 3069,
                "label": "Gannosu",
                "label_ar": "جانوسو",
                "label_fr": "Gannosu"
            },
            {
                "value": 2784,
                "label": "Tenri",
                "label_ar": "تينري",
                "label_fr": "Tenri"
            },
            {
                "value": 2375,
                "label": "Kagoshima",
                "label_ar": "كاجوشيما",
                "label_fr": "Kagoshima"
            },
            {
                "value": 2447,
                "label": "Shirayamamachi",
                "label_ar": "شيراياماماتشي",
                "label_fr": "Shirayamamachi"
            },
            {
                "value": 3115,
                "label": "Kitami",
                "label_ar": "كيتامي",
                "label_fr": "Kitami"
            },
            {
                "value": 3104,
                "label": "Yamanashi",
                "label_ar": "ياماناشي",
                "label_fr": "Yamanashi"
            },
            {
                "value": 2514,
                "label": "Iida",
                "label_ar": "إيدا",
                "label_fr": "Iida"
            },
            {
                "value": 2752,
                "label": "Komoro",
                "label_ar": "كومورو",
                "label_fr": "Komoro"
            },
            {
                "value": 3350,
                "label": "Oiso",
                "label_ar": "أويسو",
                "label_fr": "Oiso"
            },
            {
                "value": 3106,
                "label": "Koriyama",
                "label_ar": "كورياما",
                "label_fr": "Koriyama"
            },
            {
                "value": 3019,
                "label": "Satte",
                "label_ar": "ساتيه",
                "label_fr": "Satte"
            },
            {
                "value": 2403,
                "label": "Sapporo",
                "label_ar": "سابورو",
                "label_fr": "Sapporo"
            },
            {
                "value": 2658,
                "label": "Miyazu",
                "label_ar": "ميازو",
                "label_fr": "Miyazu"
            },
            {
                "value": 2505,
                "label": "Tsubame",
                "label_ar": "تسوبام",
                "label_fr": "Tsubame"
            },
            {
                "value": 2306,
                "label": "Ichikawa",
                "label_ar": "إيتشيكاوا",
                "label_fr": "Ichikawa"
            },
            {
                "value": 2804,
                "label": "Suigetsu",
                "label_ar": "السقف يبدأ بالتحطم",
                "label_fr": "Suigetsu"
            },
            {
                "value": 3160,
                "label": "Yasuda",
                "label_ar": "ياسودا",
                "label_fr": "Yasuda"
            },
            {
                "value": 3515,
                "label": "Horinouchi",
                "label_ar": "هورينوشي",
                "label_fr": "Horinouchi"
            },
            {
                "value": 2345,
                "label": "Handa",
                "label_ar": "هاندا",
                "label_fr": "Handa"
            },
            {
                "value": 2392,
                "label": "Shiogama",
                "label_ar": "شيوجاما",
                "label_fr": "Shiogama"
            },
            {
                "value": 2493,
                "label": "Kokubunji",
                "label_ar": "كوكوبونجي",
                "label_fr": "Kokubunji"
            },
            {
                "value": 2399,
                "label": "Hino",
                "label_ar": "هينو",
                "label_fr": "Hino"
            },
            {
                "value": 2989,
                "label": "Sanmu",
                "label_ar": "سانمو",
                "label_fr": "Sanmu"
            },
            {
                "value": 3079,
                "label": "Otsuku-kibi",
                "label_ar": "أوتسكو كيبي",
                "label_fr": "Otsuku-kibi"
            },
            {
                "value": 3407,
                "label": "Senriyama",
                "label_ar": "سينرياما",
                "label_fr": "Senriyama"
            },
            {
                "value": 2789,
                "label": "Akasaka",
                "label_ar": "أكاساكا",
                "label_fr": "Akasaka"
            },
            {
                "value": 2938,
                "label": "Gotanda",
                "label_ar": "جوتاندا",
                "label_fr": "Gotanda"
            },
            {
                "value": 3260,
                "label": "Hikari",
                "label_ar": "هيكاري",
                "label_fr": "Hikari"
            },
            {
                "value": 2318,
                "label": "Kawasaki",
                "label_ar": "كاواساكي",
                "label_fr": "Kawasaki"
            },
            {
                "value": 2874,
                "label": "Imagami",
                "label_ar": "Imagami",
                "label_fr": "Imagami"
            },
            {
                "value": 2418,
                "label": "Soka",
                "label_ar": "سوكا",
                "label_fr": "Soka"
            },
            {
                "value": 3415,
                "label": "Morodomitsu",
                "label_ar": "مورودوميتسو",
                "label_fr": "Morodomitsu"
            },
            {
                "value": 2640,
                "label": "Midori",
                "label_ar": "ميدوري",
                "label_fr": "Midori"
            },
            {
                "value": 3384,
                "label": "Joshi",
                "label_ar": "جوشي",
                "label_fr": "Joshi"
            },
            {
                "value": 2390,
                "label": "Machida",
                "label_ar": "ماتشيدا",
                "label_fr": "Machida"
            },
            {
                "value": 2613,
                "label": "Koka",
                "label_ar": "كوكا",
                "label_fr": "Koka"
            },
            {
                "value": 3309,
                "label": "Koryocho-itazu",
                "label_ar": "كوريوتشو إيتازو",
                "label_fr": "Koryocho-itazu"
            },
            {
                "value": 3305,
                "label": "Mineyama",
                "label_ar": "مينياما",
                "label_fr": "Mineyama"
            },
            {
                "value": 2868,
                "label": "Ritto",
                "label_ar": "ريتو",
                "label_fr": "Ritto"
            },
            {
                "value": 3132,
                "label": "Gotanda",
                "label_ar": "جوتاندا",
                "label_fr": "Gotanda"
            },
            {
                "value": 2649,
                "label": "Honjo",
                "label_ar": "هونجو",
                "label_fr": "Honjo"
            },
            {
                "value": 2810,
                "label": "Sasebo",
                "label_ar": "ساسيبو",
                "label_fr": "Sasebo"
            },
            {
                "value": 2520,
                "label": "Shibata",
                "label_ar": "شيباتا",
                "label_fr": "Shibata"
            },
            {
                "value": 2737,
                "label": "Toyo",
                "label_ar": "تويو",
                "label_fr": "Toyo"
            },
            {
                "value": 2973,
                "label": "Omachi",
                "label_ar": "Omachi",
                "label_fr": "Omachi"
            },
            {
                "value": 3024,
                "label": "Goshogawara",
                "label_ar": "جوشوجاوارا",
                "label_fr": "Goshogawara"
            },
            {
                "value": 3066,
                "label": "Izunokuni",
                "label_ar": "Izunokuni",
                "label_fr": "Izunokuni"
            },
            {
                "value": 3007,
                "label": "Mizugama",
                "label_ar": "ميزوجاما",
                "label_fr": "Mizugama"
            },
            {
                "value": 3477,
                "label": "Aboshiku-okinohama",
                "label_ar": "Aboshiku-Okinohama",
                "label_fr": "Aboshiku-okinohama"
            },
            {
                "value": 3390,
                "label": "Konancho-fukawa",
                "label_ar": "كونانشو فوكاوا",
                "label_fr": "Konancho-fukawa"
            },
            {
                "value": 2469,
                "label": "Mihama",
                "label_ar": "ميهاما",
                "label_fr": "Mihama"
            },
            {
                "value": 2864,
                "label": "Kameoka",
                "label_ar": "كاميوكا",
                "label_fr": "Kameoka"
            },
            {
                "value": 2846,
                "label": "Tsubata",
                "label_ar": "تسوباتا",
                "label_fr": "Tsubata"
            },
            {
                "value": 3028,
                "label": "Keta",
                "label_ar": "كيتا",
                "label_fr": "Keta"
            },
            {
                "value": 3205,
                "label": "Taiki",
                "label_ar": "تايكي",
                "label_fr": "Taiki"
            },
            {
                "value": 2395,
                "label": "Utsunomiya",
                "label_ar": "أوتسونوميا",
                "label_fr": "Utsunomiya"
            },
            {
                "value": 2662,
                "label": "Yawata",
                "label_ar": "ياواتا",
                "label_fr": "Yawata"
            },
            {
                "value": 2768,
                "label": "Kitsuki",
                "label_ar": "كيتسوكي",
                "label_fr": "Kitsuki"
            },
            {
                "value": 3127,
                "label": "Abashiri",
                "label_ar": "أباشيري",
                "label_fr": "Abashiri"
            },
            {
                "value": 3482,
                "label": "Fuefuki-shi",
                "label_ar": "فويفوكي شي",
                "label_fr": "Fuefuki-shi"
            },
            {
                "value": 3460,
                "label": "Nakayama",
                "label_ar": "ناكاياما",
                "label_fr": "Nakayama"
            },
            {
                "value": 3503,
                "label": "Nakagusuku",
                "label_ar": "ناكاجوسوكو",
                "label_fr": "Nakagusuku"
            },
            {
                "value": 2353,
                "label": "Zushi",
                "label_ar": "زوشي",
                "label_fr": "Zushi"
            },
            {
                "value": 2495,
                "label": "Atsugi",
                "label_ar": "أتسوجي",
                "label_fr": "Atsugi"
            },
            {
                "value": 3462,
                "label": "Oyama",
                "label_ar": "وياما",
                "label_fr": "Oyama"
            },
            {
                "value": 3489,
                "label": "Yoshimi",
                "label_ar": "يوشيمي",
                "label_fr": "Yoshimi"
            },
            {
                "value": 2389,
                "label": "Tokorozawa",
                "label_ar": "توكوروزاوا",
                "label_fr": "Tokorozawa"
            },
            {
                "value": 2697,
                "label": "Nagano-shi",
                "label_ar": "ناغانو شي",
                "label_fr": "Nagano-shi"
            },
            {
                "value": 2818,
                "label": "Nakatsu",
                "label_ar": "ناكاتسو",
                "label_fr": "Nakatsu"
            },
            {
                "value": 3041,
                "label": "Yufutsu",
                "label_ar": "يوفوتسو",
                "label_fr": "Yufutsu"
            },
            {
                "value": 2805,
                "label": "Makuhari",
                "label_ar": "مكوهاري",
                "label_fr": "Makuhari"
            },
            {
                "value": 2454,
                "label": "Noda",
                "label_ar": "نودا",
                "label_fr": "Noda"
            },
            {
                "value": 2932,
                "label": "Takata",
                "label_ar": "تاكاتا",
                "label_fr": "Takata"
            },
            {
                "value": 2756,
                "label": "Kanuma",
                "label_ar": "كانوما",
                "label_fr": "Kanuma"
            },
            {
                "value": 2741,
                "label": "Tonami",
                "label_ar": "تونامي",
                "label_fr": "Tonami"
            },
            {
                "value": 2947,
                "label": "Imabari-shi",
                "label_ar": "Imabari شي",
                "label_fr": "Imabari-shi"
            },
            {
                "value": 2959,
                "label": "Taniyama-chuo",
                "label_ar": "تانياما تشو",
                "label_fr": "Taniyama-chuo"
            },
            {
                "value": 2948,
                "label": "Ikejiri",
                "label_ar": "إيكيجيري",
                "label_fr": "Ikejiri"
            },
            {
                "value": 3310,
                "label": "Seta",
                "label_ar": "سيتا",
                "label_fr": "Seta"
            },
            {
                "value": 2621,
                "label": "Tsuruga",
                "label_ar": "تسوروجا",
                "label_fr": "Tsuruga"
            },
            {
                "value": 2931,
                "label": "Senju",
                "label_ar": "سينجو",
                "label_fr": "Senju"
            },
            {
                "value": 3262,
                "label": "Omaezaki",
                "label_ar": "أومايزاكي",
                "label_fr": "Omaezaki"
            },
            {
                "value": 2463,
                "label": "Chino",
                "label_ar": "تشينو",
                "label_fr": "Chino"
            },
            {
                "value": 2822,
                "label": "Aikawa",
                "label_ar": "أيكاوا",
                "label_fr": "Aikawa"
            },
            {
                "value": 2987,
                "label": "Akiruno-shi",
                "label_ar": "أكيرونو شي",
                "label_fr": "Akiruno-shi"
            },
            {
                "value": 3442,
                "label": "Daigo",
                "label_ar": "دايجو",
                "label_fr": "Daigo"
            },
            {
                "value": 3333,
                "label": "Shonaicho",
                "label_ar": "شونايشو",
                "label_fr": "Shonaicho"
            },
            {
                "value": 2401,
                "label": "Tokushima",
                "label_ar": "توكوشيما",
                "label_fr": "Tokushima"
            },
            {
                "value": 2393,
                "label": "Shimizukoji",
                "label_ar": "شيميزوكوجي",
                "label_fr": "Shimizukoji"
            },
            {
                "value": 2564,
                "label": "Choshi",
                "label_ar": "تشوشي",
                "label_fr": "Choshi"
            },
            {
                "value": 3433,
                "label": "Yano",
                "label_ar": "يانو",
                "label_fr": "Yano"
            },
            {
                "value": 3436,
                "label": "Kichijoji-honcho",
                "label_ar": "Kichijoji-honcho",
                "label_fr": "Kichijoji-honcho"
            },
            {
                "value": 2617,
                "label": "Kashihara",
                "label_ar": "كاشيهارا",
                "label_fr": "Kashihara"
            },
            {
                "value": 2555,
                "label": "Kiryu",
                "label_ar": "كيريو",
                "label_fr": "Kiryu"
            },
            {
                "value": 2763,
                "label": "Asahimachi",
                "label_ar": "أساهيماشي",
                "label_fr": "Asahimachi"
            },
            {
                "value": 3244,
                "label": "Hiji",
                "label_ar": "هيجي",
                "label_fr": "Hiji"
            },
            {
                "value": 3344,
                "label": "Yamazaki",
                "label_ar": "يامازاكي",
                "label_fr": "Yamazaki"
            },
            {
                "value": 2679,
                "label": "Nikkō",
                "label_ar": "نيكو",
                "label_fr": "Nikkō"
            },
            {
                "value": 3446,
                "label": "Toge",
                "label_ar": "توج",
                "label_fr": "Toge"
            },
            {
                "value": 3083,
                "label": "Inabe",
                "label_ar": "إينابي",
                "label_fr": "Inabe"
            },
            {
                "value": 3374,
                "label": "Oyabe",
                "label_ar": "Oyabe",
                "label_fr": "Oyabe"
            },
            {
                "value": 2510,
                "label": "Shiki",
                "label_ar": "شيكي",
                "label_fr": "Shiki"
            },
            {
                "value": 2478,
                "label": "Chigasaki",
                "label_ar": "شيغاساكي",
                "label_fr": "Chigasaki"
            },
            {
                "value": 2750,
                "label": "Mino",
                "label_ar": "مينو",
                "label_fr": "Mino"
            },
            {
                "value": 2554,
                "label": "Hirakata",
                "label_ar": "حركاتا",
                "label_fr": "Hirakata"
            },
            {
                "value": 3111,
                "label": "Miyoshi",
                "label_ar": "ميوشي",
                "label_fr": "Miyoshi"
            },
            {
                "value": 2385,
                "label": "Inzai",
                "label_ar": "انزاي",
                "label_fr": "Inzai"
            },
            {
                "value": 2668,
                "label": "Hyogo",
                "label_ar": "هيوغو",
                "label_fr": "Hyogo"
            },
            {
                "value": 2806,
                "label": "Noguchi",
                "label_ar": "نوغوتشي",
                "label_fr": "Noguchi"
            },
            {
                "value": 2949,
                "label": "Kiyokawa",
                "label_ar": "كيوكاوا",
                "label_fr": "Kiyokawa"
            },
            {
                "value": 3359,
                "label": "Suijo",
                "label_ar": "Suijo",
                "label_fr": "Suijo"
            },
            {
                "value": 2740,
                "label": "Mobara",
                "label_ar": "Mobara",
                "label_fr": "Mobara"
            },
            {
                "value": 3009,
                "label": "Kano",
                "label_ar": "كانو",
                "label_fr": "Kano"
            },
            {
                "value": 3509,
                "label": "Nishikawa",
                "label_ar": "نيشيكاوا",
                "label_fr": "Nishikawa"
            },
            {
                "value": 2502,
                "label": "Sayama",
                "label_ar": "ساياما",
                "label_fr": "Sayama"
            },
            {
                "value": 3379,
                "label": "Kugayama",
                "label_ar": "كوجاياما",
                "label_fr": "Kugayama"
            },
            {
                "value": 2865,
                "label": "Miemachi",
                "label_ar": "ميماتشي",
                "label_fr": "Miemachi"
            },
            {
                "value": 2725,
                "label": "Tsu",
                "label_ar": "تسو",
                "label_fr": "Tsu"
            },
            {
                "value": 2980,
                "label": "Akishima",
                "label_ar": "أكيشيما",
                "label_fr": "Akishima"
            },
            {
                "value": 2918,
                "label": "Yoshii",
                "label_ar": "يوشي",
                "label_fr": "Yoshii"
            },
            {
                "value": 3074,
                "label": "Narutocho-mitsuishi",
                "label_ar": "ناروتوتشو ميتسوشي",
                "label_fr": "Narutocho-mitsuishi"
            },
            {
                "value": 3269,
                "label": "Senzoku",
                "label_ar": "سينزوكو",
                "label_fr": "Senzoku"
            },
            {
                "value": 3242,
                "label": "Wakkanai",
                "label_ar": "واكاناي",
                "label_fr": "Wakkanai"
            },
            {
                "value": 2544,
                "label": "Musashino",
                "label_ar": "موساشينو",
                "label_fr": "Musashino"
            },
            {
                "value": 3196,
                "label": "Monzenmachi-igisu",
                "label_ar": "Monzenmachi-igisu",
                "label_fr": "Monzenmachi-igisu"
            },
            {
                "value": 3490,
                "label": "Uonuma",
                "label_ar": "أونوما",
                "label_fr": "Uonuma"
            },
            {
                "value": 3251,
                "label": "Fumoto",
                "label_ar": "فوموتو",
                "label_fr": "Fumoto"
            },
            {
                "value": 3498,
                "label": "Iwakiri",
                "label_ar": "إيواكيري",
                "label_fr": "Iwakiri"
            },
            {
                "value": 2458,
                "label": "Gyoda",
                "label_ar": "جيودا",
                "label_fr": "Gyoda"
            },
            {
                "value": 2748,
                "label": "Kobe",
                "label_ar": "كوبي",
                "label_fr": "Kobe"
            },
            {
                "value": 2636,
                "label": "Annaka",
                "label_ar": "أناكا",
                "label_fr": "Annaka"
            },
            {
                "value": 2891,
                "label": "Minabe",
                "label_ar": "مينابي",
                "label_fr": "Minabe"
            },
            {
                "value": 2620,
                "label": "Kadoma",
                "label_ar": "كادوما",
                "label_fr": "Kadoma"
            },
            {
                "value": 2542,
                "label": "Matsuyama",
                "label_ar": "ماتسوياما",
                "label_fr": "Matsuyama"
            },
            {
                "value": 2735,
                "label": "Miyagi",
                "label_ar": "مياجي",
                "label_fr": "Miyagi"
            },
            {
                "value": 2657,
                "label": "Kasai",
                "label_ar": "كاساي",
                "label_fr": "Kasaï"
            },
            {
                "value": 3391,
                "label": "Kabe",
                "label_ar": "كاب",
                "label_fr": "Kabe"
            },
            {
                "value": 2388,
                "label": "Kamagaya-shi",
                "label_ar": "كاماغايا شي",
                "label_fr": "Kamagaya-shi"
            },
            {
                "value": 3249,
                "label": "Sugiyama",
                "label_ar": "سوجياما",
                "label_fr": "Sugiyama"
            },
            {
                "value": 2628,
                "label": "Masaki",
                "label_ar": "ماساكي",
                "label_fr": "Masaki"
            },
            {
                "value": 3000,
                "label": "Imizucho",
                "label_ar": "Imizucho",
                "label_fr": "Imizucho"
            },
            {
                "value": 3209,
                "label": "Komagane",
                "label_ar": "كوماجان",
                "label_fr": "Komagane"
            },
            {
                "value": 3351,
                "label": "Akashi",
                "label_ar": "اكاشي",
                "label_fr": "Akashi"
            },
            {
                "value": 3376,
                "label": "Toyoda",
                "label_ar": "تويودا",
                "label_fr": "Toyoda"
            },
            {
                "value": 3386,
                "label": "Onuma",
                "label_ar": "اونوما",
                "label_fr": "Onuma"
            },
            {
                "value": 2452,
                "label": "Toyohira-ku",
                "label_ar": "تويوهيرا كو",
                "label_fr": "Toyohira-ku"
            },
            {
                "value": 2506,
                "label": "Higashiboridori",
                "label_ar": "هيغاشيبوريدوري",
                "label_fr": "Higashiboridori"
            },
            {
                "value": 2482,
                "label": "Nobitome",
                "label_ar": "نوبيتومي",
                "label_fr": "Nobitome"
            },
            {
                "value": 3062,
                "label": "Yahata",
                "label_ar": "ياهاتا",
                "label_fr": "Yahata"
            },
            {
                "value": 3431,
                "label": "Okuchi",
                "label_ar": "أوكوتشي",
                "label_fr": "Okuchi"
            },
            {
                "value": 3299,
                "label": "Niitsu",
                "label_ar": "نيتسو",
                "label_fr": "Niitsu"
            },
            {
                "value": 3427,
                "label": "Yorishimacho",
                "label_ar": "يوريشيماشو",
                "label_fr": "Yorishimacho"
            },
            {
                "value": 2759,
                "label": "Natori-shi",
                "label_ar": "ناتوري شي",
                "label_fr": "Natori-shi"
            },
            {
                "value": 2431,
                "label": "Kashiwa",
                "label_ar": "كاشيوا",
                "label_fr": "Kashiwa"
            },
            {
                "value": 3221,
                "label": "Echizen",
                "label_ar": "إتشيزين",
                "label_fr": "Echizen"
            },
            {
                "value": 3495,
                "label": "Kutchan",
                "label_ar": "كوتشان",
                "label_fr": "Kutchan"
            },
            {
                "value": 2317,
                "label": "Den'en-chofu",
                "label_ar": "دينين تشوفو",
                "label_fr": "Den'en-chofu"
            },
            {
                "value": 2504,
                "label": "Ebina",
                "label_ar": "إبينا",
                "label_fr": "Ebina"
            },
            {
                "value": 3206,
                "label": "Omura",
                "label_ar": "أومورا",
                "label_fr": "Omura"
            },
            {
                "value": 3213,
                "label": "Totsukacho",
                "label_ar": "توتسوكاتشو",
                "label_fr": "Totsukacho"
            },
            {
                "value": 3287,
                "label": "Odaira",
                "label_ar": "عديرا",
                "label_fr": "Odaira"
            },
            {
                "value": 3455,
                "label": "Osawa",
                "label_ar": "أوساوا",
                "label_fr": "Osawa"
            },
            {
                "value": 2793,
                "label": "Daitocho",
                "label_ar": "دايتوتشو",
                "label_fr": "Daitocho"
            },
            {
                "value": 3085,
                "label": "Ogori-shimogo",
                "label_ar": "Ogori-shimogo",
                "label_fr": "Ogori-shimogo"
            },
            {
                "value": 2556,
                "label": "Nakatsugawa",
                "label_ar": "ناكاتسوجاوا",
                "label_fr": "Nakatsugawa"
            },
            {
                "value": 2850,
                "label": "Akeno",
                "label_ar": "أكينو",
                "label_fr": "Akeno"
            },
            {
                "value": 2878,
                "label": "Susami",
                "label_ar": "سوسامي",
                "label_fr": "Susami"
            },
            {
                "value": 2900,
                "label": "Inuyama",
                "label_ar": "إينوياما",
                "label_fr": "Inuyama"
            },
            {
                "value": 2985,
                "label": "Ome",
                "label_ar": "أوم",
                "label_fr": "Ome"
            },
            {
                "value": 3401,
                "label": "Sogabe-minamijo",
                "label_ar": "سوجابي ميناميجو",
                "label_fr": "Sogabe-minamijo"
            },
            {
                "value": 3292,
                "label": "Shin-shigai",
                "label_ar": "شين شيجاي",
                "label_fr": "Shin-shigai"
            },
            {
                "value": 2486,
                "label": "Hadano",
                "label_ar": "هادانو",
                "label_fr": "Hadano"
            },
            {
                "value": 3254,
                "label": "Kokufu",
                "label_ar": "كوكوفو",
                "label_fr": "Kokufu"
            },
            {
                "value": 3194,
                "label": "Ichinomiyacho",
                "label_ar": "ايتشينومياتشو",
                "label_fr": "Ichinomiyacho"
            },
            {
                "value": 2808,
                "label": "Omagari",
                "label_ar": "أوماجاري",
                "label_fr": "Omagari"
            },
            {
                "value": 3023,
                "label": "Kaga",
                "label_ar": "كاجا",
                "label_fr": "Kaga"
            },
            {
                "value": 3134,
                "label": "Isomura",
                "label_ar": "ايسومورا",
                "label_fr": "Isomura"
            },
            {
                "value": 3126,
                "label": "Meiji",
                "label_ar": "ميجي",
                "label_fr": "Meiji"
            },
            {
                "value": 3131,
                "label": "Obari",
                "label_ar": "أوباري",
                "label_fr": "Obari"
            },
            {
                "value": 3017,
                "label": "Mihara",
                "label_ar": "ميهارا",
                "label_fr": "Mihara"
            },
            {
                "value": 3081,
                "label": "Ama-higashimachi",
                "label_ar": "أما-هيجاشيماشي",
                "label_fr": "Ama-higashimachi"
            },
            {
                "value": 2992,
                "label": "Iruma",
                "label_ar": "ايروما",
                "label_fr": "Iruma"
            },
            {
                "value": 3476,
                "label": "Kamoi",
                "label_ar": "كاموي",
                "label_fr": "Kamoi"
            },
            {
                "value": 3396,
                "label": "Isawa",
                "label_ar": "رأيت",
                "label_fr": "J'ai vu un"
            },
            {
                "value": 2857,
                "label": "Agano",
                "label_ar": "اجانو",
                "label_fr": "Agano"
            },
            {
                "value": 3159,
                "label": "Yanocho-futatsugi",
                "label_ar": "Yanocho-futatsugi",
                "label_fr": "Yanocho-futatsugi"
            },
            {
                "value": 3054,
                "label": "Uwajima",
                "label_ar": "اواجيما",
                "label_fr": "Uwajima"
            },
            {
                "value": 2425,
                "label": "Yorii",
                "label_ar": "يوري",
                "label_fr": "Yorii"
            },
            {
                "value": 3257,
                "label": "Nagato",
                "label_ar": "ناجاتو",
                "label_fr": "Nagato"
            },
            {
                "value": 2476,
                "label": "Toride",
                "label_ar": "ركوب",
                "label_fr": "Conduire"
            },
            {
                "value": 2571,
                "label": "Naha",
                "label_ar": "ناها",
                "label_fr": "Naha"
            },
            {
                "value": 3138,
                "label": "Wakicho",
                "label_ar": "واكيشو",
                "label_fr": "Wakicho"
            },
            {
                "value": 3478,
                "label": "Sango",
                "label_ar": "سانجو",
                "label_fr": "Sango"
            },
            {
                "value": 3135,
                "label": "Tamashima",
                "label_ar": "تاماشيما",
                "label_fr": "Tamashima"
            },
            {
                "value": 3428,
                "label": "Minatomachi",
                "label_ar": "ميناتوماتشي",
                "label_fr": "Minatomachi"
            },
            {
                "value": 2897,
                "label": "Kochi-shi",
                "label_ar": "كوشي شي",
                "label_fr": "Kochi-shi"
            },
            {
                "value": 2788,
                "label": "Kashiba",
                "label_ar": "كشيبا",
                "label_fr": "Kashiba"
            },
            {
                "value": 2996,
                "label": "Nakama",
                "label_ar": "ناكاما",
                "label_fr": "Nakama"
            },
            {
                "value": 3226,
                "label": "Nakanoshima",
                "label_ar": "ناكانوشيما",
                "label_fr": "Nakanoshima"
            },
            {
                "value": 3256,
                "label": "Nakano-kami",
                "label_ar": "ناكانو كامي",
                "label_fr": "Nakano-kami"
            },
            {
                "value": 3048,
                "label": "Iwatsuki",
                "label_ar": "إيواتسوكي",
                "label_fr": "Iwatsuki"
            },
            {
                "value": 3026,
                "label": "Izumiotsu",
                "label_ar": "ايزوميوتسو",
                "label_fr": "Izumiotsu"
            },
            {
                "value": 3394,
                "label": "Hongo",
                "label_ar": "هونجو",
                "label_fr": "Hongo"
            },
            {
                "value": 3417,
                "label": "Hosobara",
                "label_ar": "هوسوبارا",
                "label_fr": "Hosobara"
            },
            {
                "value": 2300,
                "label": "Nihon'odori",
                "label_ar": "نيهونودوري",
                "label_fr": "Nihon'odori"
            },
            {
                "value": 2398,
                "label": "Matsudo",
                "label_ar": "ماتسودو",
                "label_fr": "Matsudo"
            },
            {
                "value": 2498,
                "label": "Kasama",
                "label_ar": "كسامة",
                "label_fr": "Kasama"
            },
            {
                "value": 2831,
                "label": "Akao",
                "label_ar": "أكاو",
                "label_fr": "Akao"
            },
            {
                "value": 2800,
                "label": "Kyoritsu",
                "label_ar": "كيوريتسو",
                "label_fr": "Kyoritsu"
            },
            {
                "value": 3148,
                "label": "Mizushima-nishichidoricho",
                "label_ar": "ميزوشيما نيشيتشيدوريشو",
                "label_fr": "Mizushima-nishichidoricho"
            },
            {
                "value": 3053,
                "label": "Shitama",
                "label_ar": "شيتاما",
                "label_fr": "Shitama"
            },
            {
                "value": 2525,
                "label": "Nakagawa",
                "label_ar": "ناكاجاوا",
                "label_fr": "Nakagawa"
            },
            {
                "value": 3380,
                "label": "Arakawa",
                "label_ar": "أراكاوا",
                "label_fr": "Arakawa"
            },
            {
                "value": 3218,
                "label": "Miwa",
                "label_ar": "ميوا",
                "label_fr": "Miwa"
            },
            {
                "value": 3334,
                "label": "Komatsushimacho",
                "label_ar": "كوماتسوشيماشو",
                "label_fr": "Komatsushimacho"
            },
            {
                "value": 3093,
                "label": "Miyako",
                "label_ar": "مياكو",
                "label_fr": "Miyako"
            },
            {
                "value": 2879,
                "label": "Kushimoto",
                "label_ar": "كوشيموتو",
                "label_fr": "Kushimoto"
            },
            {
                "value": 2925,
                "label": "Ichinogo",
                "label_ar": "إيتشينوغو",
                "label_fr": "Ichinogo"
            },
            {
                "value": 3046,
                "label": "Murakami",
                "label_ar": "موراكامي",
                "label_fr": "Murakami"
            },
            {
                "value": 3524,
                "label": "Shiba-Kaigandori",
                "label_ar": "شيبا كايجاندوري",
                "label_fr": "Shiba-Kaigandori"
            },
            {
                "value": 3342,
                "label": "Nemuro",
                "label_ar": "نيمورو",
                "label_fr": "Nemuro"
            },
            {
                "value": 2402,
                "label": "Morowa",
                "label_ar": "مورووا",
                "label_fr": "Morowa"
            },
            {
                "value": 2901,
                "label": "Inami",
                "label_ar": "إينامي",
                "label_fr": "Inami"
            },
            {
                "value": 3286,
                "label": "Murayama",
                "label_ar": "موراياما",
                "label_fr": "Murayama"
            },
            {
                "value": 2364,
                "label": "Inazawa",
                "label_ar": "إينازاوا",
                "label_fr": "Inazawa"
            },
            {
                "value": 2432,
                "label": "Fuchu-machi",
                "label_ar": "فوشو ماتشي",
                "label_fr": "Fuchu-machi"
            },
            {
                "value": 2832,
                "label": "Hokuto-shi",
                "label_ar": "هوكوتو شي",
                "label_fr": "Hokuto-shi"
            },
            {
                "value": 2635,
                "label": "Ishikawa",
                "label_ar": "إيشيكاوا",
                "label_fr": "Ishikawa"
            },
            {
                "value": 3513,
                "label": "Takajo",
                "label_ar": "تاكاجو",
                "label_fr": "Takajo"
            },
            {
                "value": 2490,
                "label": "Kodaira",
                "label_ar": "كوديرا",
                "label_fr": "Kodaira"
            },
            {
                "value": 2682,
                "label": "Kushiro",
                "label_ar": "كوشيرو",
                "label_fr": "Kushiro"
            },
            {
                "value": 2332,
                "label": "Chiba",
                "label_ar": "شيبا",
                "label_fr": "Chiba"
            },
            {
                "value": 2812,
                "label": "Yashima",
                "label_ar": "ياشيما",
                "label_fr": "Yashima"
            },
            {
                "value": 3410,
                "label": "Shima",
                "label_ar": "شيما",
                "label_fr": "Shima"
            },
            {
                "value": 3169,
                "label": "Kamisu",
                "label_ar": "كاميسو",
                "label_fr": "Kamisu"
            },
            {
                "value": 3411,
                "label": "Kokuryo",
                "label_ar": "كوكوريو",
                "label_fr": "Kokuryo"
            },
            {
                "value": 3485,
                "label": "Ogi",
                "label_ar": "أوجي",
                "label_fr": "Ogi"
            },
            {
                "value": 3385,
                "label": "Kamaishi",
                "label_ar": "كامايشي",
                "label_fr": "Kamaishi"
            },
            {
                "value": 2604,
                "label": "Iwamizawa",
                "label_ar": "إيواميزاوا",
                "label_fr": "Iwamizawa"
            },
            {
                "value": 2660,
                "label": "Nishiwaki",
                "label_ar": "نيشيواكي",
                "label_fr": "Nishiwaki"
            },
            {
                "value": 3284,
                "label": "Machi",
                "label_ar": "ماشي",
                "label_fr": "Machi"
            },
            {
                "value": 2468,
                "label": "Gamagori",
                "label_ar": "جاماغوري",
                "label_fr": "Gamagori"
            },
            {
                "value": 3068,
                "label": "Kitakata",
                "label_ar": "كيتاكاتا",
                "label_fr": "Kitakata"
            },
            {
                "value": 2877,
                "label": "Tanabe",
                "label_ar": "تانابي",
                "label_fr": "Tanabe"
            },
            {
                "value": 3121,
                "label": "Tsuyama",
                "label_ar": "تسوياما",
                "label_fr": "Tsuyama"
            },
            {
                "value": 2974,
                "label": "Itoi",
                "label_ar": "إيتوي",
                "label_fr": "Itoi"
            },
            {
                "value": 2489,
                "label": "Ushiku",
                "label_ar": "أوشيكو",
                "label_fr": "Ushiku"
            },
            {
                "value": 2674,
                "label": "Fukuchiyama",
                "label_ar": "فوكوتشياما",
                "label_fr": "Fukuchiyama"
            },
            {
                "value": 2684,
                "label": "Kasumicho",
                "label_ar": "كاسوميتشو",
                "label_fr": "Kasumicho"
            },
            {
                "value": 2966,
                "label": "Kashima-shi",
                "label_ar": "كاشيما شي",
                "label_fr": "Kashima-shi"
            },
            {
                "value": 3039,
                "label": "Togane",
                "label_ar": "توجان",
                "label_fr": "Togane"
            },
            {
                "value": 2501,
                "label": "Kanagawa",
                "label_ar": "كاناغاوا",
                "label_fr": "Kanagawa"
            },
            {
                "value": 2672,
                "label": "Hannan",
                "label_ar": "حنان",
                "label_fr": "Hannan"
            },
            {
                "value": 2670,
                "label": "Kainan",
                "label_ar": "كاينان",
                "label_fr": "Kainan"
            },
            {
                "value": 2892,
                "label": "Ise",
                "label_ar": "ايس",
                "label_fr": "Ise"
            },
            {
                "value": 2777,
                "label": "Tone",
                "label_ar": "نغمة، رنه",
                "label_fr": "Ton"
            },
            {
                "value": 2512,
                "label": "Nago",
                "label_ar": "ناجو",
                "label_fr": "Nago"
            },
            {
                "value": 2577,
                "label": "Higashine",
                "label_ar": "هيجاشين",
                "label_fr": "Higashine"
            },
            {
                "value": 3072,
                "label": "Ibara",
                "label_ar": "إيبارا",
                "label_fr": "Ibara"
            },
            {
                "value": 2480,
                "label": "Kimitsu",
                "label_ar": "كيميتسو",
                "label_fr": "Kimitsu"
            },
            {
                "value": 3443,
                "label": "Kanomata",
                "label_ar": "كانوماتا",
                "label_fr": "Kanomata"
            },
            {
                "value": 2412,
                "label": "Akita",
                "label_ar": "اكيتا",
                "label_fr": "Akita"
            },
            {
                "value": 2781,
                "label": "Nanae",
                "label_ar": "نانا",
                "label_fr": "Nanae"
            },
            {
                "value": 3175,
                "label": "Matsuyama",
                "label_ar": "ماتسوياما",
                "label_fr": "Matsuyama"
            },
            {
                "value": 3005,
                "label": "Ishikari",
                "label_ar": "إيشكاري",
                "label_fr": "Ishikari"
            },
            {
                "value": 3348,
                "label": "Hamada",
                "label_ar": "حمادة",
                "label_fr": "Hamada"
            },
            {
                "value": 2715,
                "label": "Takamatsu",
                "label_ar": "تاكاماتسو",
                "label_fr": "Takamatsu"
            },
            {
                "value": 3021,
                "label": "Matsukawamachi",
                "label_ar": "ماتسوكاواماتشي",
                "label_fr": "Matsukawamachi"
            },
            {
                "value": 3033,
                "label": "Nagawado",
                "label_ar": "ناجوادو",
                "label_fr": "Nagawado"
            },
            {
                "value": 3082,
                "label": "owariasahi",
                "label_ar": "owariasahi",
                "label_fr": "owariasahi"
            },
            {
                "value": 2680,
                "label": "Sakura",
                "label_ar": "ساكورا",
                "label_fr": "Sakura"
            },
            {
                "value": 2590,
                "label": "Komaki",
                "label_ar": "كوماكي",
                "label_fr": "Komaki"
            },
            {
                "value": 2774,
                "label": "Shimonoseki",
                "label_ar": "شيمونوسيكي",
                "label_fr": "Shimonoseki"
            },
            {
                "value": 2713,
                "label": "Date",
                "label_ar": "تاريخ",
                "label_fr": "Date"
            },
            {
                "value": 2886,
                "label": "Raiba",
                "label_ar": "رايبة",
                "label_fr": "Raiba"
            },
            {
                "value": 2381,
                "label": "Amagasaki",
                "label_ar": "أماغازاكي",
                "label_fr": "Amagasaki"
            },
            {
                "value": 2711,
                "label": "Hashimoto",
                "label_ar": "هاشيموتو",
                "label_fr": "Hashimoto"
            },
            {
                "value": 3195,
                "label": "Uki",
                "label_ar": "أوكي",
                "label_fr": "Uki"
            },
            {
                "value": 3395,
                "label": "Shimogo",
                "label_ar": "شيموجو",
                "label_fr": "Shimogo"
            },
            {
                "value": 3312,
                "label": "Sakurajima-koikecho",
                "label_ar": "ساكوراجيما كويكيتشو",
                "label_fr": "Sakurajima-koikecho"
            },
            {
                "value": 2543,
                "label": "Koganei",
                "label_ar": "كوجاني",
                "label_fr": "Koganei"
            },
            {
                "value": 2853,
                "label": "Nagahama",
                "label_ar": "النغامة",
                "label_fr": "Nagahama"
            },
            {
                "value": 2411,
                "label": "Okayama",
                "label_ar": "أوكاياما",
                "label_fr": "Okayama"
            },
            {
                "value": 3382,
                "label": "Momoishi",
                "label_ar": "مومويشي",
                "label_fr": "Momoishi"
            },
            {
                "value": 2884,
                "label": "Kokawa",
                "label_ar": "كوكاوا",
                "label_fr": "Kokawa"
            },
            {
                "value": 3232,
                "label": "Sodegaura",
                "label_ar": "سوديجورا",
                "label_fr": "Sodegaura"
            },
            {
                "value": 3236,
                "label": "Haebaru",
                "label_ar": "هايبارو",
                "label_fr": "Haebaru"
            },
            {
                "value": 3522,
                "label": "Bakurocho",
                "label_ar": "باكوروشو",
                "label_fr": "Bakurocho"
            },
            {
                "value": 2988,
                "label": "Toyoake",
                "label_ar": "تويواك",
                "label_fr": "Toyoake"
            },
            {
                "value": 2424,
                "label": "Ageo",
                "label_ar": "أجيو",
                "label_fr": "Ageo"
            },
            {
                "value": 2673,
                "label": "Sennan",
                "label_ar": "سنان",
                "label_fr": "Sennan"
            },
            {
                "value": 2552,
                "label": "Nishikicho",
                "label_ar": "نيشيكيشو",
                "label_fr": "Nishikicho"
            },
            {
                "value": 3259,
                "label": "Takamori",
                "label_ar": "تاكاموري",
                "label_fr": "Takamori"
            },
            {
                "value": 2456,
                "label": "Abiko",
                "label_ar": "أبيكو",
                "label_fr": "Abiko"
            },
            {
                "value": 2359,
                "label": "Tahara",
                "label_ar": "طهارة",
                "label_fr": "Tahara"
            },
            {
                "value": 2588,
                "label": "Kuwana-shi",
                "label_ar": "كوانا شي",
                "label_fr": "Kuwana-shi"
            },
            {
                "value": 2558,
                "label": "Beppu",
                "label_ar": "بيبو",
                "label_fr": "Beppu"
            },
            {
                "value": 3099,
                "label": "Habikino",
                "label_ar": "هابكينو",
                "label_fr": "Habikino"
            },
            {
                "value": 2815,
                "label": "Takedamachi",
                "label_ar": "تاكيداماتشي",
                "label_fr": "Takedamachi"
            },
            {
                "value": 2979,
                "label": "Mooka",
                "label_ar": "موكا",
                "label_fr": "Mooka"
            },
            {
                "value": 3454,
                "label": "Nagao",
                "label_ar": "ناجاو",
                "label_fr": "Nagao"
            },
            {
                "value": 3387,
                "label": "Yamadayu",
                "label_ar": "يامادايو",
                "label_fr": "Yamadayu"
            },
            {
                "value": 2339,
                "label": "Kawanishi",
                "label_ar": "Kawanishi",
                "label_fr": "Kawanishi"
            },
            {
                "value": 2326,
                "label": "Shimotoda",
                "label_ar": "شيموتودا",
                "label_fr": "Shimotoda"
            },
            {
                "value": 2419,
                "label": "Niiza",
                "label_ar": "نييزا",
                "label_fr": "Niiza"
            },
            {
                "value": 2813,
                "label": "Kyowa",
                "label_ar": "كيووا",
                "label_fr": "Kyowa"
            },
            {
                "value": 3118,
                "label": "Itako",
                "label_ar": "إيتاكو",
                "label_fr": "Itako"
            },
            {
                "value": 3103,
                "label": "Yuzawa",
                "label_ar": "يوزاوا",
                "label_fr": "Yuzawa"
            },
            {
                "value": 2574,
                "label": "Kita-sannomaru",
                "label_ar": "كيتا سانومارو",
                "label_fr": "Kita-sannomaru"
            },
            {
                "value": 3271,
                "label": "Fushiki-minatomachi",
                "label_ar": "فوشيكي ميناتوماتشي",
                "label_fr": "Fushiki-minatomachi"
            },
            {
                "value": 3345,
                "label": "Kamo",
                "label_ar": "كامو",
                "label_fr": "Kamo"
            },
            {
                "value": 2583,
                "label": "Higashiasahimachi",
                "label_ar": "هيجاشيساهيماشي",
                "label_fr": "Higashiasahimachi"
            },
            {
                "value": 2767,
                "label": "Shiroishi",
                "label_ar": "شيروشي",
                "label_fr": "Shiroishi"
            },
            {
                "value": 3278,
                "label": "Yamano",
                "label_ar": "يامانو",
                "label_fr": "Yamano"
            },
            {
                "value": 3097,
                "label": "Ryuo",
                "label_ar": "ريوو",
                "label_fr": "Ryuo"
            },
            {
                "value": 2550,
                "label": "Otawara",
                "label_ar": "أوتاوارا",
                "label_fr": "Otawara"
            },
            {
                "value": 3063,
                "label": "Sayo",
                "label_ar": "سايو",
                "label_fr": "Sayo"
            },
            {
                "value": 2386,
                "label": "Sakai",
                "label_ar": "ساكاي",
                "label_fr": "Sakai"
            },
            {
                "value": 2563,
                "label": "Kitahiroshima",
                "label_ar": "كيتاهيروشيما",
                "label_fr": "Kitahiroshima"
            },
            {
                "value": 2951,
                "label": "Okubo",
                "label_ar": "أوكوبو",
                "label_fr": "Okubo"
            },
            {
                "value": 2319,
                "label": "Toyama-shi",
                "label_ar": "توياما شي",
                "label_fr": "Toyama-shi"
            },
            {
                "value": 3372,
                "label": "Takase",
                "label_ar": "تاكاسي",
                "label_fr": "Takase"
            },
            {
                "value": 2724,
                "label": "Kanaya",
                "label_ar": "كانايا",
                "label_fr": "Kanaya"
            },
            {
                "value": 2941,
                "label": "Meguro",
                "label_ar": "ميغورو",
                "label_fr": "Meguro"
            },
            {
                "value": 2905,
                "label": "Kusatsu",
                "label_ar": "كوساتسو",
                "label_fr": "Kusatsu"
            },
            {
                "value": 3500,
                "label": "Nakijin",
                "label_ar": "ناكيجين",
                "label_fr": "Nakijin"
            },
            {
                "value": 2335,
                "label": "Ashihara",
                "label_ar": "أشيهارا",
                "label_fr": "Ashihara"
            },
            {
                "value": 2448,
                "label": "Hakui",
                "label_ar": "هاكي",
                "label_fr": "Hakui"
            },
            {
                "value": 2537,
                "label": "Yaizu",
                "label_ar": "ييزو",
                "label_fr": "Yaizu"
            },
            {
                "value": 2964,
                "label": "Hiki",
                "label_ar": "هيكي",
                "label_fr": "Hiki"
            },
            {
                "value": 3315,
                "label": "Nishime",
                "label_ar": "نيشيم",
                "label_fr": "Nishime"
            },
            {
                "value": 3297,
                "label": "Aitsu",
                "label_ar": "ايتسو",
                "label_fr": "Aitsu"
            },
            {
                "value": 2624,
                "label": "Toyota",
                "label_ar": "تويوتا",
                "label_fr": "Toyota"
            },
            {
                "value": 2686,
                "label": "Kurihashi",
                "label_ar": "كوريهاشي",
                "label_fr": "Kurihashi"
            },
            {
                "value": 2612,
                "label": "Sasayama",
                "label_ar": "ساساياما",
                "label_fr": "Sasayama"
            },
            {
                "value": 2677,
                "label": "Tama",
                "label_ar": "تاما",
                "label_fr": "Tama"
            },
            {
                "value": 3424,
                "label": "Tsukawaki",
                "label_ar": "تسوكاواكي",
                "label_fr": "Tsukawaki"
            },
            {
                "value": 3084,
                "label": "Okawa",
                "label_ar": "أوكاوا",
                "label_fr": "Okawa"
            },
            {
                "value": 2968,
                "label": "Echizen",
                "label_ar": "إتشيزين",
                "label_fr": "Echizen"
            },
            {
                "value": 3014,
                "label": "Tobe",
                "label_ar": "أن تكون",
                "label_fr": "Être"
            },
            {
                "value": 2570,
                "label": "Urasoe",
                "label_ar": "أوراسوي",
                "label_fr": "Urasoe"
            },
            {
                "value": 3124,
                "label": "Eniwa-shi",
                "label_ar": "اينيوا شي",
                "label_fr": "Eniwa-shi"
            },
            {
                "value": 3098,
                "label": "Sayama",
                "label_ar": "ساياما",
                "label_fr": "Sayama"
            },
            {
                "value": 3497,
                "label": "Yanai",
                "label_ar": "ياناي",
                "label_fr": "Yanai"
            },
            {
                "value": 3437,
                "label": "Takicho-oda",
                "label_ar": "تاكيشو اودا",
                "label_fr": "Takicho-oda"
            },
            {
                "value": 3056,
                "label": "Kani",
                "label_ar": "كاني",
                "label_fr": "Kani"
            },
            {
                "value": 3499,
                "label": "Motegi",
                "label_ar": "موتيجي",
                "label_fr": "Motegi"
            },
            {
                "value": 3356,
                "label": "Hotei",
                "label_ar": "حوتي",
                "label_fr": "Hotei"
            },
            {
                "value": 3338,
                "label": "Tatsumi",
                "label_ar": "تاتسومي",
                "label_fr": "Tatsumi"
            },
            {
                "value": 3508,
                "label": "Aki",
                "label_ar": "آكي",
                "label_fr": "Aki"
            },
            {
                "value": 2728,
                "label": "Ginowan",
                "label_ar": "جينوان",
                "label_fr": "Ginowan"
            },
            {
                "value": 2990,
                "label": "Heiwajima",
                "label_ar": "هيواجيما",
                "label_fr": "Heiwajima"
            },
            {
                "value": 2343,
                "label": "Yao",
                "label_ar": "ياو",
                "label_fr": "Yao"
            },
            {
                "value": 2994,
                "label": "Kamitosa",
                "label_ar": "كاميتوسا",
                "label_fr": "Kamitosa"
            },
            {
                "value": 3110,
                "label": "Joetsu",
                "label_ar": "جويتسو",
                "label_fr": "Joetsu"
            },
            {
                "value": 3237,
                "label": "Kurayoshi",
                "label_ar": "كورايوشي",
                "label_fr": "Kurayoshi"
            },
            {
                "value": 2305,
                "label": "Nagareyama",
                "label_ar": "ناجارياما",
                "label_fr": "Nagareyama"
            },
            {
                "value": 2483,
                "label": "Sumidacho",
                "label_ar": "سوميداشو",
                "label_fr": "Sumidacho"
            },
            {
                "value": 2983,
                "label": "Nobeoka",
                "label_ar": "نوبيوكا",
                "label_fr": "Nobeoka"
            },
            {
                "value": 3326,
                "label": "Imabuku",
                "label_ar": "إيمابوكو",
                "label_fr": "Imabuku"
            },
            {
                "value": 2611,
                "label": "Ikeda",
                "label_ar": "إيكيدا",
                "label_fr": "Ikeda"
            },
            {
                "value": 3347,
                "label": "Urayama",
                "label_ar": "أوراياما",
                "label_fr": "Urayama"
            },
            {
                "value": 2912,
                "label": "Roppongi",
                "label_ar": "روبونجي",
                "label_fr": "Roppongi"
            },
            {
                "value": 2421,
                "label": "Shichibancho",
                "label_ar": "شيتشيبانشو",
                "label_fr": "Shichibancho"
            },
            {
                "value": 3398,
                "label": "Minamiuonuma",
                "label_ar": "ميناميونوما",
                "label_fr": "Minamiuonuma"
            },
            {
                "value": 2961,
                "label": "Minamiaoyama",
                "label_ar": "مينامياوياما",
                "label_fr": "Minamiaoyama"
            },
            {
                "value": 2984,
                "label": "Chitose",
                "label_ar": "شيتوز",
                "label_fr": "Chitose"
            },
            {
                "value": 2408,
                "label": "Ashikaga",
                "label_ar": "أشيكاغا",
                "label_fr": "Ashikaga"
            },
            {
                "value": 3378,
                "label": "Yuki",
                "label_ar": "يوكي",
                "label_fr": "Yuki"
            },
            {
                "value": 3277,
                "label": "Ariake",
                "label_ar": "أرياك",
                "label_fr": "Ariake"
            },
            {
                "value": 2355,
                "label": "Yabasehoncho",
                "label_ar": "ياباسيونشو",
                "label_fr": "Yabasehoncho"
            },
            {
                "value": 2601,
                "label": "Tobata",
                "label_ar": "توباتا",
                "label_fr": "Tobata"
            },
            {
                "value": 2581,
                "label": "Sannohe",
                "label_ar": "سانوهي",
                "label_fr": "Sannohe"
            },
            {
                "value": 2379,
                "label": "Kitakyushu",
                "label_ar": "كيتاكيوشو",
                "label_fr": "Kitakyushu"
            },
            {
                "value": 3360,
                "label": "Sone",
                "label_ar": "وحدها",
                "label_fr": "Sone"
            },
            {
                "value": 2623,
                "label": "Nagaoka",
                "label_ar": "ناجاوكا",
                "label_fr": "Nagaoka"
            },
            {
                "value": 3357,
                "label": "Kochino",
                "label_ar": "كوتشينو",
                "label_fr": "Kochino"
            },
            {
                "value": 2367,
                "label": "Okazaki",
                "label_ar": "أوكازاكي",
                "label_fr": "Okazaki"
            },
            {
                "value": 2578,
                "label": "Tsuruoka",
                "label_ar": "تسوروكا",
                "label_fr": "Tsuruoka"
            },
            {
                "value": 2310,
                "label": "Koshigaya",
                "label_ar": "كوشيجايا",
                "label_fr": "Koshigaya"
            },
            {
                "value": 2950,
                "label": "Komagawa",
                "label_ar": "كوماغاوا",
                "label_fr": "Komagawa"
            },
            {
                "value": 3058,
                "label": "Kanzaki",
                "label_ar": "كانزاكي",
                "label_fr": "Kanzaki"
            },
            {
                "value": 2790,
                "label": "Yoshino",
                "label_ar": "يوشينو",
                "label_fr": "Yoshino"
            },
            {
                "value": 3061,
                "label": "Yukuhashi",
                "label_ar": "يوكوهاشي",
                "label_fr": "Yukuhashi"
            },
            {
                "value": 2928,
                "label": "Higashiiya-takano",
                "label_ar": "هيغاشييا تاكانو",
                "label_fr": "Higashiiya-takano"
            },
            {
                "value": 3145,
                "label": "Yuge",
                "label_ar": "يوجي",
                "label_fr": "Yuge"
            },
            {
                "value": 2923,
                "label": "Shimomeguro",
                "label_ar": "شيموميجورو",
                "label_fr": "Shimomeguro"
            },
            {
                "value": 2437,
                "label": "Shinshuku",
                "label_ar": "شينشوكو",
                "label_fr": "Shinshuku"
            },
            {
                "value": 2547,
                "label": "Miura",
                "label_ar": "ميورا",
                "label_fr": "Miura"
            },
            {
                "value": 2792,
                "label": "Hatogaya-honcho",
                "label_ar": "هاتوجايا هونشو",
                "label_fr": "Hatogaya-honcho"
            },
            {
                "value": 2766,
                "label": "Kashima",
                "label_ar": "كاشيما",
                "label_fr": "Kashima"
            },
            {
                "value": 2889,
                "label": "Gobo",
                "label_ar": "جوبو",
                "label_fr": "Gobo"
            },
            {
                "value": 3425,
                "label": "Okawachi",
                "label_ar": "أوكواشي",
                "label_fr": "Okawachi"
            },
            {
                "value": 3263,
                "label": "Osaki",
                "label_ar": "أوساكي",
                "label_fr": "Osaki"
            },
            {
                "value": 3494,
                "label": "Iwanai",
                "label_ar": "إيواناي",
                "label_fr": "Iwanai"
            },
            {
                "value": 2722,
                "label": "Koyasan",
                "label_ar": "كوياسان",
                "label_fr": "Koyasan"
            },
            {
                "value": 2409,
                "label": "Matsuyama",
                "label_ar": "ماتسوياما",
                "label_fr": "Matsuyama"
            },
            {
                "value": 2363,
                "label": "Kasugai",
                "label_ar": "كاسوجاي",
                "label_fr": "Kasugai"
            },
            {
                "value": 2664,
                "label": "Ono",
                "label_ar": "أونو",
                "label_fr": "Ono"
            },
            {
                "value": 2633,
                "label": "Namerikawa",
                "label_ar": "ناميريكاوا",
                "label_fr": "Namerikawa"
            },
            {
                "value": 3013,
                "label": "Yomitan Son",
                "label_ar": "يوميتان ابن",
                "label_fr": "Fils Yomitan"
            },
            {
                "value": 3064,
                "label": "Hayama",
                "label_ar": "هياما",
                "label_fr": "Hayama"
            },
            {
                "value": 3078,
                "label": "Takahashi",
                "label_ar": "تاكاهاشي",
                "label_fr": "Takahashi"
            },
            {
                "value": 3240,
                "label": "Sukumo",
                "label_ar": "سوكومو",
                "label_fr": "Sukumo"
            },
            {
                "value": 3230,
                "label": "Oshu",
                "label_ar": "أوشو",
                "label_fr": "Oshu"
            },
            {
                "value": 3327,
                "label": "Nyuzen",
                "label_ar": "نيوزين",
                "label_fr": "Nyuzen"
            },
            {
                "value": 2404,
                "label": "Numazu",
                "label_ar": "نومازو",
                "label_fr": "Numazu"
            },
            {
                "value": 2694,
                "label": "Morioka",
                "label_ar": "موريوكا",
                "label_fr": "Morioka"
            },
            {
                "value": 2696,
                "label": "Kofu",
                "label_ar": "كوفو",
                "label_fr": "Kofu"
            },
            {
                "value": 2744,
                "label": "Nanao",
                "label_ar": "ناناو",
                "label_fr": "Nanao"
            },
            {
                "value": 2844,
                "label": "Kumano",
                "label_ar": "كومانو",
                "label_fr": "Kumano"
            },
            {
                "value": 3355,
                "label": "Ujiie",
                "label_ar": "Ujiie",
                "label_fr": "Ujiie"
            },
            {
                "value": 3052,
                "label": "Kudamatsu",
                "label_ar": "كوداماتسو",
                "label_fr": "Kudamatsu"
            },
            {
                "value": 3071,
                "label": "Hofu",
                "label_ar": "هوفو",
                "label_fr": "Hofu"
            },
            {
                "value": 3161,
                "label": "Sanuki",
                "label_ar": "سانوكي",
                "label_fr": "Sanuki"
            },
            {
                "value": 2529,
                "label": "Tajimi",
                "label_ar": "تاجيمي",
                "label_fr": "Tajimi"
            },
            {
                "value": 2530,
                "label": "Nabari",
                "label_ar": "نباري",
                "label_fr": "Nabari"
            },
            {
                "value": 2920,
                "label": "Sakaidecho",
                "label_ar": "ساكيديشو",
                "label_fr": "Sakaidecho"
            },
            {
                "value": 3502,
                "label": "Itoman",
                "label_ar": "ايتمان",
                "label_fr": "Itoman"
            },
            {
                "value": 3475,
                "label": "Moji",
                "label_ar": "موجي",
                "label_fr": "Moji"
            },
            {
                "value": 3435,
                "label": "Subashiri",
                "label_ar": "سوباشيري",
                "label_fr": "Subashiri"
            },
            {
                "value": 3451,
                "label": "Nishiyama",
                "label_ar": "نيشياما",
                "label_fr": "Nishiyama"
            },
            {
                "value": 3444,
                "label": "Inage",
                "label_ar": "فى عمر",
                "label_fr": "Inage"
            },
            {
                "value": 3227,
                "label": "Nakahai",
                "label_ar": "Nakahai",
                "label_fr": "Nakahai"
            },
            {
                "value": 2457,
                "label": "Tsurugashima",
                "label_ar": "تسوروغاشيما",
                "label_fr": "Tsurugashima"
            },
            {
                "value": 2446,
                "label": "Machi",
                "label_ar": "ماشي",
                "label_fr": "Machi"
            },
            {
                "value": 2940,
                "label": "Nishi",
                "label_ar": "نيشي",
                "label_fr": "Nishi"
            },
            {
                "value": 3200,
                "label": "Ishida",
                "label_ar": "إيشيدا",
                "label_fr": "Ishida"
            },
            {
                "value": 3153,
                "label": "Minobu",
                "label_ar": "مينوبو",
                "label_fr": "Minobu"
            },
            {
                "value": 3470,
                "label": "Onogawa",
                "label_ar": "أونوجاوا",
                "label_fr": "Onogawa"
            },
            {
                "value": 2836,
                "label": "Takashima",
                "label_ar": "تاكاشيما",
                "label_fr": "Takashima"
            },
            {
                "value": 2518,
                "label": "Aioi",
                "label_ar": "أيوي",
                "label_fr": "Aioi"
            },
            {
                "value": 2854,
                "label": "Kosai",
                "label_ar": "كوساي",
                "label_fr": "Kosai"
            },
            {
                "value": 3272,
                "label": "Tsukayama",
                "label_ar": "تسوكاياما",
                "label_fr": "Tsukayama"
            },
            {
                "value": 2459,
                "label": "Yokosuka",
                "label_ar": "يوكوسوكا",
                "label_fr": "Yokosuka"
            },
            {
                "value": 2880,
                "label": "Koza",
                "label_ar": "كوزا",
                "label_fr": "Koza"
            },
            {
                "value": 2894,
                "label": "Hommachi",
                "label_ar": "هوماشي",
                "label_fr": "Hommachi"
            },
            {
                "value": 3248,
                "label": "Kose",
                "label_ar": "كوز",
                "label_fr": "Kose"
            },
            {
                "value": 2423,
                "label": "Kumagaya",
                "label_ar": "كوماغايا",
                "label_fr": "Kumagaya"
            },
            {
                "value": 2584,
                "label": "Iwata",
                "label_ar": "ايواتا",
                "label_fr": "Iwata"
            },
            {
                "value": 2350,
                "label": "Susono",
                "label_ar": "سوسونو",
                "label_fr": "Susono"
            },
            {
                "value": 2565,
                "label": "Iizuka",
                "label_ar": "إيزوكا",
                "label_fr": "Iizuka"
            },
            {
                "value": 2414,
                "label": "Komatsubara",
                "label_ar": "كوماتسوبارا",
                "label_fr": "Komatsubara"
            },
            {
                "value": 2687,
                "label": "Mito-shi",
                "label_ar": "ميتو شي",
                "label_fr": "Mito-shi"
            },
            {
                "value": 2443,
                "label": "Sakurakabu",
                "label_ar": "ساكوراكابو",
                "label_fr": "Sakurakabu"
            },
            {
                "value": 2955,
                "label": "Nakazawa",
                "label_ar": "ناكازاوا",
                "label_fr": "Nakazawa"
            },
            {
                "value": 3070,
                "label": "Wadayama",
                "label_ar": "واداياما",
                "label_fr": "Wadayama"
            },
            {
                "value": 3224,
                "label": "Azuchi",
                "label_ar": "أزوتشي",
                "label_fr": "Azuchi"
            },
            {
                "value": 3335,
                "label": "Susaki",
                "label_ar": "سوساكي",
                "label_fr": "Susaki"
            },
            {
                "value": 2602,
                "label": "Saga-shi",
                "label_ar": "ساغا شي",
                "label_fr": "Saga-shi"
            },
            {
                "value": 3146,
                "label": "Hayashima",
                "label_ar": "هاياشيما",
                "label_fr": "Hayashima"
            },
            {
                "value": 3140,
                "label": "Yakage",
                "label_ar": "ياكاجي",
                "label_fr": "Yakage"
            },
            {
                "value": 2834,
                "label": "Ida",
                "label_ar": "إيدا",
                "label_fr": "Ida"
            },
            {
                "value": 2323,
                "label": "Nagoya-shi",
                "label_ar": "ناغويا شي",
                "label_fr": "Nagoya-shi"
            },
            {
                "value": 2435,
                "label": "Hamura",
                "label_ar": "حمورة",
                "label_fr": "Hamura"
            },
            {
                "value": 3331,
                "label": "Ichi",
                "label_ar": "إيتشي",
                "label_fr": "Ichi"
            },
            {
                "value": 3510,
                "label": "Shiobaru",
                "label_ar": "شيوبارو",
                "label_fr": "Shiobaru"
            },
            {
                "value": 2859,
                "label": "Tsudanuma",
                "label_ar": "تسودانوما",
                "label_fr": "Tsudanuma"
            },
            {
                "value": 3170,
                "label": "Ogawa",
                "label_ar": "اوجاوا",
                "label_fr": "Ogawa"
            },
            {
                "value": 3302,
                "label": "Hakone",
                "label_ar": "هاكوني",
                "label_fr": "Hakone"
            },
            {
                "value": 2324,
                "label": "Kyoto",
                "label_ar": "كيوتو",
                "label_fr": "Kyoto"
            },
            {
                "value": 2374,
                "label": "Kuroiwa",
                "label_ar": "كورويوا",
                "label_fr": "Kuroiwa"
            },
            {
                "value": 2610,
                "label": "Uji",
                "label_ar": "يوجي",
                "label_fr": "Uji"
            },
            {
                "value": 3403,
                "label": "Mutsumi",
                "label_ar": "موتسومي",
                "label_fr": "Mutsumi"
            },
            {
                "value": 2924,
                "label": "Uwayama",
                "label_ar": "Uwayama",
                "label_fr": "Uwayama"
            },
            {
                "value": 3152,
                "label": "Toba",
                "label_ar": "توبا",
                "label_fr": "Toba"
            },
            {
                "value": 2405,
                "label": "Takajomachi",
                "label_ar": "تاكاجوماتشي",
                "label_fr": "Takajomachi"
            },
            {
                "value": 2619,
                "label": "Neyagawa",
                "label_ar": "نياغاوا",
                "label_fr": "Neyagawa"
            },
            {
                "value": 3365,
                "label": "Oguni",
                "label_ar": "أوجوني",
                "label_fr": "Oguni"
            },
            {
                "value": 3483,
                "label": "Kameda-honcho",
                "label_ar": "كاميدا هونشو",
                "label_fr": "Kameda-honcho"
            },
            {
                "value": 2845,
                "label": "Wajima",
                "label_ar": "واجيما",
                "label_fr": "Wajima"
            },
            {
                "value": 2916,
                "label": "Sarugakucho",
                "label_ar": "ساروغاكوتشو",
                "label_fr": "Sarugakucho"
            },
            {
                "value": 2438,
                "label": "Shinsuka",
                "label_ar": "شينسوكا",
                "label_fr": "Shinsuka"
            },
            {
                "value": 2580,
                "label": "Minatomachi",
                "label_ar": "ميناتوماتشي",
                "label_fr": "Minatomachi"
            },
            {
                "value": 2693,
                "label": "Kameoka",
                "label_ar": "كاميوكا",
                "label_fr": "Kameoka"
            },
            {
                "value": 2681,
                "label": "Hakodate",
                "label_ar": "هاكوداته",
                "label_fr": "Hakodate"
            },
            {
                "value": 2561,
                "label": "Sakado",
                "label_ar": "ساكادو",
                "label_fr": "Sakado"
            },
            {
                "value": 2969,
                "label": "Kaminoyama",
                "label_ar": "كامينوياما",
                "label_fr": "Kaminoyama"
            },
            {
                "value": 3337,
                "label": "Kurami",
                "label_ar": "كورامي",
                "label_fr": "Kurami"
            },
            {
                "value": 3051,
                "label": "Shizukuishi",
                "label_ar": "شيزوكويشي",
                "label_fr": "Shizukuishi"
            },
            {
                "value": 3165,
                "label": "Mihama",
                "label_ar": "ميهاما",
                "label_fr": "Mihama"
            },
            {
                "value": 2406,
                "label": "Mishima",
                "label_ar": "ميشيما",
                "label_fr": "Mishima"
            },
            {
                "value": 2761,
                "label": "Sasaguri",
                "label_ar": "ساساجوري",
                "label_fr": "Sasaguri"
            },
            {
                "value": 3166,
                "label": "Taketoyo",
                "label_ar": "تاكيتويو",
                "label_fr": "Taketoyo"
            },
            {
                "value": 3080,
                "label": "Oiwake",
                "label_ar": "أوياكي",
                "label_fr": "Oiwake"
            },
            {
                "value": 3203,
                "label": "Yokogawa",
                "label_ar": "يوكوجاوا",
                "label_fr": "Yokogawa"
            },
            {
                "value": 2915,
                "label": "Tajiri",
                "label_ar": "تاجيري",
                "label_fr": "Tajiri"
            },
            {
                "value": 2852,
                "label": "Matsue-shi",
                "label_ar": "ماتسو شي",
                "label_fr": "Matsue-shi"
            },
            {
                "value": 3241,
                "label": "Nodani",
                "label_ar": "Nodani",
                "label_fr": "Nodani"
            },
            {
                "value": 2783,
                "label": "Oizu",
                "label_ar": "Oizu",
                "label_fr": "Oizu"
            },
            {
                "value": 2936,
                "label": "Edagawa",
                "label_ar": "ايداغاوا",
                "label_fr": "Edagawa"
            },
            {
                "value": 3073,
                "label": "Fukai",
                "label_ar": "فوكاي",
                "label_fr": "Fukai"
            },
            {
                "value": 3267,
                "label": "Kotoku",
                "label_ar": "كوتوكو",
                "label_fr": "Kotoku"
            },
            {
                "value": 2519,
                "label": "Ashiya",
                "label_ar": "Ashiya",
                "label_fr": "Ashiya"
            },
            {
                "value": 2666,
                "label": "Maibara",
                "label_ar": "ميبارا",
                "label_fr": "Maibara"
            },
            {
                "value": 2641,
                "label": "Kitakami",
                "label_ar": "كيتاكامي",
                "label_fr": "Kitakami"
            },
            {
                "value": 2913,
                "label": "Kashiwagimachi",
                "label_ar": "كاشيواجيماشي",
                "label_fr": "Kashiwagimachi"
            },
            {
                "value": 2957,
                "label": "Ichibanhoridoricho",
                "label_ar": "Ichibanhoridoricho",
                "label_fr": "Ichibanhoridoricho"
            },
            {
                "value": 2535,
                "label": "Fuji",
                "label_ar": "فوجي",
                "label_fr": "Fuji"
            },
            {
                "value": 3243,
                "label": "Hakozaki",
                "label_ar": "هاكوزاكي",
                "label_fr": "Hakozaki"
            },
            {
                "value": 2586,
                "label": "Hanno",
                "label_ar": "هانو",
                "label_fr": "Hanno"
            },
            {
                "value": 2676,
                "label": "Shuchi",
                "label_ar": "شوتشي",
                "label_fr": "Shuchi"
            },
            {
                "value": 2958,
                "label": "Shibaura",
                "label_ar": "شيبورا",
                "label_fr": "Shibaura"
            },
            {
                "value": 2606,
                "label": "Nakanoshima",
                "label_ar": "ناكانوشيما",
                "label_fr": "Nakanoshima"
            },
            {
                "value": 2833,
                "label": "Ueno",
                "label_ar": "أوينو",
                "label_fr": "Ueno"
            },
            {
                "value": 3137,
                "label": "Soja",
                "label_ar": "سوجا",
                "label_fr": "Soja"
            },
            {
                "value": 3422,
                "label": "Takinogawa",
                "label_ar": "تاكينوجاوا",
                "label_fr": "Takinogawa"
            },
            {
                "value": 2645,
                "label": "Yamaga",
                "label_ar": "ياماجا",
                "label_fr": "Yamaga"
            },
            {
                "value": 3282,
                "label": "Agena",
                "label_ar": "أجينا",
                "label_fr": "Agena"
            },
            {
                "value": 2533,
                "label": "Shibadaimon",
                "label_ar": "شبادايمون",
                "label_fr": "Shibadaimon"
            },
            {
                "value": 2609,
                "label": "Sandacho",
                "label_ar": "سانداشو",
                "label_fr": "Sandacho"
            },
            {
                "value": 3239,
                "label": "Ikedacho-itano",
                "label_ar": "إيكيداشو إيتانو",
                "label_fr": "Ikedacho-itano"
            },
            {
                "value": 3472,
                "label": "Katsura",
                "label_ar": "كاتسورا",
                "label_fr": "Katsura"
            },
            {
                "value": 2596,
                "label": "Kure",
                "label_ar": "كوري",
                "label_fr": "Kure"
            },
            {
                "value": 3264,
                "label": "Himi",
                "label_ar": "همي",
                "label_fr": "Himi"
            },
            {
                "value": 2522,
                "label": "Kanie",
                "label_ar": "كاني",
                "label_fr": "Kanie"
            },
            {
                "value": 3404,
                "label": "Fujisato",
                "label_ar": "فوجيساتو",
                "label_fr": "Fujisato"
            },
            {
                "value": 2771,
                "label": "Isoya",
                "label_ar": "إيزويا",
                "label_fr": "Isoya"
            },
            {
                "value": 3413,
                "label": "Kubo",
                "label_ar": "كوبو",
                "label_fr": "Kubo"
            },
            {
                "value": 2507,
                "label": "Ota",
                "label_ar": "اوتا",
                "label_fr": "Ota"
            },
            {
                "value": 3155,
                "label": "Nagase",
                "label_ar": "ناغاسي",
                "label_fr": "Nagase"
            },
            {
                "value": 3488,
                "label": "Ochiai",
                "label_ar": "أوشياي",
                "label_fr": "Ochiai"
            },
            {
                "value": 2549,
                "label": "Ishinomaki",
                "label_ar": "إيشينوماكي",
                "label_fr": "Ishinomaki"
            },
            {
                "value": 2391,
                "label": "Tomakomai",
                "label_ar": "توماكوماي",
                "label_fr": "Tomakomai"
            },
            {
                "value": 3516,
                "label": "Yabase",
                "label_ar": "ياباسي",
                "label_fr": "Yabase"
            },
            {
                "value": 2823,
                "label": "Koga",
                "label_ar": "كوجا",
                "label_fr": "Koga"
            },
            {
                "value": 2881,
                "label": "Shingu",
                "label_ar": "شينغو",
                "label_fr": "Shingu"
            },
            {
                "value": 2796,
                "label": "Yabu",
                "label_ar": "يابو",
                "label_fr": "Yabu"
            },
            {
                "value": 3100,
                "label": "Hashima",
                "label_ar": "هشيمة",
                "label_fr": "Hashima"
            },
            {
                "value": 2659,
                "label": "Maizuru",
                "label_ar": "ميزورو",
                "label_fr": "Maizuru"
            },
            {
                "value": 2939,
                "label": "Higashiiya-wakabayashi",
                "label_ar": "هيجاشييا واكباياشي",
                "label_fr": "Higashiiya-wakabayashi"
            },
            {
                "value": 2960,
                "label": "Fukuda",
                "label_ar": "فوكودا",
                "label_fr": "Fukuda"
            },
            {
                "value": 3182,
                "label": "Katsuura",
                "label_ar": "كاتسورا",
                "label_fr": "Katsuura"
            },
            {
                "value": 3288,
                "label": "Komono",
                "label_ar": "كومونو",
                "label_fr": "Komono"
            },
            {
                "value": 2467,
                "label": "Uchimaru",
                "label_ar": "أوشيمارو",
                "label_fr": "Uchimaru"
            },
            {
                "value": 2527,
                "label": "Toyokawa",
                "label_ar": "تويوكاوا",
                "label_fr": "Toyokawa"
            },
            {
                "value": 2557,
                "label": "Takayama",
                "label_ar": "تاكاياما",
                "label_fr": "Takayama"
            },
            {
                "value": 2970,
                "label": "Suzaka",
                "label_ar": "سوزاكا",
                "label_fr": "Suzaka"
            },
            {
                "value": 3136,
                "label": "Tamano",
                "label_ar": "تامانو",
                "label_fr": "Tamano"
            },
            {
                "value": 3457,
                "label": "Yutaka",
                "label_ar": "يوتاكا",
                "label_fr": "Yutaka"
            },
            {
                "value": 3215,
                "label": "Fukui",
                "label_ar": "فوكوي",
                "label_fr": "Fukui"
            },
            {
                "value": 2599,
                "label": "Tosu",
                "label_ar": "إلى Su",
                "label_fr": "Tosu"
            },
            {
                "value": 2605,
                "label": "Narashino-shi",
                "label_ar": "ناراشينو شي",
                "label_fr": "Narashino-shi"
            },
            {
                "value": 2843,
                "label": "Otake",
                "label_ar": "أوتاكي",
                "label_fr": "Otake"
            },
            {
                "value": 2919,
                "label": "Komagome",
                "label_ar": "كوماغومي",
                "label_fr": "Komagome"
            },
            {
                "value": 3109,
                "label": "Ojiya",
                "label_ar": "أوجيا",
                "label_fr": "Ojiya"
            },
            {
                "value": 3139,
                "label": "Niimi",
                "label_ar": "Niimi",
                "label_fr": "Niimi"
            },
            {
                "value": 3329,
                "label": "Jumonji",
                "label_ar": "جومونجي",
                "label_fr": "Jumonji"
            },
            {
                "value": 2524,
                "label": "Tatebayashi",
                "label_ar": "تاتباياشي",
                "label_fr": "Tatebayashi"
            },
            {
                "value": 2848,
                "label": "Honcho",
                "label_ar": "هونشو",
                "label_fr": "Honcho"
            },
            {
                "value": 2820,
                "label": "Ichinomiya",
                "label_ar": "ايتشينوميا",
                "label_fr": "Ichinomiya"
            },
            {
                "value": 2911,
                "label": "Kocho",
                "label_ar": "كوجو",
                "label_fr": "Kocho"
            },
            {
                "value": 2594,
                "label": "Kakamigahara",
                "label_ar": "كاكاميغاهارا",
                "label_fr": "Kakamigahara"
            },
            {
                "value": 2708,
                "label": "Hasuda",
                "label_ar": "حسودة",
                "label_fr": "Hasuda"
            },
            {
                "value": 3191,
                "label": "Ishikawa",
                "label_ar": "إيشيكاوا",
                "label_fr": "Ishikawa"
            },
            {
                "value": 3438,
                "label": "Miyakojima",
                "label_ar": "مياكوجيما",
                "label_fr": "Miyakojima"
            },
            {
                "value": 3336,
                "label": "Kawahara",
                "label_ar": "كوهرة",
                "label_fr": "Kawahara"
            },
            {
                "value": 3421,
                "label": "Isumi",
                "label_ar": "إسومي",
                "label_fr": "Isumi"
            },
            {
                "value": 3050,
                "label": "Hiyoshi",
                "label_ar": "هييوشي",
                "label_fr": "Hiyoshi"
            },
            {
                "value": 2903,
                "label": "Takaishi",
                "label_ar": "تاكاشي",
                "label_fr": "Takaishi"
            },
            {
                "value": 2986,
                "label": "Higashiyamato",
                "label_ar": "هيجاشياماتو",
                "label_fr": "Higashiyamato"
            },
            {
                "value": 2394,
                "label": "Hanawadamachi",
                "label_ar": "Hanawadamachi",
                "label_fr": "Hanawadamachi"
            },
            {
                "value": 2933,
                "label": "Chiji",
                "label_ar": "شيجي",
                "label_fr": "Chiji"
            },
            {
                "value": 3317,
                "label": "Sakaiminato",
                "label_ar": "ساكايميناتو",
                "label_fr": "Sakaiminato"
            },
            {
                "value": 2470,
                "label": "Higashiosaka",
                "label_ar": "هيغاشيوزاكا",
                "label_fr": "Higashiosaka"
            },
            {
                "value": 2731,
                "label": "Kamo",
                "label_ar": "كامو",
                "label_fr": "Kamo"
            },
            {
                "value": 3142,
                "label": "Kuse",
                "label_ar": "كوس",
                "label_fr": "Kuse"
            },
            {
                "value": 3235,
                "label": "Shinshiro",
                "label_ar": "شينشيرو",
                "label_fr": "Shinshiro"
            },
            {
                "value": 3505,
                "label": "Takara",
                "label_ar": "تاكارا",
                "label_fr": "Takara"
            },
            {
                "value": 3281,
                "label": "Shiretoko",
                "label_ar": "شيريتوكو",
                "label_fr": "Shiretoko"
            },
            {
                "value": 2322,
                "label": "Sano",
                "label_ar": "سانو",
                "label_fr": "Sano"
            },
            {
                "value": 2661,
                "label": "Sumoto",
                "label_ar": "سوموتو",
                "label_fr": "Sumoto"
            },
            {
                "value": 2773,
                "label": "Ami",
                "label_ar": "عامي",
                "label_fr": "Suis-je"
            },
            {
                "value": 2764,
                "label": "Karatsu",
                "label_ar": "كاراتسو",
                "label_fr": "Karatsu"
            },
            {
                "value": 3354,
                "label": "Kanayama",
                "label_ar": "كاناياما",
                "label_fr": "Kanayama"
            },
            {
                "value": 3389,
                "label": "Shimotsuke",
                "label_ar": "شيموتسوكي",
                "label_fr": "Shimotsuke"
            },
            {
                "value": 3006,
                "label": "Tomiya",
                "label_ar": "توميا",
                "label_fr": "Tomiya"
            },
            {
                "value": 3179,
                "label": "Awara",
                "label_ar": "عوارة",
                "label_fr": "Awara"
            },
            {
                "value": 2627,
                "label": "Obanazawa",
                "label_ar": "أوبانازاوا",
                "label_fr": "Obanazawa"
            },
            {
                "value": 3157,
                "label": "Tennojicho-kita",
                "label_ar": "تينوجيتشو كيتا",
                "label_fr": "Tennojicho-kita"
            },
            {
                "value": 3123,
                "label": "Yurihonjo",
                "label_ar": "يوريهونجو",
                "label_fr": "Yurihonjo"
            },
            {
                "value": 2497,
                "label": "Higashimurayama-shi",
                "label_ar": "هيغاشيموراياما شي",
                "label_fr": "Higashimurayama-shi"
            },
            {
                "value": 3119,
                "label": "Nasukarasuyama",
                "label_ar": "ناسوكاراسوياما",
                "label_fr": "Nasukarasuyama"
            },
            {
                "value": 2828,
                "label": "Shoinmachi-koji",
                "label_ar": "شوينماتشي كوجي",
                "label_fr": "Shoinmachi-koji"
            },
            {
                "value": 2439,
                "label": "Ogaki",
                "label_ar": "اوجاكي",
                "label_fr": "Ogaki"
            },
            {
                "value": 2484,
                "label": "Otemachi",
                "label_ar": "أوتيماتشي",
                "label_fr": "Otemachi"
            },
            {
                "value": 2299,
                "label": "Saitama",
                "label_ar": "سايتاما",
                "label_fr": "Saitama"
            },
            {
                "value": 3086,
                "label": "Itabashi",
                "label_ar": "إيتاباشي",
                "label_fr": "Itabashi"
            },
            {
                "value": 2758,
                "label": "Jogo",
                "label_ar": "جوجو",
                "label_fr": "Jogo"
            },
            {
                "value": 2981,
                "label": "Arida",
                "label_ar": "عريضة",
                "label_fr": "Arida"
            },
            {
                "value": 2348,
                "label": "Honjocho",
                "label_ar": "هونجوتشو",
                "label_fr": "Honjocho"
            },
            {
                "value": 3222,
                "label": "Shijonawate",
                "label_ar": "شيجوناواتي",
                "label_fr": "Shijonawate"
            },
            {
                "value": 3466,
                "label": "Sakae",
                "label_ar": "ساكاي",
                "label_fr": "Sakae"
            },
            {
                "value": 3246,
                "label": "Nakanishi",
                "label_ar": "ناكانيشي",
                "label_fr": "Nakanishi"
            },
            {
                "value": 3352,
                "label": "Tokiwa",
                "label_ar": "توكيوا",
                "label_fr": "Tokiwa"
            },
            {
                "value": 3453,
                "label": "Hatoyama",
                "label_ar": "هاتوياما",
                "label_fr": "Hatoyama"
            },
            {
                "value": 3101,
                "label": "Yonago",
                "label_ar": "يوناغو",
                "label_fr": "Yonago"
            },
            {
                "value": 2312,
                "label": "Aoicho",
                "label_ar": "اويشو",
                "label_fr": "Aoicho"
            },
            {
                "value": 2351,
                "label": "Nagasaki",
                "label_ar": "ناغازاكي",
                "label_fr": "Nagasaki"
            },
            {
                "value": 3416,
                "label": "Joso",
                "label_ar": "جوسو",
                "label_fr": "Joso"
            },
            {
                "value": 2569,
                "label": "Okinawa",
                "label_ar": "أوكيناوا",
                "label_fr": "Okinawa"
            },
            {
                "value": 2576,
                "label": "Ishigaki",
                "label_ar": "إيشيجاكي",
                "label_fr": "Ishigaki"
            },
            {
                "value": 2749,
                "label": "Moriyama",
                "label_ar": "مورياما",
                "label_fr": "Moriyama"
            },
            {
                "value": 2839,
                "label": "Kurashiki",
                "label_ar": "كوراشيكي",
                "label_fr": "Kurashiki"
            },
            {
                "value": 2407,
                "label": "Fujieda",
                "label_ar": "فوجيدا",
                "label_fr": "Fujieda"
            },
            {
                "value": 2615,
                "label": "Izumi",
                "label_ar": "ايزومي",
                "label_fr": "Izumi"
            },
            {
                "value": 3018,
                "label": "Yahagi",
                "label_ar": "ياهاجي",
                "label_fr": "Yahagi"
            },
            {
                "value": 3192,
                "label": "Utazu",
                "label_ar": "أوتازو",
                "label_fr": "Utazu"
            },
            {
                "value": 3383,
                "label": "Tomobe",
                "label_ar": "توموبي",
                "label_fr": "Tomobe"
            },
            {
                "value": 3419,
                "label": "Geinocho-mukumoto",
                "label_ar": "جينوتشو-موكوموتو",
                "label_fr": "Geinocho-mukumoto"
            },
            {
                "value": 3377,
                "label": "Ishiki",
                "label_ar": "إيشيكي",
                "label_fr": "Ishiki"
            },
            {
                "value": 2778,
                "label": "Mitsucho-iwami",
                "label_ar": "ميتسوتشو إيوامي",
                "label_fr": "Mitsucho-iwami"
            },
            {
                "value": 3001,
                "label": "Usuki",
                "label_ar": "أوسوكي",
                "label_fr": "Usuki"
            },
            {
                "value": 3016,
                "label": "Kanzaki",
                "label_ar": "كانزاكي",
                "label_fr": "Kanzaki"
            },
            {
                "value": 3434,
                "label": "Minatogawa",
                "label_ar": "ميناتوجاوا",
                "label_fr": "Minatogawa"
            },
            {
                "value": 2709,
                "label": "Honden",
                "label_ar": "هوندن",
                "label_fr": "Honden"
            },
            {
                "value": 2667,
                "label": "Ikoma",
                "label_ar": "ايكوما",
                "label_fr": "Ikoma"
            },
            {
                "value": 2830,
                "label": "Nakamura",
                "label_ar": "ناكامورا",
                "label_fr": "Nakamura"
            },
            {
                "value": 3154,
                "label": "Kitaozuma",
                "label_ar": "كيتوزوما",
                "label_fr": "Kitaozuma"
            },
            {
                "value": 3186,
                "label": "Fujioka",
                "label_ar": "فوجيوكا",
                "label_fr": "Fujioka"
            },
            {
                "value": 2337,
                "label": "Kasukabe",
                "label_ar": "كاسوكابي",
                "label_fr": "Kasukabe"
            },
            {
                "value": 2902,
                "label": "Takasagocho-takasemachi",
                "label_ar": "تاكاساغوتشو تاكاسيماتشي",
                "label_fr": "Takasagocho-takasemachi"
            },
            {
                "value": 2840,
                "label": "Chiryu",
                "label_ar": "شيريو",
                "label_fr": "Chiryu"
            },
            {
                "value": 3266,
                "label": "Tokuyama",
                "label_ar": "توكوياما",
                "label_fr": "Tokuyama"
            },
            {
                "value": 3325,
                "label": "Kure",
                "label_ar": "كوري",
                "label_fr": "Kure"
            },
            {
                "value": 3381,
                "label": "Akasaki",
                "label_ar": "أكاساكي",
                "label_fr": "Akasaki"
            },
            {
                "value": 3163,
                "label": "Minokamo",
                "label_ar": "مينوكامو",
                "label_fr": "Minokamo"
            },
            {
                "value": 3375,
                "label": "Shinjo",
                "label_ar": "شينجو",
                "label_fr": "Shinjo"
            },
            {
                "value": 3300,
                "label": "Ogura",
                "label_ar": "أوغورا",
                "label_fr": "Ogura"
            }
        ]
    },
    {
        "country": "Thailand",
        "cities": [
            {
                "value": 3548,
                "label": "Wiphawadi",
                "label_ar": "Wiphawadi",
                "label_fr": "Wiphawadi"
            },
            {
                "value": 3576,
                "label": "Mukdahan",
                "label_ar": "مكداهان",
                "label_fr": "Mukdahan"
            },
            {
                "value": 3632,
                "label": "Samphanthawong",
                "label_ar": "سامفانتاونج",
                "label_fr": "Samphanthawong"
            },
            {
                "value": 3566,
                "label": "Maha Sarakham",
                "label_ar": "مها سراخم",
                "label_fr": "Maha Sarakham"
            },
            {
                "value": 3620,
                "label": "Si Sa Ket",
                "label_ar": "سي سا كيت",
                "label_fr": "Si Sa Ket"
            },
            {
                "value": 3564,
                "label": "Lopburi",
                "label_ar": "لوبوري",
                "label_fr": "Lopburi"
            },
            {
                "value": 3667,
                "label": "Sam Khok",
                "label_ar": "سام خوك",
                "label_fr": "Sam Khok"
            },
            {
                "value": 3598,
                "label": "Pathum Thani",
                "label_ar": "باثوم ثاني",
                "label_fr": "Pathum Thani"
            },
            {
                "value": 3601,
                "label": "Rayong",
                "label_ar": "رايونغ",
                "label_fr": "Rayong"
            },
            {
                "value": 3645,
                "label": "Lat Krabang",
                "label_ar": "لات كرابانغ",
                "label_fr": "Lat Krabang"
            },
            {
                "value": 3639,
                "label": "Sathon",
                "label_ar": "ساثون",
                "label_fr": "Sathon"
            },
            {
                "value": 3655,
                "label": "Pattani",
                "label_ar": "باتاني",
                "label_fr": "Pattani"
            },
            {
                "value": 3540,
                "label": "Kanchanaburi",
                "label_ar": "كانشانابوري",
                "label_fr": "Kanchanaburi"
            },
            {
                "value": 3637,
                "label": "Nan",
                "label_ar": "نان",
                "label_fr": "Nan"
            },
            {
                "value": 3527,
                "label": "Chumphon",
                "label_ar": "شومفون",
                "label_fr": "Chumphon"
            },
            {
                "value": 3580,
                "label": "Phichit",
                "label_ar": "فيشيت",
                "label_fr": "Phichit"
            },
            {
                "value": 3635,
                "label": "Chaiyaphum",
                "label_ar": "تشايافوم",
                "label_fr": "Chaiyaphum"
            },
            {
                "value": 3685,
                "label": "Klaeng",
                "label_ar": "كلاينج",
                "label_fr": "Klaeng"
            },
            {
                "value": 3589,
                "label": "Amnat Charoen",
                "label_ar": "أمنات تشاروين",
                "label_fr": "Amnat Charoen"
            },
            {
                "value": 3656,
                "label": "Nakhon Si Thammarat",
                "label_ar": "ناخون سي ثامارات",
                "label_fr": "Nakhon Si Thammarat"
            },
            {
                "value": 3559,
                "label": "Min Buri",
                "label_ar": "مين بوري",
                "label_fr": "Min Buri"
            },
            {
                "value": 3627,
                "label": "Hua Hin",
                "label_ar": "هوا هين",
                "label_fr": "Hua Hin"
            },
            {
                "value": 3650,
                "label": "Photharam",
                "label_ar": "صور",
                "label_fr": "Photharam"
            },
            {
                "value": 3590,
                "label": "Ban Phichit",
                "label_ar": "بان فيشيت",
                "label_fr": "Ban Phichit"
            },
            {
                "value": 3691,
                "label": "Mae Sot",
                "label_ar": "ماي سوت",
                "label_fr": "Mae Sot"
            },
            {
                "value": 3607,
                "label": "Buriram",
                "label_ar": "بوريرام",
                "label_fr": "Buriram"
            },
            {
                "value": 3658,
                "label": "Cha-am",
                "label_ar": "تشا آم",
                "label_fr": "Cha-am"
            },
            {
                "value": 3574,
                "label": "Phayao",
                "label_ar": "فاياو",
                "label_fr": "Phayao"
            },
            {
                "value": 3529,
                "label": "Phang Khon",
                "label_ar": "فانغ خون",
                "label_fr": "Phang Khon"
            },
            {
                "value": 3537,
                "label": "Roi Et",
                "label_ar": "روي إت",
                "label_fr": "Roi Et"
            },
            {
                "value": 3563,
                "label": "New Sukhothai",
                "label_ar": "سوخوثاي الجديدة",
                "label_fr": "Nouveau Sukhothai"
            },
            {
                "value": 3672,
                "label": "Prakanong",
                "label_ar": "براكانونج",
                "label_fr": "Prakanong"
            },
            {
                "value": 3661,
                "label": "Ban Tha Ruea",
                "label_ar": "بان ثا رويا",
                "label_fr": "Ban Tha Ruea"
            },
            {
                "value": 3582,
                "label": "Ban Pat Mon",
                "label_ar": "بان بات مون",
                "label_fr": "Ban Pat Mon"
            },
            {
                "value": 3688,
                "label": "Phanna Nikhom",
                "label_ar": "فانا نيخوم",
                "label_fr": "Phanna Nikhom"
            },
            {
                "value": 3640,
                "label": "Suan Luang",
                "label_ar": "سوان لوانج",
                "label_fr": "Suan Luang"
            },
            {
                "value": 3595,
                "label": "Ang Thong",
                "label_ar": "آنج ثونج",
                "label_fr": "Ang Thong"
            },
            {
                "value": 3579,
                "label": "Krabi",
                "label_ar": "كرابي",
                "label_fr": "Krabi"
            },
            {
                "value": 3542,
                "label": "Ayutthaya",
                "label_ar": "أيوثايا",
                "label_fr": "Ayutthaya"
            },
            {
                "value": 3628,
                "label": "Ban Talat Rangsit",
                "label_ar": "بان طلعت رانجسيت",
                "label_fr": "Ban Talat Rangsit"
            },
            {
                "value": 3687,
                "label": "Ban Don Sak",
                "label_ar": "بان دون ساك",
                "label_fr": "Ban Don Sak"
            },
            {
                "value": 3624,
                "label": "Ban Bang Phli Nakhon",
                "label_ar": "بان بانج فلي ناخون",
                "label_fr": "Ban Bang Phli Nakhon"
            },
            {
                "value": 3671,
                "label": "Sena",
                "label_ar": "سينا",
                "label_fr": "Sena"
            },
            {
                "value": 3673,
                "label": "Ban Tha Pai",
                "label_ar": "بان ثا باي",
                "label_fr": "Ban Tha Pai"
            },
            {
                "value": 3674,
                "label": "Bang Lamung",
                "label_ar": "بانغ لامونج",
                "label_fr": "Bang Lamung"
            },
            {
                "value": 3544,
                "label": "Songkhla",
                "label_ar": "سونغكلا",
                "label_fr": "Songkhla"
            },
            {
                "value": 3633,
                "label": "Bang Khun Thian",
                "label_ar": "بانغ خون ثيان",
                "label_fr": "Bang Khun Thian"
            },
            {
                "value": 3549,
                "label": "Phuket",
                "label_ar": "فوكيت",
                "label_fr": "Phuket"
            },
            {
                "value": 3532,
                "label": "Kalasin",
                "label_ar": "كلاسين",
                "label_fr": "Kalasin"
            },
            {
                "value": 3552,
                "label": "Prachin Buri",
                "label_ar": "براتشين بوري",
                "label_fr": "Prachin Buri"
            },
            {
                "value": 3609,
                "label": "Khlong Luang",
                "label_ar": "خلونغ لوانغ",
                "label_fr": "Khlong Luang"
            },
            {
                "value": 3625,
                "label": "Salaya",
                "label_ar": "سلايا",
                "label_fr": "Salaya"
            },
            {
                "value": 3539,
                "label": "Kumphawapi",
                "label_ar": "كومفاوابي",
                "label_fr": "Kumphawapi"
            },
            {
                "value": 3613,
                "label": "Manorom",
                "label_ar": "مانوروم",
                "label_fr": "Manorom"
            },
            {
                "value": 3608,
                "label": "Dusit",
                "label_ar": "دوسِت",
                "label_fr": "Dusit"
            },
            {
                "value": 3677,
                "label": "Kamphaeng Phet",
                "label_ar": "كامبينغ فيت",
                "label_fr": "Kamphaeng Phet"
            },
            {
                "value": 3535,
                "label": "Khon Kaen",
                "label_ar": "خون كاين",
                "label_fr": "Khon Kaen"
            },
            {
                "value": 3651,
                "label": "Thalang",
                "label_ar": "ثالانج",
                "label_fr": "Thalang"
            },
            {
                "value": 3679,
                "label": "Hat Yai",
                "label_ar": "هات ياي",
                "label_fr": "Hat Yai"
            },
            {
                "value": 3558,
                "label": "Phetchabun",
                "label_ar": "فيتشابون",
                "label_fr": "Phetchabun"
            },
            {
                "value": 3653,
                "label": "Long",
                "label_ar": "طويل",
                "label_fr": "Longue"
            },
            {
                "value": 3670,
                "label": "Ban Pratunam Tha Khai",
                "label_ar": "بان براتونام ثا خاي",
                "label_fr": "Ban Pratunam Tha Khai"
            },
            {
                "value": 3612,
                "label": "Sung Noen",
                "label_ar": "سونغ نوين",
                "label_fr": "Sung Noen"
            },
            {
                "value": 3610,
                "label": "Trat",
                "label_ar": "ترات",
                "label_fr": "Trat"
            },
            {
                "value": 3530,
                "label": "Sakon Nakhon",
                "label_ar": "ساكون ناخون",
                "label_fr": "Sakon Nakhon"
            },
            {
                "value": 3528,
                "label": "Kathu",
                "label_ar": "كاثو",
                "label_fr": "Kathu"
            },
            {
                "value": 3600,
                "label": "Huai Khwang",
                "label_ar": "هواي خوانج",
                "label_fr": "Huai Khwang"
            },
            {
                "value": 3597,
                "label": "Sa Kaeo",
                "label_ar": "سا كايو",
                "label_fr": "Sa Kaeo"
            },
            {
                "value": 3541,
                "label": "Nong Khai",
                "label_ar": "نونغ خاي",
                "label_fr": "Nong Khai"
            },
            {
                "value": 3593,
                "label": "Ban Tham",
                "label_ar": "بان ثام",
                "label_fr": "Ban Tham"
            },
            {
                "value": 3543,
                "label": "Chiang Mai",
                "label_ar": "شيانغ ماي",
                "label_fr": "Chiang Mai"
            },
            {
                "value": 3634,
                "label": "Chatuchak",
                "label_ar": "شاتوشاك",
                "label_fr": "Chatuchak"
            },
            {
                "value": 3616,
                "label": "Ban Tha Duea",
                "label_ar": "بان ثا دويا",
                "label_fr": "Ban Tha Duea"
            },
            {
                "value": 3643,
                "label": "Khlong San",
                "label_ar": "خلونج سان",
                "label_fr": "Khlong San"
            },
            {
                "value": 3577,
                "label": "Phetchaburi",
                "label_ar": "فيتشابوري",
                "label_fr": "Phetchaburi"
            },
            {
                "value": 3596,
                "label": "Mueang Samut Prakan",
                "label_ar": "موينج ساموت براكان",
                "label_fr": "Mueang Samut Prakan"
            },
            {
                "value": 3689,
                "label": "Ban Na",
                "label_ar": "بان نا",
                "label_fr": "Ban Na"
            },
            {
                "value": 3642,
                "label": "Ban Khlong Prawet",
                "label_ar": "بان خلونج براويت",
                "label_fr": "Ban Khlong Prawet"
            },
            {
                "value": 3659,
                "label": "Amphoe Aranyaprathet",
                "label_ar": "أمفو أرانيابراتيت",
                "label_fr": "Amphoe Aranyaprathet"
            },
            {
                "value": 3575,
                "label": "Trang",
                "label_ar": "ترانج",
                "label_fr": "Trang"
            },
            {
                "value": 3560,
                "label": "Ratchaburi",
                "label_ar": "راتشابوري",
                "label_fr": "Ratchaburi"
            },
            {
                "value": 3526,
                "label": "Bangkok",
                "label_ar": "بانكوك",
                "label_fr": "Bangkok"
            },
            {
                "value": 3605,
                "label": "Phatthalung",
                "label_ar": "فاتالونج",
                "label_fr": "Phatthalung"
            },
            {
                "value": 3657,
                "label": "Khlong Toei",
                "label_ar": "خلونج توي",
                "label_fr": "Khlong Toei"
            },
            {
                "value": 3565,
                "label": "Uttaradit",
                "label_ar": "أوتاراديت",
                "label_fr": "Uttaradit"
            },
            {
                "value": 3619,
                "label": "Phaya Thai",
                "label_ar": "فايا تاي",
                "label_fr": "Phaya Thai"
            },
            {
                "value": 3652,
                "label": "Bang Kapi",
                "label_ar": "بانغ كابي",
                "label_fr": "Bang Kapi"
            },
            {
                "value": 3660,
                "label": "Phang Nga",
                "label_ar": "فانغ نغا",
                "label_fr": "Phang Nga"
            },
            {
                "value": 3682,
                "label": "Makkasan",
                "label_ar": "ماكاسان",
                "label_fr": "Makkasan"
            },
            {
                "value": 3567,
                "label": "Mae Hong Son",
                "label_ar": "ماي هونغ سون",
                "label_fr": "Mae Hong Son"
            },
            {
                "value": 3594,
                "label": "Sam Sen",
                "label_ar": "سام سين",
                "label_fr": "Sam Sen"
            },
            {
                "value": 3570,
                "label": "Samut Sakhon",
                "label_ar": "ساموت ساخون",
                "label_fr": "Samut Sakhon"
            },
            {
                "value": 3666,
                "label": "Bang Sue",
                "label_ar": "بانغ سو",
                "label_fr": "Bang Sue"
            },
            {
                "value": 3571,
                "label": "Phrae",
                "label_ar": "فراي",
                "label_fr": "Phrae"
            },
            {
                "value": 3684,
                "label": "Ban Hua Thale",
                "label_ar": "بان هوا ثالي",
                "label_fr": "Ban Hua Thale"
            },
            {
                "value": 3555,
                "label": "Yala",
                "label_ar": "يالا",
                "label_fr": "Yala"
            },
            {
                "value": 3550,
                "label": "Sing Buri",
                "label_ar": "غناء بوري",
                "label_fr": "Chanter Buri"
            },
            {
                "value": 3611,
                "label": "Ban Bueng",
                "label_ar": "بان بوينج",
                "label_fr": "Ban Bueng"
            },
            {
                "value": 3622,
                "label": "Bangkok",
                "label_ar": "بانكوك",
                "label_fr": "Bangkok"
            },
            {
                "value": 3583,
                "label": "Prachuap Khiri Khan",
                "label_ar": "براشواب خيرى خان",
                "label_fr": "Prachuap Khiri Khan"
            },
            {
                "value": 3551,
                "label": "Satun",
                "label_ar": "ساتون",
                "label_fr": "Satun"
            },
            {
                "value": 3569,
                "label": "Chachoengsao",
                "label_ar": "تشاتشونجساو",
                "label_fr": "Chachoengsao"
            },
            {
                "value": 3533,
                "label": "Chon Buri",
                "label_ar": "تشون بوري",
                "label_fr": "Chon Buri"
            },
            {
                "value": 3686,
                "label": "Chulabhorn",
                "label_ar": "تشولابورن",
                "label_fr": "Chulabhorn"
            },
            {
                "value": 3603,
                "label": "Phan",
                "label_ar": "فان",
                "label_fr": "Phan"
            },
            {
                "value": 3561,
                "label": "Lampang",
                "label_ar": "لامبانج",
                "label_fr": "Lampang"
            },
            {
                "value": 3641,
                "label": "Ban Wang Yai",
                "label_ar": "بان وانغ ياي",
                "label_fr": "Ban Wang Yai"
            },
            {
                "value": 3647,
                "label": "Kosum Phisai",
                "label_ar": "كوسوم فيساي",
                "label_fr": "Kosum Phisai"
            },
            {
                "value": 3676,
                "label": "San Sai",
                "label_ar": "سان ساي",
                "label_fr": "San Sai"
            },
            {
                "value": 3662,
                "label": "Chiang Muan",
                "label_ar": "شيانغ موان",
                "label_fr": "Chiang Muan"
            },
            {
                "value": 3680,
                "label": "Ban Nam Hak",
                "label_ar": "بان نام هاك",
                "label_fr": "Ban Nam Hak"
            },
            {
                "value": 3675,
                "label": "Nakhon Sawan",
                "label_ar": "ناخون صوان",
                "label_fr": "Nakhon Sawan"
            },
            {
                "value": 3654,
                "label": "Ka Bang",
                "label_ar": "كا بانغ",
                "label_fr": "Ka Bang"
            },
            {
                "value": 3545,
                "label": "Chiang Rai",
                "label_ar": "شيانج راي",
                "label_fr": "Chiang Rai"
            },
            {
                "value": 3587,
                "label": "Lamphun",
                "label_ar": "لامفون",
                "label_fr": "Lamphun"
            },
            {
                "value": 3648,
                "label": "Ban Phlam",
                "label_ar": "بان فلام",
                "label_fr": "Ban Phlam"
            },
            {
                "value": 3554,
                "label": "Pattaya",
                "label_ar": "باتايا",
                "label_fr": "Pattaya"
            },
            {
                "value": 3664,
                "label": "Ban Khlong Takhian",
                "label_ar": "بان خلونج تاخيان",
                "label_fr": "Ban Khlong Takhian"
            },
            {
                "value": 3562,
                "label": "Narathiwat",
                "label_ar": "ناراثيوات",
                "label_fr": "Narathiwat"
            },
            {
                "value": 3592,
                "label": "Thon Buri",
                "label_ar": "ثون بوري",
                "label_fr": "Thon Buri"
            },
            {
                "value": 3683,
                "label": "Bang Sao Thong",
                "label_ar": "بانغ ساو ثونغ",
                "label_fr": "Bang Sao Thong"
            },
            {
                "value": 3638,
                "label": "Bang Kruai",
                "label_ar": "بانغ كروي",
                "label_fr": "Bang Kruai"
            },
            {
                "value": 3614,
                "label": "Ban Bang Plong",
                "label_ar": "بان بانج بلونج",
                "label_fr": "Ban Bang Plong"
            },
            {
                "value": 3591,
                "label": "Bang Khae",
                "label_ar": "بانغ خاي",
                "label_fr": "Bang Khae"
            },
            {
                "value": 3678,
                "label": "Pak Kret",
                "label_ar": "باك كريت",
                "label_fr": "Pak Kret"
            },
            {
                "value": 3556,
                "label": "Bang Na",
                "label_ar": "بانغ نا",
                "label_fr": "Bang Na"
            },
            {
                "value": 3547,
                "label": "Thanyaburi",
                "label_ar": "ثانيابوري",
                "label_fr": "Thanyaburi"
            },
            {
                "value": 3599,
                "label": "Chanthaburi",
                "label_ar": "شانثابوري",
                "label_fr": "Chanthaburi"
            },
            {
                "value": 3536,
                "label": "Nong Bua Lamphu",
                "label_ar": "نونغ بوا لامفو",
                "label_fr": "Nong Bua Lamphu"
            },
            {
                "value": 3578,
                "label": "Uthai Thani",
                "label_ar": "أوثاي ثاني",
                "label_fr": "Uthai Thani"
            },
            {
                "value": 3669,
                "label": "Phachi",
                "label_ar": "Phachi",
                "label_fr": "Phachi"
            },
            {
                "value": 3636,
                "label": "Nakhon Pathom",
                "label_ar": "ناخون باتوم",
                "label_fr": "Nakhon Pathom"
            },
            {
                "value": 3534,
                "label": "Loei",
                "label_ar": "لوي",
                "label_fr": "Loei"
            },
            {
                "value": 3573,
                "label": "Pathum Wan",
                "label_ar": "باتوم وان",
                "label_fr": "Pathum Wan"
            },
            {
                "value": 3615,
                "label": "Tak",
                "label_ar": "تاك",
                "label_fr": "Tak"
            },
            {
                "value": 3557,
                "label": "Samut Songkhram",
                "label_ar": "ساموت سونغخرام",
                "label_fr": "Samut Songkhram"
            },
            {
                "value": 3621,
                "label": "Nakhon Ratchasima",
                "label_ar": "ناخون راتشاسيما",
                "label_fr": "Nakhon Ratchasima"
            },
            {
                "value": 3630,
                "label": "Nong Prue",
                "label_ar": "نونغ برو",
                "label_fr": "Nong Prue"
            },
            {
                "value": 3584,
                "label": "Ban Khlong Prasong",
                "label_ar": "بان خلونج براسونج",
                "label_fr": "Ban Khlong Prasong"
            },
            {
                "value": 3581,
                "label": "Phitsanulok",
                "label_ar": "فيتسانولوك",
                "label_fr": "Phitsanulok"
            },
            {
                "value": 3568,
                "label": "Suphan Buri",
                "label_ar": "سوفان بوري",
                "label_fr": "Suphan Buri"
            },
            {
                "value": 3631,
                "label": "Wang Thonglang",
                "label_ar": "وانغ ثونجلانغ",
                "label_fr": "Wang Thonglang"
            },
            {
                "value": 3586,
                "label": "Ranong",
                "label_ar": "رانونج",
                "label_fr": "Ranong"
            },
            {
                "value": 3681,
                "label": "Khlung",
                "label_ar": "خلونج",
                "label_fr": "Khlung"
            },
            {
                "value": 3553,
                "label": "Ubon Ratchathani",
                "label_ar": "أوبون راتشاثاني",
                "label_fr": "Ubon Ratchathani"
            },
            {
                "value": 3585,
                "label": "Yasothon",
                "label_ar": "ياسوثون",
                "label_fr": "Yasothon"
            },
            {
                "value": 3588,
                "label": "Nong Bua",
                "label_ar": "نونغ بوا",
                "label_fr": "Nong Bua"
            },
            {
                "value": 3644,
                "label": "Watthana",
                "label_ar": "واتثانا",
                "label_fr": "Watthana"
            },
            {
                "value": 3649,
                "label": "Non Thai",
                "label_ar": "غير التايلاندية",
                "label_fr": "Non thaï"
            },
            {
                "value": 3626,
                "label": "Krathum Baen",
                "label_ar": "كراتوم باين",
                "label_fr": "Krathum Baen"
            },
            {
                "value": 3606,
                "label": "Rawai",
                "label_ar": "راواي",
                "label_fr": "Rawai"
            },
            {
                "value": 3629,
                "label": "Ban Khlong Ngae",
                "label_ar": "بان خلونج نجاي",
                "label_fr": "Ban Khlong Ngae"
            },
            {
                "value": 3546,
                "label": "Surin",
                "label_ar": "سورين",
                "label_fr": "Surin"
            },
            {
                "value": 3617,
                "label": "Amphawa",
                "label_ar": "أمفاوا",
                "label_fr": "Amphawa"
            },
            {
                "value": 3572,
                "label": "Din Daeng",
                "label_ar": "دين داينج",
                "label_fr": "Din Daeng"
            },
            {
                "value": 3668,
                "label": "Don Mueang",
                "label_ar": "دون موينج",
                "label_fr": "Don Mueang"
            },
            {
                "value": 3663,
                "label": "Ban Ang Thong",
                "label_ar": "بان انج ثونج",
                "label_fr": "Ban Ang Thong"
            },
            {
                "value": 3665,
                "label": "Khan Na Yao",
                "label_ar": "خان نا ياو",
                "label_fr": "Khan Na Yao"
            },
            {
                "value": 3623,
                "label": "Bang Phlat",
                "label_ar": "بانج فلات",
                "label_fr": "Bang Phlat"
            },
            {
                "value": 3538,
                "label": "Udon Thani",
                "label_ar": "أودون ثاني",
                "label_fr": "Udon Thani"
            },
            {
                "value": 3604,
                "label": "Si Racha",
                "label_ar": "سي رشا",
                "label_fr": "Si Racha"
            },
            {
                "value": 3690,
                "label": "Ban Ko Pao",
                "label_ar": "بان كو باو",
                "label_fr": "Ban Ko Pao"
            },
            {
                "value": 3646,
                "label": "Muak Lek",
                "label_ar": "مواك ليك",
                "label_fr": "Muak Lek"
            },
            {
                "value": 3531,
                "label": "Mueang Nonthaburi",
                "label_ar": "موينج نونثابوري",
                "label_fr": "Mueang Nonthaburi"
            },
            {
                "value": 3618,
                "label": "Ban Pong Lang",
                "label_ar": "بان بونج لانج",
                "label_fr": "Ban Pong Lang"
            },
            {
                "value": 3602,
                "label": "Sattahip",
                "label_ar": "ساتاهية",
                "label_fr": "Sattahip"
            }
        ]
    },
    {
        "country": "United States",
        "cities": [
            {
                "value": 3693,
                "label": "'Ele'ele",
                "label_ar": "\"إلييلي",
                "label_fr": "'Ele'ele"
            },
            {
                "value": 3694,
                "label": "'Ewa Beach",
                "label_ar": "شاطئ إيوا",
                "label_fr": "'Ewa Beach"
            },
            {
                "value": 3695,
                "label": "Abbeville",
                "label_ar": "أبفيل",
                "label_fr": "Abbeville"
            },
            {
                "value": 3696,
                "label": "Abbotsford",
                "label_ar": "أبوتسفورد",
                "label_fr": "Abbotsford"
            },
            {
                "value": 3697,
                "label": "Abbott",
                "label_ar": "أبوت",
                "label_fr": "Abbott"
            },
            {
                "value": 3698,
                "label": "Abbottstown",
                "label_ar": "أبوتستاون",
                "label_fr": "Abbottstown"
            },
            {
                "value": 3699,
                "label": "Abercrombie",
                "label_ar": "أبيركرومبي",
                "label_fr": "Abercrombie"
            },
            {
                "value": 3700,
                "label": "Aberdeen",
                "label_ar": "أبردين",
                "label_fr": "Aberdeen"
            },
            {
                "value": 3701,
                "label": "Aberdeen Proving Ground",
                "label_ar": "ملعب أبردين",
                "label_fr": "Terrain d'essai d'Aberdeen"
            },
            {
                "value": 3702,
                "label": "Abernathy",
                "label_ar": "أبيرناثي",
                "label_fr": "Abernathy"
            },
            {
                "value": 3703,
                "label": "Abilene",
                "label_ar": "أبيلين",
                "label_fr": "Abilène"
            },
            {
                "value": 3704,
                "label": "Abingdon",
                "label_ar": "أبينجدون",
                "label_fr": "Abingdon"
            },
            {
                "value": 3705,
                "label": "Abington",
                "label_ar": "أبينجتون",
                "label_fr": "Abington"
            },
            {
                "value": 3706,
                "label": "Abington Township",
                "label_ar": "بلدة أبينجتون",
                "label_fr": "Canton d'Abington"
            },
            {
                "value": 3707,
                "label": "Abiquiu",
                "label_ar": "أبيكيو",
                "label_fr": "Abiquiu"
            },
            {
                "value": 3708,
                "label": "Abita Springs",
                "label_ar": "ابيتا سبرينجز",
                "label_fr": "Abita Springs"
            },
            {
                "value": 3709,
                "label": "Abrams",
                "label_ar": "أبرامز",
                "label_fr": "Abrams"
            },
            {
                "value": 3710,
                "label": "Absarokee",
                "label_ar": "أبساروكي",
                "label_fr": "Absarokee"
            },
            {
                "value": 3711,
                "label": "Absecon",
                "label_ar": "Absecon",
                "label_fr": "Absecon"
            },
            {
                "value": 3712,
                "label": "Acampo",
                "label_ar": "أكامبو",
                "label_fr": "Acampo"
            },
            {
                "value": 3713,
                "label": "Access",
                "label_ar": "التمكن من",
                "label_fr": "Accès"
            },
            {
                "value": 3714,
                "label": "Accident",
                "label_ar": "حادث",
                "label_fr": "Accident"
            },
            {
                "value": 3715,
                "label": "Accokeek",
                "label_ar": "Accokeek",
                "label_fr": "Accokeek"
            },
            {
                "value": 3716,
                "label": "Accomac",
                "label_ar": "أكوماك",
                "label_fr": "Accomac"
            },
            {
                "value": 3717,
                "label": "Accord",
                "label_ar": "اتفاق",
                "label_fr": "Accord"
            },
            {
                "value": 3718,
                "label": "Accoville",
                "label_ar": "أكوفيل",
                "label_fr": "Accoville"
            },
            {
                "value": 3719,
                "label": "Achille",
                "label_ar": "أشيل",
                "label_fr": "Achille"
            },
            {
                "value": 3720,
                "label": "Achilles",
                "label_ar": "أخيل",
                "label_fr": "Achille"
            },
            {
                "value": 3721,
                "label": "Ackerly",
                "label_ar": "اكرلي",
                "label_fr": "Ackerly"
            },
            {
                "value": 3722,
                "label": "Ackerman",
                "label_ar": "أكرمان",
                "label_fr": "Ackerman"
            },
            {
                "value": 3723,
                "label": "Ackley",
                "label_ar": "أكلي",
                "label_fr": "Ackley"
            },
            {
                "value": 3724,
                "label": "Acme",
                "label_ar": "ذروة",
                "label_fr": "Acmé"
            },
            {
                "value": 3725,
                "label": "Acton",
                "label_ar": "العمل على",
                "label_fr": "Agir sur"
            },
            {
                "value": 3726,
                "label": "Acushnet",
                "label_ar": "أكوشنيت",
                "label_fr": "Acushnet"
            },
            {
                "value": 3727,
                "label": "Acworth",
                "label_ar": "أكوورث",
                "label_fr": "Acworth"
            },
            {
                "value": 3728,
                "label": "Ada",
                "label_ar": "آدا",
                "label_fr": "Ada"
            },
            {
                "value": 3729,
                "label": "Adah",
                "label_ar": "آدا",
                "label_fr": "Adah"
            },
            {
                "value": 3730,
                "label": "Adair",
                "label_ar": "أدير",
                "label_fr": "Adair"
            },
            {
                "value": 3731,
                "label": "Adairsville",
                "label_ar": "Adairsville",
                "label_fr": "Adairsville"
            },
            {
                "value": 3732,
                "label": "Adairville",
                "label_ar": "أديرفيل",
                "label_fr": "Adairville"
            },
            {
                "value": 3733,
                "label": "Adak",
                "label_ar": "أداك",
                "label_fr": "Adak"
            },
            {
                "value": 3734,
                "label": "Adams",
                "label_ar": "آدامز",
                "label_fr": "Adams"
            },
            {
                "value": 3735,
                "label": "Adams Center",
                "label_ar": "مركز آدمز",
                "label_fr": "Adams Center"
            },
            {
                "value": 3736,
                "label": "Adams Run",
                "label_ar": "آدامز ران",
                "label_fr": "Adams Run"
            },
            {
                "value": 3737,
                "label": "Adamstown",
                "label_ar": "ادمستاون",
                "label_fr": "Adamstown"
            },
            {
                "value": 3738,
                "label": "Adamsville",
                "label_ar": "آدامزفيل",
                "label_fr": "Adamsville"
            },
            {
                "value": 3739,
                "label": "Addieville",
                "label_ar": "أديفيل",
                "label_fr": "Addieville"
            },
            {
                "value": 3740,
                "label": "Addis",
                "label_ar": "أديس",
                "label_fr": "Addis"
            },
            {
                "value": 3741,
                "label": "Addison",
                "label_ar": "أديسون",
                "label_fr": "Addison"
            },
            {
                "value": 3742,
                "label": "Addyston",
                "label_ar": "أديستون",
                "label_fr": "Addyston"
            },
            {
                "value": 3743,
                "label": "Adel",
                "label_ar": "عادل",
                "label_fr": "Adel"
            },
            {
                "value": 3744,
                "label": "Adelanto",
                "label_ar": "أديلانتو",
                "label_fr": "Adelanto"
            },
            {
                "value": 3745,
                "label": "Adell",
                "label_ar": "ديل",
                "label_fr": "Adell"
            },
            {
                "value": 3746,
                "label": "Adena",
                "label_ar": "أدينا",
                "label_fr": "Adena"
            },
            {
                "value": 3747,
                "label": "Adger",
                "label_ar": "الصديق",
                "label_fr": "Adger"
            },
            {
                "value": 3748,
                "label": "Adin",
                "label_ar": "أدين",
                "label_fr": "Adin"
            },
            {
                "value": 3749,
                "label": "Adkins",
                "label_ar": "آدكنز",
                "label_fr": "Adkins"
            },
            {
                "value": 3750,
                "label": "Admire",
                "label_ar": "معجب",
                "label_fr": "Admirer"
            },
            {
                "value": 3751,
                "label": "Adolphus",
                "label_ar": "أدولفوس",
                "label_fr": "Adolphus"
            },
            {
                "value": 3752,
                "label": "Adona",
                "label_ar": "أدونا",
                "label_fr": "Adona"
            },
            {
                "value": 3753,
                "label": "Adrian",
                "label_ar": "أدريان",
                "label_fr": "Adrian"
            },
            {
                "value": 3754,
                "label": "Advance",
                "label_ar": "تقدم",
                "label_fr": "Avance"
            },
            {
                "value": 3755,
                "label": "Afton",
                "label_ar": "افتون",
                "label_fr": "Afton"
            },
            {
                "value": 3756,
                "label": "Agar",
                "label_ar": "أجار",
                "label_fr": "Gélose"
            },
            {
                "value": 3757,
                "label": "Agate",
                "label_ar": "العقيق",
                "label_fr": "Agate"
            },
            {
                "value": 3758,
                "label": "Agawam",
                "label_ar": "أجوام",
                "label_fr": "Agawam"
            },
            {
                "value": 3759,
                "label": "Agency",
                "label_ar": "وكالة",
                "label_fr": "Agence"
            },
            {
                "value": 3760,
                "label": "Agoura Hills",
                "label_ar": "أجورا هيلز",
                "label_fr": "Agoura Hills"
            },
            {
                "value": 3761,
                "label": "Agra",
                "label_ar": "اجرا",
                "label_fr": "Agra"
            },
            {
                "value": 3762,
                "label": "Aguanga",
                "label_ar": "اجوانجا",
                "label_fr": "Aguanga"
            },
            {
                "value": 3763,
                "label": "Aguila",
                "label_ar": "اغيلا",
                "label_fr": "Aguila"
            },
            {
                "value": 3764,
                "label": "Ahoskie",
                "label_ar": "أهوسكي",
                "label_fr": "Ahoskie"
            },
            {
                "value": 3765,
                "label": "Ahwahnee",
                "label_ar": "أهواهني",
                "label_fr": "Ahwahnee"
            },
            {
                "value": 3766,
                "label": "Aiea",
                "label_ar": "Aiea",
                "label_fr": "Aiea"
            },
            {
                "value": 3767,
                "label": "Aiken",
                "label_ar": "أيكن",
                "label_fr": "Aiken"
            },
            {
                "value": 3768,
                "label": "Ailey",
                "label_ar": "أيلي",
                "label_fr": "Ailey"
            },
            {
                "value": 3769,
                "label": "Ainsworth",
                "label_ar": "اينسورث",
                "label_fr": "Ainsworth"
            },
            {
                "value": 3770,
                "label": "Air Force Academy",
                "label_ar": "أكاديمية القوات الجوية",
                "label_fr": "Académie de l'armée de l'air"
            },
            {
                "value": 3771,
                "label": "Airville",
                "label_ar": "ايرفيل",
                "label_fr": "Airville"
            },
            {
                "value": 3772,
                "label": "Airway Heights",
                "label_ar": "مرتفعات الخطوط الجوية",
                "label_fr": "Hauteurs des voies respiratoires"
            },
            {
                "value": 3773,
                "label": "Aitkin",
                "label_ar": "أيتكين",
                "label_fr": "Aitkin"
            },
            {
                "value": 3774,
                "label": "Ajo",
                "label_ar": "آجو",
                "label_fr": "Ajo"
            },
            {
                "value": 3775,
                "label": "Akaska",
                "label_ar": "أكاسكا",
                "label_fr": "Akaska"
            },
            {
                "value": 3776,
                "label": "Akeley",
                "label_ar": "أكيلي",
                "label_fr": "Akeley"
            },
            {
                "value": 3777,
                "label": "Akhiok",
                "label_ar": "أخيوك",
                "label_fr": "Akhiok"
            },
            {
                "value": 3778,
                "label": "Akiachak",
                "label_ar": "أكياشاك",
                "label_fr": "Akiachak"
            },
            {
                "value": 3779,
                "label": "Akiak",
                "label_ar": "اكياك",
                "label_fr": "Akiak"
            },
            {
                "value": 3780,
                "label": "Akron",
                "label_ar": "أكرون",
                "label_fr": "Akron"
            },
            {
                "value": 3781,
                "label": "Akutan",
                "label_ar": "أكوتان",
                "label_fr": "Akutan"
            },
            {
                "value": 3782,
                "label": "Alabaster",
                "label_ar": "المرمر",
                "label_fr": "Albâtre"
            },
            {
                "value": 3783,
                "label": "Alachua",
                "label_ar": "ألاتشوا",
                "label_fr": "Alachua"
            },
            {
                "value": 3784,
                "label": "Alakanuk",
                "label_ar": "الاكانوك",
                "label_fr": "Alakanuk"
            },
            {
                "value": 3785,
                "label": "Alamance",
                "label_ar": "ألمانس",
                "label_fr": "Alamance"
            },
            {
                "value": 3786,
                "label": "Alameda",
                "label_ar": "ألاميدا",
                "label_fr": "Alameda"
            },
            {
                "value": 3787,
                "label": "Alamo",
                "label_ar": "ألامو",
                "label_fr": "Alamo"
            },
            {
                "value": 3788,
                "label": "Alamogordo",
                "label_ar": "الاموغوردو",
                "label_fr": "Alamogordo"
            },
            {
                "value": 3789,
                "label": "Alamosa",
                "label_ar": "ألاموزا",
                "label_fr": "Alamosa"
            },
            {
                "value": 3790,
                "label": "Alanson",
                "label_ar": "الانسون",
                "label_fr": "Alanson"
            },
            {
                "value": 3791,
                "label": "Alapaha",
                "label_ar": "الابها",
                "label_fr": "Alapaha"
            },
            {
                "value": 3792,
                "label": "Alba",
                "label_ar": "ألبا",
                "label_fr": "Alba"
            },
            {
                "value": 3793,
                "label": "Albany",
                "label_ar": "ألباني",
                "label_fr": "Albany"
            },
            {
                "value": 3794,
                "label": "Albemarle",
                "label_ar": "ألبيمارل",
                "label_fr": "Albemarle"
            },
            {
                "value": 3795,
                "label": "Albers",
                "label_ar": "ألبرز",
                "label_fr": "Albers"
            },
            {
                "value": 3796,
                "label": "Albert City",
                "label_ar": "مدينة ألبرت",
                "label_fr": "Albert City"
            },
            {
                "value": 3797,
                "label": "Albert Lea",
                "label_ar": "ألبرت ليا",
                "label_fr": "Albert Lea"
            },
            {
                "value": 3798,
                "label": "Alberton",
                "label_ar": "ألبرتون",
                "label_fr": "Alberton"
            },
            {
                "value": 3799,
                "label": "Albertson",
                "label_ar": "ألبرتسون",
                "label_fr": "Albertson"
            },
            {
                "value": 3800,
                "label": "Albertville",
                "label_ar": "ألبرتفيل",
                "label_fr": "Albertville"
            },
            {
                "value": 3801,
                "label": "Albia",
                "label_ar": "ألبيا",
                "label_fr": "Albia"
            },
            {
                "value": 3802,
                "label": "Albin",
                "label_ar": "ألبين",
                "label_fr": "Albin"
            },
            {
                "value": 3803,
                "label": "Albion",
                "label_ar": "ألبيون",
                "label_fr": "Albion"
            },
            {
                "value": 3804,
                "label": "Alborn",
                "label_ar": "ألبورن",
                "label_fr": "Alborn"
            },
            {
                "value": 3805,
                "label": "Albright",
                "label_ar": "أولبرايت",
                "label_fr": "Albright"
            },
            {
                "value": 3806,
                "label": "Albrightsville",
                "label_ar": "ألبرايتسفيل",
                "label_fr": "Albrightsville"
            },
            {
                "value": 3807,
                "label": "Albuquerque",
                "label_ar": "البوكيرك",
                "label_fr": "Albuquerque"
            },
            {
                "value": 3808,
                "label": "Alburg",
                "label_ar": "ألبورج",
                "label_fr": "Alburg"
            },
            {
                "value": 3809,
                "label": "Alburnett",
                "label_ar": "البورنيت",
                "label_fr": "Alburnett"
            },
            {
                "value": 3810,
                "label": "Alburtis",
                "label_ar": "ألبورتيس",
                "label_fr": "Alburtis"
            },
            {
                "value": 3811,
                "label": "Alcalde",
                "label_ar": "الكالدي",
                "label_fr": "Alcalde"
            },
            {
                "value": 3812,
                "label": "Alcester",
                "label_ar": "السيستر",
                "label_fr": "Alcester"
            },
            {
                "value": 3813,
                "label": "Alcoa",
                "label_ar": "الكوا",
                "label_fr": "Alcoa"
            },
            {
                "value": 3814,
                "label": "Alcolu",
                "label_ar": "ألكولو",
                "label_fr": "Alcolu"
            },
            {
                "value": 3815,
                "label": "Alda",
                "label_ar": "Alda",
                "label_fr": "Alda"
            },
            {
                "value": 3816,
                "label": "Alden",
                "label_ar": "ألدن",
                "label_fr": "Alden"
            },
            {
                "value": 3817,
                "label": "Alderpoint",
                "label_ar": "ألدربوينت",
                "label_fr": "Alderpoint"
            },
            {
                "value": 3818,
                "label": "Alderson",
                "label_ar": "ألدرسون",
                "label_fr": "Alderson"
            },
            {
                "value": 3819,
                "label": "Aldie",
                "label_ar": "ألدي",
                "label_fr": "Aldie"
            },
            {
                "value": 3820,
                "label": "Aldrich",
                "label_ar": "ألدريتش",
                "label_fr": "Aldrich"
            },
            {
                "value": 3821,
                "label": "Aledo",
                "label_ar": "أليدو",
                "label_fr": "Aledo"
            },
            {
                "value": 3822,
                "label": "Alex",
                "label_ar": "اليكس",
                "label_fr": "Alex"
            },
            {
                "value": 3823,
                "label": "Alexander",
                "label_ar": "الكسندر",
                "label_fr": "Alexandre"
            },
            {
                "value": 3824,
                "label": "Alexander City",
                "label_ar": "مدينة الكسندر",
                "label_fr": "Alexander City"
            },
            {
                "value": 3825,
                "label": "Alexandria",
                "label_ar": "الإسكندرية",
                "label_fr": "Alexandrie"
            },
            {
                "value": 3826,
                "label": "Alexandria Bay",
                "label_ar": "الإسكندرية باي",
                "label_fr": "Baie d'Alexandrie"
            },
            {
                "value": 3827,
                "label": "Alexis",
                "label_ar": "الكسيس",
                "label_fr": "Alexis"
            },
            {
                "value": 3828,
                "label": "Alford",
                "label_ar": "ألفورد",
                "label_fr": "Alford"
            },
            {
                "value": 3829,
                "label": "Alfred",
                "label_ar": "ألفريد",
                "label_fr": "Alfred"
            },
            {
                "value": 3830,
                "label": "Alfred Station",
                "label_ar": "محطة ألفريد",
                "label_fr": "Gare d'Alfred"
            },
            {
                "value": 3831,
                "label": "Alger",
                "label_ar": "الجزائر",
                "label_fr": "Alger"
            },
            {
                "value": 3832,
                "label": "Algodones",
                "label_ar": "الجودونيس",
                "label_fr": "Algodones"
            },
            {
                "value": 3833,
                "label": "Algoma",
                "label_ar": "ألغوما",
                "label_fr": "Algoma"
            },
            {
                "value": 3834,
                "label": "Algona",
                "label_ar": "ألغونا",
                "label_fr": "Algona"
            },
            {
                "value": 3835,
                "label": "Algonac",
                "label_ar": "ألغوناك",
                "label_fr": "Algonac"
            },
            {
                "value": 3836,
                "label": "Algonquin",
                "label_ar": "ألجونكوين",
                "label_fr": "Algonquin"
            },
            {
                "value": 3837,
                "label": "Alhambra",
                "label_ar": "قصر الحمراء",
                "label_fr": "Alhambra"
            },
            {
                "value": 3838,
                "label": "Alice",
                "label_ar": "أليس",
                "label_fr": "Alice"
            },
            {
                "value": 3839,
                "label": "Aliceville",
                "label_ar": "اليسفيل",
                "label_fr": "Aliceville"
            },
            {
                "value": 3840,
                "label": "Aline",
                "label_ar": "ألين",
                "label_fr": "Une ligne"
            },
            {
                "value": 3841,
                "label": "Aliquippa",
                "label_ar": "أليكويبا",
                "label_fr": "Aliquippa"
            },
            {
                "value": 3842,
                "label": "Aliso Viejo",
                "label_ar": "أليسو فيجو",
                "label_fr": "Aliso Viejo"
            },
            {
                "value": 3843,
                "label": "Alkol",
                "label_ar": "الكول",
                "label_fr": "Alkol"
            },
            {
                "value": 3844,
                "label": "Allamuchy",
                "label_ar": "اللاموشى",
                "label_fr": "Allamuchy"
            },
            {
                "value": 3845,
                "label": "Allardt",
                "label_ar": "اللاردت",
                "label_fr": "Allardt"
            },
            {
                "value": 3846,
                "label": "Allegan",
                "label_ar": "أليجان",
                "label_fr": "Allegan"
            },
            {
                "value": 3847,
                "label": "Allegany",
                "label_ar": "أليجاني",
                "label_fr": "Allegany"
            },
            {
                "value": 3848,
                "label": "Allen",
                "label_ar": "ألين",
                "label_fr": "Allen"
            },
            {
                "value": 3849,
                "label": "Allen Park",
                "label_ar": "ألين بارك",
                "label_fr": "Parc Allen"
            },
            {
                "value": 3850,
                "label": "Allendale",
                "label_ar": "اللينديل",
                "label_fr": "Allendale"
            },
            {
                "value": 3851,
                "label": "Allenhurst",
                "label_ar": "ألينهورست",
                "label_fr": "Allenhurst"
            },
            {
                "value": 3852,
                "label": "Allenport",
                "label_ar": "الينبورت",
                "label_fr": "Allenport"
            },
            {
                "value": 3853,
                "label": "Allenspark",
                "label_ar": "ألينسبارك",
                "label_fr": "Allenspark"
            },
            {
                "value": 3854,
                "label": "Allenton",
                "label_ar": "ألينتون",
                "label_fr": "Allenton"
            },
            {
                "value": 3855,
                "label": "Allentown",
                "label_ar": "الينتاون",
                "label_fr": "Allentown"
            },
            {
                "value": 3856,
                "label": "Allenwood",
                "label_ar": "ألينوود",
                "label_fr": "Allenwood"
            },
            {
                "value": 3857,
                "label": "Allerton",
                "label_ar": "أليرتون",
                "label_fr": "Allerton"
            },
            {
                "value": 3858,
                "label": "Alliance",
                "label_ar": "تحالف",
                "label_fr": "Alliance"
            },
            {
                "value": 3859,
                "label": "Allison",
                "label_ar": "أليسون",
                "label_fr": "Allison"
            },
            {
                "value": 3860,
                "label": "Allison Park",
                "label_ar": "أليسون بارك",
                "label_fr": "Parc Allison"
            },
            {
                "value": 3861,
                "label": "Allons",
                "label_ar": "ألونس",
                "label_fr": "Allons"
            },
            {
                "value": 3862,
                "label": "Alloway",
                "label_ar": "بدل",
                "label_fr": "Alloway"
            },
            {
                "value": 3863,
                "label": "Allston",
                "label_ar": "ألستون",
                "label_fr": "Allston"
            },
            {
                "value": 3864,
                "label": "Allyn",
                "label_ar": "ألين",
                "label_fr": "Allyn"
            },
            {
                "value": 3865,
                "label": "Alma",
                "label_ar": "ألما",
                "label_fr": "Alma"
            },
            {
                "value": 3866,
                "label": "Alma Center",
                "label_ar": "مركز ألما",
                "label_fr": "Centre Alma"
            },
            {
                "value": 3867,
                "label": "Almena",
                "label_ar": "ألمينا",
                "label_fr": "Almena"
            },
            {
                "value": 3868,
                "label": "Almira",
                "label_ar": "الميرا",
                "label_fr": "Almira"
            },
            {
                "value": 3869,
                "label": "Almo",
                "label_ar": "ألمو",
                "label_fr": "Almo"
            },
            {
                "value": 3870,
                "label": "Almond",
                "label_ar": "اللوز",
                "label_fr": "Amande"
            },
            {
                "value": 3871,
                "label": "Almont",
                "label_ar": "ألمونت",
                "label_fr": "Almont"
            },
            {
                "value": 3872,
                "label": "Alna",
                "label_ar": "ألنا",
                "label_fr": "Alna"
            },
            {
                "value": 3873,
                "label": "Alpaugh",
                "label_ar": "ألبو",
                "label_fr": "Alpaugh"
            },
            {
                "value": 3874,
                "label": "Alpena",
                "label_ar": "ألبينا",
                "label_fr": "Alpena"
            },
            {
                "value": 3875,
                "label": "Alpha",
                "label_ar": "ألفا",
                "label_fr": "Alpha"
            },
            {
                "value": 3876,
                "label": "Alpharetta",
                "label_ar": "ألفاريتا",
                "label_fr": "Alpharetta"
            },
            {
                "value": 3877,
                "label": "Alpine",
                "label_ar": "جبال الألب",
                "label_fr": "Alpin"
            },
            {
                "value": 3878,
                "label": "Alplaus",
                "label_ar": "ألبلاوس",
                "label_fr": "Alplaus"
            },
            {
                "value": 3879,
                "label": "Alsea",
                "label_ar": "السي",
                "label_fr": "Alsea"
            },
            {
                "value": 3880,
                "label": "Alsey",
                "label_ar": "ألسي",
                "label_fr": "Alsey"
            },
            {
                "value": 3881,
                "label": "Alsip",
                "label_ar": "السيب",
                "label_fr": "Alsip"
            },
            {
                "value": 3882,
                "label": "Alstead",
                "label_ar": "ألستيد",
                "label_fr": "Alstead"
            },
            {
                "value": 3883,
                "label": "Alta",
                "label_ar": "ألتا",
                "label_fr": "Alta"
            },
            {
                "value": 3884,
                "label": "Alta Vista",
                "label_ar": "ألتا فيستا",
                "label_fr": "Alta Vista"
            },
            {
                "value": 3885,
                "label": "Altadena",
                "label_ar": "ألتادينا",
                "label_fr": "Altadena"
            },
            {
                "value": 3886,
                "label": "Altamont",
                "label_ar": "ألتامونت",
                "label_fr": "Altamont"
            },
            {
                "value": 3887,
                "label": "Altamonte Springs",
                "label_ar": "ألتامونتي سبرينغز",
                "label_fr": "Altamonte Springs"
            },
            {
                "value": 3888,
                "label": "Altaville",
                "label_ar": "التافيل",
                "label_fr": "Altaville"
            },
            {
                "value": 3889,
                "label": "Altavista",
                "label_ar": "ألتافيستا",
                "label_fr": "Altavista"
            },
            {
                "value": 3890,
                "label": "Altenburg",
                "label_ar": "ألتنبرغ",
                "label_fr": "Altenburg"
            },
            {
                "value": 3891,
                "label": "Altha",
                "label_ar": "ألثا",
                "label_fr": "Altha"
            },
            {
                "value": 3892,
                "label": "Altmar",
                "label_ar": "التمار",
                "label_fr": "Altmar"
            },
            {
                "value": 3893,
                "label": "Alto",
                "label_ar": "ألتو",
                "label_fr": "Alto"
            },
            {
                "value": 3894,
                "label": "Alton",
                "label_ar": "ألتون",
                "label_fr": "Alton"
            },
            {
                "value": 3895,
                "label": "Alton Bay",
                "label_ar": "ألتون باي",
                "label_fr": "Alton Bay"
            },
            {
                "value": 3896,
                "label": "Altona",
                "label_ar": "التونا",
                "label_fr": "Altona"
            },
            {
                "value": 3897,
                "label": "Altoona",
                "label_ar": "ألتونا",
                "label_fr": "Altoona"
            },
            {
                "value": 3898,
                "label": "Altura",
                "label_ar": "ألتورا",
                "label_fr": "Altura"
            },
            {
                "value": 3899,
                "label": "Alturas",
                "label_ar": "ألتوراس",
                "label_fr": "Alturas"
            },
            {
                "value": 3900,
                "label": "Altus",
                "label_ar": "ألتوس",
                "label_fr": "Altus"
            },
            {
                "value": 3901,
                "label": "Alum Bank",
                "label_ar": "بنك الشب",
                "label_fr": "Banque d'alun"
            },
            {
                "value": 3902,
                "label": "Alum Creek",
                "label_ar": "ألوم كريك",
                "label_fr": "Ruisseau Alum"
            },
            {
                "value": 3903,
                "label": "Alva",
                "label_ar": "ألفا",
                "label_fr": "Alva"
            },
            {
                "value": 3904,
                "label": "Alvada",
                "label_ar": "الفادا",
                "label_fr": "Alvada"
            },
            {
                "value": 3905,
                "label": "Alvarado",
                "label_ar": "الفارادو",
                "label_fr": "Alvarado"
            },
            {
                "value": 3906,
                "label": "Alvaton",
                "label_ar": "ألفاتون",
                "label_fr": "Alvaton"
            },
            {
                "value": 3907,
                "label": "Alverda",
                "label_ar": "ألفيردا",
                "label_fr": "Alverda"
            },
            {
                "value": 3908,
                "label": "Alverton",
                "label_ar": "ألفيرتون",
                "label_fr": "Alverton"
            },
            {
                "value": 3909,
                "label": "Alvin",
                "label_ar": "ألفين",
                "label_fr": "Alvin"
            },
            {
                "value": 3910,
                "label": "Alviso",
                "label_ar": "ألفيسو",
                "label_fr": "Alviso"
            },
            {
                "value": 3911,
                "label": "Alvo",
                "label_ar": "ألفو",
                "label_fr": "Alvo"
            },
            {
                "value": 3912,
                "label": "Alvord",
                "label_ar": "ألفورد",
                "label_fr": "Alvord"
            },
            {
                "value": 3913,
                "label": "Alvordton",
                "label_ar": "ألفوردتون",
                "label_fr": "Alvordton"
            },
            {
                "value": 3914,
                "label": "Ama",
                "label_ar": "أما",
                "label_fr": "Ama"
            },
            {
                "value": 3915,
                "label": "Amagansett",
                "label_ar": "أماجانسيت",
                "label_fr": "Amagansett"
            },
            {
                "value": 3916,
                "label": "Amana",
                "label_ar": "امانة",
                "label_fr": "Amana"
            },
            {
                "value": 3917,
                "label": "Amanda",
                "label_ar": "أماندا",
                "label_fr": "Amanda"
            },
            {
                "value": 3918,
                "label": "Amargosa Valley",
                "label_ar": "وادي أمارجوسا",
                "label_fr": "Vallée d'Amargosa"
            },
            {
                "value": 3919,
                "label": "Amarillo",
                "label_ar": "أماريلو",
                "label_fr": "Amarillo"
            },
            {
                "value": 3920,
                "label": "Amawalk",
                "label_ar": "أماوالك",
                "label_fr": "Amawalk"
            },
            {
                "value": 3921,
                "label": "Amazonia",
                "label_ar": "الأمازون",
                "label_fr": "Amazonie"
            },
            {
                "value": 3922,
                "label": "Amberg",
                "label_ar": "أمبرج",
                "label_fr": "Amberg"
            },
            {
                "value": 3923,
                "label": "Ambler",
                "label_ar": "أمبلر",
                "label_fr": "Ambler"
            },
            {
                "value": 3924,
                "label": "Amboy",
                "label_ar": "امبوي",
                "label_fr": "Amboy"
            },
            {
                "value": 3925,
                "label": "Ambridge",
                "label_ar": "أمبريدج",
                "label_fr": "Ambridge"
            },
            {
                "value": 3926,
                "label": "Ambrose",
                "label_ar": "أمبروز",
                "label_fr": "Ambrose"
            },
            {
                "value": 3927,
                "label": "Amelia",
                "label_ar": "اميليا",
                "label_fr": "Amelia"
            },
            {
                "value": 3928,
                "label": "Amelia Court House",
                "label_ar": "بيت محكمة أميليا",
                "label_fr": "Palais de justice d'Amelia"
            },
            {
                "value": 3929,
                "label": "Amenia",
                "label_ar": "أمينيا",
                "label_fr": "Amenia"
            },
            {
                "value": 3930,
                "label": "America",
                "label_ar": "أمريكا",
                "label_fr": "Amérique"
            },
            {
                "value": 3931,
                "label": "American Canyon",
                "label_ar": "أمريكان كانيون",
                "label_fr": "American Canyon"
            },
            {
                "value": 3932,
                "label": "American Falls",
                "label_ar": "الشلالات الأمريكية",
                "label_fr": "American Falls"
            },
            {
                "value": 3933,
                "label": "American Fork",
                "label_ar": "أمريكان فورك",
                "label_fr": "Fourchette américaine"
            },
            {
                "value": 3934,
                "label": "Americus",
                "label_ar": "أميريكوس",
                "label_fr": "Americus"
            },
            {
                "value": 3935,
                "label": "Amery",
                "label_ar": "العامري",
                "label_fr": "Amery"
            },
            {
                "value": 3936,
                "label": "Ames",
                "label_ar": "أميس",
                "label_fr": "Ames"
            },
            {
                "value": 3937,
                "label": "Amesbury",
                "label_ar": "أميسبوري",
                "label_fr": "Amesbury"
            },
            {
                "value": 3938,
                "label": "Amesville",
                "label_ar": "أميسفيل",
                "label_fr": "Amesville"
            },
            {
                "value": 3939,
                "label": "Amherst",
                "label_ar": "امهيرست",
                "label_fr": "Amherst"
            },
            {
                "value": 3940,
                "label": "Amherst Junction",
                "label_ar": "امهيرست مفرق",
                "label_fr": "Jonction d'Amherst"
            },
            {
                "value": 3941,
                "label": "Amherstdale",
                "label_ar": "امهيرستدال",
                "label_fr": "Amherstdale"
            },
            {
                "value": 3942,
                "label": "Amidon",
                "label_ar": "أميدون",
                "label_fr": "Amidon"
            },
            {
                "value": 3943,
                "label": "Amissville",
                "label_ar": "أميسفيل",
                "label_fr": "Amissville"
            },
            {
                "value": 3944,
                "label": "Amite",
                "label_ar": "عميت",
                "label_fr": "Amite"
            },
            {
                "value": 3945,
                "label": "Amity",
                "label_ar": "الصداقة",
                "label_fr": "Amitié"
            },
            {
                "value": 3946,
                "label": "Amityville",
                "label_ar": "أميتيفيل",
                "label_fr": "Amityville"
            },
            {
                "value": 3947,
                "label": "Ammon",
                "label_ar": "عمون",
                "label_fr": "Ammon"
            },
            {
                "value": 3948,
                "label": "Amo",
                "label_ar": "عمو",
                "label_fr": "Amo"
            },
            {
                "value": 3949,
                "label": "Amoret",
                "label_ar": "امورة",
                "label_fr": "Amoret"
            },
            {
                "value": 3950,
                "label": "Amory",
                "label_ar": "أموري",
                "label_fr": "Amory"
            },
            {
                "value": 3951,
                "label": "Amsterdam",
                "label_ar": "أمستردام",
                "label_fr": "Amsterdam"
            },
            {
                "value": 3952,
                "label": "Amston",
                "label_ar": "أمستون",
                "label_fr": "Amston"
            },
            {
                "value": 3953,
                "label": "Anacoco",
                "label_ar": "أناكوكو",
                "label_fr": "Anacoco"
            },
            {
                "value": 3954,
                "label": "Anaconda",
                "label_ar": "اناكوندا",
                "label_fr": "Anaconda"
            },
            {
                "value": 3955,
                "label": "Anacortes",
                "label_ar": "أناكورتس",
                "label_fr": "Anacortes"
            },
            {
                "value": 3956,
                "label": "Anadarko",
                "label_ar": "أناداركو",
                "label_fr": "Anadarko"
            },
            {
                "value": 3957,
                "label": "Anaheim",
                "label_ar": "أنهايم",
                "label_fr": "Anaheim"
            },
            {
                "value": 3958,
                "label": "Anahola",
                "label_ar": "أناهولا",
                "label_fr": "Anahola"
            },
            {
                "value": 3959,
                "label": "Anahuac",
                "label_ar": "أناهواك",
                "label_fr": "Anahuac"
            },
            {
                "value": 3960,
                "label": "Anamosa",
                "label_ar": "أناموسا",
                "label_fr": "Anamosa"
            },
            {
                "value": 3961,
                "label": "Anchor Point",
                "label_ar": "مرساة نقطة",
                "label_fr": "Point d'ancrage"
            },
            {
                "value": 3962,
                "label": "Anchorage",
                "label_ar": "مرسى",
                "label_fr": "Ancrage"
            },
            {
                "value": 3963,
                "label": "Ancona",
                "label_ar": "أنكونا",
                "label_fr": "Ancône"
            },
            {
                "value": 3964,
                "label": "Andale",
                "label_ar": "أندال",
                "label_fr": "Andale"
            },
            {
                "value": 3965,
                "label": "Andalusia",
                "label_ar": "الأندلس",
                "label_fr": "Andalousie"
            },
            {
                "value": 3966,
                "label": "Anderson",
                "label_ar": "أندرسون",
                "label_fr": "Anderson"
            },
            {
                "value": 3967,
                "label": "Andersonville",
                "label_ar": "أندرسونفيل",
                "label_fr": "Andersonville"
            },
            {
                "value": 3968,
                "label": "Andes",
                "label_ar": "جبال الأنديز",
                "label_fr": "Andes"
            },
            {
                "value": 3969,
                "label": "Andover",
                "label_ar": "وأكثر",
                "label_fr": "Et plus"
            },
            {
                "value": 3970,
                "label": "Andreas",
                "label_ar": "أندرياس",
                "label_fr": "Andreas"
            },
            {
                "value": 3971,
                "label": "Andrew",
                "label_ar": "أندرو",
                "label_fr": "Andrew"
            },
            {
                "value": 3972,
                "label": "Andrews",
                "label_ar": "أندروز",
                "label_fr": "Andrews"
            },
            {
                "value": 3973,
                "label": "Andrews Air Force Base",
                "label_ar": "قاعدة أندروز الجوية",
                "label_fr": "Base aérienne d'Andrews"
            },
            {
                "value": 3974,
                "label": "Angel Fire",
                "label_ar": "ملاك النار",
                "label_fr": "ange de feu"
            },
            {
                "value": 3975,
                "label": "Angelica",
                "label_ar": "انجليكا",
                "label_fr": "Angélique"
            },
            {
                "value": 3976,
                "label": "Angie",
                "label_ar": "انجي",
                "label_fr": "Angie"
            },
            {
                "value": 3977,
                "label": "Angier",
                "label_ar": "أنجير",
                "label_fr": "Angier"
            },
            {
                "value": 3978,
                "label": "Angle Inlet",
                "label_ar": "مدخل الزاوية",
                "label_fr": "Entrée d'angle"
            },
            {
                "value": 3979,
                "label": "Angleton",
                "label_ar": "أنجلتون",
                "label_fr": "Angleton"
            },
            {
                "value": 3980,
                "label": "Angola",
                "label_ar": "أنغولا",
                "label_fr": "Angola"
            },
            {
                "value": 3981,
                "label": "Angoon",
                "label_ar": "انجون",
                "label_fr": "Angoon"
            },
            {
                "value": 3982,
                "label": "Angora",
                "label_ar": "الأنجورا",
                "label_fr": "Angora"
            },
            {
                "value": 3983,
                "label": "Anguilla",
                "label_ar": "أنغيلا",
                "label_fr": "Anguilla"
            },
            {
                "value": 3984,
                "label": "Angwin",
                "label_ar": "أنجوين",
                "label_fr": "Angwin"
            },
            {
                "value": 3985,
                "label": "Animas",
                "label_ar": "أنيماس",
                "label_fr": "Animas"
            },
            {
                "value": 3986,
                "label": "Anita",
                "label_ar": "أنيتا",
                "label_fr": "Anita"
            },
            {
                "value": 3987,
                "label": "Aniwa",
                "label_ar": "أنيوا",
                "label_fr": "Aniwa"
            },
            {
                "value": 3988,
                "label": "Ankeny",
                "label_ar": "أنكيني",
                "label_fr": "Ankeny"
            },
            {
                "value": 3989,
                "label": "Anmoore",
                "label_ar": "أنمور",
                "label_fr": "Anmoore"
            },
            {
                "value": 3990,
                "label": "Ann Arbor",
                "label_ar": "آن أربور",
                "label_fr": "Ann Arbor"
            },
            {
                "value": 3991,
                "label": "Anna",
                "label_ar": "آنا",
                "label_fr": "Anna"
            },
            {
                "value": 3992,
                "label": "Annabella",
                "label_ar": "انابيلا",
                "label_fr": "Annabella"
            },
            {
                "value": 3993,
                "label": "Annandale",
                "label_ar": "أنانديل",
                "label_fr": "Annandale"
            },
            {
                "value": 3994,
                "label": "Annandale-on-Hudson",
                "label_ar": "أنانديل أون هدسون",
                "label_fr": "Annandale-sur-Hudson"
            },
            {
                "value": 3995,
                "label": "Annapolis",
                "label_ar": "أنابوليس",
                "label_fr": "Annapolis"
            },
            {
                "value": 3996,
                "label": "Annawan",
                "label_ar": "عنوان",
                "label_fr": "Annawan"
            },
            {
                "value": 3997,
                "label": "Anniston",
                "label_ar": "أنيستون",
                "label_fr": "Anniston"
            },
            {
                "value": 3998,
                "label": "Annona",
                "label_ar": "أنونا",
                "label_fr": "Annona"
            },
            {
                "value": 3999,
                "label": "Annville",
                "label_ar": "أنفيل",
                "label_fr": "Annville"
            },
            {
                "value": 4000,
                "label": "Anoka",
                "label_ar": "أنوكا",
                "label_fr": "Anoka"
            },
            {
                "value": 4001,
                "label": "Anselmo",
                "label_ar": "أنسيلمو",
                "label_fr": "Anselmo"
            },
            {
                "value": 4002,
                "label": "Ansley",
                "label_ar": "أنسلي",
                "label_fr": "Ansley"
            },
            {
                "value": 4003,
                "label": "Anson",
                "label_ar": "أنسون",
                "label_fr": "Anson"
            },
            {
                "value": 4004,
                "label": "Ansonia",
                "label_ar": "أنسونيا",
                "label_fr": "Ansonia"
            },
            {
                "value": 4005,
                "label": "Ansted",
                "label_ar": "أنستيد",
                "label_fr": "Ansted"
            },
            {
                "value": 4006,
                "label": "Antelope",
                "label_ar": "الظباء",
                "label_fr": "Antilope"
            },
            {
                "value": 4007,
                "label": "Anthem",
                "label_ar": "نشيد وطني",
                "label_fr": "Hymne"
            },
            {
                "value": 4008,
                "label": "Anthon",
                "label_ar": "أنتون",
                "label_fr": "Anthon"
            },
            {
                "value": 4009,
                "label": "Anthony",
                "label_ar": "أنتوني",
                "label_fr": "Anthony"
            },
            {
                "value": 4010,
                "label": "Antigo",
                "label_ar": "أنتيجو",
                "label_fr": "Antigo"
            },
            {
                "value": 4011,
                "label": "Antioch",
                "label_ar": "أنطاكية",
                "label_fr": "Antioche"
            },
            {
                "value": 4012,
                "label": "Antlers",
                "label_ar": "قرون",
                "label_fr": "Bois"
            },
            {
                "value": 4013,
                "label": "Antrim",
                "label_ar": "أنتريم",
                "label_fr": "Antrim"
            },
            {
                "value": 4014,
                "label": "Antwerp",
                "label_ar": "أنتويرب",
                "label_fr": "Anvers"
            },
            {
                "value": 4015,
                "label": "Anza",
                "label_ar": "عنزة",
                "label_fr": "Anza"
            },
            {
                "value": 4016,
                "label": "Apache",
                "label_ar": "اباتشي",
                "label_fr": "Apache"
            },
            {
                "value": 4017,
                "label": "Apache Junction",
                "label_ar": "مفرق اباتشي",
                "label_fr": "Jonction Apache"
            },
            {
                "value": 4018,
                "label": "Apalachicola",
                "label_ar": "أبالاتشيكولا",
                "label_fr": "Apalachicola"
            },
            {
                "value": 4019,
                "label": "Apalachin",
                "label_ar": "أبالاتشين",
                "label_fr": "Apalachin"
            },
            {
                "value": 4020,
                "label": "Apex",
                "label_ar": "ذروة",
                "label_fr": "Sommet"
            },
            {
                "value": 4021,
                "label": "Apison",
                "label_ar": "أبيسون",
                "label_fr": "Apison"
            },
            {
                "value": 4022,
                "label": "Aplington",
                "label_ar": "أبلينجتون",
                "label_fr": "Aplington"
            },
            {
                "value": 4023,
                "label": "Apollo",
                "label_ar": "أبولو",
                "label_fr": "Apollon"
            },
            {
                "value": 4024,
                "label": "Apollo Beach",
                "label_ar": "شاطئ أبولو",
                "label_fr": "Apollo Beach"
            },
            {
                "value": 4025,
                "label": "Apopka",
                "label_ar": "أبوبكا",
                "label_fr": "Apopka"
            },
            {
                "value": 4026,
                "label": "Appalachia",
                "label_ar": "أبالاتشي",
                "label_fr": "Appalaches"
            },
            {
                "value": 4027,
                "label": "Apple Creek",
                "label_ar": "آبل كريك",
                "label_fr": "Apple Creek"
            },
            {
                "value": 4028,
                "label": "Apple River",
                "label_ar": "نهر التفاح",
                "label_fr": "Rivière Apple"
            },
            {
                "value": 4029,
                "label": "Apple Valley",
                "label_ar": "وادي التفاح",
                "label_fr": "Vallée d'Apple"
            },
            {
                "value": 4030,
                "label": "Applegate",
                "label_ar": "آبلجيت",
                "label_fr": "Applegate"
            },
            {
                "value": 4031,
                "label": "Appleton",
                "label_ar": "أبليتون",
                "label_fr": "Appleton"
            },
            {
                "value": 4032,
                "label": "Appleton City",
                "label_ar": "مدينة أبليتون",
                "label_fr": "Ville d'Appleton"
            },
            {
                "value": 4033,
                "label": "Appling",
                "label_ar": "تفاح",
                "label_fr": "Appling"
            },
            {
                "value": 4034,
                "label": "Appomattox",
                "label_ar": "أبوماتوكس",
                "label_fr": "Appomattox"
            },
            {
                "value": 4035,
                "label": "Aptos",
                "label_ar": "أبتوس",
                "label_fr": "Aptos"
            },
            {
                "value": 4036,
                "label": "Aquasco",
                "label_ar": "أكواسكو",
                "label_fr": "Aquasco"
            },
            {
                "value": 4037,
                "label": "Aquashicola",
                "label_ar": "أكواشيكولا",
                "label_fr": "Aquashicola"
            },
            {
                "value": 4038,
                "label": "Aquebogue",
                "label_ar": "Aquebogue",
                "label_fr": "Aquebogue"
            },
            {
                "value": 4039,
                "label": "Aquilla",
                "label_ar": "أكويلا",
                "label_fr": "Aquilla"
            },
            {
                "value": 4040,
                "label": "Arab",
                "label_ar": "عرب",
                "label_fr": "arabe"
            },
            {
                "value": 4041,
                "label": "Arabi",
                "label_ar": "عربي",
                "label_fr": "Arabi"
            },
            {
                "value": 4042,
                "label": "Aragon",
                "label_ar": "أراجون",
                "label_fr": "Aragon"
            },
            {
                "value": 4043,
                "label": "Aransas Pass",
                "label_ar": "ممر أرانساس",
                "label_fr": "Aransas Pass"
            },
            {
                "value": 4044,
                "label": "Arapaho",
                "label_ar": "أراباهو",
                "label_fr": "Arapaho"
            },
            {
                "value": 4045,
                "label": "Arapahoe",
                "label_ar": "أراباهو",
                "label_fr": "Arapahoe"
            },
            {
                "value": 4046,
                "label": "Ararat",
                "label_ar": "أرارات",
                "label_fr": "Ararat"
            },
            {
                "value": 4047,
                "label": "Arbela",
                "label_ar": "أربيلا",
                "label_fr": "Arbela"
            },
            {
                "value": 4048,
                "label": "Arbon",
                "label_ar": "اربون",
                "label_fr": "Arbon"
            },
            {
                "value": 4049,
                "label": "Arbovale",
                "label_ar": "أربوفال",
                "label_fr": "Arbovale"
            },
            {
                "value": 4050,
                "label": "Arbuckle",
                "label_ar": "آرباكل",
                "label_fr": "Arbuckle"
            },
            {
                "value": 4051,
                "label": "Arbyrd",
                "label_ar": "اربيرد",
                "label_fr": "Arbyrd"
            },
            {
                "value": 4052,
                "label": "Arcade",
                "label_ar": "ممر",
                "label_fr": "Arcade"
            },
            {
                "value": 4053,
                "label": "Arcadia",
                "label_ar": "أركاديا",
                "label_fr": "Arcadie"
            },
            {
                "value": 4054,
                "label": "Arcanum",
                "label_ar": "أركان",
                "label_fr": "Arcane"
            },
            {
                "value": 4055,
                "label": "Arcata",
                "label_ar": "أركاتا",
                "label_fr": "Arcata"
            },
            {
                "value": 4056,
                "label": "Archbald",
                "label_ar": "ارشبالد",
                "label_fr": "Archbald"
            },
            {
                "value": 4057,
                "label": "Archbold",
                "label_ar": "أرشبولد",
                "label_fr": "Archbold"
            },
            {
                "value": 4058,
                "label": "Archer",
                "label_ar": "آرتشر",
                "label_fr": "Archer"
            },
            {
                "value": 4059,
                "label": "Archer City",
                "label_ar": "مدينة آرتشر",
                "label_fr": "Archer City"
            },
            {
                "value": 4060,
                "label": "Archie",
                "label_ar": "ارشي",
                "label_fr": "Archie"
            },
            {
                "value": 4061,
                "label": "Arco",
                "label_ar": "أركو",
                "label_fr": "Arco"
            },
            {
                "value": 4062,
                "label": "Arcola",
                "label_ar": "اركولا",
                "label_fr": "Arcola"
            },
            {
                "value": 4063,
                "label": "Arden",
                "label_ar": "أردن",
                "label_fr": "Arden"
            },
            {
                "value": 4064,
                "label": "Ardmore",
                "label_ar": "أردمور",
                "label_fr": "Ardmore"
            },
            {
                "value": 4065,
                "label": "Ardsley",
                "label_ar": "أردسلي",
                "label_fr": "Ardsley"
            },
            {
                "value": 4066,
                "label": "Aredale",
                "label_ar": "Aredale",
                "label_fr": "Aredale"
            },
            {
                "value": 4067,
                "label": "Arena",
                "label_ar": "ارينا",
                "label_fr": "Arène"
            },
            {
                "value": 4068,
                "label": "Arenas Valley",
                "label_ar": "وادي أريناس",
                "label_fr": "Vallée des Arènes"
            },
            {
                "value": 4069,
                "label": "Arendtsville",
                "label_ar": "أرندتسفيل",
                "label_fr": "Arendtsville"
            },
            {
                "value": 4070,
                "label": "Arenzville",
                "label_ar": "ارينزفيل",
                "label_fr": "Arenzville"
            },
            {
                "value": 4071,
                "label": "Argenta",
                "label_ar": "أرجنتا",
                "label_fr": "Argenta"
            },
            {
                "value": 4072,
                "label": "Argillite",
                "label_ar": "أرجليت",
                "label_fr": "Argillite"
            },
            {
                "value": 4073,
                "label": "Argonia",
                "label_ar": "أرجونيا",
                "label_fr": "Argonie"
            },
            {
                "value": 4074,
                "label": "Argonne",
                "label_ar": "أرجون",
                "label_fr": "Argonne"
            },
            {
                "value": 4075,
                "label": "Argos",
                "label_ar": "أرغوس",
                "label_fr": "Argos"
            },
            {
                "value": 4076,
                "label": "Argusville",
                "label_ar": "أرجوسفيل",
                "label_fr": "Argusville"
            },
            {
                "value": 4077,
                "label": "Argyle",
                "label_ar": "أرجيل",
                "label_fr": "Argyle"
            },
            {
                "value": 4078,
                "label": "Ariel",
                "label_ar": "ارييل",
                "label_fr": "Ariel"
            },
            {
                "value": 4079,
                "label": "Arimo",
                "label_ar": "أريمو",
                "label_fr": "Arimo"
            },
            {
                "value": 4080,
                "label": "Arispe",
                "label_ar": "أريسبي",
                "label_fr": "Arispe"
            },
            {
                "value": 4081,
                "label": "Ariton",
                "label_ar": "اريتون",
                "label_fr": "Ariton"
            },
            {
                "value": 4082,
                "label": "Arivaca",
                "label_ar": "أريفاكا",
                "label_fr": "Arivaca"
            },
            {
                "value": 4083,
                "label": "Arizona City",
                "label_ar": "أريزونا سيتي",
                "label_fr": "Arizona City"
            },
            {
                "value": 4084,
                "label": "Arkadelphia",
                "label_ar": "أركدلفيا",
                "label_fr": "Arkadelphia"
            },
            {
                "value": 4085,
                "label": "Arkansas City",
                "label_ar": "أركنساس سيتي",
                "label_fr": "Arkansas City"
            },
            {
                "value": 4086,
                "label": "Arkdale",
                "label_ar": "اركديل",
                "label_fr": "Arkdale"
            },
            {
                "value": 4087,
                "label": "Arkoma",
                "label_ar": "أركوما",
                "label_fr": "Arkoma"
            },
            {
                "value": 4088,
                "label": "Arkville",
                "label_ar": "أركفيل",
                "label_fr": "Arkville"
            },
            {
                "value": 4089,
                "label": "Arlee",
                "label_ar": "أرلي",
                "label_fr": "Arlee"
            },
            {
                "value": 4090,
                "label": "Arley",
                "label_ar": "أرلي",
                "label_fr": "Arley"
            },
            {
                "value": 4091,
                "label": "Arlington",
                "label_ar": "أرلينغتون",
                "label_fr": "Arlington"
            },
            {
                "value": 4092,
                "label": "Arlington Heights",
                "label_ar": "أرلينغتون هايتس",
                "label_fr": "Arlington Heights"
            },
            {
                "value": 4093,
                "label": "Arma",
                "label_ar": "أرما",
                "label_fr": "Arma"
            },
            {
                "value": 4094,
                "label": "Armada",
                "label_ar": "أرمادا",
                "label_fr": "Armada"
            },
            {
                "value": 4095,
                "label": "Armagh",
                "label_ar": "ارماغ",
                "label_fr": "Armagh"
            },
            {
                "value": 4096,
                "label": "Armington",
                "label_ar": "ارمينغتون",
                "label_fr": "Armington"
            },
            {
                "value": 4097,
                "label": "Armona",
                "label_ar": "أرمونا",
                "label_fr": "Armona"
            },
            {
                "value": 4098,
                "label": "Armonk",
                "label_ar": "أرمونك",
                "label_fr": "Armonk"
            },
            {
                "value": 4099,
                "label": "Armour",
                "label_ar": "درع",
                "label_fr": "Armure"
            },
            {
                "value": 4100,
                "label": "Armstrong",
                "label_ar": "ارمسترونغ",
                "label_fr": "Armstrong"
            },
            {
                "value": 4101,
                "label": "Armuchee",
                "label_ar": "أرموشي",
                "label_fr": "Armuchee"
            },
            {
                "value": 4102,
                "label": "Arnaudville",
                "label_ar": "أرنوفيل",
                "label_fr": "Arnaudville"
            },
            {
                "value": 4103,
                "label": "Arnegard",
                "label_ar": "أرنيجارد",
                "label_fr": "Arnegard"
            },
            {
                "value": 4104,
                "label": "Arnett",
                "label_ar": "أرنيت",
                "label_fr": "Arnett"
            },
            {
                "value": 4105,
                "label": "Arnold",
                "label_ar": "أرنولد",
                "label_fr": "Arnold"
            },
            {
                "value": 4106,
                "label": "Arnoldsburg",
                "label_ar": "أرنولدسبورغ",
                "label_fr": "Arnoldsburg"
            },
            {
                "value": 4107,
                "label": "Arnoldsville",
                "label_ar": "أرنولدسفيل",
                "label_fr": "Arnoldsville"
            },
            {
                "value": 4108,
                "label": "Aroma Park",
                "label_ar": "اروما بارك",
                "label_fr": "Aroma Park"
            },
            {
                "value": 4109,
                "label": "Aromas",
                "label_ar": "العبير",
                "label_fr": "Arômes"
            },
            {
                "value": 4110,
                "label": "Aroostook Band of Micmac Trust Land",
                "label_ar": "فرقة Aroostook من Micmac Trust Land",
                "label_fr": "Aroostook Band of Micmac Trust Land"
            },
            {
                "value": 4111,
                "label": "Arp",
                "label_ar": "أرب",
                "label_fr": "Arp"
            },
            {
                "value": 4112,
                "label": "Arpin",
                "label_ar": "أربين",
                "label_fr": "Arpin"
            },
            {
                "value": 4113,
                "label": "Arriba",
                "label_ar": "أريبا",
                "label_fr": "Arriba"
            },
            {
                "value": 4114,
                "label": "Arrington",
                "label_ar": "ارينجتون",
                "label_fr": "Arrington"
            },
            {
                "value": 4115,
                "label": "Arrowsmith",
                "label_ar": "السهام",
                "label_fr": "Arrowsmith"
            },
            {
                "value": 4116,
                "label": "Arroyo Grande",
                "label_ar": "أرويو غراندي",
                "label_fr": "Arroyo Grande"
            },
            {
                "value": 4117,
                "label": "Arroyo Hondo",
                "label_ar": "أرويو هوندو",
                "label_fr": "Arroyo Hondo"
            },
            {
                "value": 4118,
                "label": "Arroyo Seco",
                "label_ar": "أرويو سيكو",
                "label_fr": "Arroyo Seco"
            },
            {
                "value": 4119,
                "label": "Artesia",
                "label_ar": "ارتيسيا",
                "label_fr": "Artesia"
            },
            {
                "value": 4120,
                "label": "Artesian",
                "label_ar": "ارتوازي",
                "label_fr": "Artésien"
            },
            {
                "value": 4121,
                "label": "Arthur",
                "label_ar": "آرثر",
                "label_fr": "Arthur"
            },
            {
                "value": 4122,
                "label": "Arthur City",
                "label_ar": "آرثر سيتي",
                "label_fr": "Arthur City"
            },
            {
                "value": 4123,
                "label": "Arvada",
                "label_ar": "ارفادا",
                "label_fr": "Arvada"
            },
            {
                "value": 4124,
                "label": "Arverne",
                "label_ar": "أرفيرن",
                "label_fr": "Arverne"
            },
            {
                "value": 4125,
                "label": "Arvin",
                "label_ar": "ارفين",
                "label_fr": "Arvin"
            },
            {
                "value": 4126,
                "label": "Arvonia",
                "label_ar": "ارفونيا",
                "label_fr": "Arvonia"
            },
            {
                "value": 4127,
                "label": "Ary",
                "label_ar": "آري",
                "label_fr": "Ary"
            },
            {
                "value": 4128,
                "label": "Asbury",
                "label_ar": "اسبوري",
                "label_fr": "Asbury"
            },
            {
                "value": 4129,
                "label": "Asbury Park",
                "label_ar": "اسبري بارك",
                "label_fr": "Asbury Park"
            },
            {
                "value": 4130,
                "label": "Ascutney",
                "label_ar": "اسكوتني",
                "label_fr": "Ascutney"
            },
            {
                "value": 4131,
                "label": "Ash",
                "label_ar": "رماد",
                "label_fr": "Cendre"
            },
            {
                "value": 4132,
                "label": "Ash Flat",
                "label_ar": "الرماد شقة",
                "label_fr": "Plat en frêne"
            },
            {
                "value": 4133,
                "label": "Ash Fork",
                "label_ar": "الرماد فورك",
                "label_fr": "Fourchette à cendres"
            },
            {
                "value": 4134,
                "label": "Ash Grove",
                "label_ar": "آش جروف",
                "label_fr": "Ash Grove"
            },
            {
                "value": 4135,
                "label": "Ash Hill",
                "label_ar": "الرماد هيل",
                "label_fr": "Ash Hill"
            },
            {
                "value": 4136,
                "label": "Ashaway",
                "label_ar": "أشواى",
                "label_fr": "Ashaway"
            },
            {
                "value": 4137,
                "label": "Ashburn",
                "label_ar": "أشبورن",
                "label_fr": "Ashburn"
            },
            {
                "value": 4138,
                "label": "Ashburnham",
                "label_ar": "أشبرنهام",
                "label_fr": "Ashburnham"
            },
            {
                "value": 4139,
                "label": "Ashby",
                "label_ar": "أشبي",
                "label_fr": "Ashby"
            },
            {
                "value": 4140,
                "label": "Ashcamp",
                "label_ar": "Ashcamp",
                "label_fr": "Ashcamp"
            },
            {
                "value": 4141,
                "label": "Ashdown",
                "label_ar": "أشداون",
                "label_fr": "Ashdown"
            },
            {
                "value": 4142,
                "label": "Asheboro",
                "label_ar": "أشيبورو",
                "label_fr": "Asheboro"
            },
            {
                "value": 4143,
                "label": "Asherton",
                "label_ar": "أشيرتون",
                "label_fr": "Asherton"
            },
            {
                "value": 4144,
                "label": "Asheville",
                "label_ar": "أشفيل",
                "label_fr": "Asheville"
            },
            {
                "value": 4145,
                "label": "Ashfield",
                "label_ar": "أشفيلد",
                "label_fr": "Ashfield"
            },
            {
                "value": 4146,
                "label": "Ashford",
                "label_ar": "أشفورد",
                "label_fr": "Ashford"
            },
            {
                "value": 4147,
                "label": "Ashkum",
                "label_ar": "أشكوم",
                "label_fr": "Ashkum"
            },
            {
                "value": 4148,
                "label": "Ashland",
                "label_ar": "أشلاند",
                "label_fr": "Ashland"
            },
            {
                "value": 4149,
                "label": "Ashland City",
                "label_ar": "مدينة أشلاند",
                "label_fr": "Ashland City"
            },
            {
                "value": 4150,
                "label": "Ashley",
                "label_ar": "اشلي",
                "label_fr": "Ashley"
            },
            {
                "value": 4151,
                "label": "Ashmore",
                "label_ar": "أشمور",
                "label_fr": "Ashmore"
            },
            {
                "value": 4152,
                "label": "Ashtabula",
                "label_ar": "أشتابولا",
                "label_fr": "Ashtabula"
            },
            {
                "value": 4153,
                "label": "Ashton",
                "label_ar": "أشتون",
                "label_fr": "Ashton"
            },
            {
                "value": 4154,
                "label": "Ashville",
                "label_ar": "أشفيل",
                "label_fr": "Ashville"
            },
            {
                "value": 4155,
                "label": "Askov",
                "label_ar": "اسكوف",
                "label_fr": "Askov"
            },
            {
                "value": 4156,
                "label": "Asotin",
                "label_ar": "أسوتين",
                "label_fr": "Asotin"
            },
            {
                "value": 4157,
                "label": "Aspen",
                "label_ar": "آسبن",
                "label_fr": "Tremble"
            },
            {
                "value": 4158,
                "label": "Aspermont",
                "label_ar": "أسبرمونت",
                "label_fr": "Aspermont"
            },
            {
                "value": 4159,
                "label": "Aspers",
                "label_ar": "أسبيرس",
                "label_fr": "Aspers"
            },
            {
                "value": 4160,
                "label": "Assaria",
                "label_ar": "أساريا",
                "label_fr": "Assaria"
            },
            {
                "value": 4161,
                "label": "Assonet",
                "label_ar": "أسونيت",
                "label_fr": "Assonet"
            },
            {
                "value": 4162,
                "label": "Assumption",
                "label_ar": "افتراض",
                "label_fr": "supposition"
            },
            {
                "value": 4163,
                "label": "Astatula",
                "label_ar": "أستاتولا",
                "label_fr": "Astatula"
            },
            {
                "value": 4164,
                "label": "Aston Arms",
                "label_ar": "أستون أرمز",
                "label_fr": "Aston Arms"
            },
            {
                "value": 4165,
                "label": "Astor",
                "label_ar": "أستور",
                "label_fr": "Astor"
            },
            {
                "value": 4166,
                "label": "Astoria",
                "label_ar": "أستوريا",
                "label_fr": "Astoria"
            },
            {
                "value": 4167,
                "label": "Atalissa",
                "label_ar": "أتاليسا",
                "label_fr": "Atalissa"
            },
            {
                "value": 4168,
                "label": "Atascadero",
                "label_ar": "أتاسكاديرو",
                "label_fr": "Atascadero"
            },
            {
                "value": 4169,
                "label": "Atascosa",
                "label_ar": "أتاسكوسا",
                "label_fr": "Atascosa"
            },
            {
                "value": 4170,
                "label": "Atchison",
                "label_ar": "أتشيسون",
                "label_fr": "Atchison"
            },
            {
                "value": 4171,
                "label": "Atco",
                "label_ar": "أتكو",
                "label_fr": "Atco"
            },
            {
                "value": 4172,
                "label": "Atglen",
                "label_ar": "اتجلين",
                "label_fr": "Atglen"
            },
            {
                "value": 4173,
                "label": "Athelstane",
                "label_ar": "أثلستان",
                "label_fr": "Athelstane"
            },
            {
                "value": 4174,
                "label": "Athena",
                "label_ar": "أثينا",
                "label_fr": "Athéna"
            },
            {
                "value": 4175,
                "label": "Athens",
                "label_ar": "أثينا",
                "label_fr": "Athènes"
            },
            {
                "value": 4176,
                "label": "Atherton",
                "label_ar": "أثرتون",
                "label_fr": "Atherton"
            },
            {
                "value": 4177,
                "label": "Athol",
                "label_ar": "أثول",
                "label_fr": "Athol"
            },
            {
                "value": 4178,
                "label": "Atkins",
                "label_ar": "اتكينز",
                "label_fr": "Atkins"
            },
            {
                "value": 4179,
                "label": "Atkinson",
                "label_ar": "أتكينسون",
                "label_fr": "Atkinson"
            },
            {
                "value": 4180,
                "label": "Atlanta",
                "label_ar": "أتلانتا",
                "label_fr": "Atlanta"
            },
            {
                "value": 4181,
                "label": "Atlantic",
                "label_ar": "الأطلسي",
                "label_fr": "atlantique"
            },
            {
                "value": 4182,
                "label": "Atlantic Beach",
                "label_ar": "أتلانتيك بيتش",
                "label_fr": "Plage de l'Atlantique"
            },
            {
                "value": 4183,
                "label": "Atlantic City",
                "label_ar": "اتلانتيك سيتي",
                "label_fr": "Atlantic City"
            },
            {
                "value": 4184,
                "label": "Atlantic Highlands",
                "label_ar": "مرتفعات الأطلسي",
                "label_fr": "Highlands de l'Atlantique"
            },
            {
                "value": 4185,
                "label": "Atlantic Mine",
                "label_ar": "منجم الأطلسي",
                "label_fr": "Mine de l'Atlantique"
            },
            {
                "value": 4186,
                "label": "Atlasburg",
                "label_ar": "أطلسبرغ",
                "label_fr": "Atlasburg"
            },
            {
                "value": 4187,
                "label": "Atmore",
                "label_ar": "في أكثر من ذلك",
                "label_fr": "Atmore"
            },
            {
                "value": 4188,
                "label": "Atoka",
                "label_ar": "أتوكا",
                "label_fr": "Atoka"
            },
            {
                "value": 4189,
                "label": "Atqasuk",
                "label_ar": "Atqasuk",
                "label_fr": "Atqasuk"
            },
            {
                "value": 4190,
                "label": "Attalla",
                "label_ar": "عطاالله",
                "label_fr": "Attalla"
            },
            {
                "value": 4191,
                "label": "Attapulgus",
                "label_ar": "أتابولجوس",
                "label_fr": "Attapulgus"
            },
            {
                "value": 4192,
                "label": "Attica",
                "label_ar": "أتيكا",
                "label_fr": "Attique"
            },
            {
                "value": 4193,
                "label": "Attleboro",
                "label_ar": "أتليبورو",
                "label_fr": "Attleboro"
            },
            {
                "value": 4194,
                "label": "Attleboro Falls",
                "label_ar": "شلالات أتلبورو",
                "label_fr": "Chutes d'Attleboro"
            },
            {
                "value": 4195,
                "label": "Atwater",
                "label_ar": "أتواتر",
                "label_fr": "Atwater"
            },
            {
                "value": 4196,
                "label": "Atwood",
                "label_ar": "أتوود",
                "label_fr": "Atwood"
            },
            {
                "value": 4197,
                "label": "Au Gres",
                "label_ar": "أو جريس",
                "label_fr": "Au Gres"
            },
            {
                "value": 4198,
                "label": "Au Sable Forks",
                "label_ar": "أو السمور فوركس",
                "label_fr": "Fourches Au Sable"
            },
            {
                "value": 4199,
                "label": "Au Train",
                "label_ar": "قطار Au",
                "label_fr": "Au Train"
            },
            {
                "value": 4200,
                "label": "Auberry",
                "label_ar": "أوبيري",
                "label_fr": "Auberry"
            },
            {
                "value": 4201,
                "label": "Aubrey",
                "label_ar": "أوبري",
                "label_fr": "Aubrey"
            },
            {
                "value": 4202,
                "label": "Auburn",
                "label_ar": "أوبورن",
                "label_fr": "Auburn"
            },
            {
                "value": 4203,
                "label": "Auburn Hills",
                "label_ar": "تلال أوبورن",
                "label_fr": "Auburn Hills"
            },
            {
                "value": 4204,
                "label": "Auburndale",
                "label_ar": "أوبورنديل",
                "label_fr": "Auburndale"
            },
            {
                "value": 4205,
                "label": "Audubon",
                "label_ar": "أودوبون",
                "label_fr": "Audubon"
            },
            {
                "value": 4206,
                "label": "Augusta",
                "label_ar": "أوغوستا",
                "label_fr": "Augusta"
            },
            {
                "value": 4207,
                "label": "Aulander",
                "label_ar": "أولاندر",
                "label_fr": "Aulander"
            },
            {
                "value": 4208,
                "label": "Ault",
                "label_ar": "أولت",
                "label_fr": "Ault"
            },
            {
                "value": 4209,
                "label": "Aumsville",
                "label_ar": "أومسفيل",
                "label_fr": "Aumsville"
            },
            {
                "value": 4210,
                "label": "Aurelia",
                "label_ar": "أوريليا",
                "label_fr": "Aurelia"
            },
            {
                "value": 4211,
                "label": "Aurora",
                "label_ar": "أورورا",
                "label_fr": "Aurore"
            },
            {
                "value": 4212,
                "label": "Austell",
                "label_ar": "أوستل",
                "label_fr": "Austell"
            },
            {
                "value": 4213,
                "label": "Austerlitz",
                "label_ar": "أوسترليتز",
                "label_fr": "Austerlitz"
            },
            {
                "value": 4214,
                "label": "Austin",
                "label_ar": "أوستين",
                "label_fr": "Austin"
            },
            {
                "value": 4215,
                "label": "Austinburg",
                "label_ar": "أوستنبرغ",
                "label_fr": "Austinburg"
            },
            {
                "value": 4216,
                "label": "Austinville",
                "label_ar": "أوستنفيل",
                "label_fr": "Austinville"
            },
            {
                "value": 4217,
                "label": "Autaugaville",
                "label_ar": "أوتوجافيل",
                "label_fr": "Autaugaville"
            },
            {
                "value": 4218,
                "label": "Autryville",
                "label_ar": "أوتريفيل",
                "label_fr": "Autryville"
            },
            {
                "value": 4219,
                "label": "Auxier",
                "label_ar": "أوكسير",
                "label_fr": "Auxier"
            },
            {
                "value": 4220,
                "label": "Auxvasse",
                "label_ar": "أوكسفاسي",
                "label_fr": "Auxvasse"
            },
            {
                "value": 4221,
                "label": "Ava",
                "label_ar": "افا",
                "label_fr": "Ava"
            },
            {
                "value": 4222,
                "label": "Avalon",
                "label_ar": "أفالون",
                "label_fr": "Avalon"
            },
            {
                "value": 4223,
                "label": "Avella",
                "label_ar": "أفيلا",
                "label_fr": "Avella"
            },
            {
                "value": 4224,
                "label": "Avenal",
                "label_ar": "أفينال",
                "label_fr": "Avenal"
            },
            {
                "value": 4225,
                "label": "Avenel",
                "label_ar": "أفينيل",
                "label_fr": "Avenel"
            },
            {
                "value": 4226,
                "label": "Aventura",
                "label_ar": "أفينتورا",
                "label_fr": "Aventura"
            },
            {
                "value": 4227,
                "label": "Averill Park",
                "label_ar": "افيريل بارك",
                "label_fr": "Parc Averill"
            },
            {
                "value": 4228,
                "label": "Avery",
                "label_ar": "أفيري",
                "label_fr": "Avery"
            },
            {
                "value": 4229,
                "label": "Avila Beach",
                "label_ar": "شاطئ أفيلا",
                "label_fr": "Plage d'Avila"
            },
            {
                "value": 4230,
                "label": "Avilla",
                "label_ar": "فيلا",
                "label_fr": "Une villa"
            },
            {
                "value": 4231,
                "label": "Avinger",
                "label_ar": "أفينجر",
                "label_fr": "Avinger"
            },
            {
                "value": 4232,
                "label": "Aviston",
                "label_ar": "أفيستون",
                "label_fr": "Aviston"
            },
            {
                "value": 4233,
                "label": "Avoca",
                "label_ar": "أفوكا",
                "label_fr": "Avoca"
            },
            {
                "value": 4234,
                "label": "Avon",
                "label_ar": "افون",
                "label_fr": "Avon"
            },
            {
                "value": 4235,
                "label": "Avon Lake",
                "label_ar": "أفون ليك",
                "label_fr": "Lac Avon"
            },
            {
                "value": 4236,
                "label": "Avon Park",
                "label_ar": "افون بارك",
                "label_fr": "Parc Avon"
            },
            {
                "value": 4237,
                "label": "Avon-by-the-Sea",
                "label_ar": "افون باي ذا سي",
                "label_fr": "Avon-by-the-Sea"
            },
            {
                "value": 4238,
                "label": "Avondale",
                "label_ar": "أفونديل",
                "label_fr": "Avondale"
            },
            {
                "value": 4239,
                "label": "Avondale Estates",
                "label_ar": "أفونديل إستيتس",
                "label_fr": "Domaines Avondale"
            },
            {
                "value": 4240,
                "label": "Avonmore",
                "label_ar": "أفونمور",
                "label_fr": "Avonmore"
            },
            {
                "value": 4241,
                "label": "Awendaw",
                "label_ar": "Awendaw",
                "label_fr": "Awendaw"
            },
            {
                "value": 4242,
                "label": "Axis",
                "label_ar": "محور",
                "label_fr": "Axe"
            },
            {
                "value": 4243,
                "label": "Axtell",
                "label_ar": "أكستيل",
                "label_fr": "Axtell"
            },
            {
                "value": 4244,
                "label": "Axton",
                "label_ar": "أكستون",
                "label_fr": "Axton"
            },
            {
                "value": 4245,
                "label": "Ayden",
                "label_ar": "آيدن",
                "label_fr": "Ayden"
            },
            {
                "value": 4246,
                "label": "Ayer",
                "label_ar": "آير",
                "label_fr": "Ayer"
            },
            {
                "value": 4247,
                "label": "Aylett",
                "label_ar": "ايليت",
                "label_fr": "Aylett"
            },
            {
                "value": 4248,
                "label": "Aynor",
                "label_ar": "اينور",
                "label_fr": "Aynor"
            },
            {
                "value": 4249,
                "label": "Azalea",
                "label_ar": "أزاليا",
                "label_fr": "Azalée"
            },
            {
                "value": 4250,
                "label": "Azle",
                "label_ar": "آزل",
                "label_fr": "Azle"
            },
            {
                "value": 4251,
                "label": "Aztec",
                "label_ar": "ازتيك",
                "label_fr": "Aztèque"
            },
            {
                "value": 4252,
                "label": "Azusa",
                "label_ar": "أزوسا",
                "label_fr": "Azusa"
            },
            {
                "value": 4253,
                "label": "Babb",
                "label_ar": "باب",
                "label_fr": "Babb"
            },
            {
                "value": 4254,
                "label": "Babbitt",
                "label_ar": "بابيت",
                "label_fr": "Babbitt"
            },
            {
                "value": 4255,
                "label": "Babcock",
                "label_ar": "بابكوك",
                "label_fr": "Babcock"
            },
            {
                "value": 4256,
                "label": "Babson Park",
                "label_ar": "حديقة بابسون",
                "label_fr": "Parc Babson"
            },
            {
                "value": 4257,
                "label": "Babylon",
                "label_ar": "بابل",
                "label_fr": "Babylone"
            },
            {
                "value": 4258,
                "label": "Backus",
                "label_ar": "باكوس",
                "label_fr": "Backus"
            },
            {
                "value": 4259,
                "label": "Bacliff",
                "label_ar": "باكليف",
                "label_fr": "Bacliff"
            },
            {
                "value": 4260,
                "label": "Bad Axe",
                "label_ar": "الفأس السيئ",
                "label_fr": "Mauvaise hache"
            },
            {
                "value": 4261,
                "label": "Baden",
                "label_ar": "بادن",
                "label_fr": "Baden"
            },
            {
                "value": 4262,
                "label": "Badger",
                "label_ar": "بادجر",
                "label_fr": "Blaireau"
            },
            {
                "value": 4263,
                "label": "Badin",
                "label_ar": "بادين",
                "label_fr": "Badin"
            },
            {
                "value": 4264,
                "label": "Bagdad",
                "label_ar": "بغداد",
                "label_fr": "Bagdad"
            },
            {
                "value": 4265,
                "label": "Baggs",
                "label_ar": "باجز",
                "label_fr": "Baggs"
            },
            {
                "value": 4266,
                "label": "Bagley",
                "label_ar": "باجلي",
                "label_fr": "Bagley"
            },
            {
                "value": 4267,
                "label": "Bagwell",
                "label_ar": "باغويل",
                "label_fr": "Bagwell"
            },
            {
                "value": 4268,
                "label": "Bahama",
                "label_ar": "جزر البهاما",
                "label_fr": "Bahama"
            },
            {
                "value": 4269,
                "label": "Bailey",
                "label_ar": "بيلي",
                "label_fr": "Bailey"
            },
            {
                "value": 4270,
                "label": "Bailey Island",
                "label_ar": "جزيرة بيلي",
                "label_fr": "Île Bailey"
            },
            {
                "value": 4271,
                "label": "Baileys Harbor",
                "label_ar": "ميناء بايليز",
                "label_fr": "Baileys Harbour"
            },
            {
                "value": 4272,
                "label": "Baileyton",
                "label_ar": "بيليتون",
                "label_fr": "Baileyton"
            },
            {
                "value": 4273,
                "label": "Baileyville",
                "label_ar": "بيليفيل",
                "label_fr": "Baileyville"
            },
            {
                "value": 4274,
                "label": "Bainbridge",
                "label_ar": "بينبريدج",
                "label_fr": "Bainbridge"
            },
            {
                "value": 4275,
                "label": "Bainbridge Island",
                "label_ar": "جزيرة بينبريدج",
                "label_fr": "Île Bainbridge"
            },
            {
                "value": 4276,
                "label": "Bainville",
                "label_ar": "بينفيل",
                "label_fr": "Bainville"
            },
            {
                "value": 4277,
                "label": "Baird",
                "label_ar": "بيرد",
                "label_fr": "Baird"
            },
            {
                "value": 4278,
                "label": "Bairdford",
                "label_ar": "بيردفورد",
                "label_fr": "Bairdford"
            },
            {
                "value": 4279,
                "label": "Baisden",
                "label_ar": "بيسدن",
                "label_fr": "Baisden"
            },
            {
                "value": 4280,
                "label": "Baker",
                "label_ar": "خباز",
                "label_fr": "boulanger"
            },
            {
                "value": 4281,
                "label": "Baker City",
                "label_ar": "مدينة بيكر",
                "label_fr": "Baker City"
            },
            {
                "value": 4282,
                "label": "Bakersfield",
                "label_ar": "بيكرسفيلد",
                "label_fr": "Bakersfield"
            },
            {
                "value": 4283,
                "label": "Bakersville",
                "label_ar": "بيكرسفيل",
                "label_fr": "Bakersville"
            },
            {
                "value": 4284,
                "label": "Bakewell",
                "label_ar": "باكويل",
                "label_fr": "Bakewell"
            },
            {
                "value": 4285,
                "label": "Bala-Cynwyd",
                "label_ar": "بالا سينويد",
                "label_fr": "Bala-Cynwyd"
            },
            {
                "value": 4286,
                "label": "Balaton",
                "label_ar": "بالاتون",
                "label_fr": "Balaton"
            },
            {
                "value": 4287,
                "label": "Bald Knob",
                "label_ar": "المقبض الأصلع",
                "label_fr": "Bouton chauve"
            },
            {
                "value": 4288,
                "label": "Baldwin",
                "label_ar": "بالدوين",
                "label_fr": "Baldwin"
            },
            {
                "value": 4289,
                "label": "Baldwin City",
                "label_ar": "مدينة بالدوين",
                "label_fr": "Baldwin City"
            },
            {
                "value": 4290,
                "label": "Baldwin Park",
                "label_ar": "بالدوين بارك",
                "label_fr": "Parc Baldwin"
            },
            {
                "value": 4291,
                "label": "Baldwin Place",
                "label_ar": "مكان بالدوين",
                "label_fr": "Baldwin Place"
            },
            {
                "value": 4292,
                "label": "Baldwinsville",
                "label_ar": "بالدوينزفيل",
                "label_fr": "Baldwinsville"
            },
            {
                "value": 4293,
                "label": "Baldwinville",
                "label_ar": "بالدوينفيل",
                "label_fr": "Baldwinville"
            },
            {
                "value": 4294,
                "label": "Baldwyn",
                "label_ar": "بالدوين",
                "label_fr": "Baldwyn"
            },
            {
                "value": 4295,
                "label": "Ball",
                "label_ar": "الكرة",
                "label_fr": "Balle"
            },
            {
                "value": 4296,
                "label": "Ball Ground",
                "label_ar": "الكرة الأرضية",
                "label_fr": "Terrain de balle"
            },
            {
                "value": 4297,
                "label": "Ballantine",
                "label_ar": "بلانتين",
                "label_fr": "Ballantine"
            },
            {
                "value": 4298,
                "label": "Ballico",
                "label_ar": "باليكو",
                "label_fr": "Ballico"
            },
            {
                "value": 4299,
                "label": "Ballinger",
                "label_ar": "بالينجر",
                "label_fr": "Ballinger"
            },
            {
                "value": 4300,
                "label": "Ballston Lake",
                "label_ar": "بحيرة بالستون",
                "label_fr": "Lac Ballston"
            },
            {
                "value": 4301,
                "label": "Ballston Spa",
                "label_ar": "سبا بالستون",
                "label_fr": "Ballston Spa"
            },
            {
                "value": 4302,
                "label": "Ballwin",
                "label_ar": "بلوين",
                "label_fr": "Ballwin"
            },
            {
                "value": 4303,
                "label": "Bally",
                "label_ar": "بالي",
                "label_fr": "Bally"
            },
            {
                "value": 4304,
                "label": "Balsam Lake",
                "label_ar": "بحيرة بلسم",
                "label_fr": "Lac Balsam"
            },
            {
                "value": 4305,
                "label": "Baltic",
                "label_ar": "البلطيق",
                "label_fr": "baltique"
            },
            {
                "value": 4306,
                "label": "Baltimore",
                "label_ar": "بالتيمور",
                "label_fr": "Baltimore"
            },
            {
                "value": 4307,
                "label": "Bamberg",
                "label_ar": "بامبرج",
                "label_fr": "Bamberg"
            },
            {
                "value": 4308,
                "label": "Bancroft",
                "label_ar": "بانكروفت",
                "label_fr": "Bancroft"
            },
            {
                "value": 4309,
                "label": "Bandera",
                "label_ar": "بانديرا",
                "label_fr": "Bandera"
            },
            {
                "value": 4310,
                "label": "Bandon",
                "label_ar": "باندون",
                "label_fr": "Bandon"
            },
            {
                "value": 4311,
                "label": "Bangor",
                "label_ar": "بانجور",
                "label_fr": "Bangor"
            },
            {
                "value": 4312,
                "label": "Bangs",
                "label_ar": "الانفجارات",
                "label_fr": "Frange"
            },
            {
                "value": 4313,
                "label": "Banks",
                "label_ar": "البنوك",
                "label_fr": "Banques"
            },
            {
                "value": 4314,
                "label": "Bankston",
                "label_ar": "بانكستون",
                "label_fr": "Bankston"
            },
            {
                "value": 4315,
                "label": "Banner",
                "label_ar": "لافتة",
                "label_fr": "Bannière"
            },
            {
                "value": 4316,
                "label": "Banner Elk",
                "label_ar": "بانر إلك",
                "label_fr": "Bannière Elk"
            },
            {
                "value": 4317,
                "label": "Banning",
                "label_ar": "الحظر",
                "label_fr": "Interdiction"
            },
            {
                "value": 4318,
                "label": "Bannister",
                "label_ar": "بانيستر",
                "label_fr": "Rampe"
            },
            {
                "value": 4319,
                "label": "Banquete",
                "label_ar": "بانكيت",
                "label_fr": "Banquete"
            },
            {
                "value": 4320,
                "label": "Bantam",
                "label_ar": "بانتام",
                "label_fr": "Coq nain"
            },
            {
                "value": 4321,
                "label": "Bapchule",
                "label_ar": "بابكيول",
                "label_fr": "Bapchule"
            },
            {
                "value": 4322,
                "label": "Baptistown",
                "label_ar": "بابتيستاون",
                "label_fr": "Baptistown"
            },
            {
                "value": 4323,
                "label": "Bar Harbor",
                "label_ar": "بار هاربور",
                "label_fr": "Bar Harbor"
            },
            {
                "value": 4324,
                "label": "Baraboo",
                "label_ar": "بارابو",
                "label_fr": "Baraboo"
            },
            {
                "value": 4325,
                "label": "Baraga",
                "label_ar": "باراجا",
                "label_fr": "Baraga"
            },
            {
                "value": 4326,
                "label": "Barbeau",
                "label_ar": "باربو",
                "label_fr": "Barbeau"
            },
            {
                "value": 4327,
                "label": "Barberton",
                "label_ar": "باربرتون",
                "label_fr": "Barberton"
            },
            {
                "value": 4328,
                "label": "Barboursville",
                "label_ar": "باربورسفيل",
                "label_fr": "Barboursville"
            },
            {
                "value": 4329,
                "label": "Barbourville",
                "label_ar": "باربورفيل",
                "label_fr": "Barbourville"
            },
            {
                "value": 4330,
                "label": "Barclay",
                "label_ar": "باركلي",
                "label_fr": "Barclay"
            },
            {
                "value": 4331,
                "label": "Bardstown",
                "label_ar": "باردستاون",
                "label_fr": "Bardstown"
            },
            {
                "value": 4332,
                "label": "Bardwell",
                "label_ar": "باردويل",
                "label_fr": "Bardwell"
            },
            {
                "value": 4333,
                "label": "Bargersville",
                "label_ar": "بارجرسفيل",
                "label_fr": "Bargersville"
            },
            {
                "value": 4334,
                "label": "Baring",
                "label_ar": "تعرية",
                "label_fr": "Baring"
            },
            {
                "value": 4335,
                "label": "Bark River",
                "label_ar": "نهر بارك",
                "label_fr": "Rivière Bark"
            },
            {
                "value": 4336,
                "label": "Barkhamsted",
                "label_ar": "باركهامستيد",
                "label_fr": "Barkhamsted"
            },
            {
                "value": 4337,
                "label": "Barling",
                "label_ar": "بارلينج",
                "label_fr": "Barling"
            },
            {
                "value": 4338,
                "label": "Barlow",
                "label_ar": "بارلو",
                "label_fr": "Barlow"
            },
            {
                "value": 4339,
                "label": "Barnard",
                "label_ar": "بارنارد",
                "label_fr": "Barnard"
            },
            {
                "value": 4340,
                "label": "Barnardsville",
                "label_ar": "بارناردسفيل",
                "label_fr": "Barnardsville"
            },
            {
                "value": 4341,
                "label": "Barnegat",
                "label_ar": "بارنيجات",
                "label_fr": "Barnegat"
            },
            {
                "value": 4342,
                "label": "Barnes",
                "label_ar": "بارنز",
                "label_fr": "Barnes"
            },
            {
                "value": 4343,
                "label": "Barnes City",
                "label_ar": "بارنز سيتي",
                "label_fr": "Barnes City"
            },
            {
                "value": 4344,
                "label": "Barnesville",
                "label_ar": "بارنسفيل",
                "label_fr": "Barnesville"
            },
            {
                "value": 4345,
                "label": "Barnet",
                "label_ar": "بارنت",
                "label_fr": "Barnet"
            },
            {
                "value": 4346,
                "label": "Barneveld",
                "label_ar": "بارنيفيلد",
                "label_fr": "Barneveld"
            },
            {
                "value": 4347,
                "label": "Barney",
                "label_ar": "بارني",
                "label_fr": "Barney"
            },
            {
                "value": 4348,
                "label": "Barnhart",
                "label_ar": "بارنهارت",
                "label_fr": "Barnhart"
            },
            {
                "value": 4349,
                "label": "Barnsdall",
                "label_ar": "بارنسدال",
                "label_fr": "Barnsdall"
            },
            {
                "value": 4350,
                "label": "Barnstable",
                "label_ar": "بارنستابل",
                "label_fr": "Barnstable"
            },
            {
                "value": 4351,
                "label": "Barnstead",
                "label_ar": "بارنستيد",
                "label_fr": "Barnstead"
            },
            {
                "value": 4352,
                "label": "Barnum",
                "label_ar": "بارنوم",
                "label_fr": "Barnum"
            },
            {
                "value": 4353,
                "label": "Barnwell",
                "label_ar": "بارنويل",
                "label_fr": "Barnwell"
            },
            {
                "value": 4354,
                "label": "Baroda",
                "label_ar": "بارودا",
                "label_fr": "Baroda"
            },
            {
                "value": 4355,
                "label": "Barre",
                "label_ar": "بري",
                "label_fr": "Barre"
            },
            {
                "value": 4356,
                "label": "Barren Springs",
                "label_ar": "جرداء سبرينغز",
                "label_fr": "Barren Springs"
            },
            {
                "value": 4357,
                "label": "Barrett",
                "label_ar": "باريت",
                "label_fr": "Barrett"
            },
            {
                "value": 4358,
                "label": "Barrington",
                "label_ar": "بارينجتون",
                "label_fr": "Barrington"
            },
            {
                "value": 4359,
                "label": "Barron",
                "label_ar": "بارون",
                "label_fr": "Barron"
            },
            {
                "value": 4360,
                "label": "Barronett",
                "label_ar": "بارونيت",
                "label_fr": "Barronett"
            },
            {
                "value": 4361,
                "label": "Barrow",
                "label_ar": "رابية",
                "label_fr": "Brouette"
            },
            {
                "value": 4362,
                "label": "Barry",
                "label_ar": "باري",
                "label_fr": "Barry"
            },
            {
                "value": 4363,
                "label": "Barryton",
                "label_ar": "باريتون",
                "label_fr": "Barryton"
            },
            {
                "value": 4364,
                "label": "Barryville",
                "label_ar": "باريفيل",
                "label_fr": "Barryville"
            },
            {
                "value": 4365,
                "label": "Barstow",
                "label_ar": "بارستو",
                "label_fr": "Barstow"
            },
            {
                "value": 4366,
                "label": "Bart Township",
                "label_ar": "بلدة بارت",
                "label_fr": "Canton de Bart"
            },
            {
                "value": 4367,
                "label": "Bartelso",
                "label_ar": "بارتيلسو",
                "label_fr": "Bartelso"
            },
            {
                "value": 4368,
                "label": "Bartlesville",
                "label_ar": "بارتليسفيل",
                "label_fr": "Bartlesville"
            },
            {
                "value": 4369,
                "label": "Bartlett",
                "label_ar": "بارتليت",
                "label_fr": "Bartlett"
            },
            {
                "value": 4370,
                "label": "Bartley",
                "label_ar": "بارتلي",
                "label_fr": "Bartley"
            },
            {
                "value": 4371,
                "label": "Barto",
                "label_ar": "بارتو",
                "label_fr": "Barto"
            },
            {
                "value": 4372,
                "label": "Barton",
                "label_ar": "بارتون",
                "label_fr": "Barton"
            },
            {
                "value": 4373,
                "label": "Bartonsville",
                "label_ar": "بارتونسفيل",
                "label_fr": "Bartonsville"
            },
            {
                "value": 4374,
                "label": "Bartow",
                "label_ar": "بارتو",
                "label_fr": "Bartow"
            },
            {
                "value": 4375,
                "label": "Basalt",
                "label_ar": "بازلت حجر بركاني",
                "label_fr": "Basalte"
            },
            {
                "value": 4376,
                "label": "Bascom",
                "label_ar": "باسكوم",
                "label_fr": "Bascom"
            },
            {
                "value": 4377,
                "label": "Basehor",
                "label_ar": "بشور",
                "label_fr": "Basehor"
            },
            {
                "value": 4378,
                "label": "Basile",
                "label_ar": "باسيلي",
                "label_fr": "Basile"
            },
            {
                "value": 4379,
                "label": "Basin",
                "label_ar": "حوض",
                "label_fr": "Bassin"
            },
            {
                "value": 4380,
                "label": "Basking Ridge",
                "label_ar": "تشمس ريدج",
                "label_fr": "Basking Ridge"
            },
            {
                "value": 4381,
                "label": "Bassett",
                "label_ar": "باسيت",
                "label_fr": "Bassett"
            },
            {
                "value": 4382,
                "label": "Bassfield",
                "label_ar": "باسفيلد",
                "label_fr": "Bassfield"
            },
            {
                "value": 4383,
                "label": "Bastrop",
                "label_ar": "باستروب",
                "label_fr": "Bastrop"
            },
            {
                "value": 4384,
                "label": "Basye",
                "label_ar": "Basye",
                "label_fr": "Basye"
            },
            {
                "value": 4385,
                "label": "Batavia",
                "label_ar": "باتافيا",
                "label_fr": "Batavia"
            },
            {
                "value": 4386,
                "label": "Batchelor",
                "label_ar": "باتشلور",
                "label_fr": "Batchelor"
            },
            {
                "value": 4387,
                "label": "Batchtown",
                "label_ar": "باتشتاون",
                "label_fr": "Batchtown"
            },
            {
                "value": 4388,
                "label": "Bates City",
                "label_ar": "مدينة بيتس",
                "label_fr": "Bates City"
            },
            {
                "value": 4389,
                "label": "Batesburg",
                "label_ar": "باتسبيرغ",
                "label_fr": "Batesburg"
            },
            {
                "value": 4390,
                "label": "Batesville",
                "label_ar": "بيتسفيل",
                "label_fr": "Batesville"
            },
            {
                "value": 4391,
                "label": "Bath",
                "label_ar": "حمام",
                "label_fr": "Une baignoire"
            },
            {
                "value": 4392,
                "label": "Bathgate",
                "label_ar": "باثجيت",
                "label_fr": "Bathgate"
            },
            {
                "value": 4393,
                "label": "Baton Rouge",
                "label_ar": "باتون روج",
                "label_fr": "Baton Rouge"
            },
            {
                "value": 4394,
                "label": "Batson",
                "label_ar": "باتسون",
                "label_fr": "Batson"
            },
            {
                "value": 4395,
                "label": "Battle Creek",
                "label_ar": "معركة الخور",
                "label_fr": "Battle Creek"
            },
            {
                "value": 4396,
                "label": "Battle Ground",
                "label_ar": "ساحة المعركة",
                "label_fr": "Champ de bataille"
            },
            {
                "value": 4397,
                "label": "Battle Lake",
                "label_ar": "باتل ليك",
                "label_fr": "Battle Lake"
            },
            {
                "value": 4398,
                "label": "Battle Mountain",
                "label_ar": "جبل المعركة",
                "label_fr": "Battle Mountain"
            },
            {
                "value": 4399,
                "label": "Battleboro",
                "label_ar": "باتل بورو",
                "label_fr": "Battleboro"
            },
            {
                "value": 4400,
                "label": "Baudette",
                "label_ar": "باوديت",
                "label_fr": "Baudette"
            },
            {
                "value": 4401,
                "label": "Bausman",
                "label_ar": "باوزمان",
                "label_fr": "Bausman"
            },
            {
                "value": 4402,
                "label": "Bauxite",
                "label_ar": "البوكسيت",
                "label_fr": "Bauxite"
            },
            {
                "value": 4403,
                "label": "Baxley",
                "label_ar": "باكسلي",
                "label_fr": "Baxley"
            },
            {
                "value": 4404,
                "label": "Baxter",
                "label_ar": "باكستر",
                "label_fr": "Baxter"
            },
            {
                "value": 4405,
                "label": "Baxter Springs",
                "label_ar": "باكستر سبرينغز",
                "label_fr": "Baxter Springs"
            },
            {
                "value": 4406,
                "label": "Bay City",
                "label_ar": "مدينة خليج",
                "label_fr": "Bay City"
            },
            {
                "value": 4407,
                "label": "Bay Minette",
                "label_ar": "خليج مينيت",
                "label_fr": "Baie Minette"
            },
            {
                "value": 4408,
                "label": "Bay Port",
                "label_ar": "ميناء الخليج",
                "label_fr": "Port de la baie"
            },
            {
                "value": 4409,
                "label": "Bay Saint Louis",
                "label_ar": "خليج سانت لويس",
                "label_fr": "Baie Saint Louis"
            },
            {
                "value": 4410,
                "label": "Bay Shore",
                "label_ar": "باي شور",
                "label_fr": "Bay Shore"
            },
            {
                "value": 4411,
                "label": "Bay Springs",
                "label_ar": "باي سبرينغز",
                "label_fr": "Bay Springs"
            },
            {
                "value": 4412,
                "label": "Bay Village",
                "label_ar": "قرية باي",
                "label_fr": "Bay Village"
            },
            {
                "value": 4413,
                "label": "Bayard",
                "label_ar": "بايارد",
                "label_fr": "Bayard"
            },
            {
                "value": 4414,
                "label": "Bayboro",
                "label_ar": "بايبورو",
                "label_fr": "Bayboro"
            },
            {
                "value": 4415,
                "label": "Bayfield",
                "label_ar": "بايفيلد",
                "label_fr": "Bayfield"
            },
            {
                "value": 4416,
                "label": "Bayonne",
                "label_ar": "بايون",
                "label_fr": "Bayonne"
            },
            {
                "value": 4417,
                "label": "Bayport",
                "label_ar": "بايبورت",
                "label_fr": "Bayport"
            },
            {
                "value": 4418,
                "label": "Bayside",
                "label_ar": "بايسايد",
                "label_fr": "Bayside"
            },
            {
                "value": 4419,
                "label": "Baytown",
                "label_ar": "بايتاون",
                "label_fr": "Baytown"
            },
            {
                "value": 4420,
                "label": "Bayview",
                "label_ar": "بايفيو",
                "label_fr": "Vue sur la baie"
            },
            {
                "value": 4421,
                "label": "Bayville",
                "label_ar": "بايفيل",
                "label_fr": "Bayville"
            },
            {
                "value": 4422,
                "label": "Bazine",
                "label_ar": "بازين",
                "label_fr": "Bazine"
            },
            {
                "value": 4423,
                "label": "Beach",
                "label_ar": "شاطئ بحر",
                "label_fr": "Plage"
            },
            {
                "value": 4424,
                "label": "Beach City",
                "label_ar": "بيتش سيتي",
                "label_fr": "Ville de plage"
            },
            {
                "value": 4425,
                "label": "Beach Haven",
                "label_ar": "بيتش هيفن",
                "label_fr": "Havre de plage"
            },
            {
                "value": 4426,
                "label": "Beach Lake",
                "label_ar": "بحيرة الشاطئ",
                "label_fr": "Lac de la plage"
            },
            {
                "value": 4427,
                "label": "Beachwood",
                "label_ar": "خشب الزان",
                "label_fr": "Beachwood"
            },
            {
                "value": 4428,
                "label": "Beacon",
                "label_ar": "منارة",
                "label_fr": "Balise"
            },
            {
                "value": 4429,
                "label": "Beacon Falls",
                "label_ar": "شلالات بيكون",
                "label_fr": "Beacon Falls"
            },
            {
                "value": 4430,
                "label": "Bealeton",
                "label_ar": "بيليتون",
                "label_fr": "Bealeton"
            },
            {
                "value": 4431,
                "label": "Beaman",
                "label_ar": "كن رجلا",
                "label_fr": "Sois un homme"
            },
            {
                "value": 4432,
                "label": "Bean Station",
                "label_ar": "محطة الفول",
                "label_fr": "Station de haricots"
            },
            {
                "value": 4433,
                "label": "Beans Purchase",
                "label_ar": "شراء الفاصوليا",
                "label_fr": "Achat de haricots"
            },
            {
                "value": 4434,
                "label": "Bear",
                "label_ar": "يتحمل",
                "label_fr": "Ours"
            },
            {
                "value": 4435,
                "label": "Bear Creek",
                "label_ar": "بير كريك",
                "label_fr": "Bear Creek"
            },
            {
                "value": 4436,
                "label": "Bear Lake",
                "label_ar": "بحيرة بير",
                "label_fr": "Bear Lake"
            },
            {
                "value": 4437,
                "label": "Bear River City",
                "label_ar": "بير ريفر سيتي",
                "label_fr": "Bear River City"
            },
            {
                "value": 4438,
                "label": "Bearden",
                "label_ar": "بيردن",
                "label_fr": "Bearden"
            },
            {
                "value": 4439,
                "label": "Beardstown",
                "label_ar": "بيردستاون",
                "label_fr": "Beardstown"
            },
            {
                "value": 4440,
                "label": "Bearsville",
                "label_ar": "بيرسفيل",
                "label_fr": "Bearsville"
            },
            {
                "value": 4441,
                "label": "Beasley",
                "label_ar": "بيسلي",
                "label_fr": "Beasley"
            },
            {
                "value": 4442,
                "label": "Beatrice",
                "label_ar": "بياتريس",
                "label_fr": "Béatrice"
            },
            {
                "value": 4443,
                "label": "Beattie",
                "label_ar": "بيتي",
                "label_fr": "Beattie"
            },
            {
                "value": 4444,
                "label": "Beatty",
                "label_ar": "بيتي",
                "label_fr": "Beatty"
            },
            {
                "value": 4445,
                "label": "Beattyville",
                "label_ar": "بيتيفيل",
                "label_fr": "Beattyville"
            },
            {
                "value": 4446,
                "label": "Beaufort",
                "label_ar": "بوفورت",
                "label_fr": "Beaufort"
            },
            {
                "value": 4447,
                "label": "Beaumont",
                "label_ar": "بومونت",
                "label_fr": "Beaumont"
            },
            {
                "value": 4448,
                "label": "Beauty",
                "label_ar": "جمال",
                "label_fr": "Beauté"
            },
            {
                "value": 4449,
                "label": "Beaver",
                "label_ar": "سمور",
                "label_fr": "Castor"
            },
            {
                "value": 4450,
                "label": "Beaver Bay",
                "label_ar": "بيفر باي",
                "label_fr": "Beaver Bay"
            },
            {
                "value": 4451,
                "label": "Beaver Creek",
                "label_ar": "بيفر كريك",
                "label_fr": "Beaver Creek"
            },
            {
                "value": 4452,
                "label": "Beaver Crossing",
                "label_ar": "معبر بيفر",
                "label_fr": "Traversée du castor"
            },
            {
                "value": 4453,
                "label": "Beaver Dam",
                "label_ar": "سد القندس",
                "label_fr": "barrage de castor"
            },
            {
                "value": 4454,
                "label": "Beaver Falls",
                "label_ar": "شلالات بيفر",
                "label_fr": "Beaver Falls"
            },
            {
                "value": 4455,
                "label": "Beaver Meadows",
                "label_ar": "بيفر ميدوز",
                "label_fr": "Beaver Meadows"
            },
            {
                "value": 4456,
                "label": "Beaver Springs",
                "label_ar": "بيفر سبرينغز",
                "label_fr": "Beaver Springs"
            },
            {
                "value": 4457,
                "label": "Beaverdam",
                "label_ar": "بيفردام",
                "label_fr": "Barrage de castor"
            },
            {
                "value": 4458,
                "label": "Beaverton",
                "label_ar": "بيفيرتون",
                "label_fr": "Beaverton"
            },
            {
                "value": 4459,
                "label": "Beavertown",
                "label_ar": "بيفرتاون",
                "label_fr": "Beavertown"
            },
            {
                "value": 4460,
                "label": "Bechtelsville",
                "label_ar": "بيشتيلسفيل",
                "label_fr": "Bechtelsville"
            },
            {
                "value": 4461,
                "label": "Beckemeyer",
                "label_ar": "بيكماير",
                "label_fr": "Beckemeyer"
            },
            {
                "value": 4462,
                "label": "Becker",
                "label_ar": "بيكر",
                "label_fr": "Becker"
            },
            {
                "value": 4463,
                "label": "Becket",
                "label_ar": "بيكيت",
                "label_fr": "Becket"
            },
            {
                "value": 4464,
                "label": "Beckley",
                "label_ar": "بيكلي",
                "label_fr": "Beckley"
            },
            {
                "value": 4465,
                "label": "Beckville",
                "label_ar": "بيكفيل",
                "label_fr": "Beckville"
            },
            {
                "value": 4466,
                "label": "Bedford",
                "label_ar": "بيدفورد",
                "label_fr": "Bedford"
            },
            {
                "value": 4467,
                "label": "Bedford Hills",
                "label_ar": "بيدفورد هيلز",
                "label_fr": "Bedford Hills"
            },
            {
                "value": 4468,
                "label": "Bedford Park",
                "label_ar": "بيدفورد بارك",
                "label_fr": "Bedford Park"
            },
            {
                "value": 4469,
                "label": "Bedias",
                "label_ar": "بيدياس",
                "label_fr": "Bedias"
            },
            {
                "value": 4470,
                "label": "Bedminster",
                "label_ar": "بيدمينستر",
                "label_fr": "Bedminster"
            },
            {
                "value": 4471,
                "label": "Bee Branch",
                "label_ar": "فرع النحل",
                "label_fr": "Branche d'abeille"
            },
            {
                "value": 4472,
                "label": "Bee Spring",
                "label_ar": "ربيع النحل",
                "label_fr": "Printemps des abeilles"
            },
            {
                "value": 4473,
                "label": "Beebe",
                "label_ar": "بيب",
                "label_fr": "Abeille"
            },
            {
                "value": 4474,
                "label": "Beech Bluff",
                "label_ar": "بيتش بلاف",
                "label_fr": "Beech Bluff"
            },
            {
                "value": 4475,
                "label": "Beech Bottom",
                "label_ar": "أسفل الزان",
                "label_fr": "Fond de hêtre"
            },
            {
                "value": 4476,
                "label": "Beech Creek",
                "label_ar": "بيتش كريك",
                "label_fr": "Ruisseau Beech"
            },
            {
                "value": 4477,
                "label": "Beech Grove",
                "label_ar": "بيتش جروف",
                "label_fr": "Beech Grove"
            },
            {
                "value": 4478,
                "label": "Beech Island",
                "label_ar": "جزيرة الزان",
                "label_fr": "Île de hêtre"
            },
            {
                "value": 4479,
                "label": "Beecher",
                "label_ar": "بيتشر",
                "label_fr": "Beecher"
            },
            {
                "value": 4480,
                "label": "Beecher City",
                "label_ar": "مدينة بيتشر",
                "label_fr": "Beecher City"
            },
            {
                "value": 4481,
                "label": "Beechgrove",
                "label_ar": "بيتشجروف",
                "label_fr": "Beechgrove"
            },
            {
                "value": 4482,
                "label": "Beechmont",
                "label_ar": "بيتشمونت",
                "label_fr": "Beechmont"
            },
            {
                "value": 4483,
                "label": "Beeville",
                "label_ar": "بيفيل",
                "label_fr": "Beeville"
            },
            {
                "value": 4484,
                "label": "Beggs",
                "label_ar": "يتسول",
                "label_fr": "Beggs"
            },
            {
                "value": 4485,
                "label": "Bel Air",
                "label_ar": "بيل اير",
                "label_fr": "bel Air"
            },
            {
                "value": 4486,
                "label": "Bel Alton",
                "label_ar": "بل ألتون",
                "label_fr": "Bel Alton"
            },
            {
                "value": 4487,
                "label": "Belcamp",
                "label_ar": "بيلكامب",
                "label_fr": "Belcamp"
            },
            {
                "value": 4488,
                "label": "Belchertown",
                "label_ar": "بيلشيرتاون",
                "label_fr": "Belchertown"
            },
            {
                "value": 4489,
                "label": "Belcourt",
                "label_ar": "بلكورت",
                "label_fr": "Belcourt"
            },
            {
                "value": 4490,
                "label": "Belden",
                "label_ar": "بيلدن",
                "label_fr": "Belden"
            },
            {
                "value": 4491,
                "label": "Beldenville",
                "label_ar": "بيلدينفيل",
                "label_fr": "Beldenville"
            },
            {
                "value": 4492,
                "label": "Belding",
                "label_ar": "بلدينغ",
                "label_fr": "Belding"
            },
            {
                "value": 4493,
                "label": "Belen",
                "label_ar": "بيلين",
                "label_fr": "Belen"
            },
            {
                "value": 4494,
                "label": "Belfair",
                "label_ar": "بلفير",
                "label_fr": "Belfair"
            },
            {
                "value": 4495,
                "label": "Belfast",
                "label_ar": "بلفاست",
                "label_fr": "Belfast"
            },
            {
                "value": 4496,
                "label": "Belfield",
                "label_ar": "بلفيلد",
                "label_fr": "Belfield"
            },
            {
                "value": 4497,
                "label": "Belford",
                "label_ar": "بلفورد",
                "label_fr": "Belford"
            },
            {
                "value": 4498,
                "label": "Belfry",
                "label_ar": "بلفري",
                "label_fr": "Beffroi"
            },
            {
                "value": 4499,
                "label": "Belgium",
                "label_ar": "بلجيكا",
                "label_fr": "Belgique"
            },
            {
                "value": 4500,
                "label": "Belgrade",
                "label_ar": "بلغراد",
                "label_fr": "Belgrade"
            },
            {
                "value": 4501,
                "label": "Belhaven",
                "label_ar": "بيلهافن",
                "label_fr": "Belhaven"
            },
            {
                "value": 4502,
                "label": "Belington",
                "label_ar": "بيلنجتون",
                "label_fr": "Belington"
            },
            {
                "value": 4503,
                "label": "Belknap",
                "label_ar": "بيلكناب",
                "label_fr": "Belknap"
            },
            {
                "value": 4504,
                "label": "Bell",
                "label_ar": "جرس",
                "label_fr": "Cloche"
            },
            {
                "value": 4505,
                "label": "Bell Buckle",
                "label_ar": "بيل بوكلي",
                "label_fr": "Boucle de cloche"
            },
            {
                "value": 4506,
                "label": "Bell City",
                "label_ar": "بيل سيتي",
                "label_fr": "Bell City"
            },
            {
                "value": 4507,
                "label": "Bell Island Hot Springs",
                "label_ar": "بيل آيلاند هوت سبرينغز",
                "label_fr": "Sources chaudes de Bell Island"
            },
            {
                "value": 4508,
                "label": "Bella Vista",
                "label_ar": "بيلا فيستا",
                "label_fr": "Bella Vista"
            },
            {
                "value": 4509,
                "label": "Bellaire",
                "label_ar": "بيلير",
                "label_fr": "Bellaire"
            },
            {
                "value": 4510,
                "label": "Bellbrook",
                "label_ar": "بيلبروك",
                "label_fr": "Bellbrook"
            },
            {
                "value": 4511,
                "label": "Belle",
                "label_ar": "حسناء",
                "label_fr": "Belle"
            },
            {
                "value": 4512,
                "label": "Belle Center",
                "label_ar": "مركز بيل",
                "label_fr": "Belle Center"
            },
            {
                "value": 4513,
                "label": "Belle Chasse",
                "label_ar": "بيل تشاس",
                "label_fr": "Belle Chasse"
            },
            {
                "value": 4514,
                "label": "Belle Fourche",
                "label_ar": "بيل فورش",
                "label_fr": "Belle Fourche"
            },
            {
                "value": 4515,
                "label": "Belle Glade",
                "label_ar": "بيل جليد",
                "label_fr": "Belle clairière"
            },
            {
                "value": 4516,
                "label": "Belle Haven",
                "label_ar": "بيل هافن",
                "label_fr": "Belle Haven"
            },
            {
                "value": 4517,
                "label": "Belle Mead",
                "label_ar": "بيل ميد",
                "label_fr": "Belle Mead"
            },
            {
                "value": 4518,
                "label": "Belle Plaine",
                "label_ar": "بيل بلين",
                "label_fr": "Belle Plaine"
            },
            {
                "value": 4519,
                "label": "Belle Rose",
                "label_ar": "بيل روز",
                "label_fr": "Belle Rose"
            },
            {
                "value": 4520,
                "label": "Belle Valley",
                "label_ar": "وادي الحسناء",
                "label_fr": "Belle Vallée"
            },
            {
                "value": 4521,
                "label": "Belle Vernon",
                "label_ar": "بيل فيرنون",
                "label_fr": "Belle Vernon"
            },
            {
                "value": 4522,
                "label": "Belleair Beach",
                "label_ar": "شاطئ بيلير",
                "label_fr": "Plage de Belleair"
            },
            {
                "value": 4523,
                "label": "Bellefontaine",
                "label_ar": "بلفونتين",
                "label_fr": "Bellefontaine"
            },
            {
                "value": 4524,
                "label": "Bellefonte",
                "label_ar": "بلفونت",
                "label_fr": "Bellefonte"
            },
            {
                "value": 4525,
                "label": "Bellemont",
                "label_ar": "بيلمونت",
                "label_fr": "Bellemont"
            },
            {
                "value": 4526,
                "label": "Bellerose",
                "label_ar": "بيلروز",
                "label_fr": "Bellerose"
            },
            {
                "value": 4527,
                "label": "Belleview",
                "label_ar": "بيلفيو",
                "label_fr": "Belleview"
            },
            {
                "value": 4528,
                "label": "Belleville",
                "label_ar": "بيلفيل",
                "label_fr": "Belleville"
            },
            {
                "value": 4529,
                "label": "Bellevue",
                "label_ar": "بلفيو",
                "label_fr": "Bellevue"
            },
            {
                "value": 4530,
                "label": "Bellflower",
                "label_ar": "الجرس",
                "label_fr": "Campanule"
            },
            {
                "value": 4531,
                "label": "Bellingham",
                "label_ar": "بيلينجهام",
                "label_fr": "Bellingham"
            },
            {
                "value": 4532,
                "label": "Bellmawr",
                "label_ar": "بيلماور",
                "label_fr": "Bellmawr"
            },
            {
                "value": 4533,
                "label": "Bellmore",
                "label_ar": "بيلمور",
                "label_fr": "Bellmore"
            },
            {
                "value": 4534,
                "label": "Bellows Falls",
                "label_ar": "شلالات منفاخ",
                "label_fr": "Bellows Falls"
            },
            {
                "value": 4535,
                "label": "Bellport",
                "label_ar": "بيلبورت",
                "label_fr": "Bellport"
            },
            {
                "value": 4536,
                "label": "Bells",
                "label_ar": "أجراس",
                "label_fr": "Cloches"
            },
            {
                "value": 4537,
                "label": "Bellville",
                "label_ar": "بيلفيل",
                "label_fr": "Bellville"
            },
            {
                "value": 4538,
                "label": "Bellvue",
                "label_ar": "بلفيو",
                "label_fr": "Bellvue"
            },
            {
                "value": 4539,
                "label": "Bellwood",
                "label_ar": "بيلوود",
                "label_fr": "Bellwood"
            },
            {
                "value": 4540,
                "label": "Belmar",
                "label_ar": "بلمار",
                "label_fr": "Belmar"
            },
            {
                "value": 4541,
                "label": "Belmond",
                "label_ar": "بلموند",
                "label_fr": "Belmond"
            },
            {
                "value": 4542,
                "label": "Belmont",
                "label_ar": "بلمونت",
                "label_fr": "Belmont"
            },
            {
                "value": 4543,
                "label": "Beloit",
                "label_ar": "بلويت",
                "label_fr": "Beloit"
            },
            {
                "value": 4544,
                "label": "Belpre",
                "label_ar": "بيلبر",
                "label_fr": "Belpré"
            },
            {
                "value": 4545,
                "label": "Belt",
                "label_ar": "حزام",
                "label_fr": "Ceinture"
            },
            {
                "value": 4546,
                "label": "Belton",
                "label_ar": "بيلتون",
                "label_fr": "Ceinture sur"
            },
            {
                "value": 4547,
                "label": "Beltrami",
                "label_ar": "بلترامي",
                "label_fr": "Beltrami"
            },
            {
                "value": 4548,
                "label": "Beltsville",
                "label_ar": "بيلتسفيل",
                "label_fr": "Beltsville"
            },
            {
                "value": 4549,
                "label": "Belvidere",
                "label_ar": "بلفيدير",
                "label_fr": "Belvidere"
            },
            {
                "value": 4550,
                "label": "Belvidere Center",
                "label_ar": "مركز بلفيدير",
                "label_fr": "Centre Belvidere"
            },
            {
                "value": 4551,
                "label": "Belview",
                "label_ar": "بيلفيو",
                "label_fr": "Belview"
            },
            {
                "value": 4552,
                "label": "Belvue",
                "label_ar": "بيلفو",
                "label_fr": "Belvue"
            },
            {
                "value": 4553,
                "label": "Belzoni",
                "label_ar": "بلزوني",
                "label_fr": "Belzoni"
            },
            {
                "value": 4554,
                "label": "Bement",
                "label_ar": "بيمنت",
                "label_fr": "Bement"
            },
            {
                "value": 4555,
                "label": "Bemidji",
                "label_ar": "بميدجي",
                "label_fr": "Bemidji"
            },
            {
                "value": 4556,
                "label": "Bemus Point",
                "label_ar": "بيموس بوينت",
                "label_fr": "Point Bemus"
            },
            {
                "value": 4557,
                "label": "Ben Lomond",
                "label_ar": "بن لوموند",
                "label_fr": "Ben Lomond"
            },
            {
                "value": 4558,
                "label": "Ben Wheeler",
                "label_ar": "بن ويلر",
                "label_fr": "Ben Wheeler"
            },
            {
                "value": 4559,
                "label": "Bena",
                "label_ar": "بينا",
                "label_fr": "Bena"
            },
            {
                "value": 4560,
                "label": "Benavides",
                "label_ar": "بينافيدس",
                "label_fr": "Benavides"
            },
            {
                "value": 4561,
                "label": "Bend",
                "label_ar": "ينحني",
                "label_fr": "pliez"
            },
            {
                "value": 4562,
                "label": "Bendersville",
                "label_ar": "بيندرزفيل",
                "label_fr": "Bendersville"
            },
            {
                "value": 4563,
                "label": "Benedict",
                "label_ar": "بنديكت",
                "label_fr": "Benoît"
            },
            {
                "value": 4564,
                "label": "Benham",
                "label_ar": "بنهام",
                "label_fr": "Benham"
            },
            {
                "value": 4565,
                "label": "Benicia",
                "label_ar": "بنيسيا",
                "label_fr": "Benicia"
            },
            {
                "value": 4566,
                "label": "Benkelman",
                "label_ar": "بنكلمان",
                "label_fr": "Benkelman"
            },
            {
                "value": 4567,
                "label": "Benld",
                "label_ar": "بينلد",
                "label_fr": "Benld"
            },
            {
                "value": 4568,
                "label": "Bennet",
                "label_ar": "بينيت",
                "label_fr": "Bennet"
            },
            {
                "value": 4569,
                "label": "Bennett",
                "label_ar": "بينيت",
                "label_fr": "Bennett"
            },
            {
                "value": 4570,
                "label": "Bennettsville",
                "label_ar": "بينيتسفيل",
                "label_fr": "Bennettsville"
            },
            {
                "value": 4571,
                "label": "Bennington",
                "label_ar": "بنينجتون",
                "label_fr": "Bennington"
            },
            {
                "value": 4572,
                "label": "Benoit",
                "label_ar": "بينوا",
                "label_fr": "Benoit"
            },
            {
                "value": 4573,
                "label": "Bensalem",
                "label_ar": "بنسالم",
                "label_fr": "Bensalem"
            },
            {
                "value": 4574,
                "label": "Bensenville",
                "label_ar": "بنسينفيل",
                "label_fr": "Bensenville"
            },
            {
                "value": 4575,
                "label": "Benson",
                "label_ar": "بنسون",
                "label_fr": "Benson"
            },
            {
                "value": 4576,
                "label": "Bent",
                "label_ar": "عازمة",
                "label_fr": "Courbé"
            },
            {
                "value": 4577,
                "label": "Bent Mountain",
                "label_ar": "جبل بنت",
                "label_fr": "Bent Mountain"
            },
            {
                "value": 4578,
                "label": "Bentleyville",
                "label_ar": "بنتليفيل",
                "label_fr": "Bentleyville"
            },
            {
                "value": 4579,
                "label": "Benton",
                "label_ar": "ينحني على",
                "label_fr": "Courbé sur"
            },
            {
                "value": 4580,
                "label": "Benton City",
                "label_ar": "مدينة بنتون",
                "label_fr": "Benton City"
            },
            {
                "value": 4581,
                "label": "Benton Harbor",
                "label_ar": "ميناء بينتون",
                "label_fr": "Benton Harbor"
            },
            {
                "value": 4582,
                "label": "Benton Ridge",
                "label_ar": "بنتون ريدج",
                "label_fr": "Benton Ridge"
            },
            {
                "value": 4583,
                "label": "Bentonia",
                "label_ar": "بنتونيا",
                "label_fr": "Bentonia"
            },
            {
                "value": 4584,
                "label": "Bentonville",
                "label_ar": "بنتونفيل",
                "label_fr": "Bentonville"
            },
            {
                "value": 4585,
                "label": "Benwood",
                "label_ar": "بينوود",
                "label_fr": "Benwood"
            },
            {
                "value": 4586,
                "label": "Benzonia",
                "label_ar": "بنزونيا",
                "label_fr": "Benzonia"
            },
            {
                "value": 4587,
                "label": "Berea",
                "label_ar": "بيريا",
                "label_fr": "Berea"
            },
            {
                "value": 4588,
                "label": "Beresford",
                "label_ar": "بيريسفورد",
                "label_fr": "Beresford"
            },
            {
                "value": 4589,
                "label": "Bergen",
                "label_ar": "بيرغن",
                "label_fr": "Bergen"
            },
            {
                "value": 4590,
                "label": "Bergenfield",
                "label_ar": "بيرجينفيلد",
                "label_fr": "Bergenfield"
            },
            {
                "value": 4591,
                "label": "Berger",
                "label_ar": "برجر",
                "label_fr": "Berger"
            },
            {
                "value": 4592,
                "label": "Bergheim",
                "label_ar": "بيرجهايم",
                "label_fr": "Bergheim"
            },
            {
                "value": 4593,
                "label": "Berkeley",
                "label_ar": "بيركلي",
                "label_fr": "Berkeley"
            },
            {
                "value": 4594,
                "label": "Berkeley Heights",
                "label_ar": "مرتفعات بيركلي",
                "label_fr": "Berkeley Heights"
            },
            {
                "value": 4595,
                "label": "Berkeley Springs",
                "label_ar": "بيركلي سبرينجز",
                "label_fr": "Berkeley Springs"
            },
            {
                "value": 4596,
                "label": "Berkley",
                "label_ar": "بيركلي",
                "label_fr": "Berkley"
            },
            {
                "value": 4597,
                "label": "Berkshire",
                "label_ar": "بيركشاير",
                "label_fr": "Berkshire"
            },
            {
                "value": 4598,
                "label": "Berlin",
                "label_ar": "برلين",
                "label_fr": "Berlin"
            },
            {
                "value": 4599,
                "label": "Berlin Center",
                "label_ar": "مركز برلين",
                "label_fr": "Centre de Berlin"
            },
            {
                "value": 4600,
                "label": "Berlin Heights",
                "label_ar": "مرتفعات برلين",
                "label_fr": "Berlin Heights"
            },
            {
                "value": 4601,
                "label": "Bern",
                "label_ar": "برن",
                "label_fr": "Berne"
            },
            {
                "value": 4602,
                "label": "Bernalillo",
                "label_ar": "برناليلو",
                "label_fr": "Bernalillo"
            },
            {
                "value": 4603,
                "label": "Bernard",
                "label_ar": "برنارد",
                "label_fr": "Bernard"
            },
            {
                "value": 4604,
                "label": "Bernardston",
                "label_ar": "برناردستون",
                "label_fr": "Bernardston"
            },
            {
                "value": 4605,
                "label": "Bernardsville",
                "label_ar": "برناردسفيل",
                "label_fr": "Bernardsville"
            },
            {
                "value": 4606,
                "label": "Berne",
                "label_ar": "برن",
                "label_fr": "Berne"
            },
            {
                "value": 4607,
                "label": "Bernice",
                "label_ar": "برنيس",
                "label_fr": "Bernice"
            },
            {
                "value": 4608,
                "label": "Bernie",
                "label_ar": "بيرني",
                "label_fr": "Bernie"
            },
            {
                "value": 4609,
                "label": "Bernville",
                "label_ar": "برنفيل",
                "label_fr": "Bernville"
            },
            {
                "value": 4610,
                "label": "Berrien Center",
                "label_ar": "مركز بيرين",
                "label_fr": "Centre Berrien"
            },
            {
                "value": 4611,
                "label": "Berrien Springs",
                "label_ar": "بيرين سبرينجز",
                "label_fr": "Berrien Springs"
            },
            {
                "value": 4612,
                "label": "Berry",
                "label_ar": "بيري",
                "label_fr": "Baie"
            },
            {
                "value": 4613,
                "label": "Berry Creek",
                "label_ar": "بيري كريك",
                "label_fr": "Ruisseau Berry"
            },
            {
                "value": 4614,
                "label": "Berrysburg",
                "label_ar": "بيريسبورغ",
                "label_fr": "Berrysburg"
            },
            {
                "value": 4615,
                "label": "Berryton",
                "label_ar": "بيريتون",
                "label_fr": "Berryton"
            },
            {
                "value": 4616,
                "label": "Berryville",
                "label_ar": "بيريفيل",
                "label_fr": "Berryville"
            },
            {
                "value": 4617,
                "label": "Bertha",
                "label_ar": "بيرثا",
                "label_fr": "Berthe"
            },
            {
                "value": 4618,
                "label": "Berthoud",
                "label_ar": "برتود",
                "label_fr": "Berthoud"
            },
            {
                "value": 4619,
                "label": "Bertram",
                "label_ar": "بيرترام",
                "label_fr": "Bertram"
            },
            {
                "value": 4620,
                "label": "Bertrand",
                "label_ar": "برتراند",
                "label_fr": "Bertrand"
            },
            {
                "value": 4621,
                "label": "Berwick",
                "label_ar": "بيرويك",
                "label_fr": "Berwick"
            },
            {
                "value": 4622,
                "label": "Berwind",
                "label_ar": "بيرويند",
                "label_fr": "Berwind"
            },
            {
                "value": 4623,
                "label": "Berwyn",
                "label_ar": "بيروين",
                "label_fr": "Berwyn"
            },
            {
                "value": 4624,
                "label": "Beryl",
                "label_ar": "البريل",
                "label_fr": "Béryl"
            },
            {
                "value": 4625,
                "label": "Bessemer",
                "label_ar": "بسمر",
                "label_fr": "Bessemer"
            },
            {
                "value": 4626,
                "label": "Bessemer City",
                "label_ar": "مدينة بسمر",
                "label_fr": "Ville de Bessemer"
            },
            {
                "value": 4627,
                "label": "Bethalto",
                "label_ar": "بيثالتو",
                "label_fr": "Bethalto"
            },
            {
                "value": 4628,
                "label": "Bethania",
                "label_ar": "بيثانيا",
                "label_fr": "Bethania"
            },
            {
                "value": 4629,
                "label": "Bethany",
                "label_ar": "بيثاني",
                "label_fr": "Bethany"
            },
            {
                "value": 4630,
                "label": "Bethany Beach",
                "label_ar": "شاطئ بيثاني",
                "label_fr": "Plage de Bethany"
            },
            {
                "value": 4631,
                "label": "Bethel",
                "label_ar": "بيثيل",
                "label_fr": "Béthel"
            },
            {
                "value": 4632,
                "label": "Bethel Island",
                "label_ar": "جزيرة بيثيل",
                "label_fr": "Île Bethel"
            },
            {
                "value": 4633,
                "label": "Bethel Park",
                "label_ar": "حديقة بيثيل",
                "label_fr": "Parc Bethel"
            },
            {
                "value": 4634,
                "label": "Bethel Springs",
                "label_ar": "بيثيل سبرينجز",
                "label_fr": "Bethel Springs"
            },
            {
                "value": 4635,
                "label": "Bethesda",
                "label_ar": "بيثيسدا",
                "label_fr": "Bethesda"
            },
            {
                "value": 4636,
                "label": "Bethlehem",
                "label_ar": "بيت لحم",
                "label_fr": "Bethléem"
            },
            {
                "value": 4637,
                "label": "Bethpage",
                "label_ar": "بيثباج",
                "label_fr": "Bethpage"
            },
            {
                "value": 4638,
                "label": "Bethune",
                "label_ar": "بيثون",
                "label_fr": "Bethune"
            },
            {
                "value": 4639,
                "label": "Betsy Layne",
                "label_ar": "بيتسي لين",
                "label_fr": "Betsy Layne"
            },
            {
                "value": 4640,
                "label": "Bettendorf",
                "label_ar": "بيتيندورف",
                "label_fr": "Bettendorf"
            },
            {
                "value": 4641,
                "label": "Beulah",
                "label_ar": "بيولا",
                "label_fr": "Beulah"
            },
            {
                "value": 4642,
                "label": "Beulaville",
                "label_ar": "بيولافيل",
                "label_fr": "Beulaville"
            },
            {
                "value": 4643,
                "label": "Beverly",
                "label_ar": "بيفرلي",
                "label_fr": "Beverly"
            },
            {
                "value": 4644,
                "label": "Beverly Hills",
                "label_ar": "بيفرلي هيلز",
                "label_fr": "Beverly Hills"
            },
            {
                "value": 4645,
                "label": "Bevington",
                "label_ar": "بيفينجتون",
                "label_fr": "Bevington"
            },
            {
                "value": 4646,
                "label": "Bickmore",
                "label_ar": "بيكمور",
                "label_fr": "Bickmore"
            },
            {
                "value": 4647,
                "label": "Bicknell",
                "label_ar": "بيكنيل",
                "label_fr": "Bicknell"
            },
            {
                "value": 4648,
                "label": "Biddeford",
                "label_ar": "بيدفورد",
                "label_fr": "Biddeford"
            },
            {
                "value": 4649,
                "label": "Bidwell",
                "label_ar": "بيدويل",
                "label_fr": "Bidwell"
            },
            {
                "value": 4650,
                "label": "Bieber",
                "label_ar": "بيبر",
                "label_fr": "Bieber"
            },
            {
                "value": 4651,
                "label": "Big Bay",
                "label_ar": "بيج باي",
                "label_fr": "Big Bay"
            },
            {
                "value": 4652,
                "label": "Big Bear",
                "label_ar": "بيج بير",
                "label_fr": "Gros ours"
            },
            {
                "value": 4653,
                "label": "Big Bear Lake",
                "label_ar": "بحيرة بيج بير",
                "label_fr": "Big Bear Lake"
            },
            {
                "value": 4654,
                "label": "Big Bend",
                "label_ar": "منعطف كبير",
                "label_fr": "Grand virage"
            },
            {
                "value": 4655,
                "label": "Big Cabin",
                "label_ar": "كابينة كبيرة",
                "label_fr": "Grande cabine"
            },
            {
                "value": 4656,
                "label": "Big Cove Tannery",
                "label_ar": "بيج كوف تانيري",
                "label_fr": "Tannerie Big Cove"
            },
            {
                "value": 4657,
                "label": "Big Creek",
                "label_ar": "بيج كريك",
                "label_fr": "Big Creek"
            },
            {
                "value": 4658,
                "label": "Big Falls",
                "label_ar": "شلالات كبيرة",
                "label_fr": "Big Falls"
            },
            {
                "value": 4659,
                "label": "Big Flats",
                "label_ar": "شقق كبيرة",
                "label_fr": "Grands appartements"
            },
            {
                "value": 4660,
                "label": "Big Horn",
                "label_ar": "قرن كبير",
                "label_fr": "Grosse corne"
            },
            {
                "value": 4661,
                "label": "Big Lake",
                "label_ar": "بحيرة كبيرة",
                "label_fr": "Grand lac"
            },
            {
                "value": 4662,
                "label": "Big Pine",
                "label_ar": "بيج باين",
                "label_fr": "Grand pin"
            },
            {
                "value": 4663,
                "label": "Big Pine Key",
                "label_ar": "مفتاح الصنوبر الكبير",
                "label_fr": "Clé Big Pine"
            },
            {
                "value": 4664,
                "label": "Big Piney",
                "label_ar": "بيج بايني",
                "label_fr": "Big Piney"
            },
            {
                "value": 4665,
                "label": "Big Pool",
                "label_ar": "تجمع كبير",
                "label_fr": "Grande piscine"
            },
            {
                "value": 4666,
                "label": "Big Rapids",
                "label_ar": "منحدرات كبيرة",
                "label_fr": "Big Rapids"
            },
            {
                "value": 4667,
                "label": "Big Rock",
                "label_ar": "بيج روك",
                "label_fr": "Gros rocher"
            },
            {
                "value": 4668,
                "label": "Big Sandy",
                "label_ar": "بيج ساندي",
                "label_fr": "Big Sandy"
            },
            {
                "value": 4669,
                "label": "Big Sky",
                "label_ar": "السماء كبيرة",
                "label_fr": "Grand ciel"
            },
            {
                "value": 4670,
                "label": "Big Spring",
                "label_ar": "الربيع الكبير",
                "label_fr": "Grand printemps"
            },
            {
                "value": 4671,
                "label": "Big Springs",
                "label_ar": "الينابيع الكبيرة",
                "label_fr": "Big Springs"
            },
            {
                "value": 4672,
                "label": "Big Stone City",
                "label_ar": "بيج ستون سيتي",
                "label_fr": "Big Stone City"
            },
            {
                "value": 4673,
                "label": "Big Stone Gap",
                "label_ar": "فجوة الحجر الكبير",
                "label_fr": "Grand écart de pierre"
            },
            {
                "value": 4674,
                "label": "Big Sur",
                "label_ar": "بيج سور",
                "label_fr": "Big Sur"
            },
            {
                "value": 4675,
                "label": "Big Timber",
                "label_ar": "بيغ تيمبر",
                "label_fr": "Gros bois"
            },
            {
                "value": 4676,
                "label": "Big Wells",
                "label_ar": "آبار كبيرة",
                "label_fr": "Grands puits"
            },
            {
                "value": 4677,
                "label": "Bigbend",
                "label_ar": "منعطف كبير",
                "label_fr": "Bigbend"
            },
            {
                "value": 4678,
                "label": "Bigelow",
                "label_ar": "بيجلو",
                "label_fr": "Bigelow"
            },
            {
                "value": 4679,
                "label": "Bigfoot",
                "label_ar": "بيغ فوت",
                "label_fr": "Bigfoot"
            },
            {
                "value": 4680,
                "label": "Bigfork",
                "label_ar": "بيجفورك",
                "label_fr": "Bigfork"
            },
            {
                "value": 4681,
                "label": "Biggs",
                "label_ar": "بيجز",
                "label_fr": "Biggs"
            },
            {
                "value": 4682,
                "label": "Biggsville",
                "label_ar": "بيجسفيل",
                "label_fr": "Biggsville"
            },
            {
                "value": 4683,
                "label": "Biglerville",
                "label_ar": "بيجلرفيل",
                "label_fr": "Biglerville"
            },
            {
                "value": 4684,
                "label": "Bill",
                "label_ar": "مشروع قانون",
                "label_fr": "Facture"
            },
            {
                "value": 4685,
                "label": "Billerica",
                "label_ar": "بيليريكا",
                "label_fr": "Billerica"
            },
            {
                "value": 4686,
                "label": "Billings",
                "label_ar": "بيلينغز",
                "label_fr": "Facturation"
            },
            {
                "value": 4687,
                "label": "Biloxi",
                "label_ar": "بيلوكسي",
                "label_fr": "Biloxi"
            },
            {
                "value": 4688,
                "label": "Bim",
                "label_ar": "بيم",
                "label_fr": "BIM"
            },
            {
                "value": 4689,
                "label": "Binford",
                "label_ar": "بينفورد",
                "label_fr": "Binford"
            },
            {
                "value": 4690,
                "label": "Bingen",
                "label_ar": "بينغن",
                "label_fr": "Bingen"
            },
            {
                "value": 4691,
                "label": "Binger",
                "label_ar": "بينغر",
                "label_fr": "Binger"
            },
            {
                "value": 4692,
                "label": "Bingham Lake",
                "label_ar": "بحيرة بينغهام",
                "label_fr": "Lac Bingham"
            },
            {
                "value": 4693,
                "label": "Binghamton",
                "label_ar": "بينغهامتون",
                "label_fr": "Binghamton"
            },
            {
                "value": 4694,
                "label": "Biola",
                "label_ar": "بيولا",
                "label_fr": "Biola"
            },
            {
                "value": 4695,
                "label": "Bippus",
                "label_ar": "بيبوس",
                "label_fr": "Bippus"
            },
            {
                "value": 4696,
                "label": "Birch River",
                "label_ar": "نهر بيرش",
                "label_fr": "Birch River"
            },
            {
                "value": 4697,
                "label": "Birch Run",
                "label_ar": "بيرش ران",
                "label_fr": "Birch Run"
            },
            {
                "value": 4698,
                "label": "Birch Tree",
                "label_ar": "شجرة البتولا",
                "label_fr": "Arbre de bouleau"
            },
            {
                "value": 4699,
                "label": "Birchleaf",
                "label_ar": "بيرشليف",
                "label_fr": "Bouleau"
            },
            {
                "value": 4700,
                "label": "Birchwood",
                "label_ar": "خشب خام",
                "label_fr": "Bois de bouleau"
            },
            {
                "value": 4701,
                "label": "Bird City",
                "label_ar": "مدينة الطيور",
                "label_fr": "Bird City"
            },
            {
                "value": 4702,
                "label": "Bird Island",
                "label_ar": "جزيرة الطيور",
                "label_fr": "Île aux oiseaux"
            },
            {
                "value": 4703,
                "label": "Bird in Hand",
                "label_ar": "عصفور في متناول اليد",
                "label_fr": "Oiseau en main"
            },
            {
                "value": 4704,
                "label": "Birdsboro",
                "label_ar": "بيردسبورو",
                "label_fr": "Birdsboro"
            },
            {
                "value": 4705,
                "label": "Birdseye",
                "label_ar": "عين الطائر",
                "label_fr": "Yeux d'oiseau"
            },
            {
                "value": 4706,
                "label": "Birdsnest",
                "label_ar": "عش الطائر",
                "label_fr": "Le nid d'oiseau"
            },
            {
                "value": 4707,
                "label": "Birmingham",
                "label_ar": "برمنغهام",
                "label_fr": "Birmingham"
            },
            {
                "value": 4708,
                "label": "Birnamwood",
                "label_ar": "بيرناموود",
                "label_fr": "Birnamwood"
            },
            {
                "value": 4709,
                "label": "Bisbee",
                "label_ar": "بيسبي",
                "label_fr": "Bisbee"
            },
            {
                "value": 4710,
                "label": "Biscoe",
                "label_ar": "بسكو",
                "label_fr": "Biscoe"
            },
            {
                "value": 4711,
                "label": "Bishop",
                "label_ar": "أسقف",
                "label_fr": "Évêque"
            },
            {
                "value": 4712,
                "label": "Bishopville",
                "label_ar": "بيشوبفيل",
                "label_fr": "Bishopville"
            },
            {
                "value": 4713,
                "label": "Bismarck",
                "label_ar": "بسمارك",
                "label_fr": "Bismarck"
            },
            {
                "value": 4714,
                "label": "Bison",
                "label_ar": "الثور",
                "label_fr": "Bison"
            },
            {
                "value": 4715,
                "label": "Bitely",
                "label_ar": "بيلي",
                "label_fr": "Bately"
            },
            {
                "value": 4716,
                "label": "Bivalve",
                "label_ar": "ذوات الصدفتين",
                "label_fr": "Bivalve"
            },
            {
                "value": 4717,
                "label": "Bivins",
                "label_ar": "بيفينز",
                "label_fr": "Bivins"
            },
            {
                "value": 4718,
                "label": "Biwabik",
                "label_ar": "بيوابيك",
                "label_fr": "Biwabik"
            },
            {
                "value": 4719,
                "label": "Bixby",
                "label_ar": "بيكسبي",
                "label_fr": "Bixby"
            },
            {
                "value": 4720,
                "label": "Blachly",
                "label_ar": "بلاكلي",
                "label_fr": "Blachly"
            },
            {
                "value": 4721,
                "label": "Black Canyon City",
                "label_ar": "بلاك كانيون سيتي",
                "label_fr": "Black Canyon City"
            },
            {
                "value": 4722,
                "label": "Black Creek",
                "label_ar": "بلاك كريك",
                "label_fr": "Black Creek"
            },
            {
                "value": 4723,
                "label": "Black Diamond",
                "label_ar": "الماس الأسود",
                "label_fr": "Diamant noir"
            },
            {
                "value": 4724,
                "label": "Black Eagle",
                "label_ar": "النسر الأسود",
                "label_fr": "Aigle Noir"
            },
            {
                "value": 4725,
                "label": "Black Earth",
                "label_ar": "الأرض السوداء",
                "label_fr": "Terre noire"
            },
            {
                "value": 4726,
                "label": "Black Hawk",
                "label_ar": "الصقر الأسود",
                "label_fr": "Black Hawk"
            },
            {
                "value": 4727,
                "label": "Black Mountain",
                "label_ar": "الجبل الأسود",
                "label_fr": "Montagne Noire"
            },
            {
                "value": 4728,
                "label": "Black River",
                "label_ar": "النهر الاسود",
                "label_fr": "Rivière Noire"
            },
            {
                "value": 4729,
                "label": "Black River Falls",
                "label_ar": "شلالات النهر الأسود",
                "label_fr": "Chutes de la rivière Noire"
            },
            {
                "value": 4730,
                "label": "Black Rock",
                "label_ar": "حجر أسود",
                "label_fr": "Roche noire"
            },
            {
                "value": 4731,
                "label": "Blackduck",
                "label_ar": "بلاك دوك",
                "label_fr": "Blackduck"
            },
            {
                "value": 4732,
                "label": "Blackfoot",
                "label_ar": "بلاكفوت",
                "label_fr": "Pied-noir"
            },
            {
                "value": 4733,
                "label": "Blackhawk",
                "label_ar": "الصقر الأسود",
                "label_fr": "Blackhawk"
            },
            {
                "value": 4734,
                "label": "Blacklick",
                "label_ar": "بلاكليك",
                "label_fr": "Blacklick"
            },
            {
                "value": 4735,
                "label": "Blacksburg",
                "label_ar": "بلاكسبرج",
                "label_fr": "Blacksburg"
            },
            {
                "value": 4736,
                "label": "Blackshear",
                "label_ar": "بلاك شير",
                "label_fr": "Blackshear"
            },
            {
                "value": 4737,
                "label": "Blackstock",
                "label_ar": "بلاكستوك",
                "label_fr": "Blackstock"
            },
            {
                "value": 4738,
                "label": "Blackstone",
                "label_ar": "حجر أسود",
                "label_fr": "Pierre noire"
            },
            {
                "value": 4739,
                "label": "Blacksville",
                "label_ar": "بلاكسفيل",
                "label_fr": "Blacksville"
            },
            {
                "value": 4740,
                "label": "Blackville",
                "label_ar": "بلاكفيل",
                "label_fr": "Blackville"
            },
            {
                "value": 4741,
                "label": "Blackwater",
                "label_ar": "مياه سوداء",
                "label_fr": "Eau noire"
            },
            {
                "value": 4742,
                "label": "Blackwell",
                "label_ar": "بلاكويل",
                "label_fr": "Blackwell"
            },
            {
                "value": 4743,
                "label": "Blackwood",
                "label_ar": "خشب أسود",
                "label_fr": "Bois noir"
            },
            {
                "value": 4744,
                "label": "Bladenboro",
                "label_ar": "بلادنبورو",
                "label_fr": "Bladenboro"
            },
            {
                "value": 4745,
                "label": "Bladensburg",
                "label_ar": "بلادينسبيرغ",
                "label_fr": "Bladensburg"
            },
            {
                "value": 4746,
                "label": "Blain",
                "label_ar": "بلين",
                "label_fr": "Blain"
            },
            {
                "value": 4747,
                "label": "Blaine",
                "label_ar": "بلين",
                "label_fr": "Blaine"
            },
            {
                "value": 4748,
                "label": "Blair",
                "label_ar": "بلير",
                "label_fr": "Blair"
            },
            {
                "value": 4749,
                "label": "Blairs",
                "label_ar": "بليرز",
                "label_fr": "Blairs"
            },
            {
                "value": 4750,
                "label": "Blairs Mills",
                "label_ar": "بليرز ميلز",
                "label_fr": "Blairs Mills"
            },
            {
                "value": 4751,
                "label": "Blairsburg",
                "label_ar": "بليرسبرج",
                "label_fr": "Blairsburg"
            },
            {
                "value": 4752,
                "label": "Blairstown",
                "label_ar": "بلايرستاون",
                "label_fr": "Blairstown"
            },
            {
                "value": 4753,
                "label": "Blairsville",
                "label_ar": "بليرسفيل",
                "label_fr": "Blairsville"
            },
            {
                "value": 4754,
                "label": "Blakely",
                "label_ar": "بلاكلي",
                "label_fr": "Blakely"
            },
            {
                "value": 4755,
                "label": "Blakesburg",
                "label_ar": "بلاكسبرج",
                "label_fr": "Blakesburg"
            },
            {
                "value": 4756,
                "label": "Blakeslee",
                "label_ar": "بلاكسلي",
                "label_fr": "Blakeslee"
            },
            {
                "value": 4757,
                "label": "Blanch",
                "label_ar": "بلانش",
                "label_fr": "Blanchir"
            },
            {
                "value": 4758,
                "label": "Blanchard",
                "label_ar": "بلانشارد",
                "label_fr": "Blanchard"
            },
            {
                "value": 4759,
                "label": "Blanchardville",
                "label_ar": "بلانشاردفيل",
                "label_fr": "Blanchardville"
            },
            {
                "value": 4760,
                "label": "Blanchester",
                "label_ar": "بلانتشيستر",
                "label_fr": "Blanchester"
            },
            {
                "value": 4761,
                "label": "Blanco",
                "label_ar": "بلانكو",
                "label_fr": "Blanco"
            },
            {
                "value": 4762,
                "label": "Bland",
                "label_ar": "ماصخ",
                "label_fr": "Fade"
            },
            {
                "value": 4763,
                "label": "Blandburg",
                "label_ar": "بلاندبرج",
                "label_fr": "Blandburg"
            },
            {
                "value": 4764,
                "label": "Blandford",
                "label_ar": "بلاندفورد",
                "label_fr": "Blandford"
            },
            {
                "value": 4765,
                "label": "Blanding",
                "label_ar": "بلاندينج",
                "label_fr": "Mousseux"
            },
            {
                "value": 4766,
                "label": "Blandinsville",
                "label_ar": "بلاندينزفيل",
                "label_fr": "Blandinsville"
            },
            {
                "value": 4767,
                "label": "Blandon",
                "label_ar": "بلاندون",
                "label_fr": "Blandon"
            },
            {
                "value": 4768,
                "label": "Blauvelt",
                "label_ar": "بلوفيلت",
                "label_fr": "Blauvelt"
            },
            {
                "value": 4769,
                "label": "Bledsoe",
                "label_ar": "بليدسو",
                "label_fr": "Bledsoe"
            },
            {
                "value": 4770,
                "label": "Blencoe",
                "label_ar": "بلينكو",
                "label_fr": "Blencoe"
            },
            {
                "value": 4771,
                "label": "Blenheim",
                "label_ar": "بلينهايم",
                "label_fr": "Blenheim"
            },
            {
                "value": 4772,
                "label": "Blenker",
                "label_ar": "بلينكر",
                "label_fr": "Blenker"
            },
            {
                "value": 4773,
                "label": "Blessing",
                "label_ar": "بركة",
                "label_fr": "Bénédiction"
            },
            {
                "value": 4774,
                "label": "Bliss",
                "label_ar": "النعيم",
                "label_fr": "Félicité"
            },
            {
                "value": 4775,
                "label": "Blissfield",
                "label_ar": "بليسفيلد",
                "label_fr": "Blissfield"
            },
            {
                "value": 4776,
                "label": "Block Island",
                "label_ar": "جزيرة بلوك",
                "label_fr": "Block Island"
            },
            {
                "value": 4777,
                "label": "Blockton",
                "label_ar": "بلوكتون",
                "label_fr": "Blockton"
            },
            {
                "value": 4778,
                "label": "Blodgett",
                "label_ar": "بلودجيت",
                "label_fr": "Blodgett"
            },
            {
                "value": 4779,
                "label": "Blodgett Mills",
                "label_ar": "بلودجيت ميلز",
                "label_fr": "Blodgett Mills"
            },
            {
                "value": 4780,
                "label": "Bloomburg",
                "label_ar": "بلومبرج",
                "label_fr": "Bloomburg"
            },
            {
                "value": 4781,
                "label": "Bloomdale",
                "label_ar": "بلومديل",
                "label_fr": "Bloomdale"
            },
            {
                "value": 4782,
                "label": "Bloomer",
                "label_ar": "خطأ شنيع",
                "label_fr": "Bloomer"
            },
            {
                "value": 4783,
                "label": "Bloomery",
                "label_ar": "بلومري",
                "label_fr": "Bloomery"
            },
            {
                "value": 4784,
                "label": "Bloomfield",
                "label_ar": "بلومفيلد",
                "label_fr": "Bloomfield"
            },
            {
                "value": 4785,
                "label": "Bloomfield Hills",
                "label_ar": "بلومفيلد هيلز",
                "label_fr": "Bloomfield Hills"
            },
            {
                "value": 4786,
                "label": "Blooming Glen",
                "label_ar": "بلومينج جلين",
                "label_fr": "Glen en fleurs"
            },
            {
                "value": 4787,
                "label": "Blooming Grove",
                "label_ar": "بلومينج جروف",
                "label_fr": "Blooming Grove"
            },
            {
                "value": 4788,
                "label": "Blooming Prairie",
                "label_ar": "تزهر البراري",
                "label_fr": "Prairie en fleurs"
            },
            {
                "value": 4789,
                "label": "Bloomingburg",
                "label_ar": "بلومينغبرج",
                "label_fr": "Bloomingburg"
            },
            {
                "value": 4790,
                "label": "Bloomingdale",
                "label_ar": "بلومينغديل",
                "label_fr": "Bloomingdale"
            },
            {
                "value": 4791,
                "label": "Bloomington",
                "label_ar": "بلومنجتون",
                "label_fr": "Bloomington"
            },
            {
                "value": 4792,
                "label": "Bloomington Springs",
                "label_ar": "بلومنجتون سبرينجز",
                "label_fr": "Bloomington Springs"
            },
            {
                "value": 4793,
                "label": "Bloomsburg",
                "label_ar": "بلومسبورغ",
                "label_fr": "Bloomsburg"
            },
            {
                "value": 4794,
                "label": "Bloomsbury",
                "label_ar": "بلومزبري",
                "label_fr": "Bloomsbury"
            },
            {
                "value": 4795,
                "label": "Bloomsdale",
                "label_ar": "بلومزديل",
                "label_fr": "Bloomsdale"
            },
            {
                "value": 4796,
                "label": "Bloomville",
                "label_ar": "بلومفيل",
                "label_fr": "Bloomville"
            },
            {
                "value": 4797,
                "label": "Blossburg",
                "label_ar": "بلوسبرج",
                "label_fr": "Blossburg"
            },
            {
                "value": 4798,
                "label": "Blossom",
                "label_ar": "زهر",
                "label_fr": "Fleur"
            },
            {
                "value": 4799,
                "label": "Blossvale",
                "label_ar": "بلوسفيل",
                "label_fr": "Blossvale"
            },
            {
                "value": 4800,
                "label": "Blountstown",
                "label_ar": "بلونتستاون",
                "label_fr": "Blountstown"
            },
            {
                "value": 4801,
                "label": "Blountsville",
                "label_ar": "بلونتسفيل",
                "label_fr": "Blountsville"
            },
            {
                "value": 4802,
                "label": "Blountville",
                "label_ar": "بلونتفيل",
                "label_fr": "Blountville"
            },
            {
                "value": 4803,
                "label": "Blowing Rock",
                "label_ar": "تهب روك",
                "label_fr": "Souffler de la roche"
            },
            {
                "value": 4804,
                "label": "Blue",
                "label_ar": "أزرق",
                "label_fr": "Bleu"
            },
            {
                "value": 4805,
                "label": "Blue Ball",
                "label_ar": "الكرة الزرقاء",
                "label_fr": "Boule bleue"
            },
            {
                "value": 4806,
                "label": "Blue Bell",
                "label_ar": "بلو بيل",
                "label_fr": "Cloche bleue"
            },
            {
                "value": 4807,
                "label": "Blue Creek",
                "label_ar": "بلو كريك",
                "label_fr": "Blue Creek"
            },
            {
                "value": 4808,
                "label": "Blue Earth",
                "label_ar": "الأرض الزرقاء",
                "label_fr": "Terre bleue"
            },
            {
                "value": 4809,
                "label": "Blue Eye",
                "label_ar": "عين زرقاء",
                "label_fr": "Oeil bleu"
            },
            {
                "value": 4810,
                "label": "Blue Grass",
                "label_ar": "بلو جراس",
                "label_fr": "Herbe bleue"
            },
            {
                "value": 4811,
                "label": "Blue Hill",
                "label_ar": "هيل بلو",
                "label_fr": "Blue Hill"
            },
            {
                "value": 4812,
                "label": "Blue Island",
                "label_ar": "الجزيرة الزرقاء",
                "label_fr": "Blue Island"
            },
            {
                "value": 4813,
                "label": "Blue Jay",
                "label_ar": "قيق أزرق",
                "label_fr": "Geai bleu"
            },
            {
                "value": 4814,
                "label": "Blue Lake",
                "label_ar": "بحيرة زرقاء",
                "label_fr": "Lac bleu"
            },
            {
                "value": 4815,
                "label": "Blue Mound",
                "label_ar": "بلو ماوند",
                "label_fr": "Monticule bleu"
            },
            {
                "value": 4816,
                "label": "Blue Mounds",
                "label_ar": "بلو ماوندز",
                "label_fr": "Monticules bleus"
            },
            {
                "value": 4817,
                "label": "Blue Mountain",
                "label_ar": "جبل أزرق",
                "label_fr": "Montagne bleue"
            },
            {
                "value": 4818,
                "label": "Blue Mountain Lake",
                "label_ar": "بحيرة بلو ماونتين",
                "label_fr": "Lac Blue Mountain"
            },
            {
                "value": 4819,
                "label": "Blue Point",
                "label_ar": "نقطة زرقاء",
                "label_fr": "Point Blue"
            },
            {
                "value": 4820,
                "label": "Blue Rapids",
                "label_ar": "بلو رابيدز",
                "label_fr": "Blue Rapids"
            },
            {
                "value": 4821,
                "label": "Blue Ridge",
                "label_ar": "بلو ريدج",
                "label_fr": "Blue Ridge"
            },
            {
                "value": 4822,
                "label": "Blue Ridge Summit",
                "label_ar": "قمة بلو ريدج",
                "label_fr": "Sommet de Blue Ridge"
            },
            {
                "value": 4823,
                "label": "Blue River",
                "label_ar": "النهر الأزرق",
                "label_fr": "Rivière bleue"
            },
            {
                "value": 4824,
                "label": "Blue Springs",
                "label_ar": "بلو سبرينغز",
                "label_fr": "Blue Springs"
            },
            {
                "value": 4825,
                "label": "Bluebell",
                "label_ar": "الجريس عشبة نباتية",
                "label_fr": "Jacinthe des bois"
            },
            {
                "value": 4826,
                "label": "Bluefield",
                "label_ar": "بلوفيلد",
                "label_fr": "Bluefield"
            },
            {
                "value": 4827,
                "label": "Bluejacket",
                "label_ar": "سترة زرقاء",
                "label_fr": "Manteau bleu"
            },
            {
                "value": 4828,
                "label": "Bluemont",
                "label_ar": "بلومونت",
                "label_fr": "Bluemont"
            },
            {
                "value": 4829,
                "label": "Bluewater",
                "label_ar": "ماء ازرق",
                "label_fr": "L'eau bleue"
            },
            {
                "value": 4830,
                "label": "Bluff",
                "label_ar": "خدعة",
                "label_fr": "Bluffer"
            },
            {
                "value": 4831,
                "label": "Bluff City",
                "label_ar": "بلاف سيتي",
                "label_fr": "Bluff City"
            },
            {
                "value": 4832,
                "label": "Bluff Dale",
                "label_ar": "بلاف ديل",
                "label_fr": "Bluff Dale"
            },
            {
                "value": 4833,
                "label": "Bluffs",
                "label_ar": "الخداع",
                "label_fr": "Bluffs"
            },
            {
                "value": 4834,
                "label": "Bluffton",
                "label_ar": "بلافتون",
                "label_fr": "Bluffton"
            },
            {
                "value": 4835,
                "label": "Bluford",
                "label_ar": "بلوفورد",
                "label_fr": "Bluford"
            },
            {
                "value": 4836,
                "label": "Blum",
                "label_ar": "بلوم",
                "label_fr": "Blum"
            },
            {
                "value": 4837,
                "label": "Bly",
                "label_ar": "بلي",
                "label_fr": "Bly"
            },
            {
                "value": 4838,
                "label": "Blythe",
                "label_ar": "بليث",
                "label_fr": "Blythe"
            },
            {
                "value": 4839,
                "label": "Blytheville",
                "label_ar": "بليثفيل",
                "label_fr": "Blytheville"
            },
            {
                "value": 4840,
                "label": "Blythewood",
                "label_ar": "بليثوود",
                "label_fr": "Blythewood"
            },
            {
                "value": 4841,
                "label": "Boalsburg",
                "label_ar": "Boalsburg",
                "label_fr": "Boalsburg"
            },
            {
                "value": 4842,
                "label": "Boardman",
                "label_ar": "بوردمان",
                "label_fr": "Boardman"
            },
            {
                "value": 4843,
                "label": "Boaz",
                "label_ar": "بوعز",
                "label_fr": "Boaz"
            },
            {
                "value": 4844,
                "label": "Bob White",
                "label_ar": "بوب وايت",
                "label_fr": "Bob White"
            },
            {
                "value": 4845,
                "label": "Bobtown",
                "label_ar": "بوبتاون",
                "label_fr": "Bobtown"
            },
            {
                "value": 4846,
                "label": "Boca Grande",
                "label_ar": "بوكا غراندي",
                "label_fr": "Boca Grande"
            },
            {
                "value": 4847,
                "label": "Boca Raton",
                "label_ar": "بوكا راتون",
                "label_fr": "Boca Raton"
            },
            {
                "value": 4848,
                "label": "Bode",
                "label_ar": "بودي",
                "label_fr": "Présager"
            },
            {
                "value": 4849,
                "label": "Bodega Bay",
                "label_ar": "خليج بوديجا",
                "label_fr": "Bodega Bay"
            },
            {
                "value": 4850,
                "label": "Bodfish",
                "label_ar": "بودفيش",
                "label_fr": "Bodfish"
            },
            {
                "value": 4851,
                "label": "Boelus",
                "label_ar": "Boelus",
                "label_fr": "Boelus"
            },
            {
                "value": 4852,
                "label": "Boerne",
                "label_ar": "بويرن",
                "label_fr": "Boerne"
            },
            {
                "value": 4853,
                "label": "Bogalusa",
                "label_ar": "بوغالوسا",
                "label_fr": "Bogalusa"
            },
            {
                "value": 4854,
                "label": "Bogard",
                "label_ar": "بوجارد",
                "label_fr": "Bogard"
            },
            {
                "value": 4855,
                "label": "Bogart",
                "label_ar": "بوغارت",
                "label_fr": "Bogart"
            },
            {
                "value": 4856,
                "label": "Bogata",
                "label_ar": "بوغاتا",
                "label_fr": "Bogata"
            },
            {
                "value": 4857,
                "label": "Boggstown",
                "label_ar": "بوجستاون",
                "label_fr": "Boggstown"
            },
            {
                "value": 4858,
                "label": "Bogota",
                "label_ar": "بوغوتا",
                "label_fr": "Bogota"
            },
            {
                "value": 4859,
                "label": "Bogue Chitto",
                "label_ar": "بوج شيتو",
                "label_fr": "Bogue Chitto"
            },
            {
                "value": 4860,
                "label": "Bohannon",
                "label_ar": "بوهانون",
                "label_fr": "Bohannon"
            },
            {
                "value": 4861,
                "label": "Bohemia",
                "label_ar": "بوهيميا",
                "label_fr": "Bohême"
            },
            {
                "value": 4862,
                "label": "Boiceville",
                "label_ar": "Boiceville",
                "label_fr": "Boiceville"
            },
            {
                "value": 4863,
                "label": "Boiling Springs",
                "label_ar": "غليان الينابيع",
                "label_fr": "Sources d'ébullition"
            },
            {
                "value": 4864,
                "label": "Boise",
                "label_ar": "بويز",
                "label_fr": "Boise"
            },
            {
                "value": 4865,
                "label": "Boise City",
                "label_ar": "مدينة بويز",
                "label_fr": "Ville de Boise"
            },
            {
                "value": 4866,
                "label": "Boissevain",
                "label_ar": "Boissevain",
                "label_fr": "Boissevain"
            },
            {
                "value": 4867,
                "label": "Bokchito",
                "label_ar": "بوكيتو",
                "label_fr": "Bokchito"
            },
            {
                "value": 4868,
                "label": "Bokeelia",
                "label_ar": "بوكيليا",
                "label_fr": "Bokeelia"
            },
            {
                "value": 4869,
                "label": "Bolckow",
                "label_ar": "بولكو",
                "label_fr": "Bolckow"
            },
            {
                "value": 4870,
                "label": "Boligee",
                "label_ar": "Boligee",
                "label_fr": "Boligee"
            },
            {
                "value": 4871,
                "label": "Bolinas",
                "label_ar": "بوليناس",
                "label_fr": "Bolinas"
            },
            {
                "value": 4872,
                "label": "Boling",
                "label_ar": "بولينج",
                "label_fr": "Boling"
            },
            {
                "value": 4873,
                "label": "Bolingbroke",
                "label_ar": "بولينغبروك",
                "label_fr": "Bolingbroke"
            },
            {
                "value": 4874,
                "label": "Bolingbrook",
                "label_ar": "بولينغبروك",
                "label_fr": "Bolingbrook"
            },
            {
                "value": 4875,
                "label": "Bolivar",
                "label_ar": "بوليفار",
                "label_fr": "Bolivar"
            },
            {
                "value": 4876,
                "label": "Bolivia",
                "label_ar": "بوليفيا",
                "label_fr": "Bolivie"
            },
            {
                "value": 4877,
                "label": "Bolt",
                "label_ar": "بولت",
                "label_fr": "Boulon"
            },
            {
                "value": 4878,
                "label": "Bolton",
                "label_ar": "بولتون",
                "label_fr": "Boulonner"
            },
            {
                "value": 4879,
                "label": "Bolton Landing",
                "label_ar": "بولتون لاندينج",
                "label_fr": "Atterrissage de Bolton"
            },
            {
                "value": 4880,
                "label": "Bomont",
                "label_ar": "بومونت",
                "label_fr": "Bomont"
            },
            {
                "value": 4881,
                "label": "Bon Aqua",
                "label_ar": "بون أكوا",
                "label_fr": "Bon Aqua"
            },
            {
                "value": 4882,
                "label": "Bonaire",
                "label_ar": "بونير",
                "label_fr": "Bonaire"
            },
            {
                "value": 4883,
                "label": "Bonaparte",
                "label_ar": "بونابرت",
                "label_fr": "Bonaparte"
            },
            {
                "value": 4884,
                "label": "Bondsville",
                "label_ar": "بوندسفيل",
                "label_fr": "Bondsville"
            },
            {
                "value": 4885,
                "label": "Bonduel",
                "label_ar": "بوندويل",
                "label_fr": "Bonduel"
            },
            {
                "value": 4886,
                "label": "Bondurant",
                "label_ar": "عبيد",
                "label_fr": "Bondurant"
            },
            {
                "value": 4887,
                "label": "Bondville",
                "label_ar": "بوندفيل",
                "label_fr": "Bondville"
            },
            {
                "value": 4888,
                "label": "Bone Gap",
                "label_ar": "فجوة العظام",
                "label_fr": "Écart osseux"
            },
            {
                "value": 4889,
                "label": "Bonfield",
                "label_ar": "بونفيلد",
                "label_fr": "Bonfield"
            },
            {
                "value": 4890,
                "label": "Bonham",
                "label_ar": "بونهام",
                "label_fr": "Bonham"
            },
            {
                "value": 4891,
                "label": "Bonifay",
                "label_ar": "بونيفاي",
                "label_fr": "Bonifay"
            },
            {
                "value": 4892,
                "label": "Bonita",
                "label_ar": "بونيتا",
                "label_fr": "Bonita"
            },
            {
                "value": 4893,
                "label": "Bonita Springs",
                "label_ar": "بونيتا سبرينغز",
                "label_fr": "Bonita Springs"
            },
            {
                "value": 4894,
                "label": "Bonlee",
                "label_ar": "بونلي",
                "label_fr": "Bonlee"
            },
            {
                "value": 4895,
                "label": "Bonne Terre",
                "label_ar": "بون تيري",
                "label_fr": "Bonne Terre"
            },
            {
                "value": 4896,
                "label": "Bonneau",
                "label_ar": "بونو",
                "label_fr": "Bonneau"
            },
            {
                "value": 4897,
                "label": "Bonner Springs",
                "label_ar": "بونر سبرينغز",
                "label_fr": "Bonner Springs"
            },
            {
                "value": 4898,
                "label": "Bonnerdale",
                "label_ar": "بونيرديل",
                "label_fr": "Bonnerdale"
            },
            {
                "value": 4899,
                "label": "Bonners Ferry",
                "label_ar": "بونرز فيري",
                "label_fr": "Bonners Ferry"
            },
            {
                "value": 4900,
                "label": "Bonney Lake",
                "label_ar": "بحيرة بوني",
                "label_fr": "Lac Bonney"
            },
            {
                "value": 4901,
                "label": "Bonnie",
                "label_ar": "بوني",
                "label_fr": "Bonnie"
            },
            {
                "value": 4902,
                "label": "Bonnieville",
                "label_ar": "بونيفيل",
                "label_fr": "Bonnieville"
            },
            {
                "value": 4903,
                "label": "Bonnots Mill",
                "label_ar": "مطحنة بونوتس",
                "label_fr": "Moulin de Bonnots"
            },
            {
                "value": 4904,
                "label": "Bonnyman",
                "label_ar": "بونيمان",
                "label_fr": "Bonnyman"
            },
            {
                "value": 4905,
                "label": "Bono",
                "label_ar": "بونو",
                "label_fr": "Bono"
            },
            {
                "value": 4906,
                "label": "Bonsall",
                "label_ar": "بونسال",
                "label_fr": "Bonsall"
            },
            {
                "value": 4907,
                "label": "Boody",
                "label_ar": "بودي",
                "label_fr": "Boody"
            },
            {
                "value": 4908,
                "label": "Booker",
                "label_ar": "بوكر",
                "label_fr": "Booker"
            },
            {
                "value": 4909,
                "label": "Boomer",
                "label_ar": "بومر",
                "label_fr": "Boomer"
            },
            {
                "value": 4910,
                "label": "Boone",
                "label_ar": "بون",
                "label_fr": "Boone"
            },
            {
                "value": 4911,
                "label": "Boones Mill",
                "label_ar": "مطحنة بونس",
                "label_fr": "Moulin Boones"
            },
            {
                "value": 4912,
                "label": "Booneville",
                "label_ar": "Booneville",
                "label_fr": "Booneville"
            },
            {
                "value": 4913,
                "label": "Boonsboro",
                "label_ar": "بونسبورو",
                "label_fr": "Boonsboro"
            },
            {
                "value": 4914,
                "label": "Boonton",
                "label_ar": "بونتون",
                "label_fr": "Boonton"
            },
            {
                "value": 4915,
                "label": "Boonville",
                "label_ar": "بونفيل",
                "label_fr": "Boonville"
            },
            {
                "value": 4916,
                "label": "Boothbay",
                "label_ar": "بوثباي",
                "label_fr": "Boothbay"
            },
            {
                "value": 4917,
                "label": "Boothbay Harbor",
                "label_ar": "ميناء بوثباي",
                "label_fr": "Port de Boothbay"
            },
            {
                "value": 4918,
                "label": "Borden",
                "label_ar": "بوردن",
                "label_fr": "Borden"
            },
            {
                "value": 4919,
                "label": "Bordentown",
                "label_ar": "بوردنتاون",
                "label_fr": "Bordentown"
            },
            {
                "value": 4920,
                "label": "Borger",
                "label_ar": "بورغر",
                "label_fr": "Borger"
            },
            {
                "value": 4921,
                "label": "Boring",
                "label_ar": "ممل",
                "label_fr": "Ennuyeuse"
            },
            {
                "value": 4922,
                "label": "Boron",
                "label_ar": "البورون",
                "label_fr": "Bore"
            },
            {
                "value": 4923,
                "label": "Borrego Springs",
                "label_ar": "بوريغو سبرينغز",
                "label_fr": "Borrego Springs"
            },
            {
                "value": 4924,
                "label": "Boscobel",
                "label_ar": "بوسكوبل",
                "label_fr": "Boscobel"
            },
            {
                "value": 4925,
                "label": "Bosque Farms",
                "label_ar": "مزارع بوسكي",
                "label_fr": "Fermes Bosque"
            },
            {
                "value": 4926,
                "label": "Bossier City",
                "label_ar": "مدينة بوسير",
                "label_fr": "Bossier City"
            },
            {
                "value": 4927,
                "label": "Bostic",
                "label_ar": "بوستيك",
                "label_fr": "Bostic"
            },
            {
                "value": 4928,
                "label": "Boston",
                "label_ar": "بوسطن",
                "label_fr": "Boston"
            },
            {
                "value": 4929,
                "label": "Bostwick",
                "label_ar": "بوستويك",
                "label_fr": "Bostwick"
            },
            {
                "value": 4930,
                "label": "Boswell",
                "label_ar": "بوسويل",
                "label_fr": "Boswell"
            },
            {
                "value": 4931,
                "label": "Bosworth",
                "label_ar": "بوسورث",
                "label_fr": "Bosworth"
            },
            {
                "value": 4932,
                "label": "Bothell",
                "label_ar": "بوثيل",
                "label_fr": "Bothell"
            },
            {
                "value": 4933,
                "label": "Botkins",
                "label_ar": "بوتكينز",
                "label_fr": "Botkins"
            },
            {
                "value": 4934,
                "label": "Bottineau",
                "label_ar": "بوتينو",
                "label_fr": "Bottineau"
            },
            {
                "value": 4935,
                "label": "Boulder",
                "label_ar": "بولدر",
                "label_fr": "Rocher"
            },
            {
                "value": 4936,
                "label": "Boulder City",
                "label_ar": "مدينة بولدر",
                "label_fr": "Boulder City"
            },
            {
                "value": 4937,
                "label": "Boulder Creek",
                "label_ar": "بولدر كريك",
                "label_fr": "Ruisseau Boulder"
            },
            {
                "value": 4938,
                "label": "Boulder Town",
                "label_ar": "بولدر تاون",
                "label_fr": "Ville de Boulder"
            },
            {
                "value": 4939,
                "label": "Boulevard",
                "label_ar": "شارع عريض تكتنفه الاشجار",
                "label_fr": "Boulevard"
            },
            {
                "value": 4940,
                "label": "Bound Brook",
                "label_ar": "منضم بروك",
                "label_fr": "Bound Brook"
            },
            {
                "value": 4941,
                "label": "Bountiful",
                "label_ar": "كريمة",
                "label_fr": "Généreux"
            },
            {
                "value": 4942,
                "label": "Bourbon",
                "label_ar": "بوربون",
                "label_fr": "Bourbon"
            },
            {
                "value": 4943,
                "label": "Bourbonnais",
                "label_ar": "بوربونايس",
                "label_fr": "Bourbonnais"
            },
            {
                "value": 4944,
                "label": "Bourg",
                "label_ar": "بورغ",
                "label_fr": "Bourg"
            },
            {
                "value": 4945,
                "label": "Boutte",
                "label_ar": "بوت",
                "label_fr": "Boutte"
            },
            {
                "value": 4946,
                "label": "Bovard",
                "label_ar": "بوفارد",
                "label_fr": "Bovard"
            },
            {
                "value": 4947,
                "label": "Bovey",
                "label_ar": "بوفي",
                "label_fr": "Bovey"
            },
            {
                "value": 4948,
                "label": "Bovina",
                "label_ar": "بوفينا",
                "label_fr": "Bovina"
            },
            {
                "value": 4949,
                "label": "Bovina Center",
                "label_ar": "مركز بوفينا",
                "label_fr": "Centre Bovina"
            },
            {
                "value": 4950,
                "label": "Bow",
                "label_ar": "ينحني",
                "label_fr": "Arc"
            },
            {
                "value": 4951,
                "label": "Bow Center",
                "label_ar": "مركز القوس",
                "label_fr": "Centre d'arc"
            },
            {
                "value": 4952,
                "label": "Bowdoin Center",
                "label_ar": "مركز بودوين",
                "label_fr": "Centre Bowdoin"
            },
            {
                "value": 4953,
                "label": "Bowdoinham",
                "label_ar": "بودوينهام",
                "label_fr": "Bowdoinham"
            },
            {
                "value": 4954,
                "label": "Bowdon",
                "label_ar": "بودون",
                "label_fr": "Bowdon"
            },
            {
                "value": 4955,
                "label": "Bowerston",
                "label_ar": "باورستون",
                "label_fr": "Bowerston"
            },
            {
                "value": 4956,
                "label": "Bowersville",
                "label_ar": "بوويرسفيل",
                "label_fr": "Bowersville"
            },
            {
                "value": 4957,
                "label": "Bowie",
                "label_ar": "بوي",
                "label_fr": "Bowie"
            },
            {
                "value": 4958,
                "label": "Bowler",
                "label_ar": "الرامي",
                "label_fr": "Melon"
            },
            {
                "value": 4959,
                "label": "Bowling Green",
                "label_ar": "بولينج جرين",
                "label_fr": "Terrain de boules"
            },
            {
                "value": 4960,
                "label": "Bowlus",
                "label_ar": "بولس",
                "label_fr": "Bowlus"
            },
            {
                "value": 4961,
                "label": "Bowman",
                "label_ar": "بومان",
                "label_fr": "Archer"
            },
            {
                "value": 4962,
                "label": "Bowmanstown",
                "label_ar": "بومانستاون",
                "label_fr": "Bowmanstown"
            },
            {
                "value": 4963,
                "label": "Bowmansville",
                "label_ar": "بومانسفيل",
                "label_fr": "Bowmansville"
            },
            {
                "value": 4964,
                "label": "Box Elder",
                "label_ar": "بوكس شيخ",
                "label_fr": "Ancien de la boîte"
            },
            {
                "value": 4965,
                "label": "Box Springs",
                "label_ar": "الينابيع مربع",
                "label_fr": "Box Springs"
            },
            {
                "value": 4966,
                "label": "Boxford",
                "label_ar": "بوكسفورد",
                "label_fr": "Boxford"
            },
            {
                "value": 4967,
                "label": "Boxholm",
                "label_ar": "بوكسهولم",
                "label_fr": "Boxholm"
            },
            {
                "value": 4968,
                "label": "Boyce",
                "label_ar": "بويس",
                "label_fr": "Boyce"
            },
            {
                "value": 4969,
                "label": "Boyceville",
                "label_ar": "Boyceville",
                "label_fr": "Boyceville"
            },
            {
                "value": 4970,
                "label": "Boyd",
                "label_ar": "بويد",
                "label_fr": "Boyd"
            },
            {
                "value": 4971,
                "label": "Boyden",
                "label_ar": "بويدن",
                "label_fr": "Boyden"
            },
            {
                "value": 4972,
                "label": "Boyds",
                "label_ar": "بويدز",
                "label_fr": "Boyds"
            },
            {
                "value": 4973,
                "label": "Boydton",
                "label_ar": "بويدتون",
                "label_fr": "Boydton"
            },
            {
                "value": 4974,
                "label": "Boyers",
                "label_ar": "بويرز",
                "label_fr": "Boyers"
            },
            {
                "value": 4975,
                "label": "Boyertown",
                "label_ar": "Boyertown",
                "label_fr": "Boyertown"
            },
            {
                "value": 4976,
                "label": "Boyle",
                "label_ar": "بويل",
                "label_fr": "Boyle"
            },
            {
                "value": 4977,
                "label": "Boylston",
                "label_ar": "بويلستون",
                "label_fr": "Boylston"
            },
            {
                "value": 4978,
                "label": "Boyne City",
                "label_ar": "بوين سيتي",
                "label_fr": "Boyne City"
            },
            {
                "value": 4979,
                "label": "Boyne Falls",
                "label_ar": "بوين فولز",
                "label_fr": "Boyne Falls"
            },
            {
                "value": 4980,
                "label": "Boynton",
                "label_ar": "بوينتون",
                "label_fr": "Boynton"
            },
            {
                "value": 4981,
                "label": "Boynton Beach",
                "label_ar": "بوينتون بيتش",
                "label_fr": "Plage de Boynton"
            },
            {
                "value": 4982,
                "label": "Bozeman",
                "label_ar": "بوزمان",
                "label_fr": "Bozeman"
            },
            {
                "value": 4983,
                "label": "Braceville",
                "label_ar": "براسفيل",
                "label_fr": "Braceville"
            },
            {
                "value": 4984,
                "label": "Bracey",
                "label_ar": "بريسى",
                "label_fr": "Bracey"
            },
            {
                "value": 4985,
                "label": "Brackenridge",
                "label_ar": "براكنريدج",
                "label_fr": "Brackenridge"
            },
            {
                "value": 4986,
                "label": "Brackettville",
                "label_ar": "براكيتفيل",
                "label_fr": "Brackettville"
            },
            {
                "value": 4987,
                "label": "Brackney",
                "label_ar": "براكني",
                "label_fr": "Brackney"
            },
            {
                "value": 4988,
                "label": "Braddock",
                "label_ar": "برادوك",
                "label_fr": "Braddock"
            },
            {
                "value": 4989,
                "label": "Bradenton",
                "label_ar": "برادنتون",
                "label_fr": "Bradenton"
            },
            {
                "value": 4990,
                "label": "Bradenton Beach",
                "label_ar": "شاطئ برادنتون",
                "label_fr": "Plage de Bradenton"
            },
            {
                "value": 4991,
                "label": "Bradenville",
                "label_ar": "برادنفيل",
                "label_fr": "Bradenville"
            },
            {
                "value": 4992,
                "label": "Bradford",
                "label_ar": "برادفورد",
                "label_fr": "Bradford"
            },
            {
                "value": 4993,
                "label": "Bradley",
                "label_ar": "برادلي",
                "label_fr": "Bradley"
            },
            {
                "value": 4994,
                "label": "Bradley Beach",
                "label_ar": "برادلي بيتش",
                "label_fr": "Bradley Beach"
            },
            {
                "value": 4995,
                "label": "Bradley Junction",
                "label_ar": "برادلي جانكشن",
                "label_fr": "Bradley Junction"
            },
            {
                "value": 4996,
                "label": "Bradner",
                "label_ar": "برادنر",
                "label_fr": "Bradner"
            },
            {
                "value": 4997,
                "label": "Bradshaw",
                "label_ar": "برادشو",
                "label_fr": "Bradshaw"
            },
            {
                "value": 4998,
                "label": "Brady",
                "label_ar": "برادي",
                "label_fr": "Brady"
            },
            {
                "value": 4999,
                "label": "Bradyville",
                "label_ar": "براديفيل",
                "label_fr": "Bradyville"
            },
            {
                "value": 5000,
                "label": "Bragg City",
                "label_ar": "مدينة براج",
                "label_fr": "Ville de Bragg"
            },
            {
                "value": 5001,
                "label": "Braham",
                "label_ar": "براهام",
                "label_fr": "Braham"
            },
            {
                "value": 5002,
                "label": "Braidwood",
                "label_ar": "برايدوود",
                "label_fr": "Braidwood"
            },
            {
                "value": 5003,
                "label": "Brainerd",
                "label_ar": "برينرد",
                "label_fr": "Brainerd"
            },
            {
                "value": 5004,
                "label": "Braintree",
                "label_ar": "برينتري",
                "label_fr": "Braintree"
            },
            {
                "value": 5005,
                "label": "Braithwaite",
                "label_ar": "بريثويت",
                "label_fr": "Braithwaite"
            },
            {
                "value": 5006,
                "label": "Braman",
                "label_ar": "برامان",
                "label_fr": "Braman"
            },
            {
                "value": 5007,
                "label": "Bramwell",
                "label_ar": "برامويل",
                "label_fr": "Bramwell"
            },
            {
                "value": 5008,
                "label": "Branch",
                "label_ar": "فرع شجرة",
                "label_fr": "Branche"
            },
            {
                "value": 5009,
                "label": "Branch Township",
                "label_ar": "فرع بلدة",
                "label_fr": "Canton de Branch"
            },
            {
                "value": 5010,
                "label": "Branchland",
                "label_ar": "برانشلاند",
                "label_fr": "Branchland"
            },
            {
                "value": 5011,
                "label": "Branchville",
                "label_ar": "برانشفيل",
                "label_fr": "Branchville"
            },
            {
                "value": 5012,
                "label": "Brandamore",
                "label_ar": "براندامور",
                "label_fr": "Brandamore"
            },
            {
                "value": 5013,
                "label": "Brandenburg",
                "label_ar": "براندنبورغ",
                "label_fr": "Brandebourg"
            },
            {
                "value": 5014,
                "label": "Brandon",
                "label_ar": "براندون",
                "label_fr": "Brandon"
            },
            {
                "value": 5015,
                "label": "Brandt",
                "label_ar": "براندت",
                "label_fr": "Brandt"
            },
            {
                "value": 5016,
                "label": "Brandy Station",
                "label_ar": "محطة براندي",
                "label_fr": "Station Brandy"
            },
            {
                "value": 5017,
                "label": "Brandywine",
                "label_ar": "برانديواين",
                "label_fr": "Brandywine"
            },
            {
                "value": 5018,
                "label": "Branford",
                "label_ar": "برانفورد",
                "label_fr": "Branford"
            },
            {
                "value": 5019,
                "label": "Branson",
                "label_ar": "برانسون",
                "label_fr": "Branson"
            },
            {
                "value": 5020,
                "label": "Brant",
                "label_ar": "برند",
                "label_fr": "Brant"
            },
            {
                "value": 5021,
                "label": "Brant Lake",
                "label_ar": "بحيرة برانت",
                "label_fr": "Lac Brant"
            },
            {
                "value": 5022,
                "label": "Brantingham",
                "label_ar": "برانتنغهام",
                "label_fr": "Brantingham"
            },
            {
                "value": 5023,
                "label": "Braselton",
                "label_ar": "براسلتون",
                "label_fr": "Braselton"
            },
            {
                "value": 5024,
                "label": "Brashear",
                "label_ar": "براشير",
                "label_fr": "Brashear"
            },
            {
                "value": 5025,
                "label": "Brasher Falls",
                "label_ar": "شلالات براشر",
                "label_fr": "Brasher Falls"
            },
            {
                "value": 5026,
                "label": "Brasstown",
                "label_ar": "براستاون",
                "label_fr": "Brasstown"
            },
            {
                "value": 5027,
                "label": "Brattleboro",
                "label_ar": "براتلبورو",
                "label_fr": "Brattleboro"
            },
            {
                "value": 5028,
                "label": "Brave",
                "label_ar": "شجاع",
                "label_fr": "Courageux"
            },
            {
                "value": 5029,
                "label": "Brawley",
                "label_ar": "براولي",
                "label_fr": "Brawley"
            },
            {
                "value": 5030,
                "label": "Braxton",
                "label_ar": "براكستون",
                "label_fr": "Braxton"
            },
            {
                "value": 5031,
                "label": "Brayton",
                "label_ar": "برايتون",
                "label_fr": "Brayton"
            },
            {
                "value": 5032,
                "label": "Brazil",
                "label_ar": "البرازيل",
                "label_fr": "Brésil"
            },
            {
                "value": 5033,
                "label": "Brazoria",
                "label_ar": "برازوريا",
                "label_fr": "Brazoria"
            },
            {
                "value": 5034,
                "label": "Brea",
                "label_ar": "بريا",
                "label_fr": "Brea"
            },
            {
                "value": 5035,
                "label": "Breaux Bridge",
                "label_ar": "جسر بريوكس",
                "label_fr": "Pont des Breaux"
            },
            {
                "value": 5036,
                "label": "Breckenridge",
                "label_ar": "بريكنريدج",
                "label_fr": "Breckenridge"
            },
            {
                "value": 5037,
                "label": "Brecksville",
                "label_ar": "بريكسفيل",
                "label_fr": "Brecksville"
            },
            {
                "value": 5038,
                "label": "Breda",
                "label_ar": "بريدا",
                "label_fr": "Breda"
            },
            {
                "value": 5039,
                "label": "Breese",
                "label_ar": "بريز",
                "label_fr": "Breese"
            },
            {
                "value": 5040,
                "label": "Breezewood",
                "label_ar": "بريزوود",
                "label_fr": "Breezewood"
            },
            {
                "value": 5041,
                "label": "Breezy Point",
                "label_ar": "نقطة النسيم",
                "label_fr": "Breezy Point"
            },
            {
                "value": 5042,
                "label": "Breinigsville",
                "label_ar": "برينغسفيل",
                "label_fr": "Breinigsville"
            },
            {
                "value": 5043,
                "label": "Bremen",
                "label_ar": "بريمن",
                "label_fr": "Brême"
            },
            {
                "value": 5044,
                "label": "Bremerton",
                "label_ar": "بريميرتون",
                "label_fr": "Bremerton"
            },
            {
                "value": 5045,
                "label": "Bremo Bluff",
                "label_ar": "بريمو بلاف",
                "label_fr": "Bremo Bluff"
            },
            {
                "value": 5046,
                "label": "Brenham",
                "label_ar": "برينهام",
                "label_fr": "Brenham"
            },
            {
                "value": 5047,
                "label": "Brent",
                "label_ar": "برنت",
                "label_fr": "Brent"
            },
            {
                "value": 5048,
                "label": "Brentwood",
                "label_ar": "برينتوود",
                "label_fr": "Brentwood"
            },
            {
                "value": 5049,
                "label": "Bretton Woods",
                "label_ar": "بريتون وودز",
                "label_fr": "Bretton Woods"
            },
            {
                "value": 5050,
                "label": "Brevard",
                "label_ar": "بريفارد",
                "label_fr": "Brevard"
            },
            {
                "value": 5051,
                "label": "Brewer",
                "label_ar": "بروير",
                "label_fr": "Brasseur"
            },
            {
                "value": 5052,
                "label": "Brewerton",
                "label_ar": "بريورتون",
                "label_fr": "Brewerton"
            },
            {
                "value": 5053,
                "label": "Brewster",
                "label_ar": "بروستر",
                "label_fr": "Brewster"
            },
            {
                "value": 5054,
                "label": "Brewton",
                "label_ar": "بروتون",
                "label_fr": "Brewton"
            },
            {
                "value": 5055,
                "label": "Brian Head",
                "label_ar": "بريان هيد",
                "label_fr": "Brian Head"
            },
            {
                "value": 5056,
                "label": "Briarcliff Manor",
                "label_ar": "برياركليف مانور",
                "label_fr": "Manoir de Briarcliff"
            },
            {
                "value": 5057,
                "label": "Bricelyn",
                "label_ar": "بريسلين",
                "label_fr": "Bricelyn"
            },
            {
                "value": 5058,
                "label": "Briceville",
                "label_ar": "بريسفيل",
                "label_fr": "Briceville"
            },
            {
                "value": 5059,
                "label": "Brick Township",
                "label_ar": "بلدة القرميد",
                "label_fr": "Canton de Brick"
            },
            {
                "value": 5060,
                "label": "Bridge City",
                "label_ar": "بريدج سيتي",
                "label_fr": "Bridge City"
            },
            {
                "value": 5061,
                "label": "Bridgehampton",
                "label_ar": "بريدجهامبتون",
                "label_fr": "Bridgehampton"
            },
            {
                "value": 5062,
                "label": "Bridgeport",
                "label_ar": "بريدجبورت",
                "label_fr": "Bridgeport"
            },
            {
                "value": 5063,
                "label": "Bridger",
                "label_ar": "بريدجر",
                "label_fr": "Bridger"
            },
            {
                "value": 5064,
                "label": "Bridgeton",
                "label_ar": "بريدجتون",
                "label_fr": "Bridgeton"
            },
            {
                "value": 5065,
                "label": "Bridgeview",
                "label_ar": "بريدجفيو",
                "label_fr": "Bridgeview"
            },
            {
                "value": 5066,
                "label": "Bridgeville",
                "label_ar": "بريدجفيل",
                "label_fr": "Bridgeville"
            },
            {
                "value": 5067,
                "label": "Bridgewater",
                "label_ar": "بريدجووتر",
                "label_fr": "Bridgewater"
            },
            {
                "value": 5068,
                "label": "Bridgewater Corners",
                "label_ar": "زوايا Bridgewater",
                "label_fr": "Bridgewater Corners"
            },
            {
                "value": 5069,
                "label": "Bridgman",
                "label_ar": "بريدجمان",
                "label_fr": "Bridgman"
            },
            {
                "value": 5070,
                "label": "Bridgton",
                "label_ar": "بريدجتون",
                "label_fr": "Bridgton"
            },
            {
                "value": 5071,
                "label": "Brielle",
                "label_ar": "بريل",
                "label_fr": "Brielle"
            },
            {
                "value": 5072,
                "label": "Brier Hill",
                "label_ar": "برير هيل",
                "label_fr": "Brier Hill"
            },
            {
                "value": 5073,
                "label": "Brierfield",
                "label_ar": "بريرفيلد",
                "label_fr": "Brierfield"
            },
            {
                "value": 5074,
                "label": "Brigantine",
                "label_ar": "مركب شراعي",
                "label_fr": "Brigantin"
            },
            {
                "value": 5075,
                "label": "Briggs",
                "label_ar": "بريجز",
                "label_fr": "Briggs"
            },
            {
                "value": 5076,
                "label": "Briggsdale",
                "label_ar": "بريجسدال",
                "label_fr": "Briggsdale"
            },
            {
                "value": 5077,
                "label": "Brigham City",
                "label_ar": "مدينة بريغهام",
                "label_fr": "Brigham City"
            },
            {
                "value": 5078,
                "label": "Brighton",
                "label_ar": "برايتون",
                "label_fr": "Brighton"
            },
            {
                "value": 5079,
                "label": "Brightwaters",
                "label_ar": "برايتواترز",
                "label_fr": "Brightwaters"
            },
            {
                "value": 5080,
                "label": "Brightwood",
                "label_ar": "برايتوود",
                "label_fr": "Brightwood"
            },
            {
                "value": 5081,
                "label": "Brilliant",
                "label_ar": "متألق",
                "label_fr": "Brillant"
            },
            {
                "value": 5082,
                "label": "Brillion",
                "label_ar": "بريليون",
                "label_fr": "Brillion"
            },
            {
                "value": 5083,
                "label": "Brimfield",
                "label_ar": "بريمفيلد",
                "label_fr": "Brimfield"
            },
            {
                "value": 5084,
                "label": "Brimley",
                "label_ar": "بريملي",
                "label_fr": "Brimley"
            },
            {
                "value": 5085,
                "label": "Bringhurst",
                "label_ar": "Bringhurst",
                "label_fr": "Bringhurst"
            },
            {
                "value": 5086,
                "label": "Brinkley",
                "label_ar": "برينكلي",
                "label_fr": "Brinkley"
            },
            {
                "value": 5087,
                "label": "Brisbane",
                "label_ar": "بريسبان",
                "label_fr": "Brisbane"
            },
            {
                "value": 5088,
                "label": "Briscoe",
                "label_ar": "بريسكو",
                "label_fr": "Briscoe"
            },
            {
                "value": 5089,
                "label": "Bristol",
                "label_ar": "بريستول",
                "label_fr": "Bristol"
            },
            {
                "value": 5090,
                "label": "Bristolville",
                "label_ar": "بريستولفيل",
                "label_fr": "Bristolville"
            },
            {
                "value": 5091,
                "label": "Bristow",
                "label_ar": "بريستو",
                "label_fr": "Bristow"
            },
            {
                "value": 5092,
                "label": "Britt",
                "label_ar": "بريت",
                "label_fr": "Britt"
            },
            {
                "value": 5093,
                "label": "Brittany",
                "label_ar": "بريتاني",
                "label_fr": "Bretagne"
            },
            {
                "value": 5094,
                "label": "Britton",
                "label_ar": "بريتون",
                "label_fr": "Britton"
            },
            {
                "value": 5095,
                "label": "Broad Brook",
                "label_ar": "برود بروك",
                "label_fr": "Broad Brook"
            },
            {
                "value": 5096,
                "label": "Broad Run",
                "label_ar": "واسع المدى",
                "label_fr": "Large piste"
            },
            {
                "value": 5097,
                "label": "Broadalbin",
                "label_ar": "برودالبين",
                "label_fr": "Broadalbin"
            },
            {
                "value": 5098,
                "label": "Broaddus",
                "label_ar": "برودوس",
                "label_fr": "Broaddus"
            },
            {
                "value": 5099,
                "label": "Broadlands",
                "label_ar": "برودلاندز",
                "label_fr": "Broadlands"
            },
            {
                "value": 5100,
                "label": "Broadus",
                "label_ar": "برودس",
                "label_fr": "Broadus"
            },
            {
                "value": 5101,
                "label": "Broadview",
                "label_ar": "نظرة واسعة",
                "label_fr": "Large vue"
            },
            {
                "value": 5102,
                "label": "Broadview Heights",
                "label_ar": "مرتفعات برودفيو",
                "label_fr": "Broadview Heights"
            },
            {
                "value": 5103,
                "label": "Broadway",
                "label_ar": "برودواي",
                "label_fr": "Broadway"
            },
            {
                "value": 5104,
                "label": "Brockport",
                "label_ar": "بروكبورت",
                "label_fr": "Brockport"
            },
            {
                "value": 5105,
                "label": "Brockton",
                "label_ar": "بروكتون",
                "label_fr": "Brockton"
            },
            {
                "value": 5106,
                "label": "Brockway",
                "label_ar": "بروكواي",
                "label_fr": "Brockway"
            },
            {
                "value": 5107,
                "label": "Brocton",
                "label_ar": "بروكتون",
                "label_fr": "Brocton"
            },
            {
                "value": 5108,
                "label": "Brodhead",
                "label_ar": "برودهيد",
                "label_fr": "Brodhead"
            },
            {
                "value": 5109,
                "label": "Brodheadsville",
                "label_ar": "Brodheadsville",
                "label_fr": "Brodheadsville"
            },
            {
                "value": 5110,
                "label": "Brodnax",
                "label_ar": "برودناكس",
                "label_fr": "Brodnax"
            },
            {
                "value": 5111,
                "label": "Brogue",
                "label_ar": "البروغ حذاء أيرلندي",
                "label_fr": "Brogue"
            },
            {
                "value": 5112,
                "label": "Brohard",
                "label_ar": "بروهارد",
                "label_fr": "Brohard"
            },
            {
                "value": 5113,
                "label": "Broken Arrow",
                "label_ar": "السهم المكسور",
                "label_fr": "Flêche brisée"
            },
            {
                "value": 5114,
                "label": "Broken Bow",
                "label_ar": "القوس المكسور",
                "label_fr": "Arc cassé"
            },
            {
                "value": 5115,
                "label": "Bronaugh",
                "label_ar": "برونو",
                "label_fr": "Bronaugh"
            },
            {
                "value": 5116,
                "label": "Bronson",
                "label_ar": "برونسون",
                "label_fr": "Bronson"
            },
            {
                "value": 5117,
                "label": "Bronston",
                "label_ar": "برونستون",
                "label_fr": "Bronston"
            },
            {
                "value": 5118,
                "label": "Bronte",
                "label_ar": "برونتي",
                "label_fr": "Bronte"
            },
            {
                "value": 5119,
                "label": "Bronwood",
                "label_ar": "برونوود",
                "label_fr": "Bronwood"
            },
            {
                "value": 5120,
                "label": "Bronxville",
                "label_ar": "برونكسفيل",
                "label_fr": "Bronxville"
            },
            {
                "value": 5121,
                "label": "Brook",
                "label_ar": "بروك",
                "label_fr": "Ruisseau"
            },
            {
                "value": 5122,
                "label": "Brook Park",
                "label_ar": "بروك بارك",
                "label_fr": "Brook Park"
            },
            {
                "value": 5123,
                "label": "Brookdale",
                "label_ar": "بروكديل",
                "label_fr": "Brookdale"
            },
            {
                "value": 5124,
                "label": "Brookeland",
                "label_ar": "بروكلاند",
                "label_fr": "Brookeland"
            },
            {
                "value": 5125,
                "label": "Brooker",
                "label_ar": "بروكر",
                "label_fr": "Brooker"
            },
            {
                "value": 5126,
                "label": "Brookeville",
                "label_ar": "بروكفيل",
                "label_fr": "Brookeville"
            },
            {
                "value": 5127,
                "label": "Brookfield",
                "label_ar": "بروكفيلد",
                "label_fr": "Brookfield"
            },
            {
                "value": 5128,
                "label": "Brookhaven",
                "label_ar": "بروكهافن",
                "label_fr": "Brookhaven"
            },
            {
                "value": 5129,
                "label": "Brookings",
                "label_ar": "بروكينجز",
                "label_fr": "Brookings"
            },
            {
                "value": 5130,
                "label": "Brookland",
                "label_ar": "بروكلاند",
                "label_fr": "Brookland"
            },
            {
                "value": 5131,
                "label": "Brooklandville",
                "label_ar": "بروكلاندفيل",
                "label_fr": "Brooklandville"
            },
            {
                "value": 5132,
                "label": "Brooklet",
                "label_ar": "بروكليت",
                "label_fr": "Ruisselet"
            },
            {
                "value": 5133,
                "label": "Brooklin",
                "label_ar": "بروكلين",
                "label_fr": "Brooklin"
            },
            {
                "value": 5134,
                "label": "Brookline",
                "label_ar": "بروكلين",
                "label_fr": "Brookline"
            },
            {
                "value": 5135,
                "label": "Brooklyn",
                "label_ar": "بروكلين",
                "label_fr": "Brooklyn"
            },
            {
                "value": 5136,
                "label": "Brookport",
                "label_ar": "بروكبورت",
                "label_fr": "Brookport"
            },
            {
                "value": 5137,
                "label": "Brooks",
                "label_ar": "بروكس",
                "label_fr": "Brooks"
            },
            {
                "value": 5138,
                "label": "Brookshire",
                "label_ar": "بروكشاير",
                "label_fr": "Brookshire"
            },
            {
                "value": 5139,
                "label": "Brookston",
                "label_ar": "بروكستون",
                "label_fr": "Brookston"
            },
            {
                "value": 5140,
                "label": "Brooksville",
                "label_ar": "بروكسفيل",
                "label_fr": "Brooksville"
            },
            {
                "value": 5141,
                "label": "Brookton",
                "label_ar": "بروكتون",
                "label_fr": "Brookton"
            },
            {
                "value": 5142,
                "label": "Brooktondale",
                "label_ar": "Brooktondale",
                "label_fr": "Brooktondale"
            },
            {
                "value": 5143,
                "label": "Brookville",
                "label_ar": "بروكفيل",
                "label_fr": "Brookville"
            },
            {
                "value": 5144,
                "label": "Brookwood",
                "label_ar": "بروكوود",
                "label_fr": "Brookwood"
            },
            {
                "value": 5145,
                "label": "Broomall",
                "label_ar": "برومال",
                "label_fr": "Balai"
            },
            {
                "value": 5146,
                "label": "Broomfield",
                "label_ar": "برومفيلد",
                "label_fr": "Broomfield"
            },
            {
                "value": 5147,
                "label": "Brooten",
                "label_ar": "بروتين",
                "label_fr": "Brooten"
            },
            {
                "value": 5148,
                "label": "Broseley",
                "label_ar": "بروسلي",
                "label_fr": "Broseley"
            },
            {
                "value": 5149,
                "label": "Broughton",
                "label_ar": "جلبت على",
                "label_fr": "Introduit le"
            },
            {
                "value": 5150,
                "label": "Broussard",
                "label_ar": "بروسارد",
                "label_fr": "Broussard"
            },
            {
                "value": 5151,
                "label": "Browder",
                "label_ar": "براودر",
                "label_fr": "Browder"
            },
            {
                "value": 5152,
                "label": "Brown City",
                "label_ar": "مدينة براون",
                "label_fr": "Ville brune"
            },
            {
                "value": 5153,
                "label": "Brownell",
                "label_ar": "براونيل",
                "label_fr": "Brownell"
            },
            {
                "value": 5154,
                "label": "Brownfield",
                "label_ar": "براونفيلد",
                "label_fr": "Brownfield"
            },
            {
                "value": 5155,
                "label": "Browning",
                "label_ar": "براوننج",
                "label_fr": "Brunissement"
            },
            {
                "value": 5156,
                "label": "Browns Mills",
                "label_ar": "براونز ميلز",
                "label_fr": "Browns Mills"
            },
            {
                "value": 5157,
                "label": "Browns Summit",
                "label_ar": "قمة براونز",
                "label_fr": "Sommet des Browns"
            },
            {
                "value": 5158,
                "label": "Browns Valley",
                "label_ar": "وادي براونز",
                "label_fr": "Browns Valley"
            },
            {
                "value": 5159,
                "label": "Brownsboro",
                "label_ar": "براونسبورو",
                "label_fr": "Brownsboro"
            },
            {
                "value": 5160,
                "label": "Brownsburg",
                "label_ar": "براونزبرج",
                "label_fr": "Brownsburg"
            },
            {
                "value": 5161,
                "label": "Brownsdale",
                "label_ar": "براونزديل",
                "label_fr": "Brownsdale"
            },
            {
                "value": 5162,
                "label": "Brownstown",
                "label_ar": "براونستاون",
                "label_fr": "Brownstown"
            },
            {
                "value": 5163,
                "label": "Brownsville",
                "label_ar": "براونزفيل",
                "label_fr": "Brownsville"
            },
            {
                "value": 5164,
                "label": "Browntown",
                "label_ar": "براونتاون",
                "label_fr": "Browntown"
            },
            {
                "value": 5165,
                "label": "Brownville",
                "label_ar": "براونفيل",
                "label_fr": "Brownville"
            },
            {
                "value": 5166,
                "label": "Brownwood",
                "label_ar": "براونوود",
                "label_fr": "Brownwood"
            },
            {
                "value": 5167,
                "label": "Broxton",
                "label_ar": "بروكستون",
                "label_fr": "Broxton"
            },
            {
                "value": 5168,
                "label": "Bruce",
                "label_ar": "بروس",
                "label_fr": "Bruce"
            },
            {
                "value": 5169,
                "label": "Bruceton",
                "label_ar": "بروتون",
                "label_fr": "Bruceton"
            },
            {
                "value": 5170,
                "label": "Bruceton Mills",
                "label_ar": "بروتون ميلز",
                "label_fr": "Bruceton Mills"
            },
            {
                "value": 5171,
                "label": "Bruceville",
                "label_ar": "بروسفيل",
                "label_fr": "Bruceville"
            },
            {
                "value": 5172,
                "label": "Bruin",
                "label_ar": "بروين",
                "label_fr": "Bruin"
            },
            {
                "value": 5173,
                "label": "Brule",
                "label_ar": "برول",
                "label_fr": "Brule"
            },
            {
                "value": 5174,
                "label": "Brundidge",
                "label_ar": "بروندج",
                "label_fr": "Brundidge"
            },
            {
                "value": 5175,
                "label": "Bruner",
                "label_ar": "برونر",
                "label_fr": "Bruner"
            },
            {
                "value": 5176,
                "label": "Bruni",
                "label_ar": "بروني",
                "label_fr": "Bruni"
            },
            {
                "value": 5177,
                "label": "Bruning",
                "label_ar": "برونينج",
                "label_fr": "Bruning"
            },
            {
                "value": 5178,
                "label": "Bruno",
                "label_ar": "برونو",
                "label_fr": "Bruno"
            },
            {
                "value": 5179,
                "label": "Brunsville",
                "label_ar": "برونسفيل",
                "label_fr": "Brunsville"
            },
            {
                "value": 5180,
                "label": "Brunswick",
                "label_ar": "برونزويك",
                "label_fr": "Brunswick"
            },
            {
                "value": 5181,
                "label": "Brush",
                "label_ar": "فرشاة",
                "label_fr": "Brosse"
            },
            {
                "value": 5182,
                "label": "Brush Prairie",
                "label_ar": "فرشاة المرج",
                "label_fr": "Brosse Prairie"
            },
            {
                "value": 5183,
                "label": "Brushton",
                "label_ar": "برشتون",
                "label_fr": "Brushton"
            },
            {
                "value": 5184,
                "label": "Brusly",
                "label_ar": "بروسلي",
                "label_fr": "Brusly"
            },
            {
                "value": 5185,
                "label": "Brussels",
                "label_ar": "بروكسل",
                "label_fr": "Bruxelles"
            },
            {
                "value": 5186,
                "label": "Bryan",
                "label_ar": "بريان",
                "label_fr": "Bryan"
            },
            {
                "value": 5187,
                "label": "Bryans Road",
                "label_ar": "طريق بريانز",
                "label_fr": "Route de Bryans"
            },
            {
                "value": 5188,
                "label": "Bryant",
                "label_ar": "براينت",
                "label_fr": "Bryant"
            },
            {
                "value": 5189,
                "label": "Bryant Pond",
                "label_ar": "براينت بوند",
                "label_fr": "Étang Bryant"
            },
            {
                "value": 5190,
                "label": "Bryantville",
                "label_ar": "بريانتفيل",
                "label_fr": "Bryantville"
            },
            {
                "value": 5191,
                "label": "Bryce Canyon",
                "label_ar": "برايس كانيون",
                "label_fr": "Bryce Canyon"
            },
            {
                "value": 5192,
                "label": "Bryce Canyon City",
                "label_ar": "مدينة برايس كانيون",
                "label_fr": "Bryce Canyon City"
            },
            {
                "value": 5193,
                "label": "Bryceville",
                "label_ar": "بريسفيل",
                "label_fr": "Bryceville"
            },
            {
                "value": 5194,
                "label": "Bryn Athyn",
                "label_ar": "برين أثين",
                "label_fr": "Bryn Athyn"
            },
            {
                "value": 5195,
                "label": "Bryn Mawr",
                "label_ar": "برين ماور",
                "label_fr": "Bryn Mawr"
            },
            {
                "value": 5196,
                "label": "Bryson",
                "label_ar": "بريسون",
                "label_fr": "Bryson"
            },
            {
                "value": 5197,
                "label": "Bryson City",
                "label_ar": "بريسون سيتي",
                "label_fr": "Ville de Bryson"
            },
            {
                "value": 5198,
                "label": "Buchanan",
                "label_ar": "بوكانان",
                "label_fr": "Buchanan"
            },
            {
                "value": 5199,
                "label": "Buchanan Dam",
                "label_ar": "سد بوكانان",
                "label_fr": "Barrage de Buchanan"
            },
            {
                "value": 5200,
                "label": "Buckatunna",
                "label_ar": "بوكاتونا",
                "label_fr": "Buckatunna"
            },
            {
                "value": 5201,
                "label": "Buckeye",
                "label_ar": "باكاي",
                "label_fr": "Buckeye"
            },
            {
                "value": 5202,
                "label": "Buckeystown",
                "label_ar": "باكستاون",
                "label_fr": "Buckeystown"
            },
            {
                "value": 5203,
                "label": "Buckfield",
                "label_ar": "باكفيلد",
                "label_fr": "Buckfield"
            },
            {
                "value": 5204,
                "label": "Buckhannon",
                "label_ar": "بوكانون",
                "label_fr": "Buckhannon"
            },
            {
                "value": 5205,
                "label": "Buckhead",
                "label_ar": "باكهيد",
                "label_fr": "Buckhead"
            },
            {
                "value": 5206,
                "label": "Buckholts",
                "label_ar": "بوكهولتس",
                "label_fr": "Buckholts"
            },
            {
                "value": 5207,
                "label": "Buckingham",
                "label_ar": "باكنغهام",
                "label_fr": "Buckingham"
            },
            {
                "value": 5208,
                "label": "Buckingham Township",
                "label_ar": "بلدة باكنغهام",
                "label_fr": "Canton de Buckingham"
            },
            {
                "value": 5209,
                "label": "Buckland",
                "label_ar": "باكلاند",
                "label_fr": "Buckland"
            },
            {
                "value": 5210,
                "label": "Buckley",
                "label_ar": "باكلي",
                "label_fr": "Buckley"
            },
            {
                "value": 5211,
                "label": "Bucklin",
                "label_ar": "بوكلين",
                "label_fr": "Bucklin"
            },
            {
                "value": 5212,
                "label": "Buckner",
                "label_ar": "بوكنر",
                "label_fr": "Buckner"
            },
            {
                "value": 5213,
                "label": "Bucksport",
                "label_ar": "باكسبورت",
                "label_fr": "Bucksport"
            },
            {
                "value": 5214,
                "label": "Bucyrus",
                "label_ar": "بوكيروس",
                "label_fr": "Bucyrus"
            },
            {
                "value": 5215,
                "label": "Bud",
                "label_ar": "برعم",
                "label_fr": "Bourgeon"
            },
            {
                "value": 5216,
                "label": "Buda",
                "label_ar": "بودا",
                "label_fr": "Buda"
            },
            {
                "value": 5217,
                "label": "Budd Lake",
                "label_ar": "بحيرة بود",
                "label_fr": "Lac Budd"
            },
            {
                "value": 5218,
                "label": "Bude",
                "label_ar": "بودي",
                "label_fr": "Bude"
            },
            {
                "value": 5219,
                "label": "Buellton",
                "label_ar": "بويلتون",
                "label_fr": "Buellton"
            },
            {
                "value": 5220,
                "label": "Buena",
                "label_ar": "بوينا",
                "label_fr": "Buena"
            },
            {
                "value": 5221,
                "label": "Buena Park",
                "label_ar": "بوينا بارك",
                "label_fr": "Parc Buena"
            },
            {
                "value": 5222,
                "label": "Buena Vista",
                "label_ar": "بوينا فيستا",
                "label_fr": "Buena Vista"
            },
            {
                "value": 5223,
                "label": "Buffalo",
                "label_ar": "الجاموس",
                "label_fr": "Buffle"
            },
            {
                "value": 5224,
                "label": "Buffalo Center",
                "label_ar": "مركز الجاموس",
                "label_fr": "Centre Buffalo"
            },
            {
                "value": 5225,
                "label": "Buffalo Gap",
                "label_ar": "فجوة الجاموس",
                "label_fr": "Buffalo Gap"
            },
            {
                "value": 5226,
                "label": "Buffalo Grove",
                "label_ar": "بوفالو جروف",
                "label_fr": "Buffalo Grove"
            },
            {
                "value": 5227,
                "label": "Buffalo Lake",
                "label_ar": "بحيرة بوفالو",
                "label_fr": "Buffalo Lake"
            },
            {
                "value": 5228,
                "label": "Buffalo Valley",
                "label_ar": "وادي بوفالو",
                "label_fr": "Buffalo Valley"
            },
            {
                "value": 5229,
                "label": "Buford",
                "label_ar": "بوفورد",
                "label_fr": "Buford"
            },
            {
                "value": 5230,
                "label": "Buhl",
                "label_ar": "بوهل",
                "label_fr": "Buhl"
            },
            {
                "value": 5231,
                "label": "Buhler",
                "label_ar": "بوهلر",
                "label_fr": "Buhler"
            },
            {
                "value": 5232,
                "label": "Buies Creek",
                "label_ar": "بويز كريك",
                "label_fr": "Buies Creek"
            },
            {
                "value": 5233,
                "label": "Bulan",
                "label_ar": "بولان",
                "label_fr": "Bulan"
            },
            {
                "value": 5234,
                "label": "Bulger",
                "label_ar": "بولجر",
                "label_fr": "Bulger"
            },
            {
                "value": 5235,
                "label": "Bull Shoals",
                "label_ar": "الثور شولز",
                "label_fr": "Bull Shoals"
            },
            {
                "value": 5236,
                "label": "Bullard",
                "label_ar": "بولارد",
                "label_fr": "Bullard"
            },
            {
                "value": 5237,
                "label": "Bullhead",
                "label_ar": "بولهيد",
                "label_fr": "Bullhead"
            },
            {
                "value": 5238,
                "label": "Bullhead City",
                "label_ar": "بولهيد سيتي",
                "label_fr": "Bullhead City"
            },
            {
                "value": 5239,
                "label": "Bullock",
                "label_ar": "بولوك",
                "label_fr": "Bœuf"
            },
            {
                "value": 5240,
                "label": "Bulls Gap",
                "label_ar": "فجوة الثيران",
                "label_fr": "Bulls Gap"
            },
            {
                "value": 5241,
                "label": "Bullville",
                "label_ar": "بولفيل",
                "label_fr": "Bullville"
            },
            {
                "value": 5242,
                "label": "Bulpitt",
                "label_ar": "بولبيت",
                "label_fr": "Bulpitt"
            },
            {
                "value": 5243,
                "label": "Bulverde",
                "label_ar": "بولفيردي",
                "label_fr": "Bulverde"
            },
            {
                "value": 5244,
                "label": "Bumpass",
                "label_ar": "بومباس",
                "label_fr": "Bumpass"
            },
            {
                "value": 5245,
                "label": "Bumpus Mills",
                "label_ar": "مطاحن بومبوس",
                "label_fr": "Bumpus Mills"
            },
            {
                "value": 5246,
                "label": "Buna",
                "label_ar": "بونا",
                "label_fr": "Buna"
            },
            {
                "value": 5247,
                "label": "Bunceton",
                "label_ar": "Bunceton",
                "label_fr": "Bunceton"
            },
            {
                "value": 5248,
                "label": "Bunch",
                "label_ar": "حفنة",
                "label_fr": "Bouquet"
            },
            {
                "value": 5249,
                "label": "Buncombe",
                "label_ar": "بونكومب",
                "label_fr": "Buncombe"
            },
            {
                "value": 5250,
                "label": "Bunker Hill",
                "label_ar": "بنكر هيل",
                "label_fr": "Bunker Hill"
            },
            {
                "value": 5251,
                "label": "Bunkie",
                "label_ar": "بنكي",
                "label_fr": "Bunkie"
            },
            {
                "value": 5252,
                "label": "Bunn",
                "label_ar": "بون",
                "label_fr": "Lapin"
            },
            {
                "value": 5253,
                "label": "Bunnell",
                "label_ar": "بونيل",
                "label_fr": "Bunnell"
            },
            {
                "value": 5254,
                "label": "Bunnlevel",
                "label_ar": "بونليفيل",
                "label_fr": "Bunnlevel"
            },
            {
                "value": 5255,
                "label": "Bunola",
                "label_ar": "بونولا",
                "label_fr": "Bunola"
            },
            {
                "value": 5256,
                "label": "Buras",
                "label_ar": "بوراس",
                "label_fr": "Buras"
            },
            {
                "value": 5257,
                "label": "Burbank",
                "label_ar": "بوربانك",
                "label_fr": "Burbank"
            },
            {
                "value": 5258,
                "label": "Burden",
                "label_ar": "عبء",
                "label_fr": "Fardeau"
            },
            {
                "value": 5259,
                "label": "Burdett",
                "label_ar": "بوردت",
                "label_fr": "Burdett"
            },
            {
                "value": 5260,
                "label": "Burdick",
                "label_ar": "بورديك",
                "label_fr": "Burdick"
            },
            {
                "value": 5261,
                "label": "Bureau",
                "label_ar": "مكتب",
                "label_fr": "bureau"
            },
            {
                "value": 5262,
                "label": "Burgaw",
                "label_ar": "بورغو",
                "label_fr": "Burgaw"
            },
            {
                "value": 5263,
                "label": "Burgess",
                "label_ar": "بورغس",
                "label_fr": "Bourgeois"
            },
            {
                "value": 5264,
                "label": "Burgettstown",
                "label_ar": "بورغيتستاون",
                "label_fr": "Burgettstown"
            },
            {
                "value": 5265,
                "label": "Burgin",
                "label_ar": "بورجين",
                "label_fr": "Burgin"
            },
            {
                "value": 5266,
                "label": "Burkburnett",
                "label_ar": "بوركبرنيت",
                "label_fr": "Burkburnett"
            },
            {
                "value": 5267,
                "label": "Burke",
                "label_ar": "بورك",
                "label_fr": "Burke"
            },
            {
                "value": 5268,
                "label": "Burkesville",
                "label_ar": "بوركيسفيل",
                "label_fr": "Burkesville"
            },
            {
                "value": 5269,
                "label": "Burket",
                "label_ar": "بركات",
                "label_fr": "Burket"
            },
            {
                "value": 5270,
                "label": "Burkeville",
                "label_ar": "بوركفيل",
                "label_fr": "Burkeville"
            },
            {
                "value": 5271,
                "label": "Burleson",
                "label_ar": "بورليسون",
                "label_fr": "Burleson"
            },
            {
                "value": 5272,
                "label": "Burley",
                "label_ar": "بيرلي",
                "label_fr": "Burley"
            },
            {
                "value": 5273,
                "label": "Burlingame",
                "label_ar": "بورلينجيم",
                "label_fr": "Burlingame"
            },
            {
                "value": 5274,
                "label": "Burlington",
                "label_ar": "برلنغتون",
                "label_fr": "Burlington"
            },
            {
                "value": 5275,
                "label": "Burlington Flats",
                "label_ar": "شقق برلنغتون",
                "label_fr": "Appartements Burlington"
            },
            {
                "value": 5276,
                "label": "Burlington Junction",
                "label_ar": "مفرق برلنغتون",
                "label_fr": "Burlington Junction"
            },
            {
                "value": 5277,
                "label": "Burlison",
                "label_ar": "بورليسون",
                "label_fr": "Burlison"
            },
            {
                "value": 5278,
                "label": "Burnet",
                "label_ar": "بيرنت",
                "label_fr": "Burnet"
            },
            {
                "value": 5279,
                "label": "Burnettsville",
                "label_ar": "بورنيتسفيل",
                "label_fr": "Burnettsville"
            },
            {
                "value": 5280,
                "label": "Burney",
                "label_ar": "بيرني",
                "label_fr": "Burney"
            },
            {
                "value": 5281,
                "label": "Burneyville",
                "label_ar": "بورنيفيل",
                "label_fr": "Burneyville"
            },
            {
                "value": 5282,
                "label": "Burnham",
                "label_ar": "بورنهام",
                "label_fr": "Burnham"
            },
            {
                "value": 5283,
                "label": "Burns",
                "label_ar": "الحروق",
                "label_fr": "Brûlures"
            },
            {
                "value": 5284,
                "label": "Burns Flat",
                "label_ar": "شقة حروق",
                "label_fr": "Brûle à plat"
            },
            {
                "value": 5285,
                "label": "Burnside",
                "label_ar": "بيرنسايد",
                "label_fr": "Burnside"
            },
            {
                "value": 5286,
                "label": "Burnsville",
                "label_ar": "بيرنزفيل",
                "label_fr": "Burnsville"
            },
            {
                "value": 5287,
                "label": "Burnt Hills",
                "label_ar": "بيرنت هيلز",
                "label_fr": "Burnt Hills"
            },
            {
                "value": 5288,
                "label": "Burr",
                "label_ar": "لدغ",
                "label_fr": "Bavure"
            },
            {
                "value": 5289,
                "label": "Burr Hill",
                "label_ar": "بور هيل",
                "label_fr": "Burr Hill"
            },
            {
                "value": 5290,
                "label": "Burrton",
                "label_ar": "بيرتون",
                "label_fr": "Burrton"
            },
            {
                "value": 5291,
                "label": "Burt",
                "label_ar": "بيرت",
                "label_fr": "Burt"
            },
            {
                "value": 5292,
                "label": "Burton",
                "label_ar": "بيرتون",
                "label_fr": "Burton"
            },
            {
                "value": 5293,
                "label": "Burtonsville",
                "label_ar": "بورتونسفيل",
                "label_fr": "Burtonsville"
            },
            {
                "value": 5294,
                "label": "Burtrum",
                "label_ar": "بيرتروم",
                "label_fr": "Burtrum"
            },
            {
                "value": 5295,
                "label": "Burwell",
                "label_ar": "بورويل",
                "label_fr": "Burwell"
            },
            {
                "value": 5296,
                "label": "Busby",
                "label_ar": "باسبي",
                "label_fr": "Bonnet à poil"
            },
            {
                "value": 5297,
                "label": "Bush",
                "label_ar": "دفع",
                "label_fr": "Buisson"
            },
            {
                "value": 5298,
                "label": "Bushkill",
                "label_ar": "بوشكيل",
                "label_fr": "Bushkill"
            },
            {
                "value": 5299,
                "label": "Bushland",
                "label_ar": "بوشلاند",
                "label_fr": "Bushland"
            },
            {
                "value": 5300,
                "label": "Bushnell",
                "label_ar": "بوشنيل",
                "label_fr": "Bushnell"
            },
            {
                "value": 5301,
                "label": "Bushton",
                "label_ar": "بوشتون",
                "label_fr": "Bushton"
            },
            {
                "value": 5302,
                "label": "Buskirk",
                "label_ar": "بوسكيرك",
                "label_fr": "Buskirk"
            },
            {
                "value": 5303,
                "label": "Busy",
                "label_ar": "مشغول",
                "label_fr": "Occupé"
            },
            {
                "value": 5304,
                "label": "Butler",
                "label_ar": "بتلر",
                "label_fr": "Majordome"
            },
            {
                "value": 5305,
                "label": "Butlerville",
                "label_ar": "بتلرفيل",
                "label_fr": "Butlerville"
            },
            {
                "value": 5306,
                "label": "Butner",
                "label_ar": "باتنر",
                "label_fr": "Butner"
            },
            {
                "value": 5307,
                "label": "Butte",
                "label_ar": "بوت",
                "label_fr": "Butte"
            },
            {
                "value": 5308,
                "label": "Butte des Morts",
                "label_ar": "Butte des Morts",
                "label_fr": "Butte des Morts"
            },
            {
                "value": 5309,
                "label": "Butterfield",
                "label_ar": "بترفيلد",
                "label_fr": "Butterfield"
            },
            {
                "value": 5310,
                "label": "Butternut",
                "label_ar": "الجوز",
                "label_fr": "Butternut"
            },
            {
                "value": 5311,
                "label": "Buttonwillow",
                "label_ar": "Buttonwillow",
                "label_fr": "Buttonwillow"
            },
            {
                "value": 5312,
                "label": "Buxton",
                "label_ar": "بوكستون",
                "label_fr": "Buxton"
            },
            {
                "value": 5313,
                "label": "Buzzards Bay",
                "label_ar": "بازاردز باي",
                "label_fr": "Buzzards Bay"
            },
            {
                "value": 5314,
                "label": "Byers",
                "label_ar": "بايرز",
                "label_fr": "Byers"
            },
            {
                "value": 5315,
                "label": "Byesville",
                "label_ar": "بيسفيل",
                "label_fr": "Byesville"
            },
            {
                "value": 5316,
                "label": "Byfield",
                "label_ar": "بيفيلد",
                "label_fr": "Byfield"
            },
            {
                "value": 5317,
                "label": "Byhalia",
                "label_ar": "بيهاليا",
                "label_fr": "Byhalia"
            },
            {
                "value": 5318,
                "label": "Bylas",
                "label_ar": "بيلاس",
                "label_fr": "Bylas"
            },
            {
                "value": 5319,
                "label": "Bynum",
                "label_ar": "بينوم",
                "label_fr": "Bynum"
            },
            {
                "value": 5320,
                "label": "Byram",
                "label_ar": "بيرام",
                "label_fr": "Byram"
            },
            {
                "value": 5321,
                "label": "Byrdstown",
                "label_ar": "بيردستاون",
                "label_fr": "Byrdstown"
            },
            {
                "value": 5322,
                "label": "Byron",
                "label_ar": "بايرون",
                "label_fr": "Byron"
            },
            {
                "value": 5323,
                "label": "Byron Center",
                "label_ar": "مركز بايرون",
                "label_fr": "Centre Byron"
            },
            {
                "value": 5324,
                "label": "Cabazon",
                "label_ar": "كابازون",
                "label_fr": "Cabazon"
            },
            {
                "value": 5325,
                "label": "Cabery",
                "label_ar": "كابري",
                "label_fr": "Cabery"
            },
            {
                "value": 5326,
                "label": "Cabin Creek",
                "label_ar": "كابين كريك",
                "label_fr": "Ruisseau Cabin"
            },
            {
                "value": 5327,
                "label": "Cabin John",
                "label_ar": "كابينة جون",
                "label_fr": "Cabine John"
            },
            {
                "value": 5328,
                "label": "Cable",
                "label_ar": "كابل",
                "label_fr": "Câble"
            },
            {
                "value": 5329,
                "label": "Cabool",
                "label_ar": "كابول",
                "label_fr": "Cabool"
            },
            {
                "value": 5330,
                "label": "Cabot",
                "label_ar": "كابوت",
                "label_fr": "Cabot"
            },
            {
                "value": 5331,
                "label": "Cache",
                "label_ar": "مخبأ",
                "label_fr": "Cache"
            },
            {
                "value": 5332,
                "label": "Cactus",
                "label_ar": "صبار",
                "label_fr": "Cactus"
            },
            {
                "value": 5333,
                "label": "Caddo",
                "label_ar": "كادو",
                "label_fr": "Caddo"
            },
            {
                "value": 5334,
                "label": "Caddo Gap",
                "label_ar": "كادو جاب",
                "label_fr": "Caddo Gap"
            },
            {
                "value": 5335,
                "label": "Caddo Mills",
                "label_ar": "كادو ميلز",
                "label_fr": "Moulins Caddo"
            },
            {
                "value": 5336,
                "label": "Cade",
                "label_ar": "كيد",
                "label_fr": "Cade"
            },
            {
                "value": 5337,
                "label": "Cades",
                "label_ar": "كاديس",
                "label_fr": "Cades"
            },
            {
                "value": 5338,
                "label": "Cadet",
                "label_ar": "كاديت",
                "label_fr": "Cadet"
            },
            {
                "value": 5339,
                "label": "Cadillac",
                "label_ar": "كاديلاك",
                "label_fr": "Cadillac"
            },
            {
                "value": 5340,
                "label": "Cadiz",
                "label_ar": "قادس",
                "label_fr": "Cadix"
            },
            {
                "value": 5341,
                "label": "Cadott",
                "label_ar": "كادوت",
                "label_fr": "Cadott"
            },
            {
                "value": 5342,
                "label": "Cadyville",
                "label_ar": "كاديفيل",
                "label_fr": "Cadyville"
            },
            {
                "value": 5343,
                "label": "Cainsville",
                "label_ar": "سينسفيل",
                "label_fr": "Cainsville"
            },
            {
                "value": 5344,
                "label": "Cairnbrook",
                "label_ar": "كيرنبروك",
                "label_fr": "Cairnbrook"
            },
            {
                "value": 5345,
                "label": "Cairo",
                "label_ar": "القاهرة",
                "label_fr": "Caire"
            },
            {
                "value": 5346,
                "label": "Calabasas",
                "label_ar": "كالاباساس",
                "label_fr": "Calabasas"
            },
            {
                "value": 5347,
                "label": "Calabash",
                "label_ar": "كالاباش",
                "label_fr": "Calebasse"
            },
            {
                "value": 5348,
                "label": "Calais",
                "label_ar": "كاليه",
                "label_fr": "Calais"
            },
            {
                "value": 5349,
                "label": "Calamus",
                "label_ar": "قلم",
                "label_fr": "Calamus"
            },
            {
                "value": 5350,
                "label": "Calder",
                "label_ar": "كالدر",
                "label_fr": "Calder"
            },
            {
                "value": 5351,
                "label": "Caldwell",
                "label_ar": "كالدويل",
                "label_fr": "Caldwell"
            },
            {
                "value": 5352,
                "label": "Caledonia",
                "label_ar": "كاليدونيا",
                "label_fr": "Calédonie"
            },
            {
                "value": 5353,
                "label": "Calera",
                "label_ar": "كاليرا",
                "label_fr": "Calera"
            },
            {
                "value": 5354,
                "label": "Calexico",
                "label_ar": "كاليكسيكو",
                "label_fr": "Calexico"
            },
            {
                "value": 5355,
                "label": "Calhan",
                "label_ar": "كالهان",
                "label_fr": "Calhan"
            },
            {
                "value": 5356,
                "label": "Calhoun",
                "label_ar": "كالهون",
                "label_fr": "Calhoun"
            },
            {
                "value": 5357,
                "label": "Calhoun City",
                "label_ar": "مدينة كالهون",
                "label_fr": "Ville de Calhoun"
            },
            {
                "value": 5358,
                "label": "Calhoun Falls",
                "label_ar": "شلالات كالهون",
                "label_fr": "Chutes de Calhoun"
            },
            {
                "value": 5359,
                "label": "Calico Rock",
                "label_ar": "كاليكو روك",
                "label_fr": "Calico Rock"
            },
            {
                "value": 5360,
                "label": "Caliente",
                "label_ar": "كالينته",
                "label_fr": "Caliente"
            },
            {
                "value": 5361,
                "label": "Califon",
                "label_ar": "كاليفورنيا",
                "label_fr": "Califon"
            },
            {
                "value": 5362,
                "label": "California",
                "label_ar": "كاليفورنيا",
                "label_fr": "Californie"
            },
            {
                "value": 5363,
                "label": "California City",
                "label_ar": "كاليفورنيا سيتي",
                "label_fr": "California City"
            },
            {
                "value": 5364,
                "label": "Calimesa",
                "label_ar": "كاليمسا",
                "label_fr": "Calimesa"
            },
            {
                "value": 5365,
                "label": "Calipatria",
                "label_ar": "كاليباتريا",
                "label_fr": "Calipatria"
            },
            {
                "value": 5366,
                "label": "Calistoga",
                "label_ar": "كاليستوجا",
                "label_fr": "Calistoga"
            },
            {
                "value": 5367,
                "label": "Callahan",
                "label_ar": "كالاهان",
                "label_fr": "Callahan"
            },
            {
                "value": 5368,
                "label": "Callao",
                "label_ar": "كالاو",
                "label_fr": "Callao"
            },
            {
                "value": 5369,
                "label": "Callaway",
                "label_ar": "كالاواي",
                "label_fr": "Callaway"
            },
            {
                "value": 5370,
                "label": "Callicoon",
                "label_ar": "كاليكون",
                "label_fr": "Callicoon"
            },
            {
                "value": 5371,
                "label": "Calmar",
                "label_ar": "الهدوء",
                "label_fr": "Calmar"
            },
            {
                "value": 5372,
                "label": "Calpella",
                "label_ar": "كالبيلا",
                "label_fr": "Calpella"
            },
            {
                "value": 5373,
                "label": "Calpine",
                "label_ar": "كالبين",
                "label_fr": "Calpine"
            },
            {
                "value": 5374,
                "label": "Calumet",
                "label_ar": "كالوميت",
                "label_fr": "Calumet"
            },
            {
                "value": 5375,
                "label": "Calumet City",
                "label_ar": "مدينة كالوميت",
                "label_fr": "Ville de Calumet"
            },
            {
                "value": 5376,
                "label": "Calvert",
                "label_ar": "كالفيرت",
                "label_fr": "Calvert"
            },
            {
                "value": 5377,
                "label": "Calvert City",
                "label_ar": "مدينة كالفيرت",
                "label_fr": "Ville de Calvert"
            },
            {
                "value": 5378,
                "label": "Calverton",
                "label_ar": "كالفرتون",
                "label_fr": "Calverton"
            },
            {
                "value": 5379,
                "label": "Calypso",
                "label_ar": "كاليبسو",
                "label_fr": "Calypso"
            },
            {
                "value": 5380,
                "label": "Camanche",
                "label_ar": "كامانش",
                "label_fr": "Camanche"
            },
            {
                "value": 5381,
                "label": "Camano",
                "label_ar": "كامانو",
                "label_fr": "Camano"
            },
            {
                "value": 5382,
                "label": "Camarillo",
                "label_ar": "كاماريلو",
                "label_fr": "Camarillo"
            },
            {
                "value": 5383,
                "label": "Camas",
                "label_ar": "كاماس",
                "label_fr": "Camas"
            },
            {
                "value": 5384,
                "label": "Cambria",
                "label_ar": "كامبريا",
                "label_fr": "Cambria"
            },
            {
                "value": 5385,
                "label": "Cambria Heights",
                "label_ar": "مرتفعات كامبريا",
                "label_fr": "Cambria Heights"
            },
            {
                "value": 5386,
                "label": "Cambridge",
                "label_ar": "كامبريدج",
                "label_fr": "Cambridge"
            },
            {
                "value": 5387,
                "label": "Cambridge City",
                "label_ar": "مدينة كامبريدج",
                "label_fr": "Cambridge City"
            },
            {
                "value": 5388,
                "label": "Cambridge Springs",
                "label_ar": "كامبريدج سبرينجز",
                "label_fr": "Cambridge Springs"
            },
            {
                "value": 5389,
                "label": "Cambridgeport",
                "label_ar": "كامبريدجبورت",
                "label_fr": "Cambridgeport"
            },
            {
                "value": 5390,
                "label": "Camby",
                "label_ar": "كامبي",
                "label_fr": "Camby"
            },
            {
                "value": 5391,
                "label": "Camden",
                "label_ar": "كامدن",
                "label_fr": "Camden"
            },
            {
                "value": 5392,
                "label": "Camden Point",
                "label_ar": "كامدن بوينت",
                "label_fr": "Camden Point"
            },
            {
                "value": 5393,
                "label": "Camdenton",
                "label_ar": "كامدينتون",
                "label_fr": "Camdenton"
            },
            {
                "value": 5394,
                "label": "Cameron",
                "label_ar": "كاميرون",
                "label_fr": "Cameron"
            },
            {
                "value": 5395,
                "label": "Cameron Mills",
                "label_ar": "كاميرون ميلز",
                "label_fr": "Cameron Mills"
            },
            {
                "value": 5396,
                "label": "Camilla",
                "label_ar": "كاميلا",
                "label_fr": "Camilla"
            },
            {
                "value": 5397,
                "label": "Camillus",
                "label_ar": "كاميلوس",
                "label_fr": "Camille"
            },
            {
                "value": 5398,
                "label": "Camino",
                "label_ar": "كامينو",
                "label_fr": "Camino"
            },
            {
                "value": 5399,
                "label": "Camp Creek",
                "label_ar": "كامب كريك",
                "label_fr": "Camp Creek"
            },
            {
                "value": 5400,
                "label": "Camp Dennison",
                "label_ar": "معسكر دينيسون",
                "label_fr": "Camp Dennison"
            },
            {
                "value": 5401,
                "label": "Camp Douglas",
                "label_ar": "كامب دوغلاس",
                "label_fr": "Camp Douglas"
            },
            {
                "value": 5402,
                "label": "Camp H M Smith",
                "label_ar": "كامب إتش إم سميث",
                "label_fr": "Camp H M Smith"
            },
            {
                "value": 5403,
                "label": "Camp Hill",
                "label_ar": "كامب هيل",
                "label_fr": "Camp Hill"
            },
            {
                "value": 5404,
                "label": "Camp Meeker",
                "label_ar": "كامب ميكر",
                "label_fr": "Camp Meeker"
            },
            {
                "value": 5405,
                "label": "Camp Point",
                "label_ar": "كامب بوينت",
                "label_fr": "Point de camp"
            },
            {
                "value": 5406,
                "label": "Camp Verde",
                "label_ar": "كامب فيردي",
                "label_fr": "Camp Verde"
            },
            {
                "value": 5407,
                "label": "Camp Wood",
                "label_ar": "كامب وود",
                "label_fr": "Bois de camp"
            },
            {
                "value": 5408,
                "label": "Campbell",
                "label_ar": "كامبل",
                "label_fr": "Campbell"
            },
            {
                "value": 5409,
                "label": "Campbell Hall",
                "label_ar": "قاعة كامبل",
                "label_fr": "Campbell Hall"
            },
            {
                "value": 5410,
                "label": "Campbell Hill",
                "label_ar": "كامبل هيل",
                "label_fr": "Campbell Hill"
            },
            {
                "value": 5411,
                "label": "Campbellsburg",
                "label_ar": "كامبلسبورج",
                "label_fr": "Campbellsburg"
            },
            {
                "value": 5412,
                "label": "Campbellsport",
                "label_ar": "كامبلسبورت",
                "label_fr": "Campbellsport"
            },
            {
                "value": 5413,
                "label": "Campbellsville",
                "label_ar": "كامبلسفيل",
                "label_fr": "Campbellsville"
            },
            {
                "value": 5414,
                "label": "Campbelltown",
                "label_ar": "كامبلتاون",
                "label_fr": "Campbelltown"
            },
            {
                "value": 5415,
                "label": "Campo",
                "label_ar": "كامبو",
                "label_fr": "Campo"
            },
            {
                "value": 5416,
                "label": "Campobello",
                "label_ar": "كامبوبيلو",
                "label_fr": "Campobello"
            },
            {
                "value": 5417,
                "label": "Campti",
                "label_ar": "كامبتي",
                "label_fr": "Campti"
            },
            {
                "value": 5418,
                "label": "Campton",
                "label_ar": "كامبتون",
                "label_fr": "Campton"
            },
            {
                "value": 5419,
                "label": "Campton Upper Village",
                "label_ar": "قرية كامبتون العليا",
                "label_fr": "Campton Upper Village"
            },
            {
                "value": 5420,
                "label": "Campus",
                "label_ar": "الحرم الجامعي",
                "label_fr": "Campus"
            },
            {
                "value": 5421,
                "label": "Cana",
                "label_ar": "قانا",
                "label_fr": "Cana"
            },
            {
                "value": 5422,
                "label": "Canaan",
                "label_ar": "كنعان",
                "label_fr": "Canaan"
            },
            {
                "value": 5423,
                "label": "Canada",
                "label_ar": "كندا",
                "label_fr": "Canada"
            },
            {
                "value": 5424,
                "label": "Canadensis",
                "label_ar": "الكندي",
                "label_fr": "Canadensis"
            },
            {
                "value": 5425,
                "label": "Canadian",
                "label_ar": "كندي",
                "label_fr": "canadien"
            },
            {
                "value": 5426,
                "label": "Canajoharie",
                "label_ar": "كاناجوهاري",
                "label_fr": "Canajoharie"
            },
            {
                "value": 5427,
                "label": "Canal Fulton",
                "label_ar": "قناة فولتون",
                "label_fr": "Canal Fulton"
            },
            {
                "value": 5428,
                "label": "Canal Point",
                "label_ar": "كانال بوينت",
                "label_fr": "Canal Point"
            },
            {
                "value": 5429,
                "label": "Canal Winchester",
                "label_ar": "قناة وينشستر",
                "label_fr": "Canal Winchester"
            },
            {
                "value": 5430,
                "label": "Canalou",
                "label_ar": "كانالو",
                "label_fr": "Canalou"
            },
            {
                "value": 5431,
                "label": "Canandaigua",
                "label_ar": "كانانديغوا",
                "label_fr": "Canandaigua"
            },
            {
                "value": 5432,
                "label": "Canastota",
                "label_ar": "كاناستوتا",
                "label_fr": "Canastota"
            },
            {
                "value": 5433,
                "label": "Canby",
                "label_ar": "يمكن",
                "label_fr": "Canby"
            },
            {
                "value": 5434,
                "label": "Candia",
                "label_ar": "كانديا",
                "label_fr": "Candia"
            },
            {
                "value": 5435,
                "label": "Candler",
                "label_ar": "كاندلر",
                "label_fr": "Candler"
            },
            {
                "value": 5436,
                "label": "Cando",
                "label_ar": "يمكن القيام به",
                "label_fr": "Peut faire"
            },
            {
                "value": 5437,
                "label": "Candor",
                "label_ar": "الصدق",
                "label_fr": "Candeur"
            },
            {
                "value": 5438,
                "label": "Canehill",
                "label_ar": "كانهيل",
                "label_fr": "Canehill"
            },
            {
                "value": 5439,
                "label": "Caney",
                "label_ar": "كاني",
                "label_fr": "Caney"
            },
            {
                "value": 5440,
                "label": "Caneyville",
                "label_ar": "كانيفيل",
                "label_fr": "Caneyville"
            },
            {
                "value": 5441,
                "label": "Canfield",
                "label_ar": "كانفيلد",
                "label_fr": "Canfield"
            },
            {
                "value": 5442,
                "label": "Canisteo",
                "label_ar": "كانيستو",
                "label_fr": "Canisteo"
            },
            {
                "value": 5443,
                "label": "Canmer",
                "label_ar": "كانمر",
                "label_fr": "Canmer"
            },
            {
                "value": 5444,
                "label": "Cannel City",
                "label_ar": "كانيل سيتي",
                "label_fr": "Cannel City"
            },
            {
                "value": 5445,
                "label": "Cannelton",
                "label_ar": "كانيلتون",
                "label_fr": "Cannelton"
            },
            {
                "value": 5446,
                "label": "Cannon",
                "label_ar": "مدفع",
                "label_fr": "Canon"
            },
            {
                "value": 5447,
                "label": "Cannon Falls",
                "label_ar": "شلالات كانون",
                "label_fr": "Cannon Falls"
            },
            {
                "value": 5448,
                "label": "Cannonville",
                "label_ar": "كانونفيل",
                "label_fr": "Cannonville"
            },
            {
                "value": 5449,
                "label": "Canoga Park",
                "label_ar": "كانوجا بارك",
                "label_fr": "Parc Canoga"
            },
            {
                "value": 5450,
                "label": "Canon",
                "label_ar": "كانون",
                "label_fr": "Canon"
            },
            {
                "value": 5451,
                "label": "Canon City",
                "label_ar": "كانون سيتي",
                "label_fr": "Canon City"
            },
            {
                "value": 5452,
                "label": "Canonsburg",
                "label_ar": "كانونسبورغ",
                "label_fr": "Canonsburg"
            },
            {
                "value": 5453,
                "label": "Canterbury",
                "label_ar": "كانتربري",
                "label_fr": "Cantorbéry"
            },
            {
                "value": 5454,
                "label": "Canton",
                "label_ar": "كانتون",
                "label_fr": "Canton"
            },
            {
                "value": 5455,
                "label": "Cantonment",
                "label_ar": "كانتون",
                "label_fr": "Cantonnement"
            },
            {
                "value": 5456,
                "label": "Cantrall",
                "label_ar": "كانترال",
                "label_fr": "Cantrall"
            },
            {
                "value": 5457,
                "label": "Canute",
                "label_ar": "كانوت",
                "label_fr": "Canut"
            },
            {
                "value": 5458,
                "label": "Canutillo",
                "label_ar": "كانوتيلو",
                "label_fr": "Canutillo"
            },
            {
                "value": 5459,
                "label": "Canvas",
                "label_ar": "اللوحة القماشية",
                "label_fr": "Toile"
            },
            {
                "value": 5460,
                "label": "Canyon",
                "label_ar": "كانيون",
                "label_fr": "Canyon"
            },
            {
                "value": 5461,
                "label": "Canyon City",
                "label_ar": "كانيون سيتي",
                "label_fr": "Canyon City"
            },
            {
                "value": 5462,
                "label": "Canyon Country",
                "label_ar": "كانيون كونتري",
                "label_fr": "Canyon Country"
            },
            {
                "value": 5463,
                "label": "Canyon Lake",
                "label_ar": "بحيرة كانيون",
                "label_fr": "Canyon Lake"
            },
            {
                "value": 5464,
                "label": "Canyonville",
                "label_ar": "كانيونفيل",
                "label_fr": "Canyonville"
            },
            {
                "value": 5465,
                "label": "Capac",
                "label_ar": "كاباك",
                "label_fr": "Capac"
            },
            {
                "value": 5466,
                "label": "Cape Canaveral",
                "label_ar": "كيب كانافيرال",
                "label_fr": "Cap Canaveral"
            },
            {
                "value": 5467,
                "label": "Cape Charles",
                "label_ar": "كيب تشارلز",
                "label_fr": "Cap Charles"
            },
            {
                "value": 5468,
                "label": "Cape Coral",
                "label_ar": "كيب كورال",
                "label_fr": "Cape Coral"
            },
            {
                "value": 5469,
                "label": "Cape Elizabeth",
                "label_ar": "كيب إليزابيث",
                "label_fr": "Cape Elizabeth"
            },
            {
                "value": 5470,
                "label": "Cape Fair",
                "label_ar": "كيب فير",
                "label_fr": "Cape Fair"
            },
            {
                "value": 5471,
                "label": "Cape Girardeau",
                "label_ar": "كيب جيراردو",
                "label_fr": "Cap Girardeau"
            },
            {
                "value": 5472,
                "label": "Cape May",
                "label_ar": "كيب ماي",
                "label_fr": "Cape May"
            },
            {
                "value": 5473,
                "label": "Cape May Court House",
                "label_ar": "بيت محكمة كيب ماي",
                "label_fr": "Palais de justice de Cape May"
            },
            {
                "value": 5474,
                "label": "Cape May Point",
                "label_ar": "كيب ماي بوينت",
                "label_fr": "Cape May Point"
            },
            {
                "value": 5475,
                "label": "Cape Neddick",
                "label_ar": "كيب نيديك",
                "label_fr": "Cape Neddick"
            },
            {
                "value": 5476,
                "label": "Cape Porpoise",
                "label_ar": "كيب خنزير البحر",
                "label_fr": "Marsouin du Cap"
            },
            {
                "value": 5477,
                "label": "Cape Vincent",
                "label_ar": "كيب فنسنت",
                "label_fr": "Cap Vincent"
            },
            {
                "value": 5478,
                "label": "Capeville",
                "label_ar": "كابفيل",
                "label_fr": "Capeville"
            },
            {
                "value": 5479,
                "label": "Capistrano Beach",
                "label_ar": "شاطئ كابيسترانو",
                "label_fr": "Plage de Capistrano"
            },
            {
                "value": 5480,
                "label": "Capitan",
                "label_ar": "كابيتان",
                "label_fr": "Capitan"
            },
            {
                "value": 5481,
                "label": "Capitola",
                "label_ar": "كابيتولا",
                "label_fr": "Capitole"
            },
            {
                "value": 5482,
                "label": "Capon Bridge",
                "label_ar": "جسر كابون",
                "label_fr": "Pont de Capon"
            },
            {
                "value": 5483,
                "label": "Capron",
                "label_ar": "كابرون",
                "label_fr": "Capron"
            },
            {
                "value": 5484,
                "label": "Captain Cook",
                "label_ar": "رئيس الطبخ",
                "label_fr": "Capitaine cuisinier"
            },
            {
                "value": 5485,
                "label": "Captiva",
                "label_ar": "كابتيفا",
                "label_fr": "Captiva"
            },
            {
                "value": 5486,
                "label": "Caputa",
                "label_ar": "كابوتا",
                "label_fr": "Caputa"
            },
            {
                "value": 5487,
                "label": "Caraway",
                "label_ar": "كراوية",
                "label_fr": "Carvi"
            },
            {
                "value": 5488,
                "label": "Carbon",
                "label_ar": "كربون",
                "label_fr": "Carbone"
            },
            {
                "value": 5489,
                "label": "Carbon Hill",
                "label_ar": "كاربون هيل",
                "label_fr": "Carbon Hill"
            },
            {
                "value": 5490,
                "label": "Carbonado",
                "label_ar": "كاربونادو",
                "label_fr": "Carbonado"
            },
            {
                "value": 5491,
                "label": "Carbondale",
                "label_ar": "كاربونديل",
                "label_fr": "Carbondale"
            },
            {
                "value": 5492,
                "label": "Cardale",
                "label_ar": "كارديل",
                "label_fr": "Cardale"
            },
            {
                "value": 5493,
                "label": "Cardington",
                "label_ar": "كاردينجتون",
                "label_fr": "Cardington"
            },
            {
                "value": 5494,
                "label": "Carefree",
                "label_ar": "براحة البال",
                "label_fr": "Insouciant"
            },
            {
                "value": 5495,
                "label": "Carencro",
                "label_ar": "كارينكرو",
                "label_fr": "Carencro"
            },
            {
                "value": 5496,
                "label": "Carey",
                "label_ar": "كاري",
                "label_fr": "Carey"
            },
            {
                "value": 5497,
                "label": "Careywood",
                "label_ar": "كاريوود",
                "label_fr": "Careywood"
            },
            {
                "value": 5498,
                "label": "Caribou",
                "label_ar": "كاريبو",
                "label_fr": "Caribou"
            },
            {
                "value": 5499,
                "label": "Carl Junction",
                "label_ar": "كارل جانكشن",
                "label_fr": "Carl Junction"
            },
            {
                "value": 5500,
                "label": "Carle Place",
                "label_ar": "مكان كارل",
                "label_fr": "Place Carle"
            },
            {
                "value": 5501,
                "label": "Carleton",
                "label_ar": "كارلتون",
                "label_fr": "Carleton"
            },
            {
                "value": 5502,
                "label": "Carlin",
                "label_ar": "كارلين",
                "label_fr": "Carlin"
            },
            {
                "value": 5503,
                "label": "Carlinville",
                "label_ar": "كارلينفيل",
                "label_fr": "Carlinville"
            },
            {
                "value": 5504,
                "label": "Carlisle",
                "label_ar": "كارلايل",
                "label_fr": "Carlisle"
            },
            {
                "value": 5505,
                "label": "Carlock",
                "label_ar": "كارلوك",
                "label_fr": "Carlock"
            },
            {
                "value": 5506,
                "label": "Carlos",
                "label_ar": "كارلوس",
                "label_fr": "Carlos"
            },
            {
                "value": 5507,
                "label": "Carlotta",
                "label_ar": "كارلوتا",
                "label_fr": "Carlotta"
            },
            {
                "value": 5508,
                "label": "Carlsbad",
                "label_ar": "كارلسباد",
                "label_fr": "Carlsbad"
            },
            {
                "value": 5509,
                "label": "Carlsborg",
                "label_ar": "كارلسبورج",
                "label_fr": "Carlsborg"
            },
            {
                "value": 5510,
                "label": "Carlstadt",
                "label_ar": "كارلستادت",
                "label_fr": "Carlstadt"
            },
            {
                "value": 5511,
                "label": "Carlton",
                "label_ar": "كارلتون",
                "label_fr": "Carlton"
            },
            {
                "value": 5512,
                "label": "Carlyle",
                "label_ar": "كارلايل",
                "label_fr": "Carlyle"
            },
            {
                "value": 5513,
                "label": "Carman",
                "label_ar": "كارمان",
                "label_fr": "Carman"
            },
            {
                "value": 5514,
                "label": "Carmel",
                "label_ar": "الكرمل",
                "label_fr": "Carmel"
            },
            {
                "value": 5515,
                "label": "Carmel Valley",
                "label_ar": "وادي الكرمل",
                "label_fr": "Vallée du Carmel"
            },
            {
                "value": 5516,
                "label": "Carmen",
                "label_ar": "كارمن",
                "label_fr": "Carmen"
            },
            {
                "value": 5517,
                "label": "Carmi",
                "label_ar": "كارمي",
                "label_fr": "Carmi"
            },
            {
                "value": 5518,
                "label": "Carmichael",
                "label_ar": "كارمايكل",
                "label_fr": "Carmichael"
            },
            {
                "value": 5519,
                "label": "Carmichaels",
                "label_ar": "كارميشيل",
                "label_fr": "Carmichaels"
            },
            {
                "value": 5520,
                "label": "Carmine",
                "label_ar": "كارمين",
                "label_fr": "Carmin"
            },
            {
                "value": 5521,
                "label": "Carnation",
                "label_ar": "قرنفل",
                "label_fr": "Œillet"
            },
            {
                "value": 5522,
                "label": "Carnegie",
                "label_ar": "كارنيجي",
                "label_fr": "Carnegie"
            },
            {
                "value": 5523,
                "label": "Carnesville",
                "label_ar": "كارنسفيل",
                "label_fr": "Carnesville"
            },
            {
                "value": 5524,
                "label": "Carney",
                "label_ar": "كارني",
                "label_fr": "Carney"
            },
            {
                "value": 5525,
                "label": "Caro",
                "label_ar": "كارو",
                "label_fr": "Caro"
            },
            {
                "value": 5526,
                "label": "Caroga Lake",
                "label_ar": "بحيرة كاروجا",
                "label_fr": "Lac Caroga"
            },
            {
                "value": 5527,
                "label": "Carol Stream",
                "label_ar": "كارول ستريم",
                "label_fr": "Carol Stream"
            },
            {
                "value": 5528,
                "label": "Carolina",
                "label_ar": "كارولينا",
                "label_fr": "Caroline"
            },
            {
                "value": 5529,
                "label": "Carolina Beach",
                "label_ar": "كارولينا بيتش",
                "label_fr": "Carolina Beach"
            },
            {
                "value": 5530,
                "label": "Carp Lake",
                "label_ar": "بحيرة الكارب",
                "label_fr": "Lac Carp"
            },
            {
                "value": 5531,
                "label": "Carpentersville",
                "label_ar": "كاربينترسفيل",
                "label_fr": "Carpentersville"
            },
            {
                "value": 5532,
                "label": "Carpinteria",
                "label_ar": "كاربينتريا",
                "label_fr": "Carpinteria"
            },
            {
                "value": 5533,
                "label": "Carr",
                "label_ar": "كار",
                "label_fr": "Carr"
            },
            {
                "value": 5534,
                "label": "Carrabelle",
                "label_ar": "كارابيل",
                "label_fr": "Carrabelle"
            },
            {
                "value": 5535,
                "label": "Carrboro",
                "label_ar": "كاربورو",
                "label_fr": "Carrboro"
            },
            {
                "value": 5536,
                "label": "Carrie",
                "label_ar": "كاري",
                "label_fr": "Carrie"
            },
            {
                "value": 5537,
                "label": "Carrier",
                "label_ar": "الناقل",
                "label_fr": "Transporteur"
            },
            {
                "value": 5538,
                "label": "Carrier Mills",
                "label_ar": "كاريير ميلز",
                "label_fr": "Usines de transport"
            },
            {
                "value": 5539,
                "label": "Carriere",
                "label_ar": "كاريير",
                "label_fr": "Carrière"
            },
            {
                "value": 5540,
                "label": "Carrington",
                "label_ar": "كارينجتون",
                "label_fr": "Carrington"
            },
            {
                "value": 5541,
                "label": "Carrizo Springs",
                "label_ar": "كاريزو سبرينغز",
                "label_fr": "Carrizo Springs"
            },
            {
                "value": 5542,
                "label": "Carrizozo",
                "label_ar": "كاريزوزو",
                "label_fr": "Carrizozo"
            },
            {
                "value": 5543,
                "label": "Carroll",
                "label_ar": "كارول",
                "label_fr": "Carroll"
            },
            {
                "value": 5544,
                "label": "Carrollton",
                "label_ar": "كارولتون",
                "label_fr": "Carrollton"
            },
            {
                "value": 5545,
                "label": "Carrolltown",
                "label_ar": "كارولتاون",
                "label_fr": "Carrolltown"
            },
            {
                "value": 5546,
                "label": "Carrsville",
                "label_ar": "كارسفيل",
                "label_fr": "Carrsville"
            },
            {
                "value": 5547,
                "label": "Carson",
                "label_ar": "كارسون",
                "label_fr": "Carson"
            },
            {
                "value": 5548,
                "label": "Carson City",
                "label_ar": "كارسون سيتي",
                "label_fr": "Carson City"
            },
            {
                "value": 5549,
                "label": "Carsonville",
                "label_ar": "كارسونفيل",
                "label_fr": "Carsonville"
            },
            {
                "value": 5550,
                "label": "Carter Lake",
                "label_ar": "بحيرة كارتر",
                "label_fr": "Lac Carter"
            },
            {
                "value": 5551,
                "label": "Carteret",
                "label_ar": "كارتريت",
                "label_fr": "Carteret"
            },
            {
                "value": 5552,
                "label": "Cartersville",
                "label_ar": "كارترزفيل",
                "label_fr": "Cartersville"
            },
            {
                "value": 5553,
                "label": "Carterville",
                "label_ar": "كارترفيل",
                "label_fr": "Carterville"
            },
            {
                "value": 5554,
                "label": "Carthage",
                "label_ar": "قرطاج",
                "label_fr": "Carthage"
            },
            {
                "value": 5555,
                "label": "Cartwright",
                "label_ar": "كارترايت",
                "label_fr": "Cartwright"
            },
            {
                "value": 5556,
                "label": "Caruthers",
                "label_ar": "كاروثرز",
                "label_fr": "Caruthers"
            },
            {
                "value": 5557,
                "label": "Caruthersville",
                "label_ar": "كاروثرزفيل",
                "label_fr": "Caruthersville"
            },
            {
                "value": 5558,
                "label": "Carver",
                "label_ar": "كارفر",
                "label_fr": "Sculpteur"
            },
            {
                "value": 5559,
                "label": "Cary",
                "label_ar": "كاري",
                "label_fr": "Cary"
            },
            {
                "value": 5560,
                "label": "Caryville",
                "label_ar": "كاريفيل",
                "label_fr": "Caryville"
            },
            {
                "value": 5561,
                "label": "Casa Grande",
                "label_ar": "كاسا غراندي",
                "label_fr": "Casa Grande"
            },
            {
                "value": 5562,
                "label": "Casanova",
                "label_ar": "كازانوفا",
                "label_fr": "Casanova"
            },
            {
                "value": 5563,
                "label": "Casar",
                "label_ar": "كاسار",
                "label_fr": "Casar"
            },
            {
                "value": 5564,
                "label": "Cascade",
                "label_ar": "تتالي",
                "label_fr": "Cascade"
            },
            {
                "value": 5565,
                "label": "Cascade Locks",
                "label_ar": "أقفال تتالي",
                "label_fr": "Serrures en cascade"
            },
            {
                "value": 5566,
                "label": "Cascilla",
                "label_ar": "كاسيلا",
                "label_fr": "Cascilla"
            },
            {
                "value": 5567,
                "label": "Casco",
                "label_ar": "كاسكو",
                "label_fr": "Casco"
            },
            {
                "value": 5568,
                "label": "Caseville",
                "label_ar": "كاسفيل",
                "label_fr": "Caseville"
            },
            {
                "value": 5569,
                "label": "Casey",
                "label_ar": "كيسي",
                "label_fr": "Casey"
            },
            {
                "value": 5570,
                "label": "Caseyville",
                "label_ar": "Caseyville",
                "label_fr": "Caseyville"
            },
            {
                "value": 5571,
                "label": "Cashiers",
                "label_ar": "الصرافين",
                "label_fr": "Caissiers"
            },
            {
                "value": 5572,
                "label": "Cashion",
                "label_ar": "أزياء",
                "label_fr": "Mode"
            },
            {
                "value": 5573,
                "label": "Cashmere",
                "label_ar": "الكشمير",
                "label_fr": "Cachemire"
            },
            {
                "value": 5574,
                "label": "Cashton",
                "label_ar": "كاشتون",
                "label_fr": "Cashton"
            },
            {
                "value": 5575,
                "label": "Cashtown",
                "label_ar": "كاشتاون",
                "label_fr": "Cashtown"
            },
            {
                "value": 5576,
                "label": "Casnovia",
                "label_ar": "كاسنوفيا",
                "label_fr": "Casnovia"
            },
            {
                "value": 5577,
                "label": "Casper",
                "label_ar": "كاسبر",
                "label_fr": "Casper"
            },
            {
                "value": 5578,
                "label": "Caspian",
                "label_ar": "قزوين",
                "label_fr": "Caspienne"
            },
            {
                "value": 5579,
                "label": "Cass City",
                "label_ar": "كاس سيتي",
                "label_fr": "Cass City"
            },
            {
                "value": 5580,
                "label": "Cass Lake",
                "label_ar": "كاس ليك",
                "label_fr": "Lac Cass"
            },
            {
                "value": 5581,
                "label": "Cassadaga",
                "label_ar": "كاساداجا",
                "label_fr": "Cassadaga"
            },
            {
                "value": 5582,
                "label": "Cassatt",
                "label_ar": "كاسات",
                "label_fr": "Cassatt"
            },
            {
                "value": 5583,
                "label": "Cassel",
                "label_ar": "كاسل",
                "label_fr": "Cassel"
            },
            {
                "value": 5584,
                "label": "Casselberry",
                "label_ar": "كاسيلبيري",
                "label_fr": "Casselberry"
            },
            {
                "value": 5585,
                "label": "Casselton",
                "label_ar": "كاسيلتون",
                "label_fr": "Casselton"
            },
            {
                "value": 5586,
                "label": "Cassoday",
                "label_ar": "Cassoday",
                "label_fr": "Cassoday"
            },
            {
                "value": 5587,
                "label": "Cassopolis",
                "label_ar": "كاسوبوليس",
                "label_fr": "Cassopolis"
            },
            {
                "value": 5588,
                "label": "Casstown",
                "label_ar": "كاسستاون",
                "label_fr": "Casstown"
            },
            {
                "value": 5589,
                "label": "Cassville",
                "label_ar": "كاسفيل",
                "label_fr": "Cassville"
            },
            {
                "value": 5590,
                "label": "Castaic",
                "label_ar": "كاستايك",
                "label_fr": "Castaic"
            },
            {
                "value": 5591,
                "label": "Castalia",
                "label_ar": "كاستاليا",
                "label_fr": "Castalia"
            },
            {
                "value": 5592,
                "label": "Castalian Springs",
                "label_ar": "الينابيع القشتالية",
                "label_fr": "Castalian Springs"
            },
            {
                "value": 5593,
                "label": "Castile",
                "label_ar": "قشتالة",
                "label_fr": "Castille"
            },
            {
                "value": 5594,
                "label": "Castine",
                "label_ar": "كاستين",
                "label_fr": "Castine"
            },
            {
                "value": 5595,
                "label": "Castle Dale",
                "label_ar": "قلعة دايل",
                "label_fr": "Château Dale"
            },
            {
                "value": 5596,
                "label": "Castle Hayne",
                "label_ar": "قلعة هاين",
                "label_fr": "Château Hayne"
            },
            {
                "value": 5597,
                "label": "Castle Point",
                "label_ar": "كاسل بوينت",
                "label_fr": "Castle Point"
            },
            {
                "value": 5598,
                "label": "Castle Rock",
                "label_ar": "صخور القلعة",
                "label_fr": "château de Pierre"
            },
            {
                "value": 5599,
                "label": "Castleberry",
                "label_ar": "كاسلبيري",
                "label_fr": "Castleberry"
            },
            {
                "value": 5600,
                "label": "Castleton",
                "label_ar": "كاسلتون",
                "label_fr": "Castleton"
            },
            {
                "value": 5601,
                "label": "Castleton-on-Hudson",
                "label_ar": "كاسلتون أون هدسون",
                "label_fr": "Castleton-sur-Hudson"
            },
            {
                "value": 5603,
                "label": "Castlewood",
                "label_ar": "كاسلوود",
                "label_fr": "Castlewood"
            },
            {
                "value": 5604,
                "label": "Castor",
                "label_ar": "كاستور",
                "label_fr": "Castor"
            },
            {
                "value": 5605,
                "label": "Castorland",
                "label_ar": "كاستورلاند",
                "label_fr": "Castorland"
            },
            {
                "value": 5606,
                "label": "Castro Valley",
                "label_ar": "وادي كاسترو",
                "label_fr": "Vallée de Castro"
            },
            {
                "value": 5607,
                "label": "Castroville",
                "label_ar": "كاستروفيل",
                "label_fr": "Castroville"
            },
            {
                "value": 5608,
                "label": "Cat Spring",
                "label_ar": "القط الربيع",
                "label_fr": "Ressort de chat"
            },
            {
                "value": 5609,
                "label": "Cataldo",
                "label_ar": "كاتالدو",
                "label_fr": "Cataldo"
            },
            {
                "value": 5610,
                "label": "Catasauqua",
                "label_ar": "كاتاسوكوا",
                "label_fr": "Catasauqua"
            },
            {
                "value": 5611,
                "label": "Cataula",
                "label_ar": "كاتولا",
                "label_fr": "Cataula"
            },
            {
                "value": 5612,
                "label": "Cataumet",
                "label_ar": "كاتوميت",
                "label_fr": "Cataumet"
            },
            {
                "value": 5613,
                "label": "Catawba",
                "label_ar": "كاتاوبا",
                "label_fr": "Catawba"
            },
            {
                "value": 5614,
                "label": "Catawissa",
                "label_ar": "كاتاويسا",
                "label_fr": "Catawissa"
            },
            {
                "value": 5615,
                "label": "Catharpin",
                "label_ar": "كاثاربين",
                "label_fr": "Catharpin"
            },
            {
                "value": 5616,
                "label": "Cathedral City",
                "label_ar": "مدينة الكاتدرائية",
                "label_fr": "Cathédrale de la ville"
            },
            {
                "value": 5617,
                "label": "Catherine",
                "label_ar": "كاثرين",
                "label_fr": "Catherine"
            },
            {
                "value": 5618,
                "label": "Catheys Valley",
                "label_ar": "وادي كاثيس",
                "label_fr": "Vallée de Catheys"
            },
            {
                "value": 5619,
                "label": "Cathlamet",
                "label_ar": "كاثلميت",
                "label_fr": "Cathlamet"
            },
            {
                "value": 5620,
                "label": "Catlett",
                "label_ar": "كاتليت",
                "label_fr": "Catlett"
            },
            {
                "value": 5621,
                "label": "Catlettsburg",
                "label_ar": "كاتليتسبرج",
                "label_fr": "Catlettsburg"
            },
            {
                "value": 5622,
                "label": "Catlin",
                "label_ar": "كاتلين",
                "label_fr": "Catlin"
            },
            {
                "value": 5623,
                "label": "Cato",
                "label_ar": "كاتو",
                "label_fr": "Caton"
            },
            {
                "value": 5624,
                "label": "Catonsville",
                "label_ar": "كاتونسفيل",
                "label_fr": "Catonsville"
            },
            {
                "value": 5625,
                "label": "Catoosa",
                "label_ar": "كاتوسا",
                "label_fr": "Catoosa"
            },
            {
                "value": 5626,
                "label": "Catron",
                "label_ar": "كاترون",
                "label_fr": "Catron"
            },
            {
                "value": 5627,
                "label": "Catskill",
                "label_ar": "كاتسكيل",
                "label_fr": "Catskill"
            },
            {
                "value": 5628,
                "label": "Cattaraugus",
                "label_ar": "كاتاروجوس",
                "label_fr": "Cattaraugus"
            },
            {
                "value": 5629,
                "label": "Caulfield",
                "label_ar": "كولفيلد",
                "label_fr": "Caulfield"
            },
            {
                "value": 5630,
                "label": "Cavalier",
                "label_ar": "فارس",
                "label_fr": "Cavalier"
            },
            {
                "value": 5631,
                "label": "Cave City",
                "label_ar": "مدينة الكهف",
                "label_fr": "Cave City"
            },
            {
                "value": 5632,
                "label": "Cave Creek",
                "label_ar": "كيف كريك",
                "label_fr": "Cave Creek"
            },
            {
                "value": 5633,
                "label": "Cave Junction",
                "label_ar": "مفرق الكهف",
                "label_fr": "Jonction de la grotte"
            },
            {
                "value": 5634,
                "label": "Cave Spring",
                "label_ar": "كهف الربيع",
                "label_fr": "Source des cavernes"
            },
            {
                "value": 5635,
                "label": "Cave Springs",
                "label_ar": "كهف سبرينغز",
                "label_fr": "Cave Springs"
            },
            {
                "value": 5636,
                "label": "Cave-in-Rock",
                "label_ar": "الكهف في روك",
                "label_fr": "Grotte dans la roche"
            },
            {
                "value": 5637,
                "label": "Cavendish",
                "label_ar": "كافنديش",
                "label_fr": "Cavendish"
            },
            {
                "value": 5638,
                "label": "Cavour",
                "label_ar": "كافور",
                "label_fr": "Cavour"
            },
            {
                "value": 5639,
                "label": "Cawood",
                "label_ar": "كاود",
                "label_fr": "Cawood"
            },
            {
                "value": 5640,
                "label": "Cayce",
                "label_ar": "كايس",
                "label_fr": "Cayce"
            },
            {
                "value": 5641,
                "label": "Cayucos",
                "label_ar": "كايوكوس",
                "label_fr": "Cayucos"
            },
            {
                "value": 5642,
                "label": "Cayuga",
                "label_ar": "كايوجا",
                "label_fr": "Cayuga"
            },
            {
                "value": 5643,
                "label": "Cayuta",
                "label_ar": "كايوتا",
                "label_fr": "Cayuta"
            },
            {
                "value": 5644,
                "label": "Cazadero",
                "label_ar": "كازاديرو",
                "label_fr": "Cazadero"
            },
            {
                "value": 5645,
                "label": "Cazenovia",
                "label_ar": "كازينوفيا",
                "label_fr": "Cazenovia"
            },
            {
                "value": 5646,
                "label": "Cañon City",
                "label_ar": "مدينة كانون",
                "label_fr": "Ville de Cañon"
            },
            {
                "value": 5647,
                "label": "Cecil",
                "label_ar": "سيسيل",
                "label_fr": "Cecil"
            },
            {
                "value": 5648,
                "label": "Cecilia",
                "label_ar": "سيسيليا",
                "label_fr": "Cecilia"
            },
            {
                "value": 5649,
                "label": "Cecilton",
                "label_ar": "سيسيلتون",
                "label_fr": "Cecilton"
            },
            {
                "value": 5650,
                "label": "Cedar",
                "label_ar": "سيدار",
                "label_fr": "Cèdre"
            },
            {
                "value": 5651,
                "label": "Cedar Bluff",
                "label_ar": "سيدار بلاف",
                "label_fr": "Cedar Bluff"
            },
            {
                "value": 5652,
                "label": "Cedar Bluffs",
                "label_ar": "سيدار بلافز",
                "label_fr": "Cedar Bluffs"
            },
            {
                "value": 5653,
                "label": "Cedar Brook",
                "label_ar": "سيدار بروك",
                "label_fr": "Cedar Brook"
            },
            {
                "value": 5654,
                "label": "Cedar City",
                "label_ar": "سيدار سيتي",
                "label_fr": "Cedar City"
            },
            {
                "value": 5655,
                "label": "Cedar Creek",
                "label_ar": "سيدار كريك",
                "label_fr": "Cedar Creek"
            },
            {
                "value": 5656,
                "label": "Cedar Crest",
                "label_ar": "سيدار كريست",
                "label_fr": "Crête de cèdre"
            },
            {
                "value": 5657,
                "label": "Cedar Falls",
                "label_ar": "شلالات سيدار",
                "label_fr": "Cedar Falls"
            },
            {
                "value": 5658,
                "label": "Cedar Glen",
                "label_ar": "سيدار جلين",
                "label_fr": "Cedar Glen"
            },
            {
                "value": 5659,
                "label": "Cedar Grove",
                "label_ar": "سيدار جروف",
                "label_fr": "Cedar Grove"
            },
            {
                "value": 5660,
                "label": "Cedar Hill",
                "label_ar": "سيدار هيل",
                "label_fr": "Cedar Hill"
            },
            {
                "value": 5661,
                "label": "Cedar Key",
                "label_ar": "مفتاح سيدار",
                "label_fr": "Clé de cèdre"
            },
            {
                "value": 5662,
                "label": "Cedar Knolls",
                "label_ar": "الربوة الارز",
                "label_fr": "Cèdre Knolls"
            },
            {
                "value": 5663,
                "label": "Cedar Lake",
                "label_ar": "بحيرة الأرز",
                "label_fr": "Cedar Lake"
            },
            {
                "value": 5664,
                "label": "Cedar Park",
                "label_ar": "سيدار بارك",
                "label_fr": "Cedar Park"
            },
            {
                "value": 5665,
                "label": "Cedar Point",
                "label_ar": "سيدار بوينت",
                "label_fr": "Point de cèdre"
            },
            {
                "value": 5666,
                "label": "Cedar Rapids",
                "label_ar": "منحدرات الأرز",
                "label_fr": "Cedar Rapids"
            },
            {
                "value": 5667,
                "label": "Cedar Ridge",
                "label_ar": "سيدار ريدج",
                "label_fr": "Cedar Ridge"
            },
            {
                "value": 5668,
                "label": "Cedar Springs",
                "label_ar": "سيدار سبرينغز",
                "label_fr": "Cedar Springs"
            },
            {
                "value": 5669,
                "label": "Cedar Vale",
                "label_ar": "سيدار فالي",
                "label_fr": "Cedar Vale"
            },
            {
                "value": 5670,
                "label": "Cedar Valley",
                "label_ar": "وادي الأرز",
                "label_fr": "Cedar Valley"
            },
            {
                "value": 5671,
                "label": "Cedarburg",
                "label_ar": "سيداربورغ",
                "label_fr": "Cedarburg"
            },
            {
                "value": 5672,
                "label": "Cedaredge",
                "label_ar": "سيدارج",
                "label_fr": "Cedaredge"
            },
            {
                "value": 5673,
                "label": "Cedarhurst",
                "label_ar": "سيدارهيرست",
                "label_fr": "Cedarhurst"
            },
            {
                "value": 5674,
                "label": "Cedartown",
                "label_ar": "سيدارتاون",
                "label_fr": "Cedartown"
            },
            {
                "value": 5675,
                "label": "Cedarville",
                "label_ar": "سيدارفيل",
                "label_fr": "Cedarville"
            },
            {
                "value": 5676,
                "label": "Celeste",
                "label_ar": "سيليست",
                "label_fr": "Céleste"
            },
            {
                "value": 5677,
                "label": "Celina",
                "label_ar": "سيلينا",
                "label_fr": "Célina"
            },
            {
                "value": 5678,
                "label": "Celoron",
                "label_ar": "سيلورون",
                "label_fr": "Céloron"
            },
            {
                "value": 5679,
                "label": "Cement",
                "label_ar": "يبني",
                "label_fr": "Ciment"
            },
            {
                "value": 5680,
                "label": "Cement City",
                "label_ar": "مدينة الاسمنت",
                "label_fr": "Cement City"
            },
            {
                "value": 5681,
                "label": "Centenary",
                "label_ar": "المئوية",
                "label_fr": "Centenaire"
            },
            {
                "value": 5682,
                "label": "Center",
                "label_ar": "مركز",
                "label_fr": "Centre"
            },
            {
                "value": 5683,
                "label": "Center Barnstead",
                "label_ar": "مركز بارنستيد",
                "label_fr": "Centre Barnstead"
            },
            {
                "value": 5684,
                "label": "Center City",
                "label_ar": "وسط المدينة",
                "label_fr": "Centre ville"
            },
            {
                "value": 5685,
                "label": "Center Conway",
                "label_ar": "مركز كونواي",
                "label_fr": "Centre Conway"
            },
            {
                "value": 5686,
                "label": "Center Cross",
                "label_ar": "سنتر كروس",
                "label_fr": "Croix centrale"
            },
            {
                "value": 5687,
                "label": "Center Harbor",
                "label_ar": "سنتر هاربور",
                "label_fr": "Centre Harbour"
            },
            {
                "value": 5688,
                "label": "Center Hill",
                "label_ar": "سنتر هيل",
                "label_fr": "Centre Hill"
            },
            {
                "value": 5689,
                "label": "Center Junction",
                "label_ar": "سنتر جنكشن",
                "label_fr": "Centre Junction"
            },
            {
                "value": 5690,
                "label": "Center Line",
                "label_ar": "خط الوسط",
                "label_fr": "Ligne centrale"
            },
            {
                "value": 5691,
                "label": "Center Moriches",
                "label_ar": "مركز Moriches",
                "label_fr": "Centre Moriches"
            },
            {
                "value": 5692,
                "label": "Center Ossipee",
                "label_ar": "مركز أوسيبي",
                "label_fr": "Centre Ossipee"
            },
            {
                "value": 5693,
                "label": "Center Point",
                "label_ar": "نقطة المركز",
                "label_fr": "Point central"
            },
            {
                "value": 5694,
                "label": "Center Ridge",
                "label_ar": "مركز ريدج",
                "label_fr": "Centre Ridge"
            },
            {
                "value": 5695,
                "label": "Center Rutland",
                "label_ar": "مركز روتلاند",
                "label_fr": "Centre Rutland"
            },
            {
                "value": 5696,
                "label": "Center Sandwich",
                "label_ar": "سنتر ساندوتش",
                "label_fr": "Sandwich au centre"
            },
            {
                "value": 5697,
                "label": "Center Tuftonboro",
                "label_ar": "مركز Tuftonboro",
                "label_fr": "Centre Tuftonboro"
            },
            {
                "value": 5698,
                "label": "Center Valley",
                "label_ar": "سنتر فالي",
                "label_fr": "Centre Valley"
            },
            {
                "value": 5699,
                "label": "Centerbrook",
                "label_ar": "سنتربروك",
                "label_fr": "Centerbrook"
            },
            {
                "value": 5700,
                "label": "Centerburg",
                "label_ar": "سنتربرج",
                "label_fr": "Centerburg"
            },
            {
                "value": 5701,
                "label": "Centereach",
                "label_ar": "سنتريتش",
                "label_fr": "Centereach"
            },
            {
                "value": 5702,
                "label": "Centerfield",
                "label_ar": "سنترفيلد",
                "label_fr": "Champ central"
            },
            {
                "value": 5703,
                "label": "Centerport",
                "label_ar": "سنتربورت",
                "label_fr": "Centerport"
            },
            {
                "value": 5704,
                "label": "Centerton",
                "label_ar": "سنتيرتون",
                "label_fr": "Centerton"
            },
            {
                "value": 5705,
                "label": "Centertown",
                "label_ar": "سنتر تاون",
                "label_fr": "Centertown"
            },
            {
                "value": 5706,
                "label": "Centerview",
                "label_ar": "سنتفيو",
                "label_fr": "Centerview"
            },
            {
                "value": 5707,
                "label": "Centerville",
                "label_ar": "سنترفيل",
                "label_fr": "Centreville"
            },
            {
                "value": 5708,
                "label": "Centrahoma",
                "label_ar": "سنتراهوما",
                "label_fr": "Centrahoma"
            },
            {
                "value": 5709,
                "label": "Central",
                "label_ar": "وسط",
                "label_fr": "Central"
            },
            {
                "value": 5710,
                "label": "Central Bridge",
                "label_ar": "الجسر المركزي",
                "label_fr": "Pont central"
            },
            {
                "value": 5711,
                "label": "Central City",
                "label_ar": "وسط المدينة",
                "label_fr": "Centre ville"
            },
            {
                "value": 5712,
                "label": "Central Falls",
                "label_ar": "سنترال فولز",
                "label_fr": "Central Falls"
            },
            {
                "value": 5713,
                "label": "Central Islip",
                "label_ar": "وسط إسليب",
                "label_fr": "Islip central"
            },
            {
                "value": 5714,
                "label": "Central Lake",
                "label_ar": "البحيرة المركزية",
                "label_fr": "Lac central"
            },
            {
                "value": 5715,
                "label": "Central Point",
                "label_ar": "سنترال بوينت",
                "label_fr": "Point central"
            },
            {
                "value": 5716,
                "label": "Central Square",
                "label_ar": "الميدان المركزي",
                "label_fr": "Place centrale"
            },
            {
                "value": 5717,
                "label": "Central Valley",
                "label_ar": "الوادي المركزي",
                "label_fr": "Vallée centrale"
            },
            {
                "value": 5718,
                "label": "Central Village",
                "label_ar": "القرية المركزية",
                "label_fr": "Village central"
            },
            {
                "value": 5719,
                "label": "Centralia",
                "label_ar": "سينتراليا",
                "label_fr": "Centralia"
            },
            {
                "value": 5720,
                "label": "Centre",
                "label_ar": "مركز",
                "label_fr": "Centre"
            },
            {
                "value": 5721,
                "label": "Centre Hall",
                "label_ar": "قاعة المركز",
                "label_fr": "Hall central"
            },
            {
                "value": 5722,
                "label": "Centreville",
                "label_ar": "سنترفيل",
                "label_fr": "Centreville"
            },
            {
                "value": 5723,
                "label": "Centuria",
                "label_ar": "سنتوريا",
                "label_fr": "Centuria"
            },
            {
                "value": 5724,
                "label": "Century",
                "label_ar": "مئة عام",
                "label_fr": "Siècle"
            },
            {
                "value": 5725,
                "label": "Ceres",
                "label_ar": "سيريس",
                "label_fr": "Cérès"
            },
            {
                "value": 5726,
                "label": "Ceresco",
                "label_ar": "سيريسكو",
                "label_fr": "Ceresco"
            },
            {
                "value": 5727,
                "label": "Cerrillos",
                "label_ar": "سيريلوس",
                "label_fr": "Cerrillos"
            },
            {
                "value": 5728,
                "label": "Cerritos",
                "label_ar": "سيريتوس",
                "label_fr": "Cerritos"
            },
            {
                "value": 5729,
                "label": "Cerro",
                "label_ar": "سيرو",
                "label_fr": "Cerro"
            },
            {
                "value": 5730,
                "label": "Cerro Gordo",
                "label_ar": "سيرو جوردو",
                "label_fr": "Cerro Gordo"
            },
            {
                "value": 5731,
                "label": "Cerulean",
                "label_ar": "سيرولين",
                "label_fr": "Azuré"
            },
            {
                "value": 5732,
                "label": "Chadbourn",
                "label_ar": "تشادبورن",
                "label_fr": "Chadbourn"
            },
            {
                "value": 5733,
                "label": "Chadds Ford",
                "label_ar": "شادز فورد",
                "label_fr": "Chadds Ford"
            },
            {
                "value": 5734,
                "label": "Chadron",
                "label_ar": "شادرون",
                "label_fr": "Chadron"
            },
            {
                "value": 5735,
                "label": "Chadwick",
                "label_ar": "تشادويك",
                "label_fr": "Chadwick"
            },
            {
                "value": 5736,
                "label": "Chaffee",
                "label_ar": "تشافي",
                "label_fr": "Chaffee"
            },
            {
                "value": 5737,
                "label": "Chagrin Falls",
                "label_ar": "شلالات شاجرين",
                "label_fr": "Chutes de Chagrin"
            },
            {
                "value": 5738,
                "label": "Chalfont",
                "label_ar": "تشالفونت",
                "label_fr": "Chalfont"
            },
            {
                "value": 5739,
                "label": "Challis",
                "label_ar": "تشاليس",
                "label_fr": "Challis"
            },
            {
                "value": 5740,
                "label": "Chalmette",
                "label_ar": "تشالميت",
                "label_fr": "Chalmette"
            },
            {
                "value": 5741,
                "label": "Chama",
                "label_ar": "شامة",
                "label_fr": "Chama"
            },
            {
                "value": 5742,
                "label": "Chamberino",
                "label_ar": "شامبيرينو",
                "label_fr": "Chamberino"
            },
            {
                "value": 5743,
                "label": "Chamberlain",
                "label_ar": "تشامبرلين",
                "label_fr": "Chambellan"
            },
            {
                "value": 5744,
                "label": "Chambers",
                "label_ar": "الغرف",
                "label_fr": "Chambres"
            },
            {
                "value": 5745,
                "label": "Chambersburg",
                "label_ar": "تشامبرسبيرغ",
                "label_fr": "Chambersburg"
            },
            {
                "value": 5746,
                "label": "Champaign",
                "label_ar": "شامبين",
                "label_fr": "Champaign"
            },
            {
                "value": 5747,
                "label": "Champion",
                "label_ar": "بطل",
                "label_fr": "Champion"
            },
            {
                "value": 5748,
                "label": "Champlain",
                "label_ar": "شامبلين",
                "label_fr": "Champlain"
            },
            {
                "value": 5749,
                "label": "Champlin",
                "label_ar": "شامبلين",
                "label_fr": "Champlin"
            },
            {
                "value": 5750,
                "label": "Chana",
                "label_ar": "شانا",
                "label_fr": "Chana"
            },
            {
                "value": 5751,
                "label": "Chancellor",
                "label_ar": "المستشار",
                "label_fr": "Chancelier"
            },
            {
                "value": 5752,
                "label": "Chandler",
                "label_ar": "تشاندلر",
                "label_fr": "Chandler"
            },
            {
                "value": 5753,
                "label": "Chandlersville",
                "label_ar": "تشاندلرسفيل",
                "label_fr": "Chandlersville"
            },
            {
                "value": 5754,
                "label": "Chandlerville",
                "label_ar": "تشاندلرفيل",
                "label_fr": "Chandlerville"
            },
            {
                "value": 5755,
                "label": "Chanhassen",
                "label_ar": "تشانهاسن",
                "label_fr": "Chanhassen"
            },
            {
                "value": 5756,
                "label": "Channahon",
                "label_ar": "شاناهون",
                "label_fr": "Channahon"
            },
            {
                "value": 5757,
                "label": "Channelview",
                "label_ar": "تشانيلفيو",
                "label_fr": "Channelview"
            },
            {
                "value": 5758,
                "label": "Channing",
                "label_ar": "تشانينج",
                "label_fr": "Channing"
            },
            {
                "value": 5759,
                "label": "Chantilly",
                "label_ar": "شانتيلي",
                "label_fr": "Chantilly"
            },
            {
                "value": 5760,
                "label": "Chanute",
                "label_ar": "تشانوت",
                "label_fr": "Chanute"
            },
            {
                "value": 5761,
                "label": "Chaparral",
                "label_ar": "شابارال",
                "label_fr": "Chaparral"
            },
            {
                "value": 5762,
                "label": "Chapel Hill",
                "label_ar": "التل كنيسة",
                "label_fr": "Chapel Hill"
            },
            {
                "value": 5763,
                "label": "Chapin",
                "label_ar": "شابين",
                "label_fr": "Chapin"
            },
            {
                "value": 5764,
                "label": "Chaplin",
                "label_ar": "شابلن",
                "label_fr": "Chaplin"
            },
            {
                "value": 5765,
                "label": "Chapman",
                "label_ar": "تشابمان",
                "label_fr": "Chapman"
            },
            {
                "value": 5766,
                "label": "Chapmansboro",
                "label_ar": "تشابمانسبورو",
                "label_fr": "Chapmansboro"
            },
            {
                "value": 5767,
                "label": "Chapmanville",
                "label_ar": "تشابمانفيل",
                "label_fr": "Chapmanville"
            },
            {
                "value": 5768,
                "label": "Chappaqua",
                "label_ar": "تشاباكوا",
                "label_fr": "Chappaqua"
            },
            {
                "value": 5769,
                "label": "Chappell Hill",
                "label_ar": "تشابيل هيل",
                "label_fr": "Chappell Hill"
            },
            {
                "value": 5770,
                "label": "Chardon",
                "label_ar": "شاردون",
                "label_fr": "Chardon"
            },
            {
                "value": 5771,
                "label": "Charenton",
                "label_ar": "تشارنتون",
                "label_fr": "Charenton"
            },
            {
                "value": 5772,
                "label": "Chariton",
                "label_ar": "شاريتون",
                "label_fr": "Chariton"
            },
            {
                "value": 5773,
                "label": "Charlemont",
                "label_ar": "شارلمونت",
                "label_fr": "Charlemont"
            },
            {
                "value": 5774,
                "label": "Charleroi",
                "label_ar": "شارلروا",
                "label_fr": "Charleroi"
            },
            {
                "value": 5775,
                "label": "Charles City",
                "label_ar": "تشارلز سيتي",
                "label_fr": "Charles City"
            },
            {
                "value": 5776,
                "label": "Charles Town",
                "label_ar": "تشارلز تاون",
                "label_fr": "Charles Town"
            },
            {
                "value": 5777,
                "label": "Charleston",
                "label_ar": "تشارلستون",
                "label_fr": "Charleston"
            },
            {
                "value": 5778,
                "label": "Charlestown",
                "label_ar": "تشارلزتاون",
                "label_fr": "Charlestown"
            },
            {
                "value": 5779,
                "label": "Charlevoix",
                "label_ar": "شارلفوا",
                "label_fr": "Charlevoix"
            },
            {
                "value": 5780,
                "label": "Charlotte",
                "label_ar": "شارلوت",
                "label_fr": "Charlotte"
            },
            {
                "value": 5781,
                "label": "Charlotte Court House",
                "label_ar": "بيت محكمة شارلوت",
                "label_fr": "Palais de justice de Charlotte"
            },
            {
                "value": 5782,
                "label": "Charlotte Hall",
                "label_ar": "شارلوت هول",
                "label_fr": "Charlotte Hall"
            },
            {
                "value": 5783,
                "label": "Charlottesville",
                "label_ar": "شارلوتسفيل",
                "label_fr": "Charlottesville"
            },
            {
                "value": 5784,
                "label": "Charlotteville",
                "label_ar": "شارلوتفيل",
                "label_fr": "Charlotteville"
            },
            {
                "value": 5785,
                "label": "Charlton",
                "label_ar": "تشارلتون",
                "label_fr": "Charlton"
            },
            {
                "value": 5786,
                "label": "Charter Oak",
                "label_ar": "ميثاق البلوط",
                "label_fr": "Chêne Charter"
            },
            {
                "value": 5787,
                "label": "Charter Township of Clinton",
                "label_ar": "بلدة تشارتر من كلينتون",
                "label_fr": "Canton de Charte de Clinton"
            },
            {
                "value": 5788,
                "label": "Chartley",
                "label_ar": "تشارتلي",
                "label_fr": "Chartley"
            },
            {
                "value": 5789,
                "label": "Chase",
                "label_ar": "مطاردة",
                "label_fr": "Chasse"
            },
            {
                "value": 5790,
                "label": "Chase City",
                "label_ar": "تشيس سيتي",
                "label_fr": "Chase City"
            },
            {
                "value": 5791,
                "label": "Chaseburg",
                "label_ar": "تشاسبورغ",
                "label_fr": "Chaseburg"
            },
            {
                "value": 5792,
                "label": "Chaseley",
                "label_ar": "تشاسلي",
                "label_fr": "Chaseley"
            },
            {
                "value": 5793,
                "label": "Chaska",
                "label_ar": "شاسكا",
                "label_fr": "Chaska"
            },
            {
                "value": 5794,
                "label": "Chassell",
                "label_ar": "شاسيل",
                "label_fr": "Chassell"
            },
            {
                "value": 5795,
                "label": "Chataignier",
                "label_ar": "شاتينييه",
                "label_fr": "Chataignier"
            },
            {
                "value": 5796,
                "label": "Chatfield",
                "label_ar": "شاتفيلد",
                "label_fr": "Chatfield"
            },
            {
                "value": 5797,
                "label": "Chatham",
                "label_ar": "تشاتام",
                "label_fr": "Chatham"
            },
            {
                "value": 5798,
                "label": "Chatom",
                "label_ar": "شاتوم",
                "label_fr": "Chatom"
            },
            {
                "value": 5799,
                "label": "Chatsworth",
                "label_ar": "تشاتسوورث",
                "label_fr": "Chatsworth"
            },
            {
                "value": 5800,
                "label": "Chattahoochee",
                "label_ar": "تشاتاهوتشي",
                "label_fr": "Chattahoochee"
            },
            {
                "value": 5801,
                "label": "Chattanooga",
                "label_ar": "تشاتانوغا",
                "label_fr": "Chattanooga"
            },
            {
                "value": 5802,
                "label": "Chattaroy",
                "label_ar": "تشاتاروي",
                "label_fr": "Chattaroy"
            },
            {
                "value": 5803,
                "label": "Chaumont",
                "label_ar": "شومون",
                "label_fr": "Chaumont"
            },
            {
                "value": 5804,
                "label": "Chauncey",
                "label_ar": "تشونسي",
                "label_fr": "Chauncey"
            },
            {
                "value": 5805,
                "label": "Chautauqua",
                "label_ar": "تشوتاوكوا",
                "label_fr": "Chautauqua"
            },
            {
                "value": 5806,
                "label": "Chauvin",
                "label_ar": "شوفين",
                "label_fr": "Chauvin"
            },
            {
                "value": 5807,
                "label": "Chavies",
                "label_ar": "تشافيس",
                "label_fr": "Chavies"
            },
            {
                "value": 5808,
                "label": "Chazy",
                "label_ar": "تشازي",
                "label_fr": "Chazy"
            },
            {
                "value": 5809,
                "label": "Chebanse",
                "label_ar": "شيبانس",
                "label_fr": "Chebanse"
            },
            {
                "value": 5810,
                "label": "Chebeague Island",
                "label_ar": "جزيرة Chebeague",
                "label_fr": "Île Chebeague"
            },
            {
                "value": 5811,
                "label": "Cheboygan",
                "label_ar": "تشيبويجان",
                "label_fr": "Cheboygan"
            },
            {
                "value": 5812,
                "label": "Checotah",
                "label_ar": "Checotah",
                "label_fr": "Checotah"
            },
            {
                "value": 5813,
                "label": "Chefornak",
                "label_ar": "شيفورناك",
                "label_fr": "Chefornak"
            },
            {
                "value": 5814,
                "label": "Chehalis",
                "label_ar": "Chehalis",
                "label_fr": "Chehalis"
            },
            {
                "value": 5815,
                "label": "Chelan",
                "label_ar": "شيلان",
                "label_fr": "Chelan"
            },
            {
                "value": 5816,
                "label": "Chelan Falls",
                "label_ar": "شلالات شيلان",
                "label_fr": "Chelan Falls"
            },
            {
                "value": 5817,
                "label": "Chelmsford",
                "label_ar": "تشيلمسفورد",
                "label_fr": "Chelmsford"
            },
            {
                "value": 5818,
                "label": "Chelsea",
                "label_ar": "تشيلسي",
                "label_fr": "Chelsea"
            },
            {
                "value": 5819,
                "label": "Cheltenham",
                "label_ar": "شلتنهام",
                "label_fr": "Cheltenham"
            },
            {
                "value": 5820,
                "label": "Chemung",
                "label_ar": "شيمونج",
                "label_fr": "Chemung"
            },
            {
                "value": 5821,
                "label": "Chenango Forks",
                "label_ar": "تشينانجو فوركس",
                "label_fr": "Fourches Chenango"
            },
            {
                "value": 5822,
                "label": "Cheney",
                "label_ar": "تشيني",
                "label_fr": "Cheney"
            },
            {
                "value": 5823,
                "label": "Cheneyville",
                "label_ar": "تشينيفيل",
                "label_fr": "Cheneyville"
            },
            {
                "value": 5824,
                "label": "Chenoa",
                "label_ar": "Chenoa",
                "label_fr": "Chenoa"
            },
            {
                "value": 5825,
                "label": "Chepachet",
                "label_ar": "Chepachet",
                "label_fr": "Chepachet"
            },
            {
                "value": 5826,
                "label": "Cheraw",
                "label_ar": "شيراو",
                "label_fr": "Cheraw"
            },
            {
                "value": 5827,
                "label": "Cheriton",
                "label_ar": "تشريتون",
                "label_fr": "Cheriton"
            },
            {
                "value": 5828,
                "label": "Cherokee",
                "label_ar": "شيروكي",
                "label_fr": "Cherokee"
            },
            {
                "value": 5829,
                "label": "Cherokee Village",
                "label_ar": "قرية شيروكي",
                "label_fr": "Village de Cherokee"
            },
            {
                "value": 5830,
                "label": "Cherry Hill",
                "label_ar": "شيري هيل",
                "label_fr": "Cherry Hill"
            },
            {
                "value": 5831,
                "label": "Cherry Log",
                "label_ar": "سجل الكرز",
                "label_fr": "Bûche cerise"
            },
            {
                "value": 5832,
                "label": "Cherry Point Marine Base Mobile Home Park",
                "label_ar": "حديقة Cherry Point Marine Base Mobile Home Park",
                "label_fr": "Parc de maisons mobiles Cherry Point Marine Base"
            },
            {
                "value": 5833,
                "label": "Cherry Tree",
                "label_ar": "شجرة الكرز",
                "label_fr": "Cerisier"
            },
            {
                "value": 5834,
                "label": "Cherry Valley",
                "label_ar": "وادي الكرز",
                "label_fr": "Cherry Valley"
            },
            {
                "value": 5835,
                "label": "Cherryfield",
                "label_ar": "شيريفيلد",
                "label_fr": "Cherryfield"
            },
            {
                "value": 5836,
                "label": "Cherryvale",
                "label_ar": "Cherryvale",
                "label_fr": "Cherryvale"
            },
            {
                "value": 5837,
                "label": "Cherryville",
                "label_ar": "شيريفيل",
                "label_fr": "Cherryville"
            },
            {
                "value": 5838,
                "label": "Chesaning",
                "label_ar": "تشيزانينج",
                "label_fr": "Chesaning"
            },
            {
                "value": 5839,
                "label": "Chesapeake",
                "label_ar": "تشيسابيك",
                "label_fr": "Chesapeake"
            },
            {
                "value": 5840,
                "label": "Chesapeake Beach",
                "label_ar": "شاطئ تشيسابيك",
                "label_fr": "Plage de Chesapeake"
            },
            {
                "value": 5841,
                "label": "Chesapeake City",
                "label_ar": "مدينة تشيسابيك",
                "label_fr": "Ville de Chesapeake"
            },
            {
                "value": 5842,
                "label": "Cheshire",
                "label_ar": "شيشاير",
                "label_fr": "Cheshire"
            },
            {
                "value": 5843,
                "label": "Chesnee",
                "label_ar": "تشيسني",
                "label_fr": "Chesnee"
            },
            {
                "value": 5844,
                "label": "Chester",
                "label_ar": "تشيستر",
                "label_fr": "Chester"
            },
            {
                "value": 5845,
                "label": "Chester Heights",
                "label_ar": "تشيستر هايتس",
                "label_fr": "Chester Heights"
            },
            {
                "value": 5846,
                "label": "Chester Springs",
                "label_ar": "تشيستر سبرينغز",
                "label_fr": "Chester Springs"
            },
            {
                "value": 5847,
                "label": "Chesterfield",
                "label_ar": "الحقل المصدر",
                "label_fr": "Chesterfield"
            },
            {
                "value": 5848,
                "label": "Chesterhill",
                "label_ar": "تشيسترهيل",
                "label_fr": "Chesterhill"
            },
            {
                "value": 5849,
                "label": "Chesterland",
                "label_ar": "تشيسترلاند",
                "label_fr": "Chesterland"
            },
            {
                "value": 5850,
                "label": "Chesterton",
                "label_ar": "تشيسترتون",
                "label_fr": "Chesterton"
            },
            {
                "value": 5851,
                "label": "Chestertown",
                "label_ar": "تشيسترتاون",
                "label_fr": "Chestertown"
            },
            {
                "value": 5852,
                "label": "Chestnut",
                "label_ar": "كستناء",
                "label_fr": "châtaigne"
            },
            {
                "value": 5853,
                "label": "Chestnut Hill",
                "label_ar": "كستناء هيل",
                "label_fr": "Chestnut Hill"
            },
            {
                "value": 5854,
                "label": "Chestnut Mountain",
                "label_ar": "جبل الكستناء",
                "label_fr": "Chestnut Mountain"
            },
            {
                "value": 5855,
                "label": "Chestnut Ridge",
                "label_ar": "كستناء ريدج",
                "label_fr": "Chestnut Ridge"
            },
            {
                "value": 5856,
                "label": "Cheswick",
                "label_ar": "تشيسويك",
                "label_fr": "Cheswick"
            },
            {
                "value": 5857,
                "label": "Chetek",
                "label_ar": "شتيك",
                "label_fr": "Chetek"
            },
            {
                "value": 5858,
                "label": "Chetopa",
                "label_ar": "شيتوبا",
                "label_fr": "Chetopa"
            },
            {
                "value": 5859,
                "label": "Chevak",
                "label_ar": "شوفاك",
                "label_fr": "Chevak"
            },
            {
                "value": 5860,
                "label": "Chevy Chase",
                "label_ar": "تشيفي تشيس",
                "label_fr": "Chevy Chase"
            },
            {
                "value": 5861,
                "label": "Chewelah",
                "label_ar": "شويلة",
                "label_fr": "Chewelah"
            },
            {
                "value": 5862,
                "label": "Cheyenne",
                "label_ar": "شايان",
                "label_fr": "Cheyenne"
            },
            {
                "value": 5863,
                "label": "Cheyenne Wells",
                "label_ar": "شايان ويلز",
                "label_fr": "Cheyenne Wells"
            },
            {
                "value": 5864,
                "label": "Cheyney",
                "label_ar": "تشيني",
                "label_fr": "Cheyney"
            },
            {
                "value": 5865,
                "label": "Chicago",
                "label_ar": "شيكاغو",
                "label_fr": "Chicago"
            },
            {
                "value": 5866,
                "label": "Chicago Heights",
                "label_ar": "مرتفعات شيكاغو",
                "label_fr": "Chicago Heights"
            },
            {
                "value": 5867,
                "label": "Chicago Ridge",
                "label_ar": "شيكاغو ريدج",
                "label_fr": "Chicago Ridge"
            },
            {
                "value": 5868,
                "label": "Chichester",
                "label_ar": "شيشستر",
                "label_fr": "Chichester"
            },
            {
                "value": 5869,
                "label": "Chickamauga",
                "label_ar": "تشيكاماوجا",
                "label_fr": "Chickamauga"
            },
            {
                "value": 5870,
                "label": "Chickasha",
                "label_ar": "تشيكاشا",
                "label_fr": "Chickasha"
            },
            {
                "value": 5871,
                "label": "Chicken",
                "label_ar": "دجاج",
                "label_fr": "poulet"
            },
            {
                "value": 5872,
                "label": "Chico",
                "label_ar": "شيكو",
                "label_fr": "Chico"
            },
            {
                "value": 5873,
                "label": "Chicopee",
                "label_ar": "شيكوبي",
                "label_fr": "Chicopée"
            },
            {
                "value": 5874,
                "label": "Chicora",
                "label_ar": "شيكورا",
                "label_fr": "Chicora"
            },
            {
                "value": 5875,
                "label": "Chiefland",
                "label_ar": "شيفلاند",
                "label_fr": "Chiefland"
            },
            {
                "value": 5876,
                "label": "Childersburg",
                "label_ar": "تشلدرسبورغ",
                "label_fr": "Childersburg"
            },
            {
                "value": 5877,
                "label": "Childress",
                "label_ar": "تشايلدريس",
                "label_fr": "Childress"
            },
            {
                "value": 5878,
                "label": "Childs",
                "label_ar": "تشايلدز",
                "label_fr": "Enfants"
            },
            {
                "value": 5879,
                "label": "Chilhowee",
                "label_ar": "تشيلهوي",
                "label_fr": "Chilhowee"
            },
            {
                "value": 5880,
                "label": "Chilhowie",
                "label_ar": "تشيلهوي",
                "label_fr": "Chilhowie"
            },
            {
                "value": 5881,
                "label": "Chili",
                "label_ar": "الفلفل الحار",
                "label_fr": "le Chili"
            },
            {
                "value": 5882,
                "label": "Chillicothe",
                "label_ar": "تشيليكوث",
                "label_fr": "Chillicothe"
            },
            {
                "value": 5883,
                "label": "Chilo",
                "label_ar": "تشيلو",
                "label_fr": "Chilo"
            },
            {
                "value": 5884,
                "label": "Chilton",
                "label_ar": "شيلتون",
                "label_fr": "Chilton"
            },
            {
                "value": 5885,
                "label": "Chimacum",
                "label_ar": "شيماكوم",
                "label_fr": "Chimacum"
            },
            {
                "value": 5886,
                "label": "China Grove",
                "label_ar": "تشاينا جروف",
                "label_fr": "China Grove"
            },
            {
                "value": 5887,
                "label": "China Springs",
                "label_ar": "الصين سبرينغز",
                "label_fr": "China Springs"
            },
            {
                "value": 5888,
                "label": "Chinchilla",
                "label_ar": "شينشيلا",
                "label_fr": "Chinchilla"
            },
            {
                "value": 5889,
                "label": "Chincoteague Island",
                "label_ar": "جزيرة تشينكوتيج",
                "label_fr": "Île de Chincoteague"
            },
            {
                "value": 5890,
                "label": "Chinese Camp",
                "label_ar": "المعسكر الصيني",
                "label_fr": "Camp chinois"
            },
            {
                "value": 5891,
                "label": "Chinle",
                "label_ar": "تشينلي",
                "label_fr": "Chinle"
            },
            {
                "value": 5892,
                "label": "Chino",
                "label_ar": "تشينو",
                "label_fr": "Chino"
            },
            {
                "value": 5893,
                "label": "Chino Hills",
                "label_ar": "تلال تشينو",
                "label_fr": "Chino Hills"
            },
            {
                "value": 5894,
                "label": "Chino Valley",
                "label_ar": "وادي تشينو",
                "label_fr": "Vallée du Chino"
            },
            {
                "value": 5895,
                "label": "Chinook",
                "label_ar": "شينوك",
                "label_fr": "Chinook"
            },
            {
                "value": 5896,
                "label": "Chipley",
                "label_ar": "تشيبلي",
                "label_fr": "Chipley"
            },
            {
                "value": 5897,
                "label": "Chippewa Falls",
                "label_ar": "شلالات تشيبيوا",
                "label_fr": "Chippewa Falls"
            },
            {
                "value": 5898,
                "label": "Chippewa Lake",
                "label_ar": "بحيرة تشيبيوا",
                "label_fr": "Lac Chippewa"
            },
            {
                "value": 5899,
                "label": "Chireno",
                "label_ar": "شيرينو",
                "label_fr": "Chireno"
            },
            {
                "value": 5900,
                "label": "Chisago City",
                "label_ar": "مدينة تشيساغو",
                "label_fr": "Ville de Chisago"
            },
            {
                "value": 5901,
                "label": "Chisholm",
                "label_ar": "تشيشولم",
                "label_fr": "Chisholm"
            },
            {
                "value": 5902,
                "label": "Chittenango",
                "label_ar": "تشيتينانجو",
                "label_fr": "Chittenango"
            },
            {
                "value": 5903,
                "label": "Chittenden",
                "label_ar": "شيتيندين",
                "label_fr": "Chittenden"
            },
            {
                "value": 5904,
                "label": "Chloride",
                "label_ar": "كلوريد",
                "label_fr": "Chlorure"
            },
            {
                "value": 5905,
                "label": "Chocorua",
                "label_ar": "شوكوروا",
                "label_fr": "Chocorua"
            },
            {
                "value": 5906,
                "label": "Chocowinity",
                "label_ar": "تشوكووينيتي",
                "label_fr": "Chocowinity"
            },
            {
                "value": 5907,
                "label": "Choctaw",
                "label_ar": "الشوكتو",
                "label_fr": "Choctaw"
            },
            {
                "value": 5908,
                "label": "Chokio",
                "label_ar": "تشوكيو",
                "label_fr": "Chokio"
            },
            {
                "value": 5909,
                "label": "Choteau",
                "label_ar": "تشوتو",
                "label_fr": "Choteau"
            },
            {
                "value": 5910,
                "label": "Choudrant",
                "label_ar": "شودرانت",
                "label_fr": "Choudrant"
            },
            {
                "value": 5911,
                "label": "Chouteau",
                "label_ar": "تشوتو",
                "label_fr": "Chouteau"
            },
            {
                "value": 5912,
                "label": "Chowchilla",
                "label_ar": "تشوتشيلا",
                "label_fr": "Chowchilla"
            },
            {
                "value": 5913,
                "label": "Chrisman",
                "label_ar": "كريسمان",
                "label_fr": "Chrisman"
            },
            {
                "value": 5914,
                "label": "Christchurch",
                "label_ar": "كرايستشيرش",
                "label_fr": "Christchurch"
            },
            {
                "value": 5915,
                "label": "Christiana",
                "label_ar": "كريستيانا",
                "label_fr": "Christiana"
            },
            {
                "value": 5916,
                "label": "Christiansburg",
                "label_ar": "كريستيانسبورغ",
                "label_fr": "Christiansburg"
            },
            {
                "value": 5917,
                "label": "Christine",
                "label_ar": "كريستين",
                "label_fr": "Christine"
            },
            {
                "value": 5918,
                "label": "Christmas",
                "label_ar": "عيد الميلاد",
                "label_fr": "Noël"
            },
            {
                "value": 5919,
                "label": "Christmas Valley",
                "label_ar": "وادي عيد الميلاد",
                "label_fr": "Vallée de Noël"
            },
            {
                "value": 5920,
                "label": "Christopher",
                "label_ar": "كريستوفر",
                "label_fr": "Christophe"
            },
            {
                "value": 5921,
                "label": "Christoval",
                "label_ar": "كريستوفال",
                "label_fr": "Christoval"
            },
            {
                "value": 5922,
                "label": "Chualar",
                "label_ar": "شوالار",
                "label_fr": "Chualar"
            },
            {
                "value": 5923,
                "label": "Chuckey",
                "label_ar": "تشكي",
                "label_fr": "Chuckey"
            },
            {
                "value": 5924,
                "label": "Chugiak",
                "label_ar": "تشوجياك",
                "label_fr": "Chugiak"
            },
            {
                "value": 5925,
                "label": "Chugwater",
                "label_ar": "تشوغواتر",
                "label_fr": "Chugwater"
            },
            {
                "value": 5926,
                "label": "Chula",
                "label_ar": "شولا",
                "label_fr": "Chula"
            },
            {
                "value": 5927,
                "label": "Chula Vista",
                "label_ar": "شولا فيستا",
                "label_fr": "Chula Vista"
            },
            {
                "value": 5928,
                "label": "Chunchula",
                "label_ar": "شونتشولا",
                "label_fr": "Chunchula"
            },
            {
                "value": 5929,
                "label": "Chunky",
                "label_ar": "مكتنزة",
                "label_fr": "Chunky"
            },
            {
                "value": 5930,
                "label": "Church Hill",
                "label_ar": "تشرش هيل",
                "label_fr": "Church Hill"
            },
            {
                "value": 5931,
                "label": "Church Point",
                "label_ar": "تشيرش بوينت",
                "label_fr": "Point de l'église"
            },
            {
                "value": 5932,
                "label": "Church Rock",
                "label_ar": "صخرة الكنيسة",
                "label_fr": "Church Rock"
            },
            {
                "value": 5933,
                "label": "Churchton",
                "label_ar": "تشيرشتون",
                "label_fr": "Churchton"
            },
            {
                "value": 5934,
                "label": "Churchville",
                "label_ar": "تشيرشفيل",
                "label_fr": "Churchville"
            },
            {
                "value": 5935,
                "label": "Churdan",
                "label_ar": "كوردان",
                "label_fr": "Churdan"
            },
            {
                "value": 5936,
                "label": "Churubusco",
                "label_ar": "تشوروبوسكو",
                "label_fr": "Churubusco"
            },
            {
                "value": 5937,
                "label": "Cibola",
                "label_ar": "سيبولا",
                "label_fr": "Cibola"
            },
            {
                "value": 5938,
                "label": "Cibolo",
                "label_ar": "سيبولو",
                "label_fr": "Cibolo"
            },
            {
                "value": 5939,
                "label": "Cicero",
                "label_ar": "شيشرون",
                "label_fr": "Cicéron"
            },
            {
                "value": 5940,
                "label": "Cimarron",
                "label_ar": "سيمارون",
                "label_fr": "Cimarron"
            },
            {
                "value": 5941,
                "label": "Cincinnati",
                "label_ar": "سينسيناتي",
                "label_fr": "Cincinnati"
            },
            {
                "value": 5942,
                "label": "Cincinnatus",
                "label_ar": "سينسيناتوس",
                "label_fr": "Cincinnatus"
            },
            {
                "value": 5943,
                "label": "Cinebar",
                "label_ar": "سينبار",
                "label_fr": "Cinébar"
            },
            {
                "value": 5944,
                "label": "Circle",
                "label_ar": "دائرة",
                "label_fr": "Cercle"
            },
            {
                "value": 5945,
                "label": "Circle Pines",
                "label_ar": "دائرة الصنوبر",
                "label_fr": "Cercle des pins"
            },
            {
                "value": 5946,
                "label": "Circleville",
                "label_ar": "سيركلفيل",
                "label_fr": "Circleville"
            },
            {
                "value": 5947,
                "label": "Cisco",
                "label_ar": "سيسكو",
                "label_fr": "Cisco"
            },
            {
                "value": 5948,
                "label": "Cisne",
                "label_ar": "سيسن",
                "label_fr": "Cisne"
            },
            {
                "value": 5949,
                "label": "Cissna Park",
                "label_ar": "حديقة سيسنا",
                "label_fr": "Parc Cissna"
            },
            {
                "value": 5950,
                "label": "Citra",
                "label_ar": "سيترا",
                "label_fr": "Citra"
            },
            {
                "value": 5951,
                "label": "Citronelle",
                "label_ar": "السترونيل",
                "label_fr": "Citronelle"
            },
            {
                "value": 5952,
                "label": "Citrus Heights",
                "label_ar": "مرتفعات سيتروس",
                "label_fr": "Hauteurs d'agrumes"
            },
            {
                "value": 5953,
                "label": "City of Angels",
                "label_ar": "مدينة الملائكة",
                "label_fr": "Cité des Anges"
            },
            {
                "value": 5954,
                "label": "City of Industry",
                "label_ar": "مدينة الصناعة",
                "label_fr": "Ville de l'industrie"
            },
            {
                "value": 5955,
                "label": "City of Picher (historical)",
                "label_ar": "مدينة بيشر (تاريخية)",
                "label_fr": "Ville de Picher (historique)"
            },
            {
                "value": 5956,
                "label": "City of Saint Marys",
                "label_ar": "مدينة سانت ماري",
                "label_fr": "Ville de Saint Marys"
            },
            {
                "value": 5957,
                "label": "City of Saint Peters",
                "label_ar": "مدينة سانت بيترز",
                "label_fr": "Ville de Saint Peters"
            },
            {
                "value": 5958,
                "label": "Clackamas",
                "label_ar": "كلاكاماس",
                "label_fr": "Clackamas"
            },
            {
                "value": 5959,
                "label": "Claflin",
                "label_ar": "كلافلين",
                "label_fr": "Claflin"
            },
            {
                "value": 5960,
                "label": "Claire City",
                "label_ar": "كلير سيتي",
                "label_fr": "Claire City"
            },
            {
                "value": 5961,
                "label": "Clairton",
                "label_ar": "كليرتون",
                "label_fr": "Clairton"
            },
            {
                "value": 5962,
                "label": "Clam Lake",
                "label_ar": "بحيرة البطلينوس",
                "label_fr": "Clam Lake"
            },
            {
                "value": 5963,
                "label": "Clancy",
                "label_ar": "كلانسي",
                "label_fr": "Clancy"
            },
            {
                "value": 5964,
                "label": "Clanton",
                "label_ar": "كلانتون",
                "label_fr": "Clanton"
            },
            {
                "value": 5965,
                "label": "Clara City",
                "label_ar": "كلارا سيتي",
                "label_fr": "Clara City"
            },
            {
                "value": 5966,
                "label": "Clare",
                "label_ar": "كلير",
                "label_fr": "Clare"
            },
            {
                "value": 5967,
                "label": "Claremont",
                "label_ar": "كليرمونت",
                "label_fr": "Claremont"
            },
            {
                "value": 5968,
                "label": "Claremore",
                "label_ar": "كلاريمور",
                "label_fr": "Claremore"
            },
            {
                "value": 5969,
                "label": "Clarence",
                "label_ar": "كلارنس",
                "label_fr": "Clarence"
            },
            {
                "value": 5970,
                "label": "Clarendon",
                "label_ar": "كلاريندون",
                "label_fr": "Clarendon"
            },
            {
                "value": 5971,
                "label": "Clarendon Hills",
                "label_ar": "كلاريندون هيلز",
                "label_fr": "Clarendon Hills"
            },
            {
                "value": 5972,
                "label": "Claridge",
                "label_ar": "كلاريدج",
                "label_fr": "Claridge"
            },
            {
                "value": 5973,
                "label": "Clarinda",
                "label_ar": "كلاريندا",
                "label_fr": "Clarinda"
            },
            {
                "value": 5974,
                "label": "Clarington",
                "label_ar": "كلارينجتون",
                "label_fr": "Clarington"
            },
            {
                "value": 5975,
                "label": "Clarion",
                "label_ar": "كلاريون",
                "label_fr": "Clairon"
            },
            {
                "value": 5976,
                "label": "Clarissa",
                "label_ar": "كلاريسا",
                "label_fr": "Clarissa"
            },
            {
                "value": 5977,
                "label": "Clark",
                "label_ar": "كلارك",
                "label_fr": "Clark"
            },
            {
                "value": 5978,
                "label": "Clark Fork",
                "label_ar": "كلارك فورك",
                "label_fr": "Clark Fork"
            },
            {
                "value": 5979,
                "label": "Clark Mills",
                "label_ar": "كلارك ميلز",
                "label_fr": "Clark Mills"
            },
            {
                "value": 5980,
                "label": "Clarkesville",
                "label_ar": "كلاركسفيل",
                "label_fr": "Clarkesville"
            },
            {
                "value": 5981,
                "label": "Clarkfield",
                "label_ar": "كلاركفيلد",
                "label_fr": "Clarkfield"
            },
            {
                "value": 5982,
                "label": "Clarkia",
                "label_ar": "كلاركيا",
                "label_fr": "Clarkia"
            },
            {
                "value": 5983,
                "label": "Clarklake",
                "label_ar": "كلاركليك",
                "label_fr": "Clarklake"
            },
            {
                "value": 5984,
                "label": "Clarkrange",
                "label_ar": "كلاركرينج",
                "label_fr": "Clarkrange"
            },
            {
                "value": 5985,
                "label": "Clarks",
                "label_ar": "كلاركس",
                "label_fr": "Clarks"
            },
            {
                "value": 5986,
                "label": "Clarks Grove",
                "label_ar": "كلاركس جروف",
                "label_fr": "Clarks Grove"
            },
            {
                "value": 5987,
                "label": "Clarks Hill",
                "label_ar": "كلاركس هيل",
                "label_fr": "Clarks Hill"
            },
            {
                "value": 5988,
                "label": "Clarks Summit",
                "label_ar": "قمة كلاركس",
                "label_fr": "Sommet Clarks"
            },
            {
                "value": 5989,
                "label": "Clarksboro",
                "label_ar": "كلاركسبورو",
                "label_fr": "Clarksboro"
            },
            {
                "value": 5990,
                "label": "Clarksburg",
                "label_ar": "كلاركسبيرغ",
                "label_fr": "Clarksburg"
            },
            {
                "value": 5991,
                "label": "Clarksdale",
                "label_ar": "كلاركسدال",
                "label_fr": "Clarksdale"
            },
            {
                "value": 5992,
                "label": "Clarkson",
                "label_ar": "كلاركسون",
                "label_fr": "Clarkson"
            },
            {
                "value": 5993,
                "label": "Clarkston",
                "label_ar": "كلاركستون",
                "label_fr": "Clarkston"
            },
            {
                "value": 5994,
                "label": "Clarksville",
                "label_ar": "كلاركسفيل",
                "label_fr": "Clarksville"
            },
            {
                "value": 5995,
                "label": "Clarkton",
                "label_ar": "كلاركتون",
                "label_fr": "Clarkton"
            },
            {
                "value": 5996,
                "label": "Claryville",
                "label_ar": "كلاريفيل",
                "label_fr": "Claryville"
            },
            {
                "value": 5997,
                "label": "Clatonia",
                "label_ar": "كلاتونيا",
                "label_fr": "Clatonie"
            },
            {
                "value": 5998,
                "label": "Clatskanie",
                "label_ar": "كلاتسكاني",
                "label_fr": "Clatskanie"
            },
            {
                "value": 5999,
                "label": "Claude",
                "label_ar": "كلود",
                "label_fr": "Claude"
            },
            {
                "value": 6000,
                "label": "Claudville",
                "label_ar": "كلودفيل",
                "label_fr": "Claudville"
            },
            {
                "value": 6001,
                "label": "Claverack",
                "label_ar": "كلافيراك",
                "label_fr": "Claverack"
            },
            {
                "value": 6002,
                "label": "Clawson",
                "label_ar": "كلوسون",
                "label_fr": "Clawson"
            },
            {
                "value": 6003,
                "label": "Claxton",
                "label_ar": "كلاكستون",
                "label_fr": "Claxton"
            },
            {
                "value": 6004,
                "label": "Clay",
                "label_ar": "طين",
                "label_fr": "Argile"
            },
            {
                "value": 6005,
                "label": "Clay Center",
                "label_ar": "مركز الطين",
                "label_fr": "Centre d'argile"
            },
            {
                "value": 6006,
                "label": "Clay City",
                "label_ar": "مدينة كلاي",
                "label_fr": "Clay City"
            },
            {
                "value": 6007,
                "label": "Claymont",
                "label_ar": "كليمونت",
                "label_fr": "Claymont"
            },
            {
                "value": 6008,
                "label": "Claypool",
                "label_ar": "كلايبول",
                "label_fr": "Claypool"
            },
            {
                "value": 6009,
                "label": "Claysburg",
                "label_ar": "كلايسبرج",
                "label_fr": "Claysburg"
            },
            {
                "value": 6010,
                "label": "Claysville",
                "label_ar": "كلايسفيل",
                "label_fr": "Claysville"
            },
            {
                "value": 6011,
                "label": "Clayton",
                "label_ar": "كلايتون",
                "label_fr": "Clayton"
            },
            {
                "value": 6012,
                "label": "Cle Elum",
                "label_ar": "كلي إلوم",
                "label_fr": "Cle Elum"
            },
            {
                "value": 6013,
                "label": "Clear Brook",
                "label_ar": "مسح بروك",
                "label_fr": "Clear Brook"
            },
            {
                "value": 6014,
                "label": "Clear Fork",
                "label_ar": "مسح شوكة",
                "label_fr": "Clear Fork"
            },
            {
                "value": 6015,
                "label": "Clear Lake",
                "label_ar": "كلير ليك",
                "label_fr": "Clear Lake"
            },
            {
                "value": 6016,
                "label": "Clear Spring",
                "label_ar": "واضح الربيع",
                "label_fr": "Printemps clair"
            },
            {
                "value": 6017,
                "label": "Clearbrook",
                "label_ar": "كليربروك",
                "label_fr": "Clearbrook"
            },
            {
                "value": 6018,
                "label": "Clearfield",
                "label_ar": "الحقل خالى",
                "label_fr": "Champ libre"
            },
            {
                "value": 6019,
                "label": "Clearlake",
                "label_ar": "كليرليك",
                "label_fr": "Clearlake"
            },
            {
                "value": 6020,
                "label": "Clearlake Oaks",
                "label_ar": "كليرليك أوكس",
                "label_fr": "Clearlake Oaks"
            },
            {
                "value": 6021,
                "label": "Clearmont",
                "label_ar": "كليرمونت",
                "label_fr": "Clearmont"
            },
            {
                "value": 6022,
                "label": "Clearville",
                "label_ar": "كليرفيل",
                "label_fr": "Clearville"
            },
            {
                "value": 6023,
                "label": "Clearwater",
                "label_ar": "ماء نقي",
                "label_fr": "Eau claire"
            },
            {
                "value": 6024,
                "label": "Clearwater Beach",
                "label_ar": "كليرووتر بيتش",
                "label_fr": "Clearwater Beach"
            },
            {
                "value": 6025,
                "label": "Cleburne",
                "label_ar": "كليبرن",
                "label_fr": "Cleburne"
            },
            {
                "value": 6026,
                "label": "Cleghorn",
                "label_ar": "كليجورن",
                "label_fr": "Cleghorn"
            },
            {
                "value": 6027,
                "label": "Clementon",
                "label_ar": "كليمنتون",
                "label_fr": "Clementon"
            },
            {
                "value": 6028,
                "label": "Clements",
                "label_ar": "كليمنتس",
                "label_fr": "Clément"
            },
            {
                "value": 6029,
                "label": "Clemmons",
                "label_ar": "كليمونز",
                "label_fr": "Clemmons"
            },
            {
                "value": 6030,
                "label": "Clemons",
                "label_ar": "كليمونز",
                "label_fr": "Clémons"
            },
            {
                "value": 6031,
                "label": "Clemson",
                "label_ar": "كليمسون",
                "label_fr": "Clemson"
            },
            {
                "value": 6032,
                "label": "Clendenin",
                "label_ar": "كليندينين",
                "label_fr": "Clendénine"
            },
            {
                "value": 6033,
                "label": "Clermont",
                "label_ar": "كليرمونت",
                "label_fr": "Clermont"
            },
            {
                "value": 6034,
                "label": "Cleveland",
                "label_ar": "كليفلاند",
                "label_fr": "Cleveland"
            },
            {
                "value": 6035,
                "label": "Clever",
                "label_ar": "ذكي",
                "label_fr": "Intelligent"
            },
            {
                "value": 6036,
                "label": "Cleves",
                "label_ar": "كليفز",
                "label_fr": "Clèves"
            },
            {
                "value": 6037,
                "label": "Clewiston",
                "label_ar": "كليويستون",
                "label_fr": "Clewiston"
            },
            {
                "value": 6038,
                "label": "Clifford",
                "label_ar": "كليفورد",
                "label_fr": "Clifford"
            },
            {
                "value": 6039,
                "label": "Cliffside Park",
                "label_ar": "كليفسايد بارك",
                "label_fr": "Cliffside Park"
            },
            {
                "value": 6040,
                "label": "Cliffwood",
                "label_ar": "كليفوود",
                "label_fr": "Cliffwood"
            },
            {
                "value": 6041,
                "label": "Clifton",
                "label_ar": "كليفتون",
                "label_fr": "Clifton"
            },
            {
                "value": 6042,
                "label": "Clifton Forge",
                "label_ar": "كليفتون فورج",
                "label_fr": "Clifton Forge"
            },
            {
                "value": 6043,
                "label": "Clifton Heights",
                "label_ar": "كليفتون هايتس",
                "label_fr": "Clifton Heights"
            },
            {
                "value": 6044,
                "label": "Clifton Park",
                "label_ar": "كليفتون بارك",
                "label_fr": "Clifton Park"
            },
            {
                "value": 6045,
                "label": "Clifton Springs",
                "label_ar": "كليفتون سبرينجز",
                "label_fr": "Clifton Springs"
            },
            {
                "value": 6046,
                "label": "Climax",
                "label_ar": "ذروة",
                "label_fr": "Climax"
            },
            {
                "value": 6047,
                "label": "Climax Springs",
                "label_ar": "Climax Springs",
                "label_fr": "Climax Springs"
            },
            {
                "value": 6048,
                "label": "Clinchco",
                "label_ar": "كلينشكو",
                "label_fr": "Clinchco"
            },
            {
                "value": 6049,
                "label": "Clint",
                "label_ar": "كلينت",
                "label_fr": "Clint"
            },
            {
                "value": 6050,
                "label": "Clinton",
                "label_ar": "كلينتون",
                "label_fr": "Clinton"
            },
            {
                "value": 6051,
                "label": "Clinton Corners",
                "label_ar": "كلينتون كورنرز",
                "label_fr": "Clinton Corners"
            },
            {
                "value": 6052,
                "label": "Clintondale",
                "label_ar": "كلينتونديل",
                "label_fr": "Clintondale"
            },
            {
                "value": 6053,
                "label": "Clintonville",
                "label_ar": "كلينتونفيل",
                "label_fr": "Clintonville"
            },
            {
                "value": 6054,
                "label": "Clintwood",
                "label_ar": "كلينتوود",
                "label_fr": "Clintwood"
            },
            {
                "value": 6055,
                "label": "Clio",
                "label_ar": "كليو",
                "label_fr": "Clio"
            },
            {
                "value": 6056,
                "label": "Clive",
                "label_ar": "كلايف",
                "label_fr": "Clive"
            },
            {
                "value": 6057,
                "label": "Clontarf",
                "label_ar": "كلونتارف",
                "label_fr": "Clontarf"
            },
            {
                "value": 6058,
                "label": "Clopton",
                "label_ar": "كلوبتون",
                "label_fr": "Clopton"
            },
            {
                "value": 6059,
                "label": "Cloquet",
                "label_ar": "كلوكيه",
                "label_fr": "Cloquet"
            },
            {
                "value": 6060,
                "label": "Closplint",
                "label_ar": "القرب",
                "label_fr": "Closplint"
            },
            {
                "value": 6061,
                "label": "Closter",
                "label_ar": "كلوستر",
                "label_fr": "Closter"
            },
            {
                "value": 6062,
                "label": "Cloud",
                "label_ar": "غيم",
                "label_fr": "Nuage"
            },
            {
                "value": 6063,
                "label": "Cloudcroft",
                "label_ar": "كلاودكروفت",
                "label_fr": "Cloudcroft"
            },
            {
                "value": 6064,
                "label": "Clover",
                "label_ar": "زهرة البرسيم",
                "label_fr": "Trèfle"
            },
            {
                "value": 6065,
                "label": "Cloverdale",
                "label_ar": "كلوفرديل",
                "label_fr": "Cloverdale"
            },
            {
                "value": 6066,
                "label": "Cloverport",
                "label_ar": "كلوفيربورت",
                "label_fr": "Cloverport"
            },
            {
                "value": 6067,
                "label": "Clovis",
                "label_ar": "كلوفيس",
                "label_fr": "Clovis"
            },
            {
                "value": 6068,
                "label": "Clute",
                "label_ar": "كلوت",
                "label_fr": "Clute"
            },
            {
                "value": 6069,
                "label": "Clyde",
                "label_ar": "كلايد",
                "label_fr": "Clyde"
            },
            {
                "value": 6070,
                "label": "Clyde Park",
                "label_ar": "كلايد بارك",
                "label_fr": "Parc Clyde"
            },
            {
                "value": 6071,
                "label": "Clyman",
                "label_ar": "كلايمان",
                "label_fr": "Clyman"
            },
            {
                "value": 6072,
                "label": "Clymer",
                "label_ar": "كليمر",
                "label_fr": "Clymer"
            },
            {
                "value": 6073,
                "label": "Clyo",
                "label_ar": "كليو",
                "label_fr": "Clyo"
            },
            {
                "value": 6074,
                "label": "Coachella",
                "label_ar": "كوتشيلا",
                "label_fr": "Coachella"
            },
            {
                "value": 6075,
                "label": "Coahoma",
                "label_ar": "كواهوما",
                "label_fr": "Coahoma"
            },
            {
                "value": 6076,
                "label": "Coal Center",
                "label_ar": "مركز الفحم",
                "label_fr": "Centre du charbon"
            },
            {
                "value": 6077,
                "label": "Coal City",
                "label_ar": "مدينة الفحم",
                "label_fr": "Coal City"
            },
            {
                "value": 6078,
                "label": "Coal Hill",
                "label_ar": "تل الفحم",
                "label_fr": "Coal Hill"
            },
            {
                "value": 6079,
                "label": "Coal Mountain",
                "label_ar": "جبل الفحم",
                "label_fr": "Coal Mountain"
            },
            {
                "value": 6080,
                "label": "Coal Township",
                "label_ar": "بلدة الفحم",
                "label_fr": "Canton de Coal"
            },
            {
                "value": 6081,
                "label": "Coal Valley",
                "label_ar": "وادي الفحم",
                "label_fr": "Coal Valley"
            },
            {
                "value": 6082,
                "label": "Coaldale",
                "label_ar": "كوالديل",
                "label_fr": "Coaldale"
            },
            {
                "value": 6083,
                "label": "Coalfield",
                "label_ar": "كولفيلد",
                "label_fr": "Coalfield"
            },
            {
                "value": 6084,
                "label": "Coalgate",
                "label_ar": "كولجيت",
                "label_fr": "Coalgate"
            },
            {
                "value": 6085,
                "label": "Coalinga",
                "label_ar": "كوالينجا",
                "label_fr": "Coalinga"
            },
            {
                "value": 6086,
                "label": "Coalmont",
                "label_ar": "كولمونت",
                "label_fr": "Coalmont"
            },
            {
                "value": 6087,
                "label": "Coalport",
                "label_ar": "كولبورت",
                "label_fr": "Coalport"
            },
            {
                "value": 6088,
                "label": "Coalville",
                "label_ar": "كولفيل",
                "label_fr": "Coalville"
            },
            {
                "value": 6089,
                "label": "Coarsegold",
                "label_ar": "خشن",
                "label_fr": "Gros or"
            },
            {
                "value": 6090,
                "label": "Coatesville",
                "label_ar": "كوتسفيل",
                "label_fr": "Coatesville"
            },
            {
                "value": 6091,
                "label": "Coats",
                "label_ar": "المعاطف",
                "label_fr": "Manteaux"
            },
            {
                "value": 6092,
                "label": "Coatsburg",
                "label_ar": "كوتسبورغ",
                "label_fr": "Coatsburg"
            },
            {
                "value": 6093,
                "label": "Cobalt",
                "label_ar": "كوبالت",
                "label_fr": "Cobalt"
            },
            {
                "value": 6094,
                "label": "Cobb",
                "label_ar": "كوب",
                "label_fr": "Cobb"
            },
            {
                "value": 6095,
                "label": "Cobbs Creek",
                "label_ar": "كوبس كريك",
                "label_fr": "Cobbs Creek"
            },
            {
                "value": 6096,
                "label": "Cobden",
                "label_ar": "كوبدن",
                "label_fr": "Cobden"
            },
            {
                "value": 6097,
                "label": "Cobleskill",
                "label_ar": "كوبليسكيل",
                "label_fr": "Cobleskill"
            },
            {
                "value": 6098,
                "label": "Coburn",
                "label_ar": "كوبيرن",
                "label_fr": "Coburn"
            },
            {
                "value": 6099,
                "label": "Cochecton",
                "label_ar": "كوشيكتون",
                "label_fr": "Cochecton"
            },
            {
                "value": 6100,
                "label": "Cochise",
                "label_ar": "كوتشيس",
                "label_fr": "Cochise"
            },
            {
                "value": 6101,
                "label": "Cochiti Lake",
                "label_ar": "بحيرة Cochiti",
                "label_fr": "Lac Cochiti"
            },
            {
                "value": 6102,
                "label": "Cochiti Pueblo",
                "label_ar": "Cochiti Pueblo",
                "label_fr": "Cochiti Pueblo"
            },
            {
                "value": 6103,
                "label": "Cochran",
                "label_ar": "كوكران",
                "label_fr": "Cochran"
            },
            {
                "value": 6104,
                "label": "Cochrane",
                "label_ar": "كوكرين",
                "label_fr": "Cochrane"
            },
            {
                "value": 6105,
                "label": "Cochranton",
                "label_ar": "كوكرانتون",
                "label_fr": "Cochranton"
            },
            {
                "value": 6106,
                "label": "Cochranville",
                "label_ar": "كوكرانفيل",
                "label_fr": "Cochranville"
            },
            {
                "value": 6107,
                "label": "Cockeysville",
                "label_ar": "كوكيزفيل",
                "label_fr": "Cockeysville"
            },
            {
                "value": 6108,
                "label": "Cocoa",
                "label_ar": "كاكاو",
                "label_fr": "Cacao"
            },
            {
                "value": 6109,
                "label": "Cocoa Beach",
                "label_ar": "شاطئ الكاكاو",
                "label_fr": "Cocoa Beach"
            },
            {
                "value": 6110,
                "label": "Cocolamus",
                "label_ar": "كوكولاموس",
                "label_fr": "Cocolamus"
            },
            {
                "value": 6111,
                "label": "Coden",
                "label_ar": "كودن",
                "label_fr": "Coden"
            },
            {
                "value": 6112,
                "label": "Cody",
                "label_ar": "كودي",
                "label_fr": "Cody"
            },
            {
                "value": 6113,
                "label": "Coeburn",
                "label_ar": "كويبورن",
                "label_fr": "Coeburn"
            },
            {
                "value": 6114,
                "label": "Coeur d'Alene",
                "label_ar": "كور دي أليني",
                "label_fr": "Coeur d'Alene"
            },
            {
                "value": 6115,
                "label": "Coeymans",
                "label_ar": "كويمانز",
                "label_fr": "Coeymans"
            },
            {
                "value": 6116,
                "label": "Coeymans Hollow",
                "label_ar": "كويمانز هولو",
                "label_fr": "Coeymans Hollow"
            },
            {
                "value": 6117,
                "label": "Coffee Springs",
                "label_ar": "ينابيع القهوة",
                "label_fr": "Coffee Springs"
            },
            {
                "value": 6118,
                "label": "Coffeen",
                "label_ar": "نعش",
                "label_fr": "Café"
            },
            {
                "value": 6119,
                "label": "Coffeeville",
                "label_ar": "كوفيفيل",
                "label_fr": "Coffeeville"
            },
            {
                "value": 6120,
                "label": "Coffey",
                "label_ar": "كوفي",
                "label_fr": "Coffey"
            },
            {
                "value": 6121,
                "label": "Coffeyville",
                "label_ar": "كوفيفيل",
                "label_fr": "Coffeyville"
            },
            {
                "value": 6122,
                "label": "Coffman Cove",
                "label_ar": "كوفمان كوف",
                "label_fr": "Coffman Cove"
            },
            {
                "value": 6123,
                "label": "Coggon",
                "label_ar": "كوجون",
                "label_fr": "Coggon"
            },
            {
                "value": 6124,
                "label": "Cogswell",
                "label_ar": "كوجسويل",
                "label_fr": "Cogswell"
            },
            {
                "value": 6125,
                "label": "Cohasset",
                "label_ar": "كوهاسيت",
                "label_fr": "Cohasset"
            },
            {
                "value": 6126,
                "label": "Cohocton",
                "label_ar": "Cohocton",
                "label_fr": "Cohocton"
            },
            {
                "value": 6127,
                "label": "Cohoes",
                "label_ar": "كوهوز",
                "label_fr": "Cohoes"
            },
            {
                "value": 6128,
                "label": "Cohutta",
                "label_ar": "كوهوتا",
                "label_fr": "Cohutta"
            },
            {
                "value": 6129,
                "label": "Coin",
                "label_ar": "عملة",
                "label_fr": "Pièce de monnaie"
            },
            {
                "value": 6130,
                "label": "Coinjock",
                "label_ar": "كوينجوك",
                "label_fr": "Coinjock"
            },
            {
                "value": 6131,
                "label": "Cokato",
                "label_ar": "كوكاتو",
                "label_fr": "Cokato"
            },
            {
                "value": 6132,
                "label": "Cokeburg",
                "label_ar": "كوكبيرج",
                "label_fr": "Cokeburg"
            },
            {
                "value": 6133,
                "label": "Coker",
                "label_ar": "كوكر",
                "label_fr": "Coker"
            },
            {
                "value": 6134,
                "label": "Cokeville",
                "label_ar": "كوكفيل",
                "label_fr": "Cokeville"
            },
            {
                "value": 6135,
                "label": "Colbert",
                "label_ar": "كولبير",
                "label_fr": "Colbert"
            },
            {
                "value": 6136,
                "label": "Colby",
                "label_ar": "كولبي",
                "label_fr": "Colby"
            },
            {
                "value": 6137,
                "label": "Colchester",
                "label_ar": "كولشيستر",
                "label_fr": "Colchester"
            },
            {
                "value": 6138,
                "label": "Colcord",
                "label_ar": "كولكورد",
                "label_fr": "Colcord"
            },
            {
                "value": 6139,
                "label": "Cold Brook",
                "label_ar": "كولد بروك",
                "label_fr": "Cold Brook"
            },
            {
                "value": 6140,
                "label": "Cold Spring",
                "label_ar": "الربيع البارد",
                "label_fr": "Printemps froid"
            },
            {
                "value": 6141,
                "label": "Cold Spring Harbor",
                "label_ar": "كولد سبرينج هاربور",
                "label_fr": "Cold Spring Harbor"
            },
            {
                "value": 6142,
                "label": "Cold Springs",
                "label_ar": "كولد سبرينغز",
                "label_fr": "Cold Springs"
            },
            {
                "value": 6143,
                "label": "Colden",
                "label_ar": "كولدن",
                "label_fr": "Colden"
            },
            {
                "value": 6144,
                "label": "Coldspring",
                "label_ar": "الربيع البارد",
                "label_fr": "Coldspring"
            },
            {
                "value": 6145,
                "label": "Coldwater",
                "label_ar": "ماء بارد",
                "label_fr": "Eau froide"
            },
            {
                "value": 6146,
                "label": "Cole Camp",
                "label_ar": "كول كامب",
                "label_fr": "Camp Cole"
            },
            {
                "value": 6147,
                "label": "Colebrook",
                "label_ar": "كولبروك",
                "label_fr": "Colebrook"
            },
            {
                "value": 6148,
                "label": "Coleman",
                "label_ar": "كولمان",
                "label_fr": "Coleman"
            },
            {
                "value": 6149,
                "label": "Colerain",
                "label_ar": "كوليرين",
                "label_fr": "Colerain"
            },
            {
                "value": 6150,
                "label": "Coleraine",
                "label_ar": "كوليرين",
                "label_fr": "Coleraine"
            },
            {
                "value": 6151,
                "label": "Coleridge",
                "label_ar": "كوليردج",
                "label_fr": "Coleridge"
            },
            {
                "value": 6152,
                "label": "Colesburg",
                "label_ar": "كولسبورغ",
                "label_fr": "Colesburg"
            },
            {
                "value": 6153,
                "label": "Coleville",
                "label_ar": "كوليفيل",
                "label_fr": "Coleville"
            },
            {
                "value": 6154,
                "label": "Colfax",
                "label_ar": "كولفاكس",
                "label_fr": "Colfax"
            },
            {
                "value": 6155,
                "label": "Colgate",
                "label_ar": "كولجيت",
                "label_fr": "Colgate"
            },
            {
                "value": 6156,
                "label": "College Corner",
                "label_ar": "ركن الكلية",
                "label_fr": "Coin universitaire"
            },
            {
                "value": 6157,
                "label": "College Grove",
                "label_ar": "كلية جروف",
                "label_fr": "College Grove"
            },
            {
                "value": 6158,
                "label": "College Park",
                "label_ar": "كوليدج بارك",
                "label_fr": "College Park"
            },
            {
                "value": 6159,
                "label": "College Place",
                "label_ar": "مكان الكلية",
                "label_fr": "Place du Collège"
            },
            {
                "value": 6160,
                "label": "College Point",
                "label_ar": "كوليدج بوينت",
                "label_fr": "College Point"
            },
            {
                "value": 6161,
                "label": "College Station",
                "label_ar": "محطة الكلية",
                "label_fr": "College Station"
            },
            {
                "value": 6162,
                "label": "Collegedale",
                "label_ar": "كوليجيدال",
                "label_fr": "Collegedale"
            },
            {
                "value": 6163,
                "label": "Collegeport",
                "label_ar": "كوليجبورت",
                "label_fr": "Collegeport"
            },
            {
                "value": 6164,
                "label": "Collegeville",
                "label_ar": "كوليجفيل",
                "label_fr": "Collegeville"
            },
            {
                "value": 6165,
                "label": "Colleyville",
                "label_ar": "كوليفيل",
                "label_fr": "Colleyville"
            },
            {
                "value": 6166,
                "label": "Colliers",
                "label_ar": "كوليرز",
                "label_fr": "Colliers"
            },
            {
                "value": 6167,
                "label": "Collierville",
                "label_ar": "كوليرفيل",
                "label_fr": "Collierville"
            },
            {
                "value": 6168,
                "label": "Collingswood",
                "label_ar": "كولينجسوود",
                "label_fr": "Collingswood"
            },
            {
                "value": 6169,
                "label": "Collins",
                "label_ar": "كولينز",
                "label_fr": "Collins"
            },
            {
                "value": 6170,
                "label": "Collinston",
                "label_ar": "كولينستون",
                "label_fr": "Collinston"
            },
            {
                "value": 6171,
                "label": "Collinsville",
                "label_ar": "كولينزفيل",
                "label_fr": "Collinsville"
            },
            {
                "value": 6172,
                "label": "Collinwood",
                "label_ar": "كولينوود",
                "label_fr": "Collinwood"
            },
            {
                "value": 6173,
                "label": "Collison",
                "label_ar": "كوليسون",
                "label_fr": "Collison"
            },
            {
                "value": 6174,
                "label": "Colman",
                "label_ar": "كولمان",
                "label_fr": "Colman"
            },
            {
                "value": 6175,
                "label": "Colmar",
                "label_ar": "كولمار",
                "label_fr": "Colmar"
            },
            {
                "value": 6176,
                "label": "Colmesneil",
                "label_ar": "كولميسنيل",
                "label_fr": "Colmesneil"
            },
            {
                "value": 6177,
                "label": "Colo",
                "label_ar": "كولو",
                "label_fr": "Colo"
            },
            {
                "value": 6178,
                "label": "Cologne",
                "label_ar": "كولونيا",
                "label_fr": "Eau de Cologne"
            },
            {
                "value": 6179,
                "label": "Coloma",
                "label_ar": "كولوما",
                "label_fr": "Coloma"
            },
            {
                "value": 6180,
                "label": "Colon",
                "label_ar": "القولون",
                "label_fr": "Côlon"
            },
            {
                "value": 6181,
                "label": "Colona",
                "label_ar": "كولونا",
                "label_fr": "Colona"
            },
            {
                "value": 6182,
                "label": "Colonia",
                "label_ar": "كولونيا",
                "label_fr": "Colonia"
            },
            {
                "value": 6183,
                "label": "Colonial Beach",
                "label_ar": "كولونيال بيتش",
                "label_fr": "Plage coloniale"
            },
            {
                "value": 6184,
                "label": "Colonial Heights",
                "label_ar": "مرتفعات المستعمرة",
                "label_fr": "Colonial Heights"
            },
            {
                "value": 6185,
                "label": "Colony",
                "label_ar": "مستعمرة",
                "label_fr": "Colonie"
            },
            {
                "value": 6186,
                "label": "Colora",
                "label_ar": "كولورا",
                "label_fr": "Colora"
            },
            {
                "value": 6187,
                "label": "Colorado City",
                "label_ar": "كولورادو سيتي",
                "label_fr": "Colorado City"
            },
            {
                "value": 6188,
                "label": "Colorado Springs",
                "label_ar": "كولورادو سبرينغز",
                "label_fr": "Colorado Springs"
            },
            {
                "value": 6189,
                "label": "Colquitt",
                "label_ar": "كولكيت",
                "label_fr": "Colquitt"
            },
            {
                "value": 6190,
                "label": "Colstrip",
                "label_ar": "كولستريب",
                "label_fr": "Colstrip"
            },
            {
                "value": 6191,
                "label": "Colt",
                "label_ar": "مسدس",
                "label_fr": "Poulain"
            },
            {
                "value": 6192,
                "label": "Colton",
                "label_ar": "كولتون",
                "label_fr": "Colton"
            },
            {
                "value": 6193,
                "label": "Colts Neck",
                "label_ar": "عنق المهور",
                "label_fr": "Colts cou"
            },
            {
                "value": 6194,
                "label": "Columbia",
                "label_ar": "كولومبيا",
                "label_fr": "Columbia"
            },
            {
                "value": 6195,
                "label": "Columbia City",
                "label_ar": "مدينة كولومبيا",
                "label_fr": "Columbia City"
            },
            {
                "value": 6196,
                "label": "Columbia Cross Roads",
                "label_ar": "طرق كولومبيا كروس",
                "label_fr": "Columbia Cross Roads"
            },
            {
                "value": 6197,
                "label": "Columbia Falls",
                "label_ar": "شلالات كولومبيا",
                "label_fr": "Columbia Falls"
            },
            {
                "value": 6198,
                "label": "Columbia Station",
                "label_ar": "محطة كولومبيا",
                "label_fr": "Gare de Columbia"
            },
            {
                "value": 6199,
                "label": "Columbiana",
                "label_ar": "كولومبيانا",
                "label_fr": "Columbiana"
            },
            {
                "value": 6200,
                "label": "Columbiaville",
                "label_ar": "كولومبيافيل",
                "label_fr": "Columbiaville"
            },
            {
                "value": 6201,
                "label": "Columbus",
                "label_ar": "كولومبوس",
                "label_fr": "Colomb"
            },
            {
                "value": 6202,
                "label": "Columbus Grove",
                "label_ar": "كولومبوس جروف",
                "label_fr": "Columbus Grove"
            },
            {
                "value": 6203,
                "label": "Columbus Junction",
                "label_ar": "تقاطع كولومبوس",
                "label_fr": "Columbus Junction"
            },
            {
                "value": 6204,
                "label": "Colusa",
                "label_ar": "كولوسا",
                "label_fr": "Colusa"
            },
            {
                "value": 6205,
                "label": "Colver",
                "label_ar": "كولفر",
                "label_fr": "Colver"
            },
            {
                "value": 6206,
                "label": "Colville",
                "label_ar": "كولفيل",
                "label_fr": "Colville"
            },
            {
                "value": 6207,
                "label": "Colwich",
                "label_ar": "كولويتش",
                "label_fr": "Colwich"
            },
            {
                "value": 6208,
                "label": "Comanche",
                "label_ar": "كومانتش",
                "label_fr": "Comanche"
            },
            {
                "value": 6209,
                "label": "Combined Locks",
                "label_ar": "أقفال مدمجة",
                "label_fr": "Serrures combinées"
            },
            {
                "value": 6210,
                "label": "Combs",
                "label_ar": "أمشاط",
                "label_fr": "Peignes"
            },
            {
                "value": 6211,
                "label": "Comer",
                "label_ar": "قادم",
                "label_fr": "Arrivant"
            },
            {
                "value": 6212,
                "label": "Comfort",
                "label_ar": "راحة",
                "label_fr": "Confort"
            },
            {
                "value": 6213,
                "label": "Comfrey",
                "label_ar": "كومفري",
                "label_fr": "Consoude"
            },
            {
                "value": 6214,
                "label": "Commack",
                "label_ar": "كوماك",
                "label_fr": "Commack"
            },
            {
                "value": 6215,
                "label": "Commerce",
                "label_ar": "التجارة",
                "label_fr": "Commerce"
            },
            {
                "value": 6216,
                "label": "Commerce City",
                "label_ar": "مدينة التجارة",
                "label_fr": "Commerce City"
            },
            {
                "value": 6217,
                "label": "Commercial Point",
                "label_ar": "نقطة تجارية",
                "label_fr": "Point commercial"
            },
            {
                "value": 6218,
                "label": "Commiskey",
                "label_ar": "كوميسكي",
                "label_fr": "Commiskey"
            },
            {
                "value": 6219,
                "label": "Como",
                "label_ar": "كومو",
                "label_fr": "Côme"
            },
            {
                "value": 6220,
                "label": "Comptche",
                "label_ar": "كومبتشي",
                "label_fr": "Comptche"
            },
            {
                "value": 6221,
                "label": "Compton",
                "label_ar": "كومبتون",
                "label_fr": "Compton"
            },
            {
                "value": 6222,
                "label": "Comstock",
                "label_ar": "كومستوك",
                "label_fr": "Comstock"
            },
            {
                "value": 6223,
                "label": "Comstock Park",
                "label_ar": "كومستوك بارك",
                "label_fr": "Parc Comstock"
            },
            {
                "value": 6224,
                "label": "Conception Junction",
                "label_ar": "مفرق الحمل",
                "label_fr": "Conception Junction"
            },
            {
                "value": 6225,
                "label": "Concho",
                "label_ar": "كونشو",
                "label_fr": "Concho"
            },
            {
                "value": 6226,
                "label": "Concord",
                "label_ar": "كونكورد",
                "label_fr": "Concorde"
            },
            {
                "value": 6227,
                "label": "Concordia",
                "label_ar": "كونكورديا",
                "label_fr": "Concordia"
            },
            {
                "value": 6228,
                "label": "Concordville",
                "label_ar": "كونكوردفيل",
                "label_fr": "Concordville"
            },
            {
                "value": 6229,
                "label": "Concrete",
                "label_ar": "الخرسانة",
                "label_fr": "Béton"
            },
            {
                "value": 6230,
                "label": "Condon",
                "label_ar": "كوندون",
                "label_fr": "Condon"
            },
            {
                "value": 6231,
                "label": "Conestoga",
                "label_ar": "كونستوجا",
                "label_fr": "Conestoga"
            },
            {
                "value": 6232,
                "label": "Conesus",
                "label_ar": "المخاريط",
                "label_fr": "Cône"
            },
            {
                "value": 6233,
                "label": "Conesville",
                "label_ar": "كونيسفيل",
                "label_fr": "Conesville"
            },
            {
                "value": 6234,
                "label": "Confluence",
                "label_ar": "التقاء نهرين",
                "label_fr": "Confluence"
            },
            {
                "value": 6235,
                "label": "Conger",
                "label_ar": "سلور",
                "label_fr": "Congre"
            },
            {
                "value": 6236,
                "label": "Congers",
                "label_ar": "كونجرز",
                "label_fr": "Congres"
            },
            {
                "value": 6237,
                "label": "Congerville",
                "label_ar": "كونجيرفيل",
                "label_fr": "Congerville"
            },
            {
                "value": 6238,
                "label": "Congress",
                "label_ar": "الكونجرس",
                "label_fr": "Congrès"
            },
            {
                "value": 6239,
                "label": "Conifer",
                "label_ar": "صنوبر",
                "label_fr": "Conifère"
            },
            {
                "value": 6240,
                "label": "Conklin",
                "label_ar": "كونكلين",
                "label_fr": "Conklin"
            },
            {
                "value": 6241,
                "label": "Conley",
                "label_ar": "كونلي",
                "label_fr": "Conley"
            },
            {
                "value": 6242,
                "label": "Conneaut",
                "label_ar": "كونيوت",
                "label_fr": "Conneaut"
            },
            {
                "value": 6243,
                "label": "Conneaut Lake",
                "label_ar": "كونيوت ليك",
                "label_fr": "Lac Conneaut"
            },
            {
                "value": 6244,
                "label": "Conneautville",
                "label_ar": "كونيوتفيل",
                "label_fr": "Conneautville"
            },
            {
                "value": 6245,
                "label": "Connell",
                "label_ar": "كونيل",
                "label_fr": "Connell"
            },
            {
                "value": 6246,
                "label": "Connellsville",
                "label_ar": "كونيلسفيل",
                "label_fr": "Connellsville"
            },
            {
                "value": 6247,
                "label": "Connelly Springs",
                "label_ar": "كونيلي سبرينغز",
                "label_fr": "Connelly Springs"
            },
            {
                "value": 6248,
                "label": "Connersville",
                "label_ar": "كونرسفيل",
                "label_fr": "Connersville"
            },
            {
                "value": 6249,
                "label": "Conover",
                "label_ar": "كونوفر",
                "label_fr": "Conover"
            },
            {
                "value": 6250,
                "label": "Conowingo",
                "label_ar": "كونوينغو",
                "label_fr": "Conowingo"
            },
            {
                "value": 6251,
                "label": "Conrad",
                "label_ar": "كونراد",
                "label_fr": "Conrad"
            },
            {
                "value": 6252,
                "label": "Conrath",
                "label_ar": "كونراث",
                "label_fr": "Conrath"
            },
            {
                "value": 6253,
                "label": "Conroe",
                "label_ar": "كونرو",
                "label_fr": "Conroe"
            },
            {
                "value": 6254,
                "label": "Conroy",
                "label_ar": "كونروي",
                "label_fr": "Conroy"
            },
            {
                "value": 6255,
                "label": "Conshohocken",
                "label_ar": "كونشوهوكين",
                "label_fr": "Conshohocken"
            },
            {
                "value": 6256,
                "label": "Constableville",
                "label_ar": "كونستابلفيل",
                "label_fr": "Constableville"
            },
            {
                "value": 6257,
                "label": "Constantia",
                "label_ar": "كونستانتيا",
                "label_fr": "Constantia"
            },
            {
                "value": 6258,
                "label": "Constantine",
                "label_ar": "قسنطينة",
                "label_fr": "Constantine"
            },
            {
                "value": 6259,
                "label": "Contact",
                "label_ar": "اتصل",
                "label_fr": "Contact"
            },
            {
                "value": 6260,
                "label": "Content",
                "label_ar": "المحتوى",
                "label_fr": "Contenu"
            },
            {
                "value": 6261,
                "label": "Continental",
                "label_ar": "كونتيننتال",
                "label_fr": "Continental"
            },
            {
                "value": 6262,
                "label": "Contoocook",
                "label_ar": "Contoocook",
                "label_fr": "Contoocook"
            },
            {
                "value": 6263,
                "label": "Convent",
                "label_ar": "دير",
                "label_fr": "Couvent"
            },
            {
                "value": 6264,
                "label": "Converse",
                "label_ar": "الحديث",
                "label_fr": "Converser"
            },
            {
                "value": 6265,
                "label": "Convoy",
                "label_ar": "قافلة",
                "label_fr": "Convoi"
            },
            {
                "value": 6266,
                "label": "Conway",
                "label_ar": "كونواي",
                "label_fr": "Conway"
            },
            {
                "value": 6267,
                "label": "Conway Springs",
                "label_ar": "كونواي سبرينغز",
                "label_fr": "Conway Springs"
            },
            {
                "value": 6268,
                "label": "Conyers",
                "label_ar": "كونيرز",
                "label_fr": "Conyers"
            },
            {
                "value": 6269,
                "label": "Conyngham",
                "label_ar": "كونينجهام",
                "label_fr": "Conyngham"
            },
            {
                "value": 6270,
                "label": "Cook",
                "label_ar": "يطبخ",
                "label_fr": "cuisinier"
            },
            {
                "value": 6271,
                "label": "Cookeville",
                "label_ar": "كوكفيل",
                "label_fr": "Cookeville"
            },
            {
                "value": 6272,
                "label": "Cookstown",
                "label_ar": "كوكستاون",
                "label_fr": "Cookstown"
            },
            {
                "value": 6273,
                "label": "Cooksville",
                "label_ar": "كوكسفيل",
                "label_fr": "Cooksville"
            },
            {
                "value": 6274,
                "label": "Coolidge",
                "label_ar": "كوليدج",
                "label_fr": "Coolidge"
            },
            {
                "value": 6275,
                "label": "Coolin",
                "label_ar": "كولين",
                "label_fr": "Coolin"
            },
            {
                "value": 6276,
                "label": "Coolville",
                "label_ar": "كولفيل",
                "label_fr": "Coolville"
            },
            {
                "value": 6277,
                "label": "Coon Rapids",
                "label_ar": "كون رابيدز",
                "label_fr": "Coon Rapids"
            },
            {
                "value": 6278,
                "label": "Coon Valley",
                "label_ar": "وادي كون",
                "label_fr": "Vallée de Coon"
            },
            {
                "value": 6279,
                "label": "Cooper",
                "label_ar": "كوبر",
                "label_fr": "Tonnelier"
            },
            {
                "value": 6280,
                "label": "Cooper Landing",
                "label_ar": "كوبر لاندينج",
                "label_fr": "Cooper Landing"
            },
            {
                "value": 6281,
                "label": "Coopers Plains",
                "label_ar": "كوبرز بلينز",
                "label_fr": "Coopers Plains"
            },
            {
                "value": 6282,
                "label": "Coopersburg",
                "label_ar": "كوبرسبيرج",
                "label_fr": "Coopersburg"
            },
            {
                "value": 6283,
                "label": "Cooperstown",
                "label_ar": "كوبرستاون",
                "label_fr": "Cooperstown"
            },
            {
                "value": 6284,
                "label": "Coopersville",
                "label_ar": "كوبرسفيل",
                "label_fr": "Coopersville"
            },
            {
                "value": 6285,
                "label": "Coos Bay",
                "label_ar": "خليج كوس",
                "label_fr": "Coos Bay"
            },
            {
                "value": 6286,
                "label": "Copake",
                "label_ar": "Copake",
                "label_fr": "Copake"
            },
            {
                "value": 6287,
                "label": "Copake Falls",
                "label_ar": "شلالات Copake",
                "label_fr": "Copake Falls"
            },
            {
                "value": 6288,
                "label": "Copalis Beach",
                "label_ar": "شاطئ كوباليس",
                "label_fr": "Plage de Copalis"
            },
            {
                "value": 6289,
                "label": "Copan",
                "label_ar": "كوبان",
                "label_fr": "Copan"
            },
            {
                "value": 6290,
                "label": "Cope",
                "label_ar": "التأقلم",
                "label_fr": "Chape"
            },
            {
                "value": 6291,
                "label": "Copeland",
                "label_ar": "كوبلاند",
                "label_fr": "Copeland"
            },
            {
                "value": 6292,
                "label": "Copenhagen",
                "label_ar": "كوبنهاغن",
                "label_fr": "Copenhague"
            },
            {
                "value": 6293,
                "label": "Copeville",
                "label_ar": "كوبفيل",
                "label_fr": "Copeville"
            },
            {
                "value": 6294,
                "label": "Copiague",
                "label_ar": "Copiague",
                "label_fr": "Copiague"
            },
            {
                "value": 6295,
                "label": "Coplay",
                "label_ar": "كوبلاي",
                "label_fr": "Coplay"
            },
            {
                "value": 6296,
                "label": "Coppell",
                "label_ar": "كوبيل",
                "label_fr": "Coppell"
            },
            {
                "value": 6297,
                "label": "Copper Center",
                "label_ar": "مركز النحاس",
                "label_fr": "Centre du cuivre"
            },
            {
                "value": 6298,
                "label": "Copper Harbor",
                "label_ar": "كوبر هاربور",
                "label_fr": "Copper Harbour"
            },
            {
                "value": 6299,
                "label": "Copperas Cove",
                "label_ar": "كوبراس كوف",
                "label_fr": "Copperas Cove"
            },
            {
                "value": 6300,
                "label": "Copperhill",
                "label_ar": "كوبرهيل",
                "label_fr": "Copperhill"
            },
            {
                "value": 6301,
                "label": "Copperopolis",
                "label_ar": "كوبروبوليس",
                "label_fr": "Copperopolis"
            },
            {
                "value": 6302,
                "label": "Coquille",
                "label_ar": "كوكيل",
                "label_fr": "Coquille"
            },
            {
                "value": 6303,
                "label": "Coral",
                "label_ar": "المرجان",
                "label_fr": "corail"
            },
            {
                "value": 6304,
                "label": "Coral Gables",
                "label_ar": "حائط مرجان",
                "label_fr": "Coral Gables"
            },
            {
                "value": 6305,
                "label": "Coralville",
                "label_ar": "كورالفيل",
                "label_fr": "Coralville"
            },
            {
                "value": 6306,
                "label": "Coram",
                "label_ar": "كورام",
                "label_fr": "Coram"
            },
            {
                "value": 6307,
                "label": "Coraopolis",
                "label_ar": "كوراوبوليس",
                "label_fr": "Coraopolis"
            },
            {
                "value": 6308,
                "label": "Corbett",
                "label_ar": "كوربيت",
                "label_fr": "Corbett"
            },
            {
                "value": 6309,
                "label": "Corbin",
                "label_ar": "كوربين",
                "label_fr": "Corbin"
            },
            {
                "value": 6310,
                "label": "Corcoran",
                "label_ar": "كوركوران",
                "label_fr": "Corcoran"
            },
            {
                "value": 6311,
                "label": "Cordele",
                "label_ar": "كورديل",
                "label_fr": "Cordele"
            },
            {
                "value": 6312,
                "label": "Cordell",
                "label_ar": "كورديل",
                "label_fr": "Cordell"
            },
            {
                "value": 6313,
                "label": "Cordesville",
                "label_ar": "كوردسفيل",
                "label_fr": "Cordesville"
            },
            {
                "value": 6314,
                "label": "Cordova",
                "label_ar": "كوردوفا",
                "label_fr": "Cordoue"
            },
            {
                "value": 6315,
                "label": "Corfu",
                "label_ar": "كورفو",
                "label_fr": "Corfou"
            },
            {
                "value": 6316,
                "label": "Corinna",
                "label_ar": "كورينا",
                "label_fr": "Corinna"
            },
            {
                "value": 6317,
                "label": "Corinne",
                "label_ar": "كورين",
                "label_fr": "Corinne"
            },
            {
                "value": 6318,
                "label": "Corinth",
                "label_ar": "كورنثوس",
                "label_fr": "Corinthe"
            },
            {
                "value": 6319,
                "label": "Corn",
                "label_ar": "حبوب ذرة",
                "label_fr": "Blé"
            },
            {
                "value": 6320,
                "label": "Cornelia",
                "label_ar": "كورنيليا",
                "label_fr": "Cornelia"
            },
            {
                "value": 6321,
                "label": "Cornelius",
                "label_ar": "كورنيليوس",
                "label_fr": "Cornélius"
            },
            {
                "value": 6322,
                "label": "Cornell",
                "label_ar": "كورنيل",
                "label_fr": "Cornell"
            },
            {
                "value": 6323,
                "label": "Cornersville",
                "label_ar": "كورنرسفيل",
                "label_fr": "Cornersville"
            },
            {
                "value": 6324,
                "label": "Corning",
                "label_ar": "كورنينج",
                "label_fr": "Corning"
            },
            {
                "value": 6325,
                "label": "Cornish",
                "label_ar": "كورنيش",
                "label_fr": "cornouaillais"
            },
            {
                "value": 6326,
                "label": "Cornish Flat",
                "label_ar": "شقة الكورنيش",
                "label_fr": "Plat de Cornouailles"
            },
            {
                "value": 6327,
                "label": "Cornville",
                "label_ar": "كورنفيل",
                "label_fr": "Cornville"
            },
            {
                "value": 6328,
                "label": "Cornwall",
                "label_ar": "كورنوال",
                "label_fr": "Cornouailles"
            },
            {
                "value": 6329,
                "label": "Cornwall Bridge",
                "label_ar": "جسر كورنوال",
                "label_fr": "Pont de Cornwall"
            },
            {
                "value": 6330,
                "label": "Cornwall-on-Hudson",
                "label_ar": "كورنوال أون هدسون",
                "label_fr": "Cornwall-sur-Hudson"
            },
            {
                "value": 6331,
                "label": "Cornwallville",
                "label_ar": "كورنوالفيل",
                "label_fr": "Cornwallville"
            },
            {
                "value": 6332,
                "label": "Corolla",
                "label_ar": "كورولا",
                "label_fr": "Corolle"
            },
            {
                "value": 6333,
                "label": "Corona",
                "label_ar": "كورونا",
                "label_fr": "Couronne"
            },
            {
                "value": 6334,
                "label": "Corona del Mar",
                "label_ar": "كورونا ديل مار",
                "label_fr": "Corona del Mar"
            },
            {
                "value": 6335,
                "label": "Coronado",
                "label_ar": "كورونادو",
                "label_fr": "Coronado"
            },
            {
                "value": 6336,
                "label": "Corpus Christi",
                "label_ar": "عيد القربان",
                "label_fr": "corpus Christi"
            },
            {
                "value": 6337,
                "label": "Corrales",
                "label_ar": "كوراليس",
                "label_fr": "Corrales"
            },
            {
                "value": 6338,
                "label": "Correctionville",
                "label_ar": "كوريكشنفيل",
                "label_fr": "Correctionville"
            },
            {
                "value": 6339,
                "label": "Corrigan",
                "label_ar": "كوريجان",
                "label_fr": "Corrigan"
            },
            {
                "value": 6340,
                "label": "Corry",
                "label_ar": "كوري",
                "label_fr": "Corry"
            },
            {
                "value": 6341,
                "label": "Corryton",
                "label_ar": "كوريتون",
                "label_fr": "Corryton"
            },
            {
                "value": 6342,
                "label": "Corsica",
                "label_ar": "كورسيكا",
                "label_fr": "la Corse"
            },
            {
                "value": 6343,
                "label": "Corsicana",
                "label_ar": "كورسيكانا",
                "label_fr": "Corsicana"
            },
            {
                "value": 6344,
                "label": "Cortaro",
                "label_ar": "كورتارو",
                "label_fr": "Cortaro"
            },
            {
                "value": 6345,
                "label": "Corte Madera",
                "label_ar": "كورت ماديرا",
                "label_fr": "Corte Madera"
            },
            {
                "value": 6346,
                "label": "Cortez",
                "label_ar": "كورتيز",
                "label_fr": "Cortez"
            },
            {
                "value": 6347,
                "label": "Cortland",
                "label_ar": "كورتلاند",
                "label_fr": "Cortland"
            },
            {
                "value": 6348,
                "label": "Corunna",
                "label_ar": "كورونا",
                "label_fr": "La Corogne"
            },
            {
                "value": 6349,
                "label": "Corvallis",
                "label_ar": "كورفاليس",
                "label_fr": "Corvallis"
            },
            {
                "value": 6350,
                "label": "Corwith",
                "label_ar": "كورويث",
                "label_fr": "Corwith"
            },
            {
                "value": 6351,
                "label": "Cory",
                "label_ar": "كوري",
                "label_fr": "Cory"
            },
            {
                "value": 6352,
                "label": "Corydon",
                "label_ar": "كوريدون",
                "label_fr": "Corydon"
            },
            {
                "value": 6353,
                "label": "Coryell's Ferry",
                "label_ar": "فيري كوريل",
                "label_fr": "Le ferry de Coryell"
            },
            {
                "value": 6354,
                "label": "Cos Cob",
                "label_ar": "كوس كوب",
                "label_fr": "Cos Cob"
            },
            {
                "value": 6355,
                "label": "Cosby",
                "label_ar": "كوسبي",
                "label_fr": "Cosby"
            },
            {
                "value": 6356,
                "label": "Coshocton",
                "label_ar": "كوشوكتون",
                "label_fr": "Coshocton"
            },
            {
                "value": 6357,
                "label": "Cosmopolis",
                "label_ar": "كوزموبوليس",
                "label_fr": "Cosmopolis"
            },
            {
                "value": 6358,
                "label": "Cosmos",
                "label_ar": "كوزموس",
                "label_fr": "Cosmos"
            },
            {
                "value": 6359,
                "label": "Costa Mesa",
                "label_ar": "كوستا ميسا",
                "label_fr": "Costa Mesa"
            },
            {
                "value": 6360,
                "label": "Cotati",
                "label_ar": "كوتاتي",
                "label_fr": "Cotati"
            },
            {
                "value": 6361,
                "label": "Cottage Grove",
                "label_ar": "كوخ غروف",
                "label_fr": "Cottage Grove"
            },
            {
                "value": 6362,
                "label": "Cottage Hills",
                "label_ar": "تلال الكوخ",
                "label_fr": "Cottage Hills"
            },
            {
                "value": 6363,
                "label": "Cottageville",
                "label_ar": "كوتاجفيل",
                "label_fr": "Cottageville"
            },
            {
                "value": 6364,
                "label": "Cottekill",
                "label_ar": "كوتيكيل",
                "label_fr": "Cottekill"
            },
            {
                "value": 6365,
                "label": "Cotton",
                "label_ar": "قطن",
                "label_fr": "Coton"
            },
            {
                "value": 6366,
                "label": "Cotton Valley",
                "label_ar": "وادي القطن",
                "label_fr": "Cotton Valley"
            },
            {
                "value": 6367,
                "label": "Cottondale",
                "label_ar": "كوتونديل",
                "label_fr": "Cottondale"
            },
            {
                "value": 6368,
                "label": "Cottonport",
                "label_ar": "كوتونبورت",
                "label_fr": "Cottonport"
            },
            {
                "value": 6369,
                "label": "Cottontown",
                "label_ar": "كوتونتاون",
                "label_fr": "Cottontown"
            },
            {
                "value": 6370,
                "label": "Cottonwood",
                "label_ar": "كوتونوود",
                "label_fr": "Cottonwood"
            },
            {
                "value": 6371,
                "label": "Cotuit",
                "label_ar": "كوتويت",
                "label_fr": "Cotuit"
            },
            {
                "value": 6372,
                "label": "Cotulla",
                "label_ar": "كوتولا",
                "label_fr": "Cotulla"
            },
            {
                "value": 6373,
                "label": "Coudersport",
                "label_ar": "كودرسبورت",
                "label_fr": "Coudersport"
            },
            {
                "value": 6374,
                "label": "Coulee City",
                "label_ar": "مدينة كولي",
                "label_fr": "Coulee City"
            },
            {
                "value": 6375,
                "label": "Coulee Dam",
                "label_ar": "سد كولي",
                "label_fr": "Barrage de Coulée"
            },
            {
                "value": 6376,
                "label": "Coulterville",
                "label_ar": "كولترفيل",
                "label_fr": "Coulterville"
            },
            {
                "value": 6377,
                "label": "Counce",
                "label_ar": "اقفز",
                "label_fr": "Counce"
            },
            {
                "value": 6378,
                "label": "Council",
                "label_ar": "مجلس",
                "label_fr": "Conseil"
            },
            {
                "value": 6379,
                "label": "Council Bluffs",
                "label_ar": "كاونسيل بلافز",
                "label_fr": "Council Bluffs"
            },
            {
                "value": 6380,
                "label": "Council Grove",
                "label_ar": "مجلس جروف",
                "label_fr": "Council Grove"
            },
            {
                "value": 6381,
                "label": "Council Hill",
                "label_ar": "المجلس هيل",
                "label_fr": "Council Hill"
            },
            {
                "value": 6382,
                "label": "Country Club Hills",
                "label_ar": "كونتري كلوب هيلز",
                "label_fr": "Country Club Hills"
            },
            {
                "value": 6383,
                "label": "Coupeville",
                "label_ar": "كوبفيل",
                "label_fr": "Coupeville"
            },
            {
                "value": 6384,
                "label": "Coupland",
                "label_ar": "كوبلاند",
                "label_fr": "Coupland"
            },
            {
                "value": 6385,
                "label": "Courtland",
                "label_ar": "كورتلاند",
                "label_fr": "Courtland"
            },
            {
                "value": 6386,
                "label": "Coushatta",
                "label_ar": "كوشاتا",
                "label_fr": "Coushatta"
            },
            {
                "value": 6387,
                "label": "Cove",
                "label_ar": "كوف",
                "label_fr": "Crique"
            },
            {
                "value": 6388,
                "label": "Covelo",
                "label_ar": "كوفيلو",
                "label_fr": "Covelo"
            },
            {
                "value": 6389,
                "label": "Coventry",
                "label_ar": "كوفنتري",
                "label_fr": "Coventry"
            },
            {
                "value": 6390,
                "label": "Covert",
                "label_ar": "السرية",
                "label_fr": "Secret"
            },
            {
                "value": 6391,
                "label": "Covina",
                "label_ar": "كوفينا",
                "label_fr": "Covina"
            },
            {
                "value": 6392,
                "label": "Covington",
                "label_ar": "كوفينجتون",
                "label_fr": "Covington"
            },
            {
                "value": 6393,
                "label": "Cowan",
                "label_ar": "كوان",
                "label_fr": "Cowan"
            },
            {
                "value": 6394,
                "label": "Cowansville",
                "label_ar": "كوانسفيل",
                "label_fr": "Cowansville"
            },
            {
                "value": 6395,
                "label": "Coward",
                "label_ar": "جبان",
                "label_fr": "lâche"
            },
            {
                "value": 6396,
                "label": "Cowden",
                "label_ar": "كاودن",
                "label_fr": "Cowden"
            },
            {
                "value": 6397,
                "label": "Cowen",
                "label_ar": "كوين",
                "label_fr": "Cowen"
            },
            {
                "value": 6398,
                "label": "Coweta",
                "label_ar": "Coweta",
                "label_fr": "Coweta"
            },
            {
                "value": 6399,
                "label": "Cowiche",
                "label_ar": "كويش",
                "label_fr": "Cowiche"
            },
            {
                "value": 6400,
                "label": "Cowlesville",
                "label_ar": "كوليسفيل",
                "label_fr": "Cowlesville"
            },
            {
                "value": 6401,
                "label": "Cowley",
                "label_ar": "كاولي",
                "label_fr": "Cowley"
            },
            {
                "value": 6402,
                "label": "Cowpens",
                "label_ar": "رعاة البقر",
                "label_fr": "Cowpens"
            },
            {
                "value": 6403,
                "label": "Coxs Creek",
                "label_ar": "كوكس كريك",
                "label_fr": "Ruisseau Coxs"
            },
            {
                "value": 6404,
                "label": "Coxsackie",
                "label_ar": "كوكساكي",
                "label_fr": "Coxsackie"
            },
            {
                "value": 6405,
                "label": "Coyle",
                "label_ar": "كويل",
                "label_fr": "Coyle"
            },
            {
                "value": 6406,
                "label": "Coyote",
                "label_ar": "ذئب امريكى - كايوتى",
                "label_fr": "Coyote"
            },
            {
                "value": 6407,
                "label": "Cozad",
                "label_ar": "كوزاد",
                "label_fr": "Cozad"
            },
            {
                "value": 6408,
                "label": "Crab Orchard",
                "label_ar": "كراب بستان",
                "label_fr": "Verger de crabe"
            },
            {
                "value": 6409,
                "label": "Crabtree",
                "label_ar": "كرابتري",
                "label_fr": "Crabtree"
            },
            {
                "value": 6410,
                "label": "Craftsbury",
                "label_ar": "كرافتسبيري",
                "label_fr": "Craftsbury"
            },
            {
                "value": 6411,
                "label": "Craig",
                "label_ar": "كريج",
                "label_fr": "Craig"
            },
            {
                "value": 6412,
                "label": "Craigmont",
                "label_ar": "كريجمونت",
                "label_fr": "Craigmont"
            },
            {
                "value": 6413,
                "label": "Craigsville",
                "label_ar": "كريغسفيل",
                "label_fr": "Craigsville"
            },
            {
                "value": 6414,
                "label": "Craigville",
                "label_ar": "كريجفيل",
                "label_fr": "Craigville"
            },
            {
                "value": 6415,
                "label": "Cramerton",
                "label_ar": "كرامرتون",
                "label_fr": "Cramerton"
            },
            {
                "value": 6416,
                "label": "Cranberry Township",
                "label_ar": "بلدة التوت البري",
                "label_fr": "Canton de Cranberry"
            },
            {
                "value": 6417,
                "label": "Cranbury",
                "label_ar": "كرانبري",
                "label_fr": "Cranbury"
            },
            {
                "value": 6418,
                "label": "Crandall",
                "label_ar": "كراندال",
                "label_fr": "Crandall"
            },
            {
                "value": 6419,
                "label": "Crandon",
                "label_ar": "كراندون",
                "label_fr": "Crandon"
            },
            {
                "value": 6420,
                "label": "Crane",
                "label_ar": "رافعه",
                "label_fr": "Grue"
            },
            {
                "value": 6421,
                "label": "Crane Hill",
                "label_ar": "كرين هيل",
                "label_fr": "Crane Hill"
            },
            {
                "value": 6422,
                "label": "Crane Lake",
                "label_ar": "بحيرة كرين",
                "label_fr": "Crane Lake"
            },
            {
                "value": 6423,
                "label": "Cranesville",
                "label_ar": "كرانسفيل",
                "label_fr": "Cranesville"
            },
            {
                "value": 6424,
                "label": "Cranfills Gap",
                "label_ar": "كرانفيلز جاب",
                "label_fr": "Cranfills Gap"
            },
            {
                "value": 6425,
                "label": "Cranford",
                "label_ar": "كرانفورد",
                "label_fr": "Cranford"
            },
            {
                "value": 6426,
                "label": "Cranks",
                "label_ar": "السواعد",
                "label_fr": "Manivelles"
            },
            {
                "value": 6427,
                "label": "Cranston",
                "label_ar": "كرانستون",
                "label_fr": "Cranston"
            },
            {
                "value": 6428,
                "label": "Craryville",
                "label_ar": "Craryville",
                "label_fr": "Craryville"
            },
            {
                "value": 6429,
                "label": "Crawford",
                "label_ar": "كروفورد",
                "label_fr": "Crawford"
            },
            {
                "value": 6430,
                "label": "Crawfordsville",
                "label_ar": "كروفوردسفيل",
                "label_fr": "Crawfordsville"
            },
            {
                "value": 6431,
                "label": "Crawfordville",
                "label_ar": "كروفوردفيل",
                "label_fr": "Crawfordville"
            },
            {
                "value": 6432,
                "label": "Crawley",
                "label_ar": "كرولي",
                "label_fr": "Crawley"
            },
            {
                "value": 6433,
                "label": "Creal Springs",
                "label_ar": "كريال سبرينغز",
                "label_fr": "Creal Springs"
            },
            {
                "value": 6434,
                "label": "Cream Ridge",
                "label_ar": "كريم ريدج",
                "label_fr": "Cream Ridge"
            },
            {
                "value": 6435,
                "label": "Creede",
                "label_ar": "العقيدة",
                "label_fr": "Creede"
            },
            {
                "value": 6436,
                "label": "Creedmoor",
                "label_ar": "كريدمور",
                "label_fr": "Creedmoor"
            },
            {
                "value": 6437,
                "label": "Creekside",
                "label_ar": "كريكسايد",
                "label_fr": "Creekside"
            },
            {
                "value": 6438,
                "label": "Creighton",
                "label_ar": "كريتون",
                "label_fr": "Creighton"
            },
            {
                "value": 6439,
                "label": "Creola",
                "label_ar": "كريولا",
                "label_fr": "Créole"
            },
            {
                "value": 6440,
                "label": "Creole",
                "label_ar": "الكريول",
                "label_fr": "le créole"
            },
            {
                "value": 6441,
                "label": "Cresbard",
                "label_ar": "كريسبارد",
                "label_fr": "Cresbard"
            },
            {
                "value": 6442,
                "label": "Crescent",
                "label_ar": "هلال",
                "label_fr": "Croissant"
            },
            {
                "value": 6443,
                "label": "Crescent City",
                "label_ar": "مدينة الهلال",
                "label_fr": "Crescent City"
            },
            {
                "value": 6444,
                "label": "Crescent Hills",
                "label_ar": "تلال الهلال",
                "label_fr": "Crescent Hills"
            },
            {
                "value": 6445,
                "label": "Crescent Mills",
                "label_ar": "مطاحن الهلال",
                "label_fr": "Crescent Mills"
            },
            {
                "value": 6446,
                "label": "Crescent Valley",
                "label_ar": "وادي الهلال",
                "label_fr": "Crescent Valley"
            },
            {
                "value": 6447,
                "label": "Cresco",
                "label_ar": "كريسكو",
                "label_fr": "Cresco"
            },
            {
                "value": 6448,
                "label": "Cresskill",
                "label_ar": "كريسكيل",
                "label_fr": "Cresskill"
            },
            {
                "value": 6449,
                "label": "Cresson",
                "label_ar": "كريسون",
                "label_fr": "Cresson"
            },
            {
                "value": 6450,
                "label": "Crest Hill",
                "label_ar": "كريست هيل",
                "label_fr": "Crest Hill"
            },
            {
                "value": 6451,
                "label": "Crested Butte",
                "label_ar": "كريستيد بوت",
                "label_fr": "Crested Butte"
            },
            {
                "value": 6452,
                "label": "Crestline",
                "label_ar": "كريستلين",
                "label_fr": "Crestline"
            },
            {
                "value": 6453,
                "label": "Creston",
                "label_ar": "كريستون",
                "label_fr": "Creston"
            },
            {
                "value": 6454,
                "label": "Crestone",
                "label_ar": "حجر كريم",
                "label_fr": "Crestone"
            },
            {
                "value": 6455,
                "label": "Crestview",
                "label_ar": "كريستفيو",
                "label_fr": "Crestview"
            },
            {
                "value": 6456,
                "label": "Crestwood",
                "label_ar": "كريستوود",
                "label_fr": "Crestwood"
            },
            {
                "value": 6457,
                "label": "Creswell",
                "label_ar": "كريسويل",
                "label_fr": "Creswell"
            },
            {
                "value": 6458,
                "label": "Crete",
                "label_ar": "كريت",
                "label_fr": "Crète"
            },
            {
                "value": 6459,
                "label": "Creve Coeur",
                "label_ar": "كريف كور",
                "label_fr": "Creve Coeur"
            },
            {
                "value": 6460,
                "label": "Crewe",
                "label_ar": "كرو",
                "label_fr": "Crewe"
            },
            {
                "value": 6461,
                "label": "Crimora",
                "label_ar": "كريمورا",
                "label_fr": "Crimora"
            },
            {
                "value": 6462,
                "label": "Cripple Creek",
                "label_ar": "كريبل كريك",
                "label_fr": "Ruisseau Cripple"
            },
            {
                "value": 6463,
                "label": "Crisfield",
                "label_ar": "كريسفيلد",
                "label_fr": "Crisfield"
            },
            {
                "value": 6464,
                "label": "Crittenden",
                "label_ar": "كريتندن",
                "label_fr": "Crittenden"
            },
            {
                "value": 6465,
                "label": "Critz",
                "label_ar": "كريتز",
                "label_fr": "Critz"
            },
            {
                "value": 6466,
                "label": "Crivitz",
                "label_ar": "كريفيتز",
                "label_fr": "Crivitz"
            },
            {
                "value": 6467,
                "label": "Crocker",
                "label_ar": "كروكر",
                "label_fr": "Crocker"
            },
            {
                "value": 6468,
                "label": "Crockett",
                "label_ar": "كروكيت",
                "label_fr": "Crockett"
            },
            {
                "value": 6469,
                "label": "Crofton",
                "label_ar": "كروفتون",
                "label_fr": "Crofton"
            },
            {
                "value": 6470,
                "label": "Croghan",
                "label_ar": "كروغان",
                "label_fr": "Croghan"
            },
            {
                "value": 6471,
                "label": "Crompond",
                "label_ar": "كروموند",
                "label_fr": "Crompond"
            },
            {
                "value": 6472,
                "label": "Cromwell",
                "label_ar": "كرومويل",
                "label_fr": "Cromwell"
            },
            {
                "value": 6473,
                "label": "Crooks",
                "label_ar": "المحتالون",
                "label_fr": "Escrocs"
            },
            {
                "value": 6474,
                "label": "Crookston",
                "label_ar": "كروكستون",
                "label_fr": "Crookston"
            },
            {
                "value": 6475,
                "label": "Crooksville",
                "label_ar": "كروكسفيل",
                "label_fr": "Crooksville"
            },
            {
                "value": 6476,
                "label": "Cropseyville",
                "label_ar": "كروبسيفيل",
                "label_fr": "Cropseyville"
            },
            {
                "value": 6477,
                "label": "Crosby",
                "label_ar": "كروسبي",
                "label_fr": "Crosby"
            },
            {
                "value": 6478,
                "label": "Crosbyton",
                "label_ar": "كروسبيتون",
                "label_fr": "Crosbyton"
            },
            {
                "value": 6479,
                "label": "Cross",
                "label_ar": "تعبر",
                "label_fr": "Traverser"
            },
            {
                "value": 6480,
                "label": "Cross City",
                "label_ar": "كروس سيتي",
                "label_fr": "Cross City"
            },
            {
                "value": 6481,
                "label": "Cross Junction",
                "label_ar": "عبر مفرق",
                "label_fr": "Jonction croisée"
            },
            {
                "value": 6482,
                "label": "Cross Lake",
                "label_ar": "كروس ليك",
                "label_fr": "Cross Lake"
            },
            {
                "value": 6483,
                "label": "Cross Plains",
                "label_ar": "عبر السهول",
                "label_fr": "Traverser les plaines"
            },
            {
                "value": 6484,
                "label": "Cross River",
                "label_ar": "كروس ريفر",
                "label_fr": "Cross River"
            },
            {
                "value": 6485,
                "label": "Crossett",
                "label_ar": "كروسيت",
                "label_fr": "Crossett"
            },
            {
                "value": 6486,
                "label": "Crossville",
                "label_ar": "كروسفيل",
                "label_fr": "Crossville"
            },
            {
                "value": 6487,
                "label": "Crosswicks",
                "label_ar": "Crosswicks",
                "label_fr": "Crosswicks"
            },
            {
                "value": 6488,
                "label": "Croswell",
                "label_ar": "كروسويل",
                "label_fr": "Croswell"
            },
            {
                "value": 6489,
                "label": "Crothersville",
                "label_ar": "كروثرزفيل",
                "label_fr": "Crothersville"
            },
            {
                "value": 6490,
                "label": "Croton",
                "label_ar": "كروتون",
                "label_fr": "Croton"
            },
            {
                "value": 6491,
                "label": "Croton Falls",
                "label_ar": "شلالات كروتون",
                "label_fr": "Croton Falls"
            },
            {
                "value": 6492,
                "label": "Croton-on-Hudson",
                "label_ar": "كروتون أون هدسون",
                "label_fr": "Croton-sur-Hudson"
            },
            {
                "value": 6493,
                "label": "Crow Agency",
                "label_ar": "وكالة الغراب",
                "label_fr": "Agence Crow"
            },
            {
                "value": 6494,
                "label": "Crowder",
                "label_ar": "كراودر",
                "label_fr": "Crowder"
            },
            {
                "value": 6495,
                "label": "Crowell",
                "label_ar": "كروويل",
                "label_fr": "Crowell"
            },
            {
                "value": 6496,
                "label": "Crowheart",
                "label_ar": "قلب الغراب",
                "label_fr": "Crowheart"
            },
            {
                "value": 6497,
                "label": "Crowley",
                "label_ar": "كرولي",
                "label_fr": "Crowley"
            },
            {
                "value": 6498,
                "label": "Crown City",
                "label_ar": "كراون سيتي",
                "label_fr": "Crown City"
            },
            {
                "value": 6499,
                "label": "Crown Point",
                "label_ar": "كراون بوينت",
                "label_fr": "Point de couronne"
            },
            {
                "value": 6500,
                "label": "Crownpoint",
                "label_ar": "كراون بوينت",
                "label_fr": "Crownpoint"
            },
            {
                "value": 6501,
                "label": "Crownsville",
                "label_ar": "كراونزفيل",
                "label_fr": "Crownsville"
            },
            {
                "value": 6502,
                "label": "Crows Landing",
                "label_ar": "هبوط الغربان",
                "label_fr": "Débarquement de corbeaux"
            },
            {
                "value": 6503,
                "label": "Croydon",
                "label_ar": "كرويدون",
                "label_fr": "Croydon"
            },
            {
                "value": 6504,
                "label": "Crozet",
                "label_ar": "كروزيت",
                "label_fr": "Crozet"
            },
            {
                "value": 6505,
                "label": "Crucible",
                "label_ar": "بوتقة",
                "label_fr": "Creuset"
            },
            {
                "value": 6506,
                "label": "Cruger",
                "label_ar": "كروجر",
                "label_fr": "Cruger"
            },
            {
                "value": 6507,
                "label": "Crum",
                "label_ar": "كرم",
                "label_fr": "Crum"
            },
            {
                "value": 6508,
                "label": "Crum Lynne",
                "label_ar": "كروم لين",
                "label_fr": "Crum Lynne"
            },
            {
                "value": 6509,
                "label": "Crump",
                "label_ar": "كرامب",
                "label_fr": "Éclatement"
            },
            {
                "value": 6510,
                "label": "Crumpler",
                "label_ar": "كرمبلر",
                "label_fr": "Crumpler"
            },
            {
                "value": 6511,
                "label": "Crystal",
                "label_ar": "كريستال",
                "label_fr": "Cristal"
            },
            {
                "value": 6512,
                "label": "Crystal City",
                "label_ar": "كريستال سيتى",
                "label_fr": "Crystal City"
            },
            {
                "value": 6513,
                "label": "Crystal Falls",
                "label_ar": "كريستال فولز",
                "label_fr": "Crystal Falls"
            },
            {
                "value": 6514,
                "label": "Crystal Lake",
                "label_ar": "بحيرة الكريستال",
                "label_fr": "Crystal Lake"
            },
            {
                "value": 6515,
                "label": "Crystal River",
                "label_ar": "نهر الكريستال",
                "label_fr": "Crystal River"
            },
            {
                "value": 6516,
                "label": "Crystal Springs",
                "label_ar": "كريستال سبرينجز",
                "label_fr": "Crystal Springs"
            },
            {
                "value": 6517,
                "label": "Cub Run",
                "label_ar": "تشغيل شبل",
                "label_fr": "Course de louveteaux"
            },
            {
                "value": 6518,
                "label": "Cuba",
                "label_ar": "كوبا",
                "label_fr": "Cuba"
            },
            {
                "value": 6519,
                "label": "Cuba City",
                "label_ar": "مدينة كوبا",
                "label_fr": "Cuba ville"
            },
            {
                "value": 6520,
                "label": "Cudahy",
                "label_ar": "كوداهي",
                "label_fr": "Cudahy"
            },
            {
                "value": 6521,
                "label": "Cuddebackville",
                "label_ar": "كودديباكفيل",
                "label_fr": "Cuddebackville"
            },
            {
                "value": 6522,
                "label": "Cuddy Hill",
                "label_ar": "كادي هيل",
                "label_fr": "Cuddy Hill"
            },
            {
                "value": 6523,
                "label": "Cuero",
                "label_ar": "كويرو",
                "label_fr": "Cuero"
            },
            {
                "value": 6524,
                "label": "Culbertson",
                "label_ar": "كولبيرتسون",
                "label_fr": "Culbertson"
            },
            {
                "value": 6525,
                "label": "Culdesac",
                "label_ar": "كولديساك",
                "label_fr": "Culdesac"
            },
            {
                "value": 6526,
                "label": "Cullen",
                "label_ar": "كولين",
                "label_fr": "Cullen"
            },
            {
                "value": 6527,
                "label": "Culleoka",
                "label_ar": "كوليوكا",
                "label_fr": "Culleoka"
            },
            {
                "value": 6528,
                "label": "Cullman",
                "label_ar": "كولمان",
                "label_fr": "Cullman"
            },
            {
                "value": 6529,
                "label": "Culloden",
                "label_ar": "كولودين",
                "label_fr": "Culloden"
            },
            {
                "value": 6530,
                "label": "Cullom",
                "label_ar": "كولوم",
                "label_fr": "Cullom"
            },
            {
                "value": 6531,
                "label": "Cullowhee",
                "label_ar": "كولوهي",
                "label_fr": "Cullowhee"
            },
            {
                "value": 6532,
                "label": "Culpeper",
                "label_ar": "كولبيبر",
                "label_fr": "Culpeper"
            },
            {
                "value": 6533,
                "label": "Culver",
                "label_ar": "كولفر",
                "label_fr": "Culver"
            },
            {
                "value": 6534,
                "label": "Culver City",
                "label_ar": "كولفر سيتي",
                "label_fr": "Culver City"
            },
            {
                "value": 6535,
                "label": "Cumberland",
                "label_ar": "كمبرلاند",
                "label_fr": "Cumberland"
            },
            {
                "value": 6536,
                "label": "Cumberland Center",
                "label_ar": "مركز كمبرلاند",
                "label_fr": "Centre Cumberland"
            },
            {
                "value": 6537,
                "label": "Cumberland City",
                "label_ar": "كمبرلاند سيتي",
                "label_fr": "Cumberland City"
            },
            {
                "value": 6538,
                "label": "Cumberland Foreside",
                "label_ar": "كمبرلاند فورسايد",
                "label_fr": "Cumberland Foreside"
            },
            {
                "value": 6539,
                "label": "Cumberland Furnace",
                "label_ar": "فرن كمبرلاند",
                "label_fr": "Fournaise Cumberland"
            },
            {
                "value": 6540,
                "label": "Cumberland Gap",
                "label_ar": "كمبرلاند جاب",
                "label_fr": "Cumberland Gap"
            },
            {
                "value": 6541,
                "label": "Cumby",
                "label_ar": "كومبي",
                "label_fr": "Cumby"
            },
            {
                "value": 6542,
                "label": "Cumming",
                "label_ar": "كومينغ",
                "label_fr": "Éjacule"
            },
            {
                "value": 6543,
                "label": "Cummings",
                "label_ar": "كامينغز",
                "label_fr": "Éjaculations"
            },
            {
                "value": 6544,
                "label": "Cummington",
                "label_ar": "كومينغتون",
                "label_fr": "Cummington"
            },
            {
                "value": 6545,
                "label": "Cunningham",
                "label_ar": "كننغهام",
                "label_fr": "Cunningham"
            },
            {
                "value": 6546,
                "label": "Cupertino",
                "label_ar": "كوبرتينو",
                "label_fr": "Cupertino"
            },
            {
                "value": 6547,
                "label": "Currie",
                "label_ar": "كوري",
                "label_fr": "Currie"
            },
            {
                "value": 6548,
                "label": "Currituck",
                "label_ar": "كوريتوك",
                "label_fr": "Currituck"
            },
            {
                "value": 6549,
                "label": "Curryville",
                "label_ar": "كوريفيل",
                "label_fr": "Curryville"
            },
            {
                "value": 6550,
                "label": "Curtice",
                "label_ar": "ستارة",
                "label_fr": "Curtice"
            },
            {
                "value": 6551,
                "label": "Curtis",
                "label_ar": "كورتيس",
                "label_fr": "Curtis"
            },
            {
                "value": 6552,
                "label": "Curtis Bay",
                "label_ar": "خليج كورتيس",
                "label_fr": "Curtis Bay"
            },
            {
                "value": 6553,
                "label": "Curtiss",
                "label_ar": "كيرتس",
                "label_fr": "Curtiss"
            },
            {
                "value": 6554,
                "label": "Curwensville",
                "label_ar": "كوروينسفيل",
                "label_fr": "Curwensville"
            },
            {
                "value": 6555,
                "label": "Cushing",
                "label_ar": "كوشينغ",
                "label_fr": "Cushing"
            },
            {
                "value": 6556,
                "label": "Cusick",
                "label_ar": "كوزيك",
                "label_fr": "Cusick"
            },
            {
                "value": 6557,
                "label": "Cusseta",
                "label_ar": "كوسيتا",
                "label_fr": "Cusseta"
            },
            {
                "value": 6558,
                "label": "Custar",
                "label_ar": "كاستر",
                "label_fr": "Custar"
            },
            {
                "value": 6559,
                "label": "Custer",
                "label_ar": "كاستر",
                "label_fr": "Custer"
            },
            {
                "value": 6560,
                "label": "Custer City",
                "label_ar": "مدينة كستر",
                "label_fr": "Custer City"
            },
            {
                "value": 6561,
                "label": "Cut Bank",
                "label_ar": "كت بنك",
                "label_fr": "Couper la banque"
            },
            {
                "value": 6562,
                "label": "Cut Off",
                "label_ar": "قطع",
                "label_fr": "Couper"
            },
            {
                "value": 6563,
                "label": "Cutchogue",
                "label_ar": "Cutchogue",
                "label_fr": "Cutchogue"
            },
            {
                "value": 6564,
                "label": "Cuthbert",
                "label_ar": "كوثبرت",
                "label_fr": "Cuthbert"
            },
            {
                "value": 6565,
                "label": "Cutler",
                "label_ar": "كاتلر",
                "label_fr": "Coutelier"
            },
            {
                "value": 6566,
                "label": "Cuttingsville",
                "label_ar": "Cuttingsville",
                "label_fr": "Cuttingsville"
            },
            {
                "value": 6567,
                "label": "Cuyahoga Falls",
                "label_ar": "شلالات كوياهوغا",
                "label_fr": "Chutes de Cuyahoga"
            },
            {
                "value": 6568,
                "label": "Cyclone",
                "label_ar": "إعصار",
                "label_fr": "Cyclone"
            },
            {
                "value": 6569,
                "label": "Cygnet",
                "label_ar": "Cygnet",
                "label_fr": "Jeune Cygne"
            },
            {
                "value": 6570,
                "label": "Cylinder",
                "label_ar": "اسطوانة",
                "label_fr": "Cylindre"
            },
            {
                "value": 6571,
                "label": "Cynthiana",
                "label_ar": "سينثيانا",
                "label_fr": "Cynthiana"
            },
            {
                "value": 6572,
                "label": "Cypress",
                "label_ar": "شجرة السرو",
                "label_fr": "Cyprès"
            },
            {
                "value": 6573,
                "label": "Cypress Inn",
                "label_ar": "نزل السرو",
                "label_fr": "Cypress Inn"
            },
            {
                "value": 6574,
                "label": "Cyril",
                "label_ar": "سيريل",
                "label_fr": "Cyril"
            },
            {
                "value": 6575,
                "label": "D'Iberville",
                "label_ar": "ديبرفيل",
                "label_fr": "D'Iberville"
            },
            {
                "value": 6576,
                "label": "Dacoma",
                "label_ar": "داكوما",
                "label_fr": "Dacoma"
            },
            {
                "value": 6577,
                "label": "Dacono",
                "label_ar": "داكونو",
                "label_fr": "Dacono"
            },
            {
                "value": 6578,
                "label": "Dacula",
                "label_ar": "داكولا",
                "label_fr": "Dacula"
            },
            {
                "value": 6579,
                "label": "Dade City",
                "label_ar": "ديد سيتي",
                "label_fr": "Dade City"
            },
            {
                "value": 6580,
                "label": "Dadeville",
                "label_ar": "دادفيل",
                "label_fr": "Dadeville"
            },
            {
                "value": 6581,
                "label": "Daggett",
                "label_ar": "داجيت",
                "label_fr": "Daggett"
            },
            {
                "value": 6582,
                "label": "Dagsboro",
                "label_ar": "داجسبورو",
                "label_fr": "Dagsboro"
            },
            {
                "value": 6583,
                "label": "Dahlgren",
                "label_ar": "دالغرين",
                "label_fr": "Dahlgren"
            },
            {
                "value": 6584,
                "label": "Dahlonega",
                "label_ar": "داهلونيجا",
                "label_fr": "Dahlonega"
            },
            {
                "value": 6585,
                "label": "Daingerfield",
                "label_ar": "دينجيرفيلد",
                "label_fr": "Daingerfield"
            },
            {
                "value": 6586,
                "label": "Daisetta",
                "label_ar": "دايسيتا",
                "label_fr": "Daisetta"
            },
            {
                "value": 6587,
                "label": "Daisytown",
                "label_ar": "ديزي تاون",
                "label_fr": "Daisytown"
            },
            {
                "value": 6588,
                "label": "Dakota",
                "label_ar": "داكوتا",
                "label_fr": "Dakota"
            },
            {
                "value": 6589,
                "label": "Dakota City",
                "label_ar": "مدينة داكوتا",
                "label_fr": "Dakota City"
            },
            {
                "value": 6590,
                "label": "Dale",
                "label_ar": "دايل",
                "label_fr": "Vallée"
            },
            {
                "value": 6591,
                "label": "Daleville",
                "label_ar": "دالفيل",
                "label_fr": "Daleville"
            },
            {
                "value": 6592,
                "label": "Dalhart",
                "label_ar": "دالهارت",
                "label_fr": "Dalhart"
            },
            {
                "value": 6593,
                "label": "Dallas",
                "label_ar": "دالاس",
                "label_fr": "Dallas"
            },
            {
                "value": 6594,
                "label": "Dallas Center",
                "label_ar": "مركز دالاس",
                "label_fr": "Centre de Dallas"
            },
            {
                "value": 6595,
                "label": "Dallas City",
                "label_ar": "مدينة دالاس",
                "label_fr": "Dallas City"
            },
            {
                "value": 6596,
                "label": "Dallastown",
                "label_ar": "دالستاون",
                "label_fr": "Dallastown"
            },
            {
                "value": 6597,
                "label": "Dalmatia",
                "label_ar": "دالماتيا",
                "label_fr": "Dalmatie"
            },
            {
                "value": 6598,
                "label": "Dalton",
                "label_ar": "دالتون",
                "label_fr": "Dalton"
            },
            {
                "value": 6599,
                "label": "Dalton City",
                "label_ar": "دالتون سيتي",
                "label_fr": "Dalton City"
            },
            {
                "value": 6600,
                "label": "Daly City",
                "label_ar": "دالي سيتي",
                "label_fr": "Daly City"
            },
            {
                "value": 6601,
                "label": "Dalzell",
                "label_ar": "دالزيل",
                "label_fr": "Dalzell"
            },
            {
                "value": 6602,
                "label": "Damariscotta",
                "label_ar": "داماريسكوتا",
                "label_fr": "Damariscotta"
            },
            {
                "value": 6603,
                "label": "Damascus",
                "label_ar": "دمشق",
                "label_fr": "Damas"
            },
            {
                "value": 6604,
                "label": "Damon",
                "label_ar": "ديمون",
                "label_fr": "Damon"
            },
            {
                "value": 6605,
                "label": "Dana",
                "label_ar": "دانا",
                "label_fr": "Dana"
            },
            {
                "value": 6606,
                "label": "Dana Point",
                "label_ar": "دانا بوينت",
                "label_fr": "Dana Point"
            },
            {
                "value": 6607,
                "label": "Danbury",
                "label_ar": "دانبري",
                "label_fr": "Danbury"
            },
            {
                "value": 6608,
                "label": "Danby",
                "label_ar": "دانبي",
                "label_fr": "Danby"
            },
            {
                "value": 6609,
                "label": "Dandridge",
                "label_ar": "داندريدج",
                "label_fr": "Dandridge"
            },
            {
                "value": 6610,
                "label": "Dane",
                "label_ar": "الدنماركي",
                "label_fr": "Danois"
            },
            {
                "value": 6611,
                "label": "Danese",
                "label_ar": "الدانماركية",
                "label_fr": "Danese"
            },
            {
                "value": 6612,
                "label": "Danforth",
                "label_ar": "دانفورث",
                "label_fr": "Danforth"
            },
            {
                "value": 6613,
                "label": "Dania Beach",
                "label_ar": "شاطئ دانيا",
                "label_fr": "Plage de Dania"
            },
            {
                "value": 6614,
                "label": "Daniels",
                "label_ar": "دانيلز",
                "label_fr": "Daniels"
            },
            {
                "value": 6615,
                "label": "Danielson",
                "label_ar": "دانيلسون",
                "label_fr": "Danielson"
            },
            {
                "value": 6616,
                "label": "Danielsville",
                "label_ar": "دانيلسفيل",
                "label_fr": "Danielsville"
            },
            {
                "value": 6617,
                "label": "Dannemora",
                "label_ar": "دانيمورا",
                "label_fr": "Dannemora"
            },
            {
                "value": 6618,
                "label": "Dansville",
                "label_ar": "دانفيل",
                "label_fr": "Dansville"
            },
            {
                "value": 6619,
                "label": "Dante",
                "label_ar": "دانتي",
                "label_fr": "Dante"
            },
            {
                "value": 6620,
                "label": "Danube",
                "label_ar": "الدانوب",
                "label_fr": "Danube"
            },
            {
                "value": 6621,
                "label": "Danvers",
                "label_ar": "دانفرز",
                "label_fr": "Danvers"
            },
            {
                "value": 6622,
                "label": "Danville",
                "label_ar": "دانفيل",
                "label_fr": "Danville"
            },
            {
                "value": 6623,
                "label": "Daphne",
                "label_ar": "دافني",
                "label_fr": "Daphné"
            },
            {
                "value": 6624,
                "label": "Darby",
                "label_ar": "داربي",
                "label_fr": "Darby"
            },
            {
                "value": 6625,
                "label": "Dardanelle",
                "label_ar": "الدردنيل",
                "label_fr": "Dardanelle"
            },
            {
                "value": 6626,
                "label": "Darden",
                "label_ar": "داردن",
                "label_fr": "Darden"
            },
            {
                "value": 6627,
                "label": "Darien",
                "label_ar": "دارين",
                "label_fr": "Darien"
            },
            {
                "value": 6628,
                "label": "Darlington",
                "label_ar": "دارلينجتون",
                "label_fr": "Darlington"
            },
            {
                "value": 6629,
                "label": "Darmstadt",
                "label_ar": "دارمشتات",
                "label_fr": "Darmstadt"
            },
            {
                "value": 6630,
                "label": "Darrington",
                "label_ar": "دارينجتون",
                "label_fr": "Darrington"
            },
            {
                "value": 6631,
                "label": "Darrouzett",
                "label_ar": "داروزيت",
                "label_fr": "Darrouzett"
            },
            {
                "value": 6632,
                "label": "Darrow",
                "label_ar": "دارو",
                "label_fr": "Darrow"
            },
            {
                "value": 6633,
                "label": "Darwin",
                "label_ar": "داروين",
                "label_fr": "Darwin"
            },
            {
                "value": 6634,
                "label": "Dassel",
                "label_ar": "داسيل",
                "label_fr": "Dassel"
            },
            {
                "value": 6635,
                "label": "Dateland",
                "label_ar": "داتلاند",
                "label_fr": "Dateland"
            },
            {
                "value": 6636,
                "label": "Datil",
                "label_ar": "داتيل",
                "label_fr": "Datil"
            },
            {
                "value": 6637,
                "label": "Dauphin",
                "label_ar": "دوفين",
                "label_fr": "Dauphin"
            },
            {
                "value": 6638,
                "label": "Dauphin Island",
                "label_ar": "جزيرة دوفين",
                "label_fr": "Île Dauphin"
            },
            {
                "value": 6639,
                "label": "Davenport",
                "label_ar": "دافنبورت",
                "label_fr": "Canapé"
            },
            {
                "value": 6640,
                "label": "David City",
                "label_ar": "ديفيد سيتي",
                "label_fr": "David City"
            },
            {
                "value": 6641,
                "label": "Davidson",
                "label_ar": "ديفيدسون",
                "label_fr": "Davidson"
            },
            {
                "value": 6642,
                "label": "Davidsonville",
                "label_ar": "ديفيدسونفيل",
                "label_fr": "Davidsonville"
            },
            {
                "value": 6643,
                "label": "Davis",
                "label_ar": "ديفيس",
                "label_fr": "Davis"
            },
            {
                "value": 6644,
                "label": "Davis City",
                "label_ar": "مدينة ديفيس",
                "label_fr": "Davis City"
            },
            {
                "value": 6645,
                "label": "Davis Junction",
                "label_ar": "مفرق ديفيس",
                "label_fr": "Davis Junction"
            },
            {
                "value": 6646,
                "label": "Davisboro",
                "label_ar": "دافيسبورو",
                "label_fr": "Davisboro"
            },
            {
                "value": 6647,
                "label": "Davisburg",
                "label_ar": "دافيسبورغ",
                "label_fr": "Davisburg"
            },
            {
                "value": 6648,
                "label": "Davison",
                "label_ar": "دافيسون",
                "label_fr": "Davison"
            },
            {
                "value": 6649,
                "label": "Daviston",
                "label_ar": "دافيستون",
                "label_fr": "Daviston"
            },
            {
                "value": 6650,
                "label": "Davisville",
                "label_ar": "ديفيسفيل",
                "label_fr": "Davisville"
            },
            {
                "value": 6651,
                "label": "Davy",
                "label_ar": "ديفي",
                "label_fr": "Davy"
            },
            {
                "value": 6652,
                "label": "Dawes",
                "label_ar": "Dawes",
                "label_fr": "Dawes"
            },
            {
                "value": 6653,
                "label": "Dawn",
                "label_ar": "فجر",
                "label_fr": "Aube"
            },
            {
                "value": 6654,
                "label": "Dawson",
                "label_ar": "داوسون",
                "label_fr": "Dawson"
            },
            {
                "value": 6655,
                "label": "Dawson Springs",
                "label_ar": "داوسون سبرينغز",
                "label_fr": "Dawson Springs"
            },
            {
                "value": 6656,
                "label": "Dawsonville",
                "label_ar": "داوسونفيل",
                "label_fr": "Dawsonville"
            },
            {
                "value": 6657,
                "label": "Days Creek",
                "label_ar": "دايز كريك",
                "label_fr": "Days Creek"
            },
            {
                "value": 6658,
                "label": "Dayton",
                "label_ar": "دايتون",
                "label_fr": "Dayton"
            },
            {
                "value": 6659,
                "label": "Daytona Beach",
                "label_ar": "شاطئ دايتونا",
                "label_fr": "Daytona Beach"
            },
            {
                "value": 6660,
                "label": "Dayville",
                "label_ar": "دايفيل",
                "label_fr": "Dayville"
            },
            {
                "value": 6661,
                "label": "Dazey",
                "label_ar": "مبهر",
                "label_fr": "Dazey"
            },
            {
                "value": 6662,
                "label": "De Beque",
                "label_ar": "دي بيكيه",
                "label_fr": "De Beque"
            },
            {
                "value": 6663,
                "label": "De Borgia",
                "label_ar": "دي بورجيا",
                "label_fr": "De Borgia"
            },
            {
                "value": 6664,
                "label": "De Forest",
                "label_ar": "دي فورست",
                "label_fr": "De Forest"
            },
            {
                "value": 6665,
                "label": "De Graff",
                "label_ar": "دي جراف",
                "label_fr": "De Graff"
            },
            {
                "value": 6666,
                "label": "De Kalb",
                "label_ar": "دي كلب",
                "label_fr": "De Kalb"
            },
            {
                "value": 6667,
                "label": "De Kalb Junction",
                "label_ar": "مفرق دي كلب",
                "label_fr": "De Kalb Junction"
            },
            {
                "value": 6668,
                "label": "De Leon",
                "label_ar": "دي ليون",
                "label_fr": "De Léon"
            },
            {
                "value": 6669,
                "label": "De Leon Springs",
                "label_ar": "دي ليون سبرينجز",
                "label_fr": "De Leon Springs"
            },
            {
                "value": 6670,
                "label": "De Queen",
                "label_ar": "دي كوين",
                "label_fr": "De Queen"
            },
            {
                "value": 6671,
                "label": "De Smet",
                "label_ar": "دي سميت",
                "label_fr": "De Smet"
            },
            {
                "value": 6672,
                "label": "De Soto",
                "label_ar": "دي سوتو",
                "label_fr": "De Soto"
            },
            {
                "value": 6673,
                "label": "De Valls Bluff",
                "label_ar": "دي فالس بلاف",
                "label_fr": "De Valls Bluff"
            },
            {
                "value": 6674,
                "label": "De Witt",
                "label_ar": "دي ويت",
                "label_fr": "De Witt"
            },
            {
                "value": 6675,
                "label": "DeBary",
                "label_ar": "ديباري",
                "label_fr": "DeBary"
            },
            {
                "value": 6676,
                "label": "DeFuniak Springs",
                "label_ar": "DeFuniak Springs",
                "label_fr": "DeFuniak Springs"
            },
            {
                "value": 6677,
                "label": "DeKalb",
                "label_ar": "ديكالب",
                "label_fr": "DeKalb"
            },
            {
                "value": 6678,
                "label": "DeLand",
                "label_ar": "ديلاند",
                "label_fr": "DeLand"
            },
            {
                "value": 6679,
                "label": "DeMossville",
                "label_ar": "ديموسفيل",
                "label_fr": "DeMossville"
            },
            {
                "value": 6680,
                "label": "DeMotte",
                "label_ar": "ديموت",
                "label_fr": "DeMotte"
            },
            {
                "value": 6681,
                "label": "DeQuincy",
                "label_ar": "ديكوينسي",
                "label_fr": "DeQuincy"
            },
            {
                "value": 6682,
                "label": "DeRidder",
                "label_ar": "ديريدر",
                "label_fr": "DeRidder"
            },
            {
                "value": 6683,
                "label": "DeRuyter",
                "label_ar": "ديرويتر",
                "label_fr": "DeRuyter"
            },
            {
                "value": 6684,
                "label": "DeSoto",
                "label_ar": "ديسوتو",
                "label_fr": "DeSoto"
            },
            {
                "value": 6685,
                "label": "DeWitt",
                "label_ar": "ديويت",
                "label_fr": "DeWitt"
            },
            {
                "value": 6686,
                "label": "Deadwood",
                "label_ar": "خشب ميت",
                "label_fr": "Bois morts"
            },
            {
                "value": 6687,
                "label": "Deal",
                "label_ar": "صفقة",
                "label_fr": "Traiter"
            },
            {
                "value": 6688,
                "label": "Deal Island",
                "label_ar": "جزيرة ديل",
                "label_fr": "Deal Island"
            },
            {
                "value": 6689,
                "label": "Deale",
                "label_ar": "دييل",
                "label_fr": "Deale"
            },
            {
                "value": 6690,
                "label": "Deansboro",
                "label_ar": "دينسبورو",
                "label_fr": "Deansboro"
            },
            {
                "value": 6691,
                "label": "Deanville",
                "label_ar": "دينفيل",
                "label_fr": "Deanville"
            },
            {
                "value": 6692,
                "label": "Dearborn",
                "label_ar": "ديربورن",
                "label_fr": "Dearborn"
            },
            {
                "value": 6693,
                "label": "Dearborn Heights",
                "label_ar": "ديربورن هايتس",
                "label_fr": "Dearborn Heights"
            },
            {
                "value": 6694,
                "label": "Dearing",
                "label_ar": "عزيز",
                "label_fr": "Audacieux"
            },
            {
                "value": 6695,
                "label": "Death Valley Junction",
                "label_ar": "مفرق وادي الموت",
                "label_fr": "Death Valley Junction"
            },
            {
                "value": 6696,
                "label": "Deatsville",
                "label_ar": "ديتسفيل",
                "label_fr": "Deatsville"
            },
            {
                "value": 6697,
                "label": "Deaver",
                "label_ar": "ديفر",
                "label_fr": "Deaver"
            },
            {
                "value": 6698,
                "label": "Debord",
                "label_ar": "ديبورد",
                "label_fr": "Debord"
            },
            {
                "value": 6699,
                "label": "Decatur",
                "label_ar": "ديكاتور",
                "label_fr": "Decatur"
            },
            {
                "value": 6700,
                "label": "Decaturville",
                "label_ar": "ديكاتورفيل",
                "label_fr": "Decaturville"
            },
            {
                "value": 6701,
                "label": "Decherd",
                "label_ar": "ديكيرد",
                "label_fr": "Decherd"
            },
            {
                "value": 6702,
                "label": "Decker",
                "label_ar": "ديكر",
                "label_fr": "Decker"
            },
            {
                "value": 6703,
                "label": "Deckerville",
                "label_ar": "ديكرفيل",
                "label_fr": "Deckerville"
            },
            {
                "value": 6704,
                "label": "Declo",
                "label_ar": "ديكلو",
                "label_fr": "Declo"
            },
            {
                "value": 6705,
                "label": "Decorah",
                "label_ar": "ديكورا",
                "label_fr": "Decorah"
            },
            {
                "value": 6706,
                "label": "Dedham",
                "label_ar": "ديدهام",
                "label_fr": "Dedham"
            },
            {
                "value": 6707,
                "label": "Deep River",
                "label_ar": "نهر عميق",
                "label_fr": "Rivière profonde"
            },
            {
                "value": 6708,
                "label": "Deep Run",
                "label_ar": "الجري العميق",
                "label_fr": "Course profonde"
            },
            {
                "value": 6709,
                "label": "Deep Water",
                "label_ar": "مياه عميقة",
                "label_fr": "Eaux profondes"
            },
            {
                "value": 6710,
                "label": "Deepwater",
                "label_ar": "مياه عميقة",
                "label_fr": "Eaux profondes"
            },
            {
                "value": 6711,
                "label": "Deer",
                "label_ar": "الغزال",
                "label_fr": "Cerf"
            },
            {
                "value": 6712,
                "label": "Deer Island",
                "label_ar": "جزيرة دير",
                "label_fr": "Deer Island"
            },
            {
                "value": 6713,
                "label": "Deer Isle",
                "label_ar": "دير جزيرة",
                "label_fr": "Ile aux cerfs"
            },
            {
                "value": 6714,
                "label": "Deer Lodge",
                "label_ar": "دير لودج",
                "label_fr": "Deer Lodge"
            },
            {
                "value": 6715,
                "label": "Deer Park",
                "label_ar": "حديقة الغزلان",
                "label_fr": "Parc aux cerfs"
            },
            {
                "value": 6716,
                "label": "Deer River",
                "label_ar": "نهر الغزلان",
                "label_fr": "Deer River"
            },
            {
                "value": 6717,
                "label": "Deer Trail",
                "label_ar": "دير تريل",
                "label_fr": "Sentier des cerfs"
            },
            {
                "value": 6718,
                "label": "Deerfield",
                "label_ar": "ديرفيلد",
                "label_fr": "Deerfield"
            },
            {
                "value": 6719,
                "label": "Deerfield Beach",
                "label_ar": "ديرفيلد بيتش",
                "label_fr": "Deerfield Beach"
            },
            {
                "value": 6720,
                "label": "Deering",
                "label_ar": "الغزلان",
                "label_fr": "Cerf"
            },
            {
                "value": 6721,
                "label": "Deerwood",
                "label_ar": "ديروود",
                "label_fr": "Deerwood"
            },
            {
                "value": 6722,
                "label": "Defiance",
                "label_ar": "التحدي",
                "label_fr": "Défi"
            },
            {
                "value": 6723,
                "label": "Deford",
                "label_ar": "ديفورد",
                "label_fr": "Deford"
            },
            {
                "value": 6724,
                "label": "Del Mar",
                "label_ar": "ديل مار",
                "label_fr": "Del Mar"
            },
            {
                "value": 6725,
                "label": "Del Norte",
                "label_ar": "ديل نورتي",
                "label_fr": "Del Norte"
            },
            {
                "value": 6726,
                "label": "Del Rey",
                "label_ar": "ديل ري",
                "label_fr": "Del Rey"
            },
            {
                "value": 6727,
                "label": "Del Rio",
                "label_ar": "ديل ريو",
                "label_fr": "Del Rio"
            },
            {
                "value": 6728,
                "label": "Del Valle",
                "label_ar": "ديل فالي",
                "label_fr": "Del Valle"
            },
            {
                "value": 6729,
                "label": "Delafield",
                "label_ar": "ديلافيلد",
                "label_fr": "Delafield"
            },
            {
                "value": 6730,
                "label": "Delano",
                "label_ar": "ديلانو",
                "label_fr": "Delano"
            },
            {
                "value": 6731,
                "label": "Delanson",
                "label_ar": "ديلانسون",
                "label_fr": "Delanson"
            },
            {
                "value": 6732,
                "label": "Delaplaine",
                "label_ar": "ديلابلين",
                "label_fr": "Delaplaine"
            },
            {
                "value": 6733,
                "label": "Delavan",
                "label_ar": "ديلافان",
                "label_fr": "Delavan"
            },
            {
                "value": 6734,
                "label": "Delaware",
                "label_ar": "ديلاوير",
                "label_fr": "Delaware"
            },
            {
                "value": 6735,
                "label": "Delaware City",
                "label_ar": "مدينة ديلاوير",
                "label_fr": "Delaware City"
            },
            {
                "value": 6736,
                "label": "Delbarton",
                "label_ar": "دلبارتون",
                "label_fr": "Delbarton"
            },
            {
                "value": 6737,
                "label": "Delcambre",
                "label_ar": "ديلكامبر",
                "label_fr": "Delcambre"
            },
            {
                "value": 6738,
                "label": "Delco",
                "label_ar": "ديلكو",
                "label_fr": "Delco"
            },
            {
                "value": 6739,
                "label": "Delevan",
                "label_ar": "ديليفان",
                "label_fr": "Delevan"
            },
            {
                "value": 6740,
                "label": "Delhi",
                "label_ar": "دلهي",
                "label_fr": "Delhi"
            },
            {
                "value": 6741,
                "label": "Delight",
                "label_ar": "بهجة",
                "label_fr": "Délice"
            },
            {
                "value": 6742,
                "label": "Dell",
                "label_ar": "ديل",
                "label_fr": "Dell"
            },
            {
                "value": 6743,
                "label": "Dell Rapids",
                "label_ar": "ديل رابيدز",
                "label_fr": "Dell Rapids"
            },
            {
                "value": 6744,
                "label": "Delmar",
                "label_ar": "ديل مار",
                "label_fr": "Delmar"
            },
            {
                "value": 6745,
                "label": "Delmont",
                "label_ar": "دلمونت",
                "label_fr": "Delmont"
            },
            {
                "value": 6746,
                "label": "Deloit",
                "label_ar": "ديلويت",
                "label_fr": "Deloit"
            },
            {
                "value": 6747,
                "label": "Delphi",
                "label_ar": "دلفي",
                "label_fr": "Delphes"
            },
            {
                "value": 6748,
                "label": "Delphos",
                "label_ar": "دلفوس",
                "label_fr": "Delphos"
            },
            {
                "value": 6749,
                "label": "Delray",
                "label_ar": "ديلراي",
                "label_fr": "Delray"
            },
            {
                "value": 6750,
                "label": "Delray Beach",
                "label_ar": "شاطئ ديلراي",
                "label_fr": "Plage de Delray"
            },
            {
                "value": 6751,
                "label": "Delta",
                "label_ar": "دلتا",
                "label_fr": "Delta"
            },
            {
                "value": 6752,
                "label": "Deltana",
                "label_ar": "دلتانا",
                "label_fr": "Deltana"
            },
            {
                "value": 6753,
                "label": "Deltaville",
                "label_ar": "ديلتافيل",
                "label_fr": "Deltaville"
            },
            {
                "value": 6754,
                "label": "Delton",
                "label_ar": "دلتون",
                "label_fr": "Delton"
            },
            {
                "value": 6755,
                "label": "Deltona",
                "label_ar": "دلتونا",
                "label_fr": "Deltona"
            },
            {
                "value": 6756,
                "label": "Dema",
                "label_ar": "ديما",
                "label_fr": "Dema"
            },
            {
                "value": 6757,
                "label": "Demarest",
                "label_ar": "ديماريست",
                "label_fr": "Demarest"
            },
            {
                "value": 6758,
                "label": "Deming",
                "label_ar": "دمينغ",
                "label_fr": "Deming"
            },
            {
                "value": 6759,
                "label": "Demopolis",
                "label_ar": "ديموبوليس",
                "label_fr": "Demopolis"
            },
            {
                "value": 6760,
                "label": "Demorest",
                "label_ar": "أضعف",
                "label_fr": "Demorest"
            },
            {
                "value": 6761,
                "label": "Denair",
                "label_ar": "دينير",
                "label_fr": "Denair"
            },
            {
                "value": 6762,
                "label": "Dendron",
                "label_ar": "دندرون",
                "label_fr": "Dendron"
            },
            {
                "value": 6763,
                "label": "Denham Springs",
                "label_ar": "دنهام سبرينجز",
                "label_fr": "Denham Springs"
            },
            {
                "value": 6764,
                "label": "Denison",
                "label_ar": "دينيسون",
                "label_fr": "Denison"
            },
            {
                "value": 6765,
                "label": "Denmark",
                "label_ar": "الدنمارك",
                "label_fr": "Danemark"
            },
            {
                "value": 6766,
                "label": "Dennis",
                "label_ar": "دينيس",
                "label_fr": "Dennis"
            },
            {
                "value": 6767,
                "label": "Dennis Port",
                "label_ar": "ميناء دينيس",
                "label_fr": "Port de Dennis"
            },
            {
                "value": 6768,
                "label": "Dennison",
                "label_ar": "دينيسون",
                "label_fr": "Dennison"
            },
            {
                "value": 6769,
                "label": "Denniston",
                "label_ar": "دينيستون",
                "label_fr": "Denniston"
            },
            {
                "value": 6770,
                "label": "Dennysville",
                "label_ar": "دينيسفيل",
                "label_fr": "Dennysville"
            },
            {
                "value": 6771,
                "label": "Dent",
                "label_ar": "صدمه خفيفه",
                "label_fr": "Bosse"
            },
            {
                "value": 6772,
                "label": "Denton",
                "label_ar": "دنتون",
                "label_fr": "Denton"
            },
            {
                "value": 6773,
                "label": "Denver",
                "label_ar": "دنفر",
                "label_fr": "Denver"
            },
            {
                "value": 6774,
                "label": "Denver City",
                "label_ar": "دنفر سيتي",
                "label_fr": "Denver City"
            },
            {
                "value": 6775,
                "label": "Denville",
                "label_ar": "دنفيل",
                "label_fr": "Denville"
            },
            {
                "value": 6776,
                "label": "Depauw",
                "label_ar": "ديباو",
                "label_fr": "Depauw"
            },
            {
                "value": 6777,
                "label": "Depere",
                "label_ar": "ديبير",
                "label_fr": "Depere"
            },
            {
                "value": 6778,
                "label": "Depew",
                "label_ar": "ديبو",
                "label_fr": "Depew"
            },
            {
                "value": 6779,
                "label": "Depoe Bay",
                "label_ar": "خليج ديبو",
                "label_fr": "Depoe Bay"
            },
            {
                "value": 6780,
                "label": "Deposit",
                "label_ar": "الوديعة",
                "label_fr": "Dépôt"
            },
            {
                "value": 6781,
                "label": "Depue",
                "label_ar": "ديبو",
                "label_fr": "Depue"
            },
            {
                "value": 6782,
                "label": "Deputy",
                "label_ar": "نائب",
                "label_fr": "Adjoint"
            },
            {
                "value": 6783,
                "label": "Derby",
                "label_ar": "دربي",
                "label_fr": "Derby"
            },
            {
                "value": 6784,
                "label": "Derby Center",
                "label_ar": "مركز ديربي",
                "label_fr": "Derby Center"
            },
            {
                "value": 6785,
                "label": "Derby Line",
                "label_ar": "ديربي لاين",
                "label_fr": "Ligne Derby"
            },
            {
                "value": 6786,
                "label": "Derma",
                "label_ar": "ديرما",
                "label_fr": "Derma"
            },
            {
                "value": 6787,
                "label": "Dermott",
                "label_ar": "ديرموت",
                "label_fr": "Dermott"
            },
            {
                "value": 6788,
                "label": "Derry",
                "label_ar": "ديري",
                "label_fr": "Derry"
            },
            {
                "value": 6789,
                "label": "Derwood",
                "label_ar": "ديروود",
                "label_fr": "Derwood"
            },
            {
                "value": 6790,
                "label": "Des Allemands",
                "label_ar": "ديس ألماندس",
                "label_fr": "Des Allemands"
            },
            {
                "value": 6791,
                "label": "Des Arc",
                "label_ar": "ديس آرك",
                "label_fr": "Des Arc"
            },
            {
                "value": 6792,
                "label": "Des Moines",
                "label_ar": "دي موين",
                "label_fr": "des moines"
            },
            {
                "value": 6793,
                "label": "Des Plaines",
                "label_ar": "ديس بلاينز",
                "label_fr": "Des Plaines"
            },
            {
                "value": 6794,
                "label": "Descanso",
                "label_ar": "ديسكانسو",
                "label_fr": "Descanso"
            },
            {
                "value": 6795,
                "label": "Desert Hot Springs",
                "label_ar": "ينابيع الصحراء الحارة",
                "label_fr": "Sources chaudes du désert"
            },
            {
                "value": 6796,
                "label": "Deshler",
                "label_ar": "ديشلر",
                "label_fr": "Deshler"
            },
            {
                "value": 6797,
                "label": "Destin",
                "label_ar": "ديستين",
                "label_fr": "Destin"
            },
            {
                "value": 6798,
                "label": "Destrehan",
                "label_ar": "ديستريهان",
                "label_fr": "Destrehan"
            },
            {
                "value": 6799,
                "label": "Detroit",
                "label_ar": "ديترويت",
                "label_fr": "Détroit"
            },
            {
                "value": 6800,
                "label": "Detroit Lakes",
                "label_ar": "ديترويت ليكس",
                "label_fr": "Lacs de Detroit"
            },
            {
                "value": 6801,
                "label": "Devens",
                "label_ar": "ديفينس",
                "label_fr": "Devens"
            },
            {
                "value": 6802,
                "label": "Deville",
                "label_ar": "ديفيل",
                "label_fr": "Deville"
            },
            {
                "value": 6803,
                "label": "Devils Lake",
                "label_ar": "بحيرة الشياطين",
                "label_fr": "Devils Lake"
            },
            {
                "value": 6804,
                "label": "Devine",
                "label_ar": "ديفين",
                "label_fr": "Devine"
            },
            {
                "value": 6805,
                "label": "Devon",
                "label_ar": "ديفون",
                "label_fr": "Devon"
            },
            {
                "value": 6806,
                "label": "Dewar",
                "label_ar": "ديوار",
                "label_fr": "Dewar"
            },
            {
                "value": 6807,
                "label": "Dewey",
                "label_ar": "ديوي",
                "label_fr": "Dewey"
            },
            {
                "value": 6808,
                "label": "Dewy Rose",
                "label_ar": "روز ديوي",
                "label_fr": "Rose rosée"
            },
            {
                "value": 6809,
                "label": "Dexter",
                "label_ar": "دكستر",
                "label_fr": "Dexter"
            },
            {
                "value": 6810,
                "label": "Dexter City",
                "label_ar": "دكستر سيتي",
                "label_fr": "Dexter City"
            },
            {
                "value": 6811,
                "label": "Diablo",
                "label_ar": "ديابلو",
                "label_fr": "Diablo"
            },
            {
                "value": 6812,
                "label": "Diagonal",
                "label_ar": "قطري",
                "label_fr": "Diagonale"
            },
            {
                "value": 6813,
                "label": "Diamond",
                "label_ar": "الماس",
                "label_fr": "diamant"
            },
            {
                "value": 6814,
                "label": "Diamond Bar",
                "label_ar": "شريط الماس",
                "label_fr": "Barre de diamant"
            },
            {
                "value": 6815,
                "label": "Diamond Point",
                "label_ar": "دايموند بوينت",
                "label_fr": "Point de diamant"
            },
            {
                "value": 6816,
                "label": "Diamond Springs",
                "label_ar": "دايموند سبرينغز",
                "label_fr": "Diamond Springs"
            },
            {
                "value": 6817,
                "label": "Diamondhead",
                "label_ar": "دايموندهيد",
                "label_fr": "Diamondhead"
            },
            {
                "value": 6818,
                "label": "Diamondville",
                "label_ar": "دياموندفيل",
                "label_fr": "Diamondville"
            },
            {
                "value": 6819,
                "label": "Diana",
                "label_ar": "ديانا",
                "label_fr": "Diane"
            },
            {
                "value": 6820,
                "label": "Diboll",
                "label_ar": "ديبول",
                "label_fr": "Diboll"
            },
            {
                "value": 6821,
                "label": "Dickerson",
                "label_ar": "ديكرسون",
                "label_fr": "Dickerson"
            },
            {
                "value": 6822,
                "label": "Dickeyville",
                "label_ar": "ديكيفيل",
                "label_fr": "Dickeyville"
            },
            {
                "value": 6823,
                "label": "Dickinson",
                "label_ar": "ديكنسون",
                "label_fr": "Dickinson"
            },
            {
                "value": 6824,
                "label": "Dickinson Center",
                "label_ar": "مركز ديكنسون",
                "label_fr": "Centre Dickinson"
            },
            {
                "value": 6825,
                "label": "Dickson",
                "label_ar": "ديكسون",
                "label_fr": "Dickson"
            },
            {
                "value": 6826,
                "label": "Dickson City",
                "label_ar": "ديكسون سيتي",
                "label_fr": "Dickson City"
            },
            {
                "value": 6827,
                "label": "Dierks",
                "label_ar": "ديركس",
                "label_fr": "Dierks"
            },
            {
                "value": 6828,
                "label": "Dieterich",
                "label_ar": "ديتريش",
                "label_fr": "Dieterich"
            },
            {
                "value": 6829,
                "label": "Dietrich",
                "label_ar": "ديتريش",
                "label_fr": "Dietrich"
            },
            {
                "value": 6830,
                "label": "Diggs",
                "label_ar": "ديجز",
                "label_fr": "Diggs"
            },
            {
                "value": 6831,
                "label": "Dighton",
                "label_ar": "دايتون",
                "label_fr": "Dighton"
            },
            {
                "value": 6832,
                "label": "Dill City",
                "label_ar": "مدينة الشبت",
                "label_fr": "Ville d'aneth"
            },
            {
                "value": 6833,
                "label": "Dillard",
                "label_ar": "ديلارد",
                "label_fr": "Dillard"
            },
            {
                "value": 6834,
                "label": "Diller",
                "label_ar": "ديلر",
                "label_fr": "Diller"
            },
            {
                "value": 6835,
                "label": "Dilley",
                "label_ar": "ديلي",
                "label_fr": "Dilley"
            },
            {
                "value": 6836,
                "label": "Dillingham",
                "label_ar": "ديلينجهام",
                "label_fr": "Dillingham"
            },
            {
                "value": 6837,
                "label": "Dillon",
                "label_ar": "ديلون",
                "label_fr": "Dillon"
            },
            {
                "value": 6838,
                "label": "Dillon Beach",
                "label_ar": "شاطئ ديلون",
                "label_fr": "Plage de Dillon"
            },
            {
                "value": 6839,
                "label": "Dillonvale",
                "label_ar": "ديلونفيل",
                "label_fr": "Dillonvale"
            },
            {
                "value": 6840,
                "label": "Dillsboro",
                "label_ar": "ديلسبورو",
                "label_fr": "Dillsboro"
            },
            {
                "value": 6841,
                "label": "Dillsburg",
                "label_ar": "ديلسبورغ",
                "label_fr": "Dillsburg"
            },
            {
                "value": 6842,
                "label": "Dillwyn",
                "label_ar": "ديلوين",
                "label_fr": "Dillwyn"
            },
            {
                "value": 6843,
                "label": "Dime Box",
                "label_ar": "صندوق الدايم",
                "label_fr": "Boîte de dix cents"
            },
            {
                "value": 6844,
                "label": "Dimmitt",
                "label_ar": "خافت",
                "label_fr": "Dimmitt"
            },
            {
                "value": 6845,
                "label": "Dimock",
                "label_ar": "ديموك",
                "label_fr": "Dimock"
            },
            {
                "value": 6846,
                "label": "Dimondale",
                "label_ar": "ديموندال",
                "label_fr": "Dimondale"
            },
            {
                "value": 6847,
                "label": "Dingess",
                "label_ar": "دينغس",
                "label_fr": "Dingess"
            },
            {
                "value": 6848,
                "label": "Dingmans Ferry",
                "label_ar": "Dingmans العبارة",
                "label_fr": "Ferry Dingmans"
            },
            {
                "value": 6849,
                "label": "Dinuba",
                "label_ar": "دينوبا",
                "label_fr": "Dinuba"
            },
            {
                "value": 6850,
                "label": "Dinwiddie",
                "label_ar": "دينويدي",
                "label_fr": "Dinwiddie"
            },
            {
                "value": 6851,
                "label": "Discovery Bay",
                "label_ar": "اكتشاف خليج",
                "label_fr": "Baie de découverte"
            },
            {
                "value": 6852,
                "label": "Disputanta",
                "label_ar": "ديسبوتانتا",
                "label_fr": "Disputanta"
            },
            {
                "value": 6853,
                "label": "Distant",
                "label_ar": "بعيد",
                "label_fr": "Loin"
            },
            {
                "value": 6854,
                "label": "District Heights",
                "label_ar": "مرتفعات الحي",
                "label_fr": "District Heights"
            },
            {
                "value": 6855,
                "label": "Dittmer",
                "label_ar": "ديتمير",
                "label_fr": "Dittmer"
            },
            {
                "value": 6856,
                "label": "Divernon",
                "label_ar": "ديفرنون",
                "label_fr": "Divernon"
            },
            {
                "value": 6857,
                "label": "Divide",
                "label_ar": "يقسم",
                "label_fr": "Diviser"
            },
            {
                "value": 6858,
                "label": "Dividing Creek",
                "label_ar": "تقسيم الخور",
                "label_fr": "Ruisseau Dividing"
            },
            {
                "value": 6859,
                "label": "Dix",
                "label_ar": "ديكس",
                "label_fr": "Dix"
            },
            {
                "value": 6860,
                "label": "Dixfield",
                "label_ar": "ديكسفيلد",
                "label_fr": "Dixfield"
            },
            {
                "value": 6861,
                "label": "Dixie",
                "label_ar": "ديكسي",
                "label_fr": "Gamelle"
            },
            {
                "value": 6862,
                "label": "Dixmont",
                "label_ar": "ديكسمونت",
                "label_fr": "Dixmont"
            },
            {
                "value": 6863,
                "label": "Dixon",
                "label_ar": "ديكسون",
                "label_fr": "Dixon"
            },
            {
                "value": 6864,
                "label": "Dixon Springs",
                "label_ar": "ديكسون سبرينغز",
                "label_fr": "Dixon Springs"
            },
            {
                "value": 6865,
                "label": "Dixons Mills",
                "label_ar": "ديكسون ميلز",
                "label_fr": "Dixons Mills"
            },
            {
                "value": 6866,
                "label": "Dixonville",
                "label_ar": "ديكسونفيل",
                "label_fr": "Dixonville"
            },
            {
                "value": 6867,
                "label": "Dobbs Ferry",
                "label_ar": "دوبس فيري",
                "label_fr": "Dobbs Ferry"
            },
            {
                "value": 6868,
                "label": "Dobson",
                "label_ar": "دوبسون",
                "label_fr": "Dobson"
            },
            {
                "value": 6869,
                "label": "Docena",
                "label_ar": "دوسينا",
                "label_fr": "Docena"
            },
            {
                "value": 6870,
                "label": "Dodd City",
                "label_ar": "مدينة دود",
                "label_fr": "Dodd City"
            },
            {
                "value": 6871,
                "label": "Doddridge",
                "label_ar": "دودريدج",
                "label_fr": "Doddridge"
            },
            {
                "value": 6872,
                "label": "Dodge",
                "label_ar": "يتملص",
                "label_fr": "Esquive"
            },
            {
                "value": 6873,
                "label": "Dodge Center",
                "label_ar": "مركز دودج",
                "label_fr": "Centre Dodge"
            },
            {
                "value": 6874,
                "label": "Dodge City",
                "label_ar": "دودج سيتي",
                "label_fr": "Dodge City"
            },
            {
                "value": 6875,
                "label": "Dodgeville",
                "label_ar": "دودجفيل",
                "label_fr": "Dodgeville"
            },
            {
                "value": 6876,
                "label": "Dodgewood",
                "label_ar": "دودجوود",
                "label_fr": "Dodgewood"
            },
            {
                "value": 6877,
                "label": "Doerun",
                "label_ar": "دورون",
                "label_fr": "Doerun"
            },
            {
                "value": 6878,
                "label": "Dolan Springs",
                "label_ar": "دولان سبرينجز",
                "label_fr": "Dolan Springs"
            },
            {
                "value": 6879,
                "label": "Doland",
                "label_ar": "دولاند",
                "label_fr": "Doland"
            },
            {
                "value": 6880,
                "label": "Dolgeville",
                "label_ar": "دولجفيل",
                "label_fr": "Dolgeville"
            },
            {
                "value": 6881,
                "label": "Dollar Bay",
                "label_ar": "خليج الدولار",
                "label_fr": "Dollar Bay"
            },
            {
                "value": 6882,
                "label": "Dolliver",
                "label_ar": "دوليفر",
                "label_fr": "Dolliver"
            },
            {
                "value": 6883,
                "label": "Dolomite",
                "label_ar": "الدولوميت",
                "label_fr": "Dolomie"
            },
            {
                "value": 6884,
                "label": "Dolores",
                "label_ar": "دولوريس",
                "label_fr": "Dolores"
            },
            {
                "value": 6885,
                "label": "Dolphin",
                "label_ar": "دولفين",
                "label_fr": "Dauphin"
            },
            {
                "value": 6886,
                "label": "Dolton",
                "label_ar": "دولتون",
                "label_fr": "Dolton"
            },
            {
                "value": 6887,
                "label": "Donald",
                "label_ar": "دونالد",
                "label_fr": "Donald"
            },
            {
                "value": 6888,
                "label": "Donalds",
                "label_ar": "دونالد",
                "label_fr": "Donalds"
            },
            {
                "value": 6889,
                "label": "Donaldson",
                "label_ar": "دونالدسون",
                "label_fr": "Donaldson"
            },
            {
                "value": 6890,
                "label": "Donaldsonville",
                "label_ar": "دونالدسونفيل",
                "label_fr": "Donaldsonville"
            },
            {
                "value": 6891,
                "label": "Donalsonville",
                "label_ar": "دونالسونفيل",
                "label_fr": "Donalsonville"
            },
            {
                "value": 6892,
                "label": "Donegal",
                "label_ar": "دونيجال",
                "label_fr": "Donegal"
            },
            {
                "value": 6893,
                "label": "Dongola",
                "label_ar": "دنقلا",
                "label_fr": "Dongola"
            },
            {
                "value": 6894,
                "label": "Donie",
                "label_ar": "دوني",
                "label_fr": "Donie"
            },
            {
                "value": 6895,
                "label": "Doniphan",
                "label_ar": "دونيفان",
                "label_fr": "Doniphan"
            },
            {
                "value": 6896,
                "label": "Donna",
                "label_ar": "دونا",
                "label_fr": "Donna"
            },
            {
                "value": 6897,
                "label": "Donnellson",
                "label_ar": "دونيلسون",
                "label_fr": "Donnellson"
            },
            {
                "value": 6898,
                "label": "Donnelly",
                "label_ar": "دونيلي",
                "label_fr": "Donnelly"
            },
            {
                "value": 6899,
                "label": "Donnelsville",
                "label_ar": "دونلسفيل",
                "label_fr": "Donnelsville"
            },
            {
                "value": 6900,
                "label": "Donner",
                "label_ar": "دونر",
                "label_fr": "Donner"
            },
            {
                "value": 6901,
                "label": "Donora",
                "label_ar": "دونورا",
                "label_fr": "Donora"
            },
            {
                "value": 6902,
                "label": "Donovan",
                "label_ar": "دونوفان",
                "label_fr": "Donovan"
            },
            {
                "value": 6903,
                "label": "Doon",
                "label_ar": "دون",
                "label_fr": "Doon"
            },
            {
                "value": 6904,
                "label": "Dora",
                "label_ar": "درة",
                "label_fr": "Dora"
            },
            {
                "value": 6905,
                "label": "Dorchester",
                "label_ar": "دورتشستر",
                "label_fr": "Dorchester"
            },
            {
                "value": 6906,
                "label": "Dorena",
                "label_ar": "دورينا",
                "label_fr": "Dorena"
            },
            {
                "value": 6907,
                "label": "Dornsife",
                "label_ar": "دورنسيف",
                "label_fr": "Dornsife"
            },
            {
                "value": 6908,
                "label": "Dorothy",
                "label_ar": "دوروثي",
                "label_fr": "Dorothy"
            },
            {
                "value": 6909,
                "label": "Dorr",
                "label_ar": "دور",
                "label_fr": "Dorr"
            },
            {
                "value": 6910,
                "label": "Dorrance",
                "label_ar": "دورانس",
                "label_fr": "Dorrance"
            },
            {
                "value": 6911,
                "label": "Dorris",
                "label_ar": "دوريس",
                "label_fr": "Dorris"
            },
            {
                "value": 6912,
                "label": "Dorset",
                "label_ar": "دورست",
                "label_fr": "Dorset"
            },
            {
                "value": 6913,
                "label": "Dorsey",
                "label_ar": "دورسي",
                "label_fr": "Dorsey"
            },
            {
                "value": 6914,
                "label": "Dos Palos",
                "label_ar": "دوس بالوس",
                "label_fr": "Dos Palos"
            },
            {
                "value": 6915,
                "label": "Doss",
                "label_ar": "دوس",
                "label_fr": "Doss"
            },
            {
                "value": 6916,
                "label": "Doswell",
                "label_ar": "دوزويل",
                "label_fr": "Doswell"
            },
            {
                "value": 6917,
                "label": "Dothan",
                "label_ar": "دوثان",
                "label_fr": "Dothan"
            },
            {
                "value": 6918,
                "label": "Double Springs",
                "label_ar": "نوابض مزدوجة",
                "label_fr": "Ressorts doubles"
            },
            {
                "value": 6919,
                "label": "Dougherty",
                "label_ar": "دوجيرتي",
                "label_fr": "Dougherty"
            },
            {
                "value": 6920,
                "label": "Douglas",
                "label_ar": "دوغلاس",
                "label_fr": "Douglas"
            },
            {
                "value": 6921,
                "label": "Douglas City",
                "label_ar": "مدينة دوجلاس",
                "label_fr": "Douglas City"
            },
            {
                "value": 6922,
                "label": "Douglass",
                "label_ar": "دوغلاس",
                "label_fr": "Douglass"
            },
            {
                "value": 6923,
                "label": "Douglassville",
                "label_ar": "دوغلاسفيل",
                "label_fr": "Douglassville"
            },
            {
                "value": 6924,
                "label": "Douglasville",
                "label_ar": "دوغلاسفيل",
                "label_fr": "Douglasville"
            },
            {
                "value": 6925,
                "label": "Dousman",
                "label_ar": "دوسمان",
                "label_fr": "Dousman"
            },
            {
                "value": 6926,
                "label": "Dover",
                "label_ar": "دوفر",
                "label_fr": "Douvres"
            },
            {
                "value": 6927,
                "label": "Dover Plains",
                "label_ar": "دوفر بلينز",
                "label_fr": "Plaines de Douvres"
            },
            {
                "value": 6928,
                "label": "Dover-Foxcroft",
                "label_ar": "دوفر فوكسكروفت",
                "label_fr": "Douvres-Foxcroft"
            },
            {
                "value": 6929,
                "label": "Dow",
                "label_ar": "داو",
                "label_fr": "Dow"
            },
            {
                "value": 6930,
                "label": "Dowagiac",
                "label_ar": "دواجياك",
                "label_fr": "Dowagiac"
            },
            {
                "value": 6931,
                "label": "Dowell",
                "label_ar": "دويل",
                "label_fr": "Dowell"
            },
            {
                "value": 6932,
                "label": "Dowelltown",
                "label_ar": "دويلتاون",
                "label_fr": "Dowelltown"
            },
            {
                "value": 6933,
                "label": "Dowling",
                "label_ar": "داولينج",
                "label_fr": "Dowling"
            },
            {
                "value": 6934,
                "label": "Downers Grove",
                "label_ar": "داونرز جروف",
                "label_fr": "Downers Grove"
            },
            {
                "value": 6935,
                "label": "Downey",
                "label_ar": "داوني",
                "label_fr": "Downey"
            },
            {
                "value": 6936,
                "label": "Downieville",
                "label_ar": "داونيفيل",
                "label_fr": "Downieville"
            },
            {
                "value": 6937,
                "label": "Downingtown",
                "label_ar": "داونينجتاون",
                "label_fr": "Downingtown"
            },
            {
                "value": 6938,
                "label": "Downs",
                "label_ar": "هبوط",
                "label_fr": "Downs"
            },
            {
                "value": 6939,
                "label": "Downsville",
                "label_ar": "داونزفيل",
                "label_fr": "Downsville"
            },
            {
                "value": 6940,
                "label": "Dows",
                "label_ar": "داووس",
                "label_fr": "Dows"
            },
            {
                "value": 6941,
                "label": "Doyle",
                "label_ar": "دويل",
                "label_fr": "Doyle"
            },
            {
                "value": 6942,
                "label": "Doylestown",
                "label_ar": "Doylestown",
                "label_fr": "Doylestown"
            },
            {
                "value": 6943,
                "label": "Doyline",
                "label_ar": "دويلين",
                "label_fr": "Doyline"
            },
            {
                "value": 6944,
                "label": "Dozier",
                "label_ar": "دوزير",
                "label_fr": "Dozier"
            },
            {
                "value": 6945,
                "label": "Dracut",
                "label_ar": "دراكوت",
                "label_fr": "Dracut"
            },
            {
                "value": 6946,
                "label": "Dragoon",
                "label_ar": "دراجون",
                "label_fr": "Dragon"
            },
            {
                "value": 6947,
                "label": "Drain",
                "label_ar": "تصرف",
                "label_fr": "Drainer"
            },
            {
                "value": 6948,
                "label": "Drakes Branch",
                "label_ar": "فرع دريكس",
                "label_fr": "Branche de Drakes"
            },
            {
                "value": 6949,
                "label": "Drakesboro",
                "label_ar": "دراكسبورو",
                "label_fr": "Drakesboro"
            },
            {
                "value": 6950,
                "label": "Draper",
                "label_ar": "درابر",
                "label_fr": "Draper"
            },
            {
                "value": 6951,
                "label": "Drasco",
                "label_ar": "دراسكو",
                "label_fr": "Drasco"
            },
            {
                "value": 6952,
                "label": "Dravosburg",
                "label_ar": "درافوسبرج",
                "label_fr": "Dravosburg"
            },
            {
                "value": 6953,
                "label": "Drayden",
                "label_ar": "درايدن",
                "label_fr": "Drayden"
            },
            {
                "value": 6954,
                "label": "Dresden",
                "label_ar": "دريسدن",
                "label_fr": "Dresde"
            },
            {
                "value": 6955,
                "label": "Dresher",
                "label_ar": "دريشر",
                "label_fr": "Dresher"
            },
            {
                "value": 6956,
                "label": "Dresser",
                "label_ar": "مضمد",
                "label_fr": "Une commode"
            },
            {
                "value": 6957,
                "label": "Drewryville",
                "label_ar": "دروريفيل",
                "label_fr": "Drewryville"
            },
            {
                "value": 6958,
                "label": "Drexel",
                "label_ar": "دريكسيل",
                "label_fr": "Drexel"
            },
            {
                "value": 6959,
                "label": "Drexel Hill",
                "label_ar": "دريكسيل هيل",
                "label_fr": "Drexel Hill"
            },
            {
                "value": 6960,
                "label": "Drift",
                "label_ar": "المغزى",
                "label_fr": "Dérive"
            },
            {
                "value": 6961,
                "label": "Drifting",
                "label_ar": "الانجراف",
                "label_fr": "Dérive"
            },
            {
                "value": 6962,
                "label": "Driftwood",
                "label_ar": "دريفتوود",
                "label_fr": "Bois flotté"
            },
            {
                "value": 6963,
                "label": "Driggs",
                "label_ar": "دريجز",
                "label_fr": "Driggs"
            },
            {
                "value": 6964,
                "label": "Dripping Springs",
                "label_ar": "تقطر الينابيع",
                "label_fr": "Ressorts dégoulinants"
            },
            {
                "value": 6965,
                "label": "Driscoll",
                "label_ar": "دريسكول",
                "label_fr": "Driscoll"
            },
            {
                "value": 6966,
                "label": "Drummond",
                "label_ar": "دروموند",
                "label_fr": "Drummond"
            },
            {
                "value": 6967,
                "label": "Drummonds",
                "label_ar": "دراموند",
                "label_fr": "Drummonds"
            },
            {
                "value": 6968,
                "label": "Drumore",
                "label_ar": "درامور",
                "label_fr": "Drumore"
            },
            {
                "value": 6969,
                "label": "Drumright",
                "label_ar": "طبل",
                "label_fr": "Drumright"
            },
            {
                "value": 6970,
                "label": "Drums",
                "label_ar": "طبول",
                "label_fr": "Tambours"
            },
            {
                "value": 6971,
                "label": "Dry Branch",
                "label_ar": "فرع جاف",
                "label_fr": "Branche sèche"
            },
            {
                "value": 6972,
                "label": "Dry Creek",
                "label_ar": "جاف كريك",
                "label_fr": "Dry Creek"
            },
            {
                "value": 6973,
                "label": "Dry Fork",
                "label_ar": "شوكة جافة",
                "label_fr": "Fourche sèche"
            },
            {
                "value": 6974,
                "label": "Dry Prong",
                "label_ar": "الشق الجاف",
                "label_fr": "Broche sèche"
            },
            {
                "value": 6975,
                "label": "Dry Ridge",
                "label_ar": "ريدج جاف",
                "label_fr": "Dry Ridge"
            },
            {
                "value": 6976,
                "label": "Dry Run",
                "label_ar": "ركض جاف",
                "label_fr": "Course à sec"
            },
            {
                "value": 6977,
                "label": "Drybranch",
                "label_ar": "درايبرانش",
                "label_fr": "Drybranch"
            },
            {
                "value": 6978,
                "label": "Dryden",
                "label_ar": "درايدن",
                "label_fr": "Dryden"
            },
            {
                "value": 6979,
                "label": "Dryfork",
                "label_ar": "Dryfork",
                "label_fr": "Fourche sèche"
            },
            {
                "value": 6980,
                "label": "Du Bois",
                "label_ar": "دو بوا",
                "label_fr": "Du Bois"
            },
            {
                "value": 6981,
                "label": "Du Quoin",
                "label_ar": "دو كوين",
                "label_fr": "Du Quoin"
            },
            {
                "value": 6982,
                "label": "DuBois",
                "label_ar": "دوبوا",
                "label_fr": "DuBois"
            },
            {
                "value": 6983,
                "label": "DuPont",
                "label_ar": "دوبونت",
                "label_fr": "DuPont"
            },
            {
                "value": 6984,
                "label": "Duanesburg",
                "label_ar": "دوانسبورغ",
                "label_fr": "Duanesburg"
            },
            {
                "value": 6985,
                "label": "Duarte",
                "label_ar": "دوارتي",
                "label_fr": "Duarte"
            },
            {
                "value": 6986,
                "label": "Dubach",
                "label_ar": "دوباخ",
                "label_fr": "Dubach"
            },
            {
                "value": 6987,
                "label": "Dublin",
                "label_ar": "دبلن",
                "label_fr": "Dublin"
            },
            {
                "value": 6988,
                "label": "Dubois",
                "label_ar": "دوبوا",
                "label_fr": "Dubois"
            },
            {
                "value": 6989,
                "label": "Dubuque",
                "label_ar": "دوبوك",
                "label_fr": "Dubuque"
            },
            {
                "value": 6990,
                "label": "Duchesne",
                "label_ar": "دوتشيسن",
                "label_fr": "Duchesne"
            },
            {
                "value": 6991,
                "label": "Duck",
                "label_ar": "بطة",
                "label_fr": "canard"
            },
            {
                "value": 6992,
                "label": "Duck Creek Village",
                "label_ar": "قرية دك كريك",
                "label_fr": "Village de Duck Creek"
            },
            {
                "value": 6993,
                "label": "Duck Hill",
                "label_ar": "بطة هيل",
                "label_fr": "Duck Hill"
            },
            {
                "value": 6994,
                "label": "Ducktown",
                "label_ar": "دكتاون",
                "label_fr": "Ducktown"
            },
            {
                "value": 6995,
                "label": "Dudley",
                "label_ar": "دودلي",
                "label_fr": "Dudley"
            },
            {
                "value": 6996,
                "label": "Due West",
                "label_ar": "غربا",
                "label_fr": "Vers l'ouest"
            },
            {
                "value": 6997,
                "label": "Duff",
                "label_ar": "داف",
                "label_fr": "Duff"
            },
            {
                "value": 6998,
                "label": "Duffield",
                "label_ar": "دافيلد",
                "label_fr": "Duffield"
            },
            {
                "value": 6999,
                "label": "Dufur",
                "label_ar": "دوفور",
                "label_fr": "Dufur"
            },
            {
                "value": 7000,
                "label": "Dugger",
                "label_ar": "الحفار",
                "label_fr": "Dugger"
            },
            {
                "value": 7001,
                "label": "Dugway",
                "label_ar": "دوغواي",
                "label_fr": "Dugway"
            },
            {
                "value": 7002,
                "label": "Duke",
                "label_ar": "دوق",
                "label_fr": "Duc"
            },
            {
                "value": 7003,
                "label": "Duke Center",
                "label_ar": "مركز ديوك",
                "label_fr": "Duke Center"
            },
            {
                "value": 7004,
                "label": "Dukedom",
                "label_ar": "دوقية",
                "label_fr": "Duché"
            },
            {
                "value": 7005,
                "label": "Dulac",
                "label_ar": "دولاك",
                "label_fr": "Dulac"
            },
            {
                "value": 7006,
                "label": "Dulce",
                "label_ar": "دولسي",
                "label_fr": "Dulce"
            },
            {
                "value": 7007,
                "label": "Dulles",
                "label_ar": "دالاس",
                "label_fr": "Dulles"
            },
            {
                "value": 7008,
                "label": "Duluth",
                "label_ar": "دولوث",
                "label_fr": "Duluth"
            },
            {
                "value": 7009,
                "label": "Dulzura",
                "label_ar": "دولزورا",
                "label_fr": "Dulzura"
            },
            {
                "value": 7010,
                "label": "Dumas",
                "label_ar": "دوما",
                "label_fr": "Dumas"
            },
            {
                "value": 7011,
                "label": "Dumfries",
                "label_ar": "دومفريز",
                "label_fr": "Dumfries"
            },
            {
                "value": 7012,
                "label": "Dumont",
                "label_ar": "دومون",
                "label_fr": "Dumont"
            },
            {
                "value": 7013,
                "label": "Dunbar",
                "label_ar": "دنبار",
                "label_fr": "Dunbar"
            },
            {
                "value": 7014,
                "label": "Dunbarton Center",
                "label_ar": "مركز دانبارتون",
                "label_fr": "Centre Dunbarton"
            },
            {
                "value": 7015,
                "label": "Duncan",
                "label_ar": "دنكان",
                "label_fr": "Duncan"
            },
            {
                "value": 7016,
                "label": "Duncannon",
                "label_ar": "دنكنون",
                "label_fr": "Duncannon"
            },
            {
                "value": 7017,
                "label": "Duncans Mills",
                "label_ar": "دنكانز ميلز",
                "label_fr": "Duncans Mills"
            },
            {
                "value": 7018,
                "label": "Duncansville",
                "label_ar": "دنكانسفيل",
                "label_fr": "Duncansville"
            },
            {
                "value": 7019,
                "label": "Duncanville",
                "label_ar": "دنكانفيل",
                "label_fr": "Duncanville"
            },
            {
                "value": 7020,
                "label": "Duncombe",
                "label_ar": "دونكومب",
                "label_fr": "Duncombe"
            },
            {
                "value": 7021,
                "label": "Dundalk",
                "label_ar": "دوندالك",
                "label_fr": "Dundalk"
            },
            {
                "value": 7022,
                "label": "Dundas",
                "label_ar": "دونداس",
                "label_fr": "Dundas"
            },
            {
                "value": 7023,
                "label": "Dundee",
                "label_ar": "دندي",
                "label_fr": "Dundee"
            },
            {
                "value": 7024,
                "label": "Dunedin",
                "label_ar": "دنيدن",
                "label_fr": "Dunedin"
            },
            {
                "value": 7025,
                "label": "Dunellen",
                "label_ar": "دنلين",
                "label_fr": "Dunellen"
            },
            {
                "value": 7026,
                "label": "Dungannon",
                "label_ar": "دونجانون",
                "label_fr": "Dungannon"
            },
            {
                "value": 7027,
                "label": "Dunkerton",
                "label_ar": "دنكرتون",
                "label_fr": "Dunkerton"
            },
            {
                "value": 7028,
                "label": "Dunkirk",
                "label_ar": "دونكيرك",
                "label_fr": "Dunkerque"
            },
            {
                "value": 7029,
                "label": "Dunlap",
                "label_ar": "دنلاب",
                "label_fr": "Dunlap"
            },
            {
                "value": 7030,
                "label": "Dunlow",
                "label_ar": "دنلو",
                "label_fr": "Dunlow"
            },
            {
                "value": 7031,
                "label": "Dunmor",
                "label_ar": "دنمور",
                "label_fr": "Dunmor"
            },
            {
                "value": 7032,
                "label": "Dunmore",
                "label_ar": "دنمور",
                "label_fr": "Dunmore"
            },
            {
                "value": 7033,
                "label": "Dunn",
                "label_ar": "دن",
                "label_fr": "Dunn"
            },
            {
                "value": 7034,
                "label": "Dunn Loring",
                "label_ar": "دن لورينج",
                "label_fr": "Dunn Loring"
            },
            {
                "value": 7035,
                "label": "Dunnell",
                "label_ar": "دنيل",
                "label_fr": "Dunnell"
            },
            {
                "value": 7036,
                "label": "Dunnellon",
                "label_ar": "دنيلون",
                "label_fr": "Dunnellon"
            },
            {
                "value": 7037,
                "label": "Dunnsville",
                "label_ar": "دانسفيل",
                "label_fr": "Dunnsville"
            },
            {
                "value": 7038,
                "label": "Dunnville",
                "label_ar": "دنفيل",
                "label_fr": "Dunnville"
            },
            {
                "value": 7039,
                "label": "Dunseith",
                "label_ar": "دنسيث",
                "label_fr": "Dunseith"
            },
            {
                "value": 7040,
                "label": "Dunsmuir",
                "label_ar": "دنسمير",
                "label_fr": "Dunsmuir"
            },
            {
                "value": 7041,
                "label": "Dunstable",
                "label_ar": "دنستابل",
                "label_fr": "Dunstable"
            },
            {
                "value": 7042,
                "label": "Duplessis",
                "label_ar": "دوبليسيس",
                "label_fr": "Duplessis"
            },
            {
                "value": 7043,
                "label": "Dupo",
                "label_ar": "دوبو",
                "label_fr": "Dupo"
            },
            {
                "value": 7044,
                "label": "Dupont",
                "label_ar": "دوبونت",
                "label_fr": "Dupont"
            },
            {
                "value": 7045,
                "label": "Dupuyer",
                "label_ar": "دوبويير",
                "label_fr": "Dupuyer"
            },
            {
                "value": 7046,
                "label": "Duquesne",
                "label_ar": "دوكين",
                "label_fr": "Duquesne"
            },
            {
                "value": 7047,
                "label": "Durand",
                "label_ar": "دوراند",
                "label_fr": "Durand"
            },
            {
                "value": 7048,
                "label": "Durango",
                "label_ar": "دورانجو",
                "label_fr": "Durango"
            },
            {
                "value": 7049,
                "label": "Durant",
                "label_ar": "ديورانت",
                "label_fr": "Durant"
            },
            {
                "value": 7050,
                "label": "Durbin",
                "label_ar": "دوربين",
                "label_fr": "Durbin"
            },
            {
                "value": 7051,
                "label": "Durham",
                "label_ar": "دورهام",
                "label_fr": "Durham"
            },
            {
                "value": 7052,
                "label": "Durhamville",
                "label_ar": "دورهامفيل",
                "label_fr": "Durhamville"
            },
            {
                "value": 7053,
                "label": "Duryea",
                "label_ar": "دوريا",
                "label_fr": "Duryea"
            },
            {
                "value": 7054,
                "label": "Dushore",
                "label_ar": "دوشور",
                "label_fr": "Dushore"
            },
            {
                "value": 7055,
                "label": "Duson",
                "label_ar": "دوسون",
                "label_fr": "Duson"
            },
            {
                "value": 7056,
                "label": "Dustin",
                "label_ar": "داستن",
                "label_fr": "Dustin"
            },
            {
                "value": 7057,
                "label": "Dutch Flat",
                "label_ar": "شقة دوتش",
                "label_fr": "Appartement néerlandais"
            },
            {
                "value": 7058,
                "label": "Dutch Harbor",
                "label_ar": "دوتش هاربور",
                "label_fr": "Dutch Harbor"
            },
            {
                "value": 7059,
                "label": "Dutch John",
                "label_ar": "هولندي جون",
                "label_fr": "Néerlandais John"
            },
            {
                "value": 7060,
                "label": "Dutton",
                "label_ar": "دوتون",
                "label_fr": "Dutton"
            },
            {
                "value": 7061,
                "label": "Duvall",
                "label_ar": "دوفال",
                "label_fr": "Duvall"
            },
            {
                "value": 7062,
                "label": "Duxbury",
                "label_ar": "دوكسبري",
                "label_fr": "Duxbury"
            },
            {
                "value": 7063,
                "label": "Dwale",
                "label_ar": "دويل",
                "label_fr": "Dwale"
            },
            {
                "value": 7064,
                "label": "Dwight",
                "label_ar": "دوايت",
                "label_fr": "Dwight"
            },
            {
                "value": 7065,
                "label": "Dyer",
                "label_ar": "صباغ",
                "label_fr": "Teinturier"
            },
            {
                "value": 7066,
                "label": "Dyersburg",
                "label_ar": "Dyersburg",
                "label_fr": "Dyersburg"
            },
            {
                "value": 7067,
                "label": "Dyersville",
                "label_ar": "ديرسفيل",
                "label_fr": "Dyersville"
            },
            {
                "value": 7068,
                "label": "Dysart",
                "label_ar": "ديسارت",
                "label_fr": "Dysart"
            },
            {
                "value": 7069,
                "label": "Eads",
                "label_ar": "إيدس",
                "label_fr": "Eads"
            },
            {
                "value": 7070,
                "label": "Eagar",
                "label_ar": "إيغار",
                "label_fr": "Eagar"
            },
            {
                "value": 7071,
                "label": "Eagle",
                "label_ar": "نسر",
                "label_fr": "Aigle"
            },
            {
                "value": 7072,
                "label": "Eagle Bend",
                "label_ar": "النسر بيند",
                "label_fr": "Eagle Bend"
            },
            {
                "value": 7073,
                "label": "Eagle Bridge",
                "label_ar": "جسر النسر",
                "label_fr": "Pont de l'Aigle"
            },
            {
                "value": 7074,
                "label": "Eagle Butte",
                "label_ar": "النسر بوت",
                "label_fr": "Eagle Butte"
            },
            {
                "value": 7075,
                "label": "Eagle Creek",
                "label_ar": "إيجل كريك",
                "label_fr": "Eagle Creek"
            },
            {
                "value": 7076,
                "label": "Eagle Grove",
                "label_ar": "إيجل جروف",
                "label_fr": "Eagle Grove"
            },
            {
                "value": 7077,
                "label": "Eagle Lake",
                "label_ar": "بحيرة النسر",
                "label_fr": "Eagle Lake"
            },
            {
                "value": 7078,
                "label": "Eagle Mountain",
                "label_ar": "جبل النسر",
                "label_fr": "Eagle Mountain"
            },
            {
                "value": 7079,
                "label": "Eagle Nest",
                "label_ar": "عش النسر",
                "label_fr": "Nid d'aigle"
            },
            {
                "value": 7080,
                "label": "Eagle Pass",
                "label_ar": "تمرير النسر",
                "label_fr": "Eagle Pass"
            },
            {
                "value": 7081,
                "label": "Eagle Point",
                "label_ar": "إيجل بوينت",
                "label_fr": "Eagle Point"
            },
            {
                "value": 7082,
                "label": "Eagle River",
                "label_ar": "نهر النسر",
                "label_fr": "Eagle River"
            },
            {
                "value": 7083,
                "label": "Eagle Rock",
                "label_ar": "إيجل روك",
                "label_fr": "Eagle Rock"
            },
            {
                "value": 7084,
                "label": "Eagle Springs",
                "label_ar": "إيجل سبرينجز",
                "label_fr": "Eagle Springs"
            },
            {
                "value": 7085,
                "label": "Eagleville",
                "label_ar": "إيجلفيل",
                "label_fr": "Eagleville"
            },
            {
                "value": 7086,
                "label": "Earl Park",
                "label_ar": "ايرل بارك",
                "label_fr": "Earl Park"
            },
            {
                "value": 7087,
                "label": "Earle",
                "label_ar": "إيرل",
                "label_fr": "Earle"
            },
            {
                "value": 7088,
                "label": "Earleville",
                "label_ar": "إيرلفيل",
                "label_fr": "Earleville"
            },
            {
                "value": 7089,
                "label": "Earlham",
                "label_ar": "إيرلهام",
                "label_fr": "Earlham"
            },
            {
                "value": 7090,
                "label": "Earlimart",
                "label_ar": "إيرليمارت",
                "label_fr": "Earlimart"
            },
            {
                "value": 7091,
                "label": "Earling",
                "label_ar": "إيرلينج",
                "label_fr": "Earling"
            },
            {
                "value": 7092,
                "label": "Earlsboro",
                "label_ar": "إيرلسبورو",
                "label_fr": "Earlsboro"
            },
            {
                "value": 7093,
                "label": "Earlton",
                "label_ar": "إيرلتون",
                "label_fr": "Earlton"
            },
            {
                "value": 7094,
                "label": "Earlville",
                "label_ar": "إيرلفيل",
                "label_fr": "Earlville"
            },
            {
                "value": 7095,
                "label": "Early",
                "label_ar": "مبكرا",
                "label_fr": "De bonne heure"
            },
            {
                "value": 7096,
                "label": "Earlysville",
                "label_ar": "إيرليزفيل",
                "label_fr": "Earlysville"
            },
            {
                "value": 7097,
                "label": "Earth",
                "label_ar": "أرض",
                "label_fr": "Terre"
            },
            {
                "value": 7098,
                "label": "Earth City",
                "label_ar": "مدينة الأرض",
                "label_fr": "Earth City"
            },
            {
                "value": 7099,
                "label": "Easley",
                "label_ar": "إيزلي",
                "label_fr": "Easley"
            },
            {
                "value": 7100,
                "label": "East Alton",
                "label_ar": "شرق ألتون",
                "label_fr": "East Alton"
            },
            {
                "value": 7101,
                "label": "East Amherst",
                "label_ar": "امهيرست الشرقية",
                "label_fr": "East Amherst"
            },
            {
                "value": 7102,
                "label": "East Andover",
                "label_ar": "شرق أندوفر",
                "label_fr": "East Andover"
            },
            {
                "value": 7103,
                "label": "East Aurora",
                "label_ar": "شرق أورورا",
                "label_fr": "Aurore orientale"
            },
            {
                "value": 7104,
                "label": "East Bank",
                "label_ar": "الضفة الشرقية",
                "label_fr": "Rive Est"
            },
            {
                "value": 7105,
                "label": "East Barre",
                "label_ar": "شرق بري",
                "label_fr": "Barre est"
            },
            {
                "value": 7106,
                "label": "East Bend",
                "label_ar": "الشرق بيند",
                "label_fr": "East Bend"
            },
            {
                "value": 7107,
                "label": "East Berkshire",
                "label_ar": "شرق بيركشاير",
                "label_fr": "East Berkshire"
            },
            {
                "value": 7108,
                "label": "East Berlin",
                "label_ar": "برلين الشرقية",
                "label_fr": "Berlin Est"
            },
            {
                "value": 7109,
                "label": "East Bernard",
                "label_ar": "شرق برنارد",
                "label_fr": "Bernard Est"
            },
            {
                "value": 7110,
                "label": "East Berne",
                "label_ar": "شرق برن",
                "label_fr": "Berne Est"
            },
            {
                "value": 7111,
                "label": "East Bernstadt",
                "label_ar": "شرق برنشتات",
                "label_fr": "Bernstadt Est"
            },
            {
                "value": 7112,
                "label": "East Blue Hill",
                "label_ar": "إيست بلو هيل",
                "label_fr": "East Blue Hill"
            },
            {
                "value": 7113,
                "label": "East Boothbay",
                "label_ar": "إيست بوثباي",
                "label_fr": "East Boothbay"
            },
            {
                "value": 7114,
                "label": "East Brady",
                "label_ar": "شرق برادي",
                "label_fr": "East Brady"
            },
            {
                "value": 7115,
                "label": "East Branch",
                "label_ar": "فرع الشرق",
                "label_fr": "Branche Est"
            },
            {
                "value": 7116,
                "label": "East Bridgewater",
                "label_ar": "الشرق بريدجووتر",
                "label_fr": "East Bridgewater"
            },
            {
                "value": 7117,
                "label": "East Brookfield",
                "label_ar": "إيست بروكفيلد",
                "label_fr": "East Brookfield"
            },
            {
                "value": 7118,
                "label": "East Brunswick",
                "label_ar": "شرق برونزويك",
                "label_fr": "Brunswick Est"
            },
            {
                "value": 7119,
                "label": "East Burke",
                "label_ar": "شرق بورك",
                "label_fr": "East Burke"
            },
            {
                "value": 7120,
                "label": "East Butler",
                "label_ar": "الشرق بتلر",
                "label_fr": "Butler de l'Est"
            },
            {
                "value": 7121,
                "label": "East Calais",
                "label_ar": "شرق كاليه",
                "label_fr": "Calais Est"
            },
            {
                "value": 7122,
                "label": "East Canaan",
                "label_ar": "شرق كنعان",
                "label_fr": "Est de Canaan"
            },
            {
                "value": 7123,
                "label": "East Candia",
                "label_ar": "شرق كانديا",
                "label_fr": "Candie orientale"
            },
            {
                "value": 7124,
                "label": "East Carbon City",
                "label_ar": "شرق مدينة الكربون",
                "label_fr": "East Carbon City"
            },
            {
                "value": 7125,
                "label": "East Carondelet",
                "label_ar": "الشرق كارونديليت",
                "label_fr": "Carondelet Est"
            },
            {
                "value": 7126,
                "label": "East Chatham",
                "label_ar": "شرق تشاتام",
                "label_fr": "East Chatham"
            },
            {
                "value": 7127,
                "label": "East Chicago",
                "label_ar": "شرق شيكاغو",
                "label_fr": "Chicago Est"
            },
            {
                "value": 7128,
                "label": "East China Township",
                "label_ar": "بلدة شرق الصين",
                "label_fr": "Canton de Chine orientale"
            },
            {
                "value": 7129,
                "label": "East Claridon",
                "label_ar": "شرق كلاريدون",
                "label_fr": "East Claridon"
            },
            {
                "value": 7130,
                "label": "East Corinth",
                "label_ar": "كورينث الشرقية",
                "label_fr": "Corinthe orientale"
            },
            {
                "value": 7131,
                "label": "East Dennis",
                "label_ar": "شرق دينيس",
                "label_fr": "East Dennis"
            },
            {
                "value": 7132,
                "label": "East Dover",
                "label_ar": "شرق دوفر",
                "label_fr": "East Dover"
            },
            {
                "value": 7133,
                "label": "East Dublin",
                "label_ar": "شرق دبلن",
                "label_fr": "Dublin Est"
            },
            {
                "value": 7134,
                "label": "East Dubuque",
                "label_ar": "دوبوك الشرقية",
                "label_fr": "Dubuque Est"
            },
            {
                "value": 7135,
                "label": "East Durham",
                "label_ar": "شرق دورهام",
                "label_fr": "East Durham"
            },
            {
                "value": 7136,
                "label": "East Earl",
                "label_ar": "الشرق ايرل",
                "label_fr": "East Earl"
            },
            {
                "value": 7137,
                "label": "East Elmhurst",
                "label_ar": "شرق المهرست",
                "label_fr": "East Elmhurst"
            },
            {
                "value": 7138,
                "label": "East Fairfield",
                "label_ar": "إيست فيرفيلد",
                "label_fr": "East Fairfield"
            },
            {
                "value": 7139,
                "label": "East Fairview",
                "label_ar": "الشرق فيرفيو",
                "label_fr": "East Fairview"
            },
            {
                "value": 7140,
                "label": "East Falmouth",
                "label_ar": "شرق فالماوث",
                "label_fr": "East Falmouth"
            },
            {
                "value": 7141,
                "label": "East Flat Rock",
                "label_ar": "إيست فلات روك",
                "label_fr": "East Flat Rock"
            },
            {
                "value": 7142,
                "label": "East Freedom",
                "label_ar": "الشرق الحرية",
                "label_fr": "Liberté de l'Est"
            },
            {
                "value": 7143,
                "label": "East Freetown",
                "label_ar": "شرق فريتاون",
                "label_fr": "East Freetown"
            },
            {
                "value": 7144,
                "label": "East Galesburg",
                "label_ar": "شرق جاليسبرج",
                "label_fr": "East Galesburg"
            },
            {
                "value": 7145,
                "label": "East Granby",
                "label_ar": "شرق جرانبي",
                "label_fr": "East Granby"
            },
            {
                "value": 7146,
                "label": "East Grand Forks",
                "label_ar": "إيست غراند فوركس",
                "label_fr": "East Grand Forks"
            },
            {
                "value": 7147,
                "label": "East Greenbush",
                "label_ar": "شرق جرينبوش",
                "label_fr": "East Greenbush"
            },
            {
                "value": 7148,
                "label": "East Greenville",
                "label_ar": "شرق جرينفيل",
                "label_fr": "East Greenville"
            },
            {
                "value": 7149,
                "label": "East Greenwich",
                "label_ar": "شرق غرينتش",
                "label_fr": "East Greenwich"
            },
            {
                "value": 7150,
                "label": "East Haddam",
                "label_ar": "شرق الهدام",
                "label_fr": "East Haddam"
            },
            {
                "value": 7151,
                "label": "East Hampstead",
                "label_ar": "إيست هامبستيد",
                "label_fr": "East Hampstead"
            },
            {
                "value": 7152,
                "label": "East Hampton",
                "label_ar": "إيست هامبتون",
                "label_fr": "East Hampton"
            },
            {
                "value": 7153,
                "label": "East Hanover",
                "label_ar": "شرق هانوفر",
                "label_fr": "East Hanovre"
            },
            {
                "value": 7154,
                "label": "East Hardwick",
                "label_ar": "شرق هاردويك",
                "label_fr": "East Hardwick"
            },
            {
                "value": 7155,
                "label": "East Hartford",
                "label_ar": "إيست هارتفورد",
                "label_fr": "East Hartford"
            },
            {
                "value": 7156,
                "label": "East Hartland",
                "label_ar": "شرق هارتلاند",
                "label_fr": "East Hartland"
            },
            {
                "value": 7157,
                "label": "East Haven",
                "label_ar": "إيست هافن",
                "label_fr": "East Haven"
            },
            {
                "value": 7158,
                "label": "East Helena",
                "label_ar": "شرق هيلانة",
                "label_fr": "Hélène orientale"
            },
            {
                "value": 7159,
                "label": "East Irvine",
                "label_ar": "شرق ايرفين",
                "label_fr": "Irvine Est"
            },
            {
                "value": 7160,
                "label": "East Islip",
                "label_ar": "شرق إسليب",
                "label_fr": "East Islip"
            },
            {
                "value": 7161,
                "label": "East Jordan",
                "label_ar": "شرق الاردن",
                "label_fr": "Jordanie orientale"
            },
            {
                "value": 7162,
                "label": "East Kingston",
                "label_ar": "شرق كينغستون",
                "label_fr": "Kingston Est"
            },
            {
                "value": 7163,
                "label": "East Lake",
                "label_ar": "إيست ليك",
                "label_fr": "East Lake"
            },
            {
                "value": 7164,
                "label": "East Lansing",
                "label_ar": "إيست لانسينغ",
                "label_fr": "East Lansing"
            },
            {
                "value": 7165,
                "label": "East Leroy",
                "label_ar": "الشرق ليروي",
                "label_fr": "Est Leroy"
            },
            {
                "value": 7166,
                "label": "East Liberty",
                "label_ar": "شرق ليبرتي",
                "label_fr": "East Liberty"
            },
            {
                "value": 7167,
                "label": "East Liverpool",
                "label_ar": "شرق ليفربول",
                "label_fr": "Est de Liverpool"
            },
            {
                "value": 7168,
                "label": "East Longmeadow",
                "label_ar": "شرق Longmeadow",
                "label_fr": "East Longmeadow"
            },
            {
                "value": 7169,
                "label": "East Lynn",
                "label_ar": "ايست لين",
                "label_fr": "East Lynn"
            },
            {
                "value": 7170,
                "label": "East Machias",
                "label_ar": "ماكياس الشرقية",
                "label_fr": "Machias est"
            },
            {
                "value": 7171,
                "label": "East Marion",
                "label_ar": "شرق ماريون",
                "label_fr": "Marion Est"
            },
            {
                "value": 7172,
                "label": "East McKeesport",
                "label_ar": "شرق ماكيسبورت",
                "label_fr": "East McKeesport"
            },
            {
                "value": 7173,
                "label": "East Meadow",
                "label_ar": "إيست ميدو",
                "label_fr": "East Meadow"
            },
            {
                "value": 7174,
                "label": "East Meredith",
                "label_ar": "الشرق ميريديث",
                "label_fr": "East Meredith"
            },
            {
                "value": 7175,
                "label": "East Middlebury",
                "label_ar": "الشرق ميدلبري",
                "label_fr": "East Middlebury"
            },
            {
                "value": 7176,
                "label": "East Millinocket",
                "label_ar": "الشرق ميلينوكيت",
                "label_fr": "East Millinocket"
            },
            {
                "value": 7177,
                "label": "East Millsboro",
                "label_ar": "شرق ميلسبورو",
                "label_fr": "East Millsboro"
            },
            {
                "value": 7178,
                "label": "East Moline",
                "label_ar": "الشرق مولين",
                "label_fr": "East Moline"
            },
            {
                "value": 7179,
                "label": "East Montpelier",
                "label_ar": "شرق مونبلييه",
                "label_fr": "Montpellier Est"
            },
            {
                "value": 7180,
                "label": "East Moriches",
                "label_ar": "الشرق موريش",
                "label_fr": "Moriches Est"
            },
            {
                "value": 7181,
                "label": "East New Market",
                "label_ar": "إيست نيو ماركت",
                "label_fr": "Nouveau marché est"
            },
            {
                "value": 7182,
                "label": "East Northport",
                "label_ar": "إيست نورثبورت",
                "label_fr": "East Northport"
            },
            {
                "value": 7183,
                "label": "East Norwich",
                "label_ar": "شرق نورويتش",
                "label_fr": "East Norwich"
            },
            {
                "value": 7184,
                "label": "East Orange",
                "label_ar": "شرق أورانج",
                "label_fr": "Orange de l'Est"
            },
            {
                "value": 7185,
                "label": "East Orleans",
                "label_ar": "شرق اورليانز",
                "label_fr": "Orléans Est"
            },
            {
                "value": 7186,
                "label": "East Otis",
                "label_ar": "شرق أوتيس",
                "label_fr": "East Otis"
            },
            {
                "value": 7187,
                "label": "East Palatka",
                "label_ar": "شرق بالاتكا",
                "label_fr": "East Palatka"
            },
            {
                "value": 7188,
                "label": "East Palestine",
                "label_ar": "شرق فلسطين",
                "label_fr": "Palestine orientale"
            },
            {
                "value": 7189,
                "label": "East Peoria",
                "label_ar": "شرق بيوريا",
                "label_fr": "East Peoria"
            },
            {
                "value": 7190,
                "label": "East Petersburg",
                "label_ar": "شرق بطرسبرج",
                "label_fr": "Est de Pétersbourg"
            },
            {
                "value": 7191,
                "label": "East Pharsalia",
                "label_ar": "شرق الفرسالية",
                "label_fr": "East Pharsalia"
            },
            {
                "value": 7192,
                "label": "East Pittsburgh",
                "label_ar": "شرق بيتسبرغ",
                "label_fr": "East Pittsburgh"
            },
            {
                "value": 7193,
                "label": "East Point",
                "label_ar": "إيست بوينت",
                "label_fr": "East Point"
            },
            {
                "value": 7194,
                "label": "East Prairie",
                "label_ar": "شرق البراري",
                "label_fr": "East Prairie"
            },
            {
                "value": 7195,
                "label": "East Prospect",
                "label_ar": "توقع الشرق",
                "label_fr": "Prospect Est"
            },
            {
                "value": 7196,
                "label": "East Providence",
                "label_ar": "بروفيدنس الشرقية",
                "label_fr": "Providence orientale"
            },
            {
                "value": 7197,
                "label": "East Quogue",
                "label_ar": "الشرق Quogue",
                "label_fr": "East Quogue"
            },
            {
                "value": 7198,
                "label": "East Randolph",
                "label_ar": "شرق راندولف",
                "label_fr": "East Randolph"
            },
            {
                "value": 7199,
                "label": "East Rochester",
                "label_ar": "شرق روتشستر",
                "label_fr": "East Rochester"
            },
            {
                "value": 7200,
                "label": "East Rockaway",
                "label_ar": "شرق روكاواي",
                "label_fr": "East Rockaway"
            },
            {
                "value": 7201,
                "label": "East Rutherford",
                "label_ar": "شرق رذرفورد",
                "label_fr": "East Rutherford"
            },
            {
                "value": 7202,
                "label": "East Saint Louis",
                "label_ar": "شرق سانت لويس",
                "label_fr": "East Saint Louis"
            },
            {
                "value": 7203,
                "label": "East Sandwich",
                "label_ar": "ايست ساندويتش",
                "label_fr": "Sandwich de l'Est"
            },
            {
                "value": 7204,
                "label": "East Setauket",
                "label_ar": "شرق سيتوكيت",
                "label_fr": "East Setauket"
            },
            {
                "value": 7205,
                "label": "East Sparta",
                "label_ar": "شرق سبارتا",
                "label_fr": "Sparte orientale"
            },
            {
                "value": 7206,
                "label": "East Stone Gap",
                "label_ar": "إيست ستون جاب",
                "label_fr": "Gap de pierre est"
            },
            {
                "value": 7207,
                "label": "East Stoneham",
                "label_ar": "إيست ستونهام",
                "label_fr": "East Stoneham"
            },
            {
                "value": 7208,
                "label": "East Stroudsburg",
                "label_ar": "شرق سترودسبورغ",
                "label_fr": "East Stroudsburg"
            },
            {
                "value": 7209,
                "label": "East Syracuse",
                "label_ar": "شرق سيراكيوز",
                "label_fr": "Syracuse est"
            },
            {
                "value": 7210,
                "label": "East Taunton",
                "label_ar": "شرق تونتون",
                "label_fr": "East Taunton"
            },
            {
                "value": 7211,
                "label": "East Tawas",
                "label_ar": "شرق تاواس",
                "label_fr": "East Tawas"
            },
            {
                "value": 7212,
                "label": "East Thetford",
                "label_ar": "شرق ثيتفورد",
                "label_fr": "East Thetford"
            },
            {
                "value": 7213,
                "label": "East Troy",
                "label_ar": "شرق تروي",
                "label_fr": "East Troy"
            },
            {
                "value": 7214,
                "label": "East Wakefield",
                "label_ar": "إيست ويكفيلد",
                "label_fr": "East Wakefield"
            },
            {
                "value": 7215,
                "label": "East Walpole",
                "label_ar": "شرق والبول",
                "label_fr": "Walpole Est"
            },
            {
                "value": 7216,
                "label": "East Wareham",
                "label_ar": "شرق ويرهام",
                "label_fr": "East Wareham"
            },
            {
                "value": 7217,
                "label": "East Waterboro",
                "label_ar": "إيست ووتربورو",
                "label_fr": "East Waterboro"
            },
            {
                "value": 7218,
                "label": "East Wenatchee",
                "label_ar": "شرق ويناتشي",
                "label_fr": "East Wenatchee"
            },
            {
                "value": 7219,
                "label": "East Weymouth",
                "label_ar": "شرق ويموث",
                "label_fr": "East Weymouth"
            },
            {
                "value": 7220,
                "label": "East Windsor Hill",
                "label_ar": "شرق وندسور هيل",
                "label_fr": "East Windsor Hill"
            },
            {
                "value": 7221,
                "label": "Eastaboga",
                "label_ar": "ايستابوجا",
                "label_fr": "Eastaboga"
            },
            {
                "value": 7222,
                "label": "Eastanollee",
                "label_ar": "ايستانولي",
                "label_fr": "Eastanollee"
            },
            {
                "value": 7223,
                "label": "Eastchester",
                "label_ar": "إيستشستر",
                "label_fr": "Eastchester"
            },
            {
                "value": 7224,
                "label": "Eastern",
                "label_ar": "الشرقية",
                "label_fr": "Est"
            },
            {
                "value": 7225,
                "label": "Eastford",
                "label_ar": "إيستفورد",
                "label_fr": "Eastford"
            },
            {
                "value": 7226,
                "label": "Eastham",
                "label_ar": "إيستهام",
                "label_fr": "Eastham"
            },
            {
                "value": 7227,
                "label": "Easthampton",
                "label_ar": "ايستهامبتون",
                "label_fr": "Easthampton"
            },
            {
                "value": 7228,
                "label": "Eastlake",
                "label_ar": "إيستليك",
                "label_fr": "Eastlake"
            },
            {
                "value": 7229,
                "label": "Eastland",
                "label_ar": "ايستلاند",
                "label_fr": "Eastland"
            },
            {
                "value": 7230,
                "label": "Eastman",
                "label_ar": "ايستمان",
                "label_fr": "Eastman"
            },
            {
                "value": 7231,
                "label": "Easton",
                "label_ar": "ايستون",
                "label_fr": "Easton"
            },
            {
                "value": 7232,
                "label": "Eastover",
                "label_ar": "إيستوفر",
                "label_fr": "Eastover"
            },
            {
                "value": 7233,
                "label": "Eastpoint",
                "label_ar": "ايست بوينت",
                "label_fr": "Eastpoint"
            },
            {
                "value": 7234,
                "label": "Eastpointe",
                "label_ar": "إيستبوينت",
                "label_fr": "Eastpointe"
            },
            {
                "value": 7235,
                "label": "Eastport",
                "label_ar": "ايستبورت",
                "label_fr": "Eastport"
            },
            {
                "value": 7236,
                "label": "Eastsound",
                "label_ar": "إيست ساوند",
                "label_fr": "Eastsound"
            },
            {
                "value": 7237,
                "label": "Eastview",
                "label_ar": "إيستفيو",
                "label_fr": "Eastview"
            },
            {
                "value": 7238,
                "label": "Eastville",
                "label_ar": "ايستفيل",
                "label_fr": "Eastville"
            },
            {
                "value": 7239,
                "label": "Eaton",
                "label_ar": "إيتون",
                "label_fr": "Eaton"
            },
            {
                "value": 7240,
                "label": "Eaton Rapids",
                "label_ar": "إيتون رابيدز",
                "label_fr": "Rapides d'Eaton"
            },
            {
                "value": 7241,
                "label": "Eatonton",
                "label_ar": "ايتونتون",
                "label_fr": "Eatonton"
            },
            {
                "value": 7242,
                "label": "Eatontown",
                "label_ar": "إيتونتون",
                "label_fr": "Eatontown"
            },
            {
                "value": 7243,
                "label": "Eatonville",
                "label_ar": "إيتونفيل",
                "label_fr": "Eatonville"
            },
            {
                "value": 7244,
                "label": "Eau Claire",
                "label_ar": "او كلير",
                "label_fr": "Eau Claire"
            },
            {
                "value": 7245,
                "label": "Eau Galle",
                "label_ar": "أو جالي",
                "label_fr": "Eau Galle"
            },
            {
                "value": 7246,
                "label": "Ebensburg",
                "label_ar": "إيبينسبورغ",
                "label_fr": "Ebensburg"
            },
            {
                "value": 7247,
                "label": "Echo",
                "label_ar": "صدى صوت",
                "label_fr": "Écho"
            },
            {
                "value": 7248,
                "label": "Eckert",
                "label_ar": "إيكرت",
                "label_fr": "Eckert"
            },
            {
                "value": 7249,
                "label": "Eckerty",
                "label_ar": "إيكرتي",
                "label_fr": "Eckerty"
            },
            {
                "value": 7250,
                "label": "Eclectic",
                "label_ar": "انتقائي",
                "label_fr": "Éclectique"
            },
            {
                "value": 7251,
                "label": "Economy",
                "label_ar": "الاقتصاد",
                "label_fr": "Économie"
            },
            {
                "value": 7252,
                "label": "Ecorse",
                "label_ar": "إكورس",
                "label_fr": "Ecorse"
            },
            {
                "value": 7253,
                "label": "Ecru",
                "label_ar": "بيج فاتح",
                "label_fr": "Écru"
            },
            {
                "value": 7254,
                "label": "Ector",
                "label_ar": "إكتور",
                "label_fr": "Ector"
            },
            {
                "value": 7255,
                "label": "Edcouch",
                "label_ar": "إدكوش",
                "label_fr": "Edcouch"
            },
            {
                "value": 7256,
                "label": "Eddington",
                "label_ar": "إدينجتون",
                "label_fr": "Eddington"
            },
            {
                "value": 7257,
                "label": "Eddy",
                "label_ar": "إيدي",
                "label_fr": "Tourbillon"
            },
            {
                "value": 7258,
                "label": "Eddyville",
                "label_ar": "إديفيل",
                "label_fr": "Eddyville"
            },
            {
                "value": 7259,
                "label": "Edelstein",
                "label_ar": "إديلشتاين",
                "label_fr": "Edelstein"
            },
            {
                "value": 7260,
                "label": "Eden",
                "label_ar": "عدن",
                "label_fr": "Eden"
            },
            {
                "value": 7261,
                "label": "Eden Prairie",
                "label_ar": "إيدن بريري",
                "label_fr": "Eden Prairie"
            },
            {
                "value": 7262,
                "label": "Eden Valley",
                "label_ar": "وادي عدن",
                "label_fr": "Eden Valley"
            },
            {
                "value": 7263,
                "label": "Edenton",
                "label_ar": "ادينتون",
                "label_fr": "Edenton"
            },
            {
                "value": 7264,
                "label": "Edgar",
                "label_ar": "إدغار",
                "label_fr": "Edgar"
            },
            {
                "value": 7265,
                "label": "Edgard",
                "label_ar": "إدجارد",
                "label_fr": "Edgard"
            },
            {
                "value": 7266,
                "label": "Edgartown",
                "label_ar": "إدجارتاون",
                "label_fr": "Edgartown"
            },
            {
                "value": 7267,
                "label": "Edgecomb",
                "label_ar": "Edgecomb",
                "label_fr": "Edgecomb"
            },
            {
                "value": 7268,
                "label": "Edgefield",
                "label_ar": "إيدجفيلد",
                "label_fr": "Edgefield"
            },
            {
                "value": 7269,
                "label": "Edgeley",
                "label_ar": "إدجلي",
                "label_fr": "Edgeley"
            },
            {
                "value": 7270,
                "label": "Edgemont",
                "label_ar": "Edgemont",
                "label_fr": "Edgemont"
            },
            {
                "value": 7271,
                "label": "Edgemoor",
                "label_ar": "Edgemoor",
                "label_fr": "Edgemoor"
            },
            {
                "value": 7272,
                "label": "Edgerton",
                "label_ar": "إدجيرتون",
                "label_fr": "Edgerton"
            },
            {
                "value": 7273,
                "label": "Edgewater",
                "label_ar": "إدجووتر",
                "label_fr": "Edgewater"
            },
            {
                "value": 7274,
                "label": "Edgewood",
                "label_ar": "إدجوود",
                "label_fr": "Edgewood"
            },
            {
                "value": 7275,
                "label": "Edina",
                "label_ar": "إيدينا",
                "label_fr": "Edina"
            },
            {
                "value": 7276,
                "label": "Edinboro",
                "label_ar": "ادينبورو",
                "label_fr": "Edinboro"
            },
            {
                "value": 7277,
                "label": "Edinburg",
                "label_ar": "ادينبورغ",
                "label_fr": "Edimbourg"
            },
            {
                "value": 7278,
                "label": "Edinburgh",
                "label_ar": "ادنبره",
                "label_fr": "Edinbourg"
            },
            {
                "value": 7279,
                "label": "Edison",
                "label_ar": "اديسون",
                "label_fr": "Edison"
            },
            {
                "value": 7280,
                "label": "Edisto Island",
                "label_ar": "جزيرة إديستو",
                "label_fr": "Île Edisto"
            },
            {
                "value": 7281,
                "label": "Edmeston",
                "label_ar": "إدمستون",
                "label_fr": "Edmeston"
            },
            {
                "value": 7282,
                "label": "Edmond",
                "label_ar": "ادموند",
                "label_fr": "Edmond"
            },
            {
                "value": 7283,
                "label": "Edmonds",
                "label_ar": "ادموندز",
                "label_fr": "Edmonds"
            },
            {
                "value": 7284,
                "label": "Edmonton",
                "label_ar": "ادمونتون",
                "label_fr": "Edmonton"
            },
            {
                "value": 7285,
                "label": "Edmore",
                "label_ar": "ادمور",
                "label_fr": "Edmore"
            },
            {
                "value": 7286,
                "label": "Edna",
                "label_ar": "إدنا",
                "label_fr": "Edna"
            },
            {
                "value": 7287,
                "label": "Edneyville",
                "label_ar": "إدنيفيل",
                "label_fr": "Edneyville"
            },
            {
                "value": 7288,
                "label": "Edon",
                "label_ar": "ايدون",
                "label_fr": "Edon"
            },
            {
                "value": 7289,
                "label": "Edson",
                "label_ar": "إدسون",
                "label_fr": "Edson"
            },
            {
                "value": 7290,
                "label": "Edwall",
                "label_ar": "إدوال",
                "label_fr": "Edwall"
            },
            {
                "value": 7291,
                "label": "Edwards",
                "label_ar": "إدواردز",
                "label_fr": "Edwards"
            },
            {
                "value": 7292,
                "label": "Edwards Air Force Base",
                "label_ar": "قاعدة إدواردز الجوية",
                "label_fr": "Base aérienne d'Edwards"
            },
            {
                "value": 7293,
                "label": "Edwardsburg",
                "label_ar": "إدواردسبورغ",
                "label_fr": "Edwardsburg"
            },
            {
                "value": 7294,
                "label": "Edwardsport",
                "label_ar": "إدواردسبورت",
                "label_fr": "Edwardsport"
            },
            {
                "value": 7295,
                "label": "Edwardsville",
                "label_ar": "إدواردسفيل",
                "label_fr": "Edwardsville"
            },
            {
                "value": 7296,
                "label": "Effie",
                "label_ar": "إيفي",
                "label_fr": "Effie"
            },
            {
                "value": 7297,
                "label": "Effingham",
                "label_ar": "إيفنغهام",
                "label_fr": "Effingham"
            },
            {
                "value": 7298,
                "label": "Effort",
                "label_ar": "مجهود",
                "label_fr": "Effort"
            },
            {
                "value": 7299,
                "label": "Efland",
                "label_ar": "إيفلاند",
                "label_fr": "Efland"
            },
            {
                "value": 7300,
                "label": "Egan",
                "label_ar": "إيغان",
                "label_fr": "Egan"
            },
            {
                "value": 7301,
                "label": "Egg Harbor",
                "label_ar": "ميناء البيض",
                "label_fr": "Egg Harbor"
            },
            {
                "value": 7302,
                "label": "Egg Harbor City",
                "label_ar": "إيج هاربور سيتي",
                "label_fr": "Egg Harbor City"
            },
            {
                "value": 7303,
                "label": "Eglin",
                "label_ar": "اجلين",
                "label_fr": "Eglin"
            },
            {
                "value": 7304,
                "label": "Eglon",
                "label_ar": "إيجلون",
                "label_fr": "Eglon"
            },
            {
                "value": 7305,
                "label": "Egypt",
                "label_ar": "مصر",
                "label_fr": "Egypte"
            },
            {
                "value": 7306,
                "label": "Ehrenberg",
                "label_ar": "إهرنبرغ",
                "label_fr": "Ehrenberg"
            },
            {
                "value": 7307,
                "label": "Eielson Air Force Base",
                "label_ar": "قاعدة ايلسون الجوية",
                "label_fr": "Base aérienne d'Eielson"
            },
            {
                "value": 7308,
                "label": "Eight Mile",
                "label_ar": "ثمانية أميال",
                "label_fr": "Huit milles"
            },
            {
                "value": 7309,
                "label": "Eighty Four",
                "label_ar": "أربعة وثمانون",
                "label_fr": "Quatre-vingt-quatre"
            },
            {
                "value": 7310,
                "label": "Ekalaka",
                "label_ar": "إكالاكا",
                "label_fr": "Ekalaka"
            },
            {
                "value": 7311,
                "label": "Ekron",
                "label_ar": "عقرون",
                "label_fr": "Ekron"
            },
            {
                "value": 7312,
                "label": "El Cajon",
                "label_ar": "إل كاجون",
                "label_fr": "El Cajon"
            },
            {
                "value": 7313,
                "label": "El Campo",
                "label_ar": "إل كامبو",
                "label_fr": "El Campo"
            },
            {
                "value": 7314,
                "label": "El Centro",
                "label_ar": "إل سنترو",
                "label_fr": "El Centro"
            },
            {
                "value": 7315,
                "label": "El Cerrito",
                "label_ar": "إل سيريتو",
                "label_fr": "El Cerrito"
            },
            {
                "value": 7316,
                "label": "El Dorado",
                "label_ar": "الدورادو",
                "label_fr": "El Dorado"
            },
            {
                "value": 7317,
                "label": "El Dorado Hills",
                "label_ar": "تلال الدورادو",
                "label_fr": "El Dorado Hills"
            },
            {
                "value": 7318,
                "label": "El Dorado Springs",
                "label_ar": "الينابيع الدورادو",
                "label_fr": "El Dorado Springs"
            },
            {
                "value": 7319,
                "label": "El Granada",
                "label_ar": "غرناطة",
                "label_fr": "El Grenade"
            },
            {
                "value": 7320,
                "label": "El Macero",
                "label_ar": "إل ماسيرو",
                "label_fr": "El Macero"
            },
            {
                "value": 7321,
                "label": "El Mirage",
                "label_ar": "الميراج",
                "label_fr": "El Mirage"
            },
            {
                "value": 7322,
                "label": "El Monte",
                "label_ar": "المونتي",
                "label_fr": "El Monte"
            },
            {
                "value": 7323,
                "label": "El Paso",
                "label_ar": "الباسو",
                "label_fr": "El Paso"
            },
            {
                "value": 7324,
                "label": "El Prado",
                "label_ar": "إل برادو",
                "label_fr": "El Prado"
            },
            {
                "value": 7325,
                "label": "El Reno",
                "label_ar": "إل رينو",
                "label_fr": "El Reno"
            },
            {
                "value": 7326,
                "label": "El Segundo",
                "label_ar": "إل سيجوندو",
                "label_fr": "El Segundo"
            },
            {
                "value": 7327,
                "label": "El Sobrante",
                "label_ar": "السوبرانتي",
                "label_fr": "El Sobrante"
            },
            {
                "value": 7328,
                "label": "Elba",
                "label_ar": "إلبا",
                "label_fr": "Elba"
            },
            {
                "value": 7329,
                "label": "Elberon",
                "label_ar": "Elberon",
                "label_fr": "Elberon"
            },
            {
                "value": 7330,
                "label": "Elbert",
                "label_ar": "إلبرت",
                "label_fr": "Elbert"
            },
            {
                "value": 7331,
                "label": "Elberta",
                "label_ar": "إلبرتا",
                "label_fr": "Elberta"
            },
            {
                "value": 7332,
                "label": "Elberton",
                "label_ar": "إلبرتون",
                "label_fr": "Elberton"
            },
            {
                "value": 7333,
                "label": "Elbow Lake",
                "label_ar": "بحيرة الكوع",
                "label_fr": "Elbow Lake"
            },
            {
                "value": 7334,
                "label": "Elbridge",
                "label_ar": "إلبريدج",
                "label_fr": "Elbridge"
            },
            {
                "value": 7335,
                "label": "Elburn",
                "label_ar": "إلبورن",
                "label_fr": "Elburn"
            },
            {
                "value": 7336,
                "label": "Elcho",
                "label_ar": "إلكو",
                "label_fr": "Elcho"
            },
            {
                "value": 7337,
                "label": "Elco",
                "label_ar": "شرم المشترك",
                "label_fr": "Elco"
            },
            {
                "value": 7338,
                "label": "Elderton",
                "label_ar": "إلدرتون",
                "label_fr": "Elderton"
            },
            {
                "value": 7339,
                "label": "Eldon",
                "label_ar": "إلدون",
                "label_fr": "Eldon"
            },
            {
                "value": 7340,
                "label": "Eldora",
                "label_ar": "إلدورا",
                "label_fr": "Eldora"
            },
            {
                "value": 7341,
                "label": "Eldorado",
                "label_ar": "إلدورادو",
                "label_fr": "Eldorado"
            },
            {
                "value": 7342,
                "label": "Eldred",
                "label_ar": "إلدريد",
                "label_fr": "Eldred"
            },
            {
                "value": 7343,
                "label": "Eldridge",
                "label_ar": "إلدريدج",
                "label_fr": "Eldridge"
            },
            {
                "value": 7344,
                "label": "Eleanor",
                "label_ar": "إليانور",
                "label_fr": "Eleanor"
            },
            {
                "value": 7345,
                "label": "Electra",
                "label_ar": "إلكترا",
                "label_fr": "Electra"
            },
            {
                "value": 7346,
                "label": "Elephant Butte",
                "label_ar": "الفيل بوت",
                "label_fr": "Elephant Butte"
            },
            {
                "value": 7347,
                "label": "Eleva",
                "label_ar": "إليفا",
                "label_fr": "Eleva"
            },
            {
                "value": 7348,
                "label": "Elfrida",
                "label_ar": "الفريدا",
                "label_fr": "Elfrida"
            },
            {
                "value": 7349,
                "label": "Elgin",
                "label_ar": "إلجين",
                "label_fr": "Elgin"
            },
            {
                "value": 7350,
                "label": "Eliot",
                "label_ar": "إليوت",
                "label_fr": "Eliot"
            },
            {
                "value": 7351,
                "label": "Elizabeth",
                "label_ar": "إليزابيث",
                "label_fr": "Elizabeth"
            },
            {
                "value": 7352,
                "label": "Elizabeth City",
                "label_ar": "إليزابيث سيتي",
                "label_fr": "Elizabeth City"
            },
            {
                "value": 7353,
                "label": "Elizabethton",
                "label_ar": "إليزابيثتون",
                "label_fr": "Elizabethton"
            },
            {
                "value": 7354,
                "label": "Elizabethtown",
                "label_ar": "إليزابيثتاون",
                "label_fr": "Elizabethtown"
            },
            {
                "value": 7355,
                "label": "Elizabethville",
                "label_ar": "إليزابيثفيل",
                "label_fr": "Elizabethville"
            },
            {
                "value": 7356,
                "label": "Elizaville",
                "label_ar": "إليزافيل",
                "label_fr": "Elizaville"
            },
            {
                "value": 7357,
                "label": "Elk",
                "label_ar": "إلك",
                "label_fr": "Wapiti"
            },
            {
                "value": 7358,
                "label": "Elk City",
                "label_ar": "مدينة الأيل",
                "label_fr": "Elk City"
            },
            {
                "value": 7359,
                "label": "Elk Falls",
                "label_ar": "شلالات إلك",
                "label_fr": "Elk Falls"
            },
            {
                "value": 7360,
                "label": "Elk Garden",
                "label_ar": "إلك جاردن",
                "label_fr": "Jardin des élans"
            },
            {
                "value": 7361,
                "label": "Elk Grove",
                "label_ar": "إلك غروف",
                "label_fr": "Elk Grove"
            },
            {
                "value": 7362,
                "label": "Elk Grove Village",
                "label_ar": "قرية إلك جروف",
                "label_fr": "Village d'Elk Grove"
            },
            {
                "value": 7363,
                "label": "Elk Horn",
                "label_ar": "إلك هورن",
                "label_fr": "Corne d'élan"
            },
            {
                "value": 7364,
                "label": "Elk Mills",
                "label_ar": "إلك ميلز",
                "label_fr": "Elk Mills"
            },
            {
                "value": 7365,
                "label": "Elk Mound",
                "label_ar": "إلك ماوند",
                "label_fr": "Elk Mound"
            },
            {
                "value": 7366,
                "label": "Elk Park",
                "label_ar": "إلك بارك",
                "label_fr": "Parc Elk"
            },
            {
                "value": 7367,
                "label": "Elk Point",
                "label_ar": "إلك بوينت",
                "label_fr": "Elk Point"
            },
            {
                "value": 7368,
                "label": "Elk Rapids",
                "label_ar": "إلك رابيدز",
                "label_fr": "Elk Rapids"
            },
            {
                "value": 7369,
                "label": "Elk River",
                "label_ar": "نهر إلك",
                "label_fr": "Elk River"
            },
            {
                "value": 7370,
                "label": "Elkader",
                "label_ar": "القادر",
                "label_fr": "Elkader"
            },
            {
                "value": 7371,
                "label": "Elkfork",
                "label_ar": "إلكفورك",
                "label_fr": "Elkfork"
            },
            {
                "value": 7372,
                "label": "Elkhart",
                "label_ar": "الخارت",
                "label_fr": "Elkhart"
            },
            {
                "value": 7373,
                "label": "Elkhart Lake",
                "label_ar": "بحيرة الخارت",
                "label_fr": "Lac Elkhart"
            },
            {
                "value": 7374,
                "label": "Elkhorn",
                "label_ar": "الخورن",
                "label_fr": "Elkhorn"
            },
            {
                "value": 7375,
                "label": "Elkhorn City",
                "label_ar": "مدينة الخورن",
                "label_fr": "Elkhorn City"
            },
            {
                "value": 7376,
                "label": "Elkin",
                "label_ar": "إلكين",
                "label_fr": "Elkin"
            },
            {
                "value": 7377,
                "label": "Elkins",
                "label_ar": "إلكينز",
                "label_fr": "Elkins"
            },
            {
                "value": 7378,
                "label": "Elkins Park",
                "label_ar": "إلكينز بارك",
                "label_fr": "Parc Elkins"
            },
            {
                "value": 7379,
                "label": "Elkland",
                "label_ar": "Elkland",
                "label_fr": "Elkland"
            },
            {
                "value": 7380,
                "label": "Elkmont",
                "label_ar": "إلكمون",
                "label_fr": "Elkmont"
            },
            {
                "value": 7381,
                "label": "Elko",
                "label_ar": "إلكو",
                "label_fr": "Elko"
            },
            {
                "value": 7382,
                "label": "Elkridge",
                "label_ar": "الكريدج",
                "label_fr": "Elkridge"
            },
            {
                "value": 7383,
                "label": "Elkton",
                "label_ar": "إلكتون",
                "label_fr": "Elkton"
            },
            {
                "value": 7384,
                "label": "Elkview",
                "label_ar": "الكفيو",
                "label_fr": "Elkview"
            },
            {
                "value": 7385,
                "label": "Elkville",
                "label_ar": "إلكفيل",
                "label_fr": "Elkville"
            },
            {
                "value": 7386,
                "label": "Elkwood",
                "label_ar": "إلكوود",
                "label_fr": "Elkwood"
            },
            {
                "value": 7387,
                "label": "Ellabell",
                "label_ar": "اللابل",
                "label_fr": "Ellabell"
            },
            {
                "value": 7388,
                "label": "Ellaville",
                "label_ar": "Ellaville",
                "label_fr": "Ellaville"
            },
            {
                "value": 7389,
                "label": "Ellenboro",
                "label_ar": "إلينبورو",
                "label_fr": "Ellenboro"
            },
            {
                "value": 7390,
                "label": "Ellenburg Center",
                "label_ar": "مركز إلينبورغ",
                "label_fr": "Centre Ellenburg"
            },
            {
                "value": 7391,
                "label": "Ellenburg Depot",
                "label_ar": "مستودع Ellenburg",
                "label_fr": "Dépôt d'Ellenburg"
            },
            {
                "value": 7392,
                "label": "Ellendale",
                "label_ar": "إليندال",
                "label_fr": "Ellendale"
            },
            {
                "value": 7393,
                "label": "Ellensburg",
                "label_ar": "إلينسبورغ",
                "label_fr": "Ellensburg"
            },
            {
                "value": 7394,
                "label": "Ellenton",
                "label_ar": "إلينتون",
                "label_fr": "Ellenton"
            },
            {
                "value": 7395,
                "label": "Ellenville",
                "label_ar": "إلينفيل",
                "label_fr": "Ellenville"
            },
            {
                "value": 7396,
                "label": "Ellenwood",
                "label_ar": "إلينوود",
                "label_fr": "Ellenwood"
            },
            {
                "value": 7397,
                "label": "Ellerbe",
                "label_ar": "إليربي",
                "label_fr": "Ellerbe"
            },
            {
                "value": 7398,
                "label": "Ellerslie",
                "label_ar": "إليرسلي",
                "label_fr": "Ellerslie"
            },
            {
                "value": 7399,
                "label": "Ellettsville",
                "label_ar": "إليتسفيل",
                "label_fr": "Ellettsville"
            },
            {
                "value": 7400,
                "label": "Ellicott City",
                "label_ar": "مدينة اليكوت",
                "label_fr": "Ellicott City"
            },
            {
                "value": 7401,
                "label": "Ellicottville",
                "label_ar": "إليكوتفيل",
                "label_fr": "Ellicottville"
            },
            {
                "value": 7402,
                "label": "Ellijay",
                "label_ar": "إليجاي",
                "label_fr": "Ellijay"
            },
            {
                "value": 7403,
                "label": "Ellington",
                "label_ar": "إلينغتون",
                "label_fr": "Ellington"
            },
            {
                "value": 7404,
                "label": "Ellinwood",
                "label_ar": "إلينوود",
                "label_fr": "Ellinwood"
            },
            {
                "value": 7405,
                "label": "Elliott",
                "label_ar": "إليوت",
                "label_fr": "Elliott"
            },
            {
                "value": 7406,
                "label": "Elliottsburg",
                "label_ar": "إليوتسبورج",
                "label_fr": "Elliottsburg"
            },
            {
                "value": 7407,
                "label": "Ellis",
                "label_ar": "إليس",
                "label_fr": "Ellis"
            },
            {
                "value": 7408,
                "label": "Ellis Grove",
                "label_ar": "إليس جروف",
                "label_fr": "Ellis Grove"
            },
            {
                "value": 7409,
                "label": "Elliston",
                "label_ar": "إليستون",
                "label_fr": "Elliston"
            },
            {
                "value": 7410,
                "label": "Ellisville",
                "label_ar": "إليسفيل",
                "label_fr": "Ellisville"
            },
            {
                "value": 7411,
                "label": "Elloree",
                "label_ar": "إلوري",
                "label_fr": "Elloree"
            },
            {
                "value": 7412,
                "label": "Ellsinore",
                "label_ar": "إلسينور",
                "label_fr": "Ellsinore"
            },
            {
                "value": 7413,
                "label": "Ellsworth",
                "label_ar": "إلسورث",
                "label_fr": "Ellsworth"
            },
            {
                "value": 7414,
                "label": "Ellwood City",
                "label_ar": "مدينة إلوود",
                "label_fr": "Ellwood City"
            },
            {
                "value": 7415,
                "label": "Elm City",
                "label_ar": "مدينة العلم",
                "label_fr": "Elm City"
            },
            {
                "value": 7416,
                "label": "Elm Creek",
                "label_ar": "إلم كريك",
                "label_fr": "Ruisseau Elm"
            },
            {
                "value": 7417,
                "label": "Elm Grove",
                "label_ar": "إلم جروف",
                "label_fr": "Elm Grove"
            },
            {
                "value": 7418,
                "label": "Elma",
                "label_ar": "إلما",
                "label_fr": "Elma"
            },
            {
                "value": 7419,
                "label": "Elmdale",
                "label_ar": "إلمديل",
                "label_fr": "Elmdale"
            },
            {
                "value": 7420,
                "label": "Elmendorf",
                "label_ar": "إلمندورف",
                "label_fr": "Elmendorf"
            },
            {
                "value": 7421,
                "label": "Elmer",
                "label_ar": "إلمر",
                "label_fr": "Elmer"
            },
            {
                "value": 7422,
                "label": "Elmer City",
                "label_ar": "مدينة المير",
                "label_fr": "Elmer City"
            },
            {
                "value": 7423,
                "label": "Elmhurst",
                "label_ar": "المهرست",
                "label_fr": "Elmhurst"
            },
            {
                "value": 7424,
                "label": "Elmira",
                "label_ar": "الميرا",
                "label_fr": "Elmira"
            },
            {
                "value": 7425,
                "label": "Elmo",
                "label_ar": "إلمو",
                "label_fr": "Elmo"
            },
            {
                "value": 7426,
                "label": "Elmont",
                "label_ar": "إلمونت",
                "label_fr": "Elmont"
            },
            {
                "value": 7427,
                "label": "Elmora",
                "label_ar": "المورا",
                "label_fr": "Elmora"
            },
            {
                "value": 7428,
                "label": "Elmore",
                "label_ar": "إلمور",
                "label_fr": "Elmore"
            },
            {
                "value": 7429,
                "label": "Elmore City",
                "label_ar": "مدينة إلمور",
                "label_fr": "Elmore City"
            },
            {
                "value": 7430,
                "label": "Elmsford",
                "label_ar": "إلمسفورد",
                "label_fr": "Elmsford"
            },
            {
                "value": 7431,
                "label": "Elmwood",
                "label_ar": "خشب الدردار",
                "label_fr": "Bois d'orme"
            },
            {
                "value": 7432,
                "label": "Elmwood Park",
                "label_ar": "إلموود بارك",
                "label_fr": "Parc Elmwood"
            },
            {
                "value": 7433,
                "label": "Elnora",
                "label_ar": "النورة",
                "label_fr": "Elnora"
            },
            {
                "value": 7434,
                "label": "Elon",
                "label_ar": "إيلون",
                "label_fr": "Elon"
            },
            {
                "value": 7435,
                "label": "Elora",
                "label_ar": "إلورا",
                "label_fr": "Elora"
            },
            {
                "value": 7436,
                "label": "Eloy",
                "label_ar": "إلوي",
                "label_fr": "Eloy"
            },
            {
                "value": 7437,
                "label": "Elroy",
                "label_ar": "إلروي",
                "label_fr": "Elroy"
            },
            {
                "value": 7438,
                "label": "Elsa",
                "label_ar": "إلسا",
                "label_fr": "Elsa"
            },
            {
                "value": 7439,
                "label": "Elsah",
                "label_ar": "Elsah",
                "label_fr": "Elsah"
            },
            {
                "value": 7440,
                "label": "Elsberry",
                "label_ar": "التوت",
                "label_fr": "Elsberry"
            },
            {
                "value": 7441,
                "label": "Elsie",
                "label_ar": "إلسي",
                "label_fr": "Elsie"
            },
            {
                "value": 7442,
                "label": "Elsinore",
                "label_ar": "Elsinore",
                "label_fr": "Elseneur"
            },
            {
                "value": 7443,
                "label": "Elton",
                "label_ar": "إلتون",
                "label_fr": "Elton"
            },
            {
                "value": 7444,
                "label": "Eltopia",
                "label_ar": "Eltopia",
                "label_fr": "Eltopia"
            },
            {
                "value": 7445,
                "label": "Elvaston",
                "label_ar": "إلفاستون",
                "label_fr": "Elvaston"
            },
            {
                "value": 7446,
                "label": "Elverson",
                "label_ar": "إلفرسون",
                "label_fr": "Elverson"
            },
            {
                "value": 7447,
                "label": "Elverta",
                "label_ar": "إلفيرتا",
                "label_fr": "Elverta"
            },
            {
                "value": 7448,
                "label": "Elwell",
                "label_ar": "إيلويل",
                "label_fr": "Elwell"
            },
            {
                "value": 7449,
                "label": "Elwood",
                "label_ar": "إلوود",
                "label_fr": "Elwood"
            },
            {
                "value": 7450,
                "label": "Ely",
                "label_ar": "ايلي",
                "label_fr": "Ely"
            },
            {
                "value": 7451,
                "label": "Elyria",
                "label_ar": "إليريا",
                "label_fr": "Elyria"
            },
            {
                "value": 7452,
                "label": "Elysburg",
                "label_ar": "إليسبورغ",
                "label_fr": "Elysbourg"
            },
            {
                "value": 7453,
                "label": "Elysian",
                "label_ar": "إليسيان",
                "label_fr": "élyséen"
            },
            {
                "value": 7454,
                "label": "Elysian Fields",
                "label_ar": "الحقول السماوية",
                "label_fr": "champs-Elysées"
            },
            {
                "value": 7455,
                "label": "Embarrass",
                "label_ar": "يحرج",
                "label_fr": "Embarrasser"
            },
            {
                "value": 7456,
                "label": "Embudo",
                "label_ar": "احتضان",
                "label_fr": "Embudo"
            },
            {
                "value": 7457,
                "label": "Emden",
                "label_ar": "إمدن",
                "label_fr": "Emden"
            },
            {
                "value": 7458,
                "label": "Emerado",
                "label_ar": "إميرادو",
                "label_fr": "Emerado"
            },
            {
                "value": 7459,
                "label": "Emerald Isle",
                "label_ar": "جزيرة الزمرد",
                "label_fr": "Île d'émeraude"
            },
            {
                "value": 7460,
                "label": "Emerson",
                "label_ar": "ايمرسون",
                "label_fr": "Emerson"
            },
            {
                "value": 7461,
                "label": "Emery",
                "label_ar": "ايمري",
                "label_fr": "Émeri"
            },
            {
                "value": 7462,
                "label": "Emeryville",
                "label_ar": "إميريفيل",
                "label_fr": "Emeryville"
            },
            {
                "value": 7463,
                "label": "Emigrant",
                "label_ar": "مهاجر",
                "label_fr": "Émigrant"
            },
            {
                "value": 7464,
                "label": "Emily",
                "label_ar": "إميلي",
                "label_fr": "Emilie"
            },
            {
                "value": 7465,
                "label": "Eminence",
                "label_ar": "سماحة",
                "label_fr": "Éminence"
            },
            {
                "value": 7466,
                "label": "Emlenton",
                "label_ar": "إملينتون",
                "label_fr": "Emlenton"
            },
            {
                "value": 7467,
                "label": "Emmaus",
                "label_ar": "عمواس",
                "label_fr": "Emmaüs"
            },
            {
                "value": 7468,
                "label": "Emmet",
                "label_ar": "إيميت",
                "label_fr": "Emmet"
            },
            {
                "value": 7469,
                "label": "Emmetsburg",
                "label_ar": "ايميتسبورج",
                "label_fr": "Emmetsburg"
            },
            {
                "value": 7470,
                "label": "Emmett",
                "label_ar": "ايميت",
                "label_fr": "Emmett"
            },
            {
                "value": 7471,
                "label": "Emmitsburg",
                "label_ar": "ايميتسبورج",
                "label_fr": "Emmitsburg"
            },
            {
                "value": 7472,
                "label": "Emmons",
                "label_ar": "ايمونز",
                "label_fr": "Emmons"
            },
            {
                "value": 7473,
                "label": "Emory",
                "label_ar": "إيموري",
                "label_fr": "Emory"
            },
            {
                "value": 7474,
                "label": "Empire",
                "label_ar": "إمبراطورية",
                "label_fr": "Empire"
            },
            {
                "value": 7475,
                "label": "Emporia",
                "label_ar": "إمبوريا",
                "label_fr": "Emporia"
            },
            {
                "value": 7476,
                "label": "Emporium",
                "label_ar": "إمبوريوم",
                "label_fr": "Centre commercial"
            },
            {
                "value": 7477,
                "label": "Encampment",
                "label_ar": "المعسكر",
                "label_fr": "Campement"
            },
            {
                "value": 7478,
                "label": "Encinitas",
                "label_ar": "إنسينيتاس",
                "label_fr": "Encinitas"
            },
            {
                "value": 7479,
                "label": "Encino",
                "label_ar": "إنسينو",
                "label_fr": "Encino"
            },
            {
                "value": 7480,
                "label": "Endeavor",
                "label_ar": "المسعى",
                "label_fr": "Effort"
            },
            {
                "value": 7481,
                "label": "Enderlin",
                "label_ar": "إندرلين",
                "label_fr": "Enderlin"
            },
            {
                "value": 7482,
                "label": "Endicott",
                "label_ar": "انديكوت",
                "label_fr": "Endicott"
            },
            {
                "value": 7483,
                "label": "Energy",
                "label_ar": "طاقة",
                "label_fr": "Énergie"
            },
            {
                "value": 7484,
                "label": "Enfield",
                "label_ar": "انفيلد",
                "label_fr": "Enfield"
            },
            {
                "value": 7485,
                "label": "Engelhard",
                "label_ar": "إنجلهارد",
                "label_fr": "Engelhard"
            },
            {
                "value": 7486,
                "label": "England",
                "label_ar": "إنكلترا",
                "label_fr": "Angleterre"
            },
            {
                "value": 7487,
                "label": "Englewood",
                "label_ar": "إنجلوود",
                "label_fr": "Englewood"
            },
            {
                "value": 7488,
                "label": "Englewood Cliffs",
                "label_ar": "إنجليوود كليفس",
                "label_fr": "Falaises d'Englewood"
            },
            {
                "value": 7489,
                "label": "Englishtown",
                "label_ar": "إنجليشتاون",
                "label_fr": "Englishtown"
            },
            {
                "value": 7490,
                "label": "Enid",
                "label_ar": "إنيد",
                "label_fr": "Enid"
            },
            {
                "value": 7491,
                "label": "Enigma",
                "label_ar": "لغز",
                "label_fr": "Énigme"
            },
            {
                "value": 7492,
                "label": "Enka",
                "label_ar": "إنكا",
                "label_fr": "Enka"
            },
            {
                "value": 7493,
                "label": "Ennice",
                "label_ar": "اينيس",
                "label_fr": "Ennice"
            },
            {
                "value": 7494,
                "label": "Ennis",
                "label_ar": "اينيس",
                "label_fr": "Ennis"
            },
            {
                "value": 7495,
                "label": "Enola",
                "label_ar": "إينولا",
                "label_fr": "Enola"
            },
            {
                "value": 7496,
                "label": "Enon",
                "label_ar": "إينون",
                "label_fr": "Enon"
            },
            {
                "value": 7497,
                "label": "Enoree",
                "label_ar": "إينوري",
                "label_fr": "Enoree"
            },
            {
                "value": 7498,
                "label": "Enosburg Falls",
                "label_ar": "شلالات Enosburg",
                "label_fr": "Chutes d'Enosburg"
            },
            {
                "value": 7499,
                "label": "Enterprise",
                "label_ar": "مشروع - مغامرة",
                "label_fr": "Entreprise"
            },
            {
                "value": 7500,
                "label": "Entiat",
                "label_ar": "إنتيات",
                "label_fr": "Entiat"
            },
            {
                "value": 7501,
                "label": "Enumclaw",
                "label_ar": "إنومكلو",
                "label_fr": "Enumclaw"
            },
            {
                "value": 7502,
                "label": "Eolia",
                "label_ar": "يوليا",
                "label_fr": "Eolia"
            },
            {
                "value": 7503,
                "label": "Epes",
                "label_ar": "الحلقات",
                "label_fr": "Epes"
            },
            {
                "value": 7504,
                "label": "Ephraim",
                "label_ar": "افرايم",
                "label_fr": "Éphraïm"
            },
            {
                "value": 7505,
                "label": "Ephrata",
                "label_ar": "افراتا",
                "label_fr": "Ephrata"
            },
            {
                "value": 7506,
                "label": "Epping",
                "label_ar": "إبينج",
                "label_fr": "Epping"
            },
            {
                "value": 7507,
                "label": "Epps",
                "label_ar": "إبس",
                "label_fr": "Epps"
            },
            {
                "value": 7508,
                "label": "Epsom",
                "label_ar": "ابسوم",
                "label_fr": "Epsom"
            },
            {
                "value": 7509,
                "label": "Epworth",
                "label_ar": "إبوورث",
                "label_fr": "Epworth"
            },
            {
                "value": 7510,
                "label": "Equality",
                "label_ar": "المساواة",
                "label_fr": "Égalité"
            },
            {
                "value": 7511,
                "label": "Equinunk",
                "label_ar": "اعتدال",
                "label_fr": "Equinunk"
            },
            {
                "value": 7512,
                "label": "Erath",
                "label_ar": "إراث",
                "label_fr": "Erath"
            },
            {
                "value": 7513,
                "label": "Erbacon",
                "label_ar": "ارباكون",
                "label_fr": "Erbacon"
            },
            {
                "value": 7514,
                "label": "Erhard",
                "label_ar": "إرهارد",
                "label_fr": "Erhard"
            },
            {
                "value": 7515,
                "label": "Erick",
                "label_ar": "إريك",
                "label_fr": "Erick"
            },
            {
                "value": 7516,
                "label": "Erie",
                "label_ar": "إيري",
                "label_fr": "Érié"
            },
            {
                "value": 7517,
                "label": "Erieville",
                "label_ar": "اريفيل",
                "label_fr": "Erieville"
            },
            {
                "value": 7518,
                "label": "Erin",
                "label_ar": "ايرين",
                "label_fr": "Erin"
            },
            {
                "value": 7519,
                "label": "Erlanger",
                "label_ar": "إرلانجر",
                "label_fr": "Erlanger"
            },
            {
                "value": 7520,
                "label": "Eros",
                "label_ar": "إيروس",
                "label_fr": "Éros"
            },
            {
                "value": 7521,
                "label": "Errol",
                "label_ar": "إيرول",
                "label_fr": "Errol"
            },
            {
                "value": 7522,
                "label": "Erskine",
                "label_ar": "إرسكين",
                "label_fr": "Erskine"
            },
            {
                "value": 7523,
                "label": "Erving",
                "label_ar": "ايرفينغ",
                "label_fr": "Erving"
            },
            {
                "value": 7524,
                "label": "Ervings Location",
                "label_ar": "موقع Ervings",
                "label_fr": "Localisation Ervings"
            },
            {
                "value": 7525,
                "label": "Erwin",
                "label_ar": "اروين",
                "label_fr": "Erwin"
            },
            {
                "value": 7526,
                "label": "Erwinna",
                "label_ar": "اروينا",
                "label_fr": "Erwinna"
            },
            {
                "value": 7527,
                "label": "Erwinville",
                "label_ar": "اروينفيل",
                "label_fr": "Erwinville"
            },
            {
                "value": 7528,
                "label": "Esbon",
                "label_ar": "اسبون",
                "label_fr": "Esbon"
            },
            {
                "value": 7529,
                "label": "Escalante",
                "label_ar": "إسكالانتي",
                "label_fr": "Escalante"
            },
            {
                "value": 7530,
                "label": "Escalon",
                "label_ar": "اسكالون",
                "label_fr": "Escalon"
            },
            {
                "value": 7531,
                "label": "Escanaba",
                "label_ar": "إسكانابا",
                "label_fr": "Escanaba"
            },
            {
                "value": 7532,
                "label": "Escondido",
                "label_ar": "إسكونديدو",
                "label_fr": "Escondido"
            },
            {
                "value": 7533,
                "label": "Esko",
                "label_ar": "اسكو",
                "label_fr": "Esko"
            },
            {
                "value": 7534,
                "label": "Eskridge",
                "label_ar": "إسكريدج",
                "label_fr": "Eskridge"
            },
            {
                "value": 7535,
                "label": "Esmond",
                "label_ar": "ازموند",
                "label_fr": "Esmond"
            },
            {
                "value": 7536,
                "label": "Esmont",
                "label_ar": "إزمونت",
                "label_fr": "Esmont"
            },
            {
                "value": 7537,
                "label": "Espanola",
                "label_ar": "اسبانولا",
                "label_fr": "Espanola"
            },
            {
                "value": 7538,
                "label": "Esparto",
                "label_ar": "اسبارتو",
                "label_fr": "Sparte"
            },
            {
                "value": 7539,
                "label": "Esperance",
                "label_ar": "الترجي",
                "label_fr": "Espérance"
            },
            {
                "value": 7540,
                "label": "Essex",
                "label_ar": "إسكس",
                "label_fr": "Essex"
            },
            {
                "value": 7541,
                "label": "Essex Fells",
                "label_ar": "إسكس فيلز",
                "label_fr": "Essex Fells"
            },
            {
                "value": 7542,
                "label": "Essex Junction",
                "label_ar": "مفرق إسكس",
                "label_fr": "Jonction d'Essex"
            },
            {
                "value": 7543,
                "label": "Essexville",
                "label_ar": "إسكسفيل",
                "label_fr": "Essexville"
            },
            {
                "value": 7544,
                "label": "Essie",
                "label_ar": "إيسي",
                "label_fr": "Essie"
            },
            {
                "value": 7545,
                "label": "Essington",
                "label_ar": "إيسينغتون",
                "label_fr": "Essington"
            },
            {
                "value": 7546,
                "label": "Estacada",
                "label_ar": "إستاكادا",
                "label_fr": "Estacada"
            },
            {
                "value": 7547,
                "label": "Estancia",
                "label_ar": "إستانسيا",
                "label_fr": "Estancia"
            },
            {
                "value": 7548,
                "label": "Estell Manor",
                "label_ar": "إستل مانور",
                "label_fr": "Manoir Estell"
            },
            {
                "value": 7549,
                "label": "Estelline",
                "label_ar": "إستلين",
                "label_fr": "Estelline"
            },
            {
                "value": 7550,
                "label": "Estero",
                "label_ar": "استيرو",
                "label_fr": "Estero"
            },
            {
                "value": 7551,
                "label": "Estes Park",
                "label_ar": "إستس بارك",
                "label_fr": "Parc Estes"
            },
            {
                "value": 7552,
                "label": "Estherville",
                "label_ar": "استرفيل",
                "label_fr": "Estherville"
            },
            {
                "value": 7553,
                "label": "Estherwood",
                "label_ar": "استيروود",
                "label_fr": "Estherwood"
            },
            {
                "value": 7554,
                "label": "Estill",
                "label_ar": "إستيل",
                "label_fr": "Estill"
            },
            {
                "value": 7555,
                "label": "Estill Springs",
                "label_ar": "إستيل سبرينغز",
                "label_fr": "Estill Springs"
            },
            {
                "value": 7556,
                "label": "Estillfork",
                "label_ar": "إستيلفورك",
                "label_fr": "Estillfork"
            },
            {
                "value": 7557,
                "label": "Ethel",
                "label_ar": "اثيل",
                "label_fr": "Ethel"
            },
            {
                "value": 7558,
                "label": "Ethelsville",
                "label_ar": "ايثيلسفيل",
                "label_fr": "Ethelsville"
            },
            {
                "value": 7559,
                "label": "Ethridge",
                "label_ar": "إيثريدج",
                "label_fr": "Ethridge"
            },
            {
                "value": 7560,
                "label": "Etna",
                "label_ar": "إتنا",
                "label_fr": "Etna"
            },
            {
                "value": 7561,
                "label": "Etna Green",
                "label_ar": "إتنا جرين",
                "label_fr": "Etna vert"
            },
            {
                "value": 7562,
                "label": "Etoile",
                "label_ar": "نجم",
                "label_fr": "Etoile"
            },
            {
                "value": 7563,
                "label": "Eton",
                "label_ar": "إيتون",
                "label_fr": "Eton"
            },
            {
                "value": 7564,
                "label": "Etowah",
                "label_ar": "إيتواه",
                "label_fr": "Etowah"
            },
            {
                "value": 7565,
                "label": "Etta",
                "label_ar": "إيتا",
                "label_fr": "Etta"
            },
            {
                "value": 7566,
                "label": "Ettrick",
                "label_ar": "إتريك",
                "label_fr": "Ettrick"
            },
            {
                "value": 7567,
                "label": "Eubank",
                "label_ar": "يوبانك",
                "label_fr": "Eubank"
            },
            {
                "value": 7568,
                "label": "Eucha",
                "label_ar": "يوتشا",
                "label_fr": "Eucha"
            },
            {
                "value": 7569,
                "label": "Euclid",
                "label_ar": "إقليدس",
                "label_fr": "Euclide"
            },
            {
                "value": 7570,
                "label": "Eudora",
                "label_ar": "يودورا",
                "label_fr": "Eudora"
            },
            {
                "value": 7571,
                "label": "Eufaula",
                "label_ar": "يوفولا",
                "label_fr": "Eufaula"
            },
            {
                "value": 7572,
                "label": "Eugene",
                "label_ar": "يوجين",
                "label_fr": "Eugène"
            },
            {
                "value": 7573,
                "label": "Euless",
                "label_ar": "يوليس",
                "label_fr": "Euless"
            },
            {
                "value": 7574,
                "label": "Eunice",
                "label_ar": "يونيس",
                "label_fr": "Eunice"
            },
            {
                "value": 7575,
                "label": "Eupora",
                "label_ar": "يوبورا",
                "label_fr": "Eupora"
            },
            {
                "value": 7576,
                "label": "Eureka",
                "label_ar": "يوريكا",
                "label_fr": "Eurêka"
            },
            {
                "value": 7577,
                "label": "Eureka Springs",
                "label_ar": "يوريكا سبرينجز",
                "label_fr": "Eureka Springs"
            },
            {
                "value": 7578,
                "label": "Eustace",
                "label_ar": "يوستاس",
                "label_fr": "Eustache"
            },
            {
                "value": 7579,
                "label": "Eustis",
                "label_ar": "يوستيس",
                "label_fr": "Eustis"
            },
            {
                "value": 7580,
                "label": "Eutaw",
                "label_ar": "يوتاو",
                "label_fr": "Eutaw"
            },
            {
                "value": 7581,
                "label": "Eutawville",
                "label_ar": "يوتاوفيل",
                "label_fr": "Eutawville"
            },
            {
                "value": 7582,
                "label": "Eva",
                "label_ar": "إيفا",
                "label_fr": "Eva"
            },
            {
                "value": 7583,
                "label": "Evangeline",
                "label_ar": "إيفانجلين",
                "label_fr": "Evangeline"
            },
            {
                "value": 7584,
                "label": "Evans",
                "label_ar": "إيفانز",
                "label_fr": "Evans"
            },
            {
                "value": 7585,
                "label": "Evans City",
                "label_ar": "إيفانز سيتي",
                "label_fr": "Evans City"
            },
            {
                "value": 7586,
                "label": "Evans Mills",
                "label_ar": "إيفانز ميلز",
                "label_fr": "Evans Mills"
            },
            {
                "value": 7587,
                "label": "Evansdale",
                "label_ar": "إيفانسديل",
                "label_fr": "Evansdale"
            },
            {
                "value": 7588,
                "label": "Evanston",
                "label_ar": "إيفانستون",
                "label_fr": "Evanston"
            },
            {
                "value": 7589,
                "label": "Evansville",
                "label_ar": "إيفانسفيل",
                "label_fr": "Evansville"
            },
            {
                "value": 7590,
                "label": "Evant",
                "label_ar": "إيفانت",
                "label_fr": "Evant"
            },
            {
                "value": 7591,
                "label": "Evart",
                "label_ar": "إيفارت",
                "label_fr": "Evart"
            },
            {
                "value": 7592,
                "label": "Evarts",
                "label_ar": "إيفارتس",
                "label_fr": "Evarts"
            },
            {
                "value": 7593,
                "label": "Eveleth",
                "label_ar": "إيفليث",
                "label_fr": "Eveleth"
            },
            {
                "value": 7594,
                "label": "Evensville",
                "label_ar": "إيفينسفيل",
                "label_fr": "Evensville"
            },
            {
                "value": 7595,
                "label": "Everest",
                "label_ar": "قمة افرست",
                "label_fr": "Everest"
            },
            {
                "value": 7596,
                "label": "Everett",
                "label_ar": "إيفريت",
                "label_fr": "Everett"
            },
            {
                "value": 7597,
                "label": "Everetts",
                "label_ar": "ايفرتس",
                "label_fr": "Everetts"
            },
            {
                "value": 7598,
                "label": "Everglades City",
                "label_ar": "مدينة إيفرجليدز",
                "label_fr": "Everglades City"
            },
            {
                "value": 7599,
                "label": "Evergreen",
                "label_ar": "دائم الخضرة",
                "label_fr": "À feuilles persistantes"
            },
            {
                "value": 7600,
                "label": "Evergreen Park",
                "label_ar": "ايفرجرين بارك",
                "label_fr": "Parc Evergreen"
            },
            {
                "value": 7602,
                "label": "Everson",
                "label_ar": "إيفرسون",
                "label_fr": "Everson"
            },
            {
                "value": 7603,
                "label": "Everton",
                "label_ar": "إيفرتون",
                "label_fr": "Everton"
            },
            {
                "value": 7604,
                "label": "Evington",
                "label_ar": "إيفينجتون",
                "label_fr": "Evington"
            },
            {
                "value": 7605,
                "label": "Ewan",
                "label_ar": "إيوان",
                "label_fr": "Ewan"
            },
            {
                "value": 7606,
                "label": "Ewen",
                "label_ar": "اوين",
                "label_fr": "Ewen"
            },
            {
                "value": 7607,
                "label": "Ewing",
                "label_ar": "إوينغ",
                "label_fr": "Ewing"
            },
            {
                "value": 7608,
                "label": "Excel",
                "label_ar": "اكسل",
                "label_fr": "Exceller"
            },
            {
                "value": 7609,
                "label": "Excello",
                "label_ar": "اكسكسيلو",
                "label_fr": "Excello"
            },
            {
                "value": 7610,
                "label": "Excelsior",
                "label_ar": "نجارة",
                "label_fr": "Excelsior"
            },
            {
                "value": 7611,
                "label": "Excelsior Springs",
                "label_ar": "اكسلسيور سبرينغز",
                "label_fr": "Excelsior Springs"
            },
            {
                "value": 7612,
                "label": "Exeland",
                "label_ar": "اكسلاند",
                "label_fr": "Exeland"
            },
            {
                "value": 7613,
                "label": "Exeter",
                "label_ar": "إكستر",
                "label_fr": "Exeter"
            },
            {
                "value": 7614,
                "label": "Exira",
                "label_ar": "اكسيرا",
                "label_fr": "Exira"
            },
            {
                "value": 7615,
                "label": "Exline",
                "label_ar": "إكسلين",
                "label_fr": "Exline"
            },
            {
                "value": 7616,
                "label": "Exmore",
                "label_ar": "اكسمور",
                "label_fr": "Exmore"
            },
            {
                "value": 7617,
                "label": "Experiment",
                "label_ar": "تجربة",
                "label_fr": "Expérience"
            },
            {
                "value": 7618,
                "label": "Export",
                "label_ar": "تصدير",
                "label_fr": "Exportation"
            },
            {
                "value": 7619,
                "label": "Exton",
                "label_ar": "اكستون",
                "label_fr": "Exton"
            },
            {
                "value": 7620,
                "label": "Eyota",
                "label_ar": "ايوتا",
                "label_fr": "Eyota"
            },
            {
                "value": 7621,
                "label": "Ezel",
                "label_ar": "ايزل",
                "label_fr": "Ezel"
            },
            {
                "value": 7622,
                "label": "Fabens",
                "label_ar": "فابينز",
                "label_fr": "Fabens"
            },
            {
                "value": 7623,
                "label": "Faber",
                "label_ar": "فابر",
                "label_fr": "Faber"
            },
            {
                "value": 7624,
                "label": "Fabius",
                "label_ar": "فابيوس",
                "label_fr": "Fabius"
            },
            {
                "value": 7625,
                "label": "Fackler",
                "label_ar": "فاكلر",
                "label_fr": "Fackler"
            },
            {
                "value": 7626,
                "label": "Factoryville",
                "label_ar": "فاكتوريفيل",
                "label_fr": "Factoryville"
            },
            {
                "value": 7627,
                "label": "Fair Bluff",
                "label_ar": "عادل بلاف",
                "label_fr": "Fair Bluff"
            },
            {
                "value": 7628,
                "label": "Fair Grove",
                "label_ar": "معرض جروف",
                "label_fr": "Fair Grove"
            },
            {
                "value": 7629,
                "label": "Fair Haven",
                "label_ar": "Fair Haven",
                "label_fr": "Fair Haven"
            },
            {
                "value": 7630,
                "label": "Fair Lawn",
                "label_ar": "عادل لون",
                "label_fr": "Bonne pelouse"
            },
            {
                "value": 7631,
                "label": "Fair Oaks",
                "label_ar": "معرض أوكس",
                "label_fr": "Fair Oaks"
            },
            {
                "value": 7632,
                "label": "Fair Play",
                "label_ar": "اللعب العادل",
                "label_fr": "Fair-play"
            },
            {
                "value": 7633,
                "label": "Fairacres",
                "label_ar": "Fairacres",
                "label_fr": "Fairacres"
            },
            {
                "value": 7634,
                "label": "Fairbank",
                "label_ar": "فيربانك",
                "label_fr": "Fairbank"
            },
            {
                "value": 7635,
                "label": "Fairbanks",
                "label_ar": "فيربانكس",
                "label_fr": "Fairbanks"
            },
            {
                "value": 7636,
                "label": "Fairbanks North Star Borough",
                "label_ar": "فيربانكس نورث ستار بورو",
                "label_fr": "Arrondissement de Fairbanks North Star"
            },
            {
                "value": 7637,
                "label": "Fairborn",
                "label_ar": "فيربورن",
                "label_fr": "Fairborn"
            },
            {
                "value": 7638,
                "label": "Fairburn",
                "label_ar": "فيربورن",
                "label_fr": "Fairburn"
            },
            {
                "value": 7639,
                "label": "Fairbury",
                "label_ar": "فيربري",
                "label_fr": "Fairbury"
            },
            {
                "value": 7640,
                "label": "Fairchance",
                "label_ar": "فرصة عادلة",
                "label_fr": "Fairchance"
            },
            {
                "value": 7641,
                "label": "Fairchild",
                "label_ar": "فيرتشايلد",
                "label_fr": "Fairchild"
            },
            {
                "value": 7642,
                "label": "Fairchild Air Force Base",
                "label_ar": "قاعدة فيرتشايلد الجوية",
                "label_fr": "Base aérienne de Fairchild"
            },
            {
                "value": 7643,
                "label": "Fairdale",
                "label_ar": "فيرديل",
                "label_fr": "Fairdale"
            },
            {
                "value": 7644,
                "label": "Fairdealing",
                "label_ar": "Fairdealing",
                "label_fr": "Fairdealing"
            },
            {
                "value": 7645,
                "label": "Fairfax",
                "label_ar": "فيرفاكس",
                "label_fr": "Fairfax"
            },
            {
                "value": 7646,
                "label": "Fairfax Station",
                "label_ar": "محطة فيرفاكس",
                "label_fr": "Gare de Fairfax"
            },
            {
                "value": 7647,
                "label": "Fairfield",
                "label_ar": "فيرفيلد",
                "label_fr": "Fairfield"
            },
            {
                "value": 7648,
                "label": "Fairfield Bay",
                "label_ar": "فيرفيلد باي",
                "label_fr": "Fairfield Bay"
            },
            {
                "value": 7649,
                "label": "Fairgrove",
                "label_ar": "فيرغروف",
                "label_fr": "Fairgrove"
            },
            {
                "value": 7650,
                "label": "Fairhaven",
                "label_ar": "فيرهافن",
                "label_fr": "Fairhaven"
            },
            {
                "value": 7651,
                "label": "Fairhope",
                "label_ar": "فيرهوب",
                "label_fr": "Fairhope"
            },
            {
                "value": 7652,
                "label": "Fairland",
                "label_ar": "فيرلاند",
                "label_fr": "Fairland"
            },
            {
                "value": 7653,
                "label": "Fairlee",
                "label_ar": "فيرلي",
                "label_fr": "Fairlee"
            },
            {
                "value": 7654,
                "label": "Fairless Hills",
                "label_ar": "تلال فيرليس",
                "label_fr": "Fairless Hills"
            },
            {
                "value": 7655,
                "label": "Fairmont",
                "label_ar": "فيرمونت",
                "label_fr": "Fairmont"
            },
            {
                "value": 7656,
                "label": "Fairmont City",
                "label_ar": "فيرمونت سيتي",
                "label_fr": "Fairmont City"
            },
            {
                "value": 7657,
                "label": "Fairmount",
                "label_ar": "فيرمونت",
                "label_fr": "Fairmount"
            },
            {
                "value": 7658,
                "label": "Fairplay",
                "label_ar": "اللعب العادل",
                "label_fr": "Fair-play"
            },
            {
                "value": 7659,
                "label": "Fairport",
                "label_ar": "فيربورت",
                "label_fr": "Fairport"
            },
            {
                "value": 7660,
                "label": "Fairton",
                "label_ar": "فيرتون",
                "label_fr": "Fairton"
            },
            {
                "value": 7661,
                "label": "Fairview",
                "label_ar": "فيرفيو",
                "label_fr": "Fairview"
            },
            {
                "value": 7662,
                "label": "Fairview Heights",
                "label_ar": "فيرفيو هايتس",
                "label_fr": "Fairview Heights"
            },
            {
                "value": 7663,
                "label": "Fairview Village",
                "label_ar": "قرية فيرفيو",
                "label_fr": "Village de Fairview"
            },
            {
                "value": 7664,
                "label": "Faith",
                "label_ar": "الإيمان",
                "label_fr": "Foi"
            },
            {
                "value": 7665,
                "label": "Falcon",
                "label_ar": "فالكون",
                "label_fr": "Faucon"
            },
            {
                "value": 7666,
                "label": "Falconer",
                "label_ar": "الصقار",
                "label_fr": "Fauconnier"
            },
            {
                "value": 7667,
                "label": "Falfurrias",
                "label_ar": "فالفورياس",
                "label_fr": "Falfurrias"
            },
            {
                "value": 7668,
                "label": "Falkland",
                "label_ar": "فوكلاند",
                "label_fr": "Falkland"
            },
            {
                "value": 7669,
                "label": "Falkner",
                "label_ar": "فولكنر",
                "label_fr": "Falkner"
            },
            {
                "value": 7670,
                "label": "Falkville",
                "label_ar": "فالكفيل",
                "label_fr": "Falkville"
            },
            {
                "value": 7671,
                "label": "Fall Branch",
                "label_ar": "فرع الخريف",
                "label_fr": "Branche d'automne"
            },
            {
                "value": 7672,
                "label": "Fall City",
                "label_ar": "فال سيتي",
                "label_fr": "Ville d'automne"
            },
            {
                "value": 7673,
                "label": "Fall Creek",
                "label_ar": "فال كريك",
                "label_fr": "Fall Creek"
            },
            {
                "value": 7674,
                "label": "Fall River",
                "label_ar": "فال ريفر",
                "label_fr": "Fall River"
            },
            {
                "value": 7675,
                "label": "Fall River Mills",
                "label_ar": "فول ريفر ميلز",
                "label_fr": "Fall River Mills"
            },
            {
                "value": 7676,
                "label": "Fallbrook",
                "label_ar": "فالبروك",
                "label_fr": "Fallbrook"
            },
            {
                "value": 7677,
                "label": "Falling Rock",
                "label_ar": "حجر هاوي",
                "label_fr": "Chute de rocher"
            },
            {
                "value": 7678,
                "label": "Falling Waters",
                "label_ar": "سقوط المياه",
                "label_fr": "Chute des eaux"
            },
            {
                "value": 7679,
                "label": "Fallon",
                "label_ar": "سقط على",
                "label_fr": "Tomber sur"
            },
            {
                "value": 7680,
                "label": "Falls",
                "label_ar": "السقوط",
                "label_fr": "Des chutes"
            },
            {
                "value": 7681,
                "label": "Falls Church",
                "label_ar": "فولز تشيرش",
                "label_fr": "Église des chutes"
            },
            {
                "value": 7682,
                "label": "Falls City",
                "label_ar": "فولز سيتي",
                "label_fr": "Falls City"
            },
            {
                "value": 7683,
                "label": "Falls Creek",
                "label_ar": "فولز كريك",
                "label_fr": "Falls Creek"
            },
            {
                "value": 7684,
                "label": "Falls Mills",
                "label_ar": "فولز ميلز",
                "label_fr": "Falls Mills"
            },
            {
                "value": 7685,
                "label": "Falls Village",
                "label_ar": "قرية فولز",
                "label_fr": "Village des chutes"
            },
            {
                "value": 7686,
                "label": "Falls of Rough",
                "label_ar": "شلالات الخام",
                "label_fr": "Chutes de rugueux"
            },
            {
                "value": 7687,
                "label": "Fallsburg",
                "label_ar": "فولسبورغ",
                "label_fr": "Fallsburg"
            },
            {
                "value": 7688,
                "label": "Fallston",
                "label_ar": "فالستون",
                "label_fr": "Fallston"
            },
            {
                "value": 7689,
                "label": "Falmouth",
                "label_ar": "فالماوث",
                "label_fr": "Falmouth"
            },
            {
                "value": 7690,
                "label": "Fancy Farm",
                "label_ar": "مزرعة فاخرة",
                "label_fr": "Fantaisie Ferme"
            },
            {
                "value": 7691,
                "label": "Fannettsburg",
                "label_ar": "فانيتسبيرغ",
                "label_fr": "Fannettsburg"
            },
            {
                "value": 7692,
                "label": "Fanwood",
                "label_ar": "فانوود",
                "label_fr": "Fanwood"
            },
            {
                "value": 7693,
                "label": "Far Hills",
                "label_ar": "فار هيلز",
                "label_fr": "Far Hills"
            },
            {
                "value": 7694,
                "label": "Far Rockaway",
                "label_ar": "فار روكواي",
                "label_fr": "Far Rockaway"
            },
            {
                "value": 7695,
                "label": "Farber",
                "label_ar": "فاربر",
                "label_fr": "Farber"
            },
            {
                "value": 7696,
                "label": "Fargo",
                "label_ar": "فارجو",
                "label_fr": "Fargo"
            },
            {
                "value": 7697,
                "label": "Faribault",
                "label_ar": "فاريبولت",
                "label_fr": "Faribault"
            },
            {
                "value": 7698,
                "label": "Farina",
                "label_ar": "فارينا",
                "label_fr": "Farine"
            },
            {
                "value": 7699,
                "label": "Farley",
                "label_ar": "فارلي",
                "label_fr": "Farley"
            },
            {
                "value": 7700,
                "label": "Farlington",
                "label_ar": "فارلينجتون",
                "label_fr": "Farlington"
            },
            {
                "value": 7701,
                "label": "Farmdale",
                "label_ar": "فارمديل",
                "label_fr": "Farmdale"
            },
            {
                "value": 7702,
                "label": "Farmer City",
                "label_ar": "مدينة المزارعين",
                "label_fr": "Ville de fermier"
            },
            {
                "value": 7703,
                "label": "Farmersburg",
                "label_ar": "فارمرسبيرغ",
                "label_fr": "Farmersburg"
            },
            {
                "value": 7704,
                "label": "Farmersville",
                "label_ar": "فارمرسفيل",
                "label_fr": "Farmersville"
            },
            {
                "value": 7705,
                "label": "Farmerville",
                "label_ar": "فارمرفيل",
                "label_fr": "Farmerville"
            },
            {
                "value": 7706,
                "label": "Farmingdale",
                "label_ar": "فارمنجديل",
                "label_fr": "Farmingdale"
            },
            {
                "value": 7707,
                "label": "Farmington",
                "label_ar": "فارمنجتون",
                "label_fr": "Farmington"
            },
            {
                "value": 7708,
                "label": "Farmington Hills",
                "label_ar": "فارمنجتون هيلز",
                "label_fr": "Farmington Hills"
            },
            {
                "value": 7709,
                "label": "Farmingville",
                "label_ar": "فارمينجفيل",
                "label_fr": "Farmingville"
            },
            {
                "value": 7710,
                "label": "Farmland",
                "label_ar": "الأرض الزراعية",
                "label_fr": "Les terres agricoles"
            },
            {
                "value": 7711,
                "label": "Farmville",
                "label_ar": "فارم فيل",
                "label_fr": "Farmville"
            },
            {
                "value": 7712,
                "label": "Farnham",
                "label_ar": "فارنهام",
                "label_fr": "Farnham"
            },
            {
                "value": 7713,
                "label": "Farnhamville",
                "label_ar": "فارنامفيل",
                "label_fr": "Farnhamville"
            },
            {
                "value": 7714,
                "label": "Farragut",
                "label_ar": "فراجوت",
                "label_fr": "Farragut"
            },
            {
                "value": 7715,
                "label": "Farrell",
                "label_ar": "فاريل",
                "label_fr": "Farrell"
            },
            {
                "value": 7716,
                "label": "Farson",
                "label_ar": "فارسون",
                "label_fr": "Farson"
            },
            {
                "value": 7717,
                "label": "Farwell",
                "label_ar": "توديع",
                "label_fr": "Farwell"
            },
            {
                "value": 7718,
                "label": "Faulkner",
                "label_ar": "فولكنر",
                "label_fr": "Faulkner"
            },
            {
                "value": 7719,
                "label": "Faulkton",
                "label_ar": "فولكتون",
                "label_fr": "Faulkton"
            },
            {
                "value": 7720,
                "label": "Fawn Grove",
                "label_ar": "فاون جروف",
                "label_fr": "Fawn Grove"
            },
            {
                "value": 7721,
                "label": "Fawnskin",
                "label_ar": "Fawnskin",
                "label_fr": "Fawnskin"
            },
            {
                "value": 7722,
                "label": "Faxon",
                "label_ar": "فاكسون",
                "label_fr": "Faxon"
            },
            {
                "value": 7723,
                "label": "Fayette",
                "label_ar": "فاييت",
                "label_fr": "Fayette"
            },
            {
                "value": 7724,
                "label": "Fayette City",
                "label_ar": "مدينة فاييت",
                "label_fr": "Ville de Fayette"
            },
            {
                "value": 7725,
                "label": "Fayetteville",
                "label_ar": "فايتفيل",
                "label_fr": "Fayetteville"
            },
            {
                "value": 7726,
                "label": "Fayville",
                "label_ar": "فايفيل",
                "label_fr": "Fayville"
            },
            {
                "value": 7727,
                "label": "Feasterville-Trevose",
                "label_ar": "فيسترفيل تريفوس",
                "label_fr": "Feasterville-Trevose"
            },
            {
                "value": 7728,
                "label": "Federal Way",
                "label_ar": "الطريقة الفيدرالية",
                "label_fr": "Voie fédérale"
            },
            {
                "value": 7729,
                "label": "Federalsburg",
                "label_ar": "فيدرالسبورج",
                "label_fr": "Federalsburg"
            },
            {
                "value": 7730,
                "label": "Feeding Hills",
                "label_ar": "تلال التغذية",
                "label_fr": "Nourrir les collines"
            },
            {
                "value": 7731,
                "label": "Felda",
                "label_ar": "فيلدا",
                "label_fr": "Felda"
            },
            {
                "value": 7732,
                "label": "Felicity",
                "label_ar": "فيليسيتي",
                "label_fr": "Félicité"
            },
            {
                "value": 7733,
                "label": "Fellsmere",
                "label_ar": "فيلسمير",
                "label_fr": "Fellsmere"
            },
            {
                "value": 7734,
                "label": "Felton",
                "label_ar": "فيلتون",
                "label_fr": "Felton"
            },
            {
                "value": 7735,
                "label": "Fenelton",
                "label_ar": "فينلتون",
                "label_fr": "Fenelton"
            },
            {
                "value": 7736,
                "label": "Fennimore",
                "label_ar": "فينيمور",
                "label_fr": "Fennimore"
            },
            {
                "value": 7737,
                "label": "Fennville",
                "label_ar": "فينفيل",
                "label_fr": "Fennville"
            },
            {
                "value": 7738,
                "label": "Fenton",
                "label_ar": "فنتون",
                "label_fr": "Fenton"
            },
            {
                "value": 7739,
                "label": "Fentress",
                "label_ar": "محاصرة",
                "label_fr": "Fentress"
            },
            {
                "value": 7740,
                "label": "Fenwick",
                "label_ar": "فينويك",
                "label_fr": "Fenwick"
            },
            {
                "value": 7741,
                "label": "Fenwick Island",
                "label_ar": "جزيرة فينويك",
                "label_fr": "Île Fenwick"
            },
            {
                "value": 7742,
                "label": "Ferdinand",
                "label_ar": "فرديناند",
                "label_fr": "Ferdinand"
            },
            {
                "value": 7743,
                "label": "Fergus Falls",
                "label_ar": "شلالات فيرغوس",
                "label_fr": "Fergus Falls"
            },
            {
                "value": 7744,
                "label": "Ferguson",
                "label_ar": "فيرغسون",
                "label_fr": "Ferguson"
            },
            {
                "value": 7745,
                "label": "Fernandina Beach",
                "label_ar": "شاطئ فرناندينا",
                "label_fr": "Plage de Fernandina"
            },
            {
                "value": 7746,
                "label": "Ferndale",
                "label_ar": "فيرنديل",
                "label_fr": "Ferndale"
            },
            {
                "value": 7747,
                "label": "Fernley",
                "label_ar": "فيرنلي",
                "label_fr": "Fernley"
            },
            {
                "value": 7748,
                "label": "Fernwood",
                "label_ar": "فيرنوود",
                "label_fr": "Fernwood"
            },
            {
                "value": 7749,
                "label": "Ferriday",
                "label_ar": "فيريدي",
                "label_fr": "Ferriday"
            },
            {
                "value": 7750,
                "label": "Ferris",
                "label_ar": "فيريس",
                "label_fr": "Ferris"
            },
            {
                "value": 7751,
                "label": "Ferrisburgh",
                "label_ar": "فيريسبرج",
                "label_fr": "Ferrisburgh"
            },
            {
                "value": 7752,
                "label": "Ferron",
                "label_ar": "فيرون",
                "label_fr": "Ferron"
            },
            {
                "value": 7753,
                "label": "Ferrum",
                "label_ar": "فيروم",
                "label_fr": "Ferrum"
            },
            {
                "value": 7754,
                "label": "Ferrysburg",
                "label_ar": "فيريسبورغ",
                "label_fr": "Ferrysburg"
            },
            {
                "value": 7755,
                "label": "Fertile",
                "label_ar": "خصب",
                "label_fr": "Fertile"
            },
            {
                "value": 7756,
                "label": "Fessenden",
                "label_ar": "فيسيندين",
                "label_fr": "Fessenden"
            },
            {
                "value": 7757,
                "label": "Festus",
                "label_ar": "فيستوس",
                "label_fr": "Festus"
            },
            {
                "value": 7758,
                "label": "Feura Bush",
                "label_ar": "فيورا بوش",
                "label_fr": "Feura Bush"
            },
            {
                "value": 7759,
                "label": "Fiddletown",
                "label_ar": "فيدل تاون",
                "label_fr": "Fiddletown"
            },
            {
                "value": 7760,
                "label": "Fidelity",
                "label_ar": "الاخلاص",
                "label_fr": "fidélité"
            },
            {
                "value": 7761,
                "label": "Fieldale",
                "label_ar": "فيلدال",
                "label_fr": "Fieldale"
            },
            {
                "value": 7762,
                "label": "Fielding",
                "label_ar": "فيلدينغ",
                "label_fr": "Fielding"
            },
            {
                "value": 7763,
                "label": "Fieldon",
                "label_ar": "فيلدون",
                "label_fr": "Fieldon"
            },
            {
                "value": 7764,
                "label": "Fife Lake",
                "label_ar": "بحيرة فايف",
                "label_fr": "Lac Fife"
            },
            {
                "value": 7765,
                "label": "Fifty Lakes",
                "label_ar": "خمسون بحيرات",
                "label_fr": "Cinquante lacs"
            },
            {
                "value": 7766,
                "label": "Filer",
                "label_ar": "المصنف",
                "label_fr": "Déposant"
            },
            {
                "value": 7767,
                "label": "Filley",
                "label_ar": "فيلي",
                "label_fr": "Filley"
            },
            {
                "value": 7768,
                "label": "Fillmore",
                "label_ar": "فيلمور",
                "label_fr": "Fillmore"
            },
            {
                "value": 7769,
                "label": "Fincastle",
                "label_ar": "فينكاسل",
                "label_fr": "Fincastle"
            },
            {
                "value": 7770,
                "label": "Finchville",
                "label_ar": "فينشفيل",
                "label_fr": "Finchville"
            },
            {
                "value": 7771,
                "label": "Findlay",
                "label_ar": "فيندلي",
                "label_fr": "Findlay"
            },
            {
                "value": 7772,
                "label": "Finger",
                "label_ar": "اصبع اليد",
                "label_fr": "Doigt"
            },
            {
                "value": 7773,
                "label": "Fingerville",
                "label_ar": "فينجرفيل",
                "label_fr": "Fingerville"
            },
            {
                "value": 7774,
                "label": "Finksburg",
                "label_ar": "فينكسبورج",
                "label_fr": "Finksburg"
            },
            {
                "value": 7775,
                "label": "Finlayson",
                "label_ar": "فينلايسون",
                "label_fr": "Finlayson"
            },
            {
                "value": 7776,
                "label": "Finley",
                "label_ar": "فينلي",
                "label_fr": "Finley"
            },
            {
                "value": 7777,
                "label": "Finleyville",
                "label_ar": "فينليفيل",
                "label_fr": "Finleyville"
            },
            {
                "value": 7778,
                "label": "Firebaugh",
                "label_ar": "فايرباو",
                "label_fr": "Firebaugh"
            },
            {
                "value": 7779,
                "label": "Firestone",
                "label_ar": "فايرستون",
                "label_fr": "Firestone"
            },
            {
                "value": 7780,
                "label": "Firth",
                "label_ar": "فيرث",
                "label_fr": "Firth"
            },
            {
                "value": 7781,
                "label": "Fischer",
                "label_ar": "فيشر",
                "label_fr": "Fischer"
            },
            {
                "value": 7782,
                "label": "Fish Camp",
                "label_ar": "مخيم الأسماك",
                "label_fr": "Camp de poissons"
            },
            {
                "value": 7783,
                "label": "Fish Creek",
                "label_ar": "فيش كريك",
                "label_fr": "Fish Creek"
            },
            {
                "value": 7784,
                "label": "Fish Haven",
                "label_ar": "هافن السمك",
                "label_fr": "Fish Haven"
            },
            {
                "value": 7785,
                "label": "Fishel",
                "label_ar": "فيشل",
                "label_fr": "Fishel"
            },
            {
                "value": 7786,
                "label": "Fisher",
                "label_ar": "فيشر",
                "label_fr": "Pêcheur"
            },
            {
                "value": 7787,
                "label": "Fishers",
                "label_ar": "فيشرز",
                "label_fr": "Les pêcheurs"
            },
            {
                "value": 7788,
                "label": "Fishers Island",
                "label_ar": "جزيرة فيشرز",
                "label_fr": "Île Fishers"
            },
            {
                "value": 7789,
                "label": "Fishersville",
                "label_ar": "فيشرزفيل",
                "label_fr": "Fishersville"
            },
            {
                "value": 7790,
                "label": "Fisherville",
                "label_ar": "فيشرفيل",
                "label_fr": "Fisherville"
            },
            {
                "value": 7791,
                "label": "Fishkill",
                "label_ar": "فيشكيل",
                "label_fr": "Fishkill"
            },
            {
                "value": 7792,
                "label": "Fishtail",
                "label_ar": "ذيل السمكة",
                "label_fr": "Queue de poisson"
            },
            {
                "value": 7793,
                "label": "Fisk",
                "label_ar": "فيسك",
                "label_fr": "Fisk"
            },
            {
                "value": 7794,
                "label": "Fiskdale",
                "label_ar": "فيسكديل",
                "label_fr": "Fiskdale"
            },
            {
                "value": 7795,
                "label": "Fitchburg",
                "label_ar": "فيتشبرج",
                "label_fr": "Fitchburg"
            },
            {
                "value": 7796,
                "label": "Fitzgerald",
                "label_ar": "فيتزجيرالد",
                "label_fr": "Fitzgerald"
            },
            {
                "value": 7797,
                "label": "Fitzhugh",
                "label_ar": "فيتزهوغ",
                "label_fr": "Fitzhugh"
            },
            {
                "value": 7798,
                "label": "Fitzpatrick",
                "label_ar": "فيتزباتريك",
                "label_fr": "Fitzpatrick"
            },
            {
                "value": 7799,
                "label": "Fitzwilliam",
                "label_ar": "فيتزويليام",
                "label_fr": "Fitzwilliam"
            },
            {
                "value": 7800,
                "label": "Five Points",
                "label_ar": "خمس نقاط",
                "label_fr": "Cinq points"
            },
            {
                "value": 7801,
                "label": "Flag Pond",
                "label_ar": "علم البركة",
                "label_fr": "Flag Pond"
            },
            {
                "value": 7802,
                "label": "Flagler",
                "label_ar": "فلاجلر",
                "label_fr": "Flagler"
            },
            {
                "value": 7803,
                "label": "Flagler Beach",
                "label_ar": "شاطئ فلاجلر",
                "label_fr": "Plage de Flagler"
            },
            {
                "value": 7804,
                "label": "Flagstaff",
                "label_ar": "سارية العلم",
                "label_fr": "Flagstaff"
            },
            {
                "value": 7805,
                "label": "Flanagan",
                "label_ar": "فلاناغان",
                "label_fr": "Flanagan"
            },
            {
                "value": 7806,
                "label": "Flanders",
                "label_ar": "فلاندرز",
                "label_fr": "Flandre"
            },
            {
                "value": 7807,
                "label": "Flandreau",
                "label_ar": "فلاندرو",
                "label_fr": "Flandreau"
            },
            {
                "value": 7808,
                "label": "Flasher",
                "label_ar": "المتعري",
                "label_fr": "Flasher"
            },
            {
                "value": 7809,
                "label": "Flat Lick",
                "label_ar": "لعق مسطح",
                "label_fr": "Plat lécher"
            },
            {
                "value": 7810,
                "label": "Flat Rock",
                "label_ar": "صخرة مسطحة",
                "label_fr": "Rock plat"
            },
            {
                "value": 7811,
                "label": "Flatgap",
                "label_ar": "فلاتجاب",
                "label_fr": "Flatgap"
            },
            {
                "value": 7812,
                "label": "Flatwoods",
                "label_ar": "فلاتوودز",
                "label_fr": "Flatwoods"
            },
            {
                "value": 7813,
                "label": "Flaxton",
                "label_ar": "فلاكستون",
                "label_fr": "Flaxton"
            },
            {
                "value": 7814,
                "label": "Flaxville",
                "label_ar": "فلاكسفيل",
                "label_fr": "Flaxville"
            },
            {
                "value": 7815,
                "label": "Fleetwood",
                "label_ar": "فليتوود",
                "label_fr": "Fleetwood"
            },
            {
                "value": 7816,
                "label": "Fleischmanns",
                "label_ar": "فليشمانس",
                "label_fr": "Fleischmanns"
            },
            {
                "value": 7817,
                "label": "Fleming",
                "label_ar": "فليمنغ",
                "label_fr": "Flamand"
            },
            {
                "value": 7818,
                "label": "Flemingsburg",
                "label_ar": "فليمنجسبورج",
                "label_fr": "Flemingsburg"
            },
            {
                "value": 7819,
                "label": "Flemington",
                "label_ar": "فليمنجتون",
                "label_fr": "Flemington"
            },
            {
                "value": 7820,
                "label": "Flensburg",
                "label_ar": "فلنسبورغ",
                "label_fr": "Flensburg"
            },
            {
                "value": 7821,
                "label": "Fletcher",
                "label_ar": "فليتشر",
                "label_fr": "Fletcher"
            },
            {
                "value": 7822,
                "label": "Flint",
                "label_ar": "فلينت",
                "label_fr": "Silex"
            },
            {
                "value": 7823,
                "label": "Flinton",
                "label_ar": "فلينتون",
                "label_fr": "Flinton"
            },
            {
                "value": 7824,
                "label": "Flintstone",
                "label_ar": "فلينتستون",
                "label_fr": "Flintstone"
            },
            {
                "value": 7825,
                "label": "Flintville",
                "label_ar": "فلينتفيل",
                "label_fr": "Flintville"
            },
            {
                "value": 7826,
                "label": "Flippin",
                "label_ar": "فليبين",
                "label_fr": "Flippin"
            },
            {
                "value": 7827,
                "label": "Flomaton",
                "label_ar": "فلوماتون",
                "label_fr": "Flomaton"
            },
            {
                "value": 7828,
                "label": "Floodwood",
                "label_ar": "فلود وود",
                "label_fr": "Floodwood"
            },
            {
                "value": 7829,
                "label": "Flora",
                "label_ar": "النباتية",
                "label_fr": "Flore"
            },
            {
                "value": 7830,
                "label": "Flora Vista",
                "label_ar": "فلورا فيستا",
                "label_fr": "Flora Vista"
            },
            {
                "value": 7831,
                "label": "Florahome",
                "label_ar": "فلوراهوم",
                "label_fr": "Florahome"
            },
            {
                "value": 7832,
                "label": "Floral City",
                "label_ar": "مدينة الزهور",
                "label_fr": "Ville florale"
            },
            {
                "value": 7833,
                "label": "Floral Park",
                "label_ar": "حديقة الزهور",
                "label_fr": "Parc floral"
            },
            {
                "value": 7834,
                "label": "Florala",
                "label_ar": "فلورالا",
                "label_fr": "Florala"
            },
            {
                "value": 7835,
                "label": "Florence",
                "label_ar": "فلورنسا",
                "label_fr": "Florence"
            },
            {
                "value": 7836,
                "label": "Floresville",
                "label_ar": "فلوريسفيل",
                "label_fr": "Floresville"
            },
            {
                "value": 7837,
                "label": "Florham Park",
                "label_ar": "حديقة فلورهام",
                "label_fr": "Parc Florham"
            },
            {
                "value": 7838,
                "label": "Florida",
                "label_ar": "فلوريدا",
                "label_fr": "Floride"
            },
            {
                "value": 7839,
                "label": "Florien",
                "label_ar": "فلوريان",
                "label_fr": "Florien"
            },
            {
                "value": 7840,
                "label": "Florissant",
                "label_ar": "فلوريسانت",
                "label_fr": "Florissant"
            },
            {
                "value": 7841,
                "label": "Flossmoor",
                "label_ar": "فلوسمور",
                "label_fr": "Flossmoor"
            },
            {
                "value": 7842,
                "label": "Flourtown",
                "label_ar": "فلورتاون",
                "label_fr": "Flourtown"
            },
            {
                "value": 7843,
                "label": "Flovilla",
                "label_ar": "فلوفيلا",
                "label_fr": "Flovilla"
            },
            {
                "value": 7844,
                "label": "Flower Mound",
                "label_ar": "كومة الزهور",
                "label_fr": "Butte de fleurs"
            },
            {
                "value": 7845,
                "label": "Floweree",
                "label_ar": "فلويري",
                "label_fr": "Floweree"
            },
            {
                "value": 7846,
                "label": "Flowery Branch",
                "label_ar": "فرع الزهور",
                "label_fr": "Branche fleurie"
            },
            {
                "value": 7847,
                "label": "Flowood",
                "label_ar": "تدفق",
                "label_fr": "Flowood"
            },
            {
                "value": 7848,
                "label": "Floyd",
                "label_ar": "فلويد",
                "label_fr": "Floyd"
            },
            {
                "value": 7849,
                "label": "Floydada",
                "label_ar": "فلويدادا",
                "label_fr": "Floydada"
            },
            {
                "value": 7850,
                "label": "Floyds Knobs",
                "label_ar": "مقابض فلويد",
                "label_fr": "Boutons Floyds"
            },
            {
                "value": 7851,
                "label": "Fluker",
                "label_ar": "فلوكر",
                "label_fr": "Fluker"
            },
            {
                "value": 7852,
                "label": "Flushing",
                "label_ar": "تدفق مائى - صرف",
                "label_fr": "Rinçage"
            },
            {
                "value": 7853,
                "label": "Fly Creek",
                "label_ar": "فلاي كريك",
                "label_fr": "Fly Creek"
            },
            {
                "value": 7854,
                "label": "Fogelsville",
                "label_ar": "فوغلسفيل",
                "label_fr": "Fogelsville"
            },
            {
                "value": 7855,
                "label": "Folcroft",
                "label_ar": "فولكروفت",
                "label_fr": "Folcroft"
            },
            {
                "value": 7856,
                "label": "Foley",
                "label_ar": "فولي",
                "label_fr": "Foley"
            },
            {
                "value": 7857,
                "label": "Folkston",
                "label_ar": "فولكستون",
                "label_fr": "Folkston"
            },
            {
                "value": 7858,
                "label": "Follansbee",
                "label_ar": "فولانسبي",
                "label_fr": "Follansbee"
            },
            {
                "value": 7859,
                "label": "Follett",
                "label_ar": "فوليت",
                "label_fr": "Follett"
            },
            {
                "value": 7860,
                "label": "Folly Beach",
                "label_ar": "فولي بيتش",
                "label_fr": "Plage de la folie"
            },
            {
                "value": 7861,
                "label": "Folsom",
                "label_ar": "فولسوم",
                "label_fr": "Folsom"
            },
            {
                "value": 7862,
                "label": "Fombell",
                "label_ar": "فومبل",
                "label_fr": "Fombell"
            },
            {
                "value": 7863,
                "label": "Fond du Lac",
                "label_ar": "فوند دو لاك",
                "label_fr": "Fond du Lac"
            },
            {
                "value": 7864,
                "label": "Fonda",
                "label_ar": "فوندا",
                "label_fr": "Fonda"
            },
            {
                "value": 7865,
                "label": "Fontana",
                "label_ar": "فونتانا",
                "label_fr": "Fontana"
            },
            {
                "value": 7866,
                "label": "Fontanelle",
                "label_ar": "فونتانيلي",
                "label_fr": "Fontanelle"
            },
            {
                "value": 7867,
                "label": "Foothill Ranch",
                "label_ar": "Foothill Ranch",
                "label_fr": "Ranch de Foothill"
            },
            {
                "value": 7868,
                "label": "Forbes",
                "label_ar": "فوربس",
                "label_fr": "Forbes"
            },
            {
                "value": 7869,
                "label": "Forbes Road",
                "label_ar": "طريق فوربس",
                "label_fr": "Chemin Forbes"
            },
            {
                "value": 7870,
                "label": "Ford",
                "label_ar": "معقل",
                "label_fr": "Gué"
            },
            {
                "value": 7871,
                "label": "Ford City",
                "label_ar": "مدينة فورد",
                "label_fr": "Ford City"
            },
            {
                "value": 7872,
                "label": "Fordland",
                "label_ar": "فوردلاند",
                "label_fr": "Fordland"
            },
            {
                "value": 7873,
                "label": "Fordoche",
                "label_ar": "فوردوش",
                "label_fr": "Fordoche"
            },
            {
                "value": 7874,
                "label": "Fords",
                "label_ar": "فورد",
                "label_fr": "Ford"
            },
            {
                "value": 7875,
                "label": "Fordsville",
                "label_ar": "فوردسفيل",
                "label_fr": "Fordsville"
            },
            {
                "value": 7876,
                "label": "Fordville",
                "label_ar": "فوردفيل",
                "label_fr": "Fordville"
            },
            {
                "value": 7877,
                "label": "Fordyce",
                "label_ar": "فورديس",
                "label_fr": "Fordyce"
            },
            {
                "value": 7878,
                "label": "Foreman",
                "label_ar": "مراقب عمال",
                "label_fr": "Contremaître"
            },
            {
                "value": 7879,
                "label": "Forest",
                "label_ar": "غابة",
                "label_fr": "Forêt"
            },
            {
                "value": 7880,
                "label": "Forest City",
                "label_ar": "فورست سيتي",
                "label_fr": "Ville forestière"
            },
            {
                "value": 7881,
                "label": "Forest Falls",
                "label_ar": "شلالات الغابة",
                "label_fr": "Forest Falls"
            },
            {
                "value": 7882,
                "label": "Forest Grove",
                "label_ar": "فورست جروف",
                "label_fr": "Forest Grove"
            },
            {
                "value": 7883,
                "label": "Forest Hill",
                "label_ar": "تلة الغابة",
                "label_fr": "Forest Hill"
            },
            {
                "value": 7884,
                "label": "Forest Hills",
                "label_ar": "تلال الغابات",
                "label_fr": "collines boisées"
            },
            {
                "value": 7885,
                "label": "Forest Home",
                "label_ar": "فورست هوم",
                "label_fr": "Maison de la forêt"
            },
            {
                "value": 7886,
                "label": "Forest Junction",
                "label_ar": "تقاطع الغابة",
                "label_fr": "Jonction forestière"
            },
            {
                "value": 7887,
                "label": "Forest Knolls",
                "label_ar": "نول الغابة",
                "label_fr": "Montagnes forestières"
            },
            {
                "value": 7888,
                "label": "Forest Lake",
                "label_ar": "بحيرة الغابة",
                "label_fr": "Forest Lake"
            },
            {
                "value": 7889,
                "label": "Forest Park",
                "label_ar": "حديقة غابات",
                "label_fr": "Parc forestier"
            },
            {
                "value": 7890,
                "label": "Forest River",
                "label_ar": "نهر الغابة",
                "label_fr": "Rivière Forest"
            },
            {
                "value": 7891,
                "label": "Forestdale",
                "label_ar": "فورستديل",
                "label_fr": "Forestdale"
            },
            {
                "value": 7892,
                "label": "Foresthill",
                "label_ar": "تلة الغابة",
                "label_fr": "Foresthill"
            },
            {
                "value": 7893,
                "label": "Foreston",
                "label_ar": "فورستون",
                "label_fr": "Foreston"
            },
            {
                "value": 7894,
                "label": "Forestport",
                "label_ar": "فورستبورت",
                "label_fr": "Forestport"
            },
            {
                "value": 7895,
                "label": "Forestville",
                "label_ar": "فورستفيل",
                "label_fr": "Forestville"
            },
            {
                "value": 7896,
                "label": "Forgan",
                "label_ar": "فورجان",
                "label_fr": "Forgan"
            },
            {
                "value": 7897,
                "label": "Foristell",
                "label_ar": "فوريستل",
                "label_fr": "Foristell"
            },
            {
                "value": 7898,
                "label": "Fork Union",
                "label_ar": "اتحاد الشوكة",
                "label_fr": "Union de fourche"
            },
            {
                "value": 7899,
                "label": "Forked River",
                "label_ar": "نهر متفرع",
                "label_fr": "Rivière fourchue"
            },
            {
                "value": 7900,
                "label": "Forkland",
                "label_ar": "فوركلاند",
                "label_fr": "Forkland"
            },
            {
                "value": 7901,
                "label": "Forks",
                "label_ar": "شوك",
                "label_fr": "Fourches"
            },
            {
                "value": 7902,
                "label": "Forksville",
                "label_ar": "فوركسفيل",
                "label_fr": "Forksville"
            },
            {
                "value": 7903,
                "label": "Forman",
                "label_ar": "فورمان",
                "label_fr": "Pour homme"
            },
            {
                "value": 7904,
                "label": "Formoso",
                "label_ar": "فورموسو",
                "label_fr": "Formoso"
            },
            {
                "value": 7905,
                "label": "Forney",
                "label_ar": "فورني",
                "label_fr": "Forney"
            },
            {
                "value": 7906,
                "label": "Forrest",
                "label_ar": "فورست",
                "label_fr": "Forrest"
            },
            {
                "value": 7907,
                "label": "Forrest City",
                "label_ar": "فورست سيتي",
                "label_fr": "Forrest City"
            },
            {
                "value": 7908,
                "label": "Forreston",
                "label_ar": "فورستون",
                "label_fr": "Forreston"
            },
            {
                "value": 7909,
                "label": "Forsyth",
                "label_ar": "فورسيث",
                "label_fr": "Forsyth"
            },
            {
                "value": 7910,
                "label": "Fort Ann",
                "label_ar": "فورت آن",
                "label_fr": "Fort Ann"
            },
            {
                "value": 7911,
                "label": "Fort Apache",
                "label_ar": "فورت أباتشي",
                "label_fr": "Fort Apache"
            },
            {
                "value": 7912,
                "label": "Fort Ashby",
                "label_ar": "حصن أشبي",
                "label_fr": "Fort Ashby"
            },
            {
                "value": 7913,
                "label": "Fort Atkinson",
                "label_ar": "فورت أتكينسون",
                "label_fr": "Fort Atkinson"
            },
            {
                "value": 7914,
                "label": "Fort Belvoir",
                "label_ar": "حصن بلفوار",
                "label_fr": "Fort Belvoir"
            },
            {
                "value": 7915,
                "label": "Fort Benning",
                "label_ar": "فورت بينينج",
                "label_fr": "Fort Benning"
            },
            {
                "value": 7916,
                "label": "Fort Benton",
                "label_ar": "فورت بينتون",
                "label_fr": "Fort Benton"
            },
            {
                "value": 7917,
                "label": "Fort Blackmore",
                "label_ar": "فورت بلاكمور",
                "label_fr": "Fort Blackmore"
            },
            {
                "value": 7918,
                "label": "Fort Bragg",
                "label_ar": "فورت براج",
                "label_fr": "Fort Bragg"
            },
            {
                "value": 7919,
                "label": "Fort Branch",
                "label_ar": "فرع فورت",
                "label_fr": "Branche de Fort"
            },
            {
                "value": 7920,
                "label": "Fort Bridger",
                "label_ar": "فورت بريدجر",
                "label_fr": "Fort Bridger"
            },
            {
                "value": 7921,
                "label": "Fort Calhoun",
                "label_ar": "حصن كالهون",
                "label_fr": "Fort Calhoun"
            },
            {
                "value": 7922,
                "label": "Fort Campbell North",
                "label_ar": "فورت كامبل نورث",
                "label_fr": "Fort Campbell Nord"
            },
            {
                "value": 7923,
                "label": "Fort Cobb",
                "label_ar": "فورت كوب",
                "label_fr": "Fort Cobb"
            },
            {
                "value": 7924,
                "label": "Fort Collins",
                "label_ar": "فورت كولينز",
                "label_fr": "Fort Collins"
            },
            {
                "value": 7925,
                "label": "Fort Davis",
                "label_ar": "فورت ديفيس",
                "label_fr": "Fort Davis"
            },
            {
                "value": 7926,
                "label": "Fort Defiance",
                "label_ar": "فورت التحدي",
                "label_fr": "Fort Defiance"
            },
            {
                "value": 7927,
                "label": "Fort Deposit",
                "label_ar": "إيداع فورت",
                "label_fr": "Gisement de Fort"
            },
            {
                "value": 7928,
                "label": "Fort Dodge",
                "label_ar": "فورت دودج",
                "label_fr": "Fort Dodge"
            },
            {
                "value": 7929,
                "label": "Fort Drum",
                "label_ar": "فورت درام",
                "label_fr": "Fort Drum"
            },
            {
                "value": 7930,
                "label": "Fort Duchesne",
                "label_ar": "فورت دوتشيسن",
                "label_fr": "Fort Duchesne"
            },
            {
                "value": 7931,
                "label": "Fort Edward",
                "label_ar": "حصن إدوارد",
                "label_fr": "Fort Edward"
            },
            {
                "value": 7932,
                "label": "Fort Fairfield",
                "label_ar": "فورت فيرفيلد",
                "label_fr": "Fort Fairfield"
            },
            {
                "value": 7933,
                "label": "Fort Gaines",
                "label_ar": "فورت جاينز",
                "label_fr": "Fort Gaines"
            },
            {
                "value": 7934,
                "label": "Fort Garland",
                "label_ar": "فورت جارلاند",
                "label_fr": "Fort Garland"
            },
            {
                "value": 7935,
                "label": "Fort Gay",
                "label_ar": "فورت جاي",
                "label_fr": "Fort Gay"
            },
            {
                "value": 7936,
                "label": "Fort George Meade",
                "label_ar": "فورت جورج ميد",
                "label_fr": "Fort George Meade"
            },
            {
                "value": 7937,
                "label": "Fort Gibson",
                "label_ar": "فورت جيبسون",
                "label_fr": "Fort Gibson"
            },
            {
                "value": 7938,
                "label": "Fort Gratiot Township",
                "label_ar": "بلدة فورت جراتيوت",
                "label_fr": "Canton de Fort Gratiot"
            },
            {
                "value": 7939,
                "label": "Fort Greely",
                "label_ar": "حصن غريلي",
                "label_fr": "Fort Greely"
            },
            {
                "value": 7940,
                "label": "Fort Hall",
                "label_ar": "فورت هول",
                "label_fr": "Fort Hall"
            },
            {
                "value": 7941,
                "label": "Fort Irwin",
                "label_ar": "فورت ايروين",
                "label_fr": "Fort Irwin"
            },
            {
                "value": 7942,
                "label": "Fort Jennings",
                "label_ar": "فورت جينينغز",
                "label_fr": "Fort Jennings"
            },
            {
                "value": 7943,
                "label": "Fort Jones",
                "label_ar": "فورت جونز",
                "label_fr": "Fort Jones"
            },
            {
                "value": 7944,
                "label": "Fort Kent",
                "label_ar": "فورت كينت",
                "label_fr": "Fort Kent"
            },
            {
                "value": 7945,
                "label": "Fort Knox",
                "label_ar": "فورت نوكس",
                "label_fr": "fort Knox"
            },
            {
                "value": 7946,
                "label": "Fort Lauderdale",
                "label_ar": "ثكنة لودرديل",
                "label_fr": "Fort Lauderdale"
            },
            {
                "value": 7947,
                "label": "Fort Lee",
                "label_ar": "فورت لي",
                "label_fr": "Fort Lee"
            },
            {
                "value": 7948,
                "label": "Fort Leonard Wood",
                "label_ar": "فورت ليونارد وود",
                "label_fr": "Bois de Fort Leonard"
            },
            {
                "value": 7949,
                "label": "Fort Loramie",
                "label_ar": "فورت لورامي",
                "label_fr": "Fort Loramie"
            },
            {
                "value": 7950,
                "label": "Fort Loudon",
                "label_ar": "فورت لودون",
                "label_fr": "Fort Loudon"
            },
            {
                "value": 7951,
                "label": "Fort Lupton",
                "label_ar": "فورت لوبتون",
                "label_fr": "Fort Lupton"
            },
            {
                "value": 7952,
                "label": "Fort Madison",
                "label_ar": "فورت ماديسون",
                "label_fr": "Fort Madison"
            },
            {
                "value": 7953,
                "label": "Fort McCoy",
                "label_ar": "فورت ماكوي",
                "label_fr": "Fort McCoy"
            },
            {
                "value": 7954,
                "label": "Fort McDowell",
                "label_ar": "فورت ماكدويل",
                "label_fr": "Fort McDowell"
            },
            {
                "value": 7955,
                "label": "Fort Meade",
                "label_ar": "فورت ميد",
                "label_fr": "Fort Meade"
            },
            {
                "value": 7956,
                "label": "Fort Mill",
                "label_ar": "فورت ميل",
                "label_fr": "Moulin de Fort"
            },
            {
                "value": 7957,
                "label": "Fort Mitchell",
                "label_ar": "فورت ميتشل",
                "label_fr": "Fort Mitchell"
            },
            {
                "value": 7958,
                "label": "Fort Montgomery",
                "label_ar": "حصن مونتغمري",
                "label_fr": "Fort Montgomery"
            },
            {
                "value": 7959,
                "label": "Fort Morgan",
                "label_ar": "فورت مورغان",
                "label_fr": "Fort Morgan"
            },
            {
                "value": 7960,
                "label": "Fort Myers",
                "label_ar": "فورت مايرز",
                "label_fr": "Fort Myers"
            },
            {
                "value": 7961,
                "label": "Fort Myers Beach",
                "label_ar": "شاطئ فورت مايرز",
                "label_fr": "Plage de Fort Myers"
            },
            {
                "value": 7962,
                "label": "Fort Oglethorpe",
                "label_ar": "حصن أوغليثورب",
                "label_fr": "Fort Oglethorpe"
            },
            {
                "value": 7963,
                "label": "Fort Payne",
                "label_ar": "فورت باين",
                "label_fr": "Fort Payne"
            },
            {
                "value": 7964,
                "label": "Fort Pierre",
                "label_ar": "فورت بيير",
                "label_fr": "Fort Pierre"
            },
            {
                "value": 7965,
                "label": "Fort Plain",
                "label_ar": "فورت سهل",
                "label_fr": "Fort Plain"
            },
            {
                "value": 7966,
                "label": "Fort Polk",
                "label_ar": "فورت بولك",
                "label_fr": "Fort Polk"
            },
            {
                "value": 7967,
                "label": "Fort Ransom",
                "label_ar": "فورت رانسوم",
                "label_fr": "Fort Ransom"
            },
            {
                "value": 7968,
                "label": "Fort Recovery",
                "label_ar": "فورت ريكفري",
                "label_fr": "Récupération du fort"
            },
            {
                "value": 7969,
                "label": "Fort Richardson",
                "label_ar": "فورت ريتشاردسون",
                "label_fr": "Fort Richardson"
            },
            {
                "value": 7970,
                "label": "Fort Riley-Camp Whiteside",
                "label_ar": "فورت رايلي كامب وايتسايد",
                "label_fr": "Fort Riley-Camp Whiteside"
            },
            {
                "value": 7971,
                "label": "Fort Ripley",
                "label_ar": "فورت ريبلي",
                "label_fr": "Fort Ripley"
            },
            {
                "value": 7972,
                "label": "Fort Rucker",
                "label_ar": "فورت روكر",
                "label_fr": "Fort Rucker"
            },
            {
                "value": 7973,
                "label": "Fort Scott",
                "label_ar": "فورت سكوت",
                "label_fr": "Fort Scott"
            },
            {
                "value": 7974,
                "label": "Fort Sill Indian School Reservation (historical)",
                "label_ar": "حجز مدرسة فورت سيل الهندية (تاريخي)",
                "label_fr": "Réserve de l'école indienne de Fort Sill (historique)"
            },
            {
                "value": 7975,
                "label": "Fort Smith",
                "label_ar": "فورت سميث",
                "label_fr": "Fort Smith"
            },
            {
                "value": 7976,
                "label": "Fort Stewart",
                "label_ar": "فورت ستيوارت",
                "label_fr": "Fort Stewart"
            },
            {
                "value": 7977,
                "label": "Fort Stockton",
                "label_ar": "فورت ستوكتون",
                "label_fr": "Fort Stockton"
            },
            {
                "value": 7978,
                "label": "Fort Sumner",
                "label_ar": "فورت سمنر",
                "label_fr": "Fort Sumner"
            },
            {
                "value": 7979,
                "label": "Fort Thomas",
                "label_ar": "فورت توماس",
                "label_fr": "Fort Thomas"
            },
            {
                "value": 7980,
                "label": "Fort Thompson",
                "label_ar": "فورت طومسون",
                "label_fr": "Fort Thompson"
            },
            {
                "value": 7981,
                "label": "Fort Totten",
                "label_ar": "فورت توتن",
                "label_fr": "Fort Totten"
            },
            {
                "value": 7982,
                "label": "Fort Valley",
                "label_ar": "فورت فالي",
                "label_fr": "Fort Valley"
            },
            {
                "value": 7983,
                "label": "Fort Walton Beach",
                "label_ar": "شاطئ فورت والتون",
                "label_fr": "Plage de Fort Walton"
            },
            {
                "value": 7984,
                "label": "Fort Washakie",
                "label_ar": "حصن الوشكي",
                "label_fr": "Fort Washakie"
            },
            {
                "value": 7985,
                "label": "Fort Washington",
                "label_ar": "فورت واشنطن",
                "label_fr": "Fort Washington"
            },
            {
                "value": 7986,
                "label": "Fort Wayne",
                "label_ar": "فورت واين",
                "label_fr": "Fort Wayne"
            },
            {
                "value": 7987,
                "label": "Fort White",
                "label_ar": "فورت وايت",
                "label_fr": "Fort White"
            },
            {
                "value": 7988,
                "label": "Fort Worth",
                "label_ar": "فورت وورث",
                "label_fr": "Fort Worth"
            },
            {
                "value": 7989,
                "label": "Fort Yates",
                "label_ar": "فورت ييتس",
                "label_fr": "Fort Yates"
            },
            {
                "value": 7990,
                "label": "Fortine",
                "label_ar": "فورتين",
                "label_fr": "Fortine"
            },
            {
                "value": 7991,
                "label": "Fortson",
                "label_ar": "فورتسون",
                "label_fr": "Fortson"
            },
            {
                "value": 7992,
                "label": "Fortuna",
                "label_ar": "فورتونا",
                "label_fr": "Fortuna"
            },
            {
                "value": 7993,
                "label": "Fortville",
                "label_ar": "فورتفيل",
                "label_fr": "Fortville"
            },
            {
                "value": 7994,
                "label": "Foss",
                "label_ar": "فوس",
                "label_fr": "Foss"
            },
            {
                "value": 7995,
                "label": "Fossil",
                "label_ar": "حفرية",
                "label_fr": "Fossile"
            },
            {
                "value": 7996,
                "label": "Fosston",
                "label_ar": "فوسستون",
                "label_fr": "Fosston"
            },
            {
                "value": 7997,
                "label": "Foster",
                "label_ar": "الحاضنة",
                "label_fr": "Favoriser"
            },
            {
                "value": 7998,
                "label": "Foster City",
                "label_ar": "فوستر سيتي",
                "label_fr": "Foster City"
            },
            {
                "value": 7999,
                "label": "Fosters",
                "label_ar": "تعزز",
                "label_fr": "Favorise"
            },
            {
                "value": 8000,
                "label": "Fostoria",
                "label_ar": "فوستوريا",
                "label_fr": "Fostoria"
            },
            {
                "value": 8001,
                "label": "Fouke",
                "label_ar": "فوك",
                "label_fr": "Fouke"
            },
            {
                "value": 8002,
                "label": "Fountain",
                "label_ar": "نافورة",
                "label_fr": "Fontaine"
            },
            {
                "value": 8003,
                "label": "Fountain City",
                "label_ar": "مدينة النافورة",
                "label_fr": "Ville de la fontaine"
            },
            {
                "value": 8004,
                "label": "Fountain Green",
                "label_ar": "نافورة خضراء",
                "label_fr": "Vert fontaine"
            },
            {
                "value": 8005,
                "label": "Fountain Hills",
                "label_ar": "تلال النافورة",
                "label_fr": "Collines de la fontaine"
            },
            {
                "value": 8006,
                "label": "Fountain Inn",
                "label_ar": "نزل النافورة",
                "label_fr": "Auberge de la fontaine"
            },
            {
                "value": 8007,
                "label": "Fountain Run",
                "label_ar": "تشغيل النافورة",
                "label_fr": "Course de fontaine"
            },
            {
                "value": 8008,
                "label": "Fountain Valley",
                "label_ar": "فاونتن فالي",
                "label_fr": "Vallée de la fontaine"
            },
            {
                "value": 8009,
                "label": "Fountaintown",
                "label_ar": "نافورة",
                "label_fr": "Fountaintown"
            },
            {
                "value": 8010,
                "label": "Fountainville",
                "label_ar": "فاونتنفيل",
                "label_fr": "Fountainville"
            },
            {
                "value": 8011,
                "label": "Four Oaks",
                "label_ar": "أربعة أوكس",
                "label_fr": "Quatre chênes"
            },
            {
                "value": 8012,
                "label": "Fowler",
                "label_ar": "الصياد",
                "label_fr": "Chasseur"
            },
            {
                "value": 8013,
                "label": "Fowlerville",
                "label_ar": "فاولرفيل",
                "label_fr": "Fowlerville"
            },
            {
                "value": 8014,
                "label": "Fox Island",
                "label_ar": "جزيرة فوكس",
                "label_fr": "Île Fox"
            },
            {
                "value": 8015,
                "label": "Fox Lake",
                "label_ar": "بحيرة فوكس",
                "label_fr": "Fox Lake"
            },
            {
                "value": 8016,
                "label": "Fox River Grove",
                "label_ar": "فوكس ريفر جروف",
                "label_fr": "Fox River Grove"
            },
            {
                "value": 8017,
                "label": "Foxborough",
                "label_ar": "فوكسبورو",
                "label_fr": "Foxborough"
            },
            {
                "value": 8018,
                "label": "Foxburg",
                "label_ar": "فوكسبورغ",
                "label_fr": "Foxburg"
            },
            {
                "value": 8019,
                "label": "Foxhome",
                "label_ar": "فوكسهوم",
                "label_fr": "Foxhome"
            },
            {
                "value": 8020,
                "label": "Foxworth",
                "label_ar": "فوكسوورث",
                "label_fr": "Foxworth"
            },
            {
                "value": 8021,
                "label": "Frackville",
                "label_ar": "فراكفيل",
                "label_fr": "Frackville"
            },
            {
                "value": 8022,
                "label": "Framingham",
                "label_ar": "فرامنغهام",
                "label_fr": "Framingham"
            },
            {
                "value": 8023,
                "label": "Francestown",
                "label_ar": "فرانسيستاون",
                "label_fr": "Francestown"
            },
            {
                "value": 8024,
                "label": "Francesville",
                "label_ar": "فرانسيسفيل",
                "label_fr": "Francesville"
            },
            {
                "value": 8025,
                "label": "Francisco",
                "label_ar": "فرانسيسكو",
                "label_fr": "Francisco"
            },
            {
                "value": 8026,
                "label": "Franconia",
                "label_ar": "فرانكونيا",
                "label_fr": "Franconie"
            },
            {
                "value": 8027,
                "label": "Frankenmuth",
                "label_ar": "فرانكنموث",
                "label_fr": "Frankenmuth"
            },
            {
                "value": 8028,
                "label": "Frankewing",
                "label_ar": "فرانكفنج",
                "label_fr": "Frankewing"
            },
            {
                "value": 8029,
                "label": "Frankford",
                "label_ar": "فرانكفورد",
                "label_fr": "Frankford"
            },
            {
                "value": 8030,
                "label": "Frankfort",
                "label_ar": "فرانكفورت",
                "label_fr": "Francfort"
            },
            {
                "value": 8031,
                "label": "Franklin",
                "label_ar": "فرانكلين",
                "label_fr": "Franklin"
            },
            {
                "value": 8032,
                "label": "Franklin Grove",
                "label_ar": "فرانكلين جروف",
                "label_fr": "Franklin Grove"
            },
            {
                "value": 8033,
                "label": "Franklin Lakes",
                "label_ar": "فرانكلين ليكس",
                "label_fr": "Franklin Lakes"
            },
            {
                "value": 8034,
                "label": "Franklin Park",
                "label_ar": "فرانكلين بارك",
                "label_fr": "Franklin Park"
            },
            {
                "value": 8035,
                "label": "Franklin Springs",
                "label_ar": "فرانكلين سبرينغز",
                "label_fr": "Franklin Springs"
            },
            {
                "value": 8036,
                "label": "Franklin Square",
                "label_ar": "ميدان فرانكلين",
                "label_fr": "Franklin Square"
            },
            {
                "value": 8037,
                "label": "Franklinton",
                "label_ar": "فرانكلينتون",
                "label_fr": "Franklinton"
            },
            {
                "value": 8038,
                "label": "Franklinville",
                "label_ar": "فرانكلينفيل",
                "label_fr": "Franklinville"
            },
            {
                "value": 8039,
                "label": "Frankston",
                "label_ar": "فرانكستون",
                "label_fr": "Frankston"
            },
            {
                "value": 8040,
                "label": "Franksville",
                "label_ar": "فرانكسفيل",
                "label_fr": "Franksville"
            },
            {
                "value": 8041,
                "label": "Frankton",
                "label_ar": "فرانكتون",
                "label_fr": "Frankton"
            },
            {
                "value": 8042,
                "label": "Franktown",
                "label_ar": "فرانكتاون",
                "label_fr": "Franktown"
            },
            {
                "value": 8043,
                "label": "Frannie",
                "label_ar": "فراني",
                "label_fr": "Frannie"
            },
            {
                "value": 8044,
                "label": "Fraser",
                "label_ar": "فريزر",
                "label_fr": "Fraser"
            },
            {
                "value": 8045,
                "label": "Frazee",
                "label_ar": "فرازي",
                "label_fr": "Frazee"
            },
            {
                "value": 8046,
                "label": "Frazer",
                "label_ar": "فريزر",
                "label_fr": "Frazer"
            },
            {
                "value": 8047,
                "label": "Frazeysburg",
                "label_ar": "فرازيبرغ",
                "label_fr": "Frazeysburg"
            },
            {
                "value": 8048,
                "label": "Frazier Park",
                "label_ar": "فريزر بارك",
                "label_fr": "Parc Frazier"
            },
            {
                "value": 8049,
                "label": "Fraziers Bottom",
                "label_ar": "Fraziers القاع",
                "label_fr": "Bas de Fraziers"
            },
            {
                "value": 8050,
                "label": "Fred",
                "label_ar": "فريد",
                "label_fr": "Fred"
            },
            {
                "value": 8051,
                "label": "Frederic",
                "label_ar": "فريدريك",
                "label_fr": "Frédéric"
            },
            {
                "value": 8052,
                "label": "Frederica",
                "label_ar": "فريدريكا",
                "label_fr": "Frederica"
            },
            {
                "value": 8053,
                "label": "Frederick",
                "label_ar": "فريدريك",
                "label_fr": "Frédéric"
            },
            {
                "value": 8054,
                "label": "Fredericksburg",
                "label_ar": "فريدريكسبيرغ",
                "label_fr": "Fredericksburg"
            },
            {
                "value": 8055,
                "label": "Fredericktown",
                "label_ar": "فريدريكتاون",
                "label_fr": "Fredericktown"
            },
            {
                "value": 8056,
                "label": "Fredonia",
                "label_ar": "فريدونيا",
                "label_fr": "Fredonia"
            },
            {
                "value": 8057,
                "label": "Free Soil",
                "label_ar": "التربة الحرة",
                "label_fr": "Sol libre"
            },
            {
                "value": 8058,
                "label": "Free Union",
                "label_ar": "الاتحاد الحر",
                "label_fr": "Union libre"
            },
            {
                "value": 8059,
                "label": "Freeburg",
                "label_ar": "فريبورغ",
                "label_fr": "Freeburg"
            },
            {
                "value": 8060,
                "label": "Freeburn",
                "label_ar": "فريبورن",
                "label_fr": "Freeburn"
            },
            {
                "value": 8061,
                "label": "Freedom",
                "label_ar": "حرية",
                "label_fr": "Liberté"
            },
            {
                "value": 8062,
                "label": "Freehold",
                "label_ar": "التملك الحر",
                "label_fr": "Freehold"
            },
            {
                "value": 8063,
                "label": "Freehold Borough",
                "label_ar": "منطقة التملك الحر",
                "label_fr": "Arrondissement en pleine propriété"
            },
            {
                "value": 8064,
                "label": "Freeland",
                "label_ar": "ارض حرة",
                "label_fr": "Freeland"
            },
            {
                "value": 8065,
                "label": "Freeman",
                "label_ar": "رجل حر",
                "label_fr": "Homme libre"
            },
            {
                "value": 8066,
                "label": "Freeport",
                "label_ar": "ميناء حر",
                "label_fr": "Freeport"
            },
            {
                "value": 8067,
                "label": "Freer",
                "label_ar": "أكثر حرية",
                "label_fr": "Plus libre"
            },
            {
                "value": 8068,
                "label": "Freetown",
                "label_ar": "فريتاون",
                "label_fr": "Freetown"
            },
            {
                "value": 8069,
                "label": "Freeville",
                "label_ar": "فريفيل",
                "label_fr": "Freeville"
            },
            {
                "value": 8070,
                "label": "Fremont",
                "label_ar": "فريمونت",
                "label_fr": "Fremont"
            },
            {
                "value": 8071,
                "label": "French Camp",
                "label_ar": "المعسكر الفرنسي",
                "label_fr": "Camp français"
            },
            {
                "value": 8072,
                "label": "French Lick",
                "label_ar": "لعق الفرنسية",
                "label_fr": "Français lécher"
            },
            {
                "value": 8073,
                "label": "French Settlement",
                "label_ar": "التسوية الفرنسية",
                "label_fr": "Règlement français"
            },
            {
                "value": 8074,
                "label": "French Village",
                "label_ar": "القرية الفرنسية",
                "label_fr": "Village français"
            },
            {
                "value": 8075,
                "label": "Frenchburg",
                "label_ar": "فرينسبورغ",
                "label_fr": "Frenchburg"
            },
            {
                "value": 8076,
                "label": "Frenchtown",
                "label_ar": "فرينشتون",
                "label_fr": "Frenchtown"
            },
            {
                "value": 8077,
                "label": "Frenchville",
                "label_ar": "فرينشفيل",
                "label_fr": "Frenchville"
            },
            {
                "value": 8078,
                "label": "Fresh Meadows",
                "label_ar": "المروج الطازجة",
                "label_fr": "Prairies fraîches"
            },
            {
                "value": 8079,
                "label": "Fresno",
                "label_ar": "فريسنو",
                "label_fr": "Fresno"
            },
            {
                "value": 8080,
                "label": "Frewsburg",
                "label_ar": "فروسبرج",
                "label_fr": "Frewsburg"
            },
            {
                "value": 8081,
                "label": "Friant",
                "label_ar": "فريانت",
                "label_fr": "Friant"
            },
            {
                "value": 8082,
                "label": "Friars Point",
                "label_ar": "فريرز بوينت",
                "label_fr": "Friars Point"
            },
            {
                "value": 8083,
                "label": "Friday Harbor",
                "label_ar": "فرايداي هاربور",
                "label_fr": "Friday Harbor"
            },
            {
                "value": 8084,
                "label": "Friedens",
                "label_ar": "فريدينز",
                "label_fr": "Friedens"
            },
            {
                "value": 8085,
                "label": "Friend",
                "label_ar": "صديق",
                "label_fr": "Ami"
            },
            {
                "value": 8086,
                "label": "Friendly",
                "label_ar": "ودود",
                "label_fr": "Amical"
            },
            {
                "value": 8087,
                "label": "Friendship",
                "label_ar": "صداقة",
                "label_fr": "Relation amicale"
            },
            {
                "value": 8088,
                "label": "Friendsville",
                "label_ar": "فريندزفيل",
                "label_fr": "Friendsville"
            },
            {
                "value": 8089,
                "label": "Friendswood",
                "label_ar": "فريندسوود",
                "label_fr": "Friendswood"
            },
            {
                "value": 8090,
                "label": "Fries",
                "label_ar": "بطاطس مقلية",
                "label_fr": "frites"
            },
            {
                "value": 8091,
                "label": "Friona",
                "label_ar": "فريونا",
                "label_fr": "Friona"
            },
            {
                "value": 8092,
                "label": "Frisco",
                "label_ar": "فريسكو",
                "label_fr": "Frisco"
            },
            {
                "value": 8093,
                "label": "Frisco City",
                "label_ar": "فريسكو سيتي",
                "label_fr": "Ville de Frisco"
            },
            {
                "value": 8094,
                "label": "Fritch",
                "label_ar": "فريتش",
                "label_fr": "Fritch"
            },
            {
                "value": 8095,
                "label": "Frohna",
                "label_ar": "فروهنا",
                "label_fr": "Frohna"
            },
            {
                "value": 8096,
                "label": "Froid",
                "label_ar": "Froid",
                "label_fr": "Froid"
            },
            {
                "value": 8097,
                "label": "Front Royal",
                "label_ar": "أمام رويال",
                "label_fr": "Front Royal"
            },
            {
                "value": 8098,
                "label": "Frontenac",
                "label_ar": "فرونتيناك",
                "label_fr": "Frontenac"
            },
            {
                "value": 8099,
                "label": "Frost",
                "label_ar": "الصقيع",
                "label_fr": "Gel"
            },
            {
                "value": 8100,
                "label": "Frostburg",
                "label_ar": "فروستبرج",
                "label_fr": "Frostburg"
            },
            {
                "value": 8101,
                "label": "Frostproof",
                "label_ar": "صقيع",
                "label_fr": "Résistant au gel"
            },
            {
                "value": 8102,
                "label": "Fruita",
                "label_ar": "فرويتا",
                "label_fr": "Fruita"
            },
            {
                "value": 8103,
                "label": "Fruitdale",
                "label_ar": "فروتديل",
                "label_fr": "Fruitdale"
            },
            {
                "value": 8104,
                "label": "Fruitland",
                "label_ar": "فروتلاند",
                "label_fr": "Fruitland"
            },
            {
                "value": 8105,
                "label": "Fruitland Park",
                "label_ar": "حديقة فروتلاند",
                "label_fr": "Parc Fruitland"
            },
            {
                "value": 8106,
                "label": "Fruitport",
                "label_ar": "فروتبورت",
                "label_fr": "Fruitport"
            },
            {
                "value": 8107,
                "label": "Fruitvale",
                "label_ar": "فروتفيل",
                "label_fr": "Fruitvale"
            },
            {
                "value": 8108,
                "label": "Fryburg",
                "label_ar": "فريبورغ",
                "label_fr": "Fryburg"
            },
            {
                "value": 8109,
                "label": "Fryeburg",
                "label_ar": "فريبورغ",
                "label_fr": "Fryeburg"
            },
            {
                "value": 8110,
                "label": "Ft. Pierce",
                "label_ar": "قدم. بيرس",
                "label_fr": "Ft. transpercer"
            },
            {
                "value": 8111,
                "label": "Ft. Washington",
                "label_ar": "قدم. واشنطن",
                "label_fr": "Ft. Washington"
            },
            {
                "value": 8112,
                "label": "Fulda",
                "label_ar": "فولدا",
                "label_fr": "Fulda"
            },
            {
                "value": 8113,
                "label": "Fullerton",
                "label_ar": "فوليرتون",
                "label_fr": "Fullerton"
            },
            {
                "value": 8114,
                "label": "Fulshear",
                "label_ar": "فولشير",
                "label_fr": "Fulshear"
            },
            {
                "value": 8115,
                "label": "Fulton",
                "label_ar": "فولتون",
                "label_fr": "Fulton"
            },
            {
                "value": 8116,
                "label": "Fultondale",
                "label_ar": "فولتونديل",
                "label_fr": "Fultondale"
            },
            {
                "value": 8117,
                "label": "Fultonham",
                "label_ar": "فولتونهام",
                "label_fr": "Fultonham"
            },
            {
                "value": 8118,
                "label": "Fultonville",
                "label_ar": "فولتونفيل",
                "label_fr": "Fultonville"
            },
            {
                "value": 8119,
                "label": "Fults",
                "label_ar": "أخطاء",
                "label_fr": "Défauts"
            },
            {
                "value": 8120,
                "label": "Funkstown",
                "label_ar": "فونكستاون",
                "label_fr": "Funkstown"
            },
            {
                "value": 8121,
                "label": "Fuquay-Varina",
                "label_ar": "فوكواي فارينا",
                "label_fr": "Fuquay-Varina"
            },
            {
                "value": 8122,
                "label": "Furlong",
                "label_ar": "فورلونج",
                "label_fr": "Furlong"
            },
            {
                "value": 8123,
                "label": "Fyffe",
                "label_ar": "فايف",
                "label_fr": "Fyffe"
            },
            {
                "value": 8124,
                "label": "Gable",
                "label_ar": "الجملون",
                "label_fr": "Gâble"
            },
            {
                "value": 8125,
                "label": "Gackle",
                "label_ar": "ثرثرة",
                "label_fr": "Gackle"
            },
            {
                "value": 8126,
                "label": "Gadsden",
                "label_ar": "جادسدن",
                "label_fr": "Gadsden"
            },
            {
                "value": 8127,
                "label": "Gaffney",
                "label_ar": "جافني",
                "label_fr": "Gaffney"
            },
            {
                "value": 8128,
                "label": "Gagetown",
                "label_ar": "جاجتاون",
                "label_fr": "Gagetown"
            },
            {
                "value": 8129,
                "label": "Gail",
                "label_ar": "جيل",
                "label_fr": "Gail"
            },
            {
                "value": 8130,
                "label": "Gaines",
                "label_ar": "جاينز",
                "label_fr": "Gaines"
            },
            {
                "value": 8131,
                "label": "Gainesboro",
                "label_ar": "جينسبورو",
                "label_fr": "Gainesboro"
            },
            {
                "value": 8132,
                "label": "Gainestown",
                "label_ar": "جاينستاون",
                "label_fr": "Gainestown"
            },
            {
                "value": 8133,
                "label": "Gainesville",
                "label_ar": "غينزفيل",
                "label_fr": "Gainesville"
            },
            {
                "value": 8134,
                "label": "Gaithersburg",
                "label_ar": "جايثرسبيرغ",
                "label_fr": "Gaithersburg"
            },
            {
                "value": 8135,
                "label": "Galatia",
                "label_ar": "غلاطية",
                "label_fr": "Galatie"
            },
            {
                "value": 8136,
                "label": "Galax",
                "label_ar": "جالاكس",
                "label_fr": "Galax"
            },
            {
                "value": 8137,
                "label": "Galena",
                "label_ar": "جالينا",
                "label_fr": "Galène"
            },
            {
                "value": 8138,
                "label": "Galena Park",
                "label_ar": "جالينا بارك",
                "label_fr": "Parc Galena"
            },
            {
                "value": 8139,
                "label": "Gales Ferry",
                "label_ar": "جاليس فيري",
                "label_fr": "Gales Ferry"
            },
            {
                "value": 8140,
                "label": "Galesburg",
                "label_ar": "جاليسبرج",
                "label_fr": "Galesburg"
            },
            {
                "value": 8141,
                "label": "Galesville",
                "label_ar": "جاليسفيل",
                "label_fr": "Galesville"
            },
            {
                "value": 8142,
                "label": "Galien",
                "label_ar": "جالين",
                "label_fr": "Galien"
            },
            {
                "value": 8143,
                "label": "Galion",
                "label_ar": "جاليون",
                "label_fr": "Galion"
            },
            {
                "value": 8144,
                "label": "Galivants Ferry",
                "label_ar": "جاليفانتس فيري",
                "label_fr": "Ferry des Galivants"
            },
            {
                "value": 8145,
                "label": "Gallatin",
                "label_ar": "جالاتين",
                "label_fr": "Gallatin"
            },
            {
                "value": 8146,
                "label": "Gallatin Gateway",
                "label_ar": "بوابة جالاتين",
                "label_fr": "Porte Gallatin"
            },
            {
                "value": 8147,
                "label": "Galliano",
                "label_ar": "جاليانو",
                "label_fr": "Galliano"
            },
            {
                "value": 8148,
                "label": "Gallion",
                "label_ar": "جاليون",
                "label_fr": "Gallion"
            },
            {
                "value": 8149,
                "label": "Gallipolis",
                "label_ar": "جاليبوليس",
                "label_fr": "Gallipolis"
            },
            {
                "value": 8150,
                "label": "Gallipolis Ferry",
                "label_ar": "جاليبوليس فيري",
                "label_fr": "Ferry de Gallipolis"
            },
            {
                "value": 8151,
                "label": "Gallitzin",
                "label_ar": "جاليتزين",
                "label_fr": "Gallitzin"
            },
            {
                "value": 8152,
                "label": "Galloway",
                "label_ar": "جالاوي",
                "label_fr": "Galloway"
            },
            {
                "value": 8153,
                "label": "Gallup",
                "label_ar": "جالوب",
                "label_fr": "Gallup"
            },
            {
                "value": 8154,
                "label": "Galt",
                "label_ar": "جالت",
                "label_fr": "Galt"
            },
            {
                "value": 8155,
                "label": "Galva",
                "label_ar": "جالفا",
                "label_fr": "Galva"
            },
            {
                "value": 8156,
                "label": "Galveston",
                "label_ar": "جالفستون",
                "label_fr": "Galveston"
            },
            {
                "value": 8157,
                "label": "Galway",
                "label_ar": "غالواي",
                "label_fr": "Galway"
            },
            {
                "value": 8158,
                "label": "Gambier",
                "label_ar": "جامبير",
                "label_fr": "Gambier"
            },
            {
                "value": 8159,
                "label": "Gambrills",
                "label_ar": "جامبريلز",
                "label_fr": "Gambrills"
            },
            {
                "value": 8160,
                "label": "Ganado",
                "label_ar": "جانادو",
                "label_fr": "Ganado"
            },
            {
                "value": 8161,
                "label": "Gans",
                "label_ar": "جانس",
                "label_fr": "Gans"
            },
            {
                "value": 8162,
                "label": "Gansevoort",
                "label_ar": "غانسيفورت",
                "label_fr": "Gansevoort"
            },
            {
                "value": 8163,
                "label": "Gap",
                "label_ar": "الفارق",
                "label_fr": "Écart"
            },
            {
                "value": 8164,
                "label": "Gap Mills",
                "label_ar": "جاب ميلز",
                "label_fr": "Gap Mills"
            },
            {
                "value": 8165,
                "label": "Garards Fort",
                "label_ar": "حصن جاراردس",
                "label_fr": "Fort de Garards"
            },
            {
                "value": 8166,
                "label": "Garber",
                "label_ar": "جاربر",
                "label_fr": "Garber"
            },
            {
                "value": 8167,
                "label": "Garberville",
                "label_ar": "جاربرفيل",
                "label_fr": "Garberville"
            },
            {
                "value": 8168,
                "label": "Garciasville",
                "label_ar": "جارسياسفيل",
                "label_fr": "Garciasville"
            },
            {
                "value": 8169,
                "label": "Garden City",
                "label_ar": "جاردن سيتي",
                "label_fr": "Garden City"
            },
            {
                "value": 8170,
                "label": "Garden Grove",
                "label_ar": "جاردن جروف",
                "label_fr": "Jardin arboré"
            },
            {
                "value": 8171,
                "label": "Garden Plain",
                "label_ar": "سهل الحديقة",
                "label_fr": "Jardin Plaine"
            },
            {
                "value": 8172,
                "label": "Garden Prairie",
                "label_ar": "حديقة البراري",
                "label_fr": "Jardin Prairie"
            },
            {
                "value": 8173,
                "label": "Garden Valley",
                "label_ar": "جاردن فالي",
                "label_fr": "Garden Valley"
            },
            {
                "value": 8174,
                "label": "Gardena",
                "label_ar": "جاردينا",
                "label_fr": "Gardena"
            },
            {
                "value": 8175,
                "label": "Gardendale",
                "label_ar": "غاردينديل",
                "label_fr": "Gardendale"
            },
            {
                "value": 8176,
                "label": "Gardiner",
                "label_ar": "جاردينر",
                "label_fr": "Gardiner"
            },
            {
                "value": 8177,
                "label": "Gardner",
                "label_ar": "عامل الحديقة",
                "label_fr": "Gardner"
            },
            {
                "value": 8178,
                "label": "Gardners",
                "label_ar": "الحدائق",
                "label_fr": "Gardners"
            },
            {
                "value": 8179,
                "label": "Gardnerville",
                "label_ar": "Gardnerville",
                "label_fr": "Gardnerville"
            },
            {
                "value": 8180,
                "label": "Garfield",
                "label_ar": "غارفيلد",
                "label_fr": "Garfield"
            },
            {
                "value": 8181,
                "label": "Garland",
                "label_ar": "جارلاند",
                "label_fr": "Guirlande"
            },
            {
                "value": 8182,
                "label": "Garnavillo",
                "label_ar": "غارنافيلو",
                "label_fr": "Garnavillo"
            },
            {
                "value": 8183,
                "label": "Garner",
                "label_ar": "غارنر",
                "label_fr": "Recueillir"
            },
            {
                "value": 8184,
                "label": "Garnerville",
                "label_ar": "غارنرفيل",
                "label_fr": "Garnerville"
            },
            {
                "value": 8185,
                "label": "Garnet Valley",
                "label_ar": "وادي جارنت",
                "label_fr": "Vallée du grenat"
            },
            {
                "value": 8186,
                "label": "Garnett",
                "label_ar": "غارنيت",
                "label_fr": "Garnett"
            },
            {
                "value": 8187,
                "label": "Garrard",
                "label_ar": "جارارد",
                "label_fr": "Garrard"
            },
            {
                "value": 8188,
                "label": "Garretson",
                "label_ar": "جاريتسون",
                "label_fr": "Garretson"
            },
            {
                "value": 8189,
                "label": "Garrett",
                "label_ar": "جاريت",
                "label_fr": "Garrett"
            },
            {
                "value": 8190,
                "label": "Garrettsville",
                "label_ar": "جاريتسفيل",
                "label_fr": "Garrettsville"
            },
            {
                "value": 8191,
                "label": "Garrison",
                "label_ar": "حامية",
                "label_fr": "Garnison"
            },
            {
                "value": 8192,
                "label": "Garryowen",
                "label_ar": "جاريوين",
                "label_fr": "Garryowen"
            },
            {
                "value": 8193,
                "label": "Garvin",
                "label_ar": "جارفين",
                "label_fr": "Garvin"
            },
            {
                "value": 8194,
                "label": "Garwin",
                "label_ar": "جاروين",
                "label_fr": "Garwin"
            },
            {
                "value": 8195,
                "label": "Garwood",
                "label_ar": "جاروود",
                "label_fr": "Garwood"
            },
            {
                "value": 8196,
                "label": "Gary",
                "label_ar": "غاري",
                "label_fr": "Gary"
            },
            {
                "value": 8197,
                "label": "Garysburg",
                "label_ar": "جاريسبورغ",
                "label_fr": "Garysburg"
            },
            {
                "value": 8198,
                "label": "Garyville",
                "label_ar": "جريفيل",
                "label_fr": "Garyville"
            },
            {
                "value": 8199,
                "label": "Gas",
                "label_ar": "غاز",
                "label_fr": "Gaz"
            },
            {
                "value": 8200,
                "label": "Gas City",
                "label_ar": "مدينة الغاز",
                "label_fr": "Gas City"
            },
            {
                "value": 8201,
                "label": "Gasport",
                "label_ar": "جاسبورت",
                "label_fr": "Gasport"
            },
            {
                "value": 8202,
                "label": "Gasquet",
                "label_ar": "جاسكيه",
                "label_fr": "Gasquet"
            },
            {
                "value": 8203,
                "label": "Gassaway",
                "label_ar": "جاساواي",
                "label_fr": "Gassaway"
            },
            {
                "value": 8204,
                "label": "Gassville",
                "label_ar": "جاسفيل",
                "label_fr": "Gassville"
            },
            {
                "value": 8205,
                "label": "Gaston",
                "label_ar": "جاستون",
                "label_fr": "Gaston"
            },
            {
                "value": 8206,
                "label": "Gastonia",
                "label_ar": "غاستونيا",
                "label_fr": "Gastonia"
            },
            {
                "value": 8207,
                "label": "Gastonville",
                "label_ar": "جاستونفيل",
                "label_fr": "Gastonville"
            },
            {
                "value": 8208,
                "label": "Gate City",
                "label_ar": "بوابة المدينة",
                "label_fr": "Gate City"
            },
            {
                "value": 8209,
                "label": "Gates",
                "label_ar": "بوابات",
                "label_fr": "portes"
            },
            {
                "value": 8210,
                "label": "Gates Mills",
                "label_ar": "جيتس ميلز",
                "label_fr": "Gates Mills"
            },
            {
                "value": 8211,
                "label": "Gatesville",
                "label_ar": "جيتسفيل",
                "label_fr": "Gatesville"
            },
            {
                "value": 8212,
                "label": "Gateway",
                "label_ar": "بوابة",
                "label_fr": "passerelle"
            },
            {
                "value": 8213,
                "label": "Gatlinburg",
                "label_ar": "جاتلينبرج",
                "label_fr": "Gatlinburg"
            },
            {
                "value": 8214,
                "label": "Gatzke",
                "label_ar": "جاتسك",
                "label_fr": "Gatzke"
            },
            {
                "value": 8215,
                "label": "Gauley Bridge",
                "label_ar": "جسر Gauley",
                "label_fr": "Pont Gauley"
            },
            {
                "value": 8216,
                "label": "Gause",
                "label_ar": "غثيان",
                "label_fr": "Gause"
            },
            {
                "value": 8217,
                "label": "Gautier",
                "label_ar": "جوتييه",
                "label_fr": "Gautier"
            },
            {
                "value": 8218,
                "label": "Gay",
                "label_ar": "مثلي",
                "label_fr": "Gay"
            },
            {
                "value": 8219,
                "label": "Gaylesville",
                "label_ar": "جايلزفيل",
                "label_fr": "Gaylesville"
            },
            {
                "value": 8220,
                "label": "Gaylord",
                "label_ar": "جايلورد",
                "label_fr": "Gaylord"
            },
            {
                "value": 8221,
                "label": "Gaylordsville",
                "label_ar": "جايلوردسفيل",
                "label_fr": "Gaylordsville"
            },
            {
                "value": 8222,
                "label": "Gays Creek",
                "label_ar": "جيز كريك",
                "label_fr": "Ruisseau Gays"
            },
            {
                "value": 8223,
                "label": "Gays Mills",
                "label_ar": "جيز ميلز",
                "label_fr": "Gays Mills"
            },
            {
                "value": 8224,
                "label": "Gazelle",
                "label_ar": "غزال",
                "label_fr": "Gazelle"
            },
            {
                "value": 8225,
                "label": "Geary",
                "label_ar": "جيري",
                "label_fr": "Geary"
            },
            {
                "value": 8226,
                "label": "Geff",
                "label_ar": "جيف",
                "label_fr": "Geff"
            },
            {
                "value": 8227,
                "label": "Geigertown",
                "label_ar": "جيجرتاون",
                "label_fr": "Geigertown"
            },
            {
                "value": 8228,
                "label": "Geismar",
                "label_ar": "جايسمار",
                "label_fr": "Geismar"
            },
            {
                "value": 8229,
                "label": "Genesee",
                "label_ar": "جينيسي",
                "label_fr": "Genesee"
            },
            {
                "value": 8230,
                "label": "Geneseo",
                "label_ar": "جينيسيو",
                "label_fr": "Geneseo"
            },
            {
                "value": 8231,
                "label": "Geneva",
                "label_ar": "جنيف",
                "label_fr": "Genève"
            },
            {
                "value": 8232,
                "label": "Genoa",
                "label_ar": "جنوة",
                "label_fr": "Gênes"
            },
            {
                "value": 8233,
                "label": "Genoa City",
                "label_ar": "مدينة جنوة",
                "label_fr": "Gênes"
            },
            {
                "value": 8234,
                "label": "Gentry",
                "label_ar": "الطبقة الراقية",
                "label_fr": "Noblesse"
            },
            {
                "value": 8235,
                "label": "Gentryville",
                "label_ar": "جينتريفيل",
                "label_fr": "Gentryville"
            },
            {
                "value": 8236,
                "label": "George",
                "label_ar": "جورج",
                "label_fr": "George"
            },
            {
                "value": 8237,
                "label": "George West",
                "label_ar": "جورج ويست",
                "label_fr": "George West"
            },
            {
                "value": 8238,
                "label": "Georgetown",
                "label_ar": "جورج تاون",
                "label_fr": "Georgetown"
            },
            {
                "value": 8239,
                "label": "Georgiana",
                "label_ar": "جورجيانا",
                "label_fr": "Georgiana"
            },
            {
                "value": 8240,
                "label": "Gerald",
                "label_ar": "جيرالد",
                "label_fr": "Gérald"
            },
            {
                "value": 8241,
                "label": "Geraldine",
                "label_ar": "جيرالدين",
                "label_fr": "Géraldine"
            },
            {
                "value": 8242,
                "label": "Gerber",
                "label_ar": "جربر",
                "label_fr": "Gerber"
            },
            {
                "value": 8243,
                "label": "Gering",
                "label_ar": "جيرنج",
                "label_fr": "Gering"
            },
            {
                "value": 8244,
                "label": "Gerlach",
                "label_ar": "جيرلاش",
                "label_fr": "Gerlach"
            },
            {
                "value": 8245,
                "label": "German Valley",
                "label_ar": "الوادي الألماني",
                "label_fr": "Vallée allemande"
            },
            {
                "value": 8246,
                "label": "Germansville",
                "label_ar": "جيرمانسفيل",
                "label_fr": "Germansville"
            },
            {
                "value": 8247,
                "label": "Germanton",
                "label_ar": "جيرمانتون",
                "label_fr": "Germanton"
            },
            {
                "value": 8248,
                "label": "Germantown",
                "label_ar": "جيرمانتاون",
                "label_fr": "Germantown"
            },
            {
                "value": 8249,
                "label": "Geronimo",
                "label_ar": "جيرونيمو",
                "label_fr": "Geronimo"
            },
            {
                "value": 8250,
                "label": "Gerrardstown",
                "label_ar": "جيراردستاون",
                "label_fr": "Gerrardstown"
            },
            {
                "value": 8251,
                "label": "Gerry",
                "label_ar": "جيري",
                "label_fr": "Gerry"
            },
            {
                "value": 8252,
                "label": "Gervais",
                "label_ar": "جيرفيه",
                "label_fr": "Gervais"
            },
            {
                "value": 8253,
                "label": "Gettysburg",
                "label_ar": "جيتيسبيرغ",
                "label_fr": "Gettysburg"
            },
            {
                "value": 8254,
                "label": "Getzville",
                "label_ar": "جيتزفيل",
                "label_fr": "Getzville"
            },
            {
                "value": 8255,
                "label": "Geuda Springs",
                "label_ar": "جيودا سبرينغز",
                "label_fr": "Geuda Springs"
            },
            {
                "value": 8256,
                "label": "Geyser",
                "label_ar": "السخان",
                "label_fr": "Geyser"
            },
            {
                "value": 8257,
                "label": "Geyserville",
                "label_ar": "جيسيرفيل",
                "label_fr": "Geyserville"
            },
            {
                "value": 8258,
                "label": "Gheens",
                "label_ar": "غينز",
                "label_fr": "Gheens"
            },
            {
                "value": 8259,
                "label": "Ghent",
                "label_ar": "غينت",
                "label_fr": "Gand"
            },
            {
                "value": 8260,
                "label": "Gibbon",
                "label_ar": "جيبون",
                "label_fr": "Gibbon"
            },
            {
                "value": 8261,
                "label": "Gibbsboro",
                "label_ar": "جيبسبورو",
                "label_fr": "Gibbsboro"
            },
            {
                "value": 8262,
                "label": "Gibbstown",
                "label_ar": "جيبستاون",
                "label_fr": "Gibbstown"
            },
            {
                "value": 8263,
                "label": "Gibsland",
                "label_ar": "جيبسلاند",
                "label_fr": "Gibsland"
            },
            {
                "value": 8264,
                "label": "Gibson",
                "label_ar": "جيبسون",
                "label_fr": "Gibson"
            },
            {
                "value": 8265,
                "label": "Gibson City",
                "label_ar": "مدينة جيبسون",
                "label_fr": "Gibson City"
            },
            {
                "value": 8266,
                "label": "Gibsonburg",
                "label_ar": "جيبسونبرغ",
                "label_fr": "Gibsonburg"
            },
            {
                "value": 8267,
                "label": "Gibsonia",
                "label_ar": "جيبسونيا",
                "label_fr": "Gibsonia"
            },
            {
                "value": 8268,
                "label": "Gibsonton",
                "label_ar": "جيبسون",
                "label_fr": "Gibsonton"
            },
            {
                "value": 8269,
                "label": "Gibsonville",
                "label_ar": "جيبسونفيل",
                "label_fr": "Gibsonville"
            },
            {
                "value": 8270,
                "label": "Giddings",
                "label_ar": "جيدينجز",
                "label_fr": "Étourdissements"
            },
            {
                "value": 8271,
                "label": "Gideon",
                "label_ar": "جدعون",
                "label_fr": "Gédéon"
            },
            {
                "value": 8272,
                "label": "Gifford",
                "label_ar": "جيفورد",
                "label_fr": "Gifford"
            },
            {
                "value": 8273,
                "label": "Gig Harbor",
                "label_ar": "جيج هاربور",
                "label_fr": "Gig Harbor"
            },
            {
                "value": 8274,
                "label": "Gila",
                "label_ar": "جيلا",
                "label_fr": "Gila"
            },
            {
                "value": 8275,
                "label": "Gila Bend",
                "label_ar": "جيلا بيند",
                "label_fr": "Gila Bend"
            },
            {
                "value": 8276,
                "label": "Gilbert",
                "label_ar": "جيلبرت",
                "label_fr": "Gilbert"
            },
            {
                "value": 8277,
                "label": "Gilbertown",
                "label_ar": "جيلبرتاون",
                "label_fr": "Gilbertown"
            },
            {
                "value": 8278,
                "label": "Gilberts",
                "label_ar": "جيلبرتس",
                "label_fr": "Gilberts"
            },
            {
                "value": 8279,
                "label": "Gilbertsville",
                "label_ar": "جيلبرتسفيل",
                "label_fr": "Gilbertsville"
            },
            {
                "value": 8280,
                "label": "Gilbertville",
                "label_ar": "جيلبرتفيل",
                "label_fr": "Gilbertville"
            },
            {
                "value": 8281,
                "label": "Gilboa",
                "label_ar": "جلبوع",
                "label_fr": "Gilboa"
            },
            {
                "value": 8282,
                "label": "Gilby",
                "label_ar": "جيلبي",
                "label_fr": "Gilby"
            },
            {
                "value": 8283,
                "label": "Gilcrest",
                "label_ar": "جيلكريست",
                "label_fr": "Gilcrest"
            },
            {
                "value": 8284,
                "label": "Gilford",
                "label_ar": "جيلفورد",
                "label_fr": "Gilford"
            },
            {
                "value": 8285,
                "label": "Gill",
                "label_ar": "جيل",
                "label_fr": "Gill"
            },
            {
                "value": 8286,
                "label": "Gillespie",
                "label_ar": "جيليسبي",
                "label_fr": "Gillespie"
            },
            {
                "value": 8287,
                "label": "Gillett",
                "label_ar": "جيليت",
                "label_fr": "Gillett"
            },
            {
                "value": 8288,
                "label": "Gillett Grove",
                "label_ar": "جيليت جروف",
                "label_fr": "Gillett Grove"
            },
            {
                "value": 8289,
                "label": "Gillette",
                "label_ar": "جيليت",
                "label_fr": "Gillette"
            },
            {
                "value": 8290,
                "label": "Gillham",
                "label_ar": "جيلهام",
                "label_fr": "Gillham"
            },
            {
                "value": 8291,
                "label": "Gillsville",
                "label_ar": "جيلسفيل",
                "label_fr": "Gillsville"
            },
            {
                "value": 8292,
                "label": "Gilman",
                "label_ar": "جيلمان",
                "label_fr": "Gilman"
            },
            {
                "value": 8293,
                "label": "Gilman City",
                "label_ar": "مدينة جيلمان",
                "label_fr": "Gilman City"
            },
            {
                "value": 8294,
                "label": "Gilmanton",
                "label_ar": "جيلمانتون",
                "label_fr": "Gilmanton"
            },
            {
                "value": 8295,
                "label": "Gilmanton Ironworks",
                "label_ar": "جيلمانتون لأعمال الحديد",
                "label_fr": "Usine sidérurgique de Gilmanton"
            },
            {
                "value": 8296,
                "label": "Gilmer",
                "label_ar": "جيلمر",
                "label_fr": "Gilmer"
            },
            {
                "value": 8297,
                "label": "Gilmore City",
                "label_ar": "مدينة جيلمور",
                "label_fr": "Gilmore City"
            },
            {
                "value": 8298,
                "label": "Gilroy",
                "label_ar": "جيلروي",
                "label_fr": "Gilroy"
            },
            {
                "value": 8299,
                "label": "Gilson",
                "label_ar": "جيلسون",
                "label_fr": "Gilson"
            },
            {
                "value": 8300,
                "label": "Gilsum",
                "label_ar": "جيلسوم",
                "label_fr": "Gilsum"
            },
            {
                "value": 8301,
                "label": "Giltner",
                "label_ar": "جيلتنر",
                "label_fr": "Giltner"
            },
            {
                "value": 8302,
                "label": "Girard",
                "label_ar": "جيرارد",
                "label_fr": "Girard"
            },
            {
                "value": 8303,
                "label": "Girardville",
                "label_ar": "جيراردفيل",
                "label_fr": "Girardville"
            },
            {
                "value": 8304,
                "label": "Girdwood",
                "label_ar": "جيردوود",
                "label_fr": "Girdwood"
            },
            {
                "value": 8305,
                "label": "Girvin",
                "label_ar": "جيرفين",
                "label_fr": "Girvin"
            },
            {
                "value": 8306,
                "label": "Gladbrook",
                "label_ar": "جلادبروك",
                "label_fr": "Gladbrook"
            },
            {
                "value": 8307,
                "label": "Glade Spring",
                "label_ar": "جليد سبرينغ",
                "label_fr": "Glade Spring"
            },
            {
                "value": 8308,
                "label": "Glade Valley",
                "label_ar": "وادي جليد",
                "label_fr": "Glade Valley"
            },
            {
                "value": 8309,
                "label": "Gladehill",
                "label_ar": "جلايدهيل",
                "label_fr": "Gladehill"
            },
            {
                "value": 8310,
                "label": "Gladewater",
                "label_ar": "جلديووتر",
                "label_fr": "Gladewater"
            },
            {
                "value": 8311,
                "label": "Gladstone",
                "label_ar": "جلادستون",
                "label_fr": "Gladstone"
            },
            {
                "value": 8312,
                "label": "Gladwin",
                "label_ar": "جلادوين",
                "label_fr": "Gladwin"
            },
            {
                "value": 8313,
                "label": "Gladwyne",
                "label_ar": "جلادوين",
                "label_fr": "Gladwyne"
            },
            {
                "value": 8314,
                "label": "Gladys",
                "label_ar": "غلاديس",
                "label_fr": "Gladys"
            },
            {
                "value": 8315,
                "label": "Glasco",
                "label_ar": "جلاسكو",
                "label_fr": "Glasco"
            },
            {
                "value": 8316,
                "label": "Glasford",
                "label_ar": "جلاسفورد",
                "label_fr": "Glasford"
            },
            {
                "value": 8317,
                "label": "Glasgow",
                "label_ar": "غلاسكو",
                "label_fr": "Glasgow"
            },
            {
                "value": 8318,
                "label": "Glassboro",
                "label_ar": "جلاسبورو",
                "label_fr": "Glassboro"
            },
            {
                "value": 8319,
                "label": "Glassport",
                "label_ar": "جلاسبورت",
                "label_fr": "Glassport"
            },
            {
                "value": 8320,
                "label": "Glastonbury",
                "label_ar": "جلاستونبري",
                "label_fr": "Glastonbury"
            },
            {
                "value": 8321,
                "label": "Gleason",
                "label_ar": "جليسون",
                "label_fr": "Gleason"
            },
            {
                "value": 8322,
                "label": "Glen Allan",
                "label_ar": "جلين آلان",
                "label_fr": "Glen Allan"
            },
            {
                "value": 8323,
                "label": "Glen Allen",
                "label_ar": "جلين ألين",
                "label_fr": "Glen Allen"
            },
            {
                "value": 8324,
                "label": "Glen Arbor",
                "label_ar": "جلين أربور",
                "label_fr": "Glen Arbour"
            },
            {
                "value": 8325,
                "label": "Glen Arm",
                "label_ar": "جلين ارم",
                "label_fr": "Glen Arm"
            },
            {
                "value": 8326,
                "label": "Glen Burnie",
                "label_ar": "جلين بورني",
                "label_fr": "Glen Burnie"
            },
            {
                "value": 8327,
                "label": "Glen Campbell",
                "label_ar": "جلين كامبل",
                "label_fr": "Glen Campbell"
            },
            {
                "value": 8328,
                "label": "Glen Carbon",
                "label_ar": "جلين كربون",
                "label_fr": "Glen Carbon"
            },
            {
                "value": 8329,
                "label": "Glen Cove",
                "label_ar": "جلين كوف",
                "label_fr": "Glen Cove"
            },
            {
                "value": 8330,
                "label": "Glen Daniel",
                "label_ar": "جلين دانيال",
                "label_fr": "Glen Daniel"
            },
            {
                "value": 8331,
                "label": "Glen Easton",
                "label_ar": "جلين ايستون",
                "label_fr": "Glen Easton"
            },
            {
                "value": 8332,
                "label": "Glen Elder",
                "label_ar": "جلين إلدر",
                "label_fr": "Glen Elder"
            },
            {
                "value": 8333,
                "label": "Glen Ellen",
                "label_ar": "جلين إلين",
                "label_fr": "Glen Ellen"
            },
            {
                "value": 8334,
                "label": "Glen Ellyn",
                "label_ar": "جلين إلين",
                "label_fr": "Glen Ellyn"
            },
            {
                "value": 8335,
                "label": "Glen Flora",
                "label_ar": "جلين فلورا",
                "label_fr": "Glen Flora"
            },
            {
                "value": 8336,
                "label": "Glen Fork",
                "label_ar": "جلين فورك",
                "label_fr": "Glen Fork"
            },
            {
                "value": 8337,
                "label": "Glen Gardner",
                "label_ar": "جلين جاردنر",
                "label_fr": "Glen Gardner"
            },
            {
                "value": 8338,
                "label": "Glen Head",
                "label_ar": "جلين هيد",
                "label_fr": "Glen Head"
            },
            {
                "value": 8339,
                "label": "Glen Hope",
                "label_ar": "جلين هوب",
                "label_fr": "Glen Hope"
            },
            {
                "value": 8340,
                "label": "Glen Lyon",
                "label_ar": "جلين ليون",
                "label_fr": "Glen Lyon"
            },
            {
                "value": 8341,
                "label": "Glen Mills",
                "label_ar": "جلين ميلز",
                "label_fr": "Glen Mills"
            },
            {
                "value": 8342,
                "label": "Glen Oaks",
                "label_ar": "جلين أوكس",
                "label_fr": "Glen Oaks"
            },
            {
                "value": 8343,
                "label": "Glen Ridge",
                "label_ar": "جلين ريدج",
                "label_fr": "Glen Ridge"
            },
            {
                "value": 8344,
                "label": "Glen Rock",
                "label_ar": "جلين روك",
                "label_fr": "Glen Rock"
            },
            {
                "value": 8345,
                "label": "Glen Rose",
                "label_ar": "جلين روز",
                "label_fr": "Glen Rose"
            },
            {
                "value": 8346,
                "label": "Glen Saint Mary",
                "label_ar": "جلين سانت ماري",
                "label_fr": "Glen Saint Mary"
            },
            {
                "value": 8347,
                "label": "Glen Spey",
                "label_ar": "جلين سبي",
                "label_fr": "Glen Spey"
            },
            {
                "value": 8348,
                "label": "Glen Ullin",
                "label_ar": "جلين أولين",
                "label_fr": "Glen Ullin"
            },
            {
                "value": 8349,
                "label": "Glenallen",
                "label_ar": "جلينالين",
                "label_fr": "Glenallen"
            },
            {
                "value": 8350,
                "label": "Glenbeulah",
                "label_ar": "جلينبولا",
                "label_fr": "Glenbeulah"
            },
            {
                "value": 8351,
                "label": "Glenbrook",
                "label_ar": "جلينبروك",
                "label_fr": "Glenbrook"
            },
            {
                "value": 8352,
                "label": "Glenburn",
                "label_ar": "جلينبرن",
                "label_fr": "Glenburn"
            },
            {
                "value": 8353,
                "label": "Glencoe",
                "label_ar": "جلينكو",
                "label_fr": "Glencoe"
            },
            {
                "value": 8354,
                "label": "Glendale",
                "label_ar": "جلينديل",
                "label_fr": "Glendale"
            },
            {
                "value": 8355,
                "label": "Glendale Heights",
                "label_ar": "مرتفعات جلينديل",
                "label_fr": "Glendale Heights"
            },
            {
                "value": 8356,
                "label": "Glendive",
                "label_ar": "جلينديف",
                "label_fr": "Glendive"
            },
            {
                "value": 8357,
                "label": "Glendo",
                "label_ar": "جليندو",
                "label_fr": "Glendo"
            },
            {
                "value": 8358,
                "label": "Glendora",
                "label_ar": "جليندورا",
                "label_fr": "Glendora"
            },
            {
                "value": 8359,
                "label": "Glenelg",
                "label_ar": "جلينيلج",
                "label_fr": "Glenelg"
            },
            {
                "value": 8360,
                "label": "Glenfield",
                "label_ar": "جلينفيلد",
                "label_fr": "Glenfield"
            },
            {
                "value": 8361,
                "label": "Glenford",
                "label_ar": "جلينفورد",
                "label_fr": "Glenford"
            },
            {
                "value": 8362,
                "label": "Glengary",
                "label_ar": "غلينجارى",
                "label_fr": "Glengary"
            },
            {
                "value": 8363,
                "label": "Glenham",
                "label_ar": "جلينهام",
                "label_fr": "Glenham"
            },
            {
                "value": 8364,
                "label": "Glenmont",
                "label_ar": "جلينمونت",
                "label_fr": "Glenmont"
            },
            {
                "value": 8365,
                "label": "Glenmoore",
                "label_ar": "جلينمور",
                "label_fr": "Glenmoore"
            },
            {
                "value": 8366,
                "label": "Glenmora",
                "label_ar": "جلينمورا",
                "label_fr": "Glenmora"
            },
            {
                "value": 8367,
                "label": "Glenn",
                "label_ar": "جلين",
                "label_fr": "Glenn"
            },
            {
                "value": 8368,
                "label": "Glenn Dale",
                "label_ar": "جلين ديل",
                "label_fr": "Glenn Dale"
            },
            {
                "value": 8369,
                "label": "Glenns Ferry",
                "label_ar": "جلينز فيري",
                "label_fr": "Glenns Ferry"
            },
            {
                "value": 8370,
                "label": "Glennville",
                "label_ar": "جلينفيل",
                "label_fr": "Glennville"
            },
            {
                "value": 8371,
                "label": "Glenolden",
                "label_ar": "جلينولدن",
                "label_fr": "Glenolden"
            },
            {
                "value": 8372,
                "label": "Glenpool",
                "label_ar": "جلينبول",
                "label_fr": "Glenpool"
            },
            {
                "value": 8373,
                "label": "Glenrock",
                "label_ar": "جلينروك",
                "label_fr": "Glenrock"
            },
            {
                "value": 8374,
                "label": "Glens",
                "label_ar": "جلينز",
                "label_fr": "Glens"
            },
            {
                "value": 8375,
                "label": "Glens Falls",
                "label_ar": "جلينز فولز",
                "label_fr": "Glens Falls"
            },
            {
                "value": 8376,
                "label": "Glens Fork",
                "label_ar": "جلينز فورك",
                "label_fr": "Fourchette Glens"
            },
            {
                "value": 8377,
                "label": "Glenshaw",
                "label_ar": "جلينشو",
                "label_fr": "Glenshaw"
            },
            {
                "value": 8378,
                "label": "Glenside",
                "label_ar": "جلينسايد",
                "label_fr": "Glenside"
            },
            {
                "value": 8379,
                "label": "Glenview",
                "label_ar": "جلينفيو",
                "label_fr": "Glenview"
            },
            {
                "value": 8380,
                "label": "Glenvil",
                "label_ar": "جلينفيل",
                "label_fr": "Glenvil"
            },
            {
                "value": 8381,
                "label": "Glenville",
                "label_ar": "جلينفيل",
                "label_fr": "Glenville"
            },
            {
                "value": 8382,
                "label": "Glenwood",
                "label_ar": "جلينوود",
                "label_fr": "Glenwood"
            },
            {
                "value": 8383,
                "label": "Glenwood City",
                "label_ar": "مدينة جلينوود",
                "label_fr": "Glenwood City"
            },
            {
                "value": 8384,
                "label": "Glenwood Landing",
                "label_ar": "جلينوود لاندينج",
                "label_fr": "Glenwood Landing"
            },
            {
                "value": 8385,
                "label": "Glenwood Springs",
                "label_ar": "جلينوود سبرينجز",
                "label_fr": "Glenwood Springs"
            },
            {
                "value": 8386,
                "label": "Glidden",
                "label_ar": "غليدن",
                "label_fr": "Glidden"
            },
            {
                "value": 8387,
                "label": "Globe",
                "label_ar": "كره ارضيه",
                "label_fr": "Globe"
            },
            {
                "value": 8388,
                "label": "Glorieta",
                "label_ar": "جلوريتا",
                "label_fr": "Glorieta"
            },
            {
                "value": 8389,
                "label": "Gloster",
                "label_ar": "جلوستر",
                "label_fr": "Gloster"
            },
            {
                "value": 8390,
                "label": "Gloucester",
                "label_ar": "جلوستر",
                "label_fr": "Gloucester"
            },
            {
                "value": 8391,
                "label": "Gloucester City",
                "label_ar": "جلوستر سيتي",
                "label_fr": "Ville de Gloucester"
            },
            {
                "value": 8392,
                "label": "Gloucester Point",
                "label_ar": "جلوستر بوينت",
                "label_fr": "Gloucester Point"
            },
            {
                "value": 8393,
                "label": "Glouster",
                "label_ar": "جلوستر",
                "label_fr": "Glouster"
            },
            {
                "value": 8394,
                "label": "Glover",
                "label_ar": "جلوفر",
                "label_fr": "Gantier"
            },
            {
                "value": 8395,
                "label": "Gloversville",
                "label_ar": "جلوفرسفيل",
                "label_fr": "Gloversville"
            },
            {
                "value": 8396,
                "label": "Glyndon",
                "label_ar": "جليندون",
                "label_fr": "Glyndon"
            },
            {
                "value": 8397,
                "label": "Gobles",
                "label_ar": "جوبلز",
                "label_fr": "Gobles"
            },
            {
                "value": 8398,
                "label": "Goddard",
                "label_ar": "جودارد",
                "label_fr": "Goddard"
            },
            {
                "value": 8399,
                "label": "Godfrey",
                "label_ar": "جودفري",
                "label_fr": "Godfrey"
            },
            {
                "value": 8400,
                "label": "Godley",
                "label_ar": "جودلي",
                "label_fr": "Godley"
            },
            {
                "value": 8401,
                "label": "Godwin",
                "label_ar": "جودوين",
                "label_fr": "Godwin"
            },
            {
                "value": 8402,
                "label": "Goehner",
                "label_ar": "Goehner",
                "label_fr": "Goehner"
            },
            {
                "value": 8403,
                "label": "Goessel",
                "label_ar": "جوسيل",
                "label_fr": "Goessel"
            },
            {
                "value": 8404,
                "label": "Goff",
                "label_ar": "جوف",
                "label_fr": "Goff"
            },
            {
                "value": 8405,
                "label": "Goffstown",
                "label_ar": "جوفستاون",
                "label_fr": "Goffstown"
            },
            {
                "value": 8406,
                "label": "Golconda",
                "label_ar": "جولكوندا",
                "label_fr": "Golconde"
            },
            {
                "value": 8407,
                "label": "Gold Bar",
                "label_ar": "سبيكة ذهب",
                "label_fr": "Lingot d'or"
            },
            {
                "value": 8408,
                "label": "Gold Beach",
                "label_ar": "جولد بيتش",
                "label_fr": "Plage d'or"
            },
            {
                "value": 8409,
                "label": "Gold Canyon",
                "label_ar": "جولد كانيون",
                "label_fr": "Canyon d'or"
            },
            {
                "value": 8410,
                "label": "Gold Hill",
                "label_ar": "جولد هيل",
                "label_fr": "Gold Hill"
            },
            {
                "value": 8411,
                "label": "Golden",
                "label_ar": "ذهبي",
                "label_fr": "d'or"
            },
            {
                "value": 8412,
                "label": "Golden City",
                "label_ar": "المدينة الذهبية",
                "label_fr": "ville d'or"
            },
            {
                "value": 8413,
                "label": "Golden Eagle",
                "label_ar": "النسر الذهبي",
                "label_fr": "Aigle en or"
            },
            {
                "value": 8414,
                "label": "Golden Meadow",
                "label_ar": "جولدن ميدو",
                "label_fr": "Prairie dorée"
            },
            {
                "value": 8415,
                "label": "Golden Valley",
                "label_ar": "جولدن فالي",
                "label_fr": "Vallée dorée"
            },
            {
                "value": 8416,
                "label": "Goldendale",
                "label_ar": "غولدنديل",
                "label_fr": "Goldendale"
            },
            {
                "value": 8417,
                "label": "Goldenrod",
                "label_ar": "جولدن رود",
                "label_fr": "Verge d'or"
            },
            {
                "value": 8418,
                "label": "Goldens Bridge",
                "label_ar": "جسر غولدنز",
                "label_fr": "Pont Goldens"
            },
            {
                "value": 8419,
                "label": "Goldfield",
                "label_ar": "غولدفيلد",
                "label_fr": "Goldfield"
            },
            {
                "value": 8420,
                "label": "Goldonna",
                "label_ar": "غولدونا",
                "label_fr": "Goldonna"
            },
            {
                "value": 8421,
                "label": "Goldsboro",
                "label_ar": "غولدسبورو",
                "label_fr": "Goldsboro"
            },
            {
                "value": 8422,
                "label": "Goldsmith",
                "label_ar": "صائغ",
                "label_fr": "Orfèvre"
            },
            {
                "value": 8423,
                "label": "Goldston",
                "label_ar": "غولدستون",
                "label_fr": "Goldston"
            },
            {
                "value": 8424,
                "label": "Goldthwaite",
                "label_ar": "جولدثويت",
                "label_fr": "Goldthwaite"
            },
            {
                "value": 8425,
                "label": "Goldvein",
                "label_ar": "غولدفين",
                "label_fr": "Goldvein"
            },
            {
                "value": 8426,
                "label": "Goleta",
                "label_ar": "جوليتا",
                "label_fr": "Goleta"
            },
            {
                "value": 8427,
                "label": "Goliad",
                "label_ar": "جالاد",
                "label_fr": "Goliad"
            },
            {
                "value": 8428,
                "label": "Goltry",
                "label_ar": "جولتري",
                "label_fr": "Goltry"
            },
            {
                "value": 8429,
                "label": "Gonvick",
                "label_ar": "جونفيك",
                "label_fr": "Gonvick"
            },
            {
                "value": 8430,
                "label": "Gonzales",
                "label_ar": "جونزاليس",
                "label_fr": "Gonzales"
            },
            {
                "value": 8431,
                "label": "Goochland",
                "label_ar": "Goochland",
                "label_fr": "Goochland"
            },
            {
                "value": 8432,
                "label": "Good Hope",
                "label_ar": "رجاء جميل",
                "label_fr": "Bon espoir"
            },
            {
                "value": 8433,
                "label": "Good Thunder",
                "label_ar": "رعد جيد",
                "label_fr": "Bon tonnerre"
            },
            {
                "value": 8434,
                "label": "Goode",
                "label_ar": "جود",
                "label_fr": "Goode"
            },
            {
                "value": 8435,
                "label": "Goodells",
                "label_ar": "جودلز",
                "label_fr": "Goodells"
            },
            {
                "value": 8436,
                "label": "Goodfield",
                "label_ar": "جودفيلد",
                "label_fr": "Goodfield"
            },
            {
                "value": 8437,
                "label": "Goodhue",
                "label_ar": "جودهو",
                "label_fr": "Goodhue"
            },
            {
                "value": 8438,
                "label": "Gooding",
                "label_ar": "جوودينغ",
                "label_fr": "Gooding"
            },
            {
                "value": 8439,
                "label": "Goodland",
                "label_ar": "جودلاند",
                "label_fr": "Goodland"
            },
            {
                "value": 8440,
                "label": "Goodlettsville",
                "label_ar": "جودليتسفيل",
                "label_fr": "Goodlettsville"
            },
            {
                "value": 8441,
                "label": "Goodman",
                "label_ar": "رجل صالح",
                "label_fr": "Homme bon"
            },
            {
                "value": 8442,
                "label": "Goodnews Bay",
                "label_ar": "جودنيوز باي",
                "label_fr": "Goodnews Bay"
            },
            {
                "value": 8443,
                "label": "Goodrich",
                "label_ar": "جودريتش",
                "label_fr": "Goodrich"
            },
            {
                "value": 8444,
                "label": "Goodridge",
                "label_ar": "جودريدج",
                "label_fr": "Goodridge"
            },
            {
                "value": 8445,
                "label": "Goodview",
                "label_ar": "جودفيو",
                "label_fr": "Bonne vue"
            },
            {
                "value": 8446,
                "label": "Goodwater",
                "label_ar": "جود ووتر",
                "label_fr": "Goodwater"
            },
            {
                "value": 8447,
                "label": "Goodwell",
                "label_ar": "كذلك جيد",
                "label_fr": "Bon bien"
            },
            {
                "value": 8448,
                "label": "Goodyear",
                "label_ar": "سنة جيدة",
                "label_fr": "Bonne année"
            },
            {
                "value": 8449,
                "label": "Goose Creek",
                "label_ar": "غوس كريك",
                "label_fr": "Goose Creek"
            },
            {
                "value": 8450,
                "label": "Gordo",
                "label_ar": "جوردو",
                "label_fr": "Gordo"
            },
            {
                "value": 8451,
                "label": "Gordon",
                "label_ar": "جوردون",
                "label_fr": "Gordon"
            },
            {
                "value": 8452,
                "label": "Gordonsville",
                "label_ar": "جوردونسفيل",
                "label_fr": "Gordonsville"
            },
            {
                "value": 8453,
                "label": "Gordonville",
                "label_ar": "جوردونفيل",
                "label_fr": "Gordonville"
            },
            {
                "value": 8454,
                "label": "Gore",
                "label_ar": "جور",
                "label_fr": "Sang"
            },
            {
                "value": 8455,
                "label": "Gore Springs",
                "label_ar": "جور سبرينجز",
                "label_fr": "Gore Springs"
            },
            {
                "value": 8456,
                "label": "Goreville",
                "label_ar": "جوريفيل",
                "label_fr": "Goreville"
            },
            {
                "value": 8457,
                "label": "Gorham",
                "label_ar": "جورهام",
                "label_fr": "Gorham"
            },
            {
                "value": 8458,
                "label": "Gorin",
                "label_ar": "كورين",
                "label_fr": "Gorin"
            },
            {
                "value": 8459,
                "label": "Gorman",
                "label_ar": "جورمان",
                "label_fr": "Gorman"
            },
            {
                "value": 8460,
                "label": "Goshen",
                "label_ar": "جاسان",
                "label_fr": "Goshen"
            },
            {
                "value": 8461,
                "label": "Gosport",
                "label_ar": "اذهب للمارسة الرياضه",
                "label_fr": "Aller au sport"
            },
            {
                "value": 8462,
                "label": "Gotha",
                "label_ar": "جوتا",
                "label_fr": "Gotha"
            },
            {
                "value": 8463,
                "label": "Gotham",
                "label_ar": "جوثام",
                "label_fr": "Gotham"
            },
            {
                "value": 8464,
                "label": "Gothenburg",
                "label_ar": "جوتنبرج",
                "label_fr": "Göteborg"
            },
            {
                "value": 8465,
                "label": "Gould",
                "label_ar": "جولد",
                "label_fr": "Gould"
            },
            {
                "value": 8466,
                "label": "Gouldsboro",
                "label_ar": "جولدسبورو",
                "label_fr": "Gouldsboro"
            },
            {
                "value": 8467,
                "label": "Gouverneur",
                "label_ar": "جوفيرنور",
                "label_fr": "Gouverneur"
            },
            {
                "value": 8468,
                "label": "Gowanda",
                "label_ar": "جواندا",
                "label_fr": "Gowanda"
            },
            {
                "value": 8469,
                "label": "Gowen",
                "label_ar": "جوين",
                "label_fr": "Gowen"
            },
            {
                "value": 8470,
                "label": "Gower",
                "label_ar": "جاور",
                "label_fr": "Gower"
            },
            {
                "value": 8471,
                "label": "Gowrie",
                "label_ar": "جوري",
                "label_fr": "Gowrie"
            },
            {
                "value": 8472,
                "label": "Grabill",
                "label_ar": "جرابيل",
                "label_fr": "Grabill"
            },
            {
                "value": 8473,
                "label": "Grace",
                "label_ar": "نعمة او وقت سماح",
                "label_fr": "la grâce"
            },
            {
                "value": 8474,
                "label": "Grace City",
                "label_ar": "جريس سيتي",
                "label_fr": "Grace City"
            },
            {
                "value": 8475,
                "label": "Gracemont",
                "label_ar": "جراسيمونت",
                "label_fr": "Gracemont"
            },
            {
                "value": 8476,
                "label": "Graceville",
                "label_ar": "جراسفيل",
                "label_fr": "Graceville"
            },
            {
                "value": 8477,
                "label": "Grady",
                "label_ar": "جرادي",
                "label_fr": "Grady"
            },
            {
                "value": 8478,
                "label": "Gradyville",
                "label_ar": "جراديفيل",
                "label_fr": "Gradyville"
            },
            {
                "value": 8479,
                "label": "Graettinger",
                "label_ar": "غراتينجر",
                "label_fr": "Graettinger"
            },
            {
                "value": 8480,
                "label": "Graford",
                "label_ar": "جرافورد",
                "label_fr": "Graford"
            },
            {
                "value": 8481,
                "label": "Grafton",
                "label_ar": "جرافتون",
                "label_fr": "Grafton"
            },
            {
                "value": 8482,
                "label": "Graham",
                "label_ar": "جراهام",
                "label_fr": "Graham"
            },
            {
                "value": 8483,
                "label": "Grain Valley",
                "label_ar": "وادي الحبوب",
                "label_fr": "Vallée des céréales"
            },
            {
                "value": 8484,
                "label": "Grambling",
                "label_ar": "غرامبلينج",
                "label_fr": "Grambling"
            },
            {
                "value": 8485,
                "label": "Gramercy",
                "label_ar": "جراميرسي",
                "label_fr": "Gramercy"
            },
            {
                "value": 8486,
                "label": "Grammer",
                "label_ar": "نحوي",
                "label_fr": "Grammer"
            },
            {
                "value": 8487,
                "label": "Grampian",
                "label_ar": "غرامبيان",
                "label_fr": "Grampian"
            },
            {
                "value": 8488,
                "label": "Granada",
                "label_ar": "غرناطة",
                "label_fr": "Grenade"
            },
            {
                "value": 8489,
                "label": "Granada Hills",
                "label_ar": "تلال غرناطة",
                "label_fr": "Grenade Hills"
            },
            {
                "value": 8490,
                "label": "Granbury",
                "label_ar": "جرانبري",
                "label_fr": "Granbury"
            },
            {
                "value": 8491,
                "label": "Granby",
                "label_ar": "جرانبي",
                "label_fr": "Granby"
            },
            {
                "value": 8492,
                "label": "Grand Bay",
                "label_ar": "جراند باي",
                "label_fr": "Grand Baie"
            },
            {
                "value": 8493,
                "label": "Grand Blanc",
                "label_ar": "جراند بلانك",
                "label_fr": "Grand Blanc"
            },
            {
                "value": 8494,
                "label": "Grand Cane",
                "label_ar": "جراند قصب",
                "label_fr": "Grande Canne"
            },
            {
                "value": 8495,
                "label": "Grand Canyon",
                "label_ar": "جراند كانيون",
                "label_fr": "grand Canyon"
            },
            {
                "value": 8496,
                "label": "Grand Coulee",
                "label_ar": "جراند كولي",
                "label_fr": "Grand Coulée"
            },
            {
                "value": 8497,
                "label": "Grand Forks",
                "label_ar": "جراند فوركس",
                "label_fr": "Grand Forks"
            },
            {
                "value": 8498,
                "label": "Grand Haven",
                "label_ar": "جراند هافن",
                "label_fr": "Grand Haven"
            },
            {
                "value": 8499,
                "label": "Grand Island",
                "label_ar": "جراند ايلاند",
                "label_fr": "Grand Island"
            },
            {
                "value": 8500,
                "label": "Grand Isle",
                "label_ar": "جراند آيل",
                "label_fr": "Grand Isle"
            },
            {
                "value": 8501,
                "label": "Grand Junction",
                "label_ar": "جراند جنكشن",
                "label_fr": "Grand Junction"
            },
            {
                "value": 8502,
                "label": "Grand Lake",
                "label_ar": "جراند ليك",
                "label_fr": "Grand lac"
            },
            {
                "value": 8503,
                "label": "Grand Ledge",
                "label_ar": "جراند ليدج",
                "label_fr": "Grand rebord"
            },
            {
                "value": 8504,
                "label": "Grand Marais",
                "label_ar": "جراند ماريه",
                "label_fr": "Grand Marais"
            },
            {
                "value": 8505,
                "label": "Grand Marsh",
                "label_ar": "جراند مارش",
                "label_fr": "Grand Marais"
            },
            {
                "value": 8506,
                "label": "Grand Meadow",
                "label_ar": "جراند ميدو",
                "label_fr": "Grand Prairie"
            },
            {
                "value": 8507,
                "label": "Grand Mound",
                "label_ar": "جراند ماوند",
                "label_fr": "Grand Mound"
            },
            {
                "value": 8508,
                "label": "Grand Portage",
                "label_ar": "جراند بورتاج",
                "label_fr": "Grand Portage"
            },
            {
                "value": 8509,
                "label": "Grand Prairie",
                "label_ar": "المرج الكبير",
                "label_fr": "Grand Prairie"
            },
            {
                "value": 8510,
                "label": "Grand Rapids",
                "label_ar": "المنحدرات الكبرى",
                "label_fr": "Grand Rapids"
            },
            {
                "value": 8511,
                "label": "Grand Ridge",
                "label_ar": "جراند ريدج",
                "label_fr": "Grand Ridge"
            },
            {
                "value": 8512,
                "label": "Grand River",
                "label_ar": "جراند ريفر",
                "label_fr": "Grand River"
            },
            {
                "value": 8513,
                "label": "Grand Rivers",
                "label_ar": "جراند ريفرز",
                "label_fr": "Grand Rivers"
            },
            {
                "value": 8514,
                "label": "Grand Ronde",
                "label_ar": "جراند روند",
                "label_fr": "Grand Ronde"
            },
            {
                "value": 8515,
                "label": "Grand Saline",
                "label_ar": "جراند سالين",
                "label_fr": "Grand Saline"
            },
            {
                "value": 8516,
                "label": "Grand Terrace",
                "label_ar": "جراند تراس",
                "label_fr": "Grande terrasse"
            },
            {
                "value": 8517,
                "label": "Grand Tower",
                "label_ar": "جراند تاور",
                "label_fr": "Grand tour"
            },
            {
                "value": 8518,
                "label": "Grand View",
                "label_ar": "عرض الكبير",
                "label_fr": "Grand Vue"
            },
            {
                "value": 8519,
                "label": "Grandfalls",
                "label_ar": "الأجداد",
                "label_fr": "Grandfalls"
            },
            {
                "value": 8520,
                "label": "Grandfield",
                "label_ar": "جراندفيلد",
                "label_fr": "Grandfield"
            },
            {
                "value": 8521,
                "label": "Grandin",
                "label_ar": "جراندين",
                "label_fr": "Grandin"
            },
            {
                "value": 8522,
                "label": "Grandview",
                "label_ar": "عرض الكبير",
                "label_fr": "Grandview"
            },
            {
                "value": 8523,
                "label": "Grandville",
                "label_ar": "جراندفيل",
                "label_fr": "Grandville"
            },
            {
                "value": 8524,
                "label": "Grandy",
                "label_ar": "جراندي",
                "label_fr": "Grandy"
            },
            {
                "value": 8525,
                "label": "Granger",
                "label_ar": "جرانجر",
                "label_fr": "Granger"
            },
            {
                "value": 8526,
                "label": "Grangeville",
                "label_ar": "جرانجفيل",
                "label_fr": "Grangeville"
            },
            {
                "value": 8527,
                "label": "Granite",
                "label_ar": "جرانيت",
                "label_fr": "Granit"
            },
            {
                "value": 8528,
                "label": "Granite Bay",
                "label_ar": "خليج الجرانيت",
                "label_fr": "Granite Bay"
            },
            {
                "value": 8529,
                "label": "Granite City",
                "label_ar": "جرانيت سيتي",
                "label_fr": "Granite City"
            },
            {
                "value": 8530,
                "label": "Granite Falls",
                "label_ar": "شلالات الجرانيت",
                "label_fr": "Granite Falls"
            },
            {
                "value": 8531,
                "label": "Granite Quarry",
                "label_ar": "مقلع الجرانيت",
                "label_fr": "Carrière de granit"
            },
            {
                "value": 8532,
                "label": "Graniteville",
                "label_ar": "جرانيتفيل",
                "label_fr": "Graniteville"
            },
            {
                "value": 8533,
                "label": "Grant",
                "label_ar": "منحة",
                "label_fr": "Subvention"
            },
            {
                "value": 8534,
                "label": "Grant City",
                "label_ar": "جرانت سيتي",
                "label_fr": "Grant City"
            },
            {
                "value": 8535,
                "label": "Grant Park",
                "label_ar": "جرانت بارك",
                "label_fr": "Grant Park"
            },
            {
                "value": 8536,
                "label": "Grantham",
                "label_ar": "جرانثام",
                "label_fr": "Grantham"
            },
            {
                "value": 8537,
                "label": "Granton",
                "label_ar": "جرانتون",
                "label_fr": "Granton"
            },
            {
                "value": 8538,
                "label": "Grants",
                "label_ar": "منح",
                "label_fr": "Subventions"
            },
            {
                "value": 8539,
                "label": "Grants Pass",
                "label_ar": "Grants Pass",
                "label_fr": "Grants Pass"
            },
            {
                "value": 8540,
                "label": "Grantsboro",
                "label_ar": "جرانتسبورو",
                "label_fr": "Grantsboro"
            },
            {
                "value": 8541,
                "label": "Grantsburg",
                "label_ar": "جرانتسبيرغ",
                "label_fr": "Grantsburg"
            },
            {
                "value": 8542,
                "label": "Grantsville",
                "label_ar": "جرانتسفيل",
                "label_fr": "Grantsville"
            },
            {
                "value": 8543,
                "label": "Grantville",
                "label_ar": "جرانتفيل",
                "label_fr": "Grantville"
            },
            {
                "value": 8544,
                "label": "Granville",
                "label_ar": "جرانفيل",
                "label_fr": "Granville"
            },
            {
                "value": 8545,
                "label": "Granville Summit",
                "label_ar": "قمة جرانفيل",
                "label_fr": "Sommet de Granville"
            },
            {
                "value": 8546,
                "label": "Grapeland",
                "label_ar": "جرابلاند",
                "label_fr": "Grapeland"
            },
            {
                "value": 8547,
                "label": "Grapeview",
                "label_ar": "جرابفيو",
                "label_fr": "Grapeview"
            },
            {
                "value": 8548,
                "label": "Grapevine",
                "label_ar": "كرمة",
                "label_fr": "La vigne"
            },
            {
                "value": 8549,
                "label": "Grasonville",
                "label_ar": "جراسونفيل",
                "label_fr": "Grasonville"
            },
            {
                "value": 8550,
                "label": "Grass Lake",
                "label_ar": "بحيرة العشب",
                "label_fr": "Lac Grass"
            },
            {
                "value": 8551,
                "label": "Grass Valley",
                "label_ar": "وادي العشب",
                "label_fr": "Grass Valley"
            },
            {
                "value": 8552,
                "label": "Grassflat",
                "label_ar": "العشب",
                "label_fr": "Grassflat"
            },
            {
                "value": 8553,
                "label": "Grassy Creek",
                "label_ar": "غراسي كريك",
                "label_fr": "Grassy Creek"
            },
            {
                "value": 8554,
                "label": "Grassy Meadows",
                "label_ar": "غراسي ميدوز",
                "label_fr": "Prairies herbeuses"
            },
            {
                "value": 8555,
                "label": "Gratiot",
                "label_ar": "جراتيوت",
                "label_fr": "Gratiot"
            },
            {
                "value": 8556,
                "label": "Gratis",
                "label_ar": "مجانا",
                "label_fr": "Gratuitement"
            },
            {
                "value": 8557,
                "label": "Graton",
                "label_ar": "غراتون",
                "label_fr": "Graton"
            },
            {
                "value": 8558,
                "label": "Gratz",
                "label_ar": "جراتز",
                "label_fr": "Gratz"
            },
            {
                "value": 8559,
                "label": "Gravel Switch",
                "label_ar": "تبديل الحصى",
                "label_fr": "Commutateur de gravier"
            },
            {
                "value": 8560,
                "label": "Gravelly",
                "label_ar": "أجش",
                "label_fr": "Graveleux"
            },
            {
                "value": 8561,
                "label": "Gravette",
                "label_ar": "جرافيت",
                "label_fr": "Gravette"
            },
            {
                "value": 8562,
                "label": "Gravity",
                "label_ar": "الجاذبية",
                "label_fr": "La gravité"
            },
            {
                "value": 8563,
                "label": "Gravois Mills",
                "label_ar": "جرافيس ميلز",
                "label_fr": "Gravois Mills"
            },
            {
                "value": 8564,
                "label": "Grawn",
                "label_ar": "العشب",
                "label_fr": "Grawn"
            },
            {
                "value": 8565,
                "label": "Gray",
                "label_ar": "اللون الرمادي",
                "label_fr": "gris"
            },
            {
                "value": 8566,
                "label": "Gray Court",
                "label_ar": "غراي كورت",
                "label_fr": "Grey Court"
            },
            {
                "value": 8567,
                "label": "Gray Summit",
                "label_ar": "القمة الرمادية",
                "label_fr": "Sommet gris"
            },
            {
                "value": 8568,
                "label": "Grayland",
                "label_ar": "جرايلاند",
                "label_fr": "Grayland"
            },
            {
                "value": 8569,
                "label": "Grayling",
                "label_ar": "غرايلينج",
                "label_fr": "Ombre"
            },
            {
                "value": 8570,
                "label": "Grayslake",
                "label_ar": "جرايسليك",
                "label_fr": "Grayslake"
            },
            {
                "value": 8571,
                "label": "Grayson",
                "label_ar": "جرايسون",
                "label_fr": "Grayson"
            },
            {
                "value": 8572,
                "label": "Graysville",
                "label_ar": "جرايسفيل",
                "label_fr": "Graysville"
            },
            {
                "value": 8573,
                "label": "Graytown",
                "label_ar": "جرايتاون",
                "label_fr": "Graytown"
            },
            {
                "value": 8574,
                "label": "Grayville",
                "label_ar": "جرايفيل",
                "label_fr": "Grayville"
            },
            {
                "value": 8575,
                "label": "Great Barrington",
                "label_ar": "جريت بارينجتون",
                "label_fr": "Great Barrington"
            },
            {
                "value": 8576,
                "label": "Great Bend",
                "label_ar": "بيند كبير",
                "label_fr": "Grand virage"
            },
            {
                "value": 8577,
                "label": "Great Cacapon",
                "label_ar": "الكاكابون العظيم",
                "label_fr": "Grand Cacapon"
            },
            {
                "value": 8578,
                "label": "Great Falls",
                "label_ar": "غريت فولز",
                "label_fr": "Great Falls"
            },
            {
                "value": 8579,
                "label": "Great Lakes",
                "label_ar": "بحيرات عظيمة",
                "label_fr": "Grands Lacs"
            },
            {
                "value": 8580,
                "label": "Great Meadows",
                "label_ar": "المروج الكبرى",
                "label_fr": "Great Meadows"
            },
            {
                "value": 8581,
                "label": "Great Mills",
                "label_ar": "جريت ميلز",
                "label_fr": "Grands moulins"
            },
            {
                "value": 8582,
                "label": "Great Neck",
                "label_ar": "العنق الكبير",
                "label_fr": "Grand cou"
            },
            {
                "value": 8583,
                "label": "Great River",
                "label_ar": "النهر الكبير",
                "label_fr": "Grande rivière"
            },
            {
                "value": 8584,
                "label": "Greater Capitol Heights",
                "label_ar": "مرتفعات الكابيتول الكبرى",
                "label_fr": "Grandes capitales"
            },
            {
                "value": 8585,
                "label": "Greeley",
                "label_ar": "غريلي",
                "label_fr": "Greeley"
            },
            {
                "value": 8586,
                "label": "Greeleyville",
                "label_ar": "جريليفيل",
                "label_fr": "Greeleyville"
            },
            {
                "value": 8587,
                "label": "Green",
                "label_ar": "أخضر",
                "label_fr": "vert"
            },
            {
                "value": 8588,
                "label": "Green Bank",
                "label_ar": "جرين بانك",
                "label_fr": "Banque verte"
            },
            {
                "value": 8589,
                "label": "Green Bay",
                "label_ar": "جرين باي",
                "label_fr": "Green Bay"
            },
            {
                "value": 8590,
                "label": "Green Castle",
                "label_ar": "القلعة الخضراء",
                "label_fr": "Château vert"
            },
            {
                "value": 8591,
                "label": "Green City",
                "label_ar": "مدينة خضراء",
                "label_fr": "Ville verte"
            },
            {
                "value": 8592,
                "label": "Green Cove Springs",
                "label_ar": "جرين كوف سبرينجز",
                "label_fr": "Green Cove Springs"
            },
            {
                "value": 8593,
                "label": "Green Forest",
                "label_ar": "غابة خضراء",
                "label_fr": "Forêt verte"
            },
            {
                "value": 8594,
                "label": "Green Isle",
                "label_ar": "جرين آيل",
                "label_fr": "Île verte"
            },
            {
                "value": 8595,
                "label": "Green Lake",
                "label_ar": "البحيرة الخضراء",
                "label_fr": "Lac vert"
            },
            {
                "value": 8596,
                "label": "Green Lane",
                "label_ar": "جرين لين",
                "label_fr": "Voie verte"
            },
            {
                "value": 8597,
                "label": "Green Mountain",
                "label_ar": "جبل أخضر",
                "label_fr": "Montagne Verte"
            },
            {
                "value": 8598,
                "label": "Green Mountain Falls",
                "label_ar": "شلالات الجبل الأخضر",
                "label_fr": "Green Mountain Falls"
            },
            {
                "value": 8599,
                "label": "Green Pond",
                "label_ar": "البركة الخضراء",
                "label_fr": "Étang vert"
            },
            {
                "value": 8600,
                "label": "Green River",
                "label_ar": "نهر أخضر",
                "label_fr": "Rivière verte"
            },
            {
                "value": 8601,
                "label": "Green Sea",
                "label_ar": "البحر الاخضر",
                "label_fr": "Mer verte"
            },
            {
                "value": 8602,
                "label": "Green Springs",
                "label_ar": "جرين سبرينغز",
                "label_fr": "Green Springs"
            },
            {
                "value": 8603,
                "label": "Green Valley",
                "label_ar": "الوادي الأخضر",
                "label_fr": "Vallée verte"
            },
            {
                "value": 8604,
                "label": "Green Village",
                "label_ar": "القرية الخضراء",
                "label_fr": "Village vert"
            },
            {
                "value": 8605,
                "label": "Greenacres",
                "label_ar": "جريناكريس",
                "label_fr": "Greenacres"
            },
            {
                "value": 8606,
                "label": "Greenback",
                "label_ar": "جرينباك",
                "label_fr": "Dollar"
            },
            {
                "value": 8607,
                "label": "Greenbackville",
                "label_ar": "جرينباكفيل",
                "label_fr": "Greenbackville"
            },
            {
                "value": 8608,
                "label": "Greenbank",
                "label_ar": "جرينبانك",
                "label_fr": "Greenbank"
            },
            {
                "value": 8609,
                "label": "Greenbelt",
                "label_ar": "حزام أخضر",
                "label_fr": "ceinture verte"
            },
            {
                "value": 8610,
                "label": "Greenbrae",
                "label_ar": "جرينبراي",
                "label_fr": "Greenbrae"
            },
            {
                "value": 8611,
                "label": "Greenbrier",
                "label_ar": "جرينبرير",
                "label_fr": "Greenbrier"
            },
            {
                "value": 8612,
                "label": "Greenbush",
                "label_ar": "جرينبوش",
                "label_fr": "Greenbush"
            },
            {
                "value": 8613,
                "label": "Greencastle",
                "label_ar": "جرينكاسل",
                "label_fr": "Greencastle"
            },
            {
                "value": 8614,
                "label": "Greendale",
                "label_ar": "جريندال",
                "label_fr": "Greendale"
            },
            {
                "value": 8615,
                "label": "Greene",
                "label_ar": "غرين",
                "label_fr": "Greene"
            },
            {
                "value": 8616,
                "label": "Greeneville",
                "label_ar": "جرينفيل",
                "label_fr": "Greeneville"
            },
            {
                "value": 8617,
                "label": "Greenfield",
                "label_ar": "جرينفيلد",
                "label_fr": "Greenfield"
            },
            {
                "value": 8618,
                "label": "Greenland",
                "label_ar": "الأرض الخضراء",
                "label_fr": "Groenland"
            },
            {
                "value": 8619,
                "label": "Greenlawn",
                "label_ar": "عشب أخضر",
                "label_fr": "Greenlawn"
            },
            {
                "value": 8620,
                "label": "Greenleaf",
                "label_ar": "ورقة خضراء",
                "label_fr": "Feuille verte"
            },
            {
                "value": 8621,
                "label": "Greenock",
                "label_ar": "غرينوك",
                "label_fr": "Greenock"
            },
            {
                "value": 8622,
                "label": "Greenport",
                "label_ar": "جرينبورت",
                "label_fr": "Greenport"
            },
            {
                "value": 8623,
                "label": "Greens Farms",
                "label_ar": "مزارع جرينز",
                "label_fr": "Fermes vertes"
            },
            {
                "value": 8624,
                "label": "Greensboro",
                "label_ar": "جرينسبورو",
                "label_fr": "Greensboro"
            },
            {
                "value": 8625,
                "label": "Greensburg",
                "label_ar": "جرينسبيرغ",
                "label_fr": "Greensburg"
            },
            {
                "value": 8626,
                "label": "Greentop",
                "label_ar": "جرينتوب",
                "label_fr": "Greentop"
            },
            {
                "value": 8627,
                "label": "Greentown",
                "label_ar": "جرين تاون",
                "label_fr": "Greentown"
            },
            {
                "value": 8628,
                "label": "Greenup",
                "label_ar": "جرينوب",
                "label_fr": "Greenup"
            },
            {
                "value": 8629,
                "label": "Greenvale",
                "label_ar": "جرينفيل",
                "label_fr": "Greenvale"
            },
            {
                "value": 8630,
                "label": "Greenview",
                "label_ar": "جرينفيو",
                "label_fr": "Greenview"
            },
            {
                "value": 8631,
                "label": "Greenville",
                "label_ar": "جرينفيل",
                "label_fr": "Greenville"
            },
            {
                "value": 8632,
                "label": "Greenway Downs",
                "label_ar": "جرينواي داونز",
                "label_fr": "Greenway Downs"
            },
            {
                "value": 8633,
                "label": "Greenwell Springs",
                "label_ar": "جرينويل سبرينجز",
                "label_fr": "Greenwell Springs"
            },
            {
                "value": 8634,
                "label": "Greenwich",
                "label_ar": "غرينتش",
                "label_fr": "Greenwich"
            },
            {
                "value": 8635,
                "label": "Greenwood",
                "label_ar": "غرينوود",
                "label_fr": "Greenwood"
            },
            {
                "value": 8636,
                "label": "Greenwood Lake",
                "label_ar": "بحيرة غرينوود",
                "label_fr": "Lac Greenwood"
            },
            {
                "value": 8637,
                "label": "Greenwood Springs",
                "label_ar": "غرينوود سبرينغز",
                "label_fr": "Greenwood Springs"
            },
            {
                "value": 8638,
                "label": "Greenwood Village",
                "label_ar": "قرية غرينوود",
                "label_fr": "Village de Greenwood"
            },
            {
                "value": 8639,
                "label": "Greer",
                "label_ar": "جرير",
                "label_fr": "Greer"
            },
            {
                "value": 8640,
                "label": "Gregory",
                "label_ar": "جريجوري",
                "label_fr": "Grégory"
            },
            {
                "value": 8641,
                "label": "Grelton",
                "label_ar": "جريلتون",
                "label_fr": "Grelton"
            },
            {
                "value": 8642,
                "label": "Grenada",
                "label_ar": "غرينادا",
                "label_fr": "Grenade"
            },
            {
                "value": 8643,
                "label": "Grenloch",
                "label_ar": "جرينلوخ",
                "label_fr": "Grenloch"
            },
            {
                "value": 8644,
                "label": "Grenville",
                "label_ar": "جرينفيل",
                "label_fr": "Grenville"
            },
            {
                "value": 8645,
                "label": "Gresham",
                "label_ar": "جريشام",
                "label_fr": "Gresham"
            },
            {
                "value": 8646,
                "label": "Grethel",
                "label_ar": "جريتيل",
                "label_fr": "Grethel"
            },
            {
                "value": 8647,
                "label": "Gretna",
                "label_ar": "جريتنا",
                "label_fr": "Gretna"
            },
            {
                "value": 8648,
                "label": "Grey Eagle",
                "label_ar": "النسر الرمادي",
                "label_fr": "Aigle gris"
            },
            {
                "value": 8649,
                "label": "Greybull",
                "label_ar": "جريبول",
                "label_fr": "Greybull"
            },
            {
                "value": 8650,
                "label": "Greycliff",
                "label_ar": "جرايكليف",
                "label_fr": "Greycliff"
            },
            {
                "value": 8651,
                "label": "Gridley",
                "label_ar": "جريدلي",
                "label_fr": "Gridley"
            },
            {
                "value": 8652,
                "label": "Griffin",
                "label_ar": "جريفين",
                "label_fr": "Griffon"
            },
            {
                "value": 8653,
                "label": "Griffith",
                "label_ar": "جريفيث",
                "label_fr": "Griffith"
            },
            {
                "value": 8654,
                "label": "Griffithsville",
                "label_ar": "جريفيثسفيل",
                "label_fr": "Griffithsville"
            },
            {
                "value": 8655,
                "label": "Griffithville",
                "label_ar": "جريفيثفيل",
                "label_fr": "Griffithville"
            },
            {
                "value": 8656,
                "label": "Grifton",
                "label_ar": "جريفتون",
                "label_fr": "Grifton"
            },
            {
                "value": 8657,
                "label": "Griggsville",
                "label_ar": "جريجسفيل",
                "label_fr": "Griggsville"
            },
            {
                "value": 8658,
                "label": "Grimes",
                "label_ar": "غرايمز",
                "label_fr": "Grimes"
            },
            {
                "value": 8659,
                "label": "Grimesland",
                "label_ar": "غريمسلاند",
                "label_fr": "Grimesland"
            },
            {
                "value": 8660,
                "label": "Grimsley",
                "label_ar": "جريمسلي",
                "label_fr": "Grimsley"
            },
            {
                "value": 8661,
                "label": "Grindstone",
                "label_ar": "مجلخة",
                "label_fr": "Meule"
            },
            {
                "value": 8662,
                "label": "Grinnell",
                "label_ar": "غرينيل",
                "label_fr": "Grinnell"
            },
            {
                "value": 8663,
                "label": "Griswold",
                "label_ar": "جريسوولد",
                "label_fr": "Griswold"
            },
            {
                "value": 8664,
                "label": "Groesbeck",
                "label_ar": "جروسبيك",
                "label_fr": "Groesbeck"
            },
            {
                "value": 8665,
                "label": "Groom",
                "label_ar": "العريس",
                "label_fr": "Jeune marié"
            },
            {
                "value": 8666,
                "label": "Grosse Ile",
                "label_ar": "جروس إيل",
                "label_fr": "Grosse Ile"
            },
            {
                "value": 8667,
                "label": "Grosse Pointe",
                "label_ar": "جروس بوينت",
                "label_fr": "Grosse Pointe"
            },
            {
                "value": 8668,
                "label": "Grosse Tete",
                "label_ar": "جروس تيتي",
                "label_fr": "Grosse tête"
            },
            {
                "value": 8669,
                "label": "Grosvenor Dale",
                "label_ar": "جروسفينور ديل",
                "label_fr": "Grosvenor Dale"
            },
            {
                "value": 8670,
                "label": "Groton",
                "label_ar": "جروتون",
                "label_fr": "Groton"
            },
            {
                "value": 8671,
                "label": "Grottoes",
                "label_ar": "الكهوف",
                "label_fr": "Grottes"
            },
            {
                "value": 8672,
                "label": "Grouse Creek",
                "label_ar": "غروس كريك",
                "label_fr": "Grouse Creek"
            },
            {
                "value": 8673,
                "label": "Grove",
                "label_ar": "بستان",
                "label_fr": "Bosquet"
            },
            {
                "value": 8674,
                "label": "Grove City",
                "label_ar": "جروف سيتي",
                "label_fr": "Grove City"
            },
            {
                "value": 8675,
                "label": "Grove Hill",
                "label_ar": "جروف هيل",
                "label_fr": "Grove Hill"
            },
            {
                "value": 8676,
                "label": "Grove Oak",
                "label_ar": "جروف أوك",
                "label_fr": "Chêne Grove"
            },
            {
                "value": 8677,
                "label": "Groveland",
                "label_ar": "غروفلاند",
                "label_fr": "Groveland"
            },
            {
                "value": 8678,
                "label": "Groveport",
                "label_ar": "غروفبورت",
                "label_fr": "Groveport"
            },
            {
                "value": 8679,
                "label": "Grover",
                "label_ar": "جروفر",
                "label_fr": "Grover"
            },
            {
                "value": 8680,
                "label": "Grover Beach",
                "label_ar": "شاطئ جروفر",
                "label_fr": "Plage de Grover"
            },
            {
                "value": 8681,
                "label": "Grover Hill",
                "label_ar": "غروفر هيل",
                "label_fr": "Grover Hill"
            },
            {
                "value": 8682,
                "label": "Grovertown",
                "label_ar": "جروفرتاون",
                "label_fr": "Grovertown"
            },
            {
                "value": 8683,
                "label": "Groves",
                "label_ar": "بساتين",
                "label_fr": "Bosquets"
            },
            {
                "value": 8684,
                "label": "Groveton",
                "label_ar": "جروفتون",
                "label_fr": "Groveton"
            },
            {
                "value": 8685,
                "label": "Grovetown",
                "label_ar": "جروفتاون",
                "label_fr": "Grovetown"
            },
            {
                "value": 8686,
                "label": "Gruetli-Laager",
                "label_ar": "جروتلي لاجر",
                "label_fr": "Gruetli-Laager"
            },
            {
                "value": 8687,
                "label": "Grundy",
                "label_ar": "جراندي",
                "label_fr": "Grundy"
            },
            {
                "value": 8688,
                "label": "Grundy Center",
                "label_ar": "مركز جراندي",
                "label_fr": "Centre Grundy"
            },
            {
                "value": 8689,
                "label": "Gruver",
                "label_ar": "جروفر",
                "label_fr": "Gruver"
            },
            {
                "value": 8690,
                "label": "Grygla",
                "label_ar": "جريجلا",
                "label_fr": "Grygla"
            },
            {
                "value": 8691,
                "label": "Guadalupe",
                "label_ar": "غوادالوبي",
                "label_fr": "Guadalupe"
            },
            {
                "value": 8692,
                "label": "Gualala",
                "label_ar": "جوالالا",
                "label_fr": "Gualala"
            },
            {
                "value": 8693,
                "label": "Guatay",
                "label_ar": "جواتاي",
                "label_fr": "Guatay"
            },
            {
                "value": 8694,
                "label": "Guerneville",
                "label_ar": "غيرنفيل",
                "label_fr": "Guerneville"
            },
            {
                "value": 8695,
                "label": "Guernsey",
                "label_ar": "غيرنسي",
                "label_fr": "Guernesey"
            },
            {
                "value": 8696,
                "label": "Gueydan",
                "label_ar": "جويدان",
                "label_fr": "Gueydan"
            },
            {
                "value": 8697,
                "label": "Guffey",
                "label_ar": "جوفي",
                "label_fr": "Guffey"
            },
            {
                "value": 8698,
                "label": "Guide Rock",
                "label_ar": "دليل روك",
                "label_fr": "Guide Rock"
            },
            {
                "value": 8699,
                "label": "Guilderland",
                "label_ar": "جيلدرلاند",
                "label_fr": "Guilderland"
            },
            {
                "value": 8700,
                "label": "Guilford",
                "label_ar": "جيلفورد",
                "label_fr": "Guilford"
            },
            {
                "value": 8701,
                "label": "Guin",
                "label_ar": "جين",
                "label_fr": "Guin"
            },
            {
                "value": 8702,
                "label": "Guinda",
                "label_ar": "جيندا",
                "label_fr": "Guinda"
            },
            {
                "value": 8703,
                "label": "Gulf Breeze",
                "label_ar": "نسيم الخليج",
                "label_fr": "Gulf Breeze"
            },
            {
                "value": 8704,
                "label": "Gulf Shores",
                "label_ar": "شواطئ الخليج",
                "label_fr": "Gulf Shores"
            },
            {
                "value": 8705,
                "label": "Gulfport",
                "label_ar": "جولفبورت",
                "label_fr": "Gulfport"
            },
            {
                "value": 8706,
                "label": "Gully",
                "label_ar": "أخاديد",
                "label_fr": "Ravine"
            },
            {
                "value": 8707,
                "label": "Gum Spring",
                "label_ar": "ربيع اللثة",
                "label_fr": "Gum Spring"
            },
            {
                "value": 8708,
                "label": "Gunlock",
                "label_ar": "زند البندقية",
                "label_fr": "Percuteur"
            },
            {
                "value": 8709,
                "label": "Gunnison",
                "label_ar": "Gunnison",
                "label_fr": "Gunnison"
            },
            {
                "value": 8710,
                "label": "Gunpowder",
                "label_ar": "البارود",
                "label_fr": "Poudre à canon"
            },
            {
                "value": 8711,
                "label": "Gunter",
                "label_ar": "غونتر",
                "label_fr": "Gunter"
            },
            {
                "value": 8712,
                "label": "Guntersville",
                "label_ar": "Guntersville",
                "label_fr": "Guntersville"
            },
            {
                "value": 8713,
                "label": "Guntown",
                "label_ar": "غونتاون",
                "label_fr": "Robe"
            },
            {
                "value": 8714,
                "label": "Gurley",
                "label_ar": "جورلي",
                "label_fr": "Gurley"
            },
            {
                "value": 8715,
                "label": "Gurnee",
                "label_ar": "جورني",
                "label_fr": "Gurnee"
            },
            {
                "value": 8716,
                "label": "Gustine",
                "label_ar": "جوستين",
                "label_fr": "Gustine"
            },
            {
                "value": 8717,
                "label": "Guston",
                "label_ar": "جوستون",
                "label_fr": "Guston"
            },
            {
                "value": 8718,
                "label": "Guthrie",
                "label_ar": "جوثري",
                "label_fr": "Guthrie"
            },
            {
                "value": 8719,
                "label": "Guthrie Center",
                "label_ar": "مركز جوثري",
                "label_fr": "Centre Guthrie"
            },
            {
                "value": 8720,
                "label": "Guttenberg",
                "label_ar": "جوتنبرج",
                "label_fr": "Guttenberg"
            },
            {
                "value": 8721,
                "label": "Guy",
                "label_ar": "شاب",
                "label_fr": "Gars"
            },
            {
                "value": 8722,
                "label": "Guymon",
                "label_ar": "Guymon",
                "label_fr": "Guymon"
            },
            {
                "value": 8723,
                "label": "Guys",
                "label_ar": "رفاق",
                "label_fr": "Les gars"
            },
            {
                "value": 8724,
                "label": "Guys Mills",
                "label_ar": "جايز ميلز",
                "label_fr": "Guys Mills"
            },
            {
                "value": 8725,
                "label": "Guysville",
                "label_ar": "Guysville",
                "label_fr": "Guysville"
            },
            {
                "value": 8726,
                "label": "Guyton",
                "label_ar": "غايتون",
                "label_fr": "Guyton"
            },
            {
                "value": 8727,
                "label": "Gwinn",
                "label_ar": "جوين",
                "label_fr": "Gwinn"
            },
            {
                "value": 8728,
                "label": "Gwinner",
                "label_ar": "جوينر",
                "label_fr": "Gwinner"
            },
            {
                "value": 8729,
                "label": "Gwynedd",
                "label_ar": "جوينيد",
                "label_fr": "Gwynedd"
            },
            {
                "value": 8730,
                "label": "Gwynedd Valley",
                "label_ar": "وادي جوينيد",
                "label_fr": "Vallée de Gwynedd"
            },
            {
                "value": 8731,
                "label": "Gwynn Oak",
                "label_ar": "جوين أوك",
                "label_fr": "Chêne Gwynn"
            },
            {
                "value": 8732,
                "label": "Gypsum",
                "label_ar": "جبس",
                "label_fr": "Gypse"
            },
            {
                "value": 8733,
                "label": "Gypsy",
                "label_ar": "الغجر",
                "label_fr": "gitan"
            },
            {
                "value": 8734,
                "label": "Ha'iku",
                "label_ar": "هايكو",
                "label_fr": "Ha'iku"
            },
            {
                "value": 8735,
                "label": "Hacienda Heights",
                "label_ar": "هاسيندا هايتس",
                "label_fr": "Hacienda Heights"
            },
            {
                "value": 8736,
                "label": "Hackberry",
                "label_ar": "الثمر الميس",
                "label_fr": "Micocoulier"
            },
            {
                "value": 8737,
                "label": "Hackensack",
                "label_ar": "هاكنساك",
                "label_fr": "Hackensack"
            },
            {
                "value": 8738,
                "label": "Hackett",
                "label_ar": "هاكيت",
                "label_fr": "Hackett"
            },
            {
                "value": 8739,
                "label": "Hackettstown",
                "label_ar": "هاكيتستاون",
                "label_fr": "Hackettstown"
            },
            {
                "value": 8740,
                "label": "Haddam",
                "label_ar": "هدام",
                "label_fr": "Haddam"
            },
            {
                "value": 8741,
                "label": "Haddock",
                "label_ar": "الحدوق",
                "label_fr": "Églefin"
            },
            {
                "value": 8742,
                "label": "Haddon Heights",
                "label_ar": "مرتفعات هادون",
                "label_fr": "Haddon Heights"
            },
            {
                "value": 8743,
                "label": "Haddonfield",
                "label_ar": "هادونفيلد",
                "label_fr": "Haddonfield"
            },
            {
                "value": 8744,
                "label": "Hadley",
                "label_ar": "هادلي",
                "label_fr": "Hadley"
            },
            {
                "value": 8745,
                "label": "Hadlyme",
                "label_ar": "هادليم",
                "label_fr": "Hadlyme"
            },
            {
                "value": 8746,
                "label": "Hagan",
                "label_ar": "هاجان",
                "label_fr": "Hagan"
            },
            {
                "value": 8747,
                "label": "Hager City",
                "label_ar": "هاجر سيتي",
                "label_fr": "Hager City"
            },
            {
                "value": 8748,
                "label": "Hagerhill",
                "label_ar": "هاجرهيل",
                "label_fr": "Hagerhill"
            },
            {
                "value": 8749,
                "label": "Hagerman",
                "label_ar": "هاجرمان",
                "label_fr": "Hagerman"
            },
            {
                "value": 8750,
                "label": "Hagerstown",
                "label_ar": "هاجرستاون",
                "label_fr": "Hagerstown"
            },
            {
                "value": 8751,
                "label": "Hahira",
                "label_ar": "هريرة",
                "label_fr": "Hahira"
            },
            {
                "value": 8752,
                "label": "Hahnville",
                "label_ar": "هانفيل",
                "label_fr": "Hahnville"
            },
            {
                "value": 8753,
                "label": "Haigler",
                "label_ar": "هايجلر",
                "label_fr": "Haigler"
            },
            {
                "value": 8754,
                "label": "Hailey",
                "label_ar": "هيلي",
                "label_fr": "Hailey"
            },
            {
                "value": 8755,
                "label": "Haines",
                "label_ar": "هينز",
                "label_fr": "Haines"
            },
            {
                "value": 8756,
                "label": "Haines City",
                "label_ar": "مدينة هينز",
                "label_fr": "Haines City"
            },
            {
                "value": 8757,
                "label": "Hainesport",
                "label_ar": "هينسبورت",
                "label_fr": "Hainesport"
            },
            {
                "value": 8758,
                "label": "Hakalau",
                "label_ar": "هاكالاو",
                "label_fr": "Hakalau"
            },
            {
                "value": 8759,
                "label": "Halbur",
                "label_ar": "هالبور",
                "label_fr": "Halbur"
            },
            {
                "value": 8760,
                "label": "Hale",
                "label_ar": "هيل",
                "label_fr": "Vigoureux"
            },
            {
                "value": 8761,
                "label": "Hale'iwa",
                "label_ar": "حليوة",
                "label_fr": "Hale'iwa"
            },
            {
                "value": 8762,
                "label": "Haledon",
                "label_ar": "هالدون",
                "label_fr": "Haledon"
            },
            {
                "value": 8763,
                "label": "Hales Corners",
                "label_ar": "هالز كورنرز",
                "label_fr": "Hales Corners"
            },
            {
                "value": 8764,
                "label": "Halethorpe",
                "label_ar": "هاليثورب",
                "label_fr": "Halethorpe"
            },
            {
                "value": 8765,
                "label": "Haleyville",
                "label_ar": "هاليفيل",
                "label_fr": "Haleyville"
            },
            {
                "value": 8766,
                "label": "Half Moon Bay",
                "label_ar": "نصف القمر، جزء رئيسي من مبنى",
                "label_fr": "Half Moon Bay"
            },
            {
                "value": 8767,
                "label": "Halfway",
                "label_ar": "في منتصف الطريق",
                "label_fr": "À mi-chemin"
            },
            {
                "value": 8768,
                "label": "Halifax",
                "label_ar": "هاليفاكس",
                "label_fr": "Halifax"
            },
            {
                "value": 8769,
                "label": "Hall",
                "label_ar": "صالة",
                "label_fr": "salle"
            },
            {
                "value": 8770,
                "label": "Hallam",
                "label_ar": "هالام",
                "label_fr": "Hallam"
            },
            {
                "value": 8771,
                "label": "Hallandale",
                "label_ar": "هالانديل",
                "label_fr": "Hallandale"
            },
            {
                "value": 8772,
                "label": "Hallett",
                "label_ar": "هاليت",
                "label_fr": "Hallett"
            },
            {
                "value": 8773,
                "label": "Hallettsville",
                "label_ar": "هاليتسفيل",
                "label_fr": "Hallettsville"
            },
            {
                "value": 8774,
                "label": "Halliday",
                "label_ar": "هاليداي",
                "label_fr": "Halliday"
            },
            {
                "value": 8775,
                "label": "Hallie",
                "label_ar": "هالي",
                "label_fr": "Hallie"
            },
            {
                "value": 8776,
                "label": "Hallieford",
                "label_ar": "هاليفورد",
                "label_fr": "Hallieford"
            },
            {
                "value": 8777,
                "label": "Hallock",
                "label_ar": "هالوك",
                "label_fr": "Hallock"
            },
            {
                "value": 8778,
                "label": "Hallowell",
                "label_ar": "هالويل",
                "label_fr": "Hallowell"
            },
            {
                "value": 8779,
                "label": "Halls",
                "label_ar": "قاعات",
                "label_fr": "Salles"
            },
            {
                "value": 8780,
                "label": "Hallstead",
                "label_ar": "هالستيد",
                "label_fr": "Hallstead"
            },
            {
                "value": 8781,
                "label": "Hallsville",
                "label_ar": "هالسفيل",
                "label_fr": "Hallsville"
            },
            {
                "value": 8782,
                "label": "Halltown",
                "label_ar": "بلدة قاعة",
                "label_fr": "Halltown"
            },
            {
                "value": 8783,
                "label": "Hallwood",
                "label_ar": "هالوود",
                "label_fr": "Hallwood"
            },
            {
                "value": 8784,
                "label": "Halma",
                "label_ar": "هالما",
                "label_fr": "Halma"
            },
            {
                "value": 8785,
                "label": "Halsey",
                "label_ar": "هالسي",
                "label_fr": "Halsey"
            },
            {
                "value": 8786,
                "label": "Halstad",
                "label_ar": "هالستاد",
                "label_fr": "Halstad"
            },
            {
                "value": 8787,
                "label": "Halstead",
                "label_ar": "هالستيد",
                "label_fr": "Halstead"
            },
            {
                "value": 8788,
                "label": "Haltom City",
                "label_ar": "مدينة هالتم",
                "label_fr": "Haltom City"
            },
            {
                "value": 8789,
                "label": "Hambleton",
                "label_ar": "هامبلتون",
                "label_fr": "Hambleton"
            },
            {
                "value": 8790,
                "label": "Hamburg",
                "label_ar": "هامبورغ",
                "label_fr": "Hambourg"
            },
            {
                "value": 8791,
                "label": "Hamden",
                "label_ar": "هامدن",
                "label_fr": "Hamden"
            },
            {
                "value": 8792,
                "label": "Hamel",
                "label_ar": "هامل",
                "label_fr": "Hamel"
            },
            {
                "value": 8793,
                "label": "Hamer",
                "label_ar": "هامر",
                "label_fr": "Hamer"
            },
            {
                "value": 8794,
                "label": "Hamersville",
                "label_ar": "هامرسفيل",
                "label_fr": "Hamersville"
            },
            {
                "value": 8795,
                "label": "Hamilton",
                "label_ar": "هاملتون",
                "label_fr": "Hamilton"
            },
            {
                "value": 8796,
                "label": "Hamler",
                "label_ar": "هاملر",
                "label_fr": "Hamler"
            },
            {
                "value": 8797,
                "label": "Hamlet",
                "label_ar": "قرية",
                "label_fr": "Hamlet"
            },
            {
                "value": 8798,
                "label": "Hamlin",
                "label_ar": "هاملين",
                "label_fr": "Hamlin"
            },
            {
                "value": 8799,
                "label": "Hammon",
                "label_ar": "هامون",
                "label_fr": "Hammon"
            },
            {
                "value": 8800,
                "label": "Hammond",
                "label_ar": "هاموند",
                "label_fr": "Hammond"
            },
            {
                "value": 8801,
                "label": "Hammondsport",
                "label_ar": "هاموندسبورت",
                "label_fr": "Hammondsport"
            },
            {
                "value": 8802,
                "label": "Hammonton",
                "label_ar": "هامونتون",
                "label_fr": "Hammonton"
            },
            {
                "value": 8803,
                "label": "Hampden",
                "label_ar": "هامبدن",
                "label_fr": "Hampden"
            },
            {
                "value": 8804,
                "label": "Hampden Sydney",
                "label_ar": "هامبدن سيدني",
                "label_fr": "Hampden Sydney"
            },
            {
                "value": 8805,
                "label": "Hampshire",
                "label_ar": "هامبشاير",
                "label_fr": "Hampshire"
            },
            {
                "value": 8806,
                "label": "Hampstead",
                "label_ar": "هامبستيد",
                "label_fr": "Hampstead"
            },
            {
                "value": 8807,
                "label": "Hampton",
                "label_ar": "هامبتون",
                "label_fr": "Hampton"
            },
            {
                "value": 8808,
                "label": "Hampton Bays",
                "label_ar": "هامبتون بايز",
                "label_fr": "Hampton Bays"
            },
            {
                "value": 8809,
                "label": "Hampton Falls",
                "label_ar": "هامبتون فولز",
                "label_fr": "Hampton Falls"
            },
            {
                "value": 8810,
                "label": "Hamptonville",
                "label_ar": "هامبتونفيل",
                "label_fr": "Hamptonville"
            },
            {
                "value": 8811,
                "label": "Hamtramck",
                "label_ar": "هامترامك",
                "label_fr": "Hamtramck"
            },
            {
                "value": 8812,
                "label": "Hana",
                "label_ar": "هنا",
                "label_fr": "Hana"
            },
            {
                "value": 8813,
                "label": "Hanalei",
                "label_ar": "هانالي",
                "label_fr": "Hanalei"
            },
            {
                "value": 8814,
                "label": "Hanapepe",
                "label_ar": "هانابيبي",
                "label_fr": "Hanapepe"
            },
            {
                "value": 8815,
                "label": "Hanceville",
                "label_ar": "هانسفيل",
                "label_fr": "Hanceville"
            },
            {
                "value": 8816,
                "label": "Hancock",
                "label_ar": "هانكوك",
                "label_fr": "Hancock"
            },
            {
                "value": 8817,
                "label": "Hancocks Bridge",
                "label_ar": "جسر هانكوكس",
                "label_fr": "Pont Hancocks"
            },
            {
                "value": 8818,
                "label": "Hanford",
                "label_ar": "هانفورد",
                "label_fr": "Hanford"
            },
            {
                "value": 8819,
                "label": "Hankamer",
                "label_ar": "هانكامر",
                "label_fr": "Hankamer"
            },
            {
                "value": 8820,
                "label": "Hankinson",
                "label_ar": "هانكينسون",
                "label_fr": "Hankinson"
            },
            {
                "value": 8821,
                "label": "Hanksville",
                "label_ar": "هانكسفيل",
                "label_fr": "Hanksville"
            },
            {
                "value": 8822,
                "label": "Hanley Falls",
                "label_ar": "شلالات هانلي",
                "label_fr": "Hanley Falls"
            },
            {
                "value": 8823,
                "label": "Hanlontown",
                "label_ar": "هانلونتاون",
                "label_fr": "Hanlontown"
            },
            {
                "value": 8824,
                "label": "Hanna",
                "label_ar": "حنا",
                "label_fr": "Hanna"
            },
            {
                "value": 8825,
                "label": "Hanna City",
                "label_ar": "مدينة حنا",
                "label_fr": "Hanna City"
            },
            {
                "value": 8826,
                "label": "Hannacroix",
                "label_ar": "حناكروا",
                "label_fr": "Hannacroix"
            },
            {
                "value": 8827,
                "label": "Hannah",
                "label_ar": "هانا",
                "label_fr": "Hannah"
            },
            {
                "value": 8828,
                "label": "Hannibal",
                "label_ar": "حنبعل",
                "label_fr": "Hannibal"
            },
            {
                "value": 8829,
                "label": "Hanover",
                "label_ar": "هانوفر",
                "label_fr": "Hanovre"
            },
            {
                "value": 8830,
                "label": "Hanover Park",
                "label_ar": "هانوفر بارك",
                "label_fr": "Parc de Hanovre"
            },
            {
                "value": 8831,
                "label": "Hanoverton",
                "label_ar": "هانوفرتون",
                "label_fr": "Hanoverton"
            },
            {
                "value": 8832,
                "label": "Hanska",
                "label_ar": "هانسكا",
                "label_fr": "Hanska"
            },
            {
                "value": 8833,
                "label": "Hanson",
                "label_ar": "هانسون",
                "label_fr": "Hanson"
            },
            {
                "value": 8834,
                "label": "Hansville",
                "label_ar": "هانسفيل",
                "label_fr": "Hansville"
            },
            {
                "value": 8835,
                "label": "Happy",
                "label_ar": "سعيدة",
                "label_fr": "Heureux"
            },
            {
                "value": 8836,
                "label": "Happy Camp",
                "label_ar": "مخيم سعيد",
                "label_fr": "Camp heureux"
            },
            {
                "value": 8837,
                "label": "Happy Jack",
                "label_ar": "جاك سعيد",
                "label_fr": "Heureux Jack"
            },
            {
                "value": 8838,
                "label": "Happy Valley",
                "label_ar": "الوادي السعيد",
                "label_fr": "Happy Valley"
            },
            {
                "value": 8839,
                "label": "Harbeson",
                "label_ar": "هاربيسون",
                "label_fr": "Harbeson"
            },
            {
                "value": 8840,
                "label": "Harbor Beach",
                "label_ar": "هاربور بيتش",
                "label_fr": "Plage du port"
            },
            {
                "value": 8841,
                "label": "Harbor City",
                "label_ar": "هاربور سيتي",
                "label_fr": "Ville portuaire"
            },
            {
                "value": 8842,
                "label": "Harbor Springs",
                "label_ar": "هاربور سبرينغز",
                "label_fr": "Harbor Springs"
            },
            {
                "value": 8843,
                "label": "Harborcreek",
                "label_ar": "هاربوركريك",
                "label_fr": "Harbourcreek"
            },
            {
                "value": 8844,
                "label": "Harborside",
                "label_ar": "هاربورسايد",
                "label_fr": "Harbourside"
            },
            {
                "value": 8845,
                "label": "Harborton",
                "label_ar": "هاربورتون",
                "label_fr": "Harborton"
            },
            {
                "value": 8846,
                "label": "Hardeeville",
                "label_ar": "هارديفيل",
                "label_fr": "Hardeeville"
            },
            {
                "value": 8847,
                "label": "Hardin",
                "label_ar": "هاردن",
                "label_fr": "Hardin"
            },
            {
                "value": 8848,
                "label": "Hardinsburg",
                "label_ar": "هاردينسبورج",
                "label_fr": "Hardinsburg"
            },
            {
                "value": 8849,
                "label": "Hardtner",
                "label_ar": "هاردتنر",
                "label_fr": "Hardtner"
            },
            {
                "value": 8850,
                "label": "Hardwick",
                "label_ar": "هاردويك",
                "label_fr": "Hardwick"
            },
            {
                "value": 8851,
                "label": "Hardy",
                "label_ar": "هاردي",
                "label_fr": "Robuste"
            },
            {
                "value": 8852,
                "label": "Hardyville",
                "label_ar": "هارديفيل",
                "label_fr": "Hardyville"
            },
            {
                "value": 8853,
                "label": "Harford",
                "label_ar": "هارفورد",
                "label_fr": "Harford"
            },
            {
                "value": 8854,
                "label": "Harker Heights",
                "label_ar": "هاركر هايتس",
                "label_fr": "Harker Heights"
            },
            {
                "value": 8855,
                "label": "Harlan",
                "label_ar": "هارلان",
                "label_fr": "Harlan"
            },
            {
                "value": 8856,
                "label": "Harlem",
                "label_ar": "هارلم",
                "label_fr": "Harlem"
            },
            {
                "value": 8857,
                "label": "Harleton",
                "label_ar": "هارلتون",
                "label_fr": "Harleton"
            },
            {
                "value": 8858,
                "label": "Harleysville",
                "label_ar": "هارليزفيل",
                "label_fr": "Harleysville"
            },
            {
                "value": 8859,
                "label": "Harlingen",
                "label_ar": "هارلينجن",
                "label_fr": "Harlingen"
            },
            {
                "value": 8860,
                "label": "Harlowton",
                "label_ar": "هارلوتون",
                "label_fr": "Harlowton"
            },
            {
                "value": 8861,
                "label": "Harmans",
                "label_ar": "هارمانز",
                "label_fr": "Harmans"
            },
            {
                "value": 8862,
                "label": "Harmon",
                "label_ar": "هارمون",
                "label_fr": "Harmon"
            },
            {
                "value": 8863,
                "label": "Harmonsburg",
                "label_ar": "هارمونسبورج",
                "label_fr": "Harmonsburg"
            },
            {
                "value": 8864,
                "label": "Harmony",
                "label_ar": "انسجام",
                "label_fr": "Harmonie"
            },
            {
                "value": 8865,
                "label": "Harned",
                "label_ar": "ملعون",
                "label_fr": "Harned"
            },
            {
                "value": 8866,
                "label": "Harold",
                "label_ar": "هارولد",
                "label_fr": "Harold"
            },
            {
                "value": 8867,
                "label": "Harper",
                "label_ar": "هاربر",
                "label_fr": "Harper"
            },
            {
                "value": 8868,
                "label": "Harper Woods",
                "label_ar": "هاربر وودز",
                "label_fr": "Harper Woods"
            },
            {
                "value": 8869,
                "label": "Harpers Ferry",
                "label_ar": "هاربرز فيري",
                "label_fr": "Harpers Ferry"
            },
            {
                "value": 8870,
                "label": "Harpersville",
                "label_ar": "هاربرسفيل",
                "label_fr": "Harpersville"
            },
            {
                "value": 8871,
                "label": "Harpster",
                "label_ar": "هاربستر",
                "label_fr": "Harpster"
            },
            {
                "value": 8872,
                "label": "Harpswell",
                "label_ar": "هاربسويل",
                "label_fr": "Harpswell"
            },
            {
                "value": 8873,
                "label": "Harpursville",
                "label_ar": "هاربورسفيل",
                "label_fr": "Harpursville"
            },
            {
                "value": 8874,
                "label": "Harrah",
                "label_ar": "حارة",
                "label_fr": "Harrah"
            },
            {
                "value": 8875,
                "label": "Harrells",
                "label_ar": "هاريلز",
                "label_fr": "Harrells"
            },
            {
                "value": 8876,
                "label": "Harriet",
                "label_ar": "هارييت",
                "label_fr": "Harriet"
            },
            {
                "value": 8877,
                "label": "Harrietta",
                "label_ar": "هارييتا",
                "label_fr": "Harrietta"
            },
            {
                "value": 8878,
                "label": "Harriman",
                "label_ar": "هاريمان",
                "label_fr": "Harriman"
            },
            {
                "value": 8879,
                "label": "Harrington",
                "label_ar": "هارينجتون",
                "label_fr": "Harrington"
            },
            {
                "value": 8880,
                "label": "Harrington Park",
                "label_ar": "هارينجتون بارك",
                "label_fr": "Harrington Park"
            },
            {
                "value": 8881,
                "label": "Harris",
                "label_ar": "هاريس",
                "label_fr": "Harris"
            },
            {
                "value": 8882,
                "label": "Harrisburg",
                "label_ar": "هاريسبرج",
                "label_fr": "Harrisburg"
            },
            {
                "value": 8883,
                "label": "Harrison",
                "label_ar": "هاريسون",
                "label_fr": "Harrison"
            },
            {
                "value": 8884,
                "label": "Harrison City",
                "label_ar": "هاريسون سيتي",
                "label_fr": "Harrison City"
            },
            {
                "value": 8885,
                "label": "Harrison Valley",
                "label_ar": "وادي هاريسون",
                "label_fr": "Vallée de Harrison"
            },
            {
                "value": 8886,
                "label": "Harrisonburg",
                "label_ar": "هاريسونبرج",
                "label_fr": "Harrisonburg"
            },
            {
                "value": 8887,
                "label": "Harrisonville",
                "label_ar": "هاريسونفيل",
                "label_fr": "Harrisonville"
            },
            {
                "value": 8888,
                "label": "Harrisville",
                "label_ar": "هاريسفيل",
                "label_fr": "Harrisville"
            },
            {
                "value": 8889,
                "label": "Harrod",
                "label_ar": "هارود",
                "label_fr": "Harrod"
            },
            {
                "value": 8890,
                "label": "Harrodsburg",
                "label_ar": "هارودسبرج",
                "label_fr": "Harrodsburg"
            },
            {
                "value": 8891,
                "label": "Harrogate",
                "label_ar": "هاروغيت",
                "label_fr": "Harrogate"
            },
            {
                "value": 8892,
                "label": "Harrold",
                "label_ar": "هارولد",
                "label_fr": "Harrold"
            },
            {
                "value": 8893,
                "label": "Harshaw",
                "label_ar": "هارشو",
                "label_fr": "Harshaw"
            },
            {
                "value": 8894,
                "label": "Hart",
                "label_ar": "هارت",
                "label_fr": "Cerf"
            },
            {
                "value": 8895,
                "label": "Hartfield",
                "label_ar": "هارتفيلد",
                "label_fr": "Hartfield"
            },
            {
                "value": 8896,
                "label": "Hartford",
                "label_ar": "هارتفورد",
                "label_fr": "Hartford"
            },
            {
                "value": 8897,
                "label": "Hartford City",
                "label_ar": "هارتفورد سيتي",
                "label_fr": "Hartford City"
            },
            {
                "value": 8898,
                "label": "Hartington",
                "label_ar": "هارتينجتون",
                "label_fr": "Hartington"
            },
            {
                "value": 8899,
                "label": "Hartland",
                "label_ar": "هارتلاند",
                "label_fr": "Hartland"
            },
            {
                "value": 8900,
                "label": "Hartley",
                "label_ar": "هارتلي",
                "label_fr": "Hartley"
            },
            {
                "value": 8901,
                "label": "Hartline",
                "label_ar": "هارتلاين",
                "label_fr": "Hartline"
            },
            {
                "value": 8902,
                "label": "Hartly",
                "label_ar": "هارتلي",
                "label_fr": "Hartly"
            },
            {
                "value": 8903,
                "label": "Hartman",
                "label_ar": "هارتمان",
                "label_fr": "Hartman"
            },
            {
                "value": 8904,
                "label": "Harts",
                "label_ar": "هارتس",
                "label_fr": "Harts"
            },
            {
                "value": 8905,
                "label": "Hartsburg",
                "label_ar": "هارتسبورغ",
                "label_fr": "Hartsburg"
            },
            {
                "value": 8906,
                "label": "Hartsdale",
                "label_ar": "هارتسديل",
                "label_fr": "Hartsdale"
            },
            {
                "value": 8907,
                "label": "Hartsel",
                "label_ar": "هارتسيل",
                "label_fr": "Hartsel"
            },
            {
                "value": 8908,
                "label": "Hartselle",
                "label_ar": "هارتسيل",
                "label_fr": "Hartselle"
            },
            {
                "value": 8909,
                "label": "Hartsfield",
                "label_ar": "هارتسفيلد",
                "label_fr": "Hartsfield"
            },
            {
                "value": 8910,
                "label": "Hartshorne",
                "label_ar": "هارتشورن",
                "label_fr": "Hartshorne"
            },
            {
                "value": 8911,
                "label": "Hartstown",
                "label_ar": "هارتستاون",
                "label_fr": "Hartstown"
            },
            {
                "value": 8912,
                "label": "Hartsville",
                "label_ar": "هارتسفيل",
                "label_fr": "Hartsville"
            },
            {
                "value": 8913,
                "label": "Hartville",
                "label_ar": "هارتفيل",
                "label_fr": "Hartville"
            },
            {
                "value": 8914,
                "label": "Hartwell",
                "label_ar": "هارتويل",
                "label_fr": "Hartwell"
            },
            {
                "value": 8915,
                "label": "Hartwick",
                "label_ar": "هارتويك",
                "label_fr": "Hartwick"
            },
            {
                "value": 8916,
                "label": "Hartwood",
                "label_ar": "هارتوود",
                "label_fr": "Hartwood"
            },
            {
                "value": 8917,
                "label": "Harvard",
                "label_ar": "هارفارد",
                "label_fr": "Harvard"
            },
            {
                "value": 8918,
                "label": "Harvest",
                "label_ar": "حصاد",
                "label_fr": "Récolte"
            },
            {
                "value": 8919,
                "label": "Harvey",
                "label_ar": "هارفي",
                "label_fr": "Harvey"
            },
            {
                "value": 8920,
                "label": "Harveys Lake",
                "label_ar": "بحيرة هارفيز",
                "label_fr": "Lac Harveys"
            },
            {
                "value": 8921,
                "label": "Harveyville",
                "label_ar": "هارفيفيل",
                "label_fr": "Harveyville"
            },
            {
                "value": 8922,
                "label": "Harviell",
                "label_ar": "هارفيل",
                "label_fr": "Harviell"
            },
            {
                "value": 8923,
                "label": "Harwich",
                "label_ar": "هارويتش",
                "label_fr": "Harwich"
            },
            {
                "value": 8924,
                "label": "Harwich Port",
                "label_ar": "ميناء هارويش",
                "label_fr": "Port de Harwich"
            },
            {
                "value": 8925,
                "label": "Harwick",
                "label_ar": "هارويك",
                "label_fr": "Harwick"
            },
            {
                "value": 8926,
                "label": "Harwinton",
                "label_ar": "هاروينتون",
                "label_fr": "Harwinton"
            },
            {
                "value": 8927,
                "label": "Harwood",
                "label_ar": "هاروود",
                "label_fr": "Harwood"
            },
            {
                "value": 8928,
                "label": "Harwood Heights",
                "label_ar": "مرتفعات هاروود",
                "label_fr": "Harwood Heights"
            },
            {
                "value": 8929,
                "label": "Hasbrouck Heights",
                "label_ar": "هاسبروك هايتس",
                "label_fr": "Hasbrouck Heights"
            },
            {
                "value": 8930,
                "label": "Haskell",
                "label_ar": "هاسكل",
                "label_fr": "Haskell"
            },
            {
                "value": 8931,
                "label": "Haskins",
                "label_ar": "هاسكينز",
                "label_fr": "Haskins"
            },
            {
                "value": 8932,
                "label": "Haslet",
                "label_ar": "هاسليت",
                "label_fr": "Haslet"
            },
            {
                "value": 8933,
                "label": "Haslett",
                "label_ar": "هاسليت",
                "label_fr": "Haslett"
            },
            {
                "value": 8934,
                "label": "Hastings",
                "label_ar": "هاستنجز",
                "label_fr": "Hastings"
            },
            {
                "value": 8935,
                "label": "Hastings-on-Hudson",
                "label_ar": "هاستينغز أون هدسون",
                "label_fr": "Hastings-on-Hudson"
            },
            {
                "value": 8936,
                "label": "Hasty",
                "label_ar": "متسرع",
                "label_fr": "Précipité"
            },
            {
                "value": 8937,
                "label": "Hat Creek",
                "label_ar": "هات كريك",
                "label_fr": "Hat Creek"
            },
            {
                "value": 8938,
                "label": "Hatboro",
                "label_ar": "هاتبورو",
                "label_fr": "Hatboro"
            },
            {
                "value": 8939,
                "label": "Hatch",
                "label_ar": "فقس",
                "label_fr": "la trappe"
            },
            {
                "value": 8940,
                "label": "Hatfield",
                "label_ar": "هاتفيلد",
                "label_fr": "Hatfield"
            },
            {
                "value": 8941,
                "label": "Hathaway Pines",
                "label_ar": "هاثاواي باينز",
                "label_fr": "Hathaway Pines"
            },
            {
                "value": 8942,
                "label": "Hathorne",
                "label_ar": "هاتورن",
                "label_fr": "Hathorne"
            },
            {
                "value": 8943,
                "label": "Hatley",
                "label_ar": "هاتلي",
                "label_fr": "Hatley"
            },
            {
                "value": 8944,
                "label": "Hattiesburg",
                "label_ar": "هاتيسبرج",
                "label_fr": "Hattiesburg"
            },
            {
                "value": 8945,
                "label": "Hattieville",
                "label_ar": "هاتيفيل",
                "label_fr": "Hattieville"
            },
            {
                "value": 8946,
                "label": "Hau'ula",
                "label_ar": "حولة",
                "label_fr": "Hau'ula"
            },
            {
                "value": 8947,
                "label": "Haubstadt",
                "label_ar": "هاوبشتات",
                "label_fr": "Haubstadt"
            },
            {
                "value": 8948,
                "label": "Haughton",
                "label_ar": "هوتون",
                "label_fr": "Haughton"
            },
            {
                "value": 8949,
                "label": "Hauppauge",
                "label_ar": "هوبوج",
                "label_fr": "Hauppauge"
            },
            {
                "value": 8950,
                "label": "Havana",
                "label_ar": "هافانا",
                "label_fr": "La Havane"
            },
            {
                "value": 8951,
                "label": "Havelock",
                "label_ar": "هافلوك",
                "label_fr": "Havelock"
            },
            {
                "value": 8952,
                "label": "Haven",
                "label_ar": "هافن",
                "label_fr": "Havre"
            },
            {
                "value": 8953,
                "label": "Havensville",
                "label_ar": "هافينسفيل",
                "label_fr": "Havensville"
            },
            {
                "value": 8954,
                "label": "Haverford",
                "label_ar": "هافرفورد",
                "label_fr": "Haverford"
            },
            {
                "value": 8955,
                "label": "Haverhill",
                "label_ar": "هافرهيل",
                "label_fr": "Haverhill"
            },
            {
                "value": 8956,
                "label": "Haverstraw",
                "label_ar": "هافرسترو",
                "label_fr": "Haverstraw"
            },
            {
                "value": 8957,
                "label": "Havertown",
                "label_ar": "هافرتاون",
                "label_fr": "Havertown"
            },
            {
                "value": 8958,
                "label": "Haviland",
                "label_ar": "هافيلاند",
                "label_fr": "Haviland"
            },
            {
                "value": 8959,
                "label": "Havre",
                "label_ar": "هافر",
                "label_fr": "Havre"
            },
            {
                "value": 8960,
                "label": "Havre de Grace",
                "label_ar": "هافر دي جريس",
                "label_fr": "Havre de Grâce"
            },
            {
                "value": 8961,
                "label": "Haw River",
                "label_ar": "نهر هاو",
                "label_fr": "Rivière Haw"
            },
            {
                "value": 8962,
                "label": "Hawaiian Gardens",
                "label_ar": "حدائق هاواي",
                "label_fr": "Jardins hawaïens"
            },
            {
                "value": 8963,
                "label": "Hawaiian Ocean View",
                "label_ar": "منظر هاواي المحيط",
                "label_fr": "Vue sur l'océan hawaïen"
            },
            {
                "value": 8964,
                "label": "Hawarden",
                "label_ar": "هواردن",
                "label_fr": "Hawarden"
            },
            {
                "value": 8965,
                "label": "Hawesville",
                "label_ar": "هاويسفيل",
                "label_fr": "Hawesville"
            },
            {
                "value": 8966,
                "label": "Hawkeye",
                "label_ar": "هوك",
                "label_fr": "Oeil de faucon"
            },
            {
                "value": 8967,
                "label": "Hawkins",
                "label_ar": "هوكينز",
                "label_fr": "Hawkins"
            },
            {
                "value": 8968,
                "label": "Hawkinsville",
                "label_ar": "هوكينزفيل",
                "label_fr": "Hawkinsville"
            },
            {
                "value": 8969,
                "label": "Hawley",
                "label_ar": "هاولي",
                "label_fr": "Hawley"
            },
            {
                "value": 8970,
                "label": "Haworth",
                "label_ar": "هاوورث",
                "label_fr": "Haworth"
            },
            {
                "value": 8971,
                "label": "Hawthorn",
                "label_ar": "الزعرور",
                "label_fr": "Aubépine"
            },
            {
                "value": 8972,
                "label": "Hawthorne",
                "label_ar": "هوثورن",
                "label_fr": "Hawthorne"
            },
            {
                "value": 8973,
                "label": "Haxtun",
                "label_ar": "هاكستون",
                "label_fr": "Haxtun"
            },
            {
                "value": 8974,
                "label": "Hay",
                "label_ar": "القش",
                "label_fr": "Foins"
            },
            {
                "value": 8975,
                "label": "Hay Springs",
                "label_ar": "هاي سبرينغز",
                "label_fr": "Hay Springs"
            },
            {
                "value": 8976,
                "label": "Hayden",
                "label_ar": "هايدن",
                "label_fr": "Hayden"
            },
            {
                "value": 8977,
                "label": "Haydenville",
                "label_ar": "هايدنفيل",
                "label_fr": "Haydenville"
            },
            {
                "value": 8978,
                "label": "Hayes",
                "label_ar": "هايز",
                "label_fr": "Hayes"
            },
            {
                "value": 8979,
                "label": "Hayesville",
                "label_ar": "هايسفيل",
                "label_fr": "Hayesville"
            },
            {
                "value": 8980,
                "label": "Hayfield",
                "label_ar": "هايفيلد",
                "label_fr": "Hayfield"
            },
            {
                "value": 8981,
                "label": "Hayfork",
                "label_ar": "هايفورك",
                "label_fr": "Hayfork"
            },
            {
                "value": 8982,
                "label": "Haymarket",
                "label_ar": "هايماركت",
                "label_fr": "Haymarket"
            },
            {
                "value": 8983,
                "label": "Haynesville",
                "label_ar": "هاينزفيل",
                "label_fr": "Haynesville"
            },
            {
                "value": 8984,
                "label": "Hayneville",
                "label_ar": "هاينفيل",
                "label_fr": "Hayneville"
            },
            {
                "value": 8985,
                "label": "Hays",
                "label_ar": "هايز",
                "label_fr": "Hays"
            },
            {
                "value": 8986,
                "label": "Haysi",
                "label_ar": "هايسي",
                "label_fr": "Haysi"
            },
            {
                "value": 8987,
                "label": "Haysville",
                "label_ar": "هايسفيل",
                "label_fr": "Haysville"
            },
            {
                "value": 8988,
                "label": "Hayti",
                "label_ar": "Hayti",
                "label_fr": "Hayti"
            },
            {
                "value": 8989,
                "label": "Hayward",
                "label_ar": "هايوارد",
                "label_fr": "Hayward"
            },
            {
                "value": 8990,
                "label": "Hazard",
                "label_ar": "الخطر",
                "label_fr": "Danger"
            },
            {
                "value": 8991,
                "label": "Hazel",
                "label_ar": "بندق",
                "label_fr": "Noisette"
            },
            {
                "value": 8992,
                "label": "Hazel Crest",
                "label_ar": "عسلي كريست",
                "label_fr": "Hazel Crest"
            },
            {
                "value": 8993,
                "label": "Hazel Green",
                "label_ar": "اخضر بلون البندق",
                "label_fr": "Vert noisette"
            },
            {
                "value": 8994,
                "label": "Hazel Park",
                "label_ar": "هازل بارك",
                "label_fr": "Hazel Park"
            },
            {
                "value": 8995,
                "label": "Hazelhurst",
                "label_ar": "هازلهورست",
                "label_fr": "Hazelhurst"
            },
            {
                "value": 8996,
                "label": "Hazelton",
                "label_ar": "هازلتون",
                "label_fr": "Hazelton"
            },
            {
                "value": 8997,
                "label": "Hazelwood",
                "label_ar": "بندق",
                "label_fr": "Noisetier"
            },
            {
                "value": 8998,
                "label": "Hazen",
                "label_ar": "هازن",
                "label_fr": "Hazen"
            },
            {
                "value": 8999,
                "label": "Hazlehurst",
                "label_ar": "هازلهيرست",
                "label_fr": "Hazlehurst"
            },
            {
                "value": 9000,
                "label": "Hazlet",
                "label_ar": "هازليت",
                "label_fr": "Hazlet"
            },
            {
                "value": 9001,
                "label": "Hazleton",
                "label_ar": "هازلتون",
                "label_fr": "Hazleton"
            },
            {
                "value": 9002,
                "label": "Head of Westport",
                "label_ar": "رئيس ويستبورت",
                "label_fr": "Directeur de Westport"
            },
            {
                "value": 9003,
                "label": "Headland",
                "label_ar": "هيدلاند",
                "label_fr": "Promontoire"
            },
            {
                "value": 9004,
                "label": "Healdsburg",
                "label_ar": "هيلدسبورج",
                "label_fr": "Healdsburg"
            },
            {
                "value": 9005,
                "label": "Healdton",
                "label_ar": "هيلتون",
                "label_fr": "Healdton"
            },
            {
                "value": 9006,
                "label": "Healy",
                "label_ar": "هيلي",
                "label_fr": "Healy"
            },
            {
                "value": 9007,
                "label": "Hearne",
                "label_ar": "هيرن",
                "label_fr": "Hearne"
            },
            {
                "value": 9008,
                "label": "Heath",
                "label_ar": "هيث",
                "label_fr": "Bruyère"
            },
            {
                "value": 9009,
                "label": "Heath Springs",
                "label_ar": "هيث سبرينغز",
                "label_fr": "Heath Springs"
            },
            {
                "value": 9010,
                "label": "Heathsville",
                "label_ar": "هيثسفيل",
                "label_fr": "Heathsville"
            },
            {
                "value": 9011,
                "label": "Heavener",
                "label_ar": "الجنة",
                "label_fr": "Céleste"
            },
            {
                "value": 9012,
                "label": "Hebbronville",
                "label_ar": "هيبرونفيل",
                "label_fr": "Hebbronville"
            },
            {
                "value": 9013,
                "label": "Heber",
                "label_ar": "هيبر",
                "label_fr": "Heber"
            },
            {
                "value": 9014,
                "label": "Heber Springs",
                "label_ar": "هيبر سبرينغز",
                "label_fr": "Heber Springs"
            },
            {
                "value": 9015,
                "label": "Hebron",
                "label_ar": "الخليل",
                "label_fr": "Hébron"
            },
            {
                "value": 9016,
                "label": "Hector",
                "label_ar": "هيكتور",
                "label_fr": "Hector"
            },
            {
                "value": 9017,
                "label": "Hedgesville",
                "label_ar": "هيدجسفيل",
                "label_fr": "Hedgesville"
            },
            {
                "value": 9018,
                "label": "Hedrick",
                "label_ar": "هيدريك",
                "label_fr": "Hedrick"
            },
            {
                "value": 9019,
                "label": "Heflin",
                "label_ar": "هيفلين",
                "label_fr": "Heflin"
            },
            {
                "value": 9020,
                "label": "Hegins",
                "label_ar": "هيجينز",
                "label_fr": "Hegins"
            },
            {
                "value": 9021,
                "label": "Heidelberg",
                "label_ar": "هايدلبرغ",
                "label_fr": "Heidelberg"
            },
            {
                "value": 9022,
                "label": "Heidrick",
                "label_ar": "هايدريك",
                "label_fr": "Heidrick"
            },
            {
                "value": 9023,
                "label": "Heiskell",
                "label_ar": "هيسكل",
                "label_fr": "Heiskell"
            },
            {
                "value": 9024,
                "label": "Helen",
                "label_ar": "هيلين",
                "label_fr": "Hélène"
            },
            {
                "value": 9025,
                "label": "Helena",
                "label_ar": "هيلينا",
                "label_fr": "Helena"
            },
            {
                "value": 9026,
                "label": "Helendale",
                "label_ar": "هيلينديل",
                "label_fr": "Helendale"
            },
            {
                "value": 9027,
                "label": "Helenwood",
                "label_ar": "هيلينوود",
                "label_fr": "Helenwood"
            },
            {
                "value": 9028,
                "label": "Helix",
                "label_ar": "اللولب",
                "label_fr": "Hélix"
            },
            {
                "value": 9029,
                "label": "Hellertown",
                "label_ar": "هيلرتاون",
                "label_fr": "Hellertown"
            },
            {
                "value": 9030,
                "label": "Helmetta",
                "label_ar": "هيلميتا",
                "label_fr": "Helmetta"
            },
            {
                "value": 9031,
                "label": "Helotes",
                "label_ar": "هيلوتس",
                "label_fr": "Helotes"
            },
            {
                "value": 9032,
                "label": "Helper",
                "label_ar": "المساعد",
                "label_fr": "Assistant"
            },
            {
                "value": 9033,
                "label": "Helvetia",
                "label_ar": "هيلفيتيا",
                "label_fr": "Helvetia"
            },
            {
                "value": 9034,
                "label": "Hematite",
                "label_ar": "الهيماتيت",
                "label_fr": "Hématite"
            },
            {
                "value": 9035,
                "label": "Hemet",
                "label_ar": "التقى",
                "label_fr": "Il a rencontré"
            },
            {
                "value": 9036,
                "label": "Hemingford",
                "label_ar": "همنغفورد",
                "label_fr": "Hemingford"
            },
            {
                "value": 9037,
                "label": "Hemingway",
                "label_ar": "همنغواي",
                "label_fr": "Hemingway"
            },
            {
                "value": 9038,
                "label": "Hemlock",
                "label_ar": "الشوكران",
                "label_fr": "Ciguë"
            },
            {
                "value": 9039,
                "label": "Hemphill",
                "label_ar": "هيمفيل",
                "label_fr": "Hemphill"
            },
            {
                "value": 9040,
                "label": "Hempstead",
                "label_ar": "هيمبستيد",
                "label_fr": "Hempstead"
            },
            {
                "value": 9041,
                "label": "Henagar",
                "label_ar": "Henagar",
                "label_fr": "Henagar"
            },
            {
                "value": 9042,
                "label": "Henderson",
                "label_ar": "هندرسون",
                "label_fr": "Henderson"
            },
            {
                "value": 9043,
                "label": "Hendersonville",
                "label_ar": "هندرسونفيل",
                "label_fr": "Hendersonville"
            },
            {
                "value": 9044,
                "label": "Hendricks",
                "label_ar": "هندريكس",
                "label_fr": "Hendricks"
            },
            {
                "value": 9045,
                "label": "Hendrix",
                "label_ar": "هندريكس",
                "label_fr": "Hendrix"
            },
            {
                "value": 9046,
                "label": "Henefer",
                "label_ar": "هنفر",
                "label_fr": "Henefer"
            },
            {
                "value": 9047,
                "label": "Hennepin",
                "label_ar": "هينيبين",
                "label_fr": "Hennepin"
            },
            {
                "value": 9048,
                "label": "Hennessey",
                "label_ar": "هينيسي",
                "label_fr": "Hennessey"
            },
            {
                "value": 9049,
                "label": "Henniker",
                "label_ar": "هينكر",
                "label_fr": "Randonneur"
            },
            {
                "value": 9050,
                "label": "Henning",
                "label_ar": "هينينج",
                "label_fr": "Henning"
            },
            {
                "value": 9051,
                "label": "Henrico",
                "label_ar": "هنريكو",
                "label_fr": "Henrico"
            },
            {
                "value": 9052,
                "label": "Henrietta",
                "label_ar": "هنريتا",
                "label_fr": "Henrietta"
            },
            {
                "value": 9053,
                "label": "Henry",
                "label_ar": "هنري",
                "label_fr": "Henri"
            },
            {
                "value": 9054,
                "label": "Henryetta",
                "label_ar": "هنريتا",
                "label_fr": "Henryetta"
            },
            {
                "value": 9055,
                "label": "Henryville",
                "label_ar": "Henryville",
                "label_fr": "Henryville"
            },
            {
                "value": 9056,
                "label": "Hensley",
                "label_ar": "هينسلي",
                "label_fr": "Hensley"
            },
            {
                "value": 9057,
                "label": "Hephzibah",
                "label_ar": "حفزيبة",
                "label_fr": "Hephzibah"
            },
            {
                "value": 9058,
                "label": "Hepler",
                "label_ar": "هيبلر",
                "label_fr": "Hepler"
            },
            {
                "value": 9059,
                "label": "Heppner",
                "label_ar": "هيبنر",
                "label_fr": "Heppner"
            },
            {
                "value": 9060,
                "label": "Hepzibah",
                "label_ar": "Hepzibah",
                "label_fr": "Hepzibah"
            },
            {
                "value": 9061,
                "label": "Herald",
                "label_ar": "يعلن",
                "label_fr": "Héraut"
            },
            {
                "value": 9062,
                "label": "Herculaneum",
                "label_ar": "هيركولانيوم",
                "label_fr": "Herculanum"
            },
            {
                "value": 9063,
                "label": "Hercules",
                "label_ar": "هرقل",
                "label_fr": "Hercule"
            },
            {
                "value": 9064,
                "label": "Hereford",
                "label_ar": "هيريفورد",
                "label_fr": "Hereford"
            },
            {
                "value": 9065,
                "label": "Herington",
                "label_ar": "هرينجتون",
                "label_fr": "Herington"
            },
            {
                "value": 9066,
                "label": "Herkimer",
                "label_ar": "هيركيمير",
                "label_fr": "Herkimer"
            },
            {
                "value": 9067,
                "label": "Herlong",
                "label_ar": "هيرلونج",
                "label_fr": "Herlong"
            },
            {
                "value": 9068,
                "label": "Herman",
                "label_ar": "هيرمان",
                "label_fr": "Herman"
            },
            {
                "value": 9069,
                "label": "Hermann",
                "label_ar": "هيرمان",
                "label_fr": "Hermann"
            },
            {
                "value": 9070,
                "label": "Hermansville",
                "label_ar": "هيرمانسفيل",
                "label_fr": "Hermansville"
            },
            {
                "value": 9071,
                "label": "Hermanville",
                "label_ar": "هيرمانفيل",
                "label_fr": "Hermanville"
            },
            {
                "value": 9072,
                "label": "Herminie",
                "label_ar": "هيرميني",
                "label_fr": "Herminie"
            },
            {
                "value": 9073,
                "label": "Hermiston",
                "label_ar": "هيرميستون",
                "label_fr": "Hermiston"
            },
            {
                "value": 9074,
                "label": "Hermitage",
                "label_ar": "هيرميتاج",
                "label_fr": "Ermitage"
            },
            {
                "value": 9075,
                "label": "Hermon",
                "label_ar": "حرمون",
                "label_fr": "Hermon"
            },
            {
                "value": 9076,
                "label": "Hermosa",
                "label_ar": "هيرموسا",
                "label_fr": "Hermosa"
            },
            {
                "value": 9077,
                "label": "Hermosa Beach",
                "label_ar": "شاطئ هيرموسا",
                "label_fr": "Plage Hermosa"
            },
            {
                "value": 9078,
                "label": "Hernando",
                "label_ar": "هيرناندو",
                "label_fr": "Hernando"
            },
            {
                "value": 9079,
                "label": "Herndon",
                "label_ar": "هيرندون",
                "label_fr": "Herndon"
            },
            {
                "value": 9080,
                "label": "Hernshaw",
                "label_ar": "هيرنشو",
                "label_fr": "Hernshaw"
            },
            {
                "value": 9081,
                "label": "Herod",
                "label_ar": "هيرودس",
                "label_fr": "Hérode"
            },
            {
                "value": 9082,
                "label": "Heron",
                "label_ar": "مالك الحزين",
                "label_fr": "Héron"
            },
            {
                "value": 9083,
                "label": "Heron Lake",
                "label_ar": "بحيرة هيرون",
                "label_fr": "Lac Heron"
            },
            {
                "value": 9084,
                "label": "Herrick",
                "label_ar": "هيريك",
                "label_fr": "Herrick"
            },
            {
                "value": 9085,
                "label": "Herriman",
                "label_ar": "هيريمان",
                "label_fr": "Herriman"
            },
            {
                "value": 9086,
                "label": "Herrin",
                "label_ar": "هيرين",
                "label_fr": "Herrin"
            },
            {
                "value": 9087,
                "label": "Hershey",
                "label_ar": "هيرشي",
                "label_fr": "Hershey"
            },
            {
                "value": 9088,
                "label": "Hertford",
                "label_ar": "هيرتفورد",
                "label_fr": "Hertford"
            },
            {
                "value": 9089,
                "label": "Hesperia",
                "label_ar": "هيسبيريا",
                "label_fr": "Hespérie"
            },
            {
                "value": 9090,
                "label": "Hesperus",
                "label_ar": "هيسبروس",
                "label_fr": "Hespérus"
            },
            {
                "value": 9091,
                "label": "Hessmer",
                "label_ar": "هيسمر",
                "label_fr": "Hessmer"
            },
            {
                "value": 9092,
                "label": "Hesston",
                "label_ar": "هيسستون",
                "label_fr": "Hesston"
            },
            {
                "value": 9093,
                "label": "Hettick",
                "label_ar": "هيتيك",
                "label_fr": "Hettick"
            },
            {
                "value": 9094,
                "label": "Hettinger",
                "label_ar": "هيتينجر",
                "label_fr": "Hettinger"
            },
            {
                "value": 9095,
                "label": "Heuvelton",
                "label_ar": "هيوفيلتون",
                "label_fr": "Heuvelton"
            },
            {
                "value": 9096,
                "label": "Hewett",
                "label_ar": "هيويت",
                "label_fr": "Hewett"
            },
            {
                "value": 9097,
                "label": "Hewitt",
                "label_ar": "هيويت",
                "label_fr": "Hewitt"
            },
            {
                "value": 9098,
                "label": "Hewlett",
                "label_ar": "هيوليت",
                "label_fr": "Hewlett"
            },
            {
                "value": 9099,
                "label": "Heyburn",
                "label_ar": "هيبورن",
                "label_fr": "Heyburn"
            },
            {
                "value": 9100,
                "label": "Heyworth",
                "label_ar": "هيوورث",
                "label_fr": "Heyworth"
            },
            {
                "value": 9101,
                "label": "Hialeah",
                "label_ar": "هياليه",
                "label_fr": "Hialeah"
            },
            {
                "value": 9102,
                "label": "Hiawassee",
                "label_ar": "هياواسي",
                "label_fr": "Hiawassee"
            },
            {
                "value": 9103,
                "label": "Hiawatha",
                "label_ar": "هياواثا",
                "label_fr": "Hiawatha"
            },
            {
                "value": 9104,
                "label": "Hibbing",
                "label_ar": "الضرب",
                "label_fr": "Hibbing"
            },
            {
                "value": 9105,
                "label": "Hibbs",
                "label_ar": "هيبس",
                "label_fr": "Hibbs"
            },
            {
                "value": 9106,
                "label": "Hickman",
                "label_ar": "هيكمان",
                "label_fr": "Hickman"
            },
            {
                "value": 9107,
                "label": "Hickory",
                "label_ar": "جوز",
                "label_fr": "Hickory"
            },
            {
                "value": 9108,
                "label": "Hickory Corners",
                "label_ar": "زوايا هيكوري",
                "label_fr": "Coins en hickory"
            },
            {
                "value": 9109,
                "label": "Hickory Flat",
                "label_ar": "شقة هيكوري",
                "label_fr": "Appartement Hickory"
            },
            {
                "value": 9110,
                "label": "Hickory Hills",
                "label_ar": "تلال هيكوري",
                "label_fr": "Hickory Hills"
            },
            {
                "value": 9111,
                "label": "Hickory Valley",
                "label_ar": "وادي هيكوري",
                "label_fr": "Vallée de Hickory"
            },
            {
                "value": 9112,
                "label": "Hicksville",
                "label_ar": "هيكسفيل",
                "label_fr": "Hicksville"
            },
            {
                "value": 9113,
                "label": "Hico",
                "label_ar": "هيكو",
                "label_fr": "Hico"
            },
            {
                "value": 9114,
                "label": "Hidalgo",
                "label_ar": "هيدالجو",
                "label_fr": "Hidalgo"
            },
            {
                "value": 9115,
                "label": "Hidden Valley Lake",
                "label_ar": "بحيرة هيدن فالي",
                "label_fr": "Lac de la vallée cachée"
            },
            {
                "value": 9116,
                "label": "Hiddenite",
                "label_ar": "هيدينيت",
                "label_fr": "Hiddenite"
            },
            {
                "value": 9117,
                "label": "Higden",
                "label_ar": "هيغدن",
                "label_fr": "Higden"
            },
            {
                "value": 9118,
                "label": "Higdon",
                "label_ar": "هيجدون",
                "label_fr": "Higdon"
            },
            {
                "value": 9119,
                "label": "Higganum",
                "label_ar": "هيغانوم",
                "label_fr": "Higganum"
            },
            {
                "value": 9120,
                "label": "Higgins",
                "label_ar": "هيغينز",
                "label_fr": "Higgins"
            },
            {
                "value": 9121,
                "label": "Higginsville",
                "label_ar": "هيجينسفيل",
                "label_fr": "Higginsville"
            },
            {
                "value": 9122,
                "label": "High Bridge",
                "label_ar": "جسر عالية",
                "label_fr": "Pont élevé"
            },
            {
                "value": 9123,
                "label": "High Falls",
                "label_ar": "هاي فولز",
                "label_fr": "High Falls"
            },
            {
                "value": 9124,
                "label": "High Island",
                "label_ar": "جزيرة عالية",
                "label_fr": "Île haute"
            },
            {
                "value": 9125,
                "label": "High Point",
                "label_ar": "نقطة عالية",
                "label_fr": "Point haut"
            },
            {
                "value": 9126,
                "label": "High Ridge",
                "label_ar": "هاي ريدج",
                "label_fr": "High Ridge"
            },
            {
                "value": 9127,
                "label": "High Springs",
                "label_ar": "الينابيع العالية",
                "label_fr": "High Springs"
            },
            {
                "value": 9128,
                "label": "High View",
                "label_ar": "مشاهدة عالية",
                "label_fr": "Vue haute"
            },
            {
                "value": 9129,
                "label": "Highland",
                "label_ar": "هايلاند",
                "label_fr": "Montagnes"
            },
            {
                "value": 9130,
                "label": "Highland Falls",
                "label_ar": "هايلاند فولز",
                "label_fr": "Highland Falls"
            },
            {
                "value": 9131,
                "label": "Highland Home",
                "label_ar": "منزل المرتفعات",
                "label_fr": "Maison Highland"
            },
            {
                "value": 9132,
                "label": "Highland Lakes",
                "label_ar": "بحيرات المرتفعات",
                "label_fr": "Lacs des Highlands"
            },
            {
                "value": 9133,
                "label": "Highland Mills",
                "label_ar": "هايلاند ميلز",
                "label_fr": "Highland Mills"
            },
            {
                "value": 9134,
                "label": "Highland Park",
                "label_ar": "حديقة المرتفعات",
                "label_fr": "Highland Park"
            },
            {
                "value": 9135,
                "label": "Highland Springs",
                "label_ar": "هايلاند سبرينغز",
                "label_fr": "Highland Springs"
            },
            {
                "value": 9136,
                "label": "Highlands",
                "label_ar": "المرتفعات",
                "label_fr": "hauts plateaux"
            },
            {
                "value": 9137,
                "label": "Highlandville",
                "label_ar": "هايلاندفيل",
                "label_fr": "Highlandville"
            },
            {
                "value": 9138,
                "label": "Highmore",
                "label_ar": "هايمور",
                "label_fr": "Highmore"
            },
            {
                "value": 9139,
                "label": "Highspire",
                "label_ar": "هايسبير",
                "label_fr": "Highspire"
            },
            {
                "value": 9140,
                "label": "Hightstown",
                "label_ar": "هايتستاون",
                "label_fr": "Hightstown"
            },
            {
                "value": 9141,
                "label": "Highwood",
                "label_ar": "هايوود",
                "label_fr": "Highwood"
            },
            {
                "value": 9142,
                "label": "Higley",
                "label_ar": "هيجلي",
                "label_fr": "Higley"
            },
            {
                "value": 9143,
                "label": "Hiko",
                "label_ar": "هيكو",
                "label_fr": "Hiko"
            },
            {
                "value": 9144,
                "label": "Hilbert",
                "label_ar": "هيلبرت",
                "label_fr": "Hilbert"
            },
            {
                "value": 9145,
                "label": "Hildale",
                "label_ar": "هيلديل",
                "label_fr": "Hildale"
            },
            {
                "value": 9146,
                "label": "Hildebran",
                "label_ar": "هيلديبران",
                "label_fr": "Hildebran"
            },
            {
                "value": 9147,
                "label": "Hildreth",
                "label_ar": "هيلدريث",
                "label_fr": "Hildreth"
            },
            {
                "value": 9148,
                "label": "Hilham",
                "label_ar": "هيلهام",
                "label_fr": "Hilham"
            },
            {
                "value": 9149,
                "label": "Hill",
                "label_ar": "تل",
                "label_fr": "Colline"
            },
            {
                "value": 9150,
                "label": "Hill City",
                "label_ar": "هيل سيتي",
                "label_fr": "Hill City"
            },
            {
                "value": 9151,
                "label": "Hillburn",
                "label_ar": "هيلبورن",
                "label_fr": "Hillburn"
            },
            {
                "value": 9152,
                "label": "Hilliard",
                "label_ar": "هيليارد",
                "label_fr": "Hilliard"
            },
            {
                "value": 9153,
                "label": "Hillister",
                "label_ar": "هيليستر",
                "label_fr": "Hillister"
            },
            {
                "value": 9154,
                "label": "Hillman",
                "label_ar": "هيلمان",
                "label_fr": "Hillman"
            },
            {
                "value": 9155,
                "label": "Hills",
                "label_ar": "تلال",
                "label_fr": "Collines"
            },
            {
                "value": 9156,
                "label": "Hillsboro",
                "label_ar": "هيلزبورو",
                "label_fr": "Hillsboro"
            },
            {
                "value": 9157,
                "label": "Hillsborough",
                "label_ar": "هيلزبورو",
                "label_fr": "Hillsborough"
            },
            {
                "value": 9158,
                "label": "Hillsdale",
                "label_ar": "هيلزديل",
                "label_fr": "Hillsdale"
            },
            {
                "value": 9159,
                "label": "Hillside",
                "label_ar": "هيلسايد",
                "label_fr": "Flanc de coteau"
            },
            {
                "value": 9160,
                "label": "Hillsville",
                "label_ar": "هيلزفيل",
                "label_fr": "Hillsville"
            },
            {
                "value": 9161,
                "label": "Hilltop",
                "label_ar": "هيلتوب",
                "label_fr": "Hilltop"
            },
            {
                "value": 9162,
                "label": "Hillview",
                "label_ar": "منظر التل",
                "label_fr": "Hillview"
            },
            {
                "value": 9163,
                "label": "Hilmar",
                "label_ar": "هيلمار",
                "label_fr": "Hilmar"
            },
            {
                "value": 9164,
                "label": "Hilo",
                "label_ar": "هيلو",
                "label_fr": "Hilo"
            },
            {
                "value": 9165,
                "label": "Hilton",
                "label_ar": "هيلتون",
                "label_fr": "Hilton"
            },
            {
                "value": 9166,
                "label": "Hilton Head Island",
                "label_ar": "جزيرة هيلتون هيد",
                "label_fr": "Île de Hilton Head"
            },
            {
                "value": 9167,
                "label": "Himrod",
                "label_ar": "حمرود",
                "label_fr": "Himrod"
            },
            {
                "value": 9168,
                "label": "Hinckley",
                "label_ar": "هينكلي",
                "label_fr": "Hinckley"
            },
            {
                "value": 9169,
                "label": "Hindman",
                "label_ar": "هندمان",
                "label_fr": "Hindman"
            },
            {
                "value": 9170,
                "label": "Hindsboro",
                "label_ar": "هيندسبورو",
                "label_fr": "Hindsboro"
            },
            {
                "value": 9171,
                "label": "Hindsville",
                "label_ar": "هيندسفيل",
                "label_fr": "Hindsville"
            },
            {
                "value": 9172,
                "label": "Hines",
                "label_ar": "هاينز",
                "label_fr": "Hines"
            },
            {
                "value": 9173,
                "label": "Hinesburg",
                "label_ar": "هينسبورغ",
                "label_fr": "Hinesburg"
            },
            {
                "value": 9174,
                "label": "Hinesville",
                "label_ar": "هينسفيل",
                "label_fr": "Hinesville"
            },
            {
                "value": 9175,
                "label": "Hingham",
                "label_ar": "هنغهام",
                "label_fr": "Hingham"
            },
            {
                "value": 9176,
                "label": "Hinkley",
                "label_ar": "هينكلي",
                "label_fr": "Hinkley"
            },
            {
                "value": 9177,
                "label": "Hinsdale",
                "label_ar": "هينسدال",
                "label_fr": "Hinsdale"
            },
            {
                "value": 9178,
                "label": "Hinton",
                "label_ar": "هينتون",
                "label_fr": "Hinton"
            },
            {
                "value": 9179,
                "label": "Hiram",
                "label_ar": "حيرام",
                "label_fr": "Hiram"
            },
            {
                "value": 9180,
                "label": "Hitchcock",
                "label_ar": "هيتشكوك",
                "label_fr": "Hitchcock"
            },
            {
                "value": 9181,
                "label": "Hitterdal",
                "label_ar": "هيتردال",
                "label_fr": "Hitterdal"
            },
            {
                "value": 9182,
                "label": "Hiwasse",
                "label_ar": "هايواس",
                "label_fr": "Hiwasse"
            },
            {
                "value": 9183,
                "label": "Hixson",
                "label_ar": "هيكسون",
                "label_fr": "Hixson"
            },
            {
                "value": 9184,
                "label": "Hixton",
                "label_ar": "هيكستون",
                "label_fr": "Hixton"
            },
            {
                "value": 9185,
                "label": "Ho'olehua",
                "label_ar": "Ho'olehua",
                "label_fr": "Ho'olehua"
            },
            {
                "value": 9186,
                "label": "Ho-Ho-Kus",
                "label_ar": "هو-هو-كوس",
                "label_fr": "Ho-Ho-Kus"
            },
            {
                "value": 9187,
                "label": "Hoagland",
                "label_ar": "هوغلاند",
                "label_fr": "Hoagland"
            },
            {
                "value": 9188,
                "label": "Hobart",
                "label_ar": "هوبارت",
                "label_fr": "Hobart"
            },
            {
                "value": 9189,
                "label": "Hobbs",
                "label_ar": "هوبز",
                "label_fr": "Hobbs"
            },
            {
                "value": 9190,
                "label": "Hobbsville",
                "label_ar": "هوبسفيل",
                "label_fr": "Hobbsville"
            },
            {
                "value": 9191,
                "label": "Hobe Sound",
                "label_ar": "هوبي ساوند",
                "label_fr": "Son Hobe"
            },
            {
                "value": 9192,
                "label": "Hoboken",
                "label_ar": "هوبوكين",
                "label_fr": "Hoboken"
            },
            {
                "value": 9193,
                "label": "Hobson",
                "label_ar": "هوبسون",
                "label_fr": "Hobson"
            },
            {
                "value": 9194,
                "label": "Hockessin",
                "label_ar": "هوكيسين",
                "label_fr": "Hockessin"
            },
            {
                "value": 9195,
                "label": "Hockley",
                "label_ar": "هوكلي",
                "label_fr": "Hockley"
            },
            {
                "value": 9196,
                "label": "Hodge",
                "label_ar": "هودج",
                "label_fr": "Hodge"
            },
            {
                "value": 9197,
                "label": "Hodgen",
                "label_ar": "هودجن",
                "label_fr": "Hodgen"
            },
            {
                "value": 9198,
                "label": "Hodgenville",
                "label_ar": "هودجنفيل",
                "label_fr": "Hodgenville"
            },
            {
                "value": 9199,
                "label": "Hodges",
                "label_ar": "هودجز",
                "label_fr": "Hodges"
            },
            {
                "value": 9200,
                "label": "Hoffman",
                "label_ar": "هوفمان",
                "label_fr": "Hoffman"
            },
            {
                "value": 9201,
                "label": "Hoffman Estates",
                "label_ar": "هوفمان إستيتس",
                "label_fr": "Hoffman Estates"
            },
            {
                "value": 9202,
                "label": "Hogansburg",
                "label_ar": "هوغانسبورغ",
                "label_fr": "Hogansburg"
            },
            {
                "value": 9203,
                "label": "Hogansville",
                "label_ar": "هوجانسفيل",
                "label_fr": "Hogansville"
            },
            {
                "value": 9204,
                "label": "Hohenwald",
                "label_ar": "هوهينوالد",
                "label_fr": "Hohenwald"
            },
            {
                "value": 9205,
                "label": "Hoisington",
                "label_ar": "هوسينغتون",
                "label_fr": "Hoisington"
            },
            {
                "value": 9206,
                "label": "Hokah",
                "label_ar": "الحوكة",
                "label_fr": "Hokah"
            },
            {
                "value": 9207,
                "label": "Holabird",
                "label_ar": "هولابيرد",
                "label_fr": "Holabird"
            },
            {
                "value": 9208,
                "label": "Holbrook",
                "label_ar": "هولبروك",
                "label_fr": "Holbrook"
            },
            {
                "value": 9209,
                "label": "Holcomb",
                "label_ar": "هولكومب",
                "label_fr": "Holcomb"
            },
            {
                "value": 9210,
                "label": "Holcombe",
                "label_ar": "هولكومب",
                "label_fr": "Holcombe"
            },
            {
                "value": 9211,
                "label": "Holden",
                "label_ar": "هولدن",
                "label_fr": "Holden"
            },
            {
                "value": 9212,
                "label": "Holdenville",
                "label_ar": "هولدينفيل",
                "label_fr": "Holdenville"
            },
            {
                "value": 9213,
                "label": "Holderness",
                "label_ar": "هولدرنس",
                "label_fr": "Holderness"
            },
            {
                "value": 9214,
                "label": "Holdingford",
                "label_ar": "هولدينجفورد",
                "label_fr": "Holdingford"
            },
            {
                "value": 9215,
                "label": "Holdrege",
                "label_ar": "هولدريج",
                "label_fr": "Holdrege"
            },
            {
                "value": 9216,
                "label": "Holgate",
                "label_ar": "هولجيت",
                "label_fr": "Holgate"
            },
            {
                "value": 9217,
                "label": "Holiday",
                "label_ar": "يوم الاجازة",
                "label_fr": "Vacances"
            },
            {
                "value": 9218,
                "label": "Holland",
                "label_ar": "الهولندي",
                "label_fr": "Hollande"
            },
            {
                "value": 9219,
                "label": "Holland Patent",
                "label_ar": "براءات الاختراع الهولندية",
                "label_fr": "Brevet néerlandais"
            },
            {
                "value": 9220,
                "label": "Hollandale",
                "label_ar": "هولاندال",
                "label_fr": "Hollandale"
            },
            {
                "value": 9221,
                "label": "Holley",
                "label_ar": "هولي",
                "label_fr": "Holley"
            },
            {
                "value": 9222,
                "label": "Holliday",
                "label_ar": "عطلة",
                "label_fr": "Holliday"
            },
            {
                "value": 9223,
                "label": "Hollidaysburg",
                "label_ar": "هوليديسبيرغ",
                "label_fr": "Hollidaysburg"
            },
            {
                "value": 9224,
                "label": "Hollis",
                "label_ar": "هوليس",
                "label_fr": "Hollis"
            },
            {
                "value": 9225,
                "label": "Hollis Center",
                "label_ar": "مركز هوليس",
                "label_fr": "Hollis Center"
            },
            {
                "value": 9226,
                "label": "Hollister",
                "label_ar": "هوليستر",
                "label_fr": "Hollister"
            },
            {
                "value": 9227,
                "label": "Holliston",
                "label_ar": "هوليستون",
                "label_fr": "Holliston"
            },
            {
                "value": 9228,
                "label": "Holloman Air Force Base",
                "label_ar": "قاعدة هولومان الجوية",
                "label_fr": "Base aérienne de Holloman"
            },
            {
                "value": 9229,
                "label": "Hollow Rock",
                "label_ar": "هولو روك",
                "label_fr": "Roche creuse"
            },
            {
                "value": 9230,
                "label": "Holloway",
                "label_ar": "هولواي",
                "label_fr": "Holloway"
            },
            {
                "value": 9231,
                "label": "Hollsopple",
                "label_ar": "هولسوبل",
                "label_fr": "Hollsopple"
            },
            {
                "value": 9232,
                "label": "Holly",
                "label_ar": "هولي",
                "label_fr": "houx"
            },
            {
                "value": 9233,
                "label": "Holly Grove",
                "label_ar": "هولي جروف",
                "label_fr": "Holly Grove"
            },
            {
                "value": 9234,
                "label": "Holly Hill",
                "label_ar": "هولي هيل",
                "label_fr": "Holly Hill"
            },
            {
                "value": 9235,
                "label": "Holly Pond",
                "label_ar": "هولي بوند",
                "label_fr": "Holly Pond"
            },
            {
                "value": 9236,
                "label": "Holly Ridge",
                "label_ar": "هولي ريدج",
                "label_fr": "Holly Ridge"
            },
            {
                "value": 9237,
                "label": "Holly Springs",
                "label_ar": "هولي سبرينغز",
                "label_fr": "Holly Springs"
            },
            {
                "value": 9238,
                "label": "Hollywood",
                "label_ar": "هوليوود",
                "label_fr": "Hollywood"
            },
            {
                "value": 9239,
                "label": "Holmdel",
                "label_ar": "هولمدل",
                "label_fr": "Holmdel"
            },
            {
                "value": 9240,
                "label": "Holmen",
                "label_ar": "هولمن",
                "label_fr": "Holmen"
            },
            {
                "value": 9241,
                "label": "Holmes",
                "label_ar": "هولمز",
                "label_fr": "Holmes"
            },
            {
                "value": 9242,
                "label": "Holmesville",
                "label_ar": "هولمزفيل",
                "label_fr": "Holmesville"
            },
            {
                "value": 9243,
                "label": "Holstein",
                "label_ar": "هولشتاين",
                "label_fr": "Holstein"
            },
            {
                "value": 9244,
                "label": "Holt",
                "label_ar": "هولت",
                "label_fr": "Holt"
            },
            {
                "value": 9245,
                "label": "Holton",
                "label_ar": "هولتون",
                "label_fr": "Holton"
            },
            {
                "value": 9246,
                "label": "Holts Summit",
                "label_ar": "قمة هولتس",
                "label_fr": "Sommet de Holts"
            },
            {
                "value": 9247,
                "label": "Holtsville",
                "label_ar": "هولتسفيل",
                "label_fr": "Holtsville"
            },
            {
                "value": 9248,
                "label": "Holtville",
                "label_ar": "هولتفيل",
                "label_fr": "Holtville"
            },
            {
                "value": 9249,
                "label": "Holtwood",
                "label_ar": "هولتوود",
                "label_fr": "Holtwood"
            },
            {
                "value": 9250,
                "label": "Holualoa",
                "label_ar": "Holualoa",
                "label_fr": "Holualoa"
            },
            {
                "value": 9251,
                "label": "Holy Cross",
                "label_ar": "الصليب المقدس",
                "label_fr": "la Sainte Croix"
            },
            {
                "value": 9252,
                "label": "Holyoke",
                "label_ar": "هوليوك",
                "label_fr": "Holyoke"
            },
            {
                "value": 9253,
                "label": "Holyrood",
                "label_ar": "هوليرود",
                "label_fr": "Holyrood"
            },
            {
                "value": 9254,
                "label": "Home",
                "label_ar": "الصفحة الرئيسية",
                "label_fr": "Accueil"
            },
            {
                "value": 9255,
                "label": "Homedale",
                "label_ar": "هومديل",
                "label_fr": "Homedale"
            },
            {
                "value": 9256,
                "label": "Homeland",
                "label_ar": "البلد الام",
                "label_fr": "Patrie"
            },
            {
                "value": 9257,
                "label": "Homer",
                "label_ar": "هوميروس",
                "label_fr": "Homère"
            },
            {
                "value": 9258,
                "label": "Homer City",
                "label_ar": "مدينة هوميروس",
                "label_fr": "Homer City"
            },
            {
                "value": 9259,
                "label": "Homer Glen",
                "label_ar": "هوميروس جلين",
                "label_fr": "Homer Glen"
            },
            {
                "value": 9260,
                "label": "Homerville",
                "label_ar": "هومرفيل",
                "label_fr": "Homerville"
            },
            {
                "value": 9261,
                "label": "Homestead",
                "label_ar": "العزبة",
                "label_fr": "Propriété"
            },
            {
                "value": 9262,
                "label": "Hometown",
                "label_ar": "مسقط رأس",
                "label_fr": "Ville natale"
            },
            {
                "value": 9263,
                "label": "Homewood",
                "label_ar": "هوموود",
                "label_fr": "Homewood"
            },
            {
                "value": 9264,
                "label": "Homeworth",
                "label_ar": "هومورث",
                "label_fr": "Homeworth"
            },
            {
                "value": 9265,
                "label": "Hominy",
                "label_ar": "عصيدة الذرة",
                "label_fr": "Hominy"
            },
            {
                "value": 9266,
                "label": "Homosassa",
                "label_ar": "حموساسة",
                "label_fr": "Homosassa"
            },
            {
                "value": 9267,
                "label": "Honaker",
                "label_ar": "هوناكر",
                "label_fr": "Honaker"
            },
            {
                "value": 9268,
                "label": "Honaunau",
                "label_ar": "هونوناو",
                "label_fr": "Honaunau"
            },
            {
                "value": 9269,
                "label": "Hondo",
                "label_ar": "هوندو",
                "label_fr": "Hondo"
            },
            {
                "value": 9270,
                "label": "Honea Path",
                "label_ar": "مسار هونيا",
                "label_fr": "Chemin Honea"
            },
            {
                "value": 9271,
                "label": "Honeoye",
                "label_ar": "هونيوي",
                "label_fr": "Honeoye"
            },
            {
                "value": 9272,
                "label": "Honeoye Falls",
                "label_ar": "شلالات هونوي",
                "label_fr": "Chutes Honeoye"
            },
            {
                "value": 9273,
                "label": "Honesdale",
                "label_ar": "هونسديل",
                "label_fr": "Honesdale"
            },
            {
                "value": 9274,
                "label": "Honey Brook",
                "label_ar": "هوني بروك",
                "label_fr": "Honey Brook"
            },
            {
                "value": 9275,
                "label": "Honey Creek",
                "label_ar": "هنى كريك",
                "label_fr": "Honey Creek"
            },
            {
                "value": 9276,
                "label": "Honey Grove",
                "label_ar": "هوني جروف",
                "label_fr": "Honey Grove"
            },
            {
                "value": 9277,
                "label": "Honeydew",
                "label_ar": "المن",
                "label_fr": "Miellat"
            },
            {
                "value": 9278,
                "label": "Honeyville",
                "label_ar": "هانيفيل",
                "label_fr": "Honeyville"
            },
            {
                "value": 9279,
                "label": "Honoka'a",
                "label_ar": "هونوكا",
                "label_fr": "Honoka'a"
            },
            {
                "value": 9280,
                "label": "Honolulu",
                "label_ar": "هونولولو",
                "label_fr": "Honolulu"
            },
            {
                "value": 9281,
                "label": "Honor",
                "label_ar": "شرف",
                "label_fr": "Honneur"
            },
            {
                "value": 9282,
                "label": "Hood River",
                "label_ar": "نهر هود",
                "label_fr": "Rivière Hood"
            },
            {
                "value": 9283,
                "label": "Hoodsport",
                "label_ar": "هودسبورت",
                "label_fr": "Hoodsport"
            },
            {
                "value": 9284,
                "label": "Hooker",
                "label_ar": "عاهرة",
                "label_fr": "Talonneur"
            },
            {
                "value": 9285,
                "label": "Hooks",
                "label_ar": "خطاف",
                "label_fr": "Crochets"
            },
            {
                "value": 9286,
                "label": "Hooksett",
                "label_ar": "هوكسيت",
                "label_fr": "Hooksett"
            },
            {
                "value": 9287,
                "label": "Hookstown",
                "label_ar": "هوكستاون",
                "label_fr": "Hookstown"
            },
            {
                "value": 9288,
                "label": "Hooper",
                "label_ar": "هوبر",
                "label_fr": "Hooper"
            },
            {
                "value": 9289,
                "label": "Hooper Bay",
                "label_ar": "خليج هوبر",
                "label_fr": "Hooper Bay"
            },
            {
                "value": 9290,
                "label": "Hoopeston",
                "label_ar": "هوبستون",
                "label_fr": "Hoopeston"
            },
            {
                "value": 9291,
                "label": "Hoople",
                "label_ar": "هوبلي",
                "label_fr": "Hoople"
            },
            {
                "value": 9292,
                "label": "Hoosick",
                "label_ar": "هوسيك",
                "label_fr": "Hoosick"
            },
            {
                "value": 9293,
                "label": "Hoosick Falls",
                "label_ar": "شلالات هوسيك",
                "label_fr": "Chutes Hoosick"
            },
            {
                "value": 9294,
                "label": "Hooversville",
                "label_ar": "هوفرسفيل",
                "label_fr": "Hooversville"
            },
            {
                "value": 9295,
                "label": "Hop Bottom",
                "label_ar": "قفزة القاع",
                "label_fr": "Hop bas"
            },
            {
                "value": 9296,
                "label": "Hopatcong",
                "label_ar": "هوباتكونغ",
                "label_fr": "Hopatcong"
            },
            {
                "value": 9297,
                "label": "Hope",
                "label_ar": "أمل",
                "label_fr": "Espérer"
            },
            {
                "value": 9298,
                "label": "Hope Hull",
                "label_ar": "هوب هال",
                "label_fr": "Hope Hull"
            },
            {
                "value": 9299,
                "label": "Hope Mills",
                "label_ar": "هوب ميلز",
                "label_fr": "Hope Mills"
            },
            {
                "value": 9300,
                "label": "Hope Valley",
                "label_ar": "وادي الأمل",
                "label_fr": "Hope Valley"
            },
            {
                "value": 9301,
                "label": "Hopedale",
                "label_ar": "هوبديل",
                "label_fr": "Hopedale"
            },
            {
                "value": 9302,
                "label": "Hopewell",
                "label_ar": "هوبويل",
                "label_fr": "Hopewell"
            },
            {
                "value": 9303,
                "label": "Hopewell Junction",
                "label_ar": "مفرق هوبويل",
                "label_fr": "Hopewell Junction"
            },
            {
                "value": 9304,
                "label": "Hopkins",
                "label_ar": "هوبكنز",
                "label_fr": "Hopkins"
            },
            {
                "value": 9305,
                "label": "Hopkinsville",
                "label_ar": "هوبكنسفيل",
                "label_fr": "Hopkinsville"
            },
            {
                "value": 9306,
                "label": "Hopkinton",
                "label_ar": "هوبكينتون",
                "label_fr": "Hopkinton"
            },
            {
                "value": 9307,
                "label": "Hopland",
                "label_ar": "هوبلاند",
                "label_fr": "Hopland"
            },
            {
                "value": 9308,
                "label": "Hopwood",
                "label_ar": "هوبوود",
                "label_fr": "Hopwood"
            },
            {
                "value": 9309,
                "label": "Hoquiam",
                "label_ar": "Hoquiam",
                "label_fr": "Hoquiam"
            },
            {
                "value": 9310,
                "label": "Horatio",
                "label_ar": "هوراشيو",
                "label_fr": "Horatio"
            },
            {
                "value": 9311,
                "label": "Horicon",
                "label_ar": "هوريكون",
                "label_fr": "Horicon"
            },
            {
                "value": 9312,
                "label": "Horn Lake",
                "label_ar": "بحيرة هورن",
                "label_fr": "Horn Lake"
            },
            {
                "value": 9313,
                "label": "Hornbeak",
                "label_ar": "منقار البورن",
                "label_fr": "Calao"
            },
            {
                "value": 9314,
                "label": "Hornbeck",
                "label_ar": "هورنبيك",
                "label_fr": "Calao"
            },
            {
                "value": 9315,
                "label": "Hornell",
                "label_ar": "هورنيل",
                "label_fr": "Hornell"
            },
            {
                "value": 9316,
                "label": "Horner",
                "label_ar": "هورنر",
                "label_fr": "Horner"
            },
            {
                "value": 9317,
                "label": "Hornick",
                "label_ar": "هورنيك",
                "label_fr": "Hornick"
            },
            {
                "value": 9318,
                "label": "Horntown",
                "label_ar": "هورنتاون",
                "label_fr": "Horntown"
            },
            {
                "value": 9319,
                "label": "Horse Branch",
                "label_ar": "فرع الخيول",
                "label_fr": "Branche de cheval"
            },
            {
                "value": 9320,
                "label": "Horse Cave",
                "label_ar": "كهف الحصان",
                "label_fr": "Grotte du cheval"
            },
            {
                "value": 9321,
                "label": "Horse Shoe",
                "label_ar": "حذاء الحصان",
                "label_fr": "Fer à cheval"
            },
            {
                "value": 9322,
                "label": "Horseheads",
                "label_ar": "رؤوس الخيول",
                "label_fr": "Chevaux"
            },
            {
                "value": 9323,
                "label": "Horseshoe Bay",
                "label_ar": "خليج حدوة الحصان",
                "label_fr": "Horseshoe Bay"
            },
            {
                "value": 9324,
                "label": "Horseshoe Bend",
                "label_ar": "بيند حدوة الحصان",
                "label_fr": "courbure en fer à cheval"
            },
            {
                "value": 9325,
                "label": "Horsham",
                "label_ar": "هورشام",
                "label_fr": "Horsham"
            },
            {
                "value": 9326,
                "label": "Hortense",
                "label_ar": "هورتنس",
                "label_fr": "Hortense"
            },
            {
                "value": 9327,
                "label": "Horton",
                "label_ar": "هورتون",
                "label_fr": "Horton"
            },
            {
                "value": 9328,
                "label": "Hortonville",
                "label_ar": "هورتونفيل",
                "label_fr": "Hortonville"
            },
            {
                "value": 9329,
                "label": "Hoschton",
                "label_ar": "هوشتون",
                "label_fr": "Hoschton"
            },
            {
                "value": 9330,
                "label": "Hosford",
                "label_ar": "هوسفورد",
                "label_fr": "Hosford"
            },
            {
                "value": 9331,
                "label": "Hoskins",
                "label_ar": "هوسكينز",
                "label_fr": "Hoskins"
            },
            {
                "value": 9332,
                "label": "Hoskinston",
                "label_ar": "هوسكينستون",
                "label_fr": "Hoskinston"
            },
            {
                "value": 9333,
                "label": "Hosmer",
                "label_ar": "هوسمر",
                "label_fr": "Hosmer"
            },
            {
                "value": 9334,
                "label": "Hospers",
                "label_ar": "هوسبرز",
                "label_fr": "Hospers"
            },
            {
                "value": 9335,
                "label": "Hosston",
                "label_ar": "هوسستون",
                "label_fr": "Hosston"
            },
            {
                "value": 9336,
                "label": "Hostetter",
                "label_ar": "Hostetter",
                "label_fr": "Hostetter"
            },
            {
                "value": 9337,
                "label": "Hot Springs",
                "label_ar": "الينابيع الساخنة",
                "label_fr": "Sources chaudes"
            },
            {
                "value": 9338,
                "label": "Hot Springs National Park",
                "label_ar": "حديقة الينابيع الساخنة الوطنية",
                "label_fr": "Parc national de Hot Springs"
            },
            {
                "value": 9339,
                "label": "Hot Springs Village",
                "label_ar": "قرية الينابيع الساخنة",
                "label_fr": "Village de sources chaudes"
            },
            {
                "value": 9340,
                "label": "Hotchkiss",
                "label_ar": "هوتشكيس",
                "label_fr": "Hotchkiss"
            },
            {
                "value": 9341,
                "label": "Houghton",
                "label_ar": "هوتون",
                "label_fr": "Houghton"
            },
            {
                "value": 9342,
                "label": "Houghton Lake",
                "label_ar": "بحيرة هوتون",
                "label_fr": "Lac Houghton"
            },
            {
                "value": 9343,
                "label": "Houlka",
                "label_ar": "هولكا",
                "label_fr": "Houlka"
            },
            {
                "value": 9344,
                "label": "Houlton",
                "label_ar": "هولتون",
                "label_fr": "Houlton"
            },
            {
                "value": 9345,
                "label": "Houma",
                "label_ar": "هوما",
                "label_fr": "Houma"
            },
            {
                "value": 9346,
                "label": "House Springs",
                "label_ar": "هاوس سبرينغز",
                "label_fr": "House Springs"
            },
            {
                "value": 9347,
                "label": "Houston",
                "label_ar": "هيوستن",
                "label_fr": "Houston"
            },
            {
                "value": 9348,
                "label": "Houtzdale",
                "label_ar": "هوتزديل",
                "label_fr": "Houtzdale"
            },
            {
                "value": 9349,
                "label": "Hoven",
                "label_ar": "هوفن",
                "label_fr": "Hoven"
            },
            {
                "value": 9350,
                "label": "Hovland",
                "label_ar": "هوفلاند",
                "label_fr": "Hovland"
            },
            {
                "value": 9351,
                "label": "Howard",
                "label_ar": "هوارد",
                "label_fr": "Howard"
            },
            {
                "value": 9352,
                "label": "Howard Beach",
                "label_ar": "هوارد بيتش",
                "label_fr": "Howard Beach"
            },
            {
                "value": 9353,
                "label": "Howard City",
                "label_ar": "هوارد سيتي",
                "label_fr": "Howard City"
            },
            {
                "value": 9354,
                "label": "Howard Lake",
                "label_ar": "هوارد ليك",
                "label_fr": "Howard Lake"
            },
            {
                "value": 9355,
                "label": "Howardsville",
                "label_ar": "هواردسفيل",
                "label_fr": "Howardsville"
            },
            {
                "value": 9356,
                "label": "Howe",
                "label_ar": "هاو",
                "label_fr": "Howe"
            },
            {
                "value": 9357,
                "label": "Howell",
                "label_ar": "هويل",
                "label_fr": "Howell"
            },
            {
                "value": 9358,
                "label": "Howes Cave",
                "label_ar": "كهف هاوز",
                "label_fr": "Grotte de Howes"
            },
            {
                "value": 9359,
                "label": "Howie In The Hills",
                "label_ar": "Howie في التلال",
                "label_fr": "Howie dans les collines"
            },
            {
                "value": 9360,
                "label": "Howland",
                "label_ar": "هاولاند",
                "label_fr": "Howland"
            },
            {
                "value": 9361,
                "label": "Hoxie",
                "label_ar": "هوكسي",
                "label_fr": "Hoxie"
            },
            {
                "value": 9362,
                "label": "Hoyleton",
                "label_ar": "هويلتون",
                "label_fr": "Hoyleton"
            },
            {
                "value": 9363,
                "label": "Hoyt",
                "label_ar": "هويت",
                "label_fr": "Hoyt"
            },
            {
                "value": 9364,
                "label": "Hoyt Lakes",
                "label_ar": "هويت ليكس",
                "label_fr": "Lacs Hoyt"
            },
            {
                "value": 9365,
                "label": "Huachuca City",
                "label_ar": "مدينة هواتشوكا",
                "label_fr": "Ville de Huachuca"
            },
            {
                "value": 9366,
                "label": "Hubbard",
                "label_ar": "هوبارد",
                "label_fr": "Hubbard"
            },
            {
                "value": 9367,
                "label": "Hubbard Lake",
                "label_ar": "بحيرة هوبارد",
                "label_fr": "Lac Hubbard"
            },
            {
                "value": 9368,
                "label": "Hubbardston",
                "label_ar": "هوباردستون",
                "label_fr": "Hubbardston"
            },
            {
                "value": 9369,
                "label": "Hubbardsville",
                "label_ar": "هوباردسفيل",
                "label_fr": "Hubbardsville"
            },
            {
                "value": 9370,
                "label": "Hubbell",
                "label_ar": "هوبيل",
                "label_fr": "Hubbell"
            },
            {
                "value": 9371,
                "label": "Hubert",
                "label_ar": "هوبرت",
                "label_fr": "Hubert"
            },
            {
                "value": 9372,
                "label": "Hubertus",
                "label_ar": "Hubertus",
                "label_fr": "Hubertus"
            },
            {
                "value": 9373,
                "label": "Huddleston",
                "label_ar": "هادلستون",
                "label_fr": "Huddleston"
            },
            {
                "value": 9374,
                "label": "Huddy",
                "label_ar": "هدي",
                "label_fr": "Huddy"
            },
            {
                "value": 9375,
                "label": "Hudson",
                "label_ar": "هدسون",
                "label_fr": "Hudson"
            },
            {
                "value": 9376,
                "label": "Hudson Falls",
                "label_ar": "شلالات هدسون",
                "label_fr": "Hudson Falls"
            },
            {
                "value": 9377,
                "label": "Hudsonville",
                "label_ar": "هدسونفيل",
                "label_fr": "Hudsonville"
            },
            {
                "value": 9378,
                "label": "Huffman",
                "label_ar": "هوفمان",
                "label_fr": "Huffman"
            },
            {
                "value": 9379,
                "label": "Huger",
                "label_ar": "هوجر",
                "label_fr": "Huger"
            },
            {
                "value": 9380,
                "label": "Hughes",
                "label_ar": "هيوز",
                "label_fr": "Hughes"
            },
            {
                "value": 9381,
                "label": "Hughes Springs",
                "label_ar": "هيوز سبرينغز",
                "label_fr": "Hughes Springs"
            },
            {
                "value": 9382,
                "label": "Hugheston",
                "label_ar": "هيغستون",
                "label_fr": "Hugheston"
            },
            {
                "value": 9383,
                "label": "Hughesville",
                "label_ar": "هيوزفيل",
                "label_fr": "Hughesville"
            },
            {
                "value": 9384,
                "label": "Hughson",
                "label_ar": "هيغسون",
                "label_fr": "Hughson"
            },
            {
                "value": 9385,
                "label": "Hugo",
                "label_ar": "هوغو",
                "label_fr": "Hugo"
            },
            {
                "value": 9386,
                "label": "Hugoton",
                "label_ar": "هوجوتون",
                "label_fr": "Hugoton"
            },
            {
                "value": 9387,
                "label": "Hulbert",
                "label_ar": "هولبرت",
                "label_fr": "Hulbert"
            },
            {
                "value": 9388,
                "label": "Hulett",
                "label_ar": "هوليت",
                "label_fr": "Hulett"
            },
            {
                "value": 9389,
                "label": "Hull",
                "label_ar": "هال",
                "label_fr": "coque"
            },
            {
                "value": 9390,
                "label": "Humansville",
                "label_ar": "هيومنسفيل",
                "label_fr": "Humansville"
            },
            {
                "value": 9391,
                "label": "Humarock",
                "label_ar": "هوماروك",
                "label_fr": "Humarock"
            },
            {
                "value": 9392,
                "label": "Humbird",
                "label_ar": "هامبيرد",
                "label_fr": "Humbird"
            },
            {
                "value": 9393,
                "label": "Humble",
                "label_ar": "متواضع",
                "label_fr": "Humble"
            },
            {
                "value": 9394,
                "label": "Humboldt",
                "label_ar": "همبولت",
                "label_fr": "Humboldt"
            },
            {
                "value": 9395,
                "label": "Hume",
                "label_ar": "هيوم",
                "label_fr": "Hume"
            },
            {
                "value": 9396,
                "label": "Humeston",
                "label_ar": "هومستون",
                "label_fr": "Humeston"
            },
            {
                "value": 9397,
                "label": "Hummelstown",
                "label_ar": "هاميلستاون",
                "label_fr": "Hummelstown"
            },
            {
                "value": 9398,
                "label": "Humphrey",
                "label_ar": "همفري",
                "label_fr": "Humphrey"
            },
            {
                "value": 9399,
                "label": "Hundred",
                "label_ar": "مائة",
                "label_fr": "Cent"
            },
            {
                "value": 9400,
                "label": "Hungerford",
                "label_ar": "هانجرفورد",
                "label_fr": "Hungerford"
            },
            {
                "value": 9401,
                "label": "Hunker",
                "label_ar": "هونكر",
                "label_fr": "Hunker"
            },
            {
                "value": 9402,
                "label": "Hunlock Creek",
                "label_ar": "هونلوك كريك",
                "label_fr": "Ruisseau Hunlock"
            },
            {
                "value": 9403,
                "label": "Hunt",
                "label_ar": "مطاردة",
                "label_fr": "Chasser"
            },
            {
                "value": 9404,
                "label": "Hunt Valley",
                "label_ar": "هانت فالي",
                "label_fr": "Hunt Valley"
            },
            {
                "value": 9405,
                "label": "Hunter",
                "label_ar": "صياد",
                "label_fr": "chasseur"
            },
            {
                "value": 9406,
                "label": "Huntersville",
                "label_ar": "هانترسفيل",
                "label_fr": "Huntersville"
            },
            {
                "value": 9407,
                "label": "Huntertown",
                "label_ar": "هنترتاون",
                "label_fr": "Huntertown"
            },
            {
                "value": 9408,
                "label": "Huntingburg",
                "label_ar": "هنتنغبرج",
                "label_fr": "Huntingburg"
            },
            {
                "value": 9409,
                "label": "Huntingdon",
                "label_ar": "هانتينجدون",
                "label_fr": "Huntingdon"
            },
            {
                "value": 9410,
                "label": "Huntingdon Valley",
                "label_ar": "وادي هانتينجدون",
                "label_fr": "Vallée de Huntingdon"
            },
            {
                "value": 9411,
                "label": "Huntington",
                "label_ar": "هنتنغتون",
                "label_fr": "Huntington"
            },
            {
                "value": 9412,
                "label": "Huntington Beach",
                "label_ar": "شاطئ هنتنغتون",
                "label_fr": "Plage Huntington"
            },
            {
                "value": 9413,
                "label": "Huntington Mills",
                "label_ar": "هنتنغتون ميلز",
                "label_fr": "Huntington Mills"
            },
            {
                "value": 9414,
                "label": "Huntington Park",
                "label_ar": "هنتنغتون بارك",
                "label_fr": "Parc Huntington"
            },
            {
                "value": 9415,
                "label": "Huntington Station",
                "label_ar": "محطة هنتنغتون",
                "label_fr": "Gare de Huntington"
            },
            {
                "value": 9416,
                "label": "Huntington Woods",
                "label_ar": "هنتنغتون وودز",
                "label_fr": "Huntington Woods"
            },
            {
                "value": 9417,
                "label": "Huntingtown",
                "label_ar": "هنتنغتاون",
                "label_fr": "Huntingtown"
            },
            {
                "value": 9418,
                "label": "Huntland",
                "label_ar": "هنتلاند",
                "label_fr": "Huntland"
            },
            {
                "value": 9419,
                "label": "Huntley",
                "label_ar": "هنتلي",
                "label_fr": "Huntley"
            },
            {
                "value": 9420,
                "label": "Huntsburg",
                "label_ar": "هانتسبيرغ",
                "label_fr": "Huntsburg"
            },
            {
                "value": 9421,
                "label": "Huntsville",
                "label_ar": "هانتسفيل",
                "label_fr": "Huntsville"
            },
            {
                "value": 9422,
                "label": "Hurdland",
                "label_ar": "هوردلاند",
                "label_fr": "Hurdland"
            },
            {
                "value": 9423,
                "label": "Hurdle Mills",
                "label_ar": "هيردل ميلز",
                "label_fr": "Hurdle Mills"
            },
            {
                "value": 9424,
                "label": "Hurley",
                "label_ar": "هيرلي",
                "label_fr": "Hurley"
            },
            {
                "value": 9425,
                "label": "Hurleyville",
                "label_ar": "هيرليفيل",
                "label_fr": "Hurleyville"
            },
            {
                "value": 9426,
                "label": "Hurlock",
                "label_ar": "هيرلوك",
                "label_fr": "Hurlock"
            },
            {
                "value": 9427,
                "label": "Huron",
                "label_ar": "هورون",
                "label_fr": "Huron"
            },
            {
                "value": 9428,
                "label": "Hurricane",
                "label_ar": "اعصار",
                "label_fr": "ouragan"
            },
            {
                "value": 9429,
                "label": "Hurst",
                "label_ar": "هيرست",
                "label_fr": "Hurst"
            },
            {
                "value": 9430,
                "label": "Hurt",
                "label_ar": "جرح",
                "label_fr": "Blesser"
            },
            {
                "value": 9431,
                "label": "Hurtsboro",
                "label_ar": "هورتسبورو",
                "label_fr": "Hurtsboro"
            },
            {
                "value": 9432,
                "label": "Huson",
                "label_ar": "حسون",
                "label_fr": "Huson"
            },
            {
                "value": 9433,
                "label": "Hustisford",
                "label_ar": "هوستيسفورد",
                "label_fr": "Hustisford"
            },
            {
                "value": 9434,
                "label": "Hustler",
                "label_ar": "محتال",
                "label_fr": "Hustler"
            },
            {
                "value": 9435,
                "label": "Hustontown",
                "label_ar": "Hustontown",
                "label_fr": "Hustontown"
            },
            {
                "value": 9436,
                "label": "Hustonville",
                "label_ar": "هيستونفيل",
                "label_fr": "Hustonville"
            },
            {
                "value": 9437,
                "label": "Husum",
                "label_ar": "هوسوم",
                "label_fr": "Husum"
            },
            {
                "value": 9438,
                "label": "Hutchins",
                "label_ar": "هتشينز",
                "label_fr": "Hutchins"
            },
            {
                "value": 9439,
                "label": "Hutchinson",
                "label_ar": "هاتشينسون",
                "label_fr": "Hutchinson"
            },
            {
                "value": 9440,
                "label": "Huttig",
                "label_ar": "هوتيج",
                "label_fr": "Huttig"
            },
            {
                "value": 9441,
                "label": "Hutto",
                "label_ar": "هوتو",
                "label_fr": "Hutto"
            },
            {
                "value": 9442,
                "label": "Huttonsville",
                "label_ar": "هوتونسفيل",
                "label_fr": "Huttonsville"
            },
            {
                "value": 9443,
                "label": "Huxley",
                "label_ar": "هكسلي",
                "label_fr": "Huxley"
            },
            {
                "value": 9444,
                "label": "Hyampom",
                "label_ar": "هيامبوم",
                "label_fr": "Hyampom"
            },
            {
                "value": 9445,
                "label": "Hyannis",
                "label_ar": "هيانيس",
                "label_fr": "Hyannis"
            },
            {
                "value": 9446,
                "label": "Hyattsville",
                "label_ar": "هياتسفيل",
                "label_fr": "Hyattsville"
            },
            {
                "value": 9447,
                "label": "Hyde",
                "label_ar": "هايد",
                "label_fr": "Hyde"
            },
            {
                "value": 9448,
                "label": "Hyde Park",
                "label_ar": "هايد بارك",
                "label_fr": "Hyde Park"
            },
            {
                "value": 9449,
                "label": "Hyden",
                "label_ar": "هيدن",
                "label_fr": "Hyden"
            },
            {
                "value": 9450,
                "label": "Hydes",
                "label_ar": "هيدس",
                "label_fr": "Hydes"
            },
            {
                "value": 9451,
                "label": "Hydesville",
                "label_ar": "هيدسفيل",
                "label_fr": "Hydesville"
            },
            {
                "value": 9452,
                "label": "Hydeville",
                "label_ar": "هيدفيل",
                "label_fr": "Hydeville"
            },
            {
                "value": 9453,
                "label": "Hydro",
                "label_ar": "هيدرو",
                "label_fr": "Hydro"
            },
            {
                "value": 9454,
                "label": "Hygiene",
                "label_ar": "النظافة",
                "label_fr": "Hygiène"
            },
            {
                "value": 9455,
                "label": "Hymera",
                "label_ar": "هيميرا",
                "label_fr": "Hymera"
            },
            {
                "value": 9456,
                "label": "Hyndman",
                "label_ar": "هيندمان",
                "label_fr": "Hyndman"
            },
            {
                "value": 9457,
                "label": "Hyrum",
                "label_ar": "Hyrum",
                "label_fr": "Hyrum"
            },
            {
                "value": 9458,
                "label": "Hysham",
                "label_ar": "هشام",
                "label_fr": "Hysham"
            },
            {
                "value": 9459,
                "label": "Iaeger",
                "label_ar": "ايجر",
                "label_fr": "Iaeger"
            },
            {
                "value": 9460,
                "label": "Iberia",
                "label_ar": "ايبيريا",
                "label_fr": "Ibérie"
            },
            {
                "value": 9461,
                "label": "Ickesburg",
                "label_ar": "إيكسبيرغ",
                "label_fr": "Ickesburg"
            },
            {
                "value": 9462,
                "label": "Ida",
                "label_ar": "إيدا",
                "label_fr": "Ida"
            },
            {
                "value": 9463,
                "label": "Ida Grove",
                "label_ar": "إيدا جروف",
                "label_fr": "Ida Grove"
            },
            {
                "value": 9464,
                "label": "Idabel",
                "label_ar": "ايدابيل",
                "label_fr": "Idabel"
            },
            {
                "value": 9465,
                "label": "Idaho Falls",
                "label_ar": "ايداهو فولز",
                "label_fr": "Idaho Falls"
            },
            {
                "value": 9466,
                "label": "Idaho Springs",
                "label_ar": "ايداهو سبرينغز",
                "label_fr": "Idaho Springs"
            },
            {
                "value": 9467,
                "label": "Idalia",
                "label_ar": "إيداليا",
                "label_fr": "Idalia"
            },
            {
                "value": 9468,
                "label": "Idalou",
                "label_ar": "ايدالو",
                "label_fr": "Idalou"
            },
            {
                "value": 9469,
                "label": "Idaville",
                "label_ar": "إيدافيل",
                "label_fr": "Idaville"
            },
            {
                "value": 9470,
                "label": "Ider",
                "label_ar": "ايدر",
                "label_fr": "Ider"
            },
            {
                "value": 9471,
                "label": "Idledale",
                "label_ar": "Idledale",
                "label_fr": "Idledale"
            },
            {
                "value": 9472,
                "label": "Idyllwild",
                "label_ar": "Idyllwild",
                "label_fr": "Idyllwild"
            },
            {
                "value": 9473,
                "label": "Ignacio",
                "label_ar": "اجناسيو",
                "label_fr": "Ignacio"
            },
            {
                "value": 9474,
                "label": "Igo",
                "label_ar": "انا اذهب",
                "label_fr": "Je vais"
            },
            {
                "value": 9475,
                "label": "Ijamsville",
                "label_ar": "إيجامسفيل",
                "label_fr": "Ijamsville"
            },
            {
                "value": 9476,
                "label": "Iliff",
                "label_ar": "إليف",
                "label_fr": "Iliff"
            },
            {
                "value": 9477,
                "label": "Ilion",
                "label_ar": "إليون",
                "label_fr": "Ilion"
            },
            {
                "value": 9478,
                "label": "Imlay City",
                "label_ar": "مدينة Imlay",
                "label_fr": "Ville d'Imlay"
            },
            {
                "value": 9479,
                "label": "Imler",
                "label_ar": "إيملر",
                "label_fr": "Imler"
            },
            {
                "value": 9480,
                "label": "Immaculata",
                "label_ar": "إيماكولاتا",
                "label_fr": "Immaculée"
            },
            {
                "value": 9481,
                "label": "Immokalee",
                "label_ar": "إيموكالي",
                "label_fr": "Immokalee"
            },
            {
                "value": 9482,
                "label": "Imogene",
                "label_ar": "إيموجين",
                "label_fr": "Imogène"
            },
            {
                "value": 9483,
                "label": "Imperial",
                "label_ar": "إمبراطوري",
                "label_fr": "Impérial"
            },
            {
                "value": 9484,
                "label": "Imperial Beach",
                "label_ar": "امبريال بيتش",
                "label_fr": "Plage impériale"
            },
            {
                "value": 9485,
                "label": "Ina",
                "label_ar": "في",
                "label_fr": "Dans un"
            },
            {
                "value": 9486,
                "label": "Inchelium",
                "label_ar": "إنشليوم",
                "label_fr": "Inchelium"
            },
            {
                "value": 9487,
                "label": "Incline Village",
                "label_ar": "قرية منحدرة",
                "label_fr": "Village incliné"
            },
            {
                "value": 9488,
                "label": "Independence",
                "label_ar": "استقلال",
                "label_fr": "Indépendance"
            },
            {
                "value": 9489,
                "label": "India",
                "label_ar": "الهند",
                "label_fr": "Inde"
            },
            {
                "value": 9490,
                "label": "Indialantic",
                "label_ar": "إنديالانتيكي",
                "label_fr": "Indialantic"
            },
            {
                "value": 9491,
                "label": "Indian Head",
                "label_ar": "رئيس هندي",
                "label_fr": "Tête indienne"
            },
            {
                "value": 9492,
                "label": "Indian Hills",
                "label_ar": "التلال الهندية",
                "label_fr": "Indian Hills"
            },
            {
                "value": 9493,
                "label": "Indian Lake",
                "label_ar": "البحيرة الهندية",
                "label_fr": "Indian Lake"
            },
            {
                "value": 9494,
                "label": "Indian Mound",
                "label_ar": "التلة الهندية",
                "label_fr": "Monticule indien"
            },
            {
                "value": 9495,
                "label": "Indian Orchard",
                "label_ar": "البستان الهندي",
                "label_fr": "Verger indien"
            },
            {
                "value": 9496,
                "label": "Indian River",
                "label_ar": "النهر الهندي",
                "label_fr": "Indian River"
            },
            {
                "value": 9497,
                "label": "Indian Rocks Beach",
                "label_ar": "إنديان روكس بيتش",
                "label_fr": "Plage d'Indian Rocks"
            },
            {
                "value": 9498,
                "label": "Indian Trail",
                "label_ar": "الممر الهندي",
                "label_fr": "Sentier indien"
            },
            {
                "value": 9499,
                "label": "Indian Wells",
                "label_ar": "الآبار الهندية",
                "label_fr": "biens indiens"
            },
            {
                "value": 9500,
                "label": "Indiana",
                "label_ar": "إنديانا",
                "label_fr": "Indiana"
            },
            {
                "value": 9501,
                "label": "Indianapolis",
                "label_ar": "إنديانابوليس",
                "label_fr": "Indianapolis"
            },
            {
                "value": 9502,
                "label": "Indianola",
                "label_ar": "إنديانولا",
                "label_fr": "Indianola"
            },
            {
                "value": 9503,
                "label": "Indiantown",
                "label_ar": "إنديانتاون",
                "label_fr": "Indiantown"
            },
            {
                "value": 9504,
                "label": "Indio",
                "label_ar": "إنديو",
                "label_fr": "Indio"
            },
            {
                "value": 9505,
                "label": "Industry",
                "label_ar": "صناعة",
                "label_fr": "Industrie"
            },
            {
                "value": 9506,
                "label": "Inez",
                "label_ar": "إينيز",
                "label_fr": "Inez"
            },
            {
                "value": 9507,
                "label": "Ingalls",
                "label_ar": "إينغلس",
                "label_fr": "Ingalls"
            },
            {
                "value": 9508,
                "label": "Ingleside",
                "label_ar": "إنجلسايد",
                "label_fr": "Ingleside"
            },
            {
                "value": 9509,
                "label": "Inglewood",
                "label_ar": "إنجلوود",
                "label_fr": "Inglewood"
            },
            {
                "value": 9510,
                "label": "Inglis",
                "label_ar": "إنجليس",
                "label_fr": "Inglis"
            },
            {
                "value": 9511,
                "label": "Ingomar",
                "label_ar": "إنجومار",
                "label_fr": "Ingomar"
            },
            {
                "value": 9512,
                "label": "Ingraham",
                "label_ar": "إنغراهام",
                "label_fr": "Ingraham"
            },
            {
                "value": 9513,
                "label": "Ingram",
                "label_ar": "انجرام",
                "label_fr": "Ingram"
            },
            {
                "value": 9514,
                "label": "Inkom",
                "label_ar": "إنكوم",
                "label_fr": "Inkom"
            },
            {
                "value": 9515,
                "label": "Inkster",
                "label_ar": "إنكستر",
                "label_fr": "Inkster"
            },
            {
                "value": 9516,
                "label": "Inland",
                "label_ar": "الداخلية",
                "label_fr": "Intérieur"
            },
            {
                "value": 9517,
                "label": "Inman",
                "label_ar": "في رجل",
                "label_fr": "Dans homme"
            },
            {
                "value": 9518,
                "label": "Inola",
                "label_ar": "إينولا",
                "label_fr": "Inola"
            },
            {
                "value": 9519,
                "label": "Institute",
                "label_ar": "معهد",
                "label_fr": "Institut"
            },
            {
                "value": 9520,
                "label": "Intercession City",
                "label_ar": "مدينة الشفاعة",
                "label_fr": "Cité de l'Intercession"
            },
            {
                "value": 9521,
                "label": "Intercourse",
                "label_ar": "الجماع",
                "label_fr": "Rapports"
            },
            {
                "value": 9522,
                "label": "Interlachen",
                "label_ar": "إنترلاشن",
                "label_fr": "Interlachen"
            },
            {
                "value": 9523,
                "label": "Interlaken",
                "label_ar": "إنترلاكن",
                "label_fr": "Interlaken"
            },
            {
                "value": 9524,
                "label": "Interlochen",
                "label_ar": "إنترلوشين",
                "label_fr": "Interlochen"
            },
            {
                "value": 9525,
                "label": "International Falls",
                "label_ar": "الشلالات الدولية",
                "label_fr": "Chutes internationales"
            },
            {
                "value": 9526,
                "label": "Inver Grove Heights",
                "label_ar": "إنفر غروف هايتس",
                "label_fr": "Inver Grove Heights"
            },
            {
                "value": 9527,
                "label": "Inverness",
                "label_ar": "إينفيرنيس",
                "label_fr": "Inverness"
            },
            {
                "value": 9528,
                "label": "Inwood",
                "label_ar": "في الخشب",
                "label_fr": "Inwood"
            },
            {
                "value": 9529,
                "label": "Inyokern",
                "label_ar": "إينوكيرن",
                "label_fr": "Inyokern"
            },
            {
                "value": 9530,
                "label": "Iola",
                "label_ar": "إيولا",
                "label_fr": "Iola"
            },
            {
                "value": 9531,
                "label": "Ione",
                "label_ar": "ايوني",
                "label_fr": "Ione"
            },
            {
                "value": 9532,
                "label": "Ionia",
                "label_ar": "ايونيا",
                "label_fr": "Ionia"
            },
            {
                "value": 9533,
                "label": "Iota",
                "label_ar": "ذرة",
                "label_fr": "Iota"
            },
            {
                "value": 9534,
                "label": "Iowa",
                "label_ar": "ايوا",
                "label_fr": "Iowa"
            },
            {
                "value": 9535,
                "label": "Iowa City",
                "label_ar": "مدينة آيوا",
                "label_fr": "Iowa City"
            },
            {
                "value": 9536,
                "label": "Iowa Falls",
                "label_ar": "شلالات آيوا",
                "label_fr": "Chutes d'Iowa"
            },
            {
                "value": 9537,
                "label": "Iowa Park",
                "label_ar": "آيوا بارك",
                "label_fr": "Parc de l'Iowa"
            },
            {
                "value": 9538,
                "label": "Ipswich",
                "label_ar": "إبسويتش",
                "label_fr": "Ipswich"
            },
            {
                "value": 9539,
                "label": "Iraan",
                "label_ar": "إيران",
                "label_fr": "Iraan"
            },
            {
                "value": 9540,
                "label": "Irasburg",
                "label_ar": "ايراسبورغ",
                "label_fr": "Irasburg"
            },
            {
                "value": 9541,
                "label": "Iredell",
                "label_ar": "إيريدل",
                "label_fr": "Iredell"
            },
            {
                "value": 9542,
                "label": "Ireland",
                "label_ar": "أيرلندا",
                "label_fr": "Irlande"
            },
            {
                "value": 9543,
                "label": "Irene",
                "label_ar": "ايرين",
                "label_fr": "Irène"
            },
            {
                "value": 9544,
                "label": "Ireton",
                "label_ar": "إريتون",
                "label_fr": "Ireton"
            },
            {
                "value": 9545,
                "label": "Irma",
                "label_ar": "إيرما",
                "label_fr": "Irma"
            },
            {
                "value": 9546,
                "label": "Irmo",
                "label_ar": "إيرمو",
                "label_fr": "Irmo"
            },
            {
                "value": 9547,
                "label": "Iron City",
                "label_ar": "مدينة الحديد",
                "label_fr": "Iron City"
            },
            {
                "value": 9548,
                "label": "Iron Junction",
                "label_ar": "تقاطع الحديد",
                "label_fr": "Jonction de fer"
            },
            {
                "value": 9549,
                "label": "Iron Mountain",
                "label_ar": "جبل الحديد",
                "label_fr": "Montagne de fer"
            },
            {
                "value": 9550,
                "label": "Iron Ridge",
                "label_ar": "حديد ريدج",
                "label_fr": "Iron Ridge"
            },
            {
                "value": 9551,
                "label": "Iron River",
                "label_ar": "نهر الحديد",
                "label_fr": "Iron River"
            },
            {
                "value": 9552,
                "label": "Iron Station",
                "label_ar": "محطة حديد",
                "label_fr": "Station de fer"
            },
            {
                "value": 9553,
                "label": "Irons",
                "label_ar": "مكاوي",
                "label_fr": "Fers"
            },
            {
                "value": 9554,
                "label": "Ironton",
                "label_ar": "ايرونتون",
                "label_fr": "Ironton"
            },
            {
                "value": 9555,
                "label": "Ironwood",
                "label_ar": "أيرونوود",
                "label_fr": "Ironwood"
            },
            {
                "value": 9556,
                "label": "Iroquois",
                "label_ar": "إيروكوا",
                "label_fr": "Iroquois"
            },
            {
                "value": 9557,
                "label": "Irrigon",
                "label_ar": "إيريجون",
                "label_fr": "Irrigon"
            },
            {
                "value": 9558,
                "label": "Irvine",
                "label_ar": "ايرفين",
                "label_fr": "Irvine"
            },
            {
                "value": 9559,
                "label": "Irving",
                "label_ar": "ايرفينغ",
                "label_fr": "Irving"
            },
            {
                "value": 9560,
                "label": "Irvington",
                "label_ar": "ايرفينغتون",
                "label_fr": "Irvington"
            },
            {
                "value": 9561,
                "label": "Irwin",
                "label_ar": "اروين",
                "label_fr": "Irwin"
            },
            {
                "value": 9562,
                "label": "Irwinton",
                "label_ar": "ايروينتون",
                "label_fr": "Irwinton"
            },
            {
                "value": 9563,
                "label": "Isabel",
                "label_ar": "إيزابيل",
                "label_fr": "Isabel"
            },
            {
                "value": 9564,
                "label": "Isabella",
                "label_ar": "إيزابيلا",
                "label_fr": "Isabelle"
            },
            {
                "value": 9565,
                "label": "Isanti",
                "label_ar": "إيسانتي",
                "label_fr": "Isanti"
            },
            {
                "value": 9566,
                "label": "Iselin",
                "label_ar": "Iselin",
                "label_fr": "Iselin"
            },
            {
                "value": 9567,
                "label": "Ishpeming",
                "label_ar": "Ishpeming",
                "label_fr": "Ishpeming"
            },
            {
                "value": 9568,
                "label": "Islamorada",
                "label_ar": "إسلامورادا",
                "label_fr": "Islamorada"
            },
            {
                "value": 9569,
                "label": "Island",
                "label_ar": "جزيرة",
                "label_fr": "Île"
            },
            {
                "value": 9570,
                "label": "Island Falls",
                "label_ar": "شلالات الجزيرة",
                "label_fr": "Island Falls"
            },
            {
                "value": 9571,
                "label": "Island Heights",
                "label_ar": "مرتفعات الجزيرة",
                "label_fr": "Island Heights"
            },
            {
                "value": 9572,
                "label": "Island Lake",
                "label_ar": "بحيرة الجزيرة",
                "label_fr": "Island Lake"
            },
            {
                "value": 9573,
                "label": "Island Park",
                "label_ar": "آيلاند بارك",
                "label_fr": "Parc de l'île"
            },
            {
                "value": 9574,
                "label": "Islandia",
                "label_ar": "ايلانديا",
                "label_fr": "Islandia"
            },
            {
                "value": 9575,
                "label": "Islandton",
                "label_ar": "آيلندتون",
                "label_fr": "Islandton"
            },
            {
                "value": 9576,
                "label": "Isle",
                "label_ar": "جزيرة",
                "label_fr": "Île"
            },
            {
                "value": 9577,
                "label": "Isle of Palms",
                "label_ar": "جزيرة النخيل",
                "label_fr": "Île des palmiers"
            },
            {
                "value": 9578,
                "label": "Isle of Wight",
                "label_ar": "جزيرة وايت",
                "label_fr": "l'île de Wight"
            },
            {
                "value": 9579,
                "label": "Islesboro",
                "label_ar": "ايلسبورو",
                "label_fr": "Islesboro"
            },
            {
                "value": 9580,
                "label": "Islesford",
                "label_ar": "Islesford",
                "label_fr": "Islesford"
            },
            {
                "value": 9581,
                "label": "Isleta",
                "label_ar": "جزيرة",
                "label_fr": "Isleta"
            },
            {
                "value": 9582,
                "label": "Isleton",
                "label_ar": "ايسلتون",
                "label_fr": "Isleton"
            },
            {
                "value": 9583,
                "label": "Islip",
                "label_ar": "انزلقت",
                "label_fr": "Je glisse"
            },
            {
                "value": 9584,
                "label": "Islip Terrace",
                "label_ar": "إسليب تراس",
                "label_fr": "Terrasse Islip"
            },
            {
                "value": 9585,
                "label": "Ismay",
                "label_ar": "إسماي",
                "label_fr": "C'est peut"
            },
            {
                "value": 9586,
                "label": "Issaquah",
                "label_ar": "Issaquah",
                "label_fr": "Issaquah"
            },
            {
                "value": 9587,
                "label": "Italia",
                "label_ar": "ايطاليا",
                "label_fr": "Italie"
            },
            {
                "value": 9588,
                "label": "Italy",
                "label_ar": "إيطاليا",
                "label_fr": "Italie"
            },
            {
                "value": 9589,
                "label": "Itasca",
                "label_ar": "إتاسكا",
                "label_fr": "Itasca"
            },
            {
                "value": 9590,
                "label": "Ithaca",
                "label_ar": "إيثاكا",
                "label_fr": "Ithaque"
            },
            {
                "value": 9591,
                "label": "Itmann",
                "label_ar": "ايتمان",
                "label_fr": "Itmann"
            },
            {
                "value": 9592,
                "label": "Itta Bena",
                "label_ar": "إيتا بينا",
                "label_fr": "Itta Bena"
            },
            {
                "value": 9593,
                "label": "Iuka",
                "label_ar": "يوكا",
                "label_fr": "Iuka"
            },
            {
                "value": 9594,
                "label": "Iva",
                "label_ar": "إيفا",
                "label_fr": "Iva"
            },
            {
                "value": 9595,
                "label": "Ivanhoe",
                "label_ar": "إيفانهو",
                "label_fr": "Ivanhoé"
            },
            {
                "value": 9596,
                "label": "Ivel",
                "label_ar": "ايفيل",
                "label_fr": "Ivel"
            },
            {
                "value": 9597,
                "label": "Ivins",
                "label_ar": "ايفينز",
                "label_fr": "Ivins"
            },
            {
                "value": 9598,
                "label": "Ivor",
                "label_ar": "ايفور",
                "label_fr": "Ivor"
            },
            {
                "value": 9599,
                "label": "Ivoryton",
                "label_ar": "ايفوريتون",
                "label_fr": "Ivoryton"
            },
            {
                "value": 9600,
                "label": "Ivydale",
                "label_ar": "ايفيدال",
                "label_fr": "Ivydale"
            },
            {
                "value": 9601,
                "label": "Ixonia",
                "label_ar": "اكسونيا",
                "label_fr": "Ixonia"
            },
            {
                "value": 9602,
                "label": "Jack",
                "label_ar": "جاك",
                "label_fr": "Jack"
            },
            {
                "value": 9603,
                "label": "Jackpot",
                "label_ar": "الفوز بالجائزة الكبرى",
                "label_fr": "Cagnotte"
            },
            {
                "value": 9604,
                "label": "Jacks Creek",
                "label_ar": "جاكس كريك",
                "label_fr": "Jacks Creek"
            },
            {
                "value": 9605,
                "label": "Jacksboro",
                "label_ar": "جاكسبورو",
                "label_fr": "Jacksboro"
            },
            {
                "value": 9606,
                "label": "Jackson",
                "label_ar": "جاكسون",
                "label_fr": "Jackson"
            },
            {
                "value": 9607,
                "label": "Jackson Center",
                "label_ar": "مركز جاكسون",
                "label_fr": "Jackson Center"
            },
            {
                "value": 9608,
                "label": "Jackson Heights",
                "label_ar": "مرتفعات جاكسون",
                "label_fr": "Jackson Heights"
            },
            {
                "value": 9609,
                "label": "Jackson Township",
                "label_ar": "بلدة جاكسون",
                "label_fr": "Canton de Jackson"
            },
            {
                "value": 9610,
                "label": "Jacksonboro",
                "label_ar": "جاكسونبورو",
                "label_fr": "Jacksonboro"
            },
            {
                "value": 9611,
                "label": "Jacksonburg",
                "label_ar": "جاكسونبرج",
                "label_fr": "Jacksonburg"
            },
            {
                "value": 9612,
                "label": "Jacksons Gap",
                "label_ar": "Jacksons Gap",
                "label_fr": "Jacksons Gap"
            },
            {
                "value": 9613,
                "label": "Jacksonville",
                "label_ar": "جاكسونفيل",
                "label_fr": "Jacksonville"
            },
            {
                "value": 9614,
                "label": "Jacksonville Beach",
                "label_ar": "جاكسونفيل بيتش",
                "label_fr": "Jacksonville Beach"
            },
            {
                "value": 9615,
                "label": "Jacobs Creek",
                "label_ar": "جاكوبس كريك",
                "label_fr": "Jacobs Creek"
            },
            {
                "value": 9616,
                "label": "Jacobsburg",
                "label_ar": "جاكوبسبرج",
                "label_fr": "Jacobsburg"
            },
            {
                "value": 9617,
                "label": "Jacobson",
                "label_ar": "جاكوبسون",
                "label_fr": "Jacobson"
            },
            {
                "value": 9618,
                "label": "Jacumba Hot Springs",
                "label_ar": "جاكومبا هوت سبرينغز",
                "label_fr": "Jacumba Hot Springs"
            },
            {
                "value": 9619,
                "label": "Jaffrey",
                "label_ar": "جافري",
                "label_fr": "Jaffrey"
            },
            {
                "value": 9620,
                "label": "Jal",
                "label_ar": "جال",
                "label_fr": "Jal"
            },
            {
                "value": 9621,
                "label": "Jamaica",
                "label_ar": "جامايكا",
                "label_fr": "Jamaïque"
            },
            {
                "value": 9622,
                "label": "Jamaica Plain",
                "label_ar": "سهل جامايكا",
                "label_fr": "Jamaïque Plain"
            },
            {
                "value": 9623,
                "label": "Jameson",
                "label_ar": "جيمسون",
                "label_fr": "Jameson"
            },
            {
                "value": 9624,
                "label": "Jamesport",
                "label_ar": "جيمسبورت",
                "label_fr": "Jamesport"
            },
            {
                "value": 9625,
                "label": "Jamestown",
                "label_ar": "جيمستاون",
                "label_fr": "Jamestown"
            },
            {
                "value": 9626,
                "label": "Jamesville",
                "label_ar": "جيمسفيل",
                "label_fr": "Jamesville"
            },
            {
                "value": 9627,
                "label": "Jamison",
                "label_ar": "جاميسون",
                "label_fr": "Jamison"
            },
            {
                "value": 9628,
                "label": "Jamul",
                "label_ar": "Jamul",
                "label_fr": "Jamul"
            },
            {
                "value": 9629,
                "label": "Jane Lew",
                "label_ar": "جين لو",
                "label_fr": "Jane Lew"
            },
            {
                "value": 9630,
                "label": "Janesville",
                "label_ar": "جينسفيل",
                "label_fr": "Janesville"
            },
            {
                "value": 9631,
                "label": "Jaroso",
                "label_ar": "جاروسو",
                "label_fr": "Jaroso"
            },
            {
                "value": 9632,
                "label": "Jarreau",
                "label_ar": "جارو",
                "label_fr": "Jarreau"
            },
            {
                "value": 9633,
                "label": "Jarrell",
                "label_ar": "جاريل",
                "label_fr": "Jarrell"
            },
            {
                "value": 9634,
                "label": "Jarrettsville",
                "label_ar": "جاريتسفيل",
                "label_fr": "Jarrettsville"
            },
            {
                "value": 9635,
                "label": "Jarvisburg",
                "label_ar": "جارفيسبرج",
                "label_fr": "Jarvisburg"
            },
            {
                "value": 9636,
                "label": "Jasonville",
                "label_ar": "جايسونفيل",
                "label_fr": "Jasonville"
            },
            {
                "value": 9637,
                "label": "Jasper",
                "label_ar": "يشب",
                "label_fr": "Jaspe"
            },
            {
                "value": 9638,
                "label": "Jay",
                "label_ar": "جاي",
                "label_fr": "Geai"
            },
            {
                "value": 9639,
                "label": "Jayess",
                "label_ar": "جايس",
                "label_fr": "Jayess"
            },
            {
                "value": 9640,
                "label": "Jayton",
                "label_ar": "جايتون",
                "label_fr": "Jayton"
            },
            {
                "value": 9641,
                "label": "Jean",
                "label_ar": "جان",
                "label_fr": "Jean"
            },
            {
                "value": 9642,
                "label": "Jeanerette",
                "label_ar": "جينيريت",
                "label_fr": "Jeanerette"
            },
            {
                "value": 9643,
                "label": "Jeannette",
                "label_ar": "جانيت",
                "label_fr": "Jeannette"
            },
            {
                "value": 9644,
                "label": "Jeddo",
                "label_ar": "جدو",
                "label_fr": "Jeddo"
            },
            {
                "value": 9645,
                "label": "Jeff",
                "label_ar": "جيف",
                "label_fr": "Jeff"
            },
            {
                "value": 9646,
                "label": "Jeffers",
                "label_ar": "جيفرز",
                "label_fr": "Jeffers"
            },
            {
                "value": 9647,
                "label": "Jefferson",
                "label_ar": "جيفرسون",
                "label_fr": "Jefferson"
            },
            {
                "value": 9648,
                "label": "Jefferson City",
                "label_ar": "جيفرسون سيتي",
                "label_fr": "Jefferson City"
            },
            {
                "value": 9649,
                "label": "Jefferson Valley",
                "label_ar": "جيفرسون فالي",
                "label_fr": "Jefferson Valley"
            },
            {
                "value": 9650,
                "label": "Jeffersonton",
                "label_ar": "جيفرسونتون",
                "label_fr": "Jeffersonton"
            },
            {
                "value": 9651,
                "label": "Jeffersonville",
                "label_ar": "جيفرسونفيل",
                "label_fr": "Jeffersonville"
            },
            {
                "value": 9652,
                "label": "Jeffrey",
                "label_ar": "جيفري",
                "label_fr": "Jeffrey"
            },
            {
                "value": 9653,
                "label": "Jekyll Island",
                "label_ar": "جزيرة جيكل",
                "label_fr": "Île Jekyll"
            },
            {
                "value": 9654,
                "label": "Jellico",
                "label_ar": "جيليكو",
                "label_fr": "Jellico"
            },
            {
                "value": 9655,
                "label": "Jemez Pueblo",
                "label_ar": "جيميز بويبلو",
                "label_fr": "Jemez Pueblo"
            },
            {
                "value": 9656,
                "label": "Jemez Springs",
                "label_ar": "جميز سبرينجز",
                "label_fr": "Jemez Springs"
            },
            {
                "value": 9657,
                "label": "Jemison",
                "label_ar": "جيميسون",
                "label_fr": "Jemison"
            },
            {
                "value": 9658,
                "label": "Jena",
                "label_ar": "جينا",
                "label_fr": "Iéna"
            },
            {
                "value": 9659,
                "label": "Jenison",
                "label_ar": "جينيسون",
                "label_fr": "Jenison"
            },
            {
                "value": 9660,
                "label": "Jenkins",
                "label_ar": "جينكينز",
                "label_fr": "Jenkins"
            },
            {
                "value": 9661,
                "label": "Jenkinsburg",
                "label_ar": "جينكينسبيرغ",
                "label_fr": "Jenkinsburg"
            },
            {
                "value": 9662,
                "label": "Jenkinsville",
                "label_ar": "جينكينزفيل",
                "label_fr": "Jenkinsville"
            },
            {
                "value": 9663,
                "label": "Jenkintown",
                "label_ar": "جينكينتاون",
                "label_fr": "Jenkintown"
            },
            {
                "value": 9664,
                "label": "Jenks",
                "label_ar": "جنكس",
                "label_fr": "Jenks"
            },
            {
                "value": 9665,
                "label": "Jenner",
                "label_ar": "جينر",
                "label_fr": "Jenner"
            },
            {
                "value": 9666,
                "label": "Jennerstown",
                "label_ar": "جينيرستاون",
                "label_fr": "Jennerstown"
            },
            {
                "value": 9667,
                "label": "Jennings",
                "label_ar": "جينينغز",
                "label_fr": "Jennings"
            },
            {
                "value": 9668,
                "label": "Jensen Beach",
                "label_ar": "شاطئ جنسن",
                "label_fr": "Plage de Jensen"
            },
            {
                "value": 9669,
                "label": "Jeremiah",
                "label_ar": "ارميا",
                "label_fr": "Jérémie"
            },
            {
                "value": 9670,
                "label": "Jericho",
                "label_ar": "أريحا",
                "label_fr": "Jéricho"
            },
            {
                "value": 9671,
                "label": "Jerico Springs",
                "label_ar": "جيريكو سبرينغز",
                "label_fr": "Jerico Springs"
            },
            {
                "value": 9672,
                "label": "Jermyn",
                "label_ar": "جيرمين",
                "label_fr": "Jermyn"
            },
            {
                "value": 9673,
                "label": "Jerome",
                "label_ar": "جيروم",
                "label_fr": "Jérôme"
            },
            {
                "value": 9674,
                "label": "Jersey",
                "label_ar": "جيرسي",
                "label_fr": "Jersey"
            },
            {
                "value": 9675,
                "label": "Jersey City",
                "label_ar": "جيرسي سيتي",
                "label_fr": "Jersey City"
            },
            {
                "value": 9676,
                "label": "Jersey Shore",
                "label_ar": "جيرسي شور",
                "label_fr": "Jersey Shore"
            },
            {
                "value": 9677,
                "label": "Jerseyville",
                "label_ar": "جيرسيفيل",
                "label_fr": "Jerseyville"
            },
            {
                "value": 9678,
                "label": "Jerusalem",
                "label_ar": "بيت المقدس",
                "label_fr": "Jérusalem"
            },
            {
                "value": 9679,
                "label": "Jessup",
                "label_ar": "جيسوب",
                "label_fr": "Jessup"
            },
            {
                "value": 9680,
                "label": "Jesup",
                "label_ar": "جيسوب",
                "label_fr": "Jesup"
            },
            {
                "value": 9681,
                "label": "Jet",
                "label_ar": "طائرة نفاثة",
                "label_fr": "Jet"
            },
            {
                "value": 9682,
                "label": "Jetersville",
                "label_ar": "جيترزفيل",
                "label_fr": "Jetersville"
            },
            {
                "value": 9683,
                "label": "Jetmore",
                "label_ar": "جيتمور",
                "label_fr": "Jetmore"
            },
            {
                "value": 9684,
                "label": "Jewell",
                "label_ar": "جيويل",
                "label_fr": "Jewell"
            },
            {
                "value": 9685,
                "label": "Jewett",
                "label_ar": "جيويت",
                "label_fr": "Jewett"
            },
            {
                "value": 9686,
                "label": "Jewett City",
                "label_ar": "جيويت سيتي",
                "label_fr": "Jewett City"
            },
            {
                "value": 9687,
                "label": "Jim Falls",
                "label_ar": "جيم فولز",
                "label_fr": "Jim Falls"
            },
            {
                "value": 9688,
                "label": "Jim Thorpe",
                "label_ar": "جيم ثورب",
                "label_fr": "Jim Thorpe"
            },
            {
                "value": 9689,
                "label": "Joanna",
                "label_ar": "جوانا",
                "label_fr": "Joanna"
            },
            {
                "value": 9690,
                "label": "Joaquin",
                "label_ar": "جواكين",
                "label_fr": "Joaquin"
            },
            {
                "value": 9691,
                "label": "Jobstown",
                "label_ar": "جوبستاون",
                "label_fr": "Jobstown"
            },
            {
                "value": 9692,
                "label": "Joelton",
                "label_ar": "جويلتون",
                "label_fr": "Joelton"
            },
            {
                "value": 9693,
                "label": "Joes",
                "label_ar": "جو",
                "label_fr": "Joes"
            },
            {
                "value": 9694,
                "label": "Johannesburg",
                "label_ar": "جوهانسبرج",
                "label_fr": "Johannesburg"
            },
            {
                "value": 9695,
                "label": "John Day",
                "label_ar": "جون داي",
                "label_fr": "John Day"
            },
            {
                "value": 9696,
                "label": "Johns Island",
                "label_ar": "جزيرة جونز",
                "label_fr": "Île Johns"
            },
            {
                "value": 9697,
                "label": "Johnson",
                "label_ar": "جونسون",
                "label_fr": "Johnson"
            },
            {
                "value": 9698,
                "label": "Johnson City",
                "label_ar": "جونسون سيتي",
                "label_fr": "Johnson City"
            },
            {
                "value": 9699,
                "label": "Johnson Creek",
                "label_ar": "جونسون كريك",
                "label_fr": "Johnson Creek"
            },
            {
                "value": 9700,
                "label": "Johnsonburg",
                "label_ar": "جونسونبرغ",
                "label_fr": "Johnsonburg"
            },
            {
                "value": 9701,
                "label": "Johnsonville",
                "label_ar": "جونسونفيل",
                "label_fr": "Johnsonville"
            },
            {
                "value": 9702,
                "label": "Johnston",
                "label_ar": "جونستون",
                "label_fr": "Johnston"
            },
            {
                "value": 9703,
                "label": "Johnston City",
                "label_ar": "جونستون سيتي",
                "label_fr": "Johnston City"
            },
            {
                "value": 9704,
                "label": "Johnstown",
                "label_ar": "جونستاون",
                "label_fr": "Johnstown"
            },
            {
                "value": 9705,
                "label": "Joice",
                "label_ar": "جويس",
                "label_fr": "Joice"
            },
            {
                "value": 9706,
                "label": "Joiner",
                "label_ar": "نجار",
                "label_fr": "Menuisier"
            },
            {
                "value": 9707,
                "label": "Joliet",
                "label_ar": "جوليت",
                "label_fr": "Joliet"
            },
            {
                "value": 9708,
                "label": "Jolon",
                "label_ar": "جلون",
                "label_fr": "Jolon"
            },
            {
                "value": 9709,
                "label": "Jones",
                "label_ar": "جونز",
                "label_fr": "Jones"
            },
            {
                "value": 9710,
                "label": "Jonesboro",
                "label_ar": "جونزبورو",
                "label_fr": "Jonesboro"
            },
            {
                "value": 9711,
                "label": "Jonesborough",
                "label_ar": "جونزبورو",
                "label_fr": "Jonesborough"
            },
            {
                "value": 9712,
                "label": "Jonesburg",
                "label_ar": "جونسبورغ",
                "label_fr": "Jonesburg"
            },
            {
                "value": 9713,
                "label": "Jonesport",
                "label_ar": "جونسبورت",
                "label_fr": "Jonesport"
            },
            {
                "value": 9714,
                "label": "Jonestown",
                "label_ar": "جونستاون",
                "label_fr": "Jonestown"
            },
            {
                "value": 9715,
                "label": "Jonesville",
                "label_ar": "جونزفيل",
                "label_fr": "Jonesville"
            },
            {
                "value": 9716,
                "label": "Joplin",
                "label_ar": "جوبلين",
                "label_fr": "Joplin"
            },
            {
                "value": 9717,
                "label": "Joppa",
                "label_ar": "جوبا",
                "label_fr": "Joppé"
            },
            {
                "value": 9718,
                "label": "Jordan",
                "label_ar": "الأردن",
                "label_fr": "Jordan"
            },
            {
                "value": 9719,
                "label": "Jordan Valley",
                "label_ar": "وادي الاردن",
                "label_fr": "Vallée du Jourdain"
            },
            {
                "value": 9720,
                "label": "Joseph",
                "label_ar": "جوزيف",
                "label_fr": "Joseph"
            },
            {
                "value": 9721,
                "label": "Josephine",
                "label_ar": "جوزفين",
                "label_fr": "Joséphine"
            },
            {
                "value": 9722,
                "label": "Joshua",
                "label_ar": "جوشوا",
                "label_fr": "Joshua"
            },
            {
                "value": 9723,
                "label": "Joshua Tree",
                "label_ar": "شجرة جوشوا",
                "label_fr": "Joshua Tree"
            },
            {
                "value": 9724,
                "label": "Jourdanton",
                "label_ar": "جوردانتون",
                "label_fr": "Jourdanton"
            },
            {
                "value": 9725,
                "label": "Joy",
                "label_ar": "الفرح",
                "label_fr": "Joie"
            },
            {
                "value": 9726,
                "label": "Jud",
                "label_ar": "جود",
                "label_fr": "Jud"
            },
            {
                "value": 9727,
                "label": "Juda",
                "label_ar": "جودا",
                "label_fr": "Juda"
            },
            {
                "value": 9728,
                "label": "Judith Gap",
                "label_ar": "جوديث جاب",
                "label_fr": "Judith Gap"
            },
            {
                "value": 9729,
                "label": "Judsonia",
                "label_ar": "جودسونيا",
                "label_fr": "Judsonia"
            },
            {
                "value": 9730,
                "label": "Julesburg",
                "label_ar": "جولسبورغ",
                "label_fr": "Julesburg"
            },
            {
                "value": 9731,
                "label": "Juliaetta",
                "label_ar": "جولييتا",
                "label_fr": "Juliaetta"
            },
            {
                "value": 9732,
                "label": "Julian",
                "label_ar": "جوليان",
                "label_fr": "julien"
            },
            {
                "value": 9733,
                "label": "Juliette",
                "label_ar": "جولييت",
                "label_fr": "Juliette"
            },
            {
                "value": 9734,
                "label": "Jumping Branch",
                "label_ar": "فرع القفز",
                "label_fr": "Branche de saut"
            },
            {
                "value": 9735,
                "label": "Junction",
                "label_ar": "تقاطع طرق",
                "label_fr": "Jonction"
            },
            {
                "value": 9736,
                "label": "Junction City",
                "label_ar": "جانكشن سيتي",
                "label_fr": "Ville de jonction"
            },
            {
                "value": 9737,
                "label": "June Lake",
                "label_ar": "بحيرة يونيو",
                "label_fr": "Lac June"
            },
            {
                "value": 9738,
                "label": "Juneau",
                "label_ar": "جونو",
                "label_fr": "Juneau"
            },
            {
                "value": 9739,
                "label": "Juniata",
                "label_ar": "جونياتا",
                "label_fr": "Juniata"
            },
            {
                "value": 9740,
                "label": "Junior",
                "label_ar": "نجارة",
                "label_fr": "Junior"
            },
            {
                "value": 9741,
                "label": "Juno Beach",
                "label_ar": "جونو بيتش",
                "label_fr": "Plage Juno"
            },
            {
                "value": 9742,
                "label": "Jupiter",
                "label_ar": "كوكب المشتري",
                "label_fr": "Jupiter"
            },
            {
                "value": 9743,
                "label": "Justice",
                "label_ar": "عدالة",
                "label_fr": "Justice"
            },
            {
                "value": 9744,
                "label": "Justin",
                "label_ar": "جاستن",
                "label_fr": "Justin"
            },
            {
                "value": 9745,
                "label": "Ka'a'awa",
                "label_ar": "الكعبة",
                "label_fr": "Ka'a'awa"
            },
            {
                "value": 9746,
                "label": "Kadoka",
                "label_ar": "كادوكا",
                "label_fr": "Kadoka"
            },
            {
                "value": 9747,
                "label": "Kahoka",
                "label_ar": "كاهوكا",
                "label_fr": "Kahoka"
            },
            {
                "value": 9748,
                "label": "Kahuku",
                "label_ar": "كاهوكو",
                "label_fr": "Kahuku"
            },
            {
                "value": 9749,
                "label": "Kahului",
                "label_ar": "كاهولوي",
                "label_fr": "Kahului"
            },
            {
                "value": 9750,
                "label": "Kailua",
                "label_ar": "كايلو",
                "label_fr": "Kailua"
            },
            {
                "value": 9751,
                "label": "Kaiser",
                "label_ar": "كايزر",
                "label_fr": "kaiser"
            },
            {
                "value": 9752,
                "label": "Kake",
                "label_ar": "كيك",
                "label_fr": "Kake"
            },
            {
                "value": 9753,
                "label": "Kaktovik",
                "label_ar": "كاكتوفيك",
                "label_fr": "Kaktovik"
            },
            {
                "value": 9754,
                "label": "Kalaheo",
                "label_ar": "كالاهيو",
                "label_fr": "Kalaheo"
            },
            {
                "value": 9755,
                "label": "Kalama",
                "label_ar": "كالاما",
                "label_fr": "Kalama"
            },
            {
                "value": 9756,
                "label": "Kalamazoo",
                "label_ar": "كالامازو",
                "label_fr": "Kalamazoo"
            },
            {
                "value": 9757,
                "label": "Kaleva",
                "label_ar": "كاليفا",
                "label_fr": "Kaleva"
            },
            {
                "value": 9758,
                "label": "Kalida",
                "label_ar": "كاليدا",
                "label_fr": "Kalida"
            },
            {
                "value": 9759,
                "label": "Kalispell",
                "label_ar": "كاليسبيل",
                "label_fr": "Kalispell"
            },
            {
                "value": 9760,
                "label": "Kalkaska",
                "label_ar": "كالكاسكا",
                "label_fr": "Kalkaska"
            },
            {
                "value": 9761,
                "label": "Kalona",
                "label_ar": "كالونا",
                "label_fr": "Kalona"
            },
            {
                "value": 9762,
                "label": "Kalskag",
                "label_ar": "كالسكاغ",
                "label_fr": "Kalskag"
            },
            {
                "value": 9763,
                "label": "Kamas",
                "label_ar": "كاماس",
                "label_fr": "Kamas"
            },
            {
                "value": 9764,
                "label": "Kamiah",
                "label_ar": "كاميا",
                "label_fr": "Kamiah"
            },
            {
                "value": 9765,
                "label": "Kampsville",
                "label_ar": "كامبسفيل",
                "label_fr": "Kampsville"
            },
            {
                "value": 9766,
                "label": "Kanab",
                "label_ar": "كناب",
                "label_fr": "Kanab"
            },
            {
                "value": 9767,
                "label": "Kanawha",
                "label_ar": "كناوة",
                "label_fr": "Kanawha"
            },
            {
                "value": 9768,
                "label": "Kandiyohi",
                "label_ar": "كانديوهي",
                "label_fr": "Kandiyohi"
            },
            {
                "value": 9769,
                "label": "Kane",
                "label_ar": "كين",
                "label_fr": "Kane"
            },
            {
                "value": 9770,
                "label": "Kane'ohe",
                "label_ar": "كانيوهي",
                "label_fr": "Kane'ohe"
            },
            {
                "value": 9771,
                "label": "Kaneville",
                "label_ar": "كانفيل",
                "label_fr": "Kaneville"
            },
            {
                "value": 9772,
                "label": "Kankakee",
                "label_ar": "كانكاكي",
                "label_fr": "Kankakee"
            },
            {
                "value": 9773,
                "label": "Kannapolis",
                "label_ar": "كانابوليس",
                "label_fr": "Kannapolis"
            },
            {
                "value": 9774,
                "label": "Kanopolis",
                "label_ar": "كانوبوليس",
                "label_fr": "Kanopolis"
            },
            {
                "value": 9775,
                "label": "Kansas",
                "label_ar": "كانساس",
                "label_fr": "Kansas"
            },
            {
                "value": 9776,
                "label": "Kansas City",
                "label_ar": "مدينة كانساس",
                "label_fr": "Kansas City"
            },
            {
                "value": 9777,
                "label": "Kansasville",
                "label_ar": "كانساسفيل",
                "label_fr": "Kansasville"
            },
            {
                "value": 9778,
                "label": "Kapa'a",
                "label_ar": "كابا",
                "label_fr": "Kapa'a"
            },
            {
                "value": 9779,
                "label": "Kapaau",
                "label_ar": "كاباو",
                "label_fr": "Kapaau"
            },
            {
                "value": 9780,
                "label": "Kaplan",
                "label_ar": "كابلان",
                "label_fr": "Kaplan"
            },
            {
                "value": 9781,
                "label": "Kapolei",
                "label_ar": "كابولي",
                "label_fr": "Kapolei"
            },
            {
                "value": 9782,
                "label": "Karlstad",
                "label_ar": "كارلستاد",
                "label_fr": "Karlstad"
            },
            {
                "value": 9783,
                "label": "Karnack",
                "label_ar": "الكرنك",
                "label_fr": "Karnack"
            },
            {
                "value": 9784,
                "label": "Karnak",
                "label_ar": "الكرنك",
                "label_fr": "Karnak"
            },
            {
                "value": 9785,
                "label": "Karnes City",
                "label_ar": "كارنز سيتي",
                "label_fr": "Karnes City"
            },
            {
                "value": 9786,
                "label": "Karns City",
                "label_ar": "كارنز سيتي",
                "label_fr": "Ville de Karns"
            },
            {
                "value": 9787,
                "label": "Karthaus",
                "label_ar": "كارتهاوس",
                "label_fr": "Karthaus"
            },
            {
                "value": 9788,
                "label": "Karval",
                "label_ar": "كارفال",
                "label_fr": "Karval"
            },
            {
                "value": 9789,
                "label": "Kasota",
                "label_ar": "كاسوتا",
                "label_fr": "Kasota"
            },
            {
                "value": 9790,
                "label": "Kasson",
                "label_ar": "كاسون",
                "label_fr": "Kasson"
            },
            {
                "value": 9791,
                "label": "Kathleen",
                "label_ar": "كاثلين",
                "label_fr": "Kathleen"
            },
            {
                "value": 9792,
                "label": "Katonah",
                "label_ar": "كاتونه",
                "label_fr": "Katonah"
            },
            {
                "value": 9793,
                "label": "Katy",
                "label_ar": "كاتي",
                "label_fr": "Katy"
            },
            {
                "value": 9794,
                "label": "Kaufman",
                "label_ar": "كوفمان",
                "label_fr": "Kaufman"
            },
            {
                "value": 9795,
                "label": "Kaukauna",
                "label_ar": "كاوكونا",
                "label_fr": "Kaukauna"
            },
            {
                "value": 9796,
                "label": "Kaumakani",
                "label_ar": "كوماكاني",
                "label_fr": "Kaumakani"
            },
            {
                "value": 9797,
                "label": "Kaunakakai",
                "label_ar": "كاوناكاكاي",
                "label_fr": "Kaunakakai"
            },
            {
                "value": 9798,
                "label": "Kauneonga Lake",
                "label_ar": "بحيرة كاونونجا",
                "label_fr": "Lac Kauneonga"
            },
            {
                "value": 9799,
                "label": "Kaw City",
                "label_ar": "مدينة كاو",
                "label_fr": "Kaw City"
            },
            {
                "value": 9800,
                "label": "Kaweah",
                "label_ar": "كاوية",
                "label_fr": "Kaweah"
            },
            {
                "value": 9801,
                "label": "Kawkawlin",
                "label_ar": "Kawkawlin",
                "label_fr": "Kawkawlin"
            },
            {
                "value": 9802,
                "label": "Kaycee",
                "label_ar": "كيسي",
                "label_fr": "Kaycee"
            },
            {
                "value": 9803,
                "label": "Kayenta",
                "label_ar": "كاينتا",
                "label_fr": "Kayenta"
            },
            {
                "value": 9804,
                "label": "Kaysville",
                "label_ar": "كايسفيل",
                "label_fr": "Kaysville"
            },
            {
                "value": 9805,
                "label": "Kea'au",
                "label_ar": "كياو",
                "label_fr": "Kea'au"
            },
            {
                "value": 9806,
                "label": "Kealakekua",
                "label_ar": "كيلاكيكوا",
                "label_fr": "Kealakekua"
            },
            {
                "value": 9807,
                "label": "Keams Canyon",
                "label_ar": "كيمس كانيون",
                "label_fr": "Canyon de Keams"
            },
            {
                "value": 9808,
                "label": "Keansburg",
                "label_ar": "كينسبيرغ",
                "label_fr": "Keansburg"
            },
            {
                "value": 9809,
                "label": "Kearney",
                "label_ar": "كيرني",
                "label_fr": "Kearney"
            },
            {
                "value": 9810,
                "label": "Kearneysville",
                "label_ar": "كيرنيزفيل",
                "label_fr": "Kearneysville"
            },
            {
                "value": 9811,
                "label": "Kearny",
                "label_ar": "كيرني",
                "label_fr": "Kearny"
            },
            {
                "value": 9812,
                "label": "Keasbey",
                "label_ar": "كيسبي",
                "label_fr": "Keasbey"
            },
            {
                "value": 9813,
                "label": "Keatchie",
                "label_ar": "كيتشي",
                "label_fr": "Keatchie"
            },
            {
                "value": 9814,
                "label": "Keauhou",
                "label_ar": "كيوهو",
                "label_fr": "Keauhou"
            },
            {
                "value": 9815,
                "label": "Keavy",
                "label_ar": "كيفي",
                "label_fr": "Keavy"
            },
            {
                "value": 9816,
                "label": "Kechi",
                "label_ar": "كيتشي",
                "label_fr": "Kechi"
            },
            {
                "value": 9817,
                "label": "Keedysville",
                "label_ar": "كيديسفيل",
                "label_fr": "Keedysville"
            },
            {
                "value": 9818,
                "label": "Keego Harbor",
                "label_ar": "ميناء كيجو",
                "label_fr": "Keego Harbour"
            },
            {
                "value": 9819,
                "label": "Keene",
                "label_ar": "كين",
                "label_fr": "Keene"
            },
            {
                "value": 9820,
                "label": "Keene Valley",
                "label_ar": "وادي كين",
                "label_fr": "Vallée de Keene"
            },
            {
                "value": 9821,
                "label": "Keenesburg",
                "label_ar": "كينسبورغ",
                "label_fr": "Keenesburg"
            },
            {
                "value": 9822,
                "label": "Keeseville",
                "label_ar": "كيسفيل",
                "label_fr": "Keeseville"
            },
            {
                "value": 9823,
                "label": "Keewatin",
                "label_ar": "كيواتين",
                "label_fr": "Keewatin"
            },
            {
                "value": 9824,
                "label": "Keezletown",
                "label_ar": "كيزلتاون",
                "label_fr": "Keezletown"
            },
            {
                "value": 9825,
                "label": "Kegley",
                "label_ar": "كيجلي",
                "label_fr": "Kegley"
            },
            {
                "value": 9826,
                "label": "Keithville",
                "label_ar": "كيثفيل",
                "label_fr": "Keithville"
            },
            {
                "value": 9827,
                "label": "Keizer",
                "label_ar": "كيزر",
                "label_fr": "Keizer"
            },
            {
                "value": 9828,
                "label": "Kekaha",
                "label_ar": "كيكاها",
                "label_fr": "Kekaha"
            },
            {
                "value": 9829,
                "label": "Kelayres",
                "label_ar": "كيلايرز",
                "label_fr": "Kelayres"
            },
            {
                "value": 9830,
                "label": "Kelford",
                "label_ar": "كلفورد",
                "label_fr": "Kelford"
            },
            {
                "value": 9831,
                "label": "Keller",
                "label_ar": "كيلر",
                "label_fr": "Keller"
            },
            {
                "value": 9832,
                "label": "Kellerton",
                "label_ar": "كيلرتون",
                "label_fr": "Kellerton"
            },
            {
                "value": 9833,
                "label": "Kelley",
                "label_ar": "كيلي",
                "label_fr": "Kelley"
            },
            {
                "value": 9834,
                "label": "Kelliher",
                "label_ar": "كيليهر",
                "label_fr": "Kelliher"
            },
            {
                "value": 9835,
                "label": "Kellogg",
                "label_ar": "كيلوج",
                "label_fr": "Kellogg"
            },
            {
                "value": 9836,
                "label": "Kelly",
                "label_ar": "كيلي",
                "label_fr": "Kelly"
            },
            {
                "value": 9837,
                "label": "Kellyton",
                "label_ar": "كيليتون",
                "label_fr": "Kellyton"
            },
            {
                "value": 9838,
                "label": "Kellyville",
                "label_ar": "كيليفيل",
                "label_fr": "Kellyville"
            },
            {
                "value": 9839,
                "label": "Kelseyville",
                "label_ar": "كيلسيفيل",
                "label_fr": "Kelseyville"
            },
            {
                "value": 9840,
                "label": "Kelso",
                "label_ar": "كيلسو",
                "label_fr": "Kelso"
            },
            {
                "value": 9841,
                "label": "Kemah",
                "label_ar": "كيماه",
                "label_fr": "Kemah"
            },
            {
                "value": 9842,
                "label": "Kemmerer",
                "label_ar": "كيميرر",
                "label_fr": "Kemmerer"
            },
            {
                "value": 9843,
                "label": "Kemp",
                "label_ar": "كيمب",
                "label_fr": "Kemp"
            },
            {
                "value": 9844,
                "label": "Kempner",
                "label_ar": "كيمبنر",
                "label_fr": "Kempner"
            },
            {
                "value": 9845,
                "label": "Kempton",
                "label_ar": "كمبتون",
                "label_fr": "Kempton"
            },
            {
                "value": 9846,
                "label": "Kenai",
                "label_ar": "كيناي",
                "label_fr": "Kenai"
            },
            {
                "value": 9847,
                "label": "Kenansville",
                "label_ar": "كينانسفيل",
                "label_fr": "Kenansville"
            },
            {
                "value": 9848,
                "label": "Kenbridge",
                "label_ar": "كينبريدج",
                "label_fr": "Kenbridge"
            },
            {
                "value": 9849,
                "label": "Kendalia",
                "label_ar": "كنداليا",
                "label_fr": "Kendalia"
            },
            {
                "value": 9850,
                "label": "Kendall",
                "label_ar": "كيندال",
                "label_fr": "Kendall"
            },
            {
                "value": 9851,
                "label": "Kendall Park",
                "label_ar": "كيندال بارك",
                "label_fr": "Parc Kendall"
            },
            {
                "value": 9852,
                "label": "Kendallville",
                "label_ar": "كيندالفيل",
                "label_fr": "Kendallville"
            },
            {
                "value": 9853,
                "label": "Kendrick",
                "label_ar": "كندريك",
                "label_fr": "Kendrick"
            },
            {
                "value": 9854,
                "label": "Kenduskeag",
                "label_ar": "Kenduskeag",
                "label_fr": "Kenduskeag"
            },
            {
                "value": 9855,
                "label": "Kenedy",
                "label_ar": "كينيدي",
                "label_fr": "Kenedy"
            },
            {
                "value": 9856,
                "label": "Kenesaw",
                "label_ar": "كينيساو",
                "label_fr": "Kenesaw"
            },
            {
                "value": 9857,
                "label": "Kenilworth",
                "label_ar": "كينيلورث",
                "label_fr": "Kenilworth"
            },
            {
                "value": 9858,
                "label": "Kenly",
                "label_ar": "كينلي",
                "label_fr": "Kenly"
            },
            {
                "value": 9859,
                "label": "Kenmare",
                "label_ar": "كينمير",
                "label_fr": "Kenmare"
            },
            {
                "value": 9860,
                "label": "Kenmore",
                "label_ar": "كينمور",
                "label_fr": "Kenmore"
            },
            {
                "value": 9861,
                "label": "Kenna",
                "label_ar": "كينا",
                "label_fr": "Kenna"
            },
            {
                "value": 9862,
                "label": "Kennan",
                "label_ar": "كينان",
                "label_fr": "Kennan"
            },
            {
                "value": 9863,
                "label": "Kennard",
                "label_ar": "كينارد",
                "label_fr": "Kennard"
            },
            {
                "value": 9864,
                "label": "Kennebec",
                "label_ar": "كينيبيك",
                "label_fr": "Kennebec"
            },
            {
                "value": 9865,
                "label": "Kennebunk",
                "label_ar": "كينيبانك",
                "label_fr": "Kennebunk"
            },
            {
                "value": 9866,
                "label": "Kennebunkport",
                "label_ar": "كينيبانكبورت",
                "label_fr": "Kennebunkport"
            },
            {
                "value": 9867,
                "label": "Kennedale",
                "label_ar": "كينيديل",
                "label_fr": "Kennedale"
            },
            {
                "value": 9868,
                "label": "Kennedy",
                "label_ar": "كينيدي",
                "label_fr": "Kennedy"
            },
            {
                "value": 9869,
                "label": "Kennedyville",
                "label_ar": "كينيديفيل",
                "label_fr": "Kennedyville"
            },
            {
                "value": 9870,
                "label": "Kenner",
                "label_ar": "كينر",
                "label_fr": "Kenner"
            },
            {
                "value": 9871,
                "label": "Kennerdell",
                "label_ar": "كينيرديل",
                "label_fr": "Kennerdell"
            },
            {
                "value": 9872,
                "label": "Kennesaw",
                "label_ar": "كينيساو",
                "label_fr": "Kennesaw"
            },
            {
                "value": 9873,
                "label": "Kennett",
                "label_ar": "كينيت",
                "label_fr": "Kennett"
            },
            {
                "value": 9874,
                "label": "Kennett Square",
                "label_ar": "ساحة كينيت",
                "label_fr": "Place Kennett"
            },
            {
                "value": 9875,
                "label": "Kennewick",
                "label_ar": "كينويك",
                "label_fr": "Kennewick"
            },
            {
                "value": 9876,
                "label": "Kenney",
                "label_ar": "كيني",
                "label_fr": "Kenney"
            },
            {
                "value": 9877,
                "label": "Keno",
                "label_ar": "كينو",
                "label_fr": "Keno"
            },
            {
                "value": 9878,
                "label": "Kenosha",
                "label_ar": "كينوشا",
                "label_fr": "Kenosha"
            },
            {
                "value": 9879,
                "label": "Kenova",
                "label_ar": "كينوفا",
                "label_fr": "Kenova"
            },
            {
                "value": 9880,
                "label": "Kensett",
                "label_ar": "كينسيت",
                "label_fr": "Kensett"
            },
            {
                "value": 9881,
                "label": "Kensington",
                "label_ar": "كنسينغتون",
                "label_fr": "Kensington"
            },
            {
                "value": 9882,
                "label": "Kent",
                "label_ar": "كينت",
                "label_fr": "Kent"
            },
            {
                "value": 9883,
                "label": "Kent City",
                "label_ar": "كنت سيتي",
                "label_fr": "Kent City"
            },
            {
                "value": 9884,
                "label": "Kentfield",
                "label_ar": "كنتفيلد",
                "label_fr": "Kentfield"
            },
            {
                "value": 9885,
                "label": "Kentland",
                "label_ar": "كنتلاند",
                "label_fr": "Kentland"
            },
            {
                "value": 9886,
                "label": "Kenton",
                "label_ar": "كنتون",
                "label_fr": "Kenton"
            },
            {
                "value": 9887,
                "label": "Kents Hill",
                "label_ar": "كينتس هيل",
                "label_fr": "Kents Hill"
            },
            {
                "value": 9888,
                "label": "Kents Store",
                "label_ar": "متجر كينتس",
                "label_fr": "Magasin Kents"
            },
            {
                "value": 9889,
                "label": "Kentwood",
                "label_ar": "كينتوود",
                "label_fr": "Kentwood"
            },
            {
                "value": 9890,
                "label": "Kenvil",
                "label_ar": "كينفيل",
                "label_fr": "Kenvil"
            },
            {
                "value": 9891,
                "label": "Kenyon",
                "label_ar": "كينيون",
                "label_fr": "Kenyon"
            },
            {
                "value": 9892,
                "label": "Keokuk",
                "label_ar": "كيوكوك",
                "label_fr": "Keokuk"
            },
            {
                "value": 9893,
                "label": "Keosauqua",
                "label_ar": "كيوساوكوا",
                "label_fr": "Keosauqua"
            },
            {
                "value": 9894,
                "label": "Keota",
                "label_ar": "كيوتا",
                "label_fr": "Keota"
            },
            {
                "value": 9895,
                "label": "Kerby",
                "label_ar": "كيربي",
                "label_fr": "Kerby"
            },
            {
                "value": 9896,
                "label": "Kerens",
                "label_ar": "كيرنز",
                "label_fr": "Kerens"
            },
            {
                "value": 9897,
                "label": "Kerhonkson",
                "label_ar": "كيرهونكسون",
                "label_fr": "Kerhonkson"
            },
            {
                "value": 9898,
                "label": "Kerkhoven",
                "label_ar": "كيركهوفن",
                "label_fr": "Kerkhoven"
            },
            {
                "value": 9899,
                "label": "Kerman",
                "label_ar": "كرمان",
                "label_fr": "Kerman"
            },
            {
                "value": 9900,
                "label": "Kermit",
                "label_ar": "كيرميت",
                "label_fr": "Kermit"
            },
            {
                "value": 9901,
                "label": "Kernersville",
                "label_ar": "كيرنرسفيل",
                "label_fr": "Kernersville"
            },
            {
                "value": 9902,
                "label": "Kernville",
                "label_ar": "كيرنفيل",
                "label_fr": "Kernville"
            },
            {
                "value": 9903,
                "label": "Kerrick",
                "label_ar": "كيريك",
                "label_fr": "Kerrick"
            },
            {
                "value": 9904,
                "label": "Kerrville",
                "label_ar": "كيرفيل",
                "label_fr": "Kerrville"
            },
            {
                "value": 9905,
                "label": "Kersey",
                "label_ar": "كيرسي",
                "label_fr": "Kersey"
            },
            {
                "value": 9906,
                "label": "Kershaw",
                "label_ar": "كيرشو",
                "label_fr": "Kershaw"
            },
            {
                "value": 9907,
                "label": "Keshena",
                "label_ar": "كيشينا",
                "label_fr": "Keshena"
            },
            {
                "value": 9908,
                "label": "Keswick",
                "label_ar": "كيسويك",
                "label_fr": "Keswick"
            },
            {
                "value": 9909,
                "label": "Ketchikan",
                "label_ar": "كيتشيكان",
                "label_fr": "Ketchikan"
            },
            {
                "value": 9910,
                "label": "Ketchum",
                "label_ar": "كيتشوم",
                "label_fr": "Ketchum"
            },
            {
                "value": 9911,
                "label": "Kettle Falls",
                "label_ar": "شلالات غلاية",
                "label_fr": "Kettle Falls"
            },
            {
                "value": 9912,
                "label": "Kettle Island",
                "label_ar": "جزيرة غلاية",
                "label_fr": "Île Kettle"
            },
            {
                "value": 9913,
                "label": "Kettleman City",
                "label_ar": "مدينة كتلمان",
                "label_fr": "Kettleman City"
            },
            {
                "value": 9914,
                "label": "Keuka Park",
                "label_ar": "حديقة كيوكا",
                "label_fr": "Parc Keuka"
            },
            {
                "value": 9915,
                "label": "Kevil",
                "label_ar": "كيفيل",
                "label_fr": "Kevil"
            },
            {
                "value": 9916,
                "label": "Kevin",
                "label_ar": "كيفن",
                "label_fr": "Kevin"
            },
            {
                "value": 9917,
                "label": "Kew Gardens",
                "label_ar": "حدائق كيو",
                "label_fr": "Kew Gardens"
            },
            {
                "value": 9918,
                "label": "Kewadin",
                "label_ar": "كوادين",
                "label_fr": "Kewadin"
            },
            {
                "value": 9919,
                "label": "Kewanee",
                "label_ar": "كيواني",
                "label_fr": "Kewanee"
            },
            {
                "value": 9920,
                "label": "Kewanna",
                "label_ar": "كيوانا",
                "label_fr": "Kewanna"
            },
            {
                "value": 9921,
                "label": "Kewaskum",
                "label_ar": "Kewaskum",
                "label_fr": "Kewaskum"
            },
            {
                "value": 9922,
                "label": "Kewaunee",
                "label_ar": "Kewaunee",
                "label_fr": "Kewaunee"
            },
            {
                "value": 9923,
                "label": "Key Biscayne",
                "label_ar": "مفتاح بيسكاين",
                "label_fr": "Key Biscayne"
            },
            {
                "value": 9924,
                "label": "Key Largo",
                "label_ar": "مفتاح لارجو",
                "label_fr": "Key Largo"
            },
            {
                "value": 9925,
                "label": "Key West",
                "label_ar": "الغرب الرئيسي",
                "label_fr": "Key West"
            },
            {
                "value": 9926,
                "label": "Keyes",
                "label_ar": "كيز",
                "label_fr": "Keyes"
            },
            {
                "value": 9927,
                "label": "Keymar",
                "label_ar": "كيمار",
                "label_fr": "Keymar"
            },
            {
                "value": 9928,
                "label": "Keyport",
                "label_ar": "Keyport",
                "label_fr": "Keyport"
            },
            {
                "value": 9929,
                "label": "Keyser",
                "label_ar": "كيسير",
                "label_fr": "Keyser"
            },
            {
                "value": 9930,
                "label": "Keystone",
                "label_ar": "كيستون",
                "label_fr": "clé de voûte"
            },
            {
                "value": 9931,
                "label": "Keystone Heights",
                "label_ar": "مرتفعات كيستون",
                "label_fr": "Keystone Heights"
            },
            {
                "value": 9932,
                "label": "Keysville",
                "label_ar": "كيسفيل",
                "label_fr": "Keysville"
            },
            {
                "value": 9933,
                "label": "Keytesville",
                "label_ar": "كيتيسفيل",
                "label_fr": "Keytesville"
            },
            {
                "value": 9934,
                "label": "Kiamesha Lake",
                "label_ar": "بحيرة كيامشا",
                "label_fr": "Lac Kiamesha"
            },
            {
                "value": 9935,
                "label": "Kiana",
                "label_ar": "كيانا",
                "label_fr": "Kiana"
            },
            {
                "value": 9936,
                "label": "Kiefer",
                "label_ar": "كيفر",
                "label_fr": "Kiefer"
            },
            {
                "value": 9937,
                "label": "Kiel",
                "label_ar": "كيل",
                "label_fr": "Kiel"
            },
            {
                "value": 9938,
                "label": "Kieler",
                "label_ar": "كيلر",
                "label_fr": "Kieler"
            },
            {
                "value": 9939,
                "label": "Kiester",
                "label_ar": "كيستر",
                "label_fr": "Kiester"
            },
            {
                "value": 9940,
                "label": "Kihei",
                "label_ar": "كيهي",
                "label_fr": "Kihei"
            },
            {
                "value": 9941,
                "label": "Kila",
                "label_ar": "كيلا",
                "label_fr": "Kila"
            },
            {
                "value": 9942,
                "label": "Kilauea",
                "label_ar": "كيلويا",
                "label_fr": "Kilauea"
            },
            {
                "value": 9943,
                "label": "Kilbourne",
                "label_ar": "كيلبورن",
                "label_fr": "Kilbourne"
            },
            {
                "value": 9944,
                "label": "Kilgore",
                "label_ar": "كيلجور",
                "label_fr": "Kilgore"
            },
            {
                "value": 9945,
                "label": "Kilkenny",
                "label_ar": "كيلكيني",
                "label_fr": "Kilkenny"
            },
            {
                "value": 9946,
                "label": "Kilkenny Township",
                "label_ar": "بلدة كيلكيني",
                "label_fr": "Canton de Kilkenny"
            },
            {
                "value": 9947,
                "label": "Kill Devil Hills",
                "label_ar": "اقتل تلال الشيطان",
                "label_fr": "Tuer les collines du diable"
            },
            {
                "value": 9948,
                "label": "Killbuck",
                "label_ar": "كيلباك",
                "label_fr": "Killbuck"
            },
            {
                "value": 9949,
                "label": "Killdeer",
                "label_ar": "كيلدير",
                "label_fr": "Killdeer"
            },
            {
                "value": 9950,
                "label": "Killeen",
                "label_ar": "كيلين",
                "label_fr": "Killeen"
            },
            {
                "value": 9951,
                "label": "Killen",
                "label_ar": "قتل",
                "label_fr": "Killen"
            },
            {
                "value": 9952,
                "label": "Killington",
                "label_ar": "كلينجتون",
                "label_fr": "Killington"
            },
            {
                "value": 9953,
                "label": "Killingworth",
                "label_ar": "كيلنغوورث",
                "label_fr": "Killingworth"
            },
            {
                "value": 9954,
                "label": "Kilmarnock",
                "label_ar": "كيلمارنوك",
                "label_fr": "Kilmarnock"
            },
            {
                "value": 9955,
                "label": "Kilmichael",
                "label_ar": "كيلمايكل",
                "label_fr": "Kilmichael"
            },
            {
                "value": 9956,
                "label": "Kiln",
                "label_ar": "فرن",
                "label_fr": "Four"
            },
            {
                "value": 9957,
                "label": "Kimball",
                "label_ar": "كيمبال",
                "label_fr": "Kimball"
            },
            {
                "value": 9958,
                "label": "Kimballton",
                "label_ar": "كيمبالتون",
                "label_fr": "Kimballton"
            },
            {
                "value": 9959,
                "label": "Kimberling City",
                "label_ar": "مدينة كيمبرلينج",
                "label_fr": "Ville de Kimberling"
            },
            {
                "value": 9960,
                "label": "Kimberly",
                "label_ar": "كيمبرلي",
                "label_fr": "Kimberly"
            },
            {
                "value": 9961,
                "label": "Kimberton",
                "label_ar": "كيمبرتون",
                "label_fr": "Kimberton"
            },
            {
                "value": 9962,
                "label": "Kimbolton",
                "label_ar": "كيمبولتون",
                "label_fr": "Kimbolton"
            },
            {
                "value": 9963,
                "label": "Kimmell",
                "label_ar": "كيميل",
                "label_fr": "Kimmell"
            },
            {
                "value": 9964,
                "label": "Kimper",
                "label_ar": "كيمبر",
                "label_fr": "Kimper"
            },
            {
                "value": 9965,
                "label": "Kincaid",
                "label_ar": "كينكيد",
                "label_fr": "Kincaid"
            },
            {
                "value": 9966,
                "label": "Kinde",
                "label_ar": "كيندي",
                "label_fr": "Kinde"
            },
            {
                "value": 9967,
                "label": "Kinder",
                "label_ar": "كيندر",
                "label_fr": "Kinder"
            },
            {
                "value": 9968,
                "label": "Kinderhook",
                "label_ar": "Kinderhook",
                "label_fr": "Kinderhook"
            },
            {
                "value": 9969,
                "label": "Kindred",
                "label_ar": "نوع",
                "label_fr": "Parenté"
            },
            {
                "value": 9970,
                "label": "King",
                "label_ar": "ملك",
                "label_fr": "Roi"
            },
            {
                "value": 9971,
                "label": "King City",
                "label_ar": "مدينة الملك",
                "label_fr": "King City"
            },
            {
                "value": 9972,
                "label": "King Cove",
                "label_ar": "الملك كوف",
                "label_fr": "King Cove"
            },
            {
                "value": 9973,
                "label": "King George",
                "label_ar": "الملك جورج",
                "label_fr": "le roi George"
            },
            {
                "value": 9974,
                "label": "King Salmon",
                "label_ar": "ملك السلمون",
                "label_fr": "King Salmon"
            },
            {
                "value": 9975,
                "label": "King William",
                "label_ar": "الملك وليام",
                "label_fr": "Le roi Guillaume"
            },
            {
                "value": 9976,
                "label": "King and Queen Court House",
                "label_ar": "بيت الملك والملكة",
                "label_fr": "Palais de justice du roi et de la reine"
            },
            {
                "value": 9977,
                "label": "King of Prussia",
                "label_ar": "ملك بروسيا",
                "label_fr": "Roi de Prusse"
            },
            {
                "value": 9978,
                "label": "Kingdom City",
                "label_ar": "مدينة المملكة",
                "label_fr": "Kingdom City"
            },
            {
                "value": 9979,
                "label": "Kingfield",
                "label_ar": "كينجفيلد",
                "label_fr": "Kingfield"
            },
            {
                "value": 9980,
                "label": "Kingfisher",
                "label_ar": "الرفراف",
                "label_fr": "Kingfisher"
            },
            {
                "value": 9981,
                "label": "Kingman",
                "label_ar": "رجل ملك",
                "label_fr": "Roi homme"
            },
            {
                "value": 9982,
                "label": "Kings Bay Base",
                "label_ar": "قاعدة خليج الملوك",
                "label_fr": "Base de Kings Bay"
            },
            {
                "value": 9983,
                "label": "Kings Beach",
                "label_ar": "كينغز بيتش",
                "label_fr": "Plage de Kings"
            },
            {
                "value": 9984,
                "label": "Kings Mills",
                "label_ar": "كينجز ميلز",
                "label_fr": "Kings Mills"
            },
            {
                "value": 9985,
                "label": "Kings Mountain",
                "label_ar": "كينغز ماونتن",
                "label_fr": "Kings Mountain"
            },
            {
                "value": 9986,
                "label": "Kings Park",
                "label_ar": "حديقة الملوك",
                "label_fr": "Kings Park"
            },
            {
                "value": 9987,
                "label": "Kingsburg",
                "label_ar": "كينجسبورج",
                "label_fr": "Kingsburg"
            },
            {
                "value": 9988,
                "label": "Kingsbury",
                "label_ar": "كينجسبري",
                "label_fr": "Kingsbury"
            },
            {
                "value": 9989,
                "label": "Kingsford",
                "label_ar": "كينجسفورد",
                "label_fr": "Kingsford"
            },
            {
                "value": 9990,
                "label": "Kingsland",
                "label_ar": "أرض الملك",
                "label_fr": "Kingsland"
            },
            {
                "value": 9991,
                "label": "Kingsley",
                "label_ar": "كينجسلي",
                "label_fr": "Kingsley"
            },
            {
                "value": 9992,
                "label": "Kingsport",
                "label_ar": "كينجسبورت",
                "label_fr": "Kingsport"
            },
            {
                "value": 9993,
                "label": "Kingston",
                "label_ar": "كينغستون",
                "label_fr": "Kingston"
            },
            {
                "value": 9994,
                "label": "Kingston Springs",
                "label_ar": "كينغستون سبرينغز",
                "label_fr": "Kingston Springs"
            },
            {
                "value": 9995,
                "label": "Kingstree",
                "label_ar": "Kingstree",
                "label_fr": "Kingstree"
            },
            {
                "value": 9996,
                "label": "Kingsville",
                "label_ar": "كينجسفيل",
                "label_fr": "Kingsville"
            },
            {
                "value": 9997,
                "label": "Kingwood",
                "label_ar": "كينجوود",
                "label_fr": "Kingwood"
            },
            {
                "value": 9998,
                "label": "Kinmundy",
                "label_ar": "كينموندي",
                "label_fr": "Kinmundy"
            },
            {
                "value": 9999,
                "label": "Kinnear",
                "label_ar": "كينير",
                "label_fr": "Kinnear"
            },
            {
                "value": 10000,
                "label": "Kinsley",
                "label_ar": "كينسلي",
                "label_fr": "Kinsley"
            },
            {
                "value": 10001,
                "label": "Kinsman",
                "label_ar": "كينسمان",
                "label_fr": "Parent"
            },
            {
                "value": 10002,
                "label": "Kinston",
                "label_ar": "كينستون",
                "label_fr": "Kinston"
            },
            {
                "value": 10003,
                "label": "Kinta",
                "label_ar": "كينتا",
                "label_fr": "Kinta"
            },
            {
                "value": 10004,
                "label": "Kintnersville",
                "label_ar": "كينترسفيل",
                "label_fr": "Kintnersville"
            },
            {
                "value": 10005,
                "label": "Kintyre",
                "label_ar": "Kintyre",
                "label_fr": "Kintyre"
            },
            {
                "value": 10006,
                "label": "Kinzers",
                "label_ar": "كنزر",
                "label_fr": "Kinzers"
            },
            {
                "value": 10007,
                "label": "Kiowa",
                "label_ar": "كيوا",
                "label_fr": "Kiowa"
            },
            {
                "value": 10008,
                "label": "Kipling",
                "label_ar": "كيبلينج",
                "label_fr": "Kipling"
            },
            {
                "value": 10009,
                "label": "Kipnuk",
                "label_ar": "كيبنوك",
                "label_fr": "Kipnuk"
            },
            {
                "value": 10010,
                "label": "Kirby",
                "label_ar": "كيربي",
                "label_fr": "Kirby"
            },
            {
                "value": 10011,
                "label": "Kirbyville",
                "label_ar": "كيربيفيل",
                "label_fr": "Kirbyville"
            },
            {
                "value": 10012,
                "label": "Kirk",
                "label_ar": "كيرك",
                "label_fr": "Église"
            },
            {
                "value": 10013,
                "label": "Kirkland",
                "label_ar": "كيركلاند",
                "label_fr": "Kirkland"
            },
            {
                "value": 10014,
                "label": "Kirklin",
                "label_ar": "كيركلين",
                "label_fr": "Kirklin"
            },
            {
                "value": 10015,
                "label": "Kirksey",
                "label_ar": "كيركسي",
                "label_fr": "Kirksey"
            },
            {
                "value": 10016,
                "label": "Kirksville",
                "label_ar": "كيركسفيل",
                "label_fr": "Kirksville"
            },
            {
                "value": 10017,
                "label": "Kirkville",
                "label_ar": "كيركفيل",
                "label_fr": "Kirkville"
            },
            {
                "value": 10018,
                "label": "Kirkwood",
                "label_ar": "كيركوود",
                "label_fr": "Kirkwood"
            },
            {
                "value": 10019,
                "label": "Kiron",
                "label_ar": "كيرون",
                "label_fr": "Kiron"
            },
            {
                "value": 10020,
                "label": "Kirtland",
                "label_ar": "كيرتلاند",
                "label_fr": "Kirtland"
            },
            {
                "value": 10021,
                "label": "Kirwin",
                "label_ar": "كيروين",
                "label_fr": "Kirwin"
            },
            {
                "value": 10022,
                "label": "Kismet",
                "label_ar": "كيسمت",
                "label_fr": "Kismet"
            },
            {
                "value": 10023,
                "label": "Kissee Mills",
                "label_ar": "كيسيه ميلز",
                "label_fr": "Kissee Mills"
            },
            {
                "value": 10024,
                "label": "Kissimmee",
                "label_ar": "كيسمي",
                "label_fr": "Kissimmee"
            },
            {
                "value": 10025,
                "label": "Kite",
                "label_ar": "طائرة ورقية",
                "label_fr": "Cerf-volant"
            },
            {
                "value": 10026,
                "label": "Kittanning",
                "label_ar": "كيتانينج",
                "label_fr": "Kittanning"
            },
            {
                "value": 10027,
                "label": "Kittery",
                "label_ar": "كيتيري",
                "label_fr": "Kittery"
            },
            {
                "value": 10028,
                "label": "Kittery Point",
                "label_ar": "كيتيري بوينت",
                "label_fr": "Kittery Point"
            },
            {
                "value": 10029,
                "label": "Kitts Hill",
                "label_ar": "كيتس هيل",
                "label_fr": "Kitts Hill"
            },
            {
                "value": 10030,
                "label": "Kitty Hawk",
                "label_ar": "كيتي هوك",
                "label_fr": "Kitty Hawk"
            },
            {
                "value": 10031,
                "label": "Klamath",
                "label_ar": "كلاماث",
                "label_fr": "Klamath"
            },
            {
                "value": 10032,
                "label": "Klamath Falls",
                "label_ar": "شلالات كلاماث",
                "label_fr": "Chutes de Klamath"
            },
            {
                "value": 10033,
                "label": "Klamath River",
                "label_ar": "نهر كلاماث",
                "label_fr": "Rivière Klamath"
            },
            {
                "value": 10034,
                "label": "Klawock",
                "label_ar": "كلاوك",
                "label_fr": "Klawock"
            },
            {
                "value": 10035,
                "label": "Klemme",
                "label_ar": "كليم",
                "label_fr": "Klemme"
            },
            {
                "value": 10036,
                "label": "Knapp",
                "label_ar": "كناب",
                "label_fr": "Knapp"
            },
            {
                "value": 10037,
                "label": "Knife River",
                "label_ar": "نهر السكين",
                "label_fr": "Rivière Knife"
            },
            {
                "value": 10038,
                "label": "Knightdale",
                "label_ar": "نايتديل",
                "label_fr": "Knightdale"
            },
            {
                "value": 10039,
                "label": "Knights Landing",
                "label_ar": "فرسان الهبوط",
                "label_fr": "Atterrissage des chevaliers"
            },
            {
                "value": 10040,
                "label": "Knightsen",
                "label_ar": "نايتس",
                "label_fr": "Knightsen"
            },
            {
                "value": 10041,
                "label": "Knightstown",
                "label_ar": "نايتستاون",
                "label_fr": "Knightstown"
            },
            {
                "value": 10042,
                "label": "Knightsville",
                "label_ar": "نايتسفيل",
                "label_fr": "Knightsville"
            },
            {
                "value": 10043,
                "label": "Knippa",
                "label_ar": "كنيبا",
                "label_fr": "Knippa"
            },
            {
                "value": 10044,
                "label": "Knob Lick",
                "label_ar": "لعق المقبض",
                "label_fr": "Bouton lécher"
            },
            {
                "value": 10045,
                "label": "Knob Noster",
                "label_ar": "مقبض نوستر",
                "label_fr": "Bouton Noster"
            },
            {
                "value": 10046,
                "label": "Knott",
                "label_ar": "نوت",
                "label_fr": "Knott"
            },
            {
                "value": 10047,
                "label": "Knox",
                "label_ar": "نوكس",
                "label_fr": "Knox"
            },
            {
                "value": 10048,
                "label": "Knox City",
                "label_ar": "مدينة نوكس",
                "label_fr": "Knox City"
            },
            {
                "value": 10049,
                "label": "Knox Dale",
                "label_ar": "نوكس ديل",
                "label_fr": "Knox Dale"
            },
            {
                "value": 10050,
                "label": "Knoxville",
                "label_ar": "نوكسفيل",
                "label_fr": "Knoxville"
            },
            {
                "value": 10051,
                "label": "Kodak",
                "label_ar": "كوداك",
                "label_fr": "Kodak"
            },
            {
                "value": 10052,
                "label": "Kodiak",
                "label_ar": "كودياك",
                "label_fr": "Kodiak"
            },
            {
                "value": 10053,
                "label": "Kohler",
                "label_ar": "كوهلر",
                "label_fr": "Kohler"
            },
            {
                "value": 10054,
                "label": "Kokomo",
                "label_ar": "كوكومو",
                "label_fr": "Kokomo"
            },
            {
                "value": 10055,
                "label": "Koloa",
                "label_ar": "كولوا",
                "label_fr": "Koloa"
            },
            {
                "value": 10056,
                "label": "Konawa",
                "label_ar": "كوناوا",
                "label_fr": "Konawa"
            },
            {
                "value": 10057,
                "label": "Kongiganak",
                "label_ar": "كونغجاناك",
                "label_fr": "Kongiganak"
            },
            {
                "value": 10058,
                "label": "Kooskia",
                "label_ar": "كوسكيا",
                "label_fr": "Kooskia"
            },
            {
                "value": 10059,
                "label": "Koppel",
                "label_ar": "كوبيل",
                "label_fr": "Koppel"
            },
            {
                "value": 10060,
                "label": "Kopperl",
                "label_ar": "كوبرل",
                "label_fr": "Kopperl"
            },
            {
                "value": 10061,
                "label": "Kosciusko",
                "label_ar": "كوسيوسكو",
                "label_fr": "Kosciusko"
            },
            {
                "value": 10062,
                "label": "Kotlik",
                "label_ar": "كوتليك",
                "label_fr": "Kotlik"
            },
            {
                "value": 10063,
                "label": "Kountze",
                "label_ar": "كونتزي",
                "label_fr": "Kountze"
            },
            {
                "value": 10064,
                "label": "Kouts",
                "label_ar": "كوتس",
                "label_fr": "Kouts"
            },
            {
                "value": 10065,
                "label": "Koyuk",
                "label_ar": "كويوك",
                "label_fr": "Koyuk"
            },
            {
                "value": 10066,
                "label": "Krakow",
                "label_ar": "كراكوف",
                "label_fr": "Cracovie"
            },
            {
                "value": 10067,
                "label": "Kramer",
                "label_ar": "كرامر",
                "label_fr": "Kramer"
            },
            {
                "value": 10068,
                "label": "Krebs",
                "label_ar": "كريبس",
                "label_fr": "Krebs"
            },
            {
                "value": 10069,
                "label": "Kremmling",
                "label_ar": "كرملينج",
                "label_fr": "Kremmling"
            },
            {
                "value": 10070,
                "label": "Krum",
                "label_ar": "كروم",
                "label_fr": "Krum"
            },
            {
                "value": 10071,
                "label": "Kula",
                "label_ar": "كولا",
                "label_fr": "Kula"
            },
            {
                "value": 10072,
                "label": "Kulm",
                "label_ar": "كولم",
                "label_fr": "Kulm"
            },
            {
                "value": 10073,
                "label": "Kulpmont",
                "label_ar": "كولبمونت",
                "label_fr": "Kulpmont"
            },
            {
                "value": 10074,
                "label": "Kuna",
                "label_ar": "كونا",
                "label_fr": "Kuna"
            },
            {
                "value": 10075,
                "label": "Kunkletown",
                "label_ar": "كونكلتاون",
                "label_fr": "Kunkletown"
            },
            {
                "value": 10076,
                "label": "Kure Beach",
                "label_ar": "شاطئ كوري",
                "label_fr": "Plage de Kure"
            },
            {
                "value": 10077,
                "label": "Kurtistown",
                "label_ar": "كورتيستاون",
                "label_fr": "Kurtistown"
            },
            {
                "value": 10078,
                "label": "Kuttawa",
                "label_ar": "كوتاوا",
                "label_fr": "Kuttawa"
            },
            {
                "value": 10079,
                "label": "Kutztown",
                "label_ar": "كوتزتاون",
                "label_fr": "Kutztown"
            },
            {
                "value": 10080,
                "label": "Kwethluk",
                "label_ar": "كويثلوك",
                "label_fr": "Kwethluk"
            },
            {
                "value": 10081,
                "label": "Kykotsmovi Village",
                "label_ar": "قرية Kykotsmovi",
                "label_fr": "Village de Kykotsmovi"
            },
            {
                "value": 10082,
                "label": "Kyle",
                "label_ar": "كايل",
                "label_fr": "Kyle"
            },
            {
                "value": 10083,
                "label": "Kyles Ford",
                "label_ar": "كيليس فورد",
                "label_fr": "Kyles Ford"
            },
            {
                "value": 10084,
                "label": "L'Anse",
                "label_ar": "لانس",
                "label_fr": "L'Anse"
            },
            {
                "value": 10085,
                "label": "La Barge",
                "label_ar": "لا بارج",
                "label_fr": "La Barge"
            },
            {
                "value": 10086,
                "label": "La Belle",
                "label_ar": "لا بيل",
                "label_fr": "La Belle"
            },
            {
                "value": 10087,
                "label": "La Blanca",
                "label_ar": "لا بلانكا",
                "label_fr": "La Blanca"
            },
            {
                "value": 10088,
                "label": "La Canada Flintridge",
                "label_ar": "لا كندا فلينتريدج",
                "label_fr": "La Canada Flintridge"
            },
            {
                "value": 10089,
                "label": "La Center",
                "label_ar": "لا سنتر",
                "label_fr": "La Centre"
            },
            {
                "value": 10090,
                "label": "La Conner",
                "label_ar": "لا كونر",
                "label_fr": "La Conner"
            },
            {
                "value": 10091,
                "label": "La Crescent",
                "label_ar": "لا كريسنت",
                "label_fr": "La Crescent"
            },
            {
                "value": 10092,
                "label": "La Crescenta",
                "label_ar": "لا كريسنتا",
                "label_fr": "La Crescenta"
            },
            {
                "value": 10093,
                "label": "La Crosse",
                "label_ar": "لا كروس",
                "label_fr": "La Crosse"
            },
            {
                "value": 10094,
                "label": "La Cygne",
                "label_ar": "لا سيجن",
                "label_fr": "La Cygne"
            },
            {
                "value": 10095,
                "label": "La Farge",
                "label_ar": "لا فارج",
                "label_fr": "La Farge"
            },
            {
                "value": 10096,
                "label": "La Fayette",
                "label_ar": "لا فاييت",
                "label_fr": "La Fayette"
            },
            {
                "value": 10097,
                "label": "La Feria",
                "label_ar": "لا فيريا",
                "label_fr": "La Feria"
            },
            {
                "value": 10098,
                "label": "La Fontaine",
                "label_ar": "لافونتين",
                "label_fr": "La Fontaine"
            },
            {
                "value": 10099,
                "label": "La Fox",
                "label_ar": "لا فوكس",
                "label_fr": "La Fox"
            },
            {
                "value": 10100,
                "label": "La Grande",
                "label_ar": "لا غراندي",
                "label_fr": "La Grande"
            },
            {
                "value": 10101,
                "label": "La Grange",
                "label_ar": "لا جرانج",
                "label_fr": "La Grange"
            },
            {
                "value": 10102,
                "label": "La Grange Park",
                "label_ar": "حديقة لا جرانج",
                "label_fr": "Parc de la Grange"
            },
            {
                "value": 10103,
                "label": "La Habra",
                "label_ar": "هبرة",
                "label_fr": "La Habra"
            },
            {
                "value": 10104,
                "label": "La Harpe",
                "label_ar": "لا هارب",
                "label_fr": "La Harpe"
            },
            {
                "value": 10105,
                "label": "La Honda",
                "label_ar": "لا هوندا",
                "label_fr": "La Honda"
            },
            {
                "value": 10106,
                "label": "La Jara",
                "label_ar": "لا جارا",
                "label_fr": "La Jara"
            },
            {
                "value": 10107,
                "label": "La Jolla",
                "label_ar": "لا جولا",
                "label_fr": "La Jolla"
            },
            {
                "value": 10108,
                "label": "La Joya",
                "label_ar": "لا جويا",
                "label_fr": "La Joya"
            },
            {
                "value": 10109,
                "label": "La Junta",
                "label_ar": "لا جونتا",
                "label_fr": "La Junta"
            },
            {
                "value": 10110,
                "label": "La Luz",
                "label_ar": "لا لوز",
                "label_fr": "La Luz"
            },
            {
                "value": 10111,
                "label": "La Marque",
                "label_ar": "لا مارك",
                "label_fr": "La Marque"
            },
            {
                "value": 10112,
                "label": "La Mesa",
                "label_ar": "لا ميسا",
                "label_fr": "La Mesa"
            },
            {
                "value": 10113,
                "label": "La Mirada",
                "label_ar": "لا ميرادا",
                "label_fr": "La Mirada"
            },
            {
                "value": 10114,
                "label": "La Moille",
                "label_ar": "لا مويل",
                "label_fr": "La Moille"
            },
            {
                "value": 10115,
                "label": "La Motte",
                "label_ar": "لا موت",
                "label_fr": "La Motte"
            },
            {
                "value": 10116,
                "label": "La Palma",
                "label_ar": "لا بالما",
                "label_fr": "La Palma"
            },
            {
                "value": 10117,
                "label": "La Pine",
                "label_ar": "لا باين",
                "label_fr": "La Pine"
            },
            {
                "value": 10118,
                "label": "La Plata",
                "label_ar": "لا بلاتا",
                "label_fr": "La Plata"
            },
            {
                "value": 10119,
                "label": "La Pointe",
                "label_ar": "لا بوينت",
                "label_fr": "La Pointe"
            },
            {
                "value": 10120,
                "label": "La Porte",
                "label_ar": "لا بورت",
                "label_fr": "La Porte"
            },
            {
                "value": 10121,
                "label": "La Porte City",
                "label_ar": "مدينة لابورت",
                "label_fr": "La Porte City"
            },
            {
                "value": 10122,
                "label": "La Prairie",
                "label_ar": "لا برايري",
                "label_fr": "La Prairie"
            },
            {
                "value": 10123,
                "label": "La Pryor",
                "label_ar": "لا بريور",
                "label_fr": "La Pryor"
            },
            {
                "value": 10124,
                "label": "La Puente",
                "label_ar": "لا بوينتي",
                "label_fr": "La Puente"
            },
            {
                "value": 10125,
                "label": "La Quinta",
                "label_ar": "لا كينتا",
                "label_fr": "La quinta"
            },
            {
                "value": 10126,
                "label": "La Rue",
                "label_ar": "لا رو",
                "label_fr": "la rue"
            },
            {
                "value": 10127,
                "label": "La Sal",
                "label_ar": "لا سال",
                "label_fr": "La Sal"
            },
            {
                "value": 10128,
                "label": "La Salle",
                "label_ar": "لا سال",
                "label_fr": "la salle"
            },
            {
                "value": 10129,
                "label": "La Valle",
                "label_ar": "لا فالي",
                "label_fr": "La Valle"
            },
            {
                "value": 10130,
                "label": "La Vergne",
                "label_ar": "لا فيرجني",
                "label_fr": "La Vergne"
            },
            {
                "value": 10131,
                "label": "La Verne",
                "label_ar": "لا فيرن",
                "label_fr": "La Verne"
            },
            {
                "value": 10132,
                "label": "La Vernia",
                "label_ar": "لا فيرنيا",
                "label_fr": "La Vernia"
            },
            {
                "value": 10133,
                "label": "La Veta",
                "label_ar": "لا فيتا",
                "label_fr": "La Veta"
            },
            {
                "value": 10134,
                "label": "La Vista",
                "label_ar": "لافيستا",
                "label_fr": "La Vista"
            },
            {
                "value": 10135,
                "label": "La'ie",
                "label_ar": "لاي",
                "label_fr": "La'ie"
            },
            {
                "value": 10136,
                "label": "LaBelle",
                "label_ar": "لابيل",
                "label_fr": "LaBelle"
            },
            {
                "value": 10137,
                "label": "LaBolt",
                "label_ar": "لابولت",
                "label_fr": "LaBolt"
            },
            {
                "value": 10138,
                "label": "LaFayette",
                "label_ar": "لافاييت",
                "label_fr": "LaFayette"
            },
            {
                "value": 10139,
                "label": "LaFollette",
                "label_ar": "لافوليت",
                "label_fr": "LaFollette"
            },
            {
                "value": 10140,
                "label": "LaMoure",
                "label_ar": "لامور",
                "label_fr": "LaMoure"
            },
            {
                "value": 10141,
                "label": "LaSalle",
                "label_ar": "لا سال",
                "label_fr": "La salle"
            },
            {
                "value": 10142,
                "label": "LaVerkin",
                "label_ar": "لافيركين",
                "label_fr": "LaVerkin"
            },
            {
                "value": 10143,
                "label": "Labadie",
                "label_ar": "لابادي",
                "label_fr": "Labadie"
            },
            {
                "value": 10144,
                "label": "Labadieville",
                "label_ar": "لاباديفيل",
                "label_fr": "Labadieville"
            },
            {
                "value": 10145,
                "label": "Lac du Flambeau",
                "label_ar": "لاك دو فلامبو",
                "label_fr": "Lac du Flambeau"
            },
            {
                "value": 10146,
                "label": "Lacey",
                "label_ar": "لاسي",
                "label_fr": "Lacé"
            },
            {
                "value": 10147,
                "label": "Laceys Spring",
                "label_ar": "ربيع ليسيس",
                "label_fr": "Laceys Printemps"
            },
            {
                "value": 10148,
                "label": "Laceyville",
                "label_ar": "لاسيفيل",
                "label_fr": "Laceyville"
            },
            {
                "value": 10149,
                "label": "Lachine",
                "label_ar": "لاتشين",
                "label_fr": "Lachine"
            },
            {
                "value": 10150,
                "label": "Lackawaxen",
                "label_ar": "لاكاواكسين",
                "label_fr": "Lackawaxen"
            },
            {
                "value": 10151,
                "label": "Lackey",
                "label_ar": "لاكي",
                "label_fr": "Laquais"
            },
            {
                "value": 10152,
                "label": "Lacombe",
                "label_ar": "لاكومب",
                "label_fr": "Lacombe"
            },
            {
                "value": 10153,
                "label": "Lacon",
                "label_ar": "لا يخدع",
                "label_fr": "La con"
            },
            {
                "value": 10154,
                "label": "Lacona",
                "label_ar": "لاكونا",
                "label_fr": "Lacona"
            },
            {
                "value": 10155,
                "label": "Laconia",
                "label_ar": "لاكونيا",
                "label_fr": "Laconie"
            },
            {
                "value": 10156,
                "label": "Ladd",
                "label_ar": "لاد",
                "label_fr": "Ladd"
            },
            {
                "value": 10157,
                "label": "Laddonia",
                "label_ar": "لادونيا",
                "label_fr": "Laddonia"
            },
            {
                "value": 10158,
                "label": "Ladera Ranch",
                "label_ar": "مزرعة لاديرا",
                "label_fr": "Ranch Ladera"
            },
            {
                "value": 10159,
                "label": "Ladoga",
                "label_ar": "لادوجا",
                "label_fr": "Ladoga"
            },
            {
                "value": 10160,
                "label": "Ladonia",
                "label_ar": "لادونيا",
                "label_fr": "Ladonia"
            },
            {
                "value": 10161,
                "label": "Ladora",
                "label_ar": "لادورا",
                "label_fr": "Ladora"
            },
            {
                "value": 10162,
                "label": "Ladson",
                "label_ar": "لادسون",
                "label_fr": "Ladson"
            },
            {
                "value": 10163,
                "label": "Lady Lake",
                "label_ar": "ليدي ليك",
                "label_fr": "Lady Lake"
            },
            {
                "value": 10164,
                "label": "Ladysmith",
                "label_ar": "ليديسميث",
                "label_fr": "Ladysmith"
            },
            {
                "value": 10165,
                "label": "Lafayette",
                "label_ar": "لافاييت",
                "label_fr": "Lafayette"
            },
            {
                "value": 10166,
                "label": "Lafferty",
                "label_ar": "افرتي",
                "label_fr": "Lafferty"
            },
            {
                "value": 10167,
                "label": "Lafitte",
                "label_ar": "لافيت",
                "label_fr": "Lafitte"
            },
            {
                "value": 10168,
                "label": "Lagrange",
                "label_ar": "لاغرانج",
                "label_fr": "Lagrange"
            },
            {
                "value": 10169,
                "label": "Lagrangeville",
                "label_ar": "لاغرانجفيل",
                "label_fr": "Lagrangeville"
            },
            {
                "value": 10170,
                "label": "Lagro",
                "label_ar": "لاغرو",
                "label_fr": "Lagro"
            },
            {
                "value": 10171,
                "label": "Laguna",
                "label_ar": "لاجونا",
                "label_fr": "Laguna"
            },
            {
                "value": 10172,
                "label": "Laguna Beach",
                "label_ar": "شاطئ لاغونا",
                "label_fr": "Laguna beach"
            },
            {
                "value": 10173,
                "label": "Laguna Hills",
                "label_ar": "لاجونا هيلز",
                "label_fr": "Laguna Hills"
            },
            {
                "value": 10174,
                "label": "Laguna Niguel",
                "label_ar": "لاجونا نيجيل",
                "label_fr": "Laguna Niguel"
            },
            {
                "value": 10175,
                "label": "Laguna Woods",
                "label_ar": "لاجونا وودز",
                "label_fr": "Bois de Laguna"
            },
            {
                "value": 10176,
                "label": "Lagunitas",
                "label_ar": "لاجونيتاس",
                "label_fr": "Lagunitas"
            },
            {
                "value": 10177,
                "label": "Lahaina",
                "label_ar": "لاهاينا",
                "label_fr": "Lahaina"
            },
            {
                "value": 10178,
                "label": "Lahaska",
                "label_ar": "لاهاسكا",
                "label_fr": "Lahaska"
            },
            {
                "value": 10179,
                "label": "Laingsburg",
                "label_ar": "لينجسبورج",
                "label_fr": "Laingsburg"
            },
            {
                "value": 10180,
                "label": "Lake",
                "label_ar": "بحيرة",
                "label_fr": "Lac"
            },
            {
                "value": 10181,
                "label": "Lake Alfred",
                "label_ar": "بحيرة الفريد",
                "label_fr": "Lac Alfred"
            },
            {
                "value": 10182,
                "label": "Lake Andes",
                "label_ar": "بحيرة الأنديز",
                "label_fr": "Lac des Andes"
            },
            {
                "value": 10183,
                "label": "Lake Ann",
                "label_ar": "بحيرة آن",
                "label_fr": "Lac Ann"
            },
            {
                "value": 10184,
                "label": "Lake Ariel",
                "label_ar": "بحيرة ارييل",
                "label_fr": "Lac Ariel"
            },
            {
                "value": 10185,
                "label": "Lake Arrowhead",
                "label_ar": "بحيرة أروهيد",
                "label_fr": "Lake Arrowhead"
            },
            {
                "value": 10186,
                "label": "Lake Arthur",
                "label_ar": "بحيرة آرثر",
                "label_fr": "Lac Arthur"
            },
            {
                "value": 10187,
                "label": "Lake Benton",
                "label_ar": "بحيرة بينتون",
                "label_fr": "Lac Benton"
            },
            {
                "value": 10188,
                "label": "Lake Bluff",
                "label_ar": "بحيرة بلاف",
                "label_fr": "Lake Bluff"
            },
            {
                "value": 10189,
                "label": "Lake Bronson",
                "label_ar": "بحيرة برونسون",
                "label_fr": "Lac Bronson"
            },
            {
                "value": 10190,
                "label": "Lake Buena Vista",
                "label_ar": "بحيرة بوينا فيستا",
                "label_fr": "Lake Buena Vista"
            },
            {
                "value": 10191,
                "label": "Lake Butler",
                "label_ar": "بحيرة بتلر",
                "label_fr": "Lake Butler"
            },
            {
                "value": 10192,
                "label": "Lake Charles",
                "label_ar": "بحيرة تشارلز",
                "label_fr": "Lake Charles"
            },
            {
                "value": 10193,
                "label": "Lake City",
                "label_ar": "مدينة البحيرة",
                "label_fr": "Lake City"
            },
            {
                "value": 10194,
                "label": "Lake Cormorant",
                "label_ar": "بحيرة كورمورانت",
                "label_fr": "Lac Cormorant"
            },
            {
                "value": 10195,
                "label": "Lake Crystal",
                "label_ar": "بحيرة كريستال",
                "label_fr": "Lac Crystal"
            },
            {
                "value": 10196,
                "label": "Lake Dallas",
                "label_ar": "بحيرة دالاس",
                "label_fr": "Lac Dallas"
            },
            {
                "value": 10197,
                "label": "Lake Delton",
                "label_ar": "بحيرة دلتون",
                "label_fr": "Lac Delton"
            },
            {
                "value": 10198,
                "label": "Lake Elmo",
                "label_ar": "بحيرة إلمو",
                "label_fr": "Lac Elmo"
            },
            {
                "value": 10199,
                "label": "Lake Elsinore",
                "label_ar": "بحيرة Elsinore",
                "label_fr": "Lac Elseneur"
            },
            {
                "value": 10200,
                "label": "Lake Forest",
                "label_ar": "ليك فورست",
                "label_fr": "Forêt du lac"
            },
            {
                "value": 10201,
                "label": "Lake Geneva",
                "label_ar": "بحيرة جنيف",
                "label_fr": "lac de Genève"
            },
            {
                "value": 10202,
                "label": "Lake George",
                "label_ar": "بحيرة جورج",
                "label_fr": "Lake George"
            },
            {
                "value": 10203,
                "label": "Lake Grove",
                "label_ar": "بحيرة جروف",
                "label_fr": "Lake Grove"
            },
            {
                "value": 10204,
                "label": "Lake Hamilton",
                "label_ar": "بحيرة هاميلتون",
                "label_fr": "Lac Hamilton"
            },
            {
                "value": 10205,
                "label": "Lake Harmony",
                "label_ar": "بحيرة هارموني",
                "label_fr": "Harmonie du lac"
            },
            {
                "value": 10206,
                "label": "Lake Havasu City",
                "label_ar": "مدينة بحيرة هافاسو",
                "label_fr": "Ville de Lake Havasu"
            },
            {
                "value": 10207,
                "label": "Lake Helen",
                "label_ar": "بحيرة هيلين",
                "label_fr": "Lac Helen"
            },
            {
                "value": 10208,
                "label": "Lake Hiawatha",
                "label_ar": "بحيرة هياواثا",
                "label_fr": "Lac Hiawatha"
            },
            {
                "value": 10209,
                "label": "Lake Hopatcong",
                "label_ar": "بحيرة هوباتكونغ",
                "label_fr": "Lac Hopatcong"
            },
            {
                "value": 10210,
                "label": "Lake Hughes",
                "label_ar": "بحيرة هيوز",
                "label_fr": "Lac Hughes"
            },
            {
                "value": 10211,
                "label": "Lake Isabella",
                "label_ar": "بحيرة إيزابيلا",
                "label_fr": "Lac Isabella"
            },
            {
                "value": 10212,
                "label": "Lake Jackson",
                "label_ar": "بحيرة جاكسون",
                "label_fr": "Lac Jackson"
            },
            {
                "value": 10213,
                "label": "Lake Junaluska",
                "label_ar": "بحيرة جونالوسكا",
                "label_fr": "Lac Junaluska"
            },
            {
                "value": 10214,
                "label": "Lake Katrine",
                "label_ar": "بحيرة كاترين",
                "label_fr": "Lac Katrine"
            },
            {
                "value": 10215,
                "label": "Lake Lillian",
                "label_ar": "بحيرة ليليان",
                "label_fr": "Lac Lillian"
            },
            {
                "value": 10216,
                "label": "Lake Linden",
                "label_ar": "بحيرة ليندن",
                "label_fr": "Lac Linden"
            },
            {
                "value": 10217,
                "label": "Lake Lure",
                "label_ar": "بحيرة لور",
                "label_fr": "Lac Lure"
            },
            {
                "value": 10218,
                "label": "Lake Luzerne",
                "label_ar": "بحيرة لوزيرن",
                "label_fr": "Lac de Luzerne"
            },
            {
                "value": 10219,
                "label": "Lake Lynn",
                "label_ar": "بحيرة لين",
                "label_fr": "Lac Lynn"
            },
            {
                "value": 10220,
                "label": "Lake Mary",
                "label_ar": "بحيرة ماري",
                "label_fr": "Lac Mary"
            },
            {
                "value": 10221,
                "label": "Lake Mills",
                "label_ar": "ليك ميلز",
                "label_fr": "Lake Mills"
            },
            {
                "value": 10222,
                "label": "Lake Monroe",
                "label_ar": "بحيرة مونرو",
                "label_fr": "Lac Monroe"
            },
            {
                "value": 10223,
                "label": "Lake Nebagamon",
                "label_ar": "بحيرة نيبغامون",
                "label_fr": "Lac Nebagamon"
            },
            {
                "value": 10224,
                "label": "Lake Norden",
                "label_ar": "بحيرة نوردن",
                "label_fr": "Lac Norden"
            },
            {
                "value": 10225,
                "label": "Lake Odessa",
                "label_ar": "بحيرة أوديسا",
                "label_fr": "Lac Odessa"
            },
            {
                "value": 10226,
                "label": "Lake Orion",
                "label_ar": "بحيرة أوريون",
                "label_fr": "Lac d'Orion"
            },
            {
                "value": 10227,
                "label": "Lake Oswego",
                "label_ar": "بحيرة أوسويغو",
                "label_fr": "Lac Oswego"
            },
            {
                "value": 10228,
                "label": "Lake Ozark",
                "label_ar": "بحيرة أوزارك",
                "label_fr": "Lac Ozark"
            },
            {
                "value": 10229,
                "label": "Lake Panasoffkee",
                "label_ar": "بحيرة باناسوفكي",
                "label_fr": "Lac Panasoffkee"
            },
            {
                "value": 10230,
                "label": "Lake Park",
                "label_ar": "ليك بارك",
                "label_fr": "Parc du lac"
            },
            {
                "value": 10231,
                "label": "Lake Peekskill",
                "label_ar": "بحيرة بيكسكيل",
                "label_fr": "Lac Peekskill"
            },
            {
                "value": 10232,
                "label": "Lake Placid",
                "label_ar": "البحيره هادئه",
                "label_fr": "Lake Placid"
            },
            {
                "value": 10233,
                "label": "Lake Pleasant",
                "label_ar": "بحيرة بليزانت",
                "label_fr": "Lake Pleasant"
            },
            {
                "value": 10234,
                "label": "Lake Preston",
                "label_ar": "بحيرة بريستون",
                "label_fr": "Lac Preston"
            },
            {
                "value": 10235,
                "label": "Lake Providence",
                "label_ar": "بحيرة بروفيدنس",
                "label_fr": "Lac Providence"
            },
            {
                "value": 10236,
                "label": "Lake Saint Louis",
                "label_ar": "بحيرة سانت لويس",
                "label_fr": "Lac Saint Louis"
            },
            {
                "value": 10237,
                "label": "Lake Shasta",
                "label_ar": "بحيرة شاستا",
                "label_fr": "Lac Shasta"
            },
            {
                "value": 10238,
                "label": "Lake Station",
                "label_ar": "محطة البحيرة",
                "label_fr": "Station du lac"
            },
            {
                "value": 10239,
                "label": "Lake Stevens",
                "label_ar": "بحيرة ستيفنز",
                "label_fr": "Lac Stevens"
            },
            {
                "value": 10240,
                "label": "Lake Tomahawk",
                "label_ar": "بحيرة توماهوك",
                "label_fr": "Lac Tomahawk"
            },
            {
                "value": 10241,
                "label": "Lake Toxaway",
                "label_ar": "بحيرة توكساواي",
                "label_fr": "Lac Toxaway"
            },
            {
                "value": 10242,
                "label": "Lake View",
                "label_ar": "ليك فيو",
                "label_fr": "vue sur le lac"
            },
            {
                "value": 10243,
                "label": "Lake Villa",
                "label_ar": "فيلا ليك",
                "label_fr": "Villa Lac"
            },
            {
                "value": 10244,
                "label": "Lake Village",
                "label_ar": "قرية البحيرة",
                "label_fr": "Village du lac"
            },
            {
                "value": 10245,
                "label": "Lake Waccamaw",
                "label_ar": "بحيرة واكاماو",
                "label_fr": "Lac Waccamaw"
            },
            {
                "value": 10246,
                "label": "Lake Wales",
                "label_ar": "بحيرة ويلز",
                "label_fr": "Lake Wales"
            },
            {
                "value": 10247,
                "label": "Lake Wilson",
                "label_ar": "بحيرة ويلسون",
                "label_fr": "Lac Wilson"
            },
            {
                "value": 10248,
                "label": "Lake Winola",
                "label_ar": "بحيرة وينولا",
                "label_fr": "Lac Winola"
            },
            {
                "value": 10249,
                "label": "Lake Worth",
                "label_ar": "بحيرة وورث",
                "label_fr": "Lake Worth"
            },
            {
                "value": 10250,
                "label": "Lake Zurich",
                "label_ar": "بحيرة زيورخ",
                "label_fr": "Lac de Zurich"
            },
            {
                "value": 10251,
                "label": "Lake in the Hills",
                "label_ar": "بحيرة في التلال",
                "label_fr": "Lac dans les collines"
            },
            {
                "value": 10252,
                "label": "Lakebay",
                "label_ar": "لاكباي",
                "label_fr": "Lakebay"
            },
            {
                "value": 10253,
                "label": "Lakefield",
                "label_ar": "ليكفيلد",
                "label_fr": "Lakefield"
            },
            {
                "value": 10254,
                "label": "Lakehead",
                "label_ar": "ليكهيد",
                "label_fr": "Lakehead"
            },
            {
                "value": 10255,
                "label": "Lakehurst",
                "label_ar": "ليكهورست",
                "label_fr": "Lakehurst"
            },
            {
                "value": 10256,
                "label": "Lakeland",
                "label_ar": "ليكلاند",
                "label_fr": "Lakeland"
            },
            {
                "value": 10257,
                "label": "Lakemont",
                "label_ar": "لاكيمونت",
                "label_fr": "Lakemont"
            },
            {
                "value": 10258,
                "label": "Lakemore",
                "label_ar": "لاكمور",
                "label_fr": "Lakemore"
            },
            {
                "value": 10259,
                "label": "Lakeport",
                "label_ar": "ليكبورت",
                "label_fr": "Lakeport"
            },
            {
                "value": 10260,
                "label": "Lakeside",
                "label_ar": "ليكسايد",
                "label_fr": "Bord du lac"
            },
            {
                "value": 10261,
                "label": "Laketown",
                "label_ar": "ليك تاون",
                "label_fr": "Laketown"
            },
            {
                "value": 10262,
                "label": "Lakeview",
                "label_ar": "ليكفيو",
                "label_fr": "Vue sur le lac"
            },
            {
                "value": 10263,
                "label": "Lakeville",
                "label_ar": "ليكفيل",
                "label_fr": "Lakeville"
            },
            {
                "value": 10264,
                "label": "Lakewood",
                "label_ar": "ليكوود",
                "label_fr": "Lakewood"
            },
            {
                "value": 10265,
                "label": "Lakin",
                "label_ar": "لاكين",
                "label_fr": "Lakin"
            },
            {
                "value": 10266,
                "label": "Lakota",
                "label_ar": "لاكوتا",
                "label_fr": "Lakota"
            },
            {
                "value": 10267,
                "label": "Lamar",
                "label_ar": "لامار",
                "label_fr": "Lamar"
            },
            {
                "value": 10268,
                "label": "Lambert",
                "label_ar": "لامبرت",
                "label_fr": "Lambert"
            },
            {
                "value": 10269,
                "label": "Lamberton",
                "label_ar": "لامبرتون",
                "label_fr": "Lamberton"
            },
            {
                "value": 10270,
                "label": "Lambertville",
                "label_ar": "لامبرتفيل",
                "label_fr": "Lambertville"
            },
            {
                "value": 10271,
                "label": "Lambsburg",
                "label_ar": "لامبسبورغ",
                "label_fr": "Lambsburg"
            },
            {
                "value": 10272,
                "label": "Lame Deer",
                "label_ar": "أعرج الغزلان",
                "label_fr": "Cerf boiteux"
            },
            {
                "value": 10273,
                "label": "Lamesa",
                "label_ar": "لامسا",
                "label_fr": "Lamesa"
            },
            {
                "value": 10274,
                "label": "Lamoni",
                "label_ar": "لاموني",
                "label_fr": "Lamoni"
            },
            {
                "value": 10275,
                "label": "Lamont",
                "label_ar": "لامونت",
                "label_fr": "Lamont"
            },
            {
                "value": 10276,
                "label": "Lampasas",
                "label_ar": "لامباساس",
                "label_fr": "Lampasas"
            },
            {
                "value": 10277,
                "label": "Lamy",
                "label_ar": "لامي",
                "label_fr": "Lamy"
            },
            {
                "value": 10278,
                "label": "Lanai",
                "label_ar": "لاناي",
                "label_fr": "Lanai"
            },
            {
                "value": 10279,
                "label": "Lanark",
                "label_ar": "لانارك",
                "label_fr": "Lanark"
            },
            {
                "value": 10280,
                "label": "Lancaster",
                "label_ar": "لانكستر",
                "label_fr": "Lancaster"
            },
            {
                "value": 10281,
                "label": "Lancing",
                "label_ar": "الطعن",
                "label_fr": "Lancing"
            },
            {
                "value": 10282,
                "label": "Land O' Lakes",
                "label_ar": "Land O 'Lakes",
                "label_fr": "Land O 'Lakes"
            },
            {
                "value": 10283,
                "label": "Landenberg",
                "label_ar": "Landenberg",
                "label_fr": "Landenberg"
            },
            {
                "value": 10284,
                "label": "Lander",
                "label_ar": "لاندر",
                "label_fr": "Lander"
            },
            {
                "value": 10285,
                "label": "Landing",
                "label_ar": "هبوط",
                "label_fr": "Atterrissage"
            },
            {
                "value": 10286,
                "label": "Landis",
                "label_ar": "لانديس",
                "label_fr": "Landis"
            },
            {
                "value": 10287,
                "label": "Landisburg",
                "label_ar": "لانديسبورغ",
                "label_fr": "Landisburg"
            },
            {
                "value": 10288,
                "label": "Landisville",
                "label_ar": "لانديسفيل",
                "label_fr": "Landisville"
            },
            {
                "value": 10289,
                "label": "Landrum",
                "label_ar": "لاندروم",
                "label_fr": "Landrum"
            },
            {
                "value": 10290,
                "label": "Lanesboro",
                "label_ar": "لانسبورو",
                "label_fr": "Lanesboro"
            },
            {
                "value": 10291,
                "label": "Lanesville",
                "label_ar": "لانيسفيل",
                "label_fr": "Lanesville"
            },
            {
                "value": 10292,
                "label": "Lanett",
                "label_ar": "لانيت",
                "label_fr": "Lanett"
            },
            {
                "value": 10293,
                "label": "Laneville",
                "label_ar": "لانفيل",
                "label_fr": "Laneville"
            },
            {
                "value": 10294,
                "label": "Lanexa",
                "label_ar": "لانكسا",
                "label_fr": "Lanexa"
            },
            {
                "value": 10295,
                "label": "Langdon",
                "label_ar": "لانغدون",
                "label_fr": "Langdon"
            },
            {
                "value": 10296,
                "label": "Langeloth",
                "label_ar": "لانجيلوث",
                "label_fr": "Langeloth"
            },
            {
                "value": 10297,
                "label": "Langford",
                "label_ar": "لانجفورد",
                "label_fr": "Langford"
            },
            {
                "value": 10298,
                "label": "Langhorne",
                "label_ar": "لانغورن",
                "label_fr": "Langhorne"
            },
            {
                "value": 10299,
                "label": "Langley",
                "label_ar": "لانجلي",
                "label_fr": "Langley"
            },
            {
                "value": 10300,
                "label": "Langston",
                "label_ar": "لانجستون",
                "label_fr": "Langston"
            },
            {
                "value": 10301,
                "label": "Langsville",
                "label_ar": "لانجسفيل",
                "label_fr": "Langsville"
            },
            {
                "value": 10302,
                "label": "Lanham",
                "label_ar": "لانهام",
                "label_fr": "Lanham"
            },
            {
                "value": 10303,
                "label": "Lanoka Harbor",
                "label_ar": "ميناء لانوكا",
                "label_fr": "Port de Lanoka"
            },
            {
                "value": 10304,
                "label": "Lansdale",
                "label_ar": "لانسديل",
                "label_fr": "Lansdale"
            },
            {
                "value": 10305,
                "label": "Lansdowne",
                "label_ar": "لانسداون",
                "label_fr": "Lansdowne"
            },
            {
                "value": 10306,
                "label": "Lanse",
                "label_ar": "لانس",
                "label_fr": "Lanse"
            },
            {
                "value": 10307,
                "label": "Lansford",
                "label_ar": "لانسفورد",
                "label_fr": "Lansford"
            },
            {
                "value": 10308,
                "label": "Lansing",
                "label_ar": "لانسينغ",
                "label_fr": "Lansing"
            },
            {
                "value": 10309,
                "label": "Lantry",
                "label_ar": "لانترى",
                "label_fr": "Lantry"
            },
            {
                "value": 10310,
                "label": "Laona",
                "label_ar": "لاونا",
                "label_fr": "Laona"
            },
            {
                "value": 10311,
                "label": "Laotto",
                "label_ar": "لوتو",
                "label_fr": "Laotto"
            },
            {
                "value": 10312,
                "label": "Lapeer",
                "label_ar": "لابير",
                "label_fr": "Lapeer"
            },
            {
                "value": 10313,
                "label": "Lapel",
                "label_ar": "طية صدر السترة",
                "label_fr": "Revers"
            },
            {
                "value": 10314,
                "label": "Lapine",
                "label_ar": "لابين",
                "label_fr": "Lapine"
            },
            {
                "value": 10315,
                "label": "Laplace",
                "label_ar": "لابلاس",
                "label_fr": "Laplace"
            },
            {
                "value": 10316,
                "label": "Laporte",
                "label_ar": "لا بورت",
                "label_fr": "Laporte"
            },
            {
                "value": 10317,
                "label": "Lapwai",
                "label_ar": "لابواي",
                "label_fr": "Lapwai"
            },
            {
                "value": 10318,
                "label": "Laquey",
                "label_ar": "لاكي",
                "label_fr": "Laquey"
            },
            {
                "value": 10319,
                "label": "Laramie",
                "label_ar": "لارامي",
                "label_fr": "Laramie"
            },
            {
                "value": 10320,
                "label": "Larchmont",
                "label_ar": "لارشمونت",
                "label_fr": "Larchmont"
            },
            {
                "value": 10321,
                "label": "Larchwood",
                "label_ar": "لارشوود",
                "label_fr": "Mélèze"
            },
            {
                "value": 10322,
                "label": "Laredo",
                "label_ar": "لاريدو",
                "label_fr": "Laredo"
            },
            {
                "value": 10323,
                "label": "Largo",
                "label_ar": "لارجو",
                "label_fr": "Largo"
            },
            {
                "value": 10324,
                "label": "Larimore",
                "label_ar": "لاريمور",
                "label_fr": "Larimore"
            },
            {
                "value": 10325,
                "label": "Larkspur",
                "label_ar": "لاركسبور",
                "label_fr": "Larkspur"
            },
            {
                "value": 10326,
                "label": "Larned",
                "label_ar": "لارنيد",
                "label_fr": "Larned"
            },
            {
                "value": 10327,
                "label": "Larose",
                "label_ar": "لاروس",
                "label_fr": "Larose"
            },
            {
                "value": 10328,
                "label": "Las Animas",
                "label_ar": "لاس أنيماس",
                "label_fr": "Las Animas"
            },
            {
                "value": 10329,
                "label": "Las Cruces",
                "label_ar": "لاس كروسيس",
                "label_fr": "Las Cruces"
            },
            {
                "value": 10330,
                "label": "Las Vegas",
                "label_ar": "لاس فيجاس",
                "label_fr": "Las Vegas"
            },
            {
                "value": 10331,
                "label": "Lascassas",
                "label_ar": "لاسكاساس",
                "label_fr": "Lascassas"
            },
            {
                "value": 10332,
                "label": "Latexo",
                "label_ar": "لاتكسو",
                "label_fr": "Latexo"
            },
            {
                "value": 10333,
                "label": "Latham",
                "label_ar": "لاثام",
                "label_fr": "Latham"
            },
            {
                "value": 10334,
                "label": "Lathrop",
                "label_ar": "لاثروب",
                "label_fr": "Lathrop"
            },
            {
                "value": 10335,
                "label": "Latimer",
                "label_ar": "لاتيمر",
                "label_fr": "Latimer"
            },
            {
                "value": 10336,
                "label": "Laton",
                "label_ar": "لاتون",
                "label_fr": "Laton"
            },
            {
                "value": 10337,
                "label": "Latonia",
                "label_ar": "لاتونيا",
                "label_fr": "Latonie"
            },
            {
                "value": 10338,
                "label": "Latrobe",
                "label_ar": "لاتروب",
                "label_fr": "Latrobe"
            },
            {
                "value": 10339,
                "label": "Latta",
                "label_ar": "لاتا",
                "label_fr": "Latta"
            },
            {
                "value": 10340,
                "label": "Lattimer",
                "label_ar": "لاتيمر",
                "label_fr": "Lattimer"
            },
            {
                "value": 10341,
                "label": "Lattimore",
                "label_ar": "لاتيمور",
                "label_fr": "Lattimore"
            },
            {
                "value": 10342,
                "label": "Lauderdale",
                "label_ar": "لودرديل",
                "label_fr": "Lauderdale"
            },
            {
                "value": 10343,
                "label": "Laughlin",
                "label_ar": "ضحك",
                "label_fr": "Laughlin"
            },
            {
                "value": 10344,
                "label": "Laughlintown",
                "label_ar": "اضحك",
                "label_fr": "Laughlintown"
            },
            {
                "value": 10345,
                "label": "Laura",
                "label_ar": "لورا",
                "label_fr": "Laura"
            },
            {
                "value": 10346,
                "label": "Laurel",
                "label_ar": "لوريل",
                "label_fr": "Laurier"
            },
            {
                "value": 10347,
                "label": "Laurel Hill",
                "label_ar": "لوريل هيل",
                "label_fr": "Laurel Hill"
            },
            {
                "value": 10348,
                "label": "Laurel Springs",
                "label_ar": "لوريل سبرينجز",
                "label_fr": "Laurel Springs"
            },
            {
                "value": 10349,
                "label": "Laurelton",
                "label_ar": "لوريلتون",
                "label_fr": "Laurelton"
            },
            {
                "value": 10350,
                "label": "Laurelville",
                "label_ar": "لوريلفيل",
                "label_fr": "Laurelville"
            },
            {
                "value": 10351,
                "label": "Laurens",
                "label_ar": "لورينز",
                "label_fr": "Laurens"
            },
            {
                "value": 10352,
                "label": "Laurinburg",
                "label_ar": "لورينبورغ",
                "label_fr": "Laurinburg"
            },
            {
                "value": 10353,
                "label": "Laurys Station",
                "label_ar": "محطة لوريس",
                "label_fr": "Gare de Laurys"
            },
            {
                "value": 10354,
                "label": "Lavaca",
                "label_ar": "لافاكا",
                "label_fr": "Lavaca"
            },
            {
                "value": 10355,
                "label": "Lavalette",
                "label_ar": "لافاليت",
                "label_fr": "Lavalette"
            },
            {
                "value": 10356,
                "label": "Lavallette",
                "label_ar": "لافاليت",
                "label_fr": "Lavallette"
            },
            {
                "value": 10357,
                "label": "Laveen",
                "label_ar": "لافين",
                "label_fr": "Laveen"
            },
            {
                "value": 10358,
                "label": "Laverne",
                "label_ar": "لافيرن",
                "label_fr": "Laverne"
            },
            {
                "value": 10359,
                "label": "Lavon",
                "label_ar": "لافون",
                "label_fr": "Lavon"
            },
            {
                "value": 10360,
                "label": "Lavonia",
                "label_ar": "لافونيا",
                "label_fr": "Lavonia"
            },
            {
                "value": 10361,
                "label": "Lawler",
                "label_ar": "لولر",
                "label_fr": "Lawler"
            },
            {
                "value": 10362,
                "label": "Lawn",
                "label_ar": "العشب",
                "label_fr": "Pelouse"
            },
            {
                "value": 10363,
                "label": "Lawndale",
                "label_ar": "لونديل",
                "label_fr": "Lawndale"
            },
            {
                "value": 10364,
                "label": "Lawnside",
                "label_ar": "الحديقة",
                "label_fr": "Au bord de la pelouse"
            },
            {
                "value": 10365,
                "label": "Lawrence",
                "label_ar": "لورانس",
                "label_fr": "Lawrence"
            },
            {
                "value": 10366,
                "label": "Lawrenceburg",
                "label_ar": "لورنسبرج",
                "label_fr": "Lawrenceburg"
            },
            {
                "value": 10367,
                "label": "Lawrenceville",
                "label_ar": "لورنسفيل",
                "label_fr": "Lawrenceville"
            },
            {
                "value": 10368,
                "label": "Lawson",
                "label_ar": "لوسون",
                "label_fr": "Lawson"
            },
            {
                "value": 10369,
                "label": "Lawton",
                "label_ar": "لوتون",
                "label_fr": "Lawton"
            },
            {
                "value": 10370,
                "label": "Layton",
                "label_ar": "لايتون",
                "label_fr": "Layton"
            },
            {
                "value": 10371,
                "label": "Laytonville",
                "label_ar": "لايتونفيل",
                "label_fr": "Laytonville"
            },
            {
                "value": 10372,
                "label": "Le Center",
                "label_ar": "لو سنتر",
                "label_fr": "Le Centre"
            },
            {
                "value": 10373,
                "label": "Le Grand",
                "label_ar": "لو جراند",
                "label_fr": "Le Grand"
            },
            {
                "value": 10374,
                "label": "Le Mars",
                "label_ar": "لو مارس",
                "label_fr": "Le Mars"
            },
            {
                "value": 10375,
                "label": "Le Raysville",
                "label_ar": "لو رايسفيل",
                "label_fr": "Le Raysville"
            },
            {
                "value": 10376,
                "label": "Le Roy",
                "label_ar": "لو روي",
                "label_fr": "Le Roy"
            },
            {
                "value": 10377,
                "label": "Le Sueur",
                "label_ar": "لو سوير",
                "label_fr": "Le Sueur"
            },
            {
                "value": 10378,
                "label": "LeClaire",
                "label_ar": "ليكلير",
                "label_fr": "LeClaire"
            },
            {
                "value": 10379,
                "label": "LeRoy",
                "label_ar": "ليروي",
                "label_fr": "LeRoy"
            },
            {
                "value": 10380,
                "label": "Lead",
                "label_ar": "قيادة",
                "label_fr": "Conduire"
            },
            {
                "value": 10381,
                "label": "Lead Hill",
                "label_ar": "ليد هيل",
                "label_fr": "Lead Hill"
            },
            {
                "value": 10382,
                "label": "Leadville",
                "label_ar": "ليدفيل",
                "label_fr": "Leadville"
            },
            {
                "value": 10383,
                "label": "Leadwood",
                "label_ar": "ليدوود",
                "label_fr": "Bois de plomb"
            },
            {
                "value": 10384,
                "label": "Leaf River",
                "label_ar": "نهر ليف",
                "label_fr": "Rivière aux Feuilles"
            },
            {
                "value": 10385,
                "label": "League City",
                "label_ar": "مدينة الدوري",
                "label_fr": "League City"
            },
            {
                "value": 10386,
                "label": "Leakesville",
                "label_ar": "ليكسفيل",
                "label_fr": "Leakesville"
            },
            {
                "value": 10387,
                "label": "Leakey",
                "label_ar": "ليكي",
                "label_fr": "Leakey"
            },
            {
                "value": 10388,
                "label": "Leander",
                "label_ar": "ليندر",
                "label_fr": "Maigre"
            },
            {
                "value": 10389,
                "label": "Leary",
                "label_ar": "ليري",
                "label_fr": "Leary"
            },
            {
                "value": 10390,
                "label": "Leavenworth",
                "label_ar": "ليفنوورث",
                "label_fr": "Leavenworth"
            },
            {
                "value": 10391,
                "label": "Leavittsburg",
                "label_ar": "ليفيتسبرغ",
                "label_fr": "Leavittsburg"
            },
            {
                "value": 10392,
                "label": "Leawood",
                "label_ar": "ليوود",
                "label_fr": "Leawood"
            },
            {
                "value": 10393,
                "label": "Lebanon",
                "label_ar": "لبنان",
                "label_fr": "Liban"
            },
            {
                "value": 10394,
                "label": "Lebanon Junction",
                "label_ar": "مفرق لبنان",
                "label_fr": "Jonction du Liban"
            },
            {
                "value": 10395,
                "label": "Lebec",
                "label_ar": "ليبيك",
                "label_fr": "Lebec"
            },
            {
                "value": 10396,
                "label": "Lebo",
                "label_ar": "ليبو",
                "label_fr": "Lebo"
            },
            {
                "value": 10397,
                "label": "Leburn",
                "label_ar": "ليبرن",
                "label_fr": "Leburn"
            },
            {
                "value": 10398,
                "label": "Lecanto",
                "label_ar": "ليكانتو",
                "label_fr": "Lecanto"
            },
            {
                "value": 10399,
                "label": "Lecompte",
                "label_ar": "ليكومبتي",
                "label_fr": "Lecompte"
            },
            {
                "value": 10400,
                "label": "Lecompton",
                "label_ar": "ليكومبتون",
                "label_fr": "Lecompton"
            },
            {
                "value": 10401,
                "label": "Ledbetter",
                "label_ar": "ليدبيتر",
                "label_fr": "Ledbetter"
            },
            {
                "value": 10402,
                "label": "Lederach",
                "label_ar": "ليديراخ",
                "label_fr": "Lederach"
            },
            {
                "value": 10403,
                "label": "Ledgewood",
                "label_ar": "ليدجوود",
                "label_fr": "Ledgewood"
            },
            {
                "value": 10404,
                "label": "Ledyard",
                "label_ar": "ليديارد",
                "label_fr": "Ledyard"
            },
            {
                "value": 10405,
                "label": "Ledyard Center",
                "label_ar": "مركز ليديارد",
                "label_fr": "Centre Ledyard"
            },
            {
                "value": 10406,
                "label": "Lee",
                "label_ar": "لي",
                "label_fr": "Lee"
            },
            {
                "value": 10407,
                "label": "Lee Center",
                "label_ar": "مركز لي",
                "label_fr": "Lee Center"
            },
            {
                "value": 10408,
                "label": "Lee's Summit",
                "label_ar": "قمة لي",
                "label_fr": "Sommet de Lee"
            },
            {
                "value": 10409,
                "label": "Leechburg",
                "label_ar": "ليتشبورج",
                "label_fr": "Leechburg"
            },
            {
                "value": 10410,
                "label": "Leedey",
                "label_ar": "ليدي",
                "label_fr": "Leedey"
            },
            {
                "value": 10411,
                "label": "Leeds",
                "label_ar": "ليدز",
                "label_fr": "Leeds"
            },
            {
                "value": 10412,
                "label": "Leeper",
                "label_ar": "ليبر",
                "label_fr": "Leeper"
            },
            {
                "value": 10413,
                "label": "Leesburg",
                "label_ar": "ليسبورغ",
                "label_fr": "Leesburg"
            },
            {
                "value": 10414,
                "label": "Leesport",
                "label_ar": "ليسبورت",
                "label_fr": "Leesport"
            },
            {
                "value": 10415,
                "label": "Leesville",
                "label_ar": "ليسفيل",
                "label_fr": "Leesville"
            },
            {
                "value": 10416,
                "label": "Leeton",
                "label_ar": "ليتون",
                "label_fr": "Leeton"
            },
            {
                "value": 10417,
                "label": "Leetonia",
                "label_ar": "ليتونيا",
                "label_fr": "Leetonia"
            },
            {
                "value": 10418,
                "label": "Leetsdale",
                "label_ar": "ليتسدال",
                "label_fr": "Leetsdale"
            },
            {
                "value": 10419,
                "label": "Lefor",
                "label_ar": "ليفور",
                "label_fr": "Lefor"
            },
            {
                "value": 10420,
                "label": "Lefors",
                "label_ar": "ليفورز",
                "label_fr": "Lefors"
            },
            {
                "value": 10421,
                "label": "Lehi",
                "label_ar": "ليحي",
                "label_fr": "Léhi"
            },
            {
                "value": 10422,
                "label": "Lehigh",
                "label_ar": "ليهاي",
                "label_fr": "Lehigh"
            },
            {
                "value": 10423,
                "label": "Lehigh Acres",
                "label_ar": "ليهاي فدان",
                "label_fr": "Lehigh Acres"
            },
            {
                "value": 10424,
                "label": "Lehighton",
                "label_ar": "ليهايتون",
                "label_fr": "Lehighton"
            },
            {
                "value": 10425,
                "label": "Lehman",
                "label_ar": "ليمان",
                "label_fr": "Lehman"
            },
            {
                "value": 10426,
                "label": "Lehr",
                "label_ar": "لير",
                "label_fr": "Lehr"
            },
            {
                "value": 10427,
                "label": "Leicester",
                "label_ar": "ليستر",
                "label_fr": "Leicester"
            },
            {
                "value": 10428,
                "label": "Leigh",
                "label_ar": "لي",
                "label_fr": "Leigh"
            },
            {
                "value": 10429,
                "label": "Leighton",
                "label_ar": "لايتون",
                "label_fr": "Leighton"
            },
            {
                "value": 10430,
                "label": "Leipsic",
                "label_ar": "ليبسيك",
                "label_fr": "Leipsic"
            },
            {
                "value": 10431,
                "label": "Leitchfield",
                "label_ar": "ليتشفيلد",
                "label_fr": "Leitchfield"
            },
            {
                "value": 10432,
                "label": "Leland",
                "label_ar": "ليلاند",
                "label_fr": "Leland"
            },
            {
                "value": 10433,
                "label": "Lemasters",
                "label_ar": "Lemasters",
                "label_fr": "Lemasters"
            },
            {
                "value": 10434,
                "label": "Lemmon",
                "label_ar": "ليمون",
                "label_fr": "Lemmon"
            },
            {
                "value": 10435,
                "label": "Lemon Grove",
                "label_ar": "ليمون جروف",
                "label_fr": "Lemon Grove"
            },
            {
                "value": 10436,
                "label": "Lemont",
                "label_ar": "ليمونت",
                "label_fr": "Lemont"
            },
            {
                "value": 10437,
                "label": "Lemont Furnace",
                "label_ar": "فرن ليمونت",
                "label_fr": "Fournaise Lemont"
            },
            {
                "value": 10438,
                "label": "Lemoore",
                "label_ar": "ليمور",
                "label_fr": "Lemoore"
            },
            {
                "value": 10439,
                "label": "Lemoyne",
                "label_ar": "ليموين",
                "label_fr": "Lemoyne"
            },
            {
                "value": 10440,
                "label": "Lempster",
                "label_ar": "ليمبستر",
                "label_fr": "Lempster"
            },
            {
                "value": 10441,
                "label": "Lena",
                "label_ar": "لينا",
                "label_fr": "Lena"
            },
            {
                "value": 10442,
                "label": "Lenapah",
                "label_ar": "Lenapah",
                "label_fr": "Lenapah"
            },
            {
                "value": 10443,
                "label": "Lenexa",
                "label_ar": "لينيكسا",
                "label_fr": "Lenexa"
            },
            {
                "value": 10444,
                "label": "Lengby",
                "label_ar": "لينجبي",
                "label_fr": "Lengby"
            },
            {
                "value": 10445,
                "label": "Lenhartsville",
                "label_ar": "لينهارتسفيل",
                "label_fr": "Lenhartsville"
            },
            {
                "value": 10446,
                "label": "Lenni",
                "label_ar": "ليني",
                "label_fr": "Lenni"
            },
            {
                "value": 10447,
                "label": "Lennon",
                "label_ar": "لينون",
                "label_fr": "Lennon"
            },
            {
                "value": 10448,
                "label": "Lennox",
                "label_ar": "لينوكس",
                "label_fr": "Lennox"
            },
            {
                "value": 10449,
                "label": "Lenoir",
                "label_ar": "لينوار",
                "label_fr": "Lenoir"
            },
            {
                "value": 10450,
                "label": "Lenoir City",
                "label_ar": "مدينة لينوار",
                "label_fr": "Ville de Lenoir"
            },
            {
                "value": 10451,
                "label": "Lenora",
                "label_ar": "لينورا",
                "label_fr": "Lenora"
            },
            {
                "value": 10452,
                "label": "Lenore",
                "label_ar": "لينور",
                "label_fr": "Lenore"
            },
            {
                "value": 10453,
                "label": "Lenox",
                "label_ar": "لينوكس",
                "label_fr": "Lenox"
            },
            {
                "value": 10454,
                "label": "Leo",
                "label_ar": "ليو",
                "label_fr": "Leo"
            },
            {
                "value": 10455,
                "label": "Leola",
                "label_ar": "ليولا",
                "label_fr": "Leola"
            },
            {
                "value": 10456,
                "label": "Leoma",
                "label_ar": "ليوما",
                "label_fr": "Leoma"
            },
            {
                "value": 10457,
                "label": "Leominster",
                "label_ar": "ليومينستر",
                "label_fr": "Léominster"
            },
            {
                "value": 10458,
                "label": "Leon",
                "label_ar": "ليون",
                "label_fr": "Léon"
            },
            {
                "value": 10459,
                "label": "Leona",
                "label_ar": "ليونا",
                "label_fr": "Leona"
            },
            {
                "value": 10460,
                "label": "Leonard",
                "label_ar": "ليونارد",
                "label_fr": "Léonard"
            },
            {
                "value": 10461,
                "label": "Leonardo",
                "label_ar": "ليوناردو",
                "label_fr": "Léonard"
            },
            {
                "value": 10462,
                "label": "Leonardsville",
                "label_ar": "ليوناردسفيل",
                "label_fr": "Léonardsville"
            },
            {
                "value": 10463,
                "label": "Leonardtown",
                "label_ar": "ليوناردتاون",
                "label_fr": "Leonardtown"
            },
            {
                "value": 10464,
                "label": "Leonardville",
                "label_ar": "ليوناردفيل",
                "label_fr": "Leonardville"
            },
            {
                "value": 10465,
                "label": "Leonia",
                "label_ar": "ليونيا",
                "label_fr": "Léonia"
            },
            {
                "value": 10466,
                "label": "Leonidas",
                "label_ar": "ليونيداس",
                "label_fr": "Léonidas"
            },
            {
                "value": 10467,
                "label": "Leonore",
                "label_ar": "ليونور",
                "label_fr": "Léonore"
            },
            {
                "value": 10468,
                "label": "Leonville",
                "label_ar": "ليونفيل",
                "label_fr": "Léonville"
            },
            {
                "value": 10469,
                "label": "Leopold",
                "label_ar": "ليوبولد",
                "label_fr": "Léopold"
            },
            {
                "value": 10470,
                "label": "Leopolis",
                "label_ar": "ليوبوليس",
                "label_fr": "Léopolis"
            },
            {
                "value": 10471,
                "label": "Leoti",
                "label_ar": "ليوتي",
                "label_fr": "Léoti"
            },
            {
                "value": 10472,
                "label": "Lepanto",
                "label_ar": "ليبانتو",
                "label_fr": "Lépante"
            },
            {
                "value": 10473,
                "label": "Lerna",
                "label_ar": "ليرنا",
                "label_fr": "Lerna"
            },
            {
                "value": 10474,
                "label": "Leroy",
                "label_ar": "ليروي",
                "label_fr": "Leroy"
            },
            {
                "value": 10475,
                "label": "Lesage",
                "label_ar": "ليساج",
                "label_fr": "Lesage"
            },
            {
                "value": 10476,
                "label": "Leslie",
                "label_ar": "ليزلي",
                "label_fr": "Leslie"
            },
            {
                "value": 10477,
                "label": "Lester",
                "label_ar": "ليستر",
                "label_fr": "Lester"
            },
            {
                "value": 10478,
                "label": "Lester Prairie",
                "label_ar": "ليستر بريري",
                "label_fr": "Lester Prairie"
            },
            {
                "value": 10479,
                "label": "Lesterville",
                "label_ar": "ليسترفيل",
                "label_fr": "Lesterville"
            },
            {
                "value": 10480,
                "label": "Letart",
                "label_ar": "ليتارت",
                "label_fr": "Letart"
            },
            {
                "value": 10481,
                "label": "Letohatchee",
                "label_ar": "Letohatchee",
                "label_fr": "Letohatchee"
            },
            {
                "value": 10482,
                "label": "Letts",
                "label_ar": "ليتس",
                "label_fr": "Letts"
            },
            {
                "value": 10483,
                "label": "Levan",
                "label_ar": "ليفان",
                "label_fr": "Levan"
            },
            {
                "value": 10484,
                "label": "Levant",
                "label_ar": "الشرق",
                "label_fr": "Levant"
            },
            {
                "value": 10485,
                "label": "Levelland",
                "label_ar": "ليفيلاند",
                "label_fr": "Levelland"
            },
            {
                "value": 10486,
                "label": "Levelock",
                "label_ar": "ليفلوك",
                "label_fr": "Levelock"
            },
            {
                "value": 10487,
                "label": "Levels",
                "label_ar": "المستويات",
                "label_fr": "Les niveaux"
            },
            {
                "value": 10488,
                "label": "Leverett",
                "label_ar": "ليفريت",
                "label_fr": "Leverett"
            },
            {
                "value": 10489,
                "label": "Levering",
                "label_ar": "رفع",
                "label_fr": "Lever"
            },
            {
                "value": 10490,
                "label": "Levittown",
                "label_ar": "ليفيتاون",
                "label_fr": "Levittown"
            },
            {
                "value": 10491,
                "label": "Lewellen",
                "label_ar": "لويلين",
                "label_fr": "Lewellen"
            },
            {
                "value": 10492,
                "label": "Lewes",
                "label_ar": "لويس",
                "label_fr": "Lewes"
            },
            {
                "value": 10493,
                "label": "Lewis",
                "label_ar": "لويس",
                "label_fr": "Lewis"
            },
            {
                "value": 10494,
                "label": "Lewis Center",
                "label_ar": "مركز لويس",
                "label_fr": "Lewis Center"
            },
            {
                "value": 10495,
                "label": "Lewis Run",
                "label_ar": "لويس ران",
                "label_fr": "Lewis Run"
            },
            {
                "value": 10496,
                "label": "Lewisberry",
                "label_ar": "لويسبيري",
                "label_fr": "Lewisberry"
            },
            {
                "value": 10497,
                "label": "Lewisburg",
                "label_ar": "لويسبورغ",
                "label_fr": "Lewisburg"
            },
            {
                "value": 10498,
                "label": "Lewisport",
                "label_ar": "Lewisport",
                "label_fr": "Lewisport"
            },
            {
                "value": 10499,
                "label": "Lewiston",
                "label_ar": "لويستون",
                "label_fr": "Lewiston"
            },
            {
                "value": 10500,
                "label": "Lewiston Woodville",
                "label_ar": "لويستون وودفيل",
                "label_fr": "Lewiston Woodville"
            },
            {
                "value": 10501,
                "label": "Lewistown",
                "label_ar": "لويستاون",
                "label_fr": "Lewistown"
            },
            {
                "value": 10502,
                "label": "Lewisville",
                "label_ar": "لويسفيل",
                "label_fr": "Lewisville"
            },
            {
                "value": 10503,
                "label": "Lexa",
                "label_ar": "ليكسا",
                "label_fr": "Lexa"
            },
            {
                "value": 10504,
                "label": "Lexington",
                "label_ar": "ليكسينغتون",
                "label_fr": "Lexington"
            },
            {
                "value": 10505,
                "label": "Lexington Park",
                "label_ar": "ليكسينغتون بارك",
                "label_fr": "Lexington Park"
            },
            {
                "value": 10506,
                "label": "Libby",
                "label_ar": "ليبي",
                "label_fr": "Libby"
            },
            {
                "value": 10507,
                "label": "Liberal",
                "label_ar": "ليبرالية",
                "label_fr": "Libéral"
            },
            {
                "value": 10508,
                "label": "Liberty",
                "label_ar": "حرية",
                "label_fr": "Liberté"
            },
            {
                "value": 10509,
                "label": "Liberty Center",
                "label_ar": "مركز الحرية",
                "label_fr": "Liberty Center"
            },
            {
                "value": 10510,
                "label": "Liberty Corner",
                "label_ar": "ركن الحرية",
                "label_fr": "Coin de la Liberté"
            },
            {
                "value": 10511,
                "label": "Liberty Hill",
                "label_ar": "ليبرتي هيل",
                "label_fr": "Liberty Hill"
            },
            {
                "value": 10512,
                "label": "Liberty Lake",
                "label_ar": "بحيرة الحرية",
                "label_fr": "Liberty Lake"
            },
            {
                "value": 10513,
                "label": "Libertytown",
                "label_ar": "ليبرتيتاون",
                "label_fr": "Libertytown"
            },
            {
                "value": 10514,
                "label": "Libertyville",
                "label_ar": "Libertyville",
                "label_fr": "Libertyville"
            },
            {
                "value": 10515,
                "label": "Lick Creek",
                "label_ar": "ليك كريك",
                "label_fr": "Ruisseau Lick"
            },
            {
                "value": 10516,
                "label": "Licking",
                "label_ar": "لعق",
                "label_fr": "Lécher"
            },
            {
                "value": 10517,
                "label": "Lidgerwood",
                "label_ar": "ليدجيروود",
                "label_fr": "Lidgerwood"
            },
            {
                "value": 10518,
                "label": "Liebenthal",
                "label_ar": "ليبنتال",
                "label_fr": "Liebenthal"
            },
            {
                "value": 10519,
                "label": "Lignum",
                "label_ar": "Lignum",
                "label_fr": "Lignum"
            },
            {
                "value": 10520,
                "label": "Ligonier",
                "label_ar": "ليجونير",
                "label_fr": "Ligonier"
            },
            {
                "value": 10521,
                "label": "Lihue",
                "label_ar": "ليهو",
                "label_fr": "Lihue"
            },
            {
                "value": 10522,
                "label": "Likely",
                "label_ar": "المحتمل أن",
                "label_fr": "Probable"
            },
            {
                "value": 10523,
                "label": "Lilburn",
                "label_ar": "ليلبورن",
                "label_fr": "Lilburn"
            },
            {
                "value": 10524,
                "label": "Lilesville",
                "label_ar": "ليلسفيل",
                "label_fr": "Lilesville"
            },
            {
                "value": 10525,
                "label": "Lillian",
                "label_ar": "ليليان",
                "label_fr": "Lillian"
            },
            {
                "value": 10526,
                "label": "Lillington",
                "label_ar": "ليلينجتون",
                "label_fr": "Lillington"
            },
            {
                "value": 10527,
                "label": "Lilliwaup",
                "label_ar": "Lilliwaup",
                "label_fr": "Lilliwaup"
            },
            {
                "value": 10528,
                "label": "Lilly",
                "label_ar": "ليلي",
                "label_fr": "Lilly"
            },
            {
                "value": 10529,
                "label": "Lily Dale",
                "label_ar": "ليلي ديل",
                "label_fr": "Lily Dale"
            },
            {
                "value": 10530,
                "label": "Lima",
                "label_ar": "ليما",
                "label_fr": "Lima"
            },
            {
                "value": 10531,
                "label": "Lime Ridge",
                "label_ar": "لايم ريدج",
                "label_fr": "Lime Ridge"
            },
            {
                "value": 10532,
                "label": "Lime Springs",
                "label_ar": "لايم سبرينغز",
                "label_fr": "Lime Springs"
            },
            {
                "value": 10533,
                "label": "Limerick",
                "label_ar": "ليمريك",
                "label_fr": "Limerick"
            },
            {
                "value": 10534,
                "label": "Limestone",
                "label_ar": "حجر الكلس",
                "label_fr": "Calcaire"
            },
            {
                "value": 10535,
                "label": "Limington",
                "label_ar": "ليمنجتون",
                "label_fr": "Limington"
            },
            {
                "value": 10536,
                "label": "Limon",
                "label_ar": "ليمون",
                "label_fr": "Limon"
            },
            {
                "value": 10537,
                "label": "Lincoln",
                "label_ar": "لينكولن",
                "label_fr": "Lincoln"
            },
            {
                "value": 10538,
                "label": "Lincoln City",
                "label_ar": "لينكولن سيتي",
                "label_fr": "Lincoln City"
            },
            {
                "value": 10539,
                "label": "Lincoln Park",
                "label_ar": "لينكولن بارك",
                "label_fr": "Lincoln Park"
            },
            {
                "value": 10540,
                "label": "Lincolndale",
                "label_ar": "لينكولندال",
                "label_fr": "Lincolndale"
            },
            {
                "value": 10541,
                "label": "Lincolnshire",
                "label_ar": "لينكولنشاير",
                "label_fr": "Lincolnshire"
            },
            {
                "value": 10542,
                "label": "Lincolnton",
                "label_ar": "لينكولنتون",
                "label_fr": "Lincolnton"
            },
            {
                "value": 10543,
                "label": "Lincolnville",
                "label_ar": "لينكولنفيل",
                "label_fr": "Lincolnville"
            },
            {
                "value": 10544,
                "label": "Lincolnville Center",
                "label_ar": "مركز لينكولنفيل",
                "label_fr": "Centre de Lincolnville"
            },
            {
                "value": 10545,
                "label": "Lincolnwood",
                "label_ar": "لينكولنوود",
                "label_fr": "Lincolnwood"
            },
            {
                "value": 10546,
                "label": "Lincroft",
                "label_ar": "لينكروفت",
                "label_fr": "Lincroft"
            },
            {
                "value": 10547,
                "label": "Lindale",
                "label_ar": "لينديل",
                "label_fr": "Lindale"
            },
            {
                "value": 10548,
                "label": "Linden",
                "label_ar": "الزيزفون",
                "label_fr": "Tilleul"
            },
            {
                "value": 10549,
                "label": "Lindenhurst",
                "label_ar": "ليندنهورست",
                "label_fr": "Lindenhurst"
            },
            {
                "value": 10550,
                "label": "Lindley",
                "label_ar": "ليندلي",
                "label_fr": "Lindley"
            },
            {
                "value": 10551,
                "label": "Lindon",
                "label_ar": "ليندون",
                "label_fr": "Lindon"
            },
            {
                "value": 10552,
                "label": "Lindsay",
                "label_ar": "ليندسي",
                "label_fr": "Lindsay"
            },
            {
                "value": 10553,
                "label": "Lindsborg",
                "label_ar": "ليندسبورج",
                "label_fr": "Lindsborg"
            },
            {
                "value": 10554,
                "label": "Lindside",
                "label_ar": "ليندسايد",
                "label_fr": "Lindside"
            },
            {
                "value": 10555,
                "label": "Lindstrom",
                "label_ar": "ليندستروم",
                "label_fr": "Lindstrom"
            },
            {
                "value": 10556,
                "label": "Linesville",
                "label_ar": "لينسفيل",
                "label_fr": "Linesville"
            },
            {
                "value": 10557,
                "label": "Lineville",
                "label_ar": "لينفيل",
                "label_fr": "Lineville"
            },
            {
                "value": 10558,
                "label": "Linkwood",
                "label_ar": "لينكوود",
                "label_fr": "Linkwood"
            },
            {
                "value": 10559,
                "label": "Linn",
                "label_ar": "لين",
                "label_fr": "Linn"
            },
            {
                "value": 10560,
                "label": "Linn Creek",
                "label_ar": "لين كريك",
                "label_fr": "Ruisseau Linn"
            },
            {
                "value": 10561,
                "label": "Linneus",
                "label_ar": "لينوس",
                "label_fr": "Linneus"
            },
            {
                "value": 10562,
                "label": "Linthicum Heights",
                "label_ar": "مرتفعات لينثيكوم",
                "label_fr": "Hauteurs de Linthicum"
            },
            {
                "value": 10563,
                "label": "Linton",
                "label_ar": "لينتون",
                "label_fr": "Linton"
            },
            {
                "value": 10564,
                "label": "Linville",
                "label_ar": "لينفيل",
                "label_fr": "Linville"
            },
            {
                "value": 10565,
                "label": "Linwood",
                "label_ar": "لينوود",
                "label_fr": "Linwood"
            },
            {
                "value": 10566,
                "label": "Lipan",
                "label_ar": "ليبان",
                "label_fr": "Lipan"
            },
            {
                "value": 10567,
                "label": "Lisbon",
                "label_ar": "لشبونة",
                "label_fr": "Lisbonne"
            },
            {
                "value": 10568,
                "label": "Lisbon Falls",
                "label_ar": "شلالات لشبونة",
                "label_fr": "Chutes de Lisbonne"
            },
            {
                "value": 10569,
                "label": "Liscomb",
                "label_ar": "ليسكومب",
                "label_fr": "Liscomb"
            },
            {
                "value": 10570,
                "label": "Lisle",
                "label_ar": "ليسل",
                "label_fr": "Lisle"
            },
            {
                "value": 10571,
                "label": "Lismore",
                "label_ar": "ليزمور",
                "label_fr": "Lismore"
            },
            {
                "value": 10572,
                "label": "Listie",
                "label_ar": "ليستي",
                "label_fr": "Listie"
            },
            {
                "value": 10573,
                "label": "Litchfield",
                "label_ar": "ليتشفيلد",
                "label_fr": "Litchfield"
            },
            {
                "value": 10574,
                "label": "Litchfield Park",
                "label_ar": "ليتشفيلد بارك",
                "label_fr": "Parc Litchfield"
            },
            {
                "value": 10575,
                "label": "Litchfield Plains",
                "label_ar": "ليتشفيلد بلينز",
                "label_fr": "Plaines de Litchfield"
            },
            {
                "value": 10576,
                "label": "Lithia",
                "label_ar": "ليثيا",
                "label_fr": "Lithia"
            },
            {
                "value": 10577,
                "label": "Lithia Springs",
                "label_ar": "ليثيا سبرينجز",
                "label_fr": "Lithia Springs"
            },
            {
                "value": 10578,
                "label": "Lithonia",
                "label_ar": "ليثونيا",
                "label_fr": "Lithonia"
            },
            {
                "value": 10579,
                "label": "Lithopolis",
                "label_ar": "ليثوبوليس",
                "label_fr": "Lithopolis"
            },
            {
                "value": 10580,
                "label": "Lititz",
                "label_ar": "ليتيتز",
                "label_fr": "Lititz"
            },
            {
                "value": 10581,
                "label": "Little America",
                "label_ar": "أمريكا الصغيرة",
                "label_fr": "Petite Amérique"
            },
            {
                "value": 10582,
                "label": "Little Chute",
                "label_ar": "شلال صغير",
                "label_fr": "Petite Chute"
            },
            {
                "value": 10583,
                "label": "Little Compton",
                "label_ar": "ليتل كومبتون",
                "label_fr": "Petit Compton"
            },
            {
                "value": 10584,
                "label": "Little Elm",
                "label_ar": "ليتل علم",
                "label_fr": "Petit orme"
            },
            {
                "value": 10585,
                "label": "Little Falls",
                "label_ar": "ليتل فولز",
                "label_fr": "Little Falls"
            },
            {
                "value": 10586,
                "label": "Little Ferry",
                "label_ar": "ليتل فيري",
                "label_fr": "Petit ferry"
            },
            {
                "value": 10587,
                "label": "Little Hocking",
                "label_ar": "هوكينغ صغير",
                "label_fr": "Petit Hocking"
            },
            {
                "value": 10588,
                "label": "Little Meadows",
                "label_ar": "ليتل ميدوز",
                "label_fr": "Little Meadows"
            },
            {
                "value": 10589,
                "label": "Little Mountain",
                "label_ar": "ليتل ماونتن",
                "label_fr": "Petite Montagne"
            },
            {
                "value": 10590,
                "label": "Little Neck",
                "label_ar": "العنق الصغير",
                "label_fr": "Petit cou"
            },
            {
                "value": 10591,
                "label": "Little River",
                "label_ar": "النهر الصغير",
                "label_fr": "Petite rivière"
            },
            {
                "value": 10592,
                "label": "Little River-Academy",
                "label_ar": "ليتل ريفر أكاديمي",
                "label_fr": "Little River-Académie"
            },
            {
                "value": 10593,
                "label": "Little Rock",
                "label_ar": "صخرة صغيرة",
                "label_fr": "Petite pierre"
            },
            {
                "value": 10594,
                "label": "Little Rock Air Force Base",
                "label_ar": "قاعدة ليتل روك الجوية",
                "label_fr": "Base aérienne de Little Rock"
            },
            {
                "value": 10595,
                "label": "Little Silver",
                "label_ar": "الفضة الصغيرة",
                "label_fr": "Petit argent"
            },
            {
                "value": 10596,
                "label": "Little Suamico",
                "label_ar": "ليتل Suamico",
                "label_fr": "Petit Suamico"
            },
            {
                "value": 10597,
                "label": "Little Valley",
                "label_ar": "ليتل فالي",
                "label_fr": "Petite vallée"
            },
            {
                "value": 10598,
                "label": "Littlefield",
                "label_ar": "ليتلفيلد",
                "label_fr": "Littlefield"
            },
            {
                "value": 10599,
                "label": "Littlefork",
                "label_ar": "ليتل فورك",
                "label_fr": "Littlefork"
            },
            {
                "value": 10600,
                "label": "Littlerock",
                "label_ar": "صخرة صغيرة",
                "label_fr": "Petite pierre"
            },
            {
                "value": 10601,
                "label": "Littlestown",
                "label_ar": "ليتلستاون",
                "label_fr": "Littlestown"
            },
            {
                "value": 10602,
                "label": "Littleton",
                "label_ar": "ليتلتون",
                "label_fr": "Littleton"
            },
            {
                "value": 10603,
                "label": "Live Oak",
                "label_ar": "البلوط الحية",
                "label_fr": "Chêne vivant"
            },
            {
                "value": 10604,
                "label": "Livermore",
                "label_ar": "ليفرمور",
                "label_fr": "Livermore"
            },
            {
                "value": 10605,
                "label": "Livermore Falls",
                "label_ar": "شلالات ليفرمور",
                "label_fr": "Chutes de Livermore"
            },
            {
                "value": 10606,
                "label": "Liverpool",
                "label_ar": "ليفربول",
                "label_fr": "Liverpool"
            },
            {
                "value": 10607,
                "label": "Livingston",
                "label_ar": "ليفينغستون",
                "label_fr": "Livingston"
            },
            {
                "value": 10608,
                "label": "Livingston Manor",
                "label_ar": "ليفينجستون مانور",
                "label_fr": "Manoir Livingston"
            },
            {
                "value": 10609,
                "label": "Livonia",
                "label_ar": "ليفونيا",
                "label_fr": "Livonie"
            },
            {
                "value": 10610,
                "label": "Lizella",
                "label_ar": "ليزيلا",
                "label_fr": "Lizella"
            },
            {
                "value": 10611,
                "label": "Lizton",
                "label_ar": "ليزتون",
                "label_fr": "Lizton"
            },
            {
                "value": 10612,
                "label": "Llano",
                "label_ar": "لانو",
                "label_fr": "Llano"
            },
            {
                "value": 10613,
                "label": "Loa",
                "label_ar": "لوا",
                "label_fr": "Loa"
            },
            {
                "value": 10614,
                "label": "Loachapoka",
                "label_ar": "Loachapoka",
                "label_fr": "Loachapoka"
            },
            {
                "value": 10615,
                "label": "Loami",
                "label_ar": "Loami",
                "label_fr": "Loami"
            },
            {
                "value": 10616,
                "label": "Lobelville",
                "label_ar": "لوبلفيل",
                "label_fr": "Lobelville"
            },
            {
                "value": 10617,
                "label": "Local",
                "label_ar": "محلي",
                "label_fr": "Local"
            },
            {
                "value": 10618,
                "label": "Loch Sheldrake",
                "label_ar": "بحيرة لوخ شيلدريك",
                "label_fr": "Loch Sheldrake"
            },
            {
                "value": 10619,
                "label": "Lock Haven",
                "label_ar": "لوك هافن",
                "label_fr": "Lock Haven"
            },
            {
                "value": 10620,
                "label": "Lockbourne",
                "label_ar": "لوكبورن",
                "label_fr": "Lockbourne"
            },
            {
                "value": 10621,
                "label": "Locke",
                "label_ar": "لوك",
                "label_fr": "Locke"
            },
            {
                "value": 10622,
                "label": "Lockeford",
                "label_ar": "لوكفورد",
                "label_fr": "Lockeford"
            },
            {
                "value": 10623,
                "label": "Lockesburg",
                "label_ar": "لوكسبورغ",
                "label_fr": "Lockesburg"
            },
            {
                "value": 10624,
                "label": "Lockhart",
                "label_ar": "لوكهارت",
                "label_fr": "Lockhart"
            },
            {
                "value": 10625,
                "label": "Lockney",
                "label_ar": "لوكني",
                "label_fr": "Lockney"
            },
            {
                "value": 10626,
                "label": "Lockport",
                "label_ar": "لوكبورت",
                "label_fr": "Lockport"
            },
            {
                "value": 10627,
                "label": "Lockwood",
                "label_ar": "لوكوود",
                "label_fr": "Lockwood"
            },
            {
                "value": 10628,
                "label": "Loco",
                "label_ar": "لوكو",
                "label_fr": "Locomotive"
            },
            {
                "value": 10629,
                "label": "Locust",
                "label_ar": "الجراد",
                "label_fr": "Criquet"
            },
            {
                "value": 10630,
                "label": "Locust Fork",
                "label_ar": "شوكة الجراد",
                "label_fr": "Fourche de sauterelle"
            },
            {
                "value": 10631,
                "label": "Locust Gap",
                "label_ar": "فجوة الجراد",
                "label_fr": "Écart acridien"
            },
            {
                "value": 10632,
                "label": "Locust Grove",
                "label_ar": "الجراد جروف",
                "label_fr": "Locust Grove"
            },
            {
                "value": 10633,
                "label": "Locust Valley",
                "label_ar": "وادي الجراد",
                "label_fr": "Vallée acridienne"
            },
            {
                "value": 10634,
                "label": "Loda",
                "label_ar": "لودا",
                "label_fr": "Loda"
            },
            {
                "value": 10635,
                "label": "Lodge",
                "label_ar": "النزل",
                "label_fr": "Loge"
            },
            {
                "value": 10636,
                "label": "Lodge Grass",
                "label_ar": "لودج جراس",
                "label_fr": "Lodge Grass"
            },
            {
                "value": 10637,
                "label": "Lodi",
                "label_ar": "لودي",
                "label_fr": "Lodi"
            },
            {
                "value": 10638,
                "label": "Log Lane Village",
                "label_ar": "قرية لوج لين",
                "label_fr": "Village de Log Lane"
            },
            {
                "value": 10639,
                "label": "Logan",
                "label_ar": "لوجان",
                "label_fr": "Logan"
            },
            {
                "value": 10640,
                "label": "Logandale",
                "label_ar": "لوغانديل",
                "label_fr": "Logandale"
            },
            {
                "value": 10641,
                "label": "Logansport",
                "label_ar": "لوجان سبورت",
                "label_fr": "Logansport"
            },
            {
                "value": 10642,
                "label": "Loganton",
                "label_ar": "لوجانتون",
                "label_fr": "Connexion"
            },
            {
                "value": 10643,
                "label": "Loganville",
                "label_ar": "لوجانفيل",
                "label_fr": "Loganville"
            },
            {
                "value": 10644,
                "label": "Lohman",
                "label_ar": "لوهمان",
                "label_fr": "Lohman"
            },
            {
                "value": 10645,
                "label": "Lohn",
                "label_ar": "لون",
                "label_fr": "Lohn"
            },
            {
                "value": 10646,
                "label": "Lolita",
                "label_ar": "لوليتا",
                "label_fr": "Lolita"
            },
            {
                "value": 10647,
                "label": "Lolo",
                "label_ar": "لولو",
                "label_fr": "Lolo"
            },
            {
                "value": 10648,
                "label": "Loma",
                "label_ar": "لوما",
                "label_fr": "Loma"
            },
            {
                "value": 10649,
                "label": "Loma Linda",
                "label_ar": "لوما ليندا",
                "label_fr": "Loma Linda"
            },
            {
                "value": 10650,
                "label": "Lomax",
                "label_ar": "لوماكس",
                "label_fr": "Lomax"
            },
            {
                "value": 10651,
                "label": "Lombard",
                "label_ar": "لومبارد",
                "label_fr": "Lombard"
            },
            {
                "value": 10652,
                "label": "Lomira",
                "label_ar": "لوميرا",
                "label_fr": "Lomira"
            },
            {
                "value": 10653,
                "label": "Lomita",
                "label_ar": "لوميتا",
                "label_fr": "Lomita"
            },
            {
                "value": 10654,
                "label": "Lompoc",
                "label_ar": "لومبوك",
                "label_fr": "Lompoc"
            },
            {
                "value": 10655,
                "label": "Lonaconing",
                "label_ar": "لوناكونينغ",
                "label_fr": "Lonaconing"
            },
            {
                "value": 10656,
                "label": "London",
                "label_ar": "لندن",
                "label_fr": "Londres"
            },
            {
                "value": 10657,
                "label": "London Mills",
                "label_ar": "لندن ميلز",
                "label_fr": "London Mills"
            },
            {
                "value": 10658,
                "label": "Londonderry",
                "label_ar": "لندنديري",
                "label_fr": "Londonderry"
            },
            {
                "value": 10659,
                "label": "Lone Grove",
                "label_ar": "لون جروف",
                "label_fr": "Lone Grove"
            },
            {
                "value": 10660,
                "label": "Lone Jack",
                "label_ar": "لون جاك",
                "label_fr": "Jack solitaire"
            },
            {
                "value": 10661,
                "label": "Lone Oak",
                "label_ar": "لون أوك",
                "label_fr": "Chêne solitaire"
            },
            {
                "value": 10662,
                "label": "Lone Pine",
                "label_ar": "الصنوبر الوحيد",
                "label_fr": "Pin solitaire"
            },
            {
                "value": 10663,
                "label": "Lone Rock",
                "label_ar": "لون روك",
                "label_fr": "Rocher solitaire"
            },
            {
                "value": 10664,
                "label": "Lone Tree",
                "label_ar": "لون تري",
                "label_fr": "Arbre solitaire"
            },
            {
                "value": 10665,
                "label": "Lone Wolf",
                "label_ar": "وحيد وولف",
                "label_fr": "Loup solitaire"
            },
            {
                "value": 10666,
                "label": "Lonedell",
                "label_ar": "لونديل",
                "label_fr": "Lonedell"
            },
            {
                "value": 10667,
                "label": "Long Beach",
                "label_ar": "شاطئ طويل",
                "label_fr": "Long Beach"
            },
            {
                "value": 10668,
                "label": "Long Branch",
                "label_ar": "فرع طويل",
                "label_fr": "Longue branche"
            },
            {
                "value": 10669,
                "label": "Long Grove",
                "label_ar": "لونج جروف",
                "label_fr": "Long Grove"
            },
            {
                "value": 10670,
                "label": "Long Island",
                "label_ar": "جزيرة طويلة",
                "label_fr": "Long Island"
            },
            {
                "value": 10671,
                "label": "Long Island City",
                "label_ar": "لونغ آيلاند سيتي",
                "label_fr": "Long Island City"
            },
            {
                "value": 10672,
                "label": "Long Lake",
                "label_ar": "لونج ليك",
                "label_fr": "Lac Long"
            },
            {
                "value": 10673,
                "label": "Long Lane",
                "label_ar": "لونج لين",
                "label_fr": "Long Lane"
            },
            {
                "value": 10674,
                "label": "Long Pine",
                "label_ar": "طويل باين",
                "label_fr": "Pin long"
            },
            {
                "value": 10675,
                "label": "Long Pond",
                "label_ar": "البركة الطويلة",
                "label_fr": "Long Pond"
            },
            {
                "value": 10676,
                "label": "Long Prairie",
                "label_ar": "مرج طويل",
                "label_fr": "Long Prairie"
            },
            {
                "value": 10677,
                "label": "Long Valley",
                "label_ar": "لونج فالي",
                "label_fr": "Long Valley"
            },
            {
                "value": 10678,
                "label": "Longboat Key",
                "label_ar": "مفتاح Longboat",
                "label_fr": "Clé de chaloupe"
            },
            {
                "value": 10679,
                "label": "Longbranch",
                "label_ar": "فرع طويل",
                "label_fr": "Longbranch"
            },
            {
                "value": 10680,
                "label": "Longdale",
                "label_ar": "لونجديل",
                "label_fr": "Longdale"
            },
            {
                "value": 10681,
                "label": "Longmeadow",
                "label_ar": "مرج طويل",
                "label_fr": "Longmeadow"
            },
            {
                "value": 10682,
                "label": "Longmont",
                "label_ar": "لونجمونت",
                "label_fr": "Longmont"
            },
            {
                "value": 10683,
                "label": "Longport",
                "label_ar": "لونجبورت",
                "label_fr": "Longport"
            },
            {
                "value": 10684,
                "label": "Longs",
                "label_ar": "لونغز",
                "label_fr": "Longs"
            },
            {
                "value": 10685,
                "label": "Longton",
                "label_ar": "لونجتون",
                "label_fr": "Longton"
            },
            {
                "value": 10686,
                "label": "Longview",
                "label_ar": "لونجفيو",
                "label_fr": "Vision à long terme"
            },
            {
                "value": 10687,
                "label": "Longville",
                "label_ar": "لونجفيل",
                "label_fr": "Longville"
            },
            {
                "value": 10688,
                "label": "Longwood",
                "label_ar": "لونغوود",
                "label_fr": "Longwood"
            },
            {
                "value": 10689,
                "label": "Lonoke",
                "label_ar": "لونوكي",
                "label_fr": "Lonoke"
            },
            {
                "value": 10690,
                "label": "Lonsdale",
                "label_ar": "لونسديل",
                "label_fr": "Lonsdale"
            },
            {
                "value": 10691,
                "label": "Loogootee",
                "label_ar": "Loogootee",
                "label_fr": "Loogootee"
            },
            {
                "value": 10692,
                "label": "Lookout",
                "label_ar": "انتبه احذر خذ بالك",
                "label_fr": "Attention"
            },
            {
                "value": 10693,
                "label": "Lookout Mountain",
                "label_ar": "جبل لوك",
                "label_fr": "Lookout Montagne"
            },
            {
                "value": 10694,
                "label": "Loomis",
                "label_ar": "لوميس",
                "label_fr": "Loomis"
            },
            {
                "value": 10695,
                "label": "Loon Lake",
                "label_ar": "بحيرة لون",
                "label_fr": "Lac Loon"
            },
            {
                "value": 10696,
                "label": "Loop",
                "label_ar": "عقدة",
                "label_fr": "Boucle"
            },
            {
                "value": 10697,
                "label": "Loose Creek",
                "label_ar": "فضفاض كريك",
                "label_fr": "Loose Creek"
            },
            {
                "value": 10698,
                "label": "Lopez",
                "label_ar": "لوبيز",
                "label_fr": "Lopez"
            },
            {
                "value": 10699,
                "label": "Lorain",
                "label_ar": "لورين",
                "label_fr": "Lorain"
            },
            {
                "value": 10700,
                "label": "Loraine",
                "label_ar": "لورين",
                "label_fr": "Loraine"
            },
            {
                "value": 10701,
                "label": "Loranger",
                "label_ar": "لورانجر",
                "label_fr": "Loranger"
            },
            {
                "value": 10702,
                "label": "Lordsburg",
                "label_ar": "لوردسبورج",
                "label_fr": "Lordsburg"
            },
            {
                "value": 10703,
                "label": "Lore City",
                "label_ar": "مدينة لور",
                "label_fr": "Ville du savoir"
            },
            {
                "value": 10704,
                "label": "Lorena",
                "label_ar": "لورينا",
                "label_fr": "Lorena"
            },
            {
                "value": 10705,
                "label": "Lorenzo",
                "label_ar": "لورينزو",
                "label_fr": "Lorenzo"
            },
            {
                "value": 10706,
                "label": "Loretto",
                "label_ar": "لوريتو",
                "label_fr": "Loretto"
            },
            {
                "value": 10707,
                "label": "Lorimor",
                "label_ar": "لوريمور",
                "label_fr": "Lorimor"
            },
            {
                "value": 10708,
                "label": "Loris",
                "label_ar": "لوريس",
                "label_fr": "Loris"
            },
            {
                "value": 10709,
                "label": "Lorman",
                "label_ar": "لورمان",
                "label_fr": "Lorman"
            },
            {
                "value": 10710,
                "label": "Lorraine",
                "label_ar": "لورين",
                "label_fr": "Lorraine"
            },
            {
                "value": 10711,
                "label": "Lorton",
                "label_ar": "لورتون",
                "label_fr": "Lorton"
            },
            {
                "value": 10712,
                "label": "Los Alamitos",
                "label_ar": "لوس الاميتوس",
                "label_fr": "Los Alamitos"
            },
            {
                "value": 10713,
                "label": "Los Alamos",
                "label_ar": "لوس الاموس",
                "label_fr": "Los Alamos"
            },
            {
                "value": 10714,
                "label": "Los Altos",
                "label_ar": "لوس ألتوس",
                "label_fr": "Los Altos"
            },
            {
                "value": 10715,
                "label": "Los Altos Hills",
                "label_ar": "تلال لوس ألتوس",
                "label_fr": "Los Altos Hills"
            },
            {
                "value": 10716,
                "label": "Los Angeles",
                "label_ar": "لوس أنجلوس",
                "label_fr": "Los Angeles"
            },
            {
                "value": 10717,
                "label": "Los Banos",
                "label_ar": "لوس بانوس",
                "label_fr": "Los Banos"
            },
            {
                "value": 10718,
                "label": "Los Fresnos",
                "label_ar": "لوس فريسنوس",
                "label_fr": "Los Fresnos"
            },
            {
                "value": 10719,
                "label": "Los Gatos",
                "label_ar": "لوس جاتوس",
                "label_fr": "Los Gatos"
            },
            {
                "value": 10720,
                "label": "Los Lunas",
                "label_ar": "لوس لوناس",
                "label_fr": "Los Lunas"
            },
            {
                "value": 10721,
                "label": "Los Molinos",
                "label_ar": "لوس مولينوس",
                "label_fr": "Los Molinos"
            },
            {
                "value": 10722,
                "label": "Los Olivos",
                "label_ar": "لوس أوليفوس",
                "label_fr": "Los Olivos"
            },
            {
                "value": 10723,
                "label": "Los Osos",
                "label_ar": "لوس اوسوس",
                "label_fr": "Los Osos"
            },
            {
                "value": 10724,
                "label": "Losantville",
                "label_ar": "لوسانتفيل",
                "label_fr": "Losantville"
            },
            {
                "value": 10725,
                "label": "Lost City",
                "label_ar": "مدينة ضائعة",
                "label_fr": "Cité perdue"
            },
            {
                "value": 10726,
                "label": "Lost Creek",
                "label_ar": "فقدت الخور",
                "label_fr": "Ruisseau perdu"
            },
            {
                "value": 10727,
                "label": "Lost Nation",
                "label_ar": "الأمة المفقودة",
                "label_fr": "Nation perdue"
            },
            {
                "value": 10728,
                "label": "Lost Springs",
                "label_ar": "الينابيع المفقودة",
                "label_fr": "Ressorts perdus"
            },
            {
                "value": 10729,
                "label": "Lostant",
                "label_ar": "فقدت",
                "label_fr": "Perdu"
            },
            {
                "value": 10730,
                "label": "Lostine",
                "label_ar": "لوستين",
                "label_fr": "Lostine"
            },
            {
                "value": 10731,
                "label": "Lothian",
                "label_ar": "لوثيان",
                "label_fr": "Lothian"
            },
            {
                "value": 10732,
                "label": "Lott",
                "label_ar": "لوت",
                "label_fr": "Lott"
            },
            {
                "value": 10733,
                "label": "Lottie",
                "label_ar": "لوتي",
                "label_fr": "Lottie"
            },
            {
                "value": 10734,
                "label": "Lottsburg",
                "label_ar": "لوتسبيرغ",
                "label_fr": "Lottsburg"
            },
            {
                "value": 10735,
                "label": "Lotus",
                "label_ar": "لوتس",
                "label_fr": "Lotus"
            },
            {
                "value": 10736,
                "label": "Loudon",
                "label_ar": "لودون",
                "label_fr": "Loudon"
            },
            {
                "value": 10737,
                "label": "Loudonville",
                "label_ar": "لودونفيل",
                "label_fr": "Loudonville"
            },
            {
                "value": 10738,
                "label": "Louin",
                "label_ar": "لوين",
                "label_fr": "Louin"
            },
            {
                "value": 10739,
                "label": "Louisa",
                "label_ar": "لويزا",
                "label_fr": "Louisa"
            },
            {
                "value": 10740,
                "label": "Louisburg",
                "label_ar": "لويسبورغ",
                "label_fr": "Louisbourg"
            },
            {
                "value": 10741,
                "label": "Louise",
                "label_ar": "لويز",
                "label_fr": "Louise"
            },
            {
                "value": 10742,
                "label": "Louisiana",
                "label_ar": "لويزيانا",
                "label_fr": "Louisiane"
            },
            {
                "value": 10743,
                "label": "Louisville",
                "label_ar": "لويزفيل",
                "label_fr": "Louisville"
            },
            {
                "value": 10744,
                "label": "Loup City",
                "label_ar": "مدينة لوب",
                "label_fr": "Ville de Loup"
            },
            {
                "value": 10745,
                "label": "Lovejoy",
                "label_ar": "أحب الفرح",
                "label_fr": "Lovejoy"
            },
            {
                "value": 10746,
                "label": "Lovelady",
                "label_ar": "لوفلادي",
                "label_fr": "Lovelady"
            },
            {
                "value": 10747,
                "label": "Loveland",
                "label_ar": "أرض الحب",
                "label_fr": "Terre d'amour"
            },
            {
                "value": 10748,
                "label": "Lovell",
                "label_ar": "لوفيل",
                "label_fr": "Lovell"
            },
            {
                "value": 10749,
                "label": "Lovelock",
                "label_ar": "لوفلوك",
                "label_fr": "Lovelock"
            },
            {
                "value": 10750,
                "label": "Lovely",
                "label_ar": "محبوب",
                "label_fr": "Charmant"
            },
            {
                "value": 10751,
                "label": "Loves Park",
                "label_ar": "لوفز بارك",
                "label_fr": "Aime le parc"
            },
            {
                "value": 10752,
                "label": "Lovettsville",
                "label_ar": "لوفيتسفيل",
                "label_fr": "Lovettsville"
            },
            {
                "value": 10753,
                "label": "Loveville",
                "label_ar": "لوففيل",
                "label_fr": "Loveville"
            },
            {
                "value": 10754,
                "label": "Lovilia",
                "label_ar": "لوفيليا",
                "label_fr": "Lovilia"
            },
            {
                "value": 10755,
                "label": "Loving",
                "label_ar": "محب",
                "label_fr": "Aimant"
            },
            {
                "value": 10756,
                "label": "Lovingston",
                "label_ar": "لوفينغستون",
                "label_fr": "Lovingston"
            },
            {
                "value": 10757,
                "label": "Lovington",
                "label_ar": "لوفينجتون",
                "label_fr": "Lovington"
            },
            {
                "value": 10758,
                "label": "Low Moor",
                "label_ar": "منخفض مور",
                "label_fr": "Low Moor"
            },
            {
                "value": 10759,
                "label": "Low and Burbanks Grant",
                "label_ar": "منحة Low and Burbanks",
                "label_fr": "Subvention Low et Burbanks"
            },
            {
                "value": 10760,
                "label": "Lowber",
                "label_ar": "لوبر",
                "label_fr": "Lowber"
            },
            {
                "value": 10761,
                "label": "Lowden",
                "label_ar": "لودين",
                "label_fr": "Lowden"
            },
            {
                "value": 10762,
                "label": "Lowell",
                "label_ar": "لويل",
                "label_fr": "Lowell"
            },
            {
                "value": 10763,
                "label": "Lowellville",
                "label_ar": "لويلفيل",
                "label_fr": "Lowellville"
            },
            {
                "value": 10764,
                "label": "Lower Brule",
                "label_ar": "برويل السفلى",
                "label_fr": "Brule inférieure"
            },
            {
                "value": 10765,
                "label": "Lower Lake",
                "label_ar": "البحيرة السفلى",
                "label_fr": "Lac inférieur"
            },
            {
                "value": 10766,
                "label": "Lower Salem",
                "label_ar": "لور سالم",
                "label_fr": "Bas Salem"
            },
            {
                "value": 10767,
                "label": "Lowgap",
                "label_ar": "Lowgap",
                "label_fr": "Lowgap"
            },
            {
                "value": 10768,
                "label": "Lowman",
                "label_ar": "لومان",
                "label_fr": "Homme faible"
            },
            {
                "value": 10769,
                "label": "Lowmansville",
                "label_ar": "لومانسفيل",
                "label_fr": "Lowmansville"
            },
            {
                "value": 10770,
                "label": "Lowndes",
                "label_ar": "لاوندز",
                "label_fr": "Lowndes"
            },
            {
                "value": 10771,
                "label": "Lowndesboro",
                "label_ar": "لونديسبورو",
                "label_fr": "Lowndesboro"
            },
            {
                "value": 10772,
                "label": "Lowry",
                "label_ar": "لوري",
                "label_fr": "Lowry"
            },
            {
                "value": 10773,
                "label": "Lowry City",
                "label_ar": "مدينة لوري",
                "label_fr": "Ville de Lowry"
            },
            {
                "value": 10774,
                "label": "Lowville",
                "label_ar": "لوفيل",
                "label_fr": "Lowville"
            },
            {
                "value": 10775,
                "label": "Loxahatchee Groves",
                "label_ar": "لوكساهاتشي غروفز",
                "label_fr": "Les bosquets de Loxahatchee"
            },
            {
                "value": 10776,
                "label": "Loxley",
                "label_ar": "لوكسلي",
                "label_fr": "Loxley"
            },
            {
                "value": 10777,
                "label": "Loyal",
                "label_ar": "مخلص",
                "label_fr": "Fidèle"
            },
            {
                "value": 10778,
                "label": "Loyalhanna",
                "label_ar": "Loyalhanna",
                "label_fr": "Loyalhanna"
            },
            {
                "value": 10779,
                "label": "Loyall",
                "label_ar": "لويال",
                "label_fr": "Loyall"
            },
            {
                "value": 10780,
                "label": "Loysville",
                "label_ar": "لويزفيل",
                "label_fr": "Loysville"
            },
            {
                "value": 10781,
                "label": "Lu Verne",
                "label_ar": "لو فيرن",
                "label_fr": "Lu Verne"
            },
            {
                "value": 10782,
                "label": "Lubbock",
                "label_ar": "لوبوك",
                "label_fr": "Lubbock"
            },
            {
                "value": 10783,
                "label": "Lubec",
                "label_ar": "لوبيك",
                "label_fr": "Lubec"
            },
            {
                "value": 10784,
                "label": "Lucama",
                "label_ar": "لوكاما",
                "label_fr": "Lucama"
            },
            {
                "value": 10785,
                "label": "Lucan",
                "label_ar": "لوكان",
                "label_fr": "Lucan"
            },
            {
                "value": 10786,
                "label": "Lucas",
                "label_ar": "لوكاس",
                "label_fr": "Lucas"
            },
            {
                "value": 10787,
                "label": "Lucasville",
                "label_ar": "لوكاسفيل",
                "label_fr": "Lucasville"
            },
            {
                "value": 10788,
                "label": "Lucedale",
                "label_ar": "لوسديل",
                "label_fr": "Lucedale"
            },
            {
                "value": 10789,
                "label": "Lucerne",
                "label_ar": "لوسيرن",
                "label_fr": "Luzerne"
            },
            {
                "value": 10790,
                "label": "Lucerne Valley",
                "label_ar": "وادي لوسيرن",
                "label_fr": "Vallée de Lucerne"
            },
            {
                "value": 10791,
                "label": "Lucinda",
                "label_ar": "لوسيندا",
                "label_fr": "Lucinda"
            },
            {
                "value": 10792,
                "label": "Luck",
                "label_ar": "حظ",
                "label_fr": "La chance"
            },
            {
                "value": 10793,
                "label": "Luckey",
                "label_ar": "لوكي",
                "label_fr": "Luckey"
            },
            {
                "value": 10794,
                "label": "Ludington",
                "label_ar": "لودينجتون",
                "label_fr": "Ludington"
            },
            {
                "value": 10795,
                "label": "Ludlow",
                "label_ar": "لودلو",
                "label_fr": "Ludlow"
            },
            {
                "value": 10796,
                "label": "Ludlow Falls",
                "label_ar": "شلالات لودلو",
                "label_fr": "Ludlow Falls"
            },
            {
                "value": 10797,
                "label": "Ludowici",
                "label_ar": "Ludowici",
                "label_fr": "Ludowici"
            },
            {
                "value": 10798,
                "label": "Lueders",
                "label_ar": "لوديرز",
                "label_fr": "Lueders"
            },
            {
                "value": 10799,
                "label": "Lufkin",
                "label_ar": "لوفكين",
                "label_fr": "Lufkin"
            },
            {
                "value": 10800,
                "label": "Lugoff",
                "label_ar": "لوغوف",
                "label_fr": "Lugoff"
            },
            {
                "value": 10801,
                "label": "Lula",
                "label_ar": "لولا",
                "label_fr": "Lula"
            },
            {
                "value": 10802,
                "label": "Luling",
                "label_ar": "لولينج",
                "label_fr": "Luling"
            },
            {
                "value": 10803,
                "label": "Lumberport",
                "label_ar": "لومربورت",
                "label_fr": "Lumberport"
            },
            {
                "value": 10804,
                "label": "Lumberton",
                "label_ar": "لومبيرتون",
                "label_fr": "Lumberton"
            },
            {
                "value": 10805,
                "label": "Lumberville",
                "label_ar": "لامبرفيل",
                "label_fr": "Lumberville"
            },
            {
                "value": 10806,
                "label": "Lummi Island",
                "label_ar": "جزيرة لومي",
                "label_fr": "Île Lummi"
            },
            {
                "value": 10807,
                "label": "Lumpkin",
                "label_ar": "لومبكين",
                "label_fr": "Lumpkin"
            },
            {
                "value": 10808,
                "label": "Luna Pier",
                "label_ar": "لونا بيير",
                "label_fr": "Jetée de Luna"
            },
            {
                "value": 10809,
                "label": "Lund",
                "label_ar": "لوند",
                "label_fr": "Lund"
            },
            {
                "value": 10810,
                "label": "Lunenburg",
                "label_ar": "لونينبورج",
                "label_fr": "Lunenburg"
            },
            {
                "value": 10811,
                "label": "Lupton City",
                "label_ar": "مدينة لوبتون",
                "label_fr": "Lupton City"
            },
            {
                "value": 10812,
                "label": "Luray",
                "label_ar": "لوراي",
                "label_fr": "Luray"
            },
            {
                "value": 10813,
                "label": "Lusby",
                "label_ar": "لوسبي",
                "label_fr": "Lusby"
            },
            {
                "value": 10814,
                "label": "Lusk",
                "label_ar": "لاسك",
                "label_fr": "Lusk"
            },
            {
                "value": 10815,
                "label": "Lutcher",
                "label_ar": "لوتشر",
                "label_fr": "Lutcher"
            },
            {
                "value": 10816,
                "label": "Luther",
                "label_ar": "لوثر",
                "label_fr": "Luther"
            },
            {
                "value": 10817,
                "label": "Luthersburg",
                "label_ar": "لوثرسبيرغ",
                "label_fr": "Luthersburg"
            },
            {
                "value": 10818,
                "label": "Luthersville",
                "label_ar": "لوثرسفيل",
                "label_fr": "Luthersville"
            },
            {
                "value": 10819,
                "label": "Lutherville-Timonium",
                "label_ar": "لوثرفيل تيمونيوم",
                "label_fr": "Lutherville-Timonium"
            },
            {
                "value": 10820,
                "label": "Lutsen",
                "label_ar": "لوتسن",
                "label_fr": "Lutsen"
            },
            {
                "value": 10821,
                "label": "Luttrell",
                "label_ar": "لوتريل",
                "label_fr": "Luttrell"
            },
            {
                "value": 10822,
                "label": "Lutz",
                "label_ar": "لوتز",
                "label_fr": "Lutz"
            },
            {
                "value": 10823,
                "label": "Luverne",
                "label_ar": "لوفيرن",
                "label_fr": "Luverne"
            },
            {
                "value": 10824,
                "label": "Luxemburg",
                "label_ar": "لوكسمبورغ",
                "label_fr": "Luxemburg"
            },
            {
                "value": 10825,
                "label": "Luxor",
                "label_ar": "الأقصر",
                "label_fr": "Louxor"
            },
            {
                "value": 10826,
                "label": "Luxora",
                "label_ar": "لوكسورا",
                "label_fr": "Luxora"
            },
            {
                "value": 10827,
                "label": "Luzerne",
                "label_ar": "لوزيرن",
                "label_fr": "Luzerne"
            },
            {
                "value": 10828,
                "label": "Lydia",
                "label_ar": "ليديا",
                "label_fr": "Lydia"
            },
            {
                "value": 10829,
                "label": "Lyerly",
                "label_ar": "قيثارة",
                "label_fr": "Lyerly"
            },
            {
                "value": 10830,
                "label": "Lykens",
                "label_ar": "ليكينز",
                "label_fr": "Lykens"
            },
            {
                "value": 10831,
                "label": "Lyle",
                "label_ar": "لايل",
                "label_fr": "Lyle"
            },
            {
                "value": 10832,
                "label": "Lyles",
                "label_ar": "لايلز",
                "label_fr": "Lyles"
            },
            {
                "value": 10833,
                "label": "Lyman",
                "label_ar": "ليمان",
                "label_fr": "Lyman"
            },
            {
                "value": 10834,
                "label": "Lyme",
                "label_ar": "لايم",
                "label_fr": "Lyme"
            },
            {
                "value": 10835,
                "label": "Lynbrook",
                "label_ar": "لينبروك",
                "label_fr": "Lynbrook"
            },
            {
                "value": 10836,
                "label": "Lynch",
                "label_ar": "لينش",
                "label_fr": "Lyncher"
            },
            {
                "value": 10837,
                "label": "Lynch Station",
                "label_ar": "محطة لينش",
                "label_fr": "Gare de Lynch"
            },
            {
                "value": 10838,
                "label": "Lynchburg",
                "label_ar": "لينشبورغ",
                "label_fr": "Lynchburg"
            },
            {
                "value": 10839,
                "label": "Lynco",
                "label_ar": "لينكو",
                "label_fr": "Lynco"
            },
            {
                "value": 10840,
                "label": "Lynd",
                "label_ar": "ليند",
                "label_fr": "Lynd"
            },
            {
                "value": 10841,
                "label": "Lyndeborough",
                "label_ar": "لينديبورو",
                "label_fr": "Lyndeborough"
            },
            {
                "value": 10842,
                "label": "Lynden",
                "label_ar": "ليندن",
                "label_fr": "Lynden"
            },
            {
                "value": 10843,
                "label": "Lyndhurst",
                "label_ar": "ليندهيرست",
                "label_fr": "Lyndhurst"
            },
            {
                "value": 10844,
                "label": "Lyndon",
                "label_ar": "ليندون",
                "label_fr": "Lyndon"
            },
            {
                "value": 10845,
                "label": "Lyndon Center",
                "label_ar": "مركز ليندون",
                "label_fr": "Centre Lyndon"
            },
            {
                "value": 10846,
                "label": "Lyndon Station",
                "label_ar": "محطة ليندون",
                "label_fr": "Gare de Lyndon"
            },
            {
                "value": 10847,
                "label": "Lyndonville",
                "label_ar": "ليندونفيل",
                "label_fr": "Lyndonville"
            },
            {
                "value": 10848,
                "label": "Lynn",
                "label_ar": "لين",
                "label_fr": "Lynn"
            },
            {
                "value": 10849,
                "label": "Lynn Center",
                "label_ar": "لين سنتر",
                "label_fr": "Centre Lynn"
            },
            {
                "value": 10850,
                "label": "Lynn Haven",
                "label_ar": "لين هافن",
                "label_fr": "Lynn Haven"
            },
            {
                "value": 10851,
                "label": "Lynnfield",
                "label_ar": "لينفيلد",
                "label_fr": "Lynnfield"
            },
            {
                "value": 10852,
                "label": "Lynnville",
                "label_ar": "لينفيل",
                "label_fr": "Lynnville"
            },
            {
                "value": 10853,
                "label": "Lynnwood",
                "label_ar": "لينوود",
                "label_fr": "Lynnwood"
            },
            {
                "value": 10854,
                "label": "Lynwood",
                "label_ar": "لينوود",
                "label_fr": "Lynwood"
            },
            {
                "value": 10855,
                "label": "Lyon",
                "label_ar": "ليون",
                "label_fr": "Lyon"
            },
            {
                "value": 10856,
                "label": "Lyons",
                "label_ar": "ليونز",
                "label_fr": "Lyon"
            },
            {
                "value": 10857,
                "label": "Lytle",
                "label_ar": "ليتل",
                "label_fr": "Lytle"
            },
            {
                "value": 10858,
                "label": "Lytle Creek",
                "label_ar": "ليتل كريك",
                "label_fr": "Lytle Creek"
            },
            {
                "value": 10859,
                "label": "Mabank",
                "label_ar": "مابانك",
                "label_fr": "Mabank"
            },
            {
                "value": 10860,
                "label": "Mabel",
                "label_ar": "مابل",
                "label_fr": "Mabel"
            },
            {
                "value": 10861,
                "label": "Mabelvale",
                "label_ar": "مابيلفيل",
                "label_fr": "Mabelvale"
            },
            {
                "value": 10862,
                "label": "Maben",
                "label_ar": "مابين",
                "label_fr": "Maben"
            },
            {
                "value": 10863,
                "label": "Mableton",
                "label_ar": "مابلتون",
                "label_fr": "Mableton"
            },
            {
                "value": 10864,
                "label": "Mabton",
                "label_ar": "مابتون",
                "label_fr": "Mabton"
            },
            {
                "value": 10865,
                "label": "Macclenny",
                "label_ar": "ماكليني",
                "label_fr": "Macclenny"
            },
            {
                "value": 10866,
                "label": "Macclesfield",
                "label_ar": "ماكليسفيلد",
                "label_fr": "Macclesfield"
            },
            {
                "value": 10867,
                "label": "Macdoel",
                "label_ar": "ماكدويل",
                "label_fr": "Macdoel"
            },
            {
                "value": 10868,
                "label": "Macedon",
                "label_ar": "ماسيدون",
                "label_fr": "Macédoine"
            },
            {
                "value": 10869,
                "label": "Macedonia",
                "label_ar": "مقدونيا",
                "label_fr": "Macédoine"
            },
            {
                "value": 10870,
                "label": "Macfarlan",
                "label_ar": "ماكفارلان",
                "label_fr": "Macfarlan"
            },
            {
                "value": 10871,
                "label": "Machesney Park",
                "label_ar": "ماتشيسني بارك",
                "label_fr": "Parc Machesney"
            },
            {
                "value": 10872,
                "label": "Machias",
                "label_ar": "ميكياس",
                "label_fr": "Machias"
            },
            {
                "value": 10873,
                "label": "Machiasport",
                "label_ar": "مكياسبورت",
                "label_fr": "Machiasport"
            },
            {
                "value": 10874,
                "label": "Mackay",
                "label_ar": "ماكاي",
                "label_fr": "Mackay"
            },
            {
                "value": 10875,
                "label": "Mackinac Island",
                "label_ar": "جزيرة ماكيناك",
                "label_fr": "Île Mackinac"
            },
            {
                "value": 10876,
                "label": "Mackinaw",
                "label_ar": "ماكيناو",
                "label_fr": "Mackinaw"
            },
            {
                "value": 10877,
                "label": "Mackinaw City",
                "label_ar": "مدينة ماكيناو",
                "label_fr": "Mackinaw City"
            },
            {
                "value": 10878,
                "label": "Macksburg",
                "label_ar": "ماكسبيرغ",
                "label_fr": "Macksburg"
            },
            {
                "value": 10879,
                "label": "Macomb",
                "label_ar": "ماكومب",
                "label_fr": "Macomb"
            },
            {
                "value": 10880,
                "label": "Macon",
                "label_ar": "ماكون",
                "label_fr": "Macon"
            },
            {
                "value": 10881,
                "label": "Macungie",
                "label_ar": "ماكونجي",
                "label_fr": "Macungie"
            },
            {
                "value": 10882,
                "label": "Mad River",
                "label_ar": "نهر جنون",
                "label_fr": "Mad River"
            },
            {
                "value": 10883,
                "label": "Madawaska",
                "label_ar": "مادواسكا",
                "label_fr": "Madawaska"
            },
            {
                "value": 10884,
                "label": "Madbury",
                "label_ar": "مادبوري",
                "label_fr": "Madbury"
            },
            {
                "value": 10885,
                "label": "Maddock",
                "label_ar": "مادوك",
                "label_fr": "Maddock"
            },
            {
                "value": 10886,
                "label": "Madelia",
                "label_ar": "ماديليا",
                "label_fr": "Madelia"
            },
            {
                "value": 10887,
                "label": "Madera",
                "label_ar": "ماديرا",
                "label_fr": "Madera"
            },
            {
                "value": 10888,
                "label": "Madill",
                "label_ar": "ماديل",
                "label_fr": "Madill"
            },
            {
                "value": 10889,
                "label": "Madison",
                "label_ar": "ماديسون",
                "label_fr": "Madison"
            },
            {
                "value": 10890,
                "label": "Madison Heights",
                "label_ar": "ماديسون هايتس",
                "label_fr": "Madison Heights"
            },
            {
                "value": 10891,
                "label": "Madison Lake",
                "label_ar": "ماديسون ليك",
                "label_fr": "Lac Madison"
            },
            {
                "value": 10892,
                "label": "Madisonville",
                "label_ar": "ماديسونفيل",
                "label_fr": "Madisonville"
            },
            {
                "value": 10893,
                "label": "Madras",
                "label_ar": "مدراس",
                "label_fr": "Madras"
            },
            {
                "value": 10894,
                "label": "Madrid",
                "label_ar": "مدريد",
                "label_fr": "Madrid"
            },
            {
                "value": 10895,
                "label": "Magalia",
                "label_ar": "ماجاليا",
                "label_fr": "Magalia"
            },
            {
                "value": 10896,
                "label": "Magazine",
                "label_ar": "مجلة",
                "label_fr": "Magazine"
            },
            {
                "value": 10897,
                "label": "Magdalena",
                "label_ar": "ماغدالينا",
                "label_fr": "Magdalena"
            },
            {
                "value": 10898,
                "label": "Magee",
                "label_ar": "ماجي",
                "label_fr": "Magee"
            },
            {
                "value": 10899,
                "label": "Maggie Valley",
                "label_ar": "ماجي فالي",
                "label_fr": "Maggie Valley"
            },
            {
                "value": 10900,
                "label": "Magna",
                "label_ar": "ماجنا",
                "label_fr": "Magna"
            },
            {
                "value": 10901,
                "label": "Magnolia",
                "label_ar": "ماغنوليا",
                "label_fr": "Magnolia"
            },
            {
                "value": 10902,
                "label": "Magnolia Springs",
                "label_ar": "ماغنوليا سبرينغز",
                "label_fr": "Magnolia Springs"
            },
            {
                "value": 10903,
                "label": "Mahaffey",
                "label_ar": "ماهافي",
                "label_fr": "Mahaffey"
            },
            {
                "value": 10904,
                "label": "Mahanoy City",
                "label_ar": "مدينة ماهانوي",
                "label_fr": "Mahanoy City"
            },
            {
                "value": 10905,
                "label": "Mahaska",
                "label_ar": "ماهاسكا",
                "label_fr": "Mahaska"
            },
            {
                "value": 10906,
                "label": "Mahnomen",
                "label_ar": "Mahnomen",
                "label_fr": "Mahnomen"
            },
            {
                "value": 10907,
                "label": "Mahomet",
                "label_ar": "ماهوميت",
                "label_fr": "Mahomet"
            },
            {
                "value": 10908,
                "label": "Mahopac",
                "label_ar": "Mahopac",
                "label_fr": "Mahopac"
            },
            {
                "value": 10909,
                "label": "Mahwah",
                "label_ar": "Mahwah",
                "label_fr": "Mahwah"
            },
            {
                "value": 10910,
                "label": "Maiden",
                "label_ar": "عذراء",
                "label_fr": "Jeune fille"
            },
            {
                "value": 10911,
                "label": "Maiden Rock",
                "label_ar": "صخرة البكر",
                "label_fr": "Maiden Rock"
            },
            {
                "value": 10912,
                "label": "Maidens",
                "label_ar": "عوانس",
                "label_fr": "Jeunes filles"
            },
            {
                "value": 10913,
                "label": "Maidsville",
                "label_ar": "ميدسفيل",
                "label_fr": "Maidsville"
            },
            {
                "value": 10914,
                "label": "Mainesburg",
                "label_ar": "ماينسبورغ",
                "label_fr": "Mainesburg"
            },
            {
                "value": 10915,
                "label": "Maineville",
                "label_ar": "ماينفيل",
                "label_fr": "Maineville"
            },
            {
                "value": 10916,
                "label": "Maitland",
                "label_ar": "ميتلاند",
                "label_fr": "Maitland"
            },
            {
                "value": 10917,
                "label": "Maize",
                "label_ar": "الذرة",
                "label_fr": "Maïs"
            },
            {
                "value": 10918,
                "label": "Makanda",
                "label_ar": "ماكاندا",
                "label_fr": "Makanda"
            },
            {
                "value": 10919,
                "label": "Makawao",
                "label_ar": "ماكاواو",
                "label_fr": "Makawao"
            },
            {
                "value": 10920,
                "label": "Makinen",
                "label_ar": "ماكينين",
                "label_fr": "Makinen"
            },
            {
                "value": 10921,
                "label": "Malabar",
                "label_ar": "مالابار",
                "label_fr": "Malabar"
            },
            {
                "value": 10922,
                "label": "Malad City",
                "label_ar": "مدينة مالاد",
                "label_fr": "Malad City"
            },
            {
                "value": 10923,
                "label": "Malaga",
                "label_ar": "ملقة",
                "label_fr": "Malaga"
            },
            {
                "value": 10924,
                "label": "Malakoff",
                "label_ar": "مالاكوف",
                "label_fr": "Malakoff"
            },
            {
                "value": 10925,
                "label": "Malcom",
                "label_ar": "مالكوم",
                "label_fr": "Malcom"
            },
            {
                "value": 10926,
                "label": "Malden",
                "label_ar": "مالدن",
                "label_fr": "Malden"
            },
            {
                "value": 10927,
                "label": "Malibu",
                "label_ar": "ماليبو",
                "label_fr": "Malibu"
            },
            {
                "value": 10928,
                "label": "Malin",
                "label_ar": "مالين",
                "label_fr": "Malin"
            },
            {
                "value": 10929,
                "label": "Malinta",
                "label_ar": "مالينتا",
                "label_fr": "Malinta"
            },
            {
                "value": 10930,
                "label": "Mallory",
                "label_ar": "مالوري",
                "label_fr": "Mallory"
            },
            {
                "value": 10931,
                "label": "Malmo",
                "label_ar": "مالمو",
                "label_fr": "Malmö"
            },
            {
                "value": 10932,
                "label": "Malone",
                "label_ar": "مالون",
                "label_fr": "Malone"
            },
            {
                "value": 10933,
                "label": "Malott",
                "label_ar": "مالوت",
                "label_fr": "Malott"
            },
            {
                "value": 10934,
                "label": "Malta",
                "label_ar": "مالطا",
                "label_fr": "Malte"
            },
            {
                "value": 10935,
                "label": "Malvern",
                "label_ar": "مالفيرن",
                "label_fr": "Malvern"
            },
            {
                "value": 10936,
                "label": "Malverne",
                "label_ar": "مالفيرن",
                "label_fr": "Malverne"
            },
            {
                "value": 10937,
                "label": "Mamaroneck",
                "label_ar": "Mamaroneck",
                "label_fr": "Mamaroneck"
            },
            {
                "value": 10938,
                "label": "Mammoth",
                "label_ar": "الماموث",
                "label_fr": "Mammouth"
            },
            {
                "value": 10939,
                "label": "Mammoth Lakes",
                "label_ar": "ماموث ليكس",
                "label_fr": "Mammoth Lakes"
            },
            {
                "value": 10940,
                "label": "Mamou",
                "label_ar": "مامو",
                "label_fr": "Mamou"
            },
            {
                "value": 10941,
                "label": "Man",
                "label_ar": "رجل",
                "label_fr": "Homme"
            },
            {
                "value": 10942,
                "label": "Manahawkin",
                "label_ar": "مانهاوكين",
                "label_fr": "Manahawkin"
            },
            {
                "value": 10943,
                "label": "Manasquan",
                "label_ar": "ماناسكوان",
                "label_fr": "Manasquan"
            },
            {
                "value": 10944,
                "label": "Manassas",
                "label_ar": "ماناساس",
                "label_fr": "Manassas"
            },
            {
                "value": 10945,
                "label": "Manassas Park",
                "label_ar": "حديقة ماناساس",
                "label_fr": "Parc Manassas"
            },
            {
                "value": 10946,
                "label": "Manawa",
                "label_ar": "مناوة",
                "label_fr": "Manawa"
            },
            {
                "value": 10947,
                "label": "Mancelona",
                "label_ar": "مانسيلونا",
                "label_fr": "Mancelona"
            },
            {
                "value": 10948,
                "label": "Manchaca",
                "label_ar": "مانشاكا",
                "label_fr": "Manchaca"
            },
            {
                "value": 10949,
                "label": "Manchaug",
                "label_ar": "مانشوج",
                "label_fr": "Manchaug"
            },
            {
                "value": 10950,
                "label": "Manchester",
                "label_ar": "مانشستر",
                "label_fr": "Manchester"
            },
            {
                "value": 10951,
                "label": "Manchester Center",
                "label_ar": "مركز مانشستر",
                "label_fr": "Centre de Manchester"
            },
            {
                "value": 10952,
                "label": "Manchester Township",
                "label_ar": "بلدة مانشستر",
                "label_fr": "Canton de Manchester"
            },
            {
                "value": 10953,
                "label": "Manchester-by-the-Sea",
                "label_ar": "مانشستر عن طريق البحر",
                "label_fr": "Manchester-by-the-Sea"
            },
            {
                "value": 10954,
                "label": "Mancos",
                "label_ar": "مانكوس",
                "label_fr": "Mancos"
            },
            {
                "value": 10955,
                "label": "Mandan",
                "label_ar": "ماندان",
                "label_fr": "Mandan"
            },
            {
                "value": 10956,
                "label": "Mandaree",
                "label_ar": "Mandaree",
                "label_fr": "Mandaree"
            },
            {
                "value": 10957,
                "label": "Manderson",
                "label_ar": "ماندرسون",
                "label_fr": "Manderson"
            },
            {
                "value": 10958,
                "label": "Mandeville",
                "label_ar": "ماندفيل",
                "label_fr": "Mandeville"
            },
            {
                "value": 10959,
                "label": "Mangum",
                "label_ar": "مانغوم",
                "label_fr": "Mangum"
            },
            {
                "value": 10960,
                "label": "Manhasset",
                "label_ar": "مانهاست",
                "label_fr": "Manhasset"
            },
            {
                "value": 10961,
                "label": "Manhattan",
                "label_ar": "مانهاتن",
                "label_fr": "Manhattan"
            },
            {
                "value": 10962,
                "label": "Manhattan Beach",
                "label_ar": "شاطئ مانهاتن",
                "label_fr": "Manhattan Beach"
            },
            {
                "value": 10963,
                "label": "Manheim",
                "label_ar": "مانهايم",
                "label_fr": "Manheim"
            },
            {
                "value": 10964,
                "label": "Manila",
                "label_ar": "مانيلا",
                "label_fr": "Manille"
            },
            {
                "value": 10965,
                "label": "Manistee",
                "label_ar": "مانيستي",
                "label_fr": "Manistee"
            },
            {
                "value": 10966,
                "label": "Manistique",
                "label_ar": "مانيستيك",
                "label_fr": "Manistique"
            },
            {
                "value": 10967,
                "label": "Manito",
                "label_ar": "مانيتو",
                "label_fr": "Manito"
            },
            {
                "value": 10968,
                "label": "Manitou",
                "label_ar": "مانيتو",
                "label_fr": "Manitou"
            },
            {
                "value": 10969,
                "label": "Manitou Springs",
                "label_ar": "مانيتو سبرينغز",
                "label_fr": "Manitou Springs"
            },
            {
                "value": 10970,
                "label": "Manitowoc",
                "label_ar": "مانيتووك",
                "label_fr": "Manitowoc"
            },
            {
                "value": 10971,
                "label": "Mankato",
                "label_ar": "مانكاتو",
                "label_fr": "Mankato"
            },
            {
                "value": 10972,
                "label": "Manlius",
                "label_ar": "مانليوس",
                "label_fr": "Manlius"
            },
            {
                "value": 10973,
                "label": "Manly",
                "label_ar": "رجولي",
                "label_fr": "Viril"
            },
            {
                "value": 10974,
                "label": "Mannford",
                "label_ar": "مانفورد",
                "label_fr": "Mannford"
            },
            {
                "value": 10975,
                "label": "Manning",
                "label_ar": "مانينغ",
                "label_fr": "Manning"
            },
            {
                "value": 10976,
                "label": "Mannington",
                "label_ar": "مانينغتون",
                "label_fr": "Mannington"
            },
            {
                "value": 10977,
                "label": "Manns Choice",
                "label_ar": "اختيار مان",
                "label_fr": "Choix de Manns"
            },
            {
                "value": 10978,
                "label": "Mannsville",
                "label_ar": "مانزفيل",
                "label_fr": "Mannsville"
            },
            {
                "value": 10979,
                "label": "Manokotak",
                "label_ar": "مانوكوتاك",
                "label_fr": "Manokotak"
            },
            {
                "value": 10980,
                "label": "Manomet",
                "label_ar": "مانوميت",
                "label_fr": "Manomet"
            },
            {
                "value": 10981,
                "label": "Manor",
                "label_ar": "مانور",
                "label_fr": "Manoir"
            },
            {
                "value": 10982,
                "label": "Manorville",
                "label_ar": "مانورفيل",
                "label_fr": "Manorville"
            },
            {
                "value": 10983,
                "label": "Manquin",
                "label_ar": "مانكوين",
                "label_fr": "Manquin"
            },
            {
                "value": 10984,
                "label": "Mansfield",
                "label_ar": "مانسفيلد",
                "label_fr": "Mansfield"
            },
            {
                "value": 10985,
                "label": "Mansfield Center",
                "label_ar": "مركز مانسفيلد",
                "label_fr": "Centre Mansfield"
            },
            {
                "value": 10986,
                "label": "Mansfield Depot",
                "label_ar": "مستودع مانسفيلد",
                "label_fr": "Dépôt de Mansfield"
            },
            {
                "value": 10987,
                "label": "Manson",
                "label_ar": "مانسون",
                "label_fr": "Manson"
            },
            {
                "value": 10988,
                "label": "Mansura",
                "label_ar": "المنصورة",
                "label_fr": "Mansura"
            },
            {
                "value": 10989,
                "label": "Mantachie",
                "label_ar": "مانتاشي",
                "label_fr": "Mantachie"
            },
            {
                "value": 10990,
                "label": "Manteca",
                "label_ar": "مانتيكا",
                "label_fr": "Manteca"
            },
            {
                "value": 10991,
                "label": "Mantee",
                "label_ar": "مانتي",
                "label_fr": "Mantee"
            },
            {
                "value": 10992,
                "label": "Manteno",
                "label_ar": "مانتينو",
                "label_fr": "Manteno"
            },
            {
                "value": 10993,
                "label": "Manteo",
                "label_ar": "مانتيو",
                "label_fr": "Manteo"
            },
            {
                "value": 10994,
                "label": "Manti",
                "label_ar": "مانتي",
                "label_fr": "Manti"
            },
            {
                "value": 10995,
                "label": "Mantoloking",
                "label_ar": "مانتولوكينغ",
                "label_fr": "Mantoloking"
            },
            {
                "value": 10996,
                "label": "Manton",
                "label_ar": "مانتون",
                "label_fr": "Manton"
            },
            {
                "value": 10997,
                "label": "Mantorville",
                "label_ar": "مانتورفيل",
                "label_fr": "Mantorville"
            },
            {
                "value": 10998,
                "label": "Mantua",
                "label_ar": "مانتوفا",
                "label_fr": "Mantoue"
            },
            {
                "value": 10999,
                "label": "Manvel",
                "label_ar": "مانفيل",
                "label_fr": "Manvel"
            },
            {
                "value": 11000,
                "label": "Manville",
                "label_ar": "مانفيل",
                "label_fr": "Manville"
            },
            {
                "value": 11001,
                "label": "Many",
                "label_ar": "كثير",
                "label_fr": "Beaucoup"
            },
            {
                "value": 11002,
                "label": "Many Farms",
                "label_ar": "العديد من المزارع",
                "label_fr": "De nombreuses fermes"
            },
            {
                "value": 11003,
                "label": "Manzanita",
                "label_ar": "مانزانيتا",
                "label_fr": "Manzanita"
            },
            {
                "value": 11004,
                "label": "Manzanola",
                "label_ar": "مانزانولا",
                "label_fr": "Manzanola"
            },
            {
                "value": 11005,
                "label": "Maple City",
                "label_ar": "مدينة القيقب",
                "label_fr": "Maple City"
            },
            {
                "value": 11006,
                "label": "Maple Falls",
                "label_ar": "شلالات القيقب",
                "label_fr": "Maple Falls"
            },
            {
                "value": 11007,
                "label": "Maple Grove",
                "label_ar": "مابل جروف",
                "label_fr": "Maple Grove"
            },
            {
                "value": 11008,
                "label": "Maple Heights",
                "label_ar": "مرتفعات القيقب",
                "label_fr": "Maple Heights"
            },
            {
                "value": 11009,
                "label": "Maple Hill",
                "label_ar": "مابل هيل",
                "label_fr": "Maple Hill"
            },
            {
                "value": 11010,
                "label": "Maple Lake",
                "label_ar": "بحيرة القيقب",
                "label_fr": "Lac Maple"
            },
            {
                "value": 11011,
                "label": "Maple Park",
                "label_ar": "حديقة القيقب",
                "label_fr": "Maple Park"
            },
            {
                "value": 11012,
                "label": "Maple Plain",
                "label_ar": "سهل القيقب",
                "label_fr": "Érable plaine"
            },
            {
                "value": 11013,
                "label": "Maple Rapids",
                "label_ar": "مابل رابيدز",
                "label_fr": "Maple Rapids"
            },
            {
                "value": 11014,
                "label": "Maple Shade",
                "label_ar": "ظل القيقب",
                "label_fr": "Abat-jour en érable"
            },
            {
                "value": 11015,
                "label": "Maple Valley",
                "label_ar": "وادي القيقب",
                "label_fr": "Maple Valley"
            },
            {
                "value": 11016,
                "label": "Maplesville",
                "label_ar": "مابليسفيل",
                "label_fr": "Maplesville"
            },
            {
                "value": 11017,
                "label": "Mapleton",
                "label_ar": "مابلتون",
                "label_fr": "Mapleton"
            },
            {
                "value": 11018,
                "label": "Mapleville",
                "label_ar": "مابليفيل",
                "label_fr": "Mapleville"
            },
            {
                "value": 11019,
                "label": "Maplewood",
                "label_ar": "خشب القيقب",
                "label_fr": "Bois d'érable"
            },
            {
                "value": 11020,
                "label": "Mappsville",
                "label_ar": "مابسفيل",
                "label_fr": "Mappsville"
            },
            {
                "value": 11021,
                "label": "Maquoketa",
                "label_ar": "ماكوكيتا",
                "label_fr": "Maquoketa"
            },
            {
                "value": 11022,
                "label": "Marana",
                "label_ar": "مارانا",
                "label_fr": "Marana"
            },
            {
                "value": 11023,
                "label": "Marathon",
                "label_ar": "ماراثون",
                "label_fr": "Marathon"
            },
            {
                "value": 11024,
                "label": "Marble",
                "label_ar": "رخام",
                "label_fr": "Marbre"
            },
            {
                "value": 11025,
                "label": "Marble Falls",
                "label_ar": "شلالات الرخام",
                "label_fr": "Marble Falls"
            },
            {
                "value": 11026,
                "label": "Marble Hill",
                "label_ar": "ماربل هيل",
                "label_fr": "Marble Hill"
            },
            {
                "value": 11027,
                "label": "Marble Rock",
                "label_ar": "ماربل روك",
                "label_fr": "Marbre Rock"
            },
            {
                "value": 11028,
                "label": "Marblehead",
                "label_ar": "ماربلهيد",
                "label_fr": "Marblehead"
            },
            {
                "value": 11029,
                "label": "Marblehill",
                "label_ar": "ماربلهيل",
                "label_fr": "Marblehill"
            },
            {
                "value": 11030,
                "label": "Marblemount",
                "label_ar": "ماربل ماونت",
                "label_fr": "Marblemount"
            },
            {
                "value": 11031,
                "label": "Marbury",
                "label_ar": "ماربوري",
                "label_fr": "Marbury"
            },
            {
                "value": 11032,
                "label": "Marceline",
                "label_ar": "مارسيلين",
                "label_fr": "Marceline"
            },
            {
                "value": 11033,
                "label": "Marcell",
                "label_ar": "مارسيل",
                "label_fr": "Marcell"
            },
            {
                "value": 11034,
                "label": "Marcellus",
                "label_ar": "مارسيلوس",
                "label_fr": "Marcellus"
            },
            {
                "value": 11035,
                "label": "March Air Force Base",
                "label_ar": "قاعدة مارس الجوية",
                "label_fr": "Base aérienne de mars"
            },
            {
                "value": 11036,
                "label": "Marco Island",
                "label_ar": "جزيرة ماركو",
                "label_fr": "Marco Island"
            },
            {
                "value": 11037,
                "label": "Marcus",
                "label_ar": "ماركوس",
                "label_fr": "Marcus"
            },
            {
                "value": 11038,
                "label": "Marcus Hook",
                "label_ar": "ماركوس هوك",
                "label_fr": "Marcus Hook"
            },
            {
                "value": 11039,
                "label": "Marcy",
                "label_ar": "مارسي",
                "label_fr": "Marcy"
            },
            {
                "value": 11040,
                "label": "Mardela Springs",
                "label_ar": "مارديلا سبرينجز",
                "label_fr": "Mardela Springs"
            },
            {
                "value": 11041,
                "label": "Marengo",
                "label_ar": "مارينغو",
                "label_fr": "Marengo"
            },
            {
                "value": 11042,
                "label": "Marenisco",
                "label_ar": "مارينيسكو",
                "label_fr": "Marenisco"
            },
            {
                "value": 11043,
                "label": "Marfa",
                "label_ar": "المرفأ",
                "label_fr": "Marfa"
            },
            {
                "value": 11044,
                "label": "Margaret",
                "label_ar": "مارجريت",
                "label_fr": "Margaret"
            },
            {
                "value": 11045,
                "label": "Margaretville",
                "label_ar": "مارغريتفيل",
                "label_fr": "Margaretville"
            },
            {
                "value": 11046,
                "label": "Margate City",
                "label_ar": "مارجيت سيتي",
                "label_fr": "Ville de Margate"
            },
            {
                "value": 11047,
                "label": "Maria Stein",
                "label_ar": "ماريا شتاين",
                "label_fr": "Maria Stein"
            },
            {
                "value": 11048,
                "label": "Marianna",
                "label_ar": "ماريانا",
                "label_fr": "Marianna"
            },
            {
                "value": 11049,
                "label": "Maribel",
                "label_ar": "ماريبل",
                "label_fr": "Maribel"
            },
            {
                "value": 11050,
                "label": "Maricopa",
                "label_ar": "ماريكوبا",
                "label_fr": "Maricopa"
            },
            {
                "value": 11051,
                "label": "Marienthal",
                "label_ar": "مارينثال",
                "label_fr": "Marienthal"
            },
            {
                "value": 11052,
                "label": "Marienville",
                "label_ar": "مارينفيل",
                "label_fr": "Marienville"
            },
            {
                "value": 11053,
                "label": "Marietta",
                "label_ar": "ماريتا",
                "label_fr": "Marietta"
            },
            {
                "value": 11054,
                "label": "Marina",
                "label_ar": "مارينا",
                "label_fr": "Marina"
            },
            {
                "value": 11055,
                "label": "Marina del Rey",
                "label_ar": "مارينا ديل ري",
                "label_fr": "Marina del Rey"
            },
            {
                "value": 11056,
                "label": "Marine",
                "label_ar": "البحرية",
                "label_fr": "Marin"
            },
            {
                "value": 11057,
                "label": "Marine City",
                "label_ar": "المدينة البحرية",
                "label_fr": "Ville marine"
            },
            {
                "value": 11058,
                "label": "Marine on Saint Croix",
                "label_ar": "البحرية في سانت كروا",
                "label_fr": "Marine sur Sainte Croix"
            },
            {
                "value": 11059,
                "label": "Marinette",
                "label_ar": "مارينيت",
                "label_fr": "Marinette"
            },
            {
                "value": 11060,
                "label": "Maringouin",
                "label_ar": "مارينغوين",
                "label_fr": "Maringouin"
            },
            {
                "value": 11061,
                "label": "Marion",
                "label_ar": "ماريون",
                "label_fr": "Marion"
            },
            {
                "value": 11062,
                "label": "Marion Center",
                "label_ar": "مركز ماريون",
                "label_fr": "Centre Marion"
            },
            {
                "value": 11063,
                "label": "Marion Heights",
                "label_ar": "مرتفعات ماريون",
                "label_fr": "Marion Heights"
            },
            {
                "value": 11064,
                "label": "Marion Junction",
                "label_ar": "تقاطع ماريون",
                "label_fr": "Marion Junction"
            },
            {
                "value": 11065,
                "label": "Marionville",
                "label_ar": "ماريونفيل",
                "label_fr": "Marionville"
            },
            {
                "value": 11066,
                "label": "Mariposa",
                "label_ar": "ماريبوسا",
                "label_fr": "Mariposa"
            },
            {
                "value": 11067,
                "label": "Marissa",
                "label_ar": "ماريسا",
                "label_fr": "Marissa"
            },
            {
                "value": 11068,
                "label": "Mark Center",
                "label_ar": "مارك سنتر",
                "label_fr": "Mark Center"
            },
            {
                "value": 11069,
                "label": "Marked Tree",
                "label_ar": "شجرة ملحوظة",
                "label_fr": "Arbre marqué"
            },
            {
                "value": 11070,
                "label": "Markesan",
                "label_ar": "ماركيسان",
                "label_fr": "Markesan"
            },
            {
                "value": 11071,
                "label": "Markham",
                "label_ar": "ماركهام",
                "label_fr": "Markham"
            },
            {
                "value": 11072,
                "label": "Markle",
                "label_ar": "ماركل",
                "label_fr": "Markle"
            },
            {
                "value": 11073,
                "label": "Markleeville",
                "label_ar": "ماركلفيل",
                "label_fr": "Markleeville"
            },
            {
                "value": 11074,
                "label": "Markleville",
                "label_ar": "ماركليفيل",
                "label_fr": "Markleville"
            },
            {
                "value": 11075,
                "label": "Markleysburg",
                "label_ar": "ماركليسبورج",
                "label_fr": "Markleysburg"
            },
            {
                "value": 11076,
                "label": "Marks",
                "label_ar": "ماركس",
                "label_fr": "Des marques"
            },
            {
                "value": 11077,
                "label": "Marksville",
                "label_ar": "ماركسفيل",
                "label_fr": "Marksville"
            },
            {
                "value": 11078,
                "label": "Marlboro",
                "label_ar": "مارلبورو",
                "label_fr": "Marlboro"
            },
            {
                "value": 11079,
                "label": "Marlborough",
                "label_ar": "مارلبورو",
                "label_fr": "Marlborough"
            },
            {
                "value": 11080,
                "label": "Marlette",
                "label_ar": "مارليت",
                "label_fr": "Marlette"
            },
            {
                "value": 11081,
                "label": "Marlin",
                "label_ar": "مارلين",
                "label_fr": "Marlin"
            },
            {
                "value": 11082,
                "label": "Marlinton",
                "label_ar": "مارلينتون",
                "label_fr": "Marlinton"
            },
            {
                "value": 11083,
                "label": "Marlow",
                "label_ar": "مارلو",
                "label_fr": "Marlow"
            },
            {
                "value": 11084,
                "label": "Marlton",
                "label_ar": "مارلتون",
                "label_fr": "Marlton"
            },
            {
                "value": 11085,
                "label": "Marmora",
                "label_ar": "مرمورا",
                "label_fr": "Marmora"
            },
            {
                "value": 11086,
                "label": "Marne",
                "label_ar": "مارن",
                "label_fr": "Marne"
            },
            {
                "value": 11087,
                "label": "Maroa",
                "label_ar": "ماروا",
                "label_fr": "Maroa"
            },
            {
                "value": 11088,
                "label": "Marquand",
                "label_ar": "ماركواند",
                "label_fr": "Marquand"
            },
            {
                "value": 11089,
                "label": "Marquette",
                "label_ar": "ماركيت",
                "label_fr": "Marquette"
            },
            {
                "value": 11090,
                "label": "Marquez",
                "label_ar": "ماركيز",
                "label_fr": "Marquez"
            },
            {
                "value": 11091,
                "label": "Marrero",
                "label_ar": "ماريرو",
                "label_fr": "Marrero"
            },
            {
                "value": 11092,
                "label": "Marriottsville",
                "label_ar": "ماريوتسفيل",
                "label_fr": "Marriottsville"
            },
            {
                "value": 11093,
                "label": "Mars",
                "label_ar": "المريخ",
                "label_fr": "Mars"
            },
            {
                "value": 11094,
                "label": "Mars Hill",
                "label_ar": "مارس هيل",
                "label_fr": "Mars Hill"
            },
            {
                "value": 11095,
                "label": "Marseilles",
                "label_ar": "مرسيليا",
                "label_fr": "Marseille"
            },
            {
                "value": 11096,
                "label": "Marshall",
                "label_ar": "مارشال",
                "label_fr": "Marshall"
            },
            {
                "value": 11097,
                "label": "Marshalls Creek",
                "label_ar": "مارشال كريك",
                "label_fr": "Marshalls Creek"
            },
            {
                "value": 11098,
                "label": "Marshalltown",
                "label_ar": "مارشالتاون",
                "label_fr": "Marshalltown"
            },
            {
                "value": 11099,
                "label": "Marshallville",
                "label_ar": "مارشالفيل",
                "label_fr": "Marshallville"
            },
            {
                "value": 11100,
                "label": "Marshfield",
                "label_ar": "مارشفيلد",
                "label_fr": "Marshfield"
            },
            {
                "value": 11101,
                "label": "Marshfield Hills",
                "label_ar": "مارشفيلد هيلز",
                "label_fr": "Marshfield Hills"
            },
            {
                "value": 11102,
                "label": "Marshville",
                "label_ar": "مارشفيل",
                "label_fr": "Marshville"
            },
            {
                "value": 11103,
                "label": "Marsing",
                "label_ar": "التمرين",
                "label_fr": "Marsing"
            },
            {
                "value": 11104,
                "label": "Marsteller",
                "label_ar": "مارستيلر",
                "label_fr": "Marsteller"
            },
            {
                "value": 11105,
                "label": "Marstons Mills",
                "label_ar": "مارستون ميلز",
                "label_fr": "Marstons Mills"
            },
            {
                "value": 11106,
                "label": "Mart",
                "label_ar": "مارت",
                "label_fr": "Marché"
            },
            {
                "value": 11107,
                "label": "Martell",
                "label_ar": "مارتيل",
                "label_fr": "Martell"
            },
            {
                "value": 11108,
                "label": "Martelle",
                "label_ar": "مارتيل",
                "label_fr": "Martelle"
            },
            {
                "value": 11109,
                "label": "Martha",
                "label_ar": "مارثا",
                "label_fr": "Martha"
            },
            {
                "value": 11110,
                "label": "Marthasville",
                "label_ar": "مارثاسفيل",
                "label_fr": "Marthasville"
            },
            {
                "value": 11111,
                "label": "Marthaville",
                "label_ar": "مارثافيل",
                "label_fr": "Marthaville"
            },
            {
                "value": 11112,
                "label": "Martin",
                "label_ar": "مارتن",
                "label_fr": "Martin"
            },
            {
                "value": 11113,
                "label": "Martindale",
                "label_ar": "مارتنديل",
                "label_fr": "Martindale"
            },
            {
                "value": 11114,
                "label": "Martinez",
                "label_ar": "مارتينيز",
                "label_fr": "Martinez"
            },
            {
                "value": 11115,
                "label": "Martins Creek",
                "label_ar": "مارتينز كريك",
                "label_fr": "Martins Creek"
            },
            {
                "value": 11116,
                "label": "Martins Ferry",
                "label_ar": "مارتينز فيري",
                "label_fr": "Martins Ferry"
            },
            {
                "value": 11117,
                "label": "Martinsburg",
                "label_ar": "Martinsburg",
                "label_fr": "Martinsburg"
            },
            {
                "value": 11118,
                "label": "Martinsdale",
                "label_ar": "مارتينسديل",
                "label_fr": "Martinsdale"
            },
            {
                "value": 11119,
                "label": "Martinsville",
                "label_ar": "مارتينسفيل",
                "label_fr": "Martinsville"
            },
            {
                "value": 11120,
                "label": "Martville",
                "label_ar": "مارتفيل",
                "label_fr": "Martville"
            },
            {
                "value": 11121,
                "label": "Mary Esther",
                "label_ar": "ماري إستير",
                "label_fr": "Mary Esther"
            },
            {
                "value": 11122,
                "label": "Marydel",
                "label_ar": "مارديل",
                "label_fr": "Marydel"
            },
            {
                "value": 11123,
                "label": "Maryknoll",
                "label_ar": "مارينول",
                "label_fr": "Maryknoll"
            },
            {
                "value": 11124,
                "label": "Maryland",
                "label_ar": "ماريلاند",
                "label_fr": "Maryland"
            },
            {
                "value": 11125,
                "label": "Maryland Heights",
                "label_ar": "مرتفعات ماريلاند",
                "label_fr": "Maryland Heights"
            },
            {
                "value": 11126,
                "label": "Marylhurst",
                "label_ar": "ماريلهيرست",
                "label_fr": "Marylhurst"
            },
            {
                "value": 11127,
                "label": "Maryneal",
                "label_ar": "مارينيل",
                "label_fr": "Maryneal"
            },
            {
                "value": 11128,
                "label": "Marysvale",
                "label_ar": "ماريسفال",
                "label_fr": "Marysvale"
            },
            {
                "value": 11129,
                "label": "Marysville",
                "label_ar": "ماريسفيل",
                "label_fr": "Marysville"
            },
            {
                "value": 11130,
                "label": "Maryville",
                "label_ar": "ماريفيل",
                "label_fr": "Maryville"
            },
            {
                "value": 11131,
                "label": "Mascot",
                "label_ar": "تعويذة",
                "label_fr": "Mascotte"
            },
            {
                "value": 11132,
                "label": "Mascotte",
                "label_ar": "ماسكوت",
                "label_fr": "Mascotte"
            },
            {
                "value": 11133,
                "label": "Mascoutah",
                "label_ar": "مسكوتة",
                "label_fr": "Mascoutah"
            },
            {
                "value": 11134,
                "label": "Mashantucket",
                "label_ar": "ماشانتوكيت",
                "label_fr": "Mashantucket"
            },
            {
                "value": 11135,
                "label": "Mashpee",
                "label_ar": "ماشبي",
                "label_fr": "Mashpee"
            },
            {
                "value": 11136,
                "label": "Maskell",
                "label_ar": "مسكل",
                "label_fr": "Maskell"
            },
            {
                "value": 11137,
                "label": "Mason",
                "label_ar": "ميسون",
                "label_fr": "le maçon"
            },
            {
                "value": 11138,
                "label": "Mason City",
                "label_ar": "ماسون سيتي",
                "label_fr": "Mason City"
            },
            {
                "value": 11139,
                "label": "Masontown",
                "label_ar": "ماسونتاون",
                "label_fr": "Masontown"
            },
            {
                "value": 11140,
                "label": "Masonville",
                "label_ar": "ماسونفيل",
                "label_fr": "Masonville"
            },
            {
                "value": 11141,
                "label": "Maspeth",
                "label_ar": "ماسبيث",
                "label_fr": "Maspeth"
            },
            {
                "value": 11142,
                "label": "Mass City",
                "label_ar": "ماس سيتي",
                "label_fr": "Mass City"
            },
            {
                "value": 11143,
                "label": "Massapequa",
                "label_ar": "ماسابيكوا",
                "label_fr": "Massapequa"
            },
            {
                "value": 11144,
                "label": "Massapequa Park",
                "label_ar": "حديقة ماسابيكوا",
                "label_fr": "Parc Massapequa"
            },
            {
                "value": 11145,
                "label": "Massena",
                "label_ar": "ماسينا",
                "label_fr": "Masséna"
            },
            {
                "value": 11146,
                "label": "Massey",
                "label_ar": "ماسي",
                "label_fr": "Massey"
            },
            {
                "value": 11147,
                "label": "Massillon",
                "label_ar": "ماسيلون",
                "label_fr": "Massillon"
            },
            {
                "value": 11148,
                "label": "Mastic",
                "label_ar": "المصطكي",
                "label_fr": "du mastique"
            },
            {
                "value": 11149,
                "label": "Mastic Beach",
                "label_ar": "شاطئ ماستيك",
                "label_fr": "Plage de Mastic"
            },
            {
                "value": 11150,
                "label": "Masury",
                "label_ar": "ماسوري",
                "label_fr": "Masury"
            },
            {
                "value": 11151,
                "label": "Matamoras",
                "label_ar": "ماتاموراس",
                "label_fr": "Matamoras"
            },
            {
                "value": 11152,
                "label": "Matawan",
                "label_ar": "مطاوان",
                "label_fr": "Matawan"
            },
            {
                "value": 11153,
                "label": "Matewan",
                "label_ar": "ماتيوان",
                "label_fr": "Matewan"
            },
            {
                "value": 11154,
                "label": "Matfield Green",
                "label_ar": "ماتفيلد جرين",
                "label_fr": "Matfield Green"
            },
            {
                "value": 11155,
                "label": "Mather Field",
                "label_ar": "ماثر فيلد",
                "label_fr": "Champ Mather"
            },
            {
                "value": 11156,
                "label": "Mathews",
                "label_ar": "ماثيوز",
                "label_fr": "Mathews"
            },
            {
                "value": 11157,
                "label": "Mathias",
                "label_ar": "ماتياس",
                "label_fr": "Mathias"
            },
            {
                "value": 11158,
                "label": "Mathis",
                "label_ar": "ماتيس",
                "label_fr": "Mathis"
            },
            {
                "value": 11159,
                "label": "Mathiston",
                "label_ar": "ماثيستون",
                "label_fr": "Mathiston"
            },
            {
                "value": 11160,
                "label": "Matinicus",
                "label_ar": "ماتينيكوس",
                "label_fr": "Matinicus"
            },
            {
                "value": 11161,
                "label": "Matoaka",
                "label_ar": "ماتواكا",
                "label_fr": "Matoaka"
            },
            {
                "value": 11162,
                "label": "Mattapan",
                "label_ar": "ماتابان",
                "label_fr": "Mattapan"
            },
            {
                "value": 11163,
                "label": "Mattapoisett",
                "label_ar": "ماتابويسيت",
                "label_fr": "Mattapoisett"
            },
            {
                "value": 11164,
                "label": "Mattaponi",
                "label_ar": "ماتابوني",
                "label_fr": "Mattaponi"
            },
            {
                "value": 11165,
                "label": "Mattawa",
                "label_ar": "مطاوع",
                "label_fr": "Mattawa"
            },
            {
                "value": 11166,
                "label": "Mattawamkeag",
                "label_ar": "متومكيج",
                "label_fr": "Mattawamkeag"
            },
            {
                "value": 11167,
                "label": "Mattawan",
                "label_ar": "متوان",
                "label_fr": "Mattawan"
            },
            {
                "value": 11168,
                "label": "Mattawana",
                "label_ar": "مطاونة",
                "label_fr": "Mattawana"
            },
            {
                "value": 11169,
                "label": "Matteson",
                "label_ar": "ماتيسون",
                "label_fr": "Matteson"
            },
            {
                "value": 11170,
                "label": "Matthews",
                "label_ar": "ماثيوز",
                "label_fr": "Matthews"
            },
            {
                "value": 11171,
                "label": "Mattituck",
                "label_ar": "ماتيتوك",
                "label_fr": "Mattituck"
            },
            {
                "value": 11172,
                "label": "Mattoon",
                "label_ar": "متون",
                "label_fr": "Mattoon"
            },
            {
                "value": 11173,
                "label": "Mauckport",
                "label_ar": "ماكبورت",
                "label_fr": "Mauckport"
            },
            {
                "value": 11174,
                "label": "Maud",
                "label_ar": "مود",
                "label_fr": "Maud"
            },
            {
                "value": 11175,
                "label": "Mauk",
                "label_ar": "ماوك",
                "label_fr": "Mauk"
            },
            {
                "value": 11176,
                "label": "Mauldin",
                "label_ar": "مولدين",
                "label_fr": "Mauldin"
            },
            {
                "value": 11177,
                "label": "Maumee",
                "label_ar": "ماومي",
                "label_fr": "Maumee"
            },
            {
                "value": 11178,
                "label": "Maumelle",
                "label_ar": "موميل",
                "label_fr": "Maumelle"
            },
            {
                "value": 11179,
                "label": "Maunaloa",
                "label_ar": "مونالوا",
                "label_fr": "Mauna Loa"
            },
            {
                "value": 11180,
                "label": "Maupin",
                "label_ar": "موبين",
                "label_fr": "Maupin"
            },
            {
                "value": 11181,
                "label": "Maurepas",
                "label_ar": "موريباس",
                "label_fr": "Maurepas"
            },
            {
                "value": 11182,
                "label": "Maurertown",
                "label_ar": "موريتاون",
                "label_fr": "Maurertown"
            },
            {
                "value": 11183,
                "label": "Maurice",
                "label_ar": "موريس",
                "label_fr": "Maurice"
            },
            {
                "value": 11184,
                "label": "Mauriceville",
                "label_ar": "موريسفيل",
                "label_fr": "Mauriceville"
            },
            {
                "value": 11185,
                "label": "Maury",
                "label_ar": "موري",
                "label_fr": "Maury"
            },
            {
                "value": 11186,
                "label": "Maury City",
                "label_ar": "مدينة موري",
                "label_fr": "Maury City"
            },
            {
                "value": 11187,
                "label": "Mauston",
                "label_ar": "ماوستون",
                "label_fr": "Mauston"
            },
            {
                "value": 11188,
                "label": "Max",
                "label_ar": "ماكس",
                "label_fr": "Max"
            },
            {
                "value": 11189,
                "label": "Max Meadows",
                "label_ar": "ماكس ميدوز",
                "label_fr": "Max Meadows"
            },
            {
                "value": 11190,
                "label": "Maxatawny",
                "label_ar": "مكساتوني",
                "label_fr": "Maxatawny"
            },
            {
                "value": 11191,
                "label": "Maxbass",
                "label_ar": "ماكسباس",
                "label_fr": "Maxbass"
            },
            {
                "value": 11192,
                "label": "Maxton",
                "label_ar": "ماكستون",
                "label_fr": "Maxton"
            },
            {
                "value": 11193,
                "label": "Maxwell",
                "label_ar": "ماكسويل",
                "label_fr": "Maxwell"
            },
            {
                "value": 11194,
                "label": "May",
                "label_ar": "مايو",
                "label_fr": "Mai"
            },
            {
                "value": 11195,
                "label": "May Creek",
                "label_ar": "ماي كريك",
                "label_fr": "May Creek"
            },
            {
                "value": 11196,
                "label": "Maybee",
                "label_ar": "مايبي",
                "label_fr": "Maybee"
            },
            {
                "value": 11197,
                "label": "Maybrook",
                "label_ar": "مايبروك",
                "label_fr": "Maybrook"
            },
            {
                "value": 11198,
                "label": "Mayer",
                "label_ar": "ماير",
                "label_fr": "Mayer"
            },
            {
                "value": 11199,
                "label": "Mayesville",
                "label_ar": "مايسفيل",
                "label_fr": "Mayesville"
            },
            {
                "value": 11200,
                "label": "Mayetta",
                "label_ar": "مايتا",
                "label_fr": "Mayetta"
            },
            {
                "value": 11201,
                "label": "Mayfield",
                "label_ar": "مايفيلد",
                "label_fr": "Mayfield"
            },
            {
                "value": 11202,
                "label": "Mayflower",
                "label_ar": "ماي فلاور",
                "label_fr": "Fleur de mai"
            },
            {
                "value": 11203,
                "label": "Maylene",
                "label_ar": "مايلين",
                "label_fr": "Maylene"
            },
            {
                "value": 11204,
                "label": "Maynard",
                "label_ar": "ماينارد",
                "label_fr": "Maynard"
            },
            {
                "value": 11205,
                "label": "Maynardville",
                "label_ar": "مايناردفيل",
                "label_fr": "Maynardville"
            },
            {
                "value": 11206,
                "label": "Mayo",
                "label_ar": "مايو",
                "label_fr": "Mayo"
            },
            {
                "value": 11207,
                "label": "Mayodan",
                "label_ar": "Mayodan",
                "label_fr": "Mayodan"
            },
            {
                "value": 11208,
                "label": "Maypearl",
                "label_ar": "ماي بيرل",
                "label_fr": "Maypearl"
            },
            {
                "value": 11209,
                "label": "Mayport",
                "label_ar": "مايبورت",
                "label_fr": "Mayport"
            },
            {
                "value": 11210,
                "label": "Mays Landing",
                "label_ar": "ميس الهبوط",
                "label_fr": "Mays Landing"
            },
            {
                "value": 11211,
                "label": "Maysel",
                "label_ar": "ميسل",
                "label_fr": "Maysel"
            },
            {
                "value": 11212,
                "label": "Maysville",
                "label_ar": "ميسفيل",
                "label_fr": "Maysville"
            },
            {
                "value": 11213,
                "label": "Mayville",
                "label_ar": "مايفيل",
                "label_fr": "Mayville"
            },
            {
                "value": 11214,
                "label": "Maywood",
                "label_ar": "مايوود",
                "label_fr": "Maywood"
            },
            {
                "value": 11215,
                "label": "Mazeppa",
                "label_ar": "مازيبا",
                "label_fr": "Mazeppa"
            },
            {
                "value": 11216,
                "label": "Mazomanie",
                "label_ar": "Mazomanie",
                "label_fr": "Mazomanie"
            },
            {
                "value": 11217,
                "label": "Mazon",
                "label_ar": "مازون",
                "label_fr": "Mazon"
            },
            {
                "value": 11218,
                "label": "McAdoo",
                "label_ar": "ماكادو",
                "label_fr": "McAdoo"
            },
            {
                "value": 11219,
                "label": "McAlester",
                "label_ar": "مكاليستر",
                "label_fr": "McAlester"
            },
            {
                "value": 11220,
                "label": "McAlister",
                "label_ar": "مكاليستر",
                "label_fr": "McAlister"
            },
            {
                "value": 11221,
                "label": "McAlisterville",
                "label_ar": "مكاليسترفيل",
                "label_fr": "McAlisterville"
            },
            {
                "value": 11222,
                "label": "McAllen",
                "label_ar": "ماك ألين",
                "label_fr": "McAllen"
            },
            {
                "value": 11223,
                "label": "McAlpin",
                "label_ar": "مكالبين",
                "label_fr": "McAlpin"
            },
            {
                "value": 11224,
                "label": "McArthur",
                "label_ar": "مكارثر",
                "label_fr": "McArthur"
            },
            {
                "value": 11225,
                "label": "McBain",
                "label_ar": "ماكبين",
                "label_fr": "McBain"
            },
            {
                "value": 11226,
                "label": "McBee",
                "label_ar": "ماكبي",
                "label_fr": "McBee"
            },
            {
                "value": 11227,
                "label": "McCall",
                "label_ar": "ماكول",
                "label_fr": "McCall"
            },
            {
                "value": 11228,
                "label": "McCalla",
                "label_ar": "مكلا",
                "label_fr": "McCalla"
            },
            {
                "value": 11229,
                "label": "McCamey",
                "label_ar": "مكامي",
                "label_fr": "McCamey"
            },
            {
                "value": 11230,
                "label": "McCammon",
                "label_ar": "ماكامون",
                "label_fr": "McCammon"
            },
            {
                "value": 11231,
                "label": "McCaysville",
                "label_ar": "ماكيسفيل",
                "label_fr": "McCaysville"
            },
            {
                "value": 11232,
                "label": "McCleary",
                "label_ar": "مكليري",
                "label_fr": "McCleary"
            },
            {
                "value": 11233,
                "label": "McClellan Park Census Designated Place",
                "label_ar": "المكان المخصص لتعداد منتزه ماكليلان",
                "label_fr": "Lieu désigné du recensement du parc McClellan"
            },
            {
                "value": 11234,
                "label": "McClelland",
                "label_ar": "مكليلاند",
                "label_fr": "McClelland"
            },
            {
                "value": 11235,
                "label": "McClellandtown",
                "label_ar": "مكليلاندتاون",
                "label_fr": "McClellandtown"
            },
            {
                "value": 11236,
                "label": "McClellanville",
                "label_ar": "مكليلانفيل",
                "label_fr": "McClellanville"
            },
            {
                "value": 11237,
                "label": "McCloud",
                "label_ar": "مكلاود",
                "label_fr": "McCloud"
            },
            {
                "value": 11238,
                "label": "McClure",
                "label_ar": "مكلور",
                "label_fr": "McClure"
            },
            {
                "value": 11239,
                "label": "McClusky",
                "label_ar": "مكلوسكي",
                "label_fr": "McClusky"
            },
            {
                "value": 11240,
                "label": "McColl",
                "label_ar": "ماكول",
                "label_fr": "McColl"
            },
            {
                "value": 11241,
                "label": "McComb",
                "label_ar": "ماكومب",
                "label_fr": "McComb"
            },
            {
                "value": 11242,
                "label": "McConnells",
                "label_ar": "ماكونيلس",
                "label_fr": "McConnells"
            },
            {
                "value": 11243,
                "label": "McConnellsburg",
                "label_ar": "ماكونيلسبورغ",
                "label_fr": "McConnellsburg"
            },
            {
                "value": 11244,
                "label": "McConnelsville",
                "label_ar": "ماكونيلسفيل",
                "label_fr": "McConnelsville"
            },
            {
                "value": 11245,
                "label": "McCook",
                "label_ar": "ماكوك",
                "label_fr": "McCook"
            },
            {
                "value": 11246,
                "label": "McCool",
                "label_ar": "ماكول",
                "label_fr": "McCool"
            },
            {
                "value": 11247,
                "label": "McCool Junction",
                "label_ar": "مكول جانكشن",
                "label_fr": "McCool Junction"
            },
            {
                "value": 11248,
                "label": "McCordsville",
                "label_ar": "مكوردسفيل",
                "label_fr": "McCordsville"
            },
            {
                "value": 11249,
                "label": "McCormick",
                "label_ar": "ماكورميك",
                "label_fr": "McCormick"
            },
            {
                "value": 11250,
                "label": "McCoy",
                "label_ar": "مكوي",
                "label_fr": "McCoy"
            },
            {
                "value": 11251,
                "label": "McCune",
                "label_ar": "ماكيون",
                "label_fr": "McCune"
            },
            {
                "value": 11252,
                "label": "McCurtain",
                "label_ar": "ماكورتين",
                "label_fr": "McCurtain"
            },
            {
                "value": 11253,
                "label": "McCutchenville",
                "label_ar": "ماكوتشينفيل",
                "label_fr": "McCutchenville"
            },
            {
                "value": 11254,
                "label": "McDaniels",
                "label_ar": "مكدانيلز",
                "label_fr": "McDaniels"
            },
            {
                "value": 11255,
                "label": "McDavid",
                "label_ar": "ماكدافيد",
                "label_fr": "McDavid"
            },
            {
                "value": 11256,
                "label": "McDermitt",
                "label_ar": "مكديرميت",
                "label_fr": "McDermitt"
            },
            {
                "value": 11257,
                "label": "McDermott",
                "label_ar": "مكديرموت",
                "label_fr": "McDermott"
            },
            {
                "value": 11258,
                "label": "McDonald",
                "label_ar": "ماكدونالد",
                "label_fr": "McDonald"
            },
            {
                "value": 11259,
                "label": "McDonough",
                "label_ar": "ماكدونو",
                "label_fr": "McDonough"
            },
            {
                "value": 11260,
                "label": "McDowell",
                "label_ar": "ماكدويل",
                "label_fr": "McDowell"
            },
            {
                "value": 11261,
                "label": "McElhattan",
                "label_ar": "مسيلهاتان",
                "label_fr": "McElhattan"
            },
            {
                "value": 11262,
                "label": "McEwen",
                "label_ar": "مكيوين",
                "label_fr": "McEwen"
            },
            {
                "value": 11263,
                "label": "McFall",
                "label_ar": "ماكفول",
                "label_fr": "McFall"
            },
            {
                "value": 11264,
                "label": "McFarland",
                "label_ar": "مكفارلاند",
                "label_fr": "McFarland"
            },
            {
                "value": 11265,
                "label": "McGaheysville",
                "label_ar": "مكجايسفيل",
                "label_fr": "McGaheysville"
            },
            {
                "value": 11266,
                "label": "McGehee",
                "label_ar": "ماكجي",
                "label_fr": "McGehee"
            },
            {
                "value": 11267,
                "label": "McGraw",
                "label_ar": "ماكجرو",
                "label_fr": "McGraw"
            },
            {
                "value": 11268,
                "label": "McGregor",
                "label_ar": "ماكجريجور",
                "label_fr": "McGregor"
            },
            {
                "value": 11269,
                "label": "McHenry",
                "label_ar": "ماكهنري",
                "label_fr": "McHenry"
            },
            {
                "value": 11270,
                "label": "McIntosh",
                "label_ar": "ماكنتوش",
                "label_fr": "McIntosh"
            },
            {
                "value": 11271,
                "label": "McKean Township",
                "label_ar": "بلدة ماكين",
                "label_fr": "Canton de McKean"
            },
            {
                "value": 11272,
                "label": "McKee",
                "label_ar": "ماكي",
                "label_fr": "McKee"
            },
            {
                "value": 11273,
                "label": "McKees Rocks",
                "label_ar": "مكيس روكس",
                "label_fr": "McKees Rocks"
            },
            {
                "value": 11274,
                "label": "McKeesport",
                "label_ar": "مكيسبورت",
                "label_fr": "McKeesport"
            },
            {
                "value": 11275,
                "label": "McKenna",
                "label_ar": "ماكينا",
                "label_fr": "McKenna"
            },
            {
                "value": 11276,
                "label": "McKenzie",
                "label_ar": "ماكنزي",
                "label_fr": "McKenzie"
            },
            {
                "value": 11277,
                "label": "McKinleyville",
                "label_ar": "ماكينليفيل",
                "label_fr": "McKinleyville"
            },
            {
                "value": 11278,
                "label": "McKinney",
                "label_ar": "ماكيني",
                "label_fr": "McKinney"
            },
            {
                "value": 11279,
                "label": "McLain",
                "label_ar": "ماكلين",
                "label_fr": "McLain"
            },
            {
                "value": 11280,
                "label": "McLaughlin",
                "label_ar": "ماكلولين",
                "label_fr": "McLaughlin"
            },
            {
                "value": 11281,
                "label": "McLean",
                "label_ar": "ماكلين",
                "label_fr": "McLean"
            },
            {
                "value": 11282,
                "label": "McLeansboro",
                "label_ar": "ماكلينسبورو",
                "label_fr": "McLeansboro"
            },
            {
                "value": 11283,
                "label": "McLeansville",
                "label_ar": "ماكلينزفيل",
                "label_fr": "McLeansville"
            },
            {
                "value": 11284,
                "label": "McLoud",
                "label_ar": "ماكلود",
                "label_fr": "McLoud"
            },
            {
                "value": 11285,
                "label": "McMechen",
                "label_ar": "مكميشين",
                "label_fr": "McMechen"
            },
            {
                "value": 11286,
                "label": "McMillan",
                "label_ar": "ماكميلان",
                "label_fr": "McMillan"
            },
            {
                "value": 11287,
                "label": "McMinnville",
                "label_ar": "مكمينفيل",
                "label_fr": "McMinnville"
            },
            {
                "value": 11288,
                "label": "McNabb",
                "label_ar": "مكناب",
                "label_fr": "McNabb"
            },
            {
                "value": 11289,
                "label": "McNary",
                "label_ar": "ماكناري",
                "label_fr": "McNary"
            },
            {
                "value": 11290,
                "label": "McNeal",
                "label_ar": "مكنيل",
                "label_fr": "McNeal"
            },
            {
                "value": 11291,
                "label": "McPherson",
                "label_ar": "ماكفرسون",
                "label_fr": "McPherson"
            },
            {
                "value": 11292,
                "label": "McQueeney",
                "label_ar": "ماكوين",
                "label_fr": "McQueeney"
            },
            {
                "value": 11293,
                "label": "McRae",
                "label_ar": "ماكراي",
                "label_fr": "McRae"
            },
            {
                "value": 11294,
                "label": "McSherrystown",
                "label_ar": "ماكشيرستون",
                "label_fr": "McSherrystown"
            },
            {
                "value": 11295,
                "label": "McVeytown",
                "label_ar": "مكفايتاون",
                "label_fr": "McVeytown"
            },
            {
                "value": 11296,
                "label": "McVille",
                "label_ar": "ماكفيل",
                "label_fr": "McVille"
            },
            {
                "value": 11297,
                "label": "Meacham",
                "label_ar": "ميتشام",
                "label_fr": "Meacham"
            },
            {
                "value": 11298,
                "label": "Mead",
                "label_ar": "ميد",
                "label_fr": "Hydromel"
            },
            {
                "value": 11299,
                "label": "Meade",
                "label_ar": "ميد",
                "label_fr": "Meade"
            },
            {
                "value": 11300,
                "label": "Meadow",
                "label_ar": "مرج",
                "label_fr": "Prairie"
            },
            {
                "value": 11301,
                "label": "Meadow Bridge",
                "label_ar": "جسر المرج",
                "label_fr": "Pont de prairie"
            },
            {
                "value": 11302,
                "label": "Meadow Grove",
                "label_ar": "ميدو جروف",
                "label_fr": "Meadow Grove"
            },
            {
                "value": 11303,
                "label": "Meadow Lands",
                "label_ar": "ميدو لاندز",
                "label_fr": "Terres de prairie"
            },
            {
                "value": 11304,
                "label": "Meadow Valley",
                "label_ar": "وادي المروج",
                "label_fr": "Meadow Valley"
            },
            {
                "value": 11305,
                "label": "Meadow Vista",
                "label_ar": "ميدو فيستا",
                "label_fr": "Meadow Vista"
            },
            {
                "value": 11306,
                "label": "Meadowlands",
                "label_ar": "ميدولاندز",
                "label_fr": "Prairie"
            },
            {
                "value": 11307,
                "label": "Meadows of Dan",
                "label_ar": "مروج دان",
                "label_fr": "Prairies de Dan"
            },
            {
                "value": 11308,
                "label": "Meadowview",
                "label_ar": "ميدوفيو",
                "label_fr": "Meadowview"
            },
            {
                "value": 11309,
                "label": "Meadview",
                "label_ar": "ميدفيو",
                "label_fr": "Meadview"
            },
            {
                "value": 11310,
                "label": "Meadville",
                "label_ar": "ميدفيل",
                "label_fr": "Meadville"
            },
            {
                "value": 11311,
                "label": "Meally",
                "label_ar": "وجبة",
                "label_fr": "Repas"
            },
            {
                "value": 11312,
                "label": "Meansville",
                "label_ar": "مينسفيل",
                "label_fr": "Meansville"
            },
            {
                "value": 11313,
                "label": "Mears",
                "label_ar": "ميرز",
                "label_fr": "Mears"
            },
            {
                "value": 11314,
                "label": "Mebane",
                "label_ar": "ميباني",
                "label_fr": "Mebane"
            },
            {
                "value": 11315,
                "label": "Mecca",
                "label_ar": "مكة المكرمة",
                "label_fr": "Mecque"
            },
            {
                "value": 11316,
                "label": "Mechanic Falls",
                "label_ar": "شلالات ميكانيكي",
                "label_fr": "Mécanicien tombe"
            },
            {
                "value": 11317,
                "label": "Mechanicsburg",
                "label_ar": "ميكانيكسبيرغ",
                "label_fr": "Mechanicsburg"
            },
            {
                "value": 11318,
                "label": "Mechanicsville",
                "label_ar": "ميكانيكسفيل",
                "label_fr": "Mechanicsville"
            },
            {
                "value": 11319,
                "label": "Mechanicville",
                "label_ar": "ميكانيكفيل",
                "label_fr": "Mechanicville"
            },
            {
                "value": 11320,
                "label": "Mecosta",
                "label_ar": "ميكوستا",
                "label_fr": "Mecosta"
            },
            {
                "value": 11321,
                "label": "Medanales",
                "label_ar": "ميداناليس",
                "label_fr": "Medanales"
            },
            {
                "value": 11322,
                "label": "Medaryville",
                "label_ar": "ميدريفيل",
                "label_fr": "Medaryville"
            },
            {
                "value": 11323,
                "label": "Medfield",
                "label_ar": "ميدفيلد",
                "label_fr": "Medfield"
            },
            {
                "value": 11324,
                "label": "Medford",
                "label_ar": "ميدفورد",
                "label_fr": "Medford"
            },
            {
                "value": 11325,
                "label": "Media",
                "label_ar": "وسائل الإعلام",
                "label_fr": "Médias"
            },
            {
                "value": 11326,
                "label": "Mediapolis",
                "label_ar": "ميديابوليس",
                "label_fr": "Mediapolis"
            },
            {
                "value": 11327,
                "label": "Medical Lake",
                "label_ar": "بحيرة طبية",
                "label_fr": "Medical Lake"
            },
            {
                "value": 11328,
                "label": "Medicine Lake",
                "label_ar": "بحيرة الطب",
                "label_fr": "Medicine Lake"
            },
            {
                "value": 11329,
                "label": "Medicine Lodge",
                "label_ar": "نزل الطب",
                "label_fr": "Medicine Lodge"
            },
            {
                "value": 11330,
                "label": "Medicine Park",
                "label_ar": "حديقة الطب",
                "label_fr": "Medicine Park"
            },
            {
                "value": 11331,
                "label": "Medina",
                "label_ar": "المدينة المنورة",
                "label_fr": "Médina"
            },
            {
                "value": 11332,
                "label": "Medinah",
                "label_ar": "المدينة المنورة",
                "label_fr": "Medinah"
            },
            {
                "value": 11333,
                "label": "Medon",
                "label_ar": "ميدون",
                "label_fr": "Médon"
            },
            {
                "value": 11334,
                "label": "Medora",
                "label_ar": "ميدورا",
                "label_fr": "Medora"
            },
            {
                "value": 11335,
                "label": "Medway",
                "label_ar": "ميدواي",
                "label_fr": "Medway"
            },
            {
                "value": 11336,
                "label": "Meeker",
                "label_ar": "ميكر",
                "label_fr": "Plus doux"
            },
            {
                "value": 11337,
                "label": "Meeteetse",
                "label_ar": "ميتيتسي",
                "label_fr": "Meeteetse"
            },
            {
                "value": 11338,
                "label": "Meherrin",
                "label_ar": "مهرين",
                "label_fr": "Meherrin"
            },
            {
                "value": 11339,
                "label": "Mehoopany",
                "label_ar": "ميهوباني",
                "label_fr": "Méhoopany"
            },
            {
                "value": 11340,
                "label": "Mekoryuk",
                "label_ar": "ميكوريوك",
                "label_fr": "Mekoryuk"
            },
            {
                "value": 11341,
                "label": "Melba",
                "label_ar": "ميلبا",
                "label_fr": "Melba"
            },
            {
                "value": 11342,
                "label": "Melbourne",
                "label_ar": "ملبورن",
                "label_fr": "Melbourne"
            },
            {
                "value": 11343,
                "label": "Melbourne Beach",
                "label_ar": "شاطئ ملبورن",
                "label_fr": "Plage de Melbourne"
            },
            {
                "value": 11344,
                "label": "Melcher-Dallas",
                "label_ar": "ميلشر دالاس",
                "label_fr": "Melcher-Dallas"
            },
            {
                "value": 11345,
                "label": "Melcroft",
                "label_ar": "ميلكروفت",
                "label_fr": "Melcroft"
            },
            {
                "value": 11346,
                "label": "Melfa",
                "label_ar": "ملفا",
                "label_fr": "Melfa"
            },
            {
                "value": 11347,
                "label": "Melissa",
                "label_ar": "ميليسا",
                "label_fr": "Mélisse"
            },
            {
                "value": 11348,
                "label": "Mellen",
                "label_ar": "ميلين",
                "label_fr": "Mellen"
            },
            {
                "value": 11349,
                "label": "Mellenville",
                "label_ar": "ميلينفيل",
                "label_fr": "Mellenville"
            },
            {
                "value": 11350,
                "label": "Melrose",
                "label_ar": "ميلروز",
                "label_fr": "Melrose"
            },
            {
                "value": 11351,
                "label": "Melrose Park",
                "label_ar": "ميلروز بارك",
                "label_fr": "Parc Melrose"
            },
            {
                "value": 11352,
                "label": "Melstone",
                "label_ar": "ميلستون",
                "label_fr": "Melstone"
            },
            {
                "value": 11353,
                "label": "Melville",
                "label_ar": "ملفيل",
                "label_fr": "Melville"
            },
            {
                "value": 11354,
                "label": "Melvin",
                "label_ar": "ملفين",
                "label_fr": "Melvin"
            },
            {
                "value": 11355,
                "label": "Melvindale",
                "label_ar": "ملفينديل",
                "label_fr": "Melvindale"
            },
            {
                "value": 11356,
                "label": "Memphis",
                "label_ar": "ممفيس",
                "label_fr": "Memphis"
            },
            {
                "value": 11357,
                "label": "Mena",
                "label_ar": "منة",
                "label_fr": "Mena"
            },
            {
                "value": 11358,
                "label": "Menahga",
                "label_ar": "ميناهغا",
                "label_fr": "Menahga"
            },
            {
                "value": 11359,
                "label": "Menan",
                "label_ar": "مينان",
                "label_fr": "Menan"
            },
            {
                "value": 11360,
                "label": "Menard",
                "label_ar": "مينارد",
                "label_fr": "Ménard"
            },
            {
                "value": 11361,
                "label": "Menasha",
                "label_ar": "مناشا",
                "label_fr": "Menasha"
            },
            {
                "value": 11362,
                "label": "Mendenhall",
                "label_ar": "ميندنهال",
                "label_fr": "Mendenhall"
            },
            {
                "value": 11363,
                "label": "Mendham",
                "label_ar": "مندهام",
                "label_fr": "Mendham"
            },
            {
                "value": 11364,
                "label": "Mendocino",
                "label_ar": "ميندوسينو",
                "label_fr": "Mendocino"
            },
            {
                "value": 11365,
                "label": "Mendon",
                "label_ar": "ميندون",
                "label_fr": "Mendon"
            },
            {
                "value": 11366,
                "label": "Mendota",
                "label_ar": "مندوتا",
                "label_fr": "Mendota"
            },
            {
                "value": 11367,
                "label": "Menifee",
                "label_ar": "مينيفي",
                "label_fr": "Menifee"
            },
            {
                "value": 11368,
                "label": "Menlo",
                "label_ar": "مينلو",
                "label_fr": "Menlo"
            },
            {
                "value": 11369,
                "label": "Menlo Park",
                "label_ar": "متنزه مينلو",
                "label_fr": "Le parc Menlo"
            },
            {
                "value": 11370,
                "label": "Menno",
                "label_ar": "مينو",
                "label_fr": "Menno"
            },
            {
                "value": 11371,
                "label": "Meno",
                "label_ar": "انا لا",
                "label_fr": "Moi non"
            },
            {
                "value": 11372,
                "label": "Menominee",
                "label_ar": "مينوميني",
                "label_fr": "Menominee"
            },
            {
                "value": 11373,
                "label": "Menomonee Falls",
                "label_ar": "شلالات مينوموني",
                "label_fr": "Menomonee Falls"
            },
            {
                "value": 11374,
                "label": "Mentone",
                "label_ar": "مينتون",
                "label_fr": "Mentone"
            },
            {
                "value": 11375,
                "label": "Mentor",
                "label_ar": "مرشد",
                "label_fr": "Mentor"
            },
            {
                "value": 11376,
                "label": "Mequon",
                "label_ar": "ميكون",
                "label_fr": "Mequon"
            },
            {
                "value": 11377,
                "label": "Mer Rouge",
                "label_ar": "مير روج",
                "label_fr": "Mer Rouge"
            },
            {
                "value": 11378,
                "label": "Meraux",
                "label_ar": "ميرو",
                "label_fr": "Meraux"
            },
            {
                "value": 11379,
                "label": "Merced",
                "label_ar": "ميرسيد",
                "label_fr": "Merced"
            },
            {
                "value": 11380,
                "label": "Mercedes",
                "label_ar": "مرسيدس",
                "label_fr": "Mercedes"
            },
            {
                "value": 11381,
                "label": "Mercer",
                "label_ar": "ميرسر",
                "label_fr": "Mercer"
            },
            {
                "value": 11382,
                "label": "Mercer Island",
                "label_ar": "جزيرة ميرسر",
                "label_fr": "Île Mercer"
            },
            {
                "value": 11383,
                "label": "Mercersburg",
                "label_ar": "ميرسبرج",
                "label_fr": "Mercersburg"
            },
            {
                "value": 11384,
                "label": "Merchantville",
                "label_ar": "ميرشانتفيل",
                "label_fr": "Merchantville"
            },
            {
                "value": 11385,
                "label": "Meredith",
                "label_ar": "ميريديث",
                "label_fr": "Meredith"
            },
            {
                "value": 11386,
                "label": "Mereta",
                "label_ar": "ميريتا",
                "label_fr": "Mereta"
            },
            {
                "value": 11387,
                "label": "Meriden",
                "label_ar": "ميريديان",
                "label_fr": "Meriden"
            },
            {
                "value": 11388,
                "label": "Meridian",
                "label_ar": "خط الطول",
                "label_fr": "Méridien"
            },
            {
                "value": 11389,
                "label": "Meridianville",
                "label_ar": "ميريديانفيل",
                "label_fr": "Meridianville"
            },
            {
                "value": 11390,
                "label": "Merino",
                "label_ar": "ميرينو",
                "label_fr": "Mérinos"
            },
            {
                "value": 11391,
                "label": "Merion",
                "label_ar": "ميريون",
                "label_fr": "Merion"
            },
            {
                "value": 11392,
                "label": "Merit",
                "label_ar": "ميزة",
                "label_fr": "Mérite"
            },
            {
                "value": 11393,
                "label": "Merkel",
                "label_ar": "ميركل",
                "label_fr": "Merkel"
            },
            {
                "value": 11394,
                "label": "Merlin",
                "label_ar": "ميرلين",
                "label_fr": "Merlin"
            },
            {
                "value": 11395,
                "label": "Mermentau",
                "label_ar": "Mermentau",
                "label_fr": "Mermentau"
            },
            {
                "value": 11396,
                "label": "Merna",
                "label_ar": "ميرنا",
                "label_fr": "Merna"
            },
            {
                "value": 11397,
                "label": "Merrick",
                "label_ar": "ميريك",
                "label_fr": "Merrick"
            },
            {
                "value": 11398,
                "label": "Merrifield",
                "label_ar": "ميريفيلد",
                "label_fr": "Merrifield"
            },
            {
                "value": 11399,
                "label": "Merrill",
                "label_ar": "ميريل",
                "label_fr": "Merrill"
            },
            {
                "value": 11400,
                "label": "Merrillan",
                "label_ar": "ميريلان",
                "label_fr": "Merrillan"
            },
            {
                "value": 11401,
                "label": "Merrillville",
                "label_ar": "ميريلفيل",
                "label_fr": "Merrillville"
            },
            {
                "value": 11402,
                "label": "Merrimac",
                "label_ar": "ميريماك",
                "label_fr": "Merrimac"
            },
            {
                "value": 11403,
                "label": "Merrimack",
                "label_ar": "ميريماك",
                "label_fr": "Merrimack"
            },
            {
                "value": 11404,
                "label": "Merriman",
                "label_ar": "ميريمان",
                "label_fr": "Merriman"
            },
            {
                "value": 11405,
                "label": "Merritt",
                "label_ar": "ميريت",
                "label_fr": "Merritt"
            },
            {
                "value": 11406,
                "label": "Merritt Island",
                "label_ar": "جزيرة ميريت",
                "label_fr": "Île Merritt"
            },
            {
                "value": 11407,
                "label": "Merry Hill",
                "label_ar": "ميري هيل",
                "label_fr": "Joyeux Hill"
            },
            {
                "value": 11408,
                "label": "Merry Point",
                "label_ar": "ميري بوينت",
                "label_fr": "Joyeux point"
            },
            {
                "value": 11409,
                "label": "Merryville",
                "label_ar": "ميريفيل",
                "label_fr": "Merryville"
            },
            {
                "value": 11410,
                "label": "Merton",
                "label_ar": "ميرتون",
                "label_fr": "Merton"
            },
            {
                "value": 11411,
                "label": "Mertzon",
                "label_ar": "ميرتزون",
                "label_fr": "Mertzon"
            },
            {
                "value": 11412,
                "label": "Mertztown",
                "label_ar": "ميرتزتاون",
                "label_fr": "Mertztown"
            },
            {
                "value": 11413,
                "label": "Mesa",
                "label_ar": "ميسا",
                "label_fr": "Mesa"
            },
            {
                "value": 11414,
                "label": "Mescalero",
                "label_ar": "ميسكاليرو",
                "label_fr": "Mescalero"
            },
            {
                "value": 11415,
                "label": "Meshoppen",
                "label_ar": "مشوبين",
                "label_fr": "Meshoppen"
            },
            {
                "value": 11416,
                "label": "Mesick",
                "label_ar": "انا مريض",
                "label_fr": "Moi malade"
            },
            {
                "value": 11417,
                "label": "Mesilla",
                "label_ar": "ميسيلا",
                "label_fr": "Mesilla"
            },
            {
                "value": 11418,
                "label": "Mesilla Park",
                "label_ar": "حديقة ميسيلا",
                "label_fr": "Parc Mesilla"
            },
            {
                "value": 11419,
                "label": "Mesquite",
                "label_ar": "المسكيت",
                "label_fr": "Mesquite"
            },
            {
                "value": 11420,
                "label": "Metairie",
                "label_ar": "ميتايري",
                "label_fr": "Métairie"
            },
            {
                "value": 11421,
                "label": "Metaline Falls",
                "label_ar": "شلالات ميتالين",
                "label_fr": "Metaline Falls"
            },
            {
                "value": 11422,
                "label": "Metamora",
                "label_ar": "ميتامورا",
                "label_fr": "Metamora"
            },
            {
                "value": 11423,
                "label": "Metcalf",
                "label_ar": "ميتكالف",
                "label_fr": "Metcalf"
            },
            {
                "value": 11424,
                "label": "Methow",
                "label_ar": "ميثو",
                "label_fr": "Methow"
            },
            {
                "value": 11425,
                "label": "Methuen",
                "label_ar": "ميثوين",
                "label_fr": "Methuen"
            },
            {
                "value": 11426,
                "label": "Metlakatla",
                "label_ar": "ميتلاكاتلا",
                "label_fr": "Metlakatla"
            },
            {
                "value": 11427,
                "label": "Metropolis",
                "label_ar": "متروبوليس",
                "label_fr": "Métropole"
            },
            {
                "value": 11428,
                "label": "Metropolitan",
                "label_ar": "محافظه",
                "label_fr": "Métropolitain"
            },
            {
                "value": 11429,
                "label": "Metter",
                "label_ar": "متر",
                "label_fr": "Metter"
            },
            {
                "value": 11430,
                "label": "Metuchen",
                "label_ar": "ميتوتشين",
                "label_fr": "Metuchen"
            },
            {
                "value": 11431,
                "label": "Metz",
                "label_ar": "ميتز",
                "label_fr": "Metz"
            },
            {
                "value": 11432,
                "label": "Mexia",
                "label_ar": "مكسيا",
                "label_fr": "Mexia"
            },
            {
                "value": 11433,
                "label": "Mexico",
                "label_ar": "المكسيك",
                "label_fr": "Mexique"
            },
            {
                "value": 11434,
                "label": "Meyersdale",
                "label_ar": "مايرسدال",
                "label_fr": "Meyersdale"
            },
            {
                "value": 11435,
                "label": "Mi-Wuk Village",
                "label_ar": "قرية مي ووك",
                "label_fr": "Village de Mi-Wuk"
            },
            {
                "value": 11436,
                "label": "Miami",
                "label_ar": "ميامي",
                "label_fr": "Miami"
            },
            {
                "value": 11437,
                "label": "Miami Beach",
                "label_ar": "شاطئ ميامي",
                "label_fr": "Plage de Miami"
            },
            {
                "value": 11438,
                "label": "Miamisburg",
                "label_ar": "مياميسبورغ",
                "label_fr": "Miamisburg"
            },
            {
                "value": 11439,
                "label": "Miamitown",
                "label_ar": "ميامي",
                "label_fr": "Miamitown"
            },
            {
                "value": 11440,
                "label": "Miamiville",
                "label_ar": "مياميفيل",
                "label_fr": "Miamiville"
            },
            {
                "value": 11441,
                "label": "Micanopy",
                "label_ar": "ميكانوبي",
                "label_fr": "Micanopy"
            },
            {
                "value": 11442,
                "label": "Michie",
                "label_ar": "ميتشي",
                "label_fr": "Michie"
            },
            {
                "value": 11443,
                "label": "Michigan Center",
                "label_ar": "مركز ميشيغان",
                "label_fr": "Centre du Michigan"
            },
            {
                "value": 11444,
                "label": "Michigan City",
                "label_ar": "مدينة ميشيغان",
                "label_fr": "Michigan City"
            },
            {
                "value": 11445,
                "label": "Michigantown",
                "label_ar": "ميتشيجانتاون",
                "label_fr": "Michigantown"
            },
            {
                "value": 11446,
                "label": "Mickleton",
                "label_ar": "ميكلتون",
                "label_fr": "Mickleton"
            },
            {
                "value": 11447,
                "label": "Mico",
                "label_ar": "ميكو",
                "label_fr": "Mico"
            },
            {
                "value": 11448,
                "label": "Micro",
                "label_ar": "مجهري",
                "label_fr": "Micro"
            },
            {
                "value": 11449,
                "label": "Middle Bass",
                "label_ar": "وسط باس",
                "label_fr": "Basse moyenne"
            },
            {
                "value": 11450,
                "label": "Middle Granville",
                "label_ar": "وسط جرانفيل",
                "label_fr": "Moyen Granville"
            },
            {
                "value": 11451,
                "label": "Middle Grove",
                "label_ar": "ميدل جروف",
                "label_fr": "Middle Grove"
            },
            {
                "value": 11452,
                "label": "Middle Haddam",
                "label_ar": "وسط الهدام",
                "label_fr": "Milieu Haddam"
            },
            {
                "value": 11453,
                "label": "Middle Island",
                "label_ar": "الجزيرة الوسطى",
                "label_fr": "Ile du milieu"
            },
            {
                "value": 11454,
                "label": "Middle Point",
                "label_ar": "نقطة المنتصف",
                "label_fr": "Point médian"
            },
            {
                "value": 11455,
                "label": "Middle River",
                "label_ar": "نهر الأوسط",
                "label_fr": "Middle River"
            },
            {
                "value": 11456,
                "label": "Middle Village",
                "label_ar": "القرية الوسطى",
                "label_fr": "Village moyen"
            },
            {
                "value": 11457,
                "label": "Middleboro",
                "label_ar": "ميدلبورو",
                "label_fr": "Middleboro"
            },
            {
                "value": 11458,
                "label": "Middlebourne",
                "label_ar": "ميدلبورن",
                "label_fr": "Middlebourne"
            },
            {
                "value": 11459,
                "label": "Middleburg",
                "label_ar": "ميدلبورغ",
                "label_fr": "Middleburg"
            },
            {
                "value": 11460,
                "label": "Middleburgh",
                "label_ar": "ميدلبورج",
                "label_fr": "Middleburgh"
            },
            {
                "value": 11461,
                "label": "Middlebury",
                "label_ar": "ميدلبري",
                "label_fr": "Middlebury"
            },
            {
                "value": 11462,
                "label": "Middlefield",
                "label_ar": "ميدلفيلد",
                "label_fr": "Middlefield"
            },
            {
                "value": 11463,
                "label": "Middleport",
                "label_ar": "ميدلبورت",
                "label_fr": "Middleport"
            },
            {
                "value": 11464,
                "label": "Middlesboro",
                "label_ar": "ميدلسبورو",
                "label_fr": "Middlesboro"
            },
            {
                "value": 11465,
                "label": "Middlesex",
                "label_ar": "ميدلسكس",
                "label_fr": "Middlesex"
            },
            {
                "value": 11466,
                "label": "Middleton",
                "label_ar": "ميدلتون",
                "label_fr": "Middleton"
            },
            {
                "value": 11467,
                "label": "Middletown",
                "label_ar": "ميدلتاون",
                "label_fr": "Middletown"
            },
            {
                "value": 11468,
                "label": "Middletown Springs",
                "label_ar": "ميدلتاون سبرينجز",
                "label_fr": "Middletown Springs"
            },
            {
                "value": 11469,
                "label": "Middleville",
                "label_ar": "ميدلفيل",
                "label_fr": "Middleville"
            },
            {
                "value": 11470,
                "label": "Midfield",
                "label_ar": "خط الوسط",
                "label_fr": "Milieu de terrain"
            },
            {
                "value": 11471,
                "label": "Midkiff",
                "label_ar": "مدكيف",
                "label_fr": "Midkiff"
            },
            {
                "value": 11472,
                "label": "Midland",
                "label_ar": "ميدلاند",
                "label_fr": "Midland"
            },
            {
                "value": 11473,
                "label": "Midland City",
                "label_ar": "ميدلاند سيتي",
                "label_fr": "Midland City"
            },
            {
                "value": 11474,
                "label": "Midland Park",
                "label_ar": "ميدلاند بارك",
                "label_fr": "Parc Midland"
            },
            {
                "value": 11475,
                "label": "Midlothian",
                "label_ar": "ميدلوثيان",
                "label_fr": "Midlothian"
            },
            {
                "value": 11476,
                "label": "Midvale",
                "label_ar": "ميدفال",
                "label_fr": "Midvale"
            },
            {
                "value": 11477,
                "label": "Midville",
                "label_ar": "ميدفيل",
                "label_fr": "Midville"
            },
            {
                "value": 11478,
                "label": "Midway",
                "label_ar": "منتصف الطريق",
                "label_fr": "Midway"
            },
            {
                "value": 11479,
                "label": "Midway City",
                "label_ar": "ميدواي سيتي",
                "label_fr": "Midway City"
            },
            {
                "value": 11480,
                "label": "Midway Park",
                "label_ar": "ميدواي بارك",
                "label_fr": "Parc Midway"
            },
            {
                "value": 11481,
                "label": "Midwest",
                "label_ar": "الغرب الأوسط",
                "label_fr": "Midwest"
            },
            {
                "value": 11482,
                "label": "Mifflin",
                "label_ar": "ميفلين",
                "label_fr": "Mifflin"
            },
            {
                "value": 11483,
                "label": "Mifflinburg",
                "label_ar": "ميفلينبرج",
                "label_fr": "Mifflinburg"
            },
            {
                "value": 11484,
                "label": "Mifflintown",
                "label_ar": "ميفلينتاون",
                "label_fr": "Mifflintown"
            },
            {
                "value": 11485,
                "label": "Mifflinville",
                "label_ar": "ميفلينفيل",
                "label_fr": "Mifflinville"
            },
            {
                "value": 11486,
                "label": "Mikado",
                "label_ar": "ميكادو",
                "label_fr": "Mikado"
            },
            {
                "value": 11487,
                "label": "Milaca",
                "label_ar": "ميلاكا",
                "label_fr": "Milaca"
            },
            {
                "value": 11488,
                "label": "Milam",
                "label_ar": "ميلام",
                "label_fr": "Milam"
            },
            {
                "value": 11489,
                "label": "Milan",
                "label_ar": "ميلان",
                "label_fr": "Milan"
            },
            {
                "value": 11490,
                "label": "Milano",
                "label_ar": "ميلانو",
                "label_fr": "Milan"
            },
            {
                "value": 11491,
                "label": "Milbank",
                "label_ar": "ميلبانك",
                "label_fr": "Milbank"
            },
            {
                "value": 11492,
                "label": "Milburn",
                "label_ar": "ميلبورن",
                "label_fr": "Milburn"
            },
            {
                "value": 11493,
                "label": "Miles",
                "label_ar": "اميال",
                "label_fr": "Miles"
            },
            {
                "value": 11494,
                "label": "Miles City",
                "label_ar": "مايلز سيتي",
                "label_fr": "Miles City"
            },
            {
                "value": 11495,
                "label": "Milesburg",
                "label_ar": "ميليسبورغ",
                "label_fr": "Milesburg"
            },
            {
                "value": 11496,
                "label": "Milford",
                "label_ar": "ميلفورد",
                "label_fr": "Milford"
            },
            {
                "value": 11497,
                "label": "Milford Center",
                "label_ar": "مركز ميلفورد",
                "label_fr": "Milford Centre"
            },
            {
                "value": 11498,
                "label": "Mililani Town",
                "label_ar": "بلدة ميليلاني",
                "label_fr": "Ville de Mililani"
            },
            {
                "value": 11499,
                "label": "Mill City",
                "label_ar": "مطحنة سيتي",
                "label_fr": "Mill City"
            },
            {
                "value": 11500,
                "label": "Mill Creek",
                "label_ar": "ميل كريك",
                "label_fr": "Mill Creek"
            },
            {
                "value": 11501,
                "label": "Mill Hall",
                "label_ar": "مطحنة هول",
                "label_fr": "Hall du moulin"
            },
            {
                "value": 11502,
                "label": "Mill Neck",
                "label_ar": "مطحنة العنق",
                "label_fr": "Mill Neck"
            },
            {
                "value": 11503,
                "label": "Mill River",
                "label_ar": "نهر ميل",
                "label_fr": "Rivière Mill"
            },
            {
                "value": 11504,
                "label": "Mill Shoals",
                "label_ar": "مطحنة شولز",
                "label_fr": "Mill Shoals"
            },
            {
                "value": 11505,
                "label": "Mill Spring",
                "label_ar": "مطحنة الربيع",
                "label_fr": "Moulin Spring"
            },
            {
                "value": 11506,
                "label": "Mill Valley",
                "label_ar": "مطحنة فالي",
                "label_fr": "Mill Valley"
            },
            {
                "value": 11507,
                "label": "Milladore",
                "label_ar": "ميلادور",
                "label_fr": "Milladore"
            },
            {
                "value": 11508,
                "label": "Millboro",
                "label_ar": "ميلبورو",
                "label_fr": "Millboro"
            },
            {
                "value": 11509,
                "label": "Millbrae",
                "label_ar": "ميلبرا",
                "label_fr": "Millbrae"
            },
            {
                "value": 11510,
                "label": "Millbrook",
                "label_ar": "ميلبروك",
                "label_fr": "Millbrook"
            },
            {
                "value": 11511,
                "label": "Millburn",
                "label_ar": "ميلبورن",
                "label_fr": "Millburn"
            },
            {
                "value": 11512,
                "label": "Millbury",
                "label_ar": "ميلبري",
                "label_fr": "Millbury"
            },
            {
                "value": 11513,
                "label": "Milldale",
                "label_ar": "ميلديل",
                "label_fr": "Milldale"
            },
            {
                "value": 11514,
                "label": "Milledgeville",
                "label_ar": "ميلدجفيل",
                "label_fr": "Milledgeville"
            },
            {
                "value": 11515,
                "label": "Millen",
                "label_ar": "ميلن",
                "label_fr": "Millen"
            },
            {
                "value": 11516,
                "label": "Miller",
                "label_ar": "ميلر",
                "label_fr": "Meunier"
            },
            {
                "value": 11517,
                "label": "Miller Place",
                "label_ar": "ميلر بليس",
                "label_fr": "Miller Place"
            },
            {
                "value": 11518,
                "label": "Millers Creek",
                "label_ar": "ميلرز كريك",
                "label_fr": "Millers Creek"
            },
            {
                "value": 11519,
                "label": "Millersburg",
                "label_ar": "ميلرسبورغ",
                "label_fr": "Millersburg"
            },
            {
                "value": 11520,
                "label": "Millersport",
                "label_ar": "ميلر سبورت",
                "label_fr": "Millersport"
            },
            {
                "value": 11521,
                "label": "Millerstown",
                "label_ar": "ميلرستاون",
                "label_fr": "Millerstown"
            },
            {
                "value": 11522,
                "label": "Millersview",
                "label_ar": "ميلرسفيو",
                "label_fr": "Millersview"
            },
            {
                "value": 11523,
                "label": "Millersville",
                "label_ar": "ميلرسفيل",
                "label_fr": "Millersville"
            },
            {
                "value": 11524,
                "label": "Millerton",
                "label_ar": "ميلرتون",
                "label_fr": "Millerton"
            },
            {
                "value": 11525,
                "label": "Millfield",
                "label_ar": "ميلفيلد",
                "label_fr": "Millfield"
            },
            {
                "value": 11526,
                "label": "Millheim",
                "label_ar": "ميلهايم",
                "label_fr": "Millheim"
            },
            {
                "value": 11527,
                "label": "Millican",
                "label_ar": "ميليكان",
                "label_fr": "Millican"
            },
            {
                "value": 11528,
                "label": "Milligan",
                "label_ar": "ميليجان",
                "label_fr": "Milligan"
            },
            {
                "value": 11529,
                "label": "Milliken",
                "label_ar": "ميليكين",
                "label_fr": "Milliken"
            },
            {
                "value": 11530,
                "label": "Millington",
                "label_ar": "ميلينجتون",
                "label_fr": "Millington"
            },
            {
                "value": 11531,
                "label": "Millinocket",
                "label_ar": "ميلينوكيت",
                "label_fr": "Millinocket"
            },
            {
                "value": 11532,
                "label": "Millis",
                "label_ar": "ملي",
                "label_fr": "Millis"
            },
            {
                "value": 11533,
                "label": "Millmont",
                "label_ar": "ميلمونت",
                "label_fr": "Millmont"
            },
            {
                "value": 11534,
                "label": "Millport",
                "label_ar": "ميلبورت",
                "label_fr": "Millport"
            },
            {
                "value": 11535,
                "label": "Millry",
                "label_ar": "ميلري",
                "label_fr": "Millry"
            },
            {
                "value": 11536,
                "label": "Mills",
                "label_ar": "المطاحن",
                "label_fr": "Moulins"
            },
            {
                "value": 11537,
                "label": "Mills River",
                "label_ar": "نهر ميلز",
                "label_fr": "Mills River"
            },
            {
                "value": 11538,
                "label": "Millsap",
                "label_ar": "ميلساب",
                "label_fr": "Millsap"
            },
            {
                "value": 11539,
                "label": "Millsboro",
                "label_ar": "ميلسبورو",
                "label_fr": "Millsboro"
            },
            {
                "value": 11540,
                "label": "Millstadt",
                "label_ar": "ميلستادت",
                "label_fr": "Millstadt"
            },
            {
                "value": 11541,
                "label": "Millstone",
                "label_ar": "حجر الرحى",
                "label_fr": "Meule"
            },
            {
                "value": 11542,
                "label": "Milltown",
                "label_ar": "ميلتاون",
                "label_fr": "Ville de moulin"
            },
            {
                "value": 11543,
                "label": "Millville",
                "label_ar": "ميلفيل",
                "label_fr": "Millville"
            },
            {
                "value": 11544,
                "label": "Millwood",
                "label_ar": "ميلوود",
                "label_fr": "Millwood"
            },
            {
                "value": 11545,
                "label": "Milmay",
                "label_ar": "ميلماي",
                "label_fr": "Milmay"
            },
            {
                "value": 11546,
                "label": "Milner",
                "label_ar": "ميلنر",
                "label_fr": "Milner"
            },
            {
                "value": 11547,
                "label": "Milnesville",
                "label_ar": "ميلنسفيل",
                "label_fr": "Milnesville"
            },
            {
                "value": 11548,
                "label": "Milnor",
                "label_ar": "ميلنور",
                "label_fr": "Milnor"
            },
            {
                "value": 11549,
                "label": "Milo",
                "label_ar": "ميلو",
                "label_fr": "Milo"
            },
            {
                "value": 11550,
                "label": "Milpitas",
                "label_ar": "ميلبيتاس",
                "label_fr": "Milpitas"
            },
            {
                "value": 11551,
                "label": "Milroy",
                "label_ar": "ميلروي",
                "label_fr": "Milroy"
            },
            {
                "value": 11552,
                "label": "Milton",
                "label_ar": "ميلتون",
                "label_fr": "Milton"
            },
            {
                "value": 11553,
                "label": "Milton Mills",
                "label_ar": "ميلتون ميلز",
                "label_fr": "Milton Mills"
            },
            {
                "value": 11554,
                "label": "Milton-Freewater",
                "label_ar": "ميلتون فريوتر",
                "label_fr": "Milton-Freewater"
            },
            {
                "value": 11555,
                "label": "Miltona",
                "label_ar": "ميلتون",
                "label_fr": "Miltona"
            },
            {
                "value": 11556,
                "label": "Miltonvale",
                "label_ar": "ميلتونفال",
                "label_fr": "Miltonvale"
            },
            {
                "value": 11557,
                "label": "Milwaukee",
                "label_ar": "ميلووكي",
                "label_fr": "Milwaukee"
            },
            {
                "value": 11558,
                "label": "Milwaukie",
                "label_ar": "ميلووكي",
                "label_fr": "Milwaukie"
            },
            {
                "value": 11559,
                "label": "Mimbres",
                "label_ar": "ميمبريس",
                "label_fr": "Mimbres"
            },
            {
                "value": 11560,
                "label": "Mims",
                "label_ar": "ميمس",
                "label_fr": "Mims"
            },
            {
                "value": 11561,
                "label": "Minburn",
                "label_ar": "مينبورن",
                "label_fr": "Minburn"
            },
            {
                "value": 11562,
                "label": "Minco",
                "label_ar": "مينكو",
                "label_fr": "Minco"
            },
            {
                "value": 11563,
                "label": "Minden",
                "label_ar": "ميندن",
                "label_fr": "Minden"
            },
            {
                "value": 11564,
                "label": "Minden City",
                "label_ar": "مدينة ميندين",
                "label_fr": "Minden City"
            },
            {
                "value": 11565,
                "label": "Mine Hill",
                "label_ar": "تل منجم",
                "label_fr": "Mine Hill"
            },
            {
                "value": 11566,
                "label": "Mineola",
                "label_ar": "مينولا",
                "label_fr": "Mineola"
            },
            {
                "value": 11567,
                "label": "Mineral",
                "label_ar": "المعدنية",
                "label_fr": "Minéral"
            },
            {
                "value": 11568,
                "label": "Mineral Bluff",
                "label_ar": "بلاف المعدنية",
                "label_fr": "Bluff minéral"
            },
            {
                "value": 11569,
                "label": "Mineral City",
                "label_ar": "مدينة معدنية",
                "label_fr": "Ville minérale"
            },
            {
                "value": 11570,
                "label": "Mineral Point",
                "label_ar": "نقطة معدنية",
                "label_fr": "Point minéral"
            },
            {
                "value": 11571,
                "label": "Mineral Ridge",
                "label_ar": "ريدج المعدنية",
                "label_fr": "Crête minérale"
            },
            {
                "value": 11572,
                "label": "Mineral Springs",
                "label_ar": "ينابيع المياه المعدنية",
                "label_fr": "Sources minérales"
            },
            {
                "value": 11573,
                "label": "Mineral Wells",
                "label_ar": "الآبار المعدنية",
                "label_fr": "Puits minéraux"
            },
            {
                "value": 11574,
                "label": "Minersville",
                "label_ar": "مينرسفيل",
                "label_fr": "Minersville"
            },
            {
                "value": 11575,
                "label": "Minerva",
                "label_ar": "مينيرفا",
                "label_fr": "Minerva"
            },
            {
                "value": 11576,
                "label": "Minford",
                "label_ar": "مينفورد",
                "label_fr": "Minford"
            },
            {
                "value": 11577,
                "label": "Mingo Junction",
                "label_ar": "مفرق مينجو",
                "label_fr": "Jonction Mingo"
            },
            {
                "value": 11578,
                "label": "Minier",
                "label_ar": "مينير",
                "label_fr": "Minier"
            },
            {
                "value": 11579,
                "label": "Minneapolis",
                "label_ar": "مينيابوليس",
                "label_fr": "Minneapolis"
            },
            {
                "value": 11580,
                "label": "Minneola",
                "label_ar": "مينولا",
                "label_fr": "Minneola"
            },
            {
                "value": 11581,
                "label": "Minneota",
                "label_ar": "مينوتا",
                "label_fr": "Minneota"
            },
            {
                "value": 11582,
                "label": "Minnesota Lake",
                "label_ar": "بحيرة مينيسوتا",
                "label_fr": "Lac Minnesota"
            },
            {
                "value": 11583,
                "label": "Minnetonka",
                "label_ar": "مينيتونكا",
                "label_fr": "Minnetonka"
            },
            {
                "value": 11584,
                "label": "Minnewaukan",
                "label_ar": "مينيووكان",
                "label_fr": "Minnewaukan"
            },
            {
                "value": 11585,
                "label": "Minnie",
                "label_ar": "ميني",
                "label_fr": "Minnie"
            },
            {
                "value": 11586,
                "label": "Minoa",
                "label_ar": "مينوا",
                "label_fr": "Minoa"
            },
            {
                "value": 11587,
                "label": "Minocqua",
                "label_ar": "مينوكوا",
                "label_fr": "Minocqua"
            },
            {
                "value": 11588,
                "label": "Minonk",
                "label_ar": "مينونك",
                "label_fr": "Minonk"
            },
            {
                "value": 11589,
                "label": "Minooka",
                "label_ar": "مينوكا",
                "label_fr": "Minooka"
            },
            {
                "value": 11590,
                "label": "Minot",
                "label_ar": "مينوت",
                "label_fr": "Minot"
            },
            {
                "value": 11591,
                "label": "Minotola",
                "label_ar": "مينوتولا",
                "label_fr": "Minotola"
            },
            {
                "value": 11592,
                "label": "Minster",
                "label_ar": "وزير",
                "label_fr": "Cathédrale"
            },
            {
                "value": 11593,
                "label": "Minto",
                "label_ar": "مينتو",
                "label_fr": "Minto"
            },
            {
                "value": 11594,
                "label": "Minturn",
                "label_ar": "مينتورن",
                "label_fr": "Minturn"
            },
            {
                "value": 11595,
                "label": "Mio",
                "label_ar": "ميو",
                "label_fr": "Mio"
            },
            {
                "value": 11596,
                "label": "Mira Loma",
                "label_ar": "ميرا لوما",
                "label_fr": "Mira Loma"
            },
            {
                "value": 11597,
                "label": "Miramar",
                "label_ar": "ميرامار",
                "label_fr": "Miramar"
            },
            {
                "value": 11598,
                "label": "Miramar Beach",
                "label_ar": "شاطئ ميرامار",
                "label_fr": "Plage de Miramar"
            },
            {
                "value": 11599,
                "label": "Miranda",
                "label_ar": "ميراندا",
                "label_fr": "Miranda"
            },
            {
                "value": 11600,
                "label": "Mishawaka",
                "label_ar": "ميشواكا",
                "label_fr": "Mishawaka"
            },
            {
                "value": 11601,
                "label": "Mishicot",
                "label_ar": "ميشيكوت",
                "label_fr": "Mishicot"
            },
            {
                "value": 11602,
                "label": "Mission",
                "label_ar": "مهمة",
                "label_fr": "Mission"
            },
            {
                "value": 11603,
                "label": "Mission Hill",
                "label_ar": "ميشن هيل",
                "label_fr": "Mission Hill"
            },
            {
                "value": 11604,
                "label": "Mission Hills",
                "label_ar": "تلال ميشن",
                "label_fr": "Mission Hills"
            },
            {
                "value": 11605,
                "label": "Mission Viejo",
                "label_ar": "ميشن فيجو",
                "label_fr": "Mission Viejo"
            },
            {
                "value": 11606,
                "label": "Missoula",
                "label_ar": "ميسولا",
                "label_fr": "Missoula"
            },
            {
                "value": 11607,
                "label": "Missouri City",
                "label_ar": "ميسوري سيتي",
                "label_fr": "Missouri City"
            },
            {
                "value": 11608,
                "label": "Missouri Valley",
                "label_ar": "وادي ميسوري",
                "label_fr": "Missouri Valley"
            },
            {
                "value": 11609,
                "label": "Mitchell",
                "label_ar": "ميتشل",
                "label_fr": "Mitchell"
            },
            {
                "value": 11610,
                "label": "Mitchellville",
                "label_ar": "ميتشلفيل",
                "label_fr": "Mitchellville"
            },
            {
                "value": 11611,
                "label": "Mize",
                "label_ar": "تكبير",
                "label_fr": "Mize"
            },
            {
                "value": 11612,
                "label": "Moab",
                "label_ar": "موآب",
                "label_fr": "Moab"
            },
            {
                "value": 11613,
                "label": "Moapa",
                "label_ar": "موابا",
                "label_fr": "Moapa"
            },
            {
                "value": 11614,
                "label": "Moatsville",
                "label_ar": "مواتسفيل",
                "label_fr": "Moatsville"
            },
            {
                "value": 11615,
                "label": "Moberly",
                "label_ar": "موبرلي",
                "label_fr": "Moberly"
            },
            {
                "value": 11616,
                "label": "Mobile",
                "label_ar": "التليفون المحمول",
                "label_fr": "Mobile"
            },
            {
                "value": 11617,
                "label": "Mobridge",
                "label_ar": "Mobridge",
                "label_fr": "Mobridge"
            },
            {
                "value": 11618,
                "label": "Moccasin",
                "label_ar": "حذاء بدون كعب",
                "label_fr": "Mocassin"
            },
            {
                "value": 11619,
                "label": "Mocksville",
                "label_ar": "موكفيل",
                "label_fr": "Mocksville"
            },
            {
                "value": 11620,
                "label": "Modale",
                "label_ar": "مودال",
                "label_fr": "Modale"
            },
            {
                "value": 11621,
                "label": "Modena",
                "label_ar": "مودينا",
                "label_fr": "Modène"
            },
            {
                "value": 11622,
                "label": "Modesto",
                "label_ar": "موديستو",
                "label_fr": "Modesto"
            },
            {
                "value": 11623,
                "label": "Modoc",
                "label_ar": "مودوك",
                "label_fr": "Modoc"
            },
            {
                "value": 11624,
                "label": "Mogadore",
                "label_ar": "موغادور",
                "label_fr": "Mogadore"
            },
            {
                "value": 11625,
                "label": "Mohave Valley",
                "label_ar": "وادي موهافي",
                "label_fr": "Vallée de Mohave"
            },
            {
                "value": 11626,
                "label": "Mohawk",
                "label_ar": "الموهوك",
                "label_fr": "Mohawk"
            },
            {
                "value": 11627,
                "label": "Mohegan Lake",
                "label_ar": "بحيرة موهيغان",
                "label_fr": "Lac Mohegan"
            },
            {
                "value": 11628,
                "label": "Mohnton",
                "label_ar": "موهنتون",
                "label_fr": "Mohnton"
            },
            {
                "value": 11629,
                "label": "Mohrsville",
                "label_ar": "موهرسفيل",
                "label_fr": "Mohrsville"
            },
            {
                "value": 11630,
                "label": "Mojave",
                "label_ar": "موهافي",
                "label_fr": "Mojave"
            },
            {
                "value": 11631,
                "label": "Mokelumne Hill",
                "label_ar": "تل موكيلومن",
                "label_fr": "Mokelumne Hill"
            },
            {
                "value": 11632,
                "label": "Mokena",
                "label_ar": "موكينا",
                "label_fr": "Mokena"
            },
            {
                "value": 11633,
                "label": "Molalla",
                "label_ar": "مولالا",
                "label_fr": "Molalla"
            },
            {
                "value": 11634,
                "label": "Molena",
                "label_ar": "مولينا",
                "label_fr": "Molena"
            },
            {
                "value": 11635,
                "label": "Moline",
                "label_ar": "مولين",
                "label_fr": "Moline"
            },
            {
                "value": 11636,
                "label": "Molino",
                "label_ar": "مولينو",
                "label_fr": "Molino"
            },
            {
                "value": 11637,
                "label": "Mollusk",
                "label_ar": "رخويات",
                "label_fr": "Mollusque"
            },
            {
                "value": 11638,
                "label": "Molt",
                "label_ar": "تساقط",
                "label_fr": "Mue"
            },
            {
                "value": 11639,
                "label": "Momence",
                "label_ar": "لحظة",
                "label_fr": "Momence"
            },
            {
                "value": 11640,
                "label": "Mona",
                "label_ar": "منى",
                "label_fr": "Mona"
            },
            {
                "value": 11641,
                "label": "Monaca",
                "label_ar": "موناكا",
                "label_fr": "Monaca"
            },
            {
                "value": 11642,
                "label": "Monahans",
                "label_ar": "موناهانس",
                "label_fr": "Monahans"
            },
            {
                "value": 11643,
                "label": "Moncks Corner",
                "label_ar": "ركن مونكس",
                "label_fr": "Coin Moncks"
            },
            {
                "value": 11644,
                "label": "Moncure",
                "label_ar": "مونكور",
                "label_fr": "Moncure"
            },
            {
                "value": 11645,
                "label": "Mondamin",
                "label_ar": "موندامين",
                "label_fr": "Mondamin"
            },
            {
                "value": 11646,
                "label": "Mondovi",
                "label_ar": "موندوفي",
                "label_fr": "Mondovi"
            },
            {
                "value": 11647,
                "label": "Monee",
                "label_ar": "موني",
                "label_fr": "Monee"
            },
            {
                "value": 11648,
                "label": "Monessen",
                "label_ar": "مونسين",
                "label_fr": "Monessen"
            },
            {
                "value": 11649,
                "label": "Moneta",
                "label_ar": "مونيتا",
                "label_fr": "Moneta"
            },
            {
                "value": 11650,
                "label": "Monett",
                "label_ar": "مونيت",
                "label_fr": "Monett"
            },
            {
                "value": 11651,
                "label": "Monette",
                "label_ar": "مونيت",
                "label_fr": "Monette"
            },
            {
                "value": 11652,
                "label": "Monkton",
                "label_ar": "مونكتون",
                "label_fr": "Monkton"
            },
            {
                "value": 11653,
                "label": "Monmouth",
                "label_ar": "مونماوث",
                "label_fr": "Monmouth"
            },
            {
                "value": 11654,
                "label": "Monmouth Beach",
                "label_ar": "شاطئ مونماوث",
                "label_fr": "Plage de Monmouth"
            },
            {
                "value": 11655,
                "label": "Monmouth Junction",
                "label_ar": "تقاطع مونماوث",
                "label_fr": "Jonction de Monmouth"
            },
            {
                "value": 11656,
                "label": "Monon",
                "label_ar": "مونون",
                "label_fr": "Monon"
            },
            {
                "value": 11657,
                "label": "Monona",
                "label_ar": "مونونا",
                "label_fr": "Monona"
            },
            {
                "value": 11658,
                "label": "Monongahela",
                "label_ar": "مونونجاهيلا",
                "label_fr": "Monongahela"
            },
            {
                "value": 11659,
                "label": "Monponsett",
                "label_ar": "مونبونسيت",
                "label_fr": "Monponsett"
            },
            {
                "value": 11660,
                "label": "Monroe",
                "label_ar": "مونرو",
                "label_fr": "Monroe"
            },
            {
                "value": 11661,
                "label": "Monroe Center",
                "label_ar": "مركز مونرو",
                "label_fr": "Centre Monroe"
            },
            {
                "value": 11662,
                "label": "Monroe City",
                "label_ar": "مدينة مونرو",
                "label_fr": "Ville de Monroe"
            },
            {
                "value": 11663,
                "label": "Monroe Township",
                "label_ar": "بلدة مونرو",
                "label_fr": "Canton de Monroe"
            },
            {
                "value": 11664,
                "label": "Monroeton",
                "label_ar": "مونروتون",
                "label_fr": "Monroeton"
            },
            {
                "value": 11665,
                "label": "Monroeville",
                "label_ar": "مونروفيل",
                "label_fr": "Monroeville"
            },
            {
                "value": 11666,
                "label": "Monrovia",
                "label_ar": "مونروفيا",
                "label_fr": "Monrovia"
            },
            {
                "value": 11667,
                "label": "Monsey",
                "label_ar": "مونسي",
                "label_fr": "Monsey"
            },
            {
                "value": 11668,
                "label": "Monson",
                "label_ar": "مونسون",
                "label_fr": "Monson"
            },
            {
                "value": 11669,
                "label": "Mont Belvieu",
                "label_ar": "مونت بيلفيو",
                "label_fr": "Mont Belvieu"
            },
            {
                "value": 11670,
                "label": "Mont Clare",
                "label_ar": "مونت كلير",
                "label_fr": "Mont Clare"
            },
            {
                "value": 11671,
                "label": "Mont Vernon",
                "label_ar": "مونت فيرنون",
                "label_fr": "Mont Vernon"
            },
            {
                "value": 11672,
                "label": "Montague",
                "label_ar": "مونتاج",
                "label_fr": "Montague"
            },
            {
                "value": 11673,
                "label": "Montandon",
                "label_ar": "مونتاندون",
                "label_fr": "Montandon"
            },
            {
                "value": 11674,
                "label": "Montara",
                "label_ar": "مونتارا",
                "label_fr": "Montara"
            },
            {
                "value": 11675,
                "label": "Montauk",
                "label_ar": "مونتوك",
                "label_fr": "Montauk"
            },
            {
                "value": 11676,
                "label": "Montclair",
                "label_ar": "مونتكلير",
                "label_fr": "Montclair"
            },
            {
                "value": 11677,
                "label": "Monte Rio",
                "label_ar": "مونتي ريو",
                "label_fr": "Monte Rio"
            },
            {
                "value": 11678,
                "label": "Monte Vista",
                "label_ar": "مونت فيستا",
                "label_fr": "Monte Vista"
            },
            {
                "value": 11679,
                "label": "Monteagle",
                "label_ar": "مونتيجل",
                "label_fr": "Monteagle"
            },
            {
                "value": 11680,
                "label": "Montebello",
                "label_ar": "مونتيبيلو",
                "label_fr": "Montebello"
            },
            {
                "value": 11681,
                "label": "Montegut",
                "label_ar": "مونتيغوت",
                "label_fr": "Montegut"
            },
            {
                "value": 11682,
                "label": "Montello",
                "label_ar": "مونتيلو",
                "label_fr": "Montello"
            },
            {
                "value": 11683,
                "label": "Monterey",
                "label_ar": "مونتيري",
                "label_fr": "Monterey"
            },
            {
                "value": 11684,
                "label": "Monterey County",
                "label_ar": "مقاطعة مونتيري",
                "label_fr": "Comté de Monterey"
            },
            {
                "value": 11685,
                "label": "Monterey Park",
                "label_ar": "مونتيري بارك",
                "label_fr": "Parc de Monterey"
            },
            {
                "value": 11686,
                "label": "Monterville",
                "label_ar": "مونترفيل",
                "label_fr": "Monterville"
            },
            {
                "value": 11687,
                "label": "Montesano",
                "label_ar": "مونتيسانو",
                "label_fr": "Montesano"
            },
            {
                "value": 11688,
                "label": "Montevallo",
                "label_ar": "مونتيفالو",
                "label_fr": "Montevallo"
            },
            {
                "value": 11689,
                "label": "Montevideo",
                "label_ar": "مونتيفيديو",
                "label_fr": "Montevideo"
            },
            {
                "value": 11690,
                "label": "Monteview",
                "label_ar": "مونتيفيو",
                "label_fr": "Monteview"
            },
            {
                "value": 11691,
                "label": "Montezuma",
                "label_ar": "مونتيزوما",
                "label_fr": "Montezuma"
            },
            {
                "value": 11692,
                "label": "Montfort",
                "label_ar": "مونتفورت",
                "label_fr": "Montfort"
            },
            {
                "value": 11693,
                "label": "Montgomery",
                "label_ar": "مونتغمري",
                "label_fr": "Montgomery"
            },
            {
                "value": 11694,
                "label": "Montgomery Center",
                "label_ar": "مركز مونتغمري",
                "label_fr": "Centre Montgomery"
            },
            {
                "value": 11695,
                "label": "Montgomery City",
                "label_ar": "مدينة مونتغمري",
                "label_fr": "Ville de Montgomery"
            },
            {
                "value": 11696,
                "label": "Montgomery Creek",
                "label_ar": "مونتغمري كريك",
                "label_fr": "Montgomery Creek"
            },
            {
                "value": 11697,
                "label": "Montgomery Village",
                "label_ar": "قرية مونتغمري",
                "label_fr": "Village de Montgomery"
            },
            {
                "value": 11698,
                "label": "Montgomeryville",
                "label_ar": "مونتجومريفيل",
                "label_fr": "Montgomeryville"
            },
            {
                "value": 11699,
                "label": "Monticello",
                "label_ar": "مونتايسلو",
                "label_fr": "Monticello"
            },
            {
                "value": 11700,
                "label": "Montour",
                "label_ar": "مونتور",
                "label_fr": "Montour"
            },
            {
                "value": 11701,
                "label": "Montour Falls",
                "label_ar": "شلالات مونتور",
                "label_fr": "Chutes Montour"
            },
            {
                "value": 11702,
                "label": "Montoursville",
                "label_ar": "مونتورسفيل",
                "label_fr": "Montoursville"
            },
            {
                "value": 11703,
                "label": "Montpelier",
                "label_ar": "مونبلييه",
                "label_fr": "Montpellier"
            },
            {
                "value": 11704,
                "label": "Montreal",
                "label_ar": "مونتريال",
                "label_fr": "Montréal"
            },
            {
                "value": 11705,
                "label": "Montreat",
                "label_ar": "مونتريت",
                "label_fr": "Montreat"
            },
            {
                "value": 11706,
                "label": "Montrose",
                "label_ar": "مونتروز",
                "label_fr": "Montrose"
            },
            {
                "value": 11707,
                "label": "Montross",
                "label_ar": "مونتروس",
                "label_fr": "Montross"
            },
            {
                "value": 11708,
                "label": "Montvale",
                "label_ar": "مونتفيل",
                "label_fr": "Montvale"
            },
            {
                "value": 11709,
                "label": "Montverde",
                "label_ar": "مونتفيردي",
                "label_fr": "Montverde"
            },
            {
                "value": 11710,
                "label": "Montville",
                "label_ar": "مونتفيل",
                "label_fr": "Montville"
            },
            {
                "value": 11711,
                "label": "Monument",
                "label_ar": "نصب",
                "label_fr": "Monument"
            },
            {
                "value": 11712,
                "label": "Monument Beach",
                "label_ar": "شاطئ النصب",
                "label_fr": "Monument Beach"
            },
            {
                "value": 11713,
                "label": "Moodus",
                "label_ar": "مزاج",
                "label_fr": "Moodus"
            },
            {
                "value": 11714,
                "label": "Moody",
                "label_ar": "متقلبة المزاج",
                "label_fr": "de mauvaise humeur"
            },
            {
                "value": 11715,
                "label": "Mooers",
                "label_ar": "Mooers",
                "label_fr": "Mooers"
            },
            {
                "value": 11716,
                "label": "Mooers Forks",
                "label_ar": "Mooers Forks",
                "label_fr": "Fourches Mooers"
            },
            {
                "value": 11717,
                "label": "Moon",
                "label_ar": "القمر",
                "label_fr": "Lune"
            },
            {
                "value": 11718,
                "label": "Moonachie",
                "label_ar": "موناتشي",
                "label_fr": "Moonachie"
            },
            {
                "value": 11719,
                "label": "Moorcroft",
                "label_ar": "موركروفت",
                "label_fr": "Moorcroft"
            },
            {
                "value": 11720,
                "label": "Moore",
                "label_ar": "مور",
                "label_fr": "Moore"
            },
            {
                "value": 11721,
                "label": "Moore Haven",
                "label_ar": "مور هافن",
                "label_fr": "Moore Haven"
            },
            {
                "value": 11722,
                "label": "Moorefield",
                "label_ar": "مورفيلد",
                "label_fr": "Moorefield"
            },
            {
                "value": 11723,
                "label": "Mooreland",
                "label_ar": "مورلاند",
                "label_fr": "Landes"
            },
            {
                "value": 11724,
                "label": "Moores Hill",
                "label_ar": "تل موريس",
                "label_fr": "Moores Hill"
            },
            {
                "value": 11725,
                "label": "Mooresboro",
                "label_ar": "موريسبورو",
                "label_fr": "Mooresboro"
            },
            {
                "value": 11726,
                "label": "Mooresburg",
                "label_ar": "مورسبورغ",
                "label_fr": "Mooresburg"
            },
            {
                "value": 11727,
                "label": "Moorestown Township",
                "label_ar": "بلدة مورستاون",
                "label_fr": "Canton de Moorestown"
            },
            {
                "value": 11728,
                "label": "Mooresville",
                "label_ar": "مورسفيل",
                "label_fr": "Mooresville"
            },
            {
                "value": 11729,
                "label": "Mooreton",
                "label_ar": "موريتون",
                "label_fr": "Mooreton"
            },
            {
                "value": 11730,
                "label": "Mooreville",
                "label_ar": "مورفيل",
                "label_fr": "Mooreville"
            },
            {
                "value": 11731,
                "label": "Moorhead",
                "label_ar": "مورهيد",
                "label_fr": "Moorhead"
            },
            {
                "value": 11732,
                "label": "Mooringsport",
                "label_ar": "Mooringsport",
                "label_fr": "Mooringsport"
            },
            {
                "value": 11733,
                "label": "Moorland",
                "label_ar": "مورلاند",
                "label_fr": "Landes"
            },
            {
                "value": 11734,
                "label": "Moorpark",
                "label_ar": "موربارك",
                "label_fr": "Moorpark"
            },
            {
                "value": 11735,
                "label": "Moose",
                "label_ar": "غزال أمريكي ضخم",
                "label_fr": "élan"
            },
            {
                "value": 11736,
                "label": "Moose Lake",
                "label_ar": "بحيرة موس",
                "label_fr": "Lac Moose"
            },
            {
                "value": 11737,
                "label": "Moosic",
                "label_ar": "موسيك",
                "label_fr": "Moosic"
            },
            {
                "value": 11738,
                "label": "Moosup",
                "label_ar": "Moosup",
                "label_fr": "Moosup"
            },
            {
                "value": 11739,
                "label": "Mora",
                "label_ar": "مورا",
                "label_fr": "Mora"
            },
            {
                "value": 11740,
                "label": "Moraga",
                "label_ar": "موراغا",
                "label_fr": "Moraga"
            },
            {
                "value": 11741,
                "label": "Moran",
                "label_ar": "موران",
                "label_fr": "Moran"
            },
            {
                "value": 11742,
                "label": "Moravia",
                "label_ar": "مورافيا",
                "label_fr": "Moravie"
            },
            {
                "value": 11743,
                "label": "Moravian Falls",
                "label_ar": "شلالات مورافيا",
                "label_fr": "Moravian Falls"
            },
            {
                "value": 11744,
                "label": "Moreauville",
                "label_ar": "موريوفيل",
                "label_fr": "Moreauville"
            },
            {
                "value": 11745,
                "label": "Morehead",
                "label_ar": "مورهيد",
                "label_fr": "Morehead"
            },
            {
                "value": 11746,
                "label": "Morehead City",
                "label_ar": "مورهيد سيتي",
                "label_fr": "Morehead City"
            },
            {
                "value": 11747,
                "label": "Moreland",
                "label_ar": "المزيد من الأراضي",
                "label_fr": "Plus de terrain"
            },
            {
                "value": 11748,
                "label": "Morenci",
                "label_ar": "مورينسي",
                "label_fr": "Morenci"
            },
            {
                "value": 11749,
                "label": "Moreno Valley",
                "label_ar": "وادي مورينو",
                "label_fr": "Vallée de Moreno"
            },
            {
                "value": 11750,
                "label": "Moretown",
                "label_ar": "موريتاون",
                "label_fr": "Moretown"
            },
            {
                "value": 11751,
                "label": "Morgan",
                "label_ar": "مورغان",
                "label_fr": "Morgan"
            },
            {
                "value": 11752,
                "label": "Morgan City",
                "label_ar": "مورغان سيتي",
                "label_fr": "Morgan City"
            },
            {
                "value": 11753,
                "label": "Morgan Hill",
                "label_ar": "مورغان هيل",
                "label_fr": "Morgan Hill"
            },
            {
                "value": 11754,
                "label": "Morganfield",
                "label_ar": "مورغانفيلد",
                "label_fr": "Morganfield"
            },
            {
                "value": 11755,
                "label": "Morganton",
                "label_ar": "مورغانتون",
                "label_fr": "Morganton"
            },
            {
                "value": 11756,
                "label": "Morgantown",
                "label_ar": "مورغانتاون",
                "label_fr": "Morgantown"
            },
            {
                "value": 11757,
                "label": "Morganville",
                "label_ar": "مورغانفيل",
                "label_fr": "Morganville"
            },
            {
                "value": 11758,
                "label": "Moriah",
                "label_ar": "موريا",
                "label_fr": "Moriah"
            },
            {
                "value": 11759,
                "label": "Moriarty",
                "label_ar": "موريارتي",
                "label_fr": "Moriarty"
            },
            {
                "value": 11760,
                "label": "Moriches",
                "label_ar": "موريشيس",
                "label_fr": "Moriches"
            },
            {
                "value": 11761,
                "label": "Morley",
                "label_ar": "مورلي",
                "label_fr": "Morley"
            },
            {
                "value": 11762,
                "label": "Morning Sun",
                "label_ar": "شمس الصباح",
                "label_fr": "Soleil du matin"
            },
            {
                "value": 11763,
                "label": "Morning View",
                "label_ar": "عرض صباح اليوم",
                "label_fr": "Vue du matin"
            },
            {
                "value": 11764,
                "label": "Moro",
                "label_ar": "مورو",
                "label_fr": "Moro"
            },
            {
                "value": 11765,
                "label": "Morocco",
                "label_ar": "المغرب",
                "label_fr": "Maroc"
            },
            {
                "value": 11766,
                "label": "Morongo Valley",
                "label_ar": "وادي مورونجو",
                "label_fr": "Vallée de Morongo"
            },
            {
                "value": 11767,
                "label": "Moroni",
                "label_ar": "موروني",
                "label_fr": "Moroni"
            },
            {
                "value": 11768,
                "label": "Morral",
                "label_ar": "مورال",
                "label_fr": "Morral"
            },
            {
                "value": 11769,
                "label": "Morrice",
                "label_ar": "موريس",
                "label_fr": "Morrice"
            },
            {
                "value": 11770,
                "label": "Morrill",
                "label_ar": "موريل",
                "label_fr": "Morrill"
            },
            {
                "value": 11771,
                "label": "Morrilton",
                "label_ar": "موريلتون",
                "label_fr": "Morrilton"
            },
            {
                "value": 11772,
                "label": "Morris",
                "label_ar": "موريس",
                "label_fr": "Morris"
            },
            {
                "value": 11773,
                "label": "Morris Chapel",
                "label_ar": "موريس تشابل",
                "label_fr": "Chapelle Morris"
            },
            {
                "value": 11774,
                "label": "Morris Plains",
                "label_ar": "موريس بلينز",
                "label_fr": "Morris Plains"
            },
            {
                "value": 11775,
                "label": "Morrisdale",
                "label_ar": "موريسديل",
                "label_fr": "Morrisdale"
            },
            {
                "value": 11776,
                "label": "Morrison",
                "label_ar": "موريسون",
                "label_fr": "Morrison"
            },
            {
                "value": 11777,
                "label": "Morrisonville",
                "label_ar": "موريسونفيل",
                "label_fr": "Morrisonville"
            },
            {
                "value": 11778,
                "label": "Morriston",
                "label_ar": "موريستون",
                "label_fr": "Morriston"
            },
            {
                "value": 11779,
                "label": "Morristown",
                "label_ar": "موريستاون",
                "label_fr": "Morristown"
            },
            {
                "value": 11780,
                "label": "Morrisville",
                "label_ar": "موريسفيل",
                "label_fr": "Morrisville"
            },
            {
                "value": 11781,
                "label": "Morro Bay",
                "label_ar": "خليج مورو",
                "label_fr": "Morro Bay"
            },
            {
                "value": 11782,
                "label": "Morrow",
                "label_ar": "الغد",
                "label_fr": "Morrow"
            },
            {
                "value": 11783,
                "label": "Morse",
                "label_ar": "مورس",
                "label_fr": "Morse"
            },
            {
                "value": 11784,
                "label": "Morse Bluff",
                "label_ar": "مورس بلاف",
                "label_fr": "Morse Bluff"
            },
            {
                "value": 11785,
                "label": "Morton",
                "label_ar": "مورتون",
                "label_fr": "Morton"
            },
            {
                "value": 11786,
                "label": "Morton Grove",
                "label_ar": "مورتون جروف",
                "label_fr": "Morton Grove"
            },
            {
                "value": 11787,
                "label": "Mortons Gap",
                "label_ar": "مورتونز جاب",
                "label_fr": "Mortons Gap"
            },
            {
                "value": 11788,
                "label": "Morven",
                "label_ar": "مورفن",
                "label_fr": "Morven"
            },
            {
                "value": 11789,
                "label": "Mosca",
                "label_ar": "موسكا",
                "label_fr": "Mosca"
            },
            {
                "value": 11790,
                "label": "Moscow",
                "label_ar": "موسكو",
                "label_fr": "Moscou"
            },
            {
                "value": 11791,
                "label": "Moscow Mills",
                "label_ar": "موسكو ميلز",
                "label_fr": "Moscou Mills"
            },
            {
                "value": 11792,
                "label": "Moseley",
                "label_ar": "موزلي",
                "label_fr": "Moseley"
            },
            {
                "value": 11793,
                "label": "Moses Lake",
                "label_ar": "بحيرة موسى",
                "label_fr": "Lac Moses"
            },
            {
                "value": 11794,
                "label": "Mosheim",
                "label_ar": "موشيم",
                "label_fr": "Mosheim"
            },
            {
                "value": 11795,
                "label": "Mosier",
                "label_ar": "موزير",
                "label_fr": "Mosier"
            },
            {
                "value": 11796,
                "label": "Mosinee",
                "label_ar": "Mosinee",
                "label_fr": "Mosinee"
            },
            {
                "value": 11797,
                "label": "Moss",
                "label_ar": "طحلب",
                "label_fr": "Mousse"
            },
            {
                "value": 11798,
                "label": "Moss Beach",
                "label_ar": "شاطئ موس",
                "label_fr": "Plage de la mousse"
            },
            {
                "value": 11799,
                "label": "Moss Landing",
                "label_ar": "موس لاندينغ",
                "label_fr": "Atterrissage de mousse"
            },
            {
                "value": 11800,
                "label": "Moss Point",
                "label_ar": "موس بوينت",
                "label_fr": "Point de mousse"
            },
            {
                "value": 11801,
                "label": "Mossyrock",
                "label_ar": "موسيروك",
                "label_fr": "Mossyrock"
            },
            {
                "value": 11802,
                "label": "Motley",
                "label_ar": "موتلي",
                "label_fr": "Hétéroclite"
            },
            {
                "value": 11803,
                "label": "Mott",
                "label_ar": "موط",
                "label_fr": "Mott"
            },
            {
                "value": 11804,
                "label": "Moulton",
                "label_ar": "مولتون",
                "label_fr": "Moulton"
            },
            {
                "value": 11805,
                "label": "Moultonborough",
                "label_ar": "مولتونبورو",
                "label_fr": "Moultonborough"
            },
            {
                "value": 11806,
                "label": "Moultrie",
                "label_ar": "مولتري",
                "label_fr": "Moultrie"
            },
            {
                "value": 11807,
                "label": "Mound",
                "label_ar": "تل",
                "label_fr": "Monticule"
            },
            {
                "value": 11808,
                "label": "Mound Bayou",
                "label_ar": "تل بايو",
                "label_fr": "Mound Bayou"
            },
            {
                "value": 11809,
                "label": "Mound City",
                "label_ar": "مدينة التل",
                "label_fr": "Mound City"
            },
            {
                "value": 11810,
                "label": "Moundridge",
                "label_ar": "موندريدج",
                "label_fr": "Moundridge"
            },
            {
                "value": 11811,
                "label": "Mounds",
                "label_ar": "تلال",
                "label_fr": "Monticules"
            },
            {
                "value": 11812,
                "label": "Moundsville",
                "label_ar": "موندسفيل",
                "label_fr": "Moundsville"
            },
            {
                "value": 11813,
                "label": "Moundville",
                "label_ar": "موندفيل",
                "label_fr": "Moundville"
            },
            {
                "value": 11814,
                "label": "Mount Airy",
                "label_ar": "جبل إيري",
                "label_fr": "Mont Airy"
            },
            {
                "value": 11815,
                "label": "Mount Angel",
                "label_ar": "جبل انجيل",
                "label_fr": "Mont Angel"
            },
            {
                "value": 11816,
                "label": "Mount Arlington",
                "label_ar": "جبل أرلينغتون",
                "label_fr": "Mont Arlington"
            },
            {
                "value": 11817,
                "label": "Mount Auburn",
                "label_ar": "جبل أوبورن",
                "label_fr": "Mont Auburn"
            },
            {
                "value": 11818,
                "label": "Mount Ayr",
                "label_ar": "جبل آير",
                "label_fr": "Mont Ayr"
            },
            {
                "value": 11819,
                "label": "Mount Berry",
                "label_ar": "جبل بيري",
                "label_fr": "Mont Berry"
            },
            {
                "value": 11820,
                "label": "Mount Bethel",
                "label_ar": "جبل بيت ايل",
                "label_fr": "Mont Béthel"
            },
            {
                "value": 11821,
                "label": "Mount Blanchard",
                "label_ar": "جبل بلانشارد",
                "label_fr": "Mont Blanchard"
            },
            {
                "value": 11822,
                "label": "Mount Calvary",
                "label_ar": "جبل الجلجثة",
                "label_fr": "Mont Calvaire"
            },
            {
                "value": 11823,
                "label": "Mount Carbon",
                "label_ar": "جبل الكربون",
                "label_fr": "Monter le carbone"
            },
            {
                "value": 11824,
                "label": "Mount Carmel",
                "label_ar": "جبل الكرمل",
                "label_fr": "Mont Carmel"
            },
            {
                "value": 11825,
                "label": "Mount Carroll",
                "label_ar": "جبل كارول",
                "label_fr": "Mont Carroll"
            },
            {
                "value": 11826,
                "label": "Mount Clemens",
                "label_ar": "جبل كليمنس",
                "label_fr": "Mont Clemens"
            },
            {
                "value": 11827,
                "label": "Mount Cory",
                "label_ar": "جبل كوري",
                "label_fr": "Mont Cory"
            },
            {
                "value": 11828,
                "label": "Mount Crawford",
                "label_ar": "جبل كروفورد",
                "label_fr": "Mont Crawford"
            },
            {
                "value": 11829,
                "label": "Mount Croghan",
                "label_ar": "جبل كروغان",
                "label_fr": "Mont Croghan"
            },
            {
                "value": 11830,
                "label": "Mount Eaton",
                "label_ar": "جبل إيتون",
                "label_fr": "Mont Eaton"
            },
            {
                "value": 11831,
                "label": "Mount Eden",
                "label_ar": "جبل عدن",
                "label_fr": "Mont Eden"
            },
            {
                "value": 11832,
                "label": "Mount Enterprise",
                "label_ar": "جبل إنتربرايز",
                "label_fr": "Mount Enterprise"
            },
            {
                "value": 11833,
                "label": "Mount Ephraim",
                "label_ar": "جبل افرايم",
                "label_fr": "Mont Ephraim"
            },
            {
                "value": 11834,
                "label": "Mount Erie",
                "label_ar": "جبل إيري",
                "label_fr": "Mont Érié"
            },
            {
                "value": 11835,
                "label": "Mount Gay",
                "label_ar": "جبل جاي",
                "label_fr": "Mount Gay"
            },
            {
                "value": 11836,
                "label": "Mount Gilead",
                "label_ar": "جبل جلعاد",
                "label_fr": "Mont Galaad"
            },
            {
                "value": 11837,
                "label": "Mount Gretna",
                "label_ar": "جبل جريتنا",
                "label_fr": "Mont Gretna"
            },
            {
                "value": 11838,
                "label": "Mount Hermon",
                "label_ar": "جبل حرمون",
                "label_fr": "Mont Hermon"
            },
            {
                "value": 11839,
                "label": "Mount Holly",
                "label_ar": "جبل هولي",
                "label_fr": "Mont Holly"
            },
            {
                "value": 11840,
                "label": "Mount Holly Springs",
                "label_ar": "جبل هولي سبرينغز",
                "label_fr": "Mount Holly Springs"
            },
            {
                "value": 11841,
                "label": "Mount Hope",
                "label_ar": "جبل الأمل",
                "label_fr": "Mount Hope"
            },
            {
                "value": 11842,
                "label": "Mount Horeb",
                "label_ar": "جبل حوريب",
                "label_fr": "Mont Horeb"
            },
            {
                "value": 11843,
                "label": "Mount Ida",
                "label_ar": "جبل إيدا",
                "label_fr": "Mont Ida"
            },
            {
                "value": 11844,
                "label": "Mount Jackson",
                "label_ar": "جبل جاكسون",
                "label_fr": "Mont Jackson"
            },
            {
                "value": 11845,
                "label": "Mount Jewett",
                "label_ar": "جبل جيويت",
                "label_fr": "Mont Jewett"
            },
            {
                "value": 11846,
                "label": "Mount Joy",
                "label_ar": "جبل جوي",
                "label_fr": "Mont Joy"
            },
            {
                "value": 11847,
                "label": "Mount Juliet",
                "label_ar": "جبل جولييت",
                "label_fr": "Mont Juliette"
            },
            {
                "value": 11848,
                "label": "Mount Kisco",
                "label_ar": "جبل كيسكو",
                "label_fr": "Mont Kisco"
            },
            {
                "value": 11849,
                "label": "Mount Laurel",
                "label_ar": "جبل لوريل",
                "label_fr": "Mont Laurel"
            },
            {
                "value": 11850,
                "label": "Mount Lookout",
                "label_ar": "جبل لوكاوت",
                "label_fr": "Mount Lookout"
            },
            {
                "value": 11851,
                "label": "Mount Morris",
                "label_ar": "جبل موريس",
                "label_fr": "Mont Morris"
            },
            {
                "value": 11852,
                "label": "Mount Nebo",
                "label_ar": "جبل نيبو",
                "label_fr": "Mont Nebo"
            },
            {
                "value": 11853,
                "label": "Mount Olive",
                "label_ar": "جبل الزيتون",
                "label_fr": "Mont Olive"
            },
            {
                "value": 11854,
                "label": "Mount Olivet",
                "label_ar": "جبل الزيتون",
                "label_fr": "Mont Olivet"
            },
            {
                "value": 11855,
                "label": "Mount Orab",
                "label_ar": "جبل عراب",
                "label_fr": "Mont Orab"
            },
            {
                "value": 11856,
                "label": "Mount Pleasant",
                "label_ar": "جبل ممتعة",
                "label_fr": "Mount Pleasant"
            },
            {
                "value": 11857,
                "label": "Mount Pleasant Mills",
                "label_ar": "ماونت بليزانت ميلز",
                "label_fr": "Mount Pleasant Mills"
            },
            {
                "value": 11858,
                "label": "Mount Pocono",
                "label_ar": "جبل بوكونو",
                "label_fr": "Mont Pocono"
            },
            {
                "value": 11859,
                "label": "Mount Prospect",
                "label_ar": "جبل بروسبكت",
                "label_fr": "Mount Prospect"
            },
            {
                "value": 11860,
                "label": "Mount Pulaski",
                "label_ar": "جبل بولاسكي",
                "label_fr": "Mont Pulaski"
            },
            {
                "value": 11861,
                "label": "Mount Rainier",
                "label_ar": "جبل رينييه",
                "label_fr": "Mont Rainier"
            },
            {
                "value": 11862,
                "label": "Mount Royal",
                "label_ar": "جبل رويال",
                "label_fr": "Mont-Royal"
            },
            {
                "value": 11863,
                "label": "Mount Saint Joseph",
                "label_ar": "جبل القديس يوسف",
                "label_fr": "Mont Saint Joseph"
            },
            {
                "value": 11864,
                "label": "Mount Savage",
                "label_ar": "جبل سافاج",
                "label_fr": "Mont Savage"
            },
            {
                "value": 11865,
                "label": "Mount Shasta",
                "label_ar": "جبل شاستا",
                "label_fr": "Mont Shasta"
            },
            {
                "value": 11866,
                "label": "Mount Sidney",
                "label_ar": "جبل سيدني",
                "label_fr": "Mont Sidney"
            },
            {
                "value": 11867,
                "label": "Mount Sinai",
                "label_ar": "جبل سيناء",
                "label_fr": "Mont Sinaï"
            },
            {
                "value": 11868,
                "label": "Mount Sterling",
                "label_ar": "جبل الاسترليني",
                "label_fr": "Mont Sterling"
            },
            {
                "value": 11869,
                "label": "Mount Storm",
                "label_ar": "جبل ستورم",
                "label_fr": "Mont Storm"
            },
            {
                "value": 11870,
                "label": "Mount Tremper",
                "label_ar": "جبل Tremper",
                "label_fr": "Mont Tremper"
            },
            {
                "value": 11871,
                "label": "Mount Ulla",
                "label_ar": "جبل أولا",
                "label_fr": "Mont Ulla"
            },
            {
                "value": 11872,
                "label": "Mount Union",
                "label_ar": "جبل الاتحاد",
                "label_fr": "Mount Union"
            },
            {
                "value": 11873,
                "label": "Mount Upton",
                "label_ar": "جبل ابتون",
                "label_fr": "Mont Upton"
            },
            {
                "value": 11874,
                "label": "Mount Vernon",
                "label_ar": "جبل فيرنون",
                "label_fr": "Mont Vernon"
            },
            {
                "value": 11875,
                "label": "Mount Victoria",
                "label_ar": "جبل فيكتوريا",
                "label_fr": "Mont Victoria"
            },
            {
                "value": 11876,
                "label": "Mount Victory",
                "label_ar": "جبل النصر",
                "label_fr": "Mont Victoire"
            },
            {
                "value": 11877,
                "label": "Mount Vision",
                "label_ar": "ماونت فيجن",
                "label_fr": "Mount Vision"
            },
            {
                "value": 11878,
                "label": "Mount Washington",
                "label_ar": "جبل واشنطن",
                "label_fr": "Mont Washington"
            },
            {
                "value": 11879,
                "label": "Mount Wilson",
                "label_ar": "جبل ويلسون",
                "label_fr": "Mont Wilson"
            },
            {
                "value": 11880,
                "label": "Mount Wolf",
                "label_ar": "جبل وولف",
                "label_fr": "Mount Wolf"
            },
            {
                "value": 11881,
                "label": "Mount Zion",
                "label_ar": "جبل صهيون",
                "label_fr": "Mont Sion"
            },
            {
                "value": 11882,
                "label": "Mountain",
                "label_ar": "الجبل",
                "label_fr": "Montagne"
            },
            {
                "value": 11883,
                "label": "Mountain City",
                "label_ar": "مدينة الجبل",
                "label_fr": "Ville de montagne"
            },
            {
                "value": 11884,
                "label": "Mountain Grove",
                "label_ar": "ماونتن جروف",
                "label_fr": "Mountain Grove"
            },
            {
                "value": 11885,
                "label": "Mountain Home",
                "label_ar": "ماونتن هوم",
                "label_fr": "Maison de montagne"
            },
            {
                "value": 11886,
                "label": "Mountain Iron",
                "label_ar": "جبل الحديد",
                "label_fr": "Fer de montagne"
            },
            {
                "value": 11887,
                "label": "Mountain Lake",
                "label_ar": "بحيرة الجبل",
                "label_fr": "Lac de montagne"
            },
            {
                "value": 11888,
                "label": "Mountain Lakes",
                "label_ar": "بحيرات جبلية",
                "label_fr": "Lacs de montagne"
            },
            {
                "value": 11889,
                "label": "Mountain Pine",
                "label_ar": "الجبل الصنوبر",
                "label_fr": "Pin de montagne"
            },
            {
                "value": 11890,
                "label": "Mountain Ranch",
                "label_ar": "مزرعة الجبل",
                "label_fr": "Ranch de montagne"
            },
            {
                "value": 11891,
                "label": "Mountain Top",
                "label_ar": "قمة الجبل",
                "label_fr": "Sommet de la montagne"
            },
            {
                "value": 11892,
                "label": "Mountain View",
                "label_ar": "ماونتن فيو",
                "label_fr": "vue sur la montagne"
            },
            {
                "value": 11893,
                "label": "Mountain Village",
                "label_ar": "قرية جبلية",
                "label_fr": "Village de montagne"
            },
            {
                "value": 11894,
                "label": "Mountainair",
                "label_ar": "ماونتينير",
                "label_fr": "Mountainair"
            },
            {
                "value": 11895,
                "label": "Mountainburg",
                "label_ar": "ماونتينبيرج",
                "label_fr": "Mountainburg"
            },
            {
                "value": 11896,
                "label": "Mountainside",
                "label_ar": "سفح الجبل",
                "label_fr": "À flanc de montagne"
            },
            {
                "value": 11897,
                "label": "Mountainville",
                "label_ar": "ماونتنفيل",
                "label_fr": "Mountainville"
            },
            {
                "value": 11898,
                "label": "Mountlake Terrace",
                "label_ar": "ماونت ليك تراس",
                "label_fr": "Terrasse Mountlake"
            },
            {
                "value": 11899,
                "label": "Mountville",
                "label_ar": "ماونتفيل",
                "label_fr": "Mountville"
            },
            {
                "value": 11900,
                "label": "Mousie",
                "label_ar": "موسي",
                "label_fr": "Mousie"
            },
            {
                "value": 11901,
                "label": "Mouth of Wilson",
                "label_ar": "فم ويلسون",
                "label_fr": "Bouche de Wilson"
            },
            {
                "value": 11902,
                "label": "Mouthcard",
                "label_ar": "بطاقة الفم",
                "label_fr": "Carte de la bouche"
            },
            {
                "value": 11903,
                "label": "Moville",
                "label_ar": "موفيل",
                "label_fr": "Moville"
            },
            {
                "value": 11904,
                "label": "Moweaqua",
                "label_ar": "Moweaqua",
                "label_fr": "Moweaqua"
            },
            {
                "value": 11905,
                "label": "Moxee City",
                "label_ar": "مدينة موكسي",
                "label_fr": "Moxee City"
            },
            {
                "value": 11906,
                "label": "Moyie Springs",
                "label_ar": "مويي سبرينجز",
                "label_fr": "Moyie Springs"
            },
            {
                "value": 11907,
                "label": "Moyock",
                "label_ar": "موك",
                "label_fr": "Moyock"
            },
            {
                "value": 11908,
                "label": "Mt Pleasant",
                "label_ar": "ماونت بليزانت",
                "label_fr": "Mt Pleasant"
            },
            {
                "value": 11909,
                "label": "Mt. Dora",
                "label_ar": "جبل. درة",
                "label_fr": "Mt. Dora"
            },
            {
                "value": 11910,
                "label": "Mt. Morris",
                "label_ar": "جبل. موريس",
                "label_fr": "Mt. Morris"
            },
            {
                "value": 11911,
                "label": "Mt. Pleasant",
                "label_ar": "جبل. ممتع",
                "label_fr": "Mt. Agréable"
            },
            {
                "value": 11912,
                "label": "Mt. Vernon",
                "label_ar": "جبل. فيرنون",
                "label_fr": "Mt. Vernon"
            },
            {
                "value": 11913,
                "label": "Muenster",
                "label_ar": "مونستر",
                "label_fr": "Muenster"
            },
            {
                "value": 11914,
                "label": "Muir",
                "label_ar": "موير",
                "label_fr": "Muir"
            },
            {
                "value": 11915,
                "label": "Mukilteo",
                "label_ar": "موكيلتيو",
                "label_fr": "Mukilteo"
            },
            {
                "value": 11916,
                "label": "Mukwonago",
                "label_ar": "موكوناجو",
                "label_fr": "Mukwonago"
            },
            {
                "value": 11917,
                "label": "Mulberry",
                "label_ar": "التوت",
                "label_fr": "Mûre"
            },
            {
                "value": 11918,
                "label": "Mulberry Grove",
                "label_ar": "مولبيري جروف",
                "label_fr": "Mulberry Grove"
            },
            {
                "value": 11919,
                "label": "Muldoon",
                "label_ar": "مولدون",
                "label_fr": "Muldoon"
            },
            {
                "value": 11920,
                "label": "Muldraugh",
                "label_ar": "مولدراوغ",
                "label_fr": "Muldraugh"
            },
            {
                "value": 11921,
                "label": "Muldrow",
                "label_ar": "مولدو",
                "label_fr": "Muldrow"
            },
            {
                "value": 11922,
                "label": "Muleshoe",
                "label_ar": "موليشو",
                "label_fr": "Muleshoe"
            },
            {
                "value": 11923,
                "label": "Mulga",
                "label_ar": "مولجا",
                "label_fr": "Mulga"
            },
            {
                "value": 11924,
                "label": "Mulino",
                "label_ar": "مولينو",
                "label_fr": "Mulino"
            },
            {
                "value": 11925,
                "label": "Mulkeytown",
                "label_ar": "مولكيتاون",
                "label_fr": "Mulkeytown"
            },
            {
                "value": 11926,
                "label": "Mullan",
                "label_ar": "مولان",
                "label_fr": "Mullan"
            },
            {
                "value": 11927,
                "label": "Mullen",
                "label_ar": "مولين",
                "label_fr": "Mullen"
            },
            {
                "value": 11928,
                "label": "Mullica Hill",
                "label_ar": "تل موليكا",
                "label_fr": "Mullica Hill"
            },
            {
                "value": 11929,
                "label": "Mulliken",
                "label_ar": "موليكن",
                "label_fr": "Mulliken"
            },
            {
                "value": 11930,
                "label": "Mullin",
                "label_ar": "مولين",
                "label_fr": "Mullin"
            },
            {
                "value": 11931,
                "label": "Mullins",
                "label_ar": "مولينز",
                "label_fr": "Mullins"
            },
            {
                "value": 11932,
                "label": "Mullinville",
                "label_ar": "مولينفيل",
                "label_fr": "Mullinville"
            },
            {
                "value": 11933,
                "label": "Mulvane",
                "label_ar": "مولفان",
                "label_fr": "Mulvane"
            },
            {
                "value": 11934,
                "label": "Muncie",
                "label_ar": "مونسي",
                "label_fr": "Muncie"
            },
            {
                "value": 11935,
                "label": "Muncy",
                "label_ar": "مونسي",
                "label_fr": "Muncy"
            },
            {
                "value": 11936,
                "label": "Munday",
                "label_ar": "الأحد",
                "label_fr": "Munday"
            },
            {
                "value": 11937,
                "label": "Mundelein",
                "label_ar": "موندلين",
                "label_fr": "Mundelein"
            },
            {
                "value": 11938,
                "label": "Munford",
                "label_ar": "مونفورد",
                "label_fr": "Munford"
            },
            {
                "value": 11939,
                "label": "Munfordville",
                "label_ar": "مونفوردفيل",
                "label_fr": "Munfordville"
            },
            {
                "value": 11940,
                "label": "Munger",
                "label_ar": "مونجر",
                "label_fr": "Munger"
            },
            {
                "value": 11941,
                "label": "Munich",
                "label_ar": "ميونيخ",
                "label_fr": "Munich"
            },
            {
                "value": 11942,
                "label": "Munising",
                "label_ar": "مونيسينج",
                "label_fr": "Munising"
            },
            {
                "value": 11943,
                "label": "Munith",
                "label_ar": "منيث",
                "label_fr": "Munith"
            },
            {
                "value": 11944,
                "label": "Munnsville",
                "label_ar": "مونسفيل",
                "label_fr": "Munnsville"
            },
            {
                "value": 11945,
                "label": "Munroe Falls",
                "label_ar": "شلالات مونرو",
                "label_fr": "Munroe Falls"
            },
            {
                "value": 11946,
                "label": "Munster",
                "label_ar": "مونستر",
                "label_fr": "Munster"
            },
            {
                "value": 11947,
                "label": "Murchison",
                "label_ar": "مورشيسون",
                "label_fr": "Murchison"
            },
            {
                "value": 11948,
                "label": "Murdock",
                "label_ar": "موردوك",
                "label_fr": "Murdock"
            },
            {
                "value": 11949,
                "label": "Murfreesboro",
                "label_ar": "مورفريسبورو",
                "label_fr": "Murfreesboro"
            },
            {
                "value": 11950,
                "label": "Murphy",
                "label_ar": "مورفي",
                "label_fr": "Murphy"
            },
            {
                "value": 11951,
                "label": "Murphys",
                "label_ar": "مورفس",
                "label_fr": "Murphys"
            },
            {
                "value": 11952,
                "label": "Murphysboro",
                "label_ar": "مورفيسبورو",
                "label_fr": "Murphysboro"
            },
            {
                "value": 11953,
                "label": "Murray",
                "label_ar": "موراي",
                "label_fr": "Murray"
            },
            {
                "value": 11954,
                "label": "Murrayville",
                "label_ar": "مورايفيل",
                "label_fr": "Murrayville"
            },
            {
                "value": 11955,
                "label": "Murrells Inlet",
                "label_ar": "موريلز مدخل",
                "label_fr": "Entrée Murrells"
            },
            {
                "value": 11956,
                "label": "Murrieta",
                "label_ar": "موريتا",
                "label_fr": "Murrieta"
            },
            {
                "value": 11957,
                "label": "Murrysville",
                "label_ar": "موريسفيل",
                "label_fr": "Murrysville"
            },
            {
                "value": 11958,
                "label": "Muscatine",
                "label_ar": "موسكاتين",
                "label_fr": "Muscatine"
            },
            {
                "value": 11959,
                "label": "Muscle Shoals",
                "label_ar": "مسل شولز",
                "label_fr": "Bancs musculaires"
            },
            {
                "value": 11960,
                "label": "Muscoda",
                "label_ar": "مسكودا",
                "label_fr": "Muscoda"
            },
            {
                "value": 11961,
                "label": "Muse",
                "label_ar": "تأمل",
                "label_fr": "Muse"
            },
            {
                "value": 11962,
                "label": "Musella",
                "label_ar": "موسيلا",
                "label_fr": "Musella"
            },
            {
                "value": 11963,
                "label": "Muskego",
                "label_ar": "Muskego",
                "label_fr": "Muskego"
            },
            {
                "value": 11964,
                "label": "Muskegon",
                "label_ar": "مسكيجون",
                "label_fr": "Muskegon"
            },
            {
                "value": 11965,
                "label": "Muskogee",
                "label_ar": "موسكوجي",
                "label_fr": "Muskogee"
            },
            {
                "value": 11966,
                "label": "Mustang",
                "label_ar": "موستانج",
                "label_fr": "Mustang"
            },
            {
                "value": 11967,
                "label": "Mutual",
                "label_ar": "متبادل",
                "label_fr": "Mutuel"
            },
            {
                "value": 11968,
                "label": "Myakka City",
                "label_ar": "مدينة مياكا",
                "label_fr": "Myakka City"
            },
            {
                "value": 11969,
                "label": "Myerstown",
                "label_ar": "مايرستاون",
                "label_fr": "Myerstown"
            },
            {
                "value": 11970,
                "label": "Myersville",
                "label_ar": "مايرزفيل",
                "label_fr": "Myersville"
            },
            {
                "value": 11971,
                "label": "Mylo",
                "label_ar": "ميلو",
                "label_fr": "Mylo"
            },
            {
                "value": 11972,
                "label": "Myrtle",
                "label_ar": "ميرتل",
                "label_fr": "Myrte"
            },
            {
                "value": 11973,
                "label": "Myrtle Beach",
                "label_ar": "ميرتل بيتش",
                "label_fr": "Myrtle Beach"
            },
            {
                "value": 11974,
                "label": "Myrtle Creek",
                "label_ar": "ميرتل كريك",
                "label_fr": "Myrtle Creek"
            },
            {
                "value": 11975,
                "label": "Myrtle Point",
                "label_ar": "ميرتل بوينت",
                "label_fr": "Myrtle Point"
            },
            {
                "value": 11976,
                "label": "Mystic",
                "label_ar": "صوفي",
                "label_fr": "Mystique"
            },
            {
                "value": 11977,
                "label": "Myton",
                "label_ar": "ميتون",
                "label_fr": "Myton"
            },
            {
                "value": 11978,
                "label": "N. Lawrence",
                "label_ar": "ن. لورانس",
                "label_fr": "N. Lawrence"
            },
            {
                "value": 11979,
                "label": "Na'alehu",
                "label_ar": "نعليهو",
                "label_fr": "Na'alehu"
            },
            {
                "value": 11980,
                "label": "Nabb",
                "label_ar": "نب",
                "label_fr": "Nabb"
            },
            {
                "value": 11981,
                "label": "Nabesna",
                "label_ar": "نبصنا",
                "label_fr": "Nabesna"
            },
            {
                "value": 11982,
                "label": "Naco",
                "label_ar": "ناكو",
                "label_fr": "Naco"
            },
            {
                "value": 11983,
                "label": "Nacogdoches",
                "label_ar": "Nacogdoches",
                "label_fr": "Nacogdoches"
            },
            {
                "value": 11984,
                "label": "Nacoochee",
                "label_ar": "ناكوشي",
                "label_fr": "Nacoochee"
            },
            {
                "value": 11985,
                "label": "Nageezi",
                "label_ar": "ناجيزي",
                "label_fr": "Nageezi"
            },
            {
                "value": 11986,
                "label": "Nags Head",
                "label_ar": "ناجز هيد",
                "label_fr": "Tête de Nags"
            },
            {
                "value": 11987,
                "label": "Nahant",
                "label_ar": "ناهانت",
                "label_fr": "Nahant"
            },
            {
                "value": 11988,
                "label": "Nahunta",
                "label_ar": "ناهونتا",
                "label_fr": "Nahunta"
            },
            {
                "value": 11989,
                "label": "Naknek",
                "label_ar": "نكنك",
                "label_fr": "Naknek"
            },
            {
                "value": 11990,
                "label": "Nampa",
                "label_ar": "نامبا",
                "label_fr": "Nampa"
            },
            {
                "value": 11991,
                "label": "Nancy",
                "label_ar": "نانسي",
                "label_fr": "Nancy"
            },
            {
                "value": 11992,
                "label": "Nanjemoy",
                "label_ar": "نانجموي",
                "label_fr": "Nanjemoy"
            },
            {
                "value": 11993,
                "label": "Nanticoke",
                "label_ar": "نانتيكوك",
                "label_fr": "Nanticoke"
            },
            {
                "value": 11994,
                "label": "Nantucket",
                "label_ar": "نانتوكيت",
                "label_fr": "Nantucket"
            },
            {
                "value": 11995,
                "label": "Nanty Glo",
                "label_ar": "نانتي جلو",
                "label_fr": "Nanty Glo"
            },
            {
                "value": 11996,
                "label": "Nanuet",
                "label_ar": "نانويت",
                "label_fr": "Nanuet"
            },
            {
                "value": 11997,
                "label": "Naoma",
                "label_ar": "نعوما",
                "label_fr": "Naoma"
            },
            {
                "value": 11998,
                "label": "Napa",
                "label_ar": "نابا",
                "label_fr": "Napa"
            },
            {
                "value": 11999,
                "label": "Napavine",
                "label_ar": "نابافين",
                "label_fr": "Napavine"
            },
            {
                "value": 12000,
                "label": "Naper",
                "label_ar": "نابر",
                "label_fr": "Naper"
            },
            {
                "value": 12001,
                "label": "Naperville",
                "label_ar": "نابرفيل",
                "label_fr": "Naperville"
            },
            {
                "value": 12002,
                "label": "Napier",
                "label_ar": "نابير",
                "label_fr": "Napier"
            },
            {
                "value": 12003,
                "label": "Naples",
                "label_ar": "نابولي",
                "label_fr": "Naples"
            },
            {
                "value": 12004,
                "label": "Napoleon",
                "label_ar": "نابليون",
                "label_fr": "Napoléon"
            },
            {
                "value": 12005,
                "label": "Napoleonville",
                "label_ar": "نابليونفيل",
                "label_fr": "Napoléonville"
            },
            {
                "value": 12006,
                "label": "Nappanee",
                "label_ar": "ناباني",
                "label_fr": "Nappanee"
            },
            {
                "value": 12007,
                "label": "Nara Visa",
                "label_ar": "تأشيرة نارا",
                "label_fr": "Visa Nara"
            },
            {
                "value": 12008,
                "label": "Narberth",
                "label_ar": "ناربيرث",
                "label_fr": "Narberth"
            },
            {
                "value": 12009,
                "label": "Narka",
                "label_ar": "النركا",
                "label_fr": "Narka"
            },
            {
                "value": 12010,
                "label": "Narragansett",
                "label_ar": "ناراغانسيت",
                "label_fr": "Narragansett"
            },
            {
                "value": 12011,
                "label": "Narrows",
                "label_ar": "يضيق",
                "label_fr": "Narrows"
            },
            {
                "value": 12012,
                "label": "Narrowsburg",
                "label_ar": "ناروسبرج",
                "label_fr": "Narrowsburg"
            },
            {
                "value": 12013,
                "label": "Narvon",
                "label_ar": "نارفون",
                "label_fr": "Narvon"
            },
            {
                "value": 12014,
                "label": "Naselle",
                "label_ar": "ناسيل",
                "label_fr": "Naselle"
            },
            {
                "value": 12015,
                "label": "Nash",
                "label_ar": "ناش",
                "label_fr": "Nash"
            },
            {
                "value": 12016,
                "label": "Nashoba",
                "label_ar": "ناشوبا",
                "label_fr": "Nashoba"
            },
            {
                "value": 12017,
                "label": "Nashotah",
                "label_ar": "نشوة",
                "label_fr": "Nashotah"
            },
            {
                "value": 12018,
                "label": "Nashport",
                "label_ar": "ناشبورت",
                "label_fr": "Nashport"
            },
            {
                "value": 12019,
                "label": "Nashua",
                "label_ar": "ناشوا",
                "label_fr": "Nashua"
            },
            {
                "value": 12020,
                "label": "Nashville",
                "label_ar": "ناشفيل",
                "label_fr": "Nashville"
            },
            {
                "value": 12021,
                "label": "Nashwauk",
                "label_ar": "ناشوك",
                "label_fr": "Nashwauk"
            },
            {
                "value": 12022,
                "label": "Nassau",
                "label_ar": "ناسو",
                "label_fr": "Nassau"
            },
            {
                "value": 12023,
                "label": "Nassawadox",
                "label_ar": "ناسوادوكس",
                "label_fr": "Nassawadox"
            },
            {
                "value": 12024,
                "label": "Natalbany",
                "label_ar": "ناتالباني",
                "label_fr": "Natalbany"
            },
            {
                "value": 12025,
                "label": "Natalia",
                "label_ar": "ناتاليا",
                "label_fr": "Natalia"
            },
            {
                "value": 12026,
                "label": "Natchez",
                "label_ar": "ناتشيز",
                "label_fr": "Natchez"
            },
            {
                "value": 12027,
                "label": "Natchitoches",
                "label_ar": "ناتشيتوتش",
                "label_fr": "Natchitoches"
            },
            {
                "value": 12028,
                "label": "Natick",
                "label_ar": "ناتيك",
                "label_fr": "Natick"
            },
            {
                "value": 12029,
                "label": "National City",
                "label_ar": "المدينة الوطنية",
                "label_fr": "Ville nationale"
            },
            {
                "value": 12030,
                "label": "National Park",
                "label_ar": "متنزه قومي",
                "label_fr": "parc national"
            },
            {
                "value": 12031,
                "label": "Natrona Heights",
                "label_ar": "ناترونا هايتس",
                "label_fr": "Natrona Heights"
            },
            {
                "value": 12032,
                "label": "Natural Bridge",
                "label_ar": "الجسر الطبيعي",
                "label_fr": "Pont naturel"
            },
            {
                "value": 12033,
                "label": "Natural Bridge Station",
                "label_ar": "محطة الجسر الطبيعي",
                "label_fr": "Station de pont naturel"
            },
            {
                "value": 12034,
                "label": "Naturita",
                "label_ar": "ناتوريتا",
                "label_fr": "Naturita"
            },
            {
                "value": 12035,
                "label": "Naubinway",
                "label_ar": "Naubinway",
                "label_fr": "Naubinway"
            },
            {
                "value": 12036,
                "label": "Naugatuck",
                "label_ar": "Naugatuck",
                "label_fr": "Naugatuck"
            },
            {
                "value": 12037,
                "label": "Nauvoo",
                "label_ar": "Nauvoo",
                "label_fr": "Nauvoo"
            },
            {
                "value": 12038,
                "label": "Navajo",
                "label_ar": "نافاجو",
                "label_fr": "Navajo"
            },
            {
                "value": 12039,
                "label": "Navarre",
                "label_ar": "نافارا",
                "label_fr": "Navarre"
            },
            {
                "value": 12040,
                "label": "Navasota",
                "label_ar": "نافاسوتا",
                "label_fr": "Navasota"
            },
            {
                "value": 12041,
                "label": "Naylor",
                "label_ar": "نايلور",
                "label_fr": "Naylor"
            },
            {
                "value": 12042,
                "label": "Nazan Village",
                "label_ar": "قرية نازان",
                "label_fr": "Village de Nazan"
            },
            {
                "value": 12043,
                "label": "Nazareth",
                "label_ar": "الناصرة",
                "label_fr": "Nazareth"
            },
            {
                "value": 12044,
                "label": "Neah Bay",
                "label_ar": "نيه باي",
                "label_fr": "Neah Bay"
            },
            {
                "value": 12045,
                "label": "Nebo",
                "label_ar": "نيبو",
                "label_fr": "Nébo"
            },
            {
                "value": 12046,
                "label": "Nebraska City",
                "label_ar": "مدينة نبراسكا",
                "label_fr": "Nebraska City"
            },
            {
                "value": 12047,
                "label": "Necedah",
                "label_ar": "نجدة",
                "label_fr": "Necedah"
            },
            {
                "value": 12048,
                "label": "Neche",
                "label_ar": "نيك",
                "label_fr": "Neche"
            },
            {
                "value": 12049,
                "label": "Nederland",
                "label_ar": "Nederland",
                "label_fr": "Nederland"
            },
            {
                "value": 12050,
                "label": "Nedrow",
                "label_ar": "نيدرو",
                "label_fr": "Nedrow"
            },
            {
                "value": 12051,
                "label": "Needham",
                "label_ar": "نيدهام",
                "label_fr": "Needham"
            },
            {
                "value": 12052,
                "label": "Needham Heights",
                "label_ar": "نيدهام هايتس",
                "label_fr": "Needham Heights"
            },
            {
                "value": 12053,
                "label": "Needles",
                "label_ar": "الإبر",
                "label_fr": "Aiguilles"
            },
            {
                "value": 12054,
                "label": "Needmore",
                "label_ar": "أحتاج المزيد",
                "label_fr": "Besoin de plus"
            },
            {
                "value": 12055,
                "label": "Needville",
                "label_ar": "نيدفيل",
                "label_fr": "Needville"
            },
            {
                "value": 12056,
                "label": "Neely",
                "label_ar": "نيلي",
                "label_fr": "Neely"
            },
            {
                "value": 12057,
                "label": "Neelyville",
                "label_ar": "نيليفيل",
                "label_fr": "Neelyville"
            },
            {
                "value": 12058,
                "label": "Neenah",
                "label_ar": "نينه",
                "label_fr": "Neenah"
            },
            {
                "value": 12059,
                "label": "Neeses",
                "label_ar": "نيسيس",
                "label_fr": "Neeses"
            },
            {
                "value": 12060,
                "label": "Neffs",
                "label_ar": "نيفس",
                "label_fr": "Neffs"
            },
            {
                "value": 12061,
                "label": "Negaunee",
                "label_ar": "نيجوني",
                "label_fr": "Negaunee"
            },
            {
                "value": 12062,
                "label": "Negley",
                "label_ar": "نيجلي",
                "label_fr": "Negley"
            },
            {
                "value": 12063,
                "label": "Nehalem",
                "label_ar": "نيحالم",
                "label_fr": "Nehalem"
            },
            {
                "value": 12064,
                "label": "Neillsville",
                "label_ar": "نيلسفيل",
                "label_fr": "Neillsville"
            },
            {
                "value": 12065,
                "label": "Nekoosa",
                "label_ar": "نيكوسا",
                "label_fr": "Nekoosa"
            },
            {
                "value": 12066,
                "label": "Neligh",
                "label_ar": "نيلي",
                "label_fr": "Neligh"
            },
            {
                "value": 12067,
                "label": "Nellis",
                "label_ar": "نيليس",
                "label_fr": "Nellis"
            },
            {
                "value": 12068,
                "label": "Nellysford",
                "label_ar": "نيليسفورد",
                "label_fr": "Nellysford"
            },
            {
                "value": 12069,
                "label": "Nelson",
                "label_ar": "نيلسون",
                "label_fr": "Nelson"
            },
            {
                "value": 12070,
                "label": "Nelsonville",
                "label_ar": "نيلسونفيل",
                "label_fr": "Nelsonville"
            },
            {
                "value": 12071,
                "label": "Nemacolin",
                "label_ar": "نيماكولين",
                "label_fr": "Némacoline"
            },
            {
                "value": 12072,
                "label": "Nemaha",
                "label_ar": "نعمة",
                "label_fr": "Nemaha"
            },
            {
                "value": 12073,
                "label": "Nemo",
                "label_ar": "نيمو",
                "label_fr": "Nemo"
            },
            {
                "value": 12074,
                "label": "Nenana",
                "label_ar": "نينانا",
                "label_fr": "Nenana"
            },
            {
                "value": 12075,
                "label": "Neodesha",
                "label_ar": "نيوديشا",
                "label_fr": "Néodesha"
            },
            {
                "value": 12076,
                "label": "Neoga",
                "label_ar": "نيوجا",
                "label_fr": "Neoga"
            },
            {
                "value": 12077,
                "label": "Neola",
                "label_ar": "نيولا",
                "label_fr": "Neola"
            },
            {
                "value": 12078,
                "label": "Neosho",
                "label_ar": "نيوشو",
                "label_fr": "Neosho"
            },
            {
                "value": 12079,
                "label": "Neosho Falls",
                "label_ar": "شلالات نيوشو",
                "label_fr": "Neosho Falls"
            },
            {
                "value": 12080,
                "label": "Neosho Rapids",
                "label_ar": "نيوشو رابيدز",
                "label_fr": "Rapides de Neosho"
            },
            {
                "value": 12081,
                "label": "Neotsu",
                "label_ar": "نيوتسو",
                "label_fr": "Neotsu"
            },
            {
                "value": 12082,
                "label": "Nephi",
                "label_ar": "نافي",
                "label_fr": "Néphi"
            },
            {
                "value": 12083,
                "label": "Neponset",
                "label_ar": "نيبونسيت",
                "label_fr": "Neponset"
            },
            {
                "value": 12084,
                "label": "Neptune Beach",
                "label_ar": "نبتون بيتش",
                "label_fr": "Plage de Neptune"
            },
            {
                "value": 12085,
                "label": "Neptune City",
                "label_ar": "مدينة نبتون",
                "label_fr": "Neptune City"
            },
            {
                "value": 12086,
                "label": "Nerstrand",
                "label_ar": "نيرستراند",
                "label_fr": "Nerstrand"
            },
            {
                "value": 12087,
                "label": "Nesbit",
                "label_ar": "نسبيت",
                "label_fr": "Nesbit"
            },
            {
                "value": 12088,
                "label": "Nesconset",
                "label_ar": "Nesconset",
                "label_fr": "Nesconset"
            },
            {
                "value": 12089,
                "label": "Nescopeck",
                "label_ar": "نسكوبك",
                "label_fr": "Nescopeck"
            },
            {
                "value": 12090,
                "label": "Neshanic Station",
                "label_ar": "محطة نيشانيك",
                "label_fr": "Gare de Neshanic"
            },
            {
                "value": 12091,
                "label": "Neshkoro",
                "label_ar": "نيشكورو",
                "label_fr": "Neshkoro"
            },
            {
                "value": 12092,
                "label": "Nesquehoning",
                "label_ar": "Nesquehoning",
                "label_fr": "Nesquehoning"
            },
            {
                "value": 12093,
                "label": "Ness City",
                "label_ar": "مدينة نيس",
                "label_fr": "Ness City"
            },
            {
                "value": 12094,
                "label": "Nester",
                "label_ar": "نيستر",
                "label_fr": "Nester"
            },
            {
                "value": 12095,
                "label": "Netawaka",
                "label_ar": "نيتاواكا",
                "label_fr": "Netawaka"
            },
            {
                "value": 12096,
                "label": "Netcong",
                "label_ar": "نتكونغ",
                "label_fr": "Netcong"
            },
            {
                "value": 12097,
                "label": "Nettleton",
                "label_ar": "نيتلتون",
                "label_fr": "Nettleton"
            },
            {
                "value": 12098,
                "label": "Nevada",
                "label_ar": "نيفادا",
                "label_fr": "Nevada"
            },
            {
                "value": 12099,
                "label": "Nevada City",
                "label_ar": "مدينة نيفادا",
                "label_fr": "Nevada City"
            },
            {
                "value": 12100,
                "label": "Neversink",
                "label_ar": "نيفرسينك",
                "label_fr": "Neversink"
            },
            {
                "value": 12101,
                "label": "Nevis",
                "label_ar": "نيفيس",
                "label_fr": "Nevis"
            },
            {
                "value": 12102,
                "label": "New Albany",
                "label_ar": "نيو ألباني",
                "label_fr": "New Albany"
            },
            {
                "value": 12103,
                "label": "New Albin",
                "label_ar": "ألبين الجديد",
                "label_fr": "New Albin"
            },
            {
                "value": 12104,
                "label": "New Alexandria",
                "label_ar": "اسكندرية الجديدة",
                "label_fr": "Nouvelle Alexandrie"
            },
            {
                "value": 12105,
                "label": "New Almaden",
                "label_ar": "المادين الجديدة",
                "label_fr": "New Almaden"
            },
            {
                "value": 12106,
                "label": "New Athens",
                "label_ar": "أثينا الجديدة",
                "label_fr": "Nouvelle Athènes"
            },
            {
                "value": 12107,
                "label": "New Auburn",
                "label_ar": "نيو أوبورن",
                "label_fr": "New Auburn"
            },
            {
                "value": 12108,
                "label": "New Baden",
                "label_ar": "بادن الجديدة",
                "label_fr": "Nouveau Baden"
            },
            {
                "value": 12109,
                "label": "New Baltimore",
                "label_ar": "بالتيمور الجديدة",
                "label_fr": "Nouveau Baltimore"
            },
            {
                "value": 12110,
                "label": "New Bavaria",
                "label_ar": "بافاريا الجديدة",
                "label_fr": "Nouvelle Bavière"
            },
            {
                "value": 12111,
                "label": "New Bedford",
                "label_ar": "بيدفورد الجديدة",
                "label_fr": "New Bedford"
            },
            {
                "value": 12112,
                "label": "New Berlin",
                "label_ar": "برلين الجديدة",
                "label_fr": "Nouveau Berlin"
            },
            {
                "value": 12113,
                "label": "New Berlinville",
                "label_ar": "برلينفيل الجديدة",
                "label_fr": "New Berlinville"
            },
            {
                "value": 12114,
                "label": "New Bern",
                "label_ar": "نيو برن",
                "label_fr": "Nouvelle Berne"
            },
            {
                "value": 12115,
                "label": "New Bethlehem",
                "label_ar": "بيت لحم الجديدة",
                "label_fr": "New Bethléem"
            },
            {
                "value": 12116,
                "label": "New Blaine",
                "label_ar": "نيو بلين",
                "label_fr": "Nouveau Blaine"
            },
            {
                "value": 12117,
                "label": "New Bloomfield",
                "label_ar": "نيو بلومفيلد",
                "label_fr": "Nouveau Bloomfield"
            },
            {
                "value": 12118,
                "label": "New Boston",
                "label_ar": "نيو بوسطن",
                "label_fr": "New Boston"
            },
            {
                "value": 12119,
                "label": "New Braintree",
                "label_ar": "جديد Braintree",
                "label_fr": "Nouveau Braintree"
            },
            {
                "value": 12120,
                "label": "New Braunfels",
                "label_ar": "جديد Braunfels",
                "label_fr": "New Braunfels"
            },
            {
                "value": 12121,
                "label": "New Bremen",
                "label_ar": "بريمن الجديدة",
                "label_fr": "Nouveau Brême"
            },
            {
                "value": 12122,
                "label": "New Brighton",
                "label_ar": "نيو برايتون",
                "label_fr": "New Brighton"
            },
            {
                "value": 12123,
                "label": "New Britain",
                "label_ar": "بريطانيا الجديدة",
                "label_fr": "Nouvelle Bretagne"
            },
            {
                "value": 12124,
                "label": "New Brockton",
                "label_ar": "نيو بروكتون",
                "label_fr": "Nouveau Brockton"
            },
            {
                "value": 12125,
                "label": "New Brunswick",
                "label_ar": "برونزيك جديد",
                "label_fr": "Nouveau-Brunswick"
            },
            {
                "value": 12126,
                "label": "New Buffalo",
                "label_ar": "نيو بوفالو",
                "label_fr": "Nouveau Buffalo"
            },
            {
                "value": 12127,
                "label": "New Burnside",
                "label_ar": "نيو برنسايد",
                "label_fr": "Nouveau Burnside"
            },
            {
                "value": 12128,
                "label": "New Cambria",
                "label_ar": "كامبريا الجديدة",
                "label_fr": "Nouveau Cambria"
            },
            {
                "value": 12129,
                "label": "New Canaan",
                "label_ar": "كنعان الجديدة",
                "label_fr": "New Canaan"
            },
            {
                "value": 12130,
                "label": "New Caney",
                "label_ar": "نيو كاني",
                "label_fr": "New Caney"
            },
            {
                "value": 12131,
                "label": "New Canton",
                "label_ar": "كانتون جديدة",
                "label_fr": "Nouveau canton"
            },
            {
                "value": 12132,
                "label": "New Carlisle",
                "label_ar": "كارلايل الجديدة",
                "label_fr": "New Carlisle"
            },
            {
                "value": 12133,
                "label": "New Castle",
                "label_ar": "نيو كاسل",
                "label_fr": "Nouveau château"
            },
            {
                "value": 12134,
                "label": "New Church",
                "label_ar": "الكنيسة الجديدة",
                "label_fr": "Nouvelle église"
            },
            {
                "value": 12135,
                "label": "New City",
                "label_ar": "مدينة جديدة",
                "label_fr": "Nouvelle ville"
            },
            {
                "value": 12136,
                "label": "New Columbia",
                "label_ar": "نيو كولومبيا",
                "label_fr": "New Columbia"
            },
            {
                "value": 12137,
                "label": "New Concord",
                "label_ar": "كونكورد جديد",
                "label_fr": "Nouveau Concord"
            },
            {
                "value": 12138,
                "label": "New Creek",
                "label_ar": "نيو كريك",
                "label_fr": "New Creek"
            },
            {
                "value": 12139,
                "label": "New Cumberland",
                "label_ar": "كمبرلاند الجديدة",
                "label_fr": "Nouveau Cumberland"
            },
            {
                "value": 12140,
                "label": "New Cuyama",
                "label_ar": "نيو كوياما",
                "label_fr": "Nouveau Cuyama"
            },
            {
                "value": 12141,
                "label": "New Deal",
                "label_ar": "صفقة جديدة",
                "label_fr": "Nouvelle affaire"
            },
            {
                "value": 12142,
                "label": "New Derry",
                "label_ar": "نيو ديري",
                "label_fr": "New Derry"
            },
            {
                "value": 12143,
                "label": "New Douglas",
                "label_ar": "نيو دوغلاس",
                "label_fr": "Nouveau Douglas"
            },
            {
                "value": 12144,
                "label": "New Durham",
                "label_ar": "دورهام الجديدة",
                "label_fr": "New Durham"
            },
            {
                "value": 12145,
                "label": "New Eagle",
                "label_ar": "النسر الجديد",
                "label_fr": "Nouvel aigle"
            },
            {
                "value": 12146,
                "label": "New Effington",
                "label_ar": "ايفينجتون الجديدة",
                "label_fr": "New Effington"
            },
            {
                "value": 12147,
                "label": "New Egypt",
                "label_ar": "مصر الجديدة",
                "label_fr": "Nouvelle Egypte"
            },
            {
                "value": 12148,
                "label": "New England",
                "label_ar": "بريطانيا الجديدة",
                "label_fr": "Nouvelle-Angleterre"
            },
            {
                "value": 12149,
                "label": "New Enterprise",
                "label_ar": "مشروع جديد",
                "label_fr": "Nouvelle entreprise"
            },
            {
                "value": 12150,
                "label": "New Era",
                "label_ar": "عهد جديد",
                "label_fr": "Nouvelle ère"
            },
            {
                "value": 12151,
                "label": "New Fairfield",
                "label_ar": "فيرفيلد الجديدة",
                "label_fr": "New Fairfield"
            },
            {
                "value": 12152,
                "label": "New Florence",
                "label_ar": "فلورنسا الجديدة",
                "label_fr": "Nouvelle Florence"
            },
            {
                "value": 12153,
                "label": "New Franken",
                "label_ar": "نيو فرانكن",
                "label_fr": "Nouveau Franken"
            },
            {
                "value": 12154,
                "label": "New Franklin",
                "label_ar": "فرانكلين الجديد",
                "label_fr": "New Franklin"
            },
            {
                "value": 12155,
                "label": "New Freedom",
                "label_ar": "الحرية الجديدة",
                "label_fr": "Nouvelle liberté"
            },
            {
                "value": 12156,
                "label": "New Galilee",
                "label_ar": "الجليل الجديد",
                "label_fr": "Nouvelle Galilée"
            },
            {
                "value": 12157,
                "label": "New Germany",
                "label_ar": "ألمانيا الجديدة",
                "label_fr": "Nouvelle Allemagne"
            },
            {
                "value": 12158,
                "label": "New Glarus",
                "label_ar": "نيو جلاروس",
                "label_fr": "Nouveau Glaris"
            },
            {
                "value": 12159,
                "label": "New Gloucester",
                "label_ar": "جديد جلوستر",
                "label_fr": "Nouveau Gloucester"
            },
            {
                "value": 12160,
                "label": "New Goshen",
                "label_ar": "جاسان الجديد",
                "label_fr": "Nouveau Goshen"
            },
            {
                "value": 12161,
                "label": "New Hampton",
                "label_ar": "نيو هامبتون",
                "label_fr": "New Hampton"
            },
            {
                "value": 12162,
                "label": "New Harbor",
                "label_ar": "نيو هاربور",
                "label_fr": "Nouveau port"
            },
            {
                "value": 12163,
                "label": "New Harmony",
                "label_ar": "الوئام الجديد",
                "label_fr": "Nouvelle harmonie"
            },
            {
                "value": 12164,
                "label": "New Hartford",
                "label_ar": "نيو هارتفورد",
                "label_fr": "New Hartford"
            },
            {
                "value": 12165,
                "label": "New Haven",
                "label_ar": "ملاذ جديد",
                "label_fr": "New Haven"
            },
            {
                "value": 12166,
                "label": "New Hebron",
                "label_ar": "الخليل الجديدة",
                "label_fr": "New Hebron"
            },
            {
                "value": 12167,
                "label": "New Hill",
                "label_ar": "نيو هيل",
                "label_fr": "New Hill"
            },
            {
                "value": 12168,
                "label": "New Holland",
                "label_ar": "نيو هولاند",
                "label_fr": "New Holland"
            },
            {
                "value": 12169,
                "label": "New Holstein",
                "label_ar": "نيو هولشتاين",
                "label_fr": "Nouveau Holstein"
            },
            {
                "value": 12170,
                "label": "New Hope",
                "label_ar": "أمل جديد",
                "label_fr": "Un nouvel espoir"
            },
            {
                "value": 12171,
                "label": "New Hudson",
                "label_ar": "نيو هدسون",
                "label_fr": "New Hudson"
            },
            {
                "value": 12172,
                "label": "New Hyde Park",
                "label_ar": "نيو هايد بارك",
                "label_fr": "New Hyde Park"
            },
            {
                "value": 12173,
                "label": "New Iberia",
                "label_ar": "أيبيريا الجديدة",
                "label_fr": "Nouvelle Iberia"
            },
            {
                "value": 12174,
                "label": "New Ipswich",
                "label_ar": "جديد إبسويتش",
                "label_fr": "New Ipswich"
            },
            {
                "value": 12175,
                "label": "New Johnsonville",
                "label_ar": "نيو جونسونفيل",
                "label_fr": "New Johnsonville"
            },
            {
                "value": 12176,
                "label": "New Kensington",
                "label_ar": "كنسينغتون الجديدة",
                "label_fr": "New Kensington"
            },
            {
                "value": 12177,
                "label": "New Kent",
                "label_ar": "كينت الجديدة",
                "label_fr": "Nouveau Kent"
            },
            {
                "value": 12178,
                "label": "New Knoxville",
                "label_ar": "نوكسفيل الجديدة",
                "label_fr": "New Knoxville"
            },
            {
                "value": 12179,
                "label": "New Laguna",
                "label_ar": "لاجونا الجديدة",
                "label_fr": "Nouvelle Laguna"
            },
            {
                "value": 12180,
                "label": "New Lebanon",
                "label_ar": "لبنان الجديد",
                "label_fr": "Nouveau Liban"
            },
            {
                "value": 12181,
                "label": "New Lenox",
                "label_ar": "لينوكس الجديدة",
                "label_fr": "Nouveau Lenox"
            },
            {
                "value": 12182,
                "label": "New Lexington",
                "label_ar": "ليكسينغتون الجديدة",
                "label_fr": "Nouveau Lexington"
            },
            {
                "value": 12183,
                "label": "New Liberty",
                "label_ar": "نيو ليبرتي",
                "label_fr": "Nouvelle liberté"
            },
            {
                "value": 12184,
                "label": "New Lisbon",
                "label_ar": "لشبونة الجديدة",
                "label_fr": "Nouveau Lisbonne"
            },
            {
                "value": 12185,
                "label": "New Llano",
                "label_ar": "نيو لانو",
                "label_fr": "Nouveau Llano"
            },
            {
                "value": 12186,
                "label": "New London",
                "label_ar": "لندن الجديدة",
                "label_fr": "New London"
            },
            {
                "value": 12187,
                "label": "New Lothrop",
                "label_ar": "لوثروب الجديدة",
                "label_fr": "Nouveau Lothrop"
            },
            {
                "value": 12188,
                "label": "New Madrid",
                "label_ar": "مدريد الجديدة",
                "label_fr": "Nouveau Madrid"
            },
            {
                "value": 12189,
                "label": "New Market",
                "label_ar": "سوق جديد",
                "label_fr": "Nouveau marché"
            },
            {
                "value": 12190,
                "label": "New Marshfield",
                "label_ar": "مارشفيلد الجديدة",
                "label_fr": "Nouveau Marshfield"
            },
            {
                "value": 12191,
                "label": "New Martinsville",
                "label_ar": "نيو مارتينسفيل",
                "label_fr": "New Martinsville"
            },
            {
                "value": 12192,
                "label": "New Meadows",
                "label_ar": "المروج الجديدة",
                "label_fr": "New Meadows"
            },
            {
                "value": 12193,
                "label": "New Memphis",
                "label_ar": "ممفيس الجديدة",
                "label_fr": "Nouveau Memphis"
            },
            {
                "value": 12194,
                "label": "New Middletown",
                "label_ar": "ميدلتاون الجديدة",
                "label_fr": "New Middletown"
            },
            {
                "value": 12195,
                "label": "New Milford",
                "label_ar": "نيو ميلفورد",
                "label_fr": "New Milford"
            },
            {
                "value": 12196,
                "label": "New Orleans",
                "label_ar": "نيو أورليانز",
                "label_fr": "La Nouvelle Orléans"
            },
            {
                "value": 12197,
                "label": "New Oxford",
                "label_ar": "أكسفورد الجديدة",
                "label_fr": "New Oxford"
            },
            {
                "value": 12198,
                "label": "New Palestine",
                "label_ar": "فلسطين الجديدة",
                "label_fr": "Nouvelle Palestine"
            },
            {
                "value": 12199,
                "label": "New Paltz",
                "label_ar": "نيو بالتز",
                "label_fr": "Nouveau Paltz"
            },
            {
                "value": 12200,
                "label": "New Paris",
                "label_ar": "باريس الجديدة",
                "label_fr": "Nouveau Paris"
            },
            {
                "value": 12201,
                "label": "New Park",
                "label_ar": "نيو بارك",
                "label_fr": "Nouveau parc"
            },
            {
                "value": 12202,
                "label": "New Philadelphia",
                "label_ar": "فيلادلفيا الجديدة",
                "label_fr": "New Philadelphie"
            },
            {
                "value": 12203,
                "label": "New Plymouth",
                "label_ar": "نيو بليموث",
                "label_fr": "New Plymouth"
            },
            {
                "value": 12204,
                "label": "New Point",
                "label_ar": "نقطة جديدة",
                "label_fr": "Nouveau point"
            },
            {
                "value": 12205,
                "label": "New Port Richey",
                "label_ar": "نيو بورت ريتشي",
                "label_fr": "Nouveau Port Richey"
            },
            {
                "value": 12206,
                "label": "New Portland",
                "label_ar": "نيو بورتلاند",
                "label_fr": "New Portland"
            },
            {
                "value": 12207,
                "label": "New Prague",
                "label_ar": "نيو براغ",
                "label_fr": "Nouvelle Prague"
            },
            {
                "value": 12208,
                "label": "New Preston",
                "label_ar": "بريستون الجديدة",
                "label_fr": "Nouveau Preston"
            },
            {
                "value": 12209,
                "label": "New Providence",
                "label_ar": "بروفيدانس الجديدة",
                "label_fr": "Nouvelle Providence"
            },
            {
                "value": 12210,
                "label": "New Raymer",
                "label_ar": "نيو رايمر",
                "label_fr": "Nouveau Raymer"
            },
            {
                "value": 12211,
                "label": "New Richland",
                "label_ar": "نيو ريتشلاند",
                "label_fr": "New Richland"
            },
            {
                "value": 12212,
                "label": "New Richmond",
                "label_ar": "نيو ريتشموند",
                "label_fr": "New Richmond"
            },
            {
                "value": 12213,
                "label": "New Riegel",
                "label_ar": "ريجل جديدة",
                "label_fr": "Nouveau Riegel"
            },
            {
                "value": 12214,
                "label": "New Ringgold",
                "label_ar": "رينغولد الجديد",
                "label_fr": "Nouveau Ringgold"
            },
            {
                "value": 12215,
                "label": "New River",
                "label_ar": "نهر جديد",
                "label_fr": "New River"
            },
            {
                "value": 12216,
                "label": "New Roads",
                "label_ar": "طرق جديدة",
                "label_fr": "Nouvelles routes"
            },
            {
                "value": 12217,
                "label": "New Rochelle",
                "label_ar": "روشيل الجديدة",
                "label_fr": "Nouvelle Rochelle"
            },
            {
                "value": 12218,
                "label": "New Rockford",
                "label_ar": "نيو روكفورد",
                "label_fr": "New Rockford"
            },
            {
                "value": 12219,
                "label": "New Ross",
                "label_ar": "نيو روس",
                "label_fr": "Nouveau Ross"
            },
            {
                "value": 12220,
                "label": "New Salem",
                "label_ar": "نيو سالم",
                "label_fr": "Nouveau Salem"
            },
            {
                "value": 12221,
                "label": "New Salisbury",
                "label_ar": "نيو سالزبوري",
                "label_fr": "Nouveau Salisbury"
            },
            {
                "value": 12222,
                "label": "New Sharon",
                "label_ar": "شارون الجديدة",
                "label_fr": "Nouveau Sharon"
            },
            {
                "value": 12223,
                "label": "New Smyrna Beach",
                "label_ar": "شاطئ سميرنا الجديد",
                "label_fr": "New Smyrna Beach"
            },
            {
                "value": 12224,
                "label": "New Springfield",
                "label_ar": "سبرينجفيلد الجديدة",
                "label_fr": "New Springfield"
            },
            {
                "value": 12225,
                "label": "New Stanton",
                "label_ar": "ستانتون الجديدة",
                "label_fr": "Nouveau Stanton"
            },
            {
                "value": 12226,
                "label": "New Straitsville",
                "label_ar": "نيو ستريتسفيل",
                "label_fr": "New Straitsville"
            },
            {
                "value": 12227,
                "label": "New Stuyahok",
                "label_ar": "ستوياهوك الجديدة",
                "label_fr": "Nouveau Stuyahok"
            },
            {
                "value": 12228,
                "label": "New Tazewell",
                "label_ar": "جديد Tazewell",
                "label_fr": "Nouveau Tazewell"
            },
            {
                "value": 12229,
                "label": "New Town",
                "label_ar": "بلدة جديدة",
                "label_fr": "Nouvelle ville"
            },
            {
                "value": 12230,
                "label": "New Tripoli",
                "label_ar": "طرابلس الجديدة",
                "label_fr": "Nouveau Tripoli"
            },
            {
                "value": 12231,
                "label": "New Troy",
                "label_ar": "نيو تروي",
                "label_fr": "New Troy"
            },
            {
                "value": 12232,
                "label": "New Ulm",
                "label_ar": "أولم الجديدة",
                "label_fr": "Nouveau Ulm"
            },
            {
                "value": 12233,
                "label": "New Underwood",
                "label_ar": "جديد أندروود",
                "label_fr": "New Underwood"
            },
            {
                "value": 12234,
                "label": "New Vernon",
                "label_ar": "نيو فيرنون",
                "label_fr": "Nouveau Vernon"
            },
            {
                "value": 12235,
                "label": "New Vienna",
                "label_ar": "فيينا الجديدة",
                "label_fr": "Nouvelle Vienne"
            },
            {
                "value": 12236,
                "label": "New Vineyard",
                "label_ar": "كرم جديد",
                "label_fr": "Nouveau vignoble"
            },
            {
                "value": 12237,
                "label": "New Virginia",
                "label_ar": "فيرجينيا الجديدة",
                "label_fr": "Nouvelle Virginie"
            },
            {
                "value": 12238,
                "label": "New Washington",
                "label_ar": "واشنطن الجديدة",
                "label_fr": "New Washington"
            },
            {
                "value": 12239,
                "label": "New Waterford",
                "label_ar": "نيو وترفورد",
                "label_fr": "New Waterford"
            },
            {
                "value": 12240,
                "label": "New Waverly",
                "label_ar": "جديد ويفرلي",
                "label_fr": "Nouveau Waverly"
            },
            {
                "value": 12241,
                "label": "New Weston",
                "label_ar": "نيو ويستون",
                "label_fr": "New Weston"
            },
            {
                "value": 12242,
                "label": "New Wilmington",
                "label_ar": "نيو ويلمنجتون",
                "label_fr": "New Wilmington"
            },
            {
                "value": 12243,
                "label": "New Windsor",
                "label_ar": "نيو وندسور",
                "label_fr": "New Windsor"
            },
            {
                "value": 12244,
                "label": "New Woodstock",
                "label_ar": "نيو وودستوك",
                "label_fr": "New Woodstock"
            },
            {
                "value": 12245,
                "label": "New York",
                "label_ar": "نيويورك",
                "label_fr": "New York"
            },
            {
                "value": 12246,
                "label": "New York Mills",
                "label_ar": "نيويورك ميلز",
                "label_fr": "New York Mills"
            },
            {
                "value": 12247,
                "label": "Newalla",
                "label_ar": "نيوالا",
                "label_fr": "Newalla"
            },
            {
                "value": 12248,
                "label": "Newark",
                "label_ar": "نيوارك",
                "label_fr": "Newark"
            },
            {
                "value": 12249,
                "label": "Newark Valley",
                "label_ar": "وادي نيوارك",
                "label_fr": "Vallée de Newark"
            },
            {
                "value": 12250,
                "label": "Newaygo",
                "label_ar": "نيوايجو",
                "label_fr": "Newaygo"
            },
            {
                "value": 12251,
                "label": "Newberg",
                "label_ar": "نيوبيرغ",
                "label_fr": "Newberg"
            },
            {
                "value": 12252,
                "label": "Newbern",
                "label_ar": "نيوبيرن",
                "label_fr": "Newbern"
            },
            {
                "value": 12253,
                "label": "Newberry",
                "label_ar": "نيوبيري",
                "label_fr": "Newberry"
            },
            {
                "value": 12254,
                "label": "Newberry Springs",
                "label_ar": "نيوبيري سبرينجز",
                "label_fr": "Newberry Springs"
            },
            {
                "value": 12255,
                "label": "Newborn",
                "label_ar": "مولود جديد",
                "label_fr": "Nouveau née"
            },
            {
                "value": 12256,
                "label": "Newburg",
                "label_ar": "نيوبورج",
                "label_fr": "Newburg"
            },
            {
                "value": 12257,
                "label": "Newburgh",
                "label_ar": "نيوبورج",
                "label_fr": "Newburgh"
            },
            {
                "value": 12258,
                "label": "Newbury",
                "label_ar": "نيوبري",
                "label_fr": "Newbury"
            },
            {
                "value": 12259,
                "label": "Newbury Center",
                "label_ar": "مركز نيوبري",
                "label_fr": "Centre de Newbury"
            },
            {
                "value": 12260,
                "label": "Newbury Old Town",
                "label_ar": "مدينة نيوبري القديمة",
                "label_fr": "Vieille ville de Newbury"
            },
            {
                "value": 12261,
                "label": "Newbury Park",
                "label_ar": "نيوبري بارك",
                "label_fr": "Newbury Park"
            },
            {
                "value": 12262,
                "label": "Newburyport",
                "label_ar": "نيوبريبورت",
                "label_fr": "Newburyport"
            },
            {
                "value": 12263,
                "label": "Newcastle",
                "label_ar": "نيوكاسل",
                "label_fr": "Newcastle"
            },
            {
                "value": 12264,
                "label": "Newcomb",
                "label_ar": "نيوكومب",
                "label_fr": "Newcomb"
            },
            {
                "value": 12265,
                "label": "Newcomerstown",
                "label_ar": "الوافد الجديد",
                "label_fr": "Newcomerstown"
            },
            {
                "value": 12266,
                "label": "Newell",
                "label_ar": "نيويل",
                "label_fr": "Newell"
            },
            {
                "value": 12267,
                "label": "Newellton",
                "label_ar": "نيولتون",
                "label_fr": "Newellton"
            },
            {
                "value": 12268,
                "label": "Newfane",
                "label_ar": "نيوفان",
                "label_fr": "Newfane"
            },
            {
                "value": 12269,
                "label": "Newfield",
                "label_ar": "حقل جديد",
                "label_fr": "Nouveau champ"
            },
            {
                "value": 12270,
                "label": "Newfields",
                "label_ar": "نيوفيلدز",
                "label_fr": "Newfields"
            },
            {
                "value": 12271,
                "label": "Newfolden",
                "label_ar": "نيوفولدن",
                "label_fr": "Newfolden"
            },
            {
                "value": 12272,
                "label": "Newfoundland",
                "label_ar": "نيوفاوندلاند",
                "label_fr": "Terre-Neuve"
            },
            {
                "value": 12273,
                "label": "Newhall",
                "label_ar": "نيوهول",
                "label_fr": "Newhall"
            },
            {
                "value": 12274,
                "label": "Newhope",
                "label_ar": "أمل جديد",
                "label_fr": "Un nouvel espoir"
            },
            {
                "value": 12275,
                "label": "Newington",
                "label_ar": "نيوينجتون",
                "label_fr": "Newington"
            },
            {
                "value": 12276,
                "label": "Newkirk",
                "label_ar": "نيوكيرك",
                "label_fr": "Newkirk"
            },
            {
                "value": 12277,
                "label": "Newland",
                "label_ar": "ارض جديدة",
                "label_fr": "Nouvelle terre"
            },
            {
                "value": 12278,
                "label": "Newman",
                "label_ar": "رجل جديد",
                "label_fr": "Homme nouveau"
            },
            {
                "value": 12279,
                "label": "Newman Grove",
                "label_ar": "نيومان جروف",
                "label_fr": "Newman Grove"
            },
            {
                "value": 12280,
                "label": "Newman Lake",
                "label_ar": "نيومان ليك",
                "label_fr": "Lac Newman"
            },
            {
                "value": 12281,
                "label": "Newmanstown",
                "label_ar": "نيومانستاون",
                "label_fr": "Newmanstown"
            },
            {
                "value": 12282,
                "label": "Newmarket",
                "label_ar": "سوق جديد",
                "label_fr": "Nouveau marché"
            },
            {
                "value": 12283,
                "label": "Newnan",
                "label_ar": "نيونان",
                "label_fr": "Newnan"
            },
            {
                "value": 12284,
                "label": "Newport",
                "label_ar": "نيوبورت",
                "label_fr": "Newport"
            },
            {
                "value": 12285,
                "label": "Newport Beach",
                "label_ar": "شاطيء الميناء",
                "label_fr": "Newport Beach"
            },
            {
                "value": 12286,
                "label": "Newport Center",
                "label_ar": "مركز نيوبورت",
                "label_fr": "Centre de Newport"
            },
            {
                "value": 12287,
                "label": "Newport Coast",
                "label_ar": "نيوبورت كوست",
                "label_fr": "Côte de Newport"
            },
            {
                "value": 12288,
                "label": "Newport News",
                "label_ar": "نيوبورت نيوز",
                "label_fr": "Nouvelles de Newport"
            },
            {
                "value": 12289,
                "label": "Newry",
                "label_ar": "نيوري",
                "label_fr": "Newry"
            },
            {
                "value": 12290,
                "label": "Newsoms",
                "label_ar": "Newsoms",
                "label_fr": "Newsoms"
            },
            {
                "value": 12291,
                "label": "Newton",
                "label_ar": "نيوتن",
                "label_fr": "Newton"
            },
            {
                "value": 12292,
                "label": "Newton Center",
                "label_ar": "مركز نيوتن",
                "label_fr": "Centre de Newton"
            },
            {
                "value": 12293,
                "label": "Newton Falls",
                "label_ar": "نيوتن فولز",
                "label_fr": "Newton Falls"
            },
            {
                "value": 12294,
                "label": "Newton Grove",
                "label_ar": "نيوتن جروف",
                "label_fr": "Newton Grove"
            },
            {
                "value": 12295,
                "label": "Newton Highlands",
                "label_ar": "مرتفعات نيوتن",
                "label_fr": "Newton Highlands"
            },
            {
                "value": 12296,
                "label": "Newton Lower Falls",
                "label_ar": "نيوتن لوار فولز",
                "label_fr": "Newton Lower Falls"
            },
            {
                "value": 12297,
                "label": "Newton Upper Falls",
                "label_ar": "نيوتن أبر فولز",
                "label_fr": "Newton Upper Falls"
            },
            {
                "value": 12298,
                "label": "Newtonsville",
                "label_ar": "نيوتنفيل",
                "label_fr": "Newtonsville"
            },
            {
                "value": 12299,
                "label": "Newtonville",
                "label_ar": "نيوتنفيل",
                "label_fr": "Newtonville"
            },
            {
                "value": 12300,
                "label": "Newtown",
                "label_ar": "بلدة جديدة",
                "label_fr": "Nouvelle ville"
            },
            {
                "value": 12301,
                "label": "Newtown Square",
                "label_ar": "ميدان نيوتاون",
                "label_fr": "Newtown Square"
            },
            {
                "value": 12302,
                "label": "Newville",
                "label_ar": "نيوفيل",
                "label_fr": "Newville"
            },
            {
                "value": 12303,
                "label": "Nezperce",
                "label_ar": "نيزبيرس",
                "label_fr": "Nezperce"
            },
            {
                "value": 12304,
                "label": "Niagara",
                "label_ar": "نياجرا",
                "label_fr": "Niagara"
            },
            {
                "value": 12305,
                "label": "Niagara Falls",
                "label_ar": "شلالات نياجرا",
                "label_fr": "chutes du Niagara"
            },
            {
                "value": 12306,
                "label": "Niangua",
                "label_ar": "نيانغوا",
                "label_fr": "Niangua"
            },
            {
                "value": 12307,
                "label": "Niantic",
                "label_ar": "نيانتيك",
                "label_fr": "Niantic"
            },
            {
                "value": 12308,
                "label": "Nicasio",
                "label_ar": "نيكاسيو",
                "label_fr": "Nicasio"
            },
            {
                "value": 12309,
                "label": "Niceville",
                "label_ar": "نيسفيل",
                "label_fr": "Niceville"
            },
            {
                "value": 12310,
                "label": "Nicholasville",
                "label_ar": "نيكولاسفيل",
                "label_fr": "Nicholasville"
            },
            {
                "value": 12311,
                "label": "Nicholls",
                "label_ar": "نيكولز",
                "label_fr": "Nicholls"
            },
            {
                "value": 12312,
                "label": "Nichols",
                "label_ar": "نيكولز",
                "label_fr": "Nichols"
            },
            {
                "value": 12313,
                "label": "Nicholson",
                "label_ar": "نيكولسون",
                "label_fr": "Nicholson"
            },
            {
                "value": 12314,
                "label": "Nicholville",
                "label_ar": "نيكولفيل",
                "label_fr": "Nicholville"
            },
            {
                "value": 12315,
                "label": "Nickelsville",
                "label_ar": "نيكلسفيل",
                "label_fr": "Nickelsville"
            },
            {
                "value": 12316,
                "label": "Nickerson",
                "label_ar": "نيكرسون",
                "label_fr": "Nickerson"
            },
            {
                "value": 12317,
                "label": "Nicktown",
                "label_ar": "نيكتاون",
                "label_fr": "Nicktown"
            },
            {
                "value": 12318,
                "label": "Nicollet",
                "label_ar": "نيكوليت",
                "label_fr": "Nicollet"
            },
            {
                "value": 12319,
                "label": "Nicoma Park",
                "label_ar": "نيكوما بارك",
                "label_fr": "Parc Nicoma"
            },
            {
                "value": 12320,
                "label": "Nielsville",
                "label_ar": "نيلسفيل",
                "label_fr": "Nielsville"
            },
            {
                "value": 12321,
                "label": "Nikiski",
                "label_ar": "نيكي",
                "label_fr": "Nikiski"
            },
            {
                "value": 12322,
                "label": "Niland",
                "label_ar": "نيلاند",
                "label_fr": "Niland"
            },
            {
                "value": 12323,
                "label": "Niles",
                "label_ar": "نايلز",
                "label_fr": "Niles"
            },
            {
                "value": 12324,
                "label": "Nilwood",
                "label_ar": "نيلوود",
                "label_fr": "Nilwood"
            },
            {
                "value": 12325,
                "label": "Nine Mile Falls",
                "label_ar": "شلالات ناين مايل",
                "label_fr": "Nine Mile Falls"
            },
            {
                "value": 12326,
                "label": "Ninety Six",
                "label_ar": "ستة و تسعون",
                "label_fr": "Quatre-vingt-seize"
            },
            {
                "value": 12327,
                "label": "Nineveh",
                "label_ar": "نينوى",
                "label_fr": "Ninive"
            },
            {
                "value": 12328,
                "label": "Ninilchik",
                "label_ar": "نينيلتشيك",
                "label_fr": "Ninilchik"
            },
            {
                "value": 12329,
                "label": "Ninnekah",
                "label_ar": "نينيكا",
                "label_fr": "Ninnekah"
            },
            {
                "value": 12330,
                "label": "Ninole",
                "label_ar": "نينول",
                "label_fr": "Ninole"
            },
            {
                "value": 12331,
                "label": "Niobe",
                "label_ar": "نيوب",
                "label_fr": "Niobe"
            },
            {
                "value": 12332,
                "label": "Niobrara",
                "label_ar": "نيوبرارا",
                "label_fr": "Niobrara"
            },
            {
                "value": 12333,
                "label": "Niota",
                "label_ar": "نيوتا",
                "label_fr": "Niota"
            },
            {
                "value": 12334,
                "label": "Nipomo",
                "label_ar": "نيبومو",
                "label_fr": "Nipomo"
            },
            {
                "value": 12335,
                "label": "Nisswa",
                "label_ar": "نسوى",
                "label_fr": "Nisswa"
            },
            {
                "value": 12336,
                "label": "Nitro",
                "label_ar": "نيترو",
                "label_fr": "Nitro"
            },
            {
                "value": 12337,
                "label": "Niwot",
                "label_ar": "نيوت",
                "label_fr": "Niwot"
            },
            {
                "value": 12338,
                "label": "Nixa",
                "label_ar": "نيكسا",
                "label_fr": "Nixa"
            },
            {
                "value": 12339,
                "label": "Nixon",
                "label_ar": "نيكسون",
                "label_fr": "Nixon"
            },
            {
                "value": 12340,
                "label": "Noatak",
                "label_ar": "نواتاك",
                "label_fr": "Noatak"
            },
            {
                "value": 12341,
                "label": "Noble",
                "label_ar": "النبيل",
                "label_fr": "noble"
            },
            {
                "value": 12342,
                "label": "Nobleboro",
                "label_ar": "نوبلبورو",
                "label_fr": "Nobleboro"
            },
            {
                "value": 12343,
                "label": "Noblesville",
                "label_ar": "نوبلسفيل",
                "label_fr": "Noblesville"
            },
            {
                "value": 12344,
                "label": "Nocona",
                "label_ar": "نوكونا",
                "label_fr": "Nocona"
            },
            {
                "value": 12345,
                "label": "Noel",
                "label_ar": "نويل",
                "label_fr": "Noel"
            },
            {
                "value": 12346,
                "label": "Nogal",
                "label_ar": "نوجال",
                "label_fr": "Nogal"
            },
            {
                "value": 12347,
                "label": "Nogales",
                "label_ar": "نوجاليس",
                "label_fr": "Nogales"
            },
            {
                "value": 12348,
                "label": "Nokesville",
                "label_ar": "نوكيسفيل",
                "label_fr": "Nokesville"
            },
            {
                "value": 12349,
                "label": "Nokomis",
                "label_ar": "نوكوميس",
                "label_fr": "Nokomis"
            },
            {
                "value": 12350,
                "label": "Nolanville",
                "label_ar": "نولانفيل",
                "label_fr": "Nolanville"
            },
            {
                "value": 12351,
                "label": "Nolensville",
                "label_ar": "نولينزفيل",
                "label_fr": "Nolensville"
            },
            {
                "value": 12352,
                "label": "Nome",
                "label_ar": "نوم",
                "label_fr": "Nome"
            },
            {
                "value": 12353,
                "label": "Nondalton",
                "label_ar": "نوندالتون",
                "label_fr": "Nondalton"
            },
            {
                "value": 12354,
                "label": "Nooksack",
                "label_ar": "نوكساك",
                "label_fr": "Nooksack"
            },
            {
                "value": 12355,
                "label": "Noonan",
                "label_ar": "نونان",
                "label_fr": "Noonan"
            },
            {
                "value": 12356,
                "label": "Noorvik",
                "label_ar": "نورفيك",
                "label_fr": "Noorvik"
            },
            {
                "value": 12357,
                "label": "Nora Springs",
                "label_ar": "نورا سبرينجز",
                "label_fr": "Nora Springs"
            },
            {
                "value": 12358,
                "label": "Norborne",
                "label_ar": "نوربورن",
                "label_fr": "Norborne"
            },
            {
                "value": 12359,
                "label": "Norco",
                "label_ar": "نوركو",
                "label_fr": "Norco"
            },
            {
                "value": 12360,
                "label": "Norcross",
                "label_ar": "نوركروس",
                "label_fr": "Norcross"
            },
            {
                "value": 12361,
                "label": "Nordland",
                "label_ar": "نوردلاند",
                "label_fr": "Nordland"
            },
            {
                "value": 12362,
                "label": "Norfolk",
                "label_ar": "نورفولك",
                "label_fr": "Norfolk"
            },
            {
                "value": 12363,
                "label": "Norlina",
                "label_ar": "نورلينا",
                "label_fr": "Norlina"
            },
            {
                "value": 12364,
                "label": "Norma",
                "label_ar": "نورما",
                "label_fr": "Norma"
            },
            {
                "value": 12365,
                "label": "Normal",
                "label_ar": "عادي",
                "label_fr": "Ordinaire"
            },
            {
                "value": 12366,
                "label": "Normalville",
                "label_ar": "نورمالفيل",
                "label_fr": "Normalville"
            },
            {
                "value": 12367,
                "label": "Norman",
                "label_ar": "نورمان",
                "label_fr": "normand"
            },
            {
                "value": 12368,
                "label": "Norman Park",
                "label_ar": "نورمان بارك",
                "label_fr": "Parc normand"
            },
            {
                "value": 12369,
                "label": "Normandy",
                "label_ar": "نورماندي",
                "label_fr": "la Normandie"
            },
            {
                "value": 12370,
                "label": "Normandy Beach",
                "label_ar": "نورماندي بيتش",
                "label_fr": "Plage de Normandie"
            },
            {
                "value": 12371,
                "label": "Normangee",
                "label_ar": "نورمانجي",
                "label_fr": "Normangee"
            },
            {
                "value": 12372,
                "label": "Normantown",
                "label_ar": "نورمانتاون",
                "label_fr": "Normantown"
            },
            {
                "value": 12373,
                "label": "Norridgewock",
                "label_ar": "نوريجوك",
                "label_fr": "Norridgewock"
            },
            {
                "value": 12374,
                "label": "Norris",
                "label_ar": "نوريس",
                "label_fr": "Norris"
            },
            {
                "value": 12375,
                "label": "Norris City",
                "label_ar": "نوريس سيتي",
                "label_fr": "Norris City"
            },
            {
                "value": 12376,
                "label": "Norristown",
                "label_ar": "نوريستاون",
                "label_fr": "Norristown"
            },
            {
                "value": 12377,
                "label": "North",
                "label_ar": "شمال",
                "label_fr": "Nord"
            },
            {
                "value": 12378,
                "label": "North Adams",
                "label_ar": "شمال ادامز",
                "label_fr": "North Adams"
            },
            {
                "value": 12379,
                "label": "North Amherst",
                "label_ar": "شمال امهيرست",
                "label_fr": "North Amherst"
            },
            {
                "value": 12380,
                "label": "North Andover",
                "label_ar": "شمال أندوفر",
                "label_fr": "North Andover"
            },
            {
                "value": 12381,
                "label": "North Anson",
                "label_ar": "شمال أنسون",
                "label_fr": "North Anson"
            },
            {
                "value": 12382,
                "label": "North Apollo",
                "label_ar": "شمال أبولو",
                "label_fr": "North Apollo"
            },
            {
                "value": 12383,
                "label": "North Arlington",
                "label_ar": "شمال أرلينغتون",
                "label_fr": "North Arlington"
            },
            {
                "value": 12384,
                "label": "North Attleboro",
                "label_ar": "شمال أتلبورو",
                "label_fr": "North Attleboro"
            },
            {
                "value": 12385,
                "label": "North Augusta",
                "label_ar": "شمال أوغوستا",
                "label_fr": "North Augusta"
            },
            {
                "value": 12386,
                "label": "North Aurora",
                "label_ar": "شمال أورورا",
                "label_fr": "Aurore du Nord"
            },
            {
                "value": 12387,
                "label": "North Babylon",
                "label_ar": "شمال بابل",
                "label_fr": "Babylone du Nord"
            },
            {
                "value": 12388,
                "label": "North Baltimore",
                "label_ar": "شمال بالتيمور",
                "label_fr": "North Baltimore"
            },
            {
                "value": 12389,
                "label": "North Bangor",
                "label_ar": "شمال بانجور",
                "label_fr": "North Bangor"
            },
            {
                "value": 12390,
                "label": "North Beach",
                "label_ar": "نورث بيتش",
                "label_fr": "North Beach"
            },
            {
                "value": 12391,
                "label": "North Bend",
                "label_ar": "شمال بيند",
                "label_fr": "North Bend"
            },
            {
                "value": 12392,
                "label": "North Bennington",
                "label_ar": "شمال بينينجتون",
                "label_fr": "North Bennington"
            },
            {
                "value": 12393,
                "label": "North Bergen",
                "label_ar": "شمال بيرغن",
                "label_fr": "North Bergen"
            },
            {
                "value": 12394,
                "label": "North Berwick",
                "label_ar": "نورث بيرويك",
                "label_fr": "North Berwick"
            },
            {
                "value": 12395,
                "label": "North Billerica",
                "label_ar": "شمال بيليريكا",
                "label_fr": "North Billerica"
            },
            {
                "value": 12396,
                "label": "North Bloomfield",
                "label_ar": "شمال بلومفيلد",
                "label_fr": "North Bloomfield"
            },
            {
                "value": 12397,
                "label": "North Bonneville",
                "label_ar": "شمال بونفيل",
                "label_fr": "Nord Bonneville"
            },
            {
                "value": 12398,
                "label": "North Branch",
                "label_ar": "فرع الشمال",
                "label_fr": "Branche Nord"
            },
            {
                "value": 12399,
                "label": "North Branford",
                "label_ar": "شمال برانفورد",
                "label_fr": "North Branford"
            },
            {
                "value": 12400,
                "label": "North Bridgton",
                "label_ar": "شمال بريدجتون",
                "label_fr": "North Bridgton"
            },
            {
                "value": 12401,
                "label": "North Brookfield",
                "label_ar": "نورث بروكفيلد",
                "label_fr": "North Brookfield"
            },
            {
                "value": 12402,
                "label": "North Brunswick",
                "label_ar": "شمال برونزويك",
                "label_fr": "Nord-Brunswick"
            },
            {
                "value": 12403,
                "label": "North Carrollton",
                "label_ar": "شمال كارولتون",
                "label_fr": "North Carrollton"
            },
            {
                "value": 12404,
                "label": "North Carver",
                "label_ar": "نورث كارفر",
                "label_fr": "North Carver"
            },
            {
                "value": 12405,
                "label": "North Charleston",
                "label_ar": "نورث تشارلستون",
                "label_fr": "North Charleston"
            },
            {
                "value": 12406,
                "label": "North Chatham",
                "label_ar": "شمال تشاتام",
                "label_fr": "North Chatham"
            },
            {
                "value": 12407,
                "label": "North Chelmsford",
                "label_ar": "شمال تشيلمسفورد",
                "label_fr": "North Chelmsford"
            },
            {
                "value": 12408,
                "label": "North Chicago",
                "label_ar": "شمال شيكاغو",
                "label_fr": "North Chicago"
            },
            {
                "value": 12409,
                "label": "North Chili",
                "label_ar": "شمال الفلفل الحار",
                "label_fr": "Chili du Nord"
            },
            {
                "value": 12410,
                "label": "North Clarendon",
                "label_ar": "شمال كلاريندون",
                "label_fr": "North Clarendon"
            },
            {
                "value": 12411,
                "label": "North Collins",
                "label_ar": "نورث كولينز",
                "label_fr": "North Collins"
            },
            {
                "value": 12412,
                "label": "North Conway",
                "label_ar": "شمال كونواي",
                "label_fr": "North Conway"
            },
            {
                "value": 12413,
                "label": "North Creek",
                "label_ar": "نورث كريك",
                "label_fr": "North Creek"
            },
            {
                "value": 12414,
                "label": "North Dartmouth",
                "label_ar": "شمال دارتموث",
                "label_fr": "North Dartmouth"
            },
            {
                "value": 12415,
                "label": "North Dighton",
                "label_ar": "نورث دايتون",
                "label_fr": "North Dighton"
            },
            {
                "value": 12416,
                "label": "North East",
                "label_ar": "الشمال الشرقي",
                "label_fr": "Nord Est"
            },
            {
                "value": 12417,
                "label": "North Eastham",
                "label_ar": "شمال ايستهام",
                "label_fr": "North Eastham"
            },
            {
                "value": 12418,
                "label": "North Easton",
                "label_ar": "شمال ايستون",
                "label_fr": "North Easton"
            },
            {
                "value": 12419,
                "label": "North English",
                "label_ar": "شمال الإنجليزية",
                "label_fr": "Anglais du Nord"
            },
            {
                "value": 12420,
                "label": "North Fairfield",
                "label_ar": "نورث فيرفيلد",
                "label_fr": "North Fairfield"
            },
            {
                "value": 12421,
                "label": "North Falmouth",
                "label_ar": "شمال فالماوث",
                "label_fr": "North Falmouth"
            },
            {
                "value": 12422,
                "label": "North Fork",
                "label_ar": "نورث فورك",
                "label_fr": "North Fork"
            },
            {
                "value": 12423,
                "label": "North Fort Myers",
                "label_ar": "نورث فورت مايرز",
                "label_fr": "North Fort Myers"
            },
            {
                "value": 12424,
                "label": "North Franklin",
                "label_ar": "شمال فرانكلين",
                "label_fr": "North Franklin"
            },
            {
                "value": 12425,
                "label": "North Freedom",
                "label_ar": "شمال الحرية",
                "label_fr": "Liberté du Nord"
            },
            {
                "value": 12426,
                "label": "North Garden",
                "label_ar": "نورث جاردن",
                "label_fr": "Jardin nord"
            },
            {
                "value": 12427,
                "label": "North Grafton",
                "label_ar": "شمال جرافتون",
                "label_fr": "North Grafton"
            },
            {
                "value": 12428,
                "label": "North Granby",
                "label_ar": "شمال جرانبي",
                "label_fr": "North Granby"
            },
            {
                "value": 12429,
                "label": "North Greece",
                "label_ar": "شمال اليونان",
                "label_fr": "Grèce du Nord"
            },
            {
                "value": 12430,
                "label": "North Grosvenor Dale",
                "label_ar": "شمال جروسفينور ديل",
                "label_fr": "North Grosvenor Dale"
            },
            {
                "value": 12431,
                "label": "North Hampton",
                "label_ar": "نورث هامبتون",
                "label_fr": "North Hampton"
            },
            {
                "value": 12432,
                "label": "North Hatfield",
                "label_ar": "شمال هاتفيلد",
                "label_fr": "North Hatfield"
            },
            {
                "value": 12433,
                "label": "North Haven",
                "label_ar": "نورث هيفن",
                "label_fr": "North Haven"
            },
            {
                "value": 12434,
                "label": "North Haverhill",
                "label_ar": "شمال هافرهيل",
                "label_fr": "North Haverhill"
            },
            {
                "value": 12435,
                "label": "North Henderson",
                "label_ar": "شمال هندرسون",
                "label_fr": "North Henderson"
            },
            {
                "value": 12436,
                "label": "North Hero",
                "label_ar": "بطل الشمال",
                "label_fr": "Héros du Nord"
            },
            {
                "value": 12437,
                "label": "North Highlands",
                "label_ar": "المرتفعات الشمالية",
                "label_fr": "North Highlands"
            },
            {
                "value": 12438,
                "label": "North Hills",
                "label_ar": "التلال الشمالية",
                "label_fr": "North Hills"
            },
            {
                "value": 12439,
                "label": "North Hollywood",
                "label_ar": "شمال هوليوود",
                "label_fr": "North Hollywood"
            },
            {
                "value": 12440,
                "label": "North Jackson",
                "label_ar": "نورث جاكسون",
                "label_fr": "North Jackson"
            },
            {
                "value": 12441,
                "label": "North Judson",
                "label_ar": "شمال جودسون",
                "label_fr": "North Judson"
            },
            {
                "value": 12442,
                "label": "North Kingstown",
                "label_ar": "شمال كينغستاون",
                "label_fr": "North Kingstown"
            },
            {
                "value": 12443,
                "label": "North Kingsville",
                "label_ar": "شمال كينجسفيل",
                "label_fr": "North Kingsville"
            },
            {
                "value": 12444,
                "label": "North Las Vegas",
                "label_ar": "شمال لاس فيغاس",
                "label_fr": "North Las Vegas"
            },
            {
                "value": 12445,
                "label": "North Lemmon",
                "label_ar": "شمال ليمون",
                "label_fr": "North Lemmon"
            },
            {
                "value": 12446,
                "label": "North Lewisburg",
                "label_ar": "شمال لويسبورغ",
                "label_fr": "North Lewisburg"
            },
            {
                "value": 12447,
                "label": "North Liberty",
                "label_ar": "شمال ليبرتي",
                "label_fr": "North Liberty"
            },
            {
                "value": 12448,
                "label": "North Lima",
                "label_ar": "شمال ليما",
                "label_fr": "Nord de Lima"
            },
            {
                "value": 12449,
                "label": "North Little Rock",
                "label_ar": "نورث ليتل روك",
                "label_fr": "North Little Rock"
            },
            {
                "value": 12450,
                "label": "North Loup",
                "label_ar": "شمال لوب",
                "label_fr": "North Loup"
            },
            {
                "value": 12451,
                "label": "North Manchester",
                "label_ar": "شمال مانشستر",
                "label_fr": "North Manchester"
            },
            {
                "value": 12452,
                "label": "North Matewan",
                "label_ar": "شمال ماتيوان",
                "label_fr": "North Matewan"
            },
            {
                "value": 12453,
                "label": "North Miami Beach",
                "label_ar": "شمال ميامي بيتش",
                "label_fr": "North Miami Beach"
            },
            {
                "value": 12454,
                "label": "North Monmouth",
                "label_ar": "شمال مونماوث",
                "label_fr": "North Monmouth"
            },
            {
                "value": 12455,
                "label": "North Myrtle Beach",
                "label_ar": "شمال ميرتل بيتش",
                "label_fr": "North Myrtle Beach"
            },
            {
                "value": 12456,
                "label": "North Newton",
                "label_ar": "شمال نيوتن",
                "label_fr": "North Newton"
            },
            {
                "value": 12457,
                "label": "North Olmsted",
                "label_ar": "شمال أولمستيد",
                "label_fr": "North Olmsted"
            },
            {
                "value": 12458,
                "label": "North Oxford",
                "label_ar": "شمال أكسفورد",
                "label_fr": "North Oxford"
            },
            {
                "value": 12459,
                "label": "North Palm Beach",
                "label_ar": "نورث بالم بيتش",
                "label_fr": "North Palm Beach"
            },
            {
                "value": 12460,
                "label": "North Pembroke",
                "label_ar": "شمال بيمبروك",
                "label_fr": "North Pembroke"
            },
            {
                "value": 12461,
                "label": "North Plains",
                "label_ar": "السهول الشمالية",
                "label_fr": "Plaines du Nord"
            },
            {
                "value": 12462,
                "label": "North Platte",
                "label_ar": "نورث بلات",
                "label_fr": "North Platte"
            },
            {
                "value": 12463,
                "label": "North Point",
                "label_ar": "نقطة الشمال",
                "label_fr": "point nord"
            },
            {
                "value": 12464,
                "label": "North Pole",
                "label_ar": "القطب الشمالي",
                "label_fr": "pôle Nord"
            },
            {
                "value": 12465,
                "label": "North Pomfret",
                "label_ar": "شمال بومفريت",
                "label_fr": "North Pomfret"
            },
            {
                "value": 12466,
                "label": "North Port",
                "label_ar": "الميناء الشمالي",
                "label_fr": "Port Nord"
            },
            {
                "value": 12467,
                "label": "North Powder",
                "label_ar": "مسحوق الشمال",
                "label_fr": "Poudre du Nord"
            },
            {
                "value": 12468,
                "label": "North Prairie",
                "label_ar": "شمال البراري",
                "label_fr": "North Prairie"
            },
            {
                "value": 12469,
                "label": "North Providence",
                "label_ar": "شمال بروفيدنس",
                "label_fr": "Providence du Nord"
            },
            {
                "value": 12470,
                "label": "North Reading",
                "label_ar": "القراءة الشمالية",
                "label_fr": "Lecture du Nord"
            },
            {
                "value": 12471,
                "label": "North Richland Hills",
                "label_ar": "شمال ريتشلاند هيلز",
                "label_fr": "North Richland Hills"
            },
            {
                "value": 12472,
                "label": "North Ridgeville",
                "label_ar": "شمال ريدجفيل",
                "label_fr": "North Ridgeville"
            },
            {
                "value": 12473,
                "label": "North Rose",
                "label_ar": "نورث روز",
                "label_fr": "Rose du Nord"
            },
            {
                "value": 12474,
                "label": "North Royalton",
                "label_ar": "شمال رويالتون",
                "label_fr": "North Royalton"
            },
            {
                "value": 12475,
                "label": "North Salem",
                "label_ar": "شمال سالم",
                "label_fr": "North Salem"
            },
            {
                "value": 12476,
                "label": "North Salt Lake",
                "label_ar": "شمال سولت ليك",
                "label_fr": "North Salt Lake"
            },
            {
                "value": 12477,
                "label": "North San Juan",
                "label_ar": "شمال سان خوان",
                "label_fr": "Nord de San Juan"
            },
            {
                "value": 12478,
                "label": "North Sandwich",
                "label_ar": "نورث ساندويتش",
                "label_fr": "North Sandwich"
            },
            {
                "value": 12479,
                "label": "North Scituate",
                "label_ar": "شمال سيتويت",
                "label_fr": "Scituat du Nord"
            },
            {
                "value": 12480,
                "label": "North Sioux City",
                "label_ar": "مدينة سيوكس الشمالية",
                "label_fr": "North Sioux City"
            },
            {
                "value": 12481,
                "label": "North Smithfield",
                "label_ar": "شمال سميثفيلد",
                "label_fr": "North Smithfield"
            },
            {
                "value": 12482,
                "label": "North Springfield",
                "label_ar": "شمال سبرينغفيلد",
                "label_fr": "North Springfield"
            },
            {
                "value": 12483,
                "label": "North Stonington",
                "label_ar": "شمال ستونينجتون",
                "label_fr": "North Stonington"
            },
            {
                "value": 12484,
                "label": "North Sutton",
                "label_ar": "شمال ساتون",
                "label_fr": "North Sutton"
            },
            {
                "value": 12485,
                "label": "North Tazewell",
                "label_ar": "شمال تازويل",
                "label_fr": "North Tazewell"
            },
            {
                "value": 12486,
                "label": "North Tonawanda",
                "label_ar": "شمال توناواندا",
                "label_fr": "North Tonawanda"
            },
            {
                "value": 12487,
                "label": "North Troy",
                "label_ar": "شمال تروي",
                "label_fr": "North Troy"
            },
            {
                "value": 12488,
                "label": "North Truro",
                "label_ar": "شمال ترورو",
                "label_fr": "North Truro"
            },
            {
                "value": 12489,
                "label": "North Vernon",
                "label_ar": "شمال فيرنون",
                "label_fr": "North Vernon"
            },
            {
                "value": 12490,
                "label": "North Versailles",
                "label_ar": "شمال فرساي",
                "label_fr": "Nord de Versailles"
            },
            {
                "value": 12491,
                "label": "North Wales",
                "label_ar": "شمال ويلز",
                "label_fr": "Galles du Nord"
            },
            {
                "value": 12492,
                "label": "North Walpole",
                "label_ar": "شمال والبول",
                "label_fr": "North Walpole"
            },
            {
                "value": 12493,
                "label": "North Waterboro",
                "label_ar": "شمال واتربورو",
                "label_fr": "North Waterboro"
            },
            {
                "value": 12494,
                "label": "North Webster",
                "label_ar": "شمال ويبستر",
                "label_fr": "North Webster"
            },
            {
                "value": 12495,
                "label": "North Weymouth",
                "label_ar": "شمال ويموث",
                "label_fr": "North Weymouth"
            },
            {
                "value": 12496,
                "label": "North Wilkesboro",
                "label_ar": "شمال ويلكسبورو",
                "label_fr": "North Wilkesboro"
            },
            {
                "value": 12497,
                "label": "North Windham",
                "label_ar": "شمال ويندهام",
                "label_fr": "North Windham"
            },
            {
                "value": 12498,
                "label": "North Woodstock",
                "label_ar": "شمال وودستوك",
                "label_fr": "North Woodstock"
            },
            {
                "value": 12499,
                "label": "North Zulch",
                "label_ar": "شمال زولش",
                "label_fr": "Nord Zulch"
            },
            {
                "value": 12500,
                "label": "Northampton",
                "label_ar": "نورثهامبتون",
                "label_fr": "Northampton"
            },
            {
                "value": 12501,
                "label": "Northboro",
                "label_ar": "نورثبورو",
                "label_fr": "Northboro"
            },
            {
                "value": 12502,
                "label": "Northborough",
                "label_ar": "نورثبورو",
                "label_fr": "Northborough"
            },
            {
                "value": 12503,
                "label": "Northbridge",
                "label_ar": "نورثبريدج",
                "label_fr": "Le pont Nord"
            },
            {
                "value": 12504,
                "label": "Northbrook",
                "label_ar": "نورثبروك",
                "label_fr": "Northbrook"
            },
            {
                "value": 12505,
                "label": "Northeast Harbor",
                "label_ar": "الميناء الشمالي الشرقي",
                "label_fr": "Northeast Harbor"
            },
            {
                "value": 12506,
                "label": "Northern Cambria",
                "label_ar": "شمال كامبريا",
                "label_fr": "Cambria du nord"
            },
            {
                "value": 12507,
                "label": "Northfield",
                "label_ar": "نورثفيلد",
                "label_fr": "Northfield"
            },
            {
                "value": 12508,
                "label": "Northfield Falls",
                "label_ar": "نورثفيلد فولز",
                "label_fr": "Northfield Falls"
            },
            {
                "value": 12509,
                "label": "Northford",
                "label_ar": "نورثفورد",
                "label_fr": "Northford"
            },
            {
                "value": 12510,
                "label": "Northfork",
                "label_ar": "نورثفورك",
                "label_fr": "Northfork"
            },
            {
                "value": 12511,
                "label": "Northome",
                "label_ar": "نورثومي",
                "label_fr": "Northome"
            },
            {
                "value": 12512,
                "label": "Northport",
                "label_ar": "الميناء الشمالي",
                "label_fr": "Northport"
            },
            {
                "value": 12513,
                "label": "Northridge",
                "label_ar": "نورثريدج",
                "label_fr": "Northridge"
            },
            {
                "value": 12514,
                "label": "Northrup's of West Bloomfield Mobile Home Park",
                "label_ar": "نورثروب في ويست بلومفيلد موبايل هوم بارك",
                "label_fr": "Northrup's of West Bloomfield Mobile Home Park"
            },
            {
                "value": 12515,
                "label": "Northumberland",
                "label_ar": "نورثمبرلاند",
                "label_fr": "Northumberland"
            },
            {
                "value": 12516,
                "label": "Northvale",
                "label_ar": "نورثفيل",
                "label_fr": "Northvale"
            },
            {
                "value": 12517,
                "label": "Northville",
                "label_ar": "نورثفيل",
                "label_fr": "Northville"
            },
            {
                "value": 12518,
                "label": "Northway",
                "label_ar": "نورثواي",
                "label_fr": "Northway"
            },
            {
                "value": 12519,
                "label": "Northwood",
                "label_ar": "نورثوود",
                "label_fr": "Northwood"
            },
            {
                "value": 12520,
                "label": "Norton",
                "label_ar": "نورتون",
                "label_fr": "Norton"
            },
            {
                "value": 12521,
                "label": "Nortonville",
                "label_ar": "نورتونفيل",
                "label_fr": "Nortonville"
            },
            {
                "value": 12522,
                "label": "Norwalk",
                "label_ar": "نورووك",
                "label_fr": "Norwalk"
            },
            {
                "value": 12523,
                "label": "Norway",
                "label_ar": "النرويج",
                "label_fr": "Norvège"
            },
            {
                "value": 12524,
                "label": "Norwell",
                "label_ar": "نورويل",
                "label_fr": "Norwell"
            },
            {
                "value": 12525,
                "label": "Norwich",
                "label_ar": "نورويتش",
                "label_fr": "Norwich"
            },
            {
                "value": 12526,
                "label": "Norwood",
                "label_ar": "نوروود",
                "label_fr": "Norwood"
            },
            {
                "value": 12527,
                "label": "Norwood Young America",
                "label_ar": "نوروود يونغ أمريكا",
                "label_fr": "Norwood Young America"
            },
            {
                "value": 12528,
                "label": "Notasulga",
                "label_ar": "نوتاسولجا",
                "label_fr": "Notasulga"
            },
            {
                "value": 12529,
                "label": "Notre Dame",
                "label_ar": "نوتردام",
                "label_fr": "notre Dame"
            },
            {
                "value": 12530,
                "label": "Nottingham",
                "label_ar": "نوتنغهام",
                "label_fr": "Nottingham"
            },
            {
                "value": 12531,
                "label": "Nova",
                "label_ar": "نوفا",
                "label_fr": "Nova"
            },
            {
                "value": 12532,
                "label": "Novato",
                "label_ar": "نوفاتو",
                "label_fr": "Novato"
            },
            {
                "value": 12533,
                "label": "Novelty",
                "label_ar": "بدعة",
                "label_fr": "Nouveauté"
            },
            {
                "value": 12534,
                "label": "Novi",
                "label_ar": "نوفي",
                "label_fr": "Novi"
            },
            {
                "value": 12535,
                "label": "Novinger",
                "label_ar": "نوفينجر",
                "label_fr": "Novinger"
            },
            {
                "value": 12536,
                "label": "Nowata",
                "label_ar": "نوواتا",
                "label_fr": "Nowata"
            },
            {
                "value": 12537,
                "label": "Noxapater",
                "label_ar": "نوكساتير",
                "label_fr": "Noxapater"
            },
            {
                "value": 12538,
                "label": "Noxen",
                "label_ar": "Noxen",
                "label_fr": "Noxen"
            },
            {
                "value": 12539,
                "label": "Noxon",
                "label_ar": "نوكسون",
                "label_fr": "Noxon"
            },
            {
                "value": 12540,
                "label": "NuMine",
                "label_ar": "نوميني",
                "label_fr": "NuMine"
            },
            {
                "value": 12541,
                "label": "Nucla",
                "label_ar": "نوكلا",
                "label_fr": "Nucla"
            },
            {
                "value": 12542,
                "label": "Nuevo",
                "label_ar": "نويفو",
                "label_fr": "Nuevo"
            },
            {
                "value": 12543,
                "label": "Nunda",
                "label_ar": "نوندا",
                "label_fr": "Nunda"
            },
            {
                "value": 12544,
                "label": "Nunica",
                "label_ar": "نونيكا",
                "label_fr": "Nunica"
            },
            {
                "value": 12545,
                "label": "Nunn",
                "label_ar": "راهبة",
                "label_fr": "Nunn"
            },
            {
                "value": 12546,
                "label": "Nunnelly",
                "label_ar": "نونيلي",
                "label_fr": "Nunnelly"
            },
            {
                "value": 12547,
                "label": "Nuremberg",
                "label_ar": "نورمبرغ",
                "label_fr": "Nuremberg"
            },
            {
                "value": 12548,
                "label": "Nutley",
                "label_ar": "نوتلي",
                "label_fr": "Nutley"
            },
            {
                "value": 12549,
                "label": "Nutrioso",
                "label_ar": "نيوتريوزو",
                "label_fr": "Nutrioso"
            },
            {
                "value": 12550,
                "label": "Nyack",
                "label_ar": "نياك",
                "label_fr": "Nyack"
            },
            {
                "value": 12551,
                "label": "Nye",
                "label_ar": "ناي",
                "label_fr": "Nye"
            },
            {
                "value": 12552,
                "label": "Nyssa",
                "label_ar": "النيصي",
                "label_fr": "Nyssa"
            },
            {
                "value": 12553,
                "label": "O'Brien",
                "label_ar": "أوبراين",
                "label_fr": "O'Brien"
            },
            {
                "value": 12554,
                "label": "O'Fallon",
                "label_ar": "أوفالون",
                "label_fr": "O'Fallon"
            },
            {
                "value": 12555,
                "label": "O'Neals",
                "label_ar": "أونيلز",
                "label_fr": "O'Neals"
            },
            {
                "value": 12556,
                "label": "O'Neill",
                "label_ar": "أونيل",
                "label_fr": "O'Neill"
            },
            {
                "value": 12557,
                "label": "Oacoma",
                "label_ar": "أوكوما",
                "label_fr": "Oacoma"
            },
            {
                "value": 12558,
                "label": "Oak Bluffs",
                "label_ar": "أوك بلافس",
                "label_fr": "Oak Bluffs"
            },
            {
                "value": 12559,
                "label": "Oak Brook",
                "label_ar": "أوك بروك",
                "label_fr": "Oak Brook"
            },
            {
                "value": 12560,
                "label": "Oak City",
                "label_ar": "أوك سيتي",
                "label_fr": "Oak City"
            },
            {
                "value": 12561,
                "label": "Oak Creek",
                "label_ar": "بلوط، جدول",
                "label_fr": "Oak Creek"
            },
            {
                "value": 12562,
                "label": "Oak Forest",
                "label_ar": "غابة البلوط",
                "label_fr": "Forêt de chênes"
            },
            {
                "value": 12563,
                "label": "Oak Grove",
                "label_ar": "البلوط بستان",
                "label_fr": "Oak Grove"
            },
            {
                "value": 12564,
                "label": "Oak Harbor",
                "label_ar": "أوك هاربور",
                "label_fr": "Oak Harbor"
            },
            {
                "value": 12565,
                "label": "Oak Hill",
                "label_ar": "اوك هيل",
                "label_fr": "Oak Hill"
            },
            {
                "value": 12566,
                "label": "Oak Island",
                "label_ar": "جزيرة أوك",
                "label_fr": "Oak Island"
            },
            {
                "value": 12567,
                "label": "Oak Lawn",
                "label_ar": "اوك لاون",
                "label_fr": "Pelouse de chêne"
            },
            {
                "value": 12568,
                "label": "Oak Park",
                "label_ar": "حديقة البلوط",
                "label_fr": "Oak Park"
            },
            {
                "value": 12569,
                "label": "Oak Ridge",
                "label_ar": "أوك ريدج",
                "label_fr": "Oak Ridge"
            },
            {
                "value": 12570,
                "label": "Oak Run",
                "label_ar": "أوك ران",
                "label_fr": "Oak Run"
            },
            {
                "value": 12571,
                "label": "Oak View",
                "label_ar": "منظر البلوط",
                "label_fr": "Vue sur le chêne"
            },
            {
                "value": 12572,
                "label": "Oakboro",
                "label_ar": "اوكبورو",
                "label_fr": "Oakboro"
            },
            {
                "value": 12573,
                "label": "Oakdale",
                "label_ar": "اوكديل",
                "label_fr": "Oakdale"
            },
            {
                "value": 12574,
                "label": "Oakes",
                "label_ar": "أوكس",
                "label_fr": "Oakes"
            },
            {
                "value": 12575,
                "label": "Oakesdale",
                "label_ar": "أوكسدال",
                "label_fr": "Oakesdale"
            },
            {
                "value": 12576,
                "label": "Oakfield",
                "label_ar": "أوكفيلد",
                "label_fr": "Oakfield"
            },
            {
                "value": 12577,
                "label": "Oakham",
                "label_ar": "أوكهام",
                "label_fr": "Oakham"
            },
            {
                "value": 12578,
                "label": "Oakhurst",
                "label_ar": "أوخورست",
                "label_fr": "Oakhurst"
            },
            {
                "value": 12579,
                "label": "Oakland",
                "label_ar": "اوكلاند",
                "label_fr": "Oakland"
            },
            {
                "value": 12580,
                "label": "Oakland City",
                "label_ar": "مدينة اوكلاند",
                "label_fr": "Oakland City"
            },
            {
                "value": 12581,
                "label": "Oakland Estates Mobile Home Park",
                "label_ar": "أوكلاند إستيتس موبايل هوم بارك",
                "label_fr": "Parc de maisons mobiles Oakland Estates"
            },
            {
                "value": 12582,
                "label": "Oakland Gardens",
                "label_ar": "حدائق اوكلاند",
                "label_fr": "Oakland Gardens"
            },
            {
                "value": 12583,
                "label": "Oakley",
                "label_ar": "اوكلي",
                "label_fr": "Oakley"
            },
            {
                "value": 12584,
                "label": "Oaklyn",
                "label_ar": "أوكلين",
                "label_fr": "Oaklyn"
            },
            {
                "value": 12585,
                "label": "Oakman",
                "label_ar": "أوكمان",
                "label_fr": "Oakman"
            },
            {
                "value": 12586,
                "label": "Oakmont",
                "label_ar": "أوكمونت",
                "label_fr": "Oakmont"
            },
            {
                "value": 12587,
                "label": "Oaks",
                "label_ar": "أوكس",
                "label_fr": "Chênes"
            },
            {
                "value": 12588,
                "label": "Oakton",
                "label_ar": "أوكتون",
                "label_fr": "Oakton"
            },
            {
                "value": 12589,
                "label": "Oakvale",
                "label_ar": "أوكفيل",
                "label_fr": "Oakvale"
            },
            {
                "value": 12590,
                "label": "Oakville",
                "label_ar": "أوكفيل",
                "label_fr": "Oakville"
            },
            {
                "value": 12591,
                "label": "Oakwood",
                "label_ar": "خشب البلوط",
                "label_fr": "Bois de chêne"
            },
            {
                "value": 12592,
                "label": "Oberlin",
                "label_ar": "أوبرلين",
                "label_fr": "Oberlin"
            },
            {
                "value": 12593,
                "label": "Obion",
                "label_ar": "أوبيون",
                "label_fr": "Obion"
            },
            {
                "value": 12594,
                "label": "Oblong",
                "label_ar": "مستطيل",
                "label_fr": "Oblong"
            },
            {
                "value": 12595,
                "label": "Ocala",
                "label_ar": "أوكالا",
                "label_fr": "Ocala"
            },
            {
                "value": 12596,
                "label": "Ocate",
                "label_ar": "أوكيت",
                "label_fr": "Ocate"
            },
            {
                "value": 12597,
                "label": "Occidental",
                "label_ar": "أوكسيدنتال",
                "label_fr": "Occidental"
            },
            {
                "value": 12598,
                "label": "Occoquan",
                "label_ar": "Occoquan",
                "label_fr": "Occoquan"
            },
            {
                "value": 12599,
                "label": "Ocean City",
                "label_ar": "مدينة المحيط",
                "label_fr": "Ocean City"
            },
            {
                "value": 12600,
                "label": "Ocean Gate",
                "label_ar": "بوابة المحيط",
                "label_fr": "Porte de l'océan"
            },
            {
                "value": 12601,
                "label": "Ocean Grove",
                "label_ar": "أوشن جروف",
                "label_fr": "Ocean Grove"
            },
            {
                "value": 12602,
                "label": "Ocean Isle Beach",
                "label_ar": "شاطئ المحيط جزيرة",
                "label_fr": "Plage d'Ocean Isle"
            },
            {
                "value": 12603,
                "label": "Ocean Park",
                "label_ar": "منتزه المحيط",
                "label_fr": "Ocean Park"
            },
            {
                "value": 12604,
                "label": "Ocean Shores",
                "label_ar": "شواطئ المحيط",
                "label_fr": "Ocean Shores"
            },
            {
                "value": 12605,
                "label": "Ocean Springs",
                "label_ar": "أوشن سبرينغز",
                "label_fr": "Ocean Springs"
            },
            {
                "value": 12606,
                "label": "Ocean View",
                "label_ar": "عرض المحيط",
                "label_fr": "vue sur l'océan"
            },
            {
                "value": 12607,
                "label": "Oceana",
                "label_ar": "أوشيانا",
                "label_fr": "Oceana"
            },
            {
                "value": 12608,
                "label": "Oceano",
                "label_ar": "أوشينو",
                "label_fr": "Oceano"
            },
            {
                "value": 12609,
                "label": "Oceanport",
                "label_ar": "ميناء المحيط",
                "label_fr": "Oceanport"
            },
            {
                "value": 12610,
                "label": "Oceanside",
                "label_ar": "أوشنسايد",
                "label_fr": "Oceanside"
            },
            {
                "value": 12611,
                "label": "Oceanville",
                "label_ar": "أوشنفيل",
                "label_fr": "Oceanville"
            },
            {
                "value": 12612,
                "label": "Ochelata",
                "label_ar": "Ochelata",
                "label_fr": "Ochelata"
            },
            {
                "value": 12613,
                "label": "Ocheyedan",
                "label_ar": "Ocheyedan",
                "label_fr": "Ocheyedan"
            },
            {
                "value": 12614,
                "label": "Ochlocknee",
                "label_ar": "Ochlocknee",
                "label_fr": "Ochlocknee"
            },
            {
                "value": 12615,
                "label": "Ocilla",
                "label_ar": "أوسيللا",
                "label_fr": "Ocilla"
            },
            {
                "value": 12616,
                "label": "Ocklawaha",
                "label_ar": "أشكلواها",
                "label_fr": "Ocklawaha"
            },
            {
                "value": 12617,
                "label": "Ocoee",
                "label_ar": "Ocoee",
                "label_fr": "Ocoee"
            },
            {
                "value": 12618,
                "label": "Oconomowoc",
                "label_ar": "أوكونومووك",
                "label_fr": "Oconomowoc"
            },
            {
                "value": 12619,
                "label": "Oconto",
                "label_ar": "أوكونتو",
                "label_fr": "Oconto"
            },
            {
                "value": 12620,
                "label": "Oconto Falls",
                "label_ar": "شلالات أوكونتو",
                "label_fr": "Oconto Falls"
            },
            {
                "value": 12621,
                "label": "Ocotillo",
                "label_ar": "أوكوتيلو",
                "label_fr": "Ocotillo"
            },
            {
                "value": 12622,
                "label": "Odanah",
                "label_ar": "أودانة",
                "label_fr": "Odanah"
            },
            {
                "value": 12623,
                "label": "Odebolt",
                "label_ar": "أودبولت",
                "label_fr": "Odebolt"
            },
            {
                "value": 12624,
                "label": "Odell",
                "label_ar": "أوديل",
                "label_fr": "Odell"
            },
            {
                "value": 12625,
                "label": "Odem",
                "label_ar": "أوديم",
                "label_fr": "Odem"
            },
            {
                "value": 12626,
                "label": "Oden",
                "label_ar": "اودين",
                "label_fr": "Oden"
            },
            {
                "value": 12627,
                "label": "Odenton",
                "label_ar": "أودنتون",
                "label_fr": "Odenton"
            },
            {
                "value": 12628,
                "label": "Odenville",
                "label_ar": "أودنفيل",
                "label_fr": "Odenville"
            },
            {
                "value": 12629,
                "label": "Odessa",
                "label_ar": "أوديسا",
                "label_fr": "Odessa"
            },
            {
                "value": 12630,
                "label": "Odin",
                "label_ar": "أودين",
                "label_fr": "Odin"
            },
            {
                "value": 12631,
                "label": "Odon",
                "label_ar": "أودون",
                "label_fr": "Odon"
            },
            {
                "value": 12632,
                "label": "Odum",
                "label_ar": "أودوم",
                "label_fr": "Odum"
            },
            {
                "value": 12633,
                "label": "Oelrichs",
                "label_ar": "Oelrichs",
                "label_fr": "Oelrichs"
            },
            {
                "value": 12634,
                "label": "Oelwein",
                "label_ar": "أولوين",
                "label_fr": "Oelwein"
            },
            {
                "value": 12635,
                "label": "Ogallah",
                "label_ar": "عجب الله",
                "label_fr": "Ogallah"
            },
            {
                "value": 12636,
                "label": "Ogallala",
                "label_ar": "أوجالالا",
                "label_fr": "Ogallala"
            },
            {
                "value": 12637,
                "label": "Ogden",
                "label_ar": "أوغدن",
                "label_fr": "Ogden"
            },
            {
                "value": 12638,
                "label": "Ogdensburg",
                "label_ar": "أوغدينسبورغ",
                "label_fr": "Ogdensburg"
            },
            {
                "value": 12639,
                "label": "Ogema",
                "label_ar": "اوجيما",
                "label_fr": "Ogema"
            },
            {
                "value": 12640,
                "label": "Ogilvie",
                "label_ar": "أوجيلفي",
                "label_fr": "Ogilvie"
            },
            {
                "value": 12641,
                "label": "Oglesby",
                "label_ar": "أوجليسبي",
                "label_fr": "Oglesby"
            },
            {
                "value": 12642,
                "label": "Oglethorpe",
                "label_ar": "أوجليثورب",
                "label_fr": "Oglethorpe"
            },
            {
                "value": 12643,
                "label": "Ogunquit",
                "label_ar": "أوغونكويت",
                "label_fr": "Ogunquit"
            },
            {
                "value": 12644,
                "label": "Ohatchee",
                "label_ar": "اهاتشي",
                "label_fr": "Ohatchee"
            },
            {
                "value": 12645,
                "label": "Ohio",
                "label_ar": "أوهايو",
                "label_fr": "Ohio"
            },
            {
                "value": 12646,
                "label": "Ohio City",
                "label_ar": "مدينة أوهايو",
                "label_fr": "Ohio City"
            },
            {
                "value": 12647,
                "label": "Ohiopyle",
                "label_ar": "أوهيوبيل",
                "label_fr": "Ohiopyle"
            },
            {
                "value": 12648,
                "label": "Oil City",
                "label_ar": "مدينة النفط",
                "label_fr": "Ville pétrolière"
            },
            {
                "value": 12649,
                "label": "Oil Springs",
                "label_ar": "الينابيع الزيتية",
                "label_fr": "Ressorts à huile"
            },
            {
                "value": 12650,
                "label": "Oilton",
                "label_ar": "أويلتون",
                "label_fr": "Oilton"
            },
            {
                "value": 12651,
                "label": "Ojai",
                "label_ar": "أوجاي",
                "label_fr": "Ojai"
            },
            {
                "value": 12652,
                "label": "Ojo Caliente",
                "label_ar": "أوجو كالينتي",
                "label_fr": "Ojo Caliente"
            },
            {
                "value": 12653,
                "label": "Okahumpka",
                "label_ar": "أوكاهومبكا",
                "label_fr": "Okahumpka"
            },
            {
                "value": 12654,
                "label": "Okanogan",
                "label_ar": "أوكانوجان",
                "label_fr": "Okanogan"
            },
            {
                "value": 12655,
                "label": "Okarche",
                "label_ar": "Okarche",
                "label_fr": "Okarche"
            },
            {
                "value": 12656,
                "label": "Okatie",
                "label_ar": "اكاتي",
                "label_fr": "Okatie"
            },
            {
                "value": 12657,
                "label": "Okay",
                "label_ar": "حسنا",
                "label_fr": "d'accord"
            },
            {
                "value": 12658,
                "label": "Okeana",
                "label_ar": "أوكينا",
                "label_fr": "Okeana"
            },
            {
                "value": 12659,
                "label": "Okeechobee",
                "label_ar": "أوكيشوبي",
                "label_fr": "Okeechobee"
            },
            {
                "value": 12660,
                "label": "Okeene",
                "label_ar": "أوكين",
                "label_fr": "Okeene"
            },
            {
                "value": 12661,
                "label": "Okemah",
                "label_ar": "اوكيما",
                "label_fr": "Okemah"
            },
            {
                "value": 12662,
                "label": "Okemos",
                "label_ar": "أوكيموس",
                "label_fr": "Okemos"
            },
            {
                "value": 12663,
                "label": "Oketo",
                "label_ar": "اوكيتو",
                "label_fr": "Oketo"
            },
            {
                "value": 12664,
                "label": "Oklahoma City",
                "label_ar": "أوكلاهوما سيتي",
                "label_fr": "La Ville d'Oklahoma"
            },
            {
                "value": 12665,
                "label": "Oklaunion",
                "label_ar": "أوكلاونيون",
                "label_fr": "Oklaunion"
            },
            {
                "value": 12666,
                "label": "Oklee",
                "label_ar": "حسنا",
                "label_fr": "Oklee"
            },
            {
                "value": 12667,
                "label": "Okmulgee",
                "label_ar": "اوكمولجي",
                "label_fr": "Okmulgee"
            },
            {
                "value": 12668,
                "label": "Okoboji",
                "label_ar": "أوكوبوجي",
                "label_fr": "Okoboji"
            },
            {
                "value": 12669,
                "label": "Okolona",
                "label_ar": "أوكولونا",
                "label_fr": "Okolona"
            },
            {
                "value": 12670,
                "label": "Oktaha",
                "label_ar": "أكتاها",
                "label_fr": "Oktaha"
            },
            {
                "value": 12671,
                "label": "Ola",
                "label_ar": "علا",
                "label_fr": "Ola"
            },
            {
                "value": 12672,
                "label": "Olalla",
                "label_ar": "أولالا",
                "label_fr": "Olalla"
            },
            {
                "value": 12673,
                "label": "Olancha",
                "label_ar": "أولانشا",
                "label_fr": "Olancha"
            },
            {
                "value": 12674,
                "label": "Olanta",
                "label_ar": "أولانتا",
                "label_fr": "Olanta"
            },
            {
                "value": 12675,
                "label": "Olathe",
                "label_ar": "أولاثي",
                "label_fr": "Olathe"
            },
            {
                "value": 12676,
                "label": "Olaton",
                "label_ar": "أولاتون",
                "label_fr": "Olaton"
            },
            {
                "value": 12677,
                "label": "Old Bethpage",
                "label_ar": "قديم Bethpage",
                "label_fr": "Vieux Bethpage"
            },
            {
                "value": 12678,
                "label": "Old Bridge",
                "label_ar": "جسر قديم",
                "label_fr": "Vieux pont"
            },
            {
                "value": 12679,
                "label": "Old Chatham",
                "label_ar": "تشاتام القديمة",
                "label_fr": "Vieux Chatham"
            },
            {
                "value": 12680,
                "label": "Old Fields",
                "label_ar": "الحقول القديمة",
                "label_fr": "Vieux champs"
            },
            {
                "value": 12681,
                "label": "Old Forge",
                "label_ar": "أولد فورج",
                "label_fr": "Ancienne forge"
            },
            {
                "value": 12682,
                "label": "Old Fort",
                "label_ar": "القلعة القديمة",
                "label_fr": "Vieux fort"
            },
            {
                "value": 12683,
                "label": "Old Greenwich",
                "label_ar": "غرينتش القديمة",
                "label_fr": "Old Greenwich"
            },
            {
                "value": 12684,
                "label": "Old Hickory",
                "label_ar": "هيكوري القديم",
                "label_fr": "Vieux Hickory"
            },
            {
                "value": 12685,
                "label": "Old Kotzebue",
                "label_ar": "Kotzebue القديمة",
                "label_fr": "Vieux Kotzebue"
            },
            {
                "value": 12686,
                "label": "Old Lyme",
                "label_ar": "أولد لايم",
                "label_fr": "Vieux Lyme"
            },
            {
                "value": 12687,
                "label": "Old Ocean",
                "label_ar": "المحيط القديم",
                "label_fr": "Vieil océan"
            },
            {
                "value": 12688,
                "label": "Old Orchard Beach",
                "label_ar": "شاطئ أورشارد القديم",
                "label_fr": "Old Orchard Beach"
            },
            {
                "value": 12689,
                "label": "Old Pekin",
                "label_ar": "بيكين القديمة",
                "label_fr": "Vieux Pékin"
            },
            {
                "value": 12690,
                "label": "Old Saybrook",
                "label_ar": "قديم سايبروك",
                "label_fr": "Old Saybrook"
            },
            {
                "value": 12691,
                "label": "Old Town",
                "label_ar": "البلدة القديمة",
                "label_fr": "Vieille ville"
            },
            {
                "value": 12692,
                "label": "Old Westbury",
                "label_ar": "قديم ويستبري",
                "label_fr": "Old Westbury"
            },
            {
                "value": 12693,
                "label": "Oldfort",
                "label_ar": "أولدفورت",
                "label_fr": "Oldfort"
            },
            {
                "value": 12694,
                "label": "Oldsmar",
                "label_ar": "أولدسمار",
                "label_fr": "Oldsmar"
            },
            {
                "value": 12695,
                "label": "Oldtown",
                "label_ar": "أولدتاون",
                "label_fr": "Vieille ville"
            },
            {
                "value": 12696,
                "label": "Oldwick",
                "label_ar": "أولدويك",
                "label_fr": "Oldwick"
            },
            {
                "value": 12697,
                "label": "Olean",
                "label_ar": "أوليان",
                "label_fr": "Olean"
            },
            {
                "value": 12698,
                "label": "Oley",
                "label_ar": "اولي",
                "label_fr": "Oley"
            },
            {
                "value": 12699,
                "label": "Olin",
                "label_ar": "أولين",
                "label_fr": "Olin"
            },
            {
                "value": 12700,
                "label": "Olive Branch",
                "label_ar": "غصن الزيتون",
                "label_fr": "Branche d'olivier"
            },
            {
                "value": 12701,
                "label": "Olive Hill",
                "label_ar": "أوليف هيل",
                "label_fr": "Olive Hill"
            },
            {
                "value": 12702,
                "label": "Olivebridge",
                "label_ar": "أوليفبريدج",
                "label_fr": "Olivebridge"
            },
            {
                "value": 12703,
                "label": "Oliveburg",
                "label_ar": "أوليفبورغ",
                "label_fr": "Oliveburg"
            },
            {
                "value": 12704,
                "label": "Olivehurst",
                "label_ar": "أوليفهورست",
                "label_fr": "Olivehurst"
            },
            {
                "value": 12705,
                "label": "Oliver Springs",
                "label_ar": "أوليفر سبرينجز",
                "label_fr": "Oliver Springs"
            },
            {
                "value": 12706,
                "label": "Olivet",
                "label_ar": "زيتون",
                "label_fr": "Olivet"
            },
            {
                "value": 12707,
                "label": "Olivette",
                "label_ar": "زيتون",
                "label_fr": "Olivette"
            },
            {
                "value": 12708,
                "label": "Olivia",
                "label_ar": "أوليفيا",
                "label_fr": "Olivia"
            },
            {
                "value": 12709,
                "label": "Olla",
                "label_ar": "أولا",
                "label_fr": "Olla"
            },
            {
                "value": 12710,
                "label": "Ollie",
                "label_ar": "أولي",
                "label_fr": "Ollie"
            },
            {
                "value": 12711,
                "label": "Olmito",
                "label_ar": "أولميتو",
                "label_fr": "Olmito"
            },
            {
                "value": 12712,
                "label": "Olmsted",
                "label_ar": "أولمستيد",
                "label_fr": "Olmsted"
            },
            {
                "value": 12713,
                "label": "Olmsted Falls",
                "label_ar": "شلالات أولمستيد",
                "label_fr": "Olmsted Falls"
            },
            {
                "value": 12714,
                "label": "Olmstedville",
                "label_ar": "أولمستيدفيل",
                "label_fr": "Olmstedville"
            },
            {
                "value": 12715,
                "label": "Olney",
                "label_ar": "أولني",
                "label_fr": "Olney"
            },
            {
                "value": 12716,
                "label": "Olney Springs",
                "label_ar": "أولني سبرينجز",
                "label_fr": "Olney Springs"
            },
            {
                "value": 12717,
                "label": "Olpe",
                "label_ar": "أولبي",
                "label_fr": "Olpe"
            },
            {
                "value": 12718,
                "label": "Olsburg",
                "label_ar": "أولسبورغ",
                "label_fr": "Olsburg"
            },
            {
                "value": 12719,
                "label": "Olton",
                "label_ar": "أولتون",
                "label_fr": "Olton"
            },
            {
                "value": 12720,
                "label": "Olympia",
                "label_ar": "أولمبيا",
                "label_fr": "Olympie"
            },
            {
                "value": 12721,
                "label": "Olympia Fields",
                "label_ar": "حقول أولمبيا",
                "label_fr": "Champs d'Olympia"
            },
            {
                "value": 12722,
                "label": "Olyphant",
                "label_ar": "أوليفانت",
                "label_fr": "Olyphant"
            },
            {
                "value": 12723,
                "label": "Omaha",
                "label_ar": "أوماها",
                "label_fr": "Omaha"
            },
            {
                "value": 12724,
                "label": "Omak",
                "label_ar": "اوماك",
                "label_fr": "Omak"
            },
            {
                "value": 12725,
                "label": "Omega",
                "label_ar": "أوميغا",
                "label_fr": "Oméga"
            },
            {
                "value": 12726,
                "label": "Omena",
                "label_ar": "أومينا",
                "label_fr": "Omena"
            },
            {
                "value": 12727,
                "label": "Omro",
                "label_ar": "أومرو",
                "label_fr": "Omro"
            },
            {
                "value": 12728,
                "label": "Ona",
                "label_ar": "على",
                "label_fr": "Sur un"
            },
            {
                "value": 12729,
                "label": "Onaga",
                "label_ar": "اوناجا",
                "label_fr": "Onaga"
            },
            {
                "value": 12730,
                "label": "Onalaska",
                "label_ar": "أونالاسكا",
                "label_fr": "Onalaska"
            },
            {
                "value": 12731,
                "label": "Onamia",
                "label_ar": "أوناميا",
                "label_fr": "Onamia"
            },
            {
                "value": 12732,
                "label": "Onancock",
                "label_ar": "أونانكوك",
                "label_fr": "Onancock"
            },
            {
                "value": 12733,
                "label": "Onarga",
                "label_ar": "أونارجا",
                "label_fr": "Onarga"
            },
            {
                "value": 12734,
                "label": "Onawa",
                "label_ar": "أوناوا",
                "label_fr": "Onawa"
            },
            {
                "value": 12735,
                "label": "Onaway",
                "label_ar": "على الطريق",
                "label_fr": "Onaway"
            },
            {
                "value": 12736,
                "label": "Oneco",
                "label_ar": "اونيكو",
                "label_fr": "Oneco"
            },
            {
                "value": 12737,
                "label": "Oneida",
                "label_ar": "أونيدا",
                "label_fr": "Oneida"
            },
            {
                "value": 12738,
                "label": "Onekama",
                "label_ar": "اونيكاما",
                "label_fr": "Onekama"
            },
            {
                "value": 12739,
                "label": "Oneonta",
                "label_ar": "أونيونتا",
                "label_fr": "Oneonta"
            },
            {
                "value": 12740,
                "label": "Onia",
                "label_ar": "أونيا",
                "label_fr": "Onia"
            },
            {
                "value": 12741,
                "label": "Onley",
                "label_ar": "أونلي",
                "label_fr": "Onley"
            },
            {
                "value": 12742,
                "label": "Onondaga",
                "label_ar": "أونونداغا",
                "label_fr": "Onondaga"
            },
            {
                "value": 12743,
                "label": "Onset",
                "label_ar": "بداية",
                "label_fr": "Début"
            },
            {
                "value": 12744,
                "label": "Onslow",
                "label_ar": "أونسلو",
                "label_fr": "Onslow"
            },
            {
                "value": 12745,
                "label": "Onsted",
                "label_ar": "أونستيد",
                "label_fr": "Onsted"
            },
            {
                "value": 12746,
                "label": "Ontario",
                "label_ar": "أونتاريو",
                "label_fr": "Ontario"
            },
            {
                "value": 12747,
                "label": "Ontonagon",
                "label_ar": "اونتوناجون",
                "label_fr": "Ontonagon"
            },
            {
                "value": 12748,
                "label": "Onyx",
                "label_ar": "أونيكس",
                "label_fr": "Onyx"
            },
            {
                "value": 12749,
                "label": "Oologah",
                "label_ar": "Oologah",
                "label_fr": "Oologah"
            },
            {
                "value": 12750,
                "label": "Ooltewah",
                "label_ar": "أولتيوة",
                "label_fr": "Ooltewah"
            },
            {
                "value": 12751,
                "label": "Oostburg",
                "label_ar": "أوستبورغ",
                "label_fr": "Oostburg"
            },
            {
                "value": 12752,
                "label": "Opa-locka",
                "label_ar": "أوبا لوكا",
                "label_fr": "Opa-locka"
            },
            {
                "value": 12753,
                "label": "Opdyke",
                "label_ar": "أوبديك",
                "label_fr": "Opdyke"
            },
            {
                "value": 12754,
                "label": "Opelika",
                "label_ar": "أوبيليكا",
                "label_fr": "Opelika"
            },
            {
                "value": 12755,
                "label": "Opelousas",
                "label_ar": "أوبلوساس",
                "label_fr": "Opelousas"
            },
            {
                "value": 12756,
                "label": "Opheim",
                "label_ar": "أوفايم",
                "label_fr": "Opheim"
            },
            {
                "value": 12757,
                "label": "Ophiem",
                "label_ar": "أوفييم",
                "label_fr": "Ophiem"
            },
            {
                "value": 12758,
                "label": "Opolis",
                "label_ar": "أوبوليس",
                "label_fr": "Opolis"
            },
            {
                "value": 12759,
                "label": "Opp",
                "label_ar": "مقابل",
                "label_fr": "Opp"
            },
            {
                "value": 12760,
                "label": "Oquawka",
                "label_ar": "اوكواوكا",
                "label_fr": "Oquawka"
            },
            {
                "value": 12761,
                "label": "Oracle",
                "label_ar": "وحي",
                "label_fr": "Oracle"
            },
            {
                "value": 12762,
                "label": "Oradell",
                "label_ar": "اوراديل",
                "label_fr": "Oradell"
            },
            {
                "value": 12763,
                "label": "Oran",
                "label_ar": "وهران",
                "label_fr": "Oran"
            },
            {
                "value": 12764,
                "label": "Orange",
                "label_ar": "البرتقالي",
                "label_fr": "Orange"
            },
            {
                "value": 12765,
                "label": "Orange Beach",
                "label_ar": "أورانج بيتش",
                "label_fr": "Plage d'Orange"
            },
            {
                "value": 12766,
                "label": "Orange City",
                "label_ar": "أورانج سيتي",
                "label_fr": "Ville d'Orange"
            },
            {
                "value": 12767,
                "label": "Orange Cove",
                "label_ar": "أورانج كوف",
                "label_fr": "Orange Cove"
            },
            {
                "value": 12768,
                "label": "Orange Grove",
                "label_ar": "حرش البرتقال",
                "label_fr": "Orangeraie"
            },
            {
                "value": 12769,
                "label": "Orange Park",
                "label_ar": "أورانج بارك",
                "label_fr": "Orange Park"
            },
            {
                "value": 12770,
                "label": "Orange Springs",
                "label_ar": "أورانج سبرينغز",
                "label_fr": "Orange Springs"
            },
            {
                "value": 12771,
                "label": "Orangeburg",
                "label_ar": "أورانجبورج",
                "label_fr": "Orangeburg"
            },
            {
                "value": 12772,
                "label": "Orangevale",
                "label_ar": "أورانجيفال",
                "label_fr": "Orangevale"
            },
            {
                "value": 12773,
                "label": "Orangeville",
                "label_ar": "أورانجفيل",
                "label_fr": "Orangeville"
            },
            {
                "value": 12774,
                "label": "Orchard",
                "label_ar": "بستان",
                "label_fr": "Verger"
            },
            {
                "value": 12775,
                "label": "Orchard Hill",
                "label_ar": "اوركارد هيل",
                "label_fr": "Orchard Hill"
            },
            {
                "value": 12776,
                "label": "Orchard Park",
                "label_ar": "اوركارد بارك",
                "label_fr": "Orchard Park"
            },
            {
                "value": 12777,
                "label": "Ord",
                "label_ar": "Ord",
                "label_fr": "Ord"
            },
            {
                "value": 12778,
                "label": "Orderville",
                "label_ar": "Orderville",
                "label_fr": "Orderville"
            },
            {
                "value": 12779,
                "label": "Ordway",
                "label_ar": "أوردواي",
                "label_fr": "Ordway"
            },
            {
                "value": 12780,
                "label": "Ore City",
                "label_ar": "مدينة خام",
                "label_fr": "Ore City"
            },
            {
                "value": 12781,
                "label": "Oreana",
                "label_ar": "أوريا",
                "label_fr": "Oreana"
            },
            {
                "value": 12782,
                "label": "Orefield",
                "label_ar": "أوريفيلد",
                "label_fr": "Orefield"
            },
            {
                "value": 12783,
                "label": "Oregon",
                "label_ar": "أوريغون",
                "label_fr": "Oregon"
            },
            {
                "value": 12784,
                "label": "Oregon City",
                "label_ar": "مدينة أوريغون",
                "label_fr": "Oregon City"
            },
            {
                "value": 12785,
                "label": "Oregon House",
                "label_ar": "أوريغون هاوس",
                "label_fr": "Maison de l'Oregon"
            },
            {
                "value": 12786,
                "label": "Oregonia",
                "label_ar": "أوريغونيا",
                "label_fr": "Oregonia"
            },
            {
                "value": 12787,
                "label": "Oreland",
                "label_ar": "أورلاند",
                "label_fr": "Oreland"
            },
            {
                "value": 12788,
                "label": "Orem",
                "label_ar": "اوريم",
                "label_fr": "Orem"
            },
            {
                "value": 12789,
                "label": "Orford",
                "label_ar": "أورفورد",
                "label_fr": "Orford"
            },
            {
                "value": 12790,
                "label": "Orfordville",
                "label_ar": "أورفوردفيل",
                "label_fr": "Orfordville"
            },
            {
                "value": 12791,
                "label": "Orient",
                "label_ar": "توجيه",
                "label_fr": "Orient"
            },
            {
                "value": 12792,
                "label": "Oriental",
                "label_ar": "شرقية",
                "label_fr": "Oriental"
            },
            {
                "value": 12793,
                "label": "Orinda",
                "label_ar": "أوريندا",
                "label_fr": "Orinda"
            },
            {
                "value": 12794,
                "label": "Orion",
                "label_ar": "أوريون",
                "label_fr": "Orion"
            },
            {
                "value": 12795,
                "label": "Oriska",
                "label_ar": "أوريسكا",
                "label_fr": "Oriska"
            },
            {
                "value": 12796,
                "label": "Oriskany",
                "label_ar": "اوريسكاني",
                "label_fr": "Oriskany"
            },
            {
                "value": 12797,
                "label": "Oriskany Falls",
                "label_ar": "شلالات اوريسكاني",
                "label_fr": "Chutes d'Oriskany"
            },
            {
                "value": 12798,
                "label": "Orland",
                "label_ar": "أورلاند",
                "label_fr": "Orland"
            },
            {
                "value": 12799,
                "label": "Orland Park",
                "label_ar": "أورلاند بارك",
                "label_fr": "Parc d'Orland"
            },
            {
                "value": 12800,
                "label": "Orlando",
                "label_ar": "أورلاندو",
                "label_fr": "Orlando"
            },
            {
                "value": 12801,
                "label": "Orleans",
                "label_ar": "اورليانز",
                "label_fr": "Orléans"
            },
            {
                "value": 12802,
                "label": "Orlinda",
                "label_ar": "أورليندا",
                "label_fr": "Orlinda"
            },
            {
                "value": 12803,
                "label": "Orma",
                "label_ar": "أورما",
                "label_fr": "Orma"
            },
            {
                "value": 12804,
                "label": "Ormond Beach",
                "label_ar": "شاطئ أورموند",
                "label_fr": "Plage d'Ormond"
            },
            {
                "value": 12805,
                "label": "Orofino",
                "label_ar": "أوروفينو",
                "label_fr": "Orofino"
            },
            {
                "value": 12806,
                "label": "Orondo",
                "label_ar": "أوروندو",
                "label_fr": "Orondo"
            },
            {
                "value": 12807,
                "label": "Orono",
                "label_ar": "أورونو",
                "label_fr": "Orono"
            },
            {
                "value": 12808,
                "label": "Oronoco",
                "label_ar": "أورونوكو",
                "label_fr": "Oronoco"
            },
            {
                "value": 12809,
                "label": "Oronogo",
                "label_ar": "أورونوجو",
                "label_fr": "Oronogo"
            },
            {
                "value": 12810,
                "label": "Orosi",
                "label_ar": "أوروسي",
                "label_fr": "Orosi"
            },
            {
                "value": 12811,
                "label": "Oroville",
                "label_ar": "أوروفيل",
                "label_fr": "Oroville"
            },
            {
                "value": 12812,
                "label": "Orrington",
                "label_ar": "أورينجتون",
                "label_fr": "Orrington"
            },
            {
                "value": 12813,
                "label": "Orrs Island",
                "label_ar": "جزيرة أورس",
                "label_fr": "Île d'Orrs"
            },
            {
                "value": 12814,
                "label": "Orrstown",
                "label_ar": "أورستاون",
                "label_fr": "Orrstown"
            },
            {
                "value": 12815,
                "label": "Orrtanna",
                "label_ar": "أورتانا",
                "label_fr": "Orrtanna"
            },
            {
                "value": 12816,
                "label": "Orrville",
                "label_ar": "أورفيل",
                "label_fr": "Orrville"
            },
            {
                "value": 12817,
                "label": "Orting",
                "label_ar": "أورتنج",
                "label_fr": "Orting"
            },
            {
                "value": 12818,
                "label": "Ortonville",
                "label_ar": "أورتونفيل",
                "label_fr": "Ortonville"
            },
            {
                "value": 12819,
                "label": "Orwell",
                "label_ar": "أورويل",
                "label_fr": "Orwell"
            },
            {
                "value": 12820,
                "label": "Orwigsburg",
                "label_ar": "أورويغسبورغ",
                "label_fr": "Orwigsburg"
            },
            {
                "value": 12821,
                "label": "Osage",
                "label_ar": "أوساج",
                "label_fr": "Osage"
            },
            {
                "value": 12822,
                "label": "Osage Beach",
                "label_ar": "شاطئ أوساج",
                "label_fr": "Plage d'Osage"
            },
            {
                "value": 12823,
                "label": "Osage City",
                "label_ar": "مدينة أوساج",
                "label_fr": "Osage City"
            },
            {
                "value": 12824,
                "label": "Osakis",
                "label_ar": "أوساكيس",
                "label_fr": "Osakis"
            },
            {
                "value": 12825,
                "label": "Osawatomie",
                "label_ar": "أوساواتومي",
                "label_fr": "Osawatomie"
            },
            {
                "value": 12826,
                "label": "Osborn",
                "label_ar": "أوزبورن",
                "label_fr": "Osborn"
            },
            {
                "value": 12827,
                "label": "Osborne",
                "label_ar": "أوزبورن",
                "label_fr": "Osborne"
            },
            {
                "value": 12828,
                "label": "Osburn",
                "label_ar": "أوزبورن",
                "label_fr": "Osburn"
            },
            {
                "value": 12829,
                "label": "Osceola",
                "label_ar": "أوسيولا",
                "label_fr": "Osceola"
            },
            {
                "value": 12830,
                "label": "Osceola Mills",
                "label_ar": "أوسيولا ميلز",
                "label_fr": "Osceola Mills"
            },
            {
                "value": 12831,
                "label": "Osco",
                "label_ar": "أوسكو",
                "label_fr": "Osco"
            },
            {
                "value": 12832,
                "label": "Oscoda",
                "label_ar": "اوسكودا",
                "label_fr": "Oscoda"
            },
            {
                "value": 12833,
                "label": "Osgood",
                "label_ar": "أوسجود",
                "label_fr": "Osgood"
            },
            {
                "value": 12834,
                "label": "Oshkosh",
                "label_ar": "أوشكوش",
                "label_fr": "Oshkosh"
            },
            {
                "value": 12835,
                "label": "Oshtemo",
                "label_ar": "أوشتمو",
                "label_fr": "Oshtemo"
            },
            {
                "value": 12836,
                "label": "Oskaloosa",
                "label_ar": "أوسكالوسا",
                "label_fr": "Oskaloosa"
            },
            {
                "value": 12837,
                "label": "Oslo",
                "label_ar": "أوسلو",
                "label_fr": "Oslo"
            },
            {
                "value": 12838,
                "label": "Osmond",
                "label_ar": "أوزموند",
                "label_fr": "Osmond"
            },
            {
                "value": 12839,
                "label": "Osprey",
                "label_ar": "اوسبري",
                "label_fr": "Balbuzard"
            },
            {
                "value": 12840,
                "label": "Osseo",
                "label_ar": "أوسيو",
                "label_fr": "Osseo"
            },
            {
                "value": 12841,
                "label": "Ossian",
                "label_ar": "أوسيان",
                "label_fr": "Ossian"
            },
            {
                "value": 12842,
                "label": "Ossineke",
                "label_ar": "أوسينكي",
                "label_fr": "Ossineke"
            },
            {
                "value": 12843,
                "label": "Ossining",
                "label_ar": "أوسينينج",
                "label_fr": "Ossining"
            },
            {
                "value": 12844,
                "label": "Ossipee",
                "label_ar": "أوسيبي",
                "label_fr": "Ossipee"
            },
            {
                "value": 12845,
                "label": "Osteen",
                "label_ar": "أوستين",
                "label_fr": "Osteen"
            },
            {
                "value": 12846,
                "label": "Osterville",
                "label_ar": "أوسترفيل",
                "label_fr": "Osterville"
            },
            {
                "value": 12847,
                "label": "Ostrander",
                "label_ar": "اوستراندر",
                "label_fr": "Ostrander"
            },
            {
                "value": 12848,
                "label": "Oswego",
                "label_ar": "أوسويغو",
                "label_fr": "Oswego"
            },
            {
                "value": 12849,
                "label": "Osyka",
                "label_ar": "أوسيكا",
                "label_fr": "Osyka"
            },
            {
                "value": 12850,
                "label": "Otego",
                "label_ar": "Otego",
                "label_fr": "Otego"
            },
            {
                "value": 12851,
                "label": "Othello",
                "label_ar": "عطيل",
                "label_fr": "Othello"
            },
            {
                "value": 12852,
                "label": "Otho",
                "label_ar": "أوثو",
                "label_fr": "Otho"
            },
            {
                "value": 12853,
                "label": "Otis",
                "label_ar": "أوتيس",
                "label_fr": "Otis"
            },
            {
                "value": 12854,
                "label": "Otis Orchards",
                "label_ar": "أوتيس بساتين",
                "label_fr": "Vergers d'Otis"
            },
            {
                "value": 12855,
                "label": "Otisco",
                "label_ar": "أوتيسكو",
                "label_fr": "Otisco"
            },
            {
                "value": 12856,
                "label": "Otisville",
                "label_ar": "أوتيسفيل",
                "label_fr": "Otisville"
            },
            {
                "value": 12857,
                "label": "Otoe",
                "label_ar": "أوتو",
                "label_fr": "Otoe"
            },
            {
                "value": 12858,
                "label": "Otsego",
                "label_ar": "أوتسيغو",
                "label_fr": "Otsego"
            },
            {
                "value": 12859,
                "label": "Ottawa",
                "label_ar": "أوتاوا",
                "label_fr": "Ottawa"
            },
            {
                "value": 12860,
                "label": "Otter",
                "label_ar": "قندس",
                "label_fr": "Loutre"
            },
            {
                "value": 12861,
                "label": "Otter Lake",
                "label_ar": "بحيرة أوتر",
                "label_fr": "Otter Lake"
            },
            {
                "value": 12862,
                "label": "Otterbein",
                "label_ar": "أوتربين",
                "label_fr": "Otterbein"
            },
            {
                "value": 12863,
                "label": "Ottertail",
                "label_ar": "Ottertail",
                "label_fr": "Queue de loutre"
            },
            {
                "value": 12864,
                "label": "Otto",
                "label_ar": "أوتو",
                "label_fr": "Otto"
            },
            {
                "value": 12865,
                "label": "Ottosen",
                "label_ar": "أوتوسن",
                "label_fr": "Ottosen"
            },
            {
                "value": 12866,
                "label": "Ottoville",
                "label_ar": "أوتوفيل",
                "label_fr": "Ottoville"
            },
            {
                "value": 12867,
                "label": "Ottsville",
                "label_ar": "أوتسفيل",
                "label_fr": "Ottsville"
            },
            {
                "value": 12868,
                "label": "Ottumwa",
                "label_ar": "أوتوموا",
                "label_fr": "Ottumwa"
            },
            {
                "value": 12869,
                "label": "Otway",
                "label_ar": "أوتواي",
                "label_fr": "Otway"
            },
            {
                "value": 12870,
                "label": "Ouray",
                "label_ar": "أوراي",
                "label_fr": "Ouray"
            },
            {
                "value": 12871,
                "label": "Outing",
                "label_ar": "نزهة",
                "label_fr": "Sortie"
            },
            {
                "value": 12872,
                "label": "Outlook",
                "label_ar": "الآفاق",
                "label_fr": "Perspective"
            },
            {
                "value": 12873,
                "label": "Overbrook",
                "label_ar": "أوفربروك",
                "label_fr": "Overbrook"
            },
            {
                "value": 12874,
                "label": "Overgaard",
                "label_ar": "زائدة",
                "label_fr": "Overgaard"
            },
            {
                "value": 12875,
                "label": "Overland Park",
                "label_ar": "أوفرلاند بارك",
                "label_fr": "Overland Park"
            },
            {
                "value": 12876,
                "label": "Overpeck",
                "label_ar": "أوفيربيك",
                "label_fr": "Overpeck"
            },
            {
                "value": 12877,
                "label": "Overton",
                "label_ar": "أوفرتون",
                "label_fr": "Overton"
            },
            {
                "value": 12878,
                "label": "Ovid",
                "label_ar": "أوفيد",
                "label_fr": "Ovide"
            },
            {
                "value": 12879,
                "label": "Oviedo",
                "label_ar": "أوفييدو",
                "label_fr": "Oviedo"
            },
            {
                "value": 12880,
                "label": "Owaneco",
                "label_ar": "أوانيكو",
                "label_fr": "Owaneco"
            },
            {
                "value": 12881,
                "label": "Owasso",
                "label_ar": "أواسو",
                "label_fr": "Owasso"
            },
            {
                "value": 12882,
                "label": "Owatonna",
                "label_ar": "أواتونا",
                "label_fr": "Owatonna"
            },
            {
                "value": 12883,
                "label": "Owego",
                "label_ar": "أويجو",
                "label_fr": "Owego"
            },
            {
                "value": 12884,
                "label": "Owen",
                "label_ar": "أوين",
                "label_fr": "Owen"
            },
            {
                "value": 12885,
                "label": "Owens Cross Roads",
                "label_ar": "أوينز كروس رودز",
                "label_fr": "Owens Cross Roads"
            },
            {
                "value": 12886,
                "label": "Owensboro",
                "label_ar": "أوينسبورو",
                "label_fr": "Owensboro"
            },
            {
                "value": 12887,
                "label": "Owensburg",
                "label_ar": "اوينسبورغ",
                "label_fr": "Owensburg"
            },
            {
                "value": 12888,
                "label": "Owensville",
                "label_ar": "أوينزفيل",
                "label_fr": "Owensville"
            },
            {
                "value": 12889,
                "label": "Owenton",
                "label_ar": "أوينتون",
                "label_fr": "Owenton"
            },
            {
                "value": 12890,
                "label": "Owings",
                "label_ar": "ممتلكات",
                "label_fr": "Obligations"
            },
            {
                "value": 12891,
                "label": "Owings Mills",
                "label_ar": "أوينجز ميلز",
                "label_fr": "Owings Mills"
            },
            {
                "value": 12892,
                "label": "Owingsville",
                "label_ar": "أوينغسفيل",
                "label_fr": "Owingsville"
            },
            {
                "value": 12893,
                "label": "Owosso",
                "label_ar": "أووسو",
                "label_fr": "Owosso"
            },
            {
                "value": 12894,
                "label": "Oxford",
                "label_ar": "أكسفورد",
                "label_fr": "Oxford"
            },
            {
                "value": 12895,
                "label": "Oxford Junction",
                "label_ar": "تقاطع أكسفورد",
                "label_fr": "Oxford Junction"
            },
            {
                "value": 12896,
                "label": "Oxnard",
                "label_ar": "أوكسنارد",
                "label_fr": "Oxnard"
            },
            {
                "value": 12897,
                "label": "Oxon Hill",
                "label_ar": "أوكسون هيل",
                "label_fr": "Oxon Hill"
            },
            {
                "value": 12898,
                "label": "Oyster Bay",
                "label_ar": "أويستر باي",
                "label_fr": "Oyster Bay"
            },
            {
                "value": 12899,
                "label": "Ozan",
                "label_ar": "أوزان",
                "label_fr": "Ozan"
            },
            {
                "value": 12900,
                "label": "Ozark",
                "label_ar": "أوزارك",
                "label_fr": "Ozark"
            },
            {
                "value": 12901,
                "label": "Ozawkie",
                "label_ar": "أوزوكي",
                "label_fr": "Ozawkie"
            },
            {
                "value": 12902,
                "label": "Ozona",
                "label_ar": "أوزونا",
                "label_fr": "Ozona"
            },
            {
                "value": 12903,
                "label": "Ozone",
                "label_ar": "الأوزون",
                "label_fr": "Ozone"
            },
            {
                "value": 12904,
                "label": "Ozone Park",
                "label_ar": "أوزون بارك",
                "label_fr": "Parc Ozone"
            },
            {
                "value": 12905,
                "label": "Pa'auilo",
                "label_ar": "باويلو",
                "label_fr": "Pa'auilo"
            },
            {
                "value": 12906,
                "label": "Pablo",
                "label_ar": "بابلو",
                "label_fr": "Pablo"
            },
            {
                "value": 12907,
                "label": "Pacific",
                "label_ar": "المحيط الهادئ",
                "label_fr": "Pacifique"
            },
            {
                "value": 12908,
                "label": "Pacific City",
                "label_ar": "باسيفيك سيتي",
                "label_fr": "Pacific City"
            },
            {
                "value": 12909,
                "label": "Pacific Grove",
                "label_ar": "باسيفيك جروف",
                "label_fr": "Pacific Grove"
            },
            {
                "value": 12910,
                "label": "Pacific Junction",
                "label_ar": "تقاطع المحيط الهادئ",
                "label_fr": "Jonction du Pacifique"
            },
            {
                "value": 12911,
                "label": "Pacific Palisades",
                "label_ar": "حواجز المحيط الهادئ",
                "label_fr": "Pacific Palisades"
            },
            {
                "value": 12912,
                "label": "Pacifica",
                "label_ar": "باسيفيكا",
                "label_fr": "Pacifica"
            },
            {
                "value": 12913,
                "label": "Packwood",
                "label_ar": "باكوود",
                "label_fr": "Packwood"
            },
            {
                "value": 12914,
                "label": "Pacoima",
                "label_ar": "باكويما",
                "label_fr": "Pacoima"
            },
            {
                "value": 12915,
                "label": "Pacolet",
                "label_ar": "باكوليت",
                "label_fr": "Pacolet"
            },
            {
                "value": 12916,
                "label": "Paden",
                "label_ar": "بادين",
                "label_fr": "Paden"
            },
            {
                "value": 12917,
                "label": "Paden City",
                "label_ar": "مدينة بادين",
                "label_fr": "Paden City"
            },
            {
                "value": 12918,
                "label": "Paducah",
                "label_ar": "بادوكا",
                "label_fr": "Paducah"
            },
            {
                "value": 12919,
                "label": "Paeonian Springs",
                "label_ar": "الينابيع Paeonian",
                "label_fr": "Sources Paeonian"
            },
            {
                "value": 12920,
                "label": "Page",
                "label_ar": "الصفحة",
                "label_fr": "Page"
            },
            {
                "value": 12921,
                "label": "Pageland",
                "label_ar": "باجيلاند",
                "label_fr": "Pageland"
            },
            {
                "value": 12922,
                "label": "Pageton",
                "label_ar": "باجيتون",
                "label_fr": "Pageton"
            },
            {
                "value": 12923,
                "label": "Pagosa Springs",
                "label_ar": "باجوسا سبرينغز",
                "label_fr": "Pagosa Springs"
            },
            {
                "value": 12924,
                "label": "Pahala",
                "label_ar": "باهالا",
                "label_fr": "Pahala"
            },
            {
                "value": 12925,
                "label": "Pahoa",
                "label_ar": "باهوا",
                "label_fr": "Pahoa"
            },
            {
                "value": 12926,
                "label": "Pahokee",
                "label_ar": "باهوكي",
                "label_fr": "Pahokee"
            },
            {
                "value": 12927,
                "label": "Pahrump",
                "label_ar": "بارومب",
                "label_fr": "Pahrump"
            },
            {
                "value": 12928,
                "label": "Paia",
                "label_ar": "بايا",
                "label_fr": "Paia"
            },
            {
                "value": 12929,
                "label": "Paige",
                "label_ar": "بيج",
                "label_fr": "Paige"
            },
            {
                "value": 12930,
                "label": "Painesdale",
                "label_ar": "باينسديل",
                "label_fr": "Painesdale"
            },
            {
                "value": 12931,
                "label": "Painesville",
                "label_ar": "بينيسفيل",
                "label_fr": "Painesville"
            },
            {
                "value": 12932,
                "label": "Paint Lick",
                "label_ar": "لعق الطلاء",
                "label_fr": "Peinture à lécher"
            },
            {
                "value": 12933,
                "label": "Paint Rock",
                "label_ar": "بينت روك",
                "label_fr": "Paint Rock"
            },
            {
                "value": 12934,
                "label": "Painted Post",
                "label_ar": "وظيفة مرسومة",
                "label_fr": "Poteau peint"
            },
            {
                "value": 12935,
                "label": "Painter",
                "label_ar": "دهان",
                "label_fr": "Peintre"
            },
            {
                "value": 12936,
                "label": "Paintsville",
                "label_ar": "بينتسفيل",
                "label_fr": "Paintsville"
            },
            {
                "value": 12937,
                "label": "Pala",
                "label_ar": "بالا",
                "label_fr": "Pala"
            },
            {
                "value": 12938,
                "label": "Palacios",
                "label_ar": "بالاسيوس",
                "label_fr": "Palacios"
            },
            {
                "value": 12939,
                "label": "Palatine",
                "label_ar": "بالاتين",
                "label_fr": "Palatin"
            },
            {
                "value": 12940,
                "label": "Palatka",
                "label_ar": "بالاتكا",
                "label_fr": "Palatka"
            },
            {
                "value": 12941,
                "label": "Palermo",
                "label_ar": "باليرمو",
                "label_fr": "Palerme"
            },
            {
                "value": 12942,
                "label": "Palestine",
                "label_ar": "فلسطين",
                "label_fr": "Palestine"
            },
            {
                "value": 12943,
                "label": "Palisade",
                "label_ar": "باليسايد",
                "label_fr": "Palissade"
            },
            {
                "value": 12944,
                "label": "Palisades",
                "label_ar": "الحواجز",
                "label_fr": "Palissades"
            },
            {
                "value": 12945,
                "label": "Palisades Park",
                "label_ar": "حديقة باليسادس",
                "label_fr": "Parc des palissades"
            },
            {
                "value": 12946,
                "label": "Pall Mall",
                "label_ar": "مركز بال",
                "label_fr": "Pall Mall"
            },
            {
                "value": 12947,
                "label": "Palm",
                "label_ar": "كف، نخلة",
                "label_fr": "Paume"
            },
            {
                "value": 12948,
                "label": "Palm Bay",
                "label_ar": "بالم باي",
                "label_fr": "Palm Bay"
            },
            {
                "value": 12949,
                "label": "Palm Beach",
                "label_ar": "شاطئ النخيل",
                "label_fr": "plage de palmiers"
            },
            {
                "value": 12950,
                "label": "Palm Beach Gardens",
                "label_ar": "حدائق بالم بيتش",
                "label_fr": "Jardins de Palm Beach"
            },
            {
                "value": 12951,
                "label": "Palm City",
                "label_ar": "بالم سيتي",
                "label_fr": "Palm City"
            },
            {
                "value": 12952,
                "label": "Palm Coast",
                "label_ar": "بالم كوست",
                "label_fr": "Palm Coast"
            },
            {
                "value": 12953,
                "label": "Palm Desert",
                "label_ar": "بالم ديزرت",
                "label_fr": "Palm Desert"
            },
            {
                "value": 12954,
                "label": "Palm Harbor",
                "label_ar": "بالم هاربور",
                "label_fr": "Palm Harbor"
            },
            {
                "value": 12955,
                "label": "Palm Springs",
                "label_ar": "بالم سبرينغز",
                "label_fr": "Palm Springs"
            },
            {
                "value": 12956,
                "label": "Palmdale",
                "label_ar": "بالمديل",
                "label_fr": "Palmdale"
            },
            {
                "value": 12957,
                "label": "Palmer",
                "label_ar": "بالمر",
                "label_fr": "Palmer"
            },
            {
                "value": 12958,
                "label": "Palmer Lake",
                "label_ar": "بحيرة بالمر",
                "label_fr": "Lac Palmer"
            },
            {
                "value": 12959,
                "label": "Palmerdale",
                "label_ar": "بالمرديل",
                "label_fr": "Palmerdale"
            },
            {
                "value": 12960,
                "label": "Palmersville",
                "label_ar": "بالميرسفيل",
                "label_fr": "Palmersville"
            },
            {
                "value": 12961,
                "label": "Palmerton",
                "label_ar": "بالميرتون",
                "label_fr": "Palmerton"
            },
            {
                "value": 12962,
                "label": "Palmetto",
                "label_ar": "بالميتو",
                "label_fr": "Palmier nain"
            },
            {
                "value": 12963,
                "label": "Palmyra",
                "label_ar": "تدمر",
                "label_fr": "Palmyre"
            },
            {
                "value": 12964,
                "label": "Palo",
                "label_ar": "بالو",
                "label_fr": "Palo"
            },
            {
                "value": 12965,
                "label": "Palo Alto",
                "label_ar": "بالو التو",
                "label_fr": "Palo Alto"
            },
            {
                "value": 12966,
                "label": "Palo Cedro",
                "label_ar": "بالو سيدرو",
                "label_fr": "Palo Cedro"
            },
            {
                "value": 12967,
                "label": "Palo Verde",
                "label_ar": "بالو فيردي",
                "label_fr": "Palo Verde"
            },
            {
                "value": 12968,
                "label": "Paloma",
                "label_ar": "بالوما",
                "label_fr": "Paloma"
            },
            {
                "value": 12969,
                "label": "Palos Heights",
                "label_ar": "مرتفعات بالوس",
                "label_fr": "Palos Heights"
            },
            {
                "value": 12970,
                "label": "Palos Hills",
                "label_ar": "تلال بالوس",
                "label_fr": "Palos Hills"
            },
            {
                "value": 12971,
                "label": "Palos Park",
                "label_ar": "بالوس بارك",
                "label_fr": "Parc Palos"
            },
            {
                "value": 12972,
                "label": "Palos Verdes Peninsula",
                "label_ar": "شبه جزيرة بالوس فيرديس",
                "label_fr": "Péninsule de Palos Verdes"
            },
            {
                "value": 12973,
                "label": "Palouse",
                "label_ar": "بالوز",
                "label_fr": "Palouse"
            },
            {
                "value": 12974,
                "label": "Pampa",
                "label_ar": "بامبا",
                "label_fr": "Pampa"
            },
            {
                "value": 12975,
                "label": "Pamplico",
                "label_ar": "بامبليكو",
                "label_fr": "Pamplico"
            },
            {
                "value": 12976,
                "label": "Pamplin",
                "label_ar": "بامبلين",
                "label_fr": "Pamplin"
            },
            {
                "value": 12977,
                "label": "Pana",
                "label_ar": "بانا",
                "label_fr": "Pana"
            },
            {
                "value": 12978,
                "label": "Panaca",
                "label_ar": "باناكا",
                "label_fr": "Panaca"
            },
            {
                "value": 12979,
                "label": "Panacea",
                "label_ar": "الحل الناجع",
                "label_fr": "Panacée"
            },
            {
                "value": 12980,
                "label": "Panama",
                "label_ar": "بنما",
                "label_fr": "Panama"
            },
            {
                "value": 12981,
                "label": "Panama City",
                "label_ar": "مدينة باناما",
                "label_fr": "Ville de Panama"
            },
            {
                "value": 12982,
                "label": "Panama City Beach",
                "label_ar": "بنما سيتي بيتش",
                "label_fr": "Panama City Beach"
            },
            {
                "value": 12983,
                "label": "Pandora",
                "label_ar": "باندورا",
                "label_fr": "Pandore"
            },
            {
                "value": 12984,
                "label": "Pangburn",
                "label_ar": "بانجبرن",
                "label_fr": "Pangburn"
            },
            {
                "value": 12985,
                "label": "Panguitch",
                "label_ar": "بانغويتش",
                "label_fr": "Panguitch"
            },
            {
                "value": 12986,
                "label": "Panhandle",
                "label_ar": "استجواب",
                "label_fr": "Manche"
            },
            {
                "value": 12987,
                "label": "Panola",
                "label_ar": "بانولا",
                "label_fr": "Panola"
            },
            {
                "value": 12988,
                "label": "Panora",
                "label_ar": "بانورا",
                "label_fr": "Panora"
            },
            {
                "value": 12989,
                "label": "Panorama City",
                "label_ar": "بانوراما سيتي",
                "label_fr": "Ville panoramique"
            },
            {
                "value": 12990,
                "label": "Pansey",
                "label_ar": "بانسي",
                "label_fr": "Pansey"
            },
            {
                "value": 12991,
                "label": "Pantego",
                "label_ar": "بانتيجو",
                "label_fr": "Pantego"
            },
            {
                "value": 12992,
                "label": "Paola",
                "label_ar": "باولا",
                "label_fr": "Paola"
            },
            {
                "value": 12993,
                "label": "Paoli",
                "label_ar": "باولي",
                "label_fr": "Paoli"
            },
            {
                "value": 12994,
                "label": "Paonia",
                "label_ar": "باونيا",
                "label_fr": "Paonia"
            },
            {
                "value": 12995,
                "label": "Papillion",
                "label_ar": "بابليون",
                "label_fr": "Papillon"
            },
            {
                "value": 12996,
                "label": "Parachute",
                "label_ar": "المظلة",
                "label_fr": "Parachute"
            },
            {
                "value": 12997,
                "label": "Paradis",
                "label_ar": "الجنة",
                "label_fr": "Paradis"
            },
            {
                "value": 12998,
                "label": "Paradise",
                "label_ar": "الجنة",
                "label_fr": "paradis"
            },
            {
                "value": 12999,
                "label": "Paradise Valley",
                "label_ar": "وادي الجنة",
                "label_fr": "Paradise Valley"
            },
            {
                "value": 13000,
                "label": "Paradox",
                "label_ar": "المفارقة",
                "label_fr": "Paradoxe"
            },
            {
                "value": 13001,
                "label": "Paragon",
                "label_ar": "باراغون",
                "label_fr": "Parangon"
            },
            {
                "value": 13002,
                "label": "Paragould",
                "label_ar": "باراغولد",
                "label_fr": "Paragould"
            },
            {
                "value": 13003,
                "label": "Paramount",
                "label_ar": "أساسي",
                "label_fr": "Primordial"
            },
            {
                "value": 13004,
                "label": "Paramus",
                "label_ar": "باراموس",
                "label_fr": "Paramus"
            },
            {
                "value": 13005,
                "label": "Pardeeville",
                "label_ar": "بارديفيل",
                "label_fr": "Pardeeville"
            },
            {
                "value": 13006,
                "label": "Paris",
                "label_ar": "باريس",
                "label_fr": "Paris"
            },
            {
                "value": 13007,
                "label": "Parish",
                "label_ar": "أبرشية",
                "label_fr": "Paroisse"
            },
            {
                "value": 13008,
                "label": "Park",
                "label_ar": "منتزه",
                "label_fr": "parc"
            },
            {
                "value": 13009,
                "label": "Park City",
                "label_ar": "بارك سيتي",
                "label_fr": "Park City"
            },
            {
                "value": 13010,
                "label": "Park Falls",
                "label_ar": "بارك فولز",
                "label_fr": "Park Falls"
            },
            {
                "value": 13011,
                "label": "Park Forest",
                "label_ar": "بارك فورست",
                "label_fr": "Parc Forest"
            },
            {
                "value": 13012,
                "label": "Park Hill",
                "label_ar": "بارك هيل",
                "label_fr": "Park Hill"
            },
            {
                "value": 13013,
                "label": "Park Hills",
                "label_ar": "بارك هيلز",
                "label_fr": "Park Hills"
            },
            {
                "value": 13014,
                "label": "Park Rapids",
                "label_ar": "بارك رابيدز",
                "label_fr": "Park Rapids"
            },
            {
                "value": 13015,
                "label": "Park Ridge",
                "label_ar": "بارك ريدج",
                "label_fr": "Park Ridge"
            },
            {
                "value": 13016,
                "label": "Park River",
                "label_ar": "بارك ريفر",
                "label_fr": "Park River"
            },
            {
                "value": 13017,
                "label": "Parker",
                "label_ar": "باركر",
                "label_fr": "Parker"
            },
            {
                "value": 13018,
                "label": "Parker City",
                "label_ar": "باركر سيتي",
                "label_fr": "Parker City"
            },
            {
                "value": 13019,
                "label": "Parker Ford",
                "label_ar": "باركر فورد",
                "label_fr": "Parker Ford"
            },
            {
                "value": 13020,
                "label": "Parkers Lake",
                "label_ar": "بحيرة باركرز",
                "label_fr": "Parkers Lake"
            },
            {
                "value": 13021,
                "label": "Parkers Prairie",
                "label_ar": "باركرز براري",
                "label_fr": "Parkers Prairie"
            },
            {
                "value": 13022,
                "label": "Parkersburg",
                "label_ar": "باركرسبورغ",
                "label_fr": "Parkersburg"
            },
            {
                "value": 13023,
                "label": "Parkesburg",
                "label_ar": "باركيسبرج",
                "label_fr": "Parkesburg"
            },
            {
                "value": 13024,
                "label": "Parkman",
                "label_ar": "باركمان",
                "label_fr": "Parkman"
            },
            {
                "value": 13025,
                "label": "Parksley",
                "label_ar": "باركسلي",
                "label_fr": "Parksley"
            },
            {
                "value": 13026,
                "label": "Parkston",
                "label_ar": "باركستون",
                "label_fr": "Parkston"
            },
            {
                "value": 13027,
                "label": "Parkton",
                "label_ar": "باركتون",
                "label_fr": "Parkton"
            },
            {
                "value": 13028,
                "label": "Parkville",
                "label_ar": "باركفيل",
                "label_fr": "Parkville"
            },
            {
                "value": 13029,
                "label": "Parlier",
                "label_ar": "بارلير",
                "label_fr": "Parlier"
            },
            {
                "value": 13030,
                "label": "Parlin",
                "label_ar": "بارلين",
                "label_fr": "Parlin"
            },
            {
                "value": 13031,
                "label": "Parma",
                "label_ar": "بارما",
                "label_fr": "Parme"
            },
            {
                "value": 13032,
                "label": "Parnell",
                "label_ar": "بارنيل",
                "label_fr": "Parnell"
            },
            {
                "value": 13033,
                "label": "Parowan",
                "label_ar": "باروان",
                "label_fr": "Parowan"
            },
            {
                "value": 13034,
                "label": "Parrish",
                "label_ar": "باريش",
                "label_fr": "Parrish"
            },
            {
                "value": 13035,
                "label": "Parrottsville",
                "label_ar": "باروتسفيل",
                "label_fr": "Parrottsville"
            },
            {
                "value": 13036,
                "label": "Parshall",
                "label_ar": "بارشال",
                "label_fr": "Parshall"
            },
            {
                "value": 13037,
                "label": "Parsippany",
                "label_ar": "بارسيباني",
                "label_fr": "Parsippany"
            },
            {
                "value": 13038,
                "label": "Parsons",
                "label_ar": "بارسونز",
                "label_fr": "Parsons"
            },
            {
                "value": 13039,
                "label": "Parsonsburg",
                "label_ar": "بارسونسبرج",
                "label_fr": "Parsonsburg"
            },
            {
                "value": 13040,
                "label": "Parsonsfield",
                "label_ar": "بارسونزفيلد",
                "label_fr": "Parsonsfield"
            },
            {
                "value": 13041,
                "label": "Partlow",
                "label_ar": "بارتلو",
                "label_fr": "Partlow"
            },
            {
                "value": 13042,
                "label": "Partridge",
                "label_ar": "الحجل",
                "label_fr": "Perdrix"
            },
            {
                "value": 13043,
                "label": "Pasadena",
                "label_ar": "باسادينا",
                "label_fr": "Pasadena"
            },
            {
                "value": 13044,
                "label": "Pascagoula",
                "label_ar": "باسكاجولا",
                "label_fr": "Pascagoula"
            },
            {
                "value": 13045,
                "label": "Pasco",
                "label_ar": "باسكو",
                "label_fr": "Pasco"
            },
            {
                "value": 13046,
                "label": "Pascoag",
                "label_ar": "باسكواج",
                "label_fr": "Pascoag"
            },
            {
                "value": 13047,
                "label": "Paso Robles",
                "label_ar": "باسو روبلز",
                "label_fr": "Paso Robles"
            },
            {
                "value": 13048,
                "label": "Pass Christian",
                "label_ar": "تمر كريستيان",
                "label_fr": "Passer Christian"
            },
            {
                "value": 13049,
                "label": "Passaic",
                "label_ar": "باسيك",
                "label_fr": "Passaic"
            },
            {
                "value": 13050,
                "label": "Passumpsic",
                "label_ar": "كامن",
                "label_fr": "Passumpsic"
            },
            {
                "value": 13051,
                "label": "Pataskala",
                "label_ar": "باتاسكالا",
                "label_fr": "Pataskala"
            },
            {
                "value": 13052,
                "label": "Patchogue",
                "label_ar": "رقعة",
                "label_fr": "Patchogue"
            },
            {
                "value": 13053,
                "label": "Paterson",
                "label_ar": "باترسون",
                "label_fr": "Paterson"
            },
            {
                "value": 13054,
                "label": "Patoka",
                "label_ar": "باتوكا",
                "label_fr": "Patoka"
            },
            {
                "value": 13055,
                "label": "Paton",
                "label_ar": "باتون",
                "label_fr": "Paton"
            },
            {
                "value": 13056,
                "label": "Patrick",
                "label_ar": "باتريك",
                "label_fr": "Patrick"
            },
            {
                "value": 13057,
                "label": "Patrick Springs",
                "label_ar": "باتريك سبرينجز",
                "label_fr": "Patrick Springs"
            },
            {
                "value": 13058,
                "label": "Patriot",
                "label_ar": "باتريوت",
                "label_fr": "Patriote"
            },
            {
                "value": 13059,
                "label": "Patten",
                "label_ar": "باتن",
                "label_fr": "Patten"
            },
            {
                "value": 13060,
                "label": "Patterson",
                "label_ar": "باترسون",
                "label_fr": "Patterson"
            },
            {
                "value": 13061,
                "label": "Pattersonville",
                "label_ar": "باترسونفيل",
                "label_fr": "Pattersonville"
            },
            {
                "value": 13062,
                "label": "Pattison",
                "label_ar": "باتيسون",
                "label_fr": "Pattison"
            },
            {
                "value": 13063,
                "label": "Patton",
                "label_ar": "باتون",
                "label_fr": "Patton"
            },
            {
                "value": 13064,
                "label": "Pattonsburg",
                "label_ar": "باتونسبورغ",
                "label_fr": "Pattonsburg"
            },
            {
                "value": 13065,
                "label": "Pattonville",
                "label_ar": "باتونفيل",
                "label_fr": "Pattonville"
            },
            {
                "value": 13066,
                "label": "Paul",
                "label_ar": "بول",
                "label_fr": "Paul"
            },
            {
                "value": 13067,
                "label": "Paul Smiths",
                "label_ar": "بول سميث",
                "label_fr": "Paul Smiths"
            },
            {
                "value": 13068,
                "label": "Paulden",
                "label_ar": "بولدن",
                "label_fr": "Paulden"
            },
            {
                "value": 13069,
                "label": "Paulding",
                "label_ar": "بولدينج",
                "label_fr": "Paulding"
            },
            {
                "value": 13070,
                "label": "Paulina",
                "label_ar": "بولينا",
                "label_fr": "Paulina"
            },
            {
                "value": 13071,
                "label": "Pauline",
                "label_ar": "بولين",
                "label_fr": "Pauline"
            },
            {
                "value": 13072,
                "label": "Paullina",
                "label_ar": "باولينا",
                "label_fr": "Paullina"
            },
            {
                "value": 13073,
                "label": "Pauls Valley",
                "label_ar": "وادي بولس",
                "label_fr": "Vallée de Paul"
            },
            {
                "value": 13074,
                "label": "Paulsboro",
                "label_ar": "بولسبورو",
                "label_fr": "Paulsboro"
            },
            {
                "value": 13075,
                "label": "Pauma Valley",
                "label_ar": "وادي بوما",
                "label_fr": "Vallée de Pauma"
            },
            {
                "value": 13076,
                "label": "Pavilion",
                "label_ar": "جناح",
                "label_fr": "Pavillon"
            },
            {
                "value": 13077,
                "label": "Pavillion",
                "label_ar": "بافليون",
                "label_fr": "Pavillon"
            },
            {
                "value": 13078,
                "label": "Pavo",
                "label_ar": "بافو",
                "label_fr": "Pavo"
            },
            {
                "value": 13079,
                "label": "Paw Paw",
                "label_ar": "باو باو",
                "label_fr": "Patte patte"
            },
            {
                "value": 13080,
                "label": "Pawcatuck",
                "label_ar": "Pawcatuck",
                "label_fr": "Pawcatuck"
            },
            {
                "value": 13081,
                "label": "Pawhuska",
                "label_ar": "بووسكا",
                "label_fr": "Pawhuska"
            },
            {
                "value": 13082,
                "label": "Pawlet",
                "label_ar": "Pawlet",
                "label_fr": "Pawlet"
            },
            {
                "value": 13083,
                "label": "Pawleys Island",
                "label_ar": "جزيرة باوليز",
                "label_fr": "Île Pawleys"
            },
            {
                "value": 13084,
                "label": "Pawling",
                "label_ar": "البولينج",
                "label_fr": "Cliquetis"
            },
            {
                "value": 13085,
                "label": "Pawnee",
                "label_ar": "باوني",
                "label_fr": "Pawnee"
            },
            {
                "value": 13086,
                "label": "Pawnee City",
                "label_ar": "مدينة باوني",
                "label_fr": "Pawnee City"
            },
            {
                "value": 13087,
                "label": "Pawtucket",
                "label_ar": "Pawtucket",
                "label_fr": "Pawtucket"
            },
            {
                "value": 13088,
                "label": "Paxico",
                "label_ar": "باكسيكو",
                "label_fr": "Paxico"
            },
            {
                "value": 13089,
                "label": "Paxinos",
                "label_ar": "باكسينوس",
                "label_fr": "Paxinos"
            },
            {
                "value": 13090,
                "label": "Paxton",
                "label_ar": "باكستون",
                "label_fr": "Paxton"
            },
            {
                "value": 13091,
                "label": "Payette",
                "label_ar": "باييت",
                "label_fr": "Payette"
            },
            {
                "value": 13092,
                "label": "Payne",
                "label_ar": "باين",
                "label_fr": "Payne"
            },
            {
                "value": 13093,
                "label": "Paynesville",
                "label_ar": "باينسفيل",
                "label_fr": "Paynesville"
            },
            {
                "value": 13094,
                "label": "Payneville",
                "label_ar": "باينفيل",
                "label_fr": "Payneville"
            },
            {
                "value": 13095,
                "label": "Payson",
                "label_ar": "بايسون",
                "label_fr": "Payson"
            },
            {
                "value": 13096,
                "label": "Pea Ridge",
                "label_ar": "البازلاء ريدج",
                "label_fr": "Pea Ridge"
            },
            {
                "value": 13097,
                "label": "Peabody",
                "label_ar": "بيبودي",
                "label_fr": "Peabody"
            },
            {
                "value": 13098,
                "label": "Peace Dale",
                "label_ar": "السلام ديل",
                "label_fr": "Peace Dale"
            },
            {
                "value": 13099,
                "label": "Peach Bottom",
                "label_ar": "قاع الخوخ",
                "label_fr": "Fond de pêche"
            },
            {
                "value": 13100,
                "label": "Peach Creek",
                "label_ar": "الخوخ كريك",
                "label_fr": "Ruisseau Peach"
            },
            {
                "value": 13101,
                "label": "Peach Orchard",
                "label_ar": "بستان الخوخ",
                "label_fr": "Verger de pêches"
            },
            {
                "value": 13102,
                "label": "Peach Springs",
                "label_ar": "خوخ سبرينغز",
                "label_fr": "Peach Springs"
            },
            {
                "value": 13103,
                "label": "Peachland",
                "label_ar": "بيتش لاند",
                "label_fr": "Peachland"
            },
            {
                "value": 13104,
                "label": "Peachtree City",
                "label_ar": "مدينة بيتشتري",
                "label_fr": "Peachtree City"
            },
            {
                "value": 13105,
                "label": "Peak",
                "label_ar": "قمة",
                "label_fr": "De pointe"
            },
            {
                "value": 13106,
                "label": "Peaks Island",
                "label_ar": "جزيرة القمم",
                "label_fr": "Île Peaks"
            },
            {
                "value": 13107,
                "label": "Peapack",
                "label_ar": "Peapack",
                "label_fr": "Peapack"
            },
            {
                "value": 13108,
                "label": "Pearblossom",
                "label_ar": "زهرة الكمثرى",
                "label_fr": "Fleur de poirier"
            },
            {
                "value": 13109,
                "label": "Pearce",
                "label_ar": "بيرس",
                "label_fr": "Pearce"
            },
            {
                "value": 13110,
                "label": "Pearcy",
                "label_ar": "بيرسي",
                "label_fr": "Pearcy"
            },
            {
                "value": 13111,
                "label": "Pearisburg",
                "label_ar": "بيريسبرغ",
                "label_fr": "Pearisburg"
            },
            {
                "value": 13112,
                "label": "Pearl",
                "label_ar": "لؤلؤة",
                "label_fr": "perle"
            },
            {
                "value": 13113,
                "label": "Pearl City",
                "label_ar": "مدينة اللؤلؤة",
                "label_fr": "Pearl City"
            },
            {
                "value": 13114,
                "label": "Pearl City, Manana",
                "label_ar": "بيرل سيتي ، مانانا",
                "label_fr": "Pearl City, Manana"
            },
            {
                "value": 13115,
                "label": "Pearl River",
                "label_ar": "نهر اللؤلؤ",
                "label_fr": "Rivière des Perles"
            },
            {
                "value": 13116,
                "label": "Pearland",
                "label_ar": "بيرلاند",
                "label_fr": "Pearland"
            },
            {
                "value": 13117,
                "label": "Pearlington",
                "label_ar": "بيرلينجتون",
                "label_fr": "Pearlington"
            },
            {
                "value": 13118,
                "label": "Pearsall",
                "label_ar": "بيرسال",
                "label_fr": "Pearsall"
            },
            {
                "value": 13119,
                "label": "Pearson",
                "label_ar": "بيرسون",
                "label_fr": "Pearson"
            },
            {
                "value": 13120,
                "label": "Pease",
                "label_ar": "بيز",
                "label_fr": "Pois"
            },
            {
                "value": 13121,
                "label": "Pebble Beach",
                "label_ar": "شاطئ بيبل",
                "label_fr": "plage de galets"
            },
            {
                "value": 13122,
                "label": "Pecatonica",
                "label_ar": "بيكاتونيكا",
                "label_fr": "Pecatonica"
            },
            {
                "value": 13123,
                "label": "Peck",
                "label_ar": "بيك",
                "label_fr": "Picorer"
            },
            {
                "value": 13124,
                "label": "Pecks Mill",
                "label_ar": "مطحنة بيكس",
                "label_fr": "Moulin Pecks"
            },
            {
                "value": 13125,
                "label": "Peckville",
                "label_ar": "بيكفيل",
                "label_fr": "Peckville"
            },
            {
                "value": 13126,
                "label": "Peconic",
                "label_ar": "بيكوني",
                "label_fr": "Péconique"
            },
            {
                "value": 13127,
                "label": "Pecos",
                "label_ar": "بيكوس",
                "label_fr": "Pecos"
            },
            {
                "value": 13128,
                "label": "Peculiar",
                "label_ar": "غريب",
                "label_fr": "Particulier"
            },
            {
                "value": 13129,
                "label": "Pedricktown",
                "label_ar": "بيدريكتاون",
                "label_fr": "Pedricktown"
            },
            {
                "value": 13130,
                "label": "Pedro Bay",
                "label_ar": "خليج بيدرو",
                "label_fr": "Pedro Bay"
            },
            {
                "value": 13131,
                "label": "Peebles",
                "label_ar": "بيبلز",
                "label_fr": "Peebles"
            },
            {
                "value": 13132,
                "label": "Peekskill",
                "label_ar": "بيكسكيل",
                "label_fr": "Peekskill"
            },
            {
                "value": 13133,
                "label": "Peel",
                "label_ar": "قشر",
                "label_fr": "Peler"
            },
            {
                "value": 13134,
                "label": "Peetz",
                "label_ar": "بيتز",
                "label_fr": "Peetz"
            },
            {
                "value": 13135,
                "label": "Peever",
                "label_ar": "بيفير",
                "label_fr": "Peever"
            },
            {
                "value": 13136,
                "label": "Peggs",
                "label_ar": "بيجز",
                "label_fr": "Peggs"
            },
            {
                "value": 13137,
                "label": "Pegram",
                "label_ar": "بيغرام",
                "label_fr": "Pegram"
            },
            {
                "value": 13138,
                "label": "Pekin",
                "label_ar": "بيكين",
                "label_fr": "Pékin"
            },
            {
                "value": 13139,
                "label": "Pelahatchie",
                "label_ar": "بيلهاتشي",
                "label_fr": "Pelahatchie"
            },
            {
                "value": 13140,
                "label": "Pelham",
                "label_ar": "بيلهام",
                "label_fr": "Pelham"
            },
            {
                "value": 13141,
                "label": "Pelican",
                "label_ar": "البجع",
                "label_fr": "Pélican"
            },
            {
                "value": 13142,
                "label": "Pelican Lake",
                "label_ar": "بحيرة البجع",
                "label_fr": "Lac Pelican"
            },
            {
                "value": 13143,
                "label": "Pelican Rapids",
                "label_ar": "بيليكان رابيدز",
                "label_fr": "Pelican Rapids"
            },
            {
                "value": 13144,
                "label": "Pelion",
                "label_ar": "بيليون",
                "label_fr": "Pélion"
            },
            {
                "value": 13145,
                "label": "Pelkie",
                "label_ar": "بيلكي",
                "label_fr": "Pelkie"
            },
            {
                "value": 13146,
                "label": "Pell City",
                "label_ar": "مدينة بيل",
                "label_fr": "Pell City"
            },
            {
                "value": 13147,
                "label": "Pella",
                "label_ar": "بيلا",
                "label_fr": "Pella"
            },
            {
                "value": 13148,
                "label": "Pellston",
                "label_ar": "بيلستون",
                "label_fr": "Pellston"
            },
            {
                "value": 13149,
                "label": "Pelzer",
                "label_ar": "بيلزر",
                "label_fr": "Pelzer"
            },
            {
                "value": 13150,
                "label": "Pemberton",
                "label_ar": "بيمبرتون",
                "label_fr": "Pemberton"
            },
            {
                "value": 13151,
                "label": "Pemberville",
                "label_ar": "بيمبرفيل",
                "label_fr": "Pemberville"
            },
            {
                "value": 13152,
                "label": "Pembina",
                "label_ar": "بيمبينا",
                "label_fr": "Pembina"
            },
            {
                "value": 13153,
                "label": "Pembine",
                "label_ar": "بيمبين",
                "label_fr": "Pembine"
            },
            {
                "value": 13154,
                "label": "Pembroke",
                "label_ar": "بيمبروك",
                "label_fr": "Pembroke"
            },
            {
                "value": 13155,
                "label": "Pembroke Pines",
                "label_ar": "بيمبروك باينز",
                "label_fr": "Pins Pembroke"
            },
            {
                "value": 13156,
                "label": "Pen Argyl",
                "label_ar": "القلم أرجيل",
                "label_fr": "Stylo Argyl"
            },
            {
                "value": 13157,
                "label": "Penasco",
                "label_ar": "بيناسكو",
                "label_fr": "Penasco"
            },
            {
                "value": 13158,
                "label": "Pender",
                "label_ar": "معلق",
                "label_fr": "Pender"
            },
            {
                "value": 13159,
                "label": "Pendergrass",
                "label_ar": "بندرجراس",
                "label_fr": "Pendergrass"
            },
            {
                "value": 13160,
                "label": "Pendleton",
                "label_ar": "بندلتون",
                "label_fr": "Pendleton"
            },
            {
                "value": 13161,
                "label": "Penelope",
                "label_ar": "بينيلوبي",
                "label_fr": "Pénélope"
            },
            {
                "value": 13162,
                "label": "Penfield",
                "label_ar": "بنفيلد",
                "label_fr": "Penfield"
            },
            {
                "value": 13163,
                "label": "Pengilly",
                "label_ar": "بينجلي",
                "label_fr": "Pengilly"
            },
            {
                "value": 13164,
                "label": "Penhook",
                "label_ar": "بنهوك",
                "label_fr": "Penhook"
            },
            {
                "value": 13165,
                "label": "Peninsula",
                "label_ar": "شبه جزيرة",
                "label_fr": "Péninsule"
            },
            {
                "value": 13166,
                "label": "Penitas",
                "label_ar": "بينيتاس",
                "label_fr": "Penitas"
            },
            {
                "value": 13167,
                "label": "Penn",
                "label_ar": "بن",
                "label_fr": "Penn"
            },
            {
                "value": 13168,
                "label": "Penn Laird",
                "label_ar": "بن ليرد",
                "label_fr": "Penn Laird"
            },
            {
                "value": 13169,
                "label": "Penn Run",
                "label_ar": "بن ران",
                "label_fr": "Penn Run"
            },
            {
                "value": 13170,
                "label": "Penn Valley",
                "label_ar": "وادي بن",
                "label_fr": "Penn Valley"
            },
            {
                "value": 13171,
                "label": "Penn Yan",
                "label_ar": "بن يان",
                "label_fr": "Penn Yan"
            },
            {
                "value": 13172,
                "label": "Pennellville",
                "label_ar": "بنيلفيل",
                "label_fr": "Pennellville"
            },
            {
                "value": 13173,
                "label": "Penney Farms",
                "label_ar": "مزارع بيني",
                "label_fr": "Fermes Penney"
            },
            {
                "value": 13174,
                "label": "Penngrove",
                "label_ar": "بنجروف",
                "label_fr": "Penngrove"
            },
            {
                "value": 13175,
                "label": "Pennington",
                "label_ar": "بنينجتون",
                "label_fr": "Pennington"
            },
            {
                "value": 13176,
                "label": "Pennington Gap",
                "label_ar": "بنينجتون جاب",
                "label_fr": "Pennington Gap"
            },
            {
                "value": 13177,
                "label": "Pennock",
                "label_ar": "بينوك",
                "label_fr": "Pennock"
            },
            {
                "value": 13178,
                "label": "Penns Grove",
                "label_ar": "بنس جروف",
                "label_fr": "Penns Grove"
            },
            {
                "value": 13179,
                "label": "Penns Park",
                "label_ar": "بنس بارك",
                "label_fr": "Penns Park"
            },
            {
                "value": 13180,
                "label": "Pennsauken Township",
                "label_ar": "بلدة بنسوكين",
                "label_fr": "Canton de Pennsauken"
            },
            {
                "value": 13181,
                "label": "Pennsboro",
                "label_ar": "بنسبورو",
                "label_fr": "Pennsboro"
            },
            {
                "value": 13182,
                "label": "Pennsburg",
                "label_ar": "بنسبورغ",
                "label_fr": "Pennsburg"
            },
            {
                "value": 13183,
                "label": "Pennsville Township",
                "label_ar": "بلدة بنسفيل",
                "label_fr": "Canton de Pennsville"
            },
            {
                "value": 13184,
                "label": "Pennsylvania Furnace",
                "label_ar": "فرن بنسلفانيا",
                "label_fr": "Fournaise de Pennsylvanie"
            },
            {
                "value": 13185,
                "label": "Pennville",
                "label_ar": "بنفيل",
                "label_fr": "Pennville"
            },
            {
                "value": 13186,
                "label": "Penokee",
                "label_ar": "بينوكي",
                "label_fr": "Penokee"
            },
            {
                "value": 13187,
                "label": "Penrose",
                "label_ar": "بنروز",
                "label_fr": "Penrose"
            },
            {
                "value": 13188,
                "label": "Penryn",
                "label_ar": "بنرين",
                "label_fr": "Penryn"
            },
            {
                "value": 13189,
                "label": "Pensacola",
                "label_ar": "بينساكولا",
                "label_fr": "Pensacola"
            },
            {
                "value": 13190,
                "label": "Pentress",
                "label_ar": "بنتريس",
                "label_fr": "Pentress"
            },
            {
                "value": 13191,
                "label": "Pentwater",
                "label_ar": "بينتواتر",
                "label_fr": "Pentwater"
            },
            {
                "value": 13192,
                "label": "Peoria",
                "label_ar": "بيوريا",
                "label_fr": "Peoria"
            },
            {
                "value": 13193,
                "label": "Peoria Heights",
                "label_ar": "مرتفعات بيوريا",
                "label_fr": "Peoria Heights"
            },
            {
                "value": 13194,
                "label": "Peosta",
                "label_ar": "بيوستا",
                "label_fr": "Peosta"
            },
            {
                "value": 13195,
                "label": "Peotone",
                "label_ar": "بيوتون",
                "label_fr": "Peotone"
            },
            {
                "value": 13196,
                "label": "Pepperell",
                "label_ar": "بيبريل",
                "label_fr": "Pepperell"
            },
            {
                "value": 13197,
                "label": "Pequannock",
                "label_ar": "بيكانوك",
                "label_fr": "Pequannock"
            },
            {
                "value": 13198,
                "label": "Pequea",
                "label_ar": "بيكيا",
                "label_fr": "Pequea"
            },
            {
                "value": 13199,
                "label": "Pequot Lakes",
                "label_ar": "بحيرات بيكوت",
                "label_fr": "Lacs Pequot"
            },
            {
                "value": 13200,
                "label": "Peralta",
                "label_ar": "بيرالتا",
                "label_fr": "Peralta"
            },
            {
                "value": 13201,
                "label": "Percy",
                "label_ar": "بيرسي",
                "label_fr": "Percy"
            },
            {
                "value": 13202,
                "label": "Perdido",
                "label_ar": "بيرديدو",
                "label_fr": "Perdido"
            },
            {
                "value": 13203,
                "label": "Perham",
                "label_ar": "بيرهام",
                "label_fr": "Perham"
            },
            {
                "value": 13204,
                "label": "Peridot",
                "label_ar": "الزبرجد",
                "label_fr": "Péridot"
            },
            {
                "value": 13205,
                "label": "Perkasie",
                "label_ar": "بيركاسي",
                "label_fr": "Perkasie"
            },
            {
                "value": 13206,
                "label": "Perkins",
                "label_ar": "بيركنز",
                "label_fr": "Perkins"
            },
            {
                "value": 13207,
                "label": "Perkinston",
                "label_ar": "بيركينستون",
                "label_fr": "Perkinston"
            },
            {
                "value": 13208,
                "label": "Perkinsville",
                "label_ar": "بيركينسفيل",
                "label_fr": "Perkinsville"
            },
            {
                "value": 13209,
                "label": "Perkiomenville",
                "label_ar": "بيركينفيل",
                "label_fr": "Perkiomenville"
            },
            {
                "value": 13210,
                "label": "Perley",
                "label_ar": "بيرلي",
                "label_fr": "Perley"
            },
            {
                "value": 13211,
                "label": "Perrineville",
                "label_ar": "بيرينفيل",
                "label_fr": "Perrineville"
            },
            {
                "value": 13212,
                "label": "Perrinton",
                "label_ar": "بيرينتون",
                "label_fr": "Perrinton"
            },
            {
                "value": 13213,
                "label": "Perris",
                "label_ar": "بيريس",
                "label_fr": "Perris"
            },
            {
                "value": 13214,
                "label": "Perronville",
                "label_ar": "بيرونفيل",
                "label_fr": "Perronville"
            },
            {
                "value": 13215,
                "label": "Perry",
                "label_ar": "بيري",
                "label_fr": "Poiré"
            },
            {
                "value": 13216,
                "label": "Perry Hall",
                "label_ar": "بيري هول",
                "label_fr": "Salle Perry"
            },
            {
                "value": 13217,
                "label": "Perry Point",
                "label_ar": "بيري بوينت",
                "label_fr": "Perry Point"
            },
            {
                "value": 13218,
                "label": "Perryman",
                "label_ar": "بيريمان",
                "label_fr": "Perryman"
            },
            {
                "value": 13219,
                "label": "Perryopolis",
                "label_ar": "بيريوبوليس",
                "label_fr": "Perryopolis"
            },
            {
                "value": 13220,
                "label": "Perrysburg",
                "label_ar": "بيريسبورغ",
                "label_fr": "Perrysburg"
            },
            {
                "value": 13221,
                "label": "Perryton",
                "label_ar": "بيريتون",
                "label_fr": "Perryton"
            },
            {
                "value": 13222,
                "label": "Perryville",
                "label_ar": "بيريفيل",
                "label_fr": "Perryville"
            },
            {
                "value": 13223,
                "label": "Pershing",
                "label_ar": "بيرشينج",
                "label_fr": "Pershing"
            },
            {
                "value": 13224,
                "label": "Persia",
                "label_ar": "بلاد فارس",
                "label_fr": "Perse"
            },
            {
                "value": 13225,
                "label": "Perth Amboy",
                "label_ar": "بيرث أمبوي",
                "label_fr": "Perth Amboy"
            },
            {
                "value": 13226,
                "label": "Peru",
                "label_ar": "بيرو",
                "label_fr": "Pérou"
            },
            {
                "value": 13227,
                "label": "Pescadero",
                "label_ar": "بيسكاديرو",
                "label_fr": "Pescadero"
            },
            {
                "value": 13228,
                "label": "Peshastin",
                "label_ar": "بيشاستين",
                "label_fr": "Peshastin"
            },
            {
                "value": 13229,
                "label": "Peshtigo",
                "label_ar": "بيشتيجو",
                "label_fr": "Peshtigo"
            },
            {
                "value": 13230,
                "label": "Pesotum",
                "label_ar": "بيسوتوم",
                "label_fr": "Pesotum"
            },
            {
                "value": 13231,
                "label": "Petal",
                "label_ar": "بتلة",
                "label_fr": "Pétale"
            },
            {
                "value": 13232,
                "label": "Petaluma",
                "label_ar": "بيتالوما",
                "label_fr": "Petaluma"
            },
            {
                "value": 13233,
                "label": "Peterborough",
                "label_ar": "بيتربورو",
                "label_fr": "Peterborough"
            },
            {
                "value": 13234,
                "label": "Peterman",
                "label_ar": "بيترمان",
                "label_fr": "Peterman"
            },
            {
                "value": 13235,
                "label": "Petersburg",
                "label_ar": "بطرسبورغ",
                "label_fr": "Pétersbourg"
            },
            {
                "value": 13236,
                "label": "Petersham",
                "label_ar": "بيترشام",
                "label_fr": "Petersham"
            },
            {
                "value": 13237,
                "label": "Peterson",
                "label_ar": "بيترسون",
                "label_fr": "Peterson"
            },
            {
                "value": 13238,
                "label": "Peterstown",
                "label_ar": "بيترستاون",
                "label_fr": "Peterstown"
            },
            {
                "value": 13239,
                "label": "Petoskey",
                "label_ar": "بيتوسكي",
                "label_fr": "Petoskey"
            },
            {
                "value": 13240,
                "label": "Petroleum",
                "label_ar": "البترول",
                "label_fr": "Pétrole"
            },
            {
                "value": 13241,
                "label": "Petrolia",
                "label_ar": "بتروليا",
                "label_fr": "Petrolia"
            },
            {
                "value": 13242,
                "label": "Petros",
                "label_ar": "بيتروس",
                "label_fr": "Petros"
            },
            {
                "value": 13243,
                "label": "Pettigrew",
                "label_ar": "بيتيجرو",
                "label_fr": "Pettigrew"
            },
            {
                "value": 13244,
                "label": "Pevely",
                "label_ar": "بيفيلي",
                "label_fr": "Pevely"
            },
            {
                "value": 13245,
                "label": "Pewamo",
                "label_ar": "بيوامو",
                "label_fr": "Pewamo"
            },
            {
                "value": 13246,
                "label": "Pewaukee",
                "label_ar": "بيووكي",
                "label_fr": "Pewaukee"
            },
            {
                "value": 13247,
                "label": "Pewee Valley",
                "label_ar": "وادي بيوي",
                "label_fr": "Vallée Pewee"
            },
            {
                "value": 13248,
                "label": "Peyton",
                "label_ar": "بيتون",
                "label_fr": "Peyton"
            },
            {
                "value": 13249,
                "label": "Pfafftown",
                "label_ar": "بفافتاون",
                "label_fr": "Pfafftown"
            },
            {
                "value": 13250,
                "label": "Pflugerville",
                "label_ar": "بفلوجرفيل",
                "label_fr": "Pflugerville"
            },
            {
                "value": 13251,
                "label": "Pharr",
                "label_ar": "فار",
                "label_fr": "Pharr"
            },
            {
                "value": 13252,
                "label": "Phelan",
                "label_ar": "فيلان",
                "label_fr": "Phelan"
            },
            {
                "value": 13253,
                "label": "Phelps",
                "label_ar": "فيلبس",
                "label_fr": "Phelps"
            },
            {
                "value": 13254,
                "label": "Phenix",
                "label_ar": "فينيكس",
                "label_fr": "Phénix"
            },
            {
                "value": 13255,
                "label": "Phenix City",
                "label_ar": "مدينة فينيكس",
                "label_fr": "Phenix City"
            },
            {
                "value": 13256,
                "label": "Phil Campbell",
                "label_ar": "فيل كامبل",
                "label_fr": "Phil Campbell"
            },
            {
                "value": 13257,
                "label": "Philadelphia",
                "label_ar": "فيلادلفيا",
                "label_fr": "crême Philadelphia"
            },
            {
                "value": 13258,
                "label": "Philip",
                "label_ar": "فيليب",
                "label_fr": "Philippe"
            },
            {
                "value": 13259,
                "label": "Philipp",
                "label_ar": "فيليب",
                "label_fr": "Philipp"
            },
            {
                "value": 13260,
                "label": "Philippi",
                "label_ar": "فيليبي",
                "label_fr": "Philippes"
            },
            {
                "value": 13261,
                "label": "Philipsburg",
                "label_ar": "فيليبسبورج",
                "label_fr": "Philipsburg"
            },
            {
                "value": 13262,
                "label": "Phillips",
                "label_ar": "فيليبس",
                "label_fr": "Phillips"
            },
            {
                "value": 13263,
                "label": "Phillipsburg",
                "label_ar": "فيليبسبرج",
                "label_fr": "Phillipsburg"
            },
            {
                "value": 13264,
                "label": "Philo",
                "label_ar": "فيلو",
                "label_fr": "Philon"
            },
            {
                "value": 13265,
                "label": "Philomath",
                "label_ar": "فيلوماث",
                "label_fr": "Philomath"
            },
            {
                "value": 13266,
                "label": "Philpot",
                "label_ar": "فيلبوت",
                "label_fr": "Philpot"
            },
            {
                "value": 13267,
                "label": "Phippsburg",
                "label_ar": "فيبسبورغ",
                "label_fr": "Phippsburg"
            },
            {
                "value": 13268,
                "label": "Phoenicia",
                "label_ar": "فينيقيا",
                "label_fr": "Phénicie"
            },
            {
                "value": 13269,
                "label": "Phoenix",
                "label_ar": "فينيكس",
                "label_fr": "Phénix"
            },
            {
                "value": 13270,
                "label": "Phoenixville",
                "label_ar": "فينيكسفيل",
                "label_fr": "Phoenixville"
            },
            {
                "value": 13271,
                "label": "Piasa",
                "label_ar": "بياسا",
                "label_fr": "Piasa"
            },
            {
                "value": 13272,
                "label": "Picabo",
                "label_ar": "بيكابو",
                "label_fr": "Picabo"
            },
            {
                "value": 13273,
                "label": "Picayune",
                "label_ar": "بيكايون",
                "label_fr": "Mesquin"
            },
            {
                "value": 13274,
                "label": "Pickens",
                "label_ar": "بيكنز",
                "label_fr": "Pickens"
            },
            {
                "value": 13275,
                "label": "Pickerington",
                "label_ar": "بيكرينغتون",
                "label_fr": "Pickerington"
            },
            {
                "value": 13276,
                "label": "Pickett",
                "label_ar": "بيكيت",
                "label_fr": "Pickett"
            },
            {
                "value": 13277,
                "label": "Pickstown",
                "label_ar": "بيكستاون",
                "label_fr": "Pickstown"
            },
            {
                "value": 13278,
                "label": "Pickton",
                "label_ar": "بيكتون",
                "label_fr": "Pickton"
            },
            {
                "value": 13279,
                "label": "Pico Rivera",
                "label_ar": "بيكو ريفيرا",
                "label_fr": "Pico Rivera"
            },
            {
                "value": 13280,
                "label": "Picture Rocks",
                "label_ar": "صور الصخور",
                "label_fr": "Roches de l'image"
            },
            {
                "value": 13281,
                "label": "Piedmont",
                "label_ar": "بيدمونت",
                "label_fr": "Piémont"
            },
            {
                "value": 13282,
                "label": "Pierce",
                "label_ar": "بيرس",
                "label_fr": "transpercer"
            },
            {
                "value": 13283,
                "label": "Pierce City",
                "label_ar": "بيرس سيتي",
                "label_fr": "Pierce City"
            },
            {
                "value": 13284,
                "label": "Pierceton",
                "label_ar": "بيرستون",
                "label_fr": "Pierceton"
            },
            {
                "value": 13285,
                "label": "Piercy",
                "label_ar": "بيرسي",
                "label_fr": "Piercy"
            },
            {
                "value": 13286,
                "label": "Piermont",
                "label_ar": "بيرمونت",
                "label_fr": "Piermont"
            },
            {
                "value": 13287,
                "label": "Pierpont",
                "label_ar": "بيربونت",
                "label_fr": "Pierpont"
            },
            {
                "value": 13288,
                "label": "Pierre",
                "label_ar": "بيير",
                "label_fr": "Pierre"
            },
            {
                "value": 13289,
                "label": "Pierre Part",
                "label_ar": "بيير الجزء",
                "label_fr": "Pierre Part"
            },
            {
                "value": 13290,
                "label": "Pierron",
                "label_ar": "بييرون",
                "label_fr": "Pierron"
            },
            {
                "value": 13291,
                "label": "Pierson",
                "label_ar": "بيرسون",
                "label_fr": "Pierson"
            },
            {
                "value": 13292,
                "label": "Pierz",
                "label_ar": "بيرز",
                "label_fr": "Pierz"
            },
            {
                "value": 13293,
                "label": "Piffard",
                "label_ar": "بيفارد",
                "label_fr": "Piffard"
            },
            {
                "value": 13294,
                "label": "Pigeon",
                "label_ar": "حمامة",
                "label_fr": "Pigeon"
            },
            {
                "value": 13295,
                "label": "Pigeon Forge",
                "label_ar": "بيجون فورج",
                "label_fr": "Forge aux pigeons"
            },
            {
                "value": 13296,
                "label": "Piggott",
                "label_ar": "بيجوت",
                "label_fr": "Piggott"
            },
            {
                "value": 13297,
                "label": "Pike Road",
                "label_ar": "طريق بايك",
                "label_fr": "Pike Road"
            },
            {
                "value": 13298,
                "label": "Pikesville",
                "label_ar": "بايكسفيل",
                "label_fr": "Pikesville"
            },
            {
                "value": 13299,
                "label": "Piketon",
                "label_ar": "بيكيتون",
                "label_fr": "Piketon"
            },
            {
                "value": 13300,
                "label": "Pikeville",
                "label_ar": "بيكفيل",
                "label_fr": "Pikeville"
            },
            {
                "value": 13301,
                "label": "Pillager",
                "label_ar": "النهب",
                "label_fr": "Pilleur"
            },
            {
                "value": 13302,
                "label": "Pilot",
                "label_ar": "طيار",
                "label_fr": "Pilote"
            },
            {
                "value": 13303,
                "label": "Pilot Grove",
                "label_ar": "بايلوت جروف",
                "label_fr": "Pilot Grove"
            },
            {
                "value": 13304,
                "label": "Pilot Hill",
                "label_ar": "بايلوت هيل",
                "label_fr": "Pilot Hill"
            },
            {
                "value": 13305,
                "label": "Pilot Knob",
                "label_ar": "مقبض الطيار",
                "label_fr": "Bouton pilote"
            },
            {
                "value": 13306,
                "label": "Pilot Mound",
                "label_ar": "تل الطيار",
                "label_fr": "Pilote Mound"
            },
            {
                "value": 13307,
                "label": "Pilot Mountain",
                "label_ar": "جبل الطيار",
                "label_fr": "Pilot Mountain"
            },
            {
                "value": 13308,
                "label": "Pilot Point",
                "label_ar": "بايلوت بوينت",
                "label_fr": "Point pilote"
            },
            {
                "value": 13309,
                "label": "Pilot Rock",
                "label_ar": "بايلوت روك",
                "label_fr": "Pilote Rock"
            },
            {
                "value": 13310,
                "label": "Pilot Station",
                "label_ar": "محطة طيار",
                "label_fr": "Station pilote"
            },
            {
                "value": 13311,
                "label": "Pima",
                "label_ar": "بيما",
                "label_fr": "Pima"
            },
            {
                "value": 13312,
                "label": "Pima County",
                "label_ar": "مقاطعة بيما",
                "label_fr": "Comté de Pima"
            },
            {
                "value": 13313,
                "label": "Pimento",
                "label_ar": "بيمنتو",
                "label_fr": "Piment"
            },
            {
                "value": 13314,
                "label": "Pinckney",
                "label_ar": "بينكني",
                "label_fr": "Pinckney"
            },
            {
                "value": 13315,
                "label": "Pinckneyville",
                "label_ar": "بينكنيفيل",
                "label_fr": "Pinckneyville"
            },
            {
                "value": 13316,
                "label": "Pinconning",
                "label_ar": "بينكونينج",
                "label_fr": "Piquage"
            },
            {
                "value": 13317,
                "label": "Pine",
                "label_ar": "صنوبر",
                "label_fr": "Pin"
            },
            {
                "value": 13318,
                "label": "Pine Beach",
                "label_ar": "شاطئ الصنوبر",
                "label_fr": "Pine Beach"
            },
            {
                "value": 13319,
                "label": "Pine Bluff",
                "label_ar": "باين بلاف",
                "label_fr": "Pine Bluff"
            },
            {
                "value": 13320,
                "label": "Pine Bluffs",
                "label_ar": "باين بلافس",
                "label_fr": "Pine Bluffs"
            },
            {
                "value": 13321,
                "label": "Pine Brook",
                "label_ar": "باين بروك",
                "label_fr": "Pine Brook"
            },
            {
                "value": 13322,
                "label": "Pine Bush",
                "label_ar": "شجيرة الصنوبر",
                "label_fr": "Pinède"
            },
            {
                "value": 13323,
                "label": "Pine City",
                "label_ar": "مدينة الصنوبر",
                "label_fr": "Pine City"
            },
            {
                "value": 13324,
                "label": "Pine Forge",
                "label_ar": "باين فورج",
                "label_fr": "Forge de pin"
            },
            {
                "value": 13325,
                "label": "Pine Grove",
                "label_ar": "بستان الصنوبرة",
                "label_fr": "Pine Grove"
            },
            {
                "value": 13326,
                "label": "Pine Grove Mills",
                "label_ar": "مطاحن باين جروف",
                "label_fr": "Pine Grove Mills"
            },
            {
                "value": 13327,
                "label": "Pine Hill",
                "label_ar": "باين هيل",
                "label_fr": "Pine Hill"
            },
            {
                "value": 13328,
                "label": "Pine Island",
                "label_ar": "جزيرة الصنوبر",
                "label_fr": "Île Pine"
            },
            {
                "value": 13329,
                "label": "Pine Knot",
                "label_ar": "عقدة الصنوبر",
                "label_fr": "Noeud de pin"
            },
            {
                "value": 13330,
                "label": "Pine Lake",
                "label_ar": "بحيرة الصنوبر",
                "label_fr": "Pine Lake"
            },
            {
                "value": 13331,
                "label": "Pine Mountain",
                "label_ar": "جبل الصنوبر",
                "label_fr": "Pine Mountain"
            },
            {
                "value": 13332,
                "label": "Pine Mountain Club",
                "label_ar": "نادي باين ماونتن",
                "label_fr": "Pine Mountain Club"
            },
            {
                "value": 13333,
                "label": "Pine Plains",
                "label_ar": "سهول الصنوبر",
                "label_fr": "Pine Plains"
            },
            {
                "value": 13334,
                "label": "Pine Ridge",
                "label_ar": "باين ريدج",
                "label_fr": "Pine Ridge"
            },
            {
                "value": 13335,
                "label": "Pine River",
                "label_ar": "نهر الصنوبر",
                "label_fr": "Rivière Pine"
            },
            {
                "value": 13336,
                "label": "Pine Top",
                "label_ar": "قمة الصنوبر",
                "label_fr": "Dessus de pin"
            },
            {
                "value": 13337,
                "label": "Pine Valley",
                "label_ar": "وادي الصنوبر",
                "label_fr": "Pine Valley"
            },
            {
                "value": 13338,
                "label": "Pinebluff",
                "label_ar": "بينبلوف",
                "label_fr": "Pinebluff"
            },
            {
                "value": 13339,
                "label": "Pinecrest",
                "label_ar": "Pinecrest",
                "label_fr": "Pinecrest"
            },
            {
                "value": 13340,
                "label": "Pinedale",
                "label_ar": "بينديل",
                "label_fr": "Pinedale"
            },
            {
                "value": 13341,
                "label": "Pinehill",
                "label_ar": "بينهيل",
                "label_fr": "Pinehill"
            },
            {
                "value": 13342,
                "label": "Pinehurst",
                "label_ar": "بينهورست",
                "label_fr": "Pinehurst"
            },
            {
                "value": 13343,
                "label": "Pinellas Park",
                "label_ar": "حديقة بينيلاس",
                "label_fr": "Parc Pinellas"
            },
            {
                "value": 13344,
                "label": "Pineola",
                "label_ar": "بينيولا",
                "label_fr": "Pineola"
            },
            {
                "value": 13345,
                "label": "Pinesdale",
                "label_ar": "بينسديل",
                "label_fr": "Pinesdale"
            },
            {
                "value": 13346,
                "label": "Pinetop-Lakeside",
                "label_ar": "بينيتوب ليكسايد",
                "label_fr": "Pinetop-Lakeside"
            },
            {
                "value": 13347,
                "label": "Pinetops",
                "label_ar": "بينيتوبس",
                "label_fr": "Pinetops"
            },
            {
                "value": 13348,
                "label": "Pinetown",
                "label_ar": "بينتاون",
                "label_fr": "Pinetown"
            },
            {
                "value": 13349,
                "label": "Pineville",
                "label_ar": "بينفيل",
                "label_fr": "Pineville"
            },
            {
                "value": 13350,
                "label": "Pinewood",
                "label_ar": "خشب الصنوبر",
                "label_fr": "Pinède"
            },
            {
                "value": 13351,
                "label": "Piney Creek",
                "label_ar": "بيني كريك",
                "label_fr": "Piney Creek"
            },
            {
                "value": 13352,
                "label": "Piney Flats",
                "label_ar": "بيني فلاتس",
                "label_fr": "Piney Flats"
            },
            {
                "value": 13353,
                "label": "Piney View",
                "label_ar": "عرض بايني",
                "label_fr": "Vue Piney"
            },
            {
                "value": 13354,
                "label": "Piney Woods",
                "label_ar": "بيني وودز",
                "label_fr": "Piney Woods"
            },
            {
                "value": 13355,
                "label": "Pingree",
                "label_ar": "بينغري",
                "label_fr": "Pingree"
            },
            {
                "value": 13356,
                "label": "Pink Hill",
                "label_ar": "بينك هيل",
                "label_fr": "Pink Hill"
            },
            {
                "value": 13357,
                "label": "Pinnacle",
                "label_ar": "قمة",
                "label_fr": "Sommet"
            },
            {
                "value": 13358,
                "label": "Pinola",
                "label_ar": "بينولا",
                "label_fr": "Pinola"
            },
            {
                "value": 13359,
                "label": "Pinole",
                "label_ar": "بينول",
                "label_fr": "Pinole"
            },
            {
                "value": 13360,
                "label": "Pinon",
                "label_ar": "بينون",
                "label_fr": "Épingler à"
            },
            {
                "value": 13361,
                "label": "Pinon Hills",
                "label_ar": "تلال بينون",
                "label_fr": "Pinon Hills"
            },
            {
                "value": 13362,
                "label": "Pinson",
                "label_ar": "بينسون",
                "label_fr": "Pinson"
            },
            {
                "value": 13363,
                "label": "Pioche",
                "label_ar": "بيوش",
                "label_fr": "Pioche"
            },
            {
                "value": 13364,
                "label": "Pioneer",
                "label_ar": "رائد",
                "label_fr": "Pionnier"
            },
            {
                "value": 13365,
                "label": "Pioneertown",
                "label_ar": "بايونيرتاون",
                "label_fr": "Pioneertown"
            },
            {
                "value": 13366,
                "label": "Pipe Creek",
                "label_ar": "بايب كريك",
                "label_fr": "Pipe Creek"
            },
            {
                "value": 13367,
                "label": "Piper City",
                "label_ar": "بايبر سيتي",
                "label_fr": "Piper City"
            },
            {
                "value": 13368,
                "label": "Pipersville",
                "label_ar": "بيبرسفيل",
                "label_fr": "Pipersville"
            },
            {
                "value": 13369,
                "label": "Pipestone",
                "label_ar": "بايبستون",
                "label_fr": "Pipestone"
            },
            {
                "value": 13370,
                "label": "Piqua",
                "label_ar": "بيكوا",
                "label_fr": "Piqua"
            },
            {
                "value": 13371,
                "label": "Pirtleville",
                "label_ar": "بيرتليفيل",
                "label_fr": "Pirtleville"
            },
            {
                "value": 13372,
                "label": "Piscataway",
                "label_ar": "بيسكاتواي",
                "label_fr": "Piscataway"
            },
            {
                "value": 13373,
                "label": "Piseco",
                "label_ar": "بيسيكو",
                "label_fr": "Piseco"
            },
            {
                "value": 13374,
                "label": "Pisgah",
                "label_ar": "بسكة",
                "label_fr": "Pisgah"
            },
            {
                "value": 13375,
                "label": "Pisgah Forest",
                "label_ar": "غابة بيسجاه",
                "label_fr": "Forêt de Pisgah"
            },
            {
                "value": 13376,
                "label": "Pismo Beach",
                "label_ar": "شاطئ بيسمو",
                "label_fr": "Plage de Pismo"
            },
            {
                "value": 13377,
                "label": "Pitcairn",
                "label_ar": "بيتكيرن",
                "label_fr": "Pitcairn"
            },
            {
                "value": 13378,
                "label": "Pitcher",
                "label_ar": "جرة",
                "label_fr": "Lanceur"
            },
            {
                "value": 13379,
                "label": "Pitkin",
                "label_ar": "بيتكين",
                "label_fr": "Pitkin"
            },
            {
                "value": 13380,
                "label": "Pitman",
                "label_ar": "بيتمان",
                "label_fr": "Pitman"
            },
            {
                "value": 13381,
                "label": "Pittsboro",
                "label_ar": "بيتسبورو",
                "label_fr": "Pittsboro"
            },
            {
                "value": 13382,
                "label": "Pittsburg",
                "label_ar": "بيتسبرغ",
                "label_fr": "Pittsburg"
            },
            {
                "value": 13383,
                "label": "Pittsburgh",
                "label_ar": "بيتسبرغ",
                "label_fr": "Pittsburgh"
            },
            {
                "value": 13384,
                "label": "Pittsfield",
                "label_ar": "بيتسفيلد",
                "label_fr": "Pittsfield"
            },
            {
                "value": 13385,
                "label": "Pittsford",
                "label_ar": "بيتسفورد",
                "label_fr": "Pittsford"
            },
            {
                "value": 13386,
                "label": "Pittston",
                "label_ar": "بيتستون",
                "label_fr": "Pittston"
            },
            {
                "value": 13387,
                "label": "Pittstown",
                "label_ar": "بيتستاون",
                "label_fr": "Pittstown"
            },
            {
                "value": 13388,
                "label": "Pittsview",
                "label_ar": "بيتسفيو",
                "label_fr": "Pittsview"
            },
            {
                "value": 13389,
                "label": "Pittsville",
                "label_ar": "بيتسفيل",
                "label_fr": "Pittsville"
            },
            {
                "value": 13390,
                "label": "Pixley",
                "label_ar": "بيكسلي",
                "label_fr": "Pixley"
            },
            {
                "value": 13391,
                "label": "Placedo",
                "label_ar": "بلايدو",
                "label_fr": "Placedo"
            },
            {
                "value": 13392,
                "label": "Placentia",
                "label_ar": "المشيمة",
                "label_fr": "Placentia"
            },
            {
                "value": 13393,
                "label": "Placerville",
                "label_ar": "بلاسيرفيل",
                "label_fr": "Placerville"
            },
            {
                "value": 13394,
                "label": "Placida",
                "label_ar": "بلاسيدا",
                "label_fr": "Placida"
            },
            {
                "value": 13395,
                "label": "Placitas",
                "label_ar": "بلاسيتاس",
                "label_fr": "Placitas"
            },
            {
                "value": 13396,
                "label": "Plain",
                "label_ar": "عادي",
                "label_fr": "Plaine"
            },
            {
                "value": 13397,
                "label": "Plain City",
                "label_ar": "مدينة سهل",
                "label_fr": "Ville simple"
            },
            {
                "value": 13398,
                "label": "Plain Dealing",
                "label_ar": "التعامل البسيط",
                "label_fr": "Procédés honnête"
            },
            {
                "value": 13399,
                "label": "Plainfield",
                "label_ar": "بلينفيلد",
                "label_fr": "Plainfield"
            },
            {
                "value": 13400,
                "label": "Plains",
                "label_ar": "السهول",
                "label_fr": "Plaines"
            },
            {
                "value": 13401,
                "label": "Plainsboro",
                "label_ar": "بلينسبورو",
                "label_fr": "Plainsboro"
            },
            {
                "value": 13402,
                "label": "Plainview",
                "label_ar": "مرأى",
                "label_fr": "Plainview"
            },
            {
                "value": 13403,
                "label": "Plainville",
                "label_ar": "بلينفيل",
                "label_fr": "Plainville"
            },
            {
                "value": 13404,
                "label": "Plainwell",
                "label_ar": "بلينويل",
                "label_fr": "Plainwell"
            },
            {
                "value": 13405,
                "label": "Plaistow",
                "label_ar": "بلايستو",
                "label_fr": "Plaistow"
            },
            {
                "value": 13406,
                "label": "Planada",
                "label_ar": "بلانادا",
                "label_fr": "Planada"
            },
            {
                "value": 13407,
                "label": "Plankinton",
                "label_ar": "بلانكنتون",
                "label_fr": "Plankinton"
            },
            {
                "value": 13408,
                "label": "Plano",
                "label_ar": "بلانو",
                "label_fr": "Plano"
            },
            {
                "value": 13409,
                "label": "Plant City",
                "label_ar": "مدينة النبات",
                "label_fr": "Ville végétale"
            },
            {
                "value": 13410,
                "label": "Plantation",
                "label_ar": "بلانتيشن",
                "label_fr": "Plantation"
            },
            {
                "value": 13411,
                "label": "Plantersville",
                "label_ar": "بلانترسفيل",
                "label_fr": "Plantersville"
            },
            {
                "value": 13412,
                "label": "Plantsville",
                "label_ar": "بلانتسفيل",
                "label_fr": "Plantsville"
            },
            {
                "value": 13413,
                "label": "Plaquemine",
                "label_ar": "بلاكيمين",
                "label_fr": "Plaquemine"
            },
            {
                "value": 13414,
                "label": "Plato",
                "label_ar": "أفلاطون",
                "label_fr": "Platon"
            },
            {
                "value": 13415,
                "label": "Platte",
                "label_ar": "بلات",
                "label_fr": "Platte"
            },
            {
                "value": 13416,
                "label": "Platte City",
                "label_ar": "بلات سيتي",
                "label_fr": "Ville de Platte"
            },
            {
                "value": 13417,
                "label": "Plattekill",
                "label_ar": "بلاتيكيل",
                "label_fr": "Plattekill"
            },
            {
                "value": 13418,
                "label": "Plattenville",
                "label_ar": "بلاتنفيل",
                "label_fr": "Plattenville"
            },
            {
                "value": 13419,
                "label": "Platteville",
                "label_ar": "بلاتفيل",
                "label_fr": "Platteville"
            },
            {
                "value": 13420,
                "label": "Plattsburg",
                "label_ar": "بلاتسبورغ",
                "label_fr": "Plattsburg"
            },
            {
                "value": 13421,
                "label": "Plattsburgh",
                "label_ar": "بلاتسبرج",
                "label_fr": "Plattsburgh"
            },
            {
                "value": 13422,
                "label": "Plattsmouth",
                "label_ar": "بلاتسماوث",
                "label_fr": "Plattsmouth"
            },
            {
                "value": 13423,
                "label": "Playa del Rey",
                "label_ar": "بلايا ديل ري",
                "label_fr": "Playa del Rey"
            },
            {
                "value": 13424,
                "label": "Plaza",
                "label_ar": "بلازا",
                "label_fr": "Place"
            },
            {
                "value": 13425,
                "label": "Pleasant City",
                "label_ar": "بليزانت سيتي",
                "label_fr": "Ville agréable"
            },
            {
                "value": 13426,
                "label": "Pleasant Dale",
                "label_ar": "بليزانت ديل",
                "label_fr": "Pleasant Dale"
            },
            {
                "value": 13427,
                "label": "Pleasant Garden",
                "label_ar": "بليزانت جاردن",
                "label_fr": "Jardin agréable"
            },
            {
                "value": 13428,
                "label": "Pleasant Grove",
                "label_ar": "بليزانت جروف",
                "label_fr": "Pleasant Grove"
            },
            {
                "value": 13429,
                "label": "Pleasant Hill",
                "label_ar": "بليزانت هيل",
                "label_fr": "Pleasant Hill"
            },
            {
                "value": 13430,
                "label": "Pleasant Hope",
                "label_ar": "أمل سعيد",
                "label_fr": "Pleasant Hope"
            },
            {
                "value": 13431,
                "label": "Pleasant Lake",
                "label_ar": "بليزانت ليك",
                "label_fr": "Pleasant Lake"
            },
            {
                "value": 13432,
                "label": "Pleasant Mount",
                "label_ar": "بليزانت ماونت",
                "label_fr": "Mont agréable"
            },
            {
                "value": 13433,
                "label": "Pleasant Plain",
                "label_ar": "سهل لطيف",
                "label_fr": "Pleasant Plain"
            },
            {
                "value": 13434,
                "label": "Pleasant Plains",
                "label_ar": "سهول ممتعة",
                "label_fr": "Pleasant Plains"
            },
            {
                "value": 13435,
                "label": "Pleasant Prairie",
                "label_ar": "بليزانت براري",
                "label_fr": "Pleasant Prairie"
            },
            {
                "value": 13436,
                "label": "Pleasant Ridge",
                "label_ar": "بليزانت ريدج",
                "label_fr": "Pleasant Ridge"
            },
            {
                "value": 13437,
                "label": "Pleasant Shade",
                "label_ar": "ظل لطيف",
                "label_fr": "Ombre agréable"
            },
            {
                "value": 13438,
                "label": "Pleasant Unity",
                "label_ar": "الوحدة السارة",
                "label_fr": "Unité agréable"
            },
            {
                "value": 13439,
                "label": "Pleasant Valley",
                "label_ar": "بليزانت فالي",
                "label_fr": "Pleasant Valley"
            },
            {
                "value": 13440,
                "label": "Pleasant View",
                "label_ar": "منظر لطيف",
                "label_fr": "Vue agréable"
            },
            {
                "value": 13441,
                "label": "Pleasanton",
                "label_ar": "بليسانتون",
                "label_fr": "Pleasanton"
            },
            {
                "value": 13442,
                "label": "Pleasantville",
                "label_ar": "بليزانتفيل",
                "label_fr": "Pleasantville"
            },
            {
                "value": 13443,
                "label": "Pleasureville",
                "label_ar": "بليسورفيل",
                "label_fr": "Pleasureville"
            },
            {
                "value": 13444,
                "label": "Plentywood",
                "label_ar": "بلينتيوود",
                "label_fr": "Plentywood"
            },
            {
                "value": 13445,
                "label": "Plover",
                "label_ar": "الزقزاق",
                "label_fr": "Pluvier"
            },
            {
                "value": 13446,
                "label": "Plum Branch",
                "label_ar": "فرع البرقوق",
                "label_fr": "Branche de prune"
            },
            {
                "value": 13447,
                "label": "Plum City",
                "label_ar": "بلوم سيتي",
                "label_fr": "Ville de prune"
            },
            {
                "value": 13448,
                "label": "Plumerville",
                "label_ar": "بلومرفيل",
                "label_fr": "Plumerville"
            },
            {
                "value": 13449,
                "label": "Plummer",
                "label_ar": "بلامر",
                "label_fr": "Plummer"
            },
            {
                "value": 13450,
                "label": "Plummers Landing",
                "label_ar": "الهبوط بلامرز",
                "label_fr": "Atterrissage des plummers"
            },
            {
                "value": 13451,
                "label": "Plumsteadville",
                "label_ar": "بلومستيدفيل",
                "label_fr": "Plumsteadville"
            },
            {
                "value": 13452,
                "label": "Plumville",
                "label_ar": "بلومفيل",
                "label_fr": "Plumville"
            },
            {
                "value": 13453,
                "label": "Plymouth",
                "label_ar": "بليموث",
                "label_fr": "Plymouth"
            },
            {
                "value": 13454,
                "label": "Plymouth Meeting",
                "label_ar": "اجتماع بليموث",
                "label_fr": "Réunion de Plymouth"
            },
            {
                "value": 13455,
                "label": "Plympton",
                "label_ar": "بليمبتون",
                "label_fr": "Plympton"
            },
            {
                "value": 13456,
                "label": "Poca",
                "label_ar": "بوكا",
                "label_fr": "Poca"
            },
            {
                "value": 13457,
                "label": "Pocahontas",
                "label_ar": "بوكاهونتاس",
                "label_fr": "Pocahontas"
            },
            {
                "value": 13458,
                "label": "Pocasset",
                "label_ar": "بوكاسيت",
                "label_fr": "Pocasset"
            },
            {
                "value": 13459,
                "label": "Pocatello",
                "label_ar": "بوكاتيلو",
                "label_fr": "Pocatello"
            },
            {
                "value": 13460,
                "label": "Pocola",
                "label_ar": "بوكولا",
                "label_fr": "Pocola"
            },
            {
                "value": 13461,
                "label": "Pocomoke City",
                "label_ar": "مدينة بوكوموك",
                "label_fr": "Ville de Pocomoke"
            },
            {
                "value": 13462,
                "label": "Pocono Pines",
                "label_ar": "بوكونو باينز",
                "label_fr": "Pins Pocono"
            },
            {
                "value": 13463,
                "label": "Pocono Summit",
                "label_ar": "قمة بوكونو",
                "label_fr": "Sommet Pocono"
            },
            {
                "value": 13464,
                "label": "Poestenkill",
                "label_ar": "Poestenkill",
                "label_fr": "Poestenkill"
            },
            {
                "value": 13465,
                "label": "Point",
                "label_ar": "نقطة",
                "label_fr": "Point"
            },
            {
                "value": 13466,
                "label": "Point Arena",
                "label_ar": "بوينت ارينا",
                "label_fr": "Point Arena"
            },
            {
                "value": 13467,
                "label": "Point Comfort",
                "label_ar": "نقطة الراحة",
                "label_fr": "Point confort"
            },
            {
                "value": 13468,
                "label": "Point Harbor",
                "label_ar": "بوينت هاربور",
                "label_fr": "Point Harbour"
            },
            {
                "value": 13469,
                "label": "Point Hope",
                "label_ar": "نقطة الأمل",
                "label_fr": "Point Hope"
            },
            {
                "value": 13470,
                "label": "Point Lay",
                "label_ar": "نقطة لاي",
                "label_fr": "Point Lay"
            },
            {
                "value": 13471,
                "label": "Point Lookout",
                "label_ar": "نقطة المراقبة",
                "label_fr": "Point de vue"
            },
            {
                "value": 13472,
                "label": "Point Pleasant",
                "label_ar": "بوينت بليزانت",
                "label_fr": "Point agréable"
            },
            {
                "value": 13473,
                "label": "Point Pleasant Beach",
                "label_ar": "بوينت بليزانت بيتش",
                "label_fr": "Plage de Point Pleasant"
            },
            {
                "value": 13474,
                "label": "Point Reyes Station",
                "label_ar": "محطة بوينت رييس",
                "label_fr": "Gare de Point Reyes"
            },
            {
                "value": 13475,
                "label": "Point Roberts",
                "label_ar": "نقطة روبرتس",
                "label_fr": "Point Roberts"
            },
            {
                "value": 13476,
                "label": "Point of Rocks",
                "label_ar": "نقطة الصخور",
                "label_fr": "Point de roches"
            },
            {
                "value": 13477,
                "label": "Pointblank",
                "label_ar": "نقطة فارغة",
                "label_fr": "Pointblank"
            },
            {
                "value": 13478,
                "label": "Polacca",
                "label_ar": "بولاكا",
                "label_fr": "Polacca"
            },
            {
                "value": 13479,
                "label": "Poland",
                "label_ar": "بولندا",
                "label_fr": "Pologne"
            },
            {
                "value": 13480,
                "label": "Polk",
                "label_ar": "بولك",
                "label_fr": "Polk"
            },
            {
                "value": 13481,
                "label": "Polk City",
                "label_ar": "بولك سيتي",
                "label_fr": "Polk City"
            },
            {
                "value": 13482,
                "label": "Polkton",
                "label_ar": "بولكتون",
                "label_fr": "Polkton"
            },
            {
                "value": 13483,
                "label": "Polkville",
                "label_ar": "بولكفيل",
                "label_fr": "Polkville"
            },
            {
                "value": 13484,
                "label": "Pollard",
                "label_ar": "بولارد",
                "label_fr": "Têtard"
            },
            {
                "value": 13485,
                "label": "Pollock",
                "label_ar": "بولوك",
                "label_fr": "Pollock"
            },
            {
                "value": 13486,
                "label": "Pollock Pines",
                "label_ar": "بولوك باينز",
                "label_fr": "Pollock Pines"
            },
            {
                "value": 13487,
                "label": "Pollocksville",
                "label_ar": "بولوكسفيل",
                "label_fr": "Pollocksville"
            },
            {
                "value": 13488,
                "label": "Pollok",
                "label_ar": "بولوك",
                "label_fr": "Pollok"
            },
            {
                "value": 13489,
                "label": "Polo",
                "label_ar": "بولو",
                "label_fr": "Polo"
            },
            {
                "value": 13490,
                "label": "Polson",
                "label_ar": "بولسون",
                "label_fr": "Polson"
            },
            {
                "value": 13491,
                "label": "Pomaria",
                "label_ar": "بوماريا",
                "label_fr": "Pomaria"
            },
            {
                "value": 13492,
                "label": "Pomerene",
                "label_ar": "بومرين",
                "label_fr": "Pomérène"
            },
            {
                "value": 13493,
                "label": "Pomeroy",
                "label_ar": "بوميروي",
                "label_fr": "Pomeroy"
            },
            {
                "value": 13494,
                "label": "Pomfret",
                "label_ar": "بومفريت",
                "label_fr": "Pomfret"
            },
            {
                "value": 13495,
                "label": "Pomfret Center",
                "label_ar": "مركز بومفريت",
                "label_fr": "Centre Pomfret"
            },
            {
                "value": 13496,
                "label": "Pomona",
                "label_ar": "بومونا",
                "label_fr": "Pomona"
            },
            {
                "value": 13497,
                "label": "Pomona Park",
                "label_ar": "بومونا بارك",
                "label_fr": "Parc Pomona"
            },
            {
                "value": 13498,
                "label": "Pompano Beach",
                "label_ar": "شاطئ بومبانو",
                "label_fr": "Plage de Pompano"
            },
            {
                "value": 13499,
                "label": "Pompey",
                "label_ar": "بومبي",
                "label_fr": "Pompée"
            },
            {
                "value": 13500,
                "label": "Pompton Lakes",
                "label_ar": "بحيرات بومبتون",
                "label_fr": "Lacs Pompton"
            },
            {
                "value": 13501,
                "label": "Pompton Plains",
                "label_ar": "بومبتون بلينز",
                "label_fr": "Plaines de Pompton"
            },
            {
                "value": 13502,
                "label": "Ponca",
                "label_ar": "بونكا",
                "label_fr": "Ponca"
            },
            {
                "value": 13503,
                "label": "Ponca City",
                "label_ar": "مدينة بونكا",
                "label_fr": "Ville de Ponca"
            },
            {
                "value": 13504,
                "label": "Ponce de Leon",
                "label_ar": "بونس دي ليون",
                "label_fr": "Ponce de Léon"
            },
            {
                "value": 13505,
                "label": "Ponchatoula",
                "label_ar": "بونشاتولا",
                "label_fr": "Ponchatoula"
            },
            {
                "value": 13506,
                "label": "Pond Creek",
                "label_ar": "بوند كريك",
                "label_fr": "Pond Creek"
            },
            {
                "value": 13507,
                "label": "Ponder",
                "label_ar": "تأمل",
                "label_fr": "Réflechir"
            },
            {
                "value": 13508,
                "label": "Ponderay",
                "label_ar": "بونديراي",
                "label_fr": "Ponderay"
            },
            {
                "value": 13509,
                "label": "Ponemah",
                "label_ar": "بونيمة",
                "label_fr": "Ponemah"
            },
            {
                "value": 13510,
                "label": "Poneto",
                "label_ar": "بونتو",
                "label_fr": "Poneto"
            },
            {
                "value": 13511,
                "label": "Ponsford",
                "label_ar": "بونسفورد",
                "label_fr": "Ponsford"
            },
            {
                "value": 13512,
                "label": "Ponte Vedra",
                "label_ar": "بونتي فيدرا",
                "label_fr": "Ponte Vedra"
            },
            {
                "value": 13513,
                "label": "Ponte Vedra Beach",
                "label_ar": "شاطئ بونتي فيدرا",
                "label_fr": "Plage de Ponte Vedra"
            },
            {
                "value": 13514,
                "label": "Pontiac",
                "label_ar": "بونتياك",
                "label_fr": "Pontiac"
            },
            {
                "value": 13515,
                "label": "Pontotoc",
                "label_ar": "بونتوتوك",
                "label_fr": "Pontotoc"
            },
            {
                "value": 13516,
                "label": "Pooler",
                "label_ar": "بولر",
                "label_fr": "Pooler"
            },
            {
                "value": 13517,
                "label": "Poolesville",
                "label_ar": "بولسفيل",
                "label_fr": "Poolesville"
            },
            {
                "value": 13518,
                "label": "Poolville",
                "label_ar": "بولفيل",
                "label_fr": "Poolville"
            },
            {
                "value": 13519,
                "label": "Pope",
                "label_ar": "بابا الفاتيكان",
                "label_fr": "le pape"
            },
            {
                "value": 13520,
                "label": "Pope Valley",
                "label_ar": "وادي البابا",
                "label_fr": "Vallée du pape"
            },
            {
                "value": 13521,
                "label": "Poplar",
                "label_ar": "حور",
                "label_fr": "Peuplier"
            },
            {
                "value": 13522,
                "label": "Poplar Bluff",
                "label_ar": "حور بلاف",
                "label_fr": "Poplar Bluff"
            },
            {
                "value": 13523,
                "label": "Poplar Branch",
                "label_ar": "فرع الحور",
                "label_fr": "Branche de peuplier"
            },
            {
                "value": 13524,
                "label": "Poplar Grove",
                "label_ar": "بوبلار جروف",
                "label_fr": "Poplar Grove"
            },
            {
                "value": 13525,
                "label": "Poplarville",
                "label_ar": "بوبلارفيل",
                "label_fr": "Poplarville"
            },
            {
                "value": 13526,
                "label": "Poquoson",
                "label_ar": "بوكوسون",
                "label_fr": "Poquoson"
            },
            {
                "value": 13527,
                "label": "Porcupine",
                "label_ar": "النيص",
                "label_fr": "Porc-épic"
            },
            {
                "value": 13528,
                "label": "Port Allegany",
                "label_ar": "بورت اليجاني",
                "label_fr": "Port Allegany"
            },
            {
                "value": 13529,
                "label": "Port Allen",
                "label_ar": "بورت ألين",
                "label_fr": "Port Allen"
            },
            {
                "value": 13530,
                "label": "Port Angeles",
                "label_ar": "بورت انجيليس",
                "label_fr": "Port Angeles"
            },
            {
                "value": 13531,
                "label": "Port Aransas",
                "label_ar": "ميناء أرانساس",
                "label_fr": "Port Aransas"
            },
            {
                "value": 13532,
                "label": "Port Arthur",
                "label_ar": "بورت آرثر",
                "label_fr": "Port Arthur"
            },
            {
                "value": 13533,
                "label": "Port Austin",
                "label_ar": "ميناء أوستن",
                "label_fr": "Port Austin"
            },
            {
                "value": 13534,
                "label": "Port Barre",
                "label_ar": "بورت باري",
                "label_fr": "Port Barre"
            },
            {
                "value": 13535,
                "label": "Port Bolivar",
                "label_ar": "بورت بوليفار",
                "label_fr": "Port Bolivar"
            },
            {
                "value": 13536,
                "label": "Port Byron",
                "label_ar": "بورت بايرون",
                "label_fr": "Port Byron"
            },
            {
                "value": 13537,
                "label": "Port Carbon",
                "label_ar": "ميناء كربون",
                "label_fr": "Port Carbon"
            },
            {
                "value": 13538,
                "label": "Port Charlotte",
                "label_ar": "بورت شارلوت",
                "label_fr": "Port Charlotte"
            },
            {
                "value": 13539,
                "label": "Port Chester",
                "label_ar": "بورت تشيستر",
                "label_fr": "Port Chester"
            },
            {
                "value": 13540,
                "label": "Port Clinton",
                "label_ar": "بورت كلينتون",
                "label_fr": "Port Clinton"
            },
            {
                "value": 13541,
                "label": "Port Crane",
                "label_ar": "ميناء كرين",
                "label_fr": "Grue portuaire"
            },
            {
                "value": 13542,
                "label": "Port Deposit",
                "label_ar": "إيداع المنفذ",
                "label_fr": "Dépôt au port"
            },
            {
                "value": 13543,
                "label": "Port Edwards",
                "label_ar": "بورت إدواردز",
                "label_fr": "Port Edwards"
            },
            {
                "value": 13544,
                "label": "Port Elizabeth",
                "label_ar": "ميناء اليزابيث",
                "label_fr": "Port Elizabeth"
            },
            {
                "value": 13545,
                "label": "Port Gibson",
                "label_ar": "بورت جيبسون",
                "label_fr": "Port Gibson"
            },
            {
                "value": 13546,
                "label": "Port Hadlock",
                "label_ar": "ميناء هادلوك",
                "label_fr": "Port Hadlock"
            },
            {
                "value": 13547,
                "label": "Port Henry",
                "label_ar": "ميناء هنري",
                "label_fr": "Port Henry"
            },
            {
                "value": 13548,
                "label": "Port Hope",
                "label_ar": "ميناء الأمل",
                "label_fr": "Port Hope"
            },
            {
                "value": 13549,
                "label": "Port Hueneme",
                "label_ar": "ميناء Hueneme",
                "label_fr": "Port Hueneme"
            },
            {
                "value": 13550,
                "label": "Port Huron",
                "label_ar": "بورت هورون",
                "label_fr": "Port Huron"
            },
            {
                "value": 13551,
                "label": "Port Isabel",
                "label_ar": "ميناء إيزابيل",
                "label_fr": "Port Isabel"
            },
            {
                "value": 13552,
                "label": "Port Jefferson",
                "label_ar": "ميناء جيفرسون",
                "label_fr": "Port Jefferson"
            },
            {
                "value": 13553,
                "label": "Port Jefferson Station",
                "label_ar": "محطة بورت جيفرسون",
                "label_fr": "Gare de Port Jefferson"
            },
            {
                "value": 13554,
                "label": "Port Jervis",
                "label_ar": "ميناء جيرفيس",
                "label_fr": "Port Jervis"
            },
            {
                "value": 13555,
                "label": "Port Lavaca",
                "label_ar": "بورت لافاكا",
                "label_fr": "Port Lavaca"
            },
            {
                "value": 13556,
                "label": "Port Leyden",
                "label_ar": "ميناء ليدن",
                "label_fr": "Port Leyden"
            },
            {
                "value": 13557,
                "label": "Port Ludlow",
                "label_ar": "بورت لودلو",
                "label_fr": "Port Ludlow"
            },
            {
                "value": 13558,
                "label": "Port Mansfield",
                "label_ar": "بورت مانسفيلد",
                "label_fr": "Port Mansfield"
            },
            {
                "value": 13559,
                "label": "Port Matilda",
                "label_ar": "ميناء ماتيلدا",
                "label_fr": "Port Mathilde"
            },
            {
                "value": 13560,
                "label": "Port Monmouth",
                "label_ar": "بورت مونماوث",
                "label_fr": "Port Monmouth"
            },
            {
                "value": 13561,
                "label": "Port Murray",
                "label_ar": "بورت موراي",
                "label_fr": "Port Murray"
            },
            {
                "value": 13562,
                "label": "Port Neches",
                "label_ar": "ميناء Neches",
                "label_fr": "Port Neches"
            },
            {
                "value": 13563,
                "label": "Port Norris",
                "label_ar": "بورت نوريس",
                "label_fr": "Port Norris"
            },
            {
                "value": 13564,
                "label": "Port Orange",
                "label_ar": "بورت أورانج",
                "label_fr": "Port orange"
            },
            {
                "value": 13565,
                "label": "Port Orchard",
                "label_ar": "بورت أوركارد",
                "label_fr": "Verger de Port"
            },
            {
                "value": 13566,
                "label": "Port Orford",
                "label_ar": "بورت أورفورد",
                "label_fr": "Port Orford"
            },
            {
                "value": 13567,
                "label": "Port Penn",
                "label_ar": "بورت بن",
                "label_fr": "Port Penn"
            },
            {
                "value": 13568,
                "label": "Port Reading",
                "label_ar": "قراءة الميناء",
                "label_fr": "Lecture du port"
            },
            {
                "value": 13569,
                "label": "Port Republic",
                "label_ar": "جمهورية ميناء",
                "label_fr": "République portuaire"
            },
            {
                "value": 13570,
                "label": "Port Richey",
                "label_ar": "بورت ريتشي",
                "label_fr": "Port Richey"
            },
            {
                "value": 13571,
                "label": "Port Royal",
                "label_ar": "ميناء ملكي",
                "label_fr": "Port Royal"
            },
            {
                "value": 13572,
                "label": "Port Saint Joe",
                "label_ar": "بورت سانت جو",
                "label_fr": "Port Saint Joe"
            },
            {
                "value": 13573,
                "label": "Port Saint Lucie",
                "label_ar": "بورت سانت لوسي",
                "label_fr": "Port Sainte Lucie"
            },
            {
                "value": 13574,
                "label": "Port Salerno",
                "label_ar": "بورت ساليرنو",
                "label_fr": "Port Salerne"
            },
            {
                "value": 13575,
                "label": "Port Sanilac",
                "label_ar": "ميناء سانيلاك",
                "label_fr": "Port Sanilac"
            },
            {
                "value": 13576,
                "label": "Port Tobacco",
                "label_ar": "بورت توباكو",
                "label_fr": "Tabac de Porto"
            },
            {
                "value": 13577,
                "label": "Port Townsend",
                "label_ar": "ميناء تاونسند",
                "label_fr": "Port Townsend"
            },
            {
                "value": 13578,
                "label": "Port Trevorton",
                "label_ar": "بورت تريفورتون",
                "label_fr": "Port Trevorton"
            },
            {
                "value": 13579,
                "label": "Port Washington",
                "label_ar": "ميناء واشنطن",
                "label_fr": "Port Washington"
            },
            {
                "value": 13580,
                "label": "Portage",
                "label_ar": "حمل",
                "label_fr": "Portage"
            },
            {
                "value": 13581,
                "label": "Portageville",
                "label_ar": "بورتاجفيل",
                "label_fr": "Portageville"
            },
            {
                "value": 13582,
                "label": "Portal",
                "label_ar": "بوابة",
                "label_fr": "Portail"
            },
            {
                "value": 13583,
                "label": "Portales",
                "label_ar": "بورتاليس",
                "label_fr": "Portales"
            },
            {
                "value": 13584,
                "label": "Porter",
                "label_ar": "حمال",
                "label_fr": "Porter"
            },
            {
                "value": 13585,
                "label": "Porter Corners",
                "label_ar": "زوايا بورتر",
                "label_fr": "Porter Corners"
            },
            {
                "value": 13586,
                "label": "Portersville",
                "label_ar": "بورترسفيل",
                "label_fr": "Portersville"
            },
            {
                "value": 13587,
                "label": "Porterville",
                "label_ar": "بورترفيل",
                "label_fr": "Porterville"
            },
            {
                "value": 13588,
                "label": "Portland",
                "label_ar": "بورتلاند",
                "label_fr": "Portland"
            },
            {
                "value": 13589,
                "label": "Portola",
                "label_ar": "بورتولا",
                "label_fr": "Portola"
            },
            {
                "value": 13590,
                "label": "Portola Valley",
                "label_ar": "وادي بورتولا",
                "label_fr": "Vallée de la Portola"
            },
            {
                "value": 13591,
                "label": "Portsmouth",
                "label_ar": "بورتسموث",
                "label_fr": "Portsmouth"
            },
            {
                "value": 13592,
                "label": "Portville",
                "label_ar": "بورتفيل",
                "label_fr": "Portville"
            },
            {
                "value": 13593,
                "label": "Porum",
                "label_ar": "بوروم",
                "label_fr": "Porum"
            },
            {
                "value": 13594,
                "label": "Posen",
                "label_ar": "بوزين",
                "label_fr": "Posen"
            },
            {
                "value": 13595,
                "label": "Poseyville",
                "label_ar": "بوسيفيل",
                "label_fr": "Poseyville"
            },
            {
                "value": 13596,
                "label": "Post",
                "label_ar": "بريد",
                "label_fr": "Publier"
            },
            {
                "value": 13597,
                "label": "Post Falls",
                "label_ar": "شلالات بوست",
                "label_fr": "Post Falls"
            },
            {
                "value": 13598,
                "label": "Post Mills",
                "label_ar": "مصانع البريد",
                "label_fr": "Post Mills"
            },
            {
                "value": 13599,
                "label": "Poston",
                "label_ar": "بوستون",
                "label_fr": "Publier sur"
            },
            {
                "value": 13600,
                "label": "Postville",
                "label_ar": "بوستفيل",
                "label_fr": "Postville"
            },
            {
                "value": 13601,
                "label": "Poteau",
                "label_ar": "بوتو",
                "label_fr": "Poteau"
            },
            {
                "value": 13602,
                "label": "Poteet",
                "label_ar": "بوتيت",
                "label_fr": "Poteet"
            },
            {
                "value": 13603,
                "label": "Poth",
                "label_ar": "بوث",
                "label_fr": "Poth"
            },
            {
                "value": 13604,
                "label": "Potlatch",
                "label_ar": "بوتلاتش",
                "label_fr": "Potlatch"
            },
            {
                "value": 13605,
                "label": "Potomac",
                "label_ar": "بوتوماك",
                "label_fr": "Potomac"
            },
            {
                "value": 13606,
                "label": "Potosi",
                "label_ar": "بوتوسي",
                "label_fr": "Potosi"
            },
            {
                "value": 13607,
                "label": "Potsdam",
                "label_ar": "السد الخلفي",
                "label_fr": "Potsdam"
            },
            {
                "value": 13608,
                "label": "Potter",
                "label_ar": "بوتر",
                "label_fr": "Potier"
            },
            {
                "value": 13609,
                "label": "Potter Valley",
                "label_ar": "وادي بوتر",
                "label_fr": "Potter Valley"
            },
            {
                "value": 13610,
                "label": "Pottersville",
                "label_ar": "بوترزفيل",
                "label_fr": "Pottersville"
            },
            {
                "value": 13611,
                "label": "Potterville",
                "label_ar": "بوترفيل",
                "label_fr": "Potterville"
            },
            {
                "value": 13612,
                "label": "Potts Camp",
                "label_ar": "بوتس كامب",
                "label_fr": "Camp de Potts"
            },
            {
                "value": 13613,
                "label": "Pottsboro",
                "label_ar": "بوتسبورو",
                "label_fr": "Pottsboro"
            },
            {
                "value": 13614,
                "label": "Pottstown",
                "label_ar": "بوتستاون",
                "label_fr": "Pottstown"
            },
            {
                "value": 13615,
                "label": "Pottsville",
                "label_ar": "بوتسفيل",
                "label_fr": "Pottsville"
            },
            {
                "value": 13616,
                "label": "Potwin",
                "label_ar": "بوتوين",
                "label_fr": "Potwin"
            },
            {
                "value": 13617,
                "label": "Poughkeepsie",
                "label_ar": "بوكيبسي",
                "label_fr": "Poughkeepsie"
            },
            {
                "value": 13618,
                "label": "Poughquag",
                "label_ar": "بوغكواج",
                "label_fr": "Poughquag"
            },
            {
                "value": 13619,
                "label": "Poulan",
                "label_ar": "بولان",
                "label_fr": "Poulan"
            },
            {
                "value": 13620,
                "label": "Poulsbo",
                "label_ar": "بولسبو",
                "label_fr": "Poulsbo"
            },
            {
                "value": 13621,
                "label": "Poultney",
                "label_ar": "بولتني",
                "label_fr": "Poultney"
            },
            {
                "value": 13622,
                "label": "Pound",
                "label_ar": "جنيه",
                "label_fr": "Livre"
            },
            {
                "value": 13623,
                "label": "Pound Ridge",
                "label_ar": "باوند ريدج",
                "label_fr": "Pound Ridge"
            },
            {
                "value": 13624,
                "label": "Pounding Mill",
                "label_ar": "مطحنة القصف",
                "label_fr": "Moulin à piler"
            },
            {
                "value": 13625,
                "label": "Poway",
                "label_ar": "بواي",
                "label_fr": "Poway"
            },
            {
                "value": 13626,
                "label": "Powder Springs",
                "label_ar": "مسحوق الينابيع",
                "label_fr": "Powder Springs"
            },
            {
                "value": 13627,
                "label": "Powderly",
                "label_ar": "مسحوق",
                "label_fr": "Poudreux"
            },
            {
                "value": 13628,
                "label": "Powell",
                "label_ar": "باول",
                "label_fr": "Powell"
            },
            {
                "value": 13629,
                "label": "Powell Butte",
                "label_ar": "باول بوت",
                "label_fr": "Powell Butte"
            },
            {
                "value": 13630,
                "label": "Powellsville",
                "label_ar": "باويلسفيل",
                "label_fr": "Powellsville"
            },
            {
                "value": 13631,
                "label": "Powellton",
                "label_ar": "باولتون",
                "label_fr": "Powellton"
            },
            {
                "value": 13632,
                "label": "Powers",
                "label_ar": "السلطات",
                "label_fr": "Pouvoirs"
            },
            {
                "value": 13633,
                "label": "Powers Lake",
                "label_ar": "بحيرة باورز",
                "label_fr": "Lac Powers"
            },
            {
                "value": 13634,
                "label": "Powhatan",
                "label_ar": "بوهاتان",
                "label_fr": "Powhatan"
            },
            {
                "value": 13635,
                "label": "Powhatan Point",
                "label_ar": "نقطة بوهاتان",
                "label_fr": "Point Powhatan"
            },
            {
                "value": 13636,
                "label": "Pownal",
                "label_ar": "Pownal",
                "label_fr": "Pownal"
            },
            {
                "value": 13637,
                "label": "Poyen",
                "label_ar": "بوين",
                "label_fr": "Poyen"
            },
            {
                "value": 13638,
                "label": "Poynette",
                "label_ar": "بوينيت",
                "label_fr": "Poynette"
            },
            {
                "value": 13639,
                "label": "Poynor",
                "label_ar": "بوينور",
                "label_fr": "Poynor"
            },
            {
                "value": 13640,
                "label": "Poyntelle",
                "label_ar": "Poyntelle",
                "label_fr": "Poyntelle"
            },
            {
                "value": 13641,
                "label": "Prague",
                "label_ar": "براغ",
                "label_fr": "Prague"
            },
            {
                "value": 13642,
                "label": "Prairie",
                "label_ar": "البراري",
                "label_fr": "Prairie"
            },
            {
                "value": 13643,
                "label": "Prairie City",
                "label_ar": "مدينة البراري",
                "label_fr": "Prairie City"
            },
            {
                "value": 13644,
                "label": "Prairie Farm",
                "label_ar": "مزرعة البراري",
                "label_fr": "Ferme des Prairies"
            },
            {
                "value": 13645,
                "label": "Prairie Grove",
                "label_ar": "برايري غروف",
                "label_fr": "Prairie Grove"
            },
            {
                "value": 13646,
                "label": "Prairie View",
                "label_ar": "منظر البراري",
                "label_fr": "Vue sur les prairies"
            },
            {
                "value": 13647,
                "label": "Prairie Village",
                "label_ar": "قرية البراري",
                "label_fr": "Village des Prairies"
            },
            {
                "value": 13648,
                "label": "Prairie du Chien",
                "label_ar": "براري دو شين",
                "label_fr": "Prairie du Chien"
            },
            {
                "value": 13649,
                "label": "Prairie du Rocher",
                "label_ar": "المرج دو روشيه",
                "label_fr": "Prairie du Rocher"
            },
            {
                "value": 13650,
                "label": "Prairie du Sac",
                "label_ar": "براري دو ساك",
                "label_fr": "Prairie du Sac"
            },
            {
                "value": 13651,
                "label": "Prairieton",
                "label_ar": "برايريتون",
                "label_fr": "Prairieton"
            },
            {
                "value": 13652,
                "label": "Prairieville",
                "label_ar": "برايرييفيل",
                "label_fr": "Prairieville"
            },
            {
                "value": 13653,
                "label": "Prather",
                "label_ar": "براثر",
                "label_fr": "Prather"
            },
            {
                "value": 13654,
                "label": "Pratt",
                "label_ar": "برات",
                "label_fr": "Pratt"
            },
            {
                "value": 13655,
                "label": "Pratts",
                "label_ar": "براتس",
                "label_fr": "Pratts"
            },
            {
                "value": 13656,
                "label": "Prattsburgh",
                "label_ar": "براتسبرج",
                "label_fr": "Prattsburgh"
            },
            {
                "value": 13657,
                "label": "Prattsville",
                "label_ar": "براتسفيل",
                "label_fr": "Prattsville"
            },
            {
                "value": 13658,
                "label": "Prattville",
                "label_ar": "براتفيل",
                "label_fr": "Prattville"
            },
            {
                "value": 13659,
                "label": "Preble",
                "label_ar": "بريبل",
                "label_fr": "Preble"
            },
            {
                "value": 13660,
                "label": "Preemption",
                "label_ar": "الشفعة",
                "label_fr": "Préemption"
            },
            {
                "value": 13661,
                "label": "Premier",
                "label_ar": "الرائدة",
                "label_fr": "Premier"
            },
            {
                "value": 13662,
                "label": "Prentiss",
                "label_ar": "برينتيس",
                "label_fr": "Prentiss"
            },
            {
                "value": 13663,
                "label": "Prescott",
                "label_ar": "بريسكوت",
                "label_fr": "Prescott"
            },
            {
                "value": 13664,
                "label": "Prescott Valley",
                "label_ar": "وادي بريسكوت",
                "label_fr": "Vallée de Prescott"
            },
            {
                "value": 13665,
                "label": "Presho",
                "label_ar": "بريشو",
                "label_fr": "Presho"
            },
            {
                "value": 13666,
                "label": "Presidio",
                "label_ar": "بريسيديو",
                "label_fr": "Presidio"
            },
            {
                "value": 13667,
                "label": "Presque Isle",
                "label_ar": "Presque Isle",
                "label_fr": "Île Presque"
            },
            {
                "value": 13668,
                "label": "Presto",
                "label_ar": "المعزوفة",
                "label_fr": "Presto"
            },
            {
                "value": 13669,
                "label": "Preston",
                "label_ar": "بريستون",
                "label_fr": "Preston"
            },
            {
                "value": 13670,
                "label": "Preston City",
                "label_ar": "مدينة بريستون",
                "label_fr": "Preston City"
            },
            {
                "value": 13671,
                "label": "Preston Hollow",
                "label_ar": "بريستون هولو",
                "label_fr": "Preston Hollow"
            },
            {
                "value": 13672,
                "label": "Prestonsburg",
                "label_ar": "برستونسبورغ",
                "label_fr": "Prestonsburg"
            },
            {
                "value": 13673,
                "label": "Prewitt",
                "label_ar": "بريويت",
                "label_fr": "Prewitt"
            },
            {
                "value": 13674,
                "label": "Price",
                "label_ar": "السعر",
                "label_fr": "Prix"
            },
            {
                "value": 13675,
                "label": "Prichard",
                "label_ar": "بريتشارد",
                "label_fr": "Prichard"
            },
            {
                "value": 13676,
                "label": "Pride",
                "label_ar": "فخر",
                "label_fr": "Fierté"
            },
            {
                "value": 13677,
                "label": "Priest River",
                "label_ar": "نهر الكاهن",
                "label_fr": "Rivière Priest"
            },
            {
                "value": 13678,
                "label": "Primghar",
                "label_ar": "بريمغار",
                "label_fr": "Primghar"
            },
            {
                "value": 13679,
                "label": "Primm Springs",
                "label_ar": "بريم سبرينغز",
                "label_fr": "Primm Springs"
            },
            {
                "value": 13680,
                "label": "Primus",
                "label_ar": "بريموس",
                "label_fr": "Primus"
            },
            {
                "value": 13681,
                "label": "Prince Frederick",
                "label_ar": "الأمير فريدريك",
                "label_fr": "Prince Frederick"
            },
            {
                "value": 13682,
                "label": "Prince George",
                "label_ar": "الأمير جورج",
                "label_fr": "Prince George"
            },
            {
                "value": 13683,
                "label": "Princess Anne",
                "label_ar": "الأميرة آن",
                "label_fr": "Princesse Anne"
            },
            {
                "value": 13684,
                "label": "Princeton",
                "label_ar": "برينستون",
                "label_fr": "Princeton"
            },
            {
                "value": 13685,
                "label": "Princeton Junction",
                "label_ar": "مفرق برينستون",
                "label_fr": "Princeton Junction"
            },
            {
                "value": 13686,
                "label": "Princeville",
                "label_ar": "برينسفيل",
                "label_fr": "Princeville"
            },
            {
                "value": 13687,
                "label": "Princewick",
                "label_ar": "برينسويك",
                "label_fr": "Princewick"
            },
            {
                "value": 13688,
                "label": "Prineville",
                "label_ar": "برينفيل",
                "label_fr": "Prineville"
            },
            {
                "value": 13689,
                "label": "Prinsburg",
                "label_ar": "برينسبورغ",
                "label_fr": "Prinsburg"
            },
            {
                "value": 13690,
                "label": "Printer",
                "label_ar": "طابعة",
                "label_fr": "Imprimante"
            },
            {
                "value": 13691,
                "label": "Prior Lake",
                "label_ar": "بحيرة بريور",
                "label_fr": "Lac Prior"
            },
            {
                "value": 13692,
                "label": "Pritchett",
                "label_ar": "بريتشيت",
                "label_fr": "Pritchett"
            },
            {
                "value": 13693,
                "label": "Procious",
                "label_ar": "مطيع",
                "label_fr": "Procieux"
            },
            {
                "value": 13694,
                "label": "Proctor",
                "label_ar": "بروكتور",
                "label_fr": "Procureur"
            },
            {
                "value": 13695,
                "label": "Proctorville",
                "label_ar": "بروكتورفيل",
                "label_fr": "Proctorville"
            },
            {
                "value": 13696,
                "label": "Progreso",
                "label_ar": "بروغريسو",
                "label_fr": "Progreso"
            },
            {
                "value": 13697,
                "label": "Prompton",
                "label_ar": "برومبتون",
                "label_fr": "Prompton"
            },
            {
                "value": 13698,
                "label": "Prophetstown",
                "label_ar": "نبيزتاون",
                "label_fr": "Prophetstown"
            },
            {
                "value": 13699,
                "label": "Prospect",
                "label_ar": "احتمال",
                "label_fr": "Perspective"
            },
            {
                "value": 13700,
                "label": "Prospect Harbor",
                "label_ar": "ميناء بروسبكت",
                "label_fr": "Prospect Harbour"
            },
            {
                "value": 13701,
                "label": "Prospect Heights",
                "label_ar": "بروسبكت هايتس",
                "label_fr": "Prospect Heights"
            },
            {
                "value": 13702,
                "label": "Prospect Park",
                "label_ar": "بروسبكت بارك",
                "label_fr": "Prospect Park"
            },
            {
                "value": 13703,
                "label": "Prosper",
                "label_ar": "تزدهر",
                "label_fr": "Prospérer"
            },
            {
                "value": 13704,
                "label": "Prosperity",
                "label_ar": "الازدهار",
                "label_fr": "La prospérité"
            },
            {
                "value": 13705,
                "label": "Prosser",
                "label_ar": "بروسر",
                "label_fr": "Prosser"
            },
            {
                "value": 13706,
                "label": "Protection",
                "label_ar": "الحماية",
                "label_fr": "protection"
            },
            {
                "value": 13707,
                "label": "Protivin",
                "label_ar": "بروتيفين",
                "label_fr": "Protivin"
            },
            {
                "value": 13708,
                "label": "Providence",
                "label_ar": "بروفيدنس",
                "label_fr": "Providence"
            },
            {
                "value": 13709,
                "label": "Providence Forge",
                "label_ar": "بروفيدنس فورج",
                "label_fr": "Providence Forge"
            },
            {
                "value": 13710,
                "label": "Provincetown",
                "label_ar": "بروفينستاون",
                "label_fr": "Provincetown"
            },
            {
                "value": 13711,
                "label": "Provo",
                "label_ar": "بروفو",
                "label_fr": "Provo"
            },
            {
                "value": 13712,
                "label": "Prudenville",
                "label_ar": "برودينفيل",
                "label_fr": "Prudenville"
            },
            {
                "value": 13713,
                "label": "Prudhoe Bay",
                "label_ar": "خليج برودهو",
                "label_fr": "Prudhoe Bay"
            },
            {
                "value": 13714,
                "label": "Pryor",
                "label_ar": "بريور",
                "label_fr": "Pryor"
            },
            {
                "value": 13715,
                "label": "Pu'unene",
                "label_ar": "Pu'unene",
                "label_fr": "Pu'unene"
            },
            {
                "value": 13716,
                "label": "Pueblo",
                "label_ar": "بويبلو",
                "label_fr": "Pueblo"
            },
            {
                "value": 13717,
                "label": "Puerto Rico",
                "label_ar": "بورتوريكو",
                "label_fr": "Porto Rico"
            },
            {
                "value": 13718,
                "label": "Pukalani",
                "label_ar": "بوكالاني",
                "label_fr": "Pukalani"
            },
            {
                "value": 13719,
                "label": "Pukwana",
                "label_ar": "بوكوانا",
                "label_fr": "Pukwana"
            },
            {
                "value": 13720,
                "label": "Pulaski",
                "label_ar": "بولاسكي",
                "label_fr": "Pulaski"
            },
            {
                "value": 13721,
                "label": "Pullman",
                "label_ar": "بولمان",
                "label_fr": "Pullman"
            },
            {
                "value": 13722,
                "label": "Pungoteague",
                "label_ar": "بونغوتيج",
                "label_fr": "Pungoteague"
            },
            {
                "value": 13723,
                "label": "Punta Gorda",
                "label_ar": "بونتا جوردا",
                "label_fr": "Punta Gorda"
            },
            {
                "value": 13724,
                "label": "Punxsutawney",
                "label_ar": "Punxsutawney",
                "label_fr": "Punxsutawney"
            },
            {
                "value": 13725,
                "label": "Purcell",
                "label_ar": "بورسيل",
                "label_fr": "Purcell"
            },
            {
                "value": 13726,
                "label": "Purcellville",
                "label_ar": "بورسيلفيل",
                "label_fr": "Purcellville"
            },
            {
                "value": 13727,
                "label": "Purchase",
                "label_ar": "شراء",
                "label_fr": "achat"
            },
            {
                "value": 13728,
                "label": "Purdin",
                "label_ar": "بوردين",
                "label_fr": "Purdin"
            },
            {
                "value": 13729,
                "label": "Purdon",
                "label_ar": "بوردون",
                "label_fr": "Purdon"
            },
            {
                "value": 13730,
                "label": "Purdy",
                "label_ar": "نقي",
                "label_fr": "Purdy"
            },
            {
                "value": 13731,
                "label": "Purdys",
                "label_ar": "بورديس",
                "label_fr": "Purdys"
            },
            {
                "value": 13732,
                "label": "Purgitsville",
                "label_ar": "بورغيتسفيل",
                "label_fr": "Purgitsville"
            },
            {
                "value": 13733,
                "label": "Purlear",
                "label_ar": "نقي",
                "label_fr": "Purlear"
            },
            {
                "value": 13734,
                "label": "Purling",
                "label_ar": "Purling",
                "label_fr": "Purling"
            },
            {
                "value": 13735,
                "label": "Purvis",
                "label_ar": "بورفيس",
                "label_fr": "Purvis"
            },
            {
                "value": 13736,
                "label": "Puryear",
                "label_ar": "بيريير",
                "label_fr": "Puryear"
            },
            {
                "value": 13737,
                "label": "Put-in-Bay",
                "label_ar": "ضع في خليج",
                "label_fr": "Put-in-Bay"
            },
            {
                "value": 13738,
                "label": "Putnam",
                "label_ar": "بوتنام",
                "label_fr": "Putnam"
            },
            {
                "value": 13739,
                "label": "Putnam Station",
                "label_ar": "محطة بوتنام",
                "label_fr": "Gare de Putnam"
            },
            {
                "value": 13740,
                "label": "Putnam Valley",
                "label_ar": "وادي بوتنام",
                "label_fr": "Vallée de Putnam"
            },
            {
                "value": 13741,
                "label": "Putney",
                "label_ar": "بوتني",
                "label_fr": "Putney"
            },
            {
                "value": 13742,
                "label": "Puyallup",
                "label_ar": "بويالوب",
                "label_fr": "Puyallup"
            },
            {
                "value": 13743,
                "label": "Pylesville",
                "label_ar": "بيليسفيل",
                "label_fr": "Pylesville"
            },
            {
                "value": 13744,
                "label": "Quail",
                "label_ar": "طائر السمان",
                "label_fr": "Caille"
            },
            {
                "value": 13745,
                "label": "Quakake",
                "label_ar": "زلزال",
                "label_fr": "Quakake"
            },
            {
                "value": 13746,
                "label": "Quaker City",
                "label_ar": "مدينة كويكر",
                "label_fr": "Quaker City"
            },
            {
                "value": 13747,
                "label": "Quaker Hill",
                "label_ar": "كويكر هيل",
                "label_fr": "Quaker Hill"
            },
            {
                "value": 13748,
                "label": "Quakertown",
                "label_ar": "كويكرتاون",
                "label_fr": "Quakertown"
            },
            {
                "value": 13749,
                "label": "Quality",
                "label_ar": "جودة",
                "label_fr": "Qualité"
            },
            {
                "value": 13750,
                "label": "Quanah",
                "label_ar": "كوانا",
                "label_fr": "Quanah"
            },
            {
                "value": 13751,
                "label": "Quantico",
                "label_ar": "كوانتيكو",
                "label_fr": "Quantico"
            },
            {
                "value": 13752,
                "label": "Quapaw",
                "label_ar": "Quapaw",
                "label_fr": "Quapaw"
            },
            {
                "value": 13753,
                "label": "Quarryville",
                "label_ar": "كوارفيل",
                "label_fr": "Quarryville"
            },
            {
                "value": 13754,
                "label": "Quartzsite",
                "label_ar": "كوارتزسيت",
                "label_fr": "Quartzsite"
            },
            {
                "value": 13755,
                "label": "Quasqueton",
                "label_ar": "Quasqueton",
                "label_fr": "Quasqueton"
            },
            {
                "value": 13756,
                "label": "Quebeck",
                "label_ar": "كيبيك",
                "label_fr": "Québec"
            },
            {
                "value": 13757,
                "label": "Quechee",
                "label_ar": "كيتشي",
                "label_fr": "Quechee"
            },
            {
                "value": 13758,
                "label": "Queen Anne",
                "label_ar": "الملكة آن",
                "label_fr": "Reine Anne"
            },
            {
                "value": 13759,
                "label": "Queen City",
                "label_ar": "كوين سيتي",
                "label_fr": "Queen City"
            },
            {
                "value": 13760,
                "label": "Queen Creek",
                "label_ar": "كوين كريك",
                "label_fr": "Queen Creek"
            },
            {
                "value": 13761,
                "label": "Queens Village",
                "label_ar": "قرية كوينز",
                "label_fr": "Village du Queens"
            },
            {
                "value": 13762,
                "label": "Queensbury",
                "label_ar": "كوينزبري",
                "label_fr": "Queensbury"
            },
            {
                "value": 13763,
                "label": "Queenstown",
                "label_ar": "كوينزتاون",
                "label_fr": "Queenstown"
            },
            {
                "value": 13764,
                "label": "Quemado",
                "label_ar": "كيمادو",
                "label_fr": "Quemado"
            },
            {
                "value": 13765,
                "label": "Questa",
                "label_ar": "كويستا",
                "label_fr": "Questa"
            },
            {
                "value": 13766,
                "label": "Quilcene",
                "label_ar": "كويلسين",
                "label_fr": "Quilcène"
            },
            {
                "value": 13767,
                "label": "Quimby",
                "label_ar": "كويمبي",
                "label_fr": "Quimby"
            },
            {
                "value": 13768,
                "label": "Quincy",
                "label_ar": "كوينسي",
                "label_fr": "Quincy"
            },
            {
                "value": 13769,
                "label": "Quinebaug",
                "label_ar": "كوينباوج",
                "label_fr": "Quinebaug"
            },
            {
                "value": 13770,
                "label": "Quinlan",
                "label_ar": "كوينلان",
                "label_fr": "Quinlan"
            },
            {
                "value": 13771,
                "label": "Quinnesec",
                "label_ar": "كوينسيك",
                "label_fr": "Quinnesec"
            },
            {
                "value": 13772,
                "label": "Quinter",
                "label_ar": "كوينتر",
                "label_fr": "Quinter"
            },
            {
                "value": 13773,
                "label": "Quinton",
                "label_ar": "كوينتون",
                "label_fr": "Quinton"
            },
            {
                "value": 13774,
                "label": "Quinwood",
                "label_ar": "كوينوود",
                "label_fr": "Quinwood"
            },
            {
                "value": 13775,
                "label": "Quitman",
                "label_ar": "Quitman",
                "label_fr": "Quitman"
            },
            {
                "value": 13776,
                "label": "Qulin",
                "label_ar": "Qulin",
                "label_fr": "Qulin"
            },
            {
                "value": 13777,
                "label": "Quogue",
                "label_ar": "Quogue",
                "label_fr": "Quogue"
            },
            {
                "value": 13778,
                "label": "Raccoon",
                "label_ar": "الراكون",
                "label_fr": "Raton laveur"
            },
            {
                "value": 13779,
                "label": "Raceland",
                "label_ar": "راسلاند",
                "label_fr": "Raceland"
            },
            {
                "value": 13780,
                "label": "Racine",
                "label_ar": "راسين",
                "label_fr": "Racine"
            },
            {
                "value": 13781,
                "label": "Radcliff",
                "label_ar": "رادكليف",
                "label_fr": "Radcliff"
            },
            {
                "value": 13782,
                "label": "Radcliffe",
                "label_ar": "رادكليف",
                "label_fr": "Radcliffe"
            },
            {
                "value": 13783,
                "label": "Radford",
                "label_ar": "رادفورد",
                "label_fr": "Radford"
            },
            {
                "value": 13784,
                "label": "Radisson",
                "label_ar": "راديسون",
                "label_fr": "Radisson"
            },
            {
                "value": 13785,
                "label": "Radnor",
                "label_ar": "رادنور",
                "label_fr": "Radnor"
            },
            {
                "value": 13786,
                "label": "Raeford",
                "label_ar": "ريفورد",
                "label_fr": "Raeford"
            },
            {
                "value": 13787,
                "label": "Ragland",
                "label_ar": "راجلاند",
                "label_fr": "Ragland"
            },
            {
                "value": 13788,
                "label": "Rahway",
                "label_ar": "راهواي",
                "label_fr": "Rahway"
            },
            {
                "value": 13789,
                "label": "Rainbow",
                "label_ar": "قوس المطر",
                "label_fr": "arc en ciel"
            },
            {
                "value": 13790,
                "label": "Rainbow City",
                "label_ar": "مدينة قوس قزح",
                "label_fr": "Rainbow City"
            },
            {
                "value": 13791,
                "label": "Rainelle",
                "label_ar": "رينيلي",
                "label_fr": "Rainelle"
            },
            {
                "value": 13792,
                "label": "Rainier",
                "label_ar": "رينييه",
                "label_fr": "Rainier"
            },
            {
                "value": 13793,
                "label": "Rainsville",
                "label_ar": "رينزفيل",
                "label_fr": "Rainsville"
            },
            {
                "value": 13794,
                "label": "Raleigh",
                "label_ar": "رالي",
                "label_fr": "Raleigh"
            },
            {
                "value": 13795,
                "label": "Ralls",
                "label_ar": "رالز",
                "label_fr": "Ralls"
            },
            {
                "value": 13796,
                "label": "Ralph",
                "label_ar": "رالف",
                "label_fr": "Ralph"
            },
            {
                "value": 13797,
                "label": "Ralston",
                "label_ar": "رالستون",
                "label_fr": "Ralston"
            },
            {
                "value": 13798,
                "label": "Ramah",
                "label_ar": "الرامة",
                "label_fr": "Ramah"
            },
            {
                "value": 13799,
                "label": "Ramer",
                "label_ar": "رامير",
                "label_fr": "Ramer"
            },
            {
                "value": 13800,
                "label": "Ramey",
                "label_ar": "رامي",
                "label_fr": "Ramey"
            },
            {
                "value": 13801,
                "label": "Ramona",
                "label_ar": "رامونا",
                "label_fr": "Ramona"
            },
            {
                "value": 13802,
                "label": "Ramseur",
                "label_ar": "رامسير",
                "label_fr": "Ramseur"
            },
            {
                "value": 13803,
                "label": "Ramsey",
                "label_ar": "رامسي",
                "label_fr": "Ramsey"
            },
            {
                "value": 13804,
                "label": "Ranburne",
                "label_ar": "رانبورن",
                "label_fr": "Ranburne"
            },
            {
                "value": 13805,
                "label": "Ranchita",
                "label_ar": "رانشيتا",
                "label_fr": "Ranchita"
            },
            {
                "value": 13806,
                "label": "Rancho Cordova",
                "label_ar": "رانشو كوردوفا",
                "label_fr": "Rancho Cordova"
            },
            {
                "value": 13807,
                "label": "Rancho Cucamonga",
                "label_ar": "رانشو كوكامونجا",
                "label_fr": "Rancho Cucamonga"
            },
            {
                "value": 13808,
                "label": "Rancho Mirage",
                "label_ar": "رانشو ميراج",
                "label_fr": "Rancho Mirage"
            },
            {
                "value": 13809,
                "label": "Rancho Palos Verdes",
                "label_ar": "رانشو بالوس فيرديس",
                "label_fr": "Rancho Palos Verdes"
            },
            {
                "value": 13810,
                "label": "Rancho Santa Fe",
                "label_ar": "رانشو سانتا في",
                "label_fr": "Rancho Santa Fe"
            },
            {
                "value": 13811,
                "label": "Rancho Santa Margarita",
                "label_ar": "رانشو سانتا مارغريتا",
                "label_fr": "Rancho Santa Margarita"
            },
            {
                "value": 13812,
                "label": "Ranchos de Taos",
                "label_ar": "رانشوس دي تاوس",
                "label_fr": "Ranchos de Taos"
            },
            {
                "value": 13813,
                "label": "Rancocas",
                "label_ar": "رانكوكاس",
                "label_fr": "Rancocas"
            },
            {
                "value": 13814,
                "label": "Randall",
                "label_ar": "راندال",
                "label_fr": "Randall"
            },
            {
                "value": 13815,
                "label": "Randallstown",
                "label_ar": "راندالستاون",
                "label_fr": "Randallstown"
            },
            {
                "value": 13816,
                "label": "Randle",
                "label_ar": "راندل",
                "label_fr": "Randle"
            },
            {
                "value": 13817,
                "label": "Randleman",
                "label_ar": "راندلمان",
                "label_fr": "Randleman"
            },
            {
                "value": 13818,
                "label": "Randlett",
                "label_ar": "راندليت",
                "label_fr": "Randlett"
            },
            {
                "value": 13819,
                "label": "Randolph",
                "label_ar": "راندولف",
                "label_fr": "Randolph"
            },
            {
                "value": 13820,
                "label": "Randolph Center",
                "label_ar": "مركز راندولف",
                "label_fr": "Centre Randolph"
            },
            {
                "value": 13821,
                "label": "Randolph Township",
                "label_ar": "بلدة راندولف",
                "label_fr": "Canton de Randolph"
            },
            {
                "value": 13822,
                "label": "Random Lake",
                "label_ar": "بحيرة عشوائية",
                "label_fr": "Random Lake"
            },
            {
                "value": 13823,
                "label": "Rangely",
                "label_ar": "مرحى",
                "label_fr": "Rangely"
            },
            {
                "value": 13824,
                "label": "Ranger",
                "label_ar": "الحارس",
                "label_fr": "Ranger"
            },
            {
                "value": 13825,
                "label": "Rankin",
                "label_ar": "رانكين",
                "label_fr": "Rankin"
            },
            {
                "value": 13826,
                "label": "Ransom",
                "label_ar": "فدية",
                "label_fr": "Une rançon"
            },
            {
                "value": 13827,
                "label": "Ransom Canyon",
                "label_ar": "رانسوم كانيون",
                "label_fr": "Canyon de la rançon"
            },
            {
                "value": 13828,
                "label": "Ransomville",
                "label_ar": "رانسومفيل",
                "label_fr": "Ransomville"
            },
            {
                "value": 13829,
                "label": "Ranson",
                "label_ar": "رانسون",
                "label_fr": "Ranson"
            },
            {
                "value": 13830,
                "label": "Rantoul",
                "label_ar": "رنتول",
                "label_fr": "Rantoul"
            },
            {
                "value": 13831,
                "label": "Raphine",
                "label_ar": "رافين",
                "label_fr": "Raphine"
            },
            {
                "value": 13832,
                "label": "Rapid City",
                "label_ar": "رابيد سيتي",
                "label_fr": "Ville rapide"
            },
            {
                "value": 13833,
                "label": "Rapid River",
                "label_ar": "نهر رابيد",
                "label_fr": "Rivière rapide"
            },
            {
                "value": 13834,
                "label": "Rapidan",
                "label_ar": "رابيدان",
                "label_fr": "Rapidan"
            },
            {
                "value": 13835,
                "label": "Raquette Lake",
                "label_ar": "بحيرة راكيت",
                "label_fr": "Lac Raquette"
            },
            {
                "value": 13836,
                "label": "Raritan",
                "label_ar": "راريتان",
                "label_fr": "Raritain"
            },
            {
                "value": 13837,
                "label": "Rathdrum",
                "label_ar": "راثدروم",
                "label_fr": "Rathdrum"
            },
            {
                "value": 13838,
                "label": "Ratliff City",
                "label_ar": "مدينة راتليف",
                "label_fr": "Ratliff City"
            },
            {
                "value": 13839,
                "label": "Raton",
                "label_ar": "راتون",
                "label_fr": "Raton"
            },
            {
                "value": 13840,
                "label": "Rattan",
                "label_ar": "الروطان",
                "label_fr": "Rotin"
            },
            {
                "value": 13841,
                "label": "Ravalli",
                "label_ar": "رافالي",
                "label_fr": "Ravalli"
            },
            {
                "value": 13842,
                "label": "Raven",
                "label_ar": "غراب أسود",
                "label_fr": "Corbeau"
            },
            {
                "value": 13843,
                "label": "Ravena",
                "label_ar": "رافينا",
                "label_fr": "Ravena"
            },
            {
                "value": 13844,
                "label": "Ravencliff",
                "label_ar": "رافينكليف",
                "label_fr": "Ravencliff"
            },
            {
                "value": 13845,
                "label": "Ravenden",
                "label_ar": "رافيندين",
                "label_fr": "Ravenden"
            },
            {
                "value": 13846,
                "label": "Ravenel",
                "label_ar": "رافينيل",
                "label_fr": "Ravenel"
            },
            {
                "value": 13847,
                "label": "Ravenna",
                "label_ar": "رافينا",
                "label_fr": "Ravenne"
            },
            {
                "value": 13848,
                "label": "Ravensdale",
                "label_ar": "رافنسديل",
                "label_fr": "Ravensdale"
            },
            {
                "value": 13849,
                "label": "Ravenswood",
                "label_ar": "رافنسوود",
                "label_fr": "Ravenswood"
            },
            {
                "value": 13850,
                "label": "Ravenwood",
                "label_ar": "رافينوود",
                "label_fr": "Ravenwood"
            },
            {
                "value": 13851,
                "label": "Ravia",
                "label_ar": "رافيا",
                "label_fr": "Ravia"
            },
            {
                "value": 13852,
                "label": "Rawlings",
                "label_ar": "رولينغز",
                "label_fr": "Rawlings"
            },
            {
                "value": 13853,
                "label": "Rawlins",
                "label_ar": "رولينز",
                "label_fr": "Rawlins"
            },
            {
                "value": 13854,
                "label": "Rawson",
                "label_ar": "روسون",
                "label_fr": "Rawson"
            },
            {
                "value": 13855,
                "label": "Ray",
                "label_ar": "شعاع",
                "label_fr": "Rayon"
            },
            {
                "value": 13856,
                "label": "Ray Center",
                "label_ar": "مركز راي",
                "label_fr": "Centre des rayons"
            },
            {
                "value": 13857,
                "label": "Ray City",
                "label_ar": "راي سيتي",
                "label_fr": "Ray City"
            },
            {
                "value": 13858,
                "label": "Rayland",
                "label_ar": "رايلاند",
                "label_fr": "Rayland"
            },
            {
                "value": 13859,
                "label": "Raymond",
                "label_ar": "ريمون",
                "label_fr": "Raymond"
            },
            {
                "value": 13860,
                "label": "Raymondville",
                "label_ar": "ريموندفيل",
                "label_fr": "Raymondville"
            },
            {
                "value": 13861,
                "label": "Raymore",
                "label_ar": "رايمور",
                "label_fr": "Raymore"
            },
            {
                "value": 13862,
                "label": "Rayne",
                "label_ar": "راين",
                "label_fr": "Rayne"
            },
            {
                "value": 13863,
                "label": "Raynham",
                "label_ar": "راينهام",
                "label_fr": "Raynham"
            },
            {
                "value": 13864,
                "label": "Rayville",
                "label_ar": "رايفيل",
                "label_fr": "Rayville"
            },
            {
                "value": 13865,
                "label": "Raywick",
                "label_ar": "رايويك",
                "label_fr": "Raywick"
            },
            {
                "value": 13866,
                "label": "Readfield",
                "label_ar": "ريدفيلد",
                "label_fr": "Readfield"
            },
            {
                "value": 13867,
                "label": "Reading",
                "label_ar": "قراءة",
                "label_fr": "En train de lire"
            },
            {
                "value": 13868,
                "label": "Readlyn",
                "label_ar": "ريدلين",
                "label_fr": "Readlyn"
            },
            {
                "value": 13869,
                "label": "Readsboro",
                "label_ar": "ريدسبورو",
                "label_fr": "Readsboro"
            },
            {
                "value": 13870,
                "label": "Readyville",
                "label_ar": "ريديفيل",
                "label_fr": "Readyville"
            },
            {
                "value": 13871,
                "label": "Reamstown",
                "label_ar": "ريمستاون",
                "label_fr": "Reamstown"
            },
            {
                "value": 13872,
                "label": "Reardan",
                "label_ar": "ريردان",
                "label_fr": "Reardan"
            },
            {
                "value": 13873,
                "label": "Rebecca",
                "label_ar": "ريبيكا",
                "label_fr": "Rebecca"
            },
            {
                "value": 13874,
                "label": "Rebersburg",
                "label_ar": "ريبرسبورج",
                "label_fr": "Rebersburg"
            },
            {
                "value": 13875,
                "label": "Rector",
                "label_ar": "رئيس الجامعة",
                "label_fr": "Recteur"
            },
            {
                "value": 13876,
                "label": "Red Bank",
                "label_ar": "البنك الأحمر",
                "label_fr": "Banque rouge"
            },
            {
                "value": 13877,
                "label": "Red Banks",
                "label_ar": "البنوك الحمراء",
                "label_fr": "Banques rouges"
            },
            {
                "value": 13878,
                "label": "Red Bay",
                "label_ar": "ريد باي",
                "label_fr": "Red Bay"
            },
            {
                "value": 13879,
                "label": "Red Bluff",
                "label_ar": "خدعة حمراء",
                "label_fr": "Red Bluff"
            },
            {
                "value": 13880,
                "label": "Red Boiling Springs",
                "label_ar": "الينابيع الحمراء الغليان",
                "label_fr": "Sources bouillantes rouges"
            },
            {
                "value": 13881,
                "label": "Red Bud",
                "label_ar": "برعم أحمر",
                "label_fr": "Bourgeon rouge"
            },
            {
                "value": 13882,
                "label": "Red Cloud",
                "label_ar": "سحابة حمراء",
                "label_fr": "nuage rouge"
            },
            {
                "value": 13883,
                "label": "Red Creek",
                "label_ar": "ريد كريك",
                "label_fr": "Red Creek"
            },
            {
                "value": 13884,
                "label": "Red Hill",
                "label_ar": "التل الأحمر",
                "label_fr": "colline rouge"
            },
            {
                "value": 13885,
                "label": "Red Hook",
                "label_ar": "هوك الأحمر",
                "label_fr": "Crochet rouge"
            },
            {
                "value": 13886,
                "label": "Red House",
                "label_ar": "البيت الأحمر",
                "label_fr": "maison Rouge"
            },
            {
                "value": 13887,
                "label": "Red Jacket",
                "label_ar": "سترة حمراء",
                "label_fr": "Manteau rouge"
            },
            {
                "value": 13888,
                "label": "Red Lake Falls",
                "label_ar": "ريد ليك فولز",
                "label_fr": "Red Lake Falls"
            },
            {
                "value": 13889,
                "label": "Red Level",
                "label_ar": "المستوى الأحمر",
                "label_fr": "Niveau rouge"
            },
            {
                "value": 13890,
                "label": "Red Lion",
                "label_ar": "أسد أحمر",
                "label_fr": "Lion rouge"
            },
            {
                "value": 13891,
                "label": "Red Lodge",
                "label_ar": "ريد لودج",
                "label_fr": "Lodge rouge"
            },
            {
                "value": 13892,
                "label": "Red Oak",
                "label_ar": "البلوط الأحمر",
                "label_fr": "chêne rouge"
            },
            {
                "value": 13893,
                "label": "Red River",
                "label_ar": "نهر أحمر",
                "label_fr": "rivière Rouge"
            },
            {
                "value": 13894,
                "label": "Red Rock",
                "label_ar": "ريد روك",
                "label_fr": "Red Rock"
            },
            {
                "value": 13895,
                "label": "Red Springs",
                "label_ar": "الينابيع الحمراء",
                "label_fr": "Red Springs"
            },
            {
                "value": 13896,
                "label": "Red Wing",
                "label_ar": "الجناح الأحمر",
                "label_fr": "aile rouge"
            },
            {
                "value": 13897,
                "label": "Redan",
                "label_ar": "ريدان",
                "label_fr": "Redan"
            },
            {
                "value": 13898,
                "label": "Redby",
                "label_ar": "ريدبي",
                "label_fr": "Redby"
            },
            {
                "value": 13899,
                "label": "Reddick",
                "label_ar": "ريديك",
                "label_fr": "Reddick"
            },
            {
                "value": 13900,
                "label": "Redding",
                "label_ar": "ريدينغ",
                "label_fr": "Redding"
            },
            {
                "value": 13901,
                "label": "Redding Ridge",
                "label_ar": "ريدنج ريدج",
                "label_fr": "Redding Ridge"
            },
            {
                "value": 13902,
                "label": "Redfield",
                "label_ar": "ريدفيلد",
                "label_fr": "Redfield"
            },
            {
                "value": 13903,
                "label": "Redford",
                "label_ar": "ريدفورد",
                "label_fr": "Redford"
            },
            {
                "value": 13904,
                "label": "Redfox",
                "label_ar": "ثعلب احمر",
                "label_fr": "Renard rouge"
            },
            {
                "value": 13905,
                "label": "Redkey",
                "label_ar": "أحمر",
                "label_fr": "Redkey"
            },
            {
                "value": 13906,
                "label": "Redlands",
                "label_ar": "ريدلاندز",
                "label_fr": "Redlands"
            },
            {
                "value": 13907,
                "label": "Redmon",
                "label_ar": "ريدمون",
                "label_fr": "Redmon"
            },
            {
                "value": 13908,
                "label": "Redmond",
                "label_ar": "ريدموند",
                "label_fr": "Redmond"
            },
            {
                "value": 13909,
                "label": "Redondo Beach",
                "label_ar": "شاطئ ريدوندو",
                "label_fr": "Plage de Redondo"
            },
            {
                "value": 13910,
                "label": "Redwater",
                "label_ar": "ماء أحمر",
                "label_fr": "Eau Rouge"
            },
            {
                "value": 13911,
                "label": "Redway",
                "label_ar": "ريدواي",
                "label_fr": "Redway"
            },
            {
                "value": 13912,
                "label": "Redwood",
                "label_ar": "الخشب الأحمر",
                "label_fr": "Séquoia"
            },
            {
                "value": 13913,
                "label": "Redwood City",
                "label_ar": "ريدوود سيتي",
                "label_fr": "Redwood City"
            },
            {
                "value": 13914,
                "label": "Redwood Estates",
                "label_ar": "ريدوود إستيتس",
                "label_fr": "Domaines de séquoias"
            },
            {
                "value": 13915,
                "label": "Redwood Falls",
                "label_ar": "شلالات ريدوود",
                "label_fr": "Redwood Falls"
            },
            {
                "value": 13916,
                "label": "Redwood Valley",
                "label_ar": "وادي ريدوود",
                "label_fr": "Redwood Valley"
            },
            {
                "value": 13917,
                "label": "Reed City",
                "label_ar": "مدينة ريد",
                "label_fr": "Reed City"
            },
            {
                "value": 13918,
                "label": "Reed Point",
                "label_ar": "ريد بوينت",
                "label_fr": "Reed Point"
            },
            {
                "value": 13919,
                "label": "Reedley",
                "label_ar": "ريدلي",
                "label_fr": "Reedley"
            },
            {
                "value": 13920,
                "label": "Reeds",
                "label_ar": "القصب",
                "label_fr": "Roseaux"
            },
            {
                "value": 13921,
                "label": "Reeds Spring",
                "label_ar": "ريدز سبرينج",
                "label_fr": "Reeds Spring"
            },
            {
                "value": 13922,
                "label": "Reedsburg",
                "label_ar": "ريدسبورغ",
                "label_fr": "Reedsburg"
            },
            {
                "value": 13923,
                "label": "Reedsport",
                "label_ar": "ريدسبورت",
                "label_fr": "Reedsport"
            },
            {
                "value": 13924,
                "label": "Reedsville",
                "label_ar": "ريدزفيل",
                "label_fr": "Reedsville"
            },
            {
                "value": 13925,
                "label": "Reedville",
                "label_ar": "ريدفيل",
                "label_fr": "Reedville"
            },
            {
                "value": 13926,
                "label": "Reedy",
                "label_ar": "ريدي",
                "label_fr": "De roseaux"
            },
            {
                "value": 13927,
                "label": "Reelsville",
                "label_ar": "ريلسفيل",
                "label_fr": "Reelsville"
            },
            {
                "value": 13928,
                "label": "Reese",
                "label_ar": "ريس",
                "label_fr": "Reese"
            },
            {
                "value": 13929,
                "label": "Reeseville",
                "label_ar": "ريسفيل",
                "label_fr": "Reeseville"
            },
            {
                "value": 13930,
                "label": "Reevesville",
                "label_ar": "ريفزفيل",
                "label_fr": "Reevesville"
            },
            {
                "value": 13931,
                "label": "Reform",
                "label_ar": "اعادة تشكيل",
                "label_fr": "Réforme"
            },
            {
                "value": 13932,
                "label": "Refugio",
                "label_ar": "الملجأ",
                "label_fr": "Refugio"
            },
            {
                "value": 13933,
                "label": "Regent",
                "label_ar": "ريجنت",
                "label_fr": "Régent"
            },
            {
                "value": 13934,
                "label": "Register",
                "label_ar": "تسجيل",
                "label_fr": "S'inscrire"
            },
            {
                "value": 13935,
                "label": "Rego Park",
                "label_ar": "ريجو بارك",
                "label_fr": "Rego Park"
            },
            {
                "value": 13936,
                "label": "Rehoboth",
                "label_ar": "ريهوبوث",
                "label_fr": "Rehoboth"
            },
            {
                "value": 13937,
                "label": "Rehoboth Beach",
                "label_ar": "شاطئ ريهوبوث",
                "label_fr": "Plage de Rehoboth"
            },
            {
                "value": 13938,
                "label": "Reidsville",
                "label_ar": "ريدسفيل",
                "label_fr": "Reidsville"
            },
            {
                "value": 13939,
                "label": "Reidville",
                "label_ar": "ريدفيل",
                "label_fr": "Reidville"
            },
            {
                "value": 13940,
                "label": "Reinbeck",
                "label_ar": "رينبيك",
                "label_fr": "Reinbeck"
            },
            {
                "value": 13941,
                "label": "Reinholds",
                "label_ar": "رينهولدز",
                "label_fr": "Reinholds"
            },
            {
                "value": 13942,
                "label": "Reisterstown",
                "label_ar": "ريسترستاون",
                "label_fr": "Reisterstown"
            },
            {
                "value": 13943,
                "label": "Reklaw",
                "label_ar": "ريكلاو",
                "label_fr": "Reklaw"
            },
            {
                "value": 13944,
                "label": "Reliance",
                "label_ar": "الاعتماد",
                "label_fr": "Dépendance"
            },
            {
                "value": 13945,
                "label": "Rembert",
                "label_ar": "رمبرت",
                "label_fr": "Rembert"
            },
            {
                "value": 13946,
                "label": "Rembrandt",
                "label_ar": "رامبرانت",
                "label_fr": "Rembrandt"
            },
            {
                "value": 13947,
                "label": "Remer",
                "label_ar": "ريمير",
                "label_fr": "Remer"
            },
            {
                "value": 13948,
                "label": "Remington",
                "label_ar": "ريمنجتون",
                "label_fr": "Remington"
            },
            {
                "value": 13949,
                "label": "Remlap",
                "label_ar": "إعادة رسم",
                "label_fr": "Remlap"
            },
            {
                "value": 13950,
                "label": "Remote",
                "label_ar": "التحكم عن بعد",
                "label_fr": "Éloigné"
            },
            {
                "value": 13951,
                "label": "Remsen",
                "label_ar": "ريمسن",
                "label_fr": "Remsen"
            },
            {
                "value": 13952,
                "label": "Remsenburg",
                "label_ar": "ريمسينبورغ",
                "label_fr": "Remsenburg"
            },
            {
                "value": 13953,
                "label": "Remus",
                "label_ar": "ريموس",
                "label_fr": "Remus"
            },
            {
                "value": 13954,
                "label": "Renfrew",
                "label_ar": "رينفرو",
                "label_fr": "Renfrew"
            },
            {
                "value": 13955,
                "label": "Renner",
                "label_ar": "رينر",
                "label_fr": "Renner"
            },
            {
                "value": 13956,
                "label": "Reno",
                "label_ar": "رينو",
                "label_fr": "Reno"
            },
            {
                "value": 13957,
                "label": "Renovo",
                "label_ar": "رينوفو",
                "label_fr": "Renovo"
            },
            {
                "value": 13958,
                "label": "Rensselaer",
                "label_ar": "رينسيلار",
                "label_fr": "Rensselaer"
            },
            {
                "value": 13959,
                "label": "Rensselaer Falls",
                "label_ar": "شلالات رينسيلار",
                "label_fr": "Chutes de Rensselaer"
            },
            {
                "value": 13960,
                "label": "Renton",
                "label_ar": "رينتون",
                "label_fr": "Renton"
            },
            {
                "value": 13961,
                "label": "Rentz",
                "label_ar": "رينتز",
                "label_fr": "Rentz"
            },
            {
                "value": 13962,
                "label": "Renville",
                "label_ar": "رينفيل",
                "label_fr": "Renville"
            },
            {
                "value": 13963,
                "label": "Renwick",
                "label_ar": "رينويك",
                "label_fr": "Renwick"
            },
            {
                "value": 13964,
                "label": "Repton",
                "label_ar": "ريبتون",
                "label_fr": "Repton"
            },
            {
                "value": 13965,
                "label": "Republic",
                "label_ar": "جمهورية",
                "label_fr": "République"
            },
            {
                "value": 13966,
                "label": "Resaca",
                "label_ar": "ريساكا",
                "label_fr": "Resaca"
            },
            {
                "value": 13967,
                "label": "Reseda",
                "label_ar": "ريسيدا",
                "label_fr": "Réséda"
            },
            {
                "value": 13968,
                "label": "Reserve",
                "label_ar": "الاحتياطي",
                "label_fr": "réserve"
            },
            {
                "value": 13969,
                "label": "Reston",
                "label_ar": "راحة على",
                "label_fr": "Reposer sur"
            },
            {
                "value": 13970,
                "label": "Revere",
                "label_ar": "احترام",
                "label_fr": "Révérer"
            },
            {
                "value": 13971,
                "label": "Revillo",
                "label_ar": "Revillo",
                "label_fr": "Revillo"
            },
            {
                "value": 13972,
                "label": "Rex",
                "label_ar": "ريكس",
                "label_fr": "Rex"
            },
            {
                "value": 13973,
                "label": "Rexburg",
                "label_ar": "ريكسبورغ",
                "label_fr": "Rexburg"
            },
            {
                "value": 13974,
                "label": "Rexford",
                "label_ar": "ريكسفورد",
                "label_fr": "Rexford"
            },
            {
                "value": 13975,
                "label": "Rexmont",
                "label_ar": "ريكسمونت",
                "label_fr": "Rexmont"
            },
            {
                "value": 13976,
                "label": "Rexville",
                "label_ar": "ريكسفيل",
                "label_fr": "Rexville"
            },
            {
                "value": 13977,
                "label": "Reydell",
                "label_ar": "رايدل",
                "label_fr": "Reydell"
            },
            {
                "value": 13978,
                "label": "Reydon",
                "label_ar": "ريدون",
                "label_fr": "Reydon"
            },
            {
                "value": 13979,
                "label": "Reynolds",
                "label_ar": "رينولدز",
                "label_fr": "Reynolds"
            },
            {
                "value": 13980,
                "label": "Reynoldsburg",
                "label_ar": "رينولدسبورغ",
                "label_fr": "Reynoldsburg"
            },
            {
                "value": 13981,
                "label": "Reynoldsville",
                "label_ar": "رينولدزفيل",
                "label_fr": "Reynoldsville"
            },
            {
                "value": 13982,
                "label": "Rhame",
                "label_ar": "رهام",
                "label_fr": "Rhame"
            },
            {
                "value": 13983,
                "label": "Rheems",
                "label_ar": "ريمس",
                "label_fr": "Rheems"
            },
            {
                "value": 13984,
                "label": "Rhine",
                "label_ar": "الراين",
                "label_fr": "Rhin"
            },
            {
                "value": 13985,
                "label": "Rhinebeck",
                "label_ar": "راينبيك",
                "label_fr": "Rhinebeck"
            },
            {
                "value": 13986,
                "label": "Rhineland",
                "label_ar": "راينلاند",
                "label_fr": "Rhénanie"
            },
            {
                "value": 13987,
                "label": "Rhinelander",
                "label_ar": "راينلاندر",
                "label_fr": "Rhinelander"
            },
            {
                "value": 13988,
                "label": "Rhoadesville",
                "label_ar": "رودسفيل",
                "label_fr": "Rhoadesville"
            },
            {
                "value": 13989,
                "label": "Rhodelia",
                "label_ar": "روديليا",
                "label_fr": "Rhodelia"
            },
            {
                "value": 13990,
                "label": "Rhodell",
                "label_ar": "رودل",
                "label_fr": "Rhodell"
            },
            {
                "value": 13991,
                "label": "Rhodes",
                "label_ar": "رودس",
                "label_fr": "Rhodes"
            },
            {
                "value": 13992,
                "label": "Rhodesdale",
                "label_ar": "رودسديل",
                "label_fr": "Rhodesdale"
            },
            {
                "value": 13993,
                "label": "Rhodhiss",
                "label_ar": "روديس",
                "label_fr": "Rhodhiss"
            },
            {
                "value": 13994,
                "label": "Rhome",
                "label_ar": "روم",
                "label_fr": "Rhome"
            },
            {
                "value": 13995,
                "label": "Rialto",
                "label_ar": "ريالتو",
                "label_fr": "Rialto"
            },
            {
                "value": 13996,
                "label": "Rib Lake",
                "label_ar": "بحيرة الضلع",
                "label_fr": "Lac Rib"
            },
            {
                "value": 13997,
                "label": "Ribera",
                "label_ar": "ريبيرا",
                "label_fr": "Ribera"
            },
            {
                "value": 13998,
                "label": "Rice",
                "label_ar": "أرز",
                "label_fr": "Riz"
            },
            {
                "value": 13999,
                "label": "Rice Lake",
                "label_ar": "بحيرة رايس",
                "label_fr": "Lac Rice"
            },
            {
                "value": 14000,
                "label": "Rices Landing",
                "label_ar": "هبوط الأرز",
                "label_fr": "Débarquement de riz"
            },
            {
                "value": 14001,
                "label": "Riceville",
                "label_ar": "ريكفيل",
                "label_fr": "Riceville"
            },
            {
                "value": 14002,
                "label": "Rich Creek",
                "label_ar": "ريتش كريك",
                "label_fr": "Ruisseau Rich"
            },
            {
                "value": 14003,
                "label": "Rich Hill",
                "label_ar": "ريتش هيل",
                "label_fr": "Rich Hill"
            },
            {
                "value": 14004,
                "label": "Rich Square",
                "label_ar": "ريتش سكوير",
                "label_fr": "Carré riche"
            },
            {
                "value": 14005,
                "label": "Richards",
                "label_ar": "ريتشاردز",
                "label_fr": "Richards"
            },
            {
                "value": 14006,
                "label": "Richardson",
                "label_ar": "ريتشاردسون",
                "label_fr": "Richardson"
            },
            {
                "value": 14007,
                "label": "Richardton",
                "label_ar": "ريتشاردتون",
                "label_fr": "Richardton"
            },
            {
                "value": 14008,
                "label": "Richboro",
                "label_ar": "ريتشبورو",
                "label_fr": "Richboro"
            },
            {
                "value": 14009,
                "label": "Richburg",
                "label_ar": "ريتشبورج",
                "label_fr": "Richburg"
            },
            {
                "value": 14010,
                "label": "Richeyville",
                "label_ar": "ريتشيفيل",
                "label_fr": "Richeyville"
            },
            {
                "value": 14011,
                "label": "Richfield",
                "label_ar": "حقل غني",
                "label_fr": "Richfield"
            },
            {
                "value": 14012,
                "label": "Richfield Springs",
                "label_ar": "ريتشفيلد سبرينجز",
                "label_fr": "Richfield Springs"
            },
            {
                "value": 14013,
                "label": "Richford",
                "label_ar": "ريتشفورد",
                "label_fr": "Richford"
            },
            {
                "value": 14014,
                "label": "Richgrove",
                "label_ar": "ريتجروف",
                "label_fr": "Richgrove"
            },
            {
                "value": 14015,
                "label": "Richland",
                "label_ar": "ريتشلاند",
                "label_fr": "Richland"
            },
            {
                "value": 14016,
                "label": "Richland Center",
                "label_ar": "مركز ريتشلاند",
                "label_fr": "Centre Richland"
            },
            {
                "value": 14017,
                "label": "Richlands",
                "label_ar": "ريتشلاندز",
                "label_fr": "Richlands"
            },
            {
                "value": 14018,
                "label": "Richlandtown",
                "label_ar": "ريتشلاندتاون",
                "label_fr": "Richlandtown"
            },
            {
                "value": 14019,
                "label": "Richmond",
                "label_ar": "ريتشموند",
                "label_fr": "Richmond"
            },
            {
                "value": 14020,
                "label": "Richmond Hill",
                "label_ar": "ريتشموند هيل",
                "label_fr": "Richmond Hill"
            },
            {
                "value": 14021,
                "label": "Richton",
                "label_ar": "ريتشتون",
                "label_fr": "Richton"
            },
            {
                "value": 14022,
                "label": "Richton Park",
                "label_ar": "ريتشتون بارك",
                "label_fr": "Parc Richton"
            },
            {
                "value": 14023,
                "label": "Richvale",
                "label_ar": "ريتشفيل",
                "label_fr": "Richvale"
            },
            {
                "value": 14024,
                "label": "Richview",
                "label_ar": "ريتشفيو",
                "label_fr": "Richview"
            },
            {
                "value": 14025,
                "label": "Richwood",
                "label_ar": "ريتشوود",
                "label_fr": "Richwood"
            },
            {
                "value": 14026,
                "label": "Rickman",
                "label_ar": "ريكمان",
                "label_fr": "Rickman"
            },
            {
                "value": 14027,
                "label": "Rickreall",
                "label_ar": "ريكريل",
                "label_fr": "Rickreall"
            },
            {
                "value": 14028,
                "label": "Riddle",
                "label_ar": "لغز",
                "label_fr": "énigme"
            },
            {
                "value": 14029,
                "label": "Riddleton",
                "label_ar": "ريدلتون",
                "label_fr": "Riddleton"
            },
            {
                "value": 14030,
                "label": "Riderwood",
                "label_ar": "راديروود",
                "label_fr": "Riderwood"
            },
            {
                "value": 14031,
                "label": "Ridge",
                "label_ar": "ريدج",
                "label_fr": "crête"
            },
            {
                "value": 14032,
                "label": "Ridge Farm",
                "label_ar": "مزرعة ريدج",
                "label_fr": "Ridge Farm"
            },
            {
                "value": 14033,
                "label": "Ridge Spring",
                "label_ar": "ريدج سبرينج",
                "label_fr": "Ridge Spring"
            },
            {
                "value": 14034,
                "label": "Ridgecrest",
                "label_ar": "ريدجكريست",
                "label_fr": "Ridgecrest"
            },
            {
                "value": 14035,
                "label": "Ridgedale",
                "label_ar": "ريدجديل",
                "label_fr": "Ridgedale"
            },
            {
                "value": 14036,
                "label": "Ridgefield",
                "label_ar": "ريدجفيلد",
                "label_fr": "Ridgefield"
            },
            {
                "value": 14037,
                "label": "Ridgefield Park",
                "label_ar": "ريدجفيلد بارك",
                "label_fr": "Ridgefield Park"
            },
            {
                "value": 14038,
                "label": "Ridgeland",
                "label_ar": "ريدجلاند",
                "label_fr": "Ridgeland"
            },
            {
                "value": 14039,
                "label": "Ridgeley",
                "label_ar": "ريدجلي",
                "label_fr": "Ridgeley"
            },
            {
                "value": 14040,
                "label": "Ridgely",
                "label_ar": "بخيبة أمل",
                "label_fr": "Ridgely"
            },
            {
                "value": 14041,
                "label": "Ridgeview",
                "label_ar": "ريدجفيو",
                "label_fr": "Ridgeview"
            },
            {
                "value": 14042,
                "label": "Ridgeville",
                "label_ar": "ريدجفيل",
                "label_fr": "Ridgeville"
            },
            {
                "value": 14043,
                "label": "Ridgeway",
                "label_ar": "ريدجواي",
                "label_fr": "Ridgeway"
            },
            {
                "value": 14044,
                "label": "Ridgewood",
                "label_ar": "ريدجوود",
                "label_fr": "Ridgewood"
            },
            {
                "value": 14045,
                "label": "Ridgway",
                "label_ar": "ريدجواي",
                "label_fr": "Ridgway"
            },
            {
                "value": 14046,
                "label": "Ridley Park",
                "label_ar": "ريدلي بارك",
                "label_fr": "Ridley Park"
            },
            {
                "value": 14047,
                "label": "Ridott",
                "label_ar": "ريدوت",
                "label_fr": "Ridott"
            },
            {
                "value": 14048,
                "label": "Riegelsville",
                "label_ar": "ريجيلسفيل",
                "label_fr": "Riegelsville"
            },
            {
                "value": 14049,
                "label": "Riegelwood",
                "label_ar": "ريجيلوود",
                "label_fr": "Riegelwood"
            },
            {
                "value": 14050,
                "label": "Rienzi",
                "label_ar": "رينزي",
                "label_fr": "Rienzi"
            },
            {
                "value": 14051,
                "label": "Riesel",
                "label_ar": "ريزل",
                "label_fr": "Riesel"
            },
            {
                "value": 14052,
                "label": "Rifle",
                "label_ar": "بندقية",
                "label_fr": "Fusil"
            },
            {
                "value": 14053,
                "label": "Riga",
                "label_ar": "ريغا",
                "label_fr": "Riga"
            },
            {
                "value": 14054,
                "label": "Rigby",
                "label_ar": "ريجبي",
                "label_fr": "Rigby"
            },
            {
                "value": 14055,
                "label": "Riggins",
                "label_ar": "ريجينز",
                "label_fr": "Riggins"
            },
            {
                "value": 14056,
                "label": "Riley",
                "label_ar": "رايلي",
                "label_fr": "Riley"
            },
            {
                "value": 14057,
                "label": "Rillton",
                "label_ar": "ريلتون",
                "label_fr": "Rillton"
            },
            {
                "value": 14058,
                "label": "Rimersburg",
                "label_ar": "ريمرسبيرج",
                "label_fr": "Rimersburg"
            },
            {
                "value": 14059,
                "label": "Rimforest",
                "label_ar": "ريمفورست",
                "label_fr": "Rimforest"
            },
            {
                "value": 14060,
                "label": "Rimrock",
                "label_ar": "ريمروك",
                "label_fr": "Rimrock"
            },
            {
                "value": 14061,
                "label": "Rinard",
                "label_ar": "رينارد",
                "label_fr": "Rinard"
            },
            {
                "value": 14062,
                "label": "Rincon",
                "label_ar": "رينكون",
                "label_fr": "Rincon"
            },
            {
                "value": 14063,
                "label": "Rindge",
                "label_ar": "Rindge",
                "label_fr": "Rindge"
            },
            {
                "value": 14064,
                "label": "Riner",
                "label_ar": "راينر",
                "label_fr": "Riner"
            },
            {
                "value": 14065,
                "label": "Rineyville",
                "label_ar": "رينيفيل",
                "label_fr": "Rineyville"
            },
            {
                "value": 14066,
                "label": "Ringgold",
                "label_ar": "رينغولد",
                "label_fr": "Ringgold"
            },
            {
                "value": 14067,
                "label": "Ringle",
                "label_ar": "رينجل",
                "label_fr": "Ringle"
            },
            {
                "value": 14068,
                "label": "Ringling",
                "label_ar": "رينغلينغ",
                "label_fr": "Ringling"
            },
            {
                "value": 14069,
                "label": "Ringoes",
                "label_ar": "رينجو",
                "label_fr": "Ringoes"
            },
            {
                "value": 14070,
                "label": "Ringsted",
                "label_ar": "رينجستيد",
                "label_fr": "Ringsted"
            },
            {
                "value": 14071,
                "label": "Ringtown",
                "label_ar": "رينغتاون",
                "label_fr": "Ringtown"
            },
            {
                "value": 14072,
                "label": "Ringwood",
                "label_ar": "رينجوود",
                "label_fr": "Ringwood"
            },
            {
                "value": 14073,
                "label": "Rio",
                "label_ar": "ريو",
                "label_fr": "Rio"
            },
            {
                "value": 14074,
                "label": "Rio Dell",
                "label_ar": "ريو ديل",
                "label_fr": "Rio Dell"
            },
            {
                "value": 14075,
                "label": "Rio Grande",
                "label_ar": "ريو غراندي",
                "label_fr": "Rio Grande"
            },
            {
                "value": 14076,
                "label": "Rio Grande City",
                "label_ar": "مدينة ريو غراندي",
                "label_fr": "Ville de Rio Grande"
            },
            {
                "value": 14077,
                "label": "Rio Hondo",
                "label_ar": "ريو هوندو",
                "label_fr": "Rio Hondo"
            },
            {
                "value": 14078,
                "label": "Rio Linda",
                "label_ar": "ريو ليندا",
                "label_fr": "Rio Linda"
            },
            {
                "value": 14079,
                "label": "Rio Nido",
                "label_ar": "ريو نيدو",
                "label_fr": "Rio Nido"
            },
            {
                "value": 14080,
                "label": "Rio Rancho",
                "label_ar": "ريو رانشو",
                "label_fr": "Rio Rancho"
            },
            {
                "value": 14081,
                "label": "Rio Rico",
                "label_ar": "ريو ريكو",
                "label_fr": "Rio Rico"
            },
            {
                "value": 14082,
                "label": "Rio Verde",
                "label_ar": "ريو فيردي",
                "label_fr": "Rio Verde"
            },
            {
                "value": 14083,
                "label": "Rio Vista",
                "label_ar": "ريو فيستا",
                "label_fr": "Rio Vista"
            },
            {
                "value": 14084,
                "label": "Ripley",
                "label_ar": "ريبلي",
                "label_fr": "Ripley"
            },
            {
                "value": 14085,
                "label": "Ripon",
                "label_ar": "ريبون",
                "label_fr": "Ripon"
            },
            {
                "value": 14086,
                "label": "Rippey",
                "label_ar": "ريبي",
                "label_fr": "Rippey"
            },
            {
                "value": 14087,
                "label": "Ripplemead",
                "label_ar": "ريبليميد",
                "label_fr": "Ripplemead"
            },
            {
                "value": 14088,
                "label": "Ripton",
                "label_ar": "ريبتون",
                "label_fr": "Ripton"
            },
            {
                "value": 14089,
                "label": "Rising City",
                "label_ar": "المدينة الصاعدة",
                "label_fr": "Ville montante"
            },
            {
                "value": 14090,
                "label": "Rising Fawn",
                "label_ar": "ارتفاع الظبي",
                "label_fr": "Rising Fawn"
            },
            {
                "value": 14091,
                "label": "Rising Star",
                "label_ar": "نجم صاعد",
                "label_fr": "Étoile montante"
            },
            {
                "value": 14092,
                "label": "Rising Sun",
                "label_ar": "شمس مشرقة",
                "label_fr": "Soleil levant"
            },
            {
                "value": 14093,
                "label": "Risingsun",
                "label_ar": "شمس مشرقة",
                "label_fr": "Soleil levant"
            },
            {
                "value": 14094,
                "label": "Rison",
                "label_ar": "ريسون",
                "label_fr": "Rison"
            },
            {
                "value": 14095,
                "label": "Rittman",
                "label_ar": "ريتمان",
                "label_fr": "Rittman"
            },
            {
                "value": 14096,
                "label": "Ritzville",
                "label_ar": "ريتزفيل",
                "label_fr": "Ritzville"
            },
            {
                "value": 14097,
                "label": "Riva",
                "label_ar": "ريفا",
                "label_fr": "Riva"
            },
            {
                "value": 14098,
                "label": "River Edge",
                "label_ar": "ريفر إيدج",
                "label_fr": "Bord de la rivière"
            },
            {
                "value": 14099,
                "label": "River Falls",
                "label_ar": "شلالات النهر",
                "label_fr": "Chutes de la rivière"
            },
            {
                "value": 14100,
                "label": "River Forest",
                "label_ar": "غابة النهر",
                "label_fr": "Forêt fluviale"
            },
            {
                "value": 14101,
                "label": "River Grove",
                "label_ar": "ريفر جروف",
                "label_fr": "River Grove"
            },
            {
                "value": 14102,
                "label": "River Pines",
                "label_ar": "نهر الصنوبر",
                "label_fr": "River Pines"
            },
            {
                "value": 14103,
                "label": "River Rouge",
                "label_ar": "نهر روج",
                "label_fr": "Rivière Rouge"
            },
            {
                "value": 14104,
                "label": "Riverbank",
                "label_ar": "ضفه النهر",
                "label_fr": "Berge de rivière"
            },
            {
                "value": 14105,
                "label": "Riverdale",
                "label_ar": "ريفرديل",
                "label_fr": "Riverdale"
            },
            {
                "value": 14106,
                "label": "Riverdale Hills",
                "label_ar": "ريفرديل هيلز",
                "label_fr": "Riverdale Hills"
            },
            {
                "value": 14107,
                "label": "Riverhead",
                "label_ar": "ريفرهيد",
                "label_fr": "Riverhead"
            },
            {
                "value": 14108,
                "label": "Riverside",
                "label_ar": "ريفرسايد",
                "label_fr": "Riverside"
            },
            {
                "value": 14109,
                "label": "Riverside County",
                "label_ar": "مقاطعة ريفرسايد",
                "label_fr": "Comté de Riverside"
            },
            {
                "value": 14110,
                "label": "Riverton",
                "label_ar": "ريفرتون",
                "label_fr": "Riverton"
            },
            {
                "value": 14111,
                "label": "Riverview",
                "label_ar": "اطلالة النهر",
                "label_fr": "Vue de la rivière"
            },
            {
                "value": 14112,
                "label": "Rives",
                "label_ar": "رايف",
                "label_fr": "Rives"
            },
            {
                "value": 14113,
                "label": "Rives Junction",
                "label_ar": "مفرق رايفز",
                "label_fr": "Jonction de Rives"
            },
            {
                "value": 14114,
                "label": "Rivesville",
                "label_ar": "ريفيسفيل",
                "label_fr": "Rivesville"
            },
            {
                "value": 14115,
                "label": "Riviera",
                "label_ar": "ريفيرا",
                "label_fr": "Riviera"
            },
            {
                "value": 14116,
                "label": "Rixeyville",
                "label_ar": "ريكسفيل",
                "label_fr": "Rixeyville"
            },
            {
                "value": 14117,
                "label": "Roach",
                "label_ar": "صرصور",
                "label_fr": "Gardon"
            },
            {
                "value": 14118,
                "label": "Roachdale",
                "label_ar": "روتشديل",
                "label_fr": "Roachdale"
            },
            {
                "value": 14119,
                "label": "Roan Mountain",
                "label_ar": "جبل روان",
                "label_fr": "Roan Mountain"
            },
            {
                "value": 14120,
                "label": "Roann",
                "label_ar": "روان",
                "label_fr": "Roann"
            },
            {
                "value": 14121,
                "label": "Roanoke",
                "label_ar": "رونوك",
                "label_fr": "Roanoke"
            },
            {
                "value": 14122,
                "label": "Roanoke Rapids",
                "label_ar": "رونوك رابيدز",
                "label_fr": "Roanoke Rapids"
            },
            {
                "value": 14123,
                "label": "Roaring Branch",
                "label_ar": "فرع هدير",
                "label_fr": "Branche rugissante"
            },
            {
                "value": 14124,
                "label": "Roaring River",
                "label_ar": "هدير النهر",
                "label_fr": "Rivière rugissante"
            },
            {
                "value": 14125,
                "label": "Roaring Spring",
                "label_ar": "هدير الربيع",
                "label_fr": "Printemps rugissant"
            },
            {
                "value": 14126,
                "label": "Robards",
                "label_ar": "السرقات",
                "label_fr": "Robards"
            },
            {
                "value": 14127,
                "label": "Robbins",
                "label_ar": "روبينز",
                "label_fr": "Robbins"
            },
            {
                "value": 14128,
                "label": "Robbinsville",
                "label_ar": "روبنزفيل",
                "label_fr": "Robbinsville"
            },
            {
                "value": 14129,
                "label": "Robersonville",
                "label_ar": "روبرسونفيل",
                "label_fr": "Robersonville"
            },
            {
                "value": 14130,
                "label": "Robert",
                "label_ar": "روبرت",
                "label_fr": "Robert"
            },
            {
                "value": 14131,
                "label": "Robert Lee",
                "label_ar": "روبرت لي",
                "label_fr": "Robert Lee"
            },
            {
                "value": 14132,
                "label": "Roberta",
                "label_ar": "روبرتا",
                "label_fr": "Roberta"
            },
            {
                "value": 14133,
                "label": "Roberts",
                "label_ar": "روبرتس",
                "label_fr": "Roberts"
            },
            {
                "value": 14134,
                "label": "Robertsdale",
                "label_ar": "روبرتسديل",
                "label_fr": "Robertsdale"
            },
            {
                "value": 14135,
                "label": "Robertson",
                "label_ar": "روبرتسون",
                "label_fr": "Robertson"
            },
            {
                "value": 14136,
                "label": "Robertsville",
                "label_ar": "روبرتسفيل",
                "label_fr": "Robertsville"
            },
            {
                "value": 14137,
                "label": "Robesonia",
                "label_ar": "روبسونيا",
                "label_fr": "Robesonia"
            },
            {
                "value": 14138,
                "label": "Robins",
                "label_ar": "روبينز",
                "label_fr": "Merles"
            },
            {
                "value": 14139,
                "label": "Robinson",
                "label_ar": "روبنسون",
                "label_fr": "Robinson"
            },
            {
                "value": 14140,
                "label": "Robinson Creek",
                "label_ar": "روبنسون كريك",
                "label_fr": "Robinson Creek"
            },
            {
                "value": 14141,
                "label": "Robinsonville",
                "label_ar": "روبنسونفيل",
                "label_fr": "Robinsonville"
            },
            {
                "value": 14142,
                "label": "Robstown",
                "label_ar": "روبستاون",
                "label_fr": "Robstown"
            },
            {
                "value": 14143,
                "label": "Roby",
                "label_ar": "روبي",
                "label_fr": "Roby"
            },
            {
                "value": 14144,
                "label": "Roca",
                "label_ar": "روكا",
                "label_fr": "Roca"
            },
            {
                "value": 14145,
                "label": "Rochdale",
                "label_ar": "روتشديل",
                "label_fr": "Rochdale"
            },
            {
                "value": 14146,
                "label": "Rochelle",
                "label_ar": "روشيل",
                "label_fr": "Rochelle"
            },
            {
                "value": 14147,
                "label": "Rochelle Park",
                "label_ar": "روشيل بارك",
                "label_fr": "Parc de la Rochelle"
            },
            {
                "value": 14148,
                "label": "Rochester",
                "label_ar": "روتشستر",
                "label_fr": "Rochester"
            },
            {
                "value": 14149,
                "label": "Rociada",
                "label_ar": "Rociada",
                "label_fr": "Rociada"
            },
            {
                "value": 14150,
                "label": "Rock",
                "label_ar": "صخرة",
                "label_fr": "Roche"
            },
            {
                "value": 14151,
                "label": "Rock Creek",
                "label_ar": "روك كريك",
                "label_fr": "Rock Creek"
            },
            {
                "value": 14152,
                "label": "Rock Falls",
                "label_ar": "روك فولز",
                "label_fr": "Rock Falls"
            },
            {
                "value": 14153,
                "label": "Rock Hall",
                "label_ar": "روك هول",
                "label_fr": "Rock Hall"
            },
            {
                "value": 14154,
                "label": "Rock Hill",
                "label_ar": "روك هيل",
                "label_fr": "Rock Hill"
            },
            {
                "value": 14155,
                "label": "Rock Island",
                "label_ar": "جزيرة روك",
                "label_fr": "Rock Island"
            },
            {
                "value": 14156,
                "label": "Rock Port",
                "label_ar": "روك بورت",
                "label_fr": "Rock Port"
            },
            {
                "value": 14157,
                "label": "Rock Rapids",
                "label_ar": "روك رابيدز",
                "label_fr": "Rock Rapids"
            },
            {
                "value": 14158,
                "label": "Rock River",
                "label_ar": "نهر روك",
                "label_fr": "Rivière Rock"
            },
            {
                "value": 14159,
                "label": "Rock Spring",
                "label_ar": "روك سبرينج",
                "label_fr": "Rock Spring"
            },
            {
                "value": 14160,
                "label": "Rock Springs",
                "label_ar": "روك سبرينغز",
                "label_fr": "Rock Springs"
            },
            {
                "value": 14161,
                "label": "Rock Tavern",
                "label_ar": "روك تافيرن",
                "label_fr": "Rock Tavern"
            },
            {
                "value": 14162,
                "label": "Rock Valley",
                "label_ar": "روك فالي",
                "label_fr": "Rock Valley"
            },
            {
                "value": 14163,
                "label": "Rock View",
                "label_ar": "عرض الصخور",
                "label_fr": "Vue sur le rocher"
            },
            {
                "value": 14164,
                "label": "Rockaway",
                "label_ar": "روكاواي",
                "label_fr": "Rockaway"
            },
            {
                "value": 14165,
                "label": "Rockaway Beach",
                "label_ar": "شاطئ روكواي",
                "label_fr": "Plage Rockaway"
            },
            {
                "value": 14166,
                "label": "Rockaway Park",
                "label_ar": "روكواي بارك",
                "label_fr": "Parc Rockaway"
            },
            {
                "value": 14167,
                "label": "Rockbridge",
                "label_ar": "روكبريدج",
                "label_fr": "Rockbridge"
            },
            {
                "value": 14168,
                "label": "Rockdale",
                "label_ar": "روكديل",
                "label_fr": "Rockdale"
            },
            {
                "value": 14169,
                "label": "Rockfield",
                "label_ar": "روكفيلد",
                "label_fr": "Rockfield"
            },
            {
                "value": 14170,
                "label": "Rockford",
                "label_ar": "روكفورد",
                "label_fr": "Rockford"
            },
            {
                "value": 14171,
                "label": "Rockholds",
                "label_ar": "Rockholds",
                "label_fr": "Rockholds"
            },
            {
                "value": 14172,
                "label": "Rockhouse",
                "label_ar": "روكهاوس",
                "label_fr": "Rockhouse"
            },
            {
                "value": 14173,
                "label": "Rockingham",
                "label_ar": "روكينجهام",
                "label_fr": "Rockingham"
            },
            {
                "value": 14174,
                "label": "Rockland",
                "label_ar": "روكلاند",
                "label_fr": "Rockland"
            },
            {
                "value": 14175,
                "label": "Rockledge",
                "label_ar": "روكليدج",
                "label_fr": "Rockledge"
            },
            {
                "value": 14176,
                "label": "Rocklin",
                "label_ar": "روكلين",
                "label_fr": "Rocklin"
            },
            {
                "value": 14177,
                "label": "Rockmart",
                "label_ar": "روك مارت",
                "label_fr": "Rockmart"
            },
            {
                "value": 14178,
                "label": "Rockport",
                "label_ar": "روكبورت",
                "label_fr": "Rockport"
            },
            {
                "value": 14179,
                "label": "Rocksprings",
                "label_ar": "صخور الزنبركات",
                "label_fr": "Rocksprings"
            },
            {
                "value": 14180,
                "label": "Rockton",
                "label_ar": "روكتون",
                "label_fr": "Rockton"
            },
            {
                "value": 14181,
                "label": "Rockvale",
                "label_ar": "روكفيل",
                "label_fr": "Rockvale"
            },
            {
                "value": 14182,
                "label": "Rockville",
                "label_ar": "روكفيل",
                "label_fr": "Rockville"
            },
            {
                "value": 14183,
                "label": "Rockville Centre",
                "label_ar": "مركز روكفيل",
                "label_fr": "Centre Rockville"
            },
            {
                "value": 14184,
                "label": "Rockwall",
                "label_ar": "روكوول",
                "label_fr": "Mur en pierre"
            },
            {
                "value": 14185,
                "label": "Rockwell",
                "label_ar": "روكويل",
                "label_fr": "Rockwell"
            },
            {
                "value": 14186,
                "label": "Rockwell City",
                "label_ar": "روكويل سيتي",
                "label_fr": "Rockwell City"
            },
            {
                "value": 14187,
                "label": "Rockwood",
                "label_ar": "روكوود",
                "label_fr": "Rockwood"
            },
            {
                "value": 14188,
                "label": "Rocky",
                "label_ar": "صخري",
                "label_fr": "Rocheux"
            },
            {
                "value": 14189,
                "label": "Rocky Face",
                "label_ar": "وجه صخري",
                "label_fr": "Visage rocheux"
            },
            {
                "value": 14190,
                "label": "Rocky Ford",
                "label_ar": "روكي فورد",
                "label_fr": "Rocky Ford"
            },
            {
                "value": 14191,
                "label": "Rocky Gap",
                "label_ar": "روكي جاب",
                "label_fr": "Rocky Gap"
            },
            {
                "value": 14192,
                "label": "Rocky Hill",
                "label_ar": "تلة صخرية",
                "label_fr": "Rocky Hill"
            },
            {
                "value": 14193,
                "label": "Rocky Mount",
                "label_ar": "روكي ماونت",
                "label_fr": "Mont rocheux"
            },
            {
                "value": 14194,
                "label": "Rocky Point",
                "label_ar": "نقطة صخرية",
                "label_fr": "Point rocheux"
            },
            {
                "value": 14195,
                "label": "Rocky River",
                "label_ar": "نهر روكي",
                "label_fr": "Rocky River"
            },
            {
                "value": 14196,
                "label": "Rodanthe",
                "label_ar": "رودانثي",
                "label_fr": "Rodanthe"
            },
            {
                "value": 14197,
                "label": "Rodeo",
                "label_ar": "روديو",
                "label_fr": "Rodeo"
            },
            {
                "value": 14198,
                "label": "Roderfield",
                "label_ar": "رودرفيلد",
                "label_fr": "Roderfield"
            },
            {
                "value": 14199,
                "label": "Rodman",
                "label_ar": "رودمان",
                "label_fr": "Rodman"
            },
            {
                "value": 14200,
                "label": "Rodney",
                "label_ar": "رودني",
                "label_fr": "Rodney"
            },
            {
                "value": 14201,
                "label": "Roebling",
                "label_ar": "رويبلينج",
                "label_fr": "Roebling"
            },
            {
                "value": 14202,
                "label": "Roebuck",
                "label_ar": "روبوك",
                "label_fr": "Chevreuil"
            },
            {
                "value": 14203,
                "label": "Rogers",
                "label_ar": "روجرز",
                "label_fr": "Rogers"
            },
            {
                "value": 14204,
                "label": "Rogers City",
                "label_ar": "روجرز سيتي",
                "label_fr": "Rogers City"
            },
            {
                "value": 14205,
                "label": "Rogersville",
                "label_ar": "روجرسفيل",
                "label_fr": "Rogersville"
            },
            {
                "value": 14206,
                "label": "Roggen",
                "label_ar": "روجين",
                "label_fr": "Roggen"
            },
            {
                "value": 14207,
                "label": "Rogue River",
                "label_ar": "روغ ريفر",
                "label_fr": "Rivière Rogue"
            },
            {
                "value": 14208,
                "label": "Rohnert Park",
                "label_ar": "روهنرت بارك",
                "label_fr": "Parc Rohnert"
            },
            {
                "value": 14209,
                "label": "Rohrersville",
                "label_ar": "روهرسفيل",
                "label_fr": "Rohrersville"
            },
            {
                "value": 14210,
                "label": "Roland",
                "label_ar": "رولاند",
                "label_fr": "Roland"
            },
            {
                "value": 14211,
                "label": "Rolesville",
                "label_ar": "روليسفيل",
                "label_fr": "Rolesville"
            },
            {
                "value": 14212,
                "label": "Rolfe",
                "label_ar": "رولف",
                "label_fr": "Rolfe"
            },
            {
                "value": 14213,
                "label": "Roll",
                "label_ar": "تدحرج",
                "label_fr": "Rouleau"
            },
            {
                "value": 14214,
                "label": "Rolla",
                "label_ar": "رولا",
                "label_fr": "Rolla"
            },
            {
                "value": 14215,
                "label": "Rolling Fork",
                "label_ar": "رولينج فورك",
                "label_fr": "Fourche à roulettes"
            },
            {
                "value": 14216,
                "label": "Rolling Meadows",
                "label_ar": "رولينج ميدوز",
                "label_fr": "Rolling Meadows"
            },
            {
                "value": 14217,
                "label": "Rolling Prairie",
                "label_ar": "رولينج البراري",
                "label_fr": "Rolling Prairie"
            },
            {
                "value": 14218,
                "label": "Rollingbay",
                "label_ar": "رولينجباي",
                "label_fr": "Rollingbay"
            },
            {
                "value": 14219,
                "label": "Rollingstone",
                "label_ar": "صخره متدحرجه",
                "label_fr": "Pierre roulante"
            },
            {
                "value": 14220,
                "label": "Rollinsford",
                "label_ar": "رولينسفورد",
                "label_fr": "Rollinsford"
            },
            {
                "value": 14221,
                "label": "Roma",
                "label_ar": "روما",
                "label_fr": "Roma"
            },
            {
                "value": 14222,
                "label": "Rome",
                "label_ar": "روما",
                "label_fr": "Rome"
            },
            {
                "value": 14223,
                "label": "Rome City",
                "label_ar": "مدينة روما",
                "label_fr": "Ville de Rome"
            },
            {
                "value": 14224,
                "label": "Romeo",
                "label_ar": "روميو",
                "label_fr": "Roméo"
            },
            {
                "value": 14225,
                "label": "Romeoville",
                "label_ar": "روميوفيل",
                "label_fr": "Romeoville"
            },
            {
                "value": 14226,
                "label": "Romney",
                "label_ar": "رومني",
                "label_fr": "Romney"
            },
            {
                "value": 14227,
                "label": "Romulus",
                "label_ar": "رومولوس",
                "label_fr": "Romulus"
            },
            {
                "value": 14228,
                "label": "Ronald",
                "label_ar": "رونالد",
                "label_fr": "Ronald"
            },
            {
                "value": 14229,
                "label": "Ronan",
                "label_ar": "رونان",
                "label_fr": "Ronan"
            },
            {
                "value": 14230,
                "label": "Ronceverte",
                "label_ar": "رونسفيرتي",
                "label_fr": "Ronceverte"
            },
            {
                "value": 14231,
                "label": "Ronda",
                "label_ar": "روندا",
                "label_fr": "Ronda"
            },
            {
                "value": 14232,
                "label": "Ronkonkoma",
                "label_ar": "رونكونكوما",
                "label_fr": "Ronkonkoma"
            },
            {
                "value": 14233,
                "label": "Ronks",
                "label_ar": "رونكس",
                "label_fr": "Ronks"
            },
            {
                "value": 14234,
                "label": "Roodhouse",
                "label_ar": "رودهاوس",
                "label_fr": "Roodhouse"
            },
            {
                "value": 14235,
                "label": "Roosevelt",
                "label_ar": "روزفلت",
                "label_fr": "Roosevelt"
            },
            {
                "value": 14236,
                "label": "Rootstown",
                "label_ar": "روتستاون",
                "label_fr": "Rootstown"
            },
            {
                "value": 14237,
                "label": "Roper",
                "label_ar": "روبر",
                "label_fr": "Roper"
            },
            {
                "value": 14238,
                "label": "Ropesville",
                "label_ar": "روبسفيل",
                "label_fr": "Ropesville"
            },
            {
                "value": 14239,
                "label": "Rosalia",
                "label_ar": "روزاليا",
                "label_fr": "Rosalia"
            },
            {
                "value": 14240,
                "label": "Rosamond",
                "label_ar": "روزاموند",
                "label_fr": "Rosamond"
            },
            {
                "value": 14241,
                "label": "Rosburg",
                "label_ar": "روزبرغ",
                "label_fr": "Rosburg"
            },
            {
                "value": 14242,
                "label": "Roscoe",
                "label_ar": "روسكو",
                "label_fr": "Roscoe"
            },
            {
                "value": 14243,
                "label": "Roscommon",
                "label_ar": "روسكومون",
                "label_fr": "Roscommon"
            },
            {
                "value": 14244,
                "label": "Rose Bud",
                "label_ar": "روز برعم",
                "label_fr": "Rose Bud"
            },
            {
                "value": 14245,
                "label": "Rose City",
                "label_ar": "روز سيتي",
                "label_fr": "Rose City"
            },
            {
                "value": 14246,
                "label": "Rose Creek",
                "label_ar": "روز كريك",
                "label_fr": "Rose Creek"
            },
            {
                "value": 14247,
                "label": "Rose Hill",
                "label_ar": "روز هيل",
                "label_fr": "Rose Hill"
            },
            {
                "value": 14248,
                "label": "Roseau",
                "label_ar": "روسو",
                "label_fr": "Roseau"
            },
            {
                "value": 14249,
                "label": "Roseboro",
                "label_ar": "روزبورو",
                "label_fr": "Roseboro"
            },
            {
                "value": 14250,
                "label": "Rosebud",
                "label_ar": "زهرة الورد",
                "label_fr": "Rosebud"
            },
            {
                "value": 14251,
                "label": "Roseburg",
                "label_ar": "روزبورغ",
                "label_fr": "Roseburg"
            },
            {
                "value": 14252,
                "label": "Rosebush",
                "label_ar": "الورد",
                "label_fr": "Rosier"
            },
            {
                "value": 14253,
                "label": "Rosedale",
                "label_ar": "روزديل",
                "label_fr": "Rosedale"
            },
            {
                "value": 14254,
                "label": "Roseland",
                "label_ar": "روزلاند",
                "label_fr": "Roseland"
            },
            {
                "value": 14255,
                "label": "Roselle",
                "label_ar": "كركديه",
                "label_fr": "Roselle"
            },
            {
                "value": 14256,
                "label": "Roselle Park",
                "label_ar": "روزيل بارك",
                "label_fr": "Parc de la Roselle"
            },
            {
                "value": 14257,
                "label": "Rosemary Beach",
                "label_ar": "شاطئ روزماري",
                "label_fr": "Rosemary Beach"
            },
            {
                "value": 14258,
                "label": "Rosemead",
                "label_ar": "روزميد",
                "label_fr": "Rosemead"
            },
            {
                "value": 14259,
                "label": "Rosemont",
                "label_ar": "روزمونت",
                "label_fr": "Rosemont"
            },
            {
                "value": 14260,
                "label": "Rosemount",
                "label_ar": "روزماونت",
                "label_fr": "Rosemount"
            },
            {
                "value": 14261,
                "label": "Rosenberg",
                "label_ar": "روزنبرغ",
                "label_fr": "Rosenberg"
            },
            {
                "value": 14262,
                "label": "Rosendale",
                "label_ar": "روزندال",
                "label_fr": "Rosendale"
            },
            {
                "value": 14263,
                "label": "Rosenhayn",
                "label_ar": "روزنهاين",
                "label_fr": "Rosenhayn"
            },
            {
                "value": 14264,
                "label": "Rosepine",
                "label_ar": "روزبين",
                "label_fr": "Rosepine"
            },
            {
                "value": 14265,
                "label": "Roseville",
                "label_ar": "روزفيل",
                "label_fr": "Roseville"
            },
            {
                "value": 14266,
                "label": "Rosewood",
                "label_ar": "روزوود",
                "label_fr": "bois de rose"
            },
            {
                "value": 14267,
                "label": "Rosharon",
                "label_ar": "روشارون",
                "label_fr": "Rosharon"
            },
            {
                "value": 14268,
                "label": "Rosholt",
                "label_ar": "روشولت",
                "label_fr": "Rosholt"
            },
            {
                "value": 14269,
                "label": "Rosiclare",
                "label_ar": "روزيكلار",
                "label_fr": "Rosiclare"
            },
            {
                "value": 14270,
                "label": "Rosine",
                "label_ar": "روزين",
                "label_fr": "Rosine"
            },
            {
                "value": 14271,
                "label": "Roslindale",
                "label_ar": "روسليندال",
                "label_fr": "Roslindale"
            },
            {
                "value": 14272,
                "label": "Roslyn",
                "label_ar": "روزلين",
                "label_fr": "Roslyn"
            },
            {
                "value": 14273,
                "label": "Roslyn Heights",
                "label_ar": "مرتفعات روزلين",
                "label_fr": "Roslyn Heights"
            },
            {
                "value": 14274,
                "label": "Rosman",
                "label_ar": "روزمان",
                "label_fr": "Rosman"
            },
            {
                "value": 14275,
                "label": "Ross",
                "label_ar": "روس",
                "label_fr": "Ross"
            },
            {
                "value": 14276,
                "label": "Rosser",
                "label_ar": "روسر",
                "label_fr": "Rosser"
            },
            {
                "value": 14277,
                "label": "Rossford",
                "label_ar": "روسفورد",
                "label_fr": "Rossford"
            },
            {
                "value": 14278,
                "label": "Rossiter",
                "label_ar": "روسيتر",
                "label_fr": "Rossiter"
            },
            {
                "value": 14279,
                "label": "Rossville",
                "label_ar": "روسفيل",
                "label_fr": "Rossville"
            },
            {
                "value": 14280,
                "label": "Roswell",
                "label_ar": "روزويل",
                "label_fr": "Roswell"
            },
            {
                "value": 14281,
                "label": "Rotan",
                "label_ar": "روتان",
                "label_fr": "Rotan"
            },
            {
                "value": 14282,
                "label": "Rothbury",
                "label_ar": "روثبيري",
                "label_fr": "Rothbury"
            },
            {
                "value": 14283,
                "label": "Rothsay",
                "label_ar": "روثساي",
                "label_fr": "Rothsay"
            },
            {
                "value": 14284,
                "label": "Rothschild",
                "label_ar": "روتشيلد",
                "label_fr": "Rothschild"
            },
            {
                "value": 14285,
                "label": "Rotonda",
                "label_ar": "روتوندا",
                "label_fr": "Rotonda"
            },
            {
                "value": 14286,
                "label": "Rotterdam Junction",
                "label_ar": "روتردام جانكشن",
                "label_fr": "Jonction de Rotterdam"
            },
            {
                "value": 14287,
                "label": "Rougemont",
                "label_ar": "روجيمونت",
                "label_fr": "Rougemont"
            },
            {
                "value": 14288,
                "label": "Rough and Ready",
                "label_ar": "خشن وجاهز",
                "label_fr": "Rugueux et prêt"
            },
            {
                "value": 14289,
                "label": "Roulette",
                "label_ar": "روليت",
                "label_fr": "Roulette"
            },
            {
                "value": 14290,
                "label": "Round Hill",
                "label_ar": "جولة هيل",
                "label_fr": "Round Hill"
            },
            {
                "value": 14291,
                "label": "Round Lake",
                "label_ar": "بحيرة مستديرة",
                "label_fr": "Lac rond"
            },
            {
                "value": 14292,
                "label": "Round Mountain",
                "label_ar": "جولة الجبل",
                "label_fr": "Montagne ronde"
            },
            {
                "value": 14293,
                "label": "Round O",
                "label_ar": "جولة O",
                "label_fr": "Rond O"
            },
            {
                "value": 14294,
                "label": "Round Rock",
                "label_ar": "جولة روك",
                "label_fr": "Rocher rond"
            },
            {
                "value": 14295,
                "label": "Round Top",
                "label_ar": "قمة مستديرة",
                "label_fr": "Haut rond"
            },
            {
                "value": 14296,
                "label": "Roundhill",
                "label_ar": "راوندهيل",
                "label_fr": "Roundhill"
            },
            {
                "value": 14297,
                "label": "Roundup",
                "label_ar": "جمع الشمل",
                "label_fr": "Roundup"
            },
            {
                "value": 14298,
                "label": "Rouses Point",
                "label_ar": "روسيس بوينت",
                "label_fr": "Rouses Point"
            },
            {
                "value": 14299,
                "label": "Rousseau",
                "label_ar": "روسو",
                "label_fr": "Rousseau"
            },
            {
                "value": 14300,
                "label": "Rowan",
                "label_ar": "روان",
                "label_fr": "Sorbier des oiseleurs"
            },
            {
                "value": 14301,
                "label": "Rowe",
                "label_ar": "رو",
                "label_fr": "Rowe"
            },
            {
                "value": 14302,
                "label": "Rowena",
                "label_ar": "روينا",
                "label_fr": "Rowena"
            },
            {
                "value": 14303,
                "label": "Rowesville",
                "label_ar": "روزفيل",
                "label_fr": "Rowesville"
            },
            {
                "value": 14304,
                "label": "Rowland",
                "label_ar": "رولاند",
                "label_fr": "Rowland"
            },
            {
                "value": 14305,
                "label": "Rowland Heights",
                "label_ar": "مرتفعات رولاند",
                "label_fr": "Rowland Heights"
            },
            {
                "value": 14306,
                "label": "Rowlesburg",
                "label_ar": "روليسبورغ",
                "label_fr": "Rowlesburg"
            },
            {
                "value": 14307,
                "label": "Rowlett",
                "label_ar": "روليت",
                "label_fr": "Rowlett"
            },
            {
                "value": 14308,
                "label": "Rowley",
                "label_ar": "رولي",
                "label_fr": "Rowley"
            },
            {
                "value": 14309,
                "label": "Roxana",
                "label_ar": "روكسانا",
                "label_fr": "Roxana"
            },
            {
                "value": 14310,
                "label": "Roxboro",
                "label_ar": "روكسبورو",
                "label_fr": "Roxboro"
            },
            {
                "value": 14311,
                "label": "Roxbury",
                "label_ar": "روكسبري",
                "label_fr": "Roxbury"
            },
            {
                "value": 14312,
                "label": "Roxie",
                "label_ar": "روكسي",
                "label_fr": "Roxie"
            },
            {
                "value": 14313,
                "label": "Roy",
                "label_ar": "روي",
                "label_fr": "Roy"
            },
            {
                "value": 14314,
                "label": "Roy-Winifred Junction",
                "label_ar": "تقاطع روي وينيفريد",
                "label_fr": "Jonction Roy-Winifred"
            },
            {
                "value": 14315,
                "label": "Royal",
                "label_ar": "رويال",
                "label_fr": "Royal"
            },
            {
                "value": 14316,
                "label": "Royal Center",
                "label_ar": "رويال سنتر",
                "label_fr": "Centre royal"
            },
            {
                "value": 14317,
                "label": "Royal City",
                "label_ar": "المدينة الملكية",
                "label_fr": "ville royale"
            },
            {
                "value": 14318,
                "label": "Royal Oak",
                "label_ar": "رويال اوك",
                "label_fr": "Chêne royal"
            },
            {
                "value": 14319,
                "label": "Royal Palm Beach",
                "label_ar": "رويال بالم بيتش",
                "label_fr": "Royal Palm Beach"
            },
            {
                "value": 14320,
                "label": "Royalton",
                "label_ar": "رويالتون",
                "label_fr": "Royalton"
            },
            {
                "value": 14321,
                "label": "Royersford",
                "label_ar": "رويرسفورد",
                "label_fr": "Royersford"
            },
            {
                "value": 14322,
                "label": "Royse City",
                "label_ar": "رويس سيتي",
                "label_fr": "Royse City"
            },
            {
                "value": 14323,
                "label": "Royston",
                "label_ar": "رويستون",
                "label_fr": "Royston"
            },
            {
                "value": 14324,
                "label": "Rozet",
                "label_ar": "روزيت",
                "label_fr": "Rozet"
            },
            {
                "value": 14325,
                "label": "Rubicon",
                "label_ar": "روبيكون",
                "label_fr": "Rubicon"
            },
            {
                "value": 14326,
                "label": "Ruby",
                "label_ar": "روبي",
                "label_fr": "Rubis"
            },
            {
                "value": 14327,
                "label": "Ruckersville",
                "label_ar": "روكرزفيل",
                "label_fr": "Ruckersville"
            },
            {
                "value": 14328,
                "label": "Rudd",
                "label_ar": "أرد سمك نهري",
                "label_fr": "Rudd"
            },
            {
                "value": 14329,
                "label": "Rudolph",
                "label_ar": "رودولف",
                "label_fr": "Rudolph"
            },
            {
                "value": 14330,
                "label": "Rudyard",
                "label_ar": "روديارد",
                "label_fr": "Rudyard"
            },
            {
                "value": 14331,
                "label": "Ruffin",
                "label_ar": "روفين",
                "label_fr": "Ruffin"
            },
            {
                "value": 14332,
                "label": "Ruffs Dale",
                "label_ar": "روفس ديل",
                "label_fr": "Ruffs Dale"
            },
            {
                "value": 14333,
                "label": "Rugby",
                "label_ar": "كرة القدم الامريكية",
                "label_fr": "Le rugby"
            },
            {
                "value": 14334,
                "label": "Ruidoso",
                "label_ar": "رويدوسو",
                "label_fr": "Ruidoso"
            },
            {
                "value": 14335,
                "label": "Ruidoso Downs",
                "label_ar": "رويدوسو داونز",
                "label_fr": "Ruidoso Downs"
            },
            {
                "value": 14336,
                "label": "Rule",
                "label_ar": "قاعدة",
                "label_fr": "Règle"
            },
            {
                "value": 14337,
                "label": "Ruleville",
                "label_ar": "رولفيل",
                "label_fr": "Ruleville"
            },
            {
                "value": 14338,
                "label": "Rulo",
                "label_ar": "رولو",
                "label_fr": "Rulo"
            },
            {
                "value": 14339,
                "label": "Rumford",
                "label_ar": "رومفورد",
                "label_fr": "Rumford"
            },
            {
                "value": 14340,
                "label": "Rumney",
                "label_ar": "رومني",
                "label_fr": "Rumney"
            },
            {
                "value": 14341,
                "label": "Rumsey",
                "label_ar": "رمزي",
                "label_fr": "Rumsey"
            },
            {
                "value": 14342,
                "label": "Rumson",
                "label_ar": "رومسون",
                "label_fr": "Rumson"
            },
            {
                "value": 14343,
                "label": "Runge",
                "label_ar": "رانج",
                "label_fr": "Runge"
            },
            {
                "value": 14344,
                "label": "Runnells",
                "label_ar": "رونيلس",
                "label_fr": "Runnells"
            },
            {
                "value": 14345,
                "label": "Runnemede",
                "label_ar": "رانميد",
                "label_fr": "Runnemede"
            },
            {
                "value": 14346,
                "label": "Running Springs",
                "label_ar": "تشغيل الينابيع",
                "label_fr": "Ressorts de course"
            },
            {
                "value": 14347,
                "label": "Rupert",
                "label_ar": "روبرت",
                "label_fr": "Rupert"
            },
            {
                "value": 14348,
                "label": "Rural Hall",
                "label_ar": "القاعة الريفية",
                "label_fr": "Salle rurale"
            },
            {
                "value": 14349,
                "label": "Rural Retreat",
                "label_ar": "تراجع الريف",
                "label_fr": "Retraite rurale"
            },
            {
                "value": 14350,
                "label": "Rural Ridge",
                "label_ar": "ريدج الريفية",
                "label_fr": "Crête rurale"
            },
            {
                "value": 14351,
                "label": "Rural Valley",
                "label_ar": "الوادي الريفي",
                "label_fr": "Vallée rurale"
            },
            {
                "value": 14352,
                "label": "Rush",
                "label_ar": "سرعه",
                "label_fr": "se ruer"
            },
            {
                "value": 14353,
                "label": "Rush Center",
                "label_ar": "مركز راش",
                "label_fr": "Centre de pointe"
            },
            {
                "value": 14354,
                "label": "Rush City",
                "label_ar": "مدينة راش",
                "label_fr": "Rush City"
            },
            {
                "value": 14355,
                "label": "Rush Hill",
                "label_ar": "راش هيل",
                "label_fr": "Rush Hill"
            },
            {
                "value": 14356,
                "label": "Rush Valley",
                "label_ar": "راش فالي",
                "label_fr": "Rush Valley"
            },
            {
                "value": 14357,
                "label": "Rushford",
                "label_ar": "راشفورد",
                "label_fr": "Rushford"
            },
            {
                "value": 14358,
                "label": "Rushmore",
                "label_ar": "راشمور",
                "label_fr": "Rushmore"
            },
            {
                "value": 14359,
                "label": "Rushville",
                "label_ar": "راشفيل",
                "label_fr": "Rushville"
            },
            {
                "value": 14360,
                "label": "Rusk",
                "label_ar": "بقسماط",
                "label_fr": "Biscotte"
            },
            {
                "value": 14361,
                "label": "Ruskin",
                "label_ar": "روسكين",
                "label_fr": "Ruskin"
            },
            {
                "value": 14362,
                "label": "Russell",
                "label_ar": "راسل",
                "label_fr": "Russell"
            },
            {
                "value": 14363,
                "label": "Russell Springs",
                "label_ar": "راسل سبرينجز",
                "label_fr": "Russell Springs"
            },
            {
                "value": 14364,
                "label": "Russells Point",
                "label_ar": "راسل بوينت",
                "label_fr": "Pointe Russells"
            },
            {
                "value": 14365,
                "label": "Russellton",
                "label_ar": "راسلتون",
                "label_fr": "Russellton"
            },
            {
                "value": 14366,
                "label": "Russellville",
                "label_ar": "راسلفيل",
                "label_fr": "Russellville"
            },
            {
                "value": 14367,
                "label": "Russia",
                "label_ar": "روسيا",
                "label_fr": "Russie"
            },
            {
                "value": 14368,
                "label": "Russian Mission",
                "label_ar": "البعثة الروسية",
                "label_fr": "Mission russe"
            },
            {
                "value": 14369,
                "label": "Russiaville",
                "label_ar": "روسيافيل",
                "label_fr": "Russiaville"
            },
            {
                "value": 14370,
                "label": "Rustburg",
                "label_ar": "روستبورغ",
                "label_fr": "Rustburg"
            },
            {
                "value": 14371,
                "label": "Ruston",
                "label_ar": "رستون",
                "label_fr": "Ruston"
            },
            {
                "value": 14372,
                "label": "Ruth",
                "label_ar": "راعوث",
                "label_fr": "Ruth"
            },
            {
                "value": 14373,
                "label": "Ruther Glen",
                "label_ar": "روثر جلين",
                "label_fr": "Ruther Glen"
            },
            {
                "value": 14374,
                "label": "Rutherford",
                "label_ar": "رذرفورد",
                "label_fr": "Rutherford"
            },
            {
                "value": 14375,
                "label": "Rutherfordton",
                "label_ar": "رذرفوردتون",
                "label_fr": "Rutherfordton"
            },
            {
                "value": 14376,
                "label": "Ruthton",
                "label_ar": "روثتون",
                "label_fr": "Ruthton"
            },
            {
                "value": 14377,
                "label": "Ruthven",
                "label_ar": "روثفن",
                "label_fr": "Ruthven"
            },
            {
                "value": 14378,
                "label": "Rutland",
                "label_ar": "روتلاند",
                "label_fr": "Rutland"
            },
            {
                "value": 14379,
                "label": "Rutledge",
                "label_ar": "روتليدج",
                "label_fr": "Rutledge"
            },
            {
                "value": 14380,
                "label": "Ryan",
                "label_ar": "ريان",
                "label_fr": "Ryan"
            },
            {
                "value": 14381,
                "label": "Rydal",
                "label_ar": "ريدال",
                "label_fr": "Rydal"
            },
            {
                "value": 14382,
                "label": "Ryde",
                "label_ar": "رايد",
                "label_fr": "Ryde"
            },
            {
                "value": 14383,
                "label": "Rye",
                "label_ar": "الذرة",
                "label_fr": "Seigle"
            },
            {
                "value": 14384,
                "label": "Sabattus",
                "label_ar": "سباتوس",
                "label_fr": "Sabattus"
            },
            {
                "value": 14385,
                "label": "Sabetha",
                "label_ar": "صابثة",
                "label_fr": "Sabetha"
            },
            {
                "value": 14386,
                "label": "Sabillasville",
                "label_ar": "سابيلاسفيل",
                "label_fr": "Sabillasville"
            },
            {
                "value": 14387,
                "label": "Sabin",
                "label_ar": "سابين",
                "label_fr": "Sabin"
            },
            {
                "value": 14388,
                "label": "Sabina",
                "label_ar": "سابينا",
                "label_fr": "Sabina"
            },
            {
                "value": 14389,
                "label": "Sabinal",
                "label_ar": "سابينال",
                "label_fr": "Sabinal"
            },
            {
                "value": 14390,
                "label": "Sabot",
                "label_ar": "قبقاب",
                "label_fr": "Sabot"
            },
            {
                "value": 14391,
                "label": "Sabula",
                "label_ar": "سابولا",
                "label_fr": "Sabula"
            },
            {
                "value": 14392,
                "label": "Sac City",
                "label_ar": "ساك سيتي",
                "label_fr": "Sac City"
            },
            {
                "value": 14393,
                "label": "Sacaton",
                "label_ar": "ساكاتون",
                "label_fr": "Sacaton"
            },
            {
                "value": 14394,
                "label": "Sachse",
                "label_ar": "ساكس",
                "label_fr": "Sachse"
            },
            {
                "value": 14395,
                "label": "Sackets Harbor",
                "label_ar": "ميناء ساكتس",
                "label_fr": "Port de Sackets"
            },
            {
                "value": 14396,
                "label": "Saco",
                "label_ar": "ساكو",
                "label_fr": "Saco"
            },
            {
                "value": 14397,
                "label": "Sacramento",
                "label_ar": "سكرامنتو",
                "label_fr": "Sacramento"
            },
            {
                "value": 14398,
                "label": "Sacred Heart",
                "label_ar": "قلب مقدس",
                "label_fr": "Sacre Coeur"
            },
            {
                "value": 14399,
                "label": "Saddle Brook",
                "label_ar": "سادل بروك",
                "label_fr": "Saddle Brook"
            },
            {
                "value": 14400,
                "label": "Saddle River",
                "label_ar": "نهر السرج",
                "label_fr": "Rivière Saddle"
            },
            {
                "value": 14401,
                "label": "Sadieville",
                "label_ar": "ساديفيل",
                "label_fr": "Sadieville"
            },
            {
                "value": 14402,
                "label": "Sadler",
                "label_ar": "سادلر",
                "label_fr": "Sadler"
            },
            {
                "value": 14403,
                "label": "Saegertown",
                "label_ar": "سايجرتاون",
                "label_fr": "Saegertown"
            },
            {
                "value": 14404,
                "label": "Safety Harbor",
                "label_ar": "مرفأ السلامة",
                "label_fr": "Safety Harbor"
            },
            {
                "value": 14405,
                "label": "Safford",
                "label_ar": "سافورد",
                "label_fr": "Safford"
            },
            {
                "value": 14406,
                "label": "Sag Harbor",
                "label_ar": "ساغ هاربور",
                "label_fr": "Sag Harbor"
            },
            {
                "value": 14407,
                "label": "Sagamore",
                "label_ar": "ساجامور",
                "label_fr": "Sagamore"
            },
            {
                "value": 14408,
                "label": "Sagamore Beach",
                "label_ar": "شاطئ ساجامور",
                "label_fr": "Plage de Sagamore"
            },
            {
                "value": 14409,
                "label": "Sagaponack",
                "label_ar": "ساجابوناك",
                "label_fr": "Sagaponack"
            },
            {
                "value": 14410,
                "label": "Saginaw",
                "label_ar": "ساجينو",
                "label_fr": "Saginaw"
            },
            {
                "value": 14411,
                "label": "Sagle",
                "label_ar": "ساجل",
                "label_fr": "Sagle"
            },
            {
                "value": 14412,
                "label": "Saguache",
                "label_ar": "ساغواش",
                "label_fr": "Saguache"
            },
            {
                "value": 14413,
                "label": "Sahuarita",
                "label_ar": "ساهواريتا",
                "label_fr": "Sahuarita"
            },
            {
                "value": 14414,
                "label": "Sailor Springs",
                "label_ar": "بحار الينابيع",
                "label_fr": "Sailor Springs"
            },
            {
                "value": 14415,
                "label": "Saint Agatha",
                "label_ar": "القديسة أجاثا",
                "label_fr": "Sainte Agathe"
            },
            {
                "value": 14416,
                "label": "Saint Albans",
                "label_ar": "سانت ألبانز",
                "label_fr": "Saint Albans"
            },
            {
                "value": 14417,
                "label": "Saint Amant",
                "label_ar": "سانت امانت",
                "label_fr": "Saint Amant"
            },
            {
                "value": 14418,
                "label": "Saint Ann",
                "label_ar": "سانت آن",
                "label_fr": "Saint Ann"
            },
            {
                "value": 14419,
                "label": "Saint Anne",
                "label_ar": "القديسة آن",
                "label_fr": "Sainte Anne"
            },
            {
                "value": 14420,
                "label": "Saint Ansgar",
                "label_ar": "القديس أنسجار",
                "label_fr": "Saint Ansgar"
            },
            {
                "value": 14421,
                "label": "Saint Anthony",
                "label_ar": "القديس أنتوني",
                "label_fr": "Saint Antoine"
            },
            {
                "value": 14422,
                "label": "Saint Bonaventure",
                "label_ar": "القديس بونافنتورا",
                "label_fr": "Saint Bonaventure"
            },
            {
                "value": 14423,
                "label": "Saint Boniface",
                "label_ar": "القديس بونيفاس",
                "label_fr": "Saint Boniface"
            },
            {
                "value": 14424,
                "label": "Saint Bonifacius",
                "label_ar": "القديس بونيفاسيوس",
                "label_fr": "Saint Boniface"
            },
            {
                "value": 14425,
                "label": "Saint Catharine",
                "label_ar": "القديسة كاثرين",
                "label_fr": "Saint Catharine"
            },
            {
                "value": 14426,
                "label": "Saint Charles",
                "label_ar": "سانت تشارلز",
                "label_fr": "Saint Charles"
            },
            {
                "value": 14427,
                "label": "Saint Clair",
                "label_ar": "سانت كلير",
                "label_fr": "Saint Clair"
            },
            {
                "value": 14428,
                "label": "Saint Clair Shores",
                "label_ar": "سانت كلير شورز",
                "label_fr": "Saint Clair Shores"
            },
            {
                "value": 14429,
                "label": "Saint Clairsville",
                "label_ar": "سانت كليرسفيل",
                "label_fr": "Saint Clairsville"
            },
            {
                "value": 14430,
                "label": "Saint Cloud",
                "label_ar": "سانت كلاود",
                "label_fr": "Saint Cloud"
            },
            {
                "value": 14431,
                "label": "Saint Croix Falls",
                "label_ar": "شلالات سانت كروا",
                "label_fr": "Chutes de Sainte Croix"
            },
            {
                "value": 14432,
                "label": "Saint David",
                "label_ar": "القديس داود",
                "label_fr": "Saint David"
            },
            {
                "value": 14433,
                "label": "Saint Edward",
                "label_ar": "سانت ادوارد",
                "label_fr": "Saint Edouard"
            },
            {
                "value": 14434,
                "label": "Saint Elizabeth",
                "label_ar": "القديسة اليزابيث",
                "label_fr": "Sainte Élisabeth"
            },
            {
                "value": 14435,
                "label": "Saint Elmo",
                "label_ar": "سانت إلمو",
                "label_fr": "Saint Elme"
            },
            {
                "value": 14436,
                "label": "Saint Francis",
                "label_ar": "القديس فرنسيس",
                "label_fr": "Saint François"
            },
            {
                "value": 14437,
                "label": "Saint Francisville",
                "label_ar": "القديس فرانسيسفيل",
                "label_fr": "Saint Francisville"
            },
            {
                "value": 14438,
                "label": "Saint Gabriel",
                "label_ar": "القديس جبرائيل",
                "label_fr": "Saint Gabriel"
            },
            {
                "value": 14439,
                "label": "Saint George",
                "label_ar": "القديس جورج",
                "label_fr": "Saint George"
            },
            {
                "value": 14440,
                "label": "Saint Germain",
                "label_ar": "سان جيرمان",
                "label_fr": "Saint Germain"
            },
            {
                "value": 14441,
                "label": "Saint Hedwig",
                "label_ar": "القديس هيدويغ",
                "label_fr": "Saint Hedwige"
            },
            {
                "value": 14442,
                "label": "Saint Helen",
                "label_ar": "سانت هيلين",
                "label_fr": "Sainte Hélène"
            },
            {
                "value": 14443,
                "label": "Saint Helena",
                "label_ar": "سانت هيلانة",
                "label_fr": "Sainte-Hélène"
            },
            {
                "value": 14444,
                "label": "Saint Helens",
                "label_ar": "سانت هيلين",
                "label_fr": "Saint Helens"
            },
            {
                "value": 14445,
                "label": "Saint Henry",
                "label_ar": "القديس هنري",
                "label_fr": "Saint Henri"
            },
            {
                "value": 14446,
                "label": "Saint Hilaire",
                "label_ar": "سانت هيلير",
                "label_fr": "Saint Hilaire"
            },
            {
                "value": 14447,
                "label": "Saint Ignace",
                "label_ar": "القديس اجناس",
                "label_fr": "Saint Ignace"
            },
            {
                "value": 14448,
                "label": "Saint Ignatius",
                "label_ar": "القديس اغناطيوس",
                "label_fr": "Saint Ignace"
            },
            {
                "value": 14449,
                "label": "Saint Jacob",
                "label_ar": "القديس يعقوب",
                "label_fr": "Saint Jacob"
            },
            {
                "value": 14450,
                "label": "Saint James",
                "label_ar": "جيمس قديس",
                "label_fr": "Saint James"
            },
            {
                "value": 14451,
                "label": "Saint James City",
                "label_ar": "سانت جيمس سيتي",
                "label_fr": "Ville de Saint James"
            },
            {
                "value": 14452,
                "label": "Saint Joe",
                "label_ar": "سانت جو",
                "label_fr": "Saint Joe"
            },
            {
                "value": 14453,
                "label": "Saint John",
                "label_ar": "القديس يوحنا",
                "label_fr": "Saint Jean"
            },
            {
                "value": 14454,
                "label": "Saint Johns",
                "label_ar": "سانت جونز",
                "label_fr": "Saint Johns"
            },
            {
                "value": 14455,
                "label": "Saint Johnsbury",
                "label_ar": "سانت جونسبري",
                "label_fr": "Saint Johnsbury"
            },
            {
                "value": 14456,
                "label": "Saint Johnsville",
                "label_ar": "سانت جونزفيل",
                "label_fr": "Saint Johnsville"
            },
            {
                "value": 14457,
                "label": "Saint Joseph",
                "label_ar": "القديس يوسف",
                "label_fr": "Saint Joseph"
            },
            {
                "value": 14458,
                "label": "Saint Leo",
                "label_ar": "القديس ليو",
                "label_fr": "Saint Léon"
            },
            {
                "value": 14459,
                "label": "Saint Leonard",
                "label_ar": "سانت ليونارد",
                "label_fr": "Saint Léonard"
            },
            {
                "value": 14460,
                "label": "Saint Libory",
                "label_ar": "القديس ليبوري",
                "label_fr": "Saint Libory"
            },
            {
                "value": 14461,
                "label": "Saint Louis",
                "label_ar": "سانت لويس",
                "label_fr": "Saint Louis"
            },
            {
                "value": 14462,
                "label": "Saint Louis Park",
                "label_ar": "سانت لويس بارك",
                "label_fr": "Parc Saint Louis"
            },
            {
                "value": 14463,
                "label": "Saint Louisville",
                "label_ar": "سانت لويسفيل",
                "label_fr": "Saint Louisville"
            },
            {
                "value": 14464,
                "label": "Saint Marie",
                "label_ar": "سانت ماري",
                "label_fr": "Sainte Marie"
            },
            {
                "value": 14465,
                "label": "Saint Maries",
                "label_ar": "سانت ماريز",
                "label_fr": "Saint Maries"
            },
            {
                "value": 14466,
                "label": "Saint Marks",
                "label_ar": "القديس مرقس",
                "label_fr": "Saint Marc"
            },
            {
                "value": 14467,
                "label": "Saint Martin",
                "label_ar": "القديس مارتن",
                "label_fr": "Saint Martin"
            },
            {
                "value": 14468,
                "label": "Saint Martinville",
                "label_ar": "سانت مارتينفيل",
                "label_fr": "Saint Martinville"
            },
            {
                "value": 14469,
                "label": "Saint Mary",
                "label_ar": "القديس ماري",
                "label_fr": "Sainte Marie"
            },
            {
                "value": 14470,
                "label": "Saint Marys",
                "label_ar": "القديسة ماري",
                "label_fr": "Saint Marys"
            },
            {
                "value": 14471,
                "label": "Saint Matthews",
                "label_ar": "القديس ماثيوز",
                "label_fr": "Saint Matthieu"
            },
            {
                "value": 14472,
                "label": "Saint Meinrad",
                "label_ar": "القديس مينراد",
                "label_fr": "Saint Meinrad"
            },
            {
                "value": 14473,
                "label": "Saint Michael",
                "label_ar": "القديس مايكل",
                "label_fr": "Saint Michel"
            },
            {
                "value": 14474,
                "label": "Saint Michaels",
                "label_ar": "القديس مايكلز",
                "label_fr": "Saint Michaels"
            },
            {
                "value": 14475,
                "label": "Saint Nazianz",
                "label_ar": "سانت نازينز",
                "label_fr": "Saint Nazianz"
            },
            {
                "value": 14476,
                "label": "Saint Onge",
                "label_ar": "القديس اونج",
                "label_fr": "Saint Onge"
            },
            {
                "value": 14477,
                "label": "Saint Paris",
                "label_ar": "سانت باريس",
                "label_fr": "Saint Paris"
            },
            {
                "value": 14478,
                "label": "Saint Paul",
                "label_ar": "القديس بول",
                "label_fr": "Saint Paul"
            },
            {
                "value": 14479,
                "label": "Saint Paul Park",
                "label_ar": "سانت بول بارك",
                "label_fr": "Parc Saint Paul"
            },
            {
                "value": 14480,
                "label": "Saint Pauls",
                "label_ar": "القديس بولس",
                "label_fr": "Saint Paul"
            },
            {
                "value": 14481,
                "label": "Saint Peter",
                "label_ar": "القديس بطرس",
                "label_fr": "Saint Pierre"
            },
            {
                "value": 14482,
                "label": "Saint Peters",
                "label_ar": "سانت بيترز",
                "label_fr": "Saint Peters"
            },
            {
                "value": 14483,
                "label": "Saint Petersburg",
                "label_ar": "سان بطرسبورج",
                "label_fr": "Saint-Pétersbourg"
            },
            {
                "value": 14484,
                "label": "Saint Regis",
                "label_ar": "سانت ريجيس",
                "label_fr": "Saint Régis"
            },
            {
                "value": 14485,
                "label": "Saint Regis Falls",
                "label_ar": "شلالات سانت ريجيس",
                "label_fr": "Chutes Saint-Régis"
            },
            {
                "value": 14486,
                "label": "Saint Robert",
                "label_ar": "سانت روبرت",
                "label_fr": "Saint Robert"
            },
            {
                "value": 14487,
                "label": "Saint Simons Island",
                "label_ar": "جزيرة سانت سيمونز",
                "label_fr": "Île Saint Simons"
            },
            {
                "value": 14488,
                "label": "Saint Stephen",
                "label_ar": "القديس ستيفن",
                "label_fr": "Saint Etienne"
            },
            {
                "value": 14489,
                "label": "Saint Stephens",
                "label_ar": "القديس ستيفنس",
                "label_fr": "Saint Stephens"
            },
            {
                "value": 14490,
                "label": "Saint Thomas",
                "label_ar": "سانت توماس",
                "label_fr": "Saint Thomas"
            },
            {
                "value": 14491,
                "label": "Sainte Marie",
                "label_ar": "سانت ماري",
                "label_fr": "Sainte Marie"
            },
            {
                "value": 14492,
                "label": "Salado",
                "label_ar": "سالادو",
                "label_fr": "Salado"
            },
            {
                "value": 14493,
                "label": "Salamanca",
                "label_ar": "سالامانكا",
                "label_fr": "Salamanque"
            },
            {
                "value": 14494,
                "label": "Sale City",
                "label_ar": "مدينة البيع",
                "label_fr": "Sale Ville"
            },
            {
                "value": 14495,
                "label": "Sale Creek",
                "label_ar": "سلا كريك",
                "label_fr": "Sale Creek"
            },
            {
                "value": 14496,
                "label": "Salem",
                "label_ar": "سالم",
                "label_fr": "Salem"
            },
            {
                "value": 14497,
                "label": "Salemburg",
                "label_ar": "ساليمبورغ",
                "label_fr": "Salemburg"
            },
            {
                "value": 14498,
                "label": "Salesville",
                "label_ar": "ساليسفيل",
                "label_fr": "Salesville"
            },
            {
                "value": 14499,
                "label": "Salida",
                "label_ar": "ساليدا",
                "label_fr": "Salida"
            },
            {
                "value": 14500,
                "label": "Salina",
                "label_ar": "سالينا",
                "label_fr": "Saline"
            },
            {
                "value": 14501,
                "label": "Salinas",
                "label_ar": "ساليناس",
                "label_fr": "Salinas"
            },
            {
                "value": 14502,
                "label": "Saline",
                "label_ar": "محلول ملحي",
                "label_fr": "Saline"
            },
            {
                "value": 14503,
                "label": "Salineville",
                "label_ar": "سالينفيل",
                "label_fr": "Salineville"
            },
            {
                "value": 14504,
                "label": "Salisbury",
                "label_ar": "سالزبوري",
                "label_fr": "Salisbury"
            },
            {
                "value": 14505,
                "label": "Salisbury Mills",
                "label_ar": "مطاحن سالزبوري",
                "label_fr": "Moulins de Salisbury"
            },
            {
                "value": 14506,
                "label": "Salix",
                "label_ar": "ساليكس",
                "label_fr": "Salix"
            },
            {
                "value": 14507,
                "label": "Salkum",
                "label_ar": "سالكوم",
                "label_fr": "Salkum"
            },
            {
                "value": 14508,
                "label": "Sallis",
                "label_ar": "ساليس",
                "label_fr": "Sallis"
            },
            {
                "value": 14509,
                "label": "Sallisaw",
                "label_ar": "ساليسو",
                "label_fr": "Sallisaw"
            },
            {
                "value": 14510,
                "label": "Salmon",
                "label_ar": "سمك السالمون",
                "label_fr": "Saumon"
            },
            {
                "value": 14511,
                "label": "Salome",
                "label_ar": "سالومي",
                "label_fr": "Salomé"
            },
            {
                "value": 14512,
                "label": "Salt Lake City",
                "label_ar": "سولت لايك سيتي",
                "label_fr": "Salt Lake City"
            },
            {
                "value": 14513,
                "label": "Salt Lick",
                "label_ar": "ملح ليك",
                "label_fr": "Lécher le sel"
            },
            {
                "value": 14514,
                "label": "Salt Point",
                "label_ar": "سولت بوينت",
                "label_fr": "Salt Point"
            },
            {
                "value": 14515,
                "label": "Salt Rock",
                "label_ar": "سولت روك",
                "label_fr": "Salt Rock"
            },
            {
                "value": 14516,
                "label": "Salters",
                "label_ar": "مملحات",
                "label_fr": "Saleurs"
            },
            {
                "value": 14517,
                "label": "Saltillo",
                "label_ar": "سالتيلو",
                "label_fr": "Saltillo"
            },
            {
                "value": 14518,
                "label": "Salton City",
                "label_ar": "سالتون سيتي",
                "label_fr": "Salton City"
            },
            {
                "value": 14519,
                "label": "Saltsburg",
                "label_ar": "سالتسبورغ",
                "label_fr": "Saltsburg"
            },
            {
                "value": 14520,
                "label": "Saltville",
                "label_ar": "سالتفيل",
                "label_fr": "Saltville"
            },
            {
                "value": 14521,
                "label": "Saluda",
                "label_ar": "سالودا",
                "label_fr": "Saluda"
            },
            {
                "value": 14522,
                "label": "Salvisa",
                "label_ar": "سالفيسا",
                "label_fr": "Salvisa"
            },
            {
                "value": 14523,
                "label": "Salyer",
                "label_ar": "ساليير",
                "label_fr": "Salyer"
            },
            {
                "value": 14524,
                "label": "Salyersville",
                "label_ar": "ساليرسفيل",
                "label_fr": "Salyersville"
            },
            {
                "value": 14525,
                "label": "Sammamish",
                "label_ar": "ساماميش",
                "label_fr": "Sammamish"
            },
            {
                "value": 14526,
                "label": "Samoa",
                "label_ar": "ساموا",
                "label_fr": "Samoa"
            },
            {
                "value": 14527,
                "label": "Samson",
                "label_ar": "شمشون",
                "label_fr": "Samson"
            },
            {
                "value": 14528,
                "label": "San Andreas",
                "label_ar": "سان أندريس",
                "label_fr": "San Andreas"
            },
            {
                "value": 14529,
                "label": "San Angelo",
                "label_ar": "سان أنجيلو",
                "label_fr": "San Angelo"
            },
            {
                "value": 14530,
                "label": "San Anselmo",
                "label_ar": "سان أنسيلمو",
                "label_fr": "San Anselmo"
            },
            {
                "value": 14531,
                "label": "San Antonio",
                "label_ar": "سان أنطونيو",
                "label_fr": "San Antonio"
            },
            {
                "value": 14532,
                "label": "San Augustine",
                "label_ar": "سان أوغسطين",
                "label_fr": "San Augustin"
            },
            {
                "value": 14533,
                "label": "San Benito",
                "label_ar": "سان بينيتو",
                "label_fr": "San Benito"
            },
            {
                "value": 14534,
                "label": "San Bernardino",
                "label_ar": "سان برناردينو",
                "label_fr": "San Bernardino"
            },
            {
                "value": 14535,
                "label": "San Bernardino County",
                "label_ar": "مقاطعة سان برناردينو",
                "label_fr": "Comté de San Bernardino"
            },
            {
                "value": 14536,
                "label": "San Bruno",
                "label_ar": "سان برونو",
                "label_fr": "San Bruno"
            },
            {
                "value": 14537,
                "label": "San Carlos",
                "label_ar": "سان كارلوس",
                "label_fr": "San Carlos"
            },
            {
                "value": 14538,
                "label": "San Clemente",
                "label_ar": "سان كليمنتي",
                "label_fr": "San Clemente"
            },
            {
                "value": 14539,
                "label": "San Cristobal",
                "label_ar": "سان كريستوبال",
                "label_fr": "San Cristobal"
            },
            {
                "value": 14540,
                "label": "San Diego",
                "label_ar": "سان دييغو",
                "label_fr": "San Diego"
            },
            {
                "value": 14541,
                "label": "San Dimas",
                "label_ar": "سان ديماس",
                "label_fr": "San Dimas"
            },
            {
                "value": 14542,
                "label": "San Elizario",
                "label_ar": "سان إليزاريو",
                "label_fr": "San Elizario"
            },
            {
                "value": 14543,
                "label": "San Felipe",
                "label_ar": "سان فيليبي",
                "label_fr": "San Felipe"
            },
            {
                "value": 14544,
                "label": "San Fernando",
                "label_ar": "سان فرناندو",
                "label_fr": "San Fernando"
            },
            {
                "value": 14545,
                "label": "San Fidel",
                "label_ar": "سان فيدل",
                "label_fr": "San Fidel"
            },
            {
                "value": 14546,
                "label": "San Francisco",
                "label_ar": "سان فرانسيسكو",
                "label_fr": "San Francisco"
            },
            {
                "value": 14547,
                "label": "San Gabriel",
                "label_ar": "سان جبرائيل",
                "label_fr": "San Gabriel"
            },
            {
                "value": 14548,
                "label": "San Geronimo",
                "label_ar": "سان جيرونيمو",
                "label_fr": "San Geronimo"
            },
            {
                "value": 14549,
                "label": "San Gregorio",
                "label_ar": "سان جريجوريو",
                "label_fr": "San Gregorio"
            },
            {
                "value": 14550,
                "label": "San Jacinto",
                "label_ar": "سان جاسينتو",
                "label_fr": "San Jacinto"
            },
            {
                "value": 14551,
                "label": "San Joaquin",
                "label_ar": "سان جواكين",
                "label_fr": "San Joaquin"
            },
            {
                "value": 14552,
                "label": "San Jon",
                "label_ar": "سان جون",
                "label_fr": "San Jon"
            },
            {
                "value": 14553,
                "label": "San Jose",
                "label_ar": "سان خوسيه",
                "label_fr": "San José"
            },
            {
                "value": 14554,
                "label": "San Juan",
                "label_ar": "سان خوان",
                "label_fr": "San Juan"
            },
            {
                "value": 14555,
                "label": "San Juan Bautista",
                "label_ar": "سان خوان باوتيستا",
                "label_fr": "San Juan Bautista"
            },
            {
                "value": 14556,
                "label": "San Juan Capistrano",
                "label_ar": "سان خوان كابيسترانو",
                "label_fr": "San Juan Capistrano"
            },
            {
                "value": 14557,
                "label": "San Leandro",
                "label_ar": "سان لياندرو",
                "label_fr": "San Leandro"
            },
            {
                "value": 14558,
                "label": "San Lorenzo",
                "label_ar": "سان لورينزو",
                "label_fr": "San Lorenzo"
            },
            {
                "value": 14559,
                "label": "San Luis",
                "label_ar": "سان لويس",
                "label_fr": "San Luis"
            },
            {
                "value": 14560,
                "label": "San Luis Obispo",
                "label_ar": "سان لويس أوبيسبو",
                "label_fr": "San Luis Obispo"
            },
            {
                "value": 14561,
                "label": "San Luis Rey Heights",
                "label_ar": "سان لويس ري هايتس",
                "label_fr": "San Luis Rey Heights"
            },
            {
                "value": 14562,
                "label": "San Manuel",
                "label_ar": "سان مانويل",
                "label_fr": "San Manuel"
            },
            {
                "value": 14563,
                "label": "San Marcos",
                "label_ar": "سان ماركوس",
                "label_fr": "San Marcos"
            },
            {
                "value": 14564,
                "label": "San Marino",
                "label_ar": "سان مارينو",
                "label_fr": "Saint Marin"
            },
            {
                "value": 14565,
                "label": "San Martin",
                "label_ar": "سان مارتن",
                "label_fr": "San Martin"
            },
            {
                "value": 14566,
                "label": "San Mateo",
                "label_ar": "سان ماتيو",
                "label_fr": "San Mateo"
            },
            {
                "value": 14567,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 14568,
                "label": "San Pablo",
                "label_ar": "سان بابلو",
                "label_fr": "San Pablo"
            },
            {
                "value": 14569,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 14570,
                "label": "San Pierre",
                "label_ar": "سان بيير",
                "label_fr": "San Pierre"
            },
            {
                "value": 14571,
                "label": "San Rafael",
                "label_ar": "سان رافائيل",
                "label_fr": "San Rafael"
            },
            {
                "value": 14572,
                "label": "San Ramon",
                "label_ar": "سان رامون",
                "label_fr": "San Ramon"
            },
            {
                "value": 14573,
                "label": "San Saba",
                "label_ar": "سان سابا",
                "label_fr": "San Saba"
            },
            {
                "value": 14574,
                "label": "San Simeon",
                "label_ar": "سان سيميون",
                "label_fr": "San Simeon"
            },
            {
                "value": 14575,
                "label": "San Simon",
                "label_ar": "سان سيمون",
                "label_fr": "San Simon"
            },
            {
                "value": 14576,
                "label": "San Ysidro",
                "label_ar": "سان يسيدرو",
                "label_fr": "San Ysidro"
            },
            {
                "value": 14577,
                "label": "Sanborn",
                "label_ar": "سانبورن",
                "label_fr": "Sanborn"
            },
            {
                "value": 14578,
                "label": "Sanbornton",
                "label_ar": "سانبورنتون",
                "label_fr": "Sanbornton"
            },
            {
                "value": 14579,
                "label": "Sanbornville",
                "label_ar": "سانبورنفيل",
                "label_fr": "Sanbornville"
            },
            {
                "value": 14580,
                "label": "Sand Coulee",
                "label_ar": "ساند كولي",
                "label_fr": "Coulée de sable"
            },
            {
                "value": 14581,
                "label": "Sand Creek",
                "label_ar": "ساند كريك",
                "label_fr": "Sand Creek"
            },
            {
                "value": 14582,
                "label": "Sand Fork",
                "label_ar": "شوكة الرمل",
                "label_fr": "Fourche à sable"
            },
            {
                "value": 14583,
                "label": "Sand Lake",
                "label_ar": "بحيرة الرمال",
                "label_fr": "Sand Lake"
            },
            {
                "value": 14584,
                "label": "Sand Point",
                "label_ar": "ساند بوينت",
                "label_fr": "Sand Point"
            },
            {
                "value": 14585,
                "label": "Sand Springs",
                "label_ar": "ينابيع الرمال",
                "label_fr": "Sand Springs"
            },
            {
                "value": 14586,
                "label": "Sandborn",
                "label_ar": "ساندبورن",
                "label_fr": "Sandborn"
            },
            {
                "value": 14587,
                "label": "Sanders",
                "label_ar": "ساندرز",
                "label_fr": "Ponceuses"
            },
            {
                "value": 14588,
                "label": "Sanderson",
                "label_ar": "ساندرسون",
                "label_fr": "Sanderson"
            },
            {
                "value": 14589,
                "label": "Sandersville",
                "label_ar": "ساندرزفيل",
                "label_fr": "Sandersville"
            },
            {
                "value": 14590,
                "label": "Sandia",
                "label_ar": "سانديا",
                "label_fr": "Sandia"
            },
            {
                "value": 14591,
                "label": "Sandia Park",
                "label_ar": "حديقة سانديا",
                "label_fr": "Parc Sandia"
            },
            {
                "value": 14592,
                "label": "Sandoval",
                "label_ar": "ساندوفال",
                "label_fr": "Sandoval"
            },
            {
                "value": 14593,
                "label": "Sandoval County",
                "label_ar": "مقاطعة ساندوفال",
                "label_fr": "Comté de Sandoval"
            },
            {
                "value": 14594,
                "label": "Sandown",
                "label_ar": "سانداون",
                "label_fr": "Sandown"
            },
            {
                "value": 14595,
                "label": "Sandpoint",
                "label_ar": "ساندبوينت",
                "label_fr": "Sandpoint"
            },
            {
                "value": 14596,
                "label": "Sandston",
                "label_ar": "ساندستون",
                "label_fr": "Sandston"
            },
            {
                "value": 14597,
                "label": "Sandstone",
                "label_ar": "الحجر الرملي",
                "label_fr": "Grès"
            },
            {
                "value": 14598,
                "label": "Sandusky",
                "label_ar": "ساندوسكي",
                "label_fr": "Sandusky"
            },
            {
                "value": 14599,
                "label": "Sandwich",
                "label_ar": "ساندويتش",
                "label_fr": "Sandwich"
            },
            {
                "value": 14600,
                "label": "Sandy",
                "label_ar": "ساندي",
                "label_fr": "Sablonneux"
            },
            {
                "value": 14601,
                "label": "Sandy City",
                "label_ar": "مدينة ساندي",
                "label_fr": "Sandy City"
            },
            {
                "value": 14602,
                "label": "Sandy Creek",
                "label_ar": "ساندي كريك",
                "label_fr": "Sandy Creek"
            },
            {
                "value": 14603,
                "label": "Sandy Hook",
                "label_ar": "ربط الرملي",
                "label_fr": "Crochet de sable"
            },
            {
                "value": 14604,
                "label": "Sandy Lake",
                "label_ar": "بحيرة ساندي",
                "label_fr": "Sandy Lake"
            },
            {
                "value": 14605,
                "label": "Sandy Ridge",
                "label_ar": "ساندي ريدج",
                "label_fr": "Sandy Ridge"
            },
            {
                "value": 14606,
                "label": "Sandy Spring",
                "label_ar": "ساندي سبرينج",
                "label_fr": "Printemps de sable"
            },
            {
                "value": 14607,
                "label": "Sandyville",
                "label_ar": "سانديفيل",
                "label_fr": "Sandyville"
            },
            {
                "value": 14608,
                "label": "Sanford",
                "label_ar": "سانفورد",
                "label_fr": "Sanford"
            },
            {
                "value": 14609,
                "label": "Sanger",
                "label_ar": "سانجر",
                "label_fr": "Sanger"
            },
            {
                "value": 14610,
                "label": "Sangerville",
                "label_ar": "سانجرفيل",
                "label_fr": "Sangerville"
            },
            {
                "value": 14611,
                "label": "Sanibel",
                "label_ar": "سانيبل",
                "label_fr": "Sanibel"
            },
            {
                "value": 14612,
                "label": "Santa Ana",
                "label_ar": "سانتا آنا",
                "label_fr": "Santa Ana"
            },
            {
                "value": 14613,
                "label": "Santa Ana Heights",
                "label_ar": "مرتفعات سانتا آنا",
                "label_fr": "Santa Ana Heights"
            },
            {
                "value": 14614,
                "label": "Santa Anna",
                "label_ar": "سانتا آنا",
                "label_fr": "Santa Anna"
            },
            {
                "value": 14615,
                "label": "Santa Barbara",
                "label_ar": "سانتا باربارا",
                "label_fr": "Santa Barbara"
            },
            {
                "value": 14616,
                "label": "Santa Clara",
                "label_ar": "سانتا كلارا",
                "label_fr": "Santa Clara"
            },
            {
                "value": 14617,
                "label": "Santa Clarita",
                "label_ar": "سانتا كلاريتا",
                "label_fr": "Santa Clarita"
            },
            {
                "value": 14618,
                "label": "Santa Claus",
                "label_ar": "بابا نويل",
                "label_fr": "père Noël"
            },
            {
                "value": 14619,
                "label": "Santa Cruz",
                "label_ar": "سانتا كروز",
                "label_fr": "Santa Cruz"
            },
            {
                "value": 14620,
                "label": "Santa Fe",
                "label_ar": "سانتا في",
                "label_fr": "Santa Fe"
            },
            {
                "value": 14621,
                "label": "Santa Fe Springs",
                "label_ar": "سانتا في سبرينغز",
                "label_fr": "Sources de Santa Fe"
            },
            {
                "value": 14622,
                "label": "Santa Margarita",
                "label_ar": "سانتا مارغريتا",
                "label_fr": "Santa Margarita"
            },
            {
                "value": 14623,
                "label": "Santa Maria",
                "label_ar": "سانتا ماريا",
                "label_fr": "Sainte Marie"
            },
            {
                "value": 14624,
                "label": "Santa Monica",
                "label_ar": "سانتا مونيكا",
                "label_fr": "Santa Monica"
            },
            {
                "value": 14625,
                "label": "Santa Paula",
                "label_ar": "سانتا بولا",
                "label_fr": "Santa Paula"
            },
            {
                "value": 14626,
                "label": "Santa Rosa",
                "label_ar": "سانتا روزا",
                "label_fr": "Santa Rosa"
            },
            {
                "value": 14627,
                "label": "Santa Rosa Beach",
                "label_ar": "شاطئ سانتا روزا",
                "label_fr": "Plage de Santa Rosa"
            },
            {
                "value": 14628,
                "label": "Santa Teresa",
                "label_ar": "سانتا تيريزا",
                "label_fr": "Santa Teresa"
            },
            {
                "value": 14629,
                "label": "Santa Ynez",
                "label_ar": "سانتا ينز",
                "label_fr": "Santa Ynez"
            },
            {
                "value": 14630,
                "label": "Santa Ysabel",
                "label_ar": "سانتا يسابيل",
                "label_fr": "Santa Ysabel"
            },
            {
                "value": 14631,
                "label": "Santaquin",
                "label_ar": "سانتاكوين",
                "label_fr": "Santaquin"
            },
            {
                "value": 14632,
                "label": "Santee",
                "label_ar": "سانتي",
                "label_fr": "Santee"
            },
            {
                "value": 14633,
                "label": "Santo",
                "label_ar": "سانتو",
                "label_fr": "Santo"
            },
            {
                "value": 14634,
                "label": "Sapphire",
                "label_ar": "الياقوت",
                "label_fr": "Saphir"
            },
            {
                "value": 14635,
                "label": "Sapulpa",
                "label_ar": "سابولبا",
                "label_fr": "Sapulpa"
            },
            {
                "value": 14636,
                "label": "Sarah",
                "label_ar": "ساره",
                "label_fr": "Sarah"
            },
            {
                "value": 14637,
                "label": "Sarah Ann",
                "label_ar": "سارة آن",
                "label_fr": "Sarah Ann"
            },
            {
                "value": 14638,
                "label": "Sarahsville",
                "label_ar": "سارازفيل",
                "label_fr": "Sarahsville"
            },
            {
                "value": 14639,
                "label": "Saraland",
                "label_ar": "سارالاند",
                "label_fr": "Saraland"
            },
            {
                "value": 14640,
                "label": "Saranac",
                "label_ar": "ساراناك",
                "label_fr": "Saranac"
            },
            {
                "value": 14641,
                "label": "Saranac Lake",
                "label_ar": "بحيرة ساراناك",
                "label_fr": "Lac Saranac"
            },
            {
                "value": 14642,
                "label": "Sarasota",
                "label_ar": "ساراسوتا",
                "label_fr": "Sarasota"
            },
            {
                "value": 14643,
                "label": "Saratoga",
                "label_ar": "ساراتوجا",
                "label_fr": "Saratoga"
            },
            {
                "value": 14644,
                "label": "Saratoga Springs",
                "label_ar": "ساراتوجا سبرينجز",
                "label_fr": "Saratoga Springs"
            },
            {
                "value": 14645,
                "label": "Sarcoxie",
                "label_ar": "ساركوكسي",
                "label_fr": "Sarcoxie"
            },
            {
                "value": 14646,
                "label": "Sardinia",
                "label_ar": "سردينيا",
                "label_fr": "Sardaigne"
            },
            {
                "value": 14647,
                "label": "Sardis",
                "label_ar": "ساردس",
                "label_fr": "Sardes"
            },
            {
                "value": 14648,
                "label": "Sarepta",
                "label_ar": "Sarepta",
                "label_fr": "Sarepta"
            },
            {
                "value": 14649,
                "label": "Sargent",
                "label_ar": "سارجنت",
                "label_fr": "Sargent"
            },
            {
                "value": 14650,
                "label": "Sarita",
                "label_ar": "ساريتا",
                "label_fr": "Sarita"
            },
            {
                "value": 14651,
                "label": "Sarona",
                "label_ar": "سارونا",
                "label_fr": "Sarona"
            },
            {
                "value": 14652,
                "label": "Saronville",
                "label_ar": "سارونفيل",
                "label_fr": "Saronville"
            },
            {
                "value": 14653,
                "label": "Sartell",
                "label_ar": "سارتيل",
                "label_fr": "Sartell"
            },
            {
                "value": 14654,
                "label": "Sarver",
                "label_ar": "سارفر",
                "label_fr": "Sarver"
            },
            {
                "value": 14655,
                "label": "Sasakwa",
                "label_ar": "ساساكوا",
                "label_fr": "Sasakwa"
            },
            {
                "value": 14656,
                "label": "Satanta",
                "label_ar": "ساتانتا",
                "label_fr": "Satanta"
            },
            {
                "value": 14657,
                "label": "Satartia",
                "label_ar": "ساتارتيا",
                "label_fr": "Satartia"
            },
            {
                "value": 14658,
                "label": "Satellite Beach",
                "label_ar": "شاطئ القمر الصناعي",
                "label_fr": "Plage satellite"
            },
            {
                "value": 14659,
                "label": "Satsop",
                "label_ar": "ساتسوب",
                "label_fr": "Satsop"
            },
            {
                "value": 14660,
                "label": "Satsuma",
                "label_ar": "ساتسوما",
                "label_fr": "Satsuma"
            },
            {
                "value": 14661,
                "label": "Saucier",
                "label_ar": "صوص",
                "label_fr": "Saucier"
            },
            {
                "value": 14662,
                "label": "Saugatuck",
                "label_ar": "سوجاتوك",
                "label_fr": "Saugatuck"
            },
            {
                "value": 14663,
                "label": "Saugerties",
                "label_ar": "Saugerties",
                "label_fr": "Saugerties"
            },
            {
                "value": 14664,
                "label": "Saugus",
                "label_ar": "Saugus",
                "label_fr": "Saugus"
            },
            {
                "value": 14665,
                "label": "Sauk Centre",
                "label_ar": "مركز سوك",
                "label_fr": "Centre Sauk"
            },
            {
                "value": 14666,
                "label": "Sauk City",
                "label_ar": "مدينة سوك",
                "label_fr": "Sauk City"
            },
            {
                "value": 14667,
                "label": "Sauk Rapids",
                "label_ar": "سوك رابيدز",
                "label_fr": "Rapides de Sauk"
            },
            {
                "value": 14668,
                "label": "Saukville",
                "label_ar": "سوكفيل",
                "label_fr": "Saukville"
            },
            {
                "value": 14669,
                "label": "Saulsbury",
                "label_ar": "سالسبري",
                "label_fr": "Saulsbury"
            },
            {
                "value": 14670,
                "label": "Saulsville",
                "label_ar": "ساولسفيل",
                "label_fr": "Saulsville"
            },
            {
                "value": 14671,
                "label": "Sault Ste. Marie",
                "label_ar": "سولت سانت. ماري",
                "label_fr": "Sault Ste. Marie"
            },
            {
                "value": 14672,
                "label": "Saunderstown",
                "label_ar": "Saunderstown",
                "label_fr": "Saunderstown"
            },
            {
                "value": 14673,
                "label": "Saunemin",
                "label_ar": "سونمين",
                "label_fr": "Saunemin"
            },
            {
                "value": 14674,
                "label": "Sauquoit",
                "label_ar": "ساوكويت",
                "label_fr": "Sauquoit"
            },
            {
                "value": 14675,
                "label": "Sausalito",
                "label_ar": "سوساليتو",
                "label_fr": "Sausalito"
            },
            {
                "value": 14676,
                "label": "Savage",
                "label_ar": "سافاج",
                "label_fr": "Sauvage"
            },
            {
                "value": 14677,
                "label": "Savanna",
                "label_ar": "السافانا",
                "label_fr": "Savane"
            },
            {
                "value": 14678,
                "label": "Savannah",
                "label_ar": "سافانا",
                "label_fr": "Savane"
            },
            {
                "value": 14679,
                "label": "Savonburg",
                "label_ar": "سافونبورغ",
                "label_fr": "Savonburg"
            },
            {
                "value": 14680,
                "label": "Savoy",
                "label_ar": "سافوي",
                "label_fr": "Savoie"
            },
            {
                "value": 14681,
                "label": "Sawyer",
                "label_ar": "سوير",
                "label_fr": "Scieur"
            },
            {
                "value": 14682,
                "label": "Saxon",
                "label_ar": "سكسونية",
                "label_fr": "saxon"
            },
            {
                "value": 14683,
                "label": "Saxonburg",
                "label_ar": "ساكسونبرج",
                "label_fr": "Saxonburg"
            },
            {
                "value": 14684,
                "label": "Saxton",
                "label_ar": "ساكستون",
                "label_fr": "Saxton"
            },
            {
                "value": 14685,
                "label": "Saxtons River",
                "label_ar": "نهر ساكستون",
                "label_fr": "Rivière Saxtons"
            },
            {
                "value": 14686,
                "label": "Saybrook",
                "label_ar": "سايبروك",
                "label_fr": "Saybrook"
            },
            {
                "value": 14687,
                "label": "Saylorsburg",
                "label_ar": "سايلورسبورج",
                "label_fr": "Saylorsburg"
            },
            {
                "value": 14688,
                "label": "Sayre",
                "label_ar": "ساير",
                "label_fr": "Sayre"
            },
            {
                "value": 14689,
                "label": "Sayreville",
                "label_ar": "سايرفيل",
                "label_fr": "Sayreville"
            },
            {
                "value": 14690,
                "label": "Sayville",
                "label_ar": "سايفيل",
                "label_fr": "Sayville"
            },
            {
                "value": 14691,
                "label": "Scales Mound",
                "label_ar": "كومة الموازين",
                "label_fr": "Échelles Mound"
            },
            {
                "value": 14692,
                "label": "Scammon Bay",
                "label_ar": "سكامون باي",
                "label_fr": "Scammon Bay"
            },
            {
                "value": 14693,
                "label": "Scandia",
                "label_ar": "سكانديا",
                "label_fr": "Scandia"
            },
            {
                "value": 14694,
                "label": "Scandinavia",
                "label_ar": "اسكندنافيا",
                "label_fr": "Scandinavie"
            },
            {
                "value": 14695,
                "label": "Scappoose",
                "label_ar": "سكابوز",
                "label_fr": "Scappoose"
            },
            {
                "value": 14696,
                "label": "Scarborough",
                "label_ar": "سكاربورو",
                "label_fr": "Scarborough"
            },
            {
                "value": 14697,
                "label": "Scarbro",
                "label_ar": "سكاربرو",
                "label_fr": "Scarbro"
            },
            {
                "value": 14698,
                "label": "Scarsdale",
                "label_ar": "سكارسديل",
                "label_fr": "Scarsdale"
            },
            {
                "value": 14699,
                "label": "Scarville",
                "label_ar": "سكارفيل",
                "label_fr": "Scarville"
            },
            {
                "value": 14700,
                "label": "Scenery Hill",
                "label_ar": "مشهد التل",
                "label_fr": "Paysage Colline"
            },
            {
                "value": 14701,
                "label": "Schaefferstown",
                "label_ar": "شيفرستاون",
                "label_fr": "Schaefferstown"
            },
            {
                "value": 14702,
                "label": "Schaghticoke",
                "label_ar": "شاغتيكوك",
                "label_fr": "Schaghticoke"
            },
            {
                "value": 14703,
                "label": "Schaller",
                "label_ar": "شالر",
                "label_fr": "Schaller"
            },
            {
                "value": 14704,
                "label": "Schaumburg",
                "label_ar": "شاومبورغ",
                "label_fr": "Schaumburg"
            },
            {
                "value": 14705,
                "label": "Schellsburg",
                "label_ar": "شيلسبورغ",
                "label_fr": "Schellsburg"
            },
            {
                "value": 14706,
                "label": "Schenectady",
                "label_ar": "شينيكتادي",
                "label_fr": "Schenectady"
            },
            {
                "value": 14707,
                "label": "Schenevus",
                "label_ar": "شينيفوس",
                "label_fr": "Schenevus"
            },
            {
                "value": 14708,
                "label": "Schenley",
                "label_ar": "شينلي",
                "label_fr": "Schenley"
            },
            {
                "value": 14709,
                "label": "Schererville",
                "label_ar": "شيرفيل",
                "label_fr": "Schererville"
            },
            {
                "value": 14710,
                "label": "Schertz",
                "label_ar": "شيرتز",
                "label_fr": "Schertz"
            },
            {
                "value": 14711,
                "label": "Schiller Park",
                "label_ar": "شيلر بارك",
                "label_fr": "Parc Schiller"
            },
            {
                "value": 14712,
                "label": "Schlater",
                "label_ar": "شلاتر",
                "label_fr": "Schlater"
            },
            {
                "value": 14713,
                "label": "Schleswig",
                "label_ar": "شليسفيغ",
                "label_fr": "Schleswig"
            },
            {
                "value": 14714,
                "label": "Schnecksville",
                "label_ar": "شنكسفيل",
                "label_fr": "Schnecksville"
            },
            {
                "value": 14715,
                "label": "Schneider",
                "label_ar": "شنايدر",
                "label_fr": "Schneider"
            },
            {
                "value": 14716,
                "label": "Schodack Landing",
                "label_ar": "شوداك لاندينج",
                "label_fr": "Atterrissage Schodack"
            },
            {
                "value": 14717,
                "label": "Schoenchen",
                "label_ar": "شوينشين",
                "label_fr": "Schoenchen"
            },
            {
                "value": 14718,
                "label": "Schofield",
                "label_ar": "سكوفيلد",
                "label_fr": "Schofield"
            },
            {
                "value": 14719,
                "label": "Schoharie",
                "label_ar": "شوهري",
                "label_fr": "Schoharie"
            },
            {
                "value": 14720,
                "label": "Schoolcraft",
                "label_ar": "سكولكرافت",
                "label_fr": "Schoolcraft"
            },
            {
                "value": 14721,
                "label": "Schroon Lake",
                "label_ar": "بحيرة شروون",
                "label_fr": "Lac Schroon"
            },
            {
                "value": 14722,
                "label": "Schulenburg",
                "label_ar": "شولنبرغ",
                "label_fr": "Schulenburg"
            },
            {
                "value": 14723,
                "label": "Schulter",
                "label_ar": "شولتر",
                "label_fr": "Schulter"
            },
            {
                "value": 14724,
                "label": "Schurz",
                "label_ar": "شورز",
                "label_fr": "Schurz"
            },
            {
                "value": 14725,
                "label": "Schuyler",
                "label_ar": "شويلر",
                "label_fr": "Schuyler"
            },
            {
                "value": 14726,
                "label": "Schuylerville",
                "label_ar": "شويلرفيل",
                "label_fr": "Schuylerville"
            },
            {
                "value": 14727,
                "label": "Schuylkill Haven",
                "label_ar": "شيلكيل هافن",
                "label_fr": "Schuylkill Haven"
            },
            {
                "value": 14728,
                "label": "Schwenksville",
                "label_ar": "شفينكسفيل",
                "label_fr": "Schwenksville"
            },
            {
                "value": 14729,
                "label": "Science Hill",
                "label_ar": "ساينس هيل",
                "label_fr": "Science Hill"
            },
            {
                "value": 14730,
                "label": "Scio",
                "label_ar": "Scio",
                "label_fr": "Scio"
            },
            {
                "value": 14731,
                "label": "Sciota",
                "label_ar": "Sciota",
                "label_fr": "Sciota"
            },
            {
                "value": 14732,
                "label": "Scipio",
                "label_ar": "سكيبيو",
                "label_fr": "Scipion"
            },
            {
                "value": 14733,
                "label": "Scipio Center",
                "label_ar": "مركز سكيبيو",
                "label_fr": "Centre Scipion"
            },
            {
                "value": 14734,
                "label": "Scituate",
                "label_ar": "شيطاني",
                "label_fr": "Scituer"
            },
            {
                "value": 14735,
                "label": "Scobey",
                "label_ar": "سكوبي",
                "label_fr": "Scobey"
            },
            {
                "value": 14736,
                "label": "Scotch Plains",
                "label_ar": "سكوتش بلينز",
                "label_fr": "Plaines écossaises"
            },
            {
                "value": 14737,
                "label": "Scotia",
                "label_ar": "سكوتيا",
                "label_fr": "Ecosse"
            },
            {
                "value": 14738,
                "label": "Scotland",
                "label_ar": "اسكتلندا",
                "label_fr": "Écosse"
            },
            {
                "value": 14739,
                "label": "Scotland Neck",
                "label_ar": "اسكتلندا العنق",
                "label_fr": "Ecosse Neck"
            },
            {
                "value": 14740,
                "label": "Scotrun",
                "label_ar": "سكترون",
                "label_fr": "Scotrun"
            },
            {
                "value": 14741,
                "label": "Scott",
                "label_ar": "سكوت",
                "label_fr": "Scott"
            },
            {
                "value": 14742,
                "label": "Scott Air Force Base",
                "label_ar": "قاعدة سكوت الجوية",
                "label_fr": "Base aérienne de Scott"
            },
            {
                "value": 14743,
                "label": "Scott City",
                "label_ar": "سكوت سيتي",
                "label_fr": "Scott City"
            },
            {
                "value": 14744,
                "label": "Scott Depot",
                "label_ar": "سكوت ديبوت",
                "label_fr": "Scott Depot"
            },
            {
                "value": 14745,
                "label": "Scottdale",
                "label_ar": "سكوتديل",
                "label_fr": "Scottdale"
            },
            {
                "value": 14746,
                "label": "Scottown",
                "label_ar": "سكوتاون",
                "label_fr": "Scottown"
            },
            {
                "value": 14747,
                "label": "Scotts",
                "label_ar": "سكوتس",
                "label_fr": "Scotts"
            },
            {
                "value": 14748,
                "label": "Scotts Hill",
                "label_ar": "سكوتس هيل",
                "label_fr": "Scotts Hill"
            },
            {
                "value": 14749,
                "label": "Scotts Mills",
                "label_ar": "سكوتس ميلز",
                "label_fr": "Scotts Mills"
            },
            {
                "value": 14750,
                "label": "Scotts Valley",
                "label_ar": "وادي سكوتس",
                "label_fr": "Vallée de Scotts"
            },
            {
                "value": 14751,
                "label": "Scottsbluff",
                "label_ar": "سكوتسبلوف",
                "label_fr": "Scottsbluff"
            },
            {
                "value": 14752,
                "label": "Scottsboro",
                "label_ar": "سكوتسبورو",
                "label_fr": "Scottsboro"
            },
            {
                "value": 14753,
                "label": "Scottsburg",
                "label_ar": "سكوتسبرغ",
                "label_fr": "Scottsburg"
            },
            {
                "value": 14754,
                "label": "Scottsdale",
                "label_ar": "سكوتسديل",
                "label_fr": "Scottsdale"
            },
            {
                "value": 14755,
                "label": "Scottsville",
                "label_ar": "سكوتسفيل",
                "label_fr": "Scottsville"
            },
            {
                "value": 14756,
                "label": "Scottville",
                "label_ar": "سكوتفيل",
                "label_fr": "Scottville"
            },
            {
                "value": 14757,
                "label": "Scranton",
                "label_ar": "سكرانتون",
                "label_fr": "Scranton"
            },
            {
                "value": 14758,
                "label": "Scribner",
                "label_ar": "سكريبنر",
                "label_fr": "Scribner"
            },
            {
                "value": 14759,
                "label": "Scroggins",
                "label_ar": "سكروجينز",
                "label_fr": "Scroggins"
            },
            {
                "value": 14760,
                "label": "Scurry",
                "label_ar": "هرول",
                "label_fr": "Se précipiter"
            },
            {
                "value": 14761,
                "label": "Sea Cliff",
                "label_ar": "جرف البحر",
                "label_fr": "Falaise de la mer"
            },
            {
                "value": 14762,
                "label": "Sea Girt",
                "label_ar": "جيرت البحر",
                "label_fr": "Jupe de mer"
            },
            {
                "value": 14763,
                "label": "Sea Island",
                "label_ar": "جزيرة البحر",
                "label_fr": "Sea Island"
            },
            {
                "value": 14764,
                "label": "Sea Isle City",
                "label_ar": "سي ايل سيتي",
                "label_fr": "Sea Isle City"
            },
            {
                "value": 14765,
                "label": "Sea Ranch",
                "label_ar": "سي رانش",
                "label_fr": "Sea Ranch"
            },
            {
                "value": 14766,
                "label": "Seabeck",
                "label_ar": "سيبيك",
                "label_fr": "Seabeck"
            },
            {
                "value": 14767,
                "label": "Seabrook",
                "label_ar": "سيبروك",
                "label_fr": "Seabrook"
            },
            {
                "value": 14768,
                "label": "Seadrift",
                "label_ar": "الانجراف البحر",
                "label_fr": "Seadrift"
            },
            {
                "value": 14769,
                "label": "Seaford",
                "label_ar": "سيفورد",
                "label_fr": "Seaford"
            },
            {
                "value": 14770,
                "label": "Seagoville",
                "label_ar": "سيغوفيل",
                "label_fr": "Seagoville"
            },
            {
                "value": 14771,
                "label": "Seagraves",
                "label_ar": "Seagraves",
                "label_fr": "Seagraves"
            },
            {
                "value": 14772,
                "label": "Seagrove",
                "label_ar": "سيغروف",
                "label_fr": "Seagrove"
            },
            {
                "value": 14773,
                "label": "Seahurst",
                "label_ar": "سيهورست",
                "label_fr": "Seahurst"
            },
            {
                "value": 14774,
                "label": "Seal",
                "label_ar": "عجل البحر",
                "label_fr": "Joint"
            },
            {
                "value": 14775,
                "label": "Seal Beach",
                "label_ar": "سيل بيتش",
                "label_fr": "Seal Beach"
            },
            {
                "value": 14776,
                "label": "Seal Cove",
                "label_ar": "الختم كوف",
                "label_fr": "Seal Cove"
            },
            {
                "value": 14777,
                "label": "Seal Rock",
                "label_ar": "صخرة الختم",
                "label_fr": "Seal Rock"
            },
            {
                "value": 14778,
                "label": "Seale",
                "label_ar": "سيل",
                "label_fr": "Seale"
            },
            {
                "value": 14779,
                "label": "Sealevel",
                "label_ar": "مستوى سطح البحر",
                "label_fr": "Niveau de la mer"
            },
            {
                "value": 14780,
                "label": "Sealy",
                "label_ar": "سيلي",
                "label_fr": "Sealy"
            },
            {
                "value": 14781,
                "label": "Seaman",
                "label_ar": "بحار",
                "label_fr": "Marin"
            },
            {
                "value": 14782,
                "label": "Searcy",
                "label_ar": "سيرسي",
                "label_fr": "Searcy"
            },
            {
                "value": 14783,
                "label": "Searsboro",
                "label_ar": "سيرسبورو",
                "label_fr": "Searsboro"
            },
            {
                "value": 14784,
                "label": "Searsmont",
                "label_ar": "سيرسمونت",
                "label_fr": "Searsmont"
            },
            {
                "value": 14785,
                "label": "Seaside",
                "label_ar": "شاطئ البحر",
                "label_fr": "Bord de mer"
            },
            {
                "value": 14786,
                "label": "Seaside Heights",
                "label_ar": "سيسايد هايتس",
                "label_fr": "Hauteurs de bord de mer"
            },
            {
                "value": 14787,
                "label": "Seaside Park",
                "label_ar": "حديقة سي سايد",
                "label_fr": "Parc balnéaire"
            },
            {
                "value": 14788,
                "label": "Seaton",
                "label_ar": "سيتون",
                "label_fr": "Seaton"
            },
            {
                "value": 14789,
                "label": "Seatonville",
                "label_ar": "سيتونفيل",
                "label_fr": "Seatonville"
            },
            {
                "value": 14790,
                "label": "Seattle",
                "label_ar": "سياتل",
                "label_fr": "Seattle"
            },
            {
                "value": 14791,
                "label": "Sebastian",
                "label_ar": "سيباستيان",
                "label_fr": "Sébastien"
            },
            {
                "value": 14792,
                "label": "Sebastopol",
                "label_ar": "سيباستوبول",
                "label_fr": "Sébastopol"
            },
            {
                "value": 14793,
                "label": "Sebec",
                "label_ar": "سيبيك",
                "label_fr": "Sebec"
            },
            {
                "value": 14794,
                "label": "Sebeka",
                "label_ar": "سيبكا",
                "label_fr": "Sebeka"
            },
            {
                "value": 14795,
                "label": "Sebewaing",
                "label_ar": "سيبيوانج",
                "label_fr": "Sebewaing"
            },
            {
                "value": 14796,
                "label": "Sebree",
                "label_ar": "سيبري",
                "label_fr": "Sebree"
            },
            {
                "value": 14797,
                "label": "Sebring",
                "label_ar": "سيبرينغ",
                "label_fr": "Sebring"
            },
            {
                "value": 14798,
                "label": "Secaucus",
                "label_ar": "Secaucus",
                "label_fr": "Secaucus"
            },
            {
                "value": 14799,
                "label": "Second Mesa",
                "label_ar": "ميسا الثانية",
                "label_fr": "Deuxième Mesa"
            },
            {
                "value": 14800,
                "label": "Secor",
                "label_ar": "سيكور",
                "label_fr": "Secor"
            },
            {
                "value": 14801,
                "label": "Section",
                "label_ar": "الجزء",
                "label_fr": "Section"
            },
            {
                "value": 14802,
                "label": "Sedalia",
                "label_ar": "سيداليا",
                "label_fr": "Sedalia"
            },
            {
                "value": 14803,
                "label": "Sedan",
                "label_ar": "سيدان",
                "label_fr": "Sedan"
            },
            {
                "value": 14804,
                "label": "Sedgwick",
                "label_ar": "سيدجويك",
                "label_fr": "Sedgwick"
            },
            {
                "value": 14805,
                "label": "Sedona",
                "label_ar": "سيدونا",
                "label_fr": "Sedona"
            },
            {
                "value": 14806,
                "label": "Sedro-Woolley",
                "label_ar": "سيدرو وولي",
                "label_fr": "Sedro-Woolley"
            },
            {
                "value": 14807,
                "label": "Seekonk",
                "label_ar": "سيكونك",
                "label_fr": "Seekonk"
            },
            {
                "value": 14808,
                "label": "Seeley",
                "label_ar": "سيلي",
                "label_fr": "Seeley"
            },
            {
                "value": 14809,
                "label": "Seeley Lake",
                "label_ar": "بحيرة سيلي",
                "label_fr": "Lac Seeley"
            },
            {
                "value": 14810,
                "label": "Seffner",
                "label_ar": "سيفنر",
                "label_fr": "Seffner"
            },
            {
                "value": 14811,
                "label": "Seguin",
                "label_ar": "سيغوين",
                "label_fr": "Seguin"
            },
            {
                "value": 14812,
                "label": "Seiling",
                "label_ar": "الغسل",
                "label_fr": "Seiling"
            },
            {
                "value": 14813,
                "label": "Selah",
                "label_ar": "صلاح",
                "label_fr": "Selah"
            },
            {
                "value": 14814,
                "label": "Selawik",
                "label_ar": "سلاويك",
                "label_fr": "Selawik"
            },
            {
                "value": 14815,
                "label": "Selby",
                "label_ar": "سيلبي",
                "label_fr": "Selby"
            },
            {
                "value": 14816,
                "label": "Selbyville",
                "label_ar": "سيلبيفيل",
                "label_fr": "Selbyville"
            },
            {
                "value": 14817,
                "label": "Selden",
                "label_ar": "سلدن",
                "label_fr": "Selden"
            },
            {
                "value": 14818,
                "label": "Seligman",
                "label_ar": "سيليجمان",
                "label_fr": "Seligman"
            },
            {
                "value": 14819,
                "label": "Selinsgrove",
                "label_ar": "سيلينجروف",
                "label_fr": "Selinsgrove"
            },
            {
                "value": 14820,
                "label": "Selkirk",
                "label_ar": "سيلكيرك",
                "label_fr": "Selkirk"
            },
            {
                "value": 14821,
                "label": "Sellersburg",
                "label_ar": "سيلرسبورغ",
                "label_fr": "Sellersburg"
            },
            {
                "value": 14822,
                "label": "Sellersville",
                "label_ar": "سيلرسفيل",
                "label_fr": "Sellersville"
            },
            {
                "value": 14823,
                "label": "Sells",
                "label_ar": "تبيع",
                "label_fr": "Vend"
            },
            {
                "value": 14824,
                "label": "Selma",
                "label_ar": "سلمى",
                "label_fr": "Selma"
            },
            {
                "value": 14825,
                "label": "Selmer",
                "label_ar": "سيلمر",
                "label_fr": "Selmer"
            },
            {
                "value": 14826,
                "label": "Seminary",
                "label_ar": "مدرسة",
                "label_fr": "Séminaire"
            },
            {
                "value": 14827,
                "label": "Seminole",
                "label_ar": "سيمينول",
                "label_fr": "Seminole"
            },
            {
                "value": 14828,
                "label": "Semmes",
                "label_ar": "سيميس",
                "label_fr": "Semmes"
            },
            {
                "value": 14829,
                "label": "Senath",
                "label_ar": "سينات",
                "label_fr": "Senath"
            },
            {
                "value": 14830,
                "label": "Senatobia",
                "label_ar": "سيناتوبيا",
                "label_fr": "Sénatobie"
            },
            {
                "value": 14831,
                "label": "Seneca",
                "label_ar": "سينيكا",
                "label_fr": "Sénèque"
            },
            {
                "value": 14832,
                "label": "Seneca Falls",
                "label_ar": "سينيكا فولز",
                "label_fr": "Seneca Falls"
            },
            {
                "value": 14833,
                "label": "Senecaville",
                "label_ar": "سينيكافيل",
                "label_fr": "Sénécaville"
            },
            {
                "value": 14834,
                "label": "Senoia",
                "label_ar": "سينويا",
                "label_fr": "Senoia"
            },
            {
                "value": 14835,
                "label": "Sentinel Butte",
                "label_ar": "الحارس بوت",
                "label_fr": "Sentinel Butte"
            },
            {
                "value": 14836,
                "label": "Sequim",
                "label_ar": "Sequim",
                "label_fr": "Sequim"
            },
            {
                "value": 14837,
                "label": "Sequoia",
                "label_ar": "سيكويا",
                "label_fr": "Séquoia"
            },
            {
                "value": 14838,
                "label": "Serafina",
                "label_ar": "سيرافينا",
                "label_fr": "Serafina"
            },
            {
                "value": 14839,
                "label": "Serena",
                "label_ar": "سيرينا",
                "label_fr": "Serena"
            },
            {
                "value": 14840,
                "label": "Sergeant Bluff",
                "label_ar": "الرقيب بلاف",
                "label_fr": "Sergent Bluff"
            },
            {
                "value": 14841,
                "label": "Sergeantsville",
                "label_ar": "سيرجنتسفيل",
                "label_fr": "Sergeantsville"
            },
            {
                "value": 14842,
                "label": "Sesser",
                "label_ar": "سيسر",
                "label_fr": "Sesser"
            },
            {
                "value": 14843,
                "label": "Seth",
                "label_ar": "سيث",
                "label_fr": "Seth"
            },
            {
                "value": 14844,
                "label": "Seven Mile",
                "label_ar": "سبعة أميال",
                "label_fr": "Seven Mile"
            },
            {
                "value": 14845,
                "label": "Seven Springs",
                "label_ar": "سبعة سبرينغز",
                "label_fr": "Sept sources"
            },
            {
                "value": 14846,
                "label": "Seven Valleys",
                "label_ar": "سبعة وديان",
                "label_fr": "Sept vallées"
            },
            {
                "value": 14847,
                "label": "Severance",
                "label_ar": "قطع",
                "label_fr": "Rupture"
            },
            {
                "value": 14848,
                "label": "Severn",
                "label_ar": "سيفيرن",
                "label_fr": "Severn"
            },
            {
                "value": 14849,
                "label": "Severna Park",
                "label_ar": "حديقة سيفيرنا",
                "label_fr": "Parc Severna"
            },
            {
                "value": 14850,
                "label": "Severy",
                "label_ar": "سيفري",
                "label_fr": "Severy"
            },
            {
                "value": 14851,
                "label": "Sevierville",
                "label_ar": "سيفيرفيل",
                "label_fr": "Sevierville"
            },
            {
                "value": 14852,
                "label": "Seville",
                "label_ar": "إشبيلية",
                "label_fr": "Séville"
            },
            {
                "value": 14853,
                "label": "Sewanee",
                "label_ar": "سيواني",
                "label_fr": "Sewanee"
            },
            {
                "value": 14854,
                "label": "Seward",
                "label_ar": "سيوارد",
                "label_fr": "Seward"
            },
            {
                "value": 14855,
                "label": "Sewaren",
                "label_ar": "مخيط",
                "label_fr": "Sewaren"
            },
            {
                "value": 14856,
                "label": "Sewell",
                "label_ar": "سيويل",
                "label_fr": "Sewell"
            },
            {
                "value": 14857,
                "label": "Sewickley",
                "label_ar": "سيويكلي",
                "label_fr": "Sewickley"
            },
            {
                "value": 14858,
                "label": "Seymour",
                "label_ar": "سيمور",
                "label_fr": "Seymour"
            },
            {
                "value": 14859,
                "label": "Shabbona",
                "label_ar": "شبونا",
                "label_fr": "Shabbona"
            },
            {
                "value": 14860,
                "label": "Shacklefords",
                "label_ar": "شاكلفورد",
                "label_fr": "Shacklefords"
            },
            {
                "value": 14861,
                "label": "Shade Gap",
                "label_ar": "فجوة الظل",
                "label_fr": "Écart d'ombre"
            },
            {
                "value": 14862,
                "label": "Shady Cove",
                "label_ar": "شادي كوف",
                "label_fr": "Shady Cove"
            },
            {
                "value": 14863,
                "label": "Shady Dale",
                "label_ar": "شادي ديل",
                "label_fr": "Shady Dale"
            },
            {
                "value": 14864,
                "label": "Shady Point",
                "label_ar": "شادي بوينت",
                "label_fr": "Point ombragé"
            },
            {
                "value": 14865,
                "label": "Shady Side",
                "label_ar": "شادي سايد",
                "label_fr": "Côté ombragé"
            },
            {
                "value": 14866,
                "label": "Shady Spring",
                "label_ar": "شادي سبرينج",
                "label_fr": "Printemps ombragé"
            },
            {
                "value": 14867,
                "label": "Shady Valley",
                "label_ar": "وادي شادي",
                "label_fr": "Vallée ombragée"
            },
            {
                "value": 14868,
                "label": "Shadyside",
                "label_ar": "شاديسايد",
                "label_fr": "Shadyside"
            },
            {
                "value": 14869,
                "label": "Shafer",
                "label_ar": "شيفر",
                "label_fr": "Shafer"
            },
            {
                "value": 14870,
                "label": "Shafter",
                "label_ar": "شافتر",
                "label_fr": "Shafter"
            },
            {
                "value": 14871,
                "label": "Shaftsbury",
                "label_ar": "شافتسبري",
                "label_fr": "Shaftsbury"
            },
            {
                "value": 14872,
                "label": "Shakopee",
                "label_ar": "شاكوبي",
                "label_fr": "Shakopee"
            },
            {
                "value": 14873,
                "label": "Shalimar",
                "label_ar": "شاليمار",
                "label_fr": "Shalimar"
            },
            {
                "value": 14874,
                "label": "Shallotte",
                "label_ar": "شالوت",
                "label_fr": "Shallotte"
            },
            {
                "value": 14875,
                "label": "Shallowater",
                "label_ar": "المياه الضحلة",
                "label_fr": "Peu profond"
            },
            {
                "value": 14876,
                "label": "Shamokin",
                "label_ar": "شاموكين",
                "label_fr": "Shamokin"
            },
            {
                "value": 14877,
                "label": "Shamokin Dam",
                "label_ar": "سد شاموكين",
                "label_fr": "Barrage de Shamokin"
            },
            {
                "value": 14878,
                "label": "Shamrock",
                "label_ar": "شامروك",
                "label_fr": "Trèfle"
            },
            {
                "value": 14879,
                "label": "Shandaken",
                "label_ar": "شانداكين",
                "label_fr": "Shandaken"
            },
            {
                "value": 14880,
                "label": "Shandon",
                "label_ar": "شاندون",
                "label_fr": "Shandon"
            },
            {
                "value": 14881,
                "label": "Shanks",
                "label_ar": "السيقان",
                "label_fr": "Shanks"
            },
            {
                "value": 14882,
                "label": "Shanksville",
                "label_ar": "شانكسفيل",
                "label_fr": "Shanksville"
            },
            {
                "value": 14883,
                "label": "Shannon",
                "label_ar": "شانون",
                "label_fr": "Shannon"
            },
            {
                "value": 14884,
                "label": "Shannon City",
                "label_ar": "مدينة شانون",
                "label_fr": "Ville de Shannon"
            },
            {
                "value": 14885,
                "label": "Shapleigh",
                "label_ar": "شابلي",
                "label_fr": "Shapleigh"
            },
            {
                "value": 14886,
                "label": "Sharon",
                "label_ar": "شارون",
                "label_fr": "Sharon"
            },
            {
                "value": 14887,
                "label": "Sharon Center",
                "label_ar": "مركز شارون",
                "label_fr": "Centre Sharon"
            },
            {
                "value": 14888,
                "label": "Sharon Hill",
                "label_ar": "شارون هيل",
                "label_fr": "Sharon Hill"
            },
            {
                "value": 14889,
                "label": "Sharon Springs",
                "label_ar": "شارون سبرينجز",
                "label_fr": "Sharon Springs"
            },
            {
                "value": 14890,
                "label": "Sharpsburg",
                "label_ar": "شاربسبورج",
                "label_fr": "Sharpsburg"
            },
            {
                "value": 14891,
                "label": "Sharpsville",
                "label_ar": "شاربسفيل",
                "label_fr": "Sharpsville"
            },
            {
                "value": 14892,
                "label": "Sharptown",
                "label_ar": "شاربتاون",
                "label_fr": "Sharptown"
            },
            {
                "value": 14893,
                "label": "Shartlesville",
                "label_ar": "شارتلسفيل",
                "label_fr": "Shartlesville"
            },
            {
                "value": 14894,
                "label": "Shasta",
                "label_ar": "شاستا",
                "label_fr": "Shasta"
            },
            {
                "value": 14895,
                "label": "Shattuck",
                "label_ar": "شاتوك",
                "label_fr": "Shattuck"
            },
            {
                "value": 14896,
                "label": "Shavertown",
                "label_ar": "شافرتاون",
                "label_fr": "Shavertown"
            },
            {
                "value": 14897,
                "label": "Shaw",
                "label_ar": "شو",
                "label_fr": "Shaw"
            },
            {
                "value": 14898,
                "label": "Shaw Island",
                "label_ar": "جزيرة شو",
                "label_fr": "Île Shaw"
            },
            {
                "value": 14899,
                "label": "Shawano",
                "label_ar": "شاوانو",
                "label_fr": "Shawano"
            },
            {
                "value": 14900,
                "label": "Shawboro",
                "label_ar": "شوبورو",
                "label_fr": "Shawboro"
            },
            {
                "value": 14901,
                "label": "Shawnee",
                "label_ar": "شوني",
                "label_fr": "Shawnee"
            },
            {
                "value": 14902,
                "label": "Shawnee on Delaware",
                "label_ar": "شوني في ديلاوير",
                "label_fr": "Shawnee sur Delaware"
            },
            {
                "value": 14903,
                "label": "Shawneetown",
                "label_ar": "شوني تاون",
                "label_fr": "Shawneetown"
            },
            {
                "value": 14904,
                "label": "Shawsville",
                "label_ar": "شوزفيل",
                "label_fr": "Shawsville"
            },
            {
                "value": 14905,
                "label": "Sheboygan",
                "label_ar": "شيبويجان",
                "label_fr": "Sheboygan"
            },
            {
                "value": 14906,
                "label": "Sheboygan Falls",
                "label_ar": "شلالات شيبويجان",
                "label_fr": "Sheboygan Falls"
            },
            {
                "value": 14907,
                "label": "Shedd",
                "label_ar": "شيد",
                "label_fr": "Shedd"
            },
            {
                "value": 14908,
                "label": "Sheep Springs",
                "label_ar": "ينابيع الأغنام",
                "label_fr": "Sheep Springs"
            },
            {
                "value": 14909,
                "label": "Sheffield",
                "label_ar": "شيفيلد",
                "label_fr": "Sheffield"
            },
            {
                "value": 14910,
                "label": "Sheffield Lake",
                "label_ar": "بحيرة شيفيلد",
                "label_fr": "Lac Sheffield"
            },
            {
                "value": 14911,
                "label": "Shelbiana",
                "label_ar": "شيلبيانا",
                "label_fr": "Shelbiana"
            },
            {
                "value": 14912,
                "label": "Shelburn",
                "label_ar": "شيلبورن",
                "label_fr": "Shelburn"
            },
            {
                "value": 14913,
                "label": "Shelburne",
                "label_ar": "شيلبورن",
                "label_fr": "Shelburne"
            },
            {
                "value": 14914,
                "label": "Shelburne Falls",
                "label_ar": "شيلبورن فولز",
                "label_fr": "Shelburne Falls"
            },
            {
                "value": 14915,
                "label": "Shelby",
                "label_ar": "شيلبي",
                "label_fr": "Shelby"
            },
            {
                "value": 14916,
                "label": "Shelbyville",
                "label_ar": "شيلبيفيل",
                "label_fr": "Shelbyville"
            },
            {
                "value": 14917,
                "label": "Sheldahl",
                "label_ar": "شيلدال",
                "label_fr": "Sheldahl"
            },
            {
                "value": 14918,
                "label": "Sheldon",
                "label_ar": "شيلدون",
                "label_fr": "Sheldon"
            },
            {
                "value": 14919,
                "label": "Sheldon Springs",
                "label_ar": "شيلدون سبرينجز",
                "label_fr": "Sheldon Springs"
            },
            {
                "value": 14920,
                "label": "Shell",
                "label_ar": "الصدف",
                "label_fr": "coquille"
            },
            {
                "value": 14921,
                "label": "Shell Knob",
                "label_ar": "مقبض شل",
                "label_fr": "Bouton Shell"
            },
            {
                "value": 14922,
                "label": "Shell Lake",
                "label_ar": "شل ليك",
                "label_fr": "Lac Shell"
            },
            {
                "value": 14923,
                "label": "Shell Rock",
                "label_ar": "شل روك",
                "label_fr": "Shell Rock"
            },
            {
                "value": 14924,
                "label": "Shelley",
                "label_ar": "شيلي",
                "label_fr": "Shelley"
            },
            {
                "value": 14925,
                "label": "Shellman",
                "label_ar": "شيلمان",
                "label_fr": "Shellman"
            },
            {
                "value": 14926,
                "label": "Shellsburg",
                "label_ar": "شيلسبورغ",
                "label_fr": "Shellsburg"
            },
            {
                "value": 14927,
                "label": "Shelly",
                "label_ar": "شيلي",
                "label_fr": "Shelly"
            },
            {
                "value": 14928,
                "label": "Shelocta",
                "label_ar": "شيلوكتا",
                "label_fr": "Shelocta"
            },
            {
                "value": 14929,
                "label": "Shelter Island",
                "label_ar": "جزيرة المأوى",
                "label_fr": "Île Shelter"
            },
            {
                "value": 14930,
                "label": "Shelter Island Heights",
                "label_ar": "مرتفعات جزيرة المأوى",
                "label_fr": "Shelter Island Heights"
            },
            {
                "value": 14931,
                "label": "Shelton",
                "label_ar": "شيلتون",
                "label_fr": "Shelton"
            },
            {
                "value": 14932,
                "label": "Shenandoah",
                "label_ar": "شيناندواه",
                "label_fr": "Shenandoah"
            },
            {
                "value": 14933,
                "label": "Shenandoah Junction",
                "label_ar": "مفرق شيناندواه",
                "label_fr": "Jonction Shenandoah"
            },
            {
                "value": 14934,
                "label": "Shepherd",
                "label_ar": "الراعي",
                "label_fr": "Berger"
            },
            {
                "value": 14935,
                "label": "Shepherdstown",
                "label_ar": "شيبردستاون",
                "label_fr": "Shepherdstown"
            },
            {
                "value": 14936,
                "label": "Shepherdsville",
                "label_ar": "شيبردزفيل",
                "label_fr": "Shepherdsville"
            },
            {
                "value": 14937,
                "label": "Sherborn",
                "label_ar": "شيربورن",
                "label_fr": "Sherborn"
            },
            {
                "value": 14938,
                "label": "Sherburn",
                "label_ar": "شيربورن",
                "label_fr": "Sherburn"
            },
            {
                "value": 14939,
                "label": "Sherburne",
                "label_ar": "شيربورن",
                "label_fr": "Sherburne"
            },
            {
                "value": 14940,
                "label": "Sheridan",
                "label_ar": "شيريدان",
                "label_fr": "Sheridan"
            },
            {
                "value": 14941,
                "label": "Sherman",
                "label_ar": "شيرمان",
                "label_fr": "Sherman"
            },
            {
                "value": 14942,
                "label": "Sherman Oaks",
                "label_ar": "شيرمان أوكس",
                "label_fr": "Sherman Oaks"
            },
            {
                "value": 14943,
                "label": "Shermans Dale",
                "label_ar": "شيرمان دايل",
                "label_fr": "Shermans Dale"
            },
            {
                "value": 14944,
                "label": "Sherrard",
                "label_ar": "شيرارد",
                "label_fr": "Sherrard"
            },
            {
                "value": 14945,
                "label": "Sherrill",
                "label_ar": "شيريل",
                "label_fr": "Sherrill"
            },
            {
                "value": 14946,
                "label": "Sherrills Ford",
                "label_ar": "شيريلز فورد",
                "label_fr": "Sherrills Ford"
            },
            {
                "value": 14947,
                "label": "Sherrodsville",
                "label_ar": "شيرودسفيل",
                "label_fr": "Sherrodsville"
            },
            {
                "value": 14948,
                "label": "Sherwood",
                "label_ar": "شيروود",
                "label_fr": "Sherwood"
            },
            {
                "value": 14949,
                "label": "Shevlin",
                "label_ar": "شيفلين",
                "label_fr": "Shevlin"
            },
            {
                "value": 14950,
                "label": "Sheyenne",
                "label_ar": "شيان",
                "label_fr": "Sheyenne"
            },
            {
                "value": 14951,
                "label": "Shickley",
                "label_ar": "شيكلي",
                "label_fr": "Shickley"
            },
            {
                "value": 14952,
                "label": "Shickshinny",
                "label_ar": "شيكشيني",
                "label_fr": "Shickshinny"
            },
            {
                "value": 14953,
                "label": "Shidler",
                "label_ar": "شيدلر",
                "label_fr": "Shidler"
            },
            {
                "value": 14954,
                "label": "Shiloh",
                "label_ar": "شيلوه",
                "label_fr": "Shiloh"
            },
            {
                "value": 14955,
                "label": "Shiner",
                "label_ar": "شاينر",
                "label_fr": "Méné"
            },
            {
                "value": 14956,
                "label": "Shingle Springs",
                "label_ar": "Shingle Springs",
                "label_fr": "Shingle Springs"
            },
            {
                "value": 14957,
                "label": "Shinglehouse",
                "label_ar": "شينغل هاوس",
                "label_fr": "Shinglehouse"
            },
            {
                "value": 14958,
                "label": "Shingleton",
                "label_ar": "شينغلتون",
                "label_fr": "Shingleton"
            },
            {
                "value": 14959,
                "label": "Shingletown",
                "label_ar": "شينغلتاون",
                "label_fr": "Shingletown"
            },
            {
                "value": 14960,
                "label": "Shinnston",
                "label_ar": "شينستون",
                "label_fr": "Shinnston"
            },
            {
                "value": 14961,
                "label": "Shiocton",
                "label_ar": "شيوكتون",
                "label_fr": "Shiocton"
            },
            {
                "value": 14962,
                "label": "Shipman",
                "label_ar": "شيبمان",
                "label_fr": "Shipman"
            },
            {
                "value": 14963,
                "label": "Shippensburg",
                "label_ar": "شيبنسبرج",
                "label_fr": "Shippensburg"
            },
            {
                "value": 14964,
                "label": "Shippenville",
                "label_ar": "شيبنفيل",
                "label_fr": "Shippenville"
            },
            {
                "value": 14965,
                "label": "Shippingport",
                "label_ar": "ميناء الشحن",
                "label_fr": "Port d'expédition"
            },
            {
                "value": 14966,
                "label": "Shiprock",
                "label_ar": "شيبروك",
                "label_fr": "Shiprock"
            },
            {
                "value": 14967,
                "label": "Shipshewana",
                "label_ar": "شيبشيوانا",
                "label_fr": "Shipshewana"
            },
            {
                "value": 14968,
                "label": "Shirley",
                "label_ar": "شيرلي",
                "label_fr": "Shirley"
            },
            {
                "value": 14969,
                "label": "Shirley Mills",
                "label_ar": "شيرلي ميلز",
                "label_fr": "Shirley Mills"
            },
            {
                "value": 14970,
                "label": "Shirleysburg",
                "label_ar": "شيرليسبيرغ",
                "label_fr": "Shirleysburg"
            },
            {
                "value": 14971,
                "label": "Shoals",
                "label_ar": "المياه الضحلة",
                "label_fr": "Bancs"
            },
            {
                "value": 14972,
                "label": "Shobonier",
                "label_ar": "شوبونير",
                "label_fr": "Shobonier"
            },
            {
                "value": 14973,
                "label": "Shoemakersville",
                "label_ar": "شوميكرسفيل",
                "label_fr": "Shoemakersville"
            },
            {
                "value": 14974,
                "label": "Shohola",
                "label_ar": "شوهولا",
                "label_fr": "Shohola"
            },
            {
                "value": 14975,
                "label": "Shokan",
                "label_ar": "شوكان",
                "label_fr": "Shokan"
            },
            {
                "value": 14976,
                "label": "Shongaloo",
                "label_ar": "شونغالو",
                "label_fr": "Shongaloo"
            },
            {
                "value": 14977,
                "label": "Shoreham",
                "label_ar": "شورهام",
                "label_fr": "Shoreham"
            },
            {
                "value": 14978,
                "label": "Shorewood",
                "label_ar": "شوروود",
                "label_fr": "Shorewood"
            },
            {
                "value": 14979,
                "label": "Short Hills",
                "label_ar": "تلال قصيرة",
                "label_fr": "Collines courtes"
            },
            {
                "value": 14980,
                "label": "Shoshone",
                "label_ar": "شوشون",
                "label_fr": "Shoshone"
            },
            {
                "value": 14981,
                "label": "Shoshoni",
                "label_ar": "شوشوني",
                "label_fr": "Shoshoni"
            },
            {
                "value": 14982,
                "label": "Show Low",
                "label_ar": "عرض منخفض",
                "label_fr": "Afficher faible"
            },
            {
                "value": 14983,
                "label": "Shreve",
                "label_ar": "شريف",
                "label_fr": "Shreve"
            },
            {
                "value": 14984,
                "label": "Shreveport",
                "label_ar": "شريفيبورت",
                "label_fr": "Shreveport"
            },
            {
                "value": 14985,
                "label": "Shrewsbury",
                "label_ar": "شروزبري",
                "label_fr": "Shrewsbury"
            },
            {
                "value": 14986,
                "label": "Shrub Oak",
                "label_ar": "شجيرة البلوط",
                "label_fr": "Chêne arbustif"
            },
            {
                "value": 14987,
                "label": "Shubuta",
                "label_ar": "شبوتا",
                "label_fr": "Shubuta"
            },
            {
                "value": 14988,
                "label": "Shumway",
                "label_ar": "شمواي",
                "label_fr": "Shumway"
            },
            {
                "value": 14989,
                "label": "Shungnak",
                "label_ar": "شونغناك",
                "label_fr": "Shungnak"
            },
            {
                "value": 14990,
                "label": "Shutesbury",
                "label_ar": "شوتسبري",
                "label_fr": "Shutesbury"
            },
            {
                "value": 14991,
                "label": "Sibley",
                "label_ar": "سيبلي",
                "label_fr": "Sibley"
            },
            {
                "value": 14992,
                "label": "Sicily Island",
                "label_ar": "جزيرة صقلية",
                "label_fr": "Ile de la Sicile"
            },
            {
                "value": 14993,
                "label": "Sicklerville",
                "label_ar": "سيكلرفيل",
                "label_fr": "Sicklerville"
            },
            {
                "value": 14994,
                "label": "Side Lake",
                "label_ar": "بحيرة سايد",
                "label_fr": "Lac Side"
            },
            {
                "value": 14995,
                "label": "Sidell",
                "label_ar": "Sidell",
                "label_fr": "Sidell"
            },
            {
                "value": 14996,
                "label": "Sidman",
                "label_ar": "سيدمان",
                "label_fr": "Sidman"
            },
            {
                "value": 14997,
                "label": "Sidney",
                "label_ar": "سيدني",
                "label_fr": "Sidney"
            },
            {
                "value": 14998,
                "label": "Sidney Center",
                "label_ar": "مركز سيدني",
                "label_fr": "Sidney Center"
            },
            {
                "value": 14999,
                "label": "Sidon",
                "label_ar": "صيدا",
                "label_fr": "Sidon"
            },
            {
                "value": 15000,
                "label": "Siemens",
                "label_ar": "سيمنز",
                "label_fr": "Siemens"
            },
            {
                "value": 15001,
                "label": "Sierra Madre",
                "label_ar": "سييرا مادري",
                "label_fr": "Sierra Madre"
            },
            {
                "value": 15002,
                "label": "Sierra Vista",
                "label_ar": "سييرا فيستا",
                "label_fr": "Sierra Vista"
            },
            {
                "value": 15003,
                "label": "Signal Hill",
                "label_ar": "سيجنال هيل",
                "label_fr": "Signal Hill"
            },
            {
                "value": 15004,
                "label": "Signal Mountain",
                "label_ar": "جبل سيجنال",
                "label_fr": "Signal Mountain"
            },
            {
                "value": 15005,
                "label": "Sigourney",
                "label_ar": "سيغورني",
                "label_fr": "Sigourney"
            },
            {
                "value": 15006,
                "label": "Sikes",
                "label_ar": "سايكس",
                "label_fr": "Sikes"
            },
            {
                "value": 15007,
                "label": "Sikeston",
                "label_ar": "سيكيستون",
                "label_fr": "Sikeston"
            },
            {
                "value": 15008,
                "label": "Silas",
                "label_ar": "سيلاس",
                "label_fr": "Silas"
            },
            {
                "value": 15009,
                "label": "Siler City",
                "label_ar": "مدينة صيلر",
                "label_fr": "Siler City"
            },
            {
                "value": 15010,
                "label": "Silex",
                "label_ar": "سيليكس",
                "label_fr": "Silex"
            },
            {
                "value": 15011,
                "label": "Siloam",
                "label_ar": "سلوام",
                "label_fr": "Siloam"
            },
            {
                "value": 15012,
                "label": "Siloam Springs",
                "label_ar": "سيلوام سبرينغز",
                "label_fr": "Siloam Springs"
            },
            {
                "value": 15013,
                "label": "Silsbee",
                "label_ar": "سيلسبي",
                "label_fr": "Silsbee"
            },
            {
                "value": 15014,
                "label": "Silt",
                "label_ar": "الطمي",
                "label_fr": "Limon"
            },
            {
                "value": 15015,
                "label": "Silva",
                "label_ar": "سيلفا",
                "label_fr": "Silva"
            },
            {
                "value": 15016,
                "label": "Silvana",
                "label_ar": "سيلفانا",
                "label_fr": "Silvana"
            },
            {
                "value": 15017,
                "label": "Silver Bay",
                "label_ar": "سيلفر باي",
                "label_fr": "Silver Bay"
            },
            {
                "value": 15018,
                "label": "Silver City",
                "label_ar": "سيلفر سيتي",
                "label_fr": "Silver City"
            },
            {
                "value": 15019,
                "label": "Silver Creek",
                "label_ar": "سيلفر كريك",
                "label_fr": "Silver Creek"
            },
            {
                "value": 15020,
                "label": "Silver Grove",
                "label_ar": "سيلفر جروف",
                "label_fr": "Silver Grove"
            },
            {
                "value": 15021,
                "label": "Silver Lake",
                "label_ar": "بحيرة سيلفر",
                "label_fr": "Lac d'Argent"
            },
            {
                "value": 15022,
                "label": "Silver Spring",
                "label_ar": "الربيع الفضي",
                "label_fr": "Silver Spring"
            },
            {
                "value": 15023,
                "label": "Silver Springs",
                "label_ar": "سيلفر سبرينغز",
                "label_fr": "Silver Springs"
            },
            {
                "value": 15024,
                "label": "Silverado",
                "label_ar": "سيلفرادو",
                "label_fr": "Silverado"
            },
            {
                "value": 15025,
                "label": "Silverdale",
                "label_ar": "سيلفرديل",
                "label_fr": "Silverdale"
            },
            {
                "value": 15026,
                "label": "Silverhill",
                "label_ar": "سيلفرهيل",
                "label_fr": "Silverhill"
            },
            {
                "value": 15027,
                "label": "Silverstreet",
                "label_ar": "سيلفر ستريت",
                "label_fr": "Silverstreet"
            },
            {
                "value": 15028,
                "label": "Silverthorne",
                "label_ar": "سيلفرثورن",
                "label_fr": "Silverthorne"
            },
            {
                "value": 15029,
                "label": "Silverton",
                "label_ar": "سيلفرتون",
                "label_fr": "Silverton"
            },
            {
                "value": 15030,
                "label": "Silvis",
                "label_ar": "سيلفيس",
                "label_fr": "Silvis"
            },
            {
                "value": 15031,
                "label": "Simi Valley",
                "label_ar": "وادي سيمي",
                "label_fr": "Vallée de Simi"
            },
            {
                "value": 15032,
                "label": "Simla",
                "label_ar": "شيملا",
                "label_fr": "Simla"
            },
            {
                "value": 15033,
                "label": "Simmesport",
                "label_ar": "سيمسبورت",
                "label_fr": "Simmesport"
            },
            {
                "value": 15034,
                "label": "Simms",
                "label_ar": "سيمز",
                "label_fr": "Simms"
            },
            {
                "value": 15035,
                "label": "Simon",
                "label_ar": "سيمون",
                "label_fr": "Simon"
            },
            {
                "value": 15036,
                "label": "Simonton",
                "label_ar": "سيمونتون",
                "label_fr": "Simonton"
            },
            {
                "value": 15037,
                "label": "Simpson",
                "label_ar": "سيمبسون",
                "label_fr": "Simpson"
            },
            {
                "value": 15038,
                "label": "Simpsonville",
                "label_ar": "سيمبسونفيل",
                "label_fr": "Simpsonville"
            },
            {
                "value": 15039,
                "label": "Sims",
                "label_ar": "سيمز",
                "label_fr": "Sims"
            },
            {
                "value": 15040,
                "label": "Simsboro",
                "label_ar": "سيمسبورو",
                "label_fr": "Simsboro"
            },
            {
                "value": 15041,
                "label": "Simsbury",
                "label_ar": "سيمسبري",
                "label_fr": "Simsbury"
            },
            {
                "value": 15042,
                "label": "Sinclair",
                "label_ar": "سنكلير",
                "label_fr": "Sinclair"
            },
            {
                "value": 15043,
                "label": "Sinclairville",
                "label_ar": "سينكليرفيل",
                "label_fr": "Sinclairville"
            },
            {
                "value": 15044,
                "label": "Sinks Grove",
                "label_ar": "مغاسل غروف",
                "label_fr": "Éviers Grove"
            },
            {
                "value": 15045,
                "label": "Sinton",
                "label_ar": "سينتون",
                "label_fr": "Sinton"
            },
            {
                "value": 15046,
                "label": "Sioux Center",
                "label_ar": "مركز سيوكس",
                "label_fr": "Centre Sioux"
            },
            {
                "value": 15047,
                "label": "Sioux City",
                "label_ar": "مدينة سيوكس",
                "label_fr": "Sioux City"
            },
            {
                "value": 15048,
                "label": "Sioux Falls",
                "label_ar": "شلالات سيوكس",
                "label_fr": "Sioux Falls"
            },
            {
                "value": 15049,
                "label": "Sioux Rapids",
                "label_ar": "سيوكس رابيدز",
                "label_fr": "Sioux Rapids"
            },
            {
                "value": 15050,
                "label": "Sipesville",
                "label_ar": "سايبسفيل",
                "label_fr": "Sipesville"
            },
            {
                "value": 15051,
                "label": "Sipsey",
                "label_ar": "سيبسي",
                "label_fr": "Sipsey"
            },
            {
                "value": 15052,
                "label": "Siren",
                "label_ar": "صفارة إنذار",
                "label_fr": "Sirène"
            },
            {
                "value": 15053,
                "label": "Sisseton",
                "label_ar": "سيستون",
                "label_fr": "Sisseton"
            },
            {
                "value": 15054,
                "label": "Sister Bay",
                "label_ar": "سيستر باي",
                "label_fr": "Sœur Bay"
            },
            {
                "value": 15055,
                "label": "Sisters",
                "label_ar": "أخوات",
                "label_fr": "Sœurs"
            },
            {
                "value": 15056,
                "label": "Sistersville",
                "label_ar": "سيسترزفيل",
                "label_fr": "Sistersville"
            },
            {
                "value": 15057,
                "label": "Sitka",
                "label_ar": "سيتكا",
                "label_fr": "Sitka"
            },
            {
                "value": 15058,
                "label": "Six Lakes",
                "label_ar": "ست بحيرات",
                "label_fr": "Six lacs"
            },
            {
                "value": 15059,
                "label": "Six Mile",
                "label_ar": "ستة أميال",
                "label_fr": "Six milles"
            },
            {
                "value": 15060,
                "label": "Skaguay",
                "label_ar": "سكاجواي",
                "label_fr": "Skaguay"
            },
            {
                "value": 15061,
                "label": "Skamokawa",
                "label_ar": "سكاموكاوا",
                "label_fr": "Skamokawa"
            },
            {
                "value": 15062,
                "label": "Skandia",
                "label_ar": "سكانديا",
                "label_fr": "Skandia"
            },
            {
                "value": 15063,
                "label": "Skaneateles",
                "label_ar": "Skaneateles",
                "label_fr": "Skaneateles"
            },
            {
                "value": 15064,
                "label": "Skaneateles Falls",
                "label_ar": "شلالات Skaneateles",
                "label_fr": "Chutes de Skaneateles"
            },
            {
                "value": 15065,
                "label": "Skanee",
                "label_ar": "سكانى",
                "label_fr": "Skanee"
            },
            {
                "value": 15066,
                "label": "Skellytown",
                "label_ar": "سكيليتاون",
                "label_fr": "Skellytown"
            },
            {
                "value": 15067,
                "label": "Skiatook",
                "label_ar": "سكياتوك",
                "label_fr": "Skiatook"
            },
            {
                "value": 15068,
                "label": "Skidmore",
                "label_ar": "سكيدمور",
                "label_fr": "Skidmore"
            },
            {
                "value": 15069,
                "label": "Skillman",
                "label_ar": "سكيلمان",
                "label_fr": "Skillman"
            },
            {
                "value": 15070,
                "label": "Skippack",
                "label_ar": "Skippack",
                "label_fr": "Skippack"
            },
            {
                "value": 15071,
                "label": "Skokie",
                "label_ar": "سكوكي",
                "label_fr": "Skokie"
            },
            {
                "value": 15072,
                "label": "Skowhegan",
                "label_ar": "Skowhegan",
                "label_fr": "Skowhegan"
            },
            {
                "value": 15073,
                "label": "Skyforest",
                "label_ar": "سكاي فورست",
                "label_fr": "Skyforest"
            },
            {
                "value": 15074,
                "label": "Skykomish",
                "label_ar": "سكاي كوميش",
                "label_fr": "Skykomish"
            },
            {
                "value": 15075,
                "label": "Skyland",
                "label_ar": "سكايلاند",
                "label_fr": "Skyland"
            },
            {
                "value": 15076,
                "label": "Slagle",
                "label_ar": "الخبث",
                "label_fr": "Slagle"
            },
            {
                "value": 15077,
                "label": "Slanesville",
                "label_ar": "سلانسفيل",
                "label_fr": "Slanesville"
            },
            {
                "value": 15078,
                "label": "Slate Hill",
                "label_ar": "سليت هيل",
                "label_fr": "Slate Hill"
            },
            {
                "value": 15079,
                "label": "Slater",
                "label_ar": "سلاتر",
                "label_fr": "Couvreur"
            },
            {
                "value": 15080,
                "label": "Slatersville",
                "label_ar": "سلاترسفيل",
                "label_fr": "Slatersville"
            },
            {
                "value": 15081,
                "label": "Slatington",
                "label_ar": "سلاتينجتون",
                "label_fr": "Slatington"
            },
            {
                "value": 15082,
                "label": "Slaton",
                "label_ar": "سلاتون",
                "label_fr": "Slaton"
            },
            {
                "value": 15083,
                "label": "Slaty Fork",
                "label_ar": "سلاتي شوكة",
                "label_fr": "Fourchette Slaty"
            },
            {
                "value": 15084,
                "label": "Slaughter",
                "label_ar": "ذبح",
                "label_fr": "Abattage"
            },
            {
                "value": 15085,
                "label": "Slaughters",
                "label_ar": "ذبح",
                "label_fr": "Abattages"
            },
            {
                "value": 15086,
                "label": "Slayton",
                "label_ar": "سلايتون",
                "label_fr": "Slayton"
            },
            {
                "value": 15087,
                "label": "Sleepy Eye",
                "label_ar": "عين نعسان",
                "label_fr": "Œil endormi"
            },
            {
                "value": 15088,
                "label": "Slidell",
                "label_ar": "سليديل",
                "label_fr": "Slidell"
            },
            {
                "value": 15089,
                "label": "Sligo",
                "label_ar": "سليجو",
                "label_fr": "Sligo"
            },
            {
                "value": 15090,
                "label": "Slinger",
                "label_ar": "سلينجر",
                "label_fr": "Slinger"
            },
            {
                "value": 15091,
                "label": "Slingerlands",
                "label_ar": "سلينجرلاندز",
                "label_fr": "Slingerlands"
            },
            {
                "value": 15092,
                "label": "Slippery Rock",
                "label_ar": "صخرة زلقة",
                "label_fr": "Rocher glissant"
            },
            {
                "value": 15093,
                "label": "Sloan",
                "label_ar": "سلون",
                "label_fr": "Sloan"
            },
            {
                "value": 15094,
                "label": "Sloansville",
                "label_ar": "سلونسفيل",
                "label_fr": "Sloansville"
            },
            {
                "value": 15095,
                "label": "Sloatsburg",
                "label_ar": "سلواتسبيرغ",
                "label_fr": "Sloatsburg"
            },
            {
                "value": 15096,
                "label": "Slocomb",
                "label_ar": "سلوكومب",
                "label_fr": "Slocomb"
            },
            {
                "value": 15097,
                "label": "Sloughhouse",
                "label_ar": "سلوهاوس",
                "label_fr": "Sloughhouse"
            },
            {
                "value": 15098,
                "label": "Slovan",
                "label_ar": "سلوفان",
                "label_fr": "Slovan"
            },
            {
                "value": 15099,
                "label": "Smackover",
                "label_ar": "سماكوفر",
                "label_fr": "Smackover"
            },
            {
                "value": 15100,
                "label": "Smarr",
                "label_ar": "Smarr",
                "label_fr": "Smarr"
            },
            {
                "value": 15101,
                "label": "Smartt",
                "label_ar": "سمارت",
                "label_fr": "Smartt"
            },
            {
                "value": 15102,
                "label": "Smethport",
                "label_ar": "سميثبورت",
                "label_fr": "Smethport"
            },
            {
                "value": 15103,
                "label": "Smilax",
                "label_ar": "سمايلاكس",
                "label_fr": "Smilax"
            },
            {
                "value": 15104,
                "label": "Smiley",
                "label_ar": "مبتسم",
                "label_fr": "Smiley"
            },
            {
                "value": 15105,
                "label": "Smith Center",
                "label_ar": "مركز سميث",
                "label_fr": "Smith Center"
            },
            {
                "value": 15106,
                "label": "Smith River",
                "label_ar": "نهر سميث",
                "label_fr": "Smith River"
            },
            {
                "value": 15107,
                "label": "Smithers",
                "label_ar": "سميثرز",
                "label_fr": "Smithers"
            },
            {
                "value": 15108,
                "label": "Smithfield",
                "label_ar": "سميثفيلد",
                "label_fr": "Smithfield"
            },
            {
                "value": 15109,
                "label": "Smithland",
                "label_ar": "سميثلاند",
                "label_fr": "Smithland"
            },
            {
                "value": 15110,
                "label": "Smiths",
                "label_ar": "سميث",
                "label_fr": "Smiths"
            },
            {
                "value": 15111,
                "label": "Smiths Creek",
                "label_ar": "سميث كريك",
                "label_fr": "Smiths Creek"
            },
            {
                "value": 15112,
                "label": "Smiths Grove",
                "label_ar": "سميثز جروف",
                "label_fr": "Smiths Grove"
            },
            {
                "value": 15113,
                "label": "Smithsburg",
                "label_ar": "سميثسبورغ",
                "label_fr": "Smithsburg"
            },
            {
                "value": 15114,
                "label": "Smithshire",
                "label_ar": "سميثشاير",
                "label_fr": "Smithshire"
            },
            {
                "value": 15115,
                "label": "Smithton",
                "label_ar": "سميثتون",
                "label_fr": "Smithton"
            },
            {
                "value": 15116,
                "label": "Smithtown",
                "label_ar": "سميثتاون",
                "label_fr": "Smithtown"
            },
            {
                "value": 15117,
                "label": "Smithville",
                "label_ar": "سميثفيل",
                "label_fr": "Smithville"
            },
            {
                "value": 15118,
                "label": "Smithville Flats",
                "label_ar": "سميثفيل فلاتس",
                "label_fr": "Appartements Smithville"
            },
            {
                "value": 15119,
                "label": "Smoaks",
                "label_ar": "Smoaks",
                "label_fr": "Smoaks"
            },
            {
                "value": 15120,
                "label": "Smock",
                "label_ar": "ثوب",
                "label_fr": "Blouse"
            },
            {
                "value": 15121,
                "label": "Smoke Run",
                "label_ar": "تشغيل الدخان",
                "label_fr": "Fumée"
            },
            {
                "value": 15122,
                "label": "Smoketown",
                "label_ar": "سموك تاون",
                "label_fr": "Smoketown"
            },
            {
                "value": 15123,
                "label": "Smyer",
                "label_ar": "سمير",
                "label_fr": "Smyer"
            },
            {
                "value": 15124,
                "label": "Smyrna",
                "label_ar": "سميرنا",
                "label_fr": "Smyrne"
            },
            {
                "value": 15125,
                "label": "Smyrna Mills",
                "label_ar": "سميرنا ميلز",
                "label_fr": "Smyrna Mills"
            },
            {
                "value": 15126,
                "label": "Sneads",
                "label_ar": "سنيدس",
                "label_fr": "Sneads"
            },
            {
                "value": 15127,
                "label": "Sneads Ferry",
                "label_ar": "سنيدز فيري",
                "label_fr": "Sneads Ferry"
            },
            {
                "value": 15128,
                "label": "Sneedville",
                "label_ar": "سنيدفيل",
                "label_fr": "Sneedville"
            },
            {
                "value": 15129,
                "label": "Snelling",
                "label_ar": "شم",
                "label_fr": "Snelling"
            },
            {
                "value": 15130,
                "label": "Snellville",
                "label_ar": "سنلفيل",
                "label_fr": "Snellville"
            },
            {
                "value": 15131,
                "label": "Snohomish",
                "label_ar": "سنوهوميش",
                "label_fr": "Snohomish"
            },
            {
                "value": 15132,
                "label": "Snoqualmie",
                "label_ar": "Snoqualmie",
                "label_fr": "Snoqualmie"
            },
            {
                "value": 15133,
                "label": "Snover",
                "label_ar": "سنوفر",
                "label_fr": "Snover"
            },
            {
                "value": 15134,
                "label": "Snow Camp",
                "label_ar": "مخيم الثلج",
                "label_fr": "Camp de neige"
            },
            {
                "value": 15135,
                "label": "Snow Hill",
                "label_ar": "سنو هيل",
                "label_fr": "Snow Hill"
            },
            {
                "value": 15136,
                "label": "Snow Shoe",
                "label_ar": "حذاء الثلج",
                "label_fr": "Raquette à neige"
            },
            {
                "value": 15137,
                "label": "Snowflake",
                "label_ar": "ندفة الثلج",
                "label_fr": "Flocon de neige"
            },
            {
                "value": 15138,
                "label": "Snowmass",
                "label_ar": "سنوماس",
                "label_fr": "Snowmass"
            },
            {
                "value": 15139,
                "label": "Snowmass Village",
                "label_ar": "قرية سنوماس",
                "label_fr": "Village de Snowmass"
            },
            {
                "value": 15140,
                "label": "Snyder",
                "label_ar": "سنايدر",
                "label_fr": "Snyder"
            },
            {
                "value": 15141,
                "label": "Soap Lake",
                "label_ar": "بحيرة الصابون",
                "label_fr": "Soap Lake"
            },
            {
                "value": 15142,
                "label": "Sobieski",
                "label_ar": "سوبيسكي",
                "label_fr": "Sobieski"
            },
            {
                "value": 15143,
                "label": "Social Circle",
                "label_ar": "الدائرة الاجتماعية",
                "label_fr": "Cercle social"
            },
            {
                "value": 15144,
                "label": "Society Hill",
                "label_ar": "مجتمع هيل",
                "label_fr": "Society Hill"
            },
            {
                "value": 15145,
                "label": "Socorro",
                "label_ar": "سوكورو",
                "label_fr": "Socorro"
            },
            {
                "value": 15146,
                "label": "Sod",
                "label_ar": "الاحمق",
                "label_fr": "Gazon"
            },
            {
                "value": 15147,
                "label": "Soda Springs",
                "label_ar": "الصودا سبرينغز",
                "label_fr": "Soda Springs"
            },
            {
                "value": 15148,
                "label": "Soddy-Daisy",
                "label_ar": "سودي ديزي",
                "label_fr": "Soddy-Daisy"
            },
            {
                "value": 15149,
                "label": "Sodus",
                "label_ar": "سدس",
                "label_fr": "Sodus"
            },
            {
                "value": 15150,
                "label": "Solana Beach",
                "label_ar": "شاطئ سولانا",
                "label_fr": "Plage de Solana"
            },
            {
                "value": 15151,
                "label": "Soldier",
                "label_ar": "جندي",
                "label_fr": "Soldat"
            },
            {
                "value": 15152,
                "label": "Soldiers Grove",
                "label_ar": "جنود غروف",
                "label_fr": "Soldats Grove"
            },
            {
                "value": 15153,
                "label": "Soldotna",
                "label_ar": "سولدوتنا",
                "label_fr": "Soldotna"
            },
            {
                "value": 15154,
                "label": "Solebury",
                "label_ar": "سوليبوري",
                "label_fr": "Solebury"
            },
            {
                "value": 15155,
                "label": "Soledad",
                "label_ar": "سوليداد",
                "label_fr": "Soledad"
            },
            {
                "value": 15156,
                "label": "Solen",
                "label_ar": "سولين",
                "label_fr": "Solen"
            },
            {
                "value": 15157,
                "label": "Solomons",
                "label_ar": "سليمان",
                "label_fr": "Salomon"
            },
            {
                "value": 15158,
                "label": "Solon",
                "label_ar": "سولون",
                "label_fr": "Solon"
            },
            {
                "value": 15159,
                "label": "Solon Springs",
                "label_ar": "سولون سبرينغز",
                "label_fr": "Solon Springs"
            },
            {
                "value": 15160,
                "label": "Solsberry",
                "label_ar": "سولسبيري",
                "label_fr": "Solsberry"
            },
            {
                "value": 15161,
                "label": "Solvang",
                "label_ar": "سولفانغ",
                "label_fr": "Solvang"
            },
            {
                "value": 15162,
                "label": "Solway",
                "label_ar": "سولواي",
                "label_fr": "Solway"
            },
            {
                "value": 15163,
                "label": "Somerdale",
                "label_ar": "سومردال",
                "label_fr": "Somerdale"
            },
            {
                "value": 15164,
                "label": "Somers",
                "label_ar": "سومرز",
                "label_fr": "Somers"
            },
            {
                "value": 15165,
                "label": "Somers Point",
                "label_ar": "سومرز بوينت",
                "label_fr": "Somers Point"
            },
            {
                "value": 15166,
                "label": "Somerset",
                "label_ar": "سومرست",
                "label_fr": "Somerset"
            },
            {
                "value": 15167,
                "label": "Somerset Center",
                "label_ar": "مركز سومرست",
                "label_fr": "Centre Somerset"
            },
            {
                "value": 15168,
                "label": "Somersworth",
                "label_ar": "سومرسورث",
                "label_fr": "Somersworth"
            },
            {
                "value": 15169,
                "label": "Somerton",
                "label_ar": "سوميرتون",
                "label_fr": "Somerton"
            },
            {
                "value": 15170,
                "label": "Somerville",
                "label_ar": "سومرفيل",
                "label_fr": "Somerville"
            },
            {
                "value": 15171,
                "label": "Somis",
                "label_ar": "سوميس",
                "label_fr": "Somis"
            },
            {
                "value": 15172,
                "label": "Somonauk",
                "label_ar": "سومونوك",
                "label_fr": "Somonauk"
            },
            {
                "value": 15173,
                "label": "Sonoita",
                "label_ar": "سونويتا",
                "label_fr": "Sonoita"
            },
            {
                "value": 15174,
                "label": "Sonoma",
                "label_ar": "سونوما",
                "label_fr": "Sonoma"
            },
            {
                "value": 15175,
                "label": "Sonora",
                "label_ar": "سونورا",
                "label_fr": "Sonora"
            },
            {
                "value": 15176,
                "label": "Sopchoppy",
                "label_ar": "سوبشوبي",
                "label_fr": "Sopchoppy"
            },
            {
                "value": 15177,
                "label": "Soper",
                "label_ar": "سوبر",
                "label_fr": "Soper"
            },
            {
                "value": 15178,
                "label": "Soperton",
                "label_ar": "سوبيرتون",
                "label_fr": "Soperton"
            },
            {
                "value": 15179,
                "label": "Sophia",
                "label_ar": "صوفيا",
                "label_fr": "Sophia"
            },
            {
                "value": 15180,
                "label": "Soquel",
                "label_ar": "سوكويل",
                "label_fr": "Soquel"
            },
            {
                "value": 15181,
                "label": "Sorento",
                "label_ar": "سورينتو",
                "label_fr": "Sorento"
            },
            {
                "value": 15182,
                "label": "Sorrento",
                "label_ar": "سورينتو",
                "label_fr": "Sorrento"
            },
            {
                "value": 15183,
                "label": "Soso",
                "label_ar": "لا بأس",
                "label_fr": "Comme ci comme ça"
            },
            {
                "value": 15184,
                "label": "Soudan",
                "label_ar": "السودان",
                "label_fr": "Soudan"
            },
            {
                "value": 15185,
                "label": "Souderton",
                "label_ar": "سودرتون",
                "label_fr": "Souderton"
            },
            {
                "value": 15186,
                "label": "Soulsbyville",
                "label_ar": "سولسبيفيل",
                "label_fr": "Soulsbyville"
            },
            {
                "value": 15187,
                "label": "Sound Beach",
                "label_ar": "ساوند بيتش",
                "label_fr": "Plage Sound"
            },
            {
                "value": 15188,
                "label": "Sour Lake",
                "label_ar": "بحيرة صور",
                "label_fr": "Lac Sour"
            },
            {
                "value": 15189,
                "label": "South Amboy",
                "label_ar": "جنوب امبوي",
                "label_fr": "Sud Amboy"
            },
            {
                "value": 15190,
                "label": "South Barre",
                "label_ar": "جنوب باري",
                "label_fr": "Barre sud"
            },
            {
                "value": 15191,
                "label": "South Bay",
                "label_ar": "جنوب خليج",
                "label_fr": "South Bay"
            },
            {
                "value": 15192,
                "label": "South Beach",
                "label_ar": "الشاطىء الجنوبى",
                "label_fr": "plage sud"
            },
            {
                "value": 15193,
                "label": "South Beloit",
                "label_ar": "جنوب بلويت",
                "label_fr": "Sud Beloit"
            },
            {
                "value": 15194,
                "label": "South Bend",
                "label_ar": "جنوب بيند",
                "label_fr": "South Bend"
            },
            {
                "value": 15195,
                "label": "South Berwick",
                "label_ar": "ساوث بيرويك",
                "label_fr": "South Berwick"
            },
            {
                "value": 15196,
                "label": "South Boardman",
                "label_ar": "جنوب بوردمان",
                "label_fr": "South Boardman"
            },
            {
                "value": 15197,
                "label": "South Boston",
                "label_ar": "جنوب بوسطن",
                "label_fr": "South Boston"
            },
            {
                "value": 15198,
                "label": "South Bound Brook",
                "label_ar": "ساوث باوند بروك",
                "label_fr": "Ruisseau South Bound"
            },
            {
                "value": 15199,
                "label": "South Bristol",
                "label_ar": "جنوب بريستول",
                "label_fr": "South Bristol"
            },
            {
                "value": 15200,
                "label": "South Burlington",
                "label_ar": "جنوب برلنغتون",
                "label_fr": "South Burlington"
            },
            {
                "value": 15201,
                "label": "South Cairo",
                "label_ar": "جنوب القاهرة",
                "label_fr": "Le sud du Caire"
            },
            {
                "value": 15202,
                "label": "South Canaan",
                "label_ar": "جنوب كنعان",
                "label_fr": "Sud de Canaan"
            },
            {
                "value": 15203,
                "label": "South Casco",
                "label_ar": "جنوب كاسكو",
                "label_fr": "Sud Casco"
            },
            {
                "value": 15204,
                "label": "South Charleston",
                "label_ar": "جنوب تشارلستون",
                "label_fr": "South Charleston"
            },
            {
                "value": 15205,
                "label": "South China",
                "label_ar": "جنوب الصين",
                "label_fr": "Chine du sud"
            },
            {
                "value": 15206,
                "label": "South Dartmouth",
                "label_ar": "جنوب دارتموث",
                "label_fr": "South Dartmouth"
            },
            {
                "value": 15207,
                "label": "South Deerfield",
                "label_ar": "جنوب ديرفيلد",
                "label_fr": "South Deerfield"
            },
            {
                "value": 15208,
                "label": "South Dennis",
                "label_ar": "جنوب دينيس",
                "label_fr": "South Dennis"
            },
            {
                "value": 15209,
                "label": "South Easton",
                "label_ar": "جنوب ايستون",
                "label_fr": "South Easton"
            },
            {
                "value": 15210,
                "label": "South Egremont",
                "label_ar": "جنوب إيغريمونت",
                "label_fr": "Sud Egremont"
            },
            {
                "value": 15211,
                "label": "South El Monte",
                "label_ar": "جنوب المونت",
                "label_fr": "Sud El Monte"
            },
            {
                "value": 15212,
                "label": "South Elgin",
                "label_ar": "جنوب الجين",
                "label_fr": "South Elgin"
            },
            {
                "value": 15213,
                "label": "South English",
                "label_ar": "جنوب الإنجليزية",
                "label_fr": "Anglais du sud"
            },
            {
                "value": 15214,
                "label": "South Fallsburg",
                "label_ar": "جنوب فولسبورغ",
                "label_fr": "South Fallsburg"
            },
            {
                "value": 15215,
                "label": "South Fork",
                "label_ar": "ساوث فورك",
                "label_fr": "South Fork"
            },
            {
                "value": 15216,
                "label": "South Freeport",
                "label_ar": "جنوب فريبورت",
                "label_fr": "South Freeport"
            },
            {
                "value": 15217,
                "label": "South Fulton",
                "label_ar": "ساوث فولتون",
                "label_fr": "Sud Fulton"
            },
            {
                "value": 15218,
                "label": "South Gate",
                "label_ar": "البوابة الجنوبية",
                "label_fr": "Porte sud"
            },
            {
                "value": 15219,
                "label": "South Gibson",
                "label_ar": "جنوب جيبسون",
                "label_fr": "South Gibson"
            },
            {
                "value": 15220,
                "label": "South Glastonbury",
                "label_ar": "جنوب غلاستونبري",
                "label_fr": "South Glastonbury"
            },
            {
                "value": 15221,
                "label": "South Glens Falls",
                "label_ar": "ساوث جلينز فولز",
                "label_fr": "South Glens Falls"
            },
            {
                "value": 15222,
                "label": "South Hackensack",
                "label_ar": "ساوث هاكنساك",
                "label_fr": "South Hackensack"
            },
            {
                "value": 15223,
                "label": "South Hadley",
                "label_ar": "جنوب هادلي",
                "label_fr": "South Hadley"
            },
            {
                "value": 15224,
                "label": "South Hamilton",
                "label_ar": "ساوث هاميلتون",
                "label_fr": "Hamilton Sud"
            },
            {
                "value": 15225,
                "label": "South Harwich",
                "label_ar": "جنوب هارويش",
                "label_fr": "South Harwich"
            },
            {
                "value": 15226,
                "label": "South Haven",
                "label_ar": "ساوث هيفن",
                "label_fr": "South Haven"
            },
            {
                "value": 15227,
                "label": "South Hero",
                "label_ar": "بطل الجنوب",
                "label_fr": "Héros du sud"
            },
            {
                "value": 15228,
                "label": "South Hill",
                "label_ar": "ساوث هيل",
                "label_fr": "South Hill"
            },
            {
                "value": 15229,
                "label": "South Holland",
                "label_ar": "جنوب هولندا",
                "label_fr": "Hollande méridionale"
            },
            {
                "value": 15230,
                "label": "South Houston",
                "label_ar": "جنوب هيوستن",
                "label_fr": "South Houston"
            },
            {
                "value": 15231,
                "label": "South Hutchinson",
                "label_ar": "جنوب هاتشينسون",
                "label_fr": "South Hutchinson"
            },
            {
                "value": 15232,
                "label": "South Jamesport",
                "label_ar": "جنوب جيمسبورت",
                "label_fr": "South Jamesport"
            },
            {
                "value": 15233,
                "label": "South Jordan",
                "label_ar": "جنوب الاردن",
                "label_fr": "Sud de la Jordanie"
            },
            {
                "value": 15234,
                "label": "South Kent",
                "label_ar": "جنوب كينت",
                "label_fr": "Sud du Kent"
            },
            {
                "value": 15235,
                "label": "South Lake Tahoe",
                "label_ar": "ساوث ليك تاهو",
                "label_fr": "South Lake Tahoe"
            },
            {
                "value": 15236,
                "label": "South Lancaster",
                "label_ar": "جنوب لانكستر",
                "label_fr": "South Lancaster"
            },
            {
                "value": 15237,
                "label": "South Lebanon",
                "label_ar": "جنوب لبنان",
                "label_fr": "Liban sud"
            },
            {
                "value": 15238,
                "label": "South Londonderry",
                "label_ar": "جنوب لندنديري",
                "label_fr": "South Londonderry"
            },
            {
                "value": 15239,
                "label": "South Lyon",
                "label_ar": "جنوب ليون",
                "label_fr": "Sud de Lyon"
            },
            {
                "value": 15240,
                "label": "South Mills",
                "label_ar": "مطاحن الجنوب",
                "label_fr": "South Mills"
            },
            {
                "value": 15241,
                "label": "South Milwaukee",
                "label_ar": "جنوب ميلووكي",
                "label_fr": "South Milwaukee"
            },
            {
                "value": 15242,
                "label": "South New Berlin",
                "label_ar": "جنوب نيو برلين",
                "label_fr": "Sud de New Berlin"
            },
            {
                "value": 15243,
                "label": "South Newfane",
                "label_ar": "جنوب نيوفان",
                "label_fr": "South Newfane"
            },
            {
                "value": 15244,
                "label": "South Orange",
                "label_ar": "جنوب أورانج",
                "label_fr": "Orange du Sud"
            },
            {
                "value": 15245,
                "label": "South Otselic",
                "label_ar": "جنوب أوتسيليك",
                "label_fr": "Sud Otselic"
            },
            {
                "value": 15246,
                "label": "South Ozone Park",
                "label_ar": "حديقة الأوزون الجنوبية",
                "label_fr": "South Ozone Park"
            },
            {
                "value": 15247,
                "label": "South Padre Island",
                "label_ar": "جزيرة ساوث بادري",
                "label_fr": "Île de South Padre"
            },
            {
                "value": 15248,
                "label": "South Paris",
                "label_ar": "جنوب باريس",
                "label_fr": "Sud de Paris"
            },
            {
                "value": 15249,
                "label": "South Park Township",
                "label_ar": "ساوث بارك تاونشيب",
                "label_fr": "Canton de South Park"
            },
            {
                "value": 15250,
                "label": "South Pasadena",
                "label_ar": "جنوب باسادينا",
                "label_fr": "South Pasadena"
            },
            {
                "value": 15251,
                "label": "South Pekin",
                "label_ar": "جنوب بيكين",
                "label_fr": "Sud de Pékin"
            },
            {
                "value": 15252,
                "label": "South Pittsburg",
                "label_ar": "جنوب بيتسبرج",
                "label_fr": "South Pittsburg"
            },
            {
                "value": 15253,
                "label": "South Plainfield",
                "label_ar": "جنوب بلينفيلد",
                "label_fr": "South Plainfield"
            },
            {
                "value": 15254,
                "label": "South Plymouth",
                "label_ar": "جنوب بليموث",
                "label_fr": "South Plymouth"
            },
            {
                "value": 15255,
                "label": "South Point",
                "label_ar": "ساوث بوينت",
                "label_fr": "South Point"
            },
            {
                "value": 15256,
                "label": "South Pomfret",
                "label_ar": "جنوب بومفريت",
                "label_fr": "Sud Pomfret"
            },
            {
                "value": 15257,
                "label": "South Portland",
                "label_ar": "جنوب بورتلاند",
                "label_fr": "South Portland"
            },
            {
                "value": 15258,
                "label": "South Prairie",
                "label_ar": "جنوب البراري",
                "label_fr": "South Prairie"
            },
            {
                "value": 15259,
                "label": "South Range",
                "label_ar": "المدى الجنوبي",
                "label_fr": "Gamme sud"
            },
            {
                "value": 15260,
                "label": "South River",
                "label_ar": "نهر الجنوب",
                "label_fr": "South River"
            },
            {
                "value": 15261,
                "label": "South Rockwood",
                "label_ar": "ساوث روكوود",
                "label_fr": "South Rockwood"
            },
            {
                "value": 15262,
                "label": "South Roxana",
                "label_ar": "جنوب روكسانا",
                "label_fr": "South Roxana"
            },
            {
                "value": 15263,
                "label": "South Royalton",
                "label_ar": "جنوب رويالتون",
                "label_fr": "South Royalton"
            },
            {
                "value": 15264,
                "label": "South Ryegate",
                "label_ar": "جنوب رايجيت",
                "label_fr": "South Ryegate"
            },
            {
                "value": 15265,
                "label": "South Saint Paul",
                "label_ar": "جنوب سانت بول",
                "label_fr": "Sud Saint Paul"
            },
            {
                "value": 15266,
                "label": "South Salem",
                "label_ar": "جنوب سالم",
                "label_fr": "Sud de Salem"
            },
            {
                "value": 15267,
                "label": "South Sanford",
                "label_ar": "جنوب سانفورد",
                "label_fr": "South Sanford"
            },
            {
                "value": 15268,
                "label": "South Seaville",
                "label_ar": "جنوب سيفيل",
                "label_fr": "South Seaville"
            },
            {
                "value": 15269,
                "label": "South Shore",
                "label_ar": "ساوث شور",
                "label_fr": "rive sud"
            },
            {
                "value": 15270,
                "label": "South Sioux City",
                "label_ar": "جنوب مدينة سيوكس",
                "label_fr": "South Sioux City"
            },
            {
                "value": 15271,
                "label": "South Solon",
                "label_ar": "جنوب سولون",
                "label_fr": "Sud Solon"
            },
            {
                "value": 15272,
                "label": "South Sterling",
                "label_ar": "الجنيه الاسترليني الجنوبي",
                "label_fr": "Sud Sterling"
            },
            {
                "value": 15273,
                "label": "South Strafford",
                "label_ar": "ساوث سترافورد",
                "label_fr": "South Strafford"
            },
            {
                "value": 15274,
                "label": "South Tamworth",
                "label_ar": "جنوب تامورث",
                "label_fr": "South Tamworth"
            },
            {
                "value": 15275,
                "label": "South Vienna",
                "label_ar": "جنوب فيينا",
                "label_fr": "Sud de Vienne"
            },
            {
                "value": 15276,
                "label": "South Wales",
                "label_ar": "جنوب ويلز",
                "label_fr": "Galles du Sud"
            },
            {
                "value": 15277,
                "label": "South Wayne",
                "label_ar": "ساوث واين",
                "label_fr": "South Wayne"
            },
            {
                "value": 15278,
                "label": "South Webster",
                "label_ar": "جنوب ويبستر",
                "label_fr": "South Webster"
            },
            {
                "value": 15279,
                "label": "South Wellfleet",
                "label_ar": "جنوب ويلفليت",
                "label_fr": "South Wellfleet"
            },
            {
                "value": 15280,
                "label": "South West City",
                "label_ar": "جنوب غرب المدينة",
                "label_fr": "South West City"
            },
            {
                "value": 15281,
                "label": "South Weymouth",
                "label_ar": "جنوب ويموث",
                "label_fr": "South Weymouth"
            },
            {
                "value": 15282,
                "label": "South Whitley",
                "label_ar": "ساوث ويتلي",
                "label_fr": "South Whitley"
            },
            {
                "value": 15283,
                "label": "South Williamson",
                "label_ar": "جنوب ويليامسون",
                "label_fr": "South Williamson"
            },
            {
                "value": 15284,
                "label": "South Willington",
                "label_ar": "جنوب ويلينجتون",
                "label_fr": "South Willington"
            },
            {
                "value": 15285,
                "label": "South Wilmington",
                "label_ar": "جنوب ويلمنجتون",
                "label_fr": "South Wilmington"
            },
            {
                "value": 15286,
                "label": "South Windsor",
                "label_ar": "وندسور الجنوبية",
                "label_fr": "South Windsor"
            },
            {
                "value": 15287,
                "label": "South Woodstock",
                "label_ar": "جنوب وودستوك",
                "label_fr": "South Woodstock"
            },
            {
                "value": 15288,
                "label": "South Yarmouth",
                "label_ar": "جنوب يارماوث",
                "label_fr": "South Yarmouth"
            },
            {
                "value": 15289,
                "label": "Southampton",
                "label_ar": "ساوثهامبتون",
                "label_fr": "Southampton"
            },
            {
                "value": 15290,
                "label": "Southaven",
                "label_ar": "ساوثهافن",
                "label_fr": "Southaven"
            },
            {
                "value": 15291,
                "label": "Southborough",
                "label_ar": "ساوثبورو",
                "label_fr": "Southborough"
            },
            {
                "value": 15292,
                "label": "Southbridge",
                "label_ar": "ساوثبريدج",
                "label_fr": "Southbridge"
            },
            {
                "value": 15293,
                "label": "Southbury",
                "label_ar": "ساوثبيري",
                "label_fr": "Southbury"
            },
            {
                "value": 15294,
                "label": "Southern Pines",
                "label_ar": "جنوب باينز",
                "label_fr": "Pins du Sud"
            },
            {
                "value": 15295,
                "label": "Southfield",
                "label_ar": "ساوثفيلد",
                "label_fr": "Southfield"
            },
            {
                "value": 15296,
                "label": "Southfields",
                "label_ar": "ساوث فيلدز",
                "label_fr": "Southfields"
            },
            {
                "value": 15297,
                "label": "Southgate",
                "label_ar": "ساوثجيت",
                "label_fr": "Southgate"
            },
            {
                "value": 15298,
                "label": "Southington",
                "label_ar": "ساوثينجتون",
                "label_fr": "Southington"
            },
            {
                "value": 15299,
                "label": "Southlake",
                "label_ar": "ساوث ليك",
                "label_fr": "Southlake"
            },
            {
                "value": 15300,
                "label": "Southmayd",
                "label_ar": "جنوب ميد",
                "label_fr": "Southmayd"
            },
            {
                "value": 15301,
                "label": "Southold",
                "label_ar": "ساوثولد",
                "label_fr": "Southold"
            },
            {
                "value": 15302,
                "label": "Southport",
                "label_ar": "ساوثبورت",
                "label_fr": "Southport"
            },
            {
                "value": 15303,
                "label": "Southside",
                "label_ar": "الجانب الجنوبي",
                "label_fr": "Côté sud"
            },
            {
                "value": 15304,
                "label": "Southview",
                "label_ar": "ساوثفيو",
                "label_fr": "Southview"
            },
            {
                "value": 15305,
                "label": "Southwest Harbor",
                "label_ar": "ميناء الجنوب الغربي",
                "label_fr": "Southwest Harbor"
            },
            {
                "value": 15306,
                "label": "Southwick",
                "label_ar": "ساوثويك",
                "label_fr": "Southwick"
            },
            {
                "value": 15307,
                "label": "Spain",
                "label_ar": "إسبانيا",
                "label_fr": "Espagne"
            },
            {
                "value": 15308,
                "label": "Spalding",
                "label_ar": "سبالدينج",
                "label_fr": "Spalding"
            },
            {
                "value": 15309,
                "label": "Spanaway",
                "label_ar": "سباناواي",
                "label_fr": "Spanaway"
            },
            {
                "value": 15310,
                "label": "Spanish Fork",
                "label_ar": "الشوكة الاسبانية",
                "label_fr": "Fourchette espagnole"
            },
            {
                "value": 15311,
                "label": "Spanish Fort",
                "label_ar": "الحصن الاسباني",
                "label_fr": "Fort espagnol"
            },
            {
                "value": 15312,
                "label": "Spanishburg",
                "label_ar": "سبانسبورج",
                "label_fr": "Spanishburg"
            },
            {
                "value": 15313,
                "label": "Sparkill",
                "label_ar": "سباركيل",
                "label_fr": "Sparkill"
            },
            {
                "value": 15314,
                "label": "Sparkman",
                "label_ar": "سباركمان",
                "label_fr": "Sparkman"
            },
            {
                "value": 15315,
                "label": "Sparks",
                "label_ar": "شرارات",
                "label_fr": "Des étincelles"
            },
            {
                "value": 15316,
                "label": "Sparland",
                "label_ar": "سبارلاند",
                "label_fr": "Sparland"
            },
            {
                "value": 15317,
                "label": "Sparrow Bush",
                "label_ar": "سبارو بوش",
                "label_fr": "Bruant moineau"
            },
            {
                "value": 15318,
                "label": "Sparrows Point",
                "label_ar": "نقطة العصافير",
                "label_fr": "Sparrows Point"
            },
            {
                "value": 15319,
                "label": "Sparta",
                "label_ar": "سبارتا",
                "label_fr": "Sparte"
            },
            {
                "value": 15320,
                "label": "Spartanburg",
                "label_ar": "سبارتانبورغ",
                "label_fr": "Spartanburg"
            },
            {
                "value": 15321,
                "label": "Spartansburg",
                "label_ar": "سبارتانسبورغ",
                "label_fr": "Spartansburg"
            },
            {
                "value": 15322,
                "label": "Spavinaw",
                "label_ar": "سبافينو",
                "label_fr": "Spavinaw"
            },
            {
                "value": 15323,
                "label": "Spearfish",
                "label_ar": "سبيرفيش",
                "label_fr": "Spearfish"
            },
            {
                "value": 15324,
                "label": "Spearman",
                "label_ar": "الرامح",
                "label_fr": "Lancier"
            },
            {
                "value": 15325,
                "label": "Spearsville",
                "label_ar": "سبيرزفيل",
                "label_fr": "Spearsville"
            },
            {
                "value": 15326,
                "label": "Spearville",
                "label_ar": "سبيرفيل",
                "label_fr": "Spearville"
            },
            {
                "value": 15327,
                "label": "Speculator",
                "label_ar": "مضارب",
                "label_fr": "Spéculateur"
            },
            {
                "value": 15328,
                "label": "Spencer",
                "label_ar": "سبنسر",
                "label_fr": "Spencer"
            },
            {
                "value": 15329,
                "label": "Spencerport",
                "label_ar": "سبنسربورت",
                "label_fr": "Spencerport"
            },
            {
                "value": 15330,
                "label": "Spencertown",
                "label_ar": "سبنسرتاون",
                "label_fr": "Spencertown"
            },
            {
                "value": 15331,
                "label": "Spencerville",
                "label_ar": "سبنسرفيل",
                "label_fr": "Spencerville"
            },
            {
                "value": 15332,
                "label": "Sperry",
                "label_ar": "سبيري",
                "label_fr": "Sperry"
            },
            {
                "value": 15333,
                "label": "Sperryville",
                "label_ar": "سبريفيل",
                "label_fr": "Sperryville"
            },
            {
                "value": 15334,
                "label": "Spiceland",
                "label_ar": "Spiceland",
                "label_fr": "Spiceland"
            },
            {
                "value": 15335,
                "label": "Spicer",
                "label_ar": "سبايسر",
                "label_fr": "Spicer"
            },
            {
                "value": 15336,
                "label": "Spicewood",
                "label_ar": "سبايسوود",
                "label_fr": "Bois d'épice"
            },
            {
                "value": 15337,
                "label": "Spickard",
                "label_ar": "سبيكارد",
                "label_fr": "Spickard"
            },
            {
                "value": 15338,
                "label": "Spindale",
                "label_ar": "سبينديل",
                "label_fr": "Spindale"
            },
            {
                "value": 15339,
                "label": "Spinnerstown",
                "label_ar": "سبينرستاون",
                "label_fr": "Spinnerstown"
            },
            {
                "value": 15340,
                "label": "Spirit Lake",
                "label_ar": "بحيرة الروح",
                "label_fr": "Spirit Lake"
            },
            {
                "value": 15341,
                "label": "Spiro",
                "label_ar": "سبيرو",
                "label_fr": "Spiro"
            },
            {
                "value": 15342,
                "label": "Spivey",
                "label_ar": "سبيفي",
                "label_fr": "Spivey"
            },
            {
                "value": 15343,
                "label": "Splendora",
                "label_ar": "سبلندورا",
                "label_fr": "Splendora"
            },
            {
                "value": 15344,
                "label": "Spofford",
                "label_ar": "سبوفورد",
                "label_fr": "Spofford"
            },
            {
                "value": 15345,
                "label": "Spokane",
                "label_ar": "سبوكان",
                "label_fr": "Spokane"
            },
            {
                "value": 15346,
                "label": "Spooner",
                "label_ar": "سبونر",
                "label_fr": "Cuillère"
            },
            {
                "value": 15347,
                "label": "Spotswood",
                "label_ar": "سبوتسوود",
                "label_fr": "Spotswood"
            },
            {
                "value": 15348,
                "label": "Spotsylvania",
                "label_ar": "سبوتسيلفانيا",
                "label_fr": "Spotsylvanie"
            },
            {
                "value": 15349,
                "label": "Spout Spring",
                "label_ar": "صنبور الربيع",
                "label_fr": "Ressort de bec"
            },
            {
                "value": 15350,
                "label": "Spraggs",
                "label_ar": "سبراجس",
                "label_fr": "Spraggs"
            },
            {
                "value": 15351,
                "label": "Sprakers",
                "label_ar": "سبراكرز",
                "label_fr": "Sprakers"
            },
            {
                "value": 15352,
                "label": "Spreckels",
                "label_ar": "الراتينج",
                "label_fr": "Spreckels"
            },
            {
                "value": 15353,
                "label": "Spring",
                "label_ar": "ربيع",
                "label_fr": "Printemps"
            },
            {
                "value": 15354,
                "label": "Spring Arbor",
                "label_ar": "ربيع أربور",
                "label_fr": "Spring Arbor"
            },
            {
                "value": 15355,
                "label": "Spring Branch",
                "label_ar": "فرع الربيع",
                "label_fr": "Branche de printemps"
            },
            {
                "value": 15356,
                "label": "Spring Church",
                "label_ar": "كنيسة الربيع",
                "label_fr": "Église de printemps"
            },
            {
                "value": 15357,
                "label": "Spring City",
                "label_ar": "مدينة الربيع",
                "label_fr": "Ville de printemps"
            },
            {
                "value": 15358,
                "label": "Spring Creek",
                "label_ar": "سبرينج كريك",
                "label_fr": "Spring Creek"
            },
            {
                "value": 15359,
                "label": "Spring Glen",
                "label_ar": "سبرينج جلين",
                "label_fr": "Spring Glen"
            },
            {
                "value": 15360,
                "label": "Spring Green",
                "label_ar": "ربيع اخضر",
                "label_fr": "Vert printanier"
            },
            {
                "value": 15361,
                "label": "Spring Grove",
                "label_ar": "سبرينج جروف",
                "label_fr": "Spring Grove"
            },
            {
                "value": 15362,
                "label": "Spring Hill",
                "label_ar": "تل الربيع",
                "label_fr": "Spring Hill"
            },
            {
                "value": 15363,
                "label": "Spring Hope",
                "label_ar": "ربيع الأمل",
                "label_fr": "Espoir de printemps"
            },
            {
                "value": 15364,
                "label": "Spring House",
                "label_ar": "ربيع البيت",
                "label_fr": "Maison de printemps"
            },
            {
                "value": 15365,
                "label": "Spring Lake",
                "label_ar": "سبرينج ليك",
                "label_fr": "Spring Lake"
            },
            {
                "value": 15366,
                "label": "Spring Lake Park",
                "label_ar": "سبرينج ليك بارك",
                "label_fr": "Parc du lac Spring"
            },
            {
                "value": 15367,
                "label": "Spring Mills",
                "label_ar": "مطاحن الربيع",
                "label_fr": "Spring Mills"
            },
            {
                "value": 15368,
                "label": "Spring Park",
                "label_ar": "سبرينج بارك",
                "label_fr": "Spring Park"
            },
            {
                "value": 15369,
                "label": "Spring Run",
                "label_ar": "الربيع تشغيل",
                "label_fr": "Course de printemps"
            },
            {
                "value": 15370,
                "label": "Spring Valley",
                "label_ar": "وادي الربيع",
                "label_fr": "Vallée du printemps"
            },
            {
                "value": 15371,
                "label": "Springboro",
                "label_ar": "سبرينغبورو",
                "label_fr": "Springboro"
            },
            {
                "value": 15372,
                "label": "Springdale",
                "label_ar": "سبرينجديل",
                "label_fr": "Springdale"
            },
            {
                "value": 15373,
                "label": "Springer",
                "label_ar": "سبرينغر",
                "label_fr": "Springer"
            },
            {
                "value": 15374,
                "label": "Springerville",
                "label_ar": "سبرينجرفيل",
                "label_fr": "Springerville"
            },
            {
                "value": 15375,
                "label": "Springfield",
                "label_ar": "سبرينجفيلد",
                "label_fr": "Springfield"
            },
            {
                "value": 15376,
                "label": "Springfield Gardens",
                "label_ar": "حدائق سبرينجفيلد",
                "label_fr": "Springfield Gardens"
            },
            {
                "value": 15377,
                "label": "Springfield Township",
                "label_ar": "بلدة سبرينجفيلد",
                "label_fr": "Canton de Springfield"
            },
            {
                "value": 15378,
                "label": "Springhill",
                "label_ar": "تل الربيع",
                "label_fr": "Springhill"
            },
            {
                "value": 15379,
                "label": "Springport",
                "label_ar": "سبرينغبورت",
                "label_fr": "Springport"
            },
            {
                "value": 15380,
                "label": "Springtown",
                "label_ar": "سبرينجتاون",
                "label_fr": "Springtown"
            },
            {
                "value": 15381,
                "label": "Springvale",
                "label_ar": "سبرينجفيل",
                "label_fr": "Springvale"
            },
            {
                "value": 15382,
                "label": "Springview",
                "label_ar": "سبرينج فيو",
                "label_fr": "Springview"
            },
            {
                "value": 15383,
                "label": "Springville",
                "label_ar": "سبرينجفيل",
                "label_fr": "Springville"
            },
            {
                "value": 15384,
                "label": "Springwater",
                "label_ar": "مياه النبع",
                "label_fr": "Eau de source"
            },
            {
                "value": 15385,
                "label": "Spruce",
                "label_ar": "شجرة التنوب",
                "label_fr": "Épicéa"
            },
            {
                "value": 15386,
                "label": "Spruce Pine",
                "label_ar": "شجرة التنوب الصنوبر",
                "label_fr": "Pin épicéa"
            },
            {
                "value": 15387,
                "label": "Spurlockville",
                "label_ar": "سبورلوكفيل",
                "label_fr": "Spurlockville"
            },
            {
                "value": 15388,
                "label": "Squaw Lake",
                "label_ar": "بحيرة Squaw",
                "label_fr": "Squaw Lake"
            },
            {
                "value": 15389,
                "label": "Squaw Valley",
                "label_ar": "وادي Squaw",
                "label_fr": "Squaw Valley"
            },
            {
                "value": 15390,
                "label": "Squires",
                "label_ar": "سكوايرز",
                "label_fr": "Écuyers"
            },
            {
                "value": 15391,
                "label": "St Louis",
                "label_ar": "سانت لويس",
                "label_fr": "Saint Louis"
            },
            {
                "value": 15392,
                "label": "St. Augustine",
                "label_ar": "القديس أغسطينوس",
                "label_fr": "Sainte-Augustine"
            },
            {
                "value": 15393,
                "label": "St. Charles",
                "label_ar": "سانت تشارلز",
                "label_fr": "Saint Charles"
            },
            {
                "value": 15394,
                "label": "St. Genevieve",
                "label_ar": "سانت جينيفيف",
                "label_fr": "Sainte Geneviève"
            },
            {
                "value": 15395,
                "label": "St. Helena",
                "label_ar": "سانت هيلانة",
                "label_fr": "Sainte-Hélène"
            },
            {
                "value": 15396,
                "label": "St. Joseph",
                "label_ar": "شارع يوسف",
                "label_fr": "Saint Joseph"
            },
            {
                "value": 15397,
                "label": "St. Rose",
                "label_ar": "سانت روز",
                "label_fr": "Sainte Rose"
            },
            {
                "value": 15398,
                "label": "Staatsburg",
                "label_ar": "ستاتسبورغ",
                "label_fr": "Staatsburg"
            },
            {
                "value": 15399,
                "label": "Stacy",
                "label_ar": "ستايسي",
                "label_fr": "Stacy"
            },
            {
                "value": 15400,
                "label": "Stacyville",
                "label_ar": "ستايسفيل",
                "label_fr": "Stacyville"
            },
            {
                "value": 15401,
                "label": "Stafford",
                "label_ar": "ستافورد",
                "label_fr": "Stafford"
            },
            {
                "value": 15402,
                "label": "Stafford Springs",
                "label_ar": "ستافورد سبرينغز",
                "label_fr": "Stafford Springs"
            },
            {
                "value": 15403,
                "label": "Staffordsville",
                "label_ar": "ستافوردسفيل",
                "label_fr": "Staffordsville"
            },
            {
                "value": 15404,
                "label": "Stahlstown",
                "label_ar": "ستالستاون",
                "label_fr": "Stahlstown"
            },
            {
                "value": 15405,
                "label": "Staley",
                "label_ar": "ستالي",
                "label_fr": "Staley"
            },
            {
                "value": 15406,
                "label": "Stamford",
                "label_ar": "ستامفورد",
                "label_fr": "Stamford"
            },
            {
                "value": 15407,
                "label": "Stanardsville",
                "label_ar": "ستاناردسفيل",
                "label_fr": "Stanardsville"
            },
            {
                "value": 15408,
                "label": "Stanberry",
                "label_ar": "ستانبيري",
                "label_fr": "Stanberry"
            },
            {
                "value": 15409,
                "label": "Stanchfield",
                "label_ar": "ستانتشفيلد",
                "label_fr": "Stanchfield"
            },
            {
                "value": 15410,
                "label": "Standard",
                "label_ar": "اساسي",
                "label_fr": "la norme"
            },
            {
                "value": 15411,
                "label": "Standish",
                "label_ar": "ستانديش",
                "label_fr": "Standish"
            },
            {
                "value": 15412,
                "label": "Stanfield",
                "label_ar": "ستانفيلد",
                "label_fr": "Stanfield"
            },
            {
                "value": 15413,
                "label": "Stanford",
                "label_ar": "ستانفورد",
                "label_fr": "Stanford"
            },
            {
                "value": 15414,
                "label": "Stanfordville",
                "label_ar": "ستانفوردفيل",
                "label_fr": "Stanfordville"
            },
            {
                "value": 15415,
                "label": "Stanhope",
                "label_ar": "ستانهوب",
                "label_fr": "Stanhope"
            },
            {
                "value": 15416,
                "label": "Stanley",
                "label_ar": "ستانلي",
                "label_fr": "Stanley"
            },
            {
                "value": 15417,
                "label": "Stanton",
                "label_ar": "ستانتون",
                "label_fr": "Stanton"
            },
            {
                "value": 15418,
                "label": "Stantonsburg",
                "label_ar": "ستانتونسبيرغ",
                "label_fr": "Stantonsburg"
            },
            {
                "value": 15419,
                "label": "Stanville",
                "label_ar": "ستانفيل",
                "label_fr": "Stanville"
            },
            {
                "value": 15420,
                "label": "Stanwood",
                "label_ar": "ستانوود",
                "label_fr": "Stanwood"
            },
            {
                "value": 15421,
                "label": "Staples",
                "label_ar": "مشبك الورق",
                "label_fr": "Agrafes"
            },
            {
                "value": 15422,
                "label": "Stapleton",
                "label_ar": "ستابلتون",
                "label_fr": "Stapleton"
            },
            {
                "value": 15423,
                "label": "Star",
                "label_ar": "نجمة",
                "label_fr": "Étoile"
            },
            {
                "value": 15424,
                "label": "Star City",
                "label_ar": "مدينة النجوم",
                "label_fr": "Star City"
            },
            {
                "value": 15425,
                "label": "Star Lake",
                "label_ar": "ستار ليك",
                "label_fr": "Star Lake"
            },
            {
                "value": 15426,
                "label": "Star Prairie",
                "label_ar": "ستار براري",
                "label_fr": "Star Prairie"
            },
            {
                "value": 15427,
                "label": "Starbuck",
                "label_ar": "ستاربوك",
                "label_fr": "Starbuck"
            },
            {
                "value": 15428,
                "label": "Starford",
                "label_ar": "ستارفورد",
                "label_fr": "Starford"
            },
            {
                "value": 15429,
                "label": "Stark",
                "label_ar": "ستارك",
                "label_fr": "Rigide"
            },
            {
                "value": 15430,
                "label": "Stark City",
                "label_ar": "ستارك سيتي",
                "label_fr": "Ville Stark"
            },
            {
                "value": 15431,
                "label": "Starke",
                "label_ar": "ستارك",
                "label_fr": "Starke"
            },
            {
                "value": 15432,
                "label": "Starksboro",
                "label_ar": "ستاركسبورو",
                "label_fr": "Starksboro"
            },
            {
                "value": 15433,
                "label": "Starkville",
                "label_ar": "ستاركفيل",
                "label_fr": "Starkville"
            },
            {
                "value": 15434,
                "label": "Starkweather",
                "label_ar": "ستاركويذر",
                "label_fr": "Starkweather"
            },
            {
                "value": 15435,
                "label": "Starr",
                "label_ar": "ستار",
                "label_fr": "Starr"
            },
            {
                "value": 15436,
                "label": "Startex",
                "label_ar": "Startex",
                "label_fr": "Startex"
            },
            {
                "value": 15437,
                "label": "State Center",
                "label_ar": "مركز الدولة",
                "label_fr": "Centre d'État"
            },
            {
                "value": 15438,
                "label": "State College",
                "label_ar": "كلية الدولة",
                "label_fr": "Université d'État"
            },
            {
                "value": 15439,
                "label": "State Line",
                "label_ar": "حد الدولة",
                "label_fr": "Ligne d'état"
            },
            {
                "value": 15440,
                "label": "State Road",
                "label_ar": "طريق الولاية",
                "label_fr": "State Road"
            },
            {
                "value": 15441,
                "label": "State University",
                "label_ar": "جامعة الدولة",
                "label_fr": "Université d'État"
            },
            {
                "value": 15442,
                "label": "Stateline",
                "label_ar": "حد الدولة",
                "label_fr": "Stateline"
            },
            {
                "value": 15443,
                "label": "Staten Island",
                "label_ar": "جزيرة ستاتين",
                "label_fr": "Staten Island"
            },
            {
                "value": 15444,
                "label": "Statenville",
                "label_ar": "ستاتنفيل",
                "label_fr": "Statenville"
            },
            {
                "value": 15445,
                "label": "Statesboro",
                "label_ar": "ستاتسبورو",
                "label_fr": "Statesboro"
            },
            {
                "value": 15446,
                "label": "Statesville",
                "label_ar": "ستيتسفيل",
                "label_fr": "Statesville"
            },
            {
                "value": 15447,
                "label": "Statham",
                "label_ar": "ستاثام",
                "label_fr": "Statham"
            },
            {
                "value": 15448,
                "label": "Staunton",
                "label_ar": "ستونتون",
                "label_fr": "Staunton"
            },
            {
                "value": 15449,
                "label": "Stayton",
                "label_ar": "ستايتون",
                "label_fr": "Stayton"
            },
            {
                "value": 15450,
                "label": "Steamboat Rock",
                "label_ar": "ستيمبوت روك",
                "label_fr": "Rock à vapeur"
            },
            {
                "value": 15451,
                "label": "Steamboat Springs",
                "label_ar": "ستيمبوت سبرينغز",
                "label_fr": "Steamboat Springs"
            },
            {
                "value": 15452,
                "label": "Steamburg",
                "label_ar": "ستيمبورغ",
                "label_fr": "Steamburg"
            },
            {
                "value": 15453,
                "label": "Stearns",
                "label_ar": "ستيرنز",
                "label_fr": "Stearns"
            },
            {
                "value": 15454,
                "label": "Stebbins",
                "label_ar": "ستيبينز",
                "label_fr": "Stebbins"
            },
            {
                "value": 15455,
                "label": "Stedman",
                "label_ar": "ستيدمان",
                "label_fr": "Stedman"
            },
            {
                "value": 15456,
                "label": "Steedman",
                "label_ar": "ستيدمان",
                "label_fr": "Steedman"
            },
            {
                "value": 15457,
                "label": "Steele",
                "label_ar": "ستيل",
                "label_fr": "Steele"
            },
            {
                "value": 15458,
                "label": "Steele City",
                "label_ar": "ستيل سيتي",
                "label_fr": "Steele City"
            },
            {
                "value": 15459,
                "label": "Steeleville",
                "label_ar": "ستيلفيل",
                "label_fr": "Steeleville"
            },
            {
                "value": 15460,
                "label": "Steelville",
                "label_ar": "ستيلفيل",
                "label_fr": "Steelville"
            },
            {
                "value": 15461,
                "label": "Steen",
                "label_ar": "ستين",
                "label_fr": "Steen"
            },
            {
                "value": 15462,
                "label": "Steens",
                "label_ar": "ستينز",
                "label_fr": "Steens"
            },
            {
                "value": 15463,
                "label": "Steep Falls",
                "label_ar": "شلالات حادة",
                "label_fr": "Chutes raides"
            },
            {
                "value": 15464,
                "label": "Steger",
                "label_ar": "ستيجر",
                "label_fr": "Steger"
            },
            {
                "value": 15465,
                "label": "Steilacoom",
                "label_ar": "ستيلاكوم",
                "label_fr": "Steilacoom"
            },
            {
                "value": 15466,
                "label": "Steinauer",
                "label_ar": "شتاينوير",
                "label_fr": "Steinauer"
            },
            {
                "value": 15467,
                "label": "Steinhatchee",
                "label_ar": "شتاينهاتشي",
                "label_fr": "Steinhatchee"
            },
            {
                "value": 15468,
                "label": "Stella",
                "label_ar": "ستيلا",
                "label_fr": "Stella"
            },
            {
                "value": 15469,
                "label": "Stem",
                "label_ar": "إيقاف",
                "label_fr": "Tige"
            },
            {
                "value": 15470,
                "label": "Stephen",
                "label_ar": "ستيفن",
                "label_fr": "Stephen"
            },
            {
                "value": 15471,
                "label": "Stephens",
                "label_ar": "ستيفنس",
                "label_fr": "Stephens"
            },
            {
                "value": 15472,
                "label": "Stephens City",
                "label_ar": "ستيفنز سيتي",
                "label_fr": "Ville de Stephens"
            },
            {
                "value": 15473,
                "label": "Stephenson",
                "label_ar": "ستيفنسون",
                "label_fr": "Stephenson"
            },
            {
                "value": 15474,
                "label": "Stephensport",
                "label_ar": "ستيفنسبورت",
                "label_fr": "Stephensport"
            },
            {
                "value": 15475,
                "label": "Stephentown",
                "label_ar": "ستيفين تاون",
                "label_fr": "Stephentown"
            },
            {
                "value": 15476,
                "label": "Stephenville",
                "label_ar": "ستيفنفيل",
                "label_fr": "Stephenville"
            },
            {
                "value": 15477,
                "label": "Sterling",
                "label_ar": "الجنيه الاسترليني",
                "label_fr": "Sterling"
            },
            {
                "value": 15478,
                "label": "Sterling Forest",
                "label_ar": "الجنيه الاسترليني فورست",
                "label_fr": "Forêt Sterling"
            },
            {
                "value": 15479,
                "label": "Sterling Heights",
                "label_ar": "ستيرلينج هايتس",
                "label_fr": "Sterling Heights"
            },
            {
                "value": 15480,
                "label": "Sterlington",
                "label_ar": "ستيرلينغتون",
                "label_fr": "Sterlington"
            },
            {
                "value": 15481,
                "label": "Sterrett",
                "label_ar": "ستريت",
                "label_fr": "Sterrett"
            },
            {
                "value": 15482,
                "label": "Stetson",
                "label_ar": "ستيتسون",
                "label_fr": "Stetson"
            },
            {
                "value": 15483,
                "label": "Stetsonville",
                "label_ar": "ستيتسونفيل",
                "label_fr": "Stetsonville"
            },
            {
                "value": 15484,
                "label": "Steuben",
                "label_ar": "ستوبين",
                "label_fr": "Steuben"
            },
            {
                "value": 15485,
                "label": "Steubenville",
                "label_ar": "ستوبنفيل",
                "label_fr": "Steubenville"
            },
            {
                "value": 15486,
                "label": "Stevens",
                "label_ar": "ستيفنز",
                "label_fr": "Stevens"
            },
            {
                "value": 15487,
                "label": "Stevens Point",
                "label_ar": "ستيفنز بوينت",
                "label_fr": "Stevens Point"
            },
            {
                "value": 15488,
                "label": "Stevenson",
                "label_ar": "ستيفنسون",
                "label_fr": "Stevenson"
            },
            {
                "value": 15489,
                "label": "Stevenson Ranch",
                "label_ar": "ستيفنسون رانش",
                "label_fr": "Stevenson Ranch"
            },
            {
                "value": 15490,
                "label": "Stevensville",
                "label_ar": "ستيفنسفيل",
                "label_fr": "Stevensville"
            },
            {
                "value": 15491,
                "label": "Stevinson",
                "label_ar": "ستيفنسون",
                "label_fr": "Stevinson"
            },
            {
                "value": 15492,
                "label": "Steward",
                "label_ar": "ستيوارد",
                "label_fr": "Intendant"
            },
            {
                "value": 15493,
                "label": "Stewardson",
                "label_ar": "ستيواردسون",
                "label_fr": "Stewardson"
            },
            {
                "value": 15494,
                "label": "Stewart",
                "label_ar": "ستيوارت",
                "label_fr": "Stewart"
            },
            {
                "value": 15495,
                "label": "Stewartstown",
                "label_ar": "ستيوارتستاون",
                "label_fr": "Stewartstown"
            },
            {
                "value": 15496,
                "label": "Stewartsville",
                "label_ar": "ستيوارتسفيل",
                "label_fr": "Stewartsville"
            },
            {
                "value": 15497,
                "label": "Stewartville",
                "label_ar": "ستيوارتفيل",
                "label_fr": "Stewartville"
            },
            {
                "value": 15498,
                "label": "Stickney",
                "label_ar": "ستكني",
                "label_fr": "Stickney"
            },
            {
                "value": 15499,
                "label": "Stigler",
                "label_ar": "ستيجلر",
                "label_fr": "Stigler"
            },
            {
                "value": 15500,
                "label": "Stilesville",
                "label_ar": "ستيلسفيل",
                "label_fr": "Stilesville"
            },
            {
                "value": 15501,
                "label": "Still Pond",
                "label_ar": "لا تزال بركة",
                "label_fr": "Étang Still"
            },
            {
                "value": 15502,
                "label": "Stillman Valley",
                "label_ar": "وادي ستيلمان",
                "label_fr": "Vallée Stillman"
            },
            {
                "value": 15503,
                "label": "Stillmore",
                "label_ar": "هناك المزيد",
                "label_fr": "Toujours plus"
            },
            {
                "value": 15504,
                "label": "Stillwater",
                "label_ar": "مياه راكدة",
                "label_fr": "Eau plate"
            },
            {
                "value": 15505,
                "label": "Stilwell",
                "label_ar": "ستيلويل",
                "label_fr": "Stilwell"
            },
            {
                "value": 15506,
                "label": "Stinesville",
                "label_ar": "ستينسفيل",
                "label_fr": "Stinesville"
            },
            {
                "value": 15507,
                "label": "Stinnett",
                "label_ar": "ستينيت",
                "label_fr": "Stinnett"
            },
            {
                "value": 15508,
                "label": "Stinson Beach",
                "label_ar": "ستينسون بيتش",
                "label_fr": "Plage de Stinson"
            },
            {
                "value": 15509,
                "label": "Stirling",
                "label_ar": "ستيرلينغ",
                "label_fr": "Stirling"
            },
            {
                "value": 15510,
                "label": "Stites",
                "label_ar": "ستيتس",
                "label_fr": "Stites"
            },
            {
                "value": 15511,
                "label": "Stittville",
                "label_ar": "ستيتفيل",
                "label_fr": "Stittville"
            },
            {
                "value": 15512,
                "label": "Stockbridge",
                "label_ar": "ستوكبريدج",
                "label_fr": "Stockbridge"
            },
            {
                "value": 15513,
                "label": "Stockdale",
                "label_ar": "ستوكديل",
                "label_fr": "Stockdale"
            },
            {
                "value": 15514,
                "label": "Stockertown",
                "label_ar": "مخزون",
                "label_fr": "Stockertown"
            },
            {
                "value": 15515,
                "label": "Stockholm",
                "label_ar": "ستوكهولم",
                "label_fr": "Stockholm"
            },
            {
                "value": 15516,
                "label": "Stockport",
                "label_ar": "ستوكبورت",
                "label_fr": "Stockport"
            },
            {
                "value": 15517,
                "label": "Stockton",
                "label_ar": "ستوكتون",
                "label_fr": "Stockton"
            },
            {
                "value": 15518,
                "label": "Stockton Springs",
                "label_ar": "ستوكتون سبرينغز",
                "label_fr": "Stockton Springs"
            },
            {
                "value": 15519,
                "label": "Stoddard",
                "label_ar": "ستودارد",
                "label_fr": "Stoddard"
            },
            {
                "value": 15520,
                "label": "Stokesdale",
                "label_ar": "ستوكسديل",
                "label_fr": "Stokesdale"
            },
            {
                "value": 15521,
                "label": "Stollings",
                "label_ar": "ستولنج",
                "label_fr": "Stollings"
            },
            {
                "value": 15522,
                "label": "Stone",
                "label_ar": "حصاة",
                "label_fr": "Calcul"
            },
            {
                "value": 15523,
                "label": "Stone Creek",
                "label_ar": "ستون كريك",
                "label_fr": "Stone Creek"
            },
            {
                "value": 15524,
                "label": "Stone Harbor",
                "label_ar": "ستون هاربور",
                "label_fr": "Stone Harbor"
            },
            {
                "value": 15525,
                "label": "Stone Lake",
                "label_ar": "ستون ليك",
                "label_fr": "Stone Lake"
            },
            {
                "value": 15526,
                "label": "Stone Mountain",
                "label_ar": "جبل صخري",
                "label_fr": "Montagne de pierre"
            },
            {
                "value": 15527,
                "label": "Stone Park",
                "label_ar": "ستون بارك",
                "label_fr": "Stone Park"
            },
            {
                "value": 15528,
                "label": "Stone Ridge",
                "label_ar": "ستون ريدج",
                "label_fr": "Stone Ridge"
            },
            {
                "value": 15529,
                "label": "Stoneboro",
                "label_ar": "ستونبورو",
                "label_fr": "Stoneboro"
            },
            {
                "value": 15530,
                "label": "Stonefort",
                "label_ar": "ستونفورت",
                "label_fr": "Stonefort"
            },
            {
                "value": 15531,
                "label": "Stoneham",
                "label_ar": "ستونهام",
                "label_fr": "Stoneham"
            },
            {
                "value": 15532,
                "label": "Stoneville",
                "label_ar": "ستونفيل",
                "label_fr": "Stoneville"
            },
            {
                "value": 15533,
                "label": "Stonewall",
                "label_ar": "حائط حجارة",
                "label_fr": "Mur de pierre"
            },
            {
                "value": 15534,
                "label": "Stonington",
                "label_ar": "ستونينجتون",
                "label_fr": "Stonington"
            },
            {
                "value": 15535,
                "label": "Stony Brook",
                "label_ar": "ستوني بروك",
                "label_fr": "Ruisseau Stony"
            },
            {
                "value": 15536,
                "label": "Stony Creek",
                "label_ar": "ستوني كريك",
                "label_fr": "Ruisseau Stony"
            },
            {
                "value": 15537,
                "label": "Stony Point",
                "label_ar": "ستوني بوينت",
                "label_fr": "Pointe pierreuse"
            },
            {
                "value": 15538,
                "label": "Stonyford",
                "label_ar": "ستونيفورد",
                "label_fr": "Stonyford"
            },
            {
                "value": 15539,
                "label": "Storden",
                "label_ar": "ستوردن",
                "label_fr": "Storden"
            },
            {
                "value": 15540,
                "label": "Storm Lake",
                "label_ar": "بحيرة العاصفة",
                "label_fr": "Storm Lake"
            },
            {
                "value": 15541,
                "label": "Stormville",
                "label_ar": "ستورمفيل",
                "label_fr": "Stormville"
            },
            {
                "value": 15542,
                "label": "Storrs",
                "label_ar": "ستورز",
                "label_fr": "Storrs"
            },
            {
                "value": 15543,
                "label": "Story",
                "label_ar": "قصة",
                "label_fr": "Récit"
            },
            {
                "value": 15544,
                "label": "Story City",
                "label_ar": "ستوري سيتي",
                "label_fr": "Ville d'histoire"
            },
            {
                "value": 15545,
                "label": "Stotts City",
                "label_ar": "ستوتس سيتي",
                "label_fr": "Ville de Stotts"
            },
            {
                "value": 15546,
                "label": "Stoughton",
                "label_ar": "ستوتون",
                "label_fr": "Stoughton"
            },
            {
                "value": 15547,
                "label": "Stout",
                "label_ar": "شجاع",
                "label_fr": "bière"
            },
            {
                "value": 15548,
                "label": "Stoutland",
                "label_ar": "ستوتلاند",
                "label_fr": "Stoutland"
            },
            {
                "value": 15549,
                "label": "Stover",
                "label_ar": "ستوفر",
                "label_fr": "Stover"
            },
            {
                "value": 15550,
                "label": "Stow",
                "label_ar": "خبأ",
                "label_fr": "Ranger"
            },
            {
                "value": 15551,
                "label": "Stowe",
                "label_ar": "ستو",
                "label_fr": "Stowe"
            },
            {
                "value": 15552,
                "label": "Stoystown",
                "label_ar": "ستويستاون",
                "label_fr": "Stoystown"
            },
            {
                "value": 15553,
                "label": "Strafford",
                "label_ar": "سترافورد",
                "label_fr": "Strafford"
            },
            {
                "value": 15554,
                "label": "Strasburg",
                "label_ar": "ستراسبورغ",
                "label_fr": "Strasbourg"
            },
            {
                "value": 15555,
                "label": "Stratford",
                "label_ar": "ستراتفورد",
                "label_fr": "Stratford"
            },
            {
                "value": 15556,
                "label": "Stratham",
                "label_ar": "ستراتهام",
                "label_fr": "Stratham"
            },
            {
                "value": 15557,
                "label": "Strathmore",
                "label_ar": "ستراثمور",
                "label_fr": "Strathmore"
            },
            {
                "value": 15558,
                "label": "Strattanville",
                "label_ar": "ستراتانفيل",
                "label_fr": "Strattanville"
            },
            {
                "value": 15559,
                "label": "Stratton",
                "label_ar": "ستراتون",
                "label_fr": "Stratton"
            },
            {
                "value": 15560,
                "label": "Straughn",
                "label_ar": "ستراون",
                "label_fr": "Straughn"
            },
            {
                "value": 15561,
                "label": "Strawberry",
                "label_ar": "الفراولة",
                "label_fr": "fraise"
            },
            {
                "value": 15562,
                "label": "Strawberry Plains",
                "label_ar": "سهول الفراولة",
                "label_fr": "Plaines aux fraises"
            },
            {
                "value": 15563,
                "label": "Strawberry Point",
                "label_ar": "نقطة الفراولة",
                "label_fr": "Point de fraise"
            },
            {
                "value": 15564,
                "label": "Strawn",
                "label_ar": "القش",
                "label_fr": "Paille"
            },
            {
                "value": 15565,
                "label": "Streamwood",
                "label_ar": "ستريموود",
                "label_fr": "Streamwood"
            },
            {
                "value": 15566,
                "label": "Streator",
                "label_ar": "ستريتور",
                "label_fr": "Streator"
            },
            {
                "value": 15567,
                "label": "Street",
                "label_ar": "شارع",
                "label_fr": "rue"
            },
            {
                "value": 15568,
                "label": "Streeter",
                "label_ar": "ستريتر",
                "label_fr": "Streeter"
            },
            {
                "value": 15569,
                "label": "Streetman",
                "label_ar": "رجل الشارع",
                "label_fr": "Streetman"
            },
            {
                "value": 15570,
                "label": "Streetsboro",
                "label_ar": "ستريتسبورو",
                "label_fr": "Streetsboro"
            },
            {
                "value": 15571,
                "label": "Stringer",
                "label_ar": "سترينجر",
                "label_fr": "Raidisseur"
            },
            {
                "value": 15572,
                "label": "Stromsburg",
                "label_ar": "سترومسبورغ",
                "label_fr": "Stromsburg"
            },
            {
                "value": 15573,
                "label": "Strong",
                "label_ar": "قوي",
                "label_fr": "Fort"
            },
            {
                "value": 15574,
                "label": "Stronghurst",
                "label_ar": "سترونجهيرست",
                "label_fr": "Stronghurst"
            },
            {
                "value": 15575,
                "label": "Strongstown",
                "label_ar": "سترونجستاون",
                "label_fr": "Strongstown"
            },
            {
                "value": 15576,
                "label": "Strongsville",
                "label_ar": "سترونجسفيل",
                "label_fr": "Strongsville"
            },
            {
                "value": 15577,
                "label": "Stroud",
                "label_ar": "ستراود",
                "label_fr": "Stroud"
            },
            {
                "value": 15578,
                "label": "Stroudsburg",
                "label_ar": "سترودسبورغ",
                "label_fr": "Stroudsburg"
            },
            {
                "value": 15579,
                "label": "Strum",
                "label_ar": "مداعبة أوتار الآلة الموسيقية",
                "label_fr": "Gratter"
            },
            {
                "value": 15580,
                "label": "Strunk",
                "label_ar": "سترنك",
                "label_fr": "Strunk"
            },
            {
                "value": 15581,
                "label": "Struthers",
                "label_ar": "ستروثرز",
                "label_fr": "Struthers"
            },
            {
                "value": 15582,
                "label": "Stryker",
                "label_ar": "سترايكر",
                "label_fr": "Stryker"
            },
            {
                "value": 15583,
                "label": "Stuart",
                "label_ar": "ستيوارت",
                "label_fr": "Stuart"
            },
            {
                "value": 15584,
                "label": "Stuarts Draft",
                "label_ar": "مشروع ستيوارت",
                "label_fr": "Brouillon Stuarts"
            },
            {
                "value": 15585,
                "label": "Studio City",
                "label_ar": "ستوديو سيتي",
                "label_fr": "Studio City"
            },
            {
                "value": 15586,
                "label": "Stump Creek",
                "label_ar": "ستامب كريك",
                "label_fr": "Ruisseau Stump"
            },
            {
                "value": 15587,
                "label": "Sturbridge",
                "label_ar": "ستربريدج",
                "label_fr": "Sturbridge"
            },
            {
                "value": 15588,
                "label": "Sturgeon",
                "label_ar": "سمك الحفش",
                "label_fr": "Esturgeon"
            },
            {
                "value": 15589,
                "label": "Sturgeon Bay",
                "label_ar": "خليج Sturgeon",
                "label_fr": "Sturgeon Bay"
            },
            {
                "value": 15590,
                "label": "Sturgeon Lake",
                "label_ar": "بحيرة الحفش",
                "label_fr": "Lac Sturgeon"
            },
            {
                "value": 15591,
                "label": "Sturgis",
                "label_ar": "ستورجيس",
                "label_fr": "Sturgis"
            },
            {
                "value": 15592,
                "label": "Sturtevant",
                "label_ar": "قوي",
                "label_fr": "Sturtevant"
            },
            {
                "value": 15593,
                "label": "Stuttgart",
                "label_ar": "شتوتغارت",
                "label_fr": "Stuttgart"
            },
            {
                "value": 15594,
                "label": "Stuyvesant",
                "label_ar": "ستايفسانت",
                "label_fr": "Stuyvesant"
            },
            {
                "value": 15595,
                "label": "Suamico",
                "label_ar": "Suamico",
                "label_fr": "Suamico"
            },
            {
                "value": 15596,
                "label": "Sublette",
                "label_ar": "سبليت",
                "label_fr": "Sublette"
            },
            {
                "value": 15597,
                "label": "Sublimity",
                "label_ar": "سمو",
                "label_fr": "Sublimité"
            },
            {
                "value": 15598,
                "label": "Succasunna",
                "label_ar": "سوكاسونا",
                "label_fr": "Succasunna"
            },
            {
                "value": 15599,
                "label": "Suches",
                "label_ar": "سوتش",
                "label_fr": "Suches"
            },
            {
                "value": 15600,
                "label": "Sudan",
                "label_ar": "السودان",
                "label_fr": "Soudan"
            },
            {
                "value": 15601,
                "label": "Sudbury",
                "label_ar": "سودبيري",
                "label_fr": "Sudbury"
            },
            {
                "value": 15602,
                "label": "Sudlersville",
                "label_ar": "سودلرسفيل",
                "label_fr": "Sudlersville"
            },
            {
                "value": 15603,
                "label": "Suffern",
                "label_ar": "سوفيرن",
                "label_fr": "Suffern"
            },
            {
                "value": 15604,
                "label": "Suffield",
                "label_ar": "سوفيلد",
                "label_fr": "Suffield"
            },
            {
                "value": 15605,
                "label": "Suffolk",
                "label_ar": "سوفولك",
                "label_fr": "Suffolk"
            },
            {
                "value": 15606,
                "label": "Sugar City",
                "label_ar": "مدينة السكر",
                "label_fr": "Sugar City"
            },
            {
                "value": 15607,
                "label": "Sugar Grove",
                "label_ar": "شوجر جروف",
                "label_fr": "Sugar Grove"
            },
            {
                "value": 15608,
                "label": "Sugar Hill",
                "label_ar": "شوجر هيل",
                "label_fr": "Sugar Hill"
            },
            {
                "value": 15609,
                "label": "Sugar Land",
                "label_ar": "ارض السكر",
                "label_fr": "Pays du sucre"
            },
            {
                "value": 15610,
                "label": "Sugar Loaf",
                "label_ar": "ورق السكر",
                "label_fr": "Pain de Sucre"
            },
            {
                "value": 15611,
                "label": "Sugar Run",
                "label_ar": "شوجر ران",
                "label_fr": "Sugar Run"
            },
            {
                "value": 15612,
                "label": "Sugar Tree",
                "label_ar": "شجرة السكر",
                "label_fr": "Arbre à sucre"
            },
            {
                "value": 15613,
                "label": "Sugar Valley",
                "label_ar": "وادي السكر",
                "label_fr": "Sugar Valley"
            },
            {
                "value": 15614,
                "label": "Sugarcreek",
                "label_ar": "شوجركريك",
                "label_fr": "Sucre"
            },
            {
                "value": 15615,
                "label": "Sugarloaf",
                "label_ar": "ورق السكر",
                "label_fr": "Pain de Sucre"
            },
            {
                "value": 15616,
                "label": "Sugarloaf Township",
                "label_ar": "بلدة سوغارلوف",
                "label_fr": "Canton de Sugarloaf"
            },
            {
                "value": 15617,
                "label": "Sugartown",
                "label_ar": "سوغارتاون",
                "label_fr": "Sugartown"
            },
            {
                "value": 15618,
                "label": "Suisun",
                "label_ar": "Suisun",
                "label_fr": "Suisun"
            },
            {
                "value": 15619,
                "label": "Suitland",
                "label_ar": "سوتلاند",
                "label_fr": "Suitland"
            },
            {
                "value": 15620,
                "label": "Sula",
                "label_ar": "سولا",
                "label_fr": "Sula"
            },
            {
                "value": 15621,
                "label": "Sulligent",
                "label_ar": "قذر",
                "label_fr": "Sulligent"
            },
            {
                "value": 15622,
                "label": "Sullivan",
                "label_ar": "سوليفان",
                "label_fr": "Sullivan"
            },
            {
                "value": 15623,
                "label": "Sullivan's Island",
                "label_ar": "جزيرة سوليفان",
                "label_fr": "Île de Sullivan"
            },
            {
                "value": 15624,
                "label": "Sully",
                "label_ar": "سولي",
                "label_fr": "Souiller"
            },
            {
                "value": 15625,
                "label": "Sulphur",
                "label_ar": "الكبريت",
                "label_fr": "Soufre"
            },
            {
                "value": 15626,
                "label": "Sulphur Bluff",
                "label_ar": "خدعة الكبريت",
                "label_fr": "Sulphur Bluff"
            },
            {
                "value": 15627,
                "label": "Sulphur Springs",
                "label_ar": "ينابيع الكبريت",
                "label_fr": "Sulphur Springs"
            },
            {
                "value": 15628,
                "label": "Sultan",
                "label_ar": "سلطان",
                "label_fr": "Sultan"
            },
            {
                "value": 15629,
                "label": "Sumas",
                "label_ar": "سماس",
                "label_fr": "Sumas"
            },
            {
                "value": 15630,
                "label": "Sumerduck",
                "label_ar": "سومردوك",
                "label_fr": "Sumerduck"
            },
            {
                "value": 15631,
                "label": "Sumiton",
                "label_ar": "سوميتون",
                "label_fr": "Sumiton"
            },
            {
                "value": 15632,
                "label": "Summer Shade",
                "label_ar": "ظل الصيف",
                "label_fr": "Ombre d'été"
            },
            {
                "value": 15633,
                "label": "Summerdale",
                "label_ar": "سمرديل",
                "label_fr": "Summerdale"
            },
            {
                "value": 15634,
                "label": "Summerfield",
                "label_ar": "سمرفيلد",
                "label_fr": "Summerfield"
            },
            {
                "value": 15635,
                "label": "Summerhill",
                "label_ar": "سمرهيل",
                "label_fr": "Summerhill"
            },
            {
                "value": 15636,
                "label": "Summerland",
                "label_ar": "سمرلاند",
                "label_fr": "Summerland"
            },
            {
                "value": 15637,
                "label": "Summerland Key",
                "label_ar": "مفتاح سمرلاند",
                "label_fr": "Clé de Summerland"
            },
            {
                "value": 15638,
                "label": "Summers",
                "label_ar": "الصيف",
                "label_fr": "Étés"
            },
            {
                "value": 15639,
                "label": "Summersville",
                "label_ar": "سامرسفيل",
                "label_fr": "Summersville"
            },
            {
                "value": 15640,
                "label": "Summerton",
                "label_ar": "سمرتون",
                "label_fr": "Summerton"
            },
            {
                "value": 15641,
                "label": "Summertown",
                "label_ar": "الصيف",
                "label_fr": "Summertown"
            },
            {
                "value": 15642,
                "label": "Summerville",
                "label_ar": "سامرفيل",
                "label_fr": "Summerville"
            },
            {
                "value": 15643,
                "label": "Summit",
                "label_ar": "قمة",
                "label_fr": "Sommet"
            },
            {
                "value": 15644,
                "label": "Summit Hill",
                "label_ar": "قمة هيل",
                "label_fr": "Summit Hill"
            },
            {
                "value": 15645,
                "label": "Summit Lake",
                "label_ar": "سوميت ليك",
                "label_fr": "Summit Lake"
            },
            {
                "value": 15646,
                "label": "Summit Station",
                "label_ar": "محطة القمة",
                "label_fr": "Station Summit"
            },
            {
                "value": 15647,
                "label": "Summitville",
                "label_ar": "سوميتفيل",
                "label_fr": "Summitville"
            },
            {
                "value": 15648,
                "label": "Sumner",
                "label_ar": "سومنر",
                "label_fr": "Sumner"
            },
            {
                "value": 15649,
                "label": "Sumrall",
                "label_ar": "سومرال",
                "label_fr": "Sumrall"
            },
            {
                "value": 15650,
                "label": "Sumter",
                "label_ar": "سمتر",
                "label_fr": "Sumter"
            },
            {
                "value": 15651,
                "label": "Sun",
                "label_ar": "شمس",
                "label_fr": "Soleil"
            },
            {
                "value": 15652,
                "label": "Sun City",
                "label_ar": "مدينة الشمس",
                "label_fr": "Sun City"
            },
            {
                "value": 15653,
                "label": "Sun City Center",
                "label_ar": "صن سيتى سنتر",
                "label_fr": "Centre de Sun City"
            },
            {
                "value": 15654,
                "label": "Sun City West",
                "label_ar": "صن سيتي ويست",
                "label_fr": "Sun City Ouest"
            },
            {
                "value": 15655,
                "label": "Sun Prairie",
                "label_ar": "صن براري",
                "label_fr": "Sun Prairie"
            },
            {
                "value": 15656,
                "label": "Sun River",
                "label_ar": "نهر صن",
                "label_fr": "Sun River"
            },
            {
                "value": 15657,
                "label": "Sun Valley",
                "label_ar": "شمس، واد",
                "label_fr": "Sun Valley"
            },
            {
                "value": 15658,
                "label": "Sunapee",
                "label_ar": "سونابي",
                "label_fr": "Sunapee"
            },
            {
                "value": 15659,
                "label": "Sunbright",
                "label_ar": "ضوء الشمس",
                "label_fr": "Sunbright"
            },
            {
                "value": 15660,
                "label": "Sunburg",
                "label_ar": "سنبورغ",
                "label_fr": "Sunburg"
            },
            {
                "value": 15661,
                "label": "Sunburst",
                "label_ar": "أمة الله",
                "label_fr": "Sunburst"
            },
            {
                "value": 15662,
                "label": "Sunbury",
                "label_ar": "صنبيري",
                "label_fr": "Sunbury"
            },
            {
                "value": 15663,
                "label": "Suncook",
                "label_ar": "سنكوك",
                "label_fr": "Suncook"
            },
            {
                "value": 15664,
                "label": "Sundance",
                "label_ar": "صندانس",
                "label_fr": "Sundance"
            },
            {
                "value": 15665,
                "label": "Sunderland",
                "label_ar": "سندرلاند",
                "label_fr": "Sunderland"
            },
            {
                "value": 15666,
                "label": "Sundown",
                "label_ar": "غروب",
                "label_fr": "Coucher"
            },
            {
                "value": 15667,
                "label": "Sunflower",
                "label_ar": "دوار الشمس",
                "label_fr": "Tournesol"
            },
            {
                "value": 15668,
                "label": "Sunland",
                "label_ar": "صن لاند",
                "label_fr": "Sunland"
            },
            {
                "value": 15669,
                "label": "Sunman",
                "label_ar": "سنمان",
                "label_fr": "Sunman"
            },
            {
                "value": 15670,
                "label": "Sunnyside",
                "label_ar": "جانب مشرق",
                "label_fr": "Côté ensoleillé"
            },
            {
                "value": 15671,
                "label": "Sunnyvale",
                "label_ar": "سانيفيل",
                "label_fr": "Sunnyvale"
            },
            {
                "value": 15672,
                "label": "Sunol",
                "label_ar": "سنول",
                "label_fr": "Sunol"
            },
            {
                "value": 15673,
                "label": "Sunray",
                "label_ar": "صن راي",
                "label_fr": "Rayon du soleil"
            },
            {
                "value": 15674,
                "label": "Sunrise",
                "label_ar": "شروق الشمس",
                "label_fr": "lever du soleil"
            },
            {
                "value": 15675,
                "label": "Sunrise Beach",
                "label_ar": "صن رايز بيتش",
                "label_fr": "Sunrise Beach"
            },
            {
                "value": 15676,
                "label": "Sunset",
                "label_ar": "غروب الشمس",
                "label_fr": "Le coucher du soleil"
            },
            {
                "value": 15677,
                "label": "Sunset Beach",
                "label_ar": "شاطئ الغروب",
                "label_fr": "Le soleil couchant sur la plage"
            },
            {
                "value": 15678,
                "label": "Sunshine",
                "label_ar": "إشراق",
                "label_fr": "Ensoleillement"
            },
            {
                "value": 15679,
                "label": "Superior",
                "label_ar": "متفوق",
                "label_fr": "Supérieur"
            },
            {
                "value": 15680,
                "label": "Supply",
                "label_ar": "يتبرع",
                "label_fr": "La fourniture"
            },
            {
                "value": 15681,
                "label": "Suquamish",
                "label_ar": "سوكاميش",
                "label_fr": "Suquamish"
            },
            {
                "value": 15682,
                "label": "Surgoinsville",
                "label_ar": "سورجوينسفيل",
                "label_fr": "Surgoinsville"
            },
            {
                "value": 15683,
                "label": "Suring",
                "label_ar": "مؤكد",
                "label_fr": "Suring"
            },
            {
                "value": 15684,
                "label": "Surprise",
                "label_ar": "مفاجأة",
                "label_fr": "Surprise"
            },
            {
                "value": 15685,
                "label": "Surrency",
                "label_ar": "العملة",
                "label_fr": "Monnaie"
            },
            {
                "value": 15686,
                "label": "Surry",
                "label_ar": "سريعا",
                "label_fr": "Surry"
            },
            {
                "value": 15687,
                "label": "Susanville",
                "label_ar": "سوزانفيل",
                "label_fr": "Susanville"
            },
            {
                "value": 15688,
                "label": "Susquehanna",
                "label_ar": "سسكويهانا",
                "label_fr": "Susquehanna"
            },
            {
                "value": 15689,
                "label": "Sussex",
                "label_ar": "ساسكس",
                "label_fr": "Sussex"
            },
            {
                "value": 15690,
                "label": "Sutersville",
                "label_ar": "سوترسفيل",
                "label_fr": "Sutersville"
            },
            {
                "value": 15691,
                "label": "Sutherland",
                "label_ar": "ساذرلاند",
                "label_fr": "Sutherland"
            },
            {
                "value": 15692,
                "label": "Sutherland Springs",
                "label_ar": "ساذرلاند سبرينغز",
                "label_fr": "Sutherland Springs"
            },
            {
                "value": 15693,
                "label": "Sutherlin",
                "label_ar": "ساذرلين",
                "label_fr": "Sutherlin"
            },
            {
                "value": 15694,
                "label": "Sutter",
                "label_ar": "سوتر",
                "label_fr": "Sutter"
            },
            {
                "value": 15695,
                "label": "Sutter Creek",
                "label_ar": "سوتر كريك",
                "label_fr": "Ruisseau Sutter"
            },
            {
                "value": 15696,
                "label": "Sutton",
                "label_ar": "ساتون",
                "label_fr": "Sutton"
            },
            {
                "value": 15697,
                "label": "Suttons Bay",
                "label_ar": "ساتونز باي",
                "label_fr": "Baie de Suttons"
            },
            {
                "value": 15698,
                "label": "Suwanee",
                "label_ar": "الصواني",
                "label_fr": "Suwanee"
            },
            {
                "value": 15699,
                "label": "Suwannee",
                "label_ar": "Suwannee",
                "label_fr": "Suwannee"
            },
            {
                "value": 15700,
                "label": "Swainsboro",
                "label_ar": "سوينسبورو",
                "label_fr": "Swainsboro"
            },
            {
                "value": 15701,
                "label": "Swaledale",
                "label_ar": "Swaledale",
                "label_fr": "Swaledale"
            },
            {
                "value": 15702,
                "label": "Swampscott",
                "label_ar": "سوامبسكوت",
                "label_fr": "Swampscott"
            },
            {
                "value": 15703,
                "label": "Swan",
                "label_ar": "بجعة",
                "label_fr": "Cygne"
            },
            {
                "value": 15704,
                "label": "Swannanoa",
                "label_ar": "سوانانوا",
                "label_fr": "Swannanoa"
            },
            {
                "value": 15705,
                "label": "Swans Island",
                "label_ar": "جزيرة البجع",
                "label_fr": "Île des cygnes"
            },
            {
                "value": 15706,
                "label": "Swansboro",
                "label_ar": "سوانسبورو",
                "label_fr": "Swansboro"
            },
            {
                "value": 15707,
                "label": "Swansea",
                "label_ar": "سوانزي",
                "label_fr": "Swansea"
            },
            {
                "value": 15708,
                "label": "Swanton",
                "label_ar": "سوانتون",
                "label_fr": "Swanton"
            },
            {
                "value": 15709,
                "label": "Swanville",
                "label_ar": "سوانفيل",
                "label_fr": "Swanville"
            },
            {
                "value": 15710,
                "label": "Swanzey",
                "label_ar": "سوانزي",
                "label_fr": "Swanzey"
            },
            {
                "value": 15711,
                "label": "Swarthmore",
                "label_ar": "سوارثمور",
                "label_fr": "Swarthmore"
            },
            {
                "value": 15712,
                "label": "Swartswood",
                "label_ar": "سوارتسوود",
                "label_fr": "Swartswood"
            },
            {
                "value": 15713,
                "label": "Swartz",
                "label_ar": "شوارتز",
                "label_fr": "Swartz"
            },
            {
                "value": 15714,
                "label": "Swartz Creek",
                "label_ar": "شوارتز كريك",
                "label_fr": "Ruisseau Swartz"
            },
            {
                "value": 15715,
                "label": "Swayzee",
                "label_ar": "سويزي",
                "label_fr": "Swayzee"
            },
            {
                "value": 15716,
                "label": "Swedesboro",
                "label_ar": "سويدسبورو",
                "label_fr": "Swedesboro"
            },
            {
                "value": 15717,
                "label": "Sweeny",
                "label_ar": "سويني",
                "label_fr": "Sweeny"
            },
            {
                "value": 15718,
                "label": "Sweet",
                "label_ar": "حلو",
                "label_fr": "Sucré"
            },
            {
                "value": 15719,
                "label": "Sweet Home",
                "label_ar": "منزل جميل",
                "label_fr": "Douce maison"
            },
            {
                "value": 15720,
                "label": "Sweet Springs",
                "label_ar": "سويت سبرينغز",
                "label_fr": "Sweet Springs"
            },
            {
                "value": 15721,
                "label": "Sweet Valley",
                "label_ar": "سويت فالي",
                "label_fr": "Sweet Valley"
            },
            {
                "value": 15722,
                "label": "Sweet Water",
                "label_ar": "ماء محلى",
                "label_fr": "Eau douce"
            },
            {
                "value": 15723,
                "label": "Sweetser",
                "label_ar": "حلويات",
                "label_fr": "Bonbon"
            },
            {
                "value": 15724,
                "label": "Sweetwater",
                "label_ar": "ماء محلى",
                "label_fr": "Eau douce"
            },
            {
                "value": 15725,
                "label": "Swiftwater",
                "label_ar": "سويفت ووتر",
                "label_fr": "Swiftwater"
            },
            {
                "value": 15726,
                "label": "Swink",
                "label_ar": "طرفة",
                "label_fr": "Swink"
            },
            {
                "value": 15727,
                "label": "Swisher",
                "label_ar": "سويشر",
                "label_fr": "Swisher"
            },
            {
                "value": 15728,
                "label": "Swisshome",
                "label_ar": "Swisshome",
                "label_fr": "Swisshome"
            },
            {
                "value": 15729,
                "label": "Switz City",
                "label_ar": "مدينة سويتز",
                "label_fr": "Switz City"
            },
            {
                "value": 15730,
                "label": "Switzer",
                "label_ar": "سويتزر",
                "label_fr": "Suisse"
            },
            {
                "value": 15731,
                "label": "Swoope",
                "label_ar": "Swoope",
                "label_fr": "Swoope"
            },
            {
                "value": 15732,
                "label": "Sybertsville",
                "label_ar": "سيبرتسفيل",
                "label_fr": "Sybertsville"
            },
            {
                "value": 15733,
                "label": "Sycamore",
                "label_ar": "شجر جميز",
                "label_fr": "Sycomore"
            },
            {
                "value": 15734,
                "label": "Sykeston",
                "label_ar": "سيكيستون",
                "label_fr": "Sykeston"
            },
            {
                "value": 15735,
                "label": "Sykesville",
                "label_ar": "سايكسفيل",
                "label_fr": "Sykesville"
            },
            {
                "value": 15736,
                "label": "Sylacauga",
                "label_ar": "سيلاكوجا",
                "label_fr": "Sylacauga"
            },
            {
                "value": 15737,
                "label": "Sylmar",
                "label_ar": "سيلمار",
                "label_fr": "Sylmar"
            },
            {
                "value": 15738,
                "label": "Sylva",
                "label_ar": "سيلفا",
                "label_fr": "Sylva"
            },
            {
                "value": 15739,
                "label": "Sylvan Beach",
                "label_ar": "شاطئ سيلفان",
                "label_fr": "Plage Sylvan"
            },
            {
                "value": 15740,
                "label": "Sylvan Grove",
                "label_ar": "سيلفان جروف",
                "label_fr": "Sylvan Grove"
            },
            {
                "value": 15741,
                "label": "Sylvania",
                "label_ar": "سيلفانيا",
                "label_fr": "Sylvania"
            },
            {
                "value": 15742,
                "label": "Sylvester",
                "label_ar": "سيلفستر",
                "label_fr": "Sylvestre"
            },
            {
                "value": 15743,
                "label": "Symsonia",
                "label_ar": "سيمسونيا",
                "label_fr": "Symsonia"
            },
            {
                "value": 15744,
                "label": "Syosset",
                "label_ar": "سيوسيت",
                "label_fr": "Syosset"
            },
            {
                "value": 15745,
                "label": "Syracuse",
                "label_ar": "سيراكيوز",
                "label_fr": "Syracuse"
            },
            {
                "value": 15746,
                "label": "Taberg",
                "label_ar": "تابيرج",
                "label_fr": "Taberg"
            },
            {
                "value": 15747,
                "label": "Tabernash",
                "label_ar": "تابرناش",
                "label_fr": "Tabernash"
            },
            {
                "value": 15748,
                "label": "Table Grove",
                "label_ar": "تيبل جروف",
                "label_fr": "Table Grove"
            },
            {
                "value": 15749,
                "label": "Tabor",
                "label_ar": "تابور",
                "label_fr": "Tambourin"
            },
            {
                "value": 15750,
                "label": "Tabor City",
                "label_ar": "مدينة تابور",
                "label_fr": "Ville de Tabor"
            },
            {
                "value": 15751,
                "label": "Tacoma",
                "label_ar": "تاكوما",
                "label_fr": "Tacoma"
            },
            {
                "value": 15752,
                "label": "Taft",
                "label_ar": "تافت",
                "label_fr": "Taft"
            },
            {
                "value": 15753,
                "label": "Tafton",
                "label_ar": "تافتون",
                "label_fr": "Tafton"
            },
            {
                "value": 15754,
                "label": "Taftville",
                "label_ar": "تافتفيل",
                "label_fr": "Taftville"
            },
            {
                "value": 15755,
                "label": "Tahlequah",
                "label_ar": "تهليكوة",
                "label_fr": "Tahlequah"
            },
            {
                "value": 15756,
                "label": "Tahoe City",
                "label_ar": "تاهو سيتي",
                "label_fr": "Tahoe City"
            },
            {
                "value": 15757,
                "label": "Tahoe Valley",
                "label_ar": "تاهو فالي",
                "label_fr": "Vallée de Tahoe"
            },
            {
                "value": 15758,
                "label": "Tahoe Vista",
                "label_ar": "تاهو فيستا",
                "label_fr": "Tahoe Vista"
            },
            {
                "value": 15759,
                "label": "Tahoka",
                "label_ar": "تاهوكا",
                "label_fr": "Tahoka"
            },
            {
                "value": 15760,
                "label": "Taholah",
                "label_ar": "تحولة",
                "label_fr": "Taholah"
            },
            {
                "value": 15761,
                "label": "Tahoma",
                "label_ar": "تاهوما",
                "label_fr": "Tahoma"
            },
            {
                "value": 15762,
                "label": "Tahuya",
                "label_ar": "تحويا",
                "label_fr": "Tahuya"
            },
            {
                "value": 15763,
                "label": "Takoma Park",
                "label_ar": "تاكوما بارك",
                "label_fr": "Parc Takoma"
            },
            {
                "value": 15764,
                "label": "Talala",
                "label_ar": "طلاله",
                "label_fr": "Talala"
            },
            {
                "value": 15765,
                "label": "Talbott",
                "label_ar": "تالبوت",
                "label_fr": "Talbott"
            },
            {
                "value": 15766,
                "label": "Talbotton",
                "label_ar": "تالبوتون",
                "label_fr": "Talbotton"
            },
            {
                "value": 15767,
                "label": "Talco",
                "label_ar": "تالكو",
                "label_fr": "Talco"
            },
            {
                "value": 15768,
                "label": "Talcott",
                "label_ar": "تلكوت",
                "label_fr": "Talcott"
            },
            {
                "value": 15769,
                "label": "Talent",
                "label_ar": "الموهبة",
                "label_fr": "Talent"
            },
            {
                "value": 15770,
                "label": "Talihina",
                "label_ar": "تالهينة",
                "label_fr": "Talihina"
            },
            {
                "value": 15771,
                "label": "Talkeetna",
                "label_ar": "تالكيتنا",
                "label_fr": "Talkeetna"
            },
            {
                "value": 15772,
                "label": "Talking Rock",
                "label_ar": "الحديث الصخرة",
                "label_fr": "Parler Rock"
            },
            {
                "value": 15773,
                "label": "Tall Timbers",
                "label_ar": "طويل القامة",
                "label_fr": "Grands bois"
            },
            {
                "value": 15774,
                "label": "Talladega",
                "label_ar": "تالاديجا",
                "label_fr": "Talladega"
            },
            {
                "value": 15775,
                "label": "Tallahassee",
                "label_ar": "تالاهاسي",
                "label_fr": "Tallahassee"
            },
            {
                "value": 15776,
                "label": "Tallapoosa",
                "label_ar": "Tallapoosa",
                "label_fr": "Tallapoosa"
            },
            {
                "value": 15777,
                "label": "Tallassee",
                "label_ar": "تلاسي",
                "label_fr": "Tallassee"
            },
            {
                "value": 15778,
                "label": "Tallmadge",
                "label_ar": "تالمادج",
                "label_fr": "Tallmadge"
            },
            {
                "value": 15779,
                "label": "Tallulah",
                "label_ar": "طلولة",
                "label_fr": "Tallulah"
            },
            {
                "value": 15780,
                "label": "Talmo",
                "label_ar": "تالمو",
                "label_fr": "Talmo"
            },
            {
                "value": 15781,
                "label": "Talmoon",
                "label_ar": "تلمون",
                "label_fr": "Talmoon"
            },
            {
                "value": 15782,
                "label": "Taloga",
                "label_ar": "تالوجا",
                "label_fr": "Taloga"
            },
            {
                "value": 15783,
                "label": "Tama",
                "label_ar": "تاما",
                "label_fr": "Tama"
            },
            {
                "value": 15784,
                "label": "Tamaqua",
                "label_ar": "تاماكوا",
                "label_fr": "Tamaqua"
            },
            {
                "value": 15785,
                "label": "Tamassee",
                "label_ar": "Tamassee",
                "label_fr": "Tamassee"
            },
            {
                "value": 15786,
                "label": "Tamms",
                "label_ar": "تامس",
                "label_fr": "Tamms"
            },
            {
                "value": 15787,
                "label": "Tampa",
                "label_ar": "تامبا",
                "label_fr": "Tampa"
            },
            {
                "value": 15788,
                "label": "Tampico",
                "label_ar": "تامبيكو",
                "label_fr": "Tampico"
            },
            {
                "value": 15789,
                "label": "Tamworth",
                "label_ar": "تامورث",
                "label_fr": "Tamworth"
            },
            {
                "value": 15790,
                "label": "Taneytown",
                "label_ar": "تانيتاون",
                "label_fr": "Taneytown"
            },
            {
                "value": 15791,
                "label": "Taneyville",
                "label_ar": "تانيفيل",
                "label_fr": "Taneyville"
            },
            {
                "value": 15792,
                "label": "Tangent",
                "label_ar": "الظل",
                "label_fr": "Tangente"
            },
            {
                "value": 15793,
                "label": "Tangerine",
                "label_ar": "يوسفي",
                "label_fr": "Mandarine"
            },
            {
                "value": 15794,
                "label": "Tangier",
                "label_ar": "طنجة",
                "label_fr": "Tanger"
            },
            {
                "value": 15795,
                "label": "Tangipahoa",
                "label_ar": "تانجيباهوا",
                "label_fr": "Tangipahoa"
            },
            {
                "value": 15796,
                "label": "Tanner",
                "label_ar": "تانر",
                "label_fr": "Tanneur"
            },
            {
                "value": 15797,
                "label": "Tannersville",
                "label_ar": "تانرسفيل",
                "label_fr": "Tannersville"
            },
            {
                "value": 15798,
                "label": "Taos",
                "label_ar": "تاوس",
                "label_fr": "Taos"
            },
            {
                "value": 15799,
                "label": "Taos Ski Valley",
                "label_ar": "وادي تاوس للتزلج",
                "label_fr": "Vallée de ski de Taos"
            },
            {
                "value": 15800,
                "label": "Tappahannock",
                "label_ar": "تاباهانوك",
                "label_fr": "Tappahannock"
            },
            {
                "value": 15801,
                "label": "Tappan",
                "label_ar": "تابان",
                "label_fr": "Tappan"
            },
            {
                "value": 15802,
                "label": "Tappen",
                "label_ar": "تابين",
                "label_fr": "Tappen"
            },
            {
                "value": 15803,
                "label": "Tar Heel",
                "label_ar": "كعب القطران",
                "label_fr": "Talon de goudron"
            },
            {
                "value": 15804,
                "label": "Tarawa Terrace I",
                "label_ar": "Tarawa Terrace I",
                "label_fr": "Terrasse Tarawa I"
            },
            {
                "value": 15805,
                "label": "Tarboro",
                "label_ar": "تاربورو",
                "label_fr": "Tarboro"
            },
            {
                "value": 15806,
                "label": "Tarentum",
                "label_ar": "تارانتوم",
                "label_fr": "Tarente"
            },
            {
                "value": 15807,
                "label": "Tariffville",
                "label_ar": "تريففيل",
                "label_fr": "Tariffville"
            },
            {
                "value": 15808,
                "label": "Tarkio",
                "label_ar": "تاركيو",
                "label_fr": "Tarkio"
            },
            {
                "value": 15809,
                "label": "Tarpon Springs",
                "label_ar": "تاربون سبرينغز",
                "label_fr": "Tarpon Springs"
            },
            {
                "value": 15810,
                "label": "Tarrs",
                "label_ar": "Tarrs",
                "label_fr": "Tarrs"
            },
            {
                "value": 15811,
                "label": "Tarrytown",
                "label_ar": "تاريتاون",
                "label_fr": "Tarrytown"
            },
            {
                "value": 15812,
                "label": "Tarzana",
                "label_ar": "طرزانا",
                "label_fr": "Tarzana"
            },
            {
                "value": 15813,
                "label": "Tasley",
                "label_ar": "تاسلي",
                "label_fr": "Tasley"
            },
            {
                "value": 15814,
                "label": "Tatamy",
                "label_ar": "تاتامي",
                "label_fr": "Tatamy"
            },
            {
                "value": 15815,
                "label": "Tate",
                "label_ar": "تيت",
                "label_fr": "Tate"
            },
            {
                "value": 15816,
                "label": "Tatum",
                "label_ar": "تاتوم",
                "label_fr": "Tatum"
            },
            {
                "value": 15817,
                "label": "Taunton",
                "label_ar": "تونتون",
                "label_fr": "Taunton"
            },
            {
                "value": 15818,
                "label": "Tavares",
                "label_ar": "تافاريس",
                "label_fr": "Tavares"
            },
            {
                "value": 15819,
                "label": "Tavernier",
                "label_ar": "تافيرنير",
                "label_fr": "Tavernier"
            },
            {
                "value": 15820,
                "label": "Tawas City",
                "label_ar": "مدينة تواس",
                "label_fr": "Tawas City"
            },
            {
                "value": 15821,
                "label": "Taylor",
                "label_ar": "تايلور",
                "label_fr": "Taylor"
            },
            {
                "value": 15822,
                "label": "Taylor Springs",
                "label_ar": "تايلور سبرينغز",
                "label_fr": "Taylor Springs"
            },
            {
                "value": 15823,
                "label": "Taylors",
                "label_ar": "تايلورز",
                "label_fr": "Taylors"
            },
            {
                "value": 15824,
                "label": "Taylors Falls",
                "label_ar": "شلالات تايلورز",
                "label_fr": "Taylors Falls"
            },
            {
                "value": 15825,
                "label": "Taylorsville",
                "label_ar": "تايلورزفيل",
                "label_fr": "Taylorsville"
            },
            {
                "value": 15826,
                "label": "Taylorville",
                "label_ar": "تايلورفيل",
                "label_fr": "Taylorville"
            },
            {
                "value": 15827,
                "label": "Tazewell",
                "label_ar": "تازويل",
                "label_fr": "Tazewell"
            },
            {
                "value": 15828,
                "label": "Tea",
                "label_ar": "شاي",
                "label_fr": "thé"
            },
            {
                "value": 15829,
                "label": "Teachey",
                "label_ar": "تيتشي",
                "label_fr": "Teachey"
            },
            {
                "value": 15830,
                "label": "Teague",
                "label_ar": "تيج",
                "label_fr": "Teague"
            },
            {
                "value": 15831,
                "label": "Teaneck",
                "label_ar": "تينيك",
                "label_fr": "Teaneck"
            },
            {
                "value": 15832,
                "label": "Teasdale",
                "label_ar": "تيسدال",
                "label_fr": "Teasdale"
            },
            {
                "value": 15833,
                "label": "Tebbetts",
                "label_ar": "Tebbetts",
                "label_fr": "Tebbetts"
            },
            {
                "value": 15834,
                "label": "Tecumseh",
                "label_ar": "تيكومسيه",
                "label_fr": "Tecumseh"
            },
            {
                "value": 15835,
                "label": "Teec Nos Pos",
                "label_ar": "Teec Nos Pos",
                "label_fr": "Teec Nos Pos"
            },
            {
                "value": 15836,
                "label": "Tehachapi",
                "label_ar": "تيهاتشابي",
                "label_fr": "Tehachapi"
            },
            {
                "value": 15837,
                "label": "Tehama",
                "label_ar": "تهامة",
                "label_fr": "Tehama"
            },
            {
                "value": 15838,
                "label": "Tekamah",
                "label_ar": "تكامة",
                "label_fr": "Tekamah"
            },
            {
                "value": 15839,
                "label": "Tekoa",
                "label_ar": "تيكوا",
                "label_fr": "Tekoa"
            },
            {
                "value": 15840,
                "label": "Tekonsha",
                "label_ar": "تيكونشا",
                "label_fr": "Tekonsha"
            },
            {
                "value": 15841,
                "label": "Telephone",
                "label_ar": "هاتف",
                "label_fr": "Téléphone"
            },
            {
                "value": 15842,
                "label": "Telford",
                "label_ar": "تيلفورد",
                "label_fr": "Telford"
            },
            {
                "value": 15843,
                "label": "Tell City",
                "label_ar": "تل سيتي",
                "label_fr": "Dites à la ville"
            },
            {
                "value": 15844,
                "label": "Teller",
                "label_ar": "راوي",
                "label_fr": "Caissier"
            },
            {
                "value": 15845,
                "label": "Tellico Plains",
                "label_ar": "تيليكو بلينز",
                "label_fr": "Tellico Plains"
            },
            {
                "value": 15846,
                "label": "Telluride",
                "label_ar": "تيلورايد",
                "label_fr": "Telluride"
            },
            {
                "value": 15847,
                "label": "Telogia",
                "label_ar": "تيلوجيا",
                "label_fr": "Telogia"
            },
            {
                "value": 15848,
                "label": "Temecula",
                "label_ar": "تيميكولا",
                "label_fr": "Temecula"
            },
            {
                "value": 15849,
                "label": "Tempe",
                "label_ar": "تيمبي",
                "label_fr": "Tempe"
            },
            {
                "value": 15850,
                "label": "Temperance",
                "label_ar": "اعتدال",
                "label_fr": "Tempérance"
            },
            {
                "value": 15851,
                "label": "Temple",
                "label_ar": "معبد",
                "label_fr": "Temple"
            },
            {
                "value": 15852,
                "label": "Temple City",
                "label_ar": "تيمبل سيتي",
                "label_fr": "Temple City"
            },
            {
                "value": 15853,
                "label": "Temple Hills",
                "label_ar": "تلال المعبد",
                "label_fr": "Temple Hills"
            },
            {
                "value": 15854,
                "label": "Templeton",
                "label_ar": "تمبلتون",
                "label_fr": "Templeton"
            },
            {
                "value": 15855,
                "label": "Ten Mile",
                "label_ar": "عشرة أميال",
                "label_fr": "Dix milles"
            },
            {
                "value": 15856,
                "label": "Ten Sleep",
                "label_ar": "عشرة النوم",
                "label_fr": "Dix sommeil"
            },
            {
                "value": 15857,
                "label": "Tenafly",
                "label_ar": "تنفلي",
                "label_fr": "Tenafly"
            },
            {
                "value": 15858,
                "label": "Tenaha",
                "label_ar": "تيناها",
                "label_fr": "Tenaha"
            },
            {
                "value": 15859,
                "label": "Tenants Harbor",
                "label_ar": "ميناء المستأجرين",
                "label_fr": "Tenants Harbour"
            },
            {
                "value": 15860,
                "label": "Tenino",
                "label_ar": "تينينو",
                "label_fr": "Tenino"
            },
            {
                "value": 15861,
                "label": "Tennent",
                "label_ar": "تينينت",
                "label_fr": "Tennent"
            },
            {
                "value": 15862,
                "label": "Tennessee",
                "label_ar": "تينيسي",
                "label_fr": "Tennessee"
            },
            {
                "value": 15863,
                "label": "Tennessee Colony",
                "label_ar": "مستعمرة تينيسي",
                "label_fr": "Colonie du Tennessee"
            },
            {
                "value": 15864,
                "label": "Tennessee Ridge",
                "label_ar": "تينيسي ريدج",
                "label_fr": "Tennessee Ridge"
            },
            {
                "value": 15865,
                "label": "Tennille",
                "label_ar": "تينيل",
                "label_fr": "Tennille"
            },
            {
                "value": 15866,
                "label": "Tensed",
                "label_ar": "متوترة",
                "label_fr": "Tendu"
            },
            {
                "value": 15867,
                "label": "Tenstrike",
                "label_ar": "تينسترايك",
                "label_fr": "Tenstrike"
            },
            {
                "value": 15868,
                "label": "Terlingua",
                "label_ar": "تيرلينجوا",
                "label_fr": "Terlingua"
            },
            {
                "value": 15869,
                "label": "Terlton",
                "label_ar": "تيرلتون",
                "label_fr": "Terlton"
            },
            {
                "value": 15870,
                "label": "Terra Alta",
                "label_ar": "تيرا ألتا",
                "label_fr": "Terra Alta"
            },
            {
                "value": 15871,
                "label": "Terra Bella",
                "label_ar": "تيرا بيلا",
                "label_fr": "Terra Bella"
            },
            {
                "value": 15872,
                "label": "Terrace Park",
                "label_ar": "حديقة التراس",
                "label_fr": "Parc de la terrasse"
            },
            {
                "value": 15873,
                "label": "Terre Haute",
                "label_ar": "تير هوت",
                "label_fr": "Terre Haute"
            },
            {
                "value": 15874,
                "label": "Terre Hill",
                "label_ar": "تيري هيل",
                "label_fr": "Terre Hill"
            },
            {
                "value": 15875,
                "label": "Terrebonne",
                "label_ar": "تريبون",
                "label_fr": "Terrebonne"
            },
            {
                "value": 15876,
                "label": "Terrell",
                "label_ar": "تيريل",
                "label_fr": "Terrell"
            },
            {
                "value": 15877,
                "label": "Terreton",
                "label_ar": "تيريتون",
                "label_fr": "Terreton"
            },
            {
                "value": 15878,
                "label": "Terril",
                "label_ar": "تيريل",
                "label_fr": "Terril"
            },
            {
                "value": 15879,
                "label": "Terry",
                "label_ar": "تيري",
                "label_fr": "Terry"
            },
            {
                "value": 15880,
                "label": "Terryville",
                "label_ar": "تيريفيل",
                "label_fr": "Terryville"
            },
            {
                "value": 15881,
                "label": "Tescott",
                "label_ar": "تيسكوت",
                "label_fr": "Tescott"
            },
            {
                "value": 15882,
                "label": "Tesuque",
                "label_ar": "تيسوك",
                "label_fr": "Tesuque"
            },
            {
                "value": 15883,
                "label": "Teterboro",
                "label_ar": "تيتربورو",
                "label_fr": "Teterboro"
            },
            {
                "value": 15884,
                "label": "Teton",
                "label_ar": "تيتون",
                "label_fr": "TETONS"
            },
            {
                "value": 15885,
                "label": "Teton Village",
                "label_ar": "قرية تيتون",
                "label_fr": "Village de Teton"
            },
            {
                "value": 15886,
                "label": "Tetonia",
                "label_ar": "تتونيا",
                "label_fr": "Tetonia"
            },
            {
                "value": 15887,
                "label": "Teutopolis",
                "label_ar": "تيوتوبوليس",
                "label_fr": "Teutopolis"
            },
            {
                "value": 15888,
                "label": "Tewksbury",
                "label_ar": "توكسبري",
                "label_fr": "Tewksbury"
            },
            {
                "value": 15889,
                "label": "Texarkana",
                "label_ar": "تيكساركانا",
                "label_fr": "Texarkana"
            },
            {
                "value": 15890,
                "label": "Texas City",
                "label_ar": "تكساس سيتي",
                "label_fr": "Texas City"
            },
            {
                "value": 15891,
                "label": "Texhoma",
                "label_ar": "تيكشوما",
                "label_fr": "Texhoma"
            },
            {
                "value": 15892,
                "label": "Texico",
                "label_ar": "تيكسيكو",
                "label_fr": "Texico"
            },
            {
                "value": 15893,
                "label": "Texline",
                "label_ar": "تكسلين",
                "label_fr": "Texline"
            },
            {
                "value": 15894,
                "label": "Thackerville",
                "label_ar": "ثاكرفيل",
                "label_fr": "Thackerville"
            },
            {
                "value": 15895,
                "label": "Thatcher",
                "label_ar": "تاتشر",
                "label_fr": "Thatcher"
            },
            {
                "value": 15896,
                "label": "Thaxton",
                "label_ar": "ثاكستون",
                "label_fr": "Thaxton"
            },
            {
                "value": 15897,
                "label": "Thayer",
                "label_ar": "ثاير",
                "label_fr": "Thayer"
            },
            {
                "value": 15898,
                "label": "Thayne",
                "label_ar": "ثاين",
                "label_fr": "Thayne"
            },
            {
                "value": 15899,
                "label": "The Bronx",
                "label_ar": "ذا برونكس",
                "label_fr": "Le Bronx"
            },
            {
                "value": 15900,
                "label": "The Colony",
                "label_ar": "مستعمرة",
                "label_fr": "La colonie"
            },
            {
                "value": 15901,
                "label": "The Dalles",
                "label_ar": "داليس",
                "label_fr": "Les Dalles"
            },
            {
                "value": 15902,
                "label": "The Plains",
                "label_ar": "الخطط",
                "label_fr": "Les plaines"
            },
            {
                "value": 15903,
                "label": "The Rock",
                "label_ar": "الصخرة",
                "label_fr": "Le rocher"
            },
            {
                "value": 15904,
                "label": "The Villages",
                "label_ar": "القرى",
                "label_fr": "Les villages"
            },
            {
                "value": 15905,
                "label": "Thelma",
                "label_ar": "ثيلما",
                "label_fr": "Thelma"
            },
            {
                "value": 15906,
                "label": "Thendara",
                "label_ar": "ثندارا",
                "label_fr": "Thendara"
            },
            {
                "value": 15907,
                "label": "Theodore",
                "label_ar": "ثيودور",
                "label_fr": "Théodore"
            },
            {
                "value": 15908,
                "label": "Theresa",
                "label_ar": "هناك",
                "label_fr": "Il y a un"
            },
            {
                "value": 15909,
                "label": "Thermal",
                "label_ar": "حراري",
                "label_fr": "Thermique"
            },
            {
                "value": 15910,
                "label": "Thermopolis",
                "label_ar": "ثيرموبوليس",
                "label_fr": "Thermopolis"
            },
            {
                "value": 15911,
                "label": "Thetford Center",
                "label_ar": "مركز ثيتفورد",
                "label_fr": "Thetford Centre"
            },
            {
                "value": 15912,
                "label": "Thibodaux",
                "label_ar": "ثيبودوكس",
                "label_fr": "Thibodaux"
            },
            {
                "value": 15913,
                "label": "Thief River Falls",
                "label_ar": "شلالات نهر اللص",
                "label_fr": "Chutes de la rivière Thief"
            },
            {
                "value": 15914,
                "label": "Thiells",
                "label_ar": "ثيلس",
                "label_fr": "Thiells"
            },
            {
                "value": 15915,
                "label": "Thiensville",
                "label_ar": "ثينسفيل",
                "label_fr": "Thiensville"
            },
            {
                "value": 15916,
                "label": "Thomas",
                "label_ar": "توماس",
                "label_fr": "Thomas"
            },
            {
                "value": 15917,
                "label": "Thomasboro",
                "label_ar": "توماسبورو",
                "label_fr": "Thomasboro"
            },
            {
                "value": 15918,
                "label": "Thomaston",
                "label_ar": "توماستون",
                "label_fr": "Thomaston"
            },
            {
                "value": 15919,
                "label": "Thomasville",
                "label_ar": "توماسفيل",
                "label_fr": "Thomasville"
            },
            {
                "value": 15920,
                "label": "Thompson",
                "label_ar": "طومسون",
                "label_fr": "Thompson"
            },
            {
                "value": 15921,
                "label": "Thompson Falls",
                "label_ar": "شلالات طومسون",
                "label_fr": "Thompson Falls"
            },
            {
                "value": 15922,
                "label": "Thompson's Station",
                "label_ar": "محطة طومسون",
                "label_fr": "Gare de Thompson"
            },
            {
                "value": 15923,
                "label": "Thompsontown",
                "label_ar": "طومسونتاون",
                "label_fr": "Thompsontown"
            },
            {
                "value": 15924,
                "label": "Thompsonville",
                "label_ar": "طومسونفيل",
                "label_fr": "Thompsonville"
            },
            {
                "value": 15925,
                "label": "Thomson",
                "label_ar": "طومسون",
                "label_fr": "Thomson"
            },
            {
                "value": 15926,
                "label": "Thonotosassa",
                "label_ar": "ثونوتساسا",
                "label_fr": "Thonotosassa"
            },
            {
                "value": 15927,
                "label": "Thoreau",
                "label_ar": "ثورو",
                "label_fr": "Thoreau"
            },
            {
                "value": 15928,
                "label": "Thorn Hill",
                "label_ar": "ثورن هيل",
                "label_fr": "Thorn Hill"
            },
            {
                "value": 15929,
                "label": "Thornburg",
                "label_ar": "ثورنبرج",
                "label_fr": "Thornburg"
            },
            {
                "value": 15930,
                "label": "Thorndale",
                "label_ar": "ثورنديل",
                "label_fr": "Thorndale"
            },
            {
                "value": 15931,
                "label": "Thorndike",
                "label_ar": "ثورندايك",
                "label_fr": "Thorndike"
            },
            {
                "value": 15932,
                "label": "Thorne Bay",
                "label_ar": "ثورن باي",
                "label_fr": "Baie de Thorne"
            },
            {
                "value": 15933,
                "label": "Thornton",
                "label_ar": "ثورنتون",
                "label_fr": "Thornton"
            },
            {
                "value": 15934,
                "label": "Thorntown",
                "label_ar": "ثورنتاون",
                "label_fr": "Thorntown"
            },
            {
                "value": 15935,
                "label": "Thornville",
                "label_ar": "ثورنفيل",
                "label_fr": "Thornville"
            },
            {
                "value": 15936,
                "label": "Thornwood",
                "label_ar": "ثورنوود",
                "label_fr": "Thornwood"
            },
            {
                "value": 15937,
                "label": "Thorofare",
                "label_ar": "ثوروفير",
                "label_fr": "Thorofare"
            },
            {
                "value": 15938,
                "label": "Thorp",
                "label_ar": "ثورب",
                "label_fr": "Thorp"
            },
            {
                "value": 15939,
                "label": "Thousand Oaks",
                "label_ar": "ألف السنديان",
                "label_fr": "Mille chênes"
            },
            {
                "value": 15940,
                "label": "Thousand Palms",
                "label_ar": "ألف نخلة",
                "label_fr": "Mille paumes"
            },
            {
                "value": 15941,
                "label": "Thrall",
                "label_ar": "عبث",
                "label_fr": "Servitude"
            },
            {
                "value": 15942,
                "label": "Three Bridges",
                "label_ar": "ثلاثة جسور",
                "label_fr": "Trois ponts"
            },
            {
                "value": 15943,
                "label": "Three Forks",
                "label_ar": "ثلاث شوكات",
                "label_fr": "Trois fourchettes"
            },
            {
                "value": 15944,
                "label": "Three Lakes",
                "label_ar": "ثلاث بحيرات",
                "label_fr": "Trois lacs"
            },
            {
                "value": 15945,
                "label": "Three Mile Bay",
                "label_ar": "ثري مايل باي",
                "label_fr": "Three Mile Bay"
            },
            {
                "value": 15946,
                "label": "Three Oaks",
                "label_ar": "ثلاثة أوكس",
                "label_fr": "Trois chênes"
            },
            {
                "value": 15947,
                "label": "Three Rivers",
                "label_ar": "ثلاث أنهار",
                "label_fr": "Trois fleuves"
            },
            {
                "value": 15948,
                "label": "Three Springs",
                "label_ar": "ثلاثة ينابيع",
                "label_fr": "Trois sources"
            },
            {
                "value": 15949,
                "label": "Throckmorton",
                "label_ar": "ثروكمورتون",
                "label_fr": "Throckmorton"
            },
            {
                "value": 15950,
                "label": "Thurmond",
                "label_ar": "ثورموند",
                "label_fr": "Thurmond"
            },
            {
                "value": 15951,
                "label": "Thurmont",
                "label_ar": "ثورمونت",
                "label_fr": "Thurmont"
            },
            {
                "value": 15952,
                "label": "Thurston",
                "label_ar": "ثورستون",
                "label_fr": "Thurston"
            },
            {
                "value": 15953,
                "label": "Tiburon",
                "label_ar": "تيبورون",
                "label_fr": "Tiburon"
            },
            {
                "value": 15954,
                "label": "Tickfaw",
                "label_ar": "Tickfaw",
                "label_fr": "Tickfaw"
            },
            {
                "value": 15955,
                "label": "Ticonderoga",
                "label_ar": "تيكونديروجا",
                "label_fr": "Ticonderoga"
            },
            {
                "value": 15956,
                "label": "Tidioute",
                "label_ar": "تيديوت",
                "label_fr": "Tidioute"
            },
            {
                "value": 15957,
                "label": "Tierra Amarilla",
                "label_ar": "تييرا أماريلا",
                "label_fr": "Tierra Amarilla"
            },
            {
                "value": 15958,
                "label": "Tieton",
                "label_ar": "تيتون",
                "label_fr": "Tieton"
            },
            {
                "value": 15959,
                "label": "Tiffin",
                "label_ar": "تيفين",
                "label_fr": "Repas de midi"
            },
            {
                "value": 15960,
                "label": "Tifton",
                "label_ar": "تيفتون",
                "label_fr": "Tifton"
            },
            {
                "value": 15961,
                "label": "Tigard",
                "label_ar": "تيغارد",
                "label_fr": "Tigard"
            },
            {
                "value": 15962,
                "label": "Tiger",
                "label_ar": "نمر",
                "label_fr": "tigre"
            },
            {
                "value": 15963,
                "label": "Tigerton",
                "label_ar": "تيجرتون",
                "label_fr": "Tigerton"
            },
            {
                "value": 15964,
                "label": "Tigerville",
                "label_ar": "تيجرفيل",
                "label_fr": "Tigerville"
            },
            {
                "value": 15965,
                "label": "Tignall",
                "label_ar": "تيغنال",
                "label_fr": "Tignall"
            },
            {
                "value": 15966,
                "label": "Tijeras",
                "label_ar": "تيجراس",
                "label_fr": "Tijeras"
            },
            {
                "value": 15967,
                "label": "Tilden",
                "label_ar": "تيلدن",
                "label_fr": "Tilden"
            },
            {
                "value": 15968,
                "label": "Tilghman",
                "label_ar": "تيلغمان",
                "label_fr": "Tilghman"
            },
            {
                "value": 15969,
                "label": "Tiline",
                "label_ar": "تلين",
                "label_fr": "Tiline"
            },
            {
                "value": 15970,
                "label": "Tillamook",
                "label_ar": "تيلاموك",
                "label_fr": "Tillamook"
            },
            {
                "value": 15971,
                "label": "Tillar",
                "label_ar": "تيلار",
                "label_fr": "Tillar"
            },
            {
                "value": 15972,
                "label": "Tilleda",
                "label_ar": "تيلدا",
                "label_fr": "Tilleda"
            },
            {
                "value": 15973,
                "label": "Tillson",
                "label_ar": "تيلسون",
                "label_fr": "Tillson"
            },
            {
                "value": 15974,
                "label": "Tilton",
                "label_ar": "تيلتون",
                "label_fr": "Tilton"
            },
            {
                "value": 15975,
                "label": "Tiltonsville",
                "label_ar": "تيلتونسفيل",
                "label_fr": "Tiltonsville"
            },
            {
                "value": 15976,
                "label": "Timber Lake",
                "label_ar": "بحيرة الأخشاب",
                "label_fr": "Lac Timber"
            },
            {
                "value": 15977,
                "label": "Timberlake",
                "label_ar": "تيمبرليك",
                "label_fr": "Timberlake"
            },
            {
                "value": 15978,
                "label": "Timberon",
                "label_ar": "تيمبرون",
                "label_fr": "Timberon"
            },
            {
                "value": 15979,
                "label": "Timberville",
                "label_ar": "تيمبرفيل",
                "label_fr": "Timberville"
            },
            {
                "value": 15980,
                "label": "Timbo",
                "label_ar": "تيمبو",
                "label_fr": "Timbo"
            },
            {
                "value": 15981,
                "label": "Timewell",
                "label_ar": "تيمويل",
                "label_fr": "Timewell"
            },
            {
                "value": 15982,
                "label": "Timmonsville",
                "label_ar": "تيمونسفيل",
                "label_fr": "Timmonsville"
            },
            {
                "value": 15983,
                "label": "Timpson",
                "label_ar": "تيمبسون",
                "label_fr": "Timpson"
            },
            {
                "value": 15984,
                "label": "Tingley",
                "label_ar": "تينجلي",
                "label_fr": "Tingley"
            },
            {
                "value": 15985,
                "label": "Tinley Park",
                "label_ar": "تينلي بارك",
                "label_fr": "Parc Tinley"
            },
            {
                "value": 15986,
                "label": "Tioga",
                "label_ar": "تيوجا",
                "label_fr": "Tioga"
            },
            {
                "value": 15987,
                "label": "Tiona",
                "label_ar": "تيونا",
                "label_fr": "Tiona"
            },
            {
                "value": 15988,
                "label": "Tionesta",
                "label_ar": "تيونيستا",
                "label_fr": "Tionesta"
            },
            {
                "value": 15989,
                "label": "Tipp City",
                "label_ar": "مدينة تيب",
                "label_fr": "Tipp City"
            },
            {
                "value": 15990,
                "label": "Tipton",
                "label_ar": "تيبتون",
                "label_fr": "Tipton"
            },
            {
                "value": 15991,
                "label": "Tiptonville",
                "label_ar": "تيبتونفيل",
                "label_fr": "Tiptonville"
            },
            {
                "value": 15992,
                "label": "Tire Hill",
                "label_ar": "تل صور",
                "label_fr": "Tire Hill"
            },
            {
                "value": 15993,
                "label": "Tiro",
                "label_ar": "تيرو",
                "label_fr": "Tiro"
            },
            {
                "value": 15994,
                "label": "Tishomingo",
                "label_ar": "تيشومينغو",
                "label_fr": "Tishomingo"
            },
            {
                "value": 15995,
                "label": "Tiskilwa",
                "label_ar": "تيسكيلوا",
                "label_fr": "Tiskilwa"
            },
            {
                "value": 15996,
                "label": "Titonka",
                "label_ar": "تيتونكا",
                "label_fr": "Titonka"
            },
            {
                "value": 15997,
                "label": "Titus",
                "label_ar": "تيطس",
                "label_fr": "Titus"
            },
            {
                "value": 15998,
                "label": "Titusville",
                "label_ar": "تيتوسفيل",
                "label_fr": "Titusville"
            },
            {
                "value": 15999,
                "label": "Tiverton",
                "label_ar": "تيفرتون",
                "label_fr": "Tiverton"
            },
            {
                "value": 16000,
                "label": "Tivoli",
                "label_ar": "تيفولي",
                "label_fr": "Tivoli"
            },
            {
                "value": 16001,
                "label": "Toano",
                "label_ar": "توانو",
                "label_fr": "Toano"
            },
            {
                "value": 16002,
                "label": "Tobaccoville",
                "label_ar": "توباكوفيل",
                "label_fr": "Tobaccoville"
            },
            {
                "value": 16003,
                "label": "Tobyhanna",
                "label_ar": "توبيهانا",
                "label_fr": "Tobyhanna"
            },
            {
                "value": 16004,
                "label": "Toccoa",
                "label_ar": "توكوا",
                "label_fr": "Toccoa"
            },
            {
                "value": 16005,
                "label": "Toccoa Falls",
                "label_ar": "شلالات توكوا",
                "label_fr": "Toccoa Falls"
            },
            {
                "value": 16006,
                "label": "Todd",
                "label_ar": "تود",
                "label_fr": "Todd"
            },
            {
                "value": 16007,
                "label": "Toddville",
                "label_ar": "تودفيل",
                "label_fr": "Toddville"
            },
            {
                "value": 16008,
                "label": "Tofte",
                "label_ar": "توفت",
                "label_fr": "Tofte"
            },
            {
                "value": 16009,
                "label": "Togiak",
                "label_ar": "توجياك",
                "label_fr": "Togiak"
            },
            {
                "value": 16010,
                "label": "Toivola",
                "label_ar": "تويفولا",
                "label_fr": "Toivola"
            },
            {
                "value": 16011,
                "label": "Tok",
                "label_ar": "توك",
                "label_fr": "Tok"
            },
            {
                "value": 16012,
                "label": "Toksook Bay",
                "label_ar": "خليج توكسوك",
                "label_fr": "Toksook Bay"
            },
            {
                "value": 16013,
                "label": "Tolar",
                "label_ar": "تولار",
                "label_fr": "Tolar"
            },
            {
                "value": 16014,
                "label": "Toledo",
                "label_ar": "توليدو",
                "label_fr": "Toledo"
            },
            {
                "value": 16015,
                "label": "Tolland",
                "label_ar": "تولاند",
                "label_fr": "Tolland"
            },
            {
                "value": 16016,
                "label": "Tollesboro",
                "label_ar": "توليسبورو",
                "label_fr": "Tollesboro"
            },
            {
                "value": 16017,
                "label": "Tolleson",
                "label_ar": "تولسون",
                "label_fr": "Tolleson"
            },
            {
                "value": 16018,
                "label": "Tollhouse",
                "label_ar": "تولهاوس",
                "label_fr": "Péage"
            },
            {
                "value": 16019,
                "label": "Tolna",
                "label_ar": "تولنا",
                "label_fr": "Tolna"
            },
            {
                "value": 16020,
                "label": "Tolono",
                "label_ar": "تولونو",
                "label_fr": "Tolono"
            },
            {
                "value": 16021,
                "label": "Toluca",
                "label_ar": "تولوكا",
                "label_fr": "Toluca"
            },
            {
                "value": 16022,
                "label": "Tom Bean",
                "label_ar": "توم بين",
                "label_fr": "Tom Bean"
            },
            {
                "value": 16023,
                "label": "Tomah",
                "label_ar": "توماه",
                "label_fr": "Tomah"
            },
            {
                "value": 16024,
                "label": "Tomahawk",
                "label_ar": "توماهوك",
                "label_fr": "Tomahawk"
            },
            {
                "value": 16025,
                "label": "Tomball",
                "label_ar": "تومبال",
                "label_fr": "Tomball"
            },
            {
                "value": 16026,
                "label": "Tombstone",
                "label_ar": "علامة مميزة",
                "label_fr": "Pierre tombale"
            },
            {
                "value": 16027,
                "label": "Tomkins Cove",
                "label_ar": "تومكينز كوف",
                "label_fr": "Tomkins Cove"
            },
            {
                "value": 16028,
                "label": "Tompkinsville",
                "label_ar": "تومبكينسفيل",
                "label_fr": "Tompkinsville"
            },
            {
                "value": 16029,
                "label": "Toms Brook",
                "label_ar": "تومز بروك",
                "label_fr": "Toms Brook"
            },
            {
                "value": 16030,
                "label": "Toms River",
                "label_ar": "نهر تومس",
                "label_fr": "Rivière Toms"
            },
            {
                "value": 16031,
                "label": "Tonasket",
                "label_ar": "توناسكيت",
                "label_fr": "Tonasket"
            },
            {
                "value": 16032,
                "label": "Tonawanda",
                "label_ar": "توناواندا",
                "label_fr": "Tonawanda"
            },
            {
                "value": 16033,
                "label": "Toney",
                "label_ar": "توني",
                "label_fr": "Toney"
            },
            {
                "value": 16034,
                "label": "Tonganoxie",
                "label_ar": "تونجانوكسي",
                "label_fr": "Tonganoxie"
            },
            {
                "value": 16035,
                "label": "Tonica",
                "label_ar": "تونيكا",
                "label_fr": "Tonica"
            },
            {
                "value": 16036,
                "label": "Tonkawa",
                "label_ar": "تونكاوا",
                "label_fr": "Tonkawa"
            },
            {
                "value": 16037,
                "label": "Tonopah",
                "label_ar": "تونوباه",
                "label_fr": "Tonopah"
            },
            {
                "value": 16038,
                "label": "Tontitown",
                "label_ar": "تونتيتاون",
                "label_fr": "Tontitown"
            },
            {
                "value": 16039,
                "label": "Tonto Basin",
                "label_ar": "حوض تونتو",
                "label_fr": "Bassin de Tonto"
            },
            {
                "value": 16040,
                "label": "Tony",
                "label_ar": "توني",
                "label_fr": "Tony"
            },
            {
                "value": 16041,
                "label": "Tooele",
                "label_ar": "جدا",
                "label_fr": "Tooele"
            },
            {
                "value": 16042,
                "label": "Toomsboro",
                "label_ar": "تومسبورو",
                "label_fr": "Toomsboro"
            },
            {
                "value": 16043,
                "label": "Toomsuba",
                "label_ar": "تومسوبا",
                "label_fr": "Toomsuba"
            },
            {
                "value": 16044,
                "label": "Toone",
                "label_ar": "تون",
                "label_fr": "À une"
            },
            {
                "value": 16045,
                "label": "Topanga",
                "label_ar": "توبانجا",
                "label_fr": "Topanga"
            },
            {
                "value": 16046,
                "label": "Topeka",
                "label_ar": "توبيكا",
                "label_fr": "Topeka"
            },
            {
                "value": 16047,
                "label": "Topock",
                "label_ar": "توبوك",
                "label_fr": "Topock"
            },
            {
                "value": 16048,
                "label": "Toppenish",
                "label_ar": "توبينيش",
                "label_fr": "Toppenish"
            },
            {
                "value": 16049,
                "label": "Topping",
                "label_ar": "تتصدر",
                "label_fr": "Garniture"
            },
            {
                "value": 16050,
                "label": "Topsfield",
                "label_ar": "توبسفيلد",
                "label_fr": "Topsfield"
            },
            {
                "value": 16051,
                "label": "Topsham",
                "label_ar": "توبشام",
                "label_fr": "Topsham"
            },
            {
                "value": 16052,
                "label": "Topton",
                "label_ar": "توبتون",
                "label_fr": "Topton"
            },
            {
                "value": 16053,
                "label": "Tornado",
                "label_ar": "إعصار",
                "label_fr": "Tornade"
            },
            {
                "value": 16054,
                "label": "Tornillo",
                "label_ar": "تورنيو",
                "label_fr": "Tornillo"
            },
            {
                "value": 16055,
                "label": "Toronto",
                "label_ar": "تورنتو",
                "label_fr": "Toronto"
            },
            {
                "value": 16056,
                "label": "Torrance",
                "label_ar": "تورانس",
                "label_fr": "Torrance"
            },
            {
                "value": 16057,
                "label": "Torreon",
                "label_ar": "توريون",
                "label_fr": "Torreon"
            },
            {
                "value": 16058,
                "label": "Torrey",
                "label_ar": "توري",
                "label_fr": "Torrey"
            },
            {
                "value": 16059,
                "label": "Torrington",
                "label_ar": "تورينجتون",
                "label_fr": "Torrington"
            },
            {
                "value": 16060,
                "label": "Totowa",
                "label_ar": "توتووا",
                "label_fr": "Totowa"
            },
            {
                "value": 16061,
                "label": "Totz",
                "label_ar": "توتز",
                "label_fr": "Totz"
            },
            {
                "value": 16062,
                "label": "Tougaloo",
                "label_ar": "توجالو",
                "label_fr": "Tougaloo"
            },
            {
                "value": 16063,
                "label": "Toughkenamon",
                "label_ar": "توكينامون",
                "label_fr": "Toughkenamon"
            },
            {
                "value": 16064,
                "label": "Toulon",
                "label_ar": "طولون",
                "label_fr": "Toulon"
            },
            {
                "value": 16065,
                "label": "Toutle",
                "label_ar": "توتلي",
                "label_fr": "Toutle"
            },
            {
                "value": 16066,
                "label": "Tovey",
                "label_ar": "توفى",
                "label_fr": "Tovey"
            },
            {
                "value": 16067,
                "label": "Towaco",
                "label_ar": "تواكو",
                "label_fr": "Towaco"
            },
            {
                "value": 16068,
                "label": "Towanda",
                "label_ar": "تواندا",
                "label_fr": "Towanda"
            },
            {
                "value": 16069,
                "label": "Tower",
                "label_ar": "برج",
                "label_fr": "La tour"
            },
            {
                "value": 16070,
                "label": "Tower City",
                "label_ar": "برج المدينة",
                "label_fr": "Tower City"
            },
            {
                "value": 16071,
                "label": "Tower Hill",
                "label_ar": "تلة البرج",
                "label_fr": "Tower Hill"
            },
            {
                "value": 16072,
                "label": "Town Creek",
                "label_ar": "تاون كريك",
                "label_fr": "Town Creek"
            },
            {
                "value": 16073,
                "label": "Town of Boxborough",
                "label_ar": "بلدة بوكسبورو",
                "label_fr": "Ville de Boxborough"
            },
            {
                "value": 16074,
                "label": "Town of Bozrah",
                "label_ar": "بلدة بوزرة",
                "label_fr": "Ville de Bozrah"
            },
            {
                "value": 16075,
                "label": "Town of East Lyme",
                "label_ar": "بلدة إيست لايم",
                "label_fr": "Ville d'East Lyme"
            },
            {
                "value": 16076,
                "label": "Town of Mount Desert",
                "label_ar": "بلدة جبل الصحراء",
                "label_fr": "Ville de Mount Desert"
            },
            {
                "value": 16077,
                "label": "Towner",
                "label_ar": "تاونر",
                "label_fr": "Towner"
            },
            {
                "value": 16078,
                "label": "Townsend",
                "label_ar": "تاونسند",
                "label_fr": "Townsend"
            },
            {
                "value": 16079,
                "label": "Townshend",
                "label_ar": "تاونسند",
                "label_fr": "Townshend"
            },
            {
                "value": 16080,
                "label": "Townville",
                "label_ar": "تاونفيل",
                "label_fr": "Townville"
            },
            {
                "value": 16081,
                "label": "Towson",
                "label_ar": "توسون",
                "label_fr": "Towson"
            },
            {
                "value": 16082,
                "label": "Toxey",
                "label_ar": "توكسي",
                "label_fr": "Toxey"
            },
            {
                "value": 16083,
                "label": "Trabuco Canyon",
                "label_ar": "ترابوكو كانيون",
                "label_fr": "Canyon de Trabuco"
            },
            {
                "value": 16084,
                "label": "Tracy",
                "label_ar": "تريسي",
                "label_fr": "Tracy"
            },
            {
                "value": 16085,
                "label": "Tracy City",
                "label_ar": "تريسي سيتي",
                "label_fr": "Tracy City"
            },
            {
                "value": 16086,
                "label": "Tracys Landing",
                "label_ar": "هبوط Tracys",
                "label_fr": "Tracys Landing"
            },
            {
                "value": 16087,
                "label": "Tracyton",
                "label_ar": "تراسيتون",
                "label_fr": "Tracyton"
            },
            {
                "value": 16088,
                "label": "Trade",
                "label_ar": "التجارة",
                "label_fr": "Commerce"
            },
            {
                "value": 16089,
                "label": "Traer",
                "label_ar": "تراير",
                "label_fr": "Traer"
            },
            {
                "value": 16090,
                "label": "Trafalgar",
                "label_ar": "ترافالغار",
                "label_fr": "Trafalgar"
            },
            {
                "value": 16091,
                "label": "Trafford",
                "label_ar": "ترافورد",
                "label_fr": "Trafford"
            },
            {
                "value": 16092,
                "label": "Trail",
                "label_ar": "ممر المشاة",
                "label_fr": "Piste"
            },
            {
                "value": 16093,
                "label": "Transfer",
                "label_ar": "نقل",
                "label_fr": "Transfert"
            },
            {
                "value": 16094,
                "label": "Traphill",
                "label_ar": "ترافيل",
                "label_fr": "Traphill"
            },
            {
                "value": 16095,
                "label": "Trappe",
                "label_ar": "تراب",
                "label_fr": "Trappe"
            },
            {
                "value": 16096,
                "label": "Travelers Rest",
                "label_ar": "راحة المسافرين",
                "label_fr": "Repos des voyageurs"
            },
            {
                "value": 16097,
                "label": "Traverse City",
                "label_ar": "ترافيرس سيتي",
                "label_fr": "Traverse City"
            },
            {
                "value": 16098,
                "label": "Treadwell",
                "label_ar": "تريدويل",
                "label_fr": "Treadwell"
            },
            {
                "value": 16099,
                "label": "Trego",
                "label_ar": "تريجو",
                "label_fr": "Trego"
            },
            {
                "value": 16100,
                "label": "Treichlers",
                "label_ar": "Treichlers",
                "label_fr": "Treichlers"
            },
            {
                "value": 16101,
                "label": "Trementina",
                "label_ar": "تريمينتينا",
                "label_fr": "Trementina"
            },
            {
                "value": 16102,
                "label": "Tremont",
                "label_ar": "تريمونت",
                "label_fr": "Tremont"
            },
            {
                "value": 16103,
                "label": "Tremonton",
                "label_ar": "تريمونتون",
                "label_fr": "Tremonton"
            },
            {
                "value": 16104,
                "label": "Trempealeau",
                "label_ar": "Trempealeau",
                "label_fr": "Trempealeau"
            },
            {
                "value": 16105,
                "label": "Trenary",
                "label_ar": "تريناري",
                "label_fr": "Trenary"
            },
            {
                "value": 16106,
                "label": "Trent",
                "label_ar": "ترينت",
                "label_fr": "Trent"
            },
            {
                "value": 16107,
                "label": "Trenton",
                "label_ar": "ترينتون",
                "label_fr": "Trenton"
            },
            {
                "value": 16108,
                "label": "Tres Pinos",
                "label_ar": "تريس بينوس",
                "label_fr": "Tres Pinos"
            },
            {
                "value": 16109,
                "label": "Tresckow",
                "label_ar": "تريسكوف",
                "label_fr": "Tresckow"
            },
            {
                "value": 16110,
                "label": "Trevett",
                "label_ar": "تريفيت",
                "label_fr": "Trevett"
            },
            {
                "value": 16111,
                "label": "Trevor",
                "label_ar": "تريفور",
                "label_fr": "Trevor"
            },
            {
                "value": 16112,
                "label": "Trevorton",
                "label_ar": "تريفورتون",
                "label_fr": "Trevorton"
            },
            {
                "value": 16113,
                "label": "Trexlertown",
                "label_ar": "تريكليرتاون",
                "label_fr": "Trexlertown"
            },
            {
                "value": 16114,
                "label": "Treynor",
                "label_ar": "ترينور",
                "label_fr": "Treynor"
            },
            {
                "value": 16115,
                "label": "Trezevant",
                "label_ar": "تريزيفانت",
                "label_fr": "Trezevant"
            },
            {
                "value": 16116,
                "label": "Triadelphia",
                "label_ar": "ترياديلفيا",
                "label_fr": "Triadelphia"
            },
            {
                "value": 16117,
                "label": "Triangle",
                "label_ar": "مثلث",
                "label_fr": "Triangle"
            },
            {
                "value": 16118,
                "label": "Tribes Hill",
                "label_ar": "القبائل هيل",
                "label_fr": "Tribes Hill"
            },
            {
                "value": 16119,
                "label": "Tribune",
                "label_ar": "منبر",
                "label_fr": "Tribune"
            },
            {
                "value": 16120,
                "label": "Trimble",
                "label_ar": "تريمبل",
                "label_fr": "Trimble"
            },
            {
                "value": 16121,
                "label": "Trimont",
                "label_ar": "تريمونت",
                "label_fr": "Trimont"
            },
            {
                "value": 16122,
                "label": "Trinidad",
                "label_ar": "ترينيداد",
                "label_fr": "Trinidad"
            },
            {
                "value": 16123,
                "label": "Trinity",
                "label_ar": "الثالوث",
                "label_fr": "Trinité"
            },
            {
                "value": 16124,
                "label": "Trion",
                "label_ar": "تريون",
                "label_fr": "Trion"
            },
            {
                "value": 16125,
                "label": "Tripoli",
                "label_ar": "طرابلس",
                "label_fr": "Tripoli"
            },
            {
                "value": 16126,
                "label": "Tripp",
                "label_ar": "تريب",
                "label_fr": "Tripp"
            },
            {
                "value": 16127,
                "label": "Triumph",
                "label_ar": "انتصار",
                "label_fr": "Triomphe"
            },
            {
                "value": 16128,
                "label": "Trivoli",
                "label_ar": "تريفولي",
                "label_fr": "Trivoli"
            },
            {
                "value": 16129,
                "label": "Trona",
                "label_ar": "ترونا",
                "label_fr": "Trona"
            },
            {
                "value": 16130,
                "label": "Tropic",
                "label_ar": "مدار",
                "label_fr": "Tropique"
            },
            {
                "value": 16131,
                "label": "Trosper",
                "label_ar": "تروسبر",
                "label_fr": "Trosper"
            },
            {
                "value": 16132,
                "label": "Troup",
                "label_ar": "فرقة",
                "label_fr": "Troup"
            },
            {
                "value": 16133,
                "label": "Troupsburg",
                "label_ar": "تروبسبورغ",
                "label_fr": "Troupsburg"
            },
            {
                "value": 16134,
                "label": "Trout Creek",
                "label_ar": "تراوت كريك",
                "label_fr": "Trout Creek"
            },
            {
                "value": 16135,
                "label": "Trout Run",
                "label_ar": "الجري التراوت",
                "label_fr": "Course à la truite"
            },
            {
                "value": 16136,
                "label": "Troutdale",
                "label_ar": "تروتديل",
                "label_fr": "Troutdale"
            },
            {
                "value": 16137,
                "label": "Troutman",
                "label_ar": "تروتمان",
                "label_fr": "Troutman"
            },
            {
                "value": 16138,
                "label": "Troutville",
                "label_ar": "تروتفيل",
                "label_fr": "Troutville"
            },
            {
                "value": 16139,
                "label": "Troy",
                "label_ar": "طروادة",
                "label_fr": "Troie"
            },
            {
                "value": 16140,
                "label": "Truchas",
                "label_ar": "تروشاس",
                "label_fr": "Truchas"
            },
            {
                "value": 16141,
                "label": "Truckee",
                "label_ar": "تروكي",
                "label_fr": "Truckee"
            },
            {
                "value": 16142,
                "label": "Trufant",
                "label_ar": "تروفانت",
                "label_fr": "Trufant"
            },
            {
                "value": 16143,
                "label": "Truman",
                "label_ar": "ترومان",
                "label_fr": "Truman"
            },
            {
                "value": 16144,
                "label": "Trumann",
                "label_ar": "ترومان",
                "label_fr": "Trumann"
            },
            {
                "value": 16145,
                "label": "Trumansburg",
                "label_ar": "ترومانسبورغ",
                "label_fr": "Trumansburg"
            },
            {
                "value": 16146,
                "label": "Trumbauersville",
                "label_ar": "ترومباويرسفيل",
                "label_fr": "Trumbauersville"
            },
            {
                "value": 16147,
                "label": "Trumbull",
                "label_ar": "ترمبل",
                "label_fr": "Trumbull"
            },
            {
                "value": 16148,
                "label": "Truro",
                "label_ar": "ترورو",
                "label_fr": "Truro"
            },
            {
                "value": 16149,
                "label": "Trussville",
                "label_ar": "تروسفيل",
                "label_fr": "Trussville"
            },
            {
                "value": 16150,
                "label": "Truth or Consequences",
                "label_ar": "الحقيقة أو العواقب",
                "label_fr": "Vérité ou conséquences"
            },
            {
                "value": 16151,
                "label": "Truxton",
                "label_ar": "تروكستون",
                "label_fr": "Truxton"
            },
            {
                "value": 16152,
                "label": "Tryon",
                "label_ar": "حاول في",
                "label_fr": "Essayer"
            },
            {
                "value": 16153,
                "label": "Tsaile",
                "label_ar": "تسايلي",
                "label_fr": "Tsaile"
            },
            {
                "value": 16154,
                "label": "Tualatin",
                "label_ar": "توالاتين",
                "label_fr": "Tualatin"
            },
            {
                "value": 16155,
                "label": "Tuba City",
                "label_ar": "مدينة طوبا",
                "label_fr": "Tuba City"
            },
            {
                "value": 16156,
                "label": "Tubac",
                "label_ar": "توباك",
                "label_fr": "Tubac"
            },
            {
                "value": 16157,
                "label": "Tuckahoe",
                "label_ar": "توكاهو",
                "label_fr": "Tuckahoe"
            },
            {
                "value": 16158,
                "label": "Tuckasegee",
                "label_ar": "Tuckasegee",
                "label_fr": "Tuckasegee"
            },
            {
                "value": 16159,
                "label": "Tucker",
                "label_ar": "تاكر",
                "label_fr": "Tucker"
            },
            {
                "value": 16160,
                "label": "Tuckerman",
                "label_ar": "تاكرمان",
                "label_fr": "Tuckerman"
            },
            {
                "value": 16161,
                "label": "Tuckerton",
                "label_ar": "تاكرتون",
                "label_fr": "Tuckerton"
            },
            {
                "value": 16162,
                "label": "Tucson",
                "label_ar": "توكسون",
                "label_fr": "Tucson"
            },
            {
                "value": 16163,
                "label": "Tucumcari",
                "label_ar": "توكومكاري",
                "label_fr": "Tucumcari"
            },
            {
                "value": 16164,
                "label": "Tujunga",
                "label_ar": "توجونجا",
                "label_fr": "Tujunga"
            },
            {
                "value": 16165,
                "label": "Tulare",
                "label_ar": "تولير",
                "label_fr": "Tulare"
            },
            {
                "value": 16166,
                "label": "Tulare County",
                "label_ar": "مقاطعة تيولير",
                "label_fr": "Comté de Tulare"
            },
            {
                "value": 16167,
                "label": "Tularosa",
                "label_ar": "تولاروزا",
                "label_fr": "Tularosa"
            },
            {
                "value": 16168,
                "label": "Tulelake",
                "label_ar": "توليليك",
                "label_fr": "Tulelake"
            },
            {
                "value": 16169,
                "label": "Tulia",
                "label_ar": "توليا",
                "label_fr": "Tulia"
            },
            {
                "value": 16170,
                "label": "Tullahoma",
                "label_ar": "تولاوما",
                "label_fr": "Tullahoma"
            },
            {
                "value": 16171,
                "label": "Tully",
                "label_ar": "تولي",
                "label_fr": "Tully"
            },
            {
                "value": 16172,
                "label": "Tulsa",
                "label_ar": "تولسا",
                "label_fr": "Tulsa"
            },
            {
                "value": 16173,
                "label": "Tumacacori",
                "label_ar": "توماكوري",
                "label_fr": "Tumacacori"
            },
            {
                "value": 16174,
                "label": "Tumtum",
                "label_ar": "توم توم",
                "label_fr": "Tumtum"
            },
            {
                "value": 16175,
                "label": "Tumwater",
                "label_ar": "تومواتر",
                "label_fr": "Tumwater"
            },
            {
                "value": 16176,
                "label": "Tunas",
                "label_ar": "التونة",
                "label_fr": "Thons"
            },
            {
                "value": 16177,
                "label": "Tunbridge",
                "label_ar": "تونبريدج",
                "label_fr": "Tunbridge"
            },
            {
                "value": 16178,
                "label": "Tunica",
                "label_ar": "تونيكا",
                "label_fr": "Tunica"
            },
            {
                "value": 16179,
                "label": "Tunkhannock",
                "label_ar": "تونخانوك",
                "label_fr": "Tunkhannock"
            },
            {
                "value": 16180,
                "label": "Tunnel Hill",
                "label_ar": "نفق هيل",
                "label_fr": "Tunnel Hill"
            },
            {
                "value": 16181,
                "label": "Tunnelton",
                "label_ar": "تانيلتون",
                "label_fr": "Tunnelton"
            },
            {
                "value": 16182,
                "label": "Tuntutuliak",
                "label_ar": "تونتوتولياك",
                "label_fr": "Tuntutuliak"
            },
            {
                "value": 16183,
                "label": "Tuolumne",
                "label_ar": "تولومن",
                "label_fr": "Tuolumne"
            },
            {
                "value": 16184,
                "label": "Tupelo",
                "label_ar": "توبيلو",
                "label_fr": "Tupelo"
            },
            {
                "value": 16185,
                "label": "Tupper Lake",
                "label_ar": "بحيرة تابر",
                "label_fr": "Lac Tupper"
            },
            {
                "value": 16186,
                "label": "Turbeville",
                "label_ar": "توربفيل",
                "label_fr": "Turbeville"
            },
            {
                "value": 16187,
                "label": "Turbotville",
                "label_ar": "توربوتفيل",
                "label_fr": "Turbotville"
            },
            {
                "value": 16188,
                "label": "Turin",
                "label_ar": "تورينو",
                "label_fr": "Turin"
            },
            {
                "value": 16189,
                "label": "Turkey",
                "label_ar": "ديك رومي",
                "label_fr": "dinde"
            },
            {
                "value": 16190,
                "label": "Turkey Creek",
                "label_ar": "تركيا كريك",
                "label_fr": "Turkey Creek"
            },
            {
                "value": 16191,
                "label": "Turlock",
                "label_ar": "تورلوك",
                "label_fr": "Turlock"
            },
            {
                "value": 16192,
                "label": "Turner",
                "label_ar": "تيرنر",
                "label_fr": "Tourneur"
            },
            {
                "value": 16193,
                "label": "Turners Falls",
                "label_ar": "شلالات تورنرز",
                "label_fr": "Turners Falls"
            },
            {
                "value": 16194,
                "label": "Turners Station",
                "label_ar": "محطة ترنرز",
                "label_fr": "Station de tournage"
            },
            {
                "value": 16195,
                "label": "Turnerville",
                "label_ar": "تورنرفيل",
                "label_fr": "Turnerville"
            },
            {
                "value": 16196,
                "label": "Turpin",
                "label_ar": "توربين",
                "label_fr": "Turpin"
            },
            {
                "value": 16197,
                "label": "Turtle Creek",
                "label_ar": "ترتل كريك",
                "label_fr": "Turtle Creek"
            },
            {
                "value": 16198,
                "label": "Turtle Lake",
                "label_ar": "بحيرة السلاحف",
                "label_fr": "Turtle Lake"
            },
            {
                "value": 16199,
                "label": "Tuscaloosa",
                "label_ar": "توسكالوسا",
                "label_fr": "Tuscaloosa"
            },
            {
                "value": 16200,
                "label": "Tuscarawas",
                "label_ar": "توسكارواس",
                "label_fr": "Tuscarawas"
            },
            {
                "value": 16201,
                "label": "Tuscola",
                "label_ar": "توسكولا",
                "label_fr": "Tuscola"
            },
            {
                "value": 16202,
                "label": "Tuscumbia",
                "label_ar": "توسكومبيا",
                "label_fr": "Tuscumbia"
            },
            {
                "value": 16203,
                "label": "Tuskegee",
                "label_ar": "توسكيجي",
                "label_fr": "Tuskegee"
            },
            {
                "value": 16204,
                "label": "Tustin",
                "label_ar": "توستين",
                "label_fr": "Tustin"
            },
            {
                "value": 16205,
                "label": "Tutor Key",
                "label_ar": "مفتاح المعلم",
                "label_fr": "Clé du tuteur"
            },
            {
                "value": 16206,
                "label": "Tuttle",
                "label_ar": "تتل",
                "label_fr": "Tuttle"
            },
            {
                "value": 16207,
                "label": "Tutwiler",
                "label_ar": "توتويلر",
                "label_fr": "Tutwiler"
            },
            {
                "value": 16208,
                "label": "Tuxedo Park",
                "label_ar": "توكسيدو بارك",
                "label_fr": "Tuxedo Park"
            },
            {
                "value": 16209,
                "label": "Twain Harte",
                "label_ar": "توين هارت",
                "label_fr": "Twain Harte"
            },
            {
                "value": 16210,
                "label": "Twelve Mile",
                "label_ar": "اثنا عشر ميل",
                "label_fr": "Douze milles"
            },
            {
                "value": 16211,
                "label": "Twentynine Palms",
                "label_ar": "توينتي ناين بالمز",
                "label_fr": "Palmiers Twentynine"
            },
            {
                "value": 16212,
                "label": "Twin Bridges",
                "label_ar": "التوأم الجسور",
                "label_fr": "Ponts jumeaux"
            },
            {
                "value": 16213,
                "label": "Twin City",
                "label_ar": "المدينة التوأم",
                "label_fr": "Ville jumelle"
            },
            {
                "value": 16214,
                "label": "Twin Falls",
                "label_ar": "توين فولز",
                "label_fr": "Twin Falls"
            },
            {
                "value": 16215,
                "label": "Twin Lake",
                "label_ar": "بحيرة التوأم",
                "label_fr": "Lac Twin"
            },
            {
                "value": 16216,
                "label": "Twin Lakes",
                "label_ar": "بحيرات التوأم",
                "label_fr": "Lacs jumeaux"
            },
            {
                "value": 16217,
                "label": "Twin Peaks",
                "label_ar": "قمم التوأم",
                "label_fr": "Pics jumeaux"
            },
            {
                "value": 16218,
                "label": "Twin Valley",
                "label_ar": "توين فالي",
                "label_fr": "Twin Valley"
            },
            {
                "value": 16219,
                "label": "Twining",
                "label_ar": "توأمة",
                "label_fr": "Twining"
            },
            {
                "value": 16220,
                "label": "Twinsburg",
                "label_ar": "توينسبورغ",
                "label_fr": "Twinsburg"
            },
            {
                "value": 16221,
                "label": "Twisp",
                "label_ar": "تويسب",
                "label_fr": "Twisp"
            },
            {
                "value": 16222,
                "label": "Two Buttes",
                "label_ar": "اثنين من الأزرار",
                "label_fr": "Deux Buttes"
            },
            {
                "value": 16223,
                "label": "Two Harbors",
                "label_ar": "اثنان الموانئ",
                "label_fr": "Deux ports"
            },
            {
                "value": 16224,
                "label": "Two Rivers",
                "label_ar": "نهرين",
                "label_fr": "Deux rivières"
            },
            {
                "value": 16225,
                "label": "Tyaskin",
                "label_ar": "تايسكين",
                "label_fr": "Tyaskin"
            },
            {
                "value": 16226,
                "label": "Tybee Island",
                "label_ar": "جزيرة تيبي",
                "label_fr": "Île Tybee"
            },
            {
                "value": 16227,
                "label": "Tybo",
                "label_ar": "تيبو",
                "label_fr": "Tybo"
            },
            {
                "value": 16228,
                "label": "Tygh Valley",
                "label_ar": "وادي تايج",
                "label_fr": "Tygh Valley"
            },
            {
                "value": 16229,
                "label": "Tyler",
                "label_ar": "تايلر",
                "label_fr": "Tyler"
            },
            {
                "value": 16230,
                "label": "Tylertown",
                "label_ar": "تايلرتاون",
                "label_fr": "Tylertown"
            },
            {
                "value": 16231,
                "label": "Tyndall",
                "label_ar": "تيندال",
                "label_fr": "Tyndall"
            },
            {
                "value": 16232,
                "label": "Tyner",
                "label_ar": "تاينر",
                "label_fr": "Tyner"
            },
            {
                "value": 16233,
                "label": "Tyngsboro",
                "label_ar": "تينغسبورو",
                "label_fr": "Tyngsboro"
            },
            {
                "value": 16234,
                "label": "Tyringham",
                "label_ar": "تيرينجهام",
                "label_fr": "Tyringham"
            },
            {
                "value": 16235,
                "label": "Tyrone",
                "label_ar": "تيرون",
                "label_fr": "Tyrone"
            },
            {
                "value": 16236,
                "label": "Tyronza",
                "label_ar": "تايرونزا",
                "label_fr": "Tyronza"
            },
            {
                "value": 16237,
                "label": "Ubly",
                "label_ar": "اوبلي",
                "label_fr": "Ubly"
            },
            {
                "value": 16238,
                "label": "Udall",
                "label_ar": "أودال",
                "label_fr": "Udall"
            },
            {
                "value": 16239,
                "label": "Uhrichsville",
                "label_ar": "أوريتشسفيل",
                "label_fr": "Uhrichsville"
            },
            {
                "value": 16240,
                "label": "Ukiah",
                "label_ar": "يوكيا",
                "label_fr": "Ukiah"
            },
            {
                "value": 16241,
                "label": "Uledi",
                "label_ar": "أوليدي",
                "label_fr": "Uledi"
            },
            {
                "value": 16242,
                "label": "Ulen",
                "label_ar": "أولين",
                "label_fr": "Ulen"
            },
            {
                "value": 16243,
                "label": "Ullin",
                "label_ar": "أولين",
                "label_fr": "Ullin"
            },
            {
                "value": 16244,
                "label": "Ulm",
                "label_ar": "أولم",
                "label_fr": "Ulm"
            },
            {
                "value": 16245,
                "label": "Ulster",
                "label_ar": "أولستر",
                "label_fr": "Ulster"
            },
            {
                "value": 16246,
                "label": "Ulster Park",
                "label_ar": "حديقة الستر",
                "label_fr": "Parc d'Ulster"
            },
            {
                "value": 16247,
                "label": "Ulysses",
                "label_ar": "يوليسيس",
                "label_fr": "Ulysse"
            },
            {
                "value": 16248,
                "label": "Umatilla",
                "label_ar": "أوماتيلا",
                "label_fr": "Umatilla"
            },
            {
                "value": 16249,
                "label": "Umpire",
                "label_ar": "حكم",
                "label_fr": "Arbitre"
            },
            {
                "value": 16250,
                "label": "Unadilla",
                "label_ar": "Unadilla",
                "label_fr": "Unadilla"
            },
            {
                "value": 16251,
                "label": "Unalakleet",
                "label_ar": "أونالاكليت",
                "label_fr": "Unalakleet"
            },
            {
                "value": 16252,
                "label": "Unalaska",
                "label_ar": "أونالاسكا",
                "label_fr": "Unalaska"
            },
            {
                "value": 16253,
                "label": "Uncasville",
                "label_ar": "أونكاسفيل",
                "label_fr": "Uncasville"
            },
            {
                "value": 16254,
                "label": "Underhill",
                "label_ar": "أندرهيل",
                "label_fr": "Underhill"
            },
            {
                "value": 16255,
                "label": "Underwood",
                "label_ar": "أندروود",
                "label_fr": "Underwood"
            },
            {
                "value": 16256,
                "label": "Unicoi",
                "label_ar": "يونيكوي",
                "label_fr": "Unicoi"
            },
            {
                "value": 16257,
                "label": "Union",
                "label_ar": "اتحاد",
                "label_fr": "syndicat"
            },
            {
                "value": 16258,
                "label": "Union Bridge",
                "label_ar": "جسر الاتحاد",
                "label_fr": "Pont de l'Union"
            },
            {
                "value": 16259,
                "label": "Union City",
                "label_ar": "مدينة الاتحاد",
                "label_fr": "Union City"
            },
            {
                "value": 16260,
                "label": "Union Dale",
                "label_ar": "يونيون ديل",
                "label_fr": "Union Dale"
            },
            {
                "value": 16261,
                "label": "Union Grove",
                "label_ar": "يونيون جروف",
                "label_fr": "Union Grove"
            },
            {
                "value": 16262,
                "label": "Union Hall",
                "label_ar": "قاعة الاتحاد",
                "label_fr": "Salle de l'Union"
            },
            {
                "value": 16263,
                "label": "Union Hill",
                "label_ar": "يونيون هيل",
                "label_fr": "Union Hill"
            },
            {
                "value": 16264,
                "label": "Union Lake",
                "label_ar": "بحيرة الاتحاد",
                "label_fr": "Union Lake"
            },
            {
                "value": 16265,
                "label": "Union Mills",
                "label_ar": "يونيون ميلز",
                "label_fr": "Union Mills"
            },
            {
                "value": 16266,
                "label": "Union Point",
                "label_ar": "يونيون بوينت",
                "label_fr": "Point Union"
            },
            {
                "value": 16267,
                "label": "Union Springs",
                "label_ar": "يونيون سبرينغز",
                "label_fr": "Union Springs"
            },
            {
                "value": 16268,
                "label": "Union Star",
                "label_ar": "نجمة الاتحاد",
                "label_fr": "Union Star"
            },
            {
                "value": 16269,
                "label": "Union Township",
                "label_ar": "بلدة الاتحاد",
                "label_fr": "Canton de l'Union"
            },
            {
                "value": 16270,
                "label": "Uniondale",
                "label_ar": "يونيونديل",
                "label_fr": "Uniondale"
            },
            {
                "value": 16271,
                "label": "Uniontown",
                "label_ar": "يونيون تاون",
                "label_fr": "Uniontown"
            },
            {
                "value": 16272,
                "label": "Unionville",
                "label_ar": "يونيونفيل",
                "label_fr": "Unionville"
            },
            {
                "value": 16273,
                "label": "Unity",
                "label_ar": "وحدة",
                "label_fr": "Unité"
            },
            {
                "value": 16274,
                "label": "Universal City",
                "label_ar": "يونيفرسال سيتي",
                "label_fr": "Ville universelle"
            },
            {
                "value": 16275,
                "label": "University",
                "label_ar": "جامعة",
                "label_fr": "Université"
            },
            {
                "value": 16276,
                "label": "University Park",
                "label_ar": "جامعة بارك",
                "label_fr": "Parc universitaire"
            },
            {
                "value": 16277,
                "label": "University Place",
                "label_ar": "مكان الجامعة",
                "label_fr": "Place de l'Université"
            },
            {
                "value": 16278,
                "label": "Unorganized Territory of Camp Lejeune",
                "label_ar": "إقليم غير منظم في معسكر ليجون",
                "label_fr": "Territoire non organisé de Camp Lejeune"
            },
            {
                "value": 16279,
                "label": "Upatoi",
                "label_ar": "أوباتوي",
                "label_fr": "Upatoi"
            },
            {
                "value": 16280,
                "label": "Upland",
                "label_ar": "أبلاند",
                "label_fr": "Upland"
            },
            {
                "value": 16281,
                "label": "Upper Abbot",
                "label_ar": "الاباتي العلوي",
                "label_fr": "Abbé supérieur"
            },
            {
                "value": 16282,
                "label": "Upper Black Eddy",
                "label_ar": "أبر بلاك إيدي",
                "label_fr": "Eddy noir supérieur"
            },
            {
                "value": 16283,
                "label": "Upper Darby",
                "label_ar": "أبر داربي",
                "label_fr": "Upper Darby"
            },
            {
                "value": 16284,
                "label": "Upper Falls",
                "label_ar": "الشلالات العليا",
                "label_fr": "Upper Falls"
            },
            {
                "value": 16285,
                "label": "Upper Jay",
                "label_ar": "العليا جاي",
                "label_fr": "Geai supérieur"
            },
            {
                "value": 16286,
                "label": "Upper Marlboro",
                "label_ar": "مارلبورو العليا",
                "label_fr": "Upper Marlboro"
            },
            {
                "value": 16287,
                "label": "Upper Saddle River",
                "label_ar": "نهر السرج العلوي",
                "label_fr": "Rivière Upper Saddle"
            },
            {
                "value": 16288,
                "label": "Upper Sandusky",
                "label_ar": "العلوي Sandusky",
                "label_fr": "Upper Sandusky"
            },
            {
                "value": 16289,
                "label": "Upperco",
                "label_ar": "Upperco",
                "label_fr": "Upperco"
            },
            {
                "value": 16290,
                "label": "Upperville",
                "label_ar": "أوبرفيل",
                "label_fr": "Upperville"
            },
            {
                "value": 16291,
                "label": "Upsala",
                "label_ar": "أوبسالا",
                "label_fr": "Upsala"
            },
            {
                "value": 16292,
                "label": "Upton",
                "label_ar": "ابتون",
                "label_fr": "Upton"
            },
            {
                "value": 16293,
                "label": "Urbana",
                "label_ar": "أوربانا",
                "label_fr": "Urbana"
            },
            {
                "value": 16294,
                "label": "Urbandale",
                "label_ar": "Urbandale",
                "label_fr": "Urbandale"
            },
            {
                "value": 16295,
                "label": "Urbanna",
                "label_ar": "أورباننا",
                "label_fr": "Urbanna"
            },
            {
                "value": 16296,
                "label": "Uriah",
                "label_ar": "أوريا",
                "label_fr": "Urie"
            },
            {
                "value": 16297,
                "label": "Urich",
                "label_ar": "يوريش",
                "label_fr": "Urich"
            },
            {
                "value": 16298,
                "label": "Ursa",
                "label_ar": "Ursa",
                "label_fr": "Ursa"
            },
            {
                "value": 16299,
                "label": "Ute",
                "label_ar": "يوتا",
                "label_fr": "Ute"
            },
            {
                "value": 16300,
                "label": "Utica",
                "label_ar": "يوتيكا",
                "label_fr": "Utica"
            },
            {
                "value": 16301,
                "label": "Utopia",
                "label_ar": "المدينة الفاضلة",
                "label_fr": "utopie"
            },
            {
                "value": 16302,
                "label": "Uvalda",
                "label_ar": "أوفالدا",
                "label_fr": "Uvalda"
            },
            {
                "value": 16303,
                "label": "Uvalde",
                "label_ar": "أوفالد",
                "label_fr": "Uvalde"
            },
            {
                "value": 16304,
                "label": "Uxbridge",
                "label_ar": "أوكسبريدج",
                "label_fr": "Uxbridge"
            },
            {
                "value": 16305,
                "label": "Vacaville",
                "label_ar": "فاكافيل",
                "label_fr": "Vacaville"
            },
            {
                "value": 16306,
                "label": "Vacherie",
                "label_ar": "فاشيري",
                "label_fr": "Vacherie"
            },
            {
                "value": 16307,
                "label": "Vader",
                "label_ar": "فيدر",
                "label_fr": "Vador"
            },
            {
                "value": 16308,
                "label": "Vail",
                "label_ar": "فيل",
                "label_fr": "Vail"
            },
            {
                "value": 16309,
                "label": "Vails Gate",
                "label_ar": "بوابة فيلز",
                "label_fr": "Porte Vails"
            },
            {
                "value": 16310,
                "label": "Valatie",
                "label_ar": "فالاتي",
                "label_fr": "Valatie"
            },
            {
                "value": 16311,
                "label": "Valders",
                "label_ar": "فالدرز",
                "label_fr": "Valders"
            },
            {
                "value": 16312,
                "label": "Valdese",
                "label_ar": "فالديزي",
                "label_fr": "Valdese"
            },
            {
                "value": 16313,
                "label": "Valdez",
                "label_ar": "فالديز",
                "label_fr": "Valdez"
            },
            {
                "value": 16314,
                "label": "Valdosta",
                "label_ar": "فالدوستا",
                "label_fr": "Valdosta"
            },
            {
                "value": 16315,
                "label": "Vale",
                "label_ar": "فالي",
                "label_fr": "Vallée"
            },
            {
                "value": 16316,
                "label": "Valencia",
                "label_ar": "فالنسيا",
                "label_fr": "Valence"
            },
            {
                "value": 16317,
                "label": "Valentine",
                "label_ar": "عيد الحب",
                "label_fr": "Valentin"
            },
            {
                "value": 16318,
                "label": "Valentines",
                "label_ar": "عيد الحب",
                "label_fr": "Saint Valentin"
            },
            {
                "value": 16319,
                "label": "Valera",
                "label_ar": "فاليرا",
                "label_fr": "Valera"
            },
            {
                "value": 16320,
                "label": "Valhalla",
                "label_ar": "فالهالا",
                "label_fr": "Valhalla"
            },
            {
                "value": 16321,
                "label": "Valhermoso Springs",
                "label_ar": "فالهيرموسو سبرينغز",
                "label_fr": "Valhermoso Springs"
            },
            {
                "value": 16322,
                "label": "Valier",
                "label_ar": "فاليير",
                "label_fr": "Valier"
            },
            {
                "value": 16323,
                "label": "Valle",
                "label_ar": "فالي",
                "label_fr": "Valle"
            },
            {
                "value": 16324,
                "label": "Vallecito",
                "label_ar": "فاليسيتو",
                "label_fr": "Vallecito"
            },
            {
                "value": 16325,
                "label": "Vallejo",
                "label_ar": "فاليجو",
                "label_fr": "Vallejo"
            },
            {
                "value": 16326,
                "label": "Valles Mines",
                "label_ar": "مناجم فاليس",
                "label_fr": "Mines de Valles"
            },
            {
                "value": 16327,
                "label": "Valley",
                "label_ar": "الوادي",
                "label_fr": "Vallée"
            },
            {
                "value": 16328,
                "label": "Valley Bend",
                "label_ar": "وادي بيند",
                "label_fr": "Valley Bend"
            },
            {
                "value": 16329,
                "label": "Valley Center",
                "label_ar": "مركز الوادي",
                "label_fr": "Centre de la vallée"
            },
            {
                "value": 16330,
                "label": "Valley City",
                "label_ar": "فالي سيتي",
                "label_fr": "Valley City"
            },
            {
                "value": 16331,
                "label": "Valley Cottage",
                "label_ar": "كوخ الوادي",
                "label_fr": "Cottage Valley"
            },
            {
                "value": 16332,
                "label": "Valley Falls",
                "label_ar": "شلالات الوادي",
                "label_fr": "Valley Falls"
            },
            {
                "value": 16333,
                "label": "Valley Farms",
                "label_ar": "مزارع الوادي",
                "label_fr": "Fermes de la vallée"
            },
            {
                "value": 16334,
                "label": "Valley Forge",
                "label_ar": "فالي فورج",
                "label_fr": "Forge de la vallée"
            },
            {
                "value": 16335,
                "label": "Valley Green",
                "label_ar": "الوادي الأخضر",
                "label_fr": "Vert de la vallée"
            },
            {
                "value": 16336,
                "label": "Valley Head",
                "label_ar": "فالي هيد",
                "label_fr": "Valley Head"
            },
            {
                "value": 16337,
                "label": "Valley Mills",
                "label_ar": "مطاحن الوادي",
                "label_fr": "Valley Mills"
            },
            {
                "value": 16338,
                "label": "Valley Park",
                "label_ar": "فالي بارك",
                "label_fr": "Parc de la vallée"
            },
            {
                "value": 16339,
                "label": "Valley Springs",
                "label_ar": "وادي الينابيع",
                "label_fr": "Valley Springs"
            },
            {
                "value": 16340,
                "label": "Valley Stream",
                "label_ar": "فالي ستريم",
                "label_fr": "Ruisseau de la vallée"
            },
            {
                "value": 16341,
                "label": "Valley View",
                "label_ar": "منظر الوادي",
                "label_fr": "Vue sur la vallée"
            },
            {
                "value": 16342,
                "label": "Valley Village",
                "label_ar": "قرية الوادي",
                "label_fr": "Village de la vallée"
            },
            {
                "value": 16343,
                "label": "Valleyford",
                "label_ar": "فالفورد",
                "label_fr": "Valleyford"
            },
            {
                "value": 16344,
                "label": "Valliant",
                "label_ar": "فاليانت",
                "label_fr": "Valliant"
            },
            {
                "value": 16345,
                "label": "Vallonia",
                "label_ar": "فالونيا",
                "label_fr": "Vallonia"
            },
            {
                "value": 16346,
                "label": "Valmeyer",
                "label_ar": "فالمير",
                "label_fr": "Valmeyer"
            },
            {
                "value": 16347,
                "label": "Valparaiso",
                "label_ar": "فالبارايسو",
                "label_fr": "Valparaiso"
            },
            {
                "value": 16348,
                "label": "Valrico",
                "label_ar": "فالريكو",
                "label_fr": "Valrico"
            },
            {
                "value": 16349,
                "label": "Van",
                "label_ar": "سيارة نقل",
                "label_fr": "Van"
            },
            {
                "value": 16350,
                "label": "Van Alstyne",
                "label_ar": "فان الستين",
                "label_fr": "Van Alstyne"
            },
            {
                "value": 16351,
                "label": "Van Buren",
                "label_ar": "فان بورين",
                "label_fr": "Van Buren"
            },
            {
                "value": 16352,
                "label": "Van Dyne",
                "label_ar": "فان داين",
                "label_fr": "Van Dyne"
            },
            {
                "value": 16353,
                "label": "Van Etten",
                "label_ar": "فان إيتن",
                "label_fr": "Van Etten"
            },
            {
                "value": 16354,
                "label": "Van Horn",
                "label_ar": "فان هورن",
                "label_fr": "Van Horn"
            },
            {
                "value": 16355,
                "label": "Van Horne",
                "label_ar": "فان هورن",
                "label_fr": "Van Horne"
            },
            {
                "value": 16356,
                "label": "Van Lear",
                "label_ar": "فان لير",
                "label_fr": "Van Lear"
            },
            {
                "value": 16357,
                "label": "Van Meter",
                "label_ar": "فان متر",
                "label_fr": "Compteur Van"
            },
            {
                "value": 16358,
                "label": "Van Nuys",
                "label_ar": "فان نويس",
                "label_fr": "Van Nuys"
            },
            {
                "value": 16359,
                "label": "Van Vleck",
                "label_ar": "فان فليك",
                "label_fr": "Van Vleck"
            },
            {
                "value": 16360,
                "label": "Van Vleet",
                "label_ar": "فان فليت",
                "label_fr": "Van Vleet"
            },
            {
                "value": 16361,
                "label": "Van Wert",
                "label_ar": "فان فيرت",
                "label_fr": "Van Wert"
            },
            {
                "value": 16362,
                "label": "Van Wyck",
                "label_ar": "فان ويك",
                "label_fr": "Van Wyck"
            },
            {
                "value": 16363,
                "label": "Vance",
                "label_ar": "فانس",
                "label_fr": "Vance"
            },
            {
                "value": 16364,
                "label": "Vanceburg",
                "label_ar": "فانسبرج",
                "label_fr": "Vanceburg"
            },
            {
                "value": 16365,
                "label": "Vancleave",
                "label_ar": "فانكلايف",
                "label_fr": "Vancleave"
            },
            {
                "value": 16366,
                "label": "Vancouver",
                "label_ar": "فانكوفر",
                "label_fr": "Vancouver"
            },
            {
                "value": 16367,
                "label": "Vandalia",
                "label_ar": "فانداليا",
                "label_fr": "Vandalia"
            },
            {
                "value": 16368,
                "label": "Vanderbilt",
                "label_ar": "فاندربيلت",
                "label_fr": "Vanderbilt"
            },
            {
                "value": 16369,
                "label": "Vandergrift",
                "label_ar": "فاندرغريفت",
                "label_fr": "Vandergrift"
            },
            {
                "value": 16370,
                "label": "Vandiver",
                "label_ar": "فانديفر",
                "label_fr": "Vandiver"
            },
            {
                "value": 16371,
                "label": "Vanleer",
                "label_ar": "فانلير",
                "label_fr": "Vanleer"
            },
            {
                "value": 16372,
                "label": "Vanlue",
                "label_ar": "فانلو",
                "label_fr": "Vanlue"
            },
            {
                "value": 16373,
                "label": "Vansant",
                "label_ar": "فانسان",
                "label_fr": "Vansant"
            },
            {
                "value": 16374,
                "label": "Vanzant",
                "label_ar": "فانزانت",
                "label_fr": "Vanzant"
            },
            {
                "value": 16375,
                "label": "Vardaman",
                "label_ar": "فاردمان",
                "label_fr": "Vardaman"
            },
            {
                "value": 16376,
                "label": "Varna",
                "label_ar": "فارنا",
                "label_fr": "Varna"
            },
            {
                "value": 16377,
                "label": "Varnville",
                "label_ar": "فارنفيل",
                "label_fr": "Varnville"
            },
            {
                "value": 16378,
                "label": "Vashon",
                "label_ar": "فاشون",
                "label_fr": "Vashon"
            },
            {
                "value": 16379,
                "label": "Vass",
                "label_ar": "فاس",
                "label_fr": "Vass"
            },
            {
                "value": 16380,
                "label": "Vassalboro",
                "label_ar": "فاسالبورو",
                "label_fr": "Vassalboro"
            },
            {
                "value": 16381,
                "label": "Vassar",
                "label_ar": "فاسار",
                "label_fr": "Vassar"
            },
            {
                "value": 16382,
                "label": "Vaughan",
                "label_ar": "فوغان",
                "label_fr": "Vaughan"
            },
            {
                "value": 16383,
                "label": "Vaughn",
                "label_ar": "فون",
                "label_fr": "Vaughn"
            },
            {
                "value": 16384,
                "label": "Vauxhall",
                "label_ar": "فوكسهول",
                "label_fr": "Vauxhall"
            },
            {
                "value": 16385,
                "label": "Veblen",
                "label_ar": "فيبلين",
                "label_fr": "Veblen"
            },
            {
                "value": 16386,
                "label": "Veedersburg",
                "label_ar": "فيدرسبيرج",
                "label_fr": "Veedersburg"
            },
            {
                "value": 16387,
                "label": "Vega",
                "label_ar": "فيجا",
                "label_fr": "Vega"
            },
            {
                "value": 16388,
                "label": "Velarde",
                "label_ar": "فيلاردي",
                "label_fr": "Velarde"
            },
            {
                "value": 16389,
                "label": "Velva",
                "label_ar": "فيلفا",
                "label_fr": "Velva"
            },
            {
                "value": 16390,
                "label": "Vendor",
                "label_ar": "بائع",
                "label_fr": "Vendeur"
            },
            {
                "value": 16391,
                "label": "Venedocia",
                "label_ar": "فينيدوكيا",
                "label_fr": "Venedocia"
            },
            {
                "value": 16392,
                "label": "Veneta",
                "label_ar": "فينيتا",
                "label_fr": "Veneta"
            },
            {
                "value": 16393,
                "label": "Venetia",
                "label_ar": "البندقية",
                "label_fr": "Vénétie"
            },
            {
                "value": 16394,
                "label": "Venice",
                "label_ar": "مدينة البندقية",
                "label_fr": "Venise"
            },
            {
                "value": 16395,
                "label": "Ventnor City",
                "label_ar": "مدينة فينتنور",
                "label_fr": "Ville de Ventnor"
            },
            {
                "value": 16396,
                "label": "Ventress",
                "label_ar": "بطن",
                "label_fr": "Ventress"
            },
            {
                "value": 16397,
                "label": "Ventura",
                "label_ar": "فينتورا",
                "label_fr": "Ventura"
            },
            {
                "value": 16398,
                "label": "Venus",
                "label_ar": "كوكب الزهرة",
                "label_fr": "Vénus"
            },
            {
                "value": 16399,
                "label": "Veradale",
                "label_ar": "فيراديل",
                "label_fr": "Veradale"
            },
            {
                "value": 16400,
                "label": "Verbank",
                "label_ar": "فيربانك",
                "label_fr": "Verbank"
            },
            {
                "value": 16401,
                "label": "Verdi",
                "label_ar": "فيردي",
                "label_fr": "Verdi"
            },
            {
                "value": 16402,
                "label": "Verdigre",
                "label_ar": "فيرديجر",
                "label_fr": "Verdigre"
            },
            {
                "value": 16403,
                "label": "Verdon",
                "label_ar": "فيردون",
                "label_fr": "Verdon"
            },
            {
                "value": 16404,
                "label": "Verdugo City",
                "label_ar": "مدينة فيردوجو",
                "label_fr": "Ville de Verdugo"
            },
            {
                "value": 16405,
                "label": "Verdunville",
                "label_ar": "فردانفيل",
                "label_fr": "Verdunville"
            },
            {
                "value": 16406,
                "label": "Vergas",
                "label_ar": "فيرجاس",
                "label_fr": "Vergas"
            },
            {
                "value": 16407,
                "label": "Vergennes",
                "label_ar": "فيرجينس",
                "label_fr": "Vergennes"
            },
            {
                "value": 16408,
                "label": "Vermilion",
                "label_ar": "قرمزي",
                "label_fr": "Vermillon"
            },
            {
                "value": 16409,
                "label": "Vermillion",
                "label_ar": "قرمزي",
                "label_fr": "Vermillon"
            },
            {
                "value": 16410,
                "label": "Vermont",
                "label_ar": "فيرمونت",
                "label_fr": "Vermont"
            },
            {
                "value": 16411,
                "label": "Vermontville",
                "label_ar": "فيرمونتفيل",
                "label_fr": "Vermontville"
            },
            {
                "value": 16412,
                "label": "Vernal",
                "label_ar": "ربيعي",
                "label_fr": "Vernal"
            },
            {
                "value": 16413,
                "label": "Verndale",
                "label_ar": "فيرنديل",
                "label_fr": "Verndale"
            },
            {
                "value": 16414,
                "label": "Vernon",
                "label_ar": "فيرنون",
                "label_fr": "Vernon"
            },
            {
                "value": 16415,
                "label": "Vernon Center",
                "label_ar": "مركز فيرنون",
                "label_fr": "Centre Vernon"
            },
            {
                "value": 16416,
                "label": "Vernon Hill",
                "label_ar": "فيرنون هيل",
                "label_fr": "Vernon Hill"
            },
            {
                "value": 16417,
                "label": "Vernon Hills",
                "label_ar": "فيرنون هيلز",
                "label_fr": "Vernon Hills"
            },
            {
                "value": 16418,
                "label": "Vernonia",
                "label_ar": "فيرنونيا",
                "label_fr": "Vernonia"
            },
            {
                "value": 16419,
                "label": "Vero Beach",
                "label_ar": "فيرو بيتش",
                "label_fr": "Plage de Vero"
            },
            {
                "value": 16420,
                "label": "Verona",
                "label_ar": "فيرونا",
                "label_fr": "Vérone"
            },
            {
                "value": 16421,
                "label": "Verplanck",
                "label_ar": "فيربلانك",
                "label_fr": "Verplanck"
            },
            {
                "value": 16422,
                "label": "Versailles",
                "label_ar": "فرساي",
                "label_fr": "Versailles"
            },
            {
                "value": 16423,
                "label": "Vershire",
                "label_ar": "فيرشاير",
                "label_fr": "Vershire"
            },
            {
                "value": 16424,
                "label": "Vesper",
                "label_ar": "فيسبر",
                "label_fr": "Vesper"
            },
            {
                "value": 16425,
                "label": "Vesta",
                "label_ar": "فيستا",
                "label_fr": "Vesta"
            },
            {
                "value": 16426,
                "label": "Vestaburg",
                "label_ar": "فيستابورج",
                "label_fr": "Vestaburg"
            },
            {
                "value": 16427,
                "label": "Vestal",
                "label_ar": "فيستال",
                "label_fr": "Vestale"
            },
            {
                "value": 16428,
                "label": "Vesuvius",
                "label_ar": "فيزوف",
                "label_fr": "Vésuve"
            },
            {
                "value": 16429,
                "label": "Vevay",
                "label_ar": "فيفاي",
                "label_fr": "Vevay"
            },
            {
                "value": 16430,
                "label": "Veyo",
                "label_ar": "فييو",
                "label_fr": "Veyo"
            },
            {
                "value": 16431,
                "label": "Vian",
                "label_ar": "فيان",
                "label_fr": "Vian"
            },
            {
                "value": 16432,
                "label": "Viborg",
                "label_ar": "فيبورغ",
                "label_fr": "Viborg"
            },
            {
                "value": 16433,
                "label": "Viburnum",
                "label_ar": "الويبرنوم",
                "label_fr": "Viorne"
            },
            {
                "value": 16434,
                "label": "Vicco",
                "label_ar": "فيكو",
                "label_fr": "Vicco"
            },
            {
                "value": 16435,
                "label": "Vici",
                "label_ar": "فيشي",
                "label_fr": "Vici"
            },
            {
                "value": 16436,
                "label": "Vicksburg",
                "label_ar": "فيكسبيرغ",
                "label_fr": "Vicksburg"
            },
            {
                "value": 16437,
                "label": "Victor",
                "label_ar": "فيكتور",
                "label_fr": "Victor"
            },
            {
                "value": 16438,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 16439,
                "label": "Victorville",
                "label_ar": "فيكتورفيل",
                "label_fr": "Victorville"
            },
            {
                "value": 16440,
                "label": "Vida",
                "label_ar": "فيدا",
                "label_fr": "Vida"
            },
            {
                "value": 16441,
                "label": "Vidalia",
                "label_ar": "فيداليا",
                "label_fr": "Vidalia"
            },
            {
                "value": 16442,
                "label": "Vidor",
                "label_ar": "فيدور",
                "label_fr": "Vidor"
            },
            {
                "value": 16443,
                "label": "Vienna",
                "label_ar": "فيينا",
                "label_fr": "Vienne"
            },
            {
                "value": 16444,
                "label": "Vilas",
                "label_ar": "فيلاس",
                "label_fr": "Vilas"
            },
            {
                "value": 16445,
                "label": "Villa Grove",
                "label_ar": "فيلا جروف",
                "label_fr": "Villa Grove"
            },
            {
                "value": 16446,
                "label": "Villa Park",
                "label_ar": "فيلا بارك",
                "label_fr": "Villa Park"
            },
            {
                "value": 16447,
                "label": "Villa Rica",
                "label_ar": "فيلا ريكا",
                "label_fr": "Villa Rica"
            },
            {
                "value": 16448,
                "label": "Villa Ridge",
                "label_ar": "فيلا ريدج",
                "label_fr": "Villa Ridge"
            },
            {
                "value": 16449,
                "label": "Village",
                "label_ar": "قرية",
                "label_fr": "Village"
            },
            {
                "value": 16450,
                "label": "Village Mills",
                "label_ar": "مطاحن القرية",
                "label_fr": "Village Mills"
            },
            {
                "value": 16451,
                "label": "Villanova",
                "label_ar": "فيلانوفا",
                "label_fr": "Villanova"
            },
            {
                "value": 16452,
                "label": "Villanueva",
                "label_ar": "فيلانويفا",
                "label_fr": "Villanueva"
            },
            {
                "value": 16453,
                "label": "Villard",
                "label_ar": "فيلارد",
                "label_fr": "Villard"
            },
            {
                "value": 16454,
                "label": "Villas",
                "label_ar": "فلل",
                "label_fr": "Villas"
            },
            {
                "value": 16455,
                "label": "Ville Platte",
                "label_ar": "فيل بلات",
                "label_fr": "Ville Platte"
            },
            {
                "value": 16456,
                "label": "Villisca",
                "label_ar": "فيليسكا",
                "label_fr": "Villisca"
            },
            {
                "value": 16457,
                "label": "Vilonia",
                "label_ar": "فيلونيا",
                "label_fr": "Vilonia"
            },
            {
                "value": 16458,
                "label": "Vina",
                "label_ar": "فينا",
                "label_fr": "Vina"
            },
            {
                "value": 16459,
                "label": "Vinalhaven",
                "label_ar": "فينالهافن",
                "label_fr": "Vinalhaven"
            },
            {
                "value": 16460,
                "label": "Vincennes",
                "label_ar": "فينسينز",
                "label_fr": "Vincennes"
            },
            {
                "value": 16461,
                "label": "Vincent",
                "label_ar": "فنسنت",
                "label_fr": "Vincent"
            },
            {
                "value": 16462,
                "label": "Vincentown",
                "label_ar": "فنسنتاون",
                "label_fr": "Vincentown"
            },
            {
                "value": 16463,
                "label": "Vine Grove",
                "label_ar": "فاين جروف",
                "label_fr": "Vine Grove"
            },
            {
                "value": 16464,
                "label": "Vineland",
                "label_ar": "فينلاند",
                "label_fr": "Vineland"
            },
            {
                "value": 16465,
                "label": "Vinemont",
                "label_ar": "فينمونت",
                "label_fr": "Vinemont"
            },
            {
                "value": 16466,
                "label": "Vineyard Haven",
                "label_ar": "فينيارد هافن",
                "label_fr": "Vineyard Haven"
            },
            {
                "value": 16467,
                "label": "Vining",
                "label_ar": "كرم",
                "label_fr": "Vining"
            },
            {
                "value": 16468,
                "label": "Vinita",
                "label_ar": "فينيتا",
                "label_fr": "Vinita"
            },
            {
                "value": 16469,
                "label": "Vinton",
                "label_ar": "فينتون",
                "label_fr": "Vinton"
            },
            {
                "value": 16470,
                "label": "Vintondale",
                "label_ar": "فينتونديل",
                "label_fr": "Vintondale"
            },
            {
                "value": 16471,
                "label": "Viola",
                "label_ar": "فيولا",
                "label_fr": "Alto"
            },
            {
                "value": 16472,
                "label": "Violet",
                "label_ar": "البنفسجي",
                "label_fr": "Violet"
            },
            {
                "value": 16473,
                "label": "Violet Hill",
                "label_ar": "التل البنفسجي",
                "label_fr": "Violet Hill"
            },
            {
                "value": 16474,
                "label": "Viper",
                "label_ar": "أفعى",
                "label_fr": "Vipère"
            },
            {
                "value": 16475,
                "label": "Virden",
                "label_ar": "فيردن",
                "label_fr": "Virden"
            },
            {
                "value": 16476,
                "label": "Virgie",
                "label_ar": "برجي",
                "label_fr": "Virgie"
            },
            {
                "value": 16477,
                "label": "Virgilina",
                "label_ar": "فيرجيلينا",
                "label_fr": "Virgilina"
            },
            {
                "value": 16478,
                "label": "Virgin",
                "label_ar": "عذراء",
                "label_fr": "vierge"
            },
            {
                "value": 16479,
                "label": "Virginia",
                "label_ar": "فرجينيا",
                "label_fr": "Virginie"
            },
            {
                "value": 16480,
                "label": "Virginia Beach",
                "label_ar": "شاطئ فرجينيا",
                "label_fr": "Virginia Beach"
            },
            {
                "value": 16481,
                "label": "Viroqua",
                "label_ar": "فيروكوا",
                "label_fr": "Viroqua"
            },
            {
                "value": 16482,
                "label": "Visalia",
                "label_ar": "فيساليا",
                "label_fr": "Visalia"
            },
            {
                "value": 16483,
                "label": "Vista",
                "label_ar": "مشهد من خلال",
                "label_fr": "Vue"
            },
            {
                "value": 16484,
                "label": "Vivian",
                "label_ar": "فيفيان",
                "label_fr": "Vivian"
            },
            {
                "value": 16485,
                "label": "Volborg",
                "label_ar": "فولبورغ",
                "label_fr": "Volborg"
            },
            {
                "value": 16486,
                "label": "Volcano",
                "label_ar": "بركان",
                "label_fr": "Volcan"
            },
            {
                "value": 16487,
                "label": "Volga",
                "label_ar": "فولغا",
                "label_fr": "Volga"
            },
            {
                "value": 16488,
                "label": "Voluntown",
                "label_ar": "التطوع",
                "label_fr": "Voluntown"
            },
            {
                "value": 16489,
                "label": "Von Ormy",
                "label_ar": "فون أورمي",
                "label_fr": "Von Ormy"
            },
            {
                "value": 16490,
                "label": "Vona",
                "label_ar": "فونا",
                "label_fr": "Vona"
            },
            {
                "value": 16491,
                "label": "Vonore",
                "label_ar": "فونور",
                "label_fr": "Vonore"
            },
            {
                "value": 16492,
                "label": "Voorhees Township",
                "label_ar": "بلدة فورهيس",
                "label_fr": "Canton de Voorhees"
            },
            {
                "value": 16493,
                "label": "Voorheesville",
                "label_ar": "فورهيسفيل",
                "label_fr": "Voorheesville"
            },
            {
                "value": 16494,
                "label": "Vulcan",
                "label_ar": "فولكان",
                "label_fr": "Vulcain"
            },
            {
                "value": 16495,
                "label": "WaKeeney",
                "label_ar": "واكيني",
                "label_fr": "WaKeeney"
            },
            {
                "value": 16496,
                "label": "Waban",
                "label_ar": "وابان",
                "label_fr": "Waban"
            },
            {
                "value": 16497,
                "label": "Wabash",
                "label_ar": "واباش",
                "label_fr": "Wabash"
            },
            {
                "value": 16498,
                "label": "Wabasha",
                "label_ar": "واباشا",
                "label_fr": "Wabasha"
            },
            {
                "value": 16499,
                "label": "Wabasso",
                "label_ar": "واباسو",
                "label_fr": "Wabasso"
            },
            {
                "value": 16500,
                "label": "Waccabuc",
                "label_ar": "واكابوك",
                "label_fr": "Waccabuc"
            },
            {
                "value": 16501,
                "label": "Wachapreague",
                "label_ar": "Wachapreague",
                "label_fr": "Wachapreague"
            },
            {
                "value": 16502,
                "label": "Waco",
                "label_ar": "واكو",
                "label_fr": "Waco"
            },
            {
                "value": 16503,
                "label": "Waconia",
                "label_ar": "واكونيا",
                "label_fr": "Waconia"
            },
            {
                "value": 16504,
                "label": "Waddell",
                "label_ar": "واديل",
                "label_fr": "Waddell"
            },
            {
                "value": 16505,
                "label": "Waddy",
                "label_ar": "وادي",
                "label_fr": "Waddy"
            },
            {
                "value": 16506,
                "label": "Wade",
                "label_ar": "واد",
                "label_fr": "Patauger"
            },
            {
                "value": 16507,
                "label": "Wadena",
                "label_ar": "وادينا",
                "label_fr": "Wadena"
            },
            {
                "value": 16508,
                "label": "Wadesboro",
                "label_ar": "وادسبورو",
                "label_fr": "Wadesboro"
            },
            {
                "value": 16509,
                "label": "Wadesville",
                "label_ar": "وادسفيل",
                "label_fr": "Wadesville"
            },
            {
                "value": 16510,
                "label": "Wading River",
                "label_ar": "نهر الخوض",
                "label_fr": "Rivière Wading"
            },
            {
                "value": 16511,
                "label": "Wadley",
                "label_ar": "وادلي",
                "label_fr": "Wadley"
            },
            {
                "value": 16512,
                "label": "Wadmalaw Island",
                "label_ar": "جزيرة وادمالو",
                "label_fr": "Île de Wadmalaw"
            },
            {
                "value": 16513,
                "label": "Wadsworth",
                "label_ar": "وادزورث",
                "label_fr": "Wadsworth"
            },
            {
                "value": 16514,
                "label": "Waelder",
                "label_ar": "وائل",
                "label_fr": "Waelder"
            },
            {
                "value": 16515,
                "label": "Wagener",
                "label_ar": "فاغنر",
                "label_fr": "Wagener"
            },
            {
                "value": 16516,
                "label": "Wagner",
                "label_ar": "فاغنر",
                "label_fr": "Wagner"
            },
            {
                "value": 16517,
                "label": "Wagoner",
                "label_ar": "واجنر",
                "label_fr": "Charretier"
            },
            {
                "value": 16518,
                "label": "Wahiawa",
                "label_ar": "Wahiawa",
                "label_fr": "Wahiawa"
            },
            {
                "value": 16519,
                "label": "Wahkon",
                "label_ar": "وكون",
                "label_fr": "Wahkon"
            },
            {
                "value": 16520,
                "label": "Wahoo",
                "label_ar": "واهو",
                "label_fr": "Wahoo"
            },
            {
                "value": 16521,
                "label": "Wahpeton",
                "label_ar": "Wahpeton",
                "label_fr": "Wahpeton"
            },
            {
                "value": 16522,
                "label": "Wai'anae",
                "label_ar": "Wai'anae",
                "label_fr": "Wai'anae"
            },
            {
                "value": 16523,
                "label": "Waialua",
                "label_ar": "وايالوا",
                "label_fr": "Waialua"
            },
            {
                "value": 16524,
                "label": "Waikoloa",
                "label_ar": "ويكولوا",
                "label_fr": "Waikoloa"
            },
            {
                "value": 16525,
                "label": "Wailuku",
                "label_ar": "وايلوكو",
                "label_fr": "Wailuku"
            },
            {
                "value": 16526,
                "label": "Waimanalo",
                "label_ar": "وايمانالو",
                "label_fr": "Waimanalo"
            },
            {
                "value": 16527,
                "label": "Wainscott",
                "label_ar": "وينسكوت",
                "label_fr": "Wainscott"
            },
            {
                "value": 16528,
                "label": "Wainwright",
                "label_ar": "وينرايت",
                "label_fr": "Wainwright"
            },
            {
                "value": 16529,
                "label": "Waipahu",
                "label_ar": "ويباهو",
                "label_fr": "Waipahu"
            },
            {
                "value": 16530,
                "label": "Waite Park",
                "label_ar": "وايت بارك",
                "label_fr": "Parc Waite"
            },
            {
                "value": 16531,
                "label": "Waitsburg",
                "label_ar": "ويتسبورغ",
                "label_fr": "Waitsburg"
            },
            {
                "value": 16532,
                "label": "Waitsfield",
                "label_ar": "ويتسفيلد",
                "label_fr": "Waitsfield"
            },
            {
                "value": 16533,
                "label": "Wakarusa",
                "label_ar": "واكروسا",
                "label_fr": "Wakarusa"
            },
            {
                "value": 16534,
                "label": "Wake",
                "label_ar": "استيقظ",
                "label_fr": "Réveiller"
            },
            {
                "value": 16535,
                "label": "Wake Forest",
                "label_ar": "ويك فورست",
                "label_fr": "Wake Forest"
            },
            {
                "value": 16536,
                "label": "Wakefield",
                "label_ar": "ويكفيلد",
                "label_fr": "Wakefield"
            },
            {
                "value": 16537,
                "label": "Wakeman",
                "label_ar": "واكمان",
                "label_fr": "Wakeman"
            },
            {
                "value": 16538,
                "label": "Wakita",
                "label_ar": "واكيتا",
                "label_fr": "Wakita"
            },
            {
                "value": 16539,
                "label": "Wakonda",
                "label_ar": "واكوندا",
                "label_fr": "Wakonda"
            },
            {
                "value": 16540,
                "label": "Walbridge",
                "label_ar": "والبريدج",
                "label_fr": "Walbridge"
            },
            {
                "value": 16541,
                "label": "Walcott",
                "label_ar": "والكوت",
                "label_fr": "Walcott"
            },
            {
                "value": 16542,
                "label": "Walden",
                "label_ar": "والدن",
                "label_fr": "Walden"
            },
            {
                "value": 16543,
                "label": "Waldo",
                "label_ar": "والدو",
                "label_fr": "Waldo"
            },
            {
                "value": 16544,
                "label": "Waldoboro",
                "label_ar": "والدوبورو",
                "label_fr": "Waldoboro"
            },
            {
                "value": 16545,
                "label": "Waldorf",
                "label_ar": "والدورف",
                "label_fr": "Waldorf"
            },
            {
                "value": 16546,
                "label": "Waldport",
                "label_ar": "والدبورت",
                "label_fr": "Waldport"
            },
            {
                "value": 16547,
                "label": "Waldron",
                "label_ar": "والدرون",
                "label_fr": "Waldron"
            },
            {
                "value": 16548,
                "label": "Waldwick",
                "label_ar": "والدويك",
                "label_fr": "Waldwick"
            },
            {
                "value": 16549,
                "label": "Wales",
                "label_ar": "ويلز",
                "label_fr": "Pays de Galles"
            },
            {
                "value": 16550,
                "label": "Waleska",
                "label_ar": "ويلزكا",
                "label_fr": "Waleska"
            },
            {
                "value": 16551,
                "label": "Walford",
                "label_ar": "والفورد",
                "label_fr": "Walford"
            },
            {
                "value": 16552,
                "label": "Walhalla",
                "label_ar": "والله",
                "label_fr": "Walhalla"
            },
            {
                "value": 16553,
                "label": "Walhonding",
                "label_ar": "Walhonding",
                "label_fr": "Walhonding"
            },
            {
                "value": 16554,
                "label": "Walker",
                "label_ar": "ووكر",
                "label_fr": "Marcheur"
            },
            {
                "value": 16555,
                "label": "Walker Valley",
                "label_ar": "وادي ووكر",
                "label_fr": "Walker Valley"
            },
            {
                "value": 16556,
                "label": "Walkersville",
                "label_ar": "ووكرسفيل",
                "label_fr": "Walkersville"
            },
            {
                "value": 16557,
                "label": "Walkerton",
                "label_ar": "والكرتون",
                "label_fr": "Walkerton"
            },
            {
                "value": 16558,
                "label": "Walkertown",
                "label_ar": "والكرتاون",
                "label_fr": "Walkertown"
            },
            {
                "value": 16559,
                "label": "Walkerville",
                "label_ar": "والكرفيل",
                "label_fr": "Walkerville"
            },
            {
                "value": 16560,
                "label": "Wall",
                "label_ar": "حائط",
                "label_fr": "mur"
            },
            {
                "value": 16561,
                "label": "Wall Lake",
                "label_ar": "وول ليك",
                "label_fr": "Wall Lake"
            },
            {
                "value": 16562,
                "label": "Walla Walla",
                "label_ar": "والا والا",
                "label_fr": "Walla Walla"
            },
            {
                "value": 16563,
                "label": "Wallace",
                "label_ar": "والاس",
                "label_fr": "Wallace"
            },
            {
                "value": 16564,
                "label": "Wallaceton",
                "label_ar": "والاسيتون",
                "label_fr": "Wallaceton"
            },
            {
                "value": 16565,
                "label": "Walland",
                "label_ar": "وولاند",
                "label_fr": "Walland"
            },
            {
                "value": 16566,
                "label": "Wallback",
                "label_ar": "وولباك",
                "label_fr": "Wallback"
            },
            {
                "value": 16567,
                "label": "Wallburg",
                "label_ar": "والبرج",
                "label_fr": "Wallburg"
            },
            {
                "value": 16568,
                "label": "Walled Lake",
                "label_ar": "بحيرة مسورة",
                "label_fr": "Lac fortifié"
            },
            {
                "value": 16569,
                "label": "Waller",
                "label_ar": "والر",
                "label_fr": "Waller"
            },
            {
                "value": 16570,
                "label": "Wallingford",
                "label_ar": "والينجفورد",
                "label_fr": "Wallingford"
            },
            {
                "value": 16571,
                "label": "Wallington",
                "label_ar": "والينغتون",
                "label_fr": "Wallington"
            },
            {
                "value": 16572,
                "label": "Wallins Creek",
                "label_ar": "والينز كريك",
                "label_fr": "Ruisseau Wallins"
            },
            {
                "value": 16573,
                "label": "Wallis",
                "label_ar": "واليس",
                "label_fr": "Wallis"
            },
            {
                "value": 16574,
                "label": "Wallkill",
                "label_ar": "وولكيل",
                "label_fr": "Wallkill"
            },
            {
                "value": 16575,
                "label": "Wallowa",
                "label_ar": "الووا",
                "label_fr": "Wallowa"
            },
            {
                "value": 16576,
                "label": "Walls",
                "label_ar": "الجدران",
                "label_fr": "Des murs"
            },
            {
                "value": 16577,
                "label": "Wallsburg",
                "label_ar": "والسبورغ",
                "label_fr": "Wallsburg"
            },
            {
                "value": 16578,
                "label": "Walnut",
                "label_ar": "جوز",
                "label_fr": "Noyer"
            },
            {
                "value": 16579,
                "label": "Walnut Bottom",
                "label_ar": "قعر الجوز",
                "label_fr": "Fond en noyer"
            },
            {
                "value": 16580,
                "label": "Walnut Cove",
                "label_ar": "الجوز كوف",
                "label_fr": "Walnut Cove"
            },
            {
                "value": 16581,
                "label": "Walnut Creek",
                "label_ar": "والنوت كريك",
                "label_fr": "Walnut Creek"
            },
            {
                "value": 16582,
                "label": "Walnut Grove",
                "label_ar": "الجوز بستان",
                "label_fr": "Walnut Grove"
            },
            {
                "value": 16583,
                "label": "Walnut Ridge",
                "label_ar": "الجوز ريدج",
                "label_fr": "Walnut Ridge"
            },
            {
                "value": 16584,
                "label": "Walnut Shade",
                "label_ar": "الظل الجوز",
                "label_fr": "Abat-jour noyer"
            },
            {
                "value": 16585,
                "label": "Walnut Springs",
                "label_ar": "الجوز سبرينغز",
                "label_fr": "Walnut Springs"
            },
            {
                "value": 16586,
                "label": "Walnutport",
                "label_ar": "وولنتبورت",
                "label_fr": "Walnutport"
            },
            {
                "value": 16587,
                "label": "Walpole",
                "label_ar": "والبول",
                "label_fr": "Walpole"
            },
            {
                "value": 16588,
                "label": "Walsenburg",
                "label_ar": "Walsenburg",
                "label_fr": "Walsenburg"
            },
            {
                "value": 16589,
                "label": "Walsh",
                "label_ar": "والش",
                "label_fr": "Walsh"
            },
            {
                "value": 16590,
                "label": "Walshville",
                "label_ar": "والشفيل",
                "label_fr": "Walshville"
            },
            {
                "value": 16591,
                "label": "Walstonburg",
                "label_ar": "والستونبرج",
                "label_fr": "Walstonburg"
            },
            {
                "value": 16592,
                "label": "Walterboro",
                "label_ar": "والتربورو",
                "label_fr": "Walterboro"
            },
            {
                "value": 16593,
                "label": "Walters",
                "label_ar": "والترز",
                "label_fr": "Walters"
            },
            {
                "value": 16594,
                "label": "Waltham",
                "label_ar": "والثام",
                "label_fr": "Waltham"
            },
            {
                "value": 16595,
                "label": "Walthill",
                "label_ar": "والثيل",
                "label_fr": "Walthill"
            },
            {
                "value": 16596,
                "label": "Walton",
                "label_ar": "والتون",
                "label_fr": "Walton"
            },
            {
                "value": 16597,
                "label": "Waltonville",
                "label_ar": "والتونفيل",
                "label_fr": "Waltonville"
            },
            {
                "value": 16598,
                "label": "Walworth",
                "label_ar": "والورث",
                "label_fr": "Walworth"
            },
            {
                "value": 16599,
                "label": "Wamego",
                "label_ar": "واميغو",
                "label_fr": "Wamego"
            },
            {
                "value": 16600,
                "label": "Wampsville",
                "label_ar": "وامبسفيل",
                "label_fr": "Wampsville"
            },
            {
                "value": 16601,
                "label": "Wampum",
                "label_ar": "وامبوم",
                "label_fr": "Wampum"
            },
            {
                "value": 16602,
                "label": "Wamsutter",
                "label_ar": "وامساتر",
                "label_fr": "Wamsutter"
            },
            {
                "value": 16603,
                "label": "Wana",
                "label_ar": "و أنا",
                "label_fr": "Wana"
            },
            {
                "value": 16604,
                "label": "Wanakena",
                "label_ar": "واناكينا",
                "label_fr": "Wanakena"
            },
            {
                "value": 16605,
                "label": "Wanamingo",
                "label_ar": "وانامينجو",
                "label_fr": "Wanamingo"
            },
            {
                "value": 16606,
                "label": "Wanaque",
                "label_ar": "واناك",
                "label_fr": "Wanaque"
            },
            {
                "value": 16607,
                "label": "Wanatah",
                "label_ar": "وانة",
                "label_fr": "Wanatah"
            },
            {
                "value": 16608,
                "label": "Wanchese",
                "label_ar": "وانشيز",
                "label_fr": "Wanchese"
            },
            {
                "value": 16609,
                "label": "Waneta",
                "label_ar": "وانيتا",
                "label_fr": "Waneta"
            },
            {
                "value": 16610,
                "label": "Wanette",
                "label_ar": "وانيت",
                "label_fr": "Wanette"
            },
            {
                "value": 16611,
                "label": "Wannaska",
                "label_ar": "واناسكا",
                "label_fr": "Wannaska"
            },
            {
                "value": 16612,
                "label": "Wantagh",
                "label_ar": "وانتاج",
                "label_fr": "Wantagh"
            },
            {
                "value": 16613,
                "label": "Wapakoneta",
                "label_ar": "واباكونيتا",
                "label_fr": "Wapakoneta"
            },
            {
                "value": 16614,
                "label": "Wapato",
                "label_ar": "واباتو",
                "label_fr": "Wapato"
            },
            {
                "value": 16615,
                "label": "Wapella",
                "label_ar": "وابيلا",
                "label_fr": "Wapella"
            },
            {
                "value": 16616,
                "label": "Wapello",
                "label_ar": "وابيلو",
                "label_fr": "Wapello"
            },
            {
                "value": 16617,
                "label": "Wappapello",
                "label_ar": "وابابيلو",
                "label_fr": "Wappapello"
            },
            {
                "value": 16618,
                "label": "Wappingers Falls",
                "label_ar": "شلالات وابينجرز",
                "label_fr": "Wappingers Falls"
            },
            {
                "value": 16619,
                "label": "Wapwallopen",
                "label_ar": "وابوالوبين",
                "label_fr": "Wapwallopen"
            },
            {
                "value": 16620,
                "label": "War",
                "label_ar": "حرب",
                "label_fr": "Guerre"
            },
            {
                "value": 16621,
                "label": "Warba",
                "label_ar": "وربة",
                "label_fr": "Warba"
            },
            {
                "value": 16622,
                "label": "Ward",
                "label_ar": "وارد",
                "label_fr": "quartier"
            },
            {
                "value": 16623,
                "label": "Warden",
                "label_ar": "حارس",
                "label_fr": "Directeur"
            },
            {
                "value": 16624,
                "label": "Wardensville",
                "label_ar": "واردنسفيل",
                "label_fr": "Wardensville"
            },
            {
                "value": 16625,
                "label": "Ware",
                "label_ar": "وير",
                "label_fr": "Ware"
            },
            {
                "value": 16626,
                "label": "Ware Neck",
                "label_ar": "عنق وير",
                "label_fr": "Ware Neck"
            },
            {
                "value": 16627,
                "label": "Ware Shoals",
                "label_ar": "وير شولز",
                "label_fr": "Bancs d'articles"
            },
            {
                "value": 16628,
                "label": "Wareham",
                "label_ar": "ويرهام",
                "label_fr": "Wareham"
            },
            {
                "value": 16629,
                "label": "Waretown",
                "label_ar": "واريتاون",
                "label_fr": "Waretown"
            },
            {
                "value": 16630,
                "label": "Warfordsburg",
                "label_ar": "وارفوردسبورج",
                "label_fr": "Warfordsburg"
            },
            {
                "value": 16631,
                "label": "Warm Springs",
                "label_ar": "الينابيع الدافئة",
                "label_fr": "Warm Springs"
            },
            {
                "value": 16632,
                "label": "Warminster",
                "label_ar": "ارمينستر",
                "label_fr": "Warminster"
            },
            {
                "value": 16633,
                "label": "Warne",
                "label_ar": "وارن",
                "label_fr": "Warne"
            },
            {
                "value": 16634,
                "label": "Warner",
                "label_ar": "وارنر",
                "label_fr": "Warner"
            },
            {
                "value": 16635,
                "label": "Warner Robins",
                "label_ar": "وارنر روبينز",
                "label_fr": "Warner Robins"
            },
            {
                "value": 16636,
                "label": "Warner Springs",
                "label_ar": "وارنر سبرينغز",
                "label_fr": "Warner Springs"
            },
            {
                "value": 16637,
                "label": "Warners",
                "label_ar": "نذير",
                "label_fr": "Warners"
            },
            {
                "value": 16638,
                "label": "Warren",
                "label_ar": "وارن",
                "label_fr": "Garenne"
            },
            {
                "value": 16639,
                "label": "Warren Center",
                "label_ar": "مركز وارن",
                "label_fr": "Centre Warren"
            },
            {
                "value": 16640,
                "label": "Warren Township",
                "label_ar": "بلدة وارن",
                "label_fr": "Canton de Warren"
            },
            {
                "value": 16641,
                "label": "Warrendale",
                "label_ar": "وارينديل",
                "label_fr": "Warrendale"
            },
            {
                "value": 16642,
                "label": "Warrens",
                "label_ar": "وارينز",
                "label_fr": "Warrens"
            },
            {
                "value": 16643,
                "label": "Warrensburg",
                "label_ar": "وارنسبرج",
                "label_fr": "Warrensburg"
            },
            {
                "value": 16644,
                "label": "Warrensville",
                "label_ar": "وارنسفيل",
                "label_fr": "Warrensville"
            },
            {
                "value": 16645,
                "label": "Warrenton",
                "label_ar": "وارينتون",
                "label_fr": "Warrenton"
            },
            {
                "value": 16646,
                "label": "Warrenville",
                "label_ar": "وارينفيل",
                "label_fr": "Warrenville"
            },
            {
                "value": 16647,
                "label": "Warrington",
                "label_ar": "وارينغتون",
                "label_fr": "Warrington"
            },
            {
                "value": 16648,
                "label": "Warrior",
                "label_ar": "محارب",
                "label_fr": "guerrier"
            },
            {
                "value": 16649,
                "label": "Warriors Mark",
                "label_ar": "ووريورز مارك",
                "label_fr": "Marque des guerriers"
            },
            {
                "value": 16650,
                "label": "Warroad",
                "label_ar": "وارود",
                "label_fr": "Warroad"
            },
            {
                "value": 16651,
                "label": "Warsaw",
                "label_ar": "وارسو",
                "label_fr": "Varsovie"
            },
            {
                "value": 16652,
                "label": "Wartburg",
                "label_ar": "وارتبرج",
                "label_fr": "Wartburg"
            },
            {
                "value": 16653,
                "label": "Wartrace",
                "label_ar": "Wartrace",
                "label_fr": "Wartrace"
            },
            {
                "value": 16654,
                "label": "Warwick",
                "label_ar": "وارويك",
                "label_fr": "Warwick"
            },
            {
                "value": 16655,
                "label": "Wasco",
                "label_ar": "واسكو",
                "label_fr": "Wasco"
            },
            {
                "value": 16656,
                "label": "Waseca",
                "label_ar": "واسيكا",
                "label_fr": "Waseca"
            },
            {
                "value": 16657,
                "label": "Washburn",
                "label_ar": "واشبورن",
                "label_fr": "Washburn"
            },
            {
                "value": 16658,
                "label": "Washington",
                "label_ar": "واشنطن",
                "label_fr": "Washington"
            },
            {
                "value": 16659,
                "label": "Washington Boro",
                "label_ar": "واشنطن بورو",
                "label_fr": "Washington Boro"
            },
            {
                "value": 16660,
                "label": "Washington Court House",
                "label_ar": "بيت محكمة واشنطن",
                "label_fr": "Palais de justice de Washington"
            },
            {
                "value": 16661,
                "label": "Washington Crossing",
                "label_ar": "معبر واشنطن",
                "label_fr": "Washington Crossing"
            },
            {
                "value": 16662,
                "label": "Washington Grove",
                "label_ar": "واشنطن جروف",
                "label_fr": "Washington Grove"
            },
            {
                "value": 16663,
                "label": "Washington Township",
                "label_ar": "بلدة واشنطن",
                "label_fr": "Canton de Washington"
            },
            {
                "value": 16664,
                "label": "Washingtonville",
                "label_ar": "واشنطنفيل",
                "label_fr": "Washingtonville"
            },
            {
                "value": 16665,
                "label": "Washougal",
                "label_ar": "واشوجال",
                "label_fr": "Washougal"
            },
            {
                "value": 16666,
                "label": "Wasilla",
                "label_ar": "واسيلا",
                "label_fr": "Wasilla"
            },
            {
                "value": 16667,
                "label": "Waskom",
                "label_ar": "واسكوم",
                "label_fr": "Waskom"
            },
            {
                "value": 16668,
                "label": "Wassaic",
                "label_ar": "واسايك",
                "label_fr": "Wassaic"
            },
            {
                "value": 16669,
                "label": "Watauga",
                "label_ar": "واتوجا",
                "label_fr": "Watauga"
            },
            {
                "value": 16670,
                "label": "Watchung",
                "label_ar": "واتشونج",
                "label_fr": "Watchung"
            },
            {
                "value": 16671,
                "label": "Water Mill",
                "label_ar": "مطحنة المياه",
                "label_fr": "Moulin à eau"
            },
            {
                "value": 16672,
                "label": "Water Valley",
                "label_ar": "وادي المياه",
                "label_fr": "Vallée de l'eau"
            },
            {
                "value": 16673,
                "label": "Waterboro",
                "label_ar": "واتربورو",
                "label_fr": "Waterboro"
            },
            {
                "value": 16674,
                "label": "Waterbury",
                "label_ar": "واتربري",
                "label_fr": "Waterbury"
            },
            {
                "value": 16675,
                "label": "Waterbury Center",
                "label_ar": "مركز واتربري",
                "label_fr": "Centre de Waterbury"
            },
            {
                "value": 16676,
                "label": "Waterford",
                "label_ar": "وترفورد",
                "label_fr": "Waterford"
            },
            {
                "value": 16677,
                "label": "Waterford Flat",
                "label_ar": "شقة واترفورد",
                "label_fr": "Appartement Waterford"
            },
            {
                "value": 16678,
                "label": "Waterford Works",
                "label_ar": "أعمال ووترفورد",
                "label_fr": "Travaux de Waterford"
            },
            {
                "value": 16679,
                "label": "Waterloo",
                "label_ar": "واترلو",
                "label_fr": "Waterloo"
            },
            {
                "value": 16680,
                "label": "Waterman",
                "label_ar": "الماء",
                "label_fr": "Batelier"
            },
            {
                "value": 16681,
                "label": "Waterport",
                "label_ar": "ميناء مائي",
                "label_fr": "Waterport"
            },
            {
                "value": 16682,
                "label": "Waterproof",
                "label_ar": "ضد للماء",
                "label_fr": "Étanche"
            },
            {
                "value": 16683,
                "label": "Waters",
                "label_ar": "مياه",
                "label_fr": "Eaux"
            },
            {
                "value": 16684,
                "label": "Watersmeet",
                "label_ar": "Watersmeet",
                "label_fr": "Watersmeet"
            },
            {
                "value": 16685,
                "label": "Watertown",
                "label_ar": "ووترتاون",
                "label_fr": "Watertown"
            },
            {
                "value": 16686,
                "label": "Waterville",
                "label_ar": "ووترفيل",
                "label_fr": "Waterville"
            },
            {
                "value": 16687,
                "label": "Waterville Valley",
                "label_ar": "وادي ووترفيل",
                "label_fr": "Vallée de Waterville"
            },
            {
                "value": 16688,
                "label": "Watervliet",
                "label_ar": "ووترفليت",
                "label_fr": "Watervliet"
            },
            {
                "value": 16689,
                "label": "Watford City",
                "label_ar": "واتفورد سيتي",
                "label_fr": "Ville de Watford"
            },
            {
                "value": 16690,
                "label": "Wathena",
                "label_ar": "واثينا",
                "label_fr": "Wathena"
            },
            {
                "value": 16691,
                "label": "Watkins",
                "label_ar": "واتكينز",
                "label_fr": "Watkins"
            },
            {
                "value": 16692,
                "label": "Watkins Glen",
                "label_ar": "واتكينز جلين",
                "label_fr": "Watkins Glen"
            },
            {
                "value": 16693,
                "label": "Watkinsville",
                "label_ar": "واتكينزفيل",
                "label_fr": "Watkinsville"
            },
            {
                "value": 16694,
                "label": "Watonga",
                "label_ar": "واتونجا",
                "label_fr": "Watonga"
            },
            {
                "value": 16695,
                "label": "Watseka",
                "label_ar": "واتسيكا",
                "label_fr": "Watseka"
            },
            {
                "value": 16696,
                "label": "Watson",
                "label_ar": "واتسون",
                "label_fr": "Watson"
            },
            {
                "value": 16697,
                "label": "Watsontown",
                "label_ar": "واتسون تاون",
                "label_fr": "Watsontown"
            },
            {
                "value": 16698,
                "label": "Watsonville",
                "label_ar": "واتسونفيل",
                "label_fr": "Watsonville"
            },
            {
                "value": 16699,
                "label": "Watts",
                "label_ar": "واتس",
                "label_fr": "Watts"
            },
            {
                "value": 16700,
                "label": "Wattsburg",
                "label_ar": "واتسبرج",
                "label_fr": "Wattsburg"
            },
            {
                "value": 16701,
                "label": "Wattsville",
                "label_ar": "واتسفيل",
                "label_fr": "Wattsville"
            },
            {
                "value": 16702,
                "label": "Waubun",
                "label_ar": "واوبون",
                "label_fr": "Waubun"
            },
            {
                "value": 16703,
                "label": "Wauchula",
                "label_ar": "Wauchula",
                "label_fr": "Wauchula"
            },
            {
                "value": 16704,
                "label": "Waucoma",
                "label_ar": "واكوما",
                "label_fr": "Waucome"
            },
            {
                "value": 16705,
                "label": "Wauconda",
                "label_ar": "واكوندا",
                "label_fr": "Wauconda"
            },
            {
                "value": 16706,
                "label": "Waukee",
                "label_ar": "ووكي",
                "label_fr": "Waukee"
            },
            {
                "value": 16707,
                "label": "Waukegan",
                "label_ar": "ووكيجان",
                "label_fr": "Waukegan"
            },
            {
                "value": 16708,
                "label": "Waukesha",
                "label_ar": "واوكيشا",
                "label_fr": "Waukesha"
            },
            {
                "value": 16709,
                "label": "Waukomis",
                "label_ar": "ووكوميس",
                "label_fr": "Waukomis"
            },
            {
                "value": 16710,
                "label": "Waukon",
                "label_ar": "وكون",
                "label_fr": "Waukon"
            },
            {
                "value": 16711,
                "label": "Waunakee",
                "label_ar": "Waunakee",
                "label_fr": "Waunakee"
            },
            {
                "value": 16712,
                "label": "Wauneta",
                "label_ar": "واونيتا",
                "label_fr": "Wauneta"
            },
            {
                "value": 16713,
                "label": "Waupaca",
                "label_ar": "واوباكا",
                "label_fr": "Waupaca"
            },
            {
                "value": 16714,
                "label": "Waupun",
                "label_ar": "واوبون",
                "label_fr": "Waupun"
            },
            {
                "value": 16715,
                "label": "Wauregan",
                "label_ar": "واوريجان",
                "label_fr": "Wauregan"
            },
            {
                "value": 16716,
                "label": "Waurika",
                "label_ar": "ووريكا",
                "label_fr": "Waurika"
            },
            {
                "value": 16717,
                "label": "Wausa",
                "label_ar": "واوسا",
                "label_fr": "Wausa"
            },
            {
                "value": 16718,
                "label": "Wausau",
                "label_ar": "واوساو",
                "label_fr": "Wausau"
            },
            {
                "value": 16719,
                "label": "Wauseon",
                "label_ar": "ويسون",
                "label_fr": "Wauseon"
            },
            {
                "value": 16720,
                "label": "Wautoma",
                "label_ar": "Wautoma",
                "label_fr": "Wautoma"
            },
            {
                "value": 16721,
                "label": "Waveland",
                "label_ar": "ويفلاند",
                "label_fr": "Waveland"
            },
            {
                "value": 16722,
                "label": "Waverly",
                "label_ar": "متموج",
                "label_fr": "Waverly"
            },
            {
                "value": 16723,
                "label": "Waverly Hall",
                "label_ar": "قاعة ويفرلي",
                "label_fr": "Waverly Hall"
            },
            {
                "value": 16724,
                "label": "Wawaka",
                "label_ar": "واكا",
                "label_fr": "Wawaka"
            },
            {
                "value": 16725,
                "label": "Waxahachie",
                "label_ar": "واكساهاتشي",
                "label_fr": "Waxahachie"
            },
            {
                "value": 16726,
                "label": "Waxhaw",
                "label_ar": "واكسشو",
                "label_fr": "Waxhaw"
            },
            {
                "value": 16727,
                "label": "Waycross",
                "label_ar": "وايكروس",
                "label_fr": "Waycross"
            },
            {
                "value": 16728,
                "label": "Wayland",
                "label_ar": "وايلاند",
                "label_fr": "Wayland"
            },
            {
                "value": 16729,
                "label": "Waymart",
                "label_ar": "وايمارت",
                "label_fr": "Waymart"
            },
            {
                "value": 16730,
                "label": "Wayne",
                "label_ar": "واين",
                "label_fr": "Wayne"
            },
            {
                "value": 16731,
                "label": "Wayne City",
                "label_ar": "واين سيتي",
                "label_fr": "Wayne City"
            },
            {
                "value": 16732,
                "label": "Waynesboro",
                "label_ar": "وينسبورو",
                "label_fr": "Waynesboro"
            },
            {
                "value": 16733,
                "label": "Waynesburg",
                "label_ar": "وينسبورغ",
                "label_fr": "Waynesburg"
            },
            {
                "value": 16734,
                "label": "Waynesfield",
                "label_ar": "وينسفيلد",
                "label_fr": "Waynesfield"
            },
            {
                "value": 16735,
                "label": "Waynesville",
                "label_ar": "وينسفيل",
                "label_fr": "Waynesville"
            },
            {
                "value": 16736,
                "label": "Waynetown",
                "label_ar": "وين تاون",
                "label_fr": "Waynetown"
            },
            {
                "value": 16737,
                "label": "Waynoka",
                "label_ar": "وينوكا",
                "label_fr": "Waynoka"
            },
            {
                "value": 16738,
                "label": "Wayzata",
                "label_ar": "وايزاتا",
                "label_fr": "Wayzata"
            },
            {
                "value": 16739,
                "label": "Weare",
                "label_ar": "نحن",
                "label_fr": "Nous sommes"
            },
            {
                "value": 16740,
                "label": "Weatherford",
                "label_ar": "ويذرفورد",
                "label_fr": "Weatherford"
            },
            {
                "value": 16741,
                "label": "Weatherly",
                "label_ar": "طقس",
                "label_fr": "Weatherly"
            },
            {
                "value": 16742,
                "label": "Weatogue",
                "label_ar": "ويتوج",
                "label_fr": "Weatogue"
            },
            {
                "value": 16743,
                "label": "Weaubleau",
                "label_ar": "ويوبليو",
                "label_fr": "Weaubleau"
            },
            {
                "value": 16744,
                "label": "Weaver",
                "label_ar": "ويفر",
                "label_fr": "Tisserand"
            },
            {
                "value": 16745,
                "label": "Weaverville",
                "label_ar": "ويفرفيل",
                "label_fr": "Weaverville"
            },
            {
                "value": 16746,
                "label": "Webb",
                "label_ar": "ويب",
                "label_fr": "Webb"
            },
            {
                "value": 16747,
                "label": "Webb City",
                "label_ar": "مدينة ويب",
                "label_fr": "Ville Webb"
            },
            {
                "value": 16748,
                "label": "Webbers Falls",
                "label_ar": "شلالات ويبرز",
                "label_fr": "Webbers Falls"
            },
            {
                "value": 16749,
                "label": "Webberville",
                "label_ar": "ويبرفيل",
                "label_fr": "Webberville"
            },
            {
                "value": 16750,
                "label": "Weber City",
                "label_ar": "ويبر سيتي",
                "label_fr": "Weber City"
            },
            {
                "value": 16751,
                "label": "Webster",
                "label_ar": "ويبستر",
                "label_fr": "Webster"
            },
            {
                "value": 16752,
                "label": "Webster City",
                "label_ar": "ويبستر سيتي",
                "label_fr": "Webster City"
            },
            {
                "value": 16753,
                "label": "Webster Springs",
                "label_ar": "ويبستر سبرينغز",
                "label_fr": "Webster Springs"
            },
            {
                "value": 16754,
                "label": "Wedowee",
                "label_ar": "ويدوي",
                "label_fr": "Wedowee"
            },
            {
                "value": 16755,
                "label": "Weed",
                "label_ar": "عشبة ضارة",
                "label_fr": "Cannabis"
            },
            {
                "value": 16756,
                "label": "Weedsport",
                "label_ar": "Weedsport",
                "label_fr": "Weedsport"
            },
            {
                "value": 16757,
                "label": "Weedville",
                "label_ar": "ويدفيل",
                "label_fr": "Weedville"
            },
            {
                "value": 16758,
                "label": "Weehawken",
                "label_ar": "ويهاوكين",
                "label_fr": "Weehawken"
            },
            {
                "value": 16759,
                "label": "Weeksbury",
                "label_ar": "ويكسبري",
                "label_fr": "Weeksbury"
            },
            {
                "value": 16760,
                "label": "Weems",
                "label_ar": "ويمس",
                "label_fr": "Weems"
            },
            {
                "value": 16761,
                "label": "Weeping Water",
                "label_ar": "ماء البكاء",
                "label_fr": "Eau qui pleure"
            },
            {
                "value": 16762,
                "label": "Weidman",
                "label_ar": "ويدمان",
                "label_fr": "Weidman"
            },
            {
                "value": 16763,
                "label": "Weimar",
                "label_ar": "فايمار",
                "label_fr": "Weimar"
            },
            {
                "value": 16764,
                "label": "Weiner",
                "label_ar": "وينر",
                "label_fr": "Weiner"
            },
            {
                "value": 16765,
                "label": "Weippe",
                "label_ar": "ويبي",
                "label_fr": "Weippe"
            },
            {
                "value": 16766,
                "label": "Weir",
                "label_ar": "وير",
                "label_fr": "seuil"
            },
            {
                "value": 16767,
                "label": "Weirsdale",
                "label_ar": "ويرسدال",
                "label_fr": "Weirsdale"
            },
            {
                "value": 16768,
                "label": "Weirton",
                "label_ar": "ويرتون",
                "label_fr": "Weirton"
            },
            {
                "value": 16769,
                "label": "Weiser",
                "label_ar": "وايزر",
                "label_fr": "Weiser"
            },
            {
                "value": 16770,
                "label": "Welaka",
                "label_ar": "ويلاكا",
                "label_fr": "Welaka"
            },
            {
                "value": 16771,
                "label": "Welch",
                "label_ar": "ويلش",
                "label_fr": "Welch"
            },
            {
                "value": 16772,
                "label": "Welches",
                "label_ar": "ويلشيس",
                "label_fr": "Welches"
            },
            {
                "value": 16773,
                "label": "Welcome",
                "label_ar": "أهلا بك",
                "label_fr": "Bienvenue"
            },
            {
                "value": 16774,
                "label": "Weldon",
                "label_ar": "ويلدون",
                "label_fr": "Weldon"
            },
            {
                "value": 16775,
                "label": "Weleetka",
                "label_ar": "ويليتكا",
                "label_fr": "Weleetka"
            },
            {
                "value": 16776,
                "label": "Wellborn",
                "label_ar": "ويلبورن",
                "label_fr": "Bien né"
            },
            {
                "value": 16777,
                "label": "Wellesley",
                "label_ar": "ويليسلي",
                "label_fr": "Wellesley"
            },
            {
                "value": 16778,
                "label": "Wellesley Hills",
                "label_ar": "ويليسلي هيلز",
                "label_fr": "Wellesley Hills"
            },
            {
                "value": 16779,
                "label": "Wellfleet",
                "label_ar": "ويلفليت",
                "label_fr": "Wellfleet"
            },
            {
                "value": 16780,
                "label": "Wellford",
                "label_ar": "ويلفورد",
                "label_fr": "Wellford"
            },
            {
                "value": 16781,
                "label": "Wellington",
                "label_ar": "ويلينجتون",
                "label_fr": "Wellington"
            },
            {
                "value": 16782,
                "label": "Wellman",
                "label_ar": "رجل جيد",
                "label_fr": "Wellman"
            },
            {
                "value": 16783,
                "label": "Wellpinit",
                "label_ar": "Wellpinit",
                "label_fr": "Wellpinit"
            },
            {
                "value": 16784,
                "label": "Wells",
                "label_ar": "آبار",
                "label_fr": "puits"
            },
            {
                "value": 16785,
                "label": "Wellsboro",
                "label_ar": "ويلسبورو",
                "label_fr": "Wellsboro"
            },
            {
                "value": 16786,
                "label": "Wellsburg",
                "label_ar": "ويلسبورغ",
                "label_fr": "Wellsburg"
            },
            {
                "value": 16787,
                "label": "Wellsley",
                "label_ar": "ويلسلي",
                "label_fr": "Wellsley"
            },
            {
                "value": 16788,
                "label": "Wellston",
                "label_ar": "ويلستون",
                "label_fr": "Wellston"
            },
            {
                "value": 16789,
                "label": "Wellsville",
                "label_ar": "ويلسفيل",
                "label_fr": "Wellsville"
            },
            {
                "value": 16790,
                "label": "Wellton",
                "label_ar": "ويلتون",
                "label_fr": "Wellton"
            },
            {
                "value": 16791,
                "label": "Welsh",
                "label_ar": "تهرب من دفع الرهان",
                "label_fr": "gallois"
            },
            {
                "value": 16792,
                "label": "Wenatchee",
                "label_ar": "ويناتشي",
                "label_fr": "Wenatchee"
            },
            {
                "value": 16793,
                "label": "Wendel",
                "label_ar": "ويندل",
                "label_fr": "Wendel"
            },
            {
                "value": 16794,
                "label": "Wendell",
                "label_ar": "ويندل",
                "label_fr": "Wendell"
            },
            {
                "value": 16795,
                "label": "Wenden",
                "label_ar": "ويندين",
                "label_fr": "Wenden"
            },
            {
                "value": 16796,
                "label": "Wendover",
                "label_ar": "ويندوفر",
                "label_fr": "Wendover"
            },
            {
                "value": 16797,
                "label": "Wenham",
                "label_ar": "وينهام",
                "label_fr": "Wenham"
            },
            {
                "value": 16798,
                "label": "Wenona",
                "label_ar": "وينونا",
                "label_fr": "Wenona"
            },
            {
                "value": 16799,
                "label": "Wenonah",
                "label_ar": "وينونة",
                "label_fr": "Wenonah"
            },
            {
                "value": 16800,
                "label": "Wentworth",
                "label_ar": "وينتورث",
                "label_fr": "Wentworth"
            },
            {
                "value": 16801,
                "label": "Wentzville",
                "label_ar": "وينتزفيل",
                "label_fr": "Wentzville"
            },
            {
                "value": 16802,
                "label": "Wernersville",
                "label_ar": "ويرنرسفيل",
                "label_fr": "Wernersville"
            },
            {
                "value": 16803,
                "label": "Weskan",
                "label_ar": "ويسكان",
                "label_fr": "Weskan"
            },
            {
                "value": 16804,
                "label": "Weslaco",
                "label_ar": "ويسلاكو",
                "label_fr": "Weslaco"
            },
            {
                "value": 16805,
                "label": "Wesley",
                "label_ar": "ويسلي",
                "label_fr": "Wesley"
            },
            {
                "value": 16806,
                "label": "Wesley Chapel",
                "label_ar": "مصلى ويسلي",
                "label_fr": "Chapelle Wesley"
            },
            {
                "value": 16807,
                "label": "Wessington",
                "label_ar": "ويسينغتون",
                "label_fr": "Wessington"
            },
            {
                "value": 16808,
                "label": "Wessington Springs",
                "label_ar": "ويسينغتون سبرينغز",
                "label_fr": "Wessington Springs"
            },
            {
                "value": 16809,
                "label": "Wesson",
                "label_ar": "ويسون",
                "label_fr": "Wesson"
            },
            {
                "value": 16810,
                "label": "West",
                "label_ar": "الغرب",
                "label_fr": "Ouest"
            },
            {
                "value": 16811,
                "label": "West Alexander",
                "label_ar": "غرب الكسندر",
                "label_fr": "Alexander occidental"
            },
            {
                "value": 16812,
                "label": "West Alexandria",
                "label_ar": "غرب الاسكندرية",
                "label_fr": "Alexandrie occidentale"
            },
            {
                "value": 16813,
                "label": "West Alton",
                "label_ar": "ويست ألتون",
                "label_fr": "West Alton"
            },
            {
                "value": 16814,
                "label": "West Babylon",
                "label_ar": "غرب بابل",
                "label_fr": "Babylone occidentale"
            },
            {
                "value": 16815,
                "label": "West Baldwin",
                "label_ar": "غرب بالدوين",
                "label_fr": "West Baldwin"
            },
            {
                "value": 16816,
                "label": "West Barnstable",
                "label_ar": "ويست بارنستابل",
                "label_fr": "West Barnstable"
            },
            {
                "value": 16817,
                "label": "West Bend",
                "label_ar": "ويست بيند",
                "label_fr": "West Bend"
            },
            {
                "value": 16818,
                "label": "West Berlin",
                "label_ar": "برلين الغربية",
                "label_fr": "Berlin ouest"
            },
            {
                "value": 16819,
                "label": "West Blocton",
                "label_ar": "ويست بلوكتون",
                "label_fr": "West Blocton"
            },
            {
                "value": 16820,
                "label": "West Bloomfield",
                "label_ar": "ويست بلومفيلد",
                "label_fr": "West Bloomfield"
            },
            {
                "value": 16821,
                "label": "West Boylston",
                "label_ar": "ويست بويلستون",
                "label_fr": "West Boylston"
            },
            {
                "value": 16822,
                "label": "West Branch",
                "label_ar": "فرع الغرب",
                "label_fr": "Branche ouest"
            },
            {
                "value": 16823,
                "label": "West Briar",
                "label_ar": "ويست براير",
                "label_fr": "West Briar"
            },
            {
                "value": 16824,
                "label": "West Bridgewater",
                "label_ar": "ويست بريدجووتر",
                "label_fr": "West Bridgewater"
            },
            {
                "value": 16825,
                "label": "West Brookfield",
                "label_ar": "ويست بروكفيلد",
                "label_fr": "West Brookfield"
            },
            {
                "value": 16826,
                "label": "West Burke",
                "label_ar": "ويست بورك",
                "label_fr": "West Burke"
            },
            {
                "value": 16827,
                "label": "West Burlington",
                "label_ar": "ويست برلنغتون",
                "label_fr": "West Burlington"
            },
            {
                "value": 16828,
                "label": "West Chatham",
                "label_ar": "ويست تشاتام",
                "label_fr": "West Chatham"
            },
            {
                "value": 16829,
                "label": "West Chazy",
                "label_ar": "غرب تشازي",
                "label_fr": "West Chazy"
            },
            {
                "value": 16830,
                "label": "West Chester",
                "label_ar": "ويست تشيستر",
                "label_fr": "West Chester"
            },
            {
                "value": 16831,
                "label": "West Chesterfield",
                "label_ar": "ويست تشيسترفيلد",
                "label_fr": "West Chesterfield"
            },
            {
                "value": 16832,
                "label": "West Chicago",
                "label_ar": "غرب شيكاغو",
                "label_fr": "Chicago Ouest"
            },
            {
                "value": 16833,
                "label": "West Columbia",
                "label_ar": "غرب كولومبيا",
                "label_fr": "Columbia Ouest"
            },
            {
                "value": 16834,
                "label": "West Concord",
                "label_ar": "غرب كونكورد",
                "label_fr": "West Concord"
            },
            {
                "value": 16835,
                "label": "West Corinth",
                "label_ar": "ويست كورنث",
                "label_fr": "Corinthe occidentale"
            },
            {
                "value": 16836,
                "label": "West Cornwall",
                "label_ar": "ويست كورنوال",
                "label_fr": "Cornouailles ouest"
            },
            {
                "value": 16837,
                "label": "West Covina",
                "label_ar": "غرب كوفينا",
                "label_fr": "West Covina"
            },
            {
                "value": 16838,
                "label": "West Coxsackie",
                "label_ar": "ويست كوكساكي",
                "label_fr": "West Coxsackie"
            },
            {
                "value": 16839,
                "label": "West Creek",
                "label_ar": "ويست كريك",
                "label_fr": "West Creek"
            },
            {
                "value": 16840,
                "label": "West Danville",
                "label_ar": "غرب دانفيل",
                "label_fr": "West Danville"
            },
            {
                "value": 16841,
                "label": "West Decatur",
                "label_ar": "غرب ديكاتور",
                "label_fr": "Decatur ouest"
            },
            {
                "value": 16842,
                "label": "West Dennis",
                "label_ar": "غرب دينيس",
                "label_fr": "West Dennis"
            },
            {
                "value": 16843,
                "label": "West Des Moines",
                "label_ar": "ويست دي موين",
                "label_fr": "West Des Moines"
            },
            {
                "value": 16844,
                "label": "West Dover",
                "label_ar": "ويست دوفر",
                "label_fr": "West Dover"
            },
            {
                "value": 16845,
                "label": "West Dummerston",
                "label_ar": "غرب دومرستون",
                "label_fr": "West Dummerston"
            },
            {
                "value": 16846,
                "label": "West Dundee",
                "label_ar": "غرب دندي",
                "label_fr": "West Dundee"
            },
            {
                "value": 16847,
                "label": "West Durham",
                "label_ar": "ويست دورهام",
                "label_fr": "West Durham"
            },
            {
                "value": 16848,
                "label": "West Edmeston",
                "label_ar": "ويست إدمستون",
                "label_fr": "West Edmeston"
            },
            {
                "value": 16849,
                "label": "West End",
                "label_ar": "نهاية الغرب",
                "label_fr": "extremite ouest"
            },
            {
                "value": 16850,
                "label": "West Enfield",
                "label_ar": "ويست انفيلد",
                "label_fr": "West Enfield"
            },
            {
                "value": 16851,
                "label": "West Falls",
                "label_ar": "ويست فولز",
                "label_fr": "West Falls"
            },
            {
                "value": 16852,
                "label": "West Falmouth",
                "label_ar": "ويست فالماوث",
                "label_fr": "West Falmouth"
            },
            {
                "value": 16853,
                "label": "West Fargo",
                "label_ar": "ويست فارجو",
                "label_fr": "West Fargo"
            },
            {
                "value": 16854,
                "label": "West Farmington",
                "label_ar": "ويست فارمنجتون",
                "label_fr": "West Farmington"
            },
            {
                "value": 16855,
                "label": "West Finley",
                "label_ar": "ويست فينلي",
                "label_fr": "West Finley"
            },
            {
                "value": 16856,
                "label": "West Fork",
                "label_ar": "ويست فورك",
                "label_fr": "West Fork"
            },
            {
                "value": 16857,
                "label": "West Frankfort",
                "label_ar": "غرب فرانكفورت",
                "label_fr": "Francfort ouest"
            },
            {
                "value": 16858,
                "label": "West Friendship",
                "label_ar": "صداقة الغرب",
                "label_fr": "Amitié de l'Ouest"
            },
            {
                "value": 16859,
                "label": "West Glover",
                "label_ar": "ويست جلوفر",
                "label_fr": "West Glover"
            },
            {
                "value": 16860,
                "label": "West Granby",
                "label_ar": "غرب جرانبي",
                "label_fr": "West Granby"
            },
            {
                "value": 16861,
                "label": "West Green",
                "label_ar": "ويست جرين",
                "label_fr": "West Green"
            },
            {
                "value": 16862,
                "label": "West Greenwich",
                "label_ar": "ويست غرينتش",
                "label_fr": "West Greenwich"
            },
            {
                "value": 16863,
                "label": "West Groton",
                "label_ar": "ويست جروتون",
                "label_fr": "West Groton"
            },
            {
                "value": 16864,
                "label": "West Grove",
                "label_ar": "ويست جروف",
                "label_fr": "West Grove"
            },
            {
                "value": 16865,
                "label": "West Hamlin",
                "label_ar": "وست هاملين",
                "label_fr": "West Hamlin"
            },
            {
                "value": 16866,
                "label": "West Harrison",
                "label_ar": "ويست هاريسون",
                "label_fr": "West Harrison"
            },
            {
                "value": 16867,
                "label": "West Hartford",
                "label_ar": "ويست هارتفورد",
                "label_fr": "West Hartford"
            },
            {
                "value": 16868,
                "label": "West Hartland",
                "label_ar": "ويست هارتلاند",
                "label_fr": "West Hartland"
            },
            {
                "value": 16869,
                "label": "West Harwich",
                "label_ar": "ويست هارويتش",
                "label_fr": "West Harwich"
            },
            {
                "value": 16870,
                "label": "West Haven",
                "label_ar": "ويست هافن",
                "label_fr": "West Haven"
            },
            {
                "value": 16871,
                "label": "West Haverstraw",
                "label_ar": "غرب هافرسترو",
                "label_fr": "West Haverstraw"
            },
            {
                "value": 16872,
                "label": "West Helena",
                "label_ar": "غرب هيلينا",
                "label_fr": "West Helena"
            },
            {
                "value": 16873,
                "label": "West Hempstead",
                "label_ar": "ويست هيمبستيد",
                "label_fr": "West Hempstead"
            },
            {
                "value": 16874,
                "label": "West Henrietta",
                "label_ar": "غرب هنريتا",
                "label_fr": "Henrietta Ouest"
            },
            {
                "value": 16875,
                "label": "West Hills",
                "label_ar": "ويست هيلز",
                "label_fr": "West Hills"
            },
            {
                "value": 16876,
                "label": "West Hollywood",
                "label_ar": "ويست هوليود",
                "label_fr": "Hollywood Ouest"
            },
            {
                "value": 16877,
                "label": "West Islip",
                "label_ar": "غرب إسليب",
                "label_fr": "West Islip"
            },
            {
                "value": 16878,
                "label": "West Jefferson",
                "label_ar": "ويست جيفرسون",
                "label_fr": "West Jefferson"
            },
            {
                "value": 16879,
                "label": "West Jordan",
                "label_ar": "غرب الاردن",
                "label_fr": "Jordanie occidentale"
            },
            {
                "value": 16880,
                "label": "West Kingston",
                "label_ar": "غرب كينغستون",
                "label_fr": "Kingston ouest"
            },
            {
                "value": 16881,
                "label": "West Lafayette",
                "label_ar": "ويست لافاييت",
                "label_fr": "West Lafayette"
            },
            {
                "value": 16882,
                "label": "West Lebanon",
                "label_ar": "لبنان الغربي",
                "label_fr": "Liban occidental"
            },
            {
                "value": 16883,
                "label": "West Leyden",
                "label_ar": "ويست ليدن",
                "label_fr": "West Leyden"
            },
            {
                "value": 16884,
                "label": "West Liberty",
                "label_ar": "ويست ليبرتي",
                "label_fr": "West Liberty"
            },
            {
                "value": 16885,
                "label": "West Linn",
                "label_ar": "ويست لين",
                "label_fr": "West Linn"
            },
            {
                "value": 16886,
                "label": "West Long Branch",
                "label_ar": "فرع ويست لونج",
                "label_fr": "West Long Branch"
            },
            {
                "value": 16887,
                "label": "West Louisville",
                "label_ar": "ويست لويزفيل",
                "label_fr": "West Louisville"
            },
            {
                "value": 16888,
                "label": "West Manchester",
                "label_ar": "غرب مانشستر",
                "label_fr": "Manchester ouest"
            },
            {
                "value": 16889,
                "label": "West Mansfield",
                "label_ar": "ويست مانسفيلد",
                "label_fr": "West Mansfield"
            },
            {
                "value": 16890,
                "label": "West McLean",
                "label_ar": "ويست ماكلين",
                "label_fr": "West McLean"
            },
            {
                "value": 16891,
                "label": "West Memphis",
                "label_ar": "غرب ممفيس",
                "label_fr": "West Memphis"
            },
            {
                "value": 16892,
                "label": "West Middlesex",
                "label_ar": "غرب ميدلسكس",
                "label_fr": "West Middlesex"
            },
            {
                "value": 16893,
                "label": "West Middleton",
                "label_ar": "وست ميدلتون",
                "label_fr": "West Middleton"
            },
            {
                "value": 16894,
                "label": "West Mifflin",
                "label_ar": "ويست ميفلين",
                "label_fr": "West Mifflin"
            },
            {
                "value": 16895,
                "label": "West Milford",
                "label_ar": "ويست ميلفورد",
                "label_fr": "West Milford"
            },
            {
                "value": 16896,
                "label": "West Milton",
                "label_ar": "ويست ميلتون",
                "label_fr": "West Milton"
            },
            {
                "value": 16897,
                "label": "West Mineral",
                "label_ar": "ويست مينرال",
                "label_fr": "West Mineral"
            },
            {
                "value": 16898,
                "label": "West Minot",
                "label_ar": "ويست مينوت",
                "label_fr": "West Minot"
            },
            {
                "value": 16899,
                "label": "West Monroe",
                "label_ar": "ويست مونرو",
                "label_fr": "West Monroe"
            },
            {
                "value": 16900,
                "label": "West New York",
                "label_ar": "غرب نيويورك",
                "label_fr": "West New York"
            },
            {
                "value": 16901,
                "label": "West Newbury",
                "label_ar": "ويست نيوبري",
                "label_fr": "West Newbury"
            },
            {
                "value": 16902,
                "label": "West Newton",
                "label_ar": "ويست نيوتن",
                "label_fr": "West Newton"
            },
            {
                "value": 16903,
                "label": "West Nyack",
                "label_ar": "ويست نياك",
                "label_fr": "West Nyack"
            },
            {
                "value": 16904,
                "label": "West Olive",
                "label_ar": "غرب الزيتون",
                "label_fr": "Olive de l'Ouest"
            },
            {
                "value": 16905,
                "label": "West Orange",
                "label_ar": "ويست أورانج",
                "label_fr": "Orange de l'Ouest"
            },
            {
                "value": 16906,
                "label": "West Paducah",
                "label_ar": "غرب بادوكا",
                "label_fr": "West Paducah"
            },
            {
                "value": 16907,
                "label": "West Palm Beach",
                "label_ar": "غرب بالم بيتش",
                "label_fr": "West Palm Beach"
            },
            {
                "value": 16908,
                "label": "West Paris",
                "label_ar": "غرب باريس",
                "label_fr": "Paris Ouest"
            },
            {
                "value": 16909,
                "label": "West Park",
                "label_ar": "ويست بارك",
                "label_fr": "West Park"
            },
            {
                "value": 16910,
                "label": "West Pawlet",
                "label_ar": "ويست باوليت",
                "label_fr": "Pawlet Ouest"
            },
            {
                "value": 16911,
                "label": "West Pittsburg",
                "label_ar": "غرب بيتسبرغ",
                "label_fr": "West Pittsburg"
            },
            {
                "value": 16912,
                "label": "West Plains",
                "label_ar": "ويست بلينز",
                "label_fr": "Plaines de l'Ouest"
            },
            {
                "value": 16913,
                "label": "West Point",
                "label_ar": "نقطة غربية",
                "label_fr": "West Point"
            },
            {
                "value": 16914,
                "label": "West Portsmouth",
                "label_ar": "غرب بورتسموث",
                "label_fr": "West Portsmouth"
            },
            {
                "value": 16915,
                "label": "West Richland",
                "label_ar": "غرب ريتشلاند",
                "label_fr": "West Richland"
            },
            {
                "value": 16916,
                "label": "West River",
                "label_ar": "ويست ريفر",
                "label_fr": "West River"
            },
            {
                "value": 16917,
                "label": "West Rockport",
                "label_ar": "ويست روكبورت",
                "label_fr": "West Rockport"
            },
            {
                "value": 16918,
                "label": "West Roxbury",
                "label_ar": "ويست روكسبري",
                "label_fr": "West Roxbury"
            },
            {
                "value": 16919,
                "label": "West Rutland",
                "label_ar": "غرب روتلاند",
                "label_fr": "West Rutland"
            },
            {
                "value": 16920,
                "label": "West Sacramento",
                "label_ar": "غرب ساكرامنتو",
                "label_fr": "West Sacramento"
            },
            {
                "value": 16921,
                "label": "West Salem",
                "label_ar": "غرب سالم",
                "label_fr": "West Salem"
            },
            {
                "value": 16922,
                "label": "West Sand Lake",
                "label_ar": "بحيرة ويست ساند",
                "label_fr": "West Sand Lake"
            },
            {
                "value": 16923,
                "label": "West Sayville",
                "label_ar": "غرب سايفيل",
                "label_fr": "West Sayville"
            },
            {
                "value": 16924,
                "label": "West Shokan",
                "label_ar": "ويست شوكان",
                "label_fr": "West Shokan"
            },
            {
                "value": 16925,
                "label": "West Simsbury",
                "label_ar": "ويست سيمسبري",
                "label_fr": "West Simsbury"
            },
            {
                "value": 16926,
                "label": "West Springfield",
                "label_ar": "ويست سبرينغفيلد",
                "label_fr": "West Springfield"
            },
            {
                "value": 16927,
                "label": "West Stockbridge",
                "label_ar": "غرب ستوكبريدج",
                "label_fr": "West Stockbridge"
            },
            {
                "value": 16928,
                "label": "West Stockholm",
                "label_ar": "غرب ستوكهولم",
                "label_fr": "Stockholm ouest"
            },
            {
                "value": 16929,
                "label": "West Suffield",
                "label_ar": "وست سوفيلد",
                "label_fr": "West Suffield"
            },
            {
                "value": 16930,
                "label": "West Sunbury",
                "label_ar": "ويست صنبيري",
                "label_fr": "West Sunbury"
            },
            {
                "value": 16931,
                "label": "West Swanzey",
                "label_ar": "ويست سوانزي",
                "label_fr": "West Swanzey"
            },
            {
                "value": 16932,
                "label": "West Terre Haute",
                "label_ar": "ويست تير هوت",
                "label_fr": "West Terre Haute"
            },
            {
                "value": 16933,
                "label": "West Tisbury",
                "label_ar": "ويست تيسبري",
                "label_fr": "West Tisbury"
            },
            {
                "value": 16934,
                "label": "West Topsham",
                "label_ar": "ويست توبشام",
                "label_fr": "West Topsham"
            },
            {
                "value": 16935,
                "label": "West Townsend",
                "label_ar": "ويست تاونسيند",
                "label_fr": "West Townsend"
            },
            {
                "value": 16936,
                "label": "West Union",
                "label_ar": "اتحاد غرب",
                "label_fr": "Union occidentale"
            },
            {
                "value": 16937,
                "label": "West Unity",
                "label_ar": "الوحدة الغربية",
                "label_fr": "Unité occidentale"
            },
            {
                "value": 16938,
                "label": "West Valley",
                "label_ar": "ويست فالي",
                "label_fr": "West Valley"
            },
            {
                "value": 16939,
                "label": "West Van Lear",
                "label_ar": "ويست فان لير",
                "label_fr": "West Van Lear"
            },
            {
                "value": 16940,
                "label": "West Wardsboro",
                "label_ar": "ويست واردسبورو",
                "label_fr": "West Wardsboro"
            },
            {
                "value": 16941,
                "label": "West Wareham",
                "label_ar": "ويست ويرهام",
                "label_fr": "West Wareham"
            },
            {
                "value": 16942,
                "label": "West Warwick",
                "label_ar": "ويست وارويك",
                "label_fr": "West Warwick"
            },
            {
                "value": 16943,
                "label": "West Wendover",
                "label_ar": "ويست ويندوفر",
                "label_fr": "West Wendover"
            },
            {
                "value": 16944,
                "label": "West Winfield",
                "label_ar": "ويست وينفيلد",
                "label_fr": "West Winfield"
            },
            {
                "value": 16945,
                "label": "West Yarmouth",
                "label_ar": "غرب يارماوث",
                "label_fr": "West Yarmouth"
            },
            {
                "value": 16946,
                "label": "West Yellowstone",
                "label_ar": "غرب يلوستون",
                "label_fr": "West Yellowstone"
            },
            {
                "value": 16947,
                "label": "Westboro",
                "label_ar": "ويستبورو",
                "label_fr": "Westboro"
            },
            {
                "value": 16948,
                "label": "Westborough",
                "label_ar": "ويستبورو",
                "label_fr": "Westborough"
            },
            {
                "value": 16949,
                "label": "Westbrook",
                "label_ar": "ويستبروك",
                "label_fr": "Westbrook"
            },
            {
                "value": 16950,
                "label": "Westbrookville",
                "label_ar": "ويستبروكفيل",
                "label_fr": "Westbrookville"
            },
            {
                "value": 16951,
                "label": "Westbury",
                "label_ar": "ويستبري",
                "label_fr": "Westbury"
            },
            {
                "value": 16952,
                "label": "Westby",
                "label_ar": "ويستبي",
                "label_fr": "Westby"
            },
            {
                "value": 16953,
                "label": "Westchester",
                "label_ar": "ويستشستر",
                "label_fr": "Westchester"
            },
            {
                "value": 16954,
                "label": "Westcliffe",
                "label_ar": "ويستكليف",
                "label_fr": "Westcliffe"
            },
            {
                "value": 16955,
                "label": "Westerlo",
                "label_ar": "ويستيرلو",
                "label_fr": "Westerlo"
            },
            {
                "value": 16956,
                "label": "Westerly",
                "label_ar": "غربي",
                "label_fr": "Westerly"
            },
            {
                "value": 16957,
                "label": "Western Grove",
                "label_ar": "ويسترن جروف",
                "label_fr": "Western Grove"
            },
            {
                "value": 16958,
                "label": "Western Springs",
                "label_ar": "ويسترن سبرينغز",
                "label_fr": "Western Springs"
            },
            {
                "value": 16959,
                "label": "Westernport",
                "label_ar": "ويسترنبورت",
                "label_fr": "Westernport"
            },
            {
                "value": 16960,
                "label": "Westernville",
                "label_ar": "ويسترنفيل",
                "label_fr": "Westernville"
            },
            {
                "value": 16961,
                "label": "Westerville",
                "label_ar": "ويستيرفيل",
                "label_fr": "Westerville"
            },
            {
                "value": 16962,
                "label": "Westfield",
                "label_ar": "ويستفيلد",
                "label_fr": "Westfield"
            },
            {
                "value": 16963,
                "label": "Westfield Center",
                "label_ar": "مركز ويستفيلد",
                "label_fr": "Westfield Centre"
            },
            {
                "value": 16964,
                "label": "Westford",
                "label_ar": "الغرب فورد",
                "label_fr": "Westford"
            },
            {
                "value": 16965,
                "label": "Westgate",
                "label_ar": "ويست جيت",
                "label_fr": "Westgate"
            },
            {
                "value": 16966,
                "label": "Westhampton",
                "label_ar": "ويستهامبتون",
                "label_fr": "Westhampton"
            },
            {
                "value": 16967,
                "label": "Westhampton Beach",
                "label_ar": "شاطئ ويستهامبتون",
                "label_fr": "Plage de Westhampton"
            },
            {
                "value": 16968,
                "label": "Westhope",
                "label_ar": "ويستهوب",
                "label_fr": "Westhope"
            },
            {
                "value": 16969,
                "label": "Westlake",
                "label_ar": "البحيرة الغربية",
                "label_fr": "Lac de l'Ouest"
            },
            {
                "value": 16970,
                "label": "Westlake Village",
                "label_ar": "قرية ويستليك",
                "label_fr": "Village de Westlake"
            },
            {
                "value": 16971,
                "label": "Westland",
                "label_ar": "ويستلاند",
                "label_fr": "Westland"
            },
            {
                "value": 16972,
                "label": "Westley",
                "label_ar": "ويستلي",
                "label_fr": "Westley"
            },
            {
                "value": 16973,
                "label": "Westminster",
                "label_ar": "وستمنستر",
                "label_fr": "Westminster"
            },
            {
                "value": 16974,
                "label": "Westmont",
                "label_ar": "ويستمونت",
                "label_fr": "Westmont"
            },
            {
                "value": 16975,
                "label": "Westmoreland",
                "label_ar": "ويستمورلاند",
                "label_fr": "Westmoreland"
            },
            {
                "value": 16976,
                "label": "Westmoreland City",
                "label_ar": "مدينة ويستمورلاند",
                "label_fr": "Westmoreland City"
            },
            {
                "value": 16977,
                "label": "Weston",
                "label_ar": "ويستون",
                "label_fr": "Weston"
            },
            {
                "value": 16978,
                "label": "Westover",
                "label_ar": "ويستوفر",
                "label_fr": "Westover"
            },
            {
                "value": 16979,
                "label": "Westphalia",
                "label_ar": "ويستفاليا",
                "label_fr": "Westphalie"
            },
            {
                "value": 16980,
                "label": "Westpoint",
                "label_ar": "نقطة غربية",
                "label_fr": "West Point"
            },
            {
                "value": 16981,
                "label": "Westport",
                "label_ar": "ويستبورت",
                "label_fr": "Westport"
            },
            {
                "value": 16982,
                "label": "Westside",
                "label_ar": "الجانب الغربي",
                "label_fr": "Côté ouest"
            },
            {
                "value": 16983,
                "label": "Westtown",
                "label_ar": "ويستتاون",
                "label_fr": "Westtown"
            },
            {
                "value": 16984,
                "label": "Westview",
                "label_ar": "ويستفيو",
                "label_fr": "Westview"
            },
            {
                "value": 16985,
                "label": "Westville",
                "label_ar": "ويستفيل",
                "label_fr": "Westville"
            },
            {
                "value": 16986,
                "label": "Westwego",
                "label_ar": "ويستويغو",
                "label_fr": "Westwego"
            },
            {
                "value": 16987,
                "label": "Westwood",
                "label_ar": "ويستوود",
                "label_fr": "Westwood"
            },
            {
                "value": 16988,
                "label": "Wethersfield",
                "label_ar": "ويذرسفيلد",
                "label_fr": "Wethersfield"
            },
            {
                "value": 16989,
                "label": "Wetmore",
                "label_ar": "ويتمور",
                "label_fr": "Wetmore"
            },
            {
                "value": 16990,
                "label": "Wetumka",
                "label_ar": "ويتومكا",
                "label_fr": "Wetumka"
            },
            {
                "value": 16991,
                "label": "Wetumpka",
                "label_ar": "ويتومبكا",
                "label_fr": "Wetumpka"
            },
            {
                "value": 16992,
                "label": "Wewahitchka",
                "label_ar": "Wewahitchka",
                "label_fr": "Wewahitchka"
            },
            {
                "value": 16993,
                "label": "Wewoka",
                "label_ar": "ويوكا",
                "label_fr": "Wewoka"
            },
            {
                "value": 16994,
                "label": "Wexford",
                "label_ar": "ويكسفورد",
                "label_fr": "Wexford"
            },
            {
                "value": 16995,
                "label": "Weyauwega",
                "label_ar": "وياويجا",
                "label_fr": "Weyauwega"
            },
            {
                "value": 16996,
                "label": "Weyerhaeuser",
                "label_ar": "Weyerhaeuser",
                "label_fr": "Weyerhaeuser"
            },
            {
                "value": 16997,
                "label": "Weyers Cave",
                "label_ar": "كهف وايرز",
                "label_fr": "Grotte de Weyers"
            },
            {
                "value": 16998,
                "label": "Weymouth",
                "label_ar": "ويموث",
                "label_fr": "Weymouth"
            },
            {
                "value": 16999,
                "label": "Whaleyville",
                "label_ar": "واليفيل",
                "label_fr": "Whaleyville"
            },
            {
                "value": 17000,
                "label": "Wharncliffe",
                "label_ar": "Wharncliffe",
                "label_fr": "Wharncliffe"
            },
            {
                "value": 17001,
                "label": "Wharton",
                "label_ar": "وارتون",
                "label_fr": "Wharton"
            },
            {
                "value": 17002,
                "label": "What Cheer",
                "label_ar": "ما ابتهج",
                "label_fr": "Quelle joie"
            },
            {
                "value": 17003,
                "label": "Whately",
                "label_ar": "مهما",
                "label_fr": "Whately"
            },
            {
                "value": 17004,
                "label": "Whatley",
                "label_ar": "واتلي",
                "label_fr": "Whatley"
            },
            {
                "value": 17005,
                "label": "Wheat Ridge",
                "label_ar": "ريدج القمح",
                "label_fr": "Wheat Ridge"
            },
            {
                "value": 17006,
                "label": "Wheatfield",
                "label_ar": "حقل القمح",
                "label_fr": "Champ de blé"
            },
            {
                "value": 17007,
                "label": "Wheatland",
                "label_ar": "ويتلاند",
                "label_fr": "Wheatland"
            },
            {
                "value": 17008,
                "label": "Wheaton",
                "label_ar": "ويتون",
                "label_fr": "Wheaton"
            },
            {
                "value": 17009,
                "label": "Wheeler",
                "label_ar": "ويلر",
                "label_fr": "Wheeler"
            },
            {
                "value": 17010,
                "label": "Wheelersburg",
                "label_ar": "ويلرسبورغ",
                "label_fr": "Wheelersburg"
            },
            {
                "value": 17011,
                "label": "Wheeling",
                "label_ar": "ويلنج",
                "label_fr": "Wheeling"
            },
            {
                "value": 17012,
                "label": "Wheelwright",
                "label_ar": "صانع العجلات",
                "label_fr": "Charron"
            },
            {
                "value": 17013,
                "label": "Whigham",
                "label_ar": "ويغام",
                "label_fr": "Whigham"
            },
            {
                "value": 17014,
                "label": "Whippany",
                "label_ar": "ويباني",
                "label_fr": "Whippany"
            },
            {
                "value": 17015,
                "label": "Whipple",
                "label_ar": "ويبل",
                "label_fr": "Whipple"
            },
            {
                "value": 17016,
                "label": "Whitakers",
                "label_ar": "ويتيكرز",
                "label_fr": "Whitakers"
            },
            {
                "value": 17017,
                "label": "White",
                "label_ar": "أبيض",
                "label_fr": "blanc"
            },
            {
                "value": 17018,
                "label": "White Bear Lake",
                "label_ar": "بحيرة الدب الأبيض",
                "label_fr": "Lac de l'ours blanc"
            },
            {
                "value": 17019,
                "label": "White Bird",
                "label_ar": "طير أبيض",
                "label_fr": "Oiseau blanc"
            },
            {
                "value": 17020,
                "label": "White Bluff",
                "label_ar": "الخداع الأبيض",
                "label_fr": "Bluff blanc"
            },
            {
                "value": 17021,
                "label": "White Castle",
                "label_ar": "قلعة بيضاء",
                "label_fr": "château Blanc"
            },
            {
                "value": 17022,
                "label": "White City",
                "label_ar": "المدينة البيضاء",
                "label_fr": "Ville blanche"
            },
            {
                "value": 17023,
                "label": "White Cloud",
                "label_ar": "سحابة بيضاء",
                "label_fr": "Nuage blanc"
            },
            {
                "value": 17024,
                "label": "White Deer",
                "label_ar": "الأيل الأبيض",
                "label_fr": "Cerf blanc"
            },
            {
                "value": 17025,
                "label": "White Hall",
                "label_ar": "القاعة البيضاء",
                "label_fr": "Salle blanche"
            },
            {
                "value": 17026,
                "label": "White Haven",
                "label_ar": "وايت هافن",
                "label_fr": "Havre blanc"
            },
            {
                "value": 17027,
                "label": "White Heath",
                "label_ar": "وايت هيث",
                "label_fr": "Bruyère blanche"
            },
            {
                "value": 17028,
                "label": "White House",
                "label_ar": "البيت الابيض",
                "label_fr": "maison Blanche"
            },
            {
                "value": 17029,
                "label": "White Lake",
                "label_ar": "البحيرة البيضاء",
                "label_fr": "Lac blanc"
            },
            {
                "value": 17030,
                "label": "White Marsh",
                "label_ar": "وايت مارش",
                "label_fr": "Marais blanc"
            },
            {
                "value": 17031,
                "label": "White Mountain Lake",
                "label_ar": "بحيرة وايت ماونتن",
                "label_fr": "Lac White Mountain"
            },
            {
                "value": 17032,
                "label": "White Oak",
                "label_ar": "بلوط ابيض",
                "label_fr": "chêne blanc"
            },
            {
                "value": 17033,
                "label": "White Pigeon",
                "label_ar": "حمامة بيضاء",
                "label_fr": "Pigeon blanc"
            },
            {
                "value": 17034,
                "label": "White Pine",
                "label_ar": "الصنوبر الابيض",
                "label_fr": "pin blanc"
            },
            {
                "value": 17035,
                "label": "White Plains",
                "label_ar": "سهول بيضاء",
                "label_fr": "plaines blanches"
            },
            {
                "value": 17036,
                "label": "White Post",
                "label_ar": "وايت بوست",
                "label_fr": "Poteau blanc"
            },
            {
                "value": 17037,
                "label": "White River Junction",
                "label_ar": "وايت ريفر جانكشن",
                "label_fr": "Jonction de White River"
            },
            {
                "value": 17038,
                "label": "White Salmon",
                "label_ar": "سمك السلمون الأبيض",
                "label_fr": "Saumon blanc"
            },
            {
                "value": 17039,
                "label": "White Sands",
                "label_ar": "رمال بيضاء",
                "label_fr": "sables Blancs"
            },
            {
                "value": 17040,
                "label": "White Springs",
                "label_ar": "وايت سبرينغز",
                "label_fr": "White Springs"
            },
            {
                "value": 17041,
                "label": "White Stone",
                "label_ar": "حجر ابيض",
                "label_fr": "Pierre blanche"
            },
            {
                "value": 17042,
                "label": "White Sulphur Springs",
                "label_ar": "ينابيع الكبريت الأبيض",
                "label_fr": "White Sulphur Springs"
            },
            {
                "value": 17043,
                "label": "White Swan",
                "label_ar": "البجعة البيضاء",
                "label_fr": "cygne blanc"
            },
            {
                "value": 17044,
                "label": "White Water",
                "label_ar": "وايت ووتر",
                "label_fr": "Eau vive"
            },
            {
                "value": 17045,
                "label": "Whiteface",
                "label_ar": "وجه أبيض",
                "label_fr": "Visage blanc"
            },
            {
                "value": 17046,
                "label": "Whitefield",
                "label_ar": "حقل أبيض",
                "label_fr": "Whitefield"
            },
            {
                "value": 17047,
                "label": "Whitefish",
                "label_ar": "السمك الأبيض",
                "label_fr": "Poisson blanc"
            },
            {
                "value": 17048,
                "label": "Whiteford",
                "label_ar": "وايتفورد",
                "label_fr": "Whiteford"
            },
            {
                "value": 17049,
                "label": "Whitehall",
                "label_ar": "وايتهول",
                "label_fr": "Whitehall"
            },
            {
                "value": 17050,
                "label": "Whitehall Township",
                "label_ar": "بلدة وايتهول",
                "label_fr": "Canton de Whitehall"
            },
            {
                "value": 17051,
                "label": "Whitehouse",
                "label_ar": "البيت الابيض",
                "label_fr": "Maison Blanche"
            },
            {
                "value": 17052,
                "label": "Whitehouse Station",
                "label_ar": "محطة وايتهاوس",
                "label_fr": "Gare de Whitehouse"
            },
            {
                "value": 17053,
                "label": "Whiteland",
                "label_ar": "وايتلاند",
                "label_fr": "Whiteland"
            },
            {
                "value": 17054,
                "label": "Whiteman Air Force Base",
                "label_ar": "قاعدة وايتمان الجوية",
                "label_fr": "Base aérienne de Whiteman"
            },
            {
                "value": 17055,
                "label": "Whiteriver",
                "label_ar": "النهر الأبيض",
                "label_fr": "Whiteriver"
            },
            {
                "value": 17056,
                "label": "Whites Creek",
                "label_ar": "وايت كريك",
                "label_fr": "Whites Creek"
            },
            {
                "value": 17057,
                "label": "Whitesboro",
                "label_ar": "وايتسبورو",
                "label_fr": "Whitesboro"
            },
            {
                "value": 17058,
                "label": "Whitesburg",
                "label_ar": "وايتسبورغ",
                "label_fr": "Whitesburg"
            },
            {
                "value": 17059,
                "label": "Whiteside",
                "label_ar": "الجانب الأبيض",
                "label_fr": "Côté blanc"
            },
            {
                "value": 17060,
                "label": "Whitestone",
                "label_ar": "وايتستون",
                "label_fr": "Pierre blanche"
            },
            {
                "value": 17061,
                "label": "Whitestown",
                "label_ar": "وايتستاون",
                "label_fr": "Whitestown"
            },
            {
                "value": 17062,
                "label": "Whitesville",
                "label_ar": "وايتسفيل",
                "label_fr": "Whitesville"
            },
            {
                "value": 17063,
                "label": "Whitethorn",
                "label_ar": "ويتثورن",
                "label_fr": "Whitethorn"
            },
            {
                "value": 17064,
                "label": "Whiteville",
                "label_ar": "ويتفيل",
                "label_fr": "Whiteville"
            },
            {
                "value": 17065,
                "label": "Whitewater",
                "label_ar": "وايت ووتر",
                "label_fr": "Eau vive"
            },
            {
                "value": 17066,
                "label": "Whitewright",
                "label_ar": "وايترايت",
                "label_fr": "Whitewright"
            },
            {
                "value": 17067,
                "label": "Whiting",
                "label_ar": "البياض",
                "label_fr": "Merlan"
            },
            {
                "value": 17068,
                "label": "Whitingham",
                "label_ar": "ويتينغهام",
                "label_fr": "Whitingham"
            },
            {
                "value": 17069,
                "label": "Whitinsville",
                "label_ar": "ويتينسفيل",
                "label_fr": "Whitinsville"
            },
            {
                "value": 17070,
                "label": "Whitley City",
                "label_ar": "ويتلي سيتي",
                "label_fr": "Whitley City"
            },
            {
                "value": 17071,
                "label": "Whitman",
                "label_ar": "ويتمان",
                "label_fr": "Whitman"
            },
            {
                "value": 17072,
                "label": "Whitmire",
                "label_ar": "ويتمير",
                "label_fr": "Whitmire"
            },
            {
                "value": 17073,
                "label": "Whitmore",
                "label_ar": "ويتمور",
                "label_fr": "Whitmore"
            },
            {
                "value": 17074,
                "label": "Whitmore Lake",
                "label_ar": "بحيرة ويتمور",
                "label_fr": "Lac Whitmore"
            },
            {
                "value": 17075,
                "label": "Whitney",
                "label_ar": "ويتني",
                "label_fr": "Whitney"
            },
            {
                "value": 17076,
                "label": "Whitney Point",
                "label_ar": "ويتني بوينت",
                "label_fr": "Whitney Point"
            },
            {
                "value": 17077,
                "label": "Whitsett",
                "label_ar": "ويتسيت",
                "label_fr": "Whitsett"
            },
            {
                "value": 17078,
                "label": "Whittemore",
                "label_ar": "ويتيمور",
                "label_fr": "Whittemore"
            },
            {
                "value": 17079,
                "label": "Whittier",
                "label_ar": "ويتير",
                "label_fr": "Whittier"
            },
            {
                "value": 17080,
                "label": "Whittington",
                "label_ar": "ويتينغتون",
                "label_fr": "Whittington"
            },
            {
                "value": 17081,
                "label": "Whitwell",
                "label_ar": "ويتويل",
                "label_fr": "Whitwell"
            },
            {
                "value": 17082,
                "label": "Wibaux",
                "label_ar": "Wibaux",
                "label_fr": "Wibaux"
            },
            {
                "value": 17083,
                "label": "Wichita",
                "label_ar": "ويتشيتا",
                "label_fr": "Wichita"
            },
            {
                "value": 17084,
                "label": "Wichita Falls",
                "label_ar": "ويتشيتا فولز",
                "label_fr": "Wichita Falls"
            },
            {
                "value": 17085,
                "label": "Wickenburg",
                "label_ar": "ويكينبورغ",
                "label_fr": "Wickenburg"
            },
            {
                "value": 17086,
                "label": "Wickes",
                "label_ar": "ويكس",
                "label_fr": "Mèches"
            },
            {
                "value": 17087,
                "label": "Wickliffe",
                "label_ar": "ويكليف",
                "label_fr": "Wickliffe"
            },
            {
                "value": 17088,
                "label": "Wiconisco",
                "label_ar": "Wiconisco",
                "label_fr": "Wiconisco"
            },
            {
                "value": 17089,
                "label": "Widener",
                "label_ar": "متوسع",
                "label_fr": "Élargisseur"
            },
            {
                "value": 17090,
                "label": "Wiergate",
                "label_ar": "ويرجيت",
                "label_fr": "Wiergate"
            },
            {
                "value": 17091,
                "label": "Wiggins",
                "label_ar": "يغينز",
                "label_fr": "Wiggins"
            },
            {
                "value": 17092,
                "label": "Wilber",
                "label_ar": "ويلبر",
                "label_fr": "Wilber"
            },
            {
                "value": 17093,
                "label": "Wilberforce",
                "label_ar": "ويلبرفورس",
                "label_fr": "Wilberforce"
            },
            {
                "value": 17094,
                "label": "Wilbraham",
                "label_ar": "ويلبراهام",
                "label_fr": "Wilbraham"
            },
            {
                "value": 17095,
                "label": "Wilbur",
                "label_ar": "ويلبر",
                "label_fr": "Wilbur"
            },
            {
                "value": 17096,
                "label": "Wilburton",
                "label_ar": "ويلبرتون",
                "label_fr": "Wilburton"
            },
            {
                "value": 17097,
                "label": "Wilcox",
                "label_ar": "ويلكوكس",
                "label_fr": "Wilcox"
            },
            {
                "value": 17098,
                "label": "Wild Rose",
                "label_ar": "زهرة برية",
                "label_fr": "Rose sauvage"
            },
            {
                "value": 17099,
                "label": "Wilder",
                "label_ar": "وايلدر",
                "label_fr": "Wilder"
            },
            {
                "value": 17100,
                "label": "Wilderville",
                "label_ar": "ويلدرفيل",
                "label_fr": "Wilderville"
            },
            {
                "value": 17101,
                "label": "Wildomar",
                "label_ar": "ويلدومار",
                "label_fr": "Wildomar"
            },
            {
                "value": 17102,
                "label": "Wildorado",
                "label_ar": "ويلدورادو",
                "label_fr": "Wildorado"
            },
            {
                "value": 17103,
                "label": "Wildwood",
                "label_ar": "خشب البرية",
                "label_fr": "Forêt sauvage"
            },
            {
                "value": 17104,
                "label": "Wiley",
                "label_ar": "وايلي",
                "label_fr": "Wiley"
            },
            {
                "value": 17105,
                "label": "Wilkes Barre",
                "label_ar": "ويلكس بار",
                "label_fr": "Wilkes Barre"
            },
            {
                "value": 17106,
                "label": "Wilkesboro",
                "label_ar": "ويلكسبورو",
                "label_fr": "Wilkesboro"
            },
            {
                "value": 17107,
                "label": "Wilkinson",
                "label_ar": "ويلكينسون",
                "label_fr": "Wilkinson"
            },
            {
                "value": 17108,
                "label": "Willacoochee",
                "label_ar": "Willacoochee",
                "label_fr": "Willacoochee"
            },
            {
                "value": 17109,
                "label": "Willamina",
                "label_ar": "ويلامينا",
                "label_fr": "Willamina"
            },
            {
                "value": 17110,
                "label": "Willapa",
                "label_ar": "ويلابا",
                "label_fr": "Willapa"
            },
            {
                "value": 17111,
                "label": "Willard",
                "label_ar": "ويلارد",
                "label_fr": "Willard"
            },
            {
                "value": 17112,
                "label": "Willcox",
                "label_ar": "ويلكوكس",
                "label_fr": "Willcox"
            },
            {
                "value": 17113,
                "label": "Willet",
                "label_ar": "ويليت",
                "label_fr": "Willet"
            },
            {
                "value": 17114,
                "label": "Williams",
                "label_ar": "وليامز",
                "label_fr": "Williams"
            },
            {
                "value": 17115,
                "label": "Williams Bay",
                "label_ar": "ويليامز باي",
                "label_fr": "Williams Bay"
            },
            {
                "value": 17116,
                "label": "Williamsburg",
                "label_ar": "وليامزبيرج",
                "label_fr": "Williamsburg"
            },
            {
                "value": 17117,
                "label": "Williamsfield",
                "label_ar": "ويليامزفيلد",
                "label_fr": "Williamsfield"
            },
            {
                "value": 17118,
                "label": "Williamson",
                "label_ar": "وليامسون",
                "label_fr": "Williamson"
            },
            {
                "value": 17119,
                "label": "Williamsport",
                "label_ar": "ويليامسبورت",
                "label_fr": "Williamsport"
            },
            {
                "value": 17120,
                "label": "Williamston",
                "label_ar": "وليامستون",
                "label_fr": "Williamston"
            },
            {
                "value": 17121,
                "label": "Williamstown",
                "label_ar": "وليامزتاون",
                "label_fr": "Williamstown"
            },
            {
                "value": 17122,
                "label": "Williamsville",
                "label_ar": "ويليامزفيل",
                "label_fr": "Williamsville"
            },
            {
                "value": 17123,
                "label": "Willimantic",
                "label_ar": "ويليمانتيك",
                "label_fr": "Willimantic"
            },
            {
                "value": 17124,
                "label": "Willingboro",
                "label_ar": "ويلينجبورو",
                "label_fr": "Willingboro"
            },
            {
                "value": 17125,
                "label": "Willis",
                "label_ar": "ويليس",
                "label_fr": "Willis"
            },
            {
                "value": 17126,
                "label": "Willis Wharf",
                "label_ar": "ويليس وارف",
                "label_fr": "Willis Wharf"
            },
            {
                "value": 17127,
                "label": "Willisburg",
                "label_ar": "ويليسبرغ",
                "label_fr": "Willisburg"
            },
            {
                "value": 17128,
                "label": "Williston",
                "label_ar": "ويليستون",
                "label_fr": "Williston"
            },
            {
                "value": 17129,
                "label": "Williston Park",
                "label_ar": "ويليستون بارك",
                "label_fr": "Parc Williston"
            },
            {
                "value": 17130,
                "label": "Willisville",
                "label_ar": "ويليسفيل",
                "label_fr": "Willisville"
            },
            {
                "value": 17131,
                "label": "Willits",
                "label_ar": "ويليتس",
                "label_fr": "Willits"
            },
            {
                "value": 17132,
                "label": "Willmar",
                "label_ar": "ويلمار",
                "label_fr": "Willmar"
            },
            {
                "value": 17133,
                "label": "Willoughby",
                "label_ar": "ويلوبي",
                "label_fr": "Willoughby"
            },
            {
                "value": 17134,
                "label": "Willow Creek",
                "label_ar": "صفصاف الخور",
                "label_fr": "Willow Creek"
            },
            {
                "value": 17135,
                "label": "Willow Grove",
                "label_ar": "ويلو جروف",
                "label_fr": "Willow Grove"
            },
            {
                "value": 17136,
                "label": "Willow Hill",
                "label_ar": "ويلو هيل",
                "label_fr": "Willow Hill"
            },
            {
                "value": 17137,
                "label": "Willow Lake",
                "label_ar": "بحيرة الصفصاف",
                "label_fr": "Willow Lake"
            },
            {
                "value": 17138,
                "label": "Willow River",
                "label_ar": "نهر الصفصاف",
                "label_fr": "Willow River"
            },
            {
                "value": 17139,
                "label": "Willow Springs",
                "label_ar": "الصفصاف الينابيع",
                "label_fr": "Willow Springs"
            },
            {
                "value": 17140,
                "label": "Willow Street",
                "label_ar": "شارع الصفصاف",
                "label_fr": "Willow Street"
            },
            {
                "value": 17141,
                "label": "Willowbrook",
                "label_ar": "ويلوبروك",
                "label_fr": "Willowbrook"
            },
            {
                "value": 17142,
                "label": "Willows",
                "label_ar": "الصفصاف",
                "label_fr": "Saules"
            },
            {
                "value": 17143,
                "label": "Wills Point",
                "label_ar": "ويلز بوينت",
                "label_fr": "Wills Point"
            },
            {
                "value": 17144,
                "label": "Willsboro",
                "label_ar": "ويلسبورو",
                "label_fr": "Willsboro"
            },
            {
                "value": 17145,
                "label": "Willseyville",
                "label_ar": "ويلسيفيل",
                "label_fr": "Willseyville"
            },
            {
                "value": 17146,
                "label": "Willshire",
                "label_ar": "ويلشاير",
                "label_fr": "Willshire"
            },
            {
                "value": 17147,
                "label": "Wilmar",
                "label_ar": "ويلمار",
                "label_fr": "Wilmar"
            },
            {
                "value": 17148,
                "label": "Wilmer",
                "label_ar": "ويلمر",
                "label_fr": "Wilmer"
            },
            {
                "value": 17149,
                "label": "Wilmerding",
                "label_ar": "ويلمردينج",
                "label_fr": "Wilmerding"
            },
            {
                "value": 17150,
                "label": "Wilmette",
                "label_ar": "ويلميت",
                "label_fr": "Wilmette"
            },
            {
                "value": 17151,
                "label": "Wilmington",
                "label_ar": "ويلمنجتون",
                "label_fr": "Wilmington"
            },
            {
                "value": 17152,
                "label": "Wilmont",
                "label_ar": "ويلمونت",
                "label_fr": "Wilmont"
            },
            {
                "value": 17153,
                "label": "Wilmore",
                "label_ar": "ويلمور",
                "label_fr": "Wilmore"
            },
            {
                "value": 17154,
                "label": "Wilmot",
                "label_ar": "ويلموت",
                "label_fr": "Wilmot"
            },
            {
                "value": 17155,
                "label": "Wilsall",
                "label_ar": "ويلسال",
                "label_fr": "Wilsall"
            },
            {
                "value": 17156,
                "label": "Wilsey",
                "label_ar": "ويلسي",
                "label_fr": "Wilsey"
            },
            {
                "value": 17157,
                "label": "Wilseyville",
                "label_ar": "ويلسيفيل",
                "label_fr": "Wilseyville"
            },
            {
                "value": 17158,
                "label": "Wilson",
                "label_ar": "ويلسون",
                "label_fr": "Wilson"
            },
            {
                "value": 17159,
                "label": "Wilsonville",
                "label_ar": "ويلسونفيل",
                "label_fr": "Wilsonville"
            },
            {
                "value": 17160,
                "label": "Wilton",
                "label_ar": "ويلتون",
                "label_fr": "Wilton"
            },
            {
                "value": 17161,
                "label": "Wilton Manors",
                "label_ar": "ويلتون مانورز",
                "label_fr": "Manoirs Wilton"
            },
            {
                "value": 17162,
                "label": "Wimauma",
                "label_ar": "Wimauma",
                "label_fr": "Wimauma"
            },
            {
                "value": 17163,
                "label": "Wimberley",
                "label_ar": "ويمبرلي",
                "label_fr": "Wimberley"
            },
            {
                "value": 17164,
                "label": "Wimbledon",
                "label_ar": "ويمبلدون",
                "label_fr": "Wimbledon"
            },
            {
                "value": 17165,
                "label": "Winamac",
                "label_ar": "ويناماك",
                "label_fr": "Winamac"
            },
            {
                "value": 17166,
                "label": "Winchendon",
                "label_ar": "وينتشندون",
                "label_fr": "Winchendon"
            },
            {
                "value": 17167,
                "label": "Winchester",
                "label_ar": "وينشستر",
                "label_fr": "Winchester"
            },
            {
                "value": 17168,
                "label": "Winchester Center",
                "label_ar": "مركز وينشستر",
                "label_fr": "Centre Winchester"
            },
            {
                "value": 17169,
                "label": "Wind Gap",
                "label_ar": "فجوة الرياح",
                "label_fr": "Espace vent"
            },
            {
                "value": 17170,
                "label": "Wind Ridge",
                "label_ar": "ويند ريدج",
                "label_fr": "Wind Ridge"
            },
            {
                "value": 17171,
                "label": "Windber",
                "label_ar": "ويندبر",
                "label_fr": "Windber"
            },
            {
                "value": 17172,
                "label": "Winder",
                "label_ar": "اللفاف",
                "label_fr": "Remontoir"
            },
            {
                "value": 17173,
                "label": "Windermere",
                "label_ar": "ويندرمير",
                "label_fr": "Windermere"
            },
            {
                "value": 17174,
                "label": "Windfall",
                "label_ar": "المفاجأة",
                "label_fr": "Aubaine"
            },
            {
                "value": 17175,
                "label": "Windham",
                "label_ar": "ويندهام",
                "label_fr": "Windham"
            },
            {
                "value": 17176,
                "label": "Windham Center",
                "label_ar": "مركز ويندهام",
                "label_fr": "Centre de Windham"
            },
            {
                "value": 17177,
                "label": "Windom",
                "label_ar": "ويندوم",
                "label_fr": "Windom"
            },
            {
                "value": 17178,
                "label": "Window Rock",
                "label_ar": "نافذة الصخرة",
                "label_fr": "Fenêtre Rock"
            },
            {
                "value": 17179,
                "label": "Windsor",
                "label_ar": "وندسور",
                "label_fr": "Windsor"
            },
            {
                "value": 17180,
                "label": "Windsor Heights",
                "label_ar": "وندسور هايتس",
                "label_fr": "Windsor Heights"
            },
            {
                "value": 17181,
                "label": "Windsor Locks",
                "label_ar": "أقفال وندسور",
                "label_fr": "Écluses de Windsor"
            },
            {
                "value": 17182,
                "label": "Windsor Mill",
                "label_ar": "مطحنة وندسور",
                "label_fr": "Moulin de Windsor"
            },
            {
                "value": 17183,
                "label": "Windthorst",
                "label_ar": "ويندثورست",
                "label_fr": "Windthorst"
            },
            {
                "value": 17184,
                "label": "Winesburg",
                "label_ar": "وينسبورغ",
                "label_fr": "Winesburg"
            },
            {
                "value": 17185,
                "label": "Winfield",
                "label_ar": "وينفيلد",
                "label_fr": "Winfield"
            },
            {
                "value": 17186,
                "label": "Wing",
                "label_ar": "جناح",
                "label_fr": "Aile"
            },
            {
                "value": 17187,
                "label": "Wingate",
                "label_ar": "وينجيت",
                "label_fr": "Wingate"
            },
            {
                "value": 17188,
                "label": "Wingdale",
                "label_ar": "وينجدال",
                "label_fr": "Wingdale"
            },
            {
                "value": 17189,
                "label": "Winger",
                "label_ar": "الجناح",
                "label_fr": "Ailier"
            },
            {
                "value": 17190,
                "label": "Wingina",
                "label_ar": "وينجينا",
                "label_fr": "Wingina"
            },
            {
                "value": 17191,
                "label": "Wingo",
                "label_ar": "وينغو",
                "label_fr": "Wingo"
            },
            {
                "value": 17192,
                "label": "Winifrede",
                "label_ar": "وينيفريدي",
                "label_fr": "Winifrede"
            },
            {
                "value": 17193,
                "label": "Wink",
                "label_ar": "غمزة",
                "label_fr": "Clin d'œil"
            },
            {
                "value": 17194,
                "label": "Winlock",
                "label_ar": "وينلوك",
                "label_fr": "Winlock"
            },
            {
                "value": 17195,
                "label": "Winnebago",
                "label_ar": "وينيباغو",
                "label_fr": "Winnebago"
            },
            {
                "value": 17196,
                "label": "Winneconne",
                "label_ar": "وينكوني",
                "label_fr": "Winneconne"
            },
            {
                "value": 17197,
                "label": "Winnemucca",
                "label_ar": "وينيموكا",
                "label_fr": "Winnemucca"
            },
            {
                "value": 17198,
                "label": "Winner",
                "label_ar": "الفائز",
                "label_fr": "Gagnant"
            },
            {
                "value": 17199,
                "label": "Winnetka",
                "label_ar": "ويننيتكا",
                "label_fr": "Winnetka"
            },
            {
                "value": 17200,
                "label": "Winnett",
                "label_ar": "وينيت",
                "label_fr": "Winnett"
            },
            {
                "value": 17201,
                "label": "Winnfield",
                "label_ar": "وينفيلد",
                "label_fr": "Winnfield"
            },
            {
                "value": 17202,
                "label": "Winnie",
                "label_ar": "ويني",
                "label_fr": "Winnie"
            },
            {
                "value": 17203,
                "label": "Winnsboro",
                "label_ar": "وينزبورو",
                "label_fr": "Winnsboro"
            },
            {
                "value": 17204,
                "label": "Winona",
                "label_ar": "وينونا",
                "label_fr": "Winona"
            },
            {
                "value": 17205,
                "label": "Winona Lake",
                "label_ar": "بحيرة وينونا",
                "label_fr": "Lac Winona"
            },
            {
                "value": 17206,
                "label": "Winooski",
                "label_ar": "وينوسكي",
                "label_fr": "Winooski"
            },
            {
                "value": 17207,
                "label": "Winside",
                "label_ar": "وينسايد",
                "label_fr": "Winside"
            },
            {
                "value": 17208,
                "label": "Winslow",
                "label_ar": "وينسلو",
                "label_fr": "Winslow"
            },
            {
                "value": 17209,
                "label": "Winslow Township",
                "label_ar": "بلدة وينسلو",
                "label_fr": "Canton de Winslow"
            },
            {
                "value": 17210,
                "label": "Winsted",
                "label_ar": "وينستيد",
                "label_fr": "Winsted"
            },
            {
                "value": 17211,
                "label": "Winston",
                "label_ar": "وينستون",
                "label_fr": "Winston"
            },
            {
                "value": 17212,
                "label": "Winston-Salem",
                "label_ar": "وينستون سالم",
                "label_fr": "Winston-Salem"
            },
            {
                "value": 17213,
                "label": "Winter",
                "label_ar": "شتاء",
                "label_fr": "Hiver"
            },
            {
                "value": 17214,
                "label": "Winter Garden",
                "label_ar": "حديقة الشتاء",
                "label_fr": "Jardin d'hiver"
            },
            {
                "value": 17215,
                "label": "Winter Harbor",
                "label_ar": "وينتر هاربور",
                "label_fr": "Port d'hiver"
            },
            {
                "value": 17216,
                "label": "Winter Haven",
                "label_ar": "وينتر هيفن",
                "label_fr": "Havre d'hiver"
            },
            {
                "value": 17217,
                "label": "Winter Park",
                "label_ar": "وينتر بارك",
                "label_fr": "Parc d'hiver"
            },
            {
                "value": 17218,
                "label": "Winter Springs",
                "label_ar": "وينتر سبرينغز",
                "label_fr": "Winter Springs"
            },
            {
                "value": 17219,
                "label": "Winterhaven",
                "label_ar": "وينترهافن",
                "label_fr": "Winterhaven"
            },
            {
                "value": 17220,
                "label": "Winterport",
                "label_ar": "وينتربورت",
                "label_fr": "Winterport"
            },
            {
                "value": 17221,
                "label": "Winters",
                "label_ar": "الشتاء",
                "label_fr": "Les hivers"
            },
            {
                "value": 17222,
                "label": "Winterset",
                "label_ar": "وينترست",
                "label_fr": "Winterset"
            },
            {
                "value": 17223,
                "label": "Winterthur",
                "label_ar": "فينترتور",
                "label_fr": "Winterthour"
            },
            {
                "value": 17224,
                "label": "Winterville",
                "label_ar": "وينترفيل",
                "label_fr": "Winterville"
            },
            {
                "value": 17225,
                "label": "Winthrop",
                "label_ar": "وينثروب",
                "label_fr": "Winthrop"
            },
            {
                "value": 17226,
                "label": "Winthrop Harbor",
                "label_ar": "وينثروب هاربور",
                "label_fr": "Port de Winthrop"
            },
            {
                "value": 17227,
                "label": "Winton",
                "label_ar": "وينتون",
                "label_fr": "Winton"
            },
            {
                "value": 17228,
                "label": "Wiota",
                "label_ar": "ويوتا",
                "label_fr": "Wiota"
            },
            {
                "value": 17229,
                "label": "Wirtz",
                "label_ar": "فيرتس",
                "label_fr": "Wirtz"
            },
            {
                "value": 17230,
                "label": "Wiscasset",
                "label_ar": "ويسكاست",
                "label_fr": "Wiscasset"
            },
            {
                "value": 17231,
                "label": "Wisconsin Dells",
                "label_ar": "ويسكونسن ديلز",
                "label_fr": "Wisconsin Dells"
            },
            {
                "value": 17232,
                "label": "Wisconsin Rapids",
                "label_ar": "ويسكونسن رابيدز",
                "label_fr": "Rapides du Wisconsin"
            },
            {
                "value": 17233,
                "label": "Wisdom",
                "label_ar": "حكمة",
                "label_fr": "Sagesse"
            },
            {
                "value": 17234,
                "label": "Wise",
                "label_ar": "حكيم",
                "label_fr": "Sage"
            },
            {
                "value": 17235,
                "label": "Wishek",
                "label_ar": "ويشك",
                "label_fr": "Wishek"
            },
            {
                "value": 17236,
                "label": "Wisner",
                "label_ar": "ويسنر",
                "label_fr": "Wisner"
            },
            {
                "value": 17237,
                "label": "Wister",
                "label_ar": "ويستر",
                "label_fr": "Wister"
            },
            {
                "value": 17238,
                "label": "Withams",
                "label_ar": "ويثامز",
                "label_fr": "Withams"
            },
            {
                "value": 17239,
                "label": "Withee",
                "label_ar": "ويذ",
                "label_fr": "Withee"
            },
            {
                "value": 17240,
                "label": "Witt",
                "label_ar": "ويت",
                "label_fr": "Witt"
            },
            {
                "value": 17241,
                "label": "Witten",
                "label_ar": "ويتن",
                "label_fr": "Witten"
            },
            {
                "value": 17242,
                "label": "Wittenberg",
                "label_ar": "ويتنبرغ",
                "label_fr": "Wittenberg"
            },
            {
                "value": 17243,
                "label": "Wittensville",
                "label_ar": "ويتنسفيل",
                "label_fr": "Wittensville"
            },
            {
                "value": 17244,
                "label": "Wittmann",
                "label_ar": "ويتمان",
                "label_fr": "Wittmann"
            },
            {
                "value": 17245,
                "label": "Wixom",
                "label_ar": "ويكسوم",
                "label_fr": "Wixom"
            },
            {
                "value": 17246,
                "label": "Woburn",
                "label_ar": "وبرن",
                "label_fr": "Woburn"
            },
            {
                "value": 17247,
                "label": "Woden",
                "label_ar": "ودين",
                "label_fr": "Woden"
            },
            {
                "value": 17248,
                "label": "Wofford Heights",
                "label_ar": "مرتفعات ووفورد",
                "label_fr": "Wofford Heights"
            },
            {
                "value": 17249,
                "label": "Wolbach",
                "label_ar": "وولباخ",
                "label_fr": "Wolbach"
            },
            {
                "value": 17250,
                "label": "Wolcott",
                "label_ar": "وولكوت",
                "label_fr": "Wolcott"
            },
            {
                "value": 17251,
                "label": "Wolcottville",
                "label_ar": "وولكوتفيل",
                "label_fr": "Wolcottville"
            },
            {
                "value": 17252,
                "label": "Wolf Point",
                "label_ar": "وولف بوينت",
                "label_fr": "Wolf Point"
            },
            {
                "value": 17253,
                "label": "Wolfe City",
                "label_ar": "وولف سيتي",
                "label_fr": "Wolfe City"
            },
            {
                "value": 17254,
                "label": "Wolfeboro",
                "label_ar": "ولفيبورو",
                "label_fr": "Wolfeboro"
            },
            {
                "value": 17255,
                "label": "Wolfeboro Falls",
                "label_ar": "شلالات ولفيبورو",
                "label_fr": "Wolfeboro Falls"
            },
            {
                "value": 17256,
                "label": "Wolfforth",
                "label_ar": "وولفورث",
                "label_fr": "Wolfforth"
            },
            {
                "value": 17257,
                "label": "Wolford",
                "label_ar": "ولفورد",
                "label_fr": "Wolford"
            },
            {
                "value": 17258,
                "label": "Wolverton",
                "label_ar": "ولفرتون",
                "label_fr": "Wolverton"
            },
            {
                "value": 17259,
                "label": "Womelsdorf",
                "label_ar": "Womelsdorf",
                "label_fr": "Womelsdorf"
            },
            {
                "value": 17260,
                "label": "Wonder Lake",
                "label_ar": "وندر ليك",
                "label_fr": "Wonder Lake"
            },
            {
                "value": 17261,
                "label": "Wonewoc",
                "label_ar": "وونووك",
                "label_fr": "Wonewoc"
            },
            {
                "value": 17262,
                "label": "Wood Dale",
                "label_ar": "وود دايل",
                "label_fr": "Wood Dale"
            },
            {
                "value": 17263,
                "label": "Wood Lake",
                "label_ar": "وود ليك",
                "label_fr": "Wood Lake"
            },
            {
                "value": 17264,
                "label": "Wood River",
                "label_ar": "نهر وود",
                "label_fr": "Wood River"
            },
            {
                "value": 17265,
                "label": "Wood River Junction",
                "label_ar": "وود ريفر جانكشن",
                "label_fr": "Jonction de Wood River"
            },
            {
                "value": 17266,
                "label": "Wood-Ridge",
                "label_ar": "وود ريدج",
                "label_fr": "Wood-Ridge"
            },
            {
                "value": 17267,
                "label": "Woodacre",
                "label_ar": "الحطب",
                "label_fr": "Woodacre"
            },
            {
                "value": 17268,
                "label": "Woodberry Forest",
                "label_ar": "وودبيري فورست",
                "label_fr": "Woodberry Forest"
            },
            {
                "value": 17269,
                "label": "Woodbine",
                "label_ar": "وودباين",
                "label_fr": "Chèvrefeuille"
            },
            {
                "value": 17270,
                "label": "Woodbourne",
                "label_ar": "وودبورن",
                "label_fr": "Woodbourne"
            },
            {
                "value": 17271,
                "label": "Woodbridge",
                "label_ar": "وودبريدج",
                "label_fr": "Woodbridge"
            },
            {
                "value": 17272,
                "label": "Woodburn",
                "label_ar": "وودبيرن",
                "label_fr": "Woodburn"
            },
            {
                "value": 17273,
                "label": "Woodbury",
                "label_ar": "وودبري",
                "label_fr": "Woodbury"
            },
            {
                "value": 17274,
                "label": "Woodbury Heights",
                "label_ar": "وودبري هايتس",
                "label_fr": "Woodbury Heights"
            },
            {
                "value": 17275,
                "label": "Woodcliff Lake",
                "label_ar": "بحيرة وودكليف",
                "label_fr": "Lac Woodcliff"
            },
            {
                "value": 17276,
                "label": "Woodford",
                "label_ar": "وودفورد",
                "label_fr": "Woodford"
            },
            {
                "value": 17277,
                "label": "Woodgate",
                "label_ar": "وودجيت",
                "label_fr": "Woodgate"
            },
            {
                "value": 17278,
                "label": "Woodhaven",
                "label_ar": "وودهافن",
                "label_fr": "Woodhaven"
            },
            {
                "value": 17279,
                "label": "Woodhull",
                "label_ar": "وودهول",
                "label_fr": "Woodhull"
            },
            {
                "value": 17280,
                "label": "Woodinville",
                "label_ar": "ودينفيل",
                "label_fr": "Woodinville"
            },
            {
                "value": 17281,
                "label": "Woodlake",
                "label_ar": "وودليك",
                "label_fr": "Woodlake"
            },
            {
                "value": 17282,
                "label": "Woodland",
                "label_ar": "وودلاند",
                "label_fr": "Des bois"
            },
            {
                "value": 17283,
                "label": "Woodland Hills",
                "label_ar": "وودلاند هيلز",
                "label_fr": "Woodland Hills"
            },
            {
                "value": 17284,
                "label": "Woodland Mills",
                "label_ar": "وودلاند ميلز",
                "label_fr": "Woodland Mills"
            },
            {
                "value": 17285,
                "label": "Woodland Park",
                "label_ar": "وودلاند بارك",
                "label_fr": "Woodland Park"
            },
            {
                "value": 17286,
                "label": "Woodlawn",
                "label_ar": "وودلون",
                "label_fr": "Woodlawn"
            },
            {
                "value": 17287,
                "label": "Woodleaf",
                "label_ar": "ورق خشب",
                "label_fr": "Feuille de bois"
            },
            {
                "value": 17288,
                "label": "Woodlyn",
                "label_ar": "وودلين",
                "label_fr": "Woodlyn"
            },
            {
                "value": 17289,
                "label": "Woodmere",
                "label_ar": "وودمير",
                "label_fr": "Woodmere"
            },
            {
                "value": 17290,
                "label": "Woodridge",
                "label_ar": "وودريدج",
                "label_fr": "Woodridge"
            },
            {
                "value": 17291,
                "label": "Woodruff",
                "label_ar": "وودروف",
                "label_fr": "Woodruff"
            },
            {
                "value": 17292,
                "label": "Woods Cross",
                "label_ar": "وودز كروس",
                "label_fr": "Croix de bois"
            },
            {
                "value": 17293,
                "label": "Woods Hole",
                "label_ar": "وودز هول",
                "label_fr": "Trou de bois"
            },
            {
                "value": 17294,
                "label": "Woodsboro",
                "label_ar": "وودسبورو",
                "label_fr": "Woodsboro"
            },
            {
                "value": 17295,
                "label": "Woodsfield",
                "label_ar": "وودزفيلد",
                "label_fr": "Woodsfield"
            },
            {
                "value": 17296,
                "label": "Woodside",
                "label_ar": "وودسايد",
                "label_fr": "Woodside"
            },
            {
                "value": 17297,
                "label": "Woodson",
                "label_ar": "وودسون",
                "label_fr": "Woodson"
            },
            {
                "value": 17298,
                "label": "Woodstock",
                "label_ar": "وودستوك",
                "label_fr": "Woodstock"
            },
            {
                "value": 17299,
                "label": "Woodstown",
                "label_ar": "وودستاون",
                "label_fr": "Woodstown"
            },
            {
                "value": 17300,
                "label": "Woodsville",
                "label_ar": "وودزفيل",
                "label_fr": "Woodsville"
            },
            {
                "value": 17301,
                "label": "Woodville",
                "label_ar": "وودفيل",
                "label_fr": "Woodville"
            },
            {
                "value": 17302,
                "label": "Woodward",
                "label_ar": "وودوارد",
                "label_fr": "Woodward"
            },
            {
                "value": 17303,
                "label": "Woodway",
                "label_ar": "وودواي",
                "label_fr": "Woodway"
            },
            {
                "value": 17304,
                "label": "Woodworth",
                "label_ar": "وودوورث",
                "label_fr": "Woodworth"
            },
            {
                "value": 17305,
                "label": "Woolstock",
                "label_ar": "وولستوك",
                "label_fr": "Woolstock"
            },
            {
                "value": 17306,
                "label": "Woolwich",
                "label_ar": "وولويتش",
                "label_fr": "Woolwich"
            },
            {
                "value": 17307,
                "label": "Woonsocket",
                "label_ar": "وونسوكيت",
                "label_fr": "Woonsocket"
            },
            {
                "value": 17308,
                "label": "Wooster",
                "label_ar": "ووستر",
                "label_fr": "Wooster"
            },
            {
                "value": 17309,
                "label": "Wooton",
                "label_ar": "ووتون",
                "label_fr": "Wooton"
            },
            {
                "value": 17310,
                "label": "Worcester",
                "label_ar": "ورسستر",
                "label_fr": "Worcester"
            },
            {
                "value": 17311,
                "label": "Worden",
                "label_ar": "ووردن",
                "label_fr": "Worden"
            },
            {
                "value": 17312,
                "label": "Worland",
                "label_ar": "وورلاند",
                "label_fr": "Worland"
            },
            {
                "value": 17313,
                "label": "Worley",
                "label_ar": "وورلي",
                "label_fr": "Worley"
            },
            {
                "value": 17314,
                "label": "Worth",
                "label_ar": "يستحق",
                "label_fr": "Vaut"
            },
            {
                "value": 17315,
                "label": "Wortham",
                "label_ar": "وورثام",
                "label_fr": "Wortham"
            },
            {
                "value": 17316,
                "label": "Worthing",
                "label_ar": "ورذينج",
                "label_fr": "Worthing"
            },
            {
                "value": 17317,
                "label": "Worthington",
                "label_ar": "ورثينجتون",
                "label_fr": "Worthington"
            },
            {
                "value": 17318,
                "label": "Worthington Center",
                "label_ar": "مركز ورثينجتون",
                "label_fr": "Centre Worthington"
            },
            {
                "value": 17319,
                "label": "Worthington Springs",
                "label_ar": "ورثينجتون سبرينجز",
                "label_fr": "Worthington Springs"
            },
            {
                "value": 17320,
                "label": "Worthville",
                "label_ar": "ورثفيل",
                "label_fr": "Worthville"
            },
            {
                "value": 17321,
                "label": "Worton",
                "label_ar": "وورتون",
                "label_fr": "Worton"
            },
            {
                "value": 17322,
                "label": "Wray",
                "label_ar": "راي",
                "label_fr": "Wray"
            },
            {
                "value": 17323,
                "label": "Wrens",
                "label_ar": "ينصر",
                "label_fr": "Wrens"
            },
            {
                "value": 17324,
                "label": "Wrenshall",
                "label_ar": "رينشال",
                "label_fr": "Wrenshall"
            },
            {
                "value": 17325,
                "label": "Wrentham",
                "label_ar": "رينثام",
                "label_fr": "Wrentham"
            },
            {
                "value": 17326,
                "label": "Wright",
                "label_ar": "رايت",
                "label_fr": "Wright"
            },
            {
                "value": 17327,
                "label": "Wright City",
                "label_ar": "مدينة رايت",
                "label_fr": "Wright City"
            },
            {
                "value": 17328,
                "label": "Wrightstown",
                "label_ar": "رايتستاون",
                "label_fr": "Wrightstown"
            },
            {
                "value": 17329,
                "label": "Wrightsville",
                "label_ar": "رايتسفيل",
                "label_fr": "Wrightsville"
            },
            {
                "value": 17330,
                "label": "Wrightsville Beach",
                "label_ar": "شاطئ Wrightsville",
                "label_fr": "Plage de Wrightsville"
            },
            {
                "value": 17331,
                "label": "Wrightwood",
                "label_ar": "رايتوود",
                "label_fr": "Wrightwood"
            },
            {
                "value": 17332,
                "label": "Wurtsboro",
                "label_ar": "Wurtsboro",
                "label_fr": "Wurtsboro"
            },
            {
                "value": 17333,
                "label": "Wyaconda",
                "label_ar": "واياكوندا",
                "label_fr": "Wyaconda"
            },
            {
                "value": 17334,
                "label": "Wyalusing",
                "label_ar": "ويلسينغ",
                "label_fr": "Wyalusing"
            },
            {
                "value": 17335,
                "label": "Wyandanch",
                "label_ar": "وياندانش",
                "label_fr": "Wyandanch"
            },
            {
                "value": 17336,
                "label": "Wyandotte",
                "label_ar": "وياندوت",
                "label_fr": "Wyandotte"
            },
            {
                "value": 17337,
                "label": "Wyanet",
                "label_ar": "ويانيت",
                "label_fr": "Wyanet"
            },
            {
                "value": 17338,
                "label": "Wyckoff",
                "label_ar": "ويكوف",
                "label_fr": "Wyckoff"
            },
            {
                "value": 17339,
                "label": "Wyco",
                "label_ar": "ويكو",
                "label_fr": "Wyco"
            },
            {
                "value": 17340,
                "label": "Wye Mills",
                "label_ar": "واي ميلز",
                "label_fr": "Wye Mills"
            },
            {
                "value": 17341,
                "label": "Wykoff",
                "label_ar": "ويكوف",
                "label_fr": "Wykoff"
            },
            {
                "value": 17342,
                "label": "Wylie",
                "label_ar": "ويلي",
                "label_fr": "Wylie"
            },
            {
                "value": 17343,
                "label": "Wylliesburg",
                "label_ar": "ويليسبرغ",
                "label_fr": "Wylliesburg"
            },
            {
                "value": 17344,
                "label": "Wymore",
                "label_ar": "ويمور",
                "label_fr": "Wymore"
            },
            {
                "value": 17345,
                "label": "Wynantskill",
                "label_ar": "وينانتسكيل",
                "label_fr": "Wynantskill"
            },
            {
                "value": 17346,
                "label": "Wyncote",
                "label_ar": "وينكوت",
                "label_fr": "Wyncote"
            },
            {
                "value": 17347,
                "label": "Wyndmere",
                "label_ar": "ويندمير",
                "label_fr": "Wyndmere"
            },
            {
                "value": 17348,
                "label": "Wynne",
                "label_ar": "وين",
                "label_fr": "Wynne"
            },
            {
                "value": 17349,
                "label": "Wynnewood",
                "label_ar": "وينوود",
                "label_fr": "Wynnewood"
            },
            {
                "value": 17350,
                "label": "Wynona",
                "label_ar": "وينونا",
                "label_fr": "Wynona"
            },
            {
                "value": 17351,
                "label": "Wynot",
                "label_ar": "وينوت",
                "label_fr": "Wynot"
            },
            {
                "value": 17352,
                "label": "Wyocena",
                "label_ar": "ويوسينا",
                "label_fr": "Wyocena"
            },
            {
                "value": 17353,
                "label": "Wyoming",
                "label_ar": "وايومنغ",
                "label_fr": "Wyoming"
            },
            {
                "value": 17354,
                "label": "Wysox",
                "label_ar": "ويسوكس",
                "label_fr": "Wysox"
            },
            {
                "value": 17355,
                "label": "Wytheville",
                "label_ar": "ويثفيل",
                "label_fr": "Wytheville"
            },
            {
                "value": 17356,
                "label": "Xenia",
                "label_ar": "زينيا",
                "label_fr": "Xenia"
            },
            {
                "value": 17357,
                "label": "Yachats",
                "label_ar": "ياتشاتس",
                "label_fr": "Yachats"
            },
            {
                "value": 17358,
                "label": "Yacolt",
                "label_ar": "ياكولت",
                "label_fr": "Yacolt"
            },
            {
                "value": 17359,
                "label": "Yadkinville",
                "label_ar": "يادكينفيل",
                "label_fr": "Yadkinville"
            },
            {
                "value": 17360,
                "label": "Yakima",
                "label_ar": "ياكيما",
                "label_fr": "Yakima"
            },
            {
                "value": 17361,
                "label": "Yalaha",
                "label_ar": "يلاها",
                "label_fr": "Yalaha"
            },
            {
                "value": 17362,
                "label": "Yale",
                "label_ar": "ييل",
                "label_fr": "Yale"
            },
            {
                "value": 17363,
                "label": "Yamhill",
                "label_ar": "يامهيل",
                "label_fr": "Yamhill"
            },
            {
                "value": 17364,
                "label": "Yampa",
                "label_ar": "يامبا",
                "label_fr": "Yampa"
            },
            {
                "value": 17365,
                "label": "Yanceyville",
                "label_ar": "يانسيفيل",
                "label_fr": "Yanceyville"
            },
            {
                "value": 17366,
                "label": "Yankeetown",
                "label_ar": "يانكي تاون",
                "label_fr": "Yankeetown"
            },
            {
                "value": 17367,
                "label": "Yankton",
                "label_ar": "يانكتون",
                "label_fr": "Yankton"
            },
            {
                "value": 17368,
                "label": "Yantis",
                "label_ar": "يانتيس",
                "label_fr": "Yantis"
            },
            {
                "value": 17369,
                "label": "Yaphank",
                "label_ar": "يافانك",
                "label_fr": "Yaphank"
            },
            {
                "value": 17370,
                "label": "Yarmouth",
                "label_ar": "يارموث",
                "label_fr": "Yarmouth"
            },
            {
                "value": 17371,
                "label": "Yarmouth Port",
                "label_ar": "ميناء يارموث",
                "label_fr": "Port de Yarmouth"
            },
            {
                "value": 17372,
                "label": "Yates Center",
                "label_ar": "مركز ييتس",
                "label_fr": "Centre Yates"
            },
            {
                "value": 17373,
                "label": "Yates City",
                "label_ar": "مدينة ييتس",
                "label_fr": "Yates City"
            },
            {
                "value": 17374,
                "label": "Yatesboro",
                "label_ar": "ياتسبورو",
                "label_fr": "Yatesboro"
            },
            {
                "value": 17375,
                "label": "Yatesville",
                "label_ar": "ييتسفيل",
                "label_fr": "Yatesville"
            },
            {
                "value": 17376,
                "label": "Yazoo City",
                "label_ar": "مدينة يازو",
                "label_fr": "Ville de Yazoo"
            },
            {
                "value": 17377,
                "label": "Yeaddiss",
                "label_ar": "ياديس",
                "label_fr": "Yeaddiss"
            },
            {
                "value": 17378,
                "label": "Yellow Spring",
                "label_ar": "الربيع الأصفر",
                "label_fr": "Printemps jaune"
            },
            {
                "value": 17379,
                "label": "Yellow Springs",
                "label_ar": "الينابيع الصفراء",
                "label_fr": "Yellow Springs"
            },
            {
                "value": 17380,
                "label": "Yellville",
                "label_ar": "يلفيل",
                "label_fr": "Yellville"
            },
            {
                "value": 17381,
                "label": "Yelm",
                "label_ar": "يلم",
                "label_fr": "Yelm"
            },
            {
                "value": 17382,
                "label": "Yemassee",
                "label_ar": "يمسي",
                "label_fr": "Yemassee"
            },
            {
                "value": 17383,
                "label": "Yerington",
                "label_ar": "يرينجتون",
                "label_fr": "Yerington"
            },
            {
                "value": 17384,
                "label": "Yermo",
                "label_ar": "يرمو",
                "label_fr": "Yermo"
            },
            {
                "value": 17385,
                "label": "Yoakum",
                "label_ar": "يواكوم",
                "label_fr": "Yoakum"
            },
            {
                "value": 17386,
                "label": "Yoder",
                "label_ar": "يودر",
                "label_fr": "Yoder"
            },
            {
                "value": 17387,
                "label": "Yolyn",
                "label_ar": "يولين",
                "label_fr": "Yolyn"
            },
            {
                "value": 17388,
                "label": "Yoncalla",
                "label_ar": "يونكالا",
                "label_fr": "Yoncalla"
            },
            {
                "value": 17389,
                "label": "Yonkers",
                "label_ar": "يونكرز",
                "label_fr": "Yonkers"
            },
            {
                "value": 17390,
                "label": "Yorba Linda",
                "label_ar": "يوربا ليندا",
                "label_fr": "Yorba Linda"
            },
            {
                "value": 17391,
                "label": "York",
                "label_ar": "يورك",
                "label_fr": "York"
            },
            {
                "value": 17392,
                "label": "York Beach",
                "label_ar": "يورك بيتش",
                "label_fr": "York Beach"
            },
            {
                "value": 17393,
                "label": "York Corner",
                "label_ar": "ركن يورك",
                "label_fr": "York Corner"
            },
            {
                "value": 17394,
                "label": "York Harbor",
                "label_ar": "يورك هاربور",
                "label_fr": "Port de York"
            },
            {
                "value": 17395,
                "label": "York Haven",
                "label_ar": "يورك هافن",
                "label_fr": "York Haven"
            },
            {
                "value": 17396,
                "label": "York Springs",
                "label_ar": "يورك سبرينغز",
                "label_fr": "York Springs"
            },
            {
                "value": 17397,
                "label": "Yorkshire",
                "label_ar": "يوركشاير",
                "label_fr": "Yorkshire"
            },
            {
                "value": 17398,
                "label": "Yorktown",
                "label_ar": "يوركتاون",
                "label_fr": "Yorktown"
            },
            {
                "value": 17399,
                "label": "Yorktown Heights",
                "label_ar": "يوركتاون هايتس",
                "label_fr": "Yorktown Heights"
            },
            {
                "value": 17400,
                "label": "Yorkville",
                "label_ar": "يوركفيل",
                "label_fr": "Yorkville"
            },
            {
                "value": 17401,
                "label": "Yosemite Valley",
                "label_ar": "وادى يوسمايت",
                "label_fr": "Vallée de Yosemite"
            },
            {
                "value": 17402,
                "label": "Young",
                "label_ar": "شاب",
                "label_fr": "Jeune"
            },
            {
                "value": 17403,
                "label": "Young America",
                "label_ar": "أمريكا الشابة",
                "label_fr": "Jeune Amérique"
            },
            {
                "value": 17404,
                "label": "Young Harris",
                "label_ar": "يونغ هاريس",
                "label_fr": "Jeune Harris"
            },
            {
                "value": 17405,
                "label": "Youngstown",
                "label_ar": "يونجستاون",
                "label_fr": "Youngstown"
            },
            {
                "value": 17406,
                "label": "Youngsville",
                "label_ar": "يونجسفيل",
                "label_fr": "Youngsville"
            },
            {
                "value": 17407,
                "label": "Youngtown",
                "label_ar": "يونغتاون",
                "label_fr": "Youngtown"
            },
            {
                "value": 17408,
                "label": "Youngwood",
                "label_ar": "يونجوود",
                "label_fr": "Youngwood"
            },
            {
                "value": 17409,
                "label": "Yountville",
                "label_ar": "يونتفيل",
                "label_fr": "Yountville"
            },
            {
                "value": 17410,
                "label": "Ypsilanti",
                "label_ar": "يبسيلانتي",
                "label_fr": "Ypsilanti"
            },
            {
                "value": 17411,
                "label": "Yreka",
                "label_ar": "يريكا",
                "label_fr": "Yreka"
            },
            {
                "value": 17412,
                "label": "Yuba City",
                "label_ar": "مدينة يوبا",
                "label_fr": "Ville de Yuba"
            },
            {
                "value": 17413,
                "label": "Yucaipa",
                "label_ar": "يوكايبا",
                "label_fr": "Yucaipa"
            },
            {
                "value": 17414,
                "label": "Yucca",
                "label_ar": "يوكا",
                "label_fr": "Yucca"
            },
            {
                "value": 17415,
                "label": "Yucca Valley",
                "label_ar": "وادي اليوكا",
                "label_fr": "Vallée du Yucca"
            },
            {
                "value": 17416,
                "label": "Yukon",
                "label_ar": "يوكون",
                "label_fr": "Yukon"
            },
            {
                "value": 17417,
                "label": "Yulan",
                "label_ar": "يولان",
                "label_fr": "Yulan"
            },
            {
                "value": 17418,
                "label": "Yulee",
                "label_ar": "يولي",
                "label_fr": "Yulee"
            },
            {
                "value": 17419,
                "label": "Yuma",
                "label_ar": "يوما",
                "label_fr": "Yuma"
            },
            {
                "value": 17420,
                "label": "Yutan",
                "label_ar": "يوتان",
                "label_fr": "Yutan"
            },
            {
                "value": 17421,
                "label": "Zachary",
                "label_ar": "زكاري",
                "label_fr": "Zachary"
            },
            {
                "value": 17422,
                "label": "Zaleski",
                "label_ar": "زاليسكي",
                "label_fr": "Zaleski"
            },
            {
                "value": 17423,
                "label": "Zalma",
                "label_ar": "زلما",
                "label_fr": "Zalma"
            },
            {
                "value": 17424,
                "label": "Zanesfield",
                "label_ar": "زانسفيلد",
                "label_fr": "Zanesfield"
            },
            {
                "value": 17425,
                "label": "Zanesville",
                "label_ar": "زانسفيل",
                "label_fr": "Zanesville"
            },
            {
                "value": 17426,
                "label": "Zapata",
                "label_ar": "زاباتا",
                "label_fr": "Zapata"
            },
            {
                "value": 17427,
                "label": "Zarephath",
                "label_ar": "صرفة",
                "label_fr": "Zarephath"
            },
            {
                "value": 17428,
                "label": "Zavalla",
                "label_ar": "زافالا",
                "label_fr": "Zavalla"
            },
            {
                "value": 17429,
                "label": "Zearing",
                "label_ar": "زيرينج",
                "label_fr": "Zearing"
            },
            {
                "value": 17430,
                "label": "Zebulon",
                "label_ar": "زيبولون",
                "label_fr": "Zebulon"
            },
            {
                "value": 17431,
                "label": "Zeeland",
                "label_ar": "زيلاند",
                "label_fr": "Zélande"
            },
            {
                "value": 17432,
                "label": "Zeigler",
                "label_ar": "زيجلر",
                "label_fr": "Zeigler"
            },
            {
                "value": 17433,
                "label": "Zelienople",
                "label_ar": "زيلينوبل",
                "label_fr": "Zelienople"
            },
            {
                "value": 17434,
                "label": "Zellwood",
                "label_ar": "زيلوود",
                "label_fr": "Zellwood"
            },
            {
                "value": 17435,
                "label": "Zenda",
                "label_ar": "زندا",
                "label_fr": "Zenda"
            },
            {
                "value": 17436,
                "label": "Zephyr",
                "label_ar": "زفير",
                "label_fr": "Zéphyr"
            },
            {
                "value": 17437,
                "label": "Zephyr Cove",
                "label_ar": "زفير كوف",
                "label_fr": "Zephyr Cove"
            },
            {
                "value": 17438,
                "label": "Zephyrhills",
                "label_ar": "زفيرهيلز",
                "label_fr": "Zephyrhills"
            },
            {
                "value": 17439,
                "label": "Zieglersville",
                "label_ar": "زيجلرسفيل",
                "label_fr": "Zieglersville"
            },
            {
                "value": 17440,
                "label": "Zillah",
                "label_ar": "زلة",
                "label_fr": "Zillah"
            },
            {
                "value": 17441,
                "label": "Zimmerman",
                "label_ar": "زيمرمان",
                "label_fr": "Zimmerman"
            },
            {
                "value": 17442,
                "label": "Zion",
                "label_ar": "صهيون",
                "label_fr": "Sion"
            },
            {
                "value": 17443,
                "label": "Zion Grove",
                "label_ar": "صهيون جروف",
                "label_fr": "Zion Grove"
            },
            {
                "value": 17444,
                "label": "Zionhill",
                "label_ar": "زيونهيل",
                "label_fr": "Zionhill"
            },
            {
                "value": 17445,
                "label": "Zionsville",
                "label_ar": "زيونسفيل",
                "label_fr": "Zionsville"
            },
            {
                "value": 17446,
                "label": "Zionville",
                "label_ar": "زيونفيل",
                "label_fr": "Zionville"
            },
            {
                "value": 17447,
                "label": "Zirconia",
                "label_ar": "زركونيا",
                "label_fr": "Zircone"
            },
            {
                "value": 17448,
                "label": "Zolfo Springs",
                "label_ar": "زولفو سبرينغز",
                "label_fr": "Zolfo Springs"
            },
            {
                "value": 17449,
                "label": "Zortman",
                "label_ar": "زورتمان",
                "label_fr": "Zortman"
            },
            {
                "value": 17450,
                "label": "Zullinger",
                "label_ar": "زولينجر",
                "label_fr": "Zullinger"
            },
            {
                "value": 17451,
                "label": "Zumbrota",
                "label_ar": "زومبروتا",
                "label_fr": "Zumbrota"
            },
            {
                "value": 17452,
                "label": "Zuni",
                "label_ar": "زوني",
                "label_fr": "Zuni"
            },
            {
                "value": 17453,
                "label": "Zurich",
                "label_ar": "زيورخ",
                "label_fr": "Zurich"
            },
            {
                "value": 17454,
                "label": "Zwingle",
                "label_ar": "زوينجل",
                "label_fr": "Zwingle"
            }
        ]
    },
    {
        "country": "India",
        "cities": [
            {
                "value": 18375,
                "label": "Teri",
                "label_ar": "تيري",
                "label_fr": "Teri"
            },
            {
                "value": 18783,
                "label": "Chikodi",
                "label_ar": "شيكودي",
                "label_fr": "Chikodi"
            },
            {
                "value": 18836,
                "label": "Ganga",
                "label_ar": "الجانج",
                "label_fr": "Ganga"
            },
            {
                "value": 18216,
                "label": "Sultanpur",
                "label_ar": "سلطانبور",
                "label_fr": "Sultanpur"
            },
            {
                "value": 19020,
                "label": "Gudur",
                "label_ar": "جودور",
                "label_fr": "Gudur"
            },
            {
                "value": 19021,
                "label": "Farakka",
                "label_ar": "فاركة",
                "label_fr": "Farakka"
            },
            {
                "value": 18592,
                "label": "Banswara",
                "label_ar": "بانسوارا",
                "label_fr": "Banswara"
            },
            {
                "value": 18457,
                "label": "Mangalagiri",
                "label_ar": "مانغالاجيري",
                "label_fr": "Mangalagiri"
            },
            {
                "value": 18188,
                "label": "Kalyani",
                "label_ar": "كالياني",
                "label_fr": "Kalyani"
            },
            {
                "value": 18359,
                "label": "Raipur",
                "label_ar": "رايبور",
                "label_fr": "Raipur"
            },
            {
                "value": 18467,
                "label": "Neral",
                "label_ar": "نيرال",
                "label_fr": "Neral"
            },
            {
                "value": 18400,
                "label": "Lamba Harisingh",
                "label_ar": "لامبا هاريسينغ",
                "label_fr": "Lamba Harisingh"
            },
            {
                "value": 18614,
                "label": "Gobichettipalayam",
                "label_ar": "Gobichettipalayam",
                "label_fr": "Gobichettipalayam"
            },
            {
                "value": 18928,
                "label": "Amreli",
                "label_ar": "أمريلي",
                "label_fr": "Amreli"
            },
            {
                "value": 18898,
                "label": "Nagal",
                "label_ar": "ناجال",
                "label_fr": "Nagal"
            },
            {
                "value": 18085,
                "label": "Kakinada",
                "label_ar": "كاكينادا",
                "label_fr": "Kakinada"
            },
            {
                "value": 18251,
                "label": "Ankleshwar",
                "label_ar": "أنكلشوار",
                "label_fr": "Ankleshwar"
            },
            {
                "value": 18334,
                "label": "Lohaghat",
                "label_ar": "لوهاغات",
                "label_fr": "Lohaghat"
            },
            {
                "value": 18569,
                "label": "Ramavaram",
                "label_ar": "رامافارام",
                "label_fr": "Ramavaram"
            },
            {
                "value": 18548,
                "label": "Nayagarh",
                "label_ar": "ناياجاره",
                "label_fr": "Nayagarh"
            },
            {
                "value": 18495,
                "label": "Hosur",
                "label_ar": "هسور",
                "label_fr": "Hosur"
            },
            {
                "value": 18767,
                "label": "Ambejogai",
                "label_ar": "أمبيجوجاي",
                "label_fr": "Ambejogai"
            },
            {
                "value": 18724,
                "label": "Kagal",
                "label_ar": "كاجال",
                "label_fr": "Kagal"
            },
            {
                "value": 18028,
                "label": "Hyderabad",
                "label_ar": "حيدر أباد",
                "label_fr": "Hyderabad"
            },
            {
                "value": 18111,
                "label": "Kolhapur",
                "label_ar": "كولهابور",
                "label_fr": "Kolhapur"
            },
            {
                "value": 18745,
                "label": "Mandvi",
                "label_ar": "ماندفي",
                "label_fr": "Mandvi"
            },
            {
                "value": 18295,
                "label": "Karimnagar",
                "label_ar": "كاريمناغار",
                "label_fr": "Karimnagar"
            },
            {
                "value": 18451,
                "label": "Sahibabad",
                "label_ar": "صاحب أباد",
                "label_fr": "Sahibabad"
            },
            {
                "value": 18865,
                "label": "Ranaghat",
                "label_ar": "رناغات",
                "label_fr": "Ranaghat"
            },
            {
                "value": 18049,
                "label": "Valsad",
                "label_ar": "فالساد",
                "label_fr": "Valsad"
            },
            {
                "value": 18688,
                "label": "Jalesar",
                "label_ar": "جلسار",
                "label_fr": "Jalesar"
            },
            {
                "value": 18947,
                "label": "Nandi",
                "label_ar": "ناندي",
                "label_fr": "Nandi"
            },
            {
                "value": 18372,
                "label": "Kaul",
                "label_ar": "كاول",
                "label_fr": "Kaul"
            },
            {
                "value": 18607,
                "label": "Piduguralla",
                "label_ar": "بيدوجورالا",
                "label_fr": "Piduguralla"
            },
            {
                "value": 18586,
                "label": "Kankauli",
                "label_ar": "كانكولي",
                "label_fr": "Kankauli"
            },
            {
                "value": 18666,
                "label": "Chamba",
                "label_ar": "شامبا",
                "label_fr": "Chamba"
            },
            {
                "value": 18820,
                "label": "Sanand",
                "label_ar": "ساناند",
                "label_fr": "Sanand"
            },
            {
                "value": 18143,
                "label": "Thiruvananthapuram",
                "label_ar": "ثيروفانانثابورام",
                "label_fr": "Thiruvananthapuram"
            },
            {
                "value": 19015,
                "label": "Rayagada",
                "label_ar": "رياجادا",
                "label_fr": "Rayagada"
            },
            {
                "value": 19005,
                "label": "Muddanuru",
                "label_ar": "مودانورو",
                "label_fr": "Muddanuru"
            },
            {
                "value": 18288,
                "label": "Nagar",
                "label_ar": "ناجار",
                "label_fr": "Nagar"
            },
            {
                "value": 18938,
                "label": "Hira",
                "label_ar": "حراء",
                "label_fr": "Hira"
            },
            {
                "value": 18215,
                "label": "Gurdaspur",
                "label_ar": "جورداسبور",
                "label_fr": "Gurdaspur"
            },
            {
                "value": 18070,
                "label": "Naraina",
                "label_ar": "ناراينا",
                "label_fr": "Naraina"
            },
            {
                "value": 18235,
                "label": "Sagar",
                "label_ar": "ساجار",
                "label_fr": "Sagar"
            },
            {
                "value": 18289,
                "label": "Ghatkopar",
                "label_ar": "غاتكوبار",
                "label_fr": "Ghatkopar"
            },
            {
                "value": 18600,
                "label": "Bolpur",
                "label_ar": "بولبور",
                "label_fr": "Bolpur"
            },
            {
                "value": 18904,
                "label": "Mill",
                "label_ar": "مطحنة",
                "label_fr": "Moulin"
            },
            {
                "value": 18244,
                "label": "Vasai",
                "label_ar": "فاساي",
                "label_fr": "Vasai"
            },
            {
                "value": 18307,
                "label": "Madgaon",
                "label_ar": "مادجاون",
                "label_fr": "Madgaon"
            },
            {
                "value": 18536,
                "label": "Mahad",
                "label_ar": "ماهد",
                "label_fr": "Mahad"
            },
            {
                "value": 18515,
                "label": "Haripad",
                "label_ar": "هاريباد",
                "label_fr": "Haripad"
            },
            {
                "value": 18474,
                "label": "Sirkazhi",
                "label_ar": "Sirkazhi",
                "label_fr": "Sirkazhi"
            },
            {
                "value": 18874,
                "label": "Mandsaur",
                "label_ar": "مندسور",
                "label_fr": "Mandsaure"
            },
            {
                "value": 18115,
                "label": "Karad",
                "label_ar": "كراد",
                "label_fr": "Karad"
            },
            {
                "value": 18795,
                "label": "Dharapuram",
                "label_ar": "دارابورام",
                "label_fr": "Dharapuram"
            },
            {
                "value": 18201,
                "label": "Bankura",
                "label_ar": "بانكورا",
                "label_fr": "Bankura"
            },
            {
                "value": 18315,
                "label": "Khatauli",
                "label_ar": "خاتولي",
                "label_fr": "Khatauli"
            },
            {
                "value": 18236,
                "label": "Rewa",
                "label_ar": "ريوا",
                "label_fr": "Rewa"
            },
            {
                "value": 18476,
                "label": "Hiriyur",
                "label_ar": "هيريور",
                "label_fr": "Hiriyur"
            },
            {
                "value": 18962,
                "label": "Basirhat",
                "label_ar": "بصيرات",
                "label_fr": "Basirhat"
            },
            {
                "value": 18527,
                "label": "Habra",
                "label_ar": "هبرة",
                "label_fr": "Habra"
            },
            {
                "value": 18914,
                "label": "Maru",
                "label_ar": "مارو",
                "label_fr": "Maru"
            },
            {
                "value": 18716,
                "label": "Dhone",
                "label_ar": "الدون",
                "label_fr": "Dhone"
            },
            {
                "value": 18071,
                "label": "Mandi",
                "label_ar": "مندي",
                "label_fr": "Mandi"
            },
            {
                "value": 18230,
                "label": "Bareilly",
                "label_ar": "باريلي",
                "label_fr": "Bareilly"
            },
            {
                "value": 18450,
                "label": "Ekkattuthangal",
                "label_ar": "إكاتوثانغال",
                "label_fr": "Ekkattuthangal"
            },
            {
                "value": 18817,
                "label": "Tuljapur",
                "label_ar": "تولجابور",
                "label_fr": "Tuljapur"
            },
            {
                "value": 18949,
                "label": "Suman",
                "label_ar": "سومان",
                "label_fr": "Suman"
            },
            {
                "value": 18597,
                "label": "Basti",
                "label_ar": "باستي",
                "label_fr": "Basti"
            },
            {
                "value": 18922,
                "label": "Chhabra",
                "label_ar": "شبرا",
                "label_fr": "Chhabra"
            },
            {
                "value": 18862,
                "label": "Channapatna",
                "label_ar": "Channapatna",
                "label_fr": "Channapatna"
            },
            {
                "value": 18564,
                "label": "Orai",
                "label_ar": "أوراي",
                "label_fr": "Orai"
            },
            {
                "value": 18152,
                "label": "Idukki",
                "label_ar": "ادوكي",
                "label_fr": "Idukki"
            },
            {
                "value": 19013,
                "label": "Markal",
                "label_ar": "ماركال",
                "label_fr": "Markal"
            },
            {
                "value": 18320,
                "label": "Paloncha",
                "label_ar": "بالونتشا",
                "label_fr": "Paloncha"
            },
            {
                "value": 18127,
                "label": "Somnath",
                "label_ar": "سومناث",
                "label_fr": "Somnath"
            },
            {
                "value": 18719,
                "label": "Solim",
                "label_ar": "سليم",
                "label_fr": "Solim"
            },
            {
                "value": 18765,
                "label": "Baroda",
                "label_ar": "بارودا",
                "label_fr": "Baroda"
            },
            {
                "value": 18932,
                "label": "Rawal",
                "label_ar": "روال",
                "label_fr": "Rawal"
            },
            {
                "value": 18027,
                "label": "Allahabad",
                "label_ar": "الله أباد",
                "label_fr": "Allahabad"
            },
            {
                "value": 18179,
                "label": "Bihar Sharif",
                "label_ar": "بيهار شريف",
                "label_fr": "Bihar Sharif"
            },
            {
                "value": 18218,
                "label": "Vidisha",
                "label_ar": "فيديشا",
                "label_fr": "Vidisha"
            },
            {
                "value": 18073,
                "label": "Jodhpur",
                "label_ar": "جودبور",
                "label_fr": "Jodhpur"
            },
            {
                "value": 18657,
                "label": "Ramanathapuram",
                "label_ar": "راماناثابورام",
                "label_fr": "Ramanathapuram"
            },
            {
                "value": 18693,
                "label": "Kathua",
                "label_ar": "كاثوا",
                "label_fr": "Kathua"
            },
            {
                "value": 19001,
                "label": "Yanam",
                "label_ar": "يانام",
                "label_fr": "Yanam"
            },
            {
                "value": 18123,
                "label": "Panjim",
                "label_ar": "بانجيم",
                "label_fr": "Panjim"
            },
            {
                "value": 18509,
                "label": "Asansol",
                "label_ar": "أسانسول",
                "label_fr": "Asansol"
            },
            {
                "value": 18973,
                "label": "Karari",
                "label_ar": "كراري",
                "label_fr": "Karari"
            },
            {
                "value": 18829,
                "label": "Mount Abu",
                "label_ar": "جبل ابو",
                "label_fr": "Mont Abu"
            },
            {
                "value": 18377,
                "label": "Jamal",
                "label_ar": "جمال",
                "label_fr": "Jamal"
            },
            {
                "value": 18837,
                "label": "Barmer",
                "label_ar": "بارمر",
                "label_fr": "Barmer"
            },
            {
                "value": 18999,
                "label": "Hadadi",
                "label_ar": "حدادي",
                "label_fr": "Hadadi"
            },
            {
                "value": 18055,
                "label": "Unnao",
                "label_ar": "أوناو",
                "label_fr": "Unnao"
            },
            {
                "value": 18804,
                "label": "Ramapuram",
                "label_ar": "رامابورام",
                "label_fr": "Ramapuram"
            },
            {
                "value": 18934,
                "label": "Nandigama",
                "label_ar": "نانديجاما",
                "label_fr": "Nandigama"
            },
            {
                "value": 18579,
                "label": "Sagar",
                "label_ar": "ساجار",
                "label_fr": "Sagar"
            },
            {
                "value": 18523,
                "label": "Sikar",
                "label_ar": "سيكار",
                "label_fr": "Sikar"
            },
            {
                "value": 18448,
                "label": "Godhra",
                "label_ar": "جودهرا",
                "label_fr": "Godhra"
            },
            {
                "value": 18843,
                "label": "Mirza Murad",
                "label_ar": "ميرزا مراد",
                "label_fr": "Mirza Murad"
            },
            {
                "value": 18492,
                "label": "Vashi",
                "label_ar": "فاشي",
                "label_fr": "Vashi"
            },
            {
                "value": 19010,
                "label": "Turaiyur",
                "label_ar": "توريور",
                "label_fr": "Turaiyur"
            },
            {
                "value": 18171,
                "label": "Gangtok",
                "label_ar": "جانجتوك",
                "label_fr": "Gangtok"
            },
            {
                "value": 18313,
                "label": "Bhatinda",
                "label_ar": "باتيندا",
                "label_fr": "Bhatinda"
            },
            {
                "value": 18927,
                "label": "Adwani",
                "label_ar": "العدواني",
                "label_fr": "Adwani"
            },
            {
                "value": 18769,
                "label": "Osmanabad",
                "label_ar": "عثمان اباد",
                "label_fr": "Osmanabad"
            },
            {
                "value": 18860,
                "label": "Shahkot",
                "label_ar": "شاهكوت",
                "label_fr": "Shahkot"
            },
            {
                "value": 18863,
                "label": "Madurantakam",
                "label_ar": "مادورانتاكام",
                "label_fr": "Madurantakam"
            },
            {
                "value": 18344,
                "label": "Aluva",
                "label_ar": "ألوفا",
                "label_fr": "Aluva"
            },
            {
                "value": 18993,
                "label": "Curchorem",
                "label_ar": "كورشورم",
                "label_fr": "Curchorem"
            },
            {
                "value": 18177,
                "label": "Arunachal",
                "label_ar": "أروناتشال",
                "label_fr": "Arunachal"
            },
            {
                "value": 18521,
                "label": "Singtam",
                "label_ar": "سينغتام",
                "label_fr": "Singtam"
            },
            {
                "value": 18258,
                "label": "Bodhan",
                "label_ar": "بودهان",
                "label_fr": "Bodhan"
            },
            {
                "value": 18429,
                "label": "Belapur",
                "label_ar": "بيلابور",
                "label_fr": "Belapur"
            },
            {
                "value": 18550,
                "label": "Bhatapara",
                "label_ar": "بهاتابارا",
                "label_fr": "Bhatapara"
            },
            {
                "value": 18026,
                "label": "Varanasi",
                "label_ar": "فاراناسي",
                "label_fr": "Varanasi"
            },
            {
                "value": 18272,
                "label": "Virar",
                "label_ar": "فيرار",
                "label_fr": "Virar"
            },
            {
                "value": 18412,
                "label": "Ongole",
                "label_ar": "اونجول",
                "label_fr": "Ongole"
            },
            {
                "value": 18398,
                "label": "Ranippettai",
                "label_ar": "رانيبيتاي",
                "label_fr": "Ranippettai"
            },
            {
                "value": 18746,
                "label": "Behala",
                "label_ar": "بهالا",
                "label_fr": "Behala"
            },
            {
                "value": 18538,
                "label": "Pimpri",
                "label_ar": "بيمبري",
                "label_fr": "Pimpri"
            },
            {
                "value": 18582,
                "label": "Honavar",
                "label_ar": "هونافار",
                "label_fr": "Honavar"
            },
            {
                "value": 18884,
                "label": "Ghatal",
                "label_ar": "غتال",
                "label_fr": "Ghatal"
            },
            {
                "value": 18736,
                "label": "Ottappalam",
                "label_ar": "Ottappalam",
                "label_fr": "Ottappalam"
            },
            {
                "value": 18791,
                "label": "Faizabad",
                "label_ar": "فايز آباد",
                "label_fr": "Faizabad"
            },
            {
                "value": 18302,
                "label": "Alipur",
                "label_ar": "عليبور",
                "label_fr": "Alipur"
            },
            {
                "value": 18309,
                "label": "Katra",
                "label_ar": "كاترا",
                "label_fr": "Katra"
            },
            {
                "value": 18603,
                "label": "Binnaguri",
                "label_ar": "بيناغوري",
                "label_fr": "Binnaguri"
            },
            {
                "value": 18018,
                "label": "Faridabad",
                "label_ar": "فريداباد",
                "label_fr": "Faridabad"
            },
            {
                "value": 18097,
                "label": "Kurnool",
                "label_ar": "كورنول",
                "label_fr": "Kurnool"
            },
            {
                "value": 18852,
                "label": "Nagaur",
                "label_ar": "ناجور",
                "label_fr": "Nagaur"
            },
            {
                "value": 18017,
                "label": "New Delhi",
                "label_ar": "نيو دلهي",
                "label_fr": "New Delhi"
            },
            {
                "value": 18822,
                "label": "Mussoorie",
                "label_ar": "موسوري",
                "label_fr": "Mussoorie"
            },
            {
                "value": 18926,
                "label": "Ellora Caves",
                "label_ar": "كهوف Ellora",
                "label_fr": "Grottes d'Ellora"
            },
            {
                "value": 18886,
                "label": "Belgharia",
                "label_ar": "بلغارية",
                "label_fr": "Belgharia"
            },
            {
                "value": 18841,
                "label": "Sangola",
                "label_ar": "سانجولا",
                "label_fr": "Sangola"
            },
            {
                "value": 18935,
                "label": "Mathan",
                "label_ar": "ماثان",
                "label_fr": "Mathan"
            },
            {
                "value": 18925,
                "label": "Karimganj",
                "label_ar": "كريمغانج",
                "label_fr": "Karimganj"
            },
            {
                "value": 18056,
                "label": "Tiruvallur",
                "label_ar": "تيروفالور",
                "label_fr": "Tiruvallur"
            },
            {
                "value": 18120,
                "label": "Ponda",
                "label_ar": "بوندا",
                "label_fr": "Ponda"
            },
            {
                "value": 18895,
                "label": "Mahajan",
                "label_ar": "ماهاجان",
                "label_fr": "Mahajan"
            },
            {
                "value": 19003,
                "label": "Balanagar",
                "label_ar": "Balanagar",
                "label_fr": "Balanagar"
            },
            {
                "value": 18092,
                "label": "Nalgonda",
                "label_ar": "نالجوندا",
                "label_fr": "Nalgonda"
            },
            {
                "value": 18995,
                "label": "Bhagwan",
                "label_ar": "بهاجوان",
                "label_fr": "Bhagwan"
            },
            {
                "value": 18616,
                "label": "Sibsagar",
                "label_ar": "Sibsagar",
                "label_fr": "Sibsagar"
            },
            {
                "value": 18975,
                "label": "Jaunpur",
                "label_ar": "جاونبور",
                "label_fr": "Jaunpur"
            },
            {
                "value": 18996,
                "label": "Vijapur",
                "label_ar": "فيجابور",
                "label_fr": "Vijapur"
            },
            {
                "value": 18561,
                "label": "Morena",
                "label_ar": "مورينا",
                "label_fr": "Morena"
            },
            {
                "value": 18553,
                "label": "Goshaingaon",
                "label_ar": "Goshaingaon",
                "label_fr": "Goshaingaon"
            },
            {
                "value": 18779,
                "label": "Singarayakonda",
                "label_ar": "سينجاراياكوندا",
                "label_fr": "Singarayakonda"
            },
            {
                "value": 18675,
                "label": "Parra",
                "label_ar": "بارا",
                "label_fr": "Parra"
            },
            {
                "value": 18588,
                "label": "Parbhani",
                "label_ar": "باربهاني",
                "label_fr": "Parbhani"
            },
            {
                "value": 18924,
                "label": "Manu",
                "label_ar": "مانو",
                "label_fr": "Manu"
            },
            {
                "value": 18847,
                "label": "Tonk",
                "label_ar": "تونك",
                "label_fr": "Tonk"
            },
            {
                "value": 18003,
                "label": "Mumbai",
                "label_ar": "مومباي",
                "label_fr": "Bombay"
            },
            {
                "value": 18741,
                "label": "Damoh",
                "label_ar": "دموه",
                "label_fr": "Damoh"
            },
            {
                "value": 18038,
                "label": "Udupi",
                "label_ar": "أودوبي",
                "label_fr": "Udupi"
            },
            {
                "value": 18277,
                "label": "Ernakulam",
                "label_ar": "ارناكولام",
                "label_fr": "Ernakulam"
            },
            {
                "value": 18730,
                "label": "Talwandi Sabo",
                "label_ar": "تالوندي سابو",
                "label_fr": "Talwandi Sabo"
            },
            {
                "value": 18667,
                "label": "Khergam",
                "label_ar": "خرقام",
                "label_fr": "Khergam"
            },
            {
                "value": 18511,
                "label": "Kohima",
                "label_ar": "كوهيما",
                "label_fr": "Kohima"
            },
            {
                "value": 18063,
                "label": "Ooty",
                "label_ar": "أوتي",
                "label_fr": "Ooty"
            },
            {
                "value": 18397,
                "label": "Ariyalur",
                "label_ar": "أريالور",
                "label_fr": "Ariyalur"
            },
            {
                "value": 18542,
                "label": "Mhow",
                "label_ar": "مهو",
                "label_fr": "Mhow"
            },
            {
                "value": 18012,
                "label": "Indore",
                "label_ar": "إندور",
                "label_fr": "Indore"
            },
            {
                "value": 18568,
                "label": "Pithapuram",
                "label_ar": "بيثابورام",
                "label_fr": "Pithapuram"
            },
            {
                "value": 18755,
                "label": "Pauri",
                "label_ar": "بوري",
                "label_fr": "Pauri"
            },
            {
                "value": 18758,
                "label": "Nongpoh",
                "label_ar": "Nongpoh",
                "label_fr": "Nongpoh"
            },
            {
                "value": 18916,
                "label": "Chopra",
                "label_ar": "تشوبرا",
                "label_fr": "Chopra"
            },
            {
                "value": 18066,
                "label": "Puducherry",
                "label_ar": "بودوتشيري",
                "label_fr": "Pondichéry"
            },
            {
                "value": 18403,
                "label": "Lanka",
                "label_ar": "لانكا",
                "label_fr": "Lanka"
            },
            {
                "value": 18621,
                "label": "Cheyyar",
                "label_ar": "شيار",
                "label_fr": "Cheyyar"
            },
            {
                "value": 18232,
                "label": "Kapurthala Town",
                "label_ar": "بلدة كابورتالا",
                "label_fr": "Ville de Kapurthala"
            },
            {
                "value": 18358,
                "label": "Petlad",
                "label_ar": "بيتلاد",
                "label_fr": "Petlad"
            },
            {
                "value": 18362,
                "label": "Dasua",
                "label_ar": "داسوا",
                "label_fr": "Dasua"
            },
            {
                "value": 18590,
                "label": "Barpali",
                "label_ar": "باربالي",
                "label_fr": "Barpali"
            },
            {
                "value": 18997,
                "label": "Sinnar",
                "label_ar": "سنار",
                "label_fr": "Sinnar"
            },
            {
                "value": 18541,
                "label": "Vaikam",
                "label_ar": "Vaikam",
                "label_fr": "Vaikam"
            },
            {
                "value": 18426,
                "label": "Nila",
                "label_ar": "نيلا",
                "label_fr": "Nila"
            },
            {
                "value": 18392,
                "label": "Karaikal",
                "label_ar": "كاريكال",
                "label_fr": "Karaikal"
            },
            {
                "value": 18456,
                "label": "Nehra",
                "label_ar": "نهرا",
                "label_fr": "Nehra"
            },
            {
                "value": 18613,
                "label": "Mattanur",
                "label_ar": "ماتانور",
                "label_fr": "Mattanur"
            },
            {
                "value": 18800,
                "label": "Sandur",
                "label_ar": "ساندور",
                "label_fr": "Sandur"
            },
            {
                "value": 18496,
                "label": "Kota",
                "label_ar": "كوتا",
                "label_fr": "Kota"
            },
            {
                "value": 18775,
                "label": "Neri",
                "label_ar": "نيري",
                "label_fr": "Neri"
            },
            {
                "value": 18154,
                "label": "Alappuzha",
                "label_ar": "الابوزا",
                "label_fr": "Alappuzha"
            },
            {
                "value": 18047,
                "label": "Palakkad",
                "label_ar": "بالاكاد",
                "label_fr": "Palakkad"
            },
            {
                "value": 18165,
                "label": "Chitra",
                "label_ar": "شيترا",
                "label_fr": "Chitra"
            },
            {
                "value": 18089,
                "label": "Kadapa",
                "label_ar": "كادابا",
                "label_fr": "Kadapa"
            },
            {
                "value": 18839,
                "label": "Khajuraho Group of Monuments",
                "label_ar": "مجموعة خاجوراهو للآثار",
                "label_fr": "Groupe de monuments de Khajuraho"
            },
            {
                "value": 18335,
                "label": "Mohali",
                "label_ar": "موهالي",
                "label_fr": "Mohali"
            },
            {
                "value": 18248,
                "label": "Brahmapur",
                "label_ar": "براهمابور",
                "label_fr": "Brahmapur"
            },
            {
                "value": 18689,
                "label": "Kasganj",
                "label_ar": "كاسجانج",
                "label_fr": "Kasganj"
            },
            {
                "value": 18650,
                "label": "Munger",
                "label_ar": "مونجر",
                "label_fr": "Munger"
            },
            {
                "value": 18594,
                "label": "Mettur",
                "label_ar": "ميتور",
                "label_fr": "Mettur"
            },
            {
                "value": 18986,
                "label": "Chhota Udepur",
                "label_ar": "تشوتا أوديبور",
                "label_fr": "Chhota Udepur"
            },
            {
                "value": 18433,
                "label": "Bulandshahr",
                "label_ar": "بولاندشهر",
                "label_fr": "Bulandshahr"
            },
            {
                "value": 18518,
                "label": "Bambolim",
                "label_ar": "بامبوليم",
                "label_fr": "Bambolim"
            },
            {
                "value": 18628,
                "label": "Puttur",
                "label_ar": "بوتور",
                "label_fr": "Puttur"
            },
            {
                "value": 18010,
                "label": "Lucknow",
                "label_ar": "لكناو",
                "label_fr": "Lucknow"
            },
            {
                "value": 18738,
                "label": "Palus",
                "label_ar": "بالوس",
                "label_fr": "Palus"
            },
            {
                "value": 18261,
                "label": "Vizianagaram",
                "label_ar": "فيزياناجارام",
                "label_fr": "Vizianagaram"
            },
            {
                "value": 18164,
                "label": "Attingal",
                "label_ar": "أتنغال",
                "label_fr": "Attingal"
            },
            {
                "value": 18606,
                "label": "Madikeri",
                "label_ar": "ماديكيري",
                "label_fr": "Madikeri"
            },
            {
                "value": 18348,
                "label": "Balasore",
                "label_ar": "بالاسور",
                "label_fr": "Balasore"
            },
            {
                "value": 18395,
                "label": "Aranmula",
                "label_ar": "أرانمولا",
                "label_fr": "Aranmula"
            },
            {
                "value": 18558,
                "label": "Keshod",
                "label_ar": "كيشود",
                "label_fr": "Keshod"
            },
            {
                "value": 18263,
                "label": "Pathanamthitta",
                "label_ar": "باثانامثيتا",
                "label_fr": "Pathanamthitta"
            },
            {
                "value": 18181,
                "label": "Dibrugarh",
                "label_ar": "ديبروجاره",
                "label_fr": "Dibrugarh"
            },
            {
                "value": 18465,
                "label": "Khurja",
                "label_ar": "خورجة",
                "label_fr": "Khurja"
            },
            {
                "value": 18845,
                "label": "Budha",
                "label_ar": "بوذا",
                "label_fr": "Budha"
            },
            {
                "value": 18753,
                "label": "Bichpuri",
                "label_ar": "Bichpuri",
                "label_fr": "Bichpuri"
            },
            {
                "value": 18729,
                "label": "Machhiwara",
                "label_ar": "مشيوارا",
                "label_fr": "Machhiwara"
            },
            {
                "value": 18363,
                "label": "Gonda",
                "label_ar": "جوندا",
                "label_fr": "Gonda"
            },
            {
                "value": 18225,
                "label": "Kangra",
                "label_ar": "كانجرا",
                "label_fr": "Kangra"
            },
            {
                "value": 18360,
                "label": "Villupuram",
                "label_ar": "فيلوبورام",
                "label_fr": "Villupuram"
            },
            {
                "value": 18552,
                "label": "Dhubri",
                "label_ar": "ذبري",
                "label_fr": "Dhubri"
            },
            {
                "value": 18520,
                "label": "Connaught Place",
                "label_ar": "كونوت بليس",
                "label_fr": "Connaught Place"
            },
            {
                "value": 18609,
                "label": "Berasia",
                "label_ar": "بيراسيا",
                "label_fr": "Berasia"
            },
            {
                "value": 18198,
                "label": "Silchar",
                "label_ar": "سيلشار",
                "label_fr": "Silchar"
            },
            {
                "value": 18506,
                "label": "Pali",
                "label_ar": "بالي",
                "label_fr": "Pali"
            },
            {
                "value": 18715,
                "label": "Nandyal",
                "label_ar": "نانديال",
                "label_fr": "Nandyal"
            },
            {
                "value": 18275,
                "label": "Siliguri",
                "label_ar": "سيليجوري",
                "label_fr": "Siliguri"
            },
            {
                "value": 18512,
                "label": "Shahdara",
                "label_ar": "شهدارا",
                "label_fr": "Shahdara"
            },
            {
                "value": 18048,
                "label": "Kalyan",
                "label_ar": "كاليان",
                "label_fr": "Kalyan"
            },
            {
                "value": 18052,
                "label": "Shimla",
                "label_ar": "شيملا",
                "label_fr": "Shimla"
            },
            {
                "value": 18355,
                "label": "Goa",
                "label_ar": "غوا",
                "label_fr": "Goa"
            },
            {
                "value": 18565,
                "label": "Fatehpur",
                "label_ar": "فاتحبور",
                "label_fr": "Fatehpur"
            },
            {
                "value": 18797,
                "label": "Talcher",
                "label_ar": "تالشر",
                "label_fr": "Talcher"
            },
            {
                "value": 18830,
                "label": "Vadner",
                "label_ar": "فادنر",
                "label_fr": "Vadner"
            },
            {
                "value": 18848,
                "label": "Koni",
                "label_ar": "كوني",
                "label_fr": "Koni"
            },
            {
                "value": 18752,
                "label": "Firozabad",
                "label_ar": "فيروز آباد",
                "label_fr": "Firozabad"
            },
            {
                "value": 18620,
                "label": "Karanja",
                "label_ar": "كارانجا",
                "label_fr": "Karanja"
            },
            {
                "value": 18535,
                "label": "Canning",
                "label_ar": "تعليب",
                "label_fr": "Mise en conserve"
            },
            {
                "value": 18740,
                "label": "Durg",
                "label_ar": "دورج",
                "label_fr": "Durg"
            },
            {
                "value": 18472,
                "label": "Jalalabad",
                "label_ar": "جلال اباد",
                "label_fr": "Jalalabad"
            },
            {
                "value": 18106,
                "label": "Morvi",
                "label_ar": "مورفي",
                "label_fr": "Morvi"
            },
            {
                "value": 18322,
                "label": "Alwar",
                "label_ar": "الور",
                "label_fr": "Alwar"
            },
            {
                "value": 18519,
                "label": "Baidyabati",
                "label_ar": "بيدياباتي",
                "label_fr": "Baidyabati"
            },
            {
                "value": 18595,
                "label": "Srirangam",
                "label_ar": "سريرانجام",
                "label_fr": "Srirangam"
            },
            {
                "value": 18967,
                "label": "Mahulia",
                "label_ar": "Mahulia",
                "label_fr": "Mahulia"
            },
            {
                "value": 18103,
                "label": "Anand",
                "label_ar": "أناند",
                "label_fr": "Anand"
            },
            {
                "value": 18408,
                "label": "Bala",
                "label_ar": "بالا",
                "label_fr": "Bala"
            },
            {
                "value": 18713,
                "label": "Punalur",
                "label_ar": "بونالور",
                "label_fr": "Punalur"
            },
            {
                "value": 18423,
                "label": "Andheri",
                "label_ar": "أنديري",
                "label_fr": "Andheri"
            },
            {
                "value": 18343,
                "label": "Dharmapuri",
                "label_ar": "دارمابوري",
                "label_fr": "Dharmapuri"
            },
            {
                "value": 18747,
                "label": "Fort",
                "label_ar": "فورت",
                "label_fr": "Fort"
            },
            {
                "value": 18180,
                "label": "Mandal",
                "label_ar": "ماندال",
                "label_fr": "Mandal"
            },
            {
                "value": 18308,
                "label": "Verna",
                "label_ar": "فيرنا",
                "label_fr": "Verna"
            },
            {
                "value": 18555,
                "label": "Puttur",
                "label_ar": "بوتور",
                "label_fr": "Puttur"
            },
            {
                "value": 18636,
                "label": "Thasra",
                "label_ar": "ثسرا",
                "label_fr": "Thasra"
            },
            {
                "value": 18770,
                "label": "Betalbatim",
                "label_ar": "بيتالباطيم",
                "label_fr": "Betalbatim"
            },
            {
                "value": 18807,
                "label": "Calangute",
                "label_ar": "كالانجوت",
                "label_fr": "Calangute"
            },
            {
                "value": 18037,
                "label": "Panvel",
                "label_ar": "بانفل",
                "label_fr": "Panvel"
            },
            {
                "value": 18567,
                "label": "Adilabad",
                "label_ar": "عادل اباد",
                "label_fr": "Adilabad"
            },
            {
                "value": 18534,
                "label": "Port Blair",
                "label_ar": "ميناء بلير",
                "label_fr": "Port Blair"
            },
            {
                "value": 18517,
                "label": "Vapi",
                "label_ar": "فابي",
                "label_fr": "Vapi"
            },
            {
                "value": 18751,
                "label": "Mawana",
                "label_ar": "موانا",
                "label_fr": "Mawana"
            },
            {
                "value": 18801,
                "label": "Bapatla",
                "label_ar": "باباتلا",
                "label_fr": "Bapatla"
            },
            {
                "value": 18980,
                "label": "Mithapur",
                "label_ar": "ميثابور",
                "label_fr": "Mithapur"
            },
            {
                "value": 18912,
                "label": "Kalra",
                "label_ar": "كالرا",
                "label_fr": "Kalra"
            },
            {
                "value": 18050,
                "label": "Ulhasnagar",
                "label_ar": "Ulhasnagar",
                "label_fr": "Ulhasnagar"
            },
            {
                "value": 18825,
                "label": "Pochampalli",
                "label_ar": "بوتشامبالي",
                "label_fr": "Pochampalli"
            },
            {
                "value": 18888,
                "label": "Dhariwal",
                "label_ar": "داريوال",
                "label_fr": "Dhariwal"
            },
            {
                "value": 18341,
                "label": "Kayamkulam",
                "label_ar": "كايامكولام",
                "label_fr": "Kayamkulam"
            },
            {
                "value": 18211,
                "label": "Hisar",
                "label_ar": "حصار",
                "label_fr": "Hisar"
            },
            {
                "value": 18824,
                "label": "Sholavandan",
                "label_ar": "شولافاندان",
                "label_fr": "Sholavandan"
            },
            {
                "value": 18732,
                "label": "Dhar",
                "label_ar": "ذر",
                "label_fr": "Dhar"
            },
            {
                "value": 18905,
                "label": "Khambhat",
                "label_ar": "خمبهات",
                "label_fr": "Khambhat"
            },
            {
                "value": 18936,
                "label": "Panchal",
                "label_ar": "بانشال",
                "label_fr": "Panchal"
            },
            {
                "value": 18259,
                "label": "Parappanangadi",
                "label_ar": "بارابانانجادي",
                "label_fr": "Parappanangadi"
            },
            {
                "value": 18946,
                "label": "Hari",
                "label_ar": "هاري",
                "label_fr": "Hari"
            },
            {
                "value": 18129,
                "label": "Karwar",
                "label_ar": "كاروار",
                "label_fr": "Karwar"
            },
            {
                "value": 18833,
                "label": "Pahalgam",
                "label_ar": "باهالجام",
                "label_fr": "Pahalgam"
            },
            {
                "value": 18407,
                "label": "Salt Lake City",
                "label_ar": "سولت لايك سيتي",
                "label_fr": "Salt Lake City"
            },
            {
                "value": 18329,
                "label": "Batala",
                "label_ar": "باتالا",
                "label_fr": "Batala"
            },
            {
                "value": 18285,
                "label": "Bandra",
                "label_ar": "باندرا",
                "label_fr": "Bandra"
            },
            {
                "value": 18224,
                "label": "Ujjain",
                "label_ar": "يوجين",
                "label_fr": "Ujjain"
            },
            {
                "value": 18907,
                "label": "Begusarai",
                "label_ar": "بيجوساراي",
                "label_fr": "Begusarai"
            },
            {
                "value": 18622,
                "label": "Vandavasi",
                "label_ar": "فاندافاسي",
                "label_fr": "Vandavasi"
            },
            {
                "value": 18483,
                "label": "Mahim",
                "label_ar": "Mahim",
                "label_fr": "Mahim"
            },
            {
                "value": 18910,
                "label": "Golaghat",
                "label_ar": "غولاغات",
                "label_fr": "Golaghat"
            },
            {
                "value": 18437,
                "label": "Baharampur",
                "label_ar": "باهارامبور",
                "label_fr": "Baharampur"
            },
            {
                "value": 18130,
                "label": "Davangere",
                "label_ar": "دافانجير",
                "label_fr": "Davangere"
            },
            {
                "value": 18526,
                "label": "Puruliya",
                "label_ar": "Puruliya",
                "label_fr": "Puruliya"
            },
            {
                "value": 18453,
                "label": "Bardoli",
                "label_ar": "باردولي",
                "label_fr": "Bardoli"
            },
            {
                "value": 18677,
                "label": "Chicalim",
                "label_ar": "تشيكاليم",
                "label_fr": "Chicalim"
            },
            {
                "value": 18034,
                "label": "Ahmedabad",
                "label_ar": "أحمد آباد",
                "label_fr": "Ahmedabad"
            },
            {
                "value": 18704,
                "label": "Rupnarayanpur",
                "label_ar": "روبناراانبور",
                "label_fr": "Rupnarayanpur"
            },
            {
                "value": 18045,
                "label": "Chandigarh",
                "label_ar": "شانديغار",
                "label_fr": "Chandigarh"
            },
            {
                "value": 18871,
                "label": "Dhaka",
                "label_ar": "دكا",
                "label_fr": "Dhaka"
            },
            {
                "value": 18901,
                "label": "Tikamgarh",
                "label_ar": "تيكامجاره",
                "label_fr": "Tikamgarh"
            },
            {
                "value": 18546,
                "label": "Gudivada",
                "label_ar": "جوديفادا",
                "label_fr": "Gudivada"
            },
            {
                "value": 18641,
                "label": "Barnala",
                "label_ar": "بارنالا",
                "label_fr": "Barnala"
            },
            {
                "value": 18655,
                "label": "Nagercoil",
                "label_ar": "ناجركويل",
                "label_fr": "Nagercoil"
            },
            {
                "value": 18366,
                "label": "Nuzvid",
                "label_ar": "نوزفيد",
                "label_fr": "Nuzvid"
            },
            {
                "value": 18773,
                "label": "Yeola",
                "label_ar": "يولا",
                "label_fr": "Yeola"
            },
            {
                "value": 18035,
                "label": "Manipala",
                "label_ar": "مانيبالا",
                "label_fr": "Manipala"
            },
            {
                "value": 18941,
                "label": "Kheda",
                "label_ar": "خدة",
                "label_fr": "Kheda"
            },
            {
                "value": 18734,
                "label": "Pileru",
                "label_ar": "بيليرو",
                "label_fr": "Pileru"
            },
            {
                "value": 18168,
                "label": "Kalam",
                "label_ar": "كلام",
                "label_fr": "Kalam"
            },
            {
                "value": 18694,
                "label": "Chalisgaon",
                "label_ar": "تشاليسجاون",
                "label_fr": "Chalisgaon"
            },
            {
                "value": 18239,
                "label": "Bhadohi",
                "label_ar": "بهادوهي",
                "label_fr": "Bhadohi"
            },
            {
                "value": 18697,
                "label": "Arani",
                "label_ar": "أراني",
                "label_fr": "Arani"
            },
            {
                "value": 18788,
                "label": "Karamadai",
                "label_ar": "كرامداي",
                "label_fr": "Karamadai"
            },
            {
                "value": 18029,
                "label": "Noida",
                "label_ar": "نويدا",
                "label_fr": "Noida"
            },
            {
                "value": 18169,
                "label": "Ranchi",
                "label_ar": "رانشي",
                "label_fr": "Ranchi"
            },
            {
                "value": 18439,
                "label": "Boisar",
                "label_ar": "بويزار",
                "label_fr": "Boisar"
            },
            {
                "value": 18091,
                "label": "Anantpur",
                "label_ar": "أنانتبور",
                "label_fr": "Anantpur"
            },
            {
                "value": 18798,
                "label": "Haldia",
                "label_ar": "هالديا",
                "label_fr": "Haldia"
            },
            {
                "value": 18530,
                "label": "Tiptur",
                "label_ar": "تيبتور",
                "label_fr": "Tiptur"
            },
            {
                "value": 18799,
                "label": "Karsiyang",
                "label_ar": "كارسيانغ",
                "label_fr": "Karsiyang"
            },
            {
                "value": 18210,
                "label": "Raigarh",
                "label_ar": "رايجاره",
                "label_fr": "Raigarh"
            },
            {
                "value": 18445,
                "label": "Puras",
                "label_ar": "بوراس",
                "label_fr": "Puras"
            },
            {
                "value": 18759,
                "label": "Gangrar",
                "label_ar": "جانجرار",
                "label_fr": "Gangrar"
            },
            {
                "value": 18134,
                "label": "Hassan",
                "label_ar": "حسان",
                "label_fr": "Hassan"
            },
            {
                "value": 18585,
                "label": "Gargoti",
                "label_ar": "جارجوتي",
                "label_fr": "Gargoti"
            },
            {
                "value": 18316,
                "label": "Vikasnagar",
                "label_ar": "فيكاسناغار",
                "label_fr": "Vikasnagar"
            },
            {
                "value": 18208,
                "label": "Faridkot",
                "label_ar": "فريدكوت",
                "label_fr": "Faridkot"
            },
            {
                "value": 18612,
                "label": "Angadipuram",
                "label_ar": "أنجاديبورام",
                "label_fr": "Angadipuram"
            },
            {
                "value": 18872,
                "label": "Mahal",
                "label_ar": "محل",
                "label_fr": "Mahal"
            },
            {
                "value": 18551,
                "label": "Ramgarh",
                "label_ar": "رامجاره",
                "label_fr": "Ramgarh"
            },
            {
                "value": 18399,
                "label": "Kundan",
                "label_ar": "كوندان",
                "label_fr": "Kundan"
            },
            {
                "value": 18291,
                "label": "Powai",
                "label_ar": "بواي",
                "label_fr": "Powai"
            },
            {
                "value": 18771,
                "label": "Gangapur",
                "label_ar": "جانجابور",
                "label_fr": "Gangapur"
            },
            {
                "value": 18449,
                "label": "Dam Dam",
                "label_ar": "سد السد",
                "label_fr": "Barrage du barrage"
            },
            {
                "value": 18158,
                "label": "Amet",
                "label_ar": "أميت",
                "label_fr": "Amet"
            },
            {
                "value": 18250,
                "label": "Surendranagar",
                "label_ar": "سوريندرانجار",
                "label_fr": "Surendranagar"
            },
            {
                "value": 18196,
                "label": "Machilipatnam",
                "label_ar": "ماتشيليباتنام",
                "label_fr": "Machilipatnam"
            },
            {
                "value": 18138,
                "label": "Mysore",
                "label_ar": "ميسور",
                "label_fr": "Mysore"
            },
            {
                "value": 18853,
                "label": "Mandapeta",
                "label_ar": "ماندابيتا",
                "label_fr": "Mandapeta"
            },
            {
                "value": 18238,
                "label": "Rampur",
                "label_ar": "رامبور",
                "label_fr": "Rampur"
            },
            {
                "value": 18305,
                "label": "Yavatmal",
                "label_ar": "يافتمال",
                "label_fr": "Yavatmal"
            },
            {
                "value": 18502,
                "label": "Neyveli",
                "label_ar": "نيفيلي",
                "label_fr": "Neyveli"
            },
            {
                "value": 18777,
                "label": "Patelguda",
                "label_ar": "باتيلجودا",
                "label_fr": "Patelguda"
            },
            {
                "value": 18147,
                "label": "Thalassery",
                "label_ar": "تلاشيري",
                "label_fr": "Thalasserie"
            },
            {
                "value": 18237,
                "label": "Bhawan",
                "label_ar": "بهاوان",
                "label_fr": "Bhawan"
            },
            {
                "value": 18525,
                "label": "Mayapur",
                "label_ar": "مايابور",
                "label_fr": "Mayapur"
            },
            {
                "value": 18682,
                "label": "Ganganagar",
                "label_ar": "جانجاناجار",
                "label_fr": "Ganganagar"
            },
            {
                "value": 18031,
                "label": "Thane",
                "label_ar": "ثين",
                "label_fr": "Thane"
            },
            {
                "value": 18671,
                "label": "Tarn Taran",
                "label_ar": "تارن تاران",
                "label_fr": "Tarn Taran"
            },
            {
                "value": 18008,
                "label": "Pune",
                "label_ar": "بيون",
                "label_fr": "Pune"
            },
            {
                "value": 18019,
                "label": "Rajkot",
                "label_ar": "راجكوت",
                "label_fr": "Rajkot"
            },
            {
                "value": 18381,
                "label": "Ranjan",
                "label_ar": "رانجان",
                "label_fr": "Ranjan"
            },
            {
                "value": 18436,
                "label": "Pitampura",
                "label_ar": "بيتامبورا",
                "label_fr": "Pitampura"
            },
            {
                "value": 18598,
                "label": "Padrauna",
                "label_ar": "بادراونا",
                "label_fr": "Padrauna"
            },
            {
                "value": 18576,
                "label": "Shahabad",
                "label_ar": "شهباد",
                "label_fr": "Shahabad"
            },
            {
                "value": 18580,
                "label": "Bhadravati",
                "label_ar": "بهادرافاتي",
                "label_fr": "Bhadravati"
            },
            {
                "value": 18466,
                "label": "Ramnagar",
                "label_ar": "رامناغار",
                "label_fr": "Ramnagar"
            },
            {
                "value": 18172,
                "label": "Srikakulam",
                "label_ar": "سريكاكولام",
                "label_fr": "Srikakulam"
            },
            {
                "value": 18809,
                "label": "Khargone",
                "label_ar": "خرج",
                "label_fr": "Khargone"
            },
            {
                "value": 18929,
                "label": "Soni",
                "label_ar": "سوني",
                "label_fr": "Soni"
            },
            {
                "value": 19006,
                "label": "Panruti",
                "label_ar": "بانروتي",
                "label_fr": "Panruti"
            },
            {
                "value": 18968,
                "label": "Alipur",
                "label_ar": "عليبور",
                "label_fr": "Alipur"
            },
            {
                "value": 18644,
                "label": "Jaspur",
                "label_ar": "جاسبور",
                "label_fr": "Jaspur"
            },
            {
                "value": 18245,
                "label": "Badlapur",
                "label_ar": "Badlapur",
                "label_fr": "Badlapur"
            },
            {
                "value": 18878,
                "label": "Kalkaji Devi",
                "label_ar": "كالكاجي ديفي",
                "label_fr": "Kalkaji Devi"
            },
            {
                "value": 18394,
                "label": "Thenkasi",
                "label_ar": "ثنكاسي",
                "label_fr": "Thenkasi"
            },
            {
                "value": 18599,
                "label": "Budaun",
                "label_ar": "بوداون",
                "label_fr": "Budaun"
            },
            {
                "value": 18058,
                "label": "Jamshedpur",
                "label_ar": "جامشيدبور",
                "label_fr": "Jamshedpur"
            },
            {
                "value": 18199,
                "label": "Dumka",
                "label_ar": "دومكا",
                "label_fr": "Dumka"
            },
            {
                "value": 18417,
                "label": "Mylapore",
                "label_ar": "ميلابور",
                "label_fr": "Mylapore"
            },
            {
                "value": 18353,
                "label": "Cuncolim",
                "label_ar": "كونكوليم",
                "label_fr": "Cuncolim"
            },
            {
                "value": 18262,
                "label": "Mavelikara",
                "label_ar": "مافيليكارا",
                "label_fr": "Mavelikara"
            },
            {
                "value": 18112,
                "label": "Junagadh",
                "label_ar": "جوناغاد",
                "label_fr": "Junagadh"
            },
            {
                "value": 18380,
                "label": "Dharamsala",
                "label_ar": "دارامسالا",
                "label_fr": "Dharamsala"
            },
            {
                "value": 18656,
                "label": "Kanniyakumari",
                "label_ar": "كانياكوماري",
                "label_fr": "Kanniyakumari"
            },
            {
                "value": 18754,
                "label": "Almora",
                "label_ar": "المورا",
                "label_fr": "Almora"
            },
            {
                "value": 18084,
                "label": "Guntur",
                "label_ar": "جونتور",
                "label_fr": "Guntur"
            },
            {
                "value": 18969,
                "label": "Hamirpur",
                "label_ar": "هاميربور",
                "label_fr": "Hamirpur"
            },
            {
                "value": 18870,
                "label": "Bawana",
                "label_ar": "باوانا",
                "label_fr": "Bawana"
            },
            {
                "value": 18498,
                "label": "Anantnag",
                "label_ar": "أنانتناج",
                "label_fr": "Anantnag"
            },
            {
                "value": 18411,
                "label": "Nabha",
                "label_ar": "نبعه",
                "label_fr": "Nabha"
            },
            {
                "value": 18639,
                "label": "Firozpur",
                "label_ar": "فيروزبور",
                "label_fr": "Firozpur"
            },
            {
                "value": 18544,
                "label": "Madhoganj",
                "label_ar": "مادهوجان",
                "label_fr": "Madhoganj"
            },
            {
                "value": 18128,
                "label": "Ganpat",
                "label_ar": "جانبات",
                "label_fr": "Ganpat"
            },
            {
                "value": 18687,
                "label": "Arvi",
                "label_ar": "أرفي",
                "label_fr": "Arvi"
            },
            {
                "value": 18920,
                "label": "Charu",
                "label_ar": "شارو",
                "label_fr": "Charu"
            },
            {
                "value": 18699,
                "label": "Pattambi",
                "label_ar": "باتامبي",
                "label_fr": "Pattambi"
            },
            {
                "value": 18714,
                "label": "Mandya",
                "label_ar": "مانديا",
                "label_fr": "Mandya"
            },
            {
                "value": 18077,
                "label": "Karnal",
                "label_ar": "كارنال",
                "label_fr": "Karnal"
            },
            {
                "value": 18279,
                "label": "Barakpur",
                "label_ar": "باراكبور",
                "label_fr": "Barakpur"
            },
            {
                "value": 18342,
                "label": "Namakkal",
                "label_ar": "ناماكال",
                "label_fr": "Namakkal"
            },
            {
                "value": 18298,
                "label": "Markapur",
                "label_ar": "ماركابور",
                "label_fr": "Markapur"
            },
            {
                "value": 18268,
                "label": "Bodinayakkanur",
                "label_ar": "Bodinayakkanur",
                "label_fr": "Bodinayakkanur"
            },
            {
                "value": 18321,
                "label": "Vasco",
                "label_ar": "فاسكو",
                "label_fr": "Vasco"
            },
            {
                "value": 18020,
                "label": "Ghaziabad",
                "label_ar": "غازي اباد",
                "label_fr": "Ghaziabad"
            },
            {
                "value": 18195,
                "label": "Saranga",
                "label_ar": "سارانجا",
                "label_fr": "Saranga"
            },
            {
                "value": 18722,
                "label": "Sojat",
                "label_ar": "سوجات",
                "label_fr": "Sojat"
            },
            {
                "value": 18710,
                "label": "Kharar",
                "label_ar": "الخرار",
                "label_fr": "Kharar"
            },
            {
                "value": 18022,
                "label": "Meerut",
                "label_ar": "ميروت",
                "label_fr": "Meerut"
            },
            {
                "value": 18838,
                "label": "Hoshangabad",
                "label_ar": "هوشانج آباد",
                "label_fr": "Hoshangabad"
            },
            {
                "value": 18680,
                "label": "Igatpuri",
                "label_ar": "إيغاتبوري",
                "label_fr": "Igatpuri"
            },
            {
                "value": 18645,
                "label": "Jhargram",
                "label_ar": "جارجرام",
                "label_fr": "Jhargram"
            },
            {
                "value": 18061,
                "label": "Erode",
                "label_ar": "تقلص",
                "label_fr": "Éroder"
            },
            {
                "value": 18265,
                "label": "Janjgir",
                "label_ar": "جنجير",
                "label_fr": "Janjgir"
            },
            {
                "value": 18345,
                "label": "Antapur",
                "label_ar": "أنتابور",
                "label_fr": "Antapur"
            },
            {
                "value": 18364,
                "label": "Yadgir",
                "label_ar": "يادغير",
                "label_fr": "Yadgir"
            },
            {
                "value": 18665,
                "label": "Nurpur",
                "label_ar": "نوربور",
                "label_fr": "Nurpur"
            },
            {
                "value": 18700,
                "label": "Pattukkottai",
                "label_ar": "باتوكوتاي",
                "label_fr": "Pattukkottai"
            },
            {
                "value": 18802,
                "label": "Shamsabad",
                "label_ar": "شمس آباد",
                "label_fr": "Shamsabad"
            },
            {
                "value": 18718,
                "label": "Aldona",
                "label_ar": "ألدونا",
                "label_fr": "Aldona"
            },
            {
                "value": 18679,
                "label": "Changanacheri",
                "label_ar": "Changanacheri",
                "label_fr": "Changanacheri"
            },
            {
                "value": 18162,
                "label": "Mohan",
                "label_ar": "موهان",
                "label_fr": "Mohan"
            },
            {
                "value": 18276,
                "label": "Tiruppur",
                "label_ar": "تيروبور",
                "label_fr": "Tiruppur"
            },
            {
                "value": 18851,
                "label": "Hazaribagh",
                "label_ar": "هازاريباغ",
                "label_fr": "Hazaribagh"
            },
            {
                "value": 18054,
                "label": "Patna",
                "label_ar": "باتنا",
                "label_fr": "Patna"
            },
            {
                "value": 18698,
                "label": "Payyanur",
                "label_ar": "بايانور",
                "label_fr": "Payyanur"
            },
            {
                "value": 18384,
                "label": "Chinchvad",
                "label_ar": "تشينشفاد",
                "label_fr": "Chinchvad"
            },
            {
                "value": 18015,
                "label": "Kolkata",
                "label_ar": "كولكاتا",
                "label_fr": "Calcutta"
            },
            {
                "value": 18352,
                "label": "Betim",
                "label_ar": "بيتيم",
                "label_fr": "Betim"
            },
            {
                "value": 18584,
                "label": "Koppal",
                "label_ar": "كوبال",
                "label_fr": "Koppal"
            },
            {
                "value": 19009,
                "label": "Perambalur",
                "label_ar": "بيرامبالور",
                "label_fr": "Perambalur"
            },
            {
                "value": 18327,
                "label": "Ladwa",
                "label_ar": "لادوا",
                "label_fr": "Ladwa"
            },
            {
                "value": 18876,
                "label": "Sachin",
                "label_ar": "ساشين",
                "label_fr": "Sachin"
            },
            {
                "value": 18044,
                "label": "Sriperumbudur",
                "label_ar": "سريبيرومبودور",
                "label_fr": "Sriperumbudur"
            },
            {
                "value": 18042,
                "label": "Shahjahanpur",
                "label_ar": "شاهجاهانبور",
                "label_fr": "Shahjahanpur"
            },
            {
                "value": 18781,
                "label": "Gadag",
                "label_ar": "جادج",
                "label_fr": "Gadag"
            },
            {
                "value": 18150,
                "label": "Thanjavur",
                "label_ar": "ثانجافور",
                "label_fr": "Thanjavur"
            },
            {
                "value": 18850,
                "label": "Shrigonda",
                "label_ar": "شريجوندا",
                "label_fr": "Shrigonda"
            },
            {
                "value": 18428,
                "label": "Cumbum",
                "label_ar": "كومبوم",
                "label_fr": "Cumbum"
            },
            {
                "value": 18575,
                "label": "Yamunanagar",
                "label_ar": "Yamunanagar",
                "label_fr": "Yamunanagar"
            },
            {
                "value": 18221,
                "label": "Sawi",
                "label_ar": "صاوي",
                "label_fr": "Sawi"
            },
            {
                "value": 18373,
                "label": "Panipat",
                "label_ar": "بانيبات",
                "label_fr": "Panipat"
            },
            {
                "value": 18057,
                "label": "Kanchipuram",
                "label_ar": "كانشيبورام",
                "label_fr": "Kanchipuram"
            },
            {
                "value": 18371,
                "label": "Kumar",
                "label_ar": "كومار",
                "label_fr": "Kumar"
            },
            {
                "value": 18477,
                "label": "Muzaffarnagar",
                "label_ar": "مظفرناجار",
                "label_fr": "Muzaffarnagar"
            },
            {
                "value": 18155,
                "label": "Gandhigram",
                "label_ar": "غانديغرام",
                "label_fr": "Gandhigram"
            },
            {
                "value": 18133,
                "label": "Kushalnagar",
                "label_ar": "كوشالناغار",
                "label_fr": "Kushalnagar"
            },
            {
                "value": 18383,
                "label": "Haridwar",
                "label_ar": "هاريدوار",
                "label_fr": "Haridwar"
            },
            {
                "value": 18854,
                "label": "Nabadwip",
                "label_ar": "النبادوية",
                "label_fr": "Nabadwip"
            },
            {
                "value": 18915,
                "label": "Nanda",
                "label_ar": "ناندا",
                "label_fr": "Nanda"
            },
            {
                "value": 18074,
                "label": "Roorkee",
                "label_ar": "روركي",
                "label_fr": "Roorkee"
            },
            {
                "value": 18684,
                "label": "Chidambaram",
                "label_ar": "تشيدامبارام",
                "label_fr": "Chidambaram"
            },
            {
                "value": 18125,
                "label": "Nashik",
                "label_ar": "ناشيك",
                "label_fr": "Nashik"
            },
            {
                "value": 18376,
                "label": "Tiruvalla",
                "label_ar": "تيروفالا",
                "label_fr": "Tiruvalla"
            },
            {
                "value": 18440,
                "label": "Shiv",
                "label_ar": "شيف",
                "label_fr": "Shiv"
            },
            {
                "value": 18638,
                "label": "Kishangarh",
                "label_ar": "كيشانجاره",
                "label_fr": "Kishangarh"
            },
            {
                "value": 18806,
                "label": "Trimbak",
                "label_ar": "تريمباك",
                "label_fr": "Trimbak"
            },
            {
                "value": 18727,
                "label": "Kurali",
                "label_ar": "كورالي",
                "label_fr": "Kurali"
            },
            {
                "value": 18708,
                "label": "Budbud",
                "label_ar": "بودبود",
                "label_fr": "Budbud"
            },
            {
                "value": 18574,
                "label": "Pehowa",
                "label_ar": "بيهوا",
                "label_fr": "Pehowa"
            },
            {
                "value": 18513,
                "label": "Chandannagar",
                "label_ar": "شانداناغار",
                "label_fr": "Chandannagar"
            },
            {
                "value": 18227,
                "label": "Rohtak",
                "label_ar": "روهتك",
                "label_fr": "Rohtak"
            },
            {
                "value": 18011,
                "label": "Vadodara",
                "label_ar": "فادودارا",
                "label_fr": "Vadodara"
            },
            {
                "value": 18119,
                "label": "Dhule",
                "label_ar": "Dhule",
                "label_fr": "Dhule"
            },
            {
                "value": 18311,
                "label": "Uttarkashi",
                "label_ar": "أوتاركاشي",
                "label_fr": "Uttarkashi"
            },
            {
                "value": 18762,
                "label": "Jaisalmer",
                "label_ar": "جايسالمير",
                "label_fr": "Jaisalmer"
            },
            {
                "value": 18737,
                "label": "Alangulam",
                "label_ar": "الانجولام",
                "label_fr": "Alangulam"
            },
            {
                "value": 18556,
                "label": "Narnaul",
                "label_ar": "نارنول",
                "label_fr": "Narnaul"
            },
            {
                "value": 18998,
                "label": "Mangaon",
                "label_ar": "مانجاون",
                "label_fr": "Mangaon"
            },
            {
                "value": 18069,
                "label": "Vellore",
                "label_ar": "فيلور",
                "label_fr": "Vellore"
            },
            {
                "value": 18246,
                "label": "Sambalpur",
                "label_ar": "سامبالبور",
                "label_fr": "Sambalpur"
            },
            {
                "value": 18252,
                "label": "Dahanu",
                "label_ar": "داهانو",
                "label_fr": "Dahanu"
            },
            {
                "value": 18204,
                "label": "Kalinga",
                "label_ar": "كالينجا",
                "label_fr": "Kalinga"
            },
            {
                "value": 18473,
                "label": "Palani",
                "label_ar": "بالاني",
                "label_fr": "Palani"
            },
            {
                "value": 18531,
                "label": "Kalpakkam",
                "label_ar": "كالباكام",
                "label_fr": "Kalpakkam"
            },
            {
                "value": 18337,
                "label": "Thoothukudi",
                "label_ar": "ثوثوكودي",
                "label_fr": "Thoothukudi"
            },
            {
                "value": 18481,
                "label": "Manjeri",
                "label_ar": "مانجيري",
                "label_fr": "Manjeri"
            },
            {
                "value": 18989,
                "label": "Guna",
                "label_ar": "جونا",
                "label_fr": "Guna"
            },
            {
                "value": 18987,
                "label": "Matar",
                "label_ar": "مطر",
                "label_fr": "Matar"
            },
            {
                "value": 18469,
                "label": "Talegaon Dabhade",
                "label_ar": "تاليجاون دابهادي",
                "label_fr": "Talegaon Dabhade"
            },
            {
                "value": 18405,
                "label": "Kotian",
                "label_ar": "كوتيان",
                "label_fr": "Kotian"
            },
            {
                "value": 18810,
                "label": "Mandla",
                "label_ar": "ماندلا",
                "label_fr": "Mandla"
            },
            {
                "value": 19011,
                "label": "Tiruchchendur",
                "label_ar": "تيروتشيندور",
                "label_fr": "Tiruchchendur"
            },
            {
                "value": 18499,
                "label": "Murshidabad",
                "label_ar": "مرشد اباد",
                "label_fr": "Murshidabad"
            },
            {
                "value": 18328,
                "label": "Rajpura",
                "label_ar": "راجبورا",
                "label_fr": "Rajpura"
            },
            {
                "value": 18025,
                "label": "Jabalpur",
                "label_ar": "جابالبور",
                "label_fr": "Jabalpur"
            },
            {
                "value": 18060,
                "label": "Karur",
                "label_ar": "كارور",
                "label_fr": "Karur"
            },
            {
                "value": 18410,
                "label": "Paravur",
                "label_ar": "بارافور",
                "label_fr": "Paravur"
            },
            {
                "value": 18242,
                "label": "Bali",
                "label_ar": "بالي",
                "label_fr": "Bali"
            },
            {
                "value": 18414,
                "label": "Jajpur",
                "label_ar": "جاجبور",
                "label_fr": "Jajpur"
            },
            {
                "value": 18796,
                "label": "Rudrapur",
                "label_ar": "رودرابور",
                "label_fr": "Rudrapur"
            },
            {
                "value": 18988,
                "label": "Sukma",
                "label_ar": "سوكما",
                "label_fr": "Sukma"
            },
            {
                "value": 18748,
                "label": "Bela",
                "label_ar": "بيلا",
                "label_fr": "Bela"
            },
            {
                "value": 18890,
                "label": "Kottagudem",
                "label_ar": "Kottagudem",
                "label_fr": "Kottagudem"
            },
            {
                "value": 18145,
                "label": "Thrissur",
                "label_ar": "ثريسور",
                "label_fr": "Thrissur"
            },
            {
                "value": 18286,
                "label": "Aizawl",
                "label_ar": "ايزاول",
                "label_fr": "Aizawl"
            },
            {
                "value": 18273,
                "label": "Bahadurgarh",
                "label_ar": "بهادورغرة",
                "label_fr": "Bahadurgarh"
            },
            {
                "value": 19018,
                "label": "Sangam",
                "label_ar": "سانجام",
                "label_fr": "Sangam"
            },
            {
                "value": 18087,
                "label": "Tirumala - Tirupati",
                "label_ar": "تيرومالا - تيروباتي",
                "label_fr": "Tirumala - Tirupati"
            },
            {
                "value": 18241,
                "label": "Khopoli",
                "label_ar": "خوبولي",
                "label_fr": "Khopoli"
            },
            {
                "value": 18356,
                "label": "Caranzalem",
                "label_ar": "كرانزالم",
                "label_fr": "Caranzalem"
            },
            {
                "value": 18270,
                "label": "Mahabalipuram",
                "label_ar": "ماهاباليبورام",
                "label_fr": "Mahabalipuram"
            },
            {
                "value": 18349,
                "label": "Hingoli",
                "label_ar": "هينجولي",
                "label_fr": "Hingoli"
            },
            {
                "value": 18174,
                "label": "Bhubaneswar",
                "label_ar": "بوبانسوار",
                "label_fr": "Bhubaneswar"
            },
            {
                "value": 18955,
                "label": "Basu",
                "label_ar": "باسو",
                "label_fr": "Basu"
            },
            {
                "value": 18931,
                "label": "Balu",
                "label_ar": "بالو",
                "label_fr": "Balu"
            },
            {
                "value": 18623,
                "label": "Arakkonam",
                "label_ar": "اركونام",
                "label_fr": "Arakkonam"
            },
            {
                "value": 18634,
                "label": "Shahdol",
                "label_ar": "شهدول",
                "label_fr": "Shahdol"
            },
            {
                "value": 18330,
                "label": "Hoshiarpur",
                "label_ar": "هوشياربور",
                "label_fr": "Hoshiarpur"
            },
            {
                "value": 18844,
                "label": "Attur",
                "label_ar": "أتور",
                "label_fr": "Attur"
            },
            {
                "value": 18186,
                "label": "Barddhaman",
                "label_ar": "بردهمان",
                "label_fr": "Barddhaman"
            },
            {
                "value": 18487,
                "label": "Angul",
                "label_ar": "أنجول",
                "label_fr": "Angul"
            },
            {
                "value": 18463,
                "label": "Hapur",
                "label_ar": "هابر",
                "label_fr": "Hapur"
            },
            {
                "value": 19019,
                "label": "Katoya",
                "label_ar": "كاتويا",
                "label_fr": "Katoya"
            },
            {
                "value": 18067,
                "label": "Nalbari",
                "label_ar": "نالباري",
                "label_fr": "Nalbari"
            },
            {
                "value": 18226,
                "label": "Bhilai",
                "label_ar": "بهلاي",
                "label_fr": "Bhilai"
            },
            {
                "value": 18228,
                "label": "Haryana",
                "label_ar": "هاريانا",
                "label_fr": "Haryana"
            },
            {
                "value": 18908,
                "label": "Shahapur",
                "label_ar": "شهابور",
                "label_fr": "Shahapur"
            },
            {
                "value": 18041,
                "label": "Mathura",
                "label_ar": "ماثورا",
                "label_fr": "Mathura"
            },
            {
                "value": 18293,
                "label": "Bhongir",
                "label_ar": "بهونجير",
                "label_fr": "Bhongir"
            },
            {
                "value": 18194,
                "label": "Agartala",
                "label_ar": "اجارتالا",
                "label_fr": "Agartala"
            },
            {
                "value": 18618,
                "label": "North Lakhimpur",
                "label_ar": "شمال لاكيمبور",
                "label_fr": "Nord Lakhimpur"
            },
            {
                "value": 18157,
                "label": "Annamalainagar",
                "label_ar": "Annamalainagar",
                "label_fr": "Annamalainagar"
            },
            {
                "value": 18032,
                "label": "Patiala",
                "label_ar": "باتيالا",
                "label_fr": "Patiala"
            },
            {
                "value": 18368,
                "label": "Paonta Sahib",
                "label_ar": "باونتا صاحب",
                "label_fr": "Paonta Sahib"
            },
            {
                "value": 18782,
                "label": "Chikmagalūr",
                "label_ar": "شيكماغالور",
                "label_fr": "Chikmagalūr"
            },
            {
                "value": 18867,
                "label": "Mashobra",
                "label_ar": "مشوبرا",
                "label_fr": "Mashobra"
            },
            {
                "value": 18253,
                "label": "Silvassa",
                "label_ar": "سيلفاسا",
                "label_fr": "Silvassa"
            },
            {
                "value": 18803,
                "label": "Kandi",
                "label_ar": "كاندي",
                "label_fr": "Kandi"
            },
            {
                "value": 18290,
                "label": "Chen",
                "label_ar": "تشين",
                "label_fr": "Chen"
            },
            {
                "value": 18811,
                "label": "Kalan",
                "label_ar": "كالان",
                "label_fr": "Kalan"
            },
            {
                "value": 18213,
                "label": "Ajmer",
                "label_ar": "أجمر",
                "label_fr": "Ajmer"
            },
            {
                "value": 18937,
                "label": "Jha Jha",
                "label_ar": "جها جها",
                "label_fr": "Jha Jha"
            },
            {
                "value": 18326,
                "label": "Kalka",
                "label_ar": "كالكا",
                "label_fr": "Kalka"
            },
            {
                "value": 18951,
                "label": "Khalapur",
                "label_ar": "خلابور",
                "label_fr": "Khalapur"
            },
            {
                "value": 18468,
                "label": "Sendhwa",
                "label_ar": "سيندهوا",
                "label_fr": "Sendhwa"
            },
            {
                "value": 18338,
                "label": "Sivakasi",
                "label_ar": "سيفاكاسي",
                "label_fr": "Sivakasi"
            },
            {
                "value": 18818,
                "label": "Botad",
                "label_ar": "بوتاد",
                "label_fr": "Botad"
            },
            {
                "value": 18283,
                "label": "Hansi",
                "label_ar": "هانسي",
                "label_fr": "Hansi"
            },
            {
                "value": 18983,
                "label": "Kolayat",
                "label_ar": "القليعات",
                "label_fr": "Kolayat"
            },
            {
                "value": 18703,
                "label": "Bhatkal",
                "label_ar": "بهاتكال",
                "label_fr": "Bhatkal"
            },
            {
                "value": 18776,
                "label": "Umred",
                "label_ar": "اومريد",
                "label_fr": "Umred"
            },
            {
                "value": 18454,
                "label": "Wai",
                "label_ar": "واي",
                "label_fr": "Wai"
            },
            {
                "value": 18504,
                "label": "Srikalahasti",
                "label_ar": "سريكالاهاستي",
                "label_fr": "Srikalahasti"
            },
            {
                "value": 18726,
                "label": "Sirhind",
                "label_ar": "سرهيند",
                "label_fr": "Sirhind"
            },
            {
                "value": 19016,
                "label": "Kaniyambadi",
                "label_ar": "كانيامبادي",
                "label_fr": "Kaniyambadi"
            },
            {
                "value": 18660,
                "label": "Srivilliputhur",
                "label_ar": "سريفيليبوثر",
                "label_fr": "Srivilliputhur"
            },
            {
                "value": 18464,
                "label": "Kalpetta",
                "label_ar": "كالبيتا",
                "label_fr": "Kalpetta"
            },
            {
                "value": 18046,
                "label": "Ludhiana",
                "label_ar": "لوديانا",
                "label_fr": "Ludhiana"
            },
            {
                "value": 18981,
                "label": "Bali",
                "label_ar": "بالي",
                "label_fr": "Bali"
            },
            {
                "value": 18023,
                "label": "Agra",
                "label_ar": "اجرا",
                "label_fr": "Agra"
            },
            {
                "value": 18223,
                "label": "Saharanpur",
                "label_ar": "Saharanpur",
                "label_fr": "Saharanpur"
            },
            {
                "value": 18678,
                "label": "Saligao",
                "label_ar": "ساليجاو",
                "label_fr": "Saligao"
            },
            {
                "value": 18274,
                "label": "Kaithal",
                "label_ar": "كايثال",
                "label_fr": "Kaithal"
            },
            {
                "value": 18733,
                "label": "Medarametla",
                "label_ar": "ميداراميتلا",
                "label_fr": "Medarametla"
            },
            {
                "value": 18742,
                "label": "Ambarnath",
                "label_ar": "أمبارناث",
                "label_fr": "Ambarnath"
            },
            {
                "value": 18792,
                "label": "Thirumangalam",
                "label_ar": "ثيرومانغالام",
                "label_fr": "Thirumangalam"
            },
            {
                "value": 18452,
                "label": "Kalol",
                "label_ar": "كالول",
                "label_fr": "Kalol"
            },
            {
                "value": 18913,
                "label": "Chandan",
                "label_ar": "تشاندان",
                "label_fr": "Chandan"
            },
            {
                "value": 18522,
                "label": "Shyamnagar",
                "label_ar": "شيامناغار",
                "label_fr": "Shyamnagar"
            },
            {
                "value": 18900,
                "label": "Raju",
                "label_ar": "راجو",
                "label_fr": "Raju"
            },
            {
                "value": 18203,
                "label": "Durgapur",
                "label_ar": "دورجابور",
                "label_fr": "Durgapur"
            },
            {
                "value": 18559,
                "label": "Dhrol",
                "label_ar": "درهم",
                "label_fr": "Dhrol"
            },
            {
                "value": 18136,
                "label": "Bidar",
                "label_ar": "بيدار",
                "label_fr": "Bidar"
            },
            {
                "value": 18984,
                "label": "Haripur",
                "label_ar": "هاريبور",
                "label_fr": "Haripur"
            },
            {
                "value": 18948,
                "label": "Rajapur",
                "label_ar": "راجابور",
                "label_fr": "Rajapur"
            },
            {
                "value": 18470,
                "label": "Kargil",
                "label_ar": "كارجيل",
                "label_fr": "Kargil"
            },
            {
                "value": 18491,
                "label": "Ambur",
                "label_ar": "أمبور",
                "label_fr": "Ambur"
            },
            {
                "value": 18402,
                "label": "Ghana",
                "label_ar": "غانا",
                "label_fr": "Ghana"
            },
            {
                "value": 18963,
                "label": "Nagari",
                "label_ar": "Nagari",
                "label_fr": "Nagari"
            },
            {
                "value": 18098,
                "label": "Secunderabad",
                "label_ar": "سيكوندرأباد",
                "label_fr": "Secunderabad"
            },
            {
                "value": 18957,
                "label": "Medchal",
                "label_ar": "ميدشال",
                "label_fr": "Medchal"
            },
            {
                "value": 18093,
                "label": "Potti",
                "label_ar": "بوتي",
                "label_fr": "Potti"
            },
            {
                "value": 18064,
                "label": "Haldwani",
                "label_ar": "هالدواني",
                "label_fr": "Haldwani"
            },
            {
                "value": 18887,
                "label": "Kamat",
                "label_ar": "كامات",
                "label_fr": "Kamat"
            },
            {
                "value": 18631,
                "label": "Marmagao",
                "label_ar": "مارماغاو",
                "label_fr": "Marmagao"
            },
            {
                "value": 18455,
                "label": "Shirgaon",
                "label_ar": "شيرجاون",
                "label_fr": "Shirgaon"
            },
            {
                "value": 18435,
                "label": "Guindy",
                "label_ar": "جويندي",
                "label_fr": "Guindy"
            },
            {
                "value": 18652,
                "label": "Mannargudi",
                "label_ar": "مانارجودي",
                "label_fr": "Mannargudi"
            },
            {
                "value": 18705,
                "label": "Kulti",
                "label_ar": "كولتي",
                "label_fr": "Kulti"
            },
            {
                "value": 18144,
                "label": "Kozhikode",
                "label_ar": "كوزيكود",
                "label_fr": "Kozhikode"
            },
            {
                "value": 18088,
                "label": "Nizamabad",
                "label_ar": "نظام أباد",
                "label_fr": "Nizamabad"
            },
            {
                "value": 18604,
                "label": "Guruvayur",
                "label_ar": "جوروفايور",
                "label_fr": "Guruvayur"
            },
            {
                "value": 18709,
                "label": "Balangir",
                "label_ar": "بالانجير",
                "label_fr": "Balangir"
            },
            {
                "value": 18370,
                "label": "Anantapur",
                "label_ar": "أنانتابور",
                "label_fr": "Anantapur"
            },
            {
                "value": 18739,
                "label": "Chiplun",
                "label_ar": "تشبلون",
                "label_fr": "Chiplun"
            },
            {
                "value": 18735,
                "label": "Yercaud",
                "label_ar": "يركود",
                "label_fr": "Yercaud"
            },
            {
                "value": 18868,
                "label": "Rama",
                "label_ar": "راما",
                "label_fr": "Rama"
            },
            {
                "value": 18964,
                "label": "Kangayam",
                "label_ar": "كانغايام",
                "label_fr": "Kangayam"
            },
            {
                "value": 18982,
                "label": "Nilokheri",
                "label_ar": "نيلوخيري",
                "label_fr": "Nilokheri"
            },
            {
                "value": 18340,
                "label": "Shimoga",
                "label_ar": "شيموجا",
                "label_fr": "Shimoga"
            },
            {
                "value": 18774,
                "label": "Pandharpur",
                "label_ar": "باندهاربور",
                "label_fr": "Pandharpur"
            },
            {
                "value": 18346,
                "label": "Tanuku",
                "label_ar": "تانوكو",
                "label_fr": "Tanuku"
            },
            {
                "value": 18859,
                "label": "Kollegal",
                "label_ar": "كوليجال",
                "label_fr": "Kollegal"
            },
            {
                "value": 18101,
                "label": "Miraj",
                "label_ar": "ميراج",
                "label_fr": "Miraj"
            },
            {
                "value": 18940,
                "label": "Amal",
                "label_ar": "أمل",
                "label_fr": "Amal"
            },
            {
                "value": 18902,
                "label": "Parel",
                "label_ar": "باريل",
                "label_fr": "Parel"
            },
            {
                "value": 18970,
                "label": "Fatehgarh",
                "label_ar": "فاتحجاره",
                "label_fr": "Fatehgarh"
            },
            {
                "value": 18611,
                "label": "Ramanagaram",
                "label_ar": "راماناغارام",
                "label_fr": "Ramanagaram"
            },
            {
                "value": 18102,
                "label": "Nanded",
                "label_ar": "نانديد",
                "label_fr": "Nanded"
            },
            {
                "value": 18006,
                "label": "Delhi",
                "label_ar": "دلهي",
                "label_fr": "Delhi"
            },
            {
                "value": 18264,
                "label": "Satara",
                "label_ar": "ساتارا",
                "label_fr": "Satara"
            },
            {
                "value": 18094,
                "label": "Nellore",
                "label_ar": "نيلور",
                "label_fr": "Nellore"
            },
            {
                "value": 18202,
                "label": "Jalpaiguri",
                "label_ar": "جالبايجوري",
                "label_fr": "Jalpaiguri"
            },
            {
                "value": 18462,
                "label": "Narayangaon",
                "label_ar": "نارايانجاون",
                "label_fr": "Narayangaon"
            },
            {
                "value": 18882,
                "label": "Samastipur",
                "label_ar": "ساماستيبور",
                "label_fr": "Samastipur"
            },
            {
                "value": 18780,
                "label": "Peddapuram",
                "label_ar": "بيدابورام",
                "label_fr": "Peddapuram"
            },
            {
                "value": 18712,
                "label": "Mansa",
                "label_ar": "مانسا",
                "label_fr": "Mansa"
            },
            {
                "value": 18942,
                "label": "Abdul",
                "label_ar": "عبد",
                "label_fr": "Abdul"
            },
            {
                "value": 18805,
                "label": "Anchal",
                "label_ar": "انشال",
                "label_fr": "Anchal"
            },
            {
                "value": 18419,
                "label": "Khammam",
                "label_ar": "خمّام",
                "label_fr": "Khammam"
            },
            {
                "value": 18819,
                "label": "Sidhpur",
                "label_ar": "سيدهبور",
                "label_fr": "Sidhpur"
            },
            {
                "value": 18950,
                "label": "Sakri",
                "label_ar": "صكري",
                "label_fr": "Sakri"
            },
            {
                "value": 18507,
                "label": "Bokaro",
                "label_ar": "بوكارو",
                "label_fr": "Bokaro"
            },
            {
                "value": 18278,
                "label": "Jalandhar",
                "label_ar": "جالاندهار",
                "label_fr": "Jalandhar"
            },
            {
                "value": 18193,
                "label": "Medinipur",
                "label_ar": "ميدينيبور",
                "label_fr": "Medinipur"
            },
            {
                "value": 18977,
                "label": "Pantnagar",
                "label_ar": "بانتناغار",
                "label_fr": "Pantnagar"
            },
            {
                "value": 18297,
                "label": "Vijayawada",
                "label_ar": "فيجاياوادا",
                "label_fr": "Vijayawada"
            },
            {
                "value": 18281,
                "label": "Ratnagiri",
                "label_ar": "راتناجيري",
                "label_fr": "Ratnagiri"
            },
            {
                "value": 18646,
                "label": "Dimapur",
                "label_ar": "ديمابور",
                "label_fr": "Dimapur"
            },
            {
                "value": 18723,
                "label": "Alanallur",
                "label_ar": "الانالور",
                "label_fr": "Alanallur"
            },
            {
                "value": 18082,
                "label": "Bilaspur",
                "label_ar": "بيلاسبور",
                "label_fr": "Bilaspur"
            },
            {
                "value": 18379,
                "label": "Narasaraopet",
                "label_ar": "ناراساراوبت",
                "label_fr": "Narasaraopet"
            },
            {
                "value": 18374,
                "label": "Uppal",
                "label_ar": "أوبال",
                "label_fr": "Uppal"
            },
            {
                "value": 18140,
                "label": "Kolar",
                "label_ar": "كولار",
                "label_fr": "Kolar"
            },
            {
                "value": 18182,
                "label": "Darbhanga",
                "label_ar": "داربهانجا",
                "label_fr": "Darbhanga"
            },
            {
                "value": 18331,
                "label": "Katni",
                "label_ar": "كاتني",
                "label_fr": "Katni"
            },
            {
                "value": 18571,
                "label": "Champa",
                "label_ar": "تشامبا",
                "label_fr": "Champa"
            },
            {
                "value": 18785,
                "label": "Chintamani",
                "label_ar": "Chintamani",
                "label_fr": "Chintamani"
            },
            {
                "value": 18100,
                "label": "Bharuch",
                "label_ar": "بهاروش",
                "label_fr": "Bharuch"
            },
            {
                "value": 18170,
                "label": "Shillong",
                "label_ar": "شيلونج",
                "label_fr": "Shillong"
            },
            {
                "value": 18786,
                "label": "Tambaram",
                "label_ar": "تامبارام",
                "label_fr": "Tambaram"
            },
            {
                "value": 18909,
                "label": "Banka",
                "label_ar": "بانكا",
                "label_fr": "Banka"
            },
            {
                "value": 19007,
                "label": "Proddatur",
                "label_ar": "بروداتور",
                "label_fr": "Proddatur"
            },
            {
                "value": 18856,
                "label": "Nazira",
                "label_ar": "نظيرة",
                "label_fr": "Nazira"
            },
            {
                "value": 18090,
                "label": "Kuppam",
                "label_ar": "كوبام",
                "label_fr": "Kuppam"
            },
            {
                "value": 18823,
                "label": "Vadamadurai",
                "label_ar": "فادامادوراي",
                "label_fr": "Vadamadurai"
            },
            {
                "value": 18485,
                "label": "Tirur",
                "label_ar": "تيرور",
                "label_fr": "Tirur"
            },
            {
                "value": 18528,
                "label": "Kanchrapara",
                "label_ar": "كانشرابارا",
                "label_fr": "Kanchrapara"
            },
            {
                "value": 18834,
                "label": "Dinanagar",
                "label_ar": "ديناناغار",
                "label_fr": "Dinanagar"
            },
            {
                "value": 18930,
                "label": "Sarwar",
                "label_ar": "سروار",
                "label_fr": "Sarwar"
            },
            {
                "value": 18632,
                "label": "Sanquelim",
                "label_ar": "سانكوليم",
                "label_fr": "Sanquelim"
            },
            {
                "value": 18685,
                "label": "Chittur",
                "label_ar": "شيتور",
                "label_fr": "Chittur"
            },
            {
                "value": 18572,
                "label": "Ambikapur",
                "label_ar": "أمبيكابور",
                "label_fr": "Ambikapur"
            },
            {
                "value": 18354,
                "label": "Ahmednagar",
                "label_ar": "احمد النجار",
                "label_fr": "Ahmednagar"
            },
            {
                "value": 18163,
                "label": "Salem",
                "label_ar": "سالم",
                "label_fr": "Salem"
            },
            {
                "value": 18075,
                "label": "Aligarh",
                "label_ar": "عليكرة",
                "label_fr": "Aligarh"
            },
            {
                "value": 18206,
                "label": "Jammu",
                "label_ar": "جامو",
                "label_fr": "Jammu"
            },
            {
                "value": 18601,
                "label": "Gujrat",
                "label_ar": "جوجرات",
                "label_fr": "Gujrat"
            },
            {
                "value": 18658,
                "label": "Sivaganga",
                "label_ar": "سيفاجانجا",
                "label_fr": "Sivaganga"
            },
            {
                "value": 18488,
                "label": "Puri",
                "label_ar": "بوري",
                "label_fr": "Puri"
            },
            {
                "value": 18873,
                "label": "Chitradurga",
                "label_ar": "شيترادورجا",
                "label_fr": "Chitradurga"
            },
            {
                "value": 18137,
                "label": "Belgaum",
                "label_ar": "بلجاوم",
                "label_fr": "Belgaum"
            },
            {
                "value": 18624,
                "label": "Tiruvannamalai",
                "label_ar": "تيروفانامالاي",
                "label_fr": "Tiruvannamalai"
            },
            {
                "value": 18917,
                "label": "Kasal",
                "label_ar": "كاسال",
                "label_fr": "Kasal"
            },
            {
                "value": 18229,
                "label": "Ambala",
                "label_ar": "أمبالا",
                "label_fr": "Ambala"
            },
            {
                "value": 19008,
                "label": "Puliyur",
                "label_ar": "بوليور",
                "label_fr": "Puliyur"
            },
            {
                "value": 18187,
                "label": "Jadabpur",
                "label_ar": "جادابور",
                "label_fr": "Jadabpur"
            },
            {
                "value": 18447,
                "label": "Saha",
                "label_ar": "ساها",
                "label_fr": "Saha"
            },
            {
                "value": 18596,
                "label": "Deoria",
                "label_ar": "ديوريا",
                "label_fr": "Deoria"
            },
            {
                "value": 18581,
                "label": "Sirsi",
                "label_ar": "سيرسي",
                "label_fr": "Sirsi"
            },
            {
                "value": 18148,
                "label": "Kannur",
                "label_ar": "كانور",
                "label_fr": "Kannur"
            },
            {
                "value": 18972,
                "label": "Naini",
                "label_ar": "نايني",
                "label_fr": "Naini"
            },
            {
                "value": 18184,
                "label": "Bhagalpur",
                "label_ar": "بهاجالبور",
                "label_fr": "Bhagalpur"
            },
            {
                "value": 18444,
                "label": "Palghar",
                "label_ar": "بالغر",
                "label_fr": "Palghar"
            },
            {
                "value": 18899,
                "label": "Hong",
                "label_ar": "هونغ",
                "label_fr": "Hong"
            },
            {
                "value": 18234,
                "label": "Pusa",
                "label_ar": "بوسا",
                "label_fr": "Pusa"
            },
            {
                "value": 18431,
                "label": "Rander",
                "label_ar": "راندر",
                "label_fr": "Rander"
            },
            {
                "value": 18219,
                "label": "Jagdalpur",
                "label_ar": "جاغدالبور",
                "label_fr": "Jagdalpur"
            },
            {
                "value": 18406,
                "label": "Khan",
                "label_ar": "خان",
                "label_fr": "Khan"
            },
            {
                "value": 18146,
                "label": "Madurai",
                "label_ar": "مادوراي",
                "label_fr": "Madurai"
            },
            {
                "value": 18255,
                "label": "Dhansura",
                "label_ar": "ضنسورة",
                "label_fr": "Dhansura"
            },
            {
                "value": 18974,
                "label": "Ajabpur",
                "label_ar": "اجاببور",
                "label_fr": "Ajabpur"
            },
            {
                "value": 18222,
                "label": "Etawah",
                "label_ar": "عطاوة",
                "label_fr": "Etawah"
            },
            {
                "value": 18793,
                "label": "Kodaikanal",
                "label_ar": "كودايكانال",
                "label_fr": "Kodaikanal"
            },
            {
                "value": 18911,
                "label": "Palwal",
                "label_ar": "بالوال",
                "label_fr": "Palwal"
            },
            {
                "value": 18875,
                "label": "Dewas",
                "label_ar": "ديواس",
                "label_fr": "Dewas"
            },
            {
                "value": 18404,
                "label": "Kataria",
                "label_ar": "كاتاريا",
                "label_fr": "Kataria"
            },
            {
                "value": 19022,
                "label": "Baramati",
                "label_ar": "براماتي",
                "label_fr": "Baramati"
            },
            {
                "value": 18401,
                "label": "Surana",
                "label_ar": "سورانا",
                "label_fr": "Surana"
            },
            {
                "value": 18416,
                "label": "Mohala",
                "label_ar": "موهالا",
                "label_fr": "Mohala"
            },
            {
                "value": 18139,
                "label": "Dharwad",
                "label_ar": "ضرواد",
                "label_fr": "Dharwad"
            },
            {
                "value": 18183,
                "label": "Gaya",
                "label_ar": "جايا",
                "label_fr": "Gaya"
            },
            {
                "value": 18367,
                "label": "Kasaragod",
                "label_ar": "كاساراجود",
                "label_fr": "Kasaragod"
            },
            {
                "value": 18630,
                "label": "Kodungallur",
                "label_ar": "كودونجالور",
                "label_fr": "Kodungallur"
            },
            {
                "value": 18608,
                "label": "Vinukonda",
                "label_ar": "فينوكوندا",
                "label_fr": "Vinukonda"
            },
            {
                "value": 18813,
                "label": "Dhamtari",
                "label_ar": "دامتاري",
                "label_fr": "Dhamtari"
            },
            {
                "value": 18432,
                "label": "Siuri",
                "label_ar": "سيوري",
                "label_fr": "Siuri"
            },
            {
                "value": 18978,
                "label": "Dwarahat",
                "label_ar": "دوارهات",
                "label_fr": "Dwarahat"
            },
            {
                "value": 18787,
                "label": "Palayam",
                "label_ar": "بالايام",
                "label_fr": "Palayam"
            },
            {
                "value": 18059,
                "label": "Gwalior",
                "label_ar": "جواليور",
                "label_fr": "Gwalior"
            },
            {
                "value": 18503,
                "label": "Vaniyambadi",
                "label_ar": "فانيامبادي",
                "label_fr": "Vaniyambadi"
            },
            {
                "value": 18629,
                "label": "Edavanna",
                "label_ar": "ادفانا",
                "label_fr": "Edavanna"
            },
            {
                "value": 18673,
                "label": "Irinjalakuda",
                "label_ar": "إرينجالاكودا",
                "label_fr": "Irinjalakuda"
            },
            {
                "value": 19017,
                "label": "Vandalur",
                "label_ar": "فاندالور",
                "label_fr": "Vandalur"
            },
            {
                "value": 18303,
                "label": "Ichalkaranji",
                "label_ar": "إيشالكارانجي",
                "label_fr": "Ichalkaranji"
            },
            {
                "value": 18560,
                "label": "Kailaras",
                "label_ar": "كيلاراس",
                "label_fr": "Kailaras"
            },
            {
                "value": 18009,
                "label": "Nagpur",
                "label_ar": "ناجبور",
                "label_fr": "Nagpur"
            },
            {
                "value": 18185,
                "label": "Kunwar",
                "label_ar": "كونوار",
                "label_fr": "Kunwar"
            },
            {
                "value": 18479,
                "label": "Gampalagudem",
                "label_ar": "جامبالاغوديم",
                "label_fr": "Gampalagudem"
            },
            {
                "value": 18482,
                "label": "Raniganj",
                "label_ar": "رانيغانج",
                "label_fr": "Raniganj"
            },
            {
                "value": 18471,
                "label": "Manali",
                "label_ar": "منالي",
                "label_fr": "Manali"
            },
            {
                "value": 18493,
                "label": "Arch",
                "label_ar": "قوس",
                "label_fr": "Cambre"
            },
            {
                "value": 18393,
                "label": "Pollachi",
                "label_ar": "بولاشي",
                "label_fr": "Pollachi"
            },
            {
                "value": 18282,
                "label": "Moga",
                "label_ar": "موجا",
                "label_fr": "Moga"
            },
            {
                "value": 18310,
                "label": "Bilaspur",
                "label_ar": "بيلاسبور",
                "label_fr": "Bilaspur"
            },
            {
                "value": 18570,
                "label": "Amalapuram",
                "label_ar": "أمالابورام",
                "label_fr": "Amalapuram"
            },
            {
                "value": 18438,
                "label": "Dadri",
                "label_ar": "دادري",
                "label_fr": "Dadri"
            },
            {
                "value": 18361,
                "label": "Shoranur",
                "label_ar": "شورانور",
                "label_fr": "Shoranur"
            },
            {
                "value": 18176,
                "label": "Sundargarh",
                "label_ar": "سوندارجاره",
                "label_fr": "Sundargarh"
            },
            {
                "value": 18189,
                "label": "Cuttack",
                "label_ar": "كوتاك",
                "label_fr": "Cuttack"
            },
            {
                "value": 18409,
                "label": "Vazhakulam",
                "label_ar": "فازاكولام",
                "label_fr": "Vazhakulam"
            },
            {
                "value": 18269,
                "label": "Gandhidham",
                "label_ar": "غانديهام",
                "label_fr": "Gandhidham"
            },
            {
                "value": 18966,
                "label": "Nadia",
                "label_ar": "ناديه",
                "label_fr": "Nadia"
            },
            {
                "value": 18040,
                "label": "Gurgaon",
                "label_ar": "جورجاون",
                "label_fr": "Gurgaon"
            },
            {
                "value": 18731,
                "label": "Malpur",
                "label_ar": "مالبور",
                "label_fr": "Malpur"
            },
            {
                "value": 18976,
                "label": "Iglas",
                "label_ar": "إيغلاس",
                "label_fr": "Iglas"
            },
            {
                "value": 18257,
                "label": "Wardha",
                "label_ar": "وردة",
                "label_fr": "Wardha"
            },
            {
                "value": 18578,
                "label": "Gulbarga",
                "label_ar": "جولبارجا",
                "label_fr": "Gulbarga"
            },
            {
                "value": 18116,
                "label": "Jalgaon Jamod",
                "label_ar": "جالجاون جامود",
                "label_fr": "Jalgaon Jamod"
            },
            {
                "value": 18391,
                "label": "Nagapattinam",
                "label_ar": "ناجاباتينام",
                "label_fr": "Nagapattinam"
            },
            {
                "value": 18566,
                "label": "Mirzapur",
                "label_ar": "ميرزابور",
                "label_fr": "Mirzapur"
            },
            {
                "value": 18589,
                "label": "Koraput",
                "label_ar": "كورابوت",
                "label_fr": "Koraput"
            },
            {
                "value": 18425,
                "label": "Mahesana",
                "label_ar": "ماهيسانا",
                "label_fr": "Mahesana"
            },
            {
                "value": 18690,
                "label": "Chandausi",
                "label_ar": "شاندوسي",
                "label_fr": "Chandausi"
            },
            {
                "value": 18543,
                "label": "Lakhimpur",
                "label_ar": "لاكيمبور",
                "label_fr": "Lakhimpur"
            },
            {
                "value": 18945,
                "label": "Binavas",
                "label_ar": "بينافاس",
                "label_fr": "Binavas"
            },
            {
                "value": 18869,
                "label": "Chirala",
                "label_ar": "تشيرالا",
                "label_fr": "Chirala"
            },
            {
                "value": 18442,
                "label": "Bhadath",
                "label_ar": "بهادات",
                "label_fr": "Bhadath"
            },
            {
                "value": 18053,
                "label": "Dehradun",
                "label_ar": "دهرادون",
                "label_fr": "Dehradun"
            },
            {
                "value": 18906,
                "label": "Ballabgarh",
                "label_ar": "بالابجاره",
                "label_fr": "Ballabgarh"
            },
            {
                "value": 18828,
                "label": "Ponneri",
                "label_ar": "بونيري",
                "label_fr": "Ponneri"
            },
            {
                "value": 18301,
                "label": "Moradabad",
                "label_ar": "مراد آباد",
                "label_fr": "Moradabad"
            },
            {
                "value": 18333,
                "label": "Jhajjar",
                "label_ar": "ججار",
                "label_fr": "Jhajjar"
            },
            {
                "value": 18099,
                "label": "Mahatma",
                "label_ar": "ذو الروح العالية",
                "label_fr": "Mahatma"
            },
            {
                "value": 18141,
                "label": "Tumkūr",
                "label_ar": "Tumkūr",
                "label_fr": "Tumkūr"
            },
            {
                "value": 18831,
                "label": "Shanti Grama",
                "label_ar": "شانتي جراما",
                "label_fr": "Shanti Grama"
            },
            {
                "value": 18885,
                "label": "Jayanti",
                "label_ar": "جايانتي",
                "label_fr": "Jayanti"
            },
            {
                "value": 18079,
                "label": "Amritsar",
                "label_ar": "أمريتسار",
                "label_fr": "Amritsar"
            },
            {
                "value": 18178,
                "label": "Manipur",
                "label_ar": "مانيبور",
                "label_fr": "Manipur"
            },
            {
                "value": 18280,
                "label": "Kavaratti",
                "label_ar": "كافاراتي",
                "label_fr": "Kavaratti"
            },
            {
                "value": 18514,
                "label": "Nadgaon",
                "label_ar": "Nadgaon",
                "label_fr": "Nadgaon"
            },
            {
                "value": 18664,
                "label": "Fatehabad",
                "label_ar": "فاتحباد",
                "label_fr": "Fatehabad"
            },
            {
                "value": 18458,
                "label": "Latur",
                "label_ar": "لاتور",
                "label_fr": "Latur"
            },
            {
                "value": 18842,
                "label": "Tirumala",
                "label_ar": "تيرومالا",
                "label_fr": "Tirumala"
            },
            {
                "value": 18880,
                "label": "Mehra",
                "label_ar": "ميهرا",
                "label_fr": "Mehra"
            },
            {
                "value": 18533,
                "label": "Konnagar",
                "label_ar": "كوناجار",
                "label_fr": "Konnagar"
            },
            {
                "value": 18214,
                "label": "Lala",
                "label_ar": "لالا",
                "label_fr": "Lala"
            },
            {
                "value": 18649,
                "label": "Motihari",
                "label_ar": "Motihari",
                "label_fr": "Motihari"
            },
            {
                "value": 18826,
                "label": "Perundurai",
                "label_ar": "بيروندوراي",
                "label_fr": "Perundurai"
            },
            {
                "value": 18879,
                "label": "Pilkhuwa",
                "label_ar": "بيلخوا",
                "label_fr": "Pilkhuwa"
            },
            {
                "value": 18243,
                "label": "Bhiwandi",
                "label_ar": "بهيواندي",
                "label_fr": "Bhiwandi"
            },
            {
                "value": 18080,
                "label": "Raipur",
                "label_ar": "رايبور",
                "label_fr": "Raipur"
            },
            {
                "value": 18706,
                "label": "Koch Bihar",
                "label_ar": "كوخ بيهار",
                "label_fr": "Koch Bihar"
            },
            {
                "value": 18605,
                "label": "Chandauli",
                "label_ar": "تشانداولي",
                "label_fr": "Chandauli"
            },
            {
                "value": 18881,
                "label": "Chhachhrauli",
                "label_ar": "Chhachhrauli",
                "label_fr": "Chhachhrauli"
            },
            {
                "value": 18816,
                "label": "Tala",
                "label_ar": "تالا",
                "label_fr": "Tala"
            },
            {
                "value": 18475,
                "label": "Krishnagiri",
                "label_ar": "كريشناجيري",
                "label_fr": "Krishnagiri"
            },
            {
                "value": 18369,
                "label": "Sarangi",
                "label_ar": "سارانجي",
                "label_fr": "Sarangi"
            },
            {
                "value": 18478,
                "label": "Kashipur",
                "label_ar": "كاشيبور",
                "label_fr": "Kashipur"
            },
            {
                "value": 18013,
                "label": "Jalalpur",
                "label_ar": "جلالبور",
                "label_fr": "Jalalpur"
            },
            {
                "value": 18480,
                "label": "Siruseri",
                "label_ar": "سيروسيري",
                "label_fr": "Siruseri"
            },
            {
                "value": 18707,
                "label": "Nongstoin",
                "label_ar": "Nongstoin",
                "label_fr": "Nongstoin"
            },
            {
                "value": 18207,
                "label": "Dwarka",
                "label_ar": "دواركا",
                "label_fr": "Dwarka"
            },
            {
                "value": 18994,
                "label": "Balaghat",
                "label_ar": "بلاغات",
                "label_fr": "Balaghat"
            },
            {
                "value": 18672,
                "label": "Khanna",
                "label_ar": "خانا",
                "label_fr": "Khanna"
            },
            {
                "value": 18024,
                "label": "Jaipur",
                "label_ar": "جايبور",
                "label_fr": "Jaipur"
            },
            {
                "value": 18386,
                "label": "Aurangabad",
                "label_ar": "اورانجاباد",
                "label_fr": "Aurangabad"
            },
            {
                "value": 18443,
                "label": "Ulubari",
                "label_ar": "Ulubari",
                "label_fr": "Ulubari"
            },
            {
                "value": 18849,
                "label": "Rajpur",
                "label_ar": "راجبور",
                "label_fr": "Rajpur"
            },
            {
                "value": 18617,
                "label": "Namrup",
                "label_ar": "نامروب",
                "label_fr": "Namrup"
            },
            {
                "value": 18728,
                "label": "Khinwara",
                "label_ar": "خنوارا",
                "label_fr": "Khinwara"
            },
            {
                "value": 18749,
                "label": "Balana",
                "label_ar": "بالانا",
                "label_fr": "Balana"
            },
            {
                "value": 18109,
                "label": "Patan",
                "label_ar": "باتان",
                "label_fr": "Patan"
            },
            {
                "value": 18744,
                "label": "Mundgod",
                "label_ar": "موندجود",
                "label_fr": "Mundgod"
            },
            {
                "value": 18296,
                "label": "Narsapur",
                "label_ar": "نارسابور",
                "label_fr": "Narsapur"
            },
            {
                "value": 18979,
                "label": "Dasna",
                "label_ar": "دسنا",
                "label_fr": "Dasna"
            },
            {
                "value": 18167,
                "label": "Guwahati",
                "label_ar": "جواهاتي",
                "label_fr": "Guwahati"
            },
            {
                "value": 18388,
                "label": "Kalamboli",
                "label_ar": "كالامبولي",
                "label_fr": "Kalamboli"
            },
            {
                "value": 18529,
                "label": "Goregaon",
                "label_ar": "جوريجاون",
                "label_fr": "Goregaon"
            },
            {
                "value": 18122,
                "label": "Navsari",
                "label_ar": "نافساري",
                "label_fr": "Navsari"
            },
            {
                "value": 18794,
                "label": "Devipattinam",
                "label_ar": "ديفيباتينام",
                "label_fr": "Devipattinam"
            },
            {
                "value": 18256,
                "label": "Muzaffarpur",
                "label_ar": "مظفربور",
                "label_fr": "Muzaffarpur"
            },
            {
                "value": 18971,
                "label": "Bagh",
                "label_ar": "باغ",
                "label_fr": "Bagh"
            },
            {
                "value": 19023,
                "label": "Tohana",
                "label_ar": "Tohana",
                "label_fr": "Tohana"
            },
            {
                "value": 18014,
                "label": "Bhopal",
                "label_ar": "بوبال",
                "label_fr": "Bhopal"
            },
            {
                "value": 18855,
                "label": "Nandurbar",
                "label_ar": "ناندوربار",
                "label_fr": "Nandurbar"
            },
            {
                "value": 18897,
                "label": "Chand",
                "label_ar": "تشاند",
                "label_fr": "Chand"
            },
            {
                "value": 18686,
                "label": "Nilambur",
                "label_ar": "نيلامبور",
                "label_fr": "Nilambur"
            },
            {
                "value": 18756,
                "label": "Azamgarh",
                "label_ar": "عزامجاره",
                "label_fr": "Azamgarh"
            },
            {
                "value": 18891,
                "label": "Suriapet",
                "label_ar": "سوريابت",
                "label_fr": "Suriapet"
            },
            {
                "value": 18233,
                "label": "Sangrur",
                "label_ar": "سانجرور",
                "label_fr": "Sangrur"
            },
            {
                "value": 18304,
                "label": "Devgarh",
                "label_ar": "ديفجاره",
                "label_fr": "Devgarh"
            },
            {
                "value": 18508,
                "label": "Sidhi",
                "label_ar": "سيدهي",
                "label_fr": "Sidhi"
            },
            {
                "value": 18422,
                "label": "Kavali",
                "label_ar": "كافالي",
                "label_fr": "Kavali"
            },
            {
                "value": 18420,
                "label": "Ring",
                "label_ar": "حلقة",
                "label_fr": "Bague"
            },
            {
                "value": 18633,
                "label": "Sakri",
                "label_ar": "صكري",
                "label_fr": "Sakri"
            },
            {
                "value": 18545,
                "label": "Kheri",
                "label_ar": "خيري",
                "label_fr": "Kheri"
            },
            {
                "value": 18772,
                "label": "Dindori",
                "label_ar": "ديندوري",
                "label_fr": "Dindori"
            },
            {
                "value": 18441,
                "label": "Multi",
                "label_ar": "متعددة",
                "label_fr": "Multi"
            },
            {
                "value": 18292,
                "label": "Bhimavaram",
                "label_ar": "بهيمافارام",
                "label_fr": "Bhimavaram"
            },
            {
                "value": 18563,
                "label": "Banda",
                "label_ar": "باندا",
                "label_fr": "Banda"
            },
            {
                "value": 18173,
                "label": "Tezpur",
                "label_ar": "تيزبور",
                "label_fr": "Tezpur"
            },
            {
                "value": 18497,
                "label": "Hugli",
                "label_ar": "هوجلي",
                "label_fr": "Hugli"
            },
            {
                "value": 18347,
                "label": "Eluru",
                "label_ar": "إلورو",
                "label_fr": "Eluru"
            },
            {
                "value": 18209,
                "label": "Udaipur",
                "label_ar": "اودايبور",
                "label_fr": "Udaipur"
            },
            {
                "value": 18430,
                "label": "Phagwara",
                "label_ar": "فاغوارا",
                "label_fr": "Phagwara"
            },
            {
                "value": 18602,
                "label": "Balurghat",
                "label_ar": "بالورغات",
                "label_fr": "Balurghat"
            },
            {
                "value": 19014,
                "label": "Sultan",
                "label_ar": "سلطان",
                "label_fr": "Sultan"
            },
            {
                "value": 18903,
                "label": "Jaynagar",
                "label_ar": "جاينجار",
                "label_fr": "Jaynagar"
            },
            {
                "value": 18217,
                "label": "Jhansi",
                "label_ar": "جانسى",
                "label_fr": "Jhansi"
            },
            {
                "value": 18062,
                "label": "Gorakhpur",
                "label_ar": "جوراخبور",
                "label_fr": "Gorakhpur"
            },
            {
                "value": 18866,
                "label": "Mundra",
                "label_ar": "موندرا",
                "label_fr": "Mundra"
            },
            {
                "value": 18319,
                "label": "Kovvur",
                "label_ar": "كوففور",
                "label_fr": "Kovvur"
            },
            {
                "value": 18421,
                "label": "Maldah",
                "label_ar": "ملدة",
                "label_fr": "Maldah"
            },
            {
                "value": 18721,
                "label": "Koothanallur",
                "label_ar": "كوثانالور",
                "label_fr": "Koothanallur"
            },
            {
                "value": 18766,
                "label": "Ambah",
                "label_ar": "أمبه",
                "label_fr": "Ambah"
            },
            {
                "value": 18587,
                "label": "Jalna",
                "label_ar": "جالنا",
                "label_fr": "Jalna"
            },
            {
                "value": 18573,
                "label": "Korba",
                "label_ar": "الكوربة",
                "label_fr": "Korba"
            },
            {
                "value": 18254,
                "label": "Jamnagar",
                "label_ar": "جامناجار",
                "label_fr": "Jamnagar"
            },
            {
                "value": 18424,
                "label": "Baddi",
                "label_ar": "بدي",
                "label_fr": "Baddi"
            },
            {
                "value": 18197,
                "label": "Dhanbad",
                "label_ar": "دانباد",
                "label_fr": "Dhanbad"
            },
            {
                "value": 18083,
                "label": "Srinagar",
                "label_ar": "سريناغار",
                "label_fr": "Srinagar"
            },
            {
                "value": 18446,
                "label": "Sikka",
                "label_ar": "السكة",
                "label_fr": "Sikka"
            },
            {
                "value": 18626,
                "label": "Tadepallegudem",
                "label_ar": "Tadepallegudem",
                "label_fr": "Tadepallegudem"
            },
            {
                "value": 18577,
                "label": "Hamirpur",
                "label_ar": "هاميربور",
                "label_fr": "Hamirpur"
            },
            {
                "value": 18036,
                "label": "Mangalore",
                "label_ar": "مانجالور",
                "label_fr": "Mangalore"
            },
            {
                "value": 18033,
                "label": "Chakan",
                "label_ar": "تشكان",
                "label_fr": "Chakan"
            },
            {
                "value": 18961,
                "label": "Charan",
                "label_ar": "شاران",
                "label_fr": "Charan"
            },
            {
                "value": 18500,
                "label": "Jharsuguda",
                "label_ar": "جارسوجودا",
                "label_fr": "Jharsuguda"
            },
            {
                "value": 18350,
                "label": "Quepem",
                "label_ar": "Quepem",
                "label_fr": "Quepem"
            },
            {
                "value": 18643,
                "label": "Pithoragarh",
                "label_ar": "بيثوراجاره",
                "label_fr": "Pithoragarh"
            },
            {
                "value": 18637,
                "label": "Bundi",
                "label_ar": "بوندي",
                "label_fr": "Bundi"
            },
            {
                "value": 18991,
                "label": "Navelim",
                "label_ar": "نافيليم",
                "label_fr": "Navelim"
            },
            {
                "value": 18002,
                "label": "Bhandup",
                "label_ar": "بهاندوب",
                "label_fr": "Bhandup"
            },
            {
                "value": 18068,
                "label": "Bellary",
                "label_ar": "بيلاري",
                "label_fr": "Bellary"
            },
            {
                "value": 18160,
                "label": "Kottayam",
                "label_ar": "كوتايام",
                "label_fr": "Kottayam"
            },
            {
                "value": 18764,
                "label": "Sirohi",
                "label_ar": "سيروهي",
                "label_fr": "Sirohi"
            },
            {
                "value": 18357,
                "label": "Chopda",
                "label_ar": "تشوبدا",
                "label_fr": "Chopda"
            },
            {
                "value": 18299,
                "label": "Mancherial",
                "label_ar": "مانشيريال",
                "label_fr": "Mancherial"
            },
            {
                "value": 18557,
                "label": "Porbandar",
                "label_ar": "بوربندر",
                "label_fr": "Porbandar"
            },
            {
                "value": 18156,
                "label": "Kochi",
                "label_ar": "كوشي",
                "label_fr": "Kochi"
            },
            {
                "value": 18953,
                "label": "Thiruthani",
                "label_ar": "ثيروثاني",
                "label_fr": "Thiruthani"
            },
            {
                "value": 18427,
                "label": "Gannavaram",
                "label_ar": "جانافارام",
                "label_fr": "Gannavaram"
            },
            {
                "value": 18958,
                "label": "Kakdwip",
                "label_ar": "كقدوية",
                "label_fr": "Kakdwip"
            },
            {
                "value": 18336,
                "label": "Dhuri",
                "label_ar": "ضوري",
                "label_fr": "Dhuri"
            },
            {
                "value": 18918,
                "label": "Rana",
                "label_ar": "رنا",
                "label_fr": "Rana"
            },
            {
                "value": 19002,
                "label": "Udaigiri",
                "label_ar": "اودايجيري",
                "label_fr": "Udaigiri"
            },
            {
                "value": 18923,
                "label": "Bishnupur",
                "label_ar": "بيشنوبور",
                "label_fr": "Bishnupur"
            },
            {
                "value": 18669,
                "label": "Pudukkottai",
                "label_ar": "بودوكوتاي",
                "label_fr": "Pudukkottai"
            },
            {
                "value": 18954,
                "label": "Bawan",
                "label_ar": "بوان",
                "label_fr": "Bawan"
            },
            {
                "value": 18784,
                "label": "Amer",
                "label_ar": "عامر",
                "label_fr": "Amer"
            },
            {
                "value": 18212,
                "label": "Solan",
                "label_ar": "سولان",
                "label_fr": "Solan"
            },
            {
                "value": 18220,
                "label": "Dipas",
                "label_ar": "ديباس",
                "label_fr": "Dipas"
            },
            {
                "value": 18300,
                "label": "Sangli",
                "label_ar": "سانجلي",
                "label_fr": "Sangli"
            },
            {
                "value": 18494,
                "label": "Colaba",
                "label_ar": "كولابا",
                "label_fr": "Colaba"
            },
            {
                "value": 18743,
                "label": "Haveri",
                "label_ar": "هافيري",
                "label_fr": "Haveri"
            },
            {
                "value": 18131,
                "label": "Raichur",
                "label_ar": "رايشور",
                "label_fr": "Raichur"
            },
            {
                "value": 18857,
                "label": "Kasia",
                "label_ar": "كاسيا",
                "label_fr": "Kasia"
            },
            {
                "value": 18175,
                "label": "Imphal",
                "label_ar": "امفال",
                "label_fr": "Imphal"
            },
            {
                "value": 18919,
                "label": "Chetan",
                "label_ar": "شيتان",
                "label_fr": "Chetan"
            },
            {
                "value": 18332,
                "label": "Bhilwara",
                "label_ar": "بهيلوارا",
                "label_fr": "Bhilwara"
            },
            {
                "value": 18547,
                "label": "Avanigadda",
                "label_ar": "أفانيجادا",
                "label_fr": "Avanigadda"
            },
            {
                "value": 18192,
                "label": "Kharagpur",
                "label_ar": "خراجبور",
                "label_fr": "Kharagpur"
            },
            {
                "value": 18484,
                "label": "Bhusawal",
                "label_ar": "بحوصوال",
                "label_fr": "Bhusawal"
            },
            {
                "value": 18486,
                "label": "Sattur",
                "label_ar": "ساتور",
                "label_fr": "Sattur"
            },
            {
                "value": 18554,
                "label": "Bellare",
                "label_ar": "بيلاري",
                "label_fr": "Bellare"
            },
            {
                "value": 18789,
                "label": "Omalur",
                "label_ar": "Omalur",
                "label_fr": "Omalur"
            },
            {
                "value": 18692,
                "label": "Bharatpur",
                "label_ar": "بهاراتبور",
                "label_fr": "Bharatpur"
            },
            {
                "value": 18701,
                "label": "Pakala",
                "label_ar": "باكالا",
                "label_fr": "Pakala"
            },
            {
                "value": 18846,
                "label": "Pala",
                "label_ar": "بالا",
                "label_fr": "Pala"
            },
            {
                "value": 18118,
                "label": "Maharaj",
                "label_ar": "مهراج",
                "label_fr": "Maharaj"
            },
            {
                "value": 18378,
                "label": "Chakra",
                "label_ar": "شقرا",
                "label_fr": "Chakra"
            },
            {
                "value": 18625,
                "label": "Akividu",
                "label_ar": "أكيفيدو",
                "label_fr": "Akividu"
            },
            {
                "value": 19000,
                "label": "Bobbili",
                "label_ar": "بوبيلي",
                "label_fr": "Bobbili"
            },
            {
                "value": 18537,
                "label": "Alibag",
                "label_ar": "عليباغ",
                "label_fr": "Alibag"
            },
            {
                "value": 18161,
                "label": "Tirunelveli",
                "label_ar": "تيرونلفيلي",
                "label_fr": "Tirunelveli"
            },
            {
                "value": 19012,
                "label": "Shadnagar",
                "label_ar": "شادناغار",
                "label_fr": "Shadnagar"
            },
            {
                "value": 18681,
                "label": "Sangamner",
                "label_ar": "سانجامنر",
                "label_fr": "Sangamner"
            },
            {
                "value": 18883,
                "label": "Bangaon",
                "label_ar": "بانجاون",
                "label_fr": "Bangaon"
            },
            {
                "value": 18960,
                "label": "Dogadda",
                "label_ar": "دوجادا",
                "label_fr": "Dogadda"
            },
            {
                "value": 18043,
                "label": "Bagpat",
                "label_ar": "باجبات",
                "label_fr": "Bagpat"
            },
            {
                "value": 18659,
                "label": "Rajapalaiyam",
                "label_ar": "راجاباليام",
                "label_fr": "Rajapalaiyam"
            },
            {
                "value": 18610,
                "label": "Sultans Battery",
                "label_ar": "بطارية السلاطين",
                "label_fr": "Batterie des sultans"
            },
            {
                "value": 18314,
                "label": "Pathankot",
                "label_ar": "باثانكوت",
                "label_fr": "Pathankot"
            },
            {
                "value": 18121,
                "label": "Dahod",
                "label_ar": "داهود",
                "label_fr": "Dahod"
            },
            {
                "value": 18318,
                "label": "Kovilpatti",
                "label_ar": "كوفيلباتي",
                "label_fr": "Kovilpatti"
            },
            {
                "value": 18501,
                "label": "Jind",
                "label_ar": "جيند",
                "label_fr": "Jind"
            },
            {
                "value": 18861,
                "label": "Jagraon",
                "label_ar": "جاغراون",
                "label_fr": "Jagraon"
            },
            {
                "value": 18821,
                "label": "Nagwa",
                "label_ar": "نجوى",
                "label_fr": "Nagwa"
            },
            {
                "value": 18081,
                "label": "Pilani",
                "label_ar": "بيلاني",
                "label_fr": "Pilani"
            },
            {
                "value": 18323,
                "label": "Bijapur",
                "label_ar": "بيجابور",
                "label_fr": "Bijapur"
            },
            {
                "value": 18790,
                "label": "Kuzhithurai",
                "label_ar": "كوزيثوراي",
                "label_fr": "Kuzhithurai"
            },
            {
                "value": 18939,
                "label": "Manna",
                "label_ar": "مناع",
                "label_fr": "Manne"
            },
            {
                "value": 18351,
                "label": "Assagao",
                "label_ar": "Assagao",
                "label_fr": "Assagao"
            },
            {
                "value": 18894,
                "label": "Kanakpura",
                "label_ar": "كاناكبورا",
                "label_fr": "Kanakpura"
            },
            {
                "value": 18108,
                "label": "Modasa",
                "label_ar": "موداسا",
                "label_fr": "Modasa"
            },
            {
                "value": 18007,
                "label": "Bangalore",
                "label_ar": "بنغالور",
                "label_fr": "Bangalore"
            },
            {
                "value": 18933,
                "label": "Darsi",
                "label_ar": "دارسي",
                "label_fr": "Darsi"
            },
            {
                "value": 18539,
                "label": "Panchgani",
                "label_ar": "بانتشجانى",
                "label_fr": "Panchgani"
            },
            {
                "value": 18877,
                "label": "Andra",
                "label_ar": "أندرا",
                "label_fr": "Andra"
            },
            {
                "value": 18117,
                "label": "Chandrapur",
                "label_ar": "شاندرابور",
                "label_fr": "Chandrapur"
            },
            {
                "value": 18460,
                "label": "Rewari",
                "label_ar": "Rewari",
                "label_fr": "Rewari"
            },
            {
                "value": 18864,
                "label": "Kamalpur",
                "label_ar": "كامالبور",
                "label_fr": "Kamalpur"
            },
            {
                "value": 18676,
                "label": "Dicholi",
                "label_ar": "ديشولي",
                "label_fr": "Dicholi"
            },
            {
                "value": 18151,
                "label": "Manor",
                "label_ar": "مانور",
                "label_fr": "Manoir"
            },
            {
                "value": 18768,
                "label": "Ambad",
                "label_ar": "أمباد",
                "label_fr": "Ambad"
            },
            {
                "value": 18105,
                "label": "Bhavnagar",
                "label_ar": "بهافناغار",
                "label_fr": "Bhavnagar"
            },
            {
                "value": 18695,
                "label": "Karamsad",
                "label_ar": "كرامساد",
                "label_fr": "Karamsad"
            },
            {
                "value": 18691,
                "label": "Beawar",
                "label_ar": "بيوار",
                "label_fr": "Beawar"
            },
            {
                "value": 18030,
                "label": "Howrah",
                "label_ar": "هوراه",
                "label_fr": "Howrah"
            },
            {
                "value": 18725,
                "label": "Jhunjhunun",
                "label_ar": "Jhunjhunun",
                "label_fr": "Jhunjhunun"
            },
            {
                "value": 18306,
                "label": "Hinganghat",
                "label_ar": "هينجانغات",
                "label_fr": "Hinganghat"
            },
            {
                "value": 18761,
                "label": "Nathdwara",
                "label_ar": "Nathdwara",
                "label_fr": "Nathdwara"
            },
            {
                "value": 18107,
                "label": "Aurangabad",
                "label_ar": "اورانجاباد",
                "label_fr": "Aurangabad"
            },
            {
                "value": 18921,
                "label": "Arora",
                "label_ar": "أرورا",
                "label_fr": "Arora"
            },
            {
                "value": 18385,
                "label": "Narela",
                "label_ar": "ناريلا",
                "label_fr": "Narela"
            },
            {
                "value": 18190,
                "label": "Barpeta",
                "label_ar": "باربيتا",
                "label_fr": "Barpeta"
            },
            {
                "value": 18654,
                "label": "Eral",
                "label_ar": "إيرال",
                "label_fr": "Eral"
            },
            {
                "value": 18858,
                "label": "Bargarh",
                "label_ar": "بارغار",
                "label_fr": "Bargarh"
            },
            {
                "value": 18016,
                "label": "Kanpur",
                "label_ar": "كانبور",
                "label_fr": "Kanpur"
            },
            {
                "value": 18266,
                "label": "Gold",
                "label_ar": "ذهب",
                "label_fr": "Or"
            },
            {
                "value": 18670,
                "label": "Kaimganj",
                "label_ar": "Kaimganj",
                "label_fr": "Kaimganj"
            },
            {
                "value": 18757,
                "label": "Phaphamau",
                "label_ar": "فافامو",
                "label_fr": "Phaphamau"
            },
            {
                "value": 18132,
                "label": "Nagara",
                "label_ar": "ناجارا",
                "label_fr": "Nagara"
            },
            {
                "value": 18661,
                "label": "Suratgarh",
                "label_ar": "سوراتجاره",
                "label_fr": "Suratgarh"
            },
            {
                "value": 18434,
                "label": "Bilimora",
                "label_ar": "بيليمورا",
                "label_fr": "Bilimora"
            },
            {
                "value": 18815,
                "label": "Aundh",
                "label_ar": "اوند",
                "label_fr": "Aundh"
            },
            {
                "value": 18096,
                "label": "Bagalkot",
                "label_ar": "باجلكوت",
                "label_fr": "Bagalkot"
            },
            {
                "value": 18944,
                "label": "Bhandari",
                "label_ar": "بهانداري",
                "label_fr": "Bhandari"
            },
            {
                "value": 18086,
                "label": "Warangal",
                "label_ar": "وارانجال",
                "label_fr": "Warangal"
            },
            {
                "value": 18591,
                "label": "Jaypur",
                "label_ar": "جايبور",
                "label_fr": "Jaypur"
            },
            {
                "value": 18812,
                "label": "Pachmarhi",
                "label_ar": "باشمارهي",
                "label_fr": "Pachmarhi"
            },
            {
                "value": 18413,
                "label": "Kaladi",
                "label_ar": "كلادي",
                "label_fr": "Kaladi"
            },
            {
                "value": 18668,
                "label": "Dindigul",
                "label_ar": "Dindigul",
                "label_fr": "Dindigul"
            },
            {
                "value": 18648,
                "label": "Raxaul",
                "label_ar": "راكسول",
                "label_fr": "Raxaul"
            },
            {
                "value": 18271,
                "label": "Nadiad",
                "label_ar": "نادي",
                "label_fr": "Nadiad"
            },
            {
                "value": 18760,
                "label": "Jhalawar",
                "label_ar": "جلاور",
                "label_fr": "Jhalawar"
            },
            {
                "value": 18287,
                "label": "Itanagar",
                "label_ar": "إيتاناغار",
                "label_fr": "Itanagar"
            },
            {
                "value": 18750,
                "label": "Odhan",
                "label_ar": "أودهان",
                "label_fr": "Odhan"
            },
            {
                "value": 18696,
                "label": "Peranampattu",
                "label_ar": "بيرانامباتو",
                "label_fr": "Peranampattu"
            },
            {
                "value": 18072,
                "label": "Rupnagar",
                "label_ar": "روبناغار",
                "label_fr": "Rupnagar"
            },
            {
                "value": 18396,
                "label": "Koni",
                "label_ar": "كوني",
                "label_fr": "Koni"
            },
            {
                "value": 18389,
                "label": "Chittoor",
                "label_ar": "شيتور",
                "label_fr": "Chittoor"
            },
            {
                "value": 18114,
                "label": "Bhuj",
                "label_ar": "بوج",
                "label_fr": "Bhuj"
            },
            {
                "value": 18166,
                "label": "Chengannur",
                "label_ar": "Chengannur",
                "label_fr": "Chengannur"
            },
            {
                "value": 18892,
                "label": "Mahesh",
                "label_ar": "ماهيش",
                "label_fr": "Mahesh"
            },
            {
                "value": 18832,
                "label": "Nalagarh",
                "label_ar": "نالاجاره",
                "label_fr": "Nalagarh"
            },
            {
                "value": 18104,
                "label": "Gandhinagar",
                "label_ar": "جانديناغار",
                "label_fr": "Gandhinagar"
            },
            {
                "value": 18532,
                "label": "Serampore",
                "label_ar": "سيرامبور",
                "label_fr": "Serampore"
            },
            {
                "value": 18382,
                "label": "Garhshankar",
                "label_ar": "Garhshankar",
                "label_fr": "Garhshankar"
            },
            {
                "value": 18078,
                "label": "Tanda",
                "label_ar": "تاندا",
                "label_fr": "Tanda"
            },
            {
                "value": 18387,
                "label": "Sion",
                "label_ar": "سيون",
                "label_fr": "Sion"
            },
            {
                "value": 18124,
                "label": "Patel",
                "label_ar": "باتل",
                "label_fr": "Patel"
            },
            {
                "value": 18763,
                "label": "Pushkar",
                "label_ar": "بوشكار",
                "label_fr": "Pushkar"
            },
            {
                "value": 18110,
                "label": "Solapur",
                "label_ar": "سولابور",
                "label_fr": "Solapur"
            },
            {
                "value": 18959,
                "label": "Kamalpura",
                "label_ar": "كامالبورا",
                "label_fr": "Kamalpura"
            },
            {
                "value": 18312,
                "label": "Muktsar",
                "label_ar": "مختصار",
                "label_fr": "Muktsar"
            },
            {
                "value": 18549,
                "label": "Bemetara",
                "label_ar": "بميتارا",
                "label_fr": "Bemetara"
            },
            {
                "value": 18267,
                "label": "Himatnagar",
                "label_ar": "هيماتناغار",
                "label_fr": "Himatnagar"
            },
            {
                "value": 18461,
                "label": "Ponnani",
                "label_ar": "بوناني",
                "label_fr": "Ponnani"
            },
            {
                "value": 18490,
                "label": "Dharavi",
                "label_ar": "دهارفي",
                "label_fr": "Dharavi"
            },
            {
                "value": 18065,
                "label": "Bikaner",
                "label_ar": "بيكانير",
                "label_fr": "Bikaner"
            },
            {
                "value": 18390,
                "label": "Wellington",
                "label_ar": "ويلينجتون",
                "label_fr": "Wellington"
            },
            {
                "value": 18510,
                "label": "Darjeeling",
                "label_ar": "دارجيلنج",
                "label_fr": "Darjeeling"
            },
            {
                "value": 18720,
                "label": "Daman",
                "label_ar": "ضمان",
                "label_fr": "Daman"
            },
            {
                "value": 18159,
                "label": "Kottarakara",
                "label_ar": "كوتاراكارا",
                "label_fr": "Kottarakara"
            },
            {
                "value": 18683,
                "label": "Kanhangad",
                "label_ar": "كانهانجاد",
                "label_fr": "Kanhangad"
            },
            {
                "value": 18247,
                "label": "Raurkela",
                "label_ar": "راوركيلا",
                "label_fr": "Raurkela"
            },
            {
                "value": 18418,
                "label": "Bank",
                "label_ar": "مصرف",
                "label_fr": "Banque"
            },
            {
                "value": 18051,
                "label": "Bhiwani",
                "label_ar": "بهيواني",
                "label_fr": "Bhiwani"
            },
            {
                "value": 18990,
                "label": "Dona Paula",
                "label_ar": "دونا بولا",
                "label_fr": "Dona Paula"
            },
            {
                "value": 18113,
                "label": "Akola",
                "label_ar": "أكولا",
                "label_fr": "Akola"
            },
            {
                "value": 18627,
                "label": "Madanapalle",
                "label_ar": "مادانابالي",
                "label_fr": "Madanapalle"
            },
            {
                "value": 18840,
                "label": "Betul",
                "label_ar": "بيتول",
                "label_fr": "Betul"
            },
            {
                "value": 18583,
                "label": "Siruguppa",
                "label_ar": "سيروجوبا",
                "label_fr": "Siruguppa"
            },
            {
                "value": 18200,
                "label": "Kokrajhar",
                "label_ar": "كوكراجار",
                "label_fr": "Kokrajhar"
            },
            {
                "value": 18324,
                "label": "Tinsukia",
                "label_ar": "تينسوكيا",
                "label_fr": "Tinsukia"
            },
            {
                "value": 18240,
                "label": "Cuddalore",
                "label_ar": "كودالور",
                "label_fr": "Cuddalore"
            },
            {
                "value": 18205,
                "label": "Palampur",
                "label_ar": "بالامبور",
                "label_fr": "Palampur"
            },
            {
                "value": 18284,
                "label": "Sonipat",
                "label_ar": "سونيبات",
                "label_fr": "Sonipat"
            },
            {
                "value": 18524,
                "label": "Choolai",
                "label_ar": "تشولاي",
                "label_fr": "Choolai"
            },
            {
                "value": 18317,
                "label": "Kollam",
                "label_ar": "كولام",
                "label_fr": "Kollam"
            },
            {
                "value": 18893,
                "label": "Sirwani",
                "label_ar": "سيرواني",
                "label_fr": "Sirwani"
            },
            {
                "value": 19004,
                "label": "Kanigiri",
                "label_ar": "كانيجيري",
                "label_fr": "Kanigiri"
            },
            {
                "value": 18126,
                "label": "Amravati",
                "label_ar": "امرافاتي",
                "label_fr": "Amravati"
            },
            {
                "value": 18260,
                "label": "Malappuram",
                "label_ar": "مالابورام",
                "label_fr": "Malappuram"
            },
            {
                "value": 18808,
                "label": "Arpora",
                "label_ar": "أربورا",
                "label_fr": "Arpora"
            },
            {
                "value": 18593,
                "label": "Tindivanam",
                "label_ar": "تينديفانام",
                "label_fr": "Tindivanam"
            },
            {
                "value": 18827,
                "label": "Lalgudi",
                "label_ar": "لالجودي",
                "label_fr": "Lalgudi"
            },
            {
                "value": 18965,
                "label": "Sopara",
                "label_ar": "سوبارا",
                "label_fr": "Sopara"
            },
            {
                "value": 18615,
                "label": "Banga",
                "label_ar": "بانجا",
                "label_fr": "Banga"
            },
            {
                "value": 18992,
                "label": "Vainguinim",
                "label_ar": "Vainguinim",
                "label_fr": "Vainguinim"
            },
            {
                "value": 18415,
                "label": "Thenali",
                "label_ar": "ثنالي",
                "label_fr": "Thenali"
            },
            {
                "value": 18647,
                "label": "Churachandpur",
                "label_ar": "تشوراشاندبور",
                "label_fr": "Churachandpur"
            },
            {
                "value": 18191,
                "label": "Jorhat",
                "label_ar": "جورهات",
                "label_fr": "Jorhat"
            },
            {
                "value": 18365,
                "label": "Palladam",
                "label_ar": "بالادم",
                "label_fr": "Palladam"
            },
            {
                "value": 18985,
                "label": "Dang",
                "label_ar": "دانغ",
                "label_fr": "Dang"
            },
            {
                "value": 18142,
                "label": "Tiruchi",
                "label_ar": "تيروتشي",
                "label_fr": "Tiruchi"
            },
            {
                "value": 18651,
                "label": "Purnea",
                "label_ar": "بيرنيا",
                "label_fr": "Purnea"
            },
            {
                "value": 18702,
                "label": "Vikarabad",
                "label_ar": "فيكاراباد",
                "label_fr": "Vikarabad"
            },
            {
                "value": 18231,
                "label": "Bhoj",
                "label_ar": "بوج",
                "label_fr": "Bhoj"
            },
            {
                "value": 18294,
                "label": "Medak",
                "label_ar": "ميداك",
                "label_fr": "Medak"
            },
            {
                "value": 18662,
                "label": "Gohana",
                "label_ar": "جوهانا",
                "label_fr": "Gohana"
            },
            {
                "value": 18039,
                "label": "Rishikesh",
                "label_ar": "ريشيكيش",
                "label_fr": "Rishikesh"
            },
            {
                "value": 18635,
                "label": "Satna",
                "label_ar": "ساتنا",
                "label_fr": "Satna"
            },
            {
                "value": 18153,
                "label": "Thiruvarur",
                "label_ar": "ثيروفارور",
                "label_fr": "Thiruvarur"
            },
            {
                "value": 18952,
                "label": "Dangi",
                "label_ar": "دانجي",
                "label_fr": "Dangi"
            },
            {
                "value": 18095,
                "label": "Rajahmundry",
                "label_ar": "راجاهموندري",
                "label_fr": "Rajahmundry"
            },
            {
                "value": 18004,
                "label": "Visakhapatnam",
                "label_ar": "فيساخاباتنام",
                "label_fr": "Visakhapatnam"
            },
            {
                "value": 18717,
                "label": "Candolim",
                "label_ar": "كاندوليم",
                "label_fr": "Candolim"
            },
            {
                "value": 18149,
                "label": "Karaikudi",
                "label_ar": "كاريكودي",
                "label_fr": "Karaikudi"
            },
            {
                "value": 18339,
                "label": "Coonoor",
                "label_ar": "كونور",
                "label_fr": "Coonoor"
            },
            {
                "value": 18778,
                "label": "Patancheru",
                "label_ar": "باتانشيرو",
                "label_fr": "Patancheru"
            },
            {
                "value": 18021,
                "label": "Chennai",
                "label_ar": "تشيناي",
                "label_fr": "Chennai"
            },
            {
                "value": 18889,
                "label": "Morinda",
                "label_ar": "موريندا",
                "label_fr": "Morinda"
            },
            {
                "value": 18663,
                "label": "Sirsa",
                "label_ar": "سيرسا",
                "label_fr": "Sirsa"
            },
            {
                "value": 18642,
                "label": "Sunam",
                "label_ar": "سونام",
                "label_fr": "Sunam"
            },
            {
                "value": 18249,
                "label": "Visnagar",
                "label_ar": "فيسناغار",
                "label_fr": "Visnagar"
            },
            {
                "value": 18562,
                "label": "Deolali",
                "label_ar": "ديولالي",
                "label_fr": "Deolali"
            },
            {
                "value": 18459,
                "label": "Kottakkal",
                "label_ar": "كوتاكال",
                "label_fr": "Kottakkal"
            },
            {
                "value": 18835,
                "label": "Jatani",
                "label_ar": "جاتاني",
                "label_fr": "Jatani"
            },
            {
                "value": 18814,
                "label": "Kumhari",
                "label_ar": "كومهاري",
                "label_fr": "Kumhari"
            },
            {
                "value": 18956,
                "label": "Kosamba",
                "label_ar": "كوسامبا",
                "label_fr": "Kosamba"
            },
            {
                "value": 18076,
                "label": "Indraprast",
                "label_ar": "إندرابراست",
                "label_fr": "Indraprast"
            },
            {
                "value": 18619,
                "label": "Dhenkanal",
                "label_ar": "دنكانال",
                "label_fr": "Dhenkanal"
            },
            {
                "value": 18135,
                "label": "Hubli",
                "label_ar": "Hubli",
                "label_fr": "Hubli"
            },
            {
                "value": 18505,
                "label": "Liluah",
                "label_ar": "ليلوة",
                "label_fr": "Liluah"
            },
            {
                "value": 18540,
                "label": "Karjat",
                "label_ar": "كارجات",
                "label_fr": "Karjat"
            },
            {
                "value": 18489,
                "label": "Khurda",
                "label_ar": "خوردة",
                "label_fr": "Khurda"
            },
            {
                "value": 18653,
                "label": "Kumbakonam",
                "label_ar": "كومباكونام",
                "label_fr": "Kumbakonam"
            },
            {
                "value": 18674,
                "label": "Sehore",
                "label_ar": "سيهور",
                "label_fr": "Sehore"
            },
            {
                "value": 18640,
                "label": "Kot Isa Khan",
                "label_ar": "كوت عيسى خان",
                "label_fr": "Kot Isa Khan"
            },
            {
                "value": 18005,
                "label": "Coimbatore",
                "label_ar": "كويمباتور",
                "label_fr": "Coimbatore"
            },
            {
                "value": 18516,
                "label": "Sitapur",
                "label_ar": "سيتابور",
                "label_fr": "Sitapur"
            },
            {
                "value": 18711,
                "label": "Mukerian",
                "label_ar": "موكريان",
                "label_fr": "Mukerian"
            },
            {
                "value": 18325,
                "label": "Ratlam",
                "label_ar": "راتلام",
                "label_fr": "Ratlam"
            },
            {
                "value": 18943,
                "label": "Roshan",
                "label_ar": "روشان",
                "label_fr": "Roshan"
            },
            {
                "value": 18896,
                "label": "Sodhi",
                "label_ar": "سودي",
                "label_fr": "Sodhi"
            }
        ]
    },
    {
        "country": "Malaysia",
        "cities": [
            {
                "value": 19165,
                "label": "Kampung Teriang",
                "label_ar": "كامبونج تيريانج",
                "label_fr": "Kampung Teriang"
            },
            {
                "value": 19237,
                "label": "Kuala Berang",
                "label_ar": "كوالا بيرانج",
                "label_fr": "Kuala Berang"
            },
            {
                "value": 19245,
                "label": "Pantai Remis",
                "label_ar": "بانتاي ريميس",
                "label_fr": "Pantai Remis"
            },
            {
                "value": 19047,
                "label": "Muadzam Shah",
                "label_ar": "معظم شاه",
                "label_fr": "Muadzam Shah"
            },
            {
                "value": 19149,
                "label": "Hulu Langat",
                "label_ar": "هولو لانجات",
                "label_fr": "Hulu Langat"
            },
            {
                "value": 19174,
                "label": "Semenyih",
                "label_ar": "سيمنية",
                "label_fr": "Semenyih"
            },
            {
                "value": 19184,
                "label": "Lumut",
                "label_ar": "لوموت",
                "label_fr": "Lumut"
            },
            {
                "value": 19188,
                "label": "Mantin",
                "label_ar": "مانتين",
                "label_fr": "Mantin"
            },
            {
                "value": 19077,
                "label": "Bandar Baru Bangi",
                "label_ar": "بندر بارو بانجي",
                "label_fr": "Bandar Baru Bangi"
            },
            {
                "value": 19032,
                "label": "Bukit Kayu Hitam",
                "label_ar": "بوكيت كايو هيتام",
                "label_fr": "Bukit Kayu Hitam"
            },
            {
                "value": 19248,
                "label": "Gua Musang",
                "label_ar": "Gua Musang",
                "label_fr": "Gua Musang"
            },
            {
                "value": 19233,
                "label": "Pudu Ulu",
                "label_ar": "بودو أولو",
                "label_fr": "Pudu Ulu"
            },
            {
                "value": 19158,
                "label": "Kampung Teluk Baru",
                "label_ar": "كامبونج تيلوك بارو",
                "label_fr": "Kampung Teluk Baru"
            },
            {
                "value": 19186,
                "label": "Ketereh",
                "label_ar": "كتيريه",
                "label_fr": "Ketereh"
            },
            {
                "value": 19260,
                "label": "Sungai Bakap",
                "label_ar": "سونغاي باكاب",
                "label_fr": "Sungai Bakap"
            },
            {
                "value": 19274,
                "label": "Batu Berendam",
                "label_ar": "باتو بيريندام",
                "label_fr": "Batu Berendam"
            },
            {
                "value": 19213,
                "label": "Padang Serai",
                "label_ar": "بادانج سيراي",
                "label_fr": "Padang Serai"
            },
            {
                "value": 19035,
                "label": "Kuala Terengganu",
                "label_ar": "كوالا تيرينجانو",
                "label_fr": "Kuala Terengganu"
            },
            {
                "value": 19053,
                "label": "Banting",
                "label_ar": "الهراء",
                "label_fr": "Banting"
            },
            {
                "value": 19049,
                "label": "Simpang Empat",
                "label_ar": "سيمبانج إمبات",
                "label_fr": "Simpang Empat"
            },
            {
                "value": 19127,
                "label": "Pasir Gudang",
                "label_ar": "باسير جودانج",
                "label_fr": "Pasir Gudang"
            },
            {
                "value": 19063,
                "label": "Putrajaya",
                "label_ar": "بوتراجايا",
                "label_fr": "Putrajaya"
            },
            {
                "value": 19038,
                "label": "Malacca",
                "label_ar": "ملقا",
                "label_fr": "Malacca"
            },
            {
                "value": 19194,
                "label": "Tangkak",
                "label_ar": "تانجكاك",
                "label_fr": "Tangkak"
            },
            {
                "value": 19132,
                "label": "Rengam",
                "label_ar": "رينجام",
                "label_fr": "Rengam"
            },
            {
                "value": 19148,
                "label": "Kampung Batu Uban",
                "label_ar": "كامبونج باتو أوبان",
                "label_fr": "Kampung Batu Uban"
            },
            {
                "value": 19109,
                "label": "Balik Pulau",
                "label_ar": "باليك بولاو",
                "label_fr": "Balik Pulau"
            },
            {
                "value": 19098,
                "label": "Teluk Panglima Garang",
                "label_ar": "Teluk Panglima قرنق",
                "label_fr": "Teluk Panglima Garang"
            },
            {
                "value": 19296,
                "label": "Bukit Rotan",
                "label_ar": "بوكيت روتان",
                "label_fr": "Bukit Rotan"
            },
            {
                "value": 19238,
                "label": "Melor",
                "label_ar": "ميلور",
                "label_fr": "Melor"
            },
            {
                "value": 19181,
                "label": "Nibong Tebal",
                "label_ar": "نيبونغ تيبال",
                "label_fr": "Nibong Tebal"
            },
            {
                "value": 19232,
                "label": "Kampung Batu Kikir",
                "label_ar": "كامبونج باتو كيكير",
                "label_fr": "Kampung Batu Kikir"
            },
            {
                "value": 19151,
                "label": "Cameron Highlands",
                "label_ar": "مرتفعات كاميرون",
                "label_fr": "Cameron Highlands"
            },
            {
                "value": 19071,
                "label": "Ayer Itam",
                "label_ar": "آير إيتام",
                "label_fr": "Ayer Itam"
            },
            {
                "value": 19046,
                "label": "Taman Sri Ampang",
                "label_ar": "تامان سري أمبانج",
                "label_fr": "Taman Sri Ampang"
            },
            {
                "value": 19066,
                "label": "Kota",
                "label_ar": "كوتا",
                "label_fr": "Kota"
            },
            {
                "value": 19025,
                "label": "Kuala Lumpur",
                "label_ar": "كوالا لمبور",
                "label_fr": "Kuala Lumpur"
            },
            {
                "value": 19057,
                "label": "Kampong Pasir Segambut",
                "label_ar": "كامبونج باسير سيغامبوت",
                "label_fr": "Kampong Pasir Segambut"
            },
            {
                "value": 19195,
                "label": "Gopeng",
                "label_ar": "جوبينج",
                "label_fr": "Gopeng"
            },
            {
                "value": 19254,
                "label": "Pasir Puteh",
                "label_ar": "باسير بوتيه",
                "label_fr": "Pasir Puteh"
            },
            {
                "value": 19291,
                "label": "Kuang",
                "label_ar": "كوانغ",
                "label_fr": "Kuang"
            },
            {
                "value": 19074,
                "label": "Kuah",
                "label_ar": "كواه",
                "label_fr": "Kuah"
            },
            {
                "value": 19200,
                "label": "Kuala Nerang",
                "label_ar": "كوالا نيرانج",
                "label_fr": "Kuala Nerang"
            },
            {
                "value": 19275,
                "label": "Penaga",
                "label_ar": "بيناغا",
                "label_fr": "Penaga"
            },
            {
                "value": 19102,
                "label": "Port Klang",
                "label_ar": "ميناء كلانج",
                "label_fr": "Port Klang"
            },
            {
                "value": 19256,
                "label": "Langat",
                "label_ar": "لانجات",
                "label_fr": "Langat"
            },
            {
                "value": 19130,
                "label": "Lunas",
                "label_ar": "لوناس",
                "label_fr": "Lunas"
            },
            {
                "value": 19084,
                "label": "Kangar",
                "label_ar": "كنغر",
                "label_fr": "Kangar"
            },
            {
                "value": 19280,
                "label": "Batu",
                "label_ar": "باتو",
                "label_fr": "Batu"
            },
            {
                "value": 19059,
                "label": "Penampang",
                "label_ar": "بينامبانج",
                "label_fr": "Penampang"
            },
            {
                "value": 19266,
                "label": "Bukit Pasir",
                "label_ar": "بوكيت باسير",
                "label_fr": "Bukit Pasir"
            },
            {
                "value": 19267,
                "label": "Kampung Bota Kiri",
                "label_ar": "كامبونج بوتا كيري",
                "label_fr": "Kampung Bota Kiri"
            },
            {
                "value": 19135,
                "label": "Ranau",
                "label_ar": "راناو",
                "label_fr": "Ranau"
            },
            {
                "value": 19269,
                "label": "Taman Megah Ria",
                "label_ar": "تامان ميغا ريا",
                "label_fr": "Taman Megah Ria"
            },
            {
                "value": 19183,
                "label": "Tasek Glugor",
                "label_ar": "تاسيك جلوجور",
                "label_fr": "Tasek Glugor"
            },
            {
                "value": 19209,
                "label": "Seri Manjung",
                "label_ar": "سيري مانجونج",
                "label_fr": "Seri Manjung"
            },
            {
                "value": 19187,
                "label": "Jitra",
                "label_ar": "جيترا",
                "label_fr": "Jitra"
            },
            {
                "value": 19190,
                "label": "Tamparuli",
                "label_ar": "تامبارولي",
                "label_fr": "Tamparuli"
            },
            {
                "value": 19121,
                "label": "Tawau",
                "label_ar": "تاواو",
                "label_fr": "Tawau"
            },
            {
                "value": 19141,
                "label": "Jenjarum",
                "label_ar": "جينجاروم",
                "label_fr": "Jenjarum"
            },
            {
                "value": 19217,
                "label": "Kuala Ketil",
                "label_ar": "كوالا كيتيل",
                "label_fr": "Kuala Ketil"
            },
            {
                "value": 19091,
                "label": "Ulu Tiram",
                "label_ar": "أولو تيرام",
                "label_fr": "Ulu Tiram"
            },
            {
                "value": 19286,
                "label": "Kuala Klawang",
                "label_ar": "كوالا كلاوانج",
                "label_fr": "Kuala Klawang"
            },
            {
                "value": 19185,
                "label": "Slim River",
                "label_ar": "نهر سليم",
                "label_fr": "Slim River"
            },
            {
                "value": 19235,
                "label": "Serendah",
                "label_ar": "سيريندا",
                "label_fr": "Serendah"
            },
            {
                "value": 19142,
                "label": "Kuala Pilah",
                "label_ar": "كوالا بيلا",
                "label_fr": "Kuala Pilah"
            },
            {
                "value": 19221,
                "label": "Sungai Pelek",
                "label_ar": "سونغاي بيليك",
                "label_fr": "Sungai Pelek"
            },
            {
                "value": 19093,
                "label": "Kuala Kangsar",
                "label_ar": "كوالا كانغسار",
                "label_fr": "Kuala Kangsar"
            },
            {
                "value": 19153,
                "label": "Teriang",
                "label_ar": "تيريانج",
                "label_fr": "Teriang"
            },
            {
                "value": 19206,
                "label": "Beaufort",
                "label_ar": "بوفورت",
                "label_fr": "Beaufort"
            },
            {
                "value": 19155,
                "label": "Port Dickson",
                "label_ar": "بورت ديكسون",
                "label_fr": "Port dickson"
            },
            {
                "value": 19228,
                "label": "Sungai Besar",
                "label_ar": "سونغاي بيسار",
                "label_fr": "Sungai Besar"
            },
            {
                "value": 19168,
                "label": "Cyberjaya",
                "label_ar": "سيبرجايا",
                "label_fr": "Cyberjaya"
            },
            {
                "value": 19112,
                "label": "Karak",
                "label_ar": "الكرك",
                "label_fr": "Karak"
            },
            {
                "value": 19278,
                "label": "Semerah",
                "label_ar": "سميرة",
                "label_fr": "Semerah"
            },
            {
                "value": 19104,
                "label": "Pontian Kechil",
                "label_ar": "بونتيان كيتشيل",
                "label_fr": "Pontien Kechil"
            },
            {
                "value": 19252,
                "label": "Merbok",
                "label_ar": "مربوك",
                "label_fr": "Merbok"
            },
            {
                "value": 19164,
                "label": "Ampang",
                "label_ar": "أمبانج",
                "label_fr": "Ampang"
            },
            {
                "value": 19167,
                "label": "Tanjung",
                "label_ar": "تانجونج",
                "label_fr": "Tanjung"
            },
            {
                "value": 19086,
                "label": "Rawang",
                "label_ar": "راوانغ",
                "label_fr": "Rawang"
            },
            {
                "value": 19295,
                "label": "Kuala",
                "label_ar": "كوالا",
                "label_fr": "Kuala"
            },
            {
                "value": 19081,
                "label": "Kampung Tanjung Aru",
                "label_ar": "كامبونج تانجونج آرو",
                "label_fr": "Kampung Tanjung Aru"
            },
            {
                "value": 19029,
                "label": "Kuching",
                "label_ar": "كوتشينغ",
                "label_fr": "Kuching"
            },
            {
                "value": 19067,
                "label": "Sepang",
                "label_ar": "سيبانغ",
                "label_fr": "Sepang"
            },
            {
                "value": 19294,
                "label": "Tanjung Sepat",
                "label_ar": "تانجونج سيبات",
                "label_fr": "Tanjung Sepat"
            },
            {
                "value": 19282,
                "label": "Taman Desa",
                "label_ar": "تامان ديسا",
                "label_fr": "Taman Desa"
            },
            {
                "value": 19042,
                "label": "Klang",
                "label_ar": "كلانج",
                "label_fr": "Klang"
            },
            {
                "value": 19080,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 19211,
                "label": "Bukit Asahan",
                "label_ar": "بوكيت أسهان",
                "label_fr": "Bukit Asahan"
            },
            {
                "value": 19117,
                "label": "Kampong Kemaman",
                "label_ar": "كامبونج كيمامان",
                "label_fr": "Kampong Kemaman"
            },
            {
                "value": 19222,
                "label": "Rembau",
                "label_ar": "Rembau",
                "label_fr": "Rembau"
            },
            {
                "value": 19253,
                "label": "Lahat",
                "label_ar": "لاهات",
                "label_fr": "Lahat"
            },
            {
                "value": 19284,
                "label": "Bangi",
                "label_ar": "بانجي",
                "label_fr": "Bangi"
            },
            {
                "value": 19040,
                "label": "Kota Kinabalu",
                "label_ar": "كوتا كينابالو",
                "label_fr": "Kota Kinabalu"
            },
            {
                "value": 19137,
                "label": "Kulim",
                "label_ar": "كوليم",
                "label_fr": "Kulim"
            },
            {
                "value": 19166,
                "label": "Bachok",
                "label_ar": "باتشوك",
                "label_fr": "Bachok"
            },
            {
                "value": 19251,
                "label": "Pekan",
                "label_ar": "بيكان",
                "label_fr": "Pékan"
            },
            {
                "value": 19152,
                "label": "Durian Tunggal",
                "label_ar": "دوريان تونغال",
                "label_fr": "Durian Tunggal"
            },
            {
                "value": 19099,
                "label": "Lenggeng",
                "label_ar": "Lenggeng",
                "label_fr": "Lenggeng"
            },
            {
                "value": 19089,
                "label": "Teluk Intan",
                "label_ar": "تيلوك إنتان",
                "label_fr": "Teluk Intan"
            },
            {
                "value": 19051,
                "label": "Taman Senai",
                "label_ar": "تامان سيناي",
                "label_fr": "Taman Senai"
            },
            {
                "value": 19288,
                "label": "Gebeng",
                "label_ar": "جيبينج",
                "label_fr": "Gebeng"
            },
            {
                "value": 19052,
                "label": "Batu Caves",
                "label_ar": "كهوف باتو",
                "label_fr": "Grottes de Batu"
            },
            {
                "value": 19272,
                "label": "Menggatal",
                "label_ar": "مينجاتال",
                "label_fr": "Menggatal"
            },
            {
                "value": 19212,
                "label": "Melaka Tengah",
                "label_ar": "ملكا تنجاه",
                "label_fr": "Melaka Tengah"
            },
            {
                "value": 19119,
                "label": "Papar",
                "label_ar": "بابار",
                "label_fr": "Papar"
            },
            {
                "value": 19262,
                "label": "Kepala Batas",
                "label_ar": "كيبالا باتاس",
                "label_fr": "Kepala Batas"
            },
            {
                "value": 19131,
                "label": "Benut",
                "label_ar": "بنوت",
                "label_fr": "Benut"
            },
            {
                "value": 19078,
                "label": "Kerling",
                "label_ar": "كيرلينج",
                "label_fr": "Kerling"
            },
            {
                "value": 19224,
                "label": "Parit Buntar",
                "label_ar": "باريت بونتار",
                "label_fr": "Parit Buntar"
            },
            {
                "value": 19108,
                "label": "Kampung Pekan Kechil Beranang",
                "label_ar": "كامبونج بيكان كيتشيل بيرانانج",
                "label_fr": "Kampung Pekan Kechil Beranang"
            },
            {
                "value": 19039,
                "label": "Seremban",
                "label_ar": "سيريمبان",
                "label_fr": "Seremban"
            },
            {
                "value": 19259,
                "label": "Taman Seremban Jaya",
                "label_ar": "تامان سيريمبان جايا",
                "label_fr": "Taman Seremban Jaya"
            },
            {
                "value": 19068,
                "label": "Kuala Selangor",
                "label_ar": "كوالا سيلانجور",
                "label_fr": "Kuala Selangor"
            },
            {
                "value": 19092,
                "label": "Senggarang",
                "label_ar": "Senggarang",
                "label_fr": "Senggarang"
            },
            {
                "value": 19128,
                "label": "Tumpat",
                "label_ar": "تومبات",
                "label_fr": "Tumpat"
            },
            {
                "value": 19061,
                "label": "Bandar",
                "label_ar": "بندر",
                "label_fr": "Bandar"
            },
            {
                "value": 19199,
                "label": "Gurun",
                "label_ar": "جورون",
                "label_fr": "Gurun"
            },
            {
                "value": 19247,
                "label": "Bahau",
                "label_ar": "بهاو",
                "label_fr": "Bahau"
            },
            {
                "value": 19201,
                "label": "Pengkalan Kempas",
                "label_ar": "بنجالان كيمباس",
                "label_fr": "Pengkalan Kempas"
            },
            {
                "value": 19219,
                "label": "Tatau",
                "label_ar": "تاتاو",
                "label_fr": "Tatau"
            },
            {
                "value": 19037,
                "label": "Johor Bahru",
                "label_ar": "جوهور باهرو",
                "label_fr": "Johor Bahru"
            },
            {
                "value": 19043,
                "label": "Alor Gajah",
                "label_ar": "الور جاجه",
                "label_fr": "Alor Gajah"
            },
            {
                "value": 19273,
                "label": "Tambunan",
                "label_ar": "تامبونان",
                "label_fr": "Tambunan"
            },
            {
                "value": 19139,
                "label": "Kepala Batas",
                "label_ar": "كيبالا باتاس",
                "label_fr": "Kepala Batas"
            },
            {
                "value": 19048,
                "label": "Kuala Pahang",
                "label_ar": "كوالا باهانج",
                "label_fr": "Kuala Pahang"
            },
            {
                "value": 19134,
                "label": "Pedas",
                "label_ar": "بيداس",
                "label_fr": "Pedas"
            },
            {
                "value": 19031,
                "label": "Ipoh",
                "label_ar": "ايبوه",
                "label_fr": "Ipoh"
            },
            {
                "value": 19045,
                "label": "Puchong Batu Dua Belas",
                "label_ar": "بوتشونغ باتو دوا بيلاس",
                "label_fr": "Puchong Batu Dua Belas"
            },
            {
                "value": 19090,
                "label": "Masai",
                "label_ar": "الماساي",
                "label_fr": "Masaï"
            },
            {
                "value": 19223,
                "label": "Layang Layang",
                "label_ar": "لايانغ لايانغ",
                "label_fr": "Layang Layang"
            },
            {
                "value": 19220,
                "label": "Gelang Patah",
                "label_ar": "جيلانج باتاه",
                "label_fr": "Gelang Patah"
            },
            {
                "value": 19073,
                "label": "Tanah Merah New Village Site C",
                "label_ar": "موقع قرية تانا مراح الجديدة ج",
                "label_fr": "Site C du nouveau village de Tanah Merah"
            },
            {
                "value": 19204,
                "label": "Labis",
                "label_ar": "لابيس",
                "label_fr": "Labis"
            },
            {
                "value": 19297,
                "label": "Jinjang",
                "label_ar": "جينجانج",
                "label_fr": "Jinjang"
            },
            {
                "value": 19065,
                "label": "Batu Pahat",
                "label_ar": "باتو باهات",
                "label_fr": "Batu Pahat"
            },
            {
                "value": 19169,
                "label": "Serdang",
                "label_ar": "سيردانغ",
                "label_fr": "Serdang"
            },
            {
                "value": 19064,
                "label": "Damansara",
                "label_ar": "دامانسارا",
                "label_fr": "Damansara"
            },
            {
                "value": 19231,
                "label": "Bandar Tenggara",
                "label_ar": "بندر تنجارا",
                "label_fr": "Bandar Tenggara"
            },
            {
                "value": 19293,
                "label": "Labu",
                "label_ar": "لابو",
                "label_fr": "Labu"
            },
            {
                "value": 19229,
                "label": "Kampung Paya Simpang Empat Genting",
                "label_ar": "كامبونج بايا سيمبانج إمبات جنتنج",
                "label_fr": "Kampung Paya Simpang Empat Genting"
            },
            {
                "value": 19299,
                "label": "Mukah",
                "label_ar": "موكه",
                "label_fr": "Mukah"
            },
            {
                "value": 19255,
                "label": "Kubang Kerian",
                "label_ar": "كوبانج كيريان",
                "label_fr": "Kubang Kerian"
            },
            {
                "value": 19179,
                "label": "Batu Arang",
                "label_ar": "باتو أرانج",
                "label_fr": "Batu Arang"
            },
            {
                "value": 19111,
                "label": "Kota Bharu",
                "label_ar": "كوتا بهارو",
                "label_fr": "Kota Bharu"
            },
            {
                "value": 19055,
                "label": "Sungai Buluh",
                "label_ar": "سونغاي بولو",
                "label_fr": "Sungai Buluh"
            },
            {
                "value": 19239,
                "label": "Kota Belud",
                "label_ar": "كوتا بيلود",
                "label_fr": "Kota Belud"
            },
            {
                "value": 19227,
                "label": "Pulau Pinang",
                "label_ar": "بولاو بينانج",
                "label_fr": "Pulau Pinang"
            },
            {
                "value": 19110,
                "label": "Tanjung Bunga",
                "label_ar": "تانجونج بونجا",
                "label_fr": "Tanjung Bunga"
            },
            {
                "value": 19044,
                "label": "Cheras",
                "label_ar": "شيراس",
                "label_fr": "Cheras"
            },
            {
                "value": 19079,
                "label": "Bentung",
                "label_ar": "بينتونج",
                "label_fr": "Bentung"
            },
            {
                "value": 19176,
                "label": "Segamat",
                "label_ar": "سيجامات",
                "label_fr": "Segamat"
            },
            {
                "value": 19270,
                "label": "Taman Senawang Indah",
                "label_ar": "تامان سيناوانغ إنداه",
                "label_fr": "Taman Senawang Indah"
            },
            {
                "value": 19257,
                "label": "Selama",
                "label_ar": "سيلاما",
                "label_fr": "Selama"
            },
            {
                "value": 19161,
                "label": "Batu Gajah",
                "label_ar": "باتو جاجاه",
                "label_fr": "Batu Gajah"
            },
            {
                "value": 19054,
                "label": "Kampar",
                "label_ar": "كامبار",
                "label_fr": "Kampar"
            },
            {
                "value": 19197,
                "label": "Tapah Road",
                "label_ar": "شارع الطابة",
                "label_fr": "Route Tapah"
            },
            {
                "value": 19218,
                "label": "Simpang Pertang",
                "label_ar": "سيمبانج بيرتانج",
                "label_fr": "Simpang Pertang"
            },
            {
                "value": 19178,
                "label": "Kamunting",
                "label_ar": "كامونتينغ",
                "label_fr": "Kamunting"
            },
            {
                "value": 19147,
                "label": "Teronoh",
                "label_ar": "تيرونوه",
                "label_fr": "Teronoh"
            },
            {
                "value": 19268,
                "label": "Temoh",
                "label_ar": "تموه",
                "label_fr": "Temoh"
            },
            {
                "value": 19069,
                "label": "Taman Bukit Skudai",
                "label_ar": "تامان بوكيت سكوداي",
                "label_fr": "Taman Bukit Skudai"
            },
            {
                "value": 19192,
                "label": "Lukut",
                "label_ar": "لوكوت",
                "label_fr": "Lukut"
            },
            {
                "value": 19034,
                "label": "Taiping",
                "label_ar": "تايبينغ",
                "label_fr": "Taiping"
            },
            {
                "value": 19060,
                "label": "Simpang Tiga",
                "label_ar": "سيمبانج تيجا",
                "label_fr": "Simpang Tiga"
            },
            {
                "value": 19133,
                "label": "Chemor",
                "label_ar": "كيمور",
                "label_fr": "Chemor"
            },
            {
                "value": 19208,
                "label": "Bidur",
                "label_ar": "بيدور",
                "label_fr": "Bidur"
            },
            {
                "value": 19105,
                "label": "Miri",
                "label_ar": "ميري",
                "label_fr": "Miri"
            },
            {
                "value": 19258,
                "label": "Menglembu",
                "label_ar": "مينجليمبو",
                "label_fr": "Menglembu"
            },
            {
                "value": 19243,
                "label": "Batang Berjuntai",
                "label_ar": "باتانج بيرجونتاي",
                "label_fr": "Batang Berjuntai"
            },
            {
                "value": 19285,
                "label": "Genting",
                "label_ar": "جنتنج",
                "label_fr": "Genting"
            },
            {
                "value": 19172,
                "label": "Simunjan",
                "label_ar": "سيمونجان",
                "label_fr": "Simunjan"
            },
            {
                "value": 19150,
                "label": "Sungai Puyu",
                "label_ar": "سونغاي بويو",
                "label_fr": "Sungai Puyu"
            },
            {
                "value": 19033,
                "label": "Bayan Lepas",
                "label_ar": "بيان ليباس",
                "label_fr": "Bayan Lepas"
            },
            {
                "value": 19298,
                "label": "Machang",
                "label_ar": "ماتشانج",
                "label_fr": "Machang"
            },
            {
                "value": 19076,
                "label": "George Town",
                "label_ar": "جورج تاون",
                "label_fr": "George Town"
            },
            {
                "value": 19114,
                "label": "Raub",
                "label_ar": "راوب",
                "label_fr": "Raub"
            },
            {
                "value": 19216,
                "label": "Bagan Serai",
                "label_ar": "باغان سيراي",
                "label_fr": "Bagan Serai"
            },
            {
                "value": 19122,
                "label": "Kampung Baharu Nilai",
                "label_ar": "كامبونج باهارو نيلاي",
                "label_fr": "Kampung Baharu Nilai"
            },
            {
                "value": 19175,
                "label": "Kampong Changkat",
                "label_ar": "كامبونج تشانغكات",
                "label_fr": "Kampong Changkat"
            },
            {
                "value": 19246,
                "label": "Juasseh",
                "label_ar": "جواسة",
                "label_fr": "Juasseh"
            },
            {
                "value": 19287,
                "label": "Sungai Udang",
                "label_ar": "سونغاي أودانغ",
                "label_fr": "Sungai Udang"
            },
            {
                "value": 19140,
                "label": "Kuala Penyu",
                "label_ar": "كوالا بينيو",
                "label_fr": "Kuala Penyu"
            },
            {
                "value": 19129,
                "label": "Seberang Jaya",
                "label_ar": "سيبرانج جايا",
                "label_fr": "Seberang Jaya"
            },
            {
                "value": 19106,
                "label": "Sibu",
                "label_ar": "سيبو",
                "label_fr": "Sibu"
            },
            {
                "value": 19036,
                "label": "Kuantan",
                "label_ar": "كوانتان",
                "label_fr": "Kuantan"
            },
            {
                "value": 19198,
                "label": "Ayer Tawar",
                "label_ar": "آير طوار",
                "label_fr": "Ayer Tawar"
            },
            {
                "value": 19230,
                "label": "Kapar",
                "label_ar": "كابار",
                "label_fr": "Kapar"
            },
            {
                "value": 19082,
                "label": "Taman Prai",
                "label_ar": "تامان براي",
                "label_fr": "Taman Prai"
            },
            {
                "value": 19244,
                "label": "Kuala Kubu Baharu",
                "label_ar": "كوالا كوبو باهارو",
                "label_fr": "Kuala Kubu Baharu"
            },
            {
                "value": 19097,
                "label": "Butterworth",
                "label_ar": "بتروورث",
                "label_fr": "Butterworth"
            },
            {
                "value": 19265,
                "label": "Pengerang",
                "label_ar": "بينغيرانج",
                "label_fr": "Pengerang"
            },
            {
                "value": 19143,
                "label": "Kota Baharu",
                "label_ar": "كوتا باهارو",
                "label_fr": "Kota Baharu"
            },
            {
                "value": 19113,
                "label": "Mentekab",
                "label_ar": "مينتكاب",
                "label_fr": "Mentekab"
            },
            {
                "value": 19279,
                "label": "Parit",
                "label_ar": "باريت",
                "label_fr": "Parit"
            },
            {
                "value": 19085,
                "label": "Itam",
                "label_ar": "إيتام",
                "label_fr": "Itam"
            },
            {
                "value": 19026,
                "label": "Petaling Jaya",
                "label_ar": "عقد الإيجار",
                "label_fr": "Petaling Jaya"
            },
            {
                "value": 19170,
                "label": "Pudu",
                "label_ar": "بودو",
                "label_fr": "Pudu"
            },
            {
                "value": 19126,
                "label": "Tampin",
                "label_ar": "تامبين",
                "label_fr": "Tampin"
            },
            {
                "value": 19145,
                "label": "Tanjong",
                "label_ar": "تانجونج",
                "label_fr": "Tanjong"
            },
            {
                "value": 19240,
                "label": "Kertih",
                "label_ar": "كرتيه",
                "label_fr": "Kertih"
            },
            {
                "value": 19193,
                "label": "Gemas",
                "label_ar": "جيماس",
                "label_fr": "Gemas"
            },
            {
                "value": 19157,
                "label": "Membakut",
                "label_ar": "ميمباكوت",
                "label_fr": "Membakut"
            },
            {
                "value": 19173,
                "label": "Jertih",
                "label_ar": "جيرتيه",
                "label_fr": "Jertih"
            },
            {
                "value": 19226,
                "label": "Gerik",
                "label_ar": "جيريك",
                "label_fr": "Gerik"
            },
            {
                "value": 19062,
                "label": "Lintang",
                "label_ar": "لينتانج",
                "label_fr": "Lintang"
            },
            {
                "value": 19100,
                "label": "Merlimau",
                "label_ar": "ميرليماو",
                "label_fr": "Merlimau"
            },
            {
                "value": 19277,
                "label": "Marang",
                "label_ar": "مارانج",
                "label_fr": "Marang"
            },
            {
                "value": 19177,
                "label": "Bukit Gambir",
                "label_ar": "بوكيت جامبير",
                "label_fr": "Bukit Gambir"
            },
            {
                "value": 19154,
                "label": "Kampung Ayer Keroh",
                "label_ar": "كامبونج آير كيروه",
                "label_fr": "Kampung Ayer Keroh"
            },
            {
                "value": 19070,
                "label": "Batang Kali",
                "label_ar": "باتانج كالي",
                "label_fr": "Batang Kali"
            },
            {
                "value": 19118,
                "label": "Tanah Merah",
                "label_ar": "تانا ميراه",
                "label_fr": "Tanah Merah"
            },
            {
                "value": 19264,
                "label": "Simpang Renggam",
                "label_ar": "Simpang Renggam",
                "label_fr": "Simpang Renggam"
            },
            {
                "value": 19088,
                "label": "Muar town",
                "label_ar": "بلدة موار",
                "label_fr": "Ville de Muar"
            },
            {
                "value": 19101,
                "label": "Kluang",
                "label_ar": "كلوانج",
                "label_fr": "Kluang"
            },
            {
                "value": 19283,
                "label": "Tampoi",
                "label_ar": "تامبوي",
                "label_fr": "Tampoi"
            },
            {
                "value": 19250,
                "label": "Pusing",
                "label_ar": "يلوح",
                "label_fr": "Pousser"
            },
            {
                "value": 19163,
                "label": "Baru",
                "label_ar": "بارو",
                "label_fr": "Baru"
            },
            {
                "value": 19271,
                "label": "Sungai Besi",
                "label_ar": "Sungai Besi",
                "label_fr": "Sungai Besi"
            },
            {
                "value": 19191,
                "label": "Jasin",
                "label_ar": "جاسين",
                "label_fr": "Jasin"
            },
            {
                "value": 19107,
                "label": "Pengkalan Chepa",
                "label_ar": "Pengkalan Chepa",
                "label_fr": "Pengkalan Chepa"
            },
            {
                "value": 19087,
                "label": "Kulai",
                "label_ar": "كولاي",
                "label_fr": "Kulai"
            },
            {
                "value": 19207,
                "label": "Sarikei",
                "label_ar": "ساريكي",
                "label_fr": "Sarikei"
            },
            {
                "value": 19030,
                "label": "Setapak",
                "label_ar": "سيتاباك",
                "label_fr": "Setapak"
            },
            {
                "value": 19116,
                "label": "Temerluh",
                "label_ar": "تيميرلوه",
                "label_fr": "Temerluh"
            },
            {
                "value": 19050,
                "label": "Sungai Petani",
                "label_ar": "سونغاي بيتاني",
                "label_fr": "Sungai Petani"
            },
            {
                "value": 19180,
                "label": "Jerantut",
                "label_ar": "جيرانتوت",
                "label_fr": "Jerantut"
            },
            {
                "value": 19146,
                "label": "Parit Raja",
                "label_ar": "باريت رجا",
                "label_fr": "Parit Raja"
            },
            {
                "value": 19171,
                "label": "Pandan",
                "label_ar": "باندان",
                "label_fr": "Pandan"
            },
            {
                "value": 19290,
                "label": "Broga",
                "label_ar": "بروجا",
                "label_fr": "Broga"
            },
            {
                "value": 19124,
                "label": "Kuala Lipis",
                "label_ar": "كوالا ليبيس",
                "label_fr": "Kuala Lipis"
            },
            {
                "value": 19162,
                "label": "Keramat Pulai",
                "label_ar": "كرامات بولاي",
                "label_fr": "Keramat Pulai"
            },
            {
                "value": 19210,
                "label": "Masjid Tanah",
                "label_ar": "مسجد طنة",
                "label_fr": "Masjid Tanah"
            },
            {
                "value": 19189,
                "label": "Kampong Kahang Batu Twenty-eight",
                "label_ar": "كامبونج كاهانج باتو ثمانية وعشرون",
                "label_fr": "Kampong Kahang Batu vingt-huit"
            },
            {
                "value": 19138,
                "label": "Taman Kota Jaya",
                "label_ar": "تامان كوتا جايا",
                "label_fr": "Taman Kota Jaya"
            },
            {
                "value": 19203,
                "label": "Padang Rengas",
                "label_ar": "بادانج رينجاس",
                "label_fr": "Padang Rengas"
            },
            {
                "value": 19241,
                "label": "Cukai",
                "label_ar": "كوكاي",
                "label_fr": "Cukai"
            },
            {
                "value": 19182,
                "label": "Jeram",
                "label_ar": "جيرام",
                "label_fr": "Jéram"
            },
            {
                "value": 19249,
                "label": "Tanjong Malim",
                "label_ar": "تانجونج ماليم",
                "label_fr": "Tanjong Malim"
            },
            {
                "value": 19214,
                "label": "Serdang",
                "label_ar": "سيردانغ",
                "label_fr": "Serdang"
            },
            {
                "value": 19215,
                "label": "Bongawan",
                "label_ar": "بونغوان",
                "label_fr": "Bongawan"
            },
            {
                "value": 19159,
                "label": "Pasir Mas",
                "label_ar": "باسير ماس",
                "label_fr": "Pasir Mas"
            },
            {
                "value": 19205,
                "label": "Jelutong",
                "label_ar": "جيلوتونج",
                "label_fr": "Jelutong"
            },
            {
                "value": 19115,
                "label": "Dungun",
                "label_ar": "دونجون",
                "label_fr": "Dungun"
            },
            {
                "value": 19094,
                "label": "Sitiawan",
                "label_ar": "سيتياوان",
                "label_fr": "Sitiawan"
            },
            {
                "value": 19263,
                "label": "Pendang",
                "label_ar": "بيندانج",
                "label_fr": "Pendang"
            },
            {
                "value": 19095,
                "label": "Kepong",
                "label_ar": "كيبونج",
                "label_fr": "Kepong"
            },
            {
                "value": 19144,
                "label": "Dengkil",
                "label_ar": "دينكيل",
                "label_fr": "Dengkil"
            },
            {
                "value": 19024,
                "label": "Pantai",
                "label_ar": "بانتاي",
                "label_fr": "Pantai"
            },
            {
                "value": 19103,
                "label": "Kota Tinggi",
                "label_ar": "كوتا تينجي",
                "label_fr": "Kota Tinggi"
            },
            {
                "value": 19083,
                "label": "Sabak Bernam",
                "label_ar": "ساباك برنام",
                "label_fr": "Sabak Bernam"
            },
            {
                "value": 19072,
                "label": "Bandar Tun Razak",
                "label_ar": "بندر تون رزاق",
                "label_fr": "Bandar Tun Razak"
            },
            {
                "value": 19202,
                "label": "Kompleks Sukan Tenom",
                "label_ar": "كومبلكس سوكان تينوم",
                "label_fr": "Kompleks Sukan Tenom"
            },
            {
                "value": 19125,
                "label": "Bintulu",
                "label_ar": "بينتولو",
                "label_fr": "Bintulu"
            },
            {
                "value": 19292,
                "label": "Kampung Tasek",
                "label_ar": "كامبونج تاسيك",
                "label_fr": "Kampung Tasek"
            },
            {
                "value": 19261,
                "label": "Bau",
                "label_ar": "باو",
                "label_fr": "Bau"
            },
            {
                "value": 19120,
                "label": "Keningau",
                "label_ar": "كينينغاو",
                "label_fr": "Keningau"
            },
            {
                "value": 19123,
                "label": "Lahad Datu",
                "label_ar": "لحد داتو",
                "label_fr": "Lahad Datu"
            },
            {
                "value": 19289,
                "label": "Jawi",
                "label_ar": "جاوي",
                "label_fr": "Jawi"
            },
            {
                "value": 19281,
                "label": "Inanam",
                "label_ar": "إنانام",
                "label_fr": "Inanam"
            },
            {
                "value": 19041,
                "label": "Bukit Mertajam",
                "label_ar": "بوكيت ميرتاجام",
                "label_fr": "Bukit Mertajam"
            },
            {
                "value": 19096,
                "label": "Perai",
                "label_ar": "بيري",
                "label_fr": "Perai"
            },
            {
                "value": 19276,
                "label": "Batu Feringgi",
                "label_ar": "باتو فيرينجي",
                "label_fr": "Batu Feringgi"
            },
            {
                "value": 19160,
                "label": "Changloon",
                "label_ar": "شانجلون",
                "label_fr": "Changloon"
            },
            {
                "value": 19028,
                "label": "Kajang",
                "label_ar": "كاجانغ",
                "label_fr": "Kajang"
            },
            {
                "value": 19136,
                "label": "Tanjong Karang",
                "label_ar": "تانجونج كارانج",
                "label_fr": "Tanjong Karang"
            },
            {
                "value": 19242,
                "label": "Hutan Melintang",
                "label_ar": "هوتان ميلينتانغ",
                "label_fr": "Hutan Melintang"
            },
            {
                "value": 19156,
                "label": "Sandakan",
                "label_ar": "سانداكان",
                "label_fr": "Sandakan"
            },
            {
                "value": 19196,
                "label": "Bedong",
                "label_ar": "بيدونج",
                "label_fr": "Bedong"
            },
            {
                "value": 19027,
                "label": "Shah Alam",
                "label_ar": "شاه علام",
                "label_fr": "Shah Alam"
            },
            {
                "value": 19236,
                "label": "Permatang Pauh",
                "label_ar": "بيرماتانغ باوه",
                "label_fr": "Permatang Pauh"
            },
            {
                "value": 19058,
                "label": "Kampung Likas",
                "label_ar": "كامبونج ليكاس",
                "label_fr": "Kampung Likas"
            },
            {
                "value": 19225,
                "label": "Rengit",
                "label_ar": "رينجيت",
                "label_fr": "Rengit"
            },
            {
                "value": 19075,
                "label": "Alor Star",
                "label_ar": "ألور ستار",
                "label_fr": "Alor Star"
            },
            {
                "value": 19056,
                "label": "Seri Kembangan",
                "label_ar": "سيري كيمبانغان",
                "label_fr": "Seri Kembangan"
            },
            {
                "value": 19234,
                "label": "Sentul",
                "label_ar": "سينتول",
                "label_fr": "Sentul"
            }
        ]
    },
    {
        "country": "Republic of Korea",
        "cities": [
            {
                "value": 19349,
                "label": "Icheon-si",
                "label_ar": "إيتشيون سي",
                "label_fr": "Icheon-si"
            },
            {
                "value": 19378,
                "label": "Songam",
                "label_ar": "سونغام",
                "label_fr": "Songam"
            },
            {
                "value": 19337,
                "label": "Jinju",
                "label_ar": "جينجو",
                "label_fr": "Jinju"
            },
            {
                "value": 19305,
                "label": "Yongin",
                "label_ar": "يونغين",
                "label_fr": "Yongin"
            },
            {
                "value": 19317,
                "label": "Ulsan",
                "label_ar": "أولسان",
                "label_fr": "Ulsan"
            },
            {
                "value": 19308,
                "label": "Gwangju",
                "label_ar": "جوانجو",
                "label_fr": "Gwangju"
            },
            {
                "value": 19396,
                "label": "Chilgok",
                "label_ar": "تشيلجوك",
                "label_fr": "Chilgok"
            },
            {
                "value": 19309,
                "label": "Gwangmyeong",
                "label_ar": "جوانغميونغ",
                "label_fr": "Gwangmyeong"
            },
            {
                "value": 19330,
                "label": "Gongju",
                "label_ar": "Gongju",
                "label_fr": "Gongju"
            },
            {
                "value": 19345,
                "label": "Songnim",
                "label_ar": "Songnim",
                "label_fr": "Songnim"
            },
            {
                "value": 19319,
                "label": "Hanam",
                "label_ar": "هانام",
                "label_fr": "Hanam"
            },
            {
                "value": 19332,
                "label": "Sasang",
                "label_ar": "ساسانغ",
                "label_fr": "Sasang"
            },
            {
                "value": 19320,
                "label": "Pyong-gol",
                "label_ar": "بيونغ جول",
                "label_fr": "Pyong-gol"
            },
            {
                "value": 19326,
                "label": "Wonju",
                "label_ar": "ونجو",
                "label_fr": "Wonju"
            },
            {
                "value": 19329,
                "label": "Ansan-si",
                "label_ar": "أنسان سي",
                "label_fr": "Ansan-si"
            },
            {
                "value": 19363,
                "label": "Sangdo-dong",
                "label_ar": "سانجدو دونج",
                "label_fr": "Sangdo-dong"
            },
            {
                "value": 19338,
                "label": "Uiwang",
                "label_ar": "أويانغ",
                "label_fr": "Uiwang"
            },
            {
                "value": 19369,
                "label": "Duchon",
                "label_ar": "دوتشون",
                "label_fr": "Duchon"
            },
            {
                "value": 19359,
                "label": "Nae-ri",
                "label_ar": "ناي ري",
                "label_fr": "Nae-ri"
            },
            {
                "value": 19343,
                "label": "Jeonju",
                "label_ar": "جيونجو",
                "label_fr": "Jeonju"
            },
            {
                "value": 19352,
                "label": "Deokjin",
                "label_ar": "ديوكجين",
                "label_fr": "Deokjin"
            },
            {
                "value": 19377,
                "label": "Chungnim",
                "label_ar": "تشونغنيم",
                "label_fr": "Chungnim"
            },
            {
                "value": 19306,
                "label": "Kwanghui-dong",
                "label_ar": "كوانغوي دونغ",
                "label_fr": "Kwanghui-dong"
            },
            {
                "value": 19368,
                "label": "Seong-dong",
                "label_ar": "سيونغ دونغ",
                "label_fr": "Seong-dong"
            },
            {
                "value": 19361,
                "label": "Okpo-dong",
                "label_ar": "أوكبو دونغ",
                "label_fr": "Okpo-dong"
            },
            {
                "value": 19380,
                "label": "Ap'o",
                "label_ar": "أبو",
                "label_fr": "Ap'o"
            },
            {
                "value": 19325,
                "label": "Seocho",
                "label_ar": "سيوتشو",
                "label_fr": "Seocho"
            },
            {
                "value": 19384,
                "label": "Hansol",
                "label_ar": "هانسول",
                "label_fr": "Hansol"
            },
            {
                "value": 19342,
                "label": "Changwon",
                "label_ar": "تشانغوون",
                "label_fr": "Changwon"
            },
            {
                "value": 19385,
                "label": "Songang",
                "label_ar": "Songang",
                "label_fr": "Songang"
            },
            {
                "value": 19366,
                "label": "Taebuk",
                "label_ar": "تايبوك",
                "label_fr": "Taebuk"
            },
            {
                "value": 19390,
                "label": "Ulchin",
                "label_ar": "أولشين",
                "label_fr": "Ulchin"
            },
            {
                "value": 19314,
                "label": "Namyang",
                "label_ar": "ناميانغ",
                "label_fr": "Namyang"
            },
            {
                "value": 19304,
                "label": "Cheonan",
                "label_ar": "تشونان",
                "label_fr": "Cheonan"
            },
            {
                "value": 19376,
                "label": "Janghowon",
                "label_ar": "Janghowon",
                "label_fr": "Janghowon"
            },
            {
                "value": 19391,
                "label": "Namhyang-dong",
                "label_ar": "نامهيانغ دونغ",
                "label_fr": "Namhyang-dong"
            },
            {
                "value": 19322,
                "label": "Yangsan",
                "label_ar": "يانغسان",
                "label_fr": "Yangsan"
            },
            {
                "value": 19389,
                "label": "Unpo",
                "label_ar": "أونبو",
                "label_fr": "Unpo"
            },
            {
                "value": 19344,
                "label": "Yeosu",
                "label_ar": "يوسو",
                "label_fr": "Yeosu"
            },
            {
                "value": 19394,
                "label": "Haesan",
                "label_ar": "حيسان",
                "label_fr": "Haesan"
            },
            {
                "value": 19379,
                "label": "Tongan",
                "label_ar": "تونجا",
                "label_fr": "Tongan"
            },
            {
                "value": 19335,
                "label": "Ilsan-dong",
                "label_ar": "إيلسان دونغ",
                "label_fr": "Ilsan-dong"
            },
            {
                "value": 19313,
                "label": "Suwon-si",
                "label_ar": "سوون سي",
                "label_fr": "Suwon-si"
            },
            {
                "value": 19370,
                "label": "Gyeongju",
                "label_ar": "جيونجو",
                "label_fr": "Gyeongju"
            },
            {
                "value": 19392,
                "label": "T'aebaek",
                "label_ar": "طباعك",
                "label_fr": "T'aebaek"
            },
            {
                "value": 19386,
                "label": "Hyangyang",
                "label_ar": "هيانغيانغ",
                "label_fr": "Hyangyang"
            },
            {
                "value": 19351,
                "label": "Iksan",
                "label_ar": "إيكسان",
                "label_fr": "Iksan"
            },
            {
                "value": 19334,
                "label": "Chuncheon",
                "label_ar": "تشانشيون",
                "label_fr": "Chuncheon"
            },
            {
                "value": 19360,
                "label": "Suncheon",
                "label_ar": "سون تشون",
                "label_fr": "Suncheon"
            },
            {
                "value": 19382,
                "label": "Se-ri",
                "label_ar": "سي ري",
                "label_fr": "Se-ri"
            },
            {
                "value": 19395,
                "label": "Chungju",
                "label_ar": "تشونغجو",
                "label_fr": "Chungju"
            },
            {
                "value": 19388,
                "label": "Gumi",
                "label_ar": "جومي",
                "label_fr": "Gumi"
            },
            {
                "value": 19328,
                "label": "Daegu",
                "label_ar": "دايجو",
                "label_fr": "Daegu"
            },
            {
                "value": 19312,
                "label": "Seongnam-si",
                "label_ar": "سيونغنام سي",
                "label_fr": "Seongnam-si"
            },
            {
                "value": 19341,
                "label": "Pohang",
                "label_ar": "بوهانج",
                "label_fr": "Pohang"
            },
            {
                "value": 19302,
                "label": "Incheon",
                "label_ar": "انشيون",
                "label_fr": "Incheon"
            },
            {
                "value": 19358,
                "label": "Gunnae",
                "label_ar": "جوناي",
                "label_fr": "Gunnae"
            },
            {
                "value": 19323,
                "label": "Daejeon",
                "label_ar": "دايجون",
                "label_fr": "Daejeon"
            },
            {
                "value": 19339,
                "label": "Gangneung",
                "label_ar": "جانجنيونج",
                "label_fr": "Gangneung"
            },
            {
                "value": 19315,
                "label": "Namyangju",
                "label_ar": "ناميانغجو",
                "label_fr": "Namyangju"
            },
            {
                "value": 19307,
                "label": "Pon-dong",
                "label_ar": "بون دونغ",
                "label_fr": "Pon-dong"
            },
            {
                "value": 19324,
                "label": "Nonsan",
                "label_ar": "نونسان",
                "label_fr": "Nonsan"
            },
            {
                "value": 19346,
                "label": "Gimhae",
                "label_ar": "Gimhae",
                "label_fr": "Gimhae"
            },
            {
                "value": 19373,
                "label": "Asan",
                "label_ar": "أسان",
                "label_fr": "Asan"
            },
            {
                "value": 19321,
                "label": "Anyang-si",
                "label_ar": "انيانغ سي",
                "label_fr": "Anyang-si"
            },
            {
                "value": 19303,
                "label": "Paju",
                "label_ar": "باجو",
                "label_fr": "Paju"
            },
            {
                "value": 19381,
                "label": "Jecheon",
                "label_ar": "جيتشيون",
                "label_fr": "Jecheon"
            },
            {
                "value": 19374,
                "label": "Miryang",
                "label_ar": "ميريانغ",
                "label_fr": "Miryang"
            },
            {
                "value": 19375,
                "label": "Wonmi-gu",
                "label_ar": "ونمي جو",
                "label_fr": "Wonmi-gu"
            },
            {
                "value": 19301,
                "label": "Seoul",
                "label_ar": "سيول",
                "label_fr": "Séoul"
            },
            {
                "value": 19331,
                "label": "Haeundae",
                "label_ar": "هايونداي",
                "label_fr": "Haeundae"
            },
            {
                "value": 19353,
                "label": "Koyang-dong",
                "label_ar": "كويانغ دونغ",
                "label_fr": "Koyang-dong"
            },
            {
                "value": 19347,
                "label": "Songjeong",
                "label_ar": "Songjeong",
                "label_fr": "Songjeong"
            },
            {
                "value": 19348,
                "label": "Hyoja-dong",
                "label_ar": "هيوجا دونغ",
                "label_fr": "Hyoja-dong"
            },
            {
                "value": 19350,
                "label": "Kimso",
                "label_ar": "كيمسو",
                "label_fr": "Kimso"
            },
            {
                "value": 19355,
                "label": "Anseong",
                "label_ar": "أنسونغ",
                "label_fr": "Anseong"
            },
            {
                "value": 19387,
                "label": "Gyeongsan-si",
                "label_ar": "جيونغسان سي",
                "label_fr": "Gyeongsan-si"
            },
            {
                "value": 19316,
                "label": "Jeju-si",
                "label_ar": "جيجو سي",
                "label_fr": "Jeju-si"
            },
            {
                "value": 19364,
                "label": "Cheongju-si",
                "label_ar": "تشيونجو سي",
                "label_fr": "Cheongju-si"
            },
            {
                "value": 19383,
                "label": "Ka-ri",
                "label_ar": "كا ري",
                "label_fr": "Ka-ri"
            },
            {
                "value": 19393,
                "label": "Hadong",
                "label_ar": "هادونج",
                "label_fr": "Hadong"
            },
            {
                "value": 19318,
                "label": "Osan",
                "label_ar": "أوسان",
                "label_fr": "Osan"
            },
            {
                "value": 19327,
                "label": "Kisa",
                "label_ar": "كيزا",
                "label_fr": "Kisa"
            },
            {
                "value": 19357,
                "label": "Mapo-dong",
                "label_ar": "مابو دونغ",
                "label_fr": "Mapo-dong"
            },
            {
                "value": 19311,
                "label": "Busan",
                "label_ar": "بوسان",
                "label_fr": "Busan"
            },
            {
                "value": 19340,
                "label": "Yongsan-dong",
                "label_ar": "يونجسان دونج",
                "label_fr": "Yongsan-dong"
            },
            {
                "value": 19310,
                "label": "Tang-ni",
                "label_ar": "تانغ ني",
                "label_fr": "Tang-ni"
            },
            {
                "value": 19372,
                "label": "Seosan City",
                "label_ar": "مدينة سوسان",
                "label_fr": "Ville de Seosan"
            },
            {
                "value": 19362,
                "label": "Moppo",
                "label_ar": "موبو",
                "label_fr": "Moppo"
            },
            {
                "value": 19371,
                "label": "Andong",
                "label_ar": "أندونج",
                "label_fr": "Andong"
            },
            {
                "value": 19336,
                "label": "Naju",
                "label_ar": "ناجو",
                "label_fr": "Naju"
            },
            {
                "value": 19367,
                "label": "Yeoju",
                "label_ar": "يوجو",
                "label_fr": "Yeoju"
            },
            {
                "value": 19365,
                "label": "Ch'aeun",
                "label_ar": "تشايون",
                "label_fr": "Ch'aeun"
            },
            {
                "value": 19354,
                "label": "Samsung",
                "label_ar": "سامسونج",
                "label_fr": "Samsung"
            },
            {
                "value": 19333,
                "label": "Bucheon-si",
                "label_ar": "بوتشون سي",
                "label_fr": "Bucheon-si"
            },
            {
                "value": 19356,
                "label": "Samjung-ni",
                "label_ar": "سامجونغ ني",
                "label_fr": "Samjung-ni"
            }
        ]
    },
    {
        "country": "Hong Kong",
        "cities": [
            {
                "value": 19435,
                "label": "Fo Tan",
                "label_ar": "فو تان",
                "label_fr": "Fo Tan"
            },
            {
                "value": 19419,
                "label": "Sham Shui Po",
                "label_ar": "شام شوي بو",
                "label_fr": "Sham Shui Po"
            },
            {
                "value": 19407,
                "label": "Tseung Kwan O",
                "label_ar": "Tseung Kwan O",
                "label_fr": "Tseung Kwan O"
            },
            {
                "value": 19430,
                "label": "Kwai Chung",
                "label_ar": "كواي تشونغ",
                "label_fr": "Kwai Chung"
            },
            {
                "value": 19427,
                "label": "Cheung Sha Wan",
                "label_ar": "تشيونغ شا وان",
                "label_fr": "Cheung Sha Wan"
            },
            {
                "value": 19467,
                "label": "Chung Hau",
                "label_ar": "تشونغ هاو",
                "label_fr": "Chung Hau"
            },
            {
                "value": 19418,
                "label": "Chai Wan Kok",
                "label_ar": "تشاي وان كوك",
                "label_fr": "Chai Wan Kok"
            },
            {
                "value": 19405,
                "label": "Repulse Bay",
                "label_ar": "ريبالس باي",
                "label_fr": "Repulse Bay"
            },
            {
                "value": 19433,
                "label": "Hong Kong",
                "label_ar": "هونج كونج",
                "label_fr": "Hong Kong"
            },
            {
                "value": 19453,
                "label": "Fa Yuen",
                "label_ar": "فا يوين",
                "label_fr": "Fa Yuen"
            },
            {
                "value": 19445,
                "label": "Lai Chi Wo",
                "label_ar": "لاي تشي وو",
                "label_fr": "Lai Chi Wo"
            },
            {
                "value": 19456,
                "label": "Kowloon Tong",
                "label_ar": "كولون تونغ",
                "label_fr": "Kowloon Tong"
            },
            {
                "value": 19411,
                "label": "To Kwa Wan",
                "label_ar": "إلى كوا وان",
                "label_fr": "À Kwa Wan"
            },
            {
                "value": 19458,
                "label": "Ma Wan",
                "label_ar": "ما وان",
                "label_fr": "Ma Wan"
            },
            {
                "value": 19436,
                "label": "Tsimshatsui",
                "label_ar": "تسيم شا تسوي",
                "label_fr": "Tsim Sha Tsui"
            },
            {
                "value": 19426,
                "label": "Shek Tong Tsui",
                "label_ar": "شيك تونغ تسوي",
                "label_fr": "Shek Tong Tsui"
            },
            {
                "value": 19448,
                "label": "Tai Lin Pai",
                "label_ar": "تاي لين باي",
                "label_fr": "Tai Lin Pai"
            },
            {
                "value": 19424,
                "label": "Kwun Hang",
                "label_ar": "كوون هانغ",
                "label_fr": "Kwun Hang"
            },
            {
                "value": 19446,
                "label": "Shau Kei Wan",
                "label_ar": "شاو كي وان",
                "label_fr": "Shau Kei Wan"
            },
            {
                "value": 19464,
                "label": "Ting Kau",
                "label_ar": "تينغ كاو",
                "label_fr": "Ting Kau"
            },
            {
                "value": 19413,
                "label": "Tuen Mun San Hui",
                "label_ar": "توين مون سان هوي",
                "label_fr": "Tuen Mun San Hui"
            },
            {
                "value": 19420,
                "label": "Mid Levels",
                "label_ar": "مستويات متوسطة",
                "label_fr": "Niveaux intermédiaires"
            },
            {
                "value": 19434,
                "label": "Tai Hang",
                "label_ar": "تاي هانغ",
                "label_fr": "Tai Hang"
            },
            {
                "value": 19471,
                "label": "Pak Ngan Heung",
                "label_ar": "باك نجان هيونج",
                "label_fr": "Pak Ngan Heung"
            },
            {
                "value": 19463,
                "label": "Tsing Lung Tau",
                "label_ar": "تسينغ لونج تاو",
                "label_fr": "Tsing Lung Tau"
            },
            {
                "value": 19410,
                "label": "Ma On Shan Tsuen",
                "label_ar": "أماه أون شان تسوين",
                "label_fr": "Ma On Shan Tsuen"
            },
            {
                "value": 19479,
                "label": "Shatin",
                "label_ar": "شاتين",
                "label_fr": "Shatin"
            },
            {
                "value": 19442,
                "label": "Shek Kip Mei",
                "label_ar": "شيك كيب مي",
                "label_fr": "Shek Kip Mei"
            },
            {
                "value": 19440,
                "label": "Sha Po Kong",
                "label_ar": "شا بو كونغ",
                "label_fr": "Sha Po Kong"
            },
            {
                "value": 19470,
                "label": "San Tsuen",
                "label_ar": "سان تسوين",
                "label_fr": "San Tsuen"
            },
            {
                "value": 19408,
                "label": "Tai Kok Tsui",
                "label_ar": "تاي كوك تسوي",
                "label_fr": "Tai Kok Tsui"
            },
            {
                "value": 19404,
                "label": "Ying Pun",
                "label_ar": "يينغ بون",
                "label_fr": "Ying Pun"
            },
            {
                "value": 19441,
                "label": "Wan Tsai",
                "label_ar": "وان تساي",
                "label_fr": "Wan Tsai"
            },
            {
                "value": 19398,
                "label": "Shuen Wan",
                "label_ar": "شوين وان",
                "label_fr": "Shuen Wan"
            },
            {
                "value": 19451,
                "label": "Sheung Tsuen",
                "label_ar": "شيونغ تسوين",
                "label_fr": "Sheung Tsuen"
            },
            {
                "value": 19415,
                "label": "Ngau Chi Wan",
                "label_ar": "نجاو تشي وان",
                "label_fr": "Ngau Chi Wan"
            },
            {
                "value": 19414,
                "label": "Ma Yau Tong",
                "label_ar": "ما ياو تونغ",
                "label_fr": "Ma Yau Tong"
            },
            {
                "value": 19409,
                "label": "Tai Wai",
                "label_ar": "تاي واي",
                "label_fr": "Tai Wai"
            },
            {
                "value": 19443,
                "label": "Aberdeen",
                "label_ar": "أبردين",
                "label_fr": "Aberdeen"
            },
            {
                "value": 19402,
                "label": "Quarry Bay",
                "label_ar": "كواري باي",
                "label_fr": "Quarry Bay"
            },
            {
                "value": 19450,
                "label": "Sheung Shui",
                "label_ar": "شيونغ شوي",
                "label_fr": "Sheung Shui"
            },
            {
                "value": 19431,
                "label": "Sha Tin Wai",
                "label_ar": "شا تين واي",
                "label_fr": "Sha Tin Wai"
            },
            {
                "value": 19472,
                "label": "Lam Tei",
                "label_ar": "لام تي",
                "label_fr": "Lam Tei"
            },
            {
                "value": 19474,
                "label": "Lo So Shing",
                "label_ar": "لو سو شينغ",
                "label_fr": "Lo So Shing"
            },
            {
                "value": 19444,
                "label": "Tai Po",
                "label_ar": "تاي بو",
                "label_fr": "Tai Po"
            },
            {
                "value": 19432,
                "label": "Tin Shui Wai",
                "label_ar": "القصدير شوي واي",
                "label_fr": "Tin shui wai"
            },
            {
                "value": 19466,
                "label": "Lin Fa Tei",
                "label_ar": "لين فا تي",
                "label_fr": "Lin Fa Tei"
            },
            {
                "value": 19439,
                "label": "Peng Chau",
                "label_ar": "بينغ تشاو",
                "label_fr": "Peng Chau"
            },
            {
                "value": 19429,
                "label": "Yuen Long San Hui",
                "label_ar": "يوين لونغ سان هوي",
                "label_fr": "Yuen Long San Hui"
            },
            {
                "value": 19468,
                "label": "Ping Yeung",
                "label_ar": "بينغ يونغ",
                "label_fr": "Ping Yeung"
            },
            {
                "value": 19459,
                "label": "Cha Kwo Ling",
                "label_ar": "تشا كو لينغ",
                "label_fr": "Cha Kwo Ling"
            },
            {
                "value": 19454,
                "label": "Chek Chue",
                "label_ar": "تشيك تشو",
                "label_fr": "Chek Chue"
            },
            {
                "value": 19401,
                "label": "Kowloon",
                "label_ar": "كولون",
                "label_fr": "Kowloon"
            },
            {
                "value": 19412,
                "label": "Wong Tai Sin",
                "label_ar": "وونغ تاي سين",
                "label_fr": "Wong Tai Sin"
            },
            {
                "value": 19460,
                "label": "Wo Che",
                "label_ar": "وو تشي",
                "label_fr": "Wo Che"
            },
            {
                "value": 19406,
                "label": "Causeway Bay",
                "label_ar": "جسر خليج",
                "label_fr": "Causeway Bay"
            },
            {
                "value": 19457,
                "label": "Ho Man Tin",
                "label_ar": "هو مان تين",
                "label_fr": "Ho Man Tin"
            },
            {
                "value": 19437,
                "label": "Tsz Wan Shan",
                "label_ar": "Tsz Wan Shan",
                "label_fr": "Tsz Wan Shan"
            },
            {
                "value": 19473,
                "label": "Kat O Sheung Wai",
                "label_ar": "كات أو شيونغ واي",
                "label_fr": "Kat O Sheung Wai"
            },
            {
                "value": 19452,
                "label": "Fanling",
                "label_ar": "تهوية",
                "label_fr": "Fanling"
            },
            {
                "value": 19399,
                "label": "Central District",
                "label_ar": "المقاطعة المركزية",
                "label_fr": "District central"
            },
            {
                "value": 19403,
                "label": "Ngau Tau Kok",
                "label_ar": "نجاو تاو كوك",
                "label_fr": "Ngau Tau Kok"
            },
            {
                "value": 19438,
                "label": "San Tung Chung Hang",
                "label_ar": "سان تونج تشونج هانج",
                "label_fr": "San Tung Chung Hang"
            },
            {
                "value": 19477,
                "label": "Tai Wan To",
                "label_ar": "تاي وان إلى",
                "label_fr": "Tai Wan To"
            },
            {
                "value": 19400,
                "label": "Hung Hom",
                "label_ar": "هونغ هوم",
                "label_fr": "Hung Hom"
            },
            {
                "value": 19421,
                "label": "North Point",
                "label_ar": "نقطة الشمال",
                "label_fr": "point nord"
            },
            {
                "value": 19417,
                "label": "Kennedy Town",
                "label_ar": "مدينة كينيدي",
                "label_fr": "Kennedy Town"
            },
            {
                "value": 19449,
                "label": "Chuen Lung",
                "label_ar": "تشوين الرئة",
                "label_fr": "Poumon de Chuen"
            },
            {
                "value": 19447,
                "label": "Cheung Kong",
                "label_ar": "تشيونغ كونغ",
                "label_fr": "Cheung Kong"
            },
            {
                "value": 19478,
                "label": "Mau Ping",
                "label_ar": "ماو بينغ",
                "label_fr": "Mau Ping"
            },
            {
                "value": 19461,
                "label": "Lam Tin",
                "label_ar": "لام تين",
                "label_fr": "Lam Tin"
            },
            {
                "value": 19416,
                "label": "Yau Ma Tei",
                "label_ar": "ياو ما تي",
                "label_fr": "Yau Ma Tei"
            },
            {
                "value": 19465,
                "label": "Tai Chau To",
                "label_ar": "تاي تشاو",
                "label_fr": "Tai Chau à"
            },
            {
                "value": 19422,
                "label": "Happy Valley",
                "label_ar": "الوادي السعيد",
                "label_fr": "Happy Valley"
            },
            {
                "value": 19428,
                "label": "Sham Tseng",
                "label_ar": "شام تسنغ",
                "label_fr": "Sham Tseng"
            },
            {
                "value": 19469,
                "label": "Wong Chuk Hang",
                "label_ar": "وونغ تشوك هانغ",
                "label_fr": "Wong Chuk Hang"
            },
            {
                "value": 19425,
                "label": "Mong Kok",
                "label_ar": "مونغ كوك",
                "label_fr": "Mong Kok"
            },
            {
                "value": 19423,
                "label": "Sai Keng",
                "label_ar": "ساي كنج",
                "label_fr": "Sai Keng"
            },
            {
                "value": 19462,
                "label": "Nam A",
                "label_ar": "نام أ",
                "label_fr": "Nam A"
            },
            {
                "value": 19475,
                "label": "Sha Tau Kok",
                "label_ar": "شا تاو كوك",
                "label_fr": "Sha Tau Kok"
            },
            {
                "value": 19476,
                "label": "Tin Wan Resettlement Estate",
                "label_ar": "إعادة توطين تين وان العقارية",
                "label_fr": "Domaine de réinstallation de Tin Wan"
            },
            {
                "value": 19455,
                "label": "Tai Tan",
                "label_ar": "تاي تان",
                "label_fr": "Tai Tan"
            }
        ]
    },
    {
        "country": "Taiwan",
        "cities": [
            {
                "value": 19481,
                "label": "Taoyuan",
                "label_ar": "تاويوان",
                "label_fr": "Taoyuan"
            },
            {
                "value": 19536,
                "label": "Dahu",
                "label_ar": "داهو",
                "label_fr": "Dahu"
            },
            {
                "value": 19498,
                "label": "Hualian",
                "label_ar": "هواليان",
                "label_fr": "Hualian"
            },
            {
                "value": 19520,
                "label": "Neihu",
                "label_ar": "نيهو",
                "label_fr": "Neihu"
            },
            {
                "value": 19499,
                "label": "Gaozhongyicun",
                "label_ar": "Gaozhongyicun",
                "label_fr": "Gaozhongyicun"
            },
            {
                "value": 19487,
                "label": "Kao-sung",
                "label_ar": "كاو سونغ",
                "label_fr": "Kao-sung"
            },
            {
                "value": 19506,
                "label": "Matsu",
                "label_ar": "ماتسو",
                "label_fr": "Matsu"
            },
            {
                "value": 19480,
                "label": "Taipei",
                "label_ar": "تايبيه",
                "label_fr": "Taipei"
            },
            {
                "value": 19497,
                "label": "Miaoli",
                "label_ar": "مياولي",
                "label_fr": "Miaoli"
            },
            {
                "value": 19488,
                "label": "Taitung",
                "label_ar": "تايتونج",
                "label_fr": "Taitung"
            },
            {
                "value": 19515,
                "label": "Meilun",
                "label_ar": "ميلون",
                "label_fr": "Meilun"
            },
            {
                "value": 19496,
                "label": "Nantou",
                "label_ar": "نانتو",
                "label_fr": "Nantou"
            },
            {
                "value": 19492,
                "label": "Lugang",
                "label_ar": "لوغانغ",
                "label_fr": "Lugang"
            },
            {
                "value": 19544,
                "label": "Zhuqi",
                "label_ar": "Zhuqi",
                "label_fr": "Zhuqi"
            },
            {
                "value": 19523,
                "label": "Wangtongwen",
                "label_ar": "وانغتونغوين",
                "label_fr": "Wangtongwen"
            },
            {
                "value": 19502,
                "label": "Hengchun",
                "label_ar": "هنغتشون",
                "label_fr": "Hengchun"
            },
            {
                "value": 19505,
                "label": "Maru",
                "label_ar": "مارو",
                "label_fr": "Maru"
            },
            {
                "value": 19540,
                "label": "Nanhua",
                "label_ar": "نانهوا",
                "label_fr": "Nanhua"
            },
            {
                "value": 19543,
                "label": "Shuilin",
                "label_ar": "شويلين",
                "label_fr": "Shuilin"
            },
            {
                "value": 19485,
                "label": "Taichung",
                "label_ar": "تايتشونغ",
                "label_fr": "Taichung"
            },
            {
                "value": 19522,
                "label": "Hemei",
                "label_ar": "هيمي",
                "label_fr": "Hemei"
            },
            {
                "value": 19491,
                "label": "Yunlin County",
                "label_ar": "مقاطعة يونلين",
                "label_fr": "Comté de Yunlin"
            },
            {
                "value": 19530,
                "label": "Wan-hua",
                "label_ar": "وان هوا",
                "label_fr": "Wan-hua"
            },
            {
                "value": 19531,
                "label": "Zhunan",
                "label_ar": "Zhunan",
                "label_fr": "Zhunan"
            },
            {
                "value": 19511,
                "label": "Toucheng",
                "label_ar": "تاتشينج",
                "label_fr": "Toucheng"
            },
            {
                "value": 19509,
                "label": "Shuyi",
                "label_ar": "شوي",
                "label_fr": "Shuyi"
            },
            {
                "value": 19493,
                "label": "Puli",
                "label_ar": "بولي",
                "label_fr": "Puli"
            },
            {
                "value": 19535,
                "label": "Shuiliu",
                "label_ar": "شويليو",
                "label_fr": "Shuiliu"
            },
            {
                "value": 19513,
                "label": "Ma-kung",
                "label_ar": "ما كونغ",
                "label_fr": "Ma-kung"
            },
            {
                "value": 19495,
                "label": "Penghu'ercun",
                "label_ar": "Penghu'ercun",
                "label_fr": "Penghu'ercun"
            },
            {
                "value": 19547,
                "label": "Wanluan",
                "label_ar": "Wanluan",
                "label_fr": "Wanluan"
            },
            {
                "value": 19512,
                "label": "Shoufeng",
                "label_ar": "شوفينج",
                "label_fr": "Shoufeng"
            },
            {
                "value": 19534,
                "label": "Erlin",
                "label_ar": "إرلين",
                "label_fr": "Erlin"
            },
            {
                "value": 19521,
                "label": "Yangmei",
                "label_ar": "يانجمي",
                "label_fr": "Yangmei"
            },
            {
                "value": 19483,
                "label": "Hsinchu",
                "label_ar": "هسينشو",
                "label_fr": "Hsinchu"
            },
            {
                "value": 19510,
                "label": "Budai",
                "label_ar": "بوداي",
                "label_fr": "Budai"
            },
            {
                "value": 19519,
                "label": "Suqin",
                "label_ar": "سقين",
                "label_fr": "Suqin"
            },
            {
                "value": 19508,
                "label": "Shuishang",
                "label_ar": "شويشانغ",
                "label_fr": "Shuishang"
            },
            {
                "value": 19539,
                "label": "Xinji",
                "label_ar": "شينجي",
                "label_fr": "Xinji"
            },
            {
                "value": 19504,
                "label": "Chiayi",
                "label_ar": "تشيايي",
                "label_fr": "Chiayi"
            },
            {
                "value": 19501,
                "label": "Checheng",
                "label_ar": "Checheng",
                "label_fr": "Checheng"
            },
            {
                "value": 19537,
                "label": "Gangshan",
                "label_ar": "جانجشان",
                "label_fr": "Gangshan"
            },
            {
                "value": 19541,
                "label": "Shetou",
                "label_ar": "شيتو",
                "label_fr": "Shetou"
            },
            {
                "value": 19514,
                "label": "Wang'an",
                "label_ar": "وانجان",
                "label_fr": "Wang'an"
            },
            {
                "value": 19494,
                "label": "Pingtung",
                "label_ar": "بينغتونغ",
                "label_fr": "Pingtung"
            },
            {
                "value": 19526,
                "label": "Taichang",
                "label_ar": "تايتشانغ",
                "label_fr": "Taichang"
            },
            {
                "value": 19529,
                "label": "Taipingding",
                "label_ar": "تايبينجدينج",
                "label_fr": "Taipingding"
            },
            {
                "value": 19516,
                "label": "Jincheng",
                "label_ar": "جينتشنغ",
                "label_fr": "Jincheng"
            },
            {
                "value": 19524,
                "label": "Kaohsiung City",
                "label_ar": "مدينة كاوشيونغ",
                "label_fr": "Ville de Kaohsiung"
            },
            {
                "value": 19545,
                "label": "Yuli",
                "label_ar": "يولي",
                "label_fr": "Yuli"
            },
            {
                "value": 19517,
                "label": "Shulin",
                "label_ar": "شولين",
                "label_fr": "Shulin"
            },
            {
                "value": 19486,
                "label": "Chang-hua",
                "label_ar": "تشانغ هوا",
                "label_fr": "Chang-hua"
            },
            {
                "value": 19533,
                "label": "Douliu",
                "label_ar": "دوليو",
                "label_fr": "Douliu"
            },
            {
                "value": 19525,
                "label": "Xinzhuang",
                "label_ar": "شينتشوانغ",
                "label_fr": "Xinzhuang"
            },
            {
                "value": 19503,
                "label": "Shalun",
                "label_ar": "شلون",
                "label_fr": "Shalun"
            },
            {
                "value": 19532,
                "label": "Caogang",
                "label_ar": "Caogang",
                "label_fr": "Caogang"
            },
            {
                "value": 19507,
                "label": "Yilan",
                "label_ar": "ييلان",
                "label_fr": "Yilan"
            },
            {
                "value": 19489,
                "label": "Tainan",
                "label_ar": "تاينان",
                "label_fr": "Tainan"
            },
            {
                "value": 19538,
                "label": "Fengshan",
                "label_ar": "فنغشان",
                "label_fr": "Fengshan"
            },
            {
                "value": 19500,
                "label": "Wutai",
                "label_ar": "Wutai",
                "label_fr": "Wutai"
            },
            {
                "value": 19528,
                "label": "Yanping",
                "label_ar": "يانبينغ",
                "label_fr": "Yanping"
            },
            {
                "value": 19546,
                "label": "Su'ao",
                "label_ar": "Su'ao",
                "label_fr": "Su'ao"
            },
            {
                "value": 19518,
                "label": "Pozi",
                "label_ar": "بوزي",
                "label_fr": "Pozi"
            },
            {
                "value": 19490,
                "label": "Keelung",
                "label_ar": "كيلونج",
                "label_fr": "Keelung"
            },
            {
                "value": 19548,
                "label": "Zhubei",
                "label_ar": "Zhubei",
                "label_fr": "Zhubei"
            },
            {
                "value": 19542,
                "label": "Fanlu",
                "label_ar": "فانلو",
                "label_fr": "Fanlu"
            },
            {
                "value": 19482,
                "label": "Tuchang",
                "label_ar": "توتشانغ",
                "label_fr": "Tuchang"
            },
            {
                "value": 19527,
                "label": "Linkou",
                "label_ar": "لينكو",
                "label_fr": "Linkou"
            },
            {
                "value": 19484,
                "label": "Nankan",
                "label_ar": "نانكان",
                "label_fr": "Nankan"
            }
        ]
    },
    {
        "country": "Philippines",
        "cities": [
            {
                "value": 19792,
                "label": "Central",
                "label_ar": "وسط",
                "label_fr": "Central"
            },
            {
                "value": 19729,
                "label": "Subic",
                "label_ar": "سوبيك",
                "label_fr": "Subic"
            },
            {
                "value": 19844,
                "label": "Tanauan",
                "label_ar": "تاناوان",
                "label_fr": "Tanauan"
            },
            {
                "value": 19888,
                "label": "Del Pilar",
                "label_ar": "ديل بيلار",
                "label_fr": "Del Pilar"
            },
            {
                "value": 19754,
                "label": "Palo",
                "label_ar": "بالو",
                "label_fr": "Palo"
            },
            {
                "value": 19942,
                "label": "Merville Subdivision",
                "label_ar": "تقسيم ميرفيل",
                "label_fr": "Subdivision Merville"
            },
            {
                "value": 19802,
                "label": "Maquiling",
                "label_ar": "ماكولينج",
                "label_fr": "Maquiling"
            },
            {
                "value": 19723,
                "label": "West Triangle",
                "label_ar": "المثلث الغربي",
                "label_fr": "Triangle ouest"
            },
            {
                "value": 19851,
                "label": "Occidental",
                "label_ar": "أوكسيدنتال",
                "label_fr": "Occidental"
            },
            {
                "value": 19713,
                "label": "Pagsanjan",
                "label_ar": "باجسانجان",
                "label_fr": "Pagsanjan"
            },
            {
                "value": 19793,
                "label": "Rosales",
                "label_ar": "روزاليس",
                "label_fr": "Rosales"
            },
            {
                "value": 19836,
                "label": "Luzon",
                "label_ar": "لوزون",
                "label_fr": "Luzon"
            },
            {
                "value": 19662,
                "label": "Antipolo",
                "label_ar": "أنتيبولو",
                "label_fr": "Antipolo"
            },
            {
                "value": 19886,
                "label": "Tambo",
                "label_ar": "تامبو",
                "label_fr": "Tambo"
            },
            {
                "value": 19934,
                "label": "Bukidnon",
                "label_ar": "بوكيدنون",
                "label_fr": "Bukidnon"
            },
            {
                "value": 19913,
                "label": "Princesa",
                "label_ar": "برنسيسا",
                "label_fr": "Princesa"
            },
            {
                "value": 19624,
                "label": "Lapu-Lapu City",
                "label_ar": "مدينة لابو لابو",
                "label_fr": "Ville de Lapu-Lapu"
            },
            {
                "value": 19724,
                "label": "Pias",
                "label_ar": "بياس",
                "label_fr": "Pias"
            },
            {
                "value": 19805,
                "label": "Acacia",
                "label_ar": "أكاسيا",
                "label_fr": "Acacia"
            },
            {
                "value": 19631,
                "label": "Palma Gil",
                "label_ar": "بالما جيل",
                "label_fr": "Palma Gil"
            },
            {
                "value": 19736,
                "label": "San Agustin",
                "label_ar": "سان اجستين",
                "label_fr": "San Agustin"
            },
            {
                "value": 19879,
                "label": "Llanera",
                "label_ar": "يانيرا",
                "label_fr": "Llanera"
            },
            {
                "value": 19852,
                "label": "Panabo",
                "label_ar": "بانابو",
                "label_fr": "Panabo"
            },
            {
                "value": 19571,
                "label": "Pasay",
                "label_ar": "باساي",
                "label_fr": "Pasay"
            },
            {
                "value": 19811,
                "label": "Gallego",
                "label_ar": "جاليجو",
                "label_fr": "Gallego"
            },
            {
                "value": 19846,
                "label": "Island Park",
                "label_ar": "آيلاند بارك",
                "label_fr": "Parc de l'île"
            },
            {
                "value": 19610,
                "label": "New Manila",
                "label_ar": "مانيلا الجديدة",
                "label_fr": "New Manille"
            },
            {
                "value": 19623,
                "label": "Quezon",
                "label_ar": "كويزون",
                "label_fr": "Quezon"
            },
            {
                "value": 19735,
                "label": "Siniloan",
                "label_ar": "سينيلوان",
                "label_fr": "Siniloan"
            },
            {
                "value": 19573,
                "label": "Buting",
                "label_ar": "بوتينج",
                "label_fr": "Buting"
            },
            {
                "value": 19583,
                "label": "Marilag",
                "label_ar": "ماريلاج",
                "label_fr": "Marilag"
            },
            {
                "value": 19579,
                "label": "Rizal",
                "label_ar": "ريزال",
                "label_fr": "Rizal"
            },
            {
                "value": 19774,
                "label": "Limay",
                "label_ar": "لاي",
                "label_fr": "Limay"
            },
            {
                "value": 19889,
                "label": "Province of Cebu",
                "label_ar": "مقاطعة سيبو",
                "label_fr": "Province de Cebu"
            },
            {
                "value": 19728,
                "label": "Zamboanga City",
                "label_ar": "مدينة زامبوانجا",
                "label_fr": "Zamboanga City"
            },
            {
                "value": 19915,
                "label": "Putatan",
                "label_ar": "بوتاتان",
                "label_fr": "Putatan"
            },
            {
                "value": 19835,
                "label": "Tayug",
                "label_ar": "تايوغ",
                "label_fr": "Tayug"
            },
            {
                "value": 19564,
                "label": "Pasig",
                "label_ar": "باسيج",
                "label_fr": "Pasig"
            },
            {
                "value": 19578,
                "label": "San Mateo",
                "label_ar": "سان ماتيو",
                "label_fr": "San Mateo"
            },
            {
                "value": 19687,
                "label": "Tagbilaran City",
                "label_ar": "مدينة تاغبيلاران",
                "label_fr": "Ville de Tagbilaran"
            },
            {
                "value": 19618,
                "label": "Balayan",
                "label_ar": "بليان",
                "label_fr": "Balayan"
            },
            {
                "value": 19705,
                "label": "Los Banos",
                "label_ar": "لوس بانوس",
                "label_fr": "Los Banos"
            },
            {
                "value": 19621,
                "label": "Subic",
                "label_ar": "سوبيك",
                "label_fr": "Subic"
            },
            {
                "value": 19707,
                "label": "Santa Rosa",
                "label_ar": "سانتا روزا",
                "label_fr": "Santa Rosa"
            },
            {
                "value": 19629,
                "label": "Subic",
                "label_ar": "سوبيك",
                "label_fr": "Subic"
            },
            {
                "value": 19796,
                "label": "Silang",
                "label_ar": "سيلانج",
                "label_fr": "Silang"
            },
            {
                "value": 19556,
                "label": "Valenzuela",
                "label_ar": "فالينزويلا",
                "label_fr": "Valenzuela"
            },
            {
                "value": 19857,
                "label": "Osmena",
                "label_ar": "أوسمينا",
                "label_fr": "Osmena"
            },
            {
                "value": 19715,
                "label": "Talisay City",
                "label_ar": "مدينة تاليساي",
                "label_fr": "Ville de Talisay"
            },
            {
                "value": 19784,
                "label": "Balagtas",
                "label_ar": "بلاغطاس",
                "label_fr": "Balagtas"
            },
            {
                "value": 19591,
                "label": "Iligan",
                "label_ar": "إليجان",
                "label_fr": "Iligan"
            },
            {
                "value": 19943,
                "label": "San Andres",
                "label_ar": "سان أندرياس",
                "label_fr": "San Andres"
            },
            {
                "value": 19695,
                "label": "Baliuag",
                "label_ar": "باليواج",
                "label_fr": "Baliuag"
            },
            {
                "value": 19552,
                "label": "Roosevelt",
                "label_ar": "روزفلت",
                "label_fr": "Roosevelt"
            },
            {
                "value": 19643,
                "label": "Laguna",
                "label_ar": "لاجونا",
                "label_fr": "Laguna"
            },
            {
                "value": 19916,
                "label": "Villa",
                "label_ar": "فيلا",
                "label_fr": "Villa"
            },
            {
                "value": 19944,
                "label": "Burgos",
                "label_ar": "بورغوس",
                "label_fr": "Burgos"
            },
            {
                "value": 19860,
                "label": "Cotabato City",
                "label_ar": "مدينة كوتاباتو",
                "label_fr": "Ville de Cotabato"
            },
            {
                "value": 19689,
                "label": "Pulilan",
                "label_ar": "بوليلان",
                "label_fr": "Pulilan"
            },
            {
                "value": 19619,
                "label": "Laguna",
                "label_ar": "لاجونا",
                "label_fr": "Laguna"
            },
            {
                "value": 19948,
                "label": "Province of Laguna",
                "label_ar": "مقاطعة لاغونا",
                "label_fr": "Province de Laguna"
            },
            {
                "value": 19759,
                "label": "Mauban",
                "label_ar": "موبان",
                "label_fr": "Mauban"
            },
            {
                "value": 19801,
                "label": "Suyo",
                "label_ar": "سويو",
                "label_fr": "Suyo"
            },
            {
                "value": 19685,
                "label": "Iloilo City",
                "label_ar": "مدينة إيلويلو",
                "label_fr": "Ville d'Iloilo"
            },
            {
                "value": 19764,
                "label": "Cabugao",
                "label_ar": "كابوغاو",
                "label_fr": "Cabugao"
            },
            {
                "value": 19720,
                "label": "Tanauan",
                "label_ar": "تاناوان",
                "label_fr": "Tanauan"
            },
            {
                "value": 19766,
                "label": "Urdaneta",
                "label_ar": "اوردانيتا",
                "label_fr": "Urdaneta"
            },
            {
                "value": 19890,
                "label": "Bustos",
                "label_ar": "بوستوس",
                "label_fr": "Bustos"
            },
            {
                "value": 19598,
                "label": "Cavite City",
                "label_ar": "كافيت سيتي",
                "label_fr": "Cavite City"
            },
            {
                "value": 19641,
                "label": "Alabang",
                "label_ar": "الابانج",
                "label_fr": "Alabang"
            },
            {
                "value": 19785,
                "label": "Kidapawan",
                "label_ar": "كيداباوان",
                "label_fr": "Kidapawan"
            },
            {
                "value": 19866,
                "label": "Vigan",
                "label_ar": "فيغان",
                "label_fr": "Vigan"
            },
            {
                "value": 19611,
                "label": "Antipolo",
                "label_ar": "أنتيبولو",
                "label_fr": "Antipolo"
            },
            {
                "value": 19859,
                "label": "Balamban",
                "label_ar": "بالامبان",
                "label_fr": "Balamban"
            },
            {
                "value": 19768,
                "label": "Malasiqui",
                "label_ar": "مالاسكي",
                "label_fr": "Malasiqui"
            },
            {
                "value": 19838,
                "label": "Victory",
                "label_ar": "فوز",
                "label_fr": "La victoire"
            },
            {
                "value": 19671,
                "label": "Tanza",
                "label_ar": "تانزا",
                "label_fr": "Tanza"
            },
            {
                "value": 19699,
                "label": "San Fernando",
                "label_ar": "سان فرناندو",
                "label_fr": "San Fernando"
            },
            {
                "value": 19880,
                "label": "China",
                "label_ar": "الصين",
                "label_fr": "Chine"
            },
            {
                "value": 19947,
                "label": "Sto Nino",
                "label_ar": "ستو نينو",
                "label_fr": "Sto Nino"
            },
            {
                "value": 19608,
                "label": "Cebu City",
                "label_ar": "مدينة سيبو",
                "label_fr": "Cebu City"
            },
            {
                "value": 19776,
                "label": "Real",
                "label_ar": "حقيقة",
                "label_fr": "Réel"
            },
            {
                "value": 19637,
                "label": "Plaza",
                "label_ar": "بلازا",
                "label_fr": "Place"
            },
            {
                "value": 19638,
                "label": "San Antonio",
                "label_ar": "سان أنطونيو",
                "label_fr": "San Antonio"
            },
            {
                "value": 19830,
                "label": "Alcala",
                "label_ar": "الكالا",
                "label_fr": "Alcala"
            },
            {
                "value": 19894,
                "label": "Bauan",
                "label_ar": "باوان",
                "label_fr": "Bauan"
            },
            {
                "value": 19688,
                "label": "Balanga",
                "label_ar": "بالانجا",
                "label_fr": "Balanga"
            },
            {
                "value": 19927,
                "label": "Talon",
                "label_ar": "تالون",
                "label_fr": "Talon"
            },
            {
                "value": 19912,
                "label": "Malvar",
                "label_ar": "مالفار",
                "label_fr": "Malvar"
            },
            {
                "value": 19737,
                "label": "Cotabato City",
                "label_ar": "مدينة كوتاباتو",
                "label_fr": "Ville de Cotabato"
            },
            {
                "value": 19694,
                "label": "Plaridel",
                "label_ar": "بلارديل",
                "label_fr": "Plaridel"
            },
            {
                "value": 19716,
                "label": "Mandaue City",
                "label_ar": "مدينة مانداوي",
                "label_fr": "Ville de Mandaue"
            },
            {
                "value": 19572,
                "label": "Caloocan",
                "label_ar": "كالوكان",
                "label_fr": "Caloocan"
            },
            {
                "value": 19714,
                "label": "Zamboanga City",
                "label_ar": "مدينة زامبوانجا",
                "label_fr": "Zamboanga City"
            },
            {
                "value": 19584,
                "label": "Angeles City",
                "label_ar": "مدينة أنجيليس",
                "label_fr": "Angeles City"
            },
            {
                "value": 19758,
                "label": "Bagong Nayon",
                "label_ar": "باجونج نايون",
                "label_fr": "Bagong Nayon"
            },
            {
                "value": 19589,
                "label": "Angeles City",
                "label_ar": "مدينة أنجيليس",
                "label_fr": "Angeles City"
            },
            {
                "value": 19586,
                "label": "Malabon",
                "label_ar": "مالابون",
                "label_fr": "Malabon"
            },
            {
                "value": 19899,
                "label": "San Jose del Monte",
                "label_ar": "سان خوسيه ديل مونتي",
                "label_fr": "San José del Monte"
            },
            {
                "value": 19910,
                "label": "Candelaria",
                "label_ar": "كانديلاريا",
                "label_fr": "Candelaria"
            },
            {
                "value": 19856,
                "label": "Matandang Balara",
                "label_ar": "ماتاندانج بالارا",
                "label_fr": "Matandang Balara"
            },
            {
                "value": 19818,
                "label": "Poblacion, San Felipe",
                "label_ar": "بوبلاسيون ، سان فيليبي",
                "label_fr": "Poblacion, San Felipe"
            },
            {
                "value": 19804,
                "label": "Los Martires",
                "label_ar": "لوس مارتيرس",
                "label_fr": "Los Martires"
            },
            {
                "value": 19891,
                "label": "Daraga",
                "label_ar": "داراجا",
                "label_fr": "Daraga"
            },
            {
                "value": 19635,
                "label": "Vargas",
                "label_ar": "فارغاس",
                "label_fr": "Vargas"
            },
            {
                "value": 19821,
                "label": "Concepcion",
                "label_ar": "كونسيبسيون",
                "label_fr": "Concepcion"
            },
            {
                "value": 19931,
                "label": "Misamis",
                "label_ar": "ميساميس",
                "label_fr": "Misamis"
            },
            {
                "value": 19581,
                "label": "Sucat",
                "label_ar": "سوكات",
                "label_fr": "Sucat"
            },
            {
                "value": 19778,
                "label": "Kapatagan",
                "label_ar": "كاباتاجان",
                "label_fr": "Kapatagan"
            },
            {
                "value": 19625,
                "label": "Tagaytay",
                "label_ar": "تاجايتاي",
                "label_fr": "Tagaytay"
            },
            {
                "value": 19782,
                "label": "Jagna",
                "label_ar": "جاجنا",
                "label_fr": "Jagna"
            },
            {
                "value": 19557,
                "label": "Caloocan City",
                "label_ar": "مدينة كالوكان",
                "label_fr": "Ville de Caloocan"
            },
            {
                "value": 19605,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 19877,
                "label": "Ilagan",
                "label_ar": "إيلاجان",
                "label_fr": "Ilagan"
            },
            {
                "value": 19593,
                "label": "Tagum",
                "label_ar": "تاجوم",
                "label_fr": "Tagum"
            },
            {
                "value": 19941,
                "label": "City of Batac",
                "label_ar": "مدينة باتاك",
                "label_fr": "Ville de Batac"
            },
            {
                "value": 19615,
                "label": "Pandi",
                "label_ar": "باندي",
                "label_fr": "Pandi"
            },
            {
                "value": 19560,
                "label": "Las Pinas",
                "label_ar": "لاس بيناس",
                "label_fr": "Las Pinas"
            },
            {
                "value": 19648,
                "label": "Batis",
                "label_ar": "باتيس",
                "label_fr": "Batis"
            },
            {
                "value": 19928,
                "label": "Don Jose",
                "label_ar": "دون جوزيه",
                "label_fr": "Don José"
            },
            {
                "value": 19601,
                "label": "Tacurong",
                "label_ar": "تاكورونج",
                "label_fr": "Tacurong"
            },
            {
                "value": 19639,
                "label": "Philippine",
                "label_ar": "فلبيني",
                "label_fr": "Philippine"
            },
            {
                "value": 19640,
                "label": "Rizal",
                "label_ar": "ريزال",
                "label_fr": "Rizal"
            },
            {
                "value": 19722,
                "label": "Pasig",
                "label_ar": "باسيج",
                "label_fr": "Pasig"
            },
            {
                "value": 19616,
                "label": "West",
                "label_ar": "الغرب",
                "label_fr": "Ouest"
            },
            {
                "value": 19911,
                "label": "Carlatan",
                "label_ar": "كلاتان",
                "label_fr": "Carlatan"
            },
            {
                "value": 19708,
                "label": "Calamba City",
                "label_ar": "مدينة كالامبا",
                "label_fr": "Ville de Calamba"
            },
            {
                "value": 19577,
                "label": "San Pablo City",
                "label_ar": "مدينة سان بابلو",
                "label_fr": "Ville de San Pablo"
            },
            {
                "value": 19949,
                "label": "Quezon",
                "label_ar": "كويزون",
                "label_fr": "Quezon"
            },
            {
                "value": 19697,
                "label": "Bataan",
                "label_ar": "باتان",
                "label_fr": "Bataan"
            },
            {
                "value": 19645,
                "label": "Bates",
                "label_ar": "بيتس",
                "label_fr": "Bates"
            },
            {
                "value": 19744,
                "label": "Taft",
                "label_ar": "تافت",
                "label_fr": "Taft"
            },
            {
                "value": 19683,
                "label": "Dingras",
                "label_ar": "دينجرا",
                "label_fr": "Dingras"
            },
            {
                "value": 19592,
                "label": "Cagayan de Oro",
                "label_ar": "كاجايان دو آورو",
                "label_fr": "Cagayan de Oro"
            },
            {
                "value": 19865,
                "label": "Carlos",
                "label_ar": "كارلوس",
                "label_fr": "Carlos"
            },
            {
                "value": 19738,
                "label": "Veronica",
                "label_ar": "فيرونيكا",
                "label_fr": "Véronique"
            },
            {
                "value": 19815,
                "label": "Wines",
                "label_ar": "نبيذ",
                "label_fr": "Vins"
            },
            {
                "value": 19914,
                "label": "Looc",
                "label_ar": "Looc",
                "label_fr": "Looc"
            },
            {
                "value": 19812,
                "label": "Gloria",
                "label_ar": "جلوريا",
                "label_fr": "Gloria"
            },
            {
                "value": 19884,
                "label": "Cruz",
                "label_ar": "كروز",
                "label_fr": "Cruz"
            },
            {
                "value": 19831,
                "label": "Bautista",
                "label_ar": "باوتيستا",
                "label_fr": "Bautista"
            },
            {
                "value": 19922,
                "label": "Tuktukan",
                "label_ar": "تكتوكان",
                "label_fr": "Tuktukan"
            },
            {
                "value": 19620,
                "label": "Calamba",
                "label_ar": "كالامبا",
                "label_fr": "Calamba"
            },
            {
                "value": 19885,
                "label": "Ventura",
                "label_ar": "فينتورا",
                "label_fr": "Ventura"
            },
            {
                "value": 19781,
                "label": "Bagac",
                "label_ar": "باجاك",
                "label_fr": "Bagac"
            },
            {
                "value": 19718,
                "label": "Luna",
                "label_ar": "لونا",
                "label_fr": "Luna"
            },
            {
                "value": 19761,
                "label": "Apalit",
                "label_ar": "أباليت",
                "label_fr": "Apalit"
            },
            {
                "value": 19614,
                "label": "Koronadal",
                "label_ar": "كورونادال",
                "label_fr": "Koronadal"
            },
            {
                "value": 19649,
                "label": "Cubao",
                "label_ar": "كوباو",
                "label_fr": "Cubao"
            },
            {
                "value": 19684,
                "label": "Cainta",
                "label_ar": "كينتا",
                "label_fr": "Cainta"
            },
            {
                "value": 19930,
                "label": "Iriga City",
                "label_ar": "مدينة إيريجا",
                "label_fr": "Ville d'Iriga"
            },
            {
                "value": 19873,
                "label": "Padre Garcia",
                "label_ar": "بادري جارسيا",
                "label_fr": "Padre Garcia"
            },
            {
                "value": 19627,
                "label": "San Jose",
                "label_ar": "سان خوسيه",
                "label_fr": "San José"
            },
            {
                "value": 19826,
                "label": "Dagupan",
                "label_ar": "داجوبان",
                "label_fr": "Dagupan"
            },
            {
                "value": 19732,
                "label": "Orion",
                "label_ar": "أوريون",
                "label_fr": "Orion"
            },
            {
                "value": 19733,
                "label": "Arayat",
                "label_ar": "عرايات",
                "label_fr": "Arayat"
            },
            {
                "value": 19839,
                "label": "Sta Cruz",
                "label_ar": "ستا كروز",
                "label_fr": "Sta Cruz"
            },
            {
                "value": 19829,
                "label": "San Fabian",
                "label_ar": "سان فابيان",
                "label_fr": "San Fabian"
            },
            {
                "value": 19692,
                "label": "San Jose",
                "label_ar": "سان خوسيه",
                "label_fr": "San José"
            },
            {
                "value": 19742,
                "label": "Danao",
                "label_ar": "داناو",
                "label_fr": "Danao"
            },
            {
                "value": 19918,
                "label": "Bataan",
                "label_ar": "باتان",
                "label_fr": "Bataan"
            },
            {
                "value": 19872,
                "label": "Calaca",
                "label_ar": "كالاكا",
                "label_fr": "Calaca"
            },
            {
                "value": 19626,
                "label": "San Carlos City",
                "label_ar": "مدينة سان كارلوس",
                "label_fr": "Ville de San Carlos"
            },
            {
                "value": 19678,
                "label": "Bulacan",
                "label_ar": "بولاكان",
                "label_fr": "Bulacan"
            },
            {
                "value": 19900,
                "label": "Luna",
                "label_ar": "لونا",
                "label_fr": "Luna"
            },
            {
                "value": 19706,
                "label": "Paete",
                "label_ar": "بايت",
                "label_fr": "Paete"
            },
            {
                "value": 19555,
                "label": "Pasong Tamo",
                "label_ar": "باسونغ تامو",
                "label_fr": "Pasong Tamo"
            },
            {
                "value": 19940,
                "label": "Tamag",
                "label_ar": "تماغ",
                "label_fr": "Tamag"
            },
            {
                "value": 19800,
                "label": "Marcos",
                "label_ar": "ماركوس",
                "label_fr": "Marcos"
            },
            {
                "value": 19568,
                "label": "Poblacion",
                "label_ar": "بوبلاسيون",
                "label_fr": "Poblacion"
            },
            {
                "value": 19861,
                "label": "Cavinti",
                "label_ar": "كافينتي",
                "label_fr": "Cavinti"
            },
            {
                "value": 19786,
                "label": "Pampanga",
                "label_ar": "بامبانجا",
                "label_fr": "Pampanga"
            },
            {
                "value": 19862,
                "label": "Alitagtag",
                "label_ar": "عليتاغ",
                "label_fr": "Alitagtag"
            },
            {
                "value": 19822,
                "label": "Digos",
                "label_ar": "Digos",
                "label_fr": "Digos"
            },
            {
                "value": 19893,
                "label": "Sariaya",
                "label_ar": "سارية",
                "label_fr": "Sariaya"
            },
            {
                "value": 19693,
                "label": "San Fernando",
                "label_ar": "سان فرناندو",
                "label_fr": "San Fernando"
            },
            {
                "value": 19906,
                "label": "Dinas",
                "label_ar": "ديناس",
                "label_fr": "Dinas"
            },
            {
                "value": 19752,
                "label": "Pagadian",
                "label_ar": "باجاديان",
                "label_fr": "Pagadien"
            },
            {
                "value": 19700,
                "label": "Meycauayan",
                "label_ar": "ميكوايان",
                "label_fr": "Meycauayan"
            },
            {
                "value": 19702,
                "label": "Kawit",
                "label_ar": "كاويت",
                "label_fr": "Kawit"
            },
            {
                "value": 19565,
                "label": "Paranaque City",
                "label_ar": "مدينة باراناك",
                "label_fr": "Paranaque City"
            },
            {
                "value": 19634,
                "label": "Santos",
                "label_ar": "سانتوس",
                "label_fr": "Santos"
            },
            {
                "value": 19570,
                "label": "Puerto Princesa City",
                "label_ar": "مدينة بويرتو برنسيسا",
                "label_fr": "Ville de Puerto Princesa"
            },
            {
                "value": 19926,
                "label": "Mabini",
                "label_ar": "مابيني",
                "label_fr": "Mabini"
            },
            {
                "value": 19790,
                "label": "Cauayan",
                "label_ar": "كوايان",
                "label_fr": "Cauayan"
            },
            {
                "value": 19730,
                "label": "Abucay",
                "label_ar": "أبوكاي",
                "label_fr": "Abucay"
            },
            {
                "value": 19925,
                "label": "Isabela",
                "label_ar": "إيزابيلا",
                "label_fr": "Isabela"
            },
            {
                "value": 19775,
                "label": "Calbayog City",
                "label_ar": "مدينة كالبايوج",
                "label_fr": "Ville de Calbayog"
            },
            {
                "value": 19749,
                "label": "Cafe",
                "label_ar": "كافيه",
                "label_fr": "Café"
            },
            {
                "value": 19653,
                "label": "Guagua",
                "label_ar": "غواغوا",
                "label_fr": "Guagua"
            },
            {
                "value": 19895,
                "label": "Himamaylan",
                "label_ar": "هيممايلان",
                "label_fr": "Himamaylan"
            },
            {
                "value": 19867,
                "label": "Domingo",
                "label_ar": "دومينغو",
                "label_fr": "Domingo"
            },
            {
                "value": 19575,
                "label": "Taguig",
                "label_ar": "تاجويج",
                "label_fr": "Taguig"
            },
            {
                "value": 19771,
                "label": "Amadeo",
                "label_ar": "أماديو",
                "label_fr": "Amadeo"
            },
            {
                "value": 19651,
                "label": "Balabag",
                "label_ar": "بالاباج",
                "label_fr": "Balabag"
            },
            {
                "value": 19751,
                "label": "Interior",
                "label_ar": "الداخلية",
                "label_fr": "Intérieur"
            },
            {
                "value": 19595,
                "label": "Mawab",
                "label_ar": "مواب",
                "label_fr": "Mawab"
            },
            {
                "value": 19823,
                "label": "Cogan",
                "label_ar": "كوجان",
                "label_fr": "Cogan"
            },
            {
                "value": 19797,
                "label": "La Salette",
                "label_ar": "لا ساليت",
                "label_fr": "La Salette"
            },
            {
                "value": 19604,
                "label": "Carolinas",
                "label_ar": "كارولينا",
                "label_fr": "Carolines"
            },
            {
                "value": 19551,
                "label": "Bayan",
                "label_ar": "بيان",
                "label_fr": "Bayan"
            },
            {
                "value": 19657,
                "label": "Naga City",
                "label_ar": "مدينة النجا",
                "label_fr": "Naga City"
            },
            {
                "value": 19734,
                "label": "Boac",
                "label_ar": "بوك",
                "label_fr": "Boac"
            },
            {
                "value": 19606,
                "label": "General Santos",
                "label_ar": "الجنرال سانتوس",
                "label_fr": "Général Santos"
            },
            {
                "value": 19725,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 19663,
                "label": "Imus",
                "label_ar": "إيموس",
                "label_fr": "Imus"
            },
            {
                "value": 19558,
                "label": "Taytay",
                "label_ar": "تايتاي",
                "label_fr": "Taytay"
            },
            {
                "value": 19919,
                "label": "Pinaglabanan",
                "label_ar": "بيناجلابانان",
                "label_fr": "Pinaglabanan"
            },
            {
                "value": 19612,
                "label": "Villanueva",
                "label_ar": "فيلانويفا",
                "label_fr": "Villanueva"
            },
            {
                "value": 19795,
                "label": "Roque",
                "label_ar": "روكي",
                "label_fr": "Roque"
            },
            {
                "value": 19746,
                "label": "Centre",
                "label_ar": "مركز",
                "label_fr": "Centre"
            },
            {
                "value": 19613,
                "label": "Concepcion",
                "label_ar": "كونسيبسيون",
                "label_fr": "Concepcion"
            },
            {
                "value": 19904,
                "label": "Lahug",
                "label_ar": "لاهوج",
                "label_fr": "Lahug"
            },
            {
                "value": 19755,
                "label": "Mamungan",
                "label_ar": "مامونجان",
                "label_fr": "Mamungan"
            },
            {
                "value": 19907,
                "label": "Matalam",
                "label_ar": "ماتلام",
                "label_fr": "Matalam"
            },
            {
                "value": 19582,
                "label": "Sampaloc",
                "label_ar": "سامبالوك",
                "label_fr": "Sampaloc"
            },
            {
                "value": 19828,
                "label": "Mangaldan",
                "label_ar": "مانجالدان",
                "label_fr": "Mangaldan"
            },
            {
                "value": 19670,
                "label": "Mandaue City",
                "label_ar": "مدينة مانداوي",
                "label_fr": "Ville de Mandaue"
            },
            {
                "value": 19691,
                "label": "Marilao",
                "label_ar": "ماريلاو",
                "label_fr": "Marilao"
            },
            {
                "value": 19603,
                "label": "Dumaguete",
                "label_ar": "دوماجويتي",
                "label_fr": "Dumaguete"
            },
            {
                "value": 19748,
                "label": "Pagasinan",
                "label_ar": "باجاسينان",
                "label_fr": "Pagasinan"
            },
            {
                "value": 19870,
                "label": "Oara",
                "label_ar": "أوارا",
                "label_fr": "Oara"
            },
            {
                "value": 19767,
                "label": "Alaminos",
                "label_ar": "ألامينوس",
                "label_fr": "Alaminos"
            },
            {
                "value": 19574,
                "label": "Lipa City",
                "label_ar": "مدينة ليبا",
                "label_fr": "Lipa City"
            },
            {
                "value": 19652,
                "label": "General",
                "label_ar": "جنرال لواء",
                "label_fr": "Général"
            },
            {
                "value": 19863,
                "label": "Trinidad",
                "label_ar": "ترينيداد",
                "label_fr": "Trinidad"
            },
            {
                "value": 19664,
                "label": "Tarlac City",
                "label_ar": "مدينة تارلاك",
                "label_fr": "Ville de Tarlac"
            },
            {
                "value": 19878,
                "label": "Santiago",
                "label_ar": "سانتياغو",
                "label_fr": "Santiago"
            },
            {
                "value": 19849,
                "label": "Campus",
                "label_ar": "الحرم الجامعي",
                "label_fr": "Campus"
            },
            {
                "value": 19599,
                "label": "Carmona",
                "label_ar": "كارمونا",
                "label_fr": "Carmona"
            },
            {
                "value": 19770,
                "label": "Balungao",
                "label_ar": "Balungao",
                "label_fr": "Balungao"
            },
            {
                "value": 19788,
                "label": "Diliman Primero",
                "label_ar": "ديليمان بريميرو",
                "label_fr": "Diliman Primero"
            },
            {
                "value": 19864,
                "label": "Lilio",
                "label_ar": "ليليو",
                "label_fr": "Lilio"
            },
            {
                "value": 19666,
                "label": "Calapan",
                "label_ar": "كالابان",
                "label_fr": "Calapan"
            },
            {
                "value": 19757,
                "label": "Paul",
                "label_ar": "بول",
                "label_fr": "Paul"
            },
            {
                "value": 19673,
                "label": "Silang",
                "label_ar": "سيلانج",
                "label_fr": "Silang"
            },
            {
                "value": 19745,
                "label": "Sulo",
                "label_ar": "سولو",
                "label_fr": "Sulo"
            },
            {
                "value": 19789,
                "label": "Baao",
                "label_ar": "باو",
                "label_fr": "Baao"
            },
            {
                "value": 19938,
                "label": "Lourdes",
                "label_ar": "لورد",
                "label_fr": "Lourdes"
            },
            {
                "value": 19753,
                "label": "Molave",
                "label_ar": "مولاف",
                "label_fr": "Molave"
            },
            {
                "value": 19672,
                "label": "Caloocan",
                "label_ar": "كالوكان",
                "label_fr": "Caloocan"
            },
            {
                "value": 19937,
                "label": "Centro",
                "label_ar": "سنترو",
                "label_fr": "Centro"
            },
            {
                "value": 19909,
                "label": "Sorsogon",
                "label_ar": "سورسوجون",
                "label_fr": "Sorsogon"
            },
            {
                "value": 19820,
                "label": "Toledo City",
                "label_ar": "مدينة توليدو",
                "label_fr": "Ville de Tolède"
            },
            {
                "value": 19588,
                "label": "Fairview",
                "label_ar": "فيرفيو",
                "label_fr": "Fairview"
            },
            {
                "value": 19897,
                "label": "Orani",
                "label_ar": "أوراني",
                "label_fr": "Orani"
            },
            {
                "value": 19559,
                "label": "Quezon City",
                "label_ar": "مدينة كويزون",
                "label_fr": "ville de Quezon"
            },
            {
                "value": 19787,
                "label": "Naga",
                "label_ar": "النجا",
                "label_fr": "Naga"
            },
            {
                "value": 19590,
                "label": "Davao City",
                "label_ar": "مدينة دافاو",
                "label_fr": "la ville de Davao"
            },
            {
                "value": 19655,
                "label": "Tandang Sora",
                "label_ar": "تاندانغ سورا",
                "label_fr": "Tandang Sora"
            },
            {
                "value": 19799,
                "label": "Baclaran",
                "label_ar": "باكلاران",
                "label_fr": "Baclaran"
            },
            {
                "value": 19853,
                "label": "Munoz East",
                "label_ar": "مونوز الشرق",
                "label_fr": "Munoz Est"
            },
            {
                "value": 19727,
                "label": "Victorias City",
                "label_ar": "فيكتورياس سيتي",
                "label_fr": "Victorias City"
            },
            {
                "value": 19850,
                "label": "Valencia",
                "label_ar": "فالنسيا",
                "label_fr": "Valence"
            },
            {
                "value": 19946,
                "label": "San Vicente",
                "label_ar": "سان فيسينتي",
                "label_fr": "San Vicente"
            },
            {
                "value": 19719,
                "label": "Olongapo City",
                "label_ar": "مدينة اولونجابو",
                "label_fr": "Ville d'Olongapo"
            },
            {
                "value": 19740,
                "label": "Bool",
                "label_ar": "منطقي",
                "label_fr": "Booléen"
            },
            {
                "value": 19630,
                "label": "Libis",
                "label_ar": "ليبيس",
                "label_fr": "Libis"
            },
            {
                "value": 19832,
                "label": "Aquino",
                "label_ar": "أكينو",
                "label_fr": "Aquino"
            },
            {
                "value": 19932,
                "label": "Taal",
                "label_ar": "تال",
                "label_fr": "Taal"
            },
            {
                "value": 19763,
                "label": "General Trias",
                "label_ar": "جنرال ترياس",
                "label_fr": "Général Trias"
            },
            {
                "value": 19794,
                "label": "Bulacan",
                "label_ar": "بولاكان",
                "label_fr": "Bulacan"
            },
            {
                "value": 19807,
                "label": "Salle",
                "label_ar": "سال",
                "label_fr": "Salle"
            },
            {
                "value": 19819,
                "label": "Lawis",
                "label_ar": "Lawis",
                "label_fr": "Lawis"
            },
            {
                "value": 19798,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 19633,
                "label": "Kalibo",
                "label_ar": "كاليبو",
                "label_fr": "Kalibo"
            },
            {
                "value": 19883,
                "label": "Marina",
                "label_ar": "مارينا",
                "label_fr": "Marina"
            },
            {
                "value": 19813,
                "label": "Nueva",
                "label_ar": "نويفا",
                "label_fr": "Nueva"
            },
            {
                "value": 19756,
                "label": "Hagonoy",
                "label_ar": "هاجونوي",
                "label_fr": "Hagonoy"
            },
            {
                "value": 19607,
                "label": "Malolos",
                "label_ar": "مالولوس",
                "label_fr": "Malolos"
            },
            {
                "value": 19674,
                "label": "Malabon",
                "label_ar": "مالابون",
                "label_fr": "Malabon"
            },
            {
                "value": 19696,
                "label": "Cabanatuan City",
                "label_ar": "مدينة كاباناتوان",
                "label_fr": "Ville de Cabanatuan"
            },
            {
                "value": 19731,
                "label": "Samal",
                "label_ar": "سمال",
                "label_fr": "Samal"
            },
            {
                "value": 19875,
                "label": "Cagayan",
                "label_ar": "كاجايان",
                "label_fr": "Cagayan"
            },
            {
                "value": 19779,
                "label": "Trece Martires City",
                "label_ar": "مدينة تريس مارتيرس",
                "label_fr": "Ville de Trece Martires"
            },
            {
                "value": 19741,
                "label": "Tacloban City",
                "label_ar": "مدينة تاكلوبان",
                "label_fr": "Ville de Tacloban"
            },
            {
                "value": 19806,
                "label": "Minalin",
                "label_ar": "مينالين",
                "label_fr": "Minalin"
            },
            {
                "value": 19810,
                "label": "Pardo",
                "label_ar": "باردو",
                "label_fr": "Pardo"
            },
            {
                "value": 19576,
                "label": "Baguio City",
                "label_ar": "مدينة باجيو",
                "label_fr": "Ville de Baguio"
            },
            {
                "value": 19721,
                "label": "Cabatuan",
                "label_ar": "كاباتوان",
                "label_fr": "Cabatuan"
            },
            {
                "value": 19783,
                "label": "Obando",
                "label_ar": "أوباندو",
                "label_fr": "Obando"
            },
            {
                "value": 19675,
                "label": "Navotas",
                "label_ar": "نافوتاس",
                "label_fr": "Navotas"
            },
            {
                "value": 19929,
                "label": "Dolores",
                "label_ar": "دولوريس",
                "label_fr": "Dolores"
            },
            {
                "value": 19827,
                "label": "Barbar",
                "label_ar": "باربار",
                "label_fr": "Barbar"
            },
            {
                "value": 19902,
                "label": "Santo Tomas",
                "label_ar": "سانتو توماس",
                "label_fr": "Santo Tomas"
            },
            {
                "value": 19563,
                "label": "San Juan",
                "label_ar": "سان خوان",
                "label_fr": "San Juan"
            },
            {
                "value": 19661,
                "label": "Pikit",
                "label_ar": "بيكيت",
                "label_fr": "Pikit"
            },
            {
                "value": 19833,
                "label": "Ilog",
                "label_ar": "إيلوج",
                "label_fr": "Ilog"
            },
            {
                "value": 19842,
                "label": "Dapitan",
                "label_ar": "دابيتان",
                "label_fr": "Dapitan"
            },
            {
                "value": 19840,
                "label": "Sultan Kudarat",
                "label_ar": "سلطان قدرات",
                "label_fr": "Sultan Kudarat"
            },
            {
                "value": 19901,
                "label": "Smart",
                "label_ar": "ذكي",
                "label_fr": "Intelligent"
            },
            {
                "value": 19642,
                "label": "Legaspi",
                "label_ar": "ليغاسبي",
                "label_fr": "Legaspi"
            },
            {
                "value": 19567,
                "label": "Salinas",
                "label_ar": "ساليناس",
                "label_fr": "Salinas"
            },
            {
                "value": 19871,
                "label": "Alaminos",
                "label_ar": "ألامينوس",
                "label_fr": "Alaminos"
            },
            {
                "value": 19903,
                "label": "Aurora",
                "label_ar": "أورورا",
                "label_fr": "Aurore"
            },
            {
                "value": 19739,
                "label": "Masbate",
                "label_ar": "ماسباتي",
                "label_fr": "Masbate"
            },
            {
                "value": 19690,
                "label": "Olongapo City",
                "label_ar": "مدينة اولونجابو",
                "label_fr": "Ville d'Olongapo"
            },
            {
                "value": 19769,
                "label": "Bay",
                "label_ar": "خليج",
                "label_fr": "baie"
            },
            {
                "value": 19701,
                "label": "Manila",
                "label_ar": "مانيلا",
                "label_fr": "Manille"
            },
            {
                "value": 19876,
                "label": "Tuguegarao City",
                "label_ar": "مدينة توجيجاراو",
                "label_fr": "Ville de Tuguegarao"
            },
            {
                "value": 19553,
                "label": "Blumentritt",
                "label_ar": "بلومينتريت",
                "label_fr": "Blumentritt"
            },
            {
                "value": 19817,
                "label": "La Union",
                "label_ar": "لا يونيون",
                "label_fr": "La Union"
            },
            {
                "value": 19869,
                "label": "Norte",
                "label_ar": "نورتي",
                "label_fr": "Norte"
            },
            {
                "value": 19726,
                "label": "Dipolog City",
                "label_ar": "مدينة ديبولوج",
                "label_fr": "Dipolog City"
            },
            {
                "value": 19920,
                "label": "Bago City",
                "label_ar": "مدينة باجو",
                "label_fr": "Ville de Bago"
            },
            {
                "value": 19855,
                "label": "Fortuna",
                "label_ar": "فورتونا",
                "label_fr": "Fortuna"
            },
            {
                "value": 19898,
                "label": "Paniqui",
                "label_ar": "بانيكي",
                "label_fr": "Paniqui"
            },
            {
                "value": 19881,
                "label": "Ramon",
                "label_ar": "رامون",
                "label_fr": "Ramon"
            },
            {
                "value": 19650,
                "label": "Binondo",
                "label_ar": "بينوندو",
                "label_fr": "Binondo"
            },
            {
                "value": 19747,
                "label": "Santo",
                "label_ar": "سانتو",
                "label_fr": "Santo"
            },
            {
                "value": 19654,
                "label": "Liberty",
                "label_ar": "حرية",
                "label_fr": "Liberté"
            },
            {
                "value": 19580,
                "label": "Dasmarinas",
                "label_ar": "دسماريناس",
                "label_fr": "Dasmarinas"
            },
            {
                "value": 19554,
                "label": "Cardona",
                "label_ar": "كاردونا",
                "label_fr": "Cardona"
            },
            {
                "value": 19594,
                "label": "Butuan",
                "label_ar": "بوتوان",
                "label_fr": "Butuan"
            },
            {
                "value": 19659,
                "label": "San Francisco",
                "label_ar": "سان فرانسيسكو",
                "label_fr": "San Francisco"
            },
            {
                "value": 19847,
                "label": "Maria",
                "label_ar": "ماريا",
                "label_fr": "Maria"
            },
            {
                "value": 19698,
                "label": "Navotas",
                "label_ar": "نافوتاس",
                "label_fr": "Navotas"
            },
            {
                "value": 19908,
                "label": "Province of Pampanga",
                "label_ar": "مقاطعة بامبانجا",
                "label_fr": "Province de Pampanga"
            },
            {
                "value": 19858,
                "label": "Leon",
                "label_ar": "ليون",
                "label_fr": "Léon"
            },
            {
                "value": 19834,
                "label": "Manaoag",
                "label_ar": "المناوج",
                "label_fr": "Manaoag"
            },
            {
                "value": 19632,
                "label": "Fernandez",
                "label_ar": "فرنانديز",
                "label_fr": "Fernandez"
            },
            {
                "value": 19686,
                "label": "Iloilo City",
                "label_ar": "مدينة إيلويلو",
                "label_fr": "Ville d'Iloilo"
            },
            {
                "value": 19680,
                "label": "Bocaue",
                "label_ar": "بوكاوي",
                "label_fr": "Bocaue"
            },
            {
                "value": 19816,
                "label": "Vito",
                "label_ar": "فيتو",
                "label_fr": "Vito"
            },
            {
                "value": 19921,
                "label": "Tambler",
                "label_ar": "Tambler",
                "label_fr": "Tambler"
            },
            {
                "value": 19750,
                "label": "Leyte",
                "label_ar": "ليتي",
                "label_fr": "Leyte"
            },
            {
                "value": 19647,
                "label": "Salcedo",
                "label_ar": "سالسيدو",
                "label_fr": "Salcedo"
            },
            {
                "value": 19665,
                "label": "Batangas",
                "label_ar": "باتانجاس",
                "label_fr": "Batangas"
            },
            {
                "value": 19569,
                "label": "Roxas City",
                "label_ar": "مدينة روكساس",
                "label_fr": "Ville de Roxas"
            },
            {
                "value": 19814,
                "label": "Upper Bicutan",
                "label_ar": "أعلى بيكوتان",
                "label_fr": "Haut Bicutan"
            },
            {
                "value": 19682,
                "label": "Calasiao",
                "label_ar": "كالاسياو",
                "label_fr": "Calasiao"
            },
            {
                "value": 19874,
                "label": "Bauan",
                "label_ar": "باوان",
                "label_fr": "Bauan"
            },
            {
                "value": 19681,
                "label": "Dagupan",
                "label_ar": "داجوبان",
                "label_fr": "Dagupan"
            },
            {
                "value": 19825,
                "label": "Mindanaw",
                "label_ar": "مينداناو",
                "label_fr": "Mindanaw"
            },
            {
                "value": 19765,
                "label": "Province of Pangasinan",
                "label_ar": "مقاطعة بانجاسينان",
                "label_fr": "Province de Pangasinan"
            },
            {
                "value": 19600,
                "label": "Imus",
                "label_ar": "إيموس",
                "label_fr": "Imus"
            },
            {
                "value": 19597,
                "label": "Bacoor",
                "label_ar": "باكور",
                "label_fr": "Bacoor"
            },
            {
                "value": 19602,
                "label": "Pateros",
                "label_ar": "باتيروس",
                "label_fr": "Pateros"
            },
            {
                "value": 19936,
                "label": "Don Bosco Executive Village",
                "label_ar": "قرية دون بوسكو التنفيذية",
                "label_fr": "Village exécutif Don Bosco"
            },
            {
                "value": 19561,
                "label": "Makati City",
                "label_ar": "مدينة ماكاتى",
                "label_fr": "Makati City"
            },
            {
                "value": 19809,
                "label": "Gitagum",
                "label_ar": "جيتاجوم",
                "label_fr": "Gitagum"
            },
            {
                "value": 19667,
                "label": "Binan",
                "label_ar": "بينان",
                "label_fr": "Binan"
            },
            {
                "value": 19668,
                "label": "Cabuyao",
                "label_ar": "كابوياو",
                "label_fr": "Cabuyao"
            },
            {
                "value": 19628,
                "label": "Asia",
                "label_ar": "آسيا",
                "label_fr": "Asie"
            },
            {
                "value": 19585,
                "label": "Mandaluyong City",
                "label_ar": "مدينة ماندالويونغ",
                "label_fr": "Ville de Mandaluyong"
            },
            {
                "value": 19549,
                "label": "Manila",
                "label_ar": "مانيلا",
                "label_fr": "Manille"
            },
            {
                "value": 19780,
                "label": "Lamitan",
                "label_ar": "لاميتان",
                "label_fr": "Lamitan"
            },
            {
                "value": 19710,
                "label": "Pangil",
                "label_ar": "بانجيل",
                "label_fr": "Pangil"
            },
            {
                "value": 19676,
                "label": "Angat",
                "label_ar": "أنجات",
                "label_fr": "Angat"
            },
            {
                "value": 19669,
                "label": "Hilongos",
                "label_ar": "هيلونجوس",
                "label_fr": "Hilongos"
            },
            {
                "value": 19939,
                "label": "Fernando",
                "label_ar": "فرناندو",
                "label_fr": "Fernando"
            },
            {
                "value": 19923,
                "label": "Bayawan",
                "label_ar": "بياوان",
                "label_fr": "Bayawan"
            },
            {
                "value": 19841,
                "label": "Magsaysay",
                "label_ar": "ماجسايساي",
                "label_fr": "Magsaysay"
            },
            {
                "value": 19656,
                "label": "Capitol",
                "label_ar": "مبنى الكابيتول",
                "label_fr": "Capitole"
            },
            {
                "value": 19609,
                "label": "Malate",
                "label_ar": "مالات",
                "label_fr": "Malate"
            },
            {
                "value": 19777,
                "label": "Tanay",
                "label_ar": "تاناي",
                "label_fr": "Tanay"
            },
            {
                "value": 19924,
                "label": "Laguerta",
                "label_ar": "لاجويرتا",
                "label_fr": "Laguerta"
            },
            {
                "value": 19679,
                "label": "Laoag",
                "label_ar": "لاواج",
                "label_fr": "Laoag"
            },
            {
                "value": 19882,
                "label": "Feliciano",
                "label_ar": "فيليسيانو",
                "label_fr": "Feliciano"
            },
            {
                "value": 19622,
                "label": "Bonifacio",
                "label_ar": "بونيفاسيو",
                "label_fr": "Bonifacio"
            },
            {
                "value": 19709,
                "label": "Santa Cruz",
                "label_ar": "سانتا كروز",
                "label_fr": "Santa Cruz"
            },
            {
                "value": 19824,
                "label": "Calinan",
                "label_ar": "كالينان",
                "label_fr": "Calinan"
            },
            {
                "value": 19837,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 19644,
                "label": "Summit",
                "label_ar": "قمة",
                "label_fr": "Sommet"
            },
            {
                "value": 19933,
                "label": "Valencia",
                "label_ar": "فالنسيا",
                "label_fr": "Valence"
            },
            {
                "value": 19868,
                "label": "Candon",
                "label_ar": "كاندون",
                "label_fr": "Candon"
            },
            {
                "value": 19658,
                "label": "Binangonan",
                "label_ar": "بينانجونان",
                "label_fr": "Binangonan"
            },
            {
                "value": 19854,
                "label": "Aguilar",
                "label_ar": "أغيلار",
                "label_fr": "Aguilar"
            },
            {
                "value": 19646,
                "label": "Bagumbayan",
                "label_ar": "باجومبيان",
                "label_fr": "Bagumbayan"
            },
            {
                "value": 19945,
                "label": "Naguilian",
                "label_ar": "ناجيليان",
                "label_fr": "Naguilien"
            },
            {
                "value": 19617,
                "label": "Lucena City",
                "label_ar": "لوسينا سيتي",
                "label_fr": "Lucena City"
            },
            {
                "value": 19636,
                "label": "Novaliches",
                "label_ar": "نوفاليتش",
                "label_fr": "Novaliches"
            },
            {
                "value": 19566,
                "label": "Muntinlupa",
                "label_ar": "مونتينلوبا",
                "label_fr": "Muntinlupa"
            },
            {
                "value": 19712,
                "label": "Tayabas",
                "label_ar": "طيابس",
                "label_fr": "Tayabas"
            },
            {
                "value": 19808,
                "label": "Agusan Pequeno",
                "label_ar": "أجوسان بيكينو",
                "label_fr": "Agusan Pequeno"
            },
            {
                "value": 19917,
                "label": "Cinco",
                "label_ar": "سينكو",
                "label_fr": "Cinco"
            },
            {
                "value": 19773,
                "label": "Del Monte",
                "label_ar": "ديل مونتي",
                "label_fr": "Del Monte"
            },
            {
                "value": 19703,
                "label": "Bacolod City",
                "label_ar": "مدينة باكولود",
                "label_fr": "Ville de Bacolod"
            },
            {
                "value": 19677,
                "label": "Navotas",
                "label_ar": "نافوتاس",
                "label_fr": "Navotas"
            },
            {
                "value": 19892,
                "label": "Minglanilla",
                "label_ar": "مينجلانيلا",
                "label_fr": "Minglanilla"
            },
            {
                "value": 19596,
                "label": "Ozamiz City",
                "label_ar": "مدينة اوزاميز",
                "label_fr": "Ville d'Ozamiz"
            },
            {
                "value": 19843,
                "label": "Mangrove",
                "label_ar": "المانغروف",
                "label_fr": "Mangrove"
            },
            {
                "value": 19905,
                "label": "Miagao",
                "label_ar": "مياغاو",
                "label_fr": "Miagao"
            },
            {
                "value": 19791,
                "label": "Pacita",
                "label_ar": "باكيتا",
                "label_fr": "Pacita"
            },
            {
                "value": 19660,
                "label": "Mabalacat",
                "label_ar": "مابالاكات",
                "label_fr": "Mabalacat"
            },
            {
                "value": 19887,
                "label": "Tejeros Convention",
                "label_ar": "اتفاقية Tejeros",
                "label_fr": "Convention de Tejeros"
            },
            {
                "value": 19550,
                "label": "Ayala",
                "label_ar": "أيالا",
                "label_fr": "Ayala"
            },
            {
                "value": 19803,
                "label": "Plaridel",
                "label_ar": "بلارديل",
                "label_fr": "Plaridel"
            },
            {
                "value": 19845,
                "label": "Alfonso",
                "label_ar": "ألفونسو",
                "label_fr": "Alfonso"
            },
            {
                "value": 19848,
                "label": "Tigbauan",
                "label_ar": "تيجباوان",
                "label_fr": "Tigbauan"
            },
            {
                "value": 19704,
                "label": "Angono",
                "label_ar": "أنجونو",
                "label_fr": "Angono"
            },
            {
                "value": 19935,
                "label": "Mapua",
                "label_ar": "مابوا",
                "label_fr": "Mapua"
            },
            {
                "value": 19587,
                "label": "Rodriguez",
                "label_ar": "رودريغيز",
                "label_fr": "Rodriguez"
            },
            {
                "value": 19717,
                "label": "Ugong Norte",
                "label_ar": "أوغونغ نورتي",
                "label_fr": "Ugong Norte"
            },
            {
                "value": 19760,
                "label": "La Trinidad",
                "label_ar": "لا ترينيداد",
                "label_fr": "La Trinidad"
            },
            {
                "value": 19743,
                "label": "Rosario West",
                "label_ar": "روزاريو ويست",
                "label_fr": "Rosario Ouest"
            },
            {
                "value": 19562,
                "label": "Marikina City",
                "label_ar": "مدينة ماريكينا",
                "label_fr": "Ville de Marikina"
            },
            {
                "value": 19762,
                "label": "Mariveles",
                "label_ar": "ماريفيليس",
                "label_fr": "Mariveles"
            },
            {
                "value": 19896,
                "label": "Silay",
                "label_ar": "سيلاى",
                "label_fr": "Silay"
            },
            {
                "value": 19711,
                "label": "Canlubang",
                "label_ar": "كانلوبانغ",
                "label_fr": "Canlubang"
            },
            {
                "value": 19772,
                "label": "Macabebe",
                "label_ar": "ماكابيبي",
                "label_fr": "Macabebe"
            }
        ]
    },
    {
        "country": "Australia",
        "cities": [
            {
                "value": 20496,
                "label": "Manildra",
                "label_ar": "مانيلدرا",
                "label_fr": "Manildra"
            },
            {
                "value": 21231,
                "label": "Myrtleford",
                "label_ar": "ميرتلفورد",
                "label_fr": "Myrtleford"
            },
            {
                "value": 21394,
                "label": "McCrae",
                "label_ar": "مكراي",
                "label_fr": "McCrae"
            },
            {
                "value": 20950,
                "label": "Corlette",
                "label_ar": "كورليت",
                "label_fr": "Corlette"
            },
            {
                "value": 20596,
                "label": "Monbulk",
                "label_ar": "مونبولك",
                "label_fr": "Monbulk"
            },
            {
                "value": 20599,
                "label": "Maribyrnong",
                "label_ar": "ماريبيرنونج",
                "label_fr": "Maribyrnong"
            },
            {
                "value": 20418,
                "label": "Toongabbie",
                "label_ar": "تونجاببي",
                "label_fr": "Toongabbie"
            },
            {
                "value": 21292,
                "label": "Snowtown",
                "label_ar": "سنوتاون",
                "label_fr": "Snowtown"
            },
            {
                "value": 21372,
                "label": "Lake Illawarra",
                "label_ar": "بحيرة إيلاوارا",
                "label_fr": "Lac Illawarra"
            },
            {
                "value": 20883,
                "label": "Mitiamo",
                "label_ar": "ميتيامو",
                "label_fr": "Mitiamo"
            },
            {
                "value": 20453,
                "label": "Potts Point",
                "label_ar": "بوتس بوينت",
                "label_fr": "Point de Potts"
            },
            {
                "value": 20475,
                "label": "Mullumbimby",
                "label_ar": "مولومبيمبي",
                "label_fr": "Mullumbimby"
            },
            {
                "value": 20970,
                "label": "Avenue",
                "label_ar": "السبيل",
                "label_fr": "rue"
            },
            {
                "value": 20648,
                "label": "Lysterfield",
                "label_ar": "ليسترفيلد",
                "label_fr": "Lysterfield"
            },
            {
                "value": 20415,
                "label": "Ultimo",
                "label_ar": "أولتيمو",
                "label_fr": "Ultimo"
            },
            {
                "value": 21488,
                "label": "Mildura",
                "label_ar": "ميلدورا",
                "label_fr": "Mildura"
            },
            {
                "value": 21183,
                "label": "Portarlington",
                "label_ar": "بورتارلينجتون",
                "label_fr": "Portarlington"
            },
            {
                "value": 20668,
                "label": "Kardella",
                "label_ar": "كارديلا",
                "label_fr": "Kardella"
            },
            {
                "value": 20485,
                "label": "Umina",
                "label_ar": "أومينا",
                "label_fr": "Umina"
            },
            {
                "value": 20110,
                "label": "Mudjimba",
                "label_ar": "موديمبا",
                "label_fr": "Mudjimba"
            },
            {
                "value": 21912,
                "label": "Martins Creek",
                "label_ar": "مارتينز كريك",
                "label_fr": "Martins Creek"
            },
            {
                "value": 20241,
                "label": "Picton",
                "label_ar": "بيكتون",
                "label_fr": "Picton"
            },
            {
                "value": 21539,
                "label": "Old Bar",
                "label_ar": "شريط قديم",
                "label_fr": "Ancien bar"
            },
            {
                "value": 21465,
                "label": "Beechwood",
                "label_ar": "خشب الزان",
                "label_fr": "Hêtre"
            },
            {
                "value": 21693,
                "label": "Booral",
                "label_ar": "بورال",
                "label_fr": "Booral"
            },
            {
                "value": 20993,
                "label": "Saint James",
                "label_ar": "جيمس قديس",
                "label_fr": "Saint James"
            },
            {
                "value": 20977,
                "label": "Karoonda",
                "label_ar": "كاروندا",
                "label_fr": "Karoonda"
            },
            {
                "value": 20903,
                "label": "Malabar",
                "label_ar": "مالابار",
                "label_fr": "Malabar"
            },
            {
                "value": 21711,
                "label": "Hove",
                "label_ar": "هوف",
                "label_fr": "Hove"
            },
            {
                "value": 21063,
                "label": "Dalkeith",
                "label_ar": "دالكيث",
                "label_fr": "Dalkeith"
            },
            {
                "value": 20108,
                "label": "Wembley",
                "label_ar": "ويمبلي",
                "label_fr": "Wembley"
            },
            {
                "value": 20539,
                "label": "Killarney",
                "label_ar": "كيلارني",
                "label_fr": "Killarney"
            },
            {
                "value": 20424,
                "label": "Miranda",
                "label_ar": "ميراندا",
                "label_fr": "Miranda"
            },
            {
                "value": 20960,
                "label": "Wilberforce",
                "label_ar": "ويلبرفورس",
                "label_fr": "Wilberforce"
            },
            {
                "value": 21228,
                "label": "Crookwell",
                "label_ar": "كروكويل",
                "label_fr": "Crookwell"
            },
            {
                "value": 20180,
                "label": "Blaxland",
                "label_ar": "بلاكسلاند",
                "label_fr": "Blaxland"
            },
            {
                "value": 21773,
                "label": "Baynton",
                "label_ar": "بينتون",
                "label_fr": "Baynton"
            },
            {
                "value": 21924,
                "label": "Walmer",
                "label_ar": "وولمر",
                "label_fr": "Walmer"
            },
            {
                "value": 20889,
                "label": "Rushworth",
                "label_ar": "روشورث",
                "label_fr": "Rushworth"
            },
            {
                "value": 20961,
                "label": "Kotara",
                "label_ar": "كوتارا",
                "label_fr": "Kotara"
            },
            {
                "value": 20152,
                "label": "Claremont",
                "label_ar": "كليرمونت",
                "label_fr": "Claremont"
            },
            {
                "value": 20082,
                "label": "Ludlow",
                "label_ar": "لودلو",
                "label_fr": "Ludlow"
            },
            {
                "value": 20670,
                "label": "Port MacDonnell",
                "label_ar": "ميناء ماكدونيل",
                "label_fr": "Port MacDonnell"
            },
            {
                "value": 21753,
                "label": "Malmsbury",
                "label_ar": "مالمسبري",
                "label_fr": "Malmsbury"
            },
            {
                "value": 19966,
                "label": "Yass",
                "label_ar": "ياس",
                "label_fr": "Yass"
            },
            {
                "value": 20002,
                "label": "Brunswick",
                "label_ar": "برونزويك",
                "label_fr": "Brunswick"
            },
            {
                "value": 20605,
                "label": "Balaclava",
                "label_ar": "بالاكلافا",
                "label_fr": "Cagoule"
            },
            {
                "value": 19961,
                "label": "Boonah",
                "label_ar": "بونة",
                "label_fr": "Boonah"
            },
            {
                "value": 20033,
                "label": "Stapylton",
                "label_ar": "ستابيلتون",
                "label_fr": "Stapylton"
            },
            {
                "value": 20769,
                "label": "Turramurra",
                "label_ar": "تورامورا",
                "label_fr": "Turramurra"
            },
            {
                "value": 21420,
                "label": "Yarrambat",
                "label_ar": "يارامبات",
                "label_fr": "Yarrambat"
            },
            {
                "value": 20730,
                "label": "Somerville",
                "label_ar": "سومرفيل",
                "label_fr": "Somerville"
            },
            {
                "value": 20802,
                "label": "Neath",
                "label_ar": "نيث",
                "label_fr": "Neath"
            },
            {
                "value": 21699,
                "label": "Mount Helen",
                "label_ar": "جبل هيلين",
                "label_fr": "Mont Hélène"
            },
            {
                "value": 20138,
                "label": "New Farm",
                "label_ar": "مزرعة جديدة",
                "label_fr": "Nouvelle ferme"
            },
            {
                "value": 21261,
                "label": "Mangerton",
                "label_ar": "مانجيرتون",
                "label_fr": "Mangerton"
            },
            {
                "value": 20509,
                "label": "Darlinghurst",
                "label_ar": "دارلينج هيرست",
                "label_fr": "Darlinghurst"
            },
            {
                "value": 21622,
                "label": "Mount Larcom",
                "label_ar": "جبل لاركوم",
                "label_fr": "Mont Larcom"
            },
            {
                "value": 20863,
                "label": "Kinross",
                "label_ar": "كينروس",
                "label_fr": "Kinross"
            },
            {
                "value": 20324,
                "label": "Fitzroy",
                "label_ar": "فيتزروي",
                "label_fr": "Fitzroy"
            },
            {
                "value": 21841,
                "label": "Welshpool",
                "label_ar": "ويلشبول",
                "label_fr": "Welshpool"
            },
            {
                "value": 20916,
                "label": "Woorim",
                "label_ar": "ووريم",
                "label_fr": "Woorim"
            },
            {
                "value": 20115,
                "label": "Glenfield",
                "label_ar": "جلينفيلد",
                "label_fr": "Glenfield"
            },
            {
                "value": 19982,
                "label": "Emu Park",
                "label_ar": "حديقة ايمو",
                "label_fr": "Parc Emu"
            },
            {
                "value": 21368,
                "label": "Wallington",
                "label_ar": "والينغتون",
                "label_fr": "Wallington"
            },
            {
                "value": 21139,
                "label": "Southbank",
                "label_ar": "ساوثبانك",
                "label_fr": "Rive sud"
            },
            {
                "value": 21189,
                "label": "Marysville",
                "label_ar": "ماريسفيل",
                "label_fr": "Marysville"
            },
            {
                "value": 21821,
                "label": "Clontarf",
                "label_ar": "كلونتارف",
                "label_fr": "Clontarf"
            },
            {
                "value": 21585,
                "label": "Upper Burringbar",
                "label_ar": "باررينجبار العلوي",
                "label_fr": "Barre de bavure supérieure"
            },
            {
                "value": 21810,
                "label": "West Wyalong",
                "label_ar": "غرب ويالونغ",
                "label_fr": "West Wyalong"
            },
            {
                "value": 20847,
                "label": "Devonport",
                "label_ar": "ديفونبورت",
                "label_fr": "Devonport"
            },
            {
                "value": 21250,
                "label": "Wamuran",
                "label_ar": "واموران",
                "label_fr": "Wamuran"
            },
            {
                "value": 21137,
                "label": "Deakin",
                "label_ar": "ديكين",
                "label_fr": "Deakin"
            },
            {
                "value": 21808,
                "label": "Macquarie Park",
                "label_ar": "ماكواري بارك",
                "label_fr": "Parc Macquarie"
            },
            {
                "value": 20574,
                "label": "Merlynston",
                "label_ar": "ميرلينستون",
                "label_fr": "Merlynston"
            },
            {
                "value": 20955,
                "label": "Bruce",
                "label_ar": "بروس",
                "label_fr": "Bruce"
            },
            {
                "value": 21690,
                "label": "Riverside",
                "label_ar": "ريفرسايد",
                "label_fr": "Riverside"
            },
            {
                "value": 21840,
                "label": "Wentworth",
                "label_ar": "وينتورث",
                "label_fr": "Wentworth"
            },
            {
                "value": 20253,
                "label": "Revesby",
                "label_ar": "Revesby",
                "label_fr": "Revesby"
            },
            {
                "value": 20219,
                "label": "Lane Cove",
                "label_ar": "لين كوف",
                "label_fr": "Lane Cove"
            },
            {
                "value": 21142,
                "label": "Blakehurst",
                "label_ar": "بلايكهيرست",
                "label_fr": "Blakehurst"
            },
            {
                "value": 20626,
                "label": "Riverstone",
                "label_ar": "حجر النهر",
                "label_fr": "Pierre de rivière"
            },
            {
                "value": 20660,
                "label": "Brooklyn",
                "label_ar": "بروكلين",
                "label_fr": "Brooklyn"
            },
            {
                "value": 21245,
                "label": "Shenton Park",
                "label_ar": "شينتون بارك",
                "label_fr": "Parc Shenton"
            },
            {
                "value": 20005,
                "label": "South Melbourne",
                "label_ar": "جنوب ملبورن",
                "label_fr": "South Melbourne"
            },
            {
                "value": 20611,
                "label": "Maclean",
                "label_ar": "ماكلين",
                "label_fr": "Maclean"
            },
            {
                "value": 21273,
                "label": "Stieglitz",
                "label_ar": "شتيجليتز",
                "label_fr": "Stieglitz"
            },
            {
                "value": 21843,
                "label": "Tooronga",
                "label_ar": "تورونجا",
                "label_fr": "Tooronga"
            },
            {
                "value": 20797,
                "label": "Davistown",
                "label_ar": "ديفيستاون",
                "label_fr": "Davistown"
            },
            {
                "value": 20894,
                "label": "Newstead",
                "label_ar": "نيوستيد",
                "label_fr": "Newstead"
            },
            {
                "value": 21211,
                "label": "Wilson",
                "label_ar": "ويلسون",
                "label_fr": "Wilson"
            },
            {
                "value": 20339,
                "label": "Clontarf",
                "label_ar": "كلونتارف",
                "label_fr": "Clontarf"
            },
            {
                "value": 21352,
                "label": "Canterbury",
                "label_ar": "كانتربري",
                "label_fr": "Cantorbéry"
            },
            {
                "value": 21266,
                "label": "Burnside",
                "label_ar": "بيرنسايد",
                "label_fr": "Burnside"
            },
            {
                "value": 21409,
                "label": "Sandford",
                "label_ar": "ساندفورد",
                "label_fr": "Sandford"
            },
            {
                "value": 21526,
                "label": "Junee",
                "label_ar": "جوني",
                "label_fr": "Junee"
            },
            {
                "value": 20292,
                "label": "Doncaster East",
                "label_ar": "دونكاستر الشرق",
                "label_fr": "Doncaster Est"
            },
            {
                "value": 21923,
                "label": "Boneo",
                "label_ar": "بونيو",
                "label_fr": "Boneo"
            },
            {
                "value": 20223,
                "label": "Penrith",
                "label_ar": "بنريث",
                "label_fr": "Penrith"
            },
            {
                "value": 20701,
                "label": "Currumbin",
                "label_ar": "كورومبين",
                "label_fr": "Currumbin"
            },
            {
                "value": 20739,
                "label": "Loxton",
                "label_ar": "لوكستون",
                "label_fr": "Loxton"
            },
            {
                "value": 21506,
                "label": "Upper Caboolture",
                "label_ar": "كابولتشر العلوي",
                "label_fr": "Caboolture supérieure"
            },
            {
                "value": 20570,
                "label": "Clifton Springs",
                "label_ar": "كليفتون سبرينجز",
                "label_fr": "Clifton Springs"
            },
            {
                "value": 20516,
                "label": "Central Coast",
                "label_ar": "الساحل المركزي",
                "label_fr": "Côte centrale"
            },
            {
                "value": 20111,
                "label": "Wellington Point",
                "label_ar": "ويلينجتون بوينت",
                "label_fr": "Wellington Point"
            },
            {
                "value": 21319,
                "label": "Esk",
                "label_ar": "إسك",
                "label_fr": "Esk"
            },
            {
                "value": 20756,
                "label": "Wentworth Falls",
                "label_ar": "وينتورث فولز",
                "label_fr": "Wentworth Falls"
            },
            {
                "value": 20644,
                "label": "Narrawong",
                "label_ar": "ناراونج",
                "label_fr": "Narrawong"
            },
            {
                "value": 21679,
                "label": "Somersby",
                "label_ar": "سومرسبي",
                "label_fr": "Somersby"
            },
            {
                "value": 20527,
                "label": "Bolton",
                "label_ar": "بولتون",
                "label_fr": "Boulonner"
            },
            {
                "value": 21428,
                "label": "Allansford",
                "label_ar": "ألانسفورد",
                "label_fr": "Allansford"
            },
            {
                "value": 21751,
                "label": "Dereel",
                "label_ar": "ديريل",
                "label_fr": "Dereel"
            },
            {
                "value": 20434,
                "label": "Carlton North",
                "label_ar": "كارلتون نورث",
                "label_fr": "Carlton Nord"
            },
            {
                "value": 21848,
                "label": "Illawarra",
                "label_ar": "Illawarra",
                "label_fr": "Illawarra"
            },
            {
                "value": 21062,
                "label": "Marino",
                "label_ar": "مارينو",
                "label_fr": "Marino"
            },
            {
                "value": 21337,
                "label": "Mirboo North",
                "label_ar": "ميربو الشمالية",
                "label_fr": "Mirboo Nord"
            },
            {
                "value": 20922,
                "label": "Mittagong",
                "label_ar": "ميتاجونج",
                "label_fr": "Mittagong"
            },
            {
                "value": 21048,
                "label": "Freemans Reach",
                "label_ar": "وصول Freemans",
                "label_fr": "Freemans Reach"
            },
            {
                "value": 20417,
                "label": "Botany",
                "label_ar": "علم النبات",
                "label_fr": "Botanique"
            },
            {
                "value": 20705,
                "label": "Alberton",
                "label_ar": "ألبرتون",
                "label_fr": "Alberton"
            },
            {
                "value": 21783,
                "label": "Nhill",
                "label_ar": "نهيل",
                "label_fr": "Nhill"
            },
            {
                "value": 21175,
                "label": "River Heads",
                "label_ar": "رؤساء النهر",
                "label_fr": "Têtes de rivière"
            },
            {
                "value": 21619,
                "label": "Mount Julian",
                "label_ar": "جبل جوليان",
                "label_fr": "Mont Julian"
            },
            {
                "value": 20581,
                "label": "Wanneroo",
                "label_ar": "Wanneroo",
                "label_fr": "Wanneroo"
            },
            {
                "value": 20999,
                "label": "Marion",
                "label_ar": "ماريون",
                "label_fr": "Marion"
            },
            {
                "value": 21507,
                "label": "Kettering",
                "label_ar": "كيترينج",
                "label_fr": "Kettering"
            },
            {
                "value": 19991,
                "label": "Elsternwick",
                "label_ar": "Elsternwick",
                "label_fr": "Elsternwick"
            },
            {
                "value": 20839,
                "label": "Gisborne",
                "label_ar": "جيسبورن",
                "label_fr": "Gisborne"
            },
            {
                "value": 20121,
                "label": "Ivanhoe East",
                "label_ar": "ايفانهو الشرق",
                "label_fr": "Ivanhoe Est"
            },
            {
                "value": 21415,
                "label": "Ellalong",
                "label_ar": "Ellalong",
                "label_fr": "Ellalong"
            },
            {
                "value": 21028,
                "label": "Wollert",
                "label_ar": "وولرت",
                "label_fr": "Wollert"
            },
            {
                "value": 21315,
                "label": "Darkan",
                "label_ar": "داركان",
                "label_fr": "Darkan"
            },
            {
                "value": 20817,
                "label": "Pine Mountain",
                "label_ar": "جبل الصنوبر",
                "label_fr": "Pine Mountain"
            },
            {
                "value": 21348,
                "label": "Kuranda",
                "label_ar": "كوراندا",
                "label_fr": "Kuranda"
            },
            {
                "value": 21391,
                "label": "Sherbrook",
                "label_ar": "شيربروك",
                "label_fr": "Sherbrook"
            },
            {
                "value": 21900,
                "label": "Wollar",
                "label_ar": "وولار",
                "label_fr": "Wollar"
            },
            {
                "value": 21244,
                "label": "Pambula",
                "label_ar": "بامبولا",
                "label_fr": "Pambula"
            },
            {
                "value": 20247,
                "label": "Albion Park",
                "label_ar": "ألبيون بارك",
                "label_fr": "Parc Albion"
            },
            {
                "value": 21229,
                "label": "Bungonia",
                "label_ar": "بونغونيا",
                "label_fr": "Bungonia"
            },
            {
                "value": 20285,
                "label": "Sydenham",
                "label_ar": "سيدنهام",
                "label_fr": "Sydenham"
            },
            {
                "value": 21535,
                "label": "Hawkesbury Heights",
                "label_ar": "هاوكسبري هايتس",
                "label_fr": "Hawkesbury Heights"
            },
            {
                "value": 21426,
                "label": "Killarney",
                "label_ar": "كيلارني",
                "label_fr": "Killarney"
            },
            {
                "value": 21133,
                "label": "Somerset",
                "label_ar": "سومرست",
                "label_fr": "Somerset"
            },
            {
                "value": 21030,
                "label": "Ballan",
                "label_ar": "بلان",
                "label_fr": "Ballan"
            },
            {
                "value": 20353,
                "label": "Croydon North",
                "label_ar": "كرويدون نورث",
                "label_fr": "Croydon Nord"
            },
            {
                "value": 21160,
                "label": "Wallacia",
                "label_ar": "والاسيا",
                "label_fr": "Wallacia"
            },
            {
                "value": 20087,
                "label": "Port Pirie",
                "label_ar": "بورت بيري",
                "label_fr": "Port Pirie"
            },
            {
                "value": 20126,
                "label": "Dapto",
                "label_ar": "دابتو",
                "label_fr": "Dapto"
            },
            {
                "value": 21955,
                "label": "Menangle",
                "label_ar": "مينانجل",
                "label_fr": "Menangle"
            },
            {
                "value": 21026,
                "label": "Alfredton",
                "label_ar": "ألفريدتون",
                "label_fr": "Alfredton"
            },
            {
                "value": 20347,
                "label": "Derrimut",
                "label_ar": "ديريموت",
                "label_fr": "Derrimut"
            },
            {
                "value": 20192,
                "label": "Hurstville",
                "label_ar": "هورستفيل",
                "label_fr": "Hurstville"
            },
            {
                "value": 21406,
                "label": "Sassafras",
                "label_ar": "ساسافراس",
                "label_fr": "Sassafras"
            },
            {
                "value": 21343,
                "label": "Jarrahdale",
                "label_ar": "جاراديل",
                "label_fr": "Jarrahdale"
            },
            {
                "value": 21176,
                "label": "Clyde",
                "label_ar": "كلايد",
                "label_fr": "Clyde"
            },
            {
                "value": 21839,
                "label": "Woodburn",
                "label_ar": "وودبيرن",
                "label_fr": "Woodburn"
            },
            {
                "value": 21341,
                "label": "Point Pass",
                "label_ar": "ممر النقاط",
                "label_fr": "Point Pass"
            },
            {
                "value": 19968,
                "label": "Bundamba",
                "label_ar": "بندامبا",
                "label_fr": "Bundamba"
            },
            {
                "value": 21226,
                "label": "Katherine",
                "label_ar": "كاثرين",
                "label_fr": "Katherine"
            },
            {
                "value": 21045,
                "label": "Yerrinbool",
                "label_ar": "يرينبول",
                "label_fr": "Yerrinbool"
            },
            {
                "value": 20892,
                "label": "Burrum Heads",
                "label_ar": "رؤساء بوروم",
                "label_fr": "Têtes de Burrum"
            },
            {
                "value": 21136,
                "label": "Hazelwood",
                "label_ar": "بندق",
                "label_fr": "Noisetier"
            },
            {
                "value": 21545,
                "label": "Woodgate",
                "label_ar": "وودجيت",
                "label_fr": "Woodgate"
            },
            {
                "value": 20162,
                "label": "Rosebery",
                "label_ar": "روزبيري",
                "label_fr": "Rosebery"
            },
            {
                "value": 20488,
                "label": "Berkeley Vale",
                "label_ar": "بيركلي فال",
                "label_fr": "Berkeley Vale"
            },
            {
                "value": 21744,
                "label": "Gilberton",
                "label_ar": "جيلبرتون",
                "label_fr": "Gilberton"
            },
            {
                "value": 21635,
                "label": "Railton",
                "label_ar": "رايلتون",
                "label_fr": "Railton"
            },
            {
                "value": 21702,
                "label": "Glen Osmond",
                "label_ar": "جلين أوزموند",
                "label_fr": "Glen Osmond"
            },
            {
                "value": 20134,
                "label": "Murrumba",
                "label_ar": "مورومبا",
                "label_fr": "Murrumba"
            },
            {
                "value": 20523,
                "label": "Killcare",
                "label_ar": "كيلكير",
                "label_fr": "Killcare"
            },
            {
                "value": 21173,
                "label": "Napoleons",
                "label_ar": "نابليون",
                "label_fr": "Napoléon"
            },
            {
                "value": 20311,
                "label": "North Melbourne",
                "label_ar": "شمال ملبورن",
                "label_fr": "North Melbourne"
            },
            {
                "value": 21490,
                "label": "Lethbridge",
                "label_ar": "ليثبريدج",
                "label_fr": "Lethbridge"
            },
            {
                "value": 20352,
                "label": "Hamilton",
                "label_ar": "هاملتون",
                "label_fr": "Hamilton"
            },
            {
                "value": 20124,
                "label": "Narrabeen",
                "label_ar": "نارابين",
                "label_fr": "Narrabeen"
            },
            {
                "value": 20065,
                "label": "Kelmscott",
                "label_ar": "كيلمسكوت",
                "label_fr": "Kelmscott"
            },
            {
                "value": 21082,
                "label": "Erina",
                "label_ar": "ايرينا",
                "label_fr": "Erina"
            },
            {
                "value": 21283,
                "label": "Tammin",
                "label_ar": "تامين",
                "label_fr": "Tammin"
            },
            {
                "value": 20901,
                "label": "North Turramurra",
                "label_ar": "شمال تورامورا",
                "label_fr": "Nord Turramurra"
            },
            {
                "value": 20805,
                "label": "Melton South",
                "label_ar": "ميلتون ساوث",
                "label_fr": "Melton Sud"
            },
            {
                "value": 20661,
                "label": "Wagga Wagga",
                "label_ar": "واجا واجا",
                "label_fr": "Wagga Wagga"
            },
            {
                "value": 21430,
                "label": "Crossley",
                "label_ar": "كروسلي",
                "label_fr": "Crossley"
            },
            {
                "value": 20391,
                "label": "Cooranbong",
                "label_ar": "كورانبونج",
                "label_fr": "Cooranbong"
            },
            {
                "value": 21984,
                "label": "Coolamon",
                "label_ar": "كولامون",
                "label_fr": "Coolamon"
            },
            {
                "value": 21123,
                "label": "Palmer",
                "label_ar": "بالمر",
                "label_fr": "Palmer"
            },
            {
                "value": 21897,
                "label": "Laguna",
                "label_ar": "لاجونا",
                "label_fr": "Laguna"
            },
            {
                "value": 21677,
                "label": "Saint Andrews",
                "label_ar": "سانت اندروز",
                "label_fr": "Saint Andrews"
            },
            {
                "value": 21258,
                "label": "Basin View",
                "label_ar": "عرض الحوض",
                "label_fr": "Vue du bassin"
            },
            {
                "value": 20610,
                "label": "Gorokan",
                "label_ar": "جوروكان",
                "label_fr": "Gorokan"
            },
            {
                "value": 21247,
                "label": "Woodend",
                "label_ar": "وودند",
                "label_fr": "Woodend"
            },
            {
                "value": 21659,
                "label": "Tenterfield",
                "label_ar": "تينرفيلد",
                "label_fr": "Tenterfield"
            },
            {
                "value": 20081,
                "label": "Claremont",
                "label_ar": "كليرمونت",
                "label_fr": "Claremont"
            },
            {
                "value": 21403,
                "label": "Clyde",
                "label_ar": "كلايد",
                "label_fr": "Clyde"
            },
            {
                "value": 20157,
                "label": "Mount Isa",
                "label_ar": "جبل عيسى",
                "label_fr": "Mont Isa"
            },
            {
                "value": 20071,
                "label": "Stoneville",
                "label_ar": "ستونفيل",
                "label_fr": "Stoneville"
            },
            {
                "value": 20992,
                "label": "Seville",
                "label_ar": "إشبيلية",
                "label_fr": "Séville"
            },
            {
                "value": 20332,
                "label": "Belgrave",
                "label_ar": "بلجريف",
                "label_fr": "Belgrave"
            },
            {
                "value": 21709,
                "label": "Mooralla",
                "label_ar": "مورالا",
                "label_fr": "Mooralla"
            },
            {
                "value": 21915,
                "label": "Westfield",
                "label_ar": "ويستفيلد",
                "label_fr": "Westfield"
            },
            {
                "value": 21727,
                "label": "Chinchilla",
                "label_ar": "شينشيلا",
                "label_fr": "Chinchilla"
            },
            {
                "value": 20812,
                "label": "Waaia",
                "label_ar": "Waaia",
                "label_fr": "Waaia"
            },
            {
                "value": 21434,
                "label": "Gepps Cross",
                "label_ar": "جيبس كروس",
                "label_fr": "Gepps Cross"
            },
            {
                "value": 21742,
                "label": "Big Pats Creek",
                "label_ar": "بيج باتس كريك",
                "label_fr": "Big Pats Creek"
            },
            {
                "value": 20945,
                "label": "Yarra Junction",
                "label_ar": "مفرق يارا",
                "label_fr": "Jonction Yarra"
            },
            {
                "value": 20584,
                "label": "Scarborough",
                "label_ar": "سكاربورو",
                "label_fr": "Scarborough"
            },
            {
                "value": 21592,
                "label": "Newnham",
                "label_ar": "نيونهام",
                "label_fr": "Newnham"
            },
            {
                "value": 21215,
                "label": "Churchill",
                "label_ar": "تشرشل",
                "label_fr": "Churchill"
            },
            {
                "value": 21746,
                "label": "Logan Village",
                "label_ar": "قرية لوجان",
                "label_fr": "Village de Logan"
            },
            {
                "value": 21034,
                "label": "Waubra",
                "label_ar": "Waubra",
                "label_fr": "Waubra"
            },
            {
                "value": 21604,
                "label": "Braeside",
                "label_ar": "براسايد",
                "label_fr": "Braeside"
            },
            {
                "value": 20268,
                "label": "Keilor",
                "label_ar": "كيلور",
                "label_fr": "Keilor"
            },
            {
                "value": 21279,
                "label": "Williams",
                "label_ar": "وليامز",
                "label_fr": "Williams"
            },
            {
                "value": 20358,
                "label": "Oakleigh South",
                "label_ar": "أوكلي ساوث",
                "label_fr": "Oakleigh Sud"
            },
            {
                "value": 21157,
                "label": "Sale",
                "label_ar": "تخفيض السعر",
                "label_fr": "Vente"
            },
            {
                "value": 20723,
                "label": "Kenmore",
                "label_ar": "كينمور",
                "label_fr": "Kenmore"
            },
            {
                "value": 20320,
                "label": "Parkville",
                "label_ar": "باركفيل",
                "label_fr": "Parkville"
            },
            {
                "value": 20987,
                "label": "Aldgate",
                "label_ar": "الدغات",
                "label_fr": "Aldgate"
            },
            {
                "value": 21447,
                "label": "Narrogin",
                "label_ar": "ناروجين",
                "label_fr": "Narrogin"
            },
            {
                "value": 20651,
                "label": "Kenwick",
                "label_ar": "كينويك",
                "label_fr": "Kenwick"
            },
            {
                "value": 21907,
                "label": "Coonawarra",
                "label_ar": "كوناوارا",
                "label_fr": "Coonawarra"
            },
            {
                "value": 21552,
                "label": "Broken Hill",
                "label_ar": "بروكن هيل",
                "label_fr": "Broken Hill"
            },
            {
                "value": 20495,
                "label": "Moore",
                "label_ar": "مور",
                "label_fr": "Moore"
            },
            {
                "value": 20500,
                "label": "Warrigal",
                "label_ar": "واريغال",
                "label_fr": "Warrigal"
            },
            {
                "value": 21806,
                "label": "Moranbah",
                "label_ar": "مورانباه",
                "label_fr": "Moranbah"
            },
            {
                "value": 21520,
                "label": "Killingworth",
                "label_ar": "كيلنغوورث",
                "label_fr": "Killingworth"
            },
            {
                "value": 20706,
                "label": "Strathpine",
                "label_ar": "ستراثبين",
                "label_fr": "Strathpine"
            },
            {
                "value": 21052,
                "label": "Maleny",
                "label_ar": "ماليني",
                "label_fr": "Maleny"
            },
            {
                "value": 21575,
                "label": "Canungra",
                "label_ar": "كانونغرا",
                "label_fr": "Canungra"
            },
            {
                "value": 21224,
                "label": "Uki",
                "label_ar": "أوكي",
                "label_fr": "Uki"
            },
            {
                "value": 21987,
                "label": "Melrose",
                "label_ar": "ميلروز",
                "label_fr": "Melrose"
            },
            {
                "value": 20717,
                "label": "Norman Park",
                "label_ar": "نورمان بارك",
                "label_fr": "Parc normand"
            },
            {
                "value": 21095,
                "label": "Yolla",
                "label_ar": "يولا",
                "label_fr": "Yolla"
            },
            {
                "value": 20048,
                "label": "Samford",
                "label_ar": "سامفورد",
                "label_fr": "Samford"
            },
            {
                "value": 20938,
                "label": "Somerset",
                "label_ar": "سومرست",
                "label_fr": "Somerset"
            },
            {
                "value": 20120,
                "label": "Forest",
                "label_ar": "غابة",
                "label_fr": "Forêt"
            },
            {
                "value": 20037,
                "label": "Noosaville",
                "label_ar": "نوسافيل",
                "label_fr": "Noosaville"
            },
            {
                "value": 21200,
                "label": "Taree",
                "label_ar": "تاري",
                "label_fr": "Taree"
            },
            {
                "value": 20282,
                "label": "Richmond",
                "label_ar": "ريتشموند",
                "label_fr": "Richmond"
            },
            {
                "value": 20918,
                "label": "Singleton",
                "label_ar": "سينجلتون",
                "label_fr": "Singleton"
            },
            {
                "value": 20070,
                "label": "Warnbro",
                "label_ar": "وارنبرو",
                "label_fr": "Warnbro"
            },
            {
                "value": 21547,
                "label": "Mount Saint Thomas",
                "label_ar": "جبل سانت توماس",
                "label_fr": "Mont Saint Thomas"
            },
            {
                "value": 20361,
                "label": "Saint Albans",
                "label_ar": "سانت ألبانز",
                "label_fr": "Saint Albans"
            },
            {
                "value": 21135,
                "label": "Colac",
                "label_ar": "كولاك",
                "label_fr": "Colac"
            },
            {
                "value": 20860,
                "label": "Normanville",
                "label_ar": "نورمانفيل",
                "label_fr": "Normanville"
            },
            {
                "value": 21713,
                "label": "Howden",
                "label_ar": "هودين",
                "label_fr": "Howden"
            },
            {
                "value": 21814,
                "label": "Mundaring",
                "label_ar": "الندرة",
                "label_fr": "Mundaring"
            },
            {
                "value": 20269,
                "label": "Carnegie",
                "label_ar": "كارنيجي",
                "label_fr": "Carnegie"
            },
            {
                "value": 21582,
                "label": "Stannum",
                "label_ar": "ستانوم",
                "label_fr": "Stannum"
            },
            {
                "value": 21007,
                "label": "Como",
                "label_ar": "كومو",
                "label_fr": "Côme"
            },
            {
                "value": 21698,
                "label": "Broadwater",
                "label_ar": "برودووتر",
                "label_fr": "Broadwater"
            },
            {
                "value": 20338,
                "label": "Greensborough",
                "label_ar": "جرينسبورو",
                "label_fr": "Greensborough"
            },
            {
                "value": 21421,
                "label": "Wedderburn",
                "label_ar": "ويديربيرن",
                "label_fr": "Wedderburn"
            },
            {
                "value": 21450,
                "label": "Alstonville",
                "label_ar": "ألستونفيل",
                "label_fr": "Alstonville"
            },
            {
                "value": 21732,
                "label": "McLaren Flat",
                "label_ar": "شقة ماكلارين",
                "label_fr": "McLaren Flat"
            },
            {
                "value": 20318,
                "label": "Carlton",
                "label_ar": "كارلتون",
                "label_fr": "Carlton"
            },
            {
                "value": 20908,
                "label": "Parkwood",
                "label_ar": "باركوود",
                "label_fr": "Parkwood"
            },
            {
                "value": 21565,
                "label": "North Beach",
                "label_ar": "نورث بيتش",
                "label_fr": "North Beach"
            },
            {
                "value": 21412,
                "label": "Parkes",
                "label_ar": "باركس",
                "label_fr": "Parkes"
            },
            {
                "value": 21719,
                "label": "Sulphur Creek",
                "label_ar": "سلفر كريك",
                "label_fr": "Sulphur Creek"
            },
            {
                "value": 20507,
                "label": "Gloucester",
                "label_ar": "جلوستر",
                "label_fr": "Gloucester"
            },
            {
                "value": 20379,
                "label": "Noosa",
                "label_ar": "نوسا",
                "label_fr": "Noosa"
            },
            {
                "value": 21436,
                "label": "Kanwal",
                "label_ar": "كانوال",
                "label_fr": "Kanwal"
            },
            {
                "value": 20913,
                "label": "Gatton",
                "label_ar": "جاتون",
                "label_fr": "Gatton"
            },
            {
                "value": 21451,
                "label": "Blackwall",
                "label_ar": "بلاك وول",
                "label_fr": "Mur noir"
            },
            {
                "value": 20465,
                "label": "Smithfield",
                "label_ar": "سميثفيلد",
                "label_fr": "Smithfield"
            },
            {
                "value": 21491,
                "label": "Euroa",
                "label_ar": "أورا",
                "label_fr": "Euroa"
            },
            {
                "value": 21221,
                "label": "Banksmeadow",
                "label_ar": "مرج البنك",
                "label_fr": "Banksmeadow"
            },
            {
                "value": 21191,
                "label": "The Patch",
                "label_ar": "التصحيح",
                "label_fr": "Le patch"
            },
            {
                "value": 21492,
                "label": "Cardinia",
                "label_ar": "كاردينيا",
                "label_fr": "La Sardaigne"
            },
            {
                "value": 20536,
                "label": "Dakabin",
                "label_ar": "دكابين",
                "label_fr": "Dakabin"
            },
            {
                "value": 21722,
                "label": "Bicheno",
                "label_ar": "بيتشينو",
                "label_fr": "Bicheno"
            },
            {
                "value": 20020,
                "label": "Helensvale",
                "label_ar": "هيلينزفيل",
                "label_fr": "Helensvale"
            },
            {
                "value": 20755,
                "label": "Narara",
                "label_ar": "نارارا",
                "label_fr": "Narara"
            },
            {
                "value": 21332,
                "label": "Foster",
                "label_ar": "الحاضنة",
                "label_fr": "Favoriser"
            },
            {
                "value": 20559,
                "label": "Kincumber",
                "label_ar": "كينكامبر",
                "label_fr": "Kincumber"
            },
            {
                "value": 20906,
                "label": "Tennyson",
                "label_ar": "تينيسون",
                "label_fr": "Tennyson"
            },
            {
                "value": 21350,
                "label": "Tanunda",
                "label_ar": "تانوندا",
                "label_fr": "Tanunda"
            },
            {
                "value": 20798,
                "label": "Lavington",
                "label_ar": "لافينجتون",
                "label_fr": "Lavington"
            },
            {
                "value": 21022,
                "label": "Riddell",
                "label_ar": "ريدل",
                "label_fr": "Riddell"
            },
            {
                "value": 21355,
                "label": "Yarram",
                "label_ar": "يارام",
                "label_fr": "Yarram"
            },
            {
                "value": 20288,
                "label": "Williamstown",
                "label_ar": "وليامزتاون",
                "label_fr": "Williamstown"
            },
            {
                "value": 21818,
                "label": "Tumut",
                "label_ar": "توموت",
                "label_fr": "Tumut"
            },
            {
                "value": 20827,
                "label": "Bringelly",
                "label_ar": "برينجلي",
                "label_fr": "Bringelly"
            },
            {
                "value": 21288,
                "label": "Callington",
                "label_ar": "كالينجتون",
                "label_fr": "Callington"
            },
            {
                "value": 21154,
                "label": "Palm Island",
                "label_ar": "جزيرة النخلة",
                "label_fr": "Palm Island"
            },
            {
                "value": 19962,
                "label": "Terry Hills",
                "label_ar": "تيري هيلز",
                "label_fr": "Terry Hills"
            },
            {
                "value": 20895,
                "label": "Tottenham",
                "label_ar": "توتنهام",
                "label_fr": "Tottenham"
            },
            {
                "value": 21729,
                "label": "Parap",
                "label_ar": "Parap",
                "label_fr": "Parap"
            },
            {
                "value": 21444,
                "label": "Broadwater",
                "label_ar": "برودووتر",
                "label_fr": "Broadwater"
            },
            {
                "value": 21918,
                "label": "Newstead",
                "label_ar": "نيوستيد",
                "label_fr": "Newstead"
            },
            {
                "value": 20689,
                "label": "Richmond",
                "label_ar": "ريتشموند",
                "label_fr": "Richmond"
            },
            {
                "value": 20503,
                "label": "Jindabyne",
                "label_ar": "جيندابين",
                "label_fr": "Jindabyne"
            },
            {
                "value": 20325,
                "label": "Langwarrin",
                "label_ar": "لانغواررين",
                "label_fr": "Langwarrin"
            },
            {
                "value": 21241,
                "label": "Griffith",
                "label_ar": "جريفيث",
                "label_fr": "Griffith"
            },
            {
                "value": 21795,
                "label": "Beaumont",
                "label_ar": "بومونت",
                "label_fr": "Beaumont"
            },
            {
                "value": 20085,
                "label": "Erskine",
                "label_ar": "إرسكين",
                "label_fr": "Erskine"
            },
            {
                "value": 21068,
                "label": "Lawson",
                "label_ar": "لوسون",
                "label_fr": "Lawson"
            },
            {
                "value": 21147,
                "label": "Strathmore",
                "label_ar": "ستراثمور",
                "label_fr": "Strathmore"
            },
            {
                "value": 21689,
                "label": "McLaren Vale",
                "label_ar": "ماكلارين فال",
                "label_fr": "McLaren Vale"
            },
            {
                "value": 20573,
                "label": "Seaford",
                "label_ar": "سيفورد",
                "label_fr": "Seaford"
            },
            {
                "value": 20423,
                "label": "Wodonga",
                "label_ar": "وودونجا",
                "label_fr": "Wodonga"
            },
            {
                "value": 21736,
                "label": "Somerton",
                "label_ar": "سوميرتون",
                "label_fr": "Somerton"
            },
            {
                "value": 21023,
                "label": "Parkdale",
                "label_ar": "باركديل",
                "label_fr": "Parkdale"
            },
            {
                "value": 20112,
                "label": "Haberfield",
                "label_ar": "هابرفيلد",
                "label_fr": "Haberfield"
            },
            {
                "value": 20923,
                "label": "Shellharbour",
                "label_ar": "شلهربور",
                "label_fr": "Shellharbour"
            },
            {
                "value": 20634,
                "label": "Heathmont",
                "label_ar": "هيثمونت",
                "label_fr": "Heathmont"
            },
            {
                "value": 21782,
                "label": "Bowraville",
                "label_ar": "بورافيل",
                "label_fr": "Bowraville"
            },
            {
                "value": 20072,
                "label": "Gooseberry Hill",
                "label_ar": "تل عنب الثعلب",
                "label_fr": "Gooseberry Hill"
            },
            {
                "value": 21572,
                "label": "Roseworthy",
                "label_ar": "روزورثي",
                "label_fr": "Roseworthy"
            },
            {
                "value": 21296,
                "label": "Monteagle",
                "label_ar": "مونتيجل",
                "label_fr": "Monteagle"
            },
            {
                "value": 20425,
                "label": "Armidale",
                "label_ar": "أرميدال",
                "label_fr": "Armidale"
            },
            {
                "value": 19997,
                "label": "Ringwood East",
                "label_ar": "رينجوود الشرق",
                "label_fr": "Ringwood Est"
            },
            {
                "value": 21304,
                "label": "Mudgee",
                "label_ar": "Mudgee",
                "label_fr": "Mudgee"
            },
            {
                "value": 20646,
                "label": "Lemon Tree Passage",
                "label_ar": "ممر شجرة الليمون",
                "label_fr": "Passage du citronnier"
            },
            {
                "value": 20649,
                "label": "Brookfield",
                "label_ar": "بروكفيلد",
                "label_fr": "Brookfield"
            },
            {
                "value": 20751,
                "label": "Virginia",
                "label_ar": "فرجينيا",
                "label_fr": "Virginie"
            },
            {
                "value": 20416,
                "label": "Auburn",
                "label_ar": "أوبورن",
                "label_fr": "Auburn"
            },
            {
                "value": 21144,
                "label": "Kareela",
                "label_ar": "كاريلا",
                "label_fr": "Kareela"
            },
            {
                "value": 20553,
                "label": "Mannering Park",
                "label_ar": "مانرينغ بارك",
                "label_fr": "Parc Mannering"
            },
            {
                "value": 21101,
                "label": "Hagley",
                "label_ar": "هاجلي",
                "label_fr": "Hagley"
            },
            {
                "value": 21833,
                "label": "Kalorama",
                "label_ar": "كالوراما",
                "label_fr": "Kalorama"
            },
            {
                "value": 20771,
                "label": "Kenthurst",
                "label_ar": "كنثورست",
                "label_fr": "Kenthurst"
            },
            {
                "value": 20154,
                "label": "Launceston",
                "label_ar": "لونسيستون",
                "label_fr": "Launceston"
            },
            {
                "value": 20612,
                "label": "Dayboro",
                "label_ar": "دايبورو",
                "label_fr": "Dayboro"
            },
            {
                "value": 21757,
                "label": "Tambo Upper",
                "label_ar": "تامبو العلوي",
                "label_fr": "Tambo Upper"
            },
            {
                "value": 21772,
                "label": "Binnaway",
                "label_ar": "بيناوي",
                "label_fr": "Binnaway"
            },
            {
                "value": 20462,
                "label": "Fremantle",
                "label_ar": "فريمانتل",
                "label_fr": "Fremantle"
            },
            {
                "value": 20092,
                "label": "Hindmarsh",
                "label_ar": "هندمارش",
                "label_fr": "Hindmarsh"
            },
            {
                "value": 20382,
                "label": "Eudlo",
                "label_ar": "يودلو",
                "label_fr": "Eudlo"
            },
            {
                "value": 20861,
                "label": "Birkenhead",
                "label_ar": "بيركينهيد",
                "label_fr": "Birkenhead"
            },
            {
                "value": 20367,
                "label": "Bass Hill",
                "label_ar": "باس هيل",
                "label_fr": "Bass Hill"
            },
            {
                "value": 20394,
                "label": "Prospect",
                "label_ar": "احتمال",
                "label_fr": "Perspective"
            },
            {
                "value": 20275,
                "label": "Fairfield",
                "label_ar": "فيرفيلد",
                "label_fr": "Fairfield"
            },
            {
                "value": 20279,
                "label": "Springvale",
                "label_ar": "سبرينجفيل",
                "label_fr": "Springvale"
            },
            {
                "value": 20097,
                "label": "Yankalilla",
                "label_ar": "يانكاليلا",
                "label_fr": "Yankalilla"
            },
            {
                "value": 20366,
                "label": "Woodridge",
                "label_ar": "وودريدج",
                "label_fr": "Woodridge"
            },
            {
                "value": 20818,
                "label": "Oman-Ama",
                "label_ar": "عمان أما",
                "label_fr": "Oman-Ama"
            },
            {
                "value": 19993,
                "label": "Reservoir",
                "label_ar": "خزان",
                "label_fr": "Réservoir"
            },
            {
                "value": 20881,
                "label": "Nana Glen",
                "label_ar": "نانا جلين",
                "label_fr": "Nana Glen"
            },
            {
                "value": 21835,
                "label": "Douglas",
                "label_ar": "دوغلاس",
                "label_fr": "Douglas"
            },
            {
                "value": 20374,
                "label": "Nerang",
                "label_ar": "نيرانج",
                "label_fr": "Nerang"
            },
            {
                "value": 20682,
                "label": "Point Clare",
                "label_ar": "نقطة كلير",
                "label_fr": "Point Clare"
            },
            {
                "value": 21939,
                "label": "Tallangatta",
                "label_ar": "تالانجاتا",
                "label_fr": "Tallangatta"
            },
            {
                "value": 20757,
                "label": "Mulgoa",
                "label_ar": "مولجوا",
                "label_fr": "Mulgoa"
            },
            {
                "value": 20759,
                "label": "Carlsruhe",
                "label_ar": "كارلسروه",
                "label_fr": "Carlsruhe"
            },
            {
                "value": 20530,
                "label": "Dural",
                "label_ar": "دورال",
                "label_fr": "Dural"
            },
            {
                "value": 20490,
                "label": "Booker Bay",
                "label_ar": "خليج بوكر",
                "label_fr": "Booker Bay"
            },
            {
                "value": 20521,
                "label": "Thornton",
                "label_ar": "ثورنتون",
                "label_fr": "Thornton"
            },
            {
                "value": 20276,
                "label": "Yarraville",
                "label_ar": "يارافيل",
                "label_fr": "Yarraville"
            },
            {
                "value": 21857,
                "label": "Glenorchy",
                "label_ar": "جلينورتشي",
                "label_fr": "Glenorchy"
            },
            {
                "value": 20202,
                "label": "Mascot",
                "label_ar": "تعويذة",
                "label_fr": "Mascotte"
            },
            {
                "value": 20371,
                "label": "Mooloolaba",
                "label_ar": "مولولابا",
                "label_fr": "Mooloolaba"
            },
            {
                "value": 21419,
                "label": "Colo Vale",
                "label_ar": "كولو فالي",
                "label_fr": "Colo Vale"
            },
            {
                "value": 21138,
                "label": "Glenvale",
                "label_ar": "جلينفيل",
                "label_fr": "Glenvale"
            },
            {
                "value": 19971,
                "label": "Brisbane",
                "label_ar": "بريسبان",
                "label_fr": "Brisbane"
            },
            {
                "value": 21971,
                "label": "Calingiri",
                "label_ar": "كالينجيري",
                "label_fr": "Calingiri"
            },
            {
                "value": 20027,
                "label": "Petrie",
                "label_ar": "بيتري",
                "label_fr": "Petrie"
            },
            {
                "value": 21820,
                "label": "Traralgon South",
                "label_ar": "ترارالجون جنوب",
                "label_fr": "Traralgon Sud"
            },
            {
                "value": 21614,
                "label": "Nambucca Heads",
                "label_ar": "رؤساء نامبوكا",
                "label_fr": "Têtes de Nambucca"
            },
            {
                "value": 21070,
                "label": "Alpine",
                "label_ar": "جبال الألب",
                "label_fr": "Alpin"
            },
            {
                "value": 21969,
                "label": "Coleraine",
                "label_ar": "كوليرين",
                "label_fr": "Coleraine"
            },
            {
                "value": 20179,
                "label": "Glenbrook",
                "label_ar": "جلينبروك",
                "label_fr": "Glenbrook"
            },
            {
                "value": 20924,
                "label": "Wyong",
                "label_ar": "ويونغ",
                "label_fr": "Wyong"
            },
            {
                "value": 20395,
                "label": "Nairne",
                "label_ar": "نايرن",
                "label_fr": "Nairne"
            },
            {
                "value": 20393,
                "label": "Gunnedah",
                "label_ar": "غونيداه",
                "label_fr": "Gunnedah"
            },
            {
                "value": 21766,
                "label": "Gheerulla",
                "label_ar": "جير الله",
                "label_fr": "Gheerulla"
            },
            {
                "value": 21994,
                "label": "Hoddles Creek",
                "label_ar": "هودلز كريك",
                "label_fr": "Ruisseau Hoddles"
            },
            {
                "value": 21164,
                "label": "Hawksburn",
                "label_ar": "هوكسبيرن",
                "label_fr": "Hawksburn"
            },
            {
                "value": 21522,
                "label": "Oakhurst",
                "label_ar": "أوخورست",
                "label_fr": "Oakhurst"
            },
            {
                "value": 21865,
                "label": "Rudall",
                "label_ar": "رودال",
                "label_fr": "Rudall"
            },
            {
                "value": 21320,
                "label": "Bobin",
                "label_ar": "بوبين",
                "label_fr": "Bobin"
            },
            {
                "value": 21314,
                "label": "Condobolin",
                "label_ar": "كوندوبولين",
                "label_fr": "Condobolin"
            },
            {
                "value": 21072,
                "label": "Dolans Bay",
                "label_ar": "خليج دولانس",
                "label_fr": "Baie de Dolans"
            },
            {
                "value": 20201,
                "label": "Maroubra",
                "label_ar": "ماروبرا",
                "label_fr": "Maroubra"
            },
            {
                "value": 19951,
                "label": "City of Parramatta",
                "label_ar": "مدينة باراماتا",
                "label_fr": "Ville de Parramatta"
            },
            {
                "value": 21519,
                "label": "Barellan",
                "label_ar": "باريلان",
                "label_fr": "Barellan"
            },
            {
                "value": 20997,
                "label": "Hamilton",
                "label_ar": "هاملتون",
                "label_fr": "Hamilton"
            },
            {
                "value": 20233,
                "label": "Berowra",
                "label_ar": "بيرورا",
                "label_fr": "Berowra"
            },
            {
                "value": 19995,
                "label": "Broadmeadows",
                "label_ar": "برودميدوز",
                "label_fr": "Broadmeadows"
            },
            {
                "value": 20264,
                "label": "Coburg",
                "label_ar": "كوبورغ",
                "label_fr": "Cobourg"
            },
            {
                "value": 21497,
                "label": "Port Douglas",
                "label_ar": "بورت دوجلاس",
                "label_fr": "Port Douglas"
            },
            {
                "value": 21129,
                "label": "Saint Georges Basin",
                "label_ar": "حوض القديس جاورجيوس",
                "label_fr": "Bassin de Saint Georges"
            },
            {
                "value": 20848,
                "label": "Newstead",
                "label_ar": "نيوستيد",
                "label_fr": "Newstead"
            },
            {
                "value": 21290,
                "label": "Windellama",
                "label_ar": "وينديلاما",
                "label_fr": "Windellama"
            },
            {
                "value": 20493,
                "label": "Towradgi",
                "label_ar": "تورادجي",
                "label_fr": "Towradgi"
            },
            {
                "value": 20080,
                "label": "Rockingham",
                "label_ar": "روكينجهام",
                "label_fr": "Rockingham"
            },
            {
                "value": 20089,
                "label": "Port Noarlunga",
                "label_ar": "ميناء نوارلونجا",
                "label_fr": "Port Noarlunga"
            },
            {
                "value": 21569,
                "label": "Balnarring",
                "label_ar": "Balnarring",
                "label_fr": "Balnarring"
            },
            {
                "value": 21373,
                "label": "Holroyd",
                "label_ar": "هولرويد",
                "label_fr": "Holroyd"
            },
            {
                "value": 20567,
                "label": "Mile End",
                "label_ar": "مايل إند",
                "label_fr": "Mile End"
            },
            {
                "value": 20299,
                "label": "Endeavour Hills",
                "label_ar": "تلال إنديفور",
                "label_fr": "Endeavour Hills"
            },
            {
                "value": 20852,
                "label": "Latrobe",
                "label_ar": "لاتروب",
                "label_fr": "Latrobe"
            },
            {
                "value": 21035,
                "label": "Axedale",
                "label_ar": "أكسيديل",
                "label_fr": "Axedale"
            },
            {
                "value": 21424,
                "label": "Glenreagh",
                "label_ar": "جلينريغ",
                "label_fr": "Glenreagh"
            },
            {
                "value": 20876,
                "label": "Reynella",
                "label_ar": "رينيلا",
                "label_fr": "Reynella"
            },
            {
                "value": 20962,
                "label": "Barwon Heads",
                "label_ar": "رؤساء بارون",
                "label_fr": "Têtes de Barwon"
            },
            {
                "value": 21275,
                "label": "Lewiston",
                "label_ar": "لويستون",
                "label_fr": "Lewiston"
            },
            {
                "value": 20422,
                "label": "Albury",
                "label_ar": "البوري",
                "label_fr": "Albury"
            },
            {
                "value": 21297,
                "label": "Caltowie",
                "label_ar": "كالتوي",
                "label_fr": "Caltowie"
            },
            {
                "value": 20654,
                "label": "Moss Vale",
                "label_ar": "موس فالي",
                "label_fr": "Moss Vale"
            },
            {
                "value": 21764,
                "label": "Gordonvale",
                "label_ar": "جوردونفيل",
                "label_fr": "Gordonvale"
            },
            {
                "value": 21678,
                "label": "Glenore Grove",
                "label_ar": "جلينور جروف",
                "label_fr": "Glenore Grove"
            },
            {
                "value": 20000,
                "label": "Adelaide",
                "label_ar": "اديلايد",
                "label_fr": "Adélaïde"
            },
            {
                "value": 20405,
                "label": "Collaroy",
                "label_ar": "الياقة",
                "label_fr": "Collier"
            },
            {
                "value": 20344,
                "label": "Craigieburn",
                "label_ar": "كرايجيبورن",
                "label_fr": "Craigieburn"
            },
            {
                "value": 20014,
                "label": "Kirwan",
                "label_ar": "كيروان",
                "label_fr": "Kirwan"
            },
            {
                "value": 21962,
                "label": "Aberdeen",
                "label_ar": "أبردين",
                "label_fr": "Aberdeen"
            },
            {
                "value": 21868,
                "label": "Echunga",
                "label_ar": "Echunga",
                "label_fr": "Echunga"
            },
            {
                "value": 21905,
                "label": "Drouin West",
                "label_ar": "دروين ويست",
                "label_fr": "Drouin Ouest"
            },
            {
                "value": 21102,
                "label": "Carrick",
                "label_ar": "كاريك",
                "label_fr": "Carrick"
            },
            {
                "value": 21845,
                "label": "Forrest",
                "label_ar": "فورست",
                "label_fr": "Forrest"
            },
            {
                "value": 20231,
                "label": "Newport",
                "label_ar": "نيوبورت",
                "label_fr": "Newport"
            },
            {
                "value": 20601,
                "label": "Williamstown",
                "label_ar": "وليامزتاون",
                "label_fr": "Williamstown"
            },
            {
                "value": 21100,
                "label": "Selbourne",
                "label_ar": "سيلبورن",
                "label_fr": "Selbourne"
            },
            {
                "value": 20622,
                "label": "Beaudesert",
                "label_ar": "بيوديسرت",
                "label_fr": "Beaudesert"
            },
            {
                "value": 21758,
                "label": "Maffra",
                "label_ar": "مافرا",
                "label_fr": "Maffra"
            },
            {
                "value": 20053,
                "label": "Coolum Beach",
                "label_ar": "شاطئ كولوم",
                "label_fr": "Plage Coolum"
            },
            {
                "value": 20597,
                "label": "Bacchus Marsh",
                "label_ar": "باخوس مارش",
                "label_fr": "Marais de Bacchus"
            },
            {
                "value": 20452,
                "label": "Caroline Springs",
                "label_ar": "كارولين سبرينغز",
                "label_fr": "Caroline Springs"
            },
            {
                "value": 20973,
                "label": "Beechworth",
                "label_ar": "بيتشورث",
                "label_fr": "Beechworth"
            },
            {
                "value": 21390,
                "label": "Wattle Grove",
                "label_ar": "واتل جروف",
                "label_fr": "Wattle Grove"
            },
            {
                "value": 21804,
                "label": "Drummond Cove",
                "label_ar": "دروموند كوف",
                "label_fr": "Drummond Cove"
            },
            {
                "value": 20675,
                "label": "Brandon",
                "label_ar": "براندون",
                "label_fr": "Brandon"
            },
            {
                "value": 20784,
                "label": "Palm Cove",
                "label_ar": "بالم كوف",
                "label_fr": "Palm Cove"
            },
            {
                "value": 20720,
                "label": "Australind",
                "label_ar": "أستراليند",
                "label_fr": "Australind"
            },
            {
                "value": 21929,
                "label": "Nannup",
                "label_ar": "نانوب",
                "label_fr": "Nannup"
            },
            {
                "value": 21186,
                "label": "Warren Shire",
                "label_ar": "وارن شاير",
                "label_fr": "Warren Shire"
            },
            {
                "value": 20991,
                "label": "East Fremantle",
                "label_ar": "شرق فريمانتل",
                "label_fr": "Fremantle Est"
            },
            {
                "value": 21633,
                "label": "Blackmans Bay",
                "label_ar": "خليج بلاكمانز",
                "label_fr": "Blackmans Bay"
            },
            {
                "value": 21038,
                "label": "Yackandandah",
                "label_ar": "ياكانداندا",
                "label_fr": "Yackandandah"
            },
            {
                "value": 21417,
                "label": "Taroona",
                "label_ar": "تارونا",
                "label_fr": "Taroona"
            },
            {
                "value": 21131,
                "label": "Phillip",
                "label_ar": "فيليب",
                "label_fr": "Phillip"
            },
            {
                "value": 21124,
                "label": "Bungendore",
                "label_ar": "بنجندور",
                "label_fr": "Bungendore"
            },
            {
                "value": 20907,
                "label": "Daisy Hill",
                "label_ar": "ديزي هيل",
                "label_fr": "Daisy Hill"
            },
            {
                "value": 21033,
                "label": "Lancefield",
                "label_ar": "لانسفيلد",
                "label_fr": "Lancefield"
            },
            {
                "value": 20592,
                "label": "Lyndhurst",
                "label_ar": "ليندهيرست",
                "label_fr": "Lyndhurst"
            },
            {
                "value": 21517,
                "label": "Summertown",
                "label_ar": "الصيف",
                "label_fr": "Summertown"
            },
            {
                "value": 20186,
                "label": "Gosford",
                "label_ar": "جوسفورد",
                "label_fr": "Gosford"
            },
            {
                "value": 21037,
                "label": "Burradoo",
                "label_ar": "بورادو",
                "label_fr": "Burradoo"
            },
            {
                "value": 20806,
                "label": "Bulla",
                "label_ar": "بولا",
                "label_fr": "Bulla"
            },
            {
                "value": 20335,
                "label": "Croydon",
                "label_ar": "كرويدون",
                "label_fr": "Croydon"
            },
            {
                "value": 20263,
                "label": "Waratah",
                "label_ar": "واراته",
                "label_fr": "Waratah"
            },
            {
                "value": 21349,
                "label": "Scottsdale",
                "label_ar": "سكوتسديل",
                "label_fr": "Scottsdale"
            },
            {
                "value": 21015,
                "label": "Enoggera",
                "label_ar": "إنوجيرا",
                "label_fr": "Enoggera"
            },
            {
                "value": 20430,
                "label": "Geelong West",
                "label_ar": "جيلونج ويست",
                "label_fr": "Geelong West"
            },
            {
                "value": 20387,
                "label": "Saint Lucia",
                "label_ar": "القديسة لوسيا",
                "label_fr": "Sainte-Lucie"
            },
            {
                "value": 21881,
                "label": "Anglesea",
                "label_ar": "أنجلسي",
                "label_fr": "Anglesea"
            },
            {
                "value": 21543,
                "label": "Crystal Creek",
                "label_ar": "كريستال كريك",
                "label_fr": "Crystal Creek"
            },
            {
                "value": 20579,
                "label": "Greenvale",
                "label_ar": "جرينفيل",
                "label_fr": "Greenvale"
            },
            {
                "value": 20941,
                "label": "Adelong",
                "label_ar": "ادلونج",
                "label_fr": "Adelong"
            },
            {
                "value": 21815,
                "label": "Kununurra",
                "label_ar": "كونونورا",
                "label_fr": "Kununurra"
            },
            {
                "value": 20543,
                "label": "Bahrs Scrub",
                "label_ar": "فرك البحر",
                "label_fr": "Gommage Bahrs"
            },
            {
                "value": 20905,
                "label": "Crows Nest",
                "label_ar": "عش الغراب",
                "label_fr": "Nid de Corbeau"
            },
            {
                "value": 20260,
                "label": "Hamilton",
                "label_ar": "هاملتون",
                "label_fr": "Hamilton"
            },
            {
                "value": 21752,
                "label": "Yinnar",
                "label_ar": "ينار",
                "label_fr": "Yinnar"
            },
            {
                "value": 21554,
                "label": "Wonthaggi",
                "label_ar": "ونثجي",
                "label_fr": "Wonthaggi"
            },
            {
                "value": 20621,
                "label": "Bli Bli",
                "label_ar": "بلي بلي",
                "label_fr": "Bli Bli"
            },
            {
                "value": 20032,
                "label": "Cairns",
                "label_ar": "كيرنز",
                "label_fr": "Cairns"
            },
            {
                "value": 21248,
                "label": "Portland",
                "label_ar": "بورتلاند",
                "label_fr": "Portland"
            },
            {
                "value": 20230,
                "label": "Rockdale",
                "label_ar": "روكديل",
                "label_fr": "Rockdale"
            },
            {
                "value": 20144,
                "label": "Woodville",
                "label_ar": "وودفيل",
                "label_fr": "Woodville"
            },
            {
                "value": 21161,
                "label": "Leeton",
                "label_ar": "ليتون",
                "label_fr": "Leeton"
            },
            {
                "value": 20281,
                "label": "St Kilda",
                "label_ar": "سانت كيلدا",
                "label_fr": "St kilda"
            },
            {
                "value": 20497,
                "label": "Middleton",
                "label_ar": "ميدلتون",
                "label_fr": "Middleton"
            },
            {
                "value": 20946,
                "label": "Roma",
                "label_ar": "روما",
                "label_fr": "Roma"
            },
            {
                "value": 21140,
                "label": "Edith",
                "label_ar": "إديث",
                "label_fr": "Edith"
            },
            {
                "value": 20875,
                "label": "Hamilton",
                "label_ar": "هاملتون",
                "label_fr": "Hamilton"
            },
            {
                "value": 20095,
                "label": "Semaphore",
                "label_ar": "سيمافور",
                "label_fr": "Sémaphore"
            },
            {
                "value": 20357,
                "label": "Bayswater",
                "label_ar": "بايزووتر",
                "label_fr": "Bayswater"
            },
            {
                "value": 20680,
                "label": "Wyee",
                "label_ar": "ويي",
                "label_fr": "Wyee"
            },
            {
                "value": 21305,
                "label": "Narrabri",
                "label_ar": "نارابري",
                "label_fr": "Narrabri"
            },
            {
                "value": 20713,
                "label": "Coleambally",
                "label_ar": "كوليمبالي",
                "label_fr": "Coleambally"
            },
            {
                "value": 21104,
                "label": "Emu Vale",
                "label_ar": "ايمو فالي",
                "label_fr": "Emu Vale"
            },
            {
                "value": 21909,
                "label": "Mallanganee",
                "label_ar": "مالانجاني",
                "label_fr": "Mallanganee"
            },
            {
                "value": 21165,
                "label": "Nuriootpa",
                "label_ar": "نوريوتبا",
                "label_fr": "Nuriootpa"
            },
            {
                "value": 20575,
                "label": "Cowes",
                "label_ar": "كاوز",
                "label_fr": "Cowes"
            },
            {
                "value": 21989,
                "label": "Balmoral",
                "label_ar": "بالمورال",
                "label_fr": "Balmoral"
            },
            {
                "value": 21057,
                "label": "Glenhaven",
                "label_ar": "جلينهافن",
                "label_fr": "Glenhaven"
            },
            {
                "value": 20964,
                "label": "Creswick",
                "label_ar": "كريسويك",
                "label_fr": "Creswick"
            },
            {
                "value": 20966,
                "label": "Moonah",
                "label_ar": "موناه",
                "label_fr": "Moonah"
            },
            {
                "value": 21092,
                "label": "Maslin Beach",
                "label_ar": "شاطئ ماسلين",
                "label_fr": "Plage de Maslin"
            },
            {
                "value": 20878,
                "label": "Seacliff",
                "label_ar": "جرف البحر",
                "label_fr": "Seacliff"
            },
            {
                "value": 21873,
                "label": "Langley",
                "label_ar": "لانجلي",
                "label_fr": "Langley"
            },
            {
                "value": 20149,
                "label": "Hobart",
                "label_ar": "هوبارت",
                "label_fr": "Hobart"
            },
            {
                "value": 21754,
                "label": "Koornalla",
                "label_ar": "كورنالا",
                "label_fr": "Koornalla"
            },
            {
                "value": 21745,
                "label": "Limestone",
                "label_ar": "حجر الكلس",
                "label_fr": "Calcaire"
            },
            {
                "value": 20259,
                "label": "Lambton",
                "label_ar": "لامبتون",
                "label_fr": "Lambton"
            },
            {
                "value": 21956,
                "label": "Glen Innes",
                "label_ar": "جلين اينيس",
                "label_fr": "Glen Innes"
            },
            {
                "value": 20703,
                "label": "Murwillumbah",
                "label_ar": "Murwillumbah",
                "label_fr": "Murwillumbah"
            },
            {
                "value": 21167,
                "label": "Rylstone",
                "label_ar": "ريلستون",
                "label_fr": "Rylstone"
            },
            {
                "value": 20983,
                "label": "Norton Summit",
                "label_ar": "قمة نورتون",
                "label_fr": "Sommet Norton"
            },
            {
                "value": 20515,
                "label": "Maroochydore",
                "label_ar": "ماروتشيدور",
                "label_fr": "Maroochydore"
            },
            {
                "value": 20704,
                "label": "Kingston",
                "label_ar": "كينغستون",
                "label_fr": "Kingston"
            },
            {
                "value": 20789,
                "label": "Warrane",
                "label_ar": "وارين",
                "label_fr": "Warrane"
            },
            {
                "value": 21954,
                "label": "Woodstock",
                "label_ar": "وودستوك",
                "label_fr": "Woodstock"
            },
            {
                "value": 21860,
                "label": "Pinery",
                "label_ar": "دفيئة الأناناس",
                "label_fr": "Pinède"
            },
            {
                "value": 21505,
                "label": "Rosewood",
                "label_ar": "روزوود",
                "label_fr": "bois de rose"
            },
            {
                "value": 20940,
                "label": "St Helens",
                "label_ar": "سانت هيلينز",
                "label_fr": "St Helens"
            },
            {
                "value": 21313,
                "label": "Robertstown",
                "label_ar": "روبرتستاون",
                "label_fr": "Robertstown"
            },
            {
                "value": 21112,
                "label": "Bourke",
                "label_ar": "بورك",
                "label_fr": "Bourke"
            },
            {
                "value": 21737,
                "label": "Moruya",
                "label_ar": "مورويا",
                "label_fr": "Moruya"
            },
            {
                "value": 21641,
                "label": "Bald Hills",
                "label_ar": "تلال أصلع",
                "label_fr": "Collines chauves"
            },
            {
                "value": 21097,
                "label": "Swansea",
                "label_ar": "سوانزي",
                "label_fr": "Swansea"
            },
            {
                "value": 21823,
                "label": "Wickham",
                "label_ar": "ويكهام",
                "label_fr": "Wickham"
            },
            {
                "value": 21940,
                "label": "Pinkenba",
                "label_ar": "بينكينبا",
                "label_fr": "Pinkenba"
            },
            {
                "value": 21518,
                "label": "Gosforth",
                "label_ar": "جوسفورث",
                "label_fr": "Gosforth"
            },
            {
                "value": 21694,
                "label": "Mount Ousley",
                "label_ar": "جبل أوسلي",
                "label_fr": "Mont Ousley"
            },
            {
                "value": 20160,
                "label": "Dandenong",
                "label_ar": "داندينونج",
                "label_fr": "Dandenong"
            },
            {
                "value": 20841,
                "label": "Baxter",
                "label_ar": "باكستر",
                "label_fr": "Baxter"
            },
            {
                "value": 20600,
                "label": "Warburton",
                "label_ar": "واربيرتون",
                "label_fr": "Warburton"
            },
            {
                "value": 21938,
                "label": "Hopetoun",
                "label_ar": "هوبتون",
                "label_fr": "Hopetoun"
            },
            {
                "value": 21965,
                "label": "Dumbleyung",
                "label_ar": "دمبليونغ",
                "label_fr": "Dumbleyung"
            },
            {
                "value": 21360,
                "label": "Alberton",
                "label_ar": "ألبرتون",
                "label_fr": "Alberton"
            },
            {
                "value": 21472,
                "label": "Weston",
                "label_ar": "ويستون",
                "label_fr": "Weston"
            },
            {
                "value": 20125,
                "label": "Caboolture",
                "label_ar": "كابولتشر",
                "label_fr": "Caboolture"
            },
            {
                "value": 21802,
                "label": "Karnup",
                "label_ar": "كارنوب",
                "label_fr": "Karnup"
            },
            {
                "value": 21794,
                "label": "Coromandel Valley",
                "label_ar": "وادي كورومانديل",
                "label_fr": "Vallée de Coromandel"
            },
            {
                "value": 20890,
                "label": "Port Macquarie",
                "label_ar": "بورت ماكواري",
                "label_fr": "Port Macquarie"
            },
            {
                "value": 20228,
                "label": "Chippendale",
                "label_ar": "شيبينديل",
                "label_fr": "Chippendale"
            },
            {
                "value": 21078,
                "label": "Green Point",
                "label_ar": "النقطة الخضراء",
                "label_fr": "Point vert"
            },
            {
                "value": 21125,
                "label": "Braidwood",
                "label_ar": "برايدوود",
                "label_fr": "Braidwood"
            },
            {
                "value": 20637,
                "label": "Goolwa",
                "label_ar": "جولوا",
                "label_fr": "Goolwa"
            },
            {
                "value": 20762,
                "label": "Springvale South",
                "label_ar": "سبرينجفيل ساوث",
                "label_fr": "Springvale Sud"
            },
            {
                "value": 20209,
                "label": "Redfern",
                "label_ar": "ريدفيرن",
                "label_fr": "Redfern"
            },
            {
                "value": 20244,
                "label": "Spring Hill",
                "label_ar": "تل الربيع",
                "label_fr": "Spring Hill"
            },
            {
                "value": 20971,
                "label": "Stanthorpe",
                "label_ar": "ستانثورب",
                "label_fr": "Stanthorpe"
            },
            {
                "value": 21080,
                "label": "Darling Point",
                "label_ar": "دارلينج بوينت",
                "label_fr": "Darling Point"
            },
            {
                "value": 20636,
                "label": "Kilsyth",
                "label_ar": "كيلسيث",
                "label_fr": "Kilsyth"
            },
            {
                "value": 20150,
                "label": "Sandy Bay",
                "label_ar": "ساندي باي",
                "label_fr": "Sandy Bay"
            },
            {
                "value": 20880,
                "label": "Caulfield",
                "label_ar": "كولفيلد",
                "label_fr": "Caulfield"
            },
            {
                "value": 21134,
                "label": "Gordon",
                "label_ar": "جوردون",
                "label_fr": "Gordon"
            },
            {
                "value": 21047,
                "label": "Norah",
                "label_ar": "نورا",
                "label_fr": "Norah"
            },
            {
                "value": 20445,
                "label": "Mortdale",
                "label_ar": "مورتديل",
                "label_fr": "Mortdale"
            },
            {
                "value": 19996,
                "label": "Ivanhoe",
                "label_ar": "إيفانهو",
                "label_fr": "Ivanhoé"
            },
            {
                "value": 21387,
                "label": "Silverwater",
                "label_ar": "سيلفر ووتر",
                "label_fr": "Silverwater"
            },
            {
                "value": 20052,
                "label": "Summerholm",
                "label_ar": "سمرهولم",
                "label_fr": "Summerholm"
            },
            {
                "value": 21769,
                "label": "Blackbutt",
                "label_ar": "بلاكبوت",
                "label_fr": "Blackbutt"
            },
            {
                "value": 20541,
                "label": "Emu Plains",
                "label_ar": "إيمو بلينز",
                "label_fr": "Emu Plains"
            },
            {
                "value": 21634,
                "label": "Bagdad",
                "label_ar": "بغداد",
                "label_fr": "Bagdad"
            },
            {
                "value": 20148,
                "label": "Noarlunga",
                "label_ar": "Noarlunga",
                "label_fr": "Noarlunga"
            },
            {
                "value": 20025,
                "label": "Upper Brookfield",
                "label_ar": "أبر بروكفيلد",
                "label_fr": "Upper Brookfield"
            },
            {
                "value": 21855,
                "label": "Hendon",
                "label_ar": "هندون",
                "label_fr": "Hendon"
            },
            {
                "value": 20520,
                "label": "Berkeley",
                "label_ar": "بيركلي",
                "label_fr": "Berkeley"
            },
            {
                "value": 20158,
                "label": "Essendon",
                "label_ar": "اسيندون",
                "label_fr": "Essendon"
            },
            {
                "value": 20555,
                "label": "Waverley",
                "label_ar": "ويفرلي",
                "label_fr": "Waverley"
            },
            {
                "value": 21668,
                "label": "Yanchep",
                "label_ar": "يانشيب",
                "label_fr": "Yanchep"
            },
            {
                "value": 20215,
                "label": "Burwood",
                "label_ar": "بيروود",
                "label_fr": "Burwood"
            },
            {
                "value": 21143,
                "label": "Hurstville Grove",
                "label_ar": "هورستفيل جروف",
                "label_fr": "Hurstville Grove"
            },
            {
                "value": 21089,
                "label": "Spring Field",
                "label_ar": "حقل الربيع",
                "label_fr": "Champ de printemps"
            },
            {
                "value": 21778,
                "label": "Nar Nar Goon",
                "label_ar": "نار نار جوون",
                "label_fr": "Nar Nar Goon"
            },
            {
                "value": 21993,
                "label": "Hammond",
                "label_ar": "هاموند",
                "label_fr": "Hammond"
            },
            {
                "value": 20066,
                "label": "Bunbury",
                "label_ar": "بنبري",
                "label_fr": "Bunbury"
            },
            {
                "value": 21240,
                "label": "Daceyville",
                "label_ar": "داسيفيل",
                "label_fr": "Daceyville"
            },
            {
                "value": 21743,
                "label": "Myrrhee",
                "label_ar": "المر",
                "label_fr": "Myrrhee"
            },
            {
                "value": 21203,
                "label": "Nords Wharf",
                "label_ar": "نوردز وارف",
                "label_fr": "Nords Wharf"
            },
            {
                "value": 21516,
                "label": "Waterloo Corner",
                "label_ar": "ركن واترلو",
                "label_fr": "Coin de Waterloo"
            },
            {
                "value": 20227,
                "label": "Rose Bay",
                "label_ar": "روز باي",
                "label_fr": "Rose Bay"
            },
            {
                "value": 20178,
                "label": "Carlingford",
                "label_ar": "كارلينجفورد",
                "label_fr": "Carlingford"
            },
            {
                "value": 20034,
                "label": "Kobble",
                "label_ar": "كوبل",
                "label_fr": "Kobble"
            },
            {
                "value": 21252,
                "label": "Budgewoi",
                "label_ar": "بودجوي",
                "label_fr": "Budgewoi"
            },
            {
                "value": 21401,
                "label": "Maryborough",
                "label_ar": "ماريبوروه",
                "label_fr": "Maryborough"
            },
            {
                "value": 20846,
                "label": "Burnie",
                "label_ar": "بورني",
                "label_fr": "Burnie"
            },
            {
                "value": 21489,
                "label": "Crib Point",
                "label_ar": "كريب بوينت",
                "label_fr": "Point de crèche"
            },
            {
                "value": 21333,
                "label": "Jindivick",
                "label_ar": "جينديفيك",
                "label_fr": "Jindivick"
            },
            {
                "value": 21418,
                "label": "Warrawong",
                "label_ar": "واراونغ",
                "label_fr": "Warrawong"
            },
            {
                "value": 21439,
                "label": "Edithvale",
                "label_ar": "إديثفال",
                "label_fr": "Edithvale"
            },
            {
                "value": 21639,
                "label": "Mount Morgan",
                "label_ar": "جبل مورغان",
                "label_fr": "Mont Morgan"
            },
            {
                "value": 20870,
                "label": "Tarragindi",
                "label_ar": "تاراغيندي",
                "label_fr": "Tarragindi"
            },
            {
                "value": 21151,
                "label": "Sheffield",
                "label_ar": "شيفيلد",
                "label_fr": "Sheffield"
            },
            {
                "value": 21020,
                "label": "Drysdale",
                "label_ar": "درايسدال",
                "label_fr": "Drysdale"
            },
            {
                "value": 20615,
                "label": "Port Lincoln",
                "label_ar": "ميناء لينكولن",
                "label_fr": "Port Lincoln"
            },
            {
                "value": 20538,
                "label": "Wyongah",
                "label_ar": "ويونغاه",
                "label_fr": "Wyongah"
            },
            {
                "value": 21675,
                "label": "Barwon Downs",
                "label_ar": "بارون داونز",
                "label_fr": "Barwon Downs"
            },
            {
                "value": 20018,
                "label": "Toowoomba",
                "label_ar": "توومبا",
                "label_fr": "Toowoomba"
            },
            {
                "value": 20236,
                "label": "Fairfield",
                "label_ar": "فيرفيلد",
                "label_fr": "Fairfield"
            },
            {
                "value": 20118,
                "label": "Concord",
                "label_ar": "كونكورد",
                "label_fr": "Concorde"
            },
            {
                "value": 20360,
                "label": "Donvale",
                "label_ar": "دونفال",
                "label_fr": "Donvale"
            },
            {
                "value": 21687,
                "label": "Angaston",
                "label_ar": "انجاستون",
                "label_fr": "Angaston"
            },
            {
                "value": 21188,
                "label": "Kyabram",
                "label_ar": "كيابرام",
                "label_fr": "Kyabram"
            },
            {
                "value": 20724,
                "label": "Alton Downs",
                "label_ar": "ألتون داونز",
                "label_fr": "Alton Downs"
            },
            {
                "value": 20068,
                "label": "Karratha",
                "label_ar": "كراثا",
                "label_fr": "Karratha"
            },
            {
                "value": 21174,
                "label": "Whittlesea",
                "label_ar": "Whittlesea",
                "label_fr": "Whittlesea"
            },
            {
                "value": 20632,
                "label": "Loch",
                "label_ar": "بحيرة لوخ",
                "label_fr": "Loch"
            },
            {
                "value": 21130,
                "label": "Grant",
                "label_ar": "منحة",
                "label_fr": "Subvention"
            },
            {
                "value": 20041,
                "label": "Loganlea",
                "label_ar": "لوجانليا",
                "label_fr": "Loganlea"
            },
            {
                "value": 20010,
                "label": "Tarneit",
                "label_ar": "تارنيت",
                "label_fr": "Tarneit"
            },
            {
                "value": 20196,
                "label": "Penshurst",
                "label_ar": "بنشرست",
                "label_fr": "Penshurst"
            },
            {
                "value": 20641,
                "label": "Dingley",
                "label_ar": "دينجلي",
                "label_fr": "Dingley"
            },
            {
                "value": 20959,
                "label": "Taren Point",
                "label_ar": "تارين بوينت",
                "label_fr": "Taren Point"
            },
            {
                "value": 21521,
                "label": "Windang",
                "label_ar": "ويندانغ",
                "label_fr": "Windang"
            },
            {
                "value": 19959,
                "label": "Isabella",
                "label_ar": "إيزابيلا",
                "label_fr": "Isabelle"
            },
            {
                "value": 20130,
                "label": "Manly",
                "label_ar": "رجولي",
                "label_fr": "Viril"
            },
            {
                "value": 21950,
                "label": "Mossman",
                "label_ar": "موسمان",
                "label_fr": "Mossman"
            },
            {
                "value": 20607,
                "label": "Ramsgate",
                "label_ar": "رامسجيت",
                "label_fr": "Ramsgate"
            },
            {
                "value": 21883,
                "label": "Little River",
                "label_ar": "النهر الصغير",
                "label_fr": "Petite rivière"
            },
            {
                "value": 20267,
                "label": "Cheltenham",
                "label_ar": "شلتنهام",
                "label_fr": "Cheltenham"
            },
            {
                "value": 21670,
                "label": "Serpentine",
                "label_ar": "اعوج",
                "label_fr": "Serpentin"
            },
            {
                "value": 21480,
                "label": "Bolwarra",
                "label_ar": "بولورا",
                "label_fr": "Bolwarra"
            },
            {
                "value": 20835,
                "label": "Torbanlea",
                "label_ar": "توربانليا",
                "label_fr": "Torbanlea"
            },
            {
                "value": 20166,
                "label": "Epping",
                "label_ar": "إبينج",
                "label_fr": "Epping"
            },
            {
                "value": 21425,
                "label": "Franklin",
                "label_ar": "فرانكلين",
                "label_fr": "Franklin"
            },
            {
                "value": 20305,
                "label": "Kensington",
                "label_ar": "كنسينغتون",
                "label_fr": "Kensington"
            },
            {
                "value": 21718,
                "label": "Collinsvale",
                "label_ar": "كولينزفال",
                "label_fr": "Collinsvale"
            },
            {
                "value": 20372,
                "label": "Sunnybank",
                "label_ar": "صني بنك",
                "label_fr": "Sunnybank"
            },
            {
                "value": 21871,
                "label": "Wiluna",
                "label_ar": "ويلونا",
                "label_fr": "Wiluna"
            },
            {
                "value": 21581,
                "label": "Lockhart",
                "label_ar": "لوكهارت",
                "label_fr": "Lockhart"
            },
            {
                "value": 20656,
                "label": "Woodford",
                "label_ar": "وودفورد",
                "label_fr": "Woodford"
            },
            {
                "value": 21256,
                "label": "Cowan",
                "label_ar": "كوان",
                "label_fr": "Cowan"
            },
            {
                "value": 21799,
                "label": "Tawonga",
                "label_ar": "تاونجا",
                "label_fr": "Tawonga"
            },
            {
                "value": 20825,
                "label": "Mareeba",
                "label_ar": "مريبة",
                "label_fr": "Mareeba"
            },
            {
                "value": 21223,
                "label": "Lebrina",
                "label_ar": "ليبرينا",
                "label_fr": "Lebrina"
            },
            {
                "value": 20534,
                "label": "Saint Peters",
                "label_ar": "سانت بيترز",
                "label_fr": "Saint Peters"
            },
            {
                "value": 20006,
                "label": "Port Melbourne",
                "label_ar": "ميناء ملبورن",
                "label_fr": "Port de Melbourne"
            },
            {
                "value": 20099,
                "label": "Mallala",
                "label_ar": "ملالا",
                "label_fr": "Mallala"
            },
            {
                "value": 21404,
                "label": "Sellicks Beach",
                "label_ar": "شاطئ Sellicks",
                "label_fr": "Plage de Sellicks"
            },
            {
                "value": 20466,
                "label": "Cessnock",
                "label_ar": "سيسنوك",
                "label_fr": "Cessnock"
            },
            {
                "value": 20469,
                "label": "Glenelg",
                "label_ar": "جلينيلج",
                "label_fr": "Glenelg"
            },
            {
                "value": 21792,
                "label": "Bridgewater",
                "label_ar": "بريدجووتر",
                "label_fr": "Bridgewater"
            },
            {
                "value": 20420,
                "label": "Bathurst",
                "label_ar": "باثورست",
                "label_fr": "Bathurst"
            },
            {
                "value": 21051,
                "label": "Pimpama",
                "label_ar": "بيمباما",
                "label_fr": "Pimpama"
            },
            {
                "value": 20594,
                "label": "Rowville",
                "label_ar": "روفيل",
                "label_fr": "Rowville"
            },
            {
                "value": 20700,
                "label": "Regents Park",
                "label_ar": "ريجينتس بارك",
                "label_fr": "Regents Park"
            },
            {
                "value": 21494,
                "label": "Donnybrook",
                "label_ar": "دونيبروك",
                "label_fr": "Donnybrook"
            },
            {
                "value": 20486,
                "label": "West Hoxton",
                "label_ar": "ويست هوكستون",
                "label_fr": "West Hoxton"
            },
            {
                "value": 20103,
                "label": "Glen Iris",
                "label_ar": "جلين ايريس",
                "label_fr": "Glen Iris"
            },
            {
                "value": 21267,
                "label": "Queenstown",
                "label_ar": "كوينزتاون",
                "label_fr": "Queenstown"
            },
            {
                "value": 21498,
                "label": "Howard",
                "label_ar": "هوارد",
                "label_fr": "Howard"
            },
            {
                "value": 21681,
                "label": "Kooyong",
                "label_ar": "كويونغ",
                "label_fr": "Kooyong"
            },
            {
                "value": 21546,
                "label": "Bulga",
                "label_ar": "بولجا",
                "label_fr": "Bulga"
            },
            {
                "value": 20378,
                "label": "Greenbank",
                "label_ar": "جرينبانك",
                "label_fr": "Greenbank"
            },
            {
                "value": 21367,
                "label": "Bedfordale",
                "label_ar": "بيدفوردال",
                "label_fr": "Bedfordale"
            },
            {
                "value": 20225,
                "label": "Neutral Bay",
                "label_ar": "خليج محايد",
                "label_fr": "Neutral Bay"
            },
            {
                "value": 20055,
                "label": "Fernvale",
                "label_ar": "فيرنفال",
                "label_fr": "Fernvale"
            },
            {
                "value": 21163,
                "label": "Trafalgar",
                "label_ar": "ترافالغار",
                "label_fr": "Trafalgar"
            },
            {
                "value": 21666,
                "label": "Notting Hill",
                "label_ar": "نوتنج هيل",
                "label_fr": "Notting Hill"
            },
            {
                "value": 20990,
                "label": "Wellard",
                "label_ar": "ويلارد",
                "label_fr": "Wellard"
            },
            {
                "value": 21323,
                "label": "Keith",
                "label_ar": "كيث",
                "label_fr": "Keith"
            },
            {
                "value": 21013,
                "label": "West Pymble",
                "label_ar": "ويست بيمبل",
                "label_fr": "West Pymble"
            },
            {
                "value": 21579,
                "label": "Wooloweyah",
                "label_ar": "ولوية",
                "label_fr": "Wooloweyah"
            },
            {
                "value": 21824,
                "label": "Bundalong",
                "label_ar": "بوندالونج",
                "label_fr": "Bundalong"
            },
            {
                "value": 20807,
                "label": "Sippy Downs",
                "label_ar": "سيبي داونز",
                "label_fr": "Sippy Downs"
            },
            {
                "value": 21411,
                "label": "Beaconsfield Upper",
                "label_ar": "بيكونزفيلد العليا",
                "label_fr": "Beaconsfield Upper"
            },
            {
                "value": 21714,
                "label": "Norwood",
                "label_ar": "نوروود",
                "label_fr": "Norwood"
            },
            {
                "value": 21159,
                "label": "Fyshwick",
                "label_ar": "فيشويك",
                "label_fr": "Fyshwick"
            },
            {
                "value": 21684,
                "label": "Ellangowan",
                "label_ar": "Ellangowan",
                "label_fr": "Ellangowan"
            },
            {
                "value": 20477,
                "label": "Ettalong Beach",
                "label_ar": "شاطئ إتالونج",
                "label_fr": "Plage d'Ettalong"
            },
            {
                "value": 20131,
                "label": "Wollongong",
                "label_ar": "ولونجونج",
                "label_fr": "Wollongong"
            },
            {
                "value": 20100,
                "label": "Hallett",
                "label_ar": "هاليت",
                "label_fr": "Hallett"
            },
            {
                "value": 20153,
                "label": "New Town",
                "label_ar": "بلدة جديدة",
                "label_fr": "Nouvelle ville"
            },
            {
                "value": 21463,
                "label": "Woodford",
                "label_ar": "وودفورد",
                "label_fr": "Woodford"
            },
            {
                "value": 21704,
                "label": "Lovely Banks",
                "label_ar": "البنوك الجميلة",
                "label_fr": "Belles banques"
            },
            {
                "value": 20772,
                "label": "Malvern",
                "label_ar": "مالفيرن",
                "label_fr": "Malvern"
            },
            {
                "value": 21867,
                "label": "Cedar Grove",
                "label_ar": "سيدار جروف",
                "label_fr": "Cedar Grove"
            },
            {
                "value": 21066,
                "label": "Willow Vale",
                "label_ar": "ويلو فال",
                "label_fr": "Willow Vale"
            },
            {
                "value": 20535,
                "label": "Stradbroke",
                "label_ar": "سترادبروك",
                "label_fr": "Stradbroke"
            },
            {
                "value": 21583,
                "label": "Port Sorell",
                "label_ar": "بورت سوريل",
                "label_fr": "Port Sorell"
            },
            {
                "value": 21642,
                "label": "Portsea",
                "label_ar": "بورتسي",
                "label_fr": "Portsea"
            },
            {
                "value": 20050,
                "label": "Buderim",
                "label_ar": "بودريم",
                "label_fr": "Buderim"
            },
            {
                "value": 21400,
                "label": "Toolangi",
                "label_ar": "Toolangi",
                "label_fr": "Toolangi"
            },
            {
                "value": 21882,
                "label": "Carbrook",
                "label_ar": "كاربروك",
                "label_fr": "Carbrook"
            },
            {
                "value": 21009,
                "label": "Bonnet Bay",
                "label_ar": "خليج بونيه",
                "label_fr": "Bonnet Bay"
            },
            {
                "value": 19964,
                "label": "Gold Coast",
                "label_ar": "الساحل الذهبي",
                "label_fr": "Côte d'or"
            },
            {
                "value": 20630,
                "label": "Guildford",
                "label_ar": "جيلدفورد",
                "label_fr": "Guildford"
            },
            {
                "value": 20129,
                "label": "Ashfield",
                "label_ar": "أشفيلد",
                "label_fr": "Ashfield"
            },
            {
                "value": 21470,
                "label": "Bowenfels",
                "label_ar": "بوينفيلز",
                "label_fr": "Bowenfels"
            },
            {
                "value": 20524,
                "label": "Narellan",
                "label_ar": "ناريلان",
                "label_fr": "Narellan"
            },
            {
                "value": 21039,
                "label": "Fullerton",
                "label_ar": "فوليرتون",
                "label_fr": "Fullerton"
            },
            {
                "value": 20123,
                "label": "Middleton",
                "label_ar": "ميدلتون",
                "label_fr": "Middleton"
            },
            {
                "value": 20542,
                "label": "Kangaroo Point",
                "label_ar": "كنغر بوينت",
                "label_fr": "Kangaroo Point"
            },
            {
                "value": 20326,
                "label": "Aspendale",
                "label_ar": "اسبندال",
                "label_fr": "Aspendale"
            },
            {
                "value": 21347,
                "label": "Eumundi",
                "label_ar": "اوموندي",
                "label_fr": "Eumundi"
            },
            {
                "value": 21206,
                "label": "Neerim South",
                "label_ar": "نيريم الجنوبية",
                "label_fr": "Neerim Sud"
            },
            {
                "value": 21985,
                "label": "Sarina Beach",
                "label_ar": "شاطئ سارينا",
                "label_fr": "Plage de Sarina"
            },
            {
                "value": 20912,
                "label": "Yeppoon",
                "label_ar": "يبون",
                "label_fr": "Yeppoon"
            },
            {
                "value": 21797,
                "label": "Lockridge",
                "label_ar": "لوكريدج",
                "label_fr": "Lockridge"
            },
            {
                "value": 21531,
                "label": "Mortlake",
                "label_ar": "مورتليك",
                "label_fr": "Mortlake"
            },
            {
                "value": 20697,
                "label": "Boondall",
                "label_ar": "بوندال",
                "label_fr": "Boondall"
            },
            {
                "value": 21046,
                "label": "Mirrabooka",
                "label_ar": "ميرابوكا",
                "label_fr": "Mirrabooka"
            },
            {
                "value": 21328,
                "label": "Kindred",
                "label_ar": "نوع",
                "label_fr": "Parenté"
            },
            {
                "value": 21427,
                "label": "Ellerslie",
                "label_ar": "إليرسلي",
                "label_fr": "Ellerslie"
            },
            {
                "value": 21407,
                "label": "Lyndoch",
                "label_ar": "ليندوش",
                "label_fr": "Lyndoch"
            },
            {
                "value": 20856,
                "label": "Huonville",
                "label_ar": "هونفيل",
                "label_fr": "Huonville"
            },
            {
                "value": 20868,
                "label": "Bulimba",
                "label_ar": "بوليمبا",
                "label_fr": "Boulimba"
            },
            {
                "value": 21953,
                "label": "Salamander Bay",
                "label_ar": "خليج سالاماندر",
                "label_fr": "Baie de Salamandre"
            },
            {
                "value": 20296,
                "label": "Glen Waverley",
                "label_ar": "جلين ويفرلي",
                "label_fr": "Glen Waverley"
            },
            {
                "value": 20237,
                "label": "Rosebery",
                "label_ar": "روزبيري",
                "label_fr": "Rosebery"
            },
            {
                "value": 19950,
                "label": "East Gosford",
                "label_ar": "شرق جوسفورد",
                "label_fr": "East Gosford"
            },
            {
                "value": 21594,
                "label": "Forrest",
                "label_ar": "فورست",
                "label_fr": "Forrest"
            },
            {
                "value": 21503,
                "label": "Meringandan",
                "label_ar": "مرينجاندان",
                "label_fr": "Meringandan"
            },
            {
                "value": 20855,
                "label": "Ellendale",
                "label_ar": "إليندال",
                "label_fr": "Ellendale"
            },
            {
                "value": 21357,
                "label": "Bairnsdale",
                "label_ar": "بيرنسديل",
                "label_fr": "Bairnsdale"
            },
            {
                "value": 21774,
                "label": "Toora",
                "label_ar": "تورا",
                "label_fr": "Toora"
            },
            {
                "value": 20545,
                "label": "Figtree",
                "label_ar": "شجرة التين",
                "label_fr": "Figuier"
            },
            {
                "value": 21119,
                "label": "Milton",
                "label_ar": "ميلتون",
                "label_fr": "Milton"
            },
            {
                "value": 21255,
                "label": "Grafton",
                "label_ar": "جرافتون",
                "label_fr": "Grafton"
            },
            {
                "value": 21712,
                "label": "Mount Direction",
                "label_ar": "جبل الاتجاه",
                "label_fr": "Direction du montage"
            },
            {
                "value": 21901,
                "label": "Won Wron",
                "label_ar": "وون رون",
                "label_fr": "Won Wron"
            },
            {
                "value": 20834,
                "label": "Tiaro",
                "label_ar": "تيارو",
                "label_fr": "Tiaro"
            },
            {
                "value": 21568,
                "label": "Tongala",
                "label_ar": "تونغالا",
                "label_fr": "Tongala"
            },
            {
                "value": 21366,
                "label": "Keswick",
                "label_ar": "كيسويك",
                "label_fr": "Keswick"
            },
            {
                "value": 21457,
                "label": "Helensburgh",
                "label_ar": "هيلينسبورغ",
                "label_fr": "Helensburgh"
            },
            {
                "value": 21671,
                "label": "Mount Macedon",
                "label_ar": "جبل ماسيدون",
                "label_fr": "Mont Macédoine"
            },
            {
                "value": 20051,
                "label": "Toowong",
                "label_ar": "توونج",
                "label_fr": "Toowong"
            },
            {
                "value": 21074,
                "label": "Hat Head",
                "label_ar": "قبعة الرأس",
                "label_fr": "Tête de chapeau"
            },
            {
                "value": 21856,
                "label": "Ross River",
                "label_ar": "نهر روس",
                "label_fr": "Rivière Ross"
            },
            {
                "value": 20972,
                "label": "Girraween",
                "label_ar": "جيروين",
                "label_fr": "Girraween"
            },
            {
                "value": 21303,
                "label": "Caragabal",
                "label_ar": "كاراجابال",
                "label_fr": "Caragabal"
            },
            {
                "value": 21495,
                "label": "Point Vernon",
                "label_ar": "بوينت فيرنون",
                "label_fr": "Point Vernon"
            },
            {
                "value": 21061,
                "label": "Hawthorne",
                "label_ar": "هوثورن",
                "label_fr": "Hawthorne"
            },
            {
                "value": 20012,
                "label": "Mount Eliza",
                "label_ar": "جبل إليزا",
                "label_fr": "Mont Eliza"
            },
            {
                "value": 21654,
                "label": "Black Rock",
                "label_ar": "حجر أسود",
                "label_fr": "Roche noire"
            },
            {
                "value": 20443,
                "label": "Belmont",
                "label_ar": "بلمونت",
                "label_fr": "Belmont"
            },
            {
                "value": 21473,
                "label": "Arno Bay",
                "label_ar": "أرنو باي",
                "label_fr": "Baie d'Arno"
            },
            {
                "value": 21202,
                "label": "Bowral",
                "label_ar": "بورال",
                "label_fr": "Bowral"
            },
            {
                "value": 20271,
                "label": "Nunawading",
                "label_ar": "نناواد",
                "label_fr": "Nunawading"
            },
            {
                "value": 20474,
                "label": "Riverton",
                "label_ar": "ريفرتون",
                "label_fr": "Riverton"
            },
            {
                "value": 21789,
                "label": "Success",
                "label_ar": "نجاح",
                "label_fr": "Succès"
            },
            {
                "value": 20414,
                "label": "Hope Island",
                "label_ar": "جزيرة الأمل",
                "label_fr": "Île Hope"
            },
            {
                "value": 21128,
                "label": "Gaythorne",
                "label_ar": "غايثورن",
                "label_fr": "Gaythorne"
            },
            {
                "value": 20117,
                "label": "Curl Curl",
                "label_ar": "حليقة الضفيرة",
                "label_fr": "Curl Curl"
            },
            {
                "value": 21655,
                "label": "Blackwarry",
                "label_ar": "بلاكوار",
                "label_fr": "Blackwarry"
            },
            {
                "value": 21502,
                "label": "Innisfail",
                "label_ar": "إينيسيفيل",
                "label_fr": "Innisfail"
            },
            {
                "value": 20364,
                "label": "Coorparoo",
                "label_ar": "كوربارو",
                "label_fr": "Coorparoo"
            },
            {
                "value": 20328,
                "label": "Hallam",
                "label_ar": "هالام",
                "label_fr": "Hallam"
            },
            {
                "value": 20763,
                "label": "Clarinda",
                "label_ar": "كلاريندا",
                "label_fr": "Clarinda"
            },
            {
                "value": 21423,
                "label": "Jensen",
                "label_ar": "جنسن",
                "label_fr": "Jensen"
            },
            {
                "value": 21172,
                "label": "Wonga Park",
                "label_ar": "حديقة ونجا",
                "label_fr": "Parc Wonga"
            },
            {
                "value": 20781,
                "label": "Nedlands",
                "label_ar": "نيدلاندز",
                "label_fr": "Nedlands"
            },
            {
                "value": 20854,
                "label": "Tunnack",
                "label_ar": "توناك",
                "label_fr": "Tunnack"
            },
            {
                "value": 21445,
                "label": "Boddington",
                "label_ar": "بودينجتون",
                "label_fr": "Boddington"
            },
            {
                "value": 20779,
                "label": "Watsonia",
                "label_ar": "واتسونيا",
                "label_fr": "Watsonia"
            },
            {
                "value": 20208,
                "label": "Homebush",
                "label_ar": "هومبوش",
                "label_fr": "Homebush"
            },
            {
                "value": 21958,
                "label": "Enmore",
                "label_ar": "انمور",
                "label_fr": "Enmore"
            },
            {
                "value": 21431,
                "label": "Macarthur",
                "label_ar": "ماكارثر",
                "label_fr": "Macarthur"
            },
            {
                "value": 21049,
                "label": "Ningi",
                "label_ar": "نينجي",
                "label_fr": "Ningi"
            },
            {
                "value": 20956,
                "label": "Nowra",
                "label_ar": "ناورا",
                "label_fr": "Nowra"
            },
            {
                "value": 21919,
                "label": "Grandchester",
                "label_ar": "جراندشستر",
                "label_fr": "Grandchester"
            },
            {
                "value": 20314,
                "label": "Flemington",
                "label_ar": "فليمنجتون",
                "label_fr": "Flemington"
            },
            {
                "value": 20003,
                "label": "Ferntree Gully",
                "label_ar": "فيرنتري جولي",
                "label_fr": "Ferntree Gully"
            },
            {
                "value": 20976,
                "label": "Mannum",
                "label_ar": "مانوم",
                "label_fr": "Mannum"
            },
            {
                "value": 21630,
                "label": "Mandalong",
                "label_ar": "ماندالونج",
                "label_fr": "Mandalong"
            },
            {
                "value": 20205,
                "label": "Newtown",
                "label_ar": "بلدة جديدة",
                "label_fr": "Nouvelle ville"
            },
            {
                "value": 20514,
                "label": "Greendale",
                "label_ar": "جريندال",
                "label_fr": "Greendale"
            },
            {
                "value": 21656,
                "label": "Ross",
                "label_ar": "روس",
                "label_fr": "Ross"
            },
            {
                "value": 21723,
                "label": "Kempton",
                "label_ar": "كمبتون",
                "label_fr": "Kempton"
            },
            {
                "value": 21449,
                "label": "Berry",
                "label_ar": "بيري",
                "label_fr": "Baie"
            },
            {
                "value": 21632,
                "label": "Cooee",
                "label_ar": "هديل",
                "label_fr": "Cooee"
            },
            {
                "value": 21326,
                "label": "Pipers Brook",
                "label_ar": "بايبرز بروك",
                "label_fr": "Pipers Brook"
            },
            {
                "value": 21759,
                "label": "Tarlee",
                "label_ar": "Tarlee",
                "label_fr": "Tarlee"
            },
            {
                "value": 21237,
                "label": "Keysbrook",
                "label_ar": "كيزبروك",
                "label_fr": "Keysbrook"
            },
            {
                "value": 21902,
                "label": "Eden Park",
                "label_ar": "حديقة عدن",
                "label_fr": "Eden Park"
            },
            {
                "value": 21169,
                "label": "Cooroy",
                "label_ar": "كوروي",
                "label_fr": "Cooroy"
            },
            {
                "value": 20429,
                "label": "Waurn Ponds",
                "label_ar": "وورن بوندس",
                "label_fr": "Étangs de Waurn"
            },
            {
                "value": 21171,
                "label": "Research",
                "label_ar": "ابحاث",
                "label_fr": "Recherche"
            },
            {
                "value": 20568,
                "label": "Ormond",
                "label_ar": "أورموند",
                "label_fr": "Ormond"
            },
            {
                "value": 21083,
                "label": "Moree",
                "label_ar": "موري",
                "label_fr": "Moree"
            },
            {
                "value": 21429,
                "label": "Nullawarre",
                "label_ar": "نولوار",
                "label_fr": "Nullawarre"
            },
            {
                "value": 20337,
                "label": "Scoresby",
                "label_ar": "سكورسبي",
                "label_fr": "Scoresby"
            },
            {
                "value": 20016,
                "label": "Mackay",
                "label_ar": "ماكاي",
                "label_fr": "Mackay"
            },
            {
                "value": 21306,
                "label": "Bordertown",
                "label_ar": "مدينة حدودية",
                "label_fr": "Ville frontière"
            },
            {
                "value": 19952,
                "label": "Pyrmont",
                "label_ar": "بيرمونت",
                "label_fr": "Pyrmont"
            },
            {
                "value": 20982,
                "label": "Alice Springs",
                "label_ar": "أليس سبرينجز",
                "label_fr": "Alice Springs"
            },
            {
                "value": 20556,
                "label": "Coomera",
                "label_ar": "كوميرا",
                "label_fr": "Coomera"
            },
            {
                "value": 21178,
                "label": "Barnsley",
                "label_ar": "بارنسلي",
                "label_fr": "Barnsley"
            },
            {
                "value": 20491,
                "label": "Woy Woy",
                "label_ar": "ووي ووي",
                "label_fr": "Woy Woy"
            },
            {
                "value": 21253,
                "label": "The Rocks",
                "label_ar": "الصخور",
                "label_fr": "Les rochers"
            },
            {
                "value": 21913,
                "label": "Ashton",
                "label_ar": "أشتون",
                "label_fr": "Ashton"
            },
            {
                "value": 21079,
                "label": "Bega",
                "label_ar": "بيغا",
                "label_fr": "Bega"
            },
            {
                "value": 20891,
                "label": "Yallourn",
                "label_ar": "يالورن",
                "label_fr": "Yallourn"
            },
            {
                "value": 20435,
                "label": "Eltham",
                "label_ar": "إلثام",
                "label_fr": "Eltham"
            },
            {
                "value": 21358,
                "label": "Tinamba",
                "label_ar": "تينامبا",
                "label_fr": "Tinamba"
            },
            {
                "value": 21330,
                "label": "Korumburra",
                "label_ar": "كورومبورا",
                "label_fr": "Korumburra"
            },
            {
                "value": 20718,
                "label": "Oxenford",
                "label_ar": "أوكسنفورد",
                "label_fr": "Oxenford"
            },
            {
                "value": 20671,
                "label": "Golden Grove",
                "label_ar": "جولدن جروف",
                "label_fr": "Golden Grove"
            },
            {
                "value": 20866,
                "label": "Little Bay",
                "label_ar": "ليتل باي",
                "label_fr": "Little Bay"
            },
            {
                "value": 19974,
                "label": "Springwood",
                "label_ar": "سبرينجوود",
                "label_fr": "Springwood"
            },
            {
                "value": 21966,
                "label": "Southern Brook",
                "label_ar": "جنوب بروك",
                "label_fr": "Southern Brook"
            },
            {
                "value": 20310,
                "label": "Carrum Downs",
                "label_ar": "كاروم داونز",
                "label_fr": "Carrum Downs"
            },
            {
                "value": 20595,
                "label": "Rosebud",
                "label_ar": "زهرة الورد",
                "label_fr": "Rosebud"
            },
            {
                "value": 20508,
                "label": "Altona",
                "label_ar": "التونا",
                "label_fr": "Altona"
            },
            {
                "value": 21213,
                "label": "Kingston",
                "label_ar": "كينغستون",
                "label_fr": "Kingston"
            },
            {
                "value": 21071,
                "label": "Hazelbrook",
                "label_ar": "هازلبروك",
                "label_fr": "Hazelbrook"
            },
            {
                "value": 20447,
                "label": "Butchers Ridge",
                "label_ar": "الجزارين ريدج",
                "label_fr": "Butchers Ridge"
            },
            {
                "value": 20811,
                "label": "Collie",
                "label_ar": "كولي",
                "label_fr": "Colley"
            },
            {
                "value": 20664,
                "label": "Kangaroo Flat",
                "label_ar": "شقة الكنغر",
                "label_fr": "Appartement Kangourou"
            },
            {
                "value": 21892,
                "label": "Carnarvon",
                "label_ar": "كارنارفون",
                "label_fr": "Carnarvon"
            },
            {
                "value": 21331,
                "label": "Leongatha",
                "label_ar": "ليونغاثا",
                "label_fr": "Leongatha"
            },
            {
                "value": 21629,
                "label": "Copmanhurst",
                "label_ar": "كوبمانهورست",
                "label_fr": "Copmanhurst"
            },
            {
                "value": 20156,
                "label": "Kingston",
                "label_ar": "كينغستون",
                "label_fr": "Kingston"
            },
            {
                "value": 20242,
                "label": "Maitland",
                "label_ar": "ميتلاند",
                "label_fr": "Maitland"
            },
            {
                "value": 20470,
                "label": "Rye",
                "label_ar": "الذرة",
                "label_fr": "Seigle"
            },
            {
                "value": 21932,
                "label": "Barry",
                "label_ar": "باري",
                "label_fr": "Barry"
            },
            {
                "value": 21008,
                "label": "Toukley",
                "label_ar": "توكلي",
                "label_fr": "Toukley"
            },
            {
                "value": 21243,
                "label": "Bayles",
                "label_ar": "بايلز",
                "label_fr": "Bayles"
            },
            {
                "value": 20323,
                "label": "Wantirna",
                "label_ar": "وانتيرنا",
                "label_fr": "Wantirna"
            },
            {
                "value": 21120,
                "label": "Dawson",
                "label_ar": "داوسون",
                "label_fr": "Dawson"
            },
            {
                "value": 21018,
                "label": "Ascot",
                "label_ar": "أسكوت",
                "label_fr": "Ascot"
            },
            {
                "value": 19994,
                "label": "Perth",
                "label_ar": "بيرث",
                "label_fr": "Perth"
            },
            {
                "value": 21286,
                "label": "Yapeen",
                "label_ar": "يابين",
                "label_fr": "Yapeen"
            },
            {
                "value": 20787,
                "label": "Eagle Farm",
                "label_ar": "مزرعة النسر",
                "label_fr": "Eagle Farm"
            },
            {
                "value": 20554,
                "label": "Mount Druitt",
                "label_ar": "جبل درويت",
                "label_fr": "Mont Druitt"
            },
            {
                "value": 21006,
                "label": "Chipping Norton",
                "label_ar": "تشيبينج نورتون",
                "label_fr": "Chipping Norton"
            },
            {
                "value": 21084,
                "label": "Maryland",
                "label_ar": "ماريلاند",
                "label_fr": "Maryland"
            },
            {
                "value": 20858,
                "label": "Landsborough",
                "label_ar": "لاندسبورو",
                "label_fr": "Landsborough"
            },
            {
                "value": 20356,
                "label": "Carrum",
                "label_ar": "كاروم",
                "label_fr": "Carrum"
            },
            {
                "value": 21096,
                "label": "George Town",
                "label_ar": "جورج تاون",
                "label_fr": "George Town"
            },
            {
                "value": 21055,
                "label": "Moggill",
                "label_ar": "موجيل",
                "label_fr": "Moggill"
            },
            {
                "value": 21660,
                "label": "Walgett",
                "label_ar": "والجيت",
                "label_fr": "Walgett"
            },
            {
                "value": 20580,
                "label": "Truganina",
                "label_ar": "تروجانينا",
                "label_fr": "Truganina"
            },
            {
                "value": 21464,
                "label": "Lake Cathie",
                "label_ar": "بحيرة كاثي",
                "label_fr": "Lac Cathie"
            },
            {
                "value": 20562,
                "label": "Burpengary",
                "label_ar": "بيربنجارى",
                "label_fr": "Burpengary"
            },
            {
                "value": 20471,
                "label": "Port Fairy",
                "label_ar": "ميناء الجنية",
                "label_fr": "Fée du port"
            },
            {
                "value": 20925,
                "label": "Hunters Hill",
                "label_ar": "هنترز هيل",
                "label_fr": "Hunters Hill"
            },
            {
                "value": 21564,
                "label": "Rochester",
                "label_ar": "روتشستر",
                "label_fr": "Rochester"
            },
            {
                "value": 19987,
                "label": "Southport",
                "label_ar": "ساوثبورت",
                "label_fr": "Southport"
            },
            {
                "value": 19960,
                "label": "Northgate",
                "label_ar": "البوابة الشمالية",
                "label_fr": "Porte nord"
            },
            {
                "value": 20884,
                "label": "Ferny Creek",
                "label_ar": "فيرني كريك",
                "label_fr": "Ferny Creek"
            },
            {
                "value": 20732,
                "label": "Barwon Heads",
                "label_ar": "رؤساء بارون",
                "label_fr": "Têtes de Barwon"
            },
            {
                "value": 21767,
                "label": "Westbrook",
                "label_ar": "ويستبروك",
                "label_fr": "Westbrook"
            },
            {
                "value": 20151,
                "label": "Orford",
                "label_ar": "أورفورد",
                "label_fr": "Orford"
            },
            {
                "value": 21220,
                "label": "Fairview",
                "label_ar": "فيرفيو",
                "label_fr": "Fairview"
            },
            {
                "value": 21260,
                "label": "Forbes",
                "label_ar": "فوربس",
                "label_fr": "Forbes"
            },
            {
                "value": 20791,
                "label": "Thursday Island",
                "label_ar": "جزيرة الخميس",
                "label_fr": "Île du jeudi"
            },
            {
                "value": 20743,
                "label": "Bentley",
                "label_ar": "بنتلي",
                "label_fr": "Bentley"
            },
            {
                "value": 21695,
                "label": "Douglas Park",
                "label_ar": "دوجلاس بارك",
                "label_fr": "Douglas Park"
            },
            {
                "value": 20871,
                "label": "Marsden",
                "label_ar": "مارسدن",
                "label_fr": "Marsden"
            },
            {
                "value": 20577,
                "label": "Fulham",
                "label_ar": "فولهام",
                "label_fr": "Fulham"
            },
            {
                "value": 21073,
                "label": "Tarrawanna",
                "label_ar": "تاراوانا",
                "label_fr": "Tarrawanna"
            },
            {
                "value": 20426,
                "label": "Surrey Hills",
                "label_ar": "تلال ساري",
                "label_fr": "Surrey Hills"
            },
            {
                "value": 20064,
                "label": "Gosnells",
                "label_ar": "جوسنيلس",
                "label_fr": "Gosnells"
            },
            {
                "value": 21508,
                "label": "Longford",
                "label_ar": "لونجفورد",
                "label_fr": "Longford"
            },
            {
                "value": 20413,
                "label": "Cleveland",
                "label_ar": "كليفلاند",
                "label_fr": "Cleveland"
            },
            {
                "value": 21628,
                "label": "Howlong",
                "label_ar": "حتى متى",
                "label_fr": "Combien de temps"
            },
            {
                "value": 20078,
                "label": "Walliston",
                "label_ar": "والستون",
                "label_fr": "Walliston"
            },
            {
                "value": 21880,
                "label": "Beenak",
                "label_ar": "بيناك",
                "label_fr": "Beenak"
            },
            {
                "value": 21829,
                "label": "Yarra Glen",
                "label_ar": "يارا جلين",
                "label_fr": "Yarra Glen"
            },
            {
                "value": 21050,
                "label": "Pomona",
                "label_ar": "بومونا",
                "label_fr": "Pomona"
            },
            {
                "value": 20749,
                "label": "Kilcunda",
                "label_ar": "كيلكوندا",
                "label_fr": "Kilcunda"
            },
            {
                "value": 20544,
                "label": "Mount Cotton",
                "label_ar": "جبل قطن",
                "label_fr": "Mont Coton"
            },
            {
                "value": 20389,
                "label": "Queanbeyan",
                "label_ar": "كوينبيان",
                "label_fr": "Queanbeyan"
            },
            {
                "value": 20980,
                "label": "Wellington",
                "label_ar": "ويلينجتون",
                "label_fr": "Wellington"
            },
            {
                "value": 21113,
                "label": "Young",
                "label_ar": "شاب",
                "label_fr": "Jeune"
            },
            {
                "value": 20655,
                "label": "Warwick",
                "label_ar": "وارويك",
                "label_fr": "Warwick"
            },
            {
                "value": 21187,
                "label": "Tyabb",
                "label_ar": "طيب",
                "label_fr": "Tyabb"
            },
            {
                "value": 20548,
                "label": "New Lambton",
                "label_ar": "نيو لامبتون",
                "label_fr": "New Lambton"
            },
            {
                "value": 20293,
                "label": "Fawkner",
                "label_ar": "فوكنر",
                "label_fr": "Fawkner"
            },
            {
                "value": 21899,
                "label": "Hahndorf",
                "label_ar": "هاندورف",
                "label_fr": "Hahndorf"
            },
            {
                "value": 20283,
                "label": "Caulfield South",
                "label_ar": "كولفيلد ساوث",
                "label_fr": "Caulfield Sud"
            },
            {
                "value": 21446,
                "label": "Manning",
                "label_ar": "مانينغ",
                "label_fr": "Manning"
            },
            {
                "value": 20617,
                "label": "Point Cook",
                "label_ar": "نقطة كوك",
                "label_fr": "Point Cook"
            },
            {
                "value": 20207,
                "label": "Engadine",
                "label_ar": "إنجادين",
                "label_fr": "Engadine"
            },
            {
                "value": 20049,
                "label": "Hervey Bay",
                "label_ar": "خليج هيرفي",
                "label_fr": "Hervey Bay"
            },
            {
                "value": 21658,
                "label": "Clarence Town",
                "label_ar": "كلارنس تاون",
                "label_fr": "Clarence Town"
            },
            {
                "value": 20583,
                "label": "Armadale",
                "label_ar": "أرمادال",
                "label_fr": "Armadale"
            },
            {
                "value": 21361,
                "label": "Koetong",
                "label_ar": "كويتونغ",
                "label_fr": "Koetong"
            },
            {
                "value": 20463,
                "label": "O'Halloran Hill",
                "label_ar": "اوهالوران هيل",
                "label_fr": "O'Halloran Hill"
            },
            {
                "value": 21300,
                "label": "Nebo",
                "label_ar": "نيبو",
                "label_fr": "Nébo"
            },
            {
                "value": 21649,
                "label": "Gould",
                "label_ar": "جولد",
                "label_fr": "Gould"
            },
            {
                "value": 21524,
                "label": "Miles",
                "label_ar": "اميال",
                "label_fr": "Miles"
            },
            {
                "value": 20039,
                "label": "Mooloolah Valley",
                "label_ar": "وادي مولولا",
                "label_fr": "Vallée de Mooloolah"
            },
            {
                "value": 21889,
                "label": "Blackwater",
                "label_ar": "مياه سوداء",
                "label_fr": "Eau noire"
            },
            {
                "value": 21148,
                "label": "Darra",
                "label_ar": "دارا",
                "label_fr": "Darra"
            },
            {
                "value": 21150,
                "label": "Mourilyan",
                "label_ar": "موريليان",
                "label_fr": "Mourilyan"
            },
            {
                "value": 19969,
                "label": "Palm Beach",
                "label_ar": "شاطئ النخيل",
                "label_fr": "plage de palmiers"
            },
            {
                "value": 21054,
                "label": "Coolum",
                "label_ar": "كولوم",
                "label_fr": "Coolum"
            },
            {
                "value": 21353,
                "label": "Morwell",
                "label_ar": "مورويل",
                "label_fr": "Morwell"
            },
            {
                "value": 20388,
                "label": "Canberra",
                "label_ar": "كانبيرا",
                "label_fr": "Canberra"
            },
            {
                "value": 21832,
                "label": "Halls Gap",
                "label_ar": "هولز جاب",
                "label_fr": "Halls Gap"
            },
            {
                "value": 20024,
                "label": "Stratford",
                "label_ar": "ستراتفورد",
                "label_fr": "Stratford"
            },
            {
                "value": 19985,
                "label": "Seaforth",
                "label_ar": "سيفورث",
                "label_fr": "Seaforth"
            },
            {
                "value": 20022,
                "label": "Morayfield",
                "label_ar": "مورايفيلد",
                "label_fr": "Morayfield"
            },
            {
                "value": 20177,
                "label": "Sutherland",
                "label_ar": "ساذرلاند",
                "label_fr": "Sutherland"
            },
            {
                "value": 20819,
                "label": "Alexandra",
                "label_ar": "الكسندرا",
                "label_fr": "Alexandra"
            },
            {
                "value": 20403,
                "label": "South Perth",
                "label_ar": "جنوب بيرث",
                "label_fr": "Sud de Perth"
            },
            {
                "value": 20631,
                "label": "Selby",
                "label_ar": "سيلبي",
                "label_fr": "Selby"
            },
            {
                "value": 20303,
                "label": "Vermont",
                "label_ar": "فيرمونت",
                "label_fr": "Vermont"
            },
            {
                "value": 20879,
                "label": "Seaview",
                "label_ar": "اطلالة البحر",
                "label_fr": "Vue sur la mer"
            },
            {
                "value": 20250,
                "label": "Mount Colah",
                "label_ar": "جبل كولا",
                "label_fr": "Mont Colah"
            },
            {
                "value": 20139,
                "label": "North Fitzroy",
                "label_ar": "شمال فيتزروي",
                "label_fr": "North Fitzroy"
            },
            {
                "value": 20981,
                "label": "Moana",
                "label_ar": "موانا",
                "label_fr": "Moana"
            },
            {
                "value": 21561,
                "label": "Mylor",
                "label_ar": "ميلور",
                "label_fr": "Mylor"
            },
            {
                "value": 21456,
                "label": "Matcham",
                "label_ar": "ماتشام",
                "label_fr": "Matcham"
            },
            {
                "value": 20921,
                "label": "Bateau Bay",
                "label_ar": "خليج باتو",
                "label_fr": "Bateau Bay"
            },
            {
                "value": 20932,
                "label": "Healesville",
                "label_ar": "هيلسفيل",
                "label_fr": "Healesville"
            },
            {
                "value": 21854,
                "label": "Yenda",
                "label_ar": "يندا",
                "label_fr": "Yenda"
            },
            {
                "value": 20522,
                "label": "Castlecrag",
                "label_ar": "Castlecrag",
                "label_fr": "Castlecrag"
            },
            {
                "value": 20407,
                "label": "Cammeray",
                "label_ar": "كاميراي",
                "label_fr": "Cammeray"
            },
            {
                "value": 20919,
                "label": "Kingswood",
                "label_ar": "كينجسوود",
                "label_fr": "Kingswood"
            },
            {
                "value": 21042,
                "label": "Bogangar",
                "label_ar": "بوغانجار",
                "label_fr": "Bogangar"
            },
            {
                "value": 21443,
                "label": "Fern Tree Gully",
                "label_ar": "السرخس شجرة جولي",
                "label_fr": "Fern Tree Gully"
            },
            {
                "value": 21466,
                "label": "Hastings Point",
                "label_ar": "نقطة هاستنجز",
                "label_fr": "Hastings Point"
            },
            {
                "value": 21765,
                "label": "Pittsworth",
                "label_ar": "بيتسورث",
                "label_fr": "Pittsworth"
            },
            {
                "value": 21933,
                "label": "Sunnyside",
                "label_ar": "جانب مشرق",
                "label_fr": "Côté ensoleillé"
            },
            {
                "value": 21310,
                "label": "Gregadoo",
                "label_ar": "جريجادو",
                "label_fr": "Gregadoo"
            },
            {
                "value": 21460,
                "label": "Tarro",
                "label_ar": "تارو",
                "label_fr": "Tarro"
            },
            {
                "value": 21574,
                "label": "Tingoora",
                "label_ar": "تنجورة",
                "label_fr": "Tingoora"
            },
            {
                "value": 20224,
                "label": "Minto",
                "label_ar": "مينتو",
                "label_fr": "Minto"
            },
            {
                "value": 20843,
                "label": "Hall",
                "label_ar": "صالة",
                "label_fr": "salle"
            },
            {
                "value": 21943,
                "label": "Lowanna",
                "label_ar": "Lowanna",
                "label_fr": "Lowanna"
            },
            {
                "value": 21076,
                "label": "Oxley",
                "label_ar": "أوكسلي",
                "label_fr": "Oxley"
            },
            {
                "value": 20036,
                "label": "Logan City",
                "label_ar": "مدينة لوغان",
                "label_fr": "Logan City"
            },
            {
                "value": 20840,
                "label": "Wantirna South",
                "label_ar": "وانتيرنا الجنوبية",
                "label_fr": "Wantirna Sud"
            },
            {
                "value": 20525,
                "label": "Woolwich",
                "label_ar": "وولويتش",
                "label_fr": "Woolwich"
            },
            {
                "value": 21011,
                "label": "Bellbird",
                "label_ar": "بيلبيرد",
                "label_fr": "Bellbird"
            },
            {
                "value": 20181,
                "label": "Jannali",
                "label_ar": "جنالي",
                "label_fr": "Jannali"
            },
            {
                "value": 20438,
                "label": "Kew East",
                "label_ar": "كيو الشرق",
                "label_fr": "Kew Est"
            },
            {
                "value": 21976,
                "label": "Mirboo",
                "label_ar": "ميربو",
                "label_fr": "Mirboo"
            },
            {
                "value": 21864,
                "label": "Murtoa",
                "label_ar": "مرتوا",
                "label_fr": "Murtoa"
            },
            {
                "value": 21638,
                "label": "Yarrawarrah",
                "label_ar": "ياراوارا",
                "label_fr": "Yarrawarrah"
            },
            {
                "value": 21682,
                "label": "Wandoan",
                "label_ar": "واندوان",
                "label_fr": "Wandoan"
            },
            {
                "value": 21338,
                "label": "Forsayth",
                "label_ar": "فورسايث",
                "label_fr": "Forsayth"
            },
            {
                "value": 21380,
                "label": "Woombah",
                "label_ar": "ومباه",
                "label_fr": "Woombah"
            },
            {
                "value": 20133,
                "label": "Sylvania",
                "label_ar": "سيلفانيا",
                "label_fr": "Sylvania"
            },
            {
                "value": 21302,
                "label": "Goomeri",
                "label_ar": "جوميري",
                "label_fr": "Goomeri"
            },
            {
                "value": 20572,
                "label": "Wheelers Hill",
                "label_ar": "ويلرز هيل",
                "label_fr": "Wheelers Hill"
            },
            {
                "value": 21553,
                "label": "Royal Park",
                "label_ar": "رويال بارك",
                "label_fr": "Parc royal"
            },
            {
                "value": 20793,
                "label": "Warrimoo",
                "label_ar": "واريمو",
                "label_fr": "Warrimoo"
            },
            {
                "value": 20351,
                "label": "Eaglehawk",
                "label_ar": "صقر النسر",
                "label_fr": "Aigle faucon"
            },
            {
                "value": 21432,
                "label": "Camperdown",
                "label_ar": "كامبرداون",
                "label_fr": "Camperdown"
            },
            {
                "value": 21458,
                "label": "Avondale",
                "label_ar": "أفونديل",
                "label_fr": "Avondale"
            },
            {
                "value": 20229,
                "label": "Bexley",
                "label_ar": "بيكسلي",
                "label_fr": "Bexley"
            },
            {
                "value": 21077,
                "label": "Eden",
                "label_ar": "عدن",
                "label_fr": "Eden"
            },
            {
                "value": 21926,
                "label": "Kardella South",
                "label_ar": "كارديلا الجنوبية",
                "label_fr": "Kardella Sud"
            },
            {
                "value": 21468,
                "label": "Camden Haven",
                "label_ar": "كامدن هيفن",
                "label_fr": "Camden Haven"
            },
            {
                "value": 20226,
                "label": "Cremorne",
                "label_ar": "كريمورن",
                "label_fr": "Crémorne"
            },
            {
                "value": 20043,
                "label": "North Tamborine",
                "label_ar": "شمال تامبورين",
                "label_fr": "Nord Tamborine"
            },
            {
                "value": 21852,
                "label": "Gore Hill",
                "label_ar": "جور هيل",
                "label_fr": "Gore Hill"
            },
            {
                "value": 21930,
                "label": "Perenjori",
                "label_ar": "بيرينجوري",
                "label_fr": "Perenjori"
            },
            {
                "value": 20069,
                "label": "Mandurah",
                "label_ar": "ماندوراه",
                "label_fr": "Mandurah"
            },
            {
                "value": 20662,
                "label": "Wauchope",
                "label_ar": "واشوب",
                "label_fr": "Wauchope"
            },
            {
                "value": 21959,
                "label": "Koorawatha",
                "label_ar": "كوراواثا",
                "label_fr": "Koorawatha"
            },
            {
                "value": 21888,
                "label": "Kambalda",
                "label_ar": "كامبالدا",
                "label_fr": "Kambalda"
            },
            {
                "value": 20028,
                "label": "Ripley",
                "label_ar": "ريبلي",
                "label_fr": "Ripley"
            },
            {
                "value": 20666,
                "label": "Dunolly",
                "label_ar": "دنولي",
                "label_fr": "Dunolly"
            },
            {
                "value": 20517,
                "label": "Queenscliff",
                "label_ar": "كوينزكليف",
                "label_fr": "Queenscliff"
            },
            {
                "value": 21205,
                "label": "Drouin South",
                "label_ar": "دروين الجنوب",
                "label_fr": "Drouin Sud"
            },
            {
                "value": 21118,
                "label": "Waterloo",
                "label_ar": "واترلو",
                "label_fr": "Waterloo"
            },
            {
                "value": 20569,
                "label": "Collingwood",
                "label_ar": "كولينجوود",
                "label_fr": "Collingwood"
            },
            {
                "value": 21309,
                "label": "Longreach",
                "label_ar": "Longreach",
                "label_fr": "Longreach"
            },
            {
                "value": 21393,
                "label": "Bellbrae",
                "label_ar": "بيلبراي",
                "label_fr": "Bellbrae"
            },
            {
                "value": 20441,
                "label": "Rozelle",
                "label_ar": "روزيل",
                "label_fr": "Rozelle"
            },
            {
                "value": 21271,
                "label": "Largs North",
                "label_ar": "لارجز نورث",
                "label_fr": "Largs Nord"
            },
            {
                "value": 21002,
                "label": "Derwent",
                "label_ar": "درونت",
                "label_fr": "Derwent"
            },
            {
                "value": 21413,
                "label": "Arcadia",
                "label_ar": "أركاديا",
                "label_fr": "Arcadie"
            },
            {
                "value": 20377,
                "label": "Surfers Paradise",
                "label_ar": "جنة راكبي الأمواج",
                "label_fr": "Paradis des surfeurs"
            },
            {
                "value": 20613,
                "label": "Beachmere",
                "label_ar": "بيتشمير",
                "label_fr": "Beachmere"
            },
            {
                "value": 20853,
                "label": "Dilston",
                "label_ar": "ديلستون",
                "label_fr": "Dilston"
            },
            {
                "value": 20710,
                "label": "Merredin",
                "label_ar": "ميردين",
                "label_fr": "Merredin"
            },
            {
                "value": 21542,
                "label": "Cooloolabin",
                "label_ar": "كولولابين",
                "label_fr": "Cooloolabin"
            },
            {
                "value": 20083,
                "label": "Bentley",
                "label_ar": "بنتلي",
                "label_fr": "Bentley"
            },
            {
                "value": 20618,
                "label": "Windsor",
                "label_ar": "وندسور",
                "label_fr": "Windsor"
            },
            {
                "value": 21354,
                "label": "Stratford",
                "label_ar": "ستراتفورد",
                "label_fr": "Stratford"
            },
            {
                "value": 20928,
                "label": "Highett",
                "label_ar": "هيغيت",
                "label_fr": "Highett"
            },
            {
                "value": 21195,
                "label": "Tooradin",
                "label_ar": "تورادين",
                "label_fr": "Tooradin"
            },
            {
                "value": 20035,
                "label": "Thornlands",
                "label_ar": "ثورنلاندز",
                "label_fr": "Thornlands"
            },
            {
                "value": 21544,
                "label": "Coolah",
                "label_ar": "كولاه",
                "label_fr": "Coolah"
            },
            {
                "value": 21988,
                "label": "Maroota",
                "label_ar": "ماروتا",
                "label_fr": "Maroota"
            },
            {
                "value": 21179,
                "label": "Torquay",
                "label_ar": "توركواي",
                "label_fr": "Torquay"
            },
            {
                "value": 21182,
                "label": "Silvan",
                "label_ar": "سيلفان",
                "label_fr": "Sylvain"
            },
            {
                "value": 21977,
                "label": "Retreat",
                "label_ar": "تراجع",
                "label_fr": "Battre en retraite"
            },
            {
                "value": 20638,
                "label": "Mount Barker",
                "label_ar": "جبل باركر",
                "label_fr": "Mont Barker"
            },
            {
                "value": 21741,
                "label": "Advancetown",
                "label_ar": "ادفانستاون",
                "label_fr": "Advancetown"
            },
            {
                "value": 21917,
                "label": "Pitt Town",
                "label_ar": "بيت تاون",
                "label_fr": "Pitt Town"
            },
            {
                "value": 20845,
                "label": "Don",
                "label_ar": "دون",
                "label_fr": "Don"
            },
            {
                "value": 21375,
                "label": "Wattle Grove",
                "label_ar": "واتل جروف",
                "label_fr": "Wattle Grove"
            },
            {
                "value": 20986,
                "label": "Crafers",
                "label_ar": "كرافرز",
                "label_fr": "Crafers"
            },
            {
                "value": 20399,
                "label": "Macleod",
                "label_ar": "ماكلويد",
                "label_fr": "Macleod"
            },
            {
                "value": 20719,
                "label": "Pinjarra",
                "label_ar": "بينجاررا",
                "label_fr": "Pinjarra"
            },
            {
                "value": 21212,
                "label": "Wynyard",
                "label_ar": "وينيارد",
                "label_fr": "Wynyard"
            },
            {
                "value": 21455,
                "label": "Beresfield",
                "label_ar": "بيريسفيلد",
                "label_fr": "Beresfield"
            },
            {
                "value": 21180,
                "label": "Point Lonsdale",
                "label_ar": "نقطة لونسديل",
                "label_fr": "Point Lonsdale"
            },
            {
                "value": 20256,
                "label": "Croydon",
                "label_ar": "كرويدون",
                "label_fr": "Croydon"
            },
            {
                "value": 20411,
                "label": "Campbelltown",
                "label_ar": "كامبلتاون",
                "label_fr": "Campbelltown"
            },
            {
                "value": 20911,
                "label": "Gracemere",
                "label_ar": "جراسمير",
                "label_fr": "Gracemere"
            },
            {
                "value": 20306,
                "label": "Doncaster",
                "label_ar": "دونكاستر",
                "label_fr": "Doncaster"
            },
            {
                "value": 20857,
                "label": "Lindisfarne",
                "label_ar": "ليندسفارن",
                "label_fr": "Lindisfarne"
            },
            {
                "value": 20444,
                "label": "Tamworth",
                "label_ar": "تامورث",
                "label_fr": "Tamworth"
            },
            {
                "value": 20170,
                "label": "Wentworthville",
                "label_ar": "وينتورثفيل",
                "label_fr": "Wentworthville"
            },
            {
                "value": 21887,
                "label": "Yarpturk",
                "label_ar": "يربتورك",
                "label_fr": "Yarpturk"
            },
            {
                "value": 20767,
                "label": "Seville East",
                "label_ar": "إشبيلية الشرقية",
                "label_fr": "Séville Est"
            },
            {
                "value": 20334,
                "label": "Mornington",
                "label_ar": "مورنينغتون",
                "label_fr": "Mornington"
            },
            {
                "value": 20609,
                "label": "Sandgate",
                "label_ar": "Sandgate",
                "label_fr": "Sandgate"
            },
            {
                "value": 21268,
                "label": "Maudsland",
                "label_ar": "مودسلاند",
                "label_fr": "Maudsland"
            },
            {
                "value": 20375,
                "label": "Burleigh Heads",
                "label_ar": "رؤساء بيرلي",
                "label_fr": "Têtes de Burleigh"
            },
            {
                "value": 21567,
                "label": "Laverton",
                "label_ar": "لافرتون",
                "label_fr": "Laverton"
            },
            {
                "value": 20702,
                "label": "Warren",
                "label_ar": "وارن",
                "label_fr": "Garenne"
            },
            {
                "value": 20096,
                "label": "Morphett Vale",
                "label_ar": "مورفيت فالي",
                "label_fr": "Morphett Vale"
            },
            {
                "value": 20590,
                "label": "Werribee South",
                "label_ar": "Werribee الجنوب",
                "label_fr": "Werribee Sud"
            },
            {
                "value": 21392,
                "label": "Upwey",
                "label_ar": "مبروك",
                "label_fr": "Upwey"
            },
            {
                "value": 21513,
                "label": "Penguin",
                "label_ar": "البطريق",
                "label_fr": "manchot"
            },
            {
                "value": 21822,
                "label": "Heathcote Junction",
                "label_ar": "مفرق هيثكوت",
                "label_fr": "Heathcote Junction"
            },
            {
                "value": 21065,
                "label": "Redhead",
                "label_ar": "أحمر الشعر",
                "label_fr": "Roux"
            },
            {
                "value": 20404,
                "label": "Bassendean",
                "label_ar": "باسينديان",
                "label_fr": "Bassendéen"
            },
            {
                "value": 21274,
                "label": "Attadale",
                "label_ar": "أتدال",
                "label_fr": "Attadale"
            },
            {
                "value": 21365,
                "label": "Asquith",
                "label_ar": "أسكويث",
                "label_fr": "Asquith"
            },
            {
                "value": 21254,
                "label": "Cambewarra",
                "label_ar": "كامبيوارا",
                "label_fr": "Cambewarra"
            },
            {
                "value": 21414,
                "label": "Pokolbin",
                "label_ar": "بوكولبين",
                "label_fr": "Pokolbin"
            },
            {
                "value": 20606,
                "label": "Narre Warren North",
                "label_ar": "ناري وارين نورث",
                "label_fr": "Narre Warren Nord"
            },
            {
                "value": 20978,
                "label": "Thebarton",
                "label_ar": "ثيبارتون",
                "label_fr": "Thebarton"
            },
            {
                "value": 21381,
                "label": "Marsden Park",
                "label_ar": "مارسدن بارك",
                "label_fr": "Marsden Park"
            },
            {
                "value": 21706,
                "label": "Medlow Bath",
                "label_ar": "ميدلو باث",
                "label_fr": "Bain Medlow"
            },
            {
                "value": 20421,
                "label": "Kelso",
                "label_ar": "كيلسو",
                "label_fr": "Kelso"
            },
            {
                "value": 20752,
                "label": "Wallsend",
                "label_ar": "وولسيند",
                "label_fr": "Wallsend"
            },
            {
                "value": 20836,
                "label": "Boulder",
                "label_ar": "بولدر",
                "label_fr": "Rocher"
            },
            {
                "value": 20540,
                "label": "Rosehill",
                "label_ar": "روزهيل",
                "label_fr": "Rosehill"
            },
            {
                "value": 21747,
                "label": "Rodborough",
                "label_ar": "رودبورو",
                "label_fr": "Rodborough"
            },
            {
                "value": 21685,
                "label": "Balhannah",
                "label_ar": "بلحنة",
                "label_fr": "Balhannah"
            },
            {
                "value": 20673,
                "label": "Brighton",
                "label_ar": "برايتون",
                "label_fr": "Brighton"
            },
            {
                "value": 21763,
                "label": "Long Plains",
                "label_ar": "سهول طويلة",
                "label_fr": "Longues plaines"
            },
            {
                "value": 21800,
                "label": "Saints",
                "label_ar": "القديسين",
                "label_fr": "Les saints"
            },
            {
                "value": 21927,
                "label": "Allambee",
                "label_ar": "اللامبي",
                "label_fr": "Allambee"
            },
            {
                "value": 20499,
                "label": "Ballina",
                "label_ar": "بالينا",
                "label_fr": "Ballina"
            },
            {
                "value": 21735,
                "label": "Deepdene",
                "label_ar": "ديبدين",
                "label_fr": "Deepdene"
            },
            {
                "value": 20442,
                "label": "Valla Beach",
                "label_ar": "شاطئ فالا",
                "label_fr": "Plage de Valla"
            },
            {
                "value": 19984,
                "label": "Upper Coomera",
                "label_ar": "كوميرا العليا",
                "label_fr": "Coomera supérieur"
            },
            {
                "value": 20790,
                "label": "Yatala",
                "label_ar": "ياتالا",
                "label_fr": "Yatala"
            },
            {
                "value": 20450,
                "label": "Diamond Creek",
                "label_ar": "دايموند كريك",
                "label_fr": "Diamond Creek"
            },
            {
                "value": 20665,
                "label": "Fern Tree",
                "label_ar": "شجرة السرخس",
                "label_fr": "Arbre de fougère"
            },
            {
                "value": 20774,
                "label": "Oaklands",
                "label_ar": "اوكلاندز",
                "label_fr": "Oaklands"
            },
            {
                "value": 21014,
                "label": "Corinda",
                "label_ar": "كوريندا",
                "label_fr": "Corinda"
            },
            {
                "value": 21775,
                "label": "Orroroo",
                "label_ar": "أوروروو",
                "label_fr": "Orroroo"
            },
            {
                "value": 20280,
                "label": "Northcote",
                "label_ar": "نورثكوت",
                "label_fr": "Northcote"
            },
            {
                "value": 20783,
                "label": "Mosman Park",
                "label_ar": "موسمان بارك",
                "label_fr": "Parc Mosman"
            },
            {
                "value": 20365,
                "label": "Underwood",
                "label_ar": "أندروود",
                "label_fr": "Underwood"
            },
            {
                "value": 21551,
                "label": "Minmi",
                "label_ar": "Minmi",
                "label_fr": "Minmi"
            },
            {
                "value": 21624,
                "label": "Piggabeen",
                "label_ar": "Piggabeen",
                "label_fr": "Piggabeen"
            },
            {
                "value": 20255,
                "label": "Westmead",
                "label_ar": "ويستميد",
                "label_fr": "Westmead"
            },
            {
                "value": 20396,
                "label": "Laverton",
                "label_ar": "لافرتون",
                "label_fr": "Laverton"
            },
            {
                "value": 20220,
                "label": "Killara",
                "label_ar": "كيلارا",
                "label_fr": "Killara"
            },
            {
                "value": 21805,
                "label": "Hyland Park",
                "label_ar": "هايلاند بارك",
                "label_fr": "Hyland Park"
            },
            {
                "value": 20218,
                "label": "Mosman",
                "label_ar": "موسمان",
                "label_fr": "Mosman"
            },
            {
                "value": 21921,
                "label": "Tolga",
                "label_ar": "تولجا",
                "label_fr": "Tolga"
            },
            {
                "value": 21696,
                "label": "Wyreema",
                "label_ar": "ويريما",
                "label_fr": "Wyreema"
            },
            {
                "value": 20136,
                "label": "Wyndham",
                "label_ar": "ويندهام",
                "label_fr": "Wyndham"
            },
            {
                "value": 20988,
                "label": "Victor Harbor",
                "label_ar": "فيكتور هاربور",
                "label_fr": "Victor Harbor"
            },
            {
                "value": 21441,
                "label": "Buninyong",
                "label_ar": "بونينيونغ",
                "label_fr": "Buninyong"
            },
            {
                "value": 20678,
                "label": "Port Kembla",
                "label_ar": "ميناء كيمبلا",
                "label_fr": "Port Kembla"
            },
            {
                "value": 19953,
                "label": "Boambee",
                "label_ar": "بوامبي",
                "label_fr": "Boambee"
            },
            {
                "value": 21612,
                "label": "Bundanoon",
                "label_ar": "بوندانون",
                "label_fr": "Bundanoon"
            },
            {
                "value": 20436,
                "label": "Hastings",
                "label_ar": "هاستنجز",
                "label_fr": "Hastings"
            },
            {
                "value": 21787,
                "label": "Yangan",
                "label_ar": "يانغان",
                "label_fr": "Yangan"
            },
            {
                "value": 21577,
                "label": "Woolgoolga",
                "label_ar": "وولجولجا",
                "label_fr": "Woolgoolga"
            },
            {
                "value": 20109,
                "label": "Ryde",
                "label_ar": "رايد",
                "label_fr": "Ryde"
            },
            {
                "value": 20737,
                "label": "Redan",
                "label_ar": "ريدان",
                "label_fr": "Redan"
            },
            {
                "value": 20046,
                "label": "Glass House Mountains",
                "label_ar": "جبال البيت الزجاجي",
                "label_fr": "Glass House Mountains"
            },
            {
                "value": 20341,
                "label": "Bundoora",
                "label_ar": "بندورة",
                "label_fr": "Bundoora"
            },
            {
                "value": 21000,
                "label": "Aberfoyle",
                "label_ar": "أبيرفويل",
                "label_fr": "Aberfoyle"
            },
            {
                "value": 20203,
                "label": "Caringbah",
                "label_ar": "Caringbah",
                "label_fr": "Caringbah"
            },
            {
                "value": 21715,
                "label": "Hadspen",
                "label_ar": "هادسبين",
                "label_fr": "Hadspen"
            },
            {
                "value": 20731,
                "label": "Gellibrand",
                "label_ar": "جيليبراند",
                "label_fr": "Gellibrand"
            },
            {
                "value": 20896,
                "label": "Inglewood",
                "label_ar": "إنجلوود",
                "label_fr": "Inglewood"
            },
            {
                "value": 20440,
                "label": "Avalon",
                "label_ar": "أفالون",
                "label_fr": "Avalon"
            },
            {
                "value": 21686,
                "label": "Tatura",
                "label_ar": "تاتورا",
                "label_fr": "Tatura"
            },
            {
                "value": 20837,
                "label": "Failford",
                "label_ar": "فيلفورد",
                "label_fr": "Failford"
            },
            {
                "value": 21291,
                "label": "Hepburn Springs",
                "label_ar": "هيبورن سبرينغز",
                "label_fr": "Hepburn Springs"
            },
            {
                "value": 20795,
                "label": "Kurrajong Heights",
                "label_ar": "مرتفعات كوراجونج",
                "label_fr": "Kurrajong Heights"
            },
            {
                "value": 19990,
                "label": "Melbourne",
                "label_ar": "ملبورن",
                "label_fr": "Melbourne"
            },
            {
                "value": 21980,
                "label": "Koah",
                "label_ar": "كواه",
                "label_fr": "Koah"
            },
            {
                "value": 21637,
                "label": "Dover Heights",
                "label_ar": "مرتفعات دوفر",
                "label_fr": "Dover Heights"
            },
            {
                "value": 21483,
                "label": "Poowong",
                "label_ar": "بوونج",
                "label_fr": "Poowong"
            },
            {
                "value": 21496,
                "label": "Agnes Water",
                "label_ar": "مياه أغنيس",
                "label_fr": "Agnes Water"
            },
            {
                "value": 20504,
                "label": "Cooma",
                "label_ar": "كوما",
                "label_fr": "Cooma"
            },
            {
                "value": 20533,
                "label": "Doonside",
                "label_ar": "دون سايد",
                "label_fr": "Doonside"
            },
            {
                "value": 21590,
                "label": "Darlington",
                "label_ar": "دارلينجتون",
                "label_fr": "Darlington"
            },
            {
                "value": 21265,
                "label": "Rocklea",
                "label_ar": "روكليا",
                "label_fr": "Rocklea"
            },
            {
                "value": 21812,
                "label": "Rosanna",
                "label_ar": "روزانا",
                "label_fr": "Rosanna"
            },
            {
                "value": 21947,
                "label": "Bomaderry",
                "label_ar": "بوماديري",
                "label_fr": "Bomaderry"
            },
            {
                "value": 21459,
                "label": "Orchard Hills",
                "label_ar": "تلال أورشارد",
                "label_fr": "Orchard Hills"
            },
            {
                "value": 20270,
                "label": "Mitcham",
                "label_ar": "ميتشام",
                "label_fr": "Mitcham"
            },
            {
                "value": 21201,
                "label": "Marulan",
                "label_ar": "مارولان",
                "label_fr": "Marulan"
            },
            {
                "value": 21616,
                "label": "Narooma",
                "label_ar": "ناروما",
                "label_fr": "Narooma"
            },
            {
                "value": 20593,
                "label": "Mernda",
                "label_ar": "ميرندا",
                "label_fr": "Mernda"
            },
            {
                "value": 21866,
                "label": "Alma",
                "label_ar": "ألما",
                "label_fr": "Alma"
            },
            {
                "value": 21890,
                "label": "Darlington",
                "label_ar": "دارلينجتون",
                "label_fr": "Darlington"
            },
            {
                "value": 21613,
                "label": "Gundaroo",
                "label_ar": "جوندارو",
                "label_fr": "Gundaroo"
            },
            {
                "value": 21826,
                "label": "Nirranda",
                "label_ar": "نيراندا",
                "label_fr": "Nirranda"
            },
            {
                "value": 21454,
                "label": "Warnervale",
                "label_ar": "وارنرفال",
                "label_fr": "Warnervale"
            },
            {
                "value": 21474,
                "label": "Callala Bay",
                "label_ar": "خليج كالالا",
                "label_fr": "Baie de Callala"
            },
            {
                "value": 21031,
                "label": "Miners Rest",
                "label_ar": "استراحة عمال المناجم",
                "label_fr": "Repos des mineurs"
            },
            {
                "value": 21951,
                "label": "Sandy Hollow",
                "label_ar": "ساندي هولو",
                "label_fr": "Sandy Hollow"
            },
            {
                "value": 21973,
                "label": "Bullyard",
                "label_ar": "البلطجي",
                "label_fr": "Bullyard"
            },
            {
                "value": 20550,
                "label": "Ormiston",
                "label_ar": "أورميستون",
                "label_fr": "Ormiston"
            },
            {
                "value": 20060,
                "label": "Karana Downs",
                "label_ar": "كارانا داونز",
                "label_fr": "Karana Downs"
            },
            {
                "value": 21578,
                "label": "Woombye",
                "label_ar": "وومباي",
                "label_fr": "Woombye"
            },
            {
                "value": 21825,
                "label": "Purnim",
                "label_ar": "بورنيم",
                "label_fr": "Purnim"
            },
            {
                "value": 21386,
                "label": "Windsor",
                "label_ar": "وندسور",
                "label_fr": "Windsor"
            },
            {
                "value": 21640,
                "label": "Safety Bay",
                "label_ar": "خليج الأمان",
                "label_fr": "Baie de sécurité"
            },
            {
                "value": 20459,
                "label": "Stirling",
                "label_ar": "ستيرلينغ",
                "label_fr": "Stirling"
            },
            {
                "value": 21399,
                "label": "Clarkefield",
                "label_ar": "كلاركفيلد",
                "label_fr": "Clarkefield"
            },
            {
                "value": 20234,
                "label": "Cronulla",
                "label_ar": "كرونولا",
                "label_fr": "Cronulla"
            },
            {
                "value": 20753,
                "label": "Edwardstown",
                "label_ar": "إدواردزتاون",
                "label_fr": "Edwardstown"
            },
            {
                "value": 20782,
                "label": "Cottesloe",
                "label_ar": "كوتيسلو",
                "label_fr": "Cottesloe"
            },
            {
                "value": 20021,
                "label": "Scarborough",
                "label_ar": "سكاربورو",
                "label_fr": "Scarborough"
            },
            {
                "value": 20939,
                "label": "Old Beach",
                "label_ar": "الشاطئ القديم",
                "label_fr": "Vieille plage"
            },
            {
                "value": 20239,
                "label": "Pennant Hills",
                "label_ar": "بينانت هيلز",
                "label_fr": "Pennant Hills"
            },
            {
                "value": 21106,
                "label": "Tynong",
                "label_ar": "تاينونج",
                "label_fr": "Tynong"
            },
            {
                "value": 20063,
                "label": "Harvey",
                "label_ar": "هارفي",
                "label_fr": "Harvey"
            },
            {
                "value": 20461,
                "label": "Seaford",
                "label_ar": "سيفورد",
                "label_fr": "Seaford"
            },
            {
                "value": 20885,
                "label": "Osborne",
                "label_ar": "أوزبورن",
                "label_fr": "Osborne"
            },
            {
                "value": 20902,
                "label": "Greenwich",
                "label_ar": "غرينتش",
                "label_fr": "Greenwich"
            },
            {
                "value": 20432,
                "label": "Forest Hill",
                "label_ar": "تلة الغابة",
                "label_fr": "Forest Hill"
            },
            {
                "value": 20927,
                "label": "Avoca Beach",
                "label_ar": "شاطئ أفوكا",
                "label_fr": "Plage d'Avoca"
            },
            {
                "value": 21877,
                "label": "Weethalle",
                "label_ar": "ويتال",
                "label_fr": "Weethalle"
            },
            {
                "value": 21726,
                "label": "Dalby",
                "label_ar": "دالبي",
                "label_fr": "Dalby"
            },
            {
                "value": 21493,
                "label": "Port Hedland",
                "label_ar": "بورت هيدلاند",
                "label_fr": "Port Hedland"
            },
            {
                "value": 21127,
                "label": "Dubbo",
                "label_ar": "دوبو",
                "label_fr": "Dubbo"
            },
            {
                "value": 21828,
                "label": "Carlton South",
                "label_ar": "كارلتون ساوث",
                "label_fr": "Carlton Sud"
            },
            {
                "value": 21558,
                "label": "Apollo Bay",
                "label_ar": "خليج أبولو",
                "label_fr": "Apollo Bay"
            },
            {
                "value": 21861,
                "label": "Cotham",
                "label_ar": "كوثام",
                "label_fr": "Cotham"
            },
            {
                "value": 20427,
                "label": "Grovedale",
                "label_ar": "جروفديل",
                "label_fr": "Grovedale"
            },
            {
                "value": 21277,
                "label": "Kalgoorlie",
                "label_ar": "كالغورلي",
                "label_fr": "Kalgoorlie"
            },
            {
                "value": 21041,
                "label": "Ulladulla",
                "label_ar": "أولادولا",
                "label_fr": "Ulladulla"
            },
            {
                "value": 20251,
                "label": "Leumeah",
                "label_ar": "لئمة",
                "label_fr": "Leumeah"
            },
            {
                "value": 20934,
                "label": "Bohle Plains",
                "label_ar": "Bohle Plains",
                "label_fr": "Plaines de Bohle"
            },
            {
                "value": 20603,
                "label": "Mount Evelyn",
                "label_ar": "جبل إيفلين",
                "label_fr": "Mont Evelyn"
            },
            {
                "value": 21359,
                "label": "Newry",
                "label_ar": "نيوري",
                "label_fr": "Newry"
            },
            {
                "value": 21967,
                "label": "Wyalong",
                "label_ar": "ويالونج",
                "label_fr": "Wyalong"
            },
            {
                "value": 20652,
                "label": "Cringila",
                "label_ar": "كرينجيلا",
                "label_fr": "Cringila"
            },
            {
                "value": 20449,
                "label": "Cranbourne",
                "label_ar": "كرانبورن",
                "label_fr": "Cranbourne"
            },
            {
                "value": 20492,
                "label": "Unanderra",
                "label_ar": "أوناندرا",
                "label_fr": "Unanderra"
            },
            {
                "value": 20899,
                "label": "Alphington",
                "label_ar": "ألفينجتون",
                "label_fr": "Alphington"
            },
            {
                "value": 20549,
                "label": "Bethania Junction",
                "label_ar": "مفرق بيثانيا",
                "label_fr": "Jonction Bethania"
            },
            {
                "value": 20893,
                "label": "Cardigan",
                "label_ar": "كارديجان",
                "label_fr": "Cardigan"
            },
            {
                "value": 21017,
                "label": "Drayton",
                "label_ar": "درايتون",
                "label_fr": "Drayton"
            },
            {
                "value": 21196,
                "label": "Lilli Pilli",
                "label_ar": "ليلي بيلي",
                "label_fr": "Lilli Pilli"
            },
            {
                "value": 20643,
                "label": "Blair Athol",
                "label_ar": "بلير أثول",
                "label_fr": "Blair Athol"
            },
            {
                "value": 20074,
                "label": "Busselton",
                "label_ar": "بوسلتون",
                "label_fr": "Busselton"
            },
            {
                "value": 21557,
                "label": "Austins Ferry",
                "label_ar": "أوستن فيري",
                "label_fr": "Ferry d'Austins"
            },
            {
                "value": 21117,
                "label": "Carrington",
                "label_ar": "كارينجتون",
                "label_fr": "Carrington"
            },
            {
                "value": 21452,
                "label": "Stroud",
                "label_ar": "ستراود",
                "label_fr": "Stroud"
            },
            {
                "value": 20331,
                "label": "Newport",
                "label_ar": "نيوبورت",
                "label_fr": "Newport"
            },
            {
                "value": 21609,
                "label": "Broome",
                "label_ar": "بروم",
                "label_fr": "Broome"
            },
            {
                "value": 20862,
                "label": "Two Wells",
                "label_ar": "بئرين",
                "label_fr": "Deux puits"
            },
            {
                "value": 20801,
                "label": "Wingham",
                "label_ar": "وينغهام",
                "label_fr": "Wingham"
            },
            {
                "value": 21486,
                "label": "Moorooduc",
                "label_ar": "مورودوك",
                "label_fr": "Moorooduc"
            },
            {
                "value": 21510,
                "label": "Perth",
                "label_ar": "بيرث",
                "label_fr": "Perth"
            },
            {
                "value": 20011,
                "label": "Werribee",
                "label_ar": "Werribee",
                "label_fr": "Werribee"
            },
            {
                "value": 20188,
                "label": "Lakemba",
                "label_ar": "لاكيمبا",
                "label_fr": "Lakemba"
            },
            {
                "value": 20768,
                "label": "Connells Point",
                "label_ar": "كونيلز بوينت",
                "label_fr": "Connells Point"
            },
            {
                "value": 21788,
                "label": "Smythesdale",
                "label_ar": "سميثسدال",
                "label_fr": "Smythesdale"
            },
            {
                "value": 20056,
                "label": "Sandstone Point",
                "label_ar": "نقطة الحجر الرملي",
                "label_fr": "Pointe de grès"
            },
            {
                "value": 20487,
                "label": "Doreen",
                "label_ar": "دورين",
                "label_fr": "Doreen"
            },
            {
                "value": 20105,
                "label": "Bankstown",
                "label_ar": "بانكستاون",
                "label_fr": "Bankstown"
            },
            {
                "value": 21733,
                "label": "Moama",
                "label_ar": "مواما",
                "label_fr": "Moama"
            },
            {
                "value": 21193,
                "label": "Castlemaine",
                "label_ar": "كاسلمين",
                "label_fr": "Castlemaine"
            },
            {
                "value": 20291,
                "label": "Ashburton",
                "label_ar": "اشبورتون",
                "label_fr": "Ashburton"
            },
            {
                "value": 20513,
                "label": "Lyneham",
                "label_ar": "لينهام",
                "label_fr": "Lyneham"
            },
            {
                "value": 20792,
                "label": "Aitkenvale",
                "label_ar": "آيتكينفال",
                "label_fr": "Aitkenvale"
            },
            {
                "value": 21004,
                "label": "Victoria Park",
                "label_ar": "فيكتوريا بارك",
                "label_fr": "Victoria Park"
            },
            {
                "value": 20061,
                "label": "Townsville",
                "label_ar": "تاونسفيل",
                "label_fr": "Townsville"
            },
            {
                "value": 20159,
                "label": "Heatherton",
                "label_ar": "هيثرتون",
                "label_fr": "Heatherton"
            },
            {
                "value": 21029,
                "label": "Kallista",
                "label_ar": "كاليستا",
                "label_fr": "Kallista"
            },
            {
                "value": 20565,
                "label": "Boronia",
                "label_ar": "بورونيا",
                "label_fr": "Boronia"
            },
            {
                "value": 21010,
                "label": "Aberglasslyn",
                "label_ar": "Aberglasslyn",
                "label_fr": "Aberglasslyn"
            },
            {
                "value": 20214,
                "label": "Kellyville",
                "label_ar": "كيليفيل",
                "label_fr": "Kellyville"
            },
            {
                "value": 20663,
                "label": "Haymarket",
                "label_ar": "هايماركت",
                "label_fr": "Haymarket"
            },
            {
                "value": 21422,
                "label": "Greta",
                "label_ar": "جريتا",
                "label_fr": "Greta"
            },
            {
                "value": 20146,
                "label": "Happy Valley",
                "label_ar": "الوادي السعيد",
                "label_fr": "Happy Valley"
            },
            {
                "value": 20624,
                "label": "Burnett Heads",
                "label_ar": "رؤساء بورنيت",
                "label_fr": "Têtes de Burnett"
            },
            {
                "value": 21952,
                "label": "Mount Molloy",
                "label_ar": "جبل مولوي",
                "label_fr": "Mont Molloy"
            },
            {
                "value": 21270,
                "label": "Uraidla",
                "label_ar": "أوريدلا",
                "label_fr": "Uraidla"
            },
            {
                "value": 21058,
                "label": "Elderslie",
                "label_ar": "إلدرسلي",
                "label_fr": "Elderslie"
            },
            {
                "value": 19978,
                "label": "Flinders",
                "label_ar": "فليندرز",
                "label_fr": "Flinders"
            },
            {
                "value": 21781,
                "label": "Pechey",
                "label_ar": "بيتشي",
                "label_fr": "Pechey"
            },
            {
                "value": 20428,
                "label": "Bendigo",
                "label_ar": "بنديجو",
                "label_fr": "Bendigo"
            },
            {
                "value": 20400,
                "label": "McKinnon",
                "label_ar": "ماكينون",
                "label_fr": "McKinnon"
            },
            {
                "value": 21461,
                "label": "Thirlmere",
                "label_ar": "ثيرلمير",
                "label_fr": "Thirlmere"
            },
            {
                "value": 21099,
                "label": "Deloraine",
                "label_ar": "ديلورين",
                "label_fr": "Deloraine"
            },
            {
                "value": 21166,
                "label": "West Burleigh",
                "label_ar": "غرب بيرلي",
                "label_fr": "West Burleigh"
            },
            {
                "value": 20944,
                "label": "Faulconbridge",
                "label_ar": "فولكونبريدج",
                "label_fr": "Faulconbridge"
            },
            {
                "value": 21016,
                "label": "Deagon",
                "label_ar": "ديجون",
                "label_fr": "Deagon"
            },
            {
                "value": 21657,
                "label": "Denman",
                "label_ar": "دينمان",
                "label_fr": "Denman"
            },
            {
                "value": 20015,
                "label": "Victoria Point",
                "label_ar": "فيكتوريا بوينت",
                "label_fr": "Victoria Point"
            },
            {
                "value": 20333,
                "label": "Thomastown",
                "label_ar": "توماستاون",
                "label_fr": "Thomastown"
            },
            {
                "value": 20189,
                "label": "Kogarah",
                "label_ar": "كوجارا",
                "label_fr": "Kogarah"
            },
            {
                "value": 21541,
                "label": "Silverdale",
                "label_ar": "سيلفرديل",
                "label_fr": "Silverdale"
            },
            {
                "value": 21717,
                "label": "Bridgewater",
                "label_ar": "بريدجووتر",
                "label_fr": "Bridgewater"
            },
            {
                "value": 20512,
                "label": "Woolloomooloo",
                "label_ar": "وولومولو",
                "label_fr": "Woolloomooloo"
            },
            {
                "value": 20936,
                "label": "Browns Plains",
                "label_ar": "براونز بلينز",
                "label_fr": "Browns Plains"
            },
            {
                "value": 20726,
                "label": "Batesford",
                "label_ar": "باتسفورد",
                "label_fr": "Batesford"
            },
            {
                "value": 19999,
                "label": "Elizabeth",
                "label_ar": "إليزابيث",
                "label_fr": "Elizabeth"
            },
            {
                "value": 21044,
                "label": "Manning Point",
                "label_ar": "مانينغ بوينت",
                "label_fr": "Point de Manning"
            },
            {
                "value": 21870,
                "label": "Temora",
                "label_ar": "تيمورا",
                "label_fr": "Témora"
            },
            {
                "value": 21334,
                "label": "Garfield",
                "label_ar": "غارفيلد",
                "label_fr": "Garfield"
            },
            {
                "value": 21849,
                "label": "Campbelltown",
                "label_ar": "كامبلتاون",
                "label_fr": "Campbelltown"
            },
            {
                "value": 20714,
                "label": "Rutherford",
                "label_ar": "رذرفورد",
                "label_fr": "Rutherford"
            },
            {
                "value": 20764,
                "label": "Kirrawee",
                "label_ar": "قيراوي",
                "label_fr": "Kirrawee"
            },
            {
                "value": 21593,
                "label": "Zeehan",
                "label_ar": "زيهان",
                "label_fr": "Zeehan"
            },
            {
                "value": 21370,
                "label": "Clyde North",
                "label_ar": "كلايد نورث",
                "label_fr": "Clyde Nord"
            },
            {
                "value": 21217,
                "label": "Sarina",
                "label_ar": "سارينا",
                "label_fr": "Sarina"
            },
            {
                "value": 20904,
                "label": "Longueville",
                "label_ar": "لونجفيل",
                "label_fr": "Longueville"
            },
            {
                "value": 21088,
                "label": "Kiama",
                "label_ar": "كياما",
                "label_fr": "Kiama"
            },
            {
                "value": 21898,
                "label": "Gravesend",
                "label_ar": "جريفسيند",
                "label_fr": "Gravesend"
            },
            {
                "value": 20968,
                "label": "Thargomindah",
                "label_ar": "ثارجوميندا",
                "label_fr": "Thargomindah"
            },
            {
                "value": 20687,
                "label": "Vineyard",
                "label_ar": "حقل عنب",
                "label_fr": "Vignoble"
            },
            {
                "value": 20873,
                "label": "Red Hill",
                "label_ar": "التل الأحمر",
                "label_fr": "colline rouge"
            },
            {
                "value": 20147,
                "label": "Bedford Park",
                "label_ar": "بيدفورد بارك",
                "label_fr": "Bedford Park"
            },
            {
                "value": 21811,
                "label": "Everton",
                "label_ar": "إيفرتون",
                "label_fr": "Everton"
            },
            {
                "value": 21946,
                "label": "Saint George",
                "label_ar": "القديس جورج",
                "label_fr": "Saint George"
            },
            {
                "value": 21527,
                "label": "Green Hill",
                "label_ar": "تلة خضراء",
                "label_fr": "Colline verte"
            },
            {
                "value": 21402,
                "label": "Picnic Bay",
                "label_ar": "بيكنيك باي",
                "label_fr": "Picnic Bay"
            },
            {
                "value": 20777,
                "label": "Grange",
                "label_ar": "جرانج",
                "label_fr": "Grange"
            },
            {
                "value": 20104,
                "label": "Galston",
                "label_ar": "جالستون",
                "label_fr": "Galston"
            },
            {
                "value": 21242,
                "label": "Macedon",
                "label_ar": "ماسيدون",
                "label_fr": "Macédoine"
            },
            {
                "value": 21701,
                "label": "Muswellbrook",
                "label_ar": "موسويلبروك",
                "label_fr": "Muswellbrook"
            },
            {
                "value": 20821,
                "label": "Lower Plenty",
                "label_ar": "وفيرة منخفضة",
                "label_fr": "Abaisser l'abondance"
            },
            {
                "value": 20571,
                "label": "Minlaton",
                "label_ar": "مينلاتون",
                "label_fr": "Minlaton"
            },
            {
                "value": 20650,
                "label": "Two Rocks",
                "label_ar": "اثنين من الصخور",
                "label_fr": "Deux roches"
            },
            {
                "value": 20698,
                "label": "Riverview",
                "label_ar": "اطلالة النهر",
                "label_fr": "Vue de la rivière"
            },
            {
                "value": 20380,
                "label": "Balwyn North",
                "label_ar": "بالوين الشمالية",
                "label_fr": "Balwyn Nord"
            },
            {
                "value": 21540,
                "label": "Nanango",
                "label_ar": "نانانجو",
                "label_fr": "Nanango"
            },
            {
                "value": 21408,
                "label": "Whyalla",
                "label_ar": "لماذا",
                "label_fr": "Whyalla"
            },
            {
                "value": 21514,
                "label": "Merriwa",
                "label_ar": "مريوا",
                "label_fr": "Merriwa"
            },
            {
                "value": 21893,
                "label": "Tuggeranong",
                "label_ar": "توجيرانونج",
                "label_fr": "Tuggeranong"
            },
            {
                "value": 21894,
                "label": "City of West Torrens",
                "label_ar": "مدينة ويست تورينس",
                "label_fr": "Ville de West Torrens"
            },
            {
                "value": 20510,
                "label": "Artarmon",
                "label_ar": "ارتارمون",
                "label_fr": "Artarmon"
            },
            {
                "value": 20659,
                "label": "Illawong",
                "label_ar": "غير مناسب",
                "label_fr": "Illawong"
            },
            {
                "value": 20004,
                "label": "Salisbury",
                "label_ar": "سالزبوري",
                "label_fr": "Salisbury"
            },
            {
                "value": 20532,
                "label": "Coniston",
                "label_ar": "كونيستون",
                "label_fr": "Coniston"
            },
            {
                "value": 20849,
                "label": "Bellerive",
                "label_ar": "بيلريف",
                "label_fr": "Bellerive"
            },
            {
                "value": 20031,
                "label": "Jimboomba",
                "label_ar": "جيمبومبا",
                "label_fr": "Jimboomba"
            },
            {
                "value": 20193,
                "label": "Randwick",
                "label_ar": "راندويك",
                "label_fr": "Randwick"
            },
            {
                "value": 21512,
                "label": "Turners Beach",
                "label_ar": "شاطئ تيرنرز",
                "label_fr": "Plage de Turners"
            },
            {
                "value": 21983,
                "label": "Cooktown",
                "label_ar": "كوكتاون",
                "label_fr": "Cooktown"
            },
            {
                "value": 19963,
                "label": "Beerwah",
                "label_ar": "البيرة",
                "label_fr": "Beerwah"
            },
            {
                "value": 20526,
                "label": "Lisarow",
                "label_ar": "ليسارو",
                "label_fr": "Lisarow"
            },
            {
                "value": 20788,
                "label": "Bargo",
                "label_ar": "بارجو",
                "label_fr": "Bargo"
            },
            {
                "value": 20824,
                "label": "Karalee",
                "label_ar": "كارالي",
                "label_fr": "Karalee"
            },
            {
                "value": 20483,
                "label": "Leopold",
                "label_ar": "ليوبولد",
                "label_fr": "Léopold"
            },
            {
                "value": 21528,
                "label": "Thagoona",
                "label_ar": "ثاغونا",
                "label_fr": "Thagoona"
            },
            {
                "value": 20708,
                "label": "Rochedale",
                "label_ar": "روشيدال",
                "label_fr": "Rochedale"
            },
            {
                "value": 20920,
                "label": "The Entrance",
                "label_ar": "المدخل",
                "label_fr": "L'entrée"
            },
            {
                "value": 21896,
                "label": "Dunmore",
                "label_ar": "دنمور",
                "label_fr": "Dunmore"
            },
            {
                "value": 20348,
                "label": "Sunbury",
                "label_ar": "صنبيري",
                "label_fr": "Sunbury"
            },
            {
                "value": 21662,
                "label": "Inverell",
                "label_ar": "إنفيريل",
                "label_fr": "Inverell"
            },
            {
                "value": 21345,
                "label": "Sturt",
                "label_ar": "ستورت",
                "label_fr": "Sturt"
            },
            {
                "value": 21731,
                "label": "Wongan Hills",
                "label_ar": "تلال ونجان",
                "label_fr": "Wongan Hills"
            },
            {
                "value": 20813,
                "label": "Woori Yallock",
                "label_ar": "ووري يالوك",
                "label_fr": "Woori Yallock"
            },
            {
                "value": 20995,
                "label": "Spearwood",
                "label_ar": "سبيروود",
                "label_fr": "Spearwood"
            },
            {
                "value": 20009,
                "label": "Epping",
                "label_ar": "إبينج",
                "label_fr": "Epping"
            },
            {
                "value": 20290,
                "label": "Burwood",
                "label_ar": "بيروود",
                "label_fr": "Burwood"
            },
            {
                "value": 21116,
                "label": "Colo",
                "label_ar": "كولو",
                "label_fr": "Colo"
            },
            {
                "value": 21146,
                "label": "Montrose",
                "label_ar": "مونتروز",
                "label_fr": "Montrose"
            },
            {
                "value": 20098,
                "label": "Magill",
                "label_ar": "ماجيل",
                "label_fr": "Magill"
            },
            {
                "value": 21067,
                "label": "Lennox Head",
                "label_ar": "لينوكس هيد",
                "label_fr": "Tête de Lennox"
            },
            {
                "value": 20586,
                "label": "Beaumaris",
                "label_ar": "بوماريس",
                "label_fr": "Beaumaris"
            },
            {
                "value": 21168,
                "label": "Kootingal",
                "label_ar": "Kootingal",
                "label_fr": "Kootingal"
            },
            {
                "value": 20693,
                "label": "Ives",
                "label_ar": "ايفيس",
                "label_fr": "Ives"
            },
            {
                "value": 20561,
                "label": "Tugun",
                "label_ar": "توجون",
                "label_fr": "Tugun"
            },
            {
                "value": 20265,
                "label": "Ballarat",
                "label_ar": "بالارات",
                "label_fr": "Ballarat"
            },
            {
                "value": 20059,
                "label": "Narangba",
                "label_ar": "نارانجبا",
                "label_fr": "Narangba"
            },
            {
                "value": 20754,
                "label": "Lake Munmorah",
                "label_ar": "بحيرة مونمرة",
                "label_fr": "Lac Munmorah"
            },
            {
                "value": 20392,
                "label": "Tuncurry",
                "label_ar": "تونكوري",
                "label_fr": "Tuncurry"
            },
            {
                "value": 20410,
                "label": "Wahroonga",
                "label_ar": "واهرونجا",
                "label_fr": "Wahroonga"
            },
            {
                "value": 20915,
                "label": "Kew",
                "label_ar": "كيو",
                "label_fr": "Kew"
            },
            {
                "value": 21184,
                "label": "Olinda",
                "label_ar": "أوليندا",
                "label_fr": "Olinda"
            },
            {
                "value": 21269,
                "label": "Barcoo",
                "label_ar": "باركو",
                "label_fr": "Barcoo"
            },
            {
                "value": 21019,
                "label": "Tannum Sands",
                "label_ar": "رمال تانوم",
                "label_fr": "Tannum Sands"
            },
            {
                "value": 20140,
                "label": "Hackham",
                "label_ar": "هاكهام",
                "label_fr": "Hackham"
            },
            {
                "value": 21356,
                "label": "Flynn",
                "label_ar": "فلين",
                "label_fr": "Flynn"
            },
            {
                "value": 21748,
                "label": "Highfields",
                "label_ar": "هايفيلدز",
                "label_fr": "Highfields"
            },
            {
                "value": 21327,
                "label": "Hollow Tree",
                "label_ar": "شجرة جوفاء",
                "label_fr": "Arbre creux"
            },
            {
                "value": 21891,
                "label": "Eaton",
                "label_ar": "إيتون",
                "label_fr": "Eaton"
            },
            {
                "value": 21606,
                "label": "Wedderburn",
                "label_ar": "ويديربيرن",
                "label_fr": "Wedderburn"
            },
            {
                "value": 21109,
                "label": "Waikerie",
                "label_ar": "ويكيري",
                "label_fr": "Waikerie"
            },
            {
                "value": 21335,
                "label": "Inverloch",
                "label_ar": "إينفيرلوخ",
                "label_fr": "Inverloch"
            },
            {
                "value": 20829,
                "label": "Medowie",
                "label_ar": "ميدوي",
                "label_fr": "Medowie"
            },
            {
                "value": 20023,
                "label": "Paradise",
                "label_ar": "الجنة",
                "label_fr": "paradis"
            },
            {
                "value": 20669,
                "label": "Traralgon",
                "label_ar": "ترارالجون",
                "label_fr": "Traralgon"
            },
            {
                "value": 20563,
                "label": "Eagleby",
                "label_ar": "إيجلبي",
                "label_fr": "Eagleby"
            },
            {
                "value": 20062,
                "label": "Maryborough",
                "label_ar": "ماريبوروه",
                "label_fr": "Maryborough"
            },
            {
                "value": 21600,
                "label": "Evandale",
                "label_ar": "إيفاندال",
                "label_fr": "Evandale"
            },
            {
                "value": 20690,
                "label": "Kurnell",
                "label_ar": "كورنيل",
                "label_fr": "Kurnell"
            },
            {
                "value": 21813,
                "label": "Wyalkatchem",
                "label_ar": "ويالكتشيم",
                "label_fr": "Wyalkatchem"
            },
            {
                "value": 21222,
                "label": "Willaura",
                "label_ar": "ويلورا",
                "label_fr": "Willaura"
            },
            {
                "value": 21198,
                "label": "Proserpine",
                "label_ar": "بروسيربين",
                "label_fr": "Proserpine"
            },
            {
                "value": 21149,
                "label": "Lamington",
                "label_ar": "لامينجتون",
                "label_fr": "Lamington"
            },
            {
                "value": 21559,
                "label": "Ararat",
                "label_ar": "أرارات",
                "label_fr": "Ararat"
            },
            {
                "value": 21801,
                "label": "Walhalla",
                "label_ar": "والله",
                "label_fr": "Walhalla"
            },
            {
                "value": 20967,
                "label": "Murrumbateman",
                "label_ar": "موروماتمان",
                "label_fr": "Murrumbateman"
            },
            {
                "value": 21438,
                "label": "Port Hacking",
                "label_ar": "منفذ القرصنة",
                "label_fr": "Piratage de ports"
            },
            {
                "value": 21499,
                "label": "Cawarral",
                "label_ar": "كاوارال",
                "label_fr": "Cawarral"
            },
            {
                "value": 20206,
                "label": "Ingleburn",
                "label_ar": "إنجليبيرن",
                "label_fr": "Ingleburn"
            },
            {
                "value": 21060,
                "label": "Wacol",
                "label_ar": "واكول",
                "label_fr": "Wacol"
            },
            {
                "value": 21914,
                "label": "Austinmer",
                "label_ar": "أوستنمر",
                "label_fr": "Austinmer"
            },
            {
                "value": 21122,
                "label": "Narromine",
                "label_ar": "نارومين",
                "label_fr": "Narromine"
            },
            {
                "value": 20329,
                "label": "Tullamarine",
                "label_ar": "تولامارين",
                "label_fr": "Tullamarine"
            },
            {
                "value": 20155,
                "label": "Ulverstone",
                "label_ar": "أولفيرستون",
                "label_fr": "Ulverstone"
            },
            {
                "value": 21991,
                "label": "Mount Keira",
                "label_ar": "جبل كيرا",
                "label_fr": "Mont Keira"
            },
            {
                "value": 20212,
                "label": "Lindfield",
                "label_ar": "ليندفيلد",
                "label_fr": "Lindfield"
            },
            {
                "value": 21863,
                "label": "Hexham",
                "label_ar": "هيكسهام",
                "label_fr": "Hexham"
            },
            {
                "value": 20093,
                "label": "Darwin",
                "label_ar": "داروين",
                "label_fr": "Darwin"
            },
            {
                "value": 21091,
                "label": "Willunga",
                "label_ar": "ويلونجا",
                "label_fr": "Willunga"
            },
            {
                "value": 20132,
                "label": "Chatswood",
                "label_ar": "شاتسوود",
                "label_fr": "Chatswood"
            },
            {
                "value": 21481,
                "label": "Scarsdale",
                "label_ar": "سكارسديل",
                "label_fr": "Scarsdale"
            },
            {
                "value": 20198,
                "label": "Sans Souci",
                "label_ar": "سانس سوسي",
                "label_fr": "Sans Souci"
            },
            {
                "value": 21181,
                "label": "Indented Head",
                "label_ar": "رأس بادئة",
                "label_fr": "Tête en retrait"
            },
            {
                "value": 20989,
                "label": "Wayville",
                "label_ar": "وايفيل",
                "label_fr": "Wayville"
            },
            {
                "value": 21098,
                "label": "Cygnet",
                "label_ar": "Cygnet",
                "label_fr": "Jeune Cygne"
            },
            {
                "value": 20176,
                "label": "Quakers Hill",
                "label_ar": "كويكرز هيل",
                "label_fr": "Quakers Hill"
            },
            {
                "value": 20886,
                "label": "Cardup",
                "label_ar": "كاردوب",
                "label_fr": "Cardup"
            },
            {
                "value": 19981,
                "label": "Merewether",
                "label_ar": "Merewether",
                "label_fr": "Merewether"
            },
            {
                "value": 20721,
                "label": "West End",
                "label_ar": "نهاية الغرب",
                "label_fr": "extremite ouest"
            },
            {
                "value": 21317,
                "label": "Mirani",
                "label_ar": "ميراني",
                "label_fr": "Mirani"
            },
            {
                "value": 21344,
                "label": "Judbury",
                "label_ar": "جودبوري",
                "label_fr": "Judbury"
            },
            {
                "value": 21876,
                "label": "Tom Price",
                "label_ar": "توم برايس",
                "label_fr": "Tom Price"
            },
            {
                "value": 20397,
                "label": "South Yarra",
                "label_ar": "جنوب يارا",
                "label_fr": "South Yarra"
            },
            {
                "value": 19965,
                "label": "Tweed Heads",
                "label_ar": "رؤوس تويد",
                "label_fr": "Têtes en tweed"
            },
            {
                "value": 21534,
                "label": "Biloela",
                "label_ar": "بيلويلا",
                "label_fr": "Biloela"
            },
            {
                "value": 21816,
                "label": "Exmouth",
                "label_ar": "اكسماوث",
                "label_fr": "Exmouth"
            },
            {
                "value": 21730,
                "label": "Weston Creek",
                "label_ar": "ويستون كريك",
                "label_fr": "Weston Creek"
            },
            {
                "value": 21318,
                "label": "Kenilworth",
                "label_ar": "كينيلورث",
                "label_fr": "Kenilworth"
            },
            {
                "value": 20243,
                "label": "Camden",
                "label_ar": "كامدن",
                "label_fr": "Camden"
            },
            {
                "value": 21798,
                "label": "Braddon",
                "label_ar": "برادون",
                "label_fr": "Braddon"
            },
            {
                "value": 21321,
                "label": "Kerang",
                "label_ar": "كيرانج",
                "label_fr": "Kerang"
            },
            {
                "value": 21598,
                "label": "Windsor Downs",
                "label_ar": "وندسور داونز",
                "label_fr": "Windsor Downs"
            },
            {
                "value": 21529,
                "label": "Tully",
                "label_ar": "تولي",
                "label_fr": "Tully"
            },
            {
                "value": 20888,
                "label": "The Oaks",
                "label_ar": "أوكس",
                "label_fr": "Les chênes"
            },
            {
                "value": 20979,
                "label": "Yowie Bay",
                "label_ar": "خليج Yowie",
                "label_fr": "Yowie Bay"
            },
            {
                "value": 20589,
                "label": "Bulleen",
                "label_ar": "بوللين",
                "label_fr": "Bulleen"
            },
            {
                "value": 20897,
                "label": "Newmarket",
                "label_ar": "سوق جديد",
                "label_fr": "Nouveau marché"
            },
            {
                "value": 21069,
                "label": "East Maitland",
                "label_ar": "شرق ميتلاند",
                "label_fr": "East Maitland"
            },
            {
                "value": 20107,
                "label": "Balgowlah",
                "label_ar": "بالجولة",
                "label_fr": "Balgowlah"
            },
            {
                "value": 20546,
                "label": "Windermere Park",
                "label_ar": "ويندرمير بارك",
                "label_fr": "Parc Windermere"
            },
            {
                "value": 21584,
                "label": "Tincurrin",
                "label_ar": "تينكورين",
                "label_fr": "Tincurrin"
            },
            {
                "value": 20620,
                "label": "Ormeau",
                "label_ar": "أورمو",
                "label_fr": "Ormeau"
            },
            {
                "value": 20458,
                "label": "Beenleigh",
                "label_ar": "بينلي",
                "label_fr": "Beenleigh"
            },
            {
                "value": 20984,
                "label": "Meadows",
                "label_ar": "المراعي",
                "label_fr": "Prairies"
            },
            {
                "value": 21990,
                "label": "Mount White",
                "label_ar": "جبل وايت",
                "label_fr": "Mont Blanc"
            },
            {
                "value": 21591,
                "label": "Baringhup",
                "label_ar": "بارينغوب",
                "label_fr": "Baringhup"
            },
            {
                "value": 21942,
                "label": "Lyons",
                "label_ar": "ليونز",
                "label_fr": "Lyon"
            },
            {
                "value": 21369,
                "label": "Toorbul",
                "label_ar": "طوربل",
                "label_fr": "Toorbul"
            },
            {
                "value": 20368,
                "label": "Alexandra",
                "label_ar": "الكسندرا",
                "label_fr": "Alexandra"
            },
            {
                "value": 20297,
                "label": "Mount Waverley",
                "label_ar": "جبل ويفرلي",
                "label_fr": "Mont Waverley"
            },
            {
                "value": 21262,
                "label": "Anna Bay",
                "label_ar": "آنا باي",
                "label_fr": "Anna Bay"
            },
            {
                "value": 21573,
                "label": "Cummins",
                "label_ar": "الكمون",
                "label_fr": "Cummins"
            },
            {
                "value": 21676,
                "label": "Burraneer",
                "label_ar": "بورانير",
                "label_fr": "Burraneer"
            },
            {
                "value": 20935,
                "label": "Bushland Beach",
                "label_ar": "شاطئ بوشلاند",
                "label_fr": "Plage de Bushland"
            },
            {
                "value": 21779,
                "label": "Argents Hill",
                "label_ar": "أرجنتس هيل",
                "label_fr": "Argents Hill"
            },
            {
                "value": 21784,
                "label": "Brown Hill",
                "label_ar": "براون هيل",
                "label_fr": "Brown Hill"
            },
            {
                "value": 21141,
                "label": "Monterey",
                "label_ar": "مونتيري",
                "label_fr": "Monterey"
            },
            {
                "value": 20362,
                "label": "Preston",
                "label_ar": "بريستون",
                "label_fr": "Preston"
            },
            {
                "value": 21878,
                "label": "Coober Pedy",
                "label_ar": "كوبر بيدي",
                "label_fr": "Coober Pedy"
            },
            {
                "value": 21537,
                "label": "Wamberal",
                "label_ar": "وامبيرال",
                "label_fr": "Wamberal"
            },
            {
                "value": 20820,
                "label": "Darley",
                "label_ar": "دارلي",
                "label_fr": "Darley"
            },
            {
                "value": 21720,
                "label": "Lauderdale",
                "label_ar": "لودرديل",
                "label_fr": "Lauderdale"
            },
            {
                "value": 20484,
                "label": "Bellambi",
                "label_ar": "بيلامبي",
                "label_fr": "Bellambi"
            },
            {
                "value": 20044,
                "label": "Toogoom",
                "label_ar": "Toogoom",
                "label_fr": "Toogoom"
            },
            {
                "value": 21395,
                "label": "Lakes Entrance",
                "label_ar": "مدخل البحيرات",
                "label_fr": "Entrée des lacs"
            },
            {
                "value": 20385,
                "label": "Waterloo",
                "label_ar": "واترلو",
                "label_fr": "Waterloo"
            },
            {
                "value": 21555,
                "label": "Buckland",
                "label_ar": "باكلاند",
                "label_fr": "Buckland"
            },
            {
                "value": 21771,
                "label": "Coutts Crossing",
                "label_ar": "معبر كوتس",
                "label_fr": "Traversée de Coutts"
            },
            {
                "value": 20204,
                "label": "Bronte",
                "label_ar": "برونتي",
                "label_fr": "Bronte"
            },
            {
                "value": 21208,
                "label": "Pearce",
                "label_ar": "بيرس",
                "label_fr": "Pearce"
            },
            {
                "value": 21435,
                "label": "Kings Park",
                "label_ar": "حديقة الملوك",
                "label_fr": "Kings Park"
            },
            {
                "value": 21886,
                "label": "Mangalore",
                "label_ar": "مانجالور",
                "label_fr": "Mangalore"
            },
            {
                "value": 20635,
                "label": "Findon",
                "label_ar": "فيندون",
                "label_fr": "Findon"
            },
            {
                "value": 20143,
                "label": "Mitcham",
                "label_ar": "ميتشام",
                "label_fr": "Mitcham"
            },
            {
                "value": 20295,
                "label": "Warrandyte",
                "label_ar": "وارانديت",
                "label_fr": "Warrandyte"
            },
            {
                "value": 20161,
                "label": "Heidelberg",
                "label_ar": "هايدلبرغ",
                "label_fr": "Heidelberg"
            },
            {
                "value": 19967,
                "label": "Redbank",
                "label_ar": "ريدبانك",
                "label_fr": "Redbank"
            },
            {
                "value": 20761,
                "label": "Sandgate",
                "label_ar": "Sandgate",
                "label_fr": "Sandgate"
            },
            {
                "value": 21605,
                "label": "Mount Egerton",
                "label_ar": "جبل إجيرتون",
                "label_fr": "Mont Egerton"
            },
            {
                "value": 21462,
                "label": "Oakdale",
                "label_ar": "اوكديل",
                "label_fr": "Oakdale"
            },
            {
                "value": 20094,
                "label": "Aldinga Beach",
                "label_ar": "شاطئ ألدينجا",
                "label_fr": "Plage d'Aldinga"
            },
            {
                "value": 21790,
                "label": "Cockburn",
                "label_ar": "كوكبيرن",
                "label_fr": "Cockburn"
            },
            {
                "value": 20506,
                "label": "Toronto",
                "label_ar": "تورنتو",
                "label_fr": "Toronto"
            },
            {
                "value": 20929,
                "label": "Meredith",
                "label_ar": "ميريديث",
                "label_fr": "Meredith"
            },
            {
                "value": 20736,
                "label": "Newtown",
                "label_ar": "بلدة جديدة",
                "label_fr": "Nouvelle ville"
            },
            {
                "value": 20076,
                "label": "Coogee",
                "label_ar": "كوجي",
                "label_fr": "Coogee"
            },
            {
                "value": 20576,
                "label": "Mont Albert North",
                "label_ar": "مونت ألبرت نورث",
                "label_fr": "Mont Albert Nord"
            },
            {
                "value": 21928,
                "label": "Yarraman",
                "label_ar": "يارامان",
                "label_fr": "Yarraman"
            },
            {
                "value": 21442,
                "label": "Nerrina",
                "label_ar": "نيرينا",
                "label_fr": "Nerrina"
            },
            {
                "value": 20401,
                "label": "Subiaco",
                "label_ar": "سوبياكو",
                "label_fr": "Subiaco"
            },
            {
                "value": 20106,
                "label": "Newcastle",
                "label_ar": "نيوكاسل",
                "label_fr": "Newcastle"
            },
            {
                "value": 21631,
                "label": "Wilmot",
                "label_ar": "ويلموت",
                "label_fr": "Wilmot"
            },
            {
                "value": 21673,
                "label": "Trinity Beach",
                "label_ar": "ترينيتي بيتش",
                "label_fr": "Plage de la Trinité"
            },
            {
                "value": 21586,
                "label": "Beverley",
                "label_ar": "بيفرلي",
                "label_fr": "Beverley"
            },
            {
                "value": 21903,
                "label": "Piawaning",
                "label_ar": "بياوانينغ",
                "label_fr": "Piawaning"
            },
            {
                "value": 20468,
                "label": "Suffolk Park",
                "label_ar": "حديقة سوفولك",
                "label_fr": "Parc Suffolk"
            },
            {
                "value": 20142,
                "label": "Modbury",
                "label_ar": "مودبري",
                "label_fr": "Modbury"
            },
            {
                "value": 20354,
                "label": "Berwick",
                "label_ar": "بيرويك",
                "label_fr": "Berwick"
            },
            {
                "value": 21875,
                "label": "Marian",
                "label_ar": "ماريان",
                "label_fr": "Marian"
            },
            {
                "value": 21674,
                "label": "Caravonica",
                "label_ar": "كارافونيكا",
                "label_fr": "Caravonica"
            },
            {
                "value": 20308,
                "label": "Ringwood",
                "label_ar": "رينجوود",
                "label_fr": "Ringwood"
            },
            {
                "value": 20454,
                "label": "Belmont",
                "label_ar": "بلمونت",
                "label_fr": "Belmont"
            },
            {
                "value": 21563,
                "label": "Ravenswood",
                "label_ar": "رافنسوود",
                "label_fr": "Ravenswood"
            },
            {
                "value": 20249,
                "label": "Hornsby",
                "label_ar": "هورنزبي",
                "label_fr": "Hornsby"
            },
            {
                "value": 21550,
                "label": "Scarness",
                "label_ar": "الندرة",
                "label_fr": "Scarness"
            },
            {
                "value": 20197,
                "label": "Liverpool",
                "label_ar": "ليفربول",
                "label_fr": "Liverpool"
            },
            {
                "value": 20951,
                "label": "Stockton",
                "label_ar": "ستوكتون",
                "label_fr": "Stockton"
            },
            {
                "value": 21311,
                "label": "Echuca",
                "label_ar": "إتشوكا",
                "label_fr": "Echuca"
            },
            {
                "value": 20174,
                "label": "Saint Leonards",
                "label_ar": "سانت ليوناردز",
                "label_fr": "Saint Léonards"
            },
            {
                "value": 21548,
                "label": "Kendall",
                "label_ar": "كيندال",
                "label_fr": "Kendall"
            },
            {
                "value": 21307,
                "label": "Benambra",
                "label_ar": "بنامبرا",
                "label_fr": "Benambra"
            },
            {
                "value": 20302,
                "label": "Kew",
                "label_ar": "كيو",
                "label_fr": "Kew"
            },
            {
                "value": 20501,
                "label": "Emerald",
                "label_ar": "زمرد",
                "label_fr": "émeraude"
            },
            {
                "value": 20084,
                "label": "Bridgetown",
                "label_ar": "بريدجتاون",
                "label_fr": "Bridgetown"
            },
            {
                "value": 21885,
                "label": "Whitfield",
                "label_ar": "ويتفيلد",
                "label_fr": "Whitfield"
            },
            {
                "value": 20828,
                "label": "Crescent Head",
                "label_ar": "رأس الهلال",
                "label_fr": "Tête de croissant"
            },
            {
                "value": 20775,
                "label": "Highbury",
                "label_ar": "هايبري",
                "label_fr": "Highbury"
            },
            {
                "value": 20433,
                "label": "Hughesdale",
                "label_ar": "هيوزديل",
                "label_fr": "Hughesdale"
            },
            {
                "value": 20746,
                "label": "Eschol",
                "label_ar": "إسكول",
                "label_fr": "Eschol"
            },
            {
                "value": 21236,
                "label": "Coolup",
                "label_ar": "تبريد تصل",
                "label_fr": "Refroidir"
            },
            {
                "value": 21374,
                "label": "Blackheath",
                "label_ar": "بلاكهيث",
                "label_fr": "Blackheath"
            },
            {
                "value": 21734,
                "label": "White Rock",
                "label_ar": "صخرة بيضاء",
                "label_fr": "White Rock"
            },
            {
                "value": 20642,
                "label": "Encounter Bay",
                "label_ar": "لقاء خليج",
                "label_fr": "Rencontre Bay"
            },
            {
                "value": 21646,
                "label": "Belgian Gardens",
                "label_ar": "حدائق بلجيكية",
                "label_fr": "Jardins belges"
            },
            {
                "value": 20898,
                "label": "Gayndah",
                "label_ar": "غينده",
                "label_fr": "Gayndah"
            },
            {
                "value": 20832,
                "label": "Palmwoods",
                "label_ar": "النخيل",
                "label_fr": "Palmiers"
            },
            {
                "value": 20040,
                "label": "Park Ridge",
                "label_ar": "بارك ريدج",
                "label_fr": "Park Ridge"
            },
            {
                "value": 20937,
                "label": "Bongaree",
                "label_ar": "بونجاري",
                "label_fr": "Bongaree"
            },
            {
                "value": 20867,
                "label": "Laang",
                "label_ar": "لانج",
                "label_fr": "Laang"
            },
            {
                "value": 20851,
                "label": "Glenorchy",
                "label_ar": "جلينورتشي",
                "label_fr": "Glenorchy"
            },
            {
                "value": 20816,
                "label": "Nindaroo",
                "label_ar": "نيندارو",
                "label_fr": "Nindaroo"
            },
            {
                "value": 20460,
                "label": "Kensington and Norwood",
                "label_ar": "كنسينغتون ونوروود",
                "label_fr": "Kensington et Norwood"
            },
            {
                "value": 21648,
                "label": "Seven Mile Beach",
                "label_ar": "سيفن مايل بيتش",
                "label_fr": "Seven Mile Beach"
            },
            {
                "value": 20709,
                "label": "Gin Gin",
                "label_ar": "الجن الجن",
                "label_fr": "Gin Gin"
            },
            {
                "value": 20359,
                "label": "Bonbeach",
                "label_ar": "بونبيتش",
                "label_fr": "Bonbeach"
            },
            {
                "value": 20182,
                "label": "Rooty Hill",
                "label_ar": "روتي هيل",
                "label_fr": "Rooty Hill"
            },
            {
                "value": 20844,
                "label": "Margate",
                "label_ar": "مارجيت",
                "label_fr": "Margate"
            },
            {
                "value": 21667,
                "label": "Campbell",
                "label_ar": "كامبل",
                "label_fr": "Campbell"
            },
            {
                "value": 20625,
                "label": "Redland Bay",
                "label_ar": "خليج ريدلاند",
                "label_fr": "Redland Bay"
            },
            {
                "value": 21615,
                "label": "Ulmarra",
                "label_ar": "أولمارا",
                "label_fr": "Ulmarra"
            },
            {
                "value": 20695,
                "label": "Redlynch",
                "label_ar": "ريدلينش",
                "label_fr": "Redlynch"
            },
            {
                "value": 21817,
                "label": "Esperance",
                "label_ar": "الترجي",
                "label_fr": "Espérance"
            },
            {
                "value": 20639,
                "label": "Marong",
                "label_ar": "مارونج",
                "label_fr": "Marong"
            },
            {
                "value": 21293,
                "label": "Yaamba",
                "label_ar": "Yaamba",
                "label_fr": "Yaamba"
            },
            {
                "value": 21351,
                "label": "Ebenezer",
                "label_ar": "إبنيزر",
                "label_fr": "Ebenezer"
            },
            {
                "value": 20298,
                "label": "Hawthorn",
                "label_ar": "الزعرور",
                "label_fr": "Aubépine"
            },
            {
                "value": 20969,
                "label": "Guildford",
                "label_ar": "جيلدفورد",
                "label_fr": "Guildford"
            },
            {
                "value": 21739,
                "label": "Canadian",
                "label_ar": "كندي",
                "label_fr": "canadien"
            },
            {
                "value": 21603,
                "label": "Glenburn",
                "label_ar": "جلينبرن",
                "label_fr": "Glenburn"
            },
            {
                "value": 21838,
                "label": "Tyalgum",
                "label_ar": "تيالجوم",
                "label_fr": "Tyalgum"
            },
            {
                "value": 20765,
                "label": "Loftus",
                "label_ar": "لوفتوس",
                "label_fr": "Loftus"
            },
            {
                "value": 20164,
                "label": "Normanhurst",
                "label_ar": "نورمانهيرست",
                "label_fr": "Normanhurst"
            },
            {
                "value": 21233,
                "label": "Beeac",
                "label_ar": "بيك",
                "label_fr": "Beeac"
            },
            {
                "value": 21238,
                "label": "North Mackay",
                "label_ar": "شمال ماكاي",
                "label_fr": "North Mackay"
            },
            {
                "value": 21487,
                "label": "Moriac",
                "label_ar": "مورياك",
                "label_fr": "Moriac"
            },
            {
                "value": 21484,
                "label": "Teesdale",
                "label_ar": "تيسدال",
                "label_fr": "Teesdale"
            },
            {
                "value": 21819,
                "label": "Blayney",
                "label_ar": "بلايني",
                "label_fr": "Blayney"
            },
            {
                "value": 20745,
                "label": "South West Rocks",
                "label_ar": "جنوب غرب روكس",
                "label_fr": "South West Rocks"
            },
            {
                "value": 19954,
                "label": "Paramatta",
                "label_ar": "باراماتا",
                "label_fr": "Paramatta"
            },
            {
                "value": 20246,
                "label": "Cowra",
                "label_ar": "كورا",
                "label_fr": "Cowra"
            },
            {
                "value": 20953,
                "label": "Armadale",
                "label_ar": "أرمادال",
                "label_fr": "Armadale"
            },
            {
                "value": 21549,
                "label": "Wilton",
                "label_ar": "ويلتون",
                "label_fr": "Wilton"
            },
            {
                "value": 20531,
                "label": "Nelson Bay",
                "label_ar": "نيلسون باي",
                "label_fr": "Nelson Bay"
            },
            {
                "value": 21364,
                "label": "Stuart Park",
                "label_ar": "ستيوارت بارك",
                "label_fr": "Parc Stuart"
            },
            {
                "value": 21532,
                "label": "Batlow",
                "label_ar": "باتلو",
                "label_fr": "Batlow"
            },
            {
                "value": 20168,
                "label": "Lidcombe",
                "label_ar": "ليدكومب",
                "label_fr": "Lidcombe"
            },
            {
                "value": 20683,
                "label": "Waverton",
                "label_ar": "وافرتون",
                "label_fr": "Waverton"
            },
            {
                "value": 20171,
                "label": "Baulkham Hills",
                "label_ar": "تلال بولخام",
                "label_fr": "Baulkham Hills"
            },
            {
                "value": 19957,
                "label": "Sydney",
                "label_ar": "سيدني",
                "label_fr": "Sydney"
            },
            {
                "value": 20476,
                "label": "Lismore",
                "label_ar": "ليزمور",
                "label_fr": "Lismore"
            },
            {
                "value": 21209,
                "label": "Pioneer",
                "label_ar": "رائد",
                "label_fr": "Pionnier"
            },
            {
                "value": 20478,
                "label": "Tuggerah",
                "label_ar": "توجيرة",
                "label_fr": "Tuggerah"
            },
            {
                "value": 21618,
                "label": "Tarzali",
                "label_ar": "طرزالي",
                "label_fr": "Tarzali"
            },
            {
                "value": 20770,
                "label": "Bundeena",
                "label_ar": "بوندينا",
                "label_fr": "Bundeena"
            },
            {
                "value": 20766,
                "label": "San Remo",
                "label_ar": "سان ريمو",
                "label_fr": "San Remo"
            },
            {
                "value": 21289,
                "label": "Calen",
                "label_ar": "كالين",
                "label_fr": "Calen"
            },
            {
                "value": 20647,
                "label": "Seacombe",
                "label_ar": "سيكومب",
                "label_fr": "Seacombe"
            },
            {
                "value": 21827,
                "label": "Grassmere",
                "label_ar": "جراسمير",
                "label_fr": "Grassmere"
            },
            {
                "value": 21776,
                "label": "Chatsworth Island",
                "label_ar": "جزيرة تشاتسوورث",
                "label_fr": "Île de Chatsworth"
            },
            {
                "value": 20284,
                "label": "Narre Warren",
                "label_ar": "ناري وارين",
                "label_fr": "Narre Warren"
            },
            {
                "value": 20381,
                "label": "Glen Huntly",
                "label_ar": "جلين هانتلي",
                "label_fr": "Glen Huntly"
            },
            {
                "value": 20598,
                "label": "Morang",
                "label_ar": "مورانج",
                "label_fr": "Morang"
            },
            {
                "value": 20173,
                "label": "Drummoyne",
                "label_ar": "دروموين",
                "label_fr": "Drummoyne"
            },
            {
                "value": 21665,
                "label": "Ferndale",
                "label_ar": "فيرنديل",
                "label_fr": "Ferndale"
            },
            {
                "value": 21862,
                "label": "Peterborough",
                "label_ar": "بيتربورو",
                "label_fr": "Peterborough"
            },
            {
                "value": 19958,
                "label": "Kingscliff",
                "label_ar": "كينجسكليف",
                "label_fr": "Kingscliff"
            },
            {
                "value": 21090,
                "label": "Kempsey",
                "label_ar": "كيمبسي",
                "label_fr": "Kempsey"
            },
            {
                "value": 21874,
                "label": "Crawford",
                "label_ar": "كروفورد",
                "label_fr": "Crawford"
            },
            {
                "value": 20419,
                "label": "Stanmore",
                "label_ar": "ستانمور",
                "label_fr": "Stanmore"
            },
            {
                "value": 21285,
                "label": "Lithgow",
                "label_ar": "ليثجو",
                "label_fr": "Lithgow"
            },
            {
                "value": 21970,
                "label": "Manjimup",
                "label_ar": "مانجيموب",
                "label_fr": "Manjimup"
            },
            {
                "value": 21410,
                "label": "Longwarry",
                "label_ar": "لونجوارى",
                "label_fr": "Longwarry"
            },
            {
                "value": 20238,
                "label": "Eastwood",
                "label_ar": "ايستوود",
                "label_fr": "Eastwood"
            },
            {
                "value": 21625,
                "label": "Montville",
                "label_ar": "مونتفيل",
                "label_fr": "Montville"
            },
            {
                "value": 20451,
                "label": "Romsey",
                "label_ar": "رومسي",
                "label_fr": "Romsey"
            },
            {
                "value": 21478,
                "label": "Cootamundra",
                "label_ar": "كوتاموندرا",
                "label_fr": "Cootamundra"
            },
            {
                "value": 21336,
                "label": "Nyora",
                "label_ar": "نيورا",
                "label_fr": "Nyora"
            },
            {
                "value": 21611,
                "label": "Wimbledon",
                "label_ar": "ويمبلدون",
                "label_fr": "Wimbledon"
            },
            {
                "value": 20728,
                "label": "Strathfieldsaye",
                "label_ar": "ستراثفيلدساي",
                "label_fr": "Strathfieldsaye"
            },
            {
                "value": 20712,
                "label": "Yamba",
                "label_ar": "يامبا",
                "label_fr": "Yamba"
            },
            {
                "value": 21126,
                "label": "Bell",
                "label_ar": "جرس",
                "label_fr": "Cloche"
            },
            {
                "value": 21770,
                "label": "Crows Nest",
                "label_ar": "عش الغراب",
                "label_fr": "Nid de Corbeau"
            },
            {
                "value": 20786,
                "label": "Brighton",
                "label_ar": "برايتون",
                "label_fr": "Brighton"
            },
            {
                "value": 20313,
                "label": "Chelsea",
                "label_ar": "تشيلسي",
                "label_fr": "Chelsea"
            },
            {
                "value": 20455,
                "label": "Waterford",
                "label_ar": "وترفورد",
                "label_fr": "Waterford"
            },
            {
                "value": 21294,
                "label": "Wondai",
                "label_ar": "ونداي",
                "label_fr": "Wondai"
            },
            {
                "value": 20616,
                "label": "Wallaroo",
                "label_ar": "والارو",
                "label_fr": "Wallaroo"
            },
            {
                "value": 21501,
                "label": "Closeburn",
                "label_ar": "كلوسيبورن",
                "label_fr": "Closeburn"
            },
            {
                "value": 20301,
                "label": "Elsternwick",
                "label_ar": "Elsternwick",
                "label_fr": "Elsternwick"
            },
            {
                "value": 20942,
                "label": "Hurstbridge",
                "label_ar": "هيرستبريدج",
                "label_fr": "Hurstbridge"
            },
            {
                "value": 20252,
                "label": "Goulburn",
                "label_ar": "جولبرن",
                "label_fr": "Goulburn"
            },
            {
                "value": 20744,
                "label": "Rathmines",
                "label_ar": "راثماينز",
                "label_fr": "Rathmines"
            },
            {
                "value": 20725,
                "label": "Buln Buln",
                "label_ar": "بولن بولن",
                "label_fr": "Buln Buln"
            },
            {
                "value": 20494,
                "label": "Fassifern",
                "label_ar": "فاصفرن",
                "label_fr": "Fassifern"
            },
            {
                "value": 21190,
                "label": "Daylesford",
                "label_ar": "ديلسفورد",
                "label_fr": "Daylesford"
            },
            {
                "value": 20645,
                "label": "Wangaratta",
                "label_ar": "وانجاراتا",
                "label_fr": "Wangaratta"
            },
            {
                "value": 20211,
                "label": "Footscray",
                "label_ar": "فوتسكراي",
                "label_fr": "Footscray"
            },
            {
                "value": 21094,
                "label": "Exeter",
                "label_ar": "إكستر",
                "label_fr": "Exeter"
            },
            {
                "value": 21960,
                "label": "Sapphire",
                "label_ar": "الياقوت",
                "label_fr": "Saphir"
            },
            {
                "value": 20614,
                "label": "Pialba",
                "label_ar": "بيالبا",
                "label_fr": "Pialba"
            },
            {
                "value": 21475,
                "label": "Abermain",
                "label_ar": "أبيرمين",
                "label_fr": "Abermain"
            },
            {
                "value": 21740,
                "label": "Edge Hill",
                "label_ar": "إيدج هيل",
                "label_fr": "Edge Hill"
            },
            {
                "value": 20187,
                "label": "Oyster Bay",
                "label_ar": "أويستر باي",
                "label_fr": "Oyster Bay"
            },
            {
                "value": 20529,
                "label": "Orient Point",
                "label_ar": "أورينت بوينت",
                "label_fr": "Orienter le point"
            },
            {
                "value": 21992,
                "label": "Dunkeld",
                "label_ar": "دنكيلد",
                "label_fr": "Dunkeld"
            },
            {
                "value": 20799,
                "label": "Chullora",
                "label_ar": "تشولورا",
                "label_fr": "Chullora"
            },
            {
                "value": 21405,
                "label": "Lutana",
                "label_ar": "لوتانا",
                "label_fr": "Lutana"
            },
            {
                "value": 20975,
                "label": "Wanbi",
                "label_ar": "وانبي",
                "label_fr": "Wanbi"
            },
            {
                "value": 21963,
                "label": "Boyup Brook",
                "label_ar": "Boyup Brook",
                "label_fr": "Boyup Brook"
            },
            {
                "value": 20045,
                "label": "Bundaberg",
                "label_ar": "بوندابيرج",
                "label_fr": "Bundaberg"
            },
            {
                "value": 20406,
                "label": "Kirribilli",
                "label_ar": "كيريبيلي",
                "label_fr": "Kirribilli"
            },
            {
                "value": 20985,
                "label": "Woodside",
                "label_ar": "وودسايد",
                "label_fr": "Woodside"
            },
            {
                "value": 20887,
                "label": "Clovelly",
                "label_ar": "كلوفيلي",
                "label_fr": "Clovelly"
            },
            {
                "value": 20640,
                "label": "Sellicks Hill",
                "label_ar": "سيليكس هيل",
                "label_fr": "Sellicks Hill"
            },
            {
                "value": 20943,
                "label": "Thirroul",
                "label_ar": "ثيرول",
                "label_fr": "Thirroul"
            },
            {
                "value": 21515,
                "label": "Penfield",
                "label_ar": "بنفيلد",
                "label_fr": "Penfield"
            },
            {
                "value": 21500,
                "label": "Thuringowa",
                "label_ar": "تورينغوا",
                "label_fr": "Thuringowa"
            },
            {
                "value": 20330,
                "label": "Hampton Park",
                "label_ar": "هامبتون بارك",
                "label_fr": "Hampton Park"
            },
            {
                "value": 20235,
                "label": "Holsworthy",
                "label_ar": "هولسوورثي",
                "label_fr": "Holsworthy"
            },
            {
                "value": 20342,
                "label": "Templestowe",
                "label_ar": "تمبلستو",
                "label_fr": "Templestowe"
            },
            {
                "value": 20480,
                "label": "Spotswood",
                "label_ar": "سبوتسوود",
                "label_fr": "Spotswood"
            },
            {
                "value": 21397,
                "label": "Huntly",
                "label_ar": "بصراحة",
                "label_fr": "Huntly"
            },
            {
                "value": 20998,
                "label": "Mount Helena",
                "label_ar": "جبل هيلينا",
                "label_fr": "Mont Helena"
            },
            {
                "value": 21692,
                "label": "Applethorpe",
                "label_ar": "أبليثورب",
                "label_fr": "Applethorpe"
            },
            {
                "value": 21768,
                "label": "Lawgi",
                "label_ar": "Lawgi",
                "label_fr": "Lawgi"
            },
            {
                "value": 21941,
                "label": "Kings Cross",
                "label_ar": "كينغز كروس",
                "label_fr": "La Croix du Roi"
            },
            {
                "value": 20278,
                "label": "Noble Park",
                "label_ar": "نوبل بارك",
                "label_fr": "Parc Noble"
            },
            {
                "value": 21437,
                "label": "Long Point",
                "label_ar": "نقطة طويلة",
                "label_fr": "Point long"
            },
            {
                "value": 19986,
                "label": "Toogoolawah",
                "label_ar": "Toogoolawah",
                "label_fr": "Toogoolawah"
            },
            {
                "value": 21847,
                "label": "Woodvale",
                "label_ar": "وودفيل",
                "label_fr": "Woodvale"
            },
            {
                "value": 20952,
                "label": "Corrimal",
                "label_ar": "كوريمال",
                "label_fr": "Corrimal"
            },
            {
                "value": 20633,
                "label": "Benalla",
                "label_ar": "بينالا",
                "label_fr": "Benalla"
            },
            {
                "value": 21904,
                "label": "Gilston",
                "label_ar": "جيلستون",
                "label_fr": "Gilston"
            },
            {
                "value": 20119,
                "label": "Albion",
                "label_ar": "ألبيون",
                "label_fr": "Albion"
            },
            {
                "value": 21249,
                "label": "Trentham",
                "label_ar": "ترينتام",
                "label_fr": "Trentham"
            },
            {
                "value": 21251,
                "label": "Schofields",
                "label_ar": "سكوفيلدز",
                "label_fr": "Schofields"
            },
            {
                "value": 21021,
                "label": "Kyneton",
                "label_ar": "كينيتون",
                "label_fr": "Kyneton"
            },
            {
                "value": 21440,
                "label": "Barkstead",
                "label_ar": "باركستيد",
                "label_fr": "Barkstead"
            },
            {
                "value": 21032,
                "label": "Bannockburn",
                "label_ar": "بانوكبيرن",
                "label_fr": "Bannockburn"
            },
            {
                "value": 20958,
                "label": "Goonellabah",
                "label_ar": "Goonellabah",
                "label_fr": "Goonellabah"
            },
            {
                "value": 21925,
                "label": "Nagambie",
                "label_ar": "ناغامبي",
                "label_fr": "Nagambie"
            },
            {
                "value": 20217,
                "label": "Belrose",
                "label_ar": "بيلروز",
                "label_fr": "Belrose"
            },
            {
                "value": 21207,
                "label": "Moore Park",
                "label_ar": "مور بارك",
                "label_fr": "Parc Moore"
            },
            {
                "value": 20026,
                "label": "Rockhampton",
                "label_ar": "روكهامبتون",
                "label_fr": "Rockhampton"
            },
            {
                "value": 19988,
                "label": "Oatley",
                "label_ar": "أوتلي",
                "label_fr": "Oatley"
            },
            {
                "value": 20691,
                "label": "Terrigal",
                "label_ar": "تيريجال",
                "label_fr": "Terrigal"
            },
            {
                "value": 20996,
                "label": "Bicton",
                "label_ar": "بيكتون",
                "label_fr": "Bicton"
            },
            {
                "value": 21653,
                "label": "Abbotsham",
                "label_ar": "أبوتشام",
                "label_fr": "Abbotsham"
            },
            {
                "value": 21280,
                "label": "Kingaroy",
                "label_ar": "Kingaroy",
                "label_fr": "Kingaroy"
            },
            {
                "value": 21216,
                "label": "O'Connor",
                "label_ar": "أوكونور",
                "label_fr": "O'Connor"
            },
            {
                "value": 20128,
                "label": "Yellow Rock",
                "label_ar": "يلو روك",
                "label_fr": "Rocher jaune"
            },
            {
                "value": 21085,
                "label": "Smythes Creek",
                "label_ar": "سميث كريك",
                "label_fr": "Smythes Creek"
            },
            {
                "value": 20304,
                "label": "Blackburn",
                "label_ar": "حرق الأسود",
                "label_fr": "Blackburn"
            },
            {
                "value": 21257,
                "label": "Sanctuary Point",
                "label_ar": "نقطة الملاذ",
                "label_fr": "Point sanctuaire"
            },
            {
                "value": 21363,
                "label": "Grenfell",
                "label_ar": "جرينفيل",
                "label_fr": "Grenfell"
            },
            {
                "value": 20376,
                "label": "Mudgeeraba",
                "label_ar": "Mudgeeraba",
                "label_fr": "Mudgeeraba"
            },
            {
                "value": 20345,
                "label": "Albert Park",
                "label_ar": "ألبرت بارك",
                "label_fr": "Albert Park"
            },
            {
                "value": 21842,
                "label": "Baker",
                "label_ar": "خباز",
                "label_fr": "boulanger"
            },
            {
                "value": 20165,
                "label": "Blacktown",
                "label_ar": "بلاكتاون",
                "label_fr": "Blacktown"
            },
            {
                "value": 21610,
                "label": "Margaret River",
                "label_ar": "نهر مارجريت",
                "label_fr": "Margaret River"
            },
            {
                "value": 20369,
                "label": "Capalaba",
                "label_ar": "كابالابا",
                "label_fr": "Capalaba"
            },
            {
                "value": 20900,
                "label": "Davidson",
                "label_ar": "ديفيدسون",
                "label_fr": "Davidson"
            },
            {
                "value": 21964,
                "label": "Augusta",
                "label_ar": "أوغوستا",
                "label_fr": "Augusta"
            },
            {
                "value": 19980,
                "label": "Yandina",
                "label_ar": "ياندينا",
                "label_fr": "Yandina"
            },
            {
                "value": 21230,
                "label": "Coondoo",
                "label_ar": "كوندو",
                "label_fr": "Coondoo"
            },
            {
                "value": 20727,
                "label": "Toolern Vale",
                "label_ar": "تولرن فالي",
                "label_fr": "Toolern Vale"
            },
            {
                "value": 21755,
                "label": "Glengarry",
                "label_ar": "جلينجاري",
                "label_fr": "Glengarry"
            },
            {
                "value": 20261,
                "label": "Mayfield",
                "label_ar": "مايفيلد",
                "label_fr": "Mayfield"
            },
            {
                "value": 21482,
                "label": "Swan Hill",
                "label_ar": "سوان هيل",
                "label_fr": "Swan Hill"
            },
            {
                "value": 21934,
                "label": "Shoal Point",
                "label_ar": "شول بوينت",
                "label_fr": "Shoal Point"
            },
            {
                "value": 20075,
                "label": "Northam",
                "label_ar": "نورثهام",
                "label_fr": "Northam"
            },
            {
                "value": 20482,
                "label": "Gympie",
                "label_ar": "جيمبي",
                "label_fr": "Gympie"
            },
            {
                "value": 20054,
                "label": "Manly",
                "label_ar": "رجولي",
                "label_fr": "Viril"
            },
            {
                "value": 20552,
                "label": "Long Jetty",
                "label_ar": "رصيف طويل",
                "label_fr": "Longue jetée"
            },
            {
                "value": 21115,
                "label": "Verdun",
                "label_ar": "فردان",
                "label_fr": "Verdun"
            },
            {
                "value": 20122,
                "label": "Box Hill",
                "label_ar": "بوكس هيل",
                "label_fr": "Box Hill"
            },
            {
                "value": 20740,
                "label": "Albany",
                "label_ar": "ألباني",
                "label_fr": "Albany"
            },
            {
                "value": 21617,
                "label": "Burrum",
                "label_ar": "بوروم",
                "label_fr": "Burrum"
            },
            {
                "value": 21476,
                "label": "Smithfield",
                "label_ar": "سميثفيلد",
                "label_fr": "Smithfield"
            },
            {
                "value": 21910,
                "label": "York",
                "label_ar": "يورك",
                "label_fr": "York"
            },
            {
                "value": 21580,
                "label": "Howe",
                "label_ar": "هاو",
                "label_fr": "Howe"
            },
            {
                "value": 20674,
                "label": "Willaston",
                "label_ar": "ويلستون",
                "label_fr": "Willaston"
            },
            {
                "value": 20692,
                "label": "Beaumont",
                "label_ar": "بومونت",
                "label_fr": "Beaumont"
            },
            {
                "value": 21287,
                "label": "Oberon",
                "label_ar": "أوبيرون",
                "label_fr": "Oberon"
            },
            {
                "value": 20676,
                "label": "Dromana",
                "label_ar": "درومانا",
                "label_fr": "Dromana"
            },
            {
                "value": 20141,
                "label": "Unley",
                "label_ar": "Unley",
                "label_fr": "Unley"
            },
            {
                "value": 20715,
                "label": "Coffs Harbour",
                "label_ar": "كوفس هاربور",
                "label_fr": "Coffs Harbour"
            },
            {
                "value": 21383,
                "label": "Beverley",
                "label_ar": "بيفرلي",
                "label_fr": "Beverley"
            },
            {
                "value": 21884,
                "label": "Lang Lang",
                "label_ar": "لانج لانج",
                "label_fr": "Lang Lang"
            },
            {
                "value": 21027,
                "label": "Ringwood North",
                "label_ar": "رينجوود الشمالية",
                "label_fr": "Ringwood Nord"
            },
            {
                "value": 20796,
                "label": "Woonona",
                "label_ar": "وونونا",
                "label_fr": "Woonona"
            },
            {
                "value": 20370,
                "label": "Kelvin Grove",
                "label_ar": "كلفن جروف",
                "label_fr": "Kelvin Grove"
            },
            {
                "value": 20079,
                "label": "Maylands",
                "label_ar": "مايلاندز",
                "label_fr": "Maylands"
            },
            {
                "value": 20747,
                "label": "Mansfield",
                "label_ar": "مانسفيلد",
                "label_fr": "Mansfield"
            },
            {
                "value": 21607,
                "label": "Allanson",
                "label_ar": "ألانسون",
                "label_fr": "Allanson"
            },
            {
                "value": 21791,
                "label": "Norlane",
                "label_ar": "نورلين",
                "label_fr": "Norlane"
            },
            {
                "value": 20672,
                "label": "Murray Bridge",
                "label_ar": "جسر موراي",
                "label_fr": "Murray Bridge"
            },
            {
                "value": 20773,
                "label": "Canterbury",
                "label_ar": "كانتربري",
                "label_fr": "Cantorbéry"
            },
            {
                "value": 21931,
                "label": "O'Sullivan Beach",
                "label_ar": "شاطئ أوسوليفان",
                "label_fr": "Plage O'Sullivan"
            },
            {
                "value": 21232,
                "label": "Maybole",
                "label_ar": "مايبول",
                "label_fr": "Maybole"
            },
            {
                "value": 20384,
                "label": "Northmead",
                "label_ar": "نورثميد",
                "label_fr": "Northmead"
            },
            {
                "value": 21556,
                "label": "Christies",
                "label_ar": "كريستيز",
                "label_fr": "Christies"
            },
            {
                "value": 20804,
                "label": "Broadford",
                "label_ar": "برودفورد",
                "label_fr": "Broadford"
            },
            {
                "value": 20167,
                "label": "Rhodes",
                "label_ar": "رودس",
                "label_fr": "Rhodes"
            },
            {
                "value": 20245,
                "label": "Windsor",
                "label_ar": "وندسور",
                "label_fr": "Windsor"
            },
            {
                "value": 21194,
                "label": "Oatlands",
                "label_ar": "الشوفان",
                "label_fr": "Oatlands"
            },
            {
                "value": 20974,
                "label": "Campbellfield",
                "label_ar": "كامبلفيلد",
                "label_fr": "Campbellfield"
            },
            {
                "value": 20502,
                "label": "Springfield",
                "label_ar": "سبرينجفيلد",
                "label_fr": "Springfield"
            },
            {
                "value": 21643,
                "label": "Ocean Shores",
                "label_ar": "شواطئ المحيط",
                "label_fr": "Ocean Shores"
            },
            {
                "value": 20315,
                "label": "Bentleigh",
                "label_ar": "بنتلي",
                "label_fr": "Bentleigh"
            },
            {
                "value": 20537,
                "label": "Bellevue Hill",
                "label_ar": "هيل بلفيو",
                "label_fr": "Bellevue Hill"
            },
            {
                "value": 20658,
                "label": "Cundletown",
                "label_ar": "كوندلتاون",
                "label_fr": "Cundletown"
            },
            {
                "value": 21282,
                "label": "Kadina",
                "label_ar": "كادينا",
                "label_fr": "Kadina"
            },
            {
                "value": 21623,
                "label": "Marmor",
                "label_ar": "مرمر",
                "label_fr": "Marmor"
            },
            {
                "value": 21185,
                "label": "Clunes",
                "label_ar": "كلونس",
                "label_fr": "Clunes"
            },
            {
                "value": 20340,
                "label": "Redcliffe",
                "label_ar": "ريدكليف",
                "label_fr": "Redcliffe"
            },
            {
                "value": 20882,
                "label": "Darling",
                "label_ar": "حبيبي",
                "label_fr": "chéri"
            },
            {
                "value": 21056,
                "label": "Jacobs Well",
                "label_ar": "جاكوبس حسنا",
                "label_fr": "Jacobs bien"
            },
            {
                "value": 20317,
                "label": "Glenroy",
                "label_ar": "جلينروي",
                "label_fr": "Glenroy"
            },
            {
                "value": 21785,
                "label": "Kerry",
                "label_ar": "كيري",
                "label_fr": "Kerry"
            },
            {
                "value": 21760,
                "label": "Eudunda",
                "label_ar": "يودوندا",
                "label_fr": "Eudunda"
            },
            {
                "value": 20872,
                "label": "Loganholme",
                "label_ar": "لوغانهولمي",
                "label_fr": "Loganholme"
            },
            {
                "value": 21869,
                "label": "Parkhurst",
                "label_ar": "باركهورست",
                "label_fr": "Parkhurst"
            },
            {
                "value": 20627,
                "label": "Grove",
                "label_ar": "بستان",
                "label_fr": "Bosquet"
            },
            {
                "value": 21107,
                "label": "Pelican",
                "label_ar": "البجع",
                "label_fr": "Pélican"
            },
            {
                "value": 21379,
                "label": "Woolooware",
                "label_ar": "Woolooware",
                "label_fr": "Woolooware"
            },
            {
                "value": 21281,
                "label": "Binda",
                "label_ar": "بيندا",
                "label_fr": "Binda"
            },
            {
                "value": 20030,
                "label": "Albany Creek",
                "label_ar": "ألباني كريك",
                "label_fr": "Albany Creek"
            },
            {
                "value": 20464,
                "label": "Gymea",
                "label_ar": "جيميا",
                "label_fr": "Gymea"
            },
            {
                "value": 20101,
                "label": "Gawler",
                "label_ar": "جولر",
                "label_fr": "Gawler"
            },
            {
                "value": 19983,
                "label": "Saint Marys",
                "label_ar": "القديسة ماري",
                "label_fr": "Saint Marys"
            },
            {
                "value": 20711,
                "label": "Orange",
                "label_ar": "البرتقالي",
                "label_fr": "Orange"
            },
            {
                "value": 21525,
                "label": "Grantham",
                "label_ar": "جرانثام",
                "label_fr": "Grantham"
            },
            {
                "value": 21389,
                "label": "Denham",
                "label_ar": "دينهام",
                "label_fr": "Denham"
            },
            {
                "value": 20163,
                "label": "Strathfield",
                "label_ar": "ستراثفيلد",
                "label_fr": "Strathfield"
            },
            {
                "value": 21322,
                "label": "Heathcote",
                "label_ar": "هيثكوت",
                "label_fr": "Heathcote"
            },
            {
                "value": 20814,
                "label": "Black Hill",
                "label_ar": "بلاك هيل",
                "label_fr": "Black Hill"
            },
            {
                "value": 20914,
                "label": "Stanhope",
                "label_ar": "ستانهوب",
                "label_fr": "Stanhope"
            },
            {
                "value": 20810,
                "label": "Spring Hill",
                "label_ar": "تل الربيع",
                "label_fr": "Spring Hill"
            },
            {
                "value": 21103,
                "label": "Lulworth",
                "label_ar": "لولوورث",
                "label_fr": "Lulworth"
            },
            {
                "value": 20257,
                "label": "Camperdown",
                "label_ar": "كامبرداون",
                "label_fr": "Camperdown"
            },
            {
                "value": 21672,
                "label": "Cook",
                "label_ar": "يطبخ",
                "label_fr": "cuisinier"
            },
            {
                "value": 20277,
                "label": "Ascot Vale",
                "label_ar": "أسكوت فالي",
                "label_fr": "Ascot Vale"
            },
            {
                "value": 20286,
                "label": "Hoppers Crossing",
                "label_ar": "معبر هوبرز",
                "label_fr": "Traversée des trémies"
            },
            {
                "value": 21851,
                "label": "Roleystone",
                "label_ar": "روليستون",
                "label_fr": "Roleystone"
            },
            {
                "value": 20266,
                "label": "Sandringham",
                "label_ar": "ساندرينجهام",
                "label_fr": "Sandringham"
            },
            {
                "value": 19970,
                "label": "Broadbeach",
                "label_ar": "برودبيتش",
                "label_fr": "Broadbeach"
            },
            {
                "value": 21093,
                "label": "Amaroo",
                "label_ar": "أمارو",
                "label_fr": "Amaroo"
            },
            {
                "value": 20735,
                "label": "Caulfield North",
                "label_ar": "كولفيلد نورث",
                "label_fr": "Caulfield Nord"
            },
            {
                "value": 21105,
                "label": "Clifton",
                "label_ar": "كليفتون",
                "label_fr": "Clifton"
            },
            {
                "value": 21859,
                "label": "Blackwood",
                "label_ar": "خشب أسود",
                "label_fr": "Bois noir"
            },
            {
                "value": 19989,
                "label": "Aldinga",
                "label_ar": "ألدنجا",
                "label_fr": "Aldinga"
            },
            {
                "value": 21416,
                "label": "Eagle Heights",
                "label_ar": "مرتفعات النسر",
                "label_fr": "Eagle Heights"
            },
            {
                "value": 20965,
                "label": "Briar Hill",
                "label_ar": "براير هيل",
                "label_fr": "Briar Hill"
            },
            {
                "value": 21308,
                "label": "Yea",
                "label_ar": "نعم",
                "label_fr": "Oui"
            },
            {
                "value": 21680,
                "label": "Riana",
                "label_ar": "ريانا",
                "label_fr": "Riana"
            },
            {
                "value": 21588,
                "label": "Maidenwell",
                "label_ar": "مايدنويل",
                "label_fr": "Maidenwell"
            },
            {
                "value": 21087,
                "label": "Haddon",
                "label_ar": "هادون",
                "label_fr": "Haddon"
            },
            {
                "value": 20823,
                "label": "Dungog",
                "label_ar": "دنجوج",
                "label_fr": "Dungog"
            },
            {
                "value": 21981,
                "label": "Bulahdelah",
                "label_ar": "بلحللة",
                "label_fr": "Bulahdelah"
            },
            {
                "value": 20688,
                "label": "Menai",
                "label_ar": "ميناي",
                "label_fr": "Menai"
            },
            {
                "value": 21199,
                "label": "Bradbury",
                "label_ar": "برادبري",
                "label_fr": "Bradbury"
            },
            {
                "value": 20528,
                "label": "Matraville",
                "label_ar": "ماترافيل",
                "label_fr": "Matraville"
            },
            {
                "value": 20017,
                "label": "Eight Mile Plains",
                "label_ar": "ثمانية أميال السهول",
                "label_fr": "Eight Mile Plains"
            },
            {
                "value": 20681,
                "label": "Brookvale",
                "label_ar": "بروكفيل",
                "label_fr": "Brookvale"
            },
            {
                "value": 20191,
                "label": "Kensington",
                "label_ar": "كنسينغتون",
                "label_fr": "Kensington"
            },
            {
                "value": 20957,
                "label": "Raworth",
                "label_ar": "راورث",
                "label_fr": "Raworth"
            },
            {
                "value": 20815,
                "label": "Belgrave Heights",
                "label_ar": "بلغريف هايتس",
                "label_fr": "Belgrave Heights"
            },
            {
                "value": 21651,
                "label": "Bilinga",
                "label_ar": "بيلينجا",
                "label_fr": "Bilinga"
            },
            {
                "value": 20585,
                "label": "Sorrento",
                "label_ar": "سورينتو",
                "label_fr": "Sorrento"
            },
            {
                "value": 20657,
                "label": "Tamborine",
                "label_ar": "تامبورين",
                "label_fr": "Tamborine"
            },
            {
                "value": 20336,
                "label": "Deer Park",
                "label_ar": "حديقة الغزلان",
                "label_fr": "Parc aux cerfs"
            },
            {
                "value": 21479,
                "label": "Clareville",
                "label_ar": "كلاريفيل",
                "label_fr": "Clareville"
            },
            {
                "value": 20800,
                "label": "Teralba",
                "label_ar": "تيرالبا",
                "label_fr": "Teralba"
            },
            {
                "value": 20578,
                "label": "Murrumbeena",
                "label_ar": "مورومبينا",
                "label_fr": "Murrumbeena"
            },
            {
                "value": 20169,
                "label": "Merrylands",
                "label_ar": "ميريلاند",
                "label_fr": "Merrylands"
            },
            {
                "value": 21596,
                "label": "Brandy Hill",
                "label_ar": "براندي هيل",
                "label_fr": "Brandy Hill"
            },
            {
                "value": 19992,
                "label": "Elwood",
                "label_ar": "إلوود",
                "label_fr": "Elwood"
            },
            {
                "value": 21762,
                "label": "Clare",
                "label_ar": "كلير",
                "label_fr": "Clare"
            },
            {
                "value": 20221,
                "label": "Gordon",
                "label_ar": "جوردون",
                "label_fr": "Gordon"
            },
            {
                "value": 21831,
                "label": "Macksville",
                "label_ar": "ماكفيل",
                "label_fr": "Macksville"
            },
            {
                "value": 21536,
                "label": "Narrandera",
                "label_ar": "نارانديرا",
                "label_fr": "Narrandera"
            },
            {
                "value": 20653,
                "label": "Dundas",
                "label_ar": "دونداس",
                "label_fr": "Dundas"
            },
            {
                "value": 21396,
                "label": "Cockatoo",
                "label_ar": "كوكاتو",
                "label_fr": "Cacatoès"
            },
            {
                "value": 20930,
                "label": "Seymour",
                "label_ar": "سيمور",
                "label_fr": "Seymour"
            },
            {
                "value": 20558,
                "label": "Benowa",
                "label_ar": "بينوا",
                "label_fr": "Benowa"
            },
            {
                "value": 20481,
                "label": "Camberwell",
                "label_ar": "كامبرويل",
                "label_fr": "Camberwell"
            },
            {
                "value": 20519,
                "label": "Lugarno",
                "label_ar": "لوغارنو",
                "label_fr": "Lugarno"
            },
            {
                "value": 21477,
                "label": "Fingal Bay",
                "label_ar": "خليج فينجال",
                "label_fr": "Fingal Bay"
            },
            {
                "value": 21316,
                "label": "Ingham",
                "label_ar": "إنغام",
                "label_fr": "Ingham"
            },
            {
                "value": 20917,
                "label": "Condamine",
                "label_ar": "كوندامين",
                "label_fr": "Condamine"
            },
            {
                "value": 20213,
                "label": "Thornleigh",
                "label_ar": "ثورنلي",
                "label_fr": "Thornleigh"
            },
            {
                "value": 21511,
                "label": "Winkleigh",
                "label_ar": "وينكلي",
                "label_fr": "Winkleigh"
            },
            {
                "value": 20199,
                "label": "Alexandria",
                "label_ar": "الإسكندرية",
                "label_fr": "Alexandrie"
            },
            {
                "value": 20185,
                "label": "Adamstown",
                "label_ar": "ادمستاون",
                "label_fr": "Adamstown"
            },
            {
                "value": 20373,
                "label": "Robertson",
                "label_ar": "روبرتسون",
                "label_fr": "Robertson"
            },
            {
                "value": 21809,
                "label": "Gowrie",
                "label_ar": "جوري",
                "label_fr": "Gowrie"
            },
            {
                "value": 20518,
                "label": "Woronora",
                "label_ar": "وورونورا",
                "label_fr": "Woronora"
            },
            {
                "value": 20511,
                "label": "Glebe",
                "label_ar": "جليب",
                "label_fr": "Glebe"
            },
            {
                "value": 20758,
                "label": "Logan",
                "label_ar": "لوجان",
                "label_fr": "Logan"
            },
            {
                "value": 21001,
                "label": "Kapunda",
                "label_ar": "كابوندا",
                "label_fr": "Kapunda"
            },
            {
                "value": 21301,
                "label": "Cudlee Creek",
                "label_ar": "كودلي كريك",
                "label_fr": "Ruisseau Cudlee"
            },
            {
                "value": 21059,
                "label": "Virginia",
                "label_ar": "فرجينيا",
                "label_fr": "Virginie"
            },
            {
                "value": 21156,
                "label": "Atherton",
                "label_ar": "أثرتون",
                "label_fr": "Atherton"
            },
            {
                "value": 21570,
                "label": "Stanley",
                "label_ar": "ستانلي",
                "label_fr": "Stanley"
            },
            {
                "value": 20402,
                "label": "Maddington",
                "label_ar": "مادينجتون",
                "label_fr": "Maddington"
            },
            {
                "value": 21259,
                "label": "Murrurundi",
                "label_ar": "موروروندي",
                "label_fr": "Murrurundi"
            },
            {
                "value": 20760,
                "label": "Beaconsfield",
                "label_ar": "بيكونزفيلد",
                "label_fr": "Beaconsfield"
            },
            {
                "value": 20780,
                "label": "Saint Helena",
                "label_ar": "سانت هيلانة",
                "label_fr": "Sainte-Hélène"
            },
            {
                "value": 20319,
                "label": "Moonee Ponds",
                "label_ar": "مون برك",
                "label_fr": "Étangs de Moonee"
            },
            {
                "value": 20733,
                "label": "Beauty Point",
                "label_ar": "بيوتي بوينت",
                "label_fr": "Point beauté"
            },
            {
                "value": 21111,
                "label": "Thompson",
                "label_ar": "طومسون",
                "label_fr": "Thompson"
            },
            {
                "value": 21982,
                "label": "Spencer",
                "label_ar": "سبنسر",
                "label_fr": "Spencer"
            },
            {
                "value": 21177,
                "label": "Jilliby",
                "label_ar": "جيليبي",
                "label_fr": "Jilliby"
            },
            {
                "value": 20869,
                "label": "Shorncliffe",
                "label_ar": "شورنكليف",
                "label_fr": "Shorncliffe"
            },
            {
                "value": 21509,
                "label": "Smithton",
                "label_ar": "سميثتون",
                "label_fr": "Smithton"
            },
            {
                "value": 20826,
                "label": "Windaroo",
                "label_ar": "ويندارو",
                "label_fr": "Windaroo"
            },
            {
                "value": 20933,
                "label": "Slacks Creek",
                "label_ar": "سلاكس كريك",
                "label_fr": "Ruisseau Slacks"
            },
            {
                "value": 21012,
                "label": "Gilmore",
                "label_ar": "جيلمور",
                "label_fr": "Gilmore"
            },
            {
                "value": 20386,
                "label": "Raymond Terrace",
                "label_ar": "ريمون تيراس",
                "label_fr": "Terrasse Raymond"
            },
            {
                "value": 20809,
                "label": "Lowood",
                "label_ar": "لوود",
                "label_fr": "Lowood"
            },
            {
                "value": 20145,
                "label": "Strathalbyn",
                "label_ar": "ستراتالبين",
                "label_fr": "Strathalbyn"
            },
            {
                "value": 20963,
                "label": "Officer",
                "label_ar": "ضابط",
                "label_fr": "Officier"
            },
            {
                "value": 20274,
                "label": "Clayton North",
                "label_ar": "كلايتون نورث",
                "label_fr": "Clayton North"
            },
            {
                "value": 21647,
                "label": "Venus Bay",
                "label_ar": "خليج فينوس",
                "label_fr": "Baie de Vénus"
            },
            {
                "value": 21650,
                "label": "Howard Springs",
                "label_ar": "هوارد سبرينغز",
                "label_fr": "Howard Springs"
            },
            {
                "value": 21075,
                "label": "Sylvania Waters",
                "label_ar": "سيلفانيا ووترز",
                "label_fr": "Sylvania Waters"
            },
            {
                "value": 20910,
                "label": "Aylmerton",
                "label_ar": "ايلميرتون",
                "label_fr": "Aylmerton"
            },
            {
                "value": 20190,
                "label": "Bondi",
                "label_ar": "بوندي",
                "label_fr": "Bondi"
            },
            {
                "value": 21346,
                "label": "Mount Lofty",
                "label_ar": "جبل لافتي",
                "label_fr": "Mont Lofty"
            },
            {
                "value": 20216,
                "label": "Forestville",
                "label_ar": "فورستفيل",
                "label_fr": "Forestville"
            },
            {
                "value": 20479,
                "label": "Warragul",
                "label_ar": "واراجول",
                "label_fr": "Warragul"
            },
            {
                "value": 21210,
                "label": "Ridgley",
                "label_ar": "ريدجلي",
                "label_fr": "Ridgley"
            },
            {
                "value": 21453,
                "label": "Tenambit",
                "label_ar": "تينامبيت",
                "label_fr": "Tenambit"
            },
            {
                "value": 21340,
                "label": "Woodleigh",
                "label_ar": "وودلي",
                "label_fr": "Woodleigh"
            },
            {
                "value": 19955,
                "label": "McGraths Hill",
                "label_ar": "ماكغراث هيل",
                "label_fr": "McGraths Hill"
            },
            {
                "value": 21627,
                "label": "Bellara",
                "label_ar": "بيلارا",
                "label_fr": "Bellara"
            },
            {
                "value": 20778,
                "label": "Eltham North",
                "label_ar": "إلثام الشمالية",
                "label_fr": "Eltham Nord"
            },
            {
                "value": 21995,
                "label": "Copacabana",
                "label_ar": "كوباكابانا",
                "label_fr": "Copacabana"
            },
            {
                "value": 20195,
                "label": "Annandale",
                "label_ar": "أنانديل",
                "label_fr": "Annandale"
            },
            {
                "value": 21750,
                "label": "Stirling",
                "label_ar": "ستيرلينغ",
                "label_fr": "Stirling"
            },
            {
                "value": 20343,
                "label": "Hampton",
                "label_ar": "هامبتون",
                "label_fr": "Hampton"
            },
            {
                "value": 21339,
                "label": "Port Augusta",
                "label_ar": "بورت أوغوستا",
                "label_fr": "Port Augusta"
            },
            {
                "value": 21920,
                "label": "Birdwood",
                "label_ar": "بيردوود",
                "label_fr": "Birdwood"
            },
            {
                "value": 20456,
                "label": "Riverwood",
                "label_ar": "ريفروود",
                "label_fr": "Riverwood"
            },
            {
                "value": 20748,
                "label": "Beveridge",
                "label_ar": "بيفريدج",
                "label_fr": "Beveridge"
            },
            {
                "value": 21081,
                "label": "Heathcote",
                "label_ar": "هيثكوت",
                "label_fr": "Heathcote"
            },
            {
                "value": 20258,
                "label": "Vaucluse",
                "label_ar": "فوكلوز",
                "label_fr": "Vaucluse"
            },
            {
                "value": 21170,
                "label": "Casino",
                "label_ar": "كازينو",
                "label_fr": "Casino"
            },
            {
                "value": 20001,
                "label": "Caversham",
                "label_ar": "كافيرشام",
                "label_fr": "Caversham"
            },
            {
                "value": 21132,
                "label": "Stockyard Creek",
                "label_ar": "ستوكيارد كريك",
                "label_fr": "Ruisseau Stockyard"
            },
            {
                "value": 20472,
                "label": "Sebastopol",
                "label_ar": "سيباستوبول",
                "label_fr": "Sébastopol"
            },
            {
                "value": 21807,
                "label": "Willow Grove",
                "label_ar": "ويلو جروف",
                "label_fr": "Willow Grove"
            },
            {
                "value": 21644,
                "label": "Flinders",
                "label_ar": "فليندرز",
                "label_fr": "Flinders"
            },
            {
                "value": 21086,
                "label": "Ross Creek",
                "label_ar": "روس كريك",
                "label_fr": "Ross Creek"
            },
            {
                "value": 21853,
                "label": "Bilpin",
                "label_ar": "بيلبين",
                "label_fr": "Bilpin"
            },
            {
                "value": 20200,
                "label": "Villawood",
                "label_ar": "فيلاوود",
                "label_fr": "Villawood"
            },
            {
                "value": 20409,
                "label": "Gymea Bay",
                "label_ar": "خليج جيميا",
                "label_fr": "Gymea Bay"
            },
            {
                "value": 20457,
                "label": "Monash",
                "label_ar": "موناش",
                "label_fr": "Monash"
            },
            {
                "value": 20729,
                "label": "Emerald",
                "label_ar": "زمرد",
                "label_fr": "émeraude"
            },
            {
                "value": 19976,
                "label": "Padstow",
                "label_ar": "بادستو",
                "label_fr": "Padstow"
            },
            {
                "value": 20346,
                "label": "Moorabbin",
                "label_ar": "مورابين",
                "label_fr": "Moorabbin"
            },
            {
                "value": 20116,
                "label": "Ourimbah",
                "label_ar": "أوريمباه",
                "label_fr": "Ourimbah"
            },
            {
                "value": 20803,
                "label": "Wangi Wangi",
                "label_ar": "وانجي وانجي",
                "label_fr": "Wangi Wangi"
            },
            {
                "value": 20042,
                "label": "Ashgrove",
                "label_ar": "أشجروف",
                "label_fr": "Ashgrove"
            },
            {
                "value": 20750,
                "label": "Streaky Bay",
                "label_ar": "ستريكي باي",
                "label_fr": "Baie striée"
            },
            {
                "value": 20312,
                "label": "Thornbury",
                "label_ar": "ثورنبيري",
                "label_fr": "Thornbury"
            },
            {
                "value": 21936,
                "label": "Cambridge",
                "label_ar": "كامبريدج",
                "label_fr": "Cambridge"
            },
            {
                "value": 21329,
                "label": "Warrnambool",
                "label_ar": "وارنامبول",
                "label_fr": "Warrnambool"
            },
            {
                "value": 21162,
                "label": "Mooney Mooney",
                "label_ar": "موني موني",
                "label_fr": "Mooney Mooney"
            },
            {
                "value": 19979,
                "label": "Brookfield",
                "label_ar": "بروكفيلد",
                "label_fr": "Brookfield"
            },
            {
                "value": 21538,
                "label": "Linden",
                "label_ar": "الزيزفون",
                "label_fr": "Tilleul"
            },
            {
                "value": 21935,
                "label": "Hawker",
                "label_ar": "هوكر",
                "label_fr": "Colporteur"
            },
            {
                "value": 21227,
                "label": "Oakey",
                "label_ar": "أوكي",
                "label_fr": "Oakey"
            },
            {
                "value": 21025,
                "label": "Mooroopna",
                "label_ar": "موروبنا",
                "label_fr": "Mooroopna"
            },
            {
                "value": 20102,
                "label": "Little Hampton",
                "label_ar": "ليتل هامبتون",
                "label_fr": "Little Hampton"
            },
            {
                "value": 20551,
                "label": "Fairy Meadow",
                "label_ar": "فيري ميدو",
                "label_fr": "Prairie de fées"
            },
            {
                "value": 20685,
                "label": "Waitara",
                "label_ar": "ويتارا",
                "label_fr": "Waitara"
            },
            {
                "value": 21645,
                "label": "Mount Perry",
                "label_ar": "جبل بيري",
                "label_fr": "Mont Perry"
            },
            {
                "value": 21728,
                "label": "Yarragon",
                "label_ar": "ياراجون",
                "label_fr": "Yarragon"
            },
            {
                "value": 20077,
                "label": "Iluka",
                "label_ar": "إيلوكا",
                "label_fr": "Iluka"
            },
            {
                "value": 21053,
                "label": "Harristown",
                "label_ar": "هارستاون",
                "label_fr": "Harristown"
            },
            {
                "value": 21064,
                "label": "Worongary",
                "label_ar": "Worongary",
                "label_fr": "Worongary"
            },
            {
                "value": 21005,
                "label": "Clifton Hill",
                "label_ar": "كليفتون هيل",
                "label_fr": "Clifton Hill"
            },
            {
                "value": 21663,
                "label": "Scone",
                "label_ar": "سكون",
                "label_fr": "Scone"
            },
            {
                "value": 20350,
                "label": "Belmont",
                "label_ar": "بلمونت",
                "label_fr": "Belmont"
            },
            {
                "value": 21749,
                "label": "Marshalltown",
                "label_ar": "مارشالتاون",
                "label_fr": "Marshalltown"
            },
            {
                "value": 21703,
                "label": "Risdon",
                "label_ar": "ريسدون",
                "label_fr": "Risdon"
            },
            {
                "value": 21597,
                "label": "Branxton",
                "label_ar": "برانكستون",
                "label_fr": "Branxton"
            },
            {
                "value": 21043,
                "label": "Wahgunyah",
                "label_ar": "وهجونية",
                "label_fr": "Wahgunyah"
            },
            {
                "value": 21803,
                "label": "Tomago",
                "label_ar": "توماغو",
                "label_fr": "Tomago"
            },
            {
                "value": 20877,
                "label": "Ingle Farm",
                "label_ar": "مزرعة إنجل",
                "label_fr": "Ferme Ingle"
            },
            {
                "value": 21669,
                "label": "Winchelsea",
                "label_ar": "وينشلسي",
                "label_fr": "Winchelsea"
            },
            {
                "value": 21911,
                "label": "Wooroolin",
                "label_ar": "Wooroolin",
                "label_fr": "Wooroolin"
            },
            {
                "value": 21908,
                "label": "Brewarrina",
                "label_ar": "بروارينا",
                "label_fr": "Brewarrina"
            },
            {
                "value": 21688,
                "label": "Yallourn North",
                "label_ar": "يالورن الشمالية",
                "label_fr": "Yallourn Nord"
            },
            {
                "value": 21683,
                "label": "Dunsborough",
                "label_ar": "دانسبورو",
                "label_fr": "Dunsborough"
            },
            {
                "value": 20505,
                "label": "Berridale",
                "label_ar": "بيريديل",
                "label_fr": "Berridale"
            },
            {
                "value": 21844,
                "label": "Homebush",
                "label_ar": "هومبوش",
                "label_fr": "Homebush"
            },
            {
                "value": 20047,
                "label": "Laidley",
                "label_ar": "ليدلي",
                "label_fr": "Laidley"
            },
            {
                "value": 21601,
                "label": "Kinglake",
                "label_ar": "كينجليك",
                "label_fr": "Kinglake"
            },
            {
                "value": 21471,
                "label": "Hill Top",
                "label_ar": "هيل توب",
                "label_fr": "Sommet de la colline"
            },
            {
                "value": 20289,
                "label": "Mulgrave",
                "label_ar": "مولجراف",
                "label_fr": "Mulgrave"
            },
            {
                "value": 21036,
                "label": "Drouin",
                "label_ar": "دروين",
                "label_fr": "Drouin"
            },
            {
                "value": 21725,
                "label": "Endeavour",
                "label_ar": "المسعى",
                "label_fr": "Effort"
            },
            {
                "value": 21197,
                "label": "Dundowran",
                "label_ar": "دوندوران",
                "label_fr": "Dundowran"
            },
            {
                "value": 21700,
                "label": "Buxton",
                "label_ar": "بوكستون",
                "label_fr": "Buxton"
            },
            {
                "value": 21378,
                "label": "Bulli",
                "label_ar": "بولي",
                "label_fr": "Bulli"
            },
            {
                "value": 21504,
                "label": "Yorkeys Knob",
                "label_ar": "يوركيس مقبض",
                "label_fr": "Bouton Yorkeys"
            },
            {
                "value": 20363,
                "label": "Balwyn",
                "label_ar": "بالوين",
                "label_fr": "Balwyn"
            },
            {
                "value": 21469,
                "label": "Boat Harbour",
                "label_ar": "ميناء القوارب",
                "label_fr": "Boat Harbour"
            },
            {
                "value": 19977,
                "label": "Enfield",
                "label_ar": "انفيلد",
                "label_fr": "Enfield"
            },
            {
                "value": 21916,
                "label": "Berrima",
                "label_ar": "بيريما",
                "label_fr": "Berrima"
            },
            {
                "value": 20707,
                "label": "Mount Gravatt",
                "label_ar": "جبل Gravatt",
                "label_fr": "Mont Gravatt"
            },
            {
                "value": 21705,
                "label": "Telegraph Point",
                "label_ar": "نقطة التلغراف",
                "label_fr": "Point télégraphique"
            },
            {
                "value": 21793,
                "label": "Port Adelaide",
                "label_ar": "ميناء اديلايد",
                "label_fr": "Port Adélaïde"
            },
            {
                "value": 21836,
                "label": "Freeling",
                "label_ar": "العمل بالقطعة",
                "label_fr": "Freeling"
            },
            {
                "value": 20994,
                "label": "Swan View",
                "label_ar": "عرض بجعة",
                "label_fr": "Vue sur le cygne"
            },
            {
                "value": 20184,
                "label": "Charlestown",
                "label_ar": "تشارلزتاون",
                "label_fr": "Charlestown"
            },
            {
                "value": 20222,
                "label": "Paddington",
                "label_ar": "بادينغتون",
                "label_fr": "Paddington"
            },
            {
                "value": 20498,
                "label": "Byron Bay",
                "label_ar": "خليج بايرون",
                "label_fr": "Byron Bay"
            },
            {
                "value": 21114,
                "label": "Wollongbar",
                "label_ar": "وولونغبار",
                "label_fr": "Wollongbar"
            },
            {
                "value": 20560,
                "label": "Ebbw Vale",
                "label_ar": "إيبو فالي",
                "label_fr": "Ebbw Vale"
            },
            {
                "value": 21299,
                "label": "Mission Beach",
                "label_ar": "ميشن بيتش",
                "label_fr": "Mission Beach"
            },
            {
                "value": 20489,
                "label": "Swansea",
                "label_ar": "سوانزي",
                "label_fr": "Swansea"
            },
            {
                "value": 21661,
                "label": "Dorrigo",
                "label_ar": "دوريجو",
                "label_fr": "Dorrigo"
            },
            {
                "value": 20448,
                "label": "Lara",
                "label_ar": "لارا",
                "label_fr": "Lara"
            },
            {
                "value": 20926,
                "label": "Heddon Greta",
                "label_ar": "هدون جريتا",
                "label_fr": "Heddon Greta"
            },
            {
                "value": 20619,
                "label": "Deception Bay",
                "label_ar": "خليج الخداع",
                "label_fr": "Deception Bay"
            },
            {
                "value": 20628,
                "label": "Gerringong",
                "label_ar": "جيرينجونج",
                "label_fr": "Gerringong"
            },
            {
                "value": 20776,
                "label": "Campbelltown",
                "label_ar": "كامبلتاون",
                "label_fr": "Campbelltown"
            },
            {
                "value": 21608,
                "label": "Brookdale",
                "label_ar": "بروكديل",
                "label_fr": "Brookdale"
            },
            {
                "value": 21153,
                "label": "Dardanup",
                "label_ar": "داردانوب",
                "label_fr": "Dardanup"
            },
            {
                "value": 21620,
                "label": "Middlemount",
                "label_ar": "Middlemount",
                "label_fr": "Middlemount"
            },
            {
                "value": 21636,
                "label": "Beacon",
                "label_ar": "منارة",
                "label_fr": "Balise"
            },
            {
                "value": 21272,
                "label": "Keiraville",
                "label_ar": "كيرافيل",
                "label_fr": "Keiraville"
            },
            {
                "value": 20864,
                "label": "The Basin",
                "label_ar": "الحوض",
                "label_fr": "Le bassin"
            },
            {
                "value": 21110,
                "label": "Trunkey",
                "label_ar": "ترانكي",
                "label_fr": "Trunkey"
            },
            {
                "value": 21218,
                "label": "Salt Ash",
                "label_ar": "ملح الرماد",
                "label_fr": "Cendre de sel"
            },
            {
                "value": 20248,
                "label": "Balgownie",
                "label_ar": "بالجوني",
                "label_fr": "Balgownie"
            },
            {
                "value": 21599,
                "label": "Pontville",
                "label_ar": "بونتفيل",
                "label_fr": "Pontville"
            },
            {
                "value": 21972,
                "label": "Obi Obi",
                "label_ar": "أوبي أوبي",
                "label_fr": "Obi Obi"
            },
            {
                "value": 21003,
                "label": "Rokeby",
                "label_ar": "روكبي",
                "label_fr": "Rokeby"
            },
            {
                "value": 21485,
                "label": "Wandin North",
                "label_ar": "واندين نورث",
                "label_fr": "Wandin Nord"
            },
            {
                "value": 20566,
                "label": "Mordialloc",
                "label_ar": "مورديالوك",
                "label_fr": "Mordialloc"
            },
            {
                "value": 21276,
                "label": "Melville",
                "label_ar": "ملفيل",
                "label_fr": "Melville"
            },
            {
                "value": 21433,
                "label": "Lancelin",
                "label_ar": "لانسيلين",
                "label_fr": "Lancelin"
            },
            {
                "value": 20431,
                "label": "Wendouree",
                "label_ar": "ويندوري",
                "label_fr": "Wendouree"
            },
            {
                "value": 20909,
                "label": "Mitchelton",
                "label_ar": "ميتشيلتون",
                "label_fr": "Mitchelton"
            },
            {
                "value": 20008,
                "label": "Pakenham",
                "label_ar": "باكينهام",
                "label_fr": "Pakenham"
            },
            {
                "value": 20686,
                "label": "Cardiff",
                "label_ar": "كارديف",
                "label_fr": "Cardiff"
            },
            {
                "value": 20679,
                "label": "Seven Hills",
                "label_ar": "سبع تلال",
                "label_fr": "Sept collines"
            },
            {
                "value": 20383,
                "label": "Abbotsford",
                "label_ar": "أبوتسفورد",
                "label_fr": "Abbotsford"
            },
            {
                "value": 21595,
                "label": "Koolewong",
                "label_ar": "كوليونغ",
                "label_fr": "Koolewong"
            },
            {
                "value": 21523,
                "label": "Yeoval",
                "label_ar": "يوفال",
                "label_fr": "Yeoval"
            },
            {
                "value": 20694,
                "label": "Bowen",
                "label_ar": "بوين",
                "label_fr": "Bowen"
            },
            {
                "value": 20038,
                "label": "Gladstone",
                "label_ar": "جلادستون",
                "label_fr": "Gladstone"
            },
            {
                "value": 21278,
                "label": "Batchelor",
                "label_ar": "باتشلور",
                "label_fr": "Batchelor"
            },
            {
                "value": 21974,
                "label": "Broke",
                "label_ar": "حطم",
                "label_fr": "Cassé"
            },
            {
                "value": 20473,
                "label": "Tewantin",
                "label_ar": "تيوانتين",
                "label_fr": "Tewantin"
            },
            {
                "value": 20294,
                "label": "Lalor",
                "label_ar": "لالور",
                "label_fr": "Lalor"
            },
            {
                "value": 20007,
                "label": "Abbotsford",
                "label_ar": "أبوتسفورد",
                "label_fr": "Abbotsford"
            },
            {
                "value": 21204,
                "label": "Kingston",
                "label_ar": "كينغستون",
                "label_fr": "Kingston"
            },
            {
                "value": 20307,
                "label": "Oakleigh",
                "label_ar": "أوكلي",
                "label_fr": "Oakleigh"
            },
            {
                "value": 21708,
                "label": "Tieri",
                "label_ar": "تيري",
                "label_fr": "Tieri"
            },
            {
                "value": 21756,
                "label": "Koroit",
                "label_ar": "كورويت",
                "label_fr": "Koroit"
            },
            {
                "value": 21975,
                "label": "Womboota",
                "label_ar": "ومبوتا",
                "label_fr": "Womboota"
            },
            {
                "value": 21533,
                "label": "Bangalow",
                "label_ar": "بنغالو",
                "label_fr": "Bangalow"
            },
            {
                "value": 21298,
                "label": "Doubtful Creek",
                "label_ar": "مشكوك فيه كريك",
                "label_fr": "Ruisseau douteux"
            },
            {
                "value": 20412,
                "label": "Ayr",
                "label_ar": "عير",
                "label_fr": "Ayr"
            },
            {
                "value": 21382,
                "label": "Diggers Rest",
                "label_ar": "راحة الحفارون",
                "label_fr": "Repos des creuseurs"
            },
            {
                "value": 20029,
                "label": "Nambour",
                "label_ar": "نامبور",
                "label_fr": "Nambour"
            },
            {
                "value": 20322,
                "label": "Bentleigh East",
                "label_ar": "بنتلي الشرق",
                "label_fr": "Bentleigh East"
            },
            {
                "value": 20439,
                "label": "Brighton East",
                "label_ar": "شرق برايتون",
                "label_fr": "Brighton Est"
            },
            {
                "value": 21566,
                "label": "Applecross",
                "label_ar": "أبليكروس",
                "label_fr": "Applecross"
            },
            {
                "value": 20850,
                "label": "Midway Point",
                "label_ar": "ميدواي بوينت",
                "label_fr": "Point médian"
            },
            {
                "value": 20696,
                "label": "Margate",
                "label_ar": "مارجيت",
                "label_fr": "Margate"
            },
            {
                "value": 21192,
                "label": "Goornong",
                "label_ar": "جورنونج",
                "label_fr": "Goornong"
            },
            {
                "value": 21312,
                "label": "Buchan",
                "label_ar": "بوكان",
                "label_fr": "Buchan"
            },
            {
                "value": 20623,
                "label": "Goodna",
                "label_ar": "جودنا",
                "label_fr": "Goodna"
            },
            {
                "value": 21158,
                "label": "Langhorne Creek",
                "label_ar": "لانغورن كريك",
                "label_fr": "Ruisseau Langhorne"
            },
            {
                "value": 21697,
                "label": "Cannonvale",
                "label_ar": "كانونفال",
                "label_fr": "Cannonvale"
            },
            {
                "value": 21879,
                "label": "Glen Huon",
                "label_ar": "جلين هون",
                "label_fr": "Glen Huon"
            },
            {
                "value": 19975,
                "label": "Mapleton",
                "label_ar": "مابلتون",
                "label_fr": "Mapleton"
            },
            {
                "value": 19973,
                "label": "Gladesville",
                "label_ar": "غلاديسفيل",
                "label_fr": "Gladesville"
            },
            {
                "value": 21371,
                "label": "Cremorne",
                "label_ar": "كريمورن",
                "label_fr": "Crémorne"
            },
            {
                "value": 20194,
                "label": "Earlwood",
                "label_ar": "إيرلوود",
                "label_fr": "Earlwood"
            },
            {
                "value": 21225,
                "label": "Nerrigundah",
                "label_ar": "نيريجوندا",
                "label_fr": "Nerrigundah"
            },
            {
                "value": 20785,
                "label": "Birkdale",
                "label_ar": "بيركدال",
                "label_fr": "Birkdale"
            },
            {
                "value": 21589,
                "label": "Dirranbandi",
                "label_ar": "ديرانباندي",
                "label_fr": "Dirranbandi"
            },
            {
                "value": 21562,
                "label": "Mooroopna",
                "label_ar": "موروبنا",
                "label_fr": "Mooroopna"
            },
            {
                "value": 20582,
                "label": "Cannington",
                "label_ar": "كانينجتون",
                "label_fr": "Cannington"
            },
            {
                "value": 20947,
                "label": "Pallarenda",
                "label_ar": "بالاريندا",
                "label_fr": "Pallarenda"
            },
            {
                "value": 21858,
                "label": "Dimboola",
                "label_ar": "ديمبولا",
                "label_fr": "Dimboola"
            },
            {
                "value": 20113,
                "label": "Templestowe Lower",
                "label_ar": "تمبلستو السفلى",
                "label_fr": "Templestowe inférieur"
            },
            {
                "value": 20547,
                "label": "Morisset",
                "label_ar": "موريسيت",
                "label_fr": "Morisset"
            },
            {
                "value": 20588,
                "label": "Corio",
                "label_ar": "كوريو",
                "label_fr": "Corio"
            },
            {
                "value": 21571,
                "label": "Robinvale",
                "label_ar": "روبنفيل",
                "label_fr": "Robinvale"
            },
            {
                "value": 21937,
                "label": "McLachlan",
                "label_ar": "ماكلاتشلان",
                "label_fr": "McLachlan"
            },
            {
                "value": 21716,
                "label": "Spreyton",
                "label_ar": "سبريتون",
                "label_fr": "Spreyton"
            },
            {
                "value": 20716,
                "label": "Lawnton",
                "label_ar": "لونتون",
                "label_fr": "Lawnton"
            },
            {
                "value": 20262,
                "label": "Wickham",
                "label_ar": "ويكهام",
                "label_fr": "Wickham"
            },
            {
                "value": 20931,
                "label": "Woolamai",
                "label_ar": "وولاماي",
                "label_fr": "Woolamai"
            },
            {
                "value": 20557,
                "label": "Coolangatta",
                "label_ar": "كولانجاتا",
                "label_fr": "Coolangatta"
            },
            {
                "value": 20954,
                "label": "Rydalmere",
                "label_ar": "ريدالمير",
                "label_fr": "Rydalmere"
            },
            {
                "value": 21949,
                "label": "Ceduna",
                "label_ar": "سيدونا",
                "label_fr": "Ceduna"
            },
            {
                "value": 20667,
                "label": "Mount Gambier",
                "label_ar": "جبل جامبير",
                "label_fr": "Mont Gambier"
            },
            {
                "value": 20833,
                "label": "Tallebudgera",
                "label_ar": "Tallebudgera",
                "label_fr": "Tallebudgera"
            },
            {
                "value": 20300,
                "label": "Prahran",
                "label_ar": "براهران",
                "label_fr": "Prahran"
            },
            {
                "value": 20408,
                "label": "Pendle Hills",
                "label_ar": "تلال بندل",
                "label_fr": "Pendle Hills"
            },
            {
                "value": 21978,
                "label": "Kyogle",
                "label_ar": "كيوجل",
                "label_fr": "Kyogle"
            },
            {
                "value": 20090,
                "label": "Mount Pleasant",
                "label_ar": "جبل ممتعة",
                "label_fr": "Mount Pleasant"
            },
            {
                "value": 20091,
                "label": "Seaton",
                "label_ar": "سيتون",
                "label_fr": "Seaton"
            },
            {
                "value": 20013,
                "label": "Reedy Creek",
                "label_ar": "ريدي كريك",
                "label_fr": "Reedy Creek"
            },
            {
                "value": 21738,
                "label": "Airlie Beach",
                "label_ar": "شاطئ إيرلي",
                "label_fr": "Plage d'Airlie"
            },
            {
                "value": 21707,
                "label": "Babinda",
                "label_ar": "بابيندا",
                "label_fr": "Babinda"
            },
            {
                "value": 20865,
                "label": "Pymble",
                "label_ar": "بيمبل",
                "label_fr": "Pymble"
            },
            {
                "value": 20086,
                "label": "Belair",
                "label_ar": "بيل اير",
                "label_fr": "Bel Air"
            },
            {
                "value": 20272,
                "label": "Keysborough",
                "label_ar": "كيزبورو",
                "label_fr": "Keysborough"
            },
            {
                "value": 20591,
                "label": "Eaglemont",
                "label_ar": "إيجلمونت",
                "label_fr": "Eaglemont"
            },
            {
                "value": 21235,
                "label": "Bullsbrook",
                "label_ar": "بولسبروك",
                "label_fr": "Bullsbrook"
            },
            {
                "value": 21377,
                "label": "Edgecliff",
                "label_ar": "إدجكليف",
                "label_fr": "Edgecliff"
            },
            {
                "value": 20742,
                "label": "Blackwood",
                "label_ar": "خشب أسود",
                "label_fr": "Bois noir"
            },
            {
                "value": 21837,
                "label": "Corinthia",
                "label_ar": "كورنثيا",
                "label_fr": "Corinthie"
            },
            {
                "value": 21621,
                "label": "Oxley",
                "label_ar": "أوكسلي",
                "label_fr": "Oxley"
            },
            {
                "value": 21264,
                "label": "Kennington",
                "label_ar": "كينينجتون",
                "label_fr": "Kennington"
            },
            {
                "value": 21239,
                "label": "Tempe",
                "label_ar": "تيمبي",
                "label_fr": "Tempe"
            },
            {
                "value": 19998,
                "label": "Brighton",
                "label_ar": "برايتون",
                "label_fr": "Brighton"
            },
            {
                "value": 20287,
                "label": "Sunshine",
                "label_ar": "إشراق",
                "label_fr": "Ensoleillement"
            },
            {
                "value": 21040,
                "label": "Tahmoor",
                "label_ar": "حمور",
                "label_fr": "Tahmoor"
            },
            {
                "value": 21284,
                "label": "Clare",
                "label_ar": "كلير",
                "label_fr": "Clare"
            },
            {
                "value": 20390,
                "label": "Roseville",
                "label_ar": "روزفيل",
                "label_fr": "Roseville"
            },
            {
                "value": 21830,
                "label": "Mount Dandenong",
                "label_ar": "جبل داندينونج",
                "label_fr": "Mont Dandenong"
            },
            {
                "value": 20446,
                "label": "Lilydale",
                "label_ar": "ليليديل",
                "label_fr": "Lilydale"
            },
            {
                "value": 20949,
                "label": "Acacia Ridge",
                "label_ar": "أكاسيا ريدج",
                "label_fr": "Acacia Ridge"
            },
            {
                "value": 21155,
                "label": "Edmonton",
                "label_ar": "ادمونتون",
                "label_fr": "Edmonton"
            },
            {
                "value": 21961,
                "label": "Mullewa",
                "label_ar": "موليوا",
                "label_fr": "Mullewa"
            },
            {
                "value": 21145,
                "label": "Box Hill South",
                "label_ar": "بوكس هيل جنوب",
                "label_fr": "Box Hill Sud"
            },
            {
                "value": 20019,
                "label": "Caloundra",
                "label_ar": "كالوندرا",
                "label_fr": "Caloundra"
            },
            {
                "value": 21121,
                "label": "Mitchell",
                "label_ar": "ميتشل",
                "label_fr": "Mitchell"
            },
            {
                "value": 21834,
                "label": "Bombala",
                "label_ar": "بومبالا",
                "label_fr": "Bombala"
            },
            {
                "value": 21467,
                "label": "Marlee",
                "label_ar": "مارلي",
                "label_fr": "Marlee"
            },
            {
                "value": 20722,
                "label": "Dimbulah",
                "label_ar": "دمبولة",
                "label_fr": "Dimbulah"
            },
            {
                "value": 20067,
                "label": "Byford",
                "label_ar": "Byford",
                "label_fr": "Byford"
            },
            {
                "value": 20587,
                "label": "Kilmore",
                "label_ar": "كيلمور",
                "label_fr": "Kilmore"
            },
            {
                "value": 20602,
                "label": "Rockbank",
                "label_ar": "روكبانك",
                "label_fr": "Rockbank"
            },
            {
                "value": 20321,
                "label": "Frankston",
                "label_ar": "فرانكستون",
                "label_fr": "Frankston"
            },
            {
                "value": 20183,
                "label": "Castle Hill",
                "label_ar": "قلعة هيل",
                "label_fr": "colline du château"
            },
            {
                "value": 20794,
                "label": "Katoomba",
                "label_ar": "كاتومبا",
                "label_fr": "Katoomba"
            },
            {
                "value": 20349,
                "label": "Geelong",
                "label_ar": "جيلونج",
                "label_fr": "Geelong"
            },
            {
                "value": 20240,
                "label": "Saint Ives",
                "label_ar": "القديس ايفيس",
                "label_fr": "Saint Ives"
            },
            {
                "value": 20309,
                "label": "Mooroolbark",
                "label_ar": "مورول بارك",
                "label_fr": "Mooroolbark"
            },
            {
                "value": 21979,
                "label": "Cawongla",
                "label_ar": "Cawongla",
                "label_fr": "Cawongla"
            },
            {
                "value": 21324,
                "label": "Weetah",
                "label_ar": "ويتاه",
                "label_fr": "Weetah"
            },
            {
                "value": 21786,
                "label": "Allora",
                "label_ar": "الورا",
                "label_fr": "Allora"
            },
            {
                "value": 21846,
                "label": "Torquay",
                "label_ar": "توركواي",
                "label_fr": "Torquay"
            },
            {
                "value": 20210,
                "label": "Coogee",
                "label_ar": "كوجي",
                "label_fr": "Coogee"
            },
            {
                "value": 21777,
                "label": "One Tree Hill",
                "label_ar": "واحد تري هيل",
                "label_fr": "One Tree Hill"
            },
            {
                "value": 21388,
                "label": "Cobbitty",
                "label_ar": "كوبيتى",
                "label_fr": "Cobbitty"
            },
            {
                "value": 21710,
                "label": "Normanton",
                "label_ar": "نورمانتون",
                "label_fr": "Normanton"
            },
            {
                "value": 20604,
                "label": "Windsor",
                "label_ar": "وندسور",
                "label_fr": "Windsor"
            },
            {
                "value": 21986,
                "label": "Coledale",
                "label_ar": "كوليديل",
                "label_fr": "Coledale"
            },
            {
                "value": 21850,
                "label": "Blair Athol",
                "label_ar": "بلير أثول",
                "label_fr": "Blair Athol"
            },
            {
                "value": 20073,
                "label": "Geraldton",
                "label_ar": "جيرالدتون",
                "label_fr": "Geraldton"
            },
            {
                "value": 20398,
                "label": "Mount Martha",
                "label_ar": "جبل مارثا",
                "label_fr": "Mont Martha"
            },
            {
                "value": 21108,
                "label": "Kawungan",
                "label_ar": "كاونجان",
                "label_fr": "Kawungan"
            },
            {
                "value": 21263,
                "label": "Wulkuraka",
                "label_ar": "وولكوراكا",
                "label_fr": "Wulkuraka"
            },
            {
                "value": 21652,
                "label": "Horsham",
                "label_ar": "هورشام",
                "label_fr": "Horsham"
            },
            {
                "value": 20114,
                "label": "Bensville",
                "label_ar": "بنسفيل",
                "label_fr": "Bensville"
            },
            {
                "value": 20842,
                "label": "Belconnen",
                "label_ar": "بيلكونين",
                "label_fr": "Belconnen"
            },
            {
                "value": 20808,
                "label": "Kingsthorpe",
                "label_ar": "Kingsthorpe",
                "label_fr": "Kingsthorpe"
            },
            {
                "value": 20127,
                "label": "Marrickville",
                "label_ar": "ماريكفيل",
                "label_fr": "Marrickville"
            },
            {
                "value": 20057,
                "label": "Bargara",
                "label_ar": "برجرة",
                "label_fr": "Bargara"
            },
            {
                "value": 21246,
                "label": "Casterton",
                "label_ar": "كاستيرتون",
                "label_fr": "Casterton"
            },
            {
                "value": 21968,
                "label": "Talmalmo",
                "label_ar": "تالمالمو",
                "label_fr": "Talmalmo"
            },
            {
                "value": 20273,
                "label": "Toorak",
                "label_ar": "توراك",
                "label_fr": "Toorak"
            },
            {
                "value": 21325,
                "label": "Invermay",
                "label_ar": "Invermay",
                "label_fr": "Invermay"
            },
            {
                "value": 20677,
                "label": "Brighton-Le-Sands",
                "label_ar": "برايتون لو ساندس",
                "label_fr": "Brighton-Le-Sands"
            },
            {
                "value": 21342,
                "label": "Golconda",
                "label_ar": "جولكوندا",
                "label_fr": "Golconde"
            },
            {
                "value": 20629,
                "label": "Freshwater",
                "label_ar": "مياه عذبة",
                "label_fr": "Eau fraiche"
            },
            {
                "value": 20232,
                "label": "Mona Vale",
                "label_ar": "منى فالي",
                "label_fr": "Mona Vale"
            },
            {
                "value": 20699,
                "label": "Aspley",
                "label_ar": "اسبلي",
                "label_fr": "Aspley"
            },
            {
                "value": 20088,
                "label": "Balaklava",
                "label_ar": "بالاكلافا",
                "label_fr": "Balaklava"
            },
            {
                "value": 20175,
                "label": "Granville",
                "label_ar": "جرانفيل",
                "label_fr": "Granville"
            },
            {
                "value": 20608,
                "label": "Harrington",
                "label_ar": "هارينجتون",
                "label_fr": "Harrington"
            },
            {
                "value": 20948,
                "label": "Oak Park",
                "label_ar": "حديقة البلوط",
                "label_fr": "Oak Park"
            },
            {
                "value": 20137,
                "label": "Bangor",
                "label_ar": "بانجور",
                "label_fr": "Bangor"
            },
            {
                "value": 20738,
                "label": "Burnley",
                "label_ar": "بيرنلي",
                "label_fr": "Burnley"
            },
            {
                "value": 21219,
                "label": "Bonnells Bay",
                "label_ar": "خليج بونيلز",
                "label_fr": "Bonnells Bay"
            },
            {
                "value": 19972,
                "label": "Wynnum",
                "label_ar": "وينوم",
                "label_fr": "Wynnum"
            },
            {
                "value": 20058,
                "label": "Yaroomba",
                "label_ar": "يارومبا",
                "label_fr": "Yaroomba"
            },
            {
                "value": 21761,
                "label": "Waterloo",
                "label_ar": "واترلو",
                "label_fr": "Waterloo"
            },
            {
                "value": 21948,
                "label": "Point Lookout",
                "label_ar": "نقطة المراقبة",
                "label_fr": "Point de vue"
            },
            {
                "value": 20135,
                "label": "Plympton",
                "label_ar": "بليمبتون",
                "label_fr": "Plympton"
            },
            {
                "value": 20316,
                "label": "Mentone",
                "label_ar": "مينتون",
                "label_fr": "Mentone"
            },
            {
                "value": 20172,
                "label": "Balmain",
                "label_ar": "بالمين",
                "label_fr": "Balmain"
            },
            {
                "value": 21024,
                "label": "Koo-Wee-Rup",
                "label_ar": "كو وي روب",
                "label_fr": "Koo-Wee-Rup"
            },
            {
                "value": 21724,
                "label": "Goodwood",
                "label_ar": "خشب جيد",
                "label_fr": "Bon bois"
            },
            {
                "value": 20830,
                "label": "Glenorie",
                "label_ar": "جلينوري",
                "label_fr": "Glenorie"
            },
            {
                "value": 20327,
                "label": "East Malvern",
                "label_ar": "شرق مالفيرن",
                "label_fr": "East Malvern"
            },
            {
                "value": 21530,
                "label": "Amamoor",
                "label_ar": "عمامور",
                "label_fr": "Amamoor"
            },
            {
                "value": 20822,
                "label": "Forster",
                "label_ar": "فورستر",
                "label_fr": "Forster"
            },
            {
                "value": 21906,
                "label": "Parkerville",
                "label_ar": "باركرفيل",
                "label_fr": "Parkerville"
            },
            {
                "value": 21587,
                "label": "Kojonup",
                "label_ar": "كوجونوب",
                "label_fr": "Kojonup"
            },
            {
                "value": 21295,
                "label": "Bundook",
                "label_ar": "بوندوك",
                "label_fr": "Bundook"
            },
            {
                "value": 21922,
                "label": "Pearcedale",
                "label_ar": "بيرسيدال",
                "label_fr": "Pearcedale"
            },
            {
                "value": 21214,
                "label": "Hunter",
                "label_ar": "صياد",
                "label_fr": "chasseur"
            },
            {
                "value": 20874,
                "label": "Hillcrest",
                "label_ar": "هيلكريست",
                "label_fr": "Hillcrest"
            },
            {
                "value": 20684,
                "label": "Lake Heights",
                "label_ar": "ليك هايتس",
                "label_fr": "Lake Heights"
            },
            {
                "value": 21602,
                "label": "Tallarook",
                "label_ar": "Tallarook",
                "label_fr": "Tallarook"
            },
            {
                "value": 21362,
                "label": "Lindenow",
                "label_ar": "ليندينو",
                "label_fr": "Lindenow"
            },
            {
                "value": 21560,
                "label": "Yallingup",
                "label_ar": "يالينجوب",
                "label_fr": "Yallingup"
            },
            {
                "value": 21691,
                "label": "Urangan",
                "label_ar": "أورانجان",
                "label_fr": "Urangan"
            },
            {
                "value": 21957,
                "label": "Arrawarra",
                "label_ar": "أراوارا",
                "label_fr": "Arrawarra"
            },
            {
                "value": 21384,
                "label": "Empire Bay",
                "label_ar": "إمباير باي",
                "label_fr": "Empire Bay"
            },
            {
                "value": 20254,
                "label": "Leichhardt",
                "label_ar": "ليتشاردت",
                "label_fr": "Leichhardt"
            },
            {
                "value": 21152,
                "label": "Fingal",
                "label_ar": "فنغال",
                "label_fr": "Fingale"
            },
            {
                "value": 20355,
                "label": "Melton",
                "label_ar": "ميلتون",
                "label_fr": "Melton"
            },
            {
                "value": 21664,
                "label": "Stawell",
                "label_ar": "ستاويل",
                "label_fr": "Stawell"
            },
            {
                "value": 21944,
                "label": "Bool Lagoon",
                "label_ar": "بول لاجون",
                "label_fr": "Bool Lagoon"
            },
            {
                "value": 20859,
                "label": "Hope Valley",
                "label_ar": "وادي الأمل",
                "label_fr": "Hope Valley"
            },
            {
                "value": 19956,
                "label": "Ipswich",
                "label_ar": "إبسويتش",
                "label_fr": "Ipswich"
            },
            {
                "value": 21780,
                "label": "Dumbalk",
                "label_ar": "دمبل",
                "label_fr": "Dumbalk"
            },
            {
                "value": 20564,
                "label": "Wallan",
                "label_ar": "الوعلان",
                "label_fr": "Wallan"
            },
            {
                "value": 21895,
                "label": "Lower Chittering",
                "label_ar": "تقشير أقل",
                "label_fr": "Chittering inférieur"
            },
            {
                "value": 21576,
                "label": "Glossodia",
                "label_ar": "جلوسوديا",
                "label_fr": "Glossodia"
            },
            {
                "value": 21796,
                "label": "Queensferry",
                "label_ar": "كوينزفيري",
                "label_fr": "Queensferry"
            },
            {
                "value": 21376,
                "label": "Leura",
                "label_ar": "لورا",
                "label_fr": "Leura"
            },
            {
                "value": 20734,
                "label": "Legana",
                "label_ar": "ليجانا",
                "label_fr": "Legana"
            },
            {
                "value": 21234,
                "label": "Burekup",
                "label_ar": "بوركاب",
                "label_fr": "Burekup"
            },
            {
                "value": 20437,
                "label": "Montmorency",
                "label_ar": "مونتمورنسي",
                "label_fr": "Montmorency"
            },
            {
                "value": 20467,
                "label": "Kurri Kurri",
                "label_ar": "كوري كوري",
                "label_fr": "Kurri Kurri"
            },
            {
                "value": 21945,
                "label": "Valley Heights",
                "label_ar": "مرتفعات الوادي",
                "label_fr": "Valley Heights"
            },
            {
                "value": 20831,
                "label": "Kilcoy",
                "label_ar": "كيلكوي",
                "label_fr": "Kilcoy"
            },
            {
                "value": 21448,
                "label": "Muchea",
                "label_ar": "كثير",
                "label_fr": "Muchea"
            },
            {
                "value": 21872,
                "label": "Mundijong",
                "label_ar": "مونديجونج",
                "label_fr": "Mundijong"
            },
            {
                "value": 20741,
                "label": "Bonython",
                "label_ar": "بونيثون",
                "label_fr": "Bonython"
            },
            {
                "value": 21721,
                "label": "Dodges Ferry",
                "label_ar": "دودج فيري",
                "label_fr": "Dodges Ferry"
            },
            {
                "value": 20838,
                "label": "Luddenham",
                "label_ar": "لودنهام",
                "label_fr": "Luddenham"
            },
            {
                "value": 21398,
                "label": "Boolarra",
                "label_ar": "بولارا",
                "label_fr": "Boolarra"
            },
            {
                "value": 21626,
                "label": "Armstrong Creek",
                "label_ar": "ارمسترونج كريك",
                "label_fr": "Ruisseau Armstrong"
            },
            {
                "value": 21385,
                "label": "Agnes Banks",
                "label_ar": "أغنيس بانكس",
                "label_fr": "Agnes Banks"
            }
        ]
    },
    {
        "country": "Vietnam",
        "cities": [
            {
                "value": 22081,
                "label": "Dong",
                "label_ar": "دونغ",
                "label_fr": "Dong"
            },
            {
                "value": 22018,
                "label": "Tan Binh",
                "label_ar": "تان بنه",
                "label_fr": "Tan Binh"
            },
            {
                "value": 22066,
                "label": "Phu Tho",
                "label_ar": "فو ثو",
                "label_fr": "Phu Tho"
            },
            {
                "value": 22033,
                "label": "Truong Son",
                "label_ar": "ترونج سون",
                "label_fr": "Truong Son"
            },
            {
                "value": 22039,
                "label": "Quan Ngoc",
                "label_ar": "كوان نجوك",
                "label_fr": "Quan Ngoc"
            },
            {
                "value": 22093,
                "label": "Dong Nai",
                "label_ar": "دونغ ناي",
                "label_fr": "Dong Nai"
            },
            {
                "value": 22007,
                "label": "Thanh Pho Thai Nguyen",
                "label_ar": "ثانه فو تاي نغوين",
                "label_fr": "Thanh Pho Thai Nguyen"
            },
            {
                "value": 22043,
                "label": "Tang",
                "label_ar": "تانغ",
                "label_fr": "Soie"
            },
            {
                "value": 22032,
                "label": "Go Vap",
                "label_ar": "اذهب Vap",
                "label_fr": "Allez Vap"
            },
            {
                "value": 22001,
                "label": "Dinh Long",
                "label_ar": "دينه لونج",
                "label_fr": "Dinh Long"
            },
            {
                "value": 22025,
                "label": "Thu Dau Mot",
                "label_ar": "ثو داو موت",
                "label_fr": "Thu Dau Mot"
            },
            {
                "value": 22084,
                "label": "Binh Phuoc",
                "label_ar": "بنه فوك",
                "label_fr": "Binh Phuoc"
            },
            {
                "value": 22075,
                "label": "Quang Nam",
                "label_ar": "كوانج نام",
                "label_fr": "Quang Nam"
            },
            {
                "value": 22089,
                "label": "Ca Mau",
                "label_ar": "كا ماو",
                "label_fr": "Ca Mau"
            },
            {
                "value": 22107,
                "label": "Chuong Duong",
                "label_ar": "تشونغ دوونغ",
                "label_fr": "Chuong Duong"
            },
            {
                "value": 22035,
                "label": "Thanh Pho Thai Binh",
                "label_ar": "ثانه فو تاي بينه",
                "label_fr": "Thanh Pho Thai Binh"
            },
            {
                "value": 22070,
                "label": "An Dinh",
                "label_ar": "دينه",
                "label_fr": "An Dinh"
            },
            {
                "value": 22021,
                "label": "Phuong Tan",
                "label_ar": "فونج تان",
                "label_fr": "Phuong Tan"
            },
            {
                "value": 22103,
                "label": "Cam Pha",
                "label_ar": "كام فا",
                "label_fr": "Cam Pha"
            },
            {
                "value": 22082,
                "label": "Binh Thoai",
                "label_ar": "بنه ثواي",
                "label_fr": "Binh Thoai"
            },
            {
                "value": 22101,
                "label": "Bang",
                "label_ar": "انفجار",
                "label_fr": "Coup"
            },
            {
                "value": 22036,
                "label": "Van Tan",
                "label_ar": "فان تان",
                "label_fr": "Van Tan"
            },
            {
                "value": 22016,
                "label": "Thanh Pho Ninh Binh",
                "label_ar": "ثانه فو نينه",
                "label_fr": "Thanh Pho Ninh Binh"
            },
            {
                "value": 22046,
                "label": "Bac Ninh",
                "label_ar": "باك نينه",
                "label_fr": "Bac Ninh"
            },
            {
                "value": 22003,
                "label": "Nghi Loc",
                "label_ar": "نجي لوك",
                "label_fr": "Nghi Loc"
            },
            {
                "value": 22087,
                "label": "Dong Thap",
                "label_ar": "دونغ ثاب",
                "label_fr": "Dong Thap"
            },
            {
                "value": 22031,
                "label": "Ap Sai Gon",
                "label_ar": "أب ساي غون",
                "label_fr": "Ap Sai Gon"
            },
            {
                "value": 22029,
                "label": "Ben Nghe",
                "label_ar": "بن نغي",
                "label_fr": "Ben Nghe"
            },
            {
                "value": 22015,
                "label": "Long An",
                "label_ar": "لونغ آن",
                "label_fr": "Long An"
            },
            {
                "value": 22009,
                "label": "Huế",
                "label_ar": "مسحة",
                "label_fr": "Teinte"
            },
            {
                "value": 22040,
                "label": "Nha Be",
                "label_ar": "نها بي",
                "label_fr": "Nha Be"
            },
            {
                "value": 22069,
                "label": "Thanh Pho Ha Giang",
                "label_ar": "ثانه فو ها جيانغ",
                "label_fr": "Thanh Pho Ha Giang"
            },
            {
                "value": 22074,
                "label": "Quang Tri",
                "label_ar": "كوانج تري",
                "label_fr": "Quang Tri"
            },
            {
                "value": 21997,
                "label": "Hanoi",
                "label_ar": "هانوي",
                "label_fr": "Hanoï"
            },
            {
                "value": 22096,
                "label": "Ha Dong",
                "label_ar": "ها دونغ",
                "label_fr": "Ha Dong"
            },
            {
                "value": 22055,
                "label": "Di An",
                "label_ar": "دي ان",
                "label_fr": "Di An"
            },
            {
                "value": 22114,
                "label": "Van Hai",
                "label_ar": "فان هاي",
                "label_fr": "Van Hai"
            },
            {
                "value": 22115,
                "label": "Tam Ky",
                "label_ar": "تام كي",
                "label_fr": "Tam Ky"
            },
            {
                "value": 22062,
                "label": "Yen Thanh",
                "label_ar": "ين ثانه",
                "label_fr": "Yen Thanh"
            },
            {
                "value": 22071,
                "label": "Quan Trang",
                "label_ar": "كوان ترانج",
                "label_fr": "Quan Trang"
            },
            {
                "value": 22090,
                "label": "Soc Trang",
                "label_ar": "سوك ترانج",
                "label_fr": "Soc Trang"
            },
            {
                "value": 22053,
                "label": "Ha Nam",
                "label_ar": "ها نام",
                "label_fr": "Ha Nam"
            },
            {
                "value": 22112,
                "label": "Duc Thang",
                "label_ar": "دوك ثانج",
                "label_fr": "Duc Thang"
            },
            {
                "value": 21998,
                "label": "Ho Chi Minh City",
                "label_ar": "مدينة هو تشي مينه",
                "label_fr": "Ho Chi Minh-Ville"
            },
            {
                "value": 22038,
                "label": "Co Giang",
                "label_ar": "كو جيانغ",
                "label_fr": "Co Giang"
            },
            {
                "value": 22030,
                "label": "Phu Nhuan",
                "label_ar": "فو نوان",
                "label_fr": "Phu Nhuan"
            },
            {
                "value": 22050,
                "label": "Thuan An",
                "label_ar": "ثوان أن",
                "label_fr": "Thuan An"
            },
            {
                "value": 22092,
                "label": "Mỹ Tho",
                "label_ar": "مو ثو",
                "label_fr": "Mỹ Tho"
            },
            {
                "value": 22057,
                "label": "Luong Hoi",
                "label_ar": "لونج هوي",
                "label_fr": "Luong Hoi"
            },
            {
                "value": 22116,
                "label": "Truong Lam",
                "label_ar": "ترونج لام",
                "label_fr": "Truong Lam"
            },
            {
                "value": 22060,
                "label": "Qui Nhon",
                "label_ar": "Qui Nhon",
                "label_fr": "Qui Nhon"
            },
            {
                "value": 22002,
                "label": "Vinh",
                "label_ar": "فينه",
                "label_fr": "Vinh"
            },
            {
                "value": 22028,
                "label": "Long Van",
                "label_ar": "لونج فان",
                "label_fr": "Van longue"
            },
            {
                "value": 22095,
                "label": "Lang Dung",
                "label_ar": "لانج دونج",
                "label_fr": "Lang Dung"
            },
            {
                "value": 22024,
                "label": "Xom Thang",
                "label_ar": "Xom Thang",
                "label_fr": "Xom Thang"
            },
            {
                "value": 22049,
                "label": "Vũng Tàu",
                "label_ar": "فونغ تاو",
                "label_fr": "Vũng Tàu"
            },
            {
                "value": 22100,
                "label": "Thach That",
                "label_ar": "ثاتش ذلك",
                "label_fr": "Thach cela"
            },
            {
                "value": 22054,
                "label": "Bien Hoa",
                "label_ar": "بين هوا",
                "label_fr": "Bien Hoa"
            },
            {
                "value": 22063,
                "label": "Bac Kan",
                "label_ar": "باك كان",
                "label_fr": "Bac Kan"
            },
            {
                "value": 22109,
                "label": "Giong Truong",
                "label_ar": "جيونج ترونج",
                "label_fr": "Giong Truong"
            },
            {
                "value": 22073,
                "label": "Huong Tinh",
                "label_ar": "هوونغ تينه",
                "label_fr": "Huong Tinh"
            },
            {
                "value": 22072,
                "label": "Thanh Hóa",
                "label_ar": "ثانه هوا",
                "label_fr": "Thanh Hóa"
            },
            {
                "value": 22012,
                "label": "Khanh Hoa",
                "label_ar": "خانه هوا",
                "label_fr": "Khanh Hoa"
            },
            {
                "value": 22052,
                "label": "Thanh Pho Tuyen Quang",
                "label_ar": "ثانه فو توين كوانج",
                "label_fr": "Thanh Pho Tuyen Quang"
            },
            {
                "value": 22020,
                "label": "Le Loi",
                "label_ar": "لو لوي",
                "label_fr": "Le Loi"
            },
            {
                "value": 22077,
                "label": "An Nhon",
                "label_ar": "آن نون",
                "label_fr": "An Nhon"
            },
            {
                "value": 22042,
                "label": "Bac Giang",
                "label_ar": "باك جيانغ",
                "label_fr": "Bac Giang"
            },
            {
                "value": 22041,
                "label": "Thu Duc",
                "label_ar": "الخميس دوك",
                "label_fr": "Thu Duc"
            },
            {
                "value": 22102,
                "label": "Xom Phuoc My",
                "label_ar": "Xom Phuoc My",
                "label_fr": "Xom Phuoc My"
            },
            {
                "value": 22047,
                "label": "Nha Trang",
                "label_ar": "نها ترانج",
                "label_fr": "Nha Trang"
            },
            {
                "value": 22104,
                "label": "Vinh Long",
                "label_ar": "فينه لونج",
                "label_fr": "Vinh Long"
            },
            {
                "value": 22086,
                "label": "Bến Tre",
                "label_ar": "بين تري",
                "label_fr": "Bến Tre"
            },
            {
                "value": 22083,
                "label": "Thon Bao An",
                "label_ar": "ثون باو آن",
                "label_fr": "Thon Bao An"
            },
            {
                "value": 22061,
                "label": "Buon Ma Thuot",
                "label_ar": "بون ما ثوت",
                "label_fr": "Buon Ma Thuot"
            },
            {
                "value": 22088,
                "label": "An Giang",
                "label_ar": "آن جيانغ",
                "label_fr": "An Giang"
            },
            {
                "value": 22094,
                "label": "Ha Tinh",
                "label_ar": "ها تنه",
                "label_fr": "Ha Tinh"
            },
            {
                "value": 22097,
                "label": "Hoang Mai",
                "label_ar": "هوانغ ماي",
                "label_fr": "Hoang Mai"
            },
            {
                "value": 22000,
                "label": "Thanh Nguyen",
                "label_ar": "ثانه نجوين",
                "label_fr": "Thanh Nguyen"
            },
            {
                "value": 22098,
                "label": "Cho Ha",
                "label_ar": "تشو ها",
                "label_fr": "Cho Ha"
            },
            {
                "value": 22078,
                "label": "Dac Loc",
                "label_ar": "داك لوك",
                "label_fr": "Dac Loc"
            },
            {
                "value": 22080,
                "label": "Kon Tum",
                "label_ar": "كون توم",
                "label_fr": "Kon Tum"
            },
            {
                "value": 22067,
                "label": "Yen Bai",
                "label_ar": "ين باي",
                "label_fr": "Yen Bai"
            },
            {
                "value": 22105,
                "label": "Vi Thanh",
                "label_ar": "السادس ثانه",
                "label_fr": "Vi Thanh"
            },
            {
                "value": 22011,
                "label": "Da Lat",
                "label_ar": "دا لات",
                "label_fr": "Da Lat"
            },
            {
                "value": 22065,
                "label": "Lang Son",
                "label_ar": "لانج سون",
                "label_fr": "Lang Son"
            },
            {
                "value": 22005,
                "label": "Tan Thuan Dong",
                "label_ar": "تان ثوان دونغ",
                "label_fr": "Tan Thuan Dong"
            },
            {
                "value": 22079,
                "label": "Phu Yen",
                "label_ar": "فو ين",
                "label_fr": "Phu Yen"
            },
            {
                "value": 22019,
                "label": "Xom Truong Dinh",
                "label_ar": "Xom ترونج دينه",
                "label_fr": "Xom Truong Dinh"
            },
            {
                "value": 22004,
                "label": "Phu Xuyen",
                "label_ar": "فو زوين",
                "label_fr": "Phu Xuyen"
            },
            {
                "value": 22023,
                "label": "Binh Thanh",
                "label_ar": "بنه ثانه",
                "label_fr": "Binh Thanh"
            },
            {
                "value": 22113,
                "label": "Lang Hong",
                "label_ar": "لانج هونج",
                "label_fr": "Lang Hong"
            },
            {
                "value": 22056,
                "label": "Vinh Yen",
                "label_ar": "فينه ين",
                "label_fr": "Vinh Yen"
            },
            {
                "value": 22059,
                "label": "Quảng Ngãi",
                "label_ar": "Quảng Ngãi",
                "label_fr": "Quảng Ngãi"
            },
            {
                "value": 22091,
                "label": "Tay Ninh",
                "label_ar": "تاي نينه",
                "label_fr": "Tay Ninh"
            },
            {
                "value": 22111,
                "label": "Thuan Cong",
                "label_ar": "ثوان كونغ",
                "label_fr": "Thuan Cong"
            },
            {
                "value": 22064,
                "label": "Lao Cai",
                "label_ar": "لاو كاي",
                "label_fr": "Lao Cai"
            },
            {
                "value": 22117,
                "label": "Trao",
                "label_ar": "تراو",
                "label_fr": "Trao"
            },
            {
                "value": 22014,
                "label": "An Thanh",
                "label_ar": "آن ثان",
                "label_fr": "An Thanh"
            },
            {
                "value": 22013,
                "label": "Quang Trung",
                "label_ar": "كوانج ترونج",
                "label_fr": "Quang Trung"
            },
            {
                "value": 22027,
                "label": "Dien Bien Phu",
                "label_ar": "ديان بيان فو",
                "label_fr": "Dien Bien Phu"
            },
            {
                "value": 22022,
                "label": "Ap Trung",
                "label_ar": "أب ترونج",
                "label_fr": "Ap Trung"
            },
            {
                "value": 22008,
                "label": "Haiphong",
                "label_ar": "هايفونغ",
                "label_fr": "Haiphong"
            },
            {
                "value": 22010,
                "label": "Da Nang",
                "label_ar": "دا نانغ",
                "label_fr": "Da Nang"
            },
            {
                "value": 22058,
                "label": "Phan Thiết",
                "label_ar": "فان ثيت",
                "label_fr": "Phan Thiết"
            },
            {
                "value": 22099,
                "label": "Tra Vinh",
                "label_ar": "ترا فينه",
                "label_fr": "Tra Vinh"
            },
            {
                "value": 22068,
                "label": "Sơn La",
                "label_ar": "سان لا",
                "label_fr": "Sơn La"
            },
            {
                "value": 22034,
                "label": "Thong Nhat",
                "label_ar": "ثونغ نهات",
                "label_fr": "Thong Nhat"
            },
            {
                "value": 21999,
                "label": "Can Tho",
                "label_ar": "يمكن ثو",
                "label_fr": "Can Tho"
            },
            {
                "value": 22108,
                "label": "Minh Khai",
                "label_ar": "مينه خاي",
                "label_fr": "Minh Khai"
            },
            {
                "value": 22045,
                "label": "Thanh Pho Hai Duong",
                "label_ar": "ثانه فو هاي دونج",
                "label_fr": "Thanh Pho Hai Duong"
            },
            {
                "value": 22110,
                "label": "Ben Than",
                "label_ar": "بن ثان",
                "label_fr": "Ben Than"
            },
            {
                "value": 22085,
                "label": "Long Tan",
                "label_ar": "لونغ تان",
                "label_fr": "Bronzage long"
            },
            {
                "value": 22026,
                "label": "Bach Ma",
                "label_ar": "باخ ما",
                "label_fr": "Bach Ma"
            },
            {
                "value": 22048,
                "label": "Pleiku",
                "label_ar": "بليكو",
                "label_fr": "Pleiku"
            },
            {
                "value": 22037,
                "label": "An Tam",
                "label_ar": "تام",
                "label_fr": "Un Tam"
            },
            {
                "value": 22076,
                "label": "Giong Ngai",
                "label_ar": "جيونج نجاي",
                "label_fr": "Giong Ngai"
            },
            {
                "value": 22017,
                "label": "Thanh Pho Hoa Binh",
                "label_ar": "ثانه فو هوا بينه",
                "label_fr": "Thanh Pho Hoa Binh"
            },
            {
                "value": 22106,
                "label": "Phan Thon",
                "label_ar": "فان ثون",
                "label_fr": "Phan Thon"
            },
            {
                "value": 22051,
                "label": "Hung Yen",
                "label_ar": "هونغ ين",
                "label_fr": "Hung Yen"
            },
            {
                "value": 22006,
                "label": "Nam Định",
                "label_ar": "نام nh",
                "label_fr": "Nam Định"
            },
            {
                "value": 22044,
                "label": "Ha Long",
                "label_ar": "ها لونج",
                "label_fr": "Ha Long"
            }
        ]
    },
    {
        "country": "Russia",
        "cities": [
            {
                "value": 22219,
                "label": "Gubkinskiy",
                "label_ar": "جوبكينسكي",
                "label_fr": "Gubkinskiy"
            },
            {
                "value": 22452,
                "label": "Nizhniy Tagil",
                "label_ar": "نيجني تاجيل",
                "label_fr": "Nizhniy Tagil"
            },
            {
                "value": 22186,
                "label": "Chik",
                "label_ar": "تشيك",
                "label_fr": "Chik"
            },
            {
                "value": 23058,
                "label": "Gaurilov",
                "label_ar": "جوريلوف",
                "label_fr": "Gaurilov"
            },
            {
                "value": 22624,
                "label": "Borovichi",
                "label_ar": "بوروفيتشي",
                "label_fr": "Borovichi"
            },
            {
                "value": 22648,
                "label": "Kush'ya",
                "label_ar": "كوشية",
                "label_fr": "Kush'ya"
            },
            {
                "value": 22131,
                "label": "Rostov-on-Don",
                "label_ar": "روستوف اون دون",
                "label_fr": "Rostov-sur-le-Don"
            },
            {
                "value": 22361,
                "label": "Zavodoukovsk",
                "label_ar": "زافودوكوفسك",
                "label_fr": "Zavodoukovsk"
            },
            {
                "value": 22784,
                "label": "Amursk",
                "label_ar": "أمورسك",
                "label_fr": "Amoursk"
            },
            {
                "value": 23023,
                "label": "Zaozersk",
                "label_ar": "زاوزيرسك",
                "label_fr": "Zaozersk"
            },
            {
                "value": 23021,
                "label": "Rossosh'",
                "label_ar": "روسوش",
                "label_fr": "Rossosh '"
            },
            {
                "value": 22529,
                "label": "Kristall",
                "label_ar": "كريستال",
                "label_fr": "Kristall"
            },
            {
                "value": 22473,
                "label": "Alikovo",
                "label_ar": "اليكوفو",
                "label_fr": "Alikovo"
            },
            {
                "value": 22157,
                "label": "Shebekino",
                "label_ar": "شيبكينو",
                "label_fr": "Shebekino"
            },
            {
                "value": 22272,
                "label": "Irkutsk",
                "label_ar": "ايركوتسك",
                "label_fr": "Irkoutsk"
            },
            {
                "value": 22134,
                "label": "Kursk",
                "label_ar": "كورسك",
                "label_fr": "Koursk"
            },
            {
                "value": 22956,
                "label": "Baza",
                "label_ar": "بازا",
                "label_fr": "Baza"
            },
            {
                "value": 22384,
                "label": "Borodynovka",
                "label_ar": "بورودينوفكا",
                "label_fr": "Borodynovka"
            },
            {
                "value": 22168,
                "label": "Kolomna",
                "label_ar": "كولومنا",
                "label_fr": "Kolomna"
            },
            {
                "value": 23149,
                "label": "Tochka",
                "label_ar": "توشكا",
                "label_fr": "Tochka"
            },
            {
                "value": 22435,
                "label": "Ishkulovo",
                "label_ar": "إيشكولوفو",
                "label_fr": "Ishkulovo"
            },
            {
                "value": 22868,
                "label": "Golitsyno",
                "label_ar": "جوليتسينو",
                "label_fr": "Golitsyno"
            },
            {
                "value": 22951,
                "label": "Perovka",
                "label_ar": "بيروفكا",
                "label_fr": "Perovka"
            },
            {
                "value": 23106,
                "label": "Krasnoobsk",
                "label_ar": "كراسنووبسك",
                "label_fr": "Krasnoobsk"
            },
            {
                "value": 23146,
                "label": "Ruzayevka",
                "label_ar": "روزايفكا",
                "label_fr": "Ruzayevka"
            },
            {
                "value": 22445,
                "label": "Novocheboksarsk",
                "label_ar": "نوفوتشيبوكسارسك",
                "label_fr": "Novocheboksarsk"
            },
            {
                "value": 22507,
                "label": "Parkovyy",
                "label_ar": "باركوفي",
                "label_fr": "Parkovyy"
            },
            {
                "value": 22331,
                "label": "Maykop",
                "label_ar": "مايكوب",
                "label_fr": "Maykop"
            },
            {
                "value": 22649,
                "label": "Kizner",
                "label_ar": "كيزنر",
                "label_fr": "Kizner"
            },
            {
                "value": 22571,
                "label": "Obninsk",
                "label_ar": "أوبنينسك",
                "label_fr": "Obninsk"
            },
            {
                "value": 23063,
                "label": "Kiryabinskoye",
                "label_ar": "كيريابنسكوي",
                "label_fr": "Kiryabinskoye"
            },
            {
                "value": 22527,
                "label": "Zvenigorod",
                "label_ar": "زفينيجورود",
                "label_fr": "Zvenigorod"
            },
            {
                "value": 23182,
                "label": "Polyarnyy",
                "label_ar": "تعدد الأزواج",
                "label_fr": "Polyarnyy"
            },
            {
                "value": 22932,
                "label": "Novoselovo",
                "label_ar": "نوفوسيلوفو",
                "label_fr": "Novoselovo"
            },
            {
                "value": 22268,
                "label": "Syzran'",
                "label_ar": "سيزران",
                "label_fr": "Syzran '"
            },
            {
                "value": 22235,
                "label": "Severomorsk",
                "label_ar": "سيفيرومورسك",
                "label_fr": "Severomorsk"
            },
            {
                "value": 23241,
                "label": "Medvedevo",
                "label_ar": "ميدفيديفو",
                "label_fr": "Medvedevo"
            },
            {
                "value": 22675,
                "label": "Bologoye",
                "label_ar": "بولوغوي",
                "label_fr": "Bologoye"
            },
            {
                "value": 22862,
                "label": "Dedovsk",
                "label_ar": "ديدوفسك",
                "label_fr": "Dedovsk"
            },
            {
                "value": 22839,
                "label": "Novomoskovsk",
                "label_ar": "نوفوموسكوفسك",
                "label_fr": "Novomoskovsk"
            },
            {
                "value": 22891,
                "label": "Novaya Igirma",
                "label_ar": "نوفايا إغيرما",
                "label_fr": "Novaya Igirma"
            },
            {
                "value": 22417,
                "label": "Tambov",
                "label_ar": "تامبوف",
                "label_fr": "Tambov"
            },
            {
                "value": 22234,
                "label": "Murmansk",
                "label_ar": "مورمانسك",
                "label_fr": "Mourmansk"
            },
            {
                "value": 22468,
                "label": "Kamenolomni",
                "label_ar": "كامينولومني",
                "label_fr": "Kamenolomni"
            },
            {
                "value": 22597,
                "label": "Ozëry",
                "label_ar": "أوزوري",
                "label_fr": "Ozëry"
            },
            {
                "value": 22350,
                "label": "Kingisepp",
                "label_ar": "Kingisepp",
                "label_fr": "Kingisepp"
            },
            {
                "value": 22126,
                "label": "Sayanogorsk",
                "label_ar": "سايانوجورسك",
                "label_fr": "Sayanogorsk"
            },
            {
                "value": 22746,
                "label": "Sharnutovskiy",
                "label_ar": "شارناتوفسكي",
                "label_fr": "Sharnutovskiy"
            },
            {
                "value": 22968,
                "label": "Aro",
                "label_ar": "أرو",
                "label_fr": "Aro"
            },
            {
                "value": 22923,
                "label": "Nikulin",
                "label_ar": "نيكولين",
                "label_fr": "Nikulin"
            },
            {
                "value": 22707,
                "label": "Vichuga",
                "label_ar": "فيشوجا",
                "label_fr": "Vichuga"
            },
            {
                "value": 22370,
                "label": "Ishim",
                "label_ar": "هو",
                "label_fr": "Est lui"
            },
            {
                "value": 22201,
                "label": "Zarechenskiy",
                "label_ar": "زاريشنسكي",
                "label_fr": "Zarechenskiy"
            },
            {
                "value": 23124,
                "label": "Neya",
                "label_ar": "نيا",
                "label_fr": "Neya"
            },
            {
                "value": 22287,
                "label": "Baranchinskiy",
                "label_ar": "بارانشينسكي",
                "label_fr": "Baranchinskiy"
            },
            {
                "value": 23089,
                "label": "Nizhneyashkino",
                "label_ar": "نيجنياشكينو",
                "label_fr": "Nizhneyashkino"
            },
            {
                "value": 22436,
                "label": "Birsk",
                "label_ar": "بيرسك",
                "label_fr": "Birsk"
            },
            {
                "value": 23225,
                "label": "Tazovskiy",
                "label_ar": "تازوفسكي",
                "label_fr": "Tazovskiy"
            },
            {
                "value": 22614,
                "label": "Pavlovskiy Posad",
                "label_ar": "بافلوفسكي بوساد",
                "label_fr": "Pavlovskiy Posad"
            },
            {
                "value": 22600,
                "label": "Orekhovo-Zuyevo",
                "label_ar": "Orekhovo-Zuyevo",
                "label_fr": "Orekhovo-Zuyevo"
            },
            {
                "value": 23161,
                "label": "Shoshma",
                "label_ar": "شوشما",
                "label_fr": "Shoshma"
            },
            {
                "value": 23174,
                "label": "Rikasikha",
                "label_ar": "ريكاسيخا",
                "label_fr": "Rikasikha"
            },
            {
                "value": 22320,
                "label": "Lakinsk",
                "label_ar": "لاكينسك",
                "label_fr": "Lakinsk"
            },
            {
                "value": 23135,
                "label": "Danilov",
                "label_ar": "دانيلوف",
                "label_fr": "Danilov"
            },
            {
                "value": 22127,
                "label": "Chernogorsk",
                "label_ar": "تشيرنوغورسك",
                "label_fr": "Tchernogorsk"
            },
            {
                "value": 22365,
                "label": "Kachkanar",
                "label_ar": "كاتشكانار",
                "label_fr": "Kachkanar"
            },
            {
                "value": 22154,
                "label": "Cherepovets",
                "label_ar": "تشيريبوفيتس",
                "label_fr": "Cherepovets"
            },
            {
                "value": 22798,
                "label": "Avangard",
                "label_ar": "أفانغارد",
                "label_fr": "Avangard"
            },
            {
                "value": 22511,
                "label": "Novokorsunskaya",
                "label_ar": "نوفوكورسونسكايا",
                "label_fr": "Novokorsunskaya"
            },
            {
                "value": 22120,
                "label": "Peschanka",
                "label_ar": "بيشانكا",
                "label_fr": "Peschanka"
            },
            {
                "value": 22617,
                "label": "Manturovo",
                "label_ar": "مانتوروفو",
                "label_fr": "Manturovo"
            },
            {
                "value": 22285,
                "label": "Novovoronezh",
                "label_ar": "نوفوفورونيج",
                "label_fr": "Novovoronezh"
            },
            {
                "value": 22805,
                "label": "Sistema",
                "label_ar": "سيستيما",
                "label_fr": "Sistema"
            },
            {
                "value": 22780,
                "label": "Millerovo",
                "label_ar": "ميليروفو",
                "label_fr": "Millerovo"
            },
            {
                "value": 22935,
                "label": "Kolpashevo",
                "label_ar": "كولباشيفو",
                "label_fr": "Kolpashevo"
            },
            {
                "value": 23081,
                "label": "Sibir'",
                "label_ar": "سيبير",
                "label_fr": "Sibir '"
            },
            {
                "value": 23015,
                "label": "Singer'",
                "label_ar": "مغني",
                "label_fr": "Chanteur'"
            },
            {
                "value": 22491,
                "label": "Belgorod",
                "label_ar": "بيلغورود",
                "label_fr": "Belgorod"
            },
            {
                "value": 22861,
                "label": "Izberbash",
                "label_ar": "إيزبيرباش",
                "label_fr": "Izberbash"
            },
            {
                "value": 23121,
                "label": "Sredneuralsk",
                "label_ar": "سريدنورالسك",
                "label_fr": "Sredneuralsk"
            },
            {
                "value": 22379,
                "label": "Yurga",
                "label_ar": "يورجا",
                "label_fr": "Yurga"
            },
            {
                "value": 23266,
                "label": "Krasnoznamensk",
                "label_ar": "كراسنوزامينسك",
                "label_fr": "Krasnoznamensk"
            },
            {
                "value": 22312,
                "label": "Kurgan",
                "label_ar": "كورغان",
                "label_fr": "Kurgan"
            },
            {
                "value": 22512,
                "label": "Lesodacha",
                "label_ar": "ليسوداتشا",
                "label_fr": "Lesodacha"
            },
            {
                "value": 22319,
                "label": "Sobinka",
                "label_ar": "سوبينكا",
                "label_fr": "Sobinka"
            },
            {
                "value": 22351,
                "label": "Chita",
                "label_ar": "تشيتا",
                "label_fr": "Chita"
            },
            {
                "value": 22492,
                "label": "Orël",
                "label_ar": "Orël",
                "label_fr": "Orël"
            },
            {
                "value": 22682,
                "label": "Slantsy",
                "label_ar": "مائل",
                "label_fr": "Slantsy"
            },
            {
                "value": 22995,
                "label": "Kalinina",
                "label_ar": "كالينينا",
                "label_fr": "Kalinina"
            },
            {
                "value": 23109,
                "label": "Turinsk",
                "label_ar": "تورينسك",
                "label_fr": "Turinsk"
            },
            {
                "value": 23009,
                "label": "Belozërsk",
                "label_ar": "بيلوزرسك",
                "label_fr": "Belozërsk"
            },
            {
                "value": 22209,
                "label": "Chyorny Yar",
                "label_ar": "تشيورني يار",
                "label_fr": "Chyorny Yar"
            },
            {
                "value": 22355,
                "label": "Novokruchininskiy",
                "label_ar": "نوفوكروشينينسكي",
                "label_fr": "Novokruchininskiy"
            },
            {
                "value": 22180,
                "label": "Miass",
                "label_ar": "مياس",
                "label_fr": "Miass"
            },
            {
                "value": 22775,
                "label": "Bovykiny",
                "label_ar": "بوفيكيني",
                "label_fr": "Bovykiny"
            },
            {
                "value": 22769,
                "label": "Akhtubinsk",
                "label_ar": "أخطوبنسك",
                "label_fr": "Akhtubinsk"
            },
            {
                "value": 22980,
                "label": "Kirgiz-Miyaki",
                "label_ar": "كيرجيز مياكي",
                "label_fr": "Kirgiz-Miyaki"
            },
            {
                "value": 22642,
                "label": "Naryan-Mar",
                "label_ar": "ناريان مار",
                "label_fr": "Naryan-Mar"
            },
            {
                "value": 22313,
                "label": "Tagil",
                "label_ar": "تاجيل",
                "label_fr": "Tagil"
            },
            {
                "value": 22893,
                "label": "Poronaysk",
                "label_ar": "بورونايسك",
                "label_fr": "Poronaysk"
            },
            {
                "value": 23202,
                "label": "Kuz'minka",
                "label_ar": "كوزمينكا",
                "label_fr": "Kuz'minka"
            },
            {
                "value": 22681,
                "label": "Kotel'niki",
                "label_ar": "Kotel'niki",
                "label_fr": "Kotel'niki"
            },
            {
                "value": 22515,
                "label": "Leninskiy",
                "label_ar": "لينينسكي",
                "label_fr": "Leninskiy"
            },
            {
                "value": 22641,
                "label": "Morozovo",
                "label_ar": "موروزوفو",
                "label_fr": "Morozovo"
            },
            {
                "value": 22415,
                "label": "Kopeysk",
                "label_ar": "كوبيسك",
                "label_fr": "Kopeysk"
            },
            {
                "value": 22118,
                "label": "Moscow",
                "label_ar": "موسكو",
                "label_fr": "Moscou"
            },
            {
                "value": 22493,
                "label": "Kromy",
                "label_ar": "كرومي",
                "label_fr": "Kromy"
            },
            {
                "value": 22455,
                "label": "Zheleznovodsk",
                "label_ar": "جيليزنوفودسك",
                "label_fr": "Zheleznovodsk"
            },
            {
                "value": 22905,
                "label": "Troitsk",
                "label_ar": "ترويتسك",
                "label_fr": "Troitsk"
            },
            {
                "value": 22552,
                "label": "Furmanov",
                "label_ar": "فورمانوف",
                "label_fr": "Furmanov"
            },
            {
                "value": 22281,
                "label": "Yoshkar-Ola",
                "label_ar": "يوشكار أولا",
                "label_fr": "Yoshkar-Ola"
            },
            {
                "value": 23065,
                "label": "Lesnoy",
                "label_ar": "ليسنوي",
                "label_fr": "Lesnoy"
            },
            {
                "value": 23165,
                "label": "Usinskoye",
                "label_ar": "أوسينسكوي",
                "label_fr": "Usinskoïe"
            },
            {
                "value": 22998,
                "label": "Selyatino",
                "label_ar": "سيلياتينو",
                "label_fr": "Selyatino"
            },
            {
                "value": 22919,
                "label": "Komsomol'sk",
                "label_ar": "كومسومولسك",
                "label_fr": "Komsomol'sk"
            },
            {
                "value": 23194,
                "label": "Linda",
                "label_ar": "ليندا",
                "label_fr": "Linda"
            },
            {
                "value": 22229,
                "label": "Ukhta",
                "label_ar": "أوختا",
                "label_fr": "Ukhta"
            },
            {
                "value": 22390,
                "label": "Abinsk",
                "label_ar": "أبينسك",
                "label_fr": "Abinsk"
            },
            {
                "value": 22207,
                "label": "Svetlyy",
                "label_ar": "سفيتي",
                "label_fr": "Svetlyy"
            },
            {
                "value": 23267,
                "label": "Svetlaya",
                "label_ar": "سفتلايا",
                "label_fr": "Svetlaya"
            },
            {
                "value": 22253,
                "label": "Bryansk",
                "label_ar": "بريانسك",
                "label_fr": "Briansk"
            },
            {
                "value": 23264,
                "label": "Trubchevsk",
                "label_ar": "تروبشيفسك",
                "label_fr": "Trubchevsk"
            },
            {
                "value": 22761,
                "label": "Sergiyevskoye",
                "label_ar": "سيرجيفسكوي",
                "label_fr": "Sergiyevskoye"
            },
            {
                "value": 22282,
                "label": "Bibayevo",
                "label_ar": "بيبييفو",
                "label_fr": "Bibayevo"
            },
            {
                "value": 22853,
                "label": "Radist",
                "label_ar": "راديست",
                "label_fr": "Radist"
            },
            {
                "value": 22494,
                "label": "Kamyshovka",
                "label_ar": "كاميشوفكا",
                "label_fr": "Kamyshovka"
            },
            {
                "value": 22735,
                "label": "Tyrma",
                "label_ar": "تيرما",
                "label_fr": "Tyrma"
            },
            {
                "value": 22534,
                "label": "Ishimbay",
                "label_ar": "إيشيمباي",
                "label_fr": "Ishimbay"
            },
            {
                "value": 22325,
                "label": "Novosokolovogorskiy",
                "label_ar": "نوفوسوكولوفوجورسكي",
                "label_fr": "Novosokolovogorskiy"
            },
            {
                "value": 22909,
                "label": "Lodeynoye Pole",
                "label_ar": "لودينوي بول",
                "label_fr": "Pôle Lodeynoye"
            },
            {
                "value": 22848,
                "label": "Povarovo",
                "label_ar": "بوفاروفو",
                "label_fr": "Povarovo"
            },
            {
                "value": 22505,
                "label": "Leningradskaya",
                "label_ar": "لينينغرادسكايا",
                "label_fr": "Leningradskaya"
            },
            {
                "value": 22716,
                "label": "Staraya Kupavna",
                "label_ar": "ستارايا كوبافنا",
                "label_fr": "Staraya Kupavna"
            },
            {
                "value": 22876,
                "label": "Kholmistyy",
                "label_ar": "الخولميستي",
                "label_fr": "Kholmistyy"
            },
            {
                "value": 23010,
                "label": "Belyy",
                "label_ar": "بيلي",
                "label_fr": "Belyy"
            },
            {
                "value": 22827,
                "label": "Sartykov",
                "label_ar": "سارتيكوف",
                "label_fr": "Sartykov"
            },
            {
                "value": 22385,
                "label": "Kurganinsk",
                "label_ar": "كورغانينسك",
                "label_fr": "Kurganinsk"
            },
            {
                "value": 22196,
                "label": "Vinsady",
                "label_ar": "فينسادي",
                "label_fr": "Vinsady"
            },
            {
                "value": 23094,
                "label": "Bogdashino",
                "label_ar": "بوغداشينو",
                "label_fr": "Bogdashino"
            },
            {
                "value": 23044,
                "label": "Mozhaysk",
                "label_ar": "Mozhaysk",
                "label_fr": "Mozhaysk"
            },
            {
                "value": 23125,
                "label": "Vyazniki",
                "label_ar": "فيازنيكي",
                "label_fr": "Vyazniki"
            },
            {
                "value": 22548,
                "label": "Asbest",
                "label_ar": "كأفضل",
                "label_fr": "Au meilleur des cas"
            },
            {
                "value": 22442,
                "label": "Ural",
                "label_ar": "الأورال",
                "label_fr": "Oural"
            },
            {
                "value": 23222,
                "label": "Bulgakov",
                "label_ar": "بولجاكوف",
                "label_fr": "Boulgakov"
            },
            {
                "value": 22807,
                "label": "Kanash",
                "label_ar": "كاناش",
                "label_fr": "Kanash"
            },
            {
                "value": 22286,
                "label": "Rossosh'",
                "label_ar": "روسوش",
                "label_fr": "Rossosh '"
            },
            {
                "value": 23068,
                "label": "Nevskiy",
                "label_ar": "نيفسكي",
                "label_fr": "Nevskiy"
            },
            {
                "value": 23268,
                "label": "Ostrov",
                "label_ar": "أوستروف",
                "label_fr": "Ostrov"
            },
            {
                "value": 22870,
                "label": "Novyy",
                "label_ar": "نوفي",
                "label_fr": "Novyy"
            },
            {
                "value": 22664,
                "label": "Zeya",
                "label_ar": "زيا",
                "label_fr": "Zeya"
            },
            {
                "value": 22727,
                "label": "Vladykino",
                "label_ar": "فلاديكينو",
                "label_fr": "Vladykino"
            },
            {
                "value": 23246,
                "label": "Tsudakhar",
                "label_ar": "تسودخار",
                "label_fr": "Tsudakhar"
            },
            {
                "value": 22757,
                "label": "Krasnoznamensk",
                "label_ar": "كراسنوزامينسك",
                "label_fr": "Krasnoznamensk"
            },
            {
                "value": 22133,
                "label": "Smolensk",
                "label_ar": "سمولينسك",
                "label_fr": "Smolensk"
            },
            {
                "value": 22255,
                "label": "Pavlovsk",
                "label_ar": "بافلوفسك",
                "label_fr": "Pavlovsk"
            },
            {
                "value": 22579,
                "label": "Zhukovskogo",
                "label_ar": "جوكوفسكوجو",
                "label_fr": "Zhukovskogo"
            },
            {
                "value": 23046,
                "label": "Balashikha",
                "label_ar": "بلاشيخة",
                "label_fr": "Balashikha"
            },
            {
                "value": 23204,
                "label": "Apazovo",
                "label_ar": "أبازوفو",
                "label_fr": "Apazovo"
            },
            {
                "value": 22295,
                "label": "Dyurtyuli",
                "label_ar": "ديورتولي",
                "label_fr": "Dyurtyuli"
            },
            {
                "value": 22996,
                "label": "Sortavala",
                "label_ar": "سورتافالا",
                "label_fr": "Sortavala"
            },
            {
                "value": 22322,
                "label": "Balakovo",
                "label_ar": "بالاكوفو",
                "label_fr": "Balakovo"
            },
            {
                "value": 22800,
                "label": "Chastyye",
                "label_ar": "Chastyye",
                "label_fr": "Chastyye"
            },
            {
                "value": 22999,
                "label": "Vrangel'",
                "label_ar": "فرانجيل",
                "label_fr": "Vrangel '"
            },
            {
                "value": 22461,
                "label": "Sovetskaya Gavan'",
                "label_ar": "سوفيتسكايا جافان",
                "label_fr": "Sovetskaya Gavan '"
            },
            {
                "value": 22789,
                "label": "Khangash-Yurt",
                "label_ar": "كانجاش يورت",
                "label_fr": "Khangash-Yourte"
            },
            {
                "value": 23070,
                "label": "Uzlovaya",
                "label_ar": "اوزلوفايا",
                "label_fr": "Uzlovaya"
            },
            {
                "value": 22705,
                "label": "Dolgoprudnyy",
                "label_ar": "دولجوبروديني",
                "label_fr": "Dolgoprudnyy"
            },
            {
                "value": 23055,
                "label": "Atkarsk",
                "label_ar": "أتكارسك",
                "label_fr": "Atkarsk"
            },
            {
                "value": 22818,
                "label": "Krasnoarmeysk",
                "label_ar": "كراسنوارميسك",
                "label_fr": "Krasnoarmeysk"
            },
            {
                "value": 22875,
                "label": "Nazarovo",
                "label_ar": "نزاروفو",
                "label_fr": "Nazarovo"
            },
            {
                "value": 22709,
                "label": "Derbent",
                "label_ar": "ديربنت",
                "label_fr": "Derbent"
            },
            {
                "value": 23180,
                "label": "Revda",
                "label_ar": "Revda",
                "label_fr": "Revda"
            },
            {
                "value": 23028,
                "label": "Inta",
                "label_ar": "إنتا",
                "label_fr": "Inta"
            },
            {
                "value": 22984,
                "label": "Rezh",
                "label_ar": "رضا",
                "label_fr": "Rezh"
            },
            {
                "value": 22694,
                "label": "Lukhovitsy",
                "label_ar": "لوكوفيتسي",
                "label_fr": "Lukhovitsy"
            },
            {
                "value": 22333,
                "label": "Bratsk",
                "label_ar": "براتسك",
                "label_fr": "Bratsk"
            },
            {
                "value": 22563,
                "label": "Sharapova Okhota",
                "label_ar": "شارابوفا أوخوتا",
                "label_fr": "Sharapova Okhota"
            },
            {
                "value": 23188,
                "label": "Sebezh",
                "label_ar": "سيبيز",
                "label_fr": "Sebezh"
            },
            {
                "value": 23223,
                "label": "Bogdanovich",
                "label_ar": "بوجدانوفيتش",
                "label_fr": "Bogdanovich"
            },
            {
                "value": 23254,
                "label": "Kamensk",
                "label_ar": "كامينسك",
                "label_fr": "Kamensk"
            },
            {
                "value": 22978,
                "label": "Tatlybayevo",
                "label_ar": "تاتليباييفو",
                "label_fr": "Tatlybayevo"
            },
            {
                "value": 22387,
                "label": "Starovelichkovskaya",
                "label_ar": "Starovelichkovskaya",
                "label_fr": "Starovelichkovskaya"
            },
            {
                "value": 22284,
                "label": "Vladikavkaz",
                "label_ar": "فلاديكافكاز",
                "label_fr": "Vladikavkaz"
            },
            {
                "value": 22889,
                "label": "Stupino",
                "label_ar": "ستوبينو",
                "label_fr": "Stupino"
            },
            {
                "value": 23150,
                "label": "Aprelevka",
                "label_ar": "أبريليفكا",
                "label_fr": "Aprelevka"
            },
            {
                "value": 22592,
                "label": "Bol'shak",
                "label_ar": "بلعشك",
                "label_fr": "Bol'shak"
            },
            {
                "value": 22551,
                "label": "Vyatka",
                "label_ar": "فياتكا",
                "label_fr": "Vyatka"
            },
            {
                "value": 22368,
                "label": "Chebarkul'",
                "label_ar": "شيباركول",
                "label_fr": "Chebarkul '"
            },
            {
                "value": 22990,
                "label": "Karachev",
                "label_ar": "كاراتشيف",
                "label_fr": "Karachev"
            },
            {
                "value": 22808,
                "label": "China",
                "label_ar": "الصين",
                "label_fr": "Chine"
            },
            {
                "value": 22657,
                "label": "Bugul'ma",
                "label_ar": "بوجولما",
                "label_fr": "Bugul'ma"
            },
            {
                "value": 22731,
                "label": "Trekhgornyy",
                "label_ar": "Trekhgornyy",
                "label_fr": "Trekhgornyy"
            },
            {
                "value": 23173,
                "label": "Michurinsk",
                "label_ar": "ميتشورينسك",
                "label_fr": "Michurinsk"
            },
            {
                "value": 22323,
                "label": "Engel's",
                "label_ar": "إنجل",
                "label_fr": "Engel"
            },
            {
                "value": 22989,
                "label": "Novozybkov",
                "label_ar": "نوفوزيبكوف",
                "label_fr": "Novozybkov"
            },
            {
                "value": 23086,
                "label": "Enkheluk",
                "label_ar": "إنخلوك",
                "label_fr": "Enkheluk"
            },
            {
                "value": 22269,
                "label": "Nizhniy Novgorod",
                "label_ar": "نيجني نوفغورود",
                "label_fr": "Nizhniy Novgorod"
            },
            {
                "value": 23234,
                "label": "Medvenka",
                "label_ar": "ميدفينكا",
                "label_fr": "Medvenka"
            },
            {
                "value": 22338,
                "label": "Shelekhov",
                "label_ar": "شليخوف",
                "label_fr": "Chelekhov"
            },
            {
                "value": 22766,
                "label": "Gogolya",
                "label_ar": "جوجوليا",
                "label_fr": "Gogolya"
            },
            {
                "value": 22291,
                "label": "Pskov",
                "label_ar": "بسكوف",
                "label_fr": "Pskov"
            },
            {
                "value": 22788,
                "label": "Rodniki",
                "label_ar": "رودنيكي",
                "label_fr": "Rodniki"
            },
            {
                "value": 22213,
                "label": "Tarko-Sale",
                "label_ar": "تاركو سيل",
                "label_fr": "Tarko-Sale"
            },
            {
                "value": 23017,
                "label": "Krugloye",
                "label_ar": "كروغلوي",
                "label_fr": "Krugloye"
            },
            {
                "value": 23243,
                "label": "Nevel'sk",
                "label_ar": "نيفيلسك",
                "label_fr": "Nevel'sk"
            },
            {
                "value": 22910,
                "label": "Podporozh'ye",
                "label_ar": "بودبوروجي",
                "label_fr": "Podporozh'ye"
            },
            {
                "value": 22985,
                "label": "Ocher",
                "label_ar": "أكسيد الرصاص",
                "label_fr": "Ocre"
            },
            {
                "value": 22424,
                "label": "Brod",
                "label_ar": "برود",
                "label_fr": "Brod"
            },
            {
                "value": 23091,
                "label": "Yashkino",
                "label_ar": "ياشكينو",
                "label_fr": "Yashkino"
            },
            {
                "value": 22242,
                "label": "Sergeyevka",
                "label_ar": "سيرجيفكا",
                "label_fr": "Sergeyevka"
            },
            {
                "value": 22797,
                "label": "Korkino",
                "label_ar": "كوركينو",
                "label_fr": "Korkino"
            },
            {
                "value": 22412,
                "label": "Tayga",
                "label_ar": "تايجا",
                "label_fr": "Tayga"
            },
            {
                "value": 22635,
                "label": "Usinsk",
                "label_ar": "أوسينسك",
                "label_fr": "Usinsk"
            },
            {
                "value": 22826,
                "label": "Troitsk",
                "label_ar": "ترويتسك",
                "label_fr": "Troitsk"
            },
            {
                "value": 22288,
                "label": "Domodedovo",
                "label_ar": "دوموديدوفو",
                "label_fr": "Domodedovo"
            },
            {
                "value": 22890,
                "label": "Kamennomostskiy",
                "label_ar": "كامينوموستسكي",
                "label_fr": "Kamennomostskiy"
            },
            {
                "value": 22265,
                "label": "Magnitogorsk",
                "label_ar": "ماجنيتوجورسك",
                "label_fr": "Magnitogorsk"
            },
            {
                "value": 22794,
                "label": "Sary",
                "label_ar": "ساري",
                "label_fr": "Sary"
            },
            {
                "value": 23217,
                "label": "Valentin",
                "label_ar": "فالنتين",
                "label_fr": "Valentin"
            },
            {
                "value": 22950,
                "label": "Monino",
                "label_ar": "مونينو",
                "label_fr": "Monino"
            },
            {
                "value": 22439,
                "label": "Aramil",
                "label_ar": "أراميل",
                "label_fr": "Aramil"
            },
            {
                "value": 22835,
                "label": "Sverdlovskaya",
                "label_ar": "سفيردلوفسكايا",
                "label_fr": "Sverdlovskaya"
            },
            {
                "value": 23152,
                "label": "Zaokskiy",
                "label_ar": "زاوكسكي",
                "label_fr": "Zaokskiy"
            },
            {
                "value": 23282,
                "label": "Gorbatov",
                "label_ar": "جورباتوف",
                "label_fr": "Gorbatov"
            },
            {
                "value": 23120,
                "label": "Voronezhskiy",
                "label_ar": "فورونيجسكي",
                "label_fr": "Voronezhskiy"
            },
            {
                "value": 22701,
                "label": "Sarov",
                "label_ar": "ساروف",
                "label_fr": "Sarov"
            },
            {
                "value": 22508,
                "label": "Ust'-Labinsk",
                "label_ar": "أوست-لابينسك",
                "label_fr": "Ust'-Labinsk"
            },
            {
                "value": 22676,
                "label": "Redkino",
                "label_ar": "ريدكينو",
                "label_fr": "Redkino"
            },
            {
                "value": 22203,
                "label": "Taganrog",
                "label_ar": "تاجانروج",
                "label_fr": "Taganrog"
            },
            {
                "value": 22812,
                "label": "Nizhegorodskaya",
                "label_ar": "نيججورودسكايا",
                "label_fr": "Nizhegorodskaya"
            },
            {
                "value": 23250,
                "label": "Aleksandrovka",
                "label_ar": "أليكساندروفكا",
                "label_fr": "Aleksandrovka"
            },
            {
                "value": 22726,
                "label": "Kabardino",
                "label_ar": "قبردينو",
                "label_fr": "Kabardino"
            },
            {
                "value": 23227,
                "label": "Kasli",
                "label_ar": "كاسلي",
                "label_fr": "Kasli"
            },
            {
                "value": 22615,
                "label": "Rozhdestvenka",
                "label_ar": "روجديستفينكا",
                "label_fr": "Rozhdestvenka"
            },
            {
                "value": 22305,
                "label": "Azov",
                "label_ar": "آزوف",
                "label_fr": "Azov"
            },
            {
                "value": 23245,
                "label": "Yakovlevo",
                "label_ar": "ياكوفليفو",
                "label_fr": "Yakovlevo"
            },
            {
                "value": 22729,
                "label": "Vnukovo",
                "label_ar": "فنوكوفو",
                "label_fr": "Vnukovo"
            },
            {
                "value": 22895,
                "label": "Krasnotur'insk",
                "label_ar": "كراسنوتورينسك",
                "label_fr": "Krasnotur'insk"
            },
            {
                "value": 22140,
                "label": "Kostroma",
                "label_ar": "كوستروما",
                "label_fr": "Kostroma"
            },
            {
                "value": 22144,
                "label": "Sterlitamak",
                "label_ar": "ستيرليتاماك",
                "label_fr": "Sterlitamak"
            },
            {
                "value": 22292,
                "label": "Usol'ye-Sibirskoye",
                "label_ar": "Usol'ye-Sibirskoye",
                "label_fr": "Usol'ye-Sibirskoye"
            },
            {
                "value": 23148,
                "label": "Krasnokamensk",
                "label_ar": "كراسنوكامينسك",
                "label_fr": "Krasnokamensk"
            },
            {
                "value": 22164,
                "label": "Korolev",
                "label_ar": "كوروليف",
                "label_fr": "Korolev"
            },
            {
                "value": 22545,
                "label": "Naro-Fominsk",
                "label_ar": "نارو فومينسك",
                "label_fr": "Naro-Fominsk"
            },
            {
                "value": 22897,
                "label": "Krasnogorskiy",
                "label_ar": "كراسنوجورسكي",
                "label_fr": "Krasnogorskiy"
            },
            {
                "value": 22750,
                "label": "Tomilino",
                "label_ar": "توميلينو",
                "label_fr": "Tomilino"
            },
            {
                "value": 22741,
                "label": "Barnaul",
                "label_ar": "بارناول",
                "label_fr": "Barnaoul"
            },
            {
                "value": 23024,
                "label": "Baltiysk",
                "label_ar": "بالتييسك",
                "label_fr": "Baltiysk"
            },
            {
                "value": 22237,
                "label": "Kandalaksha",
                "label_ar": "كاندالاكشا",
                "label_fr": "Kandalaksha"
            },
            {
                "value": 22239,
                "label": "Ussuriysk",
                "label_ar": "أوسوريسك",
                "label_fr": "Ussuriysk"
            },
            {
                "value": 22859,
                "label": "Sosnovyy Bor",
                "label_ar": "سوسنوفي بور",
                "label_fr": "Sosnovyy Bor"
            },
            {
                "value": 22972,
                "label": "Aleksin",
                "label_ar": "ألكسين",
                "label_fr": "Aleksin"
            },
            {
                "value": 22138,
                "label": "Lipetsk",
                "label_ar": "ليبيتسك",
                "label_fr": "Lipetsk"
            },
            {
                "value": 22402,
                "label": "Bol'shoye",
                "label_ar": "بولشوي",
                "label_fr": "Bol'shoye"
            },
            {
                "value": 22656,
                "label": "Dmitriyevka",
                "label_ar": "ديمترييفكا",
                "label_fr": "Dmitriyevka"
            },
            {
                "value": 22670,
                "label": "Aldan",
                "label_ar": "ألدان",
                "label_fr": "Aldan"
            },
            {
                "value": 22499,
                "label": "Sarapul",
                "label_ar": "سارابول",
                "label_fr": "Sarapul"
            },
            {
                "value": 22714,
                "label": "Detchino",
                "label_ar": "ديتشينو",
                "label_fr": "Detchino"
            },
            {
                "value": 22553,
                "label": "Vspomogatel'nyy",
                "label_ar": "Vspomogatel'nyy",
                "label_fr": "Vspomogatel'nyy"
            },
            {
                "value": 22460,
                "label": "Zarechnyy",
                "label_ar": "زاريشني",
                "label_fr": "Zarechnyy"
            },
            {
                "value": 22556,
                "label": "Tuchkovo",
                "label_ar": "توتشكوفو",
                "label_fr": "Tuchkovo"
            },
            {
                "value": 23047,
                "label": "Pushchino",
                "label_ar": "بوشينو",
                "label_fr": "Pushchino"
            },
            {
                "value": 22787,
                "label": "Mikhaylovskoye",
                "label_ar": "ميخائيلوفسكوي",
                "label_fr": "Mikhaylovskoye"
            },
            {
                "value": 22965,
                "label": "Novoshakhtinsk",
                "label_ar": "نوفوشاختينسك",
                "label_fr": "Novoshakhtinsk"
            },
            {
                "value": 22352,
                "label": "Gus'-Khrustal'nyy",
                "label_ar": "Gus'-Khrustal'nyy",
                "label_fr": "Gus'-Khrustal'nyy"
            },
            {
                "value": 22504,
                "label": "Kropotkin",
                "label_ar": "كروبوتكين",
                "label_fr": "Kropotkine"
            },
            {
                "value": 22894,
                "label": "Severo-Zapad",
                "label_ar": "سيفيرو زاباد",
                "label_fr": "Severo-Zapad"
            },
            {
                "value": 22793,
                "label": "Progress",
                "label_ar": "التقدم",
                "label_fr": "Le progrès"
            },
            {
                "value": 22636,
                "label": "Okulovka",
                "label_ar": "أوكولوفكا",
                "label_fr": "Okulovka"
            },
            {
                "value": 22375,
                "label": "Krasnokamsk",
                "label_ar": "كراسنوكامسك",
                "label_fr": "Krasnokamsk"
            },
            {
                "value": 23134,
                "label": "Otradnoye",
                "label_ar": "أوترادنوي",
                "label_fr": "Otradnoye"
            },
            {
                "value": 22386,
                "label": "Kanevskaya",
                "label_ar": "كانفسكايا",
                "label_fr": "Kanevskaya"
            },
            {
                "value": 22652,
                "label": "Gorno-Altaysk",
                "label_ar": "جورنو ألتايسك",
                "label_fr": "Gorno-Altaysk"
            },
            {
                "value": 22925,
                "label": "Begunitsy",
                "label_ar": "Begunitsy",
                "label_fr": "Begunitsy"
            },
            {
                "value": 22252,
                "label": "Vsevolozhsk",
                "label_ar": "فسيفولوزك",
                "label_fr": "Vsevolozhsk"
            },
            {
                "value": 22903,
                "label": "Yanaul",
                "label_ar": "ياناول",
                "label_fr": "Yanaul"
            },
            {
                "value": 23019,
                "label": "Kashira",
                "label_ar": "كاشيرة",
                "label_fr": "Kashira"
            },
            {
                "value": 23095,
                "label": "Mtsensk",
                "label_ar": "متسينسك",
                "label_fr": "Mtsensk"
            },
            {
                "value": 22849,
                "label": "Bad'-Pashnya",
                "label_ar": "باد باشنيا",
                "label_fr": "Bad'-Pashnya"
            },
            {
                "value": 23258,
                "label": "Pushchino",
                "label_ar": "بوشينو",
                "label_fr": "Pushchino"
            },
            {
                "value": 22908,
                "label": "Zavolzh'ye",
                "label_ar": "Zavolzh'ye",
                "label_fr": "Zavolzh'ye"
            },
            {
                "value": 22228,
                "label": "Aykino",
                "label_ar": "أيكينو",
                "label_fr": "Aykino"
            },
            {
                "value": 22135,
                "label": "Astrakhan",
                "label_ar": "استراخان",
                "label_fr": "Astrakan"
            },
            {
                "value": 22165,
                "label": "Pushkino",
                "label_ar": "بوشكينو",
                "label_fr": "Pushkino"
            },
            {
                "value": 23032,
                "label": "Parfino",
                "label_ar": "بارفينو",
                "label_fr": "Parfino"
            },
            {
                "value": 22742,
                "label": "Al'met'yevsk",
                "label_ar": "Al'met'yevsk",
                "label_fr": "Al'met'yevsk"
            },
            {
                "value": 22619,
                "label": "Ishley",
                "label_ar": "ايشلي",
                "label_fr": "Ishley"
            },
            {
                "value": 22555,
                "label": "Kholmsk",
                "label_ar": "خلمسك",
                "label_fr": "Kholmsk"
            },
            {
                "value": 22725,
                "label": "Altayskoye",
                "label_ar": "ألتايسكوي",
                "label_fr": "Altayskoye"
            },
            {
                "value": 22976,
                "label": "Dubovka",
                "label_ar": "دوبوفكا",
                "label_fr": "Dubovka"
            },
            {
                "value": 22364,
                "label": "Okhansk",
                "label_ar": "أوخانسك",
                "label_fr": "Okhansk"
            },
            {
                "value": 22608,
                "label": "Kamchatka",
                "label_ar": "كامتشاتكا",
                "label_fr": "Kamtchatka"
            },
            {
                "value": 22878,
                "label": "Gudermes",
                "label_ar": "جودرميس",
                "label_fr": "Gudermes"
            },
            {
                "value": 22478,
                "label": "Snezhinsk",
                "label_ar": "سنيزينسك",
                "label_fr": "Snezhinsk"
            },
            {
                "value": 23131,
                "label": "Maslyanino",
                "label_ar": "Maslyanino",
                "label_fr": "Maslyanino"
            },
            {
                "value": 22526,
                "label": "Nova",
                "label_ar": "نوفا",
                "label_fr": "Nova"
            },
            {
                "value": 22991,
                "label": "Severskaya",
                "label_ar": "سيفرسكايا",
                "label_fr": "Severskaya"
            },
            {
                "value": 22829,
                "label": "Chërnaya Kholunitsa",
                "label_ar": "تشيرنايا خولونيتسا",
                "label_fr": "Chërnaya Kholunitsa"
            },
            {
                "value": 22852,
                "label": "Dzerzhinskiy",
                "label_ar": "دزيرجينسكي",
                "label_fr": "Dzerzhinskiy"
            },
            {
                "value": 23218,
                "label": "Mor'ye",
                "label_ar": "Mor'ye",
                "label_fr": "Mor'ye"
            },
            {
                "value": 23281,
                "label": "Skat",
                "label_ar": "سكات",
                "label_fr": "Skat"
            },
            {
                "value": 22865,
                "label": "Drezna",
                "label_ar": "دريزنا",
                "label_fr": "Drezna"
            },
            {
                "value": 22791,
                "label": "Volzhskiy",
                "label_ar": "Volzhskiy",
                "label_fr": "Volzhskiy"
            },
            {
                "value": 22689,
                "label": "Yuzhno-Sakhalinsk",
                "label_ar": "يوجنو ساخالينسك",
                "label_fr": "Yuzhno-Sakhalinsk"
            },
            {
                "value": 23201,
                "label": "Kamen'-na-Obi",
                "label_ar": "كامين نا اوبي",
                "label_fr": "Kamen'-na-Obi"
            },
            {
                "value": 23132,
                "label": "Cherepanovo",
                "label_ar": "شيريبانوفو",
                "label_fr": "Cherepanovo"
            },
            {
                "value": 23226,
                "label": "Filatovskoye",
                "label_ar": "فيلاتوفسكوي",
                "label_fr": "Filatovskoye"
            },
            {
                "value": 23171,
                "label": "Ishimskiy",
                "label_ar": "إيشمسكي",
                "label_fr": "Ishimskiy"
            },
            {
                "value": 22837,
                "label": "Krasnokamensk",
                "label_ar": "كراسنوكامينسك",
                "label_fr": "Krasnokamensk"
            },
            {
                "value": 22225,
                "label": "Verkhnyaya Salda",
                "label_ar": "فيركنيايا سالدا",
                "label_fr": "Verkhnyaya Salda"
            },
            {
                "value": 22658,
                "label": "Metallostroy",
                "label_ar": "ميتالوستروي",
                "label_fr": "Métallostroy"
            },
            {
                "value": 22409,
                "label": "Bilibino",
                "label_ar": "بيليبينو",
                "label_fr": "Bilibino"
            },
            {
                "value": 22380,
                "label": "Betlitsa",
                "label_ar": "Betlitsa",
                "label_fr": "Betlitsa"
            },
            {
                "value": 23221,
                "label": "Verkhotur'ye",
                "label_ar": "Verkhotur'ye",
                "label_fr": "Verkhotur'ye"
            },
            {
                "value": 22892,
                "label": "Mebel'naya Fabrika",
                "label_ar": "مبيلنايا فابريكا",
                "label_fr": "Mebel'naya Fabrika"
            },
            {
                "value": 22475,
                "label": "Achinsk",
                "label_ar": "أتشينسك",
                "label_fr": "Achinsk"
            },
            {
                "value": 22139,
                "label": "Dimitrovgrad",
                "label_ar": "ديميتروفغراد",
                "label_fr": "Dimitrovgrad"
            },
            {
                "value": 22753,
                "label": "Markovo",
                "label_ar": "ماركوفو",
                "label_fr": "Markovo"
            },
            {
                "value": 22988,
                "label": "Yugorsk",
                "label_ar": "يوغورسك",
                "label_fr": "Yugorsk"
            },
            {
                "value": 23130,
                "label": "Bagan",
                "label_ar": "باغان",
                "label_fr": "Bagan"
            },
            {
                "value": 22328,
                "label": "Druzhba",
                "label_ar": "دروجبا",
                "label_fr": "Druzhba"
            },
            {
                "value": 22630,
                "label": "Snezhnogorsk",
                "label_ar": "سنيجنوجورسك",
                "label_fr": "Snezhnogorsk"
            },
            {
                "value": 22560,
                "label": "Chernogolovka",
                "label_ar": "تشيرنوغولوفكا",
                "label_fr": "Tchernogolovka"
            },
            {
                "value": 22130,
                "label": "Volgograd",
                "label_ar": "فولغوغراد",
                "label_fr": "Volgograd"
            },
            {
                "value": 23167,
                "label": "Kharp",
                "label_ar": "خارب",
                "label_fr": "Kharp"
            },
            {
                "value": 22502,
                "label": "Pioner",
                "label_ar": "بايونير",
                "label_fr": "Pionnier"
            },
            {
                "value": 22858,
                "label": "Zaprudnya",
                "label_ar": "زابرودنيا",
                "label_fr": "Zaprudnya"
            },
            {
                "value": 22902,
                "label": "Perepravnaya",
                "label_ar": "Perepravnaya",
                "label_fr": "Perepravnaya"
            },
            {
                "value": 22221,
                "label": "Pangody",
                "label_ar": "بانجودي",
                "label_fr": "Pangody"
            },
            {
                "value": 22266,
                "label": "Samara",
                "label_ar": "سمارة",
                "label_fr": "Samara"
            },
            {
                "value": 22256,
                "label": "Sevastopol'",
                "label_ar": "سيفاستوبول",
                "label_fr": "Sébastopol '"
            },
            {
                "value": 22687,
                "label": "Glebychevo",
                "label_ar": "جليبيتشيفو",
                "label_fr": "Glebychevo"
            },
            {
                "value": 22603,
                "label": "Bronnitsy",
                "label_ar": "برونيتسي",
                "label_fr": "Bronnitsy"
            },
            {
                "value": 23083,
                "label": "Volovo",
                "label_ar": "فولوفو",
                "label_fr": "Volovo"
            },
            {
                "value": 22834,
                "label": "Lyantor",
                "label_ar": "لينتور",
                "label_fr": "Lyantor"
            },
            {
                "value": 22510,
                "label": "Pavlovskaya",
                "label_ar": "بافلوفسكايا",
                "label_fr": "Pavlovskaya"
            },
            {
                "value": 23012,
                "label": "Spas-Demensk",
                "label_ar": "سبا ديمينسك",
                "label_fr": "Spas-Demensk"
            },
            {
                "value": 22562,
                "label": "Vorovskogo",
                "label_ar": "فوروفسكوجو",
                "label_fr": "Vorovskogo"
            },
            {
                "value": 22813,
                "label": "Kraskovo",
                "label_ar": "كراسكوفو",
                "label_fr": "Kraskovo"
            },
            {
                "value": 22535,
                "label": "Volga",
                "label_ar": "فولغا",
                "label_fr": "Volga"
            },
            {
                "value": 22933,
                "label": "Asino",
                "label_ar": "أسينو",
                "label_fr": "Asino"
            },
            {
                "value": 22804,
                "label": "Studenets",
                "label_ar": "ستودينتس",
                "label_fr": "Studenets"
            },
            {
                "value": 23097,
                "label": "Dubenka",
                "label_ar": "دوبينكا",
                "label_fr": "Dubenka"
            },
            {
                "value": 22992,
                "label": "Mega",
                "label_ar": "ميجا",
                "label_fr": "Méga"
            },
            {
                "value": 22653,
                "label": "Temryuk",
                "label_ar": "تمريوك",
                "label_fr": "Témriouk"
            },
            {
                "value": 23029,
                "label": "Vytegra",
                "label_ar": "فيتيغرا",
                "label_fr": "Vytegra"
            },
            {
                "value": 22152,
                "label": "Ufa",
                "label_ar": "أوفا",
                "label_fr": "Ufa"
            },
            {
                "value": 22557,
                "label": "Skovorodino",
                "label_ar": "سكوفورودينو",
                "label_fr": "Skovorodino"
            },
            {
                "value": 22879,
                "label": "Kulikov",
                "label_ar": "كوليكوف",
                "label_fr": "Kulikov"
            },
            {
                "value": 22981,
                "label": "Povedniki",
                "label_ar": "بوفيدنيكي",
                "label_fr": "Povedniki"
            },
            {
                "value": 23198,
                "label": "Boguchany",
                "label_ar": "بوغوشاني",
                "label_fr": "Boguchany"
            },
            {
                "value": 22142,
                "label": "Kaliningrad",
                "label_ar": "كالينينغراد",
                "label_fr": "Kaliningrad"
            },
            {
                "value": 22900,
                "label": "Sayansk",
                "label_ar": "سايانسك",
                "label_fr": "Sayansk"
            },
            {
                "value": 22926,
                "label": "Novodvinsk",
                "label_ar": "نوفودفينسك",
                "label_fr": "Novodvinsk"
            },
            {
                "value": 22660,
                "label": "Svobodnyy",
                "label_ar": "Svobodnyy",
                "label_fr": "Svobodnyy"
            },
            {
                "value": 23104,
                "label": "Ryasnoye",
                "label_ar": "Ryasnoye",
                "label_fr": "Ryasnoye"
            },
            {
                "value": 22846,
                "label": "Cheremkhovo",
                "label_ar": "تشيرمخوفو",
                "label_fr": "Cheremkhovo"
            },
            {
                "value": 22310,
                "label": "Donetsk",
                "label_ar": "دونيتسك",
                "label_fr": "Donetsk"
            },
            {
                "value": 22655,
                "label": "Kirsanov",
                "label_ar": "كيرسانوف",
                "label_fr": "Kirsanov"
            },
            {
                "value": 23108,
                "label": "Pleloye",
                "label_ar": "بللوي",
                "label_fr": "Pleloye"
            },
            {
                "value": 23016,
                "label": "Elektrogorsk",
                "label_ar": "إلكتروغورسك",
                "label_fr": "Elektrogorsk"
            },
            {
                "value": 22532,
                "label": "Gusev",
                "label_ar": "جوسيف",
                "label_fr": "Gusev"
            },
            {
                "value": 22122,
                "label": "Luzino",
                "label_ar": "لوزينو",
                "label_fr": "Luzino"
            },
            {
                "value": 23049,
                "label": "Kabanovo",
                "label_ar": "كابانوفو",
                "label_fr": "Kabanovo"
            },
            {
                "value": 22411,
                "label": "Volga",
                "label_ar": "فولغا",
                "label_fr": "Volga"
            },
            {
                "value": 22888,
                "label": "Uren'",
                "label_ar": "أورين",
                "label_fr": "Uren '"
            },
            {
                "value": 22869,
                "label": "Kavkaz",
                "label_ar": "قفقاس",
                "label_fr": "Kavkaz"
            },
            {
                "value": 22518,
                "label": "Rubtsovsk",
                "label_ar": "روبتسوفسك",
                "label_fr": "Rubtsovsk"
            },
            {
                "value": 22703,
                "label": "Sloboda",
                "label_ar": "سلوبودا",
                "label_fr": "Sloboda"
            },
            {
                "value": 22723,
                "label": "Kirova",
                "label_ar": "كيروفا",
                "label_fr": "Kirova"
            },
            {
                "value": 22531,
                "label": "Vyborg",
                "label_ar": "فيبورغ",
                "label_fr": "Vyborg"
            },
            {
                "value": 22501,
                "label": "Chiverevo",
                "label_ar": "Chiverevo",
                "label_fr": "Chiverevo"
            },
            {
                "value": 22297,
                "label": "Tuymazy",
                "label_ar": "تويمازي",
                "label_fr": "Tuymazy"
            },
            {
                "value": 22300,
                "label": "Ramenskoye",
                "label_ar": "رامنسكوي",
                "label_fr": "Ramenskoye"
            },
            {
                "value": 22497,
                "label": "Dmitrov",
                "label_ar": "دميتروف",
                "label_fr": "Dmitrov"
            },
            {
                "value": 22966,
                "label": "Ivan'",
                "label_ar": "إيفان",
                "label_fr": "Ivan '"
            },
            {
                "value": 23136,
                "label": "Obyedineniye",
                "label_ar": "Obyedineniye",
                "label_fr": "Obyedineniye"
            },
            {
                "value": 22496,
                "label": "Nizhnekamsk",
                "label_ar": "نيجنكامسك",
                "label_fr": "Nizhnekamsk"
            },
            {
                "value": 22748,
                "label": "Lesnoy Gorodok",
                "label_ar": "ليسنوي جورودوك",
                "label_fr": "Lesnoy Gorodok"
            },
            {
                "value": 22824,
                "label": "Novotroitsk",
                "label_ar": "نوفوترويتسك",
                "label_fr": "Novotroitsk"
            },
            {
                "value": 22559,
                "label": "Istra",
                "label_ar": "استرا",
                "label_fr": "Istra"
            },
            {
                "value": 22927,
                "label": "Nelazskoye",
                "label_ar": "Nelazskoye",
                "label_fr": "Nelazskoye"
            },
            {
                "value": 22577,
                "label": "Sosnogorsk",
                "label_ar": "سوسنوجورسك",
                "label_fr": "Sosnogorsk"
            },
            {
                "value": 22438,
                "label": "Karaidel'",
                "label_ar": "قرايدل",
                "label_fr": "Karaidel '"
            },
            {
                "value": 22749,
                "label": "Odintsovo",
                "label_ar": "أودينتسوفو",
                "label_fr": "Odintsovo"
            },
            {
                "value": 22298,
                "label": "Meleuz",
                "label_ar": "ميليوز",
                "label_fr": "Meleuz"
            },
            {
                "value": 22185,
                "label": "Shatura",
                "label_ar": "شاتورة",
                "label_fr": "Shatura"
            },
            {
                "value": 23050,
                "label": "Roshal'",
                "label_ar": "روشال",
                "label_fr": "Roshal '"
            },
            {
                "value": 22395,
                "label": "Mikhnëvo",
                "label_ar": "Mikhnëvo",
                "label_fr": "Mikhnëvo"
            },
            {
                "value": 22301,
                "label": "Noginsk",
                "label_ar": "نوجينسك",
                "label_fr": "Noginsk"
            },
            {
                "value": 22960,
                "label": "Kovdor",
                "label_ar": "كوفدور",
                "label_fr": "Kovdor"
            },
            {
                "value": 23151,
                "label": "Shadrinsk",
                "label_ar": "شادرينسك",
                "label_fr": "Shadrinsk"
            },
            {
                "value": 22779,
                "label": "Shebalino",
                "label_ar": "شبالينو",
                "label_fr": "Shebalino"
            },
            {
                "value": 22408,
                "label": "Klimovsk",
                "label_ar": "كليموفسك",
                "label_fr": "Klimovsk"
            },
            {
                "value": 22844,
                "label": "Komarov",
                "label_ar": "كوماروف",
                "label_fr": "Komarov"
            },
            {
                "value": 23207,
                "label": "Yalta",
                "label_ar": "يالطا",
                "label_fr": "Yalta"
            },
            {
                "value": 22685,
                "label": "Teykovo",
                "label_ar": "تيكوفو",
                "label_fr": "Teykovo"
            },
            {
                "value": 22179,
                "label": "Zlatoust",
                "label_ar": "زلاتوست",
                "label_fr": "Zlatoust"
            },
            {
                "value": 22280,
                "label": "Angarsk",
                "label_ar": "أنجارسك",
                "label_fr": "Angarsk"
            },
            {
                "value": 22397,
                "label": "Novodmitriyevka",
                "label_ar": "نوفودميترييفكا",
                "label_fr": "Novodmitriyevka"
            },
            {
                "value": 23263,
                "label": "Rosa",
                "label_ar": "روزا",
                "label_fr": "Rosa"
            },
            {
                "value": 22418,
                "label": "Uvarovo",
                "label_ar": "يوفاروفو",
                "label_fr": "Uvarovo"
            },
            {
                "value": 22167,
                "label": "Kubinka",
                "label_ar": "كوبينكا",
                "label_fr": "Kubinka"
            },
            {
                "value": 22686,
                "label": "Noril'sk",
                "label_ar": "نوريلسك",
                "label_fr": "Noril'sk"
            },
            {
                "value": 23025,
                "label": "Nizhniy Odes",
                "label_ar": "نيجني أوديس",
                "label_fr": "Nizhniy Odes"
            },
            {
                "value": 22755,
                "label": "Rzhev",
                "label_ar": "رزيف",
                "label_fr": "Rzhev"
            },
            {
                "value": 22747,
                "label": "Nelidovo",
                "label_ar": "نيليدوفو",
                "label_fr": "Nelidovo"
            },
            {
                "value": 22540,
                "label": "Bashkortostan",
                "label_ar": "باشكورتوستان",
                "label_fr": "Bachkortostan"
            },
            {
                "value": 22814,
                "label": "Verkhnekazymskiy",
                "label_ar": "Verkhnekazymskiy",
                "label_fr": "Verkhnekazymskiy"
            },
            {
                "value": 22317,
                "label": "Lesnoy",
                "label_ar": "ليسنوي",
                "label_fr": "Lesnoy"
            },
            {
                "value": 22480,
                "label": "Priozersk",
                "label_ar": "بريوزيرسك",
                "label_fr": "Priozersk"
            },
            {
                "value": 23048,
                "label": "Ratmirovo",
                "label_ar": "راتميروفو",
                "label_fr": "Ratmirovo"
            },
            {
                "value": 22547,
                "label": "Filial",
                "label_ar": "بنوي",
                "label_fr": "Filial"
            },
            {
                "value": 22546,
                "label": "Postnikova",
                "label_ar": "بوستنيكوفا",
                "label_fr": "Postnikova"
            },
            {
                "value": 22680,
                "label": "Kirovgrad",
                "label_ar": "كيروفغراد",
                "label_fr": "Kirovgrad"
            },
            {
                "value": 22407,
                "label": "Protvino",
                "label_ar": "بروتفينو",
                "label_fr": "Protvino"
            },
            {
                "value": 22170,
                "label": "Yekaterinburg",
                "label_ar": "يكاترينبرج",
                "label_fr": "Ekaterinbourg"
            },
            {
                "value": 22267,
                "label": "Samsonovka",
                "label_ar": "سامسونوفكا",
                "label_fr": "Samsonovka"
            },
            {
                "value": 22169,
                "label": "Novoural'sk",
                "label_ar": "نوفورالسك",
                "label_fr": "Novoural'sk"
            },
            {
                "value": 22158,
                "label": "Elektrostal",
                "label_ar": "إليكتروستال",
                "label_fr": "Elektrostal"
            },
            {
                "value": 22550,
                "label": "Ust'-Ilimsk",
                "label_ar": "Ust'-Ilimsk",
                "label_fr": "Ust'-Ilimsk"
            },
            {
                "value": 22847,
                "label": "Isheyevka",
                "label_ar": "إيشيفكا",
                "label_fr": "Isheyevka"
            },
            {
                "value": 22939,
                "label": "Leninsk-Kuznetsky",
                "label_ar": "لينينسك كوزنتسكي",
                "label_fr": "Leninsk-Kuznetsky"
            },
            {
                "value": 22489,
                "label": "Kurchatov",
                "label_ar": "كورتشاتوف",
                "label_fr": "Kurchatov"
            },
            {
                "value": 23069,
                "label": "Frunzenskiy",
                "label_ar": "Frunzenskiy",
                "label_fr": "Frunzenskiy"
            },
            {
                "value": 22392,
                "label": "Armavir",
                "label_ar": "أرمافير",
                "label_fr": "Armavir"
            },
            {
                "value": 22583,
                "label": "Zheleznodorozhnyy",
                "label_ar": "Zheleznodorozhnyy",
                "label_fr": "Zheleznodorozhnyy"
            },
            {
                "value": 23277,
                "label": "Severo",
                "label_ar": "سيفيرو",
                "label_fr": "Severo"
            },
            {
                "value": 23096,
                "label": "Golovchino",
                "label_ar": "جولوفتشينو",
                "label_fr": "Golovchino"
            },
            {
                "value": 23189,
                "label": "Bol'shoe Isakovo",
                "label_ar": "بولشو إيزاكوفو",
                "label_fr": "Bol'shoe Isakovo"
            },
            {
                "value": 23090,
                "label": "Gur'yevsk",
                "label_ar": "جوريفسك",
                "label_fr": "Gur'yevsk"
            },
            {
                "value": 23284,
                "label": "Neftekumsk",
                "label_ar": "نيفتيكومسك",
                "label_fr": "Neftekumsk"
            },
            {
                "value": 22233,
                "label": "Sovetsk",
                "label_ar": "سوفيتسك",
                "label_fr": "Sovetsk"
            },
            {
                "value": 22728,
                "label": "Nakhabino",
                "label_ar": "نخبينو",
                "label_fr": "Nakhabino"
            },
            {
                "value": 22353,
                "label": "Tikhvin",
                "label_ar": "تيخفين",
                "label_fr": "Tikhvin"
            },
            {
                "value": 23116,
                "label": "Kimry",
                "label_ar": "كيمري",
                "label_fr": "Kimry"
            },
            {
                "value": 22964,
                "label": "Okha",
                "label_ar": "Okha",
                "label_fr": "Okha"
            },
            {
                "value": 23273,
                "label": "Karla Marksa",
                "label_ar": "كارلا ماركسا",
                "label_fr": "Karla Marksa"
            },
            {
                "value": 22570,
                "label": "Lenina",
                "label_ar": "لينينا",
                "label_fr": "Lenina"
            },
            {
                "value": 23075,
                "label": "Voskresenskoye",
                "label_ar": "فوسكريسينسكوي",
                "label_fr": "Voskresenskoye"
            },
            {
                "value": 22230,
                "label": "Kotlas",
                "label_ar": "كوتلاس",
                "label_fr": "Kotlas"
            },
            {
                "value": 22740,
                "label": "Elista",
                "label_ar": "إليستا",
                "label_fr": "Elista"
            },
            {
                "value": 22394,
                "label": "Yeysk",
                "label_ar": "ييسك",
                "label_fr": "Yeysk"
            },
            {
                "value": 22590,
                "label": "Bol'shoy Kamen'",
                "label_ar": "بولشوي كامين",
                "label_fr": "Bol'shoy Kamen '"
            },
            {
                "value": 23052,
                "label": "Kochkurovo",
                "label_ar": "كوتشكوروفو",
                "label_fr": "Kochkurovo"
            },
            {
                "value": 23183,
                "label": "Kirovsk",
                "label_ar": "كيروفسك",
                "label_fr": "Kirovsk"
            },
            {
                "value": 22431,
                "label": "Sosnovoborsk",
                "label_ar": "سوسنوفوبورسك",
                "label_fr": "Sosnovoborsk"
            },
            {
                "value": 22414,
                "label": "Sevsk",
                "label_ar": "سيفسك",
                "label_fr": "Sevsk"
            },
            {
                "value": 22211,
                "label": "Sovetskiy",
                "label_ar": "سوفيتسكي",
                "label_fr": "Sovetskiy"
            },
            {
                "value": 23233,
                "label": "Tulun",
                "label_ar": "تولون",
                "label_fr": "Tulun"
            },
            {
                "value": 22315,
                "label": "Krasnoufimsk",
                "label_ar": "كراسنوفيمسك",
                "label_fr": "Krasnoufimsk"
            },
            {
                "value": 23105,
                "label": "Krasnoarmeyskoye",
                "label_ar": "كراسنوارميسكوي",
                "label_fr": "Krasnoarmeyskoye"
            },
            {
                "value": 22498,
                "label": "Aleksandrov",
                "label_ar": "ألكساندروف",
                "label_fr": "Aleksandrov"
            },
            {
                "value": 22710,
                "label": "Khasavyurt",
                "label_ar": "خسافيورت",
                "label_fr": "Khasavyurt"
            },
            {
                "value": 22930,
                "label": "Kostomuksha",
                "label_ar": "كوستوموكشا",
                "label_fr": "Kostomuksha"
            },
            {
                "value": 23002,
                "label": "Bol'shaya Rakovka",
                "label_ar": "بولشايا راكوفكا",
                "label_fr": "Bol'shaya Rakovka"
            },
            {
                "value": 22334,
                "label": "Pivovar",
                "label_ar": "بيفوفار",
                "label_fr": "Pivovar"
            },
            {
                "value": 22639,
                "label": "Velikiye Luki",
                "label_ar": "فيليكي لوك",
                "label_fr": "Velikiye Luki"
            },
            {
                "value": 23027,
                "label": "Pechora",
                "label_ar": "بيتشورا",
                "label_fr": "Pechora"
            },
            {
                "value": 23244,
                "label": "Zhirnovsk",
                "label_ar": "جيرنوفسك",
                "label_fr": "Zhirnovsk"
            },
            {
                "value": 22306,
                "label": "Krasnyy Sulin",
                "label_ar": "كراسني سولين",
                "label_fr": "Krasnyy Sulin"
            },
            {
                "value": 22262,
                "label": "Posad",
                "label_ar": "بوساد",
                "label_fr": "Posad"
            },
            {
                "value": 22911,
                "label": "Vyaz'ma",
                "label_ar": "فيازما",
                "label_fr": "Vyaz'ma"
            },
            {
                "value": 22503,
                "label": "Tikhoretsk",
                "label_ar": "تيكوريتسك",
                "label_fr": "Tikhoretsk"
            },
            {
                "value": 23278,
                "label": "Malysheva",
                "label_ar": "ماليشيفا",
                "label_fr": "Malysheva"
            },
            {
                "value": 23084,
                "label": "Yelets",
                "label_ar": "يليتس",
                "label_fr": "Yelets"
            },
            {
                "value": 23059,
                "label": "Chesnokovka",
                "label_ar": "تشيسنوكوفكا",
                "label_fr": "Chesnokovka"
            },
            {
                "value": 22289,
                "label": "Lytkarino",
                "label_ar": "ليتكارينو",
                "label_fr": "Lytkarino"
            },
            {
                "value": 22593,
                "label": "Koz'modem'yansk",
                "label_ar": "كوزموديم يانسك",
                "label_fr": "Koz'modem'yansk"
            },
            {
                "value": 23033,
                "label": "Russa",
                "label_ar": "روسا",
                "label_fr": "Russa"
            },
            {
                "value": 22128,
                "label": "Borodino",
                "label_ar": "بورودينو",
                "label_fr": "Borodino"
            },
            {
                "value": 22426,
                "label": "Labytnangi",
                "label_ar": "Labytnangi",
                "label_fr": "Labytnangi"
            },
            {
                "value": 22329,
                "label": "Nal'chik",
                "label_ar": "نالتشيك",
                "label_fr": "Nal'chik"
            },
            {
                "value": 22401,
                "label": "Start",
                "label_ar": "بداية",
                "label_fr": "Début"
            },
            {
                "value": 22482,
                "label": "Yakutsk",
                "label_ar": "ياكوتسك",
                "label_fr": "Iakoutsk"
            },
            {
                "value": 23275,
                "label": "Slavgorod",
                "label_ar": "سلافغورود",
                "label_fr": "Slavgorod"
            },
            {
                "value": 23140,
                "label": "Tsivil'sk",
                "label_ar": "تسيفيلسك",
                "label_fr": "Tsivil'sk"
            },
            {
                "value": 22866,
                "label": "Damkhurts",
                "label_ar": "دمخورتس",
                "label_fr": "Damkhurts"
            },
            {
                "value": 22711,
                "label": "Malaya Vishera",
                "label_ar": "مالايا فيشيرا",
                "label_fr": "Malaya Vishera"
            },
            {
                "value": 23067,
                "label": "Krasnogvardeyskiy",
                "label_ar": "كراسنوجفارديسكي",
                "label_fr": "Krasnogvardeyskiy"
            },
            {
                "value": 22156,
                "label": "Saint Petersburg",
                "label_ar": "سان بطرسبورج",
                "label_fr": "Saint-Pétersbourg"
            },
            {
                "value": 23162,
                "label": "Urzhum",
                "label_ar": "أورزوم",
                "label_fr": "Urzhum"
            },
            {
                "value": 23014,
                "label": "Ivangorod",
                "label_ar": "إيفانجورود",
                "label_fr": "Ivangorod"
            },
            {
                "value": 23214,
                "label": "Usman'",
                "label_ar": "عثمان",
                "label_fr": "Usman '"
            },
            {
                "value": 22773,
                "label": "Sorochinsk",
                "label_ar": "سوروتشينسك",
                "label_fr": "Sorochinsk"
            },
            {
                "value": 22712,
                "label": "Podstepki",
                "label_ar": "بودستبيكي",
                "label_fr": "Podstepki"
            },
            {
                "value": 22249,
                "label": "Sputnik",
                "label_ar": "سبوتنيك",
                "label_fr": "Spoutnik"
            },
            {
                "value": 22263,
                "label": "Tula",
                "label_ar": "تولا",
                "label_fr": "Tula"
            },
            {
                "value": 22143,
                "label": "Tolyatti",
                "label_ar": "تولياتي",
                "label_fr": "Tolyatti"
            },
            {
                "value": 23213,
                "label": "Cherkizovo",
                "label_ar": "تشيركيزوفو",
                "label_fr": "Tcherkizovo"
            },
            {
                "value": 22367,
                "label": "Asha",
                "label_ar": "آشا",
                "label_fr": "Asha"
            },
            {
                "value": 22912,
                "label": "Bezhetsk",
                "label_ar": "Bezhetsk",
                "label_fr": "Bezhetsk"
            },
            {
                "value": 22405,
                "label": "Borisoglebsk",
                "label_ar": "بوريسوجليبسك",
                "label_fr": "Borisoglebsk"
            },
            {
                "value": 22290,
                "label": "Podolsk",
                "label_ar": "بودولسك",
                "label_fr": "Podolsk"
            },
            {
                "value": 23210,
                "label": "Lermontov",
                "label_ar": "ليرمونتوف",
                "label_fr": "Lermontov"
            },
            {
                "value": 22396,
                "label": "Vyksa",
                "label_ar": "فيكسا",
                "label_fr": "Vyksa"
            },
            {
                "value": 22651,
                "label": "Igra",
                "label_ar": "إغرا",
                "label_fr": "Igra"
            },
            {
                "value": 22625,
                "label": "Gatchina",
                "label_ar": "غاتشينا",
                "label_fr": "Gatchina"
            },
            {
                "value": 22254,
                "label": "Volgodonsk",
                "label_ar": "فولجودونسك",
                "label_fr": "Volgodonsk"
            },
            {
                "value": 22276,
                "label": "Saratov",
                "label_ar": "ساراتوف",
                "label_fr": "Saratov"
            },
            {
                "value": 22188,
                "label": "Linevo",
                "label_ar": "لينيفو",
                "label_fr": "Linevo"
            },
            {
                "value": 23219,
                "label": "Dubrovka",
                "label_ar": "دوبروفكا",
                "label_fr": "Dubrovnik"
            },
            {
                "value": 23212,
                "label": "Pavlovskaya Sloboda",
                "label_ar": "بافلوفسكايا سلوبودا",
                "label_fr": "Pavlovskaya Sloboda"
            },
            {
                "value": 22369,
                "label": "Borovskiy",
                "label_ar": "بوروفسكي",
                "label_fr": "Borovskiy"
            },
            {
                "value": 22720,
                "label": "Pervo",
                "label_ar": "بيرفو",
                "label_fr": "Pervo"
            },
            {
                "value": 22141,
                "label": "Kaluga",
                "label_ar": "كالوغا",
                "label_fr": "Kaluga"
            },
            {
                "value": 22202,
                "label": "Bataysk",
                "label_ar": "باتايسك",
                "label_fr": "Bataysk"
            },
            {
                "value": 22942,
                "label": "Roslavl'",
                "label_ar": "روسلافل",
                "label_fr": "Roslavl '"
            },
            {
                "value": 22371,
                "label": "Uktuz",
                "label_ar": "Uktuz",
                "label_fr": "Uktuz"
            },
            {
                "value": 23187,
                "label": "Pitkyaranta",
                "label_ar": "بيتكيارانتا",
                "label_fr": "Pitkyaranta"
            },
            {
                "value": 22957,
                "label": "Bogoroditsk",
                "label_ar": "بوجوروديتسك",
                "label_fr": "Bogoroditsk"
            },
            {
                "value": 22955,
                "label": "Sursk",
                "label_ar": "سرسك",
                "label_fr": "Sursk"
            },
            {
                "value": 22618,
                "label": "Oshib",
                "label_ar": "أوشيب",
                "label_fr": "Oshib"
            },
            {
                "value": 22160,
                "label": "Novosibirsk",
                "label_ar": "نوفوسيبيرسك",
                "label_fr": "Novossibirsk"
            },
            {
                "value": 22443,
                "label": "Solikamsk",
                "label_ar": "سوليكامسك",
                "label_fr": "Solikamsk"
            },
            {
                "value": 22610,
                "label": "Yelizovo",
                "label_ar": "يليزوفو",
                "label_fr": "Yelizovo"
            },
            {
                "value": 22929,
                "label": "Nadvoitsy",
                "label_ar": "نادفوتسي",
                "label_fr": "Nadvoitsy"
            },
            {
                "value": 22763,
                "label": "Mayakovskogo",
                "label_ar": "ماياكوفسكوجو",
                "label_fr": "Mayakovskogo"
            },
            {
                "value": 22962,
                "label": "Livny",
                "label_ar": "ليفني",
                "label_fr": "Livny"
            },
            {
                "value": 23040,
                "label": "Gavan'",
                "label_ar": "غافان",
                "label_fr": "Gavan '"
            },
            {
                "value": 22677,
                "label": "Kirishi",
                "label_ar": "كيريشي",
                "label_fr": "Kirishi"
            },
            {
                "value": 22599,
                "label": "Reutov",
                "label_ar": "ريوتوف",
                "label_fr": "Reutov"
            },
            {
                "value": 23003,
                "label": "Dedenevo",
                "label_ar": "ديدينيفو",
                "label_fr": "Dedenevo"
            },
            {
                "value": 23220,
                "label": "Divo",
                "label_ar": "ديفو",
                "label_fr": "Divo"
            },
            {
                "value": 22543,
                "label": "Leninskoye",
                "label_ar": "لينينسكوي",
                "label_fr": "Leninskoïe"
            },
            {
                "value": 22429,
                "label": "Stary Oskol",
                "label_ar": "ستاري أوسكول",
                "label_fr": "Stary Oskol"
            },
            {
                "value": 22536,
                "label": "Salavat",
                "label_ar": "Salavat",
                "label_fr": "Salavat"
            },
            {
                "value": 22626,
                "label": "Kolomino",
                "label_ar": "كولومينو",
                "label_fr": "Kolomino"
            },
            {
                "value": 22604,
                "label": "Rostov",
                "label_ar": "روستوف",
                "label_fr": "Rostov"
            },
            {
                "value": 22458,
                "label": "Lomonosov",
                "label_ar": "لومونوسوف",
                "label_fr": "Lomonosov"
            },
            {
                "value": 22838,
                "label": "Shtol'nyy",
                "label_ar": "شتولني",
                "label_fr": "Shtol'nyy"
            },
            {
                "value": 22343,
                "label": "Kirovsk",
                "label_ar": "كيروفسك",
                "label_fr": "Kirovsk"
            },
            {
                "value": 22223,
                "label": "Salekhard",
                "label_ar": "سالخارد",
                "label_fr": "Salekhard"
            },
            {
                "value": 22148,
                "label": "Severodvinsk",
                "label_ar": "سيفيرودفينسك",
                "label_fr": "Severodvinsk"
            },
            {
                "value": 22945,
                "label": "Avri",
                "label_ar": "أفري",
                "label_fr": "Avri"
            },
            {
                "value": 22776,
                "label": "Chemashur",
                "label_ar": "شيماشور",
                "label_fr": "Chemashur"
            },
            {
                "value": 23190,
                "label": "Gvardeysk",
                "label_ar": "غفارديسك",
                "label_fr": "Gvardeysk"
            },
            {
                "value": 22192,
                "label": "Stavropol",
                "label_ar": "ستافروبول",
                "label_fr": "Stavropol"
            },
            {
                "value": 23139,
                "label": "Urus-Martan",
                "label_ar": "أوروس مارتان",
                "label_fr": "Urus-Martan"
            },
            {
                "value": 22191,
                "label": "Pyatigorsk",
                "label_ar": "بياتيغورسك",
                "label_fr": "Piatigorsk"
            },
            {
                "value": 23270,
                "label": "Kirilly",
                "label_ar": "كيريلي",
                "label_fr": "Kirilly"
            },
            {
                "value": 22771,
                "label": "Frolovo",
                "label_ar": "فرولوفو",
                "label_fr": "Frolovo"
            },
            {
                "value": 22751,
                "label": "Metallurg",
                "label_ar": "ميتالورج",
                "label_fr": "Metallurg"
            },
            {
                "value": 22586,
                "label": "Spassk",
                "label_ar": "سباسك",
                "label_fr": "Spassk"
            },
            {
                "value": 23168,
                "label": "Kalino",
                "label_ar": "كالينو",
                "label_fr": "Kalino"
            },
            {
                "value": 22796,
                "label": "Kadrovik",
                "label_ar": "كادروفيك",
                "label_fr": "Kadrovik"
            },
            {
                "value": 22833,
                "label": "Yelanskiy",
                "label_ar": "يلانسكي",
                "label_fr": "Yelanskiy"
            },
            {
                "value": 23031,
                "label": "Pestovo",
                "label_ar": "بيستوفو",
                "label_fr": "Pestovo"
            },
            {
                "value": 23197,
                "label": "Shalinskoye",
                "label_ar": "شالينسكوي",
                "label_fr": "Shalinskoïe"
            },
            {
                "value": 23255,
                "label": "Sven'",
                "label_ar": "سفين",
                "label_fr": "Sven '"
            },
            {
                "value": 22245,
                "label": "Spassk-Dal'niy",
                "label_ar": "سباسك دلني",
                "label_fr": "Spassk-Dal'niy"
            },
            {
                "value": 22274,
                "label": "Penza",
                "label_ar": "بينزا",
                "label_fr": "Penza"
            },
            {
                "value": 22469,
                "label": "Selo",
                "label_ar": "سيلو",
                "label_fr": "Selo"
            },
            {
                "value": 22917,
                "label": "Vyazemskiy",
                "label_ar": "فيازيمسكي",
                "label_fr": "Vyazemskiy"
            },
            {
                "value": 22422,
                "label": "Dinskaya",
                "label_ar": "دينسكايا",
                "label_fr": "Dinskaya"
            },
            {
                "value": 22666,
                "label": "Raychikhinsk",
                "label_ar": "رايشينسك",
                "label_fr": "Raychikhinsk"
            },
            {
                "value": 22316,
                "label": "Lesnoy",
                "label_ar": "ليسنوي",
                "label_fr": "Lesnoy"
            },
            {
                "value": 23256,
                "label": "Beryozovsky",
                "label_ar": "بيريوزوفسكي",
                "label_fr": "Beryozovsky"
            },
            {
                "value": 22886,
                "label": "L'vovskiy",
                "label_ar": "لفوفسكي",
                "label_fr": "L'vovskiy"
            },
            {
                "value": 23248,
                "label": "Volkhov",
                "label_ar": "فولكوف",
                "label_fr": "Volkhov"
            },
            {
                "value": 22841,
                "label": "Glazunovka",
                "label_ar": "جلازونوفكا",
                "label_fr": "Glazunovka"
            },
            {
                "value": 22732,
                "label": "Melenki",
                "label_ar": "ميلينكي",
                "label_fr": "Melenki"
            },
            {
                "value": 22790,
                "label": "Kinel'",
                "label_ar": "كينيل",
                "label_fr": "Kinel '"
            },
            {
                "value": 23074,
                "label": "Naumkino",
                "label_ar": "نومكينو",
                "label_fr": "Naumkino"
            },
            {
                "value": 22896,
                "label": "Uryupinsk",
                "label_ar": "يوريوبينسك",
                "label_fr": "Uryupinsk"
            },
            {
                "value": 22986,
                "label": "Roshchino",
                "label_ar": "روشينو",
                "label_fr": "Roshchino"
            },
            {
                "value": 23011,
                "label": "Kirovo-Chepetsk",
                "label_ar": "كيروفو تشيبيتسك",
                "label_fr": "Kirovo-Chepetsk"
            },
            {
                "value": 22236,
                "label": "Murmashi",
                "label_ar": "مرماشي",
                "label_fr": "Murmashi"
            },
            {
                "value": 22542,
                "label": "Novoaltaysk",
                "label_ar": "نوفوالتايسك",
                "label_fr": "Novoaltaysk"
            },
            {
                "value": 23101,
                "label": "Tsna",
                "label_ar": "تسنا",
                "label_fr": "Tsna"
            },
            {
                "value": 23128,
                "label": "Pushkina",
                "label_ar": "بوشكينا",
                "label_fr": "Pushkina"
            },
            {
                "value": 22721,
                "label": "Torzhok",
                "label_ar": "تورزوك",
                "label_fr": "Torzhok"
            },
            {
                "value": 23176,
                "label": "Velikiy Ustyug",
                "label_ar": "فيليكي أوستيوغ",
                "label_fr": "Velikiy Ustyug"
            },
            {
                "value": 22153,
                "label": "Samara",
                "label_ar": "سمارة",
                "label_fr": "Samara"
            },
            {
                "value": 22591,
                "label": "Barabash",
                "label_ar": "باراباش",
                "label_fr": "Barabash"
            },
            {
                "value": 22803,
                "label": "Vidnoye",
                "label_ar": "فيدنوي",
                "label_fr": "Vidnoye"
            },
            {
                "value": 22218,
                "label": "Kurgan",
                "label_ar": "كورغان",
                "label_fr": "Kurgan"
            },
            {
                "value": 22884,
                "label": "Kansk",
                "label_ar": "كانسك",
                "label_fr": "Kansk"
            },
            {
                "value": 22778,
                "label": "Altay",
                "label_ar": "ألتاي",
                "label_fr": "Altay"
            },
            {
                "value": 23170,
                "label": "Dobryanka",
                "label_ar": "دوبريانكا",
                "label_fr": "Dobryanka"
            },
            {
                "value": 22704,
                "label": "Chernushka",
                "label_ar": "تشيرنوشكا",
                "label_fr": "Chernushka"
            },
            {
                "value": 22434,
                "label": "Sibay",
                "label_ar": "سيباي",
                "label_fr": "Sibay"
            },
            {
                "value": 22344,
                "label": "Khot'kovo",
                "label_ar": "خوتكوفو",
                "label_fr": "Khot'kovo"
            },
            {
                "value": 22918,
                "label": "Kor'",
                "label_ar": "كور",
                "label_fr": "Kor '"
            },
            {
                "value": 22486,
                "label": "Persianovka",
                "label_ar": "فارسيوفكا",
                "label_fr": "Persianovka"
            },
            {
                "value": 22568,
                "label": "Komintern",
                "label_ar": "كومنترن",
                "label_fr": "Komintern"
            },
            {
                "value": 22632,
                "label": "Petrozavodsk",
                "label_ar": "بتروزافودسك",
                "label_fr": "Petrozavodsk"
            },
            {
                "value": 22270,
                "label": "Bor",
                "label_ar": "بور",
                "label_fr": "Bor"
            },
            {
                "value": 22403,
                "label": "Kolpino",
                "label_ar": "كولبينو",
                "label_fr": "Kolpino"
            },
            {
                "value": 23153,
                "label": "Kuzovatovo",
                "label_ar": "كوزوفاتوفو",
                "label_fr": "Kuzovatovo"
            },
            {
                "value": 22273,
                "label": "Kalashnikovo",
                "label_ar": "كلاشينكوفو",
                "label_fr": "Kalashnikovo"
            },
            {
                "value": 22698,
                "label": "Zelenogorsk",
                "label_ar": "زيلينوجورسك",
                "label_fr": "Zelenogorsk"
            },
            {
                "value": 23053,
                "label": "Insar",
                "label_ar": "أنصار",
                "label_fr": "Insar"
            },
            {
                "value": 23137,
                "label": "Ugresha",
                "label_ar": "أوجريشا",
                "label_fr": "Ugresha"
            },
            {
                "value": 22437,
                "label": "Novobelokatay",
                "label_ar": "نوفوبلوكاتاي",
                "label_fr": "Novobelokatay"
            },
            {
                "value": 22840,
                "label": "Rakita",
                "label_ar": "راكيتا",
                "label_fr": "Rakita"
            },
            {
                "value": 22463,
                "label": "Putilkovo",
                "label_ar": "بوتيلكوفو",
                "label_fr": "Putilkovo"
            },
            {
                "value": 22509,
                "label": "Kirpil'skaya",
                "label_ar": "كيربيلسكايا",
                "label_fr": "Kirpil'skaya"
            },
            {
                "value": 22920,
                "label": "Vysokogornyy",
                "label_ar": "فيسوكوجورني",
                "label_fr": "Vysokogornyy"
            },
            {
                "value": 22465,
                "label": "Strezhevoy",
                "label_ar": "Strezhevoy",
                "label_fr": "Strezhevoy"
            },
            {
                "value": 22947,
                "label": "Belovo",
                "label_ar": "بيلوفو",
                "label_fr": "Belovo"
            },
            {
                "value": 22613,
                "label": "Nauka",
                "label_ar": "نوكا",
                "label_fr": "Nauka"
            },
            {
                "value": 22756,
                "label": "Desnogorsk",
                "label_ar": "ديسنوجورسك",
                "label_fr": "Desnogorsk"
            },
            {
                "value": 22421,
                "label": "Belorechensk",
                "label_ar": "بيلوريشينسك",
                "label_fr": "Bélorechensk"
            },
            {
                "value": 22132,
                "label": "Stavropol'skiy Rayon",
                "label_ar": "ستافروبولسكي رايون",
                "label_fr": "Rayonne de Stavropol'skiy"
            },
            {
                "value": 23166,
                "label": "Krasnyy Kut",
                "label_ar": "كراسني كوت",
                "label_fr": "Krasnyy Kut"
            },
            {
                "value": 22758,
                "label": "Uglich",
                "label_ar": "أوغليش",
                "label_fr": "Ouglitch"
            },
            {
                "value": 22668,
                "label": "Nadezhdinka",
                "label_ar": "ناديجدينكا",
                "label_fr": "Nadezhdinka"
            },
            {
                "value": 22190,
                "label": "Yegor'yevka",
                "label_ar": "Yegor'yevka",
                "label_fr": "Yegor'yevka"
            },
            {
                "value": 23102,
                "label": "Aksay",
                "label_ar": "اكساي",
                "label_fr": "Aksay"
            },
            {
                "value": 22809,
                "label": "Olimpiyskiy",
                "label_ar": "Olimpiyskiy",
                "label_fr": "Olimpiyskiy"
            },
            {
                "value": 22606,
                "label": "Tarusa",
                "label_ar": "تاروسا",
                "label_fr": "Tarusa"
            },
            {
                "value": 22171,
                "label": "Severoural'sk",
                "label_ar": "سيفيرورالسك",
                "label_fr": "Severoural'sk"
            },
            {
                "value": 22584,
                "label": "Petropavlovsk-Kamchatsky",
                "label_ar": "بتروبافلوفسك كامتشاتسكي",
                "label_fr": "Petropavlovsk-Kamtchatsky"
            },
            {
                "value": 22389,
                "label": "Uspenskoye",
                "label_ar": "Uspenskoye",
                "label_fr": "Uspenskoye"
            },
            {
                "value": 22470,
                "label": "Gukovo",
                "label_ar": "جوكوفو",
                "label_fr": "Gukovo"
            },
            {
                "value": 23206,
                "label": "Lebedyan'",
                "label_ar": "ليبيديان",
                "label_fr": "Lebedyan '"
            },
            {
                "value": 22867,
                "label": "Dagomys",
                "label_ar": "داجوميس",
                "label_fr": "Dagomys"
            },
            {
                "value": 22745,
                "label": "Skala",
                "label_ar": "سكالا",
                "label_fr": "Skala"
            },
            {
                "value": 23007,
                "label": "Ilovlya",
                "label_ar": "إيلوفليا",
                "label_fr": "Ilovlya"
            },
            {
                "value": 23006,
                "label": "Surkhakhi",
                "label_ar": "السرخاخي",
                "label_fr": "Surkhakhi"
            },
            {
                "value": 22420,
                "label": "Gelendzhik",
                "label_ar": "Gelendzhik",
                "label_fr": "Gelendzhik"
            },
            {
                "value": 23038,
                "label": "Kinel'-Cherkassy",
                "label_ar": "كينيل تشيركاسي",
                "label_fr": "Kinel'-Tcherkassy"
            },
            {
                "value": 22336,
                "label": "Biysk",
                "label_ar": "بايسك",
                "label_fr": "Biysk"
            },
            {
                "value": 22337,
                "label": "Rybnoye",
                "label_ar": "Rybnoye",
                "label_fr": "Rybnoye"
            },
            {
                "value": 23229,
                "label": "Muravlenko",
                "label_ar": "مورافلينكو",
                "label_fr": "Muravlenko"
            },
            {
                "value": 22541,
                "label": "Kineshma",
                "label_ar": "كينيشما",
                "label_fr": "Kineshma"
            },
            {
                "value": 22633,
                "label": "Nikol'skoye",
                "label_ar": "نيكولسكوي",
                "label_fr": "Nikol'skoye"
            },
            {
                "value": 22149,
                "label": "Samara",
                "label_ar": "سمارة",
                "label_fr": "Samara"
            },
            {
                "value": 22737,
                "label": "Safonovo",
                "label_ar": "سافونوفو",
                "label_fr": "Safonovo"
            },
            {
                "value": 22678,
                "label": "Mamontovka",
                "label_ar": "مامونتوفكا",
                "label_fr": "Mamontovka"
            },
            {
                "value": 22175,
                "label": "Barnaul",
                "label_ar": "بارناول",
                "label_fr": "Barnaoul"
            },
            {
                "value": 22823,
                "label": "Kartaly",
                "label_ar": "كارتالي",
                "label_fr": "Kartaly"
            },
            {
                "value": 22754,
                "label": "Urgal",
                "label_ar": "أورغال",
                "label_fr": "Urgal"
            },
            {
                "value": 23242,
                "label": "Dubovka",
                "label_ar": "دوبوفكا",
                "label_fr": "Dubovka"
            },
            {
                "value": 22121,
                "label": "Novovarshavka",
                "label_ar": "نوفوفارشافكا",
                "label_fr": "Novovarshavka"
            },
            {
                "value": 22987,
                "label": "Arti",
                "label_ar": "آرتي",
                "label_fr": "Arti"
            },
            {
                "value": 22569,
                "label": "Svetlograd",
                "label_ar": "سفيتلوغراد",
                "label_fr": "Svetlograd"
            },
            {
                "value": 22907,
                "label": "Zaural'skiy",
                "label_ar": "Zaural'skiy",
                "label_fr": "Zaural'skiy"
            },
            {
                "value": 23041,
                "label": "Gavan'",
                "label_ar": "غافان",
                "label_fr": "Gavan '"
            },
            {
                "value": 22433,
                "label": "Kazan'",
                "label_ar": "قازان",
                "label_fr": "Kazan '"
            },
            {
                "value": 22898,
                "label": "Kugesi",
                "label_ar": "كوجيسي",
                "label_fr": "Kugesi"
            },
            {
                "value": 22811,
                "label": "Vagay",
                "label_ar": "فاجاي",
                "label_fr": "Vagay"
            },
            {
                "value": 22715,
                "label": "Grozny",
                "label_ar": "غروزني",
                "label_fr": "Grozny"
            },
            {
                "value": 22271,
                "label": "Izhevsk",
                "label_ar": "إيجيفسك",
                "label_fr": "Izhevsk"
            },
            {
                "value": 22792,
                "label": "Pervomayskaya",
                "label_ar": "بيرفومايسكايا",
                "label_fr": "Pervomayskaya"
            },
            {
                "value": 22975,
                "label": "Plavsk",
                "label_ar": "بلافسك",
                "label_fr": "Plavsk"
            },
            {
                "value": 22940,
                "label": "Topki",
                "label_ar": "توبكي",
                "label_fr": "Topki"
            },
            {
                "value": 23251,
                "label": "Taldom",
                "label_ar": "Taldom",
                "label_fr": "Taldom"
            },
            {
                "value": 22187,
                "label": "Iskitim",
                "label_ar": "Iskitim",
                "label_fr": "Iskitim"
            },
            {
                "value": 22946,
                "label": "Staropesterevo",
                "label_ar": "ستاروبيستريفو",
                "label_fr": "Staropesterevo"
            },
            {
                "value": 22129,
                "label": "Beya",
                "label_ar": "بيا",
                "label_fr": "Beya"
            },
            {
                "value": 22363,
                "label": "Berezniki",
                "label_ar": "بيريزنيكي",
                "label_fr": "Berezniki"
            },
            {
                "value": 22575,
                "label": "Patrushi",
                "label_ar": "باتروشي",
                "label_fr": "Patrushi"
            },
            {
                "value": 22523,
                "label": "Zelenogorsk",
                "label_ar": "زيلينوجورسك",
                "label_fr": "Zelenogorsk"
            },
            {
                "value": 22477,
                "label": "Vega",
                "label_ar": "فيجا",
                "label_fr": "Vega"
            },
            {
                "value": 22539,
                "label": "Zaraysk",
                "label_ar": "زاريسك",
                "label_fr": "Zaraysk"
            },
            {
                "value": 22612,
                "label": "Volkhonshchino",
                "label_ar": "فولخونشينو",
                "label_fr": "Volkhonshchino"
            },
            {
                "value": 23022,
                "label": "Pavlovo",
                "label_ar": "بافلوفو",
                "label_fr": "Pavlovo"
            },
            {
                "value": 23030,
                "label": "Gryazovets",
                "label_ar": "جريازوفيتس",
                "label_fr": "Gryazovets"
            },
            {
                "value": 22572,
                "label": "Del'ta",
                "label_ar": "دلتا",
                "label_fr": "Delta"
            },
            {
                "value": 22573,
                "label": "Staraya Russa",
                "label_ar": "ستارايا روسا",
                "label_fr": "Staraya Russa"
            },
            {
                "value": 22832,
                "label": "Vostochnaya",
                "label_ar": "فوستوتشنايا",
                "label_fr": "Vostochnaya"
            },
            {
                "value": 22181,
                "label": "Yuzhnoural'sk",
                "label_ar": "يوجنورالسك",
                "label_fr": "Yuzhnoural'sk"
            },
            {
                "value": 22123,
                "label": "Mar'yanovskiy",
                "label_ar": "ماريانوفسكي",
                "label_fr": "Mar'yanovskiy"
            },
            {
                "value": 22303,
                "label": "Anapa",
                "label_ar": "أنابا",
                "label_fr": "Anapa"
            },
            {
                "value": 22663,
                "label": "Belogorka",
                "label_ar": "بيلوجوركا",
                "label_fr": "Belogorka"
            },
            {
                "value": 23247,
                "label": "Ust'-Ilimsk",
                "label_ar": "Ust'-Ilimsk",
                "label_fr": "Ust'-Ilimsk"
            },
            {
                "value": 22432,
                "label": "Kirov",
                "label_ar": "كيروف",
                "label_fr": "Kirov"
            },
            {
                "value": 22717,
                "label": "Satis",
                "label_ar": "ساتيس",
                "label_fr": "Satis"
            },
            {
                "value": 23216,
                "label": "Birzha",
                "label_ar": "بيرزا",
                "label_fr": "Birzha"
            },
            {
                "value": 22595,
                "label": "Volzhsk",
                "label_ar": "فولزسك",
                "label_fr": "Volzhsk"
            },
            {
                "value": 22278,
                "label": "Seversk",
                "label_ar": "سيفيرسك",
                "label_fr": "Seversk"
            },
            {
                "value": 23008,
                "label": "Bol'shaya Fëdorovka",
                "label_ar": "بولشايا فيدوروفكا",
                "label_fr": "Bol'shaya Fëdorovka"
            },
            {
                "value": 23228,
                "label": "Chernushka",
                "label_ar": "تشيرنوشكا",
                "label_fr": "Chernushka"
            },
            {
                "value": 22863,
                "label": "Satka",
                "label_ar": "ساتكا",
                "label_fr": "Satka"
            },
            {
                "value": 22200,
                "label": "Labinsk",
                "label_ar": "لابينسك",
                "label_fr": "Labinsk"
            },
            {
                "value": 23178,
                "label": "Ust'ye",
                "label_ar": "Ust'ye",
                "label_fr": "Ust'ye"
            },
            {
                "value": 22150,
                "label": "Beloretsk",
                "label_ar": "بيلوريتسك",
                "label_fr": "Beloretsk"
            },
            {
                "value": 22724,
                "label": "Stroiteley",
                "label_ar": "سترويتلي",
                "label_fr": "Stroiteley"
            },
            {
                "value": 23076,
                "label": "Luchegorsk",
                "label_ar": "Luchegorsk",
                "label_fr": "Luchegorsk"
            },
            {
                "value": 22810,
                "label": "Neman",
                "label_ar": "نيمان",
                "label_fr": "Neman"
            },
            {
                "value": 22825,
                "label": "Mednogorsk",
                "label_ar": "ميدنوغورسك",
                "label_fr": "Mednogorsk"
            },
            {
                "value": 22374,
                "label": "Novy Urengoy",
                "label_ar": "نوفي يورنغوي",
                "label_fr": "Novy Urengoy"
            },
            {
                "value": 22224,
                "label": "Nyagan",
                "label_ar": "نياغان",
                "label_fr": "Nyagan"
            },
            {
                "value": 22506,
                "label": "Slavyanka",
                "label_ar": "سلافيانكا",
                "label_fr": "Slavyanka"
            },
            {
                "value": 22752,
                "label": "Koptyuga",
                "label_ar": "كوبتيوجا",
                "label_fr": "Koptyuga"
            },
            {
                "value": 23286,
                "label": "Tapkhar",
                "label_ar": "طبخار",
                "label_fr": "Tapkhar"
            },
            {
                "value": 22459,
                "label": "Petergof",
                "label_ar": "بيترجوف",
                "label_fr": "Petergof"
            },
            {
                "value": 22173,
                "label": "Kamensk-Ural'skiy",
                "label_ar": "كامينسك-أورالسكي",
                "label_fr": "Kamensk-Ural'skiy"
            },
            {
                "value": 23118,
                "label": "Zarinsk",
                "label_ar": "زارينسك",
                "label_fr": "Zarinsk"
            },
            {
                "value": 22594,
                "label": "Zvenigovo",
                "label_ar": "زفينيجوفو",
                "label_fr": "Zvenigovo"
            },
            {
                "value": 22357,
                "label": "Kuznetskiy Rayon",
                "label_ar": "كوزنتسكي رايون",
                "label_fr": "Rayonne Kuznetskiy"
            },
            {
                "value": 22359,
                "label": "Blagoveshchensk",
                "label_ar": "بلاغوفيشتشينسك",
                "label_fr": "Blagovechtchensk"
            },
            {
                "value": 22119,
                "label": "Omsk",
                "label_ar": "أومسك",
                "label_fr": "Omsk"
            },
            {
                "value": 22525,
                "label": "Myski",
                "label_ar": "ميسكي",
                "label_fr": "Myski"
            },
            {
                "value": 22537,
                "label": "Orsk",
                "label_ar": "أورسك",
                "label_fr": "Orsk"
            },
            {
                "value": 22637,
                "label": "Chudovo",
                "label_ar": "تشودوفو",
                "label_fr": "Chudovo"
            },
            {
                "value": 22260,
                "label": "Krasnoyarsk",
                "label_ar": "كراسنويارسك",
                "label_fr": "Krasnoïarsk"
            },
            {
                "value": 22388,
                "label": "Gul'kevichi",
                "label_ar": "جولكيفيتشي",
                "label_fr": "Gul'kevichi"
            },
            {
                "value": 22842,
                "label": "Severo-Kuril'sk",
                "label_ar": "سيفيرو كوريلسك",
                "label_fr": "Severo-Kuril'sk"
            },
            {
                "value": 22194,
                "label": "Budënnovsk",
                "label_ar": "Budënnovsk",
                "label_fr": "Budënnovsk"
            },
            {
                "value": 22519,
                "label": "Snezhinskiy",
                "label_ar": "Snezhinskiy",
                "label_fr": "Snezhinskiy"
            },
            {
                "value": 22736,
                "label": "Borzya",
                "label_ar": "بورزيا",
                "label_fr": "Borzya"
            },
            {
                "value": 22623,
                "label": "Balabanovo",
                "label_ar": "بالابانوفو",
                "label_fr": "Balabanovo"
            },
            {
                "value": 22954,
                "label": "Bednodem'yanovsk",
                "label_ar": "بيدنوديميانوفسك",
                "label_fr": "Bednodem'yanovsk"
            },
            {
                "value": 22671,
                "label": "Ibresi",
                "label_ar": "إبريسي",
                "label_fr": "Ibresi"
            },
            {
                "value": 22977,
                "label": "Blagovar",
                "label_ar": "بلاغوفار",
                "label_fr": "Blagovar"
            },
            {
                "value": 22958,
                "label": "Reftinskiy",
                "label_ar": "Reftinskiy",
                "label_fr": "Reftinskiy"
            },
            {
                "value": 22406,
                "label": "Chekhov",
                "label_ar": "تشيخوف",
                "label_fr": "Tchekhov"
            },
            {
                "value": 22206,
                "label": "Novorossiysk",
                "label_ar": "نوفوروسيسك",
                "label_fr": "Novorossiysk"
            },
            {
                "value": 22734,
                "label": "Sofrino",
                "label_ar": "سوفرينو",
                "label_fr": "Sofrino"
            },
            {
                "value": 22356,
                "label": "Novokuznetsk",
                "label_ar": "نوفوكوزنتسك",
                "label_fr": "Novokuznetsk"
            },
            {
                "value": 22212,
                "label": "Megion",
                "label_ar": "ميجيون",
                "label_fr": "Megion"
            },
            {
                "value": 22744,
                "label": "Chistopol'",
                "label_ar": "تشيستوبول",
                "label_fr": "Chistopol '"
            },
            {
                "value": 22795,
                "label": "Aksenovo",
                "label_ar": "أكسينوفو",
                "label_fr": "Aksenovo"
            },
            {
                "value": 22308,
                "label": "Rodionovka",
                "label_ar": "روديونوفكا",
                "label_fr": "Rodionovka"
            },
            {
                "value": 22718,
                "label": "Pskovskoye",
                "label_ar": "بسكوفسكوي",
                "label_fr": "Pskovskoye"
            },
            {
                "value": 22646,
                "label": "Kvatchi",
                "label_ar": "كفاتشي",
                "label_fr": "Kvatchi"
            },
            {
                "value": 22487,
                "label": "Rodionovo-Nesvetayskaya",
                "label_ar": "روديونوفو نسفيتايسكايا",
                "label_fr": "Rodionovo-Nesvetayskaya"
            },
            {
                "value": 22215,
                "label": "Perm",
                "label_ar": "موج الشعر بإستمرار",
                "label_fr": "Permanente"
            },
            {
                "value": 22961,
                "label": "Dalmatovo",
                "label_ar": "دالماتوفو",
                "label_fr": "Dalmatovo"
            },
            {
                "value": 22366,
                "label": "Irbit",
                "label_ar": "ايربيت",
                "label_fr": "Irbit"
            },
            {
                "value": 22777,
                "label": "Lazo",
                "label_ar": "لازو",
                "label_fr": "Lazo"
            },
            {
                "value": 23224,
                "label": "Maykor",
                "label_ar": "مايكور",
                "label_fr": "Maykor"
            },
            {
                "value": 22695,
                "label": "Sokol",
                "label_ar": "سوكول",
                "label_fr": "Sokol"
            },
            {
                "value": 23026,
                "label": "Troitsko-Pechorsk",
                "label_ar": "ترويتسكو بيتشورسك",
                "label_fr": "Troitsko-Pechorsk"
            },
            {
                "value": 23252,
                "label": "Timiryazev",
                "label_ar": "Timiryazev",
                "label_fr": "Timiryazev"
            },
            {
                "value": 22831,
                "label": "Karin Perevoz",
                "label_ar": "كارين بيريفوز",
                "label_fr": "Karin Perevoz"
            },
            {
                "value": 22661,
                "label": "Arkhara",
                "label_ar": "Arkhara",
                "label_fr": "Arkhara"
            },
            {
                "value": 22481,
                "label": "Roshchino",
                "label_ar": "روشينو",
                "label_fr": "Roshchino"
            },
            {
                "value": 22250,
                "label": "Vologda",
                "label_ar": "فولوغدا",
                "label_fr": "Vologda"
            },
            {
                "value": 22447,
                "label": "Novosemeykino",
                "label_ar": "نوفوسيميكينو",
                "label_fr": "Novosemeykino"
            },
            {
                "value": 23043,
                "label": "Alekseyevka",
                "label_ar": "أليكسيفكا",
                "label_fr": "Alekseyevka"
            },
            {
                "value": 23285,
                "label": "Vikhorevka",
                "label_ar": "Vikhorevka",
                "label_fr": "Vikhorevka"
            },
            {
                "value": 22339,
                "label": "Lisikha",
                "label_ar": "ليزيكا",
                "label_fr": "Lisikha"
            },
            {
                "value": 22296,
                "label": "Yaratovo",
                "label_ar": "ياراتوفو",
                "label_fr": "Yaratovo"
            },
            {
                "value": 22786,
                "label": "Rossiyskiy",
                "label_ar": "روسيسكي",
                "label_fr": "Rossiyskiy"
            },
            {
                "value": 22544,
                "label": "Ruza",
                "label_ar": "روزا",
                "label_fr": "Ruza"
            },
            {
                "value": 23261,
                "label": "Karabash",
                "label_ar": "كاراباش",
                "label_fr": "Karabash"
            },
            {
                "value": 22125,
                "label": "Abakan",
                "label_ar": "أباكان",
                "label_fr": "Abakan"
            },
            {
                "value": 23209,
                "label": "Noginskaya",
                "label_ar": "نوجينسكايا",
                "label_fr": "Noginskaya"
            },
            {
                "value": 23079,
                "label": "Kol'tsovo",
                "label_ar": "كولتسوفو",
                "label_fr": "Kol'tsovo"
            },
            {
                "value": 22738,
                "label": "Nizhneudinsk",
                "label_ar": "نيجنيودينسك",
                "label_fr": "Nizhneudinsk"
            },
            {
                "value": 22659,
                "label": "Yekaterinoslavka",
                "label_ar": "يكاترينوسلافكا",
                "label_fr": "Yekaterinoslavka"
            },
            {
                "value": 23138,
                "label": "Lipetsk",
                "label_ar": "ليبيتسك",
                "label_fr": "Lipetsk"
            },
            {
                "value": 22241,
                "label": "Yekaterinovka",
                "label_ar": "يكاترينوفكا",
                "label_fr": "Yekaterinovka"
            },
            {
                "value": 22248,
                "label": "Tver",
                "label_ar": "تفير",
                "label_fr": "Tver"
            },
            {
                "value": 22667,
                "label": "Vysokovsk",
                "label_ar": "فيسوكوفسك",
                "label_fr": "Vysokovsk"
            },
            {
                "value": 22673,
                "label": "Agapovka",
                "label_ar": "أجابوفكا",
                "label_fr": "Agapovka"
            },
            {
                "value": 22293,
                "label": "Kushnarenkovo",
                "label_ar": "كوشنارينكوفو",
                "label_fr": "Kushnarenkovo"
            },
            {
                "value": 22243,
                "label": "Novoshakhtinskiy",
                "label_ar": "نوفوشاختينسكي",
                "label_fr": "Novoshakhtinskiy"
            },
            {
                "value": 22222,
                "label": "Nefteyugansk",
                "label_ar": "نفتيوغانسك",
                "label_fr": "Nefteyugansk"
            },
            {
                "value": 22662,
                "label": "Seryshevo",
                "label_ar": "سريشيفو",
                "label_fr": "Seryshevo"
            },
            {
                "value": 22643,
                "label": "Koryazhma",
                "label_ar": "كوريازما",
                "label_fr": "Koryazhma"
            },
            {
                "value": 22582,
                "label": "Dudinka",
                "label_ar": "دودينكا",
                "label_fr": "Dudinka"
            },
            {
                "value": 23133,
                "label": "Zainsk",
                "label_ar": "زينسك",
                "label_fr": "Zainsk"
            },
            {
                "value": 22759,
                "label": "Kronshtadt",
                "label_ar": "كرونشتات",
                "label_fr": "Kronshtadt"
            },
            {
                "value": 22982,
                "label": "Barda",
                "label_ar": "باردا",
                "label_fr": "Barda"
            },
            {
                "value": 23269,
                "label": "Buret'",
                "label_ar": "بوريت",
                "label_fr": "Buret '"
            },
            {
                "value": 22373,
                "label": "Uray",
                "label_ar": "أوراي",
                "label_fr": "Uray"
            },
            {
                "value": 22261,
                "label": "Yaroslavl",
                "label_ar": "ياروسلافل",
                "label_fr": "Yaroslavl"
            },
            {
                "value": 23054,
                "label": "Maksimovka",
                "label_ar": "ماكسيموفكا",
                "label_fr": "Maksimovka"
            },
            {
                "value": 22326,
                "label": "Volga",
                "label_ar": "فولغا",
                "label_fr": "Volga"
            },
            {
                "value": 22340,
                "label": "Baykal",
                "label_ar": "بايكال",
                "label_fr": "Baykal"
            },
            {
                "value": 22362,
                "label": "Kungur",
                "label_ar": "كونغور",
                "label_fr": "Kungur"
            },
            {
                "value": 22474,
                "label": "Zelenodolsk",
                "label_ar": "زيلينودولسك",
                "label_fr": "Zelenodolsk"
            },
            {
                "value": 22283,
                "label": "Pervouralsk",
                "label_ar": "برفورالسك",
                "label_fr": "Pervouralsk"
            },
            {
                "value": 22471,
                "label": "Krasnoye-na-Volge",
                "label_ar": "كراسنوي نا فولجي",
                "label_fr": "Krasnoïe-na-Volge"
            },
            {
                "value": 22860,
                "label": "Karabulak",
                "label_ar": "كارابولاك",
                "label_fr": "Karabulak"
            },
            {
                "value": 23236,
                "label": "Granit",
                "label_ar": "جرانيت",
                "label_fr": "Granit"
            },
            {
                "value": 22427,
                "label": "Nadym",
                "label_ar": "Nadym",
                "label_fr": "Nadym"
            },
            {
                "value": 22413,
                "label": "Kiselëvsk",
                "label_ar": "كيسيلوفسك",
                "label_fr": "Kiselëvsk"
            },
            {
                "value": 23279,
                "label": "Shumerlya",
                "label_ar": "شوميرليا",
                "label_fr": "Shumerlya"
            },
            {
                "value": 22722,
                "label": "Smolenskaya",
                "label_ar": "سمولينسكايا",
                "label_fr": "Smolenskaya"
            },
            {
                "value": 22602,
                "label": "Losino-Petrovskiy",
                "label_ar": "لوسينو بتروفسكي",
                "label_fr": "Losino-Petrovskiy"
            },
            {
                "value": 22692,
                "label": "Chapayevsk",
                "label_ar": "تشابيفسك",
                "label_fr": "Chapayevsk"
            },
            {
                "value": 22644,
                "label": "Sertolovo",
                "label_ar": "سيرتولوفو",
                "label_fr": "Sertolovo"
            },
            {
                "value": 22566,
                "label": "Amur",
                "label_ar": "أمور",
                "label_fr": "Amour"
            },
            {
                "value": 22391,
                "label": "Slavyansk-na-Kubani",
                "label_ar": "سلافيانسك نا كوباني",
                "label_fr": "Slavyansk-na-Kubani"
            },
            {
                "value": 23257,
                "label": "Gubkin",
                "label_ar": "جوبكين",
                "label_fr": "Gubkin"
            },
            {
                "value": 22774,
                "label": "Ariadnoye",
                "label_ar": "أريادنوي",
                "label_fr": "Ariadnoye"
            },
            {
                "value": 22410,
                "label": "Khrenovoye",
                "label_ar": "خرينوفوي",
                "label_fr": "Khrenovoye"
            },
            {
                "value": 22549,
                "label": "Rubin",
                "label_ar": "دهن",
                "label_fr": "Insister sur"
            },
            {
                "value": 22247,
                "label": "Belgorod",
                "label_ar": "بيلغورود",
                "label_fr": "Belgorod"
            },
            {
                "value": 22456,
                "label": "Kodinsk",
                "label_ar": "كودينسك",
                "label_fr": "Kodinsk"
            },
            {
                "value": 23147,
                "label": "Fialka",
                "label_ar": "فيالكا",
                "label_fr": "Fialka"
            },
            {
                "value": 22372,
                "label": "Yalutorovsk",
                "label_ar": "يالوتوروفسك",
                "label_fr": "Yalutorovsk"
            },
            {
                "value": 22578,
                "label": "Nartkala",
                "label_ar": "نارتكالا",
                "label_fr": "Nartkala"
            },
            {
                "value": 22516,
                "label": "Staronizhestebliyevskaya",
                "label_ar": "Staronizhestebliyevskaya",
                "label_fr": "Staronizhestebliyevskaya"
            },
            {
                "value": 23060,
                "label": "Kasimov",
                "label_ar": "قاسيموف",
                "label_fr": "Kasimov"
            },
            {
                "value": 22182,
                "label": "Vladivostok",
                "label_ar": "فلاديفوستوك",
                "label_fr": "Vladivostok"
            },
            {
                "value": 22198,
                "label": "Georgiyevskoye",
                "label_ar": "جورجييفسكوي",
                "label_fr": "Georgiyevskoye"
            },
            {
                "value": 22423,
                "label": "Krymsk",
                "label_ar": "كريمسك",
                "label_fr": "Krymsk"
            },
            {
                "value": 22802,
                "label": "Potok",
                "label_ar": "بوتوك",
                "label_fr": "Potok"
            },
            {
                "value": 22360,
                "label": "Alatyr'",
                "label_ar": "الأتير",
                "label_fr": "Alatyr '"
            },
            {
                "value": 22279,
                "label": "Ufa",
                "label_ar": "أوفا",
                "label_fr": "Ufa"
            },
            {
                "value": 22997,
                "label": "Raduga",
                "label_ar": "رادوغا",
                "label_fr": "Raduga"
            },
            {
                "value": 22479,
                "label": "Lesozavodsk",
                "label_ar": "ليسوزافودسك",
                "label_fr": "Lesozavodsk"
            },
            {
                "value": 22815,
                "label": "Soldatskoye",
                "label_ar": "سولداتسكوي",
                "label_fr": "Soldatskoye"
            },
            {
                "value": 22587,
                "label": "Dal'negorsk",
                "label_ar": "دالنيغورسك",
                "label_fr": "Dal'negorsk"
            },
            {
                "value": 23155,
                "label": "Adygeysk",
                "label_ar": "أديجيسك",
                "label_fr": "Adygeysk"
            },
            {
                "value": 22484,
                "label": "Bol'shiye Saly",
                "label_ar": "Bol'shiye Saly",
                "label_fr": "Bol'shiye Saly"
            },
            {
                "value": 22145,
                "label": "Kemerovo",
                "label_ar": "كيميروفو",
                "label_fr": "Kemerovo"
            },
            {
                "value": 22627,
                "label": "Romanovka",
                "label_ar": "رومانوفكا",
                "label_fr": "Romanovka"
            },
            {
                "value": 22137,
                "label": "Rybinsk",
                "label_ar": "ريبينسك",
                "label_fr": "Rybinsk"
            },
            {
                "value": 22275,
                "label": "Solnechnogorsk",
                "label_ar": "Solnechnogorsk",
                "label_fr": "Solnechnogorsk"
            },
            {
                "value": 22264,
                "label": "Kurgan",
                "label_ar": "كورغان",
                "label_fr": "Kurgan"
            },
            {
                "value": 22467,
                "label": "Rassvet",
                "label_ar": "راسفيت",
                "label_fr": "Rassvet"
            },
            {
                "value": 23066,
                "label": "Pereslavl'-Zalesskiy",
                "label_ar": "Pereslavl'-Zalesskiy",
                "label_fr": "Pereslavl'-Zalesskiy"
            },
            {
                "value": 22622,
                "label": "Zima",
                "label_ar": "زيما",
                "label_fr": "Zima"
            },
            {
                "value": 22684,
                "label": "Berezayka",
                "label_ar": "بيريزيكا",
                "label_fr": "Berezayka"
            },
            {
                "value": 22217,
                "label": "Alapayevsk",
                "label_ar": "ألباييفسك",
                "label_fr": "Alapayevsk"
            },
            {
                "value": 22993,
                "label": "Pulkovo",
                "label_ar": "بولكوفو",
                "label_fr": "Pulkovo"
            },
            {
                "value": 22476,
                "label": "Fryazino",
                "label_ar": "فريازينو",
                "label_fr": "Fryazino"
            },
            {
                "value": 22828,
                "label": "Sosnovo",
                "label_ar": "سوسنوفو",
                "label_fr": "Sosnovo"
            },
            {
                "value": 22346,
                "label": "Pokrov",
                "label_ar": "بوكروف",
                "label_fr": "Pokrov"
            },
            {
                "value": 22596,
                "label": "Lobnya",
                "label_ar": "لوبنيا",
                "label_fr": "Lobnya"
            },
            {
                "value": 22883,
                "label": "Al'fa",
                "label_ar": "الفا",
                "label_fr": "Al'fa"
            },
            {
                "value": 22428,
                "label": "Tobol'sk",
                "label_ar": "توبولسك",
                "label_fr": "Tobol'sk"
            },
            {
                "value": 22589,
                "label": "Primor'ye",
                "label_ar": "بريموري",
                "label_fr": "Primor'ye"
            },
            {
                "value": 22830,
                "label": "Vostochnyy",
                "label_ar": "فوستوشني",
                "label_fr": "Vostochnyy"
            },
            {
                "value": 23200,
                "label": "Mamontovo",
                "label_ar": "مامونتوفو",
                "label_fr": "Mamontovo"
            },
            {
                "value": 22799,
                "label": "Gubakha",
                "label_ar": "جوباخا",
                "label_fr": "Gubakha"
            },
            {
                "value": 22381,
                "label": "Mosal'sk",
                "label_ar": "موصلسك",
                "label_fr": "Mosal'sk"
            },
            {
                "value": 22887,
                "label": "Novomichurinsk",
                "label_ar": "نوفوميتشورينسك",
                "label_fr": "Novomichurinsk"
            },
            {
                "value": 23078,
                "label": "Krasnodarskiy",
                "label_ar": "كراسنودارسكي",
                "label_fr": "Krasnodarskiy"
            },
            {
                "value": 22952,
                "label": "Kamenka",
                "label_ar": "كامينكا",
                "label_fr": "Kamenka"
            },
            {
                "value": 22226,
                "label": "Sysert'",
                "label_ar": "سيسرت",
                "label_fr": "Sysert '"
            },
            {
                "value": 22585,
                "label": "Partizansk",
                "label_ar": "بارتيزانسك",
                "label_fr": "Partizansk"
            },
            {
                "value": 22524,
                "label": "Lesosibirsk",
                "label_ar": "ليسوسيبيرسك",
                "label_fr": "Lesosibirsk"
            },
            {
                "value": 22922,
                "label": "Bogorodskoye",
                "label_ar": "بوجورودسكوي",
                "label_fr": "Bogorodskoye"
            },
            {
                "value": 23142,
                "label": "Kusakovka",
                "label_ar": "كوساكوفكا",
                "label_fr": "Kusakovka"
            },
            {
                "value": 23034,
                "label": "Valday",
                "label_ar": "فالداي",
                "label_fr": "Valday"
            },
            {
                "value": 22881,
                "label": "Murom",
                "label_ar": "موروم",
                "label_fr": "Murom"
            },
            {
                "value": 22220,
                "label": "Lys'va",
                "label_ar": "ليسفا",
                "label_fr": "Lys'va"
            },
            {
                "value": 23115,
                "label": "Aleysk",
                "label_ar": "أليسك",
                "label_fr": "Aleysk"
            },
            {
                "value": 22974,
                "label": "Kimovsk",
                "label_ar": "كيموفسك",
                "label_fr": "Kimovsk"
            },
            {
                "value": 23253,
                "label": "Neva",
                "label_ar": "نيفا",
                "label_fr": "Neva"
            },
            {
                "value": 22517,
                "label": "Ivanovo",
                "label_ar": "إيفانوفو",
                "label_fr": "Ivanovo"
            },
            {
                "value": 22941,
                "label": "Borkovka",
                "label_ar": "بوركوفكا",
                "label_fr": "Borkovka"
            },
            {
                "value": 22730,
                "label": "Kyshtym",
                "label_ar": "كيشتيم",
                "label_fr": "Kyshtym"
            },
            {
                "value": 23185,
                "label": "Boksitogorsk",
                "label_ar": "بوكسيتوغورسك",
                "label_fr": "Boksitogorsk"
            },
            {
                "value": 22513,
                "label": "Kalininskaya",
                "label_ar": "كالينينسكايا",
                "label_fr": "Kalininskaya"
            },
            {
                "value": 22580,
                "label": "Arkhangel'skoye",
                "label_ar": "أرخانجيلسكوي",
                "label_fr": "Arkhangel'skoye"
            },
            {
                "value": 22307,
                "label": "Zernograd",
                "label_ar": "زيرنوغراد",
                "label_fr": "Zernograd"
            },
            {
                "value": 22739,
                "label": "Ust'-Katav",
                "label_ar": "أوست-كتاف",
                "label_fr": "Ust'-Katav"
            },
            {
                "value": 22971,
                "label": "Segezha",
                "label_ar": "سيجيزها",
                "label_fr": "Segezha"
            },
            {
                "value": 23272,
                "label": "Sodyshka",
                "label_ar": "سوديشكا",
                "label_fr": "Sodyshka"
            },
            {
                "value": 23164,
                "label": "Lyambir'",
                "label_ar": "Lyambir '",
                "label_fr": "Lyambir '"
            },
            {
                "value": 22416,
                "label": "Voskresenskoye",
                "label_ar": "فوسكريسينسكوي",
                "label_fr": "Voskresenskoye"
            },
            {
                "value": 23172,
                "label": "Kurtamysh",
                "label_ar": "كورتاميش",
                "label_fr": "Kurtamysh"
            },
            {
                "value": 22733,
                "label": "Lebedev",
                "label_ar": "ليبيديف",
                "label_fr": "Lebedev"
            },
            {
                "value": 22430,
                "label": "Orenburg",
                "label_ar": "أورينبورغ",
                "label_fr": "Orenbourg"
            },
            {
                "value": 22294,
                "label": "Iglino",
                "label_ar": "إيغلينو",
                "label_fr": "Iglino"
            },
            {
                "value": 22561,
                "label": "Kupavna",
                "label_ar": "كوبافنا",
                "label_fr": "Kupavna"
            },
            {
                "value": 22760,
                "label": "Pargolovo",
                "label_ar": "بارغولوفو",
                "label_fr": "Pargolovo"
            },
            {
                "value": 22801,
                "label": "Minusinsk",
                "label_ar": "مينوسينسك",
                "label_fr": "Minusinsk"
            },
            {
                "value": 22601,
                "label": "Elektrougli",
                "label_ar": "إلكتروجلي",
                "label_fr": "Elektrougli"
            },
            {
                "value": 22522,
                "label": "Svetlyy",
                "label_ar": "سفيتي",
                "label_fr": "Svetlyy"
            },
            {
                "value": 22330,
                "label": "Glazov",
                "label_ar": "جلازوف",
                "label_fr": "Glazov"
            },
            {
                "value": 22533,
                "label": "Neftekamsk",
                "label_ar": "نفتكامسك",
                "label_fr": "Neftekamsk"
            },
            {
                "value": 22448,
                "label": "Vyshniy Volochëk",
                "label_ar": "فيشني فولوتشوك",
                "label_fr": "Vyshniy Volochëk"
            },
            {
                "value": 22382,
                "label": "Kamyshin",
                "label_ar": "كاميشين",
                "label_fr": "Kamyshin"
            },
            {
                "value": 22161,
                "label": "Ryazan",
                "label_ar": "ريازان",
                "label_fr": "Ryazan"
            },
            {
                "value": 22937,
                "label": "Anzhero-Sudzhensk",
                "label_ar": "أنزيرو سودجينسك",
                "label_fr": "Anzhero-Sudzhensk"
            },
            {
                "value": 22342,
                "label": "Apatity",
                "label_ar": "عدم المبالاة",
                "label_fr": "Apatité"
            },
            {
                "value": 23211,
                "label": "Volochek",
                "label_ar": "فولوتشيك",
                "label_fr": "Volochek"
            },
            {
                "value": 23042,
                "label": "Gubkin",
                "label_ar": "جوبكين",
                "label_fr": "Gubkin"
            },
            {
                "value": 22398,
                "label": "Zheleznogorsk",
                "label_ar": "جيليزنوجورسك",
                "label_fr": "Zheleznogorsk"
            },
            {
                "value": 22383,
                "label": "Nikolayevsk",
                "label_ar": "نيكولايفسك",
                "label_fr": "Nikolayevsk"
            },
            {
                "value": 22873,
                "label": "Orshanka",
                "label_ar": "أورشانكا",
                "label_fr": "Orshanka"
            },
            {
                "value": 22697,
                "label": "Komsomolsk-on-Amur",
                "label_ar": "كومسومولسك أون أمور",
                "label_fr": "Komsomolsk-sur-Amour"
            },
            {
                "value": 22558,
                "label": "Konosha",
                "label_ar": "كونوشا",
                "label_fr": "Konosha"
            },
            {
                "value": 22419,
                "label": "Timashëvsk",
                "label_ar": "Timashëvsk",
                "label_fr": "Timashëvsk"
            },
            {
                "value": 22874,
                "label": "Atlas",
                "label_ar": "أطلس",
                "label_fr": "Atlas"
            },
            {
                "value": 23100,
                "label": "Divnogorsk",
                "label_ar": "ديفنوغورسك",
                "label_fr": "Divnogorsk"
            },
            {
                "value": 23230,
                "label": "Panayevsk",
                "label_ar": "باناييفسك",
                "label_fr": "Panayevsk"
            },
            {
                "value": 22257,
                "label": "Cheboksary",
                "label_ar": "تشيبوكساري",
                "label_fr": "Cheboksary"
            },
            {
                "value": 22864,
                "label": "Yel'niki",
                "label_ar": "يلنيكي",
                "label_fr": "Yel'niki"
            },
            {
                "value": 22901,
                "label": "Lada",
                "label_ar": "لادا",
                "label_fr": "Lada"
            },
            {
                "value": 23193,
                "label": "Serafimovskiy",
                "label_ar": "سيرافيموفسكي",
                "label_fr": "Serafimovskiy"
            },
            {
                "value": 22251,
                "label": "Sheksna",
                "label_ar": "شكسنا",
                "label_fr": "Sheksna"
            },
            {
                "value": 22598,
                "label": "Klin",
                "label_ar": "كلين",
                "label_fr": "Klin"
            },
            {
                "value": 22885,
                "label": "Ilim",
                "label_ar": "إليم",
                "label_fr": "Ilim"
            },
            {
                "value": 23154,
                "label": "Kondopoga",
                "label_ar": "كوندوبوجا",
                "label_fr": "Kondopoga"
            },
            {
                "value": 22845,
                "label": "Slyudyanka",
                "label_ar": "سليوديانكا",
                "label_fr": "Slyudyanka"
            },
            {
                "value": 22928,
                "label": "Buyantsevo",
                "label_ar": "Buyantsevo",
                "label_fr": "Buyantsevo"
            },
            {
                "value": 22782,
                "label": "Nikolayevsk-on-Amur",
                "label_ar": "نيكولايفسك أون أمور",
                "label_fr": "Nikolayevsk-on-Amur"
            },
            {
                "value": 22857,
                "label": "Klyuchi",
                "label_ar": "كليوتشي",
                "label_fr": "Klyuchi"
            },
            {
                "value": 22454,
                "label": "Zheleznogorsk",
                "label_ar": "جيليزنوجورسك",
                "label_fr": "Zheleznogorsk"
            },
            {
                "value": 23061,
                "label": "Yelat'ma",
                "label_ar": "يلاطا",
                "label_fr": "Yelat'ma"
            },
            {
                "value": 22530,
                "label": "Balashov",
                "label_ar": "بالاشوف",
                "label_fr": "Balashov"
            },
            {
                "value": 22155,
                "label": "Tyumen",
                "label_ar": "تيومين",
                "label_fr": "Tioumen"
            },
            {
                "value": 23237,
                "label": "Malino",
                "label_ar": "مالينو",
                "label_fr": "Malino"
            },
            {
                "value": 22124,
                "label": "Alekseyevka",
                "label_ar": "أليكسيفكا",
                "label_fr": "Alekseyevka"
            },
            {
                "value": 22588,
                "label": "Chernigovka",
                "label_ar": "تشيرنيجوفكا",
                "label_fr": "Tchernigovka"
            },
            {
                "value": 23181,
                "label": "Kola",
                "label_ar": "كولا",
                "label_fr": "Kola"
            },
            {
                "value": 23080,
                "label": "Gagino",
                "label_ar": "جاجينو",
                "label_fr": "Gagino"
            },
            {
                "value": 22899,
                "label": "Uyar",
                "label_ar": "أويار",
                "label_fr": "Uyar"
            },
            {
                "value": 22696,
                "label": "Gagarin",
                "label_ar": "جاجارين",
                "label_fr": "Gagarine"
            },
            {
                "value": 23000,
                "label": "Kastornoye",
                "label_ar": "كاستورنوي",
                "label_fr": "Kastornoye"
            },
            {
                "value": 22843,
                "label": "Uglegorsk",
                "label_ar": "أوليجورسك",
                "label_fr": "Uglegorsk"
            },
            {
                "value": 22521,
                "label": "Verkhnyaya Pyshma",
                "label_ar": "Verkhnyaya Pyshma",
                "label_fr": "Verkhnyaya Pyshma"
            },
            {
                "value": 22174,
                "label": "Ulan-Ude",
                "label_ar": "أولان أودي",
                "label_fr": "Oulan-Ude"
            },
            {
                "value": 22665,
                "label": "Magdagachi",
                "label_ar": "ماغداغاتشي",
                "label_fr": "Magdagachi"
            },
            {
                "value": 22195,
                "label": "Georgiyevsk",
                "label_ar": "جورجييفسك",
                "label_fr": "Georgiyevsk"
            },
            {
                "value": 23037,
                "label": "Tosno",
                "label_ar": "توسنو",
                "label_fr": "Tosno"
            },
            {
                "value": 22172,
                "label": "Syktyvkar",
                "label_ar": "سيكتيفكار",
                "label_fr": "Syktyvkar"
            },
            {
                "value": 23071,
                "label": "Shchëkino",
                "label_ar": "شتشكينو",
                "label_fr": "Shchëkino"
            },
            {
                "value": 22916,
                "label": "Gorin",
                "label_ar": "كورين",
                "label_fr": "Gorin"
            },
            {
                "value": 22690,
                "label": "Pugachev",
                "label_ar": "بوجاتشيف",
                "label_fr": "Pougatchev"
            },
            {
                "value": 22400,
                "label": "Saransk",
                "label_ar": "سارانسك",
                "label_fr": "Saransk"
            },
            {
                "value": 23093,
                "label": "Odan'",
                "label_ar": "أودان",
                "label_fr": "Odan '"
            },
            {
                "value": 22277,
                "label": "Tomsk",
                "label_ar": "تومسك",
                "label_fr": "Tomsk"
            },
            {
                "value": 23141,
                "label": "Taksimo",
                "label_ar": "تاكسيمو",
                "label_fr": "Taksimo"
            },
            {
                "value": 23126,
                "label": "Poselok",
                "label_ar": "بوسيلوك",
                "label_fr": "Poselok"
            },
            {
                "value": 22767,
                "label": "Prokhladnyy",
                "label_ar": "Prokhladnyy",
                "label_fr": "Prokhladnyy"
            },
            {
                "value": 23004,
                "label": "Yakhroma",
                "label_ar": "ياخرومة",
                "label_fr": "Yakhroma"
            },
            {
                "value": 22244,
                "label": "Arsen'yev",
                "label_ar": "أرسينيف",
                "label_fr": "Arsen'yev"
            },
            {
                "value": 22259,
                "label": "Veliky Novgorod",
                "label_ar": "فيليكي نوفغورود",
                "label_fr": "Veliky Novgorod"
            },
            {
                "value": 22621,
                "label": "Ust'-Kut",
                "label_ar": "أوست-كوت",
                "label_fr": "Ust'-Kut"
            },
            {
                "value": 22647,
                "label": "Pychas",
                "label_ar": "بيشاس",
                "label_fr": "Pychas"
            },
            {
                "value": 22327,
                "label": "Kuznetsk",
                "label_ar": "كوزنيتسك",
                "label_fr": "Kuznetsk"
            },
            {
                "value": 22210,
                "label": "Cherkessk",
                "label_ar": "تشيركيسك",
                "label_fr": "Tcherkessk"
            },
            {
                "value": 23276,
                "label": "Solnechnodol'sk",
                "label_ar": "Solnechnodol'sk",
                "label_fr": "Solnechnodol'sk"
            },
            {
                "value": 23265,
                "label": "Katav-Ivanovsk",
                "label_ar": "كاتاف إيفانوفسك",
                "label_fr": "Katav-Ivanovsk"
            },
            {
                "value": 22924,
                "label": "Sestroretsk",
                "label_ar": "سسترورتسك",
                "label_fr": "Sestroretsk"
            },
            {
                "value": 22576,
                "label": "Pushchino",
                "label_ar": "بوشينو",
                "label_fr": "Pushchino"
            },
            {
                "value": 22341,
                "label": "Volzhskiy",
                "label_ar": "Volzhskiy",
                "label_fr": "Volzhskiy"
            },
            {
                "value": 22674,
                "label": "Novator",
                "label_ar": "مبتدئ",
                "label_fr": "Novateur"
            },
            {
                "value": 23274,
                "label": "Pikalyovo",
                "label_ar": "بيكاليوفو",
                "label_fr": "Pikalyovo"
            },
            {
                "value": 23143,
                "label": "Bogorodsk",
                "label_ar": "بوجورودسك",
                "label_fr": "Bogorodsk"
            },
            {
                "value": 22472,
                "label": "Kazan'",
                "label_ar": "قازان",
                "label_fr": "Kazan '"
            },
            {
                "value": 22959,
                "label": "Starodub",
                "label_ar": "ستارودوب",
                "label_fr": "Starodub"
            },
            {
                "value": 22953,
                "label": "Lomov'",
                "label_ar": "لوموف",
                "label_fr": "Lomov '"
            },
            {
                "value": 22765,
                "label": "Lenkino",
                "label_ar": "لينكينو",
                "label_fr": "Lenkino"
            },
            {
                "value": 23122,
                "label": "Semibratovo",
                "label_ar": "سيمبراتوفو",
                "label_fr": "Semibratovo"
            },
            {
                "value": 22332,
                "label": "Novaya Silava",
                "label_ar": "نوفايا سيلافا",
                "label_fr": "Novaya Silava"
            },
            {
                "value": 22764,
                "label": "Ogarev",
                "label_ar": "اوغريف",
                "label_fr": "Ogarev"
            },
            {
                "value": 22634,
                "label": "Vorkuta",
                "label_ar": "فوركوتا",
                "label_fr": "Vorkuta"
            },
            {
                "value": 22335,
                "label": "Konakovo",
                "label_ar": "كوناكوفو",
                "label_fr": "Konakovo"
            },
            {
                "value": 23088,
                "label": "Starobachaty",
                "label_ar": "ستاروباكاتي",
                "label_fr": "Starobachaty"
            },
            {
                "value": 22528,
                "label": "Kaltan",
                "label_ar": "كالتان",
                "label_fr": "Kaltan"
            },
            {
                "value": 22304,
                "label": "Zhukovskiy",
                "label_ar": "جوكوفسكي",
                "label_fr": "Joukovski"
            },
            {
                "value": 22921,
                "label": "Obluch'ye",
                "label_ar": "Obluch'ye",
                "label_fr": "Obluch'ye"
            },
            {
                "value": 22700,
                "label": "Piter",
                "label_ar": "بيتر",
                "label_fr": "Piter"
            },
            {
                "value": 23232,
                "label": "Zhuravna",
                "label_ar": "Zhuravna",
                "label_fr": "Zhuravna"
            },
            {
                "value": 22854,
                "label": "Ilya",
                "label_ar": "ايليا",
                "label_fr": "Il y a"
            },
            {
                "value": 22205,
                "label": "Zolotarevka",
                "label_ar": "زولوتاريفكا",
                "label_fr": "Zolotarevka"
            },
            {
                "value": 22258,
                "label": "Taushkasy",
                "label_ar": "تاوشكاسي",
                "label_fr": "Taushkasy"
            },
            {
                "value": 22444,
                "label": "Artem",
                "label_ar": "أرتيم",
                "label_fr": "Artem"
            },
            {
                "value": 23208,
                "label": "Volna",
                "label_ar": "فولنا",
                "label_fr": "Volna"
            },
            {
                "value": 23127,
                "label": "Varvarovka",
                "label_ar": "فارفاروفكا",
                "label_fr": "Varvarovka"
            },
            {
                "value": 22441,
                "label": "Berdsk",
                "label_ar": "بيردس",
                "label_fr": "Berdsk"
            },
            {
                "value": 22450,
                "label": "Makhachkala",
                "label_ar": "محج قلعة",
                "label_fr": "Makhatchkala"
            },
            {
                "value": 22197,
                "label": "Kislovodsk",
                "label_ar": "كيسلوفودسك",
                "label_fr": "Kislovodsk"
            },
            {
                "value": 22609,
                "label": "Petropavlovka",
                "label_ar": "بتروبافلوفكا",
                "label_fr": "Petropavlovka"
            },
            {
                "value": 22377,
                "label": "Khanty-Mansiysk",
                "label_ar": "خانتي مانسيسك",
                "label_fr": "Khanty-Mansiysk"
            },
            {
                "value": 22914,
                "label": "Star'",
                "label_ar": "نجمة'",
                "label_fr": "Étoile'"
            },
            {
                "value": 22983,
                "label": "Kusa",
                "label_ar": "كوسا",
                "label_fr": "Kusa"
            },
            {
                "value": 22783,
                "label": "Birobidzhan",
                "label_ar": "بيروبيدجان",
                "label_fr": "Birobidzhan"
            },
            {
                "value": 22311,
                "label": "Shakhty",
                "label_ar": "شاختي",
                "label_fr": "Shakhty"
            },
            {
                "value": 22231,
                "label": "Arkhangelsk",
                "label_ar": "أرخانجيلسك",
                "label_fr": "Arkhangelsk"
            },
            {
                "value": 22146,
                "label": "Chelyabinsk",
                "label_ar": "تشيليابينسك",
                "label_fr": "Tcheliabinsk"
            },
            {
                "value": 22936,
                "label": "Prokop'yevsk",
                "label_ar": "بروكوبيفسك",
                "label_fr": "Prokop'yevsk"
            },
            {
                "value": 22994,
                "label": "Pyshma",
                "label_ar": "بيشما",
                "label_fr": "Pyshma"
            },
            {
                "value": 22564,
                "label": "Sukhanovo",
                "label_ar": "سوخانوفو",
                "label_fr": "Soukhanovo"
            },
            {
                "value": 22514,
                "label": "Moldavanskoye",
                "label_ar": "مولدافانسكوي",
                "label_fr": "Moldavanskoye"
            },
            {
                "value": 22855,
                "label": "Osel'ki",
                "label_ar": "أوسيلكي",
                "label_fr": "Osel'ki"
            },
            {
                "value": 22688,
                "label": "Kirzhach",
                "label_ar": "كيرزاخ",
                "label_fr": "Kirzhach"
            },
            {
                "value": 22358,
                "label": "Maloyaroslavets",
                "label_ar": "مالوياروسلافيتس",
                "label_fr": "Maloyaroslavets"
            },
            {
                "value": 22314,
                "label": "Serpukhov",
                "label_ar": "سربوخوف",
                "label_fr": "Serpoukhov"
            },
            {
                "value": 23175,
                "label": "Kichmengskiy Gorodok",
                "label_ar": "Kichmengskiy جورودوك",
                "label_fr": "Kichmengskiy Gorodok"
            },
            {
                "value": 23159,
                "label": "Shalushka",
                "label_ar": "شالوشكا",
                "label_fr": "Shalushka"
            },
            {
                "value": 23163,
                "label": "Podgortsy",
                "label_ar": "بودجورتسي",
                "label_fr": "Podgortsy"
            },
            {
                "value": 22629,
                "label": "Mozhga",
                "label_ar": "Mozhga",
                "label_fr": "Mozhga"
            },
            {
                "value": 23203,
                "label": "Sokolovo",
                "label_ar": "سوكولوفو",
                "label_fr": "Sokolovo"
            },
            {
                "value": 22762,
                "label": "Morozov",
                "label_ar": "موروزوف",
                "label_fr": "Morozov"
            },
            {
                "value": 22616,
                "label": "Klintsy",
                "label_ar": "كلينتسي",
                "label_fr": "Klintsy"
            },
            {
                "value": 22404,
                "label": "Pushkin",
                "label_ar": "بوشكين",
                "label_fr": "Pouchkine"
            },
            {
                "value": 22483,
                "label": "Novozolotovskaya",
                "label_ar": "نوفوزولوتوفسكايا",
                "label_fr": "Novozolotovskaya"
            },
            {
                "value": 22567,
                "label": "Dubna",
                "label_ar": "دوبنا",
                "label_fr": "Dubna"
            },
            {
                "value": 23262,
                "label": "Yemel'yanovo",
                "label_ar": "Yemel'yanovo",
                "label_fr": "Yemel'yanovo"
            },
            {
                "value": 22620,
                "label": "Zavodouspenskoye",
                "label_ar": "Zavodouspenskoye",
                "label_fr": "Zavodouspenskoye"
            },
            {
                "value": 22488,
                "label": "Chaykovskiy",
                "label_ar": "تشايكوفسكي",
                "label_fr": "Chaykovskiy"
            },
            {
                "value": 22485,
                "label": "Orlovskiy",
                "label_ar": "أورلوفسكي",
                "label_fr": "Orlovskiy"
            },
            {
                "value": 23045,
                "label": "Shaturtorf",
                "label_ar": "شاتورتورف",
                "label_fr": "Shaturtorf"
            },
            {
                "value": 22232,
                "label": "Chernyakhovsk",
                "label_ar": "تشيرنياكوفسك",
                "label_fr": "Tchernyakhovsk"
            },
            {
                "value": 22683,
                "label": "Stroitel'",
                "label_ar": "ستروتيل",
                "label_fr": "Stroitel '"
            },
            {
                "value": 22770,
                "label": "Krasnyy Yar",
                "label_ar": "كراسني يار",
                "label_fr": "Krasnyy Yar"
            },
            {
                "value": 22151,
                "label": "Voronezh",
                "label_ar": "فورونيج",
                "label_fr": "Voronej"
            },
            {
                "value": 22349,
                "label": "Unecha",
                "label_ar": "Unecha",
                "label_fr": "Unecha"
            },
            {
                "value": 22378,
                "label": "Kstovo",
                "label_ar": "Kstovo",
                "label_fr": "Kstovo"
            },
            {
                "value": 22938,
                "label": "Berezovka",
                "label_ar": "بيريزوفكا",
                "label_fr": "Berezovka"
            },
            {
                "value": 22354,
                "label": "Severobaykal'sk",
                "label_ar": "سيفروبايكالسك",
                "label_fr": "Severobaykal'sk"
            },
            {
                "value": 23098,
                "label": "Leninogorsk",
                "label_ar": "لينينوجورسك",
                "label_fr": "Léninogorsk"
            },
            {
                "value": 23129,
                "label": "Ob'",
                "label_ar": "أوب",
                "label_fr": "Ob '"
            },
            {
                "value": 22877,
                "label": "Buzuluk",
                "label_ar": "بوزولوك",
                "label_fr": "Buzuluk"
            },
            {
                "value": 22605,
                "label": "Gran'",
                "label_ar": "غران",
                "label_fr": "Gran '"
            },
            {
                "value": 22309,
                "label": "Kamensk-Shakhtinskiy",
                "label_ar": "كامينسك شاختنسكي",
                "label_fr": "Kamensk-Shakhtinskiy"
            },
            {
                "value": 23123,
                "label": "Yugra",
                "label_ar": "يوجرا",
                "label_fr": "Yugra"
            },
            {
                "value": 22915,
                "label": "Rostovskaya",
                "label_ar": "روستوفسكايا",
                "label_fr": "Rostovskaya"
            },
            {
                "value": 22702,
                "label": "Vitebskaya",
                "label_ar": "فيتيبسكايا",
                "label_fr": "Vitebskaya"
            },
            {
                "value": 22240,
                "label": "Nakhodka",
                "label_ar": "ناخودكا",
                "label_fr": "Nakhodka"
            },
            {
                "value": 23240,
                "label": "Poztykeres",
                "label_ar": "Poztykeres",
                "label_fr": "Poztykeres"
            },
            {
                "value": 22719,
                "label": "Krasnoarmeyskaya",
                "label_ar": "كراسنوارميسكايا",
                "label_fr": "Krasnoarmeyskaya"
            },
            {
                "value": 22183,
                "label": "Nizhnevartovsk",
                "label_ar": "نيجنفارتوفسك",
                "label_fr": "Nizhnevartovsk"
            },
            {
                "value": 22650,
                "label": "Bras",
                "label_ar": "براس",
                "label_fr": "Bras"
            },
            {
                "value": 23196,
                "label": "Klyuchi-Bulak",
                "label_ar": "كليوتشي بولاك",
                "label_fr": "Klyuchi-Bulak"
            },
            {
                "value": 22817,
                "label": "Sviblovo",
                "label_ar": "سفيبلوفو",
                "label_fr": "Sviblovo"
            },
            {
                "value": 23195,
                "label": "Kirovskaya",
                "label_ar": "كيروفسكايا",
                "label_fr": "Kirovskaya"
            },
            {
                "value": 23020,
                "label": "Mineralnye Vody",
                "label_ar": "مينيراليني فودي",
                "label_fr": "Mineralnye Vody"
            },
            {
                "value": 22457,
                "label": "Krasnogorsk",
                "label_ar": "كراسنوجورسك",
                "label_fr": "Krasnogorsk"
            },
            {
                "value": 22345,
                "label": "Tuapse",
                "label_ar": "توابسي",
                "label_fr": "Tuapse"
            },
            {
                "value": 23110,
                "label": "Suzëmka",
                "label_ar": "سوزيمكا",
                "label_fr": "Suzëmka"
            },
            {
                "value": 22495,
                "label": "Fatezh",
                "label_ar": "فاتح",
                "label_fr": "Fatezh"
            },
            {
                "value": 22581,
                "label": "Kanavka",
                "label_ar": "كانافكا",
                "label_fr": "Kanavka"
            },
            {
                "value": 22464,
                "label": "Veshki",
                "label_ar": "فيشكي",
                "label_fr": "Veshki"
            },
            {
                "value": 23199,
                "label": "Srostki",
                "label_ar": "سروستكي",
                "label_fr": "Srostki"
            },
            {
                "value": 22348,
                "label": "Polevskoy",
                "label_ar": "بوليفسكوي",
                "label_fr": "Polevskoy"
            },
            {
                "value": 22628,
                "label": "Marks",
                "label_ar": "ماركس",
                "label_fr": "Des marques"
            },
            {
                "value": 22449,
                "label": "Belogorsk",
                "label_ar": "بيلوجورسك",
                "label_fr": "Belogorsk"
            },
            {
                "value": 23205,
                "label": "Naberezhnyye Chelny",
                "label_ar": "Naberezhnyye Chelny",
                "label_fr": "Naberezhnyye Chelny"
            },
            {
                "value": 22440,
                "label": "Mezhdurechensk",
                "label_ar": "Mezhdurechensk",
                "label_fr": "Mezhdurechensk"
            },
            {
                "value": 22238,
                "label": "Anuchino",
                "label_ar": "أنوشينو",
                "label_fr": "Anuchino"
            },
            {
                "value": 22943,
                "label": "Kumertau",
                "label_ar": "كوميرتاو",
                "label_fr": "Kumertau"
            },
            {
                "value": 22669,
                "label": "Neryungri",
                "label_ar": "نيريونجري",
                "label_fr": "Neryungri"
            },
            {
                "value": 22214,
                "label": "Urengoy",
                "label_ar": "يورنغوي",
                "label_fr": "Urengoy"
            },
            {
                "value": 22177,
                "label": "Kushva",
                "label_ar": "كوشفا",
                "label_fr": "Kushva"
            },
            {
                "value": 22166,
                "label": "Mytishchi",
                "label_ar": "Mytishchi",
                "label_fr": "Mytishchi"
            },
            {
                "value": 22227,
                "label": "Nev'yansk",
                "label_ar": "نيفيانسك",
                "label_fr": "Nev'yansk"
            },
            {
                "value": 22321,
                "label": "Arzamas",
                "label_ar": "أرزاماس",
                "label_fr": "Arzamas"
            },
            {
                "value": 22880,
                "label": "Ovchinnikov",
                "label_ar": "أوفشينيكوف",
                "label_fr": "Ovchinnikov"
            },
            {
                "value": 22347,
                "label": "Nevinnomyssk",
                "label_ar": "نيفينوميسك",
                "label_fr": "Nevinnomyssk"
            },
            {
                "value": 22871,
                "label": "Gorodna",
                "label_ar": "جورودنا",
                "label_fr": "Gorodna"
            },
            {
                "value": 22302,
                "label": "Moskovskiy",
                "label_ar": "موسكوفسكي",
                "label_fr": "Moskovskiy"
            },
            {
                "value": 22216,
                "label": "Revda",
                "label_ar": "Revda",
                "label_fr": "Revda"
            },
            {
                "value": 23158,
                "label": "Mozdok",
                "label_ar": "موزدوك",
                "label_fr": "Mozdok"
            },
            {
                "value": 22147,
                "label": "Krasnodar",
                "label_ar": "كراسنودار",
                "label_fr": "Krasnodar"
            },
            {
                "value": 22193,
                "label": "Yessentuki",
                "label_ar": "يسينتوكي",
                "label_fr": "Yessentuki"
            },
            {
                "value": 23103,
                "label": "Morozovsk",
                "label_ar": "موروزوفسك",
                "label_fr": "Morozovsk"
            },
            {
                "value": 23117,
                "label": "Sosny",
                "label_ar": "سوسني",
                "label_fr": "Sosny"
            },
            {
                "value": 22574,
                "label": "Techa",
                "label_ar": "تيكا",
                "label_fr": "Techa"
            },
            {
                "value": 22948,
                "label": "Shchelkovo",
                "label_ar": "Shchelkovo",
                "label_fr": "Shchelkovo"
            },
            {
                "value": 23179,
                "label": "Poselok",
                "label_ar": "بوسيلوك",
                "label_fr": "Poselok"
            },
            {
                "value": 22816,
                "label": "Sofrino",
                "label_ar": "سوفرينو",
                "label_fr": "Sofrino"
            },
            {
                "value": 22654,
                "label": "Morshansk",
                "label_ar": "مورشانسك",
                "label_fr": "Morshansk"
            },
            {
                "value": 22640,
                "label": "Pryazha",
                "label_ar": "بريازا",
                "label_fr": "Pryazha"
            },
            {
                "value": 22163,
                "label": "Khimki",
                "label_ar": "خيمكي",
                "label_fr": "Khimki"
            },
            {
                "value": 22178,
                "label": "Liski",
                "label_ar": "ليسكي",
                "label_fr": "Liski"
            },
            {
                "value": 22906,
                "label": "Udomlya",
                "label_ar": "أودومليا",
                "label_fr": "Udomlya"
            },
            {
                "value": 22743,
                "label": "Yelabuga",
                "label_ar": "يلابوجا",
                "label_fr": "Yelabuga"
            },
            {
                "value": 22246,
                "label": "Artëm",
                "label_ar": "ارطوم",
                "label_fr": "Artëm"
            },
            {
                "value": 22451,
                "label": "Kuban'",
                "label_ar": "كوبان",
                "label_fr": "Kuban '"
            },
            {
                "value": 22376,
                "label": "Nytva",
                "label_ar": "نيتفا",
                "label_fr": "Nytva"
            },
            {
                "value": 22949,
                "label": "Sverdlovskiy",
                "label_ar": "سفيردلوفسكي",
                "label_fr": "Sverdlovskiy"
            },
            {
                "value": 23062,
                "label": "Kol'chugino",
                "label_ar": "كولتشوجينو",
                "label_fr": "Kol'chugino"
            },
            {
                "value": 23113,
                "label": "Volgorechensk",
                "label_ar": "فولغوريشينسك",
                "label_fr": "Volgorechensk"
            },
            {
                "value": 23191,
                "label": "Tot'ma",
                "label_ar": "توتما",
                "label_fr": "Tot'ma"
            },
            {
                "value": 22466,
                "label": "Zelenograd",
                "label_ar": "زيلينوجراد",
                "label_fr": "Zelenograd"
            },
            {
                "value": 22979,
                "label": "Ashchibutak",
                "label_ar": "Ashchibutak",
                "label_fr": "Ashchibutak"
            },
            {
                "value": 23035,
                "label": "Monchegorsk",
                "label_ar": "مونشيجورسك",
                "label_fr": "Monchegorsk"
            },
            {
                "value": 22772,
                "label": "Rovnoye",
                "label_ar": "روفنوي",
                "label_fr": "Rovnoye"
            },
            {
                "value": 23235,
                "label": "Tim",
                "label_ar": "تيم",
                "label_fr": "Tim"
            },
            {
                "value": 23073,
                "label": "Serdobsk",
                "label_ar": "سيردوبسك",
                "label_fr": "Serdobsk"
            },
            {
                "value": 22446,
                "label": "Novokuybyshevsk",
                "label_ar": "نوفوكويبيشيفسك",
                "label_fr": "Novokuybyshevsk"
            },
            {
                "value": 22934,
                "label": "Zheleznogorsk-Ilimskiy",
                "label_ar": "Zheleznogorsk-Ilimskiy",
                "label_fr": "Zheleznogorsk-Ilimskiy"
            },
            {
                "value": 22462,
                "label": "Odintsovo",
                "label_ar": "أودينتسوفو",
                "label_fr": "Odintsovo"
            },
            {
                "value": 23077,
                "label": "Lampovo",
                "label_ar": "لامبوفو",
                "label_fr": "Lampovo"
            },
            {
                "value": 22851,
                "label": "Denisovo",
                "label_ar": "دينيسوفو",
                "label_fr": "Denisovo"
            },
            {
                "value": 22872,
                "label": "Purovsk",
                "label_ar": "بوروفسك",
                "label_fr": "Purovsk"
            },
            {
                "value": 23177,
                "label": "Syamzha",
                "label_ar": "Syamzha",
                "label_fr": "Syamzha"
            },
            {
                "value": 22184,
                "label": "Zvezda",
                "label_ar": "زفيزدا",
                "label_fr": "Zvezda"
            },
            {
                "value": 23099,
                "label": "Gornozavodsk",
                "label_ar": "جورنوزافودسك",
                "label_fr": "Gornozavodsk"
            },
            {
                "value": 22785,
                "label": "Moskovskaya",
                "label_ar": "موسكوفسكايا",
                "label_fr": "Moskovskaya"
            },
            {
                "value": 22324,
                "label": "Chkalov",
                "label_ar": "تشكالوف",
                "label_fr": "Chkalov"
            },
            {
                "value": 22856,
                "label": "Novoye Devyatkino",
                "label_ar": "نوفوي ديفياتكينو",
                "label_fr": "Novoye Devyatkino"
            },
            {
                "value": 22781,
                "label": "Zubovo",
                "label_ar": "زوبوفو",
                "label_fr": "Zubovo"
            },
            {
                "value": 22318,
                "label": "Vladimir",
                "label_ar": "فلاديمير",
                "label_fr": "Vladimir"
            },
            {
                "value": 22453,
                "label": "Liniya",
                "label_ar": "لينيا",
                "label_fr": "Liniya"
            },
            {
                "value": 22538,
                "label": "Kovrov",
                "label_ar": "كوفروف",
                "label_fr": "Kovrov"
            },
            {
                "value": 23184,
                "label": "Shlissel'burg",
                "label_ar": "شليسيلبورغ",
                "label_fr": "Shlissel'burg"
            },
            {
                "value": 23114,
                "label": "Omchak",
                "label_ar": "أومشاك",
                "label_fr": "Omchak"
            },
            {
                "value": 22970,
                "label": "Svirsk",
                "label_ar": "سفيرسك",
                "label_fr": "Svirsk"
            },
            {
                "value": 23051,
                "label": "Vurnary",
                "label_ar": "فورنيري",
                "label_fr": "Vurnary"
            },
            {
                "value": 22631,
                "label": "Nikel",
                "label_ar": "نيكيل",
                "label_fr": "Nikel"
            },
            {
                "value": 22806,
                "label": "Mariinskiy Posad",
                "label_ar": "ماريينسكي بوساد",
                "label_fr": "Mariinskiy Posad"
            },
            {
                "value": 22822,
                "label": "Mira",
                "label_ar": "ميرا",
                "label_fr": "Mira"
            },
            {
                "value": 22162,
                "label": "Avtozavodskiy Rayon",
                "label_ar": "Avtozavodskiy رايون",
                "label_fr": "Rayonne Avtozavodskiy"
            },
            {
                "value": 22768,
                "label": "Baksan",
                "label_ar": "باكسان",
                "label_fr": "Baksan"
            },
            {
                "value": 23231,
                "label": "Tayshet",
                "label_ar": "تايشيت",
                "label_fr": "Tayshet"
            },
            {
                "value": 22208,
                "label": "Mostovskoy",
                "label_ar": "موستوفسكوي",
                "label_fr": "Mostovskoy"
            },
            {
                "value": 22713,
                "label": "Magadan",
                "label_ar": "ماجادان",
                "label_fr": "Magadan"
            },
            {
                "value": 22931,
                "label": "Prirechensk",
                "label_ar": "بريريتشينسك",
                "label_fr": "Prirechensk"
            },
            {
                "value": 22520,
                "label": "Serov",
                "label_ar": "سيروف",
                "label_fr": "Serov"
            },
            {
                "value": 22904,
                "label": "Sheregesh",
                "label_ar": "شيريجش",
                "label_fr": "Sheregesh"
            },
            {
                "value": 22706,
                "label": "Izmaylovo",
                "label_ar": "Izmaylovo",
                "label_fr": "Izmaylovo"
            },
            {
                "value": 22969,
                "label": "Sverdlova",
                "label_ar": "سفيردلوفا",
                "label_fr": "Sverdlova"
            },
            {
                "value": 22850,
                "label": "Voskresensk",
                "label_ar": "فوسكريسينسك",
                "label_fr": "Voskresensk"
            },
            {
                "value": 22963,
                "label": "Korsakov",
                "label_ar": "كورساكوف",
                "label_fr": "Korsakov"
            },
            {
                "value": 23072,
                "label": "Donskoy",
                "label_ar": "دونسكوي",
                "label_fr": "Donskoï"
            },
            {
                "value": 23039,
                "label": "Vanino",
                "label_ar": "فانينو",
                "label_fr": "Vanino"
            },
            {
                "value": 23036,
                "label": "Zori",
                "label_ar": "زوري",
                "label_fr": "Zori"
            },
            {
                "value": 23013,
                "label": "Mga",
                "label_ar": "إمغا",
                "label_fr": "Mga"
            },
            {
                "value": 22176,
                "label": "Surgut",
                "label_ar": "سورجوت",
                "label_fr": "Surgut"
            },
            {
                "value": 22944,
                "label": "Zelenogradsk",
                "label_ar": "زيلينوجرادسك",
                "label_fr": "Zelenogradsk"
            },
            {
                "value": 22425,
                "label": "Noyabrsk",
                "label_ar": "نويابرسك",
                "label_fr": "Noyabrsk"
            },
            {
                "value": 22699,
                "label": "Leningradskiy",
                "label_ar": "لينينغرادسكي",
                "label_fr": "Leningradskiy"
            },
            {
                "value": 23119,
                "label": "Belaya Kalitva",
                "label_ar": "بيلايا كاليتفا",
                "label_fr": "Belaya Kalitva"
            },
            {
                "value": 22693,
                "label": "Zhigulevsk",
                "label_ar": "زيغوليفسك",
                "label_fr": "Zhigulevsk"
            },
            {
                "value": 22819,
                "label": "Sergiyev Posad",
                "label_ar": "سيرجيف بوساد",
                "label_fr": "Sergiyev Posad"
            },
            {
                "value": 23087,
                "label": "Gusinoozyorsk",
                "label_ar": "جوسينوزيورسك",
                "label_fr": "Gusinoozyorsk"
            },
            {
                "value": 23092,
                "label": "Yaya",
                "label_ar": "يايا",
                "label_fr": "Yaya"
            },
            {
                "value": 23283,
                "label": "Kel'dysh",
                "label_ar": "كلديش",
                "label_fr": "Kel'dysh"
            },
            {
                "value": 22672,
                "label": "Magnitnyy",
                "label_ar": "Magnitnyy",
                "label_fr": "Magnitnyy"
            },
            {
                "value": 23112,
                "label": "Pechory",
                "label_ar": "Pechory",
                "label_fr": "Pechory"
            },
            {
                "value": 23064,
                "label": "Uchaly",
                "label_ar": "Uchaly",
                "label_fr": "Uchaly"
            },
            {
                "value": 23169,
                "label": "Ivdel'",
                "label_ar": "إيفديل",
                "label_fr": "Ivdel '"
            },
            {
                "value": 22554,
                "label": "Tynda",
                "label_ar": "تيندا",
                "label_fr": "Tynda"
            },
            {
                "value": 22199,
                "label": "Sochi",
                "label_ar": "سوتشي",
                "label_fr": "Sotchi"
            },
            {
                "value": 22399,
                "label": "Dzerzhinsk",
                "label_ar": "دزيرجينسك",
                "label_fr": "Dzerzhinsk"
            },
            {
                "value": 23271,
                "label": "Ostankino",
                "label_ar": "اوستانكينو",
                "label_fr": "Ostankino"
            },
            {
                "value": 22973,
                "label": "Shchekino",
                "label_ar": "Shchekino",
                "label_fr": "Shchekino"
            },
            {
                "value": 22913,
                "label": "Ibrya",
                "label_ar": "ابريا",
                "label_fr": "Ibrya"
            },
            {
                "value": 22882,
                "label": "Verkhniy Ufaley",
                "label_ar": "Verkhniy Ufaley",
                "label_fr": "Verkhniy Ufaley"
            },
            {
                "value": 22645,
                "label": "Votkinsk",
                "label_ar": "فوتكينسك",
                "label_fr": "Votkinsk"
            },
            {
                "value": 22393,
                "label": "Chelbasskaya",
                "label_ar": "شلبسكايا",
                "label_fr": "Chelbasskaya"
            },
            {
                "value": 23239,
                "label": "Kamennogorsk",
                "label_ar": "كامينوجورسك",
                "label_fr": "Kamennogorsk"
            },
            {
                "value": 23085,
                "label": "Olenegorsk",
                "label_ar": "اولينيغورسك",
                "label_fr": "Olenegorsk"
            },
            {
                "value": 22967,
                "label": "Osnova",
                "label_ar": "أوسنوفا",
                "label_fr": "Osnova"
            },
            {
                "value": 22299,
                "label": "Belebey",
                "label_ar": "بيليبي",
                "label_fr": "Belebey"
            },
            {
                "value": 23107,
                "label": "Yermolkino",
                "label_ar": "يرمولكينو",
                "label_fr": "Yermolkino"
            },
            {
                "value": 22159,
                "label": "Khabarovsk",
                "label_ar": "خاباروفسك",
                "label_fr": "Khabarovsk"
            },
            {
                "value": 22638,
                "label": "Svetlogorsk",
                "label_ar": "سفيتلوغورسك",
                "label_fr": "Svetlogorsk"
            },
            {
                "value": 23249,
                "label": "Syas'stroy",
                "label_ar": "Syas'stroy",
                "label_fr": "Syas'stroy"
            },
            {
                "value": 22607,
                "label": "Agidel",
                "label_ar": "Agidel",
                "label_fr": "Agidel"
            },
            {
                "value": 23111,
                "label": "Kozel'sk",
                "label_ar": "كوزيلسك",
                "label_fr": "Kozel'sk"
            },
            {
                "value": 22708,
                "label": "Ural'skaya",
                "label_ar": "Ural'skaya",
                "label_fr": "Ural'skaya"
            },
            {
                "value": 23056,
                "label": "Parapino",
                "label_ar": "بارابينو",
                "label_fr": "Parapino"
            },
            {
                "value": 22500,
                "label": "Khabarskoye",
                "label_ar": "خبرسكوي",
                "label_fr": "Khabarskoïe"
            },
            {
                "value": 22204,
                "label": "Novocherkassk",
                "label_ar": "نوفوتشركاسك",
                "label_fr": "Novotcherkassk"
            },
            {
                "value": 23260,
                "label": "Salym",
                "label_ar": "سالم",
                "label_fr": "Salym"
            },
            {
                "value": 22820,
                "label": "Bugry",
                "label_ar": "بوغري",
                "label_fr": "Bugry"
            },
            {
                "value": 22136,
                "label": "Ulyanovsk",
                "label_ar": "أوليانوفسك",
                "label_fr": "Oulianovsk"
            },
            {
                "value": 22821,
                "label": "Ugra",
                "label_ar": "أوجرا",
                "label_fr": "Ugra"
            },
            {
                "value": 23018,
                "label": "Likino-Dulevo",
                "label_ar": "ليكينو دوليفو",
                "label_fr": "Likino-Dulevo"
            },
            {
                "value": 23280,
                "label": "Aleksino",
                "label_ar": "ألكسينو",
                "label_fr": "Aleksino"
            },
            {
                "value": 22565,
                "label": "Kosteniki",
                "label_ar": "Kosteniki",
                "label_fr": "Kosteniki"
            },
            {
                "value": 22691,
                "label": "Otradnyy",
                "label_ar": "أوترادني",
                "label_fr": "Otradnyy"
            },
            {
                "value": 23186,
                "label": "Svetogorsk",
                "label_ar": "سفيتوجورسك",
                "label_fr": "Svetogorsk"
            },
            {
                "value": 23005,
                "label": "Svobodnaya",
                "label_ar": "سفوبودنايا",
                "label_fr": "Svobodnaya"
            },
            {
                "value": 22679,
                "label": "Lyubertsy",
                "label_ar": "يوبيرتسي",
                "label_fr": "Lyubertsy"
            },
            {
                "value": 22611,
                "label": "Nazran",
                "label_ar": "نازران",
                "label_fr": "Nazran"
            },
            {
                "value": 22836,
                "label": "Miasskoye",
                "label_ar": "مياسكوي",
                "label_fr": "Miasskoye"
            },
            {
                "value": 22490,
                "label": "Novozavedennoye",
                "label_ar": "نوفوزافيدينوي",
                "label_fr": "Novozavedennoye"
            },
            {
                "value": 22189,
                "label": "Kuybyshev",
                "label_ar": "كويبيشيف",
                "label_fr": "Kuybyshev"
            },
            {
                "value": 23001,
                "label": "Kyzyl",
                "label_ar": "كيزيل",
                "label_fr": "Kyzyl"
            },
            {
                "value": 23082,
                "label": "Chaplygin",
                "label_ar": "شبليجين",
                "label_fr": "Chaplygin"
            },
            {
                "value": 23238,
                "label": "Semyonov",
                "label_ar": "سيميونوف",
                "label_fr": "Semyonov"
            },
            {
                "value": 23215,
                "label": "Tolstoy",
                "label_ar": "تولستوي",
                "label_fr": "Tolstoï"
            },
            {
                "value": 23144,
                "label": "Kizlyar",
                "label_ar": "كيزليار",
                "label_fr": "Kizlyar"
            },
            {
                "value": 23157,
                "label": "Pregradnaya",
                "label_ar": "بريجرادنايا",
                "label_fr": "Pregradnaya"
            },
            {
                "value": 23057,
                "label": "Snegiri",
                "label_ar": "سنيجيري",
                "label_fr": "Snegiri"
            },
            {
                "value": 23156,
                "label": "Tyrnyauz",
                "label_ar": "تيرنيوز",
                "label_fr": "Tyrnyauz"
            },
            {
                "value": 23160,
                "label": "Vyatskiye Polyany",
                "label_ar": "فياتسكي بولياني",
                "label_fr": "Vyatskiye Polyany"
            },
            {
                "value": 23259,
                "label": "Volodarskiy",
                "label_ar": "فولودارسكي",
                "label_fr": "Volodarskiy"
            },
            {
                "value": 23192,
                "label": "Oktyabr'skiy",
                "label_ar": "Oktyabr'skiy",
                "label_fr": "Oktyabr'skiy"
            },
            {
                "value": 23145,
                "label": "Kotel'nich",
                "label_ar": "Kotel'nich",
                "label_fr": "Kotel'nich"
            }
        ]
    },
    {
        "country": "France",
        "cities": [
            {
                "value": 23288,
                "label": "Pont-Saint-Martin",
                "label_ar": "بونت سانت مارتن",
                "label_fr": "Pont-Saint-Martin"
            },
            {
                "value": 23289,
                "label": "Luce",
                "label_ar": "لوس",
                "label_fr": "Luce"
            },
            {
                "value": 23290,
                "label": "Montaigu",
                "label_ar": "مونتايجو",
                "label_fr": "Montaigu"
            },
            {
                "value": 23291,
                "label": "La Chapelle-de-Brain",
                "label_ar": "لا شابيل دي برين",
                "label_fr": "La Chapelle-de-Brain"
            },
            {
                "value": 23292,
                "label": "Saint-Mars-du-Desert",
                "label_ar": "سان مارس دو ديزرت",
                "label_fr": "Saint-Mars-du-Désert"
            },
            {
                "value": 23293,
                "label": "Challans",
                "label_ar": "شالانز",
                "label_fr": "Challans"
            },
            {
                "value": 23294,
                "label": "Le Mans",
                "label_ar": "لو مان",
                "label_fr": "le Mans"
            },
            {
                "value": 23295,
                "label": "Saint-Laurent-de-la-Plaine",
                "label_ar": "سان لوران دي لا بلين",
                "label_fr": "Saint-Laurent-de-la-Plaine"
            },
            {
                "value": 23296,
                "label": "Baule",
                "label_ar": "بول",
                "label_fr": "Baoulé"
            },
            {
                "value": 23297,
                "label": "Cholet",
                "label_ar": "شوليت",
                "label_fr": "Cholet"
            },
            {
                "value": 23298,
                "label": "Bouaye",
                "label_ar": "بواي",
                "label_fr": "Bouaye"
            },
            {
                "value": 23299,
                "label": "Le Poire-sur-Velluire",
                "label_ar": "لو بوار سور فيلوير",
                "label_fr": "Le Poire-sur-Velluire"
            },
            {
                "value": 23300,
                "label": "Nantes",
                "label_ar": "نانت",
                "label_fr": "Nantes"
            },
            {
                "value": 23301,
                "label": "Trelaze",
                "label_ar": "تريلازي",
                "label_fr": "Trelaze"
            },
            {
                "value": 23302,
                "label": "Pornichet",
                "label_ar": "بورنيشيت",
                "label_fr": "Pornichet"
            },
            {
                "value": 23303,
                "label": "Sallertaine",
                "label_ar": "Sallertaine",
                "label_fr": "Sallertaine"
            },
            {
                "value": 23304,
                "label": "La Montagne",
                "label_ar": "لا مونتاني",
                "label_fr": "La Montagne"
            },
            {
                "value": 23305,
                "label": "Angers",
                "label_ar": "أنجيه",
                "label_fr": "Angers"
            },
            {
                "value": 23306,
                "label": "Saint-Nazaire",
                "label_ar": "سان نازير",
                "label_fr": "Saint-Nazaire"
            },
            {
                "value": 23307,
                "label": "Chateaubriant",
                "label_ar": "شاتوبريانت",
                "label_fr": "Châteaubriant"
            },
            {
                "value": 23308,
                "label": "Saint-Michel-le-Cloucq",
                "label_ar": "سان ميشيل لو كلوك",
                "label_fr": "Saint-Michel-le-Cloucq"
            },
            {
                "value": 23309,
                "label": "Brissac-Quince",
                "label_ar": "بريساك سفرجل",
                "label_fr": "Brissac-Coing"
            },
            {
                "value": 23310,
                "label": "Saint-Julien-de-Concelles",
                "label_ar": "سان جوليان دي كونسيلس",
                "label_fr": "Saint-Julien-de-Concelles"
            },
            {
                "value": 23311,
                "label": "Andouille",
                "label_ar": "أندويل",
                "label_fr": "Andouille"
            },
            {
                "value": 23312,
                "label": "Bessay",
                "label_ar": "بيسيه",
                "label_fr": "Bessay"
            },
            {
                "value": 23313,
                "label": "Chavagnes-en-Paillers",
                "label_ar": "Chavagnes-en-Paillers",
                "label_fr": "Chavagnes-en-Paillers"
            },
            {
                "value": 23314,
                "label": "Moutiers-les-Mauxfaits",
                "label_ar": "موتيه ليه ماوكسفيتس",
                "label_fr": "Moutiers-les-Mauxfaits"
            },
            {
                "value": 23315,
                "label": "Sainte-Luce-sur-Loire",
                "label_ar": "سانت لوس سور لوار",
                "label_fr": "Sainte-Luce-sur-Loire"
            },
            {
                "value": 23316,
                "label": "Coueron",
                "label_ar": "كويرون",
                "label_fr": "Coueron"
            },
            {
                "value": 23317,
                "label": "Savenay",
                "label_ar": "Savenay",
                "label_fr": "Savenay"
            },
            {
                "value": 23318,
                "label": "Craon",
                "label_ar": "كرون",
                "label_fr": "Craon"
            },
            {
                "value": 23319,
                "label": "Vritz",
                "label_ar": "فريتز",
                "label_fr": "Vritz"
            },
            {
                "value": 23320,
                "label": "Chanteloup-les-Bois",
                "label_ar": "Chanteloup-les-Bois",
                "label_fr": "Chanteloup-les-Bois"
            },
            {
                "value": 23321,
                "label": "Thouare-sur-Loire",
                "label_ar": "Thouare-sur-Loire",
                "label_fr": "Thouare-sur-Loire"
            },
            {
                "value": 23322,
                "label": "Rouillon",
                "label_ar": "رويلون",
                "label_fr": "Rouillon"
            },
            {
                "value": 23323,
                "label": "Le Pellerin",
                "label_ar": "لو بيليرين",
                "label_fr": "Le Pellerin"
            },
            {
                "value": 23324,
                "label": "Clisson",
                "label_ar": "كليسون",
                "label_fr": "Clisson"
            },
            {
                "value": 23325,
                "label": "Saint-Herblain",
                "label_ar": "سان هيربلين",
                "label_fr": "Saint-Herblain"
            },
            {
                "value": 23326,
                "label": "Les Sables",
                "label_ar": "ليه سابليس",
                "label_fr": "Les Sables"
            },
            {
                "value": 23327,
                "label": "Aizenay",
                "label_ar": "ايزيناي",
                "label_fr": "Aizenay"
            },
            {
                "value": 23328,
                "label": "Ecommoy",
                "label_ar": "إكوموي",
                "label_fr": "Ecommoy"
            },
            {
                "value": 23329,
                "label": "Saint-Hilaire-de-Chaleons",
                "label_ar": "سان هيلير دي شاليون",
                "label_fr": "Saint-Hilaire-de-Chaleons"
            },
            {
                "value": 23330,
                "label": "Brain-sur-Allonnes",
                "label_ar": "برين سور ألونيس",
                "label_fr": "Brain-sur-Allonnes"
            },
            {
                "value": 23331,
                "label": "Avrille",
                "label_ar": "أفريل",
                "label_fr": "Avrille"
            },
            {
                "value": 23332,
                "label": "Breze",
                "label_ar": "بريز",
                "label_fr": "Breze"
            },
            {
                "value": 23333,
                "label": "Doue-la-Fontaine",
                "label_ar": "دو لا فونتين",
                "label_fr": "Doué-la-Fontaine"
            },
            {
                "value": 23334,
                "label": "Coulaines",
                "label_ar": "كولينز",
                "label_fr": "Coulaines"
            },
            {
                "value": 23335,
                "label": "Pere",
                "label_ar": "بيري",
                "label_fr": "Père"
            },
            {
                "value": 23336,
                "label": "Reaumur",
                "label_ar": "ريومور",
                "label_fr": "Réaumur"
            },
            {
                "value": 23337,
                "label": "Olonne-sur-Mer",
                "label_ar": "أولون سور مير",
                "label_fr": "Olonne-sur-Mer"
            },
            {
                "value": 23338,
                "label": "Montournais",
                "label_ar": "مونتورنيه",
                "label_fr": "Montournais"
            },
            {
                "value": 23339,
                "label": "Vertou",
                "label_ar": "فيرتو",
                "label_fr": "Vertou"
            },
            {
                "value": 23340,
                "label": "Sainte-Reine-de-Bretagne",
                "label_ar": "سانت رين دي بريتاني",
                "label_fr": "Sainte-Reine-de-Bretagne"
            },
            {
                "value": 23341,
                "label": "Orvault",
                "label_ar": "أورفولت",
                "label_fr": "Orvault"
            },
            {
                "value": 23342,
                "label": "Evron",
                "label_ar": "إيفرون",
                "label_fr": "Evron"
            },
            {
                "value": 23343,
                "label": "Parigne-l'Eveque",
                "label_ar": "Parigne-l'Eveque",
                "label_fr": "Parigné-l'Évêque"
            },
            {
                "value": 23344,
                "label": "Juigne-sur-Sarthe",
                "label_ar": "Juigne-sur-Sarthe",
                "label_fr": "Juigné-sur-Sarthe"
            },
            {
                "value": 23345,
                "label": "Chemille-Melay",
                "label_ar": "شيميل ملاي",
                "label_fr": "Chemille-Melay"
            },
            {
                "value": 23346,
                "label": "Renaze",
                "label_ar": "Renaze",
                "label_fr": "Renaze"
            },
            {
                "value": 23347,
                "label": "Aigrefeuille-sur-Maine",
                "label_ar": "ايجريفويل سور مين",
                "label_fr": "Aigrefeuille-sur-Maine"
            },
            {
                "value": 23348,
                "label": "Pornic",
                "label_ar": "الإباحية",
                "label_fr": "Pornic"
            },
            {
                "value": 23349,
                "label": "Montbert",
                "label_ar": "مونتبرت",
                "label_fr": "Montbert"
            },
            {
                "value": 23350,
                "label": "La Garnache",
                "label_ar": "لا جارناش",
                "label_fr": "La Garnache"
            },
            {
                "value": 23351,
                "label": "Champ",
                "label_ar": "بطل",
                "label_fr": "Champ"
            },
            {
                "value": 23352,
                "label": "La Ferriere",
                "label_ar": "لا فيرير",
                "label_fr": "La Ferrière"
            },
            {
                "value": 23353,
                "label": "Combree",
                "label_ar": "كومبري",
                "label_fr": "Combree"
            },
            {
                "value": 23354,
                "label": "Maze",
                "label_ar": "متاهة",
                "label_fr": "Labyrinthe"
            },
            {
                "value": 23355,
                "label": "Bouguenais",
                "label_ar": "بوقنيس",
                "label_fr": "Bouguenais"
            },
            {
                "value": 23356,
                "label": "Port-Brillet",
                "label_ar": "بورت بريليه",
                "label_fr": "Port-Brillet"
            },
            {
                "value": 23357,
                "label": "Saint-Christophe-en-Boucherie",
                "label_ar": "سانت كريستوف أون بوشيري",
                "label_fr": "Saint-Christophe-en-Boucherie"
            },
            {
                "value": 23358,
                "label": "Yzernay",
                "label_ar": "يزيرناي",
                "label_fr": "Yzernay"
            },
            {
                "value": 23359,
                "label": "La Meilleraye-de-Bretagne",
                "label_ar": "La Meilleraye-de-Bretagne",
                "label_fr": "La Meilleraye-de-Bretagne"
            },
            {
                "value": 23360,
                "label": "Fontenay-le-Comte",
                "label_ar": "فونتيني لو كومت",
                "label_fr": "Fontenay-le-Comte"
            },
            {
                "value": 23361,
                "label": "La Plane",
                "label_ar": "لا بلان",
                "label_fr": "La Plane"
            },
            {
                "value": 23362,
                "label": "Treillieres",
                "label_ar": "Treillieres",
                "label_fr": "Treillières"
            },
            {
                "value": 23363,
                "label": "Saint-Saturnin-sur-Loire",
                "label_ar": "سان ساتورنين سور لوار",
                "label_fr": "Saint-Saturnin-sur-Loire"
            },
            {
                "value": 23364,
                "label": "Le Pouliguen",
                "label_ar": "لو بوليجوين",
                "label_fr": "Le Pouliguen"
            },
            {
                "value": 23365,
                "label": "Saumur",
                "label_ar": "سومور",
                "label_fr": "Saumur"
            },
            {
                "value": 23366,
                "label": "Le Perrier",
                "label_ar": "لو بيرييه",
                "label_fr": "Le Perrier"
            },
            {
                "value": 23367,
                "label": "Blain",
                "label_ar": "بلين",
                "label_fr": "Blain"
            },
            {
                "value": 23368,
                "label": "Bouchemaine",
                "label_ar": "بوشمين",
                "label_fr": "Bouchemaine"
            },
            {
                "value": 23369,
                "label": "Loire",
                "label_ar": "لوار",
                "label_fr": "Loire"
            },
            {
                "value": 23370,
                "label": "Le Lude",
                "label_ar": "لو لود",
                "label_fr": "Le Lude"
            },
            {
                "value": 23371,
                "label": "Mouzillon",
                "label_ar": "موزيلون",
                "label_fr": "Mouzillon"
            },
            {
                "value": 23372,
                "label": "Cande",
                "label_ar": "كاندي",
                "label_fr": "Cande"
            },
            {
                "value": 23373,
                "label": "La Faute-sur-Mer",
                "label_ar": "لا فاوت سور مير",
                "label_fr": "La Faute-sur-Mer"
            },
            {
                "value": 23374,
                "label": "Nort-sur-Erdre",
                "label_ar": "نورت سور إردري",
                "label_fr": "Nort-sur-Erdre"
            },
            {
                "value": 23375,
                "label": "Indre",
                "label_ar": "إندري",
                "label_fr": "Indre"
            },
            {
                "value": 23376,
                "label": "Vallet",
                "label_ar": "فاليت",
                "label_fr": "Vallet"
            },
            {
                "value": 23377,
                "label": "Carquefou",
                "label_ar": "كاركفو",
                "label_fr": "Carquefou"
            },
            {
                "value": 23378,
                "label": "Saint-Etienne-de-Montluc",
                "label_ar": "سانت إتيان دي مونتلوك",
                "label_fr": "Saint-Étienne-de-Montluc"
            },
            {
                "value": 23379,
                "label": "Rezé",
                "label_ar": "ريزي",
                "label_fr": "Rezé"
            },
            {
                "value": 23380,
                "label": "Pipriac",
                "label_ar": "بيبرياك",
                "label_fr": "Pipriac"
            },
            {
                "value": 23381,
                "label": "Le Loroux",
                "label_ar": "لو لوروكس",
                "label_fr": "Le Loroux"
            },
            {
                "value": 23382,
                "label": "Lege",
                "label_ar": "ليج",
                "label_fr": "Lege"
            },
            {
                "value": 23383,
                "label": "Quelaines-Saint-Gault",
                "label_ar": "كويلانس سانت غولت",
                "label_fr": "Quelaines-Saint-Gault"
            },
            {
                "value": 23384,
                "label": "Pontchateau",
                "label_ar": "بونتشاتو",
                "label_fr": "Pontchâteau"
            },
            {
                "value": 23385,
                "label": "Pouance",
                "label_ar": "بوانس",
                "label_fr": "Pouance"
            },
            {
                "value": 23386,
                "label": "Laval",
                "label_ar": "لافال",
                "label_fr": "Laval"
            },
            {
                "value": 23387,
                "label": "Saint-Pere-en-Retz",
                "label_ar": "سان بير أون ريتز",
                "label_fr": "Saint-Père-en-Retz"
            },
            {
                "value": 23388,
                "label": "Torce-en-Vallee",
                "label_ar": "Torce-en-Vallee",
                "label_fr": "Torce-en-Vallée"
            },
            {
                "value": 23389,
                "label": "Saint-Brevin-les-Pins",
                "label_ar": "سان بريفين ليه بين",
                "label_fr": "Saint-Brevin-les-Pins"
            },
            {
                "value": 23390,
                "label": "Grand",
                "label_ar": "كبير",
                "label_fr": "Grandiose"
            },
            {
                "value": 23391,
                "label": "Le Pin",
                "label_ar": "لو بين",
                "label_fr": "Le Pin"
            },
            {
                "value": 23392,
                "label": "Saint-Berthevin",
                "label_ar": "سانت بيرتيفين",
                "label_fr": "Saint-Berthevin"
            },
            {
                "value": 23393,
                "label": "Suce-sur-Erdre",
                "label_ar": "Suce-sur-Erdre",
                "label_fr": "Suce-sur-Erdre"
            },
            {
                "value": 23394,
                "label": "Argentre",
                "label_ar": "أرجينتري",
                "label_fr": "Argentre"
            },
            {
                "value": 23395,
                "label": "Saint-Macaire-en-Mauges",
                "label_ar": "سان ماكير أون موج",
                "label_fr": "Saint-Macaire-en-Mauges"
            },
            {
                "value": 23396,
                "label": "Chavagnes-les-Redoux",
                "label_ar": "شافاني ليس ريدوكس",
                "label_fr": "Chavagnes-les-Redoux"
            },
            {
                "value": 23397,
                "label": "Couesmes-Vauce",
                "label_ar": "Couesmes-Vauce",
                "label_fr": "Couesmes-Vauce"
            },
            {
                "value": 23398,
                "label": "Loiron",
                "label_ar": "لويرون",
                "label_fr": "Loiron"
            },
            {
                "value": 23399,
                "label": "Ruille-le-Gravelais",
                "label_ar": "رويل لو Gravelais",
                "label_fr": "Ruille-le-Gravelais"
            },
            {
                "value": 23400,
                "label": "La Chapelle-aux-Lys",
                "label_ar": "لا شابيل أو ليس",
                "label_fr": "La Chapelle-aux-Lys"
            },
            {
                "value": 23401,
                "label": "Saint-Fort",
                "label_ar": "سانت فورت",
                "label_fr": "Saint-Fort"
            },
            {
                "value": 23402,
                "label": "Chemaze",
                "label_ar": "شميز",
                "label_fr": "Chemaze"
            },
            {
                "value": 23403,
                "label": "Le Coudray",
                "label_ar": "لو كودراي",
                "label_fr": "Le Coudray"
            },
            {
                "value": 23404,
                "label": "Palluau",
                "label_ar": "Palluau",
                "label_fr": "Palluau"
            },
            {
                "value": 23405,
                "label": "Ecuille",
                "label_ar": "اكويل",
                "label_fr": "Ecuille"
            },
            {
                "value": 23406,
                "label": "Saint-Barthelemy-d'Anjou",
                "label_ar": "سان بارتيليمي دانجو",
                "label_fr": "Saint-Barthélemy-d'Anjou"
            },
            {
                "value": 23407,
                "label": "Challain-la-Potherie",
                "label_ar": "Challain-la-Potherie",
                "label_fr": "Challain-la-Potherie"
            },
            {
                "value": 23408,
                "label": "Bazoges-en-Paillers",
                "label_ar": "Bazoges-en-Paillers",
                "label_fr": "Bazoges-en-Paillers"
            },
            {
                "value": 23409,
                "label": "La Bruffiere",
                "label_ar": "لا Bruffiere",
                "label_fr": "La Bruffière"
            },
            {
                "value": 23410,
                "label": "Saligny",
                "label_ar": "ساليني",
                "label_fr": "Saligny"
            },
            {
                "value": 23411,
                "label": "Segre",
                "label_ar": "سيجري",
                "label_fr": "Segre"
            },
            {
                "value": 23412,
                "label": "Thorigne-d'Anjou",
                "label_ar": "Thorigne-d'Anjou",
                "label_fr": "Thorigne-d'Anjou"
            },
            {
                "value": 23413,
                "label": "Soulvache",
                "label_ar": "سولفاتشي",
                "label_fr": "Soulvache"
            },
            {
                "value": 23414,
                "label": "Grazay",
                "label_ar": "جرازاي",
                "label_fr": "Grazay"
            },
            {
                "value": 23415,
                "label": "Drain",
                "label_ar": "تصرف",
                "label_fr": "Drainer"
            },
            {
                "value": 23416,
                "label": "Saint-Lambert-la-Potherie",
                "label_ar": "سان لامبرت لا بوثيري",
                "label_fr": "Saint-Lambert-la-Potherie"
            },
            {
                "value": 23417,
                "label": "Herbignac",
                "label_ar": "هيربينياك",
                "label_fr": "Herbignac"
            },
            {
                "value": 23418,
                "label": "Beaufou",
                "label_ar": "بوفو",
                "label_fr": "Beaufou"
            },
            {
                "value": 23419,
                "label": "Saint-Hilaire-de-Talmont",
                "label_ar": "سان هيلير دي تالمونت",
                "label_fr": "Saint-Hilaire-de-Talmont"
            },
            {
                "value": 23420,
                "label": "Lamnay",
                "label_ar": "لامناي",
                "label_fr": "Lamnay"
            },
            {
                "value": 23421,
                "label": "Change",
                "label_ar": "يتغيرون",
                "label_fr": "Changement"
            },
            {
                "value": 23422,
                "label": "La Chartre-sur-le-Loir",
                "label_ar": "لا شارتر سور لو لوير",
                "label_fr": "La Chartre-sur-le-Loir"
            },
            {
                "value": 23423,
                "label": "Mouilleron-le-Captif",
                "label_ar": "Mouilleron-le-Captif",
                "label_fr": "Mouilleron-le-Captif"
            },
            {
                "value": 23424,
                "label": "Chateau-du-Loir",
                "label_ar": "شاتو دو لوير",
                "label_fr": "Château-du-Loir"
            },
            {
                "value": 23425,
                "label": "Sainte-Sabine-sur-Longeve",
                "label_ar": "سانت سابين سور لونجيف",
                "label_fr": "Sainte-Sabine-sur-Longeve"
            },
            {
                "value": 23426,
                "label": "Vincent",
                "label_ar": "فنسنت",
                "label_fr": "Vincent"
            },
            {
                "value": 23427,
                "label": "Dreffeac",
                "label_ar": "دريفياك",
                "label_fr": "Dreffeac"
            },
            {
                "value": 23428,
                "label": "Anjou",
                "label_ar": "أنجو",
                "label_fr": "Anjou"
            },
            {
                "value": 23429,
                "label": "Coex",
                "label_ar": "كوكس",
                "label_fr": "Coex"
            },
            {
                "value": 23430,
                "label": "Beaupreau",
                "label_ar": "بيوبرو",
                "label_fr": "Beaupreau"
            },
            {
                "value": 23431,
                "label": "Le Voide",
                "label_ar": "لو فويد",
                "label_fr": "Le Voide"
            },
            {
                "value": 23432,
                "label": "Parigne",
                "label_ar": "Parigne",
                "label_fr": "Parigné"
            },
            {
                "value": 23433,
                "label": "Melleray",
                "label_ar": "ميلراي",
                "label_fr": "Melleray"
            },
            {
                "value": 23434,
                "label": "Mamers",
                "label_ar": "ممرز",
                "label_fr": "Mamers"
            },
            {
                "value": 23435,
                "label": "Chateau-Gontier",
                "label_ar": "شاتو جونتييه",
                "label_fr": "Château-Gontier"
            },
            {
                "value": 23436,
                "label": "Saint-Corneille",
                "label_ar": "سانت كورنيل",
                "label_fr": "Saint-Corneille"
            },
            {
                "value": 23437,
                "label": "Grosbreuil",
                "label_ar": "جروسبريل",
                "label_fr": "Grosbreuil"
            },
            {
                "value": 23438,
                "label": "Beaufort-en-Vallee",
                "label_ar": "بوفورت أون فالي",
                "label_fr": "Beaufort-en-Vallée"
            },
            {
                "value": 23439,
                "label": "Trementines",
                "label_ar": "تريمينتين",
                "label_fr": "Trémentines"
            },
            {
                "value": 23440,
                "label": "Bouffere",
                "label_ar": "بوفير",
                "label_fr": "Bouffère"
            },
            {
                "value": 23441,
                "label": "Touvois",
                "label_ar": "توفوا",
                "label_fr": "Touvois"
            },
            {
                "value": 23442,
                "label": "Abbaretz",
                "label_ar": "Abbaretz",
                "label_fr": "Abbaretz"
            },
            {
                "value": 23443,
                "label": "La Roche",
                "label_ar": "لاروش",
                "label_fr": "La Roche"
            },
            {
                "value": 23444,
                "label": "Aubigny",
                "label_ar": "أوبيني",
                "label_fr": "Aubigny"
            },
            {
                "value": 23445,
                "label": "Yvre-l'Eveque",
                "label_ar": "إيفر-لافيك",
                "label_fr": "Yvre-l'Évêque"
            },
            {
                "value": 23446,
                "label": "Croix-de-Vie",
                "label_ar": "كروا دي في",
                "label_fr": "Croix-de-Vie"
            },
            {
                "value": 23447,
                "label": "Saint-Viaud",
                "label_ar": "سانت فيود",
                "label_fr": "Saint-Viaud"
            },
            {
                "value": 23448,
                "label": "Madre",
                "label_ar": "مادري",
                "label_fr": "Madre"
            },
            {
                "value": 23449,
                "label": "Pellouailles-les-Vignes",
                "label_ar": "Pellouailles-les-Vignes",
                "label_fr": "Pellouailles-les-Vignes"
            },
            {
                "value": 23450,
                "label": "Loue",
                "label_ar": "لوي",
                "label_fr": "Loue"
            },
            {
                "value": 23451,
                "label": "Cheffes",
                "label_ar": "الطهاة",
                "label_fr": "Cheffes"
            },
            {
                "value": 23452,
                "label": "Tierce",
                "label_ar": "تيرس",
                "label_fr": "Tierce"
            },
            {
                "value": 23453,
                "label": "Brulon",
                "label_ar": "برولون",
                "label_fr": "Brulon"
            },
            {
                "value": 23454,
                "label": "La Suze-sur-Sarthe",
                "label_ar": "لا سوز سور سارث",
                "label_fr": "La Suze-sur-Sarthe"
            },
            {
                "value": 23455,
                "label": "Joue-l'Abbe",
                "label_ar": "جو لاب",
                "label_fr": "Joue-l'Abbe"
            },
            {
                "value": 23456,
                "label": "Les Lucs-sur-Boulogne",
                "label_ar": "ليس لوك سور بولوني",
                "label_fr": "Les Lucs-sur-Boulogne"
            },
            {
                "value": 23457,
                "label": "Toutlemonde",
                "label_ar": "توتيلموند",
                "label_fr": "Toutlemonde"
            },
            {
                "value": 23458,
                "label": "Saint-Andre-Treize-Voies",
                "label_ar": "سان أندريه تريز فوا",
                "label_fr": "Saint-André-Treize-Voies"
            },
            {
                "value": 23459,
                "label": "Chateau-d'Olonne",
                "label_ar": "شاتو دولون",
                "label_fr": "Château-d'Olonne"
            },
            {
                "value": 23460,
                "label": "Saint-Julien-de-Vouvantes",
                "label_ar": "سان جوليان دي فوفانتس",
                "label_fr": "Saint-Julien-de-Vouvantes"
            },
            {
                "value": 23461,
                "label": "La Baconniere",
                "label_ar": "لا باكونير",
                "label_fr": "La Baconnière"
            },
            {
                "value": 23462,
                "label": "Lire",
                "label_ar": "لير",
                "label_fr": "Lire"
            },
            {
                "value": 23463,
                "label": "Villaines-sous-Luce",
                "label_ar": "Villaines-sous-Luce",
                "label_fr": "Villaines-sous-Luce"
            },
            {
                "value": 23464,
                "label": "Cugand",
                "label_ar": "كوجاند",
                "label_fr": "Cugand"
            },
            {
                "value": 23465,
                "label": "Saint-Jean-de-Monts",
                "label_ar": "سان جان دي مونت",
                "label_fr": "Saint-Jean-de-Monts"
            },
            {
                "value": 23466,
                "label": "Le Longeron",
                "label_ar": "لو لونجيرون",
                "label_fr": "Le Longeron"
            },
            {
                "value": 23467,
                "label": "Malville",
                "label_ar": "مالفيل",
                "label_fr": "Malville"
            },
            {
                "value": 23468,
                "label": "Nozay",
                "label_ar": "نوزاي",
                "label_fr": "Nozay"
            },
            {
                "value": 23469,
                "label": "Chantonnay",
                "label_ar": "شانتوناي",
                "label_fr": "Chantonnay"
            },
            {
                "value": 23470,
                "label": "Derval",
                "label_ar": "ديرفال",
                "label_fr": "Derval"
            },
            {
                "value": 23471,
                "label": "Guenrouet",
                "label_ar": "Guenrouet",
                "label_fr": "Guenrouet"
            },
            {
                "value": 23472,
                "label": "Ancenis",
                "label_ar": "Ancenis",
                "label_fr": "Ancenis"
            },
            {
                "value": 23473,
                "label": "Sautron",
                "label_ar": "سوترون",
                "label_fr": "Sautron"
            },
            {
                "value": 23474,
                "label": "La Chaize-le-Vicomte",
                "label_ar": "لا تشيز لو فيكومت",
                "label_fr": "La Chaize-le-Vicomte"
            },
            {
                "value": 23475,
                "label": "La Vraie-Croix",
                "label_ar": "لا فراي كروا",
                "label_fr": "La Vraie-Croix"
            },
            {
                "value": 23476,
                "label": "Mellac",
                "label_ar": "ميلاك",
                "label_fr": "Mellac"
            },
            {
                "value": 23477,
                "label": "Rennes",
                "label_ar": "رين",
                "label_fr": "Rennes"
            },
            {
                "value": 23478,
                "label": "Plomelin",
                "label_ar": "بلوميلين",
                "label_fr": "Plomelin"
            },
            {
                "value": 23479,
                "label": "Trigavou",
                "label_ar": "تريغافو",
                "label_fr": "Trigavou"
            },
            {
                "value": 23480,
                "label": "Lorient",
                "label_ar": "لوريان",
                "label_fr": "Lorient"
            },
            {
                "value": 23481,
                "label": "Saint-Gregoire",
                "label_ar": "سان جريجوار",
                "label_fr": "Saint-Grégoire"
            },
            {
                "value": 23482,
                "label": "Argol",
                "label_ar": "أرغول",
                "label_fr": "Argol"
            },
            {
                "value": 23483,
                "label": "Saint-Brieuc",
                "label_ar": "سانت بريوك",
                "label_fr": "Saint-Brieuc"
            },
            {
                "value": 23484,
                "label": "Pontivy",
                "label_ar": "بونتيفي",
                "label_fr": "Pontivy"
            },
            {
                "value": 23485,
                "label": "La Meaugon",
                "label_ar": "لا ميوجون",
                "label_fr": "La Meaugon"
            },
            {
                "value": 23486,
                "label": "Merlevenez",
                "label_ar": "ميرليفينيز",
                "label_fr": "Merlevenez"
            },
            {
                "value": 23487,
                "label": "Landevant",
                "label_ar": "Landevant",
                "label_fr": "Landevant"
            },
            {
                "value": 23488,
                "label": "Plaine-de-Walsch",
                "label_ar": "بلين دي والش",
                "label_fr": "Plaine-de-Walsch"
            },
            {
                "value": 23489,
                "label": "Mezieres-sur-Couesnon",
                "label_ar": "Mezieres-sur-Couesnon",
                "label_fr": "Mézières-sur-Couesnon"
            },
            {
                "value": 23490,
                "label": "Carnac",
                "label_ar": "كارناك",
                "label_fr": "Carnac"
            },
            {
                "value": 23491,
                "label": "Broons",
                "label_ar": "برونات",
                "label_fr": "Broons"
            },
            {
                "value": 23492,
                "label": "Carhaix-Plouguer",
                "label_ar": "Carhaix-Plouguer",
                "label_fr": "Carhaix-Plouguer"
            },
            {
                "value": 23493,
                "label": "Concarneau",
                "label_ar": "كونكارنو",
                "label_fr": "Concarneau"
            },
            {
                "value": 23494,
                "label": "Plougastel-Daoulas",
                "label_ar": "Plougastel-Daoulas",
                "label_fr": "Plougastel-Daoulas"
            },
            {
                "value": 23495,
                "label": "Loudeac",
                "label_ar": "بصوت عال",
                "label_fr": "Loudeac"
            },
            {
                "value": 23496,
                "label": "Brest",
                "label_ar": "بريست",
                "label_fr": "Brest"
            },
            {
                "value": 23497,
                "label": "Logonna-Quimerch",
                "label_ar": "لوغونا كيميرش",
                "label_fr": "Logonna-Quimerch"
            },
            {
                "value": 23498,
                "label": "Plouzane",
                "label_ar": "بلوزان",
                "label_fr": "Plouzane"
            },
            {
                "value": 23499,
                "label": "Credin",
                "label_ar": "كريدين",
                "label_fr": "Credin"
            },
            {
                "value": 23500,
                "label": "Irodouer",
                "label_ar": "إيرودوير",
                "label_fr": "Irodouer"
            },
            {
                "value": 23501,
                "label": "Nivillac",
                "label_ar": "نيفيلاك",
                "label_fr": "Nivillac"
            },
            {
                "value": 23502,
                "label": "La Trinité-sur-Mer",
                "label_ar": "لا ترينيتي سور مير",
                "label_fr": "La Trinité-sur-Mer"
            },
            {
                "value": 23503,
                "label": "Breal-sous-Montfort",
                "label_ar": "بريل سو مونتفورت",
                "label_fr": "Breal-sous-Montfort"
            },
            {
                "value": 23504,
                "label": "Cleguerec",
                "label_ar": "كليجوريك",
                "label_fr": "Cleguerec"
            },
            {
                "value": 23505,
                "label": "Melesse",
                "label_ar": "ميليس",
                "label_fr": "Melesse"
            },
            {
                "value": 23506,
                "label": "Brech",
                "label_ar": "بريش",
                "label_fr": "Brech"
            },
            {
                "value": 23507,
                "label": "Gouesnach",
                "label_ar": "Gouesnach",
                "label_fr": "Gouesnach"
            },
            {
                "value": 23508,
                "label": "Boqueho",
                "label_ar": "بوكيهو",
                "label_fr": "Boqueho"
            },
            {
                "value": 23509,
                "label": "Penguily",
                "label_ar": "البطريق",
                "label_fr": "Penguily"
            },
            {
                "value": 23510,
                "label": "Le Palais",
                "label_ar": "لو باليه",
                "label_fr": "Le Palais"
            },
            {
                "value": 23511,
                "label": "Lanvaudan",
                "label_ar": "لانفودان",
                "label_fr": "Lanvaudan"
            },
            {
                "value": 23512,
                "label": "Montgermont",
                "label_ar": "مونتجيرمونت",
                "label_fr": "Montgermont"
            },
            {
                "value": 23513,
                "label": "Pouldreuzic",
                "label_ar": "بولدرزيتش",
                "label_fr": "Pouldreuzic"
            },
            {
                "value": 23514,
                "label": "Bulat-Pestivien",
                "label_ar": "بولات بيستيفين",
                "label_fr": "Bulat-Pestivien"
            },
            {
                "value": 23515,
                "label": "Auray",
                "label_ar": "أوراي",
                "label_fr": "Auray"
            },
            {
                "value": 23516,
                "label": "Guidel",
                "label_ar": "جويدل",
                "label_fr": "Guidel"
            },
            {
                "value": 23517,
                "label": "Crevin",
                "label_ar": "كريفين",
                "label_fr": "Crevin"
            },
            {
                "value": 23518,
                "label": "Ploubezre",
                "label_ar": "بلوبزر",
                "label_fr": "Ploubezre"
            },
            {
                "value": 23519,
                "label": "Orgeres",
                "label_ar": "Orgeres",
                "label_fr": "Orgeres"
            },
            {
                "value": 23520,
                "label": "Brehand",
                "label_ar": "بريهاند",
                "label_fr": "Brehand"
            },
            {
                "value": 23521,
                "label": "Plouay",
                "label_ar": "بلوي",
                "label_fr": "Plouay"
            },
            {
                "value": 23522,
                "label": "Sene",
                "label_ar": "سيني",
                "label_fr": "Sene"
            },
            {
                "value": 23523,
                "label": "Plemet",
                "label_ar": "بليميت",
                "label_fr": "Plemet"
            },
            {
                "value": 23524,
                "label": "Evran",
                "label_ar": "إيفران",
                "label_fr": "Evran"
            },
            {
                "value": 23525,
                "label": "Dinard",
                "label_ar": "دينار",
                "label_fr": "Dinard"
            },
            {
                "value": 23526,
                "label": "La Meziere",
                "label_ar": "لا ميزير",
                "label_fr": "La Mézière"
            },
            {
                "value": 23527,
                "label": "Vannes",
                "label_ar": "فانيس",
                "label_fr": "Vannes"
            },
            {
                "value": 23528,
                "label": "Langoelan",
                "label_ar": "لانجويلان",
                "label_fr": "Langoelan"
            },
            {
                "value": 23529,
                "label": "Pleurtuit",
                "label_ar": "بلورتويت",
                "label_fr": "Pleurtuit"
            },
            {
                "value": 23530,
                "label": "Quimper",
                "label_ar": "كيمبر",
                "label_fr": "Quimper"
            },
            {
                "value": 23531,
                "label": "Pledran",
                "label_ar": "بليدران",
                "label_fr": "Pledran"
            },
            {
                "value": 23532,
                "label": "Chateaugiron",
                "label_ar": "شاتوجيرون",
                "label_fr": "Châteaugiron"
            },
            {
                "value": 23533,
                "label": "Saint-Grave",
                "label_ar": "سان جراف",
                "label_fr": "Saint-Grave"
            },
            {
                "value": 23534,
                "label": "Quebriac",
                "label_ar": "كيبرياك",
                "label_fr": "Quebriac"
            },
            {
                "value": 23535,
                "label": "Theix",
                "label_ar": "Theix",
                "label_fr": "Theix"
            },
            {
                "value": 23536,
                "label": "Vitré",
                "label_ar": "فيتريه",
                "label_fr": "Vitré"
            },
            {
                "value": 23537,
                "label": "Saint-Jouan-des-Guerets",
                "label_ar": "سان جوان دي جيريت",
                "label_fr": "Saint-Jouan-des-Guérets"
            },
            {
                "value": 23538,
                "label": "Chantepie",
                "label_ar": "Chantepie",
                "label_fr": "Chantepie"
            },
            {
                "value": 23539,
                "label": "Vern-sur-Seiche",
                "label_ar": "فيرن سور سيش",
                "label_fr": "Vern-sur-Seiche"
            },
            {
                "value": 23540,
                "label": "Ploeuc-sur-Lie",
                "label_ar": "بلوك سور لي",
                "label_fr": "Ploeuc-sur-Lie"
            },
            {
                "value": 23541,
                "label": "Bain-de-Bretagne",
                "label_ar": "بين دي بريتاني",
                "label_fr": "Bain-de-Bretagne"
            },
            {
                "value": 23542,
                "label": "Pluneret",
                "label_ar": "بلونيريت",
                "label_fr": "Pluneret"
            },
            {
                "value": 23543,
                "label": "Breteil",
                "label_ar": "بريتيل",
                "label_fr": "Breteil"
            },
            {
                "value": 23544,
                "label": "Aubigne",
                "label_ar": "أوبيني",
                "label_fr": "Aubigné"
            },
            {
                "value": 23545,
                "label": "Quedillac",
                "label_ar": "كويديلاك",
                "label_fr": "Quedillac"
            },
            {
                "value": 23546,
                "label": "Hopital-Camfrout",
                "label_ar": "مستشفى كامفروت",
                "label_fr": "Hôpital-Camfrout"
            },
            {
                "value": 23547,
                "label": "Languidic",
                "label_ar": "لانجيديك",
                "label_fr": "Languidique"
            },
            {
                "value": 23548,
                "label": "Kerfourn",
                "label_ar": "كيرفورن",
                "label_fr": "Kerfourn"
            },
            {
                "value": 23549,
                "label": "Reminiac",
                "label_ar": "ريمينياك",
                "label_fr": "Reminiac"
            },
            {
                "value": 23550,
                "label": "Lannion",
                "label_ar": "لانيون",
                "label_fr": "Lannion"
            },
            {
                "value": 23551,
                "label": "Lampaul-Plouarzel",
                "label_ar": "لامبول بلوارزل",
                "label_fr": "Lampaul-Plouarzel"
            },
            {
                "value": 23552,
                "label": "Penmarch",
                "label_ar": "بنمارش",
                "label_fr": "Penmarch"
            },
            {
                "value": 23553,
                "label": "Le Bono",
                "label_ar": "لو بونو",
                "label_fr": "Le Bono"
            },
            {
                "value": 23554,
                "label": "Bangor",
                "label_ar": "بانجور",
                "label_fr": "Bangor"
            },
            {
                "value": 23555,
                "label": "Port-Launay",
                "label_ar": "بورت لوني",
                "label_fr": "Port-Launay"
            },
            {
                "value": 23556,
                "label": "Ploermel",
                "label_ar": "بلويرميل",
                "label_fr": "Ploermel"
            },
            {
                "value": 23557,
                "label": "Glomel",
                "label_ar": "جلوميل",
                "label_fr": "Glomel"
            },
            {
                "value": 23558,
                "label": "Landebia",
                "label_ar": "لانديبيا",
                "label_fr": "Landebia"
            },
            {
                "value": 23559,
                "label": "Gestel",
                "label_ar": "جيستل",
                "label_fr": "Gestel"
            },
            {
                "value": 23560,
                "label": "Quiberon",
                "label_ar": "كويبيرون",
                "label_fr": "Quiberon"
            },
            {
                "value": 23561,
                "label": "Queven",
                "label_ar": "كوين",
                "label_fr": "Queven"
            },
            {
                "value": 23562,
                "label": "Douarnenez",
                "label_ar": "دورنينيز",
                "label_fr": "Douarnenez"
            },
            {
                "value": 23563,
                "label": "Saint-Aubin-d'Aubigne",
                "label_ar": "سان أوبين دأوبيني",
                "label_fr": "Saint-Aubin-d'Aubigné"
            },
            {
                "value": 23564,
                "label": "Chartres-de-Bretagne",
                "label_ar": "شارتر دي بريتاني",
                "label_fr": "Chartres-de-Bretagne"
            },
            {
                "value": 23565,
                "label": "Le Rheu",
                "label_ar": "لو ريو",
                "label_fr": "Le Rheu"
            },
            {
                "value": 23566,
                "label": "Miniac-Morvan",
                "label_ar": "مينياك مورفان",
                "label_fr": "Miniac-Morvan"
            },
            {
                "value": 23567,
                "label": "Tréguier",
                "label_ar": "تريجير",
                "label_fr": "Tréguier"
            },
            {
                "value": 23568,
                "label": "Bannalec",
                "label_ar": "باناليك",
                "label_fr": "Bannalec"
            },
            {
                "value": 23569,
                "label": "Carantec",
                "label_ar": "كارانتك",
                "label_fr": "Carantec"
            },
            {
                "value": 23570,
                "label": "Plouescat",
                "label_ar": "بلوسكات",
                "label_fr": "Plouescat"
            },
            {
                "value": 23571,
                "label": "Dinan",
                "label_ar": "دينان",
                "label_fr": "Dinan"
            },
            {
                "value": 23572,
                "label": "Cormainville",
                "label_ar": "كورمينفيل",
                "label_fr": "Cormainville"
            },
            {
                "value": 23573,
                "label": "Guemene-sur-Scorff",
                "label_ar": "Guemene-sur-Scorff",
                "label_fr": "Guémène-sur-Scorff"
            },
            {
                "value": 23574,
                "label": "Pluvigner",
                "label_ar": "بلوفينر",
                "label_fr": "Pluvigner"
            },
            {
                "value": 23575,
                "label": "Saint-Nolff",
                "label_ar": "سان نولف",
                "label_fr": "Saint-Nolff"
            },
            {
                "value": 23576,
                "label": "Pace",
                "label_ar": "سرعة",
                "label_fr": "Rythme"
            },
            {
                "value": 23577,
                "label": "Guichen",
                "label_ar": "Guichen",
                "label_fr": "Guichen"
            },
            {
                "value": 23578,
                "label": "Beganne",
                "label_ar": "بدأت",
                "label_fr": "A commencé"
            },
            {
                "value": 23579,
                "label": "Molac",
                "label_ar": "مولاك",
                "label_fr": "Molac"
            },
            {
                "value": 23580,
                "label": "Saint-M'Herve",
                "label_ar": "سان مهيرف",
                "label_fr": "Saint-M'Herve"
            },
            {
                "value": 23581,
                "label": "Saint-Dolay",
                "label_ar": "سان دولاي",
                "label_fr": "Saint-Dolay"
            },
            {
                "value": 23582,
                "label": "Dinge",
                "label_ar": "الزنجر",
                "label_fr": "Dinge"
            },
            {
                "value": 23583,
                "label": "Camoel",
                "label_ar": "كامويل",
                "label_fr": "Camoel"
            },
            {
                "value": 23584,
                "label": "Chaumont",
                "label_ar": "شومون",
                "label_fr": "Chaumont"
            },
            {
                "value": 23585,
                "label": "Lyon",
                "label_ar": "ليون",
                "label_fr": "Lyon"
            },
            {
                "value": 23586,
                "label": "Saint-Maurice-de-Remens",
                "label_ar": "سان موريس دي ريمنز",
                "label_fr": "Saint-Maurice-de-Remens"
            },
            {
                "value": 23587,
                "label": "La Chapelle-en-Vercors",
                "label_ar": "لا شابيل أون فيركورز",
                "label_fr": "La Chapelle-en-Vercors"
            },
            {
                "value": 23588,
                "label": "Bourgoin",
                "label_ar": "بورجوين",
                "label_fr": "Bourgoin"
            },
            {
                "value": 23589,
                "label": "Sainte-Foy-les-Lyon",
                "label_ar": "سانت فوي ليس ليون",
                "label_fr": "Sainte-Foy-les-Lyon"
            },
            {
                "value": 23590,
                "label": "Chabeuil",
                "label_ar": "شابويل",
                "label_fr": "Chabeuil"
            },
            {
                "value": 23591,
                "label": "Villeurbanne",
                "label_ar": "فيلوربان",
                "label_fr": "Villeurbanne"
            },
            {
                "value": 23592,
                "label": "Bourg-Argental",
                "label_ar": "بورج أرجينتال",
                "label_fr": "Bourg-Argental"
            },
            {
                "value": 23593,
                "label": "Roussillon",
                "label_ar": "روسيون",
                "label_fr": "Roussillon"
            },
            {
                "value": 23594,
                "label": "Saint-Etienne",
                "label_ar": "سانت إتيان",
                "label_fr": "Saint-Etienne"
            },
            {
                "value": 23595,
                "label": "La Cote",
                "label_ar": "لا كوت",
                "label_fr": "La Cote"
            },
            {
                "value": 23596,
                "label": "Izernore",
                "label_ar": "إيزرنور",
                "label_fr": "Izernore"
            },
            {
                "value": 23597,
                "label": "Caluire-et-Cuire",
                "label_ar": "Caluire-et-Cuire",
                "label_fr": "Caluire-et-Cuire"
            },
            {
                "value": 23598,
                "label": "Tournon-sur-Rhône",
                "label_ar": "تورنون سور رون",
                "label_fr": "Tournon-sur-Rhône"
            },
            {
                "value": 23599,
                "label": "Saint-Vallier",
                "label_ar": "سانت فالييه",
                "label_fr": "Saint-Vallier"
            },
            {
                "value": 23600,
                "label": "Bourg-en-Bresse",
                "label_ar": "بورغ أون بريس",
                "label_fr": "Bourg-en-Bresse"
            },
            {
                "value": 23601,
                "label": "Chazelles-sur-Lyon",
                "label_ar": "شازيل سور ليون",
                "label_fr": "Chazelles-sur-Lyon"
            },
            {
                "value": 23602,
                "label": "Salagnon",
                "label_ar": "سالجنون",
                "label_fr": "Salagnon"
            },
            {
                "value": 23603,
                "label": "Saint-Chamond",
                "label_ar": "سان شاموند",
                "label_fr": "Saint-Chamond"
            },
            {
                "value": 23604,
                "label": "Trevoux",
                "label_ar": "تريفوكس",
                "label_fr": "Trévoux"
            },
            {
                "value": 23605,
                "label": "Chanas",
                "label_ar": "Chanas",
                "label_fr": "Chanas"
            },
            {
                "value": 23606,
                "label": "Dieulefit",
                "label_ar": "ديوليفيت",
                "label_fr": "Dieulefit"
            },
            {
                "value": 23607,
                "label": "Saint-Chef",
                "label_ar": "سان شيف",
                "label_fr": "Saint-Chef"
            },
            {
                "value": 23608,
                "label": "Andrezieux-Boutheon",
                "label_ar": "أندريزيو بوثيون",
                "label_fr": "Andrezieux-Boutheon"
            },
            {
                "value": 23609,
                "label": "Le Coteau",
                "label_ar": "لو كوتو",
                "label_fr": "Le Coteau"
            },
            {
                "value": 23610,
                "label": "Loire-sur-Rhone",
                "label_ar": "لوار سور رون",
                "label_fr": "Loire-sur-Rhône"
            },
            {
                "value": 23611,
                "label": "Saint-Etienne-sur-Chalaronne",
                "label_ar": "سانت إتيان سور شالارون",
                "label_fr": "Saint-Étienne-sur-Chalaronne"
            },
            {
                "value": 23612,
                "label": "Vernioz",
                "label_ar": "فيرنيوز",
                "label_fr": "Vernioz"
            },
            {
                "value": 23613,
                "label": "Vaulx-en-Velin",
                "label_ar": "فولكس أون فيلين",
                "label_fr": "Vaulx-en-Velin"
            },
            {
                "value": 23614,
                "label": "Chatillon-la-Palud",
                "label_ar": "شاتيلون لا بالود",
                "label_fr": "Châtillon-la-Palud"
            },
            {
                "value": 23615,
                "label": "Saint-Etienne-de-Valoux",
                "label_ar": "سانت إتيان دي فالوكس",
                "label_fr": "Saint-Étienne-de-Valoux"
            },
            {
                "value": 23616,
                "label": "Saint-Sernin",
                "label_ar": "سانت سيرنين",
                "label_fr": "Saint-Sernin"
            },
            {
                "value": 23617,
                "label": "Davezieux",
                "label_ar": "ديفزيوكس",
                "label_fr": "Davezieux"
            },
            {
                "value": 23618,
                "label": "Samognat",
                "label_ar": "ساموجنات",
                "label_fr": "Samognat"
            },
            {
                "value": 23619,
                "label": "Bron",
                "label_ar": "برون",
                "label_fr": "Bron"
            },
            {
                "value": 23620,
                "label": "Rillieux-la-Pape",
                "label_ar": "ريليو لا باب",
                "label_fr": "Rillieux-la-Pape"
            },
            {
                "value": 23621,
                "label": "Limonest",
                "label_ar": "ليمون",
                "label_fr": "Limonest"
            },
            {
                "value": 23622,
                "label": "Decines-Charpieu",
                "label_ar": "ديسينس شاربيو",
                "label_fr": "Décines-Charpieu"
            },
            {
                "value": 23623,
                "label": "La Talaudiere",
                "label_ar": "لا تالوديري",
                "label_fr": "La Talaudière"
            },
            {
                "value": 23624,
                "label": "Saint-Priest-la-Vetre",
                "label_ar": "سانت بريست لا فيتري",
                "label_fr": "Saint-Priest-la-Vetre"
            },
            {
                "value": 23625,
                "label": "Four",
                "label_ar": "أربعة",
                "label_fr": "Quatre"
            },
            {
                "value": 23626,
                "label": "Rompon",
                "label_ar": "رومبون",
                "label_fr": "Rompon"
            },
            {
                "value": 23627,
                "label": "Ville-sur-Jarnioux",
                "label_ar": "فيل سور جارنيوكس",
                "label_fr": "Ville-sur-Jarnioux"
            },
            {
                "value": 23628,
                "label": "Vernosc-les-Annonay",
                "label_ar": "Vernosc-les-Annonay",
                "label_fr": "Vernosc-les-Annonay"
            },
            {
                "value": 23629,
                "label": "Dardilly",
                "label_ar": "دارديلي",
                "label_fr": "Dardilly"
            },
            {
                "value": 23630,
                "label": "Fleurieux-sur-l'Arbresle",
                "label_ar": "فلوريوكس سور لاربرسل",
                "label_fr": "Fleurieux-sur-l'Arbresle"
            },
            {
                "value": 23631,
                "label": "Saint-Didier-de-la-Tour",
                "label_ar": "سان ديدييه دي لا تور",
                "label_fr": "Saint-Didier-de-la-Tour"
            },
            {
                "value": 23632,
                "label": "Pont-Eveque",
                "label_ar": "بونت إيفيك",
                "label_fr": "Pont-Évêque"
            },
            {
                "value": 23633,
                "label": "Bourg-de-Thizy",
                "label_ar": "بورج دي تيزي",
                "label_fr": "Bourg-de-Thizy"
            },
            {
                "value": 23634,
                "label": "Montboucher-sur-Jabron",
                "label_ar": "مونبوشر سور جابرون",
                "label_fr": "Montboucher-sur-Jabron"
            },
            {
                "value": 23635,
                "label": "Lhuis",
                "label_ar": "لويس",
                "label_fr": "Lhuis"
            },
            {
                "value": 23636,
                "label": "Brignais",
                "label_ar": "Brignais",
                "label_fr": "Brignais"
            },
            {
                "value": 23637,
                "label": "Montbrison",
                "label_ar": "مونبريسون",
                "label_fr": "Montbrison"
            },
            {
                "value": 23638,
                "label": "Saint-Cyprien",
                "label_ar": "سان سيبريان",
                "label_fr": "Saint-Cyprien"
            },
            {
                "value": 23639,
                "label": "Lupe",
                "label_ar": "لوب",
                "label_fr": "Lupe"
            },
            {
                "value": 23640,
                "label": "Blyes",
                "label_ar": "بليس",
                "label_fr": "Blyes"
            },
            {
                "value": 23641,
                "label": "Montrond-les-Bains",
                "label_ar": "Montrond-les-Bains",
                "label_fr": "Montrond-les-Bains"
            },
            {
                "value": 23642,
                "label": "Meyzieu",
                "label_ar": "ميزيو",
                "label_fr": "Meyzieu"
            },
            {
                "value": 23643,
                "label": "Saint-Forgeux",
                "label_ar": "سان فورجيو",
                "label_fr": "Saint-Forgeux"
            },
            {
                "value": 23644,
                "label": "Curtafond",
                "label_ar": "كورتافوند",
                "label_fr": "Curtafond"
            },
            {
                "value": 23645,
                "label": "Vénissieux",
                "label_ar": "فينيسيو",
                "label_fr": "Vénissieux"
            },
            {
                "value": 23646,
                "label": "Montélimar",
                "label_ar": "مونتيليمار",
                "label_fr": "Montélimar"
            },
            {
                "value": 23647,
                "label": "Ornacieux",
                "label_ar": "أورناسيو",
                "label_fr": "Ornacieux"
            },
            {
                "value": 23648,
                "label": "La Tour",
                "label_ar": "لا تور",
                "label_fr": "La Tour"
            },
            {
                "value": 23649,
                "label": "Janneyrias",
                "label_ar": "جانيرياس",
                "label_fr": "Janneyrias"
            },
            {
                "value": 23650,
                "label": "Bonson",
                "label_ar": "بونسون",
                "label_fr": "Bonson"
            },
            {
                "value": 23651,
                "label": "Sorbiers",
                "label_ar": "المصورون",
                "label_fr": "Sorbiers"
            },
            {
                "value": 23652,
                "label": "Abergement-Saint-Jean",
                "label_ar": "Abergement-Saint-Jean",
                "label_fr": "Abergement-Saint-Jean"
            },
            {
                "value": 23653,
                "label": "Saint-Fons",
                "label_ar": "سان فونس",
                "label_fr": "Saint-Fons"
            },
            {
                "value": 23654,
                "label": "Saint-Maurice-l'Exil",
                "label_ar": "Saint-Maurice-l'Exil",
                "label_fr": "Saint-Maurice-l'Exil"
            },
            {
                "value": 23655,
                "label": "La Laupie",
                "label_ar": "لا لوبي",
                "label_fr": "La Laupie"
            },
            {
                "value": 23656,
                "label": "Corbas",
                "label_ar": "كورباس",
                "label_fr": "Corbas"
            },
            {
                "value": 23657,
                "label": "Neuville-sur-Saone",
                "label_ar": "نيوفيل سور ساون",
                "label_fr": "Neuville-sur-Saône"
            },
            {
                "value": 23658,
                "label": "Saint-Donat-sur-l'Herbasse",
                "label_ar": "Saint-Donat-sur-l'Herbasse",
                "label_fr": "Saint-Donat-sur-l'Herbasse"
            },
            {
                "value": 23659,
                "label": "Fontaines-sur-Saone",
                "label_ar": "فونتين سور ساون",
                "label_fr": "Fontaines-sur-Saône"
            },
            {
                "value": 23660,
                "label": "Pont-Salomon",
                "label_ar": "بونت سالومون",
                "label_fr": "Pont-Salomon"
            },
            {
                "value": 23661,
                "label": "Frontonas",
                "label_ar": "فرونتوناس",
                "label_fr": "Frontonas"
            },
            {
                "value": 23662,
                "label": "Beauchastel",
                "label_ar": "بوشاستل",
                "label_fr": "Beauchastel"
            },
            {
                "value": 23663,
                "label": "Saint-Clair-du-Rhone",
                "label_ar": "سان كلير دو رون",
                "label_fr": "Saint-Clair-du-Rhône"
            },
            {
                "value": 23664,
                "label": "Montagnieu",
                "label_ar": "مونتاجنو",
                "label_fr": "Montagnieu"
            },
            {
                "value": 23665,
                "label": "Annonay",
                "label_ar": "أنوناي",
                "label_fr": "Annonay"
            },
            {
                "value": 23666,
                "label": "Marcollin",
                "label_ar": "ماركولين",
                "label_fr": "Marcollin"
            },
            {
                "value": 23667,
                "label": "Viviers",
                "label_ar": "حيوات",
                "label_fr": "Viviers"
            },
            {
                "value": 23668,
                "label": "Savasse",
                "label_ar": "سافاس",
                "label_fr": "Savasse"
            },
            {
                "value": 23669,
                "label": "Saint-Symphorien-de-Lay",
                "label_ar": "سان سيمفوريان دي لاي",
                "label_fr": "Saint-Symphorien-de-Lay"
            },
            {
                "value": 23670,
                "label": "Tassin-la-Demi-Lune",
                "label_ar": "تاسين لا ديمي لون",
                "label_fr": "Tassin-la-Demi-Lune"
            },
            {
                "value": 23671,
                "label": "Le Poet-Laval",
                "label_ar": "لو بويت لافال",
                "label_fr": "Le Poète-Laval"
            },
            {
                "value": 23672,
                "label": "L'Horme",
                "label_ar": "لهرم",
                "label_fr": "L'Horme"
            },
            {
                "value": 23673,
                "label": "Oullins",
                "label_ar": "أولينز",
                "label_fr": "Oullins"
            },
            {
                "value": 23674,
                "label": "Aubenas",
                "label_ar": "أوبيناس",
                "label_fr": "Aubenas"
            },
            {
                "value": 23675,
                "label": "Genay",
                "label_ar": "جيناي",
                "label_fr": "Genay"
            },
            {
                "value": 23676,
                "label": "Auriol",
                "label_ar": "أوريول",
                "label_fr": "Auriol"
            },
            {
                "value": 23677,
                "label": "Saint-Cannat",
                "label_ar": "سان كانات",
                "label_fr": "Saint-Cannat"
            },
            {
                "value": 23678,
                "label": "Saint-Martin-de-la-Brasque",
                "label_ar": "سان مارتن دي لا براسك",
                "label_fr": "Saint-Martin-de-la-Brasque"
            },
            {
                "value": 23679,
                "label": "Marseille",
                "label_ar": "مرسيليا",
                "label_fr": "Marseille"
            },
            {
                "value": 23680,
                "label": "Beaumont-de-Pertuis",
                "label_ar": "بومونت دي بيرتويز",
                "label_fr": "Beaumont-de-Pertuis"
            },
            {
                "value": 23681,
                "label": "Loriol-du-Comtat",
                "label_ar": "لوريول دو كومتات",
                "label_fr": "Loriol-du-Comtat"
            },
            {
                "value": 23682,
                "label": "Aix-en-Provence",
                "label_ar": "إيكس أون بروفانس",
                "label_fr": "Aix-en-Provence"
            },
            {
                "value": 23683,
                "label": "Eguilles",
                "label_ar": "ايجيل",
                "label_fr": "Eguilles"
            },
            {
                "value": 23684,
                "label": "Barcelonnette",
                "label_ar": "برشلونيت",
                "label_fr": "Barcelonnette"
            },
            {
                "value": 23685,
                "label": "Manosque",
                "label_ar": "مانوسك",
                "label_fr": "Manosque"
            },
            {
                "value": 23686,
                "label": "Ventabren",
                "label_ar": "فينتابرين",
                "label_fr": "Ventabren"
            },
            {
                "value": 23687,
                "label": "La Ciotat",
                "label_ar": "لا سيوتات",
                "label_fr": "La Ciotat"
            },
            {
                "value": 23688,
                "label": "Sorgues",
                "label_ar": "سورجيس",
                "label_fr": "Sorgues"
            },
            {
                "value": 23689,
                "label": "Carces",
                "label_ar": "كاريس",
                "label_fr": "Carces"
            },
            {
                "value": 23690,
                "label": "Avignon",
                "label_ar": "أفينيون",
                "label_fr": "Avignon"
            },
            {
                "value": 23691,
                "label": "Merindol",
                "label_ar": "ميريندول",
                "label_fr": "Merindol"
            },
            {
                "value": 23692,
                "label": "Ceyreste",
                "label_ar": "سيريستي",
                "label_fr": "Ceyreste"
            },
            {
                "value": 23693,
                "label": "Caumont-sur-Durance",
                "label_ar": "كومونت سور دورانس",
                "label_fr": "Caumont-sur-Durance"
            },
            {
                "value": 23694,
                "label": "Peypin",
                "label_ar": "بيبين",
                "label_fr": "Peypin"
            },
            {
                "value": 23695,
                "label": "Vedene",
                "label_ar": "فيدين",
                "label_fr": "Vedene"
            },
            {
                "value": 23696,
                "label": "Arles",
                "label_ar": "آرل",
                "label_fr": "Arles"
            },
            {
                "value": 23697,
                "label": "Vaison-la-Romaine",
                "label_ar": "فايسون لا رومين",
                "label_fr": "Vaison-la-Romaine"
            },
            {
                "value": 23698,
                "label": "St Chamas",
                "label_ar": "سانت شاماس",
                "label_fr": "St Chamas"
            },
            {
                "value": 23699,
                "label": "Le Puy-Sainte-Reparade",
                "label_ar": "لو بوي سانت ريباراد",
                "label_fr": "Le Puy-Sainte-Réparade"
            },
            {
                "value": 23700,
                "label": "Rognac",
                "label_ar": "روجناك",
                "label_fr": "Rognac"
            },
            {
                "value": 23701,
                "label": "Istres",
                "label_ar": "ايستر",
                "label_fr": "Istres"
            },
            {
                "value": 23702,
                "label": "La Brillanne",
                "label_ar": "لا بريان",
                "label_fr": "La Brillanne"
            },
            {
                "value": 23703,
                "label": "Sausset-les-Pins",
                "label_ar": "Sausset-les-Pins",
                "label_fr": "Sausset-les-Pins"
            },
            {
                "value": 23704,
                "label": "Senas",
                "label_ar": "سيناس",
                "label_fr": "Senas"
            },
            {
                "value": 23705,
                "label": "Valreas",
                "label_ar": "فالرياس",
                "label_fr": "Valréas"
            },
            {
                "value": 23706,
                "label": "Volx",
                "label_ar": "فولكس",
                "label_fr": "Volx"
            },
            {
                "value": 23707,
                "label": "Saint-Martin-de-Crau",
                "label_ar": "سان مارتن دي كراو",
                "label_fr": "Saint-Martin-de-Crau"
            },
            {
                "value": 23708,
                "label": "Monteux",
                "label_ar": "مونتوكس",
                "label_fr": "Monteux"
            },
            {
                "value": 23709,
                "label": "Plan-d'Orgon",
                "label_ar": "بلان دورغون",
                "label_fr": "Plan-d'Orgon"
            },
            {
                "value": 23710,
                "label": "Saint-Disdier",
                "label_ar": "سان ديسدير",
                "label_fr": "Saint-Disdier"
            },
            {
                "value": 23711,
                "label": "Salon-de-Provence",
                "label_ar": "صالون دي بروفانس",
                "label_fr": "Salon-de-Provence"
            },
            {
                "value": 23712,
                "label": "Allos",
                "label_ar": "ألوس",
                "label_fr": "Allos"
            },
            {
                "value": 23713,
                "label": "Saint-Rémy-de-Provence",
                "label_ar": "سان ريمي دي بروفانس",
                "label_fr": "Saint-Rémy-de-Provence"
            },
            {
                "value": 23714,
                "label": "Suze-la-Rousse",
                "label_ar": "سوز-لا-روس",
                "label_fr": "Suze-la-Rousse"
            },
            {
                "value": 23715,
                "label": "Gardanne",
                "label_ar": "غاردان",
                "label_fr": "Gardanne"
            },
            {
                "value": 23716,
                "label": "Isle",
                "label_ar": "جزيرة",
                "label_fr": "Île"
            },
            {
                "value": 23717,
                "label": "Rochefort-du-Gard",
                "label_ar": "روشفورت دو جارد",
                "label_fr": "Rochefort-du-Gard"
            },
            {
                "value": 23718,
                "label": "Aubagne",
                "label_ar": "أوباني",
                "label_fr": "Aubagne"
            },
            {
                "value": 23719,
                "label": "Briançon",
                "label_ar": "بريانسون",
                "label_fr": "Briançon"
            },
            {
                "value": 23720,
                "label": "Lancon-Provence",
                "label_ar": "لانكون بروفانس",
                "label_fr": "Lançon-Provence"
            },
            {
                "value": 23721,
                "label": "Ajaccio",
                "label_ar": "أجاكسيو",
                "label_fr": "Ajaccio"
            },
            {
                "value": 23722,
                "label": "Cadolive",
                "label_ar": "كادوليف",
                "label_fr": "Cadolive"
            },
            {
                "value": 23723,
                "label": "Rousset",
                "label_ar": "روسيت",
                "label_fr": "Rousset"
            },
            {
                "value": 23724,
                "label": "Bastia",
                "label_ar": "باستيا",
                "label_fr": "Bastia"
            },
            {
                "value": 23725,
                "label": "Chateaurenard",
                "label_ar": "شاتورينارد",
                "label_fr": "Châteaurenard"
            },
            {
                "value": 23726,
                "label": "Les Pennes-Mirabeau",
                "label_ar": "ليه بينيس ميرابو",
                "label_fr": "Les Pennes-Mirabeau"
            },
            {
                "value": 23727,
                "label": "Pertuis",
                "label_ar": "بيرتويس",
                "label_fr": "Pertuis"
            },
            {
                "value": 23728,
                "label": "Venasque",
                "label_ar": "فيناسك",
                "label_fr": "Venasque"
            },
            {
                "value": 23729,
                "label": "Martigues",
                "label_ar": "مارتيغ",
                "label_fr": "Martigues"
            },
            {
                "value": 23730,
                "label": "Fos-sur-Mer",
                "label_ar": "فوس سور مير",
                "label_fr": "Fos-sur-Mer"
            },
            {
                "value": 23731,
                "label": "Gignac-la-Nerthe",
                "label_ar": "Gignac-la-Nerthe",
                "label_fr": "Gignac-la-Nerthe"
            },
            {
                "value": 23732,
                "label": "Miramas",
                "label_ar": "ميراماس",
                "label_fr": "Miramas"
            },
            {
                "value": 23733,
                "label": "Orgon",
                "label_ar": "Orgon",
                "label_fr": "Orgon"
            },
            {
                "value": 23734,
                "label": "Velaux",
                "label_ar": "فيلوكس",
                "label_fr": "Velaux"
            },
            {
                "value": 23735,
                "label": "Pujaut",
                "label_ar": "بوجوت",
                "label_fr": "Pujaut"
            },
            {
                "value": 23736,
                "label": "Greasque",
                "label_ar": "دهني",
                "label_fr": "Grasque"
            },
            {
                "value": 23737,
                "label": "Entraigues-sur-la-Sorgue",
                "label_ar": "Entraigues-sur-la-Sorgue",
                "label_fr": "Entraigues-sur-la-Sorgue"
            },
            {
                "value": 23738,
                "label": "Meyrargues",
                "label_ar": "مايرارج",
                "label_fr": "Meyrargues"
            },
            {
                "value": 23739,
                "label": "Cabries",
                "label_ar": "كابريس",
                "label_fr": "Cabries"
            },
            {
                "value": 23740,
                "label": "Courthezon",
                "label_ar": "كورتزون",
                "label_fr": "Courthezon"
            },
            {
                "value": 23741,
                "label": "Les Taillades",
                "label_ar": "ليه تليدس",
                "label_fr": "Les Taillades"
            },
            {
                "value": 23742,
                "label": "Eyragues",
                "label_ar": "يراجيز",
                "label_fr": "Eyragues"
            },
            {
                "value": 23743,
                "label": "Seyne-les-Alpes",
                "label_ar": "سين ليه ألب",
                "label_fr": "Seyne-les-Alpes"
            },
            {
                "value": 23744,
                "label": "Lagnes",
                "label_ar": "لاجن",
                "label_fr": "Lagnes"
            },
            {
                "value": 23745,
                "label": "Vitrolles",
                "label_ar": "الزجاجات",
                "label_fr": "Vitrolles"
            },
            {
                "value": 23746,
                "label": "Lambesc",
                "label_ar": "لامبسك",
                "label_fr": "Lambesc"
            },
            {
                "value": 23747,
                "label": "Montclar",
                "label_ar": "مونكلار",
                "label_fr": "Montclar"
            },
            {
                "value": 23748,
                "label": "Pelissanne",
                "label_ar": "بيلسان",
                "label_fr": "Pelissanne"
            },
            {
                "value": 23749,
                "label": "Eyguieres",
                "label_ar": "إيجيريس",
                "label_fr": "Eyguières"
            },
            {
                "value": 23750,
                "label": "Mane",
                "label_ar": "ماني",
                "label_fr": "Crinière"
            },
            {
                "value": 23751,
                "label": "Carpentras",
                "label_ar": "كاربنترا",
                "label_fr": "Carpentras"
            },
            {
                "value": 23752,
                "label": "Luynes",
                "label_ar": "لوينيس",
                "label_fr": "Luynes"
            },
            {
                "value": 23753,
                "label": "Les Angles",
                "label_ar": "ليه انجليس",
                "label_fr": "Les Angles"
            },
            {
                "value": 23754,
                "label": "Grans",
                "label_ar": "جرانس",
                "label_fr": "Grans"
            },
            {
                "value": 23755,
                "label": "La Foux",
                "label_ar": "لا فوكس",
                "label_fr": "La Foux"
            },
            {
                "value": 23756,
                "label": "Corte",
                "label_ar": "كورتي",
                "label_fr": "Corte"
            },
            {
                "value": 23757,
                "label": "Biguglia",
                "label_ar": "بيجوجليا",
                "label_fr": "Biguglia"
            },
            {
                "value": 23758,
                "label": "Calvi",
                "label_ar": "كالفي",
                "label_fr": "Calvi"
            },
            {
                "value": 23759,
                "label": "Moncale",
                "label_ar": "مونكال",
                "label_fr": "Moncale"
            },
            {
                "value": 23760,
                "label": "Alata",
                "label_ar": "الااتا",
                "label_fr": "Alata"
            },
            {
                "value": 23761,
                "label": "Lille",
                "label_ar": "ليل",
                "label_fr": "Lille"
            },
            {
                "value": 23762,
                "label": "Somain",
                "label_ar": "شيء ما",
                "label_fr": "Somain"
            },
            {
                "value": 23763,
                "label": "Fresnes-sur-Escaut",
                "label_ar": "فرينيس سور إسكوت",
                "label_fr": "Fresnes-sur-Escaut"
            },
            {
                "value": 23764,
                "label": "Bousbecque",
                "label_ar": "بوسبيك",
                "label_fr": "Bousbecque"
            },
            {
                "value": 23765,
                "label": "Bertincourt",
                "label_ar": "بيرتينكور",
                "label_fr": "Bertincourt"
            },
            {
                "value": 23766,
                "label": "Hellemmes-Lille",
                "label_ar": "هيلميس ليل",
                "label_fr": "Hellemmes-Lille"
            },
            {
                "value": 23767,
                "label": "Avesnes-le-Comte",
                "label_ar": "أفيسنيس لو كومت",
                "label_fr": "Avesnes-le-Comte"
            },
            {
                "value": 23768,
                "label": "Isbergues",
                "label_ar": "Isbergues",
                "label_fr": "Isbergues"
            },
            {
                "value": 23769,
                "label": "Marly",
                "label_ar": "مارلي",
                "label_fr": "Marneux"
            },
            {
                "value": 23770,
                "label": "Riviere",
                "label_ar": "ريفيير",
                "label_fr": "Rivière"
            },
            {
                "value": 23771,
                "label": "Saint-Pol-sur-Mer",
                "label_ar": "سان بول سور مير",
                "label_fr": "Saint-Pol-sur-Mer"
            },
            {
                "value": 23772,
                "label": "Bruay-sur-l'Escaut",
                "label_ar": "Bruay-sur-l'Escaut",
                "label_fr": "Bruay-sur-l'Escaut"
            },
            {
                "value": 23773,
                "label": "Sains-en-Gohelle",
                "label_ar": "Sains-en-Gohelle",
                "label_fr": "Sains-en-Gohelle"
            },
            {
                "value": 23774,
                "label": "Grand-Fort-Philippe",
                "label_ar": "جراند فورت فيليب",
                "label_fr": "Grand-Fort-Philippe"
            },
            {
                "value": 23775,
                "label": "Ronchin",
                "label_ar": "رونشين",
                "label_fr": "Ronchin"
            },
            {
                "value": 23776,
                "label": "Tilloy-lez-Cambrai",
                "label_ar": "تيلوي ليز كامبراي",
                "label_fr": "Tilloy-lez-Cambrai"
            },
            {
                "value": 23777,
                "label": "Hesdin",
                "label_ar": "حسدين",
                "label_fr": "Hesdin"
            },
            {
                "value": 23778,
                "label": "Jolimetz",
                "label_ar": "جوليمتز",
                "label_fr": "Jolimetz"
            },
            {
                "value": 23779,
                "label": "Dunkirk",
                "label_ar": "دونكيرك",
                "label_fr": "Dunkerque"
            },
            {
                "value": 23780,
                "label": "Hautecloque",
                "label_ar": "Hautecloque",
                "label_fr": "Hautecloque"
            },
            {
                "value": 23781,
                "label": "Hondeghem",
                "label_ar": "هندغم",
                "label_fr": "Hondeghem"
            },
            {
                "value": 23782,
                "label": "Bellebrune",
                "label_ar": "بيليبرون",
                "label_fr": "Bellebrune"
            },
            {
                "value": 23783,
                "label": "Saint-Saulve",
                "label_ar": "سان سولفيه",
                "label_fr": "Saint-Saulve"
            },
            {
                "value": 23784,
                "label": "Meurchin",
                "label_ar": "مورشين",
                "label_fr": "Meurchin"
            },
            {
                "value": 23785,
                "label": "Rieux-en-Cambresis",
                "label_ar": "Rieux-en-Cambresis",
                "label_fr": "Rieux-en-Cambresis"
            },
            {
                "value": 23786,
                "label": "Lattre-Saint-Quentin",
                "label_ar": "لاتر سانت كوينتين",
                "label_fr": "Lattre-Saint-Quentin"
            },
            {
                "value": 23787,
                "label": "Lens",
                "label_ar": "عدسة",
                "label_fr": "Lentille"
            },
            {
                "value": 23788,
                "label": "Oignies",
                "label_ar": "أوجنيز",
                "label_fr": "Oignies"
            },
            {
                "value": 23789,
                "label": "Boisleux-au-Mont",
                "label_ar": "Boisleux-au-Mont",
                "label_fr": "Boisleux-au-Mont"
            },
            {
                "value": 23790,
                "label": "Villers-au-Bois",
                "label_ar": "فيليرز-أو-بوا",
                "label_fr": "Villers-au-Bois"
            },
            {
                "value": 23791,
                "label": "Douchy-les-Mines",
                "label_ar": "دوشي ليه ماينز",
                "label_fr": "Douchy-les-Mines"
            },
            {
                "value": 23792,
                "label": "Courcelles-les-Lens",
                "label_ar": "كورسيل ليه لينس",
                "label_fr": "Courcelles-les-Lens"
            },
            {
                "value": 23793,
                "label": "Wallers",
                "label_ar": "والر",
                "label_fr": "Wallers"
            },
            {
                "value": 23794,
                "label": "Aire-sur-la-Lys",
                "label_ar": "آير سور لا ليس",
                "label_fr": "Aire-sur-la-Lys"
            },
            {
                "value": 23795,
                "label": "Outreau",
                "label_ar": "تفشي",
                "label_fr": "Outreau"
            },
            {
                "value": 23796,
                "label": "Raimbeaucourt",
                "label_ar": "ريمبيكورت",
                "label_fr": "Raimbeaucourt"
            },
            {
                "value": 23797,
                "label": "Armentières",
                "label_ar": "Armentières",
                "label_fr": "Armentières"
            },
            {
                "value": 23798,
                "label": "Bully-les-Mines",
                "label_ar": "Bully-les-Mines",
                "label_fr": "Bully-les-Mines"
            },
            {
                "value": 23799,
                "label": "Hem",
                "label_ar": "هدب",
                "label_fr": "Ourlet"
            },
            {
                "value": 23800,
                "label": "Hinges",
                "label_ar": "مفصلات",
                "label_fr": "Charnières"
            },
            {
                "value": 23801,
                "label": "Bellaing",
                "label_ar": "بيلينج",
                "label_fr": "Bellaing"
            },
            {
                "value": 23802,
                "label": "Liévin",
                "label_ar": "ليفين",
                "label_fr": "Liévin"
            },
            {
                "value": 23803,
                "label": "Allennes-les-Marais",
                "label_ar": "ألين ليس ماريه",
                "label_fr": "Allennes-les-Marais"
            },
            {
                "value": 23804,
                "label": "Lewarde",
                "label_ar": "لوارد",
                "label_fr": "Lewarde"
            },
            {
                "value": 23805,
                "label": "Phalempin",
                "label_ar": "فالمبين",
                "label_fr": "Phalempine"
            },
            {
                "value": 23806,
                "label": "Saint-Pol-sur-Ternoise",
                "label_ar": "سان بول سور ترنوز",
                "label_fr": "Saint-Pol-sur-Ternoise"
            },
            {
                "value": 23807,
                "label": "Graincourt-les-Havrincourt",
                "label_ar": "Graincourt-les-Havrincourt",
                "label_fr": "Graincourt-les-Havrincourt"
            },
            {
                "value": 23808,
                "label": "Lorgies",
                "label_ar": "Lorgies",
                "label_fr": "Lorgies"
            },
            {
                "value": 23809,
                "label": "Zouafques",
                "label_ar": "الزواف",
                "label_fr": "Zouafques"
            },
            {
                "value": 23810,
                "label": "Tourcoing",
                "label_ar": "توركوينج",
                "label_fr": "Tourcoing"
            },
            {
                "value": 23811,
                "label": "Blaringhem",
                "label_ar": "Blaringhem",
                "label_fr": "Blaringhem"
            },
            {
                "value": 23812,
                "label": "Croix",
                "label_ar": "كروا",
                "label_fr": "Croix"
            },
            {
                "value": 23813,
                "label": "Arras",
                "label_ar": "أراس",
                "label_fr": "Arras"
            },
            {
                "value": 23814,
                "label": "Lynde",
                "label_ar": "ليند",
                "label_fr": "Lynde"
            },
            {
                "value": 23815,
                "label": "Ostricourt",
                "label_ar": "أوستريكورت",
                "label_fr": "Ostricourt"
            },
            {
                "value": 23816,
                "label": "Erquinghem-Lys",
                "label_ar": "إركوينجهيم ليس",
                "label_fr": "Erquinghem-Lys"
            },
            {
                "value": 23817,
                "label": "Coudekerque-Branche",
                "label_ar": "Coudekerque-Branche",
                "label_fr": "Coudekerque-Branche"
            },
            {
                "value": 23818,
                "label": "Locon",
                "label_ar": "لوكون",
                "label_fr": "Locon"
            },
            {
                "value": 23819,
                "label": "Teteghem",
                "label_ar": "تتغيم",
                "label_fr": "Téteghem"
            },
            {
                "value": 23820,
                "label": "Ferriere-la-Grande",
                "label_ar": "فيريير لا غراندي",
                "label_fr": "Ferrière-la-Grande"
            },
            {
                "value": 23821,
                "label": "Lestrem",
                "label_ar": "ليستريم",
                "label_fr": "Lestrem"
            },
            {
                "value": 23822,
                "label": "Masnieres",
                "label_ar": "Masnieres",
                "label_fr": "Masnières"
            },
            {
                "value": 23823,
                "label": "Tournehem-sur-la-Hem",
                "label_ar": "تورنهيم سور لا هيم",
                "label_fr": "Tournehem-sur-la-Hem"
            },
            {
                "value": 23824,
                "label": "Dourges",
                "label_ar": "مراوغ",
                "label_fr": "Dourges"
            },
            {
                "value": 23825,
                "label": "Roubaix",
                "label_ar": "روبيكس",
                "label_fr": "Roubaix"
            },
            {
                "value": 23826,
                "label": "Hazebrouck",
                "label_ar": "هازبروك",
                "label_fr": "Hazebrouck"
            },
            {
                "value": 23827,
                "label": "Beuvry-la-Foret",
                "label_ar": "بوفيري لا فوريه",
                "label_fr": "Beuvry-la-Foret"
            },
            {
                "value": 23828,
                "label": "Saint-Hilaire-de-Court",
                "label_ar": "سان هيلير دي كورت",
                "label_fr": "Saint-Hilaire-de-Court"
            },
            {
                "value": 23829,
                "label": "Flines-lez-Raches",
                "label_ar": "Flines-lez-Raches",
                "label_fr": "Flines-lez-Raches"
            },
            {
                "value": 23830,
                "label": "Abscon",
                "label_ar": "أبسكون",
                "label_fr": "Abscon"
            },
            {
                "value": 23831,
                "label": "Valenciennes",
                "label_ar": "فالنسيان",
                "label_fr": "Valenciennes"
            },
            {
                "value": 23832,
                "label": "Libercourt",
                "label_ar": "Libercourt",
                "label_fr": "Libercourt"
            },
            {
                "value": 23833,
                "label": "Hardifort",
                "label_ar": "هارديفورت",
                "label_fr": "Hardifort"
            },
            {
                "value": 23834,
                "label": "Hergnies",
                "label_ar": "هرغنيز",
                "label_fr": "Hergnies"
            },
            {
                "value": 23835,
                "label": "Samer",
                "label_ar": "سامر",
                "label_fr": "Samer"
            },
            {
                "value": 23836,
                "label": "Wimereux",
                "label_ar": "ويميرو",
                "label_fr": "Wimereux"
            },
            {
                "value": 23837,
                "label": "Conde-sur-l'Escaut",
                "label_ar": "Conde-sur-l'Escaut",
                "label_fr": "Condé-sur-l'Escaut"
            },
            {
                "value": 23838,
                "label": "Verquin",
                "label_ar": "فيركوين",
                "label_fr": "Verquin"
            },
            {
                "value": 23839,
                "label": "Boulogne-sur-Mer",
                "label_ar": "بولوني سور مير",
                "label_fr": "Boulogne-sur-Mer"
            },
            {
                "value": 23840,
                "label": "Faches-Thumesnil",
                "label_ar": "Faches-Thumesnil",
                "label_fr": "Faches-Thumesnil"
            },
            {
                "value": 23841,
                "label": "Hesdin-l'Abbe",
                "label_ar": "حسدين لاب",
                "label_fr": "Hesdin-l'Abbé"
            },
            {
                "value": 23842,
                "label": "Maroeuil",
                "label_ar": "مارويل",
                "label_fr": "Maroeuil"
            },
            {
                "value": 23843,
                "label": "Beaurainville",
                "label_ar": "بورينفيل",
                "label_fr": "Beaurainville"
            },
            {
                "value": 23844,
                "label": "Merville",
                "label_ar": "ميرفيل",
                "label_fr": "Merville"
            },
            {
                "value": 23845,
                "label": "Avion",
                "label_ar": "افيون",
                "label_fr": "Avion"
            },
            {
                "value": 23846,
                "label": "Estevelles",
                "label_ar": "إستيفيلس",
                "label_fr": "Estevelles"
            },
            {
                "value": 23847,
                "label": "Béthune",
                "label_ar": "بيتون",
                "label_fr": "Béthune"
            },
            {
                "value": 23848,
                "label": "Surques",
                "label_ar": "سوركيس",
                "label_fr": "Surques"
            },
            {
                "value": 23849,
                "label": "Courrières",
                "label_ar": "كوريير",
                "label_fr": "Courrières"
            },
            {
                "value": 23850,
                "label": "Bleriot-Plage",
                "label_ar": "بليريوت بلاج",
                "label_fr": "Blériot-Plage"
            },
            {
                "value": 23851,
                "label": "Villers-Outreaux",
                "label_ar": "فيليرز أوترو",
                "label_fr": "Villers-Outreaux"
            },
            {
                "value": 23852,
                "label": "Anzin",
                "label_ar": "أنزين",
                "label_fr": "Anzin"
            },
            {
                "value": 23853,
                "label": "Wahagnies",
                "label_ar": "Wahagnies",
                "label_fr": "Wahagnies"
            },
            {
                "value": 23854,
                "label": "Verquigneul",
                "label_ar": "فيركينيول",
                "label_fr": "Verquigneul"
            },
            {
                "value": 23855,
                "label": "Houdain-lez-Bavay",
                "label_ar": "Houdain-lez-Bavay",
                "label_fr": "Houdain-lez-Bavay"
            },
            {
                "value": 23856,
                "label": "Saint-Amand-les-Eaux",
                "label_ar": "سان اماند ليه او",
                "label_fr": "Saint-Amand-les-Eaux"
            },
            {
                "value": 23857,
                "label": "Givenchy-en-Gohelle",
                "label_ar": "جيفنشي إن جوهيل",
                "label_fr": "Givenchy-en-Gohelle"
            },
            {
                "value": 23858,
                "label": "Escaudain",
                "label_ar": "اسكاودين",
                "label_fr": "Escaudain"
            },
            {
                "value": 23859,
                "label": "Nieppe",
                "label_ar": "نيبى",
                "label_fr": "Nieppe"
            },
            {
                "value": 23860,
                "label": "Saint-Folquin",
                "label_ar": "سان فولكين",
                "label_fr": "Saint-Folquin"
            },
            {
                "value": 23861,
                "label": "Solesmes",
                "label_ar": "سولسمس",
                "label_fr": "Solesmes"
            },
            {
                "value": 23862,
                "label": "Proville",
                "label_ar": "بروفيل",
                "label_fr": "Proville"
            },
            {
                "value": 23863,
                "label": "Douai",
                "label_ar": "دواي",
                "label_fr": "Douai"
            },
            {
                "value": 23864,
                "label": "Rouvroy",
                "label_ar": "روفروي",
                "label_fr": "Rouvroy"
            },
            {
                "value": 23865,
                "label": "Bourlon",
                "label_ar": "بورلون",
                "label_fr": "Bourlon"
            },
            {
                "value": 23866,
                "label": "Waziers",
                "label_ar": "ويزير",
                "label_fr": "Waziers"
            },
            {
                "value": 23867,
                "label": "Mazingarbe",
                "label_ar": "مازينجارب",
                "label_fr": "Mazingarbe"
            },
            {
                "value": 23868,
                "label": "Richebourg",
                "label_ar": "ريتشبورج",
                "label_fr": "Richebourg"
            },
            {
                "value": 23869,
                "label": "Pont-a-Marcq",
                "label_ar": "بونت أ ماركك",
                "label_fr": "Pont-à-Marcq"
            },
            {
                "value": 23870,
                "label": "Calais",
                "label_ar": "كاليه",
                "label_fr": "Calais"
            },
            {
                "value": 23871,
                "label": "Noyelles-sur-Escaut",
                "label_ar": "Noyelles-sur-Escaut",
                "label_fr": "Noyelles-sur-Escaut"
            },
            {
                "value": 23872,
                "label": "La Foret",
                "label_ar": "لا فوريت",
                "label_fr": "La Foret"
            },
            {
                "value": 23873,
                "label": "Henin-Beaumont",
                "label_ar": "هينين بومونت",
                "label_fr": "Hénin-Beaumont"
            },
            {
                "value": 23874,
                "label": "Roncq",
                "label_ar": "رونك",
                "label_fr": "Roncq"
            },
            {
                "value": 23875,
                "label": "Nomain",
                "label_ar": "الاسم",
                "label_fr": "Nomination"
            },
            {
                "value": 23876,
                "label": "Beaumetz-les-Loges",
                "label_ar": "بوميتز ليه لوج",
                "label_fr": "Beaumetz-les-Loges"
            },
            {
                "value": 23877,
                "label": "Mons-en-Baroeul",
                "label_ar": "مونس أون بارويل",
                "label_fr": "Mons-en-Baroeul"
            },
            {
                "value": 23878,
                "label": "Fouquieres-les-Lens",
                "label_ar": "Fouquieres-les-Lens",
                "label_fr": "Fouquières-les-Lens"
            },
            {
                "value": 23879,
                "label": "Mouvaux",
                "label_ar": "موفو",
                "label_fr": "Mouvaux"
            },
            {
                "value": 23880,
                "label": "Aulnoye-Aymeries",
                "label_ar": "أولنوي أيميريس",
                "label_fr": "Aulnoye-Aymeries"
            },
            {
                "value": 23881,
                "label": "Thumeries",
                "label_ar": "Thumeries",
                "label_fr": "Thumeries"
            },
            {
                "value": 23882,
                "label": "Blagnac",
                "label_ar": "بلاجناك",
                "label_fr": "Blagnac"
            },
            {
                "value": 23883,
                "label": "Loures-Barousse",
                "label_ar": "لوريس باروس",
                "label_fr": "Loures-Barousse"
            },
            {
                "value": 23884,
                "label": "Toulouse",
                "label_ar": "تولوز",
                "label_fr": "Toulouse"
            },
            {
                "value": 23885,
                "label": "Revel",
                "label_ar": "عربد",
                "label_fr": "Se délecter"
            },
            {
                "value": 23886,
                "label": "Albi",
                "label_ar": "ألبي",
                "label_fr": "Albi"
            },
            {
                "value": 23887,
                "label": "Deyme",
                "label_ar": "ديمي",
                "label_fr": "Deyme"
            },
            {
                "value": 23888,
                "label": "Castres",
                "label_ar": "كاستر",
                "label_fr": "Castres"
            },
            {
                "value": 23889,
                "label": "Pibrac",
                "label_ar": "بيبراك",
                "label_fr": "Pibrac"
            },
            {
                "value": 23890,
                "label": "Cornebarrieu",
                "label_ar": "كورنيباريو",
                "label_fr": "Cornebarrieu"
            },
            {
                "value": 23891,
                "label": "Cahors",
                "label_ar": "كاهورز",
                "label_fr": "Cahors"
            },
            {
                "value": 23892,
                "label": "Castanet-Tolosan",
                "label_ar": "كاستانت تولوسان",
                "label_fr": "Castanet-Tolosan"
            },
            {
                "value": 23893,
                "label": "Soues",
                "label_ar": "سويس",
                "label_fr": "Soues"
            },
            {
                "value": 23894,
                "label": "Luz-Saint-Sauveur",
                "label_ar": "لوز سان سوفور",
                "label_fr": "Luz-Saint-Sauveur"
            },
            {
                "value": 23895,
                "label": "Baron",
                "label_ar": "البارون",
                "label_fr": "Baron"
            },
            {
                "value": 23896,
                "label": "Begles",
                "label_ar": "يتساهل",
                "label_fr": "Begles"
            },
            {
                "value": 23897,
                "label": "Talence",
                "label_ar": "الموهبة",
                "label_fr": "Talence"
            },
            {
                "value": 23898,
                "label": "Lauzun",
                "label_ar": "لوزون",
                "label_fr": "Lauzun"
            },
            {
                "value": 23899,
                "label": "Hourtin",
                "label_ar": "هورتين",
                "label_fr": "Hourtin"
            },
            {
                "value": 23900,
                "label": "Mussidan",
                "label_ar": "موسيدان",
                "label_fr": "Mussidan"
            },
            {
                "value": 23901,
                "label": "Mérignac",
                "label_ar": "ميرينياك",
                "label_fr": "Mérignac"
            },
            {
                "value": 23902,
                "label": "Bordeaux",
                "label_ar": "بوردو",
                "label_fr": "Bordeaux"
            },
            {
                "value": 23903,
                "label": "Catus",
                "label_ar": "كاتوس",
                "label_fr": "Catus"
            },
            {
                "value": 23904,
                "label": "Frouzins",
                "label_ar": "فروزين",
                "label_fr": "Frouzins"
            },
            {
                "value": 23905,
                "label": "Lisle-sur-Tarn",
                "label_ar": "ليسل سور تارن",
                "label_fr": "Lisle-sur-Tarn"
            },
            {
                "value": 23906,
                "label": "Muret",
                "label_ar": "موريت",
                "label_fr": "Muret"
            },
            {
                "value": 23907,
                "label": "Saujon",
                "label_ar": "سوجون",
                "label_fr": "Saujon"
            },
            {
                "value": 23908,
                "label": "Favars",
                "label_ar": "فافارز",
                "label_fr": "Favars"
            },
            {
                "value": 23909,
                "label": "Niort",
                "label_ar": "نيور",
                "label_fr": "Niort"
            },
            {
                "value": 23910,
                "label": "Frontenay-Rohan-Rohan",
                "label_ar": "فرونتني روهان روهان",
                "label_fr": "Frontenay-Rohan-Rohan"
            },
            {
                "value": 23911,
                "label": "Soyaux",
                "label_ar": "صوياوكس",
                "label_fr": "Soyaux"
            },
            {
                "value": 23912,
                "label": "Poitiers",
                "label_ar": "بواتييه",
                "label_fr": "Poitiers"
            },
            {
                "value": 23913,
                "label": "Puilboreau",
                "label_ar": "بولبورو",
                "label_fr": "Puilboreau"
            },
            {
                "value": 23914,
                "label": "Châtellerault",
                "label_ar": "شاتليراولت",
                "label_fr": "Châtellerault"
            },
            {
                "value": 23915,
                "label": "Gueret",
                "label_ar": "جيريت",
                "label_fr": "Guéret"
            },
            {
                "value": 23916,
                "label": "Romans",
                "label_ar": "رومية",
                "label_fr": "Romains"
            },
            {
                "value": 23917,
                "label": "Limoges",
                "label_ar": "ليموج",
                "label_fr": "Limoges"
            },
            {
                "value": 23918,
                "label": "La Rochelle",
                "label_ar": "لاروشيل",
                "label_fr": "La Rochelle"
            },
            {
                "value": 23919,
                "label": "Tulle",
                "label_ar": "تول",
                "label_fr": "Tulle"
            },
            {
                "value": 23920,
                "label": "Bretignolles",
                "label_ar": "بريتيجنول",
                "label_fr": "Bretignolles"
            },
            {
                "value": 23921,
                "label": "Angoulême",
                "label_ar": "أنغوليم",
                "label_fr": "Angoulême"
            },
            {
                "value": 23922,
                "label": "Ars",
                "label_ar": "آرس",
                "label_fr": "Ars"
            },
            {
                "value": 23923,
                "label": "Brioux-sur-Boutonne",
                "label_ar": "بريوكس سور بوتون",
                "label_fr": "Brioux-sur-Boutonne"
            },
            {
                "value": 23924,
                "label": "Lavaur",
                "label_ar": "لافور",
                "label_fr": "Lavaur"
            },
            {
                "value": 23925,
                "label": "Roquettes",
                "label_ar": "الروكيت",
                "label_fr": "Roquettes"
            },
            {
                "value": 23926,
                "label": "Balma",
                "label_ar": "بالما",
                "label_fr": "Balma"
            },
            {
                "value": 23927,
                "label": "Cour-sur-Loire",
                "label_ar": "كور سور لوار",
                "label_fr": "Cour-sur-Loire"
            },
            {
                "value": 23928,
                "label": "Verneuil-sur-Vienne",
                "label_ar": "فيرنويل سور فيين",
                "label_fr": "Verneuil-sur-Vienne"
            },
            {
                "value": 23929,
                "label": "Nieul-sur-Mer",
                "label_ar": "نيول سور مير",
                "label_fr": "Nieul-sur-Mer"
            },
            {
                "value": 23930,
                "label": "Le Vigeant",
                "label_ar": "لو فيجينت",
                "label_fr": "Le Vigeant"
            },
            {
                "value": 23931,
                "label": "Montlieu-la-Garde",
                "label_ar": "مونتليو لا جارد",
                "label_fr": "Montlieu-la-Garde"
            },
            {
                "value": 23932,
                "label": "Aixe-sur-Vienne",
                "label_ar": "إيكس سور فيين",
                "label_fr": "Aixe-sur-Vienne"
            },
            {
                "value": 23933,
                "label": "Mesnil-sous-Vienne",
                "label_ar": "Mesnil-sous-Vienne",
                "label_fr": "Mesnil-sous-Vienne"
            },
            {
                "value": 23934,
                "label": "Montauban",
                "label_ar": "مونتوبان",
                "label_fr": "Montauban"
            },
            {
                "value": 23935,
                "label": "Verfeil",
                "label_ar": "فيرفيل",
                "label_fr": "Verfeil"
            },
            {
                "value": 23936,
                "label": "Nérac",
                "label_ar": "نيراك",
                "label_fr": "Nérac"
            },
            {
                "value": 23937,
                "label": "Le Bouscat",
                "label_ar": "لو بوسكات",
                "label_fr": "Le Bouscat"
            },
            {
                "value": 23938,
                "label": "Marmande",
                "label_ar": "مارماند",
                "label_fr": "Marmande"
            },
            {
                "value": 23939,
                "label": "Sainte-Eanne",
                "label_ar": "سانت إيان",
                "label_fr": "Sainte-Eanne"
            },
            {
                "value": 23940,
                "label": "Cressat",
                "label_ar": "كريسات",
                "label_fr": "Cressat"
            },
            {
                "value": 23941,
                "label": "Bessines-sur-Gartempe",
                "label_ar": "بيسين سور جارتيمبي",
                "label_fr": "Bessines-sur-Gartempe"
            },
            {
                "value": 23942,
                "label": "Crayssac",
                "label_ar": "كريساك",
                "label_fr": "Crayssac"
            },
            {
                "value": 23943,
                "label": "Aussonne",
                "label_ar": "أوسون",
                "label_fr": "Aussonne"
            },
            {
                "value": 23944,
                "label": "Graulhet",
                "label_ar": "Graulhet",
                "label_fr": "Graulhet"
            },
            {
                "value": 23945,
                "label": "Coutras",
                "label_ar": "كوتراس",
                "label_fr": "Coutras"
            },
            {
                "value": 23946,
                "label": "Villenave-d'Ornon",
                "label_ar": "فيلناف دورنون",
                "label_fr": "Villenave-d'Ornon"
            },
            {
                "value": 23947,
                "label": "Ginestet",
                "label_ar": "جينستيت",
                "label_fr": "Ginestet"
            },
            {
                "value": 23948,
                "label": "Artigues-pres-Bordeaux",
                "label_ar": "Artigues-pres-Bordeaux",
                "label_fr": "Artigues-près-Bordeaux"
            },
            {
                "value": 23949,
                "label": "Miramont-Sensacq",
                "label_ar": "ميرامونت سينساك",
                "label_fr": "Miramont-Sensacq"
            },
            {
                "value": 23950,
                "label": "Cestas",
                "label_ar": "سيستاس",
                "label_fr": "Cestas"
            },
            {
                "value": 23951,
                "label": "Le Taillan-Medoc",
                "label_ar": "لو تيلان ميدوك",
                "label_fr": "Le Taillan-Médoc"
            },
            {
                "value": 23952,
                "label": "Monpazier",
                "label_ar": "Monpazier",
                "label_fr": "Monpazier"
            },
            {
                "value": 23953,
                "label": "Saint-Lys",
                "label_ar": "سانت ليس",
                "label_fr": "Saint-Lys"
            },
            {
                "value": 23954,
                "label": "Celles-sur-Belle",
                "label_ar": "سيلز سور بيل",
                "label_fr": "Celles-sur-Belle"
            },
            {
                "value": 23955,
                "label": "Saint-Jean-d'Angely",
                "label_ar": "سان جان دانجيلي",
                "label_fr": "Saint-Jean-d'Angély"
            },
            {
                "value": 23956,
                "label": "Meschers-sur-Gironde",
                "label_ar": "ميشير سور جيروند",
                "label_fr": "Meschers-sur-Gironde"
            },
            {
                "value": 23957,
                "label": "Chamboret",
                "label_ar": "شامبوريت",
                "label_fr": "Chamboret"
            },
            {
                "value": 23958,
                "label": "Saint-Just-pres-Brioude",
                "label_ar": "سان جوست بريود",
                "label_fr": "Saint-Just-pres-Brioude"
            },
            {
                "value": 23959,
                "label": "Terce",
                "label_ar": "تيريس",
                "label_fr": "Terce"
            },
            {
                "value": 23960,
                "label": "Naves",
                "label_ar": "نافيس",
                "label_fr": "Naves"
            },
            {
                "value": 23961,
                "label": "Chef-Boutonne",
                "label_ar": "الشيف بوتون",
                "label_fr": "Chef-Boutonne"
            },
            {
                "value": 23962,
                "label": "Lhommaize",
                "label_ar": "Lhommaize",
                "label_fr": "Lhommaize"
            },
            {
                "value": 23963,
                "label": "Rochefort",
                "label_ar": "روشيفورت",
                "label_fr": "Rochefort"
            },
            {
                "value": 23964,
                "label": "Romagne",
                "label_ar": "رومان",
                "label_fr": "Romagne"
            },
            {
                "value": 23965,
                "label": "Besse",
                "label_ar": "بيس",
                "label_fr": "Besse"
            },
            {
                "value": 23966,
                "label": "Taize-Aizie",
                "label_ar": "تيزي ايزي",
                "label_fr": "Taizé-Aizie"
            },
            {
                "value": 23967,
                "label": "Julienne",
                "label_ar": "جوليان",
                "label_fr": "Julienne"
            },
            {
                "value": 23968,
                "label": "Arthes",
                "label_ar": "آرثيز",
                "label_fr": "Arthes"
            },
            {
                "value": 23969,
                "label": "Noilhan",
                "label_ar": "نويلهان",
                "label_fr": "Noilhan"
            },
            {
                "value": 23970,
                "label": "Sainte-Ouenne",
                "label_ar": "سانت اوين",
                "label_fr": "Sainte-Ouenne"
            },
            {
                "value": 23971,
                "label": "Saint-Maxire",
                "label_ar": "سانت ماكسير",
                "label_fr": "Saint-Maxire"
            },
            {
                "value": 23972,
                "label": "Bonnat",
                "label_ar": "بونات",
                "label_fr": "Bonnat"
            },
            {
                "value": 23973,
                "label": "Aubusson",
                "label_ar": "أوبيسون",
                "label_fr": "Aubusson"
            },
            {
                "value": 23974,
                "label": "La Tremblade",
                "label_ar": "لا تريمبلاد",
                "label_fr": "La Tremblade"
            },
            {
                "value": 23975,
                "label": "Saint-Maurice-de-Gourdans",
                "label_ar": "سان موريس دي جوردان",
                "label_fr": "Saint-Maurice-de-Gourdans"
            },
            {
                "value": 23976,
                "label": "Jardres",
                "label_ar": "Jardres",
                "label_fr": "Jardres"
            },
            {
                "value": 23977,
                "label": "La Meignanne",
                "label_ar": "لا مينيان",
                "label_fr": "La Meignanne"
            },
            {
                "value": 23978,
                "label": "Vix",
                "label_ar": "فيكس",
                "label_fr": "Vix"
            },
            {
                "value": 23979,
                "label": "Château-lÉvêque",
                "label_ar": "شاتو ليفيك",
                "label_fr": "Château-lÉvêque"
            },
            {
                "value": 23980,
                "label": "La Seguiniere",
                "label_ar": "لا سيجوينير",
                "label_fr": "La Séguinière"
            },
            {
                "value": 23981,
                "label": "La Bazoge",
                "label_ar": "لا بازوج",
                "label_fr": "La Bazoge"
            },
            {
                "value": 23982,
                "label": "La Tranche-sur-Mer",
                "label_ar": "لا ترانش سور مير",
                "label_fr": "La Tranche-sur-Mer"
            },
            {
                "value": 23983,
                "label": "La Tessoualle",
                "label_ar": "لا تسوال",
                "label_fr": "La Tessoualle"
            },
            {
                "value": 23984,
                "label": "Murs-Erigne",
                "label_ar": "مورس إيرين",
                "label_fr": "Murs-Erigne"
            },
            {
                "value": 23985,
                "label": "Clouzeau",
                "label_ar": "كلوزو",
                "label_fr": "Clouzeau"
            },
            {
                "value": 23986,
                "label": "Doix",
                "label_ar": "دويكس",
                "label_fr": "Doix"
            },
            {
                "value": 23987,
                "label": "Champagne",
                "label_ar": "شامبانيا",
                "label_fr": "Champagne"
            },
            {
                "value": 23988,
                "label": "Joue-sur-Erdre",
                "label_ar": "جو سور إردري",
                "label_fr": "Joue-sur-Erdre"
            },
            {
                "value": 23989,
                "label": "Landeronde",
                "label_ar": "لانديروند",
                "label_fr": "Landeronde"
            },
            {
                "value": 23990,
                "label": "Rochetrejoux",
                "label_ar": "Rochetrejoux",
                "label_fr": "Rochetrejoux"
            },
            {
                "value": 23991,
                "label": "Loge-Fougereuse",
                "label_ar": "Loge-Fougereuse",
                "label_fr": "Loge-Fougereuse"
            },
            {
                "value": 23992,
                "label": "Saint-Lyphard",
                "label_ar": "سانت ليفارد",
                "label_fr": "Saint-Lyphard"
            },
            {
                "value": 23993,
                "label": "Vigneux-de-Bretagne",
                "label_ar": "Vigneux-de-Bretagne",
                "label_fr": "Vigneux-de-Bretagne"
            },
            {
                "value": 23994,
                "label": "Le Bignon",
                "label_ar": "لو بينون",
                "label_fr": "Le Bignon"
            },
            {
                "value": 23995,
                "label": "Belleville-sur-Vie",
                "label_ar": "بيلفيل سور في",
                "label_fr": "Belleville-sur-Vie"
            },
            {
                "value": 23996,
                "label": "Ernee",
                "label_ar": "إرني",
                "label_fr": "Ernee"
            },
            {
                "value": 23997,
                "label": "Saint-Sylvain-d'Anjou",
                "label_ar": "سان سيلفان دانجو",
                "label_fr": "Saint-Sylvain-d'Anjou"
            },
            {
                "value": 23998,
                "label": "Neuville-sur-Sarthe",
                "label_ar": "نيوفيل سور سارث",
                "label_fr": "Neuville-sur-Sarthe"
            },
            {
                "value": 23999,
                "label": "Savigne-sous-le-Lude",
                "label_ar": "Savigne-sous-le-Lude",
                "label_fr": "Savigne-sous-le-Lude"
            },
            {
                "value": 24000,
                "label": "Landevieille",
                "label_ar": "لانديفييل",
                "label_fr": "Landevieille"
            },
            {
                "value": 24001,
                "label": "Saint-Clement-des-Levees",
                "label_ar": "سان كليمان دي ليفيس",
                "label_fr": "Saint-Clément-des-Levées"
            },
            {
                "value": 24002,
                "label": "Sable-sur-Sarthe",
                "label_ar": "سابل سور سارث",
                "label_fr": "Sable-sur-Sarthe"
            },
            {
                "value": 24003,
                "label": "Beauvoir-sur-Mer",
                "label_ar": "بوفوار سور مير",
                "label_fr": "Beauvoir-sur-Mer"
            },
            {
                "value": 24004,
                "label": "Heric",
                "label_ar": "هيريك",
                "label_fr": "Heric"
            },
            {
                "value": 24005,
                "label": "Blaison-Gohier",
                "label_ar": "بليسون جوهير",
                "label_fr": "Blaison-Gohier"
            },
            {
                "value": 24006,
                "label": "Monce-en-Belin",
                "label_ar": "مونسي إن بيلين",
                "label_fr": "Monce-en-Belin"
            },
            {
                "value": 24007,
                "label": "La Limouziniere",
                "label_ar": "لا ليموزينيير",
                "label_fr": "La Limouzinière"
            },
            {
                "value": 24008,
                "label": "Bois",
                "label_ar": "بوا",
                "label_fr": "Bois"
            },
            {
                "value": 24009,
                "label": "Villeveque",
                "label_ar": "فيلفيك",
                "label_fr": "Villevèque"
            },
            {
                "value": 24010,
                "label": "Mayenne",
                "label_ar": "مايين",
                "label_fr": "Mayenne"
            },
            {
                "value": 24011,
                "label": "Segrie",
                "label_ar": "سيجري",
                "label_fr": "Segrie"
            },
            {
                "value": 24012,
                "label": "La Mothe-Saint-Heray",
                "label_ar": "لا موث سانت هيراي",
                "label_fr": "La Mothe-Saint-Heray"
            },
            {
                "value": 24013,
                "label": "Vion",
                "label_ar": "فيون",
                "label_fr": "Vion"
            },
            {
                "value": 24014,
                "label": "Vezins",
                "label_ar": "فيزين",
                "label_fr": "Vezins"
            },
            {
                "value": 24015,
                "label": "Saint-Florent-le-Vieil",
                "label_ar": "سانت فلوران لو فييل",
                "label_fr": "Saint-Florent-le-Vieil"
            },
            {
                "value": 24016,
                "label": "Pissotte",
                "label_ar": "بيسوت",
                "label_fr": "Pissotte"
            },
            {
                "value": 24017,
                "label": "Mars",
                "label_ar": "المريخ",
                "label_fr": "Mars"
            },
            {
                "value": 24018,
                "label": "Crespin",
                "label_ar": "كريسبين",
                "label_fr": "Crespin"
            },
            {
                "value": 24019,
                "label": "Les Herbiers",
                "label_ar": "ليه هيربير",
                "label_fr": "Les Herbiers"
            },
            {
                "value": 24020,
                "label": "Saint-Saturnin-du-Limet",
                "label_ar": "سان ساتورنين دو ليميت",
                "label_fr": "Saint-Saturnin-du-Limet"
            },
            {
                "value": 24021,
                "label": "Longeville-sur-Mer",
                "label_ar": "لونجفيل سور مير",
                "label_fr": "Longeville-sur-Mer"
            },
            {
                "value": 24022,
                "label": "La Renaudiere",
                "label_ar": "لا رينودير",
                "label_fr": "La Renaudière"
            },
            {
                "value": 24023,
                "label": "Les Clouzeaux",
                "label_ar": "لي كلوزو",
                "label_fr": "Les Clouzeaux"
            },
            {
                "value": 24024,
                "label": "Dollon",
                "label_ar": "دولون",
                "label_fr": "Dollon"
            },
            {
                "value": 24025,
                "label": "Maisdon-sur-Sevre",
                "label_ar": "Maisdon-sur-Sevre",
                "label_fr": "Maisdon-sur-Sèvre"
            },
            {
                "value": 24026,
                "label": "Frossay",
                "label_ar": "فروساي",
                "label_fr": "Frossay"
            },
            {
                "value": 24027,
                "label": "Lucon",
                "label_ar": "لوكون",
                "label_fr": "Lucon"
            },
            {
                "value": 24028,
                "label": "Saint-Philbert-de-Grand-Lieu",
                "label_ar": "سان فيلبرت دي جراند ليو",
                "label_fr": "Saint-Philbert-de-Grand-Lieu"
            },
            {
                "value": 24029,
                "label": "Sainte-Flaive-des-Loups",
                "label_ar": "سانت فلايف دي لوب",
                "label_fr": "Sainte-Flaive-des-Loups"
            },
            {
                "value": 24030,
                "label": "Guerande",
                "label_ar": "جيراند",
                "label_fr": "Guérande"
            },
            {
                "value": 24031,
                "label": "La Bernerie-en-Retz",
                "label_ar": "لا بيرنيري أون ريتز",
                "label_fr": "La Bernerie-en-Retz"
            },
            {
                "value": 24032,
                "label": "Voutre",
                "label_ar": "فوتري",
                "label_fr": "Voutre"
            },
            {
                "value": 24033,
                "label": "Sigournais",
                "label_ar": "سيغورنايس",
                "label_fr": "Sigournais"
            },
            {
                "value": 24034,
                "label": "Marigne",
                "label_ar": "مارين",
                "label_fr": "Marigne"
            },
            {
                "value": 24035,
                "label": "Noyant-la-Gravoyere",
                "label_ar": "نويانت لا جرافوير",
                "label_fr": "Noyant-la-Gravoyère"
            },
            {
                "value": 24036,
                "label": "Ligne",
                "label_ar": "لين",
                "label_fr": "Ligne"
            },
            {
                "value": 24037,
                "label": "Arnage",
                "label_ar": "أرناج",
                "label_fr": "Arnage"
            },
            {
                "value": 24038,
                "label": "Petit-Mars",
                "label_ar": "بيتي مارس",
                "label_fr": "Petit-Mars"
            },
            {
                "value": 24039,
                "label": "Prinquiau",
                "label_ar": "برينكيو",
                "label_fr": "Prinquiau"
            },
            {
                "value": 24040,
                "label": "Saint-Andre-des-Eaux",
                "label_ar": "سان أندريه دي أو",
                "label_fr": "Saint-André-des-Eaux"
            },
            {
                "value": 24041,
                "label": "La Haye",
                "label_ar": "لا هاي",
                "label_fr": "La Haye"
            },
            {
                "value": 24042,
                "label": "Donges",
                "label_ar": "دونغس",
                "label_fr": "Donges"
            },
            {
                "value": 24043,
                "label": "Cerans-Foulletourte",
                "label_ar": "كيران فولليتورت",
                "label_fr": "Cérans-Foulletourte"
            },
            {
                "value": 24044,
                "label": "Le Fuilet",
                "label_ar": "لو فويليت",
                "label_fr": "Le Fuilet"
            },
            {
                "value": 24045,
                "label": "Venansault",
                "label_ar": "فينانسولت",
                "label_fr": "Venansault"
            },
            {
                "value": 24046,
                "label": "Coron",
                "label_ar": "كورون",
                "label_fr": "Coron"
            },
            {
                "value": 24047,
                "label": "Le Fenouiller",
                "label_ar": "لو فينويلر",
                "label_fr": "Le Fenouiller"
            },
            {
                "value": 24048,
                "label": "La Guyonniere",
                "label_ar": "لا جويونيير",
                "label_fr": "La Guyonnière"
            },
            {
                "value": 24049,
                "label": "Corne",
                "label_ar": "كورن",
                "label_fr": "Corne"
            },
            {
                "value": 24050,
                "label": "Montjean-sur-Loire",
                "label_ar": "مونتجين سور لوار",
                "label_fr": "Montjean-sur-Loire"
            },
            {
                "value": 24051,
                "label": "Spay",
                "label_ar": "سباي",
                "label_fr": "Stériliser"
            },
            {
                "value": 24052,
                "label": "Noyant-la-Plaine",
                "label_ar": "نويانت لا بلين",
                "label_fr": "Noyant-la-Plaine"
            },
            {
                "value": 24053,
                "label": "Martigne-Briand",
                "label_ar": "Martigne-Briand",
                "label_fr": "Martigne-Briand"
            },
            {
                "value": 24054,
                "label": "Brigne",
                "label_ar": "برين",
                "label_fr": "Brigné"
            },
            {
                "value": 24055,
                "label": "Batz-sur-Mer",
                "label_ar": "باتز سور مير",
                "label_fr": "Batz-sur-Mer"
            },
            {
                "value": 24056,
                "label": "Vern-d'Anjou",
                "label_ar": "فيرن دانجو",
                "label_fr": "Vern-d'Anjou"
            },
            {
                "value": 24057,
                "label": "Nalliers",
                "label_ar": "نالييرز",
                "label_fr": "Nalliers"
            },
            {
                "value": 24058,
                "label": "Saint-Marceau",
                "label_ar": "سان مارسو",
                "label_fr": "Saint-Marceau"
            },
            {
                "value": 24059,
                "label": "Saint-Philbert-du-Pont-Charrault",
                "label_ar": "سان فيلبرت دو بونت شارولت",
                "label_fr": "Saint-Philbert-du-Pont-Charrault"
            },
            {
                "value": 24060,
                "label": "Saint-Gildas-des-Bois",
                "label_ar": "سان جيلداس دي بوا",
                "label_fr": "Saint-Gildas-des-Bois"
            },
            {
                "value": 24061,
                "label": "Sept-Forges",
                "label_ar": "سبتمبر فورج",
                "label_fr": "Sept-Forges"
            },
            {
                "value": 24062,
                "label": "Saint-Hilaire-de-Riez",
                "label_ar": "سان هيلير دي رييز",
                "label_fr": "Saint-Hilaire-de-Riez"
            },
            {
                "value": 24063,
                "label": "La Planche",
                "label_ar": "لا بلانش",
                "label_fr": "La Planche"
            },
            {
                "value": 24064,
                "label": "Mezieres-sous-Lavardin",
                "label_ar": "Mezieres-sous-Lavardin",
                "label_fr": "Mézières-sous-Lavardin"
            },
            {
                "value": 24065,
                "label": "Le Croisic",
                "label_ar": "لو كرواسيك",
                "label_fr": "Le Croisic"
            },
            {
                "value": 24066,
                "label": "Tillieres",
                "label_ar": "تيليريس",
                "label_fr": "Tillières"
            },
            {
                "value": 24067,
                "label": "Montreuil",
                "label_ar": "مونتروي",
                "label_fr": "Montreuil"
            },
            {
                "value": 24068,
                "label": "Mars-sur-Allier",
                "label_ar": "مارس سور أليير",
                "label_fr": "Mars-sur-Allier"
            },
            {
                "value": 24069,
                "label": "Soulitre",
                "label_ar": "سوليتر",
                "label_fr": "Soulitre"
            },
            {
                "value": 24070,
                "label": "Le Plessis-Grammoire",
                "label_ar": "لو بليسيس غراموار",
                "label_fr": "Le Plessis-Grammoire"
            },
            {
                "value": 24071,
                "label": "Dompierre-sur-Yon",
                "label_ar": "دومبيير سور يون",
                "label_fr": "Dompierre-sur-Yon"
            },
            {
                "value": 24072,
                "label": "Treize-Septiers",
                "label_ar": "تريز سيبتيرس",
                "label_fr": "Treize-Septiers"
            },
            {
                "value": 24073,
                "label": "La Haie",
                "label_ar": "لا هاي",
                "label_fr": "La Haie"
            },
            {
                "value": 24074,
                "label": "Sainte-Gemme-la-Plaine",
                "label_ar": "سانت جيم لا بلين",
                "label_fr": "Sainte-Gemme-la-Plaine"
            },
            {
                "value": 24075,
                "label": "Fontaine",
                "label_ar": "فونتين",
                "label_fr": "Fontaine"
            },
            {
                "value": 24076,
                "label": "Meslay-du-Maine",
                "label_ar": "ميسلاي دو مين",
                "label_fr": "Meslay-du-Maine"
            },
            {
                "value": 24077,
                "label": "Froidfond",
                "label_ar": "فرويدفوند",
                "label_fr": "Froidfond"
            },
            {
                "value": 24078,
                "label": "La Grigonnais",
                "label_ar": "لا غريغونيه",
                "label_fr": "La Grigonnais"
            },
            {
                "value": 24079,
                "label": "Ruille-Froid-Fonds",
                "label_ar": "رويل فرويد فوند",
                "label_fr": "Ruille-Froid-Fonds"
            },
            {
                "value": 24080,
                "label": "Ponts",
                "label_ar": "بونتس",
                "label_fr": "Ponts"
            },
            {
                "value": 24081,
                "label": "Louresse-Rochemenier",
                "label_ar": "لوريس روشيمنير",
                "label_fr": "Louresse-Rochemenier"
            },
            {
                "value": 24082,
                "label": "Martinet",
                "label_ar": "مارتينيت",
                "label_fr": "Martinet"
            },
            {
                "value": 24083,
                "label": "Pouzauges",
                "label_ar": "البوزوج",
                "label_fr": "Pouzauges"
            },
            {
                "value": 24084,
                "label": "Saint-Lumine-de-Clisson",
                "label_ar": "سان لومين دي كليسون",
                "label_fr": "Saint-Lumine-de-Clisson"
            },
            {
                "value": 24085,
                "label": "Chateauneuf",
                "label_ar": "شاتونوف",
                "label_fr": "Châteauneuf"
            },
            {
                "value": 24086,
                "label": "Les Alleuds",
                "label_ar": "ليه Alleuds",
                "label_fr": "Les Alleuds"
            },
            {
                "value": 24087,
                "label": "Bouvron",
                "label_ar": "بوفرون",
                "label_fr": "Bouvron"
            },
            {
                "value": 24088,
                "label": "Sainte-Florence",
                "label_ar": "سانت فلورنس",
                "label_fr": "Sainte-Florence"
            },
            {
                "value": 24089,
                "label": "Pierre",
                "label_ar": "بيير",
                "label_fr": "Pierre"
            },
            {
                "value": 24090,
                "label": "Landemont",
                "label_ar": "لاندمونت",
                "label_fr": "Landemont"
            },
            {
                "value": 24091,
                "label": "La Roche-sur-Yon",
                "label_ar": "لاروش سور يون",
                "label_fr": "La Roche-sur-Yon"
            },
            {
                "value": 24092,
                "label": "Disse-sous-Ballon",
                "label_ar": "Disse-sous-Ballon",
                "label_fr": "Disse-sous-Ballon"
            },
            {
                "value": 24093,
                "label": "Tregunc",
                "label_ar": "تريغونج",
                "label_fr": "Tregunc"
            },
            {
                "value": 24094,
                "label": "Ploemeur",
                "label_ar": "بلويمور",
                "label_fr": "Ploemeur"
            },
            {
                "value": 24095,
                "label": "Plougoumelen",
                "label_ar": "بلوجوميلين",
                "label_fr": "Plougoumelen"
            },
            {
                "value": 24096,
                "label": "Le Sourn",
                "label_ar": "لو سورن",
                "label_fr": "Le Sourn"
            },
            {
                "value": 24097,
                "label": "Erce-en-Lamee",
                "label_ar": "إرس-إن-لمي",
                "label_fr": "Erce-en-Lamee"
            },
            {
                "value": 24098,
                "label": "Saint-Ave",
                "label_ar": "سانت افي",
                "label_fr": "Saint-Ave"
            },
            {
                "value": 24099,
                "label": "Silfiac",
                "label_ar": "سيلفياك",
                "label_fr": "Silfiac"
            },
            {
                "value": 24100,
                "label": "Romagne",
                "label_ar": "رومان",
                "label_fr": "Romagne"
            },
            {
                "value": 24101,
                "label": "Morlaix",
                "label_ar": "مورلايكس",
                "label_fr": "Morlaix"
            },
            {
                "value": 24102,
                "label": "Bretagne",
                "label_ar": "بريتاني",
                "label_fr": "Bretagne"
            },
            {
                "value": 24103,
                "label": "Saint-Malo-en-Donziois",
                "label_ar": "سان مالو أون دونزيوا",
                "label_fr": "Saint-Malo-en-Donziois"
            },
            {
                "value": 24104,
                "label": "Saint-Bihy",
                "label_ar": "سانت بيهي",
                "label_fr": "Saint-Bihy"
            },
            {
                "value": 24105,
                "label": "Chateauneuf-du-Faou",
                "label_ar": "Chateauneuf-du-Faou",
                "label_fr": "Châteauneuf-du-Faou"
            },
            {
                "value": 24106,
                "label": "Hennebont",
                "label_ar": "هينيبونت",
                "label_fr": "Hennebont"
            },
            {
                "value": 24107,
                "label": "La Forest-Landerneau",
                "label_ar": "لا فورست لاندرنو",
                "label_fr": "La Forest-Landerneau"
            },
            {
                "value": 24108,
                "label": "La Fresnais",
                "label_ar": "لا فريسنيه",
                "label_fr": "La Fresnais"
            },
            {
                "value": 24109,
                "label": "Plaintel",
                "label_ar": "دعوى",
                "label_fr": "Plaintel"
            },
            {
                "value": 24110,
                "label": "Le Ferre",
                "label_ar": "لو فيري",
                "label_fr": "Le Ferre"
            },
            {
                "value": 24111,
                "label": "Acigne",
                "label_ar": "أسين",
                "label_fr": "Acigne"
            },
            {
                "value": 24112,
                "label": "Landerneau",
                "label_ar": "لاندرنو",
                "label_fr": "Landerneau"
            },
            {
                "value": 24113,
                "label": "Saint-Urbain",
                "label_ar": "سان اوربان",
                "label_fr": "Saint-Urbain"
            },
            {
                "value": 24114,
                "label": "Noyal-Muzillac",
                "label_ar": "نويال موزيلاك",
                "label_fr": "Noyal-Muzillac"
            },
            {
                "value": 24115,
                "label": "Briec",
                "label_ar": "بريك",
                "label_fr": "Briec"
            },
            {
                "value": 24116,
                "label": "Plerin",
                "label_ar": "بليرين",
                "label_fr": "Plérine"
            },
            {
                "value": 24117,
                "label": "Hanvec",
                "label_ar": "هانفيك",
                "label_fr": "Hanvec"
            },
            {
                "value": 24118,
                "label": "Saint-Yvi",
                "label_ar": "سانت إيفي",
                "label_fr": "Saint-Yvi"
            },
            {
                "value": 24119,
                "label": "Scaer",
                "label_ar": "صقل",
                "label_fr": "Scaer"
            },
            {
                "value": 24120,
                "label": "Melgven",
                "label_ar": "ميلغين",
                "label_fr": "Melgven"
            },
            {
                "value": 24121,
                "label": "Rosporden",
                "label_ar": "روسبوردن",
                "label_fr": "Rosporden"
            },
            {
                "value": 24122,
                "label": "Baud",
                "label_ar": "الباود",
                "label_fr": "Baud"
            },
            {
                "value": 24123,
                "label": "Lannilis",
                "label_ar": "لانيليس",
                "label_fr": "Lannilis"
            },
            {
                "value": 24124,
                "label": "Dol-de-Bretagne",
                "label_ar": "دول دي بريتاني",
                "label_fr": "Dol-de-Bretagne"
            },
            {
                "value": 24125,
                "label": "Plouneour-Trez",
                "label_ar": "بلونيور تريز",
                "label_fr": "Plouneour-Trez"
            },
            {
                "value": 24126,
                "label": "Plougasnou",
                "label_ar": "بلوجاسنو",
                "label_fr": "Plougasnou"
            },
            {
                "value": 24127,
                "label": "Penestin",
                "label_ar": "بنستين",
                "label_fr": "Penestin"
            },
            {
                "value": 24128,
                "label": "Poullaouen",
                "label_ar": "بولاون",
                "label_fr": "Poullaouen"
            },
            {
                "value": 24129,
                "label": "Riec-sur-Belon",
                "label_ar": "ريك سور بيلون",
                "label_fr": "Riec-sur-Belon"
            },
            {
                "value": 24130,
                "label": "Elven",
                "label_ar": "الجان",
                "label_fr": "Elfique"
            },
            {
                "value": 24131,
                "label": "Quelneuc",
                "label_ar": "Quelneuc",
                "label_fr": "Quelneuc"
            },
            {
                "value": 24132,
                "label": "Cournon",
                "label_ar": "كورنون",
                "label_fr": "Cournon"
            },
            {
                "value": 24133,
                "label": "Saint-Jean-sur-Vilaine",
                "label_ar": "سان جان سور فيلان",
                "label_fr": "Saint-Jean-sur-Vilaine"
            },
            {
                "value": 24134,
                "label": "Le Guerno",
                "label_ar": "لو غيرنو",
                "label_fr": "Le Guerno"
            },
            {
                "value": 24135,
                "label": "Guipavas",
                "label_ar": "جويبافاس",
                "label_fr": "Guipavas"
            },
            {
                "value": 24136,
                "label": "Saint-Tugdual",
                "label_ar": "سان توغدوال",
                "label_fr": "Saint-Tugdual"
            },
            {
                "value": 24137,
                "label": "Baden",
                "label_ar": "بادن",
                "label_fr": "Baden"
            },
            {
                "value": 24138,
                "label": "Caulnes",
                "label_ar": "كولينز",
                "label_fr": "Caulnes"
            },
            {
                "value": 24139,
                "label": "Cleder",
                "label_ar": "Cleder",
                "label_fr": "Cleder"
            },
            {
                "value": 24140,
                "label": "Villamee",
                "label_ar": "فيلام",
                "label_fr": "Villamee"
            },
            {
                "value": 24141,
                "label": "Iffendic",
                "label_ar": "إيفنديك",
                "label_fr": "Iffendic"
            },
            {
                "value": 24142,
                "label": "Redene",
                "label_ar": "ريدين",
                "label_fr": "Redene"
            },
            {
                "value": 24143,
                "label": "Plouguin",
                "label_ar": "بلوجين",
                "label_fr": "Plouguin"
            },
            {
                "value": 24144,
                "label": "Surzur",
                "label_ar": "سرزور",
                "label_fr": "Surzur"
            },
            {
                "value": 24145,
                "label": "Plumeliau",
                "label_ar": "بلوميليو",
                "label_fr": "Plumeliau"
            },
            {
                "value": 24146,
                "label": "Saint-Cast-le-Guildo",
                "label_ar": "سان كاست لو جيلدو",
                "label_fr": "Saint-Cast-le-Guildo"
            },
            {
                "value": 24147,
                "label": "Plougonvelin",
                "label_ar": "بلوجونفيلين",
                "label_fr": "Plougonvelin"
            },
            {
                "value": 24148,
                "label": "Ergue-Gaberic",
                "label_ar": "إيرج جابريك",
                "label_fr": "Ergue-Gaberic"
            },
            {
                "value": 24149,
                "label": "Pleugriffet",
                "label_ar": "بليوجريفيت",
                "label_fr": "Pleugriffet"
            },
            {
                "value": 24150,
                "label": "Combourg",
                "label_ar": "كومبورغ",
                "label_fr": "Combourg"
            },
            {
                "value": 24151,
                "label": "Elliant",
                "label_ar": "إليانت",
                "label_fr": "Elliant"
            },
            {
                "value": 24152,
                "label": "Fougeres",
                "label_ar": "عطور الفوجير",
                "label_fr": "Fougères"
            },
            {
                "value": 24153,
                "label": "Lanmerin",
                "label_ar": "لانميرين",
                "label_fr": "Lanmerin"
            },
            {
                "value": 24154,
                "label": "Lamballe",
                "label_ar": "لامبال",
                "label_fr": "Lamballe"
            },
            {
                "value": 24155,
                "label": "Camors",
                "label_ar": "Camors",
                "label_fr": "Camors"
            },
            {
                "value": 24156,
                "label": "Lanester",
                "label_ar": "لانستر",
                "label_fr": "Lanester"
            },
            {
                "value": 24157,
                "label": "Nostang",
                "label_ar": "نوستانغ",
                "label_fr": "Nostang"
            },
            {
                "value": 24158,
                "label": "Ferel",
                "label_ar": "فيريل",
                "label_fr": "Ferel"
            },
            {
                "value": 24159,
                "label": "Plaine-Haute",
                "label_ar": "بلين أوت",
                "label_fr": "Plaine-Haute"
            },
            {
                "value": 24160,
                "label": "Ploemel",
                "label_ar": "بلويميل",
                "label_fr": "Ploemel"
            },
            {
                "value": 24161,
                "label": "Goudelin",
                "label_ar": "جوديلين",
                "label_fr": "Goudelin"
            },
            {
                "value": 24162,
                "label": "Yffiniac",
                "label_ar": "يفينياك",
                "label_fr": "Yffiniac"
            },
            {
                "value": 24163,
                "label": "Beauce",
                "label_ar": "بوس",
                "label_fr": "Beauce"
            },
            {
                "value": 24164,
                "label": "Kerpert",
                "label_ar": "كيربرت",
                "label_fr": "Kerpert"
            },
            {
                "value": 24165,
                "label": "Saint-Carreuc",
                "label_ar": "سان كاروك",
                "label_fr": "Saint-Carreuc"
            },
            {
                "value": 24166,
                "label": "Plouha",
                "label_ar": "بلوحة",
                "label_fr": "Plouha"
            },
            {
                "value": 24167,
                "label": "Clohars-Carnoet",
                "label_ar": "كلوهارس كارنويت",
                "label_fr": "Clohars-Carnoet"
            },
            {
                "value": 24168,
                "label": "Treguidel",
                "label_ar": "تريغيديل",
                "label_fr": "Treguidel"
            },
            {
                "value": 24169,
                "label": "Quéménéven",
                "label_ar": "Quéménéven",
                "label_fr": "Quéménéven"
            },
            {
                "value": 24170,
                "label": "Portrieux",
                "label_ar": "بورتريو",
                "label_fr": "Portrieux"
            },
            {
                "value": 24171,
                "label": "Guer",
                "label_ar": "غير",
                "label_fr": "Guer"
            },
            {
                "value": 24172,
                "label": "Henon",
                "label_ar": "هينون",
                "label_fr": "Henon"
            },
            {
                "value": 24173,
                "label": "Saint-Quay-Portrieux",
                "label_ar": "سان كواي بورتريو",
                "label_fr": "Saint-Quay-Portrieux"
            },
            {
                "value": 24174,
                "label": "Taule",
                "label_ar": "تول",
                "label_fr": "Taule"
            },
            {
                "value": 24175,
                "label": "Kervignac",
                "label_ar": "كيرفينياك",
                "label_fr": "Kervignac"
            },
            {
                "value": 24176,
                "label": "Tremuson",
                "label_ar": "تريمسون",
                "label_fr": "Tremuson"
            },
            {
                "value": 24177,
                "label": "Pluguffan",
                "label_ar": "قابس",
                "label_fr": "Pluguffan"
            },
            {
                "value": 24178,
                "label": "Le Trevoux",
                "label_ar": "لو تريفوكس",
                "label_fr": "Le Trévoux"
            },
            {
                "value": 24179,
                "label": "Pleyben",
                "label_ar": "بليبين",
                "label_fr": "Pleyben"
            },
            {
                "value": 24180,
                "label": "Mur-de-Bretagne",
                "label_ar": "مور دي بريتاني",
                "label_fr": "Mur-de-Bretagne"
            },
            {
                "value": 24181,
                "label": "Montauban-de-Bretagne",
                "label_ar": "مونتوبان دي بريتاني",
                "label_fr": "Montauban-de-Bretagne"
            },
            {
                "value": 24182,
                "label": "Ploufragan",
                "label_ar": "بلوفراجان",
                "label_fr": "Ploufragan"
            },
            {
                "value": 24183,
                "label": "Treverien",
                "label_ar": "تريفيرين",
                "label_fr": "Treverien"
            },
            {
                "value": 24184,
                "label": "Roscoff",
                "label_ar": "روسكوف",
                "label_fr": "Roscoff"
            },
            {
                "value": 24185,
                "label": "Redon",
                "label_ar": "ريدون",
                "label_fr": "Redon"
            },
            {
                "value": 24186,
                "label": "Pont-Scorff",
                "label_ar": "بونت سكورف",
                "label_fr": "Pont-Scorff"
            },
            {
                "value": 24187,
                "label": "Le Relecq-Kerhuon",
                "label_ar": "لو ريليك كيرون",
                "label_fr": "Le Relecq-Kerhuon"
            },
            {
                "value": 24188,
                "label": "Langonnet",
                "label_ar": "لانغونيت",
                "label_fr": "Langonnet"
            },
            {
                "value": 24189,
                "label": "Pont-l'Abbé",
                "label_ar": "Pont-l'Abbé",
                "label_fr": "Pont-l'Abbé"
            },
            {
                "value": 24190,
                "label": "Ploudalmezeau",
                "label_ar": "بلودالميزو",
                "label_fr": "Ploudalmezeau"
            },
            {
                "value": 24191,
                "label": "Geveze",
                "label_ar": "جيفيز",
                "label_fr": "Geveze"
            },
            {
                "value": 24192,
                "label": "Saint-Pabu",
                "label_ar": "سان بابو",
                "label_fr": "Saint-Pabu"
            },
            {
                "value": 24193,
                "label": "Saint-Domineuc",
                "label_ar": "سان دومينوك",
                "label_fr": "Saint-Domineuc"
            },
            {
                "value": 24194,
                "label": "Trebeurden",
                "label_ar": "تريبوردن",
                "label_fr": "Trébeurden"
            },
            {
                "value": 24195,
                "label": "Missiriac",
                "label_ar": "Missiriac",
                "label_fr": "Missiriac"
            },
            {
                "value": 24196,
                "label": "Medreac",
                "label_ar": "ميدريك",
                "label_fr": "Medreac"
            },
            {
                "value": 24197,
                "label": "Argentre-du-Plessis",
                "label_ar": "آرجنتري دو بليسيس",
                "label_fr": "Argentre-du-Plessis"
            },
            {
                "value": 24198,
                "label": "Treduder",
                "label_ar": "تريودر",
                "label_fr": "Treduder"
            },
            {
                "value": 24199,
                "label": "Combrit",
                "label_ar": "كومبريت",
                "label_fr": "Combrit"
            },
            {
                "value": 24200,
                "label": "Gomene",
                "label_ar": "جومين",
                "label_fr": "Gomene"
            },
            {
                "value": 24201,
                "label": "Plouaret",
                "label_ar": "بلوارت",
                "label_fr": "Plouaret"
            },
            {
                "value": 24202,
                "label": "Lanvollon",
                "label_ar": "لانفولون",
                "label_fr": "Lanvollon"
            },
            {
                "value": 24203,
                "label": "Trebry",
                "label_ar": "تريبري",
                "label_fr": "Trebry"
            },
            {
                "value": 24204,
                "label": "Sulniac",
                "label_ar": "سولنياك",
                "label_fr": "Sulniac"
            },
            {
                "value": 24205,
                "label": "Bruz",
                "label_ar": "بروز",
                "label_fr": "Bruz"
            },
            {
                "value": 24206,
                "label": "Lanouee",
                "label_ar": "لانوي",
                "label_fr": "Lanouee"
            },
            {
                "value": 24207,
                "label": "Ploeren",
                "label_ar": "بلويرين",
                "label_fr": "Ploeren"
            },
            {
                "value": 24208,
                "label": "Saint-Jean-la-Poterie",
                "label_ar": "سان جان لا بوتيري",
                "label_fr": "Saint-Jean-la-Poterie"
            },
            {
                "value": 24209,
                "label": "Betton",
                "label_ar": "بيتون",
                "label_fr": "Betton"
            },
            {
                "value": 24210,
                "label": "Guilvinec",
                "label_ar": "جيلفينيك",
                "label_fr": "Guilvinec"
            },
            {
                "value": 24211,
                "label": "Domagne",
                "label_ar": "دوماني",
                "label_fr": "Domagne"
            },
            {
                "value": 24212,
                "label": "Peillac",
                "label_ar": "بيلاك",
                "label_fr": "Peillac"
            },
            {
                "value": 24213,
                "label": "Ploubalay",
                "label_ar": "بلوبالاي",
                "label_fr": "Ploubalay"
            },
            {
                "value": 24214,
                "label": "Plescop",
                "label_ar": "بليسكوب",
                "label_fr": "Plescop"
            },
            {
                "value": 24215,
                "label": "Saint-Adrien",
                "label_ar": "سان ادريان",
                "label_fr": "Saint-Adrien"
            },
            {
                "value": 24216,
                "label": "Vezin-le-Coquet",
                "label_ar": "فيزين لو كوكيه",
                "label_fr": "Vézin-le-Coquet"
            },
            {
                "value": 24217,
                "label": "Domalain",
                "label_ar": "دومالاين",
                "label_fr": "Domalain"
            },
            {
                "value": 24218,
                "label": "Fouesnant",
                "label_ar": "فوسنانت",
                "label_fr": "Fouesnant"
            },
            {
                "value": 24219,
                "label": "Gouezec",
                "label_ar": "جويزيك",
                "label_fr": "Gouezec"
            },
            {
                "value": 24220,
                "label": "Crehen",
                "label_ar": "كريين",
                "label_fr": "Crehen"
            },
            {
                "value": 24221,
                "label": "Mael-Carhaix",
                "label_ar": "Mael-Carhaix",
                "label_fr": "Mael-Carhaix"
            },
            {
                "value": 24222,
                "label": "Pluduno",
                "label_ar": "بلودونو",
                "label_fr": "Pluduno"
            },
            {
                "value": 24223,
                "label": "Locmaria-Plouzane",
                "label_ar": "لوكماريا بلوزان",
                "label_fr": "Locmaria-Plouzane"
            },
            {
                "value": 24224,
                "label": "Saint-Agathon",
                "label_ar": "القديس أغاثون",
                "label_fr": "Saint-Agathon"
            },
            {
                "value": 24225,
                "label": "Pont-de-Buis-les-Quimerch",
                "label_ar": "Pont-de-Buis-les-Quimerch",
                "label_fr": "Pont-de-Buis-les-Quimerch"
            },
            {
                "value": 24226,
                "label": "Plaudren",
                "label_ar": "بلودرين",
                "label_fr": "Plaudren"
            },
            {
                "value": 24227,
                "label": "Saint-Senoux",
                "label_ar": "سان سينوكس",
                "label_fr": "Saint-Senoux"
            },
            {
                "value": 24228,
                "label": "Erdeven",
                "label_ar": "إردفن",
                "label_fr": "Erdeven"
            },
            {
                "value": 24229,
                "label": "Saint-Renan",
                "label_ar": "سانت رينان",
                "label_fr": "Saint-Renan"
            },
            {
                "value": 24230,
                "label": "Moncontour",
                "label_ar": "مونكونتور",
                "label_fr": "Moncontour"
            },
            {
                "value": 24231,
                "label": "Saint-Pierre-la-Cour",
                "label_ar": "سان بيير لا كور",
                "label_fr": "Saint-Pierre-la-Cour"
            },
            {
                "value": 24232,
                "label": "Liffre",
                "label_ar": "ليفر",
                "label_fr": "Liffre"
            },
            {
                "value": 24233,
                "label": "Reguiny",
                "label_ar": "ريجيني",
                "label_fr": "Reguiny"
            },
            {
                "value": 24234,
                "label": "Brandivy",
                "label_ar": "برانديفي",
                "label_fr": "Brandivy"
            },
            {
                "value": 24235,
                "label": "Plumaugat",
                "label_ar": "بلوموجات",
                "label_fr": "Plumaugat"
            },
            {
                "value": 24236,
                "label": "Ploerdut",
                "label_ar": "بلوردوت",
                "label_fr": "Ploerdut"
            },
            {
                "value": 24237,
                "label": "Corseul",
                "label_ar": "كورسول",
                "label_fr": "Corseul"
            },
            {
                "value": 24238,
                "label": "Laille",
                "label_ar": "ليل",
                "label_fr": "Laille"
            },
            {
                "value": 24239,
                "label": "Locmariaquer",
                "label_ar": "لوكمارياكير",
                "label_fr": "Locmariaquer"
            },
            {
                "value": 24240,
                "label": "Treffendel",
                "label_ar": "تريفندل",
                "label_fr": "Treffendel"
            },
            {
                "value": 24241,
                "label": "Plelan-le-Grand",
                "label_ar": "بللان لو جراند",
                "label_fr": "Plelan-le-Grand"
            },
            {
                "value": 24242,
                "label": "Janze",
                "label_ar": "جانز",
                "label_fr": "Janze"
            },
            {
                "value": 24243,
                "label": "Guignen",
                "label_ar": "غوينين",
                "label_fr": "Guignen"
            },
            {
                "value": 24244,
                "label": "Plabennec",
                "label_ar": "بلابينيك",
                "label_fr": "Plabennec"
            },
            {
                "value": 24245,
                "label": "Tinteniac",
                "label_ar": "تينتينياك",
                "label_fr": "Tinténiac"
            },
            {
                "value": 24246,
                "label": "Le Faou",
                "label_ar": "لو فو",
                "label_fr": "Le Faou"
            },
            {
                "value": 24247,
                "label": "Lokmaria",
                "label_ar": "Lokmaria",
                "label_fr": "Lokmaria"
            },
            {
                "value": 24248,
                "label": "Begard",
                "label_ar": "التسول",
                "label_fr": "Bégard"
            },
            {
                "value": 24249,
                "label": "Ploudaniel",
                "label_ar": "بلودانيال",
                "label_fr": "Ploudaniel"
            },
            {
                "value": 24250,
                "label": "Plestin-les-Greves",
                "label_ar": "بليستين ليه جريفز",
                "label_fr": "Plestin-les-Grèves"
            },
            {
                "value": 24251,
                "label": "Saint-Derrien",
                "label_ar": "سان ديرين",
                "label_fr": "Saint-Derrien"
            },
            {
                "value": 24252,
                "label": "Maure-de-Bretagne",
                "label_ar": "موور دي بريتاني",
                "label_fr": "Maure-de-Bretagne"
            },
            {
                "value": 24253,
                "label": "Plumelin",
                "label_ar": "بلوميلين",
                "label_fr": "Pluméline"
            },
            {
                "value": 24254,
                "label": "Plelan-le-Petit",
                "label_ar": "بللان لو بيتي",
                "label_fr": "Plelan-le-Petit"
            },
            {
                "value": 24255,
                "label": "Le Verger",
                "label_ar": "لو فيرجير",
                "label_fr": "Le Verger"
            },
            {
                "value": 24256,
                "label": "Andouille-Neuville",
                "label_ar": "أندويل نوفيل",
                "label_fr": "Andouille-Neuville"
            },
            {
                "value": 24257,
                "label": "Lennon",
                "label_ar": "لينون",
                "label_fr": "Lennon"
            },
            {
                "value": 24258,
                "label": "Spezet",
                "label_ar": "سبيزيت",
                "label_fr": "Spezet"
            },
            {
                "value": 24259,
                "label": "Minihy-Treguier",
                "label_ar": "Minihy-Treguier",
                "label_fr": "Minihy-Tréguier"
            },
            {
                "value": 24260,
                "label": "Pleucadeuc",
                "label_ar": "بليوكاديوك",
                "label_fr": "Pleucadeuc"
            },
            {
                "value": 24261,
                "label": "Henvic",
                "label_ar": "Henvic",
                "label_fr": "Henvic"
            },
            {
                "value": 24262,
                "label": "Taillis",
                "label_ar": "Taillis",
                "label_fr": "Taillis"
            },
            {
                "value": 24263,
                "label": "Lignol",
                "label_ar": "لينول",
                "label_fr": "Lignol"
            },
            {
                "value": 24264,
                "label": "Saint-Divy",
                "label_ar": "سانت ديفي",
                "label_fr": "Saint-Divy"
            },
            {
                "value": 24265,
                "label": "Guenin",
                "label_ar": "قنين",
                "label_fr": "Guenin"
            },
            {
                "value": 24266,
                "label": "Pont-Sainte-Maxence",
                "label_ar": "بونت سانت ماكسينس",
                "label_fr": "Pont-Sainte-Maxence"
            },
            {
                "value": 24267,
                "label": "Bonnemain",
                "label_ar": "بونمين",
                "label_fr": "Bonnemain"
            },
            {
                "value": 24268,
                "label": "Chateaubourg",
                "label_ar": "شاتوبورج",
                "label_fr": "Châteaubourg"
            },
            {
                "value": 24269,
                "label": "Saint-Meen-le-Grand",
                "label_ar": "سان مين لو جراند",
                "label_fr": "Saint-Meen-le-Grand"
            },
            {
                "value": 24270,
                "label": "Saint-Barnabe",
                "label_ar": "سان بارنابي",
                "label_fr": "Saint-Barnabe"
            },
            {
                "value": 24271,
                "label": "Lehon",
                "label_ar": "ليهون",
                "label_fr": "Léhon"
            },
            {
                "value": 24272,
                "label": "Botsorhel",
                "label_ar": "بوتسورهيل",
                "label_fr": "Botsorhel"
            },
            {
                "value": 24273,
                "label": "Chatelaudren",
                "label_ar": "شاتيلودرين",
                "label_fr": "Châtelaudren"
            },
            {
                "value": 24274,
                "label": "Pleneuf-Val-Andre",
                "label_ar": "بلينوف فال أندريه",
                "label_fr": "Pleneuf-Val-André"
            },
            {
                "value": 24275,
                "label": "Tregastel",
                "label_ar": "تريجاستيل",
                "label_fr": "Trégastel"
            },
            {
                "value": 24276,
                "label": "Carentoir",
                "label_ar": "كارينتير",
                "label_fr": "Carentoir"
            },
            {
                "value": 24277,
                "label": "Loctudy",
                "label_ar": "Loctudy",
                "label_fr": "Loctudy"
            },
            {
                "value": 24278,
                "label": "Gahard",
                "label_ar": "جاهارد",
                "label_fr": "Gahard"
            },
            {
                "value": 24279,
                "label": "Meslin",
                "label_ar": "ميسلين",
                "label_fr": "Meslin"
            },
            {
                "value": 24280,
                "label": "Vieux",
                "label_ar": "Vieux",
                "label_fr": "Vieux"
            },
            {
                "value": 24281,
                "label": "Plouer-sur-Rance",
                "label_ar": "بلور سور رانس",
                "label_fr": "Plouer-sur-Rance"
            },
            {
                "value": 24282,
                "label": "Hede-Bazouges",
                "label_ar": "هيدي بازوج",
                "label_fr": "Hede-Bazouges"
            },
            {
                "value": 24283,
                "label": "Kerlaz",
                "label_ar": "كيرلاز",
                "label_fr": "Kerlaz"
            },
            {
                "value": 24284,
                "label": "Plougourvest",
                "label_ar": "بلوجورفيست",
                "label_fr": "Plougourvest"
            },
            {
                "value": 24285,
                "label": "Crach",
                "label_ar": "كراش",
                "label_fr": "Crach"
            },
            {
                "value": 24286,
                "label": "Tregarantec",
                "label_ar": "تريجارانتك",
                "label_fr": "Tregarantec"
            },
            {
                "value": 24287,
                "label": "Plechatel",
                "label_ar": "بليشاتيل",
                "label_fr": "Plechatel"
            },
            {
                "value": 24288,
                "label": "Saint-Philibert",
                "label_ar": "سان فيليبرت",
                "label_fr": "Saint-Philibert"
            },
            {
                "value": 24289,
                "label": "Moelan-sur-Mer",
                "label_ar": "مولان سور مير",
                "label_fr": "Moelan-sur-Mer"
            },
            {
                "value": 24290,
                "label": "Montfort-sur-Meu",
                "label_ar": "مونتفورت سور ميو",
                "label_fr": "Montfort-sur-Meu"
            },
            {
                "value": 24291,
                "label": "Caudan",
                "label_ar": "كودان",
                "label_fr": "Caudan"
            },
            {
                "value": 24292,
                "label": "Squiffiec",
                "label_ar": "Squiffiec",
                "label_fr": "Squiffiec"
            },
            {
                "value": 24293,
                "label": "Plouneour-Menez",
                "label_ar": "بلونيور مينيز",
                "label_fr": "Plouneour-Menez"
            },
            {
                "value": 24294,
                "label": "Plougar",
                "label_ar": "بلوجار",
                "label_fr": "Plougar"
            },
            {
                "value": 24295,
                "label": "Gouesnou",
                "label_ar": "جويسنو",
                "label_fr": "Gouesnou"
            },
            {
                "value": 24296,
                "label": "Paris",
                "label_ar": "باريس",
                "label_fr": "Paris"
            },
            {
                "value": 24297,
                "label": "Pabu",
                "label_ar": "بابو",
                "label_fr": "Pabu"
            },
            {
                "value": 24298,
                "label": "Le Faouet",
                "label_ar": "لو فاويه",
                "label_fr": "Le Faouet"
            },
            {
                "value": 24299,
                "label": "Plogonnec",
                "label_ar": "بلوجونيك",
                "label_fr": "Plogonnec"
            },
            {
                "value": 24300,
                "label": "Messac",
                "label_ar": "ميساك",
                "label_fr": "Messac"
            },
            {
                "value": 24301,
                "label": "Pouldergat",
                "label_ar": "بولديرغات",
                "label_fr": "Pouldergat"
            },
            {
                "value": 24302,
                "label": "Mohon",
                "label_ar": "موهون",
                "label_fr": "Mohon"
            },
            {
                "value": 24303,
                "label": "Penvenan",
                "label_ar": "بينفينان",
                "label_fr": "Penvenan"
            },
            {
                "value": 24304,
                "label": "Quimperlé",
                "label_ar": "كيمبرلي",
                "label_fr": "Quimperlé"
            },
            {
                "value": 24305,
                "label": "Larmor-Plage",
                "label_ar": "لارمور بلاج",
                "label_fr": "Larmor-Plage"
            },
            {
                "value": 24306,
                "label": "Cleguer",
                "label_ar": "كليجير",
                "label_fr": "Cleguer"
            },
            {
                "value": 24307,
                "label": "Pommeret",
                "label_ar": "بوميريه",
                "label_fr": "Pommeret"
            },
            {
                "value": 24308,
                "label": "Crozon",
                "label_ar": "كروزون",
                "label_fr": "Crozon"
            },
            {
                "value": 24309,
                "label": "Etel",
                "label_ar": "Etel",
                "label_fr": "Etel"
            },
            {
                "value": 24310,
                "label": "Pleumeleuc",
                "label_ar": "بلوميلوك",
                "label_fr": "Pleumeleuc"
            },
            {
                "value": 24311,
                "label": "Belle-Isle-en-Terre",
                "label_ar": "بيل آيل أون تير",
                "label_fr": "Belle-Isle-en-Terre"
            },
            {
                "value": 24312,
                "label": "Audierne",
                "label_ar": "أودييرن",
                "label_fr": "Audierne"
            },
            {
                "value": 24313,
                "label": "Peaule",
                "label_ar": "بيول",
                "label_fr": "Peaule"
            },
            {
                "value": 24314,
                "label": "Bains-sur-Oust",
                "label_ar": "Bains-sur-Oust",
                "label_fr": "Bains-sur-Oust"
            },
            {
                "value": 24315,
                "label": "Plurien",
                "label_ar": "بلورين",
                "label_fr": "Plurien"
            },
            {
                "value": 24316,
                "label": "Plonevez-du-Faou",
                "label_ar": "بلونيفيز دو فو",
                "label_fr": "Plonévez-du-Faou"
            },
            {
                "value": 24317,
                "label": "Brusvily",
                "label_ar": "بروسفيلي",
                "label_fr": "Brusvily"
            },
            {
                "value": 24318,
                "label": "L'Hermitage",
                "label_ar": "ليرميتاج",
                "label_fr": "L'Hermitage"
            },
            {
                "value": 24319,
                "label": "Louargat",
                "label_ar": "لوارغات",
                "label_fr": "Louargat"
            },
            {
                "value": 24320,
                "label": "Illifaut",
                "label_ar": "اليفوت",
                "label_fr": "Illifaut"
            },
            {
                "value": 24321,
                "label": "Saint-Gerand",
                "label_ar": "سان جيران",
                "label_fr": "Saint-Gérand"
            },
            {
                "value": 24322,
                "label": "Châteaulin",
                "label_ar": "شاتيولين",
                "label_fr": "Châteaulin"
            },
            {
                "value": 24323,
                "label": "Saint-Coulomb",
                "label_ar": "سان كولوم",
                "label_fr": "Saint-Coulomb"
            },
            {
                "value": 24324,
                "label": "Yvias",
                "label_ar": "يفياس",
                "label_fr": "Yvias"
            },
            {
                "value": 24325,
                "label": "Bénodet",
                "label_ar": "بينوديت",
                "label_fr": "Bénodet"
            },
            {
                "value": 24326,
                "label": "Saint-Jean-Trolimon",
                "label_ar": "سان جان تروليمون",
                "label_fr": "Saint-Jean-Trolimon"
            },
            {
                "value": 24327,
                "label": "Louannec",
                "label_ar": "لوانك",
                "label_fr": "Louannec"
            },
            {
                "value": 24328,
                "label": "Noyal-Pontivy",
                "label_ar": "نويال بونتيفي",
                "label_fr": "Noyal-Pontivy"
            },
            {
                "value": 24329,
                "label": "Larmor-Baden",
                "label_ar": "لارمور بادن",
                "label_fr": "Larmor-Baden"
            },
            {
                "value": 24330,
                "label": "Beuzec-Cap-Sizun",
                "label_ar": "Beuzec-Cap-Sizun",
                "label_fr": "Beuzec-Cap-Sizun"
            },
            {
                "value": 24331,
                "label": "Ile-d'Arz",
                "label_ar": "إيل دارز",
                "label_fr": "Ile-d'Arz"
            },
            {
                "value": 24332,
                "label": "Chevaigne",
                "label_ar": "تشيفيني",
                "label_fr": "Chevaigne"
            },
            {
                "value": 24333,
                "label": "Planguenoual",
                "label_ar": "بلانغوينوال",
                "label_fr": "Planguenoual"
            },
            {
                "value": 24334,
                "label": "Plelauff",
                "label_ar": "بليلف",
                "label_fr": "Plelauff"
            },
            {
                "value": 24335,
                "label": "Saint-Nicolas-du-Pelem",
                "label_ar": "سان نيكولا دو بيليم",
                "label_fr": "Saint-Nicolas-du-Pelem"
            },
            {
                "value": 24336,
                "label": "Questembert",
                "label_ar": "كويستمبرت",
                "label_fr": "Questembert"
            },
            {
                "value": 24337,
                "label": "Plomeur",
                "label_ar": "بلومير",
                "label_fr": "Plomeur"
            },
            {
                "value": 24338,
                "label": "Le Foeil",
                "label_ar": "لو فويل",
                "label_fr": "Le Foeil"
            },
            {
                "value": 24339,
                "label": "Pont-Aven",
                "label_ar": "بونت آفين",
                "label_fr": "Pont-Aven"
            },
            {
                "value": 24340,
                "label": "Saint-Pol-de-Léon",
                "label_ar": "سان بول دي ليون",
                "label_fr": "Saint-Pol-de-Léon"
            },
            {
                "value": 24341,
                "label": "La Richardais",
                "label_ar": "لا ريتشارديس",
                "label_fr": "La Richardais"
            },
            {
                "value": 24342,
                "label": "Le Vieux Bourg",
                "label_ar": "لو فيو بورغ",
                "label_fr": "Le Vieux Bourg"
            },
            {
                "value": 24343,
                "label": "Balaze",
                "label_ar": "بالاز",
                "label_fr": "Balaze"
            },
            {
                "value": 24344,
                "label": "Plorec-sur-Arguenon",
                "label_ar": "بلوريك سور أرغوينون",
                "label_fr": "Plorec-sur-Arguenon"
            },
            {
                "value": 24345,
                "label": "Plessala",
                "label_ar": "بليسالا",
                "label_fr": "Plessala"
            },
            {
                "value": 24346,
                "label": "Paimpont",
                "label_ar": "بايمبون",
                "label_fr": "Paimpont"
            },
            {
                "value": 24347,
                "label": "Croixanvec",
                "label_ar": "كرواكسانفيك",
                "label_fr": "Croixanvec"
            },
            {
                "value": 24348,
                "label": "Billiers",
                "label_ar": "بيليرز",
                "label_fr": "Billiers"
            },
            {
                "value": 24349,
                "label": "Sauzon",
                "label_ar": "ساوزون",
                "label_fr": "Sauzon"
            },
            {
                "value": 24350,
                "label": "Plouharnel",
                "label_ar": "بلوهارنيل",
                "label_fr": "Plouharnel"
            },
            {
                "value": 24351,
                "label": "Landrevarzec",
                "label_ar": "لاندريفارزيك",
                "label_fr": "Landrevarzec"
            },
            {
                "value": 24352,
                "label": "Pludual",
                "label_ar": "محتد",
                "label_fr": "Pluduel"
            },
            {
                "value": 24353,
                "label": "Arradon",
                "label_ar": "ارادون",
                "label_fr": "Arradon"
            },
            {
                "value": 24354,
                "label": "Lezardrieux",
                "label_ar": "ليزاردريو",
                "label_fr": "Lézardrieux"
            },
            {
                "value": 24355,
                "label": "Le Vieux",
                "label_ar": "لو فيو",
                "label_fr": "Le Vieux"
            },
            {
                "value": 24356,
                "label": "Bedee",
                "label_ar": "بيدي",
                "label_fr": "Bédée"
            },
            {
                "value": 24357,
                "label": "Gourin",
                "label_ar": "غورين",
                "label_fr": "Gourin"
            },
            {
                "value": 24358,
                "label": "Mouaze",
                "label_ar": "معاذ",
                "label_fr": "Mouaze"
            },
            {
                "value": 24359,
                "label": "Guengat",
                "label_ar": "Guengat",
                "label_fr": "Guengat"
            },
            {
                "value": 24360,
                "label": "Brive",
                "label_ar": "بريف",
                "label_fr": "Brive"
            },
            {
                "value": 24361,
                "label": "Plonéour-Lanvern",
                "label_ar": "بلونيور لانفيرن",
                "label_fr": "Plonéour-Lanvern"
            },
            {
                "value": 24362,
                "label": "Persquen",
                "label_ar": "بيركوين",
                "label_fr": "Persquen"
            },
            {
                "value": 24363,
                "label": "Baguer-Morvan",
                "label_ar": "باغير مورفان",
                "label_fr": "Baguer-Morvan"
            },
            {
                "value": 24364,
                "label": "Coesmes",
                "label_ar": "Coesmes",
                "label_fr": "Coesmes"
            },
            {
                "value": 24365,
                "label": "Saint-Gonnery",
                "label_ar": "سان جونري",
                "label_fr": "Saint-Gonnery"
            },
            {
                "value": 24366,
                "label": "St-Malo",
                "label_ar": "سانت مالو",
                "label_fr": "St-Malo"
            },
            {
                "value": 24367,
                "label": "Monterfil",
                "label_ar": "مونتيرفيل",
                "label_fr": "Monterfil"
            },
            {
                "value": 24368,
                "label": "Saint-Meloir-des-Ondes",
                "label_ar": "Saint-Meloir-des-Ondes",
                "label_fr": "Saint-Meloir-des-Ondes"
            },
            {
                "value": 24369,
                "label": "Le Vivier-sur-Mer",
                "label_ar": "لو فيفييه سور مير",
                "label_fr": "Le Vivier-sur-Mer"
            },
            {
                "value": 24370,
                "label": "Trelevern",
                "label_ar": "تريليفرن",
                "label_fr": "Trelevern"
            },
            {
                "value": 24371,
                "label": "Berric",
                "label_ar": "بيريك",
                "label_fr": "Berric"
            },
            {
                "value": 24372,
                "label": "Plerguer",
                "label_ar": "Plerguer",
                "label_fr": "Plerguer"
            },
            {
                "value": 24373,
                "label": "Grand-Fougeray",
                "label_ar": "جراند فوجراي",
                "label_fr": "Grand-Fougeray"
            },
            {
                "value": 24374,
                "label": "La Motte",
                "label_ar": "لا موت",
                "label_fr": "La Motte"
            },
            {
                "value": 24375,
                "label": "Saint-Suliac",
                "label_ar": "سانت سولياك",
                "label_fr": "Saint-Suliac"
            },
            {
                "value": 24376,
                "label": "Poullan-sur-Mer",
                "label_ar": "بولان سور مير",
                "label_fr": "Poullan-sur-Mer"
            },
            {
                "value": 24377,
                "label": "Pontorson",
                "label_ar": "بونتورسون",
                "label_fr": "Pontorson"
            },
            {
                "value": 24378,
                "label": "Ploumagoar",
                "label_ar": "بلوماغار",
                "label_fr": "Ploumagoar"
            },
            {
                "value": 24379,
                "label": "Maxent",
                "label_ar": "ماكسينت",
                "label_fr": "Maxent"
            },
            {
                "value": 24380,
                "label": "Allaire",
                "label_ar": "ألاير",
                "label_fr": "Allaire"
            },
            {
                "value": 24381,
                "label": "Luitre",
                "label_ar": "لوتر",
                "label_fr": "Luitre"
            },
            {
                "value": 24382,
                "label": "Esquibien",
                "label_ar": "Esquibien",
                "label_fr": "Esquibien"
            },
            {
                "value": 24383,
                "label": "Cogles",
                "label_ar": "تروس",
                "label_fr": "Cogles"
            },
            {
                "value": 24384,
                "label": "Plouec-du-Trieux",
                "label_ar": "بلويك دو تريو",
                "label_fr": "Plouec-du-Trieux"
            },
            {
                "value": 24385,
                "label": "Treve",
                "label_ar": "تريف",
                "label_fr": "Treve"
            },
            {
                "value": 24386,
                "label": "Treflez",
                "label_ar": "تريفليز",
                "label_fr": "Treflez"
            },
            {
                "value": 24387,
                "label": "Bourbriac",
                "label_ar": "بوربرياك",
                "label_fr": "Bourbriac"
            },
            {
                "value": 24388,
                "label": "Quevert",
                "label_ar": "كويرت",
                "label_fr": "Quevert"
            },
            {
                "value": 24389,
                "label": "Saint-Nicolas-de-Redon",
                "label_ar": "سان نيكولا دي ريدون",
                "label_fr": "Saint-Nicolas-de-Redon"
            },
            {
                "value": 24390,
                "label": "Plusquellec",
                "label_ar": "بلسكيليك",
                "label_fr": "Plusquellec"
            },
            {
                "value": 24391,
                "label": "Leon",
                "label_ar": "ليون",
                "label_fr": "Léon"
            },
            {
                "value": 24392,
                "label": "Servon-sur-Vilaine",
                "label_ar": "سيرفون سور فيلان",
                "label_fr": "Servon-sur-Vilaine"
            },
            {
                "value": 24393,
                "label": "Lanrelas",
                "label_ar": "لانريلاس",
                "label_fr": "Lanrelas"
            },
            {
                "value": 24394,
                "label": "Colpo",
                "label_ar": "كولبو",
                "label_fr": "Colpo"
            },
            {
                "value": 24395,
                "label": "Muzillac",
                "label_ar": "موزيلاك",
                "label_fr": "Muzillac"
            },
            {
                "value": 24396,
                "label": "Saint-Armel",
                "label_ar": "سان ارميل",
                "label_fr": "Saint-Armel"
            },
            {
                "value": 24397,
                "label": "Saint-Congard",
                "label_ar": "سان كونجارد",
                "label_fr": "Saint-Congard"
            },
            {
                "value": 24398,
                "label": "La Bosse-de-Bretagne",
                "label_ar": "لا بوس دو بريتاني",
                "label_fr": "La Bosse-de-Bretagne"
            },
            {
                "value": 24399,
                "label": "Plouagat",
                "label_ar": "بلواجات",
                "label_fr": "Plouagat"
            },
            {
                "value": 24400,
                "label": "Belz",
                "label_ar": "بيلز",
                "label_fr": "Belz"
            },
            {
                "value": 24401,
                "label": "Plouezec",
                "label_ar": "بلوزيك",
                "label_fr": "Plouezec"
            },
            {
                "value": 24402,
                "label": "Plouigneau",
                "label_ar": "بلوينيو",
                "label_fr": "Plouigneau"
            },
            {
                "value": 24403,
                "label": "Trefflean",
                "label_ar": "تريفلين",
                "label_fr": "Trefflean"
            },
            {
                "value": 24404,
                "label": "Plougonven",
                "label_ar": "بلوجونفين",
                "label_fr": "Plougonven"
            },
            {
                "value": 24405,
                "label": "Locmine",
                "label_ar": "لوكمين",
                "label_fr": "Locmine"
            },
            {
                "value": 24406,
                "label": "Sainte-Seve",
                "label_ar": "سانت سيف",
                "label_fr": "Sainte-Seve"
            },
            {
                "value": 24407,
                "label": "Plouvorn",
                "label_ar": "بلوفورن",
                "label_fr": "Plouvorn"
            },
            {
                "value": 24408,
                "label": "Laignelet",
                "label_ar": "لاينيليت",
                "label_fr": "Laignelet"
            },
            {
                "value": 24409,
                "label": "Boulogne-Billancourt",
                "label_ar": "بولون بيلانكور",
                "label_fr": "Boulogne-Billancourt"
            },
            {
                "value": 24410,
                "label": "Villebon-sur-Yvette",
                "label_ar": "فيلبون سور إيفيت",
                "label_fr": "Villebon-sur-Yvette"
            },
            {
                "value": 24411,
                "label": "Neuilly-sur-Seine",
                "label_ar": "نويي سور سين",
                "label_fr": "Neuilly-sur-Seine"
            },
            {
                "value": 24412,
                "label": "Les Sorinieres",
                "label_ar": "ليه سورينييه",
                "label_fr": "Les Sorinières"
            },
            {
                "value": 24413,
                "label": "Argences",
                "label_ar": "الحجج",
                "label_fr": "Argences"
            },
            {
                "value": 24414,
                "label": "Carros",
                "label_ar": "كاروس",
                "label_fr": "Carros"
            },
            {
                "value": 24415,
                "label": "Marcilly-sur-Tille",
                "label_ar": "مارسيلي سور تيل",
                "label_fr": "Marcilly-sur-Tille"
            },
            {
                "value": 24416,
                "label": "Chatillon-sur-Chalaronne",
                "label_ar": "شاتيلون سور شالارون",
                "label_fr": "Châtillon-sur-Chalaronne"
            },
            {
                "value": 24417,
                "label": "Toulon",
                "label_ar": "طولون",
                "label_fr": "Toulon"
            },
            {
                "value": 24418,
                "label": "Bollene",
                "label_ar": "بولين",
                "label_fr": "Bollène"
            },
            {
                "value": 24419,
                "label": "Draguignan",
                "label_ar": "دراغينيان",
                "label_fr": "Draguignan"
            },
            {
                "value": 24420,
                "label": "Bagnères-de-Bigorre",
                "label_ar": "Bagnères-de-Bigorre",
                "label_fr": "Bagnères-de-Bigorre"
            },
            {
                "value": 24421,
                "label": "Yzeure",
                "label_ar": "يزور",
                "label_fr": "Yzeure"
            },
            {
                "value": 24422,
                "label": "Bourg-Saint-Andeol",
                "label_ar": "بورغ سانت أنديول",
                "label_fr": "Bourg-Saint-Andeol"
            },
            {
                "value": 24423,
                "label": "Petit-Palais-et-Cornemps",
                "label_ar": "Petit-Palais-et-Cornemps",
                "label_fr": "Petit-Palais-et-Cornemps"
            },
            {
                "value": 24424,
                "label": "Chantilly",
                "label_ar": "شانتيلي",
                "label_fr": "Chantilly"
            },
            {
                "value": 24425,
                "label": "Mont-Saint-Martin",
                "label_ar": "مونت سانت مارتن",
                "label_fr": "Mont-Saint-Martin"
            },
            {
                "value": 24426,
                "label": "Chaulnes",
                "label_ar": "تشولنز",
                "label_fr": "Chaulnes"
            },
            {
                "value": 24427,
                "label": "Pont-Saint-Esprit",
                "label_ar": "بونت سانت إسبريت",
                "label_fr": "Pont-Saint-Esprit"
            },
            {
                "value": 24428,
                "label": "Saint-Crepin-Ibouvillers",
                "label_ar": "سان كريبين إيبوفيلرس",
                "label_fr": "Saint-Crépin-Ibouvillers"
            },
            {
                "value": 24429,
                "label": "Gambais",
                "label_ar": "جامبيز",
                "label_fr": "Gambais"
            },
            {
                "value": 24430,
                "label": "Urrugne",
                "label_ar": "Urrugne",
                "label_fr": "Urrugne"
            },
            {
                "value": 24431,
                "label": "Domerat",
                "label_ar": "دوميرات",
                "label_fr": "Domerat"
            },
            {
                "value": 24432,
                "label": "Peymeinade",
                "label_ar": "بيميناد",
                "label_fr": "Peymeinade"
            },
            {
                "value": 24433,
                "label": "Lattes",
                "label_ar": "لاتيه",
                "label_fr": "Lattes"
            },
            {
                "value": 24434,
                "label": "Sezanne",
                "label_ar": "سيزان",
                "label_fr": "Sézanne"
            },
            {
                "value": 24435,
                "label": "Generac",
                "label_ar": "جينيراك",
                "label_fr": "Generac"
            },
            {
                "value": 24436,
                "label": "Buellas",
                "label_ar": "بويلاس",
                "label_fr": "Buellas"
            },
            {
                "value": 24437,
                "label": "Cergy",
                "label_ar": "سيرجي",
                "label_fr": "Cergy"
            },
            {
                "value": 24438,
                "label": "Bayonne",
                "label_ar": "بايون",
                "label_fr": "Bayonne"
            },
            {
                "value": 24439,
                "label": "Epernon",
                "label_ar": "إبيرنون",
                "label_fr": "Epernon"
            },
            {
                "value": 24440,
                "label": "Villefontaine",
                "label_ar": "فيلفونتين",
                "label_fr": "Villefontaine"
            },
            {
                "value": 24441,
                "label": "Marolles-en-Hurepoix",
                "label_ar": "Marolles-en-Hurepoix",
                "label_fr": "Marolles-en-Hurepoix"
            },
            {
                "value": 24442,
                "label": "Clermont-Ferrand",
                "label_ar": "كليرمون فيران",
                "label_fr": "Clermont-Ferrand"
            },
            {
                "value": 24443,
                "label": "Cabrieres-d'Avignon",
                "label_ar": "Cabrieres-d'Avignon",
                "label_fr": "Cabrières-d'Avignon"
            },
            {
                "value": 24444,
                "label": "Chevincourt",
                "label_ar": "شيفينكور",
                "label_fr": "Chevincourt"
            },
            {
                "value": 24445,
                "label": "Villeneuve-sur-Lot",
                "label_ar": "فيلنوف سور لوط",
                "label_fr": "Villeneuve-sur-Lot"
            },
            {
                "value": 24446,
                "label": "Triel-sur-Seine",
                "label_ar": "تريل سور سين",
                "label_fr": "Triel-sur-Seine"
            },
            {
                "value": 24447,
                "label": "Chelles",
                "label_ar": "تشيل",
                "label_fr": "Chelles"
            },
            {
                "value": 24448,
                "label": "Savigny-le-Temple",
                "label_ar": "Savigny-le-Temple",
                "label_fr": "Savigny-le-Temple"
            },
            {
                "value": 24449,
                "label": "Champs-sur-Marne",
                "label_ar": "تشامب سور مارن",
                "label_fr": "Champs-sur-Marne"
            },
            {
                "value": 24450,
                "label": "Vincennes",
                "label_ar": "فينسينز",
                "label_fr": "Vincennes"
            },
            {
                "value": 24451,
                "label": "Pontaut",
                "label_ar": "بونتوت",
                "label_fr": "Pontaut"
            },
            {
                "value": 24452,
                "label": "Chazay-d'Azergues",
                "label_ar": "تشازاي دازرج",
                "label_fr": "Chazay-d'Azergues"
            },
            {
                "value": 24453,
                "label": "Chenimenil",
                "label_ar": "Chenimenil",
                "label_fr": "Chenimenil"
            },
            {
                "value": 24454,
                "label": "Épinal",
                "label_ar": "ابينال",
                "label_fr": "Épinal"
            },
            {
                "value": 24455,
                "label": "Poissy",
                "label_ar": "بويسي",
                "label_fr": "Poissy"
            },
            {
                "value": 24456,
                "label": "Saint-Denis-de-Palin",
                "label_ar": "سان دوني دي بالين",
                "label_fr": "Saint-Denis-de-Palin"
            },
            {
                "value": 24457,
                "label": "Guyancourt",
                "label_ar": "جيانكور",
                "label_fr": "Guyancourt"
            },
            {
                "value": 24458,
                "label": "Andresy",
                "label_ar": "أندريسي",
                "label_fr": "Andresy"
            },
            {
                "value": 24459,
                "label": "La Courneuve",
                "label_ar": "لا كورنيوف",
                "label_fr": "La Courneuve"
            },
            {
                "value": 24460,
                "label": "Grigny",
                "label_ar": "غريني",
                "label_fr": "Grigny"
            },
            {
                "value": 24461,
                "label": "Serris",
                "label_ar": "سيريس",
                "label_fr": "Serris"
            },
            {
                "value": 24462,
                "label": "Grenoble",
                "label_ar": "غرونوبل",
                "label_fr": "Grenoble"
            },
            {
                "value": 24463,
                "label": "Marcq-en-Baroeul",
                "label_ar": "Marcq-en-Baroeul",
                "label_fr": "Marcq-en-Baroeul"
            },
            {
                "value": 24464,
                "label": "Montpellier",
                "label_ar": "مونبلييه",
                "label_fr": "Montpellier"
            },
            {
                "value": 24465,
                "label": "Sivry-Courtry",
                "label_ar": "سيفري كورتري",
                "label_fr": "Sivry-Courtry"
            },
            {
                "value": 24466,
                "label": "Issy-les-Moulineaux",
                "label_ar": "إيسي ليه مولينو",
                "label_fr": "Issy-les-Moulineaux"
            },
            {
                "value": 24467,
                "label": "Noisiel",
                "label_ar": "نويزيل",
                "label_fr": "Noisiel"
            },
            {
                "value": 24468,
                "label": "Orléans",
                "label_ar": "أورليان",
                "label_fr": "Orléans"
            },
            {
                "value": 24469,
                "label": "Chaponost",
                "label_ar": "شابونوست",
                "label_fr": "Chaponost"
            },
            {
                "value": 24470,
                "label": "Valence",
                "label_ar": "التكافؤ",
                "label_fr": "Valence"
            },
            {
                "value": 24471,
                "label": "Buc",
                "label_ar": "بوك",
                "label_fr": "Buc"
            },
            {
                "value": 24472,
                "label": "Courbevoie",
                "label_ar": "كوربفوا",
                "label_fr": "Courbevoie"
            },
            {
                "value": 24473,
                "label": "Haubourdin",
                "label_ar": "Haubourdin",
                "label_fr": "Haubourdin"
            },
            {
                "value": 24474,
                "label": "Meudon",
                "label_ar": "مودون",
                "label_fr": "Meudon"
            },
            {
                "value": 24475,
                "label": "Marly-le-Roi",
                "label_ar": "مارلي لو روي",
                "label_fr": "Marly-le-Roi"
            },
            {
                "value": 24476,
                "label": "Fontenay-aux-Roses",
                "label_ar": "Fontenay-aux-Roses",
                "label_fr": "Fontenay-aux-Roses"
            },
            {
                "value": 24477,
                "label": "Rueil-Malmaison",
                "label_ar": "رويل مالميزون",
                "label_fr": "Rueil-Malmaison"
            },
            {
                "value": 24478,
                "label": "Montreuil",
                "label_ar": "مونتروي",
                "label_fr": "Montreuil"
            },
            {
                "value": 24479,
                "label": "Scy-Chazelles",
                "label_ar": "Scy-Chazelles",
                "label_fr": "Scy-Chazelles"
            },
            {
                "value": 24480,
                "label": "Besançon",
                "label_ar": "بيزانسون",
                "label_fr": "Besançon"
            },
            {
                "value": 24481,
                "label": "Bourg-la-Reine",
                "label_ar": "بور لا رين",
                "label_fr": "Bourg-la-Reine"
            },
            {
                "value": 24482,
                "label": "Nice",
                "label_ar": "لطيف",
                "label_fr": "Agréable"
            },
            {
                "value": 24483,
                "label": "Échirolles",
                "label_ar": "Échirolles",
                "label_fr": "Échirolles"
            },
            {
                "value": 24484,
                "label": "Maisons-Alfort",
                "label_ar": "ميزون ألفورت",
                "label_fr": "Maisons-Alfort"
            },
            {
                "value": 24485,
                "label": "Le Havre",
                "label_ar": "لوهافر",
                "label_fr": "Le Havre"
            },
            {
                "value": 24486,
                "label": "Dugny",
                "label_ar": "دوجني",
                "label_fr": "Dugny"
            },
            {
                "value": 24487,
                "label": "Suresnes",
                "label_ar": "سورينس",
                "label_fr": "Suresnes"
            },
            {
                "value": 24488,
                "label": "Le Pecq",
                "label_ar": "لو بيكق",
                "label_fr": "Le Pecq"
            },
            {
                "value": 24489,
                "label": "Évry",
                "label_ar": "إيفري",
                "label_fr": "Évry"
            },
            {
                "value": 24490,
                "label": "Tours",
                "label_ar": "جولات",
                "label_fr": "Tours"
            },
            {
                "value": 24491,
                "label": "Athis-Mons",
                "label_ar": "اثيس مونز",
                "label_fr": "Athis-Mons"
            },
            {
                "value": 24492,
                "label": "Stains",
                "label_ar": "صبغات",
                "label_fr": "Taches"
            },
            {
                "value": 24493,
                "label": "Le Bourget",
                "label_ar": "لو بورجيه",
                "label_fr": "Le Bourget"
            },
            {
                "value": 24494,
                "label": "Cannes",
                "label_ar": "كان",
                "label_fr": "Cannes"
            },
            {
                "value": 24495,
                "label": "Juan-les-Pins",
                "label_ar": "خوان ليس بينس",
                "label_fr": "Juan-les-Pins"
            },
            {
                "value": 24496,
                "label": "Sevran",
                "label_ar": "سيفران",
                "label_fr": "Sevran"
            },
            {
                "value": 24497,
                "label": "Saint-Ouen",
                "label_ar": "سان اوين",
                "label_fr": "Saint-Ouen"
            },
            {
                "value": 24498,
                "label": "Vitry-sur-Seine",
                "label_ar": "فيتري سور سين",
                "label_fr": "Vitry-sur-Seine"
            },
            {
                "value": 24499,
                "label": "Les Clayes-sous-Bois",
                "label_ar": "ليه كلايز سو بوا",
                "label_fr": "Les Clayes-sous-Bois"
            },
            {
                "value": 24500,
                "label": "Fontenay-sous-Bois",
                "label_ar": "فونتيني سو بوا",
                "label_fr": "Fontenay-sous-Bois"
            },
            {
                "value": 24501,
                "label": "Épinay-sur-Seine",
                "label_ar": "إبيناي سور سين",
                "label_fr": "Épinay-sur-Seine"
            },
            {
                "value": 24502,
                "label": "Versailles",
                "label_ar": "فرساي",
                "label_fr": "Versailles"
            },
            {
                "value": 24503,
                "label": "Saint-Maur-des-Fossés",
                "label_ar": "سان مور دي فوسيس",
                "label_fr": "Saint-Maur-des-Fossés"
            },
            {
                "value": 24504,
                "label": "Créteil",
                "label_ar": "كريتيل",
                "label_fr": "Créteil"
            },
            {
                "value": 24505,
                "label": "Loos",
                "label_ar": "لوس",
                "label_fr": "Loos"
            },
            {
                "value": 24506,
                "label": "Levallois-Perret",
                "label_ar": "ليفالوا بيريه",
                "label_fr": "Levallois-Perret"
            },
            {
                "value": 24507,
                "label": "Heillecourt",
                "label_ar": "هيلكورت",
                "label_fr": "Heillecourt"
            },
            {
                "value": 24508,
                "label": "Pessac",
                "label_ar": "بيساك",
                "label_fr": "Pessac"
            },
            {
                "value": 24509,
                "label": "Nancy",
                "label_ar": "نانسي",
                "label_fr": "Nancy"
            },
            {
                "value": 24510,
                "label": "Rouen",
                "label_ar": "روان",
                "label_fr": "Rouen"
            },
            {
                "value": 24511,
                "label": "Perpignan",
                "label_ar": "بربينيان",
                "label_fr": "Perpignan"
            },
            {
                "value": 24512,
                "label": "Montfavet",
                "label_ar": "مونتفافيت",
                "label_fr": "Montfavet"
            },
            {
                "value": 24513,
                "label": "Dijon",
                "label_ar": "ديجون",
                "label_fr": "Dijon"
            },
            {
                "value": 24514,
                "label": "Saint-Germain-en-Laye",
                "label_ar": "سان جيرمان أونلي",
                "label_fr": "Saint-Germain-en-Laye"
            },
            {
                "value": 24515,
                "label": "Gif-sur-Yvette",
                "label_ar": "جيف سور إيفيت",
                "label_fr": "Gif-sur-Yvette"
            },
            {
                "value": 24516,
                "label": "Mantes-la-Jolie",
                "label_ar": "مانتس لا جولي",
                "label_fr": "Mantes-la-Jolie"
            },
            {
                "value": 24517,
                "label": "Villefranche-sur-Saône",
                "label_ar": "فيلفرانش سور ساون",
                "label_fr": "Villefranche-sur-Saône"
            },
            {
                "value": 24518,
                "label": "Rosny-sous-Bois",
                "label_ar": "روزني سو بوا",
                "label_fr": "Rosny-sous-Bois"
            },
            {
                "value": 24519,
                "label": "Strasbourg",
                "label_ar": "ستراسبورغ",
                "label_fr": "Strasbourg"
            },
            {
                "value": 24520,
                "label": "Cerisy-la-Foret",
                "label_ar": "سيريسي لا فوريه",
                "label_fr": "Cerisy-la-Foret"
            },
            {
                "value": 24521,
                "label": "Billy-Montigny",
                "label_ar": "بيلي مونتيني",
                "label_fr": "Billy-Montigny"
            },
            {
                "value": 24522,
                "label": "Le Mee-sur-Seine",
                "label_ar": "لو مي سور سين",
                "label_fr": "Le Mee-sur-Seine"
            },
            {
                "value": 24523,
                "label": "Belfort",
                "label_ar": "بلفور",
                "label_fr": "Belfort"
            },
            {
                "value": 24524,
                "label": "Colombes",
                "label_ar": "كولومب",
                "label_fr": "Colombes"
            },
            {
                "value": 24525,
                "label": "Metz",
                "label_ar": "ميتز",
                "label_fr": "Metz"
            },
            {
                "value": 24526,
                "label": "Lannoy",
                "label_ar": "لانوي",
                "label_fr": "Lannoy"
            },
            {
                "value": 24527,
                "label": "Dogneville",
                "label_ar": "دوجنفيل",
                "label_fr": "Dogneville"
            },
            {
                "value": 24528,
                "label": "Montigny-le-Bretonneux",
                "label_ar": "مونتيني لو بريتوني",
                "label_fr": "Montigny-le-Bretonneux"
            },
            {
                "value": 24529,
                "label": "Châtenay-Malabry",
                "label_ar": "شاتيناي مالابري",
                "label_fr": "Châtenay-Malabry"
            },
            {
                "value": 24530,
                "label": "Choisy-le-Roi",
                "label_ar": "تشويسي لو روي",
                "label_fr": "Choisy-le-Roi"
            },
            {
                "value": 24531,
                "label": "Chatou",
                "label_ar": "شاتو",
                "label_fr": "Chatou"
            },
            {
                "value": 24532,
                "label": "Barlin",
                "label_ar": "بارلين",
                "label_fr": "Barlin"
            },
            {
                "value": 24533,
                "label": "Wattrelos",
                "label_ar": "واتريلوس",
                "label_fr": "Wattrelos"
            },
            {
                "value": 24534,
                "label": "Claye-Souilly",
                "label_ar": "كلاي سويلي",
                "label_fr": "Claye-Souilly"
            },
            {
                "value": 24535,
                "label": "Gagny",
                "label_ar": "جاجني",
                "label_fr": "Gagny"
            },
            {
                "value": 24536,
                "label": "Rixheim",
                "label_ar": "ريكسهايم",
                "label_fr": "Rixheim"
            },
            {
                "value": 24537,
                "label": "Louviers",
                "label_ar": "اللوفر",
                "label_fr": "Louviers"
            },
            {
                "value": 24538,
                "label": "Amiens",
                "label_ar": "اميان",
                "label_fr": "Amiens"
            },
            {
                "value": 24539,
                "label": "Antibes",
                "label_ar": "أنتيبس",
                "label_fr": "Antibes"
            },
            {
                "value": 24540,
                "label": "Fréjus",
                "label_ar": "فريجوس",
                "label_fr": "Fréjus"
            },
            {
                "value": 24541,
                "label": "Bourg-de-Péage",
                "label_ar": "بور دي بيجي",
                "label_fr": "Bourg-de-Péage"
            },
            {
                "value": 24542,
                "label": "Troyes",
                "label_ar": "تروا",
                "label_fr": "Troyes"
            },
            {
                "value": 24543,
                "label": "Chambray-lès-Tours",
                "label_ar": "شامبراي ليس تورز",
                "label_fr": "Chambray-lès-Tours"
            },
            {
                "value": 24544,
                "label": "Tinqueux",
                "label_ar": "تينكو",
                "label_fr": "Tinqueux"
            },
            {
                "value": 24545,
                "label": "Sarreguemines",
                "label_ar": "سارجومين",
                "label_fr": "Sarreguemines"
            },
            {
                "value": 24546,
                "label": "Cavaillon",
                "label_ar": "كافايون",
                "label_fr": "Cavaillon"
            },
            {
                "value": 24547,
                "label": "Saint-Étienne-du-Rouvray",
                "label_ar": "سانت إتيان دو روفراي",
                "label_fr": "Saint-Étienne-du-Rouvray"
            },
            {
                "value": 24548,
                "label": "Domont",
                "label_ar": "دومونت",
                "label_fr": "Domont"
            },
            {
                "value": 24549,
                "label": "Neuilly-sur-Marne",
                "label_ar": "نويي سور مارن",
                "label_fr": "Neuilly-sur-Marne"
            },
            {
                "value": 24550,
                "label": "Fresnes",
                "label_ar": "فرينيس",
                "label_fr": "Fresnes"
            },
            {
                "value": 24551,
                "label": "Colomiers",
                "label_ar": "كولوميير",
                "label_fr": "Colomiers"
            },
            {
                "value": 24552,
                "label": "Saint-Raphaël",
                "label_ar": "سانت رافاييل",
                "label_fr": "Saint-Raphaël"
            },
            {
                "value": 24553,
                "label": "Sartrouville",
                "label_ar": "سارتروفيل",
                "label_fr": "Sartrouville"
            },
            {
                "value": 24554,
                "label": "Surtainville",
                "label_ar": "سرتنفيل",
                "label_fr": "Surtainville"
            },
            {
                "value": 24555,
                "label": "Bischheim",
                "label_ar": "بيشهايم",
                "label_fr": "Bischheim"
            },
            {
                "value": 24556,
                "label": "Hoenheim",
                "label_ar": "هوينهايم",
                "label_fr": "Hoenheim"
            },
            {
                "value": 24557,
                "label": "Annezin",
                "label_ar": "أنزين",
                "label_fr": "Annezin"
            },
            {
                "value": 24558,
                "label": "Vandoeuvre-les-Nancy",
                "label_ar": "Vandoeuvre-les-Nancy",
                "label_fr": "Vandoeuvre-les-Nancy"
            },
            {
                "value": 24559,
                "label": "Garges-lès-Gonesse",
                "label_ar": "جارجيس ليس جونيس",
                "label_fr": "Garges-lès-Gonesse"
            },
            {
                "value": 24560,
                "label": "Saint-Jean-de-la-Ruelle",
                "label_ar": "سان جان دي لا رويل",
                "label_fr": "Saint-Jean-de-la-Ruelle"
            },
            {
                "value": 24561,
                "label": "Saint-Omer",
                "label_ar": "سانت أومير",
                "label_fr": "Saint-Omer"
            },
            {
                "value": 24562,
                "label": "Chambéry",
                "label_ar": "شامبيري",
                "label_fr": "Chambéry"
            },
            {
                "value": 24563,
                "label": "Carcassonne",
                "label_ar": "كاركاسون",
                "label_fr": "Carcassonne"
            },
            {
                "value": 24564,
                "label": "Épernay",
                "label_ar": "إبيرناي",
                "label_fr": "Épernay"
            },
            {
                "value": 24565,
                "label": "Cormeilles-en-Parisis",
                "label_ar": "Cormeilles-en-Parisis",
                "label_fr": "Cormeilles-en-Parisis"
            },
            {
                "value": 24566,
                "label": "Seichamps",
                "label_ar": "سيشامب",
                "label_fr": "Seichamps"
            },
            {
                "value": 24567,
                "label": "Le Perreux-sur-Marne",
                "label_ar": "لو بيرو سور مارن",
                "label_fr": "Le Perreux-sur-Marne"
            },
            {
                "value": 24568,
                "label": "La Cadiere-d'Azur",
                "label_ar": "لا كادير دازور",
                "label_fr": "La Cadière-d'Azur"
            },
            {
                "value": 24569,
                "label": "Combs-la-Ville",
                "label_ar": "كومبس-لا-فيل",
                "label_fr": "Peignes-la-Ville"
            },
            {
                "value": 24570,
                "label": "Mâcon",
                "label_ar": "ماكون",
                "label_fr": "Mâcon"
            },
            {
                "value": 24571,
                "label": "Grigny",
                "label_ar": "غريني",
                "label_fr": "Grigny"
            },
            {
                "value": 24572,
                "label": "Villeneuve-sur-Fere",
                "label_ar": "فيلنوف سور فير",
                "label_fr": "Villeneuve-sur-Fère"
            },
            {
                "value": 24573,
                "label": "Chevilly-Larue",
                "label_ar": "شيفيلي لارو",
                "label_fr": "Chevilly-Larue"
            },
            {
                "value": 24574,
                "label": "Vienne",
                "label_ar": "فيين",
                "label_fr": "Vienne"
            },
            {
                "value": 24575,
                "label": "Morsang-sur-Orge",
                "label_ar": "مورسانغ سور أورج",
                "label_fr": "Morsang-sur-Orge"
            },
            {
                "value": 24576,
                "label": "Montmagny",
                "label_ar": "مونماجني",
                "label_fr": "Montmagny"
            },
            {
                "value": 24577,
                "label": "Sens",
                "label_ar": "سنس",
                "label_fr": "Sens"
            },
            {
                "value": 24578,
                "label": "Compiègne",
                "label_ar": "كومبيين",
                "label_fr": "Compiègne"
            },
            {
                "value": 24579,
                "label": "Oberschaeffolsheim",
                "label_ar": "Oberschaeffolsheim",
                "label_fr": "Oberschaeffolsheim"
            },
            {
                "value": 24580,
                "label": "Maisons-Laffitte",
                "label_ar": "ميزون لافيت",
                "label_fr": "Maisons-Laffitte"
            },
            {
                "value": 24581,
                "label": "La Riche",
                "label_ar": "لا ريش",
                "label_fr": "La Riche"
            },
            {
                "value": 24582,
                "label": "Saint-Sébastien-sur-Loire",
                "label_ar": "سان سيباستيان سور لوار",
                "label_fr": "Saint-Sébastien-sur-Loire"
            },
            {
                "value": 24583,
                "label": "Fontaine",
                "label_ar": "فونتين",
                "label_fr": "Fontaine"
            },
            {
                "value": 24584,
                "label": "Chilly-Mazarin",
                "label_ar": "تشيلي مازارين",
                "label_fr": "Chilly-Mazarin"
            },
            {
                "value": 24585,
                "label": "Gaillard",
                "label_ar": "جيلارد",
                "label_fr": "Gaillard"
            },
            {
                "value": 24586,
                "label": "L'Etrat",
                "label_ar": "لاترات",
                "label_fr": "L'Etrat"
            },
            {
                "value": 24587,
                "label": "Narbonne",
                "label_ar": "ناربون",
                "label_fr": "Narbonne"
            },
            {
                "value": 24588,
                "label": "Conflans-Sainte-Honorine",
                "label_ar": "كونفلانز سانت هونورين",
                "label_fr": "Conflans-Sainte-Honorine"
            },
            {
                "value": 24589,
                "label": "Nîmes",
                "label_ar": "نيم",
                "label_fr": "Nîmes"
            },
            {
                "value": 24590,
                "label": "Auxerre",
                "label_ar": "اوكسير",
                "label_fr": "Auxerre"
            },
            {
                "value": 24591,
                "label": "Caen",
                "label_ar": "كاين",
                "label_fr": "Caen"
            },
            {
                "value": 24592,
                "label": "Eysines",
                "label_ar": "يسين",
                "label_fr": "Eysines"
            },
            {
                "value": 24593,
                "label": "Eybens",
                "label_ar": "إيبينز",
                "label_fr": "Eybens"
            },
            {
                "value": 24594,
                "label": "Le Blanc",
                "label_ar": "لو بلانك",
                "label_fr": "Le Blanc"
            },
            {
                "value": 24595,
                "label": "Allauch",
                "label_ar": "اللوخ",
                "label_fr": "Allauch"
            },
            {
                "value": 24596,
                "label": "Aix-les-Bains",
                "label_ar": "إيكس ليه با",
                "label_fr": "Aix-les-Bains"
            },
            {
                "value": 24597,
                "label": "Villeparisis",
                "label_ar": "فيلباريسيس",
                "label_fr": "Villeparisis"
            },
            {
                "value": 24598,
                "label": "Dammarie-les-Lys",
                "label_ar": "Dammarie-les-Lys",
                "label_fr": "Dammarie-les-Lys"
            },
            {
                "value": 24599,
                "label": "Argenteuil",
                "label_ar": "أرجنتويل",
                "label_fr": "Argenteuil"
            },
            {
                "value": 24600,
                "label": "Bezons",
                "label_ar": "بيزونز",
                "label_fr": "Bezons"
            },
            {
                "value": 24601,
                "label": "Clamart",
                "label_ar": "كلامارت",
                "label_fr": "Clamart"
            },
            {
                "value": 24602,
                "label": "Kingersheim",
                "label_ar": "كينجيرشيم",
                "label_fr": "Kingersheim"
            },
            {
                "value": 24603,
                "label": "Le Portel",
                "label_ar": "لو بورتيل",
                "label_fr": "Le Portel"
            },
            {
                "value": 24604,
                "label": "Limay",
                "label_ar": "لاي",
                "label_fr": "Limay"
            },
            {
                "value": 24605,
                "label": "Le Raincy",
                "label_ar": "لو رينسي",
                "label_fr": "Le Raincy"
            },
            {
                "value": 24606,
                "label": "Franconville",
                "label_ar": "فرانكونفيل",
                "label_fr": "Franconville"
            },
            {
                "value": 24607,
                "label": "Chalons-en-Champagne",
                "label_ar": "شالون أون شامبين",
                "label_fr": "Châlons-en-Champagne"
            },
            {
                "value": 24608,
                "label": "Petit-Couronne",
                "label_ar": "بيتي كورون",
                "label_fr": "Petit-Couronne"
            },
            {
                "value": 24609,
                "label": "Cagnes-sur-Mer",
                "label_ar": "كاني سور مير",
                "label_fr": "Cagnes-sur-Mer"
            },
            {
                "value": 24610,
                "label": "Saint-Andre-les-Vergers",
                "label_ar": "سان أندريه ليه فيرجرس",
                "label_fr": "Saint-André-les-Vergers"
            },
            {
                "value": 24611,
                "label": "Périgueux",
                "label_ar": "بيريجو",
                "label_fr": "Périgueux"
            },
            {
                "value": 24612,
                "label": "Nevers",
                "label_ar": "نيفيرس",
                "label_fr": "Nevers"
            },
            {
                "value": 24613,
                "label": "Palaiseau",
                "label_ar": "باليزو",
                "label_fr": "Palaiseau"
            },
            {
                "value": 24614,
                "label": "Le Cannet",
                "label_ar": "لو كانيه",
                "label_fr": "Le Cannet"
            },
            {
                "value": 24615,
                "label": "Bourges",
                "label_ar": "بورجيه",
                "label_fr": "Bourges"
            },
            {
                "value": 24616,
                "label": "Saint-Laurent-du-Var",
                "label_ar": "سان لوران دو فار",
                "label_fr": "Saint-Laurent-du-Var"
            },
            {
                "value": 24617,
                "label": "Lourches",
                "label_ar": "لورش",
                "label_fr": "Lourches"
            },
            {
                "value": 24618,
                "label": "Rosay",
                "label_ar": "روزي",
                "label_fr": "Rosay"
            },
            {
                "value": 24619,
                "label": "Évreux",
                "label_ar": "ايفرو",
                "label_fr": "Évreux"
            },
            {
                "value": 24620,
                "label": "Saint-Germain-les-Arpajon",
                "label_ar": "سان جيرمان ليه أرباجون",
                "label_fr": "Saint-Germain-les-Arpajon"
            },
            {
                "value": 24621,
                "label": "Egly",
                "label_ar": "إغلي",
                "label_fr": "Egly"
            },
            {
                "value": 24622,
                "label": "Les Mureaux",
                "label_ar": "ليه مورو",
                "label_fr": "Les Mureaux"
            },
            {
                "value": 24623,
                "label": "Terville",
                "label_ar": "ترفيل",
                "label_fr": "Terville"
            },
            {
                "value": 24624,
                "label": "Fameck",
                "label_ar": "فاميك",
                "label_fr": "Fameck"
            },
            {
                "value": 24625,
                "label": "Neuilly-Plaisance",
                "label_ar": "Neuilly-Plaisance",
                "label_fr": "Neuilly-Plaisance"
            },
            {
                "value": 24626,
                "label": "Saint-Germain-les-Corbeil",
                "label_ar": "سان جيرمان ليه كوربيل",
                "label_fr": "Saint-Germain-les-Corbeil"
            },
            {
                "value": 24627,
                "label": "Herblay",
                "label_ar": "هيربلاي",
                "label_fr": "Herblay"
            },
            {
                "value": 24628,
                "label": "Brunoy",
                "label_ar": "برونوي",
                "label_fr": "Brunoy"
            },
            {
                "value": 24629,
                "label": "Blanquefort",
                "label_ar": "بلانكفورت",
                "label_fr": "Blanquefort"
            },
            {
                "value": 24630,
                "label": "Villiers-sur-Marne",
                "label_ar": "فيلير سور مارن",
                "label_fr": "Villiers-sur-Marne"
            },
            {
                "value": 24631,
                "label": "Bondy",
                "label_ar": "بوندي",
                "label_fr": "Bondy"
            },
            {
                "value": 24632,
                "label": "Noisy-le-Sec",
                "label_ar": "صاخبة لو سيك",
                "label_fr": "Noisy-le-Sec"
            },
            {
                "value": 24633,
                "label": "Nanterre",
                "label_ar": "نانتير",
                "label_fr": "Nanterre"
            },
            {
                "value": 24634,
                "label": "Montevrain",
                "label_ar": "مونتيفرين",
                "label_fr": "Montévrain"
            },
            {
                "value": 24635,
                "label": "Roquebrune-Cap-Martin",
                "label_ar": "روكبرون كاب مارتن",
                "label_fr": "Roquebrune-Cap-Martin"
            },
            {
                "value": 24636,
                "label": "Vaujours",
                "label_ar": "فوجورس",
                "label_fr": "Vaujours"
            },
            {
                "value": 24637,
                "label": "Deuil-la-Barre",
                "label_ar": "Deuil-la-Barre",
                "label_fr": "Deuil-la-Barre"
            },
            {
                "value": 24638,
                "label": "La Celle-Saint-Cloud",
                "label_ar": "لا سيل سان كلاود",
                "label_fr": "La Celle-Saint-Cloud"
            },
            {
                "value": 24639,
                "label": "Villeneuve-Saint-Georges",
                "label_ar": "فيلنوف سان جورج",
                "label_fr": "Villeneuve-Saint-Georges"
            },
            {
                "value": 24640,
                "label": "Saint-Médard-en-Jalles",
                "label_ar": "سان ميدارد أون جال",
                "label_fr": "Saint-Médard-en-Jalles"
            },
            {
                "value": 24641,
                "label": "Creil",
                "label_ar": "كريل",
                "label_fr": "Creil"
            },
            {
                "value": 24642,
                "label": "Pantin",
                "label_ar": "بانتين",
                "label_fr": "Pantin"
            },
            {
                "value": 24643,
                "label": "Lesigny",
                "label_ar": "ليسيني",
                "label_fr": "Lesigny"
            },
            {
                "value": 24644,
                "label": "Melun",
                "label_ar": "ميلون",
                "label_fr": "Melun"
            },
            {
                "value": 24645,
                "label": "Vaires-sur-Marne",
                "label_ar": "فايرس سور مارن",
                "label_fr": "Vaires-sur-Marne"
            },
            {
                "value": 24646,
                "label": "Ennery",
                "label_ar": "اينري",
                "label_fr": "Ennery"
            },
            {
                "value": 24647,
                "label": "Maizieres-les-Metz",
                "label_ar": "Maizieres-les-Metz",
                "label_fr": "Maizières-les-Metz"
            },
            {
                "value": 24648,
                "label": "Soisy-sur-Seine",
                "label_ar": "سويزي سور سين",
                "label_fr": "Soisy-sur-Seine"
            },
            {
                "value": 24649,
                "label": "Le Neubourg",
                "label_ar": "لو نيوبورج",
                "label_fr": "Le Neubourg"
            },
            {
                "value": 24650,
                "label": "Dame-Marie",
                "label_ar": "السيدة ماري",
                "label_fr": "Dame-Marie"
            },
            {
                "value": 24651,
                "label": "Villettes",
                "label_ar": "فيليتس",
                "label_fr": "Villettes"
            },
            {
                "value": 24652,
                "label": "Le Port-Marly",
                "label_ar": "لو بورت مارلي",
                "label_fr": "Le Port-Marly"
            },
            {
                "value": 24653,
                "label": "Cesar",
                "label_ar": "سيزار",
                "label_fr": "César"
            },
            {
                "value": 24654,
                "label": "Gravelines",
                "label_ar": "جرافلين",
                "label_fr": "Gravelines"
            },
            {
                "value": 24655,
                "label": "Saint-Aubin-les-Elbeuf",
                "label_ar": "سان اوبين ليه البوف",
                "label_fr": "Saint-Aubin-les-Elbeuf"
            },
            {
                "value": 24656,
                "label": "Montataire",
                "label_ar": "مونتير",
                "label_fr": "Montataire"
            },
            {
                "value": 24657,
                "label": "Villers-sur-le-Mont",
                "label_ar": "Villers-sur-le-Mont",
                "label_fr": "Villers-sur-le-Mont"
            },
            {
                "value": 24658,
                "label": "Samazan",
                "label_ar": "سمزان",
                "label_fr": "Samazan"
            },
            {
                "value": 24659,
                "label": "Bourran",
                "label_ar": "بوران",
                "label_fr": "Bourran"
            },
            {
                "value": 24660,
                "label": "Foulayronnes",
                "label_ar": "فولايرون",
                "label_fr": "Foulayronnes"
            },
            {
                "value": 24661,
                "label": "Tombeboeuf",
                "label_ar": "تومبوف",
                "label_fr": "Tombeboeuf"
            },
            {
                "value": 24662,
                "label": "Tayrac",
                "label_ar": "تايراك",
                "label_fr": "Tayrac"
            },
            {
                "value": 24663,
                "label": "Sainte-Colombe-de-Duras",
                "label_ar": "سانت كولومب دي دوراس",
                "label_fr": "Sainte-Colombe-de-Duras"
            },
            {
                "value": 24664,
                "label": "Penne-dAgenais",
                "label_ar": "بيني داجنايس",
                "label_fr": "Penne-d'Agenais"
            },
            {
                "value": 24665,
                "label": "Sainte-Gemme-Martaillac",
                "label_ar": "Sainte-Gemme-Martaillac",
                "label_fr": "Sainte-Gemme-Martaillac"
            },
            {
                "value": 24666,
                "label": "Anthe",
                "label_ar": "أنث",
                "label_fr": "Anthe"
            },
            {
                "value": 24667,
                "label": "Devillac",
                "label_ar": "ديفيلاك",
                "label_fr": "Devillac"
            },
            {
                "value": 24668,
                "label": "Ecluzelles",
                "label_ar": "Ecluzelles",
                "label_fr": "Écluzelles"
            },
            {
                "value": 24669,
                "label": "Bonneval",
                "label_ar": "بونيفال",
                "label_fr": "Bonneval"
            },
            {
                "value": 24670,
                "label": "Lutz-en-Dunois",
                "label_ar": "لوتز أون دونوا",
                "label_fr": "Lutz-en-Dunois"
            },
            {
                "value": 24671,
                "label": "Bailleul",
                "label_ar": "بيليل",
                "label_fr": "Bailleul"
            },
            {
                "value": 24672,
                "label": "Chorges",
                "label_ar": "المروحيات",
                "label_fr": "Chorges"
            },
            {
                "value": 24673,
                "label": "Beausoleil",
                "label_ar": "بوسولي",
                "label_fr": "Beausoleil"
            },
            {
                "value": 24674,
                "label": "Menton",
                "label_ar": "مينتون",
                "label_fr": "Menton"
            },
            {
                "value": 24675,
                "label": "Drap",
                "label_ar": "ثنى",
                "label_fr": "Drap"
            },
            {
                "value": 24676,
                "label": "Grasse",
                "label_ar": "جراس",
                "label_fr": "Grasse"
            },
            {
                "value": 24677,
                "label": "Rosieres-pres-Troyes",
                "label_ar": "روزيريس بريس تروا",
                "label_fr": "Rosières-près-Troyes"
            },
            {
                "value": 24678,
                "label": "Sainte-Savine",
                "label_ar": "سانت سافين",
                "label_fr": "Sainte-Savine"
            },
            {
                "value": 24679,
                "label": "Berre-l'Etang",
                "label_ar": "Berre-l'Etang",
                "label_fr": "Berre-l'Etang"
            },
            {
                "value": 24680,
                "label": "Bouc-Bel-Air",
                "label_ar": "Bouc-Bel-Air",
                "label_fr": "Bouc-Bel-Air"
            },
            {
                "value": 24681,
                "label": "Septemes-les-Vallons",
                "label_ar": "سبتميس ليه فالون",
                "label_fr": "Septemes-les-Vallons"
            },
            {
                "value": 24682,
                "label": "Ifs",
                "label_ar": "إذا",
                "label_fr": "Ifs"
            },
            {
                "value": 24683,
                "label": "Saint-Germain-la-Blanche-Herbe",
                "label_ar": "سان جيرمان لا بلانش هيربي",
                "label_fr": "Saint-Germain-la-Blanche-Herbe"
            },
            {
                "value": 24684,
                "label": "Brive-la-Gaillarde",
                "label_ar": "بريف لا جيلارد",
                "label_fr": "Brive-la-Gaillarde"
            },
            {
                "value": 24685,
                "label": "Chevigny-Saint-Sauveur",
                "label_ar": "تشيفيني سان سوفور",
                "label_fr": "Chevigny-Saint-Sauveur"
            },
            {
                "value": 24686,
                "label": "Mensignac",
                "label_ar": "مينسينياك",
                "label_fr": "Mensignac"
            },
            {
                "value": 24687,
                "label": "Etupes",
                "label_ar": "Etupes",
                "label_fr": "Etupes"
            },
            {
                "value": 24688,
                "label": "Vieux-Charmont",
                "label_ar": "Vieux-Charmont",
                "label_fr": "Vieux-Charmont"
            },
            {
                "value": 24689,
                "label": "Romans-sur-Isère",
                "label_ar": "رومان سور إيزير",
                "label_fr": "Romans-sur-Isère"
            },
            {
                "value": 24690,
                "label": "Chartres",
                "label_ar": "شارتر",
                "label_fr": "Chartres"
            },
            {
                "value": 24691,
                "label": "Ramonville-Saint-Agne",
                "label_ar": "رامونفيل سانت أجني",
                "label_fr": "Ramonville-Saint-Agne"
            },
            {
                "value": 24692,
                "label": "Cenon",
                "label_ar": "سينون",
                "label_fr": "Cenon"
            },
            {
                "value": 24693,
                "label": "Bruges",
                "label_ar": "بروج",
                "label_fr": "Bruges"
            },
            {
                "value": 24694,
                "label": "Libourne",
                "label_ar": "ليبورن",
                "label_fr": "Libourne"
            },
            {
                "value": 24695,
                "label": "Bassens",
                "label_ar": "باسنز",
                "label_fr": "Bassens"
            },
            {
                "value": 24696,
                "label": "Lormont",
                "label_ar": "لورمونت",
                "label_fr": "Lormont"
            },
            {
                "value": 24697,
                "label": "Boujan-sur-Libron",
                "label_ar": "بوجان سور ليبرون",
                "label_fr": "Boujan-sur-Libron"
            },
            {
                "value": 24698,
                "label": "Béziers",
                "label_ar": "بيزيرز",
                "label_fr": "Béziers"
            },
            {
                "value": 24699,
                "label": "Frontignan",
                "label_ar": "فرونتنيان",
                "label_fr": "Frontignan"
            },
            {
                "value": 24700,
                "label": "Sète",
                "label_ar": "سيت",
                "label_fr": "Sète"
            },
            {
                "value": 24701,
                "label": "Mauguio",
                "label_ar": "موجيو",
                "label_fr": "Mauguio"
            },
            {
                "value": 24702,
                "label": "Joué-lès-Tours",
                "label_ar": "Joué-lès-Tours",
                "label_fr": "Joué-lès-Tours"
            },
            {
                "value": 24703,
                "label": "Blois",
                "label_ar": "بلوا",
                "label_fr": "Blois"
            },
            {
                "value": 24704,
                "label": "Firminy",
                "label_ar": "فيرميني",
                "label_fr": "Firminy"
            },
            {
                "value": 24705,
                "label": "Rive-de-Gier",
                "label_ar": "ريف دي جير",
                "label_fr": "Rive-de-Gier"
            },
            {
                "value": 24706,
                "label": "Roanne",
                "label_ar": "روان",
                "label_fr": "Roanne"
            },
            {
                "value": 24707,
                "label": "La Ricamarie",
                "label_ar": "لا ريكاماري",
                "label_fr": "La Ricamarie"
            },
            {
                "value": 24708,
                "label": "Saint-Genest-Lerpt",
                "label_ar": "سان جينست ليربت",
                "label_fr": "Saint-Genest-Lerpt"
            },
            {
                "value": 24709,
                "label": "Saint-Priest-en-Jarez",
                "label_ar": "سانت بريست أون خاريز",
                "label_fr": "Saint-Priest-en-Jarez"
            },
            {
                "value": 24710,
                "label": "Chalette-sur-Loing",
                "label_ar": "شاليت سور لونج",
                "label_fr": "Chalette-sur-Loing"
            },
            {
                "value": 24711,
                "label": "Fleury-les-Aubrais",
                "label_ar": "فلوري ليه أوبريس",
                "label_fr": "Fleury-les-Aubrais"
            },
            {
                "value": 24712,
                "label": "Ingre",
                "label_ar": "انجر",
                "label_fr": "Ingre"
            },
            {
                "value": 24713,
                "label": "Agen",
                "label_ar": "أجين",
                "label_fr": "Agen"
            },
            {
                "value": 24714,
                "label": "Saint-Memmie",
                "label_ar": "سانت ميمي",
                "label_fr": "Saint-Memmie"
            },
            {
                "value": 24715,
                "label": "Pierry",
                "label_ar": "بيري",
                "label_fr": "Pierry"
            },
            {
                "value": 24716,
                "label": "Reims",
                "label_ar": "ريمس",
                "label_fr": "Reims"
            },
            {
                "value": 24717,
                "label": "Laxou",
                "label_ar": "لاكسو",
                "label_fr": "Laxou"
            },
            {
                "value": 24718,
                "label": "Toul",
                "label_ar": "تول",
                "label_fr": "Toul"
            },
            {
                "value": 24719,
                "label": "Florange",
                "label_ar": "فلورانج",
                "label_fr": "Florange"
            },
            {
                "value": 24720,
                "label": "Forbach",
                "label_ar": "فورباخ",
                "label_fr": "Forbach"
            },
            {
                "value": 24721,
                "label": "Hagondange",
                "label_ar": "هاجوندانج",
                "label_fr": "Hagondange"
            },
            {
                "value": 24722,
                "label": "Amneville",
                "label_ar": "أمنفيل",
                "label_fr": "Amnéville"
            },
            {
                "value": 24723,
                "label": "Hayange",
                "label_ar": "Hayange",
                "label_fr": "Hayange"
            },
            {
                "value": 24724,
                "label": "Hombourg-Haut",
                "label_ar": "هومبورغ أوت",
                "label_fr": "Hombourg-Haut"
            },
            {
                "value": 24725,
                "label": "Hettange-Grande",
                "label_ar": "هيتانج غراندي",
                "label_fr": "Hettange-Grande"
            },
            {
                "value": 24726,
                "label": "Thionville",
                "label_ar": "ثيونفيل",
                "label_fr": "Thionville"
            },
            {
                "value": 24727,
                "label": "Cambrai",
                "label_ar": "كامبراي",
                "label_fr": "Cambrai"
            },
            {
                "value": 24728,
                "label": "Hornaing",
                "label_ar": "هورنينج",
                "label_fr": "Hornaing"
            },
            {
                "value": 24729,
                "label": "Senlis",
                "label_ar": "سينليس",
                "label_fr": "Senlis"
            },
            {
                "value": 24730,
                "label": "Achicourt",
                "label_ar": "أشيكورت",
                "label_fr": "Achicourt"
            },
            {
                "value": 24731,
                "label": "Wingles",
                "label_ar": "الأجنحة",
                "label_fr": "Wingles"
            },
            {
                "value": 24732,
                "label": "Saint-Martin-au-Laert",
                "label_ar": "سان مارتن أو ليرت",
                "label_fr": "Saint-Martin-au-Laert"
            },
            {
                "value": 24733,
                "label": "Chateaugay",
                "label_ar": "شاتوجاي",
                "label_fr": "Châteaugay"
            },
            {
                "value": 24734,
                "label": "Pau",
                "label_ar": "باو",
                "label_fr": "Pau"
            },
            {
                "value": 24735,
                "label": "Bizanos",
                "label_ar": "بيزانوس",
                "label_fr": "Bizanos"
            },
            {
                "value": 24736,
                "label": "Illkirch-Graffenstaden",
                "label_ar": "إلكيرش جرافنشتادن",
                "label_fr": "Illkirch-Graffenstaden"
            },
            {
                "value": 24737,
                "label": "Schiltigheim",
                "label_ar": "شيلتيجهايم",
                "label_fr": "Schiltigheim"
            },
            {
                "value": 24738,
                "label": "Colmar",
                "label_ar": "كولمار",
                "label_fr": "Colmar"
            },
            {
                "value": 24739,
                "label": "Mulhouse",
                "label_ar": "ميلوز",
                "label_fr": "Mulhouse"
            },
            {
                "value": 24740,
                "label": "Illzach",
                "label_ar": "إيلزاخ",
                "label_fr": "Illzach"
            },
            {
                "value": 24741,
                "label": "Riedisheim",
                "label_ar": "ريديسهايم",
                "label_fr": "Riedisheim"
            },
            {
                "value": 24742,
                "label": "Hegenheim",
                "label_ar": "هيجنهايم",
                "label_fr": "Hegenheim"
            },
            {
                "value": 24743,
                "label": "Ecully",
                "label_ar": "إيكولي",
                "label_fr": "Ecully"
            },
            {
                "value": 24744,
                "label": "Saint-Genis-Laval",
                "label_ar": "سان جينيس لافال",
                "label_fr": "Saint-Genis-Laval"
            },
            {
                "value": 24745,
                "label": "Genas",
                "label_ar": "جيناس",
                "label_fr": "Genas"
            },
            {
                "value": 24746,
                "label": "Chalon-sur-Saône",
                "label_ar": "شالون سور ساون",
                "label_fr": "Chalon-sur-Saône"
            },
            {
                "value": 24747,
                "label": "Varennes-les-Macon",
                "label_ar": "فارين ليه ماكون",
                "label_fr": "Varennes-les-Macon"
            },
            {
                "value": 24748,
                "label": "Le Creusot",
                "label_ar": "لو كروسو",
                "label_fr": "Le Creusot"
            },
            {
                "value": 24749,
                "label": "Crottet",
                "label_ar": "كروت",
                "label_fr": "Crottet"
            },
            {
                "value": 24750,
                "label": "Montceau-les-Mines",
                "label_ar": "مونتسو ليه مين",
                "label_fr": "Montceau-les-Mines"
            },
            {
                "value": 24751,
                "label": "Seez",
                "label_ar": "سيز",
                "label_fr": "Seez"
            },
            {
                "value": 24752,
                "label": "Annecy",
                "label_ar": "آنسي",
                "label_fr": "Annecy"
            },
            {
                "value": 24753,
                "label": "Annemasse",
                "label_ar": "أنماس",
                "label_fr": "Annemasse"
            },
            {
                "value": 24754,
                "label": "Cluses",
                "label_ar": "كلوز",
                "label_fr": "Cluses"
            },
            {
                "value": 24755,
                "label": "Saint-Leu-la-Foret",
                "label_ar": "سان لو لا فوريه",
                "label_fr": "Saint-Leu-la-Foret"
            },
            {
                "value": 24756,
                "label": "Le Grand-Pressigny",
                "label_ar": "لو جراند برسيني",
                "label_fr": "Le Grand-Pressigny"
            },
            {
                "value": 24757,
                "label": "Deville-les-Rouen",
                "label_ar": "ديفيل ليه روان",
                "label_fr": "Deville-les-Rouen"
            },
            {
                "value": 24758,
                "label": "Mont-Saint-Aignan",
                "label_ar": "مونت سانت ايجنان",
                "label_fr": "Mont-Saint-Aignan"
            },
            {
                "value": 24759,
                "label": "Sotteville-lès-Rouen",
                "label_ar": "Sotteville-lès-Rouen",
                "label_fr": "Sotteville-lès-Rouen"
            },
            {
                "value": 24760,
                "label": "Brie-Comte-Robert",
                "label_ar": "بري كومت روبرت",
                "label_fr": "Brie-Comte-Robert"
            },
            {
                "value": 24761,
                "label": "Lieusaint",
                "label_ar": "ليوسينت",
                "label_fr": "Lieusaint"
            },
            {
                "value": 24762,
                "label": "Meaux",
                "label_ar": "مو",
                "label_fr": "Meaux"
            },
            {
                "value": 24763,
                "label": "Villeblevin",
                "label_ar": "فيلبليفين",
                "label_fr": "Villeblevin"
            },
            {
                "value": 24764,
                "label": "Rubelles",
                "label_ar": "روبيل",
                "label_fr": "Rubelles"
            },
            {
                "value": 24765,
                "label": "Montereau-Fault-Yonne",
                "label_ar": "مونتيرو فولت يون",
                "label_fr": "Montereau-Fault-Yonne"
            },
            {
                "value": 24766,
                "label": "Gretz-Armainvilliers",
                "label_ar": "جريتز أرمينفيلييه",
                "label_fr": "Gretz-Armainvilliers"
            },
            {
                "value": 24767,
                "label": "Bois-d'Arcy",
                "label_ar": "بوا دارسى",
                "label_fr": "Bois-d'Arcy"
            },
            {
                "value": 24768,
                "label": "Carrieres-sous-Poissy",
                "label_ar": "كارييرز سو بواسي",
                "label_fr": "Carrieres-sous-Poissy"
            },
            {
                "value": 24769,
                "label": "Villejuif",
                "label_ar": "فيلجويف",
                "label_fr": "Villejuif"
            },
            {
                "value": 24770,
                "label": "Plaisir",
                "label_ar": "بليسير",
                "label_fr": "Plaisir"
            },
            {
                "value": 24771,
                "label": "Acheres",
                "label_ar": "اكيريس",
                "label_fr": "Achères"
            },
            {
                "value": 24772,
                "label": "Maurepas",
                "label_ar": "موريباس",
                "label_fr": "Maurepas"
            },
            {
                "value": 24773,
                "label": "Verneuil-sur-Seine",
                "label_ar": "فيرنويل سور سين",
                "label_fr": "Verneuil-sur-Seine"
            },
            {
                "value": 24774,
                "label": "Vernouillet",
                "label_ar": "فيرنويلت",
                "label_fr": "Vernouillet"
            },
            {
                "value": 24775,
                "label": "Villepreux",
                "label_ar": "فيلبرو",
                "label_fr": "Villepreux"
            },
            {
                "value": 24776,
                "label": "La Garde",
                "label_ar": "لا جارد",
                "label_fr": "La Garde"
            },
            {
                "value": 24777,
                "label": "Hyères",
                "label_ar": "هييريس",
                "label_fr": "Hyères"
            },
            {
                "value": 24778,
                "label": "La Seyne-sur-Mer",
                "label_ar": "لا سين سور مير",
                "label_fr": "La Seyne-sur-Mer"
            },
            {
                "value": 24779,
                "label": "Audincourt",
                "label_ar": "اودينكور",
                "label_fr": "Audincourt"
            },
            {
                "value": 24780,
                "label": "Arpajon",
                "label_ar": "ارباجون",
                "label_fr": "Arpajon"
            },
            {
                "value": 24781,
                "label": "Ris-Orangis",
                "label_ar": "ريس أورانجيس",
                "label_fr": "Ris-Orangis"
            },
            {
                "value": 24782,
                "label": "Corbeil-Essonnes",
                "label_ar": "كوربيل إيسونيس",
                "label_fr": "Corbeil-Essonnes"
            },
            {
                "value": 24783,
                "label": "Étampes",
                "label_ar": "Étampes",
                "label_fr": "Étampes"
            },
            {
                "value": 24784,
                "label": "Asnieres-sur-Seine",
                "label_ar": "Asnieres-sur-Seine",
                "label_fr": "Asnières-sur-Seine"
            },
            {
                "value": 24785,
                "label": "Massy",
                "label_ar": "ماسي",
                "label_fr": "Massif"
            },
            {
                "value": 24786,
                "label": "Verrieres-le-Buisson",
                "label_ar": "فيريريز لو بويسون",
                "label_fr": "Verrières-le-Buisson"
            },
            {
                "value": 24787,
                "label": "La Ville-aux-Bois",
                "label_ar": "La Ville-aux-Bois",
                "label_fr": "La Ville-aux-Bois"
            },
            {
                "value": 24788,
                "label": "Sainte-Geneviève-des-Bois",
                "label_ar": "Sainte-Geneviève-des-Bois",
                "label_fr": "Sainte-Geneviève-des-Bois"
            },
            {
                "value": 24789,
                "label": "Draveil",
                "label_ar": "درافيل",
                "label_fr": "Draveil"
            },
            {
                "value": 24790,
                "label": "Viry-Châtillon",
                "label_ar": "فيري شاتيلون",
                "label_fr": "Viry-Châtillon"
            },
            {
                "value": 24791,
                "label": "Antony",
                "label_ar": "أنتوني",
                "label_fr": "Antony"
            },
            {
                "value": 24792,
                "label": "Clichy",
                "label_ar": "كليشي",
                "label_fr": "Clichy"
            },
            {
                "value": 24793,
                "label": "Châtillon",
                "label_ar": "شاتيلون",
                "label_fr": "Châtillon"
            },
            {
                "value": 24794,
                "label": "Montrouge",
                "label_ar": "مونتروج",
                "label_fr": "Montrouge"
            },
            {
                "value": 24795,
                "label": "Garches",
                "label_ar": "أقواس",
                "label_fr": "Garches"
            },
            {
                "value": 24796,
                "label": "Aulnay-sous-Bois",
                "label_ar": "أولني سو بوا",
                "label_fr": "Aulnay-sous-Bois"
            },
            {
                "value": 24797,
                "label": "Bagnolet",
                "label_ar": "باجنوليه",
                "label_fr": "Bagnolet"
            },
            {
                "value": 24798,
                "label": "Drancy",
                "label_ar": "درانسي",
                "label_fr": "Drancy"
            },
            {
                "value": 24799,
                "label": "Les Pavillons-sous-Bois",
                "label_ar": "ليه بافيلون سو بوا",
                "label_fr": "Les Pavillons-sous-Bois"
            },
            {
                "value": 24800,
                "label": "Livry-Gargan",
                "label_ar": "ليفري جارجان",
                "label_fr": "Livry-Gargan"
            },
            {
                "value": 24801,
                "label": "Noisy-le-Grand",
                "label_ar": "صاخبة لو جراند",
                "label_fr": "Noisy-le-Grand"
            },
            {
                "value": 24802,
                "label": "Les Lilas",
                "label_ar": "ليز ليلاس",
                "label_fr": "Les Lilas"
            },
            {
                "value": 24803,
                "label": "Saint-Denis",
                "label_ar": "سان دوني",
                "label_fr": "Saint-Denis"
            },
            {
                "value": 24804,
                "label": "L'Ile-Saint-Denis",
                "label_ar": "ليل سانت دينيس",
                "label_fr": "L'Ile-Saint-Denis"
            },
            {
                "value": 24805,
                "label": "Tremblay",
                "label_ar": "ترمبلاي",
                "label_fr": "Tremblay"
            },
            {
                "value": 24806,
                "label": "Alfortville",
                "label_ar": "ألفورتفيل",
                "label_fr": "Alfortville"
            },
            {
                "value": 24807,
                "label": "Villecresnes",
                "label_ar": "فيليكرسنيس",
                "label_fr": "Villecresnes"
            },
            {
                "value": 24808,
                "label": "Champigny-sur-Marne",
                "label_ar": "شامبيني سور مارن",
                "label_fr": "Champigny-sur-Marne"
            },
            {
                "value": 24809,
                "label": "Le Plessis-Belleville",
                "label_ar": "لو بليسيس بيلفيل",
                "label_fr": "Le Plessis-Belleville"
            },
            {
                "value": 24810,
                "label": "L'Haÿ-les-Roses",
                "label_ar": "لاهاي ليه روز",
                "label_fr": "L'Haÿ-les-Roses"
            },
            {
                "value": 24811,
                "label": "Ivry-sur-Seine",
                "label_ar": "ايفري سور سين",
                "label_fr": "Ivry-sur-Seine"
            },
            {
                "value": 24812,
                "label": "Persan",
                "label_ar": "بيرسان",
                "label_fr": "Persan"
            },
            {
                "value": 24813,
                "label": "Ezanville",
                "label_ar": "ايزانفيل",
                "label_fr": "Ezanville"
            },
            {
                "value": 24814,
                "label": "Gonesse",
                "label_ar": "جونيس",
                "label_fr": "Gonesse"
            },
            {
                "value": 24815,
                "label": "Montmorency",
                "label_ar": "مونتمورنسي",
                "label_fr": "Montmorency"
            },
            {
                "value": 24816,
                "label": "Caudry",
                "label_ar": "كودري",
                "label_fr": "Caudry"
            },
            {
                "value": 24817,
                "label": "Villetaneuse",
                "label_ar": "فيليتانيوس",
                "label_fr": "Villetaneuse"
            },
            {
                "value": 24818,
                "label": "Cachan",
                "label_ar": "كاشان",
                "label_fr": "Cachan"
            },
            {
                "value": 24819,
                "label": "Mericourt",
                "label_ar": "ميريكورت",
                "label_fr": "Méricourt"
            },
            {
                "value": 24820,
                "label": "Tournefeuille",
                "label_ar": "تورنفويل",
                "label_fr": "Tournefeuille"
            },
            {
                "value": 24821,
                "label": "Lescar",
                "label_ar": "ليسكار",
                "label_fr": "Lescar"
            },
            {
                "value": 24822,
                "label": "Coubron",
                "label_ar": "كوبرون",
                "label_fr": "Coubron"
            },
            {
                "value": 24823,
                "label": "Billere",
                "label_ar": "بيلير",
                "label_fr": "Billère"
            },
            {
                "value": 24824,
                "label": "Denain",
                "label_ar": "دينين",
                "label_fr": "Denain"
            },
            {
                "value": 24825,
                "label": "Saint-Gratien",
                "label_ar": "سانت جراتين",
                "label_fr": "Saint-Gratien"
            },
            {
                "value": 24826,
                "label": "La Barre-en-Ouche",
                "label_ar": "La Barre-en-Ouche",
                "label_fr": "La Barre-en-Ouche"
            },
            {
                "value": 24827,
                "label": "Silly-la-Poterie",
                "label_ar": "سخيفة لا بوتيري",
                "label_fr": "Silly-la-Poterie"
            },
            {
                "value": 24828,
                "label": "Villemomble",
                "label_ar": "فيلمومبل",
                "label_fr": "Villemomble"
            },
            {
                "value": 24829,
                "label": "Gennevilliers",
                "label_ar": "جينفيلييه",
                "label_fr": "Gennevilliers"
            },
            {
                "value": 24830,
                "label": "Vaux-le-Penil",
                "label_ar": "فو-لو-بينيل",
                "label_fr": "Vaux-le-Penil"
            },
            {
                "value": 24831,
                "label": "Saint-Laurent-Blangy",
                "label_ar": "سان لوران بلانجي",
                "label_fr": "Saint-Laurent-Blangy"
            },
            {
                "value": 24832,
                "label": "Saint-Julien-les-Metz",
                "label_ar": "سان جوليان ليه ميتز",
                "label_fr": "Saint-Julien-les-Metz"
            },
            {
                "value": 24833,
                "label": "Fleury-Merogis",
                "label_ar": "فلوري-ميروجيس",
                "label_fr": "Fleury-Mérogis"
            },
            {
                "value": 24834,
                "label": "Chennevieres-sur-Marne",
                "label_ar": "شينفيريس سور مارن",
                "label_fr": "Chennevières-sur-Marne"
            },
            {
                "value": 24835,
                "label": "La Queue-en-Brie",
                "label_ar": "لا كيو أون بري",
                "label_fr": "La Queue-en-Brie"
            },
            {
                "value": 24836,
                "label": "Bourron-Marlotte",
                "label_ar": "بورون مارلوت",
                "label_fr": "Bourron-Marlotte"
            },
            {
                "value": 24837,
                "label": "Malakoff",
                "label_ar": "مالاكوف",
                "label_fr": "Malakoff"
            },
            {
                "value": 24838,
                "label": "Vigneux-sur-Seine",
                "label_ar": "Vigneux-sur-Seine",
                "label_fr": "Vigneux-sur-Seine"
            },
            {
                "value": 24839,
                "label": "Thiais",
                "label_ar": "ثيايس",
                "label_fr": "Thiais"
            },
            {
                "value": 24840,
                "label": "Trappes",
                "label_ar": "فخاخ",
                "label_fr": "Trappes"
            },
            {
                "value": 24841,
                "label": "Linas",
                "label_ar": "ليناس",
                "label_fr": "Linas"
            },
            {
                "value": 24842,
                "label": "Saulx-les-Chartreux",
                "label_ar": "Saulx-les-Chartreux",
                "label_fr": "Saulx-les-Chartreux"
            },
            {
                "value": 24843,
                "label": "Paron",
                "label_ar": "بارون",
                "label_fr": "Paron"
            },
            {
                "value": 24844,
                "label": "Fagnieres",
                "label_ar": "فانيريس",
                "label_fr": "Fagnières"
            },
            {
                "value": 24845,
                "label": "Aubervilliers",
                "label_ar": "أوبيرفيلييه",
                "label_fr": "Aubervilliers"
            },
            {
                "value": 24846,
                "label": "Montargis",
                "label_ar": "مونتارجيس",
                "label_fr": "Montargis"
            },
            {
                "value": 24847,
                "label": "Savigny-sur-Orge",
                "label_ar": "Savigny-sur-Orge",
                "label_fr": "Savigny-sur-Orge"
            },
            {
                "value": 24848,
                "label": "Bagneux",
                "label_ar": "بانوكس",
                "label_fr": "Bagneux"
            },
            {
                "value": 24849,
                "label": "Mougins",
                "label_ar": "موجينز",
                "label_fr": "Mougins"
            },
            {
                "value": 24850,
                "label": "Brétigny-sur-Orge",
                "label_ar": "Brétigny-sur-Orge",
                "label_fr": "Brétigny-sur-Orge"
            },
            {
                "value": 24851,
                "label": "Puteaux",
                "label_ar": "بوتو",
                "label_fr": "Puteaux"
            },
            {
                "value": 24852,
                "label": "Saint-Brice-sous-Foret",
                "label_ar": "سان بريس سو فوريه",
                "label_fr": "Saint-Brice-sous-Foret"
            },
            {
                "value": 24853,
                "label": "Irigny",
                "label_ar": "إيرجني",
                "label_fr": "Irigny"
            },
            {
                "value": 24854,
                "label": "Nandy",
                "label_ar": "ناندي",
                "label_fr": "Nandy"
            },
            {
                "value": 24855,
                "label": "Nogent-sur-Marne",
                "label_ar": "نوجينت سور مارن",
                "label_fr": "Nogent-sur-Marne"
            },
            {
                "value": 24856,
                "label": "Saint-Remy-la-Vanne",
                "label_ar": "سانت ريمي لا فان",
                "label_fr": "Saint-Rémy-la-Vanne"
            },
            {
                "value": 24857,
                "label": "Chambly",
                "label_ar": "شامبلي",
                "label_fr": "Chambly"
            },
            {
                "value": 24858,
                "label": "Anglet",
                "label_ar": "زاوية",
                "label_fr": "Anglet"
            },
            {
                "value": 24859,
                "label": "Hermes",
                "label_ar": "هيرميس",
                "label_fr": "Hermès"
            },
            {
                "value": 24860,
                "label": "Évian-les-Bains",
                "label_ar": "إيفيان ليه با",
                "label_fr": "Évian-les-Bains"
            },
            {
                "value": 24861,
                "label": "Allevard",
                "label_ar": "أليفارد",
                "label_fr": "Allevard"
            },
            {
                "value": 24862,
                "label": "Saint-Pierre-de-Jards",
                "label_ar": "سان بيير دي جاردس",
                "label_fr": "Saint-Pierre-de-Jards"
            },
            {
                "value": 24863,
                "label": "Dieppe",
                "label_ar": "دييب",
                "label_fr": "Dieppe"
            },
            {
                "value": 24864,
                "label": "Brognon",
                "label_ar": "برونون",
                "label_fr": "Brognon"
            },
            {
                "value": 24865,
                "label": "Cordemais",
                "label_ar": "كورديمايس",
                "label_fr": "Cordemais"
            },
            {
                "value": 24866,
                "label": "Villeneuve",
                "label_ar": "فيلنوف",
                "label_fr": "Villeneuve"
            },
            {
                "value": 24867,
                "label": "Coupvray",
                "label_ar": "كوبفراي",
                "label_fr": "Coupvray"
            },
            {
                "value": 24868,
                "label": "Roussy-le-Village",
                "label_ar": "روسي لو فيليدج",
                "label_fr": "Roussy-le-Village"
            },
            {
                "value": 24869,
                "label": "Peillon",
                "label_ar": "بيلون",
                "label_fr": "Peillon"
            },
            {
                "value": 24870,
                "label": "La Queue-les-Yvelines",
                "label_ar": "لا كيو ليه إيفلين",
                "label_fr": "La Queue-les-Yvelines"
            },
            {
                "value": 24871,
                "label": "Saint-Andre",
                "label_ar": "سانت اندريه",
                "label_fr": "Saint-André"
            },
            {
                "value": 24872,
                "label": "Monthyon",
                "label_ar": "مونثيون",
                "label_fr": "Monthyon"
            },
            {
                "value": 24873,
                "label": "Riom",
                "label_ar": "Riom",
                "label_fr": "Riom"
            },
            {
                "value": 24874,
                "label": "Montoire-sur-le-Loir",
                "label_ar": "مونتوار سور لو لوير",
                "label_fr": "Montoire-sur-le-Loir"
            },
            {
                "value": 24875,
                "label": "Mordelles",
                "label_ar": "مورديليس",
                "label_fr": "Mordelles"
            },
            {
                "value": 24876,
                "label": "Hendaye",
                "label_ar": "هينداي",
                "label_fr": "Hendaye"
            },
            {
                "value": 24877,
                "label": "Speracedes",
                "label_ar": "سبراديس",
                "label_fr": "Spéracèdes"
            },
            {
                "value": 24878,
                "label": "Carnoules",
                "label_ar": "كارنوليس",
                "label_fr": "Carnoules"
            },
            {
                "value": 24879,
                "label": "Elbeuf",
                "label_ar": "البوف",
                "label_fr": "Elbeuf"
            },
            {
                "value": 24880,
                "label": "Miribel",
                "label_ar": "ميريبيل",
                "label_fr": "Miribel"
            },
            {
                "value": 24881,
                "label": "Vouille",
                "label_ar": "فويل",
                "label_fr": "Vouille"
            },
            {
                "value": 24882,
                "label": "Jouarre",
                "label_ar": "جوار",
                "label_fr": "Jouarre"
            },
            {
                "value": 24883,
                "label": "Jacob-Bellecombette",
                "label_ar": "جاكوب بيلكومبيت",
                "label_fr": "Jacob-Bellecombette"
            },
            {
                "value": 24884,
                "label": "LArbresle",
                "label_ar": "لبرسل",
                "label_fr": "LArbresle"
            },
            {
                "value": 24885,
                "label": "Solaize",
                "label_ar": "سولايز",
                "label_fr": "Solaize"
            },
            {
                "value": 24886,
                "label": "Montliard",
                "label_ar": "مونتليارد",
                "label_fr": "Montliard"
            },
            {
                "value": 24887,
                "label": "Neuilly-en-Thelle",
                "label_ar": "نويي أون ثيل",
                "label_fr": "Neuilly-en-Thelle"
            },
            {
                "value": 24888,
                "label": "Falaise",
                "label_ar": "Falaise",
                "label_fr": "Falaise"
            },
            {
                "value": 24889,
                "label": "Saint-Jean-d'Ardieres",
                "label_ar": "سان جان دارديريس",
                "label_fr": "Saint-Jean-d'Ardières"
            },
            {
                "value": 24890,
                "label": "Chamblanc",
                "label_ar": "شامبلانك",
                "label_fr": "Chamblanc"
            },
            {
                "value": 24891,
                "label": "Ballancourt-sur-Essonne",
                "label_ar": "بالانكورت سور إيسون",
                "label_fr": "Ballancourt-sur-Essonne"
            },
            {
                "value": 24892,
                "label": "Cregy-les-Meaux",
                "label_ar": "كريجي لي مو",
                "label_fr": "Cregy-les-Meaux"
            },
            {
                "value": 24893,
                "label": "Pernes-les-Fontaines",
                "label_ar": "بيرنس لي فونتين",
                "label_fr": "Pernes-les-Fontaines"
            },
            {
                "value": 24894,
                "label": "Semalens",
                "label_ar": "سيمالين",
                "label_fr": "Semalens"
            },
            {
                "value": 24895,
                "label": "Emerainville",
                "label_ar": "Emerainville",
                "label_fr": "Emerainville"
            },
            {
                "value": 24896,
                "label": "Milly-la-Foret",
                "label_ar": "ميلي لا فوريه",
                "label_fr": "Milly-la-Foret"
            },
            {
                "value": 24897,
                "label": "Châteauroux",
                "label_ar": "شاتورو",
                "label_fr": "Châteauroux"
            },
            {
                "value": 24898,
                "label": "Cesson",
                "label_ar": "سيسون",
                "label_fr": "Cesson"
            },
            {
                "value": 24899,
                "label": "Yerville",
                "label_ar": "يرفيل",
                "label_fr": "Yerville"
            },
            {
                "value": 24900,
                "label": "Le Landreau",
                "label_ar": "لو لاندرو",
                "label_fr": "Le Landreau"
            },
            {
                "value": 24901,
                "label": "Gron",
                "label_ar": "جرون",
                "label_fr": "Gron"
            },
            {
                "value": 24902,
                "label": "Houdan",
                "label_ar": "هودان",
                "label_fr": "Houdan"
            },
            {
                "value": 24903,
                "label": "Eaux-Bonnes",
                "label_ar": "أو-بونس",
                "label_fr": "Eaux-Bonnes"
            },
            {
                "value": 24904,
                "label": "Saint-Loup-Cammas",
                "label_ar": "سان لوب كاماس",
                "label_fr": "Saint-Loup-Cammas"
            },
            {
                "value": 24905,
                "label": "Lempdes-sur-Allagnon",
                "label_ar": "Lempdes-sur-Allagnon",
                "label_fr": "Lempdes-sur-Allagnon"
            },
            {
                "value": 24906,
                "label": "Maubeuge",
                "label_ar": "موبيج",
                "label_fr": "Maubeuge"
            },
            {
                "value": 24907,
                "label": "La Farlede",
                "label_ar": "لا فارليد",
                "label_fr": "La Farlède"
            },
            {
                "value": 24908,
                "label": "Briouze",
                "label_ar": "بريوز",
                "label_fr": "Briouze"
            },
            {
                "value": 24909,
                "label": "Alès",
                "label_ar": "أليس",
                "label_fr": "Alès"
            },
            {
                "value": 24910,
                "label": "Daoulas",
                "label_ar": "دولاس",
                "label_fr": "Daoulas"
            },
            {
                "value": 24911,
                "label": "Sciez",
                "label_ar": "شيز",
                "label_fr": "Sciez"
            },
            {
                "value": 24912,
                "label": "Guignes",
                "label_ar": "Guignes",
                "label_fr": "Guignes"
            },
            {
                "value": 24913,
                "label": "Langlade",
                "label_ar": "لانغليد",
                "label_fr": "Langlade"
            },
            {
                "value": 24914,
                "label": "Andernos-les-Bains",
                "label_ar": "Andernos-les-Bains",
                "label_fr": "Andernos-les-Bains"
            },
            {
                "value": 24915,
                "label": "Ferolles-Attilly",
                "label_ar": "فيروليس أتلي",
                "label_fr": "Férolles-Attilly"
            },
            {
                "value": 24916,
                "label": "Gien",
                "label_ar": "جين",
                "label_fr": "Gien"
            },
            {
                "value": 24917,
                "label": "Frouard",
                "label_ar": "فوارارد",
                "label_fr": "Frouard"
            },
            {
                "value": 24918,
                "label": "Cugnaux",
                "label_ar": "كوجنو",
                "label_fr": "Cugnaux"
            },
            {
                "value": 24919,
                "label": "Cabris",
                "label_ar": "كابريس",
                "label_fr": "Cabris"
            },
            {
                "value": 24920,
                "label": "Saint-Jean-de-Boiseau",
                "label_ar": "سان جان دي بواسو",
                "label_fr": "Saint-Jean-de-Boiseau"
            },
            {
                "value": 24921,
                "label": "Onet-le-Chateau",
                "label_ar": "أونيه لو شاتو",
                "label_fr": "Onet-le-Château"
            },
            {
                "value": 24922,
                "label": "Fécamp",
                "label_ar": "فيكامب",
                "label_fr": "Fécamp"
            },
            {
                "value": 24923,
                "label": "Grandfresnoy",
                "label_ar": "Grandfresnoy",
                "label_fr": "Grandfresnoy"
            },
            {
                "value": 24924,
                "label": "Montferrand-du-Perigord",
                "label_ar": "مونتفيراند دو بيريجورد",
                "label_fr": "Montferrand-du-Périgord"
            },
            {
                "value": 24925,
                "label": "Brignoles",
                "label_ar": "بريجنول",
                "label_fr": "Brignoles"
            },
            {
                "value": 24926,
                "label": "Marck",
                "label_ar": "مارك",
                "label_fr": "Marck"
            },
            {
                "value": 24927,
                "label": "Pithiviers",
                "label_ar": "اللباب",
                "label_fr": "Pithiviers"
            },
            {
                "value": 24928,
                "label": "Jarnac-Champagne",
                "label_ar": "جارناك شامبانيا",
                "label_fr": "Jarnac-Champagne"
            },
            {
                "value": 24929,
                "label": "Guethary",
                "label_ar": "Guethary",
                "label_fr": "Guéthary"
            },
            {
                "value": 24930,
                "label": "Le Rouret",
                "label_ar": "لو روريه",
                "label_fr": "Le Rouret"
            },
            {
                "value": 24931,
                "label": "Les Essarts",
                "label_ar": "ليس إسارتس",
                "label_fr": "Les Essarts"
            },
            {
                "value": 24932,
                "label": "Angliers",
                "label_ar": "الزوايا",
                "label_fr": "Angliers"
            },
            {
                "value": 24933,
                "label": "Aytre",
                "label_ar": "أيتر",
                "label_fr": "Aytre"
            },
            {
                "value": 24934,
                "label": "Floirac",
                "label_ar": "فلويراك",
                "label_fr": "Floirac"
            },
            {
                "value": 24935,
                "label": "Gradignan",
                "label_ar": "جراديجنان",
                "label_fr": "Gradignan"
            },
            {
                "value": 24936,
                "label": "Cherbourg-Octeville",
                "label_ar": "شيربورج أوكتفيل",
                "label_fr": "Cherbourg-Octeville"
            },
            {
                "value": 24937,
                "label": "Pontoise",
                "label_ar": "بونتواز",
                "label_fr": "Pontoise"
            },
            {
                "value": 24938,
                "label": "Eaubonne",
                "label_ar": "اوبون",
                "label_fr": "Eaubonne"
            },
            {
                "value": 24939,
                "label": "Voiron",
                "label_ar": "فويرون",
                "label_fr": "Voiron"
            },
            {
                "value": 24940,
                "label": "Hallennes-lez-Haubourdin",
                "label_ar": "هالينيس-ليز-هوبوردين",
                "label_fr": "Hallennes-lez-Haubourdin"
            },
            {
                "value": 24941,
                "label": "Flers-en-Escrebieux",
                "label_ar": "Flers-en-Escrebieux",
                "label_fr": "Flers-en-Escrebieux"
            },
            {
                "value": 24942,
                "label": "Auby",
                "label_ar": "أوبي",
                "label_fr": "Auby"
            },
            {
                "value": 24943,
                "label": "Blendecques",
                "label_ar": "Blendecques",
                "label_fr": "Blendecques"
            },
            {
                "value": 24944,
                "label": "Francheville",
                "label_ar": "فرانشيفيل",
                "label_fr": "Francheville"
            },
            {
                "value": 24945,
                "label": "Igny",
                "label_ar": "إيجني",
                "label_fr": "Igny"
            },
            {
                "value": 24946,
                "label": "La Grande-Motte",
                "label_ar": "لا غراند موت",
                "label_fr": "La Grande-Motte"
            },
            {
                "value": 24947,
                "label": "Essey-les-Nancy",
                "label_ar": "إيسي ليه نانسي",
                "label_fr": "Essey-les-Nancy"
            },
            {
                "value": 24948,
                "label": "Saint-Nicolas-de-Port",
                "label_ar": "سان نيكولا دي بورت",
                "label_fr": "Saint-Nicolas-de-Port"
            },
            {
                "value": 24949,
                "label": "Saint-Gervais-sous-Meymont",
                "label_ar": "سان جيرفيه سو ميمونت",
                "label_fr": "Saint-Gervais-sous-Meymont"
            },
            {
                "value": 24950,
                "label": "Canteleu",
                "label_ar": "كانتليو",
                "label_fr": "Canteleu"
            },
            {
                "value": 24951,
                "label": "Yerres",
                "label_ar": "يريس",
                "label_fr": "Yerres"
            },
            {
                "value": 24952,
                "label": "Joinville-le-Pont",
                "label_ar": "جوينفيل لو بونت",
                "label_fr": "Joinville-le-Pont"
            },
            {
                "value": 24953,
                "label": "Ablon-sur-Seine",
                "label_ar": "ابلون سور سين",
                "label_fr": "Ablon-sur-Seine"
            },
            {
                "value": 24954,
                "label": "Saint-Cyr-sur-Loire",
                "label_ar": "سان سير سور لوار",
                "label_fr": "Saint-Cyr-sur-Loire"
            },
            {
                "value": 24955,
                "label": "Vélizy-Villacoublay",
                "label_ar": "فيليزي فيلاكوبلاي",
                "label_fr": "Vélizy-Villacoublay"
            },
            {
                "value": 24956,
                "label": "Viroflay",
                "label_ar": "فيروفلاي",
                "label_fr": "Viroflay"
            },
            {
                "value": 24957,
                "label": "Saint-Lô",
                "label_ar": "سان لو",
                "label_fr": "Saint-Lô"
            },
            {
                "value": 24958,
                "label": "Bois-Colombes",
                "label_ar": "Bois-Colombes",
                "label_fr": "Bois-Colombes"
            },
            {
                "value": 24959,
                "label": "Le Port",
                "label_ar": "لو بورت",
                "label_fr": "Le Port"
            },
            {
                "value": 24960,
                "label": "Saint-Cloud",
                "label_ar": "سان كلاود",
                "label_fr": "Saint-Cloud"
            },
            {
                "value": 24961,
                "label": "Fontainebleau",
                "label_ar": "فونتينبلو",
                "label_fr": "Fontainebleau"
            },
            {
                "value": 24962,
                "label": "Nemours",
                "label_ar": "نمور",
                "label_fr": "Nemours"
            },
            {
                "value": 24963,
                "label": "Houilles",
                "label_ar": "هويلز",
                "label_fr": "Houilles"
            },
            {
                "value": 24964,
                "label": "Le Vesinet",
                "label_ar": "لو فيسينيت",
                "label_fr": "Le Vesinet"
            },
            {
                "value": 24965,
                "label": "Chenove",
                "label_ar": "تشينوف",
                "label_fr": "Chenove"
            },
            {
                "value": 24966,
                "label": "Beaune",
                "label_ar": "بيون",
                "label_fr": "Beaune"
            },
            {
                "value": 24967,
                "label": "Sassenage",
                "label_ar": "ساسينج",
                "label_fr": "Sassenage"
            },
            {
                "value": 24968,
                "label": "Meylan",
                "label_ar": "ميلان",
                "label_fr": "Meylan"
            },
            {
                "value": 24969,
                "label": "Leers",
                "label_ar": "خبث",
                "label_fr": "Leers"
            },
            {
                "value": 24970,
                "label": "Seclin",
                "label_ar": "سيكلين",
                "label_fr": "Seclin"
            },
            {
                "value": 24971,
                "label": "Carvin",
                "label_ar": "كارفن",
                "label_fr": "Carvin"
            },
            {
                "value": 24972,
                "label": "Le Chesnay",
                "label_ar": "لو تشيسناي",
                "label_fr": "Le Chesnay"
            },
            {
                "value": 24973,
                "label": "Taverny",
                "label_ar": "تافيرني",
                "label_fr": "Taverny"
            },
            {
                "value": 24974,
                "label": "Montgeron",
                "label_ar": "مونتجيرون",
                "label_fr": "Montgeron"
            },
            {
                "value": 24975,
                "label": "Pont-à-Mousson",
                "label_ar": "Pont-à-Mousson",
                "label_fr": "Pont-à-Mousson"
            },
            {
                "value": 24976,
                "label": "Creutzwald",
                "label_ar": "كروتزوالد",
                "label_fr": "Creutzwald"
            },
            {
                "value": 24977,
                "label": "Merlebach",
                "label_ar": "Merlebach",
                "label_fr": "Merlebach"
            },
            {
                "value": 24978,
                "label": "Cocheren",
                "label_ar": "كوشرين",
                "label_fr": "Cocheren"
            },
            {
                "value": 24979,
                "label": "Illange",
                "label_ar": "إيلانج",
                "label_fr": "Illange"
            },
            {
                "value": 24980,
                "label": "Roissy-en-Brie",
                "label_ar": "رواسي أون بري",
                "label_fr": "Roissy-en-Brie"
            },
            {
                "value": 24981,
                "label": "Alençon",
                "label_ar": "Alençon",
                "label_fr": "Alençon"
            },
            {
                "value": 24982,
                "label": "Guebwiller",
                "label_ar": "جيبويلر",
                "label_fr": "Guebwiller"
            },
            {
                "value": 24983,
                "label": "Haguenau",
                "label_ar": "Haguenau",
                "label_fr": "Haguenau"
            },
            {
                "value": 24984,
                "label": "Lingolsheim",
                "label_ar": "لينجولشيم",
                "label_fr": "Lingolsheim"
            },
            {
                "value": 24985,
                "label": "Molsheim",
                "label_ar": "مولشيم",
                "label_fr": "Molsheim"
            },
            {
                "value": 24986,
                "label": "Saverne",
                "label_ar": "سافيرن",
                "label_fr": "Saverne"
            },
            {
                "value": 24987,
                "label": "Noiseau",
                "label_ar": "الضوضاء",
                "label_fr": "Noiseau"
            },
            {
                "value": 24988,
                "label": "Mandres-les-Roses",
                "label_ar": "Mandres-les-Roses",
                "label_fr": "Mandres-les-Roses"
            },
            {
                "value": 24989,
                "label": "Beauvais",
                "label_ar": "بوفيه",
                "label_fr": "Beauvais"
            },
            {
                "value": 24990,
                "label": "Biarritz",
                "label_ar": "بياريتز",
                "label_fr": "Biarritz"
            },
            {
                "value": 24991,
                "label": "Jurancon",
                "label_ar": "جورانكون",
                "label_fr": "Jurançon"
            },
            {
                "value": 24992,
                "label": "Saint-Jean-de-Luz",
                "label_ar": "سان جان دي لوز",
                "label_fr": "Saint-Jean-de-Luz"
            },
            {
                "value": 24993,
                "label": "La Valette-du-Var",
                "label_ar": "لا فاليت دو فار",
                "label_fr": "La Valette-du-Var"
            },
            {
                "value": 24994,
                "label": "Saint-Pierre-des-Corps",
                "label_ar": "سان بيير دي كوربس",
                "label_fr": "Saint-Pierre-des-Corps"
            },
            {
                "value": 24995,
                "label": "Montlouis-sur-Loire",
                "label_ar": "مونتلو سور لوار",
                "label_fr": "Montlouis-sur-Loire"
            },
            {
                "value": 24996,
                "label": "Chevreuse",
                "label_ar": "شيفريوز",
                "label_fr": "Chevreuse"
            },
            {
                "value": 24997,
                "label": "Bailly",
                "label_ar": "بايلي",
                "label_fr": "Bailly"
            },
            {
                "value": 24998,
                "label": "Goussainville",
                "label_ar": "جوسانفيل",
                "label_fr": "Goussainville"
            },
            {
                "value": 24999,
                "label": "Wasquehal",
                "label_ar": "Wasquehal",
                "label_fr": "Wasquehal"
            },
            {
                "value": 25000,
                "label": "Aubiere",
                "label_ar": "أوبير",
                "label_fr": "Aubière"
            },
            {
                "value": 25001,
                "label": "Castelnau-le-Lez",
                "label_ar": "Castelnau-le-Lez",
                "label_fr": "Castelnau-le-Lez"
            },
            {
                "value": 25002,
                "label": "Arcueil",
                "label_ar": "اركويل",
                "label_fr": "Arcueil"
            },
            {
                "value": 25003,
                "label": "Moulins-les-Metz",
                "label_ar": "مولين ليه ميتز",
                "label_fr": "Moulins-les-Metz"
            },
            {
                "value": 25004,
                "label": "Maromme",
                "label_ar": "ماروم",
                "label_fr": "Maromme"
            },
            {
                "value": 25005,
                "label": "Romainville",
                "label_ar": "رومانفيل",
                "label_fr": "Romainville"
            },
            {
                "value": 25006,
                "label": "Cran-Gevrier",
                "label_ar": "كران جيفرير",
                "label_fr": "Cran-Gevrier"
            },
            {
                "value": 25007,
                "label": "Boissy-Saint-Léger",
                "label_ar": "Boissy-Saint-Léger",
                "label_fr": "Boissy-Saint-Léger"
            },
            {
                "value": 25008,
                "label": "Beynes",
                "label_ar": "بينيز",
                "label_fr": "Beynes"
            },
            {
                "value": 25009,
                "label": "Anthon",
                "label_ar": "أنتون",
                "label_fr": "Anthon"
            },
            {
                "value": 25010,
                "label": "Uzès",
                "label_ar": "Uzès",
                "label_fr": "Uzès"
            },
            {
                "value": 25011,
                "label": "Mions",
                "label_ar": "الميون",
                "label_fr": "Les mions"
            },
            {
                "value": 25012,
                "label": "Pierrefitte-sur-Seine",
                "label_ar": "بييرفيت سور سين",
                "label_fr": "Pierrefitte-sur-Seine"
            },
            {
                "value": 25013,
                "label": "Longjumeau",
                "label_ar": "Longjumeau",
                "label_fr": "Longjumeau"
            },
            {
                "value": 25014,
                "label": "La Riviere-de-Corps",
                "label_ar": "لا ريفيير دي كوربس",
                "label_fr": "La Rivière-de-Corps"
            },
            {
                "value": 25015,
                "label": "Saint-Julien-les-Villas",
                "label_ar": "سان جوليان ليه فيلاز",
                "label_fr": "Saint-Julien-les-Villas"
            },
            {
                "value": 25016,
                "label": "Saint-Doulchard",
                "label_ar": "سان دولشارد",
                "label_fr": "Saint-Doulchard"
            },
            {
                "value": 25017,
                "label": "Groslay",
                "label_ar": "جروسلاي",
                "label_fr": "Groslay"
            },
            {
                "value": 25018,
                "label": "Sainte-Adresse",
                "label_ar": "سانت ادريس",
                "label_fr": "Sainte-Adresse"
            },
            {
                "value": 25019,
                "label": "Pierre-Benite",
                "label_ar": "بيير بينيت",
                "label_fr": "Pierre-Benite"
            },
            {
                "value": 25020,
                "label": "Mantes-la-Ville",
                "label_ar": "مانتس لا فيل",
                "label_fr": "Mantes-la-Ville"
            },
            {
                "value": 25021,
                "label": "Torcy",
                "label_ar": "تورسي",
                "label_fr": "Torcy"
            },
            {
                "value": 25022,
                "label": "Brou-sur-Chantereine",
                "label_ar": "برو سور شانترين",
                "label_fr": "Brou-sur-Chantereine"
            },
            {
                "value": 25023,
                "label": "Beaumont",
                "label_ar": "بومونت",
                "label_fr": "Beaumont"
            },
            {
                "value": 25024,
                "label": "Arlanc",
                "label_ar": "أرلانك",
                "label_fr": "Arlanc"
            },
            {
                "value": 25025,
                "label": "Ormesson-sur-Marne",
                "label_ar": "أورميسون سور مارن",
                "label_fr": "Ormesson-sur-Marne"
            },
            {
                "value": 25026,
                "label": "Orly",
                "label_ar": "أورلي",
                "label_fr": "Orly"
            },
            {
                "value": 25027,
                "label": "Les Loges",
                "label_ar": "لي لوجيس",
                "label_fr": "Les Loges"
            },
            {
                "value": 25028,
                "label": "Verniolle",
                "label_ar": "فيرنيول",
                "label_fr": "Verniolle"
            },
            {
                "value": 25029,
                "label": "Fuveau",
                "label_ar": "فوفو",
                "label_fr": "Fuveau"
            },
            {
                "value": 25030,
                "label": "Basse-Goulaine",
                "label_ar": "باس جولين",
                "label_fr": "Basse-Goulaine"
            },
            {
                "value": 25031,
                "label": "Etroussat",
                "label_ar": "إتروسات",
                "label_fr": "Étroussat"
            },
            {
                "value": 25032,
                "label": "Condom",
                "label_ar": "واق ذكري",
                "label_fr": "Préservatif"
            },
            {
                "value": 25033,
                "label": "L'Isle-Jourdain",
                "label_ar": "L'Isle-Jourdain",
                "label_fr": "L'Isle-Jourdain"
            },
            {
                "value": 25034,
                "label": "Pessan",
                "label_ar": "بيسان",
                "label_fr": "Pessan"
            },
            {
                "value": 25035,
                "label": "Élancourt",
                "label_ar": "إيلانكورت",
                "label_fr": "Élancourt"
            },
            {
                "value": 25036,
                "label": "Brochon",
                "label_ar": "بروشون",
                "label_fr": "Brochon"
            },
            {
                "value": 25037,
                "label": "Bourg-le-Comte",
                "label_ar": "بورغ لو كومت",
                "label_fr": "Bourg-le-Comte"
            },
            {
                "value": 25038,
                "label": "Gaillac",
                "label_ar": "جايلاك",
                "label_fr": "Gaillac"
            },
            {
                "value": 25039,
                "label": "Le Fraysse",
                "label_ar": "لو فرايسي",
                "label_fr": "Le Fraysse"
            },
            {
                "value": 25040,
                "label": "Saussenac",
                "label_ar": "سوسيناك",
                "label_fr": "Saussenac"
            },
            {
                "value": 25041,
                "label": "Saint-Ay",
                "label_ar": "سان آي",
                "label_fr": "Saint-Ay"
            },
            {
                "value": 25042,
                "label": "Bussy-Saint-Georges",
                "label_ar": "بوسي سان جورج",
                "label_fr": "Bussy-Saint-Georges"
            },
            {
                "value": 25043,
                "label": "Pont-l'Eveque",
                "label_ar": "Pont-l'Eveque",
                "label_fr": "Pont-l'Évêque"
            },
            {
                "value": 25044,
                "label": "Fabregues",
                "label_ar": "فابريغز",
                "label_fr": "Fabregues"
            },
            {
                "value": 25045,
                "label": "Paul",
                "label_ar": "بول",
                "label_fr": "Paul"
            },
            {
                "value": 25046,
                "label": "Lunel",
                "label_ar": "لونيل",
                "label_fr": "Lunel"
            },
            {
                "value": 25047,
                "label": "Pons",
                "label_ar": "الجسور",
                "label_fr": "Pons"
            },
            {
                "value": 25048,
                "label": "Olargues",
                "label_ar": "أولارج",
                "label_fr": "Olargues"
            },
            {
                "value": 25049,
                "label": "Pignan",
                "label_ar": "بيجنان",
                "label_fr": "Pignan"
            },
            {
                "value": 25050,
                "label": "Ganges",
                "label_ar": "الغانج",
                "label_fr": "Ganges"
            },
            {
                "value": 25051,
                "label": "Cessenon-sur-Orb",
                "label_ar": "Cessenon-sur-Orb",
                "label_fr": "Cessenon-sur-Orb"
            },
            {
                "value": 25052,
                "label": "Poussan",
                "label_ar": "بوسان",
                "label_fr": "Poussan"
            },
            {
                "value": 25053,
                "label": "Loupian",
                "label_ar": "لوبيان",
                "label_fr": "Loupian"
            },
            {
                "value": 25054,
                "label": "Jaure",
                "label_ar": "جوري",
                "label_fr": "Jaure"
            },
            {
                "value": 25055,
                "label": "Mosson",
                "label_ar": "موسون",
                "label_fr": "Mosson"
            },
            {
                "value": 25056,
                "label": "Krafft",
                "label_ar": "كرافت",
                "label_fr": "Krafft"
            },
            {
                "value": 25057,
                "label": "Beauvoir",
                "label_ar": "بوفوار",
                "label_fr": "Beauvoir"
            },
            {
                "value": 25058,
                "label": "Les Pins",
                "label_ar": "ليه بينس",
                "label_fr": "Les Pins"
            },
            {
                "value": 25059,
                "label": "Mitterand",
                "label_ar": "ميتران",
                "label_fr": "Mitterrand"
            },
            {
                "value": 25060,
                "label": "Lansargues",
                "label_ar": "لانسارج",
                "label_fr": "Lansargues"
            },
            {
                "value": 25061,
                "label": "Montagnac",
                "label_ar": "مونتاجناك",
                "label_fr": "Montagnac"
            },
            {
                "value": 25062,
                "label": "Bene",
                "label_ar": "بيني",
                "label_fr": "Bene"
            },
            {
                "value": 25063,
                "label": "Roujan",
                "label_ar": "روجان",
                "label_fr": "Roujan"
            },
            {
                "value": 25064,
                "label": "Martin-Eglise",
                "label_ar": "مارتن إيجليز",
                "label_fr": "Martin-Eglise"
            },
            {
                "value": 25065,
                "label": "Doux",
                "label_ar": "دو",
                "label_fr": "Doux"
            },
            {
                "value": 25066,
                "label": "Marc",
                "label_ar": "مارك",
                "label_fr": "Marc"
            },
            {
                "value": 25067,
                "label": "Pierre-de-Bresse",
                "label_ar": "بيير دي بريس",
                "label_fr": "Pierre-de-Bresse"
            },
            {
                "value": 25068,
                "label": "Vichy",
                "label_ar": "فيشي",
                "label_fr": "Vichy"
            },
            {
                "value": 25069,
                "label": "Mereville",
                "label_ar": "ميريفيل",
                "label_fr": "Mereville"
            },
            {
                "value": 25070,
                "label": "Cahuzac",
                "label_ar": "كاهوزاك",
                "label_fr": "Cahuzac"
            },
            {
                "value": 25071,
                "label": "Cournon-dAuvergne",
                "label_ar": "Cournon-dAuvergne",
                "label_fr": "Cournon-d'Auvergne"
            },
            {
                "value": 25072,
                "label": "Senan",
                "label_ar": "سنان",
                "label_fr": "Senan"
            },
            {
                "value": 25073,
                "label": "Bonneuil-sur-Marne",
                "label_ar": "Bonneuil-sur-Marne",
                "label_fr": "Bonneuil-sur-Marne"
            },
            {
                "value": 25074,
                "label": "Arudy",
                "label_ar": "أرودي",
                "label_fr": "Arudy"
            },
            {
                "value": 25075,
                "label": "Perols",
                "label_ar": "بيرولس",
                "label_fr": "Pérols"
            },
            {
                "value": 25076,
                "label": "La Roque-d'Antheron",
                "label_ar": "لا روكي دانتيرون",
                "label_fr": "La Roque-d'Antheron"
            },
            {
                "value": 25077,
                "label": "Coudoux",
                "label_ar": "كودوكس",
                "label_fr": "Coudoux"
            },
            {
                "value": 25078,
                "label": "Saint-Esteve-Janson",
                "label_ar": "سانت إستيف جانسون",
                "label_fr": "Saint-Esteve-Janson"
            },
            {
                "value": 25079,
                "label": "Simiane-Collongue",
                "label_ar": "Simiane-Collongue",
                "label_fr": "Simiane-Collongue"
            },
            {
                "value": 25080,
                "label": "Ozoir-la-Ferriere",
                "label_ar": "Ozoir-la-Ferriere",
                "label_fr": "Ozoir-la-Ferrière"
            },
            {
                "value": 25081,
                "label": "Saint-Maurice",
                "label_ar": "سان موريس",
                "label_fr": "Saint-Maurice"
            },
            {
                "value": 25082,
                "label": "Aulnay-sur-Mauldre",
                "label_ar": "أولناي سور مولدر",
                "label_fr": "Aulnay-sur-Mauldre"
            },
            {
                "value": 25083,
                "label": "Sucy-en-Brie",
                "label_ar": "Sucy-en-Brie",
                "label_fr": "Sucy-en-Brie"
            },
            {
                "value": 25084,
                "label": "Le Pre-Saint-Gervais",
                "label_ar": "لو بري سانت جيرفيه",
                "label_fr": "Le Pré-Saint-Gervais"
            },
            {
                "value": 25085,
                "label": "Saint-Michel-sur-Orge",
                "label_ar": "سان ميشيل سور أورج",
                "label_fr": "Saint-Michel-sur-Orge"
            },
            {
                "value": 25086,
                "label": "Maincy",
                "label_ar": "مينسي",
                "label_fr": "Maincy"
            },
            {
                "value": 25087,
                "label": "Aucamville",
                "label_ar": "أوكامفيل",
                "label_fr": "Aucamville"
            },
            {
                "value": 25088,
                "label": "Mitry-Mory",
                "label_ar": "ميتري موري",
                "label_fr": "Mitry-Mory"
            },
            {
                "value": 25089,
                "label": "Nanteuil-le-Haudouin",
                "label_ar": "Nanteuil-le-Haudouin",
                "label_fr": "Nanteuil-le-Haudouin"
            },
            {
                "value": 25090,
                "label": "Avrainville",
                "label_ar": "أفرينفيل",
                "label_fr": "Avrainville"
            },
            {
                "value": 25091,
                "label": "Ollainville",
                "label_ar": "أولينفيل",
                "label_fr": "Ollainville"
            },
            {
                "value": 25092,
                "label": "Sarcelles",
                "label_ar": "Sarcelles",
                "label_fr": "Sarcelles"
            },
            {
                "value": 25093,
                "label": "Vert-le-Grand",
                "label_ar": "فيرت لو جراند",
                "label_fr": "Vert-le-Grand"
            },
            {
                "value": 25094,
                "label": "Louveciennes",
                "label_ar": "لوفيسين",
                "label_fr": "Louveciennes"
            },
            {
                "value": 25095,
                "label": "Rumilly",
                "label_ar": "روميلي",
                "label_fr": "Rumilly"
            },
            {
                "value": 25096,
                "label": "Montfermeil",
                "label_ar": "مونتفيرميل",
                "label_fr": "Montfermeil"
            },
            {
                "value": 25097,
                "label": "La Ferte-sous-Jouarre",
                "label_ar": "La Ferte-sous-Jouarre",
                "label_fr": "La Ferté-sous-Jouarre"
            },
            {
                "value": 25098,
                "label": "Bois-le-Roi",
                "label_ar": "Bois-le-Roi",
                "label_fr": "Bois-le-Roi"
            },
            {
                "value": 25099,
                "label": "Champcerie",
                "label_ar": "تشامبسيري",
                "label_fr": "Champcerie"
            },
            {
                "value": 25100,
                "label": "Montivilliers",
                "label_ar": "مونتيفيلييه",
                "label_fr": "Montivilliers"
            },
            {
                "value": 25101,
                "label": "Villeneuve-le-Roi",
                "label_ar": "فيلنوف لو روي",
                "label_fr": "Villeneuve-le-Roi"
            },
            {
                "value": 25102,
                "label": "Villeneuve-la-Garenne",
                "label_ar": "فيلنوف لا جارين",
                "label_fr": "Villeneuve-la-Garenne"
            },
            {
                "value": 25103,
                "label": "Montigny-sur-Loing",
                "label_ar": "مونتيني سور لونج",
                "label_fr": "Montigny-sur-Loing"
            },
            {
                "value": 25104,
                "label": "Villepinte",
                "label_ar": "فيلبينت",
                "label_fr": "Villepinte"
            },
            {
                "value": 25105,
                "label": "Charenton-le-Pont",
                "label_ar": "تشارنتون لو بونت",
                "label_fr": "Charenton-le-Pont"
            },
            {
                "value": 25106,
                "label": "Villiers-le-Bel",
                "label_ar": "فيلير لو بيل",
                "label_fr": "Villiers-le-Bel"
            },
            {
                "value": 25107,
                "label": "Chaville",
                "label_ar": "شافيل",
                "label_fr": "Chaville"
            },
            {
                "value": 25108,
                "label": "Montesson",
                "label_ar": "مونتيسون",
                "label_fr": "Montesson"
            },
            {
                "value": 25109,
                "label": "Navarrenx",
                "label_ar": "نافارينكس",
                "label_fr": "Navarrenx"
            },
            {
                "value": 25110,
                "label": "Ogenne-Camptort",
                "label_ar": "أوجين كامبتورت",
                "label_fr": "Ogenne-Camptort"
            },
            {
                "value": 25111,
                "label": "Gaillac-Toulza",
                "label_ar": "جيلاك تولزا",
                "label_fr": "Gaillac-Toulza"
            },
            {
                "value": 25112,
                "label": "Gueugnon",
                "label_ar": "Gueugnon",
                "label_fr": "Gueugnon"
            },
            {
                "value": 25113,
                "label": "Épinay-sur-Orge",
                "label_ar": "إبيناي سور أورج",
                "label_fr": "Épinay-sur-Orge"
            },
            {
                "value": 25114,
                "label": "Rebais",
                "label_ar": "ريبيز",
                "label_fr": "Rebais"
            },
            {
                "value": 25115,
                "label": "Saint-Mars-de-Locquenay",
                "label_ar": "Saint-Mars-de-Locquenay",
                "label_fr": "Saint-Mars-de-Locquenay"
            },
            {
                "value": 25116,
                "label": "Vernou-sur-Brenne",
                "label_ar": "فيرنو سور برين",
                "label_fr": "Vernou-sur-Brenne"
            },
            {
                "value": 25117,
                "label": "Chateauneuf-de-Bordette",
                "label_ar": "Chateauneuf-de-Bordette",
                "label_fr": "Châteauneuf-de-Bordette"
            },
            {
                "value": 25118,
                "label": "Apt",
                "label_ar": "ملائم",
                "label_fr": "Apte"
            },
            {
                "value": 25119,
                "label": "Saint-Peray",
                "label_ar": "سان بيري",
                "label_fr": "Saint-Peray"
            },
            {
                "value": 25120,
                "label": "Vibraye",
                "label_ar": "فيبراي",
                "label_fr": "Vibraye"
            },
            {
                "value": 25121,
                "label": "Vias",
                "label_ar": "فياس",
                "label_fr": "Vias"
            },
            {
                "value": 25122,
                "label": "Montmoreau-Saint-Cybard",
                "label_ar": "مونتمورو سان سايبارد",
                "label_fr": "Montmoreau-Saint-Cybard"
            },
            {
                "value": 25123,
                "label": "Vitrac-Saint-Vincent",
                "label_ar": "فيتراك سانت فنسنت",
                "label_fr": "Vitrac-Saint-Vincent"
            },
            {
                "value": 25124,
                "label": "Brigueuil",
                "label_ar": "بريجيويل",
                "label_fr": "Brigueuil"
            },
            {
                "value": 25125,
                "label": "Chasseneuil-sur-Bonnieure",
                "label_ar": "شاسينويل سور بونيور",
                "label_fr": "Chasseneuil-sur-Bonnieure"
            },
            {
                "value": 25126,
                "label": "Estrablin",
                "label_ar": "استرابلين",
                "label_fr": "Estrablin"
            },
            {
                "value": 25127,
                "label": "Chatte",
                "label_ar": "شات",
                "label_fr": "Chatte"
            },
            {
                "value": 25128,
                "label": "Saint-Baudille-de-la-Tour",
                "label_ar": "سان بودل دو لا تور",
                "label_fr": "Saint-Baudille-de-la-Tour"
            },
            {
                "value": 25129,
                "label": "Moissieu-sur-Dolon",
                "label_ar": "Moissieu-sur-Dolon",
                "label_fr": "Moissieu-sur-Dolon"
            },
            {
                "value": 25130,
                "label": "Saint-Front",
                "label_ar": "سان فرونت",
                "label_fr": "Saint-Front"
            },
            {
                "value": 25131,
                "label": "Monsteroux-Milieu",
                "label_ar": "الوحش ميليو",
                "label_fr": "Monsteroux-Milieu"
            },
            {
                "value": 25132,
                "label": "Fouquebrune",
                "label_ar": "فوكبرون",
                "label_fr": "Fouquebrune"
            },
            {
                "value": 25133,
                "label": "Les Gours",
                "label_ar": "ليه جور",
                "label_fr": "Les Gours"
            },
            {
                "value": 25134,
                "label": "Realville",
                "label_ar": "ريلفيل",
                "label_fr": "Realville"
            },
            {
                "value": 25135,
                "label": "Auzielle",
                "label_ar": "اوزيل",
                "label_fr": "Auzielle"
            },
            {
                "value": 25136,
                "label": "Plaisance-du-Touch",
                "label_ar": "Plaisance-du-Touch",
                "label_fr": "Plaisance-du-Touch"
            },
            {
                "value": 25137,
                "label": "Montreuil-aux-Lions",
                "label_ar": "مونتروي أوكس الأسود",
                "label_fr": "Montreuil-aux-Lions"
            },
            {
                "value": 25138,
                "label": "Trilport",
                "label_ar": "تريلبورت",
                "label_fr": "Trilport"
            },
            {
                "value": 25139,
                "label": "Mezieres-sur-Seine",
                "label_ar": "Mezieres-sur-Seine",
                "label_fr": "Mézières-sur-Seine"
            },
            {
                "value": 25140,
                "label": "Reilly",
                "label_ar": "رايلي",
                "label_fr": "Reilly"
            },
            {
                "value": 25141,
                "label": "Lentilly",
                "label_ar": "عدس",
                "label_fr": "Lentilly"
            },
            {
                "value": 25142,
                "label": "Cappelle-la-Grande",
                "label_ar": "كابيل لا غراندي",
                "label_fr": "Cappelle-la-Grande"
            },
            {
                "value": 25143,
                "label": "Fort-Mardyck",
                "label_ar": "فورت مارديك",
                "label_fr": "Fort-Mardyck"
            },
            {
                "value": 25144,
                "label": "Boncourt-sur-Meuse",
                "label_ar": "بونكورت سور ميوز",
                "label_fr": "Boncourt-sur-Meuse"
            },
            {
                "value": 25145,
                "label": "Bage-la-Ville",
                "label_ar": "باج لا فيل",
                "label_fr": "Bage-la-Ville"
            },
            {
                "value": 25146,
                "label": "Certines",
                "label_ar": "شهادات",
                "label_fr": "Certines"
            },
            {
                "value": 25147,
                "label": "Thil",
                "label_ar": "ثيل",
                "label_fr": "Thil"
            },
            {
                "value": 25148,
                "label": "Chevry",
                "label_ar": "شيفري",
                "label_fr": "Chevry"
            },
            {
                "value": 25149,
                "label": "Pougny",
                "label_ar": "بوجني",
                "label_fr": "Pougny"
            },
            {
                "value": 25150,
                "label": "Belley",
                "label_ar": "بيلي",
                "label_fr": "Belley"
            },
            {
                "value": 25151,
                "label": "Rance",
                "label_ar": "رانس",
                "label_fr": "Rance"
            },
            {
                "value": 25152,
                "label": "Dangeul",
                "label_ar": "دانجول",
                "label_fr": "Dangeul"
            },
            {
                "value": 25153,
                "label": "Saint-Fregant",
                "label_ar": "سان فريجانت",
                "label_fr": "Saint-Frégant"
            },
            {
                "value": 25154,
                "label": "Gex",
                "label_ar": "جيكس",
                "label_fr": "Gex"
            },
            {
                "value": 25155,
                "label": "Vire",
                "label_ar": "فير",
                "label_fr": "Vire"
            },
            {
                "value": 25156,
                "label": "Saint-Didier-d'Aussiat",
                "label_ar": "سان ديدييه دوسيات",
                "label_fr": "Saint-Didier-d'Aussiat"
            },
            {
                "value": 25157,
                "label": "Ars-sur-Formans",
                "label_ar": "Ars-sur-Formans",
                "label_fr": "Ars-sur-Formans"
            },
            {
                "value": 25158,
                "label": "Saint-Germain-de-Joux",
                "label_ar": "سان جيرمان دي جو",
                "label_fr": "Saint-Germain-de-Joux"
            },
            {
                "value": 25159,
                "label": "Bray-sur-Seine",
                "label_ar": "براي سور سين",
                "label_fr": "Bray-sur-Seine"
            },
            {
                "value": 25160,
                "label": "Egligny",
                "label_ar": "إيجليني",
                "label_fr": "Egligny"
            },
            {
                "value": 25161,
                "label": "La Brosse",
                "label_ar": "لا بروس",
                "label_fr": "La Brosse"
            },
            {
                "value": 25162,
                "label": "Courtomer",
                "label_ar": "كورتومير",
                "label_fr": "Courtomer"
            },
            {
                "value": 25163,
                "label": "Nangis",
                "label_ar": "نانجيس",
                "label_fr": "Nangis"
            },
            {
                "value": 25164,
                "label": "Meigneux",
                "label_ar": "Meigneux",
                "label_fr": "Meigneux"
            },
            {
                "value": 25165,
                "label": "Beligneux",
                "label_ar": "Beligneux",
                "label_fr": "Béligneux"
            },
            {
                "value": 25166,
                "label": "Pizay",
                "label_ar": "بيزاي",
                "label_fr": "Pizay"
            },
            {
                "value": 25167,
                "label": "Lavours",
                "label_ar": "لافور",
                "label_fr": "Lavours"
            },
            {
                "value": 25168,
                "label": "Etrez",
                "label_ar": "إتريز",
                "label_fr": "Etrez"
            },
            {
                "value": 25169,
                "label": "Ozan",
                "label_ar": "أوزان",
                "label_fr": "Ozan"
            },
            {
                "value": 25170,
                "label": "Echenevex",
                "label_ar": "Echenevex",
                "label_fr": "Échènevex"
            },
            {
                "value": 25171,
                "label": "Rivieres",
                "label_ar": "ريفيير",
                "label_fr": "Rivières"
            },
            {
                "value": 25172,
                "label": "Valbonne",
                "label_ar": "فالبون",
                "label_fr": "Valbonne"
            },
            {
                "value": 25173,
                "label": "Ascq",
                "label_ar": "تصاعدي",
                "label_fr": "Ascq"
            },
            {
                "value": 25174,
                "label": "Saint-Etienne-de-Saint-Geoirs",
                "label_ar": "سانت إتيان دو سان جوير",
                "label_fr": "Saint-Étienne-de-Saint-Geoirs"
            },
            {
                "value": 25175,
                "label": "Labege",
                "label_ar": "لابيج",
                "label_fr": "Labège"
            },
            {
                "value": 25176,
                "label": "Sèvres",
                "label_ar": "سيفر",
                "label_fr": "Sèvres"
            },
            {
                "value": 25177,
                "label": "Mont-Saint-Martin",
                "label_ar": "مونت سانت مارتن",
                "label_fr": "Mont-Saint-Martin"
            },
            {
                "value": 25178,
                "label": "Chateauneuf-de-Gadagne",
                "label_ar": "Chateauneuf-de-Gadagne",
                "label_fr": "Châteauneuf-de-Gadagne"
            },
            {
                "value": 25179,
                "label": "Bohal",
                "label_ar": "بوهال",
                "label_fr": "Bohal"
            },
            {
                "value": 25180,
                "label": "Sens-de-Bretagne",
                "label_ar": "Sens-de-Bretagne",
                "label_fr": "Sens-de-Bretagne"
            },
            {
                "value": 25181,
                "label": "La Crau",
                "label_ar": "لا كراو",
                "label_fr": "La Crau"
            },
            {
                "value": 25182,
                "label": "Vanves",
                "label_ar": "فانفيس",
                "label_fr": "Vanves"
            },
            {
                "value": 25183,
                "label": "La Plaine-Saint-Denis",
                "label_ar": "لا بلين سانت دينيس",
                "label_fr": "La Plaine-Saint-Denis"
            },
            {
                "value": 25184,
                "label": "Crolles",
                "label_ar": "كرولس",
                "label_fr": "Crolles"
            },
            {
                "value": 25185,
                "label": "Bailly-Romainvilliers",
                "label_ar": "بايلي رومانفيلييه",
                "label_fr": "Bailly-Romainvilliers"
            },
            {
                "value": 25186,
                "label": "Sainte-Maxime",
                "label_ar": "سانت ماكسيم",
                "label_fr": "Sainte-Maxime"
            },
            {
                "value": 25187,
                "label": "Rully",
                "label_ar": "رولي",
                "label_fr": "Rully"
            },
            {
                "value": 25188,
                "label": "La Celle-sous-Montmirail",
                "label_ar": "لا سيل سوس مونتميرايل",
                "label_fr": "La Celle-sous-Montmirail"
            },
            {
                "value": 25189,
                "label": "Le Mesnil-Fuguet",
                "label_ar": "لو ميسنيل فوجويت",
                "label_fr": "Le Mesnil-Fuguet"
            },
            {
                "value": 25190,
                "label": "Vieux-Manoir",
                "label_ar": "Vieux-Manoir",
                "label_fr": "Vieux-Manoir"
            },
            {
                "value": 25191,
                "label": "Servian",
                "label_ar": "سيرفيان",
                "label_fr": "Servian"
            },
            {
                "value": 25192,
                "label": "L'Isle-Adam",
                "label_ar": "جزيرة آيل آدم",
                "label_fr": "L'Isle-Adam"
            },
            {
                "value": 25193,
                "label": "Bobigny",
                "label_ar": "بوبيني",
                "label_fr": "Bobigny"
            },
            {
                "value": 25194,
                "label": "Santes",
                "label_ar": "سانتيس",
                "label_fr": "Santes"
            },
            {
                "value": 25195,
                "label": "Bazoncourt",
                "label_ar": "بازونكورت",
                "label_fr": "Bazoncourt"
            },
            {
                "value": 25196,
                "label": "Chavanod",
                "label_ar": "شافانود",
                "label_fr": "Chavanod"
            },
            {
                "value": 25197,
                "label": "Montluel",
                "label_ar": "مونتويل",
                "label_fr": "Montluel"
            },
            {
                "value": 25198,
                "label": "Leaz",
                "label_ar": "لياز",
                "label_fr": "Leaz"
            },
            {
                "value": 25199,
                "label": "Marange-Silvange",
                "label_ar": "مارانج سيلفانج",
                "label_fr": "Marange-Silvange"
            },
            {
                "value": 25200,
                "label": "Nilvange",
                "label_ar": "نيلفانج",
                "label_fr": "Nilvange"
            },
            {
                "value": 25201,
                "label": "Gandrange",
                "label_ar": "غاندرانج",
                "label_fr": "Gandrange"
            },
            {
                "value": 25202,
                "label": "Saint-Germain-du-Puy",
                "label_ar": "سان جيرمان دو بوي",
                "label_fr": "Saint-Germain-du-Puy"
            },
            {
                "value": 25203,
                "label": "Croissy-sur-Seine",
                "label_ar": "كرواسي سور سين",
                "label_fr": "Croissy-sur-Seine"
            },
            {
                "value": 25204,
                "label": "Moulin",
                "label_ar": "مولان",
                "label_fr": "Moulin"
            },
            {
                "value": 25205,
                "label": "Les Hotelleries",
                "label_ar": "ليه هوتيليريس",
                "label_fr": "Les Hotelleries"
            },
            {
                "value": 25206,
                "label": "Marie",
                "label_ar": "ماري",
                "label_fr": "Marie"
            },
            {
                "value": 25207,
                "label": "Chilly",
                "label_ar": "حار",
                "label_fr": "Froid"
            },
            {
                "value": 25208,
                "label": "Courcouronnes",
                "label_ar": "كوركورون",
                "label_fr": "Courcouronnes"
            },
            {
                "value": 25209,
                "label": "Crillon-le-Brave",
                "label_ar": "كريلون لو بريف",
                "label_fr": "Crillon-le-Brave"
            },
            {
                "value": 25210,
                "label": "Vesly",
                "label_ar": "فيسلي",
                "label_fr": "Vesly"
            },
            {
                "value": 25211,
                "label": "Chereng",
                "label_ar": "شيرينغ",
                "label_fr": "Chereng"
            },
            {
                "value": 25212,
                "label": "Berry-Bouy",
                "label_ar": "بيري بوي",
                "label_fr": "Berry-Bouy"
            },
            {
                "value": 25213,
                "label": "Puy-Guillaume",
                "label_ar": "بوي غيوم",
                "label_fr": "Puy-Guillaume"
            },
            {
                "value": 25214,
                "label": "Ermont",
                "label_ar": "إيرمونت",
                "label_fr": "Ermont"
            },
            {
                "value": 25215,
                "label": "Le Plessis-Bouchard",
                "label_ar": "لو بليسيس بوشار",
                "label_fr": "Le Plessis-Bouchard"
            },
            {
                "value": 25216,
                "label": "Saint-Max",
                "label_ar": "سانت ماكس",
                "label_fr": "Saint-Max"
            },
            {
                "value": 25217,
                "label": "Plan-de-Cuques",
                "label_ar": "بلان دي كوك",
                "label_fr": "Plan-de-Cuques"
            },
            {
                "value": 25218,
                "label": "Malzeville",
                "label_ar": "مالزفيل",
                "label_fr": "Malzeville"
            },
            {
                "value": 25219,
                "label": "Bihorel",
                "label_ar": "بيهوريل",
                "label_fr": "Bihorel"
            },
            {
                "value": 25220,
                "label": "Romagnat",
                "label_ar": "رومانيا",
                "label_fr": "Romagnat"
            },
            {
                "value": 25221,
                "label": "La Norville",
                "label_ar": "لا نورفيل",
                "label_fr": "La Norville"
            },
            {
                "value": 25222,
                "label": "Seyssinet-Pariset",
                "label_ar": "سيسينت باريزيه",
                "label_fr": "Seyssinet-Pariset"
            },
            {
                "value": 25223,
                "label": "Carqueiranne",
                "label_ar": "Carqueiranne",
                "label_fr": "Carqueiranne"
            },
            {
                "value": 25224,
                "label": "Laye",
                "label_ar": "لاي",
                "label_fr": "Laye"
            },
            {
                "value": 25225,
                "label": "Bry-sur-Marne",
                "label_ar": "بري سور مارن",
                "label_fr": "Bry-sur-Marne"
            },
            {
                "value": 25226,
                "label": "Jarville-la-Malgrange",
                "label_ar": "جارفيل لا مالجرانج",
                "label_fr": "Jarville-la-Malgrange"
            },
            {
                "value": 25227,
                "label": "Pont-a-Vendin",
                "label_ar": "Pont-a-Vendin",
                "label_fr": "Pont-à-Vendin"
            },
            {
                "value": 25228,
                "label": "Ares",
                "label_ar": "آريس",
                "label_fr": "Ares"
            },
            {
                "value": 25229,
                "label": "Peyrehorade",
                "label_ar": "بيرهورد",
                "label_fr": "Peyrehorade"
            },
            {
                "value": 25230,
                "label": "Meythet",
                "label_ar": "ميثت",
                "label_fr": "Meythet"
            },
            {
                "value": 25231,
                "label": "Joeuf",
                "label_ar": "جوف",
                "label_fr": "Joeuf"
            },
            {
                "value": 25232,
                "label": "Breuillet",
                "label_ar": "بريوليت",
                "label_fr": "Breuillet"
            },
            {
                "value": 25233,
                "label": "Saint-Martin-de-Bernegoue",
                "label_ar": "سان مارتن دي بيرنيغوي",
                "label_fr": "Saint-Martin-de-Bernegoue"
            },
            {
                "value": 25234,
                "label": "Lillebonne",
                "label_ar": "ليلبون",
                "label_fr": "Lillebonne"
            },
            {
                "value": 25235,
                "label": "Le Muy",
                "label_ar": "لو موي",
                "label_fr": "Le Muy"
            },
            {
                "value": 25236,
                "label": "Carbonne",
                "label_ar": "كاربون",
                "label_fr": "Carbonne"
            },
            {
                "value": 25237,
                "label": "Bonifacio",
                "label_ar": "بونيفاسيو",
                "label_fr": "Bonifacio"
            },
            {
                "value": 25238,
                "label": "Veyre-Monton",
                "label_ar": "فير مونتون",
                "label_fr": "Veyre-Monton"
            },
            {
                "value": 25239,
                "label": "Escalquens",
                "label_ar": "إسكالكوينز",
                "label_fr": "Escalquens"
            },
            {
                "value": 25240,
                "label": "Aubigny-sur-Nère",
                "label_ar": "أوبيجني سور نير",
                "label_fr": "Aubigny-sur-Nère"
            },
            {
                "value": 25241,
                "label": "Gas",
                "label_ar": "غاز",
                "label_fr": "Gaz"
            },
            {
                "value": 25242,
                "label": "Monchy-Saint-Eloi",
                "label_ar": "مونشي سانت إلوي",
                "label_fr": "Monchy-Saint-Eloi"
            },
            {
                "value": 25243,
                "label": "Fitz-James",
                "label_ar": "فيتز جيمس",
                "label_fr": "Fitz-James"
            },
            {
                "value": 25244,
                "label": "Roquefort",
                "label_ar": "روكفور",
                "label_fr": "roquefort"
            },
            {
                "value": 25245,
                "label": "Royat",
                "label_ar": "رويات",
                "label_fr": "Royat"
            },
            {
                "value": 25246,
                "label": "Raon-l'Etape",
                "label_ar": "راون لاتيب",
                "label_fr": "Raon-l'Etape"
            },
            {
                "value": 25247,
                "label": "Mirecourt",
                "label_ar": "ميركور",
                "label_fr": "Mirecourt"
            },
            {
                "value": 25248,
                "label": "Borgo",
                "label_ar": "بورجو",
                "label_fr": "Borgo"
            },
            {
                "value": 25249,
                "label": "Incheville",
                "label_ar": "انشفيل",
                "label_fr": "Incheville"
            },
            {
                "value": 25250,
                "label": "Cusset",
                "label_ar": "كوسيت",
                "label_fr": "Cusset"
            },
            {
                "value": 25251,
                "label": "Savieres",
                "label_ar": "المنقذون",
                "label_fr": "Savières"
            },
            {
                "value": 25252,
                "label": "Remy",
                "label_ar": "ريمي",
                "label_fr": "Remy"
            },
            {
                "value": 25253,
                "label": "Perrecy-les-Forges",
                "label_ar": "بيرسي ليه فورج",
                "label_fr": "Perrecy-les-Forges"
            },
            {
                "value": 25254,
                "label": "Andeville",
                "label_ar": "أندفيل",
                "label_fr": "Andeville"
            },
            {
                "value": 25255,
                "label": "Franqueville",
                "label_ar": "فرانكفيل",
                "label_fr": "Franqueville"
            },
            {
                "value": 25256,
                "label": "Souppes-sur-Loing",
                "label_ar": "Souppes-sur-Loing",
                "label_fr": "Souppes-sur-Loing"
            },
            {
                "value": 25257,
                "label": "Villeneuve-Loubet",
                "label_ar": "فيلنوف لوبيه",
                "label_fr": "Villeneuve-Loubet"
            },
            {
                "value": 25258,
                "label": "Saint-Germer-de-Fly",
                "label_ar": "سان جيرمر دي فلاي",
                "label_fr": "Saint-Germer-de-Fly"
            },
            {
                "value": 25259,
                "label": "Villy-Bocage",
                "label_ar": "فيلي بوكاج",
                "label_fr": "Villy-Bocage"
            },
            {
                "value": 25260,
                "label": "Aix-Noulette",
                "label_ar": "إيكس نوليت",
                "label_fr": "Aix-Noulette"
            },
            {
                "value": 25261,
                "label": "Petite-Foret",
                "label_ar": "بيتيت فوريه",
                "label_fr": "Petite-Foret"
            },
            {
                "value": 25262,
                "label": "Saussines",
                "label_ar": "سوسين",
                "label_fr": "Saussines"
            },
            {
                "value": 25263,
                "label": "Bages",
                "label_ar": "باغس",
                "label_fr": "Bages"
            },
            {
                "value": 25264,
                "label": "Villers-Cotterêts",
                "label_ar": "Villers-Cotterêts",
                "label_fr": "Villers-Cotterêts"
            },
            {
                "value": 25265,
                "label": "Bettignies",
                "label_ar": "بيتيني",
                "label_fr": "Bettignies"
            },
            {
                "value": 25266,
                "label": "Soignolles-en-Brie",
                "label_ar": "Soignolles-en-Brie",
                "label_fr": "Soignolles-en-Brie"
            },
            {
                "value": 25267,
                "label": "Hirson",
                "label_ar": "هيرسون",
                "label_fr": "Hirson"
            },
            {
                "value": 25268,
                "label": "Rochetoirin",
                "label_ar": "روشيتورين",
                "label_fr": "Rochetoirin"
            },
            {
                "value": 25269,
                "label": "Dourdan",
                "label_ar": "دوردان",
                "label_fr": "Dourdan"
            },
            {
                "value": 25270,
                "label": "Bletterans",
                "label_ar": "Bletterans",
                "label_fr": "Bletterans"
            },
            {
                "value": 25271,
                "label": "Teloche",
                "label_ar": "تيلوش",
                "label_fr": "Teloche"
            },
            {
                "value": 25272,
                "label": "Senonches",
                "label_ar": "Senonches",
                "label_fr": "Senonches"
            },
            {
                "value": 25273,
                "label": "Jouhet",
                "label_ar": "جوهت",
                "label_fr": "Jouhet"
            },
            {
                "value": 25274,
                "label": "Saint-Etienne-de-Crossey",
                "label_ar": "سانت إتيان دي كروسي",
                "label_fr": "Saint-Étienne-de-Crossey"
            },
            {
                "value": 25275,
                "label": "Charancieu",
                "label_ar": "شارانسيو",
                "label_fr": "Charancieu"
            },
            {
                "value": 25276,
                "label": "Morestel",
                "label_ar": "موريستل",
                "label_fr": "Morestel"
            },
            {
                "value": 25277,
                "label": "Tullins",
                "label_ar": "تولينز",
                "label_fr": "Tullins"
            },
            {
                "value": 25278,
                "label": "Charavines",
                "label_ar": "شارافين",
                "label_fr": "Charavines"
            },
            {
                "value": 25279,
                "label": "Les Abrets",
                "label_ar": "ليه أبريتس",
                "label_fr": "Les Abrets"
            },
            {
                "value": 25280,
                "label": "Chirens",
                "label_ar": "Chirens",
                "label_fr": "Chirens"
            },
            {
                "value": 25281,
                "label": "Saint-Geoire-en-Valdaine",
                "label_ar": "سان جوار أون فالدين",
                "label_fr": "Saint-Geoire-en-Valdaine"
            },
            {
                "value": 25282,
                "label": "Oyeu",
                "label_ar": "أويو",
                "label_fr": "Oyeu"
            },
            {
                "value": 25283,
                "label": "Saint-Jean-de-Moirans",
                "label_ar": "سان جان دي مواران",
                "label_fr": "Saint-Jean-de-Moirans"
            },
            {
                "value": 25284,
                "label": "Villabe",
                "label_ar": "فيلاب",
                "label_fr": "Villabe"
            },
            {
                "value": 25285,
                "label": "Querrieu",
                "label_ar": "كويريو",
                "label_fr": "Querrieu"
            },
            {
                "value": 25286,
                "label": "Feucherolles",
                "label_ar": "Feucherolles",
                "label_fr": "Feucherolles"
            },
            {
                "value": 25287,
                "label": "Rocquencourt",
                "label_ar": "روكينكور",
                "label_fr": "Rocquencourt"
            },
            {
                "value": 25288,
                "label": "Crouy-sur-Ourcq",
                "label_ar": "Crouy-sur-Ourcq",
                "label_fr": "Crouy-sur-Ourcq"
            },
            {
                "value": 25289,
                "label": "Voisins-le-Bretonneux",
                "label_ar": "Voisins-le-Bretonneux",
                "label_fr": "Voisins-le-Bretonneux"
            },
            {
                "value": 25290,
                "label": "Saint-Christophe-sur-Avre",
                "label_ar": "سان كريستوف سور افر",
                "label_fr": "Saint-Christophe-sur-Avre"
            },
            {
                "value": 25291,
                "label": "Poilly-lez-Gien",
                "label_ar": "بويلي ليز جين",
                "label_fr": "Poilly-lez-Gien"
            },
            {
                "value": 25292,
                "label": "Ambérieu-en-Bugey",
                "label_ar": "Ambérieu-en-Bugey",
                "label_fr": "Ambérieu-en-Bugey"
            },
            {
                "value": 25293,
                "label": "Eschau",
                "label_ar": "إيشاو",
                "label_fr": "Eschau"
            },
            {
                "value": 25294,
                "label": "Fegersheim",
                "label_ar": "فيجرشيم",
                "label_fr": "Fegersheim"
            },
            {
                "value": 25295,
                "label": "Pfastatt",
                "label_ar": "بفاستات",
                "label_fr": "Pfastatt"
            },
            {
                "value": 25296,
                "label": "Wittenheim",
                "label_ar": "ويتنهايم",
                "label_fr": "Wittenheim"
            },
            {
                "value": 25297,
                "label": "Erstein",
                "label_ar": "ارشتاين",
                "label_fr": "Erstein"
            },
            {
                "value": 25298,
                "label": "Rhinau",
                "label_ar": "رينو",
                "label_fr": "Rhinau"
            },
            {
                "value": 25299,
                "label": "Gerstheim",
                "label_ar": "غيرستيم",
                "label_fr": "Gerstheim"
            },
            {
                "value": 25300,
                "label": "Lignerolles",
                "label_ar": "Lignerolles",
                "label_fr": "Lignerolles"
            },
            {
                "value": 25301,
                "label": "Mirepoix",
                "label_ar": "ميريبويكس",
                "label_fr": "Mirepoix"
            },
            {
                "value": 25302,
                "label": "Saint-Symphorien-sur-Saone",
                "label_ar": "سان سيمفوريان سور سون",
                "label_fr": "Saint-Symphorien-sur-Saône"
            },
            {
                "value": 25303,
                "label": "Le Puy-en-Velay",
                "label_ar": "لو بوي أون فيلاي",
                "label_fr": "Le Puy-en-Velay"
            },
            {
                "value": 25304,
                "label": "Les Fontaines",
                "label_ar": "ليه فونتين",
                "label_fr": "Les Fontaines"
            },
            {
                "value": 25305,
                "label": "Noeux-les-Auxi",
                "label_ar": "Noeux-les-Auxi",
                "label_fr": "Noeux-les-Auxi"
            },
            {
                "value": 25306,
                "label": "Confolens",
                "label_ar": "Confolens",
                "label_fr": "Confolens"
            },
            {
                "value": 25307,
                "label": "Ruffec",
                "label_ar": "روفيك",
                "label_fr": "Ruffec"
            },
            {
                "value": 25308,
                "label": "Woerth",
                "label_ar": "وورث",
                "label_fr": "Woerth"
            },
            {
                "value": 25309,
                "label": "Wissembourg",
                "label_ar": "ويسمبرغ",
                "label_fr": "Wissembourg"
            },
            {
                "value": 25310,
                "label": "La Chapelle-de-Guinchay",
                "label_ar": "لا شابيل دي جوينشاي",
                "label_fr": "La Chapelle-de-Guinchay"
            },
            {
                "value": 25311,
                "label": "Ruelle-sur-Touvre",
                "label_ar": "رويل سور توفري",
                "label_fr": "Ruelle-sur-Touvre"
            },
            {
                "value": 25312,
                "label": "Diemeringen",
                "label_ar": "ديمينرينجن",
                "label_fr": "Diemeringen"
            },
            {
                "value": 25313,
                "label": "Soufflenheim",
                "label_ar": "سوفلينهايم",
                "label_fr": "Soufflenheim"
            },
            {
                "value": 25314,
                "label": "Dettwiller",
                "label_ar": "ديتويلر",
                "label_fr": "Dettwiller"
            },
            {
                "value": 25315,
                "label": "Chemin-d'Aisey",
                "label_ar": "Chemin-d'Aisey",
                "label_fr": "Chemin-d'Aisey"
            },
            {
                "value": 25316,
                "label": "Cognac",
                "label_ar": "كونياك",
                "label_fr": "Cognac"
            },
            {
                "value": 25317,
                "label": "Saint-Hilaire-Cusson-la-Valmitte",
                "label_ar": "سان هيلير كوسون لا فالميت",
                "label_fr": "Saint-Hilaire-Cusson-la-Valmitte"
            },
            {
                "value": 25318,
                "label": "Chateauneuf-les-Martigues",
                "label_ar": "Chateauneuf-les-Martigues",
                "label_fr": "Châteauneuf-les-Martigues"
            },
            {
                "value": 25319,
                "label": "La Rochefoucauld",
                "label_ar": "لاروشفوكولد",
                "label_fr": "La Rochefoucauld"
            },
            {
                "value": 25320,
                "label": "Le Gond",
                "label_ar": "لو جوند",
                "label_fr": "Le Gond"
            },
            {
                "value": 25321,
                "label": "Ingwiller",
                "label_ar": "إنجويلر",
                "label_fr": "Ingwiller"
            },
            {
                "value": 25322,
                "label": "Fruges",
                "label_ar": "فروج",
                "label_fr": "Fruges"
            },
            {
                "value": 25323,
                "label": "Beaulieu-sur-Dordogne",
                "label_ar": "بوليو سور دوردوني",
                "label_fr": "Beaulieu-sur-Dordogne"
            },
            {
                "value": 25324,
                "label": "Objat",
                "label_ar": "أوجات",
                "label_fr": "Objat"
            },
            {
                "value": 25325,
                "label": "Bourbon-l'Archambault",
                "label_ar": "بوربون لارشامبولت",
                "label_fr": "Bourbon-l'Archambault"
            },
            {
                "value": 25326,
                "label": "Marcillac",
                "label_ar": "مارسيلاك",
                "label_fr": "Marcillac"
            },
            {
                "value": 25327,
                "label": "Sarre",
                "label_ar": "سار",
                "label_fr": "Sarre"
            },
            {
                "value": 25328,
                "label": "Nexon",
                "label_ar": "نيكسون",
                "label_fr": "Nexon"
            },
            {
                "value": 25329,
                "label": "Allassac",
                "label_ar": "ألاساك",
                "label_fr": "Allassac"
            },
            {
                "value": 25330,
                "label": "La Couronne",
                "label_ar": "لا كورون",
                "label_fr": "La Couronne"
            },
            {
                "value": 25331,
                "label": "Argentiere",
                "label_ar": "أرجنتير",
                "label_fr": "Argentière"
            },
            {
                "value": 25332,
                "label": "Reichshoffen",
                "label_ar": "Reichshoffen",
                "label_fr": "Reichshoffen"
            },
            {
                "value": 25333,
                "label": "Le Chambon",
                "label_ar": "لو شامبون",
                "label_fr": "Le Chambon"
            },
            {
                "value": 25334,
                "label": "Drulingen",
                "label_ar": "درولينجن",
                "label_fr": "Drulingen"
            },
            {
                "value": 25335,
                "label": "La Gaillarde",
                "label_ar": "لا جيلارد",
                "label_fr": "La Gaillarde"
            },
            {
                "value": 25336,
                "label": "Segonzac",
                "label_ar": "سيجونزاك",
                "label_fr": "Segonzac"
            },
            {
                "value": 25337,
                "label": "Cize",
                "label_ar": "سيز",
                "label_fr": "Cize"
            },
            {
                "value": 25338,
                "label": "Chalon",
                "label_ar": "شالون",
                "label_fr": "Chalon"
            },
            {
                "value": 25339,
                "label": "Munster",
                "label_ar": "مونستر",
                "label_fr": "Munster"
            },
            {
                "value": 25340,
                "label": "Chalais",
                "label_ar": "تشاليس",
                "label_fr": "Chalais"
            },
            {
                "value": 25341,
                "label": "Lourdes",
                "label_ar": "لورد",
                "label_fr": "Lourdes"
            },
            {
                "value": 25342,
                "label": "Mazan",
                "label_ar": "مازان",
                "label_fr": "Mazan"
            },
            {
                "value": 25343,
                "label": "Romaine",
                "label_ar": "رومين",
                "label_fr": "Romaine"
            },
            {
                "value": 25344,
                "label": "Eccles",
                "label_ar": "اكليس",
                "label_fr": "Eccles"
            },
            {
                "value": 25345,
                "label": "Thiers",
                "label_ar": "تيير",
                "label_fr": "Thiers"
            },
            {
                "value": 25346,
                "label": "Issoire",
                "label_ar": "إسوار",
                "label_fr": "Issoire"
            },
            {
                "value": 25347,
                "label": "Corbigny",
                "label_ar": "كوربيني",
                "label_fr": "Corbigny"
            },
            {
                "value": 25348,
                "label": "Blesle",
                "label_ar": "بليسل",
                "label_fr": "Blesle"
            },
            {
                "value": 25349,
                "label": "Figeac",
                "label_ar": "فيجياك",
                "label_fr": "Figeac"
            },
            {
                "value": 25350,
                "label": "Clermont",
                "label_ar": "كليرمونت",
                "label_fr": "Clermont"
            },
            {
                "value": 25351,
                "label": "Fortschwihr",
                "label_ar": "فورتشوير",
                "label_fr": "Fortschwihr"
            },
            {
                "value": 25352,
                "label": "Boussac",
                "label_ar": "بوساك",
                "label_fr": "Boussac"
            },
            {
                "value": 25353,
                "label": "Jeumont",
                "label_ar": "جومونت",
                "label_fr": "Jeumont"
            },
            {
                "value": 25354,
                "label": "Montbard",
                "label_ar": "مونبارد",
                "label_fr": "Montbard"
            },
            {
                "value": 25355,
                "label": "Larche",
                "label_ar": "لارش",
                "label_fr": "Larche"
            },
            {
                "value": 25356,
                "label": "Ottmarsheim",
                "label_ar": "Ottmarsheim",
                "label_fr": "Ottmarsheim"
            },
            {
                "value": 25357,
                "label": "Paulhaguet",
                "label_ar": "بولحجت",
                "label_fr": "Paulhaguet"
            },
            {
                "value": 25358,
                "label": "Eymoutiers",
                "label_ar": "Eymoutiers",
                "label_fr": "Eymoutiers"
            },
            {
                "value": 25359,
                "label": "Correze",
                "label_ar": "كوريز",
                "label_fr": "Corrèze"
            },
            {
                "value": 25360,
                "label": "Moulins",
                "label_ar": "مولين",
                "label_fr": "Moulins"
            },
            {
                "value": 25361,
                "label": "Montemboeuf",
                "label_ar": "مونتيمبوف",
                "label_fr": "Montemboeuf"
            },
            {
                "value": 25362,
                "label": "Bort-l'Etang",
                "label_ar": "بورت- l'Etang",
                "label_fr": "Bort-l'Etang"
            },
            {
                "value": 25363,
                "label": "Rochechouart",
                "label_ar": "روششوارت",
                "label_fr": "Rochechouart"
            },
            {
                "value": 25364,
                "label": "Chabanais",
                "label_ar": "شابانايس",
                "label_fr": "Chabanais"
            },
            {
                "value": 25365,
                "label": "Saugues",
                "label_ar": "Saugues",
                "label_fr": "Saugues"
            },
            {
                "value": 25366,
                "label": "Langeac",
                "label_ar": "لانجيك",
                "label_fr": "Langeac"
            },
            {
                "value": 25367,
                "label": "Sauze",
                "label_ar": "سوزي",
                "label_fr": "Sauze"
            },
            {
                "value": 25368,
                "label": "Argentat",
                "label_ar": "أرجينتات",
                "label_fr": "Argentat"
            },
            {
                "value": 25369,
                "label": "Le Dorat",
                "label_ar": "لو دورا",
                "label_fr": "Le Dorat"
            },
            {
                "value": 25370,
                "label": "Le Montel",
                "label_ar": "لو مونتيل",
                "label_fr": "Le Montel"
            },
            {
                "value": 25371,
                "label": "Maringues",
                "label_ar": "مارينجز",
                "label_fr": "Maringues"
            },
            {
                "value": 25372,
                "label": "Castelnau-Durban",
                "label_ar": "كاستيلناو ديربان",
                "label_fr": "Castelnau-Durban"
            },
            {
                "value": 25373,
                "label": "Semeac",
                "label_ar": "شبه",
                "label_fr": "Semeac"
            },
            {
                "value": 25374,
                "label": "Chauny",
                "label_ar": "شوني",
                "label_fr": "Chauny"
            },
            {
                "value": 25375,
                "label": "Tarentaise",
                "label_ar": "Tarentaise",
                "label_fr": "Tarentaise"
            },
            {
                "value": 25376,
                "label": "Tarbes",
                "label_ar": "تارب",
                "label_fr": "Tarbes"
            },
            {
                "value": 25377,
                "label": "Ceyrat",
                "label_ar": "سيرات",
                "label_fr": "Ceyrat"
            },
            {
                "value": 25378,
                "label": "Pouilly",
                "label_ar": "بويي",
                "label_fr": "Pouilly"
            },
            {
                "value": 25379,
                "label": "Saint-Sulpice-le-Vieux",
                "label_ar": "سان سولبيس لو فيو",
                "label_fr": "Saint-Sulpice-le-Vieux"
            },
            {
                "value": 25380,
                "label": "Brazey-en-Plaine",
                "label_ar": "Brazey-en-Plaine",
                "label_fr": "Brazey-en-Plaine"
            },
            {
                "value": 25381,
                "label": "Felletin",
                "label_ar": "فيلين",
                "label_fr": "Felletin"
            },
            {
                "value": 25382,
                "label": "Aurec-sur-Loire",
                "label_ar": "أوريك سور لوار",
                "label_fr": "Aurec-sur-Loire"
            },
            {
                "value": 25383,
                "label": "Monistrol-d'Allier",
                "label_ar": "Monistrol-d'Allier",
                "label_fr": "Monistrol-d'Allier"
            },
            {
                "value": 25384,
                "label": "Arnay-le-Duc",
                "label_ar": "أرناي لو دوك",
                "label_fr": "Arnay-le-Duc"
            },
            {
                "value": 25385,
                "label": "Treignac",
                "label_ar": "تريجناك",
                "label_fr": "Treignac"
            },
            {
                "value": 25386,
                "label": "Nolay",
                "label_ar": "نولاي",
                "label_fr": "Nolay"
            },
            {
                "value": 25387,
                "label": "Cosne-d'Allier",
                "label_ar": "كوسن داليير",
                "label_fr": "Cosne-d'Allier"
            },
            {
                "value": 25388,
                "label": "Besse",
                "label_ar": "بيس",
                "label_fr": "Besse"
            },
            {
                "value": 25389,
                "label": "Le Comte",
                "label_ar": "لو كومت",
                "label_fr": "Le Comte"
            },
            {
                "value": 25390,
                "label": "Saint-Germain-Nuelles",
                "label_ar": "سان جيرمان نويلز",
                "label_fr": "Saint-Germain-Nuelles"
            },
            {
                "value": 25391,
                "label": "Puylaurens",
                "label_ar": "بويلورين",
                "label_fr": "Puylaurens"
            },
            {
                "value": 25392,
                "label": "Jury",
                "label_ar": "هيئة المحلفين",
                "label_fr": "Jury"
            },
            {
                "value": 25393,
                "label": "Salviac",
                "label_ar": "سالفياك",
                "label_fr": "Salviac"
            },
            {
                "value": 25394,
                "label": "Caussade-Riviere",
                "label_ar": "كوساد ريفيير",
                "label_fr": "Caussade-Rivière"
            },
            {
                "value": 25395,
                "label": "Marciac",
                "label_ar": "مارسياك",
                "label_fr": "Marciac"
            },
            {
                "value": 25396,
                "label": "Uzerche",
                "label_ar": "أوزيرشي",
                "label_fr": "Uzerche"
            },
            {
                "value": 25397,
                "label": "Bessines",
                "label_ar": "بيسينس",
                "label_fr": "Bessines"
            },
            {
                "value": 25398,
                "label": "Billom",
                "label_ar": "بيلوم",
                "label_fr": "Billom"
            },
            {
                "value": 25399,
                "label": "Le Monastier-sur-Gazeille",
                "label_ar": "لو موناستير سور جيزيل",
                "label_fr": "Le Monastier-sur-Gazeille"
            },
            {
                "value": 25400,
                "label": "Le Crotoy",
                "label_ar": "لو كروتوي",
                "label_fr": "Le Crotoy"
            },
            {
                "value": 25401,
                "label": "Sélestat",
                "label_ar": "سيليستات",
                "label_fr": "Sélestat"
            },
            {
                "value": 25402,
                "label": "Digne-les-Bains",
                "label_ar": "Digne-les-Bains",
                "label_fr": "Digne-les-Bains"
            },
            {
                "value": 25403,
                "label": "Gignac",
                "label_ar": "جينياك",
                "label_fr": "Gignac"
            },
            {
                "value": 25404,
                "label": "Marignane",
                "label_ar": "مارينيان",
                "label_fr": "Marignane"
            },
            {
                "value": 25405,
                "label": "Baignes-Sainte-Radegonde",
                "label_ar": "بينيس سانت راديغوند",
                "label_fr": "Baignes-Sainte-Radegonde"
            },
            {
                "value": 25406,
                "label": "Pontailler-sur-Saone",
                "label_ar": "Pontailler-sur-Saone",
                "label_fr": "Pontailler-sur-Saône"
            },
            {
                "value": 25407,
                "label": "La Chaise",
                "label_ar": "لا تشيس",
                "label_fr": "La Chaise"
            },
            {
                "value": 25408,
                "label": "Villedieu",
                "label_ar": "فيليديو",
                "label_fr": "Villedieu"
            },
            {
                "value": 25409,
                "label": "Moltifao",
                "label_ar": "مولتيفاو",
                "label_fr": "Moltifao"
            },
            {
                "value": 25410,
                "label": "Hochfelden",
                "label_ar": "Hochfelden",
                "label_fr": "Hochfelden"
            },
            {
                "value": 25411,
                "label": "La Walck",
                "label_ar": "لا والك",
                "label_fr": "La Walck"
            },
            {
                "value": 25412,
                "label": "Chamalières",
                "label_ar": "شاماليير",
                "label_fr": "Chamalières"
            },
            {
                "value": 25413,
                "label": "Tence",
                "label_ar": "تينس",
                "label_fr": "Tence"
            },
            {
                "value": 25414,
                "label": "Sains-du-Nord",
                "label_ar": "سينس دو نورد",
                "label_fr": "Sains-du-Nord"
            },
            {
                "value": 25415,
                "label": "Genlis",
                "label_ar": "جينليس",
                "label_fr": "Genlis"
            },
            {
                "value": 25416,
                "label": "Chateau-Chervix",
                "label_ar": "شاتو شيرفيكس",
                "label_fr": "Château-Chervix"
            },
            {
                "value": 25417,
                "label": "Les Martres",
                "label_ar": "ليه مارتريس",
                "label_fr": "Les Martres"
            },
            {
                "value": 25418,
                "label": "Chatelus",
                "label_ar": "شاتيلوس",
                "label_fr": "Châtelus"
            },
            {
                "value": 25419,
                "label": "Premery",
                "label_ar": "بريميري",
                "label_fr": "Premery"
            },
            {
                "value": 25420,
                "label": "Luzy",
                "label_ar": "لوزي",
                "label_fr": "Luzy"
            },
            {
                "value": 25421,
                "label": "Cerizay",
                "label_ar": "Cerizay",
                "label_fr": "Cerizay"
            },
            {
                "value": 25422,
                "label": "Pamiers",
                "label_ar": "باميرز",
                "label_fr": "Pamiers"
            },
            {
                "value": 25423,
                "label": "Orbey",
                "label_ar": "أوربي",
                "label_fr": "Orbey"
            },
            {
                "value": 25424,
                "label": "Clamecy",
                "label_ar": "البطلينوس",
                "label_fr": "Clamecy"
            },
            {
                "value": 25425,
                "label": "Ambert",
                "label_ar": "امبرت",
                "label_fr": "Ambert"
            },
            {
                "value": 25426,
                "label": "Volvic",
                "label_ar": "فولفيك",
                "label_fr": "Volvic"
            },
            {
                "value": 25427,
                "label": "Brives-sur-Charente",
                "label_ar": "بريس سور شارينت",
                "label_fr": "Brives-sur-Charente"
            },
            {
                "value": 25428,
                "label": "Ahun",
                "label_ar": "أهون",
                "label_fr": "Ahun"
            },
            {
                "value": 25429,
                "label": "Allegre",
                "label_ar": "أليجري",
                "label_fr": "Allegre"
            },
            {
                "value": 25430,
                "label": "Saint-Martin-sur-Arve",
                "label_ar": "سان مارتن سور ارفيه",
                "label_fr": "Saint-Martin-sur-Arve"
            },
            {
                "value": 25431,
                "label": "Saint-Julien-sur-Bibost",
                "label_ar": "سان جوليان سور بيبوست",
                "label_fr": "Saint-Julien-sur-Bibost"
            },
            {
                "value": 25432,
                "label": "Retournac",
                "label_ar": "ريتورناك",
                "label_fr": "Retournac"
            },
            {
                "value": 25433,
                "label": "Illfurth",
                "label_ar": "إيلفورث",
                "label_fr": "Illfurth"
            },
            {
                "value": 25434,
                "label": "Courpiere",
                "label_ar": "كوربير",
                "label_fr": "Courpière"
            },
            {
                "value": 25435,
                "label": "Aigre",
                "label_ar": "إيغري",
                "label_fr": "Aigre"
            },
            {
                "value": 25436,
                "label": "Craponne",
                "label_ar": "كرابون",
                "label_fr": "Craponne"
            },
            {
                "value": 25437,
                "label": "Chatel-Guyon",
                "label_ar": "شاتيل جويون",
                "label_fr": "Châtel-Guyon"
            },
            {
                "value": 25438,
                "label": "Estaires",
                "label_ar": "إستير",
                "label_fr": "Estaires"
            },
            {
                "value": 25439,
                "label": "Le Fousseret",
                "label_ar": "لو فوسيه",
                "label_fr": "Le Fousseret"
            },
            {
                "value": 25440,
                "label": "Warendin",
                "label_ar": "واريندين",
                "label_fr": "Warendin"
            },
            {
                "value": 25441,
                "label": "Oissel",
                "label_ar": "أويسيل",
                "label_fr": "Oissel"
            },
            {
                "value": 25442,
                "label": "Harnes",
                "label_ar": "هارنز",
                "label_fr": "Harnes"
            },
            {
                "value": 25443,
                "label": "Laon",
                "label_ar": "لاون",
                "label_fr": "Laon"
            },
            {
                "value": 25444,
                "label": "Saint-Benin-dAzy",
                "label_ar": "سانت بنين دازي",
                "label_fr": "Saint-Bénin-d'Azy"
            },
            {
                "value": 25445,
                "label": "Gerzat",
                "label_ar": "جرزات",
                "label_fr": "Gerzat"
            },
            {
                "value": 25446,
                "label": "La Charité-sur-Loire",
                "label_ar": "لا شاريتي سور لوار",
                "label_fr": "La Charité-sur-Loire"
            },
            {
                "value": 25447,
                "label": "Benevent-l'Abbaye",
                "label_ar": "Benevent-l'Abbaye",
                "label_fr": "Bénévent-l'Abbaye"
            },
            {
                "value": 25448,
                "label": "Marquion",
                "label_ar": "ماركيون",
                "label_fr": "Marquion"
            },
            {
                "value": 25449,
                "label": "Tournay-sur-Odon",
                "label_ar": "تورناي سور أودون",
                "label_fr": "Tournay-sur-Odon"
            },
            {
                "value": 25450,
                "label": "Saint-Laurent-Rochefort",
                "label_ar": "سان لوران روشفورت",
                "label_fr": "Saint-Laurent-Rochefort"
            },
            {
                "value": 25451,
                "label": "Conde-Sainte-Libiaire",
                "label_ar": "كوندي سانت ليبيير",
                "label_fr": "Condé-Sainte-Libiaire"
            },
            {
                "value": 25452,
                "label": "Brezolles",
                "label_ar": "بريزولس",
                "label_fr": "Brezolles"
            },
            {
                "value": 25453,
                "label": "Apcher",
                "label_ar": "ابشر",
                "label_fr": "Apcher"
            },
            {
                "value": 25454,
                "label": "Bellac",
                "label_ar": "بيلاك",
                "label_fr": "Bellac"
            },
            {
                "value": 25455,
                "label": "Mielan",
                "label_ar": "ميلان",
                "label_fr": "Mielan"
            },
            {
                "value": 25456,
                "label": "La Montagne",
                "label_ar": "لا مونتاني",
                "label_fr": "La Montagne"
            },
            {
                "value": 25457,
                "label": "Meriel",
                "label_ar": "ميريل",
                "label_fr": "Meriel"
            },
            {
                "value": 25458,
                "label": "Arzon",
                "label_ar": "أرزون",
                "label_fr": "Arzon"
            },
            {
                "value": 25459,
                "label": "Montarnaud",
                "label_ar": "مونتارنو",
                "label_fr": "Montarnaud"
            },
            {
                "value": 25460,
                "label": "Cheron",
                "label_ar": "شيرون",
                "label_fr": "Chéron"
            },
            {
                "value": 25461,
                "label": "Le Cres",
                "label_ar": "لو كريس",
                "label_fr": "Le Cres"
            },
            {
                "value": 25462,
                "label": "Sainte-Marie-de-Cuines",
                "label_ar": "سانت ماري دي كوين",
                "label_fr": "Sainte-Marie-de-Cuines"
            },
            {
                "value": 25463,
                "label": "Landos",
                "label_ar": "لاندوس",
                "label_fr": "Landos"
            },
            {
                "value": 25464,
                "label": "Oradour",
                "label_ar": "أورادور",
                "label_fr": "Oradour"
            },
            {
                "value": 25465,
                "label": "Yssingeaux",
                "label_ar": "يسينجو",
                "label_fr": "Yssingeaux"
            },
            {
                "value": 25466,
                "label": "Houplines",
                "label_ar": "خطوط",
                "label_fr": "Houplines"
            },
            {
                "value": 25467,
                "label": "Semur-en-Brionnais",
                "label_ar": "Semur-en-Brionnais",
                "label_fr": "Semur-en-Brionnais"
            },
            {
                "value": 25468,
                "label": "Neuvic",
                "label_ar": "نيوفيك",
                "label_fr": "Neuvic"
            },
            {
                "value": 25469,
                "label": "Grenade",
                "label_ar": "قنبلة يدوية",
                "label_fr": "Grenade"
            },
            {
                "value": 25470,
                "label": "Nantiat",
                "label_ar": "نانتيات",
                "label_fr": "Nantiat"
            },
            {
                "value": 25471,
                "label": "Auzances",
                "label_ar": "اوزانس",
                "label_fr": "Auzances"
            },
            {
                "value": 25472,
                "label": "Lezoux",
                "label_ar": "ليزوكس",
                "label_fr": "Lezoux"
            },
            {
                "value": 25473,
                "label": "Carcopino",
                "label_ar": "كاركوبينو",
                "label_fr": "Carcopino"
            },
            {
                "value": 25474,
                "label": "Audes",
                "label_ar": "Audes",
                "label_fr": "Audes"
            },
            {
                "value": 25475,
                "label": "Arreau",
                "label_ar": "أريو",
                "label_fr": "Arreau"
            },
            {
                "value": 25476,
                "label": "Gondecourt",
                "label_ar": "جونديكورت",
                "label_fr": "Gondecourt"
            },
            {
                "value": 25477,
                "label": "Guerigny",
                "label_ar": "Guerigny",
                "label_fr": "Guérigny"
            },
            {
                "value": 25478,
                "label": "Saint-Didier-sur-Rochefort",
                "label_ar": "سان ديدييه سور روشفور",
                "label_fr": "Saint-Didier-sur-Rochefort"
            },
            {
                "value": 25479,
                "label": "Lacaune",
                "label_ar": "لاكون",
                "label_fr": "Lacaune"
            },
            {
                "value": 25480,
                "label": "Chateaumeillant",
                "label_ar": "شاتيوميلانت",
                "label_fr": "Châteaumeillant"
            },
            {
                "value": 25481,
                "label": "Le Château-dOléron",
                "label_ar": "لو شاتو دوليرون",
                "label_fr": "Le Château-d'Oléron"
            },
            {
                "value": 25482,
                "label": "Dreville",
                "label_ar": "دريفيل",
                "label_fr": "Dreville"
            },
            {
                "value": 25483,
                "label": "Agde",
                "label_ar": "اجد",
                "label_fr": "Agde"
            },
            {
                "value": 25484,
                "label": "Wittelsheim",
                "label_ar": "Wittelsheim",
                "label_fr": "Wittelsheim"
            },
            {
                "value": 25485,
                "label": "Saint-Michel-de-Volangis",
                "label_ar": "سان ميشيل دي فولانجيس",
                "label_fr": "Saint-Michel-de-Volangis"
            },
            {
                "value": 25486,
                "label": "Sombernon",
                "label_ar": "سومبرنون",
                "label_fr": "Sombernon"
            },
            {
                "value": 25487,
                "label": "Cunlhat",
                "label_ar": "كونلات",
                "label_fr": "Cunlhat"
            },
            {
                "value": 25488,
                "label": "Aspet",
                "label_ar": "Aspet",
                "label_fr": "Aspet"
            },
            {
                "value": 25489,
                "label": "Beuvron-en-Auge",
                "label_ar": "بيوفرون أون أوجي",
                "label_fr": "Beuvron-en-Auge"
            },
            {
                "value": 25490,
                "label": "Niederbronn-les-Bains",
                "label_ar": "Niederbronn-les-Bains",
                "label_fr": "Niederbronn-les-Bains"
            },
            {
                "value": 25491,
                "label": "Bouxwiller",
                "label_ar": "Bouxwiller",
                "label_fr": "Bouxwiller"
            },
            {
                "value": 25492,
                "label": "Mutzig",
                "label_ar": "موتزيغ",
                "label_fr": "Mutzig"
            },
            {
                "value": 25493,
                "label": "Porcheresse",
                "label_ar": "بورشريس",
                "label_fr": "Porcheresse"
            },
            {
                "value": 25494,
                "label": "Bugeat",
                "label_ar": "بوجيات",
                "label_fr": "Bugeat"
            },
            {
                "value": 25495,
                "label": "Rouffach",
                "label_ar": "روفاش",
                "label_fr": "Rouffach"
            },
            {
                "value": 25496,
                "label": "Baillargues",
                "label_ar": "بايلارج",
                "label_fr": "Baillargues"
            },
            {
                "value": 25497,
                "label": "Le Pontet",
                "label_ar": "لو بونتيه",
                "label_fr": "Le Pontet"
            },
            {
                "value": 25498,
                "label": "Wintzenheim",
                "label_ar": "وينتزينهايم",
                "label_fr": "Wintzenheim"
            },
            {
                "value": 25499,
                "label": "Kaysersberg",
                "label_ar": "كايزرسبرج",
                "label_fr": "Kaysersberg"
            },
            {
                "value": 25500,
                "label": "Bischwiller",
                "label_ar": "بيشويلر",
                "label_fr": "Bischwiller"
            },
            {
                "value": 25501,
                "label": "Port-Saint-Louis-du-Rhone",
                "label_ar": "بورت سانت لويس دو رون",
                "label_fr": "Port-Saint-Louis-du-Rhône"
            },
            {
                "value": 25502,
                "label": "Castelnaudary",
                "label_ar": "Castelnaudary",
                "label_fr": "Castelnaudary"
            },
            {
                "value": 25503,
                "label": "Saint-Eloy-la-Glaciere",
                "label_ar": "سان إلوي لا جلاسير",
                "label_fr": "Saint-Eloy-la-Glacière"
            },
            {
                "value": 25504,
                "label": "Aigueperse",
                "label_ar": "ايجويرس",
                "label_fr": "Aigueperse"
            },
            {
                "value": 25505,
                "label": "Champeix",
                "label_ar": "شامبيكس",
                "label_fr": "Champeix"
            },
            {
                "value": 25506,
                "label": "Orgerus",
                "label_ar": "أورجيروس",
                "label_fr": "Orgerus"
            },
            {
                "value": 25507,
                "label": "Blaye",
                "label_ar": "بلاى",
                "label_fr": "Blaye"
            },
            {
                "value": 25508,
                "label": "Cere",
                "label_ar": "قير",
                "label_fr": "Cire"
            },
            {
                "value": 25509,
                "label": "Givet",
                "label_ar": "جيفيت",
                "label_fr": "Givet"
            },
            {
                "value": 25510,
                "label": "Villefagnan",
                "label_ar": "فيلفانيان",
                "label_fr": "Villefagnan"
            },
            {
                "value": 25511,
                "label": "Rene",
                "label_ar": "رينيه",
                "label_fr": "René"
            },
            {
                "value": 25512,
                "label": "Septeuil",
                "label_ar": "سبتويل",
                "label_fr": "Septeuil"
            },
            {
                "value": 25513,
                "label": "Mulcent",
                "label_ar": "مولنت",
                "label_fr": "Mulcent"
            },
            {
                "value": 25514,
                "label": "Guillestre",
                "label_ar": "جيلستر",
                "label_fr": "Guillestre"
            },
            {
                "value": 25515,
                "label": "Chaumont",
                "label_ar": "شومون",
                "label_fr": "Chaumont"
            },
            {
                "value": 25516,
                "label": "Sangatte",
                "label_ar": "سانجات",
                "label_fr": "Sangatte"
            },
            {
                "value": 25517,
                "label": "Touques",
                "label_ar": "توك",
                "label_fr": "Touques"
            },
            {
                "value": 25518,
                "label": "Riez",
                "label_ar": "ريز",
                "label_fr": "Riez"
            },
            {
                "value": 25519,
                "label": "Quetigny",
                "label_ar": "Quetigny",
                "label_fr": "Quetigny"
            },
            {
                "value": 25520,
                "label": "Les Bains",
                "label_ar": "ليه باينز",
                "label_fr": "Les Bains"
            },
            {
                "value": 25521,
                "label": "Dechy",
                "label_ar": "ديشي",
                "label_fr": "Dechy"
            },
            {
                "value": 25522,
                "label": "Montigny-en-Cambresis",
                "label_ar": "مونتيني أون كامبريسيس",
                "label_fr": "Montigny-en-Cambresis"
            },
            {
                "value": 25523,
                "label": "Requista",
                "label_ar": "ريكيستا",
                "label_fr": "Requista"
            },
            {
                "value": 25524,
                "label": "Villefranche-le-Chateau",
                "label_ar": "فيلفرانش لو شاتو",
                "label_fr": "Villefranche-le-Château"
            },
            {
                "value": 25525,
                "label": "Clement",
                "label_ar": "كليمنت",
                "label_fr": "Clément"
            },
            {
                "value": 25526,
                "label": "Caraman",
                "label_ar": "كرمان",
                "label_fr": "Caraman"
            },
            {
                "value": 25527,
                "label": "Rungis",
                "label_ar": "رونجيس",
                "label_fr": "Rungis"
            },
            {
                "value": 25528,
                "label": "Seynod",
                "label_ar": "سينود",
                "label_fr": "Seynod"
            },
            {
                "value": 25529,
                "label": "Noisy-le-Roi",
                "label_ar": "صاخبة لو روي",
                "label_fr": "Noisy-le-Roi"
            },
            {
                "value": 25530,
                "label": "Henin-sur-Cojeul",
                "label_ar": "هينين سور كوجول",
                "label_fr": "Hénin-sur-Cojeul"
            },
            {
                "value": 25531,
                "label": "Saint-Maximin",
                "label_ar": "سانت ماكسيمين",
                "label_fr": "Saint-Maximin"
            },
            {
                "value": 25532,
                "label": "Sinceny",
                "label_ar": "سينسيني",
                "label_fr": "Sinceny"
            },
            {
                "value": 25533,
                "label": "Champagne-sur-Oise",
                "label_ar": "الشمبانيا سور واز",
                "label_fr": "Champagne-sur-Oise"
            },
            {
                "value": 25534,
                "label": "Amen",
                "label_ar": "آمين",
                "label_fr": "Amen"
            },
            {
                "value": 25535,
                "label": "Malabry",
                "label_ar": "مالابري",
                "label_fr": "Malabry"
            },
            {
                "value": 25536,
                "label": "Juvignac",
                "label_ar": "يوفينياك",
                "label_fr": "Juvignac"
            },
            {
                "value": 25537,
                "label": "Ernolsheim-Bruche",
                "label_ar": "ارنولشيم بروش",
                "label_fr": "Ernolsheim-Bruche"
            },
            {
                "value": 25538,
                "label": "Wingersheim",
                "label_ar": "وينجيرشيم",
                "label_fr": "Wingersheim"
            },
            {
                "value": 25539,
                "label": "Masevaux",
                "label_ar": "Masevaux",
                "label_fr": "Masevaux"
            },
            {
                "value": 25540,
                "label": "Guewenheim",
                "label_ar": "جوينهايم",
                "label_fr": "Guewenheim"
            },
            {
                "value": 25541,
                "label": "Huningue",
                "label_ar": "هونينج",
                "label_fr": "Huningue"
            },
            {
                "value": 25542,
                "label": "Richwiller",
                "label_ar": "ريتشويلر",
                "label_fr": "Richwiller"
            },
            {
                "value": 25543,
                "label": "Geispolsheim",
                "label_ar": "Geispolsheim",
                "label_fr": "Geispolsheim"
            },
            {
                "value": 25544,
                "label": "Ostwald",
                "label_ar": "اوستوالد",
                "label_fr": "Ostwald"
            },
            {
                "value": 25545,
                "label": "Wolfisheim",
                "label_ar": "ولفيشيم",
                "label_fr": "Wolfisheim"
            },
            {
                "value": 25546,
                "label": "Nouilly",
                "label_ar": "نويلي",
                "label_fr": "Nouilly"
            },
            {
                "value": 25547,
                "label": "Bouxieres-aux-Dames",
                "label_ar": "Bouxieres-aux-Dames",
                "label_fr": "Bouxières-aux-Dames"
            },
            {
                "value": 25548,
                "label": "Sapogne-et-Feucheres",
                "label_ar": "Sapogne-et-Feucheres",
                "label_fr": "Sapogne-et-Feucheres"
            },
            {
                "value": 25549,
                "label": "Noyon",
                "label_ar": "نويون",
                "label_fr": "Noyon"
            },
            {
                "value": 25550,
                "label": "Maxeville",
                "label_ar": "ماكسفيل",
                "label_fr": "Maxeville"
            },
            {
                "value": 25551,
                "label": "Gaillon",
                "label_ar": "جيلون",
                "label_fr": "Gaillon"
            },
            {
                "value": 25552,
                "label": "Catenoy",
                "label_ar": "كاتينوي",
                "label_fr": "Catenoy"
            },
            {
                "value": 25553,
                "label": "Issus",
                "label_ar": "ايسوس",
                "label_fr": "Issus"
            },
            {
                "value": 25554,
                "label": "La Brulatte",
                "label_ar": "لا Brulatte",
                "label_fr": "La Brulatte"
            },
            {
                "value": 25555,
                "label": "Mourenx",
                "label_ar": "مورينكس",
                "label_fr": "Mourenx"
            },
            {
                "value": 25556,
                "label": "Mirepeix",
                "label_ar": "ميريبكس",
                "label_fr": "Mirepeix"
            },
            {
                "value": 25557,
                "label": "Mesplede",
                "label_ar": "مسبليد",
                "label_fr": "Mesplede"
            },
            {
                "value": 25558,
                "label": "Arthez-de-Bearn",
                "label_ar": "أرثيز دي بيرن",
                "label_fr": "Arthez-de-Bearn"
            },
            {
                "value": 25559,
                "label": "Mazerolles",
                "label_ar": "Mazerolles",
                "label_fr": "Mazerolles"
            },
            {
                "value": 25560,
                "label": "Meillon",
                "label_ar": "ميلون",
                "label_fr": "Meillon"
            },
            {
                "value": 25561,
                "label": "Monein",
                "label_ar": "منين",
                "label_fr": "Monein"
            },
            {
                "value": 25562,
                "label": "Maure",
                "label_ar": "موور",
                "label_fr": "Maure"
            },
            {
                "value": 25563,
                "label": "Malaussanne",
                "label_ar": "مالاوسان",
                "label_fr": "Malaussanne"
            },
            {
                "value": 25564,
                "label": "Orthez",
                "label_ar": "أورثيز",
                "label_fr": "Orthez"
            },
            {
                "value": 25565,
                "label": "Lacadee",
                "label_ar": "لاكادي",
                "label_fr": "Lacadee"
            },
            {
                "value": 25566,
                "label": "Espelette",
                "label_ar": "اسبليت",
                "label_fr": "Espelette"
            },
            {
                "value": 25567,
                "label": "Morlaas",
                "label_ar": "مورلاس",
                "label_fr": "Morlaas"
            },
            {
                "value": 25568,
                "label": "Assat",
                "label_ar": "اسات",
                "label_fr": "Assat"
            },
            {
                "value": 25569,
                "label": "Andoins",
                "label_ar": "Andoins",
                "label_fr": "Andoins"
            },
            {
                "value": 25570,
                "label": "Mouguerre",
                "label_ar": "موغير",
                "label_fr": "Mouguerre"
            },
            {
                "value": 25571,
                "label": "Hasparren",
                "label_ar": "هاسبارين",
                "label_fr": "Hasparren"
            },
            {
                "value": 25572,
                "label": "Mauleon-Licharre",
                "label_ar": "موليون ليشر",
                "label_fr": "Mauléon-Licharre"
            },
            {
                "value": 25573,
                "label": "Maslacq",
                "label_ar": "مسلك",
                "label_fr": "Maslacq"
            },
            {
                "value": 25574,
                "label": "Bedous",
                "label_ar": "بدوس",
                "label_fr": "Bedous"
            },
            {
                "value": 25575,
                "label": "Villefranque",
                "label_ar": "فيلفرانكي",
                "label_fr": "Villefranque"
            },
            {
                "value": 25576,
                "label": "Oloron-Sainte-Marie",
                "label_ar": "أولورون سانت ماري",
                "label_fr": "Oloron-Sainte-Marie"
            },
            {
                "value": 25577,
                "label": "Montardon",
                "label_ar": "مونتاردون",
                "label_fr": "Montardon"
            },
            {
                "value": 25578,
                "label": "Saint-Jean-Pied-de-Port",
                "label_ar": "سان جان بيد دي بورت",
                "label_fr": "Saint-Jean-Pied-de-Port"
            },
            {
                "value": 25579,
                "label": "Lanne-en-Baretous",
                "label_ar": "Lanne-en-Baretous",
                "label_fr": "Lanne-en-Barétous"
            },
            {
                "value": 25580,
                "label": "Arbus",
                "label_ar": "أربوس",
                "label_fr": "Arbus"
            },
            {
                "value": 25581,
                "label": "Cambo-les-Bains",
                "label_ar": "Cambo-les-Bains",
                "label_fr": "Cambo-les-Bains"
            },
            {
                "value": 25582,
                "label": "Bidache",
                "label_ar": "بيداش",
                "label_fr": "Bidache"
            },
            {
                "value": 25583,
                "label": "Ondres",
                "label_ar": "أوندريس",
                "label_fr": "Ondres"
            },
            {
                "value": 25584,
                "label": "Sauveterre-de-Bearn",
                "label_ar": "سوفتير دي بيرن",
                "label_fr": "Sauveterre-de-Béarn"
            },
            {
                "value": 25585,
                "label": "Bordes",
                "label_ar": "بوردس",
                "label_fr": "Bordes"
            },
            {
                "value": 25586,
                "label": "Salies-de-Bearn",
                "label_ar": "ساليس دي بيرن",
                "label_fr": "Salies-de-Bearn"
            },
            {
                "value": 25587,
                "label": "Saint-Palais",
                "label_ar": "سان باليه",
                "label_fr": "Saint-Palais"
            },
            {
                "value": 25588,
                "label": "Artix",
                "label_ar": "ارتيكس",
                "label_fr": "Artix"
            },
            {
                "value": 25589,
                "label": "Boucau",
                "label_ar": "بوكاو",
                "label_fr": "Boucau"
            },
            {
                "value": 25590,
                "label": "Larressore",
                "label_ar": "Larressore",
                "label_fr": "Larressore"
            },
            {
                "value": 25591,
                "label": "Gomer",
                "label_ar": "جومر",
                "label_fr": "Gomer"
            },
            {
                "value": 25592,
                "label": "Saint-Pee-sur-Nivelle",
                "label_ar": "سان بي سور نيفيل",
                "label_fr": "Saint-Pée-sur-Nivelle"
            },
            {
                "value": 25593,
                "label": "Sauvagnon",
                "label_ar": "سوفاجنون",
                "label_fr": "Sauvagnon"
            },
            {
                "value": 25594,
                "label": "Ascain",
                "label_ar": "أسكين",
                "label_fr": "Ascain"
            },
            {
                "value": 25595,
                "label": "Bardos",
                "label_ar": "باردوس",
                "label_fr": "Bardos"
            },
            {
                "value": 25596,
                "label": "Asasp-Arros",
                "label_ar": "Asasp-Arros",
                "label_fr": "Asasp-Arros"
            },
            {
                "value": 25597,
                "label": "Sendets",
                "label_ar": "سينديتس",
                "label_fr": "Sendets"
            },
            {
                "value": 25598,
                "label": "Casteide-Cami",
                "label_ar": "كاستيد كامي",
                "label_fr": "Casteide-Cami"
            },
            {
                "value": 25599,
                "label": "Cescau",
                "label_ar": "سيسكاو",
                "label_fr": "Cescau"
            },
            {
                "value": 25600,
                "label": "Doumy",
                "label_ar": "دومي",
                "label_fr": "Doumy"
            },
            {
                "value": 25601,
                "label": "Soumoulou",
                "label_ar": "سومولو",
                "label_fr": "Soumoulou"
            },
            {
                "value": 25602,
                "label": "Ispoure",
                "label_ar": "إسبوري",
                "label_fr": "Ispoure"
            },
            {
                "value": 25603,
                "label": "Sare",
                "label_ar": "ساري",
                "label_fr": "Sare"
            },
            {
                "value": 25604,
                "label": "Gan",
                "label_ar": "غان",
                "label_fr": "Gan"
            },
            {
                "value": 25605,
                "label": "Louvie-Juzon",
                "label_ar": "لوفي جوزون",
                "label_fr": "Louvie-Juzon"
            },
            {
                "value": 25606,
                "label": "Boeil-Bezing",
                "label_ar": "Boeil-Bezing",
                "label_fr": "Boeil-Bezing"
            },
            {
                "value": 25607,
                "label": "Baudreix",
                "label_ar": "بودريكس",
                "label_fr": "Baudreix"
            },
            {
                "value": 25608,
                "label": "Ogeu-les-Bains",
                "label_ar": "Ogeu-les-Bains",
                "label_fr": "Ogeu-les-Bains"
            },
            {
                "value": 25609,
                "label": "Astis",
                "label_ar": "أستيس",
                "label_fr": "Astis"
            },
            {
                "value": 25610,
                "label": "Saint-Faust",
                "label_ar": "سانت فاوست",
                "label_fr": "Saint-Faust"
            },
            {
                "value": 25611,
                "label": "Feas",
                "label_ar": "فيز",
                "label_fr": "Feas"
            },
            {
                "value": 25612,
                "label": "Lalonquette",
                "label_ar": "Lalonquette",
                "label_fr": "Lalonquette"
            },
            {
                "value": 25613,
                "label": "Ustaritz",
                "label_ar": "أوستاريتز",
                "label_fr": "Ustaritz"
            },
            {
                "value": 25614,
                "label": "Ciboure",
                "label_ar": "سيبور",
                "label_fr": "Ciboure"
            },
            {
                "value": 25615,
                "label": "Briscous",
                "label_ar": "بريسكوس",
                "label_fr": "Briscous"
            },
            {
                "value": 25616,
                "label": "Ger",
                "label_ar": "جير",
                "label_fr": "Ger"
            },
            {
                "value": 25617,
                "label": "Pontacq",
                "label_ar": "بونتاك",
                "label_fr": "Pontacq"
            },
            {
                "value": 25618,
                "label": "Gabaston",
                "label_ar": "جابستون",
                "label_fr": "Gabaston"
            },
            {
                "value": 25619,
                "label": "Maucor",
                "label_ar": "Maucor",
                "label_fr": "Maucor"
            },
            {
                "value": 25620,
                "label": "Navailles-Angos",
                "label_ar": "نافيل أنجوس",
                "label_fr": "Navailles-Angos"
            },
            {
                "value": 25621,
                "label": "Coarraze",
                "label_ar": "خشن",
                "label_fr": "Coarraze"
            },
            {
                "value": 25622,
                "label": "Artiguelouve",
                "label_ar": "أرتيغيلوف",
                "label_fr": "Artiguelouve"
            },
            {
                "value": 25623,
                "label": "Rebenacq",
                "label_ar": "ريبيناك",
                "label_fr": "Rebenacq"
            },
            {
                "value": 25624,
                "label": "Osses",
                "label_ar": "أوسيس",
                "label_fr": "Osses"
            },
            {
                "value": 25625,
                "label": "Goes",
                "label_ar": "يذهب",
                "label_fr": "Va"
            },
            {
                "value": 25626,
                "label": "Louvigny",
                "label_ar": "لوفيني",
                "label_fr": "Louvigny"
            },
            {
                "value": 25627,
                "label": "Higueres-Souye",
                "label_ar": "هيغيريس سويي",
                "label_fr": "Higueres-Souye"
            },
            {
                "value": 25628,
                "label": "Beuste",
                "label_ar": "بيوست",
                "label_fr": "Beuste"
            },
            {
                "value": 25629,
                "label": "Monesties",
                "label_ar": "الأموال",
                "label_fr": "Monesties"
            },
            {
                "value": 25630,
                "label": "Nissan-lez-Enserune",
                "label_ar": "نيسان ليز إنسروني",
                "label_fr": "Nissan-lez-Enserune"
            },
            {
                "value": 25631,
                "label": "Very",
                "label_ar": "للغاية",
                "label_fr": "Très"
            },
            {
                "value": 25632,
                "label": "Lommerange",
                "label_ar": "لوميرانج",
                "label_fr": "Lommerange"
            },
            {
                "value": 25633,
                "label": "Remilly",
                "label_ar": "ريميللي",
                "label_fr": "Remilly"
            },
            {
                "value": 25634,
                "label": "Seingbouse",
                "label_ar": "Seingbouse",
                "label_fr": "Seingbouse"
            },
            {
                "value": 25635,
                "label": "Behren-les-Forbach",
                "label_ar": "بهرن ليه فورباخ",
                "label_fr": "Behren-les-Forbach"
            },
            {
                "value": 25636,
                "label": "Ottange",
                "label_ar": "أوتانج",
                "label_fr": "Ottange"
            },
            {
                "value": 25637,
                "label": "Saint-Avold",
                "label_ar": "سانت افولد",
                "label_fr": "Saint-Avold"
            },
            {
                "value": 25638,
                "label": "Theding",
                "label_ar": "الشيء",
                "label_fr": "Theding"
            },
            {
                "value": 25639,
                "label": "Duclair",
                "label_ar": "دوكلير",
                "label_fr": "Duclair"
            },
            {
                "value": 25640,
                "label": "Tournedos-Bois-Hubert",
                "label_ar": "Tournedos-Bois-Hubert",
                "label_fr": "Tournedos-Bois-Hubert"
            },
            {
                "value": 25641,
                "label": "Vernon",
                "label_ar": "فيرنون",
                "label_fr": "Vernon"
            },
            {
                "value": 25642,
                "label": "Erquy",
                "label_ar": "ارجوي",
                "label_fr": "Erquy"
            },
            {
                "value": 25643,
                "label": "Morieux",
                "label_ar": "موريوكس",
                "label_fr": "Morieux"
            },
            {
                "value": 25644,
                "label": "Le Guildo",
                "label_ar": "لو جيلدو",
                "label_fr": "Le Guildo"
            },
            {
                "value": 25645,
                "label": "Marsac",
                "label_ar": "مارساك",
                "label_fr": "Marsac"
            },
            {
                "value": 25646,
                "label": "Peyrolles-en-Provence",
                "label_ar": "بيرولس إن بروفانس",
                "label_fr": "Peyrolles-en-Provence"
            },
            {
                "value": 25647,
                "label": "Trets",
                "label_ar": "تريتس",
                "label_fr": "Trets"
            },
            {
                "value": 25648,
                "label": "Bruebach",
                "label_ar": "بروباخ",
                "label_fr": "Bruebach"
            },
            {
                "value": 25649,
                "label": "Soultz-Haut-Rhin",
                "label_ar": "سولتز أوت رين",
                "label_fr": "Soultz-Haut-Rhin"
            },
            {
                "value": 25650,
                "label": "Turckheim",
                "label_ar": "توركهايم",
                "label_fr": "Turckheim"
            },
            {
                "value": 25651,
                "label": "Hattstatt",
                "label_ar": "هاتشتات",
                "label_fr": "Hattstatt"
            },
            {
                "value": 25652,
                "label": "Ostheim",
                "label_ar": "أوستيم",
                "label_fr": "Ostheim"
            },
            {
                "value": 25653,
                "label": "Pulversheim",
                "label_ar": "بولفيرشايم",
                "label_fr": "Pulversheim"
            },
            {
                "value": 25654,
                "label": "Ensisheim",
                "label_ar": "انسيشيم",
                "label_fr": "Ensisheim"
            },
            {
                "value": 25655,
                "label": "Bouliac",
                "label_ar": "بولياك",
                "label_fr": "Bouliac"
            },
            {
                "value": 25656,
                "label": "Carbon-Blanc",
                "label_ar": "كربون بلانك",
                "label_fr": "Carbone-Blanc"
            },
            {
                "value": 25657,
                "label": "Tresses",
                "label_ar": "تريس",
                "label_fr": "Chevelure"
            },
            {
                "value": 25658,
                "label": "Ambes",
                "label_ar": "أمبيس",
                "label_fr": "Ambes"
            },
            {
                "value": 25659,
                "label": "Yvrac",
                "label_ar": "يفراك",
                "label_fr": "Yvrac"
            },
            {
                "value": 25660,
                "label": "Montaut",
                "label_ar": "مونتو",
                "label_fr": "Montaut"
            },
            {
                "value": 25661,
                "label": "Saint-Etienne-de-Baigorry",
                "label_ar": "سانت إتيان دي بايغوري",
                "label_fr": "Saint-Étienne-de-Baigorry"
            },
            {
                "value": 25662,
                "label": "Bouzonville",
                "label_ar": "بوزنفيل",
                "label_fr": "Bouzonville"
            },
            {
                "value": 25663,
                "label": "Carling",
                "label_ar": "كارلينج",
                "label_fr": "Carling"
            },
            {
                "value": 25664,
                "label": "Stiring-Wendel",
                "label_ar": "سترينج ويندل",
                "label_fr": "Stiring-Wendel"
            },
            {
                "value": 25665,
                "label": "Heming",
                "label_ar": "هيمنج",
                "label_fr": "Ourlet"
            },
            {
                "value": 25666,
                "label": "Villing",
                "label_ar": "الزغب",
                "label_fr": "Villing"
            },
            {
                "value": 25667,
                "label": "Vittersbourg",
                "label_ar": "فيترسبورج",
                "label_fr": "Vittersbourg"
            },
            {
                "value": 25668,
                "label": "Luppy",
                "label_ar": "لوبي",
                "label_fr": "Luppy"
            },
            {
                "value": 25669,
                "label": "Goin",
                "label_ar": "أدخل",
                "label_fr": "Va dans"
            },
            {
                "value": 25670,
                "label": "Berg-sur-Moselle",
                "label_ar": "بيرج سور موسيل",
                "label_fr": "Berg-sur-Moselle"
            },
            {
                "value": 25671,
                "label": "Frocourt",
                "label_ar": "فروكور",
                "label_fr": "Frocourt"
            },
            {
                "value": 25672,
                "label": "Morienval",
                "label_ar": "مورينفال",
                "label_fr": "Morienval"
            },
            {
                "value": 25673,
                "label": "Eaucourt-sur-Somme",
                "label_ar": "اوكورت سور سوم",
                "label_fr": "Eaucourt-sur-Somme"
            },
            {
                "value": 25674,
                "label": "Bourdon",
                "label_ar": "بوردون",
                "label_fr": "Bourdon"
            },
            {
                "value": 25675,
                "label": "Villers-Bocage",
                "label_ar": "فيليرز بوكاج",
                "label_fr": "Villers-Bocage"
            },
            {
                "value": 25676,
                "label": "Poix-de-Picardie",
                "label_ar": "بويكس دي بيكاردي",
                "label_fr": "Poix-de-Picardie"
            },
            {
                "value": 25677,
                "label": "Montdidier",
                "label_ar": "مونتديدير",
                "label_fr": "Montdidier"
            },
            {
                "value": 25678,
                "label": "Valdoie",
                "label_ar": "فالدوي",
                "label_fr": "Valdoie"
            },
            {
                "value": 25679,
                "label": "Montbouton",
                "label_ar": "مونبوتون",
                "label_fr": "Montbouton"
            },
            {
                "value": 25680,
                "label": "Grandvillars",
                "label_ar": "Grandvillars",
                "label_fr": "Grandvillars"
            },
            {
                "value": 25681,
                "label": "Hericourt",
                "label_ar": "هيريكورت",
                "label_fr": "Hericourt"
            },
            {
                "value": 25682,
                "label": "Buhl",
                "label_ar": "بوهل",
                "label_fr": "Buhl"
            },
            {
                "value": 25683,
                "label": "Thann",
                "label_ar": "تان",
                "label_fr": "Thann"
            },
            {
                "value": 25684,
                "label": "Lahonce",
                "label_ar": "لاهونس",
                "label_fr": "Lahonce"
            },
            {
                "value": 25685,
                "label": "Tarnos",
                "label_ar": "تارنوس",
                "label_fr": "Tarnos"
            },
            {
                "value": 25686,
                "label": "Saint-Vincent-de-Tyrosse",
                "label_ar": "سانت فنسنت دي تيروس",
                "label_fr": "Saint-Vincent-de-Tyrosse"
            },
            {
                "value": 25687,
                "label": "Soustons",
                "label_ar": "Soustons",
                "label_fr": "Soustons"
            },
            {
                "value": 25688,
                "label": "Marigny",
                "label_ar": "ماريني",
                "label_fr": "Marigny"
            },
            {
                "value": 25689,
                "label": "Granville",
                "label_ar": "جرانفيل",
                "label_fr": "Granville"
            },
            {
                "value": 25690,
                "label": "Les Pieux",
                "label_ar": "ليه بيو",
                "label_fr": "Les Pieux"
            },
            {
                "value": 25691,
                "label": "Sotteville",
                "label_ar": "سوتفيل",
                "label_fr": "Sotteville"
            },
            {
                "value": 25692,
                "label": "Bolleville",
                "label_ar": "بوليفيل",
                "label_fr": "Bolleville"
            },
            {
                "value": 25693,
                "label": "Saint-Sauveur-le-Vicomte",
                "label_ar": "سان سوفور لو فيكومت",
                "label_fr": "Saint-Sauveur-le-Vicomte"
            },
            {
                "value": 25694,
                "label": "Saint-Pair-sur-Mer",
                "label_ar": "Saint-Pair-sur-Mer",
                "label_fr": "Saint-Pair-sur-Mer"
            },
            {
                "value": 25695,
                "label": "Denneville",
                "label_ar": "دينفيل",
                "label_fr": "Denneville"
            },
            {
                "value": 25696,
                "label": "Donville-les-Bains",
                "label_ar": "دونفيل ليه با",
                "label_fr": "Donville-les-Bains"
            },
            {
                "value": 25697,
                "label": "Mortain",
                "label_ar": "مورتين",
                "label_fr": "Mortain"
            },
            {
                "value": 25698,
                "label": "La Lucerne-dOutremer",
                "label_ar": "لا لوسيرن-دوتريمير",
                "label_fr": "La Lucerne-d'Outremer"
            },
            {
                "value": 25699,
                "label": "Avranches",
                "label_ar": "أفانيس",
                "label_fr": "Avranches"
            },
            {
                "value": 25700,
                "label": "Fleury",
                "label_ar": "فلوري",
                "label_fr": "Fleury"
            },
            {
                "value": 25701,
                "label": "Torigni-sur-Vire",
                "label_ar": "توريني سور فير",
                "label_fr": "Torigni-sur-Vire"
            },
            {
                "value": 25702,
                "label": "Gavray",
                "label_ar": "جافراي",
                "label_fr": "Gavray"
            },
            {
                "value": 25703,
                "label": "Surville",
                "label_ar": "سورفيل",
                "label_fr": "Surville"
            },
            {
                "value": 25704,
                "label": "Valognes",
                "label_ar": "فالوجن",
                "label_fr": "Valognes"
            },
            {
                "value": 25705,
                "label": "Igoville",
                "label_ar": "إيغوفيل",
                "label_fr": "Igoville"
            },
            {
                "value": 25706,
                "label": "Coutances",
                "label_ar": "كوتانس",
                "label_fr": "Coutances"
            },
            {
                "value": 25707,
                "label": "Blainville-sur-Mer",
                "label_ar": "بلانفيل سور مير",
                "label_fr": "Blainville-sur-Mer"
            },
            {
                "value": 25708,
                "label": "Gouville-sur-Mer",
                "label_ar": "جوفيل سور مير",
                "label_fr": "Gouville-sur-Mer"
            },
            {
                "value": 25709,
                "label": "Jobourg",
                "label_ar": "جوبورج",
                "label_fr": "Jobourg"
            },
            {
                "value": 25710,
                "label": "Corny-sur-Moselle",
                "label_ar": "كورني سور موسيل",
                "label_fr": "Corny-sur-Moselle"
            },
            {
                "value": 25711,
                "label": "Dieuze",
                "label_ar": "ديوز",
                "label_fr": "Dieuze"
            },
            {
                "value": 25712,
                "label": "Colombelles",
                "label_ar": "كولومبليس",
                "label_fr": "Colombelles"
            },
            {
                "value": 25713,
                "label": "La Riviere",
                "label_ar": "لا ريفيير",
                "label_fr": "La Rivière"
            },
            {
                "value": 25714,
                "label": "Le Plessis-Trevise",
                "label_ar": "لو بليسيس تريفيز",
                "label_fr": "Le Plessis-Trevise"
            },
            {
                "value": 25715,
                "label": "Antheuil-Portes",
                "label_ar": "Antheuil-Portes",
                "label_fr": "Antheuil-Portes"
            },
            {
                "value": 25716,
                "label": "Lamorlaye",
                "label_ar": "لامورلاي",
                "label_fr": "Lamorlaye"
            },
            {
                "value": 25717,
                "label": "Scharrachbergheim-Irmstett",
                "label_ar": "Scharrachbergheim-Irmstett",
                "label_fr": "Scharrachbergheim-Irmstett"
            },
            {
                "value": 25718,
                "label": "Cernay",
                "label_ar": "سيرني",
                "label_fr": "Cernay"
            },
            {
                "value": 25719,
                "label": "Bassemberg",
                "label_ar": "باسبورغ",
                "label_fr": "Bassemberg"
            },
            {
                "value": 25720,
                "label": "Innenheim",
                "label_ar": "إيننهايم",
                "label_fr": "Innenheim"
            },
            {
                "value": 25721,
                "label": "Mommenheim",
                "label_ar": "مومنهايم",
                "label_fr": "Mommenheim"
            },
            {
                "value": 25722,
                "label": "Wiwersheim",
                "label_ar": "Wiwersheim",
                "label_fr": "Wiwersheim"
            },
            {
                "value": 25723,
                "label": "Entzheim",
                "label_ar": "إنتزيم",
                "label_fr": "Entzheim"
            },
            {
                "value": 25724,
                "label": "Saint-Girons",
                "label_ar": "سان جيرون",
                "label_fr": "Saint-Girons"
            },
            {
                "value": 25725,
                "label": "Foix",
                "label_ar": "فوا",
                "label_fr": "Foix"
            },
            {
                "value": 25726,
                "label": "Lezat-sur-Leze",
                "label_ar": "ليزات سور ليز",
                "label_fr": "Lezat-sur-Leze"
            },
            {
                "value": 25727,
                "label": "La Tour-du-Crieu",
                "label_ar": "لا تور دو كريو",
                "label_fr": "La Tour-du-Crieu"
            },
            {
                "value": 25728,
                "label": "Les Cabannes",
                "label_ar": "ليه كابان",
                "label_fr": "Les Cabannes"
            },
            {
                "value": 25729,
                "label": "Lavelanet",
                "label_ar": "لافيلانيت",
                "label_fr": "Lavelanet"
            },
            {
                "value": 25730,
                "label": "Seignalens",
                "label_ar": "Seignalens",
                "label_fr": "Seignalens"
            },
            {
                "value": 25731,
                "label": "Quintin",
                "label_ar": "كوينتين",
                "label_fr": "Quintin"
            },
            {
                "value": 25732,
                "label": "Jugon-les-Lacs",
                "label_ar": "جوغون ليه لاكس",
                "label_fr": "Jugon-les-Lacs"
            },
            {
                "value": 25733,
                "label": "Mousteru",
                "label_ar": "Mousteru",
                "label_fr": "Mousteru"
            },
            {
                "value": 25734,
                "label": "Hillion",
                "label_ar": "هيليون",
                "label_fr": "Hillion"
            },
            {
                "value": 25735,
                "label": "Bonnieres-sur-Seine",
                "label_ar": "Bonnieres-sur-Seine",
                "label_fr": "Bonnières-sur-Seine"
            },
            {
                "value": 25736,
                "label": "Agneaux",
                "label_ar": "اجنو",
                "label_fr": "Agneaux"
            },
            {
                "value": 25737,
                "label": "Ouzouer-sur-Trezee",
                "label_ar": "Ouzouer-sur-Trezee",
                "label_fr": "Ouzouer-sur-Trezee"
            },
            {
                "value": 25738,
                "label": "Beaugency",
                "label_ar": "بيوجينسي",
                "label_fr": "Beaugency"
            },
            {
                "value": 25739,
                "label": "Creissan",
                "label_ar": "كريسان",
                "label_fr": "Creissan"
            },
            {
                "value": 25740,
                "label": "Portiragnes",
                "label_ar": "بورتيراجنس",
                "label_fr": "Portiragnes"
            },
            {
                "value": 25741,
                "label": "Serignan",
                "label_ar": "سيرينان",
                "label_fr": "Sérignan"
            },
            {
                "value": 25742,
                "label": "Villeneuve les beziers",
                "label_ar": "Villeneuve les beziers",
                "label_fr": "Villeneuve les Béziers"
            },
            {
                "value": 25743,
                "label": "Magalas",
                "label_ar": "ماجالاس",
                "label_fr": "Magalas"
            },
            {
                "value": 25744,
                "label": "Saint-Chinian",
                "label_ar": "سانت شينيان",
                "label_fr": "Saint-Chinian"
            },
            {
                "value": 25745,
                "label": "Capestang",
                "label_ar": "كابستانج",
                "label_fr": "Capestang"
            },
            {
                "value": 25746,
                "label": "Bouzigues",
                "label_ar": "بوزيج",
                "label_fr": "Bouzigues"
            },
            {
                "value": 25747,
                "label": "Villeneuve",
                "label_ar": "فيلنوف",
                "label_fr": "Villeneuve"
            },
            {
                "value": 25748,
                "label": "Meze",
                "label_ar": "مزة",
                "label_fr": "Meze"
            },
            {
                "value": 25749,
                "label": "Palavas-les-Flots",
                "label_ar": "بالافاس ليه فلوتس",
                "label_fr": "Palavas-les-Flots"
            },
            {
                "value": 25750,
                "label": "Saint-Thibery",
                "label_ar": "سان تيبيري",
                "label_fr": "Saint-Thibéry"
            },
            {
                "value": 25751,
                "label": "Teyran",
                "label_ar": "تيران",
                "label_fr": "Teyran"
            },
            {
                "value": 25752,
                "label": "Marseillan",
                "label_ar": "مرسيليا",
                "label_fr": "Marseillan"
            },
            {
                "value": 25753,
                "label": "Bessan",
                "label_ar": "بيسان",
                "label_fr": "Bessan"
            },
            {
                "value": 25754,
                "label": "Prades-le-Lez",
                "label_ar": "براديس لو ليز",
                "label_fr": "Prades-le-Lez"
            },
            {
                "value": 25755,
                "label": "Balaruc-les-Bains",
                "label_ar": "Balaruc-les-Bains",
                "label_fr": "Balaruc-les-Bains"
            },
            {
                "value": 25756,
                "label": "Lodève",
                "label_ar": "لوديف",
                "label_fr": "Lodève"
            },
            {
                "value": 25757,
                "label": "Balaruc-le-Vieux",
                "label_ar": "Balaruc-le-Vieux",
                "label_fr": "Balaruc-le-Vieux"
            },
            {
                "value": 25758,
                "label": "Cazouls-les-Beziers",
                "label_ar": "كازول لي بيزيه",
                "label_fr": "Cazouls-les-Béziers"
            },
            {
                "value": 25759,
                "label": "Vailhauques",
                "label_ar": "Vailhauques",
                "label_fr": "Vailhauques"
            },
            {
                "value": 25760,
                "label": "Clermont-l'Herault",
                "label_ar": "كليرمون لهيرولت",
                "label_fr": "Clermont-l'Hérault"
            },
            {
                "value": 25761,
                "label": "Belarga",
                "label_ar": "بيلارجا",
                "label_fr": "Belarga"
            },
            {
                "value": 25762,
                "label": "Maraussan",
                "label_ar": "مروسان",
                "label_fr": "Maraussan"
            },
            {
                "value": 25763,
                "label": "Laroque-de-Fa",
                "label_ar": "لاروك دي فا",
                "label_fr": "Laroque-de-Fa"
            },
            {
                "value": 25764,
                "label": "Canet-de-Salars",
                "label_ar": "كانيه دي سالار",
                "label_fr": "Canet-de-Salars"
            },
            {
                "value": 25765,
                "label": "Montaud",
                "label_ar": "مونتود",
                "label_fr": "Montaud"
            },
            {
                "value": 25766,
                "label": "Pezenas",
                "label_ar": "بيزيناس",
                "label_fr": "Pézenas"
            },
            {
                "value": 25767,
                "label": "Cournonsec",
                "label_ar": "Cournonsec",
                "label_fr": "Cournonsec"
            },
            {
                "value": 25768,
                "label": "Montblanc",
                "label_ar": "الجبل الاسود",
                "label_fr": "Mont blanc"
            },
            {
                "value": 25769,
                "label": "Fitilieu",
                "label_ar": "Fitilieu",
                "label_fr": "Fitilieu"
            },
            {
                "value": 25770,
                "label": "Cintre",
                "label_ar": "سينتر",
                "label_fr": "Cintre"
            },
            {
                "value": 25771,
                "label": "Nouvoitou",
                "label_ar": "نوفويتو",
                "label_fr": "Nouvoitou"
            },
            {
                "value": 25772,
                "label": "Saint-Benoit-des-Ondes",
                "label_ar": "Saint-Benoit-des-Ondes",
                "label_fr": "Saint-Benoit-des-Ondes"
            },
            {
                "value": 25773,
                "label": "Bosc Renoult",
                "label_ar": "بوسك رينولت",
                "label_fr": "Bosc Renoult"
            },
            {
                "value": 25774,
                "label": "Peynier",
                "label_ar": "بينير",
                "label_fr": "Peynier"
            },
            {
                "value": 25775,
                "label": "Locquirec",
                "label_ar": "لوكويريك",
                "label_fr": "Locquirec"
            },
            {
                "value": 25776,
                "label": "La Motte-Saint-Jean",
                "label_ar": "لا موت سان جان",
                "label_fr": "La Motte-Saint-Jean"
            },
            {
                "value": 25777,
                "label": "Lieuron",
                "label_ar": "ليورون",
                "label_fr": "Lieuron"
            },
            {
                "value": 25778,
                "label": "Mezzavia",
                "label_ar": "Mezzavia",
                "label_fr": "Mezzavia"
            },
            {
                "value": 25779,
                "label": "Creuzier-le-Neuf",
                "label_ar": "كروزييه لو نوف",
                "label_fr": "Creuzier-le-Neuf"
            },
            {
                "value": 25780,
                "label": "Doyet",
                "label_ar": "دويت",
                "label_fr": "Doyet"
            },
            {
                "value": 25781,
                "label": "Le Loroux-Bottereau",
                "label_ar": "لو لوروكس بوتيرو",
                "label_fr": "Le Loroux-Bottereau"
            },
            {
                "value": 25782,
                "label": "Champagne-sur-Seine",
                "label_ar": "شمبانيا سور سين",
                "label_fr": "Champagne-sur-Seine"
            },
            {
                "value": 25783,
                "label": "Ormoy",
                "label_ar": "أورموي",
                "label_fr": "Ormoy"
            },
            {
                "value": 25784,
                "label": "Roquebrune-sur-Argens",
                "label_ar": "روكبرون سور أرجينز",
                "label_fr": "Roquebrune-sur-Argens"
            },
            {
                "value": 25785,
                "label": "Guilherand-Granges",
                "label_ar": "جيلهيراند جرانج",
                "label_fr": "Guilherand-Granges"
            },
            {
                "value": 25786,
                "label": "Lomme",
                "label_ar": "لوم",
                "label_fr": "Lomme"
            },
            {
                "value": 25787,
                "label": "Abbeville",
                "label_ar": "أبفيل",
                "label_fr": "Abbeville"
            },
            {
                "value": 25788,
                "label": "Gentilly",
                "label_ar": "جنتيلي",
                "label_fr": "Gentilly"
            },
            {
                "value": 25789,
                "label": "Rambouillet",
                "label_ar": "رامبوييه",
                "label_fr": "Rambouillet"
            },
            {
                "value": 25790,
                "label": "Langres",
                "label_ar": "لانجر",
                "label_fr": "Langres"
            },
            {
                "value": 25791,
                "label": "Steenvoorde",
                "label_ar": "ستينفوردي",
                "label_fr": "Steenvoorde"
            },
            {
                "value": 25792,
                "label": "Serans",
                "label_ar": "سيران",
                "label_fr": "Serans"
            },
            {
                "value": 25793,
                "label": "Mandelieu-la-Napoule",
                "label_ar": "مانديليو لا نابول",
                "label_fr": "Mandelieu-la-Napoule"
            },
            {
                "value": 25794,
                "label": "Vence",
                "label_ar": "فونس",
                "label_fr": "Vence"
            },
            {
                "value": 25795,
                "label": "Dieue-sur-Meuse",
                "label_ar": "ديو سور ميوز",
                "label_fr": "Dieue-sur-Meuse"
            },
            {
                "value": 25796,
                "label": "Sarrey",
                "label_ar": "ساري",
                "label_fr": "Sarrey"
            },
            {
                "value": 25797,
                "label": "Fleure",
                "label_ar": "فلور",
                "label_fr": "Fleure"
            },
            {
                "value": 25798,
                "label": "Cabasse",
                "label_ar": "كاباس",
                "label_fr": "Cabasse"
            },
            {
                "value": 25799,
                "label": "Montbéliard",
                "label_ar": "مونبيليارد",
                "label_fr": "Montbéliard"
            },
            {
                "value": 25800,
                "label": "Pontcharra",
                "label_ar": "بونتشارا",
                "label_fr": "Pontcharra"
            },
            {
                "value": 25801,
                "label": "Oppede",
                "label_ar": "يعارض",
                "label_fr": "Oppède"
            },
            {
                "value": 25802,
                "label": "Citerne",
                "label_ar": "سيتيرن",
                "label_fr": "Citerne"
            },
            {
                "value": 25803,
                "label": "Gauchy",
                "label_ar": "غوشي",
                "label_fr": "Gauchy"
            },
            {
                "value": 25804,
                "label": "Achiet-le-Petit",
                "label_ar": "آشيت لو بيتي",
                "label_fr": "Achiet-le-Petit"
            },
            {
                "value": 25805,
                "label": "Saint-Hostien",
                "label_ar": "سانت هوستيان",
                "label_fr": "Saint-Hostien"
            },
            {
                "value": 25806,
                "label": "Orchies",
                "label_ar": "الأوركي",
                "label_fr": "Orchies"
            },
            {
                "value": 25807,
                "label": "Château-Thierry",
                "label_ar": "شاتو تيري",
                "label_fr": "Château-Thierry"
            },
            {
                "value": 25808,
                "label": "Saintes",
                "label_ar": "سينتس",
                "label_fr": "Saintes"
            },
            {
                "value": 25809,
                "label": "Vif",
                "label_ar": "فيف",
                "label_fr": "Vif"
            },
            {
                "value": 25810,
                "label": "Rivarennes",
                "label_ar": "ريفارين",
                "label_fr": "Rivarennes"
            },
            {
                "value": 25811,
                "label": "Saint-Pierre-d'Oleron",
                "label_ar": "سان بيير دولرون",
                "label_fr": "Saint-Pierre-d'Oléron"
            },
            {
                "value": 25812,
                "label": "Ruelle",
                "label_ar": "رويل",
                "label_fr": "Ruelle"
            },
            {
                "value": 25813,
                "label": "Grugies",
                "label_ar": "Grugies",
                "label_fr": "Grugies"
            },
            {
                "value": 25814,
                "label": "Cauroy-les-Hermonville",
                "label_ar": "كوروي ليه هيرمونفيل",
                "label_fr": "Cauroy-les-Hermonville"
            },
            {
                "value": 25815,
                "label": "Cormicy",
                "label_ar": "كورميسي",
                "label_fr": "Cormicy"
            },
            {
                "value": 25816,
                "label": "Coulommiers",
                "label_ar": "كولومييه",
                "label_fr": "Coulommiers"
            },
            {
                "value": 25817,
                "label": "La Teste-de-Buch",
                "label_ar": "لا تيست دي بوخ",
                "label_fr": "La Teste-de-Buch"
            },
            {
                "value": 25818,
                "label": "Espalais",
                "label_ar": "Espalais",
                "label_fr": "Espalais"
            },
            {
                "value": 25819,
                "label": "Goudourville",
                "label_ar": "جودورفيل",
                "label_fr": "Goudourville"
            },
            {
                "value": 25820,
                "label": "Vouhe",
                "label_ar": "فوهي",
                "label_fr": "Vouhe"
            },
            {
                "value": 25821,
                "label": "Izon",
                "label_ar": "ايزون",
                "label_fr": "Izon"
            },
            {
                "value": 25822,
                "label": "Larcay",
                "label_ar": "لاركاي",
                "label_fr": "Larcay"
            },
            {
                "value": 25823,
                "label": "Ruffiac",
                "label_ar": "روفياك",
                "label_fr": "Ruffiac"
            },
            {
                "value": 25824,
                "label": "Montigne-le-Brillant",
                "label_ar": "مونتين لو بريان",
                "label_fr": "Montigne-le-Brillant"
            },
            {
                "value": 25825,
                "label": "La Boissiere-des-Landes",
                "label_ar": "لا بواسير دي لاند",
                "label_fr": "La Boissière-des-Landes"
            },
            {
                "value": 25826,
                "label": "Fontenay-Tresigny",
                "label_ar": "Fontenay-Tresigny",
                "label_fr": "Fontenay-Trésigny"
            },
            {
                "value": 25827,
                "label": "Faremoutiers",
                "label_ar": "Faremoutiers",
                "label_fr": "Faremoutiers"
            },
            {
                "value": 25828,
                "label": "Beuvraignes",
                "label_ar": "بيوفرايني",
                "label_fr": "Beuvraignes"
            },
            {
                "value": 25829,
                "label": "Jouy-le-Moutier",
                "label_ar": "جوي لو موتير",
                "label_fr": "Jouy-le-Moutier"
            },
            {
                "value": 25830,
                "label": "Villemoisson-sur-Orge",
                "label_ar": "فيلموسون سور أورج",
                "label_fr": "Villemoisson-sur-Orge"
            },
            {
                "value": 25831,
                "label": "Ouveillan",
                "label_ar": "أوفيلان",
                "label_fr": "Ouveillan"
            },
            {
                "value": 25832,
                "label": "Caux-et-Sauzens",
                "label_ar": "Caux-et-Sauzens",
                "label_fr": "Caux-et-Sauzens"
            },
            {
                "value": 25833,
                "label": "Pointis-Inard",
                "label_ar": "بوينتيس إنارد",
                "label_fr": "Pointis-Inard"
            },
            {
                "value": 25834,
                "label": "Villefranche-de-Lauragais",
                "label_ar": "فيلفرانش دي لوراجيس",
                "label_fr": "Villefranche-de-Lauragais"
            },
            {
                "value": 25835,
                "label": "Terraube",
                "label_ar": "تيراوب",
                "label_fr": "Terraube"
            },
            {
                "value": 25836,
                "label": "Labastide-Saint-Pierre",
                "label_ar": "لاباستيد سان بيير",
                "label_fr": "Labastide-Saint-Pierre"
            },
            {
                "value": 25837,
                "label": "Viviers-les-Montagnes",
                "label_ar": "Viviers-les-Montagnes",
                "label_fr": "Viviers-les-Montagnes"
            },
            {
                "value": 25838,
                "label": "Palaja",
                "label_ar": "بالاجا",
                "label_fr": "Palaja"
            },
            {
                "value": 25839,
                "label": "Montreal",
                "label_ar": "مونتريال",
                "label_fr": "Montréal"
            },
            {
                "value": 25840,
                "label": "Collioure",
                "label_ar": "كوليور",
                "label_fr": "Collioure"
            },
            {
                "value": 25841,
                "label": "Longuenesse",
                "label_ar": "Longuenesse",
                "label_fr": "Longuenesse"
            },
            {
                "value": 25842,
                "label": "Saint-Martin-d'Hères",
                "label_ar": "سان مارتن ديهير",
                "label_fr": "Saint-Martin-d'Hères"
            },
            {
                "value": 25843,
                "label": "Brindas",
                "label_ar": "برينداس",
                "label_fr": "Brindas"
            },
            {
                "value": 25844,
                "label": "Mellecey",
                "label_ar": "ميليسي",
                "label_fr": "Mellecey"
            },
            {
                "value": 25845,
                "label": "Simandres",
                "label_ar": "سيماندريس",
                "label_fr": "Simandres"
            },
            {
                "value": 25846,
                "label": "Les Chapelles",
                "label_ar": "ليه شابيل",
                "label_fr": "Les Chapelles"
            },
            {
                "value": 25847,
                "label": "Sauvian",
                "label_ar": "سوفيان",
                "label_fr": "Sauvian"
            },
            {
                "value": 25848,
                "label": "Argelliers",
                "label_ar": "أرجيليرس",
                "label_fr": "Argelliers"
            },
            {
                "value": 25849,
                "label": "Canohes",
                "label_ar": "الخناجر",
                "label_fr": "Canoës"
            },
            {
                "value": 25850,
                "label": "Colombieres-sur-Orb",
                "label_ar": "كولومبيريس سور أورب",
                "label_fr": "Colombières-sur-Orb"
            },
            {
                "value": 25851,
                "label": "Corneilla-de-Conflent",
                "label_ar": "Corneilla-de-Conflent",
                "label_fr": "Corneilla-de-Conflent"
            },
            {
                "value": 25852,
                "label": "Tressan",
                "label_ar": "تريسان",
                "label_fr": "Tressan"
            },
            {
                "value": 25853,
                "label": "Boisseron",
                "label_ar": "بواسيرون",
                "label_fr": "Boisseron"
            },
            {
                "value": 25854,
                "label": "Limeil-Brevannes",
                "label_ar": "ليميل بريفانيس",
                "label_fr": "Limeil-Brevannes"
            },
            {
                "value": 25855,
                "label": "Clevilliers",
                "label_ar": "كليفيليرز",
                "label_fr": "Clevilliers"
            },
            {
                "value": 25856,
                "label": "Marthod",
                "label_ar": "مارثود",
                "label_fr": "Marthod"
            },
            {
                "value": 25857,
                "label": "Montreal",
                "label_ar": "مونتريال",
                "label_fr": "Montréal"
            },
            {
                "value": 25858,
                "label": "Piney",
                "label_ar": "صنوري",
                "label_fr": "Piney"
            },
            {
                "value": 25859,
                "label": "Balanod",
                "label_ar": "بالانود",
                "label_fr": "Balanod"
            },
            {
                "value": 25860,
                "label": "Marles-les-Mines",
                "label_ar": "مارليس لي مين",
                "label_fr": "Marles-les-Mines"
            },
            {
                "value": 25861,
                "label": "Wervicq-Sud",
                "label_ar": "Wervicq-Sud",
                "label_fr": "Wervicq-Sud"
            },
            {
                "value": 25862,
                "label": "Montluçon",
                "label_ar": "Montluçon",
                "label_fr": "Montluçon"
            },
            {
                "value": 25863,
                "label": "Parthenay",
                "label_ar": "بارثيناي",
                "label_fr": "Parthenay"
            },
            {
                "value": 25864,
                "label": "Saint-Maixent-l'Ecole",
                "label_ar": "سان ماكسينت ليكول",
                "label_fr": "Saint-Maixent-l'Ecole"
            },
            {
                "value": 25865,
                "label": "Chauvigny",
                "label_ar": "شوفيني",
                "label_fr": "Chauvigny"
            },
            {
                "value": 25866,
                "label": "Sainte-Verge",
                "label_ar": "سانت فيرج",
                "label_fr": "Sainte-Verge"
            },
            {
                "value": 25867,
                "label": "Avize",
                "label_ar": "أفيز",
                "label_fr": "Avize"
            },
            {
                "value": 25868,
                "label": "Velleron",
                "label_ar": "فيليرون",
                "label_fr": "Velleron"
            },
            {
                "value": 25869,
                "label": "Vinon-sur-Verdon",
                "label_ar": "فينون سور فيردون",
                "label_fr": "Vinon-sur-Verdon"
            },
            {
                "value": 25870,
                "label": "Chauray",
                "label_ar": "شوراي",
                "label_fr": "Chauray"
            },
            {
                "value": 25871,
                "label": "Saint-Maigrin",
                "label_ar": "سان ميجرين",
                "label_fr": "Saint-Maigrin"
            },
            {
                "value": 25872,
                "label": "Thouars",
                "label_ar": "الثوار",
                "label_fr": "Thouars"
            },
            {
                "value": 25873,
                "label": "Le Tallud",
                "label_ar": "لو تالود",
                "label_fr": "Le Tallud"
            },
            {
                "value": 25874,
                "label": "Puihardy",
                "label_ar": "بويهاردي",
                "label_fr": "Puihardy"
            },
            {
                "value": 25875,
                "label": "Saint-Avertin",
                "label_ar": "سانت افيرتين",
                "label_fr": "Saint-Avertin"
            },
            {
                "value": 25876,
                "label": "Naizin",
                "label_ar": "نيزين",
                "label_fr": "Naizin"
            },
            {
                "value": 25877,
                "label": "Paulx",
                "label_ar": "بولكس",
                "label_fr": "Paulx"
            },
            {
                "value": 25878,
                "label": "Saint-Pryve-Saint-Mesmin",
                "label_ar": "سان بريف سانت ميسمين",
                "label_fr": "Saint-Pryve-Saint-Mesmin"
            },
            {
                "value": 25879,
                "label": "Louvres",
                "label_ar": "اللوفر",
                "label_fr": "Persiennes"
            },
            {
                "value": 25880,
                "label": "Guise",
                "label_ar": "المظهر",
                "label_fr": "Apparence"
            },
            {
                "value": 25881,
                "label": "Provins",
                "label_ar": "بروفينس",
                "label_fr": "Provins"
            },
            {
                "value": 25882,
                "label": "Oyonnax",
                "label_ar": "أويوناكس",
                "label_fr": "Oyonnax"
            },
            {
                "value": 25883,
                "label": "Rocbaron",
                "label_ar": "روكبارون",
                "label_fr": "Rocbaron"
            },
            {
                "value": 25884,
                "label": "Tarcenay",
                "label_ar": "Tarcenay",
                "label_fr": "Tarcenay"
            },
            {
                "value": 25885,
                "label": "Eze",
                "label_ar": "حزقيال",
                "label_fr": "Eze"
            },
            {
                "value": 25886,
                "label": "Harfleur",
                "label_ar": "هارفليور",
                "label_fr": "Harfleur"
            },
            {
                "value": 25887,
                "label": "Saivres",
                "label_ar": "Saivres",
                "label_fr": "Saivres"
            },
            {
                "value": 25888,
                "label": "Beliet",
                "label_ar": "بيليت",
                "label_fr": "Béliet"
            },
            {
                "value": 25889,
                "label": "Montreal-la-Cluse",
                "label_ar": "مونتريال لا كلوز",
                "label_fr": "Montréal-la-Cluse"
            },
            {
                "value": 25890,
                "label": "Jeurre",
                "label_ar": "جيوري",
                "label_fr": "Jeurre"
            },
            {
                "value": 25891,
                "label": "Preignac",
                "label_ar": "بريجناك",
                "label_fr": "Preignac"
            },
            {
                "value": 25892,
                "label": "Parcay-Meslay",
                "label_ar": "باركاي ميسلاي",
                "label_fr": "Parcay-Meslay"
            },
            {
                "value": 25893,
                "label": "Olivet",
                "label_ar": "زيتون",
                "label_fr": "Olivet"
            },
            {
                "value": 25894,
                "label": "Beaumont-sur-Oise",
                "label_ar": "بومونت سور واز",
                "label_fr": "Beaumont-sur-Oise"
            },
            {
                "value": 25895,
                "label": "Charleville-Mézières",
                "label_ar": "شارلفيل ميزير",
                "label_fr": "Charleville-Mézières"
            },
            {
                "value": 25896,
                "label": "Saint-Quentin-le-Verger",
                "label_ar": "سانت كوينتين لو فيرجير",
                "label_fr": "Saint-Quentin-le-Verger"
            },
            {
                "value": 25897,
                "label": "Soissons",
                "label_ar": "سواسون",
                "label_fr": "Soissons"
            },
            {
                "value": 25898,
                "label": "Lecelles",
                "label_ar": "ليسيلس",
                "label_fr": "Lecelles"
            },
            {
                "value": 25899,
                "label": "Homblieres",
                "label_ar": "هومبليريس",
                "label_fr": "Homblières"
            },
            {
                "value": 25900,
                "label": "Laurent",
                "label_ar": "لوران",
                "label_fr": "Laurent"
            },
            {
                "value": 25901,
                "label": "Fontenay-le-Fleury",
                "label_ar": "فونتيني لو فلوري",
                "label_fr": "Fontenay-le-Fleury"
            },
            {
                "value": 25902,
                "label": "La Colle-sur-Loup",
                "label_ar": "لا كولي سور لوب",
                "label_fr": "La Colle-sur-Loup"
            },
            {
                "value": 25903,
                "label": "Still",
                "label_ar": "ما يزال",
                "label_fr": "Encore"
            },
            {
                "value": 25904,
                "label": "Biache-Saint-Vaast",
                "label_ar": "بياش سانت فاست",
                "label_fr": "Biache-Saint-Vaast"
            },
            {
                "value": 25905,
                "label": "Saint-Jean-le-Blanc",
                "label_ar": "سان جان لو بلان",
                "label_fr": "Saint-Jean-le-Blanc"
            },
            {
                "value": 25906,
                "label": "Gareoult",
                "label_ar": "جارولت",
                "label_fr": "Gareoult"
            },
            {
                "value": 25907,
                "label": "Neuville-en-Ferrain",
                "label_ar": "نيوفيل أون فيرين",
                "label_fr": "Neuville-en-Ferrain"
            },
            {
                "value": 25908,
                "label": "Vieux Conde",
                "label_ar": "فيو كوندي",
                "label_fr": "Vieux Condé"
            },
            {
                "value": 25909,
                "label": "Saint-Ismier",
                "label_ar": "سان إسمير",
                "label_fr": "Saint-Ismier"
            },
            {
                "value": 25910,
                "label": "Golbey",
                "label_ar": "جولبي",
                "label_fr": "Golbey"
            },
            {
                "value": 25911,
                "label": "Notre-Dame-de-Bondeville",
                "label_ar": "نوتردام دي بوندفيل",
                "label_fr": "Notre-Dame-de-Bondeville"
            },
            {
                "value": 25912,
                "label": "Lantriac",
                "label_ar": "لانترياك",
                "label_fr": "Lantriac"
            },
            {
                "value": 25913,
                "label": "Villasavary",
                "label_ar": "فيلاسافاري",
                "label_fr": "Villasavary"
            },
            {
                "value": 25914,
                "label": "Saint-Amans-Soult",
                "label_ar": "سانت امانس سولت",
                "label_fr": "Saint-Amans-Soult"
            },
            {
                "value": 25915,
                "label": "Annay",
                "label_ar": "أناي",
                "label_fr": "Annay"
            },
            {
                "value": 25916,
                "label": "Ponthierry",
                "label_ar": "بونثيري",
                "label_fr": "Ponthierry"
            },
            {
                "value": 25917,
                "label": "La Madeleine",
                "label_ar": "لا مادلين",
                "label_fr": "La Madeleine"
            },
            {
                "value": 25918,
                "label": "Sollies-Pont",
                "label_ar": "سوليس بونت",
                "label_fr": "Sollies-Pont"
            },
            {
                "value": 25919,
                "label": "Lunéville",
                "label_ar": "لونيفيل",
                "label_fr": "Lunéville"
            },
            {
                "value": 25920,
                "label": "Bagnols-sur-Ceze",
                "label_ar": "Bagnols-sur-Ceze",
                "label_fr": "Bagnols-sur-Cèze"
            },
            {
                "value": 25921,
                "label": "Moureze",
                "label_ar": "موريز",
                "label_fr": "Mourèze"
            },
            {
                "value": 25922,
                "label": "Unieux",
                "label_ar": "Unieux",
                "label_fr": "Unieux"
            },
            {
                "value": 25923,
                "label": "Villejust",
                "label_ar": "فيليجست",
                "label_fr": "Villejust"
            },
            {
                "value": 25924,
                "label": "Signes",
                "label_ar": "علامات",
                "label_fr": "Signes"
            },
            {
                "value": 25925,
                "label": "Salin-de-Giraud",
                "label_ar": "سالين دي جيرود",
                "label_fr": "Salin-de-Giraud"
            },
            {
                "value": 25926,
                "label": "Saint-Mandrier-sur-Mer",
                "label_ar": "سان ماندرير سور مير",
                "label_fr": "Saint-Mandrier-sur-Mer"
            },
            {
                "value": 25927,
                "label": "Le Beausset",
                "label_ar": "لو بوسيه",
                "label_fr": "Le Beausset"
            },
            {
                "value": 25928,
                "label": "Peri",
                "label_ar": "بيري",
                "label_fr": "Péri"
            },
            {
                "value": 25929,
                "label": "La Celle",
                "label_ar": "لا سيل",
                "label_fr": "La Celle"
            },
            {
                "value": 25930,
                "label": "Montauroux",
                "label_ar": "مونتورو",
                "label_fr": "Montauroux"
            },
            {
                "value": 25931,
                "label": "Pignans",
                "label_ar": "البيجنان",
                "label_fr": "Pignans"
            },
            {
                "value": 25932,
                "label": "Le Conquet",
                "label_ar": "لو كونكيه",
                "label_fr": "Le Conquet"
            },
            {
                "value": 25933,
                "label": "Varades",
                "label_ar": "فاراديس",
                "label_fr": "Varades"
            },
            {
                "value": 25934,
                "label": "Gargenville",
                "label_ar": "جارجينفيل",
                "label_fr": "Gargenville"
            },
            {
                "value": 25935,
                "label": "Le Thor",
                "label_ar": "لو ثور",
                "label_fr": "Le Thor"
            },
            {
                "value": 25936,
                "label": "Courseulles-sur-Mer",
                "label_ar": "Courseulles-sur-Mer",
                "label_fr": "Courseulles-sur-Mer"
            },
            {
                "value": 25937,
                "label": "Bernieres-sur-Mer",
                "label_ar": "بيرنيريس سور مير",
                "label_fr": "Bernières-sur-Mer"
            },
            {
                "value": 25938,
                "label": "Rocquemont",
                "label_ar": "روكيمونت",
                "label_fr": "Rocquemont"
            },
            {
                "value": 25939,
                "label": "Morgny-la-Pommeraye",
                "label_ar": "مورني لا بوميراي",
                "label_fr": "Morgny-la-Pommeraye"
            },
            {
                "value": 25940,
                "label": "Douvres-la-Delivrande",
                "label_ar": "Douvres-la-Delivrande",
                "label_fr": "Douvres-la-Delivrande"
            },
            {
                "value": 25941,
                "label": "Saint-Clair-sur-l'Elle",
                "label_ar": "سان كلير سور ليل",
                "label_fr": "Saint-Clair-sur-l'Elle"
            },
            {
                "value": 25942,
                "label": "Cresserons",
                "label_ar": "كريسيرونس",
                "label_fr": "Cresserons"
            },
            {
                "value": 25943,
                "label": "Yvetot",
                "label_ar": "يفيتوت",
                "label_fr": "Yvetot"
            },
            {
                "value": 25944,
                "label": "Clasville",
                "label_ar": "كلاسفيل",
                "label_fr": "Clasville"
            },
            {
                "value": 25945,
                "label": "Crépy-en-Valois",
                "label_ar": "Crépy-en-Valois",
                "label_fr": "Crépy-en-Valois"
            },
            {
                "value": 25946,
                "label": "Dreux",
                "label_ar": "درو",
                "label_fr": "Dreux"
            },
            {
                "value": 25947,
                "label": "Longpont-sur-Orge",
                "label_ar": "Longpont-sur-Orge",
                "label_fr": "Longpont-sur-Orge"
            },
            {
                "value": 25948,
                "label": "Givors",
                "label_ar": "جيفورز",
                "label_fr": "Givors"
            },
            {
                "value": 25949,
                "label": "Pont-d'Ain",
                "label_ar": "بونت دان",
                "label_fr": "Pont-d'Ain"
            },
            {
                "value": 25950,
                "label": "Vendat",
                "label_ar": "فيندات",
                "label_fr": "Vendat"
            },
            {
                "value": 25951,
                "label": "Sennecey-le-Grand",
                "label_ar": "سينيسي لو جراند",
                "label_fr": "Sennecey-le-Grand"
            },
            {
                "value": 25952,
                "label": "Saint-Marcel-d'Urfe",
                "label_ar": "سان مارسيل دورف",
                "label_fr": "Saint-Marcel-d'Urfe"
            },
            {
                "value": 25953,
                "label": "Saint-Germain-du-Plain",
                "label_ar": "سان جيرمان دو بلين",
                "label_fr": "Saint-Germain-du-Plain"
            },
            {
                "value": 25954,
                "label": "Louhans",
                "label_ar": "لوهانز",
                "label_fr": "Louhans"
            },
            {
                "value": 25955,
                "label": "Savigneux",
                "label_ar": "سافينيوكس",
                "label_fr": "Savigneux"
            },
            {
                "value": 25956,
                "label": "Saint-Marcel-les-Valence",
                "label_ar": "سان مارسيل ليس فالينس",
                "label_fr": "Saint-Marcel-les-Valence"
            },
            {
                "value": 25957,
                "label": "Bellignat",
                "label_ar": "بيليغنات",
                "label_fr": "Bellignat"
            },
            {
                "value": 25958,
                "label": "Saint-Cyr-sur-Mer",
                "label_ar": "سان سير سور مير",
                "label_fr": "Saint-Cyr-sur-Mer"
            },
            {
                "value": 25959,
                "label": "Jarrie",
                "label_ar": "جاري",
                "label_fr": "Jarrie"
            },
            {
                "value": 25960,
                "label": "Cercoux",
                "label_ar": "سيركو",
                "label_fr": "Cercoux"
            },
            {
                "value": 25961,
                "label": "Montrelais",
                "label_ar": "مونتريليه",
                "label_fr": "Montrelais"
            },
            {
                "value": 25962,
                "label": "Machecoul",
                "label_ar": "مشيكول",
                "label_fr": "Machecoul"
            },
            {
                "value": 25963,
                "label": "Saint-Cyprien-Plage",
                "label_ar": "سان سيبريان بلاج",
                "label_fr": "Saint-Cyprien-Plage"
            },
            {
                "value": 25964,
                "label": "Saint-Parres-aux-Tertres",
                "label_ar": "Saint-Parres-aux-Tertres",
                "label_fr": "Saint-Parres-aux-Tertres"
            },
            {
                "value": 25965,
                "label": "Bessieres",
                "label_ar": "بيسيريس",
                "label_fr": "Bessières"
            },
            {
                "value": 25966,
                "label": "Le Haillan",
                "label_ar": "لو هيلان",
                "label_fr": "Le Haillan"
            },
            {
                "value": 25967,
                "label": "Cousances-les-Forges",
                "label_ar": "كوزانس لي فورج",
                "label_fr": "Cousances-les-Forges"
            },
            {
                "value": 25968,
                "label": "Lons-le-Saunier",
                "label_ar": "Lons-le-Saunier",
                "label_fr": "Lons-le-Saunier"
            },
            {
                "value": 25969,
                "label": "Fontaine-le-Comte",
                "label_ar": "فونتين لو كومت",
                "label_fr": "Fontaine-le-Comte"
            },
            {
                "value": 25970,
                "label": "Saint-Benoit",
                "label_ar": "سان بينوا",
                "label_fr": "Saint-Benoît"
            },
            {
                "value": 25971,
                "label": "Eu",
                "label_ar": "الاتحاد الأوروبي",
                "label_fr": "UE"
            },
            {
                "value": 25972,
                "label": "Lardy",
                "label_ar": "لاردي",
                "label_fr": "Lardy"
            },
            {
                "value": 25973,
                "label": "Saint-Aygulf",
                "label_ar": "سانت ايغولف",
                "label_fr": "Saint-Aygulf"
            },
            {
                "value": 25974,
                "label": "Cesson",
                "label_ar": "سيسون",
                "label_fr": "Cesson"
            },
            {
                "value": 25975,
                "label": "Grande-Synthe",
                "label_ar": "غراند سينث",
                "label_fr": "Grande-Synthe"
            },
            {
                "value": 25976,
                "label": "Cebazat",
                "label_ar": "سيبازات",
                "label_fr": "Cebazat"
            },
            {
                "value": 25977,
                "label": "Mazamet",
                "label_ar": "مازامت",
                "label_fr": "Mazamet"
            },
            {
                "value": 25978,
                "label": "Deauville",
                "label_ar": "دوفيل",
                "label_fr": "Deauville"
            },
            {
                "value": 25979,
                "label": "Brou",
                "label_ar": "برو",
                "label_fr": "Brou"
            },
            {
                "value": 25980,
                "label": "Mortagne-au-Perche",
                "label_ar": "مورتاني أو بيرش",
                "label_fr": "Mortagne-au-Perche"
            },
            {
                "value": 25981,
                "label": "Hauterive",
                "label_ar": "Hauterive",
                "label_fr": "Hauterive"
            },
            {
                "value": 25982,
                "label": "Ivry-la-Bataille",
                "label_ar": "إيفري لا باتاي",
                "label_fr": "Ivry-la-Bataille"
            },
            {
                "value": 25983,
                "label": "Saint-Pierre-d'Entremont",
                "label_ar": "سان بيير دي أنتريمون",
                "label_fr": "Saint-Pierre-d'Entremont"
            },
            {
                "value": 25984,
                "label": "La Chaussee",
                "label_ar": "لا تشاوسيه",
                "label_fr": "La Chaussée"
            },
            {
                "value": 25985,
                "label": "Coudray-au-Perche",
                "label_ar": "كودراي أو بيرش",
                "label_fr": "Coudray-au-Perche"
            },
            {
                "value": 25986,
                "label": "Anet",
                "label_ar": "شبكة",
                "label_fr": "Un filet"
            },
            {
                "value": 25987,
                "label": "Marcheville",
                "label_ar": "ماركيفيل",
                "label_fr": "Marcheville"
            },
            {
                "value": 25988,
                "label": "Fresse",
                "label_ar": "فريس",
                "label_fr": "Fresse"
            },
            {
                "value": 25989,
                "label": "Marpent",
                "label_ar": "ماربنت",
                "label_fr": "Marpent"
            },
            {
                "value": 25990,
                "label": "Villers-Sire-Nicole",
                "label_ar": "فيليرز سيري نيكول",
                "label_fr": "Villers-Sire-Nicole"
            },
            {
                "value": 25991,
                "label": "Coucy-le-Chateau-Auffrique",
                "label_ar": "Coucy-le-Chateau-Auffrique",
                "label_fr": "Coucy-le-Château-Auffrique"
            },
            {
                "value": 25992,
                "label": "Lectoure",
                "label_ar": "محاضرة",
                "label_fr": "Lectoure"
            },
            {
                "value": 25993,
                "label": "Enghien-les-Bains",
                "label_ar": "Enghien-les-Bains",
                "label_fr": "Enghien-les-Bains"
            },
            {
                "value": 25994,
                "label": "Saint-Ouen-l'Aumone",
                "label_ar": "سان أوين لوموني",
                "label_fr": "Saint-Ouen-l'Aumone"
            },
            {
                "value": 25995,
                "label": "Villette-d'Anthon",
                "label_ar": "فيليت دانتون",
                "label_fr": "Villette-d'Anthon"
            },
            {
                "value": 25996,
                "label": "Saint-Just-en-Bas",
                "label_ar": "سان جوست إن باس",
                "label_fr": "Saint-Just-en-Bas"
            },
            {
                "value": 25997,
                "label": "Brunstatt",
                "label_ar": "برونستات",
                "label_fr": "Brunstatt"
            },
            {
                "value": 25998,
                "label": "Collegien",
                "label_ar": "كوليجين",
                "label_fr": "Collegien"
            },
            {
                "value": 25999,
                "label": "Maule",
                "label_ar": "مولي",
                "label_fr": "Maule"
            },
            {
                "value": 26000,
                "label": "Viarmes",
                "label_ar": "فيارميس",
                "label_fr": "Viarmes"
            },
            {
                "value": 26001,
                "label": "Saint-Alban-Leysse",
                "label_ar": "سان ألبان ليسيه",
                "label_fr": "Saint-Alban-Leysse"
            },
            {
                "value": 26002,
                "label": "Labruguiere",
                "label_ar": "لابروجوير",
                "label_fr": "Labruguière"
            },
            {
                "value": 26003,
                "label": "Aussillon",
                "label_ar": "أوسيلون",
                "label_fr": "Aussillon"
            },
            {
                "value": 26004,
                "label": "Brie",
                "label_ar": "جبن أبيض طري",
                "label_fr": "Brie"
            },
            {
                "value": 26005,
                "label": "Hasnon",
                "label_ar": "حسنون",
                "label_fr": "Hasnon"
            },
            {
                "value": 26006,
                "label": "Saint-Die-des-Vosges",
                "label_ar": "Saint-Die-des-Vosges",
                "label_fr": "Saint-Die-des-Vosges"
            },
            {
                "value": 26007,
                "label": "Audenge",
                "label_ar": "أودينجي",
                "label_fr": "Audenge"
            },
            {
                "value": 26008,
                "label": "Saint-Laurent-d'Arce",
                "label_ar": "سان لوران دارس",
                "label_fr": "Saint-Laurent-d'Arce"
            },
            {
                "value": 26009,
                "label": "Geloux",
                "label_ar": "جلوكس",
                "label_fr": "Geloux"
            },
            {
                "value": 26010,
                "label": "Langon",
                "label_ar": "لانجون",
                "label_fr": "Langon"
            },
            {
                "value": 26011,
                "label": "Saint-Magne-de-Castillon",
                "label_ar": "سان ماجني دي كاستيون",
                "label_fr": "Saint-Magne-de-Castillon"
            },
            {
                "value": 26012,
                "label": "Sainte-Neomaye",
                "label_ar": "سانت نيوماي",
                "label_fr": "Sainte-Neomaye"
            },
            {
                "value": 26013,
                "label": "Vergt",
                "label_ar": "فيرغت",
                "label_fr": "Vergt"
            },
            {
                "value": 26014,
                "label": "Tartas",
                "label_ar": "طرطاس",
                "label_fr": "Tartas"
            },
            {
                "value": 26015,
                "label": "Bergerac",
                "label_ar": "برجراك",
                "label_fr": "Bergerac"
            },
            {
                "value": 26016,
                "label": "Veron",
                "label_ar": "فيرون",
                "label_fr": "Véron"
            },
            {
                "value": 26017,
                "label": "Rully",
                "label_ar": "رولي",
                "label_fr": "Rully"
            },
            {
                "value": 26018,
                "label": "Claix",
                "label_ar": "كلايكس",
                "label_fr": "Claix"
            },
            {
                "value": 26019,
                "label": "Saint-Pierre-d'Albigny",
                "label_ar": "سان بيير دالبيني",
                "label_fr": "Saint-Pierre-d'Albigny"
            },
            {
                "value": 26020,
                "label": "L'Isle-sur-la-Sorgue",
                "label_ar": "L'Isle-sur-la-Sorgue. ليل سور لا سورج",
                "label_fr": "L'Isle-sur-la-Sorgue"
            },
            {
                "value": 26021,
                "label": "Le Pont",
                "label_ar": "لو بونت",
                "label_fr": "Le Pont"
            },
            {
                "value": 26022,
                "label": "Angres",
                "label_ar": "أنجرس",
                "label_fr": "Angres"
            },
            {
                "value": 26023,
                "label": "Guesnain",
                "label_ar": "جيسنين",
                "label_fr": "Guesnain"
            },
            {
                "value": 26024,
                "label": "Missillac",
                "label_ar": "ميسيلاك",
                "label_fr": "Missillac"
            },
            {
                "value": 26025,
                "label": "Saint-Victoret",
                "label_ar": "سانت فيكتور",
                "label_fr": "Saint-Victoret"
            },
            {
                "value": 26026,
                "label": "Vidauban",
                "label_ar": "فيداوبان",
                "label_fr": "Vidauban"
            },
            {
                "value": 26027,
                "label": "Peille",
                "label_ar": "بييل",
                "label_fr": "Peille"
            },
            {
                "value": 26028,
                "label": "Saint-Gaudens",
                "label_ar": "سان جودان",
                "label_fr": "Saint-Gaudens"
            },
            {
                "value": 26029,
                "label": "Moissac",
                "label_ar": "موساك",
                "label_fr": "Moissac"
            },
            {
                "value": 26030,
                "label": "Labarthe-sur-Leze",
                "label_ar": "لابارث سور ليز",
                "label_fr": "Labarthe-sur-Leze"
            },
            {
                "value": 26031,
                "label": "Montricoux",
                "label_ar": "مونتريكو",
                "label_fr": "Montricoux"
            },
            {
                "value": 26032,
                "label": "Pinsaguel",
                "label_ar": "بينساغيل",
                "label_fr": "Pinsaguel"
            },
            {
                "value": 26033,
                "label": "Montgaillard",
                "label_ar": "مونتغيلارد",
                "label_fr": "Montgaillard"
            },
            {
                "value": 26034,
                "label": "Leuc",
                "label_ar": "ليوك",
                "label_fr": "Leuc"
            },
            {
                "value": 26035,
                "label": "Bram",
                "label_ar": "برام",
                "label_fr": "Bram"
            },
            {
                "value": 26036,
                "label": "Les Arcs",
                "label_ar": "ليه آركس",
                "label_fr": "Les Arcs"
            },
            {
                "value": 26037,
                "label": "Cabrieres-d'Aigues",
                "label_ar": "Cabrieres-d'Aigues",
                "label_fr": "Cabrières-d'Aigues"
            },
            {
                "value": 26038,
                "label": "Marguerittes",
                "label_ar": "مارجريتس",
                "label_fr": "Marguerittes"
            },
            {
                "value": 26039,
                "label": "Autrans",
                "label_ar": "أوترانس",
                "label_fr": "Autrans"
            },
            {
                "value": 26040,
                "label": "Saint-Etienne-des-Oullieres",
                "label_ar": "سانت إتيان دي اولييه",
                "label_fr": "Saint-Etienne-des-Oullières"
            },
            {
                "value": 26041,
                "label": "Creissels",
                "label_ar": "كريسيلس",
                "label_fr": "Creissels"
            },
            {
                "value": 26042,
                "label": "Villefranche-de-Rouergue",
                "label_ar": "فيلفرانش دي رويرج",
                "label_fr": "Villefranche-de-Rouergue"
            },
            {
                "value": 26043,
                "label": "Naucelle",
                "label_ar": "نوسيل",
                "label_fr": "Naucelle"
            },
            {
                "value": 26044,
                "label": "Azay-le-Brule",
                "label_ar": "أزاي لو برول",
                "label_fr": "Azay-le-Brule"
            },
            {
                "value": 26045,
                "label": "Entrecasteaux",
                "label_ar": "Entrecasteaux",
                "label_fr": "Entrecasteaux"
            },
            {
                "value": 26046,
                "label": "Nanteuil-les-Meaux",
                "label_ar": "Nanteuil-les-Meaux",
                "label_fr": "Nanteuil-les-Meaux"
            },
            {
                "value": 26047,
                "label": "Cranves-Sales",
                "label_ar": "كرنفيس-مبيعات",
                "label_fr": "Cranves-Ventes"
            },
            {
                "value": 26048,
                "label": "Rodez",
                "label_ar": "روديز",
                "label_fr": "Rodez"
            },
            {
                "value": 26049,
                "label": "Lezignan-Corbieres",
                "label_ar": "Lezignan-Corbieres",
                "label_fr": "Lezignan-Corbières"
            },
            {
                "value": 26050,
                "label": "Rieupeyroux",
                "label_ar": "Rieupeyroux",
                "label_fr": "Rieupeyroux"
            },
            {
                "value": 26051,
                "label": "Marennes",
                "label_ar": "مارينز",
                "label_fr": "Marennes"
            },
            {
                "value": 26052,
                "label": "Annezay",
                "label_ar": "أنيزاي",
                "label_fr": "Annezay"
            },
            {
                "value": 26053,
                "label": "Les Mathes",
                "label_ar": "ليه ماثيس",
                "label_fr": "Les Mathes"
            },
            {
                "value": 26054,
                "label": "Chatelaillon-Plage",
                "label_ar": "شاتيلايلون بلاج",
                "label_fr": "Châtelaillon-Plage"
            },
            {
                "value": 26055,
                "label": "Tonnay-Charente",
                "label_ar": "توني شارينت",
                "label_fr": "Tonnay-Charente"
            },
            {
                "value": 26056,
                "label": "Le Bois-Plage-en-Re",
                "label_ar": "لو بوا بلاج أون ري",
                "label_fr": "Le Bois-Plage-en-Re"
            },
            {
                "value": 26057,
                "label": "Arvert",
                "label_ar": "أرفيرت",
                "label_fr": "Arvert"
            },
            {
                "value": 26058,
                "label": "Cozes",
                "label_ar": "Cozes",
                "label_fr": "Cozes"
            },
            {
                "value": 26059,
                "label": "Royan",
                "label_ar": "رويان",
                "label_fr": "Royan"
            },
            {
                "value": 26060,
                "label": "Vouneuil-sur-Vienne",
                "label_ar": "Vouneuil-sur-Vienne",
                "label_fr": "Vouneuil-sur-Vienne"
            },
            {
                "value": 26061,
                "label": "Archiac",
                "label_ar": "أرشياك",
                "label_fr": "Archiac"
            },
            {
                "value": 26062,
                "label": "Ecoyeux",
                "label_ar": "إيكويوكس",
                "label_fr": "Ecoyeux"
            },
            {
                "value": 26063,
                "label": "Trizay",
                "label_ar": "تريزاي",
                "label_fr": "Trizay"
            },
            {
                "value": 26064,
                "label": "Pons",
                "label_ar": "الجسور",
                "label_fr": "Pons"
            },
            {
                "value": 26065,
                "label": "Bourgneuf",
                "label_ar": "بورجنوف",
                "label_fr": "Bourgneuf"
            },
            {
                "value": 26066,
                "label": "Cubzac-les-Ponts",
                "label_ar": "كوبزاك ليه بونتس",
                "label_fr": "Cubzac-les-Ponts"
            },
            {
                "value": 26067,
                "label": "Echillais",
                "label_ar": "Echillais",
                "label_fr": "Echillais"
            },
            {
                "value": 26068,
                "label": "Port-des-Barques",
                "label_ar": "بورت دي بارك",
                "label_fr": "Port-des-Barques"
            },
            {
                "value": 26069,
                "label": "Saint-Georges-d'Oleron",
                "label_ar": "سان جورج دولرون",
                "label_fr": "Saint-Georges-d'Oléron"
            },
            {
                "value": 26070,
                "label": "Rouffignac",
                "label_ar": "روفينياك",
                "label_fr": "Rouffignac"
            },
            {
                "value": 26071,
                "label": "Saint-Jean-de-Liversay",
                "label_ar": "سان جان دي ليفيرساي",
                "label_fr": "Saint-Jean-de-Liversay"
            },
            {
                "value": 26072,
                "label": "Dompierre-sur-Mer",
                "label_ar": "دومبيير سور مير",
                "label_fr": "Dompierre-sur-Mer"
            },
            {
                "value": 26073,
                "label": "Geay",
                "label_ar": "جاي",
                "label_fr": "Geay"
            },
            {
                "value": 26074,
                "label": "Saint-Agnant",
                "label_ar": "سانت - Agnant",
                "label_fr": "Saint-Agnant"
            },
            {
                "value": 26075,
                "label": "Hermies",
                "label_ar": "هيرميس",
                "label_fr": "Hermies"
            },
            {
                "value": 26076,
                "label": "Jaunay-Clan",
                "label_ar": "جوناي كلان",
                "label_fr": "Jaunay-Clan"
            },
            {
                "value": 26077,
                "label": "Pujols",
                "label_ar": "بوجولس",
                "label_fr": "Pujols"
            },
            {
                "value": 26078,
                "label": "Avanton",
                "label_ar": "أفانتون",
                "label_fr": "Avanton"
            },
            {
                "value": 26079,
                "label": "Roullet-Saint-Estephe",
                "label_ar": "روليت سانت إستيف",
                "label_fr": "Roullet-Saint-Estèphe"
            },
            {
                "value": 26080,
                "label": "Saint-Vivien",
                "label_ar": "سانت فيفيان",
                "label_fr": "Saint-Vivien"
            },
            {
                "value": 26081,
                "label": "Martignas-sur-Jalle",
                "label_ar": "Martignas-sur-Jalle",
                "label_fr": "Martignas-sur-Jalle"
            },
            {
                "value": 26082,
                "label": "Chasseneuil-du-Poitou",
                "label_ar": "Chasseneuil-du-Poitou",
                "label_fr": "Chasseneuil-du-Poitou"
            },
            {
                "value": 26083,
                "label": "Beaumont-du-Perigord",
                "label_ar": "بومون دو بيريجورد",
                "label_fr": "Beaumont-du-Périgord"
            },
            {
                "value": 26084,
                "label": "Bonneville-Aptot",
                "label_ar": "بونفيل أبتوت",
                "label_fr": "Bonneville-Aptot"
            },
            {
                "value": 26085,
                "label": "Auberchicourt",
                "label_ar": "أوبيرشيكورت",
                "label_fr": "Auberchicourt"
            },
            {
                "value": 26086,
                "label": "Scorbe-Clairvaux",
                "label_ar": "سكورب كليرفو",
                "label_fr": "Scorbe-Clairvaux"
            },
            {
                "value": 26087,
                "label": "Perigny",
                "label_ar": "الزمر",
                "label_fr": "Perigny"
            },
            {
                "value": 26088,
                "label": "Portets",
                "label_ar": "Portets",
                "label_fr": "Portets"
            },
            {
                "value": 26089,
                "label": "Saint-Robert",
                "label_ar": "سانت روبرت",
                "label_fr": "Saint-Robert"
            },
            {
                "value": 26090,
                "label": "Port-Sainte-Marie",
                "label_ar": "بورت سانت ماري",
                "label_fr": "Port-Sainte-Marie"
            },
            {
                "value": 26091,
                "label": "Saint-Denis-de-Pile",
                "label_ar": "سان دينيس دي بيل",
                "label_fr": "Saint-Denis-de-Pile"
            },
            {
                "value": 26092,
                "label": "La Chevroliere",
                "label_ar": "لا شيفروليير",
                "label_fr": "La Chevrolière"
            },
            {
                "value": 26093,
                "label": "Gujan-Mestras",
                "label_ar": "غوجان ميسترا",
                "label_fr": "Gujan-Mestras"
            },
            {
                "value": 26094,
                "label": "Olemps",
                "label_ar": "أولمبس",
                "label_fr": "Olemps"
            },
            {
                "value": 26095,
                "label": "Sebazac-Concoures",
                "label_ar": "سيبازاك كونكورس",
                "label_fr": "Sebazac-Concoures"
            },
            {
                "value": 26096,
                "label": "Bioule",
                "label_ar": "بيول",
                "label_fr": "Bioule"
            },
            {
                "value": 26097,
                "label": "Saint-Pathus",
                "label_ar": "سانت باتوس",
                "label_fr": "Saint-Pathus"
            },
            {
                "value": 26098,
                "label": "Saint-Georges-de-Baroille",
                "label_ar": "سان جورج دو بارويل",
                "label_fr": "Saint-Georges-de-Baroille"
            },
            {
                "value": 26099,
                "label": "Aubignan",
                "label_ar": "أوبيجنان",
                "label_fr": "Aubignan"
            },
            {
                "value": 26100,
                "label": "Asnans-Beauvoisin",
                "label_ar": "أسنان بوفوازين",
                "label_fr": "Asnans-Beauvoisin"
            },
            {
                "value": 26101,
                "label": "Villers-les-Pots",
                "label_ar": "Villers-les-Pots",
                "label_fr": "Villers-les-Pots"
            },
            {
                "value": 26102,
                "label": "Replonges",
                "label_ar": "يتجدد",
                "label_fr": "Replonges"
            },
            {
                "value": 26103,
                "label": "Ferreol",
                "label_ar": "فيريول",
                "label_fr": "Ferréol"
            },
            {
                "value": 26104,
                "label": "Auxonne",
                "label_ar": "اوكسون",
                "label_fr": "Auxonne"
            },
            {
                "value": 26105,
                "label": "Guny",
                "label_ar": "غوني",
                "label_fr": "Guny"
            },
            {
                "value": 26106,
                "label": "Choisy-en-Brie",
                "label_ar": "تشويسي أون بري",
                "label_fr": "Choisy-en-Brie"
            },
            {
                "value": 26107,
                "label": "Renage",
                "label_ar": "ريناج",
                "label_fr": "Renage"
            },
            {
                "value": 26108,
                "label": "Corcieux",
                "label_ar": "كورسيو",
                "label_fr": "Corcieux"
            },
            {
                "value": 26109,
                "label": "Thaon-les-Vosges",
                "label_ar": "ثون ليه فوج",
                "label_fr": "Thaon-les-Vosges"
            },
            {
                "value": 26110,
                "label": "Malancourt-la-Montagne",
                "label_ar": "مالانكور لا مونتاني",
                "label_fr": "Malancourt-la-Montagne"
            },
            {
                "value": 26111,
                "label": "Thonon-les-Bains",
                "label_ar": "Thonon-les-Bains",
                "label_fr": "Thonon-les-Bains"
            },
            {
                "value": 26112,
                "label": "Remenoville",
                "label_ar": "ريمينوفيل",
                "label_fr": "Remenoville"
            },
            {
                "value": 26113,
                "label": "Damelevieres",
                "label_ar": "داميليفيريس",
                "label_fr": "Damelevieres"
            },
            {
                "value": 26114,
                "label": "Rougemont",
                "label_ar": "روجيمونت",
                "label_fr": "Rougemont"
            },
            {
                "value": 26115,
                "label": "Saint-Maurice-sur-Moselle",
                "label_ar": "سان موريس سور موسيل",
                "label_fr": "Saint-Maurice-sur-Moselle"
            },
            {
                "value": 26116,
                "label": "Publier",
                "label_ar": "أكثر شهرة",
                "label_fr": "Publier"
            },
            {
                "value": 26117,
                "label": "Saessolsheim",
                "label_ar": "Saessolsheim",
                "label_fr": "Saessolsheim"
            },
            {
                "value": 26118,
                "label": "La Cerlangue",
                "label_ar": "لا سيرلانغ",
                "label_fr": "La Cerlangue"
            },
            {
                "value": 26119,
                "label": "La Remuee",
                "label_ar": "لا ريمي",
                "label_fr": "La Remuee"
            },
            {
                "value": 26120,
                "label": "Pont-Audemer",
                "label_ar": "بونت اوديمير",
                "label_fr": "Pont-Audemer"
            },
            {
                "value": 26121,
                "label": "Portvendres",
                "label_ar": "بورتفيندر",
                "label_fr": "Portvendres"
            },
            {
                "value": 26122,
                "label": "Castelsarrasin",
                "label_ar": "Castelsarrasin",
                "label_fr": "Castelsarrasin"
            },
            {
                "value": 26123,
                "label": "Saint-Vallier",
                "label_ar": "سانت فالييه",
                "label_fr": "Saint-Vallier"
            },
            {
                "value": 26124,
                "label": "Dammartin-en-Goele",
                "label_ar": "دامارتين أون جويل",
                "label_fr": "Dammartin-en-Goele"
            },
            {
                "value": 26125,
                "label": "Othis",
                "label_ar": "أوثيس",
                "label_fr": "Othis"
            },
            {
                "value": 26126,
                "label": "Vizille",
                "label_ar": "فيزيل",
                "label_fr": "Vizille"
            },
            {
                "value": 26127,
                "label": "Voreppe",
                "label_ar": "فوربي",
                "label_fr": "Voreppe"
            },
            {
                "value": 26128,
                "label": "Avesnes-sur-Helpe",
                "label_ar": "أفيسنيس سور هيلب",
                "label_fr": "Avesnes-sur-Helpe"
            },
            {
                "value": 26129,
                "label": "Fourmies",
                "label_ar": "فورميس",
                "label_fr": "Fourmies"
            },
            {
                "value": 26130,
                "label": "Camon",
                "label_ar": "كامون",
                "label_fr": "Camon"
            },
            {
                "value": 26131,
                "label": "Corbie",
                "label_ar": "كوربي",
                "label_fr": "Corbie"
            },
            {
                "value": 26132,
                "label": "Quaedypre",
                "label_ar": "Quaedypre",
                "label_fr": "Quaedypre"
            },
            {
                "value": 26133,
                "label": "Vimy",
                "label_ar": "فيمي",
                "label_fr": "Vimy"
            },
            {
                "value": 26134,
                "label": "Garancieres-en-Drouais",
                "label_ar": "Garancieres-en-Drouais",
                "label_fr": "Garancières-en-Drouais"
            },
            {
                "value": 26135,
                "label": "Vineuil",
                "label_ar": "كرمة",
                "label_fr": "Vineuil"
            },
            {
                "value": 26136,
                "label": "Viriat",
                "label_ar": "فيريات",
                "label_fr": "Viriat"
            },
            {
                "value": 26137,
                "label": "Pegomas",
                "label_ar": "بيجوماس",
                "label_fr": "Pégomas"
            },
            {
                "value": 26138,
                "label": "Douzy",
                "label_ar": "دوزي",
                "label_fr": "Douzy"
            },
            {
                "value": 26139,
                "label": "Villemoustaussou",
                "label_ar": "فيلموستاوسو",
                "label_fr": "Villemoustaussou"
            },
            {
                "value": 26140,
                "label": "Vineuil",
                "label_ar": "كرمة",
                "label_fr": "Vineuil"
            },
            {
                "value": 26141,
                "label": "Cassis",
                "label_ar": "كاسيس",
                "label_fr": "Cassis"
            },
            {
                "value": 26142,
                "label": "Les Ormes-sur-Voulzie",
                "label_ar": "ليه أورميس سور فولزي",
                "label_fr": "Les Ormes-sur-Voulzie"
            },
            {
                "value": 26143,
                "label": "Le Deluge",
                "label_ar": "لو ديلوج",
                "label_fr": "Le Déluge"
            },
            {
                "value": 26144,
                "label": "Chaussy",
                "label_ar": "تشاوسي",
                "label_fr": "Chaussy"
            },
            {
                "value": 26145,
                "label": "Avaray",
                "label_ar": "أفاراي",
                "label_fr": "Avaray"
            },
            {
                "value": 26146,
                "label": "Courcelles-sur-Viosne",
                "label_ar": "Courcelles-sur-Viosne",
                "label_fr": "Courcelles-sur-Viosne"
            },
            {
                "value": 26147,
                "label": "Migennes",
                "label_ar": "ميجينيس",
                "label_fr": "Migennes"
            },
            {
                "value": 26148,
                "label": "Saint-Nom-la-Breteche",
                "label_ar": "سان نوم لا بريتش",
                "label_fr": "Saint-Nom-la-Bretèche"
            },
            {
                "value": 26149,
                "label": "Vierzon",
                "label_ar": "فيرزون",
                "label_fr": "Vierzon"
            },
            {
                "value": 26150,
                "label": "Amure",
                "label_ar": "آمور",
                "label_fr": "Amure"
            },
            {
                "value": 26151,
                "label": "Saint-Jean-aux-Amognes",
                "label_ar": "سان جان أو أموج",
                "label_fr": "Saint-Jean-aux-Amognes"
            },
            {
                "value": 26152,
                "label": "L'Isle-d'Abeau",
                "label_ar": "L'Isle-d'Abeau",
                "label_fr": "L'Isle-d'Abeau"
            },
            {
                "value": 26153,
                "label": "Fontanil-Cornillon",
                "label_ar": "فونتانيل كورنيلون",
                "label_fr": "Fontanil-Cornillon"
            },
            {
                "value": 26154,
                "label": "Eragny",
                "label_ar": "إيراغني",
                "label_fr": "Eragny"
            },
            {
                "value": 26155,
                "label": "Galluis",
                "label_ar": "جالويس",
                "label_fr": "Galluis"
            },
            {
                "value": 26156,
                "label": "Saint-Dizier-en-Diois",
                "label_ar": "Saint-Dizier-en-Diois",
                "label_fr": "Saint-Dizier-en-Diois"
            },
            {
                "value": 26157,
                "label": "La Chapelle-d'Armentieres",
                "label_ar": "لا شابيل دي أرمينتيير",
                "label_fr": "La Chapelle-d'Armentières"
            },
            {
                "value": 26158,
                "label": "Le Pradet",
                "label_ar": "لو براديت",
                "label_fr": "Le Pradet"
            },
            {
                "value": 26159,
                "label": "Villard-Bonnot",
                "label_ar": "فيلارد بونو",
                "label_fr": "Villard-Bonnot"
            },
            {
                "value": 26160,
                "label": "Moirans",
                "label_ar": "مويرانس",
                "label_fr": "Moirans"
            },
            {
                "value": 26161,
                "label": "Amboise",
                "label_ar": "أمبواز",
                "label_fr": "Amboise"
            },
            {
                "value": 26162,
                "label": "Chaumontel",
                "label_ar": "شومونتل",
                "label_fr": "Chaumontel"
            },
            {
                "value": 26163,
                "label": "Fosses",
                "label_ar": "الأحافير",
                "label_fr": "Fosses"
            },
            {
                "value": 26164,
                "label": "Herserange",
                "label_ar": "هرسيرانج",
                "label_fr": "Herserange"
            },
            {
                "value": 26165,
                "label": "Puyricard",
                "label_ar": "بويريكارد",
                "label_fr": "Puyricard"
            },
            {
                "value": 26166,
                "label": "Entre-deux-Guiers",
                "label_ar": "Entre-deux-Guiers",
                "label_fr": "Entre-deux-Guiers"
            },
            {
                "value": 26167,
                "label": "Saint-Christophe-sur-Guiers",
                "label_ar": "سان كريستوف سور جوير",
                "label_fr": "Saint-Christophe-sur-Guiers"
            },
            {
                "value": 26168,
                "label": "Bayeux",
                "label_ar": "بايو",
                "label_fr": "Bayeux"
            },
            {
                "value": 26169,
                "label": "Saint-Arnoult",
                "label_ar": "سانت أرنولت",
                "label_fr": "Saint-Arnoult"
            },
            {
                "value": 26170,
                "label": "Cire-d'Aunis",
                "label_ar": "Cire-d'Aunis",
                "label_fr": "Cire-d'Aunis"
            },
            {
                "value": 26171,
                "label": "Soubise",
                "label_ar": "سوبيز",
                "label_fr": "Soubise"
            },
            {
                "value": 26172,
                "label": "Aulnay",
                "label_ar": "أولني",
                "label_fr": "Aulnay"
            },
            {
                "value": 26173,
                "label": "Chaniers",
                "label_ar": "صانير",
                "label_fr": "Chaniers"
            },
            {
                "value": 26174,
                "label": "Lisieux",
                "label_ar": "ليزيو",
                "label_fr": "Lisieux"
            },
            {
                "value": 26175,
                "label": "Saint-Amand-Montrond",
                "label_ar": "سان اماند مونتروند",
                "label_fr": "Saint-Amand-Montrond"
            },
            {
                "value": 26176,
                "label": "Breuillet",
                "label_ar": "بريوليت",
                "label_fr": "Breuillet"
            },
            {
                "value": 26177,
                "label": "Saint-Hippolyte",
                "label_ar": "سانت هيبوليت",
                "label_fr": "Saint-Hippolyte"
            },
            {
                "value": 26178,
                "label": "Saint-Denis-d'Oleron",
                "label_ar": "سان دوني دولرون",
                "label_fr": "Saint-Denis-d'Oléron"
            },
            {
                "value": 26179,
                "label": "La Flotte",
                "label_ar": "لا فلوت",
                "label_fr": "La Flotte"
            },
            {
                "value": 26180,
                "label": "Saint-Sulpice-d'Arnoult",
                "label_ar": "سان سولبيس دي ارنولت",
                "label_fr": "Saint-Sulpice-d'Arnoult"
            },
            {
                "value": 26181,
                "label": "Marans",
                "label_ar": "ماران",
                "label_fr": "Marans"
            },
            {
                "value": 26182,
                "label": "Surgeres",
                "label_ar": "جراحات",
                "label_fr": "Les chirurgies"
            },
            {
                "value": 26183,
                "label": "Vaux-sur-Mer",
                "label_ar": "فو سور مير",
                "label_fr": "Vaux-sur-Mer"
            },
            {
                "value": 26184,
                "label": "Jarny",
                "label_ar": "جارني",
                "label_fr": "Jarny"
            },
            {
                "value": 26185,
                "label": "Thiviers",
                "label_ar": "Thiviers",
                "label_fr": "Thiviers"
            },
            {
                "value": 26186,
                "label": "Morteau",
                "label_ar": "مورتو",
                "label_fr": "Morteau"
            },
            {
                "value": 26187,
                "label": "Rivesaltes",
                "label_ar": "ريفسالت",
                "label_fr": "Rivesaltes"
            },
            {
                "value": 26188,
                "label": "Pierrelatte",
                "label_ar": "بييرلات",
                "label_fr": "Pierrelatte"
            },
            {
                "value": 26189,
                "label": "Angoulins",
                "label_ar": "أنجولين",
                "label_fr": "Angoulins"
            },
            {
                "value": 26190,
                "label": "Breuil-Magne",
                "label_ar": "برويل ماجني",
                "label_fr": "Breuil-Magne"
            },
            {
                "value": 26191,
                "label": "La Chapelle-Baton",
                "label_ar": "لا شابيل باتون",
                "label_fr": "La Chapelle-Baton"
            },
            {
                "value": 26192,
                "label": "Saint-Leger-des-Vignes",
                "label_ar": "Saint-Leger-des-Vignes",
                "label_fr": "Saint-Léger-des-Vignes"
            },
            {
                "value": 26193,
                "label": "Tonnay-Boutonne",
                "label_ar": "توني بوتون",
                "label_fr": "Tonnay-Boutonne"
            },
            {
                "value": 26194,
                "label": "Vallant-Saint-Georges",
                "label_ar": "فالانت سان جورج",
                "label_fr": "Vallant-Saint-Georges"
            },
            {
                "value": 26195,
                "label": "Bompas",
                "label_ar": "بومباس",
                "label_fr": "Bompas"
            },
            {
                "value": 26196,
                "label": "Saint-Servant",
                "label_ar": "القديس خادم",
                "label_fr": "Saint-Servant"
            },
            {
                "value": 26197,
                "label": "Saint-Marcellin",
                "label_ar": "سان مارسيلين",
                "label_fr": "Saint-Marcellin"
            },
            {
                "value": 26198,
                "label": "Perenchies",
                "label_ar": "بيرنشيس",
                "label_fr": "Pérenchies"
            },
            {
                "value": 26199,
                "label": "Bouzel",
                "label_ar": "بوزل",
                "label_fr": "Bouzel"
            },
            {
                "value": 26200,
                "label": "Vertaizon",
                "label_ar": "فيرتازون",
                "label_fr": "Vertaizon"
            },
            {
                "value": 26201,
                "label": "Portet-sur-Garonne",
                "label_ar": "Portet-sur-Garonne",
                "label_fr": "Portet-sur-Garonne"
            },
            {
                "value": 26202,
                "label": "Tournus",
                "label_ar": "تورنو",
                "label_fr": "Tournus"
            },
            {
                "value": 26203,
                "label": "Carrouges",
                "label_ar": "كاروجيس",
                "label_fr": "Carrouges"
            },
            {
                "value": 26204,
                "label": "La Gacilly",
                "label_ar": "لا جاسيلي",
                "label_fr": "La Gacilly"
            },
            {
                "value": 26205,
                "label": "La Loupe",
                "label_ar": "لا لوب",
                "label_fr": "La Loupe"
            },
            {
                "value": 26206,
                "label": "Querenaing",
                "label_ar": "Querenaing",
                "label_fr": "Querenaing"
            },
            {
                "value": 26207,
                "label": "Douvrin",
                "label_ar": "دوفرين",
                "label_fr": "Douvrin"
            },
            {
                "value": 26208,
                "label": "Tergnier",
                "label_ar": "تيرجنير",
                "label_fr": "Tergnier"
            },
            {
                "value": 26209,
                "label": "Lambres-lez-Douai",
                "label_ar": "لامبريس ليز دواي",
                "label_fr": "Lambres-lez-Douai"
            },
            {
                "value": 26210,
                "label": "Blonville-sur-Mer",
                "label_ar": "بلونفيل سور مير",
                "label_fr": "Blonville-sur-Mer"
            },
            {
                "value": 26211,
                "label": "Preaux",
                "label_ar": "برو",
                "label_fr": "Preaux"
            },
            {
                "value": 26212,
                "label": "Saint-Aubin-sur-Gaillon",
                "label_ar": "سان أوبين سور جيلون",
                "label_fr": "Saint-Aubin-sur-Gaillon"
            },
            {
                "value": 26213,
                "label": "Cany-Barville",
                "label_ar": "كاني بارفيل",
                "label_fr": "Cany-Barville"
            },
            {
                "value": 26214,
                "label": "Coudray",
                "label_ar": "كودراي",
                "label_fr": "Coudray"
            },
            {
                "value": 26215,
                "label": "Poses",
                "label_ar": "يطرح",
                "label_fr": "Des poses"
            },
            {
                "value": 26216,
                "label": "Trumilly",
                "label_ar": "تروميلي",
                "label_fr": "Trumilly"
            },
            {
                "value": 26217,
                "label": "Montigny-le-Chartif",
                "label_ar": "مونتيني لو تشارتيف",
                "label_fr": "Montigny-le-Chartif"
            },
            {
                "value": 26218,
                "label": "Nogent-le-Rotrou",
                "label_ar": "نوجينت لو روترو",
                "label_fr": "Nogent-le-Rotrou"
            },
            {
                "value": 26219,
                "label": "Vauchamps",
                "label_ar": "فوتشامبس",
                "label_fr": "Vauchamps"
            },
            {
                "value": 26220,
                "label": "Reuves",
                "label_ar": "روفيس",
                "label_fr": "Reuves"
            },
            {
                "value": 26221,
                "label": "Landivisiau",
                "label_ar": "لانديفيسياو",
                "label_fr": "Landivisiau"
            },
            {
                "value": 26222,
                "label": "Varennes-sur-Seine",
                "label_ar": "فارين سور سين",
                "label_fr": "Varennes-sur-Seine"
            },
            {
                "value": 26223,
                "label": "Noeux-les-Mines",
                "label_ar": "Noeux-les-Mines",
                "label_fr": "Noeux-les-Mines"
            },
            {
                "value": 26224,
                "label": "Attiches",
                "label_ar": "أتيشيس",
                "label_fr": "Attiches"
            },
            {
                "value": 26225,
                "label": "Marsillargues",
                "label_ar": "Marsillargues",
                "label_fr": "Marsillargues"
            },
            {
                "value": 26226,
                "label": "Bouillargues",
                "label_ar": "بويلارج",
                "label_fr": "Bouillargues"
            },
            {
                "value": 26227,
                "label": "Saint-Jean-de-Bournay",
                "label_ar": "سان جان دي بورناي",
                "label_fr": "Saint-Jean-de-Bournay"
            },
            {
                "value": 26228,
                "label": "Pont-de-l'Arche",
                "label_ar": "Pont-de-l'Arche",
                "label_fr": "Pont-de-l'Arche"
            },
            {
                "value": 26229,
                "label": "Le Teilleul",
                "label_ar": "لو تيلول",
                "label_fr": "Le Teilleul"
            },
            {
                "value": 26230,
                "label": "Étretat",
                "label_ar": "إتريتات",
                "label_fr": "Étretat"
            },
            {
                "value": 26231,
                "label": "Saint-Pierre-en-Port",
                "label_ar": "سان بيير أون بورت",
                "label_fr": "Saint-Pierre-en-Port"
            },
            {
                "value": 26232,
                "label": "Elna",
                "label_ar": "إلنا",
                "label_fr": "Elna"
            },
            {
                "value": 26233,
                "label": "Paillencourt",
                "label_ar": "بيلينكور",
                "label_fr": "Paillencourt"
            },
            {
                "value": 26234,
                "label": "Commelle-Vernay",
                "label_ar": "كوميل فيرناي",
                "label_fr": "Commelle-Vernay"
            },
            {
                "value": 26235,
                "label": "La Biolle",
                "label_ar": "لا بيول",
                "label_fr": "La Biolle"
            },
            {
                "value": 26236,
                "label": "Banize",
                "label_ar": "بانيز",
                "label_fr": "Banize"
            },
            {
                "value": 26237,
                "label": "Nohanent",
                "label_ar": "نوهانينت",
                "label_fr": "Nohanent"
            },
            {
                "value": 26238,
                "label": "Ailly-sur-Noye",
                "label_ar": "ايلي سور نوي",
                "label_fr": "Ailly-sur-Noye"
            },
            {
                "value": 26239,
                "label": "Cazeres",
                "label_ar": "كازيريس",
                "label_fr": "Cazères"
            },
            {
                "value": 26240,
                "label": "Montrejeau",
                "label_ar": "مونتريجو",
                "label_fr": "Montrejeau"
            },
            {
                "value": 26241,
                "label": "Lege-Cap-Ferret",
                "label_ar": "ليج كاب فيريت",
                "label_fr": "Lège-Cap-Ferret"
            },
            {
                "value": 26242,
                "label": "Fronsac",
                "label_ar": "فرونساك",
                "label_fr": "Fronsac"
            },
            {
                "value": 26243,
                "label": "Saint-Sebastien",
                "label_ar": "سان سيباستيان",
                "label_fr": "Saint-Sébastien"
            },
            {
                "value": 26244,
                "label": "Pre-en-Pail",
                "label_ar": "Pre-en-Pail",
                "label_fr": "Pré-en-seau"
            },
            {
                "value": 26245,
                "label": "Les Touches",
                "label_ar": "ليه تاتشز",
                "label_fr": "Les Touches"
            },
            {
                "value": 26246,
                "label": "Champeon",
                "label_ar": "شامبيون",
                "label_fr": "Champeon"
            },
            {
                "value": 26247,
                "label": "Chas",
                "label_ar": "تشاس",
                "label_fr": "Chas"
            },
            {
                "value": 26248,
                "label": "La Verpilliere",
                "label_ar": "لا فيربيليير",
                "label_fr": "La Verpillière"
            },
            {
                "value": 26249,
                "label": "Saint-Clair-de-la-Tour",
                "label_ar": "سان كلير دي لا تور",
                "label_fr": "Saint-Clair-de-la-Tour"
            },
            {
                "value": 26250,
                "label": "Mont-de-Marsan",
                "label_ar": "مونت دي مارسان",
                "label_fr": "Mont-de-Marsan"
            },
            {
                "value": 26251,
                "label": "Sallanches",
                "label_ar": "سالانش",
                "label_fr": "Sallanches"
            },
            {
                "value": 26252,
                "label": "Aime",
                "label_ar": "ايم",
                "label_fr": "Aime"
            },
            {
                "value": 26253,
                "label": "Pont-en-Royans",
                "label_ar": "بونت أون رويانز",
                "label_fr": "Pont-en-Royans"
            },
            {
                "value": 26254,
                "label": "Saint-Didier-en-Velay",
                "label_ar": "سان ديدييه أون فيلاي",
                "label_fr": "Saint-Didier-en-Velay"
            },
            {
                "value": 26255,
                "label": "Saint-Loup",
                "label_ar": "سان لوب",
                "label_fr": "Saint-Loup"
            },
            {
                "value": 26256,
                "label": "La Roche-sur-le-Buis",
                "label_ar": "لاروش سور لو بويس",
                "label_fr": "La Roche-sur-le-Buis"
            },
            {
                "value": 26257,
                "label": "Messimy",
                "label_ar": "فوضوي",
                "label_fr": "Messimy"
            },
            {
                "value": 26258,
                "label": "Couternon",
                "label_ar": "Couternon",
                "label_fr": "Couternon"
            },
            {
                "value": 26259,
                "label": "Monistrol-sur-Loire",
                "label_ar": "مونسترول سور لوار",
                "label_fr": "Monistrol-sur-Loire"
            },
            {
                "value": 26260,
                "label": "Ludres",
                "label_ar": "لودرس",
                "label_fr": "Ludres"
            },
            {
                "value": 26261,
                "label": "Boissy-le-Chatel",
                "label_ar": "بويسي لو شاتيل",
                "label_fr": "Boissy-le-Châtel"
            },
            {
                "value": 26262,
                "label": "Romorantin-Lanthenay",
                "label_ar": "رومورانتين لانثيناي",
                "label_fr": "Romorantin-Lanthenay"
            },
            {
                "value": 26263,
                "label": "Vire",
                "label_ar": "فير",
                "label_fr": "Vire"
            },
            {
                "value": 26264,
                "label": "Les Ollieres-sur-Eyrieux",
                "label_ar": "ليه أوليريس سور إيرو",
                "label_fr": "Les Ollières-sur-Eyrieux"
            },
            {
                "value": 26265,
                "label": "Viviers-du-Lac",
                "label_ar": "فيفييه دو لاك",
                "label_fr": "Viviers-du-Lac"
            },
            {
                "value": 26266,
                "label": "Chabons",
                "label_ar": "شابونز",
                "label_fr": "Chabons"
            },
            {
                "value": 26267,
                "label": "La Plagne",
                "label_ar": "لا بلاني",
                "label_fr": "La Plagne"
            },
            {
                "value": 26268,
                "label": "Challes-les-Eaux",
                "label_ar": "Challes-les-Eaux",
                "label_fr": "Challes-les-Eaux"
            },
            {
                "value": 26269,
                "label": "Gap",
                "label_ar": "الفارق",
                "label_fr": "Écart"
            },
            {
                "value": 26270,
                "label": "Ugine",
                "label_ar": "يوجين",
                "label_fr": "Ugine"
            },
            {
                "value": 26271,
                "label": "Sonnay",
                "label_ar": "سوناي",
                "label_fr": "Sonnay"
            },
            {
                "value": 26272,
                "label": "Fegreac",
                "label_ar": "فيغريك",
                "label_fr": "Fegreac"
            },
            {
                "value": 26273,
                "label": "Bauvin",
                "label_ar": "بوفين",
                "label_fr": "Bauvin"
            },
            {
                "value": 26274,
                "label": "Wavrin",
                "label_ar": "وافرين",
                "label_fr": "Wavrin"
            },
            {
                "value": 26275,
                "label": "Plourin",
                "label_ar": "بلورين",
                "label_fr": "Plourin"
            },
            {
                "value": 26276,
                "label": "Saint-Bonnet-de-Mure",
                "label_ar": "سان بونيه دي موري",
                "label_fr": "Saint-Bonnet-de-Mure"
            },
            {
                "value": 26277,
                "label": "Valencin",
                "label_ar": "فالينسين",
                "label_fr": "Valencin"
            },
            {
                "value": 26278,
                "label": "Brouilla",
                "label_ar": "برويلا",
                "label_fr": "Brouilla"
            },
            {
                "value": 26279,
                "label": "Saint-Orens-Pouy-Petit",
                "label_ar": "سان أورينز بوي بيتي",
                "label_fr": "Saint-Orens-Pouy-Petit"
            },
            {
                "value": 26280,
                "label": "Paulhan",
                "label_ar": "بولهان",
                "label_fr": "Paulhan"
            },
            {
                "value": 26281,
                "label": "Limoux",
                "label_ar": "ليموكس",
                "label_fr": "Limoux"
            },
            {
                "value": 26282,
                "label": "Saint-Claude",
                "label_ar": "سان كلود",
                "label_fr": "Saint-Claude"
            },
            {
                "value": 26283,
                "label": "Piennes",
                "label_ar": "بينيس",
                "label_fr": "Piennes"
            },
            {
                "value": 26284,
                "label": "Longlaville",
                "label_ar": "لونغلافيل",
                "label_fr": "Longlaville"
            },
            {
                "value": 26285,
                "label": "Euville",
                "label_ar": "يوفيل",
                "label_fr": "Euville"
            },
            {
                "value": 26286,
                "label": "Saint-Mihiel",
                "label_ar": "سانت ميهيل",
                "label_fr": "Saint-Mihiel"
            },
            {
                "value": 26287,
                "label": "Longeville-en-Barrois",
                "label_ar": "لونجفيل أون باروا",
                "label_fr": "Longeville-en-Barrois"
            },
            {
                "value": 26288,
                "label": "Sainghin-en-Melantois",
                "label_ar": "Sainghin-en-Melantois",
                "label_fr": "Sainghin-en-Mélantois"
            },
            {
                "value": 26289,
                "label": "Violaines",
                "label_ar": "كمان",
                "label_fr": "Violaines"
            },
            {
                "value": 26290,
                "label": "Naintre",
                "label_ar": "نينتري",
                "label_fr": "Naintre"
            },
            {
                "value": 26291,
                "label": "Lussac",
                "label_ar": "لوساك",
                "label_fr": "Lussac"
            },
            {
                "value": 26292,
                "label": "Echire",
                "label_ar": "صدى",
                "label_fr": "Echire"
            },
            {
                "value": 26293,
                "label": "Corme-Royal",
                "label_ar": "كورمي رويال",
                "label_fr": "Corme-Royal"
            },
            {
                "value": 26294,
                "label": "Einville-au-Jard",
                "label_ar": "اينفيل او جارد",
                "label_fr": "Einville-au-Jard"
            },
            {
                "value": 26295,
                "label": "Hadol",
                "label_ar": "هادول",
                "label_fr": "Hadol"
            },
            {
                "value": 26296,
                "label": "Châteauneuf-dIlle-et-Vilaine",
                "label_ar": "شاتونوف ديل وآخرون فيلان",
                "label_fr": "Châteauneuf-d'Ille-et-Vilaine"
            },
            {
                "value": 26297,
                "label": "Baulon",
                "label_ar": "بولون",
                "label_fr": "Baulon"
            },
            {
                "value": 26298,
                "label": "Le Tronchet",
                "label_ar": "لو ترونشيت",
                "label_fr": "Le Tronchet"
            },
            {
                "value": 26299,
                "label": "La Milesse",
                "label_ar": "لا ميليس",
                "label_fr": "La Milesse"
            },
            {
                "value": 26300,
                "label": "Saint-Thurial",
                "label_ar": "سانت ثوريال",
                "label_fr": "Saint-Thurial"
            },
            {
                "value": 26301,
                "label": "Mareil-sur-Loir",
                "label_ar": "مريل سور لوير",
                "label_fr": "Mareil-sur-Loir"
            },
            {
                "value": 26302,
                "label": "Damigny",
                "label_ar": "داميني",
                "label_fr": "Damigny"
            },
            {
                "value": 26303,
                "label": "Pleugueneuc",
                "label_ar": "بليوجوينوك",
                "label_fr": "Pleugueneuc"
            },
            {
                "value": 26304,
                "label": "Commer",
                "label_ar": "كومر",
                "label_fr": "Commer"
            },
            {
                "value": 26305,
                "label": "Saint-Jean-sur-Erve",
                "label_ar": "سان جان سور ايرف",
                "label_fr": "Saint-Jean-sur-Erve"
            },
            {
                "value": 26306,
                "label": "La Tour-Saint-Gelin",
                "label_ar": "لا تور سان جيلين",
                "label_fr": "La Tour-Saint-Gelin"
            },
            {
                "value": 26307,
                "label": "Pluzunet",
                "label_ar": "بلوزونيت",
                "label_fr": "Pluzunet"
            },
            {
                "value": 26308,
                "label": "Laissey",
                "label_ar": "لايسي",
                "label_fr": "Laissey"
            },
            {
                "value": 26309,
                "label": "Saint-Vit",
                "label_ar": "سانت فيت",
                "label_fr": "Saint-Vit"
            },
            {
                "value": 26310,
                "label": "Bonneville",
                "label_ar": "بونفيل",
                "label_fr": "Bonneville"
            },
            {
                "value": 26311,
                "label": "Darnetal",
                "label_ar": "دارنيتال",
                "label_fr": "Darnetal"
            },
            {
                "value": 26312,
                "label": "Folschviller",
                "label_ar": "فولشفيلر",
                "label_fr": "Folschviller"
            },
            {
                "value": 26313,
                "label": "Avon",
                "label_ar": "افون",
                "label_fr": "Avon"
            },
            {
                "value": 26314,
                "label": "Bandol AOC",
                "label_ar": "باندول AOC",
                "label_fr": "Bandol AOC"
            },
            {
                "value": 26315,
                "label": "Saint-Esteve",
                "label_ar": "سانت إستيف",
                "label_fr": "Saint-Estève"
            },
            {
                "value": 26316,
                "label": "Le Lavandou",
                "label_ar": "لو لافاندو",
                "label_fr": "Le Lavandou"
            },
            {
                "value": 26317,
                "label": "Guingamp",
                "label_ar": "Guingamp",
                "label_fr": "Guingamp"
            },
            {
                "value": 26318,
                "label": "Bagneaux-sur-Loing",
                "label_ar": "Bagneaux-sur-Loing",
                "label_fr": "Bagneaux-sur-Loing"
            },
            {
                "value": 26319,
                "label": "Viriville",
                "label_ar": "فيريفيل",
                "label_fr": "Viriville"
            },
            {
                "value": 26320,
                "label": "Auterive",
                "label_ar": "أوتيريفي",
                "label_fr": "Auterive"
            },
            {
                "value": 26321,
                "label": "Baziege",
                "label_ar": "بازيغ",
                "label_fr": "Baziege"
            },
            {
                "value": 26322,
                "label": "Flers",
                "label_ar": "فليرس",
                "label_fr": "Flers"
            },
            {
                "value": 26323,
                "label": "Wattignies",
                "label_ar": "واتيجنيز",
                "label_fr": "Wattignies"
            },
            {
                "value": 26324,
                "label": "Montoir-de-Bretagne",
                "label_ar": "مونتوار دي بريتاني",
                "label_fr": "Montoir-de-Bretagne"
            },
            {
                "value": 26325,
                "label": "Vendin-le-Vieil",
                "label_ar": "Vendin-le-Vieil",
                "label_fr": "Vendin-le-Vieil"
            },
            {
                "value": 26326,
                "label": "Albertville",
                "label_ar": "ألبرتفيل",
                "label_fr": "Albertville"
            },
            {
                "value": 26327,
                "label": "Ardres",
                "label_ar": "Ardres",
                "label_fr": "Ardres"
            },
            {
                "value": 26328,
                "label": "Sanary-sur-Mer",
                "label_ar": "ساناري سور مير",
                "label_fr": "Sanary-sur-Mer"
            },
            {
                "value": 26329,
                "label": "Veneux-les-Sablons",
                "label_ar": "فينكس لي سابلونس",
                "label_fr": "Veneux-les-Sablons"
            },
            {
                "value": 26330,
                "label": "Ecuelles",
                "label_ar": "اكوادور",
                "label_fr": "Ecuelles"
            },
            {
                "value": 26331,
                "label": "Egletons",
                "label_ar": "إيجلتونز",
                "label_fr": "Égletons"
            },
            {
                "value": 26332,
                "label": "Saint-Cyr-l'École",
                "label_ar": "Saint-Cyr-l'École",
                "label_fr": "Saint-Cyr-l'École"
            },
            {
                "value": 26333,
                "label": "Villeneuve-la-Guyard",
                "label_ar": "فيلنوف لا جويارد",
                "label_fr": "Villeneuve-la-Guyard"
            },
            {
                "value": 26334,
                "label": "Lagrave",
                "label_ar": "لاغريف",
                "label_fr": "Lagrave"
            },
            {
                "value": 26335,
                "label": "Saint-Gregoire",
                "label_ar": "سان جريجوار",
                "label_fr": "Saint-Grégoire"
            },
            {
                "value": 26336,
                "label": "Aubiet",
                "label_ar": "أوبيت",
                "label_fr": "Aubiet"
            },
            {
                "value": 26337,
                "label": "Fayssac",
                "label_ar": "فيساك",
                "label_fr": "Fayssac"
            },
            {
                "value": 26338,
                "label": "Valence-d'Albigeois",
                "label_ar": "فالينس دي البيجوا",
                "label_fr": "Valence-d'Albigeois"
            },
            {
                "value": 26339,
                "label": "Cagnac-les-Mines",
                "label_ar": "Cagnac-les-Mines",
                "label_fr": "Cagnac-les-Mines"
            },
            {
                "value": 26340,
                "label": "Vielmur-sur-Agout",
                "label_ar": "فيلمور سور اجوت",
                "label_fr": "Vielmur-sur-Agout"
            },
            {
                "value": 26341,
                "label": "Brousse",
                "label_ar": "بروس",
                "label_fr": "Brousse"
            },
            {
                "value": 26342,
                "label": "Divonne-les-Bains",
                "label_ar": "ديفون ليه با",
                "label_fr": "Divonne-les-Bains"
            },
            {
                "value": 26343,
                "label": "Montagnat",
                "label_ar": "مونتاجنات",
                "label_fr": "Montagnat"
            },
            {
                "value": 26344,
                "label": "Peron",
                "label_ar": "بيرون",
                "label_fr": "Péron"
            },
            {
                "value": 26345,
                "label": "Ornex",
                "label_ar": "Ornex",
                "label_fr": "Ornex"
            },
            {
                "value": 26346,
                "label": "Dole",
                "label_ar": "إعانة",
                "label_fr": "Aumône"
            },
            {
                "value": 26347,
                "label": "Flevy",
                "label_ar": "فلفي",
                "label_fr": "Flevy"
            },
            {
                "value": 26348,
                "label": "Cuvry",
                "label_ar": "كوفري",
                "label_fr": "Cuvry"
            },
            {
                "value": 26349,
                "label": "Sceaux",
                "label_ar": "سكو",
                "label_fr": "Sceaux"
            },
            {
                "value": 26350,
                "label": "Blainville-sur-Orne",
                "label_ar": "Blainville-sur-Orne",
                "label_fr": "Blainville-sur-Orne"
            },
            {
                "value": 26351,
                "label": "Bois-Arnault",
                "label_ar": "بوا أرنو",
                "label_fr": "Bois-Arnault"
            },
            {
                "value": 26352,
                "label": "Luc-sur-Mer",
                "label_ar": "لوك سور مير",
                "label_fr": "Luc-sur-Mer"
            },
            {
                "value": 26353,
                "label": "Appeville",
                "label_ar": "أبفيل",
                "label_fr": "Appeville"
            },
            {
                "value": 26354,
                "label": "Pont-Saint-Pierre",
                "label_ar": "بونت سانت بيير",
                "label_fr": "Pont-Saint-Pierre"
            },
            {
                "value": 26355,
                "label": "Pomport",
                "label_ar": "بومبورت",
                "label_fr": "Pomport"
            },
            {
                "value": 26356,
                "label": "Mios",
                "label_ar": "ميوس",
                "label_fr": "Mios"
            },
            {
                "value": 26357,
                "label": "Barberaz",
                "label_ar": "باربيراز",
                "label_fr": "Barberaz"
            },
            {
                "value": 26358,
                "label": "Wambrechies",
                "label_ar": "Wambrechies",
                "label_fr": "Wambrechies"
            },
            {
                "value": 26359,
                "label": "Loche",
                "label_ar": "لوك",
                "label_fr": "Loche"
            },
            {
                "value": 26360,
                "label": "Echenon",
                "label_ar": "إيشنون",
                "label_fr": "Echenon"
            },
            {
                "value": 26361,
                "label": "Chaspinhac",
                "label_ar": "تشاسبيناك",
                "label_fr": "Chaspinhac"
            },
            {
                "value": 26362,
                "label": "Saint-Savournin",
                "label_ar": "سان سافورنين",
                "label_fr": "Saint-Savournin"
            },
            {
                "value": 26363,
                "label": "Noiron",
                "label_ar": "لا حديد",
                "label_fr": "Noiron"
            },
            {
                "value": 26364,
                "label": "Pessans",
                "label_ar": "بيسان",
                "label_fr": "Pessans"
            },
            {
                "value": 26365,
                "label": "Cuges-les-Pins",
                "label_ar": "كوج ليس بينس",
                "label_fr": "Cuges-les-Pins"
            },
            {
                "value": 26366,
                "label": "Uchaux",
                "label_ar": "أوشو",
                "label_fr": "Uchaux"
            },
            {
                "value": 26367,
                "label": "Mormant",
                "label_ar": "نائم",
                "label_fr": "Mormant"
            },
            {
                "value": 26368,
                "label": "Auvers-Saint-Georges",
                "label_ar": "أوفيرس سان جورج",
                "label_fr": "Auvers-Saint-Georges"
            },
            {
                "value": 26369,
                "label": "Fresnay-l'Eveque",
                "label_ar": "فريسناي لافيك",
                "label_fr": "Fresnay-l'Évêque"
            },
            {
                "value": 26370,
                "label": "La Bazoche-Gouet",
                "label_ar": "La Bazoche-Gouet",
                "label_fr": "La Bazoche-Gouet"
            },
            {
                "value": 26371,
                "label": "Souprosse",
                "label_ar": "سوبروس",
                "label_fr": "Souprosse"
            },
            {
                "value": 26372,
                "label": "Cysoing",
                "label_ar": "سيسوينج",
                "label_fr": "Cysoing"
            },
            {
                "value": 26373,
                "label": "Annequin",
                "label_ar": "أنيكين",
                "label_fr": "Annequin"
            },
            {
                "value": 26374,
                "label": "Templeuve",
                "label_ar": "تمبلوف",
                "label_fr": "Templeuve"
            },
            {
                "value": 26375,
                "label": "Dormans",
                "label_ar": "دورمانز",
                "label_fr": "Dormans"
            },
            {
                "value": 26376,
                "label": "Cogolin",
                "label_ar": "كوجولين",
                "label_fr": "Cogolin"
            },
            {
                "value": 26377,
                "label": "La Voulte-sur-Rhone",
                "label_ar": "لا فولتي سور رون",
                "label_fr": "La Voulte-sur-Rhône"
            },
            {
                "value": 26378,
                "label": "Le Teil",
                "label_ar": "لو تيل",
                "label_fr": "Le Teil"
            },
            {
                "value": 26379,
                "label": "Bellegarde-sur-Valserine",
                "label_ar": "بيلجارد سور فالسيرين",
                "label_fr": "Bellegarde-sur-Valserine"
            },
            {
                "value": 26380,
                "label": "Cloyes-sur-le-Loir",
                "label_ar": "كلوي سور لو لوير",
                "label_fr": "Cloyes-sur-le-Loir"
            },
            {
                "value": 26381,
                "label": "Viuz-en-Sallaz",
                "label_ar": "Viuz-en-Sallaz",
                "label_fr": "Viuz-en-Sallaz"
            },
            {
                "value": 26382,
                "label": "Saint-Michel-Chef-Chef",
                "label_ar": "سان ميشيل شيف شيف",
                "label_fr": "Saint-Michel-Chef-Chef"
            },
            {
                "value": 26383,
                "label": "Cuers",
                "label_ar": "كويرز",
                "label_fr": "Cuers"
            },
            {
                "value": 26384,
                "label": "Saint-Maximin-la-Sainte-Baume",
                "label_ar": "سان ماكسيمين لا سانت بوم",
                "label_fr": "Saint-Maximin-la-Sainte-Baume"
            },
            {
                "value": 26385,
                "label": "Saint-Chaptes",
                "label_ar": "سانت تشابتس",
                "label_fr": "Saint-Chaptes"
            },
            {
                "value": 26386,
                "label": "Couhe",
                "label_ar": "كوهي",
                "label_fr": "Couhé"
            },
            {
                "value": 26387,
                "label": "Montreuil-Bonnin",
                "label_ar": "مونتروي بونين",
                "label_fr": "Montreuil-Bonnin"
            },
            {
                "value": 26388,
                "label": "La Mailleraye-sur-Seine",
                "label_ar": "لا مايليري سور سين",
                "label_fr": "La Mailleraye-sur-Seine"
            },
            {
                "value": 26389,
                "label": "Marcheprime",
                "label_ar": "Marcheprime",
                "label_fr": "Marcheprime"
            },
            {
                "value": 26390,
                "label": "Morcenx",
                "label_ar": "مورسينكس",
                "label_fr": "Morcenx"
            },
            {
                "value": 26391,
                "label": "Arengosse",
                "label_ar": "أرينغوس",
                "label_fr": "Arengosse"
            },
            {
                "value": 26392,
                "label": "Vicq-d'Auribat",
                "label_ar": "Vicq-d'Auribat",
                "label_fr": "Vicq-d'Auribat"
            },
            {
                "value": 26393,
                "label": "Caudebec-en-Caux",
                "label_ar": "Caudebec-en-Caux",
                "label_fr": "Caudebec-en-Caux"
            },
            {
                "value": 26394,
                "label": "Saint-Andre-le-Gaz",
                "label_ar": "سان أندريه لو جاز",
                "label_fr": "Saint-André-le-Gaz"
            },
            {
                "value": 26395,
                "label": "Boussy-Saint-Antoine",
                "label_ar": "بوسي سان انطوان",
                "label_fr": "Boussy-Saint-Antoine"
            },
            {
                "value": 26396,
                "label": "Vaureal",
                "label_ar": "فورال",
                "label_fr": "Vauréal"
            },
            {
                "value": 26397,
                "label": "Montesquieu-Volvestre",
                "label_ar": "مونتسكيو فولفيستر",
                "label_fr": "Montesquieu-Volvestre"
            },
            {
                "value": 26398,
                "label": "Saint-Gerand-de-Vaux",
                "label_ar": "سان جيران دي فو",
                "label_fr": "Saint-Gérand-de-Vaux"
            },
            {
                "value": 26399,
                "label": "Abondant",
                "label_ar": "غزير",
                "label_fr": "Abondant"
            },
            {
                "value": 26400,
                "label": "Vendome",
                "label_ar": "فيندوم",
                "label_fr": "Vendôme"
            },
            {
                "value": 26401,
                "label": "Laneuveville-devant-Nancy",
                "label_ar": "لينوففيل ديفانت نانسي",
                "label_fr": "Laneuveville-devant-Nancy"
            },
            {
                "value": 26402,
                "label": "Monbalen",
                "label_ar": "مونبالين",
                "label_fr": "Monbalen"
            },
            {
                "value": 26403,
                "label": "Boutigny-Prouais",
                "label_ar": "بوتيني برويس",
                "label_fr": "Boutigny-Prouais"
            },
            {
                "value": 26404,
                "label": "Versonnex",
                "label_ar": "فيرسونيكس",
                "label_fr": "Versonnex"
            },
            {
                "value": 26405,
                "label": "Magny-le-Hongre",
                "label_ar": "ماجني لو هونجر",
                "label_fr": "Magny-le-Hongre"
            },
            {
                "value": 26406,
                "label": "Vivy",
                "label_ar": "فيفي",
                "label_fr": "Vivy"
            },
            {
                "value": 26407,
                "label": "Boissy-Mauvoisin",
                "label_ar": "Boissy-Mauvoisin",
                "label_fr": "Boissy-Mauvoisin"
            },
            {
                "value": 26408,
                "label": "La Fleche",
                "label_ar": "لا فليش",
                "label_fr": "La Flèche"
            },
            {
                "value": 26409,
                "label": "La Ferte",
                "label_ar": "لا فيرت",
                "label_fr": "La Ferté"
            },
            {
                "value": 26410,
                "label": "Chablis",
                "label_ar": "شابلي",
                "label_fr": "Chablis"
            },
            {
                "value": 26411,
                "label": "Soulaincourt",
                "label_ar": "سولينكورت",
                "label_fr": "Soulaincourt"
            },
            {
                "value": 26412,
                "label": "Sees",
                "label_ar": "يرى",
                "label_fr": "Voit"
            },
            {
                "value": 26413,
                "label": "Vitry-la-Ville",
                "label_ar": "فيتري لا فيل",
                "label_fr": "Vitry-la-Ville"
            },
            {
                "value": 26414,
                "label": "Chateau-sur-Epte",
                "label_ar": "شاتو سور ابتي",
                "label_fr": "Château-sur-Epte"
            },
            {
                "value": 26415,
                "label": "Saint-Thierry",
                "label_ar": "سانت تييري",
                "label_fr": "Saint-Thierry"
            },
            {
                "value": 26416,
                "label": "Biesles",
                "label_ar": "بيسليس",
                "label_fr": "Biesles"
            },
            {
                "value": 26417,
                "label": "Kienheim",
                "label_ar": "كينهايم",
                "label_fr": "Kienheim"
            },
            {
                "value": 26418,
                "label": "Les Thilliers-en-Vexin",
                "label_ar": "ليه Thilliers-en-Vexin",
                "label_fr": "Les Thilliers-en-Vexin"
            },
            {
                "value": 26419,
                "label": "Ussac",
                "label_ar": "أوساك",
                "label_fr": "Ussac"
            },
            {
                "value": 26420,
                "label": "La Souterraine",
                "label_ar": "لا سوترين",
                "label_fr": "La Souterraine"
            },
            {
                "value": 26421,
                "label": "Sainte-Soulle",
                "label_ar": "سانت سول",
                "label_fr": "Sainte-Soulle"
            },
            {
                "value": 26422,
                "label": "Sarge-sur-Braye",
                "label_ar": "سارج سور براي",
                "label_fr": "Sarge-sur-Braye"
            },
            {
                "value": 26423,
                "label": "Verzenay",
                "label_ar": "Verzenay",
                "label_fr": "Verzenay"
            },
            {
                "value": 26424,
                "label": "Hersin-Coupigny",
                "label_ar": "هرسين كوبيني",
                "label_fr": "Hersin-Coupigny"
            },
            {
                "value": 26425,
                "label": "Auchy-les-Mines",
                "label_ar": "أوشي لي ماينز",
                "label_fr": "Auchy-les-Mines"
            },
            {
                "value": 26426,
                "label": "Saint-Paul-en-Cornillon",
                "label_ar": "سانت بول إن كورنيلون",
                "label_fr": "Saint-Paul-en-Cornillon"
            },
            {
                "value": 26427,
                "label": "Camaret-sur-Mer",
                "label_ar": "كاماريت سور مير",
                "label_fr": "Camaret-sur-Mer"
            },
            {
                "value": 26428,
                "label": "Cornas",
                "label_ar": "كورناس",
                "label_fr": "Cornas"
            },
            {
                "value": 26429,
                "label": "Chaon",
                "label_ar": "تشون",
                "label_fr": "Chaon"
            },
            {
                "value": 26430,
                "label": "Loriol-sur-Drome",
                "label_ar": "لوريول سور دروم",
                "label_fr": "Loriol-sur-Drôme"
            },
            {
                "value": 26431,
                "label": "Toussieu",
                "label_ar": "توسيو",
                "label_fr": "Toussieu"
            },
            {
                "value": 26432,
                "label": "Saint-Amant-Tallende",
                "label_ar": "سانت امانت تاليندي",
                "label_fr": "Saint-Amant-Tallende"
            },
            {
                "value": 26433,
                "label": "Ciry-le-Noble",
                "label_ar": "سيري لو نوبل",
                "label_fr": "Ciry-le-Noble"
            },
            {
                "value": 26434,
                "label": "Villers-sur-Saulnot",
                "label_ar": "فيلير سور سولنوت",
                "label_fr": "Villers-sur-Saulnot"
            },
            {
                "value": 26435,
                "label": "Bethoncourt",
                "label_ar": "بيث أونكور",
                "label_fr": "Bethoncourt"
            },
            {
                "value": 26436,
                "label": "Villemoirieu",
                "label_ar": "فيلمواريو",
                "label_fr": "Villemoirieu"
            },
            {
                "value": 26437,
                "label": "Valines",
                "label_ar": "فالينس",
                "label_fr": "Valines"
            },
            {
                "value": 26438,
                "label": "Belmont",
                "label_ar": "بلمونت",
                "label_fr": "Belmont"
            },
            {
                "value": 26439,
                "label": "Schweighouse-Thann",
                "label_ar": "شفايغوز تان",
                "label_fr": "Schweighouse-Thann"
            },
            {
                "value": 26440,
                "label": "Sainte-Anne-Saint-Priest",
                "label_ar": "سانت آن سانت بريست",
                "label_fr": "Sainte-Anne-Saint-Priest"
            },
            {
                "value": 26441,
                "label": "Portes-les-Valence",
                "label_ar": "بورتس ليه فالينس",
                "label_fr": "Portes-les-Valence"
            },
            {
                "value": 26442,
                "label": "Saint-Felicien",
                "label_ar": "سانت فيليسيان",
                "label_fr": "Saint-Félicien"
            },
            {
                "value": 26443,
                "label": "Lablachere",
                "label_ar": "لابلاتشير",
                "label_fr": "Lablachere"
            },
            {
                "value": 26444,
                "label": "Montbizot",
                "label_ar": "مونبيزوت",
                "label_fr": "Montbizot"
            },
            {
                "value": 26445,
                "label": "Ussel",
                "label_ar": "أوسيل",
                "label_fr": "Ussel"
            },
            {
                "value": 26446,
                "label": "Puget-sur-Argens",
                "label_ar": "بوجيت سور أرجينز",
                "label_fr": "Puget-sur-Argens"
            },
            {
                "value": 26447,
                "label": "Tresques",
                "label_ar": "تريسكويس",
                "label_fr": "Tresques"
            },
            {
                "value": 26448,
                "label": "Ozolles",
                "label_ar": "أوزوليس",
                "label_fr": "Ozolles"
            },
            {
                "value": 26449,
                "label": "Le Teich",
                "label_ar": "لو تيش",
                "label_fr": "Le Teich"
            },
            {
                "value": 26450,
                "label": "Perrigny",
                "label_ar": "بيرجني",
                "label_fr": "Perrigny"
            },
            {
                "value": 26451,
                "label": "Limerzel",
                "label_ar": "ليميرزل",
                "label_fr": "Limerzel"
            },
            {
                "value": 26452,
                "label": "Etrepagny",
                "label_ar": "إتريباني",
                "label_fr": "Etrepagny"
            },
            {
                "value": 26453,
                "label": "Saint-Urbain",
                "label_ar": "سان اوربان",
                "label_fr": "Saint-Urbain"
            },
            {
                "value": 26454,
                "label": "Trouville-sur-Mer",
                "label_ar": "تروفيل سور مير",
                "label_fr": "Trouville-sur-Mer"
            },
            {
                "value": 26455,
                "label": "Gravigny",
                "label_ar": "مرق",
                "label_fr": "Gravigny"
            },
            {
                "value": 26456,
                "label": "Pont-du-Casse",
                "label_ar": "بونت دو كاس",
                "label_fr": "Pont-du-Casse"
            },
            {
                "value": 26457,
                "label": "Mornant",
                "label_ar": "صباح",
                "label_fr": "Mornant"
            },
            {
                "value": 26458,
                "label": "Nuits-Saint-Georges",
                "label_ar": "Nuits-Saint-Georges",
                "label_fr": "Nuits-Saint-Georges"
            },
            {
                "value": 26459,
                "label": "Les Mages",
                "label_ar": "ليه ماجيس",
                "label_fr": "Les Mages"
            },
            {
                "value": 26460,
                "label": "Ruch",
                "label_ar": "روخ",
                "label_fr": "Ruch"
            },
            {
                "value": 26461,
                "label": "Alluy",
                "label_ar": "اللوي",
                "label_fr": "Alluy"
            },
            {
                "value": 26462,
                "label": "Decize",
                "label_ar": "قرر",
                "label_fr": "Decize"
            },
            {
                "value": 26463,
                "label": "Mennecy",
                "label_ar": "مينيسي",
                "label_fr": "Mennecy"
            },
            {
                "value": 26464,
                "label": "Bouffemont",
                "label_ar": "بوفمونت",
                "label_fr": "Bouffemont"
            },
            {
                "value": 26465,
                "label": "Brasles",
                "label_ar": "براسليس",
                "label_fr": "Brasles"
            },
            {
                "value": 26466,
                "label": "Venelles",
                "label_ar": "فينيل",
                "label_fr": "Venelles"
            },
            {
                "value": 26467,
                "label": "Chateauneuf-du-Rhone",
                "label_ar": "شاتونوف دو رون",
                "label_fr": "Châteauneuf-du-Rhône"
            },
            {
                "value": 26468,
                "label": "Neuille",
                "label_ar": "نويل",
                "label_fr": "Neuille"
            },
            {
                "value": 26469,
                "label": "Chateauneuf-sur-Sarthe",
                "label_ar": "شاتونوف سور سارث",
                "label_fr": "Châteauneuf-sur-Sarthe"
            },
            {
                "value": 26470,
                "label": "Vinay",
                "label_ar": "فيناي",
                "label_fr": "Vinay"
            },
            {
                "value": 26471,
                "label": "Villaz",
                "label_ar": "فيلاز",
                "label_fr": "Villaz"
            },
            {
                "value": 26472,
                "label": "Baune",
                "label_ar": "باون",
                "label_fr": "Baune"
            },
            {
                "value": 26473,
                "label": "Champtoceaux",
                "label_ar": "Champtoceaux",
                "label_fr": "Champtoceaux"
            },
            {
                "value": 26474,
                "label": "Pelussin",
                "label_ar": "بيلوسين",
                "label_fr": "Pelussin"
            },
            {
                "value": 26475,
                "label": "Salaise-sur-Sanne",
                "label_ar": "ساليس سور سان",
                "label_fr": "Salaise-sur-Sanne"
            },
            {
                "value": 26476,
                "label": "Ennevelin",
                "label_ar": "إينيفلين",
                "label_fr": "Ennevelin"
            },
            {
                "value": 26477,
                "label": "Lillers",
                "label_ar": "ليلرز",
                "label_fr": "Lillers"
            },
            {
                "value": 26478,
                "label": "Etoges",
                "label_ar": "إتوجيس",
                "label_fr": "Etoges"
            },
            {
                "value": 26479,
                "label": "Pont-sur-Yonne",
                "label_ar": "بونت سور يون",
                "label_fr": "Pont-sur-Yonne"
            },
            {
                "value": 26480,
                "label": "Allonville",
                "label_ar": "ألونفيل",
                "label_fr": "Allonville"
            },
            {
                "value": 26481,
                "label": "Valenton",
                "label_ar": "فالنتون",
                "label_fr": "Valenton"
            },
            {
                "value": 26482,
                "label": "Chateaufort",
                "label_ar": "شاتوفورت",
                "label_fr": "Châteaufort"
            },
            {
                "value": 26483,
                "label": "Fere-Champenoise",
                "label_ar": "فير تشامبينيز",
                "label_fr": "Fère-Champenoise"
            },
            {
                "value": 26484,
                "label": "Caylus",
                "label_ar": "كايلوس",
                "label_fr": "Caylus"
            },
            {
                "value": 26485,
                "label": "Sin-le-Noble",
                "label_ar": "سين لو نوبل",
                "label_fr": "Sin-le-Noble"
            },
            {
                "value": 26486,
                "label": "Saint-Martial-de-Vitaterne",
                "label_ar": "سان مارتيال دي فيتاتيرن",
                "label_fr": "Saint-Martial-de-Vitaterne"
            },
            {
                "value": 26487,
                "label": "Couzeix",
                "label_ar": "كوزيكس",
                "label_fr": "Couzeix"
            },
            {
                "value": 26488,
                "label": "Malemort-sur-Correze",
                "label_ar": "ماليمورت سور كوريز",
                "label_fr": "Malemort-sur-Corrèze"
            },
            {
                "value": 26489,
                "label": "Ambazac",
                "label_ar": "أمبازاك",
                "label_fr": "Ambazac"
            },
            {
                "value": 26490,
                "label": "Saint-Yrieix-la-Perche",
                "label_ar": "سانت يرييكس لا بيرش",
                "label_fr": "Saint-Yrieix-la-Perche"
            },
            {
                "value": 26491,
                "label": "Saint-Chartres",
                "label_ar": "سان شارتر",
                "label_fr": "Saint-Chartres"
            },
            {
                "value": 26492,
                "label": "Allonnes",
                "label_ar": "ألونيس",
                "label_fr": "Allonnes"
            },
            {
                "value": 26493,
                "label": "Connerre",
                "label_ar": "كونير",
                "label_fr": "Connerre"
            },
            {
                "value": 26494,
                "label": "Varennes-Vauzelles",
                "label_ar": "Varennes-Vauzelles",
                "label_fr": "Varennes-Vauzelles"
            },
            {
                "value": 26495,
                "label": "La Jarrie",
                "label_ar": "لا جاري",
                "label_fr": "La Jarrie"
            },
            {
                "value": 26496,
                "label": "Forges",
                "label_ar": "تزوير",
                "label_fr": "Forges"
            },
            {
                "value": 26497,
                "label": "Champagne-les-Marais",
                "label_ar": "شامبين ليه ماريه",
                "label_fr": "Champagne-les-Marais"
            },
            {
                "value": 26498,
                "label": "Toussus-le-Noble",
                "label_ar": "توسوس لو نوبل",
                "label_fr": "Toussus-le-Noble"
            },
            {
                "value": 26499,
                "label": "Roye-sur-Matz",
                "label_ar": "روي سور ماتز",
                "label_fr": "Roye-sur-Matz"
            },
            {
                "value": 26500,
                "label": "Lormaye",
                "label_ar": "لورماي",
                "label_fr": "Lormaye"
            },
            {
                "value": 26501,
                "label": "Saint-Arnoult-en-Yvelines",
                "label_ar": "سان أرنولت أون إيفلين",
                "label_fr": "Saint-Arnoult-en-Yvelines"
            },
            {
                "value": 26502,
                "label": "Perros-Guirec",
                "label_ar": "بيروس جيريك",
                "label_fr": "Perros-Guirec"
            },
            {
                "value": 26503,
                "label": "Saint-Quay-Perros",
                "label_ar": "سان كواي بيروس",
                "label_fr": "Saint-Quay-Perros"
            },
            {
                "value": 26504,
                "label": "Montvalen",
                "label_ar": "مونتفالين",
                "label_fr": "Montvalen"
            },
            {
                "value": 26505,
                "label": "Étaples",
                "label_ar": "إتابلز",
                "label_fr": "Étaples"
            },
            {
                "value": 26506,
                "label": "Licques",
                "label_ar": "ليكس",
                "label_fr": "Licques"
            },
            {
                "value": 26507,
                "label": "Morangis",
                "label_ar": "مورانجيس",
                "label_fr": "Morangis"
            },
            {
                "value": 26508,
                "label": "Sauzelles",
                "label_ar": "Sauzelles",
                "label_fr": "Sauzelles"
            },
            {
                "value": 26509,
                "label": "Buzan",
                "label_ar": "بوزان",
                "label_fr": "Buzan"
            },
            {
                "value": 26510,
                "label": "La Mure",
                "label_ar": "لا مور",
                "label_fr": "La Mure"
            },
            {
                "value": 26511,
                "label": "Feissons-sur-Isere",
                "label_ar": "Feissons-sur-Isere",
                "label_fr": "Feissons-sur-Isère"
            },
            {
                "value": 26512,
                "label": "Culoz",
                "label_ar": "كولوز",
                "label_fr": "Culoz"
            },
            {
                "value": 26513,
                "label": "Jarcieu",
                "label_ar": "جارسيو",
                "label_fr": "Jarcieu"
            },
            {
                "value": 26514,
                "label": "Chignin",
                "label_ar": "شجنين",
                "label_fr": "Chignin"
            },
            {
                "value": 26515,
                "label": "Berd'huis",
                "label_ar": "بردهويس",
                "label_fr": "Berd'huis"
            },
            {
                "value": 26516,
                "label": "Woippy",
                "label_ar": "وويبي",
                "label_fr": "Woippy"
            },
            {
                "value": 26517,
                "label": "Hardencourt",
                "label_ar": "هاردينكورت",
                "label_fr": "Hardencourt"
            },
            {
                "value": 26518,
                "label": "Vendargues",
                "label_ar": "Vendargues",
                "label_fr": "Vendargues"
            },
            {
                "value": 26519,
                "label": "Semoine",
                "label_ar": "سيموين",
                "label_fr": "Sémoine"
            },
            {
                "value": 26520,
                "label": "Courcelles-Val-d'Esnoms",
                "label_ar": "Courcelles-Val-d'Esnoms",
                "label_fr": "Courcelles-Val-d'Esnoms"
            },
            {
                "value": 26521,
                "label": "Verdun",
                "label_ar": "فردان",
                "label_fr": "Verdun"
            },
            {
                "value": 26522,
                "label": "Neuves-Maisons",
                "label_ar": "Neuves-Maisons",
                "label_fr": "Neuves-Maisons"
            },
            {
                "value": 26523,
                "label": "La Varenne",
                "label_ar": "لا فارين",
                "label_fr": "La Varenne"
            },
            {
                "value": 26524,
                "label": "Saint-Martin-le-Vinoux",
                "label_ar": "سان مارتن لو فينو",
                "label_fr": "Saint-Martin-le-Vinoux"
            },
            {
                "value": 26525,
                "label": "Drachenbronn-Birlenbach",
                "label_ar": "دراشينبرون بيرلينباخ",
                "label_fr": "Drachenbronn-Birlenbach"
            },
            {
                "value": 26526,
                "label": "Mathenay",
                "label_ar": "ماثيني",
                "label_fr": "Mathenay"
            },
            {
                "value": 26527,
                "label": "Poulainville",
                "label_ar": "بولينفيل",
                "label_fr": "Poulainville"
            },
            {
                "value": 26528,
                "label": "Plounerin",
                "label_ar": "بلونيرين",
                "label_fr": "Plounerin"
            },
            {
                "value": 26529,
                "label": "Merxheim",
                "label_ar": "ميركسهايم",
                "label_fr": "Merxheim"
            },
            {
                "value": 26530,
                "label": "La Saulce",
                "label_ar": "لا سول",
                "label_fr": "La Saulce"
            },
            {
                "value": 26531,
                "label": "Chabottes",
                "label_ar": "شابوت",
                "label_fr": "Chabottes"
            },
            {
                "value": 26532,
                "label": "Vallouise",
                "label_ar": "فالويز",
                "label_fr": "Vallouise"
            },
            {
                "value": 26533,
                "label": "Nouzonville",
                "label_ar": "نوزونفيل",
                "label_fr": "Nouzonville"
            },
            {
                "value": 26534,
                "label": "Juniville",
                "label_ar": "جونيفيل",
                "label_fr": "Juniville"
            },
            {
                "value": 26535,
                "label": "Sommerance",
                "label_ar": "سومرانس",
                "label_fr": "Sommerance"
            },
            {
                "value": 26536,
                "label": "Vouziers",
                "label_ar": "فوزيرز",
                "label_fr": "Vouziers"
            },
            {
                "value": 26537,
                "label": "Chehery",
                "label_ar": "شهيري",
                "label_fr": "Chehery"
            },
            {
                "value": 26538,
                "label": "Jandun",
                "label_ar": "جاندون",
                "label_fr": "Jandun"
            },
            {
                "value": 26539,
                "label": "Saint-Andre-de-Chalencon",
                "label_ar": "سان أندريه دي تشالينكون",
                "label_fr": "Saint-André-de-Chalencon"
            },
            {
                "value": 26540,
                "label": "Centres",
                "label_ar": "المراكز",
                "label_fr": "Centres"
            },
            {
                "value": 26541,
                "label": "Decazeville",
                "label_ar": "ديكازفيل",
                "label_fr": "Decazeville"
            },
            {
                "value": 26542,
                "label": "Aubin",
                "label_ar": "أوبين",
                "label_fr": "Aubin"
            },
            {
                "value": 26543,
                "label": "Saint-Affrique",
                "label_ar": "سانت أفريك",
                "label_fr": "Saint-Affrique"
            },
            {
                "value": 26544,
                "label": "Charost",
                "label_ar": "شاروست",
                "label_fr": "Charost"
            },
            {
                "value": 26545,
                "label": "Mereau",
                "label_ar": "ميرو",
                "label_fr": "Mereau"
            },
            {
                "value": 26546,
                "label": "Vailly-sur-Sauldre",
                "label_ar": "Vailly-sur-Sauldre",
                "label_fr": "Vailly-sur-Sauldre"
            },
            {
                "value": 26547,
                "label": "Saint-Florent",
                "label_ar": "سانت فلوران",
                "label_fr": "Saint-Florent"
            },
            {
                "value": 26548,
                "label": "Aléria",
                "label_ar": "أليريا",
                "label_fr": "Aléria"
            },
            {
                "value": 26549,
                "label": "Brando",
                "label_ar": "براندو",
                "label_fr": "Brando"
            },
            {
                "value": 26550,
                "label": "Oletta",
                "label_ar": "اوليتا",
                "label_fr": "Oletta"
            },
            {
                "value": 26551,
                "label": "Gérardmer",
                "label_ar": "جيرارمر",
                "label_fr": "Gérardmer"
            },
            {
                "value": 26552,
                "label": "Saint-Etienne-les-Orgues",
                "label_ar": "سانت إتيان ليه أورغ",
                "label_fr": "Saint-Étienne-les-Orgues"
            },
            {
                "value": 26553,
                "label": "Reillanne",
                "label_ar": "Reillanne",
                "label_fr": "Reillanne"
            },
            {
                "value": 26554,
                "label": "Sisteron",
                "label_ar": "سيسترون",
                "label_fr": "Sisteron"
            },
            {
                "value": 26555,
                "label": "Volonne",
                "label_ar": "فولون",
                "label_fr": "Volonne"
            },
            {
                "value": 26556,
                "label": "Plessis-Barbuise",
                "label_ar": "بليسيس باربوز",
                "label_fr": "Plessis-Barbuise"
            },
            {
                "value": 26557,
                "label": "Eaux-Puiseaux",
                "label_ar": "Eaux-Puiseaux",
                "label_fr": "Eaux-Puiseaux"
            },
            {
                "value": 26558,
                "label": "Etourvy",
                "label_ar": "إتورفي",
                "label_fr": "Etourvy"
            },
            {
                "value": 26559,
                "label": "Marigny-le-Chatel",
                "label_ar": "ماريني لو شاتيل",
                "label_fr": "Marigny-le-Châtel"
            },
            {
                "value": 26560,
                "label": "Auxon",
                "label_ar": "اوكسون",
                "label_fr": "Auxon"
            },
            {
                "value": 26561,
                "label": "Ploubazlanec",
                "label_ar": "بلوبازلانيك",
                "label_fr": "Ploubazlanec"
            },
            {
                "value": 26562,
                "label": "Rospez",
                "label_ar": "روسبيز",
                "label_fr": "Rospez"
            },
            {
                "value": 26563,
                "label": "Poisy",
                "label_ar": "بويزي",
                "label_fr": "Poisy"
            },
            {
                "value": 26564,
                "label": "Aubevoye",
                "label_ar": "أوبيفوي",
                "label_fr": "Aubevoye"
            },
            {
                "value": 26565,
                "label": "Gasny",
                "label_ar": "جاسني",
                "label_fr": "Gasny"
            },
            {
                "value": 26566,
                "label": "Ecardenville-la-Campagne",
                "label_ar": "Ecardenville-la-Campagne",
                "label_fr": "Ecardenville-la-Campagne"
            },
            {
                "value": 26567,
                "label": "Saint-Etienne-sous-Bailleul",
                "label_ar": "سانت إتيان سو بيلول",
                "label_fr": "Saint-Étienne-sous-Bailleul"
            },
            {
                "value": 26568,
                "label": "Azerailles",
                "label_ar": "أزيرايلس",
                "label_fr": "Azerailles"
            },
            {
                "value": 26569,
                "label": "Uchaud",
                "label_ar": "أوشود",
                "label_fr": "Uchaud"
            },
            {
                "value": 26570,
                "label": "Nages-et-Solorgues",
                "label_ar": "Nages-et-Solorgues",
                "label_fr": "Nages-et-Solorgues"
            },
            {
                "value": 26571,
                "label": "Saint-Hilaire-de-Brethmas",
                "label_ar": "سان هيلير دي بريثماس",
                "label_fr": "Saint-Hilaire-de-Brethmas"
            },
            {
                "value": 26572,
                "label": "Bernis",
                "label_ar": "بيرنيس",
                "label_fr": "Bernis"
            },
            {
                "value": 26573,
                "label": "Leguevin",
                "label_ar": "ليجوفين",
                "label_fr": "Leguevin"
            },
            {
                "value": 26574,
                "label": "Feneyrols",
                "label_ar": "فينيرولز",
                "label_fr": "Feneyrols"
            },
            {
                "value": 26575,
                "label": "Magny-le-Desert",
                "label_ar": "ماجني لو ديزرت",
                "label_fr": "Magny-le-Désert"
            },
            {
                "value": 26576,
                "label": "La Tour-sur-Orb",
                "label_ar": "لا تور سور أورب",
                "label_fr": "La Tour-sur-Orb"
            },
            {
                "value": 26577,
                "label": "Sainte-Enimie",
                "label_ar": "سانت إنيمي",
                "label_fr": "Sainte-Enimie"
            },
            {
                "value": 26578,
                "label": "Antrain",
                "label_ar": "أنترين",
                "label_fr": "Antrain"
            },
            {
                "value": 26579,
                "label": "Tourouvre",
                "label_ar": "توروفر",
                "label_fr": "Tourouvre"
            },
            {
                "value": 26580,
                "label": "Domfront",
                "label_ar": "دومفرونت",
                "label_fr": "Domfront"
            },
            {
                "value": 26581,
                "label": "Bazoches-sur-Hoene",
                "label_ar": "بازوش سور هويني",
                "label_fr": "Bazoches-sur-Hoene"
            },
            {
                "value": 26582,
                "label": "Saint-Germain-du-Corbeis",
                "label_ar": "سان جيرمان دو كوربيس",
                "label_fr": "Saint-Germain-du-Corbeis"
            },
            {
                "value": 26583,
                "label": "Soligny-la-Trappe",
                "label_ar": "Soligny-la-Trappe",
                "label_fr": "Soligny-la-Trappe"
            },
            {
                "value": 26584,
                "label": "Couterne",
                "label_ar": "Couterne",
                "label_fr": "Couterne"
            },
            {
                "value": 26585,
                "label": "Frencq",
                "label_ar": "فرينك",
                "label_fr": "Frencq"
            },
            {
                "value": 26586,
                "label": "Marquise",
                "label_ar": "ماركيز",
                "label_fr": "Marquise"
            },
            {
                "value": 26587,
                "label": "Brebieres",
                "label_ar": "بريبيرس",
                "label_fr": "Brébières"
            },
            {
                "value": 26588,
                "label": "Coulomby",
                "label_ar": "كولومبي",
                "label_fr": "Coulomby"
            },
            {
                "value": 26589,
                "label": "Merlimont",
                "label_ar": "ميرليمونت",
                "label_fr": "Merlimont"
            },
            {
                "value": 26590,
                "label": "Landrethun-le-Nord",
                "label_ar": "لاندريثون لو نور",
                "label_fr": "Landrethun-le-Nord"
            },
            {
                "value": 26591,
                "label": "Langueux",
                "label_ar": "لانجيو",
                "label_fr": "Langueux"
            },
            {
                "value": 26592,
                "label": "Anzy-le-Duc",
                "label_ar": "أنزي لو دوك",
                "label_fr": "Anzy-le-Duc"
            },
            {
                "value": 26593,
                "label": "Epinac",
                "label_ar": "إبيناك",
                "label_fr": "Epinac"
            },
            {
                "value": 26594,
                "label": "Palinges",
                "label_ar": "الشحوب",
                "label_fr": "Palinges"
            },
            {
                "value": 26595,
                "label": "Chenay-le-Chatel",
                "label_ar": "تشيناي لو شاتيل",
                "label_fr": "Chenay-le-Châtel"
            },
            {
                "value": 26596,
                "label": "Autun",
                "label_ar": "تشغيل تلقائي",
                "label_fr": "Autun"
            },
            {
                "value": 26597,
                "label": "Buxy",
                "label_ar": "ممتلئ الجسم",
                "label_fr": "Buxy"
            },
            {
                "value": 26598,
                "label": "Savigny-en-Revermont",
                "label_ar": "Savigny-en-Revermont",
                "label_fr": "Savigny-en-Revermont"
            },
            {
                "value": 26599,
                "label": "Montfort",
                "label_ar": "مونتفورت",
                "label_fr": "Montfort"
            },
            {
                "value": 26600,
                "label": "Noyen-sur-Sarthe",
                "label_ar": "نوين سور سارث",
                "label_fr": "Noyen-sur-Sarthe"
            },
            {
                "value": 26601,
                "label": "Vaas",
                "label_ar": "فاس",
                "label_fr": "Vaas"
            },
            {
                "value": 26602,
                "label": "Coulans-sur-Gee",
                "label_ar": "كولان سور جي",
                "label_fr": "Coulans-sur-Gee"
            },
            {
                "value": 26603,
                "label": "Bourg-Saint-Maurice",
                "label_ar": "بورج سان موريس",
                "label_fr": "Bourg-Saint-Maurice"
            },
            {
                "value": 26604,
                "label": "La Motte-Chalancon",
                "label_ar": "لا موت شالانكون",
                "label_fr": "La Motte-Chalancon"
            },
            {
                "value": 26605,
                "label": "La Bathie",
                "label_ar": "لا باثي",
                "label_fr": "La Bathie"
            },
            {
                "value": 26606,
                "label": "Villard-sur-Doron",
                "label_ar": "فيلارد سور دورون",
                "label_fr": "Villard-sur-Doron"
            },
            {
                "value": 26607,
                "label": "Saint-Julien-des-Landes",
                "label_ar": "سان جوليان دي لاند",
                "label_fr": "Saint-Julien-des-Landes"
            },
            {
                "value": 26608,
                "label": "Saint-Georges-de-Pointindoux",
                "label_ar": "سان جورج دو بوينتندو",
                "label_fr": "Saint-Georges-de-Pointindoux"
            },
            {
                "value": 26609,
                "label": "La Verrie",
                "label_ar": "لا فيري",
                "label_fr": "La Verrie"
            },
            {
                "value": 26610,
                "label": "Curzay-sur-Vonne",
                "label_ar": "كرزاي سور فون",
                "label_fr": "Curzay-sur-Vonne"
            },
            {
                "value": 26611,
                "label": "Dissay",
                "label_ar": "ديساي",
                "label_fr": "Dissay"
            },
            {
                "value": 26612,
                "label": "Civray",
                "label_ar": "سيفراي",
                "label_fr": "Civray"
            },
            {
                "value": 26613,
                "label": "Sommieres-du-Clain",
                "label_ar": "Sommieres-du-Clain",
                "label_fr": "Sommières-du-Clain"
            },
            {
                "value": 26614,
                "label": "Cisse",
                "label_ar": "سيسيه",
                "label_fr": "Cissé"
            },
            {
                "value": 26615,
                "label": "Fouronnes",
                "label_ar": "فورونيس",
                "label_fr": "Fouronnes"
            },
            {
                "value": 26616,
                "label": "Ancy-le-Franc",
                "label_ar": "أنسي لو فرانك",
                "label_fr": "Ancy-le-Franc"
            },
            {
                "value": 26617,
                "label": "Courson-les-Carrieres",
                "label_ar": "كورسون ليه كاريير",
                "label_fr": "Courson-les-Carrieres"
            },
            {
                "value": 26618,
                "label": "Ouanne",
                "label_ar": "Ouanne",
                "label_fr": "Ouanne"
            },
            {
                "value": 26619,
                "label": "Ligny-le-Chatel",
                "label_ar": "ليني لو شاتيل",
                "label_fr": "Ligny-le-Châtel"
            },
            {
                "value": 26620,
                "label": "Noyers",
                "label_ar": "نوييرز",
                "label_fr": "Noyers"
            },
            {
                "value": 26621,
                "label": "Esson",
                "label_ar": "إيسون",
                "label_fr": "Esson"
            },
            {
                "value": 26622,
                "label": "Baye",
                "label_ar": "باي",
                "label_fr": "Baye"
            },
            {
                "value": 26623,
                "label": "Cordes-sur-Ciel",
                "label_ar": "كوردس سور سيل",
                "label_fr": "Cordes-sur-Ciel"
            },
            {
                "value": 26624,
                "label": "Boissezon",
                "label_ar": "Boissezon",
                "label_fr": "Boissezon"
            },
            {
                "value": 26625,
                "label": "Moulin-Mage",
                "label_ar": "مولان ماج",
                "label_fr": "Moulin-Mage"
            },
            {
                "value": 26626,
                "label": "Blan",
                "label_ar": "بلان",
                "label_fr": "Blan"
            },
            {
                "value": 26627,
                "label": "Briatexte",
                "label_ar": "برياتيكست",
                "label_fr": "Briatexte"
            },
            {
                "value": 26628,
                "label": "Trouillas",
                "label_ar": "ترويلا",
                "label_fr": "Trouillas"
            },
            {
                "value": 26629,
                "label": "Limours",
                "label_ar": "ليمور",
                "label_fr": "Limours"
            },
            {
                "value": 26630,
                "label": "Chaumes-en-Brie",
                "label_ar": "تشوميس أون بري",
                "label_fr": "Chaumes-en-Brie"
            },
            {
                "value": 26631,
                "label": "Romilly-sur-Seine",
                "label_ar": "روميلي سور سين",
                "label_fr": "Romilly-sur-Seine"
            },
            {
                "value": 26632,
                "label": "Roissy-en-France",
                "label_ar": "رواسي أون فرانس",
                "label_fr": "Roissy-en-France"
            },
            {
                "value": 26633,
                "label": "Boissy-le-Bois",
                "label_ar": "Boissy-le-Bois",
                "label_fr": "Boissy-le-Bois"
            },
            {
                "value": 26634,
                "label": "Marolles-en-Brie",
                "label_ar": "مارولس أون بري",
                "label_fr": "Marolles-en-Brie"
            },
            {
                "value": 26635,
                "label": "Pecqueuse",
                "label_ar": "بيكيوز",
                "label_fr": "Pecqueuse"
            },
            {
                "value": 26636,
                "label": "Toucy",
                "label_ar": "تويسي",
                "label_fr": "Toucy"
            },
            {
                "value": 26637,
                "label": "Six-Fours-les-Plages",
                "label_ar": "ستة اربع ليه بلاج",
                "label_fr": "Six-Fours-les-Plages"
            },
            {
                "value": 26638,
                "label": "Ollioules",
                "label_ar": "أوليول",
                "label_fr": "Ollioules"
            },
            {
                "value": 26639,
                "label": "Le Revest-les-Eaux",
                "label_ar": "لو Revest-les-Eaux",
                "label_fr": "Le Revest-les-Eaux"
            },
            {
                "value": 26640,
                "label": "Gassin",
                "label_ar": "جاسين",
                "label_fr": "Gassin"
            },
            {
                "value": 26641,
                "label": "Magny-les-Hameaux",
                "label_ar": "ماجني ليه هامو",
                "label_fr": "Magny-les-Hameaux"
            },
            {
                "value": 26642,
                "label": "Magny-en-Vexin",
                "label_ar": "Magny-en-Vexin",
                "label_fr": "Magny-en-Vexin"
            },
            {
                "value": 26643,
                "label": "Blanzat",
                "label_ar": "بلانزات",
                "label_fr": "Blanzat"
            },
            {
                "value": 26644,
                "label": "Boissy-l'Aillerie",
                "label_ar": "Boissy-l'Aillerie",
                "label_fr": "Boissy-l'Aillerie"
            },
            {
                "value": 26645,
                "label": "Vigny",
                "label_ar": "زاهي",
                "label_fr": "Vigny"
            },
            {
                "value": 26646,
                "label": "Contamine-sur-Arve",
                "label_ar": "كونتامين سور أرف",
                "label_fr": "Contamine-sur-Arve"
            },
            {
                "value": 26647,
                "label": "Saint-Germain-les-Paroisses",
                "label_ar": "سان جيرمان ليه باروا",
                "label_fr": "Saint-Germain-les-Paroisses"
            },
            {
                "value": 26648,
                "label": "Chanoz-Chatenay",
                "label_ar": "تشانوز شاتيناي",
                "label_fr": "Chanoz-Chatenay"
            },
            {
                "value": 26649,
                "label": "Aramon",
                "label_ar": "أرامون",
                "label_fr": "Aramon"
            },
            {
                "value": 26650,
                "label": "L'Herbergement",
                "label_ar": "L'Herbergement",
                "label_fr": "L'Herbergement"
            },
            {
                "value": 26651,
                "label": "Noyers",
                "label_ar": "نوييرز",
                "label_fr": "Noyers"
            },
            {
                "value": 26652,
                "label": "Valensole",
                "label_ar": "فالينسول",
                "label_fr": "Valensole"
            },
            {
                "value": 26653,
                "label": "Saint-Astier",
                "label_ar": "سان استر",
                "label_fr": "Saint-Astier"
            },
            {
                "value": 26654,
                "label": "Genis",
                "label_ar": "جينيس",
                "label_fr": "Genis"
            },
            {
                "value": 26655,
                "label": "Belvès",
                "label_ar": "بلفيس",
                "label_fr": "Belvès"
            },
            {
                "value": 26656,
                "label": "Champagne",
                "label_ar": "شامبانيا",
                "label_fr": "Champagne"
            },
            {
                "value": 26657,
                "label": "Hauteville-Lompnes",
                "label_ar": "أوتفيل لومبينيس",
                "label_fr": "Hauteville-Lompnes"
            },
            {
                "value": 26658,
                "label": "Beaupont",
                "label_ar": "بيوبونت",
                "label_fr": "Beaupont"
            },
            {
                "value": 26659,
                "label": "Neuville-sur-Ain",
                "label_ar": "نوفيل سور عين",
                "label_fr": "Neuville-sur-Ain"
            },
            {
                "value": 26660,
                "label": "Nattages",
                "label_ar": "الولادات",
                "label_fr": "Nattages"
            },
            {
                "value": 26661,
                "label": "Molandier",
                "label_ar": "مولاندير",
                "label_fr": "Molandier"
            },
            {
                "value": 26662,
                "label": "Vinassan",
                "label_ar": "فيناسان",
                "label_fr": "Vinassan"
            },
            {
                "value": 26663,
                "label": "Donzere",
                "label_ar": "دونزيري",
                "label_fr": "Donzere"
            },
            {
                "value": 26664,
                "label": "Barjac",
                "label_ar": "برجاك",
                "label_fr": "Barjac"
            },
            {
                "value": 26665,
                "label": "Le Pre-d'Auge",
                "label_ar": "لو بري دوج",
                "label_fr": "Le Pré-d'Auge"
            },
            {
                "value": 26666,
                "label": "Demouville",
                "label_ar": "ديموفيل",
                "label_fr": "Demouville"
            },
            {
                "value": 26667,
                "label": "Ouilly-le-Vicomte",
                "label_ar": "Ouilly-le-Vicomte",
                "label_fr": "Ouilly-le-Vicomte"
            },
            {
                "value": 26668,
                "label": "Ellon",
                "label_ar": "إيلون",
                "label_fr": "Ellon"
            },
            {
                "value": 26669,
                "label": "Mezidon-Canon",
                "label_ar": "Mezidon-كانون",
                "label_fr": "Mezidon-Canon"
            },
            {
                "value": 26670,
                "label": "Coupesarte",
                "label_ar": "كوبيسارت",
                "label_fr": "Coupesarte"
            },
            {
                "value": 26671,
                "label": "Heurtevent",
                "label_ar": "هيورتيفينت",
                "label_fr": "Heurtevent"
            },
            {
                "value": 26672,
                "label": "Saint-Vaast-en-Auge",
                "label_ar": "سان فاست أون أوجي",
                "label_fr": "Saint-Vaast-en-Auge"
            },
            {
                "value": 26673,
                "label": "Bonneville-la-Louvet",
                "label_ar": "Bonneville-la-Louvet",
                "label_fr": "Bonneville-la-Louvet"
            },
            {
                "value": 26674,
                "label": "Arzano",
                "label_ar": "أرزانو",
                "label_fr": "Arzano"
            },
            {
                "value": 26675,
                "label": "Scrignac",
                "label_ar": "سكرينياك",
                "label_fr": "Scrignac"
            },
            {
                "value": 26676,
                "label": "Delle",
                "label_ar": "ديلي",
                "label_fr": "Delle"
            },
            {
                "value": 26677,
                "label": "Vers-Pont-du-Gard",
                "label_ar": "Vers-Pont-du-Gard",
                "label_fr": "Vers-Pont-du-Gard"
            },
            {
                "value": 26678,
                "label": "Lesparre-Médoc",
                "label_ar": "ليسبار ميدوك",
                "label_fr": "Lesparre-Médoc"
            },
            {
                "value": 26679,
                "label": "Saint-Seurin-de-Cadourne",
                "label_ar": "سان سورين دي كادورن",
                "label_fr": "Saint-Seurin-de-Cadourne"
            },
            {
                "value": 26680,
                "label": "Targon",
                "label_ar": "تارجون",
                "label_fr": "Targon"
            },
            {
                "value": 26681,
                "label": "Pauillac Haut",
                "label_ar": "باويلاك أوت",
                "label_fr": "Pauillac Haut"
            },
            {
                "value": 26682,
                "label": "La Reole",
                "label_ar": "لا ريول",
                "label_fr": "La Reole"
            },
            {
                "value": 26683,
                "label": "La Garde",
                "label_ar": "لا جارد",
                "label_fr": "La Garde"
            },
            {
                "value": 26684,
                "label": "Cruas",
                "label_ar": "كرواس",
                "label_fr": "Cruas"
            },
            {
                "value": 26685,
                "label": "Ouzouer-le-Marche",
                "label_ar": "Ouzouer-le-Marche",
                "label_fr": "Ouzouer-le-Marche"
            },
            {
                "value": 26686,
                "label": "Selles-sur-Cher",
                "label_ar": "Selles-sur-Cher",
                "label_fr": "Selles-sur-Cher"
            },
            {
                "value": 26687,
                "label": "Limogne-en-Quercy",
                "label_ar": "ليموج أون كيرسي",
                "label_fr": "Limogne-en-Quercy"
            },
            {
                "value": 26688,
                "label": "Assier",
                "label_ar": "عسير",
                "label_fr": "Assier"
            },
            {
                "value": 26689,
                "label": "Geyssans",
                "label_ar": "جيسانس",
                "label_fr": "Geyssans"
            },
            {
                "value": 26690,
                "label": "Rohan",
                "label_ar": "روهان",
                "label_fr": "Rohan"
            },
            {
                "value": 26691,
                "label": "Sarzeau",
                "label_ar": "سارزو",
                "label_fr": "Sarzeau"
            },
            {
                "value": 26692,
                "label": "Pluherlin",
                "label_ar": "بلوهرلين",
                "label_fr": "Pluherlin"
            },
            {
                "value": 26693,
                "label": "Rety",
                "label_ar": "ريتي",
                "label_fr": "Rety"
            },
            {
                "value": 26694,
                "label": "Flechin",
                "label_ar": "فليشين",
                "label_fr": "Flechin"
            },
            {
                "value": 26695,
                "label": "Ponte-Leccia",
                "label_ar": "بونتي ليتشيا",
                "label_fr": "Ponte-Leccia"
            },
            {
                "value": 26696,
                "label": "Maintenon",
                "label_ar": "صيانة على",
                "label_fr": "Maintenon"
            },
            {
                "value": 26697,
                "label": "Saint-Valery-en-Caux",
                "label_ar": "سان فاليري أون كو",
                "label_fr": "Saint-Valery-en-Caux"
            },
            {
                "value": 26698,
                "label": "Colleville-Montgomery",
                "label_ar": "كوليفيل مونتغمري",
                "label_fr": "Colleville-Montgomery"
            },
            {
                "value": 26699,
                "label": "Thuit-Hebert",
                "label_ar": "ثويت هيبرت",
                "label_fr": "Thuit-Hébert"
            },
            {
                "value": 26700,
                "label": "Boos",
                "label_ar": "بوو",
                "label_fr": "Boos"
            },
            {
                "value": 26701,
                "label": "Bolbec",
                "label_ar": "بولبيك",
                "label_fr": "Bolbec"
            },
            {
                "value": 26702,
                "label": "Vacognes-Neuilly",
                "label_ar": "فاكوجنيس نويي",
                "label_fr": "Vacognes-Neuilly"
            },
            {
                "value": 26703,
                "label": "Fere-en-Tardenois",
                "label_ar": "Fere-en-Tardenois",
                "label_fr": "Fère-en-Tardenois"
            },
            {
                "value": 26704,
                "label": "Arcy-Sainte-Restitue",
                "label_ar": "أرسي سانت ريستيتو",
                "label_fr": "Arcy-Sainte-Restitue"
            },
            {
                "value": 26705,
                "label": "Braine",
                "label_ar": "العقل",
                "label_fr": "Braine"
            },
            {
                "value": 26706,
                "label": "Vervins",
                "label_ar": "فيرفينز",
                "label_fr": "Vervins"
            },
            {
                "value": 26707,
                "label": "Oulchy-le-Chateau",
                "label_ar": "أولشي لو شاتو",
                "label_fr": "Oulchy-le-Château"
            },
            {
                "value": 26708,
                "label": "Quiberville",
                "label_ar": "كويبرفيل",
                "label_fr": "Quiberville"
            },
            {
                "value": 26709,
                "label": "Sainte-Agathe-d'Aliermont",
                "label_ar": "سانت أجاث داليرمونت",
                "label_fr": "Sainte-Agathe-d'Aliermont"
            },
            {
                "value": 26710,
                "label": "Saint-Nicolas-d'Aliermont",
                "label_ar": "سان نيكولا داليرمونت",
                "label_fr": "Saint-Nicolas-d'Aliermont"
            },
            {
                "value": 26711,
                "label": "Gueures",
                "label_ar": "جويرس",
                "label_fr": "Gueures"
            },
            {
                "value": 26712,
                "label": "Petreto-Bicchisano",
                "label_ar": "بيتريتو بيكيسانو",
                "label_fr": "Petreto-Bicchisano"
            },
            {
                "value": 26713,
                "label": "Sari-d'Orcino",
                "label_ar": "ساري دورتشينو",
                "label_fr": "Sari-d'Orcino"
            },
            {
                "value": 26714,
                "label": "Porto-Vecchio",
                "label_ar": "بورتو فيكيو",
                "label_fr": "Porto-Vecchio"
            },
            {
                "value": 26715,
                "label": "Prigonrieux",
                "label_ar": "بريجونريو",
                "label_fr": "Prigonrieux"
            },
            {
                "value": 26716,
                "label": "Montagne",
                "label_ar": "مونتاني",
                "label_fr": "Montagne"
            },
            {
                "value": 26717,
                "label": "Cazaux",
                "label_ar": "كازاوكس",
                "label_fr": "Cazaux"
            },
            {
                "value": 26718,
                "label": "Loudun",
                "label_ar": "لودون",
                "label_fr": "Loudun"
            },
            {
                "value": 26719,
                "label": "Linars",
                "label_ar": "لينار",
                "label_fr": "Linars"
            },
            {
                "value": 26720,
                "label": "Pineuilh",
                "label_ar": "بينويل",
                "label_fr": "Pineuilh"
            },
            {
                "value": 26721,
                "label": "Couze-et-Saint-Front",
                "label_ar": "Couze-et-Saint-Front",
                "label_fr": "Couze-et-Saint-Front"
            },
            {
                "value": 26722,
                "label": "Montpon-Menesterol",
                "label_ar": "مونتبون مينستيرول",
                "label_fr": "Montpon-Ménestérol"
            },
            {
                "value": 26723,
                "label": "Port-Joinville",
                "label_ar": "بورت جوينفيل",
                "label_fr": "Port-Joinville"
            },
            {
                "value": 26724,
                "label": "Sainte-Hermine",
                "label_ar": "سانت هيرمين",
                "label_fr": "Sainte-Hermine"
            },
            {
                "value": 26725,
                "label": "Les Pineaux",
                "label_ar": "ليه بينو",
                "label_fr": "Les Pineaux"
            },
            {
                "value": 26726,
                "label": "La Reorthe",
                "label_ar": "لا ريورث",
                "label_fr": "La Reorthe"
            },
            {
                "value": 26727,
                "label": "Vignot",
                "label_ar": "فيجنوت",
                "label_fr": "Vignot"
            },
            {
                "value": 26728,
                "label": "Eyrans",
                "label_ar": "إيرانس",
                "label_fr": "Eyrans"
            },
            {
                "value": 26729,
                "label": "Saint-Medard-de-Guizieres",
                "label_ar": "Saint-Medard-de-Guizieres",
                "label_fr": "Saint-Médard-de-Guizières"
            },
            {
                "value": 26730,
                "label": "Saint-Selve",
                "label_ar": "سان سيلف",
                "label_fr": "Saint-Selve"
            },
            {
                "value": 26731,
                "label": "Neuville-de-Poitou",
                "label_ar": "نيوفيل دي بواتو",
                "label_fr": "Neuville-de-Poitou"
            },
            {
                "value": 26732,
                "label": "Nersac",
                "label_ar": "نيرساك",
                "label_fr": "Nersac"
            },
            {
                "value": 26733,
                "label": "Cordelle",
                "label_ar": "كورديل",
                "label_fr": "Cordelle"
            },
            {
                "value": 26734,
                "label": "Cremeaux",
                "label_ar": "كريمو",
                "label_fr": "Crémeaux"
            },
            {
                "value": 26735,
                "label": "Machezal",
                "label_ar": "مشيزال",
                "label_fr": "Machezal"
            },
            {
                "value": 26736,
                "label": "Bourbon-Lancy",
                "label_ar": "بوربون لانسي",
                "label_fr": "Bourbon-Lancy"
            },
            {
                "value": 26737,
                "label": "Neuilly-le-Real",
                "label_ar": "نويي لو ريال",
                "label_fr": "Neuilly-le-Real"
            },
            {
                "value": 26738,
                "label": "Dompierre-sur-Besbre",
                "label_ar": "دومبيير سور بيسبر",
                "label_fr": "Dompierre-sur-Besbre"
            },
            {
                "value": 26739,
                "label": "Les Avenieres",
                "label_ar": "ليه أفينييه",
                "label_fr": "Les Avenières"
            },
            {
                "value": 26740,
                "label": "Bedarrides",
                "label_ar": "بداريدس",
                "label_fr": "Bedarrides"
            },
            {
                "value": 26741,
                "label": "Varces-Allieres-et-Risset",
                "label_ar": "Varces-Allieres-et-Risset",
                "label_fr": "Varces-Allières-et-Risset"
            },
            {
                "value": 26742,
                "label": "Villelaure",
                "label_ar": "فيليور",
                "label_fr": "Villelaure"
            },
            {
                "value": 26743,
                "label": "Cornillon-en-Trieves",
                "label_ar": "كورنيلون أون تريفيس",
                "label_fr": "Cornillon-en-Trieves"
            },
            {
                "value": 26744,
                "label": "Beaulieu-sur-Mer",
                "label_ar": "بوليو سور مير",
                "label_fr": "Beaulieu-sur-Mer"
            },
            {
                "value": 26745,
                "label": "Vauban",
                "label_ar": "فوبان",
                "label_fr": "Vauban"
            },
            {
                "value": 26746,
                "label": "Fontain",
                "label_ar": "فونتين",
                "label_fr": "Fontain"
            },
            {
                "value": 26747,
                "label": "Varangeville",
                "label_ar": "فارانجفيل",
                "label_fr": "Varangeville"
            },
            {
                "value": 26748,
                "label": "La Force",
                "label_ar": "لا فورس",
                "label_fr": "La Force"
            },
            {
                "value": 26749,
                "label": "Crosne",
                "label_ar": "كروسن",
                "label_fr": "Crosne"
            },
            {
                "value": 26750,
                "label": "Lathuile",
                "label_ar": "لاثويل",
                "label_fr": "Lathuile"
            },
            {
                "value": 26751,
                "label": "Vagney",
                "label_ar": "فاجني",
                "label_fr": "Vagney"
            },
            {
                "value": 26752,
                "label": "La Roquette-sur-Siagne",
                "label_ar": "La Roquette-sur-Siagne",
                "label_fr": "La Roquette-sur-Siagne"
            },
            {
                "value": 26753,
                "label": "Valberg",
                "label_ar": "فالبرج",
                "label_fr": "Valberg"
            },
            {
                "value": 26754,
                "label": "Lure",
                "label_ar": "إغراء، شرك، طعم",
                "label_fr": "Leurre"
            },
            {
                "value": 26755,
                "label": "Saint-Jorioz",
                "label_ar": "سان جوريوز",
                "label_fr": "Saint-Jorioz"
            },
            {
                "value": 26756,
                "label": "Notre-Dame-de-Gravenchon",
                "label_ar": "نوتردام دي جرافينشون",
                "label_fr": "Notre-Dame-de-Gravenchon"
            },
            {
                "value": 26757,
                "label": "Baguer-Pican",
                "label_ar": "باغير بيكان",
                "label_fr": "Baguer-Pican"
            },
            {
                "value": 26758,
                "label": "Plougoulm",
                "label_ar": "بلوغولم",
                "label_fr": "Plougoulm"
            },
            {
                "value": 26759,
                "label": "Parne-sur-Roc",
                "label_ar": "بارن سور روك",
                "label_fr": "Parne-sur-Roc"
            },
            {
                "value": 26760,
                "label": "Precigne",
                "label_ar": "بريسين",
                "label_fr": "Précigne"
            },
            {
                "value": 26761,
                "label": "Andon",
                "label_ar": "و على",
                "label_fr": "Et sur"
            },
            {
                "value": 26762,
                "label": "Saint-Martin-du-Var",
                "label_ar": "سان مارتن دو فار",
                "label_fr": "Saint-Martin-du-Var"
            },
            {
                "value": 26763,
                "label": "Gruchet-le-Valasse",
                "label_ar": "Gruchet-le-Valasse",
                "label_fr": "Gruchet-le-Valasse"
            },
            {
                "value": 26764,
                "label": "Vittel",
                "label_ar": "فيتيل",
                "label_fr": "Vittel"
            },
            {
                "value": 26765,
                "label": "Saint-Cergues",
                "label_ar": "سان سيرج",
                "label_fr": "Saint-Cergues"
            },
            {
                "value": 26766,
                "label": "Basse-sur-le-Rupt",
                "label_ar": "باس سور لو روبت",
                "label_fr": "Basse-sur-le-Rupt"
            },
            {
                "value": 26767,
                "label": "Magland",
                "label_ar": "ماجلاند",
                "label_fr": "Magland"
            },
            {
                "value": 26768,
                "label": "Le Petit-Quevilly",
                "label_ar": "لو بيتيت كويفيلي",
                "label_fr": "Le Petit-Quevilly"
            },
            {
                "value": 26769,
                "label": "Haverskerque",
                "label_ar": "هافرسكيرك",
                "label_fr": "Haverskerque"
            },
            {
                "value": 26770,
                "label": "Saint-Antoine-la-Foret",
                "label_ar": "سان أنطوان لا فوريه",
                "label_fr": "Saint-Antoine-la-Foret"
            },
            {
                "value": 26771,
                "label": "Saint-Julien-en-Genevois",
                "label_ar": "سان جوليان إن جينيفوا",
                "label_fr": "Saint-Julien-en-Genevois"
            },
            {
                "value": 26772,
                "label": "Beauzelle",
                "label_ar": "بوزيل",
                "label_fr": "Beauzelle"
            },
            {
                "value": 26773,
                "label": "Rozoy-Bellevalle",
                "label_ar": "روزوي بلفالي",
                "label_fr": "Rozoy-Bellevalle"
            },
            {
                "value": 26774,
                "label": "La Couarde-sur-Mer",
                "label_ar": "لا كواردي سور مير",
                "label_fr": "La Couarde-sur-Mer"
            },
            {
                "value": 26775,
                "label": "Reignac-sur-Indre",
                "label_ar": "Reignac-sur-Indre",
                "label_fr": "Reignac-sur-Indre"
            },
            {
                "value": 26776,
                "label": "Audruicq",
                "label_ar": "أودرويتش",
                "label_fr": "Audruicq"
            },
            {
                "value": 26777,
                "label": "La Ferte-Saint-Samson",
                "label_ar": "لا فيرت سانت سامسون",
                "label_fr": "La Ferté-Saint-Samson"
            },
            {
                "value": 26778,
                "label": "Sainte-Marguerite",
                "label_ar": "سانت مارغريت",
                "label_fr": "Sainte-Marguerite"
            },
            {
                "value": 26779,
                "label": "Civrieux",
                "label_ar": "سيفرو",
                "label_fr": "Civrieux"
            },
            {
                "value": 26780,
                "label": "Meximieux",
                "label_ar": "مكسيميو",
                "label_fr": "Meximieux"
            },
            {
                "value": 26781,
                "label": "Odomez",
                "label_ar": "أودوميز",
                "label_fr": "Odomez"
            },
            {
                "value": 26782,
                "label": "Honfleur",
                "label_ar": "Honfleur",
                "label_fr": "Honfleur"
            },
            {
                "value": 26783,
                "label": "Genebrieres",
                "label_ar": "الجينات",
                "label_fr": "Genebrières"
            },
            {
                "value": 26784,
                "label": "Saint-Orens-de-Gameville",
                "label_ar": "سان أورينز دي جامفيل",
                "label_fr": "Saint-Orens-de-Gameville"
            },
            {
                "value": 26785,
                "label": "Eaunes",
                "label_ar": "ايونز",
                "label_fr": "Eaunes"
            },
            {
                "value": 26786,
                "label": "Puygouzon",
                "label_ar": "بويجوزون",
                "label_fr": "Puygouzon"
            },
            {
                "value": 26787,
                "label": "Villemur-sur-Tarn",
                "label_ar": "فيلمور سور تارن",
                "label_fr": "Villemur-sur-Tarn"
            },
            {
                "value": 26788,
                "label": "Pezilla-la-Riviere",
                "label_ar": "بيزيلا لا ريفيير",
                "label_fr": "Pezilla-la-Rivière"
            },
            {
                "value": 26789,
                "label": "Lauzerville",
                "label_ar": "لوزرفيل",
                "label_fr": "Lauzerville"
            },
            {
                "value": 26790,
                "label": "Damiatte",
                "label_ar": "دمياط",
                "label_fr": "Damiatte"
            },
            {
                "value": 26791,
                "label": "Castelginest",
                "label_ar": "كاستلجينست",
                "label_fr": "Castelginest"
            },
            {
                "value": 26792,
                "label": "Montaut",
                "label_ar": "مونتو",
                "label_fr": "Montaut"
            },
            {
                "value": 26793,
                "label": "Lapalme",
                "label_ar": "لابالم",
                "label_fr": "Lapalme"
            },
            {
                "value": 26794,
                "label": "Salies-du-Salat",
                "label_ar": "Salies-du-Salat",
                "label_fr": "Salies-du-Salat"
            },
            {
                "value": 26795,
                "label": "Martel",
                "label_ar": "مارتل",
                "label_fr": "Martel"
            },
            {
                "value": 26796,
                "label": "Mauzac",
                "label_ar": "موزاك",
                "label_fr": "Mauzac"
            },
            {
                "value": 26797,
                "label": "Cambounet-sur-le-Sor",
                "label_ar": "Cambounet-sur-le-Sor",
                "label_fr": "Cambounet-sur-le-Sor"
            },
            {
                "value": 26798,
                "label": "Seysses",
                "label_ar": "سيسيس",
                "label_fr": "Seysses"
            },
            {
                "value": 26799,
                "label": "Montbeton",
                "label_ar": "مونبيتون",
                "label_fr": "Montbeton"
            },
            {
                "value": 26800,
                "label": "Fenouillet",
                "label_ar": "فينويله",
                "label_fr": "Fenouillet"
            },
            {
                "value": 26801,
                "label": "Millas",
                "label_ar": "ميلاس",
                "label_fr": "Millas"
            },
            {
                "value": 26802,
                "label": "Saint-Etienne-de-Tulmont",
                "label_ar": "سانت إتيان دي تولمونت",
                "label_fr": "Saint-Étienne-de-Tulmont"
            },
            {
                "value": 26803,
                "label": "Tremeven",
                "label_ar": "تريميفين",
                "label_fr": "Tremeven"
            },
            {
                "value": 26804,
                "label": "Herlies",
                "label_ar": "هيرليس",
                "label_fr": "Herlies"
            },
            {
                "value": 26805,
                "label": "Terdeghem",
                "label_ar": "Terdeghem",
                "label_fr": "Terdeghem"
            },
            {
                "value": 26806,
                "label": "Evin-Malmaison",
                "label_ar": "إيفين مالميزون",
                "label_fr": "Evin-Malmaison"
            },
            {
                "value": 26807,
                "label": "Warluzel",
                "label_ar": "Warluzel",
                "label_fr": "Warluzel"
            },
            {
                "value": 26808,
                "label": "Juziers",
                "label_ar": "جوزيرس",
                "label_fr": "Juziers"
            },
            {
                "value": 26809,
                "label": "Bucy-le-Long",
                "label_ar": "بوسي لو لونج",
                "label_fr": "Bucy-le-Long"
            },
            {
                "value": 26810,
                "label": "Saint-Mande",
                "label_ar": "سان ماندي",
                "label_fr": "Saint-Mandé"
            },
            {
                "value": 26811,
                "label": "Montsoult",
                "label_ar": "مونتسولت",
                "label_fr": "Montsoult"
            },
            {
                "value": 26812,
                "label": "Charmes",
                "label_ar": "سحر",
                "label_fr": "Charmes"
            },
            {
                "value": 26813,
                "label": "Kerien",
                "label_ar": "كيرين",
                "label_fr": "Kerien"
            },
            {
                "value": 26814,
                "label": "Plouguerneau",
                "label_ar": "بلوجويرنو",
                "label_fr": "Plouguerneau"
            },
            {
                "value": 26815,
                "label": "Saint-Igneuc",
                "label_ar": "سان إجنيوك",
                "label_fr": "Saint-Igneuc"
            },
            {
                "value": 26816,
                "label": "Glos-sur-Risle",
                "label_ar": "جلوس سور ريسل",
                "label_fr": "Glos-sur-Risle"
            },
            {
                "value": 26817,
                "label": "Mondrainville",
                "label_ar": "موندرينفيل",
                "label_fr": "Mondrainville"
            },
            {
                "value": 26818,
                "label": "Pavilly",
                "label_ar": "بافيلي",
                "label_fr": "Pavilly"
            },
            {
                "value": 26819,
                "label": "Clinchamps-sur-Orne",
                "label_ar": "كلينشامب سور أورني",
                "label_fr": "Clinchamps-sur-Orne"
            },
            {
                "value": 26820,
                "label": "Fontaine-le-Dun",
                "label_ar": "فونتين لو دون",
                "label_fr": "Fontaine-le-Dun"
            },
            {
                "value": 26821,
                "label": "Desvres",
                "label_ar": "ديسفرس",
                "label_fr": "Desvres"
            },
            {
                "value": 26822,
                "label": "Thivars",
                "label_ar": "ثيفار",
                "label_fr": "Thivars"
            },
            {
                "value": 26823,
                "label": "Bras",
                "label_ar": "براس",
                "label_fr": "Bras"
            },
            {
                "value": 26824,
                "label": "Roquevaire",
                "label_ar": "روكيفير",
                "label_fr": "Roquevaire"
            },
            {
                "value": 26825,
                "label": "Saint-Mitre-les-Remparts",
                "label_ar": "سان ميتري ليه ريمبار",
                "label_fr": "Saint-Mitre-les-Remparts"
            },
            {
                "value": 26826,
                "label": "Saint-Andiol",
                "label_ar": "سانت أنديول",
                "label_fr": "Saint-Andiol"
            },
            {
                "value": 26827,
                "label": "Fours-Saint-Laurent",
                "label_ar": "فور سان لوران",
                "label_fr": "Fours-Saint-Laurent"
            },
            {
                "value": 26828,
                "label": "Saint-Andre-sur-Orne",
                "label_ar": "سان أندريه سور أورني",
                "label_fr": "Saint-André-sur-Orne"
            },
            {
                "value": 26829,
                "label": "Lusignan",
                "label_ar": "لوزينيان",
                "label_fr": "Lusignan"
            },
            {
                "value": 26830,
                "label": "Vayres",
                "label_ar": "فايرز",
                "label_fr": "Vayres"
            },
            {
                "value": 26831,
                "label": "Port-de-Bouc",
                "label_ar": "بورت دو بوك",
                "label_fr": "Port-de-Bouc"
            },
            {
                "value": 26832,
                "label": "Saint-Apollinaire",
                "label_ar": "سان أبولينير",
                "label_fr": "Saint-Apollinaire"
            },
            {
                "value": 26833,
                "label": "Saint-Jean-en-Royans",
                "label_ar": "سان جان أون رويان",
                "label_fr": "Saint-Jean-en-Royans"
            },
            {
                "value": 26834,
                "label": "Les Vans",
                "label_ar": "ليه فانس",
                "label_fr": "Les Vans"
            },
            {
                "value": 26835,
                "label": "Vernoux-en-Vivarais",
                "label_ar": "Vernoux-en-Vivarais",
                "label_fr": "Vernoux-en-Vivarais"
            },
            {
                "value": 26836,
                "label": "Saint-Lager-Bressac",
                "label_ar": "سان لاجر بريساك",
                "label_fr": "Saint-Lager-Bressac"
            },
            {
                "value": 26837,
                "label": "Saint-Gervais-sur-Roubion",
                "label_ar": "سان جيرفيه سور روبيون",
                "label_fr": "Saint-Gervais-sur-Roubion"
            },
            {
                "value": 26838,
                "label": "La Begue",
                "label_ar": "لا بيج",
                "label_fr": "La Begue"
            },
            {
                "value": 26839,
                "label": "Pont-de-Labeaume",
                "label_ar": "بونت دي لابيوم",
                "label_fr": "Pont-de-Labeaume"
            },
            {
                "value": 26840,
                "label": "Gervans",
                "label_ar": "جيرفان",
                "label_fr": "Gervans"
            },
            {
                "value": 26841,
                "label": "Beaumont-les-Valence",
                "label_ar": "بومونت ليه فالينس",
                "label_fr": "Beaumont-les-Valence"
            },
            {
                "value": 26842,
                "label": "Peyrins",
                "label_ar": "بيرينز",
                "label_fr": "Peyrins"
            },
            {
                "value": 26843,
                "label": "Puy-Saint-Martin",
                "label_ar": "بوي سانت مارتن",
                "label_fr": "Puy-Saint-Martin"
            },
            {
                "value": 26844,
                "label": "Chomerac",
                "label_ar": "تشوميراك",
                "label_fr": "Chomerac"
            },
            {
                "value": 26845,
                "label": "Serignan-du-Comtat",
                "label_ar": "سيرينان دو كومتات",
                "label_fr": "Sérignan-du-Comtat"
            },
            {
                "value": 26846,
                "label": "Roubion",
                "label_ar": "روبيون",
                "label_fr": "Roubion"
            },
            {
                "value": 26847,
                "label": "La Souche",
                "label_ar": "لا سوش",
                "label_fr": "La Souche"
            },
            {
                "value": 26848,
                "label": "Nyons",
                "label_ar": "النيون",
                "label_fr": "Nyons"
            },
            {
                "value": 26849,
                "label": "Tain-l'Hermitage",
                "label_ar": "Tain-l'Hermitage",
                "label_fr": "Tain-l'Hermitage"
            },
            {
                "value": 26850,
                "label": "Desaignes",
                "label_ar": "ديسين",
                "label_fr": "Desaignes"
            },
            {
                "value": 26851,
                "label": "Jaujac",
                "label_ar": "جوجاك",
                "label_fr": "Jaujac"
            },
            {
                "value": 26852,
                "label": "Saint-Pierreville",
                "label_ar": "سان بييرفيل",
                "label_fr": "Saint-Pierreville"
            },
            {
                "value": 26853,
                "label": "Vion",
                "label_ar": "فيون",
                "label_fr": "Vion"
            },
            {
                "value": 26854,
                "label": "Peyrus",
                "label_ar": "بيروس",
                "label_fr": "Peyrus"
            },
            {
                "value": 26855,
                "label": "Saint-Sorlin-en-Valloire",
                "label_ar": "سان سورلين أون فالوار",
                "label_fr": "Saint-Sorlin-en-Valloire"
            },
            {
                "value": 26856,
                "label": "Largentière",
                "label_ar": "لارجنتيير",
                "label_fr": "Largentière"
            },
            {
                "value": 26857,
                "label": "Alboussiere",
                "label_ar": "البوسيرى",
                "label_fr": "Alboussière"
            },
            {
                "value": 26858,
                "label": "Saint-Martin-de-Valamas",
                "label_ar": "سان مارتن دي فالاماس",
                "label_fr": "Saint-Martin-de-Valamas"
            },
            {
                "value": 26859,
                "label": "Lussas",
                "label_ar": "لوساس",
                "label_fr": "Lussas"
            },
            {
                "value": 26860,
                "label": "Saint-Barthelemy-de-Vals",
                "label_ar": "سان بارتيليمي دي فالس",
                "label_fr": "Saint-Barthélemy-de-Vals"
            },
            {
                "value": 26861,
                "label": "Banne",
                "label_ar": "باني",
                "label_fr": "Banne"
            },
            {
                "value": 26862,
                "label": "Vignes",
                "label_ar": "تزين",
                "label_fr": "Vignes"
            },
            {
                "value": 26863,
                "label": "Marsanne",
                "label_ar": "مارسان",
                "label_fr": "Marsanne"
            },
            {
                "value": 26864,
                "label": "Charnas",
                "label_ar": "تشارناس",
                "label_fr": "Charnas"
            },
            {
                "value": 26865,
                "label": "Ruoms",
                "label_ar": "رومز",
                "label_fr": "Ruoms"
            },
            {
                "value": 26866,
                "label": "La Baume-de-Transit",
                "label_ar": "لا بوم دو ترانزيت",
                "label_fr": "La Baume-de-Transit"
            },
            {
                "value": 26867,
                "label": "Colombier-le-Cardinal",
                "label_ar": "كولومبييه لو كاردينال",
                "label_fr": "Colombier-le-Cardinal"
            },
            {
                "value": 26868,
                "label": "Alixan",
                "label_ar": "أليكسان",
                "label_fr": "Alixan"
            },
            {
                "value": 26869,
                "label": "Anneyron",
                "label_ar": "أنيرون",
                "label_fr": "Anneyron"
            },
            {
                "value": 26870,
                "label": "Saint-Trojan-les-Bains",
                "label_ar": "سان تروجان ليه با",
                "label_fr": "Saint-Trojan-les-Bains"
            },
            {
                "value": 26871,
                "label": "La Chaussaire",
                "label_ar": "لا شوسير",
                "label_fr": "La Chaussaire"
            },
            {
                "value": 26872,
                "label": "Dampierre-sur-Boutonne",
                "label_ar": "دامبير سور بوتون",
                "label_fr": "Dampierre-sur-Boutonne"
            },
            {
                "value": 26873,
                "label": "Fouras",
                "label_ar": "فراس",
                "label_fr": "Fouras"
            },
            {
                "value": 26874,
                "label": "Varrains",
                "label_ar": "Varrains",
                "label_fr": "Varrains"
            },
            {
                "value": 26875,
                "label": "Vihiers",
                "label_ar": "فيهيرز",
                "label_fr": "Vihiers"
            },
            {
                "value": 26876,
                "label": "la Roca d'Albera",
                "label_ar": "لا روكا دالبيرا",
                "label_fr": "la Roca d'Albera"
            },
            {
                "value": 26877,
                "label": "Pontcharra-sur-Turdine",
                "label_ar": "بونتشارا سور توردين",
                "label_fr": "Pontcharra-sur-Turdine"
            },
            {
                "value": 26878,
                "label": "Laval-sur-Doulon",
                "label_ar": "لافال سور دولون",
                "label_fr": "Laval-sur-Doulon"
            },
            {
                "value": 26879,
                "label": "Chargey-les-Gray",
                "label_ar": "تشارجي ليه جراي",
                "label_fr": "Chargey-les-Gray"
            },
            {
                "value": 26880,
                "label": "Cluny",
                "label_ar": "كلوني",
                "label_fr": "Cluny"
            },
            {
                "value": 26881,
                "label": "Pouydesseaux",
                "label_ar": "بويديسو",
                "label_fr": "Pouydesseaux"
            },
            {
                "value": 26882,
                "label": "Lozinghem",
                "label_ar": "لوزينغهم",
                "label_fr": "Lozinghem"
            },
            {
                "value": 26883,
                "label": "Hardelot-Plage",
                "label_ar": "هاردلوت بلاج",
                "label_fr": "Hardelot-Plage"
            },
            {
                "value": 26884,
                "label": "Masny",
                "label_ar": "ماسني",
                "label_fr": "Masny"
            },
            {
                "value": 26885,
                "label": "Preseau",
                "label_ar": "بريسو",
                "label_fr": "Preseau"
            },
            {
                "value": 26886,
                "label": "La Chapelle",
                "label_ar": "لا شابيل",
                "label_fr": "La Chapelle"
            },
            {
                "value": 26887,
                "label": "Bourdenay",
                "label_ar": "بورديناي",
                "label_fr": "Bourdenay"
            },
            {
                "value": 26888,
                "label": "Saint-Lubin-des-Joncherets",
                "label_ar": "سان لوبين دي جونكيريتس",
                "label_fr": "Saint-Lubin-des-Joncherets"
            },
            {
                "value": 26889,
                "label": "Sorede",
                "label_ar": "سوريه",
                "label_fr": "Sorede"
            },
            {
                "value": 26890,
                "label": "Negrepelisse",
                "label_ar": "نيجريبليس",
                "label_fr": "Negrepelisse"
            },
            {
                "value": 26891,
                "label": "Grepiac",
                "label_ar": "جريباك",
                "label_fr": "Grepiac"
            },
            {
                "value": 26892,
                "label": "Brassac",
                "label_ar": "براساك",
                "label_fr": "Brassac"
            },
            {
                "value": 26893,
                "label": "Tarascon",
                "label_ar": "تاراسكون",
                "label_fr": "Tarascon"
            },
            {
                "value": 26894,
                "label": "Tourves",
                "label_ar": "تورفس",
                "label_fr": "Tourves"
            },
            {
                "value": 26895,
                "label": "Les Pins",
                "label_ar": "ليه بينس",
                "label_fr": "Les Pins"
            },
            {
                "value": 26896,
                "label": "Saint-Jean-de-Chevelu",
                "label_ar": "سان جان دي تشيفيلو",
                "label_fr": "Saint-Jean-de-Chevelu"
            },
            {
                "value": 26897,
                "label": "Digoin",
                "label_ar": "ديجوين",
                "label_fr": "Digoin"
            },
            {
                "value": 26898,
                "label": "Cerny",
                "label_ar": "سيرني",
                "label_fr": "Cerny"
            },
            {
                "value": 26899,
                "label": "Baillet-en-France",
                "label_ar": "بيليه أون فرانس",
                "label_fr": "Baillet-en-France"
            },
            {
                "value": 26900,
                "label": "Livarot",
                "label_ar": "ليفاروت",
                "label_fr": "Livarot"
            },
            {
                "value": 26901,
                "label": "Sebecourt",
                "label_ar": "سيبكورت",
                "label_fr": "Sebecourt"
            },
            {
                "value": 26902,
                "label": "Sail-sous-Couzan",
                "label_ar": "شراع-سوس-كوزان",
                "label_fr": "Voile-sous-Couzan"
            },
            {
                "value": 26903,
                "label": "Algrange",
                "label_ar": "ألغرانج",
                "label_fr": "Algrange"
            },
            {
                "value": 26904,
                "label": "Bournezeau",
                "label_ar": "بورنزو",
                "label_fr": "Bournezeau"
            },
            {
                "value": 26905,
                "label": "Hallines",
                "label_ar": "هالينيس",
                "label_fr": "Hallines"
            },
            {
                "value": 26906,
                "label": "Condette",
                "label_ar": "كونديت",
                "label_fr": "Condette"
            },
            {
                "value": 26907,
                "label": "Quesnoy-sur-Deule",
                "label_ar": "Quesnoy-sur-Deule",
                "label_fr": "Quesnoy-sur-Deule"
            },
            {
                "value": 26908,
                "label": "Laventie",
                "label_ar": "لافينتي",
                "label_fr": "Laventie"
            },
            {
                "value": 26909,
                "label": "Vulaines-sur-Seine",
                "label_ar": "فولينيس سور سين",
                "label_fr": "Vulaines-sur-Seine"
            },
            {
                "value": 26910,
                "label": "Presles-en-Brie",
                "label_ar": "بريسليس أون بري",
                "label_fr": "Presles-en-Brie"
            },
            {
                "value": 26911,
                "label": "Montville",
                "label_ar": "مونتفيل",
                "label_fr": "Montville"
            },
            {
                "value": 26912,
                "label": "Mers-les-Bains",
                "label_ar": "ميرس ليه با",
                "label_fr": "Mers-les-Bains"
            },
            {
                "value": 26913,
                "label": "Bosgouet",
                "label_ar": "Bosgouet",
                "label_fr": "Bosgouet"
            },
            {
                "value": 26914,
                "label": "Le Trait",
                "label_ar": "لو تريت",
                "label_fr": "Le Trait"
            },
            {
                "value": 26915,
                "label": "Rozoy-sur-Serre",
                "label_ar": "روزوي سور سيري",
                "label_fr": "Rozoy-sur-Serre"
            },
            {
                "value": 26916,
                "label": "Mondonville",
                "label_ar": "موندونفيل",
                "label_fr": "Mondonville"
            },
            {
                "value": 26917,
                "label": "Cussey-les-Forges",
                "label_ar": "كوزي ليه فورج",
                "label_fr": "Cussey-les-Forges"
            },
            {
                "value": 26918,
                "label": "L'Île-Rousse",
                "label_ar": "ليل روس",
                "label_fr": "L'Île-Rousse"
            },
            {
                "value": 26919,
                "label": "Force",
                "label_ar": "فرض",
                "label_fr": "Obliger"
            },
            {
                "value": 26920,
                "label": "Condé-sur-Huisne",
                "label_ar": "كوندي سور هويسن",
                "label_fr": "Condé-sur-Huisne"
            },
            {
                "value": 26921,
                "label": "Authie",
                "label_ar": "أوثي",
                "label_fr": "Authie"
            },
            {
                "value": 26922,
                "label": "Civaux",
                "label_ar": "سيفوكس",
                "label_fr": "Civaux"
            },
            {
                "value": 26923,
                "label": "Flayosc",
                "label_ar": "Flayosc",
                "label_fr": "Flayosc"
            },
            {
                "value": 26924,
                "label": "Fleurance",
                "label_ar": "فلورانس",
                "label_fr": "Fleurance"
            },
            {
                "value": 26925,
                "label": "Ayguesvives",
                "label_ar": "Ayguesvives",
                "label_fr": "Ayguesvives"
            },
            {
                "value": 26926,
                "label": "Le Barboux",
                "label_ar": "لو باربو",
                "label_fr": "Le Barboux"
            },
            {
                "value": 26927,
                "label": "Goux-les-Usiers",
                "label_ar": "Goux-les-Usiers",
                "label_fr": "Goux-les-Usiers"
            },
            {
                "value": 26928,
                "label": "Saint-Pourcain-sur-Sioule",
                "label_ar": "سان بوركين سور سيول",
                "label_fr": "Saint-Pourcain-sur-Sioule"
            },
            {
                "value": 26929,
                "label": "Prahecq",
                "label_ar": "براشك",
                "label_fr": "Prahecq"
            },
            {
                "value": 26930,
                "label": "Fumel",
                "label_ar": "فوميل",
                "label_fr": "Fumel"
            },
            {
                "value": 26931,
                "label": "Saint-Marcel-d'Ardeche",
                "label_ar": "سان مارسيل دارديش",
                "label_fr": "Saint-Marcel-d'Ardèche"
            },
            {
                "value": 26932,
                "label": "Malause",
                "label_ar": "سوء الحظ",
                "label_fr": "Malause"
            },
            {
                "value": 26933,
                "label": "Rousson",
                "label_ar": "روسون",
                "label_fr": "Rousson"
            },
            {
                "value": 26934,
                "label": "Vonnas",
                "label_ar": "فوناس",
                "label_fr": "Vonnas"
            },
            {
                "value": 26935,
                "label": "Poulx",
                "label_ar": "بولكس",
                "label_fr": "Poulx"
            },
            {
                "value": 26936,
                "label": "Sainte-Croix-en-Jarez",
                "label_ar": "Sainte-Croix-en-Jarez",
                "label_fr": "Sainte-Croix-en-Jarez"
            },
            {
                "value": 26937,
                "label": "Villers-Farlay",
                "label_ar": "فيليرز فارلاي",
                "label_fr": "Villers-Farlay"
            },
            {
                "value": 26938,
                "label": "Le Touvet",
                "label_ar": "لو توفيت",
                "label_fr": "Le Touvet"
            },
            {
                "value": 26939,
                "label": "Flumet",
                "label_ar": "فلوميت",
                "label_fr": "Flumet"
            },
            {
                "value": 26940,
                "label": "L'Albenc",
                "label_ar": "لابينك",
                "label_fr": "L'Albenc"
            },
            {
                "value": 26941,
                "label": "Gieres",
                "label_ar": "جيريس",
                "label_fr": "Gieres"
            },
            {
                "value": 26942,
                "label": "Morieres-les-Avignon",
                "label_ar": "مورييه ليه أفينيون",
                "label_fr": "Morières-les-Avignon"
            },
            {
                "value": 26943,
                "label": "Saint-Andre-Goule-d'Oie",
                "label_ar": "سان أندريه جول دو",
                "label_fr": "Saint-André-Goule-d'Oie"
            },
            {
                "value": 26944,
                "label": "La Gaubretiere",
                "label_ar": "لا غوبريتيير",
                "label_fr": "La Gaubretière"
            },
            {
                "value": 26945,
                "label": "Landes",
                "label_ar": "لانديس",
                "label_fr": "Landes"
            },
            {
                "value": 26946,
                "label": "Azerables",
                "label_ar": "أزيرابلز",
                "label_fr": "Azerables"
            },
            {
                "value": 26947,
                "label": "Saint-Privat",
                "label_ar": "سان بريفات",
                "label_fr": "Saint-Privat"
            },
            {
                "value": 26948,
                "label": "Jarnages",
                "label_ar": "الجرنجات",
                "label_fr": "Jarnages"
            },
            {
                "value": 26949,
                "label": "Grandsaigne",
                "label_ar": "جراندزيني",
                "label_fr": "Grandsaigne"
            },
            {
                "value": 26950,
                "label": "Pechbonnieu",
                "label_ar": "بيتشبونيو",
                "label_fr": "Pechbonnieu"
            },
            {
                "value": 26951,
                "label": "Montigny-les-Cormeilles",
                "label_ar": "مونتيني ليه كورميل",
                "label_fr": "Montigny-les-Cormeilles"
            },
            {
                "value": 26952,
                "label": "Orgeval",
                "label_ar": "Orgeval",
                "label_fr": "Orgeval"
            },
            {
                "value": 26953,
                "label": "Saint-Michel",
                "label_ar": "سان ميشيل",
                "label_fr": "Saint-Michel"
            },
            {
                "value": 26954,
                "label": "Sissonne",
                "label_ar": "سيسوني",
                "label_fr": "Sissonne"
            },
            {
                "value": 26955,
                "label": "Villers-Bocage",
                "label_ar": "فيليرز بوكاج",
                "label_fr": "Villers-Bocage"
            },
            {
                "value": 26956,
                "label": "Pont-du-Chateau",
                "label_ar": "بونت دو شاتو",
                "label_fr": "Pont-du-Château"
            },
            {
                "value": 26957,
                "label": "Savigny-les-Beaune",
                "label_ar": "سافيني ليه بون",
                "label_fr": "Savigny-les-Beaune"
            },
            {
                "value": 26958,
                "label": "Mareuil",
                "label_ar": "مريل",
                "label_fr": "Mareuil"
            },
            {
                "value": 26959,
                "label": "Cambes",
                "label_ar": "قمب",
                "label_fr": "Cambes"
            },
            {
                "value": 26960,
                "label": "Mimbaste",
                "label_ar": "ميمباستي",
                "label_fr": "Mimbaste"
            },
            {
                "value": 26961,
                "label": "Oiron",
                "label_ar": "Oiron",
                "label_fr": "Oiron"
            },
            {
                "value": 26962,
                "label": "Saran",
                "label_ar": "ساران",
                "label_fr": "Saran"
            },
            {
                "value": 26963,
                "label": "Chevannes",
                "label_ar": "شيفانيس",
                "label_fr": "Chevannes"
            },
            {
                "value": 26964,
                "label": "Le Thillot",
                "label_ar": "لو تيلوت",
                "label_fr": "Le Thillot"
            },
            {
                "value": 26965,
                "label": "Arx",
                "label_ar": "Arx",
                "label_fr": "Arx"
            },
            {
                "value": 26966,
                "label": "Le Tholonet",
                "label_ar": "لو ثولونيت",
                "label_fr": "Le Tholonet"
            },
            {
                "value": 26967,
                "label": "Durance",
                "label_ar": "ديورانس",
                "label_fr": "Durance"
            },
            {
                "value": 26968,
                "label": "Rognes",
                "label_ar": "روجنيس",
                "label_fr": "Rognes"
            },
            {
                "value": 26969,
                "label": "Lantilly",
                "label_ar": "لانتيلي",
                "label_fr": "Lantilly"
            },
            {
                "value": 26970,
                "label": "Varilhes",
                "label_ar": "الورقات",
                "label_fr": "Varilhes"
            },
            {
                "value": 26971,
                "label": "Mazeres-de-Neste",
                "label_ar": "Mazeres-de-Neste",
                "label_fr": "Mazères-de-Neste"
            },
            {
                "value": 26972,
                "label": "Ferrieres-sur-Ariege",
                "label_ar": "Ferrieres-sur-Ariege",
                "label_fr": "Ferrières-sur-Ariège"
            },
            {
                "value": 26973,
                "label": "Pradieres",
                "label_ar": "براديريس",
                "label_fr": "Pradières"
            },
            {
                "value": 26974,
                "label": "Le Mas",
                "label_ar": "لو ماس",
                "label_fr": "Le Mas"
            },
            {
                "value": 26975,
                "label": "Miglos",
                "label_ar": "ميغلوس",
                "label_fr": "Miglos"
            },
            {
                "value": 26976,
                "label": "Caumont",
                "label_ar": "كومونت",
                "label_fr": "Caumont"
            },
            {
                "value": 26977,
                "label": "Laroque-d'Olmes",
                "label_ar": "لاروك دولمس",
                "label_fr": "Laroque-d'Olmes"
            },
            {
                "value": 26978,
                "label": "Carrieres-sur-Seine",
                "label_ar": "كارييرز سور سين",
                "label_fr": "Carrieres-sur-Seine"
            },
            {
                "value": 26979,
                "label": "Nozay",
                "label_ar": "نوزاي",
                "label_fr": "Nozay"
            },
            {
                "value": 26980,
                "label": "Le Kremlin-Bicetre",
                "label_ar": "لو كرملين بيستر",
                "label_fr": "Le Kremlin-Bicêtre"
            },
            {
                "value": 26981,
                "label": "Mainvilliers",
                "label_ar": "مينفيليرس",
                "label_fr": "Mainvilliers"
            },
            {
                "value": 26982,
                "label": "Varages",
                "label_ar": "المتغيرات",
                "label_fr": "Varages"
            },
            {
                "value": 26983,
                "label": "Pourrieres",
                "label_ar": "يصب",
                "label_fr": "Pourrieres"
            },
            {
                "value": 26984,
                "label": "Arzal",
                "label_ar": "أرزال",
                "label_fr": "Arzal"
            },
            {
                "value": 26985,
                "label": "Beauvoir-de-Marc",
                "label_ar": "بوفوار دي مارك",
                "label_fr": "Beauvoir-de-Marc"
            },
            {
                "value": 26986,
                "label": "Camps-la-Source",
                "label_ar": "معسكرات لا سورس",
                "label_fr": "Camps-la-Source"
            },
            {
                "value": 26987,
                "label": "Canet-Plage",
                "label_ar": "كانيه بلاج",
                "label_fr": "Canet-Plage"
            },
            {
                "value": 26988,
                "label": "Saint-Florent-sur-Auzonnet",
                "label_ar": "سان فلوران سور أوزونيت",
                "label_fr": "Saint-Florent-sur-Auzonnet"
            },
            {
                "value": 26989,
                "label": "Dienville",
                "label_ar": "دينفيل",
                "label_fr": "Dienville"
            },
            {
                "value": 26990,
                "label": "Biermont",
                "label_ar": "بيرمونت",
                "label_fr": "Biermont"
            },
            {
                "value": 26991,
                "label": "Venables",
                "label_ar": "فينابلز",
                "label_fr": "Venables"
            },
            {
                "value": 26992,
                "label": "Forges-les-Eaux",
                "label_ar": "Forges-les-Eaux",
                "label_fr": "Forges-les-Eaux"
            },
            {
                "value": 26993,
                "label": "Goderville",
                "label_ar": "جودرفيل",
                "label_fr": "Goderville"
            },
            {
                "value": 26994,
                "label": "Les Milles",
                "label_ar": "ليه ميلز",
                "label_fr": "Les Milles"
            },
            {
                "value": 26995,
                "label": "Rians",
                "label_ar": "ريان",
                "label_fr": "Rians"
            },
            {
                "value": 26996,
                "label": "Ploumilliau",
                "label_ar": "بلوميليو",
                "label_fr": "Ploumilliau"
            },
            {
                "value": 26997,
                "label": "Nieuil-l'Espoir",
                "label_ar": "Nieuil-l'Espoir",
                "label_fr": "Nieuil-l'Espoir"
            },
            {
                "value": 26998,
                "label": "Rainfreville",
                "label_ar": "Rainfreville",
                "label_fr": "Rainfreville"
            },
            {
                "value": 26999,
                "label": "Landrecies",
                "label_ar": "لاندريسيز",
                "label_fr": "Landrecies"
            },
            {
                "value": 27000,
                "label": "Soorts-Hossegor",
                "label_ar": "Soorts-Hossegor",
                "label_fr": "Soorts-Hossegor"
            },
            {
                "value": 27001,
                "label": "La Landec",
                "label_ar": "لا لانديك",
                "label_fr": "La Landec"
            },
            {
                "value": 27001,
                "label": "Vieille-Eglise-en-Yvelines",
                "label_ar": "Vieille-Eglise-en-Yvelines",
                "label_fr": "Vieille-Eglise-en-Yvelines"
            },
            {
                "value": 27002,
                "label": "Steenbecque",
                "label_ar": "ستينبيك",
                "label_fr": "Steenbecque"
            },
            {
                "value": 27003,
                "label": "Henouville",
                "label_ar": "هينوفيل",
                "label_fr": "Henouville"
            },
            {
                "value": 27004,
                "label": "Croissanville",
                "label_ar": "كرويسانفيل",
                "label_fr": "Croissanville"
            },
            {
                "value": 27005,
                "label": "Conde-sur-Noireau",
                "label_ar": "كوندي سور نويرو",
                "label_fr": "Condé-sur-Noireau"
            },
            {
                "value": 27006,
                "label": "Saint-Malo-du-Bois",
                "label_ar": "سان مالو دو بوا",
                "label_fr": "Saint-Malo-du-Bois"
            },
            {
                "value": 27007,
                "label": "Saint-Rambert-en-Bugey",
                "label_ar": "سان رامبرت أون بوجي",
                "label_fr": "Saint-Rambert-en-Bugey"
            },
            {
                "value": 27008,
                "label": "Surville",
                "label_ar": "سورفيل",
                "label_fr": "Surville"
            },
            {
                "value": 27009,
                "label": "Merville-Franceville-Plage",
                "label_ar": "ميرفيل فرانسفيل بلاج",
                "label_fr": "Merville-Franceville-Plage"
            },
            {
                "value": 27010,
                "label": "Vic-le-Comte",
                "label_ar": "فيك لو كومت",
                "label_fr": "Vic-le-Comte"
            },
            {
                "value": 27011,
                "label": "Cires-les-Mello",
                "label_ar": "Cires-les-Mello",
                "label_fr": "Cires-les-Mello"
            },
            {
                "value": 27012,
                "label": "Jeandelaincourt",
                "label_ar": "جينديلينكورت",
                "label_fr": "Jeandelaincourt"
            },
            {
                "value": 27013,
                "label": "Faucigny",
                "label_ar": "فوسيني",
                "label_fr": "Faucigny"
            },
            {
                "value": 27014,
                "label": "Samoëns",
                "label_ar": "Samoëns",
                "label_fr": "Samoëns"
            },
            {
                "value": 27015,
                "label": "Choisy",
                "label_ar": "تشويزي",
                "label_fr": "Choisy"
            },
            {
                "value": 27016,
                "label": "Saint-Laurent-des-Autels",
                "label_ar": "سان لوران دي أوتيلز",
                "label_fr": "Saint-Laurent-des-Autels"
            },
            {
                "value": 27017,
                "label": "Villenoy",
                "label_ar": "فيلينوي",
                "label_fr": "Villenoy"
            },
            {
                "value": 27018,
                "label": "Boen-sur-Lignon",
                "label_ar": "بوين سور لينيون",
                "label_fr": "Boen-sur-Lignon"
            },
            {
                "value": 27019,
                "label": "Anglesqueville-l'Esneval",
                "label_ar": "Anglesqueville-l'Esneval",
                "label_fr": "Anglesqueville-l'Esneval"
            },
            {
                "value": 27020,
                "label": "Monnieres",
                "label_ar": "Monnieres",
                "label_fr": "Monnières"
            },
            {
                "value": 27021,
                "label": "Porcheres",
                "label_ar": "بورشير",
                "label_fr": "Porcheres"
            },
            {
                "value": 27022,
                "label": "Pleumeur-Bodou",
                "label_ar": "بلومور بودو",
                "label_fr": "Pleumeur-Bodou"
            },
            {
                "value": 27023,
                "label": "Quessoy",
                "label_ar": "Quessoy",
                "label_fr": "Quessoy"
            },
            {
                "value": 27024,
                "label": "Villeton",
                "label_ar": "فيلتون",
                "label_fr": "Villeton"
            },
            {
                "value": 27025,
                "label": "Moissy-Cramayel",
                "label_ar": "مويسي كرامايل",
                "label_fr": "Moissy-Cramayel"
            },
            {
                "value": 27026,
                "label": "Arles",
                "label_ar": "آرل",
                "label_fr": "Arles"
            },
            {
                "value": 27027,
                "label": "Pringy",
                "label_ar": "بريجي",
                "label_fr": "Pringy"
            },
            {
                "value": 27028,
                "label": "Frepillon",
                "label_ar": "فريبيلون",
                "label_fr": "Frepillon"
            },
            {
                "value": 27029,
                "label": "Tomblaine",
                "label_ar": "تومبلين",
                "label_fr": "Tomblaine"
            },
            {
                "value": 27030,
                "label": "Saint-Romain-en-Gier",
                "label_ar": "سان رومان أون جير",
                "label_fr": "Saint-Romain-en-Gier"
            },
            {
                "value": 27031,
                "label": "Saint-Lye",
                "label_ar": "سان لاي",
                "label_fr": "Saint-Lye"
            },
            {
                "value": 27032,
                "label": "Mireval-Lauragais",
                "label_ar": "ميريفال لوراجيس",
                "label_fr": "Mireval-Lauragais"
            },
            {
                "value": 27033,
                "label": "Nebian",
                "label_ar": "نيبيان",
                "label_fr": "Nebian"
            },
            {
                "value": 27034,
                "label": "Quint-Fonsegrives",
                "label_ar": "كوينت فونسيغريفز",
                "label_fr": "Quint-Fonsegrives"
            },
            {
                "value": 27035,
                "label": "Fleury-sur-Orne",
                "label_ar": "فلوري سور أورني",
                "label_fr": "Fleury-sur-Orne"
            },
            {
                "value": 27036,
                "label": "Bonsecours",
                "label_ar": "Bonsecours",
                "label_fr": "Bonsecours"
            },
            {
                "value": 27037,
                "label": "Epinay-sous-Senart",
                "label_ar": "Epinay-sous-Senart",
                "label_fr": "Epinay-sous-Senart"
            },
            {
                "value": 27038,
                "label": "Vernegues",
                "label_ar": "فيرنيج",
                "label_fr": "Vernegues"
            },
            {
                "value": 27039,
                "label": "Renescure",
                "label_ar": "تجديد",
                "label_fr": "Renescure"
            },
            {
                "value": 27040,
                "label": "Quincy-Voisins",
                "label_ar": "كوينسي فويسينز",
                "label_fr": "Quincy-Voisins"
            },
            {
                "value": 27041,
                "label": "Saint-Luperce",
                "label_ar": "سان لوبيرسي",
                "label_fr": "Saint-Luperce"
            },
            {
                "value": 27042,
                "label": "Bailleau-le-Pin",
                "label_ar": "Bailleau-le-Pin",
                "label_fr": "Bailleau-le-Pin"
            },
            {
                "value": 27043,
                "label": "Berat",
                "label_ar": "بيرات",
                "label_fr": "Berat"
            },
            {
                "value": 27044,
                "label": "Poix-du-Nord",
                "label_ar": "بويكس دو نورد",
                "label_fr": "Poix-du-Nord"
            },
            {
                "value": 27045,
                "label": "Saint-Michel-de-Maurienne",
                "label_ar": "سان ميشيل دي موريان",
                "label_fr": "Saint-Michel-de-Maurienne"
            },
            {
                "value": 27046,
                "label": "Rouans",
                "label_ar": "روان",
                "label_fr": "Rouans"
            },
            {
                "value": 27047,
                "label": "Etang-sur-Arroux",
                "label_ar": "إيتانج سور أرو",
                "label_fr": "Etang-sur-Arroux"
            },
            {
                "value": 27048,
                "label": "Roquecourbe",
                "label_ar": "روكويكورب",
                "label_fr": "Roquecourbe"
            },
            {
                "value": 27049,
                "label": "Challet",
                "label_ar": "شاليت",
                "label_fr": "Challet"
            },
            {
                "value": 27050,
                "label": "Corancez",
                "label_ar": "كورانسز",
                "label_fr": "Corancez"
            },
            {
                "value": 27051,
                "label": "Sigean",
                "label_ar": "سيجيان",
                "label_fr": "Sigean"
            },
            {
                "value": 27052,
                "label": "Sernhac",
                "label_ar": "سيرناك",
                "label_fr": "Sernhac"
            },
            {
                "value": 27053,
                "label": "Saint-Siffret",
                "label_ar": "سان صفرت",
                "label_fr": "Saint-Siffret"
            },
            {
                "value": 27054,
                "label": "Fourques",
                "label_ar": "فوركيس",
                "label_fr": "Fourques"
            },
            {
                "value": 27055,
                "label": "Gignac",
                "label_ar": "جينياك",
                "label_fr": "Gignac"
            },
            {
                "value": 27056,
                "label": "Torfou",
                "label_ar": "تورفو",
                "label_fr": "Torfou"
            },
            {
                "value": 27057,
                "label": "Montfaucon-Montigne",
                "label_ar": "مونتفوكون مونتين",
                "label_fr": "Montfaucon-Montigne"
            },
            {
                "value": 27058,
                "label": "Seiches-sur-le-Loir",
                "label_ar": "Seiches-sur-le-Loir",
                "label_fr": "Seiches-sur-le-Loir"
            },
            {
                "value": 27059,
                "label": "Barjouville",
                "label_ar": "بارجوفيل",
                "label_fr": "Barjouville"
            },
            {
                "value": 27060,
                "label": "Is-sur-Tille",
                "label_ar": "Is-sur-Tille",
                "label_fr": "Is-sur-Tille"
            },
            {
                "value": 27061,
                "label": "Sarrebourg",
                "label_ar": "ساريبورغ",
                "label_fr": "Sarrebourg"
            },
            {
                "value": 27062,
                "label": "Merville",
                "label_ar": "ميرفيل",
                "label_fr": "Merville"
            },
            {
                "value": 27063,
                "label": "Sommieres",
                "label_ar": "السوميرز",
                "label_fr": "Sommières"
            },
            {
                "value": 27064,
                "label": "Amplepuis",
                "label_ar": "Amplepuis",
                "label_fr": "Amplepuis"
            },
            {
                "value": 27065,
                "label": "Wavrechain-sous-Denain",
                "label_ar": "Wavrechain-sous-Denain",
                "label_fr": "Wavrechain-sous-Denain"
            },
            {
                "value": 27066,
                "label": "Laloubere",
                "label_ar": "لالوبير",
                "label_fr": "Laloubère"
            },
            {
                "value": 27067,
                "label": "Juillan",
                "label_ar": "جوليان",
                "label_fr": "Juillan"
            },
            {
                "value": 27068,
                "label": "Saint-Laurent-de-la-Salanque",
                "label_ar": "سان لوران دي لا سالانك",
                "label_fr": "Saint-Laurent-de-la-Salanque"
            },
            {
                "value": 27069,
                "label": "Toulouges",
                "label_ar": "تولوج",
                "label_fr": "Toulouges"
            },
            {
                "value": 27070,
                "label": "Chateau-Porcien",
                "label_ar": "شاتو بورسين",
                "label_fr": "Château-Porcien"
            },
            {
                "value": 27071,
                "label": "Buchy",
                "label_ar": "بوشي",
                "label_fr": "Buchy"
            },
            {
                "value": 27072,
                "label": "Bosc-le-Hard",
                "label_ar": "بوسك لو هارد",
                "label_fr": "Bosc-le-Hard"
            },
            {
                "value": 27073,
                "label": "Saint-Martin-sur-Ocre",
                "label_ar": "سان مارتن سور أوكري",
                "label_fr": "Saint-Martin-sur-Ocre"
            },
            {
                "value": 27074,
                "label": "Tavers",
                "label_ar": "تافرز",
                "label_fr": "Tavers"
            },
            {
                "value": 27075,
                "label": "Bezouce",
                "label_ar": "بيزوس",
                "label_fr": "Bezouce"
            },
            {
                "value": 27076,
                "label": "Pieusse",
                "label_ar": "بيوس",
                "label_fr": "Pieusse"
            },
            {
                "value": 27077,
                "label": "Ledenon",
                "label_ar": "ليدنون",
                "label_fr": "Ledenon"
            },
            {
                "value": 27078,
                "label": "Thuir",
                "label_ar": "ثوير",
                "label_fr": "Thuir"
            },
            {
                "value": 27079,
                "label": "Herbeys",
                "label_ar": "أعشاب",
                "label_fr": "Herbeys"
            },
            {
                "value": 27080,
                "label": "Beauvoisin",
                "label_ar": "بوفوازين",
                "label_fr": "Beauvoisin"
            },
            {
                "value": 27081,
                "label": "Issenheim",
                "label_ar": "إيسينهايم",
                "label_fr": "Issenheim"
            },
            {
                "value": 27082,
                "label": "Roussay",
                "label_ar": "روسي",
                "label_fr": "Roussay"
            },
            {
                "value": 27083,
                "label": "Mouy",
                "label_ar": "Mouy",
                "label_fr": "Mouy"
            },
            {
                "value": 27084,
                "label": "Saint-Calais",
                "label_ar": "سان كاليه",
                "label_fr": "Saint-Calais"
            },
            {
                "value": 27085,
                "label": "Mimet",
                "label_ar": "ميميت",
                "label_fr": "Mimet"
            },
            {
                "value": 27086,
                "label": "Lagupie",
                "label_ar": "Lagupie",
                "label_fr": "Lagupie"
            },
            {
                "value": 27087,
                "label": "Maulevrier",
                "label_ar": "موليفرير",
                "label_fr": "Maulevrier"
            },
            {
                "value": 27088,
                "label": "Besne",
                "label_ar": "بيسني",
                "label_fr": "Besne"
            },
            {
                "value": 27089,
                "label": "Steenwerck",
                "label_ar": "ستينويرك",
                "label_fr": "Steenwerck"
            },
            {
                "value": 27090,
                "label": "Saint-Loubes",
                "label_ar": "سان لوبس",
                "label_fr": "Saint-Loubes"
            },
            {
                "value": 27091,
                "label": "Tarnes",
                "label_ar": "تارنيس",
                "label_fr": "Tarnes"
            },
            {
                "value": 27092,
                "label": "Monchecourt",
                "label_ar": "مونشكورت",
                "label_fr": "Monchecourt"
            },
            {
                "value": 27093,
                "label": "Coulogne",
                "label_ar": "كولوني",
                "label_fr": "Coulogne"
            },
            {
                "value": 27094,
                "label": "Bompas",
                "label_ar": "بومباس",
                "label_fr": "Bompas"
            },
            {
                "value": 27095,
                "label": "Nogent-sur-Oise",
                "label_ar": "نوجينت سور وايز",
                "label_fr": "Nogent-sur-Oise"
            },
            {
                "value": 27096,
                "label": "Seignosse",
                "label_ar": "Seignosse",
                "label_fr": "Seignosse"
            },
            {
                "value": 27097,
                "label": "Verberie",
                "label_ar": "فيربيري",
                "label_fr": "Verberie"
            },
            {
                "value": 27098,
                "label": "Foissiat",
                "label_ar": "فوسيات",
                "label_fr": "Foissiat"
            },
            {
                "value": 27099,
                "label": "Longueil-Annel",
                "label_ar": "لونجويل أنيل",
                "label_fr": "Longueuil-Annel"
            },
            {
                "value": 27100,
                "label": "Jard-sur-Mer",
                "label_ar": "جارد سور مير",
                "label_fr": "Jard-sur-Mer"
            },
            {
                "value": 27101,
                "label": "Eauze",
                "label_ar": "ماء",
                "label_fr": "Eauze"
            },
            {
                "value": 27102,
                "label": "Ecos",
                "label_ar": "إيكوس",
                "label_fr": "Écos"
            },
            {
                "value": 27103,
                "label": "Dozule",
                "label_ar": "دوزول",
                "label_fr": "Dozule"
            },
            {
                "value": 27104,
                "label": "Saint-Erblon",
                "label_ar": "سان اربلون",
                "label_fr": "Saint-Erblon"
            },
            {
                "value": 27105,
                "label": "Le Mele-sur-Sarthe",
                "label_ar": "لو ميلي سور سارث",
                "label_fr": "Le Mele-sur-Sarthe"
            },
            {
                "value": 27106,
                "label": "Dercy",
                "label_ar": "ديرسى",
                "label_fr": "Dercy"
            },
            {
                "value": 27107,
                "label": "Villeneuve-les-Bouloc",
                "label_ar": "فيلنوف ليه بولوك",
                "label_fr": "Villeneuve-les-Bouloc"
            },
            {
                "value": 27108,
                "label": "Lacs",
                "label_ar": "البحيرات",
                "label_fr": "Lacs"
            },
            {
                "value": 27109,
                "label": "La Chatre",
                "label_ar": "لا شاتري",
                "label_fr": "La Châtre"
            },
            {
                "value": 27110,
                "label": "Clesse",
                "label_ar": "كليس",
                "label_fr": "Clessé"
            },
            {
                "value": 27111,
                "label": "Bazas",
                "label_ar": "بازاس",
                "label_fr": "Bazas"
            },
            {
                "value": 27112,
                "label": "Vireux-Wallerand",
                "label_ar": "فيروكس واليراند",
                "label_fr": "Vireux-Wallerand"
            },
            {
                "value": 27113,
                "label": "Etreux",
                "label_ar": "إترو",
                "label_fr": "Étreux"
            },
            {
                "value": 27114,
                "label": "Bourbourg",
                "label_ar": "بوربورغ",
                "label_fr": "Bourbourg"
            },
            {
                "value": 27115,
                "label": "Wormhout",
                "label_ar": "ورموت",
                "label_fr": "Wormhout"
            },
            {
                "value": 27116,
                "label": "Macau",
                "label_ar": "ماكاو",
                "label_fr": "Macao"
            },
            {
                "value": 27117,
                "label": "Beauvais-sur-Matha",
                "label_ar": "بوفيه سور ماثا",
                "label_fr": "Beauvais-sur-Matha"
            },
            {
                "value": 27118,
                "label": "Verquieres",
                "label_ar": "فيركويريس",
                "label_fr": "Verquières"
            },
            {
                "value": 27119,
                "label": "Rucqueville",
                "label_ar": "روكفيل",
                "label_fr": "Rucqueville"
            },
            {
                "value": 27120,
                "label": "Nouaille-Maupertuis",
                "label_ar": "Nouaille-Maupertuis",
                "label_fr": "Nouaille-Maupertuis"
            },
            {
                "value": 27121,
                "label": "Schirrhein",
                "label_ar": "شيرهين",
                "label_fr": "Schirrhein"
            },
            {
                "value": 27122,
                "label": "Sainte-Menehould",
                "label_ar": "سانت مينهول",
                "label_fr": "Sainte-Menehould"
            },
            {
                "value": 27123,
                "label": "Sannois",
                "label_ar": "سانويس",
                "label_fr": "Sannois"
            },
            {
                "value": 27124,
                "label": "Gorron",
                "label_ar": "غورون",
                "label_fr": "Gorron"
            },
            {
                "value": 27125,
                "label": "Belleville-sur-Mer",
                "label_ar": "بيلفيل سور مير",
                "label_fr": "Belleville-sur-Mer"
            },
            {
                "value": 27126,
                "label": "Baixas",
                "label_ar": "بايكساس",
                "label_fr": "Baixas"
            },
            {
                "value": 27127,
                "label": "Mouries",
                "label_ar": "الموريات",
                "label_fr": "Mouries"
            },
            {
                "value": 27128,
                "label": "Parigne-sur-Braye",
                "label_ar": "باريني سور براي",
                "label_fr": "Parigné-sur-Braye"
            },
            {
                "value": 27129,
                "label": "Blainville",
                "label_ar": "بلانفيل",
                "label_fr": "Blainville"
            },
            {
                "value": 27130,
                "label": "Conde-sur-Vesgre",
                "label_ar": "كوندي سور فيسجر",
                "label_fr": "Condé-sur-Vesgre"
            },
            {
                "value": 27131,
                "label": "Lampertsloch",
                "label_ar": "لامبرتسلوخ",
                "label_fr": "Lampertsloch"
            },
            {
                "value": 27132,
                "label": "Fougerolles",
                "label_ar": "فوجيرول",
                "label_fr": "Fougerolles"
            },
            {
                "value": 27133,
                "label": "Auch",
                "label_ar": "اوك",
                "label_fr": "Auch"
            },
            {
                "value": 27134,
                "label": "Amberieux-en-Dombes",
                "label_ar": "Amberieux-en-Dombes",
                "label_fr": "Ambérieux-en-Dombes"
            },
            {
                "value": 27135,
                "label": "Villars-les-Dombes",
                "label_ar": "فيلار ليس دومب",
                "label_fr": "Villars-les-Dombes"
            },
            {
                "value": 27136,
                "label": "Cadalen",
                "label_ar": "كادالين",
                "label_fr": "Cadalen"
            },
            {
                "value": 27137,
                "label": "Froges",
                "label_ar": "الضفادع",
                "label_fr": "Froges"
            },
            {
                "value": 27138,
                "label": "Sainte-Foy-d'Aigrefeuille",
                "label_ar": "Sainte-Foy-d'Aigrefeuille",
                "label_fr": "Sainte-Foy-d'Aigrefeuille"
            },
            {
                "value": 27139,
                "label": "Aigrefeuille",
                "label_ar": "ايجريفويل",
                "label_fr": "Aigrefeuille"
            },
            {
                "value": 27140,
                "label": "Reuilly",
                "label_ar": "Reuilly",
                "label_fr": "Reuilly"
            },
            {
                "value": 27141,
                "label": "Annoeullin",
                "label_ar": "أنويولين",
                "label_fr": "Annoeullin"
            },
            {
                "value": 27142,
                "label": "Farebersviller",
                "label_ar": "فاريبرسفيلر",
                "label_fr": "Farebersviller"
            },
            {
                "value": 27143,
                "label": "Villerupt",
                "label_ar": "فيليربت",
                "label_fr": "Villerupt"
            },
            {
                "value": 27144,
                "label": "Racquinghem",
                "label_ar": "راكوينجهم",
                "label_fr": "Racquinghem"
            },
            {
                "value": 27145,
                "label": "Monnaie",
                "label_ar": "Monnaie",
                "label_fr": "Monnaie"
            },
            {
                "value": 27146,
                "label": "Chateau-Renault",
                "label_ar": "شاتو رينو",
                "label_fr": "Château-Renault"
            },
            {
                "value": 27147,
                "label": "Ligueil",
                "label_ar": "ليغويل",
                "label_fr": "Ligueil"
            },
            {
                "value": 27148,
                "label": "Auxi-le-Chateau",
                "label_ar": "أوكسي لو شاتو",
                "label_fr": "Auxi-le-Château"
            },
            {
                "value": 27149,
                "label": "Le Molay",
                "label_ar": "لو مولاي",
                "label_fr": "Le Molay"
            },
            {
                "value": 27150,
                "label": "Saint-Jean-d'Illac",
                "label_ar": "سان جان ديلاك",
                "label_fr": "Saint-Jean-d'Illac"
            },
            {
                "value": 27151,
                "label": "Vinneuf",
                "label_ar": "فينوف",
                "label_fr": "Vinneuf"
            },
            {
                "value": 27152,
                "label": "Saint-Julien-du-Sault",
                "label_ar": "سان جوليان دو سولت",
                "label_fr": "Saint-Julien-du-Sault"
            },
            {
                "value": 27153,
                "label": "Le Bernard",
                "label_ar": "لو برنارد",
                "label_fr": "Le Bernard"
            },
            {
                "value": 27154,
                "label": "Saint-Martin-de-Valgalgues",
                "label_ar": "سان مارتن دي فالجالج",
                "label_fr": "Saint-Martin-de-Valgalgues"
            },
            {
                "value": 27155,
                "label": "Saint-Pierre-du-Mont",
                "label_ar": "سان بيير دو مونت",
                "label_fr": "Saint-Pierre-du-Mont"
            },
            {
                "value": 27156,
                "label": "Feneu",
                "label_ar": "فينيو",
                "label_fr": "Feneu"
            },
            {
                "value": 27157,
                "label": "Rieulay",
                "label_ar": "ريولاي",
                "label_fr": "Rieulay"
            },
            {
                "value": 27158,
                "label": "Lesconil",
                "label_ar": "ليسكونيل",
                "label_fr": "Lesconil"
            },
            {
                "value": 27159,
                "label": "Saint-Jean-de-Sauves",
                "label_ar": "سان جان دي سوفيس",
                "label_fr": "Saint-Jean-de-Sauves"
            },
            {
                "value": 27160,
                "label": "Saint-Clair",
                "label_ar": "سانت كلير",
                "label_fr": "Saint-Clair"
            },
            {
                "value": 27161,
                "label": "Seremange-Erzange",
                "label_ar": "سيريمانج إرزانج",
                "label_fr": "Seremange-Erzange"
            },
            {
                "value": 27162,
                "label": "Us",
                "label_ar": "نحن",
                "label_fr": "Nous"
            },
            {
                "value": 27163,
                "label": "Thieville",
                "label_ar": "ثيفيل",
                "label_fr": "Thieville"
            },
            {
                "value": 27164,
                "label": "Le Barp",
                "label_ar": "لو بارب",
                "label_fr": "Le Barp"
            },
            {
                "value": 27165,
                "label": "Nonville",
                "label_ar": "نونفيل",
                "label_fr": "Nonville"
            },
            {
                "value": 27166,
                "label": "Saintry-sur-Seine",
                "label_ar": "سانتري سور سين",
                "label_fr": "Saintry-sur-Seine"
            },
            {
                "value": 27167,
                "label": "Biscarrosse",
                "label_ar": "بيسكاروس",
                "label_fr": "Biscarrosse"
            },
            {
                "value": 27168,
                "label": "Parentis-en-Born",
                "label_ar": "Parentis-en-Born",
                "label_fr": "Parentis-en-Born"
            },
            {
                "value": 27169,
                "label": "Clohars-Fouesnant",
                "label_ar": "كلوهارس فوسنانت",
                "label_fr": "Clohars-Fouesnant"
            },
            {
                "value": 27170,
                "label": "Plouarzel",
                "label_ar": "بلوارزل",
                "label_fr": "Plouarzel"
            },
            {
                "value": 27171,
                "label": "Lanvallay",
                "label_ar": "لانفالاي",
                "label_fr": "Lanvallay"
            },
            {
                "value": 27172,
                "label": "May-en-Multien",
                "label_ar": "مايو أون مولتين",
                "label_fr": "May-en-Multien"
            },
            {
                "value": 27173,
                "label": "Chateau-Landon",
                "label_ar": "شاتو لاندون",
                "label_fr": "Château-Landon"
            },
            {
                "value": 27174,
                "label": "Mauperthuis",
                "label_ar": "Mauperthuis",
                "label_fr": "Mauperthuis"
            },
            {
                "value": 27175,
                "label": "Beautheil",
                "label_ar": "جميل",
                "label_fr": "Beautheil"
            },
            {
                "value": 27176,
                "label": "Caden",
                "label_ar": "كادن",
                "label_fr": "Caden"
            },
            {
                "value": 27177,
                "label": "Grez-sur-Loing",
                "label_ar": "جريز سور لونج",
                "label_fr": "Grez-sur-Loing"
            },
            {
                "value": 27178,
                "label": "Seugy",
                "label_ar": "Seugy",
                "label_fr": "Seugy"
            },
            {
                "value": 27179,
                "label": "Boullay-les-Troux",
                "label_ar": "Boullay-les-Troux",
                "label_fr": "Boullay-les-Troux"
            },
            {
                "value": 27180,
                "label": "La Turbie",
                "label_ar": "لا توربي",
                "label_fr": "La Turbie"
            },
            {
                "value": 27181,
                "label": "Alignan-du-Vent",
                "label_ar": "Alignan-du-Vent",
                "label_fr": "Alignan-du-Vent"
            },
            {
                "value": 27182,
                "label": "Vendres",
                "label_ar": "Vendres",
                "label_fr": "Vendres"
            },
            {
                "value": 27183,
                "label": "Frozes",
                "label_ar": "يتجمد",
                "label_fr": "Frozes"
            },
            {
                "value": 27184,
                "label": "Vars",
                "label_ar": "فارز",
                "label_fr": "Vars"
            },
            {
                "value": 27185,
                "label": "Mansle",
                "label_ar": "مانسل",
                "label_fr": "Mansle"
            },
            {
                "value": 27186,
                "label": "Pecquencourt",
                "label_ar": "بيكوينكور",
                "label_fr": "Pecquencourt"
            },
            {
                "value": 27187,
                "label": "La Menitre",
                "label_ar": "لا منيتر",
                "label_fr": "La Menitre"
            },
            {
                "value": 27188,
                "label": "Suippes",
                "label_ar": "سويبس",
                "label_fr": "Suippes"
            },
            {
                "value": 27189,
                "label": "Bachant",
                "label_ar": "باتشانت",
                "label_fr": "Bachant"
            },
            {
                "value": 27190,
                "label": "Assevent",
                "label_ar": "Assevent",
                "label_fr": "Assevent"
            },
            {
                "value": 27191,
                "label": "Louvroil",
                "label_ar": "لوفرويل",
                "label_fr": "Louvroil"
            },
            {
                "value": 27192,
                "label": "Cotignac",
                "label_ar": "كوتينياك",
                "label_fr": "Cotignac"
            },
            {
                "value": 27193,
                "label": "Lery",
                "label_ar": "ليري",
                "label_fr": "Lery"
            },
            {
                "value": 27194,
                "label": "Le Bocasse",
                "label_ar": "لو بوكاس",
                "label_fr": "Le Bocasse"
            },
            {
                "value": 27195,
                "label": "Gauciel",
                "label_ar": "جوسيل",
                "label_fr": "Gauciel"
            },
            {
                "value": 27196,
                "label": "Coignieres",
                "label_ar": "Coignieres",
                "label_fr": "Coignières"
            },
            {
                "value": 27197,
                "label": "Auribeau-sur-Siagne",
                "label_ar": "Auribeau-sur-Siagne",
                "label_fr": "Auribeau-sur-Siagne"
            },
            {
                "value": 27198,
                "label": "Bussang",
                "label_ar": "بوسانغ",
                "label_fr": "Bussang"
            },
            {
                "value": 27199,
                "label": "Tessy-sur-Vire",
                "label_ar": "تيسي سور فير",
                "label_fr": "Tessy-sur-Vire"
            },
            {
                "value": 27200,
                "label": "Beaumont-de-Lomagne",
                "label_ar": "بومون دي لوماني",
                "label_fr": "Beaumont-de-Lomagne"
            },
            {
                "value": 27201,
                "label": "Laroin",
                "label_ar": "لاروين",
                "label_fr": "Laroin"
            },
            {
                "value": 27202,
                "label": "Villeconin",
                "label_ar": "فيليكونين",
                "label_fr": "Villeconin"
            },
            {
                "value": 27203,
                "label": "Saclas",
                "label_ar": "ساكلاس",
                "label_fr": "Saclas"
            },
            {
                "value": 27204,
                "label": "Montigny-en-Gohelle",
                "label_ar": "مونتيني أون جويل",
                "label_fr": "Montigny-en-Gohelle"
            },
            {
                "value": 27205,
                "label": "Saint-Lormel",
                "label_ar": "سان لورميل",
                "label_fr": "Saint-Lormel"
            },
            {
                "value": 27206,
                "label": "Oraison",
                "label_ar": "اوريسون",
                "label_fr": "Oraison"
            },
            {
                "value": 27207,
                "label": "Brece",
                "label_ar": "بريس",
                "label_fr": "Brece"
            },
            {
                "value": 27208,
                "label": "Epervans",
                "label_ar": "Epervans",
                "label_fr": "Epervans"
            },
            {
                "value": 27209,
                "label": "Hamars",
                "label_ar": "هامارس",
                "label_fr": "Hamars"
            },
            {
                "value": 27210,
                "label": "Thury-Harcourt",
                "label_ar": "ثوري هاركورت",
                "label_fr": "Thury-Harcourt"
            },
            {
                "value": 27211,
                "label": "L'Union",
                "label_ar": "لأونيون",
                "label_fr": "L'Union"
            },
            {
                "value": 27212,
                "label": "Esternay",
                "label_ar": "إسترناي",
                "label_fr": "Esternay"
            },
            {
                "value": 27213,
                "label": "Lagnieu",
                "label_ar": "Lagnieu",
                "label_fr": "Lagnieu"
            },
            {
                "value": 27214,
                "label": "Champigny",
                "label_ar": "شامبيني",
                "label_fr": "Champigny"
            },
            {
                "value": 27215,
                "label": "Ergersheim",
                "label_ar": "إرجرسهايم",
                "label_fr": "Ergersheim"
            },
            {
                "value": 27216,
                "label": "Walheim",
                "label_ar": "والهايم",
                "label_fr": "Walheim"
            },
            {
                "value": 27217,
                "label": "Zillisheim",
                "label_ar": "زيليشيم",
                "label_fr": "Zillisheim"
            },
            {
                "value": 27218,
                "label": "Sury-le-Comtal",
                "label_ar": "سوري لو كومتال",
                "label_fr": "Sury-le-Comtal"
            },
            {
                "value": 27219,
                "label": "Nogent",
                "label_ar": "لا شيء",
                "label_fr": "Nogent"
            },
            {
                "value": 27220,
                "label": "Rollancourt",
                "label_ar": "رولانكور",
                "label_fr": "Rollancourt"
            },
            {
                "value": 27221,
                "label": "Montastruc-la-Conseillere",
                "label_ar": "مونتاستروك لا كونسيلير",
                "label_fr": "Montastruc-la-Conseillère"
            },
            {
                "value": 27222,
                "label": "Bar-le-Duc",
                "label_ar": "بار لو دوك",
                "label_fr": "Bar-le-Duc"
            },
            {
                "value": 27223,
                "label": "Savennieres",
                "label_ar": "سافينيريس",
                "label_fr": "Savennières"
            },
            {
                "value": 27224,
                "label": "Houlette",
                "label_ar": "هوليت",
                "label_fr": "Houlette"
            },
            {
                "value": 27225,
                "label": "Nohic",
                "label_ar": "نوهيتش",
                "label_fr": "Nohic"
            },
            {
                "value": 27226,
                "label": "Adissan",
                "label_ar": "أديسان",
                "label_fr": "Adissan"
            },
            {
                "value": 27227,
                "label": "Bonneuil-en-France",
                "label_ar": "Bonneuil-en-France",
                "label_fr": "Bonneuil-en-France"
            },
            {
                "value": 27228,
                "label": "Le Blanc-Mesnil",
                "label_ar": "لو بلان ميسنيل",
                "label_fr": "Le Blanc-Mesnil"
            },
            {
                "value": 27229,
                "label": "Verines",
                "label_ar": "فيرينز",
                "label_fr": "Verines"
            },
            {
                "value": 27230,
                "label": "Hericy",
                "label_ar": "هيرسي",
                "label_fr": "Hericy"
            },
            {
                "value": 27231,
                "label": "Roquefort-sur-Soulzon",
                "label_ar": "روكفور سور سولزون",
                "label_fr": "Roquefort-sur-Soulzon"
            },
            {
                "value": 27232,
                "label": "Matignon",
                "label_ar": "ماتينيون",
                "label_fr": "Matignon"
            },
            {
                "value": 27233,
                "label": "Severac-le-Chateau",
                "label_ar": "سيفيراك لو شاتو",
                "label_fr": "Severac-le-Château"
            },
            {
                "value": 27234,
                "label": "Chissay-en-Touraine",
                "label_ar": "Chissay-en-Touraine",
                "label_fr": "Chissay-en-Touraine"
            },
            {
                "value": 27235,
                "label": "Aubais",
                "label_ar": "أوبيس",
                "label_fr": "Aubais"
            },
            {
                "value": 27236,
                "label": "Arcachon",
                "label_ar": "اركاشون",
                "label_fr": "Arcachon"
            },
            {
                "value": 27237,
                "label": "Saint-Varent",
                "label_ar": "سان فارينت",
                "label_fr": "Saint-Varent"
            },
            {
                "value": 27238,
                "label": "Airvault",
                "label_ar": "ايرفولت",
                "label_fr": "Airvault"
            },
            {
                "value": 27239,
                "label": "Biot",
                "label_ar": "بيوت",
                "label_fr": "Biot"
            },
            {
                "value": 27240,
                "label": "Uckange",
                "label_ar": "أوكانج",
                "label_fr": "Uckange"
            },
            {
                "value": 27241,
                "label": "Cordes-Tolosannes",
                "label_ar": "كوردس تولوسانيس",
                "label_fr": "Cordes-Tolosannes"
            },
            {
                "value": 27242,
                "label": "Larrazet",
                "label_ar": "لارازيت",
                "label_fr": "Larrazet"
            },
            {
                "value": 27243,
                "label": "Kuntzig",
                "label_ar": "كونتزيج",
                "label_fr": "Kuntzig"
            },
            {
                "value": 27244,
                "label": "Le Monastier-Pin-Mories",
                "label_ar": "لو موناستير بين موريس",
                "label_fr": "Le Monastier-Pin-Mories"
            },
            {
                "value": 27245,
                "label": "Montrodat",
                "label_ar": "مونترودات",
                "label_fr": "Montrodat"
            },
            {
                "value": 27246,
                "label": "Bazarnes",
                "label_ar": "بازارنس",
                "label_fr": "Bazarnes"
            },
            {
                "value": 27247,
                "label": "Boisseuil",
                "label_ar": "Boisseuil",
                "label_fr": "Boisseuil"
            },
            {
                "value": 27248,
                "label": "Cazavet",
                "label_ar": "كازافيت",
                "label_fr": "Cazavet"
            },
            {
                "value": 27249,
                "label": "Barr",
                "label_ar": "بار",
                "label_fr": "Barr"
            },
            {
                "value": 27250,
                "label": "Lincel",
                "label_ar": "لينسل",
                "label_fr": "Lincel"
            },
            {
                "value": 27251,
                "label": "Prix-les-Mezieres",
                "label_ar": "Prix-les-Mezieres",
                "label_fr": "Prix-les-Mézières"
            },
            {
                "value": 27252,
                "label": "Alleriot",
                "label_ar": "أليريوت",
                "label_fr": "Alleriot"
            },
            {
                "value": 27253,
                "label": "Brionne",
                "label_ar": "بريون",
                "label_fr": "Brionne"
            },
            {
                "value": 27254,
                "label": "Villelongue-de-la-Salanque",
                "label_ar": "Villelongue-de-la-Salanque",
                "label_fr": "Villelongue-de-la-Salanque"
            },
            {
                "value": 27255,
                "label": "Banyuls de la Marenda",
                "label_ar": "بانيولس دي لا مارندا",
                "label_fr": "Banyuls de la Marenda"
            },
            {
                "value": 27256,
                "label": "Archamps",
                "label_ar": "أرشامبس",
                "label_fr": "Archamps"
            },
            {
                "value": 27257,
                "label": "Chamouilley",
                "label_ar": "شامويلي",
                "label_fr": "Chamouilley"
            },
            {
                "value": 27258,
                "label": "Saint-Sulpice-sur-Leze",
                "label_ar": "سان سولبيس سور ليز",
                "label_fr": "Saint-Sulpice-sur-Leze"
            },
            {
                "value": 27259,
                "label": "Longages",
                "label_ar": "أطوال",
                "label_fr": "Longages"
            },
            {
                "value": 27260,
                "label": "Bertrange",
                "label_ar": "بيرترانج",
                "label_fr": "Bertrange"
            },
            {
                "value": 27261,
                "label": "Longwy",
                "label_ar": "لونغوي",
                "label_fr": "Longwy"
            },
            {
                "value": 27262,
                "label": "Rombas",
                "label_ar": "رومباس",
                "label_fr": "Rombas"
            },
            {
                "value": 27263,
                "label": "Rosselange",
                "label_ar": "روسيلانج",
                "label_fr": "Rosselange"
            },
            {
                "value": 27264,
                "label": "Sierck-les-Bains",
                "label_ar": "Sierck-les-Bains",
                "label_fr": "Sierck-les-Bains"
            },
            {
                "value": 27265,
                "label": "LAiguillon-sur-Mer",
                "label_ar": "لايجيلون سور مير",
                "label_fr": "LAiguillon-sur-Mer"
            },
            {
                "value": 27266,
                "label": "Rechesy",
                "label_ar": "ريشيسي",
                "label_fr": "Rechesy"
            },
            {
                "value": 27267,
                "label": "Duingt",
                "label_ar": "دوينجت",
                "label_fr": "Duingt"
            },
            {
                "value": 27268,
                "label": "Cons-Sainte-Colombe",
                "label_ar": "كونس سانت كولومب",
                "label_fr": "Contre-Sainte-Colombe"
            },
            {
                "value": 27269,
                "label": "Vallauris",
                "label_ar": "فالوريس",
                "label_fr": "Vallauris"
            },
            {
                "value": 27270,
                "label": "Saint-Laurent-d'Aigouze",
                "label_ar": "سان لوران دايجوز",
                "label_fr": "Saint-Laurent-d'Aigouze"
            },
            {
                "value": 27271,
                "label": "Saint-Hippolyte-du-Fort",
                "label_ar": "سان هيبوليت دو فورت",
                "label_fr": "Saint-Hippolyte-du-Fort"
            },
            {
                "value": 27272,
                "label": "Salindres",
                "label_ar": "ساليندرز",
                "label_fr": "Salindres"
            },
            {
                "value": 27273,
                "label": "Saint-Ambroix",
                "label_ar": "سانت أمبرويكس",
                "label_fr": "Saint-Ambroix"
            },
            {
                "value": 27274,
                "label": "Besseges",
                "label_ar": "بيسيج",
                "label_fr": "Bessèges"
            },
            {
                "value": 27275,
                "label": "Miremont",
                "label_ar": "ميريمونت",
                "label_fr": "Miremont"
            },
            {
                "value": 27276,
                "label": "Daux",
                "label_ar": "داوكس",
                "label_fr": "Daux"
            },
            {
                "value": 27277,
                "label": "Rue",
                "label_ar": "شارع",
                "label_fr": "Rue"
            },
            {
                "value": 27278,
                "label": "Romeries",
                "label_ar": "رومريز",
                "label_fr": "Romeries"
            },
            {
                "value": 27279,
                "label": "Contes",
                "label_ar": "المحتويات",
                "label_fr": "Contes"
            },
            {
                "value": 27280,
                "label": "La Mulatiere",
                "label_ar": "لا مولاتير",
                "label_fr": "La Mulatière"
            },
            {
                "value": 27281,
                "label": "Talant",
                "label_ar": "تالانت",
                "label_fr": "Talant"
            },
            {
                "value": 27282,
                "label": "Fontaine-les-Dijon",
                "label_ar": "فونتين ليه ديجون",
                "label_fr": "Fontaine-les-Dijon"
            },
            {
                "value": 27283,
                "label": "Vaux-les-Saint-Claude",
                "label_ar": "فو ليه سان كلود",
                "label_fr": "Vaux-les-Saint-Claude"
            },
            {
                "value": 27284,
                "label": "Colombe",
                "label_ar": "كولومب",
                "label_fr": "Colombe"
            },
            {
                "value": 27285,
                "label": "La Frette",
                "label_ar": "لا فريت",
                "label_fr": "La Frette"
            },
            {
                "value": 27286,
                "label": "Saint-Hilaire-de-la-Cote",
                "label_ar": "سان هيلير دي لا كوت",
                "label_fr": "Saint-Hilaire-de-la-Cote"
            },
            {
                "value": 27287,
                "label": "Montreuil-l'Argille",
                "label_ar": "مونتروي لارجيل",
                "label_fr": "Montreuil-l'Argille"
            },
            {
                "value": 27288,
                "label": "Chamblac",
                "label_ar": "شامبلاك",
                "label_fr": "Chamblac"
            },
            {
                "value": 27289,
                "label": "Aunay-sur-Odon",
                "label_ar": "أوني سور أودون",
                "label_fr": "Aunay-sur-Odon"
            },
            {
                "value": 27290,
                "label": "Torreilles",
                "label_ar": "توريليس",
                "label_fr": "Torreilles"
            },
            {
                "value": 27291,
                "label": "Livet-et-Gavet",
                "label_ar": "Livet-et-Gavet",
                "label_fr": "Livet-et-Gavet"
            },
            {
                "value": 27292,
                "label": "La Paute",
                "label_ar": "لا باوت",
                "label_fr": "La Paute"
            },
            {
                "value": 27293,
                "label": "Le Bourg",
                "label_ar": "لو بورغ",
                "label_fr": "Le Bourg"
            },
            {
                "value": 27294,
                "label": "Pont-de-Vaux",
                "label_ar": "بونت دي فو",
                "label_fr": "Pont-de-Vaux"
            },
            {
                "value": 27295,
                "label": "Salernes",
                "label_ar": "ساليرنس",
                "label_fr": "Salernes"
            },
            {
                "value": 27296,
                "label": "Coulounieix-Chamiers",
                "label_ar": "Coulounieix-Chamiers",
                "label_fr": "Coulounieix-Chamiers"
            },
            {
                "value": 27297,
                "label": "Le Grau-du-Roi",
                "label_ar": "لو غراو دو روي",
                "label_fr": "Le Grau-du-Roi"
            },
            {
                "value": 27298,
                "label": "Sathonay-Camp",
                "label_ar": "ساثوناي كامب",
                "label_fr": "Camp de Sathonay"
            },
            {
                "value": 27299,
                "label": "Les Maures",
                "label_ar": "ليه موريس",
                "label_fr": "Les Maures"
            },
            {
                "value": 27300,
                "label": "Mirebeau-sur-Beze",
                "label_ar": "ميريبو سور بيز",
                "label_fr": "Mirebeau-sur-Bèze"
            },
            {
                "value": 27301,
                "label": "Grignan",
                "label_ar": "غرينيان",
                "label_fr": "Grignan"
            },
            {
                "value": 27302,
                "label": "Messy",
                "label_ar": "فوضوي",
                "label_fr": "Désordonné"
            },
            {
                "value": 27303,
                "label": "Massieux",
                "label_ar": "ماسيو",
                "label_fr": "Massieux"
            },
            {
                "value": 27304,
                "label": "Le Barcares",
                "label_ar": "لو باركاريس",
                "label_fr": "Le Barcares"
            },
            {
                "value": 27305,
                "label": "Leucate",
                "label_ar": "يوكيت",
                "label_fr": "Leucate"
            },
            {
                "value": 27306,
                "label": "Sainte-Marie",
                "label_ar": "سانت ماري",
                "label_fr": "Sainte-Marie"
            },
            {
                "value": 27307,
                "label": "Ecourt-Saint-Quentin",
                "label_ar": "ايكورت سانت كوينتين",
                "label_fr": "Écourt-Saint-Quentin"
            },
            {
                "value": 27308,
                "label": "Bormes-les-Mimosas",
                "label_ar": "بورم ليس ميموزا",
                "label_fr": "Bormes-les-Mimosas"
            },
            {
                "value": 27309,
                "label": "Passy",
                "label_ar": "باسي",
                "label_fr": "Passy"
            },
            {
                "value": 27310,
                "label": "Chailles",
                "label_ar": "Chailles",
                "label_fr": "Chailles"
            },
            {
                "value": 27311,
                "label": "Acquigny",
                "label_ar": "اكتساب",
                "label_fr": "Acquigny"
            },
            {
                "value": 27312,
                "label": "Heudreville-sur-Eure",
                "label_ar": "Heudreville-sur-Eure",
                "label_fr": "Heudreville-sur-Eure"
            },
            {
                "value": 27313,
                "label": "Fontaine-Heudebourg",
                "label_ar": "فونتين هيودبورغ",
                "label_fr": "Fontaine-Heudebourg"
            },
            {
                "value": 27314,
                "label": "Hondouville",
                "label_ar": "هوندوفيل",
                "label_fr": "Hondouville"
            },
            {
                "value": 27315,
                "label": "Brosville",
                "label_ar": "بروسفيل",
                "label_fr": "Brosville"
            },
            {
                "value": 27316,
                "label": "Bernay",
                "label_ar": "بيرناي",
                "label_fr": "Bernay"
            },
            {
                "value": 27317,
                "label": "Thiberville",
                "label_ar": "ثيبرفيل",
                "label_fr": "Thiberville"
            },
            {
                "value": 27318,
                "label": "Battenheim",
                "label_ar": "باتنهايم",
                "label_fr": "Battenheim"
            },
            {
                "value": 27319,
                "label": "Sentheim",
                "label_ar": "سينثيم",
                "label_fr": "Sentheim"
            },
            {
                "value": 27320,
                "label": "Gattieres",
                "label_ar": "جاتيريس",
                "label_fr": "Gattières"
            },
            {
                "value": 27321,
                "label": "Sainte-Sigolene",
                "label_ar": "سانت سيغولين",
                "label_fr": "Sainte-Sigolène"
            },
            {
                "value": 27322,
                "label": "Fraisses",
                "label_ar": "فريسيس",
                "label_fr": "Fraisses"
            },
            {
                "value": 27323,
                "label": "Feillens",
                "label_ar": "فيلينز",
                "label_fr": "Feillens"
            },
            {
                "value": 27324,
                "label": "Messein",
                "label_ar": "ميسين",
                "label_fr": "Messein"
            },
            {
                "value": 27325,
                "label": "Azy-sur-Marne",
                "label_ar": "عزي سور مارن",
                "label_fr": "Azy-sur-Marne"
            },
            {
                "value": 27326,
                "label": "Lanrodec",
                "label_ar": "لانروديك",
                "label_fr": "Lanrodec"
            },
            {
                "value": 27327,
                "label": "Bringolo",
                "label_ar": "برينجولو",
                "label_fr": "Bringolo"
            },
            {
                "value": 27328,
                "label": "Etriche",
                "label_ar": "إتريش",
                "label_fr": "Etriche"
            },
            {
                "value": 27329,
                "label": "Rochefort-sur-Loire",
                "label_ar": "روشيفور سور لوار",
                "label_fr": "Rochefort-sur-Loire"
            },
            {
                "value": 27330,
                "label": "Chalonnes-sur-Loire",
                "label_ar": "Chalonnes-sur-Loire",
                "label_fr": "Chalonnes-sur-Loire"
            },
            {
                "value": 27331,
                "label": "Clapiers",
                "label_ar": "كلابر",
                "label_fr": "Clapiers"
            },
            {
                "value": 27332,
                "label": "La Selve",
                "label_ar": "لا سيلف",
                "label_fr": "La Selve"
            },
            {
                "value": 27333,
                "label": "Langoat",
                "label_ar": "لانجوات",
                "label_fr": "Langoat"
            },
            {
                "value": 27334,
                "label": "Serrieres",
                "label_ar": "سيريريز",
                "label_fr": "Serrières"
            },
            {
                "value": 27335,
                "label": "Staffelfelden",
                "label_ar": "ستافلفيلدن",
                "label_fr": "Staffelfelden"
            },
            {
                "value": 27336,
                "label": "Labouheyre",
                "label_ar": "لابوهير",
                "label_fr": "Labouheyre"
            },
            {
                "value": 27337,
                "label": "Monteils",
                "label_ar": "مونتيلز",
                "label_fr": "Monteils"
            },
            {
                "value": 27338,
                "label": "Mimizan",
                "label_ar": "ميميزان",
                "label_fr": "Mimizan"
            },
            {
                "value": 27339,
                "label": "Chavanoz",
                "label_ar": "شافانوز",
                "label_fr": "Chavanoz"
            },
            {
                "value": 27340,
                "label": "Charvieu-Chavagneux",
                "label_ar": "شارفيو شافانيوكس",
                "label_fr": "Charvieu-Chavagneux"
            },
            {
                "value": 27341,
                "label": "Champagne-au-Mont-d'Or",
                "label_ar": "الشمبانيا أو مونت دور",
                "label_fr": "Champagne-au-Mont-d'Or"
            },
            {
                "value": 27342,
                "label": "Rugles",
                "label_ar": "رغلز",
                "label_fr": "Rugles"
            },
            {
                "value": 27343,
                "label": "Valras-Plage",
                "label_ar": "فالراس بلاج",
                "label_fr": "Valras-Plage"
            },
            {
                "value": 27344,
                "label": "Montmirat",
                "label_ar": "مونميرات",
                "label_fr": "Montmirat"
            },
            {
                "value": 27345,
                "label": "Dax",
                "label_ar": "داكس",
                "label_fr": "Dax"
            },
            {
                "value": 27346,
                "label": "Allouagne",
                "label_ar": "علوان",
                "label_fr": "Allouagne"
            },
            {
                "value": 27347,
                "label": "Saint-Sebastien-de-Morsent",
                "label_ar": "سان سيباستيان دي مورسينت",
                "label_fr": "Saint-Sébastien-de-Morsent"
            },
            {
                "value": 27348,
                "label": "Realmont",
                "label_ar": "ريلمونت",
                "label_fr": "Realmont"
            },
            {
                "value": 27349,
                "label": "Fleurbaix",
                "label_ar": "فلوربايكس",
                "label_fr": "Fleurbaix"
            },
            {
                "value": 27350,
                "label": "Gundershoffen",
                "label_ar": "جوندرشوفن",
                "label_fr": "Gundershoffen"
            },
            {
                "value": 27351,
                "label": "Aurignac",
                "label_ar": "Aurignac",
                "label_fr": "Aurignac"
            },
            {
                "value": 27352,
                "label": "Palaminy",
                "label_ar": "بالامينى",
                "label_fr": "Palaminy"
            },
            {
                "value": 27353,
                "label": "Saint-Julien-le-Vendomois",
                "label_ar": "سان جوليان لو فاندوموا",
                "label_fr": "Saint-Julien-le-Vendomois"
            },
            {
                "value": 27354,
                "label": "Moirans-en-Montagne",
                "label_ar": "Moirans-en-Montagne",
                "label_fr": "Moirans-en-Montagne"
            },
            {
                "value": 27355,
                "label": "Saint-Lupicin",
                "label_ar": "سان لوبيسين",
                "label_fr": "Saint-Lupicin"
            },
            {
                "value": 27356,
                "label": "Sorquainville",
                "label_ar": "سوركوينفيل",
                "label_fr": "Sorquainville"
            },
            {
                "value": 27357,
                "label": "Barentin",
                "label_ar": "بارنتين",
                "label_fr": "Barentin"
            },
            {
                "value": 27358,
                "label": "Le Passage",
                "label_ar": "لو باساج",
                "label_fr": "Le Passage"
            },
            {
                "value": 27359,
                "label": "Beaumont-le-Roger",
                "label_ar": "بومون لو روجر",
                "label_fr": "Beaumont-le-Roger"
            },
            {
                "value": 27360,
                "label": "Etainhus",
                "label_ar": "إيتينيه",
                "label_fr": "Etainhus"
            },
            {
                "value": 27361,
                "label": "Saint-Pierre-le-Moutier",
                "label_ar": "سان بيير لو موتير",
                "label_fr": "Saint-Pierre-le-Moutier"
            },
            {
                "value": 27362,
                "label": "Appeville-Annebault",
                "label_ar": "ابفيل انيبولت",
                "label_fr": "Appeville-Annebault"
            },
            {
                "value": 27363,
                "label": "Tarare",
                "label_ar": "تاراري",
                "label_fr": "Tarare"
            },
            {
                "value": 27364,
                "label": "Saint-Quentin-la-Poterie",
                "label_ar": "سان كوينتين لا بوتيري",
                "label_fr": "Saint-Quentin-la-Poterie"
            },
            {
                "value": 27365,
                "label": "Malaucene",
                "label_ar": "مالوسين",
                "label_fr": "Malaucène"
            },
            {
                "value": 27366,
                "label": "Locmiquelic",
                "label_ar": "لوكميكيليك",
                "label_fr": "Locmiquelic"
            },
            {
                "value": 27367,
                "label": "Sauteyrargues",
                "label_ar": "Sauteyrargues",
                "label_fr": "Sauteyrargues"
            },
            {
                "value": 27368,
                "label": "Saint-Genis-Pouilly",
                "label_ar": "سان جينيس بويي",
                "label_fr": "Saint-Genis-Pouilly"
            },
            {
                "value": 27369,
                "label": "Fareins",
                "label_ar": "أجرة",
                "label_fr": "Fareins"
            },
            {
                "value": 27370,
                "label": "Athies-sous-Laon",
                "label_ar": "أثيس سوس لاون",
                "label_fr": "Athies-sous-Laon"
            },
            {
                "value": 27371,
                "label": "Fresnoy-le-Grand",
                "label_ar": "فريسنوى لو جراند",
                "label_fr": "Fresnoy-le-Grand"
            },
            {
                "value": 27372,
                "label": "Bohain-en-Vermandois",
                "label_ar": "Bohain-en-Vermandois",
                "label_fr": "Bohain-en-Vermandois"
            },
            {
                "value": 27373,
                "label": "Effry",
                "label_ar": "افري",
                "label_fr": "Effry"
            },
            {
                "value": 27374,
                "label": "La Capelle",
                "label_ar": "لا كابيل",
                "label_fr": "La Capelle"
            },
            {
                "value": 27375,
                "label": "Eperlecques",
                "label_ar": "إبيرليك",
                "label_fr": "Éperlecques"
            },
            {
                "value": 27376,
                "label": "Landrethun-les-Ardres",
                "label_ar": "Landrethun-les-Ardres",
                "label_fr": "Landrethun-les-Ardres"
            },
            {
                "value": 27377,
                "label": "Ecouflant",
                "label_ar": "Ecouflant",
                "label_fr": "Écouflant"
            },
            {
                "value": 27378,
                "label": "Retiers",
                "label_ar": "المتقاعدون",
                "label_fr": "Retiers"
            },
            {
                "value": 27379,
                "label": "Jouques",
                "label_ar": "Jouques",
                "label_fr": "Jouques"
            },
            {
                "value": 27380,
                "label": "Alleins",
                "label_ar": "Alleins",
                "label_fr": "Alleins"
            },
            {
                "value": 27381,
                "label": "Thegra",
                "label_ar": "ثغرا",
                "label_fr": "Thegra"
            },
            {
                "value": 27382,
                "label": "Boutigny-sur-Essonne",
                "label_ar": "بوتيني سور إيسون",
                "label_fr": "Boutigny-sur-Essonne"
            },
            {
                "value": 27383,
                "label": "Mourmelon-le-Grand",
                "label_ar": "Mourmelon-le-Grand",
                "label_fr": "Mourmelon-le-Grand"
            },
            {
                "value": 27384,
                "label": "Joigny",
                "label_ar": "Joigny",
                "label_fr": "Joigny"
            },
            {
                "value": 27385,
                "label": "Peyruis",
                "label_ar": "بيروس",
                "label_fr": "Peyruis"
            },
            {
                "value": 27386,
                "label": "Mayet",
                "label_ar": "مايت",
                "label_fr": "Mayet"
            },
            {
                "value": 27387,
                "label": "Boulazac",
                "label_ar": "بولازاك",
                "label_fr": "Boulazac"
            },
            {
                "value": 27388,
                "label": "Orchaise",
                "label_ar": "Orchaise",
                "label_fr": "Orchaise"
            },
            {
                "value": 27389,
                "label": "Bignan",
                "label_ar": "بيجنان",
                "label_fr": "Bignan"
            },
            {
                "value": 27390,
                "label": "Ambon",
                "label_ar": "أمبون",
                "label_fr": "Ambon"
            },
            {
                "value": 27391,
                "label": "Bienvillers-au-Bois",
                "label_ar": "Bienvillers-au-Bois",
                "label_fr": "Bienvillers-au-Bois"
            },
            {
                "value": 27392,
                "label": "Briollay",
                "label_ar": "بريولاي",
                "label_fr": "Briollay"
            },
            {
                "value": 27393,
                "label": "Conde-en-Brie",
                "label_ar": "كوندي أون بري",
                "label_fr": "Condé-en-Brie"
            },
            {
                "value": 27394,
                "label": "Hagenthal-le-Haut",
                "label_ar": "هاجنثال لو أوت",
                "label_fr": "Hagenthal-le-Haut"
            },
            {
                "value": 27395,
                "label": "Thoiry",
                "label_ar": "Thoiry",
                "label_fr": "Thoiry"
            },
            {
                "value": 27396,
                "label": "Conques-sur-Orbiel",
                "label_ar": "Conques-sur-Orbiel",
                "label_fr": "Conques-sur-Orbiel"
            },
            {
                "value": 27397,
                "label": "Lescout",
                "label_ar": "ليسكوت",
                "label_fr": "Lescout"
            },
            {
                "value": 27398,
                "label": "Malaunay",
                "label_ar": "مالوناي",
                "label_fr": "Malaunay"
            },
            {
                "value": 27399,
                "label": "Trogues",
                "label_ar": "المداعب",
                "label_fr": "Trogues"
            },
            {
                "value": 27400,
                "label": "Sainte-Maure-de-Touraine",
                "label_ar": "سانت موري دي تورين",
                "label_fr": "Sainte-Maure-de-Touraine"
            },
            {
                "value": 27401,
                "label": "Abilly",
                "label_ar": "أبيلي",
                "label_fr": "Abilly"
            },
            {
                "value": 27402,
                "label": "Buxeuil",
                "label_ar": "بوكسويل",
                "label_fr": "Buxeuil"
            },
            {
                "value": 27403,
                "label": "Villebarou",
                "label_ar": "فيلبارو",
                "label_fr": "Villebarou"
            },
            {
                "value": 27404,
                "label": "Saint-Martin-du-Mont",
                "label_ar": "سان مارتن دو مونت",
                "label_fr": "Saint-Martin-du-Mont"
            },
            {
                "value": 27405,
                "label": "Grisolles",
                "label_ar": "جريسول",
                "label_fr": "Grisolles"
            },
            {
                "value": 27406,
                "label": "Chancenay",
                "label_ar": "Chancenay",
                "label_fr": "Chancenay"
            },
            {
                "value": 27407,
                "label": "Les Forges",
                "label_ar": "ليه فورجيس",
                "label_fr": "Les Forges"
            },
            {
                "value": 27408,
                "label": "Commercy",
                "label_ar": "التجارة",
                "label_fr": "Commercy"
            },
            {
                "value": 27409,
                "label": "Menil-la-Horgne",
                "label_ar": "مينيل لا هورجن",
                "label_fr": "Ménil-la-Horgne"
            },
            {
                "value": 27410,
                "label": "Ugny-sur-Meuse",
                "label_ar": "أوغني سور ميوز",
                "label_fr": "Ugny-sur-Meuse"
            },
            {
                "value": 27411,
                "label": "Vaucouleurs",
                "label_ar": "Vaucouleurs",
                "label_fr": "Vaucouleurs"
            },
            {
                "value": 27412,
                "label": "Chatillon",
                "label_ar": "شاتيلون",
                "label_fr": "Châtillon"
            },
            {
                "value": 27413,
                "label": "Laurens",
                "label_ar": "لورينز",
                "label_fr": "Laurens"
            },
            {
                "value": 27414,
                "label": "Pouzolles",
                "label_ar": "Pouzolles",
                "label_fr": "Pouzolles"
            },
            {
                "value": 27415,
                "label": "Neffies",
                "label_ar": "نفيس",
                "label_fr": "Neffies"
            },
            {
                "value": 27416,
                "label": "Rocheserviere",
                "label_ar": "روشيرفير",
                "label_fr": "Rocheservière"
            },
            {
                "value": 27417,
                "label": "Thure",
                "label_ar": "ثور",
                "label_fr": "Thure"
            },
            {
                "value": 27418,
                "label": "Roches-Premarie-Andille",
                "label_ar": "روشيه بريماري أنديل",
                "label_fr": "Roches-Premarie-Andille"
            },
            {
                "value": 27419,
                "label": "Saint-Maurice-la-Clouere",
                "label_ar": "سان موريس لا كلوير",
                "label_fr": "Saint-Maurice-la-Clouère"
            },
            {
                "value": 27420,
                "label": "Calleville",
                "label_ar": "كالفيل",
                "label_fr": "Calleville"
            },
            {
                "value": 27421,
                "label": "Lherm",
                "label_ar": "ليرم",
                "label_fr": "Lherm"
            },
            {
                "value": 27422,
                "label": "Rieux-de-Pelleport",
                "label_ar": "Rieux-de-Pelleport",
                "label_fr": "Rieux-de-Pelleport"
            },
            {
                "value": 27423,
                "label": "Barbentane",
                "label_ar": "باربنتان",
                "label_fr": "Barbentane"
            },
            {
                "value": 27424,
                "label": "Villars-en-Pons",
                "label_ar": "فيلارز أون بونس",
                "label_fr": "Villars-en-Pons"
            },
            {
                "value": 27425,
                "label": "Gemozac",
                "label_ar": "جموزاك",
                "label_fr": "Gémozac"
            },
            {
                "value": 27426,
                "label": "Carignan",
                "label_ar": "كارينيان",
                "label_fr": "Carignan"
            },
            {
                "value": 27427,
                "label": "Mouzon",
                "label_ar": "موزون",
                "label_fr": "Mouzon"
            },
            {
                "value": 27428,
                "label": "Issancourt-et-Rumel",
                "label_ar": "إيسانكورت وروميل",
                "label_fr": "Issancourt-et-Rumel"
            },
            {
                "value": 27429,
                "label": "Donchery",
                "label_ar": "Donchery",
                "label_fr": "Donchery"
            },
            {
                "value": 27430,
                "label": "Aÿ",
                "label_ar": "أ",
                "label_fr": "Aÿ"
            },
            {
                "value": 27431,
                "label": "Grauves",
                "label_ar": "قبور",
                "label_fr": "Grauves"
            },
            {
                "value": 27432,
                "label": "Ully-Saint-Georges",
                "label_ar": "يولي سان جورج",
                "label_fr": "Ully-Saint-Georges"
            },
            {
                "value": 27433,
                "label": "Vaumoise",
                "label_ar": "فومواز",
                "label_fr": "Vaumoise"
            },
            {
                "value": 27434,
                "label": "Saint-Hilaire-de-Loulay",
                "label_ar": "سان هيلير دي لولاي",
                "label_fr": "Saint-Hilaire-de-Loulay"
            },
            {
                "value": 27435,
                "label": "Dommartin",
                "label_ar": "دومارتين",
                "label_fr": "Dommartin"
            },
            {
                "value": 27436,
                "label": "Valdahon",
                "label_ar": "فالداهون",
                "label_fr": "Valdahon"
            },
            {
                "value": 27437,
                "label": "La Cluse-et-Mijoux",
                "label_ar": "لا كلوز وآخرون ميجو",
                "label_fr": "La Cluse-et-Mijoux"
            },
            {
                "value": 27438,
                "label": "Metabief",
                "label_ar": "متعب",
                "label_fr": "Metabief"
            },
            {
                "value": 27439,
                "label": "Jougne",
                "label_ar": "Jougne",
                "label_fr": "Jougne"
            },
            {
                "value": 27440,
                "label": "Thulay",
                "label_ar": "ثولاي",
                "label_fr": "Thulay"
            },
            {
                "value": 27441,
                "label": "Sort-en-Chalosse",
                "label_ar": "Sort-en-Chalosse",
                "label_fr": "Sort-en-Chalosse"
            },
            {
                "value": 27442,
                "label": "Gamarde-les-Bains",
                "label_ar": "Gamarde-les-Bains",
                "label_fr": "Gamarde-les-Bains"
            },
            {
                "value": 27443,
                "label": "Vayrac",
                "label_ar": "فايراك",
                "label_fr": "Vayrac"
            },
            {
                "value": 27444,
                "label": "Payrac",
                "label_ar": "Payrac",
                "label_fr": "Payrac"
            },
            {
                "value": 27445,
                "label": "Gourdon",
                "label_ar": "جوردون",
                "label_fr": "Gourdon"
            },
            {
                "value": 27446,
                "label": "Souillac",
                "label_ar": "سويلاك",
                "label_fr": "Souillac"
            },
            {
                "value": 27447,
                "label": "Robion",
                "label_ar": "روبيون",
                "label_fr": "Robion"
            },
            {
                "value": 27448,
                "label": "Serent",
                "label_ar": "سيرينت",
                "label_fr": "Serent"
            },
            {
                "value": 27449,
                "label": "Saint-Martin-de-Seignanx",
                "label_ar": "سان مارتن دي سينانكس",
                "label_fr": "Saint-Martin-de-Seignanx"
            },
            {
                "value": 27450,
                "label": "Dremil-Lafage",
                "label_ar": "دريميل لافاج",
                "label_fr": "Dremil-Lafage"
            },
            {
                "value": 27451,
                "label": "Saint-Paul-Trois-Chateaux",
                "label_ar": "سان بول تروا شاتو",
                "label_fr": "Saint-Paul-Trois-Châteaux"
            },
            {
                "value": 27452,
                "label": "Cavalaire-sur-Mer",
                "label_ar": "كافالير سور مير",
                "label_fr": "Cavalaire-sur-Mer"
            },
            {
                "value": 27453,
                "label": "Saix",
                "label_ar": "سايكس",
                "label_fr": "Saix"
            },
            {
                "value": 27454,
                "label": "Hérouville-Saint-Clair",
                "label_ar": "هيروفيل سانت كلير",
                "label_fr": "Hérouville-Saint-Clair"
            },
            {
                "value": 27455,
                "label": "Lavernose-Lacasse",
                "label_ar": "لافيرنوز لاكاس",
                "label_fr": "Lavernose-Lacasse"
            },
            {
                "value": 27456,
                "label": "Auvers-sur-Oise",
                "label_ar": "أوفير سور واز",
                "label_fr": "Auvers-sur-Oise"
            },
            {
                "value": 27457,
                "label": "Lorgues",
                "label_ar": "Lorgues",
                "label_fr": "Lorgues"
            },
            {
                "value": 27458,
                "label": "Excenevex",
                "label_ar": "Excenevex",
                "label_fr": "Excenevex"
            },
            {
                "value": 27459,
                "label": "Anthy-sur-Leman",
                "label_ar": "أنثي سور ليمان",
                "label_fr": "Anthy-sur-Léman"
            },
            {
                "value": 27460,
                "label": "Saint-Paul-en-Chablais",
                "label_ar": "سان بول إن شابلي",
                "label_fr": "Saint-Paul-en-Chablais"
            },
            {
                "value": 27461,
                "label": "Neuvecelle",
                "label_ar": "نيوفيسيل",
                "label_fr": "Neuvecelle"
            },
            {
                "value": 27462,
                "label": "Longessaigne",
                "label_ar": "لونجيسيني",
                "label_fr": "Longessaigne"
            },
            {
                "value": 27463,
                "label": "Biganos",
                "label_ar": "بيجانوس",
                "label_fr": "Biganos"
            },
            {
                "value": 27464,
                "label": "Couiza",
                "label_ar": "كويزا",
                "label_fr": "Couiza"
            },
            {
                "value": 27465,
                "label": "Beauregard-Baret",
                "label_ar": "بيوريجارد باريت",
                "label_fr": "Beauregard-Baret"
            },
            {
                "value": 27466,
                "label": "Canejan",
                "label_ar": "كانيجان",
                "label_fr": "Canejan"
            },
            {
                "value": 27467,
                "label": "Sierville",
                "label_ar": "سيرفيل",
                "label_fr": "Sierville"
            },
            {
                "value": 27468,
                "label": "Vatimesnil",
                "label_ar": "فاتيمسنيل",
                "label_fr": "Vatimesnil"
            },
            {
                "value": 27469,
                "label": "Breteuil",
                "label_ar": "بريتويل",
                "label_fr": "Breteuil"
            },
            {
                "value": 27470,
                "label": "Couville",
                "label_ar": "كوفيل",
                "label_fr": "Couville"
            },
            {
                "value": 27471,
                "label": "Heudebouville",
                "label_ar": "هيودبوفيل",
                "label_fr": "Heudebouville"
            },
            {
                "value": 27472,
                "label": "Saint-Samson-sur-Rance",
                "label_ar": "سان سامسون سور رانس",
                "label_fr": "Saint-Samson-sur-Rance"
            },
            {
                "value": 27473,
                "label": "Grand Champ",
                "label_ar": "جراند تشامب",
                "label_fr": "Grand Champ"
            },
            {
                "value": 27474,
                "label": "Aubigny-en-Plaine",
                "label_ar": "أوبيجني أون بلين",
                "label_fr": "Aubigny-en-Plaine"
            },
            {
                "value": 27475,
                "label": "Fargues-Saint-Hilaire",
                "label_ar": "فارجس سانت هيلير",
                "label_fr": "Fargues-Saint-Hilaire"
            },
            {
                "value": 27476,
                "label": "Orgelet",
                "label_ar": "Orgelet",
                "label_fr": "Orgelet"
            },
            {
                "value": 27477,
                "label": "Clairvaux-les-Lacs",
                "label_ar": "كليرفو ليه لاكس",
                "label_fr": "Clairvaux-les-Lacs"
            },
            {
                "value": 27478,
                "label": "Senozan",
                "label_ar": "سينوزان",
                "label_fr": "Senozan"
            },
            {
                "value": 27479,
                "label": "Truyes",
                "label_ar": "ترويس",
                "label_fr": "Truyes"
            },
            {
                "value": 27480,
                "label": "Esvres",
                "label_ar": "Esvres",
                "label_fr": "Esvres"
            },
            {
                "value": 27481,
                "label": "Marzan",
                "label_ar": "مرزان",
                "label_fr": "Marzan"
            },
            {
                "value": 27482,
                "label": "Laiz",
                "label_ar": "ليز",
                "label_fr": "Laiz"
            },
            {
                "value": 27483,
                "label": "Lignerolles",
                "label_ar": "Lignerolles",
                "label_fr": "Lignerolles"
            },
            {
                "value": 27484,
                "label": "Voujeaucourt",
                "label_ar": "فوجيوكورت",
                "label_fr": "Voujeaucourt"
            },
            {
                "value": 27485,
                "label": "Mamirolle",
                "label_ar": "ماميرول",
                "label_fr": "Mamirolle"
            },
            {
                "value": 27486,
                "label": "Villeron",
                "label_ar": "فيليرون",
                "label_fr": "Villeron"
            },
            {
                "value": 27487,
                "label": "Saint-Parize-le-Chatel",
                "label_ar": "سان باريز لو شاتيل",
                "label_fr": "Saint-Parize-le-Châtel"
            },
            {
                "value": 27488,
                "label": "Mondeville",
                "label_ar": "موندفيل",
                "label_fr": "Mondeville"
            },
            {
                "value": 27489,
                "label": "Salins-les-Bains",
                "label_ar": "سالينس ليه با",
                "label_fr": "Salins-les-Bains"
            },
            {
                "value": 27490,
                "label": "Obernai",
                "label_ar": "أوبيرناي",
                "label_fr": "Obernai"
            },
            {
                "value": 27491,
                "label": "Lisses",
                "label_ar": "ليسيس",
                "label_fr": "Lisses"
            },
            {
                "value": 27492,
                "label": "Figanieres",
                "label_ar": "فيجانيريس",
                "label_fr": "Figanières"
            },
            {
                "value": 27493,
                "label": "Le Plan-de-la-Tour",
                "label_ar": "لو بلان دي لا تور",
                "label_fr": "Le Plan-de-la-Tour"
            },
            {
                "value": 27494,
                "label": "Fontaines",
                "label_ar": "فونتين",
                "label_fr": "Fontaines"
            },
            {
                "value": 27495,
                "label": "La Membrolle-sur-Choisille",
                "label_ar": "لا Membrolle-sur-Choisille",
                "label_fr": "La Membrolle-sur-Choisille"
            },
            {
                "value": 27496,
                "label": "Saint-Antoine-du-Rocher",
                "label_ar": "سان انطوان دو روشيه",
                "label_fr": "Saint-Antoine-du-Rocher"
            },
            {
                "value": 27497,
                "label": "Ardentes",
                "label_ar": "المتحمسون",
                "label_fr": "Ardentes"
            },
            {
                "value": 27498,
                "label": "Frontenex",
                "label_ar": "فرونتنيكس",
                "label_fr": "Frontenex"
            },
            {
                "value": 27499,
                "label": "Attignat-Oncin",
                "label_ar": "أتيجنات أونسين",
                "label_fr": "Attignat-Oncin"
            },
            {
                "value": 27500,
                "label": "Dortan",
                "label_ar": "دورتان",
                "label_fr": "Dortan"
            },
            {
                "value": 27501,
                "label": "Briord",
                "label_ar": "بريورد",
                "label_fr": "Briord"
            },
            {
                "value": 27502,
                "label": "Sochaux",
                "label_ar": "سوشو",
                "label_fr": "Sochaux"
            },
            {
                "value": 27503,
                "label": "Baume-les-Dames",
                "label_ar": "بوم ليه دامس",
                "label_fr": "Baume-les-Dames"
            },
            {
                "value": 27504,
                "label": "Gueux",
                "label_ar": "جيو",
                "label_fr": "Gueux"
            },
            {
                "value": 27505,
                "label": "Albens",
                "label_ar": "ألبينز",
                "label_fr": "Albens"
            },
            {
                "value": 27506,
                "label": "Yport",
                "label_ar": "يبورت",
                "label_fr": "Yport"
            },
            {
                "value": 27507,
                "label": "Beuzeville",
                "label_ar": "Beuzeville",
                "label_fr": "Beuzeville"
            },
            {
                "value": 27508,
                "label": "Hussigny-Godbrange",
                "label_ar": "هوسيني جودبرانج",
                "label_fr": "Hussigny-Godbrange"
            },
            {
                "value": 27509,
                "label": "Neuville-les-Dieppe",
                "label_ar": "نيوفيل ليه دييب",
                "label_fr": "Neuville-les-Dieppe"
            },
            {
                "value": 27510,
                "label": "Nouvelle-Eglise",
                "label_ar": "نوفيل إجليز",
                "label_fr": "Nouvelle-Eglise"
            },
            {
                "value": 27511,
                "label": "Wierre-Effroy",
                "label_ar": "Wierre-Effroy",
                "label_fr": "Wierre-Effroy"
            },
            {
                "value": 27512,
                "label": "Malestroit",
                "label_ar": "ماليسترويت",
                "label_fr": "Malestroit"
            },
            {
                "value": 27513,
                "label": "Primarette",
                "label_ar": "بريماريت",
                "label_fr": "Primarette"
            },
            {
                "value": 27514,
                "label": "Pouilly-les-Nonains",
                "label_ar": "بويلي لي نون",
                "label_fr": "Pouilly-les-Nonains"
            },
            {
                "value": 27515,
                "label": "Mallemort",
                "label_ar": "ماليمورت",
                "label_fr": "Mallemort"
            },
            {
                "value": 27516,
                "label": "Vallon-Pont-d'Arc",
                "label_ar": "فالون بونت دارك",
                "label_fr": "Vallon-Pont-d'Arc"
            },
            {
                "value": 27517,
                "label": "Triors",
                "label_ar": "Triors",
                "label_fr": "Triors"
            },
            {
                "value": 27518,
                "label": "Mallemoisson",
                "label_ar": "ماليموسون",
                "label_fr": "Mallemoisson"
            },
            {
                "value": 27519,
                "label": "Varennes-sur-Loire",
                "label_ar": "فارين سور لوار",
                "label_fr": "Varennes-sur-Loire"
            },
            {
                "value": 27520,
                "label": "Levens",
                "label_ar": "ليفينز",
                "label_fr": "Levens"
            },
            {
                "value": 27521,
                "label": "Ribaute-les-Tavernes",
                "label_ar": "Ribaute-les-Tavernes",
                "label_fr": "Ribaute-les-Tavernes"
            },
            {
                "value": 27522,
                "label": "Sourcieux-les-Mines",
                "label_ar": "سورسيو ليه ماينز",
                "label_fr": "Sourcieux-les-Mines"
            },
            {
                "value": 27523,
                "label": "Saint-Pierre-la-Palud",
                "label_ar": "سان بيير لا بالود",
                "label_fr": "Saint-Pierre-la-Palud"
            },
            {
                "value": 27524,
                "label": "Bessenay",
                "label_ar": "بيسيني",
                "label_fr": "Bessenay"
            },
            {
                "value": 27525,
                "label": "Andilly",
                "label_ar": "أنديلي",
                "label_fr": "Andilly"
            },
            {
                "value": 27526,
                "label": "Penol",
                "label_ar": "بينول",
                "label_fr": "Penol"
            },
            {
                "value": 27527,
                "label": "Grussenheim",
                "label_ar": "جروسنهايم",
                "label_fr": "Grussenheim"
            },
            {
                "value": 27528,
                "label": "Dun-sur-Auron",
                "label_ar": "دون سور أورون",
                "label_fr": "Dun-sur-Auron"
            },
            {
                "value": 27529,
                "label": "Niederhausbergen",
                "label_ar": "نيدرهاوسبيرجين",
                "label_fr": "Niederhausbergen"
            },
            {
                "value": 27530,
                "label": "Longpre-les-Corps-Saints",
                "label_ar": "Longpre-les-Corps-Saints",
                "label_fr": "Longpré-les-Corps-Saints"
            },
            {
                "value": 27531,
                "label": "Clichy-sous-Bois",
                "label_ar": "كليشي سو بوا",
                "label_fr": "Clichy-sous-Bois"
            },
            {
                "value": 27532,
                "label": "Pommevic",
                "label_ar": "بوميفيتش",
                "label_fr": "Pommevic"
            },
            {
                "value": 27533,
                "label": "Gaas",
                "label_ar": "Gaas",
                "label_fr": "Gaas"
            },
            {
                "value": 27534,
                "label": "Langon",
                "label_ar": "لانجون",
                "label_fr": "Langon"
            },
            {
                "value": 27535,
                "label": "Villefranche-sur-Cher",
                "label_ar": "فيلفرانش سور شير",
                "label_fr": "Villefranche-sur-Cher"
            },
            {
                "value": 27536,
                "label": "Sedan",
                "label_ar": "سيدان",
                "label_fr": "Sedan"
            },
            {
                "value": 27537,
                "label": "Aigues-Mortes",
                "label_ar": "إيغ مورتس",
                "label_fr": "Aigues-Mortes"
            },
            {
                "value": 27538,
                "label": "Brain-sur-l'Authion",
                "label_ar": "برين سور لاوتيون",
                "label_fr": "Cerveau-sur-l'Authion"
            },
            {
                "value": 27539,
                "label": "La Bohalle",
                "label_ar": "لا بوهال",
                "label_fr": "La Bohalle"
            },
            {
                "value": 27540,
                "label": "Le May-sur-Evre",
                "label_ar": "لو ماي سور إيفري",
                "label_fr": "Le May-sur-Evre"
            },
            {
                "value": 27541,
                "label": "La Jubaudiere",
                "label_ar": "لا Jubaudiere",
                "label_fr": "La Jubaudière"
            },
            {
                "value": 27542,
                "label": "Champcueil",
                "label_ar": "Champcueil",
                "label_fr": "Champcueil"
            },
            {
                "value": 27543,
                "label": "Courances",
                "label_ar": "مراسم",
                "label_fr": "Courances"
            },
            {
                "value": 27544,
                "label": "Mauchamps",
                "label_ar": "ماوتشامب",
                "label_fr": "Mauchamps"
            },
            {
                "value": 27545,
                "label": "Goux-les-Dambelin",
                "label_ar": "Goux-les-Dambelin",
                "label_fr": "Goux-les-Dambelin"
            },
            {
                "value": 27546,
                "label": "Clerval",
                "label_ar": "كليفال",
                "label_fr": "Clerval"
            },
            {
                "value": 27547,
                "label": "Geney",
                "label_ar": "جيني",
                "label_fr": "Geney"
            },
            {
                "value": 27548,
                "label": "LIsle-sur-le-Doubs",
                "label_ar": "LIsle-sur-le-Doubs",
                "label_fr": "LIsle-sur-le-Doubs"
            },
            {
                "value": 27549,
                "label": "Quingey",
                "label_ar": "كوينجي",
                "label_fr": "Quingey"
            },
            {
                "value": 27550,
                "label": "Savigne-l'Eveque",
                "label_ar": "Savigne-l'Eveque",
                "label_fr": "Savigne-l'Évêque"
            },
            {
                "value": 27551,
                "label": "Marseilles-les-Aubigny",
                "label_ar": "مرسيليا ليه أوبيني",
                "label_fr": "Marseille-les-Aubigny"
            },
            {
                "value": 27552,
                "label": "Le Perray-en-Yvelines",
                "label_ar": "لو بيري أون إيفلين",
                "label_fr": "Le Perray-en-Yvelines"
            },
            {
                "value": 27553,
                "label": "Montaigut-sur-Save",
                "label_ar": "مونتايجوت سور سيف",
                "label_fr": "Montaigut-sur-Save"
            },
            {
                "value": 27554,
                "label": "L'Argentiere-la-Bessee",
                "label_ar": "L'Argentiere-la-Bessee",
                "label_fr": "L'Argentière-la-Bessée"
            },
            {
                "value": 27555,
                "label": "Puy-Saint-Andre",
                "label_ar": "بوي سانت أندريه",
                "label_fr": "Puy-Saint-André"
            },
            {
                "value": 27556,
                "label": "Jonquieres",
                "label_ar": "جونكيريس",
                "label_fr": "Jonquières"
            },
            {
                "value": 27557,
                "label": "Comps",
                "label_ar": "شركات",
                "label_fr": "Comps"
            },
            {
                "value": 27558,
                "label": "Millau",
                "label_ar": "ميلاو",
                "label_fr": "Millau"
            },
            {
                "value": 27559,
                "label": "Neuilly-Saint-Front",
                "label_ar": "نويي سانت فرونت",
                "label_fr": "Neuilly-Saint-Front"
            },
            {
                "value": 27560,
                "label": "Escoutoux",
                "label_ar": "إسكوتوكس",
                "label_fr": "Escoutoux"
            },
            {
                "value": 27561,
                "label": "Saint-Dizier",
                "label_ar": "سان ديزييه",
                "label_fr": "Saint-Dizier"
            },
            {
                "value": 27562,
                "label": "Saint-Jean-Bonnefonds",
                "label_ar": "سان جان بونيفوندس",
                "label_fr": "Saint-Jean-Bonnefonds"
            },
            {
                "value": 27563,
                "label": "Bons-en-Chablais",
                "label_ar": "بونس أون شابلي",
                "label_fr": "Bons-en-Chablais"
            },
            {
                "value": 27564,
                "label": "Le Rove",
                "label_ar": "لو روف",
                "label_fr": "Le Rove"
            },
            {
                "value": 27565,
                "label": "Saint-Zacharie",
                "label_ar": "القديس زكريا",
                "label_fr": "Saint-Zacharie"
            },
            {
                "value": 27566,
                "label": "Gommegnies",
                "label_ar": "Gommegnies",
                "label_fr": "Gommegnies"
            },
            {
                "value": 27567,
                "label": "Meynes",
                "label_ar": "مينيس",
                "label_fr": "Meynes"
            },
            {
                "value": 27568,
                "label": "Avensan",
                "label_ar": "أفينسان",
                "label_fr": "Avensan"
            },
            {
                "value": 27569,
                "label": "Parthenay-de-Bretagne",
                "label_ar": "بارثيناي دي بريتاني",
                "label_fr": "Parthenay-de-Bretagne"
            },
            {
                "value": 27570,
                "label": "Haspres",
                "label_ar": "هاسبريس",
                "label_fr": "Haspres"
            },
            {
                "value": 27571,
                "label": "Saint-Eliph",
                "label_ar": "سانت إيليف",
                "label_fr": "Saint-Eliph"
            },
            {
                "value": 27572,
                "label": "Saint-Genies-des-Mourgues",
                "label_ar": "سان جيني دي مورغ",
                "label_fr": "Saint-Genies-des-Mourgues"
            },
            {
                "value": 27573,
                "label": "Sainte-Lucie-de-Tallano",
                "label_ar": "سانت لوسي دي تالانو",
                "label_fr": "Sainte-Lucie-de-Tallano"
            },
            {
                "value": 27574,
                "label": "Saint-Maime",
                "label_ar": "سان مايم",
                "label_fr": "Saint-Maime"
            },
            {
                "value": 27575,
                "label": "Castries",
                "label_ar": "كاستريس",
                "label_fr": "Castries"
            },
            {
                "value": 27576,
                "label": "Vemars",
                "label_ar": "فيمار",
                "label_fr": "Vemars"
            },
            {
                "value": 27577,
                "label": "Missy-sur-Aisne",
                "label_ar": "ميسي سور أيسن",
                "label_fr": "Missy-sur-Aisne"
            },
            {
                "value": 27578,
                "label": "Venizel",
                "label_ar": "فينيزيل",
                "label_fr": "Venizel"
            },
            {
                "value": 27579,
                "label": "Blangy-sur-Bresle",
                "label_ar": "بلانجي سور بريسل",
                "label_fr": "Blangy-sur-Bresle"
            },
            {
                "value": 27580,
                "label": "Neufchatel-en-Bray",
                "label_ar": "نيوفشاتيل أون براي",
                "label_fr": "Neufchâtel-en-Bray"
            },
            {
                "value": 27581,
                "label": "Offranville",
                "label_ar": "أوفرانفيل",
                "label_fr": "Offranville"
            },
            {
                "value": 27582,
                "label": "Bressols",
                "label_ar": "بريسولس",
                "label_fr": "Bressols"
            },
            {
                "value": 27583,
                "label": "Freneuse",
                "label_ar": "فرينوز",
                "label_fr": "Freneuse"
            },
            {
                "value": 27584,
                "label": "Plombieres-les-Dijon",
                "label_ar": "بلومبييه ليه ديجون",
                "label_fr": "Plombières-les-Dijon"
            },
            {
                "value": 27585,
                "label": "Germainville",
                "label_ar": "جيرمينفيل",
                "label_fr": "Germainville"
            },
            {
                "value": 27586,
                "label": "Avanne-Aveney",
                "label_ar": "أفان أفيني",
                "label_fr": "Avanne-Aveney"
            },
            {
                "value": 27587,
                "label": "Pontarlier",
                "label_ar": "بونتارلير",
                "label_fr": "Pontarlier"
            },
            {
                "value": 27588,
                "label": "Arcon",
                "label_ar": "أركون",
                "label_fr": "Arcon"
            },
            {
                "value": 27589,
                "label": "Aniane",
                "label_ar": "أنيان",
                "label_fr": "Aniane"
            },
            {
                "value": 27590,
                "label": "Dieulouard",
                "label_ar": "ديولوارد",
                "label_fr": "Dieulouard"
            },
            {
                "value": 27591,
                "label": "Bruley",
                "label_ar": "برولي",
                "label_fr": "Bruley"
            },
            {
                "value": 27592,
                "label": "Saint-Gilles",
                "label_ar": "سان جيل",
                "label_fr": "Saint-Gilles"
            },
            {
                "value": 27593,
                "label": "Costaros",
                "label_ar": "كوستاروس",
                "label_fr": "Costaros"
            },
            {
                "value": 27594,
                "label": "Vals-pres-le-Puy",
                "label_ar": "فالس بريس لو بوي",
                "label_fr": "Vals-pres-le-Puy"
            },
            {
                "value": 27595,
                "label": "Le Grand Village",
                "label_ar": "لو جراند فيليدج",
                "label_fr": "Le Grand Village"
            },
            {
                "value": 27596,
                "label": "Labenne",
                "label_ar": "لابين",
                "label_fr": "Labenne"
            },
            {
                "value": 27597,
                "label": "Bourg-Achard",
                "label_ar": "بورغ آشارد",
                "label_fr": "Bourg-Achard"
            },
            {
                "value": 27598,
                "label": "Plouvien",
                "label_ar": "بلوفيان",
                "label_fr": "Plouvien"
            },
            {
                "value": 27599,
                "label": "Saint-Ouen-du-Tilleul",
                "label_ar": "Saint-Ouen-du-Tilleul",
                "label_fr": "Saint-Ouen-du-Tilleul"
            },
            {
                "value": 27600,
                "label": "Bosrobert",
                "label_ar": "بوسروبيرت",
                "label_fr": "Bosrobert"
            },
            {
                "value": 27601,
                "label": "Kunheim",
                "label_ar": "كونهايم",
                "label_fr": "Kunheim"
            },
            {
                "value": 27602,
                "label": "Sartène",
                "label_ar": "سارتين",
                "label_fr": "Sartène"
            },
            {
                "value": 27603,
                "label": "Propriano",
                "label_ar": "بروبريانو",
                "label_fr": "Propriano"
            },
            {
                "value": 27604,
                "label": "Goulet",
                "label_ar": "جولت",
                "label_fr": "Goulet"
            },
            {
                "value": 27605,
                "label": "Porticcio",
                "label_ar": "بورتيشيو",
                "label_fr": "Porticcio"
            },
            {
                "value": 27606,
                "label": "Servon",
                "label_ar": "سيرفون",
                "label_fr": "Servon"
            },
            {
                "value": 27607,
                "label": "Thenelles",
                "label_ar": "ثنيليس",
                "label_fr": "Thenelles"
            },
            {
                "value": 27608,
                "label": "Ansauvillers",
                "label_ar": "Ansauvillers",
                "label_fr": "Ansauvillers"
            },
            {
                "value": 27609,
                "label": "Salouel",
                "label_ar": "سالويل",
                "label_fr": "Salouel"
            },
            {
                "value": 27610,
                "label": "Noordpeene",
                "label_ar": "نوردبين",
                "label_fr": "Noordpeene"
            },
            {
                "value": 27611,
                "label": "Marsangy",
                "label_ar": "مارسانجي",
                "label_fr": "Marsangy"
            },
            {
                "value": 27612,
                "label": "Crochte",
                "label_ar": "كروشت",
                "label_fr": "Crochte"
            },
            {
                "value": 27613,
                "label": "Cappelle-Brouck",
                "label_ar": "كابيل بروك",
                "label_fr": "Cappelle-Brouck"
            },
            {
                "value": 27614,
                "label": "Luray",
                "label_ar": "لوراي",
                "label_fr": "Luray"
            },
            {
                "value": 27615,
                "label": "Vernouillet",
                "label_ar": "فيرنويلت",
                "label_fr": "Vernouillet"
            },
            {
                "value": 27616,
                "label": "Sainte-Colombe-sur-Guette",
                "label_ar": "Sainte-Colombe-sur-Guette",
                "label_fr": "Sainte-Colombe-sur-Guette"
            },
            {
                "value": 27617,
                "label": "La Digne-d'Amont",
                "label_ar": "لا Digne-d'Amont",
                "label_fr": "La Digne-d'Amont"
            },
            {
                "value": 27618,
                "label": "Margaux",
                "label_ar": "مارجو",
                "label_fr": "Margaux"
            },
            {
                "value": 27619,
                "label": "Rabastens-de-Bigorre",
                "label_ar": "Rabastens-de-Bigorre",
                "label_fr": "Rabastens-de-Bigorre"
            },
            {
                "value": 27620,
                "label": "Coufouleux",
                "label_ar": "كوفولو",
                "label_fr": "Coufouleux"
            },
            {
                "value": 27621,
                "label": "Saint-Savin",
                "label_ar": "سان سافين",
                "label_fr": "Saint-Savin"
            },
            {
                "value": 27622,
                "label": "Montmorillon",
                "label_ar": "مونتموريلون",
                "label_fr": "Montmorillon"
            },
            {
                "value": 27623,
                "label": "Origny-en-Thierache",
                "label_ar": "Origny-en-Thierache",
                "label_fr": "Origny-en-Thierache"
            },
            {
                "value": 27624,
                "label": "Rantigny",
                "label_ar": "رانتيجني",
                "label_fr": "Rantigny"
            },
            {
                "value": 27625,
                "label": "Vieillevigne",
                "label_ar": "فيليفين",
                "label_fr": "Vieillevigne"
            },
            {
                "value": 27626,
                "label": "Argentan",
                "label_ar": "أرجنتين",
                "label_fr": "Argentan"
            },
            {
                "value": 27627,
                "label": "Gace",
                "label_ar": "جيس",
                "label_fr": "Gace"
            },
            {
                "value": 27628,
                "label": "Rai",
                "label_ar": "راي",
                "label_fr": "Rai"
            },
            {
                "value": 27629,
                "label": "L'Aigle",
                "label_ar": "لايجل",
                "label_fr": "L'Aigle"
            },
            {
                "value": 27630,
                "label": "Faucompierre",
                "label_ar": "فوكومبير",
                "label_fr": "Faucompierre"
            },
            {
                "value": 27631,
                "label": "Seine-Port",
                "label_ar": "سين بورت",
                "label_fr": "Seine-Port"
            },
            {
                "value": 27632,
                "label": "Cagny",
                "label_ar": "كاجني",
                "label_fr": "Cagny"
            },
            {
                "value": 27633,
                "label": "Guiclan",
                "label_ar": "جويكلان",
                "label_fr": "Guiclan"
            },
            {
                "value": 27634,
                "label": "Saint-Bres",
                "label_ar": "سان بريس",
                "label_fr": "Saint-Bres"
            },
            {
                "value": 27635,
                "label": "Village-Neuf",
                "label_ar": "قرية نيوف",
                "label_fr": "Village-Neuf"
            },
            {
                "value": 27636,
                "label": "Saint-Louis",
                "label_ar": "سانت لويس",
                "label_fr": "Saint Louis"
            },
            {
                "value": 27637,
                "label": "Embrun",
                "label_ar": "إمبرون",
                "label_fr": "Embrun"
            },
            {
                "value": 27638,
                "label": "Sibiril",
                "label_ar": "سيبيريل",
                "label_fr": "Sibiril"
            },
            {
                "value": 27639,
                "label": "Vignoc",
                "label_ar": "Vignoc",
                "label_fr": "Vignoc"
            },
            {
                "value": 27640,
                "label": "Auboue",
                "label_ar": "أوبوي",
                "label_fr": "Auboue"
            },
            {
                "value": 27641,
                "label": "Homecourt",
                "label_ar": "هومكورت",
                "label_fr": "Homecourt"
            },
            {
                "value": 27642,
                "label": "Vinca",
                "label_ar": "فينكا",
                "label_fr": "Vinca"
            },
            {
                "value": 27643,
                "label": "Clerieux",
                "label_ar": "كليريوكس",
                "label_fr": "Clérieux"
            },
            {
                "value": 27644,
                "label": "Witry-les-Reims",
                "label_ar": "ويتري ليه ريمس",
                "label_fr": "Witry-les-Reims"
            },
            {
                "value": 27645,
                "label": "Aincourt",
                "label_ar": "اينكور",
                "label_fr": "Aincourt"
            },
            {
                "value": 27646,
                "label": "Meulan-en-Yvelines",
                "label_ar": "ميولان أون إيفلين",
                "label_fr": "Meulan-en-Yvelines"
            },
            {
                "value": 27647,
                "label": "Montalet-le-Bois",
                "label_ar": "مونتاليه لو بوا",
                "label_fr": "Montalet-le-Bois"
            },
            {
                "value": 27648,
                "label": "Oinville-sur-Montcient",
                "label_ar": "أوينفيل سور مونتينينت",
                "label_fr": "Oinville-sur-Montcient"
            },
            {
                "value": 27649,
                "label": "Noyers-Bocage",
                "label_ar": "نويرز بوكاج",
                "label_fr": "Noyers-Bocage"
            },
            {
                "value": 27650,
                "label": "Châtillon-sur-Seine",
                "label_ar": "شاتيلون سور سين",
                "label_fr": "Châtillon-sur-Seine"
            },
            {
                "value": 27651,
                "label": "Malain",
                "label_ar": "مالين",
                "label_fr": "Malain"
            },
            {
                "value": 27652,
                "label": "La Rochette",
                "label_ar": "لاروشيت",
                "label_fr": "La Rochette"
            },
            {
                "value": 27653,
                "label": "Olonzac",
                "label_ar": "أولونزاك",
                "label_fr": "Olonzac"
            },
            {
                "value": 27654,
                "label": "Pepieux",
                "label_ar": "بيبيوكس",
                "label_fr": "Pépieux"
            },
            {
                "value": 27655,
                "label": "Haironville",
                "label_ar": "هيرونفيل",
                "label_fr": "Haironville"
            },
            {
                "value": 27656,
                "label": "Calvisson",
                "label_ar": "كالفيسون",
                "label_fr": "Calvisson"
            },
            {
                "value": 27657,
                "label": "Milhaud",
                "label_ar": "ميلهود",
                "label_fr": "Milhaud"
            },
            {
                "value": 27658,
                "label": "Vestric-et-Candiac",
                "label_ar": "Vestric-et-Candiac",
                "label_fr": "Vestric-et-Candiac"
            },
            {
                "value": 27659,
                "label": "Vauvert",
                "label_ar": "فوفيرت",
                "label_fr": "Vauvert"
            },
            {
                "value": 27660,
                "label": "Croisilles",
                "label_ar": "كروازيل",
                "label_fr": "Croisilles"
            },
            {
                "value": 27661,
                "label": "Samatan",
                "label_ar": "ساماتان",
                "label_fr": "Samatan"
            },
            {
                "value": 27662,
                "label": "Bapaume",
                "label_ar": "بابوم",
                "label_fr": "Bapaume"
            },
            {
                "value": 27663,
                "label": "Bucquoy",
                "label_ar": "بوكوي",
                "label_fr": "Bucquoy"
            },
            {
                "value": 27664,
                "label": "Lagardelle-sur-Leze",
                "label_ar": "لاغارديل سور ليز",
                "label_fr": "Lagardelle-sur-Leze"
            },
            {
                "value": 27665,
                "label": "Metzeral",
                "label_ar": "ميتسيرال",
                "label_fr": "Metzeral"
            },
            {
                "value": 27666,
                "label": "Fraize",
                "label_ar": "هيج",
                "label_fr": "Fraize"
            },
            {
                "value": 27667,
                "label": "Bruyères",
                "label_ar": "برويير",
                "label_fr": "Bruyères"
            },
            {
                "value": 27668,
                "label": "Gray",
                "label_ar": "اللون الرمادي",
                "label_fr": "gris"
            },
            {
                "value": 27669,
                "label": "Brussey",
                "label_ar": "بروسي",
                "label_fr": "Brussey"
            },
            {
                "value": 27670,
                "label": "Villers-le-Lac",
                "label_ar": "فيليرز لو لاك",
                "label_fr": "Villers-le-Lac"
            },
            {
                "value": 27671,
                "label": "Ansac-sur-Vienne",
                "label_ar": "أنساك سور فيين",
                "label_fr": "Ansac-sur-Vienne"
            },
            {
                "value": 27672,
                "label": "Damparis",
                "label_ar": "دامباريس",
                "label_fr": "Damparis"
            },
            {
                "value": 27673,
                "label": "Saint-Paul-en-Foret",
                "label_ar": "سانت بول أون فوريه",
                "label_fr": "Saint-Paul-en-Foret"
            },
            {
                "value": 27674,
                "label": "Maisnil",
                "label_ar": "ميسنيل",
                "label_fr": "Maisnil"
            },
            {
                "value": 27675,
                "label": "Oresmaux",
                "label_ar": "اوريسمو",
                "label_fr": "Oresmaux"
            },
            {
                "value": 27676,
                "label": "Moreuil",
                "label_ar": "مورويل",
                "label_fr": "Moreuil"
            },
            {
                "value": 27677,
                "label": "Hombleux",
                "label_ar": "هومبلوكس",
                "label_fr": "Hombleux"
            },
            {
                "value": 27678,
                "label": "Neuf-Brisach",
                "label_ar": "نيوف بريساش",
                "label_fr": "Neuf-Brisach"
            },
            {
                "value": 27679,
                "label": "Tournan-en-Brie",
                "label_ar": "تورنان أون بري",
                "label_fr": "Tournan-en-Brie"
            },
            {
                "value": 27680,
                "label": "Livry-sur-Seine",
                "label_ar": "ليفري سور سين",
                "label_fr": "Livry-sur-Seine"
            },
            {
                "value": 27681,
                "label": "Doullens",
                "label_ar": "دولينز",
                "label_fr": "Doullens"
            },
            {
                "value": 27682,
                "label": "Palau-del-Vidre",
                "label_ar": "بالاو ديل فيدر",
                "label_fr": "Palau-del-Vidre"
            },
            {
                "value": 27683,
                "label": "Arpaillargues-et-Aureillac",
                "label_ar": "Arpaillargues-et-Aureillac",
                "label_fr": "Arpaillargues-et-Aureillac"
            },
            {
                "value": 27684,
                "label": "Trebes",
                "label_ar": "تريبس",
                "label_fr": "Trebes"
            },
            {
                "value": 27685,
                "label": "Arc-sur-Tille",
                "label_ar": "قوس سور تيل",
                "label_fr": "Arc-sur-Tille"
            },
            {
                "value": 27686,
                "label": "Touville",
                "label_ar": "توفيل",
                "label_fr": "Touville"
            },
            {
                "value": 27687,
                "label": "Iville",
                "label_ar": "ايفيل",
                "label_fr": "Iville"
            },
            {
                "value": 27688,
                "label": "Riorges",
                "label_ar": "ريورج",
                "label_fr": "Riorges"
            },
            {
                "value": 27689,
                "label": "Varois-et-Chaignot",
                "label_ar": "Varois-et-Chaignot",
                "label_fr": "Varois-et-Chaignot"
            },
            {
                "value": 27690,
                "label": "Etevaux",
                "label_ar": "إتيفاوكس",
                "label_fr": "Etevaux"
            },
            {
                "value": 27691,
                "label": "Binges",
                "label_ar": "الشراهة",
                "label_fr": "Frénésie"
            },
            {
                "value": 27692,
                "label": "Amfreville-la-Mi-Voie",
                "label_ar": "أمفريفيل لا مي فوي",
                "label_fr": "Amfreville-la-Mi-Voie"
            },
            {
                "value": 27693,
                "label": "Belbeuf",
                "label_ar": "بلبوف",
                "label_fr": "Belbeuf"
            },
            {
                "value": 27694,
                "label": "Avelin",
                "label_ar": "أفلين",
                "label_fr": "Avelin"
            },
            {
                "value": 27695,
                "label": "Carmaux",
                "label_ar": "كارمو",
                "label_fr": "Carmaux"
            },
            {
                "value": 27696,
                "label": "Le Garric",
                "label_ar": "لو جاريك",
                "label_fr": "Le Garric"
            },
            {
                "value": 27697,
                "label": "Javene",
                "label_ar": "جافين",
                "label_fr": "Javene"
            },
            {
                "value": 27698,
                "label": "Cobrieux",
                "label_ar": "كوبريوكس",
                "label_fr": "Cobrieux"
            },
            {
                "value": 27699,
                "label": "Trausse",
                "label_ar": "تراوس",
                "label_fr": "Trausse"
            },
            {
                "value": 27700,
                "label": "Bury",
                "label_ar": "دفن",
                "label_fr": "Enterrer"
            },
            {
                "value": 27701,
                "label": "San-Nicolao",
                "label_ar": "سان نيكولاو",
                "label_fr": "San-Nicolao"
            },
            {
                "value": 27702,
                "label": "Folelli",
                "label_ar": "فوليلي",
                "label_fr": "Folelli"
            },
            {
                "value": 27703,
                "label": "Prunelli-di-Fiumorbo",
                "label_ar": "Prunelli-di-Fiumorbo",
                "label_fr": "Prunelli-di-Fiumorbo"
            },
            {
                "value": 27704,
                "label": "Ghisonaccia",
                "label_ar": "غيسوناتشيا",
                "label_fr": "Ghisonaccia"
            },
            {
                "value": 27705,
                "label": "Fondettes",
                "label_ar": "فونديت",
                "label_fr": "Fondettes"
            },
            {
                "value": 27706,
                "label": "Maureillas-las-Illas",
                "label_ar": "Maureillas-las-Illas",
                "label_fr": "Maureillas-las-Illas"
            },
            {
                "value": 27707,
                "label": "Brives-Charensac",
                "label_ar": "بريفز شارينساك",
                "label_fr": "Brives-Charensac"
            },
            {
                "value": 27708,
                "label": "Blavozy",
                "label_ar": "بلافوزي",
                "label_fr": "Blavozy"
            },
            {
                "value": 27709,
                "label": "Porcelette",
                "label_ar": "بورسيليت",
                "label_fr": "Porcelette"
            },
            {
                "value": 27710,
                "label": "Sainte-Florine",
                "label_ar": "سانت فلورين",
                "label_fr": "Sainte-Florine"
            },
            {
                "value": 27711,
                "label": "Saint-Tropez",
                "label_ar": "سان تروبيه",
                "label_fr": "Saint Tropez"
            },
            {
                "value": 27712,
                "label": "Riedseltz",
                "label_ar": "ريدسلتز",
                "label_fr": "Riedseltz"
            },
            {
                "value": 27713,
                "label": "Ecault",
                "label_ar": "ايكولت",
                "label_fr": "Ecault"
            },
            {
                "value": 27714,
                "label": "Saint-Etienne-au-Mont",
                "label_ar": "سانت إتيان أو مونت",
                "label_fr": "Saint-Étienne-au-Mont"
            },
            {
                "value": 27715,
                "label": "Selongey",
                "label_ar": "سيلونجي",
                "label_fr": "Selongey"
            },
            {
                "value": 27716,
                "label": "Semblancay",
                "label_ar": "سيمبلانكاي",
                "label_fr": "Semblancay"
            },
            {
                "value": 27717,
                "label": "Rinxent",
                "label_ar": "Rinxent",
                "label_fr": "Rinxent"
            },
            {
                "value": 27718,
                "label": "Tilques",
                "label_ar": "تيلكويس",
                "label_fr": "Tilques"
            },
            {
                "value": 27719,
                "label": "Serques",
                "label_ar": "سيركيس",
                "label_fr": "Serques"
            },
            {
                "value": 27720,
                "label": "Thiant",
                "label_ar": "ثيانت",
                "label_fr": "Thiant"
            },
            {
                "value": 27721,
                "label": "Bousies",
                "label_ar": "بنس",
                "label_fr": "Bousies"
            },
            {
                "value": 27722,
                "label": "Le Cateau-Cambresis",
                "label_ar": "لو كاتو كامبريسيس",
                "label_fr": "Le Cateau-Cambresis"
            },
            {
                "value": 27723,
                "label": "Jenlain",
                "label_ar": "جينلين",
                "label_fr": "Jenlain"
            },
            {
                "value": 27724,
                "label": "Mondeville",
                "label_ar": "موندفيل",
                "label_fr": "Mondeville"
            },
            {
                "value": 27725,
                "label": "Coltainville",
                "label_ar": "كولتينفيل",
                "label_fr": "Coltainville"
            },
            {
                "value": 27726,
                "label": "Saint-Prest",
                "label_ar": "سانت بيرست",
                "label_fr": "Saint-Prest"
            },
            {
                "value": 27727,
                "label": "Saone",
                "label_ar": "ساون",
                "label_fr": "Saône"
            },
            {
                "value": 27728,
                "label": "Roches-les-Blamont",
                "label_ar": "روشيه ليه بلامونت",
                "label_fr": "Roches-les-Blamont"
            },
            {
                "value": 27729,
                "label": "Saint-Emilion",
                "label_ar": "سانت اميليون",
                "label_fr": "Saint-Emilion"
            },
            {
                "value": 27730,
                "label": "Aniche",
                "label_ar": "مكانة",
                "label_fr": "Une niche"
            },
            {
                "value": 27731,
                "label": "Ecaillon",
                "label_ar": "إيكيلون",
                "label_fr": "Ecaillon"
            },
            {
                "value": 27732,
                "label": "Verlinghem",
                "label_ar": "فيرلينجهيم",
                "label_fr": "Verlinghem"
            },
            {
                "value": 27733,
                "label": "Watten",
                "label_ar": "واتين",
                "label_fr": "Watten"
            },
            {
                "value": 27734,
                "label": "Marconnelle",
                "label_ar": "ماركونيل",
                "label_fr": "Marconnelle"
            },
            {
                "value": 27735,
                "label": "Moragne",
                "label_ar": "موراني",
                "label_fr": "Moragne"
            },
            {
                "value": 27736,
                "label": "Nesle",
                "label_ar": "نيسل",
                "label_fr": "Nesle"
            },
            {
                "value": 27737,
                "label": "Chambry",
                "label_ar": "شامبري",
                "label_fr": "Chambry"
            },
            {
                "value": 27738,
                "label": "Goncelin",
                "label_ar": "جونسيلين",
                "label_fr": "Goncelin"
            },
            {
                "value": 27739,
                "label": "Saint-Pierre-les-Elbeuf",
                "label_ar": "سان بيير ليس إلبوف",
                "label_fr": "Saint-Pierre-les-Elbeuf"
            },
            {
                "value": 27740,
                "label": "Saint-Vincent-de-Paul",
                "label_ar": "سانت فنسنت دي بول",
                "label_fr": "Saint-Vincent-de-Paul"
            },
            {
                "value": 27741,
                "label": "Lignan-sur-Orb",
                "label_ar": "ليجنان سور أورب",
                "label_fr": "Lignan-sur-Orb"
            },
            {
                "value": 27742,
                "label": "Lieuran-les-Beziers",
                "label_ar": "ليوران ليه بيزيه",
                "label_fr": "Lieuran-les-Béziers"
            },
            {
                "value": 27743,
                "label": "Bassan",
                "label_ar": "بسان",
                "label_fr": "Bassan"
            },
            {
                "value": 27744,
                "label": "Oudon",
                "label_ar": "أودون",
                "label_fr": "Oudon"
            },
            {
                "value": 27745,
                "label": "Saffre",
                "label_ar": "سافري",
                "label_fr": "Saffre"
            },
            {
                "value": 27746,
                "label": "Voissant",
                "label_ar": "فواسون",
                "label_fr": "Voissant"
            },
            {
                "value": 27747,
                "label": "Samois-sur-Seine",
                "label_ar": "ساموا سور سين",
                "label_fr": "Samois-sur-Seine"
            },
            {
                "value": 27748,
                "label": "Coublevie",
                "label_ar": "كوبليفي",
                "label_fr": "Coublevie"
            },
            {
                "value": 27749,
                "label": "Haveluy",
                "label_ar": "هافلوي",
                "label_fr": "Haveluy"
            },
            {
                "value": 27750,
                "label": "Benfeld",
                "label_ar": "بينفيلد",
                "label_fr": "Benfeld"
            },
            {
                "value": 27751,
                "label": "Bar-sur-Seine",
                "label_ar": "بار سور سين",
                "label_fr": "Bar-sur-Seine"
            },
            {
                "value": 27752,
                "label": "Varennes-sur-Allier",
                "label_ar": "فارين سور أليير",
                "label_fr": "Varennes-sur-Allier"
            },
            {
                "value": 27753,
                "label": "Bricquebec",
                "label_ar": "بريكيبيك",
                "label_fr": "Bricquebec"
            },
            {
                "value": 27754,
                "label": "Alenya",
                "label_ar": "الينيا",
                "label_fr": "Alenya"
            },
            {
                "value": 27755,
                "label": "Magescq",
                "label_ar": "ماجيسك",
                "label_fr": "Magescq"
            },
            {
                "value": 27756,
                "label": "Saint-Sever",
                "label_ar": "سان سيفر",
                "label_fr": "Saint-Sever"
            },
            {
                "value": 27757,
                "label": "Bonchamp-les-Laval",
                "label_ar": "Bonchamp-les-Laval",
                "label_fr": "Bonchamp-les-Laval"
            },
            {
                "value": 27758,
                "label": "Chevru",
                "label_ar": "شيفرو",
                "label_fr": "Chevru"
            },
            {
                "value": 27759,
                "label": "Cauge",
                "label_ar": "المقياس",
                "label_fr": "Cauge"
            },
            {
                "value": 27760,
                "label": "Bellegarde",
                "label_ar": "بيلجارد",
                "label_fr": "Bellegarde"
            },
            {
                "value": 27761,
                "label": "Lorris",
                "label_ar": "لوريس",
                "label_fr": "Lorris"
            },
            {
                "value": 27762,
                "label": "Pourrain",
                "label_ar": "صب",
                "label_fr": "Pourrain"
            },
            {
                "value": 27763,
                "label": "Brienon-sur-Armancon",
                "label_ar": "برينون سور أرمانكون",
                "label_fr": "Brienon-sur-Armançon"
            },
            {
                "value": 27764,
                "label": "Izeron",
                "label_ar": "ايزيرون",
                "label_fr": "Izeron"
            },
            {
                "value": 27765,
                "label": "Lembach",
                "label_ar": "ليمباتش",
                "label_fr": "Lembach"
            },
            {
                "value": 27766,
                "label": "Saint-Lambert-du-Lattay",
                "label_ar": "سان لامبرت دو لاتاي",
                "label_fr": "Saint-Lambert-du-Lattay"
            },
            {
                "value": 27767,
                "label": "Brains",
                "label_ar": "العقول",
                "label_fr": "Cerveaux"
            },
            {
                "value": 27768,
                "label": "Venarey-les-Laumes",
                "label_ar": "Venarey-les-Laumes",
                "label_fr": "Venarey-les-Laumes"
            },
            {
                "value": 27769,
                "label": "Villars-et-Villenotte",
                "label_ar": "Villars-et-Villenotte",
                "label_fr": "Villars-et-Villenotte"
            },
            {
                "value": 27770,
                "label": "Geste",
                "label_ar": "جيستي",
                "label_fr": "Geste"
            },
            {
                "value": 27771,
                "label": "Fonsorbes",
                "label_ar": "فونسوربس",
                "label_fr": "Fonsorbes"
            },
            {
                "value": 27772,
                "label": "Beyrie-en-Bearn",
                "label_ar": "بيري إن بيرن",
                "label_fr": "Beyrie-en-Bearn"
            },
            {
                "value": 27773,
                "label": "Aurillac",
                "label_ar": "أوريلاك",
                "label_fr": "Aurillac"
            },
            {
                "value": 27774,
                "label": "Sainte-Livrade-sur-Lot",
                "label_ar": "سانت ليفراد سور لوت",
                "label_fr": "Sainte-Livrade-sur-Lot"
            },
            {
                "value": 27775,
                "label": "Deulemont",
                "label_ar": "ديوليمون",
                "label_fr": "Deulemont"
            },
            {
                "value": 27776,
                "label": "Lachapelle-sous-Rougemont",
                "label_ar": "لاشابيل سو روجيمونت",
                "label_fr": "Lachapelle-sous-Rougemont"
            },
            {
                "value": 27777,
                "label": "Locoal-Mendon",
                "label_ar": "لوكوال ميندون",
                "label_fr": "Locoal-Mendon"
            },
            {
                "value": 27778,
                "label": "Babeau-Bouldoux",
                "label_ar": "بابو بولدو",
                "label_fr": "Babeau-Bouldoux"
            },
            {
                "value": 27779,
                "label": "Ducey",
                "label_ar": "دوسي",
                "label_fr": "Ducey"
            },
            {
                "value": 27780,
                "label": "Noyers-sur-Cher",
                "label_ar": "نوييرز سور شير",
                "label_fr": "Noyers-sur-Cher"
            },
            {
                "value": 27781,
                "label": "Mareuil-sur-Cher",
                "label_ar": "مريل سور شير",
                "label_fr": "Mareuil-sur-Cher"
            },
            {
                "value": 27782,
                "label": "Hauteville-sur-Mer",
                "label_ar": "أوتفيل سور مير",
                "label_fr": "Hauteville-sur-Mer"
            },
            {
                "value": 27783,
                "label": "Benouville",
                "label_ar": "بينوفيل",
                "label_fr": "Benouville"
            },
            {
                "value": 27784,
                "label": "Font-Romeu-Odeillo-Via",
                "label_ar": "Font-Romeu-Odeillo-Via",
                "label_fr": "Font-Romeu-Odeillo-Via"
            },
            {
                "value": 27785,
                "label": "Roisel",
                "label_ar": "رويسيل",
                "label_fr": "Roisel"
            },
            {
                "value": 27786,
                "label": "Quarouble",
                "label_ar": "Quarouble",
                "label_fr": "Quarouble"
            },
            {
                "value": 27787,
                "label": "Onnaing",
                "label_ar": "أونينج",
                "label_fr": "Onnaing"
            },
            {
                "value": 27788,
                "label": "Rosoy-en-Multien",
                "label_ar": "روسوي أون مولتين",
                "label_fr": "Rosoy-en-Multien"
            },
            {
                "value": 27789,
                "label": "La Sentinelle",
                "label_ar": "لا سنتينيل",
                "label_fr": "La Sentinelle"
            },
            {
                "value": 27790,
                "label": "Epone",
                "label_ar": "Epone",
                "label_fr": "Epone"
            },
            {
                "value": 27791,
                "label": "Saint-Coulitz",
                "label_ar": "سان كوليتز",
                "label_fr": "Saint-Coulitz"
            },
            {
                "value": 27792,
                "label": "Villerville",
                "label_ar": "فيرفيل",
                "label_fr": "Villerville"
            },
            {
                "value": 27793,
                "label": "L'Huisserie",
                "label_ar": "L'Huisserie",
                "label_fr": "L'Huisserie"
            },
            {
                "value": 27794,
                "label": "Albefeuille-Lagarde",
                "label_ar": "البيفويل لاغارد",
                "label_fr": "Albefeuille-Lagarde"
            },
            {
                "value": 27795,
                "label": "Fontaine-Notre-Dame",
                "label_ar": "فونتين نوتردام",
                "label_fr": "Fontaine-Notre-Dame"
            },
            {
                "value": 27796,
                "label": "Niergnies",
                "label_ar": "نيرجنيس",
                "label_fr": "Niergnies"
            },
            {
                "value": 27797,
                "label": "Neuville-Saint-Remy",
                "label_ar": "نوفيل سانت ريمي",
                "label_fr": "Neuville-Saint-Rémy"
            },
            {
                "value": 27798,
                "label": "Saint-Cyprien",
                "label_ar": "سان سيبريان",
                "label_fr": "Saint-Cyprien"
            },
            {
                "value": 27799,
                "label": "Saint-Nazaire",
                "label_ar": "سان نازير",
                "label_fr": "Saint-Nazaire"
            },
            {
                "value": 27800,
                "label": "Vilallonga dels Monts",
                "label_ar": "Vilallonga dels Monts",
                "label_fr": "Vilallonga dels Monts"
            },
            {
                "value": 27801,
                "label": "Manduel",
                "label_ar": "ماندويل",
                "label_fr": "Manduel"
            },
            {
                "value": 27802,
                "label": "Tauriac",
                "label_ar": "تورياك",
                "label_fr": "Tauriac"
            },
            {
                "value": 27803,
                "label": "Andilly",
                "label_ar": "أنديلي",
                "label_fr": "Andilly"
            },
            {
                "value": 27804,
                "label": "Maxilly-sur-Saone",
                "label_ar": "Maxilly-sur-Saone",
                "label_fr": "Maxilly-sur-Saône"
            },
            {
                "value": 27805,
                "label": "Sorigny",
                "label_ar": "سوريني",
                "label_fr": "Sorigny"
            },
            {
                "value": 27806,
                "label": "Azay-sur-Cher",
                "label_ar": "أزاي سور شير",
                "label_fr": "Azay-sur-Cher"
            },
            {
                "value": 27807,
                "label": "Bourgbarre",
                "label_ar": "بورجباري",
                "label_fr": "Bourgbarre"
            },
            {
                "value": 27808,
                "label": "Neufchateau",
                "label_ar": "نيوفشاتو",
                "label_fr": "Neufchâteau"
            },
            {
                "value": 27809,
                "label": "Saint-Christophe-sur-Roc",
                "label_ar": "سان كريستوف سور روك",
                "label_fr": "Saint-Christophe-sur-Roc"
            },
            {
                "value": 27810,
                "label": "Montebourg",
                "label_ar": "مونتيبورج",
                "label_fr": "Montebourg"
            },
            {
                "value": 27811,
                "label": "Ventiseri",
                "label_ar": "فينتيسيري",
                "label_fr": "Ventiseri"
            },
            {
                "value": 27812,
                "label": "Saint-Vaast-la-Hougue",
                "label_ar": "سان فاست لا هوج",
                "label_fr": "Saint-Vaast-la-Hougue"
            },
            {
                "value": 27813,
                "label": "Fontaine-Notre-Dame",
                "label_ar": "فونتين نوتردام",
                "label_fr": "Fontaine-Notre-Dame"
            },
            {
                "value": 27814,
                "label": "Pontaubault",
                "label_ar": "بونتاوبولت",
                "label_fr": "Pontaubault"
            },
            {
                "value": 27815,
                "label": "Saint-Gaultier",
                "label_ar": "سان جوتييه",
                "label_fr": "Saint-Gaultier"
            },
            {
                "value": 27816,
                "label": "Pitres",
                "label_ar": "بيتر",
                "label_fr": "Pitres"
            },
            {
                "value": 27817,
                "label": "Alizay",
                "label_ar": "أليزاي",
                "label_fr": "Alizay"
            },
            {
                "value": 27818,
                "label": "Romilly-sur-Andelle",
                "label_ar": "روميلي سور أنديل",
                "label_fr": "Romilly-sur-Andelle"
            },
            {
                "value": 27819,
                "label": "Amfreville-sous-les-Monts",
                "label_ar": "أمفريفيل سو ليه مونت",
                "label_fr": "Amfreville-sous-les-Monts"
            },
            {
                "value": 27820,
                "label": "Charleval",
                "label_ar": "شارليفال",
                "label_fr": "Charleval"
            },
            {
                "value": 27821,
                "label": "Les Andelys",
                "label_ar": "ليه أنديليس",
                "label_fr": "Les Andelys"
            },
            {
                "value": 27822,
                "label": "Lyons-la-Forêt",
                "label_ar": "ليونز لا فوريت",
                "label_fr": "Lyons-la-Forêt"
            },
            {
                "value": 27823,
                "label": "Saint-Pierre-du-Vauvray",
                "label_ar": "سان بيير دو فوفراي",
                "label_fr": "Saint-Pierre-du-Vauvray"
            },
            {
                "value": 27824,
                "label": "Ande",
                "label_ar": "أندي",
                "label_fr": "Ande"
            },
            {
                "value": 27825,
                "label": "Vic-sur-Aisne",
                "label_ar": "فيك سور أيسن",
                "label_fr": "Vic-sur-Aisne"
            },
            {
                "value": 27826,
                "label": "Authevernes",
                "label_ar": "اوثيفيرنس",
                "label_fr": "Authevernes"
            },
            {
                "value": 27827,
                "label": "Bueil",
                "label_ar": "بويل",
                "label_fr": "Bueil"
            },
            {
                "value": 27828,
                "label": "Noveant-sur-Moselle",
                "label_ar": "نوفينت سور موسيل",
                "label_fr": "Noveant-sur-Moselle"
            },
            {
                "value": 27829,
                "label": "La Garenne",
                "label_ar": "لا غارين",
                "label_fr": "La Garenne"
            },
            {
                "value": 27830,
                "label": "Villard-de-Lans",
                "label_ar": "فيلارد دي لانس",
                "label_fr": "Villard-de-Lans"
            },
            {
                "value": 27831,
                "label": "Saint-Martin-de-Re",
                "label_ar": "سان مارتن دي ري",
                "label_fr": "Saint-Martin-de-Ré"
            },
            {
                "value": 27832,
                "label": "Ars-en-Re",
                "label_ar": "Ars-en-Re",
                "label_fr": "Ars-en-Re"
            },
            {
                "value": 27833,
                "label": "Tourly",
                "label_ar": "بجولة",
                "label_fr": "Tourly"
            },
            {
                "value": 27834,
                "label": "Graveson",
                "label_ar": "جريفسون",
                "label_fr": "Graveson"
            },
            {
                "value": 27835,
                "label": "Anjoutey",
                "label_ar": "أنجوتي",
                "label_fr": "Anjoutey"
            },
            {
                "value": 27836,
                "label": "Sainte-Helene",
                "label_ar": "سانت هيلين",
                "label_fr": "Sainte-Hélène"
            },
            {
                "value": 27837,
                "label": "Saint-Privat-la-Montagne",
                "label_ar": "سان بريفات لا مونتاني",
                "label_fr": "Saint-Privat-la-Montagne"
            },
            {
                "value": 27838,
                "label": "Amanvillers",
                "label_ar": "Amanvillers",
                "label_fr": "Amanvillers"
            },
            {
                "value": 27839,
                "label": "Evrecy",
                "label_ar": "إيفريسي",
                "label_fr": "Evrecy"
            },
            {
                "value": 27840,
                "label": "Amaye-sur-Orne",
                "label_ar": "أماي سور أورني",
                "label_fr": "Amaye-sur-Orne"
            },
            {
                "value": 27841,
                "label": "Esquay-Notre-Dame",
                "label_ar": "إسكوي نوتردام",
                "label_fr": "Esquay-Notre-Dame"
            },
            {
                "value": 27842,
                "label": "Brouay",
                "label_ar": "بروي",
                "label_fr": "Brouay"
            },
            {
                "value": 27843,
                "label": "Exireuil",
                "label_ar": "إكسيرويل",
                "label_fr": "Exireuil"
            },
            {
                "value": 27844,
                "label": "Les Aubiers",
                "label_ar": "لي أوبير",
                "label_fr": "Les Aubiers"
            },
            {
                "value": 27845,
                "label": "Mirebeau",
                "label_ar": "ميريبو",
                "label_fr": "Mirebeau"
            },
            {
                "value": 27846,
                "label": "Lencloitre",
                "label_ar": "لينكلويتر",
                "label_fr": "Lencloitre"
            },
            {
                "value": 27847,
                "label": "Le Cailar",
                "label_ar": "لو كايلار",
                "label_fr": "Le Cailar"
            },
            {
                "value": 27848,
                "label": "Aimargues",
                "label_ar": "ايمارج",
                "label_fr": "Aimargues"
            },
            {
                "value": 27849,
                "label": "Codognan",
                "label_ar": "كودوجنان",
                "label_fr": "Codognan"
            },
            {
                "value": 27850,
                "label": "Vergeze",
                "label_ar": "حافز",
                "label_fr": "Vergeze"
            },
            {
                "value": 27851,
                "label": "Mus",
                "label_ar": "المصحف",
                "label_fr": "Mus"
            },
            {
                "value": 27852,
                "label": "Bertry",
                "label_ar": "بيرتري",
                "label_fr": "Bertry"
            },
            {
                "value": 27853,
                "label": "Saint-Denis-de-Cabanne",
                "label_ar": "سان دينيس دي كابان",
                "label_fr": "Saint-Denis-de-Cabanne"
            },
            {
                "value": 27854,
                "label": "Charlieu",
                "label_ar": "تشارليو",
                "label_fr": "Charlieu"
            },
            {
                "value": 27855,
                "label": "Blere",
                "label_ar": "بلير",
                "label_fr": "Blere"
            },
            {
                "value": 27856,
                "label": "Meru",
                "label_ar": "ميرو",
                "label_fr": "Meru"
            },
            {
                "value": 27857,
                "label": "Les Rosiers",
                "label_ar": "ليه روزيرس",
                "label_fr": "Les Rosiers"
            },
            {
                "value": 27858,
                "label": "Saint-Remy",
                "label_ar": "سانت ريمي",
                "label_fr": "Saint-Rémy"
            },
            {
                "value": 27859,
                "label": "Marsannay-la-Côte",
                "label_ar": "مارساني لا كوت",
                "label_fr": "Marsannay-la-Côte"
            },
            {
                "value": 27860,
                "label": "Perrigny-les-Dijon",
                "label_ar": "بيريني ليه ديجون",
                "label_fr": "Perrigny-les-Dijon"
            },
            {
                "value": 27861,
                "label": "Poisat",
                "label_ar": "Poisat",
                "label_fr": "Poisat"
            },
            {
                "value": 27862,
                "label": "Villefranche-sur-Mer",
                "label_ar": "فيلفرانش سور مير",
                "label_fr": "Villefranche-sur-Mer"
            },
            {
                "value": 27863,
                "label": "Beuvrages",
                "label_ar": "بيوفراج",
                "label_fr": "Beuvrages"
            },
            {
                "value": 27864,
                "label": "Feyzin",
                "label_ar": "فيزين",
                "label_fr": "Feyzin"
            },
            {
                "value": 27865,
                "label": "Lasalle",
                "label_ar": "لا سال",
                "label_fr": "La salle"
            },
            {
                "value": 27866,
                "label": "Montferrat",
                "label_ar": "مونتفيرات",
                "label_fr": "Montferrat"
            },
            {
                "value": 27867,
                "label": "Evans",
                "label_ar": "إيفانز",
                "label_fr": "Evans"
            },
            {
                "value": 27868,
                "label": "Etuz",
                "label_ar": "ايتوز",
                "label_fr": "Etuz"
            },
            {
                "value": 27869,
                "label": "La Roquebrussanne",
                "label_ar": "لاروكيبروسان",
                "label_fr": "La Roquebrussanne"
            },
            {
                "value": 27870,
                "label": "Lannemezan",
                "label_ar": "لانيميزان",
                "label_fr": "Lannemezan"
            },
            {
                "value": 27871,
                "label": "La Salvetat-Saint-Gilles",
                "label_ar": "La Salvetat-Saint-Gilles",
                "label_fr": "La Salvetat-Saint-Gilles"
            },
            {
                "value": 27872,
                "label": "La Salvetat-Lauragais",
                "label_ar": "La Salvetat-Lauragais",
                "label_fr": "La Salvetat-Lauragais"
            },
            {
                "value": 27873,
                "label": "Fontenilles",
                "label_ar": "Fontenilles",
                "label_fr": "Fontenilles"
            },
            {
                "value": 27874,
                "label": "Restinclieres",
                "label_ar": "يستنبط",
                "label_fr": "Restinclieres"
            },
            {
                "value": 27875,
                "label": "Eymet",
                "label_ar": "ايمت",
                "label_fr": "Eymet"
            },
            {
                "value": 27876,
                "label": "Seilhac",
                "label_ar": "Seilhac",
                "label_fr": "Seilhac"
            },
            {
                "value": 27877,
                "label": "Muizon",
                "label_ar": "Muizon",
                "label_fr": "Muizon"
            },
            {
                "value": 27878,
                "label": "Villemandeur",
                "label_ar": "فيلماندير",
                "label_fr": "Villemandeur"
            },
            {
                "value": 27879,
                "label": "Malataverne",
                "label_ar": "مالاتافيرن",
                "label_fr": "Malataverne"
            },
            {
                "value": 27880,
                "label": "Espeluche",
                "label_ar": "Espeluche",
                "label_fr": "Espeluche"
            },
            {
                "value": 27881,
                "label": "Plesse",
                "label_ar": "بليس",
                "label_fr": "Plesse"
            },
            {
                "value": 27882,
                "label": "Calas",
                "label_ar": "كالاس",
                "label_fr": "Calas"
            },
            {
                "value": 27883,
                "label": "Saint-Paul-les-Durance",
                "label_ar": "سانت بول ليس دورانس",
                "label_fr": "Saint-Paul-les-Durance"
            },
            {
                "value": 27884,
                "label": "Trans-en-Provence",
                "label_ar": "ترانس أون بروفانس",
                "label_fr": "Trans-en-Provence"
            },
            {
                "value": 27885,
                "label": "Meyenheim",
                "label_ar": "مينهايم",
                "label_fr": "Meyenheim"
            },
            {
                "value": 27886,
                "label": "Saint-Mars-la-Jaille",
                "label_ar": "سان مارس لا جيل",
                "label_fr": "Saint-Mars-la-Jaille"
            },
            {
                "value": 27887,
                "label": "Saint-Gereon",
                "label_ar": "سان جيرون",
                "label_fr": "Saint-Géréon"
            },
            {
                "value": 27888,
                "label": "Canly",
                "label_ar": "كانلي",
                "label_fr": "Canly"
            },
            {
                "value": 27889,
                "label": "Jaux",
                "label_ar": "جوكس",
                "label_fr": "Jaux"
            },
            {
                "value": 27890,
                "label": "Rupt",
                "label_ar": "تمزق",
                "label_fr": "Rupt"
            },
            {
                "value": 27891,
                "label": "Casson",
                "label_ar": "كاسون",
                "label_fr": "Casson"
            },
            {
                "value": 27892,
                "label": "Tracy-le-Val",
                "label_ar": "تريسي لو فال",
                "label_fr": "Tracy-le-Val"
            },
            {
                "value": 27893,
                "label": "Attichy",
                "label_ar": "أتيشي",
                "label_fr": "Attichy"
            },
            {
                "value": 27894,
                "label": "Ermenonville",
                "label_ar": "ارمينونفيل",
                "label_fr": "Ermenonville"
            },
            {
                "value": 27895,
                "label": "Geudertheim",
                "label_ar": "جيوديرثيم",
                "label_fr": "Geudertheim"
            },
            {
                "value": 27896,
                "label": "Noailles",
                "label_ar": "نويل",
                "label_fr": "Noailles"
            },
            {
                "value": 27897,
                "label": "Auteuil",
                "label_ar": "Auteuil",
                "label_fr": "Auteuil"
            },
            {
                "value": 27898,
                "label": "Loos-en-Gohelle",
                "label_ar": "Loos-en-Gohelle",
                "label_fr": "Loos-en-Gohelle"
            },
            {
                "value": 27899,
                "label": "Tigery",
                "label_ar": "تيغري",
                "label_fr": "Tigery"
            },
            {
                "value": 27900,
                "label": "Etiolles",
                "label_ar": "إتيولس",
                "label_fr": "Etiolles"
            },
            {
                "value": 27901,
                "label": "Juvisy-sur-Orge",
                "label_ar": "Juvisy-sur-Orge",
                "label_fr": "Juvisy-sur-Orge"
            },
            {
                "value": 27902,
                "label": "Villers-Saint-Paul",
                "label_ar": "فيليرز سانت بول",
                "label_fr": "Villers-Saint-Paul"
            },
            {
                "value": 27903,
                "label": "Saint-Just-en-Chaussee",
                "label_ar": "سان جوست أون تشاوسي",
                "label_fr": "Saint-Just-en-Chaussée"
            },
            {
                "value": 27904,
                "label": "Breuil-le-Vert",
                "label_ar": "بريويل لو فيرت",
                "label_fr": "Breuil-le-Vert"
            },
            {
                "value": 27905,
                "label": "Châteaudun",
                "label_ar": "شاتودون",
                "label_fr": "Châteaudun"
            },
            {
                "value": 27906,
                "label": "Horbourg-Wihr",
                "label_ar": "هوربورغ وير",
                "label_fr": "Horbourg-Wihr"
            },
            {
                "value": 27907,
                "label": "Soisy-sous-Montmorency",
                "label_ar": "Soisy-sous-Montmorency",
                "label_fr": "Soisy-sous-Montmorency"
            },
            {
                "value": 27908,
                "label": "Chaponnay",
                "label_ar": "شابوناي",
                "label_fr": "Chaponnay"
            },
            {
                "value": 27909,
                "label": "Marennes",
                "label_ar": "مارينز",
                "label_fr": "Marennes"
            },
            {
                "value": 27910,
                "label": "Heyrieux",
                "label_ar": "هيريو",
                "label_fr": "Heyrieux"
            },
            {
                "value": 27911,
                "label": "Villeneuve-Tolosane",
                "label_ar": "فيلنوف تولوسان",
                "label_fr": "Villeneuve-Tolosane"
            },
            {
                "value": 27912,
                "label": "Saint-Julien-du-Puy",
                "label_ar": "سان جوليان دو بوي",
                "label_fr": "Saint-Julien-du-Puy"
            },
            {
                "value": 27913,
                "label": "Saint-Pal-de-Mons",
                "label_ar": "سان بال دي مون",
                "label_fr": "Saint-Pal-de-Mons"
            },
            {
                "value": 27914,
                "label": "La Seauve-sur-Semene",
                "label_ar": "لا سيف سور سيمين",
                "label_fr": "La Seauve-sur-Semène"
            },
            {
                "value": 27915,
                "label": "Houlgate",
                "label_ar": "هولجيت",
                "label_fr": "Houlgate"
            },
            {
                "value": 27916,
                "label": "Villers-sur-Mer",
                "label_ar": "فيليرز سور مير",
                "label_fr": "Villers-sur-Mer"
            },
            {
                "value": 27917,
                "label": "Marchiennes",
                "label_ar": "مارشين",
                "label_fr": "Marchiennes"
            },
            {
                "value": 27918,
                "label": "Vred",
                "label_ar": "فريد",
                "label_fr": "Vred"
            },
            {
                "value": 27919,
                "label": "Warlaing",
                "label_ar": "حرب",
                "label_fr": "Warlaing"
            },
            {
                "value": 27920,
                "label": "Donneville",
                "label_ar": "دونفيل",
                "label_fr": "Donneville"
            },
            {
                "value": 27921,
                "label": "Bruguieres",
                "label_ar": "Bruguieres",
                "label_fr": "Bruguières"
            },
            {
                "value": 27922,
                "label": "Gratentour",
                "label_ar": "جراتينتور",
                "label_fr": "Gratentour"
            },
            {
                "value": 27923,
                "label": "Saint-Jory",
                "label_ar": "سان جوري",
                "label_fr": "Saint-Jory"
            },
            {
                "value": 27924,
                "label": "Fronton",
                "label_ar": "فرونتون",
                "label_fr": "Fronton"
            },
            {
                "value": 27925,
                "label": "Castelnau-d'Estretefonds",
                "label_ar": "Castelnau-d'Estretefonds",
                "label_fr": "Castelnau-d'Estretefonds"
            },
            {
                "value": 27926,
                "label": "Mirepoix-sur-Tarn",
                "label_ar": "ميريبويكس سور تارن",
                "label_fr": "Mirepoix-sur-Tarn"
            },
            {
                "value": 27927,
                "label": "Franois",
                "label_ar": "فرانسوا",
                "label_fr": "François"
            },
            {
                "value": 27928,
                "label": "Pirey",
                "label_ar": "بيري",
                "label_fr": "Pirey"
            },
            {
                "value": 27929,
                "label": "Serre-les-Sapins",
                "label_ar": "سيري ليه سابينز",
                "label_fr": "Serre-les-Sapins"
            },
            {
                "value": 27930,
                "label": "Mazerolles-le-Salin",
                "label_ar": "Mazerolles-le-Salin",
                "label_fr": "Mazerolles-le-Salin"
            },
            {
                "value": 27931,
                "label": "Peronne-en-Melantois",
                "label_ar": "بيرون أون ميلانتوا",
                "label_fr": "Péronne-en-Mélantois"
            },
            {
                "value": 27932,
                "label": "Bornel",
                "label_ar": "بورنيل",
                "label_fr": "Bornel"
            },
            {
                "value": 27933,
                "label": "Saint-Leger-en-Bray",
                "label_ar": "سان ليجيه أون براي",
                "label_fr": "Saint-Léger-en-Bray"
            },
            {
                "value": 27934,
                "label": "Chaumont-en-Vexin",
                "label_ar": "شومون إن فيكسين",
                "label_fr": "Chaumont-en-Vexin"
            },
            {
                "value": 27935,
                "label": "Trie-Chateau",
                "label_ar": "تري شاتو",
                "label_fr": "Trie-Château"
            },
            {
                "value": 27936,
                "label": "Paillart",
                "label_ar": "بيلارت",
                "label_fr": "Paillart"
            },
            {
                "value": 27937,
                "label": "Breteuil",
                "label_ar": "بريتويل",
                "label_fr": "Breteuil"
            },
            {
                "value": 27938,
                "label": "Meuse",
                "label_ar": "ميوز",
                "label_fr": "Meuse"
            },
            {
                "value": 27939,
                "label": "Longfosse",
                "label_ar": "لونجفوس",
                "label_fr": "Longfosse"
            },
            {
                "value": 27940,
                "label": "Ardoix",
                "label_ar": "أردويكس",
                "label_fr": "Ardoix"
            },
            {
                "value": 27941,
                "label": "Montferrand-le-Chateau",
                "label_ar": "مونتفيراند لو شاتو",
                "label_fr": "Montferrand-le-Château"
            },
            {
                "value": 27942,
                "label": "Boussieres",
                "label_ar": "بوسيريس",
                "label_fr": "Boussières"
            },
            {
                "value": 27943,
                "label": "Thoraise",
                "label_ar": "ثورايز",
                "label_fr": "Thoraise"
            },
            {
                "value": 27944,
                "label": "Saint-Jean-Brevelay",
                "label_ar": "سان جان بريفيلاي",
                "label_fr": "Saint-Jean-Brevelay"
            },
            {
                "value": 27945,
                "label": "Aubin",
                "label_ar": "أوبين",
                "label_fr": "Aubin"
            },
            {
                "value": 27946,
                "label": "Pfaffenhoffen",
                "label_ar": "بفافينهوفن",
                "label_fr": "Pfaffenhoffen"
            },
            {
                "value": 27947,
                "label": "Niedermodern",
                "label_ar": "نيدرمودرن",
                "label_fr": "Niedermodern"
            },
            {
                "value": 27948,
                "label": "Obermodern-Zutzendorf",
                "label_ar": "Obermodern-Zutzendorf",
                "label_fr": "Obermodern-Zutzendorf"
            },
            {
                "value": 27949,
                "label": "Zutzendorf",
                "label_ar": "زوتزيندورف",
                "label_fr": "Zutzendorf"
            },
            {
                "value": 27950,
                "label": "Saint-Cyr-en-Val",
                "label_ar": "سان سير إن فال",
                "label_fr": "Saint-Cyr-en-Val"
            },
            {
                "value": 27951,
                "label": "Oberbronn",
                "label_ar": "أوبربرون",
                "label_fr": "Oberbronn"
            },
            {
                "value": 27952,
                "label": "Goersdorf",
                "label_ar": "جويرسدورف",
                "label_fr": "Goersdorf"
            },
            {
                "value": 27953,
                "label": "Langensoultzbach",
                "label_ar": "لانجنسولتزباخ",
                "label_fr": "Langensoultzbach"
            },
            {
                "value": 27954,
                "label": "Morsbronn-les-Bains",
                "label_ar": "مورسبرون ليه با",
                "label_fr": "Morsbronn-les-Bains"
            },
            {
                "value": 27955,
                "label": "Louverne",
                "label_ar": "لوفيرن",
                "label_fr": "Louverne"
            },
            {
                "value": 27956,
                "label": "Mauriac",
                "label_ar": "مورياك",
                "label_fr": "Mauriac"
            },
            {
                "value": 27957,
                "label": "Nery",
                "label_ar": "نيري",
                "label_fr": "Nery"
            },
            {
                "value": 27958,
                "label": "Bethisy-Saint-Pierre",
                "label_ar": "Bethisy-Saint-Pierre",
                "label_fr": "Bethisy-Saint-Pierre"
            },
            {
                "value": 27959,
                "label": "Holtzwihr",
                "label_ar": "هولتزفير",
                "label_fr": "Holtzwihr"
            },
            {
                "value": 27960,
                "label": "Seilh",
                "label_ar": "صيله",
                "label_fr": "Seilh"
            },
            {
                "value": 27961,
                "label": "Trignac",
                "label_ar": "تريغناك",
                "label_fr": "Trignac"
            },
            {
                "value": 27962,
                "label": "Uxem",
                "label_ar": "اوكسيم",
                "label_fr": "Uxem"
            },
            {
                "value": 27963,
                "label": "Sain-Bel",
                "label_ar": "سين بل",
                "label_fr": "Sain-Bel"
            },
            {
                "value": 27964,
                "label": "Aubergenville",
                "label_ar": "اوبرجينفيل",
                "label_fr": "Aubergenville"
            },
            {
                "value": 27965,
                "label": "Mareuil-sur-Ourcq",
                "label_ar": "مريل سور أورك",
                "label_fr": "Mareuil-sur-Ourcq"
            },
            {
                "value": 27966,
                "label": "Barbechat",
                "label_ar": "شواء",
                "label_fr": "Barbechat"
            },
            {
                "value": 27967,
                "label": "Briare",
                "label_ar": "برياري",
                "label_fr": "Briare"
            },
            {
                "value": 27968,
                "label": "Ambutrix",
                "label_ar": "أمبوتريكس",
                "label_fr": "Ambutrix"
            },
            {
                "value": 27969,
                "label": "Mery-sur-Oise",
                "label_ar": "ميري سور وايز",
                "label_fr": "Mery-sur-Oise"
            },
            {
                "value": 27970,
                "label": "Seppois-le-Bas",
                "label_ar": "سيبوا لو باس",
                "label_fr": "Seppois-le-Bas"
            },
            {
                "value": 27971,
                "label": "Lognes",
                "label_ar": "لوجنيس",
                "label_fr": "Lognes"
            },
            {
                "value": 27972,
                "label": "Raismes",
                "label_ar": "Raismes",
                "label_fr": "Raismes"
            },
            {
                "value": 27973,
                "label": "Saulnot",
                "label_ar": "شاولوت",
                "label_fr": "Saulnot"
            },
            {
                "value": 27974,
                "label": "Bouresse",
                "label_ar": "بوريس",
                "label_fr": "Bouresse"
            },
            {
                "value": 27975,
                "label": "Granges-le-Bourg",
                "label_ar": "جرانج لو بور",
                "label_fr": "Granges-le-Bourg"
            },
            {
                "value": 27976,
                "label": "Villersexel",
                "label_ar": "فيليرسكسيل",
                "label_fr": "Villersexel"
            },
            {
                "value": 27977,
                "label": "Vesoul",
                "label_ar": "فيسول",
                "label_fr": "Vesoul"
            },
            {
                "value": 27978,
                "label": "Vaivre-et-Montoille",
                "label_ar": "Vaivre-et-Montoille",
                "label_fr": "Vaivre-et-Montoille"
            },
            {
                "value": 27979,
                "label": "Luxeuil-les-Bains",
                "label_ar": "Luxeuil-les-Bains",
                "label_fr": "Luxeuil-les-Bains"
            },
            {
                "value": 27980,
                "label": "Aillevillers-et-Lyaumont",
                "label_ar": "Aillevillers-et-Lyaumont",
                "label_fr": "Aillevillers-et-Lyaumont"
            },
            {
                "value": 27981,
                "label": "La Bassee",
                "label_ar": "لا باسسي",
                "label_fr": "La Bassee"
            },
            {
                "value": 27982,
                "label": "Salome",
                "label_ar": "سالومي",
                "label_fr": "Salomé"
            },
            {
                "value": 27983,
                "label": "Epouville",
                "label_ar": "إيبوفيل",
                "label_fr": "Epouville"
            },
            {
                "value": 27984,
                "label": "Arnas",
                "label_ar": "أرناس",
                "label_fr": "Arnas"
            },
            {
                "value": 27985,
                "label": "Equihen-Plage",
                "label_ar": "Equihen-Plage",
                "label_fr": "Equihen-Plage"
            },
            {
                "value": 27986,
                "label": "Cervens",
                "label_ar": "سرفنز",
                "label_fr": "Cervens"
            },
            {
                "value": 27987,
                "label": "Mortefontaine",
                "label_ar": "مورتيفونتين",
                "label_fr": "Mortefontaine"
            },
            {
                "value": 27988,
                "label": "Saint-Pantaleon-de-Larche",
                "label_ar": "Saint-Pantaleon-de-Larche",
                "label_fr": "Saint-Pantaléon-de-Larche"
            },
            {
                "value": 27989,
                "label": "Chantrans",
                "label_ar": "Chantrans",
                "label_fr": "Chantrans"
            },
            {
                "value": 27990,
                "label": "Levier",
                "label_ar": "ليفير",
                "label_fr": "Levier"
            },
            {
                "value": 27991,
                "label": "Gemenos",
                "label_ar": "جيمينوس",
                "label_fr": "Gémenos"
            },
            {
                "value": 27992,
                "label": "Doubs",
                "label_ar": "دوبس",
                "label_fr": "Doubs"
            },
            {
                "value": 27993,
                "label": "Le Vigan",
                "label_ar": "لو فيغان",
                "label_fr": "Le Vigan"
            },
            {
                "value": 27994,
                "label": "Aveze",
                "label_ar": "أفيزي",
                "label_fr": "Aveze"
            },
            {
                "value": 27995,
                "label": "Ville-du-Pont",
                "label_ar": "فيل دو بونت",
                "label_fr": "Ville-du-Pont"
            },
            {
                "value": 27996,
                "label": "Sarlat-la-Canéda",
                "label_ar": "Sarlat-la-Canéda",
                "label_fr": "Sarlat-la-Canéda"
            },
            {
                "value": 27997,
                "label": "Gilley",
                "label_ar": "جيلي",
                "label_fr": "Gilley"
            },
            {
                "value": 27998,
                "label": "Schwenheim",
                "label_ar": "شفينهايم",
                "label_fr": "Schwenheim"
            },
            {
                "value": 27999,
                "label": "Les Fins",
                "label_ar": "ليه فينس",
                "label_fr": "Les Fins"
            },
            {
                "value": 28000,
                "label": "Flangebouche",
                "label_ar": "فلانجبوش",
                "label_fr": "Flangebouche"
            },
            {
                "value": 28001,
                "label": "Caissargues",
                "label_ar": "قيسارج",
                "label_fr": "Caissargues"
            },
            {
                "value": 28002,
                "label": "Grosbliederstroff",
                "label_ar": "جروسبليدرستروف",
                "label_fr": "Grosbliederstroff"
            },
            {
                "value": 28003,
                "label": "Hambach",
                "label_ar": "هامباخ",
                "label_fr": "Hambach"
            },
            {
                "value": 28004,
                "label": "Sarralbe",
                "label_ar": "سارالب",
                "label_fr": "Sarralbe"
            },
            {
                "value": 28005,
                "label": "Niderviller",
                "label_ar": "نيديرفيلر",
                "label_fr": "Niderviller"
            },
            {
                "value": 28006,
                "label": "Saulxures-sur-Moselotte",
                "label_ar": "شاولكسور سور موسيلوت",
                "label_fr": "Saulxures-sur-Moselotte"
            },
            {
                "value": 28007,
                "label": "Rohrbach-les-Bitche",
                "label_ar": "Rohrbach-les-Bitche",
                "label_fr": "Rohrbach-les-Bitche"
            },
            {
                "value": 28008,
                "label": "Bitche",
                "label_ar": "بيتشي",
                "label_fr": "Bitche"
            },
            {
                "value": 28009,
                "label": "Domene",
                "label_ar": "دومين",
                "label_fr": "Domene"
            },
            {
                "value": 28010,
                "label": "La Destrousse",
                "label_ar": "لا ديستروس",
                "label_fr": "La Destrousse"
            },
            {
                "value": 28011,
                "label": "La Bouilladisse",
                "label_ar": "La Bouilladisse",
                "label_fr": "La Bouilladisse"
            },
            {
                "value": 28012,
                "label": "Vermelles",
                "label_ar": "فيرميليس",
                "label_fr": "Vermelles"
            },
            {
                "value": 28013,
                "label": "Haisnes",
                "label_ar": "هايسنس",
                "label_fr": "Haisnes"
            },
            {
                "value": 28014,
                "label": "Cambrin",
                "label_ar": "كامبرين",
                "label_fr": "Cambrin"
            },
            {
                "value": 28015,
                "label": "Hoerdt",
                "label_ar": "Hoerdt",
                "label_fr": "Hoerdt"
            },
            {
                "value": 28016,
                "label": "Gorges",
                "label_ar": "الخوانق",
                "label_fr": "Gorges"
            },
            {
                "value": 28017,
                "label": "Teurtheville-Hague",
                "label_ar": "تيورثفيل لاهاي",
                "label_fr": "Teurtheville-Hague"
            },
            {
                "value": 28018,
                "label": "Novalaise",
                "label_ar": "نوفاليز",
                "label_fr": "Novalaise"
            },
            {
                "value": 28019,
                "label": "Bois-le-Roi",
                "label_ar": "Bois-le-Roi",
                "label_fr": "Bois-le-Roi"
            },
            {
                "value": 28020,
                "label": "Chartrettes",
                "label_ar": "تشارريتس",
                "label_fr": "Chartrettes"
            },
            {
                "value": 28021,
                "label": "Gonfaron",
                "label_ar": "جونفارون",
                "label_fr": "Gonfaron"
            },
            {
                "value": 28022,
                "label": "Baraqueville",
                "label_ar": "باراكفيل",
                "label_fr": "Baraqueville"
            },
            {
                "value": 28023,
                "label": "Moyrazes",
                "label_ar": "مويراز",
                "label_fr": "Moyrazes"
            },
            {
                "value": 28024,
                "label": "Lescure",
                "label_ar": "ليسكيور",
                "label_fr": "Lescure"
            },
            {
                "value": 28025,
                "label": "Saint-Didier-sous-Riverie",
                "label_ar": "سان ديدييه سو ريفيري",
                "label_fr": "Saint-Didier-sous-Riverie"
            },
            {
                "value": 28026,
                "label": "Perigneux",
                "label_ar": "برينيوكس",
                "label_fr": "Périgneux"
            },
            {
                "value": 28027,
                "label": "Saint-Christo-en-Jarez",
                "label_ar": "سان كريستو إن خاريز",
                "label_fr": "Saint-Christo-en-Jarez"
            },
            {
                "value": 28028,
                "label": "Estivareilles",
                "label_ar": "Estivareilles",
                "label_fr": "Estivareilles"
            },
            {
                "value": 28029,
                "label": "La Tourette",
                "label_ar": "لا توريت",
                "label_fr": "La Tourette"
            },
            {
                "value": 28030,
                "label": "Saint-Julien-le-Roux",
                "label_ar": "سان جوليان لو رو",
                "label_fr": "Saint-Julien-le-Roux"
            },
            {
                "value": 28031,
                "label": "Saint-Saturnin-les-Avignon",
                "label_ar": "سان ساتورنين ليه أفينيون",
                "label_fr": "Saint-Saturnin-les-Avignon"
            },
            {
                "value": 28032,
                "label": "Saint-Vallier-de-Thiey",
                "label_ar": "سان فالير دي تي",
                "label_fr": "Saint-Vallier-de-Thiey"
            },
            {
                "value": 28033,
                "label": "Neuville-sur-Escaut",
                "label_ar": "نيوفيل سور إسكوت",
                "label_fr": "Neuville-sur-Escaut"
            },
            {
                "value": 28034,
                "label": "Mesnil-Saint-Georges",
                "label_ar": "Mesnil-Saint-Georges",
                "label_fr": "Mesnil-Saint-Georges"
            },
            {
                "value": 28035,
                "label": "Villeneuve-de-Berg",
                "label_ar": "فيلنوف دي بيرج",
                "label_fr": "Villeneuve-de-Berg"
            },
            {
                "value": 28036,
                "label": "Huchenneville",
                "label_ar": "هوشينفيل",
                "label_fr": "Huchenneville"
            },
            {
                "value": 28037,
                "label": "Courtry",
                "label_ar": "مجاملة",
                "label_fr": "Courtry"
            },
            {
                "value": 28038,
                "label": "Hanches",
                "label_ar": "هانش",
                "label_fr": "Hanches"
            },
            {
                "value": 28039,
                "label": "Bretignolles-sur-Mer",
                "label_ar": "Bretignolles-sur-Mer",
                "label_fr": "Bretignolles-sur-Mer"
            },
            {
                "value": 28040,
                "label": "Virieu-le-Grand",
                "label_ar": "Virieu-le-Grand",
                "label_fr": "Virieu-le-Grand"
            },
            {
                "value": 28041,
                "label": "Fislis",
                "label_ar": "فيليس",
                "label_fr": "Fislis"
            },
            {
                "value": 28042,
                "label": "La Ferte-Milon",
                "label_ar": "لا فيرت ميلون",
                "label_fr": "La Ferté-Milon"
            },
            {
                "value": 28043,
                "label": "Moernach",
                "label_ar": "مورناخ",
                "label_fr": "Moernach"
            },
            {
                "value": 28044,
                "label": "Canouville",
                "label_ar": "كانوفيل",
                "label_fr": "Canouville"
            },
            {
                "value": 28045,
                "label": "Gisors",
                "label_ar": "جيسورس",
                "label_fr": "Gisors"
            },
            {
                "value": 28046,
                "label": "Vieux-Ferrette",
                "label_ar": "Vieux-Ferrette",
                "label_fr": "Vieux-Ferrette"
            },
            {
                "value": 28047,
                "label": "Wittisheim",
                "label_ar": "Wittisheim",
                "label_fr": "Wittisheim"
            },
            {
                "value": 28048,
                "label": "Dambach-la-Ville",
                "label_ar": "Dambach-la-Ville",
                "label_fr": "Dambach-la-Ville"
            },
            {
                "value": 28049,
                "label": "Cucuron",
                "label_ar": "كوكورون",
                "label_fr": "Cucuron"
            },
            {
                "value": 28050,
                "label": "Cadenet",
                "label_ar": "كادينت",
                "label_fr": "Cadenet"
            },
            {
                "value": 28051,
                "label": "Lugrin",
                "label_ar": "لوغرين",
                "label_fr": "Lugrin"
            },
            {
                "value": 28052,
                "label": "Oberhaslach",
                "label_ar": "أوبرهاسلاخ",
                "label_fr": "Oberhaslach"
            },
            {
                "value": 28053,
                "label": "Niederhaslach",
                "label_ar": "نيدرهاسلاخ",
                "label_fr": "Niederhaslach"
            },
            {
                "value": 28054,
                "label": "Sarrians",
                "label_ar": "ساريان",
                "label_fr": "Sarrians"
            },
            {
                "value": 28055,
                "label": "Sauverny",
                "label_ar": "Sauverny",
                "label_fr": "Sauverny"
            },
            {
                "value": 28056,
                "label": "Le Cellier",
                "label_ar": "لو سيلييه",
                "label_fr": "Le Cellier"
            },
            {
                "value": 28057,
                "label": "Beine-Nauroy",
                "label_ar": "بين نوروي",
                "label_fr": "Beine-Nauroy"
            },
            {
                "value": 28058,
                "label": "Notre-Dame-de-Boisset",
                "label_ar": "نوتردام دي بويسيه",
                "label_fr": "Notre-Dame-de-Boisset"
            },
            {
                "value": 28059,
                "label": "Authie",
                "label_ar": "أوثي",
                "label_fr": "Authie"
            },
            {
                "value": 28060,
                "label": "Rots",
                "label_ar": "تعفن",
                "label_fr": "Pourritures"
            },
            {
                "value": 28061,
                "label": "Caromb",
                "label_ar": "كارومب",
                "label_fr": "Caromb"
            },
            {
                "value": 28062,
                "label": "Bédoin",
                "label_ar": "بيدوين",
                "label_fr": "Bédoin"
            },
            {
                "value": 28063,
                "label": "Melrand",
                "label_ar": "ميلراند",
                "label_fr": "Melrand"
            },
            {
                "value": 28064,
                "label": "Pannes",
                "label_ar": "بانيس",
                "label_fr": "Pannes"
            },
            {
                "value": 28065,
                "label": "Groisy",
                "label_ar": "جروزي",
                "label_fr": "Groisy"
            },
            {
                "value": 28066,
                "label": "Dordives",
                "label_ar": "دورديفس",
                "label_fr": "Dordives"
            },
            {
                "value": 28067,
                "label": "Nargis",
                "label_ar": "نرجس",
                "label_fr": "Nargis"
            },
            {
                "value": 28068,
                "label": "Fontenay-sur-Loing",
                "label_ar": "فونتيني سور لوينج",
                "label_fr": "Fontenay-sur-Loing"
            },
            {
                "value": 28069,
                "label": "Villecroze",
                "label_ar": "فيلكروز",
                "label_fr": "Villecroze"
            },
            {
                "value": 28070,
                "label": "Ferrieres-en-Gatinais",
                "label_ar": "Ferrieres-en-Gatinais",
                "label_fr": "Ferrières-en-Gatinais"
            },
            {
                "value": 28071,
                "label": "Courtenay",
                "label_ar": "كورتيناي",
                "label_fr": "Courtenay"
            },
            {
                "value": 28072,
                "label": "Beaune-la-Rolande",
                "label_ar": "Beaune-la-Rolande",
                "label_fr": "Beaune-la-Rolande"
            },
            {
                "value": 28073,
                "label": "Montady",
                "label_ar": "مونتادي",
                "label_fr": "Montady"
            },
            {
                "value": 28074,
                "label": "Chevilly",
                "label_ar": "شيفيلي",
                "label_fr": "Chevilly"
            },
            {
                "value": 28075,
                "label": "Rochecorbon",
                "label_ar": "روشيكوربون",
                "label_fr": "Rochecorbon"
            },
            {
                "value": 28076,
                "label": "Roville-aux-Chenes",
                "label_ar": "روفيل أو تشينيس",
                "label_fr": "Roville-aux-Chênes"
            },
            {
                "value": 28077,
                "label": "Villacourt",
                "label_ar": "فيلاكورت",
                "label_fr": "Villacourt"
            },
            {
                "value": 28078,
                "label": "Saint-Branchs",
                "label_ar": "سان برانشس",
                "label_fr": "Saint-Branchs"
            },
            {
                "value": 28079,
                "label": "Le Luc",
                "label_ar": "لو لوك",
                "label_fr": "Le Luc"
            },
            {
                "value": 28080,
                "label": "Chevrieres",
                "label_ar": "Chevrieres",
                "label_fr": "Chevrieres"
            },
            {
                "value": 28081,
                "label": "Mont",
                "label_ar": "مونت",
                "label_fr": "Mont"
            },
            {
                "value": 28082,
                "label": "Dieffenbach-au-Val",
                "label_ar": "ديفنباخ أو فال",
                "label_fr": "Dieffenbach-au-Val"
            },
            {
                "value": 28083,
                "label": "Roumare",
                "label_ar": "روماري",
                "label_fr": "Roumare"
            },
            {
                "value": 28084,
                "label": "Port-Louis",
                "label_ar": "بورت لويس",
                "label_fr": "Port Louis"
            },
            {
                "value": 28085,
                "label": "Ectot-les-Baons",
                "label_ar": "إكتوت ليه باونس",
                "label_fr": "Ectot-les-Baons"
            },
            {
                "value": 28086,
                "label": "Neville",
                "label_ar": "نيفيل",
                "label_fr": "Neville"
            },
            {
                "value": 28087,
                "label": "Laversines",
                "label_ar": "لافيرسين",
                "label_fr": "Laversines"
            },
            {
                "value": 28088,
                "label": "Saint-Romain-le-Puy",
                "label_ar": "سان رومان لو بوي",
                "label_fr": "Saint-Romain-le-Puy"
            },
            {
                "value": 28089,
                "label": "Avallon",
                "label_ar": "أفالون",
                "label_fr": "Avallon"
            },
            {
                "value": 28090,
                "label": "Blotzheim",
                "label_ar": "بلوتزهايم",
                "label_fr": "Blotzheim"
            },
            {
                "value": 28091,
                "label": "Steige",
                "label_ar": "ستيج",
                "label_fr": "Steige"
            },
            {
                "value": 28092,
                "label": "Maisonsgoutte",
                "label_ar": "ميزونسجوت",
                "label_fr": "Maisonsgoutte"
            },
            {
                "value": 28093,
                "label": "Port-Saint-Pere",
                "label_ar": "بورت سان بير",
                "label_fr": "Port-Saint-Père"
            },
            {
                "value": 28094,
                "label": "Sainte-Pazanne",
                "label_ar": "سانت بازان",
                "label_fr": "Sainte-Pazanne"
            },
            {
                "value": 28095,
                "label": "Barneville-Carteret",
                "label_ar": "بارنيفيل كارتريت",
                "label_fr": "Barneville-Carteret"
            },
            {
                "value": 28096,
                "label": "Granges",
                "label_ar": "جرانج",
                "label_fr": "Granges"
            },
            {
                "value": 28097,
                "label": "Pougues-les-Eaux",
                "label_ar": "Pougues-les-Eaux",
                "label_fr": "Pougues-les-Eaux"
            },
            {
                "value": 28098,
                "label": "Revigny-sur-Ornain",
                "label_ar": "Revigny-sur-Ornain",
                "label_fr": "Revigny-sur-Ornain"
            },
            {
                "value": 28099,
                "label": "Villers-aux-Vents",
                "label_ar": "Villers-aux-Vents",
                "label_fr": "Villers-aux-Vents"
            },
            {
                "value": 28100,
                "label": "Arthon-en-Retz",
                "label_ar": "آرثون إن ريتز",
                "label_fr": "Arthon-en-Retz"
            },
            {
                "value": 28101,
                "label": "Chemere",
                "label_ar": "كيمير",
                "label_fr": "Chemere"
            },
            {
                "value": 28102,
                "label": "Longeville-les-Metz",
                "label_ar": "لونجفيل ليه ميتز",
                "label_fr": "Longeville-les-Metz"
            },
            {
                "value": 28103,
                "label": "Ligny-en-Barrois",
                "label_ar": "Ligny-en-Barrois",
                "label_fr": "Ligny-en-Barrois"
            },
            {
                "value": 28104,
                "label": "Givrauval",
                "label_ar": "جيفراوفال",
                "label_fr": "Givrauval"
            },
            {
                "value": 28105,
                "label": "Faulquemont",
                "label_ar": "فولكمونت",
                "label_fr": "Faulquemont"
            },
            {
                "value": 28106,
                "label": "Crehange",
                "label_ar": "التغيير",
                "label_fr": "Créhange"
            },
            {
                "value": 28107,
                "label": "Ensues-la-Redonne",
                "label_ar": "أنسوز لا ريدون",
                "label_fr": "Ensues-la-Redonne"
            },
            {
                "value": 28108,
                "label": "Sainte-Foy-l'Argentiere",
                "label_ar": "Sainte-Foy-l'Argentiere",
                "label_fr": "Sainte-Foy-l'Argentière"
            },
            {
                "value": 28109,
                "label": "Pihem",
                "label_ar": "بيهم",
                "label_fr": "Pihem"
            },
            {
                "value": 28110,
                "label": "Helfaut",
                "label_ar": "هيلفاوت",
                "label_fr": "Helfaut"
            },
            {
                "value": 28111,
                "label": "Villers-les-Nancy",
                "label_ar": "فيلير ليه نانسي",
                "label_fr": "Villers-les-Nancy"
            },
            {
                "value": 28112,
                "label": "Heudicourt-sous-les-Cotes",
                "label_ar": "Heudicourt-sous-les-Cotes",
                "label_fr": "Heudicourt-sous-les-Côtes"
            },
            {
                "value": 28113,
                "label": "Bassussarry",
                "label_ar": "باسوساري",
                "label_fr": "Bassussarry"
            },
            {
                "value": 28114,
                "label": "Bray-Dunes",
                "label_ar": "براي ديونز",
                "label_fr": "Bray-Dunes"
            },
            {
                "value": 28115,
                "label": "Momeres",
                "label_ar": "مومريس",
                "label_fr": "Momères"
            },
            {
                "value": 28116,
                "label": "Montignac-de-Lauzun",
                "label_ar": "مونتينياك دي لوزون",
                "label_fr": "Montignac-de-Lauzun"
            },
            {
                "value": 28117,
                "label": "Conches-en-Ouche",
                "label_ar": "قوقع-أون-أوش",
                "label_fr": "Conches-en-Ouche"
            },
            {
                "value": 28118,
                "label": "Saint-Elier",
                "label_ar": "سانت إيلير",
                "label_fr": "Saint-Elier"
            },
            {
                "value": 28119,
                "label": "Boves",
                "label_ar": "بوفس",
                "label_fr": "Boves"
            },
            {
                "value": 28120,
                "label": "Bilieu",
                "label_ar": "بيليو",
                "label_fr": "Bilieu"
            },
            {
                "value": 28121,
                "label": "Magagnosc",
                "label_ar": "ماجغنوسك",
                "label_fr": "Magagnosc"
            },
            {
                "value": 28122,
                "label": "Le Bar-sur-Loup",
                "label_ar": "لو بار سور لوب",
                "label_fr": "Le Bar-sur-Loup"
            },
            {
                "value": 28123,
                "label": "Tourrettes-sur-Loup",
                "label_ar": "توريت سور لوب",
                "label_fr": "Tourrettes-sur-Loup"
            },
            {
                "value": 28124,
                "label": "Sains-en-Amienois",
                "label_ar": "Sains-en-Amienois",
                "label_fr": "Sains-en-Amienois"
            },
            {
                "value": 28125,
                "label": "Wasselonne",
                "label_ar": "Wasselonne",
                "label_fr": "Wasselonne"
            },
            {
                "value": 28126,
                "label": "Ouistreham",
                "label_ar": "أوسترهام",
                "label_fr": "Ouistreham"
            },
            {
                "value": 28127,
                "label": "Lion-sur-Mer",
                "label_ar": "ليون سور مير",
                "label_fr": "Lion-sur-Mer"
            },
            {
                "value": 28128,
                "label": "Hermanville-sur-Mer",
                "label_ar": "هيرمانفيل سور مير",
                "label_fr": "Hermanville-sur-Mer"
            },
            {
                "value": 28129,
                "label": "Flixecourt",
                "label_ar": "Flixecourt",
                "label_fr": "Flixecourt"
            },
            {
                "value": 28130,
                "label": "Mouflers",
                "label_ar": "الموفلرز",
                "label_fr": "Moufleurs"
            },
            {
                "value": 28131,
                "label": "Saint-Ouen",
                "label_ar": "سان اوين",
                "label_fr": "Saint-Ouen"
            },
            {
                "value": 28132,
                "label": "Halloy-les-Pernois",
                "label_ar": "هالوي ليه بيرنوا",
                "label_fr": "Halloy-les-Pernois"
            },
            {
                "value": 28133,
                "label": "Gezaincourt",
                "label_ar": "جزينكورت",
                "label_fr": "Gezaincourt"
            },
            {
                "value": 28134,
                "label": "Rans",
                "label_ar": "يطرق",
                "label_fr": "Rans"
            },
            {
                "value": 28135,
                "label": "Comines",
                "label_ar": "كومينز",
                "label_fr": "Comines"
            },
            {
                "value": 28136,
                "label": "Oye-Plage",
                "label_ar": "أويي بلاج",
                "label_fr": "Oye-Plage"
            },
            {
                "value": 28137,
                "label": "Artigueloutan",
                "label_ar": "أرتيغيلوتان",
                "label_fr": "Artigueloutan"
            },
            {
                "value": 28138,
                "label": "Boust",
                "label_ar": "بوست",
                "label_fr": "Boust"
            },
            {
                "value": 28139,
                "label": "Etoile-sur-Rhone",
                "label_ar": "إيتوال سور رون",
                "label_fr": "Etoile-sur-Rhône"
            },
            {
                "value": 28140,
                "label": "Vendenheim",
                "label_ar": "فيندنهايم",
                "label_fr": "Vendenheim"
            },
            {
                "value": 28141,
                "label": "Saint-Cyr-sur-Morin",
                "label_ar": "سان سير سور مورين",
                "label_fr": "Saint-Cyr-sur-Morin"
            },
            {
                "value": 28142,
                "label": "Saint-Denis-les-Rebais",
                "label_ar": "سان دوني ليه ريبايس",
                "label_fr": "Saint-Denis-les-Rebais"
            },
            {
                "value": 28143,
                "label": "Nazelles-Negron",
                "label_ar": "نازيل نيغرون",
                "label_fr": "Nazelles-Negron"
            },
            {
                "value": 28144,
                "label": "Saint-Hilaire-du-Harcouet",
                "label_ar": "سان هيلير دو هاركويت",
                "label_fr": "Saint-Hilaire-du-Harcouet"
            },
            {
                "value": 28145,
                "label": "Saverdun",
                "label_ar": "Saverdun",
                "label_fr": "Saverdun"
            },
            {
                "value": 28146,
                "label": "Pujaudran",
                "label_ar": "بوجودران",
                "label_fr": "Pujaudran"
            },
            {
                "value": 28147,
                "label": "Saulxures-les-Nancy",
                "label_ar": "Saulxures-les-Nancy",
                "label_fr": "Saulxures-les-Nancy"
            },
            {
                "value": 28148,
                "label": "Sospel",
                "label_ar": "الانجيل",
                "label_fr": "Sospel"
            },
            {
                "value": 28149,
                "label": "La Trinite",
                "label_ar": "لا ترينيت",
                "label_fr": "La Trinite"
            },
            {
                "value": 28150,
                "label": "Mardyck",
                "label_ar": "مارديك",
                "label_fr": "Mardyck"
            },
            {
                "value": 28151,
                "label": "Loon-Plage",
                "label_ar": "لون بلاج",
                "label_fr": "Loon-Plage"
            },
            {
                "value": 28152,
                "label": "Pont-de-l'Isere",
                "label_ar": "Pont-de-l'Isere",
                "label_fr": "Pont-de-l'Isère"
            },
            {
                "value": 28153,
                "label": "Saint-Jean-de-Muzols",
                "label_ar": "سان جان دي موزول",
                "label_fr": "Saint-Jean-de-Muzols"
            },
            {
                "value": 28154,
                "label": "Saillans",
                "label_ar": "سايلانز",
                "label_fr": "Saillans"
            },
            {
                "value": 28155,
                "label": "Guilers",
                "label_ar": "Guilers",
                "label_fr": "Guilers"
            },
            {
                "value": 28156,
                "label": "Notre-Dame-de-Riez",
                "label_ar": "نوتردام دي ريز",
                "label_fr": "Notre-Dame-de-Riez"
            },
            {
                "value": 28157,
                "label": "Givrand",
                "label_ar": "جيفراند",
                "label_fr": "Givrand"
            },
            {
                "value": 28158,
                "label": "Soullans",
                "label_ar": "Soullans",
                "label_fr": "Soullans"
            },
            {
                "value": 28159,
                "label": "Forcalquier",
                "label_ar": "Forcalquier",
                "label_fr": "Forcalquier"
            },
            {
                "value": 28160,
                "label": "Les Mees",
                "label_ar": "ليه ميس",
                "label_fr": "Les Mees"
            },
            {
                "value": 28161,
                "label": "Chateau-Arnoux-Saint-Auban",
                "label_ar": "شاتو أرنو سان أوبان",
                "label_fr": "Château-Arnoux-Saint-Auban"
            },
            {
                "value": 28162,
                "label": "Saint-Auban",
                "label_ar": "سان أوبان",
                "label_fr": "Saint-Auban"
            },
            {
                "value": 28163,
                "label": "Laragne-Monteglin",
                "label_ar": "لاراني مونتيجلين",
                "label_fr": "Laragne-Monteglin"
            },
            {
                "value": 28164,
                "label": "Tallard",
                "label_ar": "تالارد",
                "label_fr": "Tallard"
            },
            {
                "value": 28165,
                "label": "Saint-Lumier-en-Champagne",
                "label_ar": "سان لومييه أون شامبين",
                "label_fr": "Saint-Lumier-en-Champagne"
            },
            {
                "value": 28166,
                "label": "Pleuven",
                "label_ar": "بلوفين",
                "label_fr": "Pleuven"
            },
            {
                "value": 28167,
                "label": "Saint-Paul-de-Vence",
                "label_ar": "سانت بول دي فونس",
                "label_fr": "Saint-Paul-de-Vence"
            },
            {
                "value": 28168,
                "label": "Fismes",
                "label_ar": "فيزميس",
                "label_fr": "Fismes"
            },
            {
                "value": 28169,
                "label": "Kutzenhausen",
                "label_ar": "كوتزنهاوزن",
                "label_fr": "Kutzenhausen"
            },
            {
                "value": 28170,
                "label": "Saint-Soupplets",
                "label_ar": "سان سوبليتس",
                "label_fr": "Saint-Soupplets"
            },
            {
                "value": 28171,
                "label": "Seignelay",
                "label_ar": "Seignelay",
                "label_fr": "Seignelay"
            },
            {
                "value": 28172,
                "label": "Saint-Florentin",
                "label_ar": "سانت فلورنتين",
                "label_fr": "Saint-Florentin"
            },
            {
                "value": 28173,
                "label": "Venizy",
                "label_ar": "فينيزي",
                "label_fr": "Venizy"
            },
            {
                "value": 28174,
                "label": "Saint-Paul-les-Dax",
                "label_ar": "سانت بول ليس داكس",
                "label_fr": "Saint-Paul-les-Dax"
            },
            {
                "value": 28175,
                "label": "Samadet",
                "label_ar": "صمدت",
                "label_fr": "Samadet"
            },
            {
                "value": 28176,
                "label": "Saubion",
                "label_ar": "Saubion",
                "label_fr": "Saubion"
            },
            {
                "value": 28177,
                "label": "Capbreton",
                "label_ar": "كابريتون",
                "label_fr": "Capbreton"
            },
            {
                "value": 28178,
                "label": "Die",
                "label_ar": "موت",
                "label_fr": "Mourir"
            },
            {
                "value": 28179,
                "label": "Laval-d'Aix",
                "label_ar": "لافال دايكس",
                "label_fr": "Laval-d'Aix"
            },
            {
                "value": 28180,
                "label": "Valentigney",
                "label_ar": "فالنتيني",
                "label_fr": "Valentigney"
            },
            {
                "value": 28181,
                "label": "Rumilly-en-Cambresis",
                "label_ar": "Rumilly-en-Cambresis",
                "label_fr": "Rumilly-en-Cambresis"
            },
            {
                "value": 28182,
                "label": "Jonzieux",
                "label_ar": "Jonzieux",
                "label_fr": "Jonzieux"
            },
            {
                "value": 28183,
                "label": "Roche-la-Moliere",
                "label_ar": "روش لا موليير",
                "label_fr": "Roche-la-Molière"
            },
            {
                "value": 28184,
                "label": "Langan",
                "label_ar": "لانجان",
                "label_fr": "Langan"
            },
            {
                "value": 28185,
                "label": "Saint-Pierre-de-Chandieu",
                "label_ar": "سان بيير دي تشانديو",
                "label_fr": "Saint-Pierre-de-Chandieu"
            },
            {
                "value": 28186,
                "label": "Marignier",
                "label_ar": "مارينييه",
                "label_fr": "Marignier"
            },
            {
                "value": 28187,
                "label": "Ayse",
                "label_ar": "آيس",
                "label_fr": "Ayse"
            },
            {
                "value": 28188,
                "label": "Pontonx-sur-l'Adour",
                "label_ar": "Pontonx-sur-l'Adour",
                "label_fr": "Pontonx-sur-l'Adour"
            },
            {
                "value": 28189,
                "label": "Arveyres",
                "label_ar": "أرفيريس",
                "label_fr": "Arveyres"
            },
            {
                "value": 28190,
                "label": "Saint-Germain-du-Puch",
                "label_ar": "سان جيرمان دو بوتش",
                "label_fr": "Saint-Germain-du-Puch"
            },
            {
                "value": 28191,
                "label": "Genissac",
                "label_ar": "جينيساك",
                "label_fr": "Genissac"
            },
            {
                "value": 28192,
                "label": "Branne",
                "label_ar": "بران",
                "label_fr": "Branne"
            },
            {
                "value": 28193,
                "label": "Espiet",
                "label_ar": "إسبيت",
                "label_fr": "Espiet"
            },
            {
                "value": 28194,
                "label": "Saint-Quentin-de-Baron",
                "label_ar": "سانت كوينتين دي بارون",
                "label_fr": "Saint-Quentin-de-Baron"
            },
            {
                "value": 28195,
                "label": "Cavignac",
                "label_ar": "كافينياك",
                "label_fr": "Cavignac"
            },
            {
                "value": 28196,
                "label": "Noves",
                "label_ar": "مستجدات",
                "label_fr": "Noves"
            },
            {
                "value": 28197,
                "label": "Saint-Yzan-de-Soudiac",
                "label_ar": "سان يزان دو سودياك",
                "label_fr": "Saint-Yzan-de-Soudiac"
            },
            {
                "value": 28198,
                "label": "Pugnac",
                "label_ar": "بوغناك",
                "label_fr": "Pugnac"
            },
            {
                "value": 28199,
                "label": "Scherwiller",
                "label_ar": "شرويلر",
                "label_fr": "Scherwiller"
            },
            {
                "value": 28200,
                "label": "Kintzheim",
                "label_ar": "كينتسهايم",
                "label_fr": "Kintzheim"
            },
            {
                "value": 28201,
                "label": "Exincourt",
                "label_ar": "إكسينكور",
                "label_fr": "Exincourt"
            },
            {
                "value": 28202,
                "label": "Lesneven",
                "label_ar": "ليسنيفن",
                "label_fr": "Lesneven"
            },
            {
                "value": 28203,
                "label": "Moussan",
                "label_ar": "موسان",
                "label_fr": "Moussan"
            },
            {
                "value": 28204,
                "label": "Gruissan",
                "label_ar": "جرويسان",
                "label_fr": "Gruissan"
            },
            {
                "value": 28205,
                "label": "Saint-Cyr-sur-le-Rhone",
                "label_ar": "سان سير سور لو رون",
                "label_fr": "Saint-Cyr-sur-le-Rhône"
            },
            {
                "value": 28206,
                "label": "Saint-Romain-en-Gal",
                "label_ar": "سان رومان أون غال",
                "label_fr": "Saint-Romain-en-Gal"
            },
            {
                "value": 28207,
                "label": "Pont-de-Cheruy",
                "label_ar": "بونت دي شيروي",
                "label_fr": "Pont-de-Cheruy"
            },
            {
                "value": 28208,
                "label": "La Cote",
                "label_ar": "لا كوت",
                "label_fr": "La Cote"
            },
            {
                "value": 28209,
                "label": "Fouillard",
                "label_ar": "Fouillard",
                "label_fr": "Fouillard"
            },
            {
                "value": 28210,
                "label": "Trouhans",
                "label_ar": "تروحانس",
                "label_fr": "Trouhans"
            },
            {
                "value": 28211,
                "label": "Esbarres",
                "label_ar": "اسباريس",
                "label_fr": "Esbarres"
            },
            {
                "value": 28212,
                "label": "Saint-Jean-de-Losne",
                "label_ar": "سان جان دي لوسن",
                "label_fr": "Saint-Jean-de-Losne"
            },
            {
                "value": 28213,
                "label": "Seurre",
                "label_ar": "Seurre",
                "label_fr": "Seurre"
            },
            {
                "value": 28214,
                "label": "Verpillieres",
                "label_ar": "فيربيلييه",
                "label_fr": "Verpillières"
            },
            {
                "value": 28215,
                "label": "Champien",
                "label_ar": "شامبين",
                "label_fr": "Champien"
            },
            {
                "value": 28216,
                "label": "Roye",
                "label_ar": "روي",
                "label_fr": "Roye"
            },
            {
                "value": 28217,
                "label": "Matigny",
                "label_ar": "ماتيني",
                "label_fr": "Matigny"
            },
            {
                "value": 28218,
                "label": "Gragnague",
                "label_ar": "غراغناغ",
                "label_fr": "Gragnague"
            },
            {
                "value": 28219,
                "label": "Saint-Aubin-du-Cormier",
                "label_ar": "سان أوبين دو كورمير",
                "label_fr": "Saint-Aubin-du-Cormier"
            },
            {
                "value": 28220,
                "label": "Noyal-sur-Vilaine",
                "label_ar": "نويال سور فيلان",
                "label_fr": "Noyal-sur-Vilaine"
            },
            {
                "value": 28221,
                "label": "Vaire",
                "label_ar": "فاير",
                "label_fr": "Vaire"
            },
            {
                "value": 28222,
                "label": "Annecy-le-Vieux",
                "label_ar": "أنيسي لو فيو",
                "label_fr": "Annecy-le-Vieux"
            },
            {
                "value": 28223,
                "label": "Etival-Clairefontaine",
                "label_ar": "إتيفال كليرفونتين",
                "label_fr": "Etival-Clairefontaine"
            },
            {
                "value": 28224,
                "label": "Saint-Remy",
                "label_ar": "سانت ريمي",
                "label_fr": "Saint-Rémy"
            },
            {
                "value": 28225,
                "label": "Raon-sur-Plaine",
                "label_ar": "راون سور بلين",
                "label_fr": "Raon-sur-Plaine"
            },
            {
                "value": 28226,
                "label": "Baccarat",
                "label_ar": "القمار",
                "label_fr": "Baccarat"
            },
            {
                "value": 28227,
                "label": "Rambervillers",
                "label_ar": "رامبرفيلرز",
                "label_fr": "Rambervillers"
            },
            {
                "value": 28228,
                "label": "Maclas",
                "label_ar": "ماكلاس",
                "label_fr": "Maclas"
            },
            {
                "value": 28229,
                "label": "Bouray-sur-Juine",
                "label_ar": "بوراي سور جوين",
                "label_fr": "Bouray-sur-Juine"
            },
            {
                "value": 28230,
                "label": "Sully-sur-Loire",
                "label_ar": "سولي سور لوار",
                "label_fr": "Sully-sur-Loire"
            },
            {
                "value": 28231,
                "label": "Ouzouer-sur-Loire",
                "label_ar": "Ouzouer-sur-Loire",
                "label_fr": "Ouzouer-sur-Loire"
            },
            {
                "value": 28232,
                "label": "Tigy",
                "label_ar": "تيجي",
                "label_fr": "Tigy"
            },
            {
                "value": 28233,
                "label": "Guilly",
                "label_ar": "غيلي",
                "label_fr": "Guilly"
            },
            {
                "value": 28234,
                "label": "Pouxeux",
                "label_ar": "Pouxeux",
                "label_fr": "Pouxeux"
            },
            {
                "value": 28235,
                "label": "Remiremont",
                "label_ar": "ريميرمون",
                "label_fr": "Remiremont"
            },
            {
                "value": 28236,
                "label": "Boigny-sur-Bionne",
                "label_ar": "Boigny-sur-Bionne",
                "label_fr": "Boigny-sur-Bionne"
            },
            {
                "value": 28237,
                "label": "Archettes",
                "label_ar": "أركيتيس",
                "label_fr": "Archettes"
            },
            {
                "value": 28238,
                "label": "Branoux-les-Taillades",
                "label_ar": "برانوكس ليه تيلاد",
                "label_fr": "Branoux-les-Taillades"
            },
            {
                "value": 28239,
                "label": "Reignier-Esery",
                "label_ar": "Reignier-Esery",
                "label_fr": "Reignier-Esery"
            },
            {
                "value": 28240,
                "label": "Vincey",
                "label_ar": "فينسي",
                "label_fr": "Vincey"
            },
            {
                "value": 28241,
                "label": "Nomexy",
                "label_ar": "نومكسي",
                "label_fr": "Nomexy"
            },
            {
                "value": 28242,
                "label": "Chavigny",
                "label_ar": "شافيني",
                "label_fr": "Chavigny"
            },
            {
                "value": 28243,
                "label": "Bainville-sur-Madon",
                "label_ar": "بينفيل سور مادون",
                "label_fr": "Bainville-sur-Madon"
            },
            {
                "value": 28244,
                "label": "Maron",
                "label_ar": "مارون",
                "label_fr": "Maron"
            },
            {
                "value": 28245,
                "label": "Charmes",
                "label_ar": "سحر",
                "label_fr": "Charmes"
            },
            {
                "value": 28246,
                "label": "Chatel-sur-Moselle",
                "label_ar": "شاتيل سور موسيل",
                "label_fr": "Châtel-sur-Moselle"
            },
            {
                "value": 28247,
                "label": "Lux",
                "label_ar": "لوكس",
                "label_fr": "Lux"
            },
            {
                "value": 28248,
                "label": "Branches",
                "label_ar": "الفروع",
                "label_fr": "Branches"
            },
            {
                "value": 28249,
                "label": "Diemoz",
                "label_ar": "ديموز",
                "label_fr": "Diemoz"
            },
            {
                "value": 28250,
                "label": "Gundolsheim",
                "label_ar": "جوندولشيم",
                "label_fr": "Gundolsheim"
            },
            {
                "value": 28251,
                "label": "Appoigny",
                "label_ar": "أبويني",
                "label_fr": "Appoigny"
            },
            {
                "value": 28252,
                "label": "Gemil",
                "label_ar": "جميل",
                "label_fr": "Gemil"
            },
            {
                "value": 28253,
                "label": "Marcoussis",
                "label_ar": "ماركوسي",
                "label_fr": "Marcoussis"
            },
            {
                "value": 28254,
                "label": "Potigny",
                "label_ar": "بوتيني",
                "label_fr": "Potigny"
            },
            {
                "value": 28255,
                "label": "Salles-d'Aude",
                "label_ar": "ساليس دود",
                "label_fr": "Salles-d'Aude"
            },
            {
                "value": 28256,
                "label": "Serifontaine",
                "label_ar": "سيريفونتين",
                "label_fr": "Serifontaine"
            },
            {
                "value": 28257,
                "label": "Carry-le-Rouet",
                "label_ar": "كاري لو رويه",
                "label_fr": "Carry-le-Rouet"
            },
            {
                "value": 28258,
                "label": "Halluin",
                "label_ar": "هلوين",
                "label_fr": "Halluin"
            },
            {
                "value": 28259,
                "label": "Prevessin-Moens",
                "label_ar": "بريفيسين موينز",
                "label_fr": "Prevessin-Moens"
            },
            {
                "value": 28260,
                "label": "Ferney-Voltaire",
                "label_ar": "فيرني فولتير",
                "label_fr": "Ferney-Voltaire"
            },
            {
                "value": 28261,
                "label": "Valleiry",
                "label_ar": "فاليري",
                "label_fr": "Valleiry"
            },
            {
                "value": 28262,
                "label": "Ammerschwihr",
                "label_ar": "أميرشوير",
                "label_fr": "Ammerschwihr"
            },
            {
                "value": 28263,
                "label": "Mittelwihr",
                "label_ar": "ميتلوير",
                "label_fr": "Mittelwihr"
            },
            {
                "value": 28264,
                "label": "Biesheim",
                "label_ar": "بيشيم",
                "label_fr": "Biesheim"
            },
            {
                "value": 28265,
                "label": "Illhaeusern",
                "label_ar": "إيلهاوسرن",
                "label_fr": "Illhaeusern"
            },
            {
                "value": 28266,
                "label": "Ribeauvillé",
                "label_ar": "ريبوفيل",
                "label_fr": "Ribeauvillé"
            },
            {
                "value": 28267,
                "label": "Boissy-sous-Saint-Yon",
                "label_ar": "Boissy-sous-Saint-Yon",
                "label_fr": "Boissy-sous-Saint-Yon"
            },
            {
                "value": 28268,
                "label": "Allieres",
                "label_ar": "أليير",
                "label_fr": "Allières"
            },
            {
                "value": 28269,
                "label": "Remoulins",
                "label_ar": "ريمولين",
                "label_fr": "Remoulins"
            },
            {
                "value": 28270,
                "label": "Couloisy",
                "label_ar": "كولوازي",
                "label_fr": "Couloisy"
            },
            {
                "value": 28271,
                "label": "Pierrefonds",
                "label_ar": "بييرفوندس",
                "label_fr": "Pierrefonds"
            },
            {
                "value": 28272,
                "label": "Blincourt",
                "label_ar": "بلينكور",
                "label_fr": "Blincourt"
            },
            {
                "value": 28273,
                "label": "Estrees-Saint-Denis",
                "label_ar": "إستريس سانت دينيس",
                "label_fr": "Estrees-Saint-Denis"
            },
            {
                "value": 28274,
                "label": "Rouvillers",
                "label_ar": "روفيلر",
                "label_fr": "Rouvillers"
            },
            {
                "value": 28275,
                "label": "Le Lardin-Saint-Lazare",
                "label_ar": "لو لاردين سان لازار",
                "label_fr": "Le Lardin-Saint-Lazare"
            },
            {
                "value": 28276,
                "label": "Bas-en-Basset",
                "label_ar": "باس أون باسيت",
                "label_fr": "Bas-en-Basset"
            },
            {
                "value": 28277,
                "label": "Le Coudray-Montceaux",
                "label_ar": "لو كودراي مونتسو",
                "label_fr": "Le Coudray-Montceaux"
            },
            {
                "value": 28278,
                "label": "Betheny",
                "label_ar": "بيثيني",
                "label_fr": "Betheny"
            },
            {
                "value": 28279,
                "label": "Puiseaux",
                "label_ar": "Puiseaux",
                "label_fr": "Puiseaux"
            },
            {
                "value": 28280,
                "label": "Malesherbes",
                "label_ar": "ماليشيربيس",
                "label_fr": "Malesherbes"
            },
            {
                "value": 28281,
                "label": "Orveau-Bellesauve",
                "label_ar": "أورفو بيلساوف",
                "label_fr": "Orveau-Bellesauve"
            },
            {
                "value": 28282,
                "label": "Moneteau",
                "label_ar": "Moneteau",
                "label_fr": "Monéteau"
            },
            {
                "value": 28283,
                "label": "Altkirch",
                "label_ar": "التكيرش",
                "label_fr": "Altkirch"
            },
            {
                "value": 28284,
                "label": "Carspach",
                "label_ar": "كارسباخ",
                "label_fr": "Carspach"
            },
            {
                "value": 28285,
                "label": "Didenheim",
                "label_ar": "ديدنهايم",
                "label_fr": "Didenheim"
            },
            {
                "value": 28286,
                "label": "Ungersheim",
                "label_ar": "أنجرسهايم",
                "label_fr": "Ungersheim"
            },
            {
                "value": 28287,
                "label": "Heidwiller",
                "label_ar": "هايدويلر",
                "label_fr": "Heidwiller"
            },
            {
                "value": 28288,
                "label": "Waldighofen",
                "label_ar": "والدجوفين",
                "label_fr": "Waldighofen"
            },
            {
                "value": 28289,
                "label": "Roche-lez-Beaupre",
                "label_ar": "روش ليز بوبر",
                "label_fr": "Roche-lez-Beaupré"
            },
            {
                "value": 28290,
                "label": "Thise",
                "label_ar": "هذا",
                "label_fr": "Thise"
            },
            {
                "value": 28291,
                "label": "Gilly-les-Citeaux",
                "label_ar": "جيلي ليه سيتو",
                "label_fr": "Gilly-les-Citeaux"
            },
            {
                "value": 28292,
                "label": "Gevrey-Chambertin",
                "label_ar": "جيفري تشامبرتين",
                "label_fr": "Gevrey-Chambertin"
            },
            {
                "value": 28293,
                "label": "Drusenheim",
                "label_ar": "دروزنهايم",
                "label_fr": "Drusenheim"
            },
            {
                "value": 28294,
                "label": "Marly-la-Ville",
                "label_ar": "مارلي لا فيل",
                "label_fr": "Marly-la-Ville"
            },
            {
                "value": 28295,
                "label": "Cosges",
                "label_ar": "أكياس",
                "label_fr": "Les coûts"
            },
            {
                "value": 28296,
                "label": "Buigny-les-Gamaches",
                "label_ar": "Buigny-les-Gamaches",
                "label_fr": "Buigny-les-Gamaches"
            },
            {
                "value": 28297,
                "label": "Beauchamps",
                "label_ar": "بوشامب",
                "label_fr": "Beauchamps"
            },
            {
                "value": 28298,
                "label": "Vals-les-Bains",
                "label_ar": "فالس ليه با",
                "label_fr": "Vals-les-Bains"
            },
            {
                "value": 28299,
                "label": "Bazancourt",
                "label_ar": "بازانكورت",
                "label_fr": "Bazancourt"
            },
            {
                "value": 28300,
                "label": "Warmeriville",
                "label_ar": "وارميريفيل",
                "label_fr": "Warmeriville"
            },
            {
                "value": 28301,
                "label": "Pompey",
                "label_ar": "بومبي",
                "label_fr": "Pompée"
            },
            {
                "value": 28302,
                "label": "La Chapelle-Orthemale",
                "label_ar": "لا شابيل أورتيميل",
                "label_fr": "La Chapelle-Orthemale"
            },
            {
                "value": 28303,
                "label": "Quibou",
                "label_ar": "Quibou",
                "label_fr": "Quibou"
            },
            {
                "value": 28304,
                "label": "Balschwiller",
                "label_ar": "Balschwiller",
                "label_fr": "Balschwiller"
            },
            {
                "value": 28305,
                "label": "Charnecles",
                "label_ar": "تشارنكلس",
                "label_fr": "Charnecles"
            },
            {
                "value": 28306,
                "label": "Beaucroissant",
                "label_ar": "بيوكروسان",
                "label_fr": "Beaucroissant"
            },
            {
                "value": 28307,
                "label": "Guenange",
                "label_ar": "Guenange",
                "label_fr": "Guenange"
            },
            {
                "value": 28308,
                "label": "Montrequienne",
                "label_ar": "مونتريكيين",
                "label_fr": "Montrequienne"
            },
            {
                "value": 28309,
                "label": "Trieux",
                "label_ar": "تريوكس",
                "label_fr": "Trieux"
            },
            {
                "value": 28310,
                "label": "Vigy",
                "label_ar": "فيجي",
                "label_fr": "Vigy"
            },
            {
                "value": 28311,
                "label": "Aumetz",
                "label_ar": "اوميتز",
                "label_fr": "Aumetz"
            },
            {
                "value": 28312,
                "label": "Boulange",
                "label_ar": "بولانج",
                "label_fr": "Boulange"
            },
            {
                "value": 28313,
                "label": "Serrouville",
                "label_ar": "سيروفيل",
                "label_fr": "Serrouville"
            },
            {
                "value": 28314,
                "label": "Mancieulles",
                "label_ar": "مانسيولس",
                "label_fr": "Mancieulles"
            },
            {
                "value": 28315,
                "label": "Tucquegnieux",
                "label_ar": "Tucquegnieux",
                "label_fr": "Tucquegnieux"
            },
            {
                "value": 28316,
                "label": "Briey",
                "label_ar": "بري",
                "label_fr": "Briey"
            },
            {
                "value": 28317,
                "label": "Mont-Bonvillers",
                "label_ar": "مونت بونفيلرس",
                "label_fr": "Mont-Bonvillers"
            },
            {
                "value": 28318,
                "label": "Norroy-le-Sec",
                "label_ar": "نورروي لو سيك",
                "label_fr": "Norroy-le-Sec"
            },
            {
                "value": 28319,
                "label": "Joudreville",
                "label_ar": "جودريفيل",
                "label_fr": "Joudreville"
            },
            {
                "value": 28320,
                "label": "Mouliets-et-Villemartin",
                "label_ar": "Mouliets-et-Villemartin",
                "label_fr": "Mouliets-et-Villemartin"
            },
            {
                "value": 28321,
                "label": "Giraumont",
                "label_ar": "جيرومونت",
                "label_fr": "Giraumont"
            },
            {
                "value": 28322,
                "label": "Conflans-en-Jarnisy",
                "label_ar": "Conflans-en-Jarnisy",
                "label_fr": "Conflans-en-Jarnisy"
            },
            {
                "value": 28323,
                "label": "Lezennes",
                "label_ar": "ليزين",
                "label_fr": "Lezennes"
            },
            {
                "value": 28324,
                "label": "Le Grand-Quevilly",
                "label_ar": "لو جراند كويفيلي",
                "label_fr": "Le Grand-Quevilly"
            },
            {
                "value": 28325,
                "label": "Chatignonville",
                "label_ar": "شاتينيونفيل",
                "label_fr": "Châtignonville"
            },
            {
                "value": 28326,
                "label": "Corbreuse",
                "label_ar": "كوربروس",
                "label_fr": "Corbreuse"
            },
            {
                "value": 28327,
                "label": "Baron",
                "label_ar": "البارون",
                "label_fr": "Baron"
            },
            {
                "value": 28328,
                "label": "Chasse-sur-Rhone",
                "label_ar": "تشاس سور رون",
                "label_fr": "Chasse-sur-Rhône"
            },
            {
                "value": 28329,
                "label": "Ornans",
                "label_ar": "Ornans",
                "label_fr": "Ornans"
            },
            {
                "value": 28330,
                "label": "Gemeaux",
                "label_ar": "جيمو",
                "label_fr": "Gemeaux"
            },
            {
                "value": 28331,
                "label": "Payrin-Augmontel",
                "label_ar": "بارين أوغمونتيل",
                "label_fr": "Payrin-Augmontel"
            },
            {
                "value": 28332,
                "label": "Aiguefonde",
                "label_ar": "ايجيفوند",
                "label_fr": "Aiguefonde"
            },
            {
                "value": 28333,
                "label": "Lagarrigue",
                "label_ar": "لاغاريج",
                "label_fr": "Lagarrigue"
            },
            {
                "value": 28334,
                "label": "Artannes-sur-Indre",
                "label_ar": "أرتانيس سور إندر",
                "label_fr": "Artannes-sur-Indre"
            },
            {
                "value": 28335,
                "label": "Seloncourt",
                "label_ar": "سيلونكور",
                "label_fr": "Seloncourt"
            },
            {
                "value": 28336,
                "label": "Offemont",
                "label_ar": "أوفمونت",
                "label_fr": "Offemont"
            },
            {
                "value": 28337,
                "label": "Salbert",
                "label_ar": "سالبرت",
                "label_fr": "Salbert"
            },
            {
                "value": 28338,
                "label": "Notre-Dame-de-Livoye",
                "label_ar": "نوتردام دي ليفوي",
                "label_fr": "Notre-Dame-de-Livoye"
            },
            {
                "value": 28339,
                "label": "Monteynard",
                "label_ar": "مونتينارد",
                "label_fr": "Monteynard"
            },
            {
                "value": 28340,
                "label": "Ecole-Valentin",
                "label_ar": "مدرسة فالنتين",
                "label_fr": "Ecole-Valentin"
            },
            {
                "value": 28341,
                "label": "Miserey-Salines",
                "label_ar": "Miserey-Salines",
                "label_fr": "Miserey-Salines"
            },
            {
                "value": 28342,
                "label": "Villiers-sur-Orge",
                "label_ar": "فيلير سور أورج",
                "label_fr": "Villiers-sur-Orge"
            },
            {
                "value": 28343,
                "label": "Arbent",
                "label_ar": "أربنت",
                "label_fr": "Arbent"
            },
            {
                "value": 28344,
                "label": "Westhoffen",
                "label_ar": "ويستهوفن",
                "label_fr": "Westhoffen"
            },
            {
                "value": 28345,
                "label": "Boulieu-les-Annonay",
                "label_ar": "بوليو ليه أنوني",
                "label_fr": "Boulieu-les-Annonay"
            },
            {
                "value": 28346,
                "label": "Champniers",
                "label_ar": "تشامبنييرز",
                "label_fr": "Champniers"
            },
            {
                "value": 28347,
                "label": "Plailly",
                "label_ar": "بليلي",
                "label_fr": "Plailly"
            },
            {
                "value": 28348,
                "label": "Saint-Prix",
                "label_ar": "سان بريكس",
                "label_fr": "Saint-Prix"
            },
            {
                "value": 28349,
                "label": "Andilly",
                "label_ar": "أنديلي",
                "label_fr": "Andilly"
            },
            {
                "value": 28350,
                "label": "Bavent",
                "label_ar": "بافينت",
                "label_fr": "Bavent"
            },
            {
                "value": 28351,
                "label": "Sarre-Union",
                "label_ar": "ساري يونيون",
                "label_fr": "Sarre-Union"
            },
            {
                "value": 28352,
                "label": "Arche",
                "label_ar": "أرش",
                "label_fr": "Arche"
            },
            {
                "value": 28353,
                "label": "Bosc-Guerard-Saint-Adrien",
                "label_ar": "Bosc-Guerard-Saint-Adrien",
                "label_fr": "Bosc-Guérard-Saint-Adrien"
            },
            {
                "value": 28354,
                "label": "Chambourcy",
                "label_ar": "شامبورسي",
                "label_fr": "Chambourcy"
            },
            {
                "value": 28355,
                "label": "Longuyon",
                "label_ar": "لونجويون",
                "label_fr": "Longuyon"
            },
            {
                "value": 28356,
                "label": "Haucourt-Moulaine",
                "label_ar": "Haucourt-Moulaine",
                "label_fr": "Haucourt-Moulaine"
            },
            {
                "value": 28357,
                "label": "Mexy",
                "label_ar": "ميكسي",
                "label_fr": "Mexy"
            },
            {
                "value": 28358,
                "label": "Audun-le-Tiche",
                "label_ar": "أودون لو تيش",
                "label_fr": "Audun-le-Tiche"
            },
            {
                "value": 28359,
                "label": "Errouville",
                "label_ar": "اروفيل",
                "label_fr": "Errouville"
            },
            {
                "value": 28360,
                "label": "Tiercelet",
                "label_ar": "تيرسيليت",
                "label_fr": "Tiercelet"
            },
            {
                "value": 28361,
                "label": "Vetraz-Monthoux",
                "label_ar": "فيتراز-مونثوكس",
                "label_fr": "Vetraz-Monthoux"
            },
            {
                "value": 28362,
                "label": "Graveron-Semerville",
                "label_ar": "جرافيرون سيمرفيل",
                "label_fr": "Graveron-Semerville"
            },
            {
                "value": 28363,
                "label": "Podensac",
                "label_ar": "بودنساك",
                "label_fr": "Podensac"
            },
            {
                "value": 28364,
                "label": "Le Chatelet-en-Brie",
                "label_ar": "لو شاتليت أون بري",
                "label_fr": "Le Châtelet-en-Brie"
            },
            {
                "value": 28365,
                "label": "Léognan",
                "label_ar": "لوجنان",
                "label_fr": "Léognan"
            },
            {
                "value": 28366,
                "label": "Gordes",
                "label_ar": "جوردس",
                "label_fr": "Gordes"
            },
            {
                "value": 28367,
                "label": "Provin",
                "label_ar": "بروفين",
                "label_fr": "Provin"
            },
            {
                "value": 28368,
                "label": "Rott",
                "label_ar": "روت",
                "label_fr": "Rott"
            },
            {
                "value": 28369,
                "label": "Moret-sur-Loing",
                "label_ar": "موريت سور لونج",
                "label_fr": "Moret-sur-Loing"
            },
            {
                "value": 28370,
                "label": "Saint-Mammes",
                "label_ar": "سان ماميس",
                "label_fr": "Saint-Mammes"
            },
            {
                "value": 28371,
                "label": "Pontault-Combault",
                "label_ar": "بونتولت كومبو",
                "label_fr": "Pontault-Combault"
            },
            {
                "value": 28372,
                "label": "Margon",
                "label_ar": "مارغون",
                "label_fr": "Margon"
            },
            {
                "value": 28373,
                "label": "Ecouen",
                "label_ar": "إكوين",
                "label_fr": "Ecouen"
            },
            {
                "value": 28374,
                "label": "Lourenties",
                "label_ar": "Lourenties",
                "label_fr": "Lourenties"
            },
            {
                "value": 28375,
                "label": "Saint-Saens",
                "label_ar": "سانت سين",
                "label_fr": "Saint-Saëns"
            },
            {
                "value": 28376,
                "label": "Barbaste",
                "label_ar": "بارباستي",
                "label_fr": "Barbaste"
            },
            {
                "value": 28377,
                "label": "Picquigny",
                "label_ar": "بيكيني",
                "label_fr": "Picquigny"
            },
            {
                "value": 28378,
                "label": "Castillon-Massas",
                "label_ar": "كاستيلون ماساس",
                "label_fr": "Castillon-Massas"
            },
            {
                "value": 28379,
                "label": "Montech",
                "label_ar": "مونتيك",
                "label_fr": "Montech"
            },
            {
                "value": 28380,
                "label": "Renaison",
                "label_ar": "رينايسون",
                "label_fr": "Renaison"
            },
            {
                "value": 28381,
                "label": "Le Gua",
                "label_ar": "لو غوا",
                "label_fr": "Le Gua"
            },
            {
                "value": 28382,
                "label": "Champforgeuil",
                "label_ar": "شامبورجويل",
                "label_fr": "Champforgeuil"
            },
            {
                "value": 28383,
                "label": "Contrexeville",
                "label_ar": "كونتريكسفيل",
                "label_fr": "Contrexeville"
            },
            {
                "value": 28384,
                "label": "Maing",
                "label_ar": "مينغ",
                "label_fr": "Maing"
            },
            {
                "value": 28385,
                "label": "Le Quesnoy",
                "label_ar": "لو كويسنوي",
                "label_fr": "Le Quesnoy"
            },
            {
                "value": 28386,
                "label": "Louvignies-Quesnoy",
                "label_ar": "Louvignies-Quesnoy",
                "label_fr": "Louvignies-Quesnoy"
            },
            {
                "value": 28387,
                "label": "Labourse",
                "label_ar": "مختبر",
                "label_fr": "Cours de laboratoire"
            },
            {
                "value": 28388,
                "label": "Secondigny",
                "label_ar": "ثانوي",
                "label_fr": "Secondigny"
            },
            {
                "value": 28389,
                "label": "Laize",
                "label_ar": "ليز",
                "label_fr": "Laize"
            },
            {
                "value": 28390,
                "label": "Auchel",
                "label_ar": "أوشل",
                "label_fr": "Auchel"
            },
            {
                "value": 28391,
                "label": "Bressuire",
                "label_ar": "بريسوير",
                "label_fr": "Bressuire"
            },
            {
                "value": 28392,
                "label": "Verdun-sur-Garonne",
                "label_ar": "فردان سور غارون",
                "label_fr": "Verdun-sur-Garonne"
            },
            {
                "value": 28393,
                "label": "Trouy",
                "label_ar": "تروى",
                "label_fr": "Trouble"
            },
            {
                "value": 28394,
                "label": "Sansais",
                "label_ar": "سانسيس",
                "label_fr": "Sansais"
            },
            {
                "value": 28395,
                "label": "Neuville-aux-Bois",
                "label_ar": "Neuville-aux-Bois",
                "label_fr": "Neuville-aux-Bois"
            },
            {
                "value": 28396,
                "label": "Pierrelaye",
                "label_ar": "بييرلاي",
                "label_fr": "Pierrelaye"
            },
            {
                "value": 28397,
                "label": "Saint-Florent-sur-Cher",
                "label_ar": "سان فلوران سور شير",
                "label_fr": "Saint-Florent-sur-Cher"
            },
            {
                "value": 28398,
                "label": "Saint-Martin-d'Auxigny",
                "label_ar": "سان مارتن دوكسيني",
                "label_fr": "Saint-Martin-d'Auxigny"
            },
            {
                "value": 28399,
                "label": "Henrichemont",
                "label_ar": "هنريكيمونت",
                "label_fr": "Henrichemont"
            },
            {
                "value": 28400,
                "label": "Sancerre",
                "label_ar": "سانسيري",
                "label_fr": "Sancerre"
            },
            {
                "value": 28401,
                "label": "Langoiran",
                "label_ar": "لانغواران",
                "label_fr": "Langoiran"
            },
            {
                "value": 28402,
                "label": "La Chapelle-sur-Chezy",
                "label_ar": "لا شابيل سور تشيزي",
                "label_fr": "La Chapelle-sur-Chezy"
            },
            {
                "value": 28403,
                "label": "Chatillon-sur-Seiche",
                "label_ar": "شاتيلون سور سيش",
                "label_fr": "Châtillon-sur-Seiche"
            },
            {
                "value": 28404,
                "label": "Migne-Auxances",
                "label_ar": "Migne-Auxances",
                "label_fr": "Migne-Auxances"
            },
            {
                "value": 28405,
                "label": "Foug",
                "label_ar": "فوج",
                "label_fr": "Foug"
            },
            {
                "value": 28406,
                "label": "Chambord",
                "label_ar": "شامبورد",
                "label_fr": "Chambord"
            },
            {
                "value": 28407,
                "label": "Bracieux",
                "label_ar": "براسيو",
                "label_fr": "Bracieux"
            },
            {
                "value": 28408,
                "label": "Belleville",
                "label_ar": "بيلفيل",
                "label_fr": "Belleville"
            },
            {
                "value": 28409,
                "label": "Bleigny-le-Carreau",
                "label_ar": "بليني لو كارو",
                "label_fr": "Bleigny-le-Carreau"
            },
            {
                "value": 28410,
                "label": "Mousson",
                "label_ar": "موسون",
                "label_fr": "Mousson"
            },
            {
                "value": 28411,
                "label": "Atton",
                "label_ar": "أتون",
                "label_fr": "Atton"
            },
            {
                "value": 28412,
                "label": "Vandieres",
                "label_ar": "فانديريس",
                "label_fr": "Vandières"
            },
            {
                "value": 28413,
                "label": "Pagny-sur-Moselle",
                "label_ar": "باجني سور موسيل",
                "label_fr": "Pagny-sur-Moselle"
            },
            {
                "value": 28414,
                "label": "Cour-Cheverny",
                "label_ar": "كور شيفيرني",
                "label_fr": "Cour-Cheverny"
            },
            {
                "value": 28415,
                "label": "Barbazan-Debat",
                "label_ar": "باربازان ديبات",
                "label_fr": "Barbazan-Debat"
            },
            {
                "value": 28416,
                "label": "Rivedoux-Plage",
                "label_ar": "ريفيدو بلاج",
                "label_fr": "Rivedoux-Plage"
            },
            {
                "value": 28417,
                "label": "Venoy",
                "label_ar": "فينوي",
                "label_fr": "Venoy"
            },
            {
                "value": 28418,
                "label": "Weitbruch",
                "label_ar": "Weitbruch",
                "label_fr": "Weitbruch"
            },
            {
                "value": 28419,
                "label": "Kurtzenhouse",
                "label_ar": "كورتزينهاوس",
                "label_fr": "Kurtzenhouse"
            },
            {
                "value": 28420,
                "label": "Saint-Martin-le-Beau",
                "label_ar": "سان مارتن لو بو",
                "label_fr": "Saint-Martin-le-Beau"
            },
            {
                "value": 28421,
                "label": "Saint-Laurent-du-Pont",
                "label_ar": "سان لوران دو بونت",
                "label_fr": "Saint-Laurent-du-Pont"
            },
            {
                "value": 28422,
                "label": "Betschdorf",
                "label_ar": "بيتشدورف",
                "label_fr": "Betschdorf"
            },
            {
                "value": 28423,
                "label": "Guipronvel",
                "label_ar": "جبرونفيل",
                "label_fr": "Guipronvel"
            },
            {
                "value": 28424,
                "label": "Milizac",
                "label_ar": "ميليزاك",
                "label_fr": "Milizac"
            },
            {
                "value": 28425,
                "label": "Krautergersheim",
                "label_ar": "Krautergersheim",
                "label_fr": "Krautergersheim"
            },
            {
                "value": 28426,
                "label": "Saint-Gely-du-Fesc",
                "label_ar": "سان جيلي دو فيسك",
                "label_fr": "Saint-Gely-du-Fesc"
            },
            {
                "value": 28427,
                "label": "Treuzy-Levelay",
                "label_ar": "Treuzy-Levelay",
                "label_fr": "Treuzy-Levelay"
            },
            {
                "value": 28428,
                "label": "Saint-Pierre-Montlimart",
                "label_ar": "سان بيير مونتليمارت",
                "label_fr": "Saint-Pierre-Montlimart"
            },
            {
                "value": 28429,
                "label": "Eckbolsheim",
                "label_ar": "إيكبولسهايم",
                "label_fr": "Eckbolsheim"
            },
            {
                "value": 28430,
                "label": "Saint-Quentin-Fallavier",
                "label_ar": "سانت كوينتين فالافير",
                "label_fr": "Saint-Quentin-Fallavier"
            },
            {
                "value": 28431,
                "label": "Ruy",
                "label_ar": "روي",
                "label_fr": "Ruy"
            },
            {
                "value": 28432,
                "label": "Saint-Alban-de-Roche",
                "label_ar": "سان ألبان دي روش",
                "label_fr": "Saint-Alban-de-Roche"
            },
            {
                "value": 28433,
                "label": "Sermerieu",
                "label_ar": "Sermerieu",
                "label_fr": "Sermerieu"
            },
            {
                "value": 28434,
                "label": "Le Bouchage",
                "label_ar": "لو بوشاج",
                "label_fr": "Le Bouchage"
            },
            {
                "value": 28435,
                "label": "Corbelin",
                "label_ar": "كوربيلين",
                "label_fr": "Corbelin"
            },
            {
                "value": 28436,
                "label": "Athee",
                "label_ar": "أتي",
                "label_fr": "Athée"
            },
            {
                "value": 28437,
                "label": "Dolomieu",
                "label_ar": "دولوميو",
                "label_fr": "Dolomieu"
            },
            {
                "value": 28438,
                "label": "Eloyes",
                "label_ar": "الويس",
                "label_fr": "Eloyes"
            },
            {
                "value": 28439,
                "label": "Cessieu",
                "label_ar": "سيسيو",
                "label_fr": "Cessieu"
            },
            {
                "value": 28440,
                "label": "Guerting",
                "label_ar": "جويرتينج",
                "label_fr": "Guerting"
            },
            {
                "value": 28441,
                "label": "L'Hopital",
                "label_ar": "لوبيتال",
                "label_fr": "L'Hôpital"
            },
            {
                "value": 28442,
                "label": "Ippling",
                "label_ar": "إيبلينج",
                "label_fr": "Ippling"
            },
            {
                "value": 28443,
                "label": "Bozouls",
                "label_ar": "بوزولس",
                "label_fr": "Bozouls"
            },
            {
                "value": 28444,
                "label": "Espalion",
                "label_ar": "Espalion",
                "label_fr": "Espalion"
            },
            {
                "value": 28445,
                "label": "Cransac",
                "label_ar": "كرانساك",
                "label_fr": "Cransac"
            },
            {
                "value": 28446,
                "label": "Laissac",
                "label_ar": "لايساك",
                "label_fr": "Laissac"
            },
            {
                "value": 28447,
                "label": "Nointel",
                "label_ar": "Nointel",
                "label_fr": "Nointel"
            },
            {
                "value": 28448,
                "label": "Bernes-sur-Oise",
                "label_ar": "بيرنز سور واز",
                "label_fr": "Bernes-sur-Oise"
            },
            {
                "value": 28449,
                "label": "Sourdun",
                "label_ar": "سوردون",
                "label_fr": "Sourdun"
            },
            {
                "value": 28450,
                "label": "Saint-Brice",
                "label_ar": "سان بريس",
                "label_fr": "Saint-Brice"
            },
            {
                "value": 28451,
                "label": "Lelling",
                "label_ar": "ليلنج",
                "label_fr": "Lelling"
            },
            {
                "value": 28452,
                "label": "Messia-sur-Sorne",
                "label_ar": "ميسيا سور سورن",
                "label_fr": "Messie-sur-Sorne"
            },
            {
                "value": 28453,
                "label": "Vallangoujard",
                "label_ar": "فالانجوجارد",
                "label_fr": "Vallangoujard"
            },
            {
                "value": 28454,
                "label": "Grisy-Suisnes",
                "label_ar": "جريسي سويس",
                "label_fr": "Grisy-Suisnes"
            },
            {
                "value": 28455,
                "label": "Santeny",
                "label_ar": "سانتيني",
                "label_fr": "Santeny"
            },
            {
                "value": 28456,
                "label": "OEting",
                "label_ar": "OEting",
                "label_fr": "OEting"
            },
            {
                "value": 28457,
                "label": "Arnouville",
                "label_ar": "أرنوفيل",
                "label_fr": "Arnouville"
            },
            {
                "value": 28458,
                "label": "Saint-Maixant",
                "label_ar": "سانت ماكسانت",
                "label_fr": "Saint-Maixant"
            },
            {
                "value": 28459,
                "label": "Espira-de-l'Agly",
                "label_ar": "Espira-de-l'Agly",
                "label_fr": "Espira-de-l'Agly"
            },
            {
                "value": 28460,
                "label": "Tonnerre",
                "label_ar": "تونير",
                "label_fr": "Tonnerre"
            },
            {
                "value": 28461,
                "label": "Brison-Saint-Innocent",
                "label_ar": "بريسون سانت إنوسنت",
                "label_fr": "Brison-Saint-Innocent"
            },
            {
                "value": 28462,
                "label": "Mouxy",
                "label_ar": "موكسي",
                "label_fr": "Mouxy"
            },
            {
                "value": 28463,
                "label": "Ravenel",
                "label_ar": "رافينيل",
                "label_fr": "Ravenel"
            },
            {
                "value": 28464,
                "label": "Gruffy",
                "label_ar": "قذر",
                "label_fr": "Gruffy"
            },
            {
                "value": 28465,
                "label": "Longvic",
                "label_ar": "لونجفيتش",
                "label_fr": "Longvic"
            },
            {
                "value": 28466,
                "label": "Gambsheim",
                "label_ar": "جامبشيم",
                "label_fr": "Gambsheim"
            },
            {
                "value": 28467,
                "label": "Seronville",
                "label_ar": "سيرونفيل",
                "label_fr": "Seronville"
            },
            {
                "value": 28468,
                "label": "Aureilhan",
                "label_ar": "أوريلهان",
                "label_fr": "Aureilhan"
            },
            {
                "value": 28469,
                "label": "Nesles-la-Vallee",
                "label_ar": "نيسليس لا فاليه",
                "label_fr": "Nesles-la-Vallée"
            },
            {
                "value": 28470,
                "label": "Vic-la-Gardiole",
                "label_ar": "Vic-la-Gardiole",
                "label_fr": "Vic-la-Gardiole"
            },
            {
                "value": 28471,
                "label": "Blainville-sur-l'Eau",
                "label_ar": "Blainville-sur-l'Eau",
                "label_fr": "Blainville-sur-l'Eau"
            },
            {
                "value": 28472,
                "label": "Blenod-les-Toul",
                "label_ar": "بلينود ليه تول",
                "label_fr": "Blenod-les-Toul"
            },
            {
                "value": 28473,
                "label": "Azay-le-Rideau",
                "label_ar": "Azay-le-Rideau",
                "label_fr": "Azay-le-Rideau"
            },
            {
                "value": 28474,
                "label": "Cerelles",
                "label_ar": "سيريل",
                "label_fr": "Cerelles"
            },
            {
                "value": 28475,
                "label": "Rittershoffen",
                "label_ar": "ريترشوفن",
                "label_fr": "Rittershoffen"
            },
            {
                "value": 28476,
                "label": "Hohwiller",
                "label_ar": "هوهويلر",
                "label_fr": "Hohwiller"
            },
            {
                "value": 28477,
                "label": "Morigny-Champigny",
                "label_ar": "Morigny-Champigny",
                "label_fr": "Morigny-Champigny"
            },
            {
                "value": 28478,
                "label": "Coulon",
                "label_ar": "كولون",
                "label_fr": "Coulon"
            },
            {
                "value": 28479,
                "label": "Saint-Savin",
                "label_ar": "سان سافين",
                "label_fr": "Saint-Savin"
            },
            {
                "value": 28480,
                "label": "Les Eparres",
                "label_ar": "ليه إباريس",
                "label_fr": "Les Eparres"
            },
            {
                "value": 28481,
                "label": "Nivolas-Vermelle",
                "label_ar": "نيفولاس فيرميل",
                "label_fr": "Nivolas-Vermelle"
            },
            {
                "value": 28482,
                "label": "Neufmanil",
                "label_ar": "نيوفمانيل",
                "label_fr": "Neufmanil"
            },
            {
                "value": 28483,
                "label": "Aiglemont",
                "label_ar": "إيجلمونت",
                "label_fr": "Aiglemont"
            },
            {
                "value": 28484,
                "label": "Itteville",
                "label_ar": "إتفيل",
                "label_fr": "Itteville"
            },
            {
                "value": 28485,
                "label": "Bezannes",
                "label_ar": "بيزانيس",
                "label_fr": "Bezannes"
            },
            {
                "value": 28486,
                "label": "Capdenac-Gare",
                "label_ar": "كابديناك جير",
                "label_fr": "Capdenac-Gare"
            },
            {
                "value": 28487,
                "label": "Ecquevilly",
                "label_ar": "Ecquevilly",
                "label_fr": "Ecquevilly"
            },
            {
                "value": 28488,
                "label": "Saint-Christol-les-Ales",
                "label_ar": "سانت كريستول ليس أليس",
                "label_fr": "Saint-Christol-les-Alès"
            },
            {
                "value": 28489,
                "label": "Angerville-la-Martel",
                "label_ar": "أنجرفيل لا مارتل",
                "label_fr": "Angerville-la-Martel"
            },
            {
                "value": 28490,
                "label": "Valmont",
                "label_ar": "فالمونت",
                "label_fr": "Valmont"
            },
            {
                "value": 28491,
                "label": "Trept",
                "label_ar": "تربت",
                "label_fr": "Trept"
            },
            {
                "value": 28492,
                "label": "Cremieu",
                "label_ar": "كريميو",
                "label_fr": "Crémieu"
            },
            {
                "value": 28493,
                "label": "Panossas",
                "label_ar": "بانوساس",
                "label_fr": "Panossas"
            },
            {
                "value": 28494,
                "label": "Chamagnieu",
                "label_ar": "شامانيو",
                "label_fr": "Chamagnieu"
            },
            {
                "value": 28495,
                "label": "Wattwiller",
                "label_ar": "واتويلر",
                "label_fr": "Wattwiller"
            },
            {
                "value": 28496,
                "label": "Bievres",
                "label_ar": "بيفريس",
                "label_fr": "Bièvres"
            },
            {
                "value": 28497,
                "label": "Roderen",
                "label_ar": "رودرين",
                "label_fr": "Roderen"
            },
            {
                "value": 28498,
                "label": "Sanvignes-les-Mines",
                "label_ar": "Sanvignes-les-Mines",
                "label_fr": "Sanvignes-les-Mines"
            },
            {
                "value": 28499,
                "label": "Etricourt-Manancourt",
                "label_ar": "Etricourt-Manancourt",
                "label_fr": "Étricourt-Manancourt"
            },
            {
                "value": 28500,
                "label": "Saint-Macaire",
                "label_ar": "سان ماكير",
                "label_fr": "Saint-Macaire"
            },
            {
                "value": 28501,
                "label": "Toulenne",
                "label_ar": "تولين",
                "label_fr": "Toulenne"
            },
            {
                "value": 28502,
                "label": "Saint-Cheron-du-Chemin",
                "label_ar": "سان شيرون دو تشيمين",
                "label_fr": "Saint-Chéron-du-Chemin"
            },
            {
                "value": 28503,
                "label": "Janvry",
                "label_ar": "جانفري",
                "label_fr": "Janvry"
            },
            {
                "value": 28504,
                "label": "Briis-sous-Forges",
                "label_ar": "بريس سوس فورجس",
                "label_fr": "Briis-sous-Forges"
            },
            {
                "value": 28505,
                "label": "Saint-Jacques-de-la-Lande",
                "label_ar": "سان جاك دي لا لاندي",
                "label_fr": "Saint-Jacques-de-la-Lande"
            },
            {
                "value": 28506,
                "label": "Villiers-Adam",
                "label_ar": "فيلير آدم",
                "label_fr": "Villiers-Adam"
            },
            {
                "value": 28507,
                "label": "Epiais-Rhus",
                "label_ar": "Epiais-Rhus",
                "label_fr": "Epiais-Rhus"
            },
            {
                "value": 28508,
                "label": "Remecourt",
                "label_ar": "ريميكورت",
                "label_fr": "Remecourt"
            },
            {
                "value": 28509,
                "label": "Marines",
                "label_ar": "مشاة البحرية",
                "label_fr": "Marines"
            },
            {
                "value": 28510,
                "label": "Gallardon",
                "label_ar": "جالاردون",
                "label_fr": "Gallardon"
            },
            {
                "value": 28511,
                "label": "Magne",
                "label_ar": "ماجني",
                "label_fr": "Magne"
            },
            {
                "value": 28512,
                "label": "Guipry",
                "label_ar": "جبرى",
                "label_fr": "Guipry"
            },
            {
                "value": 28513,
                "label": "Gannat",
                "label_ar": "جنات",
                "label_fr": "Gannat"
            },
            {
                "value": 28514,
                "label": "Mozac",
                "label_ar": "موزاك",
                "label_fr": "Mozac"
            },
            {
                "value": 28515,
                "label": "Rupt-sur-Moselle",
                "label_ar": "Rupt-sur-Moselle",
                "label_fr": "Rupt-sur-Moselle"
            },
            {
                "value": 28516,
                "label": "Neris-les-Bains",
                "label_ar": "نيريس ليه با",
                "label_fr": "Néris-les-Bains"
            },
            {
                "value": 28517,
                "label": "Ramonchamp",
                "label_ar": "رامونشامب",
                "label_fr": "Ramonchamp"
            },
            {
                "value": 28518,
                "label": "Chamblet",
                "label_ar": "شامبلت",
                "label_fr": "Chamblet"
            },
            {
                "value": 28519,
                "label": "Cabestany",
                "label_ar": "كابستاني",
                "label_fr": "Cabestany"
            },
            {
                "value": 28520,
                "label": "La Buissiere",
                "label_ar": "لا بويسير",
                "label_fr": "La Buissière"
            },
            {
                "value": 28521,
                "label": "Gosnay",
                "label_ar": "جوسناي",
                "label_fr": "Gosnay"
            },
            {
                "value": 28522,
                "label": "Forges-les-Bains",
                "label_ar": "Forges-les-Bains",
                "label_fr": "Forges-les-Bains"
            },
            {
                "value": 28523,
                "label": "Berviller-en-Moselle",
                "label_ar": "بيرفيلر أون موسيل",
                "label_fr": "Berviller-en-Moselle"
            },
            {
                "value": 28524,
                "label": "Lizy-sur-Ourcq",
                "label_ar": "ليزي سور أورك",
                "label_fr": "Lizy-sur-Ourcq"
            },
            {
                "value": 28525,
                "label": "Varreddes",
                "label_ar": "فارريدس",
                "label_fr": "Varreddes"
            },
            {
                "value": 28526,
                "label": "Belleville",
                "label_ar": "بيلفيل",
                "label_fr": "Belleville"
            },
            {
                "value": 28527,
                "label": "Montceaux",
                "label_ar": "مونتسو",
                "label_fr": "Montceaux"
            },
            {
                "value": 28528,
                "label": "Rostrenen",
                "label_ar": "روسترينين",
                "label_fr": "Rostrenen"
            },
            {
                "value": 28529,
                "label": "Hangest-en-Santerre",
                "label_ar": "Hangest-en-Santerre",
                "label_fr": "Hangest-en-Santerre"
            },
            {
                "value": 28530,
                "label": "Touquin",
                "label_ar": "Touquin",
                "label_fr": "Touquin"
            },
            {
                "value": 28531,
                "label": "Rozay-en-Brie",
                "label_ar": "روزاي أون بري",
                "label_fr": "Rozay-en-Brie"
            },
            {
                "value": 28532,
                "label": "Courpalay",
                "label_ar": "كوربالاي",
                "label_fr": "Courpalay"
            },
            {
                "value": 28533,
                "label": "Malling",
                "label_ar": "مولينج",
                "label_fr": "Malling"
            },
            {
                "value": 28534,
                "label": "Lambersart",
                "label_ar": "لامبيرسارت",
                "label_fr": "Lambersart"
            },
            {
                "value": 28535,
                "label": "Wingen-sur-Moder",
                "label_ar": "Wingen-sur-Moder",
                "label_fr": "Wingen-sur-Moder"
            },
            {
                "value": 28536,
                "label": "Zittersheim",
                "label_ar": "زيترسهايم",
                "label_fr": "Zittersheim"
            },
            {
                "value": 28537,
                "label": "Verson",
                "label_ar": "فيرسون",
                "label_fr": "Verson"
            },
            {
                "value": 28538,
                "label": "Tourville-sur-Odon",
                "label_ar": "تورفيل سور أودون",
                "label_fr": "Tourville-sur-Odon"
            },
            {
                "value": 28539,
                "label": "Cheux",
                "label_ar": "تشيو",
                "label_fr": "Cheux"
            },
            {
                "value": 28540,
                "label": "Oberhergheim",
                "label_ar": "اوبرهيرغيم",
                "label_fr": "Oberhergheim"
            },
            {
                "value": 28541,
                "label": "Lexy",
                "label_ar": "ليكسي",
                "label_fr": "Lexy"
            },
            {
                "value": 28542,
                "label": "Crest",
                "label_ar": "قمة",
                "label_fr": "Crête"
            },
            {
                "value": 28543,
                "label": "Garat",
                "label_ar": "جارات",
                "label_fr": "Garat"
            },
            {
                "value": 28544,
                "label": "Balzac",
                "label_ar": "بلزاك",
                "label_fr": "Balzac"
            },
            {
                "value": 28545,
                "label": "Escaudoeuvres",
                "label_ar": "Escaudoeuvres",
                "label_fr": "Escaudoeuvres"
            },
            {
                "value": 28546,
                "label": "Thun-l'Eveque",
                "label_ar": "ثون-لافيك",
                "label_fr": "Thoune-l'Évêque"
            },
            {
                "value": 28547,
                "label": "Reguisheim",
                "label_ar": "ريجويشيم",
                "label_fr": "Reguisheim"
            },
            {
                "value": 28548,
                "label": "Burnhaupt-le-Haut",
                "label_ar": "بورنهاوبت لو أوت",
                "label_fr": "Burnhaupt-le-Haut"
            },
            {
                "value": 28549,
                "label": "Coutiches",
                "label_ar": "كوتيش",
                "label_fr": "Coutiches"
            },
            {
                "value": 28550,
                "label": "Port-sur-Saone",
                "label_ar": "بورت سور سون",
                "label_fr": "Port-sur-Saône"
            },
            {
                "value": 28551,
                "label": "Caille",
                "label_ar": "كايل",
                "label_fr": "Caille"
            },
            {
                "value": 28552,
                "label": "Chateauneuf-le-Rouge",
                "label_ar": "Chateauneuf-le-Rouge",
                "label_fr": "Châteauneuf-le-Rouge"
            },
            {
                "value": 28553,
                "label": "Mehun-sur-Yevre",
                "label_ar": "ميهون سور يفري",
                "label_fr": "Mehun-sur-Yèvre"
            },
            {
                "value": 28554,
                "label": "Longeville-les-Saint-Avold",
                "label_ar": "Longeville-les-Saint-Avold",
                "label_fr": "Longeville-les-Saint-Avold"
            },
            {
                "value": 28555,
                "label": "La Fare-en-Champsaur",
                "label_ar": "La Fare-en-Champsaur",
                "label_fr": "La Fare-en-Champsaur"
            },
            {
                "value": 28556,
                "label": "Lougres",
                "label_ar": "لوجرز",
                "label_fr": "Lougres"
            },
            {
                "value": 28557,
                "label": "Bavans",
                "label_ar": "بافانز",
                "label_fr": "Bavans"
            },
            {
                "value": 28558,
                "label": "Lailly-en-Val",
                "label_ar": "Lailly-en-Val",
                "label_fr": "Lailly-en-Val"
            },
            {
                "value": 28559,
                "label": "Noidans-les-Vesoul",
                "label_ar": "Noidans-les-Vesoul",
                "label_fr": "Noidans-les-Vesoul"
            },
            {
                "value": 28560,
                "label": "Bénouville",
                "label_ar": "بينوفيل",
                "label_fr": "Bénouville"
            },
            {
                "value": 28561,
                "label": "Sardieu",
                "label_ar": "سارديو",
                "label_fr": "Sardieu"
            },
            {
                "value": 28562,
                "label": "Wangen",
                "label_ar": "فانجين",
                "label_fr": "Wangen"
            },
            {
                "value": 28563,
                "label": "Saint-Simeon-de-Bressieux",
                "label_ar": "سان سيميون دي بريسيو",
                "label_fr": "Saint-Siméon-de-Bressieux"
            },
            {
                "value": 28564,
                "label": "Montois-la-Montagne",
                "label_ar": "مونتوا لا مونتاني",
                "label_fr": "Montois-la-Montagne"
            },
            {
                "value": 28565,
                "label": "Theize",
                "label_ar": "ثيم",
                "label_fr": "Theize"
            },
            {
                "value": 28566,
                "label": "Morhange",
                "label_ar": "مورشينج",
                "label_fr": "Morhange"
            },
            {
                "value": 28567,
                "label": "Baronville",
                "label_ar": "بارونفيل",
                "label_fr": "Baronville"
            },
            {
                "value": 28568,
                "label": "Saint-Drezery",
                "label_ar": "سان دريزري",
                "label_fr": "Saint-Drezery"
            },
            {
                "value": 28569,
                "label": "La Gaude",
                "label_ar": "لا جود",
                "label_fr": "La Gaude"
            },
            {
                "value": 28570,
                "label": "Saint-Jeannet",
                "label_ar": "سان جانيت",
                "label_fr": "Saint-Jeannet"
            },
            {
                "value": 28571,
                "label": "Vinzelles",
                "label_ar": "فينزيل",
                "label_fr": "Vinzelles"
            },
            {
                "value": 28572,
                "label": "Creches-sur-Saone",
                "label_ar": "Creches-sur-Saone",
                "label_fr": "Crèches-sur-Saône"
            },
            {
                "value": 28573,
                "label": "Saint-Evarzec",
                "label_ar": "سان إيفارزيك",
                "label_fr": "Saint-Evarzec"
            },
            {
                "value": 28574,
                "label": "Monts",
                "label_ar": "مونتس",
                "label_fr": "Monts"
            },
            {
                "value": 28575,
                "label": "Lanton",
                "label_ar": "لانتون",
                "label_fr": "Lanton"
            },
            {
                "value": 28576,
                "label": "Cinq-Mars-la-Pile",
                "label_ar": "سينك مارس لا بيل",
                "label_fr": "Cinq-Mars-la-Pile"
            },
            {
                "value": 28577,
                "label": "Luynes",
                "label_ar": "لوينيس",
                "label_fr": "Luynes"
            },
            {
                "value": 28578,
                "label": "Chinon",
                "label_ar": "شينون",
                "label_fr": "Chinon"
            },
            {
                "value": 28579,
                "label": "Beaumont-en-Veron",
                "label_ar": "بومونت أون فيرون",
                "label_fr": "Beaumont-en-Véron"
            },
            {
                "value": 28580,
                "label": "Saint-Aubin-sur-Mer",
                "label_ar": "سان اوبين سور مير",
                "label_fr": "Saint-Aubin-sur-Mer"
            },
            {
                "value": 28581,
                "label": "Issoudun",
                "label_ar": "ايسودون",
                "label_fr": "Issoudun"
            },
            {
                "value": 28582,
                "label": "Les Bordes",
                "label_ar": "ليه بوردس",
                "label_fr": "Les Bordes"
            },
            {
                "value": 28583,
                "label": "Seyssins",
                "label_ar": "سيسينز",
                "label_fr": "Seyssins"
            },
            {
                "value": 28584,
                "label": "Déols",
                "label_ar": "ديولز",
                "label_fr": "Déols"
            },
            {
                "value": 28585,
                "label": "Argenton-sur-Creuse",
                "label_ar": "أرجينتون سور كروز",
                "label_fr": "Argenton-sur-Creuse"
            },
            {
                "value": 28586,
                "label": "Le Tignet",
                "label_ar": "لو Tignet",
                "label_fr": "Le Tignet"
            },
            {
                "value": 28587,
                "label": "Chalonvillars",
                "label_ar": "شالونفيلارس",
                "label_fr": "Chalonvillars"
            },
            {
                "value": 28588,
                "label": "Thierville-sur-Meuse",
                "label_ar": "ثيرفيل سور ميوز",
                "label_fr": "Thierville-sur-Meuse"
            },
            {
                "value": 28589,
                "label": "Cruet",
                "label_ar": "إبريق زجاجي",
                "label_fr": "Burette"
            },
            {
                "value": 28590,
                "label": "Rotherens",
                "label_ar": "روثرنس",
                "label_fr": "Rotherens"
            },
            {
                "value": 28591,
                "label": "Beauchamp",
                "label_ar": "بوشامب",
                "label_fr": "Beauchamp"
            },
            {
                "value": 28592,
                "label": "Vert-le-Petit",
                "label_ar": "فيرت لو بيتي",
                "label_fr": "Vert-le-Petit"
            },
            {
                "value": 28593,
                "label": "Le Plessis-Pate",
                "label_ar": "لو بليسيس بات",
                "label_fr": "Le Plessis-Pate"
            },
            {
                "value": 28594,
                "label": "Leuville-sur-Orge",
                "label_ar": "لوفيل سور أورج",
                "label_fr": "Leuville-sur-Orge"
            },
            {
                "value": 28595,
                "label": "Haulchin",
                "label_ar": "هولشين",
                "label_fr": "Haulchin"
            },
            {
                "value": 28596,
                "label": "Veauche",
                "label_ar": "Veauche",
                "label_fr": "Veauche"
            },
            {
                "value": 28597,
                "label": "Anse",
                "label_ar": "آنس",
                "label_fr": "Anse"
            },
            {
                "value": 28598,
                "label": "Reyrieux",
                "label_ar": "رييو",
                "label_fr": "Reyrieux"
            },
            {
                "value": 28599,
                "label": "Argeville",
                "label_ar": "أرجفيل",
                "label_fr": "Argeville"
            },
            {
                "value": 28600,
                "label": "Pussay",
                "label_ar": "بوسي",
                "label_fr": "Chatte"
            },
            {
                "value": 28601,
                "label": "Amillis",
                "label_ar": "اميليس",
                "label_fr": "Amillis"
            },
            {
                "value": 28602,
                "label": "Communay",
                "label_ar": "Communay",
                "label_fr": "Communay"
            },
            {
                "value": 28603,
                "label": "Chasne-sur-Illet",
                "label_ar": "شاسن سور ايليت",
                "label_fr": "Chasne-sur-Illet"
            },
            {
                "value": 28604,
                "label": "Lasson",
                "label_ar": "لاسون",
                "label_fr": "Lasson"
            },
            {
                "value": 28605,
                "label": "Thaon",
                "label_ar": "ثون",
                "label_fr": "Thaon"
            },
            {
                "value": 28606,
                "label": "Beauval",
                "label_ar": "بوفال",
                "label_fr": "Beauval"
            },
            {
                "value": 28607,
                "label": "Echenoz-la-Meline",
                "label_ar": "Echenoz-la-Meline",
                "label_fr": "Echenoz-la-Méline"
            },
            {
                "value": 28608,
                "label": "Bourguebus",
                "label_ar": "بورجبوس",
                "label_fr": "Bourguebus"
            },
            {
                "value": 28609,
                "label": "Sainte-Catherine",
                "label_ar": "سانت كاترين",
                "label_fr": "Sainte-Catherine"
            },
            {
                "value": 28610,
                "label": "Champtercier",
                "label_ar": "تشامبيرتشير",
                "label_fr": "Champtercier"
            },
            {
                "value": 28611,
                "label": "Florensac",
                "label_ar": "فلورنساك",
                "label_fr": "Florensac"
            },
            {
                "value": 28612,
                "label": "Beaucaire",
                "label_ar": "بوكير",
                "label_fr": "Beaucaire"
            },
            {
                "value": 28613,
                "label": "Feuguerolles-sur-Seulles",
                "label_ar": "Feuguerolles-sur-Seulles",
                "label_fr": "Feuguerolles-sur-Seulles"
            },
            {
                "value": 28614,
                "label": "Malijai",
                "label_ar": "ماليجاي",
                "label_fr": "Malijai"
            },
            {
                "value": 28615,
                "label": "Saint-Andre-lez-Lille",
                "label_ar": "سان أندريه ليز ليل",
                "label_fr": "Saint-André-lez-Lille"
            },
            {
                "value": 28616,
                "label": "Tourlaville",
                "label_ar": "تورلافيل",
                "label_fr": "Tourlaville"
            },
            {
                "value": 28617,
                "label": "Champhol",
                "label_ar": "شامفول",
                "label_fr": "Champhol"
            },
            {
                "value": 28618,
                "label": "Luce",
                "label_ar": "لوس",
                "label_fr": "Luce"
            },
            {
                "value": 28619,
                "label": "Saint-Marcel-Bel-Accueil",
                "label_ar": "سان مارسيل بيل أكويل",
                "label_fr": "Saint-Marcel-Bel-Accueil"
            },
            {
                "value": 28620,
                "label": "Erome",
                "label_ar": "إيروم",
                "label_fr": "Erome"
            },
            {
                "value": 28621,
                "label": "Cattenom",
                "label_ar": "كاتينوم",
                "label_fr": "Cattenom"
            },
            {
                "value": 28622,
                "label": "Vallier",
                "label_ar": "فالير",
                "label_fr": "Vallier"
            },
            {
                "value": 28623,
                "label": "Custines",
                "label_ar": "كاستين",
                "label_fr": "Custines"
            },
            {
                "value": 28624,
                "label": "Champigneulles",
                "label_ar": "شامبينيول",
                "label_fr": "Champigneulles"
            },
            {
                "value": 28625,
                "label": "Bubry",
                "label_ar": "بوبري",
                "label_fr": "Bubry"
            },
            {
                "value": 28626,
                "label": "Ailly-sur-Somme",
                "label_ar": "ايلي سور سوم",
                "label_fr": "Ailly-sur-Somme"
            },
            {
                "value": 28627,
                "label": "Villers-Bretonneux",
                "label_ar": "فيليرز بريتونوكس",
                "label_fr": "Villers-Bretonneux"
            },
            {
                "value": 28628,
                "label": "Reaumont",
                "label_ar": "ريومونت",
                "label_fr": "Reaumont"
            },
            {
                "value": 28629,
                "label": "La Ville aux Chiens",
                "label_ar": "La Ville aux Chiens",
                "label_fr": "La Ville aux Chiens"
            },
            {
                "value": 28630,
                "label": "Pechabou",
                "label_ar": "بيتشابو",
                "label_fr": "Pechabou"
            },
            {
                "value": 28631,
                "label": "Montgiscard",
                "label_ar": "مونتجيسكار",
                "label_fr": "Montgiscard"
            },
            {
                "value": 28632,
                "label": "Villenouvelle",
                "label_ar": "فيلينوفيل",
                "label_fr": "Villenouvelle"
            },
            {
                "value": 28633,
                "label": "La Barre-de-Monts",
                "label_ar": "لا بار دو مونت",
                "label_fr": "La Barre-de-Monts"
            },
            {
                "value": 28634,
                "label": "Dombasle-sur-Meurthe",
                "label_ar": "دومباسل سور ميورث",
                "label_fr": "Dombasle-sur-Meurthe"
            },
            {
                "value": 28635,
                "label": "Montbazin",
                "label_ar": "مونتبازين",
                "label_fr": "Montbazin"
            },
            {
                "value": 28636,
                "label": "Laverune",
                "label_ar": "لافيرون",
                "label_fr": "Laverune"
            },
            {
                "value": 28637,
                "label": "Combronde",
                "label_ar": "كومبروند",
                "label_fr": "Combronde"
            },
            {
                "value": 28638,
                "label": "Ennezat",
                "label_ar": "انزات",
                "label_fr": "Ennezat"
            },
            {
                "value": 28639,
                "label": "Beauregard-Vendon",
                "label_ar": "بيوريجارد فيندون",
                "label_fr": "Beauregard-Vendon"
            },
            {
                "value": 28640,
                "label": "Prompsat",
                "label_ar": "برومبسات",
                "label_fr": "Prompsat"
            },
            {
                "value": 28641,
                "label": "Argenton-les-Vallees",
                "label_ar": "أرجنتون ليه فالي",
                "label_fr": "Argenton-les-Vallées"
            },
            {
                "value": 28642,
                "label": "Saint-Aubin-du-Plain",
                "label_ar": "سان أوبين دو بلين",
                "label_fr": "Saint-Aubin-du-Plain"
            },
            {
                "value": 28643,
                "label": "Aiffres",
                "label_ar": "ايفرس",
                "label_fr": "Aiffres"
            },
            {
                "value": 28644,
                "label": "Château de Loches",
                "label_ar": "شاتو دي لوش",
                "label_fr": "Château de Loches"
            },
            {
                "value": 28645,
                "label": "Saint-Jean-Saint-Germain",
                "label_ar": "سان جان سان جيرمان",
                "label_fr": "Saint-Jean-Saint-Germain"
            },
            {
                "value": 28646,
                "label": "Varennes-Jarcy",
                "label_ar": "فارينيس جارسي",
                "label_fr": "Varennes-Jarcy"
            },
            {
                "value": 28647,
                "label": "Thezan-les-Beziers",
                "label_ar": "Thezan-les-Beziers",
                "label_fr": "Thezan-les-Béziers"
            },
            {
                "value": 28648,
                "label": "Murviel-les-Montpellier",
                "label_ar": "مورفيل لي مونبلييه",
                "label_fr": "Murviel-les-Montpellier"
            },
            {
                "value": 28649,
                "label": "Saint-Martin-d'Uriage",
                "label_ar": "سان مارتن دورياج",
                "label_fr": "Saint-Martin-d'Uriage"
            },
            {
                "value": 28650,
                "label": "Kaltenhouse",
                "label_ar": "كالتنهاوس",
                "label_fr": "Kaltenhouse"
            },
            {
                "value": 28651,
                "label": "Le Boulay-Morin",
                "label_ar": "لو بولاي مورين",
                "label_fr": "Le Boulay-Morin"
            },
            {
                "value": 28652,
                "label": "Normanville",
                "label_ar": "نورمانفيل",
                "label_fr": "Normanville"
            },
            {
                "value": 28653,
                "label": "Roques",
                "label_ar": "روك",
                "label_fr": "Roques"
            },
            {
                "value": 28654,
                "label": "Bédarieux",
                "label_ar": "بيداريو",
                "label_fr": "Bédarieux"
            },
            {
                "value": 28655,
                "label": "Herepian",
                "label_ar": "عشبي",
                "label_fr": "Hérépien"
            },
            {
                "value": 28656,
                "label": "Servas",
                "label_ar": "سيرفاس",
                "label_fr": "Servas"
            },
            {
                "value": 28657,
                "label": "Cournonterral",
                "label_ar": "كورنوترال",
                "label_fr": "Cournonterral"
            },
            {
                "value": 28658,
                "label": "Gigean",
                "label_ar": "جيجين",
                "label_fr": "Gigean"
            },
            {
                "value": 28659,
                "label": "Saint-Jean-de-Vedas",
                "label_ar": "سان جان دي فيدا",
                "label_fr": "Saint-Jean-de-Vedas"
            },
            {
                "value": 28660,
                "label": "Dachstein",
                "label_ar": "داتشستين",
                "label_fr": "Dachstein"
            },
            {
                "value": 28661,
                "label": "Mathieu",
                "label_ar": "ماتيو",
                "label_fr": "Mathieu"
            },
            {
                "value": 28662,
                "label": "Saint-Junien",
                "label_ar": "سان جونين",
                "label_fr": "Saint-Junien"
            },
            {
                "value": 28663,
                "label": "Avressieux",
                "label_ar": "أفريسيو",
                "label_fr": "Avressieux"
            },
            {
                "value": 28664,
                "label": "Gauriac",
                "label_ar": "جورياك",
                "label_fr": "Gauriac"
            },
            {
                "value": 28665,
                "label": "Aoste",
                "label_ar": "أوستي",
                "label_fr": "Aoste"
            },
            {
                "value": 28666,
                "label": "Saint-Genix-sur-Guiers",
                "label_ar": "سان جينيكس سور جوييه",
                "label_fr": "Saint-Genix-sur-Guiers"
            },
            {
                "value": 28667,
                "label": "Domessin",
                "label_ar": "دوميسين",
                "label_fr": "Domessin"
            },
            {
                "value": 28668,
                "label": "Oradour-sur-Glane",
                "label_ar": "أورادور سور جلان",
                "label_fr": "Oradour-sur-Glane"
            },
            {
                "value": 28669,
                "label": "Compreignac",
                "label_ar": "كومبريينياك",
                "label_fr": "Compreignac"
            },
            {
                "value": 28670,
                "label": "Saint-Leonard-de-Noblat",
                "label_ar": "سانت ليونارد دي نوبلات",
                "label_fr": "Saint-Léonard-de-Noblat"
            },
            {
                "value": 28671,
                "label": "Saint-Jouvent",
                "label_ar": "سان جوفينت",
                "label_fr": "Saint-Jouvent"
            },
            {
                "value": 28672,
                "label": "Couvron-et-Aumencourt",
                "label_ar": "Couvron-et-Aumencourt",
                "label_fr": "Couvron-et-Aumencourt"
            },
            {
                "value": 28673,
                "label": "Troarn",
                "label_ar": "ترين",
                "label_fr": "Troarn"
            },
            {
                "value": 28674,
                "label": "Festieux",
                "label_ar": "فيستيو",
                "label_fr": "Festieux"
            },
            {
                "value": 28675,
                "label": "Heutregiville",
                "label_ar": "هيوتريجيفيل",
                "label_fr": "Heutregiville"
            },
            {
                "value": 28676,
                "label": "Aguilcourt",
                "label_ar": "اغيلكورت",
                "label_fr": "Aguilcourt"
            },
            {
                "value": 28677,
                "label": "Guignicourt",
                "label_ar": "Guignicourt",
                "label_fr": "Guignicourt"
            },
            {
                "value": 28678,
                "label": "Attigny",
                "label_ar": "اتيني",
                "label_fr": "Attigny"
            },
            {
                "value": 28679,
                "label": "Joigny-sur-Meuse",
                "label_ar": "Joigny-sur-Meuse",
                "label_fr": "Joigny-sur-Meuse"
            },
            {
                "value": 28680,
                "label": "Revin",
                "label_ar": "ريفين",
                "label_fr": "Revin"
            },
            {
                "value": 28681,
                "label": "Rocroi",
                "label_ar": "روكروي",
                "label_fr": "Rocroi"
            },
            {
                "value": 28682,
                "label": "Saint-Parres-les-Vaudes",
                "label_ar": "Saint-Parres-les-Vaudes",
                "label_fr": "Saint-Parres-les-Vaudes"
            },
            {
                "value": 28683,
                "label": "Treves",
                "label_ar": "تريفيس",
                "label_fr": "Treves"
            },
            {
                "value": 28684,
                "label": "Montseveroux",
                "label_ar": "مونتسفيروكس",
                "label_fr": "Montseveroux"
            },
            {
                "value": 28685,
                "label": "Chaufour-Notre-Dame",
                "label_ar": "شوفور نوتردام",
                "label_fr": "Chaufour-Notre-Dame"
            },
            {
                "value": 28686,
                "label": "Noisy-sur-Ecole",
                "label_ar": "صاخبة سور إيكول",
                "label_fr": "Noisy-sur-Ecole"
            },
            {
                "value": 28687,
                "label": "Sevrier",
                "label_ar": "سيفير",
                "label_fr": "Sevrier"
            },
            {
                "value": 28688,
                "label": "Saint-Andre-de-l'Eure",
                "label_ar": "سان أندريه دي لور",
                "label_fr": "Saint-André-de-l'Eure"
            },
            {
                "value": 28689,
                "label": "Garencieres",
                "label_ar": "غارينسيريس",
                "label_fr": "Garencières"
            },
            {
                "value": 28690,
                "label": "Mousseaux-Neuville",
                "label_ar": "موسو نوفيل",
                "label_fr": "Mousseaux-Neuville"
            },
            {
                "value": 28691,
                "label": "Sainte-Tulle",
                "label_ar": "سانت تول",
                "label_fr": "Sainte-Tulle"
            },
            {
                "value": 28692,
                "label": "Montfuron",
                "label_ar": "مونتفورون",
                "label_fr": "Montfuron"
            },
            {
                "value": 28693,
                "label": "Sivry-sur-Meuse",
                "label_ar": "سيفري سور ميوز",
                "label_fr": "Sivry-sur-Meuse"
            },
            {
                "value": 28694,
                "label": "Brieulles-sur-Meuse",
                "label_ar": "بريول سور ميوز",
                "label_fr": "Brieulles-sur-Meuse"
            },
            {
                "value": 28695,
                "label": "Stenay",
                "label_ar": "ستيناي",
                "label_fr": "Stenay"
            },
            {
                "value": 28696,
                "label": "Cherisy",
                "label_ar": "شيريسي",
                "label_fr": "Cherisy"
            },
            {
                "value": 28697,
                "label": "Mercy-le-Bas",
                "label_ar": "ميرسي لو باس",
                "label_fr": "Mercy-le-Bas"
            },
            {
                "value": 28698,
                "label": "Bouligny",
                "label_ar": "بوليني",
                "label_fr": "Bouligny"
            },
            {
                "value": 28699,
                "label": "Gauriaguet",
                "label_ar": "جورياجيت",
                "label_fr": "Gauriaguet"
            },
            {
                "value": 28700,
                "label": "Peltre",
                "label_ar": "بيلتر",
                "label_fr": "Peltre"
            },
            {
                "value": 28701,
                "label": "Talange",
                "label_ar": "تالانج",
                "label_fr": "Talange"
            },
            {
                "value": 28702,
                "label": "Maizeroy",
                "label_ar": "Maizeroy",
                "label_fr": "Maizeroy"
            },
            {
                "value": 28703,
                "label": "Apach",
                "label_ar": "اباتش",
                "label_fr": "Apach"
            },
            {
                "value": 28704,
                "label": "Rettel",
                "label_ar": "ريتل",
                "label_fr": "Rettel"
            },
            {
                "value": 28705,
                "label": "Aigueblanche",
                "label_ar": "Aigueblanche",
                "label_fr": "Aigueblanche"
            },
            {
                "value": 28706,
                "label": "Metzeresche",
                "label_ar": "ميتزيريشه",
                "label_fr": "Metzeresche"
            },
            {
                "value": 28707,
                "label": "Augny",
                "label_ar": "أوغني",
                "label_fr": "Augny"
            },
            {
                "value": 28708,
                "label": "Filstroff",
                "label_ar": "فيلستروف",
                "label_fr": "Filstroff"
            },
            {
                "value": 28709,
                "label": "Laquenexy",
                "label_ar": "لاكوينكسي",
                "label_fr": "Laquenexy"
            },
            {
                "value": 28710,
                "label": "Courcelles-sur-Nied",
                "label_ar": "كورسيل سور نيد",
                "label_fr": "Courcelles-sur-Nied"
            },
            {
                "value": 28711,
                "label": "Boulay-Moselle",
                "label_ar": "بولاي موسيل",
                "label_fr": "Boulay-Moselle"
            },
            {
                "value": 28712,
                "label": "Volmerange-les-Boulay",
                "label_ar": "فولمرانج ليه بولاي",
                "label_fr": "Volmerange-les-Boulay"
            },
            {
                "value": 28713,
                "label": "Illiers-l'Eveque",
                "label_ar": "Illiers-l'Eveque",
                "label_fr": "Illiers-l'Évêque"
            },
            {
                "value": 28714,
                "label": "Courdemanche",
                "label_ar": "كورديمانش",
                "label_fr": "Courdemanche"
            },
            {
                "value": 28715,
                "label": "Sorel-Moussel",
                "label_ar": "سوريل موسيل",
                "label_fr": "Sorel-Moussel"
            },
            {
                "value": 28716,
                "label": "Ezy-sur-Eure",
                "label_ar": "ازي سور اوري",
                "label_fr": "Ezy-sur-Eure"
            },
            {
                "value": 28717,
                "label": "Saint-Remy-sur-Avre",
                "label_ar": "سان ريمي سور افر",
                "label_fr": "Saint-Rémy-sur-Avre"
            },
            {
                "value": 28718,
                "label": "Saucats",
                "label_ar": "الصلصات",
                "label_fr": "Saucats"
            },
            {
                "value": 28719,
                "label": "Eterville",
                "label_ar": "إترفيل",
                "label_fr": "Eterville"
            },
            {
                "value": 28720,
                "label": "Binic",
                "label_ar": "بينيك",
                "label_fr": "Binic"
            },
            {
                "value": 28721,
                "label": "Garennes-sur-Eure",
                "label_ar": "Garennes-sur-Eure",
                "label_fr": "Garennes-sur-Eure"
            },
            {
                "value": 28722,
                "label": "Etables-sur-Mer",
                "label_ar": "Etables-sur-Mer",
                "label_fr": "Etables-sur-Mer"
            },
            {
                "value": 28723,
                "label": "Paimpol",
                "label_ar": "بيمبول",
                "label_fr": "Paimpol"
            },
            {
                "value": 28724,
                "label": "Kerfot",
                "label_ar": "كيرفوت",
                "label_fr": "Kerfot"
            },
            {
                "value": 28725,
                "label": "Pomerols",
                "label_ar": "بوميرولس",
                "label_fr": "Pomérols"
            },
            {
                "value": 28726,
                "label": "Pacy-sur-Eure",
                "label_ar": "باسي سور اوري",
                "label_fr": "Pacy-sur-Eure"
            },
            {
                "value": 28727,
                "label": "Villiers-en-Desoeuvre",
                "label_ar": "Villiers-en-Desoeuvre",
                "label_fr": "Villiers-en-Desoeuvre"
            },
            {
                "value": 28728,
                "label": "Pinet",
                "label_ar": "بينت",
                "label_fr": "Pinet"
            },
            {
                "value": 28729,
                "label": "Menville",
                "label_ar": "مينفيل",
                "label_fr": "Menville"
            },
            {
                "value": 28730,
                "label": "Pierrevert",
                "label_ar": "بييرفيرت",
                "label_fr": "Pierrevert"
            },
            {
                "value": 28731,
                "label": "Verneuil-sur-Avre",
                "label_ar": "فيرنويل سور افر",
                "label_fr": "Verneuil-sur-Avre"
            },
            {
                "value": 28732,
                "label": "Menucourt",
                "label_ar": "مينوكورت",
                "label_fr": "Ménucourt"
            },
            {
                "value": 28733,
                "label": "Boisemont",
                "label_ar": "Boisemont",
                "label_fr": "Boisemont"
            },
            {
                "value": 28734,
                "label": "Saint-Ouen-en-Brie",
                "label_ar": "سان أوين أون بري",
                "label_fr": "Saint-Ouen-en-Brie"
            },
            {
                "value": 28735,
                "label": "Villeneuve-le-Comte",
                "label_ar": "فيلنوف لو كومت",
                "label_fr": "Villeneuve-le-Comte"
            },
            {
                "value": 28736,
                "label": "Mathay",
                "label_ar": "ماثي",
                "label_fr": "Mathay"
            },
            {
                "value": 28737,
                "label": "Mandeure",
                "label_ar": "مانديور",
                "label_fr": "Mandeure"
            },
            {
                "value": 28738,
                "label": "Saint-Omer-en-Chaussee",
                "label_ar": "سان أومير أون شاوسي",
                "label_fr": "Saint-Omer-en-Chaussée"
            },
            {
                "value": 28739,
                "label": "Truchtersheim",
                "label_ar": "Truchtersheim",
                "label_fr": "Truchtersheim"
            },
            {
                "value": 28740,
                "label": "Brumath",
                "label_ar": "بروماث",
                "label_fr": "Brumath"
            },
            {
                "value": 28741,
                "label": "Feldkirch",
                "label_ar": "فيلدكيرش",
                "label_fr": "Feldkirch"
            },
            {
                "value": 28742,
                "label": "Durmenach",
                "label_ar": "دورميناتش",
                "label_fr": "Durmenach"
            },
            {
                "value": 28743,
                "label": "Coray",
                "label_ar": "كوراي",
                "label_fr": "Coray"
            },
            {
                "value": 28744,
                "label": "Goven",
                "label_ar": "غوفن",
                "label_fr": "Goven"
            },
            {
                "value": 28745,
                "label": "Arcay",
                "label_ar": "اركاي",
                "label_fr": "Arcay"
            },
            {
                "value": 28746,
                "label": "Orval",
                "label_ar": "أورفال",
                "label_fr": "Orval"
            },
            {
                "value": 28747,
                "label": "Courcelles-sur-Seine",
                "label_ar": "كورسيل سور سين",
                "label_fr": "Courcelles-sur-Seine"
            },
            {
                "value": 28748,
                "label": "Bois-Guillaume",
                "label_ar": "بوا غيوم",
                "label_fr": "Bois-Guillaume"
            },
            {
                "value": 28749,
                "label": "Martinvast",
                "label_ar": "مارتينفاست",
                "label_fr": "Martinvast"
            },
            {
                "value": 28750,
                "label": "Brehal",
                "label_ar": "بريهال",
                "label_fr": "Brehal"
            },
            {
                "value": 28751,
                "label": "Isneauville",
                "label_ar": "Isneauville",
                "label_fr": "Isneauville"
            },
            {
                "value": 28752,
                "label": "Corps-Nuds",
                "label_ar": "فيلق نودز",
                "label_fr": "Corps-Nuds"
            },
            {
                "value": 28753,
                "label": "Vendeuvre-du-Poitou",
                "label_ar": "Vendeuvre-du-Poitou",
                "label_fr": "Vendeuvre-du-Poitou"
            },
            {
                "value": 28754,
                "label": "Valmont",
                "label_ar": "فالمونت",
                "label_fr": "Valmont"
            },
            {
                "value": 28755,
                "label": "Macheren",
                "label_ar": "ماتشيرين",
                "label_fr": "Macheren"
            },
            {
                "value": 28756,
                "label": "Pont-de-Roide",
                "label_ar": "بونت دي رويد",
                "label_fr": "Pont-de-Roide"
            },
            {
                "value": 28757,
                "label": "Hyemondans",
                "label_ar": "الهيموندان",
                "label_fr": "Hyémondans"
            },
            {
                "value": 28758,
                "label": "Bourseville",
                "label_ar": "بورسفيل",
                "label_fr": "Bourseville"
            },
            {
                "value": 28759,
                "label": "Quincy-sous-Senart",
                "label_ar": "كوينسي سو سينارت",
                "label_fr": "Quincy-sous-Senart"
            },
            {
                "value": 28760,
                "label": "Brancourt-en-Laonnois",
                "label_ar": "برانكورت أون لاونوا",
                "label_fr": "Brancourt-en-Laonnois"
            },
            {
                "value": 28761,
                "label": "Cheval-Blanc",
                "label_ar": "شيفال بلانك",
                "label_fr": "Cheval-Blanc"
            },
            {
                "value": 28762,
                "label": "Montagny-les-Lanches",
                "label_ar": "مونتاني ليه لانش",
                "label_fr": "Montagny-les-Lanches"
            },
            {
                "value": 28763,
                "label": "Nogent-le-Roi",
                "label_ar": "نوجينت لو روي",
                "label_fr": "Nogent-le-Roi"
            },
            {
                "value": 28764,
                "label": "La Verriere",
                "label_ar": "لا فيرير",
                "label_fr": "La Verrière"
            },
            {
                "value": 28765,
                "label": "La Turballe",
                "label_ar": "لا توربال",
                "label_fr": "La Turballe"
            },
            {
                "value": 28766,
                "label": "Hannogne-Saint-Martin",
                "label_ar": "هانوجن سانت مارتن",
                "label_fr": "Hannogne-Saint-Martin"
            },
            {
                "value": 28767,
                "label": "Sainte-Catherine",
                "label_ar": "سانت كاترين",
                "label_fr": "Sainte-Catherine"
            },
            {
                "value": 28768,
                "label": "Dainville",
                "label_ar": "داينفيل",
                "label_fr": "Dainville"
            },
            {
                "value": 28769,
                "label": "Haybes",
                "label_ar": "هايبس",
                "label_fr": "Haybes"
            },
            {
                "value": 28770,
                "label": "Fumay",
                "label_ar": "فوماي",
                "label_fr": "Fumay"
            },
            {
                "value": 28771,
                "label": "Cuincy",
                "label_ar": "كوينسي",
                "label_fr": "Cuincy"
            },
            {
                "value": 28772,
                "label": "Essert",
                "label_ar": "Essert",
                "label_fr": "Essert"
            },
            {
                "value": 28773,
                "label": "Chocques",
                "label_ar": "الشوكولا",
                "label_fr": "Chocques"
            },
            {
                "value": 28774,
                "label": "Maurecourt",
                "label_ar": "موريكور",
                "label_fr": "Maurecourt"
            },
            {
                "value": 28775,
                "label": "Seyssinet",
                "label_ar": "سيسينت",
                "label_fr": "Seyssinet"
            },
            {
                "value": 28776,
                "label": "Puisserguier",
                "label_ar": "Puisserguier",
                "label_fr": "Puisserguier"
            },
            {
                "value": 28777,
                "label": "Vitry-en-Artois",
                "label_ar": "فيتري أون أرتويس",
                "label_fr": "Vitry-en-Artois"
            },
            {
                "value": 28778,
                "label": "Villenave",
                "label_ar": "فيلناف",
                "label_fr": "Villenave"
            },
            {
                "value": 28779,
                "label": "Breuilpont",
                "label_ar": "بريويل بونت",
                "label_fr": "Breuilpont"
            },
            {
                "value": 28780,
                "label": "Thorigny-sur-Marne",
                "label_ar": "ثوريني سور مارن",
                "label_fr": "Thorigny-sur-Marne"
            },
            {
                "value": 28781,
                "label": "Saulxures",
                "label_ar": "شاول",
                "label_fr": "Saulxures"
            },
            {
                "value": 28782,
                "label": "La Ravoire",
                "label_ar": "لا رافوار",
                "label_fr": "La Ravoire"
            },
            {
                "value": 28783,
                "label": "Saint-Quentin",
                "label_ar": "سانت كوينتين",
                "label_fr": "Saint-Quentin"
            },
            {
                "value": 28784,
                "label": "Lapte",
                "label_ar": "لابتي",
                "label_fr": "Lapte"
            },
            {
                "value": 28785,
                "label": "Saint-Sauvant",
                "label_ar": "سانت سوفانت",
                "label_fr": "Saint-Sauvant"
            },
            {
                "value": 28786,
                "label": "Feternes",
                "label_ar": "فيترنس",
                "label_fr": "Féternes"
            },
            {
                "value": 28787,
                "label": "Montberon",
                "label_ar": "مونبيرون",
                "label_fr": "Montberon"
            },
            {
                "value": 28788,
                "label": "Tonneins",
                "label_ar": "تونينز",
                "label_fr": "Tonneins"
            },
            {
                "value": 28789,
                "label": "Noueilles",
                "label_ar": "نويل",
                "label_fr": "Noueilles"
            },
            {
                "value": 28790,
                "label": "Saleilles",
                "label_ar": "Saleilles",
                "label_fr": "Saleilles"
            },
            {
                "value": 28791,
                "label": "Cessales",
                "label_ar": "سيساليس",
                "label_fr": "Cessales"
            },
            {
                "value": 28792,
                "label": "Courcy",
                "label_ar": "مراوغة",
                "label_fr": "Courcy"
            },
            {
                "value": 28793,
                "label": "La Londe-les-Maures",
                "label_ar": "لا لوند لي موريس",
                "label_fr": "La Londe-les-Maures"
            },
            {
                "value": 28794,
                "label": "Hoymille",
                "label_ar": "هوميل",
                "label_fr": "Hoymille"
            },
            {
                "value": 28795,
                "label": "Tallende",
                "label_ar": "تاليندي",
                "label_fr": "Tallende"
            },
            {
                "value": 28796,
                "label": "Geovreisset",
                "label_ar": "Geovreisset",
                "label_fr": "Geovreisset"
            },
            {
                "value": 28797,
                "label": "Brion",
                "label_ar": "بريون",
                "label_fr": "Brion"
            },
            {
                "value": 28798,
                "label": "Montfort-l'Amaury",
                "label_ar": "Montfort-l'Amaury",
                "label_fr": "Montfort-l'Amaury"
            },
            {
                "value": 28799,
                "label": "Rivery",
                "label_ar": "ريفيري",
                "label_fr": "Rivery"
            },
            {
                "value": 28800,
                "label": "Gometz-le-Chatel",
                "label_ar": "جوميتز لو شاتيل",
                "label_fr": "Gometz-le-Châtel"
            },
            {
                "value": 28801,
                "label": "Les Echelles",
                "label_ar": "ليس إيشيل",
                "label_fr": "Les Echelles"
            },
            {
                "value": 28802,
                "label": "Mens",
                "label_ar": "ملك الرجال",
                "label_fr": "Pour des hommes"
            },
            {
                "value": 28803,
                "label": "Noyarey",
                "label_ar": "نوياري",
                "label_fr": "Noyarey"
            },
            {
                "value": 28804,
                "label": "Notre-Dame-du-Pre",
                "label_ar": "نوتردام دو بري",
                "label_fr": "Notre-Dame-du-Pré"
            },
            {
                "value": 28805,
                "label": "Puicheric",
                "label_ar": "Puicheric",
                "label_fr": "Puicheric"
            },
            {
                "value": 28806,
                "label": "Domancy",
                "label_ar": "الهيمنة",
                "label_fr": "Domancy"
            },
            {
                "value": 28807,
                "label": "Saint-Saturnin-les-Apt",
                "label_ar": "Saint-Saturnin-les-Apt",
                "label_fr": "Saint-Saturnin-les-Apt"
            },
            {
                "value": 28808,
                "label": "La Tronche",
                "label_ar": "لا ترونش",
                "label_fr": "La Tronche"
            },
            {
                "value": 28809,
                "label": "Colmars",
                "label_ar": "كولمارز",
                "label_fr": "Colmars"
            },
            {
                "value": 28810,
                "label": "Lagarde-d'Apt",
                "label_ar": "لاغارد دابت",
                "label_fr": "Lagarde-d'Apt"
            },
            {
                "value": 28811,
                "label": "Argelers",
                "label_ar": "أرجلرس",
                "label_fr": "Argelers"
            },
            {
                "value": 28812,
                "label": "La Roche-des-Arnauds",
                "label_ar": "لاروش دي أرنو",
                "label_fr": "La Roche-des-Arnauds"
            },
            {
                "value": 28813,
                "label": "Luzarches",
                "label_ar": "لوزارش",
                "label_fr": "Luzarches"
            },
            {
                "value": 28814,
                "label": "Cheny",
                "label_ar": "تشيني",
                "label_fr": "Cheny"
            },
            {
                "value": 28815,
                "label": "Launaguet",
                "label_ar": "لوناجيت",
                "label_fr": "Launaguet"
            },
            {
                "value": 28816,
                "label": "Radinghem-en-Weppes",
                "label_ar": "رادينغيم أون ويبيس",
                "label_fr": "Radinghem-en-Weppes"
            },
            {
                "value": 28817,
                "label": "Optevoz",
                "label_ar": "Optevoz",
                "label_fr": "Optevoz"
            },
            {
                "value": 28818,
                "label": "Osny",
                "label_ar": "أوسني",
                "label_fr": "Osny"
            },
            {
                "value": 28819,
                "label": "Saint-Didier-de-Formans",
                "label_ar": "سان ديدييه دي فورمان",
                "label_fr": "Saint-Didier-de-Formans"
            },
            {
                "value": 28820,
                "label": "Fontaines-Saint-Martin",
                "label_ar": "فونتين سانت مارتن",
                "label_fr": "Fontaines-Saint-Martin"
            },
            {
                "value": 28821,
                "label": "Amberieux",
                "label_ar": "Amberieux",
                "label_fr": "Ambérieux"
            },
            {
                "value": 28822,
                "label": "Manziat",
                "label_ar": "منزات",
                "label_fr": "Manziat"
            },
            {
                "value": 28823,
                "label": "La Chapelle-Saint-Luc",
                "label_ar": "لا شابيل سانت لوك",
                "label_fr": "La Chapelle-Saint-Luc"
            },
            {
                "value": 28824,
                "label": "Lamothe-Montravel",
                "label_ar": "لاموث مونترافيل",
                "label_fr": "Lamothe-Montravel"
            },
            {
                "value": 28825,
                "label": "La Brigue",
                "label_ar": "لا بريج",
                "label_fr": "La Brigue"
            },
            {
                "value": 28826,
                "label": "Bree",
                "label_ar": "بري",
                "label_fr": "Bree"
            },
            {
                "value": 28827,
                "label": "Saint-Malo-de-Phily",
                "label_ar": "سان مالو دي فيلي",
                "label_fr": "Saint-Malo-de-Phily"
            },
            {
                "value": 28828,
                "label": "Grez-en-Bouere",
                "label_ar": "جريز أون بويري",
                "label_fr": "Grez-en-Bouère"
            },
            {
                "value": 28829,
                "label": "Saint-Pierre-d'Autils",
                "label_ar": "سان بيير دوتيلس",
                "label_fr": "Saint-Pierre-d'Autils"
            },
            {
                "value": 28830,
                "label": "Cannes-Ecluse",
                "label_ar": "كان إكلوز",
                "label_fr": "Cannes-Ecluse"
            },
            {
                "value": 28831,
                "label": "Vourey",
                "label_ar": "فوري",
                "label_fr": "Vourey"
            },
            {
                "value": 28832,
                "label": "La Coquille",
                "label_ar": "لا كوكيل",
                "label_fr": "La Coquille"
            },
            {
                "value": 28833,
                "label": "Tercis-les-Bains",
                "label_ar": "Tercis-les-Bains",
                "label_fr": "Tercis-les-Bains"
            },
            {
                "value": 28834,
                "label": "Coulonges",
                "label_ar": "كولونج",
                "label_fr": "Coulonges"
            },
            {
                "value": 28835,
                "label": "Proissans",
                "label_ar": "Proissans",
                "label_fr": "Proissans"
            },
            {
                "value": 28836,
                "label": "Mareuil",
                "label_ar": "مريل",
                "label_fr": "Mareuil"
            },
            {
                "value": 28837,
                "label": "Saint-Aubin-de-Baubigne",
                "label_ar": "سان أوبين دي بوبيني",
                "label_fr": "Saint-Aubin-de-Baubigne"
            },
            {
                "value": 28838,
                "label": "La Laigne",
                "label_ar": "لا ليني",
                "label_fr": "La Laigne"
            },
            {
                "value": 28839,
                "label": "Chateauneuf-sur-Charente",
                "label_ar": "Chateauneuf-sur-Charente",
                "label_fr": "Châteauneuf-sur-Charente"
            },
            {
                "value": 28840,
                "label": "Oeyreluy",
                "label_ar": "أويرلوي",
                "label_fr": "Oeyreluy"
            },
            {
                "value": 28841,
                "label": "Josselin",
                "label_ar": "جوسلين",
                "label_fr": "Josselin"
            },
            {
                "value": 28842,
                "label": "La Taillee",
                "label_ar": "لا تيلي",
                "label_fr": "La Taillee"
            },
            {
                "value": 28843,
                "label": "Saint-Georges-le-Flechard",
                "label_ar": "سان جورج لو فليشارد",
                "label_fr": "Saint-Georges-le-Flechard"
            },
            {
                "value": 28844,
                "label": "Cap-d'Ail",
                "label_ar": "كاب ديل",
                "label_fr": "Cap-d'Ail"
            },
            {
                "value": 28845,
                "label": "Cleon",
                "label_ar": "كليون",
                "label_fr": "Cléon"
            },
            {
                "value": 28846,
                "label": "Douvaine",
                "label_ar": "دوفين",
                "label_fr": "Douvaine"
            },
            {
                "value": 28847,
                "label": "Varennes-en-Argonne",
                "label_ar": "Varennes-en-Argonne",
                "label_fr": "Varennes-en-Argonne"
            },
            {
                "value": 28848,
                "label": "Blanchefosse-et-Bay",
                "label_ar": "بلانشيفوس إت باي",
                "label_fr": "Blanchefosse-et-Bay"
            },
            {
                "value": 28849,
                "label": "Villey-Saint-Etienne",
                "label_ar": "فيلي سانت إتيان",
                "label_fr": "Villey-Saint-Étienne"
            },
            {
                "value": 28850,
                "label": "Mouans-Sartoux",
                "label_ar": "موان سارتوكس",
                "label_fr": "Mouans-Sartoux"
            },
            {
                "value": 28851,
                "label": "Sainghin-en-Weppes",
                "label_ar": "Sainghin-en-Weppes",
                "label_fr": "Sainghin-en-Weppes"
            },
            {
                "value": 28852,
                "label": "Caudebec-lès-Elbeuf",
                "label_ar": "Caudebec-lès-Elbeuf",
                "label_fr": "Caudebec-lès-Elbeuf"
            },
            {
                "value": 28853,
                "label": "Boiscommun",
                "label_ar": "Boiscommun",
                "label_fr": "Boiscommun"
            },
            {
                "value": 28854,
                "label": "Mons",
                "label_ar": "مونس",
                "label_fr": "Mons"
            },
            {
                "value": 28855,
                "label": "Villeneuve-les-Avignon",
                "label_ar": "فيلنوف ليه أفينيون",
                "label_fr": "Villeneuve-lès-Avignon"
            },
            {
                "value": 28856,
                "label": "Paray-le-Monial",
                "label_ar": "باراي لو مونيال",
                "label_fr": "Paray-le-Monial"
            },
            {
                "value": 28857,
                "label": "Privas",
                "label_ar": "بريفاس",
                "label_fr": "Privas"
            },
            {
                "value": 28858,
                "label": "Doudeville",
                "label_ar": "دودفيل",
                "label_fr": "Doudeville"
            },
            {
                "value": 28859,
                "label": "Bourg-le-Roi",
                "label_ar": "بورغ لو روي",
                "label_fr": "Bourg-le-Roi"
            },
            {
                "value": 28860,
                "label": "Pierre-la-Treiche",
                "label_ar": "بيير لاريش",
                "label_fr": "Pierre-la-Treiche"
            },
            {
                "value": 28861,
                "label": "Condat-sur-Vienne",
                "label_ar": "كوندات سور فيين",
                "label_fr": "Condat-sur-Vienne"
            },
            {
                "value": 28862,
                "label": "Beaupuy",
                "label_ar": "بيوبوي",
                "label_fr": "Beaupuy"
            },
            {
                "value": 28863,
                "label": "Pavie",
                "label_ar": "بافي",
                "label_fr": "Pavie"
            },
            {
                "value": 28864,
                "label": "Les Essards",
                "label_ar": "ليه إيساردس",
                "label_fr": "Les Essards"
            },
            {
                "value": 28865,
                "label": "Castets-en-Dorthe",
                "label_ar": "كاستيتس أون دورثي",
                "label_fr": "Castets-en-Dorthe"
            },
            {
                "value": 28866,
                "label": "Hautmont",
                "label_ar": "Hautmont",
                "label_fr": "Hautmont"
            },
            {
                "value": 28867,
                "label": "Venerque",
                "label_ar": "فينيرك",
                "label_fr": "Venerque"
            },
            {
                "value": 28868,
                "label": "Rions",
                "label_ar": "ريونات",
                "label_fr": "Rions"
            },
            {
                "value": 28869,
                "label": "Goeulzin",
                "label_ar": "Goeulzin",
                "label_fr": "Goeulzin"
            },
            {
                "value": 28870,
                "label": "Chaux",
                "label_ar": "شو",
                "label_fr": "Chaux"
            },
            {
                "value": 28871,
                "label": "Harreville-les-Chanteurs",
                "label_ar": "Harreville-les-Chanteurs",
                "label_fr": "Harreville-les-Chanteurs"
            },
            {
                "value": 28872,
                "label": "Vernaison",
                "label_ar": "فيرنيسون",
                "label_fr": "Vernaison"
            },
            {
                "value": 28873,
                "label": "Pusignan",
                "label_ar": "بوسينيان",
                "label_fr": "Pusignan"
            },
            {
                "value": 28874,
                "label": "Montmerle-sur-Saone",
                "label_ar": "مونتميرل سور سون",
                "label_fr": "Montmerle-sur-Saône"
            },
            {
                "value": 28875,
                "label": "Eloise",
                "label_ar": "إلويز",
                "label_fr": "Éloïse"
            },
            {
                "value": 28876,
                "label": "Poisson",
                "label_ar": "بواسون",
                "label_fr": "Poisson"
            },
            {
                "value": 28877,
                "label": "Saint-Christophe-en-Brionnais",
                "label_ar": "سانت كريستوف أون بريونيه",
                "label_fr": "Saint-Christophe-en-Brionnais"
            },
            {
                "value": 28878,
                "label": "Vitry-aux-Loges",
                "label_ar": "فيتري أوكس لوجيس",
                "label_fr": "Vitry-aux-Loges"
            },
            {
                "value": 28879,
                "label": "Bords",
                "label_ar": "بورز",
                "label_fr": "Bords"
            },
            {
                "value": 28880,
                "label": "Holtzheim",
                "label_ar": "هولتزهايم",
                "label_fr": "Holtzheim"
            },
            {
                "value": 28881,
                "label": "Cosne-Cours-sur-Loire",
                "label_ar": "كوسن كورس سور لوار",
                "label_fr": "Cosne-Cours-sur-Loire"
            },
            {
                "value": 28882,
                "label": "Neauphle-le-Chateau",
                "label_ar": "Neauphle-le-Chateau",
                "label_fr": "Neauphle-le-Château"
            },
            {
                "value": 28883,
                "label": "Pont-Remy",
                "label_ar": "بونت ريمي",
                "label_fr": "Pont-Rémy"
            },
            {
                "value": 28884,
                "label": "Divion",
                "label_ar": "الرهان",
                "label_fr": "Divion"
            },
            {
                "value": 28885,
                "label": "Casteljaloux",
                "label_ar": "Casteljaloux",
                "label_fr": "Casteljaloux"
            },
            {
                "value": 28886,
                "label": "Lasbordes",
                "label_ar": "لاسبورديس",
                "label_fr": "Lasbordes"
            },
            {
                "value": 28887,
                "label": "Banyuls-dels-Aspres",
                "label_ar": "Banyuls-dels-Aspres",
                "label_fr": "Banyuls-dels-Aspres"
            },
            {
                "value": 28888,
                "label": "Villepinte",
                "label_ar": "فيلبينت",
                "label_fr": "Villepinte"
            },
            {
                "value": 28889,
                "label": "Cardesse",
                "label_ar": "كارديس",
                "label_fr": "Cardesse"
            },
            {
                "value": 28890,
                "label": "Haillicourt",
                "label_ar": "هايليكورت",
                "label_fr": "Haillicourt"
            },
            {
                "value": 28891,
                "label": "Lapugnoy",
                "label_ar": "لابوجني",
                "label_fr": "Lapugnoy"
            },
            {
                "value": 28892,
                "label": "Cormeilles",
                "label_ar": "كورميليس",
                "label_fr": "Cormeilles"
            },
            {
                "value": 28893,
                "label": "Camphin-en-Carembault",
                "label_ar": "كامفين أون كاريمبولت",
                "label_fr": "Camphin-en-Carembault"
            },
            {
                "value": 28894,
                "label": "Notre-Dame-de-Sanilhac",
                "label_ar": "نوتردام دي سانيلاك",
                "label_fr": "Notre-Dame-de-Sanilhac"
            },
            {
                "value": 28895,
                "label": "Sault-Brenaz",
                "label_ar": "سولت بريناز",
                "label_fr": "Sault-Brenaz"
            },
            {
                "value": 28896,
                "label": "Roussillon",
                "label_ar": "روسيون",
                "label_fr": "Roussillon"
            },
            {
                "value": 28897,
                "label": "Camarsac",
                "label_ar": "كامارساك",
                "label_fr": "Camarsac"
            },
            {
                "value": 28898,
                "label": "Chaptelat",
                "label_ar": "Chaptelat",
                "label_fr": "Chaptelat"
            },
            {
                "value": 28899,
                "label": "Breval",
                "label_ar": "بريفال",
                "label_fr": "Breval"
            },
            {
                "value": 28900,
                "label": "Bessancourt",
                "label_ar": "بيسانكور",
                "label_fr": "Bessancourt"
            },
            {
                "value": 28901,
                "label": "Saint-Vulbas",
                "label_ar": "سان فولباس",
                "label_fr": "Saint-Vulbas"
            },
            {
                "value": 28902,
                "label": "Aveizieux",
                "label_ar": "أفيزيو",
                "label_fr": "Aveizieux"
            },
            {
                "value": 28903,
                "label": "Imphy",
                "label_ar": "Imphy",
                "label_fr": "Imphy"
            },
            {
                "value": 28904,
                "label": "Varzy",
                "label_ar": "فارزي",
                "label_fr": "Varzy"
            },
            {
                "value": 28905,
                "label": "Pouilly-sur-Loire",
                "label_ar": "بويلي سور لوار",
                "label_fr": "Pouilly-sur-Loire"
            },
            {
                "value": 28906,
                "label": "Urzy",
                "label_ar": "أورزي",
                "label_fr": "Urzy"
            },
            {
                "value": 28907,
                "label": "Crozes-Hermitage",
                "label_ar": "كروز هيرميتاج",
                "label_fr": "Crozes-Hermitage"
            },
            {
                "value": 28908,
                "label": "La Machine",
                "label_ar": "لا ماشين",
                "label_fr": "La Machine"
            },
            {
                "value": 28909,
                "label": "Marzy",
                "label_ar": "مارزي",
                "label_fr": "Marzy"
            },
            {
                "value": 28910,
                "label": "Champagney",
                "label_ar": "شامباني",
                "label_fr": "Champagney"
            },
            {
                "value": 28911,
                "label": "Quievy",
                "label_ar": "هادئ",
                "label_fr": "Quievy"
            },
            {
                "value": 28912,
                "label": "Archingeay",
                "label_ar": "أرشيني",
                "label_fr": "Archingeay"
            },
            {
                "value": 28913,
                "label": "Auchy-les-Hesdin",
                "label_ar": "أوشي ليه حسين",
                "label_fr": "Auchy-les-Hesdin"
            },
            {
                "value": 28914,
                "label": "Itancourt",
                "label_ar": "إيتانكور",
                "label_fr": "Itancourt"
            },
            {
                "value": 28915,
                "label": "Roppenheim",
                "label_ar": "روبنهايم",
                "label_fr": "Roppenheim"
            },
            {
                "value": 28916,
                "label": "Rountzenheim",
                "label_ar": "راونتزينهايم",
                "label_fr": "Rountzenheim"
            },
            {
                "value": 28917,
                "label": "Esbly",
                "label_ar": "إسبي",
                "label_fr": "Esbly"
            },
            {
                "value": 28918,
                "label": "La Celle-sur-Seine",
                "label_ar": "لا سيل سور سين",
                "label_fr": "La Celle-sur-Seine"
            },
            {
                "value": 28919,
                "label": "Livron-sur-Drome",
                "label_ar": "ليفرون سور دروم",
                "label_fr": "Livron-sur-Drôme"
            },
            {
                "value": 28920,
                "label": "Arques",
                "label_ar": "Arques",
                "label_fr": "Arques"
            },
            {
                "value": 28921,
                "label": "Jouy-en-Josas",
                "label_ar": "جوي أون جوساس",
                "label_fr": "Jouy-en-Josas"
            },
            {
                "value": 28922,
                "label": "Villette",
                "label_ar": "فيليت",
                "label_fr": "Villette"
            },
            {
                "value": 28923,
                "label": "Aressy",
                "label_ar": "أريسى",
                "label_fr": "Aressy"
            },
            {
                "value": 28924,
                "label": "Louvigny",
                "label_ar": "لوفيني",
                "label_fr": "Louvigny"
            },
            {
                "value": 28925,
                "label": "Rancogne",
                "label_ar": "رانكوجن",
                "label_fr": "Rancogne"
            },
            {
                "value": 28926,
                "label": "Veigne",
                "label_ar": "فين",
                "label_fr": "Veigne"
            },
            {
                "value": 28927,
                "label": "Bon-Encontre",
                "label_ar": "بون انكونتر",
                "label_fr": "Bon-Encontre"
            },
            {
                "value": 28928,
                "label": "Crepey",
                "label_ar": "كريبي",
                "label_fr": "Crepey"
            },
            {
                "value": 28929,
                "label": "Lesquin",
                "label_ar": "ليسكوين",
                "label_fr": "Lesquin"
            },
            {
                "value": 28930,
                "label": "Wittes",
                "label_ar": "ويتس",
                "label_fr": "Wittes"
            },
            {
                "value": 28931,
                "label": "Anzin-Saint-Aubin",
                "label_ar": "أنزين سانت أوبين",
                "label_fr": "Anzin-Saint-Aubin"
            },
            {
                "value": 28932,
                "label": "Issou",
                "label_ar": "ايسو",
                "label_fr": "Issou"
            },
            {
                "value": 28933,
                "label": "Villennes-sur-Seine",
                "label_ar": "فيلين سور سين",
                "label_fr": "Villennes-sur-Seine"
            },
            {
                "value": 28934,
                "label": "Castelnau-de-Brassac",
                "label_ar": "كاستيلناو دي براساك",
                "label_fr": "Castelnau-de-Brassac"
            },
            {
                "value": 28935,
                "label": "Le Chatel",
                "label_ar": "لو شاتيل",
                "label_fr": "Le Châtel"
            },
            {
                "value": 28936,
                "label": "Willems",
                "label_ar": "فيليمس",
                "label_fr": "Willems"
            },
            {
                "value": 28937,
                "label": "Arleux",
                "label_ar": "ارلو",
                "label_fr": "Arleux"
            },
            {
                "value": 28938,
                "label": "Pont-sur-Sambre",
                "label_ar": "بونت سور سامبر",
                "label_fr": "Pont-sur-Sambre"
            },
            {
                "value": 28939,
                "label": "Aubigny-au-Bac",
                "label_ar": "أوبيجني-أو-باك",
                "label_fr": "Aubigny-au-Bac"
            },
            {
                "value": 28940,
                "label": "Herzeele",
                "label_ar": "هرتسيلي",
                "label_fr": "Herzeele"
            },
            {
                "value": 28941,
                "label": "Montigny-lès-Metz",
                "label_ar": "مونتيني ليه ميتز",
                "label_fr": "Montigny-lès-Metz"
            },
            {
                "value": 28942,
                "label": "Lesdain",
                "label_ar": "ليسدين",
                "label_fr": "Lesdain"
            },
            {
                "value": 28943,
                "label": "Masserac",
                "label_ar": "ماسيراك",
                "label_fr": "Masserac"
            },
            {
                "value": 28944,
                "label": "Grillon",
                "label_ar": "جريلون",
                "label_fr": "Grillon"
            },
            {
                "value": 28945,
                "label": "Saint-Georges-sur-Loire",
                "label_ar": "سان جورج سور لوار",
                "label_fr": "Saint-Georges-sur-Loire"
            },
            {
                "value": 28946,
                "label": "Bourgneuf-en-Retz",
                "label_ar": "بورجنوف أون ريتز",
                "label_fr": "Bourgneuf-en-Retz"
            },
            {
                "value": 28947,
                "label": "Vay",
                "label_ar": "فاي",
                "label_fr": "Vay"
            },
            {
                "value": 28948,
                "label": "Yebles",
                "label_ar": "يبلز",
                "label_fr": "Yebles"
            },
            {
                "value": 28949,
                "label": "Les Baux-de-Breteuil",
                "label_ar": "لي بو دو بريتويل",
                "label_fr": "Les Baux-de-Breteuil"
            },
            {
                "value": 28950,
                "label": "Pontgouin",
                "label_ar": "بونتجوين",
                "label_fr": "Pontgouin"
            },
            {
                "value": 28951,
                "label": "Montrichard",
                "label_ar": "مونتريتشارد",
                "label_fr": "Montrichard"
            },
            {
                "value": 28952,
                "label": "Brinay",
                "label_ar": "Brinay",
                "label_fr": "Brinay"
            },
            {
                "value": 28953,
                "label": "Lus-la-Croix-Haute",
                "label_ar": "لوس-لا-كروا-هوت",
                "label_fr": "Lus-la-Croix-Haute"
            },
            {
                "value": 28954,
                "label": "Nuaille-sur-Boutonne",
                "label_ar": "نويل سور بوتون",
                "label_fr": "Nuaille-sur-Boutonne"
            },
            {
                "value": 28955,
                "label": "Salles-sur-Mer",
                "label_ar": "ساليس سور مير",
                "label_fr": "Salles-sur-Mer"
            },
            {
                "value": 28956,
                "label": "Vic-en-Bigorre",
                "label_ar": "Vic-en-Bigorre",
                "label_fr": "Vic-en-Bigorre"
            },
            {
                "value": 28957,
                "label": "La Frette-sur-Seine",
                "label_ar": "لا فريت سور سين",
                "label_fr": "La Frette-sur-Seine"
            },
            {
                "value": 28958,
                "label": "Bouvincourt-en-Vermandois",
                "label_ar": "بوفينكور أون فيرماندوا",
                "label_fr": "Bouvincourt-en-Vermandois"
            },
            {
                "value": 28959,
                "label": "Saint-Erme-Outre-et-Ramecourt",
                "label_ar": "Saint-Erme-Outre-et-Ramecourt",
                "label_fr": "Saint-Erme-Outre-et-Ramecourt"
            },
            {
                "value": 28960,
                "label": "Malafretaz",
                "label_ar": "مالافريتاز",
                "label_fr": "Malafretaz"
            },
            {
                "value": 28961,
                "label": "Beaucouze",
                "label_ar": "بوكوز",
                "label_fr": "Beaucouze"
            },
            {
                "value": 28962,
                "label": "Sathonay-Village",
                "label_ar": "قرية ساتوناي",
                "label_fr": "Sathonay-Village"
            },
            {
                "value": 28963,
                "label": "Amberac",
                "label_ar": "امبراك",
                "label_fr": "Amberac"
            },
            {
                "value": 28964,
                "label": "Zimmersheim",
                "label_ar": "زيمرشيم",
                "label_fr": "Zimmersheim"
            },
            {
                "value": 28965,
                "label": "Guiscard",
                "label_ar": "جيسكارد",
                "label_fr": "Guiscard"
            },
            {
                "value": 28966,
                "label": "Chassieu",
                "label_ar": "شاسيو",
                "label_fr": "Chassieu"
            },
            {
                "value": 28967,
                "label": "Vezenobres",
                "label_ar": "فيزينوبريس",
                "label_fr": "Vézénobres"
            },
            {
                "value": 28968,
                "label": "Bagard",
                "label_ar": "باجارد",
                "label_fr": "Bagard"
            },
            {
                "value": 28969,
                "label": "Aigrefeuille-d'Aunis",
                "label_ar": "إيجريفويل دأونيس",
                "label_fr": "Aigrefeuille-d'Aunis"
            },
            {
                "value": 28970,
                "label": "Corneilla-la-Riviere",
                "label_ar": "كورنيلا لا ريفيير",
                "label_fr": "Corneilla-la-Rivière"
            },
            {
                "value": 28971,
                "label": "Lezan",
                "label_ar": "ليزان",
                "label_fr": "Lézan"
            },
            {
                "value": 28972,
                "label": "Saint-Sauveur-Gouvernet",
                "label_ar": "سان سوفور جوفيرنت",
                "label_fr": "Saint-Sauveur-Gouvernet"
            },
            {
                "value": 28973,
                "label": "Eschentzwiller",
                "label_ar": "Eschentzwiller",
                "label_fr": "Eschentzwiller"
            },
            {
                "value": 28974,
                "label": "Bellême",
                "label_ar": "بيليم",
                "label_fr": "Bellême"
            },
            {
                "value": 28975,
                "label": "Banvou",
                "label_ar": "بانفو",
                "label_fr": "Banvou"
            },
            {
                "value": 28976,
                "label": "Breviandes",
                "label_ar": "Breviandes",
                "label_fr": "Bréviandes"
            },
            {
                "value": 28977,
                "label": "Cabourg",
                "label_ar": "كابورج",
                "label_fr": "Cabourg"
            },
            {
                "value": 28978,
                "label": "La Batie",
                "label_ar": "لا باتي",
                "label_fr": "La Batie"
            },
            {
                "value": 28979,
                "label": "Grainville-la-Teinturiere",
                "label_ar": "Grainville-la-Teinturiere",
                "label_fr": "Grainville-la-Teinturière"
            },
            {
                "value": 28980,
                "label": "Pouillon",
                "label_ar": "بويلون",
                "label_fr": "Pouillon"
            },
            {
                "value": 28981,
                "label": "Chatillon-sur-Cher",
                "label_ar": "شاتيلون سور شير",
                "label_fr": "Châtillon-sur-Cher"
            },
            {
                "value": 28982,
                "label": "Patay",
                "label_ar": "باتاي",
                "label_fr": "Patay"
            },
            {
                "value": 28983,
                "label": "Saint-Jean-de-Braye",
                "label_ar": "سان جان دي براي",
                "label_fr": "Saint-Jean-de-Braye"
            },
            {
                "value": 28984,
                "label": "Retschwiller",
                "label_ar": "ريتشفيلر",
                "label_fr": "Retschwiller"
            },
            {
                "value": 28985,
                "label": "Montgivray",
                "label_ar": "مونتجيفراي",
                "label_fr": "Montgivray"
            },
            {
                "value": 28986,
                "label": "Mareil-sur-Mauldre",
                "label_ar": "مريل سور مولدر",
                "label_fr": "Mareil-sur-Mauldre"
            },
            {
                "value": 28987,
                "label": "Rousies",
                "label_ar": "يثير",
                "label_fr": "Rousies"
            },
            {
                "value": 28988,
                "label": "Pont-Sainte-Marie",
                "label_ar": "بونت سانت ماري",
                "label_fr": "Pont-Sainte-Marie"
            },
            {
                "value": 28989,
                "label": "Sailly-en-Ostrevent",
                "label_ar": "شراع أون أوستريفنت",
                "label_fr": "Sailly-en-Ostrevent"
            },
            {
                "value": 28990,
                "label": "Meslay-le-Vidame",
                "label_ar": "ميسلاي لو فيدام",
                "label_fr": "Meslay-le-Vidame"
            },
            {
                "value": 28991,
                "label": "Treon",
                "label_ar": "تريون",
                "label_fr": "Treon"
            },
            {
                "value": 28992,
                "label": "Hirsingue",
                "label_ar": "هيرسينج",
                "label_fr": "Hirsingue"
            },
            {
                "value": 28993,
                "label": "Galfingue",
                "label_ar": "جالفينج",
                "label_fr": "Galfingue"
            },
            {
                "value": 28994,
                "label": "Heimsbrunn",
                "label_ar": "Heimsbrunn",
                "label_fr": "Heimsbrunn"
            },
            {
                "value": 28995,
                "label": "Manspach",
                "label_ar": "مانسباخ",
                "label_fr": "Manspach"
            },
            {
                "value": 28996,
                "label": "Heimersdorf",
                "label_ar": "هايمرسدورف",
                "label_fr": "Heimersdorf"
            },
            {
                "value": 28997,
                "label": "Sainte-Croix-en-Plaine",
                "label_ar": "سانت كروا أون بلين",
                "label_fr": "Sainte-Croix-en-Plaine"
            },
            {
                "value": 28998,
                "label": "Niederentzen",
                "label_ar": "Niederentzen",
                "label_fr": "Niederentzen"
            },
            {
                "value": 28999,
                "label": "Moosch",
                "label_ar": "موش",
                "label_fr": "Moosch"
            },
            {
                "value": 29000,
                "label": "Luemschwiller",
                "label_ar": "لومشويلر",
                "label_fr": "Luemschwiller"
            },
            {
                "value": 29001,
                "label": "Bollwiller",
                "label_ar": "بولويلر",
                "label_fr": "Bollwiller"
            },
            {
                "value": 29001,
                "label": "Appenwihr",
                "label_ar": "أبنوهر",
                "label_fr": "Appenwihr"
            },
            {
                "value": 29002,
                "label": "Herrlisheim-pres-Colmar",
                "label_ar": "هيرليشيم بريس كولمار",
                "label_fr": "Herrlisheim-près-Colmar"
            },
            {
                "value": 29003,
                "label": "Husseren-Wesserling",
                "label_ar": "هوسرين فيسرلينج",
                "label_fr": "Husseren-Wesserling"
            },
            {
                "value": 29004,
                "label": "Ranspach",
                "label_ar": "رانسباخ",
                "label_fr": "Ranspach"
            },
            {
                "value": 29005,
                "label": "Mortzwiller",
                "label_ar": "مورتزويلر",
                "label_fr": "Mortzwiller"
            },
            {
                "value": 29006,
                "label": "Bischwihr",
                "label_ar": "بيشوير",
                "label_fr": "Bischwihr"
            },
            {
                "value": 29007,
                "label": "Werentzhouse",
                "label_ar": "ويرنتزهاوس",
                "label_fr": "Werentzhouse"
            },
            {
                "value": 29008,
                "label": "Kembs",
                "label_ar": "كيمبس",
                "label_fr": "Kembs"
            },
            {
                "value": 29009,
                "label": "Hagenbach",
                "label_ar": "هاجنباخ",
                "label_fr": "Hagenbach"
            },
            {
                "value": 29010,
                "label": "Wickerschwihr",
                "label_ar": "ويكرشوير",
                "label_fr": "Wickerschwihr"
            },
            {
                "value": 29011,
                "label": "Liepvre",
                "label_ar": "ليبفر",
                "label_fr": "Liepvre"
            },
            {
                "value": 29012,
                "label": "Coignet",
                "label_ar": "Coignet",
                "label_fr": "Coignet"
            },
            {
                "value": 29013,
                "label": "Chateaubriand",
                "label_ar": "شاتوبريان",
                "label_fr": "Chateaubriand"
            },
            {
                "value": 29014,
                "label": "La Cheneviere",
                "label_ar": "لا تشينفير",
                "label_fr": "La Chenevière"
            },
            {
                "value": 29015,
                "label": "Albert",
                "label_ar": "ألبرت",
                "label_fr": "Albert"
            },
            {
                "value": 29016,
                "label": "Clavel",
                "label_ar": "كلافيل",
                "label_fr": "Clavel"
            },
            {
                "value": 29017,
                "label": "Bertin",
                "label_ar": "بيرتين",
                "label_fr": "Bertin"
            },
            {
                "value": 29018,
                "label": "Chaumes",
                "label_ar": "تشوميس",
                "label_fr": "Chaumes"
            },
            {
                "value": 29019,
                "label": "Rivieres",
                "label_ar": "ريفيير",
                "label_fr": "Rivières"
            },
            {
                "value": 29020,
                "label": "La Bretonne",
                "label_ar": "لا بريتون",
                "label_fr": "La Bretonne"
            },
            {
                "value": 29021,
                "label": "Bourgogne",
                "label_ar": "بورجوني",
                "label_fr": "Bourgogne"
            },
            {
                "value": 29022,
                "label": "Montpezat-sous-Bauzon",
                "label_ar": "مونبيزات سوس باوزون",
                "label_fr": "Montpezat-sous-Bauzon"
            },
            {
                "value": 29023,
                "label": "Bermicourt",
                "label_ar": "بيرميكورت",
                "label_fr": "Bermicourt"
            },
            {
                "value": 29024,
                "label": "Bouleurs",
                "label_ar": "بولورس",
                "label_fr": "Bouleurs"
            },
            {
                "value": 29025,
                "label": "Grand-Charmont",
                "label_ar": "جراند شارمونت",
                "label_fr": "Grand-Charmont"
            },
            {
                "value": 29026,
                "label": "Revel-Tourdan",
                "label_ar": "ريفيل توردان",
                "label_fr": "Revel-Tourdan"
            },
            {
                "value": 29027,
                "label": "Jayat",
                "label_ar": "جايات",
                "label_fr": "Jayat"
            },
            {
                "value": 29028,
                "label": "Cailloux-sur-Fontaines",
                "label_ar": "Cailloux-sur-Fontaines",
                "label_fr": "Cailloux-sur-Fontaines"
            },
            {
                "value": 29029,
                "label": "Sandrans",
                "label_ar": "Sandrans",
                "label_fr": "Sandrans"
            },
            {
                "value": 29030,
                "label": "Taluyers",
                "label_ar": "Taluyers",
                "label_fr": "Taluyers"
            },
            {
                "value": 29031,
                "label": "Succieu",
                "label_ar": "سوتشيو",
                "label_fr": "Succieu"
            },
            {
                "value": 29032,
                "label": "Faramans",
                "label_ar": "فارمانس",
                "label_fr": "Faramans"
            },
            {
                "value": 29033,
                "label": "Beaurepaire",
                "label_ar": "بيوريباير",
                "label_fr": "Beaurepaire"
            },
            {
                "value": 29034,
                "label": "Saint-Georges-sur-Renon",
                "label_ar": "سان جورج سور رينون",
                "label_fr": "Saint-Georges-sur-Renon"
            },
            {
                "value": 29035,
                "label": "Treffort",
                "label_ar": "تريفورت",
                "label_fr": "Treffort"
            },
            {
                "value": 29036,
                "label": "Saint-Genis-les-Ollieres",
                "label_ar": "سان جينيس ليه أوليير",
                "label_fr": "Saint-Genis-les-Ollières"
            },
            {
                "value": 29037,
                "label": "Poncin",
                "label_ar": "بونسين",
                "label_fr": "Poncin"
            },
            {
                "value": 29038,
                "label": "Payzac",
                "label_ar": "بايزاك",
                "label_fr": "Payzac"
            },
            {
                "value": 29039,
                "label": "Bourg-les-Valence",
                "label_ar": "بورغ ليس فالينس",
                "label_fr": "Bourg-les-Valence"
            },
            {
                "value": 29040,
                "label": "Valencogne",
                "label_ar": "فالنكون",
                "label_fr": "Valencogne"
            },
            {
                "value": 29041,
                "label": "Saint-Marcellin-en-Forez",
                "label_ar": "سان مارسيلين أون فوريز",
                "label_fr": "Saint-Marcellin-en-Forez"
            },
            {
                "value": 29042,
                "label": "Corveissiat",
                "label_ar": "كورفيسيات",
                "label_fr": "Corveissiat"
            },
            {
                "value": 29043,
                "label": "Rambert",
                "label_ar": "رامبرت",
                "label_fr": "Rambert"
            },
            {
                "value": 29044,
                "label": "Viry",
                "label_ar": "فيري",
                "label_fr": "Viry"
            },
            {
                "value": 29045,
                "label": "Grenay",
                "label_ar": "غريناي",
                "label_fr": "Grenay"
            },
            {
                "value": 29046,
                "label": "Donzy",
                "label_ar": "دونزي",
                "label_fr": "Donzy"
            },
            {
                "value": 29047,
                "label": "Sainte-Colombe-sur-Gand",
                "label_ar": "Sainte-Colombe-sur-Gand",
                "label_fr": "Sainte-Colombe-sur-Gand"
            },
            {
                "value": 29048,
                "label": "Saint-Vincent-de-Durfort",
                "label_ar": "سانت فنسنت دي دورفورت",
                "label_fr": "Saint-Vincent-de-Durfort"
            },
            {
                "value": 29049,
                "label": "Duniere",
                "label_ar": "دونيير",
                "label_fr": "Dunière"
            },
            {
                "value": 29050,
                "label": "Saint-Just-Chaleyssin",
                "label_ar": "سان جوست شاليزين",
                "label_fr": "Saint-Just-Chaleyssin"
            },
            {
                "value": 29051,
                "label": "Saint-Heand",
                "label_ar": "سانت هياند",
                "label_fr": "Saint-Heand"
            },
            {
                "value": 29052,
                "label": "Neuville-les-Dames",
                "label_ar": "نيوفيل ليس دامس",
                "label_fr": "Neuville-les-Dames"
            },
            {
                "value": 29053,
                "label": "Joux",
                "label_ar": "جو",
                "label_fr": "Joux"
            },
            {
                "value": 29054,
                "label": "Coligny",
                "label_ar": "كوليجني",
                "label_fr": "Coligny"
            },
            {
                "value": 29055,
                "label": "Saint-Galmier",
                "label_ar": "سان جالمير",
                "label_fr": "Saint-Galmier"
            },
            {
                "value": 29056,
                "label": "Bouvesse-Quirieu",
                "label_ar": "Bouvesse-Quirieu",
                "label_fr": "Bouvesse-Quirieu"
            },
            {
                "value": 29057,
                "label": "Lorette",
                "label_ar": "لوريت",
                "label_fr": "Lorette"
            },
            {
                "value": 29058,
                "label": "Gleize",
                "label_ar": "جليز",
                "label_fr": "Gleize"
            },
            {
                "value": 29059,
                "label": "Tartaras",
                "label_ar": "الترتارا",
                "label_fr": "Tartares"
            },
            {
                "value": 29060,
                "label": "La Plaine",
                "label_ar": "لا بلين",
                "label_fr": "La Plaine"
            },
            {
                "value": 29061,
                "label": "Luzinay",
                "label_ar": "لوزيناي",
                "label_fr": "Luzinay"
            },
            {
                "value": 29062,
                "label": "Rieux",
                "label_ar": "ريو",
                "label_fr": "Rieux"
            },
            {
                "value": 29063,
                "label": "Saint-Just-le-Martel",
                "label_ar": "سان جوست لو مارتل",
                "label_fr": "Saint-Just-le-Martel"
            },
            {
                "value": 29064,
                "label": "Candillargues",
                "label_ar": "الشموع",
                "label_fr": "Candillargues"
            },
            {
                "value": 29065,
                "label": "Champlan",
                "label_ar": "شامبلان",
                "label_fr": "Champlan"
            },
            {
                "value": 29066,
                "label": "Gresy-sur-Isere",
                "label_ar": "جريسي سور إيسير",
                "label_fr": "Gresy-sur-Isère"
            },
            {
                "value": 29067,
                "label": "Bayon",
                "label_ar": "بايون",
                "label_fr": "Bayon"
            },
            {
                "value": 29068,
                "label": "Lavans-Vuillafans",
                "label_ar": "Lavans-Vuillafans",
                "label_fr": "Lavans-Vuillafans"
            },
            {
                "value": 29069,
                "label": "Duranus",
                "label_ar": "دورانوس",
                "label_fr": "Duranus"
            },
            {
                "value": 29070,
                "label": "Jully-sur-Sarce",
                "label_ar": "جولي سور سارسي",
                "label_fr": "Jully-sur-Sarce"
            },
            {
                "value": 29071,
                "label": "Montbellet",
                "label_ar": "مونبيليت",
                "label_fr": "Montbellet"
            },
            {
                "value": 29072,
                "label": "Auneau",
                "label_ar": "أونو",
                "label_fr": "Auneau"
            },
            {
                "value": 29073,
                "label": "Saint-Laurent-de-Mure",
                "label_ar": "سان لوران دي موري",
                "label_fr": "Saint-Laurent-de-Mure"
            },
            {
                "value": 29074,
                "label": "Villars",
                "label_ar": "فيلارس",
                "label_fr": "Villars"
            },
            {
                "value": 29075,
                "label": "Ennery",
                "label_ar": "اينري",
                "label_fr": "Ennery"
            },
            {
                "value": 29076,
                "label": "Georges",
                "label_ar": "جورج",
                "label_fr": "Georges"
            },
            {
                "value": 29077,
                "label": "Villons-les-Buissons",
                "label_ar": "Villons-les-Buissons",
                "label_fr": "Villons-les-Buissons"
            },
            {
                "value": 29078,
                "label": "La Boussac",
                "label_ar": "لابوساك",
                "label_fr": "La Boussac"
            },
            {
                "value": 29079,
                "label": "Marbache",
                "label_ar": "ماربش",
                "label_fr": "Marbache"
            },
            {
                "value": 29080,
                "label": "Paizay-le-Chapt",
                "label_ar": "Paizay-le-Chapt",
                "label_fr": "Paizay-le-Chapt"
            },
            {
                "value": 29081,
                "label": "Santeuil",
                "label_ar": "سانتويل",
                "label_fr": "Santeuil"
            },
            {
                "value": 29082,
                "label": "Gervais",
                "label_ar": "جيرفيه",
                "label_fr": "Gervais"
            },
            {
                "value": 29083,
                "label": "Gros-Rederching",
                "label_ar": "جروس ريديرشينج",
                "label_fr": "Gros-Rederching"
            },
            {
                "value": 29084,
                "label": "Soucht",
                "label_ar": "سوتش",
                "label_fr": "Soucht"
            },
            {
                "value": 29085,
                "label": "Lemberg",
                "label_ar": "ليمبيرج",
                "label_fr": "Lemberg"
            },
            {
                "value": 29086,
                "label": "Goetzenbruck",
                "label_ar": "جوتزينبروك",
                "label_fr": "Goetzenbruck"
            },
            {
                "value": 29087,
                "label": "Epping",
                "label_ar": "إبينج",
                "label_fr": "Epping"
            },
            {
                "value": 29088,
                "label": "Port-Cros",
                "label_ar": "بورت كروس",
                "label_fr": "Port-Cros"
            },
            {
                "value": 29089,
                "label": "Severac",
                "label_ar": "سيفيراك",
                "label_fr": "Severac"
            },
            {
                "value": 29090,
                "label": "Val d'Isère",
                "label_ar": "فال ديزير",
                "label_fr": "Val d'Isère"
            },
            {
                "value": 29091,
                "label": "Limas",
                "label_ar": "ليماس",
                "label_fr": "Limas"
            },
            {
                "value": 29092,
                "label": "Garche",
                "label_ar": "جارش",
                "label_fr": "Garche"
            },
            {
                "value": 29093,
                "label": "Domgermain",
                "label_ar": "دومجيرمين",
                "label_fr": "Domgermain"
            },
            {
                "value": 29094,
                "label": "Les Combes",
                "label_ar": "ليه كومبس",
                "label_fr": "Les Combes"
            },
            {
                "value": 29095,
                "label": "Ville-en-Tardenois",
                "label_ar": "فيل أون تاردينوا",
                "label_fr": "Ville-en-Tardenois"
            },
            {
                "value": 29096,
                "label": "Sillingy",
                "label_ar": "سيليني",
                "label_fr": "Sillingy"
            },
            {
                "value": 29097,
                "label": "Vignols",
                "label_ar": "فيجنول",
                "label_fr": "Vignols"
            },
            {
                "value": 29098,
                "label": "Chameyrat",
                "label_ar": "شاميرات",
                "label_fr": "Chameyrat"
            },
            {
                "value": 29099,
                "label": "Voutezac",
                "label_ar": "فوتيزاك",
                "label_fr": "Voutezac"
            },
            {
                "value": 29100,
                "label": "Panazol",
                "label_ar": "بانازول",
                "label_fr": "Panazol"
            },
            {
                "value": 29101,
                "label": "Saint-Paterne",
                "label_ar": "سانت باتيرن",
                "label_fr": "Saint-Paterne"
            },
            {
                "value": 29102,
                "label": "Gilette",
                "label_ar": "جيليت",
                "label_fr": "Gilette"
            },
            {
                "value": 29103,
                "label": "Essegney",
                "label_ar": "إيسيني",
                "label_fr": "Essegney"
            },
            {
                "value": 29104,
                "label": "Tilly-sur-Meuse",
                "label_ar": "تيلي سور ميوز",
                "label_fr": "Tilly-sur-Meuse"
            },
            {
                "value": 29105,
                "label": "Tourgeville",
                "label_ar": "تورغفيل",
                "label_fr": "Tourgeville"
            },
            {
                "value": 29106,
                "label": "Oursbelille",
                "label_ar": "أورسبيل",
                "label_fr": "Oursbelille"
            },
            {
                "value": 29107,
                "label": "Moyeuvre-Grande",
                "label_ar": "مويوفر غراندي",
                "label_fr": "Moyeuvre-Grande"
            },
            {
                "value": 29108,
                "label": "Courcelles-Chaussy",
                "label_ar": "Courcelles-Chaussy",
                "label_fr": "Courcelles-Chaussy"
            },
            {
                "value": 29109,
                "label": "Retonfey",
                "label_ar": "ريتونفي",
                "label_fr": "Retonfey"
            },
            {
                "value": 29110,
                "label": "Falck",
                "label_ar": "فالك",
                "label_fr": "Falck"
            },
            {
                "value": 29111,
                "label": "Semecourt",
                "label_ar": "سيميكورت",
                "label_fr": "Semecourt"
            },
            {
                "value": 29112,
                "label": "Puttelange-les-Thionville",
                "label_ar": "بوتيلانج ليه ثيونفيل",
                "label_fr": "Puttelange-les-Thionville"
            },
            {
                "value": 29113,
                "label": "Rozerieulles",
                "label_ar": "روزيريولس",
                "label_fr": "Rozerieulles"
            },
            {
                "value": 29114,
                "label": "Ancy-sur-Moselle",
                "label_ar": "أنسي سور موسيل",
                "label_fr": "Ancy-sur-Moselle"
            },
            {
                "value": 29115,
                "label": "Ars-sur-Moselle",
                "label_ar": "Ars-sur-Moselle",
                "label_fr": "Ars-sur-Moselle"
            },
            {
                "value": 29116,
                "label": "Ham-sous-Varsberg",
                "label_ar": "هام-سوس-فارسبرغ",
                "label_fr": "Ham-sous-Varsberg"
            },
            {
                "value": 29117,
                "label": "Lorquin",
                "label_ar": "لوركين",
                "label_fr": "Lorquin"
            },
            {
                "value": 29118,
                "label": "Mittelbronn",
                "label_ar": "ميتيلبرون",
                "label_fr": "Mittelbronn"
            },
            {
                "value": 29119,
                "label": "Guntzviller",
                "label_ar": "جونتزفيلر",
                "label_fr": "Guntzviller"
            },
            {
                "value": 29120,
                "label": "Delme",
                "label_ar": "دلمي",
                "label_fr": "Delme"
            },
            {
                "value": 29121,
                "label": "Bechy",
                "label_ar": "بيشي",
                "label_fr": "Bechy"
            },
            {
                "value": 29122,
                "label": "Phalsbourg",
                "label_ar": "فالسبورغ",
                "label_fr": "Phalsbourg"
            },
            {
                "value": 29123,
                "label": "Louvigny",
                "label_ar": "لوفيني",
                "label_fr": "Louvigny"
            },
            {
                "value": 29124,
                "label": "Donjeux",
                "label_ar": "Donjeux",
                "label_fr": "Donjeux"
            },
            {
                "value": 29125,
                "label": "Reding",
                "label_ar": "ريدينج",
                "label_fr": "Reding"
            },
            {
                "value": 29126,
                "label": "Rechicourt-le-Chateau",
                "label_ar": "Rechicourt-le-Chateau",
                "label_fr": "Rechicourt-le-Château"
            },
            {
                "value": 29127,
                "label": "Morsbach",
                "label_ar": "مورسباخ",
                "label_fr": "Morsbach"
            },
            {
                "value": 29128,
                "label": "Brouderdorff",
                "label_ar": "برودردورف",
                "label_fr": "Brouderdorff"
            },
            {
                "value": 29129,
                "label": "Kerbach",
                "label_ar": "كيرباخ",
                "label_fr": "Kerbach"
            },
            {
                "value": 29130,
                "label": "Petite-Rosselle",
                "label_ar": "بيتيت روسيل",
                "label_fr": "Petite-Rosselle"
            },
            {
                "value": 29131,
                "label": "Bousbach",
                "label_ar": "بوسباخ",
                "label_fr": "Bousbach"
            },
            {
                "value": 29132,
                "label": "Altviller",
                "label_ar": "ألفيلر",
                "label_fr": "Altviller"
            },
            {
                "value": 29133,
                "label": "Entrange",
                "label_ar": "غريب",
                "label_fr": "Entrange"
            },
            {
                "value": 29134,
                "label": "Kedange-sur-Canner",
                "label_ar": "كيدانج سور كانر",
                "label_fr": "Kedange-sur-Canner"
            },
            {
                "value": 29135,
                "label": "Richemont",
                "label_ar": "ريتشمونت",
                "label_fr": "Richemont"
            },
            {
                "value": 29136,
                "label": "Contz-les-Bains",
                "label_ar": "كونتز ليه با",
                "label_fr": "Contz-les-Bains"
            },
            {
                "value": 29137,
                "label": "Mulcey",
                "label_ar": "مولسي",
                "label_fr": "Mulcey"
            },
            {
                "value": 29138,
                "label": "Metzervisse",
                "label_ar": "ميتسيرفيس",
                "label_fr": "Metzervisse"
            },
            {
                "value": 29139,
                "label": "Harreberg",
                "label_ar": "هاربرج",
                "label_fr": "Harreberg"
            },
            {
                "value": 29140,
                "label": "Vitry-sur-Orne",
                "label_ar": "فيتري سور أورني",
                "label_fr": "Vitry-sur-Orne"
            },
            {
                "value": 29141,
                "label": "Basse-Rentgen",
                "label_ar": "Basse-Rentgen",
                "label_fr": "Basse-Rentgen"
            },
            {
                "value": 29142,
                "label": "Vaudreching",
                "label_ar": "فودريشنغ",
                "label_fr": "Vaudreching"
            },
            {
                "value": 29143,
                "label": "Waldhouse",
                "label_ar": "والدهاوس",
                "label_fr": "Waldhouse"
            },
            {
                "value": 29144,
                "label": "Remering-les-Puttelange",
                "label_ar": "Remering-les-Puttelange",
                "label_fr": "Remering-les-Puttelange"
            },
            {
                "value": 29145,
                "label": "Hundling",
                "label_ar": "هاندلينج",
                "label_fr": "Hundling"
            },
            {
                "value": 29146,
                "label": "Fenetrange",
                "label_ar": "فينترانج",
                "label_fr": "Fenetrange"
            },
            {
                "value": 29147,
                "label": "La Maxe",
                "label_ar": "لا ماكس",
                "label_fr": "La Maxe"
            },
            {
                "value": 29148,
                "label": "Cherisey",
                "label_ar": "تشيريسي",
                "label_fr": "Cherisey"
            },
            {
                "value": 29149,
                "label": "Benestroff",
                "label_ar": "بينيستروف",
                "label_fr": "Benestroff"
            },
            {
                "value": 29150,
                "label": "Etting",
                "label_ar": "إيتنج",
                "label_fr": "Etting"
            },
            {
                "value": 29151,
                "label": "Verny",
                "label_ar": "فيرني",
                "label_fr": "Verny"
            },
            {
                "value": 29152,
                "label": "Chateau-Salins",
                "label_ar": "شاتو سالينز",
                "label_fr": "Château-Salins"
            },
            {
                "value": 29153,
                "label": "Diffembach-les-Hellimer",
                "label_ar": "Diffembach-les-Hellimer",
                "label_fr": "Diffembach-les-Hellimer"
            },
            {
                "value": 29154,
                "label": "Ernestviller",
                "label_ar": "ارنستفيلر",
                "label_fr": "Ernestviller"
            },
            {
                "value": 29155,
                "label": "Montbronn",
                "label_ar": "مونبرون",
                "label_fr": "Montbronn"
            },
            {
                "value": 29156,
                "label": "Enchenberg",
                "label_ar": "Enchenberg",
                "label_fr": "Enchenberg"
            },
            {
                "value": 29157,
                "label": "Vigny",
                "label_ar": "زاهي",
                "label_fr": "Vigny"
            },
            {
                "value": 29158,
                "label": "Vibersviller",
                "label_ar": "Vibersviller",
                "label_fr": "Vibersviller"
            },
            {
                "value": 29159,
                "label": "Nousseviller-Saint-Nabor",
                "label_ar": "Nousseviller-Saint-Nabor",
                "label_fr": "Nousseviller-Saint-Nabor"
            },
            {
                "value": 29160,
                "label": "Amelecourt",
                "label_ar": "أمليكورت",
                "label_fr": "Amélecourt"
            },
            {
                "value": 29161,
                "label": "Sorbey",
                "label_ar": "سوربي",
                "label_fr": "Sorbey"
            },
            {
                "value": 29162,
                "label": "Gorze",
                "label_ar": "غورزي",
                "label_fr": "Gorze"
            },
            {
                "value": 29163,
                "label": "Nitting",
                "label_ar": "القمل",
                "label_fr": "Nitting"
            },
            {
                "value": 29164,
                "label": "Distroff",
                "label_ar": "ديستروف",
                "label_fr": "Distroff"
            },
            {
                "value": 29165,
                "label": "Lesse",
                "label_ar": "ليس",
                "label_fr": "Lesse"
            },
            {
                "value": 29166,
                "label": "Hermelange",
                "label_ar": "هيرميلانج",
                "label_fr": "Hermelange"
            },
            {
                "value": 29167,
                "label": "Chanteloup-les-Vignes",
                "label_ar": "Chanteloup-les-Vignes",
                "label_fr": "Chanteloup-les-Vignes"
            },
            {
                "value": 29168,
                "label": "Saulny",
                "label_ar": "سولني",
                "label_fr": "Saulny"
            },
            {
                "value": 29169,
                "label": "Rabat-les-Trois-Seigneurs",
                "label_ar": "الرباط لي تروا سينور",
                "label_fr": "Rabat-les-Trois-Seigneurs"
            },
            {
                "value": 29170,
                "label": "Thyez",
                "label_ar": "ثيز",
                "label_fr": "Thyez"
            },
            {
                "value": 29171,
                "label": "Vulbens",
                "label_ar": "فولبنس",
                "label_fr": "Vulbens"
            },
            {
                "value": 29172,
                "label": "Lunel-Viel",
                "label_ar": "لونيل فيل",
                "label_fr": "Lunel-Viel"
            },
            {
                "value": 29173,
                "label": "Saint-Georges-d'Orques",
                "label_ar": "سان جورج دورك",
                "label_fr": "Saint-Georges-d'Orques"
            },
            {
                "value": 29174,
                "label": "Le Cap D'Agde",
                "label_ar": "لو كاب داجد",
                "label_fr": "Le Cap D'Agde"
            },
            {
                "value": 29175,
                "label": "Abeilhan",
                "label_ar": "أبيلهان",
                "label_fr": "Abeilhan"
            },
            {
                "value": 29176,
                "label": "Saint-Christol",
                "label_ar": "سانت كريستول",
                "label_fr": "Saint-Christol"
            },
            {
                "value": 29177,
                "label": "Le Bosc",
                "label_ar": "لو بوسك",
                "label_fr": "Le Bosc"
            },
            {
                "value": 29178,
                "label": "Lamalou-les-Bains",
                "label_ar": "لامالو ليه بان",
                "label_fr": "Lamalou-les-Bains"
            },
            {
                "value": 29179,
                "label": "Saussan",
                "label_ar": "سوسان",
                "label_fr": "Saussan"
            },
            {
                "value": 29180,
                "label": "Saint-Jean-de-Cornies",
                "label_ar": "سان جان دي كورنيز",
                "label_fr": "Saint-Jean-de-Cornies"
            },
            {
                "value": 29181,
                "label": "Montferrier-sur-Lez",
                "label_ar": "مونتفيرييه سور ليز",
                "label_fr": "Montferrier-sur-Lez"
            },
            {
                "value": 29182,
                "label": "Saint-Andre-de-Sangonis",
                "label_ar": "سان أندريه دي سانغونيس",
                "label_fr": "Saint-André-de-Sangonis"
            },
            {
                "value": 29183,
                "label": "Labastide-Rouairoux",
                "label_ar": "لاباستيد رويروكس",
                "label_fr": "Labastide-Rouairoux"
            },
            {
                "value": 29184,
                "label": "Valros",
                "label_ar": "فالروس",
                "label_fr": "Valros"
            },
            {
                "value": 29185,
                "label": "Grabels",
                "label_ar": "قبضات",
                "label_fr": "Grabels"
            },
            {
                "value": 29186,
                "label": "Puissalicon",
                "label_ar": "Puissalicon",
                "label_fr": "Puissalicon"
            },
            {
                "value": 29187,
                "label": "Lespignan",
                "label_ar": "ليسبينيان",
                "label_fr": "Lespignan"
            },
            {
                "value": 29188,
                "label": "Le Bousquet",
                "label_ar": "لو بوسكيه",
                "label_fr": "Le Bousquet"
            },
            {
                "value": 29189,
                "label": "Espondeilhan",
                "label_ar": "اسبونديلهان",
                "label_fr": "Espondeilhan"
            },
            {
                "value": 29190,
                "label": "Villeneuve-des-Escaldes",
                "label_ar": "فيلنوف دي إسكالديس",
                "label_fr": "Villeneuve-des-Escaldes"
            },
            {
                "value": 29191,
                "label": "Clary",
                "label_ar": "كلاري",
                "label_fr": "Clary"
            },
            {
                "value": 29192,
                "label": "Tours-sur-Marne",
                "label_ar": "جولات سور مارن",
                "label_fr": "Tours-sur-Marne"
            },
            {
                "value": 29193,
                "label": "Saint-Cyr-sur-Menthon",
                "label_ar": "سان سير سور مينتون",
                "label_fr": "Saint-Cyr-sur-Menthon"
            },
            {
                "value": 29194,
                "label": "Chavannes-sur-Reyssouze",
                "label_ar": "شافان سور ريسوز",
                "label_fr": "Chavannes-sur-Reyssouze"
            },
            {
                "value": 29195,
                "label": "Cras-sur-Reyssouze",
                "label_ar": "كراس سور ريسوز",
                "label_fr": "Cras-sur-Reyssouze"
            },
            {
                "value": 29196,
                "label": "Chaleins",
                "label_ar": "شلين",
                "label_fr": "Chaînes"
            },
            {
                "value": 29197,
                "label": "Nantua",
                "label_ar": "نانتوا",
                "label_fr": "Nantua"
            },
            {
                "value": 29198,
                "label": "Tinchebray",
                "label_ar": "تينشبراي",
                "label_fr": "Tinchebray"
            },
            {
                "value": 29199,
                "label": "Ecorcei",
                "label_ar": "Ecorcei",
                "label_fr": "Ecorcei"
            },
            {
                "value": 29200,
                "label": "Brix",
                "label_ar": "بريكس",
                "label_fr": "Brix"
            },
            {
                "value": 29201,
                "label": "Dive",
                "label_ar": "يغوص",
                "label_fr": "Se plonger"
            },
            {
                "value": 29202,
                "label": "Bernieres-le-Patry",
                "label_ar": "بيرنييه لو باتري",
                "label_fr": "Bernières-le-Patry"
            },
            {
                "value": 29203,
                "label": "Cardonville",
                "label_ar": "كاردونفيل",
                "label_fr": "Cardonville"
            },
            {
                "value": 29204,
                "label": "Mere",
                "label_ar": "مجرد",
                "label_fr": "Simple"
            },
            {
                "value": 29205,
                "label": "Coulonces",
                "label_ar": "كولونس",
                "label_fr": "Coulonces"
            },
            {
                "value": 29206,
                "label": "Balleroy",
                "label_ar": "باليروي",
                "label_fr": "Balleroy"
            },
            {
                "value": 29207,
                "label": "Hardinvast",
                "label_ar": "هاردينفاست",
                "label_fr": "Hardinvast"
            },
            {
                "value": 29208,
                "label": "Norrey-en-Auge",
                "label_ar": "نوري أون أوجي",
                "label_fr": "Norrey-en-Auge"
            },
            {
                "value": 29209,
                "label": "Banville",
                "label_ar": "بانفيل",
                "label_fr": "Banville"
            },
            {
                "value": 29210,
                "label": "Saussemesnil",
                "label_ar": "Saussemesnil",
                "label_fr": "Saussemesnil"
            },
            {
                "value": 29211,
                "label": "Montabard",
                "label_ar": "مونتابارد",
                "label_fr": "Montabard"
            },
            {
                "value": 29212,
                "label": "Mortree",
                "label_ar": "Mortree",
                "label_fr": "Mortree"
            },
            {
                "value": 29213,
                "label": "Coudehard",
                "label_ar": "كودهارد",
                "label_fr": "Coudehard"
            },
            {
                "value": 29214,
                "label": "Cairon",
                "label_ar": "كايرون",
                "label_fr": "Cairon"
            },
            {
                "value": 29215,
                "label": "Saint-Martin-des-Champs",
                "label_ar": "سان مارتن دي تشامب",
                "label_fr": "Saint-Martin-des-Champs"
            },
            {
                "value": 29216,
                "label": "Echauffour",
                "label_ar": "إشوفور",
                "label_fr": "Echauffour"
            },
            {
                "value": 29217,
                "label": "Ouville",
                "label_ar": "أوفيل",
                "label_fr": "Ouville"
            },
            {
                "value": 29218,
                "label": "Saint-Brice-de-Landelles",
                "label_ar": "سان بريس دي لانديليس",
                "label_fr": "Saint-Brice-de-Landelles"
            },
            {
                "value": 29219,
                "label": "Carentan",
                "label_ar": "كارنتان",
                "label_fr": "Carentan"
            },
            {
                "value": 29220,
                "label": "Fontaine-le-Pin",
                "label_ar": "فونتين لو بين",
                "label_fr": "Fontaine-le-Pin"
            },
            {
                "value": 29221,
                "label": "Brecey",
                "label_ar": "بريسي",
                "label_fr": "Brecey"
            },
            {
                "value": 29222,
                "label": "Vassy",
                "label_ar": "فاسي",
                "label_fr": "Vassy"
            },
            {
                "value": 29223,
                "label": "La Graverie",
                "label_ar": "لا غرافيري",
                "label_fr": "La Graverie"
            },
            {
                "value": 29224,
                "label": "Buceels",
                "label_ar": "أحزمة",
                "label_fr": "Buceels"
            },
            {
                "value": 29225,
                "label": "Villers-Canivet",
                "label_ar": "فيليرز-كانيفيت",
                "label_fr": "Villers-Canivet"
            },
            {
                "value": 29226,
                "label": "Le Tourneur",
                "label_ar": "لو تورنيور",
                "label_fr": "Le Tourneur"
            },
            {
                "value": 29227,
                "label": "Soliers",
                "label_ar": "سوليرس",
                "label_fr": "Soliers"
            },
            {
                "value": 29228,
                "label": "Saint-Andre-de-Messei",
                "label_ar": "سان أندريه دي ميسي",
                "label_fr": "Saint-André-de-Messei"
            },
            {
                "value": 29229,
                "label": "Montmartin-sur-Mer",
                "label_ar": "مونتمارتين سور مير",
                "label_fr": "Montmartin-sur-Mer"
            },
            {
                "value": 29230,
                "label": "Boissy-Maugis",
                "label_ar": "بويسي موجيس",
                "label_fr": "Boissy-Maugis"
            },
            {
                "value": 29231,
                "label": "Amfreville",
                "label_ar": "أمفريفيل",
                "label_fr": "Amfreville"
            },
            {
                "value": 29232,
                "label": "Les Forges",
                "label_ar": "ليه فورجيس",
                "label_fr": "Les Forges"
            },
            {
                "value": 29233,
                "label": "Cossesseville",
                "label_ar": "كوسيسفيل",
                "label_fr": "Cossesseville"
            },
            {
                "value": 29234,
                "label": "Cerise",
                "label_ar": "سيريز",
                "label_fr": "Cerise"
            },
            {
                "value": 29235,
                "label": "Moon-sur-Elle",
                "label_ar": "مون سور ايل",
                "label_fr": "Moon-sur-Elle"
            },
            {
                "value": 29236,
                "label": "Saint-Jean-le-Blanc",
                "label_ar": "سان جان لو بلان",
                "label_fr": "Saint-Jean-le-Blanc"
            },
            {
                "value": 29237,
                "label": "Bellengreville",
                "label_ar": "بيلنجريفيل",
                "label_fr": "Bellengreville"
            },
            {
                "value": 29238,
                "label": "Saint-Ouen-sur-Iton",
                "label_ar": "Saint-Ouen-sur-Iton",
                "label_fr": "Saint-Ouen-sur-Iton"
            },
            {
                "value": 29239,
                "label": "Camprond",
                "label_ar": "كامبروند",
                "label_fr": "Camprond"
            },
            {
                "value": 29240,
                "label": "Sommervieu",
                "label_ar": "سوميرفيو",
                "label_fr": "Sommervieu"
            },
            {
                "value": 29241,
                "label": "Equeurdreville-Hainneville",
                "label_ar": "Equeurdreville-Hainneville",
                "label_fr": "Équeurdreville-Hainneville"
            },
            {
                "value": 29242,
                "label": "La Bigne",
                "label_ar": "لا بيني",
                "label_fr": "La Bigne"
            },
            {
                "value": 29243,
                "label": "Bretteville-sur-Odon",
                "label_ar": "بريتفيل سور أودون",
                "label_fr": "Bretteville-sur-Odon"
            },
            {
                "value": 29244,
                "label": "Herouvillette",
                "label_ar": "هيروفيليت",
                "label_fr": "Hérouvillette"
            },
            {
                "value": 29245,
                "label": "Saint-Pierre-du-Regard",
                "label_ar": "سان بيير دو ريجارد",
                "label_fr": "Saint-Pierre-du-Regard"
            },
            {
                "value": 29246,
                "label": "Ceaux",
                "label_ar": "كيو",
                "label_fr": "Ceaux"
            },
            {
                "value": 29247,
                "label": "Fye",
                "label_ar": "صبغ",
                "label_fr": "Fye"
            },
            {
                "value": 29248,
                "label": "Isigny-le-Buat",
                "label_ar": "Isigny-le-Buat",
                "label_fr": "Isigny-le-Buat"
            },
            {
                "value": 29249,
                "label": "Saint-Jean-des-Baisants",
                "label_ar": "سان جان دي بايسانتس",
                "label_fr": "Saint-Jean-des-Baisants"
            },
            {
                "value": 29250,
                "label": "Villedieu-les-Poeles",
                "label_ar": "Villedieu-les-Poeles",
                "label_fr": "Villedieu-les-Poêles"
            },
            {
                "value": 29251,
                "label": "Saint-Remy",
                "label_ar": "سانت ريمي",
                "label_fr": "Saint-Rémy"
            },
            {
                "value": 29252,
                "label": "Irai",
                "label_ar": "إيراي",
                "label_fr": "Irai"
            },
            {
                "value": 29253,
                "label": "Notre-Dame-de-Livaye",
                "label_ar": "نوتردام دي ليفاي",
                "label_fr": "Notre-Dame-de-Livaye"
            },
            {
                "value": 29254,
                "label": "Saint-Desir",
                "label_ar": "سانت ديسير",
                "label_fr": "Saint-Désir"
            },
            {
                "value": 29255,
                "label": "Giberville",
                "label_ar": "جيبرفيل",
                "label_fr": "Giberville"
            },
            {
                "value": 29256,
                "label": "Athis-de-l'Orne",
                "label_ar": "Athis-de-l'Orne",
                "label_fr": "Athis-de-l'Orne"
            },
            {
                "value": 29257,
                "label": "Saint-Sulpice-sur-Risle",
                "label_ar": "سان سولبيس سور ريسل",
                "label_fr": "Saint-Sulpice-sur-Risle"
            },
            {
                "value": 29258,
                "label": "Vimoutiers",
                "label_ar": "فيموتيرس",
                "label_fr": "Vimoutiers"
            },
            {
                "value": 29259,
                "label": "Ouffieres",
                "label_ar": "أوفيريس",
                "label_fr": "Ouffières"
            },
            {
                "value": 29260,
                "label": "La Houblonniere",
                "label_ar": "لا هوبلونيير",
                "label_fr": "La Houblonnière"
            },
            {
                "value": 29261,
                "label": "Thezy-Glimont",
                "label_ar": "Thezy-Glimont",
                "label_fr": "Thezy-Glimont"
            },
            {
                "value": 29262,
                "label": "Thieuloy-Saint-Antoine",
                "label_ar": "ثيولوي سانت انطوان",
                "label_fr": "Thieuloy-Saint-Antoine"
            },
            {
                "value": 29263,
                "label": "Courmelles",
                "label_ar": "كورميلس",
                "label_fr": "Courmelles"
            },
            {
                "value": 29264,
                "label": "Saint-Martin-le-Noeud",
                "label_ar": "سان مارتن لو نويد",
                "label_fr": "Saint-Martin-le-Noeud"
            },
            {
                "value": 29265,
                "label": "Heilly",
                "label_ar": "هيلي",
                "label_fr": "Heilly"
            },
            {
                "value": 29266,
                "label": "Dreslincourt",
                "label_ar": "درسلينكور",
                "label_fr": "Dreslincourt"
            },
            {
                "value": 29267,
                "label": "Moncetz-Longevas",
                "label_ar": "Moncetz-Longevas",
                "label_fr": "Moncetz-Longevas"
            },
            {
                "value": 29268,
                "label": "Sault-les-Rethel",
                "label_ar": "سولت ليه ريثيل",
                "label_fr": "Sault-les-Rethel"
            },
            {
                "value": 29269,
                "label": "Ville",
                "label_ar": "فيل",
                "label_fr": "Ville"
            },
            {
                "value": 29270,
                "label": "Sebourg",
                "label_ar": "سيبور",
                "label_fr": "Sebourg"
            },
            {
                "value": 29271,
                "label": "Fechain",
                "label_ar": "براز",
                "label_fr": "Féchaine"
            },
            {
                "value": 29272,
                "label": "Roost-Warendin",
                "label_ar": "روست واريندين",
                "label_fr": "Roost-Warendin"
            },
            {
                "value": 29273,
                "label": "Linselles",
                "label_ar": "Linselles",
                "label_fr": "Linselles"
            },
            {
                "value": 29274,
                "label": "Leforest",
                "label_ar": "ليفوريست",
                "label_fr": "Leforest"
            },
            {
                "value": 29275,
                "label": "Bavay",
                "label_ar": "بافاي",
                "label_fr": "Bavay"
            },
            {
                "value": 29276,
                "label": "Rumaucourt",
                "label_ar": "روموكورت",
                "label_fr": "Rumaucourt"
            },
            {
                "value": 29277,
                "label": "Courchelettes",
                "label_ar": "كورشليتس",
                "label_fr": "Courchelettes"
            },
            {
                "value": 29278,
                "label": "Saint-Martin-d'Ablois",
                "label_ar": "سان مارتن دابلويس",
                "label_fr": "Saint-Martin-d'Ablois"
            },
            {
                "value": 29279,
                "label": "La Grandville",
                "label_ar": "لا جراندفيل",
                "label_fr": "La Grandville"
            },
            {
                "value": 29280,
                "label": "Escautpont",
                "label_ar": "Escautpont",
                "label_fr": "Escautpont"
            },
            {
                "value": 29281,
                "label": "Haramont",
                "label_ar": "هارامونت",
                "label_fr": "Haramont"
            },
            {
                "value": 29282,
                "label": "Mondescourt",
                "label_ar": "مونديسكور",
                "label_fr": "Mondescourt"
            },
            {
                "value": 29283,
                "label": "Thourotte",
                "label_ar": "ثوروت",
                "label_fr": "Thourotte"
            },
            {
                "value": 29284,
                "label": "Rubempre",
                "label_ar": "روبيمبر",
                "label_fr": "Rubempre"
            },
            {
                "value": 29285,
                "label": "Precy-sur-Oise",
                "label_ar": "بريسي سور وايز",
                "label_fr": "Précy-sur-Oise"
            },
            {
                "value": 29286,
                "label": "Alliancelles",
                "label_ar": "اليانسيليس",
                "label_fr": "Alliancelles"
            },
            {
                "value": 29287,
                "label": "Boulogne-la-Grasse",
                "label_ar": "بولوني لا جراس",
                "label_fr": "Boulogne-la-Grasse"
            },
            {
                "value": 29288,
                "label": "Margny-les-Compiegne",
                "label_ar": "مارجني ليه كومبين",
                "label_fr": "Margny-les-Compiègne"
            },
            {
                "value": 29289,
                "label": "Troissereux",
                "label_ar": "ترويسيروكس",
                "label_fr": "Troissereux"
            },
            {
                "value": 29290,
                "label": "Grivillers",
                "label_ar": "Grivillers",
                "label_fr": "Grivillers"
            },
            {
                "value": 29291,
                "label": "Agnetz",
                "label_ar": "أجنيتز",
                "label_fr": "Agnetz"
            },
            {
                "value": 29292,
                "label": "Bantigny",
                "label_ar": "بانتيني",
                "label_fr": "Bantigny"
            },
            {
                "value": 29293,
                "label": "Sainte-Marie-Cappel",
                "label_ar": "سانت ماري كابيل",
                "label_fr": "Sainte-Marie-Cappel"
            },
            {
                "value": 29294,
                "label": "Souchez",
                "label_ar": "سوشيز",
                "label_fr": "Souchez"
            },
            {
                "value": 29295,
                "label": "Houplin-Ancoisne",
                "label_ar": "هوبلين أنكويسن",
                "label_fr": "Houplin-Ancoisne"
            },
            {
                "value": 29296,
                "label": "Aix-en-Ergny",
                "label_ar": "إيكس أون إرجني",
                "label_fr": "Aix-en-Ergny"
            },
            {
                "value": 29297,
                "label": "Westbecourt",
                "label_ar": "ويستبيكورت",
                "label_fr": "Westbecourt"
            },
            {
                "value": 29298,
                "label": "Ayette",
                "label_ar": "أييت",
                "label_fr": "Ayette"
            },
            {
                "value": 29299,
                "label": "Bouvelinghem",
                "label_ar": "Bouvelinghem",
                "label_fr": "Bouvelinghem"
            },
            {
                "value": 29300,
                "label": "Wanquetin",
                "label_ar": "وانكيتين",
                "label_fr": "Wanquetin"
            },
            {
                "value": 29301,
                "label": "Sainte-Marie-Kerque",
                "label_ar": "سانت ماري كيرك",
                "label_fr": "Sainte-Marie-Kerque"
            },
            {
                "value": 29302,
                "label": "Breuil",
                "label_ar": "برويل",
                "label_fr": "Breuil"
            },
            {
                "value": 29303,
                "label": "Gelannes",
                "label_ar": "جيلانيس",
                "label_fr": "Gelannes"
            },
            {
                "value": 29304,
                "label": "Chamagne",
                "label_ar": "شامان",
                "label_fr": "Chamagne"
            },
            {
                "value": 29305,
                "label": "Vertus",
                "label_ar": "فيرتوس",
                "label_fr": "Vertus"
            },
            {
                "value": 29306,
                "label": "Taissy",
                "label_ar": "Taissy",
                "label_fr": "Taissy"
            },
            {
                "value": 29307,
                "label": "Sillery",
                "label_ar": "سخافة",
                "label_fr": "Sillery"
            },
            {
                "value": 29308,
                "label": "Pomacle",
                "label_ar": "بوماكل",
                "label_fr": "Pomacle"
            },
            {
                "value": 29309,
                "label": "Sameon",
                "label_ar": "ساميون",
                "label_fr": "Sameon"
            },
            {
                "value": 29310,
                "label": "Beaufort",
                "label_ar": "بوفورت",
                "label_fr": "Beaufort"
            },
            {
                "value": 29311,
                "label": "Holnon",
                "label_ar": "هولنون",
                "label_fr": "Holnon"
            },
            {
                "value": 29312,
                "label": "La Varenne",
                "label_ar": "لا فارين",
                "label_fr": "La Varenne"
            },
            {
                "value": 29313,
                "label": "Les Brouzils",
                "label_ar": "ليه بروزيلز",
                "label_fr": "Les Brouzils"
            },
            {
                "value": 29314,
                "label": "Mortagne-sur-Sevre",
                "label_ar": "مورتاني سور سيفري",
                "label_fr": "Mortagne-sur-Sèvre"
            },
            {
                "value": 29315,
                "label": "La Flocelliere",
                "label_ar": "لا فلوسيلير",
                "label_fr": "La Flocellière"
            },
            {
                "value": 29316,
                "label": "Guemene-Penfao",
                "label_ar": "Guemene-Penfao",
                "label_fr": "Guemene-Penfao"
            },
            {
                "value": 29317,
                "label": "Saint-Avaugourd-des-Landes",
                "label_ar": "سان أفوغورد دي لاند",
                "label_fr": "Saint-Avaugourd-des-Landes"
            },
            {
                "value": 29318,
                "label": "Saint-Mathurin",
                "label_ar": "سانت ماتورين",
                "label_fr": "Saint-Mathurin"
            },
            {
                "value": 29319,
                "label": "La Chapelle-Montligeon",
                "label_ar": "لا شابيل مونتليجون",
                "label_fr": "La Chapelle-Montligeon"
            },
            {
                "value": 29320,
                "label": "Saint-Barthelemy-de-Bussiere",
                "label_ar": "سان بارتيليمي دي بوسير",
                "label_fr": "Saint-Barthélemy-de-Bussière"
            },
            {
                "value": 29321,
                "label": "Cosse-le-Vivien",
                "label_ar": "كوسيه لو فيفيان",
                "label_fr": "Cosse-le-Vivien"
            },
            {
                "value": 29322,
                "label": "Entrammes",
                "label_ar": "انتراممس",
                "label_fr": "Entrammes"
            },
            {
                "value": 29323,
                "label": "Becon-les-Granits",
                "label_ar": "بيكون ليه جرانيت",
                "label_fr": "Becon-les-Granits"
            },
            {
                "value": 29324,
                "label": "Durtal",
                "label_ar": "دورال",
                "label_fr": "Durtal"
            },
            {
                "value": 29325,
                "label": "Jumelles",
                "label_ar": "جوميل",
                "label_fr": "Jumelles"
            },
            {
                "value": 29326,
                "label": "Laigne-en-Belin",
                "label_ar": "ليني أون بيلين",
                "label_fr": "Laigne-en-Belin"
            },
            {
                "value": 29327,
                "label": "Montreuil-sous-Perouse",
                "label_ar": "مونتروي سو بيرو",
                "label_fr": "Montreuil-sous-Pérouse"
            },
            {
                "value": 29328,
                "label": "Saint-Hilaire-le-Vouhis",
                "label_ar": "سان هيلير لو فوهيس",
                "label_fr": "Saint-Hilaire-le-Vouhis"
            },
            {
                "value": 29329,
                "label": "Pontvallain",
                "label_ar": "بونتفالان",
                "label_fr": "Pontvallain"
            },
            {
                "value": 29330,
                "label": "La Cropte",
                "label_ar": "لا كروبت",
                "label_fr": "La Cropte"
            },
            {
                "value": 29331,
                "label": "Le Pallet",
                "label_ar": "لو باليت",
                "label_fr": "Le Pallet"
            },
            {
                "value": 29332,
                "label": "Les Rosiers-sur-Loire",
                "label_ar": "ليه روزيه سور لوار",
                "label_fr": "Les Rosiers-sur-Loire"
            },
            {
                "value": 29333,
                "label": "Vue",
                "label_ar": "Vue",
                "label_fr": "Vue"
            },
            {
                "value": 29334,
                "label": "Chaudefonds-sur-Layon",
                "label_ar": "Chaudefonds-sur-Layon",
                "label_fr": "Chaudefonds-sur-layon"
            },
            {
                "value": 29335,
                "label": "Willgottheim",
                "label_ar": "ويلجوتهايم",
                "label_fr": "Willgottheim"
            },
            {
                "value": 29336,
                "label": "Ravieres",
                "label_ar": "رافيرز",
                "label_fr": "Ravieres"
            },
            {
                "value": 29337,
                "label": "Sombacour",
                "label_ar": "سومباكور",
                "label_fr": "Sombacour"
            },
            {
                "value": 29338,
                "label": "Gries",
                "label_ar": "جريس",
                "label_fr": "Gries"
            },
            {
                "value": 29339,
                "label": "Norges-la-Ville",
                "label_ar": "نورجس لا فيل",
                "label_fr": "Norges-la-Ville"
            },
            {
                "value": 29340,
                "label": "Gigny-sur-Saone",
                "label_ar": "جيني سور ساون",
                "label_fr": "Gigny-sur-Saône"
            },
            {
                "value": 29341,
                "label": "Le Hohwald",
                "label_ar": "لو هوهوالد",
                "label_fr": "Le Hohwald"
            },
            {
                "value": 29342,
                "label": "Ingolsheim",
                "label_ar": "إنغولشيم",
                "label_fr": "Ingolsheim"
            },
            {
                "value": 29343,
                "label": "Altorf",
                "label_ar": "ألتورف",
                "label_fr": "Altorf"
            },
            {
                "value": 29344,
                "label": "Blaesheim",
                "label_ar": "بلاشيم",
                "label_fr": "Blaesheim"
            },
            {
                "value": 29345,
                "label": "Wittersdorf",
                "label_ar": "فيترسدورف",
                "label_fr": "Wittersdorf"
            },
            {
                "value": 29346,
                "label": "Harskirchen",
                "label_ar": "هارسكيرشن",
                "label_fr": "Harskirchen"
            },
            {
                "value": 29347,
                "label": "Zellwiller",
                "label_ar": "زيلويلر",
                "label_fr": "Zellwiller"
            },
            {
                "value": 29348,
                "label": "Morschwiller-le-Bas",
                "label_ar": "مورشويلر لو باس",
                "label_fr": "Morschwiller-le-Bas"
            },
            {
                "value": 29349,
                "label": "Limersheim",
                "label_ar": "ليميرشيم",
                "label_fr": "Limersheim"
            },
            {
                "value": 29350,
                "label": "Mertzen",
                "label_ar": "ميرتزن",
                "label_fr": "Mertzen"
            },
            {
                "value": 29351,
                "label": "Courchaton",
                "label_ar": "كورشاتون",
                "label_fr": "Courchaton"
            },
            {
                "value": 29352,
                "label": "Coutarnoux",
                "label_ar": "كوتارنو",
                "label_fr": "Coutarnoux"
            },
            {
                "value": 29353,
                "label": "Montenois",
                "label_ar": "مونتينوا",
                "label_fr": "Montenois"
            },
            {
                "value": 29354,
                "label": "Losne",
                "label_ar": "لوسن",
                "label_fr": "Losne"
            },
            {
                "value": 29355,
                "label": "Beauvoisin",
                "label_ar": "بوفوازين",
                "label_fr": "Beauvoisin"
            },
            {
                "value": 29356,
                "label": "Quers",
                "label_ar": "Quers",
                "label_fr": "Quers"
            },
            {
                "value": 29357,
                "label": "Villeneuve-les-Genets",
                "label_ar": "فيلنوف ليه جينيتس",
                "label_fr": "Villeneuve-les-Genets"
            },
            {
                "value": 29358,
                "label": "Broin",
                "label_ar": "بروين",
                "label_fr": "Broin"
            },
            {
                "value": 29359,
                "label": "Fraisans",
                "label_ar": "فريزن",
                "label_fr": "Fraisans"
            },
            {
                "value": 29360,
                "label": "Herimoncourt",
                "label_ar": "هيرمونكور",
                "label_fr": "Hérimoncourt"
            },
            {
                "value": 29361,
                "label": "Freland",
                "label_ar": "فريلاند",
                "label_fr": "Freland"
            },
            {
                "value": 29362,
                "label": "La Bourgonce",
                "label_ar": "لا بورغونس",
                "label_fr": "La Bourgonce"
            },
            {
                "value": 29363,
                "label": "Chille",
                "label_ar": "تشيل",
                "label_fr": "Chille"
            },
            {
                "value": 29364,
                "label": "Les Fourgs",
                "label_ar": "ليه فورجز",
                "label_fr": "Les Fourgs"
            },
            {
                "value": 29365,
                "label": "Bonnard",
                "label_ar": "بونارد",
                "label_fr": "Bonnard"
            },
            {
                "value": 29366,
                "label": "Creusot",
                "label_ar": "كريوسوت",
                "label_fr": "Creusot"
            },
            {
                "value": 29367,
                "label": "Lapoutroie",
                "label_ar": "لابوتروى",
                "label_fr": "Lapoutroie"
            },
            {
                "value": 29368,
                "label": "Souffelweyersheim",
                "label_ar": "سوفيلويرشيم",
                "label_fr": "Souffelweyersheim"
            },
            {
                "value": 29369,
                "label": "Cussy-les-Forges",
                "label_ar": "Cussy-les-Forges",
                "label_fr": "Cussy-les-Forges"
            },
            {
                "value": 29370,
                "label": "Curdin",
                "label_ar": "كوردين",
                "label_fr": "Curdin"
            },
            {
                "value": 29371,
                "label": "Annay-sur-Serein",
                "label_ar": "آناي سور سيرين",
                "label_fr": "Annay-sur-Serein"
            },
            {
                "value": 29372,
                "label": "Druyes-les-Belles-Fontaines",
                "label_ar": "Druyes-les-Belles-Fontaines",
                "label_fr": "Druyes-les-Belles-Fontaines"
            },
            {
                "value": 29373,
                "label": "Les Hopitaux-Vieux",
                "label_ar": "ليه هوبيتوكس فيو",
                "label_fr": "Les Hopitaux-Vieux"
            },
            {
                "value": 29374,
                "label": "Nordhouse",
                "label_ar": "نوردهاوس",
                "label_fr": "Nordhouse"
            },
            {
                "value": 29375,
                "label": "Bischoffsheim",
                "label_ar": "بيشوفشيم",
                "label_fr": "Bischoffsheim"
            },
            {
                "value": 29376,
                "label": "Bellefosse",
                "label_ar": "بلفوس",
                "label_fr": "Bellefosse"
            },
            {
                "value": 29377,
                "label": "Ruelisheim",
                "label_ar": "روليشيم",
                "label_fr": "Ruelisheim"
            },
            {
                "value": 29378,
                "label": "Busy",
                "label_ar": "مشغول",
                "label_fr": "Occupé"
            },
            {
                "value": 29379,
                "label": "Beutal",
                "label_ar": "بيوتال",
                "label_fr": "Beutal"
            },
            {
                "value": 29380,
                "label": "Cresancey",
                "label_ar": "كريسنسي",
                "label_fr": "Cresancey"
            },
            {
                "value": 29381,
                "label": "Bazoches",
                "label_ar": "بازوتش",
                "label_fr": "Bazoches"
            },
            {
                "value": 29382,
                "label": "Montchanin",
                "label_ar": "مونتشانين",
                "label_fr": "Montchanin"
            },
            {
                "value": 29383,
                "label": "Dasle",
                "label_ar": "داسل",
                "label_fr": "Dasle"
            },
            {
                "value": 29384,
                "label": "Malpas",
                "label_ar": "مالباس",
                "label_fr": "Malpas"
            },
            {
                "value": 29385,
                "label": "Merry-la-Vallee",
                "label_ar": "ميري لا فاليه",
                "label_fr": "Merry-la-Vallée"
            },
            {
                "value": 29386,
                "label": "Chamoux",
                "label_ar": "شاموكس",
                "label_fr": "Chamoux"
            },
            {
                "value": 29387,
                "label": "Baigneux-les-Juifs",
                "label_ar": "Baigneux-les-Juifs",
                "label_fr": "Baigneux-les-Juifs"
            },
            {
                "value": 29388,
                "label": "Saint-Mard-de-Vaux",
                "label_ar": "سان مارد دي فو",
                "label_fr": "Saint-Mard-de-Vaux"
            },
            {
                "value": 29389,
                "label": "Arlay",
                "label_ar": "أرلاي",
                "label_fr": "Arlay"
            },
            {
                "value": 29390,
                "label": "Noiron-sous-Gevrey",
                "label_ar": "نويرون سوس جيفري",
                "label_fr": "Noiron-sous-Gevrey"
            },
            {
                "value": 29391,
                "label": "Villevieux",
                "label_ar": "فيليفيو",
                "label_fr": "Villevieux"
            },
            {
                "value": 29392,
                "label": "Roppentzwiller",
                "label_ar": "روبينتزويلر",
                "label_fr": "Roppentzwiller"
            },
            {
                "value": 29393,
                "label": "Hombourg",
                "label_ar": "هامبورغ",
                "label_fr": "Hombourg"
            },
            {
                "value": 29394,
                "label": "Rezonville",
                "label_ar": "ريزونفيل",
                "label_fr": "Rezonville"
            },
            {
                "value": 29395,
                "label": "Arbois",
                "label_ar": "أربوا",
                "label_fr": "Arbois"
            },
            {
                "value": 29396,
                "label": "Amange",
                "label_ar": "أمانج",
                "label_fr": "Amange"
            },
            {
                "value": 29397,
                "label": "Malange",
                "label_ar": "تشوه",
                "label_fr": "Malange"
            },
            {
                "value": 29398,
                "label": "Charnay-les-Macon",
                "label_ar": "تشارناي ليه ماكون",
                "label_fr": "Charnay-les-Macon"
            },
            {
                "value": 29399,
                "label": "Moutiers",
                "label_ar": "موتيرس",
                "label_fr": "Moutiers"
            },
            {
                "value": 29400,
                "label": "Groix",
                "label_ar": "جرويكس",
                "label_fr": "Groix"
            },
            {
                "value": 29401,
                "label": "Guipel",
                "label_ar": "جويبيل",
                "label_fr": "Guipel"
            },
            {
                "value": 29402,
                "label": "Saint-Pierre-de-Plesguen",
                "label_ar": "سان بيير دي بليسجن",
                "label_fr": "Saint-Pierre-de-Plesguen"
            },
            {
                "value": 29403,
                "label": "Beignon",
                "label_ar": "بيجنون",
                "label_fr": "Beignon"
            },
            {
                "value": 29404,
                "label": "Landujan",
                "label_ar": "لاندوجان",
                "label_fr": "Landujan"
            },
            {
                "value": 29405,
                "label": "Langourla",
                "label_ar": "لانغورلا",
                "label_fr": "Langourla"
            },
            {
                "value": 29406,
                "label": "Treveneuc",
                "label_ar": "تريفينوك",
                "label_fr": "Tréveneuc"
            },
            {
                "value": 29407,
                "label": "Osse",
                "label_ar": "عوسه",
                "label_fr": "Osse"
            },
            {
                "value": 29408,
                "label": "Pordic",
                "label_ar": "بورديك",
                "label_fr": "Pordique"
            },
            {
                "value": 29409,
                "label": "Moreac",
                "label_ar": "أكثر",
                "label_fr": "Moreac"
            },
            {
                "value": 29410,
                "label": "Plussulien",
                "label_ar": "بلوسولين",
                "label_fr": "Plussulien"
            },
            {
                "value": 29411,
                "label": "La Bouexiere",
                "label_ar": "لا بوكسير",
                "label_fr": "La Bouexière"
            },
            {
                "value": 29412,
                "label": "Quily",
                "label_ar": "بهدوء",
                "label_fr": "Quily"
            },
            {
                "value": 29413,
                "label": "Pommerit-le-Vicomte",
                "label_ar": "Pommerit-le-Vicomte",
                "label_fr": "Pommerit-le-Vicomte"
            },
            {
                "value": 29414,
                "label": "Taden",
                "label_ar": "تادن",
                "label_fr": "Taden"
            },
            {
                "value": 29415,
                "label": "Merdrignac",
                "label_ar": "Merdrignac",
                "label_fr": "Merdrignac"
            },
            {
                "value": 29416,
                "label": "Callac",
                "label_ar": "كالاك",
                "label_fr": "Callac"
            },
            {
                "value": 29417,
                "label": "Andel",
                "label_ar": "أنديل",
                "label_fr": "Andel"
            },
            {
                "value": 29418,
                "label": "Monterblanc",
                "label_ar": "مونتر بلانك",
                "label_fr": "Monterblanc"
            },
            {
                "value": 29419,
                "label": "Lanmeur",
                "label_ar": "لانمور",
                "label_fr": "Lanmeur"
            },
            {
                "value": 29420,
                "label": "Saint-Seglin",
                "label_ar": "سان سيجلين",
                "label_fr": "Saint-Seglin"
            },
            {
                "value": 29421,
                "label": "Peumerit",
                "label_ar": "بيومريت",
                "label_fr": "Peumerit"
            },
            {
                "value": 29422,
                "label": "Mauron",
                "label_ar": "مورون",
                "label_fr": "Mauron"
            },
            {
                "value": 29423,
                "label": "Le Theil-de-Bretagne",
                "label_ar": "لو ثيل دي بريتاني",
                "label_fr": "Le Theil-de-Bretagne"
            },
            {
                "value": 29424,
                "label": "Cinqueux",
                "label_ar": "سينكو",
                "label_fr": "Cinqueux"
            },
            {
                "value": 29425,
                "label": "Lucciana",
                "label_ar": "لوسيانا",
                "label_fr": "Lucciana"
            },
            {
                "value": 29426,
                "label": "Labastide-d'Anjou",
                "label_ar": "لاباستيد دانجو",
                "label_fr": "Labastide-d'Anjou"
            },
            {
                "value": 29427,
                "label": "Aubinges",
                "label_ar": "أوبينجيس",
                "label_fr": "Aubinges"
            },
            {
                "value": 29428,
                "label": "Aire-sur-l'Adour",
                "label_ar": "آير سور لادور",
                "label_fr": "Aire-sur-l'Adour"
            },
            {
                "value": 29429,
                "label": "Ahuille",
                "label_ar": "أهويل",
                "label_fr": "Ahuille"
            },
            {
                "value": 29430,
                "label": "Eygurande-et-Gardedeuil",
                "label_ar": "Eygurande-et-Gardedeuil",
                "label_fr": "Eygurande-et-Gardedeuil"
            },
            {
                "value": 29431,
                "label": "Ville-d'Avray",
                "label_ar": "فيل دافراي",
                "label_fr": "Ville-d'Avray"
            },
            {
                "value": 29432,
                "label": "Elincourt-Sainte-Marguerite",
                "label_ar": "Elincourt-Sainte-Marguerite",
                "label_fr": "Élincourt-Sainte-Marguerite"
            },
            {
                "value": 29433,
                "label": "L'Etang-la-Ville",
                "label_ar": "لاتانغ لا فيل",
                "label_fr": "L'Etang-la-Ville"
            },
            {
                "value": 29434,
                "label": "Noyelles-Godault",
                "label_ar": "نويل جودولت",
                "label_fr": "Noyelles-Godault"
            },
            {
                "value": 29435,
                "label": "Camblain-Chatelain",
                "label_ar": "كامبلين شاتلين",
                "label_fr": "Camblain-Châtelain"
            },
            {
                "value": 29436,
                "label": "Plouvain",
                "label_ar": "بلوفان",
                "label_fr": "Plouvain"
            },
            {
                "value": 29437,
                "label": "Cognin",
                "label_ar": "كوجنين",
                "label_fr": "Cognin"
            },
            {
                "value": 29438,
                "label": "Les Halles",
                "label_ar": "ليه هال",
                "label_fr": "Les Halles"
            },
            {
                "value": 29439,
                "label": "Saint-Symphorien-sur-Coise",
                "label_ar": "سان سيمفوريان سور كواز",
                "label_fr": "Saint-Symphorien-sur-Coise"
            },
            {
                "value": 29440,
                "label": "Saint-Martin-en-Haut",
                "label_ar": "سان مارتن أون أوت",
                "label_fr": "Saint-Martin-en-Haut"
            },
            {
                "value": 29441,
                "label": "Vaugneray",
                "label_ar": "فوغنراي",
                "label_fr": "Vaugneray"
            },
            {
                "value": 29442,
                "label": "Hauterives",
                "label_ar": "Hauterives",
                "label_fr": "Hauterives"
            },
            {
                "value": 29443,
                "label": "Duerne",
                "label_ar": "دويرن",
                "label_fr": "Duerne"
            },
            {
                "value": 29444,
                "label": "Souzy",
                "label_ar": "سوزي",
                "label_fr": "Souzy"
            },
            {
                "value": 29445,
                "label": "Sallaumines",
                "label_ar": "سالومين",
                "label_fr": "Sallaumines"
            },
            {
                "value": 29446,
                "label": "Mareil-Marly",
                "label_ar": "مريل مارلي",
                "label_fr": "Mareil-Marly"
            },
            {
                "value": 29447,
                "label": "Bougival",
                "label_ar": "بوجيفال",
                "label_fr": "Bougival"
            },
            {
                "value": 29448,
                "label": "Leffrinckoucke",
                "label_ar": "ليفرينكوك",
                "label_fr": "Leffrinckoucke"
            },
            {
                "value": 29449,
                "label": "Calonne-Ricouart",
                "label_ar": "كالون ريكوارت",
                "label_fr": "Calonne-Ricouart"
            },
            {
                "value": 29450,
                "label": "Labuissiere",
                "label_ar": "لابويسير",
                "label_fr": "Labuissière"
            },
            {
                "value": 29451,
                "label": "Cauchy-à-la-Tour",
                "label_ar": "كوشي-آ-لا-تور",
                "label_fr": "Cauchy-à-la-Tour"
            },
            {
                "value": 29452,
                "label": "Bergues",
                "label_ar": "بيرجيس",
                "label_fr": "Bergues"
            },
            {
                "value": 29453,
                "label": "Marquillies",
                "label_ar": "Marquillies",
                "label_fr": "Marquillies"
            },
            {
                "value": 29454,
                "label": "Ghyvelde",
                "label_ar": "غيفيلد",
                "label_fr": "Ghyvelde"
            },
            {
                "value": 29455,
                "label": "La Tour",
                "label_ar": "لا تور",
                "label_fr": "La Tour"
            },
            {
                "value": 29456,
                "label": "Bondues",
                "label_ar": "السندات",
                "label_fr": "Bondues"
            },
            {
                "value": 29457,
                "label": "Loison-sous-Lens",
                "label_ar": "Loison-sous-Lens",
                "label_fr": "Loison-sous-Lens"
            },
            {
                "value": 29458,
                "label": "Drocourt",
                "label_ar": "دروكور",
                "label_fr": "Drocourt"
            },
            {
                "value": 29459,
                "label": "Noyelles-sous-Lens",
                "label_ar": "Noyelles-sous-Lens",
                "label_fr": "Noyelles-sous-Lens"
            },
            {
                "value": 29460,
                "label": "Jasseron",
                "label_ar": "جاسرون",
                "label_fr": "Jasseron"
            },
            {
                "value": 29461,
                "label": "Les Allues",
                "label_ar": "ليه ألوز",
                "label_fr": "Les Allues"
            },
            {
                "value": 29462,
                "label": "La Villetelle",
                "label_ar": "لا فيليتيل",
                "label_fr": "La Villetelle"
            },
            {
                "value": 29463,
                "label": "Magny",
                "label_ar": "ماجني",
                "label_fr": "Magny"
            },
            {
                "value": 29464,
                "label": "Boran-sur-Oise",
                "label_ar": "Boran-sur-Oise",
                "label_fr": "Boran-sur-Oise"
            },
            {
                "value": 29465,
                "label": "Somloire",
                "label_ar": "Somloire",
                "label_fr": "Somloire"
            },
            {
                "value": 29466,
                "label": "Florent-en-Argonne",
                "label_ar": "فلوران إن أرغون",
                "label_fr": "Florent-en-Argonne"
            },
            {
                "value": 29467,
                "label": "Saint-Prouant",
                "label_ar": "سانت بروانت",
                "label_fr": "Saint-Prouant"
            },
            {
                "value": 29468,
                "label": "Meilhan",
                "label_ar": "ميلهان",
                "label_fr": "Meilhan"
            },
            {
                "value": 29469,
                "label": "La Foret-Fouesnant",
                "label_ar": "لا فوريه فوسنان",
                "label_fr": "La Foret-Fouesnant"
            },
            {
                "value": 29470,
                "label": "Marques",
                "label_ar": "ماركيز",
                "label_fr": "Marques"
            },
            {
                "value": 29471,
                "label": "Saulnes",
                "label_ar": "سولنس",
                "label_fr": "Saulnes"
            },
            {
                "value": 29472,
                "label": "Anizy-le-Chateau",
                "label_ar": "أنزي لو شاتو",
                "label_fr": "Anizy-le-Château"
            },
            {
                "value": 29473,
                "label": "La Jarne",
                "label_ar": "لا جارني",
                "label_fr": "La Jarne"
            },
            {
                "value": 29474,
                "label": "Camaret-sur-Aigues",
                "label_ar": "كاماريت سور إيج",
                "label_fr": "Camaret-sur-Aigues"
            },
            {
                "value": 29475,
                "label": "Saint-Porquier",
                "label_ar": "سان بوركيه",
                "label_fr": "Saint-Porquier"
            },
            {
                "value": 29476,
                "label": "Fontenay",
                "label_ar": "فونتيناي",
                "label_fr": "Fontenay"
            },
            {
                "value": 29477,
                "label": "Grand'Combe-Chateleu",
                "label_ar": "Grand'Combe-Chateleu",
                "label_fr": "Grand'Combe-Châteleu"
            },
            {
                "value": 29478,
                "label": "Saint-Cyr-les-Champagnes",
                "label_ar": "سان سير ليه شامباني",
                "label_fr": "Saint-Cyr-les-Champagnes"
            },
            {
                "value": 29479,
                "label": "Plobsheim",
                "label_ar": "بلوبسهايم",
                "label_fr": "Plobsheim"
            },
            {
                "value": 29480,
                "label": "Collonges-au-Mont-d'Or",
                "label_ar": "Collonges-au-Mont-d'Or",
                "label_fr": "Collonges-au-Mont-d'Or"
            },
            {
                "value": 29481,
                "label": "Bavilliers",
                "label_ar": "بافيلييرز",
                "label_fr": "Bavilliers"
            },
            {
                "value": 29482,
                "label": "Fourqueux",
                "label_ar": "فوركو",
                "label_fr": "Fourqueux"
            },
            {
                "value": 29483,
                "label": "Vaucresson",
                "label_ar": "فوكريسون",
                "label_fr": "Vaucresson"
            },
            {
                "value": 29484,
                "label": "Pierre-Levee",
                "label_ar": "بيير ليفي",
                "label_fr": "Pierre-Levee"
            },
            {
                "value": 29485,
                "label": "Villiers-sur-Morin",
                "label_ar": "فيلير سور مورين",
                "label_fr": "Villiers-sur-Morin"
            },
            {
                "value": 29486,
                "label": "Montolivet",
                "label_ar": "مونتوليفيه",
                "label_fr": "Montolivet"
            },
            {
                "value": 29487,
                "label": "Pommeuse",
                "label_ar": "بوموس",
                "label_fr": "Pommeuse"
            },
            {
                "value": 29488,
                "label": "Cuy",
                "label_ar": "كوي",
                "label_fr": "Cuy"
            },
            {
                "value": 29489,
                "label": "Choisy-au-Bac",
                "label_ar": "تشويسي أو باك",
                "label_fr": "Choisy-au-Bac"
            },
            {
                "value": 29490,
                "label": "Acy-en-Multien",
                "label_ar": "Acy-en-Multien",
                "label_fr": "Acy-en-Multien"
            },
            {
                "value": 29491,
                "label": "Brexent-Enocq",
                "label_ar": "بريكسينت إينوك",
                "label_fr": "Brexent-Enocq"
            },
            {
                "value": 29492,
                "label": "Saint-Michel-sous-Bois",
                "label_ar": "سان ميشيل سو بوا",
                "label_fr": "Saint-Michel-sous-Bois"
            },
            {
                "value": 29493,
                "label": "Ruesnes",
                "label_ar": "روسنيس",
                "label_fr": "Ruesnes"
            },
            {
                "value": 29494,
                "label": "Denier",
                "label_ar": "منكر",
                "label_fr": "Denier"
            },
            {
                "value": 29495,
                "label": "Sommaing",
                "label_ar": "سومينج",
                "label_fr": "Sommaing"
            },
            {
                "value": 29496,
                "label": "Saint Nicolas Les Arras",
                "label_ar": "القديس نيكولا ليه أراس",
                "label_fr": "Saint Nicolas Les Arras"
            },
            {
                "value": 29497,
                "label": "Fournes-en-Weppes",
                "label_ar": "فورنس أون ويبيس",
                "label_fr": "Fournes-en-Weppes"
            },
            {
                "value": 29498,
                "label": "Montcy-Notre-Dame",
                "label_ar": "مونسي نوتردام",
                "label_fr": "Montcy-Notre-Dame"
            },
            {
                "value": 29499,
                "label": "Les Rivieres-Henruel",
                "label_ar": "ليه ريفيير هنرويل",
                "label_fr": "Les Rivières-Henruel"
            },
            {
                "value": 29500,
                "label": "Mandres-la-Cote",
                "label_ar": "ماندريس لا كوت",
                "label_fr": "Mandres-la-Côte"
            },
            {
                "value": 29501,
                "label": "Bienville",
                "label_ar": "بينفيل",
                "label_fr": "Bienville"
            },
            {
                "value": 29502,
                "label": "Crisolles",
                "label_ar": "كريسول",
                "label_fr": "Crisolles"
            },
            {
                "value": 29503,
                "label": "Sault-Saint-Remy",
                "label_ar": "سولت سانت ريمي",
                "label_fr": "Sault-Saint-Rémy"
            },
            {
                "value": 29504,
                "label": "Baisieux",
                "label_ar": "بيسيو",
                "label_fr": "Baisieux"
            },
            {
                "value": 29505,
                "label": "Ferques",
                "label_ar": "فيركس",
                "label_fr": "Ferques"
            },
            {
                "value": 29506,
                "label": "Verton",
                "label_ar": "فيرتون",
                "label_fr": "Verton"
            },
            {
                "value": 29507,
                "label": "Berck",
                "label_ar": "بيرك",
                "label_fr": "Berck"
            },
            {
                "value": 29508,
                "label": "Billy-Berclau",
                "label_ar": "بيلي بيركلاو",
                "label_fr": "Billy-Berclau"
            },
            {
                "value": 29509,
                "label": "Averdoingt",
                "label_ar": "Averdoingt",
                "label_fr": "Averdoingt"
            },
            {
                "value": 29510,
                "label": "Inxent",
                "label_ar": "إنكسنت",
                "label_fr": "Inxent"
            },
            {
                "value": 29511,
                "label": "Pont-de-Briques",
                "label_ar": "بونت دي بريك",
                "label_fr": "Pont-de-Briques"
            },
            {
                "value": 29512,
                "label": "Louvemont",
                "label_ar": "لوفيمونت",
                "label_fr": "Louvemont"
            },
            {
                "value": 29513,
                "label": "Vitry-le-François",
                "label_ar": "فيتري لو فرانسوا",
                "label_fr": "Vitry-le-François"
            },
            {
                "value": 29514,
                "label": "Marcilly-sur-Seine",
                "label_ar": "مارسيلي سور سين",
                "label_fr": "Marcilly-sur-Seine"
            },
            {
                "value": 29515,
                "label": "Crecy-sur-Serre",
                "label_ar": "كريسي سور سيري",
                "label_fr": "Crécy-sur-Serre"
            },
            {
                "value": 29516,
                "label": "Saint-Aubin",
                "label_ar": "سان اوبين",
                "label_fr": "Saint-Aubin"
            },
            {
                "value": 29517,
                "label": "Bajus",
                "label_ar": "باجوس",
                "label_fr": "Bajus"
            },
            {
                "value": 29518,
                "label": "Saint-Martin-Boulogne",
                "label_ar": "سان مارتن بولوني",
                "label_fr": "Saint-Martin-Boulogne"
            },
            {
                "value": 29519,
                "label": "Echinghen",
                "label_ar": "Echinghen",
                "label_fr": "Echinghen"
            },
            {
                "value": 29520,
                "label": "Avesnes-le-Sec",
                "label_ar": "أفيسنيس لو سيك",
                "label_fr": "Avesnes-le-Sec"
            },
            {
                "value": 29521,
                "label": "Cantin",
                "label_ar": "كانتين",
                "label_fr": "Cantin"
            },
            {
                "value": 29522,
                "label": "Ecuires",
                "label_ar": "اكوادور",
                "label_fr": "Écuires"
            },
            {
                "value": 29523,
                "label": "Famars",
                "label_ar": "مشاهير",
                "label_fr": "Famars"
            },
            {
                "value": 29524,
                "label": "Camphin-en-Pevele",
                "label_ar": "كامفين أون بيفيل",
                "label_fr": "Camphin-en-Pevele"
            },
            {
                "value": 29525,
                "label": "Saudoy",
                "label_ar": "سعودوي",
                "label_fr": "Saudoy"
            },
            {
                "value": 29526,
                "label": "Lacroix-Saint-Ouen",
                "label_ar": "لاكروا سان أوين",
                "label_fr": "Lacroix-Saint-Ouen"
            },
            {
                "value": 29527,
                "label": "Crezancy",
                "label_ar": "جريزسي",
                "label_fr": "Crezancy"
            },
            {
                "value": 29528,
                "label": "Joinville",
                "label_ar": "انضمام فيل",
                "label_fr": "Rejoindre la ville"
            },
            {
                "value": 29529,
                "label": "Brugny-Vaudancourt",
                "label_ar": "بروجني فودانكورت",
                "label_fr": "Brugny-Vaudancourt"
            },
            {
                "value": 29530,
                "label": "Vireux-Molhain",
                "label_ar": "فيروكس مولهاين",
                "label_fr": "Vireux-Molhain"
            },
            {
                "value": 29531,
                "label": "Rethel",
                "label_ar": "ريثيل",
                "label_fr": "Rethel"
            },
            {
                "value": 29532,
                "label": "Chessy-les-Pres",
                "label_ar": "شيسي ليه بري",
                "label_fr": "Chessy-les-Prés"
            },
            {
                "value": 29533,
                "label": "Forest-sur-Marque",
                "label_ar": "فورست سور مارك",
                "label_fr": "Forest-sur-Marque"
            },
            {
                "value": 29534,
                "label": "Andres",
                "label_ar": "أندريس",
                "label_fr": "Andrés"
            },
            {
                "value": 29535,
                "label": "Brienne-le-Château",
                "label_ar": "برين لو شاتو",
                "label_fr": "Brienne-le-Château"
            },
            {
                "value": 29536,
                "label": "Charmont-sous-Barbuise",
                "label_ar": "شارمونت سو باربوز",
                "label_fr": "Charmont-sous-Barbuise"
            },
            {
                "value": 29537,
                "label": "Arcis-sur-Aube",
                "label_ar": "Arcis-sur-Aube",
                "label_fr": "Arcis-sur-Aube"
            },
            {
                "value": 29538,
                "label": "Colombiers",
                "label_ar": "كولومبير",
                "label_fr": "Colombiers"
            },
            {
                "value": 29539,
                "label": "Orsan",
                "label_ar": "أورسان",
                "label_fr": "Orsan"
            },
            {
                "value": 29540,
                "label": "Peyriac-de-Mer",
                "label_ar": "بيريا دي مير",
                "label_fr": "Peyriac-de-Mer"
            },
            {
                "value": 29541,
                "label": "Claira",
                "label_ar": "كليرا",
                "label_fr": "Claira"
            },
            {
                "value": 29542,
                "label": "Argens",
                "label_ar": "أرجينز",
                "label_fr": "Argens"
            },
            {
                "value": 29543,
                "label": "Valleraugue",
                "label_ar": "فاليراوج",
                "label_fr": "Valleraugue"
            },
            {
                "value": 29544,
                "label": "Castillon-du-Gard",
                "label_ar": "كاستيون دو جارد",
                "label_fr": "Castillon-du-Gard"
            },
            {
                "value": 29545,
                "label": "Le Lez",
                "label_ar": "لو ليز",
                "label_fr": "Le Lez"
            },
            {
                "value": 29546,
                "label": "Ponteilla",
                "label_ar": "بونتيلا",
                "label_fr": "Ponteilla"
            },
            {
                "value": 29547,
                "label": "Lauret",
                "label_ar": "لوريت",
                "label_fr": "Lauret"
            },
            {
                "value": 29548,
                "label": "Mandagout",
                "label_ar": "Mandagout",
                "label_fr": "Mandagout"
            },
            {
                "value": 29549,
                "label": "Le Soler",
                "label_ar": "لو سولير",
                "label_fr": "Le Soler"
            },
            {
                "value": 29550,
                "label": "Saint-Clement",
                "label_ar": "سانت كليمان",
                "label_fr": "Saint-Clément"
            },
            {
                "value": 29551,
                "label": "La Salvetat-sur-Agout",
                "label_ar": "La Salvetat-sur-Agout",
                "label_fr": "La Salvetat-sur-Agout"
            },
            {
                "value": 29552,
                "label": "Pia",
                "label_ar": "بيا",
                "label_fr": "Pia"
            },
            {
                "value": 29553,
                "label": "Luc-sur-Orbieu",
                "label_ar": "لوك سور أوربيو",
                "label_fr": "Luc-sur-Orbieu"
            },
            {
                "value": 29554,
                "label": "Tavel",
                "label_ar": "تافل",
                "label_fr": "Tavel"
            },
            {
                "value": 29555,
                "label": "Anduze",
                "label_ar": "أندوز",
                "label_fr": "Anduze"
            },
            {
                "value": 29556,
                "label": "Marvejols",
                "label_ar": "مارفجولس",
                "label_fr": "Marvejols"
            },
            {
                "value": 29557,
                "label": "Saint-Andre-de-Roquelongue",
                "label_ar": "سان أندريه دي روكيلونج",
                "label_fr": "Saint-André-de-Roquelongue"
            },
            {
                "value": 29558,
                "label": "Montfaucon",
                "label_ar": "مونتفوكون",
                "label_fr": "Montfaucon"
            },
            {
                "value": 29559,
                "label": "Sonnac-sur-l'Hers",
                "label_ar": "Sonnac-sur-l'Hers",
                "label_fr": "Sonnac-sur-l'Hers"
            },
            {
                "value": 29560,
                "label": "Laurabuc",
                "label_ar": "لورابوك",
                "label_fr": "Laurabuc"
            },
            {
                "value": 29561,
                "label": "Cambieure",
                "label_ar": "Cambieure",
                "label_fr": "Cambieure"
            },
            {
                "value": 29562,
                "label": "Gallician",
                "label_ar": "غاليسيان",
                "label_fr": "Gallicien"
            },
            {
                "value": 29563,
                "label": "Fontes",
                "label_ar": "الخطوط",
                "label_fr": "Fontes"
            },
            {
                "value": 29564,
                "label": "Sardan",
                "label_ar": "سردان",
                "label_fr": "Sardan"
            },
            {
                "value": 29565,
                "label": "Caveirac",
                "label_ar": "كافيراك",
                "label_fr": "Caveirac"
            },
            {
                "value": 29566,
                "label": "Sauzet",
                "label_ar": "سوزيت",
                "label_fr": "Sauzet"
            },
            {
                "value": 29567,
                "label": "Caunes-Minervois",
                "label_ar": "Caunes-Minervois",
                "label_fr": "Caunes-Minervois"
            },
            {
                "value": 29568,
                "label": "Villemolaque",
                "label_ar": "فيلمولاك",
                "label_fr": "Villemolaque"
            },
            {
                "value": 29569,
                "label": "Lamorville",
                "label_ar": "لامورفيل",
                "label_fr": "Lamorville"
            },
            {
                "value": 29570,
                "label": "Fresnes-au-Mont",
                "label_ar": "فرينيس أو مونت",
                "label_fr": "Fresnes-au-Mont"
            },
            {
                "value": 29571,
                "label": "Marche-Allouarde",
                "label_ar": "Marche-Allouarde",
                "label_fr": "Marche-Allouarde"
            },
            {
                "value": 29572,
                "label": "Brie",
                "label_ar": "جبن أبيض طري",
                "label_fr": "Brie"
            },
            {
                "value": 29573,
                "label": "La Nerthe",
                "label_ar": "لا نيرثي",
                "label_fr": "La Nerthe"
            },
            {
                "value": 29574,
                "label": "Ludon-Medoc",
                "label_ar": "لودون ميدوك",
                "label_fr": "Ludon-Médoc"
            },
            {
                "value": 29575,
                "label": "Ecole",
                "label_ar": "مدرسة",
                "label_fr": "Ecole"
            },
            {
                "value": 29576,
                "label": "Esches",
                "label_ar": "Esches",
                "label_fr": "Esches"
            },
            {
                "value": 29577,
                "label": "Berthecourt",
                "label_ar": "بيرثكورت",
                "label_fr": "Berthecourt"
            },
            {
                "value": 29578,
                "label": "Charonville",
                "label_ar": "شارونفيل",
                "label_fr": "Charonville"
            },
            {
                "value": 29579,
                "label": "Catenay",
                "label_ar": "كاتيناي",
                "label_fr": "Catenay"
            },
            {
                "value": 29580,
                "label": "Barbery",
                "label_ar": "حلاق",
                "label_fr": "Barbier"
            },
            {
                "value": 29581,
                "label": "Charolles",
                "label_ar": "شارول",
                "label_fr": "Charolles"
            },
            {
                "value": 29582,
                "label": "La Neuville-du-Bosc",
                "label_ar": "لا نوفيل دو بوس",
                "label_fr": "La Neuville-du-Bosc"
            },
            {
                "value": 29583,
                "label": "Tillieres-sur-Avre",
                "label_ar": "Tillieres-sur-Avre",
                "label_fr": "Tillières-sur-Avre"
            },
            {
                "value": 29584,
                "label": "Auvergny",
                "label_ar": "أوفيرني",
                "label_fr": "Auvergny"
            },
            {
                "value": 29585,
                "label": "Vouneuil-sous-Biard",
                "label_ar": "Vouneuil-sous-Biard",
                "label_fr": "Vouneuil-sous-Biard"
            },
            {
                "value": 29586,
                "label": "Amailloux",
                "label_ar": "Amailloux",
                "label_fr": "Amailloux"
            },
            {
                "value": 29587,
                "label": "Pont-l'Abbe-d'Arnoult",
                "label_ar": "Pont-l'Abbe-d'Arnoult",
                "label_fr": "Pont-l'Abbe-d'Arnoult"
            },
            {
                "value": 29588,
                "label": "Castillonnes",
                "label_ar": "كاستيلون",
                "label_fr": "Castillonnes"
            },
            {
                "value": 29589,
                "label": "Alairac",
                "label_ar": "الايراك",
                "label_fr": "Alairac"
            },
            {
                "value": 29590,
                "label": "Monsegur",
                "label_ar": "مونسيجور",
                "label_fr": "Monsegur"
            },
            {
                "value": 29591,
                "label": "Saint-Caprais-de-Bordeaux",
                "label_ar": "سان كابريس دي بوردو",
                "label_fr": "Saint-Caprais-de-Bordeaux"
            },
            {
                "value": 29592,
                "label": "Nailloux",
                "label_ar": "نايلوكس",
                "label_fr": "Nailloux"
            },
            {
                "value": 29593,
                "label": "Bicqueley",
                "label_ar": "بيكيلي",
                "label_fr": "Bicqueley"
            },
            {
                "value": 29594,
                "label": "Montbonnot-Saint-Martin",
                "label_ar": "مونبونو سانت مارتن",
                "label_fr": "Montbonnot-Saint-Martin"
            },
            {
                "value": 29595,
                "label": "Le Versoud",
                "label_ar": "لو فيرسود",
                "label_fr": "Le Versoud"
            },
            {
                "value": 29596,
                "label": "Carpiquet",
                "label_ar": "كاربيكت",
                "label_fr": "Carpiquet"
            },
            {
                "value": 29597,
                "label": "Cormelles-le-Royal",
                "label_ar": "كورميليس لو رويال",
                "label_fr": "Cormelles-le-Royal"
            },
            {
                "value": 29598,
                "label": "Villiers-sous-Grez",
                "label_ar": "فيلير سو جريز",
                "label_fr": "Villiers-sous-Grez"
            },
            {
                "value": 29599,
                "label": "Theoule-sur-Mer",
                "label_ar": "تيول سور مير",
                "label_fr": "Théoule-sur-Mer"
            },
            {
                "value": 29600,
                "label": "Real",
                "label_ar": "حقيقة",
                "label_fr": "Réel"
            },
            {
                "value": 29601,
                "label": "Saint-Gence",
                "label_ar": "سانت جينس",
                "label_fr": "Saint-Gence"
            },
            {
                "value": 29602,
                "label": "Cravent",
                "label_ar": "شغف",
                "label_fr": "Cravent"
            },
            {
                "value": 29603,
                "label": "Cernay-la-Ville",
                "label_ar": "سيرناي لا فيل",
                "label_fr": "Cernay-la-Ville"
            },
            {
                "value": 29604,
                "label": "Vauhallan",
                "label_ar": "فوهالان",
                "label_fr": "Vauhallan"
            },
            {
                "value": 29605,
                "label": "Saint-Germain-de-la-Grange",
                "label_ar": "سان جيرمان دي لا جرانج",
                "label_fr": "Saint-Germain-de-la-Grange"
            },
            {
                "value": 29606,
                "label": "Wissous",
                "label_ar": "وسوس",
                "label_fr": "Wissous"
            },
            {
                "value": 29607,
                "label": "Les Mesnuls",
                "label_ar": "ليه ميسنولس",
                "label_fr": "Les Mesnuls"
            },
            {
                "value": 29608,
                "label": "Paray-Vieille-Poste",
                "label_ar": "باراي فييل بوست",
                "label_fr": "Paray-Vieille-Poste"
            },
            {
                "value": 29609,
                "label": "Buno-Bonnevaux",
                "label_ar": "بونو بونفو",
                "label_fr": "Buno-Bonnevaux"
            },
            {
                "value": 29610,
                "label": "Bresles",
                "label_ar": "بريسليس",
                "label_fr": "Bresles"
            },
            {
                "value": 29611,
                "label": "Saint-Maurice-Montcouronne",
                "label_ar": "سان موريس مونتكورون",
                "label_fr": "Saint-Maurice-Montcouronne"
            },
            {
                "value": 29612,
                "label": "Maisse",
                "label_ar": "ميس",
                "label_fr": "Maisse"
            },
            {
                "value": 29613,
                "label": "Asnieres-sur-Oise",
                "label_ar": "Asnieres-sur-Oise",
                "label_fr": "Asnières-sur-Oise"
            },
            {
                "value": 29614,
                "label": "Bruyeres-sur-Oise",
                "label_ar": "Bruyeres-sur-Oise",
                "label_fr": "Bruyères-sur-Oise"
            },
            {
                "value": 29615,
                "label": "Chevry-en-Sereine",
                "label_ar": "شيفري أون سيرين",
                "label_fr": "Chevry-en-Sereine"
            },
            {
                "value": 29616,
                "label": "Neufmoutiers-en-Brie",
                "label_ar": "نيوفموتيرز أون بري",
                "label_fr": "Neufmoutiers-en-Brie"
            },
            {
                "value": 29617,
                "label": "Engins",
                "label_ar": "محركات",
                "label_fr": "Les moteurs"
            },
            {
                "value": 29618,
                "label": "La Buisse",
                "label_ar": "لا بويز",
                "label_fr": "La Buisse"
            },
            {
                "value": 29619,
                "label": "Bourget",
                "label_ar": "بورجيه",
                "label_fr": "Bourget"
            },
            {
                "value": 29620,
                "label": "Apprieu",
                "label_ar": "أبريو",
                "label_fr": "Apprieu"
            },
            {
                "value": 29621,
                "label": "Corenc",
                "label_ar": "كورينك",
                "label_fr": "Corenc"
            },
            {
                "value": 29622,
                "label": "Sinard",
                "label_ar": "سينارد",
                "label_fr": "Sinard"
            },
            {
                "value": 29623,
                "label": "Saint-Jean-de-Maurienne",
                "label_ar": "سان جان دي موريان",
                "label_fr": "Saint-Jean-de-Maurienne"
            },
            {
                "value": 29624,
                "label": "Brie-et-Angonnes",
                "label_ar": "بري وآخرون أنغون",
                "label_fr": "Brie-et-Angonnes"
            },
            {
                "value": 29625,
                "label": "Beauvoisin",
                "label_ar": "بوفوازين",
                "label_fr": "Beauvoisin"
            },
            {
                "value": 29626,
                "label": "Gilly-sur-Isere",
                "label_ar": "جيلي سور إيسير",
                "label_fr": "Gilly-sur-Isère"
            },
            {
                "value": 29627,
                "label": "Jarrier",
                "label_ar": "جرير",
                "label_fr": "Jarrier"
            },
            {
                "value": 29628,
                "label": "Roybon",
                "label_ar": "رويبون",
                "label_fr": "Roybon"
            },
            {
                "value": 29629,
                "label": "Lans-en-Vercors",
                "label_ar": "لانس أون فيركورز",
                "label_fr": "Lans-en-Vercors"
            },
            {
                "value": 29630,
                "label": "Les Rives",
                "label_ar": "ليه ريفز",
                "label_fr": "Les Rives"
            },
            {
                "value": 29631,
                "label": "Montcel",
                "label_ar": "مونتسيل",
                "label_fr": "Montcel"
            },
            {
                "value": 29632,
                "label": "Bassens",
                "label_ar": "باسنز",
                "label_fr": "Bassens"
            },
            {
                "value": 29633,
                "label": "Brignoud",
                "label_ar": "برينود",
                "label_fr": "Brignoud"
            },
            {
                "value": 29634,
                "label": "Modane",
                "label_ar": "مودان",
                "label_fr": "Modane"
            },
            {
                "value": 29635,
                "label": "Huez",
                "label_ar": "هوز",
                "label_fr": "Huez"
            },
            {
                "value": 29636,
                "label": "Saint-Laurent-en-Beaumont",
                "label_ar": "سان لوران إن بومون",
                "label_fr": "Saint-Laurent-en-Beaumont"
            },
            {
                "value": 29637,
                "label": "Bevenais",
                "label_ar": "بيفينيس",
                "label_fr": "Bevenais"
            },
            {
                "value": 29638,
                "label": "Saint-Vital",
                "label_ar": "سانت فيتال",
                "label_fr": "Saint-Vital"
            },
            {
                "value": 29639,
                "label": "Les Marches",
                "label_ar": "ليه مارشيز",
                "label_fr": "Les Marches"
            },
            {
                "value": 29640,
                "label": "Clelles",
                "label_ar": "كليل",
                "label_fr": "Clelles"
            },
            {
                "value": 29641,
                "label": "Bernin",
                "label_ar": "برنين",
                "label_fr": "Bernin"
            },
            {
                "value": 29642,
                "label": "Theys",
                "label_ar": "هم",
                "label_fr": "Theys"
            },
            {
                "value": 29643,
                "label": "Saint-Etienne-de-Cuines",
                "label_ar": "سانت إتيان دو كوين",
                "label_fr": "Saint-Étienne-de-Cuines"
            },
            {
                "value": 29644,
                "label": "Villard-Saint-Christophe",
                "label_ar": "فيلارد سانت كريستوف",
                "label_fr": "Villard-Saint-Christophe"
            },
            {
                "value": 29645,
                "label": "Le Chatelard",
                "label_ar": "لو شاتيلارد",
                "label_fr": "Le Châtelard"
            },
            {
                "value": 29646,
                "label": "Biviers",
                "label_ar": "بيفيرس",
                "label_fr": "Biviers"
            },
            {
                "value": 29647,
                "label": "Macot-la-Plagne",
                "label_ar": "ماكوت لا بلاني",
                "label_fr": "Macot-la-Plagne"
            },
            {
                "value": 29648,
                "label": "La Porte",
                "label_ar": "لا بورت",
                "label_fr": "La Porte"
            },
            {
                "value": 29649,
                "label": "Rognaix",
                "label_ar": "روجنياكس",
                "label_fr": "Rognaix"
            },
            {
                "value": 29650,
                "label": "Chamoux-sur-Gelon",
                "label_ar": "شاموكس سور جيلون",
                "label_fr": "Chamoux-sur-Gelon"
            },
            {
                "value": 29651,
                "label": "Pugieu",
                "label_ar": "بوجيو",
                "label_fr": "Pugieu"
            },
            {
                "value": 29652,
                "label": "Saint-Appolinard",
                "label_ar": "سانت ابولينارد",
                "label_fr": "Saint-Appolinard"
            },
            {
                "value": 29653,
                "label": "Veurey-Voroize",
                "label_ar": "Veurey-Voroize",
                "label_fr": "Veurey-Voroize"
            },
            {
                "value": 29654,
                "label": "Montlhery",
                "label_ar": "مونتليري",
                "label_fr": "Montlhery"
            },
            {
                "value": 29655,
                "label": "Saint-Vrain",
                "label_ar": "سان فراين",
                "label_fr": "Saint-Vrain"
            },
            {
                "value": 29656,
                "label": "Janville-sur-Juine",
                "label_ar": "جانفيل سور جوين",
                "label_fr": "Janville-sur-Juine"
            },
            {
                "value": 29657,
                "label": "La Garenne-Colombes",
                "label_ar": "لا جارين كولومبس",
                "label_fr": "La Garenne-Colombes"
            },
            {
                "value": 29658,
                "label": "Montcourt-Fromonville",
                "label_ar": "مونتكورت فرومونفيل",
                "label_fr": "Montcourt-Fromonville"
            },
            {
                "value": 29659,
                "label": "Flins-sur-Seine",
                "label_ar": "فلين سور سين",
                "label_fr": "Flins-sur-Seine"
            },
            {
                "value": 29660,
                "label": "Bazoches-sur-Guyonne",
                "label_ar": "بازوش سور جويون",
                "label_fr": "Bazoches-sur-Guyonne"
            },
            {
                "value": 29661,
                "label": "Survilliers",
                "label_ar": "Survilliers",
                "label_fr": "Survilliers"
            },
            {
                "value": 29662,
                "label": "Fay-les-Nemours",
                "label_ar": "فاي ليه نمور",
                "label_fr": "Fay-les-Nemours"
            },
            {
                "value": 29663,
                "label": "Neuville-sur-Oise",
                "label_ar": "نيوفيل سور وايز",
                "label_fr": "Neuville-sur-Oise"
            },
            {
                "value": 29664,
                "label": "Sonchamp",
                "label_ar": "سونشامب",
                "label_fr": "Sonchamp"
            },
            {
                "value": 29665,
                "label": "Estadens",
                "label_ar": "إستادنس",
                "label_fr": "Estadens"
            },
            {
                "value": 29666,
                "label": "Barinque",
                "label_ar": "بارينك",
                "label_fr": "Barinque"
            },
            {
                "value": 29667,
                "label": "Saint-Georges-du-Bois",
                "label_ar": "سان جورج دو بوا",
                "label_fr": "Saint-Georges-du-Bois"
            },
            {
                "value": 29668,
                "label": "Corbarieu",
                "label_ar": "كورباريو",
                "label_fr": "Corbarieu"
            },
            {
                "value": 29669,
                "label": "Poulan-Pouzols",
                "label_ar": "بولان بوزول",
                "label_fr": "Poulan-Pouzols"
            },
            {
                "value": 29670,
                "label": "Nicole",
                "label_ar": "نيكول",
                "label_fr": "Nicole"
            },
            {
                "value": 29671,
                "label": "Saint-Sylvestre-sur-Lot",
                "label_ar": "سان سيلفستر سور لوت",
                "label_fr": "Saint-Sylvestre-sur-Lot"
            },
            {
                "value": 29672,
                "label": "Casseneuil",
                "label_ar": "كاسينويل",
                "label_fr": "Casseneuil"
            },
            {
                "value": 29673,
                "label": "Cenac",
                "label_ar": "سيناك",
                "label_fr": "Cénac"
            },
            {
                "value": 29674,
                "label": "Coimeres",
                "label_ar": "كويمير",
                "label_fr": "Coimeres"
            },
            {
                "value": 29675,
                "label": "Le Buisson-de-Cadouin",
                "label_ar": "لو بويسون دي كادوين",
                "label_fr": "Le Buisson-de-Cadouin"
            },
            {
                "value": 29676,
                "label": "Le Pian-sur-Garonne",
                "label_ar": "لو بيان سور جارون",
                "label_fr": "Le Pian-sur-Garonne"
            },
            {
                "value": 29677,
                "label": "Cornille",
                "label_ar": "كورنيل",
                "label_fr": "Cornille"
            },
            {
                "value": 29678,
                "label": "Tabanac",
                "label_ar": "تاباناك",
                "label_fr": "Tabanac"
            },
            {
                "value": 29679,
                "label": "Teuillac",
                "label_ar": "تويلاك",
                "label_fr": "Teuillac"
            },
            {
                "value": 29680,
                "label": "Sainte-Bazeille",
                "label_ar": "سانت بازيل",
                "label_fr": "Sainte-Bazeille"
            },
            {
                "value": 29681,
                "label": "Jonzac",
                "label_ar": "جونزاك",
                "label_fr": "Jonzac"
            },
            {
                "value": 29682,
                "label": "Aillas",
                "label_ar": "ايلاس",
                "label_fr": "Aillas"
            },
            {
                "value": 29683,
                "label": "Saint-Sulpice-et-Cameyrac",
                "label_ar": "Saint-Sulpice-et-Cameyrac",
                "label_fr": "Saint-Sulpice-et-Cameyrac"
            },
            {
                "value": 29684,
                "label": "Roaillan",
                "label_ar": "رويلان",
                "label_fr": "Roaillan"
            },
            {
                "value": 29685,
                "label": "Riocaud",
                "label_ar": "ريوكود",
                "label_fr": "Riocaud"
            },
            {
                "value": 29686,
                "label": "Fauch",
                "label_ar": "الفوش",
                "label_fr": "Fauch"
            },
            {
                "value": 29687,
                "label": "Dourgne",
                "label_ar": "دورجن",
                "label_fr": "Dourgne"
            },
            {
                "value": 29688,
                "label": "Bouloc",
                "label_ar": "بولوك",
                "label_fr": "Bouloc"
            },
            {
                "value": 29689,
                "label": "Labastide-dArmagnac",
                "label_ar": "لاباستيد- دارماجناك",
                "label_fr": "Labastide-d'Armagnac"
            },
            {
                "value": 29690,
                "label": "Gimont",
                "label_ar": "جيمونت",
                "label_fr": "Gimont"
            },
            {
                "value": 29691,
                "label": "Bezac",
                "label_ar": "بيزاك",
                "label_fr": "Bezac"
            },
            {
                "value": 29692,
                "label": "Jazeneuil",
                "label_ar": "جازينويل",
                "label_fr": "Jazeneuil"
            },
            {
                "value": 29693,
                "label": "Pellegrue",
                "label_ar": "بيليجرو",
                "label_fr": "Pellegrue"
            },
            {
                "value": 29694,
                "label": "Boe",
                "label_ar": "بو",
                "label_fr": "Boe"
            },
            {
                "value": 29695,
                "label": "Lansac",
                "label_ar": "لانساك",
                "label_fr": "Lansac"
            },
            {
                "value": 29696,
                "label": "Puisseguin",
                "label_ar": "Puisseguin",
                "label_fr": "Puisseguin"
            },
            {
                "value": 29697,
                "label": "Saint-Vincent-de-Connezac",
                "label_ar": "سانت فنسنت دي كونيزاك",
                "label_fr": "Saint-Vincent-de-Connezac"
            },
            {
                "value": 29698,
                "label": "Paleyrac",
                "label_ar": "باليراك",
                "label_fr": "Paleyrac"
            },
            {
                "value": 29699,
                "label": "Les Artigues-de-Lussac",
                "label_ar": "ليه أرتيج دو لوساك",
                "label_fr": "Les Artigues-de-Lussac"
            },
            {
                "value": 29700,
                "label": "Castelnau-sur-Gupie",
                "label_ar": "كاستيلناو سور جوبي",
                "label_fr": "Castelnau-sur-Gupie"
            },
            {
                "value": 29701,
                "label": "Doulezon",
                "label_ar": "دوليزون",
                "label_fr": "Doulezon"
            },
            {
                "value": 29702,
                "label": "Martillac",
                "label_ar": "مارتيلاك",
                "label_fr": "Martillac"
            },
            {
                "value": 29703,
                "label": "Grignols",
                "label_ar": "Grignols",
                "label_fr": "Grignols"
            },
            {
                "value": 29704,
                "label": "Bruch",
                "label_ar": "بروخ",
                "label_fr": "Bruch"
            },
            {
                "value": 29705,
                "label": "Jonquerets-de-Livet",
                "label_ar": "جونكيريتس دي ليفيه",
                "label_fr": "Jonquerets-de-Livet"
            },
            {
                "value": 29706,
                "label": "Saint-Pierre-en-Val",
                "label_ar": "سان بيير إن فال",
                "label_fr": "Saint-Pierre-en-Val"
            },
            {
                "value": 29707,
                "label": "Cauville-sur-Mer",
                "label_ar": "كوفيل سور مير",
                "label_fr": "Cauville-sur-Mer"
            },
            {
                "value": 29708,
                "label": "Anceaumeville",
                "label_ar": "أنسيومفيل",
                "label_fr": "Anceaumeville"
            },
            {
                "value": 29709,
                "label": "Heuqueville",
                "label_ar": "هيكفيل",
                "label_fr": "Heuqueville"
            },
            {
                "value": 29710,
                "label": "Bois-Jerome-Saint-Ouen",
                "label_ar": "بوا جيروم سان اوين",
                "label_fr": "Bois-Jérôme-Saint-Ouen"
            },
            {
                "value": 29711,
                "label": "Gonfreville-lOrcher",
                "label_ar": "Gonfreville-lOrcher",
                "label_fr": "Gonfreville-lOrcher"
            },
            {
                "value": 29712,
                "label": "Saint-Ouen-de-Thouberville",
                "label_ar": "سان أوين دي توبرفيل",
                "label_fr": "Saint-Ouen-de-Thouberville"
            },
            {
                "value": 29713,
                "label": "Virville",
                "label_ar": "فيرفيل",
                "label_fr": "Virville"
            },
            {
                "value": 29714,
                "label": "Angiens",
                "label_ar": "أنجيينس",
                "label_fr": "Angiens"
            },
            {
                "value": 29715,
                "label": "Ouville-l'Abbaye",
                "label_ar": "أوفيل لاباي",
                "label_fr": "Ouville-l'Abbaye"
            },
            {
                "value": 29716,
                "label": "Ventes-Saint-Remy",
                "label_ar": "فينتس سانت ريمي",
                "label_fr": "Ventes-Saint-Rémy"
            },
            {
                "value": 29717,
                "label": "Carville-la-Folletiere",
                "label_ar": "كارفيل لا فوليتيير",
                "label_fr": "Carville-la-Folletiere"
            },
            {
                "value": 29718,
                "label": "Envermeu",
                "label_ar": "إنفيرمو",
                "label_fr": "Envermeu"
            },
            {
                "value": 29719,
                "label": "Val-de-la-Haye",
                "label_ar": "فال دي لا هاي",
                "label_fr": "Val-de-la-Haye"
            },
            {
                "value": 29720,
                "label": "Barc",
                "label_ar": "بارك",
                "label_fr": "Barc"
            },
            {
                "value": 29721,
                "label": "Caux",
                "label_ar": "كوكس",
                "label_fr": "Caux"
            },
            {
                "value": 29722,
                "label": "Tourville-la-Riviere",
                "label_ar": "تورفيل لا ريفيير",
                "label_fr": "Tourville-la-Rivière"
            },
            {
                "value": 29723,
                "label": "Le Plessis-Hebert",
                "label_ar": "لو بليسيس هيبيرت",
                "label_fr": "Le Plessis-Hébert"
            },
            {
                "value": 29724,
                "label": "Mesnil-Raoul",
                "label_ar": "ميسنيل راؤول",
                "label_fr": "Mesnil-Raoul"
            },
            {
                "value": 29725,
                "label": "Longueville-sur-Scie",
                "label_ar": "Longueville-sur-Scie",
                "label_fr": "Longueville-sur-Scie"
            },
            {
                "value": 29726,
                "label": "Ymare",
                "label_ar": "يماري",
                "label_fr": "Ymare"
            },
            {
                "value": 29727,
                "label": "Trouville-la-Haule",
                "label_ar": "تروفيل لا هول",
                "label_fr": "Trouville-la-Haule"
            },
            {
                "value": 29728,
                "label": "Saint-Nicolas-de-Bliquetuit",
                "label_ar": "سان نيكولا دي بليكويت",
                "label_fr": "Saint-Nicolas-de-Bliquetuit"
            },
            {
                "value": 29729,
                "label": "Yebleron",
                "label_ar": "يبلرون",
                "label_fr": "Yebleron"
            },
            {
                "value": 29730,
                "label": "Sigy-en-Bray",
                "label_ar": "سيجي أون براي",
                "label_fr": "Sigy-en-Bray"
            },
            {
                "value": 29731,
                "label": "Rolleville",
                "label_ar": "رولفيل",
                "label_fr": "Rolleville"
            },
            {
                "value": 29732,
                "label": "Saint-Arnoult",
                "label_ar": "سانت أرنولت",
                "label_fr": "Saint-Arnoult"
            },
            {
                "value": 29733,
                "label": "Aviron",
                "label_ar": "أفيرون",
                "label_fr": "Aviron"
            },
            {
                "value": 29734,
                "label": "Riville",
                "label_ar": "ريفيل",
                "label_fr": "Riville"
            },
            {
                "value": 29735,
                "label": "Saint-Aquilin-de-Pacy",
                "label_ar": "سان أكويلين دي باسي",
                "label_fr": "Saint-Aquilin-de-Pacy"
            },
            {
                "value": 29736,
                "label": "Fontaine-sous-Preaux",
                "label_ar": "فونتين سو برو",
                "label_fr": "Fontaine-sous-Preaux"
            },
            {
                "value": 29737,
                "label": "Saint-Eustache-la-Foret",
                "label_ar": "سان يوستاش لا فوريه",
                "label_fr": "Saint-Eustache-la-Foret"
            },
            {
                "value": 29738,
                "label": "Menilles",
                "label_ar": "مينيل",
                "label_fr": "Menilles"
            },
            {
                "value": 29739,
                "label": "Martot",
                "label_ar": "مارتوت",
                "label_fr": "Martot"
            },
            {
                "value": 29740,
                "label": "Saint-Germain-Village",
                "label_ar": "قرية سان جيرمان",
                "label_fr": "Saint-Germain-Village"
            },
            {
                "value": 29741,
                "label": "Saint-Aubin-sur-Scie",
                "label_ar": "سان اوبين سور سي",
                "label_fr": "Saint-Aubin-sur-Scie"
            },
            {
                "value": 29742,
                "label": "Le Mesnil-Esnard",
                "label_ar": "لو ميسنيل-إسنارد",
                "label_fr": "Le Mesnil-Esnard"
            },
            {
                "value": 29743,
                "label": "Sainneville",
                "label_ar": "ساينفيل",
                "label_fr": "Sainneville"
            },
            {
                "value": 29744,
                "label": "Le Bosc-Roger-en-Roumois",
                "label_ar": "لو بوس روجر أون روموا",
                "label_fr": "Le Bosc-Roger-en-Roumois"
            },
            {
                "value": 29745,
                "label": "Saussay-la-Campagne",
                "label_ar": "Saussay-la-Campagne",
                "label_fr": "Saussay-la-Campagne"
            },
            {
                "value": 29746,
                "label": "Cuy-Saint-Fiacre",
                "label_ar": "كوي سان فياكري",
                "label_fr": "Cuy-Saint-Fiacre"
            },
            {
                "value": 29747,
                "label": "Saint-Martin-du-Manoir",
                "label_ar": "سان مارتن دو مانوار",
                "label_fr": "Saint-Martin-du-Manoir"
            },
            {
                "value": 29748,
                "label": "La Vacherie",
                "label_ar": "لا فاتشيري",
                "label_fr": "La Vacherie"
            },
            {
                "value": 29749,
                "label": "Sainte-Austreberthe",
                "label_ar": "سانت أوستربيرث",
                "label_fr": "Sainte-Austreberthe"
            },
            {
                "value": 29750,
                "label": "Ferrieres-Haut-Clocher",
                "label_ar": "Ferrieres-Haut-Clocher",
                "label_fr": "Ferrières-Haut-Clocher"
            },
            {
                "value": 29751,
                "label": "Brachy",
                "label_ar": "شجاع",
                "label_fr": "Brachy"
            },
            {
                "value": 29752,
                "label": "Valliquerville",
                "label_ar": "فاليكرفيل",
                "label_fr": "Valliquerville"
            },
            {
                "value": 29753,
                "label": "Ecretteville-les-Baons",
                "label_ar": "إكريتفيل ليه باونس",
                "label_fr": "Ecretteville-les-Baons"
            },
            {
                "value": 29754,
                "label": "Hattenville",
                "label_ar": "هاتنفيل",
                "label_fr": "Hattenville"
            },
            {
                "value": 29755,
                "label": "Chauvincourt-Provemont",
                "label_ar": "شوفينكور بروفيمونت",
                "label_fr": "Chauvincourt-Provemont"
            },
            {
                "value": 29756,
                "label": "Montaure",
                "label_ar": "مونتوري",
                "label_fr": "Montaure"
            },
            {
                "value": 29757,
                "label": "Sancourt",
                "label_ar": "سانكورت",
                "label_fr": "Sancourt"
            },
            {
                "value": 29758,
                "label": "Sacquenville",
                "label_ar": "ساكوينفيل",
                "label_fr": "Sacquenville"
            },
            {
                "value": 29759,
                "label": "Mesangueville",
                "label_ar": "ميسينجفيل",
                "label_fr": "Mesangueville"
            },
            {
                "value": 29760,
                "label": "Saint-Jean-le-Thomas",
                "label_ar": "سان جان لو توماس",
                "label_fr": "Saint-Jean-le-Thomas"
            },
            {
                "value": 29761,
                "label": "Le Mas-d'Azil",
                "label_ar": "لو ماس دازيل",
                "label_fr": "Le Mas-d'Azil"
            },
            {
                "value": 29762,
                "label": "Saint-Jean-du-Falga",
                "label_ar": "سان جان دو فالجا",
                "label_fr": "Saint-Jean-du-Falga"
            },
            {
                "value": 29763,
                "label": "Villeneuve-d'Olmes",
                "label_ar": "فيلنوف دولمس",
                "label_fr": "Villeneuve-d'Olmes"
            },
            {
                "value": 29764,
                "label": "Francheleins",
                "label_ar": "الامتياز",
                "label_fr": "Francheleins"
            },
            {
                "value": 29765,
                "label": "Gournay-sur-Marne",
                "label_ar": "جورناي سور مارن",
                "label_fr": "Gournay-sur-Marne"
            },
            {
                "value": 29766,
                "label": "Villes-sur-Auzon",
                "label_ar": "فيليس سور أوزون",
                "label_fr": "Villes-sur-Auzon"
            },
            {
                "value": 29767,
                "label": "Marly",
                "label_ar": "مارلي",
                "label_fr": "Marneux"
            },
            {
                "value": 29768,
                "label": "Lutterbach",
                "label_ar": "لوترباخ",
                "label_fr": "Lutterbach"
            },
            {
                "value": 29769,
                "label": "Plesnois",
                "label_ar": "Plesnois",
                "label_fr": "Plesnois"
            },
            {
                "value": 29770,
                "label": "Norroy-le-Veneur",
                "label_ar": "نورروي لو فينور",
                "label_fr": "Norroy-le-Veneur"
            },
            {
                "value": 29771,
                "label": "Saint-Pierre-la-Garenne",
                "label_ar": "سان بيير لا غارين",
                "label_fr": "Saint-Pierre-la-Garenne"
            },
            {
                "value": 29772,
                "label": "Templemars",
                "label_ar": "تمبلمارز",
                "label_fr": "Templiers"
            },
            {
                "value": 29773,
                "label": "Bouxieres-aux-Chenes",
                "label_ar": "Bouxieres-aux-Chenes",
                "label_fr": "Bouxières-aux-Chênes"
            },
            {
                "value": 29774,
                "label": "Bures-sur-Yvette",
                "label_ar": "بوريس سور إيفيت",
                "label_fr": "Bures-sur-Yvette"
            },
            {
                "value": 29775,
                "label": "Notre-Dame-d'Oe",
                "label_ar": "نوتردام دو",
                "label_fr": "Notre-Dame-d'Oe"
            },
            {
                "value": 29776,
                "label": "Mundolsheim",
                "label_ar": "موندولشيم",
                "label_fr": "Mundolsheim"
            },
            {
                "value": 29777,
                "label": "Damville",
                "label_ar": "دامفيل",
                "label_fr": "Damville"
            },
            {
                "value": 29778,
                "label": "Toufflers",
                "label_ar": "Toufflers",
                "label_fr": "Toufflers"
            },
            {
                "value": 29779,
                "label": "Follainville-Dennemont",
                "label_ar": "فولينفيل دينيمونت",
                "label_fr": "Follainville-Dennemont"
            },
            {
                "value": 29780,
                "label": "Magnanville",
                "label_ar": "ماجنانفيل",
                "label_fr": "Magnanville"
            },
            {
                "value": 29781,
                "label": "Duppigheim",
                "label_ar": "دوبيجهايم",
                "label_fr": "Duppigheim"
            },
            {
                "value": 29782,
                "label": "Brieres-les-Scelles",
                "label_ar": "برير ليس سكل",
                "label_fr": "Brieres-les-Scelles"
            },
            {
                "value": 29783,
                "label": "Pulnoy",
                "label_ar": "بولني",
                "label_fr": "Pulnoy"
            },
            {
                "value": 29784,
                "label": "Grosrouvre",
                "label_ar": "جروسروفري",
                "label_fr": "Grosrouvre"
            },
            {
                "value": 29785,
                "label": "Bruyeres-le-Chatel",
                "label_ar": "برويريس لو شاتيل",
                "label_fr": "Bruyères-le-Châtel"
            },
            {
                "value": 29786,
                "label": "Cadaujac",
                "label_ar": "كادوجاك",
                "label_fr": "Cadaujac"
            },
            {
                "value": 29787,
                "label": "Champ-sur-Drac",
                "label_ar": "تشامب سور دراك",
                "label_fr": "Champ-sur-Drac"
            },
            {
                "value": 29788,
                "label": "Venon",
                "label_ar": "فينون",
                "label_fr": "Venon"
            },
            {
                "value": 29789,
                "label": "Breteniere",
                "label_ar": "بريتنيير",
                "label_fr": "Bretenière"
            },
            {
                "value": 29790,
                "label": "Saint-Martin-sur-le-Pre",
                "label_ar": "سان مارتن سور لو بري",
                "label_fr": "Saint-Martin-sur-le-Pré"
            },
            {
                "value": 29791,
                "label": "Reitwiller",
                "label_ar": "ريتويلر",
                "label_fr": "Reitwiller"
            },
            {
                "value": 29792,
                "label": "Saint-Contest",
                "label_ar": "مسابقة القديس",
                "label_fr": "Saint-Contest"
            },
            {
                "value": 29793,
                "label": "Moisselles",
                "label_ar": "Moisselles",
                "label_fr": "Moisselles"
            },
            {
                "value": 29794,
                "label": "Oberhoffen-sur-Moder",
                "label_ar": "أوبرهوفن سور مودر",
                "label_fr": "Oberhoffen-sur-Moder"
            },
            {
                "value": 29795,
                "label": "Steinbourg",
                "label_ar": "شتاينبورغ",
                "label_fr": "Steinbourg"
            },
            {
                "value": 29796,
                "label": "Offendorf",
                "label_ar": "أوفيندورف",
                "label_fr": "Offendorf"
            },
            {
                "value": 29797,
                "label": "Cormontreuil",
                "label_ar": "كورمونترويل",
                "label_fr": "Cormontreuil"
            },
            {
                "value": 29798,
                "label": "Reventin-Vaugris",
                "label_ar": "ريفنتين فوجريس",
                "label_fr": "Reventin-Vaugris"
            },
            {
                "value": 29799,
                "label": "Nogent-sur-Seine",
                "label_ar": "نوجينت سور سين",
                "label_fr": "Nogent-sur-Seine"
            },
            {
                "value": 29800,
                "label": "Saint-Pierre-du-Perray",
                "label_ar": "سان بيير دو بيري",
                "label_fr": "Saint-Pierre-du-Perray"
            },
            {
                "value": 29801,
                "label": "Condrieu",
                "label_ar": "كوندريو",
                "label_fr": "Condrieu"
            },
            {
                "value": 29802,
                "label": "Schnersheim",
                "label_ar": "شينرشيم",
                "label_fr": "Schnersheim"
            },
            {
                "value": 29803,
                "label": "Rosheim",
                "label_ar": "روشيم",
                "label_fr": "Rosheim"
            },
            {
                "value": 29804,
                "label": "Habsheim",
                "label_ar": "هابشيم",
                "label_fr": "Habsheim"
            },
            {
                "value": 29805,
                "label": "Pontavert",
                "label_ar": "بونتافيرت",
                "label_fr": "Pontavert"
            },
            {
                "value": 29806,
                "label": "Saclay",
                "label_ar": "ساكلاي",
                "label_fr": "Saclay"
            },
            {
                "value": 29807,
                "label": "Monthieux",
                "label_ar": "مونثيو",
                "label_fr": "Monthieux"
            },
            {
                "value": 29808,
                "label": "Beynost",
                "label_ar": "بينوست",
                "label_fr": "Beynost"
            },
            {
                "value": 29809,
                "label": "Montanay",
                "label_ar": "مونتاني",
                "label_fr": "Montanay"
            },
            {
                "value": 29810,
                "label": "Quincieu",
                "label_ar": "كوينسيو",
                "label_fr": "Quincieu"
            },
            {
                "value": 29811,
                "label": "Saint-Lye-la-Foret",
                "label_ar": "سان لاي لا فوريه",
                "label_fr": "Saint-Lye-la-Foret"
            },
            {
                "value": 29812,
                "label": "Mours",
                "label_ar": "مرحى",
                "label_fr": "Mours"
            },
            {
                "value": 29813,
                "label": "Puiseux-en-France",
                "label_ar": "Puiseux-en-France",
                "label_fr": "Puiseux-en-France"
            },
            {
                "value": 29814,
                "label": "Parmain",
                "label_ar": "بارمين",
                "label_fr": "Parmain"
            },
            {
                "value": 29815,
                "label": "Butry-sur-Oise",
                "label_ar": "بوتري سور واز",
                "label_fr": "Butry-sur-Oise"
            },
            {
                "value": 29816,
                "label": "Lormaison",
                "label_ar": "لورمايسون",
                "label_fr": "Lormaison"
            },
            {
                "value": 29817,
                "label": "Marcy-l'Etoile",
                "label_ar": "مارسي ليتوال",
                "label_fr": "Marcy-l'Étoile"
            },
            {
                "value": 29818,
                "label": "Charbonnieres-les-Bains",
                "label_ar": "Charbonnieres-les-Bains",
                "label_fr": "Charbonnières-les-Bains"
            },
            {
                "value": 29819,
                "label": "Gouvieux",
                "label_ar": "جوفيو",
                "label_fr": "Gouvieux"
            },
            {
                "value": 29820,
                "label": "Saint-Aubin-de-Medoc",
                "label_ar": "سان اوبين دي ميدوك",
                "label_fr": "Saint-Aubin-de-Médoc"
            },
            {
                "value": 29821,
                "label": "Saint-Sulpice-de-Faleyrens",
                "label_ar": "سان سولبيس دي فاليرين",
                "label_fr": "Saint-Sulpice-de-Faleyrens"
            },
            {
                "value": 29822,
                "label": "Notre-Dame-de-Mesage",
                "label_ar": "نوتردام دي ميسج",
                "label_fr": "Notre-Dame-de-Mesage"
            },
            {
                "value": 29823,
                "label": "Montmelian",
                "label_ar": "مونتميليان",
                "label_fr": "Montmélian"
            },
            {
                "value": 29824,
                "label": "Apremont",
                "label_ar": "أبريمونت",
                "label_fr": "Apremont"
            },
            {
                "value": 29825,
                "label": "Gamaches",
                "label_ar": "جاماشيس",
                "label_fr": "Gamaches"
            },
            {
                "value": 29826,
                "label": "Saint-Remy-les-Chevreuse",
                "label_ar": "سان ريمي ليه شيفروز",
                "label_fr": "Saint-Rémy-les-Chevreuse"
            },
            {
                "value": 29827,
                "label": "Saint-Antonin-sur-Bayon",
                "label_ar": "سان أنطونين سور بايون",
                "label_fr": "Saint-Antonin-sur-Bayon"
            },
            {
                "value": 29828,
                "label": "Auzeville-Tolosane",
                "label_ar": "اوزفيل تولوسان",
                "label_fr": "Auzeville-Tolosane"
            },
            {
                "value": 29829,
                "label": "Pontpoint",
                "label_ar": "بونت بوينت",
                "label_fr": "Pontpoint"
            },
            {
                "value": 29830,
                "label": "Houdemont",
                "label_ar": "هوديمونت",
                "label_fr": "Houdemont"
            },
            {
                "value": 29831,
                "label": "La Penne-sur-Huveaune",
                "label_ar": "لا بين سور هوفيون",
                "label_fr": "La Penne-sur-Huveaune"
            },
            {
                "value": 29832,
                "label": "Latour",
                "label_ar": "لاتور",
                "label_fr": "Latour"
            },
            {
                "value": 29833,
                "label": "Saint-Cyr-au-Mont-d'Or",
                "label_ar": "Saint-Cyr-au-Mont-d'Or",
                "label_fr": "Saint-Cyr-au-Mont-d'Or"
            },
            {
                "value": 29834,
                "label": "Magenta",
                "label_ar": "أرجواني",
                "label_fr": "Magenta"
            },
            {
                "value": 29835,
                "label": "Emmerin",
                "label_ar": "إيمرين",
                "label_fr": "Emmerin"
            },
            {
                "value": 29836,
                "label": "Jouars-Pontchartrain",
                "label_ar": "جوار بونتشارترين",
                "label_fr": "Jouars-Pontchartrain"
            },
            {
                "value": 29837,
                "label": "Mareuil-les-Meaux",
                "label_ar": "ماريويل لي مو",
                "label_fr": "Mareuil-les-Meaux"
            },
            {
                "value": 29838,
                "label": "La Vallee de Dormelles",
                "label_ar": "لا فالي دي دورميليس",
                "label_fr": "La Vallée de Dormelles"
            },
            {
                "value": 29839,
                "label": "Grazac",
                "label_ar": "جرازاك",
                "label_fr": "Grazac"
            },
            {
                "value": 29840,
                "label": "Saint-Germain-sur-Morin",
                "label_ar": "سان جيرمان سور مورين",
                "label_fr": "Saint-Germain-sur-Morin"
            },
            {
                "value": 29841,
                "label": "Sonnaz",
                "label_ar": "سوناز",
                "label_fr": "Sonnaz"
            },
            {
                "value": 29842,
                "label": "Lattainville",
                "label_ar": "لاتينفيل",
                "label_fr": "Lattainville"
            },
            {
                "value": 29843,
                "label": "Piscop",
                "label_ar": "بيسكوب",
                "label_fr": "Piscop"
            },
            {
                "value": 29844,
                "label": "Maillane",
                "label_ar": "ميلين",
                "label_fr": "Maillane"
            },
            {
                "value": 29845,
                "label": "Buxerolles",
                "label_ar": "بوكسيرولس",
                "label_fr": "Buxerolles"
            },
            {
                "value": 29846,
                "label": "Aumur",
                "label_ar": "أومور",
                "label_fr": "Aumur"
            },
            {
                "value": 29847,
                "label": "Leves",
                "label_ar": "ليفز",
                "label_fr": "Leves"
            },
            {
                "value": 29848,
                "label": "Le Thillay",
                "label_ar": "لو تيلاي",
                "label_fr": "Le Thillay"
            },
            {
                "value": 29849,
                "label": "Lapeyrouse-Fossat",
                "label_ar": "لابيروس فوسات",
                "label_fr": "Lapeyrouse-Fossat"
            },
            {
                "value": 29850,
                "label": "Saint-Michel",
                "label_ar": "سان ميشيل",
                "label_fr": "Saint-Michel"
            },
            {
                "value": 29851,
                "label": "Le Houlme",
                "label_ar": "لو هولم",
                "label_fr": "Le Houlme"
            },
            {
                "value": 29852,
                "label": "Pomponne",
                "label_ar": "بومبون",
                "label_fr": "Pomponne"
            },
            {
                "value": 29853,
                "label": "Sollies-Toucas",
                "label_ar": "سوليس توكاس",
                "label_fr": "Sollies-Toucas"
            },
            {
                "value": 29854,
                "label": "Diges",
                "label_ar": "ديجز",
                "label_fr": "Diges"
            },
            {
                "value": 29855,
                "label": "Saint-Didier-au-Mont-d'Or",
                "label_ar": "سان ديدييه أو مونت دور",
                "label_fr": "Saint-Didier-au-Mont-d'Or"
            },
            {
                "value": 29856,
                "label": "Finhan",
                "label_ar": "فينهان",
                "label_fr": "Finhan"
            },
            {
                "value": 29857,
                "label": "Ingersheim",
                "label_ar": "انجرسهايم",
                "label_fr": "Ingersheim"
            },
            {
                "value": 29858,
                "label": "Valpuiseaux",
                "label_ar": "فالبويسو",
                "label_fr": "Valpuiseaux"
            },
            {
                "value": 29859,
                "label": "Le Mesnil-en-Thelle",
                "label_ar": "لو ميسنيل أون ثيل",
                "label_fr": "Le Mesnil-en-Thelle"
            },
            {
                "value": 29860,
                "label": "Westhouse",
                "label_ar": "ويستهاوس",
                "label_fr": "Westhouse"
            },
            {
                "value": 29861,
                "label": "Chatenois",
                "label_ar": "شاتينوا",
                "label_fr": "Chatenois"
            },
            {
                "value": 29862,
                "label": "Golfe-Juan",
                "label_ar": "جولف خوان",
                "label_fr": "Golfe-Juan"
            },
            {
                "value": 29863,
                "label": "Nicorps",
                "label_ar": "نيكوربس",
                "label_fr": "Nicorps"
            },
            {
                "value": 29864,
                "label": "Gratot",
                "label_ar": "جراتوت",
                "label_fr": "Gratot"
            },
            {
                "value": 29865,
                "label": "Charleval",
                "label_ar": "شارليفال",
                "label_fr": "Charleval"
            },
            {
                "value": 29866,
                "label": "Ille-sur-Tet",
                "label_ar": "إيل سور تيت",
                "label_fr": "Ille-sur-Tet"
            },
            {
                "value": 29867,
                "label": "Vernet-les-Bains",
                "label_ar": "فيرنيه ليه با",
                "label_fr": "Vernet-les-Bains"
            },
            {
                "value": 29868,
                "label": "Uzos",
                "label_ar": "أوزوس",
                "label_fr": "Uzos"
            },
            {
                "value": 29869,
                "label": "Pugny-Chatenod",
                "label_ar": "بوجني شاتينود",
                "label_fr": "Pugny-Chatenod"
            },
            {
                "value": 29870,
                "label": "Sierentz",
                "label_ar": "سيرينتز",
                "label_fr": "Sierentz"
            },
            {
                "value": 29871,
                "label": "La Glacerie",
                "label_ar": "لا جلاسيري",
                "label_fr": "La Glacerie"
            },
            {
                "value": 29872,
                "label": "Crespieres",
                "label_ar": "Crespieres",
                "label_fr": "Crespières"
            },
            {
                "value": 29873,
                "label": "Thiverval-Grignon",
                "label_ar": "Thiverval-Grignon",
                "label_fr": "Thiverval-Grignon"
            },
            {
                "value": 29874,
                "label": "Bolsenheim",
                "label_ar": "بولسينهايم",
                "label_fr": "Bolsenheim"
            },
            {
                "value": 29875,
                "label": "Ambres",
                "label_ar": "أمبريس",
                "label_fr": "Ambres"
            },
            {
                "value": 29876,
                "label": "Perigny",
                "label_ar": "الزمر",
                "label_fr": "Perigny"
            },
            {
                "value": 29877,
                "label": "Villeneuve-les-Sablons",
                "label_ar": "فيلنوف ليه سابلون",
                "label_fr": "Villeneuve-les-Sablons"
            },
            {
                "value": 29878,
                "label": "Corbeil-Cerf",
                "label_ar": "كوربيل سيرف",
                "label_fr": "Corbeil-Cerf"
            },
            {
                "value": 29879,
                "label": "Rogerville",
                "label_ar": "روجرفيل",
                "label_fr": "Rogerville"
            },
            {
                "value": 29880,
                "label": "Montbazon",
                "label_ar": "مونبازون",
                "label_fr": "Montbazon"
            },
            {
                "value": 29881,
                "label": "Lignieres-Chatelain",
                "label_ar": "Lignieres-Chatelain",
                "label_fr": "Lignières-Châtelain"
            },
            {
                "value": 29882,
                "label": "Essertaux",
                "label_ar": "إيسيرتاوكس",
                "label_fr": "Essertaux"
            },
            {
                "value": 29883,
                "label": "Chamborigaud",
                "label_ar": "شامبوريجود",
                "label_fr": "Chamborigaud"
            },
            {
                "value": 29884,
                "label": "Scionzier",
                "label_ar": "Scionzier",
                "label_fr": "Scionzier"
            },
            {
                "value": 29885,
                "label": "Marsac-en-Livradois",
                "label_ar": "مارساك أون ليفرادوا",
                "label_fr": "Marsac-en-Livradois"
            },
            {
                "value": 29886,
                "label": "Saint-Pierre-de-Varennes",
                "label_ar": "سان بيير دي فارين",
                "label_fr": "Saint-Pierre-de-Varennes"
            },
            {
                "value": 29887,
                "label": "Tignieu-Jameyzieu",
                "label_ar": "Tignieu-Jameyzieu",
                "label_fr": "Tignieu-Jameyzieu"
            },
            {
                "value": 29888,
                "label": "Mogneville",
                "label_ar": "موغنفيل",
                "label_fr": "Mogneville"
            },
            {
                "value": 29889,
                "label": "Laigneville",
                "label_ar": "لينفيل",
                "label_fr": "Laigneville"
            },
            {
                "value": 29890,
                "label": "Liancourt",
                "label_ar": "ليانكورت",
                "label_fr": "Liancourt"
            },
            {
                "value": 29891,
                "label": "Balagny-sur-Therain",
                "label_ar": "Balagny-sur-Therain",
                "label_fr": "Balagny-sur-Therain"
            },
            {
                "value": 29892,
                "label": "Amilly",
                "label_ar": "اميلي",
                "label_fr": "Amilly"
            },
            {
                "value": 29893,
                "label": "Pontarme",
                "label_ar": "بونتارم",
                "label_fr": "Pontarme"
            },
            {
                "value": 29894,
                "label": "Les Sauvages",
                "label_ar": "ليه سوفاج",
                "label_fr": "Les Sauvages"
            },
            {
                "value": 29895,
                "label": "Panissieres",
                "label_ar": "بانيسيريس",
                "label_fr": "Panissières"
            },
            {
                "value": 29896,
                "label": "Erbray",
                "label_ar": "Erbray",
                "label_fr": "Erbray"
            },
            {
                "value": 29897,
                "label": "Meaudre",
                "label_ar": "مودر",
                "label_fr": "Meaudre"
            },
            {
                "value": 29898,
                "label": "Praz-sur-Arly",
                "label_ar": "براز سور ارلي",
                "label_fr": "Praz-sur-Arly"
            },
            {
                "value": 29899,
                "label": "Valleres",
                "label_ar": "فاليريس",
                "label_fr": "Valleres"
            },
            {
                "value": 29900,
                "label": "Bouquetot",
                "label_ar": "بوكيه",
                "label_fr": "Bouquetot"
            },
            {
                "value": 29901,
                "label": "Bramans",
                "label_ar": "برامانس",
                "label_fr": "Bramans"
            },
            {
                "value": 29902,
                "label": "Saint-Gondran",
                "label_ar": "سان جوندران",
                "label_fr": "Saint-Gondran"
            },
            {
                "value": 29903,
                "label": "Mas-Saintes-Puelles",
                "label_ar": "ماس سانت بيليس",
                "label_fr": "Mas-Saintes-Puelles"
            },
            {
                "value": 29904,
                "label": "Gaillan-en-Medoc",
                "label_ar": "جيلان إن ميدوك",
                "label_fr": "Gaillan-en-Médoc"
            },
            {
                "value": 29905,
                "label": "Saint-Remimont",
                "label_ar": "سانت ريميمون",
                "label_fr": "Saint-Remimont"
            },
            {
                "value": 29906,
                "label": "Pernay",
                "label_ar": "بيرناي",
                "label_fr": "Pernay"
            },
            {
                "value": 29907,
                "label": "Bellegarde-en-Diois",
                "label_ar": "Bellegarde-en-Diois",
                "label_fr": "Bellegarde-en-Diois"
            },
            {
                "value": 29908,
                "label": "Les Aix-d'Angillon",
                "label_ar": "ليس إيكس دانجيلون",
                "label_fr": "Les Aix-d'Angillon"
            },
            {
                "value": 29909,
                "label": "Chatillon-sur-Indre",
                "label_ar": "شاتيلون سور إندر",
                "label_fr": "Châtillon-sur-Indre"
            },
            {
                "value": 29910,
                "label": "Molleges",
                "label_ar": "مول الكليات",
                "label_fr": "Molleges"
            },
            {
                "value": 29911,
                "label": "Cheroy",
                "label_ar": "شيروي",
                "label_fr": "Cheroy"
            },
            {
                "value": 29912,
                "label": "Lancrans",
                "label_ar": "لانكرانس",
                "label_fr": "Lancrans"
            },
            {
                "value": 29913,
                "label": "Madirac",
                "label_ar": "ماديراك",
                "label_fr": "Madirac"
            },
            {
                "value": 29914,
                "label": "Port-Sainte-Foy-et-Ponchapt",
                "label_ar": "Port-Sainte-Foy-et-Ponchapt",
                "label_fr": "Port-Sainte-Foy-et-Ponchapt"
            },
            {
                "value": 29915,
                "label": "Les Ormes",
                "label_ar": "ليه أورمس",
                "label_fr": "Les Ormes"
            },
            {
                "value": 29916,
                "label": "Les Assions",
                "label_ar": "ليه Assions",
                "label_fr": "Les Assions"
            },
            {
                "value": 29917,
                "label": "Saint-Martin-d'Heuille",
                "label_ar": "سان مارتن ديويل",
                "label_fr": "Saint-Martin-d'Heuille"
            },
            {
                "value": 29918,
                "label": "Villard",
                "label_ar": "فيلارد",
                "label_fr": "Villard"
            },
            {
                "value": 29919,
                "label": "Gouaix",
                "label_ar": "جويكس",
                "label_fr": "Gouaix"
            },
            {
                "value": 29920,
                "label": "Megrit",
                "label_ar": "ميجريت",
                "label_fr": "Megrit"
            },
            {
                "value": 29921,
                "label": "Moisville",
                "label_ar": "مويسفيل",
                "label_fr": "Moisville"
            },
            {
                "value": 29922,
                "label": "Saint-Hilarion",
                "label_ar": "سان هيلاريون",
                "label_fr": "Saint-Hilarion"
            },
            {
                "value": 29923,
                "label": "Barjon",
                "label_ar": "بارجون",
                "label_fr": "Barjon"
            },
            {
                "value": 29924,
                "label": "Cusy",
                "label_ar": "كوزي",
                "label_fr": "Cusy"
            },
            {
                "value": 29925,
                "label": "Brissac",
                "label_ar": "بريساك",
                "label_fr": "Brissac"
            },
            {
                "value": 29926,
                "label": "La Roche",
                "label_ar": "لاروش",
                "label_fr": "La Roche"
            },
            {
                "value": 29927,
                "label": "Plaimpied-Givaudins",
                "label_ar": "Plaimpied-Givaudins",
                "label_fr": "Plaimpied-Givaudins"
            },
            {
                "value": 29928,
                "label": "Charron",
                "label_ar": "شارون",
                "label_fr": "Charron"
            },
            {
                "value": 29929,
                "label": "Epineuil-le-Fleuriel",
                "label_ar": "Epineuil-le-Fleuriel",
                "label_fr": "Épineuil-le-Fleuriel"
            },
            {
                "value": 29930,
                "label": "Labastide-Beauvoir",
                "label_ar": "لاباستيد بوفوار",
                "label_fr": "Labastide-Beauvoir"
            },
            {
                "value": 29931,
                "label": "Rians",
                "label_ar": "ريان",
                "label_fr": "Rians"
            },
            {
                "value": 29932,
                "label": "Sainte-Solange",
                "label_ar": "سانت سولانج",
                "label_fr": "Sainte-Solange"
            },
            {
                "value": 29933,
                "label": "Azay-sur-Indre",
                "label_ar": "Azay-sur-Indre",
                "label_fr": "Azay-sur-Indre"
            },
            {
                "value": 29934,
                "label": "Bellenaves",
                "label_ar": "بيلناف",
                "label_fr": "Bellenaves"
            },
            {
                "value": 29935,
                "label": "Saint-Amand-de-Coly",
                "label_ar": "سان اماند دي كولي",
                "label_fr": "Saint-Amand-de-Coly"
            },
            {
                "value": 29936,
                "label": "Chuelles",
                "label_ar": "كويل",
                "label_fr": "Chuelles"
            },
            {
                "value": 29937,
                "label": "Coyriere",
                "label_ar": "كويرير",
                "label_fr": "Coyrière"
            },
            {
                "value": 29938,
                "label": "Saint-Denis-dAnjou",
                "label_ar": "سان دوني دانجو",
                "label_fr": "Saint-Denis-d'Anjou"
            },
            {
                "value": 29939,
                "label": "Bremontier-Merval",
                "label_ar": "بريمونتييه ميرفال",
                "label_fr": "Bremontier-Merval"
            },
            {
                "value": 29940,
                "label": "Carresse-Cassaber",
                "label_ar": "كارريس كاسابر",
                "label_fr": "Carresse-Cassaber"
            },
            {
                "value": 29941,
                "label": "Bonneville",
                "label_ar": "بونفيل",
                "label_fr": "Bonneville"
            },
            {
                "value": 29942,
                "label": "Preignan",
                "label_ar": "بريجنان",
                "label_fr": "Preignan"
            },
            {
                "value": 29943,
                "label": "Grand-Auverne",
                "label_ar": "جراند أوفيرن",
                "label_fr": "Grand-Auverne"
            },
            {
                "value": 29944,
                "label": "Soulaire-et-Bourg",
                "label_ar": "Soulaire-et-Bourg",
                "label_fr": "Soulaire-et-Bourg"
            },
            {
                "value": 29945,
                "label": "Bazicourt",
                "label_ar": "بازيكورت",
                "label_fr": "Bazicourt"
            },
            {
                "value": 29946,
                "label": "Saint-Mard",
                "label_ar": "سان مارد",
                "label_fr": "Saint-Mard"
            },
            {
                "value": 29947,
                "label": "Quiers",
                "label_ar": "Quiers",
                "label_fr": "Quiers"
            },
            {
                "value": 29948,
                "label": "Chamigny",
                "label_ar": "شاميني",
                "label_fr": "Chamigny"
            },
            {
                "value": 29949,
                "label": "Saint-Fiacre",
                "label_ar": "القديس فياكري",
                "label_fr": "Saint-Fiacre"
            },
            {
                "value": 29950,
                "label": "Ferrieres-en-Brie",
                "label_ar": "Ferrieres-en-Brie",
                "label_fr": "Ferrières-en-Brie"
            },
            {
                "value": 29951,
                "label": "Longueville",
                "label_ar": "لونجفيل",
                "label_fr": "Longueville"
            },
            {
                "value": 29952,
                "label": "Ozouer-le-Voulgis",
                "label_ar": "Ozouer-le-Voulgis",
                "label_fr": "Ozouer-le-Voulgis"
            },
            {
                "value": 29953,
                "label": "Egreville",
                "label_ar": "Egreville",
                "label_fr": "Egreville"
            },
            {
                "value": 29954,
                "label": "Lissy",
                "label_ar": "ليسي",
                "label_fr": "Lissy"
            },
            {
                "value": 29955,
                "label": "Amponville",
                "label_ar": "امبونفيل",
                "label_fr": "Amponville"
            },
            {
                "value": 29956,
                "label": "Saint-Ouen-sur-Morin",
                "label_ar": "سان أوين سور مورين",
                "label_fr": "Saint-Ouen-sur-Morin"
            },
            {
                "value": 29957,
                "label": "Salins",
                "label_ar": "ملاحات",
                "label_fr": "Salins"
            },
            {
                "value": 29958,
                "label": "Beaumont-les-Nonains",
                "label_ar": "بومونت ليه نون",
                "label_fr": "Beaumont-les-Nonains"
            },
            {
                "value": 29959,
                "label": "Annet-sur-Marne",
                "label_ar": "أنيت سور مارن",
                "label_fr": "Annet-sur-Marne"
            },
            {
                "value": 29960,
                "label": "Cerneux",
                "label_ar": "سيرنو",
                "label_fr": "Cerneux"
            },
            {
                "value": 29961,
                "label": "Couilly-Pont-aux-Dames",
                "label_ar": "كويلي بونت أو دامز",
                "label_fr": "Couilly-Pont-aux-Dames"
            },
            {
                "value": 29962,
                "label": "Saint-Martin-en-Biere",
                "label_ar": "سان مارتن أون بيير",
                "label_fr": "Saint-Martin-en-Bière"
            },
            {
                "value": 29963,
                "label": "Fromonville",
                "label_ar": "فرومونفيل",
                "label_fr": "Fromonville"
            },
            {
                "value": 29964,
                "label": "Coulommes",
                "label_ar": "كولوم",
                "label_fr": "Coulommes"
            },
            {
                "value": 29965,
                "label": "Jossigny",
                "label_ar": "جوسيني",
                "label_fr": "Jossigny"
            },
            {
                "value": 29966,
                "label": "Thomery",
                "label_ar": "تومري",
                "label_fr": "Thomery"
            },
            {
                "value": 29967,
                "label": "Chalmaison",
                "label_ar": "تشالميزون",
                "label_fr": "Chalmaison"
            },
            {
                "value": 29968,
                "label": "Charny",
                "label_ar": "تشارني",
                "label_fr": "Charny"
            },
            {
                "value": 29969,
                "label": "Noisy-sur-Oise",
                "label_ar": "صاخبة سور واز",
                "label_fr": "Noisy-sur-Oise"
            },
            {
                "value": 29970,
                "label": "Sollies-Ville",
                "label_ar": "سوليس فيل",
                "label_fr": "Sollies-Ville"
            },
            {
                "value": 29971,
                "label": "Meounes-les-Montrieux",
                "label_ar": "Meounes-les-Montrieux",
                "label_fr": "Meounes-les-Montrieux"
            },
            {
                "value": 29972,
                "label": "Besse-sur-Issole",
                "label_ar": "بيس سور إيسول",
                "label_fr": "Besse-sur-Issole"
            },
            {
                "value": 29973,
                "label": "Neoules",
                "label_ar": "نيولز",
                "label_fr": "Néoules"
            },
            {
                "value": 29974,
                "label": "Fayence",
                "label_ar": "فاين",
                "label_fr": "Fayence"
            },
            {
                "value": 29975,
                "label": "Forcalqueiret",
                "label_ar": "Forcalqueiret",
                "label_fr": "Forcalqueiret"
            },
            {
                "value": 29976,
                "label": "Le Val",
                "label_ar": "لو فال",
                "label_fr": "Le Val"
            },
            {
                "value": 29977,
                "label": "Ginasservis",
                "label_ar": "جيناسيرفيس",
                "label_fr": "Ginasservis"
            },
            {
                "value": 29978,
                "label": "Puget-Ville",
                "label_ar": "بوجيت فيل",
                "label_fr": "Puget-Ville"
            },
            {
                "value": 29979,
                "label": "Seillons-Source-d'Argens",
                "label_ar": "Seillons-Source-d'Argens",
                "label_fr": "Seillons-Source-d'Argens"
            },
            {
                "value": 29980,
                "label": "Evenos",
                "label_ar": "إيفينوس",
                "label_fr": "Evenos"
            },
            {
                "value": 29981,
                "label": "Tavernes",
                "label_ar": "الحانات",
                "label_fr": "Tavernes"
            },
            {
                "value": 29982,
                "label": "Foret-la-Folie",
                "label_ar": "فوريت لا فولي",
                "label_fr": "Foret-la-Folie"
            },
            {
                "value": 29983,
                "label": "Rougiers",
                "label_ar": "Rougiers",
                "label_fr": "Rougiers"
            },
            {
                "value": 29984,
                "label": "La Houssaye-en-Brie",
                "label_ar": "لا هوساي أون بري",
                "label_fr": "La Houssaye-en-Brie"
            },
            {
                "value": 29985,
                "label": "Sagy",
                "label_ar": "ساغي",
                "label_fr": "Sagy"
            },
            {
                "value": 29986,
                "label": "Saint-Augustin",
                "label_ar": "سانت أوغسطين",
                "label_fr": "Saint-Augustin"
            },
            {
                "value": 29987,
                "label": "Chessy",
                "label_ar": "شيسي",
                "label_fr": "Chessy"
            },
            {
                "value": 29988,
                "label": "Germigny-l'Eveque",
                "label_ar": "Germigny-l'Eveque",
                "label_fr": "Germigny-l'Évêque"
            },
            {
                "value": 29989,
                "label": "Fresnes-sur-Marne",
                "label_ar": "فرينيس سور مارن",
                "label_fr": "Fresnes-sur-Marne"
            },
            {
                "value": 29990,
                "label": "Boissise-le-Roi",
                "label_ar": "Boissise-le-Roi",
                "label_fr": "Boissise-le-Roi"
            },
            {
                "value": 29991,
                "label": "Plomb",
                "label_ar": "بلومب",
                "label_fr": "Plomb"
            },
            {
                "value": 29992,
                "label": "Beuvillers",
                "label_ar": "بيوفيلرز",
                "label_fr": "Beuvillers"
            },
            {
                "value": 29993,
                "label": "Montchamp",
                "label_ar": "مونتشامب",
                "label_fr": "Montchamp"
            },
            {
                "value": 29994,
                "label": "Saint-Germain-Langot",
                "label_ar": "سان جيرمان لانجوت",
                "label_fr": "Saint-Germain-Langot"
            },
            {
                "value": 29995,
                "label": "Campagnolles",
                "label_ar": "كامبانيول",
                "label_fr": "Campagnolles"
            },
            {
                "value": 29996,
                "label": "Sacey",
                "label_ar": "ساسي",
                "label_fr": "Sacey"
            },
            {
                "value": 29997,
                "label": "La Foret",
                "label_ar": "لا فوريت",
                "label_fr": "La Foret"
            },
            {
                "value": 29998,
                "label": "Tirepied",
                "label_ar": "متعب",
                "label_fr": "Tirepied"
            },
            {
                "value": 29999,
                "label": "May-sur-Orne",
                "label_ar": "ماي سور أورني",
                "label_fr": "May-sur-Orne"
            },
            {
                "value": 30000,
                "label": "Pennedepie",
                "label_ar": "بينيديبي",
                "label_fr": "Pennedepie"
            },
            {
                "value": 30001,
                "label": "Saint-Denis-le-Gast",
                "label_ar": "سان دينيس لو جاست",
                "label_fr": "Saint-Denis-le-Gast"
            },
            {
                "value": 30002,
                "label": "Hudimesnil",
                "label_ar": "Hudimesnil",
                "label_fr": "Hudimesnil"
            },
            {
                "value": 30003,
                "label": "Coudray-Rabut",
                "label_ar": "كودراي رابوت",
                "label_fr": "Coudray-Rabut"
            },
            {
                "value": 30004,
                "label": "Harcourt",
                "label_ar": "هاركورت",
                "label_fr": "Harcourt"
            },
            {
                "value": 30005,
                "label": "Ranville",
                "label_ar": "رانفيل",
                "label_fr": "Ranville"
            },
            {
                "value": 30006,
                "label": "Conde-sur-Sarthe",
                "label_ar": "كوندي سور سارث",
                "label_fr": "Condé-sur-Sarthe"
            },
            {
                "value": 30007,
                "label": "Nehou",
                "label_ar": "نيهو",
                "label_fr": "Nehou"
            },
            {
                "value": 30008,
                "label": "Sourdeval",
                "label_ar": "سورديفال",
                "label_fr": "Sourdeval"
            },
            {
                "value": 30009,
                "label": "La Ferriere-Bochard",
                "label_ar": "لا فيرير بوشارد",
                "label_fr": "La Ferrière-Bochard"
            },
            {
                "value": 30010,
                "label": "Bougy",
                "label_ar": "بوجي",
                "label_fr": "Bougy"
            },
            {
                "value": 30011,
                "label": "Sainte-Honorine-du-Fay",
                "label_ar": "سانت أونورين دو فاي",
                "label_fr": "Sainte-Honorine-du-Fay"
            },
            {
                "value": 30012,
                "label": "La Motte-Fouquet",
                "label_ar": "لا موت فوكيه",
                "label_fr": "La Motte-Fouquet"
            },
            {
                "value": 30013,
                "label": "Gonneville-sur-Mer",
                "label_ar": "جونفيل سور مير",
                "label_fr": "Gonneville-sur-Mer"
            },
            {
                "value": 30014,
                "label": "Janville",
                "label_ar": "جانفيل",
                "label_fr": "Janville"
            },
            {
                "value": 30015,
                "label": "Billy",
                "label_ar": "بيلي",
                "label_fr": "Gamelle"
            },
            {
                "value": 30016,
                "label": "Precorbin",
                "label_ar": "بركوربين",
                "label_fr": "Précorbine"
            },
            {
                "value": 30017,
                "label": "Le Dezert",
                "label_ar": "لو دزرت",
                "label_fr": "Le Dezert"
            },
            {
                "value": 30018,
                "label": "Cahagnes",
                "label_ar": "كاهاغنس",
                "label_fr": "Cahagnes"
            },
            {
                "value": 30019,
                "label": "Pair-et-Grandrupt",
                "label_ar": "زوج وآخرون Grandrupt",
                "label_fr": "Pair-et-Grandrupt"
            },
            {
                "value": 30020,
                "label": "Aunay-les-Bois",
                "label_ar": "أوني ليه بوا",
                "label_fr": "Aunay-les-Bois"
            },
            {
                "value": 30021,
                "label": "Creully",
                "label_ar": "كريولي",
                "label_fr": "Creully"
            },
            {
                "value": 30022,
                "label": "Trelly",
                "label_ar": "تريلي",
                "label_fr": "Trelly"
            },
            {
                "value": 30023,
                "label": "Fontenai-les-Louvets",
                "label_ar": "Fontenai-les-Louvets",
                "label_fr": "Fontenai-les-Louvets"
            },
            {
                "value": 30024,
                "label": "Sainte-Marie-Laumont",
                "label_ar": "سانت ماري لومونت",
                "label_fr": "Sainte-Marie-Laumont"
            },
            {
                "value": 30025,
                "label": "Le Merlerault",
                "label_ar": "لو ميرليرو",
                "label_fr": "Le Merlerault"
            },
            {
                "value": 30026,
                "label": "Ussy",
                "label_ar": "أوسي",
                "label_fr": "Ussy"
            },
            {
                "value": 30027,
                "label": "Viessoix",
                "label_ar": "فيسويكس",
                "label_fr": "Viessoix"
            },
            {
                "value": 30028,
                "label": "Angoville-sur-Ay",
                "label_ar": "أنجوفيل سور آي",
                "label_fr": "Angoville-sur-Ay"
            },
            {
                "value": 30029,
                "label": "Cintheaux",
                "label_ar": "سينثوكس",
                "label_fr": "Cintheaux"
            },
            {
                "value": 30030,
                "label": "Raids",
                "label_ar": "المداهمات",
                "label_fr": "Raids"
            },
            {
                "value": 30031,
                "label": "Ancinnes",
                "label_ar": "Ancinnes",
                "label_fr": "Ancinnes"
            },
            {
                "value": 30032,
                "label": "Samson",
                "label_ar": "شمشون",
                "label_fr": "Samson"
            },
            {
                "value": 30033,
                "label": "Frenes",
                "label_ar": "فرين",
                "label_fr": "Frenes"
            },
            {
                "value": 30034,
                "label": "Rosel",
                "label_ar": "روسيل",
                "label_fr": "Rosel"
            },
            {
                "value": 30035,
                "label": "Le Mesnil-Ozenne",
                "label_ar": "لو ميسنيل أوزين",
                "label_fr": "Le Mesnil-Ozenne"
            },
            {
                "value": 30036,
                "label": "Tourville-en-Auge",
                "label_ar": "تورفيل أون أوجي",
                "label_fr": "Tourville-en-Auge"
            },
            {
                "value": 30037,
                "label": "Neuilly-le-Bisson",
                "label_ar": "نويي لو بيسون",
                "label_fr": "Neuilly-le-Bisson"
            },
            {
                "value": 30038,
                "label": "Livet-sur-Authou",
                "label_ar": "Livet-sur-Authou",
                "label_fr": "Livet-sur-Authou"
            },
            {
                "value": 30039,
                "label": "Morteaux-Couliboeuf",
                "label_ar": "مورتو كوليبوف",
                "label_fr": "Morteaux-Couliboeuf"
            },
            {
                "value": 30040,
                "label": "Montfarville",
                "label_ar": "مونتفارفيل",
                "label_fr": "Montfarville"
            },
            {
                "value": 30041,
                "label": "Saint-Pierre-Tarentaine",
                "label_ar": "سان بيير تارنتين",
                "label_fr": "Saint-Pierre-Tarentaine"
            },
            {
                "value": 30042,
                "label": "Saint-Paul-du-Vernay",
                "label_ar": "سان بول دو فيرناي",
                "label_fr": "Saint-Paul-du-Vernay"
            },
            {
                "value": 30043,
                "label": "Sainte-Marie-du-Mont",
                "label_ar": "سانت ماري دو مونت",
                "label_fr": "Sainte-Marie-du-Mont"
            },
            {
                "value": 30044,
                "label": "Avenay",
                "label_ar": "أفيني",
                "label_fr": "Avenay"
            },
            {
                "value": 30045,
                "label": "Saint-Quentin-les-Chardonnets",
                "label_ar": "سانت كوينتين ليه شاردونيتس",
                "label_fr": "Saint-Quentin-les-Chardonnets"
            },
            {
                "value": 30046,
                "label": "Castilly",
                "label_ar": "كاستيلي",
                "label_fr": "Castilly"
            },
            {
                "value": 30047,
                "label": "Saint-Sylvain",
                "label_ar": "سان سيلفان",
                "label_fr": "Saint-Sylvain"
            },
            {
                "value": 30048,
                "label": "Saint-Michel-des-Andaines",
                "label_ar": "سان ميشيل دي أندين",
                "label_fr": "Saint-Michel-des-Andaines"
            },
            {
                "value": 30049,
                "label": "Le Sap",
                "label_ar": "لو ساب",
                "label_fr": "Le Sap"
            },
            {
                "value": 30050,
                "label": "Fierville-Bray",
                "label_ar": "فيرفيل براي",
                "label_fr": "Fierville-Bray"
            },
            {
                "value": 30051,
                "label": "Messei",
                "label_ar": "ميسي",
                "label_fr": "Messei"
            },
            {
                "value": 30052,
                "label": "Menil-Hermei",
                "label_ar": "مينيل هيرمي",
                "label_fr": "Ménil-Hermei"
            },
            {
                "value": 30053,
                "label": "Durcet",
                "label_ar": "دورسيت",
                "label_fr": "Durcet"
            },
            {
                "value": 30054,
                "label": "Ceauce",
                "label_ar": "صوص",
                "label_fr": "Ceauce"
            },
            {
                "value": 30055,
                "label": "Putot-en-Bessin",
                "label_ar": "بوتوت أون بيسين",
                "label_fr": "Putot-en-Bessin"
            },
            {
                "value": 30056,
                "label": "Artolsheim",
                "label_ar": "أرتولشيم",
                "label_fr": "Artolsheim"
            },
            {
                "value": 30057,
                "label": "Baldenheim",
                "label_ar": "بالدنهايم",
                "label_fr": "Baldenheim"
            },
            {
                "value": 30058,
                "label": "Marckolsheim",
                "label_ar": "ماركولشيم",
                "label_fr": "Marckolsheim"
            },
            {
                "value": 30059,
                "label": "Aspach-le-Haut",
                "label_ar": "أسباش لو أوت",
                "label_fr": "Aspach-le-Haut"
            },
            {
                "value": 30060,
                "label": "Obenheim",
                "label_ar": "أوبنهايم",
                "label_fr": "Obenheim"
            },
            {
                "value": 30061,
                "label": "Achenheim",
                "label_ar": "Achenheim",
                "label_fr": "Achenheim"
            },
            {
                "value": 30062,
                "label": "Hangenbieten",
                "label_ar": "Hangenbieten",
                "label_fr": "Hangenbieten"
            },
            {
                "value": 30063,
                "label": "La Couture-Boussey",
                "label_ar": "لا كوتور بوسي",
                "label_fr": "La Couture-Boussey"
            },
            {
                "value": 30064,
                "label": "Cormoranche-sur-Saone",
                "label_ar": "كورمورانش سور سون",
                "label_fr": "Cormoranche-sur-Saône"
            },
            {
                "value": 30065,
                "label": "Puyloubier",
                "label_ar": "بويلوبيير",
                "label_fr": "Puyloubier"
            },
            {
                "value": 30066,
                "label": "Charly-sur-Marne",
                "label_ar": "تشارلي سور مارن",
                "label_fr": "Charly-sur-Marne"
            },
            {
                "value": 30067,
                "label": "Feurs",
                "label_ar": "العداء",
                "label_fr": "Feurs"
            },
            {
                "value": 30068,
                "label": "Angervilliers",
                "label_ar": "Angervilliers",
                "label_fr": "Angervilliers"
            },
            {
                "value": 30069,
                "label": "Ville-la-Grand",
                "label_ar": "فيل لا جراند",
                "label_fr": "Ville-la-Grand"
            },
            {
                "value": 30070,
                "label": "Ligny-en-Cambresis",
                "label_ar": "Ligny-en-Cambresis",
                "label_fr": "Ligny-en-Cambresis"
            },
            {
                "value": 30071,
                "label": "Naours",
                "label_ar": "ناورس",
                "label_fr": "Naours"
            },
            {
                "value": 30072,
                "label": "Saint-Andre-de-Rosans",
                "label_ar": "سان أندريه دي روزان",
                "label_fr": "Saint-André-de-Rosans"
            },
            {
                "value": 30073,
                "label": "Odos",
                "label_ar": "أودوس",
                "label_fr": "Odos"
            },
            {
                "value": 30074,
                "label": "Peronne",
                "label_ar": "بيرون",
                "label_fr": "Péronne"
            },
            {
                "value": 30075,
                "label": "Crespin",
                "label_ar": "كريسبين",
                "label_fr": "Crespin"
            },
            {
                "value": 30076,
                "label": "Vert-Saint-Denis",
                "label_ar": "فيرت سانت دينيس",
                "label_fr": "Vert-Saint-Denis"
            },
            {
                "value": 30077,
                "label": "Isles-les-Villenoy",
                "label_ar": "جزر ليه فيلينوي",
                "label_fr": "Isles-les-Villenoy"
            },
            {
                "value": 30078,
                "label": "Signy-Signets",
                "label_ar": "Signy-Signets",
                "label_fr": "Signy-Signets"
            },
            {
                "value": 30079,
                "label": "Torcy",
                "label_ar": "تورسي",
                "label_fr": "Torcy"
            },
            {
                "value": 30080,
                "label": "Flavigny-sur-Moselle",
                "label_ar": "فلافيني سور موسيل",
                "label_fr": "Flavigny-sur-Moselle"
            },
            {
                "value": 30081,
                "label": "Marnaz",
                "label_ar": "مرناز",
                "label_fr": "Marnaz"
            },
            {
                "value": 30082,
                "label": "Vic-sur-Seille",
                "label_ar": "فيك-سور-سيل",
                "label_fr": "Vic-sur-Seille"
            },
            {
                "value": 30083,
                "label": "Trelissac",
                "label_ar": "تريليساك",
                "label_fr": "Trelissac"
            },
            {
                "value": 30084,
                "label": "Le Chateau Gaillard",
                "label_ar": "لو شاتو جيلارد",
                "label_fr": "Le Château Gaillard"
            },
            {
                "value": 30085,
                "label": "Belleu",
                "label_ar": "بيلو",
                "label_fr": "Belleu"
            },
            {
                "value": 30086,
                "label": "Bu",
                "label_ar": "بو",
                "label_fr": "Bu"
            },
            {
                "value": 30087,
                "label": "Nefiach",
                "label_ar": "Nefiach",
                "label_fr": "Nefiach"
            },
            {
                "value": 30088,
                "label": "Saint-Maximin",
                "label_ar": "سانت ماكسيمين",
                "label_fr": "Saint-Maximin"
            },
            {
                "value": 30089,
                "label": "Guerville",
                "label_ar": "جيرفيل",
                "label_fr": "Guerville"
            },
            {
                "value": 30090,
                "label": "Lapouyade",
                "label_ar": "لابويد",
                "label_fr": "Lapouyade"
            },
            {
                "value": 30091,
                "label": "La Poterie-Mathieu",
                "label_ar": "لا بوتيري ماتيو",
                "label_fr": "La Poterie-Mathieu"
            },
            {
                "value": 30092,
                "label": "Planches",
                "label_ar": "بلش",
                "label_fr": "Planches"
            },
            {
                "value": 30093,
                "label": "Classun",
                "label_ar": "كلاسون",
                "label_fr": "Classun"
            },
            {
                "value": 30094,
                "label": "Cavillargues",
                "label_ar": "كافيلارج",
                "label_fr": "Cavillargues"
            },
            {
                "value": 30095,
                "label": "Vaugrigneuse",
                "label_ar": "فوجرينيوز",
                "label_fr": "Vaugrigneuse"
            },
            {
                "value": 30096,
                "label": "Randan",
                "label_ar": "رندان",
                "label_fr": "Randan"
            },
            {
                "value": 30097,
                "label": "Escombres-et-le-Chesnois",
                "label_ar": "Escombres-et-le-Chesnois",
                "label_fr": "Escombres-et-le-Chesnois"
            },
            {
                "value": 30098,
                "label": "Ville-sur-Tourbe",
                "label_ar": "فيل سور تورب",
                "label_fr": "Ville-sur-Tourbe"
            },
            {
                "value": 30099,
                "label": "Tailly",
                "label_ar": "ذيل",
                "label_fr": "Tailly"
            },
            {
                "value": 30100,
                "label": "Sapogne-sur-Marche",
                "label_ar": "Sapogne-sur-Marche",
                "label_fr": "Sapogne-sur-Marche"
            },
            {
                "value": 30101,
                "label": "Blanzy-la-Salonnaise",
                "label_ar": "بلانزي لا سالونيز",
                "label_fr": "Blanzy-la-Salonnaise"
            },
            {
                "value": 30102,
                "label": "Torvilliers",
                "label_ar": "Torvilliers",
                "label_fr": "Torvilliers"
            },
            {
                "value": 30103,
                "label": "Caurel",
                "label_ar": "كوريل",
                "label_fr": "Caurel"
            },
            {
                "value": 30104,
                "label": "Vandy",
                "label_ar": "فاندي",
                "label_fr": "Vandy"
            },
            {
                "value": 30105,
                "label": "Wassy",
                "label_ar": "واسي",
                "label_fr": "Wassy"
            },
            {
                "value": 30106,
                "label": "Joncourt",
                "label_ar": "جونكورت",
                "label_fr": "Joncourt"
            },
            {
                "value": 30107,
                "label": "Conchy-les-Pots",
                "label_ar": "كونشي ليه بوت",
                "label_fr": "Conchy-les-Pots"
            },
            {
                "value": 30108,
                "label": "Quevauvillers",
                "label_ar": "كيفوفيلرس",
                "label_fr": "Quevauvillers"
            },
            {
                "value": 30109,
                "label": "Verneuil-en-Halatte",
                "label_ar": "Verneuil-en-Halatte",
                "label_fr": "Verneuil-en-Halatte"
            },
            {
                "value": 30110,
                "label": "Lieramont",
                "label_ar": "ليرامونت",
                "label_fr": "Lieramont"
            },
            {
                "value": 30111,
                "label": "Rieux",
                "label_ar": "ريو",
                "label_fr": "Rieux"
            },
            {
                "value": 30112,
                "label": "Saint-Maxent",
                "label_ar": "سانت ماكسينت",
                "label_fr": "Saint-Maxent"
            },
            {
                "value": 30113,
                "label": "Bregy",
                "label_ar": "بريجي",
                "label_fr": "Bregy"
            },
            {
                "value": 30114,
                "label": "Pasly",
                "label_ar": "باسلي",
                "label_fr": "Pasly"
            },
            {
                "value": 30115,
                "label": "Grand-Laviers",
                "label_ar": "غراند لافييرز",
                "label_fr": "Grand-Laviers"
            },
            {
                "value": 30116,
                "label": "Erquinvillers",
                "label_ar": "إركوينفيلرس",
                "label_fr": "Erquinvillers"
            },
            {
                "value": 30117,
                "label": "Rainneville",
                "label_ar": "رينفيل",
                "label_fr": "Rainneville"
            },
            {
                "value": 30118,
                "label": "Lieuvillers",
                "label_ar": "آلات التعبئة",
                "label_fr": "Lieuvillers"
            },
            {
                "value": 30119,
                "label": "Saint-Paul-de-Vezelin",
                "label_ar": "سان بول دي فيزيلين",
                "label_fr": "Saint-Paul-de-Vézelin"
            },
            {
                "value": 30120,
                "label": "Campigneulles-les-Petites",
                "label_ar": "كامبينول ليس بيتيت",
                "label_fr": "Campigneulles-les-Petites"
            },
            {
                "value": 30121,
                "label": "Coye-la-Foret",
                "label_ar": "كوي لا فوريه",
                "label_fr": "Coye-la-Foret"
            },
            {
                "value": 30122,
                "label": "Bailleul-le-Soc",
                "label_ar": "Bailleul-le-Soc",
                "label_fr": "Bailleul-le-Soc"
            },
            {
                "value": 30123,
                "label": "Estrees-sur-Noye",
                "label_ar": "إستريس سور نوي",
                "label_fr": "Estrees-sur-Noye"
            },
            {
                "value": 30124,
                "label": "Bussus-Bussuel",
                "label_ar": "بوسوس بوسويل",
                "label_fr": "Bussus-Bussuel"
            },
            {
                "value": 30125,
                "label": "Ons-en-Bray",
                "label_ar": "Ons-en-Bray",
                "label_fr": "Ons-en-Bray"
            },
            {
                "value": 30126,
                "label": "Pende",
                "label_ar": "بيندي",
                "label_fr": "Pende"
            },
            {
                "value": 30127,
                "label": "Barenton-Bugny",
                "label_ar": "بارنتون بوغني",
                "label_fr": "Barenton-Bugny"
            },
            {
                "value": 30128,
                "label": "Yaucourt-Bussus",
                "label_ar": "Yaucourt-Bussus",
                "label_fr": "Yaucourt-Bussus"
            },
            {
                "value": 30129,
                "label": "Saint-Maulvis",
                "label_ar": "سان مولفيس",
                "label_fr": "Saint-Maulvis"
            },
            {
                "value": 30130,
                "label": "Barzy-en-Thierache",
                "label_ar": "Barzy-en-Thierache",
                "label_fr": "Barzy-en-Thierache"
            },
            {
                "value": 30131,
                "label": "Isques",
                "label_ar": "إيسكيس",
                "label_fr": "Isques"
            },
            {
                "value": 30132,
                "label": "Berck-Plage",
                "label_ar": "بيرك بلاج",
                "label_fr": "Berck-Plage"
            },
            {
                "value": 30133,
                "label": "Neuville-Saint-Vaast",
                "label_ar": "نيوفيل سانت فاست",
                "label_fr": "Neuville-Saint-Vaast"
            },
            {
                "value": 30134,
                "label": "Villers-Plouich",
                "label_ar": "فيليرز بلويش",
                "label_fr": "Villers-Plouich"
            },
            {
                "value": 30135,
                "label": "Estrees",
                "label_ar": "إستريس",
                "label_fr": "Estrees"
            },
            {
                "value": 30136,
                "label": "Fillievres",
                "label_ar": "فيليفريس",
                "label_fr": "Fillièvres"
            },
            {
                "value": 30137,
                "label": "Souastre",
                "label_ar": "سواستر",
                "label_fr": "Souastre"
            },
            {
                "value": 30138,
                "label": "Ablainzevelle",
                "label_ar": "Ablainzevelle",
                "label_fr": "Ablainzevelle"
            },
            {
                "value": 30139,
                "label": "Bollezeele",
                "label_ar": "بوليزيل",
                "label_fr": "Bollezéele"
            },
            {
                "value": 30140,
                "label": "Eclaron-Braucourt-Sainte-Liviere",
                "label_ar": "Eclaron-Braucourt-Sainte-Liviere",
                "label_fr": "Eclaron-Braucourt-Sainte-Livière"
            },
            {
                "value": 30141,
                "label": "Mery-sur-Seine",
                "label_ar": "ميري سور سين",
                "label_fr": "Mery-sur-Seine"
            },
            {
                "value": 30142,
                "label": "Morvilliers",
                "label_ar": "مورفيلييه",
                "label_fr": "Morvilliers"
            },
            {
                "value": 30143,
                "label": "Lavannes",
                "label_ar": "لافانيس",
                "label_fr": "Lavannes"
            },
            {
                "value": 30144,
                "label": "Bossancourt",
                "label_ar": "بوسانكور",
                "label_fr": "Bossancourt"
            },
            {
                "value": 30145,
                "label": "Richebourg",
                "label_ar": "ريتشبورج",
                "label_fr": "Richebourg"
            },
            {
                "value": 30146,
                "label": "Rouvroy-sur-Marne",
                "label_ar": "روفروي سور مارن",
                "label_fr": "Rouvroy-sur-Marne"
            },
            {
                "value": 30147,
                "label": "Les Charmontois",
                "label_ar": "ليه شارمونتوا",
                "label_fr": "Les Charmontois"
            },
            {
                "value": 30148,
                "label": "Fontaine-en-Dormois",
                "label_ar": "فونتين إن دورموا",
                "label_fr": "Fontaine-en-Dormois"
            },
            {
                "value": 30149,
                "label": "Quilly",
                "label_ar": "كيلي",
                "label_fr": "Quilly"
            },
            {
                "value": 30150,
                "label": "Chepy",
                "label_ar": "تشيبي",
                "label_fr": "Chepy"
            },
            {
                "value": 30151,
                "label": "Bernard",
                "label_ar": "برنارد",
                "label_fr": "Bernard"
            },
            {
                "value": 30152,
                "label": "Avenay-Val-d'Or",
                "label_ar": "أفيناي فال دور",
                "label_fr": "Avenay-Val-d'Or"
            },
            {
                "value": 30153,
                "label": "Vaire-sous-Corbie",
                "label_ar": "Vaire-sous-Corbie",
                "label_fr": "Vaire-sous-Corbie"
            },
            {
                "value": 30154,
                "label": "Bonneuil-en-Valois",
                "label_ar": "Bonneuil-en-Valois",
                "label_fr": "Bonneuil-en-Valois"
            },
            {
                "value": 30155,
                "label": "Pierrepont-sur-Avre",
                "label_ar": "بييربونت سور افر",
                "label_fr": "Pierrepont-sur-Avre"
            },
            {
                "value": 30156,
                "label": "Nibas",
                "label_ar": "نيباس",
                "label_fr": "Nibas"
            },
            {
                "value": 30157,
                "label": "Dargnies",
                "label_ar": "دارجنيس",
                "label_fr": "Dargnies"
            },
            {
                "value": 30158,
                "label": "Poeuilly",
                "label_ar": "بويلي",
                "label_fr": "Poeuilly"
            },
            {
                "value": 30159,
                "label": "Bethencourt-sur-Mer",
                "label_ar": "Bethencourt-sur-Mer",
                "label_fr": "Bethencourt-sur-Mer"
            },
            {
                "value": 30160,
                "label": "Beautor",
                "label_ar": "بيوتور",
                "label_fr": "Beauteur"
            },
            {
                "value": 30161,
                "label": "Vineuil-Saint-Firmin",
                "label_ar": "كرم سانت فيرمين",
                "label_fr": "Vineuil-Saint-Firmin"
            },
            {
                "value": 30162,
                "label": "Neufvy-sur-Aronde",
                "label_ar": "نيوففي سور أروند",
                "label_fr": "Neufvy-sur-Aronde"
            },
            {
                "value": 30163,
                "label": "Brissy-Hamegicourt",
                "label_ar": "بريسي هاميجيكورت",
                "label_fr": "Brissy-Hamegicourt"
            },
            {
                "value": 30164,
                "label": "Ailette",
                "label_ar": "ايليت",
                "label_fr": "Ailette"
            },
            {
                "value": 30165,
                "label": "Pronville",
                "label_ar": "برونفيل",
                "label_fr": "Pronville"
            },
            {
                "value": 30166,
                "label": "Pontruet",
                "label_ar": "بونتروت",
                "label_fr": "Pontruet"
            },
            {
                "value": 30167,
                "label": "Davenescourt",
                "label_ar": "دافينسكورت",
                "label_fr": "Davenescourt"
            },
            {
                "value": 30168,
                "label": "Ailly-le-Haut-Clocher",
                "label_ar": "ايلي لو أوت كلوشر",
                "label_fr": "Ailly-le-Haut-Clocher"
            },
            {
                "value": 30169,
                "label": "Flers-sur-Noye",
                "label_ar": "فليرس سور نوي",
                "label_fr": "Flers-sur-Noye"
            },
            {
                "value": 30170,
                "label": "Contre",
                "label_ar": "كونتر",
                "label_fr": "Contre"
            },
            {
                "value": 30171,
                "label": "Fignieres",
                "label_ar": "فينيير",
                "label_fr": "Fignières"
            },
            {
                "value": 30172,
                "label": "Marest",
                "label_ar": "مارست",
                "label_fr": "Marest"
            },
            {
                "value": 30173,
                "label": "Montcornet",
                "label_ar": "مونكورنيه",
                "label_fr": "Montcornet"
            },
            {
                "value": 30174,
                "label": "Dury",
                "label_ar": "ديوري",
                "label_fr": "Dury"
            },
            {
                "value": 30175,
                "label": "Beaucamps-Ligny",
                "label_ar": "Beaucamps-Ligny",
                "label_fr": "Beaucamps-Ligny"
            },
            {
                "value": 30176,
                "label": "Radinghem",
                "label_ar": "رادينغيم",
                "label_fr": "Radinghem"
            },
            {
                "value": 30177,
                "label": "Farbus",
                "label_ar": "فاربوس",
                "label_fr": "Farbus"
            },
            {
                "value": 30178,
                "label": "Marcq-en-Ostrevent",
                "label_ar": "Marcq-en-Ostrevent",
                "label_fr": "Marcq-en-Ostrevent"
            },
            {
                "value": 30179,
                "label": "Montreuil-sur-Mer",
                "label_ar": "مونتروي سور مير",
                "label_fr": "Montreuil-sur-Mer"
            },
            {
                "value": 30180,
                "label": "Norrent-Fontes",
                "label_ar": "نورنت فونتس",
                "label_fr": "Norrent-Fontes"
            },
            {
                "value": 30181,
                "label": "Wail",
                "label_ar": "عويل",
                "label_fr": "Lamenter"
            },
            {
                "value": 30182,
                "label": "Carency",
                "label_ar": "كارنسي",
                "label_fr": "Carency"
            },
            {
                "value": 30183,
                "label": "Aynac",
                "label_ar": "ايناك",
                "label_fr": "Aynac"
            },
            {
                "value": 30184,
                "label": "Compolibat",
                "label_ar": "Compolibat",
                "label_fr": "Compolibat"
            },
            {
                "value": 30185,
                "label": "Gramat",
                "label_ar": "جرامات",
                "label_fr": "Gramat"
            },
            {
                "value": 30186,
                "label": "La Rochette",
                "label_ar": "لاروشيت",
                "label_fr": "La Rochette"
            },
            {
                "value": 30187,
                "label": "Saint-Remy-en-Mauges",
                "label_ar": "سانت ريمي أون موج",
                "label_fr": "Saint-Rémy-en-Mauges"
            },
            {
                "value": 30188,
                "label": "Plassay",
                "label_ar": "بلاساي",
                "label_fr": "Plassay"
            },
            {
                "value": 30189,
                "label": "Pompaire",
                "label_ar": "بومباير",
                "label_fr": "Pompaire"
            },
            {
                "value": 30190,
                "label": "Montbron",
                "label_ar": "مونبرون",
                "label_fr": "Montbron"
            },
            {
                "value": 30191,
                "label": "Lestelle-Betharram",
                "label_ar": "ليستل بيثرام",
                "label_fr": "Lestelle-Bétharram"
            },
            {
                "value": 30192,
                "label": "Aventignan",
                "label_ar": "أفينتينيان",
                "label_fr": "Aventignan"
            },
            {
                "value": 30193,
                "label": "Flavin",
                "label_ar": "فلافين",
                "label_fr": "Flavin"
            },
            {
                "value": 30194,
                "label": "Thoux",
                "label_ar": "انت",
                "label_fr": "Thoux"
            },
            {
                "value": 30195,
                "label": "Saint-Clement",
                "label_ar": "سانت كليمان",
                "label_fr": "Saint-Clément"
            },
            {
                "value": 30196,
                "label": "Lussant",
                "label_ar": "لوسانت",
                "label_fr": "Lussant"
            },
            {
                "value": 30197,
                "label": "Leugny",
                "label_ar": "ليوجني",
                "label_fr": "Leugny"
            },
            {
                "value": 30198,
                "label": "Roche-le-Peyroux",
                "label_ar": "روش لو بيروكس",
                "label_fr": "Roche-le-Peyroux"
            },
            {
                "value": 30199,
                "label": "Videix",
                "label_ar": "فيديكس",
                "label_fr": "Videix"
            },
            {
                "value": 30200,
                "label": "Chatillon-sur-Thouet",
                "label_ar": "Chatillon-sur-Thouet",
                "label_fr": "Châtillon-sur-Thouet"
            },
            {
                "value": 30201,
                "label": "Puycornet",
                "label_ar": "Puycornet",
                "label_fr": "Puycornet"
            },
            {
                "value": 30202,
                "label": "Bougue",
                "label_ar": "Bougue",
                "label_fr": "Bougue"
            },
            {
                "value": 30203,
                "label": "Benesse-Maremne",
                "label_ar": "بينيس مارمن",
                "label_fr": "Benesse-Maremne"
            },
            {
                "value": 30204,
                "label": "Semeacq-Blachon",
                "label_ar": "Semeacq-Blachon",
                "label_fr": "Semeacq-Blachon"
            },
            {
                "value": 30205,
                "label": "Montchaude",
                "label_ar": "مونتشود",
                "label_fr": "Montchaude"
            },
            {
                "value": 30206,
                "label": "Benon",
                "label_ar": "بينون",
                "label_fr": "Benon"
            },
            {
                "value": 30207,
                "label": "Orion",
                "label_ar": "أوريون",
                "label_fr": "Orion"
            },
            {
                "value": 30208,
                "label": "Laruns",
                "label_ar": "لارونس",
                "label_fr": "Laruns"
            },
            {
                "value": 30209,
                "label": "Massat",
                "label_ar": "ماسات",
                "label_fr": "Massat"
            },
            {
                "value": 30210,
                "label": "Clairvaux-dAveyron",
                "label_ar": "كليرفو دي أفيرون",
                "label_fr": "Clairvaux-d'Aveyron"
            },
            {
                "value": 30211,
                "label": "Smarves",
                "label_ar": "سمارفيس",
                "label_fr": "Smarves"
            },
            {
                "value": 30212,
                "label": "Coussay-les-Bois",
                "label_ar": "Coussay-les-Bois",
                "label_fr": "Coussay-les-Bois"
            },
            {
                "value": 30213,
                "label": "Saint-Vaury",
                "label_ar": "سانت فوري",
                "label_fr": "Saint-Vaury"
            },
            {
                "value": 30214,
                "label": "Saint-Sauveur-la-Sagne",
                "label_ar": "سان سوفور لا ساني",
                "label_fr": "Saint-Sauveur-la-Sagne"
            },
            {
                "value": 30215,
                "label": "Saint-Geniez-d'Olt",
                "label_ar": "سان جينيز دولت",
                "label_fr": "Saint-Geniez-d'Olt"
            },
            {
                "value": 30216,
                "label": "Dolus-d'Oleron",
                "label_ar": "دولوس دولرون",
                "label_fr": "Dolus-d'Oléron"
            },
            {
                "value": 30217,
                "label": "Varen",
                "label_ar": "فارين",
                "label_fr": "Varen"
            },
            {
                "value": 30218,
                "label": "Saint-Yrieix-sur-Charente",
                "label_ar": "سان يرييكس سور شارينت",
                "label_fr": "Saint-Yrieix-sur-Charente"
            },
            {
                "value": 30219,
                "label": "Châlus",
                "label_ar": "شالوس",
                "label_fr": "Châlus"
            },
            {
                "value": 30220,
                "label": "Saint-Laurent-de-la-Pree",
                "label_ar": "سان لوران دي لا بري",
                "label_fr": "Saint-Laurent-de-la-Prée"
            },
            {
                "value": 30221,
                "label": "Sauze-Vaussais",
                "label_ar": "Sauze-Vaussais",
                "label_fr": "Sauze-Vaussais"
            },
            {
                "value": 30222,
                "label": "Jugeals-Nazareth",
                "label_ar": "Jugeals-الناصرة",
                "label_fr": "Jugeals-Nazareth"
            },
            {
                "value": 30223,
                "label": "Saint-Angeau",
                "label_ar": "سان أنجو",
                "label_fr": "Saint-Angeau"
            },
            {
                "value": 30224,
                "label": "Pontenx-les-Forges",
                "label_ar": "Pontenx-les-Forges",
                "label_fr": "Pontenx-les-Forges"
            },
            {
                "value": 30225,
                "label": "Commensacq",
                "label_ar": "كومينساك",
                "label_fr": "Commensacq"
            },
            {
                "value": 30226,
                "label": "Izotges",
                "label_ar": "إيزوتجز",
                "label_fr": "Izotges"
            },
            {
                "value": 30227,
                "label": "Villeneuve-de-Duras",
                "label_ar": "فيلنوف دي دوراس",
                "label_fr": "Villeneuve-de-Duras"
            },
            {
                "value": 30228,
                "label": "Montamise",
                "label_ar": "مونتاميز",
                "label_fr": "Montamise"
            },
            {
                "value": 30229,
                "label": "La Foye-Monjault",
                "label_ar": "لا فوي مونجولت",
                "label_fr": "La Foye-Monjault"
            },
            {
                "value": 30230,
                "label": "Tourriers",
                "label_ar": "سائحون",
                "label_fr": "Tourriers"
            },
            {
                "value": 30231,
                "label": "Montdoumerc",
                "label_ar": "مونتدومرك",
                "label_fr": "Montdoumerc"
            },
            {
                "value": 30232,
                "label": "Castetner",
                "label_ar": "كاستينر",
                "label_fr": "Castetner"
            },
            {
                "value": 30233,
                "label": "Idron",
                "label_ar": "ايدرون",
                "label_fr": "Idron"
            },
            {
                "value": 30234,
                "label": "Bignoux",
                "label_ar": "بيجنو",
                "label_fr": "Bignoux"
            },
            {
                "value": 30235,
                "label": "Sedzere",
                "label_ar": "سيدزير",
                "label_fr": "Sedzere"
            },
            {
                "value": 30236,
                "label": "Saint-Jean-de-Marsacq",
                "label_ar": "سان جان دي مارساك",
                "label_fr": "Saint-Jean-de-Marsacq"
            },
            {
                "value": 30237,
                "label": "Bertholene",
                "label_ar": "بيرثولين",
                "label_fr": "Bertholène"
            },
            {
                "value": 30238,
                "label": "Garanou",
                "label_ar": "جارانو",
                "label_fr": "Garanou"
            },
            {
                "value": 30239,
                "label": "Gencay",
                "label_ar": "جينكاي",
                "label_fr": "Gencay"
            },
            {
                "value": 30240,
                "label": "Sorcy-Saint-Martin",
                "label_ar": "سورسي سانت مارتن",
                "label_fr": "Sorcy-Saint-Martin"
            },
            {
                "value": 30241,
                "label": "Angevillers",
                "label_ar": "انجيفيلرز",
                "label_fr": "Angevillers"
            },
            {
                "value": 30242,
                "label": "Fleville-devant-Nancy",
                "label_ar": "فليفيل ديفانت نانسي",
                "label_fr": "Fleville-devant-Nancy"
            },
            {
                "value": 30243,
                "label": "Spincourt",
                "label_ar": "سبينكور",
                "label_fr": "Spincourt"
            },
            {
                "value": 30244,
                "label": "Darmont",
                "label_ar": "دارمونت",
                "label_fr": "Darmont"
            },
            {
                "value": 30245,
                "label": "Aspach-le-Bas",
                "label_ar": "أسباش لو باس",
                "label_fr": "Aspach-le-Bas"
            },
            {
                "value": 30246,
                "label": "Steinbach",
                "label_ar": "شتاينباخ",
                "label_fr": "Steinbach"
            },
            {
                "value": 30247,
                "label": "Durlinsdorf",
                "label_ar": "دورلينسدورف",
                "label_fr": "Durlinsdorf"
            },
            {
                "value": 30248,
                "label": "Fellering",
                "label_ar": "قطعان",
                "label_fr": "Abattage"
            },
            {
                "value": 30249,
                "label": "Franxault",
                "label_ar": "فرانكسولت",
                "label_fr": "Franxault"
            },
            {
                "value": 30250,
                "label": "Lacrost",
                "label_ar": "لاكروست",
                "label_fr": "Lacrost"
            },
            {
                "value": 30251,
                "label": "Vault-de-Lugny",
                "label_ar": "Vault-de-Lugny",
                "label_fr": "Vault-de-Lugny"
            },
            {
                "value": 30252,
                "label": "Saint-Leger-sur-Dheune",
                "label_ar": "سان ليجيه سور ديون",
                "label_fr": "Saint-Léger-sur-Dheune"
            },
            {
                "value": 30253,
                "label": "Chigy",
                "label_ar": "شيغي",
                "label_fr": "Chigy"
            },
            {
                "value": 30254,
                "label": "Hurigny",
                "label_ar": "هيريجني",
                "label_fr": "Hurigny"
            },
            {
                "value": 30255,
                "label": "Ovanches",
                "label_ar": "الأفران",
                "label_fr": "Ovanches"
            },
            {
                "value": 30256,
                "label": "Couchey",
                "label_ar": "كوشي",
                "label_fr": "Couchey"
            },
            {
                "value": 30257,
                "label": "Ruffey-les-Beaune",
                "label_ar": "روفي ليه بون",
                "label_fr": "Ruffey-les-Beaune"
            },
            {
                "value": 30258,
                "label": "Beaufort",
                "label_ar": "بوفورت",
                "label_fr": "Beaufort"
            },
            {
                "value": 30259,
                "label": "Meursault",
                "label_ar": "مورسو",
                "label_fr": "Meursault"
            },
            {
                "value": 30260,
                "label": "Cousance",
                "label_ar": "كوزانس",
                "label_fr": "Cousance"
            },
            {
                "value": 30261,
                "label": "Danne-et-Quatre-Vents",
                "label_ar": "Danne-et-Quatre-Vents",
                "label_fr": "Danne-et-Quatre-Vents"
            },
            {
                "value": 30262,
                "label": "Moussey",
                "label_ar": "موسي",
                "label_fr": "Moussey"
            },
            {
                "value": 30263,
                "label": "Rarecourt",
                "label_ar": "راريكور",
                "label_fr": "Rarecourt"
            },
            {
                "value": 30264,
                "label": "Senones",
                "label_ar": "سينونيس",
                "label_fr": "Senones"
            },
            {
                "value": 30265,
                "label": "Velaines",
                "label_ar": "فيلينيس",
                "label_fr": "Velaines"
            },
            {
                "value": 30266,
                "label": "Nayemont-les-Fosses",
                "label_ar": "نيمونت ليه فوسيس",
                "label_fr": "Nayemont-les-Fosses"
            },
            {
                "value": 30267,
                "label": "Ogy",
                "label_ar": "أوجي",
                "label_fr": "Ogy"
            },
            {
                "value": 30268,
                "label": "Taintrux",
                "label_ar": "تاينتروكس",
                "label_fr": "Taintrux"
            },
            {
                "value": 30269,
                "label": "Sainte-Genevieve",
                "label_ar": "سانت جينيفيف",
                "label_fr": "Sainte-Geneviève"
            },
            {
                "value": 30270,
                "label": "Norroy-les-Pont-a-Mousson",
                "label_ar": "نورروي ليس بونت آ موسون",
                "label_fr": "Norroy-les-Pont-à-Mousson"
            },
            {
                "value": 30271,
                "label": "Minversheim",
                "label_ar": "مينفيرشيم",
                "label_fr": "Minversheim"
            },
            {
                "value": 30272,
                "label": "Grentzingen",
                "label_ar": "جرنتزينجن",
                "label_fr": "Grentzingen"
            },
            {
                "value": 30273,
                "label": "Wangenbourg-Engenthal",
                "label_ar": "وانجينبورغ إنجينثال",
                "label_fr": "Wangenbourg-Engenthal"
            },
            {
                "value": 30274,
                "label": "Obersaasheim",
                "label_ar": "أوبرساشيم",
                "label_fr": "Obersaasheim"
            },
            {
                "value": 30275,
                "label": "Chatillon-le-Duc",
                "label_ar": "شاتيلون لو دوك",
                "label_fr": "Châtillon-le-Duc"
            },
            {
                "value": 30276,
                "label": "Frebuans",
                "label_ar": "Frebuans",
                "label_fr": "Frebuans"
            },
            {
                "value": 30277,
                "label": "Courlaoux",
                "label_ar": "كورلاوكس",
                "label_fr": "Courlaoux"
            },
            {
                "value": 30278,
                "label": "Grandvaux",
                "label_ar": "جراندفو",
                "label_fr": "Grandvaux"
            },
            {
                "value": 30279,
                "label": "Champlitte",
                "label_ar": "شامبليت",
                "label_fr": "Champlitte"
            },
            {
                "value": 30280,
                "label": "Bonnevaux-le-Prieure",
                "label_ar": "Bonnevaux-le-Prieure",
                "label_fr": "Bonnevaux-le-Prieuré"
            },
            {
                "value": 30281,
                "label": "Saint-Bris-le-Vineux",
                "label_ar": "سان بريس لو فينيوكس",
                "label_fr": "Saint-Bris-le-Vineux"
            },
            {
                "value": 30282,
                "label": "Saint-Clement",
                "label_ar": "سانت كليمان",
                "label_fr": "Saint-Clément"
            },
            {
                "value": 30283,
                "label": "Armaucourt",
                "label_ar": "أرموكورت",
                "label_fr": "Armaucourt"
            },
            {
                "value": 30284,
                "label": "Rehainviller",
                "label_ar": "Rehainviller",
                "label_fr": "Rehainviller"
            },
            {
                "value": 30285,
                "label": "Leyr",
                "label_ar": "لير",
                "label_fr": "Leyr"
            },
            {
                "value": 30286,
                "label": "Oderen",
                "label_ar": "اوديرين",
                "label_fr": "Oderen"
            },
            {
                "value": 30287,
                "label": "Siegen",
                "label_ar": "سيغن",
                "label_fr": "Siegen"
            },
            {
                "value": 30288,
                "label": "Lipsheim",
                "label_ar": "ليبشايم",
                "label_fr": "Lipsheim"
            },
            {
                "value": 30289,
                "label": "La Frette",
                "label_ar": "لا فريت",
                "label_fr": "La Frette"
            },
            {
                "value": 30290,
                "label": "Ouhans",
                "label_ar": "اوهانس",
                "label_fr": "Ouhans"
            },
            {
                "value": 30291,
                "label": "Torpes",
                "label_ar": "توربس",
                "label_fr": "Torpes"
            },
            {
                "value": 30292,
                "label": "Saules",
                "label_ar": "شاول",
                "label_fr": "Saules"
            },
            {
                "value": 30293,
                "label": "Baulay",
                "label_ar": "باولاي",
                "label_fr": "Baulay"
            },
            {
                "value": 30294,
                "label": "Levernois",
                "label_ar": "ليفرنوا",
                "label_fr": "Levernois"
            },
            {
                "value": 30295,
                "label": "Soucy",
                "label_ar": "سوسي",
                "label_fr": "Soucy"
            },
            {
                "value": 30296,
                "label": "Collonges-les-Premieres",
                "label_ar": "Collonges-les-Premieres",
                "label_fr": "Collonges-les-Premières"
            },
            {
                "value": 30297,
                "label": "Pournoy-la-Grasse",
                "label_ar": "بورنوي لا جراس",
                "label_fr": "Pournoy-la-Grasse"
            },
            {
                "value": 30298,
                "label": "Fontenoy-la-Joute",
                "label_ar": "Fontenoy-la-Joute",
                "label_fr": "Fontenoy-la-Joute"
            },
            {
                "value": 30299,
                "label": "Treveray",
                "label_ar": "تريفيراي",
                "label_fr": "Treveray"
            },
            {
                "value": 30300,
                "label": "Xures",
                "label_ar": "Xures",
                "label_fr": "Xures"
            },
            {
                "value": 30301,
                "label": "Gripport",
                "label_ar": "غريبورت",
                "label_fr": "Gripport"
            },
            {
                "value": 30302,
                "label": "Herny",
                "label_ar": "هيرني",
                "label_fr": "Herny"
            },
            {
                "value": 30303,
                "label": "Hallering",
                "label_ar": "هالرينغ",
                "label_fr": "Hallering"
            },
            {
                "value": 30304,
                "label": "Loudrefing",
                "label_ar": "تفريغ",
                "label_fr": "Loudrefing"
            },
            {
                "value": 30305,
                "label": "Jainvillotte",
                "label_ar": "جينفيلوت",
                "label_fr": "Jainvillotte"
            },
            {
                "value": 30306,
                "label": "Dieppe-sous-Douaumont",
                "label_ar": "دييب سو دومون",
                "label_fr": "Dieppe-sous-Douaumont"
            },
            {
                "value": 30307,
                "label": "Jezainville",
                "label_ar": "جيزنفيل",
                "label_fr": "Jezainville"
            },
            {
                "value": 30308,
                "label": "Neuviller-sur-Moselle",
                "label_ar": "نيوفيلر سور موسيل",
                "label_fr": "Neuviller-sur-Moselle"
            },
            {
                "value": 30309,
                "label": "Haut-Clocher",
                "label_ar": "أوت كلوشر",
                "label_fr": "Haut-Clocher"
            },
            {
                "value": 30310,
                "label": "Vieux-Thann",
                "label_ar": "فيو تان",
                "label_fr": "Vieux-Thann"
            },
            {
                "value": 30311,
                "label": "Jettingen",
                "label_ar": "جيتينجن",
                "label_fr": "Jettingen"
            },
            {
                "value": 30312,
                "label": "Malmerspach",
                "label_ar": "مالمرسباخ",
                "label_fr": "Malmerspach"
            },
            {
                "value": 30313,
                "label": "Chemilly-sur-Serein",
                "label_ar": "كيميلي سور سيرين",
                "label_fr": "Chemilly-sur-Serein"
            },
            {
                "value": 30314,
                "label": "Mont-sur-Monnet",
                "label_ar": "مونت سور مونيه",
                "label_fr": "Mont-sur-Monnet"
            },
            {
                "value": 30315,
                "label": "Farges-les-Chalon",
                "label_ar": "فارج ليس شالون",
                "label_fr": "Farges-les-Chalon"
            },
            {
                "value": 30316,
                "label": "Julienas",
                "label_ar": "جولياناس",
                "label_fr": "Julienas"
            },
            {
                "value": 30317,
                "label": "Quenoche",
                "label_ar": "كوينوش",
                "label_fr": "Quenoche"
            },
            {
                "value": 30318,
                "label": "Ratte",
                "label_ar": "رات",
                "label_fr": "Ratte"
            },
            {
                "value": 30319,
                "label": "Courlans",
                "label_ar": "كورلانس",
                "label_fr": "Courlans"
            },
            {
                "value": 30320,
                "label": "Boussay",
                "label_ar": "بوسي",
                "label_fr": "Boussay"
            },
            {
                "value": 30321,
                "label": "La Baule-Escoublac",
                "label_ar": "لا بول إسكوبلاك",
                "label_fr": "La Baule-Escoublac"
            },
            {
                "value": 30322,
                "label": "Beaumont-Pied-de-Boeuf",
                "label_ar": "بومون بييد دي بوف",
                "label_fr": "Beaumont-Pied-de-Boeuf"
            },
            {
                "value": 30323,
                "label": "La Place",
                "label_ar": "لا بليس",
                "label_fr": "La Place"
            },
            {
                "value": 30324,
                "label": "Saint-Mars-de-Coutais",
                "label_ar": "Saint-Mars-de-Coutais",
                "label_fr": "Saint-Mars-de-Coutais"
            },
            {
                "value": 30325,
                "label": "Le Fief-Sauvin",
                "label_ar": "لو فيف-سوفين",
                "label_fr": "Le Fief-Sauvin"
            },
            {
                "value": 30326,
                "label": "Hambers",
                "label_ar": "هامبرز",
                "label_fr": "Hambers"
            },
            {
                "value": 30327,
                "label": "Besse-sur-Braye",
                "label_ar": "بيس سور براي",
                "label_fr": "Besse-sur-Braye"
            },
            {
                "value": 30328,
                "label": "Saint-Aignan-sur-Roe",
                "label_ar": "Saint-Aignan-sur-Roe",
                "label_fr": "Saint-Aignan-sur-Roe"
            },
            {
                "value": 30329,
                "label": "Triaize",
                "label_ar": "ترييز",
                "label_fr": "Triaize"
            },
            {
                "value": 30330,
                "label": "Chambretaud",
                "label_ar": "شامبريتود",
                "label_fr": "Chambretaud"
            },
            {
                "value": 30331,
                "label": "Mouzeil",
                "label_ar": "موزيل",
                "label_fr": "Mouzeil"
            },
            {
                "value": 30332,
                "label": "Saint-Martin-les-Melle",
                "label_ar": "سان مارتن ليه ميلي",
                "label_fr": "Saint-Martin-les-Melle"
            },
            {
                "value": 30333,
                "label": "Saint-Lezin",
                "label_ar": "سان ليزين",
                "label_fr": "Saint-Lezin"
            },
            {
                "value": 30334,
                "label": "Mulsanne",
                "label_ar": "مولسان",
                "label_fr": "Mulsanne"
            },
            {
                "value": 30335,
                "label": "Corsept",
                "label_ar": "كورسيبت",
                "label_fr": "Corsept"
            },
            {
                "value": 30336,
                "label": "Fille",
                "label_ar": "فيل",
                "label_fr": "Fille"
            },
            {
                "value": 30337,
                "label": "Pruille-l'Eguille",
                "label_ar": "Pruille-l'Eguille",
                "label_fr": "Pruille-l'Eguille"
            },
            {
                "value": 30338,
                "label": "Fontenay-sur-Vegre",
                "label_ar": "فونتيني سور فيجري",
                "label_fr": "Fontenay-sur-Vegre"
            },
            {
                "value": 30339,
                "label": "Saint-Melaine-sur-Aubance",
                "label_ar": "سان ميلين سور اوبانس",
                "label_fr": "Saint-Melaine-sur-Aubance"
            },
            {
                "value": 30340,
                "label": "Le Mazeau",
                "label_ar": "لو مازو",
                "label_fr": "Le Mazeau"
            },
            {
                "value": 30341,
                "label": "Mache",
                "label_ar": "ماشي",
                "label_fr": "Mache"
            },
            {
                "value": 30342,
                "label": "Angles",
                "label_ar": "الزوايا",
                "label_fr": "Angles"
            },
            {
                "value": 30343,
                "label": "Le Gavre",
                "label_ar": "لو جافري",
                "label_fr": "Le Gavre"
            },
            {
                "value": 30344,
                "label": "Jupilles",
                "label_ar": "Jupilles",
                "label_fr": "Jupilles"
            },
            {
                "value": 30345,
                "label": "Distre",
                "label_ar": "Distre",
                "label_fr": "Distre"
            },
            {
                "value": 30346,
                "label": "Luceau",
                "label_ar": "لوسو",
                "label_fr": "Luceau"
            },
            {
                "value": 30347,
                "label": "Prefailles",
                "label_ar": "الألفاظ",
                "label_fr": "Préfailles"
            },
            {
                "value": 30348,
                "label": "Saint-Clement-de-la-Place",
                "label_ar": "سان كليمان دي لا بلاس",
                "label_fr": "Saint-Clément-de-la-Place"
            },
            {
                "value": 30349,
                "label": "Gene",
                "label_ar": "الجين",
                "label_fr": "Gène"
            },
            {
                "value": 30350,
                "label": "La Salle-de-Vihiers",
                "label_ar": "La Salle-de-Vihiers",
                "label_fr": "La Salle-de-Vihiers"
            },
            {
                "value": 30351,
                "label": "Monts-sur-Guesnes",
                "label_ar": "مونتس سور جويسنيس",
                "label_fr": "Monts-sur-Guesnes"
            },
            {
                "value": 30352,
                "label": "Saint-Quentin-en-Mauges",
                "label_ar": "سانت كوينتين أون موج",
                "label_fr": "Saint-Quentin-en-Mauges"
            },
            {
                "value": 30353,
                "label": "Montigne-les-Rairies",
                "label_ar": "مونتين ليه رايري",
                "label_fr": "Montigne-les-Rairies"
            },
            {
                "value": 30354,
                "label": "Chatelais",
                "label_ar": "شاتيليس",
                "label_fr": "Châtelais"
            },
            {
                "value": 30355,
                "label": "Saint-Laurent-du-Mottay",
                "label_ar": "سان لوران دو موتاي",
                "label_fr": "Saint-Laurent-du-Mottay"
            },
            {
                "value": 30356,
                "label": "Mareuil-sur-Lay-Dissais",
                "label_ar": "مريل سور لاي ديسيس",
                "label_fr": "Mareuil-sur-Lay-Dissais"
            },
            {
                "value": 30357,
                "label": "Le Thou",
                "label_ar": "لو انت",
                "label_fr": "Le Thou"
            },
            {
                "value": 30358,
                "label": "Maigne",
                "label_ar": "ماين",
                "label_fr": "Maigne"
            },
            {
                "value": 30359,
                "label": "Laigne",
                "label_ar": "ليني",
                "label_fr": "Laigne"
            },
            {
                "value": 30360,
                "label": "Soulaines-sur-Aubance",
                "label_ar": "سولين سور اوبانس",
                "label_fr": "Soulaines-sur-Aubance"
            },
            {
                "value": 30361,
                "label": "Xanton-Chassenon",
                "label_ar": "زانتون شاسينون",
                "label_fr": "Xanton-Chassenon"
            },
            {
                "value": 30362,
                "label": "Pontigne",
                "label_ar": "بونتين",
                "label_fr": "Pontigne"
            },
            {
                "value": 30363,
                "label": "Damvix",
                "label_ar": "دامفيكس",
                "label_fr": "Damvix"
            },
            {
                "value": 30364,
                "label": "Isches",
                "label_ar": "Isches",
                "label_fr": "Isches"
            },
            {
                "value": 30365,
                "label": "Senonges",
                "label_ar": "سينونجيس",
                "label_fr": "Senonges"
            },
            {
                "value": 30366,
                "label": "Sevrey",
                "label_ar": "سيفري",
                "label_fr": "Sevrey"
            },
            {
                "value": 30367,
                "label": "Varennes-le-Grand",
                "label_ar": "فارين لو جراند",
                "label_fr": "Varennes-le-Grand"
            },
            {
                "value": 30368,
                "label": "Ladoix-Serrigny",
                "label_ar": "Ladoix-Serrigny",
                "label_fr": "Ladoix-Serrigny"
            },
            {
                "value": 30369,
                "label": "Couches",
                "label_ar": "أرائك",
                "label_fr": "Couches"
            },
            {
                "value": 30370,
                "label": "Ecrouves",
                "label_ar": "إكروفيس",
                "label_fr": "Écrouves"
            },
            {
                "value": 30371,
                "label": "Dornot",
                "label_ar": "دورنو",
                "label_fr": "Dornot"
            },
            {
                "value": 30372,
                "label": "Moncel-sur-Seille",
                "label_ar": "Moncel-sur-Seille",
                "label_fr": "Moncel-sur-Seille"
            },
            {
                "value": 30373,
                "label": "Saint-Berain-sur-Dheune",
                "label_ar": "سان بيرين سور ديون",
                "label_fr": "Saint-Berain-sur-Dheune"
            },
            {
                "value": 30374,
                "label": "Les Allies",
                "label_ar": "ليس الحلفاء",
                "label_fr": "Les Allies"
            },
            {
                "value": 30375,
                "label": "Sausheim",
                "label_ar": "Sausheim",
                "label_fr": "Sausheim"
            },
            {
                "value": 30376,
                "label": "Grandfontaine-Fournets",
                "label_ar": "غراندفونتين فورنتس",
                "label_fr": "Grandfontaine-Fournets"
            },
            {
                "value": 30377,
                "label": "Mont-sur-Meurthe",
                "label_ar": "مونت سور مورث",
                "label_fr": "Mont-sur-Meurthe"
            },
            {
                "value": 30378,
                "label": "Hauconcourt",
                "label_ar": "Hauconcourt",
                "label_fr": "Hauconcourt"
            },
            {
                "value": 30379,
                "label": "Ancerville",
                "label_ar": "أنسيرفيل",
                "label_fr": "Ancerville"
            },
            {
                "value": 30380,
                "label": "Magny-Cours",
                "label_ar": "ماجني كور",
                "label_fr": "Magny-Cours"
            },
            {
                "value": 30381,
                "label": "Batilly",
                "label_ar": "باتيلي",
                "label_fr": "Batilly"
            },
            {
                "value": 30382,
                "label": "Weyersheim",
                "label_ar": "ويرشيم",
                "label_fr": "Weyersheim"
            },
            {
                "value": 30383,
                "label": "Epfig",
                "label_ar": "Epfig",
                "label_fr": "Epfig"
            },
            {
                "value": 30384,
                "label": "Cuisery",
                "label_ar": "كويسيري",
                "label_fr": "Cuisery"
            },
            {
                "value": 30385,
                "label": "Labaroche",
                "label_ar": "لاباروش",
                "label_fr": "Labaroche"
            },
            {
                "value": 30386,
                "label": "Nanton",
                "label_ar": "نانتون",
                "label_fr": "Nanton"
            },
            {
                "value": 30387,
                "label": "Salans",
                "label_ar": "سالانز",
                "label_fr": "Salans"
            },
            {
                "value": 30388,
                "label": "Willerwald",
                "label_ar": "ويلروالد",
                "label_fr": "Willerwald"
            },
            {
                "value": 30389,
                "label": "Jesonville",
                "label_ar": "جيسونفيل",
                "label_fr": "Jesonville"
            },
            {
                "value": 30390,
                "label": "Treclun",
                "label_ar": "تريكلون",
                "label_fr": "Treclun"
            },
            {
                "value": 30391,
                "label": "La Villeneuve",
                "label_ar": "لا فيلنوف",
                "label_fr": "La Villeneuve"
            },
            {
                "value": 30392,
                "label": "Les Gras",
                "label_ar": "ليه غرا",
                "label_fr": "Les Gras"
            },
            {
                "value": 30393,
                "label": "Les Granges",
                "label_ar": "ليه جرانجز",
                "label_fr": "Les Granges"
            },
            {
                "value": 30394,
                "label": "Labry",
                "label_ar": "لابري",
                "label_fr": "Labry"
            },
            {
                "value": 30395,
                "label": "Dinoze",
                "label_ar": "دينوز",
                "label_fr": "Dinoze"
            },
            {
                "value": 30396,
                "label": "Velotte-et-Tatignecourt",
                "label_ar": "Velotte-et-Tatignecourt",
                "label_fr": "Velotte-et-Tatignecourt"
            },
            {
                "value": 30397,
                "label": "Haudiomont",
                "label_ar": "هاوديومونت",
                "label_fr": "Haudiomont"
            },
            {
                "value": 30398,
                "label": "Velars-sur-Ouche",
                "label_ar": "Velars-sur-Ouche",
                "label_fr": "Velars-sur-Ouche"
            },
            {
                "value": 30399,
                "label": "La Tagniere",
                "label_ar": "لا تاغنيير",
                "label_fr": "La Tagnière"
            },
            {
                "value": 30400,
                "label": "Chevrotaine",
                "label_ar": "شيفروتين",
                "label_fr": "Chevrotaine"
            },
            {
                "value": 30401,
                "label": "Pouilloux",
                "label_ar": "بويلوكس",
                "label_fr": "Pouilloux"
            },
            {
                "value": 30402,
                "label": "Landres",
                "label_ar": "لاندريس",
                "label_fr": "Landres"
            },
            {
                "value": 30403,
                "label": "Knutange",
                "label_ar": "كنوتانج",
                "label_fr": "Knutange"
            },
            {
                "value": 30404,
                "label": "Hilbesheim",
                "label_ar": "هيلبيشيم",
                "label_fr": "Hilbesheim"
            },
            {
                "value": 30405,
                "label": "Obtree",
                "label_ar": "أوبتري",
                "label_fr": "Obtree"
            },
            {
                "value": 30406,
                "label": "Vaudrey",
                "label_ar": "فودري",
                "label_fr": "Vaudrey"
            },
            {
                "value": 30407,
                "label": "Moroges",
                "label_ar": "موروج",
                "label_fr": "Moroges"
            },
            {
                "value": 30408,
                "label": "Juvigny-en-Perthois",
                "label_ar": "جوفيني أون بيرثوا",
                "label_fr": "Juvigny-en-Perthois"
            },
            {
                "value": 30409,
                "label": "Millery",
                "label_ar": "ميلري",
                "label_fr": "Millery"
            },
            {
                "value": 30410,
                "label": "Vezelise",
                "label_ar": "فيزيليزي",
                "label_fr": "Vézelise"
            },
            {
                "value": 30411,
                "label": "Crancot",
                "label_ar": "كرانكوت",
                "label_fr": "Crancot"
            },
            {
                "value": 30412,
                "label": "Cuinzier",
                "label_ar": "كوينزير",
                "label_fr": "Cuinzier"
            },
            {
                "value": 30413,
                "label": "Longevilles-Mont-d'Or",
                "label_ar": "Longevilles-Mont-d'Or",
                "label_fr": "Longevilles-Mont-d'Or"
            },
            {
                "value": 30414,
                "label": "Vermenton",
                "label_ar": "فيرمينتون",
                "label_fr": "Vermenton"
            },
            {
                "value": 30415,
                "label": "La Tour-de-Scay",
                "label_ar": "لا تور دي سكاى",
                "label_fr": "La Tour-de-Scay"
            },
            {
                "value": 30416,
                "label": "Chaudefontaine",
                "label_ar": "شوديفونتين",
                "label_fr": "Chaudefontaine"
            },
            {
                "value": 30417,
                "label": "Branges",
                "label_ar": "برانجز",
                "label_fr": "Branges"
            },
            {
                "value": 30418,
                "label": "Marchaux",
                "label_ar": "مارشو",
                "label_fr": "Marchaux"
            },
            {
                "value": 30419,
                "label": "Marliens",
                "label_ar": "مارلين",
                "label_fr": "Marliens"
            },
            {
                "value": 30420,
                "label": "Varanges",
                "label_ar": "Varanges",
                "label_fr": "Varanges"
            },
            {
                "value": 30421,
                "label": "Le Poet",
                "label_ar": "لو شاعر",
                "label_fr": "Le Poète"
            },
            {
                "value": 30422,
                "label": "Jausiers",
                "label_ar": "Jausiers",
                "label_fr": "Jausiers"
            },
            {
                "value": 30423,
                "label": "Les Vigneaux",
                "label_ar": "ليه فينيو",
                "label_fr": "Les Vigneaux"
            },
            {
                "value": 30424,
                "label": "Callian",
                "label_ar": "كاليان",
                "label_fr": "Callian"
            },
            {
                "value": 30425,
                "label": "La Rouine",
                "label_ar": "لا روان",
                "label_fr": "La Rouine"
            },
            {
                "value": 30426,
                "label": "Pierrefeu-du-Var",
                "label_ar": "بييرفيو دو فار",
                "label_fr": "Pierrefeu-du-Var"
            },
            {
                "value": 30427,
                "label": "Opio",
                "label_ar": "أفيو",
                "label_fr": "Opio"
            },
            {
                "value": 30428,
                "label": "Agay",
                "label_ar": "مثلي",
                "label_fr": "Un gay"
            },
            {
                "value": 30429,
                "label": "Toudon",
                "label_ar": "تودون",
                "label_fr": "Toudon"
            },
            {
                "value": 30430,
                "label": "Le Broc",
                "label_ar": "لو بروك",
                "label_fr": "Le Broc"
            },
            {
                "value": 30431,
                "label": "Castellane",
                "label_ar": "كاستيلان",
                "label_fr": "Castellane"
            },
            {
                "value": 30432,
                "label": "Isola",
                "label_ar": "ايزولا",
                "label_fr": "Isola"
            },
            {
                "value": 30433,
                "label": "Ampus",
                "label_ar": "أمبوس",
                "label_fr": "Ampus"
            },
            {
                "value": 30434,
                "label": "Croix",
                "label_ar": "كروا",
                "label_fr": "Croix"
            },
            {
                "value": 30435,
                "label": "Regusse",
                "label_ar": "ريجوس",
                "label_fr": "Regusse"
            },
            {
                "value": 30436,
                "label": "Sepmeries",
                "label_ar": "Sepmeries",
                "label_fr": "Sepmeries"
            },
            {
                "value": 30437,
                "label": "Senlecques",
                "label_ar": "Senlecques",
                "label_fr": "Senlecques"
            },
            {
                "value": 30438,
                "label": "Tilloy-lez-Marchiennes",
                "label_ar": "Tilloy-lez-Marchiennes",
                "label_fr": "Tilloy-lez-Marchiennes"
            },
            {
                "value": 30439,
                "label": "Enquin-les-Mines",
                "label_ar": "Enquin-les-Mines",
                "label_fr": "Enquin-les-Mines"
            },
            {
                "value": 30440,
                "label": "Ferfay",
                "label_ar": "فيرفاي",
                "label_fr": "Ferfay"
            },
            {
                "value": 30441,
                "label": "Cassel",
                "label_ar": "كاسل",
                "label_fr": "Cassel"
            },
            {
                "value": 30442,
                "label": "Cauroir",
                "label_ar": "كوروار",
                "label_fr": "Cauroir"
            },
            {
                "value": 30443,
                "label": "Lallaing",
                "label_ar": "لالينغ",
                "label_fr": "Lallaing"
            },
            {
                "value": 30444,
                "label": "Chemy",
                "label_ar": "كيمياء",
                "label_fr": "Chimie"
            },
            {
                "value": 30445,
                "label": "Beauquesne",
                "label_ar": "بوكسن",
                "label_fr": "Beauquesne"
            },
            {
                "value": 30446,
                "label": "Maignelay-Montigny",
                "label_ar": "Maignelay-Montigny",
                "label_fr": "Maignelay-Montigny"
            },
            {
                "value": 30447,
                "label": "La Fere",
                "label_ar": "لا فيري",
                "label_fr": "La Fère"
            },
            {
                "value": 30448,
                "label": "Mailly-Raineval",
                "label_ar": "مايلي رينيفال",
                "label_fr": "Mailly-Raineval"
            },
            {
                "value": 30449,
                "label": "Coudun",
                "label_ar": "كودون",
                "label_fr": "Coudun"
            },
            {
                "value": 30450,
                "label": "Essigny-le-Grand",
                "label_ar": "إيسيني لو جراند",
                "label_fr": "Essigny-le-Grand"
            },
            {
                "value": 30451,
                "label": "Le Meriot",
                "label_ar": "لو ميريو",
                "label_fr": "Le Meriot"
            },
            {
                "value": 30452,
                "label": "Janvilliers",
                "label_ar": "جانفيلييه",
                "label_fr": "Janvilliers"
            },
            {
                "value": 30453,
                "label": "Cumieres",
                "label_ar": "كوميريس",
                "label_fr": "Cumières"
            },
            {
                "value": 30454,
                "label": "Germaine",
                "label_ar": "جيرمين",
                "label_fr": "Germaine"
            },
            {
                "value": 30455,
                "label": "Erre",
                "label_ar": "خطأ",
                "label_fr": "Erre"
            },
            {
                "value": 30456,
                "label": "Feignies",
                "label_ar": "التظاهر",
                "label_fr": "Feignies"
            },
            {
                "value": 30457,
                "label": "Le Touquet-Paris-Plage",
                "label_ar": "لو توكيه باريس بلاج",
                "label_fr": "Le Touquet-Paris-Plage"
            },
            {
                "value": 30458,
                "label": "Fresnes-les-Montauban",
                "label_ar": "فرينيس ليه مونتوبان",
                "label_fr": "Fresnes-les-Montauban"
            },
            {
                "value": 30459,
                "label": "Villers-au-Tertre",
                "label_ar": "Villers-au-Tertre",
                "label_fr": "Villers-au-Tertre"
            },
            {
                "value": 30460,
                "label": "Roeulx",
                "label_ar": "روولكس",
                "label_fr": "Roeulx"
            },
            {
                "value": 30461,
                "label": "Brouckerque",
                "label_ar": "بروكيرك",
                "label_fr": "Brouckerque"
            },
            {
                "value": 30462,
                "label": "Le Parcq",
                "label_ar": "لو باركق",
                "label_fr": "Le Parcq"
            },
            {
                "value": 30463,
                "label": "Pouru-Saint-Remy",
                "label_ar": "بورو سانت ريمي",
                "label_fr": "Pouru-Saint-Remy"
            },
            {
                "value": 30464,
                "label": "Cheret",
                "label_ar": "شيريت",
                "label_fr": "Cheret"
            },
            {
                "value": 30465,
                "label": "Chesley",
                "label_ar": "تشيسلي",
                "label_fr": "Chesley"
            },
            {
                "value": 30466,
                "label": "Prouilly",
                "label_ar": "برويلي",
                "label_fr": "Prouilly"
            },
            {
                "value": 30467,
                "label": "Primat",
                "label_ar": "بريمات",
                "label_fr": "Primat"
            },
            {
                "value": 30468,
                "label": "Boeseghem",
                "label_ar": "Boeseghem",
                "label_fr": "Boeseghem"
            },
            {
                "value": 30469,
                "label": "Prouvy",
                "label_ar": "بروفي",
                "label_fr": "Prouvy"
            },
            {
                "value": 30470,
                "label": "Hon-Hergies",
                "label_ar": "هون هيرجيز",
                "label_fr": "Hon-Hergies"
            },
            {
                "value": 30471,
                "label": "Rebreuve-sur-Canche",
                "label_ar": "Rebreuve-sur-Canche",
                "label_fr": "Rebreuve-sur-Canche"
            },
            {
                "value": 30472,
                "label": "Canlers",
                "label_ar": "القوارب",
                "label_fr": "Canlers"
            },
            {
                "value": 30473,
                "label": "Merckeghem",
                "label_ar": "Merckeghem",
                "label_fr": "Merckeghem"
            },
            {
                "value": 30474,
                "label": "Berles-au-Bois",
                "label_ar": "Berles-au-Bois",
                "label_fr": "Berles-au-Bois"
            },
            {
                "value": 30475,
                "label": "Lieu-Saint-Amand",
                "label_ar": "ليو سانت اماند",
                "label_fr": "Lieu-Saint-Amand"
            },
            {
                "value": 30476,
                "label": "Armbouts-Cappel",
                "label_ar": "Armbouts-Cappel",
                "label_fr": "Armbouts-Cappel"
            },
            {
                "value": 30477,
                "label": "Rosult",
                "label_ar": "روسولت",
                "label_fr": "Rosult"
            },
            {
                "value": 30478,
                "label": "Nedonchel",
                "label_ar": "نيدونشل",
                "label_fr": "Nedonchel"
            },
            {
                "value": 30479,
                "label": "Frethun",
                "label_ar": "فريتون",
                "label_fr": "Frethun"
            },
            {
                "value": 30480,
                "label": "Autremencourt",
                "label_ar": "Autremencourt",
                "label_fr": "Autremencourt"
            },
            {
                "value": 30481,
                "label": "Hemevillers",
                "label_ar": "Hemevillers",
                "label_fr": "Hemevillers"
            },
            {
                "value": 30482,
                "label": "Servais",
                "label_ar": "Servais",
                "label_fr": "Servais"
            },
            {
                "value": 30483,
                "label": "Villers-le-Sec",
                "label_ar": "فيلرز لو سيك",
                "label_fr": "Villers-le-Sec"
            },
            {
                "value": 30484,
                "label": "Lheraule",
                "label_ar": "Lheraule",
                "label_fr": "Lheraule"
            },
            {
                "value": 30485,
                "label": "Caillouel-Crepigny",
                "label_ar": "Caillouel-Crepigny",
                "label_fr": "Caillouel-Crépigny"
            },
            {
                "value": 30486,
                "label": "Marigny-en-Orxois",
                "label_ar": "Marigny-en-Orxois",
                "label_fr": "Marigny-en-Orxois"
            },
            {
                "value": 30487,
                "label": "Ponchon",
                "label_ar": "بونشون",
                "label_fr": "Ponchon"
            },
            {
                "value": 30488,
                "label": "Meteren",
                "label_ar": "متر",
                "label_fr": "Meteren"
            },
            {
                "value": 30489,
                "label": "Outines",
                "label_ar": "Outines",
                "label_fr": "Outines"
            },
            {
                "value": 30490,
                "label": "Gaye",
                "label_ar": "غاي",
                "label_fr": "Gaye"
            },
            {
                "value": 30491,
                "label": "Letanne",
                "label_ar": "ليتان",
                "label_fr": "Letanne"
            },
            {
                "value": 30492,
                "label": "Moslins",
                "label_ar": "موسلينز",
                "label_fr": "Musulmans"
            },
            {
                "value": 30493,
                "label": "Boeschepe",
                "label_ar": "بوشيبي",
                "label_fr": "Boeschepe"
            },
            {
                "value": 30494,
                "label": "Trith-Saint-Leger",
                "label_ar": "تريث سانت ليجيه",
                "label_fr": "Trith-Saint-Léger"
            },
            {
                "value": 30495,
                "label": "Maisnil-les-Ruitz",
                "label_ar": "ميسنيل ليه روتز",
                "label_fr": "Maisnil-les-Ruitz"
            },
            {
                "value": 30496,
                "label": "Sarry",
                "label_ar": "ساري",
                "label_fr": "Sarry"
            },
            {
                "value": 30497,
                "label": "Thennes",
                "label_ar": "ثينيس",
                "label_fr": "Thennes"
            },
            {
                "value": 30498,
                "label": "Morangis",
                "label_ar": "مورانجيس",
                "label_fr": "Morangis"
            },
            {
                "value": 30499,
                "label": "Sept-Saulx",
                "label_ar": "سبتمبر- شاول",
                "label_fr": "Sept-Saulx"
            },
            {
                "value": 30500,
                "label": "Dizy",
                "label_ar": "دوار",
                "label_fr": "Dizy"
            },
            {
                "value": 30501,
                "label": "La Groise",
                "label_ar": "لا جرويس",
                "label_fr": "La Groise"
            },
            {
                "value": 30502,
                "label": "Bugnicourt",
                "label_ar": "بوغنيكور",
                "label_fr": "Bugnicourt"
            },
            {
                "value": 30503,
                "label": "Bermeries",
                "label_ar": "بيرميريس",
                "label_fr": "Bermeries"
            },
            {
                "value": 30504,
                "label": "Wargnies-le-Petit",
                "label_ar": "Wargnies-le-Petit",
                "label_fr": "Wargnies-le-Petit"
            },
            {
                "value": 30505,
                "label": "Callac",
                "label_ar": "كالاك",
                "label_fr": "Callac"
            },
            {
                "value": 30506,
                "label": "Hirel",
                "label_ar": "هيرل",
                "label_fr": "Hirel"
            },
            {
                "value": 30507,
                "label": "Plestan",
                "label_ar": "بليستان",
                "label_fr": "Plestan"
            },
            {
                "value": 30508,
                "label": "Saint-Briac-sur-Mer",
                "label_ar": "سان برياك سور مير",
                "label_fr": "Saint-Briac-sur-Mer"
            },
            {
                "value": 30509,
                "label": "Laurenan",
                "label_ar": "لورينان",
                "label_fr": "Laurenan"
            },
            {
                "value": 30510,
                "label": "Saint-Pere",
                "label_ar": "سان بير",
                "label_fr": "Saint-Père"
            },
            {
                "value": 30511,
                "label": "Neuville",
                "label_ar": "نوفيل",
                "label_fr": "Neuville"
            },
            {
                "value": 30512,
                "label": "Plozevet",
                "label_ar": "بلوزفيت",
                "label_fr": "Plozevet"
            },
            {
                "value": 30513,
                "label": "Plesidy",
                "label_ar": "بليسيدي",
                "label_fr": "Plesidy"
            },
            {
                "value": 30514,
                "label": "Kerity",
                "label_ar": "كيريتي",
                "label_fr": "Kerity"
            },
            {
                "value": 30515,
                "label": "Saint-Christophe-de-Valains",
                "label_ar": "سان كريستوف دي فالينز",
                "label_fr": "Saint-Christophe-de-Valains"
            },
            {
                "value": 30516,
                "label": "Haute-Goulaine",
                "label_ar": "هوت جولين",
                "label_fr": "Haute-Goulaine"
            },
            {
                "value": 30517,
                "label": "Saint-Martin-des-Champs",
                "label_ar": "سان مارتن دي تشامب",
                "label_fr": "Saint-Martin-des-Champs"
            },
            {
                "value": 30518,
                "label": "Larchant",
                "label_ar": "لارسانت",
                "label_fr": "Larchant"
            },
            {
                "value": 30519,
                "label": "Les Granges",
                "label_ar": "ليه جرانجز",
                "label_fr": "Les Granges"
            },
            {
                "value": 30520,
                "label": "Arville",
                "label_ar": "ارفيل",
                "label_fr": "Arville"
            },
            {
                "value": 30521,
                "label": "Marles-en-Brie",
                "label_ar": "مارليس أون بري",
                "label_fr": "Marles-en-Brie"
            },
            {
                "value": 30522,
                "label": "Bondoufle",
                "label_ar": "Bondoufle",
                "label_fr": "Bondoufle"
            },
            {
                "value": 30523,
                "label": "Villiers-sur-Seine",
                "label_ar": "فيلير سور سين",
                "label_fr": "Villiers-sur-Seine"
            },
            {
                "value": 30524,
                "label": "Aigremont",
                "label_ar": "ايجريمونت",
                "label_fr": "Aigremont"
            },
            {
                "value": 30525,
                "label": "Bray-et-Lu",
                "label_ar": "براي لو",
                "label_fr": "Bray-et-Lu"
            },
            {
                "value": 30526,
                "label": "Medis",
                "label_ar": "ميديس",
                "label_fr": "Medis"
            },
            {
                "value": 30527,
                "label": "Vigeois",
                "label_ar": "فيجوا",
                "label_fr": "Vigeois"
            },
            {
                "value": 30528,
                "label": "Beyssenac",
                "label_ar": "بيسيناك",
                "label_fr": "Beyssenac"
            },
            {
                "value": 30529,
                "label": "Saint-Martin-Terressus",
                "label_ar": "سان مارتن تيريسوس",
                "label_fr": "Saint-Martin-Terressus"
            },
            {
                "value": 30530,
                "label": "Brouqueyran",
                "label_ar": "بروكيران",
                "label_fr": "Brouqueyran"
            },
            {
                "value": 30531,
                "label": "Saint-Antoine-Cumond",
                "label_ar": "سان انطوان كوموند",
                "label_fr": "Saint-Antoine-Cumond"
            },
            {
                "value": 30532,
                "label": "Ardiege",
                "label_ar": "Ardiege",
                "label_fr": "Ardiege"
            },
            {
                "value": 30533,
                "label": "Boussenac",
                "label_ar": "بوسيناك",
                "label_fr": "Boussenac"
            },
            {
                "value": 30534,
                "label": "La Barthe",
                "label_ar": "لا بارث",
                "label_fr": "La Barthe"
            },
            {
                "value": 30535,
                "label": "Sautel",
                "label_ar": "سوتيل",
                "label_fr": "Sautel"
            },
            {
                "value": 30536,
                "label": "Chervettes",
                "label_ar": "Chervettes",
                "label_fr": "Chervettes"
            },
            {
                "value": 30537,
                "label": "Habas",
                "label_ar": "هاباس",
                "label_fr": "Habas"
            },
            {
                "value": 30538,
                "label": "Saint-Perdon",
                "label_ar": "سان بيردون",
                "label_fr": "Saint-Perdon"
            },
            {
                "value": 30539,
                "label": "Campagne",
                "label_ar": "كامباني",
                "label_fr": "Campagne"
            },
            {
                "value": 30540,
                "label": "Hossegor",
                "label_ar": "هوسيجور",
                "label_fr": "Hossegor"
            },
            {
                "value": 30541,
                "label": "Saint-Leon-sur-l'Isle",
                "label_ar": "Saint-Leon-sur-l'Isle",
                "label_fr": "Saint-Léon-sur-l'Isle"
            },
            {
                "value": 30542,
                "label": "Gourvillette",
                "label_ar": "جورفيليت",
                "label_fr": "Gourvillette"
            },
            {
                "value": 30543,
                "label": "Saint-Pierre-de-Maille",
                "label_ar": "سان بيير دي ميل",
                "label_fr": "Saint-Pierre-de-Maille"
            },
            {
                "value": 30544,
                "label": "Saint-Hilaire-les-Courbes",
                "label_ar": "سان هيلير لي كوربيه",
                "label_fr": "Saint-Hilaire-les-Courbes"
            },
            {
                "value": 30545,
                "label": "Lalinde",
                "label_ar": "لاليندي",
                "label_fr": "Lalinde"
            },
            {
                "value": 30546,
                "label": "Manot",
                "label_ar": "مانوت",
                "label_fr": "Manot"
            },
            {
                "value": 30547,
                "label": "Perpezac-le-Noir",
                "label_ar": "Perpezac-le-Noir",
                "label_fr": "Perpezac-le-Noir"
            },
            {
                "value": 30548,
                "label": "Saint-Saviol",
                "label_ar": "سان سافيول",
                "label_fr": "Saint-Saviol"
            },
            {
                "value": 30549,
                "label": "Ainhoa",
                "label_ar": "عينهوا",
                "label_fr": "Ainhoa"
            },
            {
                "value": 30550,
                "label": "Mees",
                "label_ar": "ميس",
                "label_fr": "Mees"
            },
            {
                "value": 30551,
                "label": "Hagetmau",
                "label_ar": "هاجيتماو",
                "label_fr": "Hagetmau"
            },
            {
                "value": 30552,
                "label": "Lacq",
                "label_ar": "لا سك",
                "label_fr": "Lacq"
            },
            {
                "value": 30553,
                "label": "Mendionde",
                "label_ar": "مينديوندي",
                "label_fr": "Mendionde"
            },
            {
                "value": 30554,
                "label": "Cauneille",
                "label_ar": "كونييل",
                "label_fr": "Cauneille"
            },
            {
                "value": 30555,
                "label": "Les Eglisottes-et-Chalaures",
                "label_ar": "Les Eglisottes-et-Chalaures",
                "label_fr": "Les Eglisottes-et-Chalaures"
            },
            {
                "value": 30556,
                "label": "Chateauneuf-Grasse",
                "label_ar": "شاتونوف جراس",
                "label_fr": "Châteauneuf-Grasse"
            },
            {
                "value": 30557,
                "label": "Aspremont",
                "label_ar": "Aspremont",
                "label_fr": "Aspremont"
            },
            {
                "value": 30558,
                "label": "Castellar",
                "label_ar": "كاستيلار",
                "label_fr": "Castellar"
            },
            {
                "value": 30559,
                "label": "Villars-sur-Var",
                "label_ar": "فيلار سور فار",
                "label_fr": "Villars-sur-Var"
            },
            {
                "value": 30560,
                "label": "Gorbio",
                "label_ar": "جوربيو",
                "label_fr": "Gorbio"
            },
            {
                "value": 30561,
                "label": "Claviers",
                "label_ar": "كلافييرز",
                "label_fr": "Claviers"
            },
            {
                "value": 30562,
                "label": "Colomars",
                "label_ar": "كولومارس",
                "label_fr": "Colomars"
            },
            {
                "value": 30563,
                "label": "Ramatuelle",
                "label_ar": "راماتويل",
                "label_fr": "Ramatuelle"
            },
            {
                "value": 30564,
                "label": "Saint-Etienne-de-Tinee",
                "label_ar": "سانت إتيان دي تايني",
                "label_fr": "Saint-Etienne-de-Tinee"
            },
            {
                "value": 30565,
                "label": "La Napoule",
                "label_ar": "لا نابول",
                "label_fr": "La Napoule"
            },
            {
                "value": 30566,
                "label": "Boissy-le-Girard",
                "label_ar": "بويسي لو جيرارد",
                "label_fr": "Boissy-le-Girard"
            },
            {
                "value": 30567,
                "label": "Etrechy",
                "label_ar": "إتريشي",
                "label_fr": "Étréchie"
            },
            {
                "value": 30568,
                "label": "Lahosse",
                "label_ar": "لاهوس",
                "label_fr": "Lahosse"
            },
            {
                "value": 30569,
                "label": "Souraide",
                "label_ar": "سوريد",
                "label_fr": "Souraide"
            },
            {
                "value": 30570,
                "label": "Chaillevette",
                "label_ar": "تشيليفيت",
                "label_fr": "Chaillevette"
            },
            {
                "value": 30571,
                "label": "Monferran-Plaves",
                "label_ar": "Monferran-Plaves",
                "label_fr": "Monferran-Plaves"
            },
            {
                "value": 30572,
                "label": "Sarrant",
                "label_ar": "سارانت",
                "label_fr": "Sarrant"
            },
            {
                "value": 30573,
                "label": "Saint-Projet",
                "label_ar": "سان بروجيت",
                "label_fr": "Saint-Projet"
            },
            {
                "value": 30574,
                "label": "Launay",
                "label_ar": "لوناي",
                "label_fr": "Launay"
            },
            {
                "value": 30575,
                "label": "Dabo",
                "label_ar": "دابو",
                "label_fr": "Dabo"
            },
            {
                "value": 30576,
                "label": "Piblange",
                "label_ar": "بيبلينج",
                "label_fr": "Piblange"
            },
            {
                "value": 30577,
                "label": "Moyenmoutier",
                "label_ar": "موينماوتييه",
                "label_fr": "Moyenmoutier"
            },
            {
                "value": 30578,
                "label": "Ballainvilliers",
                "label_ar": "Ballainvilliers",
                "label_fr": "Ballainvilliers"
            },
            {
                "value": 30579,
                "label": "Guernes",
                "label_ar": "غيرنيس",
                "label_fr": "Guernes"
            },
            {
                "value": 30580,
                "label": "Moulinet",
                "label_ar": "مولينيت",
                "label_fr": "Moulinet"
            },
            {
                "value": 30581,
                "label": "Lezay",
                "label_ar": "ليزاي",
                "label_fr": "Lezay"
            },
            {
                "value": 30582,
                "label": "Ychoux",
                "label_ar": "يشوكس",
                "label_fr": "Ychoux"
            },
            {
                "value": 30583,
                "label": "Croutelle",
                "label_ar": "كروتيل",
                "label_fr": "Croutelle"
            },
            {
                "value": 30584,
                "label": "Availles-Limouzine",
                "label_ar": "أفايل ليموزين",
                "label_fr": "Availles-Limouzine"
            },
            {
                "value": 30585,
                "label": "Beaumont",
                "label_ar": "بومونت",
                "label_fr": "Beaumont"
            },
            {
                "value": 30586,
                "label": "Lanne",
                "label_ar": "لان",
                "label_fr": "Lanne"
            },
            {
                "value": 30587,
                "label": "Prats-de-Carlux",
                "label_ar": "براتس دي كارلوكس",
                "label_fr": "Prats-de-Carlux"
            },
            {
                "value": 30588,
                "label": "Borderes-et-Lamensans",
                "label_ar": "الحدود وآخرون لامنسانس",
                "label_fr": "Borderes-et-Lamensans"
            },
            {
                "value": 30589,
                "label": "Reynies",
                "label_ar": "رينيز",
                "label_fr": "Reynies"
            },
            {
                "value": 30590,
                "label": "Cintegabelle",
                "label_ar": "كونتينتابيل",
                "label_fr": "Cintegabelle"
            },
            {
                "value": 30591,
                "label": "Aussevielle",
                "label_ar": "أوسيفيل",
                "label_fr": "Aussevielle"
            },
            {
                "value": 30592,
                "label": "Maurrin",
                "label_ar": "مورين",
                "label_fr": "Maurrin"
            },
            {
                "value": 30593,
                "label": "Lamagistere",
                "label_ar": "لاماغيستير",
                "label_fr": "Lamagistère"
            },
            {
                "value": 30594,
                "label": "Saubens",
                "label_ar": "سوبين",
                "label_fr": "Saubens"
            },
            {
                "value": 30595,
                "label": "Mirande",
                "label_ar": "ميراند",
                "label_fr": "Mirande"
            },
            {
                "value": 30596,
                "label": "Saint-Martin-le-Pin",
                "label_ar": "سان مارتن لو بين",
                "label_fr": "Saint-Martin-le-Pin"
            },
            {
                "value": 30597,
                "label": "Nontron",
                "label_ar": "نونترون",
                "label_fr": "Nontron"
            },
            {
                "value": 30598,
                "label": "Saint-Chamassy",
                "label_ar": "سانت شماسي",
                "label_fr": "Saint-Chamassy"
            },
            {
                "value": 30599,
                "label": "Aureilhan",
                "label_ar": "أوريلهان",
                "label_fr": "Aureilhan"
            },
            {
                "value": 30600,
                "label": "Saint-Julien-en-Born",
                "label_ar": "سان جوليان إن بورن",
                "label_fr": "Saint-Julien-en-Born"
            },
            {
                "value": 30601,
                "label": "Veyrac",
                "label_ar": "فيراك",
                "label_fr": "Veyrac"
            },
            {
                "value": 30602,
                "label": "Thauron",
                "label_ar": "ثارون",
                "label_fr": "Thauron"
            },
            {
                "value": 30603,
                "label": "Villedoux",
                "label_ar": "فيليدوكس",
                "label_fr": "Villedoux"
            },
            {
                "value": 30604,
                "label": "Bassillac",
                "label_ar": "باسيلاك",
                "label_fr": "Bassillac"
            },
            {
                "value": 30605,
                "label": "Cancon",
                "label_ar": "كانكون",
                "label_fr": "Cancon"
            },
            {
                "value": 30606,
                "label": "Cahuzac",
                "label_ar": "كاهوزاك",
                "label_fr": "Cahuzac"
            },
            {
                "value": 30607,
                "label": "Sergeac",
                "label_ar": "سيرجيك",
                "label_fr": "Sergeac"
            },
            {
                "value": 30608,
                "label": "Marignac",
                "label_ar": "مارينياك",
                "label_fr": "Marignac"
            },
            {
                "value": 30609,
                "label": "Saint-Laurent-Bretagne",
                "label_ar": "سان لوران بريتاني",
                "label_fr": "Saint-Laurent-Bretagne"
            },
            {
                "value": 30610,
                "label": "Menesplet",
                "label_ar": "مينيسبليت",
                "label_fr": "Menesplet"
            },
            {
                "value": 30611,
                "label": "Garrevaques",
                "label_ar": "Garrevaques",
                "label_fr": "Garrevaques"
            },
            {
                "value": 30612,
                "label": "La Cavalerie",
                "label_ar": "لا كافاليري",
                "label_fr": "La Cavalerie"
            },
            {
                "value": 30613,
                "label": "Monclar-de-Quercy",
                "label_ar": "مونكلار دي كيرسي",
                "label_fr": "Monclar-de-Quercy"
            },
            {
                "value": 30614,
                "label": "Compans",
                "label_ar": "شركات",
                "label_fr": "Compans"
            },
            {
                "value": 30615,
                "label": "Parc-d'Anxtot",
                "label_ar": "بارك دانكستوت",
                "label_fr": "Parc-d'Anxtot"
            },
            {
                "value": 30616,
                "label": "Yves",
                "label_ar": "إيف",
                "label_fr": "Yves"
            },
            {
                "value": 30617,
                "label": "Ricquebourg",
                "label_ar": "ريكويبورج",
                "label_fr": "Ricquebourg"
            },
            {
                "value": 30618,
                "label": "Heres",
                "label_ar": "هيريس",
                "label_fr": "Heres"
            },
            {
                "value": 30619,
                "label": "Auriac",
                "label_ar": "أورياك",
                "label_fr": "Auriac"
            },
            {
                "value": 30620,
                "label": "Jonvilliers",
                "label_ar": "جونفيلييه",
                "label_fr": "Jonvilliers"
            },
            {
                "value": 30621,
                "label": "Breles",
                "label_ar": "بريل",
                "label_fr": "Breles"
            },
            {
                "value": 30622,
                "label": "Vauxbuin",
                "label_ar": "Vauxbuin",
                "label_fr": "Vauxbuin"
            },
            {
                "value": 30623,
                "label": "Orcet",
                "label_ar": "أورسيت",
                "label_fr": "Orcet"
            },
            {
                "value": 30624,
                "label": "Saint-Aubin-de-Lanquais",
                "label_ar": "سان أوبين دي لانكوي",
                "label_fr": "Saint-Aubin-de-Lanquais"
            },
            {
                "value": 30625,
                "label": "Cheyssieu",
                "label_ar": "Cheyssieu",
                "label_fr": "Cheyssieu"
            },
            {
                "value": 30626,
                "label": "Saint-Martin-Choquel",
                "label_ar": "سان مارتن شوكيل",
                "label_fr": "Saint-Martin-Choquel"
            },
            {
                "value": 30627,
                "label": "Clarques",
                "label_ar": "كلاركس",
                "label_fr": "Clarques"
            },
            {
                "value": 30628,
                "label": "Commes",
                "label_ar": "كوميس",
                "label_fr": "Commes"
            },
            {
                "value": 30629,
                "label": "Fontvieille",
                "label_ar": "Fontvieille",
                "label_fr": "Fontvieille"
            },
            {
                "value": 30630,
                "label": "Crevechamps",
                "label_ar": "Crevechamps",
                "label_fr": "Crevechamps"
            },
            {
                "value": 30631,
                "label": "Monnetier-Mornex",
                "label_ar": "Monnetier-Mornex",
                "label_fr": "Monnetier-Mornex"
            },
            {
                "value": 30632,
                "label": "Cuxac-d'Aude",
                "label_ar": "كوزاك دود",
                "label_fr": "Cuxac-d'Aude"
            },
            {
                "value": 30633,
                "label": "Lauzerte",
                "label_ar": "لوزرت",
                "label_fr": "Lauzerte"
            },
            {
                "value": 30634,
                "label": "Montaigu-de-Quercy",
                "label_ar": "مونتايجو دي كيرسي",
                "label_fr": "Montaigu-de-Quercy"
            },
            {
                "value": 30635,
                "label": "Bailleau-l'Évêque",
                "label_ar": "Bailleau-l'Évêque",
                "label_fr": "Bailleau-l'Évêque"
            },
            {
                "value": 30636,
                "label": "Malincourt",
                "label_ar": "مالينكور",
                "label_fr": "Malincourt"
            },
            {
                "value": 30637,
                "label": "Coquelles",
                "label_ar": "Coquelles",
                "label_fr": "Coquelles"
            },
            {
                "value": 30638,
                "label": "Voulangis",
                "label_ar": "فولانجيس",
                "label_fr": "Voulangis"
            },
            {
                "value": 30639,
                "label": "Saint-Jean-des-Mauvrets",
                "label_ar": "سان جان دي موفريتس",
                "label_fr": "Saint-Jean-des-Mauvrets"
            },
            {
                "value": 30640,
                "label": "Saint-Crespin-sur-Moine",
                "label_ar": "سان كريسبين سور موين",
                "label_fr": "Saint-Crespin-sur-Moine"
            },
            {
                "value": 30641,
                "label": "Champigne",
                "label_ar": "شامبين",
                "label_fr": "Champigné"
            },
            {
                "value": 30642,
                "label": "Andard",
                "label_ar": "أندارد",
                "label_fr": "Andard"
            },
            {
                "value": 30643,
                "label": "Orbec",
                "label_ar": "أوربيك",
                "label_fr": "Orbec"
            },
            {
                "value": 30644,
                "label": "Cuverville",
                "label_ar": "كوفرفيل",
                "label_fr": "Cuverville"
            },
            {
                "value": 30645,
                "label": "Saint-Gervais-en-Belin",
                "label_ar": "سان جيرفيه أون بيلين",
                "label_fr": "Saint-Gervais-en-Belin"
            },
            {
                "value": 30646,
                "label": "Montgirod",
                "label_ar": "مونتغيرود",
                "label_fr": "Montgirod"
            },
            {
                "value": 30647,
                "label": "Charmes-sur-Rhone",
                "label_ar": "تشارمز سور رون",
                "label_fr": "Charmes-sur-Rhône"
            },
            {
                "value": 30648,
                "label": "Sauvagney",
                "label_ar": "سوفاجني",
                "label_fr": "Sauvagney"
            },
            {
                "value": 30649,
                "label": "Champagnole",
                "label_ar": "الشمبانيا",
                "label_fr": "Champagnole"
            },
            {
                "value": 30650,
                "label": "Port-en-Bessin-Huppain",
                "label_ar": "بورت أون بيسين هوبين",
                "label_fr": "Port-en-Bessin-Huppain"
            },
            {
                "value": 30651,
                "label": "Saint-Joachim",
                "label_ar": "القديس يواكيم",
                "label_fr": "Saint-Joachim"
            },
            {
                "value": 30652,
                "label": "Duneau",
                "label_ar": "دانو",
                "label_fr": "Duneau"
            },
            {
                "value": 30653,
                "label": "Courdemanche",
                "label_ar": "كورديمانش",
                "label_fr": "Courdemanche"
            },
            {
                "value": 30654,
                "label": "Paradou",
                "label_ar": "بارادو",
                "label_fr": "Paradou"
            },
            {
                "value": 30655,
                "label": "Allonnes",
                "label_ar": "ألونيس",
                "label_fr": "Allonnes"
            },
            {
                "value": 30656,
                "label": "Lamanon",
                "label_ar": "لامانون",
                "label_fr": "Lamanon"
            },
            {
                "value": 30657,
                "label": "Saint-Barnabe",
                "label_ar": "سان بارنابي",
                "label_fr": "Saint-Barnabe"
            },
            {
                "value": 30658,
                "label": "Verdon",
                "label_ar": "فيردون",
                "label_fr": "Verdon"
            },
            {
                "value": 30659,
                "label": "Maleville",
                "label_ar": "مالفيل",
                "label_fr": "Maleville"
            },
            {
                "value": 30660,
                "label": "Saint-Sauveur-dAunis",
                "label_ar": "سان سوفور دوني",
                "label_fr": "Saint-Sauveur-d'Aunis"
            },
            {
                "value": 30661,
                "label": "Marsais",
                "label_ar": "مارسيه",
                "label_fr": "Marsais"
            },
            {
                "value": 30662,
                "label": "Taillebourg",
                "label_ar": "تيلليبورج",
                "label_fr": "Taillebourg"
            },
            {
                "value": 30663,
                "label": "Treffieux",
                "label_ar": "تريفيو",
                "label_fr": "Treffieux"
            },
            {
                "value": 30664,
                "label": "Moinville-la-Jeulin",
                "label_ar": "موينفيل لا جيولين",
                "label_fr": "Moinville-la-Jeulin"
            },
            {
                "value": 30665,
                "label": "Chatelet",
                "label_ar": "شاتيليت",
                "label_fr": "Châtelet"
            },
            {
                "value": 30666,
                "label": "Val-dIzé",
                "label_ar": "فال ديزي",
                "label_fr": "Val-d'Izé"
            },
            {
                "value": 30667,
                "label": "Beaumont-sur-Sarthe",
                "label_ar": "بومونت سور سارث",
                "label_fr": "Beaumont-sur-Sarthe"
            },
            {
                "value": 30668,
                "label": "Aubigne-Racan",
                "label_ar": "أوبين راكان",
                "label_fr": "Aubigné-Racan"
            },
            {
                "value": 30669,
                "label": "Requeil",
                "label_ar": "قداس",
                "label_fr": "Requeil"
            },
            {
                "value": 30670,
                "label": "Corroy",
                "label_ar": "Corroy",
                "label_fr": "Corroy"
            },
            {
                "value": 30671,
                "label": "Richelieu",
                "label_ar": "ريشيليو",
                "label_fr": "Richelieu"
            },
            {
                "value": 30672,
                "label": "La Chevallerais",
                "label_ar": "لا Chevallerais",
                "label_fr": "La Chevallerais"
            },
            {
                "value": 30673,
                "label": "Ressons-le-Long",
                "label_ar": "ريسونز لو لونج",
                "label_fr": "Ressons-le-Long"
            },
            {
                "value": 30674,
                "label": "Hargicourt",
                "label_ar": "هارجيكورت",
                "label_fr": "Hargicourt"
            },
            {
                "value": 30675,
                "label": "Saint-Eloy-les-Mines",
                "label_ar": "سان إلوي ليه مين",
                "label_fr": "Saint-Eloy-les-Mines"
            },
            {
                "value": 30676,
                "label": "Beaurieux",
                "label_ar": "بوريو",
                "label_fr": "Beaurieux"
            },
            {
                "value": 30677,
                "label": "Villeneuve-sur-Yonne",
                "label_ar": "فيلنوف سور يون",
                "label_fr": "Villeneuve-sur-Yonne"
            },
            {
                "value": 30678,
                "label": "Faulx",
                "label_ar": "فولكس",
                "label_fr": "Faulx"
            },
            {
                "value": 30679,
                "label": "Simorre",
                "label_ar": "سيمور",
                "label_fr": "Simorre"
            },
            {
                "value": 30680,
                "label": "Grossoeuvre",
                "label_ar": "جروسووفر",
                "label_fr": "Grossoeuvre"
            },
            {
                "value": 30681,
                "label": "Gourlizon",
                "label_ar": "جورليزون",
                "label_fr": "Gourlizon"
            },
            {
                "value": 30682,
                "label": "Ollezy",
                "label_ar": "Ollezy",
                "label_fr": "Ollezy"
            },
            {
                "value": 30683,
                "label": "Arches",
                "label_ar": "أقواس",
                "label_fr": "Arches"
            },
            {
                "value": 30684,
                "label": "Mondragon",
                "label_ar": "موندراجون",
                "label_fr": "Mondragon"
            },
            {
                "value": 30685,
                "label": "Le Chaylard",
                "label_ar": "لو تشايلارد",
                "label_fr": "Le Chaylard"
            },
            {
                "value": 30686,
                "label": "Saint-Germain-Lembron",
                "label_ar": "سان جيرمان ليمبرون",
                "label_fr": "Saint-Germain-Lembron"
            },
            {
                "value": 30687,
                "label": "Giat",
                "label_ar": "جيات",
                "label_fr": "Giat"
            },
            {
                "value": 30688,
                "label": "Ardes",
                "label_ar": "أرديس",
                "label_fr": "Ardes"
            },
            {
                "value": 30689,
                "label": "Chapdes-Beaufort",
                "label_ar": "تشابديس بوفورت",
                "label_fr": "Chapdes-Beaufort"
            },
            {
                "value": 30690,
                "label": "Nebouzat",
                "label_ar": "نبوزات",
                "label_fr": "Nebouzat"
            },
            {
                "value": 30691,
                "label": "Pontgibaud",
                "label_ar": "بونتجيبود",
                "label_fr": "Pontgibaud"
            },
            {
                "value": 30692,
                "label": "Neronde-sur-Dore",
                "label_ar": "نيروند سور دوري",
                "label_fr": "Néronde-sur-Doré"
            },
            {
                "value": 30693,
                "label": "Pleurs",
                "label_ar": "المطلقون",
                "label_fr": "Pleurs"
            },
            {
                "value": 30694,
                "label": "Quievrecourt",
                "label_ar": "Quievrecourt",
                "label_fr": "Quievrecourt"
            },
            {
                "value": 30695,
                "label": "Saint-Denis-sur-Sarthon",
                "label_ar": "سان دوني سور سارثون",
                "label_fr": "Saint-Denis-sur-Sarthon"
            },
            {
                "value": 30696,
                "label": "Sainte-Cecile",
                "label_ar": "سانت سيسيل",
                "label_fr": "Sainte-Cécile"
            },
            {
                "value": 30697,
                "label": "Cauvigny",
                "label_ar": "كوفيني",
                "label_fr": "Cauvigny"
            },
            {
                "value": 30698,
                "label": "Gerard",
                "label_ar": "جيرارد",
                "label_fr": "Gérard"
            },
            {
                "value": 30699,
                "label": "Chatelaines",
                "label_ar": "شاتيلاينز",
                "label_fr": "Châtelaines"
            },
            {
                "value": 30700,
                "label": "Robert",
                "label_ar": "روبرت",
                "label_fr": "Robert"
            },
            {
                "value": 30701,
                "label": "Francois",
                "label_ar": "فرانسوا",
                "label_fr": "François"
            },
            {
                "value": 30702,
                "label": "Houlbec-Cocherel",
                "label_ar": "هولبيك كوشريل",
                "label_fr": "Houlbec-Cocherel"
            },
            {
                "value": 30703,
                "label": "Blacqueville",
                "label_ar": "بلاكوفيل",
                "label_fr": "Blacqueville"
            },
            {
                "value": 30704,
                "label": "Berlaimont",
                "label_ar": "بيرليمونت",
                "label_fr": "Berlaimont"
            },
            {
                "value": 30705,
                "label": "Charly",
                "label_ar": "تشارلي",
                "label_fr": "Charly"
            },
            {
                "value": 30706,
                "label": "Pont-de-Veyle",
                "label_ar": "بونت دي فيل",
                "label_fr": "Pont-de-Veyle"
            },
            {
                "value": 30707,
                "label": "Cruzilles-les-Mepillat",
                "label_ar": "كروزليس ليه ميبيليات",
                "label_fr": "Cruzilles-les-Mepillat"
            },
            {
                "value": 30708,
                "label": "Longueau",
                "label_ar": "لونغو",
                "label_fr": "Longueau"
            },
            {
                "value": 30709,
                "label": "Saint-Georges-sur-Baulche",
                "label_ar": "سان جورج سور بولش",
                "label_fr": "Saint-Georges-sur-Baulche"
            },
            {
                "value": 30710,
                "label": "Berriac",
                "label_ar": "برياك",
                "label_fr": "Berriac"
            },
            {
                "value": 30711,
                "label": "Jaucourt",
                "label_ar": "جوكورت",
                "label_fr": "Jaucourt"
            },
            {
                "value": 30712,
                "label": "Chourgnac",
                "label_ar": "تشورجناك",
                "label_fr": "Chourgnac"
            },
            {
                "value": 30713,
                "label": "Payzac",
                "label_ar": "بايزاك",
                "label_fr": "Payzac"
            },
            {
                "value": 30714,
                "label": "Soumeras",
                "label_ar": "سوميرا",
                "label_fr": "Soumeras"
            },
            {
                "value": 30715,
                "label": "Champier",
                "label_ar": "شامبير",
                "label_fr": "Champier"
            },
            {
                "value": 30716,
                "label": "Brezins",
                "label_ar": "بريزين",
                "label_fr": "Brezins"
            },
            {
                "value": 30717,
                "label": "Le Thieulin",
                "label_ar": "لو ثيولين",
                "label_fr": "Le Thieulin"
            },
            {
                "value": 30718,
                "label": "Frevin-Capelle",
                "label_ar": "فريفين كابيل",
                "label_fr": "Frevin-Capelle"
            },
            {
                "value": 30719,
                "label": "Oisy-le-Verger",
                "label_ar": "Oisy-le-Verger",
                "label_fr": "Oisy-le-Verger"
            },
            {
                "value": 30720,
                "label": "Arquettes-en-Val",
                "label_ar": "Arquettes-en-Val",
                "label_fr": "Arquettes-en-Val"
            },
            {
                "value": 30721,
                "label": "Roquemaure",
                "label_ar": "روكيماور",
                "label_fr": "Roquemaure"
            },
            {
                "value": 30722,
                "label": "Berne",
                "label_ar": "برن",
                "label_fr": "Berne"
            },
            {
                "value": 30723,
                "label": "Esquerdes",
                "label_ar": "Esquerdes",
                "label_fr": "Esquerdes"
            },
            {
                "value": 30724,
                "label": "Origny-Sainte-Benoite",
                "label_ar": "Origny-Sainte-Benoite",
                "label_fr": "Origny-Sainte-Benoite"
            },
            {
                "value": 30725,
                "label": "Buzet-sur-Baise",
                "label_ar": "Buzet-sur-Baise",
                "label_fr": "Buzet-sur-Baïse"
            },
            {
                "value": 30726,
                "label": "Lavardac",
                "label_ar": "لافارداك",
                "label_fr": "Lavardac"
            },
            {
                "value": 30727,
                "label": "Le Pin",
                "label_ar": "لو بين",
                "label_fr": "Le Pin"
            },
            {
                "value": 30728,
                "label": "Recquignies",
                "label_ar": "Recquignies",
                "label_fr": "Recquignies"
            },
            {
                "value": 30729,
                "label": "Braud-et-Saint-Louis",
                "label_ar": "Braud-et-Saint-Louis",
                "label_fr": "Braud-et-Saint-Louis"
            },
            {
                "value": 30730,
                "label": "Aiguillon",
                "label_ar": "ايجيلون",
                "label_fr": "Aiguillon"
            },
            {
                "value": 30731,
                "label": "Medan",
                "label_ar": "ميدان",
                "label_fr": "Medan"
            },
            {
                "value": 30732,
                "label": "Yenne",
                "label_ar": "يين",
                "label_fr": "Yenne"
            },
            {
                "value": 30733,
                "label": "Pujo-le-Plan",
                "label_ar": "بوجو لو بلان",
                "label_fr": "Pujo-le-Plan"
            },
            {
                "value": 30734,
                "label": "Englefontaine",
                "label_ar": "إنجلفونتين",
                "label_fr": "Englefontaine"
            },
            {
                "value": 30735,
                "label": "Saint-Jean-Lasseille",
                "label_ar": "سان جان لاسيليا",
                "label_fr": "Saint-Jean-Lasseille"
            },
            {
                "value": 30736,
                "label": "Rouillac",
                "label_ar": "رويلاك",
                "label_fr": "Rouillac"
            },
            {
                "value": 30737,
                "label": "Le Vaudreuil",
                "label_ar": "لو فودريل",
                "label_fr": "Le Vaudreuil"
            },
            {
                "value": 30738,
                "label": "Falicon",
                "label_ar": "فاليكون",
                "label_fr": "Falicon"
            },
            {
                "value": 30739,
                "label": "Damprichard",
                "label_ar": "دامريتشارد",
                "label_fr": "Damprichard"
            },
            {
                "value": 30740,
                "label": "Fresnes-en-Woevre",
                "label_ar": "فريسنس أون وويفر",
                "label_fr": "Fresnes-en-Woevre"
            },
            {
                "value": 30741,
                "label": "Gommerville",
                "label_ar": "جومرفيل",
                "label_fr": "Gommerville"
            },
            {
                "value": 30742,
                "label": "Mauves-sur-Huisne",
                "label_ar": "موفز سور هويسن",
                "label_fr": "Mauves-sur-Huisne"
            },
            {
                "value": 30743,
                "label": "Nuaille",
                "label_ar": "Nuaille",
                "label_fr": "Nuaille"
            },
            {
                "value": 30744,
                "label": "Saint-Bernard",
                "label_ar": "سانت برنارد",
                "label_fr": "Saint-Bernard"
            },
            {
                "value": 30745,
                "label": "Sainte-Radegonde",
                "label_ar": "سانت راديغوند",
                "label_fr": "Sainte-Radegonde"
            },
            {
                "value": 30746,
                "label": "Pont-de-Beauvoisin",
                "label_ar": "Pont-de-Beauvoisin",
                "label_fr": "Pont-de-Beauvoisin"
            },
            {
                "value": 30747,
                "label": "Montagny-sur-Grosne",
                "label_ar": "مونتاني سور جروسن",
                "label_fr": "Montagny-sur-Grosne"
            },
            {
                "value": 30748,
                "label": "Octeville-sur-Mer",
                "label_ar": "أوكتفيل سور مير",
                "label_fr": "Octeville-sur-Mer"
            },
            {
                "value": 30749,
                "label": "Ban-de-Laveline",
                "label_ar": "بان دي لافلين",
                "label_fr": "Ban-de-Laveline"
            },
            {
                "value": 30750,
                "label": "Luisant",
                "label_ar": "لويسانت",
                "label_fr": "Luisant"
            },
            {
                "value": 30751,
                "label": "Faverolles-la-Campagne",
                "label_ar": "Faverolles-la-Campagne",
                "label_fr": "Faverolles-la-Campagne"
            },
            {
                "value": 30752,
                "label": "Fregouville",
                "label_ar": "فريغوفيل",
                "label_fr": "Fregouville"
            },
            {
                "value": 30753,
                "label": "Varennes",
                "label_ar": "فارينيس",
                "label_fr": "Varennes"
            },
            {
                "value": 30754,
                "label": "Lacapelle-Marival",
                "label_ar": "لاكابيل ماريفال",
                "label_fr": "Lacapelle-Marival"
            },
            {
                "value": 30755,
                "label": "Latronquiere",
                "label_ar": "لاترونكيير",
                "label_fr": "Latronquière"
            },
            {
                "value": 30756,
                "label": "Lamativie",
                "label_ar": "لاماتيفي",
                "label_fr": "Lamativie"
            },
            {
                "value": 30757,
                "label": "Saint-Privat-de-Vallongue",
                "label_ar": "سان بريفات دي فالونج",
                "label_fr": "Saint-Privat-de-Vallongue"
            },
            {
                "value": 30758,
                "label": "Gagnieres",
                "label_ar": "جانيريس",
                "label_fr": "Gagnières"
            },
            {
                "value": 30759,
                "label": "Pompadour",
                "label_ar": "بومبادور",
                "label_fr": "Banane"
            },
            {
                "value": 30760,
                "label": "Mernel",
                "label_ar": "ميرنيل",
                "label_fr": "Mernel"
            },
            {
                "value": 30761,
                "label": "Louvigne-de-Bais",
                "label_ar": "لوفين دي بايس",
                "label_fr": "Louvigne-de-Bais"
            },
            {
                "value": 30762,
                "label": "Mittersheim",
                "label_ar": "ميترشيم",
                "label_fr": "Mittersheim"
            },
            {
                "value": 30763,
                "label": "Malleloy",
                "label_ar": "ماليلوي",
                "label_fr": "Malleloy"
            },
            {
                "value": 30764,
                "label": "Mertzwiller",
                "label_ar": "ميرتزويلر",
                "label_fr": "Mertzwiller"
            },
            {
                "value": 30765,
                "label": "Zinswiller",
                "label_ar": "Zinswiller",
                "label_fr": "Zinswiller"
            },
            {
                "value": 30766,
                "label": "Chatel-Saint-Germain",
                "label_ar": "شاتيل سان جيرمان",
                "label_fr": "Châtel-Saint-Germain"
            },
            {
                "value": 30767,
                "label": "Basse-Ham",
                "label_ar": "باس هام",
                "label_fr": "Basse-Ham"
            },
            {
                "value": 30768,
                "label": "Clouange",
                "label_ar": "كلوانج",
                "label_fr": "Clouange"
            },
            {
                "value": 30769,
                "label": "Lagardere",
                "label_ar": "لاغاردير",
                "label_fr": "Lagardère"
            },
            {
                "value": 30770,
                "label": "Vauchelles-les-Domart",
                "label_ar": "Vauchelles-les-Domart",
                "label_fr": "Vauchelles-les-Domart"
            },
            {
                "value": 30771,
                "label": "Fressenneville",
                "label_ar": "فريسنفيل",
                "label_fr": "Fressenneville"
            },
            {
                "value": 30772,
                "label": "Crevecoeur-le-Grand",
                "label_ar": "Crevecoeur-le-Grand",
                "label_fr": "Crèvecœur-le-Grand"
            },
            {
                "value": 30773,
                "label": "Nouvion-le-Vineux",
                "label_ar": "Nouvion-le-Vineux",
                "label_fr": "Nouvion-le-Vineux"
            },
            {
                "value": 30774,
                "label": "La Neuville-d'Aumont",
                "label_ar": "لا نوفيل ديومون",
                "label_fr": "La Neuville-d'Aumont"
            },
            {
                "value": 30775,
                "label": "Feuquieres-en-Vimeu",
                "label_ar": "Feuquieres-en-Vimeu",
                "label_fr": "Feuquières-en-Vimeu"
            },
            {
                "value": 30776,
                "label": "Milly-sur-Therain",
                "label_ar": "ميلي سور ثرين",
                "label_fr": "Milly-sur-Therain"
            },
            {
                "value": 30777,
                "label": "Fresneaux-Montchevreuil",
                "label_ar": "فريسنو مونتشيفرويل",
                "label_fr": "Fresneaux-Montchevreuil"
            },
            {
                "value": 30778,
                "label": "Saint-Riquier",
                "label_ar": "سان ريكيه",
                "label_fr": "Saint-Riquier"
            },
            {
                "value": 30779,
                "label": "Rosieres-en-Santerre",
                "label_ar": "Rosieres-en-Santerre",
                "label_fr": "Rosières-en-Santerre"
            },
            {
                "value": 30780,
                "label": "Peronne",
                "label_ar": "بيرون",
                "label_fr": "Péronne"
            },
            {
                "value": 30781,
                "label": "Gournay-en-Bray",
                "label_ar": "جورناي أون براي",
                "label_fr": "Gournay-en-Bray"
            },
            {
                "value": 30782,
                "label": "Monchy-Humieres",
                "label_ar": "مونشي هوميرس",
                "label_fr": "Monchy-Humières"
            },
            {
                "value": 30783,
                "label": "Grilly",
                "label_ar": "جريلي",
                "label_fr": "Grilly"
            },
            {
                "value": 30784,
                "label": "Neufgrange",
                "label_ar": "نيوفجرانج",
                "label_fr": "Neufgrange"
            },
            {
                "value": 30785,
                "label": "Morainvilliers",
                "label_ar": "مورينفيلييه",
                "label_fr": "Morainvilliers"
            },
            {
                "value": 30786,
                "label": "Saint-Maurice-sur-Dargoire",
                "label_ar": "سان موريس سور دارجوار",
                "label_fr": "Saint-Maurice-sur-Dargoire"
            },
            {
                "value": 30787,
                "label": "Orlienas",
                "label_ar": "أورليناس",
                "label_fr": "Orlienas"
            },
            {
                "value": 30788,
                "label": "Colombier-Saugnieu",
                "label_ar": "كولومبير-سوجنيو",
                "label_fr": "Colombier-Saugnieu"
            },
            {
                "value": 30789,
                "label": "Laudun-lArdoise",
                "label_ar": "Laudun-lArdoise",
                "label_fr": "Laudun-lArdoise"
            },
            {
                "value": 30790,
                "label": "Lessy",
                "label_ar": "ليسي",
                "label_fr": "Lessy"
            },
            {
                "value": 30791,
                "label": "Drace",
                "label_ar": "دريس",
                "label_fr": "Drace"
            },
            {
                "value": 30792,
                "label": "Saint-Bonnet-le-Troncy",
                "label_ar": "سان بونيه لو ترونسي",
                "label_fr": "Saint-Bonnet-le-Troncy"
            },
            {
                "value": 30793,
                "label": "Lantignie",
                "label_ar": "لانتيجني",
                "label_fr": "Lantignie"
            },
            {
                "value": 30794,
                "label": "Les Ardillats",
                "label_ar": "ليه ارديلاتس",
                "label_fr": "Les Ardillats"
            },
            {
                "value": 30795,
                "label": "Rosteig",
                "label_ar": "روستيج",
                "label_fr": "Rosteig"
            },
            {
                "value": 30796,
                "label": "Sewen",
                "label_ar": "مخيط",
                "label_fr": "Sewen"
            },
            {
                "value": 30797,
                "label": "Mussig",
                "label_ar": "موسيج",
                "label_fr": "Mussig"
            },
            {
                "value": 30798,
                "label": "Mittelhausbergen",
                "label_ar": "ميتيلهاوسبيرجين",
                "label_fr": "Mittelhausbergen"
            },
            {
                "value": 30799,
                "label": "Dingsheim",
                "label_ar": "Dingsheim",
                "label_fr": "Dingsheim"
            },
            {
                "value": 30800,
                "label": "Reichstett",
                "label_ar": "Reichstett",
                "label_fr": "Reichstett"
            },
            {
                "value": 30801,
                "label": "Oberhausbergen",
                "label_ar": "أوبرهاوسبيرجين",
                "label_fr": "Oberhausbergen"
            },
            {
                "value": 30802,
                "label": "La Wantzenau",
                "label_ar": "لا وانتزينو",
                "label_fr": "La Wantzenau"
            },
            {
                "value": 30803,
                "label": "Lampertheim",
                "label_ar": "لامبيرثيم",
                "label_fr": "Lampertheim"
            },
            {
                "value": 30804,
                "label": "Schirmeck",
                "label_ar": "شيرميك",
                "label_fr": "Schirmeck"
            },
            {
                "value": 30805,
                "label": "Urmatt",
                "label_ar": "أورمات",
                "label_fr": "Urmatt"
            },
            {
                "value": 30806,
                "label": "Barembach",
                "label_ar": "باريمباخ",
                "label_fr": "Barembach"
            },
            {
                "value": 30807,
                "label": "La Broque",
                "label_ar": "لا بروك",
                "label_fr": "La Broque"
            },
            {
                "value": 30808,
                "label": "Baldersheim",
                "label_ar": "بالدرشيم",
                "label_fr": "Baldersheim"
            },
            {
                "value": 30809,
                "label": "Willer-sur-Thur",
                "label_ar": "ويلر سور ثور",
                "label_fr": "Willer-sur-Thur"
            },
            {
                "value": 30810,
                "label": "Petit-Landau",
                "label_ar": "بيتي لانداو",
                "label_fr": "Petit-Landau"
            },
            {
                "value": 30811,
                "label": "Longes",
                "label_ar": "لونجيس",
                "label_fr": "Longes"
            },
            {
                "value": 30812,
                "label": "Cruseilles",
                "label_ar": "Cruseilles",
                "label_fr": "Cruseilles"
            },
            {
                "value": 30813,
                "label": "La Rixouse",
                "label_ar": "لا ريكسوس",
                "label_fr": "La Rixouse"
            },
            {
                "value": 30814,
                "label": "Chatuzange-le-Goubet",
                "label_ar": "Chatuzange-le-Goubet",
                "label_fr": "Chatuzange-le-Goubet"
            },
            {
                "value": 30815,
                "label": "Issenhausen",
                "label_ar": "إيسينهاوزن",
                "label_fr": "Issenhausen"
            },
            {
                "value": 30816,
                "label": "Lixhausen",
                "label_ar": "ليكسهاوزن",
                "label_fr": "Lixhausen"
            },
            {
                "value": 30817,
                "label": "Boisemont",
                "label_ar": "Boisemont",
                "label_fr": "Boisemont"
            },
            {
                "value": 30818,
                "label": "Gresse-en-Vercors",
                "label_ar": "جريس إن فيركورز",
                "label_fr": "Gresse-en-Vercors"
            },
            {
                "value": 30819,
                "label": "Venette",
                "label_ar": "فينيت",
                "label_fr": "Venette"
            },
            {
                "value": 30820,
                "label": "Le Meux",
                "label_ar": "لو ميوكس",
                "label_fr": "Le Meux"
            },
            {
                "value": 30821,
                "label": "Verderonne",
                "label_ar": "فيديررون",
                "label_fr": "Verderonne"
            },
            {
                "value": 30822,
                "label": "Henonville",
                "label_ar": "هينونفيل",
                "label_fr": "Hénonville"
            },
            {
                "value": 30823,
                "label": "Auneuil",
                "label_ar": "أونويل",
                "label_fr": "Auneuil"
            },
            {
                "value": 30824,
                "label": "Froissy",
                "label_ar": "فروسي",
                "label_fr": "Froissy"
            },
            {
                "value": 30825,
                "label": "Sainte-Eusoye",
                "label_ar": "سانت يوسوي",
                "label_fr": "Sainte-Eusoye"
            },
            {
                "value": 30826,
                "label": "Tracy-le-Mont",
                "label_ar": "تريسي لو مونت",
                "label_fr": "Tracy-le-Mont"
            },
            {
                "value": 30827,
                "label": "Ribecourt-Dreslincourt",
                "label_ar": "ريبكورت دريسلينكور",
                "label_fr": "Ribecourt-Dreslincourt"
            },
            {
                "value": 30828,
                "label": "Haudivillers",
                "label_ar": "Haudivillers",
                "label_fr": "Haudivillers"
            },
            {
                "value": 30829,
                "label": "Chepoix",
                "label_ar": "Chepoix",
                "label_fr": "Chepoix"
            },
            {
                "value": 30830,
                "label": "Puiseux-le-Hauberger",
                "label_ar": "Puiseux-le-Hauberger",
                "label_fr": "Puiseux-le-Hauberger"
            },
            {
                "value": 30831,
                "label": "Francieres",
                "label_ar": "فرانسيريس",
                "label_fr": "Francières"
            },
            {
                "value": 30832,
                "label": "Breuil-le-Sec",
                "label_ar": "برويل لو سيك",
                "label_fr": "Breuil-le-Sec"
            },
            {
                "value": 30833,
                "label": "Niederbruck",
                "label_ar": "نيدربروك",
                "label_fr": "Niederbruck"
            },
            {
                "value": 30834,
                "label": "Bergheim",
                "label_ar": "بيرجهايم",
                "label_fr": "Bergheim"
            },
            {
                "value": 30835,
                "label": "Fessenheim",
                "label_ar": "فيسنهايم",
                "label_fr": "Fessenheim"
            },
            {
                "value": 30836,
                "label": "Saulzoir",
                "label_ar": "Saulzoir",
                "label_fr": "Saulzoir"
            },
            {
                "value": 30837,
                "label": "Durette",
                "label_ar": "دوريت",
                "label_fr": "Durette"
            },
            {
                "value": 30838,
                "label": "Blerancourdelle",
                "label_ar": "بليرانكورديل",
                "label_fr": "Blerancourdelle"
            },
            {
                "value": 30839,
                "label": "Bongheat",
                "label_ar": "بونغهيت",
                "label_fr": "Bongheat"
            },
            {
                "value": 30840,
                "label": "Bucey-les-Gy",
                "label_ar": "بوسي ليه جي",
                "label_fr": "Bucey-les-Gy"
            },
            {
                "value": 30841,
                "label": "Bidart",
                "label_ar": "بيدارت",
                "label_fr": "Bidart"
            },
            {
                "value": 30842,
                "label": "Villiers-Couture",
                "label_ar": "فيلير كوتور",
                "label_fr": "Villiers-Couture"
            },
            {
                "value": 30843,
                "label": "Epagny",
                "label_ar": "إباني",
                "label_fr": "Epagny"
            },
            {
                "value": 30844,
                "label": "Poissons",
                "label_ar": "بواسون",
                "label_fr": "Poissons"
            },
            {
                "value": 30845,
                "label": "Brassempouy",
                "label_ar": "براسمبوي",
                "label_fr": "Brassempouy"
            },
            {
                "value": 30846,
                "label": "Labatut",
                "label_ar": "لاباتوت",
                "label_fr": "Labatut"
            },
            {
                "value": 30847,
                "label": "Bourgogne",
                "label_ar": "بورجوني",
                "label_fr": "Bourgogne"
            },
            {
                "value": 30848,
                "label": "Aumenancourt",
                "label_ar": "اومينانكورت",
                "label_fr": "Aumenancourt"
            },
            {
                "value": 30849,
                "label": "Elnes",
                "label_ar": "إلينز",
                "label_fr": "Elnes"
            },
            {
                "value": 30850,
                "label": "Pradelles",
                "label_ar": "براديليس",
                "label_fr": "Pradelles"
            },
            {
                "value": 30851,
                "label": "Belpech",
                "label_ar": "بيلبيك",
                "label_fr": "Belpech"
            },
            {
                "value": 30852,
                "label": "Saint-Ouen-des-Besaces",
                "label_ar": "Saint-Ouen-des-Besaces",
                "label_fr": "Saint-Ouen-des-Besaces"
            },
            {
                "value": 30853,
                "label": "Choisey",
                "label_ar": "تشويسي",
                "label_fr": "Choisey"
            },
            {
                "value": 30854,
                "label": "Saint-Paterne-Racan",
                "label_ar": "سانت باتيرن راكان",
                "label_fr": "Saint-Paterne-Racan"
            },
            {
                "value": 30855,
                "label": "Dampmart",
                "label_ar": "داممارت",
                "label_fr": "Dampmart"
            },
            {
                "value": 30856,
                "label": "Sonzay",
                "label_ar": "سونزي",
                "label_fr": "Sonzay"
            },
            {
                "value": 30857,
                "label": "Villedomer",
                "label_ar": "فيلدومر",
                "label_fr": "Villedomer"
            },
            {
                "value": 30858,
                "label": "Buzet-sur-Tarn",
                "label_ar": "Buzet-sur-Tarn",
                "label_fr": "Buzet-sur-Tarn"
            },
            {
                "value": 30859,
                "label": "Marigny-Marmande",
                "label_ar": "ماريني مارماندي",
                "label_fr": "Marigny-Marmande"
            },
            {
                "value": 30860,
                "label": "Clermont",
                "label_ar": "كليرمونت",
                "label_fr": "Clermont"
            },
            {
                "value": 30861,
                "label": "Herin",
                "label_ar": "هيرين",
                "label_fr": "Elle en"
            },
            {
                "value": 30862,
                "label": "Montoison",
                "label_ar": "مونتويسون",
                "label_fr": "Montoison"
            },
            {
                "value": 30863,
                "label": "Izier",
                "label_ar": "إيزير",
                "label_fr": "Izier"
            },
            {
                "value": 30864,
                "label": "Camiac-et-Saint-Denis",
                "label_ar": "Camiac-et-Saint-Denis",
                "label_fr": "Camiac-et-Saint-Denis"
            },
            {
                "value": 30865,
                "label": "Campeneac",
                "label_ar": "كامبينيك",
                "label_fr": "Campeneac"
            },
            {
                "value": 30866,
                "label": "Orphin",
                "label_ar": "أورفين",
                "label_fr": "Orphin"
            },
            {
                "value": 30867,
                "label": "Sars-Poteries",
                "label_ar": "فخار سارس",
                "label_fr": "Sars-Poteries"
            },
            {
                "value": 30868,
                "label": "Villeneuve-de-la-Raho",
                "label_ar": "فيلنوف دي لا راحو",
                "label_fr": "Villeneuve-de-la-Raho"
            },
            {
                "value": 30869,
                "label": "Sequedin",
                "label_ar": "الترتر",
                "label_fr": "Sequedin"
            },
            {
                "value": 30870,
                "label": "Toussieux",
                "label_ar": "توسيو",
                "label_fr": "Toussieux"
            },
            {
                "value": 30871,
                "label": "Reuil-en-Brie",
                "label_ar": "Reuil-en-Brie",
                "label_fr": "Reuil-en-Brie"
            },
            {
                "value": 30872,
                "label": "Cuon",
                "label_ar": "كون",
                "label_fr": "Cuon"
            },
            {
                "value": 30873,
                "label": "Denice",
                "label_ar": "دينيس",
                "label_fr": "Denice"
            },
            {
                "value": 30874,
                "label": "Saint-Marc-Jaumegarde",
                "label_ar": "سان مارك جوميجارد",
                "label_fr": "Saint-Marc-Jaumegarde"
            },
            {
                "value": 30875,
                "label": "Routot",
                "label_ar": "روتوت",
                "label_fr": "Routot"
            },
            {
                "value": 30876,
                "label": "Montlignon",
                "label_ar": "مونتلينيون",
                "label_fr": "Montlignon"
            },
            {
                "value": 30877,
                "label": "Chamelet",
                "label_ar": "شامليت",
                "label_fr": "Chamelet"
            },
            {
                "value": 30878,
                "label": "Corconne",
                "label_ar": "كوركون",
                "label_fr": "Corconne"
            },
            {
                "value": 30879,
                "label": "Montilly",
                "label_ar": "مونتيلي",
                "label_fr": "Montilly"
            },
            {
                "value": 30880,
                "label": "Combon",
                "label_ar": "كومبون",
                "label_fr": "Combon"
            },
            {
                "value": 30881,
                "label": "Villiers-le-Bacle",
                "label_ar": "فيلير لو باكل",
                "label_fr": "Villiers-le-Bacle"
            },
            {
                "value": 30882,
                "label": "Margency",
                "label_ar": "الهامش",
                "label_fr": "Margency"
            },
            {
                "value": 30883,
                "label": "Saint-Denis-en-Val",
                "label_ar": "سان دينيس إن فال",
                "label_fr": "Saint-Denis-en-Val"
            },
            {
                "value": 30884,
                "label": "Clarensac",
                "label_ar": "كلارينساك",
                "label_fr": "Clarensac"
            },
            {
                "value": 30885,
                "label": "Vaux-sur-Seine",
                "label_ar": "فو سور سين",
                "label_fr": "Vaux-sur-Seine"
            },
            {
                "value": 30886,
                "label": "Spycker",
                "label_ar": "سبايكر",
                "label_fr": "Spycker"
            },
            {
                "value": 30887,
                "label": "Uchacq-et-Parentis",
                "label_ar": "Uchacq-et-Parentis",
                "label_fr": "Uchacq-et-Parentis"
            },
            {
                "value": 30888,
                "label": "Theziers",
                "label_ar": "ثيزيرس",
                "label_fr": "Theziers"
            },
            {
                "value": 30889,
                "label": "Tautavel",
                "label_ar": "Tautavel",
                "label_fr": "Tautavel"
            },
            {
                "value": 30890,
                "label": "Chambalud",
                "label_ar": "شامبالود",
                "label_fr": "Chambalud"
            },
            {
                "value": 30891,
                "label": "Denee",
                "label_ar": "دين",
                "label_fr": "Denee"
            },
            {
                "value": 30892,
                "label": "Sadirac",
                "label_ar": "صادراك",
                "label_fr": "Sadirac"
            },
            {
                "value": 30893,
                "label": "La Bresse",
                "label_ar": "لابريس",
                "label_fr": "La Bresse"
            },
            {
                "value": 30894,
                "label": "Contrisson",
                "label_ar": "التناقض",
                "label_fr": "Contrisson"
            },
            {
                "value": 30895,
                "label": "Fanjeaux",
                "label_ar": "Fanjeaux",
                "label_fr": "Fanjeaux"
            },
            {
                "value": 30896,
                "label": "Wizernes",
                "label_ar": "ويزرنيس",
                "label_fr": "Wizernes"
            },
            {
                "value": 30897,
                "label": "Seyre",
                "label_ar": "ساير",
                "label_fr": "Seyre"
            },
            {
                "value": 30898,
                "label": "Bazet",
                "label_ar": "بازيت",
                "label_fr": "Bazet"
            },
            {
                "value": 30899,
                "label": "Lacanau",
                "label_ar": "لاكاناو",
                "label_fr": "Lacanau"
            },
            {
                "value": 30900,
                "label": "Pont-Saint-Vincent",
                "label_ar": "بونت سانت فنسنت",
                "label_fr": "Pont-Saint-Vincent"
            },
            {
                "value": 30901,
                "label": "Rosieres-aux-Salines",
                "label_ar": "Rosieres-aux-Salines",
                "label_fr": "Rosières-aux-Salines"
            },
            {
                "value": 30902,
                "label": "Cuvilly",
                "label_ar": "كوفيلي",
                "label_fr": "Cuvilly"
            },
            {
                "value": 30903,
                "label": "Moussy",
                "label_ar": "موسي",
                "label_fr": "Moussy"
            },
            {
                "value": 30904,
                "label": "Pargny-sur-Saulx",
                "label_ar": "بارجني سور سولكس",
                "label_fr": "Pargny-sur-Saulx"
            },
            {
                "value": 30905,
                "label": "Vivier-au-Court",
                "label_ar": "فيفييه أو كورت",
                "label_fr": "Vivier-au-Court"
            },
            {
                "value": 30906,
                "label": "Montreuil-sur-Blaise",
                "label_ar": "مونتروي سور بليز",
                "label_fr": "Montreuil-sur-Blaise"
            },
            {
                "value": 30907,
                "label": "Anglure",
                "label_ar": "أنجلور",
                "label_fr": "Anglure"
            },
            {
                "value": 30908,
                "label": "Boubers-sur-Canche",
                "label_ar": "بوبرز سور كانش",
                "label_fr": "Boubers-sur-Canche"
            },
            {
                "value": 30909,
                "label": "Lumbres",
                "label_ar": "لومبريس",
                "label_fr": "Lumbres"
            },
            {
                "value": 30910,
                "label": "Neuilly-sous-Clermont",
                "label_ar": "نويي سوس كليرمونت",
                "label_fr": "Neuilly-sous-Clermont"
            },
            {
                "value": 30911,
                "label": "Montherme",
                "label_ar": "شهر",
                "label_fr": "Monthermé"
            },
            {
                "value": 30912,
                "label": "Therouanne",
                "label_ar": "ثيروان",
                "label_fr": "Thérouanne"
            },
            {
                "value": 30913,
                "label": "Caullery",
                "label_ar": "كوليري",
                "label_fr": "Caullery"
            },
            {
                "value": 30914,
                "label": "Delettes",
                "label_ar": "ديليت",
                "label_fr": "Delettes"
            },
            {
                "value": 30915,
                "label": "Mailly-Champagne",
                "label_ar": "مايلي الشمبانيا",
                "label_fr": "Mailly-Champagne"
            },
            {
                "value": 30916,
                "label": "Ecques",
                "label_ar": "Ecques",
                "label_fr": "Ecques"
            },
            {
                "value": 30917,
                "label": "Frevent",
                "label_ar": "فريفينت",
                "label_fr": "Frevent"
            },
            {
                "value": 30918,
                "label": "Roeux",
                "label_ar": "رو",
                "label_fr": "Roeux"
            },
            {
                "value": 30919,
                "label": "Esnouveaux",
                "label_ar": "Esnouveaux",
                "label_fr": "Esnouveaux"
            },
            {
                "value": 30920,
                "label": "Betheniville",
                "label_ar": "بيثينيفيل",
                "label_fr": "Betheniville"
            },
            {
                "value": 30921,
                "label": "Bettancourt-la-Ferree",
                "label_ar": "بيتانكورت لا فيري",
                "label_fr": "Bettancourt-la-Ferree"
            },
            {
                "value": 30922,
                "label": "Dontrien",
                "label_ar": "دونترين",
                "label_fr": "Dontrien"
            },
            {
                "value": 30923,
                "label": "Neuflize",
                "label_ar": "نيوفليز",
                "label_fr": "Neuflize"
            },
            {
                "value": 30924,
                "label": "Vaux-sur-Blaise",
                "label_ar": "فو سور بليز",
                "label_fr": "Vaux-sur-Blaise"
            },
            {
                "value": 30925,
                "label": "Renneville",
                "label_ar": "رينفيل",
                "label_fr": "Renneville"
            },
            {
                "value": 30926,
                "label": "Bouilly",
                "label_ar": "بويلي",
                "label_fr": "Bouilly"
            },
            {
                "value": 30927,
                "label": "Tilloy-et-Bellay",
                "label_ar": "Tilloy-et-Bellay",
                "label_fr": "Tilloy-et-Bellay"
            },
            {
                "value": 30928,
                "label": "Vadenay",
                "label_ar": "فاديني",
                "label_fr": "Vadenay"
            },
            {
                "value": 30929,
                "label": "Presles-et-Thierny",
                "label_ar": "بريسليس وآخرون تيرني",
                "label_fr": "Presles-et-Thierny"
            },
            {
                "value": 30930,
                "label": "Versigny",
                "label_ar": "فيرسيني",
                "label_fr": "Versigny"
            },
            {
                "value": 30931,
                "label": "Cottenchy",
                "label_ar": "كوتينشي",
                "label_fr": "Cottenchy"
            },
            {
                "value": 30932,
                "label": "Etreillers",
                "label_ar": "Etreillers",
                "label_fr": "Etreillers"
            },
            {
                "value": 30933,
                "label": "Sailly-Flibeaucourt",
                "label_ar": "شراع فليبوكورت",
                "label_fr": "Sailly-Flibeaucourt"
            },
            {
                "value": 30934,
                "label": "Charteves",
                "label_ar": "تشارتيفيس",
                "label_fr": "Charteves"
            },
            {
                "value": 30935,
                "label": "Le Plessier-sur-Bulles",
                "label_ar": "لو بليسيه سور بولس",
                "label_fr": "Le Plessier-sur-Bulles"
            },
            {
                "value": 30936,
                "label": "Labruyere",
                "label_ar": "لابرويير",
                "label_fr": "Labruyère"
            },
            {
                "value": 30937,
                "label": "Montier-en-Der",
                "label_ar": "مونتييه أون دير",
                "label_fr": "Montier-en-Der"
            },
            {
                "value": 30938,
                "label": "Saint-Eulien",
                "label_ar": "سانت يوليان",
                "label_fr": "Saint-Eulien"
            },
            {
                "value": 30939,
                "label": "Voillecomte",
                "label_ar": "Voillecomte",
                "label_fr": "Voillecomte"
            },
            {
                "value": 30940,
                "label": "Romain-sur-Meuse",
                "label_ar": "رومان سور ميوز",
                "label_fr": "Romain-sur-Meuse"
            },
            {
                "value": 30941,
                "label": "Cunfin",
                "label_ar": "Cunfin",
                "label_fr": "Cunfin"
            },
            {
                "value": 30942,
                "label": "Boursault",
                "label_ar": "بورسو",
                "label_fr": "Boursault"
            },
            {
                "value": 30943,
                "label": "Warnecourt",
                "label_ar": "وارنيكورت",
                "label_fr": "Warnecourt"
            },
            {
                "value": 30944,
                "label": "Saint-Martin-Longueau",
                "label_ar": "سان مارتن لونغو",
                "label_fr": "Saint-Martin-Longueau"
            },
            {
                "value": 30945,
                "label": "Reuilly-Sauvigny",
                "label_ar": "Reuilly-Sauvigny",
                "label_fr": "Reuilly-Sauvigny"
            },
            {
                "value": 30946,
                "label": "Bruyeres-et-Montberault",
                "label_ar": "Bruyeres-et-Montberault",
                "label_fr": "Bruyères-et-Montberault"
            },
            {
                "value": 30947,
                "label": "Annois",
                "label_ar": "أنويس",
                "label_fr": "Annois"
            },
            {
                "value": 30948,
                "label": "Buironfosse",
                "label_ar": "بويرونفوس",
                "label_fr": "Buironfosse"
            },
            {
                "value": 30949,
                "label": "Montiers",
                "label_ar": "مونتييه",
                "label_fr": "Montiers"
            },
            {
                "value": 30950,
                "label": "Bourguignon-sous-Coucy",
                "label_ar": "بورغينيون سو كوسي",
                "label_fr": "Bourguignon-sous-Coucy"
            },
            {
                "value": 30951,
                "label": "Marcoux",
                "label_ar": "ماركو",
                "label_fr": "Marcoux"
            },
            {
                "value": 30952,
                "label": "Tourrette-Levens",
                "label_ar": "توريت ليفينز",
                "label_fr": "Tourrette-Levens"
            },
            {
                "value": 30953,
                "label": "Saint-Andre-les-Alpes",
                "label_ar": "سان أندريه ليه ألب",
                "label_fr": "Saint-André-les-Alpes"
            },
            {
                "value": 30954,
                "label": "Villars-Colmars",
                "label_ar": "فيلارس كولمارز",
                "label_fr": "Villars-Colmars"
            },
            {
                "value": 30955,
                "label": "Barreme",
                "label_ar": "باريم",
                "label_fr": "Barrème"
            },
            {
                "value": 30956,
                "label": "Tanneron",
                "label_ar": "تانيرون",
                "label_fr": "Tanneron"
            },
            {
                "value": 30957,
                "label": "Coaraze",
                "label_ar": "خشن",
                "label_fr": "Coaraze"
            },
            {
                "value": 30958,
                "label": "Breil-sur-Roya",
                "label_ar": "بريل سور رويا",
                "label_fr": "Breil-sur-Roya"
            },
            {
                "value": 30959,
                "label": "Albiosc",
                "label_ar": "البيوسك",
                "label_fr": "Albiosc"
            },
            {
                "value": 30960,
                "label": "Moulinet",
                "label_ar": "مولينيت",
                "label_fr": "Moulinet"
            },
            {
                "value": 30961,
                "label": "Blausasc",
                "label_ar": "بلاوزسك",
                "label_fr": "Blausasc"
            },
            {
                "value": 30962,
                "label": "Utelle",
                "label_ar": "يوتيل",
                "label_fr": "Utelle"
            },
            {
                "value": 30963,
                "label": "Vers-en-Montagne",
                "label_ar": "Vers-en-Montagne",
                "label_fr": "Vers-en-Montagne"
            },
            {
                "value": 30964,
                "label": "Colombier",
                "label_ar": "كولومبير",
                "label_fr": "Colombier"
            },
            {
                "value": 30965,
                "label": "Sauxillanges",
                "label_ar": "زوكسيلانج",
                "label_fr": "Sauxillanges"
            },
            {
                "value": 30966,
                "label": "Barges",
                "label_ar": "صنادل",
                "label_fr": "Barges"
            },
            {
                "value": 30967,
                "label": "Saint-Angel",
                "label_ar": "سانت انجيل",
                "label_fr": "Saint-Ange"
            },
            {
                "value": 30968,
                "label": "Lafeuillade-en-Vezie",
                "label_ar": "Lafeuillade-en-Vezie",
                "label_fr": "Lafeuillade-en-Vézie"
            },
            {
                "value": 30969,
                "label": "Viellespeze",
                "label_ar": "فيليسبيز",
                "label_fr": "Viellespeze"
            },
            {
                "value": 30970,
                "label": "Dauzat-sur-Vodable",
                "label_ar": "دوزات سور فودابل",
                "label_fr": "Dauzat-sur-Vodable"
            },
            {
                "value": 30971,
                "label": "Le Rouget",
                "label_ar": "لو روجيت",
                "label_fr": "Le Rouget"
            },
            {
                "value": 30972,
                "label": "Bossey",
                "label_ar": "بوسي",
                "label_fr": "Bossey"
            },
            {
                "value": 30973,
                "label": "Chenex",
                "label_ar": "تشينيكس",
                "label_fr": "Chenex"
            },
            {
                "value": 30974,
                "label": "Tours-sur-Meymont",
                "label_ar": "جولات سور ميمونت",
                "label_fr": "Tours-sur-Meymont"
            },
            {
                "value": 30975,
                "label": "Antignac",
                "label_ar": "أنتيجناك",
                "label_fr": "Antignac"
            },
            {
                "value": 30976,
                "label": "Couteuges",
                "label_ar": "كوتوج",
                "label_fr": "Couteuges"
            },
            {
                "value": 30977,
                "label": "Loudes",
                "label_ar": "بصوت عال",
                "label_fr": "Loudes"
            },
            {
                "value": 30978,
                "label": "Dallet",
                "label_ar": "دلة",
                "label_fr": "Dallet"
            },
            {
                "value": 30979,
                "label": "Creuzier-le-Vieux",
                "label_ar": "كروزييه لو فيو",
                "label_fr": "Creuzier-le-Vieux"
            },
            {
                "value": 30980,
                "label": "Le Chambon",
                "label_ar": "لو شامبون",
                "label_fr": "Le Chambon"
            },
            {
                "value": 30981,
                "label": "Verneuil-en-Bourbonnais",
                "label_ar": "Verneuil-en-Bourbonnais",
                "label_fr": "Verneuil-en-Bourbonnais"
            },
            {
                "value": 30982,
                "label": "Cistrieres",
                "label_ar": "سيسترريس",
                "label_fr": "Cistrières"
            },
            {
                "value": 30983,
                "label": "Bayet",
                "label_ar": "باييت",
                "label_fr": "Bayet"
            },
            {
                "value": 30984,
                "label": "Orcines",
                "label_ar": "Orcines",
                "label_fr": "Orcines"
            },
            {
                "value": 30985,
                "label": "Vezezoux",
                "label_ar": "فيزوكس",
                "label_fr": "Vezezoux"
            },
            {
                "value": 30986,
                "label": "Allonzier-la-Caille",
                "label_ar": "ألونزييه لا كايل",
                "label_fr": "Allonzier-la-Caille"
            },
            {
                "value": 30987,
                "label": "Le Monteil",
                "label_ar": "لو مونتيل",
                "label_fr": "Le Monteil"
            },
            {
                "value": 30988,
                "label": "Veigy-Foncenex",
                "label_ar": "فيجي فونسينكس",
                "label_fr": "Veigy-Foncenex"
            },
            {
                "value": 30989,
                "label": "Veyrier-du-Lac",
                "label_ar": "فيرير دو لاك",
                "label_fr": "Veyrier-du-Lac"
            },
            {
                "value": 30990,
                "label": "Bellerive-sur-Allier",
                "label_ar": "بيلريف سور أليير",
                "label_fr": "Bellerive-sur-Allier"
            },
            {
                "value": 30991,
                "label": "Coubon",
                "label_ar": "كوبون",
                "label_fr": "Coubon"
            },
            {
                "value": 30992,
                "label": "La Frasse",
                "label_ar": "لا فراس",
                "label_fr": "La Frasse"
            },
            {
                "value": 30993,
                "label": "Châtelperron",
                "label_ar": "شاتيلبرون",
                "label_fr": "Châtelperron"
            },
            {
                "value": 30994,
                "label": "Lanobre",
                "label_ar": "لانوبري",
                "label_fr": "Lanobre"
            },
            {
                "value": 30995,
                "label": "Vieille-Brioude",
                "label_ar": "Vieille-Brioude",
                "label_fr": "Vieille-Brioude"
            },
            {
                "value": 30996,
                "label": "Villeneuve-sur-Allier",
                "label_ar": "فيلنوف سور أليير",
                "label_fr": "Villeneuve-sur-Allier"
            },
            {
                "value": 30997,
                "label": "Teillet-Argenty",
                "label_ar": "Teillet-Argenty",
                "label_fr": "Teillet-Argenty"
            },
            {
                "value": 30998,
                "label": "Quezac",
                "label_ar": "كويزاك",
                "label_fr": "Quezac"
            },
            {
                "value": 30999,
                "label": "Gouise",
                "label_ar": "جويز",
                "label_fr": "Gouise"
            },
            {
                "value": 31000,
                "label": "Mezel",
                "label_ar": "ميزل",
                "label_fr": "Mezel"
            },
            {
                "value": 31001,
                "label": "Menthon-Saint-Bernard",
                "label_ar": "مينتون سانت برنارد",
                "label_fr": "Menthon-Saint-Bernard"
            },
            {
                "value": 31002,
                "label": "Dingy-en-Vuache",
                "label_ar": "Dingy-en-Vuache",
                "label_fr": "Dingy-en-Vuache"
            },
            {
                "value": 31003,
                "label": "Gouttieres",
                "label_ar": "غوتيريس",
                "label_fr": "Gouttières"
            },
            {
                "value": 31004,
                "label": "Serbannes",
                "label_ar": "سيربانيس",
                "label_fr": "Serbannes"
            },
            {
                "value": 31005,
                "label": "Marsat",
                "label_ar": "مارسات",
                "label_fr": "Marsat"
            },
            {
                "value": 31006,
                "label": "Espaly-Saint-Marcel",
                "label_ar": "اسبالي سان مارسيل",
                "label_fr": "Espaly-Saint-Marcel"
            },
            {
                "value": 31007,
                "label": "La Chapelaude",
                "label_ar": "لا شابيلود",
                "label_fr": "La Chapelaude"
            },
            {
                "value": 31008,
                "label": "Cessy",
                "label_ar": "سيسي",
                "label_fr": "Cessy"
            },
            {
                "value": 31009,
                "label": "Brugheas",
                "label_ar": "بروغياس",
                "label_fr": "Brugheas"
            },
            {
                "value": 31010,
                "label": "Chadron",
                "label_ar": "شادرون",
                "label_fr": "Chadron"
            },
            {
                "value": 31011,
                "label": "Lepuy",
                "label_ar": "ليبوي",
                "label_fr": "Lepuy"
            },
            {
                "value": 31012,
                "label": "Paray-sous-Briailles",
                "label_ar": "Paray-sous-Briailles",
                "label_fr": "Paray-sous-Briailles"
            },
            {
                "value": 31013,
                "label": "Montbeugny",
                "label_ar": "مونبيوجني",
                "label_fr": "Montbeugny"
            },
            {
                "value": 31014,
                "label": "Neuf Eglise",
                "label_ar": "نيوف إجليز",
                "label_fr": "Neuf Eglise"
            },
            {
                "value": 31015,
                "label": "Chadeleuf",
                "label_ar": "شادليف",
                "label_fr": "Chadeleuf"
            },
            {
                "value": 31016,
                "label": "Ytrac",
                "label_ar": "يتراك",
                "label_fr": "Ytrac"
            },
            {
                "value": 31017,
                "label": "Celles-sur-Durolle",
                "label_ar": "سيلز سور دورول",
                "label_fr": "Celles-sur-Durolle"
            },
            {
                "value": 31018,
                "label": "Plauzat",
                "label_ar": "بلاوزات",
                "label_fr": "Plauzat"
            },
            {
                "value": 31019,
                "label": "Sardon",
                "label_ar": "ساردون",
                "label_fr": "Sardon"
            },
            {
                "value": 31020,
                "label": "Les Breviaires",
                "label_ar": "ليه بريفيير",
                "label_fr": "Les Breviaires"
            },
            {
                "value": 31021,
                "label": "Bazemont",
                "label_ar": "بازمونت",
                "label_fr": "Bazemont"
            },
            {
                "value": 31022,
                "label": "Fleury-en-Biere",
                "label_ar": "فلوري أون بير",
                "label_fr": "Fleury-en-Bière"
            },
            {
                "value": 31023,
                "label": "Les Essarts",
                "label_ar": "ليس إسارتس",
                "label_fr": "Les Essarts"
            },
            {
                "value": 31024,
                "label": "Lere",
                "label_ar": "لير",
                "label_fr": "Lere"
            },
            {
                "value": 31025,
                "label": "Gracay",
                "label_ar": "جراساي",
                "label_fr": "Gracay"
            },
            {
                "value": 31026,
                "label": "Civray-de-Touraine",
                "label_ar": "سيفراي دي تورين",
                "label_fr": "Civray-de-Touraine"
            },
            {
                "value": 31027,
                "label": "Vouzeron",
                "label_ar": "فوزيرون",
                "label_fr": "Vouzeron"
            },
            {
                "value": 31028,
                "label": "Mardie",
                "label_ar": "ماردي",
                "label_fr": "Mardie"
            },
            {
                "value": 31029,
                "label": "Ecueille",
                "label_ar": "إيكويل",
                "label_fr": "Ecueille"
            },
            {
                "value": 31030,
                "label": "Auffargis",
                "label_ar": "أفرجيس",
                "label_fr": "Auffargis"
            },
            {
                "value": 31031,
                "label": "Umpeau",
                "label_ar": "أمبو",
                "label_fr": "Umpeau"
            },
            {
                "value": 31032,
                "label": "Ecrosnes",
                "label_ar": "إكروسنس",
                "label_fr": "Écrosnes"
            },
            {
                "value": 31033,
                "label": "Meung-sur-Loire",
                "label_ar": "ميونغ سور لوار",
                "label_fr": "Meung-sur-Loire"
            },
            {
                "value": 31034,
                "label": "Monthou-sur-Cher",
                "label_ar": "مونثو سور شير",
                "label_fr": "Monthou-sur-Cher"
            },
            {
                "value": 31035,
                "label": "Saligny-le-Vif",
                "label_ar": "ساليني لو فيف",
                "label_fr": "Saligny-le-Vif"
            },
            {
                "value": 31036,
                "label": "Bridore",
                "label_ar": "بريدور",
                "label_fr": "Bridore"
            },
            {
                "value": 31037,
                "label": "Alluyes",
                "label_ar": "Alluyes",
                "label_fr": "Alluyes"
            },
            {
                "value": 31038,
                "label": "Neuvy-en-Beauce",
                "label_ar": "Neuvy-en-Beauce",
                "label_fr": "Neuvy-en-Beauce"
            },
            {
                "value": 31039,
                "label": "Monthodon",
                "label_ar": "مونثودون",
                "label_fr": "Monthodon"
            },
            {
                "value": 31040,
                "label": "Souesmes",
                "label_ar": "Souesmes",
                "label_fr": "Souesmes"
            },
            {
                "value": 31041,
                "label": "Savigny-sur-Braye",
                "label_ar": "سافيني سور براي",
                "label_fr": "Savigny-sur-Braye"
            },
            {
                "value": 31042,
                "label": "Avord",
                "label_ar": "أفورد",
                "label_fr": "Avord"
            },
            {
                "value": 31043,
                "label": "Negron",
                "label_ar": "نيجرون",
                "label_fr": "Negron"
            },
            {
                "value": 31044,
                "label": "Averdon",
                "label_ar": "أفيردون",
                "label_fr": "Averdon"
            },
            {
                "value": 31045,
                "label": "Moree",
                "label_ar": "موري",
                "label_fr": "Moree"
            },
            {
                "value": 31046,
                "label": "Foecy",
                "label_ar": "Foecy",
                "label_fr": "Foecy"
            },
            {
                "value": 31047,
                "label": "Tournon-Saint-Martin",
                "label_ar": "تورنون سانت مارتن",
                "label_fr": "Tournon-Saint-Martin"
            },
            {
                "value": 31048,
                "label": "Armenonville-les-Gatineaux",
                "label_ar": "أرمينونفيل ليس جاتينو",
                "label_fr": "Armenonville-les-Gatineaux"
            },
            {
                "value": 31049,
                "label": "Donnery",
                "label_ar": "دونري",
                "label_fr": "Donnery"
            },
            {
                "value": 31050,
                "label": "Courcelles-en-Bassee",
                "label_ar": "Courcelles-en-Bassee",
                "label_fr": "Courcelles-en-Bassee"
            },
            {
                "value": 31051,
                "label": "Huisseau-sur-Mauves",
                "label_ar": "هويسو سور موف",
                "label_fr": "Huisseau-sur-Mauves"
            },
            {
                "value": 31052,
                "label": "Courmemin",
                "label_ar": "كورمين",
                "label_fr": "Courmemin"
            },
            {
                "value": 31053,
                "label": "Vierville",
                "label_ar": "فيرفيل",
                "label_fr": "Vierville"
            },
            {
                "value": 31054,
                "label": "Oisly",
                "label_ar": "Oisly",
                "label_fr": "Oisly"
            },
            {
                "value": 31055,
                "label": "Beauchene",
                "label_ar": "بيوشين",
                "label_fr": "Beauchène"
            },
            {
                "value": 31056,
                "label": "Blancafort",
                "label_ar": "بلانكافورت",
                "label_fr": "Blancafort"
            },
            {
                "value": 31057,
                "label": "Checy",
                "label_ar": "تشيسي",
                "label_fr": "Chécy"
            },
            {
                "value": 31058,
                "label": "Mer",
                "label_ar": "مير",
                "label_fr": "Mer"
            },
            {
                "value": 31059,
                "label": "Nibelle",
                "label_ar": "نيبيل",
                "label_fr": "Nibelle"
            },
            {
                "value": 31060,
                "label": "Ormes",
                "label_ar": "أورمس",
                "label_fr": "Ormes"
            },
            {
                "value": 31061,
                "label": "Charmont-en-Beauce",
                "label_ar": "شارمونت أون بوس",
                "label_fr": "Charmont-en-Beauce"
            },
            {
                "value": 31062,
                "label": "Saint-Lactencin",
                "label_ar": "سان لاكتينسين",
                "label_fr": "Saint-Lactencin"
            },
            {
                "value": 31063,
                "label": "Autry-le-Chatel",
                "label_ar": "أوتري لو شاتيل",
                "label_fr": "Autry-le-Châtel"
            },
            {
                "value": 31064,
                "label": "Couddes",
                "label_ar": "كودس",
                "label_fr": "Couddes"
            },
            {
                "value": 31065,
                "label": "Houville-la-Branche",
                "label_ar": "هوفيل لا برانش",
                "label_fr": "Houville-la-Branche"
            },
            {
                "value": 31066,
                "label": "Soulangis",
                "label_ar": "سولانجيس",
                "label_fr": "Soulangis"
            },
            {
                "value": 31067,
                "label": "Nonvilliers-Grandhoux",
                "label_ar": "Nonvilliers-Grandhoux",
                "label_fr": "Nonvilliers-Grandhoux"
            },
            {
                "value": 31068,
                "label": "Bessais-le-Fromental",
                "label_ar": "Bessais-le-Fromental",
                "label_fr": "Bessais-le-Fromental"
            },
            {
                "value": 31069,
                "label": "Bourgueil",
                "label_ar": "بورجيل",
                "label_fr": "Bourgueil"
            },
            {
                "value": 31070,
                "label": "Veuil",
                "label_ar": "الحجاب",
                "label_fr": "Veuil"
            },
            {
                "value": 31071,
                "label": "Ange",
                "label_ar": "أنجي",
                "label_fr": "Ange"
            },
            {
                "value": 31072,
                "label": "Chaudon",
                "label_ar": "شودون",
                "label_fr": "Chaudon"
            },
            {
                "value": 31073,
                "label": "Sandillon",
                "label_ar": "سانديلون",
                "label_fr": "Sandillon"
            },
            {
                "value": 31074,
                "label": "Boismorand",
                "label_ar": "Boismorand",
                "label_fr": "Boismorand"
            },
            {
                "value": 31075,
                "label": "Gievres",
                "label_ar": "جيفريس",
                "label_fr": "Gièvres"
            },
            {
                "value": 31076,
                "label": "Saint-Julien-de-Chedon",
                "label_ar": "سان جوليان دي شيدون",
                "label_fr": "Saint-Julien-de-Chedon"
            },
            {
                "value": 31077,
                "label": "Jouy-le-Potier",
                "label_ar": "جوي لو بوتييه",
                "label_fr": "Jouy-le-Potier"
            },
            {
                "value": 31078,
                "label": "Arthon",
                "label_ar": "آرثون",
                "label_fr": "Arthon"
            },
            {
                "value": 31079,
                "label": "Antogny le Tillac",
                "label_ar": "أنتوني لو تيلاك",
                "label_fr": "Antogny le Tillac"
            },
            {
                "value": 31080,
                "label": "Orrouer",
                "label_ar": "أوروير",
                "label_fr": "Orrouer"
            },
            {
                "value": 31081,
                "label": "Meillant",
                "label_ar": "ميلانت",
                "label_fr": "Meillant"
            },
            {
                "value": 31082,
                "label": "Marcilly-en-Villette",
                "label_ar": "مارسيلي أون فيليت",
                "label_fr": "Marcilly-en-Villette"
            },
            {
                "value": 31083,
                "label": "Selles-Saint-Denis",
                "label_ar": "Selles-Saint-Denis",
                "label_fr": "Selles-Saint-Denis"
            },
            {
                "value": 31084,
                "label": "Selles-sur-Nahon",
                "label_ar": "سليس سور ناهون",
                "label_fr": "Selles-sur-Nahon"
            },
            {
                "value": 31085,
                "label": "Pressigny",
                "label_ar": "بريسيجني",
                "label_fr": "Pressigny"
            },
            {
                "value": 31086,
                "label": "Saint-Georges-sur-Eure",
                "label_ar": "سان جورج سور يور",
                "label_fr": "Saint-Georges-sur-Eure"
            },
            {
                "value": 31087,
                "label": "Epieds-en-Beauce",
                "label_ar": "Epieds-en-Beauce",
                "label_fr": "Epieds-en-Beauce"
            },
            {
                "value": 31088,
                "label": "Reipertswiller",
                "label_ar": "Reipertswiller",
                "label_fr": "Reipertswiller"
            },
            {
                "value": 31089,
                "label": "Champenay",
                "label_ar": "تشامبيناي",
                "label_fr": "Champenay"
            },
            {
                "value": 31090,
                "label": "Bussieres-les-Belmont",
                "label_ar": "بوسيريس ليه بيلمونت",
                "label_fr": "Bussières-les-Belmont"
            },
            {
                "value": 31091,
                "label": "Montsauche-les-Settons",
                "label_ar": "مونتسوش ليه سيتون",
                "label_fr": "Montsauche-les-Settons"
            },
            {
                "value": 31092,
                "label": "Vaux-la-Petite",
                "label_ar": "فو لا بيتيت",
                "label_fr": "Vaux-la-Petite"
            },
            {
                "value": 31093,
                "label": "Cornimont",
                "label_ar": "كورنيمونت",
                "label_fr": "Cornimont"
            },
            {
                "value": 31094,
                "label": "Conde-Northen",
                "label_ar": "كوندي نورثين",
                "label_fr": "Condé-Northen"
            },
            {
                "value": 31095,
                "label": "Bazailles",
                "label_ar": "بازائيل",
                "label_fr": "Bazailles"
            },
            {
                "value": 31096,
                "label": "Blenod-les-Pont-a-Mousson",
                "label_ar": "Blenod-les-Pont-a-Mousson",
                "label_fr": "Blenod-les-Pont-à-Mousson"
            },
            {
                "value": 31097,
                "label": "Bennwihr",
                "label_ar": "بنوير",
                "label_fr": "Bennwihr"
            },
            {
                "value": 31098,
                "label": "Soultz-sous-Forets",
                "label_ar": "سولتز سوس فوريتس",
                "label_fr": "Soultz-sous-Forets"
            },
            {
                "value": 31099,
                "label": "Vars",
                "label_ar": "فارز",
                "label_fr": "Vars"
            },
            {
                "value": 31100,
                "label": "Aissey",
                "label_ar": "ايسي",
                "label_fr": "Aissey"
            },
            {
                "value": 31101,
                "label": "Hipsheim",
                "label_ar": "هيبشيم",
                "label_fr": "Hipsheim"
            },
            {
                "value": 31102,
                "label": "Wildersbach",
                "label_ar": "فيلدرسباخ",
                "label_fr": "Wildersbach"
            },
            {
                "value": 31103,
                "label": "La Veze",
                "label_ar": "لا فيز",
                "label_fr": "La Vèze"
            },
            {
                "value": 31104,
                "label": "Chevigny",
                "label_ar": "تشيفيني",
                "label_fr": "Chevigny"
            },
            {
                "value": 31105,
                "label": "Manom",
                "label_ar": "مانوم",
                "label_fr": "Manom"
            },
            {
                "value": 31106,
                "label": "Saint-Pancre",
                "label_ar": "سان بانكر",
                "label_fr": "Saint-Pancre"
            },
            {
                "value": 31107,
                "label": "Repaix",
                "label_ar": "Repaix",
                "label_fr": "Repaix"
            },
            {
                "value": 31108,
                "label": "Denting",
                "label_ar": "الطعن",
                "label_fr": "Denting"
            },
            {
                "value": 31109,
                "label": "Uruffe",
                "label_ar": "أوروف",
                "label_fr": "Uruffe"
            },
            {
                "value": 31110,
                "label": "Lemmes",
                "label_ar": "ليمس",
                "label_fr": "Lemmes"
            },
            {
                "value": 31111,
                "label": "Saint-Pierre-Bois",
                "label_ar": "سان بيير بوا",
                "label_fr": "Saint-Pierre-Bois"
            },
            {
                "value": 31112,
                "label": "Holving",
                "label_ar": "حل",
                "label_fr": "Holving"
            },
            {
                "value": 31113,
                "label": "Saint-Aubin-les-Forges",
                "label_ar": "سان أوبين ليه فورج",
                "label_fr": "Saint-Aubin-les-Forges"
            },
            {
                "value": 31114,
                "label": "Miellin",
                "label_ar": "ميلين",
                "label_fr": "Miellin"
            },
            {
                "value": 31115,
                "label": "Fromont",
                "label_ar": "فرومونت",
                "label_fr": "Fromont"
            },
            {
                "value": 31116,
                "label": "Guercheville",
                "label_ar": "جويرشيفيل",
                "label_fr": "Guercheville"
            },
            {
                "value": 31117,
                "label": "Beaumont-du-Gatinais",
                "label_ar": "بومونت دو جاتينيس",
                "label_fr": "Beaumont-du-Gatinais"
            },
            {
                "value": 31118,
                "label": "Chevrainvilliers",
                "label_ar": "Chevrainvilliers",
                "label_fr": "Chevrainvilliers"
            },
            {
                "value": 31119,
                "label": "Recloses",
                "label_ar": "يعيد",
                "label_fr": "Ferme"
            },
            {
                "value": 31120,
                "label": "Froncles",
                "label_ar": "Froncles",
                "label_fr": "Froncles"
            },
            {
                "value": 31121,
                "label": "Mesnil-Sellieres",
                "label_ar": "Mesnil-Sellieres",
                "label_fr": "Mesnil-Sellieres"
            },
            {
                "value": 31122,
                "label": "Saint-Leu-d'Esserent",
                "label_ar": "Saint-Leu-d'Esserent",
                "label_fr": "Saint-Leu-d'Esserent"
            },
            {
                "value": 31123,
                "label": "Saint-Gratien",
                "label_ar": "سانت جراتين",
                "label_fr": "Saint-Gratien"
            },
            {
                "value": 31124,
                "label": "Iwuy",
                "label_ar": "Iwuy",
                "label_fr": "Iwuy"
            },
            {
                "value": 31125,
                "label": "Montmorency-Beaufort",
                "label_ar": "مونتمورنسي بوفورت",
                "label_fr": "Montmorency-Beaufort"
            },
            {
                "value": 31126,
                "label": "Celles-en-Bassigny",
                "label_ar": "Celles-en-Bassigny",
                "label_fr": "Celles-en-Bassigny"
            },
            {
                "value": 31127,
                "label": "Pommiers",
                "label_ar": "بوميرس",
                "label_fr": "Pommiers"
            },
            {
                "value": 31128,
                "label": "Villers-sous-Saint-Leu",
                "label_ar": "فيليرز سو سان ليو",
                "label_fr": "Villers-sous-Saint-Leu"
            },
            {
                "value": 31129,
                "label": "Oisy",
                "label_ar": "أويسي",
                "label_fr": "Oisy"
            },
            {
                "value": 31130,
                "label": "Calonne-sur-la-Lys",
                "label_ar": "كالون سور لا ليس",
                "label_fr": "Calonne-sur-la-Lys"
            },
            {
                "value": 31131,
                "label": "Jonchery-sur-Vesle",
                "label_ar": "Jonchery-sur-Vesle",
                "label_fr": "Jonchery-sur-Vesle"
            },
            {
                "value": 31132,
                "label": "Roches-sur-Marne",
                "label_ar": "روشيس سور مارن",
                "label_fr": "Roches-sur-Marne"
            },
            {
                "value": 31133,
                "label": "Hautvillers",
                "label_ar": "Hautvillers",
                "label_fr": "Hautvillers"
            },
            {
                "value": 31134,
                "label": "Rumigny",
                "label_ar": "روميني",
                "label_fr": "Rumigny"
            },
            {
                "value": 31135,
                "label": "Moy-de-l'Aisne",
                "label_ar": "موي دي لايسن",
                "label_fr": "Moy-de-l'Aisne"
            },
            {
                "value": 31136,
                "label": "Duisans",
                "label_ar": "دويزانس",
                "label_fr": "Duisans"
            },
            {
                "value": 31137,
                "label": "Godewaersvelde",
                "label_ar": "Godewaersvelde",
                "label_fr": "Godewaersvelde"
            },
            {
                "value": 31138,
                "label": "Saint-Aubert",
                "label_ar": "سان أوبيرت",
                "label_fr": "Saint-Aubert"
            },
            {
                "value": 31139,
                "label": "Ames",
                "label_ar": "أميس",
                "label_fr": "Ames"
            },
            {
                "value": 31140,
                "label": "Fournival",
                "label_ar": "فورنيفال",
                "label_fr": "Fournival"
            },
            {
                "value": 31141,
                "label": "Moyenneville",
                "label_ar": "موينفيل",
                "label_fr": "Moyenneville"
            },
            {
                "value": 31142,
                "label": "Saint-Imoges",
                "label_ar": "سانت إيموج",
                "label_fr": "Saint-Imoges"
            },
            {
                "value": 31143,
                "label": "Bourg-Sainte-Marie",
                "label_ar": "بورغ سانت ماري",
                "label_fr": "Bourg-Sainte-Marie"
            },
            {
                "value": 31144,
                "label": "Valdampierre",
                "label_ar": "فالدامبيير",
                "label_fr": "Valdampierre"
            },
            {
                "value": 31145,
                "label": "Vignacourt",
                "label_ar": "فيجناكورت",
                "label_fr": "Vignacourt"
            },
            {
                "value": 31146,
                "label": "Bussy-les-Daours",
                "label_ar": "بوسي ليه دور",
                "label_fr": "Bussy-les-Daours"
            },
            {
                "value": 31147,
                "label": "Goincourt",
                "label_ar": "Goincourt",
                "label_fr": "Goincourt"
            },
            {
                "value": 31148,
                "label": "Anais",
                "label_ar": "أنايس",
                "label_fr": "Anaïs"
            },
            {
                "value": 31149,
                "label": "Arces",
                "label_ar": "آريس",
                "label_fr": "Arces"
            },
            {
                "value": 31150,
                "label": "Saint-Priest-Taurion",
                "label_ar": "سانت بريست توريون",
                "label_fr": "Saint-Priest-Taurion"
            },
            {
                "value": 31151,
                "label": "Chateauneuf-la-Foret",
                "label_ar": "شاتونوف لا فوريه",
                "label_fr": "Châteauneuf-la-Foret"
            },
            {
                "value": 31152,
                "label": "Chatenet",
                "label_ar": "شاتينت",
                "label_fr": "Chatenet"
            },
            {
                "value": 31153,
                "label": "Luxey",
                "label_ar": "لوكسي",
                "label_fr": "Luxey"
            },
            {
                "value": 31154,
                "label": "Masparraute",
                "label_ar": "Masparraute",
                "label_fr": "Masparraute"
            },
            {
                "value": 31155,
                "label": "Escout",
                "label_ar": "هروب",
                "label_fr": "Escout"
            },
            {
                "value": 31156,
                "label": "Aramits",
                "label_ar": "أراميتس",
                "label_fr": "Aramits"
            },
            {
                "value": 31157,
                "label": "Poey-de-Lescar",
                "label_ar": "بوي دي ليسكار",
                "label_fr": "Poey-de-Lescar"
            },
            {
                "value": 31158,
                "label": "Yzosse",
                "label_ar": "Yzosse",
                "label_fr": "Yzosse"
            },
            {
                "value": 31159,
                "label": "Urdos",
                "label_ar": "أردوس",
                "label_fr": "Urdos"
            },
            {
                "value": 31160,
                "label": "Heugas",
                "label_ar": "هيوغاس",
                "label_fr": "Heugas"
            },
            {
                "value": 31161,
                "label": "Agnos",
                "label_ar": "اجنوس",
                "label_fr": "Agnos"
            },
            {
                "value": 31162,
                "label": "Suhescun",
                "label_ar": "Suhescun",
                "label_fr": "Suhescun"
            },
            {
                "value": 31163,
                "label": "Mornac-sur-Seudre",
                "label_ar": "مورناك سور سودري",
                "label_fr": "Mornac-sur-Seudre"
            },
            {
                "value": 31164,
                "label": "Masseret",
                "label_ar": "ماسريت",
                "label_fr": "Masseret"
            },
            {
                "value": 31165,
                "label": "Salles-la-Source",
                "label_ar": "ساليس لا سورس",
                "label_fr": "Salles-la-Source"
            },
            {
                "value": 31166,
                "label": "Montestruc-sur-Gers",
                "label_ar": "مونتستروك سور جيرس",
                "label_fr": "Montestruc-sur-Gers"
            },
            {
                "value": 31167,
                "label": "Saint-Creac",
                "label_ar": "سان كريك",
                "label_fr": "Saint-Creac"
            },
            {
                "value": 31168,
                "label": "Lacabarede",
                "label_ar": "لاكاباريد",
                "label_fr": "Lacabarède"
            },
            {
                "value": 31169,
                "label": "Guitalens",
                "label_ar": "Guitalens",
                "label_fr": "Guitalens"
            },
            {
                "value": 31170,
                "label": "Lombez",
                "label_ar": "لومبيز",
                "label_fr": "Lombez"
            },
            {
                "value": 31171,
                "label": "Serres-Castet",
                "label_ar": "سيريس كاستيت",
                "label_fr": "Serres-Castet"
            },
            {
                "value": 31172,
                "label": "Bagnères-de-Luchon",
                "label_ar": "Bagnères-de-Luchon",
                "label_fr": "Bagnères-de-Luchon"
            },
            {
                "value": 31173,
                "label": "Saint-Georges-de-Poisieux",
                "label_ar": "Saint-Georges-de-Poisieux",
                "label_fr": "Saint-Georges-de-Poisieux"
            },
            {
                "value": 31174,
                "label": "Mauze-sur-le-Mignon",
                "label_ar": "ماوز سور لو مينون",
                "label_fr": "Mauze-sur-le-Mignon"
            },
            {
                "value": 31175,
                "label": "Sereilhac",
                "label_ar": "سيريلاك",
                "label_fr": "Sereilhac"
            },
            {
                "value": 31176,
                "label": "Paizay-le-Tort",
                "label_ar": "بيزاي لو تورت",
                "label_fr": "Paizay-le-Tort"
            },
            {
                "value": 31177,
                "label": "Messanges",
                "label_ar": "ميسانج",
                "label_fr": "Messanges"
            },
            {
                "value": 31178,
                "label": "Arette",
                "label_ar": "آريت",
                "label_fr": "Arette"
            },
            {
                "value": 31179,
                "label": "Levignacq",
                "label_ar": "ليفينياك",
                "label_fr": "Lévignacq"
            },
            {
                "value": 31180,
                "label": "Serres-Sainte-Marie",
                "label_ar": "سيريس سانت ماري",
                "label_fr": "Serres-Sainte-Marie"
            },
            {
                "value": 31181,
                "label": "Orsanco",
                "label_ar": "أورسانكو",
                "label_fr": "Orsanco"
            },
            {
                "value": 31182,
                "label": "Theze",
                "label_ar": "ثيز",
                "label_fr": "Theze"
            },
            {
                "value": 31183,
                "label": "Undurein",
                "label_ar": "وندورين",
                "label_fr": "Undurein"
            },
            {
                "value": 31184,
                "label": "Cabariot",
                "label_ar": "كاباريوت",
                "label_fr": "Cabariot"
            },
            {
                "value": 31185,
                "label": "Mosnac",
                "label_ar": "Mosnac",
                "label_fr": "Mosnac"
            },
            {
                "value": 31186,
                "label": "Gente",
                "label_ar": "جينت",
                "label_fr": "Gente"
            },
            {
                "value": 31187,
                "label": "Saint-Clar-de-Riviere",
                "label_ar": "سان كلار دي ريفيير",
                "label_fr": "Saint-Clar-de-Rivière"
            },
            {
                "value": 31188,
                "label": "Donzacq",
                "label_ar": "دونزاك",
                "label_fr": "Donzacq"
            },
            {
                "value": 31189,
                "label": "Lagor",
                "label_ar": "لاغور",
                "label_fr": "Lagor"
            },
            {
                "value": 31190,
                "label": "Montrabe",
                "label_ar": "مونتراب",
                "label_fr": "Montrabe"
            },
            {
                "value": 31191,
                "label": "Villebrumier",
                "label_ar": "فيلبرومييه",
                "label_fr": "Villebrumier"
            },
            {
                "value": 31192,
                "label": "Saint-Viance",
                "label_ar": "سانت فيانس",
                "label_fr": "Saint-Viance"
            },
            {
                "value": 31193,
                "label": "Esnandes",
                "label_ar": "اسنانديس",
                "label_fr": "Esnandes"
            },
            {
                "value": 31194,
                "label": "Mieussy",
                "label_ar": "ميوسي",
                "label_fr": "Mieussy"
            },
            {
                "value": 31195,
                "label": "Frangy",
                "label_ar": "فرانجي",
                "label_fr": "Frangy"
            },
            {
                "value": 31196,
                "label": "Champanges",
                "label_ar": "شامبانج",
                "label_fr": "Champanges"
            },
            {
                "value": 31197,
                "label": "Sergy",
                "label_ar": "سيرجي",
                "label_fr": "Sergy"
            },
            {
                "value": 31198,
                "label": "Montagnole",
                "label_ar": "مونتاجنول",
                "label_fr": "Montagnole"
            },
            {
                "value": 31199,
                "label": "Genissiat",
                "label_ar": "جينيسيات",
                "label_fr": "Genissiat"
            },
            {
                "value": 31200,
                "label": "Thusy",
                "label_ar": "ثوسي",
                "label_fr": "Thusy"
            },
            {
                "value": 31201,
                "label": "Vaumas",
                "label_ar": "فوما",
                "label_fr": "Vaumas"
            },
            {
                "value": 31202,
                "label": "Venthon",
                "label_ar": "فنثون",
                "label_fr": "Venthon"
            },
            {
                "value": 31203,
                "label": "Habere-Lullin",
                "label_ar": "هابر لولين",
                "label_fr": "Habère-Lullin"
            },
            {
                "value": 31204,
                "label": "Etaux",
                "label_ar": "إتوكس",
                "label_fr": "Etaux"
            },
            {
                "value": 31205,
                "label": "Saint-Yorre",
                "label_ar": "سان يوري",
                "label_fr": "Saint-Yorre"
            },
            {
                "value": 31206,
                "label": "Bellentre",
                "label_ar": "بيلنتر",
                "label_fr": "Bellentre"
            },
            {
                "value": 31207,
                "label": "Etercy",
                "label_ar": "اتيرسي",
                "label_fr": "Etercy"
            },
            {
                "value": 31208,
                "label": "Faverges",
                "label_ar": "فافيرجز",
                "label_fr": "Faverges"
            },
            {
                "value": 31209,
                "label": "Chapareillan",
                "label_ar": "شاباريلان",
                "label_fr": "Chapareillan"
            },
            {
                "value": 31210,
                "label": "Seyssel",
                "label_ar": "سيسيل",
                "label_fr": "Seyssel"
            },
            {
                "value": 31211,
                "label": "Allinges",
                "label_ar": "ألينج",
                "label_fr": "Allinges"
            },
            {
                "value": 31212,
                "label": "La Lechere",
                "label_ar": "لا ليتشر",
                "label_fr": "La Lechere"
            },
            {
                "value": 31213,
                "label": "Franclens",
                "label_ar": "فرانكلينز",
                "label_fr": "Franclens"
            },
            {
                "value": 31214,
                "label": "Doussard",
                "label_ar": "دوسارد",
                "label_fr": "Doussard"
            },
            {
                "value": 31215,
                "label": "Saint-Alban-du-Rhone",
                "label_ar": "سان ألبان دو رون",
                "label_fr": "Saint-Alban-du-Rhône"
            },
            {
                "value": 31216,
                "label": "Saint-Jeoire-Prieure",
                "label_ar": "سان جوير بريور",
                "label_fr": "Saint-Jeoire-Prieuré"
            },
            {
                "value": 31217,
                "label": "Taninges",
                "label_ar": "دباغة",
                "label_fr": "Taninges"
            },
            {
                "value": 31218,
                "label": "Les Gets",
                "label_ar": "ليه يحصل",
                "label_fr": "Les Gets"
            },
            {
                "value": 31219,
                "label": "Peillonnex",
                "label_ar": "بيلونيكس",
                "label_fr": "Peillonnex"
            },
            {
                "value": 31220,
                "label": "Jonzier-Epagny",
                "label_ar": "Jonzier-Epagny",
                "label_fr": "Jonzier-Epagny"
            },
            {
                "value": 31221,
                "label": "Ballaison",
                "label_ar": "باليزون",
                "label_fr": "Ballaison"
            },
            {
                "value": 31222,
                "label": "Amancy",
                "label_ar": "امانسي",
                "label_fr": "Amancy"
            },
            {
                "value": 31223,
                "label": "Collonges-sous-Saleve",
                "label_ar": "Collonges-sous-Saleve",
                "label_fr": "Collonges-sous-Saleve"
            },
            {
                "value": 31224,
                "label": "Trezioux",
                "label_ar": "Trezioux",
                "label_fr": "Trezioux"
            },
            {
                "value": 31225,
                "label": "Corbonod",
                "label_ar": "كوربونود",
                "label_fr": "Corbonod"
            },
            {
                "value": 31226,
                "label": "Vailly",
                "label_ar": "فيللي",
                "label_fr": "Vailly"
            },
            {
                "value": 31227,
                "label": "Charvonnex",
                "label_ar": "شارفونيكس",
                "label_fr": "Charvonnex"
            },
            {
                "value": 31228,
                "label": "La Rochette-sur-Crest",
                "label_ar": "لاروشيت سور كريست",
                "label_fr": "La Rochette-sur-Crest"
            },
            {
                "value": 31229,
                "label": "Thones",
                "label_ar": "عروش",
                "label_fr": "Thones"
            },
            {
                "value": 31230,
                "label": "Cercier",
                "label_ar": "سيرسير",
                "label_fr": "Cercier"
            },
            {
                "value": 31231,
                "label": "Morzine",
                "label_ar": "مورزين",
                "label_fr": "Morzine"
            },
            {
                "value": 31232,
                "label": "Chamonix",
                "label_ar": "شاموني",
                "label_fr": "Chamonix"
            },
            {
                "value": 31233,
                "label": "Marin",
                "label_ar": "مارين",
                "label_fr": "Marin"
            },
            {
                "value": 31234,
                "label": "Megève",
                "label_ar": "ميجيف",
                "label_fr": "Megève"
            },
            {
                "value": 31235,
                "label": "Thorens-Glieres",
                "label_ar": "تورين جليريس",
                "label_fr": "Thorens-Glières"
            },
            {
                "value": 31236,
                "label": "Le Bois",
                "label_ar": "لو بوا",
                "label_fr": "Le Bois"
            },
            {
                "value": 31237,
                "label": "Gerbaix",
                "label_ar": "جيربايكس",
                "label_fr": "Gerbaix"
            },
            {
                "value": 31238,
                "label": "Saint-Offenge-Dessus",
                "label_ar": "سان أوفنجي ديسوس",
                "label_fr": "Saint-Offenge-Dessus"
            },
            {
                "value": 31239,
                "label": "Onnion",
                "label_ar": "البصل",
                "label_fr": "Oignon"
            },
            {
                "value": 31240,
                "label": "Boege",
                "label_ar": "بويج",
                "label_fr": "Boege"
            },
            {
                "value": 31241,
                "label": "Bonneguete",
                "label_ar": "بونيغيتي",
                "label_fr": "Bonneguete"
            },
            {
                "value": 31242,
                "label": "Mazeuil",
                "label_ar": "متاهة",
                "label_fr": "Mazeuil"
            },
            {
                "value": 31243,
                "label": "Pers",
                "label_ar": "بيرس",
                "label_fr": "Pers"
            },
            {
                "value": 31244,
                "label": "Mauvezin-de-Prat",
                "label_ar": "موفيزين دي برات",
                "label_fr": "Mauvezin-de-Prat"
            },
            {
                "value": 31245,
                "label": "Saint-Martin-de-Lenne",
                "label_ar": "سان مارتن دي لين",
                "label_fr": "Saint-Martin-de-Lenne"
            },
            {
                "value": 31246,
                "label": "Saint-Rome-de-Tarn",
                "label_ar": "سان روما دي تارن",
                "label_fr": "Saint-Rome-de-Tarn"
            },
            {
                "value": 31247,
                "label": "Saint-Jean-d'Aigues-Vives",
                "label_ar": "سان جان دايج فيفيس",
                "label_fr": "Saint-Jean-d'Aigues-Vives"
            },
            {
                "value": 31248,
                "label": "Montgesty",
                "label_ar": "مونجيستي",
                "label_fr": "Montgesty"
            },
            {
                "value": 31249,
                "label": "Terssac",
                "label_ar": "ترساك",
                "label_fr": "Terssac"
            },
            {
                "value": 31250,
                "label": "Lavardens",
                "label_ar": "لافاردنز",
                "label_fr": "Lavardens"
            },
            {
                "value": 31251,
                "label": "Saint-Amans-Valtoret",
                "label_ar": "سانت امانس فالتوريت",
                "label_fr": "Saint-Amans-Valtoret"
            },
            {
                "value": 31252,
                "label": "Barbezieux-Saint-Hilaire",
                "label_ar": "باربيزيو سانت هيلير",
                "label_fr": "Barbezieux-Saint-Hilaire"
            },
            {
                "value": 31253,
                "label": "Mignaloux-Beauvoir",
                "label_ar": "مينالوكس بوفوار",
                "label_fr": "Mignaloux-Beauvoir"
            },
            {
                "value": 31254,
                "label": "Saint-Secondin",
                "label_ar": "سانت سكندين",
                "label_fr": "Saint-Secondin"
            },
            {
                "value": 31255,
                "label": "Auterive",
                "label_ar": "أوتيريفي",
                "label_fr": "Auterive"
            },
            {
                "value": 31256,
                "label": "Gouts",
                "label_ar": "النقرس",
                "label_fr": "Les gouttes"
            },
            {
                "value": 31257,
                "label": "Vignes",
                "label_ar": "تزين",
                "label_fr": "Vignes"
            },
            {
                "value": 31258,
                "label": "Siros",
                "label_ar": "سيروس",
                "label_fr": "Siros"
            },
            {
                "value": 31259,
                "label": "Pouille",
                "label_ar": "بويل",
                "label_fr": "Pouille"
            },
            {
                "value": 31260,
                "label": "Betaille",
                "label_ar": "Betaille",
                "label_fr": "Bétaille"
            },
            {
                "value": 31261,
                "label": "La Sauve",
                "label_ar": "لا ساف",
                "label_fr": "La Sauve"
            },
            {
                "value": 31262,
                "label": "Lavaur",
                "label_ar": "لافور",
                "label_fr": "Lavaur"
            },
            {
                "value": 31263,
                "label": "Ouzilly",
                "label_ar": "اوزيلي",
                "label_fr": "Ouzilly"
            },
            {
                "value": 31264,
                "label": "Rieumes",
                "label_ar": "ريومز",
                "label_fr": "Rieumes"
            },
            {
                "value": 31265,
                "label": "Borderes",
                "label_ar": "الحدود",
                "label_fr": "Borderes"
            },
            {
                "value": 31266,
                "label": "Labastidette",
                "label_ar": "لاباستيديت",
                "label_fr": "Labastidette"
            },
            {
                "value": 31267,
                "label": "Lautrec",
                "label_ar": "لوتريك",
                "label_fr": "Lautrec"
            },
            {
                "value": 31268,
                "label": "Pont-de-Larn",
                "label_ar": "بونت دي لارن",
                "label_fr": "Pont-de-Larn"
            },
            {
                "value": 31269,
                "label": "Peyrusse-Massas",
                "label_ar": "بيروس ماساس",
                "label_fr": "Peyrusse-Massas"
            },
            {
                "value": 31270,
                "label": "Piquecos",
                "label_ar": "بيكيكوس",
                "label_fr": "Piquecos"
            },
            {
                "value": 31271,
                "label": "Jegun",
                "label_ar": "جيجون",
                "label_fr": "Jegun"
            },
            {
                "value": 31272,
                "label": "Lanta",
                "label_ar": "لانتا",
                "label_fr": "Lanta"
            },
            {
                "value": 31273,
                "label": "Loubille",
                "label_ar": "لوبيل",
                "label_fr": "Loubille"
            },
            {
                "value": 31274,
                "label": "Neuvic-Entier",
                "label_ar": "نيوفيك انتير",
                "label_fr": "Neuvic-Entier"
            },
            {
                "value": 31275,
                "label": "Pimbo",
                "label_ar": "بيمبو",
                "label_fr": "Pimbo"
            },
            {
                "value": 31276,
                "label": "Saint-Nicolas-de-la-Grave",
                "label_ar": "سان نيكولا دي لا غراف",
                "label_fr": "Saint-Nicolas-de-la-Grave"
            },
            {
                "value": 31277,
                "label": "Mas-Grenier",
                "label_ar": "ماس جرينير",
                "label_fr": "Mas-Grenier"
            },
            {
                "value": 31278,
                "label": "Villegailhenc",
                "label_ar": "فيليجيلهنك",
                "label_fr": "Villegailhenc"
            },
            {
                "value": 31279,
                "label": "Perillos",
                "label_ar": "بيريوس",
                "label_fr": "Périllos"
            },
            {
                "value": 31280,
                "label": "Fontpedrosa",
                "label_ar": "Fontpedrosa",
                "label_fr": "Fontpedrosa"
            },
            {
                "value": 31281,
                "label": "Montesquieu-des-Alberes",
                "label_ar": "مونتسكيو دي ألبريس",
                "label_fr": "Montesquieu-des-Albères"
            },
            {
                "value": 31282,
                "label": "Corneilla-del-Vercol",
                "label_ar": "كورنييلا ديل فيركول",
                "label_fr": "Corneilla-del-Vercol"
            },
            {
                "value": 31283,
                "label": "Homps",
                "label_ar": "هومبس",
                "label_fr": "Homps"
            },
            {
                "value": 31284,
                "label": "Sainte-Anastasie-sur-Issole",
                "label_ar": "سانت أناستاسي سور إيسول",
                "label_fr": "Sainte-Anastasie-sur-Issole"
            },
            {
                "value": 31285,
                "label": "Moussoulens",
                "label_ar": "موسولين",
                "label_fr": "Moussoulens"
            },
            {
                "value": 31286,
                "label": "Domessargues",
                "label_ar": "دوميسارج",
                "label_fr": "Domessargues"
            },
            {
                "value": 31287,
                "label": "Tuchan",
                "label_ar": "توتشان",
                "label_fr": "Tuchan"
            },
            {
                "value": 31288,
                "label": "Popian",
                "label_ar": "بوبيان",
                "label_fr": "Popian"
            },
            {
                "value": 31289,
                "label": "Beaufort",
                "label_ar": "بوفورت",
                "label_fr": "Beaufort"
            },
            {
                "value": 31290,
                "label": "La Caunette",
                "label_ar": "لا كاونيت",
                "label_fr": "La Caunette"
            },
            {
                "value": 31291,
                "label": "Asperes",
                "label_ar": "أسبيريس",
                "label_fr": "Asperes"
            },
            {
                "value": 31292,
                "label": "el Volo",
                "label_ar": "فولو",
                "label_fr": "el Volo"
            },
            {
                "value": 31293,
                "label": "Saint-Papoul",
                "label_ar": "سان بابول",
                "label_fr": "Saint-Papoul"
            },
            {
                "value": 31294,
                "label": "Garons",
                "label_ar": "جارونات",
                "label_fr": "Garons"
            },
            {
                "value": 31295,
                "label": "Peyriac-Minervois",
                "label_ar": "بيرياك مينيرفوا",
                "label_fr": "Peyriac-Minervois"
            },
            {
                "value": 31296,
                "label": "Verzeille",
                "label_ar": "Verzeille",
                "label_fr": "Verzeille"
            },
            {
                "value": 31297,
                "label": "Tourbes",
                "label_ar": "توربيس",
                "label_fr": "Tourbes"
            },
            {
                "value": 31298,
                "label": "Assas",
                "label_ar": "أساس",
                "label_fr": "Assas"
            },
            {
                "value": 31299,
                "label": "Peret",
                "label_ar": "بيريت",
                "label_fr": "Péret"
            },
            {
                "value": 31300,
                "label": "Mons",
                "label_ar": "مونس",
                "label_fr": "Mons"
            },
            {
                "value": 31301,
                "label": "Alzonne",
                "label_ar": "ألزون",
                "label_fr": "Alzonne"
            },
            {
                "value": 31302,
                "label": "Aigaliers",
                "label_ar": "إيغالييرز",
                "label_fr": "Aigaliers"
            },
            {
                "value": 31303,
                "label": "Notre-Dame-de-la-Rouviere",
                "label_ar": "نوتردام دي لا روفيير",
                "label_fr": "Notre-Dame-de-la-Rouvière"
            },
            {
                "value": 31304,
                "label": "Les Fonts",
                "label_ar": "ليه الخطوط",
                "label_fr": "Les Fonts"
            },
            {
                "value": 31305,
                "label": "Collias",
                "label_ar": "كولياس",
                "label_fr": "Collias"
            },
            {
                "value": 31306,
                "label": "Cuxac-Cabardes",
                "label_ar": "كوزاك كابارديس",
                "label_fr": "Cuxac-Cabardes"
            },
            {
                "value": 31307,
                "label": "Villeneuve-la-Riviere",
                "label_ar": "فيلنوف لا ريفيير",
                "label_fr": "Villeneuve-la-Rivière"
            },
            {
                "value": 31308,
                "label": "Saint-Bauzille-de-Putois",
                "label_ar": "سان بوزيل دي بوتوا",
                "label_fr": "Saint-Bauzille-de-Putois"
            },
            {
                "value": 31309,
                "label": "Annot",
                "label_ar": "Annot",
                "label_fr": "Annot"
            },
            {
                "value": 31310,
                "label": "Roquesteron",
                "label_ar": "روكويستيرون",
                "label_fr": "Roquesteron"
            },
            {
                "value": 31311,
                "label": "La Motte-du-Caire",
                "label_ar": "لا موت دو كاير",
                "label_fr": "La Motte-du-Caire"
            },
            {
                "value": 31312,
                "label": "Longchamp",
                "label_ar": "لونجشامب",
                "label_fr": "Longchamp"
            },
            {
                "value": 31313,
                "label": "Amagney",
                "label_ar": "اماجني",
                "label_fr": "Amagney"
            },
            {
                "value": 31314,
                "label": "Montagney",
                "label_ar": "مونتاجني",
                "label_fr": "Montagney"
            },
            {
                "value": 31315,
                "label": "La Balme-d'Epy",
                "label_ar": "لا بالم دي إبي",
                "label_fr": "La Balme-d'Epy"
            },
            {
                "value": 31316,
                "label": "Maiche",
                "label_ar": "مايشي",
                "label_fr": "Maiche"
            },
            {
                "value": 31317,
                "label": "Renève",
                "label_ar": "رينيف",
                "label_fr": "Renève"
            },
            {
                "value": 31318,
                "label": "Fallerans",
                "label_ar": "فاليرانز",
                "label_fr": "Fallerans"
            },
            {
                "value": 31319,
                "label": "Meroux",
                "label_ar": "مرو",
                "label_fr": "Meroux"
            },
            {
                "value": 31320,
                "label": "Plottes",
                "label_ar": "المؤامرات",
                "label_fr": "Plottes"
            },
            {
                "value": 31321,
                "label": "Montcey",
                "label_ar": "مونتسي",
                "label_fr": "Montcey"
            },
            {
                "value": 31322,
                "label": "Faucogney-et-la-Mer",
                "label_ar": "Faucogney-et-la-Mer",
                "label_fr": "Faucogney-et-la-Mer"
            },
            {
                "value": 31323,
                "label": "Sellieres",
                "label_ar": "Sellieres",
                "label_fr": "Sellieres"
            },
            {
                "value": 31324,
                "label": "Tamnay-en-Bazois",
                "label_ar": "تمناي ان بازوا",
                "label_fr": "Tamnay-en-Bazois"
            },
            {
                "value": 31325,
                "label": "Liverdun",
                "label_ar": "ليفردون",
                "label_fr": "Liverdun"
            },
            {
                "value": 31326,
                "label": "Hellimer",
                "label_ar": "هيلمر",
                "label_fr": "Hellimer"
            },
            {
                "value": 31327,
                "label": "Jambles",
                "label_ar": "المقامر",
                "label_fr": "Jambles"
            },
            {
                "value": 31328,
                "label": "Les Deux-Fays",
                "label_ar": "ليه دوكس فاي",
                "label_fr": "Les Deux-Fays"
            },
            {
                "value": 31329,
                "label": "Champs-sur-Yonne",
                "label_ar": "تشامب سور يون",
                "label_fr": "Champs-sur-Yonne"
            },
            {
                "value": 31330,
                "label": "Saint-Symphorien-d'Ancelles",
                "label_ar": "سان سيمفوريان دانسيل",
                "label_fr": "Saint-Symphorien-d'Ancelles"
            },
            {
                "value": 31331,
                "label": "Le Bonhomme",
                "label_ar": "لو بونهوم",
                "label_fr": "Le Bonhomme"
            },
            {
                "value": 31332,
                "label": "Wihr-au-Val",
                "label_ar": "وير أو فال",
                "label_fr": "Wihr-au-Val"
            },
            {
                "value": 31333,
                "label": "Loupershouse",
                "label_ar": "لوبرسهاوس",
                "label_fr": "Loupershouse"
            },
            {
                "value": 31334,
                "label": "Freistroff",
                "label_ar": "فريستروف",
                "label_fr": "Freistroff"
            },
            {
                "value": 31335,
                "label": "Saint-Jean-Kourtzerode",
                "label_ar": "سان جان كورتزرود",
                "label_fr": "Saint-Jean-Kourtzerode"
            },
            {
                "value": 31336,
                "label": "Zimming",
                "label_ar": "زيمينج",
                "label_fr": "Zimming"
            },
            {
                "value": 31337,
                "label": "Clenay",
                "label_ar": "كليناي",
                "label_fr": "Clenay"
            },
            {
                "value": 31338,
                "label": "Walscheid",
                "label_ar": "والشيد",
                "label_fr": "Walscheid"
            },
            {
                "value": 31339,
                "label": "Belleville-sur-Meuse",
                "label_ar": "بيلفيل سور ميوز",
                "label_fr": "Belleville-sur-Meuse"
            },
            {
                "value": 31340,
                "label": "Rosenwiller",
                "label_ar": "روزنويلر",
                "label_fr": "Rosenwiller"
            },
            {
                "value": 31341,
                "label": "Dampierre-les-Bois",
                "label_ar": "Dampierre-les-Bois",
                "label_fr": "Dampierre-les-Bois"
            },
            {
                "value": 31342,
                "label": "Domats",
                "label_ar": "دوماتس",
                "label_fr": "Domats"
            },
            {
                "value": 31343,
                "label": "Velaine-en-Haye",
                "label_ar": "فيلين أون هاي",
                "label_fr": "Velaine-en-Haye"
            },
            {
                "value": 31344,
                "label": "Jolivet",
                "label_ar": "جوليفيت",
                "label_fr": "Jolivet"
            },
            {
                "value": 31345,
                "label": "Hombourg-Budange",
                "label_ar": "هومبورغ بودانج",
                "label_fr": "Hombourg-Budange"
            },
            {
                "value": 31346,
                "label": "Ciel",
                "label_ar": "سيل",
                "label_fr": "Ciel"
            },
            {
                "value": 31347,
                "label": "Pontigny",
                "label_ar": "بونتيني",
                "label_fr": "Pontigny"
            },
            {
                "value": 31348,
                "label": "Romange",
                "label_ar": "رومانج",
                "label_fr": "Romange"
            },
            {
                "value": 31349,
                "label": "Fresne",
                "label_ar": "فرينز",
                "label_fr": "Fresne"
            },
            {
                "value": 31350,
                "label": "Corcelles-les-Citeaux",
                "label_ar": "Corcelles-les-Citeaux",
                "label_fr": "Corcelles-les-Citeaux"
            },
            {
                "value": 31351,
                "label": "Juilly",
                "label_ar": "جويلي",
                "label_fr": "Juilly"
            },
            {
                "value": 31352,
                "label": "Membrey",
                "label_ar": "ممبري",
                "label_fr": "Membrey"
            },
            {
                "value": 31353,
                "label": "Montret",
                "label_ar": "مونتريت",
                "label_fr": "Montret"
            },
            {
                "value": 31354,
                "label": "Velle-le-Chatel",
                "label_ar": "فيل لو شاتيل",
                "label_fr": "Velle-le-Châtel"
            },
            {
                "value": 31355,
                "label": "Arpenans",
                "label_ar": "أربينانس",
                "label_fr": "Arpenans"
            },
            {
                "value": 31356,
                "label": "Saint-Amand-en-Puisaye",
                "label_ar": "Saint-Amand-en-Puisaye",
                "label_fr": "Saint-Amand-en-Puisaye"
            },
            {
                "value": 31357,
                "label": "Savigny-sur-Clairis",
                "label_ar": "Savigny-sur-Clairis",
                "label_fr": "Savigny-sur-Clairis"
            },
            {
                "value": 31358,
                "label": "Beure",
                "label_ar": "بيور",
                "label_fr": "Beure"
            },
            {
                "value": 31359,
                "label": "Arc-et-Senans",
                "label_ar": "Arc-et-Senans",
                "label_fr": "Arc-et-Senans"
            },
            {
                "value": 31360,
                "label": "Boesenbiesen",
                "label_ar": "Boesenbiesen",
                "label_fr": "Boesenbiesen"
            },
            {
                "value": 31361,
                "label": "Breitenbach-Haut-Rhin",
                "label_ar": "بريتنباخ أوت رين",
                "label_fr": "Breitenbach-Haut-Rhin"
            },
            {
                "value": 31362,
                "label": "Kruth",
                "label_ar": "كروث",
                "label_fr": "Kruth"
            },
            {
                "value": 31363,
                "label": "Geneuille",
                "label_ar": "جينويل",
                "label_fr": "Geneuille"
            },
            {
                "value": 31364,
                "label": "Battrans",
                "label_ar": "باترانس",
                "label_fr": "Battrans"
            },
            {
                "value": 31365,
                "label": "Pouilly-en-Auxois",
                "label_ar": "Pouilly-en-Auxois",
                "label_fr": "Pouilly-en-Auxois"
            },
            {
                "value": 31366,
                "label": "Dommartin-les-Toul",
                "label_ar": "دومارتين ليه تول",
                "label_fr": "Dommartin-les-Toul"
            },
            {
                "value": 31367,
                "label": "Durstel",
                "label_ar": "دورستل",
                "label_fr": "Durstel"
            },
            {
                "value": 31368,
                "label": "Ammerzwiller",
                "label_ar": "Ammerzwiller",
                "label_fr": "Ammerzwiller"
            },
            {
                "value": 31369,
                "label": "Callas",
                "label_ar": "كالاس",
                "label_fr": "Callas"
            },
            {
                "value": 31370,
                "label": "Peipin",
                "label_ar": "بيبين",
                "label_fr": "Peipin"
            },
            {
                "value": 31371,
                "label": "Montfroc",
                "label_ar": "مونتفروك",
                "label_fr": "Montfroc"
            },
            {
                "value": 31372,
                "label": "Mison",
                "label_ar": "ميسون",
                "label_fr": "Mison"
            },
            {
                "value": 31373,
                "label": "Tende",
                "label_ar": "تيندي",
                "label_fr": "Tende"
            },
            {
                "value": 31374,
                "label": "Revest-des-Brousses",
                "label_ar": "Revest-des-Brousses",
                "label_fr": "Revest-des-Brousses"
            },
            {
                "value": 31375,
                "label": "Castelnaud-de-Gratecambe",
                "label_ar": "Castelnaud-de-Gratecambe",
                "label_fr": "Castelnaud-de-Gratecambe"
            },
            {
                "value": 31376,
                "label": "Voultegon",
                "label_ar": "فولتيغون",
                "label_fr": "Voultegon"
            },
            {
                "value": 31377,
                "label": "Saint-Medard-en-Forez",
                "label_ar": "سان ميدارد أون فوريز",
                "label_fr": "Saint-Médard-en-Forez"
            },
            {
                "value": 31378,
                "label": "Loubieng",
                "label_ar": "لوبيينج",
                "label_fr": "Loubieng"
            },
            {
                "value": 31379,
                "label": "Ygos-Saint-Saturnin",
                "label_ar": "Ygos-Saint-Saturnin",
                "label_fr": "Ygos-Saint-Saturnin"
            },
            {
                "value": 31380,
                "label": "Came",
                "label_ar": "أتى",
                "label_fr": "Venu"
            },
            {
                "value": 31381,
                "label": "Irissarry",
                "label_ar": "ايريساري",
                "label_fr": "Irissarry"
            },
            {
                "value": 31382,
                "label": "Nere",
                "label_ar": "نير",
                "label_fr": "Nere"
            },
            {
                "value": 31383,
                "label": "Lamaziere-Basse",
                "label_ar": "لامازير باس",
                "label_fr": "Lamazière-Basse"
            },
            {
                "value": 31384,
                "label": "Fressines",
                "label_ar": "فريسنس",
                "label_fr": "Fressines"
            },
            {
                "value": 31385,
                "label": "Escondeaux",
                "label_ar": "إسكوندو",
                "label_fr": "Escondeaux"
            },
            {
                "value": 31386,
                "label": "Marcilhac-sur-Cele",
                "label_ar": "مارسيلاك سور سيلي",
                "label_fr": "Marcilhac-sur-Célé"
            },
            {
                "value": 31387,
                "label": "Noailhac",
                "label_ar": "نويلهاك",
                "label_fr": "Noailhac"
            },
            {
                "value": 31388,
                "label": "Preyssac-d'Excideuil",
                "label_ar": "Preyssac-d'Excideuil",
                "label_fr": "Preyssac-d'Excideuil"
            },
            {
                "value": 31389,
                "label": "Berson",
                "label_ar": "بيرسون",
                "label_fr": "Berson"
            },
            {
                "value": 31390,
                "label": "Saleich",
                "label_ar": "ساليش",
                "label_fr": "Saleich"
            },
            {
                "value": 31391,
                "label": "Angely",
                "label_ar": "بقلق",
                "label_fr": "Angely"
            },
            {
                "value": 31392,
                "label": "Cologne",
                "label_ar": "كولونيا",
                "label_fr": "Eau de Cologne"
            },
            {
                "value": 31393,
                "label": "Biriatou",
                "label_ar": "بيرياتو",
                "label_fr": "Biriatou"
            },
            {
                "value": 31394,
                "label": "Larriviere-Saint-Savin",
                "label_ar": "Larriviere-Saint-Savin",
                "label_fr": "Larrivière-Saint-Savin"
            },
            {
                "value": 31395,
                "label": "Garrosse",
                "label_ar": "جاروس",
                "label_fr": "Garrosse"
            },
            {
                "value": 31396,
                "label": "Exoudun",
                "label_ar": "إكسودون",
                "label_fr": "Exoudun"
            },
            {
                "value": 31397,
                "label": "Saint-Augustin",
                "label_ar": "سانت أوغسطين",
                "label_fr": "Saint-Augustin"
            },
            {
                "value": 31398,
                "label": "Chaillac-sur-Vienne",
                "label_ar": "شيلاك سور فيين",
                "label_fr": "Chaillac-sur-Vienne"
            },
            {
                "value": 31399,
                "label": "Sanxay",
                "label_ar": "سانكساي",
                "label_fr": "Sanxay"
            },
            {
                "value": 31400,
                "label": "Matha",
                "label_ar": "ماثا",
                "label_fr": "Matha"
            },
            {
                "value": 31401,
                "label": "Berdoues",
                "label_ar": "بردوس",
                "label_fr": "Berdoues"
            },
            {
                "value": 31402,
                "label": "Campuac",
                "label_ar": "كامبواك",
                "label_fr": "Campuac"
            },
            {
                "value": 31403,
                "label": "Bach",
                "label_ar": "باخ",
                "label_fr": "Bach"
            },
            {
                "value": 31404,
                "label": "Saint-Jean-de-Laur",
                "label_ar": "سان جان دي لور",
                "label_fr": "Saint-Jean-de-Laur"
            },
            {
                "value": 31405,
                "label": "Belmontet",
                "label_ar": "بلمونت",
                "label_fr": "Belmontet"
            },
            {
                "value": 31406,
                "label": "Sauveterre-la-Lemance",
                "label_ar": "Sauveterre-la-Lemance",
                "label_fr": "Sauveterre-la-Lemance"
            },
            {
                "value": 31407,
                "label": "Narrosse",
                "label_ar": "نارروس",
                "label_fr": "Narrosse"
            },
            {
                "value": 31408,
                "label": "Auvillar",
                "label_ar": "أوفيار",
                "label_fr": "Auvillar"
            },
            {
                "value": 31409,
                "label": "Nanteuil-en-Vallee",
                "label_ar": "Nanteuil-en-Vallee",
                "label_fr": "Nanteuil-en-Vallée"
            },
            {
                "value": 31410,
                "label": "Claix",
                "label_ar": "كلايكس",
                "label_fr": "Claix"
            },
            {
                "value": 31411,
                "label": "Brioude",
                "label_ar": "بريود",
                "label_fr": "Brioude"
            },
            {
                "value": 31412,
                "label": "Laussonne",
                "label_ar": "لوسون",
                "label_fr": "Laussonne"
            },
            {
                "value": 31413,
                "label": "Arpajon-sur-Cere",
                "label_ar": "ارباجون سور سيري",
                "label_fr": "Arpajon-sur-Cère"
            },
            {
                "value": 31414,
                "label": "Le Falgoux",
                "label_ar": "لو فالجوكس",
                "label_fr": "Le Falgoux"
            },
            {
                "value": 31415,
                "label": "Chilhac",
                "label_ar": "تشيلاك",
                "label_fr": "Chilhac"
            },
            {
                "value": 31416,
                "label": "Job",
                "label_ar": "مهنة",
                "label_fr": "Emploi"
            },
            {
                "value": 31417,
                "label": "Orleat",
                "label_ar": "أورليت",
                "label_fr": "Orleat"
            },
            {
                "value": 31418,
                "label": "Teissieres-de-Cornet",
                "label_ar": "تيسيريس دي كورنيه",
                "label_fr": "Teissières-de-Cornet"
            },
            {
                "value": 31419,
                "label": "Saint-Remy-sur-Durolle",
                "label_ar": "سان ريمي سور دورول",
                "label_fr": "Saint-Rémy-sur-Durolle"
            },
            {
                "value": 31420,
                "label": "Ally",
                "label_ar": "حليف",
                "label_fr": "Allié"
            },
            {
                "value": 31421,
                "label": "Aulhat-Saint-Privat",
                "label_ar": "أولهات سانت بريفات",
                "label_fr": "Aulhat-Saint-Privat"
            },
            {
                "value": 31422,
                "label": "Lorlanges",
                "label_ar": "لورلانج",
                "label_fr": "Lorlanges"
            },
            {
                "value": 31423,
                "label": "Tiranges",
                "label_ar": "تيرانج",
                "label_fr": "Tiranges"
            },
            {
                "value": 31424,
                "label": "Le Chambon",
                "label_ar": "لو شامبون",
                "label_fr": "Le Chambon"
            },
            {
                "value": 31425,
                "label": "Seuillet",
                "label_ar": "Seuillet",
                "label_fr": "Seuillet"
            },
            {
                "value": 31426,
                "label": "Couzon",
                "label_ar": "كوزون",
                "label_fr": "Couzon"
            },
            {
                "value": 31427,
                "label": "Vollore-Ville",
                "label_ar": "فولور فيل",
                "label_fr": "Vollore-Ville"
            },
            {
                "value": 31428,
                "label": "Saint-Leopardin-d'Augy",
                "label_ar": "سان ليوباردان دوجي",
                "label_fr": "Saint-Léopardin-d'Augy"
            },
            {
                "value": 31429,
                "label": "Isle-et-Bardais",
                "label_ar": "جزيرة إت بارديس",
                "label_fr": "L'Isle-et-Bardais"
            },
            {
                "value": 31430,
                "label": "Marcillat-en-Combraille",
                "label_ar": "Marcillat-en-Combraille",
                "label_fr": "Marcillat-en-Combraille"
            },
            {
                "value": 31431,
                "label": "Huriel",
                "label_ar": "هوريل",
                "label_fr": "Huriel"
            },
            {
                "value": 31432,
                "label": "Courcais",
                "label_ar": "كورسيه",
                "label_fr": "Courcais"
            },
            {
                "value": 31433,
                "label": "Saint-Germain-des-Fosses",
                "label_ar": "سان جيرمان دي فوس",
                "label_fr": "Saint-Germain-des-Fosses"
            },
            {
                "value": 31434,
                "label": "Saint-Desire",
                "label_ar": "سانت ديزاير",
                "label_fr": "Saint-Désir"
            },
            {
                "value": 31435,
                "label": "Le Mayet",
                "label_ar": "لو ماييت",
                "label_fr": "Le Mayet"
            },
            {
                "value": 31436,
                "label": "Lurcy-Levis",
                "label_ar": "لورسي ليفيس",
                "label_fr": "Lurcy-Levis"
            },
            {
                "value": 31437,
                "label": "Yronde-et-Buron",
                "label_ar": "يروند إي بورون",
                "label_fr": "Yronde-et-Buron"
            },
            {
                "value": 31438,
                "label": "Lapalisse",
                "label_ar": "لاباليس",
                "label_fr": "Lapalisse"
            },
            {
                "value": 31439,
                "label": "Prix",
                "label_ar": "جائزة",
                "label_fr": "Prix"
            },
            {
                "value": 31440,
                "label": "Aydat",
                "label_ar": "عيدات",
                "label_fr": "Aydat"
            },
            {
                "value": 31441,
                "label": "Saint-Pont",
                "label_ar": "سان بونت",
                "label_fr": "Saint-Pont"
            },
            {
                "value": 31442,
                "label": "Saint-Germain-Laprade",
                "label_ar": "سان جيرمان لابراد",
                "label_fr": "Saint-Germain-Laprade"
            },
            {
                "value": 31443,
                "label": "Domaize",
                "label_ar": "دمايز",
                "label_fr": "Domaize"
            },
            {
                "value": 31444,
                "label": "Le Brugeron",
                "label_ar": "لو بروجيرون",
                "label_fr": "Le Brugeron"
            },
            {
                "value": 31445,
                "label": "Monetay-sur-Loire",
                "label_ar": "مونيتي سور لوار",
                "label_fr": "Monétay-sur-Loire"
            },
            {
                "value": 31446,
                "label": "Yssac-la-Tourette",
                "label_ar": "يسك لا توريت",
                "label_fr": "Yssac-la-Tourette"
            },
            {
                "value": 31447,
                "label": "Roannes-Saint-Mary",
                "label_ar": "Roannes-Saint-Mary",
                "label_fr": "Roannes-Saint-Mary"
            },
            {
                "value": 31448,
                "label": "Beauzac",
                "label_ar": "بوزاك",
                "label_fr": "Beauzac"
            },
            {
                "value": 31449,
                "label": "Ronnet",
                "label_ar": "رونيت",
                "label_fr": "Ronnet"
            },
            {
                "value": 31450,
                "label": "Saint-Remy-en-Rollat",
                "label_ar": "سانت ريمي أون رولات",
                "label_fr": "Saint-Rémy-en-Rollat"
            },
            {
                "value": 31451,
                "label": "Chambon-sur-Lac",
                "label_ar": "شامبون سور لاك",
                "label_fr": "Chambon-sur-Lac"
            },
            {
                "value": 31452,
                "label": "Saint-Genes-du-Retz",
                "label_ar": "سان جينيس دو ريتز",
                "label_fr": "Saint-Genes-du-Retz"
            },
            {
                "value": 31453,
                "label": "Saint-Ours",
                "label_ar": "سانت أورز",
                "label_fr": "Saint-Ours"
            },
            {
                "value": 31454,
                "label": "La Chapelle-Laurent",
                "label_ar": "لا شابيل لوران",
                "label_fr": "La Chapelle-Laurent"
            },
            {
                "value": 31455,
                "label": "Nonette",
                "label_ar": "نونيت",
                "label_fr": "Nonette"
            },
            {
                "value": 31456,
                "label": "Montfermy",
                "label_ar": "مونتفيرمي",
                "label_fr": "Montfermy"
            },
            {
                "value": 31457,
                "label": "Ainay-le-Chateau",
                "label_ar": "عيناي لو شاتو",
                "label_fr": "Ainay-le-Château"
            },
            {
                "value": 31458,
                "label": "Mazet-Saint-Voy",
                "label_ar": "مازيت سانت فوي",
                "label_fr": "Mazet-Saint-Voy"
            },
            {
                "value": 31459,
                "label": "Berzet",
                "label_ar": "بيرزيت",
                "label_fr": "Berzet"
            },
            {
                "value": 31460,
                "label": "Pierrefort",
                "label_ar": "بييرفورت",
                "label_fr": "Pierrefort"
            },
            {
                "value": 31461,
                "label": "Servant",
                "label_ar": "خادم",
                "label_fr": "Serviteur"
            },
            {
                "value": 31462,
                "label": "Saint-Jean-des-Ollieres",
                "label_ar": "سان جان دي أوليير",
                "label_fr": "Saint-Jean-des-Ollières"
            },
            {
                "value": 31463,
                "label": "Malrevers",
                "label_ar": "ماليفيرس",
                "label_fr": "Malrevers"
            },
            {
                "value": 31464,
                "label": "Chanteuges",
                "label_ar": "Chanteuges",
                "label_fr": "Chanteuges"
            },
            {
                "value": 31465,
                "label": "Arsac-en-Velay",
                "label_ar": "Arsac-en-Velay",
                "label_fr": "Arsac-en-Velay"
            },
            {
                "value": 31466,
                "label": "Saint-Maigner",
                "label_ar": "سانت مينر",
                "label_fr": "Saint-Maigner"
            },
            {
                "value": 31467,
                "label": "Vertolaye",
                "label_ar": "فيرتولاي",
                "label_fr": "Vertolaye"
            },
            {
                "value": 31468,
                "label": "Saint-Martin-Valmeroux",
                "label_ar": "سان مارتن فالميرو",
                "label_fr": "Saint-Martin-Valmeroux"
            },
            {
                "value": 31469,
                "label": "Riom-es-Montagnes",
                "label_ar": "Riom-es-Montagnes",
                "label_fr": "Riom-es-Montagnes"
            },
            {
                "value": 31470,
                "label": "Saint-Agoulin",
                "label_ar": "سان أجولين",
                "label_fr": "Saint-Agoulin"
            },
            {
                "value": 31471,
                "label": "Murat",
                "label_ar": "مراد",
                "label_fr": "Murat"
            },
            {
                "value": 31472,
                "label": "Chassagnes",
                "label_ar": "شاسانيس",
                "label_fr": "Chassagnes"
            },
            {
                "value": 31473,
                "label": "Velzic",
                "label_ar": "فيلزيتش",
                "label_fr": "Velzic"
            },
            {
                "value": 31474,
                "label": "Bucheres",
                "label_ar": "بوخيريس",
                "label_fr": "Bucheres"
            },
            {
                "value": 31475,
                "label": "Frechencourt",
                "label_ar": "فريتشينكور",
                "label_fr": "Frechencourt"
            },
            {
                "value": 31476,
                "label": "Beaucamps-le-Vieux",
                "label_ar": "Beaucamps-le-Vieux",
                "label_fr": "Beaucamps-le-Vieux"
            },
            {
                "value": 31477,
                "label": "Hantay",
                "label_ar": "هانتاي",
                "label_fr": "Hantay"
            },
            {
                "value": 31478,
                "label": "Vaudesson",
                "label_ar": "فوديسون",
                "label_fr": "Vaudesson"
            },
            {
                "value": 31479,
                "label": "Folembray",
                "label_ar": "فوليمبراي",
                "label_fr": "Folembray"
            },
            {
                "value": 31480,
                "label": "Fralignes",
                "label_ar": "فرالينيس",
                "label_fr": "Fralignes"
            },
            {
                "value": 31481,
                "label": "Aigny",
                "label_ar": "ايجني",
                "label_fr": "Aigny"
            },
            {
                "value": 31482,
                "label": "Pougy",
                "label_ar": "بوجي",
                "label_fr": "Pougy"
            },
            {
                "value": 31483,
                "label": "Breuvannes-en-Bassigny",
                "label_ar": "Breuvannes-en-Bassigny",
                "label_fr": "Breuvannes-en-Bassigny"
            },
            {
                "value": 31484,
                "label": "Valcourt",
                "label_ar": "فالكورت",
                "label_fr": "Valcourt"
            },
            {
                "value": 31485,
                "label": "Premontre",
                "label_ar": "بريمونتر",
                "label_fr": "Prémontre"
            },
            {
                "value": 31486,
                "label": "Pouilly-sur-Serre",
                "label_ar": "بويي سور سيري",
                "label_fr": "Pouilly-sur-Serre"
            },
            {
                "value": 31487,
                "label": "Corbeny",
                "label_ar": "كوربيني",
                "label_fr": "Corbeny"
            },
            {
                "value": 31488,
                "label": "Thonnance-les-Joinville",
                "label_ar": "Thonnance-les-Joinville",
                "label_fr": "Thonnance-les-Joinville"
            },
            {
                "value": 31489,
                "label": "Orrouy",
                "label_ar": "عروى",
                "label_fr": "Orrouy"
            },
            {
                "value": 31490,
                "label": "Urcel",
                "label_ar": "Urcel",
                "label_fr": "Urcel"
            },
            {
                "value": 31491,
                "label": "Flavacourt",
                "label_ar": "فلافاكورت",
                "label_fr": "Flavacourt"
            },
            {
                "value": 31492,
                "label": "Ormoy-Villers",
                "label_ar": "أورموي فيلرز",
                "label_fr": "Ormoy-Villers"
            },
            {
                "value": 31493,
                "label": "Moulin-sous-Touvent",
                "label_ar": "مولان سو توفنت",
                "label_fr": "Moulin-sous-Touvent"
            },
            {
                "value": 31494,
                "label": "Pogny",
                "label_ar": "بوني",
                "label_fr": "Pogny"
            },
            {
                "value": 31495,
                "label": "Poligny",
                "label_ar": "بوليني",
                "label_fr": "Poligny"
            },
            {
                "value": 31496,
                "label": "Venteuil",
                "label_ar": "فينتويل",
                "label_fr": "Venteuil"
            },
            {
                "value": 31497,
                "label": "Larzicourt",
                "label_ar": "لارزيكورت",
                "label_fr": "Larzicourt"
            },
            {
                "value": 31498,
                "label": "Poinsenot",
                "label_ar": "Poinsenot",
                "label_fr": "Poinsenot"
            },
            {
                "value": 31499,
                "label": "Courtenot",
                "label_ar": "كورتنو",
                "label_fr": "Courtenot"
            },
            {
                "value": 31500,
                "label": "Chassemy",
                "label_ar": "شاسمي",
                "label_fr": "Chassemy"
            },
            {
                "value": 31501,
                "label": "Leme",
                "label_ar": "ليم",
                "label_fr": "Leme"
            },
            {
                "value": 31502,
                "label": "Machemont",
                "label_ar": "ماشمونت",
                "label_fr": "Machémont"
            },
            {
                "value": 31503,
                "label": "Vauchelles-les-Quesnoy",
                "label_ar": "Vauchelles-les-Quesnoy",
                "label_fr": "Vauchelles-les-Quesnoy"
            },
            {
                "value": 31504,
                "label": "Ribemont",
                "label_ar": "ريبمونت",
                "label_fr": "Ribemont"
            },
            {
                "value": 31505,
                "label": "Montmartin",
                "label_ar": "مونتمارتين",
                "label_fr": "Montmartin"
            },
            {
                "value": 31506,
                "label": "Vesigneul-sur-Coole",
                "label_ar": "فيسينيول سور كول",
                "label_fr": "Vesigneul-sur-Coole"
            },
            {
                "value": 31507,
                "label": "Bassu",
                "label_ar": "باسو",
                "label_fr": "Bassu"
            },
            {
                "value": 31508,
                "label": "Vaudemange",
                "label_ar": "فوديمانج",
                "label_fr": "Vaudemange"
            },
            {
                "value": 31509,
                "label": "Montjavoult",
                "label_ar": "مونتجافولت",
                "label_fr": "Montjavoult"
            },
            {
                "value": 31510,
                "label": "Esmery-Hallon",
                "label_ar": "اسمري هالون",
                "label_fr": "Esmery-Hallon"
            },
            {
                "value": 31511,
                "label": "Vendeuil-Caply",
                "label_ar": "فينديويل كابلي",
                "label_fr": "Vendeuil-Caply"
            },
            {
                "value": 31512,
                "label": "Vauchelles",
                "label_ar": "فوشيل",
                "label_fr": "Vauchelles"
            },
            {
                "value": 31513,
                "label": "Montigny-Lengrain",
                "label_ar": "مونتيني لينجرين",
                "label_fr": "Montigny-Lengrain"
            },
            {
                "value": 31514,
                "label": "Bonneuil-les-Eaux",
                "label_ar": "Bonneuil-les-Eaux",
                "label_fr": "Bonneuil-les-Eaux"
            },
            {
                "value": 31515,
                "label": "Thiers-sur-Theve",
                "label_ar": "تيير سور تيف",
                "label_fr": "Thiers-sur-Theve"
            },
            {
                "value": 31516,
                "label": "Montagny-en-Vexin",
                "label_ar": "مونتاني أون فيكسين",
                "label_fr": "Montagny-en-Vexin"
            },
            {
                "value": 31517,
                "label": "Blérancourt",
                "label_ar": "بليرانكورت",
                "label_fr": "Blérancourt"
            },
            {
                "value": 31518,
                "label": "Vermand",
                "label_ar": "فيرماند",
                "label_fr": "Vermand"
            },
            {
                "value": 31519,
                "label": "Vivier",
                "label_ar": "فيفييه",
                "label_fr": "Vivier"
            },
            {
                "value": 31520,
                "label": "Chacenay",
                "label_ar": "تشاسيناي",
                "label_fr": "Chacenay"
            },
            {
                "value": 31521,
                "label": "Coupeville",
                "label_ar": "كوبفيل",
                "label_fr": "Coupeville"
            },
            {
                "value": 31522,
                "label": "Ambrieres",
                "label_ar": "أمبيرييس",
                "label_fr": "Ambrieres"
            },
            {
                "value": 31523,
                "label": "Chambors",
                "label_ar": "غرف",
                "label_fr": "Chambres"
            },
            {
                "value": 31524,
                "label": "Fresne-Cauverville",
                "label_ar": "فريسن كوفرفيل",
                "label_fr": "Fresne-Cauverville"
            },
            {
                "value": 31525,
                "label": "Saint-Fuscien",
                "label_ar": "سانت فوسيان",
                "label_fr": "Saint-Fuscien"
            },
            {
                "value": 31526,
                "label": "Le Vaumain",
                "label_ar": "لو فومين",
                "label_fr": "Le Vaumain"
            },
            {
                "value": 31527,
                "label": "Offoy",
                "label_ar": "أوفوي",
                "label_fr": "Offoy"
            },
            {
                "value": 31528,
                "label": "Rivecourt",
                "label_ar": "ريفيكور",
                "label_fr": "Rivecourt"
            },
            {
                "value": 31529,
                "label": "Aveluy",
                "label_ar": "أفيلوي",
                "label_fr": "Aveluy"
            },
            {
                "value": 31530,
                "label": "Roucy",
                "label_ar": "روسي",
                "label_fr": "Roucy"
            },
            {
                "value": 31531,
                "label": "Margencel",
                "label_ar": "مارجنسل",
                "label_fr": "Margencel"
            },
            {
                "value": 31532,
                "label": "Combloux",
                "label_ar": "كومبلوكس",
                "label_fr": "Combloux"
            },
            {
                "value": 31533,
                "label": "Abondance",
                "label_ar": "وفرة",
                "label_fr": "Abondance"
            },
            {
                "value": 31534,
                "label": "Fillinges",
                "label_ar": "حشوات",
                "label_fr": "Remplissages"
            },
            {
                "value": 31535,
                "label": "Challex",
                "label_ar": "تشاليكس",
                "label_fr": "Challex"
            },
            {
                "value": 31536,
                "label": "Tresserve",
                "label_ar": "تريسيرف",
                "label_fr": "Tresserve"
            },
            {
                "value": 31537,
                "label": "Aiton",
                "label_ar": "أيتون",
                "label_fr": "Aiton"
            },
            {
                "value": 31538,
                "label": "Cornier",
                "label_ar": "كورنير",
                "label_fr": "Cornier"
            },
            {
                "value": 31539,
                "label": "Vinzier",
                "label_ar": "فينزير",
                "label_fr": "Vinzier"
            },
            {
                "value": 31540,
                "label": "Le Grand-Bornand",
                "label_ar": "لو جراند بورناند",
                "label_fr": "Le Grand-Bornand"
            },
            {
                "value": 31541,
                "label": "Colomieu",
                "label_ar": "كولوميو",
                "label_fr": "Colomieu"
            },
            {
                "value": 31542,
                "label": "Leysse",
                "label_ar": "ليسيه",
                "label_fr": "Leysse"
            },
            {
                "value": 31543,
                "label": "Surat",
                "label_ar": "سورة",
                "label_fr": "Sourate"
            },
            {
                "value": 31544,
                "label": "Lullin",
                "label_ar": "لولين",
                "label_fr": "Lullin"
            },
            {
                "value": 31545,
                "label": "Champfromier",
                "label_ar": "شامبفرومييه",
                "label_fr": "Champfromier"
            },
            {
                "value": 31546,
                "label": "Landry",
                "label_ar": "لاندري",
                "label_fr": "Landry"
            },
            {
                "value": 31547,
                "label": "Mont-Saxonnex",
                "label_ar": "مونت ساكسونيكس",
                "label_fr": "Mont-Saxonnex"
            },
            {
                "value": 31548,
                "label": "Chens-sur-Leman",
                "label_ar": "تشينز سور ليمان",
                "label_fr": "Chens-sur-Léman"
            },
            {
                "value": 31549,
                "label": "Saint-Gervais-les-Bains",
                "label_ar": "سان جيرفيه ليه با",
                "label_fr": "Saint-Gervais-les-Bains"
            },
            {
                "value": 31550,
                "label": "Draillant",
                "label_ar": "دريلانت",
                "label_fr": "Draillant"
            },
            {
                "value": 31551,
                "label": "Chainaz-les-Frasses",
                "label_ar": "شايناز ليه فراسيس",
                "label_fr": "Chainaz-les-Frasses"
            },
            {
                "value": 31552,
                "label": "Perrignier",
                "label_ar": "بيرجنير",
                "label_fr": "Perrignier"
            },
            {
                "value": 31553,
                "label": "Arbusigny",
                "label_ar": "اربوسيني",
                "label_fr": "Arbusigny"
            },
            {
                "value": 31554,
                "label": "Metz-Tessy",
                "label_ar": "ميتز تيسي",
                "label_fr": "Metz-Tessy"
            },
            {
                "value": 31555,
                "label": "Lully",
                "label_ar": "لولى",
                "label_fr": "Lully"
            },
            {
                "value": 31556,
                "label": "La Combelle",
                "label_ar": "لا كومبيل",
                "label_fr": "La Combelle"
            },
            {
                "value": 31557,
                "label": "Araches-la-Frasse",
                "label_ar": "Araches-la-Frasse",
                "label_fr": "Araches-la-Frasse"
            },
            {
                "value": 31558,
                "label": "Laissaud",
                "label_ar": "لايسود",
                "label_fr": "Laissaud"
            },
            {
                "value": 31559,
                "label": "Moens",
                "label_ar": "موينز",
                "label_fr": "Moens"
            },
            {
                "value": 31560,
                "label": "Le Bourget",
                "label_ar": "لو بورجيه",
                "label_fr": "Le Bourget"
            },
            {
                "value": 31561,
                "label": "Ceyssat",
                "label_ar": "سيسات",
                "label_fr": "Ceyssat"
            },
            {
                "value": 31562,
                "label": "Neussargues-Moissac",
                "label_ar": "Neussargues-Moissac",
                "label_fr": "Neussargues-Moissac"
            },
            {
                "value": 31563,
                "label": "Bellevaux",
                "label_ar": "بيلفو",
                "label_fr": "Bellevaux"
            },
            {
                "value": 31564,
                "label": "Arenthon",
                "label_ar": "ارينثون",
                "label_fr": "Arenthon"
            },
            {
                "value": 31565,
                "label": "Villy-le-Pelloux",
                "label_ar": "فيلي لو بيلوكس",
                "label_fr": "Villy-le-Pelloux"
            },
            {
                "value": 31566,
                "label": "Cordon",
                "label_ar": "كوردون",
                "label_fr": "Cordon"
            },
            {
                "value": 31567,
                "label": "Le Cendre",
                "label_ar": "لو سيندر",
                "label_fr": "Le Cendre"
            },
            {
                "value": 31568,
                "label": "Le Vernet",
                "label_ar": "لو فيرنيه",
                "label_fr": "Le Vernet"
            },
            {
                "value": 31569,
                "label": "Viry",
                "label_ar": "فيري",
                "label_fr": "Viry"
            },
            {
                "value": 31570,
                "label": "Ygrande",
                "label_ar": "يجراندي",
                "label_fr": "Ygrande"
            },
            {
                "value": 31571,
                "label": "Sales",
                "label_ar": "مبيعات",
                "label_fr": "Ventes"
            },
            {
                "value": 31572,
                "label": "Sauvessanges",
                "label_ar": "سوفيسانجيس",
                "label_fr": "Sauvessanges"
            },
            {
                "value": 31573,
                "label": "Neydens",
                "label_ar": "نايدينز",
                "label_fr": "Neydens"
            },
            {
                "value": 31574,
                "label": "Rosieres",
                "label_ar": "روزيريس",
                "label_fr": "Rosières"
            },
            {
                "value": 31575,
                "label": "Chevrier",
                "label_ar": "شيفرير",
                "label_fr": "Chevrier"
            },
            {
                "value": 31576,
                "label": "Buis-les-Baronnies",
                "label_ar": "Buis-les-Baronnies",
                "label_fr": "Buis-les-Baronnies"
            },
            {
                "value": 31577,
                "label": "Afa",
                "label_ar": "افا",
                "label_fr": "Afa"
            },
            {
                "value": 31578,
                "label": "Eygalieres",
                "label_ar": "Eygalieres",
                "label_fr": "Eygalières"
            },
            {
                "value": 31579,
                "label": "Greoux-les-Bains",
                "label_ar": "Greoux-les-Bains",
                "label_fr": "Gréoux-les-Bains"
            },
            {
                "value": 31580,
                "label": "Rognonas",
                "label_ar": "روجنوناس",
                "label_fr": "Rognonas"
            },
            {
                "value": 31581,
                "label": "Cabannes",
                "label_ar": "كابانيس",
                "label_fr": "Cabannes"
            },
            {
                "value": 31582,
                "label": "Saumane-de-Vaucluse",
                "label_ar": "سومان دي فوكلوز",
                "label_fr": "Saumane-de-Vaucluse"
            },
            {
                "value": 31583,
                "label": "Sisco",
                "label_ar": "سيسكو",
                "label_fr": "Sisco"
            },
            {
                "value": 31584,
                "label": "Sarrola-Carcopino",
                "label_ar": "سارولا كاركوبينو",
                "label_fr": "Sarrola-Carcopino"
            },
            {
                "value": 31585,
                "label": "Tavera",
                "label_ar": "تافيرا",
                "label_fr": "Tavera"
            },
            {
                "value": 31586,
                "label": "Solaro",
                "label_ar": "سولارو",
                "label_fr": "Solaro"
            },
            {
                "value": 31587,
                "label": "Saint-Christol",
                "label_ar": "سانت كريستول",
                "label_fr": "Saint-Christol"
            },
            {
                "value": 31588,
                "label": "Baume-les-Messieurs",
                "label_ar": "بوم ليه ميسيور",
                "label_fr": "Baume-les-Messieurs"
            },
            {
                "value": 31589,
                "label": "Veynes",
                "label_ar": "فيينز",
                "label_fr": "Veynes"
            },
            {
                "value": 31590,
                "label": "Saint-Michel-lObservatoire",
                "label_ar": "سان ميشيل اوبرفاتوار",
                "label_fr": "Saint-Michel-l'Observatoire"
            },
            {
                "value": 31591,
                "label": "Meyreuil",
                "label_ar": "ميريل",
                "label_fr": "Meyreuil"
            },
            {
                "value": 31592,
                "label": "Le Castellet",
                "label_ar": "لو كاستليه",
                "label_fr": "Le Castellet"
            },
            {
                "value": 31593,
                "label": "Grimaud",
                "label_ar": "غريمو",
                "label_fr": "Grimaud"
            },
            {
                "value": 31594,
                "label": "Nans-les-Pins",
                "label_ar": "نان ليه بينس",
                "label_fr": "Nans-les-Pins"
            },
            {
                "value": 31595,
                "label": "Taradeau",
                "label_ar": "Taradeau",
                "label_fr": "Taradeau"
            },
            {
                "value": 31596,
                "label": "Yvette",
                "label_ar": "إيفيت",
                "label_fr": "Yvette"
            },
            {
                "value": 31597,
                "label": "Saint-Hilliers",
                "label_ar": "سانت هيليرز",
                "label_fr": "Saint-Hilliers"
            },
            {
                "value": 31598,
                "label": "Chevry-Cossigny",
                "label_ar": "تشيفري كوسيني",
                "label_fr": "Chevry-Cossigny"
            },
            {
                "value": 31599,
                "label": "Montry",
                "label_ar": "مونتري",
                "label_fr": "Montry"
            },
            {
                "value": 31600,
                "label": "Fourches",
                "label_ar": "أربعاء",
                "label_fr": "Fourches"
            },
            {
                "value": 31601,
                "label": "Gouze",
                "label_ar": "شجر",
                "label_fr": "Gouze"
            },
            {
                "value": 31602,
                "label": "Urcuit",
                "label_ar": "Urcuit",
                "label_fr": "Urcuit"
            },
            {
                "value": 31603,
                "label": "Sainte-Marie-de-Re",
                "label_ar": "سانت ماري دي ري",
                "label_fr": "Sainte-Marie-de-Ré"
            },
            {
                "value": 31604,
                "label": "Chire-en-Montreuil",
                "label_ar": "شيري أون مونتروي",
                "label_fr": "Chire-en-Montreuil"
            },
            {
                "value": 31605,
                "label": "Albas",
                "label_ar": "ألباس",
                "label_fr": "Albas"
            },
            {
                "value": 31606,
                "label": "Lapenche",
                "label_ar": "لابينش",
                "label_fr": "Lapenche"
            },
            {
                "value": 31607,
                "label": "Saulge",
                "label_ar": "شاول",
                "label_fr": "Saulge"
            },
            {
                "value": 31608,
                "label": "Saint-Martin-de-Riberac",
                "label_ar": "سان مارتن دي ريبيراك",
                "label_fr": "Saint-Martin-de-Riberac"
            },
            {
                "value": 31609,
                "label": "Mouthiers-sur-Boeme",
                "label_ar": "Mouthiers-sur-Boeme",
                "label_fr": "Mouthiers-sur-Boème"
            },
            {
                "value": 31610,
                "label": "Saint-Laurs",
                "label_ar": "سان لورز",
                "label_fr": "Saint-Laurs"
            },
            {
                "value": 31611,
                "label": "La Coudre",
                "label_ar": "لا كودر",
                "label_fr": "La Coudre"
            },
            {
                "value": 31612,
                "label": "Saint-Georges-de-Didonne",
                "label_ar": "سان جورج دي ديدون",
                "label_fr": "Saint-Georges-de-Didonne"
            },
            {
                "value": 31613,
                "label": "Listrac-Médoc",
                "label_ar": "ليستراك ميدوك",
                "label_fr": "Listrac-Médoc"
            },
            {
                "value": 31614,
                "label": "Durfort",
                "label_ar": "دورفورت",
                "label_fr": "Durfort"
            },
            {
                "value": 31615,
                "label": "Livron",
                "label_ar": "ليفرون",
                "label_fr": "Livron"
            },
            {
                "value": 31616,
                "label": "Lahourcade",
                "label_ar": "لاهوركاد",
                "label_fr": "Lahourcade"
            },
            {
                "value": 31617,
                "label": "Cornac",
                "label_ar": "كورناك",
                "label_fr": "Cornac"
            },
            {
                "value": 31618,
                "label": "Bignac",
                "label_ar": "بيجناك",
                "label_fr": "Bignac"
            },
            {
                "value": 31619,
                "label": "Liguge",
                "label_ar": "ليجوج",
                "label_fr": "Liguge"
            },
            {
                "value": 31620,
                "label": "Cours-de-Monsegur",
                "label_ar": "كورس دي مونسيجور",
                "label_fr": "Cours-de-Monsegur"
            },
            {
                "value": 31621,
                "label": "Reparsac",
                "label_ar": "ريبارساك",
                "label_fr": "Reparsac"
            },
            {
                "value": 31622,
                "label": "Maisontiers",
                "label_ar": "ميزونتييرز",
                "label_fr": "Maisontiers"
            },
            {
                "value": 31623,
                "label": "Beauvoir-sur-Niort",
                "label_ar": "بوفوار سور نيور",
                "label_fr": "Beauvoir-sur-Niort"
            },
            {
                "value": 31624,
                "label": "Martiel",
                "label_ar": "مارتيل",
                "label_fr": "Martiel"
            },
            {
                "value": 31625,
                "label": "Darney",
                "label_ar": "دارني",
                "label_fr": "Darney"
            },
            {
                "value": 31626,
                "label": "Liocourt",
                "label_ar": "ليوكورت",
                "label_fr": "Liocourt"
            },
            {
                "value": 31627,
                "label": "Villers-sous-Preny",
                "label_ar": "فيليرز سو بريني",
                "label_fr": "Villers-sous-Preny"
            },
            {
                "value": 31628,
                "label": "Viomenil",
                "label_ar": "فيومينيل",
                "label_fr": "Viomenil"
            },
            {
                "value": 31629,
                "label": "Rosieres-en-Haye",
                "label_ar": "Rosieres-en-Haye",
                "label_fr": "Rosières-en-Haye"
            },
            {
                "value": 31630,
                "label": "Bartenheim",
                "label_ar": "بارتنهايم",
                "label_fr": "Bartenheim"
            },
            {
                "value": 31631,
                "label": "Bietlenheim",
                "label_ar": "بيتلينهايم",
                "label_fr": "Bietlenheim"
            },
            {
                "value": 31632,
                "label": "Chatenois-les-Forges",
                "label_ar": "Chatenois-les-Forges",
                "label_fr": "Châtenois-les-Forges"
            },
            {
                "value": 31633,
                "label": "Montmédy",
                "label_ar": "مونتميدي",
                "label_fr": "Montmédy"
            },
            {
                "value": 31634,
                "label": "Athesans-Etroitefontaine",
                "label_ar": "Athesans-Etroitefontaine",
                "label_fr": "Athesans-Etroitefontaine"
            },
            {
                "value": 31635,
                "label": "Ecuisses",
                "label_ar": "اكواسس",
                "label_fr": "Écuisses"
            },
            {
                "value": 31636,
                "label": "Gruey-les-Surance",
                "label_ar": "Gruey-les-Surance",
                "label_fr": "Gruey-les-Surance"
            },
            {
                "value": 31637,
                "label": "Jeuxey",
                "label_ar": "جوكسي",
                "label_fr": "Jeuxey"
            },
            {
                "value": 31638,
                "label": "Allondrelle-la-Malmaison",
                "label_ar": "ألوندريل لا مالميزون",
                "label_fr": "Allondrelle-la-Malmaison"
            },
            {
                "value": 31639,
                "label": "Cheminot",
                "label_ar": "Cheminot",
                "label_fr": "Cheminot"
            },
            {
                "value": 31640,
                "label": "Diebling",
                "label_ar": "ديبلنج",
                "label_fr": "Mourir"
            },
            {
                "value": 31641,
                "label": "Montreux-Jeune",
                "label_ar": "مونترو جون",
                "label_fr": "Montreux-Jeune"
            },
            {
                "value": 31642,
                "label": "La Charmee",
                "label_ar": "لا شارمي",
                "label_fr": "La Charmee"
            },
            {
                "value": 31643,
                "label": "Magny-sur-Tille",
                "label_ar": "ماجني سور تيل",
                "label_fr": "Magny-sur-Tille"
            },
            {
                "value": 31644,
                "label": "Sassenay",
                "label_ar": "ساسيناي",
                "label_fr": "Sassenay"
            },
            {
                "value": 31645,
                "label": "Beinheim",
                "label_ar": "بينهايم",
                "label_fr": "Beinheim"
            },
            {
                "value": 31646,
                "label": "Guerlesquin",
                "label_ar": "Guerlesquin",
                "label_fr": "Guerlesquin"
            },
            {
                "value": 31647,
                "label": "Saint-Carne",
                "label_ar": "سانت كارن",
                "label_fr": "Saint-Carne"
            },
            {
                "value": 31648,
                "label": "Locquemeau",
                "label_ar": "لوكيمو",
                "label_fr": "Locquemeau"
            },
            {
                "value": 31649,
                "label": "Damgan",
                "label_ar": "دامغان",
                "label_fr": "Damgan"
            },
            {
                "value": 31650,
                "label": "Lanhelin",
                "label_ar": "لانهيلين",
                "label_fr": "Lanhelin"
            },
            {
                "value": 31651,
                "label": "Sizun",
                "label_ar": "سيزون",
                "label_fr": "Sizun"
            },
            {
                "value": 31652,
                "label": "Plougrescant",
                "label_ar": "بلوجريسكانت",
                "label_fr": "Plougrescant"
            },
            {
                "value": 31653,
                "label": "Tremel",
                "label_ar": "تريميل",
                "label_fr": "Tremel"
            },
            {
                "value": 31654,
                "label": "Logonna-Daoulas",
                "label_ar": "لوغونا دولاس",
                "label_fr": "Logonna-Daoulas"
            },
            {
                "value": 31655,
                "label": "Les Ormes",
                "label_ar": "ليه أورمس",
                "label_fr": "Les Ormes"
            },
            {
                "value": 31656,
                "label": "Bazouges-la-Perouse",
                "label_ar": "بازوج لا بيرووس",
                "label_fr": "Bazouges-la-Pérouse"
            },
            {
                "value": 31657,
                "label": "Tremblay",
                "label_ar": "ترمبلاي",
                "label_fr": "Tremblay"
            },
            {
                "value": 31658,
                "label": "Dolo",
                "label_ar": "دولو",
                "label_fr": "Dolo"
            },
            {
                "value": 31659,
                "label": "Saint-Goazec",
                "label_ar": "سان جواسيك",
                "label_fr": "Saint-Goazec"
            },
            {
                "value": 31660,
                "label": "La Gouesniere",
                "label_ar": "لا جويسنيير",
                "label_fr": "La Gouesnière"
            },
            {
                "value": 31661,
                "label": "Plouvara",
                "label_ar": "بلوفارا",
                "label_fr": "Plouvara"
            },
            {
                "value": 31662,
                "label": "Langrune-sur-Mer",
                "label_ar": "لانغرون سور مير",
                "label_fr": "Langrune-sur-Mer"
            },
            {
                "value": 31663,
                "label": "Beauchamps",
                "label_ar": "بوشامب",
                "label_fr": "Beauchamps"
            },
            {
                "value": 31664,
                "label": "Fontaine-les-Bassets",
                "label_ar": "فونتين ليه باسيت",
                "label_fr": "Fontaine-les-Bassets"
            },
            {
                "value": 31665,
                "label": "Le Renouard",
                "label_ar": "لو رينوارد",
                "label_fr": "Le Renouard"
            },
            {
                "value": 31666,
                "label": "Portbail",
                "label_ar": "بورتبيل",
                "label_fr": "Portbail"
            },
            {
                "value": 31667,
                "label": "Periers",
                "label_ar": "بيرير",
                "label_fr": "Périers"
            },
            {
                "value": 31668,
                "label": "Grentheville",
                "label_ar": "جرينتفيل",
                "label_fr": "Grentheville"
            },
            {
                "value": 31669,
                "label": "Couvains",
                "label_ar": "Couvains",
                "label_fr": "Couvains"
            },
            {
                "value": 31670,
                "label": "Auvillars",
                "label_ar": "أوفيلارس",
                "label_fr": "Auvillars"
            },
            {
                "value": 31671,
                "label": "Varaville",
                "label_ar": "فارافيل",
                "label_fr": "Varaville"
            },
            {
                "value": 31672,
                "label": "Marchesieux",
                "label_ar": "مارشيزيو",
                "label_fr": "Marchesieux"
            },
            {
                "value": 31673,
                "label": "Saussey",
                "label_ar": "سوسى",
                "label_fr": "Saussey"
            },
            {
                "value": 31674,
                "label": "Courcy",
                "label_ar": "مراوغة",
                "label_fr": "Courcy"
            },
            {
                "value": 31675,
                "label": "Marcilly-sur-Eure",
                "label_ar": "مارسيلي سور يوري",
                "label_fr": "Marcilly-sur-Eure"
            },
            {
                "value": 31676,
                "label": "Reviers",
                "label_ar": "المنقحون",
                "label_fr": "Reviers"
            },
            {
                "value": 31677,
                "label": "Saint-Jean-des-Champs",
                "label_ar": "سان جان دي تشامب",
                "label_fr": "Saint-Jean-des-Champs"
            },
            {
                "value": 31678,
                "label": "Esquay-sur-Seulles",
                "label_ar": "Esquay-sur-Seulles",
                "label_fr": "Esquay-sur-Seulles"
            },
            {
                "value": 31679,
                "label": "Vauville",
                "label_ar": "فوفيل",
                "label_fr": "Vauville"
            },
            {
                "value": 31680,
                "label": "Perrou",
                "label_ar": "بيرو",
                "label_fr": "Perrou"
            },
            {
                "value": 31681,
                "label": "Monts-en-Bessin",
                "label_ar": "مونتس إن بيسين",
                "label_fr": "Monts-en-Bessin"
            },
            {
                "value": 31682,
                "label": "Bacilly",
                "label_ar": "باسيلي",
                "label_fr": "Bacilly"
            },
            {
                "value": 31683,
                "label": "La Meauffe",
                "label_ar": "لا موف",
                "label_fr": "La Meauffe"
            },
            {
                "value": 31684,
                "label": "Saint-Ebremond-de-Bonfosse",
                "label_ar": "سان إبرموند دي بونفوس",
                "label_fr": "Saint-Ebremond-de-Bonfosse"
            },
            {
                "value": 31685,
                "label": "Louatre",
                "label_ar": "اللواتر",
                "label_fr": "Louatre"
            },
            {
                "value": 31686,
                "label": "Moyvillers",
                "label_ar": "مويفيلرز",
                "label_fr": "Moyvillers"
            },
            {
                "value": 31687,
                "label": "Houlle",
                "label_ar": "هول",
                "label_fr": "Houlle"
            },
            {
                "value": 31688,
                "label": "Campagne-les-Hesdin",
                "label_ar": "كامباني ليه حسين",
                "label_fr": "Campagne-les-Hesdin"
            },
            {
                "value": 31689,
                "label": "Journy",
                "label_ar": "جورني",
                "label_fr": "Journy"
            },
            {
                "value": 31690,
                "label": "Fontaine-sur-Somme",
                "label_ar": "فونتين سور سوم",
                "label_fr": "Fontaine-sur-Somme"
            },
            {
                "value": 31691,
                "label": "Coeuvres-et-Valsery",
                "label_ar": "Coeuvres-et-Valsery",
                "label_fr": "Coeuvres-et-Valsery"
            },
            {
                "value": 31692,
                "label": "Buire-le-Sec",
                "label_ar": "Buire-le-Sec",
                "label_fr": "Buire-le-Sec"
            },
            {
                "value": 31693,
                "label": "Chalandry-Elaire",
                "label_ar": "خالاندري إليير",
                "label_fr": "Chalandry-Elaire"
            },
            {
                "value": 31694,
                "label": "Messon",
                "label_ar": "ميسون",
                "label_fr": "Messon"
            },
            {
                "value": 31695,
                "label": "Thennelieres",
                "label_ar": "ثينيريس",
                "label_fr": "Thennelieres"
            },
            {
                "value": 31696,
                "label": "Les Petites Loges",
                "label_ar": "لي بيتيت لوجيس",
                "label_fr": "Les Petites Loges"
            },
            {
                "value": 31697,
                "label": "Wailly-Beaucamp",
                "label_ar": "وايلي بوكامب",
                "label_fr": "Wailly-Beaucamp"
            },
            {
                "value": 31698,
                "label": "Doingt",
                "label_ar": "دوينغت",
                "label_fr": "Faire"
            },
            {
                "value": 31699,
                "label": "Hargnies",
                "label_ar": "هارجنيس",
                "label_fr": "Hargnies"
            },
            {
                "value": 31700,
                "label": "Mareuil-Caubert",
                "label_ar": "ماريويل كوبر",
                "label_fr": "Mareuil-Caubert"
            },
            {
                "value": 31701,
                "label": "Vauxtin",
                "label_ar": "فوكسستين",
                "label_fr": "Vauxtin"
            },
            {
                "value": 31702,
                "label": "Lagny-le-Sec",
                "label_ar": "لاني لو سيك",
                "label_fr": "Lagny-le-Sec"
            },
            {
                "value": 31703,
                "label": "Fressain",
                "label_ar": "فريسين",
                "label_fr": "Fressain"
            },
            {
                "value": 31704,
                "label": "Merignies",
                "label_ar": "ميرينيز",
                "label_fr": "Merignies"
            },
            {
                "value": 31705,
                "label": "Camiers",
                "label_ar": "الكاميرات",
                "label_fr": "Camiers"
            },
            {
                "value": 31706,
                "label": "Mortefontaine-en-Thelle",
                "label_ar": "مورتيفونتين أون ثيل",
                "label_fr": "Mortefontaine-en-Thelle"
            },
            {
                "value": 31707,
                "label": "Chouy",
                "label_ar": "تشوي",
                "label_fr": "Chouy"
            },
            {
                "value": 31708,
                "label": "Bourbonne-les-Bains",
                "label_ar": "بوربون ليه با",
                "label_fr": "Bourbonne-les-Bains"
            },
            {
                "value": 31709,
                "label": "Falaise",
                "label_ar": "Falaise",
                "label_fr": "Falaise"
            },
            {
                "value": 31710,
                "label": "Verneuil",
                "label_ar": "فيرنويل",
                "label_fr": "Verneuil"
            },
            {
                "value": 31711,
                "label": "Neuvy",
                "label_ar": "نيوفي",
                "label_fr": "Neuvy"
            },
            {
                "value": 31712,
                "label": "Bonnet",
                "label_ar": "غطاء محرك السيارة",
                "label_fr": "Bonnet"
            },
            {
                "value": 31713,
                "label": "Anzat-le-Luguet",
                "label_ar": "أنزات لو لوغيت",
                "label_fr": "Anzat-le-Luguet"
            },
            {
                "value": 31714,
                "label": "Brassac-les-Mines",
                "label_ar": "براساك لي مين",
                "label_fr": "Brassac-les-Mines"
            },
            {
                "value": 31715,
                "label": "Queyrieres",
                "label_ar": "كويرير",
                "label_fr": "Queyrieres"
            },
            {
                "value": 31716,
                "label": "Saint-Just-en-Chevalet",
                "label_ar": "سان جوست إن شوفاليه",
                "label_fr": "Saint-Just-en-Chevalet"
            },
            {
                "value": 31717,
                "label": "Saint-Priest-des-Champs",
                "label_ar": "سان بريست دي تشامب",
                "label_fr": "Saint-Priest-des-Champs"
            },
            {
                "value": 31718,
                "label": "Montfaucon-en-Velay",
                "label_ar": "مونتفوكون أون فيلاي",
                "label_fr": "Montfaucon-en-Velay"
            },
            {
                "value": 31719,
                "label": "Auzon",
                "label_ar": "أوزون",
                "label_fr": "Auzon"
            },
            {
                "value": 31720,
                "label": "Le Mont-Dore",
                "label_ar": "لو مونت دور",
                "label_fr": "Le Mont-Dore"
            },
            {
                "value": 31721,
                "label": "Frugeres-les-Mines",
                "label_ar": "Frugeres-les-Mines",
                "label_fr": "Frugeres-les-Mines"
            },
            {
                "value": 31722,
                "label": "Manzat",
                "label_ar": "مانزات",
                "label_fr": "Manzat"
            },
            {
                "value": 31723,
                "label": "Marmanhac",
                "label_ar": "مرمانهاك",
                "label_fr": "Marmanhac"
            },
            {
                "value": 31724,
                "label": "Villedieu",
                "label_ar": "فيليديو",
                "label_fr": "Villedieu"
            },
            {
                "value": 31725,
                "label": "Mazeyrat-d'Allier",
                "label_ar": "Mazeyrat-d'Allier",
                "label_fr": "Mazeyrat-d'Allier"
            },
            {
                "value": 31726,
                "label": "Champs-sur-Tarentaine-Marchal",
                "label_ar": "تشامب سور تارنتين مارشال",
                "label_fr": "Champs-sur-Tarentaine-Marchal"
            },
            {
                "value": 31727,
                "label": "Laqueuille",
                "label_ar": "لاكيويل",
                "label_fr": "Laqueuille"
            },
            {
                "value": 31728,
                "label": "Vergongheon",
                "label_ar": "فيرجونغهيون",
                "label_fr": "Vergongheon"
            },
            {
                "value": 31729,
                "label": "Charbonnieres-les-Varennes",
                "label_ar": "Charbonnieres-les-Varennes",
                "label_fr": "Charbonnières-les-Varennes"
            },
            {
                "value": 31730,
                "label": "Moissat",
                "label_ar": "مواسات",
                "label_fr": "Moissat"
            },
            {
                "value": 31731,
                "label": "Condat",
                "label_ar": "كوندات",
                "label_fr": "Condat"
            },
            {
                "value": 31732,
                "label": "Effiat",
                "label_ar": "إيفيات",
                "label_fr": "Effiat"
            },
            {
                "value": 31733,
                "label": "Saint-Gervazy",
                "label_ar": "سان جيرفازي",
                "label_fr": "Saint-Gervazy"
            },
            {
                "value": 31734,
                "label": "Ayrens",
                "label_ar": "ايرينز",
                "label_fr": "Ayrens"
            },
            {
                "value": 31735,
                "label": "Saint-Julien-de-Coppel",
                "label_ar": "سان جوليان دي كوبيل",
                "label_fr": "Saint-Julien-de-Coppel"
            },
            {
                "value": 31736,
                "label": "Olby",
                "label_ar": "أولبي",
                "label_fr": "Olby"
            },
            {
                "value": 31737,
                "label": "Montel-de-Gelat",
                "label_ar": "مونتيل دي جيلات",
                "label_fr": "Montel-de-Gelat"
            },
            {
                "value": 31738,
                "label": "Andelot-Morval",
                "label_ar": "أنديلوت مورفال",
                "label_fr": "Andelot-Morval"
            },
            {
                "value": 31739,
                "label": "Saligny-sur-Roudon",
                "label_ar": "ساليني سور رودون",
                "label_fr": "Saligny-sur-Roudon"
            },
            {
                "value": 31740,
                "label": "Aulnat",
                "label_ar": "اولنات",
                "label_fr": "Aulnat"
            },
            {
                "value": 31741,
                "label": "Aveze",
                "label_ar": "أفيزي",
                "label_fr": "Aveze"
            },
            {
                "value": 31742,
                "label": "Olmet",
                "label_ar": "أولمت",
                "label_fr": "Olmet"
            },
            {
                "value": 31743,
                "label": "Vigouroux",
                "label_ar": "فيجورو",
                "label_fr": "Vigouroux"
            },
            {
                "value": 31744,
                "label": "Lozanne",
                "label_ar": "لوزان",
                "label_fr": "Lozanne"
            },
            {
                "value": 31745,
                "label": "Le Crest",
                "label_ar": "لو كريست",
                "label_fr": "Le Crest"
            },
            {
                "value": 31746,
                "label": "Paulhac",
                "label_ar": "بولهاش",
                "label_fr": "Paulhac"
            },
            {
                "value": 31747,
                "label": "Vassel",
                "label_ar": "فاسل",
                "label_fr": "Vassel"
            },
            {
                "value": 31748,
                "label": "Youx",
                "label_ar": "Youx",
                "label_fr": "Youx"
            },
            {
                "value": 31749,
                "label": "Beauregard-l'Eveque",
                "label_ar": "Beauregard-l'Eveque",
                "label_fr": "Beauregard-l'Évêque"
            },
            {
                "value": 31750,
                "label": "Saint-Nectaire",
                "label_ar": "سان نكتير",
                "label_fr": "Saint-Nectaire"
            },
            {
                "value": 31751,
                "label": "Saint-Mamet-la-Salvetat",
                "label_ar": "سان ماميه لا سالفتات",
                "label_fr": "Saint-Mamet-la-Salvetat"
            },
            {
                "value": 31752,
                "label": "Montmarault",
                "label_ar": "مونمارولت",
                "label_fr": "Montmarault"
            },
            {
                "value": 31753,
                "label": "Vert",
                "label_ar": "فيرت",
                "label_fr": "Vert"
            },
            {
                "value": 31754,
                "label": "Maffliers",
                "label_ar": "المثيرون",
                "label_fr": "Maffliers"
            },
            {
                "value": 31755,
                "label": "Chaufour-les-Bonnieres",
                "label_ar": "شوفور ليه بونييه",
                "label_fr": "Chaufour-les-Bonnières"
            },
            {
                "value": 31756,
                "label": "Saint-Louis-de-Montferrand",
                "label_ar": "سان لويس دي مونتفيران",
                "label_fr": "Saint-Louis-de-Montferrand"
            },
            {
                "value": 31757,
                "label": "Sigoules",
                "label_ar": "سيغول",
                "label_fr": "Sigoules"
            },
            {
                "value": 31758,
                "label": "Fleac",
                "label_ar": "البراغيث",
                "label_fr": "Fleac"
            },
            {
                "value": 31759,
                "label": "Mourioux-Vieilleville",
                "label_ar": "موريوكس فيليفيل",
                "label_fr": "Mourioux-Vieilleville"
            },
            {
                "value": 31760,
                "label": "Bosmie-l'Aiguille",
                "label_ar": "Bosmie-l'Aiguille",
                "label_fr": "Bosmie-l'Aiguille"
            },
            {
                "value": 31761,
                "label": "Monsempron-Libos",
                "label_ar": "Monsempron-Libos",
                "label_fr": "Monsempron-Libos"
            },
            {
                "value": 31762,
                "label": "Grun-Bordas",
                "label_ar": "جرون بورداس",
                "label_fr": "Grun-Bordas"
            },
            {
                "value": 31763,
                "label": "Villemort",
                "label_ar": "فيلمورت",
                "label_fr": "Villemort"
            },
            {
                "value": 31764,
                "label": "Castelsagrat",
                "label_ar": "كاستيلساغرات",
                "label_fr": "Castelsagrat"
            },
            {
                "value": 31765,
                "label": "Le Vernet",
                "label_ar": "لو فيرنيه",
                "label_fr": "Le Vernet"
            },
            {
                "value": 31766,
                "label": "Gourge",
                "label_ar": "غورج",
                "label_fr": "Gourge"
            },
            {
                "value": 31767,
                "label": "Crazannes",
                "label_ar": "كرازانيس",
                "label_fr": "Crazannes"
            },
            {
                "value": 31768,
                "label": "Marcay",
                "label_ar": "ماركاي",
                "label_fr": "Marcay"
            },
            {
                "value": 31769,
                "label": "Venerand",
                "label_ar": "فينيراند",
                "label_fr": "Venerand"
            },
            {
                "value": 31770,
                "label": "Brieuil-sur-Chize",
                "label_ar": "بريويل سور تشيز",
                "label_fr": "Brieuil-sur-Chize"
            },
            {
                "value": 31771,
                "label": "Samoreau",
                "label_ar": "سامورو",
                "label_fr": "Samoreau"
            },
            {
                "value": 31772,
                "label": "Courquetaine",
                "label_ar": "كوركيتين",
                "label_fr": "Courquetaine"
            },
            {
                "value": 31773,
                "label": "Chauvry",
                "label_ar": "شوفري",
                "label_fr": "Chauvry"
            },
            {
                "value": 31774,
                "label": "Saint-Germain-sous-Doue",
                "label_ar": "سان جيرمان سو دو",
                "label_fr": "Saint-Germain-sous-Doué"
            },
            {
                "value": 31775,
                "label": "Montagny-les-Seurre",
                "label_ar": "مونتاني ليه سوري",
                "label_fr": "Montagny-les-Seurre"
            },
            {
                "value": 31776,
                "label": "Savoyeux",
                "label_ar": "سافويو",
                "label_fr": "Savoyeux"
            },
            {
                "value": 31777,
                "label": "Epertully",
                "label_ar": "بصدق",
                "label_fr": "Epertully"
            },
            {
                "value": 31778,
                "label": "Tendon",
                "label_ar": "وتر",
                "label_fr": "Tendon"
            },
            {
                "value": 31779,
                "label": "Belleau",
                "label_ar": "بيلو",
                "label_fr": "Belleau"
            },
            {
                "value": 31780,
                "label": "Val-et-Chatillon",
                "label_ar": "فال إت شاتيلون",
                "label_fr": "Val-et-Châtillon"
            },
            {
                "value": 31781,
                "label": "Diarville",
                "label_ar": "ديارفيل",
                "label_fr": "Diarville"
            },
            {
                "value": 31782,
                "label": "Sauvigney-les-Pesmes",
                "label_ar": "Sauvigney-les-Pesmes",
                "label_fr": "Sauvigney-les-Pesmes"
            },
            {
                "value": 31783,
                "label": "Pexonne",
                "label_ar": "بيكسون",
                "label_fr": "Pexonne"
            },
            {
                "value": 31784,
                "label": "Montcenis",
                "label_ar": "مونتينيس",
                "label_fr": "Montcenis"
            },
            {
                "value": 31785,
                "label": "Lucy-sur-Cure",
                "label_ar": "لوسي سور كيور",
                "label_fr": "Lucy-sur-Cure"
            },
            {
                "value": 31786,
                "label": "Saint-Laurent-la-Roche",
                "label_ar": "سان لوران لاروش",
                "label_fr": "Saint-Laurent-la-Roche"
            },
            {
                "value": 31787,
                "label": "Longecourt-en-Plaine",
                "label_ar": "لونج كورت أون بلين",
                "label_fr": "Longecourt-en-Plaine"
            },
            {
                "value": 31788,
                "label": "Mantoche",
                "label_ar": "مانتوش",
                "label_fr": "Mantoche"
            },
            {
                "value": 31789,
                "label": "Chieulles",
                "label_ar": "شيولز",
                "label_fr": "Chieulles"
            },
            {
                "value": 31790,
                "label": "Abbeville-les-Conflans",
                "label_ar": "أبفيل ليس كونفلان",
                "label_fr": "Abbeville-les-Conflans"
            },
            {
                "value": 31791,
                "label": "Ogeviller",
                "label_ar": "أوجفيلر",
                "label_fr": "Ogeviller"
            },
            {
                "value": 31792,
                "label": "Uxegney",
                "label_ar": "أوكسيجني",
                "label_fr": "Uxegney"
            },
            {
                "value": 31793,
                "label": "Friauville",
                "label_ar": "فريوفيل",
                "label_fr": "Friauville"
            },
            {
                "value": 31794,
                "label": "Bellechaume",
                "label_ar": "بيلتشوم",
                "label_fr": "Bellechaume"
            },
            {
                "value": 31795,
                "label": "Pouilley-Francais",
                "label_ar": "بويلي فرانسيه",
                "label_fr": "Pouilley-Francais"
            },
            {
                "value": 31796,
                "label": "Choye",
                "label_ar": "تشوي",
                "label_fr": "Choye"
            },
            {
                "value": 31797,
                "label": "Pommard",
                "label_ar": "بومارد",
                "label_fr": "Pommard"
            },
            {
                "value": 31798,
                "label": "Sainte-Lucie de Porto-Vecchio",
                "label_ar": "سانت لوسي دي بورتو فيكيو",
                "label_fr": "Sainte-Lucie de Porto-Vecchio"
            },
            {
                "value": 31799,
                "label": "Calenzana",
                "label_ar": "كالينزانا",
                "label_fr": "Calenzana"
            },
            {
                "value": 31800,
                "label": "Santa-Maria-Poggio",
                "label_ar": "سانتا ماريا بوجيو",
                "label_fr": "Santa-Maria-Poggio"
            },
            {
                "value": 31801,
                "label": "Lourmarin",
                "label_ar": "لورمارين",
                "label_fr": "Lourmarin"
            },
            {
                "value": 31802,
                "label": "Furiani",
                "label_ar": "فورياني",
                "label_fr": "Furiani"
            },
            {
                "value": 31803,
                "label": "Rauville-la-Place",
                "label_ar": "راوفيل لا بليس",
                "label_fr": "Rauville-la-Place"
            },
            {
                "value": 31804,
                "label": "Saint-Lo-d'Ourville",
                "label_ar": "سان لو دورفيل",
                "label_fr": "Saint-Lo-d'Ourville"
            },
            {
                "value": 31805,
                "label": "Beaumont-Hague",
                "label_ar": "بومونت لاهاي",
                "label_fr": "Beaumont-Hague"
            },
            {
                "value": 31806,
                "label": "Le Menil",
                "label_ar": "لو مينيل",
                "label_fr": "Le Ménil"
            },
            {
                "value": 31807,
                "label": "Cartigny-l'Epinay",
                "label_ar": "كارتيني- l'Epinay",
                "label_fr": "Cartigny-l'Epinay"
            },
            {
                "value": 31808,
                "label": "Dives-sur-Mer",
                "label_ar": "دايفس سور مير",
                "label_fr": "Dives-sur-Mer"
            },
            {
                "value": 31809,
                "label": "Grangues",
                "label_ar": "جرانج",
                "label_fr": "Grangues"
            },
            {
                "value": 31810,
                "label": "Hebecrevon",
                "label_ar": "هيبكريفون",
                "label_fr": "Hebecrevon"
            },
            {
                "value": 31811,
                "label": "Coulouvray-Boisbenatre",
                "label_ar": "كولوفراي-بويسبيناتري",
                "label_fr": "Coulouvray-Boisbenatre"
            },
            {
                "value": 31812,
                "label": "Radon",
                "label_ar": "رادون",
                "label_fr": "Radon"
            },
            {
                "value": 31813,
                "label": "Ranes",
                "label_ar": "رانيس",
                "label_fr": "Ranes"
            },
            {
                "value": 31814,
                "label": "Tournieres",
                "label_ar": "Tournieres",
                "label_fr": "Tournières"
            },
            {
                "value": 31815,
                "label": "Le Mesnil-Vigot",
                "label_ar": "لو ميسنيل فيجوت",
                "label_fr": "Le Mesnil-Vigot"
            },
            {
                "value": 31816,
                "label": "Marolles",
                "label_ar": "ماروليس",
                "label_fr": "Marolles"
            },
            {
                "value": 31817,
                "label": "Bricqueville-sur-Mer",
                "label_ar": "بريكفيل سور مير",
                "label_fr": "Bricqueville-sur-Mer"
            },
            {
                "value": 31818,
                "label": "Moyon",
                "label_ar": "مويون",
                "label_fr": "Moyon"
            },
            {
                "value": 31819,
                "label": "Urou-et-Crennes",
                "label_ar": "Urou-et-Crennes",
                "label_fr": "Urou-et-Crennes"
            },
            {
                "value": 31820,
                "label": "Airel",
                "label_ar": "ايرل",
                "label_fr": "Airel"
            },
            {
                "value": 31821,
                "label": "Saint-Quentin-sur-le-Homme",
                "label_ar": "سانت كوينتين سور لو أوم",
                "label_fr": "Saint-Quentin-sur-le-Homme"
            },
            {
                "value": 31822,
                "label": "Saint-Martin-de-Blagny",
                "label_ar": "سان مارتن دي بلاني",
                "label_fr": "Saint-Martin-de-Blagny"
            },
            {
                "value": 31823,
                "label": "Menil-Erreux",
                "label_ar": "مينيل ايروكس",
                "label_fr": "Ménil-Erreux"
            },
            {
                "value": 31824,
                "label": "Croisilles",
                "label_ar": "كروازيل",
                "label_fr": "Croisilles"
            },
            {
                "value": 31825,
                "label": "Montgaroult",
                "label_ar": "مونجارولت",
                "label_fr": "Montgaroult"
            },
            {
                "value": 31826,
                "label": "Contres",
                "label_ar": "كونتريس",
                "label_fr": "Contres"
            },
            {
                "value": 31827,
                "label": "Toury",
                "label_ar": "توري",
                "label_fr": "Toury"
            },
            {
                "value": 31828,
                "label": "Saint-Georges-sur-Cher",
                "label_ar": "سان جورج سور شير",
                "label_fr": "Saint-Georges-sur-Cher"
            },
            {
                "value": 31829,
                "label": "Etrechy",
                "label_ar": "إتريشي",
                "label_fr": "Étréchie"
            },
            {
                "value": 31830,
                "label": "Veretz",
                "label_ar": "فيريتز",
                "label_fr": "Veretz"
            },
            {
                "value": 31831,
                "label": "Coullons",
                "label_ar": "قسائم",
                "label_fr": "Coullons"
            },
            {
                "value": 31832,
                "label": "Civray",
                "label_ar": "سيفراي",
                "label_fr": "Civray"
            },
            {
                "value": 31833,
                "label": "Luze",
                "label_ar": "لوز",
                "label_fr": "Luze"
            },
            {
                "value": 31834,
                "label": "Levroux",
                "label_ar": "ليفرو",
                "label_fr": "Levroux"
            },
            {
                "value": 31835,
                "label": "Fay-aux-Loges",
                "label_ar": "فاي أوكس لوجيس",
                "label_fr": "Fay-aux-Loges"
            },
            {
                "value": 31836,
                "label": "Neuil",
                "label_ar": "نويل",
                "label_fr": "Neuil"
            },
            {
                "value": 31837,
                "label": "Sainville",
                "label_ar": "ساينفيل",
                "label_fr": "Sainville"
            },
            {
                "value": 31838,
                "label": "Pierres",
                "label_ar": "بيريس",
                "label_fr": "Pierres"
            },
            {
                "value": 31839,
                "label": "Chanteau",
                "label_ar": "شانتو",
                "label_fr": "Chanteau"
            },
            {
                "value": 31840,
                "label": "Lamotte-Beuvron",
                "label_ar": "لاموت بيوفرون",
                "label_fr": "Lamotte-Beuvron"
            },
            {
                "value": 31841,
                "label": "Saint-Claude-de-Diray",
                "label_ar": "سان كلود دي ديراي",
                "label_fr": "Saint-Claude-de-Diray"
            },
            {
                "value": 31842,
                "label": "Clery-Saint-Andre",
                "label_ar": "كليري سانت أندريه",
                "label_fr": "Cléry-Saint-André"
            },
            {
                "value": 31843,
                "label": "La Selle-en-Hermoy",
                "label_ar": "لا سيل أون هيرموي",
                "label_fr": "La Selle-en-Hermoy"
            },
            {
                "value": 31844,
                "label": "Chatres-sur-Cher",
                "label_ar": "شاتريس سور شير",
                "label_fr": "Châtres-sur-Cher"
            },
            {
                "value": 31845,
                "label": "Saint-Quentin-sur-Indrois",
                "label_ar": "سانت كوينتين سور إندرويس",
                "label_fr": "Saint-Quentin-sur-Indrois"
            },
            {
                "value": 31846,
                "label": "Bonny-sur-Loire",
                "label_ar": "بوني سور لوار",
                "label_fr": "Bonny-sur-Loire"
            },
            {
                "value": 31847,
                "label": "Segry",
                "label_ar": "سيجري",
                "label_fr": "Segry"
            },
            {
                "value": 31848,
                "label": "Valence-sur-Baise",
                "label_ar": "فالينس سور بايز",
                "label_fr": "Valence-sur-Baise"
            },
            {
                "value": 31849,
                "label": "Les Varennes",
                "label_ar": "ليه فارين",
                "label_fr": "Les Varennes"
            },
            {
                "value": 31850,
                "label": "Pressignac-Vicq",
                "label_ar": "بريسيجناك-فيك",
                "label_fr": "Pressignac-Vicq"
            },
            {
                "value": 31851,
                "label": "Lavergne",
                "label_ar": "لافيرجن",
                "label_fr": "Lavergne"
            },
            {
                "value": 31852,
                "label": "Saint-Pé-dArdet",
                "label_ar": "سان بي دارديت",
                "label_fr": "Saint-Pé-d'Ardet"
            },
            {
                "value": 31853,
                "label": "Galie",
                "label_ar": "جالي",
                "label_fr": "Galie"
            },
            {
                "value": 31854,
                "label": "Fontcouverte",
                "label_ar": "Fontcouverte",
                "label_fr": "Fontcouverte"
            },
            {
                "value": 31855,
                "label": "Touzac",
                "label_ar": "توزاك",
                "label_fr": "Touzac"
            },
            {
                "value": 31856,
                "label": "Gagnac-sur-Garonne",
                "label_ar": "جاجناك سور جارون",
                "label_fr": "Gagnac-sur-Garonne"
            },
            {
                "value": 31857,
                "label": "Rignac",
                "label_ar": "ريجناك",
                "label_fr": "Rignac"
            },
            {
                "value": 31858,
                "label": "Pontcirq",
                "label_ar": "بونتسيرك",
                "label_fr": "Pontcirq"
            },
            {
                "value": 31859,
                "label": "Lussac-les-Eglises",
                "label_ar": "لوساك ليه إجليس",
                "label_fr": "Lussac-les-Eglises"
            },
            {
                "value": 31860,
                "label": "Bouex",
                "label_ar": "بويكس",
                "label_fr": "Bouex"
            },
            {
                "value": 31861,
                "label": "Combrand",
                "label_ar": "كومبراند",
                "label_fr": "Combrand"
            },
            {
                "value": 31862,
                "label": "Malegoude",
                "label_ar": "ماليجود",
                "label_fr": "Malegoude"
            },
            {
                "value": 31863,
                "label": "Calmont",
                "label_ar": "كالمونت",
                "label_fr": "Calmont"
            },
            {
                "value": 31864,
                "label": "Ardillieres",
                "label_ar": "Ardillieres",
                "label_fr": "Ardillières"
            },
            {
                "value": 31865,
                "label": "Bord",
                "label_ar": "بورد",
                "label_fr": "Bord"
            },
            {
                "value": 31866,
                "label": "Vaureilles",
                "label_ar": "Vaureilles",
                "label_fr": "Vaureilles"
            },
            {
                "value": 31867,
                "label": "Saint-Sulpice-les-Feuilles",
                "label_ar": "سان سولبيس ليه فوي",
                "label_fr": "Saint-Sulpice-les-Feuilles"
            },
            {
                "value": 31868,
                "label": "Nieul-le-Virouil",
                "label_ar": "نيول لو فيرويل",
                "label_fr": "Nieul-le-Virouil"
            },
            {
                "value": 31869,
                "label": "Biard",
                "label_ar": "بيارد",
                "label_fr": "Biard"
            },
            {
                "value": 31870,
                "label": "Celon",
                "label_ar": "سيلون",
                "label_fr": "Celon"
            },
            {
                "value": 31871,
                "label": "Bouzy-la-Foret",
                "label_ar": "بوزي لا فوريه",
                "label_fr": "Bouzy-la-Foret"
            },
            {
                "value": 31872,
                "label": "Chemery",
                "label_ar": "الكيمياء",
                "label_fr": "Chimie"
            },
            {
                "value": 31873,
                "label": "Marville-les-Bois",
                "label_ar": "مارفيل لي بوا",
                "label_fr": "Marville-les-Bois"
            },
            {
                "value": 31874,
                "label": "Neung-sur-Beuvron",
                "label_ar": "نيونغ سور بيوفرون",
                "label_fr": "Neung-sur-Beuvron"
            },
            {
                "value": 31875,
                "label": "La Marolle-en-Sologne",
                "label_ar": "لا مارول أون سولونيا",
                "label_fr": "La Marolle-en-Sologne"
            },
            {
                "value": 31876,
                "label": "Le Bardon",
                "label_ar": "لو باردون",
                "label_fr": "Le Bardon"
            },
            {
                "value": 31877,
                "label": "Luant",
                "label_ar": "لوانت",
                "label_fr": "Luant"
            },
            {
                "value": 31878,
                "label": "Darvoy",
                "label_ar": "دارفوي",
                "label_fr": "Darvoy"
            },
            {
                "value": 31879,
                "label": "Chateauneuf-sur-Loire",
                "label_ar": "شاتونوف سور لوار",
                "label_fr": "Châteauneuf-sur-Loire"
            },
            {
                "value": 31880,
                "label": "Jargeau",
                "label_ar": "جارجو",
                "label_fr": "Jargeau"
            },
            {
                "value": 31881,
                "label": "Villemeux-sur-Eure",
                "label_ar": "Villemeux-sur-Eure",
                "label_fr": "Villemeux-sur-Eure"
            },
            {
                "value": 31882,
                "label": "Le Poinconnet",
                "label_ar": "لو بوانكونيت",
                "label_fr": "Le Poinconnet"
            },
            {
                "value": 31883,
                "label": "Maslives",
                "label_ar": "Maslives",
                "label_fr": "Maslives"
            },
            {
                "value": 31884,
                "label": "Cravant-les-Coteaux",
                "label_ar": "كرافانت لي كوتو",
                "label_fr": "Cravant-les-Coteaux"
            },
            {
                "value": 31885,
                "label": "Chabris",
                "label_ar": "شابريس",
                "label_fr": "Chabris"
            },
            {
                "value": 31886,
                "label": "Jallans",
                "label_ar": "جلان",
                "label_fr": "Jallans"
            },
            {
                "value": 31887,
                "label": "Neuvy-Saint-Sepulchre",
                "label_ar": "Neuvy-Saint-Sepulcher",
                "label_fr": "Neuvy-Saint-Sépulcre"
            },
            {
                "value": 31888,
                "label": "Muides-sur-Loire",
                "label_ar": "Muides-sur-Loire",
                "label_fr": "Muides-sur-Loire"
            },
            {
                "value": 31889,
                "label": "Lestiou",
                "label_ar": "ليستيو",
                "label_fr": "Lestiou"
            },
            {
                "value": 31890,
                "label": "Billy",
                "label_ar": "بيلي",
                "label_fr": "Gamelle"
            },
            {
                "value": 31891,
                "label": "Chatillon-Coligny",
                "label_ar": "شاتيلون كوليجني",
                "label_fr": "Châtillon-Coligny"
            },
            {
                "value": 31892,
                "label": "Saint-Maurice-sur-Fessard",
                "label_ar": "سان موريس سور فيسار",
                "label_fr": "Saint-Maurice-sur-Fessard"
            },
            {
                "value": 31893,
                "label": "Chevillon-sur-Huillard",
                "label_ar": "شيفيلون سور هويار",
                "label_fr": "Chevillon-sur-Huillard"
            },
            {
                "value": 31894,
                "label": "Saint-Avit-les-Guespieres",
                "label_ar": "Saint-Avit-les-Guespieres",
                "label_fr": "Saint-Avit-les-Guespières"
            },
            {
                "value": 31895,
                "label": "Saint-Hilaire-Saint-Mesmin",
                "label_ar": "سان هيلير سانت ميسمين",
                "label_fr": "Saint-Hilaire-Saint-Mesmin"
            },
            {
                "value": 31896,
                "label": "Saint-Genies-Bellevue",
                "label_ar": "سان جيني بلفيو",
                "label_fr": "Saint-Genies-Bellevue"
            },
            {
                "value": 31897,
                "label": "Maillebois",
                "label_ar": "Maillebois",
                "label_fr": "Maillebois"
            },
            {
                "value": 31898,
                "label": "Arnouville-les-Mantes",
                "label_ar": "أرنوفيل ليس مانت",
                "label_fr": "Arnouville-les-Mantes"
            },
            {
                "value": 31899,
                "label": "Alban",
                "label_ar": "ألبان",
                "label_fr": "Alban"
            },
            {
                "value": 31900,
                "label": "Sainte-Blandine",
                "label_ar": "سانت بلاندين",
                "label_fr": "Sainte-Blandine"
            },
            {
                "value": 31901,
                "label": "La Charme",
                "label_ar": "لا شارم",
                "label_fr": "La Charme"
            },
            {
                "value": 31902,
                "label": "Gelles",
                "label_ar": "جيليس",
                "label_fr": "Gelles"
            },
            {
                "value": 31903,
                "label": "Le Plessis-Robinson",
                "label_ar": "لو بليسيس روبنسون",
                "label_fr": "Le Plessis-Robinson"
            },
            {
                "value": 31904,
                "label": "Saint-Priest-la-Roche",
                "label_ar": "سانت بريست لاروش",
                "label_fr": "Saint-Priest-la-Roche"
            },
            {
                "value": 31905,
                "label": "Lancieux",
                "label_ar": "لانسيو",
                "label_fr": "Lancieux"
            },
            {
                "value": 31906,
                "label": "La Trimouille",
                "label_ar": "لا تريموي",
                "label_fr": "La Trimouille"
            },
            {
                "value": 31907,
                "label": "Bidarray",
                "label_ar": "بيداري",
                "label_fr": "Bidarray"
            },
            {
                "value": 31908,
                "label": "Urt",
                "label_ar": "Urt",
                "label_fr": "Urt"
            },
            {
                "value": 31909,
                "label": "Saint-Abit",
                "label_ar": "سانت أبيت",
                "label_fr": "Saint-Abit"
            },
            {
                "value": 31910,
                "label": "Ordiarp",
                "label_ar": "Ordiarp",
                "label_fr": "Ordiarp"
            },
            {
                "value": 31911,
                "label": "Garris",
                "label_ar": "غاريس",
                "label_fr": "Garris"
            },
            {
                "value": 31912,
                "label": "Domezain-Berraute",
                "label_ar": "دومزين-بريوت",
                "label_fr": "Domezain-Berraute"
            },
            {
                "value": 31913,
                "label": "Escou",
                "label_ar": "اسكو",
                "label_fr": "Escou"
            },
            {
                "value": 31914,
                "label": "Gelos",
                "label_ar": "جيلوس",
                "label_fr": "Gelos"
            },
            {
                "value": 31915,
                "label": "Lespielle",
                "label_ar": "ليسبييل",
                "label_fr": "Lespielle"
            },
            {
                "value": 31916,
                "label": "Arraute-Charritte",
                "label_ar": "Arraute-Charritte",
                "label_fr": "Arraute-Charritte"
            },
            {
                "value": 31917,
                "label": "Helette",
                "label_ar": "هيليت",
                "label_fr": "Helette"
            },
            {
                "value": 31918,
                "label": "Alos-Sibas-Abense",
                "label_ar": "Alos-Sibas-Abense",
                "label_fr": "Alos-Sibas-Abense"
            },
            {
                "value": 31919,
                "label": "Arbonne",
                "label_ar": "اربون",
                "label_fr": "Arbonne"
            },
            {
                "value": 31920,
                "label": "Lons",
                "label_ar": "لونس",
                "label_fr": "Lons"
            },
            {
                "value": 31921,
                "label": "Ousse",
                "label_ar": "Ousse",
                "label_fr": "Ousse"
            },
            {
                "value": 31922,
                "label": "Bernadets",
                "label_ar": "برناديتس",
                "label_fr": "Bernadets"
            },
            {
                "value": 31923,
                "label": "Gabat",
                "label_ar": "جابات",
                "label_fr": "Gabat"
            },
            {
                "value": 31924,
                "label": "Bussunarits-Sarrasquette",
                "label_ar": "بوسوناريتس ساراسكيت",
                "label_fr": "Bussunarits-Sarrasquette"
            },
            {
                "value": 31925,
                "label": "Montestrucq",
                "label_ar": "Montestrucq",
                "label_fr": "Montestrucq"
            },
            {
                "value": 31926,
                "label": "Irouleguy",
                "label_ar": "ايرليجوي",
                "label_fr": "Irouleguy"
            },
            {
                "value": 31927,
                "label": "Anhaux",
                "label_ar": "Anhaux",
                "label_fr": "Anhaux"
            },
            {
                "value": 31928,
                "label": "Auga",
                "label_ar": "أوجا",
                "label_fr": "Auga"
            },
            {
                "value": 31929,
                "label": "Uzein",
                "label_ar": "اوزين",
                "label_fr": "Uzein"
            },
            {
                "value": 31930,
                "label": "Lasseube",
                "label_ar": "لاسيوب",
                "label_fr": "Lasseube"
            },
            {
                "value": 31931,
                "label": "Bielle",
                "label_ar": "بييل",
                "label_fr": "Bielle"
            },
            {
                "value": 31932,
                "label": "Arthez-d'Asson",
                "label_ar": "أرتيز داسون",
                "label_fr": "Arthez-d'Asson"
            },
            {
                "value": 31933,
                "label": "Claracq",
                "label_ar": "كلاراك",
                "label_fr": "Claracq"
            },
            {
                "value": 31934,
                "label": "Woustviller",
                "label_ar": "Woustviller",
                "label_fr": "Woustviller"
            },
            {
                "value": 31935,
                "label": "Remelfing",
                "label_ar": "إعادة تشكيل",
                "label_fr": "Remelfing"
            },
            {
                "value": 31936,
                "label": "Chantraine",
                "label_ar": "شانترين",
                "label_fr": "Chantraine"
            },
            {
                "value": 31937,
                "label": "Chateauneuf-en-Thymerais",
                "label_ar": "Chateauneuf-en-Thymerais",
                "label_fr": "Châteauneuf-en-Thymerais"
            },
            {
                "value": 31938,
                "label": "Castelnau-Montratier",
                "label_ar": "Castelnau-Montratier",
                "label_fr": "Castelnau-Montratier"
            },
            {
                "value": 31939,
                "label": "Tremery",
                "label_ar": "الهزة",
                "label_fr": "Tremery"
            },
            {
                "value": 31940,
                "label": "Villaines-sous-Bois",
                "label_ar": "Villaines-sous-Bois",
                "label_fr": "Villaines-sous-Bois"
            },
            {
                "value": 31941,
                "label": "Bergholtz",
                "label_ar": "بيرغولتز",
                "label_fr": "Bergholtz"
            },
            {
                "value": 31942,
                "label": "Montalieu-Vercieu",
                "label_ar": "مونتاليو فيرسيو",
                "label_fr": "Montalieu-Vercieu"
            },
            {
                "value": 31943,
                "label": "Les Landes-Genusson",
                "label_ar": "ليس لانديس جينوسون",
                "label_fr": "Les Landes-Genusson"
            },
            {
                "value": 31944,
                "label": "Bretteville-sur-Laize",
                "label_ar": "بريتفيل سور لايز",
                "label_fr": "Bretteville-sur-Laize"
            },
            {
                "value": 31945,
                "label": "Wignehies",
                "label_ar": "Wignehies",
                "label_fr": "Wignehies"
            },
            {
                "value": 31946,
                "label": "Neuve-Maison",
                "label_ar": "نوف ميزون",
                "label_fr": "Neuve-Maison"
            },
            {
                "value": 31947,
                "label": "Castelnau-de-Montmiral",
                "label_ar": "Castelnau-de-Montmiral",
                "label_fr": "Castelnau-de-Montmiral"
            },
            {
                "value": 31948,
                "label": "Cepoy",
                "label_ar": "سيبوي",
                "label_fr": "Cepoy"
            },
            {
                "value": 31949,
                "label": "Verneuil-l'Etang",
                "label_ar": "فيرنويل ليتانغ",
                "label_fr": "Verneuil-l'Etang"
            },
            {
                "value": 31950,
                "label": "Saint-Amancet",
                "label_ar": "سان امانسيت",
                "label_fr": "Saint-Amancet"
            },
            {
                "value": 31951,
                "label": "Ablis",
                "label_ar": "أبليس",
                "label_fr": "Ablis"
            },
            {
                "value": 31952,
                "label": "Saint-Michel-sur-Meurthe",
                "label_ar": "سان ميشيل سور مورث",
                "label_fr": "Saint-Michel-sur-Meurthe"
            },
            {
                "value": 31953,
                "label": "Rumersheim-le-Haut",
                "label_ar": "روميرشيم لو أوت",
                "label_fr": "Rumersheim-le-Haut"
            },
            {
                "value": 31954,
                "label": "Magstatt-le-Haut",
                "label_ar": "Magstatt-le-Haut",
                "label_fr": "Magstatt-le-Haut"
            },
            {
                "value": 31955,
                "label": "Dietwiller",
                "label_ar": "ديتويلر",
                "label_fr": "Dietwiller"
            },
            {
                "value": 31956,
                "label": "Arpheuilles-Saint-Priest",
                "label_ar": "Arpheuilles-Saint-Priest",
                "label_fr": "Arpheuilles-Saint-Priest"
            },
            {
                "value": 31957,
                "label": "Boissy-Fresnoy",
                "label_ar": "Boissy-Fresnoy",
                "label_fr": "Boissy-Fresnoy"
            },
            {
                "value": 31958,
                "label": "Bressolles",
                "label_ar": "بريسوليس",
                "label_fr": "Bressolles"
            },
            {
                "value": 31959,
                "label": "Savigny",
                "label_ar": "سافيني",
                "label_fr": "Savigny"
            },
            {
                "value": 31960,
                "label": "Vouillers",
                "label_ar": "فويلرز",
                "label_fr": "Vouillers"
            },
            {
                "value": 31961,
                "label": "Brienne-la-Vieille",
                "label_ar": "برين لا فيل",
                "label_fr": "Brienne-la-Vieille"
            },
            {
                "value": 31962,
                "label": "Chalette-sur-Voire",
                "label_ar": "شاليت سور فوار",
                "label_fr": "Chalette-sur-Voire"
            },
            {
                "value": 31963,
                "label": "Chailly-en-Biere",
                "label_ar": "Chailly-en-Biere",
                "label_fr": "Chailly-en-Bière"
            },
            {
                "value": 31964,
                "label": "Barbatre",
                "label_ar": "بارباتري",
                "label_fr": "Barbatre"
            },
            {
                "value": 31965,
                "label": "Noirmoutier-en-l'Ile",
                "label_ar": "Noirmoutier-en-l'Ile",
                "label_fr": "Noirmoutier-en-l'Ile"
            },
            {
                "value": 31966,
                "label": "Les Essarts",
                "label_ar": "ليس إسارتس",
                "label_fr": "Les Essarts"
            },
            {
                "value": 31967,
                "label": "Langeais",
                "label_ar": "لانجياس",
                "label_fr": "Langeais"
            },
            {
                "value": 31968,
                "label": "Ginestas",
                "label_ar": "جينستاس",
                "label_fr": "Ginestas"
            },
            {
                "value": 31969,
                "label": "Saint-Eloi",
                "label_ar": "سانت إيلوي",
                "label_fr": "Saint-Eloi"
            },
            {
                "value": 31970,
                "label": "Condezaygues",
                "label_ar": "Condezaygues",
                "label_fr": "Condezaygues"
            },
            {
                "value": 31971,
                "label": "Saint-Vaast-les-Mello",
                "label_ar": "سان فاست ليس ميلو",
                "label_fr": "Saint-Vaast-les-Mello"
            },
            {
                "value": 31972,
                "label": "Coursan",
                "label_ar": "كورسان",
                "label_fr": "Coursan"
            },
            {
                "value": 31973,
                "label": "Port-la-Nouvelle",
                "label_ar": "بورت لا نوفيل",
                "label_fr": "Port-la-Nouvelle"
            },
            {
                "value": 31974,
                "label": "Luc",
                "label_ar": "لوك",
                "label_fr": "Luc"
            },
            {
                "value": 31975,
                "label": "Castres-Gironde",
                "label_ar": "كاستريس جيروند",
                "label_fr": "Castres-Gironde"
            },
            {
                "value": 31976,
                "label": "Fourchambault",
                "label_ar": "فورتشامبولت",
                "label_fr": "Fourchambault"
            },
            {
                "value": 31977,
                "label": "Areines",
                "label_ar": "أرين",
                "label_fr": "Aréines"
            },
            {
                "value": 31978,
                "label": "Escames",
                "label_ar": "اسكيمس",
                "label_fr": "Escames"
            },
            {
                "value": 31979,
                "label": "Pouilly",
                "label_ar": "بويي",
                "label_fr": "Pouilly"
            },
            {
                "value": 31980,
                "label": "Feuquieres",
                "label_ar": "فوكويريس",
                "label_fr": "Feuquières"
            },
            {
                "value": 31981,
                "label": "Grandvilliers",
                "label_ar": "Grandvilliers",
                "label_fr": "Grandvilliers"
            },
            {
                "value": 31982,
                "label": "Orny",
                "label_ar": "أورني",
                "label_fr": "Orny"
            },
            {
                "value": 31983,
                "label": "Marsais-Sainte-Radegonde",
                "label_ar": "مارسيه سانت راديغوند",
                "label_fr": "Marsais-Sainte-Radegonde"
            },
            {
                "value": 31984,
                "label": "Achatel",
                "label_ar": "أتشاتيل",
                "label_fr": "Achatel"
            },
            {
                "value": 31985,
                "label": "Marigny-les-Usages",
                "label_ar": "Marigny-les-Usages",
                "label_fr": "Marigny-les-Usages"
            },
            {
                "value": 31986,
                "label": "Rebrechien",
                "label_ar": "ريبريشين",
                "label_fr": "Rebrechien"
            },
            {
                "value": 31987,
                "label": "Loury",
                "label_ar": "لوري",
                "label_fr": "Loury"
            },
            {
                "value": 31988,
                "label": "Noailles",
                "label_ar": "نويل",
                "label_fr": "Noailles"
            },
            {
                "value": 31989,
                "label": "Le Pout",
                "label_ar": "لو بوت",
                "label_fr": "Le Pout"
            },
            {
                "value": 31990,
                "label": "Creon-d'Armagnac",
                "label_ar": "كريون دي أرماجناك",
                "label_fr": "Créon-d'Armagnac"
            },
            {
                "value": 31991,
                "label": "Belcodene",
                "label_ar": "بيلكودين",
                "label_fr": "Belcodène"
            },
            {
                "value": 31992,
                "label": "Tavaux",
                "label_ar": "تافو",
                "label_fr": "Tavaux"
            },
            {
                "value": 31993,
                "label": "Poligny",
                "label_ar": "بوليني",
                "label_fr": "Poligny"
            },
            {
                "value": 31994,
                "label": "Les Epesses",
                "label_ar": "ليه إيبيس",
                "label_fr": "Les Epesses"
            },
            {
                "value": 31995,
                "label": "Morez",
                "label_ar": "مورز",
                "label_fr": "Morez"
            },
            {
                "value": 31996,
                "label": "Morbier",
                "label_ar": "موربير",
                "label_fr": "Morbier"
            },
            {
                "value": 31997,
                "label": "Les Rousses",
                "label_ar": "ليه روس",
                "label_fr": "Les Rousses"
            },
            {
                "value": 31998,
                "label": "Premanon",
                "label_ar": "بريمانون",
                "label_fr": "Premanon"
            },
            {
                "value": 31999,
                "label": "Reiners",
                "label_ar": "رينرز",
                "label_fr": "Reiners"
            },
            {
                "value": 32000,
                "label": "Amelie-les-Bains-Palalda",
                "label_ar": "اميلي ليه بان بالالدا",
                "label_fr": "Amélie-les-Bains-Palalda"
            },
            {
                "value": 32001,
                "label": "Baho",
                "label_ar": "باهو",
                "label_fr": "Baho"
            },
            {
                "value": 32001,
                "label": "Sant Joan de Pladecorts",
                "label_ar": "سانت جوان دي بلاديكورتس",
                "label_fr": "Sant Joan de Pladecorts"
            },
            {
                "value": 32002,
                "label": "Ceret",
                "label_ar": "سيريت",
                "label_fr": "Céret"
            },
            {
                "value": 32003,
                "label": "Sant Genis de Fontanes",
                "label_ar": "سانت جينيس دي فونتانيس",
                "label_fr": "Sant Genis de Fontanes"
            },
            {
                "value": 32004,
                "label": "Jassans-Riottier",
                "label_ar": "جاسان ريوتييه",
                "label_fr": "Jassans-Riottier"
            },
            {
                "value": 32005,
                "label": "Reiningue",
                "label_ar": "رينينج",
                "label_fr": "Reiningue"
            },
            {
                "value": 32006,
                "label": "Les Peintures",
                "label_ar": "ليه بينتوريس",
                "label_fr": "Les Peintures"
            },
            {
                "value": 32007,
                "label": "Saacy-sur-Marne",
                "label_ar": "ساسي سور مارن",
                "label_fr": "Saacy-sur-Marne"
            },
            {
                "value": 32008,
                "label": "Saint-Georges-de-Reneins",
                "label_ar": "سان جورج دي رينينز",
                "label_fr": "Saint-Georges-de-Reneins"
            },
            {
                "value": 32009,
                "label": "Chauffailles",
                "label_ar": "Chauffailles",
                "label_fr": "Chauffailles"
            },
            {
                "value": 32010,
                "label": "La Clayette",
                "label_ar": "لا كلايت",
                "label_fr": "La Clayette"
            },
            {
                "value": 32011,
                "label": "Jouy-sur-Morin",
                "label_ar": "جوي سور مورين",
                "label_fr": "Jouy-sur-Morin"
            },
            {
                "value": 32012,
                "label": "Poule-les-Echarmeaux",
                "label_ar": "بول ليس إشارمو",
                "label_fr": "Poule-les-Echarmeaux"
            },
            {
                "value": 32013,
                "label": "Anor",
                "label_ar": "أنور",
                "label_fr": "Anor"
            },
            {
                "value": 32014,
                "label": "Trelon",
                "label_ar": "تريلون",
                "label_fr": "Trelon"
            },
            {
                "value": 32015,
                "label": "Gourdan-Polignan",
                "label_ar": "جوردان بولينيان",
                "label_fr": "Gourdan-Polignan"
            },
            {
                "value": 32016,
                "label": "Ousson-sur-Loire",
                "label_ar": "أوسون سور لوار",
                "label_fr": "Ousson-sur-Loire"
            },
            {
                "value": 32017,
                "label": "Chatillon-sur-Loire",
                "label_ar": "شاتيلون سور لوار",
                "label_fr": "Châtillon-sur-Loire"
            },
            {
                "value": 32018,
                "label": "Le Temple-de-Bretagne",
                "label_ar": "لو تمبل دي بريتاني",
                "label_fr": "Le Temple-de-Bretagne"
            },
            {
                "value": 32019,
                "label": "Traubach-le-Bas",
                "label_ar": "تراوباتش لو باس",
                "label_fr": "Traubach-le-Bas"
            },
            {
                "value": 32020,
                "label": "Kilstett",
                "label_ar": "كيلستيت",
                "label_fr": "Kilstett"
            },
            {
                "value": 32021,
                "label": "Bray-en-Val",
                "label_ar": "براي إن فال",
                "label_fr": "Bray-en-Val"
            },
            {
                "value": 32022,
                "label": "Grundviller",
                "label_ar": "جروندفيلر",
                "label_fr": "Grundviller"
            },
            {
                "value": 32023,
                "label": "Saint-Jean-Rohrbach",
                "label_ar": "سان جان رورباخ",
                "label_fr": "Saint-Jean-Rohrbach"
            },
            {
                "value": 32024,
                "label": "Antilly",
                "label_ar": "قبل ذلك",
                "label_fr": "Antilly"
            },
            {
                "value": 32025,
                "label": "Coupru",
                "label_ar": "كوبرو",
                "label_fr": "Coupru"
            },
            {
                "value": 32026,
                "label": "Landelles",
                "label_ar": "Landelles",
                "label_fr": "Landelles"
            },
            {
                "value": 32027,
                "label": "Fresnay-le-Gilmert",
                "label_ar": "فريسناي لو جيلمرت",
                "label_fr": "Fresnay-le-Gilmert"
            },
            {
                "value": 32028,
                "label": "Airaines",
                "label_ar": "Airaines",
                "label_fr": "Airaines"
            },
            {
                "value": 32029,
                "label": "Oisemont",
                "label_ar": "أويسمونت",
                "label_fr": "Oisemont"
            },
            {
                "value": 32030,
                "label": "Pierrevillers",
                "label_ar": "بييرفيلرز",
                "label_fr": "Pierrevillers"
            },
            {
                "value": 32031,
                "label": "Lauterbourg",
                "label_ar": "لوتربورغ",
                "label_fr": "Lauterbourg"
            },
            {
                "value": 32032,
                "label": "Saint-Barthelemy-Lestra",
                "label_ar": "سان بارتيليمي ليسترا",
                "label_fr": "Saint-Barthélemy-Lestra"
            },
            {
                "value": 32033,
                "label": "Marcilloles",
                "label_ar": "مارسيلولس",
                "label_fr": "Marcilloles"
            },
            {
                "value": 32034,
                "label": "Juilly",
                "label_ar": "جويلي",
                "label_fr": "Juilly"
            },
            {
                "value": 32035,
                "label": "Plancher-Bas",
                "label_ar": "بلانشر باس",
                "label_fr": "Plancher-Bas"
            },
            {
                "value": 32036,
                "label": "Recologne-les-Rioz",
                "label_ar": "ريكولونيا ليس ريوز",
                "label_fr": "Recologne-les-Rioz"
            },
            {
                "value": 32037,
                "label": "Esquerchin",
                "label_ar": "Esquerchin",
                "label_fr": "Esquerchin"
            },
            {
                "value": 32038,
                "label": "Saint-Fulgent",
                "label_ar": "سان فولجنت",
                "label_fr": "Saint-Fulgent"
            },
            {
                "value": 32039,
                "label": "Brax",
                "label_ar": "براكس",
                "label_fr": "Brax"
            },
            {
                "value": 32040,
                "label": "Saint-Rambert-d'Albon",
                "label_ar": "سان رامبرت دالبون",
                "label_fr": "Saint-Rambert-d'Albon"
            },
            {
                "value": 32041,
                "label": "Montmeyran",
                "label_ar": "مونتميران",
                "label_fr": "Montmeyran"
            },
            {
                "value": 32042,
                "label": "Barbieres",
                "label_ar": "باربيريس",
                "label_fr": "Barbières"
            },
            {
                "value": 32043,
                "label": "Varzay",
                "label_ar": "فارزاي",
                "label_fr": "Varzay"
            },
            {
                "value": 32044,
                "label": "Cancale",
                "label_ar": "إلغاء",
                "label_fr": "Cancale"
            },
            {
                "value": 32045,
                "label": "Orbeil",
                "label_ar": "أوربيل",
                "label_fr": "Orbeil"
            },
            {
                "value": 32046,
                "label": "Dadonville",
                "label_ar": "دادونفيل",
                "label_fr": "Dadonville"
            },
            {
                "value": 32047,
                "label": "Massiac",
                "label_ar": "ماسياك",
                "label_fr": "Massiac"
            },
            {
                "value": 32048,
                "label": "Saint-Flour",
                "label_ar": "سان فلور",
                "label_fr": "Saint-Flour"
            },
            {
                "value": 32049,
                "label": "Martres-Tolosane",
                "label_ar": "مارتريس تولوسان",
                "label_fr": "Martres-Tolosane"
            },
            {
                "value": 32050,
                "label": "Roquefort-sur-Garonne",
                "label_ar": "روكفور سور غارون",
                "label_fr": "Roquefort-sur-Garonne"
            },
            {
                "value": 32051,
                "label": "Vic-sur-Cere",
                "label_ar": "فيك سور سير",
                "label_fr": "Vic-sur-Cère"
            },
            {
                "value": 32052,
                "label": "Maureville",
                "label_ar": "موريفيل",
                "label_fr": "Maureville"
            },
            {
                "value": 32053,
                "label": "Le Port",
                "label_ar": "لو بورت",
                "label_fr": "Le Port"
            },
            {
                "value": 32054,
                "label": "Harbonnieres",
                "label_ar": "Harbonnieres",
                "label_fr": "Harbonnières"
            },
            {
                "value": 32055,
                "label": "Entressen",
                "label_ar": "Entressen",
                "label_fr": "Entressen"
            },
            {
                "value": 32056,
                "label": "Clermont",
                "label_ar": "كليرمونت",
                "label_fr": "Clermont"
            },
            {
                "value": 32057,
                "label": "Dimechaux",
                "label_ar": "ديميكو",
                "label_fr": "Dimechaux"
            },
            {
                "value": 32058,
                "label": "Gouves",
                "label_ar": "جوفز",
                "label_fr": "Gouves"
            },
            {
                "value": 32059,
                "label": "Aubigny-en-Artois",
                "label_ar": "أوبيجني أون أرتواز",
                "label_fr": "Aubigny-en-Artois"
            },
            {
                "value": 32060,
                "label": "Capelle-Fermont",
                "label_ar": "كابيل فيرمونت",
                "label_fr": "Capelle-Fermont"
            },
            {
                "value": 32061,
                "label": "Bourcefranc-le-Chapus",
                "label_ar": "Bourcefranc-le-Chapus",
                "label_fr": "Bourcefranc-le-Chapus"
            },
            {
                "value": 32062,
                "label": "Etaules",
                "label_ar": "إيتوليس",
                "label_fr": "Etaules"
            },
            {
                "value": 32063,
                "label": "Bretteville-l'Orgueilleuse",
                "label_ar": "Bretteville-l'Orgueilleuse",
                "label_fr": "Bretteville-l'Orgueilleuse"
            },
            {
                "value": 32064,
                "label": "Pouligney-Lusans",
                "label_ar": "بوليني لوزان",
                "label_fr": "Pouligney-Lusans"
            },
            {
                "value": 32065,
                "label": "Vieilley",
                "label_ar": "فييلي",
                "label_fr": "Vieilley"
            },
            {
                "value": 32066,
                "label": "Pelousey",
                "label_ar": "بيلوسي",
                "label_fr": "Pelousey"
            },
            {
                "value": 32067,
                "label": "Recologne",
                "label_ar": "إعادة الكولونيا",
                "label_fr": "Recologne"
            },
            {
                "value": 32068,
                "label": "Valay",
                "label_ar": "فالاي",
                "label_fr": "Valay"
            },
            {
                "value": 32069,
                "label": "Tassille",
                "label_ar": "تاسيلي",
                "label_fr": "Tassille"
            },
            {
                "value": 32070,
                "label": "Longevelle-sur-Doubs",
                "label_ar": "لونجيفيل سور دوبس",
                "label_fr": "Longevelle-sur-Doubs"
            },
            {
                "value": 32071,
                "label": "Etouvans",
                "label_ar": "Etouvans",
                "label_fr": "Etouvans"
            },
            {
                "value": 32072,
                "label": "Chadrac",
                "label_ar": "شادراك",
                "label_fr": "Chadrac"
            },
            {
                "value": 32073,
                "label": "Craincourt",
                "label_ar": "كرينكورت",
                "label_fr": "Craincourt"
            },
            {
                "value": 32074,
                "label": "Gresy-sur-Aix",
                "label_ar": "جريسي سور إيكس",
                "label_fr": "Gresy-sur-Aix"
            },
            {
                "value": 32075,
                "label": "Layrac",
                "label_ar": "ليراك",
                "label_fr": "Layrac"
            },
            {
                "value": 32076,
                "label": "Saint-Pargoire",
                "label_ar": "سان بارجوار",
                "label_fr": "Saint-Pargoire"
            },
            {
                "value": 32077,
                "label": "Astaffort",
                "label_ar": "أستافورت",
                "label_fr": "Astaffort"
            },
            {
                "value": 32078,
                "label": "Bossendorf",
                "label_ar": "بوسندورف",
                "label_fr": "Bossendorf"
            },
            {
                "value": 32079,
                "label": "Roeschwoog",
                "label_ar": "روشووج",
                "label_fr": "Roeschwoog"
            },
            {
                "value": 32080,
                "label": "Haussy",
                "label_ar": "هوسي",
                "label_fr": "Haussy"
            },
            {
                "value": 32081,
                "label": "Seltz",
                "label_ar": "سيلتز",
                "label_fr": "Seltz"
            },
            {
                "value": 32082,
                "label": "Lapalud",
                "label_ar": "لابالود",
                "label_fr": "Lapalud"
            },
            {
                "value": 32083,
                "label": "Piolenc",
                "label_ar": "بيولينك",
                "label_fr": "Piolenc"
            },
            {
                "value": 32084,
                "label": "Nesles",
                "label_ar": "نيسليس",
                "label_fr": "Nesles"
            },
            {
                "value": 32085,
                "label": "Rang-du-Fliers",
                "label_ar": "رانج دو فلايرز",
                "label_fr": "Rang-du-Fliers"
            },
            {
                "value": 32086,
                "label": "Boisjean",
                "label_ar": "Boisjean",
                "label_fr": "Boisjean"
            },
            {
                "value": 32087,
                "label": "Cucq",
                "label_ar": "كوكك",
                "label_fr": "Cucq"
            },
            {
                "value": 32088,
                "label": "Trepied",
                "label_ar": "مذعور",
                "label_fr": "Trépied"
            },
            {
                "value": 32089,
                "label": "Vendemian",
                "label_ar": "فيندميان",
                "label_fr": "Vendémien"
            },
            {
                "value": 32090,
                "label": "Marqueglise",
                "label_ar": "ماركيجليز",
                "label_fr": "Marqueglise"
            },
            {
                "value": 32091,
                "label": "Le Mesnil-Amelot",
                "label_ar": "لو ميسنيل أميلوت",
                "label_fr": "Le Mesnil-Amelot"
            },
            {
                "value": 32092,
                "label": "Corbeilles",
                "label_ar": "كوربيل",
                "label_fr": "Corbeilles"
            },
            {
                "value": 32093,
                "label": "Fressies",
                "label_ar": "فريسيس",
                "label_fr": "Fressies"
            },
            {
                "value": 32094,
                "label": "Flines-les-Mortagne",
                "label_ar": "Flines-les-Mortagne",
                "label_fr": "Flines-les-Mortagne"
            },
            {
                "value": 32095,
                "label": "Thun-Saint-Amand",
                "label_ar": "ثون سانت اماند",
                "label_fr": "Thoune-Saint-Amand"
            },
            {
                "value": 32096,
                "label": "Quievrechain",
                "label_ar": "Quievrechain",
                "label_fr": "Quievrechain"
            },
            {
                "value": 32097,
                "label": "Salbris",
                "label_ar": "سالبريس",
                "label_fr": "Salbris"
            },
            {
                "value": 32098,
                "label": "Liffol-le-Grand",
                "label_ar": "ليفول لو جراند",
                "label_fr": "Liffol-le-Grand"
            },
            {
                "value": 32099,
                "label": "Aslonnes",
                "label_ar": "أصلون",
                "label_fr": "Aslonnes"
            },
            {
                "value": 32100,
                "label": "Argeles-Gazost",
                "label_ar": "أرجيلس غازوست",
                "label_fr": "Argelès-Gazost"
            },
            {
                "value": 32101,
                "label": "Ordan-Larroque",
                "label_ar": "أوردان لاروك",
                "label_fr": "Ordan-Larroque"
            },
            {
                "value": 32102,
                "label": "Vic-Fezensac",
                "label_ar": "فيك-فيزينساك",
                "label_fr": "Vic-Fezensac"
            },
            {
                "value": 32103,
                "label": "Verdun-sur-le-Doubs",
                "label_ar": "فردان سور لو دوبس",
                "label_fr": "Verdun-sur-le-Doubs"
            },
            {
                "value": 32104,
                "label": "Andancette",
                "label_ar": "Andancette",
                "label_fr": "Andancette"
            },
            {
                "value": 32105,
                "label": "Beausemblant",
                "label_ar": "Beausemblant",
                "label_fr": "Beausemblant"
            },
            {
                "value": 32106,
                "label": "Raches",
                "label_ar": "راشيس",
                "label_fr": "Raches"
            },
            {
                "value": 32107,
                "label": "Sachin",
                "label_ar": "ساشين",
                "label_fr": "Sachin"
            },
            {
                "value": 32108,
                "label": "Pernes",
                "label_ar": "بيرنس",
                "label_fr": "Pernes"
            },
            {
                "value": 32109,
                "label": "Vieux-Berquin",
                "label_ar": "Vieux-Berquin",
                "label_fr": "Vieux-Berquin"
            },
            {
                "value": 32110,
                "label": "La Gorgue",
                "label_ar": "لا غورج",
                "label_fr": "La Gorgue"
            },
            {
                "value": 32111,
                "label": "Saint-Jeoire",
                "label_ar": "سان جيوار",
                "label_fr": "Saint-Jeoire"
            },
            {
                "value": 32112,
                "label": "Bloye",
                "label_ar": "بلوي",
                "label_fr": "Bloye"
            },
            {
                "value": 32113,
                "label": "Saint-Trivier-sur-Moignans",
                "label_ar": "سان تريفير سور موينانس",
                "label_fr": "Saint-Trivier-sur-Moignans"
            },
            {
                "value": 32114,
                "label": "Travecy",
                "label_ar": "ترافيسي",
                "label_fr": "Travecy"
            },
            {
                "value": 32115,
                "label": "Saint-Etienne-d'Albagnan",
                "label_ar": "سانت إتيان دالبنان",
                "label_fr": "Saint-Étienne-d'Albagnan"
            },
            {
                "value": 32116,
                "label": "Saint-Martin-de-Londres",
                "label_ar": "سان مارتن دي لوندر",
                "label_fr": "Saint-Martin-de-Londres"
            },
            {
                "value": 32117,
                "label": "Saint-Mathieu-de-Treviers",
                "label_ar": "سانت ماتيو دي تريفييه",
                "label_fr": "Saint-Mathieu-de-Treviers"
            },
            {
                "value": 32118,
                "label": "Ault",
                "label_ar": "أولت",
                "label_fr": "Ault"
            },
            {
                "value": 32119,
                "label": "Weislingen",
                "label_ar": "ويزلينجن",
                "label_fr": "Weislingen"
            },
            {
                "value": 32120,
                "label": "Bray-sur-Somme",
                "label_ar": "براي سور سوم",
                "label_fr": "Bray-sur-Somme"
            },
            {
                "value": 32121,
                "label": "Querqueville",
                "label_ar": "Querqueville",
                "label_fr": "Querqueville"
            },
            {
                "value": 32122,
                "label": "Mailly-Maillet",
                "label_ar": "مايلي مايليت",
                "label_fr": "Mailly-Maillet"
            },
            {
                "value": 32123,
                "label": "Richardmenil",
                "label_ar": "ريتشاردمينيل",
                "label_fr": "Richardmenil"
            },
            {
                "value": 32124,
                "label": "Ceintrey",
                "label_ar": "سينتري",
                "label_fr": "Ceintrey"
            },
            {
                "value": 32125,
                "label": "Pulligny",
                "label_ar": "بوليجني",
                "label_fr": "Pulligny"
            },
            {
                "value": 32126,
                "label": "Gomelange",
                "label_ar": "جوميلانج",
                "label_fr": "Gomelange"
            },
            {
                "value": 32127,
                "label": "Chessy",
                "label_ar": "شيسي",
                "label_fr": "Chessy"
            },
            {
                "value": 32128,
                "label": "Marmagne",
                "label_ar": "مارمانيا",
                "label_fr": "Marmagne"
            },
            {
                "value": 32129,
                "label": "Coincy",
                "label_ar": "مصادفة",
                "label_fr": "Coincy"
            },
            {
                "value": 32130,
                "label": "Villers-sur-Fere",
                "label_ar": "فيليرس سور فير",
                "label_fr": "Villers-sur-Fère"
            },
            {
                "value": 32131,
                "label": "Morre",
                "label_ar": "موري",
                "label_fr": "Morre"
            },
            {
                "value": 32132,
                "label": "Gerbecourt-et-Haplemont",
                "label_ar": "Gerbecourt-et-Haplemont",
                "label_fr": "Gerbecourt-et-Haplemont"
            },
            {
                "value": 32133,
                "label": "Montfaucon",
                "label_ar": "مونتفوكون",
                "label_fr": "Montfaucon"
            },
            {
                "value": 32134,
                "label": "Hery-sur-Alby",
                "label_ar": "هيري سور ألبي",
                "label_fr": "Hery-sur-Alby"
            },
            {
                "value": 32135,
                "label": "Vasselay",
                "label_ar": "فاسيلي",
                "label_fr": "Vasselay"
            },
            {
                "value": 32136,
                "label": "Anglefort",
                "label_ar": "أنجلفورت",
                "label_fr": "Anglefort"
            },
            {
                "value": 32137,
                "label": "Viesly",
                "label_ar": "فيسلي",
                "label_fr": "Viesly"
            },
            {
                "value": 32138,
                "label": "Cers",
                "label_ar": "سيرز",
                "label_fr": "Cers"
            },
            {
                "value": 32139,
                "label": "Bieville-Beuville",
                "label_ar": "بيفيل بوفيل",
                "label_fr": "Bieville-Beuville"
            },
            {
                "value": 32140,
                "label": "Maussane-les-Alpilles",
                "label_ar": "موسان ليه ألبيل",
                "label_fr": "Maussane-les-Alpilles"
            },
            {
                "value": 32141,
                "label": "Soultzmatt",
                "label_ar": "سولتزمات",
                "label_fr": "Soultzmatt"
            },
            {
                "value": 32142,
                "label": "Roquefort-la-Bedoule",
                "label_ar": "روكفور لا بيدول",
                "label_fr": "Roquefort-la-Bédoule"
            },
            {
                "value": 32143,
                "label": "Saint-Piat",
                "label_ar": "سانت بيات",
                "label_fr": "Saint-Piat"
            },
            {
                "value": 32144,
                "label": "La Dominelais",
                "label_ar": "لا دومينيليه",
                "label_fr": "La Dominelais"
            },
            {
                "value": 32145,
                "label": "Bourg-Blanc",
                "label_ar": "بورغ بلانك",
                "label_fr": "Bourg-Blanc"
            },
            {
                "value": 32146,
                "label": "Coulandon",
                "label_ar": "كولاندون",
                "label_fr": "Coulandon"
            },
            {
                "value": 32147,
                "label": "Verrieres",
                "label_ar": "فيريريز",
                "label_fr": "Verrières"
            },
            {
                "value": 32148,
                "label": "Terrasson-Lavilledieu",
                "label_ar": "تيراسون لافيليديو",
                "label_fr": "Terrasson-Lavilledieu"
            },
            {
                "value": 32149,
                "label": "Mazeray",
                "label_ar": "Mazeray",
                "label_fr": "Mazeray"
            },
            {
                "value": 32150,
                "label": "Marcillac-la-Croisille",
                "label_ar": "Marcillac-la-Croisille",
                "label_fr": "Marcillac-la-Croisille"
            },
            {
                "value": 32151,
                "label": "Bonzac",
                "label_ar": "بونزاك",
                "label_fr": "Bonzac"
            },
            {
                "value": 32152,
                "label": "Boult-sur-Suippe",
                "label_ar": "بولت سور سويبي",
                "label_fr": "Boult-sur-Suippe"
            },
            {
                "value": 32153,
                "label": "Campagne-les-Wardrecques",
                "label_ar": "كامباني ليه وردريك",
                "label_fr": "Campagne-les-Wardrecques"
            },
            {
                "value": 32154,
                "label": "Marcoing",
                "label_ar": "ماركوينج",
                "label_fr": "Marcoing"
            },
            {
                "value": 32155,
                "label": "Marcamps",
                "label_ar": "ماركامبس",
                "label_fr": "Marcamps"
            },
            {
                "value": 32156,
                "label": "Saint-Aigulin",
                "label_ar": "سانت ايجولين",
                "label_fr": "Saint-Aigulin"
            },
            {
                "value": 32157,
                "label": "Authouillet",
                "label_ar": "Authouillet",
                "label_fr": "Authouillet"
            },
            {
                "value": 32158,
                "label": "Ricarville",
                "label_ar": "ريكارفيل",
                "label_fr": "Ricarville"
            },
            {
                "value": 32159,
                "label": "Bonnebosq",
                "label_ar": "Bonnebosq",
                "label_fr": "Bonnebosq"
            },
            {
                "value": 32160,
                "label": "Angerville-l'Orcher",
                "label_ar": "Angerville-l'Orcher",
                "label_fr": "Angerville-l'Orcher"
            },
            {
                "value": 32161,
                "label": "Le Torquesne",
                "label_ar": "لو توركيسن",
                "label_fr": "Le Torquesne"
            },
            {
                "value": 32162,
                "label": "Bourth",
                "label_ar": "بورث",
                "label_fr": "Bourth"
            },
            {
                "value": 32163,
                "label": "Meyras",
                "label_ar": "ميراس",
                "label_fr": "Meyras"
            },
            {
                "value": 32164,
                "label": "Saint-Laurent-sur-Gorre",
                "label_ar": "سان لوران سور جوري",
                "label_fr": "Saint-Laurent-sur-Gorre"
            },
            {
                "value": 32165,
                "label": "Varetz",
                "label_ar": "فاريتز",
                "label_fr": "Varetz"
            },
            {
                "value": 32166,
                "label": "La Baussaine",
                "label_ar": "لا باوسين",
                "label_fr": "La Baussaine"
            },
            {
                "value": 32167,
                "label": "Cheverny",
                "label_ar": "شيفيرني",
                "label_fr": "Cheverny"
            },
            {
                "value": 32168,
                "label": "Bozel",
                "label_ar": "بوزل",
                "label_fr": "Bozel"
            },
            {
                "value": 32169,
                "label": "Pray",
                "label_ar": "صلى",
                "label_fr": "Prier"
            },
            {
                "value": 32170,
                "label": "Villesiscle",
                "label_ar": "فيليسكل",
                "label_fr": "Villesiscle"
            },
            {
                "value": 32171,
                "label": "Sannerville",
                "label_ar": "سانرفيل",
                "label_fr": "Sannerville"
            },
            {
                "value": 32172,
                "label": "Saint-Julien-sur-Sarthe",
                "label_ar": "سان جوليان سور سارث",
                "label_fr": "Saint-Julien-sur-Sarthe"
            },
            {
                "value": 32173,
                "label": "Martainville-Epreville",
                "label_ar": "Martainville-Epreville",
                "label_fr": "Martainville-Épreville"
            },
            {
                "value": 32174,
                "label": "Saint-Vivien-de-Medoc",
                "label_ar": "سان فيفيان دي ميدوك",
                "label_fr": "Saint-Vivien-de-Médoc"
            },
            {
                "value": 32175,
                "label": "Frechou",
                "label_ar": "فريتشو",
                "label_fr": "Frechou"
            },
            {
                "value": 32176,
                "label": "Montelier",
                "label_ar": "مونتيلير",
                "label_fr": "Montelier"
            },
            {
                "value": 32177,
                "label": "Argeliers",
                "label_ar": "أرجيليرز",
                "label_fr": "Argeliers"
            },
            {
                "value": 32178,
                "label": "Chalezeule",
                "label_ar": "شاليزيول",
                "label_fr": "Chalezeule"
            },
            {
                "value": 32179,
                "label": "Turquant",
                "label_ar": "تيركوانت",
                "label_fr": "Turquant"
            },
            {
                "value": 32180,
                "label": "Greolieres",
                "label_ar": "Greolieres",
                "label_fr": "Greolières"
            },
            {
                "value": 32181,
                "label": "Pargny-Filain",
                "label_ar": "بارجني فيلان",
                "label_fr": "Pargny-Filain"
            },
            {
                "value": 32182,
                "label": "Le Luart",
                "label_ar": "لو لوارت",
                "label_fr": "Le Luart"
            },
            {
                "value": 32183,
                "label": "Parce-sur-Sarthe",
                "label_ar": "Parce-sur-Sarthe",
                "label_fr": "Parce-sur-Sarthe"
            },
            {
                "value": 32184,
                "label": "Pern",
                "label_ar": "بيرن",
                "label_fr": "Pern"
            },
            {
                "value": 32185,
                "label": "Pierregot",
                "label_ar": "بيريجوت",
                "label_fr": "Pierregot"
            },
            {
                "value": 32186,
                "label": "Quinsac",
                "label_ar": "كوينساك",
                "label_fr": "Quinsac"
            },
            {
                "value": 32187,
                "label": "La Martiniere",
                "label_ar": "لا مارتينير",
                "label_fr": "La Martinière"
            },
            {
                "value": 32188,
                "label": "Lauris",
                "label_ar": "لوريس",
                "label_fr": "Lauris"
            },
            {
                "value": 32189,
                "label": "La Couronne",
                "label_ar": "لا كورون",
                "label_fr": "La Couronne"
            },
            {
                "value": 32190,
                "label": "Aurons",
                "label_ar": "أورنز",
                "label_fr": "Aurons"
            },
            {
                "value": 32191,
                "label": "Saint-Agreve",
                "label_ar": "سان اجريف",
                "label_fr": "Saint-Agreve"
            },
            {
                "value": 32192,
                "label": "Saint-Remeze",
                "label_ar": "سان ريميزي",
                "label_fr": "Saint-Remeze"
            },
            {
                "value": 32193,
                "label": "L'Houmeau",
                "label_ar": "لومو",
                "label_fr": "L'Houmeau"
            },
            {
                "value": 32194,
                "label": "Troussencourt",
                "label_ar": "تروسينكورت",
                "label_fr": "Troussencourt"
            },
            {
                "value": 32195,
                "label": "Meilhan-sur-Garonne",
                "label_ar": "ميلهان سور غارون",
                "label_fr": "Meilhan-sur-Garonne"
            },
            {
                "value": 32196,
                "label": "Peypin-d'Aigues",
                "label_ar": "بيبين دايج",
                "label_fr": "Peypin-d'Aigues"
            },
            {
                "value": 32197,
                "label": "Jouy-sur-Eure",
                "label_ar": "جوي سور أور",
                "label_fr": "Jouy-sur-Eure"
            },
            {
                "value": 32198,
                "label": "Carcans",
                "label_ar": "كاركان",
                "label_fr": "Carcans"
            },
            {
                "value": 32199,
                "label": "Le Mas-d'Agenais",
                "label_ar": "لو ماس دجانيه",
                "label_fr": "Le Mas-d'Agenais"
            },
            {
                "value": 32200,
                "label": "Ingrandes",
                "label_ar": "إنغراندس",
                "label_fr": "Ingrandes"
            },
            {
                "value": 32201,
                "label": "Balbigny",
                "label_ar": "بالبيجني",
                "label_fr": "Balbigny"
            },
            {
                "value": 32202,
                "label": "Saint-Medard-d'Aunis",
                "label_ar": "سان ميدارد دأونيس",
                "label_fr": "Saint-Médard-d'Aunis"
            },
            {
                "value": 32203,
                "label": "Les Molieres",
                "label_ar": "ليه مولييه",
                "label_fr": "Les Molières"
            },
            {
                "value": 32204,
                "label": "Malemort-du-Comtat",
                "label_ar": "Malemort-du-Comtat",
                "label_fr": "Malemort-du-Comtat"
            },
            {
                "value": 32205,
                "label": "Faverolles-sur-Cher",
                "label_ar": "فافيرولس سور شير",
                "label_fr": "Faverolles-sur-Cher"
            },
            {
                "value": 32206,
                "label": "Cublac",
                "label_ar": "كوبلاك",
                "label_fr": "Cublac"
            },
            {
                "value": 32207,
                "label": "Feytiat",
                "label_ar": "فتيات",
                "label_fr": "Feytiat"
            },
            {
                "value": 32208,
                "label": "Lubersac",
                "label_ar": "لوبرساك",
                "label_fr": "Lubersac"
            },
            {
                "value": 32209,
                "label": "Contigne",
                "label_ar": "كونتين",
                "label_fr": "Contigne"
            },
            {
                "value": 32210,
                "label": "Aigne",
                "label_ar": "أين",
                "label_fr": "Aigne"
            },
            {
                "value": 32211,
                "label": "Dourdain",
                "label_ar": "دوردين",
                "label_fr": "Dourdain"
            },
            {
                "value": 32212,
                "label": "Laugnac",
                "label_ar": "لوجناك",
                "label_fr": "Laugnac"
            },
            {
                "value": 32213,
                "label": "Le Molard",
                "label_ar": "لو مولارد",
                "label_fr": "Le Molard"
            },
            {
                "value": 32214,
                "label": "Ceyzeriat",
                "label_ar": "سيزريات",
                "label_fr": "Ceyzériat"
            },
            {
                "value": 32215,
                "label": "Montverdun",
                "label_ar": "مونتفيردون",
                "label_fr": "Montverdun"
            },
            {
                "value": 32216,
                "label": "Salleles-d'Aude",
                "label_ar": "مواز دأود",
                "label_fr": "Salleles-d'Aude"
            },
            {
                "value": 32217,
                "label": "Contoire",
                "label_ar": "كونتوار",
                "label_fr": "Contoire"
            },
            {
                "value": 32218,
                "label": "Saint-Valery-sur-Somme",
                "label_ar": "سان فاليري سور سوم",
                "label_fr": "Saint-Valery-sur-Somme"
            },
            {
                "value": 32219,
                "label": "Puchevillers",
                "label_ar": "Puchevillers",
                "label_fr": "Puchevillers"
            },
            {
                "value": 32220,
                "label": "Prasville",
                "label_ar": "براسفيل",
                "label_fr": "Prasville"
            },
            {
                "value": 32221,
                "label": "Sille-le-Guillaume",
                "label_ar": "سيل لو غيوم",
                "label_fr": "Sille-le-Guillaume"
            },
            {
                "value": 32222,
                "label": "Tuffe",
                "label_ar": "توف",
                "label_fr": "Tuffe"
            },
            {
                "value": 32223,
                "label": "Saint-Julien-de-Cassagnas",
                "label_ar": "سان جوليان دي كاساجناس",
                "label_fr": "Saint-Julien-de-Cassagnas"
            },
            {
                "value": 32224,
                "label": "Coirac",
                "label_ar": "كويراك",
                "label_fr": "Coirac"
            },
            {
                "value": 32225,
                "label": "Emieville",
                "label_ar": "اميفيل",
                "label_fr": "Emieville"
            },
            {
                "value": 32226,
                "label": "Plounevezel",
                "label_ar": "بلونيفزيل",
                "label_fr": "Plounevezel"
            },
            {
                "value": 32227,
                "label": "Clavette",
                "label_ar": "كلافيت",
                "label_fr": "Clavette"
            },
            {
                "value": 32228,
                "label": "Aougny",
                "label_ar": "اوجني",
                "label_fr": "Aougny"
            },
            {
                "value": 32229,
                "label": "Raucourt-et-Flaba",
                "label_ar": "Raucourt-et-Flaba",
                "label_fr": "Raucourt-et-Flaba"
            },
            {
                "value": 32230,
                "label": "Audun-le-Roman",
                "label_ar": "أودون لو رومان",
                "label_fr": "Audun-le-Roman"
            },
            {
                "value": 32231,
                "label": "Bouer",
                "label_ar": "بوير",
                "label_fr": "Bouer"
            },
            {
                "value": 32232,
                "label": "Lignieres-la-Carelle",
                "label_ar": "Lignieres-la-Carelle",
                "label_fr": "Lignières-la-Carelle"
            },
            {
                "value": 32233,
                "label": "Etriac",
                "label_ar": "إترياك",
                "label_fr": "Etriac"
            },
            {
                "value": 32234,
                "label": "La Halle",
                "label_ar": "لا هال",
                "label_fr": "La Halle"
            },
            {
                "value": 32235,
                "label": "Mesnil-sur-l'Estree",
                "label_ar": "Mesnil-sur-l'Estree",
                "label_fr": "Mesnil-sur-l'Estree"
            },
            {
                "value": 32236,
                "label": "Flamanville",
                "label_ar": "فلامانفيل",
                "label_fr": "Flamanville"
            },
            {
                "value": 32237,
                "label": "Laguenne",
                "label_ar": "لاجوين",
                "label_fr": "Laguenne"
            },
            {
                "value": 32238,
                "label": "Bagnoles-de-l'Orne",
                "label_ar": "Bagnoles-de-l'Orne",
                "label_fr": "Bagnoles-de-l'Orne"
            },
            {
                "value": 32239,
                "label": "La Vespiere",
                "label_ar": "لا فيسبير",
                "label_fr": "La Vespiere"
            },
            {
                "value": 32240,
                "label": "Meslay",
                "label_ar": "ميسلاي",
                "label_fr": "Meslay"
            },
            {
                "value": 32241,
                "label": "Bourganeuf",
                "label_ar": "بورجانوف",
                "label_fr": "Bourganeuf"
            },
            {
                "value": 32242,
                "label": "La Dagueniere",
                "label_ar": "لا داغوينير",
                "label_fr": "La Daguenière"
            },
            {
                "value": 32243,
                "label": "La Possonniere",
                "label_ar": "لا بوسونيير",
                "label_fr": "La Possonnière"
            },
            {
                "value": 32244,
                "label": "Bersee",
                "label_ar": "بيرسي",
                "label_fr": "Bersee"
            },
            {
                "value": 32245,
                "label": "Fretin",
                "label_ar": "فريتين",
                "label_fr": "Fretin"
            },
            {
                "value": 32246,
                "label": "Faumont",
                "label_ar": "فومونت",
                "label_fr": "Faumont"
            },
            {
                "value": 32247,
                "label": "Courcemont",
                "label_ar": "كورسيمونت",
                "label_fr": "Courcemont"
            },
            {
                "value": 32248,
                "label": "Saint-Germain-les-Belles",
                "label_ar": "سان جيرمان ليه بيل",
                "label_fr": "Saint-Germain-les-Belles"
            },
            {
                "value": 32249,
                "label": "Saint-Germain-les-Vergnes",
                "label_ar": "سان جيرمان ليه فيرجنيس",
                "label_fr": "Saint-Germain-les-Vergnes"
            },
            {
                "value": 32250,
                "label": "Oblinghem",
                "label_ar": "Oblinghem",
                "label_fr": "Oblinghem"
            },
            {
                "value": 32251,
                "label": "Cagnicourt",
                "label_ar": "كانيكورت",
                "label_fr": "Cagnicourt"
            },
            {
                "value": 32252,
                "label": "Troisvilles",
                "label_ar": "ترويسفيل",
                "label_fr": "Troisvilles"
            },
            {
                "value": 32253,
                "label": "Riantec",
                "label_ar": "ريانتك",
                "label_fr": "Riantec"
            },
            {
                "value": 32254,
                "label": "Saint-Honore-les-Bains",
                "label_ar": "سانت أونوريه ليه با",
                "label_fr": "Saint-Honoré-les-Bains"
            },
            {
                "value": 32255,
                "label": "Garchizy",
                "label_ar": "جارشيزي",
                "label_fr": "Garchizy"
            },
            {
                "value": 32256,
                "label": "La Feuillade",
                "label_ar": "لا فيليد",
                "label_fr": "La Feuillade"
            },
            {
                "value": 32257,
                "label": "Yssandon",
                "label_ar": "يساندون",
                "label_fr": "Yssandon"
            },
            {
                "value": 32258,
                "label": "Aigremont",
                "label_ar": "ايجريمونت",
                "label_fr": "Aigremont"
            },
            {
                "value": 32259,
                "label": "Blanzy",
                "label_ar": "بلانزي",
                "label_fr": "Blanzy"
            },
            {
                "value": 32260,
                "label": "Aiguebelle",
                "label_ar": "إيبيل",
                "label_fr": "Aiguebelle"
            },
            {
                "value": 32261,
                "label": "Serrieres-en-Chautagne",
                "label_ar": "Serrieres-en-Chautagne",
                "label_fr": "Serrières-en-Chautagne"
            },
            {
                "value": 32262,
                "label": "Cours-les-Barres",
                "label_ar": "كورس ليه باريه",
                "label_fr": "Cours-les-Barres"
            },
            {
                "value": 32263,
                "label": "Saint-Martin-des-Olmes",
                "label_ar": "سان مارتن دي أولمز",
                "label_fr": "Saint-Martin-des-Olmes"
            },
            {
                "value": 32264,
                "label": "Saint-Cezaire-sur-Siagne",
                "label_ar": "سان سيزير سور سيان",
                "label_fr": "Saint-Cézaire-sur-Siagne"
            },
            {
                "value": 32265,
                "label": "Nueil-sur-Layon",
                "label_ar": "Nueil-sur-Layon",
                "label_fr": "Nueil-sur-Layon"
            },
            {
                "value": 32266,
                "label": "La Romagne",
                "label_ar": "لا روماني",
                "label_fr": "La Romagne"
            },
            {
                "value": 32267,
                "label": "Fiennes",
                "label_ar": "فين",
                "label_fr": "Fiennes"
            },
            {
                "value": 32268,
                "label": "Rebreuviette",
                "label_ar": "Rebreuviette",
                "label_fr": "Rebreuviette"
            },
            {
                "value": 32269,
                "label": "La Fouillouse",
                "label_ar": "لا فويلوز",
                "label_fr": "La Fouillouse"
            },
            {
                "value": 32270,
                "label": "Busigny",
                "label_ar": "مشغول",
                "label_fr": "Busigny"
            },
            {
                "value": 32271,
                "label": "Saramon",
                "label_ar": "سارامون",
                "label_fr": "Saramon"
            },
            {
                "value": 32272,
                "label": "Sainte-Croix-Volvestre",
                "label_ar": "سانت كروا فولفستر",
                "label_fr": "Sainte-Croix-Volvestre"
            },
            {
                "value": 32273,
                "label": "Courbeveille",
                "label_ar": "كوربيفيل",
                "label_fr": "Courbeveille"
            },
            {
                "value": 32274,
                "label": "Saint-Aubin-des-Chateaux",
                "label_ar": "سان أوبين دي شاتو",
                "label_fr": "Saint-Aubin-des-Châteaux"
            },
            {
                "value": 32275,
                "label": "Espira-de-Conflent",
                "label_ar": "Espira-de-Conflent",
                "label_fr": "Espira-de-Conflent"
            },
            {
                "value": 32276,
                "label": "Vendin-les-Bethune",
                "label_ar": "Vendin-les-Bethune",
                "label_fr": "Vendin-les-Béthune"
            },
            {
                "value": 32277,
                "label": "Le Pont-de-Claix",
                "label_ar": "لو بونت دي كليكس",
                "label_fr": "Le Pont-de-Claix"
            },
            {
                "value": 32278,
                "label": "Marienthal",
                "label_ar": "مارينثال",
                "label_fr": "Marienthal"
            },
            {
                "value": 32279,
                "label": "Sempigny",
                "label_ar": "سيمبيني",
                "label_fr": "Sempigny"
            },
            {
                "value": 32280,
                "label": "Pennautier",
                "label_ar": "بينناوتييه",
                "label_fr": "Pennautier"
            },
            {
                "value": 32281,
                "label": "Jacou",
                "label_ar": "جاكو",
                "label_fr": "Jacou"
            },
            {
                "value": 32282,
                "label": "Orvillers-Sorel",
                "label_ar": "Orvillers-Sorel",
                "label_fr": "Orvillers-Sorel"
            },
            {
                "value": 32283,
                "label": "Agny",
                "label_ar": "اجني",
                "label_fr": "Agny"
            },
            {
                "value": 32284,
                "label": "Chatenoy-le-Royal",
                "label_ar": "شاتينوي لو رويال",
                "label_fr": "Châtenoy-le-Royal"
            },
            {
                "value": 32285,
                "label": "La Chapelle-Gauthier",
                "label_ar": "لا شابيل غوتييه",
                "label_fr": "La Chapelle-Gauthier"
            },
            {
                "value": 32286,
                "label": "Saint-Victor-sur-Arlanc",
                "label_ar": "سان فيكتور سور أرلانك",
                "label_fr": "Saint-Victor-sur-Arlanc"
            },
            {
                "value": 32287,
                "label": "Grezieu-la-Varenne",
                "label_ar": "Grezieu-la-Varenne",
                "label_fr": "Grezieu-la-Varenne"
            },
            {
                "value": 32288,
                "label": "Peronnas",
                "label_ar": "بيروناس",
                "label_fr": "Peronnas"
            },
            {
                "value": 32289,
                "label": "Hauteville-les-Dijon",
                "label_ar": "أوتفيل ليه ديجون",
                "label_fr": "Hauteville-les-Dijon"
            },
            {
                "value": 32290,
                "label": "Givry",
                "label_ar": "جيفري",
                "label_fr": "Givry"
            },
            {
                "value": 32291,
                "label": "Mardeuil",
                "label_ar": "مردويل",
                "label_fr": "Mardeuil"
            },
            {
                "value": 32292,
                "label": "Octeville",
                "label_ar": "أوكتفيل",
                "label_fr": "Octeville"
            },
            {
                "value": 32293,
                "label": "Urville-Nacqueville",
                "label_ar": "أورفيل-ناكفيل",
                "label_fr": "Urville-Nacqueville"
            },
            {
                "value": 32294,
                "label": "Crissey",
                "label_ar": "كريسي",
                "label_fr": "Crissey"
            },
            {
                "value": 32295,
                "label": "Nouvion-le-Comte",
                "label_ar": "نوفيون لو كومت",
                "label_fr": "Nouvion-le-Comte"
            },
            {
                "value": 32296,
                "label": "Mesanger",
                "label_ar": "ميسانجر",
                "label_fr": "Mésanger"
            },
            {
                "value": 32297,
                "label": "Serezin-du-Rhone",
                "label_ar": "سيريزين دو رون",
                "label_fr": "Serezin-du-Rhône"
            },
            {
                "value": 32298,
                "label": "Lucenay",
                "label_ar": "لوسيناي",
                "label_fr": "Lucenay"
            },
            {
                "value": 32299,
                "label": "Durtol",
                "label_ar": "درتول",
                "label_fr": "Durtol"
            },
            {
                "value": 32300,
                "label": "Griesheim-pres-Molsheim",
                "label_ar": "جريشيم بريس مولشيم",
                "label_fr": "Griesheim-près-Molsheim"
            },
            {
                "value": 32301,
                "label": "Balan",
                "label_ar": "بالان",
                "label_fr": "Balan"
            },
            {
                "value": 32302,
                "label": "Dagneux",
                "label_ar": "دانوكس",
                "label_fr": "Dagneux"
            },
            {
                "value": 32303,
                "label": "Croissy-Beaubourg",
                "label_ar": "كرواسي بيوبورج",
                "label_fr": "Croissy-Beaubourg"
            },
            {
                "value": 32304,
                "label": "Saleux",
                "label_ar": "سالوكس",
                "label_fr": "Saleux"
            },
            {
                "value": 32305,
                "label": "Vers-sur-Selles",
                "label_ar": "Vers-sur-Selles",
                "label_fr": "Vers-sur-Selles"
            },
            {
                "value": 32306,
                "label": "Hautvillers-Ouville",
                "label_ar": "Hautvillers-Ouville",
                "label_fr": "Hautvillers-Ouville"
            },
            {
                "value": 32307,
                "label": "Tatinghem",
                "label_ar": "Tatinghem",
                "label_fr": "Tatinghem"
            },
            {
                "value": 32308,
                "label": "Lompret",
                "label_ar": "لومبريت",
                "label_fr": "Lompret"
            },
            {
                "value": 32309,
                "label": "Nuelles",
                "label_ar": "نويلز",
                "label_fr": "Nuelles"
            },
            {
                "value": 32310,
                "label": "Angicourt",
                "label_ar": "انجيكورت",
                "label_fr": "Angicourt"
            },
            {
                "value": 32311,
                "label": "Brenouille",
                "label_ar": "برينويل",
                "label_fr": "Brenouille"
            },
            {
                "value": 32312,
                "label": "Mornac",
                "label_ar": "مورناك",
                "label_fr": "Mornac"
            },
            {
                "value": 32313,
                "label": "Latour-Bas-Elne",
                "label_ar": "لاتور باس إلين",
                "label_fr": "Latour-Bas-Elne"
            },
            {
                "value": 32314,
                "label": "La Grand-Croix",
                "label_ar": "لا جراند كروا",
                "label_fr": "La Grand-Croix"
            },
            {
                "value": 32315,
                "label": "Saint-Paul-en-Jarez",
                "label_ar": "سان بول إن جاريز",
                "label_fr": "Saint-Paul-en-Jarez"
            },
            {
                "value": 32316,
                "label": "Saint-Martin-la-Plaine",
                "label_ar": "سانت مارتن لا بلين",
                "label_fr": "Saint-Martin-la-Plaine"
            },
            {
                "value": 32317,
                "label": "Bouzincourt",
                "label_ar": "بوزينكور",
                "label_fr": "Bouzincourt"
            },
            {
                "value": 32318,
                "label": "Ovillers-la-Boisselle",
                "label_ar": "Ovillers-la-Boisselle",
                "label_fr": "Ovillers-la-Boisselle"
            },
            {
                "value": 32319,
                "label": "Meaulte",
                "label_ar": "ميولت",
                "label_fr": "Meaulte"
            },
            {
                "value": 32320,
                "label": "Saint-Rambert-l'Ile-Barbe",
                "label_ar": "سان رامبرت ليل باربي",
                "label_fr": "Saint-Rambert-l'Ile-Barbe"
            },
            {
                "value": 32321,
                "label": "Frucourt",
                "label_ar": "فروكورت",
                "label_fr": "Frucourt"
            },
            {
                "value": 32322,
                "label": "Charnay",
                "label_ar": "تشارناي",
                "label_fr": "Charnay"
            },
            {
                "value": 32323,
                "label": "Vicq",
                "label_ar": "فيك",
                "label_fr": "Vicq"
            },
            {
                "value": 32324,
                "label": "Athies",
                "label_ar": "أثيس",
                "label_fr": "Athies"
            },
            {
                "value": 32325,
                "label": "Perouse",
                "label_ar": "بيروس",
                "label_fr": "Pérouse"
            },
            {
                "value": 32326,
                "label": "Danjoutin",
                "label_ar": "دانجوتين",
                "label_fr": "Danjoutin"
            },
            {
                "value": 32327,
                "label": "Rouffiac-Tolosan",
                "label_ar": "روفياك تولوسان",
                "label_fr": "Rouffiac-Tolosan"
            },
            {
                "value": 32328,
                "label": "Saint-Amarin",
                "label_ar": "سان أمارين",
                "label_fr": "Saint-Amarin"
            },
            {
                "value": 32329,
                "label": "Sigloy",
                "label_ar": "سيجلوي",
                "label_fr": "Sigloy"
            },
            {
                "value": 32330,
                "label": "Chaingy",
                "label_ar": "شينجي",
                "label_fr": "Chaingy"
            },
            {
                "value": 32331,
                "label": "Rochemaure",
                "label_ar": "روشيماور",
                "label_fr": "Rochemaure"
            },
            {
                "value": 32332,
                "label": "Grane",
                "label_ar": "غران",
                "label_fr": "Grane"
            },
            {
                "value": 32333,
                "label": "Espagnac",
                "label_ar": "اسبانياك",
                "label_fr": "Espagnac"
            },
            {
                "value": 32334,
                "label": "LIsle-dEspagnac",
                "label_ar": "LIsle-dEspagnac",
                "label_fr": "LIsle-d'Espagnac"
            },
            {
                "value": 32335,
                "label": "Sainte-Fortunade",
                "label_ar": "سانت فورتونادي",
                "label_fr": "Sainte-Fortunade"
            },
            {
                "value": 32336,
                "label": "Civrieux-d'Azergues",
                "label_ar": "Civrieux-d'Azergues",
                "label_fr": "Civrieux-d'Azergues"
            },
            {
                "value": 32337,
                "label": "Lissieu",
                "label_ar": "ليسيو",
                "label_fr": "Lissieu"
            },
            {
                "value": 32338,
                "label": "Izeaux",
                "label_ar": "إيزو",
                "label_fr": "Izeaux"
            },
            {
                "value": 32339,
                "label": "Sillans",
                "label_ar": "سيلانز",
                "label_fr": "Sillans"
            },
            {
                "value": 32340,
                "label": "Carnon-Plage",
                "label_ar": "كارنون بلاج",
                "label_fr": "Carnon-Plage"
            },
            {
                "value": 32341,
                "label": "Penchard",
                "label_ar": "بنشارد",
                "label_fr": "Penchard"
            },
            {
                "value": 32342,
                "label": "Chanteheux",
                "label_ar": "شانتيهوكس",
                "label_fr": "Chanteheux"
            },
            {
                "value": 32343,
                "label": "Neuilly-les-Dijon",
                "label_ar": "نويي ليه ديجون",
                "label_fr": "Neuilly-les-Dijon"
            },
            {
                "value": 32344,
                "label": "Montmorot",
                "label_ar": "مونتموروت",
                "label_fr": "Montmorot"
            },
            {
                "value": 32345,
                "label": "Chamboeuf",
                "label_ar": "شامبووف",
                "label_fr": "Chamboeuf"
            },
            {
                "value": 32346,
                "label": "Fenain",
                "label_ar": "فينين",
                "label_fr": "Fenain"
            },
            {
                "value": 32347,
                "label": "Fein",
                "label_ar": "فين",
                "label_fr": "Fein"
            },
            {
                "value": 32348,
                "label": "Cambronne-les-Ribecourt",
                "label_ar": "كامبرون ليه ريبكورت",
                "label_fr": "Cambronne-les-Ribecourt"
            },
            {
                "value": 32349,
                "label": "Pimprez",
                "label_ar": "بيمبريز",
                "label_fr": "Pimprez"
            },
            {
                "value": 32350,
                "label": "Algolsheim",
                "label_ar": "الجولشيم",
                "label_fr": "Algolsheim"
            },
            {
                "value": 32351,
                "label": "Wolfgantzen",
                "label_ar": "وولفجانتزين",
                "label_fr": "Wolfgantzen"
            },
            {
                "value": 32352,
                "label": "Attenschwiller",
                "label_ar": "أتينشويلر",
                "label_fr": "Attenschwiller"
            },
            {
                "value": 32353,
                "label": "Orry-la-Ville",
                "label_ar": "أوري-لا-فيل",
                "label_fr": "Orry-la-Ville"
            },
            {
                "value": 32354,
                "label": "Cendras",
                "label_ar": "سندراس",
                "label_fr": "Cendras"
            },
            {
                "value": 32355,
                "label": "Vieux",
                "label_ar": "Vieux",
                "label_fr": "Vieux"
            },
            {
                "value": 32356,
                "label": "Fragnes",
                "label_ar": "فراجنز",
                "label_fr": "Fragnes"
            },
            {
                "value": 32357,
                "label": "Geanges",
                "label_ar": "جينجس",
                "label_fr": "Geanges"
            },
            {
                "value": 32358,
                "label": "Verjux",
                "label_ar": "فيرجوكس",
                "label_fr": "Verjux"
            },
            {
                "value": 32359,
                "label": "Pfulgriesheim",
                "label_ar": "بفولجريسهايم",
                "label_fr": "Pfulgriesheim"
            },
            {
                "value": 32360,
                "label": "Millery",
                "label_ar": "ميلري",
                "label_fr": "Millery"
            },
            {
                "value": 32361,
                "label": "Le Pouzin",
                "label_ar": "لو بوزين",
                "label_fr": "Le Pouzin"
            },
            {
                "value": 32362,
                "label": "Nay",
                "label_ar": "كلا",
                "label_fr": "Non"
            },
            {
                "value": 32363,
                "label": "Huttenheim",
                "label_ar": "هوتنهايم",
                "label_fr": "Huttenheim"
            },
            {
                "value": 32364,
                "label": "Jardin",
                "label_ar": "جاردين",
                "label_fr": "Jardin"
            },
            {
                "value": 32365,
                "label": "La Ferte-Saint-Cyr",
                "label_ar": "لا فيرت سان سير",
                "label_fr": "La Ferté-Saint-Cyr"
            },
            {
                "value": 32366,
                "label": "Veyras",
                "label_ar": "فيراس",
                "label_fr": "Veyras"
            },
            {
                "value": 32367,
                "label": "Coux",
                "label_ar": "كوكس",
                "label_fr": "Coux"
            },
            {
                "value": 32368,
                "label": "Chancelade",
                "label_ar": "تشانسيلاد",
                "label_fr": "Chancelade"
            },
            {
                "value": 32369,
                "label": "Le Treport",
                "label_ar": "لو تريبورت",
                "label_fr": "Le Tréport"
            },
            {
                "value": 32370,
                "label": "Widensolen",
                "label_ar": "فيدنسولين",
                "label_fr": "Widensolen"
            },
            {
                "value": 32371,
                "label": "Sundhoffen",
                "label_ar": "سوندهوفن",
                "label_fr": "Sundhoffen"
            },
            {
                "value": 32372,
                "label": "Andolsheim",
                "label_ar": "أندولشيم",
                "label_fr": "Andolsheim"
            },
            {
                "value": 32373,
                "label": "Friville-Escarbotin",
                "label_ar": "فريفيل إسكاربوتين",
                "label_fr": "Friville-Escarbotin"
            },
            {
                "value": 32374,
                "label": "Woincourt",
                "label_ar": "وينكور",
                "label_fr": "Woincourt"
            },
            {
                "value": 32375,
                "label": "Chaleze",
                "label_ar": "شاليز",
                "label_fr": "Chaleze"
            },
            {
                "value": 32376,
                "label": "La Gavotte",
                "label_ar": "لا جافوت",
                "label_fr": "La Gavotte"
            },
            {
                "value": 32377,
                "label": "Cruscades",
                "label_ar": "الحروب الصليبية",
                "label_fr": "Croisades"
            },
            {
                "value": 32378,
                "label": "Ahuy",
                "label_ar": "أهوي",
                "label_fr": "Ahuy"
            },
            {
                "value": 32379,
                "label": "Lancey",
                "label_ar": "لانسي",
                "label_fr": "Lancey"
            },
            {
                "value": 32380,
                "label": "Saint-Romain-de-Colbosc",
                "label_ar": "سان رومان دي كولبوسك",
                "label_fr": "Saint-Romain-de-Colbosc"
            },
            {
                "value": 32381,
                "label": "Loyettes",
                "label_ar": "Loyettes",
                "label_fr": "Loyettes"
            },
            {
                "value": 32382,
                "label": "Bonnelles",
                "label_ar": "بونيل",
                "label_fr": "Bonnelles"
            },
            {
                "value": 32383,
                "label": "Mareil-en-France",
                "label_ar": "مريل أون فرانس",
                "label_fr": "Mareil-en-France"
            },
            {
                "value": 32384,
                "label": "Fontenay-en-Parisis",
                "label_ar": "Fontenay-en-Parisis",
                "label_fr": "Fontenay-en-Parisis"
            },
            {
                "value": 32385,
                "label": "Saint-Witz",
                "label_ar": "سان ويتز",
                "label_fr": "Saint-Witz"
            },
            {
                "value": 32386,
                "label": "Pluvault",
                "label_ar": "بلوفولت",
                "label_fr": "Pluvault"
            },
            {
                "value": 32387,
                "label": "Rilhac-Rancon",
                "label_ar": "ريله رانكون",
                "label_fr": "Rilhac-Rancon"
            },
            {
                "value": 32388,
                "label": "Yversay",
                "label_ar": "يفيرساي",
                "label_fr": "Yversay"
            },
            {
                "value": 32389,
                "label": "Mably",
                "label_ar": "مابيلي",
                "label_fr": "Mably"
            },
            {
                "value": 32390,
                "label": "Bailleul-sur-Therain",
                "label_ar": "بيلول سور ثرين",
                "label_fr": "Bailleul-sur-Therain"
            },
            {
                "value": 32391,
                "label": "Warhem",
                "label_ar": "وارهم",
                "label_fr": "Warhem"
            },
            {
                "value": 32392,
                "label": "Lupstein",
                "label_ar": "لوبستين",
                "label_fr": "Lupstein"
            },
            {
                "value": 32393,
                "label": "Saint-Andre-de-Corcy",
                "label_ar": "سان أندريه دي كورسي",
                "label_fr": "Saint-André-de-Corcy"
            },
            {
                "value": 32394,
                "label": "Detrier",
                "label_ar": "ديترير",
                "label_fr": "Detrier"
            },
            {
                "value": 32395,
                "label": "Arvillard",
                "label_ar": "ارفيلارد",
                "label_fr": "Arvillard"
            },
            {
                "value": 32396,
                "label": "Soucieu-en-Jarrest",
                "label_ar": "Soucieu-en-Jarrest",
                "label_fr": "Soucieu-en-Jarrest"
            },
            {
                "value": 32397,
                "label": "La Ferte-Alais",
                "label_ar": "لا فيرت أليس",
                "label_fr": "La Ferté-Alais"
            },
            {
                "value": 32398,
                "label": "Estouches",
                "label_ar": "إستوشيس",
                "label_fr": "Estouches"
            },
            {
                "value": 32399,
                "label": "Chalou-Moulineux",
                "label_ar": "شالو مولينوكس",
                "label_fr": "Chalou-Moulineux"
            },
            {
                "value": 32400,
                "label": "Trilbardou",
                "label_ar": "تريلباردو",
                "label_fr": "Trilbardou"
            },
            {
                "value": 32401,
                "label": "Precy-sur-Marne",
                "label_ar": "بريسي سور مارن",
                "label_fr": "Précy-sur-Marne"
            },
            {
                "value": 32402,
                "label": "Puisieux",
                "label_ar": "Puisieux",
                "label_fr": "Puisieux"
            },
            {
                "value": 32403,
                "label": "Etrepilly",
                "label_ar": "إتريبلي",
                "label_fr": "Etrepilly"
            },
            {
                "value": 32404,
                "label": "Viry-Noureuil",
                "label_ar": "فيري نوريويل",
                "label_fr": "Viry-Noureuil"
            },
            {
                "value": 32405,
                "label": "Pierremande",
                "label_ar": "بييرماندي",
                "label_fr": "Pierremande"
            },
            {
                "value": 32406,
                "label": "Vendegies-sur-Ecaillon",
                "label_ar": "Vendegies-sur-Ecaillon",
                "label_fr": "Vendegies-sur-Ecaillon"
            },
            {
                "value": 32407,
                "label": "Sireuil",
                "label_ar": "سيرويل",
                "label_fr": "Sireuil"
            },
            {
                "value": 32408,
                "label": "Gensac-la-Pallue",
                "label_ar": "جينساك لا بالو",
                "label_fr": "Gensac-la-Pallue"
            },
            {
                "value": 32409,
                "label": "Fesches-le-Chatel",
                "label_ar": "Fesches-le-Chatel",
                "label_fr": "Fesches-le-Châtel"
            },
            {
                "value": 32410,
                "label": "Villers-Saint-Sepulcre",
                "label_ar": "Villers-Saint-Sepulcre",
                "label_fr": "Villers-Saint-Sépulcre"
            },
            {
                "value": 32411,
                "label": "Guerard",
                "label_ar": "جيرارد",
                "label_fr": "Guérard"
            },
            {
                "value": 32412,
                "label": "Villeneuve-les-Maguelone",
                "label_ar": "فيلنوف ليه ماجويلون",
                "label_fr": "Villeneuve-les-Maguelone"
            },
            {
                "value": 32413,
                "label": "Ury",
                "label_ar": "يوري",
                "label_fr": "Ury"
            },
            {
                "value": 32414,
                "label": "Donnemarie-Dontilly",
                "label_ar": "دونيماري دونتيلي",
                "label_fr": "Donnemarie-Dontilly"
            },
            {
                "value": 32415,
                "label": "Villecerf",
                "label_ar": "فيليسيرف",
                "label_fr": "Villecerf"
            },
            {
                "value": 32416,
                "label": "La Madeleine-sur-Loing",
                "label_ar": "لا مادلين سور لونج",
                "label_fr": "La Madeleine-sur-Loing"
            },
            {
                "value": 32417,
                "label": "Episy",
                "label_ar": "الحلقة",
                "label_fr": "Épisie"
            },
            {
                "value": 32418,
                "label": "Paimboeuf",
                "label_ar": "بايمبوف",
                "label_fr": "Paimboeuf"
            },
            {
                "value": 32419,
                "label": "Saint-Martin-de-Fontenay",
                "label_ar": "سان مارتن دي فونتيني",
                "label_fr": "Saint-Martin-de-Fontenay"
            },
            {
                "value": 32420,
                "label": "Ottrott",
                "label_ar": "أوتروت",
                "label_fr": "Ottrott"
            },
            {
                "value": 32421,
                "label": "Sainte-Marie-aux-Mines",
                "label_ar": "سانت ماري أو ماينز",
                "label_fr": "Sainte-Marie-aux-Mines"
            },
            {
                "value": 32422,
                "label": "Chavagne",
                "label_ar": "شافاني",
                "label_fr": "Chavagne"
            },
            {
                "value": 32423,
                "label": "Chonas-l'Amballan",
                "label_ar": "Chonas-l'Amballan",
                "label_fr": "Chonas-l'Amballan"
            },
            {
                "value": 32424,
                "label": "Caudrot",
                "label_ar": "كودروت",
                "label_fr": "Caudrot"
            },
            {
                "value": 32425,
                "label": "Saint-Prim",
                "label_ar": "سانت بريم",
                "label_fr": "Saint-Prim"
            },
            {
                "value": 32426,
                "label": "Saint-Remy-l'Honore",
                "label_ar": "سان ريمي ل هونور",
                "label_fr": "Saint-Rémy-l'Honore"
            },
            {
                "value": 32427,
                "label": "Tacoignieres",
                "label_ar": "تاكوينيريس",
                "label_fr": "Tacoignières"
            },
            {
                "value": 32428,
                "label": "Nogent-le-Phaye",
                "label_ar": "نوجينت لو فاي",
                "label_fr": "Nogent-le-Phaye"
            },
            {
                "value": 32429,
                "label": "Happonvilliers",
                "label_ar": "هابونفيليرس",
                "label_fr": "Happonvilliers"
            },
            {
                "value": 32430,
                "label": "Neron",
                "label_ar": "نيرون",
                "label_fr": "Néron"
            },
            {
                "value": 32431,
                "label": "Saint-Martin-de-Nigelles",
                "label_ar": "سان مارتن دي نيجل",
                "label_fr": "Saint-Martin-de-Nigelles"
            },
            {
                "value": 32432,
                "label": "Logelbach",
                "label_ar": "لوجلباتش",
                "label_fr": "Logelbach"
            },
            {
                "value": 32433,
                "label": "Arnieres-sur-Iton",
                "label_ar": "Arnieres-sur-Iton",
                "label_fr": "Arnières-sur-Iton"
            },
            {
                "value": 32434,
                "label": "Hordain",
                "label_ar": "حوردين",
                "label_fr": "Hordain"
            },
            {
                "value": 32435,
                "label": "Bouchain",
                "label_ar": "بوشين",
                "label_fr": "Bouchain"
            },
            {
                "value": 32436,
                "label": "Mastaing",
                "label_ar": "الصدارة",
                "label_fr": "Mastaing"
            },
            {
                "value": 32437,
                "label": "Courteranges",
                "label_ar": "كورترانج",
                "label_fr": "Courteranges"
            },
            {
                "value": 32438,
                "label": "Saultain",
                "label_ar": "سولتين",
                "label_fr": "Saultain"
            },
            {
                "value": 32439,
                "label": "Jujurieux",
                "label_ar": "جوجوريوكس",
                "label_fr": "Jujurieux"
            },
            {
                "value": 32440,
                "label": "Mionnay",
                "label_ar": "Mionnay",
                "label_fr": "Mionnay"
            },
            {
                "value": 32441,
                "label": "Leudeville",
                "label_ar": "ليودفيل",
                "label_fr": "Leudeville"
            },
            {
                "value": 32442,
                "label": "Courson-Monteloup",
                "label_ar": "كورسون مونتيلوب",
                "label_fr": "Courson-Monteloup"
            },
            {
                "value": 32443,
                "label": "Chalo-Saint-Mars",
                "label_ar": "تشالو سان مارس",
                "label_fr": "Chalo-Saint-Mars"
            },
            {
                "value": 32444,
                "label": "Coutevroult",
                "label_ar": "كوتيفرولت",
                "label_fr": "Coutevroult"
            },
            {
                "value": 32445,
                "label": "Boussens",
                "label_ar": "بوسنس",
                "label_fr": "Boussens"
            },
            {
                "value": 32446,
                "label": "Villieu-Loyes-Mollon",
                "label_ar": "فيليو لويز مولون",
                "label_fr": "Villieu-Loyes-Mollon"
            },
            {
                "value": 32447,
                "label": "Saint-Eloi",
                "label_ar": "سانت إيلوي",
                "label_fr": "Saint-Eloi"
            },
            {
                "value": 32448,
                "label": "Boulzicourt",
                "label_ar": "بولزيكورت",
                "label_fr": "Boulzicourt"
            },
            {
                "value": 32449,
                "label": "Nogent-l'Artaud",
                "label_ar": "نوجينت لارتود",
                "label_fr": "Nogent-l'Artaud"
            },
            {
                "value": 32450,
                "label": "Grand-Rozoy",
                "label_ar": "جراند روزوي",
                "label_fr": "Grand-Rozoy"
            },
            {
                "value": 32451,
                "label": "Orlu",
                "label_ar": "أورلو",
                "label_fr": "Orlu"
            },
            {
                "value": 32452,
                "label": "Clairoix",
                "label_ar": "كلايروكس",
                "label_fr": "Clairoix"
            },
            {
                "value": 32453,
                "label": "Voulx",
                "label_ar": "فولكس",
                "label_fr": "Voulx"
            },
            {
                "value": 32454,
                "label": "Dormelles",
                "label_ar": "دورميليس",
                "label_fr": "Dormelles"
            },
            {
                "value": 32455,
                "label": "Cadillac-en-Fronsadais",
                "label_ar": "كاديلاك أون فرونسادايس",
                "label_fr": "Cadillac-en-Fronsadais"
            },
            {
                "value": 32456,
                "label": "Saint-Geoirs",
                "label_ar": "سان جويرز",
                "label_fr": "Saint-Geoirs"
            },
            {
                "value": 32457,
                "label": "Limoges-Fourches",
                "label_ar": "ليموج فورشز",
                "label_fr": "Limoges-Fourches"
            },
            {
                "value": 32458,
                "label": "Crisenoy",
                "label_ar": "كريسينوي",
                "label_fr": "Crisenoy"
            },
            {
                "value": 32459,
                "label": "La Bridoire",
                "label_ar": "لا بردوار",
                "label_fr": "La Bridoire"
            },
            {
                "value": 32460,
                "label": "Saint-Beron",
                "label_ar": "سان بيرون",
                "label_fr": "Saint-Béron"
            },
            {
                "value": 32461,
                "label": "Romagnieu",
                "label_ar": "رومانيا",
                "label_fr": "Romagnieu"
            },
            {
                "value": 32462,
                "label": "Heiligenberg",
                "label_ar": "هيليغنبرغ",
                "label_fr": "Heiligenberg"
            },
            {
                "value": 32463,
                "label": "Gresswiller",
                "label_ar": "جريسويلر",
                "label_fr": "Gresswiller"
            },
            {
                "value": 32464,
                "label": "Mettray",
                "label_ar": "ميتراي",
                "label_fr": "Mettray"
            },
            {
                "value": 32465,
                "label": "Boigneville",
                "label_ar": "بوينفيل",
                "label_fr": "Boigneville"
            },
            {
                "value": 32466,
                "label": "Beguey",
                "label_ar": "بيجي",
                "label_fr": "Beguey"
            },
            {
                "value": 32467,
                "label": "Cadillac",
                "label_ar": "كاديلاك",
                "label_fr": "Cadillac"
            },
            {
                "value": 32468,
                "label": "Poigny-la-Foret",
                "label_ar": "بويني لا فوريه",
                "label_fr": "Poigny-la-Foret"
            },
            {
                "value": 32469,
                "label": "Alissas",
                "label_ar": "أليساس",
                "label_fr": "Alissas"
            },
            {
                "value": 32470,
                "label": "Schoeneck",
                "label_ar": "شوينيك",
                "label_fr": "Schoeneck"
            },
            {
                "value": 32471,
                "label": "Pfettisheim",
                "label_ar": "بفيتيشيم",
                "label_fr": "Pfettisheim"
            },
            {
                "value": 32472,
                "label": "Pomeys",
                "label_ar": "بوميس",
                "label_fr": "Pomeys"
            },
            {
                "value": 32473,
                "label": "Coise-Saint-Jean-Pied-Gauthier",
                "label_ar": "Coise-Saint-Jean-Pied-Gauthier",
                "label_fr": "Coise-Saint-Jean-Pied-Gauthier"
            },
            {
                "value": 32474,
                "label": "Saint-Gilles-des-Marais",
                "label_ar": "سان جيل دي ماريه",
                "label_fr": "Saint-Gilles-des-Marais"
            },
            {
                "value": 32475,
                "label": "Tossiat",
                "label_ar": "توسيات",
                "label_fr": "Tossiat"
            },
            {
                "value": 32476,
                "label": "Journans",
                "label_ar": "المجلات",
                "label_fr": "Journans"
            },
            {
                "value": 32477,
                "label": "Chalamont",
                "label_ar": "تشالامونت",
                "label_fr": "Chalamont"
            },
            {
                "value": 32478,
                "label": "Montceaux-les-Meaux",
                "label_ar": "مونتسو لي مو",
                "label_fr": "Montceaux-les-Meaux"
            },
            {
                "value": 32479,
                "label": "Changis-sur-Marne",
                "label_ar": "تشانغيس سور مارن",
                "label_fr": "Changis-sur-Marne"
            },
            {
                "value": 32480,
                "label": "Saint-Jean-les-Deux-Jumeaux",
                "label_ar": "سان جان ليه دوكس جومو",
                "label_fr": "Saint-Jean-les-Deux-Jumeaux"
            },
            {
                "value": 32481,
                "label": "Sailly-Labourse",
                "label_ar": "شراع المختبر",
                "label_fr": "Sailly-Labourse"
            },
            {
                "value": 32482,
                "label": "Saint-Georges-sur-Fontaine",
                "label_ar": "سان جورج سور فونتين",
                "label_fr": "Saint-Georges-sur-Fontaine"
            },
            {
                "value": 32483,
                "label": "Mannevillette",
                "label_ar": "مانيفيليت",
                "label_fr": "Mannevillette"
            },
            {
                "value": 32484,
                "label": "Carneville",
                "label_ar": "كارنفيل",
                "label_fr": "Carneville"
            },
            {
                "value": 32485,
                "label": "Saugon",
                "label_ar": "سوجون",
                "label_fr": "Saugon"
            },
            {
                "value": 32486,
                "label": "Berville-sur-Seine",
                "label_ar": "بيرفيل سور سين",
                "label_fr": "Berville-sur-Seine"
            },
            {
                "value": 32487,
                "label": "Cormes",
                "label_ar": "كورمس",
                "label_fr": "Cormes"
            },
            {
                "value": 32488,
                "label": "Cherreau",
                "label_ar": "Cherreau",
                "label_fr": "Cherreau"
            },
            {
                "value": 32489,
                "label": "Chauffry",
                "label_ar": "شوفري",
                "label_fr": "Chauffry"
            },
            {
                "value": 32490,
                "label": "Villemarechal",
                "label_ar": "فيلماريشال",
                "label_fr": "Villemarechal"
            },
            {
                "value": 32491,
                "label": "Lorrez-le-Bocage-Preaux",
                "label_ar": "لوريز لو بوكاج برو",
                "label_fr": "Lorrez-le-Bocage-Preaux"
            },
            {
                "value": 32492,
                "label": "Bransles",
                "label_ar": "برانسليس",
                "label_fr": "Bransles"
            },
            {
                "value": 32493,
                "label": "Chenou",
                "label_ar": "Chenou",
                "label_fr": "Chenou"
            },
            {
                "value": 32494,
                "label": "Chatenoy",
                "label_ar": "شاتينوي",
                "label_fr": "Chatenoy"
            },
            {
                "value": 32495,
                "label": "Marlenheim",
                "label_ar": "مارلينهايم",
                "label_fr": "Marlenheim"
            },
            {
                "value": 32496,
                "label": "Saint-Symphorien-dOzon",
                "label_ar": "سان سيمفوريان دوزون",
                "label_fr": "Saint-Symphorien-d'Ozon"
            },
            {
                "value": 32497,
                "label": "Mortcerf",
                "label_ar": "مورتسيرف",
                "label_fr": "Mortcerf"
            },
            {
                "value": 32498,
                "label": "Betz",
                "label_ar": "بيتز",
                "label_fr": "Betz"
            },
            {
                "value": 32499,
                "label": "Guines",
                "label_ar": "غينز",
                "label_fr": "Guines"
            },
            {
                "value": 32500,
                "label": "Razac-sur-lIsle",
                "label_ar": "رازاك سور ليل",
                "label_fr": "Razac-sur-lIsle"
            },
            {
                "value": 32501,
                "label": "Fremainville",
                "label_ar": "فريمينفيل",
                "label_fr": "Fremainville"
            },
            {
                "value": 32502,
                "label": "Latresne",
                "label_ar": "لاترسن",
                "label_fr": "Latresne"
            },
            {
                "value": 32503,
                "label": "Tourville-sur-Sienne",
                "label_ar": "تورفيل سور سيين",
                "label_fr": "Tourville-sur-Sienne"
            },
            {
                "value": 32504,
                "label": "Galgon",
                "label_ar": "جالغون",
                "label_fr": "Galgon"
            },
            {
                "value": 32505,
                "label": "Ittenheim",
                "label_ar": "إيتنهايم",
                "label_fr": "Ittenheim"
            },
            {
                "value": 32506,
                "label": "Marmoutier",
                "label_ar": "مارموتييه",
                "label_fr": "Marmoutier"
            },
            {
                "value": 32507,
                "label": "Dimbsthal",
                "label_ar": "ديمبستال",
                "label_fr": "Dimbsthal"
            },
            {
                "value": 32508,
                "label": "Bourogne",
                "label_ar": "بورجوني",
                "label_fr": "Bourogne"
            },
            {
                "value": 32509,
                "label": "Beaucourt",
                "label_ar": "بوكورت",
                "label_fr": "Beaucourt"
            },
            {
                "value": 32510,
                "label": "Cuzieu",
                "label_ar": "كوزيو",
                "label_fr": "Cuzieu"
            },
            {
                "value": 32511,
                "label": "Saint-Andre-le-Puy",
                "label_ar": "سان أندريه لو بوي",
                "label_fr": "Saint-André-le-Puy"
            },
            {
                "value": 32512,
                "label": "Bar-sur-Aube",
                "label_ar": "بار سور أوب",
                "label_fr": "Bar-sur-Aube"
            },
            {
                "value": 32513,
                "label": "Goupillieres",
                "label_ar": "Goupillieres",
                "label_fr": "Goupillières"
            },
            {
                "value": 32514,
                "label": "Villiers-le-Mahieu",
                "label_ar": "فيلير لو ماهيو",
                "label_fr": "Villiers-le-Mahieu"
            },
            {
                "value": 32515,
                "label": "Marcq",
                "label_ar": "ماركك",
                "label_fr": "Marcq"
            },
            {
                "value": 32516,
                "label": "La Feuillie",
                "label_ar": "لا فويلي",
                "label_fr": "La Feuillie"
            },
            {
                "value": 32517,
                "label": "Villers-Semeuse",
                "label_ar": "فيليرز سيموز",
                "label_fr": "Villers-Semeuse"
            },
            {
                "value": 32518,
                "label": "Pencran",
                "label_ar": "بنكران",
                "label_fr": "Pencran"
            },
            {
                "value": 32519,
                "label": "Saint-Vigor",
                "label_ar": "سانت فيجور",
                "label_fr": "Saint-Vigor"
            },
            {
                "value": 32520,
                "label": "Barbizon",
                "label_ar": "باربيزون",
                "label_fr": "Barbizon"
            },
            {
                "value": 32521,
                "label": "Le Pont",
                "label_ar": "لو بونت",
                "label_fr": "Le Pont"
            },
            {
                "value": 32522,
                "label": "Malissard",
                "label_ar": "ماليسارد",
                "label_fr": "Malissard"
            },
            {
                "value": 32523,
                "label": "Esery",
                "label_ar": "Esery",
                "label_fr": "Esery"
            },
            {
                "value": 32524,
                "label": "Roppe",
                "label_ar": "روب",
                "label_fr": "Roppe"
            },
            {
                "value": 32525,
                "label": "Phaffans",
                "label_ar": "الفافان",
                "label_fr": "Phaffans"
            },
            {
                "value": 32526,
                "label": "Champeaux",
                "label_ar": "تشامبو",
                "label_fr": "Champeaux"
            },
            {
                "value": 32527,
                "label": "Devecey",
                "label_ar": "ديفيسي",
                "label_fr": "Devecey"
            },
            {
                "value": 32528,
                "label": "Auxon-Dessous",
                "label_ar": "اوكسون ديسوس",
                "label_fr": "Auxon-Dessous"
            },
            {
                "value": 32529,
                "label": "Oingt",
                "label_ar": "أوينجت",
                "label_fr": "Oingt"
            },
            {
                "value": 32530,
                "label": "Frontenas",
                "label_ar": "الجبهات",
                "label_fr": "Frontenas"
            },
            {
                "value": 32531,
                "label": "Les Olmes",
                "label_ar": "ليه أولمز",
                "label_fr": "Les Olmes"
            },
            {
                "value": 32532,
                "label": "Saint-Thibault-des-Vignes",
                "label_ar": "سان تيبو دي فيني",
                "label_fr": "Saint-Thibault-des-Vignes"
            },
            {
                "value": 32533,
                "label": "Pollestres",
                "label_ar": "بولستر",
                "label_fr": "Pollestres"
            },
            {
                "value": 32534,
                "label": "Druillat",
                "label_ar": "درويلات",
                "label_fr": "Druillat"
            },
            {
                "value": 32535,
                "label": "Andance",
                "label_ar": "أندانس",
                "label_fr": "Andance"
            },
            {
                "value": 32536,
                "label": "Fleurey-sur-Ouche",
                "label_ar": "فلوري سور أوتش",
                "label_fr": "Fleurey-sur-Ouche"
            },
            {
                "value": 32537,
                "label": "Perruel",
                "label_ar": "بيرويل",
                "label_fr": "Perruel"
            },
            {
                "value": 32538,
                "label": "Marssac-sur-Tarn",
                "label_ar": "مارساك سور تارن",
                "label_fr": "Marssac-sur-Tarn"
            },
            {
                "value": 32539,
                "label": "Florentin",
                "label_ar": "فلورنتين",
                "label_fr": "Florentin"
            },
            {
                "value": 32540,
                "label": "Redessan",
                "label_ar": "ريديسان",
                "label_fr": "Redessan"
            },
            {
                "value": 32541,
                "label": "Cande-sur-Beuvron",
                "label_ar": "كاندي سور بيوفرون",
                "label_fr": "Cande-sur-Beuvron"
            },
            {
                "value": 32542,
                "label": "Saint-Sixt",
                "label_ar": "سانت سيكست",
                "label_fr": "Saint-Sixt"
            },
            {
                "value": 32543,
                "label": "Chatenay-sur-Seine",
                "label_ar": "شاتيناي سور سين",
                "label_fr": "Chatenay-sur-Seine"
            },
            {
                "value": 32544,
                "label": "Lachapelle-sous-Chaux",
                "label_ar": "لاشابيل سو شو",
                "label_fr": "Lachapelle-sous-Chaux"
            },
            {
                "value": 32545,
                "label": "Lepuix",
                "label_ar": "Lepuix",
                "label_fr": "Lepuix"
            },
            {
                "value": 32546,
                "label": "Giromagny",
                "label_ar": "جيروماجني",
                "label_fr": "Giromagny"
            },
            {
                "value": 32547,
                "label": "Le Sacq",
                "label_ar": "لو ساك",
                "label_fr": "Le Sacq"
            },
            {
                "value": 32548,
                "label": "Saint-Bauzille-de-Montmel",
                "label_ar": "سان بوزيل دي مونتميل",
                "label_fr": "Saint-Bauzille-de-Montmel"
            },
            {
                "value": 32549,
                "label": "Ouroux",
                "label_ar": "أوروكس",
                "label_fr": "Ouroux"
            },
            {
                "value": 32550,
                "label": "Ouroux-sur-Saone",
                "label_ar": "Ouroux-sur-Saone",
                "label_fr": "Ouroux-sur-Saône"
            },
            {
                "value": 32551,
                "label": "Etrelles",
                "label_ar": "إتريليس",
                "label_fr": "Étrelles"
            },
            {
                "value": 32552,
                "label": "Alby-sur-Cheran",
                "label_ar": "Alby-sur-Cheran",
                "label_fr": "Alby-sur-Cheran"
            },
            {
                "value": 32553,
                "label": "Wentzwiller",
                "label_ar": "وينتزويلر",
                "label_fr": "Wentzwiller"
            },
            {
                "value": 32554,
                "label": "Folgensbourg",
                "label_ar": "فولجنسبورج",
                "label_fr": "Folgensbourg"
            },
            {
                "value": 32555,
                "label": "Nothalten",
                "label_ar": "نوثالتن",
                "label_fr": "Nothalten"
            },
            {
                "value": 32556,
                "label": "Saint-Martin-des-Bois",
                "label_ar": "سان مارتن دي بوا",
                "label_fr": "Saint-Martin-des-Bois"
            },
            {
                "value": 32557,
                "label": "Albias",
                "label_ar": "البياس",
                "label_fr": "Albias"
            },
            {
                "value": 32558,
                "label": "Rosny-sur-Seine",
                "label_ar": "روزني سور سين",
                "label_fr": "Rosny-sur-Seine"
            },
            {
                "value": 32559,
                "label": "Porcheville",
                "label_ar": "بورشيفيل",
                "label_fr": "Porcheville"
            },
            {
                "value": 32560,
                "label": "La Guerche-de-Bretagne",
                "label_ar": "La Guerche-de-Bretagne",
                "label_fr": "La Guerche-de-Bretagne"
            },
            {
                "value": 32561,
                "label": "Killem",
                "label_ar": "اقتله",
                "label_fr": "Tue-les"
            },
            {
                "value": 32562,
                "label": "Saint-Medard-d'Eyrans",
                "label_ar": "سان ميدارد دييران",
                "label_fr": "Saint-Médard-d'Eyrans"
            },
            {
                "value": 32563,
                "label": "Parempuyre",
                "label_ar": "باريمبويير",
                "label_fr": "Parempuyre"
            },
            {
                "value": 32564,
                "label": "Chatonnay",
                "label_ar": "شاتونيه",
                "label_fr": "Chatonnay"
            },
            {
                "value": 32565,
                "label": "Villars-Brandis",
                "label_ar": "فيلارس برانديس",
                "label_fr": "Villars-Brandis"
            },
            {
                "value": 32566,
                "label": "Moutiers",
                "label_ar": "موتيرس",
                "label_fr": "Moutiers"
            },
            {
                "value": 32567,
                "label": "Monneville",
                "label_ar": "مونفيل",
                "label_fr": "Monneville"
            },
            {
                "value": 32568,
                "label": "Neuville-Bosc",
                "label_ar": "نيوفيل بوسك",
                "label_fr": "Neuville-Bosc"
            },
            {
                "value": 32569,
                "label": "Villargondran",
                "label_ar": "فيلارجوندران",
                "label_fr": "Villargondran"
            },
            {
                "value": 32570,
                "label": "Bessens",
                "label_ar": "بيسينز",
                "label_fr": "Bessens"
            },
            {
                "value": 32571,
                "label": "Saint-Hilaire-lez-Cambrai",
                "label_ar": "سان هيلير ليز كامبراي",
                "label_fr": "Saint-Hilaire-lez-Cambrai"
            },
            {
                "value": 32572,
                "label": "Saint-Pardoux-du-Breuil",
                "label_ar": "سان باردو دو برويل",
                "label_fr": "Saint-Pardoux-du-Breuil"
            },
            {
                "value": 32573,
                "label": "Miramont-de-Guyenne",
                "label_ar": "Miramont-de-Guyenne",
                "label_fr": "Miramont-de-Guyenne"
            },
            {
                "value": 32574,
                "label": "Saint-Fiacre-sur-Maine",
                "label_ar": "Saint-Fiacre-sur-Maine",
                "label_fr": "Saint-Fiacre-sur-Maine"
            },
            {
                "value": 32575,
                "label": "Haute-Isle",
                "label_ar": "هوت آيل",
                "label_fr": "Haute-Isle"
            },
            {
                "value": 32576,
                "label": "Ponthevrard",
                "label_ar": "بونتيفرارد",
                "label_fr": "Ponthevrard"
            },
            {
                "value": 32577,
                "label": "Fontenay-le-Vicomte",
                "label_ar": "Fontenay-le-Vicomte",
                "label_fr": "Fontenay-le-Vicomte"
            },
            {
                "value": 32578,
                "label": "Beaurains",
                "label_ar": "بيورين",
                "label_fr": "Beaurains"
            },
            {
                "value": 32579,
                "label": "Vriange",
                "label_ar": "فريانج",
                "label_fr": "Vriange"
            },
            {
                "value": 32580,
                "label": "Feigeres",
                "label_ar": "فيجريس",
                "label_fr": "Feigeres"
            },
            {
                "value": 32581,
                "label": "Le Louroux-Beconnais",
                "label_ar": "لو لورو بيكونيه",
                "label_fr": "Le Louroux-Beconnais"
            },
            {
                "value": 32582,
                "label": "Saint-Sernin-du-Bois",
                "label_ar": "سان سيرنين دو بوا",
                "label_fr": "Saint-Sernin-du-Bois"
            },
            {
                "value": 32583,
                "label": "Le Breuil",
                "label_ar": "لو بريل",
                "label_fr": "Le Breuil"
            },
            {
                "value": 32584,
                "label": "Saint-Morillon",
                "label_ar": "سان موريون",
                "label_fr": "Saint-Morillon"
            },
            {
                "value": 32585,
                "label": "Le Tourne",
                "label_ar": "لو تورني",
                "label_fr": "Le Tourne"
            },
            {
                "value": 32586,
                "label": "Mons-en-Pevele",
                "label_ar": "مونس أون بيفيل",
                "label_fr": "Mons-en-Pevele"
            },
            {
                "value": 32587,
                "label": "Souvigne-sur-Sarthe",
                "label_ar": "سوفين سور سارث",
                "label_fr": "Souvigne-sur-Sarthe"
            },
            {
                "value": 32588,
                "label": "Chatel-de-Neuvre",
                "label_ar": "شاتيل دي نوفر",
                "label_fr": "Châtel-de-Neuvre"
            },
            {
                "value": 32589,
                "label": "Pontcarre",
                "label_ar": "بونتكار",
                "label_fr": "Pontcarre"
            },
            {
                "value": 32590,
                "label": "Saint-Cyr-la-Campagne",
                "label_ar": "سان سير لا كامباني",
                "label_fr": "Saint-Cyr-la-Campagne"
            },
            {
                "value": 32591,
                "label": "Le Thuit-Signol",
                "label_ar": "لو ثويت سيغنول",
                "label_fr": "Le Thuit-Signol"
            },
            {
                "value": 32592,
                "label": "Warlus",
                "label_ar": "وارلس",
                "label_fr": "Warlus"
            },
            {
                "value": 32593,
                "label": "Aubers",
                "label_ar": "أوبرز",
                "label_fr": "Aubers"
            },
            {
                "value": 32594,
                "label": "Fromelles",
                "label_ar": "فروميليس",
                "label_fr": "Fromelles"
            },
            {
                "value": 32595,
                "label": "Lescherolles",
                "label_ar": "ليسكيرول",
                "label_fr": "Lescherolles"
            },
            {
                "value": 32596,
                "label": "Villiers-Saint-Georges",
                "label_ar": "فيلير سان جورج",
                "label_fr": "Villiers-Saint-Georges"
            },
            {
                "value": 32597,
                "label": "Trainel",
                "label_ar": "ترينل",
                "label_fr": "Trainel"
            },
            {
                "value": 32598,
                "label": "Dhuisy",
                "label_ar": "دويسي",
                "label_fr": "Dhuisy"
            },
            {
                "value": 32599,
                "label": "Tancrou",
                "label_ar": "تانكرو",
                "label_fr": "Tancrou"
            },
            {
                "value": 32600,
                "label": "Jouy-le-Chatel",
                "label_ar": "جوي لو شاتيل",
                "label_fr": "Jouy-le-Châtel"
            },
            {
                "value": 32601,
                "label": "Everly",
                "label_ar": "إيفرلي",
                "label_fr": "Everly"
            },
            {
                "value": 32602,
                "label": "Pamfou",
                "label_ar": "بامفو",
                "label_fr": "Pamfou"
            },
            {
                "value": 32603,
                "label": "Arinthod",
                "label_ar": "ارينثود",
                "label_fr": "Arinthod"
            },
            {
                "value": 32604,
                "label": "Belgentier",
                "label_ar": "بلجنتير",
                "label_fr": "Belgentier"
            },
            {
                "value": 32605,
                "label": "Le Champ-pres-Froges",
                "label_ar": "Le Champ-pres-Froges",
                "label_fr": "Le Champ-pres-Froges"
            },
            {
                "value": 32606,
                "label": "Gironcourt-sur-Vraine",
                "label_ar": "جيرونكورت سور فارين",
                "label_fr": "Gironcourt-sur-Vraine"
            },
            {
                "value": 32607,
                "label": "La Terrasse",
                "label_ar": "لا تيراس",
                "label_fr": "La Terrasse"
            },
            {
                "value": 32608,
                "label": "Lumbin",
                "label_ar": "لومبين",
                "label_fr": "Lumbin"
            },
            {
                "value": 32609,
                "label": "Etables",
                "label_ar": "إيتابلز",
                "label_fr": "Etables"
            },
            {
                "value": 32610,
                "label": "Barisey-au-Plain",
                "label_ar": "باريزي-أو-بلين",
                "label_fr": "Barisey-au-Plain"
            },
            {
                "value": 32611,
                "label": "Cepet",
                "label_ar": "سيبيت",
                "label_fr": "Cepet"
            },
            {
                "value": 32612,
                "label": "Jalogny",
                "label_ar": "جالوني",
                "label_fr": "Jalogny"
            },
            {
                "value": 32613,
                "label": "Porcieu-Amblagnieu",
                "label_ar": "بورسيو أمبلانيو",
                "label_fr": "Porcieu-Amblagnieu"
            },
            {
                "value": 32614,
                "label": "Briennon",
                "label_ar": "برينون",
                "label_fr": "Briennon"
            },
            {
                "value": 32615,
                "label": "Eveux",
                "label_ar": "إيفوكس",
                "label_fr": "Eveux"
            },
            {
                "value": 32616,
                "label": "Guegon",
                "label_ar": "Guegon",
                "label_fr": "Guegon"
            },
            {
                "value": 32617,
                "label": "Gourhel",
                "label_ar": "جورهيل",
                "label_fr": "Gourhel"
            },
            {
                "value": 32618,
                "label": "Taupont",
                "label_ar": "تاوبونت",
                "label_fr": "Taupont"
            },
            {
                "value": 32619,
                "label": "Chasselay",
                "label_ar": "تشاسيلاي",
                "label_fr": "Chasselay"
            },
            {
                "value": 32620,
                "label": "Chevannes",
                "label_ar": "شيفانيس",
                "label_fr": "Chevannes"
            },
            {
                "value": 32621,
                "label": "Villaries",
                "label_ar": "القرى",
                "label_fr": "Villaries"
            },
            {
                "value": 32622,
                "label": "Vacquiers",
                "label_ar": "فراغات",
                "label_fr": "Vacquiers"
            },
            {
                "value": 32623,
                "label": "Boissise-la-Bertrand",
                "label_ar": "Boissise-la-Bertrand",
                "label_fr": "Boissise-la-Bertrand"
            },
            {
                "value": 32624,
                "label": "Boulancourt",
                "label_ar": "بولانكور",
                "label_fr": "Boulancourt"
            },
            {
                "value": 32625,
                "label": "Villetelle",
                "label_ar": "فيليتيل",
                "label_fr": "Villetelle"
            },
            {
                "value": 32626,
                "label": "Verargues",
                "label_ar": "Verargues",
                "label_fr": "Verargues"
            },
            {
                "value": 32627,
                "label": "Serres-Morlaas",
                "label_ar": "سيريس مورلاس",
                "label_fr": "Serres-Morlaas"
            },
            {
                "value": 32628,
                "label": "Villotte-sur-Aire",
                "label_ar": "فيلوت سور اير",
                "label_fr": "Villotte-sur-Aire"
            },
            {
                "value": 32629,
                "label": "Chanonat",
                "label_ar": "شانونات",
                "label_fr": "Chanonat"
            },
            {
                "value": 32630,
                "label": "La Roche-Blanche",
                "label_ar": "لاروش بلانش",
                "label_fr": "La Roche-Blanche"
            },
            {
                "value": 32631,
                "label": "Saint-Gelais",
                "label_ar": "سان جيليه",
                "label_fr": "Saint-Gelais"
            },
            {
                "value": 32632,
                "label": "Themericourt",
                "label_ar": "ثيمريكورت",
                "label_fr": "Themericourt"
            },
            {
                "value": 32633,
                "label": "Commeny",
                "label_ar": "Commeny",
                "label_fr": "Commeny"
            },
            {
                "value": 32634,
                "label": "Oelleville",
                "label_ar": "أوليفيل",
                "label_fr": "Oelleville"
            },
            {
                "value": 32635,
                "label": "Bressolles",
                "label_ar": "بريسوليس",
                "label_fr": "Bressolles"
            },
            {
                "value": 32636,
                "label": "Nievroz",
                "label_ar": "نيفروز",
                "label_fr": "Nievroz"
            },
            {
                "value": 32637,
                "label": "Ballan-Mire",
                "label_ar": "بلان ماير",
                "label_fr": "Ballan-Mire"
            },
            {
                "value": 32638,
                "label": "Lempdes",
                "label_ar": "ليمبديس",
                "label_fr": "Lempdes"
            },
            {
                "value": 32639,
                "label": "Longchamp-sous-Chatenois",
                "label_ar": "لونجشامب سو شاتينوا",
                "label_fr": "Longchamp-sous-Chatenois"
            },
            {
                "value": 32640,
                "label": "Rouvres-la-Chetive",
                "label_ar": "روفريس لا شيتيف",
                "label_fr": "Rouvres-la-Chétive"
            },
            {
                "value": 32641,
                "label": "Thoissey",
                "label_ar": "تويسي",
                "label_fr": "Thoissey"
            },
            {
                "value": 32642,
                "label": "Bligny-les-Beaune",
                "label_ar": "Bligny-les-Beaune",
                "label_fr": "Bligny-les-Beaune"
            },
            {
                "value": 32643,
                "label": "Chassey-le-Camp",
                "label_ar": "تشاسي لو كامب",
                "label_fr": "Chassey-le-Camp"
            },
            {
                "value": 32644,
                "label": "Puligny-Montrachet",
                "label_ar": "بوليني مونتراشيه",
                "label_fr": "Puligny-Montrachet"
            },
            {
                "value": 32645,
                "label": "Saint-Martin-du-Bois",
                "label_ar": "سان مارتن دو بوا",
                "label_fr": "Saint-Martin-du-Bois"
            },
            {
                "value": 32646,
                "label": "Sailly-lez-Cambrai",
                "label_ar": "سايلي ليز كامبراي",
                "label_fr": "Sailly-lez-Cambrai"
            },
            {
                "value": 32647,
                "label": "Roumazieres",
                "label_ar": "رومازيريس",
                "label_fr": "Roumazières"
            },
            {
                "value": 32648,
                "label": "Saint-Rogatien",
                "label_ar": "سان روجاتيان",
                "label_fr": "Saint-Rogatien"
            },
            {
                "value": 32649,
                "label": "Granges-sur-Vologne",
                "label_ar": "غرانج سور فولونيا",
                "label_fr": "Granges-sur-Vologne"
            },
            {
                "value": 32650,
                "label": "Aumontzey",
                "label_ar": "أومونتزي",
                "label_fr": "Aumontzey"
            },
            {
                "value": 32651,
                "label": "Foussemagne",
                "label_ar": "فوسماني",
                "label_fr": "Foussemagne"
            },
            {
                "value": 32652,
                "label": "Novillard",
                "label_ar": "نوفيلارد",
                "label_fr": "Novillard"
            },
            {
                "value": 32653,
                "label": "Dierre",
                "label_ar": "ديير",
                "label_fr": "Dierre"
            },
            {
                "value": 32654,
                "label": "Saint-Germain-des-Pres",
                "label_ar": "سان جيرمان دي بري",
                "label_fr": "Saint-Germain-des-Prés"
            },
            {
                "value": 32655,
                "label": "Chailly-en-Gatinais",
                "label_ar": "Chailly-en-Gatinais",
                "label_fr": "Chailly-en-Gatinais"
            },
            {
                "value": 32656,
                "label": "Saint-Benoit-sur-Loire",
                "label_ar": "سان بينوا سور لوار",
                "label_fr": "Saint-Benoit-sur-Loire"
            },
            {
                "value": 32657,
                "label": "Ladon",
                "label_ar": "لادون",
                "label_fr": "Ladon"
            },
            {
                "value": 32658,
                "label": "Garlin",
                "label_ar": "جارلين",
                "label_fr": "Garlin"
            },
            {
                "value": 32659,
                "label": "Dry",
                "label_ar": "جاف",
                "label_fr": "Sec"
            },
            {
                "value": 32660,
                "label": "Boynes",
                "label_ar": "بوينز",
                "label_fr": "Boynes"
            },
            {
                "value": 32661,
                "label": "Trigueres",
                "label_ar": "Trigueres",
                "label_fr": "Trigueres"
            },
            {
                "value": 32662,
                "label": "Ascheres-le-Marche",
                "label_ar": "Ascheres-le-Marche",
                "label_fr": "Ascheres-le-Marche"
            },
            {
                "value": 32663,
                "label": "Coudroy",
                "label_ar": "كودروي",
                "label_fr": "Coudroy"
            },
            {
                "value": 32664,
                "label": "Estouy",
                "label_ar": "إستوي",
                "label_fr": "Estouy"
            },
            {
                "value": 32665,
                "label": "Briarres-sur-Essonne",
                "label_ar": "برياري سور إيسون",
                "label_fr": "Briarres-sur-Essonne"
            },
            {
                "value": 32666,
                "label": "Batilly-en-Gatinais",
                "label_ar": "باتيلي أون جاتينيس",
                "label_fr": "Batilly-en-Gatinais"
            },
            {
                "value": 32667,
                "label": "Griselles",
                "label_ar": "جريسيليس",
                "label_fr": "Griselles"
            },
            {
                "value": 32668,
                "label": "Nogent-sur-Vernisson",
                "label_ar": "نوجينت سور فيرنسون",
                "label_fr": "Nogent-sur-Vernisson"
            },
            {
                "value": 32669,
                "label": "Sermaises",
                "label_ar": "الخطب",
                "label_fr": "Sermaises"
            },
            {
                "value": 32670,
                "label": "Douchy",
                "label_ar": "دوشي",
                "label_fr": "Douchy"
            },
            {
                "value": 32671,
                "label": "Ardon",
                "label_ar": "أردون",
                "label_fr": "Ardon"
            },
            {
                "value": 32672,
                "label": "Ligny-le-Ribault",
                "label_ar": "Ligny-le-Ribault",
                "label_fr": "Ligny-le-Ribault"
            },
            {
                "value": 32673,
                "label": "Auvilliers-en-Gatinais",
                "label_ar": "أوفيلييه أون جاتينيس",
                "label_fr": "Auvilliers-en-Gatinais"
            },
            {
                "value": 32674,
                "label": "Ondreville-sur-Essonne",
                "label_ar": "Ondreville-sur-Essonne",
                "label_fr": "Ondreville-sur-Essonne"
            },
            {
                "value": 32675,
                "label": "Charsonville",
                "label_ar": "تشارسونفيل",
                "label_fr": "Charsonville"
            },
            {
                "value": 32676,
                "label": "Sennely",
                "label_ar": "سينيلي",
                "label_fr": "Sennely"
            },
            {
                "value": 32677,
                "label": "Cerdon",
                "label_ar": "سيردون",
                "label_fr": "Cerdon"
            },
            {
                "value": 32678,
                "label": "Mareau-aux-Pres",
                "label_ar": "مارو أوكس بري",
                "label_fr": "Mareau-aux-Prés"
            },
            {
                "value": 32679,
                "label": "Thou",
                "label_ar": "انت",
                "label_fr": "Tu"
            },
            {
                "value": 32680,
                "label": "Girolles",
                "label_ar": "جيرول",
                "label_fr": "Girolles"
            },
            {
                "value": 32681,
                "label": "Erceville",
                "label_ar": "إريسفيل",
                "label_fr": "Erceville"
            },
            {
                "value": 32682,
                "label": "Guigneville",
                "label_ar": "غوينفيل",
                "label_fr": "Guigneville"
            },
            {
                "value": 32683,
                "label": "Tivernon",
                "label_ar": "تيفيرنون",
                "label_fr": "Tivernon"
            },
            {
                "value": 32684,
                "label": "Boulay-les-Barres",
                "label_ar": "بولاي ليه باريه",
                "label_fr": "Boulay-les-Barres"
            },
            {
                "value": 32685,
                "label": "Tournoisis",
                "label_ar": "أزمة",
                "label_fr": "Tournoisis"
            },
            {
                "value": 32686,
                "label": "Rozieres-en-Beauce",
                "label_ar": "Rozieres-en-Beauce",
                "label_fr": "Rozières-en-Beauce"
            },
            {
                "value": 32687,
                "label": "Sougy",
                "label_ar": "سوجي",
                "label_fr": "Sougy"
            },
            {
                "value": 32688,
                "label": "Yevre-la-Ville",
                "label_ar": "يفر-لا-فيل",
                "label_fr": "Yèvre-la-Ville"
            },
            {
                "value": 32689,
                "label": "Saint-Maurice-sur-Aveyron",
                "label_ar": "سان موريس سور أفيرون",
                "label_fr": "Saint-Maurice-sur-Aveyron"
            },
            {
                "value": 32690,
                "label": "Artenay",
                "label_ar": "أرتيناي",
                "label_fr": "Artenay"
            },
            {
                "value": 32691,
                "label": "Chatillon-le-Roi",
                "label_ar": "شاتيلون لو روي",
                "label_fr": "Châtillon-le-Roi"
            },
            {
                "value": 32692,
                "label": "Bazoches-sur-le-Betz",
                "label_ar": "Bazoches-sur-le-Betz",
                "label_fr": "Bazoches-sur-le-Betz"
            },
            {
                "value": 32693,
                "label": "Andonville",
                "label_ar": "أندونفيل",
                "label_fr": "Andonville"
            },
            {
                "value": 32694,
                "label": "Saint-Brisson-sur-Loire",
                "label_ar": "سان بريسون سور لوار",
                "label_fr": "Saint-Brisson-sur-Loire"
            },
            {
                "value": 32695,
                "label": "Bonnee",
                "label_ar": "بوني",
                "label_fr": "Bonnee"
            },
            {
                "value": 32696,
                "label": "Dampierre-en-Burly",
                "label_ar": "Dampierre-en-Burly",
                "label_fr": "Dampierre-en-Burly"
            },
            {
                "value": 32697,
                "label": "Arrabloy",
                "label_ar": "اربلوي",
                "label_fr": "Arrabloy"
            },
            {
                "value": 32698,
                "label": "Ascoux",
                "label_ar": "أسكوكس",
                "label_fr": "Ascoux"
            },
            {
                "value": 32699,
                "label": "Bazoches-les-Gallerandes",
                "label_ar": "Bazoches-les-Gallerandes",
                "label_fr": "Bazoches-les-Gallerandes"
            },
            {
                "value": 32700,
                "label": "Louzouer",
                "label_ar": "لوزور",
                "label_fr": "Louzouer"
            },
            {
                "value": 32701,
                "label": "Villemurlin",
                "label_ar": "فيلمورلين",
                "label_fr": "Villemurlin"
            },
            {
                "value": 32702,
                "label": "Beaulieu-sur-Loire",
                "label_ar": "بوليو سور لوار",
                "label_fr": "Beaulieu-sur-Loire"
            },
            {
                "value": 32703,
                "label": "Gidy",
                "label_ar": "جيدي",
                "label_fr": "Gidy"
            },
            {
                "value": 32704,
                "label": "Bouafles",
                "label_ar": "Bouafles",
                "label_fr": "Bouafles"
            },
            {
                "value": 32705,
                "label": "Ettendorf",
                "label_ar": "إتندورف",
                "label_fr": "Ettendorf"
            },
            {
                "value": 32706,
                "label": "Puyreaux",
                "label_ar": "بويرو",
                "label_fr": "Puyreaux"
            },
            {
                "value": 32707,
                "label": "Les Loges-en-Josas",
                "label_ar": "لي لوجيس إن جوساس",
                "label_fr": "Les Loges-en-Josas"
            },
            {
                "value": 32708,
                "label": "Etzling",
                "label_ar": "إتسلنغ",
                "label_fr": "Etzling"
            },
            {
                "value": 32709,
                "label": "Imling",
                "label_ar": "Imling",
                "label_fr": "Imling"
            },
            {
                "value": 32710,
                "label": "Saint-Etienne-la-Varenne",
                "label_ar": "سانت إتيان لا فارين",
                "label_fr": "Saint-Étienne-la-Varenne"
            },
            {
                "value": 32711,
                "label": "Etaimpuis",
                "label_ar": "إتيمبويس",
                "label_fr": "Etaimpuis"
            },
            {
                "value": 32712,
                "label": "Saint-Donan",
                "label_ar": "سان دونان",
                "label_fr": "Saint-Donan"
            },
            {
                "value": 32713,
                "label": "Le Mesnil-le-Roi",
                "label_ar": "لو ميسنيل لو روي",
                "label_fr": "Le Mesnil-le-Roi"
            },
            {
                "value": 32714,
                "label": "Etauliers",
                "label_ar": "إتوليرس",
                "label_fr": "Etauliers"
            },
            {
                "value": 32715,
                "label": "Merigny",
                "label_ar": "ميريني",
                "label_fr": "Mérigny"
            },
            {
                "value": 32716,
                "label": "Roquebilliere",
                "label_ar": "روكويبيليير",
                "label_fr": "Roquebillière"
            },
            {
                "value": 32717,
                "label": "Villabon",
                "label_ar": "فيلابون",
                "label_fr": "Villabon"
            },
            {
                "value": 32718,
                "label": "Ferin",
                "label_ar": "فيرين",
                "label_fr": "Ferin"
            },
            {
                "value": 32719,
                "label": "Lussat",
                "label_ar": "لوسات",
                "label_fr": "Lussat"
            },
            {
                "value": 32720,
                "label": "La Greve-sur-Mignon",
                "label_ar": "لا جريف سور مينيون",
                "label_fr": "La Grève-sur-Mignon"
            },
            {
                "value": 32721,
                "label": "Conches-sur-Gondoire",
                "label_ar": "محار سور جوندوار",
                "label_fr": "Conches-sur-Gondoire"
            },
            {
                "value": 32722,
                "label": "Queyrac",
                "label_ar": "كويراك",
                "label_fr": "Queyrac"
            },
            {
                "value": 32723,
                "label": "Marsainvilliers",
                "label_ar": "Marsainvilliers",
                "label_fr": "Marsainvilliers"
            },
            {
                "value": 32724,
                "label": "Camlez",
                "label_ar": "كامليز",
                "label_fr": "Camlez"
            },
            {
                "value": 32725,
                "label": "Chitenay",
                "label_ar": "شيتناي",
                "label_fr": "Chitenay"
            },
            {
                "value": 32726,
                "label": "Courcerac",
                "label_ar": "كرسراك",
                "label_fr": "Courcerac"
            },
            {
                "value": 32727,
                "label": "Esclainvillers",
                "label_ar": "Esclainvillers",
                "label_fr": "Esclainvillers"
            },
            {
                "value": 32728,
                "label": "Sainte-Anne-sur-Vilaine",
                "label_ar": "سانت آن سور فيلان",
                "label_fr": "Sainte-Anne-sur-Vilaine"
            },
            {
                "value": 32729,
                "label": "Tregourez",
                "label_ar": "تريغوريز",
                "label_fr": "Trégourez"
            },
            {
                "value": 32730,
                "label": "Sainte-Marguerite-des-Loges",
                "label_ar": "Sainte-Marguerite-des-Loges",
                "label_fr": "Sainte-Marguerite-des-Loges"
            },
            {
                "value": 32731,
                "label": "Mornay-sur-Allier",
                "label_ar": "مورناي سور أليير",
                "label_fr": "Mornay-sur-Allier"
            },
            {
                "value": 32732,
                "label": "Auzouer-en-Touraine",
                "label_ar": "أزوير التوراين",
                "label_fr": "Auzouer-en-Touraine"
            },
            {
                "value": 32733,
                "label": "La Guierche",
                "label_ar": "لا غويرش",
                "label_fr": "La Guierche"
            },
            {
                "value": 32734,
                "label": "Lassigny",
                "label_ar": "فؤاد",
                "label_fr": "Lassigny"
            },
            {
                "value": 32735,
                "label": "Hesingue",
                "label_ar": "هسينج",
                "label_fr": "Hesingue"
            },
            {
                "value": 32736,
                "label": "Baulne",
                "label_ar": "بولن",
                "label_fr": "Baulne"
            },
            {
                "value": 32737,
                "label": "Gometz-la-Ville",
                "label_ar": "جوميتز لا فيل",
                "label_fr": "Gometz-la-Ville"
            },
            {
                "value": 32738,
                "label": "Saint-Aunes",
                "label_ar": "سانت أونز",
                "label_fr": "Saint-Aunes"
            },
            {
                "value": 32739,
                "label": "Lavardin",
                "label_ar": "لافاردين",
                "label_fr": "Lavardin"
            },
            {
                "value": 32740,
                "label": "Uberach",
                "label_ar": "أوبراخ",
                "label_fr": "Uberach"
            },
            {
                "value": 32741,
                "label": "Juigne-sur-Loire",
                "label_ar": "Juigne-sur-Loire",
                "label_fr": "Juigne-sur-Loire"
            },
            {
                "value": 32742,
                "label": "Frauenberg",
                "label_ar": "فراونبيرج",
                "label_fr": "Frauenberg"
            },
            {
                "value": 32743,
                "label": "Moult",
                "label_ar": "تساقط",
                "label_fr": "Mue"
            },
            {
                "value": 32744,
                "label": "Aveize",
                "label_ar": "افيزي",
                "label_fr": "Aveize"
            },
            {
                "value": 32745,
                "label": "Moulins-Engilbert",
                "label_ar": "مولين إنجيلبرت",
                "label_fr": "Moulins-Engilbert"
            },
            {
                "value": 32746,
                "label": "Ossun",
                "label_ar": "أوسون",
                "label_fr": "Ossun"
            },
            {
                "value": 32747,
                "label": "Loire-les-Marais",
                "label_ar": "لوار لي ماريه",
                "label_fr": "Loire-les-Marais"
            },
            {
                "value": 32748,
                "label": "Ferrieres",
                "label_ar": "العبارات",
                "label_fr": "Ferrières"
            },
            {
                "value": 32749,
                "label": "Saint-Jean-le-Centenier",
                "label_ar": "سان جان لو سينتينير",
                "label_fr": "Saint-Jean-le-Centenier"
            },
            {
                "value": 32750,
                "label": "Saint-Uze",
                "label_ar": "سان اوزي",
                "label_fr": "Saint-Uze"
            },
            {
                "value": 32751,
                "label": "Montvendre",
                "label_ar": "مونتفيندر",
                "label_fr": "Montvendre"
            },
            {
                "value": 32752,
                "label": "Colombier-le-Vieux",
                "label_ar": "كولومبييه لو فيو",
                "label_fr": "Colombier-le-Vieux"
            },
            {
                "value": 32753,
                "label": "Saint-Christophe-du-Bois",
                "label_ar": "سان كريستوف دو بوا",
                "label_fr": "Saint-Christophe-du-Bois"
            },
            {
                "value": 32754,
                "label": "Thaire",
                "label_ar": "تاير",
                "label_fr": "Thaire"
            },
            {
                "value": 32755,
                "label": "Sceaux-sur-Huisne",
                "label_ar": "Sceaux-sur-Huisne",
                "label_fr": "Sceaux-sur-Huisne"
            },
            {
                "value": 32756,
                "label": "Souligne-sous-Ballon",
                "label_ar": "سولين سو بالون",
                "label_fr": "Souligne-sous-Ballon"
            },
            {
                "value": 32757,
                "label": "Rochegude",
                "label_ar": "روشيجود",
                "label_fr": "Rochegude"
            },
            {
                "value": 32758,
                "label": "Villevocance",
                "label_ar": "فيليفوسانس",
                "label_fr": "Villevocance"
            },
            {
                "value": 32759,
                "label": "Ancone",
                "label_ar": "أنكون",
                "label_fr": "Ancône"
            },
            {
                "value": 32760,
                "label": "Saint-Alban-d'Ay",
                "label_ar": "سان ألبان داي",
                "label_fr": "Saint-Alban-d'Ay"
            },
            {
                "value": 32761,
                "label": "Bazouges-sur-le-Loir",
                "label_ar": "بازوج سور لو لوير",
                "label_fr": "Bazouges-sur-le-Loir"
            },
            {
                "value": 32762,
                "label": "Flee",
                "label_ar": "اهرب",
                "label_fr": "Fuir"
            },
            {
                "value": 32763,
                "label": "Saint-Augustin",
                "label_ar": "سانت أوغسطين",
                "label_fr": "Saint-Augustin"
            },
            {
                "value": 32764,
                "label": "Roiffieux",
                "label_ar": "روفيو",
                "label_fr": "Roiffieux"
            },
            {
                "value": 32765,
                "label": "Epinouze",
                "label_ar": "إبينوز",
                "label_fr": "Epinouze"
            },
            {
                "value": 32766,
                "label": "Vallans",
                "label_ar": "فالانس",
                "label_fr": "Vallans"
            },
            {
                "value": 32767,
                "label": "Chambon",
                "label_ar": "شامبون",
                "label_fr": "Chambon"
            },
            {
                "value": 32768,
                "label": "Saint-Victurnien",
                "label_ar": "سانت فيكتورنين",
                "label_fr": "Saint-Victurnien"
            },
            {
                "value": 32769,
                "label": "Fresnay-sur-Sarthe",
                "label_ar": "فريسناي سور سارث",
                "label_fr": "Fresnay-sur-Sarthe"
            },
            {
                "value": 32770,
                "label": "Donzy-le-Pertuis",
                "label_ar": "دونزي لو بيرتويز",
                "label_fr": "Donzy-le-Pertuis"
            },
            {
                "value": 32771,
                "label": "Peaugres",
                "label_ar": "بيوجريس",
                "label_fr": "Peaugres"
            },
            {
                "value": 32772,
                "label": "Sault",
                "label_ar": "سولت",
                "label_fr": "Sault"
            },
            {
                "value": 32773,
                "label": "Vitrimont",
                "label_ar": "فيتريمونت",
                "label_fr": "Vitrimont"
            },
            {
                "value": 32774,
                "label": "Bully",
                "label_ar": "بلطجي",
                "label_fr": "Brute"
            },
            {
                "value": 32775,
                "label": "Morance",
                "label_ar": "مورانس",
                "label_fr": "Morance"
            },
            {
                "value": 32776,
                "label": "Liergues",
                "label_ar": "ليرج",
                "label_fr": "Liergues"
            },
            {
                "value": 32777,
                "label": "Marnes-la-Coquette",
                "label_ar": "مارنز لا كوكيت",
                "label_fr": "Marnes-la-Coquette"
            },
            {
                "value": 32778,
                "label": "Jussy",
                "label_ar": "جوسي",
                "label_fr": "Jussy"
            },
            {
                "value": 32779,
                "label": "Alteckendorf",
                "label_ar": "ألتكندورف",
                "label_fr": "Alteckendorf"
            },
            {
                "value": 32780,
                "label": "Saint-Andre-Farivillers",
                "label_ar": "سانت أندريه فاريفيلرز",
                "label_fr": "Saint-André-Farivillers"
            },
            {
                "value": 32781,
                "label": "Bonlier",
                "label_ar": "بونير",
                "label_fr": "Bonlier"
            },
            {
                "value": 32782,
                "label": "Ivry-le-Temple",
                "label_ar": "ايفري لو تمبل",
                "label_fr": "Ivry-le-Temple"
            },
            {
                "value": 32783,
                "label": "Allonne",
                "label_ar": "ألون",
                "label_fr": "Allonne"
            },
            {
                "value": 32784,
                "label": "Silly-le-Long",
                "label_ar": "سخيف لو لونج",
                "label_fr": "Silly-le-Long"
            },
            {
                "value": 32785,
                "label": "Ver-sur-Launette",
                "label_ar": "فير سور لاونيس",
                "label_fr": "Ver-sur-Launette"
            },
            {
                "value": 32786,
                "label": "Antilly",
                "label_ar": "قبل ذلك",
                "label_fr": "Antilly"
            },
            {
                "value": 32787,
                "label": "Ressons-sur-Matz",
                "label_ar": "ريسونس سور ماتز",
                "label_fr": "Ressons-sur-Matz"
            },
            {
                "value": 32788,
                "label": "Plessis-de-Roye",
                "label_ar": "بليسيس دي روي",
                "label_fr": "Plessis-de-Roye"
            },
            {
                "value": 32789,
                "label": "Longueil-Sainte-Marie",
                "label_ar": "لونجويل سانت ماري",
                "label_fr": "Longueil-Sainte-Marie"
            },
            {
                "value": 32790,
                "label": "Angy",
                "label_ar": "انجي",
                "label_fr": "Angy"
            },
            {
                "value": 32791,
                "label": "Bailly",
                "label_ar": "بايلي",
                "label_fr": "Bailly"
            },
            {
                "value": 32792,
                "label": "Monceaux",
                "label_ar": "مونسو",
                "label_fr": "Monceaux"
            },
            {
                "value": 32793,
                "label": "Gremevillers",
                "label_ar": "جريميفيلرس",
                "label_fr": "Gremevillers"
            },
            {
                "value": 32794,
                "label": "Rousseloy",
                "label_ar": "روسيلوي",
                "label_fr": "Rousseloy"
            },
            {
                "value": 32795,
                "label": "Moliens",
                "label_ar": "مولينز",
                "label_fr": "Moliens"
            },
            {
                "value": 32796,
                "label": "Formerie",
                "label_ar": "فورميري",
                "label_fr": "Formerie"
            },
            {
                "value": 32797,
                "label": "Fresnoy-la-Riviere",
                "label_ar": "فريسني لا ريفيير",
                "label_fr": "Fresnoy-la-Rivière"
            },
            {
                "value": 32798,
                "label": "Rouville",
                "label_ar": "روفيل",
                "label_fr": "Rouville"
            },
            {
                "value": 32799,
                "label": "Tartigny",
                "label_ar": "تارتيني",
                "label_fr": "Tartigny"
            },
            {
                "value": 32800,
                "label": "Beauvoir",
                "label_ar": "بوفوار",
                "label_fr": "Beauvoir"
            },
            {
                "value": 32801,
                "label": "Valescourt",
                "label_ar": "فاليسكورت",
                "label_fr": "Valescourt"
            },
            {
                "value": 32802,
                "label": "Cuigy-en-Bray",
                "label_ar": "كويجي أون براي",
                "label_fr": "Cuigy-en-Bray"
            },
            {
                "value": 32803,
                "label": "Rainvillers",
                "label_ar": "Rainvillers",
                "label_fr": "Rainvillers"
            },
            {
                "value": 32804,
                "label": "Songeons",
                "label_ar": "الجراحون",
                "label_fr": "Songeons"
            },
            {
                "value": 32805,
                "label": "Berneuil-sur-Aisne",
                "label_ar": "برنويل سور أيسن",
                "label_fr": "Berneuil-sur-Aisne"
            },
            {
                "value": 32806,
                "label": "Marseille-en-Beauvaisis",
                "label_ar": "مرسيليا أون بوفيه",
                "label_fr": "Marseille-en-Beauvaisis"
            },
            {
                "value": 32807,
                "label": "Cuvergnon",
                "label_ar": "كوفيرنون",
                "label_fr": "Cuvergnon"
            },
            {
                "value": 32808,
                "label": "Cuise-la-Motte",
                "label_ar": "كروز لا موت",
                "label_fr": "Cuise-la-Motte"
            },
            {
                "value": 32809,
                "label": "Dernancourt",
                "label_ar": "ديرنانكورت",
                "label_fr": "Dernancourt"
            },
            {
                "value": 32810,
                "label": "Saint-Leger-les-Domart",
                "label_ar": "سان ليجيه ليس دومارت",
                "label_fr": "Saint-Léger-les-Domart"
            },
            {
                "value": 32811,
                "label": "Ayencourt",
                "label_ar": "أينكورت",
                "label_fr": "Ayencourt"
            },
            {
                "value": 32812,
                "label": "Canaples",
                "label_ar": "كانابلز",
                "label_fr": "Canaples"
            },
            {
                "value": 32813,
                "label": "Criel-sur-Mer",
                "label_ar": "كرييل سور مير",
                "label_fr": "Criel-sur-Mer"
            },
            {
                "value": 32814,
                "label": "Morisel",
                "label_ar": "موريسيل",
                "label_fr": "Morisel"
            },
            {
                "value": 32815,
                "label": "Ham",
                "label_ar": "لحم خنزير",
                "label_fr": "jambon"
            },
            {
                "value": 32816,
                "label": "Coivrel",
                "label_ar": "كويفريل",
                "label_fr": "Coivrel"
            },
            {
                "value": 32817,
                "label": "Saint-Aubin-sous-Erquery",
                "label_ar": "سان أوبين سوس إركويري",
                "label_fr": "Saint-Aubin-sous-Erquery"
            },
            {
                "value": 32818,
                "label": "Eppeville",
                "label_ar": "ابفيل",
                "label_fr": "Eppeville"
            },
            {
                "value": 32819,
                "label": "Jonquieres",
                "label_ar": "جونكيريس",
                "label_fr": "Jonquières"
            },
            {
                "value": 32820,
                "label": "Liancourt-Saint-Pierre",
                "label_ar": "ليانكورت سان بيير",
                "label_fr": "Liancourt-Saint-Pierre"
            },
            {
                "value": 32821,
                "label": "Fourdrinoy",
                "label_ar": "فوردينوي",
                "label_fr": "Fourdrinoy"
            },
            {
                "value": 32822,
                "label": "Saint-Aubin-en-Bray",
                "label_ar": "سان أوبين أون براي",
                "label_fr": "Saint-Aubin-en-Bray"
            },
            {
                "value": 32823,
                "label": "Ablaincourt-Pressoir",
                "label_ar": "Ablaincourt-Pressoir",
                "label_fr": "Ablaincourt-Pressoir"
            },
            {
                "value": 32824,
                "label": "Annesse-et-Beaulieu",
                "label_ar": "Annesse-et-Beaulieu",
                "label_fr": "Annesse-et-Beaulieu"
            },
            {
                "value": 32825,
                "label": "Marsac-sur-lIsle",
                "label_ar": "مارساك سور ليل",
                "label_fr": "Marsac-sur-l'Isle"
            },
            {
                "value": 32826,
                "label": "Coursac",
                "label_ar": "كورساك",
                "label_fr": "Coursac"
            },
            {
                "value": 32827,
                "label": "Dauendorf",
                "label_ar": "داويندورف",
                "label_fr": "Dauendorf"
            },
            {
                "value": 32828,
                "label": "Sayat",
                "label_ar": "سايا ر",
                "label_fr": "Sayat"
            },
            {
                "value": 32829,
                "label": "Beaumont",
                "label_ar": "بومونت",
                "label_fr": "Beaumont"
            },
            {
                "value": 32830,
                "label": "Wimille",
                "label_ar": "ويميل",
                "label_fr": "Wimille"
            },
            {
                "value": 32831,
                "label": "Hautot-sur-Mer",
                "label_ar": "أوتو سور مير",
                "label_fr": "Hautot-sur-Mer"
            },
            {
                "value": 32832,
                "label": "Mattexey",
                "label_ar": "ماتكسي",
                "label_fr": "Mattexey"
            },
            {
                "value": 32833,
                "label": "Locmaria-Grand-Champ",
                "label_ar": "لوكماريا جراند تشامب",
                "label_fr": "Locmaria-Grand-Champ"
            },
            {
                "value": 32834,
                "label": "Mine de Bert",
                "label_ar": "منجم دي بيرت",
                "label_fr": "Mine de Bert"
            },
            {
                "value": 32835,
                "label": "Hastingues",
                "label_ar": "هاستينج",
                "label_fr": "Hastingues"
            },
            {
                "value": 32836,
                "label": "Moussy-le-Neuf",
                "label_ar": "موسي لو نوف",
                "label_fr": "Moussy-le-Neuf"
            },
            {
                "value": 32837,
                "label": "Montredon-des-Corbieres",
                "label_ar": "مونتريدون دي كوربيريس",
                "label_fr": "Montredon-des-Corbières"
            },
            {
                "value": 32838,
                "label": "Er",
                "label_ar": "إيه",
                "label_fr": "Euh"
            },
            {
                "value": 32839,
                "label": "Espezel",
                "label_ar": "إسبيزيل",
                "label_fr": "Espezel"
            },
            {
                "value": 32840,
                "label": "Molieres-sur-Ceze",
                "label_ar": "موليريس سور سيز",
                "label_fr": "Molières-sur-Cèze"
            },
            {
                "value": 32841,
                "label": "Fons",
                "label_ar": "فونس",
                "label_fr": "Fons"
            },
            {
                "value": 32842,
                "label": "Nevian",
                "label_ar": "نيفيان",
                "label_fr": "Nevian"
            },
            {
                "value": 32843,
                "label": "Saint-Etienne-du-Valdonnez",
                "label_ar": "سانت إتيان دو فالدونيز",
                "label_fr": "Saint-Étienne-du-Valdonnez"
            },
            {
                "value": 32844,
                "label": "Mauressargues",
                "label_ar": "موريسارج",
                "label_fr": "Mauressargues"
            },
            {
                "value": 32845,
                "label": "Aubord",
                "label_ar": "أوبورد",
                "label_fr": "Aubord"
            },
            {
                "value": 32846,
                "label": "Saint-Mamert-du-Gard",
                "label_ar": "سان مامرت دو جارد",
                "label_fr": "Saint-Mamert-du-Gard"
            },
            {
                "value": 32847,
                "label": "Siran",
                "label_ar": "سيران",
                "label_fr": "Siran"
            },
            {
                "value": 32848,
                "label": "Arrigas",
                "label_ar": "اريجاس",
                "label_fr": "Arrigas"
            },
            {
                "value": 32849,
                "label": "Valliguieres",
                "label_ar": "Valliguieres",
                "label_fr": "Valliguières"
            },
            {
                "value": 32850,
                "label": "Pouzilhac",
                "label_ar": "بوزيلاك",
                "label_fr": "Pouzilhac"
            },
            {
                "value": 32851,
                "label": "Cazevieille",
                "label_ar": "كازيفييل",
                "label_fr": "Cazevieille"
            },
            {
                "value": 32852,
                "label": "Estagel",
                "label_ar": "Estagel",
                "label_fr": "Estagel"
            },
            {
                "value": 32853,
                "label": "Cazilhac",
                "label_ar": "كازيلاك",
                "label_fr": "Cazilhac"
            },
            {
                "value": 32854,
                "label": "Fendeille",
                "label_ar": "فنديل",
                "label_fr": "Fendeille"
            },
            {
                "value": 32855,
                "label": "Graissessac",
                "label_ar": "جرايسساك",
                "label_fr": "Graissessac"
            },
            {
                "value": 32856,
                "label": "Prades",
                "label_ar": "براديس",
                "label_fr": "Prades"
            },
            {
                "value": 32857,
                "label": "Fosse",
                "label_ar": "فوس",
                "label_fr": "Fosse"
            },
            {
                "value": 32858,
                "label": "Fressac",
                "label_ar": "فريساك",
                "label_fr": "Fressac"
            },
            {
                "value": 32859,
                "label": "Vacquieres",
                "label_ar": "فاكويريس",
                "label_fr": "Vacquières"
            },
            {
                "value": 32860,
                "label": "Montbel",
                "label_ar": "مونبل",
                "label_fr": "Montbel"
            },
            {
                "value": 32861,
                "label": "Nizas",
                "label_ar": "نزاس",
                "label_fr": "Nizas"
            },
            {
                "value": 32862,
                "label": "Montpezat",
                "label_ar": "مونبيزات",
                "label_fr": "Montpezat"
            },
            {
                "value": 32863,
                "label": "Saint-Dionisy",
                "label_ar": "سان ديونيسي",
                "label_fr": "Saint-Dionisy"
            },
            {
                "value": 32864,
                "label": "Embry",
                "label_ar": "امبري",
                "label_fr": "Embry"
            },
            {
                "value": 32865,
                "label": "Aubry-du-Hainaut",
                "label_ar": "أوبري دو هينو",
                "label_fr": "Aubry-du-Hainaut"
            },
            {
                "value": 32866,
                "label": "Le Doulieu",
                "label_ar": "لو دوليو",
                "label_fr": "Le Doulieu"
            },
            {
                "value": 32867,
                "label": "Auchy-lez-Orchies",
                "label_ar": "اوتشي ليز اوركيس",
                "label_fr": "Auchy-lez-Orchies"
            },
            {
                "value": 32868,
                "label": "Frelinghien",
                "label_ar": "فريلينجين",
                "label_fr": "Frelinghien"
            },
            {
                "value": 32869,
                "label": "Soulanges",
                "label_ar": "سولانج",
                "label_fr": "Soulanges"
            },
            {
                "value": 32870,
                "label": "Peroy-les-Gombries",
                "label_ar": "Peroy-les-Gombries",
                "label_fr": "Péroy-les-Gombries"
            },
            {
                "value": 32871,
                "label": "Tricot",
                "label_ar": "التريكو",
                "label_fr": "Tricot"
            },
            {
                "value": 32872,
                "label": "Amigny-Rouy",
                "label_ar": "اميني روي",
                "label_fr": "Amigny-Rouy"
            },
            {
                "value": 32873,
                "label": "Pont-de-Metz",
                "label_ar": "بونت دي ميتز",
                "label_fr": "Pont-de-Metz"
            },
            {
                "value": 32874,
                "label": "Cliron",
                "label_ar": "كلييرون",
                "label_fr": "Cliron"
            },
            {
                "value": 32875,
                "label": "Montmirail",
                "label_ar": "مونتميرايل",
                "label_fr": "Montmirail"
            },
            {
                "value": 32876,
                "label": "Gouy-en-Ternois",
                "label_ar": "Gouy-en-Ternois",
                "label_fr": "Gouy-en-Ternois"
            },
            {
                "value": 32877,
                "label": "Savy-Berlette",
                "label_ar": "سافي بيرليت",
                "label_fr": "Savy-Berlette"
            },
            {
                "value": 32878,
                "label": "Burbure",
                "label_ar": "بربور",
                "label_fr": "Burbure"
            },
            {
                "value": 32879,
                "label": "Rodelinghem",
                "label_ar": "رودلينجيم",
                "label_fr": "Rodelinghem"
            },
            {
                "value": 32880,
                "label": "Recques-sur-Course",
                "label_ar": "Recques-sur-Course",
                "label_fr": "Recques-sur-Course"
            },
            {
                "value": 32881,
                "label": "Bussy-le-Chateau",
                "label_ar": "بوسي لو شاتو",
                "label_fr": "Bussy-le-Château"
            },
            {
                "value": 32882,
                "label": "Blingel",
                "label_ar": "بلينجل",
                "label_fr": "Blingel"
            },
            {
                "value": 32883,
                "label": "Hubersent",
                "label_ar": "Hubersent",
                "label_fr": "Hubersent"
            },
            {
                "value": 32884,
                "label": "La Houssoye",
                "label_ar": "لا هوسوي",
                "label_fr": "La Houssoye"
            },
            {
                "value": 32885,
                "label": "Pont-Noyelles",
                "label_ar": "بونت نوييل",
                "label_fr": "Pont-Noyelles"
            },
            {
                "value": 32886,
                "label": "Grandchamp",
                "label_ar": "غراندشامب",
                "label_fr": "Grandchamp"
            },
            {
                "value": 32887,
                "label": "Dammartin-en-Serve",
                "label_ar": "دامارتين أون سيرف",
                "label_fr": "Dammartin-en-Serve"
            },
            {
                "value": 32888,
                "label": "Breuil",
                "label_ar": "برويل",
                "label_fr": "Breuil"
            },
            {
                "value": 32889,
                "label": "Condecourt",
                "label_ar": "كوندكور",
                "label_fr": "Condécourt"
            },
            {
                "value": 32890,
                "label": "Saulx-Marchais",
                "label_ar": "شاولكس مارشايس",
                "label_fr": "Saulx-Marchais"
            },
            {
                "value": 32891,
                "label": "Les Jumeaux",
                "label_ar": "ليه جومو",
                "label_fr": "Les Jumeaux"
            },
            {
                "value": 32892,
                "label": "Fericy",
                "label_ar": "فريسى",
                "label_fr": "Fericy"
            },
            {
                "value": 32893,
                "label": "Gressy",
                "label_ar": "جريسي",
                "label_fr": "Gressy"
            },
            {
                "value": 32894,
                "label": "Grainville",
                "label_ar": "غرينفيل",
                "label_fr": "Grainville"
            },
            {
                "value": 32895,
                "label": "Marlotte",
                "label_ar": "مارلوت",
                "label_fr": "Marlotte"
            },
            {
                "value": 32896,
                "label": "Villevaude",
                "label_ar": "فيليفود",
                "label_fr": "Villevaude"
            },
            {
                "value": 32897,
                "label": "Liverdy-en-Brie",
                "label_ar": "ليفردي أون بري",
                "label_fr": "Liverdy-en-Brie"
            },
            {
                "value": 32898,
                "label": "Mons-en-Montois",
                "label_ar": "مونس أون مونتوا",
                "label_fr": "Mons-en-Montois"
            },
            {
                "value": 32899,
                "label": "Espirat",
                "label_ar": "إسبيرات",
                "label_fr": "Espirat"
            },
            {
                "value": 32900,
                "label": "Le Theil",
                "label_ar": "لو ثيل",
                "label_fr": "Le Theil"
            },
            {
                "value": 32901,
                "label": "Roffiac",
                "label_ar": "روفياك",
                "label_fr": "Roffiac"
            },
            {
                "value": 32902,
                "label": "Bergonne",
                "label_ar": "بيرجون",
                "label_fr": "Bergonne"
            },
            {
                "value": 32903,
                "label": "Saint-Hippolyte",
                "label_ar": "سانت هيبوليت",
                "label_fr": "Saint-Hippolyte"
            },
            {
                "value": 32904,
                "label": "Coudes",
                "label_ar": "كودس",
                "label_fr": "Coudes"
            },
            {
                "value": 32905,
                "label": "Commentry",
                "label_ar": "التعليق",
                "label_fr": "Commentry"
            },
            {
                "value": 32906,
                "label": "Le Breuil-sur-Couze",
                "label_ar": "لو بريويل سور كوز",
                "label_fr": "Le Breuil-sur-Couze"
            },
            {
                "value": 32907,
                "label": "Lachaux",
                "label_ar": "لاشو",
                "label_fr": "Lachaux"
            },
            {
                "value": 32908,
                "label": "Segonzat",
                "label_ar": "سيجونزات",
                "label_fr": "Segonzat"
            },
            {
                "value": 32909,
                "label": "La Chaise",
                "label_ar": "لا تشيس",
                "label_fr": "La Chaise"
            },
            {
                "value": 32910,
                "label": "Saint-Sandoux",
                "label_ar": "سان ساندوكس",
                "label_fr": "Saint-Sandoux"
            },
            {
                "value": 32911,
                "label": "Champagnac-le-Vieux",
                "label_ar": "شامبانياك لو فيو",
                "label_fr": "Champagnac-le-Vieux"
            },
            {
                "value": 32912,
                "label": "Pignols",
                "label_ar": "بينول",
                "label_fr": "Pignols"
            },
            {
                "value": 32913,
                "label": "Vallon-en-Sully",
                "label_ar": "فالون أون سولي",
                "label_fr": "Vallon-en-Sully"
            },
            {
                "value": 32914,
                "label": "Chauriat",
                "label_ar": "شوريات",
                "label_fr": "Chauriat"
            },
            {
                "value": 32915,
                "label": "Gennetines",
                "label_ar": "جينيتين",
                "label_fr": "Gennetines"
            },
            {
                "value": 32916,
                "label": "Le Donjon",
                "label_ar": "لو دونجون",
                "label_fr": "Le Donjon"
            },
            {
                "value": 32917,
                "label": "Saint-Didier-en-Donjon",
                "label_ar": "سان ديدييه أون دونجون",
                "label_fr": "Saint-Didier-en-Donjon"
            },
            {
                "value": 32918,
                "label": "Le Broc",
                "label_ar": "لو بروك",
                "label_fr": "Le Broc"
            },
            {
                "value": 32919,
                "label": "Saint-Floret",
                "label_ar": "سانت فلوريت",
                "label_fr": "Saint-Floret"
            },
            {
                "value": 32920,
                "label": "Vernines",
                "label_ar": "فيرنينز",
                "label_fr": "Vernines"
            },
            {
                "value": 32921,
                "label": "Saint-Maurice-de-Lignon",
                "label_ar": "سان موريس دي لينيون",
                "label_fr": "Saint-Maurice-de-Lignon"
            },
            {
                "value": 32922,
                "label": "Ceaux-d'Allegre",
                "label_ar": "Ceaux-d'Allegre",
                "label_fr": "Ceaux-d'Allegre"
            },
            {
                "value": 32923,
                "label": "Fayet-le-Chateau",
                "label_ar": "فايت لو شاتو",
                "label_fr": "Fayet-le-Château"
            },
            {
                "value": 32924,
                "label": "Parent",
                "label_ar": "الأبوين",
                "label_fr": "Parent"
            },
            {
                "value": 32925,
                "label": "Le Vigean",
                "label_ar": "لو فيجين",
                "label_fr": "Le Vigean"
            },
            {
                "value": 32926,
                "label": "Landogne",
                "label_ar": "لاندوجني",
                "label_fr": "Landogne"
            },
            {
                "value": 32927,
                "label": "Culhat",
                "label_ar": "كولهات",
                "label_fr": "Culhat"
            },
            {
                "value": 32928,
                "label": "Felines",
                "label_ar": "الماكرون",
                "label_fr": "Félins"
            },
            {
                "value": 32929,
                "label": "Malicorne",
                "label_ar": "ماليكورن",
                "label_fr": "Malicorne"
            },
            {
                "value": 32930,
                "label": "Pebrac",
                "label_ar": "بيبراك",
                "label_fr": "Pebrac"
            },
            {
                "value": 32931,
                "label": "Paslieres",
                "label_ar": "عبّاس",
                "label_fr": "Paslieres"
            },
            {
                "value": 32932,
                "label": "Randan",
                "label_ar": "رندان",
                "label_fr": "Randan"
            },
            {
                "value": 32933,
                "label": "Bains",
                "label_ar": "باينز",
                "label_fr": "Bains"
            },
            {
                "value": 32934,
                "label": "Perrier",
                "label_ar": "بيرييه",
                "label_fr": "Perrier"
            },
            {
                "value": 32935,
                "label": "Le Pin",
                "label_ar": "لو بين",
                "label_fr": "Le Pin"
            },
            {
                "value": 32936,
                "label": "Lacapelle-Viescamp",
                "label_ar": "لاكابيل فيسكامب",
                "label_fr": "Lacapelle-Viescamp"
            },
            {
                "value": 32937,
                "label": "Fournols",
                "label_ar": "فورنو",
                "label_fr": "Fournols"
            },
            {
                "value": 32938,
                "label": "Chirat-l'Église",
                "label_ar": "Chirat-l'Église",
                "label_fr": "Chirat-l'Église"
            },
            {
                "value": 32939,
                "label": "Fleuriel",
                "label_ar": "فلوريل",
                "label_fr": "Fleuriel"
            },
            {
                "value": 32940,
                "label": "Brousse",
                "label_ar": "بروس",
                "label_fr": "Brousse"
            },
            {
                "value": 32941,
                "label": "Les Ancizes-Comps",
                "label_ar": "ليه أنسيزيس كومبس",
                "label_fr": "Les Ancizes-Comps"
            },
            {
                "value": 32942,
                "label": "Menetrol",
                "label_ar": "مينترول",
                "label_fr": "Ménétrol"
            },
            {
                "value": 32943,
                "label": "Abrest",
                "label_ar": "أبريست",
                "label_fr": "Abrest"
            },
            {
                "value": 32944,
                "label": "Loubeyrat",
                "label_ar": "لوبيرات",
                "label_fr": "Loubeyrat"
            },
            {
                "value": 32945,
                "label": "Verneugheol",
                "label_ar": "فيرنيوجول",
                "label_fr": "Verneugheol"
            },
            {
                "value": 32946,
                "label": "Colondannes",
                "label_ar": "كولوندان",
                "label_fr": "Colondannes"
            },
            {
                "value": 32947,
                "label": "Exideuil",
                "label_ar": "اكسيدويل",
                "label_fr": "Exideuil"
            },
            {
                "value": 32948,
                "label": "Roquecor",
                "label_ar": "روكور",
                "label_fr": "Roquecor"
            },
            {
                "value": 32949,
                "label": "Le Segur",
                "label_ar": "لو سيجور",
                "label_fr": "Le Ségur"
            },
            {
                "value": 32950,
                "label": "Cardaillac",
                "label_ar": "كارديلاك",
                "label_fr": "Cardaillac"
            },
            {
                "value": 32951,
                "label": "Caragoudes",
                "label_ar": "كاراجودس",
                "label_fr": "Caragoudes"
            },
            {
                "value": 32952,
                "label": "Puybegon",
                "label_ar": "بويبيغون",
                "label_fr": "Puybegon"
            },
            {
                "value": 32953,
                "label": "Burlats",
                "label_ar": "بورلاتس",
                "label_fr": "Burlats"
            },
            {
                "value": 32954,
                "label": "Cazaux-Layrisse",
                "label_ar": "كازاوكس ليريس",
                "label_fr": "Cazaux-Layrisse"
            },
            {
                "value": 32955,
                "label": "Rouffignac-Saint-Cernin-de-Reilhac",
                "label_ar": "روفينياك سانت سيرنين دي ريله",
                "label_fr": "Rouffignac-Saint-Cernin-de-Reilhac"
            },
            {
                "value": 32956,
                "label": "Salles-de-Barbezieux",
                "label_ar": "ساليس دي باربيزيو",
                "label_fr": "Salles-de-Barbezieux"
            },
            {
                "value": 32957,
                "label": "Fougeres",
                "label_ar": "عطور الفوجير",
                "label_fr": "Fougères"
            },
            {
                "value": 32958,
                "label": "Salles-Mongiscard",
                "label_ar": "ساليس مونجيسكار",
                "label_fr": "Salles-Mongiscard"
            },
            {
                "value": 32959,
                "label": "Castels",
                "label_ar": "القلاع",
                "label_fr": "Châteaux"
            },
            {
                "value": 32960,
                "label": "Loupes",
                "label_ar": "العدسة",
                "label_fr": "Loupes"
            },
            {
                "value": 32961,
                "label": "Donnezac",
                "label_ar": "دونيزاك",
                "label_fr": "Donnezac"
            },
            {
                "value": 32962,
                "label": "Meilhan",
                "label_ar": "ميلهان",
                "label_fr": "Meilhan"
            },
            {
                "value": 32963,
                "label": "Le Pizou",
                "label_ar": "لو بيزو",
                "label_fr": "Le Pizou"
            },
            {
                "value": 32964,
                "label": "Saint-Romain-la-Virvee",
                "label_ar": "Saint-Romain-la-Virvee",
                "label_fr": "Saint-Romain-la-Virvee"
            },
            {
                "value": 32965,
                "label": "Sarrazac",
                "label_ar": "سارازاك",
                "label_fr": "Sarrazac"
            },
            {
                "value": 32966,
                "label": "Vaulry",
                "label_ar": "فولري",
                "label_fr": "Vaulry"
            },
            {
                "value": 32967,
                "label": "Idrac-Respailles",
                "label_ar": "إدراك ريسبيل",
                "label_fr": "Idrac-Respailles"
            },
            {
                "value": 32968,
                "label": "Saint-Laurent-de-Levezou",
                "label_ar": "سان لوران دي ليفيزو",
                "label_fr": "Saint-Laurent-de-Levezou"
            },
            {
                "value": 32969,
                "label": "Saint-Elix-Seglan",
                "label_ar": "سانت إليكس سيجلان",
                "label_fr": "Saint-Elix-Seglan"
            },
            {
                "value": 32970,
                "label": "Castelnau-de-Levis",
                "label_ar": "Castelnau-de-Levis",
                "label_fr": "Castelnau-de-Lévis"
            },
            {
                "value": 32971,
                "label": "Tournecoupe",
                "label_ar": "تورنيكوب",
                "label_fr": "Tournecoupe"
            },
            {
                "value": 32972,
                "label": "Conne-de-Labarde",
                "label_ar": "كون دي لابارد",
                "label_fr": "Conne-de-Labarde"
            },
            {
                "value": 32973,
                "label": "Sainte-Eulalie-en-Born",
                "label_ar": "Sainte-Eulalie-en-Born",
                "label_fr": "Sainte-Eulalie-en-Born"
            },
            {
                "value": 32974,
                "label": "Mescoules",
                "label_ar": "ميسكول",
                "label_fr": "Mescoules"
            },
            {
                "value": 32975,
                "label": "Razac-d'Eymet",
                "label_ar": "رزاق ديميت",
                "label_fr": "Razac-d'Eymet"
            },
            {
                "value": 32976,
                "label": "Pissos",
                "label_ar": "بيسوس",
                "label_fr": "Pissos"
            },
            {
                "value": 32977,
                "label": "Sainte-Foy-de-Longas",
                "label_ar": "سانت فوي دي لونجاس",
                "label_fr": "Sainte-Foy-de-Longas"
            },
            {
                "value": 32978,
                "label": "Chaignay",
                "label_ar": "Chaignay",
                "label_fr": "Chaignay"
            },
            {
                "value": 32979,
                "label": "Savouges",
                "label_ar": "سافوج",
                "label_fr": "Savouges"
            },
            {
                "value": 32980,
                "label": "Pouilley-les-Vignes",
                "label_ar": "Pouilley-les-Vignes",
                "label_fr": "Pouilley-les-Vignes"
            },
            {
                "value": 32981,
                "label": "Lixheim",
                "label_ar": "ليكسهايم",
                "label_fr": "Lixheim"
            },
            {
                "value": 32982,
                "label": "Ars-Laquenexy",
                "label_ar": "Ars-Laquenexy",
                "label_fr": "Ars-Laquenexy"
            },
            {
                "value": 32983,
                "label": "Boucheporn",
                "label_ar": "بوشبورن",
                "label_fr": "Boucheporn"
            },
            {
                "value": 32984,
                "label": "Jarmenil",
                "label_ar": "جارمينيل",
                "label_fr": "Jarmenil"
            },
            {
                "value": 32985,
                "label": "Gosselming",
                "label_ar": "جوسيلمينج",
                "label_fr": "Gosselming"
            },
            {
                "value": 32986,
                "label": "Damerey",
                "label_ar": "داميري",
                "label_fr": "Damerey"
            },
            {
                "value": 32987,
                "label": "Le Planois",
                "label_ar": "لو بلانوا",
                "label_fr": "Le Planois"
            },
            {
                "value": 32988,
                "label": "Rodern",
                "label_ar": "رودرن",
                "label_fr": "Rodern"
            },
            {
                "value": 32989,
                "label": "Hindlingen",
                "label_ar": "هيندلينجين",
                "label_fr": "Hindlingen"
            },
            {
                "value": 32990,
                "label": "Bisel",
                "label_ar": "بيسل",
                "label_fr": "Bisel"
            },
            {
                "value": 32991,
                "label": "Echavanne",
                "label_ar": "إتشافان",
                "label_fr": "Echavanne"
            },
            {
                "value": 32992,
                "label": "Bougnon",
                "label_ar": "بوغنون",
                "label_fr": "Bougnon"
            },
            {
                "value": 32993,
                "label": "Dounoux",
                "label_ar": "دونوكس",
                "label_fr": "Dounoux"
            },
            {
                "value": 32994,
                "label": "Nomeny",
                "label_ar": "نوميني",
                "label_fr": "Nomeny"
            },
            {
                "value": 32995,
                "label": "Benney",
                "label_ar": "بيني",
                "label_fr": "Benney"
            },
            {
                "value": 32996,
                "label": "Manoncourt-sur-Seille",
                "label_ar": "مانونكور سور سيل",
                "label_fr": "Manoncourt-sur-Seille"
            },
            {
                "value": 32997,
                "label": "Bazien",
                "label_ar": "بازين",
                "label_fr": "Bazien"
            },
            {
                "value": 32998,
                "label": "Le Gratteris",
                "label_ar": "لو جراتريس",
                "label_fr": "Le Gratteris"
            },
            {
                "value": 32999,
                "label": "Olwisheim",
                "label_ar": "أولويشيم",
                "label_fr": "Olwisheim"
            },
            {
                "value": 33000,
                "label": "Fey",
                "label_ar": "فاي",
                "label_fr": "Fey"
            },
            {
                "value": 33001,
                "label": "Gerbeviller",
                "label_ar": "جيربفيللر",
                "label_fr": "Gerbeviller"
            },
            {
                "value": 33002,
                "label": "Marcilly-en-Bassigny",
                "label_ar": "Marcilly-en-Bassigny",
                "label_fr": "Marcilly-en-Bassigny"
            },
            {
                "value": 33003,
                "label": "L'Etang-Vergy",
                "label_ar": "لتانغ فيرجي",
                "label_fr": "L'Etang-Vergy"
            },
            {
                "value": 33004,
                "label": "Thal-Drulingen",
                "label_ar": "ثال درولينجن",
                "label_fr": "Thal-Drulingen"
            },
            {
                "value": 33005,
                "label": "Kesseldorf",
                "label_ar": "كيسلدورف",
                "label_fr": "Kesseldorf"
            },
            {
                "value": 33006,
                "label": "Daubensand",
                "label_ar": "دوبنساند",
                "label_fr": "Daubensand"
            },
            {
                "value": 33007,
                "label": "Darnieulles",
                "label_ar": "دارنيولس",
                "label_fr": "Darnieulles"
            },
            {
                "value": 33008,
                "label": "Etival",
                "label_ar": "إتيفال",
                "label_fr": "Etival"
            },
            {
                "value": 33009,
                "label": "Saint-Martin-du-Puy",
                "label_ar": "سان مارتن دو بوي",
                "label_fr": "Saint-Martin-du-Puy"
            },
            {
                "value": 33010,
                "label": "Dancevoir",
                "label_ar": "Dancevoir",
                "label_fr": "Dancevoir"
            },
            {
                "value": 33011,
                "label": "Montcoy",
                "label_ar": "مونتكوي",
                "label_fr": "Montcoy"
            },
            {
                "value": 33012,
                "label": "Champdotre",
                "label_ar": "Champdotre",
                "label_fr": "Champdotre"
            },
            {
                "value": 33013,
                "label": "Thenissey",
                "label_ar": "ثينيسي",
                "label_fr": "Thenissey"
            },
            {
                "value": 33014,
                "label": "Chalampe",
                "label_ar": "تشالامبي",
                "label_fr": "Chalampe"
            },
            {
                "value": 33015,
                "label": "Chichery",
                "label_ar": "شيشيري",
                "label_fr": "Chichery"
            },
            {
                "value": 33016,
                "label": "Laneuvelotte",
                "label_ar": "لينوفيلوت",
                "label_fr": "Laneuvelotte"
            },
            {
                "value": 33017,
                "label": "Brin-sur-Seille",
                "label_ar": "برين سور سيل",
                "label_fr": "Brin-sur-Seille"
            },
            {
                "value": 33018,
                "label": "Buthiers",
                "label_ar": "الجزارين",
                "label_fr": "Buthiers"
            },
            {
                "value": 33019,
                "label": "Aumont",
                "label_ar": "أومونت",
                "label_fr": "Aumont"
            },
            {
                "value": 33020,
                "label": "Precy-sur-Vrin",
                "label_ar": "بريسي سور فرين",
                "label_fr": "Précy-sur-Vrin"
            },
            {
                "value": 33021,
                "label": "Lamarche",
                "label_ar": "لامارش",
                "label_fr": "Lamarche"
            },
            {
                "value": 33022,
                "label": "Chaligny",
                "label_ar": "شاليجني",
                "label_fr": "Chaligny"
            },
            {
                "value": 33023,
                "label": "Valmestroff",
                "label_ar": "فالمستروف",
                "label_fr": "Valmestroff"
            },
            {
                "value": 33024,
                "label": "Troissy",
                "label_ar": "ترواسي",
                "label_fr": "Troissy"
            },
            {
                "value": 33025,
                "label": "Lambert",
                "label_ar": "لامبرت",
                "label_fr": "Lambert"
            },
            {
                "value": 33026,
                "label": "Mareuil-le-Port",
                "label_ar": "مريل لو بورت",
                "label_fr": "Mareuil-le-Port"
            },
            {
                "value": 33027,
                "label": "Pernes-les-Boulogne",
                "label_ar": "بيرنس ليه بولوني",
                "label_fr": "Pernes-les-Boulogne"
            },
            {
                "value": 33028,
                "label": "Estree-Blanche",
                "label_ar": "إستري بلانش",
                "label_fr": "Estrée-Blanche"
            },
            {
                "value": 33029,
                "label": "Boutancourt",
                "label_ar": "بوتانكور",
                "label_fr": "Boutancourt"
            },
            {
                "value": 33030,
                "label": "Reumont",
                "label_ar": "ريومونت",
                "label_fr": "Reumont"
            },
            {
                "value": 33031,
                "label": "Moyenneville",
                "label_ar": "موينفيل",
                "label_fr": "Moyenneville"
            },
            {
                "value": 33032,
                "label": "Moulle",
                "label_ar": "مول",
                "label_fr": "Moulle"
            },
            {
                "value": 33033,
                "label": "Tubersent",
                "label_ar": "الدرنات",
                "label_fr": "Tubercule"
            },
            {
                "value": 33034,
                "label": "Leval",
                "label_ar": "ليفال",
                "label_fr": "Leval"
            },
            {
                "value": 33035,
                "label": "Villers-Helon",
                "label_ar": "فيليرز هيلون",
                "label_fr": "Villers-Helon"
            },
            {
                "value": 33036,
                "label": "Passel",
                "label_ar": "عصبة",
                "label_fr": "Passel"
            },
            {
                "value": 33037,
                "label": "Villenauxe-la-Grande",
                "label_ar": "Villenauxe-la-Grande",
                "label_fr": "Villenauxe-la-Grande"
            },
            {
                "value": 33038,
                "label": "Chatonrupt-Sommermont",
                "label_ar": "Chatonrupt-Sommermont",
                "label_fr": "Chatonrupt-Sommermont"
            },
            {
                "value": 33039,
                "label": "Pouan-les-Vallees",
                "label_ar": "Pouan-les-Vallees",
                "label_fr": "Pouan-les-Vallées"
            },
            {
                "value": 33040,
                "label": "Enencourt-le-Sec",
                "label_ar": "Enencourt-le-Sec",
                "label_fr": "Enencourt-le-Sec"
            },
            {
                "value": 33041,
                "label": "Wirwignes",
                "label_ar": "ويروينيس",
                "label_fr": "Wirwignes"
            },
            {
                "value": 33042,
                "label": "Thivencelle",
                "label_ar": "Thivencelle",
                "label_fr": "Thivencelle"
            },
            {
                "value": 33043,
                "label": "Clairmarais",
                "label_ar": "كليرماريس",
                "label_fr": "Clairmarais"
            },
            {
                "value": 33044,
                "label": "Esquelbecq",
                "label_ar": "Esquelbecq",
                "label_fr": "Esquelbecq"
            },
            {
                "value": 33045,
                "label": "Stella-Plage",
                "label_ar": "ستيلا بلاج",
                "label_fr": "Stella-Plage"
            },
            {
                "value": 33046,
                "label": "Fauquembergues",
                "label_ar": "فوكيمبيرج",
                "label_fr": "Fauquembergues"
            },
            {
                "value": 33047,
                "label": "Roquetoire",
                "label_ar": "روكيتوير",
                "label_fr": "Roquetoire"
            },
            {
                "value": 33048,
                "label": "Selles",
                "label_ar": "يبيع",
                "label_fr": "Selles"
            },
            {
                "value": 33049,
                "label": "Rouvroy-sur-Audry",
                "label_ar": "روفروي سور أودري",
                "label_fr": "Rouvroy-sur-Audry"
            },
            {
                "value": 33050,
                "label": "Laifour",
                "label_ar": "ليفور",
                "label_fr": "Laifour"
            },
            {
                "value": 33051,
                "label": "Berthen",
                "label_ar": "بيرثين",
                "label_fr": "Berthen"
            },
            {
                "value": 33052,
                "label": "Saint-Floris",
                "label_ar": "سانت فلوريس",
                "label_fr": "Saint-Floris"
            },
            {
                "value": 33053,
                "label": "Rouilly-Sacey",
                "label_ar": "رويلي ساسي",
                "label_fr": "Rouilly-Sacey"
            },
            {
                "value": 33054,
                "label": "Elincourt",
                "label_ar": "إلينكور",
                "label_fr": "Elincourt"
            },
            {
                "value": 33055,
                "label": "Plumoison",
                "label_ar": "بلوموازون",
                "label_fr": "Plumoison"
            },
            {
                "value": 33056,
                "label": "Barastre",
                "label_ar": "باراستر",
                "label_fr": "Barastre"
            },
            {
                "value": 33057,
                "label": "Mametz",
                "label_ar": "ماميتز",
                "label_fr": "Mametz"
            },
            {
                "value": 33058,
                "label": "Courcelles-Epayelles",
                "label_ar": "Courcelles-Epayelles",
                "label_fr": "Courcelles-Epayelles"
            },
            {
                "value": 33059,
                "label": "Villers-Saint-Frambourg",
                "label_ar": "فيلير سانت فرامبورغ",
                "label_fr": "Villers-Saint-Frambourg"
            },
            {
                "value": 33060,
                "label": "Mondrepuis",
                "label_ar": "موندريبويس",
                "label_fr": "Mondrepuis"
            },
            {
                "value": 33061,
                "label": "Chatres",
                "label_ar": "شاتريس",
                "label_fr": "Chatres"
            },
            {
                "value": 33062,
                "label": "Compertrix",
                "label_ar": "كومبيرتريكس",
                "label_fr": "Compertrix"
            },
            {
                "value": 33063,
                "label": "Ville-sur-Lumes",
                "label_ar": "فيل سور لوميز",
                "label_fr": "Ville-sur-Lumes"
            },
            {
                "value": 33064,
                "label": "Leuilly-sous-Coucy",
                "label_ar": "Leuilly-sous-Coucy",
                "label_fr": "Leuilly-sous-Coucy"
            },
            {
                "value": 33065,
                "label": "Estrees-les-Crecy",
                "label_ar": "إستريس ليه كريسي",
                "label_fr": "Estrees-les-Crecy"
            },
            {
                "value": 33066,
                "label": "Wandignies-Hamage",
                "label_ar": "Wandignies-Hamage",
                "label_fr": "Wandignies-Hamage"
            },
            {
                "value": 33067,
                "label": "Croisette",
                "label_ar": "كروازيت",
                "label_fr": "Croisette"
            },
            {
                "value": 33068,
                "label": "Maninghen-Henne",
                "label_ar": "مانينغين هين",
                "label_fr": "Maninghen-Henne"
            },
            {
                "value": 33069,
                "label": "Pierrerue",
                "label_ar": "بيرير",
                "label_fr": "Pierrerue"
            },
            {
                "value": 33070,
                "label": "Vescovato",
                "label_ar": "فيسكوفاتو",
                "label_fr": "Vescovato"
            },
            {
                "value": 33071,
                "label": "Taglio-Isolaccio",
                "label_ar": "تاجليو إيزولاتشيو",
                "label_fr": "Taglio-Isolaccio"
            },
            {
                "value": 33072,
                "label": "Châteauneuf-du-Pape",
                "label_ar": "شاتونوف دو باب",
                "label_fr": "Châteauneuf-du-Pape"
            },
            {
                "value": 33073,
                "label": "Belgodere",
                "label_ar": "بيلجوديري",
                "label_fr": "Belgodère"
            },
            {
                "value": 33074,
                "label": "Castello-di-Rostino",
                "label_ar": "كاستيلو دي روستينو",
                "label_fr": "Castello-di-Rostino"
            },
            {
                "value": 33075,
                "label": "Cairanne",
                "label_ar": "كايران",
                "label_fr": "Cairanne"
            },
            {
                "value": 33076,
                "label": "Puimichel",
                "label_ar": "Puimichel",
                "label_fr": "Puimichel"
            },
            {
                "value": 33077,
                "label": "Menerbes",
                "label_ar": "مينربس",
                "label_fr": "Ménerbes"
            },
            {
                "value": 33078,
                "label": "Muro",
                "label_ar": "مورو",
                "label_fr": "Muro"
            },
            {
                "value": 33079,
                "label": "Poggio-dOletta",
                "label_ar": "بوجيو دوليتا",
                "label_fr": "Poggio-d'Oletta"
            },
            {
                "value": 33080,
                "label": "Saint-Privat-d'Allier",
                "label_ar": "سان بريفات دالييه",
                "label_fr": "Saint-Privat-d'Allier"
            },
            {
                "value": 33081,
                "label": "Loubaresse",
                "label_ar": "لوباريس",
                "label_fr": "Loubaresse"
            },
            {
                "value": 33082,
                "label": "Mezeres",
                "label_ar": "Mezeres",
                "label_fr": "Mezeres"
            },
            {
                "value": 33083,
                "label": "Villefranche-d'Allier",
                "label_ar": "فيلفرانش داليير",
                "label_fr": "Villefranche-d'Allier"
            },
            {
                "value": 33084,
                "label": "Neure",
                "label_ar": "نيور",
                "label_fr": "Neure"
            },
            {
                "value": 33085,
                "label": "Allier",
                "label_ar": "ألير",
                "label_fr": "Allier"
            },
            {
                "value": 33086,
                "label": "Saint-Front",
                "label_ar": "سان فرونت",
                "label_fr": "Saint-Front"
            },
            {
                "value": 33087,
                "label": "Madriat",
                "label_ar": "مادريات",
                "label_fr": "Madriat"
            },
            {
                "value": 33088,
                "label": "Salzuit",
                "label_ar": "سالزويت",
                "label_fr": "Salzuit"
            },
            {
                "value": 33089,
                "label": "Fontannes",
                "label_ar": "فونتانيس",
                "label_fr": "Fontannes"
            },
            {
                "value": 33090,
                "label": "Dingy-Saint-Clair",
                "label_ar": "دينجي سانت كلير",
                "label_fr": "Dingy-Saint-Clair"
            },
            {
                "value": 33091,
                "label": "Crevant-Laveine",
                "label_ar": "Crevant-Laveine",
                "label_fr": "Crevant-Laveine"
            },
            {
                "value": 33092,
                "label": "Salers",
                "label_ar": "سالرز",
                "label_fr": "Salers"
            },
            {
                "value": 33093,
                "label": "Saint-Dier-d'Auvergne",
                "label_ar": "Saint-Dier-d'Auvergne",
                "label_fr": "Saint-Dier-d'Auvergne"
            },
            {
                "value": 33094,
                "label": "Roumegoux",
                "label_ar": "روميجو",
                "label_fr": "Roumegoux"
            },
            {
                "value": 33095,
                "label": "Villebret",
                "label_ar": "فيلبريت",
                "label_fr": "Villebret"
            },
            {
                "value": 33096,
                "label": "Sauret-Besserve",
                "label_ar": "سوريت بيسيرف",
                "label_fr": "Sauret-Besserve"
            },
            {
                "value": 33097,
                "label": "Champagnac",
                "label_ar": "شامبانياك",
                "label_fr": "Champagnac"
            },
            {
                "value": 33098,
                "label": "La Forie",
                "label_ar": "لا فوري",
                "label_fr": "La Forie"
            },
            {
                "value": 33099,
                "label": "Auvers",
                "label_ar": "أوفيرس",
                "label_fr": "Auvers"
            },
            {
                "value": 33100,
                "label": "Malintrat",
                "label_ar": "مالينترات",
                "label_fr": "Malintrat"
            },
            {
                "value": 33101,
                "label": "Vorey",
                "label_ar": "فوري",
                "label_fr": "Vorey"
            },
            {
                "value": 33102,
                "label": "Aigueperse",
                "label_ar": "ايجويرس",
                "label_fr": "Aigueperse"
            },
            {
                "value": 33103,
                "label": "Chateldon",
                "label_ar": "شاتيلدون",
                "label_fr": "Châteldon"
            },
            {
                "value": 33104,
                "label": "Viplaix",
                "label_ar": "فيبليكس",
                "label_fr": "Viplaix"
            },
            {
                "value": 33105,
                "label": "Desertines",
                "label_ar": "ديزرتينز",
                "label_fr": "Désertines"
            },
            {
                "value": 33106,
                "label": "Saint-Jeures",
                "label_ar": "سان جوريس",
                "label_fr": "Saint-Jeures"
            },
            {
                "value": 33107,
                "label": "Beon",
                "label_ar": "يكون على",
                "label_fr": "Être sur"
            },
            {
                "value": 33108,
                "label": "Pessat-Villeneuve",
                "label_ar": "بيسات فيلنوف",
                "label_fr": "Pessat-Villeneuve"
            },
            {
                "value": 33109,
                "label": "Olloix",
                "label_ar": "أولويكس",
                "label_fr": "Olloix"
            },
            {
                "value": 33110,
                "label": "Saint-Illide",
                "label_ar": "سانت إيليد",
                "label_fr": "Saint-Illide"
            },
            {
                "value": 33111,
                "label": "Thuret",
                "label_ar": "ثورت",
                "label_fr": "Thuret"
            },
            {
                "value": 33112,
                "label": "Trizac",
                "label_ar": "تريزاك",
                "label_fr": "Trizac"
            },
            {
                "value": 33113,
                "label": "Souvigny",
                "label_ar": "سوفيني",
                "label_fr": "Souvigny"
            },
            {
                "value": 33114,
                "label": "Lyaud",
                "label_ar": "ليود",
                "label_fr": "Lyaud"
            },
            {
                "value": 33115,
                "label": "Herisson",
                "label_ar": "هيريسون",
                "label_fr": "Hérisson"
            },
            {
                "value": 33116,
                "label": "La Bourboule",
                "label_ar": "لابوربول",
                "label_fr": "La Bourboule"
            },
            {
                "value": 33117,
                "label": "Solignac-sur-Loire",
                "label_ar": "Solignac-sur-Loire",
                "label_fr": "Solignac-sur-Loire"
            },
            {
                "value": 33118,
                "label": "Saint-Offenge-Dessous",
                "label_ar": "سان أوفنجي ديسوس",
                "label_fr": "Saint-Offenge-Dessous"
            },
            {
                "value": 33119,
                "label": "Menat",
                "label_ar": "مينات",
                "label_fr": "Menat"
            },
            {
                "value": 33120,
                "label": "Pralognan-la-Vanoise",
                "label_ar": "Pralognan-la-Vanoise",
                "label_fr": "Pralognan-la-Vanoise"
            },
            {
                "value": 33121,
                "label": "Chapeiry",
                "label_ar": "شبيري",
                "label_fr": "Chapeiry"
            },
            {
                "value": 33122,
                "label": "Armoy",
                "label_ar": "أرموي",
                "label_fr": "Armoy"
            },
            {
                "value": 33123,
                "label": "Saint-Bonnet-les-Allier",
                "label_ar": "Saint-Bonnet-les-Allier",
                "label_fr": "Saint-Bonnet-les-Allier"
            },
            {
                "value": 33124,
                "label": "Ollieres",
                "label_ar": "أوليريس",
                "label_fr": "Ollières"
            },
            {
                "value": 33125,
                "label": "Juvigny",
                "label_ar": "جوفيني",
                "label_fr": "Juvigny"
            },
            {
                "value": 33126,
                "label": "Toulouzette",
                "label_ar": "تولوزيت",
                "label_fr": "Toulouzette"
            },
            {
                "value": 33127,
                "label": "Saint-Paul",
                "label_ar": "القديس بول",
                "label_fr": "Saint Paul"
            },
            {
                "value": 33128,
                "label": "Laurede",
                "label_ar": "لوريدي",
                "label_fr": "Laurede"
            },
            {
                "value": 33129,
                "label": "Courlay",
                "label_ar": "كورلاي",
                "label_fr": "Courlay"
            },
            {
                "value": 33130,
                "label": "Villejesus",
                "label_ar": "فيليخيسوس",
                "label_fr": "Villejesus"
            },
            {
                "value": 33131,
                "label": "Chamberet",
                "label_ar": "شامبيت",
                "label_fr": "Chamberet"
            },
            {
                "value": 33132,
                "label": "Vieux-Boucau-les-Bains",
                "label_ar": "Vieux-Boucau-les-Bains",
                "label_fr": "Vieux-Boucau-les-Bains"
            },
            {
                "value": 33133,
                "label": "Atur",
                "label_ar": "أتور",
                "label_fr": "Atur"
            },
            {
                "value": 33134,
                "label": "Castelmoron-sur-Lot",
                "label_ar": "Castelmoron-sur-Lot",
                "label_fr": "Castelmoron-sur-Lot"
            },
            {
                "value": 33135,
                "label": "Saint-Amand-de-Belves",
                "label_ar": "سان اماند دي بيلفيس",
                "label_fr": "Saint-Amand-de-Belves"
            },
            {
                "value": 33136,
                "label": "Maurens",
                "label_ar": "مورينز",
                "label_fr": "Maurens"
            },
            {
                "value": 33137,
                "label": "Saint-Michel-de-Rieufret",
                "label_ar": "سان ميشيل دي ريوفريه",
                "label_fr": "Saint-Michel-de-Rieufret"
            },
            {
                "value": 33138,
                "label": "Riberac",
                "label_ar": "ريبيراك",
                "label_fr": "Ribérac"
            },
            {
                "value": 33139,
                "label": "Antagnac",
                "label_ar": "أنتانياك",
                "label_fr": "Antagnac"
            },
            {
                "value": 33140,
                "label": "Segalas",
                "label_ar": "سيغالاس",
                "label_fr": "Segalas"
            },
            {
                "value": 33141,
                "label": "Moulin-Neuf",
                "label_ar": "مولين نوف",
                "label_fr": "Moulin-Neuf"
            },
            {
                "value": 33142,
                "label": "Anglade",
                "label_ar": "أنجليد",
                "label_fr": "Anglade"
            },
            {
                "value": 33143,
                "label": "Les Eglises-d'Argenteuil",
                "label_ar": "ليه إجليس دارجينتويل",
                "label_fr": "Les Eglises-d'Argenteuil"
            },
            {
                "value": 33144,
                "label": "Faye-l'Abbesse",
                "label_ar": "فاي لابيسي",
                "label_fr": "Faye-l'Abbesse"
            },
            {
                "value": 33145,
                "label": "Tecou",
                "label_ar": "تيكو",
                "label_fr": "Tecou"
            },
            {
                "value": 33146,
                "label": "els Banys de la Presta",
                "label_ar": "إلس بانيز دي لا بريستا",
                "label_fr": "els Banys de la Presta"
            },
            {
                "value": 33147,
                "label": "Saint-Geours-d'Auribat",
                "label_ar": "Saint-Geours-d'Auribat",
                "label_fr": "Saint-Geours-d'Auribat"
            },
            {
                "value": 33148,
                "label": "Abere",
                "label_ar": "أبير",
                "label_fr": "Abere"
            },
            {
                "value": 33149,
                "label": "Lit-et-Mixe",
                "label_ar": "Lit-et-Mixe",
                "label_fr": "Lit-et-Mixe"
            },
            {
                "value": 33150,
                "label": "Villefranche-du-Queyran",
                "label_ar": "فيلفرانش دو كيران",
                "label_fr": "Villefranche-du-Queyran"
            },
            {
                "value": 33151,
                "label": "Lussac-les-Chateaux",
                "label_ar": "لوساك لي شاتو",
                "label_fr": "Lussac-les-Châteaux"
            },
            {
                "value": 33152,
                "label": "Eybouleuf",
                "label_ar": "Eybouleuf",
                "label_fr": "Eybouleuf"
            },
            {
                "value": 33153,
                "label": "Gardonne",
                "label_ar": "جاردون",
                "label_fr": "Gardonne"
            },
            {
                "value": 33154,
                "label": "Trie-sur-Baise",
                "label_ar": "تري سور بايز",
                "label_fr": "Trie-sur-Baïse"
            },
            {
                "value": 33155,
                "label": "Virelade",
                "label_ar": "فيريلاد",
                "label_fr": "Virelade"
            },
            {
                "value": 33156,
                "label": "Corgnac-sur-lIsle",
                "label_ar": "كورجناك سور ليل",
                "label_fr": "Corgnac-sur-lIsle"
            },
            {
                "value": 33157,
                "label": "Chenaud",
                "label_ar": "شينود",
                "label_fr": "Chenaud"
            },
            {
                "value": 33158,
                "label": "Abzac",
                "label_ar": "أبزاك",
                "label_fr": "Abzac"
            },
            {
                "value": 33159,
                "label": "Saint-Julien-de-Lampon",
                "label_ar": "سان جوليان دي لامبون",
                "label_fr": "Saint-Julien-de-Lampon"
            },
            {
                "value": 33160,
                "label": "Cameyrac",
                "label_ar": "كاميراك",
                "label_fr": "Cameyrac"
            },
            {
                "value": 33161,
                "label": "Candresse",
                "label_ar": "كاندريس",
                "label_fr": "Candresse"
            },
            {
                "value": 33162,
                "label": "Monferran-Saves",
                "label_ar": "مونفييران يحفظ",
                "label_fr": "Monferran-sauve"
            },
            {
                "value": 33163,
                "label": "Magnan",
                "label_ar": "ماجنان",
                "label_fr": "Magnan"
            },
            {
                "value": 33164,
                "label": "Beyrede-Jumet",
                "label_ar": "بيريد جوميت",
                "label_fr": "Beyrede-Jumet"
            },
            {
                "value": 33165,
                "label": "Bascons",
                "label_ar": "الباسكون",
                "label_fr": "Bascons"
            },
            {
                "value": 33166,
                "label": "Thorigny",
                "label_ar": "ثوريني",
                "label_fr": "Thorigny"
            },
            {
                "value": 33167,
                "label": "Bouin",
                "label_ar": "بوين",
                "label_fr": "Bouin"
            },
            {
                "value": 33168,
                "label": "Lavardin",
                "label_ar": "لافاردين",
                "label_fr": "Lavardin"
            },
            {
                "value": 33169,
                "label": "Bouere",
                "label_ar": "بوير",
                "label_fr": "Bouere"
            },
            {
                "value": 33170,
                "label": "La Chapelle-Heulin",
                "label_ar": "لا شابيل هيولين",
                "label_fr": "La Chapelle-Heulin"
            },
            {
                "value": 33171,
                "label": "Andreze",
                "label_ar": "أندريزي",
                "label_fr": "Andreze"
            },
            {
                "value": 33172,
                "label": "La Plaine",
                "label_ar": "لا بلين",
                "label_fr": "La Plaine"
            },
            {
                "value": 33173,
                "label": "Le Mesnil-en-Vallee",
                "label_ar": "لو ميسنيل أون فاليه",
                "label_fr": "Le Mesnil-en-Vallée"
            },
            {
                "value": 33174,
                "label": "Champtoce-sur-Loire",
                "label_ar": "Champtoce-sur-Loire",
                "label_fr": "Champtoce-sur-Loire"
            },
            {
                "value": 33175,
                "label": "Souzay-Champigny",
                "label_ar": "سوزاي شامبيني",
                "label_fr": "Souzay-Champigny"
            },
            {
                "value": 33176,
                "label": "Le Marillais",
                "label_ar": "لو ماريليه",
                "label_fr": "Le Marillais"
            },
            {
                "value": 33177,
                "label": "Mansigne",
                "label_ar": "مانسين",
                "label_fr": "Mansigne"
            },
            {
                "value": 33178,
                "label": "Saint-Martin-du-Bois",
                "label_ar": "سان مارتن دو بوا",
                "label_fr": "Saint-Martin-du-Bois"
            },
            {
                "value": 33179,
                "label": "Saint-Remy-la-Varenne",
                "label_ar": "سانت ريمي لا فارين",
                "label_fr": "Saint-Rémy-la-Varenne"
            },
            {
                "value": 33180,
                "label": "La Chataigneraie",
                "label_ar": "لا شاتينيري",
                "label_fr": "La Châtaigneraie"
            },
            {
                "value": 33181,
                "label": "Bel Air",
                "label_ar": "بيل اير",
                "label_fr": "bel Air"
            },
            {
                "value": 33182,
                "label": "Trange",
                "label_ar": "ترانج",
                "label_fr": "Trange"
            },
            {
                "value": 33183,
                "label": "Rablay-sur-Layon",
                "label_ar": "ربلاي سور لايون",
                "label_fr": "Rablay-sur-Layon"
            },
            {
                "value": 33184,
                "label": "La Marche",
                "label_ar": "لا مارش",
                "label_fr": "La Marche"
            },
            {
                "value": 33185,
                "label": "Astille",
                "label_ar": "أستيل",
                "label_fr": "Astille"
            },
            {
                "value": 33186,
                "label": "Vendrennes",
                "label_ar": "فيندرينيس",
                "label_fr": "Vendrennes"
            },
            {
                "value": 33187,
                "label": "Serigne",
                "label_ar": "سيرين",
                "label_fr": "Serigne"
            },
            {
                "value": 33188,
                "label": "Corze",
                "label_ar": "كورز",
                "label_fr": "Corze"
            },
            {
                "value": 33189,
                "label": "Rammersmatt",
                "label_ar": "راميرسمات",
                "label_fr": "Rammersmatt"
            },
            {
                "value": 33190,
                "label": "Vantoux",
                "label_ar": "فانتوكس",
                "label_fr": "Vantoux"
            },
            {
                "value": 33191,
                "label": "Avril",
                "label_ar": "أفريل",
                "label_fr": "Avril"
            },
            {
                "value": 33192,
                "label": "Soing-Cubry-Charentenay",
                "label_ar": "Soing-Cubry-Charentenay",
                "label_fr": "Soing-Cubry-Charentenay"
            },
            {
                "value": 33193,
                "label": "Maux",
                "label_ar": "ماوكس",
                "label_fr": "Maux"
            },
            {
                "value": 33194,
                "label": "Bierry-les-Belles-Fontaines",
                "label_ar": "بيري ليه بيل فونتين",
                "label_fr": "Bierry-les-Belles-Fontaines"
            },
            {
                "value": 33195,
                "label": "Heiteren",
                "label_ar": "هيترين",
                "label_fr": "Heiteren"
            },
            {
                "value": 33196,
                "label": "Poilly-sur-Tholon",
                "label_ar": "بويلي سور ثولون",
                "label_fr": "Poilly-sur-Tholon"
            },
            {
                "value": 33197,
                "label": "Griselles",
                "label_ar": "جريسيليس",
                "label_fr": "Griselles"
            },
            {
                "value": 33198,
                "label": "Cessey",
                "label_ar": "سيسي",
                "label_fr": "Cessey"
            },
            {
                "value": 33199,
                "label": "Bosjean",
                "label_ar": "Bosjean",
                "label_fr": "Bosjean"
            },
            {
                "value": 33200,
                "label": "Villevallier",
                "label_ar": "فيليفالييه",
                "label_fr": "Villevallier"
            },
            {
                "value": 33201,
                "label": "Magny-les-Villers",
                "label_ar": "ماجني ليه فيلرز",
                "label_fr": "Magny-les-Villers"
            },
            {
                "value": 33202,
                "label": "Sommerviller",
                "label_ar": "سومرفيلر",
                "label_fr": "Sommerviller"
            },
            {
                "value": 33203,
                "label": "Champey-sur-Moselle",
                "label_ar": "شامبي سور موسيل",
                "label_fr": "Champey-sur-Moselle"
            },
            {
                "value": 33204,
                "label": "Fains-Veel",
                "label_ar": "فينس فيل",
                "label_fr": "Fains-Veel"
            },
            {
                "value": 33205,
                "label": "Champenoux",
                "label_ar": "شامبينو",
                "label_fr": "Champenoux"
            },
            {
                "value": 33206,
                "label": "Waldweistroff",
                "label_ar": "والدويستروف",
                "label_fr": "Waldweistroff"
            },
            {
                "value": 33207,
                "label": "Mondelange",
                "label_ar": "مونديلانج",
                "label_fr": "Mondelange"
            },
            {
                "value": 33208,
                "label": "Molesme",
                "label_ar": "مولسمي",
                "label_fr": "Molesme"
            },
            {
                "value": 33209,
                "label": "Dornes",
                "label_ar": "دورنس",
                "label_fr": "Dornes"
            },
            {
                "value": 33210,
                "label": "Dompierre-les-Ormes",
                "label_ar": "دومبير ليه أورمس",
                "label_fr": "Dompierre-les-Ormes"
            },
            {
                "value": 33211,
                "label": "Vernierfontaine",
                "label_ar": "فيرنيرفونتين",
                "label_fr": "Vernierfontaine"
            },
            {
                "value": 33212,
                "label": "Soissons-sur-Nacey",
                "label_ar": "سواسون سور ناسي",
                "label_fr": "Soissons-sur-Nacey"
            },
            {
                "value": 33213,
                "label": "Matzenheim",
                "label_ar": "ماتزينهايم",
                "label_fr": "Matzenheim"
            },
            {
                "value": 33214,
                "label": "Maidieres",
                "label_ar": "خادمات",
                "label_fr": "Maidières"
            },
            {
                "value": 33215,
                "label": "Rurange-les-Thionville",
                "label_ar": "رورانج ليه ثيونفيل",
                "label_fr": "Rurange-les-Thionville"
            },
            {
                "value": 33216,
                "label": "Bellefontaine",
                "label_ar": "بلفونتين",
                "label_fr": "Bellefontaine"
            },
            {
                "value": 33217,
                "label": "Chambley-Bussieres",
                "label_ar": "شامبلي بوسيريس",
                "label_fr": "Chambley-Bussières"
            },
            {
                "value": 33218,
                "label": "Ruffey-les-Echirey",
                "label_ar": "روفي ليه إشيري",
                "label_fr": "Ruffey-les-Echirey"
            },
            {
                "value": 33219,
                "label": "Courgis",
                "label_ar": "كورجيس",
                "label_fr": "Courgis"
            },
            {
                "value": 33220,
                "label": "Lenoncourt",
                "label_ar": "لينونكور",
                "label_fr": "Lenoncourt"
            },
            {
                "value": 33221,
                "label": "Villers",
                "label_ar": "القرويون",
                "label_fr": "Villers"
            },
            {
                "value": 33222,
                "label": "Plombieres-les-Bains",
                "label_ar": "Plombieres-les-Bains",
                "label_fr": "Plombières-les-Bains"
            },
            {
                "value": 33223,
                "label": "Foameix-Ornel",
                "label_ar": "Foameix-Ornel",
                "label_fr": "Foameix-Ornel"
            },
            {
                "value": 33224,
                "label": "Manonviller",
                "label_ar": "مانونفيلر",
                "label_fr": "Manonviller"
            },
            {
                "value": 33225,
                "label": "Hultehouse",
                "label_ar": "هولت هاوس",
                "label_fr": "Hultehouse"
            },
            {
                "value": 33226,
                "label": "Crusnes",
                "label_ar": "كروسنيس",
                "label_fr": "Crusnes"
            },
            {
                "value": 33227,
                "label": "Bocquegney",
                "label_ar": "بوكيجني",
                "label_fr": "Bocquegney"
            },
            {
                "value": 33228,
                "label": "Corgoloin",
                "label_ar": "كورجولين",
                "label_fr": "Corgoloin"
            },
            {
                "value": 33229,
                "label": "Vincelles",
                "label_ar": "فينسيلس",
                "label_fr": "Vincelles"
            },
            {
                "value": 33230,
                "label": "Grozon",
                "label_ar": "جروزون",
                "label_fr": "Grozon"
            },
            {
                "value": 33231,
                "label": "Roye",
                "label_ar": "روي",
                "label_fr": "Roye"
            },
            {
                "value": 33232,
                "label": "Aze",
                "label_ar": "آزي",
                "label_fr": "Aze"
            },
            {
                "value": 33233,
                "label": "Echenans",
                "label_ar": "Echenans",
                "label_fr": "Echenans"
            },
            {
                "value": 33234,
                "label": "Ronno",
                "label_ar": "رونو",
                "label_fr": "Ronno"
            },
            {
                "value": 33235,
                "label": "Arlebosc",
                "label_ar": "Arlebosc",
                "label_fr": "Arlebosc"
            },
            {
                "value": 33236,
                "label": "Remuzat",
                "label_ar": "رموزات",
                "label_fr": "Remuzat"
            },
            {
                "value": 33237,
                "label": "Courzieu",
                "label_ar": "كورزيو",
                "label_fr": "Courzieu"
            },
            {
                "value": 33238,
                "label": "Chuzelles",
                "label_ar": "كوزيل",
                "label_fr": "Chuzelles"
            },
            {
                "value": 33239,
                "label": "Peray",
                "label_ar": "بيري",
                "label_fr": "Peray"
            },
            {
                "value": 33240,
                "label": "Saint-Cyr-le-Chatoux",
                "label_ar": "سان سير لو شاتو",
                "label_fr": "Saint-Cyr-le-Chatoux"
            },
            {
                "value": 33241,
                "label": "Violay",
                "label_ar": "فيولاي",
                "label_fr": "Violay"
            },
            {
                "value": 33242,
                "label": "Lurcy",
                "label_ar": "لورسي",
                "label_fr": "Lurcy"
            },
            {
                "value": 33243,
                "label": "Sarras",
                "label_ar": "صرص",
                "label_fr": "Sarras"
            },
            {
                "value": 33244,
                "label": "Chabrillan",
                "label_ar": "شابريلان",
                "label_fr": "Chabrillan"
            },
            {
                "value": 33245,
                "label": "Albon-d'Ardeche",
                "label_ar": "Albon-d'Ardeche",
                "label_fr": "Albon-d'Ardèche"
            },
            {
                "value": 33246,
                "label": "Dompierre-sur-Veyle",
                "label_ar": "دومبيير سور فيل",
                "label_fr": "Dompierre-sur-Veyle"
            },
            {
                "value": 33247,
                "label": "Rozier-en-Donzy",
                "label_ar": "روزير أون دونزي",
                "label_fr": "Rozier-en-Donzy"
            },
            {
                "value": 33248,
                "label": "Marols",
                "label_ar": "مارول",
                "label_fr": "Marols"
            },
            {
                "value": 33249,
                "label": "Saint-Bonnet-le-Chateau",
                "label_ar": "سان بونيه لو شاتو",
                "label_fr": "Saint-Bonnet-le-Château"
            },
            {
                "value": 33250,
                "label": "Ville-sous-Anjou",
                "label_ar": "فيل سوس أنجو",
                "label_fr": "Ville-sous-Anjou"
            },
            {
                "value": 33251,
                "label": "Saint-Andre-en-Vivarais",
                "label_ar": "سان أندريه أون فيفاري",
                "label_fr": "Saint-André-en-Vivarais"
            },
            {
                "value": 33252,
                "label": "Saint-Laurent-d'Agny",
                "label_ar": "سان لوران داجني",
                "label_fr": "Saint-Laurent-d'Agny"
            },
            {
                "value": 33253,
                "label": "Champdieu",
                "label_ar": "Champdieu",
                "label_fr": "Champdieu"
            },
            {
                "value": 33254,
                "label": "Saint-Clair-sur-Galaure",
                "label_ar": "سان كلير سور جالاور",
                "label_fr": "Saint-Clair-sur-Galaure"
            },
            {
                "value": 33255,
                "label": "Chambonas",
                "label_ar": "شامبوناس",
                "label_fr": "Chambonas"
            },
            {
                "value": 33256,
                "label": "Lezigneux",
                "label_ar": "ليزينيو",
                "label_fr": "Lézigneux"
            },
            {
                "value": 33257,
                "label": "Saint-Sixte",
                "label_ar": "سانت سيكست",
                "label_fr": "Saint-Sixte"
            },
            {
                "value": 33258,
                "label": "Groissiat",
                "label_ar": "جروسيات",
                "label_fr": "Groissiat"
            },
            {
                "value": 33259,
                "label": "Vocance",
                "label_ar": "صوت",
                "label_fr": "Vocance"
            },
            {
                "value": 33260,
                "label": "Mirabel-aux-Baronnies",
                "label_ar": "ميرابيل-أوكس-بارونيس",
                "label_fr": "Mirabel-aux-Baronnies"
            },
            {
                "value": 33261,
                "label": "Saint-Denis-sur-Coise",
                "label_ar": "سان دوني سور كواز",
                "label_fr": "Saint-Denis-sur-Coise"
            },
            {
                "value": 33262,
                "label": "Les Etangs",
                "label_ar": "ليه إتانج",
                "label_fr": "Les Etangs"
            },
            {
                "value": 33263,
                "label": "Beauvene",
                "label_ar": "بوفين",
                "label_fr": "Beauvene"
            },
            {
                "value": 33264,
                "label": "Saint-Michel-de-Chabrillanoux",
                "label_ar": "سان ميشيل دي شابريلانو",
                "label_fr": "Saint-Michel-de-Chabrillanoux"
            },
            {
                "value": 33265,
                "label": "Chevalet-le-Haut",
                "label_ar": "شوفاليه لو أوت",
                "label_fr": "Chevalet-le-Haut"
            },
            {
                "value": 33266,
                "label": "Saint-Victor-sur-Rhins",
                "label_ar": "سان فيكتور سور رينز",
                "label_fr": "Saint-Victor-sur-Rhins"
            },
            {
                "value": 33267,
                "label": "Thoirette",
                "label_ar": "Thoirette",
                "label_fr": "Thoirette"
            },
            {
                "value": 33268,
                "label": "Genet",
                "label_ar": "جينيه",
                "label_fr": "Genet"
            },
            {
                "value": 33269,
                "label": "Versailleux",
                "label_ar": "فيرساي",
                "label_fr": "Versailleux"
            },
            {
                "value": 33270,
                "label": "Ancy",
                "label_ar": "انسي",
                "label_fr": "Ancy"
            },
            {
                "value": 33271,
                "label": "Sainte-Consorce",
                "label_ar": "سانت كونسورس",
                "label_fr": "Sainte-Consorce"
            },
            {
                "value": 33272,
                "label": "Merignat",
                "label_ar": "ميرينات",
                "label_fr": "Merignat"
            },
            {
                "value": 33273,
                "label": "Mours-Saint-Eusebe",
                "label_ar": "Mours-Saint-Eusebe",
                "label_fr": "Mours-Saint-Eusebe"
            },
            {
                "value": 33274,
                "label": "Chalencon",
                "label_ar": "تشالينكون",
                "label_fr": "Chalencon"
            },
            {
                "value": 33275,
                "label": "Saint-Romain-les-Atheux",
                "label_ar": "Saint-Romain-les-Atheux",
                "label_fr": "Saint-Romain-les-Atheux"
            },
            {
                "value": 33276,
                "label": "Doissin",
                "label_ar": "دويسين",
                "label_fr": "Doissin"
            },
            {
                "value": 33277,
                "label": "Serezin-de-la-Tour",
                "label_ar": "سيريزين دي لا تور",
                "label_fr": "Serezin-de-la-Tour"
            },
            {
                "value": 33278,
                "label": "Saint-Georges-Haute-Ville",
                "label_ar": "سان جورج هوت فيل",
                "label_fr": "Saint-Georges-Haute-Ville"
            },
            {
                "value": 33279,
                "label": "Dargoire",
                "label_ar": "دارجوار",
                "label_fr": "Dargoire"
            },
            {
                "value": 33280,
                "label": "Saint-Andeol-de-Vals",
                "label_ar": "سان أندول دي فالس",
                "label_fr": "Saint-Andeol-de-Vals"
            },
            {
                "value": 33281,
                "label": "Berzeme",
                "label_ar": "بيرزيم",
                "label_fr": "Berzeme"
            },
            {
                "value": 33282,
                "label": "Quincie-en-Beaujolais",
                "label_ar": "كوينسي أون بوجوليه",
                "label_fr": "Quincie-en-Beaujolais"
            },
            {
                "value": 33283,
                "label": "Saint-Jean-des-Vignes",
                "label_ar": "سان جان دي فيجن",
                "label_fr": "Saint-Jean-des-Vignes"
            },
            {
                "value": 33284,
                "label": "Chozeau",
                "label_ar": "تشوزو",
                "label_fr": "Chozeau"
            },
            {
                "value": 33285,
                "label": "Perrex",
                "label_ar": "بيركس",
                "label_fr": "Perrex"
            },
            {
                "value": 33286,
                "label": "Birieux",
                "label_ar": "بيريوكس",
                "label_fr": "Birieux"
            },
            {
                "value": 33287,
                "label": "Saint-Genest-Malifaux",
                "label_ar": "سان جينست ماليفو",
                "label_fr": "Saint-Genest-Malifaux"
            },
            {
                "value": 33288,
                "label": "Jons",
                "label_ar": "جونز",
                "label_fr": "Jons"
            },
            {
                "value": 33289,
                "label": "Lapeyrouse-Mornay",
                "label_ar": "لابيروس مورني",
                "label_fr": "Lapeyrouse-Mornay"
            },
            {
                "value": 33290,
                "label": "Dareize",
                "label_ar": "تجرأ",
                "label_fr": "Oser"
            },
            {
                "value": 33291,
                "label": "Faverges-de-la-Tour",
                "label_ar": "فافرجيس دي لا تور",
                "label_fr": "Faverges-de-la-Tour"
            },
            {
                "value": 33292,
                "label": "Artas",
                "label_ar": "ارطاس",
                "label_fr": "Artas"
            },
            {
                "value": 33293,
                "label": "Malmont",
                "label_ar": "مالمونت",
                "label_fr": "Malmont"
            },
            {
                "value": 33294,
                "label": "Saint-Trivier-de-Courtes",
                "label_ar": "سان تريفير دي كورتيس",
                "label_fr": "Saint-Trivier-de-Courtes"
            },
            {
                "value": 33295,
                "label": "Saint-Pierre-de-Boeuf",
                "label_ar": "سان بيير دي بوف",
                "label_fr": "Saint-Pierre-de-Boeuf"
            },
            {
                "value": 33296,
                "label": "Vaulx-Milieu",
                "label_ar": "فولكس ميليو",
                "label_fr": "Vaulx-Milieu"
            },
            {
                "value": 33297,
                "label": "Romain",
                "label_ar": "رومان",
                "label_fr": "Romain"
            },
            {
                "value": 33298,
                "label": "Saint-Pierre-de-Bressieux",
                "label_ar": "سان بيير دي بريسيو",
                "label_fr": "Saint-Pierre-de-Bressieux"
            },
            {
                "value": 33299,
                "label": "Saint-Victor-sur-Loire",
                "label_ar": "سان فيكتور سور لوار",
                "label_fr": "Saint-Victor-sur-Loire"
            },
            {
                "value": 33300,
                "label": "Lent",
                "label_ar": "أقرض",
                "label_fr": "Prêté"
            },
            {
                "value": 33301,
                "label": "Tremons",
                "label_ar": "Tremons",
                "label_fr": "Tremons"
            },
            {
                "value": 33302,
                "label": "Neuvy-Bouin",
                "label_ar": "نيوفي بوين",
                "label_fr": "Neuvy-Bouin"
            },
            {
                "value": 33303,
                "label": "Celle-Levescault",
                "label_ar": "سيل ليفسكولت",
                "label_fr": "Celle-Levescault"
            },
            {
                "value": 33304,
                "label": "Marnes",
                "label_ar": "مارنز",
                "label_fr": "Marnes"
            },
            {
                "value": 33305,
                "label": "Doeuil-sur-le-Mignon",
                "label_ar": "Doeuil-sur-le-Mignon",
                "label_fr": "Doeuil-sur-le-Mignon"
            },
            {
                "value": 33306,
                "label": "Alloue",
                "label_ar": "ألوي",
                "label_fr": "Alloué"
            },
            {
                "value": 33307,
                "label": "Eyjeaux",
                "label_ar": "Eyjeaux",
                "label_fr": "Eyjeaux"
            },
            {
                "value": 33308,
                "label": "Adriers",
                "label_ar": "Adrier",
                "label_fr": "Adriers"
            },
            {
                "value": 33309,
                "label": "La Perche",
                "label_ar": "لابش",
                "label_fr": "La Perche"
            },
            {
                "value": 33310,
                "label": "Rouille",
                "label_ar": "رويل",
                "label_fr": "Rouille"
            },
            {
                "value": 33311,
                "label": "Saint-Maurice-la-Souterraine",
                "label_ar": "سان موريس لا سوترين",
                "label_fr": "Saint-Maurice-la-Souterraine"
            },
            {
                "value": 33312,
                "label": "Usson-du-Poitou",
                "label_ar": "أوسون دو بواتو",
                "label_fr": "Usson-du-Poitou"
            },
            {
                "value": 33313,
                "label": "Aureil",
                "label_ar": "أوريل",
                "label_fr": "Aureil"
            },
            {
                "value": 33314,
                "label": "Firmi",
                "label_ar": "شركة",
                "label_fr": "Firmi"
            },
            {
                "value": 33315,
                "label": "Frejairolles",
                "label_ar": "Frejairolles",
                "label_fr": "Frejairolles"
            },
            {
                "value": 33316,
                "label": "Villeneuve-de-Marsan",
                "label_ar": "فيلنوف دي مارسان",
                "label_fr": "Villeneuve-de-Marsan"
            },
            {
                "value": 33317,
                "label": "Le Porge",
                "label_ar": "لو بورغه",
                "label_fr": "Le Porge"
            },
            {
                "value": 33318,
                "label": "Epenede",
                "label_ar": "إبيندي",
                "label_fr": "Epenede"
            },
            {
                "value": 33319,
                "label": "Les Nouillers",
                "label_ar": "ليه نويلر",
                "label_fr": "Les Nouillers"
            },
            {
                "value": 33320,
                "label": "Payroux",
                "label_ar": "بايروكس",
                "label_fr": "Payroux"
            },
            {
                "value": 33321,
                "label": "Saint-Ciers-sur-Gironde",
                "label_ar": "سان سيرس سور جيروند",
                "label_fr": "Saint-Ciers-sur-Gironde"
            },
            {
                "value": 33322,
                "label": "Gironde-sur-Dropt",
                "label_ar": "جيروند سور دروب",
                "label_fr": "Gironde-sur-Dropt"
            },
            {
                "value": 33323,
                "label": "Bernay-Saint-Martin",
                "label_ar": "بيرناي سانت مارتن",
                "label_fr": "Bernay-Saint-Martin"
            },
            {
                "value": 33324,
                "label": "La Rochenard",
                "label_ar": "لاروشينارد",
                "label_fr": "La Rochenard"
            },
            {
                "value": 33325,
                "label": "Cissac-Medoc",
                "label_ar": "سيساك ميدوك",
                "label_fr": "Cissac-Médoc"
            },
            {
                "value": 33326,
                "label": "Gondeville",
                "label_ar": "جوندفيل",
                "label_fr": "Gondeville"
            },
            {
                "value": 33327,
                "label": "Oradour-Fanais",
                "label_ar": "أورادور فنايس",
                "label_fr": "Oradour-Fanais"
            },
            {
                "value": 33328,
                "label": "Chaban",
                "label_ar": "شابان",
                "label_fr": "Chaban"
            },
            {
                "value": 33329,
                "label": "Boutiers-Saint-Trojan",
                "label_ar": "بوتييه سان تروجان",
                "label_fr": "Boutiers-Saint-Trojan"
            },
            {
                "value": 33330,
                "label": "Montendre",
                "label_ar": "مونتيندر",
                "label_fr": "Montendre"
            },
            {
                "value": 33331,
                "label": "Chamboulive",
                "label_ar": "شامبوليف",
                "label_fr": "Chamboulive"
            },
            {
                "value": 33332,
                "label": "Soreze",
                "label_ar": "سوريز",
                "label_fr": "Soreze"
            },
            {
                "value": 33333,
                "label": "Brax",
                "label_ar": "براكس",
                "label_fr": "Brax"
            },
            {
                "value": 33334,
                "label": "Mercues",
                "label_ar": "ميركوس",
                "label_fr": "Mercues"
            },
            {
                "value": 33335,
                "label": "Noaillan",
                "label_ar": "نويلان",
                "label_fr": "Noaillan"
            },
            {
                "value": 33336,
                "label": "Verdelais",
                "label_ar": "فيرديليه",
                "label_fr": "Verdelais"
            },
            {
                "value": 33337,
                "label": "Vezac",
                "label_ar": "فيزاك",
                "label_fr": "Vezac"
            },
            {
                "value": 33338,
                "label": "Excideuil",
                "label_ar": "اكسيدويل",
                "label_fr": "Excideuil"
            },
            {
                "value": 33339,
                "label": "Saint-Pierre-du-Palais",
                "label_ar": "سان بيير دو باليه",
                "label_fr": "Saint-Pierre-du-Palais"
            },
            {
                "value": 33340,
                "label": "Orches",
                "label_ar": "الأورش",
                "label_fr": "Orches"
            },
            {
                "value": 33341,
                "label": "Grainville-sur-Odon",
                "label_ar": "غرينفيل سور أودون",
                "label_fr": "Grainville-sur-Odon"
            },
            {
                "value": 33342,
                "label": "Cesny-aux-Vignes",
                "label_ar": "Cesny-aux-Vignes",
                "label_fr": "Cesny-aux-Vignes"
            },
            {
                "value": 33343,
                "label": "Moyaux",
                "label_ar": "موياوكس",
                "label_fr": "Moyaux"
            },
            {
                "value": 33344,
                "label": "Marchainville",
                "label_ar": "مارشينفيل",
                "label_fr": "Marchainville"
            },
            {
                "value": 33345,
                "label": "Ronthon",
                "label_ar": "رونثون",
                "label_fr": "Ronthon"
            },
            {
                "value": 33346,
                "label": "Le Fresne-Camilly",
                "label_ar": "لو فرين كاميلي",
                "label_fr": "Le Fresne-Camilly"
            },
            {
                "value": 33347,
                "label": "Omonville-la-Rogue",
                "label_ar": "أومونفيل لا روغ",
                "label_fr": "Omonville-la-Rogue"
            },
            {
                "value": 33348,
                "label": "Eroudeville",
                "label_ar": "إرودفيل",
                "label_fr": "Eroudeville"
            },
            {
                "value": 33349,
                "label": "Pertheville-Ners",
                "label_ar": "بيرثفيل نيرس",
                "label_fr": "Pertheville-Ners"
            },
            {
                "value": 33350,
                "label": "Landelles-et-Coupigny",
                "label_ar": "Landelles-et-Coupigny",
                "label_fr": "Landelles-et-Coupigny"
            },
            {
                "value": 33351,
                "label": "Fontaine-Etoupefour",
                "label_ar": "فونتين إيتوبفور",
                "label_fr": "Fontaine-Etoupefour"
            },
            {
                "value": 33352,
                "label": "Gilles",
                "label_ar": "جيل",
                "label_fr": "Gilles"
            },
            {
                "value": 33353,
                "label": "Nouainville",
                "label_ar": "نوينفيل",
                "label_fr": "Nouainville"
            },
            {
                "value": 33354,
                "label": "Barbeville",
                "label_ar": "بارفيل",
                "label_fr": "Barbeville"
            },
            {
                "value": 33355,
                "label": "Treauville",
                "label_ar": "تروفيل",
                "label_fr": "Treauville"
            },
            {
                "value": 33356,
                "label": "Champs",
                "label_ar": "الأبطال",
                "label_fr": "Champs"
            },
            {
                "value": 33357,
                "label": "Saint-Pair-du-Mont",
                "label_ar": "Saint-Pair-du-Mont",
                "label_fr": "Saint-Pair-du-Mont"
            },
            {
                "value": 33358,
                "label": "Tollevast",
                "label_ar": "توليفاست",
                "label_fr": "Tollevast"
            },
            {
                "value": 33359,
                "label": "Auxais",
                "label_ar": "أوكسيس",
                "label_fr": "Auxais"
            },
            {
                "value": 33360,
                "label": "Vascœuil",
                "label_ar": "فاسكويل",
                "label_fr": "Vascœuil"
            },
            {
                "value": 33361,
                "label": "Fauville-en-Caux",
                "label_ar": "فوفيل أون كوكس",
                "label_fr": "Fauville-en-Caux"
            },
            {
                "value": 33362,
                "label": "Martigny",
                "label_ar": "Martigny",
                "label_fr": "Martigny"
            },
            {
                "value": 33363,
                "label": "Allouville-Bellefosse",
                "label_ar": "ألوفيل بلفوس",
                "label_fr": "Allouville-Bellefosse"
            },
            {
                "value": 33364,
                "label": "Saint-Jean-du-Cardonnay",
                "label_ar": "سان جان دو كاردونيه",
                "label_fr": "Saint-Jean-du-Cardonnay"
            },
            {
                "value": 33365,
                "label": "Reuil-sur-Breche",
                "label_ar": "Reuil-sur-Breche",
                "label_fr": "Reuil-sur-Brèche"
            },
            {
                "value": 33366,
                "label": "Ry",
                "label_ar": "راي",
                "label_fr": "Ry"
            },
            {
                "value": 33367,
                "label": "La Vaupaliere",
                "label_ar": "لا فوباليير",
                "label_fr": "La Vaupalière"
            },
            {
                "value": 33368,
                "label": "Cierrey",
                "label_ar": "سيري",
                "label_fr": "Cierrey"
            },
            {
                "value": 33369,
                "label": "Boulleville",
                "label_ar": "بوليفيل",
                "label_fr": "Boulleville"
            },
            {
                "value": 33370,
                "label": "Sotteville-sur-Mer",
                "label_ar": "سوتفيل سور مير",
                "label_fr": "Sotteville-sur-Mer"
            },
            {
                "value": 33371,
                "label": "Aignan",
                "label_ar": "ايجنان",
                "label_fr": "Aignan"
            },
            {
                "value": 33372,
                "label": "Tourville-la-Campagne",
                "label_ar": "تورفيل لا كامباني",
                "label_fr": "Tourville-la-Campagne"
            },
            {
                "value": 33373,
                "label": "Le Thuit-Simer",
                "label_ar": "لو ثويت سيمر",
                "label_fr": "Le Thuit-Simer"
            },
            {
                "value": 33374,
                "label": "Courcelles-les-Gisors",
                "label_ar": "Courcelles-les-Gisors",
                "label_fr": "Courcelles-les-Gisors"
            },
            {
                "value": 33375,
                "label": "Fontaine-Bonneleau",
                "label_ar": "فونتين بونيلو",
                "label_fr": "Fontaine-Bonneleau"
            },
            {
                "value": 33376,
                "label": "Cavillon",
                "label_ar": "كافيلون",
                "label_fr": "Cavillon"
            },
            {
                "value": 33377,
                "label": "Fontenelle-en-Brie",
                "label_ar": "Fontenelle-en-Brie",
                "label_fr": "Fontenelle-en-Brie"
            },
            {
                "value": 33378,
                "label": "Luchy",
                "label_ar": "لوشي",
                "label_fr": "Luchy"
            },
            {
                "value": 33379,
                "label": "Saint-Venant",
                "label_ar": "سانت فينانت",
                "label_fr": "Saint-Venant"
            },
            {
                "value": 33380,
                "label": "Huppy",
                "label_ar": "هوبي",
                "label_fr": "Huppy"
            },
            {
                "value": 33381,
                "label": "Guivry",
                "label_ar": "غيفري",
                "label_fr": "Guivry"
            },
            {
                "value": 33382,
                "label": "Bouzy",
                "label_ar": "بوزي",
                "label_fr": "Bouzy"
            },
            {
                "value": 33383,
                "label": "Aubrives",
                "label_ar": "أوبريفز",
                "label_fr": "Aubrives"
            },
            {
                "value": 33384,
                "label": "Fampoux",
                "label_ar": "فامبو",
                "label_fr": "Fampoux"
            },
            {
                "value": 33385,
                "label": "Sissy",
                "label_ar": "سيسي",
                "label_fr": "Poule mouillée"
            },
            {
                "value": 33386,
                "label": "Willencourt",
                "label_ar": "ويلنكور",
                "label_fr": "Willencourt"
            },
            {
                "value": 33387,
                "label": "Ecury-sur-Coole",
                "label_ar": "Ecury-sur-Coole",
                "label_fr": "Ecury-sur-Coole"
            },
            {
                "value": 33388,
                "label": "Fays-la-Chapelle",
                "label_ar": "Fays-la-Chapelle",
                "label_fr": "Fays-la-Chapelle"
            },
            {
                "value": 33389,
                "label": "Margny",
                "label_ar": "مارجني",
                "label_fr": "Margny"
            },
            {
                "value": 33390,
                "label": "Avancon",
                "label_ar": "أفانكون",
                "label_fr": "Avancon"
            },
            {
                "value": 33391,
                "label": "Bulles",
                "label_ar": "بولس",
                "label_fr": "Bulles"
            },
            {
                "value": 33392,
                "label": "Wailly",
                "label_ar": "نائبا",
                "label_fr": "Wailly"
            },
            {
                "value": 33393,
                "label": "Mailly-le-Camp",
                "label_ar": "مايلي لو كامب",
                "label_fr": "Mailly-le-Camp"
            },
            {
                "value": 33394,
                "label": "Ham-sur-Meuse",
                "label_ar": "هام سور ميوز",
                "label_fr": "Ham-sur-Meuse"
            },
            {
                "value": 33395,
                "label": "Ham-en-Artois",
                "label_ar": "هام أون أرتواز",
                "label_fr": "Ham-en-Artois"
            },
            {
                "value": 33396,
                "label": "Hestrus",
                "label_ar": "هيستروس",
                "label_fr": "Hestrus"
            },
            {
                "value": 33397,
                "label": "Bavincourt",
                "label_ar": "بافينكور",
                "label_fr": "Bavincourt"
            },
            {
                "value": 33398,
                "label": "Camblain-lAbbé",
                "label_ar": "كامبلين لابي",
                "label_fr": "Camblain-l'Abbé"
            },
            {
                "value": 33399,
                "label": "Dieval",
                "label_ar": "ديفال",
                "label_fr": "Dieval"
            },
            {
                "value": 33400,
                "label": "Saint-Germain-la-Ville",
                "label_ar": "سان جيرمان لا فيل",
                "label_fr": "Saint-Germain-la-Ville"
            },
            {
                "value": 33401,
                "label": "Perthes-les-Brienne",
                "label_ar": "بيرث ليه برين",
                "label_fr": "Perthes-les-Brienne"
            },
            {
                "value": 33402,
                "label": "Pont-Saint-Mard",
                "label_ar": "Pont-Saint-Mard",
                "label_fr": "Pont-Saint-Mard"
            },
            {
                "value": 33403,
                "label": "Croissy-sur-Celle",
                "label_ar": "كرواسي سور سيل",
                "label_fr": "Croissy-sur-Celle"
            },
            {
                "value": 33404,
                "label": "Saint-Gobain",
                "label_ar": "سان جوبان",
                "label_fr": "Saint Gobain"
            },
            {
                "value": 33405,
                "label": "Ercheu",
                "label_ar": "إرتشو",
                "label_fr": "Ercheu"
            },
            {
                "value": 33406,
                "label": "Chatillon-les-Sons",
                "label_ar": "Chatillon-les-Sons",
                "label_fr": "Châtillon-les-Fils"
            },
            {
                "value": 33407,
                "label": "Bosmont-sur-Serre",
                "label_ar": "بوسمونت سور سيري",
                "label_fr": "Bosmont-sur-Serre"
            },
            {
                "value": 33408,
                "label": "Jalons",
                "label_ar": "جالون",
                "label_fr": "Jalons"
            },
            {
                "value": 33409,
                "label": "Neuville-sur-Seine",
                "label_ar": "نيوفيل سور سين",
                "label_fr": "Neuville-sur-Seine"
            },
            {
                "value": 33410,
                "label": "Blagny",
                "label_ar": "بلاغني",
                "label_fr": "Blagny"
            },
            {
                "value": 33411,
                "label": "Les Mesneux",
                "label_ar": "ليه ميسنو",
                "label_fr": "Les Mesneux"
            },
            {
                "value": 33412,
                "label": "Mairy-sur-Marne",
                "label_ar": "ميري سور مارن",
                "label_fr": "Mairy-sur-Marne"
            },
            {
                "value": 33413,
                "label": "Vitry-en-Perthois",
                "label_ar": "فيتري أون بيرثوا",
                "label_fr": "Vitry-en-Perthois"
            },
            {
                "value": 33414,
                "label": "Avon-la-Peze",
                "label_ar": "أفون لا بيز",
                "label_fr": "Avon-la-Pèze"
            },
            {
                "value": 33415,
                "label": "Rethondes",
                "label_ar": "ريثونديس",
                "label_fr": "Rethondes"
            },
            {
                "value": 33416,
                "label": "Vieille-Eglise",
                "label_ar": "Vieille-Eglise",
                "label_fr": "Vieille-Eglise"
            },
            {
                "value": 33417,
                "label": "Sailly-sur-la-Lys",
                "label_ar": "Sailly-sur-la-Lys",
                "label_fr": "Sailly-sur-la-Lys"
            },
            {
                "value": 33418,
                "label": "Essoyes",
                "label_ar": "Essoyes",
                "label_fr": "Essoyes"
            },
            {
                "value": 33419,
                "label": "Aix-en-Othe",
                "label_ar": "Aix-en-Othe",
                "label_fr": "Aix-en-Othe"
            },
            {
                "value": 33420,
                "label": "Aubin-Saint-Vaast",
                "label_ar": "أوبين سانت فاست",
                "label_fr": "Aubin-Saint-Vaast"
            },
            {
                "value": 33421,
                "label": "Wambercourt",
                "label_ar": "وامبيركورت",
                "label_fr": "Wambercourt"
            },
            {
                "value": 33422,
                "label": "Fiefs",
                "label_ar": "الإقطاعيات",
                "label_fr": "Fiefs"
            },
            {
                "value": 33423,
                "label": "Acq",
                "label_ar": "حسابي",
                "label_fr": "Acq"
            },
            {
                "value": 33424,
                "label": "Gombergean",
                "label_ar": "جومبيرجين",
                "label_fr": "Gombergean"
            },
            {
                "value": 33425,
                "label": "Givraines",
                "label_ar": "جيفراينز",
                "label_fr": "Givraines"
            },
            {
                "value": 33426,
                "label": "Saint-Aignan-des-Noyers",
                "label_ar": "Saint-Aignan-des-Noyers",
                "label_fr": "Saint-Aignan-des-Noyers"
            },
            {
                "value": 33427,
                "label": "Trainou",
                "label_ar": "ترينو",
                "label_fr": "Trainou"
            },
            {
                "value": 33428,
                "label": "Chisseaux",
                "label_ar": "شيسو",
                "label_fr": "Chisseaux"
            },
            {
                "value": 33429,
                "label": "Saint-Christophe-sur-le-Nais",
                "label_ar": "سان كريستوف سور لو نايس",
                "label_fr": "Saint-Christophe-sur-le-Nais"
            },
            {
                "value": 33430,
                "label": "Menetreol-sur-Sauldre",
                "label_ar": "Menetreol-sur-Sauldre",
                "label_fr": "Ménétréol-sur-Sauldre"
            },
            {
                "value": 33431,
                "label": "Fougeres-sur-Bievre",
                "label_ar": "فوجي سور بيفر",
                "label_fr": "Fougères-sur-Bièvre"
            },
            {
                "value": 33432,
                "label": "Genille",
                "label_ar": "جينيل",
                "label_fr": "Genille"
            },
            {
                "value": 33433,
                "label": "Saint-Sigismond",
                "label_ar": "سانت سيجيسموند",
                "label_fr": "Saint-Sigismond"
            },
            {
                "value": 33434,
                "label": "Chenonceaux",
                "label_ar": "Chenonceaux",
                "label_fr": "Chenonceaux"
            },
            {
                "value": 33435,
                "label": "Sury-en-Vaux",
                "label_ar": "سوري أون فو",
                "label_fr": "Sury-en-Vaux"
            },
            {
                "value": 33436,
                "label": "Menestreau-en-Villette",
                "label_ar": "مينسترو أون فيليت",
                "label_fr": "Menestreau-en-Villette"
            },
            {
                "value": 33437,
                "label": "Dampierre-sur-Blevy",
                "label_ar": "دامبير سور بلفي",
                "label_fr": "Dampierre-sur-Blevy"
            },
            {
                "value": 33438,
                "label": "Ports",
                "label_ar": "الموانئ",
                "label_fr": "Les ports"
            },
            {
                "value": 33439,
                "label": "Saint-Georges-sur-Arnon",
                "label_ar": "سان جورج سور أرنون",
                "label_fr": "Saint-Georges-sur-Arnon"
            },
            {
                "value": 33440,
                "label": "Pruniers",
                "label_ar": "المقلم",
                "label_fr": "Sécateurs"
            },
            {
                "value": 33441,
                "label": "Marce-sur-Esves",
                "label_ar": "مارس سور إفسيس",
                "label_fr": "Marce-sur-Esves"
            },
            {
                "value": 33442,
                "label": "Molineuf",
                "label_ar": "مولينوف",
                "label_fr": "Molineuf"
            },
            {
                "value": 33443,
                "label": "Druye",
                "label_ar": "دروي",
                "label_fr": "Druye"
            },
            {
                "value": 33444,
                "label": "Montresor",
                "label_ar": "مونتريسور",
                "label_fr": "Montresor"
            },
            {
                "value": 33445,
                "label": "Vouvray",
                "label_ar": "فوفراي",
                "label_fr": "Vouvray"
            },
            {
                "value": 33446,
                "label": "Montierchaume",
                "label_ar": "مونتييرشوم",
                "label_fr": "Montierchaume"
            },
            {
                "value": 33447,
                "label": "Sassay",
                "label_ar": "ساساي",
                "label_fr": "Sassay"
            },
            {
                "value": 33448,
                "label": "Neuvy-en-Sullias",
                "label_ar": "نيوفي أون سولياس",
                "label_fr": "Neuvy-en-Sullias"
            },
            {
                "value": 33449,
                "label": "Gatelles",
                "label_ar": "جاتيل",
                "label_fr": "Gatelles"
            },
            {
                "value": 33450,
                "label": "Cormeray",
                "label_ar": "كورميري",
                "label_fr": "Cormeray"
            },
            {
                "value": 33451,
                "label": "Clemont",
                "label_ar": "كليمونت",
                "label_fr": "Clémont"
            },
            {
                "value": 33452,
                "label": "Verigny",
                "label_ar": "فيريجني",
                "label_fr": "Verigny"
            },
            {
                "value": 33453,
                "label": "Oizon",
                "label_ar": "ايزون",
                "label_fr": "Oizon"
            },
            {
                "value": 33454,
                "label": "Parnay",
                "label_ar": "بارني",
                "label_fr": "Parnay"
            },
            {
                "value": 33455,
                "label": "Sambin",
                "label_ar": "سامبين",
                "label_fr": "Sambin"
            },
            {
                "value": 33456,
                "label": "Levainville",
                "label_ar": "ليفينفيل",
                "label_fr": "Levainville"
            },
            {
                "value": 33457,
                "label": "Morancez",
                "label_ar": "مورانسز",
                "label_fr": "Morancez"
            },
            {
                "value": 33458,
                "label": "Belleville-sur-Loire",
                "label_ar": "بيلفيل سور لوار",
                "label_fr": "Belleville-sur-Loire"
            },
            {
                "value": 33459,
                "label": "Vert-en-Drouais",
                "label_ar": "فيرت أون درويس",
                "label_fr": "Vert-en-Drouais"
            },
            {
                "value": 33460,
                "label": "Villaines-les-Rochers",
                "label_ar": "Villaines-les-Rochers",
                "label_fr": "Villaines-les-Rochers"
            },
            {
                "value": 33461,
                "label": "Vimory",
                "label_ar": "فيموري",
                "label_fr": "Vimory"
            },
            {
                "value": 33462,
                "label": "Pouille",
                "label_ar": "بويل",
                "label_fr": "Pouille"
            },
            {
                "value": 33463,
                "label": "Mazange",
                "label_ar": "مازانج",
                "label_fr": "Mazange"
            },
            {
                "value": 33464,
                "label": "Luzille",
                "label_ar": "لوزيل",
                "label_fr": "Luzille"
            },
            {
                "value": 33465,
                "label": "Mont-pres-Chambord",
                "label_ar": "مونت بريش شامبورد",
                "label_fr": "Mont-près-Chambord"
            },
            {
                "value": 33466,
                "label": "Marcilly-sur-Vienne",
                "label_ar": "مارسيلي سور فيين",
                "label_fr": "Marcilly-sur-Vienne"
            },
            {
                "value": 33467,
                "label": "La Berthenoux",
                "label_ar": "لا بيرثينوكس",
                "label_fr": "La Berthenoux"
            },
            {
                "value": 33468,
                "label": "Pellevoisin",
                "label_ar": "بيليفوازين",
                "label_fr": "Pellevoisin"
            },
            {
                "value": 33469,
                "label": "Barrou",
                "label_ar": "بارو",
                "label_fr": "Barrou"
            },
            {
                "value": 33470,
                "label": "Montlivault",
                "label_ar": "مونتليفولت",
                "label_fr": "Montlivault"
            },
            {
                "value": 33471,
                "label": "Thenay",
                "label_ar": "آنواي",
                "label_fr": "Thenay"
            },
            {
                "value": 33472,
                "label": "Busloup",
                "label_ar": "بوسلوب",
                "label_fr": "Busloup"
            },
            {
                "value": 33473,
                "label": "Bossay-sur-Claise",
                "label_ar": "بوساي سور كليز",
                "label_fr": "Bossay-sur-Claise"
            },
            {
                "value": 33474,
                "label": "Villiers-au-Bouin",
                "label_ar": "فيلير أو بوين",
                "label_fr": "Villiers-au-Bouin"
            },
            {
                "value": 33475,
                "label": "Noyant-de-Touraine",
                "label_ar": "نويانت دي تورين",
                "label_fr": "Noyant-de-Touraine"
            },
            {
                "value": 33476,
                "label": "Courville-sur-Eure",
                "label_ar": "كورفيل سور يوري",
                "label_fr": "Courville-sur-Eure"
            },
            {
                "value": 33477,
                "label": "Braye-sous-Faye",
                "label_ar": "براي سوس فاي",
                "label_fr": "Braye-sous-Faye"
            },
            {
                "value": 33478,
                "label": "Sainte-Fauste",
                "label_ar": "سانت فاوست",
                "label_fr": "Sainte-Fauste"
            },
            {
                "value": 33479,
                "label": "Semoy",
                "label_ar": "سيموي",
                "label_fr": "Semoy"
            },
            {
                "value": 33480,
                "label": "Leouville",
                "label_ar": "لوفيل",
                "label_fr": "Léouville"
            },
            {
                "value": 33481,
                "label": "Janville",
                "label_ar": "جانفيل",
                "label_fr": "Janville"
            },
            {
                "value": 33482,
                "label": "Broue",
                "label_ar": "برو",
                "label_fr": "Broue"
            },
            {
                "value": 33483,
                "label": "Prunay-Cassereau",
                "label_ar": "بروناي كاسيرو",
                "label_fr": "Prunay-Cassereau"
            },
            {
                "value": 33484,
                "label": "Chedigny",
                "label_ar": "شيديني",
                "label_fr": "Chedigny"
            },
            {
                "value": 33485,
                "label": "Arrou",
                "label_ar": "عرو",
                "label_fr": "Arrou"
            },
            {
                "value": 33486,
                "label": "La Bussiere",
                "label_ar": "لا بوسير",
                "label_fr": "La Bussière"
            },
            {
                "value": 33487,
                "label": "Cellettes",
                "label_ar": "سيليتس",
                "label_fr": "Cellettes"
            },
            {
                "value": 33488,
                "label": "Fontaine-Simon",
                "label_ar": "فونتين سيمون",
                "label_fr": "Fontaine-Simon"
            },
            {
                "value": 33489,
                "label": "Villeneuve-Frouville",
                "label_ar": "فيلنوف فروفيل",
                "label_fr": "Villeneuve-Frouville"
            },
            {
                "value": 33490,
                "label": "Saint-Pellerin",
                "label_ar": "سانت بيليرين",
                "label_fr": "Saint-Pellerin"
            },
            {
                "value": 33491,
                "label": "Gy-en-Sologne",
                "label_ar": "جي إن سولونيا",
                "label_fr": "Gy-en-Sologne"
            },
            {
                "value": 33492,
                "label": "Huisseau-sur-Cosson",
                "label_ar": "هويسو سور كوسون",
                "label_fr": "Huisseau-sur-Cosson"
            },
            {
                "value": 33493,
                "label": "Villers-les-Ormes",
                "label_ar": "فيلير ليه أورمس",
                "label_fr": "Villers-les-Ormes"
            },
            {
                "value": 33494,
                "label": "Rouziers-de-Touraine",
                "label_ar": "Rouziers-de-Touraine",
                "label_fr": "Rouziers-de-Touraine"
            },
            {
                "value": 33495,
                "label": "Montainville",
                "label_ar": "مونتاينفيل",
                "label_fr": "Montainville"
            },
            {
                "value": 33496,
                "label": "Mezieres-en-Drouais",
                "label_ar": "Mezieres-en-Drouais",
                "label_fr": "Mézières-en-Drouais"
            },
            {
                "value": 33497,
                "label": "Saint-Flovier",
                "label_ar": "سانت فلوفير",
                "label_fr": "Saint-Flovier"
            },
            {
                "value": 33498,
                "label": "La Guerche",
                "label_ar": "لا جويرش",
                "label_fr": "La Guerche"
            },
            {
                "value": 33499,
                "label": "Pouzay",
                "label_ar": "بوزاي",
                "label_fr": "Pouzay"
            },
            {
                "value": 33500,
                "label": "Savigny-en-Septaine",
                "label_ar": "Savigny-en-Septaine",
                "label_fr": "Savigny-en-Septaine"
            },
            {
                "value": 33501,
                "label": "Epuisay",
                "label_ar": "Epuisay",
                "label_fr": "Epuisay"
            },
            {
                "value": 33502,
                "label": "Maron",
                "label_ar": "مارون",
                "label_fr": "Maron"
            },
            {
                "value": 33503,
                "label": "Courcay",
                "label_ar": "كوركاي",
                "label_fr": "Courcay"
            },
            {
                "value": 33504,
                "label": "Manthelan",
                "label_ar": "مانثيلان",
                "label_fr": "Manthelan"
            },
            {
                "value": 33505,
                "label": "Yermenonville",
                "label_ar": "ييرمينونفيل",
                "label_fr": "Yermenonville"
            },
            {
                "value": 33506,
                "label": "La Celle",
                "label_ar": "لا سيل",
                "label_fr": "La Celle"
            },
            {
                "value": 33507,
                "label": "Saint-Firmin-des-Pres",
                "label_ar": "سان فيرمين دي بري",
                "label_fr": "Saint-Firmin-des-Prés"
            },
            {
                "value": 33508,
                "label": "Santranges",
                "label_ar": "سانترانج",
                "label_fr": "Santranges"
            },
            {
                "value": 33509,
                "label": "Ennordres",
                "label_ar": "إينوردريس",
                "label_fr": "Ennordres"
            },
            {
                "value": 33510,
                "label": "Jeu-les-Bois",
                "label_ar": "جو ليه بوا",
                "label_fr": "Jeu-les-Bois"
            },
            {
                "value": 33511,
                "label": "Neuvy-le-Roi",
                "label_ar": "نيوفي لو روي",
                "label_fr": "Neuvy-le-Roi"
            },
            {
                "value": 33512,
                "label": "Souvigny-en-Sologne",
                "label_ar": "سوفيني أون سولونيا",
                "label_fr": "Souvigny-en-Sologne"
            },
            {
                "value": 33513,
                "label": "Ouarville",
                "label_ar": "ورفيل",
                "label_fr": "Ouarville"
            },
            {
                "value": 33514,
                "label": "Montlouis",
                "label_ar": "مونتلو",
                "label_fr": "Montlouis"
            },
            {
                "value": 33515,
                "label": "Loche-sur-Indrois",
                "label_ar": "Loche-sur-Indrois",
                "label_fr": "Loche-sur-Indrois"
            },
            {
                "value": 33516,
                "label": "Onzain",
                "label_ar": "اونزين",
                "label_fr": "Onzain"
            },
            {
                "value": 33517,
                "label": "Moulins-sur-Cephons",
                "label_ar": "مولين سور سيفون",
                "label_fr": "Moulins-sur-Cephons"
            },
            {
                "value": 33518,
                "label": "Savonnieres",
                "label_ar": "سافونيريس",
                "label_fr": "Savonnières"
            },
            {
                "value": 33519,
                "label": "Chataincourt",
                "label_ar": "شاتينكورت",
                "label_fr": "Chataincourt"
            },
            {
                "value": 33520,
                "label": "Theillay",
                "label_ar": "تلاي",
                "label_fr": "Theillay"
            },
            {
                "value": 33521,
                "label": "Chaillac",
                "label_ar": "شيلاك",
                "label_fr": "Chaillac"
            },
            {
                "value": 33522,
                "label": "Argent-sur-Sauldre",
                "label_ar": "أرجينت سور سولدر",
                "label_fr": "Argent-sur-Sauldre"
            },
            {
                "value": 33523,
                "label": "Thimory",
                "label_ar": "ثيموري",
                "label_fr": "Thimory"
            },
            {
                "value": 33524,
                "label": "Conflans-sur-Loing",
                "label_ar": "Conflans-sur-Loing",
                "label_fr": "Conflans-sur-Loing"
            },
            {
                "value": 33525,
                "label": "Lury-sur-Arnon",
                "label_ar": "لوري سور أرنون",
                "label_fr": "Lury-sur-Arnon"
            },
            {
                "value": 33526,
                "label": "Ervauville",
                "label_ar": "إرفوفيل",
                "label_fr": "Ervauville"
            },
            {
                "value": 33527,
                "label": "Josnes",
                "label_ar": "جوسنس",
                "label_fr": "Josnes"
            },
            {
                "value": 33528,
                "label": "Valaire",
                "label_ar": "فالير",
                "label_fr": "Valaire"
            },
            {
                "value": 33529,
                "label": "Monthou-sur-Bievre",
                "label_ar": "مونثو سور بيفري",
                "label_fr": "Monthou-sur-Bièvre"
            },
            {
                "value": 33530,
                "label": "Pressigny-les-Pins",
                "label_ar": "بريسيني ليه بين",
                "label_fr": "Pressigny-les-Pins"
            },
            {
                "value": 33531,
                "label": "Vennecy",
                "label_ar": "فينسى",
                "label_fr": "Vennecy"
            },
            {
                "value": 33532,
                "label": "Aunay-sous-Auneau",
                "label_ar": "أوني-سوس-أونو",
                "label_fr": "Aunay-sous-Auneau"
            },
            {
                "value": 33533,
                "label": "Cuffy",
                "label_ar": "كافي",
                "label_fr": "Cuffy"
            },
            {
                "value": 33534,
                "label": "La Celle",
                "label_ar": "لا سيل",
                "label_fr": "La Celle"
            },
            {
                "value": 33535,
                "label": "Niherne",
                "label_ar": "نيهرين",
                "label_fr": "Niherne"
            },
            {
                "value": 33536,
                "label": "Ternay",
                "label_ar": "تيرناي",
                "label_fr": "Ternay"
            },
            {
                "value": 33537,
                "label": "Tour-en-Sologne",
                "label_ar": "جولة في سولونيا",
                "label_fr": "Tour-en-Sologne"
            },
            {
                "value": 33538,
                "label": "Mur-de-Sologne",
                "label_ar": "مور دي سولونيا",
                "label_fr": "Mur-de-Sologne"
            },
            {
                "value": 33539,
                "label": "Chantome",
                "label_ar": "شانتوم",
                "label_fr": "Chantome"
            },
            {
                "value": 33540,
                "label": "Cangey",
                "label_ar": "كانجي",
                "label_fr": "Cangey"
            },
            {
                "value": 33541,
                "label": "Prouais",
                "label_ar": "برويس",
                "label_fr": "Prouais"
            },
            {
                "value": 33542,
                "label": "Touchay",
                "label_ar": "توشي",
                "label_fr": "Touchay"
            },
            {
                "value": 33543,
                "label": "Bricy",
                "label_ar": "بريسي",
                "label_fr": "Bricy"
            },
            {
                "value": 33544,
                "label": "Valencay",
                "label_ar": "فالنساي",
                "label_fr": "Valençay"
            },
            {
                "value": 33545,
                "label": "Montigny",
                "label_ar": "مونتيني",
                "label_fr": "Montigny"
            },
            {
                "value": 33546,
                "label": "Les Montils",
                "label_ar": "ليه مونتيلس",
                "label_fr": "Les Montils"
            },
            {
                "value": 33547,
                "label": "Chanceaux-sur-Choisille",
                "label_ar": "Chanceaux-sur-Choisille",
                "label_fr": "Chanceaux-sur-Choisille"
            },
            {
                "value": 33548,
                "label": "Saint-Bohaire",
                "label_ar": "سان بوهير",
                "label_fr": "Saint-Bohaire"
            },
            {
                "value": 33549,
                "label": "Belleville-en-Caux",
                "label_ar": "بيلفيل إن كو",
                "label_fr": "Belleville-en-Caux"
            },
            {
                "value": 33550,
                "label": "Vandrimare",
                "label_ar": "فاندريمير",
                "label_fr": "Vandrimare"
            },
            {
                "value": 33551,
                "label": "Senneville-sur-Fecamp",
                "label_ar": "سينفيل سور فيكامب",
                "label_fr": "Senneville-sur-Fécamp"
            },
            {
                "value": 33552,
                "label": "Saint-Leger-du-Bourg-Denis",
                "label_ar": "سان ليجيه دو بور دينيس",
                "label_fr": "Saint-Léger-du-Bourg-Denis"
            },
            {
                "value": 33553,
                "label": "Fleury-sur-Andelle",
                "label_ar": "فلوري سور أنديل",
                "label_fr": "Fleury-sur-Andelle"
            },
            {
                "value": 33554,
                "label": "Saint-Martin-du-Tilleul",
                "label_ar": "سان مارتن دو تيلول",
                "label_fr": "Saint-Martin-du-Tilleul"
            },
            {
                "value": 33555,
                "label": "Aumale",
                "label_ar": "أومالي",
                "label_fr": "Aumale"
            },
            {
                "value": 33556,
                "label": "Jumièges",
                "label_ar": "جوميج",
                "label_fr": "Jumièges"
            },
            {
                "value": 33557,
                "label": "Bouville",
                "label_ar": "بوفيل",
                "label_fr": "Bouville"
            },
            {
                "value": 33558,
                "label": "Biville-sur-Mer",
                "label_ar": "بيفيل سور مير",
                "label_fr": "Biville-sur-Mer"
            },
            {
                "value": 33559,
                "label": "Croix-Mare",
                "label_ar": "كروا ماري",
                "label_fr": "Croix-Mare"
            },
            {
                "value": 33560,
                "label": "Ecaquelon",
                "label_ar": "إيكويلون",
                "label_fr": "Ecaquelon"
            },
            {
                "value": 33561,
                "label": "Ecalles-Alix",
                "label_ar": "إيكاليس أليكس",
                "label_fr": "Ecalles-Alix"
            },
            {
                "value": 33562,
                "label": "Tocqueville-en-Caux",
                "label_ar": "توكفيل أون كو",
                "label_fr": "Tocqueville-en-Caux"
            },
            {
                "value": 33563,
                "label": "Bacqueville-en-Caux",
                "label_ar": "باكفيل أون كو",
                "label_fr": "Bacqueville-en-Caux"
            },
            {
                "value": 33564,
                "label": "Bondeville",
                "label_ar": "بوندفيل",
                "label_fr": "Bondeville"
            },
            {
                "value": 33565,
                "label": "Thierville",
                "label_ar": "ثيرفيل",
                "label_fr": "Thierville"
            },
            {
                "value": 33566,
                "label": "Saint-Ouen-du-Breuil",
                "label_ar": "Saint-Ouen-du-Breuil",
                "label_fr": "Saint-Ouen-du-Breuil"
            },
            {
                "value": 33567,
                "label": "Theuville-aux-Maillots",
                "label_ar": "ثيوفيل أوكس مايو",
                "label_fr": "Theuville-aux-Maillots"
            },
            {
                "value": 33568,
                "label": "Criquetot-l'Esneval",
                "label_ar": "Criquetot-l'Esneval",
                "label_fr": "Criquetot-l'Esneval"
            },
            {
                "value": 33569,
                "label": "Boissey-le-Chatel",
                "label_ar": "Boissey-le-Chatel",
                "label_fr": "Boissey-le-Châtel"
            },
            {
                "value": 33570,
                "label": "Villez-sous-Bailleul",
                "label_ar": "فيليز سوس بيلول",
                "label_fr": "Villez-sous-Bailleul"
            },
            {
                "value": 33571,
                "label": "Saint-Martin-du-Bosc",
                "label_ar": "سان مارتن دو بوس",
                "label_fr": "Saint-Martin-du-Bosc"
            },
            {
                "value": 33572,
                "label": "Vaux-sur-Eure",
                "label_ar": "فو سور أور",
                "label_fr": "Vaux-sur-Eure"
            },
            {
                "value": 33573,
                "label": "Huest",
                "label_ar": "هيست",
                "label_fr": "Huest"
            },
            {
                "value": 33574,
                "label": "Arques-la-Bataille",
                "label_ar": "Arques-la-Bataille",
                "label_fr": "Arques-la-Bataille"
            },
            {
                "value": 33575,
                "label": "Moulineaux",
                "label_ar": "مولينو",
                "label_fr": "Moulineaux"
            },
            {
                "value": 33576,
                "label": "Caumont",
                "label_ar": "كومونت",
                "label_fr": "Caumont"
            },
            {
                "value": 33577,
                "label": "Irreville",
                "label_ar": "ايرفيل",
                "label_fr": "Irreville"
            },
            {
                "value": 33578,
                "label": "Claville",
                "label_ar": "كلافيل",
                "label_fr": "Claville"
            },
            {
                "value": 33579,
                "label": "Le Landin",
                "label_ar": "لو لاندين",
                "label_fr": "Le Landin"
            },
            {
                "value": 33580,
                "label": "Prey",
                "label_ar": "فريسة",
                "label_fr": "Proie"
            },
            {
                "value": 33581,
                "label": "Harcanville",
                "label_ar": "هاركانفيل",
                "label_fr": "Harcanville"
            },
            {
                "value": 33582,
                "label": "Fatouville-Grestain",
                "label_ar": "فاتوفيل جريستين",
                "label_fr": "Fatouville-Grestain"
            },
            {
                "value": 33583,
                "label": "Orvaux",
                "label_ar": "أورفو",
                "label_fr": "Orvaux"
            },
            {
                "value": 33584,
                "label": "Le Roncenay-Authenay",
                "label_ar": "لو رونسيناي أوثيناي",
                "label_fr": "Le Roncenay-Authenay"
            },
            {
                "value": 33585,
                "label": "Authou",
                "label_ar": "Authou",
                "label_fr": "Authou"
            },
            {
                "value": 33586,
                "label": "Le Fidelaire",
                "label_ar": "لو فيديلير",
                "label_fr": "Le Fidelaire"
            },
            {
                "value": 33587,
                "label": "Motteville",
                "label_ar": "موتفيل",
                "label_fr": "Motteville"
            },
            {
                "value": 33588,
                "label": "Goupillieres",
                "label_ar": "Goupillieres",
                "label_fr": "Goupillières"
            },
            {
                "value": 33589,
                "label": "Colleville",
                "label_ar": "كوليفيل",
                "label_fr": "Colleville"
            },
            {
                "value": 33590,
                "label": "Broglie",
                "label_ar": "بروجلي",
                "label_fr": "Broglie"
            },
            {
                "value": 33591,
                "label": "Toussaint",
                "label_ar": "توسان",
                "label_fr": "Toussaint"
            },
            {
                "value": 33592,
                "label": "Campagne",
                "label_ar": "كامباني",
                "label_fr": "Campagne"
            },
            {
                "value": 33593,
                "label": "Avremesnil",
                "label_ar": "أفريمسنيل",
                "label_fr": "Avremesnil"
            },
            {
                "value": 33594,
                "label": "Quillebeuf-sur-Seine",
                "label_ar": "Quillebeuf-sur-Seine",
                "label_fr": "Quillebeuf-sur-Seine"
            },
            {
                "value": 33595,
                "label": "Bois-Anzeray",
                "label_ar": "Bois-Anzeray",
                "label_fr": "Bois-Anzeray"
            },
            {
                "value": 33596,
                "label": "Annouville-Vilmesnil",
                "label_ar": "أنوفيل فيلميسنيل",
                "label_fr": "Annouville-Vilmesnil"
            },
            {
                "value": 33597,
                "label": "Limesy",
                "label_ar": "ليميسي",
                "label_fr": "Limesy"
            },
            {
                "value": 33598,
                "label": "Saint-Georges-du-Vievre",
                "label_ar": "سان جورج دو فيفر",
                "label_fr": "Saint-Georges-du-Vievre"
            },
            {
                "value": 33599,
                "label": "Fontaine-sous-Jouy",
                "label_ar": "فونتين صوص جوي",
                "label_fr": "Fontaine-sous-Jouy"
            },
            {
                "value": 33600,
                "label": "Nointot",
                "label_ar": "Nointot",
                "label_fr": "Nointot"
            },
            {
                "value": 33601,
                "label": "Maneglise",
                "label_ar": "مانيجليز",
                "label_fr": "Maneglise"
            },
            {
                "value": 33602,
                "label": "Raffetot",
                "label_ar": "رافتوت",
                "label_fr": "Raffetot"
            },
            {
                "value": 33603,
                "label": "Guerville",
                "label_ar": "جيرفيل",
                "label_fr": "Guerville"
            },
            {
                "value": 33604,
                "label": "Avesnes-en-Bray",
                "label_ar": "أفيسنيس أون براي",
                "label_fr": "Avesnes-en-Bray"
            },
            {
                "value": 33605,
                "label": "Rouge-Perriers",
                "label_ar": "روج بيريرز",
                "label_fr": "Rouge-Perriers"
            },
            {
                "value": 33606,
                "label": "Beaurepaire",
                "label_ar": "بيوريباير",
                "label_fr": "Beaurepaire"
            },
            {
                "value": 33607,
                "label": "Saint-Etienne-du-Vauvray",
                "label_ar": "سانت إتيان دو فوفراي",
                "label_fr": "Saint-Étienne-du-Vauvray"
            },
            {
                "value": 33608,
                "label": "Saint-Martin-Osmonville",
                "label_ar": "سان مارتن أوسمونفيل",
                "label_fr": "Saint-Martin-Osmonville"
            },
            {
                "value": 33609,
                "label": "Heudicourt",
                "label_ar": "هيوديكورت",
                "label_fr": "Heudicourt"
            },
            {
                "value": 33610,
                "label": "Thiergeville",
                "label_ar": "ثيرجفيل",
                "label_fr": "Thiergeville"
            },
            {
                "value": 33611,
                "label": "Servaville-Salmonville",
                "label_ar": "سرفافيل سالمونفيل",
                "label_fr": "Servaville-Salmonville"
            },
            {
                "value": 33612,
                "label": "Eslettes",
                "label_ar": "إسليتس",
                "label_fr": "Eslettes"
            },
            {
                "value": 33613,
                "label": "Rosay-sur-Lieure",
                "label_ar": "روزي سور ليور",
                "label_fr": "Rosay-sur-Lieure"
            },
            {
                "value": 33614,
                "label": "Sahurs",
                "label_ar": "ساحورس",
                "label_fr": "Sahurs"
            },
            {
                "value": 33615,
                "label": "Clères",
                "label_ar": "كلير",
                "label_fr": "Clères"
            },
            {
                "value": 33616,
                "label": "Fontaine-la-Mallet",
                "label_ar": "فونتين لا ماليت",
                "label_fr": "Fontaine-la-Mallet"
            },
            {
                "value": 33617,
                "label": "La Trinite-de-Reville",
                "label_ar": "لا ترينيت دي ريفيل",
                "label_fr": "La Trinite-de-Reville"
            },
            {
                "value": 33618,
                "label": "Gaillefontaine",
                "label_ar": "جيلفونتين",
                "label_fr": "Gaillefontaine"
            },
            {
                "value": 33619,
                "label": "La Gueroulde",
                "label_ar": "لا غيرولد",
                "label_fr": "La Guéroulde"
            },
            {
                "value": 33620,
                "label": "Epaignes",
                "label_ar": "إيبينيس",
                "label_fr": "Epaignes"
            },
            {
                "value": 33621,
                "label": "Saint-Germain-sur-Avre",
                "label_ar": "سان جيرمان سور افر",
                "label_fr": "Saint-Germain-sur-Avre"
            },
            {
                "value": 33622,
                "label": "Marcouville",
                "label_ar": "ماركوفيل",
                "label_fr": "Marcouville"
            },
            {
                "value": 33623,
                "label": "Tourville-les-Ifs",
                "label_ar": "تورفيل ليه إفس",
                "label_fr": "Tourville-les-Ifs"
            },
            {
                "value": 33624,
                "label": "Bellencombre",
                "label_ar": "بيلينكومبر",
                "label_fr": "Bellencombre"
            },
            {
                "value": 33625,
                "label": "Folleville",
                "label_ar": "فوليفيل",
                "label_fr": "Folleville"
            },
            {
                "value": 33626,
                "label": "Lisors",
                "label_ar": "اللزقون",
                "label_fr": "Lisors"
            },
            {
                "value": 33627,
                "label": "Neuville-Ferrieres",
                "label_ar": "نيوفيل فيريرز",
                "label_fr": "Neuville-Ferrières"
            },
            {
                "value": 33628,
                "label": "Couargues",
                "label_ar": "كارج",
                "label_fr": "Couargues"
            },
            {
                "value": 33629,
                "label": "Allouis",
                "label_ar": "اللويص",
                "label_fr": "Allouis"
            },
            {
                "value": 33630,
                "label": "Lassay-sur-Croisne",
                "label_ar": "لاساي سور كروازن",
                "label_fr": "Lassay-sur-Croisne"
            },
            {
                "value": 33631,
                "label": "Champoulet",
                "label_ar": "شامبوليت",
                "label_fr": "Champoulet"
            },
            {
                "value": 33632,
                "label": "Auxy",
                "label_ar": "اوكسي",
                "label_fr": "Auxy"
            },
            {
                "value": 33633,
                "label": "Crouy-sur-Cosson",
                "label_ar": "كروي سور كوسون",
                "label_fr": "Crouy-sur-Cosson"
            },
            {
                "value": 33634,
                "label": "Selommes",
                "label_ar": "سيلوميس",
                "label_fr": "Selommes"
            },
            {
                "value": 33635,
                "label": "Barville-en-Gâtinais",
                "label_ar": "Barville-en-Gâtinais",
                "label_fr": "Barville-en-Gâtinais"
            },
            {
                "value": 33636,
                "label": "Villeperdue",
                "label_ar": "فيليبيردو",
                "label_fr": "Villeperdue"
            },
            {
                "value": 33637,
                "label": "Saint-Laurent-en-Gatines",
                "label_ar": "سان لوران إن جاتينز",
                "label_fr": "Saint-Laurent-en-Gatines"
            },
            {
                "value": 33638,
                "label": "Cormery",
                "label_ar": "كورميري",
                "label_fr": "Cormery"
            },
            {
                "value": 33639,
                "label": "Poce-sur-Cisse",
                "label_ar": "Poce-sur-Cisse",
                "label_fr": "Poce-sur-Cisse"
            },
            {
                "value": 33640,
                "label": "Jalognes",
                "label_ar": "جالونيس",
                "label_fr": "Jalognes"
            },
            {
                "value": 33641,
                "label": "Lugny-Champagne",
                "label_ar": "لوجني شامبين",
                "label_fr": "Lugny-Champagne"
            },
            {
                "value": 33642,
                "label": "Francourville",
                "label_ar": "فرانكورفيل",
                "label_fr": "Francourville"
            },
            {
                "value": 33643,
                "label": "Maille",
                "label_ar": "مايل",
                "label_fr": "Maille"
            },
            {
                "value": 33644,
                "label": "Souvigne",
                "label_ar": "سوفين",
                "label_fr": "Souvigne"
            },
            {
                "value": 33645,
                "label": "Chaudron-en-Mauges",
                "label_ar": "شودرون أون موج",
                "label_fr": "Chaudron-en-mauges"
            },
            {
                "value": 33646,
                "label": "Gontier",
                "label_ar": "جونتييه",
                "label_fr": "Gontier"
            },
            {
                "value": 33647,
                "label": "Malicorne-sur-Sarthe",
                "label_ar": "ماليكورن سور سارث",
                "label_fr": "Malicorne-sur-Sarthe"
            },
            {
                "value": 33648,
                "label": "Villaines-sous-Malicorne",
                "label_ar": "Villaines-sous-Malicorne",
                "label_fr": "Villaines-sous-Malicorne"
            },
            {
                "value": 33649,
                "label": "Lusanger",
                "label_ar": "لوسانجر",
                "label_fr": "Lusanger"
            },
            {
                "value": 33650,
                "label": "La Membrolle-sur-Longuenee",
                "label_ar": "لا Membrolle-sur-Longuenee",
                "label_fr": "La Membrolle-sur-Longuenee"
            },
            {
                "value": 33651,
                "label": "Grandchamps-des-Fontaines",
                "label_ar": "غراند تشامب دي فونتين",
                "label_fr": "Grandchamps-des-Fontaines"
            },
            {
                "value": 33652,
                "label": "Fay-de-Bretagne",
                "label_ar": "فاي دي بريتاني",
                "label_fr": "Fay-de-Bretagne"
            },
            {
                "value": 33653,
                "label": "Aze",
                "label_ar": "آزي",
                "label_fr": "Aze"
            },
            {
                "value": 33654,
                "label": "Pareds",
                "label_ar": "باريد",
                "label_fr": "Pareds"
            },
            {
                "value": 33655,
                "label": "Notre-Dame-des-Landes",
                "label_ar": "نوتردام دي لاند",
                "label_fr": "Notre-Dame-des-Landes"
            },
            {
                "value": 33656,
                "label": "Saint-Ouen-des-Toits",
                "label_ar": "Saint-Ouen-des-Toits",
                "label_fr": "Saint-Ouen-des-Toits"
            },
            {
                "value": 33657,
                "label": "La Tardiere",
                "label_ar": "لا تارديير",
                "label_fr": "La Tardière"
            },
            {
                "value": 33658,
                "label": "Le Bourgneuf-la-Foret",
                "label_ar": "لو بورجنوف لا فوريه",
                "label_fr": "Le Bourgneuf-la-Foret"
            },
            {
                "value": 33659,
                "label": "Daumeray",
                "label_ar": "دوميراي",
                "label_fr": "Daumeray"
            },
            {
                "value": 33660,
                "label": "Cherre",
                "label_ar": "شيري",
                "label_fr": "Cherre"
            },
            {
                "value": 33661,
                "label": "Saint-Germain-de-Princay",
                "label_ar": "سان جيرمان دي برينكاي",
                "label_fr": "Saint-Germain-de-Princay"
            },
            {
                "value": 33662,
                "label": "Le Coudray-Baillet",
                "label_ar": "لو كودراي بيليه",
                "label_fr": "Le Coudray-Baillet"
            },
            {
                "value": 33663,
                "label": "Le Plessis",
                "label_ar": "لو بليسيس",
                "label_fr": "Le Plessis"
            },
            {
                "value": 33664,
                "label": "Corpe",
                "label_ar": "كورب",
                "label_fr": "Corpe"
            },
            {
                "value": 33665,
                "label": "Tallud-Sainte-Gemme",
                "label_ar": "Tallud-Sainte-Gemme",
                "label_fr": "Tallud-Sainte-Gemme"
            },
            {
                "value": 33666,
                "label": "Le Champ-Saint-Pere",
                "label_ar": "لو شام سان بير",
                "label_fr": "Le Champ-Saint-Père"
            },
            {
                "value": 33667,
                "label": "Saint-Firmin",
                "label_ar": "سان فيرمين",
                "label_fr": "Saint-Firmin"
            },
            {
                "value": 33668,
                "label": "Revigny",
                "label_ar": "Revigny",
                "label_fr": "Revigny"
            },
            {
                "value": 33669,
                "label": "Feves",
                "label_ar": "فيفز",
                "label_fr": "Fèves"
            },
            {
                "value": 33670,
                "label": "Obermorschwiller",
                "label_ar": "أوبرمورشويلر",
                "label_fr": "Obermorschwiller"
            },
            {
                "value": 33671,
                "label": "Remelfang",
                "label_ar": "Remelfang",
                "label_fr": "Remelfang"
            },
            {
                "value": 33672,
                "label": "Badevel",
                "label_ar": "باديفيل",
                "label_fr": "Badevel"
            },
            {
                "value": 33673,
                "label": "Cravant",
                "label_ar": "شغف",
                "label_fr": "Cravant"
            },
            {
                "value": 33674,
                "label": "Traenheim",
                "label_ar": "ترينهايم",
                "label_fr": "Traenheim"
            },
            {
                "value": 33675,
                "label": "Mangiennes",
                "label_ar": "مانجين",
                "label_fr": "Mangiennes"
            },
            {
                "value": 33676,
                "label": "Ormes-et-Ville",
                "label_ar": "Ormes-et-Ville",
                "label_fr": "Ormes-et-Ville"
            },
            {
                "value": 33677,
                "label": "Thil",
                "label_ar": "ثيل",
                "label_fr": "Thil"
            },
            {
                "value": 33678,
                "label": "Rougegoutte",
                "label_ar": "Rougegoutte",
                "label_fr": "Rougegoutte"
            },
            {
                "value": 33679,
                "label": "Bonnevent-Velloreille",
                "label_ar": "Bonnevent-Velloreille",
                "label_fr": "Bonnevent-Velloreille"
            },
            {
                "value": 33680,
                "label": "Uhrwiller",
                "label_ar": "أورويلر",
                "label_fr": "Uhrwiller"
            },
            {
                "value": 33681,
                "label": "La Montagne",
                "label_ar": "لا مونتاني",
                "label_fr": "La Montagne"
            },
            {
                "value": 33682,
                "label": "Erize-Saint-Dizier",
                "label_ar": "إريز سان ديزييه",
                "label_fr": "Erize-Saint-Dizier"
            },
            {
                "value": 33683,
                "label": "Tincry",
                "label_ar": "متنكرا",
                "label_fr": "Tincry"
            },
            {
                "value": 33684,
                "label": "Ancemont",
                "label_ar": "أنسيمونت",
                "label_fr": "Ancemont"
            },
            {
                "value": 33685,
                "label": "Seuil",
                "label_ar": "سويل",
                "label_fr": "Seuil"
            },
            {
                "value": 33686,
                "label": "Vaudebarrier",
                "label_ar": "فوديبارييه",
                "label_fr": "Vaudebarrier"
            },
            {
                "value": 33687,
                "label": "Aiserey",
                "label_ar": "ايسيري",
                "label_fr": "Aiserey"
            },
            {
                "value": 33688,
                "label": "Guemar",
                "label_ar": "جمار",
                "label_fr": "Guemar"
            },
            {
                "value": 33689,
                "label": "Villiers-sur-Tholon",
                "label_ar": "فيلير سور ثولون",
                "label_fr": "Villiers-sur-Tholon"
            },
            {
                "value": 33690,
                "label": "Igney",
                "label_ar": "إيغني",
                "label_fr": "Igney"
            },
            {
                "value": 33691,
                "label": "Sassey-sur-Meuse",
                "label_ar": "ساسي سور ميوز",
                "label_fr": "Sassey-sur-Meuse"
            },
            {
                "value": 33692,
                "label": "Valff",
                "label_ar": "فالف",
                "label_fr": "Valff"
            },
            {
                "value": 33693,
                "label": "Dammarie-sur-Saulx",
                "label_ar": "Dammarie-sur-Saulx",
                "label_fr": "Dammarie-sur-Saulx"
            },
            {
                "value": 33694,
                "label": "La Neuveville-devant-Lepanges",
                "label_ar": "لا نيوففيل ديفانت ليبانج",
                "label_fr": "La Neuveville-devant-Lepanges"
            },
            {
                "value": 33695,
                "label": "Volmerange-les-Mines",
                "label_ar": "فولمرانج ليه ماينز",
                "label_fr": "Volmerange-les-Mines"
            },
            {
                "value": 33696,
                "label": "Bouligney",
                "label_ar": "بوليني",
                "label_fr": "Bouligney"
            },
            {
                "value": 33697,
                "label": "Chagey",
                "label_ar": "تشاجي",
                "label_fr": "Chagey"
            },
            {
                "value": 33698,
                "label": "Lans",
                "label_ar": "لانس",
                "label_fr": "Lans"
            },
            {
                "value": 33699,
                "label": "Eckwersheim",
                "label_ar": "إيكويرشيم",
                "label_fr": "Eckwersheim"
            },
            {
                "value": 33700,
                "label": "Étain",
                "label_ar": "استين",
                "label_fr": "Étain"
            },
            {
                "value": 33701,
                "label": "Sponville",
                "label_ar": "سبونفيل",
                "label_fr": "Sponville"
            },
            {
                "value": 33702,
                "label": "Ville-sur-Cousances",
                "label_ar": "فيل سور كوزانس",
                "label_fr": "Ville-sur-Cousances"
            },
            {
                "value": 33703,
                "label": "Tremont-sur-Saulx",
                "label_ar": "تريمونت سور سولكس",
                "label_fr": "Tremont-sur-Saulx"
            },
            {
                "value": 33704,
                "label": "Clermont",
                "label_ar": "كليرمونت",
                "label_fr": "Clermont"
            },
            {
                "value": 33705,
                "label": "Pers-Jussy",
                "label_ar": "بيرس جوسي",
                "label_fr": "Pers-Jussy"
            },
            {
                "value": 33706,
                "label": "Talloires",
                "label_ar": "Talloires",
                "label_fr": "Talloires"
            },
            {
                "value": 33707,
                "label": "Marlioz",
                "label_ar": "مارليوز",
                "label_fr": "Marlioz"
            },
            {
                "value": 33708,
                "label": "Les Avanchers-Valmorel",
                "label_ar": "ليه أفانشير فالموريل",
                "label_fr": "Les Avanchers-Valmorel"
            },
            {
                "value": 33709,
                "label": "Chezery-Forens",
                "label_ar": "Chezery-Forens",
                "label_fr": "Chezery-Forens"
            },
            {
                "value": 33710,
                "label": "Saint-Germain-sur-Rhone",
                "label_ar": "سان جيرمان سور رون",
                "label_fr": "Saint-Germain-sur-Rhône"
            },
            {
                "value": 33711,
                "label": "Aviernoz",
                "label_ar": "أفيرنوز",
                "label_fr": "Aviernoz"
            },
            {
                "value": 33712,
                "label": "Bernex",
                "label_ar": "بيرنيكس",
                "label_fr": "Bernex"
            },
            {
                "value": 33713,
                "label": "Arthaz-Pont-Notre-Dame",
                "label_ar": "أرثاز بونت نوتردام",
                "label_fr": "Arthaz-Pont-Notre-Dame"
            },
            {
                "value": 33714,
                "label": "Saint-Jean-de-Gonville",
                "label_ar": "سان جان دي جونفيل",
                "label_fr": "Saint-Jean-de-Gonville"
            },
            {
                "value": 33715,
                "label": "Verchaix",
                "label_ar": "فيرشايكس",
                "label_fr": "Verchaix"
            },
            {
                "value": 33716,
                "label": "Praz",
                "label_ar": "براز",
                "label_fr": "Praz"
            },
            {
                "value": 33717,
                "label": "Bellevue-la-Montagne",
                "label_ar": "بلفيو لا مونتاني",
                "label_fr": "Bellevue-la-Montagne"
            },
            {
                "value": 33718,
                "label": "Etrembieres",
                "label_ar": "إتريمبيريس",
                "label_fr": "Étrembières"
            },
            {
                "value": 33719,
                "label": "La Muraz",
                "label_ar": "لا موراز",
                "label_fr": "La Muraz"
            },
            {
                "value": 33720,
                "label": "Saint-Martin-Bellevue",
                "label_ar": "سان مارتن بلفيو",
                "label_fr": "Saint-Martin-Bellevue"
            },
            {
                "value": 33721,
                "label": "Esserts-Saleve",
                "label_ar": "Esserts-Saleve",
                "label_fr": "Esserts-Saleve"
            },
            {
                "value": 33722,
                "label": "Orcier",
                "label_ar": "أورسيه",
                "label_fr": "Orcier"
            },
            {
                "value": 33723,
                "label": "Mures",
                "label_ar": "موريس",
                "label_fr": "Mures"
            },
            {
                "value": 33724,
                "label": "Chatel",
                "label_ar": "شاتيل",
                "label_fr": "Châtel"
            },
            {
                "value": 33725,
                "label": "Copponex",
                "label_ar": "كوبونكس",
                "label_fr": "Copponex"
            },
            {
                "value": 33726,
                "label": "Scientrier",
                "label_ar": "ساينترير",
                "label_fr": "Scientifique"
            },
            {
                "value": 33727,
                "label": "Vacheresse",
                "label_ar": "فاشيريس",
                "label_fr": "Vacheresse"
            },
            {
                "value": 33728,
                "label": "Lovagny",
                "label_ar": "لوفاني",
                "label_fr": "Lovagny"
            },
            {
                "value": 33729,
                "label": "Arcine",
                "label_ar": "ارسين",
                "label_fr": "Arcine"
            },
            {
                "value": 33730,
                "label": "Mezeriat",
                "label_ar": "مزريات",
                "label_fr": "Mezeriat"
            },
            {
                "value": 33731,
                "label": "Les Houches",
                "label_ar": "ليه هوش",
                "label_fr": "Les Houches"
            },
            {
                "value": 33732,
                "label": "Sauve",
                "label_ar": "سوف",
                "label_fr": "Sauve"
            },
            {
                "value": 33733,
                "label": "Salses-le-Chateau",
                "label_ar": "Salses-le-Chateau",
                "label_fr": "Salses-le-Château"
            },
            {
                "value": 33734,
                "label": "Saint-Martin-le-Vieil",
                "label_ar": "سان مارتن لو فييل",
                "label_fr": "Saint-Martin-le-Vieil"
            },
            {
                "value": 33735,
                "label": "Serdinya",
                "label_ar": "سيردينيا",
                "label_fr": "Serdinya"
            },
            {
                "value": 33736,
                "label": "Souilhanels",
                "label_ar": "سويلهانيلس",
                "label_fr": "Souilhanels"
            },
            {
                "value": 33737,
                "label": "Saint-Hippolyte-de-Montaigu",
                "label_ar": "سان هيبوليت دي مونتايجو",
                "label_fr": "Saint-Hippolyte-de-Montaigu"
            },
            {
                "value": 33738,
                "label": "Gailhan",
                "label_ar": "جيلهان",
                "label_fr": "Gailhan"
            },
            {
                "value": 33739,
                "label": "Quissac",
                "label_ar": "كويساك",
                "label_fr": "Quissac"
            },
            {
                "value": 33740,
                "label": "Congenies",
                "label_ar": "موافقات",
                "label_fr": "Congénies"
            },
            {
                "value": 33741,
                "label": "Saissac",
                "label_ar": "سايساك",
                "label_fr": "Saissac"
            },
            {
                "value": 33742,
                "label": "La Rouviere",
                "label_ar": "لا روفيير",
                "label_fr": "La Rouvière"
            },
            {
                "value": 33743,
                "label": "Saint-Dezery",
                "label_ar": "سان دزري",
                "label_fr": "Saint-Dezery"
            },
            {
                "value": 33744,
                "label": "Maureilhan",
                "label_ar": "موريلهان",
                "label_fr": "Maureilhan"
            },
            {
                "value": 33745,
                "label": "Paraza",
                "label_ar": "بارازا",
                "label_fr": "Paraza"
            },
            {
                "value": 33746,
                "label": "Palalda",
                "label_ar": "بالدا",
                "label_fr": "Palalda"
            },
            {
                "value": 33747,
                "label": "Haute-Rivoire",
                "label_ar": "هوت ريفوار",
                "label_fr": "Haute-Rivoire"
            },
            {
                "value": 33748,
                "label": "Septeme",
                "label_ar": "سبتيم",
                "label_fr": "Septème"
            },
            {
                "value": 33749,
                "label": "Ambierle",
                "label_ar": "أمبيرل",
                "label_fr": "Ambierle"
            },
            {
                "value": 33750,
                "label": "Bouchet",
                "label_ar": "بوشيه",
                "label_fr": "Bouchet"
            },
            {
                "value": 33751,
                "label": "Echalas",
                "label_ar": "إيكالاس",
                "label_fr": "Echalas"
            },
            {
                "value": 33752,
                "label": "Mornay",
                "label_ar": "مورني",
                "label_fr": "Mornay"
            },
            {
                "value": 33753,
                "label": "Pommiers-la-Placette",
                "label_ar": "Pommiers-la-Placette",
                "label_fr": "Pommiers-la-Placette"
            },
            {
                "value": 33754,
                "label": "Oytier-Saint-Oblas",
                "label_ar": "أويتير سانت أوبلاس",
                "label_fr": "Oytier-Saint-Oblas"
            },
            {
                "value": 33755,
                "label": "Saint-Didier-sur-Chalaronne",
                "label_ar": "سان ديدييه سور شالارون",
                "label_fr": "Saint-Didier-sur-Chalaronne"
            },
            {
                "value": 33756,
                "label": "Leyrieu",
                "label_ar": "ليريو",
                "label_fr": "Leyrieu"
            },
            {
                "value": 33757,
                "label": "Cellieu",
                "label_ar": "تشيلو",
                "label_fr": "Cellieu"
            },
            {
                "value": 33758,
                "label": "Cezay",
                "label_ar": "سيزاي",
                "label_fr": "Cezay"
            },
            {
                "value": 33759,
                "label": "Saint-Bardoux",
                "label_ar": "سان باردو",
                "label_fr": "Saint-Bardoux"
            },
            {
                "value": 33760,
                "label": "Charnay",
                "label_ar": "تشارناي",
                "label_fr": "Charnay"
            },
            {
                "value": 33761,
                "label": "Satillieu",
                "label_ar": "ساتيلو",
                "label_fr": "Satillieu"
            },
            {
                "value": 33762,
                "label": "Genissieux",
                "label_ar": "جينيسيو",
                "label_fr": "Genissieux"
            },
            {
                "value": 33763,
                "label": "Montsegur-sur-Lauzon",
                "label_ar": "مونتسيغور سور لوزون",
                "label_fr": "Montségur-sur-Lauzon"
            },
            {
                "value": 33764,
                "label": "Grammond",
                "label_ar": "جراموند",
                "label_fr": "Grammond"
            },
            {
                "value": 33765,
                "label": "Chassagny",
                "label_ar": "شاساني",
                "label_fr": "Chassagny"
            },
            {
                "value": 33766,
                "label": "Montrevel",
                "label_ar": "مونتريفيل",
                "label_fr": "Montrevel"
            },
            {
                "value": 33767,
                "label": "Saint-Laurent-des-Combes",
                "label_ar": "سان لوران دي كومبس",
                "label_fr": "Saint-Laurent-des-Combes"
            },
            {
                "value": 33768,
                "label": "Fargues",
                "label_ar": "فارجيس",
                "label_fr": "Fargues"
            },
            {
                "value": 33769,
                "label": "Bougarber",
                "label_ar": "Bougarber",
                "label_fr": "Bougarber"
            },
            {
                "value": 33770,
                "label": "Ahaxe-Alciette-Bascassan",
                "label_ar": "أهاكسي ألكيت باسكاسان",
                "label_fr": "Ahaxe-Alciette-Bascassan"
            },
            {
                "value": 33771,
                "label": "Momas",
                "label_ar": "موماس",
                "label_fr": "Momas"
            },
            {
                "value": 33772,
                "label": "Castillon-Debats",
                "label_ar": "كاستيلون ديباتس",
                "label_fr": "Castillon-Debats"
            },
            {
                "value": 33773,
                "label": "Laparrouquial",
                "label_ar": "لاباروقيال",
                "label_fr": "Laparrouquial"
            },
            {
                "value": 33774,
                "label": "Cotdoussan",
                "label_ar": "كوتدوسان",
                "label_fr": "Cotdoussan"
            },
            {
                "value": 33775,
                "label": "Clarac",
                "label_ar": "كلارك",
                "label_fr": "Clarac"
            },
            {
                "value": 33776,
                "label": "Peyrissas",
                "label_ar": "بيريساس",
                "label_fr": "Peyrissas"
            },
            {
                "value": 33777,
                "label": "Saint-Generoux",
                "label_ar": "سان جينيرو",
                "label_fr": "Saint-Generoux"
            },
            {
                "value": 33778,
                "label": "Lahitte-Toupiere",
                "label_ar": "لاهيت توبير",
                "label_fr": "Lahitte-Toupière"
            },
            {
                "value": 33779,
                "label": "Saint-Jean-de-Lier",
                "label_ar": "سان جان دي لير",
                "label_fr": "Saint-Jean-de-Lier"
            },
            {
                "value": 33780,
                "label": "Berenx",
                "label_ar": "برنكس",
                "label_fr": "Berenx"
            },
            {
                "value": 33781,
                "label": "Saubusse",
                "label_ar": "سوبوس",
                "label_fr": "Saubusse"
            },
            {
                "value": 33782,
                "label": "Ossages",
                "label_ar": "العظمية",
                "label_fr": "Ossages"
            },
            {
                "value": 33783,
                "label": "Carsac-Aillac",
                "label_ar": "كارساك ايلاك",
                "label_fr": "Carsac-Aillac"
            },
            {
                "value": 33784,
                "label": "Captieux",
                "label_ar": "Captieux",
                "label_fr": "Captieux"
            },
            {
                "value": 33785,
                "label": "Belveze",
                "label_ar": "بيلفيز",
                "label_fr": "Belveze"
            },
            {
                "value": 33786,
                "label": "Gavarnie",
                "label_ar": "جافارني",
                "label_fr": "Gavarnie"
            },
            {
                "value": 33787,
                "label": "Bonrepos",
                "label_ar": "بونريبوس",
                "label_fr": "Bonrepos"
            },
            {
                "value": 33788,
                "label": "Denat",
                "label_ar": "دينات",
                "label_fr": "Denat"
            },
            {
                "value": 33789,
                "label": "Angoume",
                "label_ar": "أنغومي",
                "label_fr": "Angoumé"
            },
            {
                "value": 33790,
                "label": "Estillac",
                "label_ar": "إستيلاك",
                "label_fr": "Estillac"
            },
            {
                "value": 33791,
                "label": "Orban",
                "label_ar": "أوربان",
                "label_fr": "Orban"
            },
            {
                "value": 33792,
                "label": "Saint-Jean-de-Marcel",
                "label_ar": "سان جان دي مارسيل",
                "label_fr": "Saint-Jean-de-Marcel"
            },
            {
                "value": 33793,
                "label": "Saint-Ybars",
                "label_ar": "سان يبارس",
                "label_fr": "Saint-Ybars"
            },
            {
                "value": 33794,
                "label": "Levis",
                "label_ar": "ليفيس",
                "label_fr": "Lévi's"
            },
            {
                "value": 33795,
                "label": "Lux",
                "label_ar": "لوكس",
                "label_fr": "Lux"
            },
            {
                "value": 33796,
                "label": "Goyrans",
                "label_ar": "جويرانس",
                "label_fr": "Goyrans"
            },
            {
                "value": 33797,
                "label": "Ondes",
                "label_ar": "Ondes",
                "label_fr": "Ondes"
            },
            {
                "value": 33798,
                "label": "Cauzac",
                "label_ar": "كوزاك",
                "label_fr": "Cauzac"
            },
            {
                "value": 33799,
                "label": "Amou",
                "label_ar": "عمو",
                "label_fr": "Amou"
            },
            {
                "value": 33800,
                "label": "Saint-Avit",
                "label_ar": "سانت افيت",
                "label_fr": "Saint-Avit"
            },
            {
                "value": 33801,
                "label": "Eance",
                "label_ar": "إانس",
                "label_fr": "Eance"
            },
            {
                "value": 33802,
                "label": "Inguiniel",
                "label_ar": "إنجينيل",
                "label_fr": "Inguiniel"
            },
            {
                "value": 33803,
                "label": "Les Champs",
                "label_ar": "ليه تشامبس",
                "label_fr": "Les Champs"
            },
            {
                "value": 33804,
                "label": "Huelgoat",
                "label_ar": "Huelgoat",
                "label_fr": "Huelgoat"
            },
            {
                "value": 33805,
                "label": "Saint-Helen",
                "label_ar": "سانت هيلين",
                "label_fr": "Sainte-Hélène"
            },
            {
                "value": 33806,
                "label": "Saint-Guinoux",
                "label_ar": "سان جينو",
                "label_fr": "Saint-Guinoux"
            },
            {
                "value": 33807,
                "label": "Saint-Herve",
                "label_ar": "سانت هيرفيه",
                "label_fr": "Saint-Hervé"
            },
            {
                "value": 33808,
                "label": "Edern",
                "label_ar": "إديرن",
                "label_fr": "Edern"
            },
            {
                "value": 33809,
                "label": "Romille",
                "label_ar": "روميل",
                "label_fr": "Romille"
            },
            {
                "value": 33810,
                "label": "Plourivo",
                "label_ar": "بلوريفو",
                "label_fr": "Plourivo"
            },
            {
                "value": 33811,
                "label": "Cardroc",
                "label_ar": "كاردروك",
                "label_fr": "Cardroc"
            },
            {
                "value": 33812,
                "label": "Martigne-Ferchaud",
                "label_ar": "Martigne-Ferchaud",
                "label_fr": "Martigné-Ferchaud"
            },
            {
                "value": 33813,
                "label": "Saint-Pern",
                "label_ar": "سان بيرن",
                "label_fr": "Saint-Pern"
            },
            {
                "value": 33814,
                "label": "La Lande",
                "label_ar": "لا لاند",
                "label_fr": "La Lande"
            },
            {
                "value": 33815,
                "label": "Saint-Vincent-des-Landes",
                "label_ar": "سانت فنسنت دي لاند",
                "label_fr": "Saint-Vincent-des-Landes"
            },
            {
                "value": 33816,
                "label": "Perreux",
                "label_ar": "Perreux",
                "label_fr": "Perreux"
            },
            {
                "value": 33817,
                "label": "Langolen",
                "label_ar": "لانغولين",
                "label_fr": "Langolen"
            },
            {
                "value": 33818,
                "label": "Gennes-sur-Seiche",
                "label_ar": "جين سور سيش",
                "label_fr": "Gennes-sur-Seiche"
            },
            {
                "value": 33819,
                "label": "Meucon",
                "label_ar": "ميكون",
                "label_fr": "Meucon"
            },
            {
                "value": 33820,
                "label": "Kergloff",
                "label_ar": "كيرغلوف",
                "label_fr": "Kergloff"
            },
            {
                "value": 33821,
                "label": "Pleyber-Christ",
                "label_ar": "بليبر المسيح",
                "label_fr": "Pleyber-Christ"
            },
            {
                "value": 33822,
                "label": "Chanteloup",
                "label_ar": "شانتيلوب",
                "label_fr": "Chanteloup"
            },
            {
                "value": 33823,
                "label": "Ploeven",
                "label_ar": "بلوفن",
                "label_fr": "Ploeven"
            },
            {
                "value": 33824,
                "label": "Neulliac",
                "label_ar": "نيولاك",
                "label_fr": "Neulliac"
            },
            {
                "value": 33825,
                "label": "Monthureux-sur-Saone",
                "label_ar": "مونوريوكس سور ساون",
                "label_fr": "Monthureux-sur-Saône"
            },
            {
                "value": 33826,
                "label": "Chaumousey",
                "label_ar": "شوموسي",
                "label_fr": "Chaumousey"
            },
            {
                "value": 33827,
                "label": "Xertigny",
                "label_ar": "Xertigny",
                "label_fr": "Xertigny"
            },
            {
                "value": 33828,
                "label": "Genelard",
                "label_ar": "جينيلارد",
                "label_fr": "Genelard"
            },
            {
                "value": 33829,
                "label": "Sampigny-les-Maranges",
                "label_ar": "سامبيني ليه مارانج",
                "label_fr": "Sampigny-les-Maranges"
            },
            {
                "value": 33830,
                "label": "Saint-Aubin-en-Charollais",
                "label_ar": "سان أوبين أون شارولي",
                "label_fr": "Saint-Aubin-en-Charollais"
            },
            {
                "value": 33831,
                "label": "Jussey",
                "label_ar": "جوسي",
                "label_fr": "Jussey"
            },
            {
                "value": 33832,
                "label": "Hageville",
                "label_ar": "هاجفيل",
                "label_fr": "Hageville"
            },
            {
                "value": 33833,
                "label": "Chateau",
                "label_ar": "شاتو",
                "label_fr": "Château"
            },
            {
                "value": 33834,
                "label": "Schmittviller",
                "label_ar": "شميتفيلر",
                "label_fr": "Schmittviller"
            },
            {
                "value": 33835,
                "label": "Laneuveville-aux-Bois",
                "label_ar": "لينوففيل أوكس بوا",
                "label_fr": "Laneuveville-aux-Bois"
            },
            {
                "value": 33836,
                "label": "Lemainville",
                "label_ar": "ليمينفيل",
                "label_fr": "Lemainville"
            },
            {
                "value": 33837,
                "label": "Chambilly",
                "label_ar": "شامبيلي",
                "label_fr": "Chambilly"
            },
            {
                "value": 33838,
                "label": "Laignes",
                "label_ar": "لاين",
                "label_fr": "Laignes"
            },
            {
                "value": 33839,
                "label": "Roulans",
                "label_ar": "رولان",
                "label_fr": "Roulans"
            },
            {
                "value": 33840,
                "label": "Charquemont",
                "label_ar": "شاركيمونت",
                "label_fr": "Charquemont"
            },
            {
                "value": 33841,
                "label": "Hugier",
                "label_ar": "هوجير",
                "label_fr": "Hugier"
            },
            {
                "value": 33842,
                "label": "Pouilly-sur-Vingeanne",
                "label_ar": "Pouilly-sur-Vingeanne",
                "label_fr": "Pouilly-sur-Vingeanne"
            },
            {
                "value": 33843,
                "label": "Cubry-les-Soing",
                "label_ar": "كوبر ليه سوينج",
                "label_fr": "Cubry-les-Soing"
            },
            {
                "value": 33844,
                "label": "Saint-Saulge",
                "label_ar": "سان سولج",
                "label_fr": "Saint-Saulge"
            },
            {
                "value": 33845,
                "label": "Saint-Martin-en-Gatinois",
                "label_ar": "سان مارتن إن جاتينوي",
                "label_fr": "Saint-Martin-en-Gatinois"
            },
            {
                "value": 33846,
                "label": "Dambenois",
                "label_ar": "دامبينوا",
                "label_fr": "Dambenois"
            },
            {
                "value": 33847,
                "label": "Le Vaudioux",
                "label_ar": "لو فوديوكس",
                "label_fr": "Le Vaudioux"
            },
            {
                "value": 33848,
                "label": "Fretigney-et-Velloreille",
                "label_ar": "Fretigney-et-Velloreille",
                "label_fr": "Fretigney-et-Velloreille"
            },
            {
                "value": 33849,
                "label": "Pagny-la-Ville",
                "label_ar": "باجني لا فيل",
                "label_fr": "Pagny-la-Ville"
            },
            {
                "value": 33850,
                "label": "Les Villedieu",
                "label_ar": "ليه فيليو",
                "label_fr": "Les Villedieu"
            },
            {
                "value": 33851,
                "label": "Montfleur",
                "label_ar": "مونتفلور",
                "label_fr": "Montfleur"
            },
            {
                "value": 33852,
                "label": "Chissey-en-Morvan",
                "label_ar": "تشيسي أون مورفان",
                "label_fr": "Chissey-en-Morvan"
            },
            {
                "value": 33853,
                "label": "Ecleux",
                "label_ar": "إكليوكس",
                "label_fr": "Ecleux"
            },
            {
                "value": 33854,
                "label": "Palleau",
                "label_ar": "بالو",
                "label_fr": "Palleau"
            },
            {
                "value": 33855,
                "label": "Mouthe",
                "label_ar": "موث",
                "label_fr": "Mouthe"
            },
            {
                "value": 33856,
                "label": "Lechatelet",
                "label_ar": "ليتشاتليت",
                "label_fr": "Lechatelet"
            },
            {
                "value": 33857,
                "label": "Les Hays",
                "label_ar": "ليه هايز",
                "label_fr": "Les Hays"
            },
            {
                "value": 33858,
                "label": "Bussy-le-Grand",
                "label_ar": "بوسي لو جراند",
                "label_fr": "Bussy-le-Grand"
            },
            {
                "value": 33859,
                "label": "Ferrieres-les-Scey",
                "label_ar": "Ferrieres-les-Scey",
                "label_fr": "Ferrières-les-Scey"
            },
            {
                "value": 33860,
                "label": "Dracy-le-Fort",
                "label_ar": "دراسي لو فورت",
                "label_fr": "Dracy-le-Fort"
            },
            {
                "value": 33861,
                "label": "Ueberstrass",
                "label_ar": "أوبرشتراس",
                "label_fr": "Ueberstrass"
            },
            {
                "value": 33862,
                "label": "Le Tholy",
                "label_ar": "لو ثولي",
                "label_fr": "Le Tholy"
            },
            {
                "value": 33863,
                "label": "Provencheres-sur-Fave",
                "label_ar": "بروفينشيريس سور فاف",
                "label_fr": "Provencheres-sur-Fave"
            },
            {
                "value": 33864,
                "label": "Verze",
                "label_ar": "فيرز",
                "label_fr": "Verze"
            },
            {
                "value": 33865,
                "label": "Armes",
                "label_ar": "ارميس",
                "label_fr": "Armes"
            },
            {
                "value": 33866,
                "label": "Foulcrey",
                "label_ar": "فولكري",
                "label_fr": "Foulcrey"
            },
            {
                "value": 33867,
                "label": "Pugey",
                "label_ar": "بوجي",
                "label_fr": "Pugey"
            },
            {
                "value": 33868,
                "label": "Charbuy",
                "label_ar": "شارباي",
                "label_fr": "Charbuy"
            },
            {
                "value": 33869,
                "label": "Montrond",
                "label_ar": "مونتروند",
                "label_fr": "Montrond"
            },
            {
                "value": 33870,
                "label": "Faimbe",
                "label_ar": "فيمبي",
                "label_fr": "Faimbe"
            },
            {
                "value": 33871,
                "label": "Fresne-Saint-Mames",
                "label_ar": "فرين سان ماميس",
                "label_fr": "Fresne-Saint-Mames"
            },
            {
                "value": 33872,
                "label": "Orchamps-Vennes",
                "label_ar": "Orchamps-Vennes",
                "label_fr": "Orchamps-Vennes"
            },
            {
                "value": 33873,
                "label": "Roffey",
                "label_ar": "روفي",
                "label_fr": "Roffey"
            },
            {
                "value": 33874,
                "label": "Bonnetage",
                "label_ar": "بونيتاج",
                "label_fr": "Bonnetage"
            },
            {
                "value": 33875,
                "label": "Fuisse",
                "label_ar": "فويز",
                "label_fr": "Fuissé"
            },
            {
                "value": 33876,
                "label": "Corcelles-les-Monts",
                "label_ar": "Corcelles-les-Monts",
                "label_fr": "Corcelles-les-Monts"
            },
            {
                "value": 33877,
                "label": "Montessaux",
                "label_ar": "مونتساو",
                "label_fr": "Montessaux"
            },
            {
                "value": 33878,
                "label": "Supt",
                "label_ar": "سوبت",
                "label_fr": "Surint."
            },
            {
                "value": 33879,
                "label": "Merry-sur-Yonne",
                "label_ar": "ميري سور يون",
                "label_fr": "Merry-sur-Yonne"
            },
            {
                "value": 33880,
                "label": "Pleure",
                "label_ar": "الجن",
                "label_fr": "Pleure"
            },
            {
                "value": 33881,
                "label": "Lucenay-l'Eveque",
                "label_ar": "Lucenay-l'Eveque",
                "label_fr": "Lucenay-l'Évêque"
            },
            {
                "value": 33882,
                "label": "Ray-sur-Saone",
                "label_ar": "راي سور سون",
                "label_fr": "Ray-sur-Saône"
            },
            {
                "value": 33883,
                "label": "Verne",
                "label_ar": "فيرن",
                "label_fr": "Verne"
            },
            {
                "value": 33884,
                "label": "Saint-Denis-de-Vaux",
                "label_ar": "سان دوني دو فو",
                "label_fr": "Saint-Denis-de-Vaux"
            },
            {
                "value": 33885,
                "label": "Floyon",
                "label_ar": "فلويون",
                "label_fr": "Floyon"
            },
            {
                "value": 33886,
                "label": "Belmont",
                "label_ar": "بلمونت",
                "label_fr": "Belmont"
            },
            {
                "value": 33887,
                "label": "Campremy",
                "label_ar": "Campremy",
                "label_fr": "Campremy"
            },
            {
                "value": 33888,
                "label": "Vadencourt",
                "label_ar": "فادينكورت",
                "label_fr": "Vadencourt"
            },
            {
                "value": 33889,
                "label": "Monceau-le-Neuf-et-Faucouzy",
                "label_ar": "Monceau-le-Neuf-et-Faucouzy",
                "label_fr": "Monceau-le-Neuf-et-Faucouzy"
            },
            {
                "value": 33890,
                "label": "Viels-Maisons",
                "label_ar": "فيل ميزون",
                "label_fr": "Viels-Maisons"
            },
            {
                "value": 33891,
                "label": "Sainte-Genevieve",
                "label_ar": "سانت جينيفيف",
                "label_fr": "Sainte-Geneviève"
            },
            {
                "value": 33892,
                "label": "Novion-Porcien",
                "label_ar": "نوفيون بورسين",
                "label_fr": "Novion-Porcien"
            },
            {
                "value": 33893,
                "label": "Fontvannes",
                "label_ar": "فونتفانيس",
                "label_fr": "Fontvannes"
            },
            {
                "value": 33894,
                "label": "Bazuel",
                "label_ar": "بازويل",
                "label_fr": "Bazuel"
            },
            {
                "value": 33895,
                "label": "Coupelle-Vieille",
                "label_ar": "كوبيل فيل",
                "label_fr": "Coupelle-Vieille"
            },
            {
                "value": 33896,
                "label": "Moyencourt",
                "label_ar": "موينكور",
                "label_fr": "Moyencourt"
            },
            {
                "value": 33897,
                "label": "Seraucourt-le-Grand",
                "label_ar": "سيروكورت لي جراند",
                "label_fr": "Seraucourt-le-Grand"
            },
            {
                "value": 33898,
                "label": "Foulangues",
                "label_ar": "فولانغ",
                "label_fr": "Foulangues"
            },
            {
                "value": 33899,
                "label": "Tupigny",
                "label_ar": "توبيني",
                "label_fr": "Tupigny"
            },
            {
                "value": 33900,
                "label": "Conde-sur-Marne",
                "label_ar": "كوندي سور مارن",
                "label_fr": "Condé-sur-Marne"
            },
            {
                "value": 33901,
                "label": "Gouy-en-Artois",
                "label_ar": "جوي أون أرتواز",
                "label_fr": "Gouy-en-Artois"
            },
            {
                "value": 33902,
                "label": "Inchy",
                "label_ar": "انشي",
                "label_fr": "Inchy"
            },
            {
                "value": 33903,
                "label": "Millencourt",
                "label_ar": "ميلينكورت",
                "label_fr": "Millencourt"
            },
            {
                "value": 33904,
                "label": "Moislains",
                "label_ar": "Moislains",
                "label_fr": "Moislains"
            },
            {
                "value": 33905,
                "label": "Avesnelles",
                "label_ar": "أفيسنيليس",
                "label_fr": "Avesnelles"
            },
            {
                "value": 33906,
                "label": "Cartignies",
                "label_ar": "كارتيني",
                "label_fr": "Cartignies"
            },
            {
                "value": 33907,
                "label": "Radonvilliers",
                "label_ar": "Radonvilliers",
                "label_fr": "Radonvilliers"
            },
            {
                "value": 33908,
                "label": "Craonne",
                "label_ar": "كرون",
                "label_fr": "Craonne"
            },
            {
                "value": 33909,
                "label": "Billy-sur-Ourcq",
                "label_ar": "بيلي سور أورك",
                "label_fr": "Billy-sur-Ourcq"
            },
            {
                "value": 33910,
                "label": "Epagne-Epagnette",
                "label_ar": "إيباني إيبجنيت",
                "label_fr": "Epagne-Epagnette"
            },
            {
                "value": 33911,
                "label": "Mont-Saint-Pere",
                "label_ar": "مونت سان بير",
                "label_fr": "Mont-Saint-Père"
            },
            {
                "value": 33912,
                "label": "Bassoles-Aulers",
                "label_ar": "Bassoles-Aulers",
                "label_fr": "Bassoles-Aulers"
            },
            {
                "value": 33913,
                "label": "Bethancourt-en-Valois",
                "label_ar": "بيثانكورت أون فالوا",
                "label_fr": "Bethancourt-en-Valois"
            },
            {
                "value": 33914,
                "label": "Lusigny-sur-Barse",
                "label_ar": "لوزيني سور بارس",
                "label_fr": "Lusigny-sur-Barse"
            },
            {
                "value": 33915,
                "label": "Ecueil",
                "label_ar": "إكويل",
                "label_fr": "Ecueil"
            },
            {
                "value": 33916,
                "label": "Carnieres",
                "label_ar": "كارنيريس",
                "label_fr": "Carnières"
            },
            {
                "value": 33917,
                "label": "Ablain-Saint-Nazaire",
                "label_ar": "عبلين سان نازير",
                "label_fr": "Ablain-Saint-Nazaire"
            },
            {
                "value": 33918,
                "label": "Valmondois",
                "label_ar": "فالموندوا",
                "label_fr": "Valmondois"
            },
            {
                "value": 33919,
                "label": "Ferrieres",
                "label_ar": "العبارات",
                "label_fr": "Ferrières"
            },
            {
                "value": 33920,
                "label": "Clairfontaine",
                "label_ar": "كليرفونتين",
                "label_fr": "Clairfontaine"
            },
            {
                "value": 33921,
                "label": "Mennevret",
                "label_ar": "مينيفريت",
                "label_fr": "Mennevret"
            },
            {
                "value": 33922,
                "label": "Proyart",
                "label_ar": "برويارت",
                "label_fr": "Proyart"
            },
            {
                "value": 33923,
                "label": "Commenchon",
                "label_ar": "كومينشون",
                "label_fr": "Commenchon"
            },
            {
                "value": 33924,
                "label": "Herpy-l'Arlesienne",
                "label_ar": "هيربي لارليسيان",
                "label_fr": "Herpy-l'Arlésienne"
            },
            {
                "value": 33925,
                "label": "Secheval",
                "label_ar": "سيتشيفال",
                "label_fr": "Secheval"
            },
            {
                "value": 33926,
                "label": "Clety",
                "label_ar": "كليتي",
                "label_fr": "Clety"
            },
            {
                "value": 33927,
                "label": "Choisel",
                "label_ar": "Choisel",
                "label_fr": "Choisel"
            },
            {
                "value": 33928,
                "label": "Chavenay",
                "label_ar": "شافيناي",
                "label_fr": "Chavenay"
            },
            {
                "value": 33929,
                "label": "Vaudoy-en-Brie",
                "label_ar": "فودوي أون بري",
                "label_fr": "Vaudoy-en-Brie"
            },
            {
                "value": 33930,
                "label": "Chateaubleau",
                "label_ar": "شاتوبليو",
                "label_fr": "Châteaubleau"
            },
            {
                "value": 33931,
                "label": "Saint-Georges-sur-Allier",
                "label_ar": "سان جورج سور ألييه",
                "label_fr": "Saint-Georges-sur-Allier"
            },
            {
                "value": 33932,
                "label": "Saint-Pierre-le-Chastel",
                "label_ar": "سان بيير لو شاستيل",
                "label_fr": "Saint-Pierre-le-Chastel"
            },
            {
                "value": 33933,
                "label": "Saint-Jean-de-Sixt",
                "label_ar": "سان جان دي سيكست",
                "label_fr": "Saint-Jean-de-Sixt"
            },
            {
                "value": 33934,
                "label": "Villy-le-Bouveret",
                "label_ar": "فيلي لو بوفيريه",
                "label_fr": "Villy-le-Bouveret"
            },
            {
                "value": 33935,
                "label": "Cereste",
                "label_ar": "سيريست",
                "label_fr": "Cereste"
            },
            {
                "value": 33936,
                "label": "Cornillon-Confoux",
                "label_ar": "كورنيلون كونفو",
                "label_fr": "Cornillon-Confoux"
            },
            {
                "value": 33937,
                "label": "Lumio",
                "label_ar": "لوميو",
                "label_fr": "Lumio"
            },
            {
                "value": 33938,
                "label": "Artigues",
                "label_ar": "ارتيجس",
                "label_fr": "Artigues"
            },
            {
                "value": 33939,
                "label": "Aups",
                "label_ar": "أوبس",
                "label_fr": "Aups"
            },
            {
                "value": 33940,
                "label": "Calcatoggio",
                "label_ar": "كالكاتوجيو",
                "label_fr": "Calcatoggio"
            },
            {
                "value": 33941,
                "label": "Montussan",
                "label_ar": "مونتوسان",
                "label_fr": "Montussan"
            },
            {
                "value": 33942,
                "label": "Montcaret",
                "label_ar": "مونتكاريت",
                "label_fr": "Montcaret"
            },
            {
                "value": 33943,
                "label": "Dieupentale",
                "label_ar": "ديوبينتال",
                "label_fr": "Dieupentale"
            },
            {
                "value": 33944,
                "label": "Miradoux",
                "label_ar": "ميرادوكس",
                "label_fr": "Miradoux"
            },
            {
                "value": 33945,
                "label": "Marigny-Chemereau",
                "label_ar": "ماريني-شيميرو",
                "label_fr": "Marigny-Chemereau"
            },
            {
                "value": 33946,
                "label": "Chenevelles",
                "label_ar": "Chenevelles",
                "label_fr": "Chenevelles"
            },
            {
                "value": 33947,
                "label": "Brux",
                "label_ar": "بروكس",
                "label_fr": "Brux"
            },
            {
                "value": 33948,
                "label": "Aigonnay",
                "label_ar": "أيغوني",
                "label_fr": "Aigonnay"
            },
            {
                "value": 33949,
                "label": "Bernos-Beaulac",
                "label_ar": "برنوس بولاك",
                "label_fr": "Bernos-Beaulac"
            },
            {
                "value": 33950,
                "label": "Cornus",
                "label_ar": "كورنوس",
                "label_fr": "Cornus"
            },
            {
                "value": 33951,
                "label": "Terrebasse",
                "label_ar": "Terrebasse",
                "label_fr": "Terrebasse"
            },
            {
                "value": 33952,
                "label": "Puycelsi",
                "label_ar": "بويكلسي",
                "label_fr": "Puycelsi"
            },
            {
                "value": 33953,
                "label": "Mingot",
                "label_ar": "مينجوت",
                "label_fr": "Mingot"
            },
            {
                "value": 33954,
                "label": "Arzacq-Arraziguet",
                "label_ar": "Arzacq-Arraziguet",
                "label_fr": "Arzacq-Arraziguet"
            },
            {
                "value": 33955,
                "label": "Saint-Gein",
                "label_ar": "سان جين",
                "label_fr": "Saint-Gein"
            },
            {
                "value": 33956,
                "label": "Saint-Mariens",
                "label_ar": "سانت ماريان",
                "label_fr": "Saint-Mariens"
            },
            {
                "value": 33957,
                "label": "Cartelegue",
                "label_ar": "كارتليجي",
                "label_fr": "Cartelegue"
            },
            {
                "value": 33958,
                "label": "Gensac",
                "label_ar": "جينساك",
                "label_fr": "Gensac"
            },
            {
                "value": 33959,
                "label": "Laguiole",
                "label_ar": "Laguiole",
                "label_fr": "Laguiole"
            },
            {
                "value": 33960,
                "label": "Glenic",
                "label_ar": "جلينيك",
                "label_fr": "Glénic"
            },
            {
                "value": 33961,
                "label": "Melle",
                "label_ar": "ميلي",
                "label_fr": "Melle"
            },
            {
                "value": 33962,
                "label": "Villexavier",
                "label_ar": "فيلكسافير",
                "label_fr": "Villexavier"
            },
            {
                "value": 33963,
                "label": "Mazieres-en-Gatine",
                "label_ar": "Mazieres-en-Gatine",
                "label_fr": "Mazières-en-Gatine"
            },
            {
                "value": 33964,
                "label": "Blaudeix",
                "label_ar": "بلوديكس",
                "label_fr": "Blaudeix"
            },
            {
                "value": 33965,
                "label": "Abzac",
                "label_ar": "أبزاك",
                "label_fr": "Abzac"
            },
            {
                "value": 33966,
                "label": "Saint-Georges-des-Coteaux",
                "label_ar": "سان جورج دي كوتو",
                "label_fr": "Saint-Georges-des-Coteaux"
            },
            {
                "value": 33967,
                "label": "Coulombiers",
                "label_ar": "كولومبييرز",
                "label_fr": "Coulombiers"
            },
            {
                "value": 33968,
                "label": "Savignac-les-Eglises",
                "label_ar": "Savignac-les-Eglises",
                "label_fr": "Savignac-les-Églises"
            },
            {
                "value": 33969,
                "label": "Castanet-le-Haut",
                "label_ar": "Castanet-le-Haut",
                "label_fr": "Castanet-le-Haut"
            },
            {
                "value": 33970,
                "label": "Verdun-en-Lauragais",
                "label_ar": "فردان أون لوراجيس",
                "label_fr": "Verdun-en-Lauragais"
            },
            {
                "value": 33971,
                "label": "Vaissac",
                "label_ar": "فايساك",
                "label_fr": "Vaissac"
            },
            {
                "value": 33972,
                "label": "Moliets-et-Maa",
                "label_ar": "Moliets-et-Maa",
                "label_fr": "Moliets-et-Maa"
            },
            {
                "value": 33973,
                "label": "Champlemy",
                "label_ar": "شامبلمي",
                "label_fr": "Champlemy"
            },
            {
                "value": 33974,
                "label": "Vaux-les-Pres",
                "label_ar": "فو ليه بري",
                "label_fr": "Vaux-les-Prés"
            },
            {
                "value": 33975,
                "label": "Dalhunden",
                "label_ar": "دالهوندن",
                "label_fr": "Dalhunden"
            },
            {
                "value": 33976,
                "label": "Gendrey",
                "label_ar": "جندري",
                "label_fr": "Gendrey"
            },
            {
                "value": 33977,
                "label": "Ruffey-sur-Seille",
                "label_ar": "روفي سور سيل",
                "label_fr": "Ruffey-sur-Seille"
            },
            {
                "value": 33978,
                "label": "Spechbach-le-Haut",
                "label_ar": "Spechbach-le-Haut",
                "label_fr": "Spechbach-le-Haut"
            },
            {
                "value": 33979,
                "label": "Vitteaux",
                "label_ar": "فيتو",
                "label_fr": "Vitteaux"
            },
            {
                "value": 33980,
                "label": "Buffard",
                "label_ar": "بوفارد",
                "label_fr": "Buffard"
            },
            {
                "value": 33981,
                "label": "Montarlot-les-Rioz",
                "label_ar": "مونتارلو ليه ريوز",
                "label_fr": "Montarlot-les-Rioz"
            },
            {
                "value": 33982,
                "label": "Lantenne-Vertiere",
                "label_ar": "Lantenne-Vertiere",
                "label_fr": "Lantenne-Vertière"
            },
            {
                "value": 33983,
                "label": "Poncey-les-Athee",
                "label_ar": "Poncey-les-Athee",
                "label_fr": "Poncey-les-Athée"
            },
            {
                "value": 33984,
                "label": "Talon",
                "label_ar": "تالون",
                "label_fr": "Talon"
            },
            {
                "value": 33985,
                "label": "Vers",
                "label_ar": "فير",
                "label_fr": "Vers"
            },
            {
                "value": 33986,
                "label": "Anteuil",
                "label_ar": "أنتيويل",
                "label_fr": "Anteuil"
            },
            {
                "value": 33987,
                "label": "Auxey-Duresses",
                "label_ar": "أوكسي ديريس",
                "label_fr": "Auxey-Duresses"
            },
            {
                "value": 33988,
                "label": "Le Ban-Saint-Martin",
                "label_ar": "لو بان سان مارتن",
                "label_fr": "Le Ban-Saint-Martin"
            },
            {
                "value": 33989,
                "label": "Laval-sur-Vologne",
                "label_ar": "لافال سور فولونيا",
                "label_fr": "Laval-sur-Vologne"
            },
            {
                "value": 33990,
                "label": "Witternheim",
                "label_ar": "فيترنهايم",
                "label_fr": "Witternheim"
            },
            {
                "value": 33991,
                "label": "Lohr",
                "label_ar": "لوهر",
                "label_fr": "Lohr"
            },
            {
                "value": 33992,
                "label": "Dannemoine",
                "label_ar": "دانيموين",
                "label_fr": "Dannemoine"
            },
            {
                "value": 33993,
                "label": "Velleminfroy",
                "label_ar": "فيليمينفروي",
                "label_fr": "Velleminfroy"
            },
            {
                "value": 33994,
                "label": "Montmalin",
                "label_ar": "مونتمالين",
                "label_fr": "Montmalin"
            },
            {
                "value": 33995,
                "label": "Brion",
                "label_ar": "بريون",
                "label_fr": "Brion"
            },
            {
                "value": 33996,
                "label": "Moineville",
                "label_ar": "موينفيل",
                "label_fr": "Moineville"
            },
            {
                "value": 33997,
                "label": "Cappel",
                "label_ar": "كابيل",
                "label_fr": "Cappel"
            },
            {
                "value": 33998,
                "label": "Ochey",
                "label_ar": "أوشي",
                "label_fr": "Ochey"
            },
            {
                "value": 33999,
                "label": "Plappeville",
                "label_ar": "بلابفيل",
                "label_fr": "Plappeville"
            },
            {
                "value": 34000,
                "label": "Silly-sur-Nied",
                "label_ar": "سخيفة سور نيد",
                "label_fr": "Silly-sur-Nied"
            },
            {
                "value": 34001,
                "label": "Sainte-Suzanne",
                "label_ar": "سانت سوزان",
                "label_fr": "Sainte-Suzanne"
            },
            {
                "value": 34002,
                "label": "Farincourt",
                "label_ar": "فارينكور",
                "label_fr": "Farincourt"
            },
            {
                "value": 34003,
                "label": "Filain",
                "label_ar": "فلين",
                "label_fr": "Filain"
            },
            {
                "value": 34004,
                "label": "Noidans-le-Ferroux",
                "label_ar": "نويدان لو فيرو",
                "label_fr": "Noidans-le-Ferroux"
            },
            {
                "value": 34005,
                "label": "Gottenhouse",
                "label_ar": "جوتنهاوس",
                "label_fr": "Gottenhouse"
            },
            {
                "value": 34006,
                "label": "Novillars",
                "label_ar": "نوفيلارس",
                "label_fr": "Novillars"
            },
            {
                "value": 34007,
                "label": "Montboillon",
                "label_ar": "مونتبيلون",
                "label_fr": "Montboillon"
            },
            {
                "value": 34008,
                "label": "Tresilley",
                "label_ar": "تريسيلي",
                "label_fr": "Tresilley"
            },
            {
                "value": 34009,
                "label": "Sessenheim",
                "label_ar": "Sessenheim",
                "label_fr": "Sessenheim"
            },
            {
                "value": 34010,
                "label": "Favieres",
                "label_ar": "فافيريس",
                "label_fr": "Favieres"
            },
            {
                "value": 34011,
                "label": "Sarraltroff",
                "label_ar": "سارالتروف",
                "label_fr": "Sarraltroff"
            },
            {
                "value": 34012,
                "label": "Nousseviller-les-Bitche",
                "label_ar": "Nousseviller-les-Bitche",
                "label_fr": "Nousseviller-les-Bitche"
            },
            {
                "value": 34013,
                "label": "Laneuveville-devant-Bayon",
                "label_ar": "لينوففيل ديفانت بايون",
                "label_fr": "Laneuveville-devant-Bayon"
            },
            {
                "value": 34014,
                "label": "Jouaville",
                "label_ar": "جوافيل",
                "label_fr": "Jouaville"
            },
            {
                "value": 34015,
                "label": "Bionville-sur-Nied",
                "label_ar": "بيونفيل سور نيد",
                "label_fr": "Bionville-sur-Nied"
            },
            {
                "value": 34016,
                "label": "Wintersbourg",
                "label_ar": "وينترسبورج",
                "label_fr": "Wintersbourg"
            },
            {
                "value": 34017,
                "label": "Remereville",
                "label_ar": "ريميرفيل",
                "label_fr": "Remereville"
            },
            {
                "value": 34018,
                "label": "Magnieres",
                "label_ar": "ماجنير",
                "label_fr": "Magnières"
            },
            {
                "value": 34019,
                "label": "Fletrange",
                "label_ar": "فليترانج",
                "label_fr": "Fletrange"
            },
            {
                "value": 34020,
                "label": "Dommartin-les-Remiremont",
                "label_ar": "دومارتين ليه ريميرمون",
                "label_fr": "Dommartin-les-Remiremont"
            },
            {
                "value": 34021,
                "label": "Lanthenans",
                "label_ar": "لانثينانس",
                "label_fr": "Lanthenans"
            },
            {
                "value": 34022,
                "label": "Lorry-Mardigny",
                "label_ar": "لوري مارديني",
                "label_fr": "Camion-Mardigny"
            },
            {
                "value": 34023,
                "label": "Troussey",
                "label_ar": "تروسي",
                "label_fr": "Troussey"
            },
            {
                "value": 34024,
                "label": "Deyvillers",
                "label_ar": "ديفيلرس",
                "label_fr": "Deyvillers"
            },
            {
                "value": 34025,
                "label": "Bindernheim",
                "label_ar": "بيندرنهايم",
                "label_fr": "Bindernheim"
            },
            {
                "value": 34026,
                "label": "Hunspach",
                "label_ar": "Hunspach",
                "label_fr": "Hunspach"
            },
            {
                "value": 34027,
                "label": "Varsberg",
                "label_ar": "فارسبرغ",
                "label_fr": "Varsberg"
            },
            {
                "value": 34028,
                "label": "Gondreville",
                "label_ar": "جوندريفيل",
                "label_fr": "Gondreville"
            },
            {
                "value": 34029,
                "label": "Ortoncourt",
                "label_ar": "أورتونكورت",
                "label_fr": "Ortoncourt"
            },
            {
                "value": 34030,
                "label": "Mamey",
                "label_ar": "مامي",
                "label_fr": "Mamey"
            },
            {
                "value": 34031,
                "label": "Benamenil",
                "label_ar": "بنامينيل",
                "label_fr": "Benamenil"
            },
            {
                "value": 34032,
                "label": "Belleray",
                "label_ar": "بيلراي",
                "label_fr": "Belleray"
            },
            {
                "value": 34033,
                "label": "Guessling-Hemering",
                "label_ar": "Guessling-Hemering",
                "label_fr": "Guessling-Hemering"
            },
            {
                "value": 34034,
                "label": "Coume",
                "label_ar": "كوم",
                "label_fr": "Coume"
            },
            {
                "value": 34035,
                "label": "Vacon",
                "label_ar": "فاكون",
                "label_fr": "Vacon"
            },
            {
                "value": 34036,
                "label": "Landser",
                "label_ar": "لاندسير",
                "label_fr": "Landser"
            },
            {
                "value": 34037,
                "label": "Vincelles",
                "label_ar": "فينسيلس",
                "label_fr": "Vincelles"
            },
            {
                "value": 34038,
                "label": "Brouennes",
                "label_ar": "بروين",
                "label_fr": "Brouennes"
            },
            {
                "value": 34039,
                "label": "Moriville",
                "label_ar": "موريفيل",
                "label_fr": "Moriville"
            },
            {
                "value": 34040,
                "label": "Billy-sous-les-Cotes",
                "label_ar": "بيلي سوس ليس كوتس",
                "label_fr": "Billy-sous-les-Cotes"
            },
            {
                "value": 34041,
                "label": "Housseville",
                "label_ar": "هوسفيل",
                "label_fr": "Housseville"
            },
            {
                "value": 34042,
                "label": "Champdray",
                "label_ar": "شامبدراي",
                "label_fr": "Champdray"
            },
            {
                "value": 34043,
                "label": "Letricourt",
                "label_ar": "ليتريكورت",
                "label_fr": "Letricourt"
            },
            {
                "value": 34044,
                "label": "Niffer",
                "label_ar": "نيفر",
                "label_fr": "Niffer"
            },
            {
                "value": 34045,
                "label": "Saint-Gingolph",
                "label_ar": "سان جينجولف",
                "label_fr": "Saint-Gingolph"
            },
            {
                "value": 34046,
                "label": "Escurolles",
                "label_ar": "اسكورول",
                "label_fr": "Escurolles"
            },
            {
                "value": 34047,
                "label": "Chaptuzat",
                "label_ar": "تشابتوزات",
                "label_fr": "Chaptuzat"
            },
            {
                "value": 34048,
                "label": "Chavaroux",
                "label_ar": "شافارو",
                "label_fr": "Chavaroux"
            },
            {
                "value": 34049,
                "label": "Loisin",
                "label_ar": "لويزين",
                "label_fr": "Loisin"
            },
            {
                "value": 34050,
                "label": "Meillerie",
                "label_ar": "Meillerie",
                "label_fr": "Meillerie"
            },
            {
                "value": 34051,
                "label": "La Clusaz",
                "label_ar": "لا كلوساز",
                "label_fr": "La Clusaz"
            },
            {
                "value": 34052,
                "label": "Ydes",
                "label_ar": "يديس",
                "label_fr": "Ydes"
            },
            {
                "value": 34053,
                "label": "Saignes",
                "label_ar": "ساينيس",
                "label_fr": "Saignes"
            },
            {
                "value": 34054,
                "label": "Le Fayet",
                "label_ar": "لو فاييت",
                "label_fr": "Le Fayet"
            },
            {
                "value": 34055,
                "label": "La Riviere",
                "label_ar": "لا ريفيير",
                "label_fr": "La Rivière"
            },
            {
                "value": 34056,
                "label": "Courchevel",
                "label_ar": "كورشوفيل",
                "label_fr": "Courchevel"
            },
            {
                "value": 34057,
                "label": "La Flachere",
                "label_ar": "لا فلاتشير",
                "label_fr": "La Flachere"
            },
            {
                "value": 34058,
                "label": "Merlas",
                "label_ar": "ميرلاس",
                "label_fr": "Merlas"
            },
            {
                "value": 34059,
                "label": "Bresson",
                "label_ar": "بريسون",
                "label_fr": "Bresson"
            },
            {
                "value": 34060,
                "label": "La Salette-Fallavaux",
                "label_ar": "لا ساليت فالافو",
                "label_fr": "La Salette-Fallavaux"
            },
            {
                "value": 34061,
                "label": "La Sone",
                "label_ar": "لا سون",
                "label_fr": "La Sone"
            },
            {
                "value": 34062,
                "label": "Apremont",
                "label_ar": "أبريمونت",
                "label_fr": "Apremont"
            },
            {
                "value": 34063,
                "label": "Cesarches",
                "label_ar": "سيزارش",
                "label_fr": "Césarches"
            },
            {
                "value": 34064,
                "label": "Tignes",
                "label_ar": "تيني",
                "label_fr": "Tignes"
            },
            {
                "value": 34065,
                "label": "Aiguebelette-le-Lac",
                "label_ar": "إيغيبيليت لو لاك",
                "label_fr": "Aiguebelette-le-Lac"
            },
            {
                "value": 34066,
                "label": "Saint-Pierre-dAllevard",
                "label_ar": "سان بيير دالفارد",
                "label_fr": "Saint-Pierre-d'Allevard"
            },
            {
                "value": 34067,
                "label": "Le Cheylas",
                "label_ar": "لو شيلاس",
                "label_fr": "Le Cheylas"
            },
            {
                "value": 34068,
                "label": "Saint-Julien-en-Champsaur",
                "label_ar": "سان جوليان إن شامبسور",
                "label_fr": "Saint-Julien-en-Champsaur"
            },
            {
                "value": 34069,
                "label": "Voglans",
                "label_ar": "فوغلانس",
                "label_fr": "Voglans"
            },
            {
                "value": 34070,
                "label": "Blaise",
                "label_ar": "بليز",
                "label_fr": "Blaise"
            },
            {
                "value": 34071,
                "label": "Barraux",
                "label_ar": "بارو",
                "label_fr": "Barraux"
            },
            {
                "value": 34072,
                "label": "Notre-Dame-de-Commiers",
                "label_ar": "نوتردام دي كوميه",
                "label_fr": "Notre-Dame-de-Commiers"
            },
            {
                "value": 34073,
                "label": "Murianette",
                "label_ar": "موريانيت",
                "label_fr": "Murianette"
            },
            {
                "value": 34074,
                "label": "Lepin-le-Lac",
                "label_ar": "ليبين لو لاك",
                "label_fr": "Lépin-le-Lac"
            },
            {
                "value": 34075,
                "label": "Saint-Cassin",
                "label_ar": "سانت كاسان",
                "label_fr": "Saint-Cassin"
            },
            {
                "value": 34076,
                "label": "Entremont-le-Vieux",
                "label_ar": "Entremont-le-Vieux",
                "label_fr": "Entremont-le-Vieux"
            },
            {
                "value": 34077,
                "label": "Pallud",
                "label_ar": "بالود",
                "label_fr": "Pallud"
            },
            {
                "value": 34078,
                "label": "Tours-en-Savoie",
                "label_ar": "جولات في سافوا",
                "label_fr": "Tours-en-Savoie"
            },
            {
                "value": 34079,
                "label": "Aillon-le-Vieux",
                "label_ar": "ايلون لو فيو",
                "label_fr": "Aillon-le-Vieux"
            },
            {
                "value": 34080,
                "label": "Thoiry",
                "label_ar": "Thoiry",
                "label_fr": "Thoiry"
            },
            {
                "value": 34081,
                "label": "Saint-Gondon",
                "label_ar": "سان جوندون",
                "label_fr": "Saint-Gondon"
            },
            {
                "value": 34082,
                "label": "Selle",
                "label_ar": "سيلي",
                "label_fr": "Selle"
            },
            {
                "value": 34083,
                "label": "Vouzon",
                "label_ar": "فوزون",
                "label_fr": "Vouzon"
            },
            {
                "value": 34084,
                "label": "Tauxigny",
                "label_ar": "تاوكسيجني",
                "label_fr": "Tauxigny"
            },
            {
                "value": 34085,
                "label": "Jars",
                "label_ar": "الجرار",
                "label_fr": "Bocaux"
            },
            {
                "value": 34086,
                "label": "Courcy-aux-Loges",
                "label_ar": "Courcy-aux-Loges",
                "label_fr": "Courcy-aux-Loges"
            },
            {
                "value": 34087,
                "label": "Croisilles",
                "label_ar": "كروازيل",
                "label_fr": "Croisilles"
            },
            {
                "value": 34088,
                "label": "Saint-Gervais-la-Foret",
                "label_ar": "سان جيرفيه لا فوريه",
                "label_fr": "Saint-Gervais-la-Foret"
            },
            {
                "value": 34089,
                "label": "Manchecourt",
                "label_ar": "مانشيكورت",
                "label_fr": "Manchecourt"
            },
            {
                "value": 34090,
                "label": "Saint-Genou",
                "label_ar": "سان جينو",
                "label_fr": "Saint-Genou"
            },
            {
                "value": 34091,
                "label": "Bouilly-en-Gatinais",
                "label_ar": "بويلي أون جاتينيس",
                "label_fr": "Bouilly-en-Gatinais"
            },
            {
                "value": 34092,
                "label": "Mesland",
                "label_ar": "ميسلاند",
                "label_fr": "Mesland"
            },
            {
                "value": 34093,
                "label": "Meusnes",
                "label_ar": "Meusnes",
                "label_fr": "Meusnes"
            },
            {
                "value": 34094,
                "label": "Thilouze",
                "label_ar": "ثيلوز",
                "label_fr": "Thilouze"
            },
            {
                "value": 34095,
                "label": "Lion-en-Sullias",
                "label_ar": "ليون أون سولياس",
                "label_fr": "Lion-en-Sullias"
            },
            {
                "value": 34096,
                "label": "Mezieres-lez-Clery",
                "label_ar": "Mezieres-lez-Clery",
                "label_fr": "Mézières-lez-Cléry"
            },
            {
                "value": 34097,
                "label": "Saint-Regle",
                "label_ar": "سان ريجل",
                "label_fr": "Saint-Regle"
            },
            {
                "value": 34098,
                "label": "Dampierre-en-Crot",
                "label_ar": "دامبيير أون كروت",
                "label_fr": "Dampierre-en-Crot"
            },
            {
                "value": 34099,
                "label": "Vrigny",
                "label_ar": "فريني",
                "label_fr": "Vrigny"
            },
            {
                "value": 34100,
                "label": "Florentin",
                "label_ar": "فلورنتين",
                "label_fr": "Florentin"
            },
            {
                "value": 34101,
                "label": "Le Noyer",
                "label_ar": "لو نوير",
                "label_fr": "Le Noyer"
            },
            {
                "value": 34102,
                "label": "Boulleret",
                "label_ar": "بوليريه",
                "label_fr": "Boulleret"
            },
            {
                "value": 34103,
                "label": "Assay",
                "label_ar": "فحص",
                "label_fr": "Essai"
            },
            {
                "value": 34104,
                "label": "Tillay-le-Peneux",
                "label_ar": "تيلاي لو بينوكس",
                "label_fr": "Tillay-le-Peneux"
            },
            {
                "value": 34105,
                "label": "Cabanac",
                "label_ar": "كاباناك",
                "label_fr": "Cabanac"
            },
            {
                "value": 34106,
                "label": "Arsague",
                "label_ar": "أرساج",
                "label_fr": "Arsague"
            },
            {
                "value": 34107,
                "label": "Serres-Gaston",
                "label_ar": "سيريس جاستون",
                "label_fr": "Serres-Gaston"
            },
            {
                "value": 34108,
                "label": "Louer",
                "label_ar": "لوير",
                "label_fr": "Louer"
            },
            {
                "value": 34109,
                "label": "Bastennes",
                "label_ar": "باستينس",
                "label_fr": "Bastennes"
            },
            {
                "value": 34110,
                "label": "Josse",
                "label_ar": "جوس",
                "label_fr": "Josse"
            },
            {
                "value": 34111,
                "label": "Aubiac",
                "label_ar": "أوبياك",
                "label_fr": "Aubiac"
            },
            {
                "value": 34112,
                "label": "Ambrus",
                "label_ar": "أمبروس",
                "label_fr": "Ambrus"
            },
            {
                "value": 34113,
                "label": "Noaillac",
                "label_ar": "نويلاك",
                "label_fr": "Noaillac"
            },
            {
                "value": 34114,
                "label": "Cabanac-et-Villagrains",
                "label_ar": "Cabanac-et-Villagrains",
                "label_fr": "Cabanac-et-Villagrains"
            },
            {
                "value": 34115,
                "label": "Mombrier",
                "label_ar": "مومبريير",
                "label_fr": "Mombrier"
            },
            {
                "value": 34116,
                "label": "Hure",
                "label_ar": "حير",
                "label_fr": "Hure"
            },
            {
                "value": 34117,
                "label": "Camblanes-et-Meynac",
                "label_ar": "Camblanes-et-Meynac",
                "label_fr": "Camblanes-et-Meynac"
            },
            {
                "value": 34118,
                "label": "Chazelles",
                "label_ar": "شازيل",
                "label_fr": "Chazelles"
            },
            {
                "value": 34119,
                "label": "Chevanceaux",
                "label_ar": "شيفانسو",
                "label_fr": "Chevanceaux"
            },
            {
                "value": 34120,
                "label": "Rohan",
                "label_ar": "روهان",
                "label_fr": "Rohan"
            },
            {
                "value": 34121,
                "label": "Saint-Martial",
                "label_ar": "سان مارتيال",
                "label_fr": "Saint-Martial"
            },
            {
                "value": 34122,
                "label": "Lagord",
                "label_ar": "لاغورد",
                "label_fr": "Lagord"
            },
            {
                "value": 34123,
                "label": "Bommes",
                "label_ar": "بومز",
                "label_fr": "Bommes"
            },
            {
                "value": 34124,
                "label": "Saint-Geyrac",
                "label_ar": "سان جيراك",
                "label_fr": "Saint-Geyrac"
            },
            {
                "value": 34125,
                "label": "Saint-Etienne-d'Orthe",
                "label_ar": "سانت إتيان دورث",
                "label_fr": "Saint-Étienne-d'Orthe"
            },
            {
                "value": 34126,
                "label": "Biaudos",
                "label_ar": "بياودوس",
                "label_fr": "Biaudos"
            },
            {
                "value": 34127,
                "label": "Viodos-Abense-de-Bas",
                "label_ar": "فيودوس أبينسي دي باس",
                "label_fr": "Viodos-Abense-de-Bas"
            },
            {
                "value": 34128,
                "label": "Castet",
                "label_ar": "كاستيت",
                "label_fr": "Castet"
            },
            {
                "value": 34129,
                "label": "Azur",
                "label_ar": "أزور",
                "label_fr": "Azur"
            },
            {
                "value": 34130,
                "label": "Buros",
                "label_ar": "بوروس",
                "label_fr": "Buros"
            },
            {
                "value": 34131,
                "label": "Oregue",
                "label_ar": "أوريغ",
                "label_fr": "Oregue"
            },
            {
                "value": 34132,
                "label": "Monflanquin",
                "label_ar": "مونفلانكوين",
                "label_fr": "Monflanquin"
            },
            {
                "value": 34133,
                "label": "Saint-Savinien",
                "label_ar": "سانت سافينيان",
                "label_fr": "Saint-Savinien"
            },
            {
                "value": 34134,
                "label": "Meuzac",
                "label_ar": "ميوزاك",
                "label_fr": "Meuzac"
            },
            {
                "value": 34135,
                "label": "Barro",
                "label_ar": "بارو",
                "label_fr": "Barro"
            },
            {
                "value": 34136,
                "label": "Guignicourt-sur-Vence",
                "label_ar": "Guignicourt-sur-Vence",
                "label_fr": "Guignicourt-sur-Vence"
            },
            {
                "value": 34137,
                "label": "Loisy-sur-Marne",
                "label_ar": "لويزي سور مارن",
                "label_fr": "Loisy-sur-Marne"
            },
            {
                "value": 34138,
                "label": "Mergey",
                "label_ar": "دمج",
                "label_fr": "Mergey"
            },
            {
                "value": 34139,
                "label": "Tincques",
                "label_ar": "تينك",
                "label_fr": "Tincques"
            },
            {
                "value": 34140,
                "label": "Lederzeele",
                "label_ar": "ليدرزيل",
                "label_fr": "Lederzeele"
            },
            {
                "value": 34141,
                "label": "Boulages",
                "label_ar": "بولاج",
                "label_fr": "Boulages"
            },
            {
                "value": 34142,
                "label": "Saint-Pouange",
                "label_ar": "سان بوانج",
                "label_fr": "Saint-Pouange"
            },
            {
                "value": 34143,
                "label": "Fontenoy",
                "label_ar": "فونتينوي",
                "label_fr": "Fontenoy"
            },
            {
                "value": 34144,
                "label": "Haussignemont",
                "label_ar": "هوسينيمونت",
                "label_fr": "Haussignemont"
            },
            {
                "value": 34145,
                "label": "Le Herie-la-Vieville",
                "label_ar": "لو هيري لا فيفيل",
                "label_fr": "Le Herie-la-Vieville"
            },
            {
                "value": 34146,
                "label": "Corbehem",
                "label_ar": "كوربهم",
                "label_fr": "Corbehem"
            },
            {
                "value": 34147,
                "label": "Riencourt-les-Bapaume",
                "label_ar": "رينكور ليه بابوم",
                "label_fr": "Riencourt-les-Bapaume"
            },
            {
                "value": 34148,
                "label": "Marquay",
                "label_ar": "ماركواي",
                "label_fr": "Marquay"
            },
            {
                "value": 34149,
                "label": "Chigny-les-Roses",
                "label_ar": "Chigny-les-Roses",
                "label_fr": "Chigny-les-Roses"
            },
            {
                "value": 34150,
                "label": "Tremilly",
                "label_ar": "تريميللي",
                "label_fr": "Tremilly"
            },
            {
                "value": 34151,
                "label": "Offekerque",
                "label_ar": "Offekerque",
                "label_fr": "Offekerque"
            },
            {
                "value": 34152,
                "label": "Louvil",
                "label_ar": "لوفيل",
                "label_fr": "Louvil"
            },
            {
                "value": 34153,
                "label": "Etroeungt",
                "label_ar": "إترويونجت",
                "label_fr": "Etroeungt"
            },
            {
                "value": 34154,
                "label": "Saint-Michel-sur-Ternoise",
                "label_ar": "سان ميشيل سور ترنوز",
                "label_fr": "Saint-Michel-sur-Ternoise"
            },
            {
                "value": 34155,
                "label": "Capelle",
                "label_ar": "كابيل",
                "label_fr": "Capelle"
            },
            {
                "value": 34156,
                "label": "Neuvireuil",
                "label_ar": "نيوفيرويل",
                "label_fr": "Neuvireuil"
            },
            {
                "value": 34157,
                "label": "Hamel",
                "label_ar": "هامل",
                "label_fr": "Hamel"
            },
            {
                "value": 34158,
                "label": "Gruson",
                "label_ar": "جروسون",
                "label_fr": "Gruson"
            },
            {
                "value": 34159,
                "label": "Vincly",
                "label_ar": "فينسلي",
                "label_fr": "Vincly"
            },
            {
                "value": 34160,
                "label": "Avesnes-les-Aubert",
                "label_ar": "Avesnes-les-Aubert",
                "label_fr": "Avesnes-les-Aubert"
            },
            {
                "value": 34161,
                "label": "Blacy",
                "label_ar": "بلاسي",
                "label_fr": "Blacy"
            },
            {
                "value": 34162,
                "label": "Sainte-Preuve",
                "label_ar": "سانت بريف",
                "label_fr": "Sainte-Preuve"
            },
            {
                "value": 34163,
                "label": "Montagny-Sainte-Felicite",
                "label_ar": "مونتاني سانت فيليسيت",
                "label_fr": "Montagny-Sainte-Félicité"
            },
            {
                "value": 34164,
                "label": "Droupt-Saint-Basle",
                "label_ar": "دروب سانت بازل",
                "label_fr": "Droupt-Saint-Bâle"
            },
            {
                "value": 34165,
                "label": "Vailly-sur-Aisne",
                "label_ar": "Vailly-sur-Aisne",
                "label_fr": "Vailly-sur-Aisne"
            },
            {
                "value": 34166,
                "label": "Monceau-Saint-Waast",
                "label_ar": "Monceau-Saint-Waast",
                "label_fr": "Monceau-Saint-Waast"
            },
            {
                "value": 34167,
                "label": "Monchy-Breton",
                "label_ar": "مونشي بريتون",
                "label_fr": "Monchy-Breton"
            },
            {
                "value": 34168,
                "label": "Damery",
                "label_ar": "دميري",
                "label_fr": "Damery"
            },
            {
                "value": 34169,
                "label": "Saint-Leger-sous-Brienne",
                "label_ar": "سان ليجيه سوس برين",
                "label_fr": "Saint-Léger-sous-Brienne"
            },
            {
                "value": 34170,
                "label": "Arrigny",
                "label_ar": "اريني",
                "label_fr": "Arrigny"
            },
            {
                "value": 34171,
                "label": "Vauchonvilliers",
                "label_ar": "فوشونفيلييه",
                "label_fr": "Vauchonvilliers"
            },
            {
                "value": 34172,
                "label": "Fontaine-au-Bois",
                "label_ar": "فونتين أو بوا",
                "label_fr": "Fontaine-au-Bois"
            },
            {
                "value": 34173,
                "label": "La Calotterie",
                "label_ar": "لا كالوتري",
                "label_fr": "La Calotterie"
            },
            {
                "value": 34174,
                "label": "Gouy-sous-Bellonne",
                "label_ar": "جوي سوس بيلون",
                "label_fr": "Gouy-sous-Bellonne"
            },
            {
                "value": 34175,
                "label": "Jonvelle",
                "label_ar": "جونفيل",
                "label_fr": "Jonvelle"
            },
            {
                "value": 34176,
                "label": "Saulon-la-Rue",
                "label_ar": "Saulon-la-Rue",
                "label_fr": "Saulon-la-Rue"
            },
            {
                "value": 34177,
                "label": "Surgy",
                "label_ar": "سورجي",
                "label_fr": "Chirurgie"
            },
            {
                "value": 34178,
                "label": "Mantry",
                "label_ar": "مانتري",
                "label_fr": "Mantry"
            },
            {
                "value": 34179,
                "label": "Pasques",
                "label_ar": "باسك",
                "label_fr": "Pasques"
            },
            {
                "value": 34180,
                "label": "Goxwiller",
                "label_ar": "Goxwiller",
                "label_fr": "Goxwiller"
            },
            {
                "value": 34181,
                "label": "Preuschdorf",
                "label_ar": "بريوشدورف",
                "label_fr": "Preuschdorf"
            },
            {
                "value": 34182,
                "label": "Sand",
                "label_ar": "الرمل",
                "label_fr": "Le sable"
            },
            {
                "value": 34183,
                "label": "Michelbach-le-Bas",
                "label_ar": "ميشيلباخ لو باس",
                "label_fr": "Michelbach-le-Bas"
            },
            {
                "value": 34184,
                "label": "Tronville-en-Barrois",
                "label_ar": "ترونفيل أون باروا",
                "label_fr": "Tronville-en-Barrois"
            },
            {
                "value": 34185,
                "label": "Dampvalley-les-Colombe",
                "label_ar": "Dampvalley-les-Colombe",
                "label_fr": "Dampvalley-les-Colombe"
            },
            {
                "value": 34186,
                "label": "Bersaillin",
                "label_ar": "بيرسايلين",
                "label_fr": "Bersaillin"
            },
            {
                "value": 34187,
                "label": "Civry-en-Montagne",
                "label_ar": "سيفري أون مونتاني",
                "label_fr": "Civry-en-Montagne"
            },
            {
                "value": 34188,
                "label": "Denevre",
                "label_ar": "دنفر",
                "label_fr": "Denevre"
            },
            {
                "value": 34189,
                "label": "Chapelle-Voland",
                "label_ar": "شابيل فولاند",
                "label_fr": "Chapelle-Voland"
            },
            {
                "value": 34190,
                "label": "Painblanc",
                "label_ar": "بين بلانك",
                "label_fr": "Painblanc"
            },
            {
                "value": 34191,
                "label": "Dezize-les-Maranges",
                "label_ar": "Dezize-les-Maranges",
                "label_fr": "Dezize-les-Maranges"
            },
            {
                "value": 34192,
                "label": "Abbans-Dessus",
                "label_ar": "أبانس ديسوس",
                "label_fr": "Abbans-Dessus"
            },
            {
                "value": 34193,
                "label": "Kriegsheim",
                "label_ar": "كريجسهايم",
                "label_fr": "Kriegsheim"
            },
            {
                "value": 34194,
                "label": "Schillersdorf",
                "label_ar": "شيلرسدورف",
                "label_fr": "Schillersdorf"
            },
            {
                "value": 34195,
                "label": "Sondernach",
                "label_ar": "Sondernach",
                "label_fr": "Sondernach"
            },
            {
                "value": 34196,
                "label": "Houssen",
                "label_ar": "حسين",
                "label_fr": "Houssen"
            },
            {
                "value": 34197,
                "label": "La Madeleine",
                "label_ar": "لا مادلين",
                "label_fr": "La Madeleine"
            },
            {
                "value": 34198,
                "label": "Vervezelle",
                "label_ar": "فيرفزيل",
                "label_fr": "Vervezelle"
            },
            {
                "value": 34199,
                "label": "Lucey",
                "label_ar": "لوسي",
                "label_fr": "Lucey"
            },
            {
                "value": 34200,
                "label": "Pouillenay",
                "label_ar": "Pouillenay",
                "label_fr": "Pouillenay"
            },
            {
                "value": 34201,
                "label": "Billey",
                "label_ar": "بيلي",
                "label_fr": "Billey"
            },
            {
                "value": 34202,
                "label": "Simandre",
                "label_ar": "سيماندري",
                "label_fr": "Simandre"
            },
            {
                "value": 34203,
                "label": "Blodelsheim",
                "label_ar": "بلودلسهايم",
                "label_fr": "Blodelsheim"
            },
            {
                "value": 34204,
                "label": "Chasnais",
                "label_ar": "Chasnais",
                "label_fr": "Chasnais"
            },
            {
                "value": 34205,
                "label": "Lombron",
                "label_ar": "لومبرون",
                "label_fr": "Lombron"
            },
            {
                "value": 34206,
                "label": "Saint-Paul-en-Pareds",
                "label_ar": "سان بول إن باريدز",
                "label_fr": "Saint-Paul-en-Pareds"
            },
            {
                "value": 34207,
                "label": "Ballon",
                "label_ar": "بالون",
                "label_fr": "Ballon"
            },
            {
                "value": 34208,
                "label": "Verneil-le-Chetif",
                "label_ar": "فيرنيل لو شيتيف",
                "label_fr": "Verneil-le-Chetif"
            },
            {
                "value": 34209,
                "label": "Mont",
                "label_ar": "مونت",
                "label_fr": "Mont"
            },
            {
                "value": 34210,
                "label": "Coulombiers",
                "label_ar": "كولومبييرز",
                "label_fr": "Coulombiers"
            },
            {
                "value": 34211,
                "label": "Saint-Michel-Mont-Mercure",
                "label_ar": "سان ميشيل مونت ميركيور",
                "label_fr": "Saint-Michel-Mont-Mercure"
            },
            {
                "value": 34212,
                "label": "Sceaux-d'Anjou",
                "label_ar": "سكو دانجو",
                "label_fr": "Sceaux-d'Anjou"
            },
            {
                "value": 34213,
                "label": "Cosmes",
                "label_ar": "كوزميس",
                "label_fr": "Cosmes"
            },
            {
                "value": 34214,
                "label": "Bonnetable",
                "label_ar": "بونيتابل",
                "label_fr": "Bonnetable"
            },
            {
                "value": 34215,
                "label": "Lassay-les-Chateaux",
                "label_ar": "لاساي لي شاتو",
                "label_fr": "Lassay-les-Châteaux"
            },
            {
                "value": 34216,
                "label": "Saint-Mars-d'Outille",
                "label_ar": "سان مارس دوتيل",
                "label_fr": "Saint-Mars-d'Outille"
            },
            {
                "value": 34217,
                "label": "Saint-Michel-Treve",
                "label_ar": "سان ميشيل تريف",
                "label_fr": "Saint-Michel-Treve"
            },
            {
                "value": 34218,
                "label": "Bouloire",
                "label_ar": "بولوار",
                "label_fr": "Bouloire"
            },
            {
                "value": 34219,
                "label": "Oisseau",
                "label_ar": "أويسو",
                "label_fr": "Oisseau"
            },
            {
                "value": 34220,
                "label": "Quilly",
                "label_ar": "كيلي",
                "label_fr": "Quilly"
            },
            {
                "value": 34221,
                "label": "Louplande",
                "label_ar": "لابلاند",
                "label_fr": "Louplande"
            },
            {
                "value": 34222,
                "label": "Sion-les-Mines",
                "label_ar": "سيون ليه ماينز",
                "label_fr": "Sion-les-Mines"
            },
            {
                "value": 34223,
                "label": "Vouvant",
                "label_ar": "فوفانت",
                "label_fr": "Vouvant"
            },
            {
                "value": 34224,
                "label": "Saint-Georges-de-Montaigu",
                "label_ar": "Saint-Georges-de-Montaigu",
                "label_fr": "Saint-Georges-de-Montaigu"
            },
            {
                "value": 34225,
                "label": "Gennes",
                "label_ar": "جين",
                "label_fr": "Gennes"
            },
            {
                "value": 34226,
                "label": "Beaurepaire",
                "label_ar": "بيوريباير",
                "label_fr": "Beaurepaire"
            },
            {
                "value": 34227,
                "label": "Le Breil",
                "label_ar": "لو بريل",
                "label_fr": "Le Breil"
            },
            {
                "value": 34228,
                "label": "Castera-Loubix",
                "label_ar": "كاستيرا لوبيكس",
                "label_fr": "Castera-Loubix"
            },
            {
                "value": 34229,
                "label": "Lasseran",
                "label_ar": "لاسيران",
                "label_fr": "Lasseran"
            },
            {
                "value": 34230,
                "label": "Buzy",
                "label_ar": "الطنانة",
                "label_fr": "Buzy"
            },
            {
                "value": 34231,
                "label": "Solferino",
                "label_ar": "سولفرينو",
                "label_fr": "Solférino"
            },
            {
                "value": 34232,
                "label": "Brignac-la-Plaine",
                "label_ar": "بريجناك لا بلين",
                "label_fr": "Brignac-la-Plaine"
            },
            {
                "value": 34233,
                "label": "Birac-sur-Trec",
                "label_ar": "بيراك سور تريك",
                "label_fr": "Birac-sur-Trec"
            },
            {
                "value": 34234,
                "label": "La Roche",
                "label_ar": "لاروش",
                "label_fr": "La Roche"
            },
            {
                "value": 34235,
                "label": "Saint-Sardos",
                "label_ar": "سان ساردوس",
                "label_fr": "Saint-Sardos"
            },
            {
                "value": 34236,
                "label": "Montvalent",
                "label_ar": "مونتفالينت",
                "label_fr": "Montvalent"
            },
            {
                "value": 34237,
                "label": "Entraygues-sur-Truyere",
                "label_ar": "Entraygues-sur-Truyere",
                "label_fr": "Entraygues-sur-Truyere"
            },
            {
                "value": 34238,
                "label": "Fargues",
                "label_ar": "فارجيس",
                "label_fr": "Fargues"
            },
            {
                "value": 34239,
                "label": "Saint-Cezert",
                "label_ar": "سان سيزرت",
                "label_fr": "Saint-Cezert"
            },
            {
                "value": 34240,
                "label": "Saint-Xandre",
                "label_ar": "سان زاندر",
                "label_fr": "Saint-Xandre"
            },
            {
                "value": 34241,
                "label": "Coulaures",
                "label_ar": "كولوريس",
                "label_fr": "Coulaures"
            },
            {
                "value": 34242,
                "label": "La Ville",
                "label_ar": "لا فيل",
                "label_fr": "La Ville"
            },
            {
                "value": 34243,
                "label": "Soulac-sur-Mer",
                "label_ar": "سولاك سور مير",
                "label_fr": "Soulac-sur-Mer"
            },
            {
                "value": 34244,
                "label": "Surba",
                "label_ar": "سوربا",
                "label_fr": "Surba"
            },
            {
                "value": 34245,
                "label": "Orgueil",
                "label_ar": "أورجيل",
                "label_fr": "Orgueil"
            },
            {
                "value": 34246,
                "label": "Boos",
                "label_ar": "بوو",
                "label_fr": "Boos"
            },
            {
                "value": 34247,
                "label": "Anche",
                "label_ar": "أنش",
                "label_fr": "Anche"
            },
            {
                "value": 34248,
                "label": "Saint-Pierre-de-Trivisy",
                "label_ar": "سان بيير دي تريفيزي",
                "label_fr": "Saint-Pierre-de-Trivisy"
            },
            {
                "value": 34249,
                "label": "Saint-Georges-de-Luzencon",
                "label_ar": "سان جورج دي لوزينكون",
                "label_fr": "Saint-Georges-de-Luzencon"
            },
            {
                "value": 34250,
                "label": "Clairac",
                "label_ar": "كلايراك",
                "label_fr": "Clairac"
            },
            {
                "value": 34251,
                "label": "Sainte-Marie-de-Chignac",
                "label_ar": "سانت ماري دي شينياك",
                "label_fr": "Sainte-Marie-de-Chignac"
            },
            {
                "value": 34252,
                "label": "Bellefond",
                "label_ar": "بلفوند",
                "label_fr": "Bellefond"
            },
            {
                "value": 34253,
                "label": "Savignac-sur-Leyze",
                "label_ar": "سافينياك سور ليز",
                "label_fr": "Savignac-sur-Leyze"
            },
            {
                "value": 34254,
                "label": "Romagne",
                "label_ar": "رومان",
                "label_fr": "Romagne"
            },
            {
                "value": 34255,
                "label": "Montrem",
                "label_ar": "مونتريم",
                "label_fr": "Montrem"
            },
            {
                "value": 34256,
                "label": "Vabres-l'Abbaye",
                "label_ar": "Vabres-l'Abbaye",
                "label_fr": "Vabres-l'Abbaye"
            },
            {
                "value": 34257,
                "label": "Juilles",
                "label_ar": "Juilles",
                "label_fr": "Juilles"
            },
            {
                "value": 34258,
                "label": "Saurat",
                "label_ar": "سورات",
                "label_fr": "Saurat"
            },
            {
                "value": 34259,
                "label": "Lavit",
                "label_ar": "لافيت",
                "label_fr": "Lavit"
            },
            {
                "value": 34260,
                "label": "Flamarens",
                "label_ar": "فلامارينس",
                "label_fr": "Flamarens"
            },
            {
                "value": 34261,
                "label": "Vandre",
                "label_ar": "فاندري",
                "label_fr": "Vandre"
            },
            {
                "value": 34262,
                "label": "Loubes-Bernac",
                "label_ar": "لوبس بيرناك",
                "label_fr": "Loubes-Bernac"
            },
            {
                "value": 34263,
                "label": "Baurech",
                "label_ar": "بوريش",
                "label_fr": "Baurech"
            },
            {
                "value": 34264,
                "label": "Sarliac-sur-lIsle",
                "label_ar": "سارلياك سور ليل",
                "label_fr": "Sarliac-sur-lIsle"
            },
            {
                "value": 34265,
                "label": "Cottun",
                "label_ar": "كوتون",
                "label_fr": "Cottun"
            },
            {
                "value": 34266,
                "label": "Gesnes-le-Gandelin",
                "label_ar": "Gesnes-le-Gandelin",
                "label_fr": "Gesnes-le-Gandelin"
            },
            {
                "value": 34267,
                "label": "Crouay",
                "label_ar": "كراواي",
                "label_fr": "Crouay"
            },
            {
                "value": 34268,
                "label": "Tocqueville",
                "label_ar": "توكفيل",
                "label_fr": "Tocqueville"
            },
            {
                "value": 34269,
                "label": "Le Mesnil-Villeman",
                "label_ar": "لو ميسنيل فيلمان",
                "label_fr": "Le Mesnil-Villeman"
            },
            {
                "value": 34270,
                "label": "Baupte",
                "label_ar": "بوبت",
                "label_fr": "Baupte"
            },
            {
                "value": 34271,
                "label": "Carantilly",
                "label_ar": "كارانتيلي",
                "label_fr": "Carantilly"
            },
            {
                "value": 34272,
                "label": "Saint-Malo-de-la-Lande",
                "label_ar": "سان مالو دي لا لاندي",
                "label_fr": "Saint-Malo-de-la-Lande"
            },
            {
                "value": 34273,
                "label": "Le Tronquay",
                "label_ar": "لو ترونكوي",
                "label_fr": "Le Tronquay"
            },
            {
                "value": 34274,
                "label": "Saint-Sauveur-Lendelin",
                "label_ar": "سان سوفور لينديلين",
                "label_fr": "Saint-Sauveur-Lendelin"
            },
            {
                "value": 34275,
                "label": "Vieux-Fume",
                "label_ar": "Vieux-Fume",
                "label_fr": "Vieux-Fume"
            },
            {
                "value": 34276,
                "label": "Hocquigny",
                "label_ar": "هوكيني",
                "label_fr": "Hocquigny"
            },
            {
                "value": 34277,
                "label": "Ger",
                "label_ar": "جير",
                "label_fr": "Ger"
            },
            {
                "value": 34278,
                "label": "Montmartin-en-Graignes",
                "label_ar": "مونتمارتين أون غراينيس",
                "label_fr": "Montmartin-en-Graignes"
            },
            {
                "value": 34279,
                "label": "Le Pin-la-Garenne",
                "label_ar": "لو بين لا غارين",
                "label_fr": "Le Pin-la-Garenne"
            },
            {
                "value": 34280,
                "label": "Fresne-la-Mere",
                "label_ar": "فريسن لا مير",
                "label_fr": "Fresne-la-Mère"
            },
            {
                "value": 34281,
                "label": "Saint-Gatien-des-Bois",
                "label_ar": "سان جاتيان دي بوا",
                "label_fr": "Saint-Gatien-des-Bois"
            },
            {
                "value": 34282,
                "label": "Sainte-Croix-Grand-Tonne",
                "label_ar": "سانت كروا غراند تون",
                "label_fr": "Sainte-Croix-Grand-Tonne"
            },
            {
                "value": 34283,
                "label": "Luneray",
                "label_ar": "لونراي",
                "label_fr": "Luneray"
            },
            {
                "value": 34284,
                "label": "Saint-Pierre-de-Varengeville",
                "label_ar": "سان بيير دي فارينجفيل",
                "label_fr": "Saint-Pierre-de-Varengeville"
            },
            {
                "value": 34285,
                "label": "Fesques",
                "label_ar": "فيسك",
                "label_fr": "Fesques"
            },
            {
                "value": 34286,
                "label": "Beaussault",
                "label_ar": "بوسولت",
                "label_fr": "Beaussault"
            },
            {
                "value": 34287,
                "label": "Guiseniers",
                "label_ar": "جيسينيرس",
                "label_fr": "Guiseniers"
            },
            {
                "value": 34288,
                "label": "Ouville",
                "label_ar": "أوفيل",
                "label_fr": "Ouville"
            },
            {
                "value": 34289,
                "label": "Saint-Leger-de-Rotes",
                "label_ar": "سان ليجيه دي روتس",
                "label_fr": "Saint-Léger-de-Rotes"
            },
            {
                "value": 34290,
                "label": "Saint-Pierre-Lavis",
                "label_ar": "سان بيير لافيس",
                "label_fr": "Saint-Pierre-Lavis"
            },
            {
                "value": 34291,
                "label": "Hodeng-Hodenger",
                "label_ar": "هودينج هودينجر",
                "label_fr": "Hodeng-Hodenger"
            },
            {
                "value": 34292,
                "label": "Saint-Denis-le-Thiboult",
                "label_ar": "سان دوني لو ثيبولت",
                "label_fr": "Saint-Denis-le-Thiboult"
            },
            {
                "value": 34293,
                "label": "Pissy-Poville",
                "label_ar": "بيسي بوفيل",
                "label_fr": "Pissy-Poville"
            },
            {
                "value": 34294,
                "label": "Thietreville",
                "label_ar": "ثيتريفيل",
                "label_fr": "Thietreville"
            },
            {
                "value": 34295,
                "label": "Nonancourt",
                "label_ar": "نونانكورت",
                "label_fr": "Nonancourt"
            },
            {
                "value": 34296,
                "label": "Quincampoix",
                "label_ar": "كوينكامبويكس",
                "label_fr": "Quincampoix"
            },
            {
                "value": 34297,
                "label": "Illois",
                "label_ar": "إيلوا",
                "label_fr": "Illois"
            },
            {
                "value": 34298,
                "label": "Bacqueville",
                "label_ar": "باكفيل",
                "label_fr": "Bacqueville"
            },
            {
                "value": 34299,
                "label": "La Hallotiere",
                "label_ar": "لا هالوتيير",
                "label_fr": "La Hallotière"
            },
            {
                "value": 34300,
                "label": "Cottevrard",
                "label_ar": "كوتيفرار",
                "label_fr": "Cottevrard"
            },
            {
                "value": 34301,
                "label": "Alvimare",
                "label_ar": "ألفيمار",
                "label_fr": "Alvimare"
            },
            {
                "value": 34302,
                "label": "Gremonville",
                "label_ar": "جريمونفيل",
                "label_fr": "Gremonville"
            },
            {
                "value": 34303,
                "label": "Saint-Saire",
                "label_ar": "سان ساير",
                "label_fr": "Saint-Saire"
            },
            {
                "value": 34304,
                "label": "Flancourt-Catelon",
                "label_ar": "فلانكور كاتيلون",
                "label_fr": "Flancourt-Catelon"
            },
            {
                "value": 34305,
                "label": "Epinay",
                "label_ar": "Epinay",
                "label_fr": "Epinay"
            },
            {
                "value": 34306,
                "label": "Gonneville-la-Mallet",
                "label_ar": "جونفيل لا ماليت",
                "label_fr": "Gonneville-la-Mallet"
            },
            {
                "value": 34307,
                "label": "Peisey-Nancroix",
                "label_ar": "Peisey-Nancroix",
                "label_fr": "Peisey-Nancroix"
            },
            {
                "value": 34308,
                "label": "Vimines",
                "label_ar": "فايمينز",
                "label_fr": "Vimines"
            },
            {
                "value": 34309,
                "label": "Saint-Avre",
                "label_ar": "سانت افر",
                "label_fr": "Saint-Avre"
            },
            {
                "value": 34310,
                "label": "Dionay",
                "label_ar": "ديوناي",
                "label_fr": "Dionay"
            },
            {
                "value": 34311,
                "label": "Méribel",
                "label_ar": "ميريبيل",
                "label_fr": "Méribel"
            },
            {
                "value": 34312,
                "label": "Etable",
                "label_ar": "إيتابل",
                "label_fr": "Etable"
            },
            {
                "value": 34313,
                "label": "Felines",
                "label_ar": "الماكرون",
                "label_fr": "Félins"
            },
            {
                "value": 34314,
                "label": "Laure",
                "label_ar": "لور",
                "label_fr": "Laure"
            },
            {
                "value": 34315,
                "label": "Saint-Maurice-de-Cazevieille",
                "label_ar": "سان موريس دي كازيفييل",
                "label_fr": "Saint-Maurice-de-Cazevieille"
            },
            {
                "value": 34316,
                "label": "Thurins",
                "label_ar": "ثورينز",
                "label_fr": "Thurins"
            },
            {
                "value": 34317,
                "label": "Toulaud",
                "label_ar": "تولود",
                "label_fr": "Toulaud"
            },
            {
                "value": 34318,
                "label": "Le Cergne",
                "label_ar": "لو سيرجن",
                "label_fr": "Le Cergne"
            },
            {
                "value": 34319,
                "label": "Montaulieu",
                "label_ar": "مونتوليو",
                "label_fr": "Montaulieu"
            },
            {
                "value": 34320,
                "label": "Auberives-sur-Vareze",
                "label_ar": "Auberives-sur-Vareze",
                "label_fr": "Auberives-sur-Varèze"
            },
            {
                "value": 34321,
                "label": "Jonage",
                "label_ar": "جوناج",
                "label_fr": "Jonage"
            },
            {
                "value": 34322,
                "label": "Saint-Victor-de-Cessieu",
                "label_ar": "سان فيكتور دي سيسيو",
                "label_fr": "Saint-Victor-de-Cessieu"
            },
            {
                "value": 34323,
                "label": "Pact",
                "label_ar": "حلف",
                "label_fr": "Pacte"
            },
            {
                "value": 34324,
                "label": "Mercurol",
                "label_ar": "ميركورول",
                "label_fr": "Mercurol"
            },
            {
                "value": 34325,
                "label": "Grandris",
                "label_ar": "غراندريس",
                "label_fr": "Grandris"
            },
            {
                "value": 34326,
                "label": "Siccieu-Saint-Julien-et-Carisieu",
                "label_ar": "Siccieu-Saint-Julien-et-Carisieu",
                "label_fr": "Siccieu-Saint-Julien-et-Carisieu"
            },
            {
                "value": 34327,
                "label": "Lamastre",
                "label_ar": "لامستر",
                "label_fr": "Lamastre"
            },
            {
                "value": 34328,
                "label": "Margerie-Chantagret",
                "label_ar": "مارجري شانتاغريت",
                "label_fr": "Margerie-Chantagret"
            },
            {
                "value": 34329,
                "label": "Les Cheres",
                "label_ar": "ليه شيريز",
                "label_fr": "Les Cheres"
            },
            {
                "value": 34330,
                "label": "Mouilleron-en-Pareds",
                "label_ar": "Mouilleron-en-Pareds",
                "label_fr": "Mouilleron-en-Pareds"
            },
            {
                "value": 34331,
                "label": "Saint-Hilaire-de-Voust",
                "label_ar": "سان هيلير دي فوست",
                "label_fr": "Saint-Hilaire-de-Voust"
            },
            {
                "value": 34332,
                "label": "Villebernier",
                "label_ar": "فيلبرنييه",
                "label_fr": "Villebernier"
            },
            {
                "value": 34333,
                "label": "Pannece",
                "label_ar": "بانيس",
                "label_fr": "Pannece"
            },
            {
                "value": 34334,
                "label": "Conquereuil",
                "label_ar": "الفتح",
                "label_fr": "Conquereuil"
            },
            {
                "value": 34335,
                "label": "Monsireigne",
                "label_ar": "Monsireigne",
                "label_fr": "Monsireigne"
            },
            {
                "value": 34336,
                "label": "Saint-Leger-sous-Cholet",
                "label_ar": "سان ليجيه سو شوليه",
                "label_fr": "Saint-Léger-sous-Cholet"
            },
            {
                "value": 34337,
                "label": "Gesvres",
                "label_ar": "جيسفريس",
                "label_fr": "Gesvres"
            },
            {
                "value": 34338,
                "label": "Anetz",
                "label_ar": "أنتز",
                "label_fr": "Anetz"
            },
            {
                "value": 34339,
                "label": "Vouille-les-Marais",
                "label_ar": "Vouille-les-Marais",
                "label_fr": "Vouille-les-Marais"
            },
            {
                "value": 34340,
                "label": "Chanteloup-en-Brie",
                "label_ar": "Chanteloup-en-Brie",
                "label_fr": "Chanteloup-en-Brie"
            },
            {
                "value": 34341,
                "label": "Gee",
                "label_ar": "جي",
                "label_fr": "Gee"
            },
            {
                "value": 34342,
                "label": "Le Thoureil",
                "label_ar": "لو ثوريل",
                "label_fr": "Le Thoureil"
            },
            {
                "value": 34343,
                "label": "Blou",
                "label_ar": "بلو",
                "label_fr": "Blou"
            },
            {
                "value": 34344,
                "label": "Saint-Pierre-du-Chemin",
                "label_ar": "سان بيير دو تشيمين",
                "label_fr": "Saint-Pierre-du-Chemin"
            },
            {
                "value": 34345,
                "label": "Echemire",
                "label_ar": "ايكيمير",
                "label_fr": "Echemire"
            },
            {
                "value": 34346,
                "label": "Saint-Christophe-du-Ligneron",
                "label_ar": "سان كريستوف دو ليجنيرون",
                "label_fr": "Saint-Christophe-du-Ligneron"
            },
            {
                "value": 34347,
                "label": "Mazieres-en-Mauges",
                "label_ar": "Mazieres-en-Mauges",
                "label_fr": "Mazières-en-Mauges"
            },
            {
                "value": 34348,
                "label": "Saint-Martin-des-Noyers",
                "label_ar": "سان مارتن دي نوير",
                "label_fr": "Saint-Martin-des-Noyers"
            },
            {
                "value": 34349,
                "label": "Saint-Georges-du-Rosay",
                "label_ar": "سان جورج دو روساي",
                "label_fr": "Saint-Georges-du-Rosay"
            },
            {
                "value": 34350,
                "label": "Jallais",
                "label_ar": "جلايس",
                "label_fr": "Jallais"
            },
            {
                "value": 34351,
                "label": "Guecelard",
                "label_ar": "جيسيلارد",
                "label_fr": "Guecelard"
            },
            {
                "value": 34352,
                "label": "La Cornuaille",
                "label_ar": "لا كورنويل",
                "label_fr": "La Cornuaille"
            },
            {
                "value": 34353,
                "label": "Saint-Cyr-en-Pail",
                "label_ar": "سان سير أون بيل",
                "label_fr": "Saint-Cyr-en-Pail"
            },
            {
                "value": 34354,
                "label": "Puceul",
                "label_ar": "بوسول",
                "label_fr": "Puceul"
            },
            {
                "value": 34355,
                "label": "Getigne",
                "label_ar": "Getigne",
                "label_fr": "Getigne"
            },
            {
                "value": 34356,
                "label": "Roche",
                "label_ar": "روش",
                "label_fr": "Roche"
            },
            {
                "value": 34357,
                "label": "Les Pins",
                "label_ar": "ليه بينس",
                "label_fr": "Les Pins"
            },
            {
                "value": 34358,
                "label": "Saint-Germain-sur-Moine",
                "label_ar": "سان جيرمان سور موين",
                "label_fr": "Saint-Germain-sur-Moine"
            },
            {
                "value": 34359,
                "label": "Soulge-sur-Ouette",
                "label_ar": "Soulge-sur-Ouette",
                "label_fr": "Soulge-sur-Ouette"
            },
            {
                "value": 34360,
                "label": "Mauves-sur-Loire",
                "label_ar": "موفيس سور لوار",
                "label_fr": "Mauves-sur-Loire"
            },
            {
                "value": 34361,
                "label": "Saint-Domet",
                "label_ar": "سان دوميت",
                "label_fr": "Saint-Domet"
            },
            {
                "value": 34362,
                "label": "Croze",
                "label_ar": "كروز",
                "label_fr": "Croze"
            },
            {
                "value": 34363,
                "label": "Lorignac",
                "label_ar": "لوريجناك",
                "label_fr": "Lorignac"
            },
            {
                "value": 34364,
                "label": "Conceze",
                "label_ar": "كونسيز",
                "label_fr": "Conceze"
            },
            {
                "value": 34365,
                "label": "Frechede",
                "label_ar": "فريتشيدي",
                "label_fr": "Frechede"
            },
            {
                "value": 34366,
                "label": "Mauran",
                "label_ar": "موران",
                "label_fr": "Mauran"
            },
            {
                "value": 34367,
                "label": "Valence",
                "label_ar": "التكافؤ",
                "label_fr": "Valence"
            },
            {
                "value": 34368,
                "label": "Giroussens",
                "label_ar": "جيروسنس",
                "label_fr": "Giroussens"
            },
            {
                "value": 34369,
                "label": "Budos",
                "label_ar": "بودوس",
                "label_fr": "Budos"
            },
            {
                "value": 34370,
                "label": "Ayguemorte-les-Graves",
                "label_ar": "Ayguemorte-les-Graves",
                "label_fr": "Ayguemorte-les-Graves"
            },
            {
                "value": 34371,
                "label": "Pessac-sur-Dordogne",
                "label_ar": "بيساك سور دوردوني",
                "label_fr": "Pessac-sur-Dordogne"
            },
            {
                "value": 34372,
                "label": "Saint-Laurent-du-Bois",
                "label_ar": "سان لوران دو بوا",
                "label_fr": "Saint-Laurent-du-Bois"
            },
            {
                "value": 34373,
                "label": "Arnac-Pompadour",
                "label_ar": "أرناك بومبادور",
                "label_fr": "Arnac-Pompadour"
            },
            {
                "value": 34374,
                "label": "Lariviere-Arnoncourt",
                "label_ar": "Lariviere-Arnoncourt",
                "label_fr": "Larivière-Arnoncourt"
            },
            {
                "value": 34375,
                "label": "Yvrac-et-Malleyrand",
                "label_ar": "Yvrac-et-Malleyrand",
                "label_fr": "Yvrac-et-Malleyrand"
            },
            {
                "value": 34376,
                "label": "Mauremont",
                "label_ar": "موريمونت",
                "label_fr": "Mauremont"
            },
            {
                "value": 34377,
                "label": "Causse-de-la-Selle",
                "label_ar": "Causse-de-la-Selle",
                "label_fr": "Causse-de-la-Selle"
            },
            {
                "value": 34378,
                "label": "Montredon-Labessonnie",
                "label_ar": "مونتريدون لابيسوني",
                "label_fr": "Montredon-Labessonnie"
            },
            {
                "value": 34379,
                "label": "Fonbeauzard",
                "label_ar": "فونبيوزارد",
                "label_fr": "Fonbeauzard"
            },
            {
                "value": 34380,
                "label": "Labouquerie",
                "label_ar": "لابوكيري",
                "label_fr": "Labouquerie"
            },
            {
                "value": 34381,
                "label": "Peyrat-le-Chateau",
                "label_ar": "بيرات لو شاتو",
                "label_fr": "Peyrat-le-Château"
            },
            {
                "value": 34382,
                "label": "Condeon",
                "label_ar": "كونديون",
                "label_fr": "Condéon"
            },
            {
                "value": 34383,
                "label": "Merignac",
                "label_ar": "ميريناك",
                "label_fr": "Mérignac"
            },
            {
                "value": 34384,
                "label": "Sauveterre-de-Guyenne",
                "label_ar": "Sauveterre-de-Guyenne",
                "label_fr": "Sauveterre-de-Guyenne"
            },
            {
                "value": 34385,
                "label": "Septfonds",
                "label_ar": "سبتفوندس",
                "label_fr": "Septfonds"
            },
            {
                "value": 34386,
                "label": "Cazals-des-Bayles",
                "label_ar": "كازال دي بايل",
                "label_fr": "Cazals-des-Bayles"
            },
            {
                "value": 34387,
                "label": "Labessiere-Candeil",
                "label_ar": "لابسيير كانديل",
                "label_fr": "Labessière-Candeil"
            },
            {
                "value": 34388,
                "label": "Sainte-Foy-de-Peyrolieres",
                "label_ar": "Sainte-Foy-de-Peyrolieres",
                "label_fr": "Sainte-Foy-de-Peyrolières"
            },
            {
                "value": 34389,
                "label": "Bussac-Foret",
                "label_ar": "بوساك فوريه",
                "label_fr": "Bussac-Foret"
            },
            {
                "value": 34390,
                "label": "Orignolles",
                "label_ar": "Orignolles",
                "label_fr": "Orignolles"
            },
            {
                "value": 34391,
                "label": "Cieux",
                "label_ar": "سيوكس",
                "label_fr": "Cieux"
            },
            {
                "value": 34392,
                "label": "Rabou",
                "label_ar": "رابو",
                "label_fr": "Rabou"
            },
            {
                "value": 34393,
                "label": "Roches",
                "label_ar": "روشيه",
                "label_fr": "Roches"
            },
            {
                "value": 34394,
                "label": "La Porcherie",
                "label_ar": "لا بورشيري",
                "label_fr": "La Porcherie"
            },
            {
                "value": 34395,
                "label": "Troche",
                "label_ar": "تروش",
                "label_fr": "Troche"
            },
            {
                "value": 34396,
                "label": "Juillac",
                "label_ar": "جويلاك",
                "label_fr": "Juillac"
            },
            {
                "value": 34397,
                "label": "Brandonnet",
                "label_ar": "براندونيت",
                "label_fr": "Brandonnet"
            },
            {
                "value": 34398,
                "label": "Najac",
                "label_ar": "نجاك",
                "label_fr": "Najac"
            },
            {
                "value": 34399,
                "label": "Courcelles",
                "label_ar": "المحاكم",
                "label_fr": "Courcelles"
            },
            {
                "value": 34400,
                "label": "Rioz",
                "label_ar": "ريوز",
                "label_fr": "Rioz"
            },
            {
                "value": 34401,
                "label": "Prisse",
                "label_ar": "Prisse",
                "label_fr": "Prisse"
            },
            {
                "value": 34402,
                "label": "Autechaux",
                "label_ar": "Autechaux",
                "label_fr": "Autechaux"
            },
            {
                "value": 34403,
                "label": "Fontaine-les-Luxeuil",
                "label_ar": "فونتين ليه لوكسويل",
                "label_fr": "Fontaine-les-Luxeuil"
            },
            {
                "value": 34404,
                "label": "Montaigu",
                "label_ar": "مونتايجو",
                "label_fr": "Montaigu"
            },
            {
                "value": 34405,
                "label": "Saint-Martin-Belle-Roche",
                "label_ar": "سان مارتن بيل روش",
                "label_fr": "Saint-Martin-Belle-Roche"
            },
            {
                "value": 34406,
                "label": "Feche-l'Eglise",
                "label_ar": "Feche-l'Eglise",
                "label_fr": "Feche-l'Eglise"
            },
            {
                "value": 34407,
                "label": "Pruzilly",
                "label_ar": "بروزيلي",
                "label_fr": "Pruzilly"
            },
            {
                "value": 34408,
                "label": "Sermoyer",
                "label_ar": "سيرموير",
                "label_fr": "Sermoyer"
            },
            {
                "value": 34409,
                "label": "Mailly-la-Ville",
                "label_ar": "مايلي لا فيل",
                "label_fr": "Mailly-la-Ville"
            },
            {
                "value": 34410,
                "label": "Saint-Amour",
                "label_ar": "سانت أمور",
                "label_fr": "Saint-Amour"
            },
            {
                "value": 34411,
                "label": "Germigny-sur-Loire",
                "label_ar": "جيرميني سور لوار",
                "label_fr": "Germigny-sur-Loire"
            },
            {
                "value": 34412,
                "label": "Bellevesvre",
                "label_ar": "بلفيسفر",
                "label_fr": "Bellevesvre"
            },
            {
                "value": 34413,
                "label": "Entrains-sur-Nohain",
                "label_ar": "إنتراينز سور نوهاين",
                "label_fr": "Entrains-sur-Nohain"
            },
            {
                "value": 34414,
                "label": "Bassigney",
                "label_ar": "تعيين",
                "label_fr": "Bassigney"
            },
            {
                "value": 34415,
                "label": "Beauvernois",
                "label_ar": "بوفرنوا",
                "label_fr": "Beauvernois"
            },
            {
                "value": 34416,
                "label": "Messigny-et-Vantoux",
                "label_ar": "Messigny-et-Vantoux",
                "label_fr": "Messigny-et-Vantoux"
            },
            {
                "value": 34417,
                "label": "Molinet",
                "label_ar": "مولينيت",
                "label_fr": "Molinet"
            },
            {
                "value": 34418,
                "label": "Glanon",
                "label_ar": "جلانون",
                "label_fr": "Glanon"
            },
            {
                "value": 34419,
                "label": "La Villedieu-les-Quenoche",
                "label_ar": "لا فيليديو ليه كوينوش",
                "label_fr": "La Villedieu-les-Quenoche"
            },
            {
                "value": 34420,
                "label": "Laroche-Saint-Cydroine",
                "label_ar": "Laroche-Saint-Cydroine",
                "label_fr": "Laroche-Saint-Cydroine"
            },
            {
                "value": 34421,
                "label": "Lainsecq",
                "label_ar": "لينسيك",
                "label_fr": "Lainsecq"
            },
            {
                "value": 34422,
                "label": "Bresse-sur-Grosne",
                "label_ar": "بريس سور جروسن",
                "label_fr": "Bresse-sur-Grosne"
            },
            {
                "value": 34423,
                "label": "Verchamp",
                "label_ar": "فيرشامب",
                "label_fr": "Verchamp"
            },
            {
                "value": 34424,
                "label": "Parly",
                "label_ar": "بارلي",
                "label_fr": "Parly"
            },
            {
                "value": 34425,
                "label": "Merey-sous-Montrond",
                "label_ar": "Merey-sous-Montrond",
                "label_fr": "Merey-sous-Montrond"
            },
            {
                "value": 34426,
                "label": "Devrouze",
                "label_ar": "ديفروز",
                "label_fr": "Devrouze"
            },
            {
                "value": 34427,
                "label": "Chagny",
                "label_ar": "تشاجني",
                "label_fr": "Chagny"
            },
            {
                "value": 34428,
                "label": "Saint-Andeux",
                "label_ar": "سانت أنديو",
                "label_fr": "Saint-Andeux"
            },
            {
                "value": 34429,
                "label": "Chassenard",
                "label_ar": "شاسينارد",
                "label_fr": "Chassenard"
            },
            {
                "value": 34430,
                "label": "Lamarche-sur-Saone",
                "label_ar": "لامارش سور سون",
                "label_fr": "Lamarche-sur-Saône"
            },
            {
                "value": 34431,
                "label": "Chargey-les-Port",
                "label_ar": "تشارجي ليه بورت",
                "label_fr": "Chargey-les-Port"
            },
            {
                "value": 34432,
                "label": "Souvans",
                "label_ar": "سوفان",
                "label_fr": "Souvans"
            },
            {
                "value": 34433,
                "label": "Villey-sur-Tille",
                "label_ar": "فيلي سور تيل",
                "label_fr": "Villey-sur-Tille"
            },
            {
                "value": 34434,
                "label": "Boursieres",
                "label_ar": "بورسيريس",
                "label_fr": "Boursières"
            },
            {
                "value": 34435,
                "label": "Bouilly",
                "label_ar": "بويلي",
                "label_fr": "Bouilly"
            },
            {
                "value": 34436,
                "label": "Echevannes",
                "label_ar": "إشيفانيس",
                "label_fr": "Echevannes"
            },
            {
                "value": 34437,
                "label": "Eternoz",
                "label_ar": "إتيرنوز",
                "label_fr": "Eternoz"
            },
            {
                "value": 34438,
                "label": "Vanclans",
                "label_ar": "فانكلانس",
                "label_fr": "Vanclans"
            },
            {
                "value": 34439,
                "label": "Ormoy",
                "label_ar": "أورموي",
                "label_fr": "Ormoy"
            },
            {
                "value": 34440,
                "label": "Saint-Martin-en-Bresse",
                "label_ar": "سان مارتن أون بريس",
                "label_fr": "Saint-Martin-en-Bresse"
            },
            {
                "value": 34441,
                "label": "Chateau-Chinon(Ville)",
                "label_ar": "شاتو شينون (فيل)",
                "label_fr": "Château-Chinon (Ville)"
            },
            {
                "value": 34442,
                "label": "Vaux-sur-Poligny",
                "label_ar": "فو سور بوليني",
                "label_fr": "Vaux-sur-Poligny"
            },
            {
                "value": 34443,
                "label": "Dampierre-les-Conflans",
                "label_ar": "Dampierre-les-Conflans",
                "label_fr": "Dampierre-les-Conflans"
            },
            {
                "value": 34444,
                "label": "Sainpuits",
                "label_ar": "سينبوتس",
                "label_fr": "Sainpuits"
            },
            {
                "value": 34445,
                "label": "Laperriere-sur-Saone",
                "label_ar": "Laperriere-sur-Saone",
                "label_fr": "Laperrière-sur-Saône"
            },
            {
                "value": 34446,
                "label": "Serein",
                "label_ar": "سيرين",
                "label_fr": "Serein"
            },
            {
                "value": 34447,
                "label": "Sornay",
                "label_ar": "سورناي",
                "label_fr": "Sornay"
            },
            {
                "value": 34448,
                "label": "Boz",
                "label_ar": "بوز",
                "label_fr": "Boz"
            },
            {
                "value": 34449,
                "label": "Saint-Usage",
                "label_ar": "سانت الاستخدام",
                "label_fr": "Saint-Usage"
            },
            {
                "value": 34450,
                "label": "Neuilly",
                "label_ar": "نويي",
                "label_fr": "Neuilly"
            },
            {
                "value": 34451,
                "label": "Logrian-Florian",
                "label_ar": "لوجريان فلوريان",
                "label_fr": "Logrian-Florian"
            },
            {
                "value": 34452,
                "label": "Pezens",
                "label_ar": "بيزنز",
                "label_fr": "Pezens"
            },
            {
                "value": 34453,
                "label": "Boucoiran-et-Nozieres",
                "label_ar": "Boucoiran-et-Nozieres",
                "label_fr": "Boucoiran-et-Nozières"
            },
            {
                "value": 34454,
                "label": "Liouc",
                "label_ar": "ليوك",
                "label_fr": "Liouc"
            },
            {
                "value": 34455,
                "label": "Jeune",
                "label_ar": "جون",
                "label_fr": "Jeune"
            },
            {
                "value": 34456,
                "label": "Enveig",
                "label_ar": "إنفيج",
                "label_fr": "Enveig"
            },
            {
                "value": 34457,
                "label": "Barbaira",
                "label_ar": "باربرا",
                "label_fr": "Barbaira"
            },
            {
                "value": 34458,
                "label": "Banassac",
                "label_ar": "بانساك",
                "label_fr": "Banassac"
            },
            {
                "value": 34459,
                "label": "Saint-Privat",
                "label_ar": "سان بريفات",
                "label_fr": "Saint-Privat"
            },
            {
                "value": 34460,
                "label": "Saint-Guilhem-le-Desert",
                "label_ar": "Saint-Guilhem-le-Desert",
                "label_fr": "Saint-Guilhem-le-Désert"
            },
            {
                "value": 34461,
                "label": "Durban-Corbieres",
                "label_ar": "ديربان كوربيريس",
                "label_fr": "Durban-Corbières"
            },
            {
                "value": 34462,
                "label": "Generargues",
                "label_ar": "جنرال",
                "label_fr": "Generargues"
            },
            {
                "value": 34463,
                "label": "Villeveyrac",
                "label_ar": "فيليفيراك",
                "label_fr": "Villeveyrac"
            },
            {
                "value": 34464,
                "label": "Montfrin",
                "label_ar": "مونتفرين",
                "label_fr": "Montfrin"
            },
            {
                "value": 34465,
                "label": "Vic-le-Fesq",
                "label_ar": "Vic-le-Fesq",
                "label_fr": "Vic-le-Fesq"
            },
            {
                "value": 34466,
                "label": "Campagnan",
                "label_ar": "كامبانيان",
                "label_fr": "Campagnan"
            },
            {
                "value": 34467,
                "label": "Combas",
                "label_ar": "كومباس",
                "label_fr": "Combas"
            },
            {
                "value": 34468,
                "label": "Saint-Hippolyte",
                "label_ar": "سانت هيبوليت",
                "label_fr": "Saint-Hippolyte"
            },
            {
                "value": 34469,
                "label": "Mejannes-le-Clap",
                "label_ar": "Mejannes-le-Clap",
                "label_fr": "Méjannes-le-Clap"
            },
            {
                "value": 34470,
                "label": "Murviel-les-Beziers",
                "label_ar": "مورفيل ليس بيزيرز",
                "label_fr": "Murviel-les-Béziers"
            },
            {
                "value": 34471,
                "label": "Fontrabiouse",
                "label_ar": "Fontrabiouse",
                "label_fr": "Fontrabiouse"
            },
            {
                "value": 34472,
                "label": "Nezignan-l'Eveque",
                "label_ar": "Nezignan-l'Eveque",
                "label_fr": "Nézignan-l'Évêque"
            },
            {
                "value": 34473,
                "label": "Le Collet-de-Deze",
                "label_ar": "لو كوليه دي ديزي",
                "label_fr": "Le Collet-de-Deze"
            },
            {
                "value": 34474,
                "label": "Sussargues",
                "label_ar": "سوسارج",
                "label_fr": "Sussargues"
            },
            {
                "value": 34475,
                "label": "Prouzel",
                "label_ar": "بروزيل",
                "label_fr": "Prouzel"
            },
            {
                "value": 34476,
                "label": "Douilly",
                "label_ar": "دويلي",
                "label_fr": "Douilly"
            },
            {
                "value": 34477,
                "label": "Morsain",
                "label_ar": "مرسين",
                "label_fr": "Morsain"
            },
            {
                "value": 34478,
                "label": "Conflans-sur-Seine",
                "label_ar": "كونفلان سور سين",
                "label_fr": "Conflans-sur-Seine"
            },
            {
                "value": 34479,
                "label": "Pargny-la-Dhuys",
                "label_ar": "Pargny-la-Dhuys",
                "label_fr": "Pargny-la-Dhuys"
            },
            {
                "value": 34480,
                "label": "Villequier-Aumont",
                "label_ar": "فيليكييه أومونت",
                "label_fr": "Villequier-Aumont"
            },
            {
                "value": 34481,
                "label": "Flaucourt",
                "label_ar": "فلوكورت",
                "label_fr": "Flaucourt"
            },
            {
                "value": 34482,
                "label": "Mourmelon-le-Petit",
                "label_ar": "Mourmelon-le-Petit",
                "label_fr": "Mourmelon-le-Petit"
            },
            {
                "value": 34483,
                "label": "Saulces-Monclin",
                "label_ar": "سولس مونكلين",
                "label_fr": "Saulces-Monclin"
            },
            {
                "value": 34484,
                "label": "Cuperly",
                "label_ar": "كوبرلي",
                "label_fr": "Cuperly"
            },
            {
                "value": 34485,
                "label": "Haussimont",
                "label_ar": "هوسيمونت",
                "label_fr": "Haussimont"
            },
            {
                "value": 34486,
                "label": "Bazeilles",
                "label_ar": "بازيل",
                "label_fr": "Bazeilles"
            },
            {
                "value": 34487,
                "label": "Puisieulx",
                "label_ar": "Puisieulx",
                "label_fr": "Puisieulx"
            },
            {
                "value": 34488,
                "label": "Vrigne-aux-Bois",
                "label_ar": "Vrigne-aux-Bois",
                "label_fr": "Vrigne-aux-Bois"
            },
            {
                "value": 34489,
                "label": "Semoutiers-Montsaon",
                "label_ar": "Semoutiers مونتسون",
                "label_fr": "Semoutiers-Montsaon"
            },
            {
                "value": 34490,
                "label": "Aulnay-sur-Marne",
                "label_ar": "أولناي سور مارن",
                "label_fr": "Aulnay-sur-Marne"
            },
            {
                "value": 34491,
                "label": "Fossemanant",
                "label_ar": "فوسيمانانت",
                "label_fr": "Fossemanant"
            },
            {
                "value": 34492,
                "label": "Flavy-le-Martel",
                "label_ar": "فلافي لو مارتل",
                "label_fr": "Flavy-le-Martel"
            },
            {
                "value": 34493,
                "label": "Froidestrees",
                "label_ar": "فرويدستريز",
                "label_fr": "Froidestrees"
            },
            {
                "value": 34494,
                "label": "Crugny",
                "label_ar": "كرغني",
                "label_fr": "Crugny"
            },
            {
                "value": 34495,
                "label": "Autreville",
                "label_ar": "أوتريفيل",
                "label_fr": "Autreville"
            },
            {
                "value": 34496,
                "label": "Boubiers",
                "label_ar": "بوبييرز",
                "label_fr": "Boubiers"
            },
            {
                "value": 34497,
                "label": "Bichancourt",
                "label_ar": "بيشانكورت",
                "label_fr": "Bichancourt"
            },
            {
                "value": 34498,
                "label": "Braucourt",
                "label_ar": "براكورت",
                "label_fr": "Braucourt"
            },
            {
                "value": 34499,
                "label": "Morvillers-Saint-Saturnin",
                "label_ar": "مورفيلرز سانت ساتورنين",
                "label_fr": "Morvillers-Saint-Saturnin"
            },
            {
                "value": 34500,
                "label": "Harcigny",
                "label_ar": "هارسيني",
                "label_fr": "Harcigny"
            },
            {
                "value": 34501,
                "label": "Festubert",
                "label_ar": "فيستوبرت",
                "label_fr": "Festubert"
            },
            {
                "value": 34502,
                "label": "Etaves-et-Bocquiaux",
                "label_ar": "Etaves-et-Bocquiaux",
                "label_fr": "Etaves-et-Bocquiaux"
            },
            {
                "value": 34503,
                "label": "Laversine",
                "label_ar": "لافيرسين",
                "label_fr": "Laversine"
            },
            {
                "value": 34504,
                "label": "Les Loges",
                "label_ar": "لي لوجيس",
                "label_fr": "Les Loges"
            },
            {
                "value": 34505,
                "label": "Chambrecy",
                "label_ar": "شامبريسي",
                "label_fr": "Chambrecy"
            },
            {
                "value": 34506,
                "label": "Saint-Etienne-au-Temple",
                "label_ar": "معبد سانت إتيان أو",
                "label_fr": "Saint-Etienne-au-Temple"
            },
            {
                "value": 34507,
                "label": "Feuges",
                "label_ar": "عداء",
                "label_fr": "Feuges"
            },
            {
                "value": 34508,
                "label": "Chapelle-Vallon",
                "label_ar": "شابيل فالون",
                "label_fr": "Chapelle-Vallon"
            },
            {
                "value": 34509,
                "label": "Le Baizil",
                "label_ar": "لو بايزيل",
                "label_fr": "Le Baizil"
            },
            {
                "value": 34510,
                "label": "Rumegies",
                "label_ar": "روميجيس",
                "label_fr": "Rumegies"
            },
            {
                "value": 34511,
                "label": "Hallencourt",
                "label_ar": "هالينكورت",
                "label_fr": "Hallencourt"
            },
            {
                "value": 34512,
                "label": "Fouencamps",
                "label_ar": "Fouencamps",
                "label_fr": "Fouencamps"
            },
            {
                "value": 34513,
                "label": "Sarcicourt",
                "label_ar": "سارسيكورت",
                "label_fr": "Sarcicourt"
            },
            {
                "value": 34514,
                "label": "Villeneuve-au-Chemin",
                "label_ar": "فيلنوف أو شيمين",
                "label_fr": "Villeneuve-au-Chemin"
            },
            {
                "value": 34515,
                "label": "Jarze",
                "label_ar": "جرز",
                "label_fr": "Jarze"
            },
            {
                "value": 34516,
                "label": "Etival-les-le-Mans",
                "label_ar": "إتيفال ليس لو مان",
                "label_fr": "Etival-les-le-Mans"
            },
            {
                "value": 34517,
                "label": "Bois-de-Cene",
                "label_ar": "Bois-de-Cene",
                "label_fr": "Bois-de-Cène"
            },
            {
                "value": 34518,
                "label": "Melay",
                "label_ar": "ميلاي",
                "label_fr": "Melay"
            },
            {
                "value": 34519,
                "label": "Louailles",
                "label_ar": "لويل",
                "label_fr": "Louailles"
            },
            {
                "value": 34520,
                "label": "Bouee",
                "label_ar": "بوي",
                "label_fr": "Bouée"
            },
            {
                "value": 34521,
                "label": "Chauve",
                "label_ar": "شاف",
                "label_fr": "Chauve"
            },
            {
                "value": 34522,
                "label": "Andigne",
                "label_ar": "أنديني",
                "label_fr": "Andigné"
            },
            {
                "value": 34523,
                "label": "Montsoreau",
                "label_ar": "مونتسورو",
                "label_fr": "Montsoreau"
            },
            {
                "value": 34524,
                "label": "Chauche",
                "label_ar": "شوش",
                "label_fr": "Chauche"
            },
            {
                "value": 34525,
                "label": "Le Boupere",
                "label_ar": "لو بوبير",
                "label_fr": "Le Boupère"
            },
            {
                "value": 34526,
                "label": "Soudan",
                "label_ar": "السودان",
                "label_fr": "Soudan"
            },
            {
                "value": 34527,
                "label": "Saint-Philbert-de-Bouaine",
                "label_ar": "سان فيلبيرت دو بواين",
                "label_fr": "Saint-Philbert-de-Bouaine"
            },
            {
                "value": 34528,
                "label": "Fougere",
                "label_ar": "فوجير",
                "label_fr": "Fougère"
            },
            {
                "value": 34529,
                "label": "Moutiers-sur-le-Lay",
                "label_ar": "موتير سور لو لاي",
                "label_fr": "Moutiers-sur-le-Lay"
            },
            {
                "value": 34530,
                "label": "Longue-Jumelles",
                "label_ar": "لونج جوميل",
                "label_fr": "Longue-Jumelles"
            },
            {
                "value": 34531,
                "label": "Nyoiseau",
                "label_ar": "نيواسو",
                "label_fr": "Nyoiseau"
            },
            {
                "value": 34532,
                "label": "Fontaine-Guerin",
                "label_ar": "فونتين غيران",
                "label_fr": "Fontaine-Guérin"
            },
            {
                "value": 34533,
                "label": "Outille",
                "label_ar": "أوتيل",
                "label_fr": "Outille"
            },
            {
                "value": 34534,
                "label": "Lezigne",
                "label_ar": "ليزين",
                "label_fr": "Lezigne"
            },
            {
                "value": 34535,
                "label": "Saint-Macaire-du-Bois",
                "label_ar": "سان ماكير دو بوا",
                "label_fr": "Saint-Macaire-du-Bois"
            },
            {
                "value": 34536,
                "label": "Moisdon-la-Riviere",
                "label_ar": "مويسدون لا ريفيير",
                "label_fr": "Moisdon-la-Rivière"
            },
            {
                "value": 34537,
                "label": "Saint-Jean-Ligoure",
                "label_ar": "سان جان ليجور",
                "label_fr": "Saint-Jean-Ligoure"
            },
            {
                "value": 34538,
                "label": "Ronsenac",
                "label_ar": "رونسيناك",
                "label_fr": "Ronsenac"
            },
            {
                "value": 34539,
                "label": "Villebois-Lavalette",
                "label_ar": "فيلبوا لافاليت",
                "label_fr": "Villebois-Lavalette"
            },
            {
                "value": 34540,
                "label": "Nercillac",
                "label_ar": "نيرسيلاك",
                "label_fr": "Nercillac"
            },
            {
                "value": 34541,
                "label": "Boussac",
                "label_ar": "بوساك",
                "label_fr": "Boussac"
            },
            {
                "value": 34542,
                "label": "Pageas",
                "label_ar": "باجياس",
                "label_fr": "Pageas"
            },
            {
                "value": 34543,
                "label": "Lahontan",
                "label_ar": "لاهونتان",
                "label_fr": "Lahontan"
            },
            {
                "value": 34544,
                "label": "Massugas",
                "label_ar": "ماسوغاس",
                "label_fr": "Massugas"
            },
            {
                "value": 34545,
                "label": "Capoulet-et-Junac",
                "label_ar": "Capoulet-et-Junac",
                "label_fr": "Capoulet-et-Junac"
            },
            {
                "value": 34546,
                "label": "La Lande",
                "label_ar": "لا لاند",
                "label_fr": "La Lande"
            },
            {
                "value": 34547,
                "label": "Chenerailles",
                "label_ar": "تشينيرايلس",
                "label_fr": "Chénerailles"
            },
            {
                "value": 34548,
                "label": "Puyravault",
                "label_ar": "بويرافولت",
                "label_fr": "Puyravault"
            },
            {
                "value": 34549,
                "label": "Lavaurette",
                "label_ar": "لافوريت",
                "label_fr": "Lavaurette"
            },
            {
                "value": 34550,
                "label": "Lacour",
                "label_ar": "لاكور",
                "label_fr": "Lacour"
            },
            {
                "value": 34551,
                "label": "Salleboeuf",
                "label_ar": "ساليبوف",
                "label_fr": "Salleboeuf"
            },
            {
                "value": 34552,
                "label": "Saint-Vite",
                "label_ar": "سانت فيت",
                "label_fr": "Saint-Vite"
            },
            {
                "value": 34553,
                "label": "Boisse-Penchot",
                "label_ar": "Boisse-Penchot",
                "label_fr": "Boisse-Penchot"
            },
            {
                "value": 34554,
                "label": "Vallesvilles",
                "label_ar": "فاليسفيل",
                "label_fr": "Vallesvilles"
            },
            {
                "value": 34555,
                "label": "Sainte-Terre",
                "label_ar": "سانت تير",
                "label_fr": "Sainte-Terre"
            },
            {
                "value": 34556,
                "label": "Vanzac",
                "label_ar": "فانزاك",
                "label_fr": "Vanzac"
            },
            {
                "value": 34557,
                "label": "La Grave",
                "label_ar": "لا جراف",
                "label_fr": "La Grave"
            },
            {
                "value": 34558,
                "label": "Lombers",
                "label_ar": "لومبرز",
                "label_fr": "Lombers"
            },
            {
                "value": 34559,
                "label": "Montpezat-de-Quercy",
                "label_ar": "مونبيزات دي كيرسي",
                "label_fr": "Montpezat-de-Quercy"
            },
            {
                "value": 34560,
                "label": "Escassefort",
                "label_ar": "إسكاسفورت",
                "label_fr": "Escassefort"
            },
            {
                "value": 34561,
                "label": "Peyrignac",
                "label_ar": "بيرينياك",
                "label_fr": "Peyrignac"
            },
            {
                "value": 34562,
                "label": "Naujan-et-Postiac",
                "label_ar": "Naujan-et-Postiac",
                "label_fr": "Naujan-et-Postiac"
            },
            {
                "value": 34563,
                "label": "Tursac",
                "label_ar": "تورساك",
                "label_fr": "Tursac"
            },
            {
                "value": 34564,
                "label": "Thenon",
                "label_ar": "ثم على",
                "label_fr": "Thenon"
            },
            {
                "value": 34565,
                "label": "Lizac",
                "label_ar": "ليزاك",
                "label_fr": "Lizac"
            },
            {
                "value": 34566,
                "label": "Saint-Sebastien",
                "label_ar": "سان سيباستيان",
                "label_fr": "Saint-Sébastien"
            },
            {
                "value": 34567,
                "label": "Perignac",
                "label_ar": "بريجناك",
                "label_fr": "Pérignac"
            },
            {
                "value": 34568,
                "label": "Villegouge",
                "label_ar": "فيليجوج",
                "label_fr": "Villegouge"
            },
            {
                "value": 34569,
                "label": "Leojac",
                "label_ar": "ليوجاك",
                "label_fr": "Leojac"
            },
            {
                "value": 34570,
                "label": "Trevien",
                "label_ar": "تريفين",
                "label_fr": "Trevien"
            },
            {
                "value": 34571,
                "label": "Bor-et-Bar",
                "label_ar": "بور إت بار",
                "label_fr": "Bor-et-Bar"
            },
            {
                "value": 34572,
                "label": "Les Eyzies-de-Tayac-Sireuil",
                "label_ar": "Les Eyzies-de-Tayac-Sireuil",
                "label_fr": "Les Eyzies-de-Tayac-Sireuil"
            },
            {
                "value": 34573,
                "label": "Lachapelle",
                "label_ar": "لاشابيل",
                "label_fr": "Lachapelle"
            },
            {
                "value": 34574,
                "label": "Pompertuzat",
                "label_ar": "بومبيرتوزات",
                "label_fr": "Pompertuzat"
            },
            {
                "value": 34575,
                "label": "Forfry",
                "label_ar": "فورفري",
                "label_fr": "Forfry"
            },
            {
                "value": 34576,
                "label": "Citry",
                "label_ar": "سيتري",
                "label_fr": "Citry"
            },
            {
                "value": 34577,
                "label": "Jumeauville",
                "label_ar": "جوموفيل",
                "label_fr": "Jumeauville"
            },
            {
                "value": 34578,
                "label": "Villers",
                "label_ar": "القرويون",
                "label_fr": "Villers"
            },
            {
                "value": 34579,
                "label": "Vetheuil",
                "label_ar": "فيثويل",
                "label_fr": "Vétheuil"
            },
            {
                "value": 34580,
                "label": "Auteuil",
                "label_ar": "Auteuil",
                "label_fr": "Auteuil"
            },
            {
                "value": 34581,
                "label": "Caumont-l'Evente",
                "label_ar": "Caumont-l'Evente",
                "label_fr": "Caumont-l'Evente"
            },
            {
                "value": 34582,
                "label": "Sainte-Croix-Hague",
                "label_ar": "سانت كروا لاهاي",
                "label_fr": "Sainte-Croix-Hague"
            },
            {
                "value": 34583,
                "label": "Moulines",
                "label_ar": "مولين",
                "label_fr": "Moulines"
            },
            {
                "value": 34584,
                "label": "Coulvain",
                "label_ar": "كولفان",
                "label_fr": "Coulvain"
            },
            {
                "value": 34585,
                "label": "Berjou",
                "label_ar": "برجو",
                "label_fr": "Berjou"
            },
            {
                "value": 34586,
                "label": "Saint-Cornier-des-Landes",
                "label_ar": "سان كورنير دي لاند",
                "label_fr": "Saint-Cornier-des-Landes"
            },
            {
                "value": 34587,
                "label": "Vaux-sur-Aure",
                "label_ar": "فو سور اوري",
                "label_fr": "Vaux-sur-Aure"
            },
            {
                "value": 34588,
                "label": "Pont-Hebert",
                "label_ar": "بونت هيبرت",
                "label_fr": "Pont-Hébert"
            },
            {
                "value": 34589,
                "label": "Saint-Ouen-du-Mesnil-Oger",
                "label_ar": "Saint-Ouen-du-Mesnil-Oger",
                "label_fr": "Saint-Ouen-du-Mesnil-Oger"
            },
            {
                "value": 34590,
                "label": "Cormolain",
                "label_ar": "كورمولين",
                "label_fr": "Cormolain"
            },
            {
                "value": 34591,
                "label": "Annebault",
                "label_ar": "انيبولت",
                "label_fr": "Annebault"
            },
            {
                "value": 34592,
                "label": "Quettreville-sur-Sienne",
                "label_ar": "Quettreville-sur-Sienne",
                "label_fr": "Quettreville-sur-Sienne"
            },
            {
                "value": 34593,
                "label": "Benerville-sur-Mer",
                "label_ar": "بنرفيل سور مير",
                "label_fr": "Benerville-sur-Mer"
            },
            {
                "value": 34594,
                "label": "La Coulonche",
                "label_ar": "لا كولونش",
                "label_fr": "La Coulonche"
            },
            {
                "value": 34595,
                "label": "Cesny-Bois-Halbout",
                "label_ar": "سيسني بوا حلبوت",
                "label_fr": "Cesny-Bois-Halbout"
            },
            {
                "value": 34596,
                "label": "Monteaux",
                "label_ar": "مونتو",
                "label_fr": "Monteaux"
            },
            {
                "value": 34597,
                "label": "Pontlevoy",
                "label_ar": "بونتليفوي",
                "label_fr": "Pontlevoy"
            },
            {
                "value": 34598,
                "label": "Gouillons",
                "label_ar": "جويلون",
                "label_fr": "Gouillons"
            },
            {
                "value": 34599,
                "label": "Ivoy-le-Pre",
                "label_ar": "إيفوي لو بري",
                "label_fr": "Ivoy-le-Pré"
            },
            {
                "value": 34600,
                "label": "Chambourg-sur-Indre",
                "label_ar": "شامبورغ سور إندر",
                "label_fr": "Chambourg-sur-Indre"
            },
            {
                "value": 34601,
                "label": "Coulommiers-la-Tour",
                "label_ar": "Coulommiers-la-Tour",
                "label_fr": "Coulommiers-la-Tour"
            },
            {
                "value": 34602,
                "label": "Villetrun",
                "label_ar": "فيليترون",
                "label_fr": "Villetrun"
            },
            {
                "value": 34603,
                "label": "Houx",
                "label_ar": "هوكس",
                "label_fr": "Houx"
            },
            {
                "value": 34604,
                "label": "Mevoisins",
                "label_ar": "Mevoisins",
                "label_fr": "Mévoisins"
            },
            {
                "value": 34605,
                "label": "Oucques",
                "label_ar": "أوكيس",
                "label_fr": "Oucques"
            },
            {
                "value": 34606,
                "label": "Levet",
                "label_ar": "ليفيت",
                "label_fr": "Levet"
            },
            {
                "value": 34607,
                "label": "Saint-Hilaire-les-Andresis",
                "label_ar": "سان هيلير ليه أندريسيس",
                "label_fr": "Saint-Hilaire-les-Andrésis"
            },
            {
                "value": 34608,
                "label": "Gasville-Oiseme",
                "label_ar": "جاسفيل-أوسيم",
                "label_fr": "Gasville-Oiseme"
            },
            {
                "value": 34609,
                "label": "Dangeau",
                "label_ar": "دانجو",
                "label_fr": "Dangeau"
            },
            {
                "value": 34610,
                "label": "Vatan",
                "label_ar": "وطن",
                "label_fr": "Vatan"
            },
            {
                "value": 34611,
                "label": "Prunay-le-Gillon",
                "label_ar": "بروناي لو جيلون",
                "label_fr": "Prunay-le-Gillon"
            },
            {
                "value": 34612,
                "label": "Villeny",
                "label_ar": "فيليني",
                "label_fr": "Villeny"
            },
            {
                "value": 34613,
                "label": "Neuville-sur-Brenne",
                "label_ar": "نيوفيل سور برين",
                "label_fr": "Neuville-sur-Brenne"
            },
            {
                "value": 34614,
                "label": "Authon",
                "label_ar": "أوثون",
                "label_fr": "Authon"
            },
            {
                "value": 34615,
                "label": "Monteglin",
                "label_ar": "مونتيجلين",
                "label_fr": "Monteglin"
            },
            {
                "value": 34616,
                "label": "Belaye",
                "label_ar": "بيلاي",
                "label_fr": "Belaye"
            },
            {
                "value": 34617,
                "label": "Viabon",
                "label_ar": "فيابون",
                "label_fr": "Viabon"
            },
            {
                "value": 34618,
                "label": "Saint-Jean-d'Aubrigoux",
                "label_ar": "سان جان دابريجو",
                "label_fr": "Saint-Jean-d'Aubrigoux"
            },
            {
                "value": 34619,
                "label": "Recey-sur-Ource",
                "label_ar": "Recey-sur-Ource",
                "label_fr": "Recey-sur-Ource"
            },
            {
                "value": 34620,
                "label": "Bagneaux",
                "label_ar": "باجنو",
                "label_fr": "Bagneaux"
            },
            {
                "value": 34621,
                "label": "La Chatre-Langlin",
                "label_ar": "لا شاتري لانجلين",
                "label_fr": "La Châtre-Langlin"
            },
            {
                "value": 34622,
                "label": "Cargèse",
                "label_ar": "كارجيس",
                "label_fr": "Cargèse"
            },
            {
                "value": 34623,
                "label": "Illiers-Combray",
                "label_ar": "إيليير كومبري",
                "label_fr": "Illiers-Combray"
            },
            {
                "value": 34624,
                "label": "Courtisols",
                "label_ar": "كورتيسولس",
                "label_fr": "Courtisols"
            },
            {
                "value": 34625,
                "label": "Ouerre",
                "label_ar": "Ouerre",
                "label_fr": "Ouerre"
            },
            {
                "value": 34626,
                "label": "Romeny-sur-Marne",
                "label_ar": "روميني سور مارن",
                "label_fr": "Romeny-sur-Marne"
            },
            {
                "value": 34627,
                "label": "Breconchaux",
                "label_ar": "بريكونشو",
                "label_fr": "Bréconchaux"
            },
            {
                "value": 34628,
                "label": "Cravanche",
                "label_ar": "كرافانش",
                "label_fr": "Cravanche"
            },
            {
                "value": 34629,
                "label": "Boussois",
                "label_ar": "بوسوا",
                "label_fr": "Boussois"
            },
            {
                "value": 34630,
                "label": "La Ferte-Mace",
                "label_ar": "لا فيرت-ميس",
                "label_fr": "La Ferté-Masse"
            },
            {
                "value": 34631,
                "label": "Hermillon",
                "label_ar": "هيرميلون",
                "label_fr": "Hermillon"
            },
            {
                "value": 34632,
                "label": "Rion-des-Landes",
                "label_ar": "ريون دي لانديس",
                "label_fr": "Rion-des-Landes"
            },
            {
                "value": 34633,
                "label": "Pompignan",
                "label_ar": "بومبينيان",
                "label_fr": "Pompignan"
            },
            {
                "value": 34634,
                "label": "Saint-Amant-de-Boixe",
                "label_ar": "سان امانت دي بويكس",
                "label_fr": "Saint-Amant-de-Boixe"
            },
            {
                "value": 34635,
                "label": "Saint-Pierre-sur-Dives",
                "label_ar": "سان بيير سور دايفز",
                "label_fr": "Saint-Pierre-sur-Dives"
            },
            {
                "value": 34636,
                "label": "Tillenay",
                "label_ar": "تيليني",
                "label_fr": "Tillenay"
            },
            {
                "value": 34637,
                "label": "Capens",
                "label_ar": "كابينز",
                "label_fr": "Capens"
            },
            {
                "value": 34638,
                "label": "Le Passage",
                "label_ar": "لو باساج",
                "label_fr": "Le Passage"
            },
            {
                "value": 34639,
                "label": "Fierville-les-Mines",
                "label_ar": "Fierville-les-Mines",
                "label_fr": "Fierville-les-Mines"
            },
            {
                "value": 34640,
                "label": "Orval",
                "label_ar": "أورفال",
                "label_fr": "Orval"
            },
            {
                "value": 34641,
                "label": "Lingreville",
                "label_ar": "لينجريفيل",
                "label_fr": "Lingreville"
            },
            {
                "value": 34642,
                "label": "Parigny",
                "label_ar": "Parigny",
                "label_fr": "Parigny"
            },
            {
                "value": 34643,
                "label": "Cosqueville",
                "label_ar": "كوسكفيل",
                "label_fr": "Cosqueville"
            },
            {
                "value": 34644,
                "label": "Reville",
                "label_ar": "ريفيل",
                "label_fr": "Reville"
            },
            {
                "value": 34645,
                "label": "Les Moitiers-en-Bauptois",
                "label_ar": "لي مواتييه أون باوبتوا",
                "label_fr": "Les Moitiers-en-Bauptois"
            },
            {
                "value": 34646,
                "label": "Roncey",
                "label_ar": "رونسي",
                "label_fr": "Roncey"
            },
            {
                "value": 34647,
                "label": "Conde-sur-Vire",
                "label_ar": "كوندي سور فير",
                "label_fr": "Condé-sur-Vire"
            },
            {
                "value": 34648,
                "label": "Boisyvon",
                "label_ar": "Boisyvon",
                "label_fr": "Boisyvon"
            },
            {
                "value": 34649,
                "label": "Notre-Dame-de-Cenilly",
                "label_ar": "نوتردام دي سينيلي",
                "label_fr": "Notre-Dame-de-Cenilly"
            },
            {
                "value": 34650,
                "label": "Doville",
                "label_ar": "دوفيل",
                "label_fr": "Doville"
            },
            {
                "value": 34651,
                "label": "Amigny",
                "label_ar": "Amigny",
                "label_fr": "Amigny"
            },
            {
                "value": 34652,
                "label": "Quettehou",
                "label_ar": "Quettehou",
                "label_fr": "Quettehou"
            },
            {
                "value": 34653,
                "label": "Montreuil-sur-Lozon",
                "label_ar": "مونتروي سور لوزون",
                "label_fr": "Montreuil-sur-Lozon"
            },
            {
                "value": 34654,
                "label": "Percy",
                "label_ar": "بيرسي",
                "label_fr": "Percy"
            },
            {
                "value": 34655,
                "label": "Sainte-Mere-Eglise",
                "label_ar": "سانت مير إجليز",
                "label_fr": "Sainte-Mère-Eglise"
            },
            {
                "value": 34656,
                "label": "Picauville",
                "label_ar": "بيكوفيل",
                "label_fr": "Picauville"
            },
            {
                "value": 34657,
                "label": "Fermanville",
                "label_ar": "فيرمانفيل",
                "label_fr": "Fermanville"
            },
            {
                "value": 34658,
                "label": "Lozon",
                "label_ar": "لوزون",
                "label_fr": "Lozon"
            },
            {
                "value": 34659,
                "label": "Saint-Patrice-de-Claids",
                "label_ar": "سان باتريس دي كلايدز",
                "label_fr": "Saint-Patrice-de-Claids"
            },
            {
                "value": 34660,
                "label": "Quineville",
                "label_ar": "كوينفيل",
                "label_fr": "Quineville"
            },
            {
                "value": 34661,
                "label": "Creances",
                "label_ar": "كرينس",
                "label_fr": "Creances"
            },
            {
                "value": 34662,
                "label": "Reffuveille",
                "label_ar": "ريفوفيل",
                "label_fr": "Reffuveille"
            },
            {
                "value": 34663,
                "label": "Sainte-Cecile",
                "label_ar": "سانت سيسيل",
                "label_fr": "Sainte-Cécile"
            },
            {
                "value": 34664,
                "label": "Milly",
                "label_ar": "ميلي",
                "label_fr": "Milly"
            },
            {
                "value": 34665,
                "label": "La Chaise-Baudouin",
                "label_ar": "لا شيز بودوان",
                "label_fr": "La Chaise-Baudouin"
            },
            {
                "value": 34666,
                "label": "Bricqueville-la-Blouette",
                "label_ar": "Bricqueville-la-Blouette",
                "label_fr": "Bricqueville-la-Blouette"
            },
            {
                "value": 34667,
                "label": "Lessay",
                "label_ar": "ليسي",
                "label_fr": "Lessay"
            },
            {
                "value": 34668,
                "label": "Lecousse",
                "label_ar": "ليكوس",
                "label_fr": "Lecousse"
            },
            {
                "value": 34669,
                "label": "Canisy",
                "label_ar": "كلبي",
                "label_fr": "Canisy"
            },
            {
                "value": 34670,
                "label": "Glatigny",
                "label_ar": "جلاتيني",
                "label_fr": "Glatigny"
            },
            {
                "value": 34671,
                "label": "Pirou",
                "label_ar": "بيرو",
                "label_fr": "Pirou"
            },
            {
                "value": 34672,
                "label": "Vasteville",
                "label_ar": "فاستفيل",
                "label_fr": "Vasteville"
            },
            {
                "value": 34673,
                "label": "Huberville",
                "label_ar": "هوبرفيل",
                "label_fr": "Huberville"
            },
            {
                "value": 34674,
                "label": "Saint-Fromond",
                "label_ar": "سان فروموند",
                "label_fr": "Saint-Fromond"
            },
            {
                "value": 34675,
                "label": "La Haye-Saint-Sylvestre",
                "label_ar": "لا هاي سانت سيلفستر",
                "label_fr": "La Haye-Saint-Sylvestre"
            },
            {
                "value": 34676,
                "label": "La Feuillie",
                "label_ar": "لا فويلي",
                "label_fr": "La Feuillie"
            },
            {
                "value": 34677,
                "label": "Barfleur",
                "label_ar": "بارفلور",
                "label_fr": "Barfleur"
            },
            {
                "value": 34678,
                "label": "Neufmesnil",
                "label_ar": "نيوفمسنيل",
                "label_fr": "Neufmesnil"
            },
            {
                "value": 34679,
                "label": "Eschbach-au-Val",
                "label_ar": "Eschbach-au-Val",
                "label_fr": "Eschbach-au-Val"
            },
            {
                "value": 34680,
                "label": "Bettwiller",
                "label_ar": "بيتويلر",
                "label_fr": "Bettwiller"
            },
            {
                "value": 34681,
                "label": "Hatten",
                "label_ar": "حطين",
                "label_fr": "Hatten"
            },
            {
                "value": 34682,
                "label": "Durrenbach",
                "label_ar": "دورنباخ",
                "label_fr": "Durrenbach"
            },
            {
                "value": 34683,
                "label": "Boersch",
                "label_ar": "بويرش",
                "label_fr": "Boersch"
            },
            {
                "value": 34684,
                "label": "Breuschwickersheim",
                "label_ar": "بريوشويكيرشيم",
                "label_fr": "Breuschwickersheim"
            },
            {
                "value": 34685,
                "label": "Raedersheim",
                "label_ar": "رايدرشيم",
                "label_fr": "Raedersheim"
            },
            {
                "value": 34686,
                "label": "Stosswihr",
                "label_ar": "ستوسوير",
                "label_fr": "Stosswihr"
            },
            {
                "value": 34687,
                "label": "Batzendorf",
                "label_ar": "باتزيندورف",
                "label_fr": "Batzendorf"
            },
            {
                "value": 34688,
                "label": "Wintzenbach",
                "label_ar": "وينتزنباخ",
                "label_fr": "Wintzenbach"
            },
            {
                "value": 34689,
                "label": "Mietesheim",
                "label_ar": "ميتيشيم",
                "label_fr": "Mietesheim"
            },
            {
                "value": 34690,
                "label": "Schopperten",
                "label_ar": "شوبرتين",
                "label_fr": "Schopperten"
            },
            {
                "value": 34691,
                "label": "Soultzeren",
                "label_ar": "سولتزيرين",
                "label_fr": "Soultzeren"
            },
            {
                "value": 34692,
                "label": "Neewiller-pres-Lauterbourg",
                "label_ar": "Neewiller-pres-Lauterbourg",
                "label_fr": "Neewiller-près-Lauterbourg"
            },
            {
                "value": 34693,
                "label": "Grassendorf",
                "label_ar": "غراسندورف",
                "label_fr": "Grassendorf"
            },
            {
                "value": 34694,
                "label": "Chevrieres",
                "label_ar": "Chevrieres",
                "label_fr": "Chevrieres"
            },
            {
                "value": 34695,
                "label": "Loyes",
                "label_ar": "لويز",
                "label_fr": "Loyes"
            },
            {
                "value": 34696,
                "label": "Saint-Romain-Lachalm",
                "label_ar": "سان رومان لاتشالم",
                "label_fr": "Saint-Romain-Lachalm"
            },
            {
                "value": 34697,
                "label": "Sevelinges",
                "label_ar": "سيفلينجيس",
                "label_fr": "Sevelinges"
            },
            {
                "value": 34698,
                "label": "Ligne",
                "label_ar": "لين",
                "label_fr": "Ligne"
            },
            {
                "value": 34699,
                "label": "Ecouviez",
                "label_ar": "ايكوفيز",
                "label_fr": "Ecouviez"
            },
            {
                "value": 34700,
                "label": "Le Caylar",
                "label_ar": "لو كايلار",
                "label_fr": "Le Caylar"
            },
            {
                "value": 34701,
                "label": "Usseau",
                "label_ar": "أوسو",
                "label_fr": "Usseau"
            },
            {
                "value": 34702,
                "label": "Germenay",
                "label_ar": "Germenay",
                "label_fr": "Germenay"
            },
            {
                "value": 34703,
                "label": "Saint-Jeure-d'Andaure",
                "label_ar": "سان جيور دأندور",
                "label_fr": "Saint-Jeure-d'Andaure"
            },
            {
                "value": 34704,
                "label": "Thurageau",
                "label_ar": "ثوراغو",
                "label_fr": "Thurageau"
            },
            {
                "value": 34705,
                "label": "Saint-Arnoult-des-Bois",
                "label_ar": "سان أرنولت دي بوا",
                "label_fr": "Saint-Arnoult-des-Bois"
            },
            {
                "value": 34706,
                "label": "Cherveux",
                "label_ar": "شيرفو",
                "label_fr": "Cherveux"
            },
            {
                "value": 34707,
                "label": "Audignies",
                "label_ar": "اودينيز",
                "label_fr": "Audignies"
            },
            {
                "value": 34708,
                "label": "Voulton",
                "label_ar": "فولتون",
                "label_fr": "Voulton"
            },
            {
                "value": 34709,
                "label": "Maurois",
                "label_ar": "موروا",
                "label_fr": "Maurois"
            },
            {
                "value": 34710,
                "label": "Auxange",
                "label_ar": "اوكسانج",
                "label_fr": "Auxange"
            },
            {
                "value": 34711,
                "label": "La Roque-Baignard",
                "label_ar": "لاروك بينارد",
                "label_fr": "La Roque-Baignard"
            },
            {
                "value": 34712,
                "label": "Rouperroux",
                "label_ar": "روبروكس",
                "label_fr": "Rouperroux"
            },
            {
                "value": 34713,
                "label": "Conde-sur-Iton",
                "label_ar": "كوندي سور إيتون",
                "label_fr": "Condé-sur-Iton"
            },
            {
                "value": 34714,
                "label": "Ecardenville-sur-Eure",
                "label_ar": "Ecardenville-sur-Eure",
                "label_fr": "Ecardenville-sur-Eure"
            },
            {
                "value": 34715,
                "label": "Pont",
                "label_ar": "بونت",
                "label_fr": "Pont"
            },
            {
                "value": 34716,
                "label": "Saintes-Maries-de-la-Mer",
                "label_ar": "سانتس ماري دو لا مير",
                "label_fr": "Saintes-Maries-de-la-Mer"
            },
            {
                "value": 34717,
                "label": "Genac",
                "label_ar": "جيناك",
                "label_fr": "Genac"
            },
            {
                "value": 34718,
                "label": "Saint-Genies-de-Comolas",
                "label_ar": "سان جيني دو كومولاس",
                "label_fr": "Saint-Genies-de-Comolas"
            },
            {
                "value": 34719,
                "label": "Ernemont-sur-Buchy",
                "label_ar": "ارنيمونت سور بوشي",
                "label_fr": "Ernemont-sur-Buchy"
            },
            {
                "value": 34720,
                "label": "Frevillers",
                "label_ar": "فريفيلرز",
                "label_fr": "Frevillers"
            },
            {
                "value": 34721,
                "label": "Escolives-Sainte-Camille",
                "label_ar": "اسكوليفس سانت كميل",
                "label_fr": "Escolives-Sainte-Camille"
            },
            {
                "value": 34722,
                "label": "Verneuil-sous-Coucy",
                "label_ar": "Verneuil-sous-Coucy",
                "label_fr": "Verneuil-sous-Coucy"
            },
            {
                "value": 34723,
                "label": "Belloy-en-France",
                "label_ar": "بيلوي أون فرانس",
                "label_fr": "Belloy-en-France"
            },
            {
                "value": 34724,
                "label": "Riaille",
                "label_ar": "ريائيل",
                "label_fr": "Riaille"
            },
            {
                "value": 34725,
                "label": "Commequiers",
                "label_ar": "Commequiers",
                "label_fr": "Commequiers"
            },
            {
                "value": 34726,
                "label": "Torsac",
                "label_ar": "تورساك",
                "label_fr": "Torsac"
            },
            {
                "value": 34727,
                "label": "Berville-en-Roumois",
                "label_ar": "برفيل أون روموا",
                "label_fr": "Berville-en-Roumois"
            },
            {
                "value": 34728,
                "label": "La Croix-Valmer",
                "label_ar": "لا كروا فالمر",
                "label_fr": "La Croix-Valmer"
            },
            {
                "value": 34729,
                "label": "Montayral",
                "label_ar": "مونتايرال",
                "label_fr": "Montayral"
            },
            {
                "value": 34730,
                "label": "Quatremare",
                "label_ar": "كواتريماري",
                "label_fr": "Quatremare"
            },
            {
                "value": 34731,
                "label": "La Sauvetat-de-Saveres",
                "label_ar": "La Sauvetat-de-Saveres",
                "label_fr": "La Sauvetat-de-Saveres"
            },
            {
                "value": 34732,
                "label": "Fourques-sur-Garonne",
                "label_ar": "فوركويس سور غارون",
                "label_fr": "Fourques-sur-Garonne"
            },
            {
                "value": 34733,
                "label": "Le Breuil",
                "label_ar": "لو بريل",
                "label_fr": "Le Breuil"
            },
            {
                "value": 34734,
                "label": "Livre-sur-Changeon",
                "label_ar": "ليفر سور تشانجون",
                "label_fr": "Livre-sur-Changeon"
            },
            {
                "value": 34735,
                "label": "Pas-en-Artois",
                "label_ar": "با-أون-أرتويس",
                "label_fr": "Pas-en-Artois"
            },
            {
                "value": 34736,
                "label": "Nommay",
                "label_ar": "نومي",
                "label_fr": "Nommay"
            },
            {
                "value": 34737,
                "label": "Ferriere-la-Petite",
                "label_ar": "Ferriere-la-Petite",
                "label_fr": "Ferrière-la-Petite"
            },
            {
                "value": 34738,
                "label": "Villermain",
                "label_ar": "فيليرمين",
                "label_fr": "Villermain"
            },
            {
                "value": 34739,
                "label": "Sainte-Fereole",
                "label_ar": "سانت فيرول",
                "label_fr": "Sainte-Féréole"
            },
            {
                "value": 34740,
                "label": "Brides-les-Bains",
                "label_ar": "عرائس ليه با",
                "label_fr": "Brides-les-Bains"
            },
            {
                "value": 34741,
                "label": "Chimilin",
                "label_ar": "تشيميلين",
                "label_fr": "Chimilin"
            },
            {
                "value": 34742,
                "label": "Saint-Baldoph",
                "label_ar": "سانت بالدوف",
                "label_fr": "Saint-Baldoph"
            },
            {
                "value": 34743,
                "label": "Saint-Martin-de-Belleville",
                "label_ar": "سان مارتن دي بيلفيل",
                "label_fr": "Saint-Martin-de-Belleville"
            },
            {
                "value": 34744,
                "label": "Hauteluce",
                "label_ar": "Hauteluce",
                "label_fr": "Hauteluce"
            },
            {
                "value": 34745,
                "label": "Proveysieux",
                "label_ar": "بروفيسيو",
                "label_fr": "Proveysieux"
            },
            {
                "value": 34746,
                "label": "Verthemex",
                "label_ar": "فيرتيمكس",
                "label_fr": "Verthémex"
            },
            {
                "value": 34747,
                "label": "Le Pin",
                "label_ar": "لو بين",
                "label_fr": "Le Pin"
            },
            {
                "value": 34748,
                "label": "Argentine",
                "label_ar": "أرجنتيني",
                "label_fr": "argentin"
            },
            {
                "value": 34749,
                "label": "Saint-Thibaud-de-Couz",
                "label_ar": "سان تيبو دي كوز",
                "label_fr": "Saint-Thibaud-de-Couz"
            },
            {
                "value": 34750,
                "label": "Dullin",
                "label_ar": "دولين",
                "label_fr": "Dullin"
            },
            {
                "value": 34751,
                "label": "Mery",
                "label_ar": "ميري",
                "label_fr": "Mery"
            },
            {
                "value": 34752,
                "label": "Nantoin",
                "label_ar": "نانتوين",
                "label_fr": "Nantoïne"
            },
            {
                "value": 34753,
                "label": "Villarlurin",
                "label_ar": "فيلارلورين",
                "label_fr": "Villarlurin"
            },
            {
                "value": 34754,
                "label": "Barby",
                "label_ar": "باربي",
                "label_fr": "Barby"
            },
            {
                "value": 34755,
                "label": "Saint-Paul-de-Varces",
                "label_ar": "سان بول دي فارس",
                "label_fr": "Saint-Paul-de-Varces"
            },
            {
                "value": 34756,
                "label": "Epierre",
                "label_ar": "إبيير",
                "label_fr": "Epierre"
            },
            {
                "value": 34757,
                "label": "Arbin",
                "label_ar": "عربين",
                "label_fr": "Arbin"
            },
            {
                "value": 34758,
                "label": "Saint-Joseph-de-Riviere",
                "label_ar": "سان جوزيف دي ريفيير",
                "label_fr": "Saint-Joseph-de-Rivière"
            },
            {
                "value": 34759,
                "label": "Randens",
                "label_ar": "راندينز",
                "label_fr": "Randens"
            },
            {
                "value": 34760,
                "label": "La Motte-Servolex",
                "label_ar": "لا موت سيرفوليكس",
                "label_fr": "La Motte-Servolex"
            },
            {
                "value": 34761,
                "label": "Monestier-de-Clermont",
                "label_ar": "مونستير دي كليرمون",
                "label_fr": "Monestier-de-Clermont"
            },
            {
                "value": 34762,
                "label": "Massieu",
                "label_ar": "ماسيو",
                "label_fr": "Massieu"
            },
            {
                "value": 34763,
                "label": "Queige",
                "label_ar": "كويج",
                "label_fr": "Queige"
            },
            {
                "value": 34764,
                "label": "Puygros",
                "label_ar": "بويغروس",
                "label_fr": "Puygros"
            },
            {
                "value": 34765,
                "label": "La Chapelle-Blanche",
                "label_ar": "لا شابيل بلانش",
                "label_fr": "La Chapelle-Blanche"
            },
            {
                "value": 34766,
                "label": "Toulon-sur-Arroux",
                "label_ar": "تولون سور اروكس",
                "label_fr": "Toulon-sur-Arroux"
            },
            {
                "value": 34767,
                "label": "Beire-le-Chatel",
                "label_ar": "Beire-le-Chatel",
                "label_fr": "Beire-le-Châtel"
            },
            {
                "value": 34768,
                "label": "Dixmont",
                "label_ar": "ديكسمونت",
                "label_fr": "Dixmont"
            },
            {
                "value": 34769,
                "label": "Tracy-sur-Loire",
                "label_ar": "تريسي سور لوار",
                "label_fr": "Tracy-sur-Loire"
            },
            {
                "value": 34770,
                "label": "Chaffois",
                "label_ar": "شافوا",
                "label_fr": "Chaffois"
            },
            {
                "value": 34771,
                "label": "Forleans",
                "label_ar": "فورلينز",
                "label_fr": "Forleans"
            },
            {
                "value": 34772,
                "label": "Saint-Seine-sur-Vingeanne",
                "label_ar": "سان سين سور فينجيان",
                "label_fr": "Saint-Seine-sur-Vingeanne"
            },
            {
                "value": 34773,
                "label": "Vitreux",
                "label_ar": "فيترو",
                "label_fr": "Vitreux"
            },
            {
                "value": 34774,
                "label": "Essey",
                "label_ar": "إيسي",
                "label_fr": "Essey"
            },
            {
                "value": 34775,
                "label": "Saint-Martin-sur-Nohain",
                "label_ar": "سان مارتن سور نوهاين",
                "label_fr": "Saint-Martin-sur-Nohain"
            },
            {
                "value": 34776,
                "label": "Epoisses",
                "label_ar": "Epoisses",
                "label_fr": "Epoisses"
            },
            {
                "value": 34777,
                "label": "Mondon",
                "label_ar": "موندون",
                "label_fr": "Mondon"
            },
            {
                "value": 34778,
                "label": "Normier",
                "label_ar": "نورمير",
                "label_fr": "Normier"
            },
            {
                "value": 34779,
                "label": "La Chaux-du-Dombief",
                "label_ar": "La Chaux-du-Dombief",
                "label_fr": "La Chaux-du-Dombief"
            },
            {
                "value": 34780,
                "label": "Foncine-le-Haut",
                "label_ar": "فونسين لو أوت",
                "label_fr": "Foncine-le-Haut"
            },
            {
                "value": 34781,
                "label": "Ougney",
                "label_ar": "اوجني",
                "label_fr": "Ougney"
            },
            {
                "value": 34782,
                "label": "Vathimenil",
                "label_ar": "فاثيمنيل",
                "label_fr": "Vathimenil"
            },
            {
                "value": 34783,
                "label": "Chemery-les-Deux",
                "label_ar": "كيمري ليه دوكس",
                "label_fr": "Chemery-les-Deux"
            },
            {
                "value": 34784,
                "label": "Sexey-aux-Forges",
                "label_ar": "Sexey-aux-Forges",
                "label_fr": "Sexey-aux-Forges"
            },
            {
                "value": 34785,
                "label": "Le Syndicat",
                "label_ar": "لو سنديكات",
                "label_fr": "Le Syndicat"
            },
            {
                "value": 34786,
                "label": "Fremonville",
                "label_ar": "فريمونفيل",
                "label_fr": "Fremonville"
            },
            {
                "value": 34787,
                "label": "Anould",
                "label_ar": "أنولد",
                "label_fr": "Anould"
            },
            {
                "value": 34788,
                "label": "Noisseville",
                "label_ar": "نويسفيل",
                "label_fr": "Noisseville"
            },
            {
                "value": 34789,
                "label": "Dorlisheim",
                "label_ar": "دورليشيم",
                "label_fr": "Dorlisheim"
            },
            {
                "value": 34790,
                "label": "Koenigsmacker",
                "label_ar": "كوينيجسمكر",
                "label_fr": "Koenigsmacker"
            },
            {
                "value": 34791,
                "label": "Richeling",
                "label_ar": "ريشيلينج",
                "label_fr": "Richeling"
            },
            {
                "value": 34792,
                "label": "Romaneche-Thorins",
                "label_ar": "رومانيش ثورين",
                "label_fr": "Romanèche-Thorins"
            },
            {
                "value": 34793,
                "label": "Brinon-sur-Beuvron",
                "label_ar": "برينون سور بيوفرون",
                "label_fr": "Brinon-sur-Beuvron"
            },
            {
                "value": 34794,
                "label": "Cuiseaux",
                "label_ar": "كويزو",
                "label_fr": "Cuiseaux"
            },
            {
                "value": 34795,
                "label": "Fenay",
                "label_ar": "فيني",
                "label_fr": "Fenay"
            },
            {
                "value": 34796,
                "label": "Rosieres-sur-Mance",
                "label_ar": "روزيريس سور مانس",
                "label_fr": "Rosières-sur-Mance"
            },
            {
                "value": 34797,
                "label": "Vosne-Romanée",
                "label_ar": "فوسني روماني",
                "label_fr": "Vosne-Romanée"
            },
            {
                "value": 34798,
                "label": "Sauvigny-les-Bois",
                "label_ar": "Sauvigny-les-Bois",
                "label_fr": "Sauvigny-les-Bois"
            },
            {
                "value": 34799,
                "label": "Moloy",
                "label_ar": "مولوي",
                "label_fr": "Moloy"
            },
            {
                "value": 34800,
                "label": "Avot",
                "label_ar": "أفوت",
                "label_fr": "Avot"
            },
            {
                "value": 34801,
                "label": "Autoreille",
                "label_ar": "أوتوريل",
                "label_fr": "Autoreille"
            },
            {
                "value": 34802,
                "label": "Gergy",
                "label_ar": "جيرجي",
                "label_fr": "Gergy"
            },
            {
                "value": 34803,
                "label": "Poiseul-les-Saulx",
                "label_ar": "Poiseul-les-Saulx",
                "label_fr": "Poiseul-les-Saulx"
            },
            {
                "value": 34804,
                "label": "Blanot",
                "label_ar": "بلانوت",
                "label_fr": "Blanot"
            },
            {
                "value": 34805,
                "label": "Massingy-les-Semur",
                "label_ar": "ماسينجي ليس سيمور",
                "label_fr": "Massingy-les-Semur"
            },
            {
                "value": 34806,
                "label": "Boncourt-le-Bois",
                "label_ar": "Boncourt-le-Bois",
                "label_fr": "Boncourt-le-Bois"
            },
            {
                "value": 34807,
                "label": "Tonnoy",
                "label_ar": "توني",
                "label_fr": "Tonnoy"
            },
            {
                "value": 34808,
                "label": "Grandvillers",
                "label_ar": "Grandvillers",
                "label_fr": "Grandvillers"
            },
            {
                "value": 34809,
                "label": "Sommelonne",
                "label_ar": "سوملون",
                "label_fr": "Sommelonne"
            },
            {
                "value": 34810,
                "label": "Saint-Ouen-les-Parey",
                "label_ar": "Saint-Ouen-les-Parey",
                "label_fr": "Saint-Ouen-les-Parey"
            },
            {
                "value": 34811,
                "label": "Pagny-sur-Meuse",
                "label_ar": "باجني سور ميوز",
                "label_fr": "Pagny-sur-Meuse"
            },
            {
                "value": 34812,
                "label": "Portieux",
                "label_ar": "بورتيوكس",
                "label_fr": "Portieux"
            },
            {
                "value": 34813,
                "label": "Ebersviller",
                "label_ar": "ابرسفيلر",
                "label_fr": "Ebersviller"
            },
            {
                "value": 34814,
                "label": "Bonnet",
                "label_ar": "غطاء محرك السيارة",
                "label_fr": "Bonnet"
            },
            {
                "value": 34815,
                "label": "Fontenoy-le-Chateau",
                "label_ar": "فونتينوي لو شاتو",
                "label_fr": "Fontenoy-le-Château"
            },
            {
                "value": 34816,
                "label": "Vaxoncourt",
                "label_ar": "فاكسونكور",
                "label_fr": "Vaxoncourt"
            },
            {
                "value": 34817,
                "label": "Eppe-Sauvage",
                "label_ar": "Eppe-Sauvage",
                "label_fr": "Eppe-Sauvage"
            },
            {
                "value": 34818,
                "label": "Montenescourt",
                "label_ar": "مونتينسكورت",
                "label_fr": "Montenescourt"
            },
            {
                "value": 34819,
                "label": "Bouvigny-Boyeffles",
                "label_ar": "بوفيني بويفليس",
                "label_fr": "Bouvigny-Boyeffles"
            },
            {
                "value": 34820,
                "label": "Saint-Souplet",
                "label_ar": "سان سوبلت",
                "label_fr": "Saint-Souplet"
            },
            {
                "value": 34821,
                "label": "Monceau-le-Waast",
                "label_ar": "Monceau-le-Waast",
                "label_fr": "Monceau-le-Waast"
            },
            {
                "value": 34822,
                "label": "Pinon",
                "label_ar": "بينون",
                "label_fr": "Épingler à"
            },
            {
                "value": 34823,
                "label": "Varinfroy",
                "label_ar": "فارينفروي",
                "label_fr": "Varinfroy"
            },
            {
                "value": 34824,
                "label": "Bellancourt",
                "label_ar": "بيلانكور",
                "label_fr": "Bellancourt"
            },
            {
                "value": 34825,
                "label": "Arvillers",
                "label_ar": "ارفيلرز",
                "label_fr": "Arvillers"
            },
            {
                "value": 34826,
                "label": "Villers-les-Roye",
                "label_ar": "فيلير ليه روي",
                "label_fr": "Villers-les-Roye"
            },
            {
                "value": 34827,
                "label": "Signy-le-Petit",
                "label_ar": "سيجني لو بيتي",
                "label_fr": "Signy-le-Petit"
            },
            {
                "value": 34828,
                "label": "Perrogney-les-Fontaines",
                "label_ar": "بيروجني ليه فونتين",
                "label_fr": "Perrogney-les-Fontaines"
            },
            {
                "value": 34829,
                "label": "Maysel",
                "label_ar": "ميسل",
                "label_fr": "Maysel"
            },
            {
                "value": 34830,
                "label": "Wavrechain-sous-Faulx",
                "label_ar": "Wavrechain-sous-Faulx",
                "label_fr": "Wavrechain-sous-Faulx"
            },
            {
                "value": 34831,
                "label": "Sainte-Croix",
                "label_ar": "سانت كروا",
                "label_fr": "Sainte-Croix"
            },
            {
                "value": 34832,
                "label": "Clamecy",
                "label_ar": "البطلينوس",
                "label_fr": "Clamecy"
            },
            {
                "value": 34833,
                "label": "Gouzeaucourt",
                "label_ar": "جوزيوكورت",
                "label_fr": "Gouzeaucourt"
            },
            {
                "value": 34834,
                "label": "Ergny",
                "label_ar": "ارجني",
                "label_fr": "Ergny"
            },
            {
                "value": 34835,
                "label": "Arleux-en-Gohelle",
                "label_ar": "Arleux-en-Gohelle",
                "label_fr": "Arleux-en-Gohelle"
            },
            {
                "value": 34836,
                "label": "Groffliers",
                "label_ar": "جروفليرس",
                "label_fr": "Groffliers"
            },
            {
                "value": 34837,
                "label": "Honnecourt-sur-Escaut",
                "label_ar": "هونكورت سور إسكوت",
                "label_fr": "Honnecourt-sur-Escaut"
            },
            {
                "value": 34838,
                "label": "Genech",
                "label_ar": "جينيك",
                "label_fr": "Genech"
            },
            {
                "value": 34839,
                "label": "Landas",
                "label_ar": "لانداس",
                "label_fr": "Landas"
            },
            {
                "value": 34840,
                "label": "Bouvaincourt-sur-Bresle",
                "label_ar": "بوفانكورت سور بريسلي",
                "label_fr": "Bouvaincourt-sur-Bresle"
            },
            {
                "value": 34841,
                "label": "Beugneux",
                "label_ar": "Beugneux",
                "label_fr": "Beugneux"
            },
            {
                "value": 34842,
                "label": "Lannoy-Cuillere",
                "label_ar": "لانوي كويلير",
                "label_fr": "Lannoy-Cuillere"
            },
            {
                "value": 34843,
                "label": "Houry",
                "label_ar": "حوري",
                "label_fr": "Houry"
            },
            {
                "value": 34844,
                "label": "Vouzy",
                "label_ar": "ضبابي",
                "label_fr": "Vouzy"
            },
            {
                "value": 34845,
                "label": "Pont-sur-Seine",
                "label_ar": "بونت سور سين",
                "label_fr": "Pont-sur-Seine"
            },
            {
                "value": 34846,
                "label": "Montpothier",
                "label_ar": "مونبوثير",
                "label_fr": "Montpothier"
            },
            {
                "value": 34847,
                "label": "Soyers",
                "label_ar": "الصويا",
                "label_fr": "Soyers"
            },
            {
                "value": 34848,
                "label": "Dampierre-au-Temple",
                "label_ar": "Dampierre-au-Temple",
                "label_fr": "Dampierre-au-Temple"
            },
            {
                "value": 34849,
                "label": "Muidorge",
                "label_ar": "Muidorge",
                "label_fr": "Muidorge"
            },
            {
                "value": 34850,
                "label": "Bernaville",
                "label_ar": "بيرنافيل",
                "label_fr": "Bernaville"
            },
            {
                "value": 34851,
                "label": "Bours",
                "label_ar": "بورس",
                "label_fr": "Bours"
            },
            {
                "value": 34852,
                "label": "Etelfay",
                "label_ar": "إتيلفاي",
                "label_fr": "Etelfay"
            },
            {
                "value": 34853,
                "label": "Goury",
                "label_ar": "غوري",
                "label_fr": "Goury"
            },
            {
                "value": 34854,
                "label": "Le Chesne",
                "label_ar": "لو تشيسن",
                "label_fr": "Le Chesne"
            },
            {
                "value": 34855,
                "label": "Estouteville-Ecalles",
                "label_ar": "Estouteville-Ecalles",
                "label_fr": "Estouteville-Ecalles"
            },
            {
                "value": 34856,
                "label": "Gauville-la-Campagne",
                "label_ar": "جوفيل لا كامباني",
                "label_fr": "Gauville-la-Campagne"
            },
            {
                "value": 34857,
                "label": "Auberville-la-Renault",
                "label_ar": "أوبيرفيل لا رينو",
                "label_fr": "Auberville-la-Renault"
            },
            {
                "value": 34858,
                "label": "Manneville-es-Plains",
                "label_ar": "مانفيل-إس-بلينز",
                "label_fr": "Manneville-es-Plaines"
            },
            {
                "value": 34859,
                "label": "Perriers-sur-Andelle",
                "label_ar": "بيرير سور أنديل",
                "label_fr": "Perriers-sur-Andelle"
            },
            {
                "value": 34860,
                "label": "Petiville",
                "label_ar": "بيتيفيل",
                "label_fr": "Petiville"
            },
            {
                "value": 34861,
                "label": "Auffay",
                "label_ar": "اوفاي",
                "label_fr": "Auffay"
            },
            {
                "value": 34862,
                "label": "Totes",
                "label_ar": "مستحضرات تجميل",
                "label_fr": "Cabas"
            },
            {
                "value": 34863,
                "label": "Ypreville-Biville",
                "label_ar": "يبريفيل بيفيل",
                "label_fr": "Ypreville-Biville"
            },
            {
                "value": 34864,
                "label": "Auberville-la-Campagne",
                "label_ar": "أوبيرفيل لا كامباني",
                "label_fr": "Auberville-la-Campagne"
            },
            {
                "value": 34865,
                "label": "Saint-Pierre-le-Vieux",
                "label_ar": "سان بيير لو فيو",
                "label_fr": "Saint-Pierre-le-Vieux"
            },
            {
                "value": 34866,
                "label": "Montigny",
                "label_ar": "مونتيني",
                "label_fr": "Montigny"
            },
            {
                "value": 34867,
                "label": "Saint-Martin-du-Tertre",
                "label_ar": "سان مارتن دو تيرتر",
                "label_fr": "Saint-Martin-du-Tertre"
            },
            {
                "value": 34868,
                "label": "Nerville-la-Foret",
                "label_ar": "نيرفيل لا فوريه",
                "label_fr": "Nerville-la-Foret"
            },
            {
                "value": 34869,
                "label": "Goussainville",
                "label_ar": "جوسانفيل",
                "label_fr": "Goussainville"
            },
            {
                "value": 34870,
                "label": "Assignan",
                "label_ar": "Assignan",
                "label_fr": "Assignan"
            },
            {
                "value": 34871,
                "label": "Mars",
                "label_ar": "المريخ",
                "label_fr": "Mars"
            },
            {
                "value": 34872,
                "label": "Brignon",
                "label_ar": "بريجنون",
                "label_fr": "Brignon"
            },
            {
                "value": 34873,
                "label": "Boissieres",
                "label_ar": "بواسير",
                "label_fr": "Boissières"
            },
            {
                "value": 34874,
                "label": "Malves-en-Minervois",
                "label_ar": "Malves-en-Minervois",
                "label_fr": "Malves-en-Minervois"
            },
            {
                "value": 34875,
                "label": "Cesseras",
                "label_ar": "Cesseras",
                "label_fr": "Cesseras"
            },
            {
                "value": 34876,
                "label": "Oupia",
                "label_ar": "أوبيا",
                "label_fr": "Oupia"
            },
            {
                "value": 34877,
                "label": "Apcher",
                "label_ar": "ابشر",
                "label_fr": "Apcher"
            },
            {
                "value": 34878,
                "label": "Montescot",
                "label_ar": "مونتيسكوت",
                "label_fr": "Montescot"
            },
            {
                "value": 34879,
                "label": "Portel-des-Corbieres",
                "label_ar": "Portel-des-Corbieres",
                "label_fr": "Portel-des-Corbières"
            },
            {
                "value": 34880,
                "label": "Bellegarde-du-Razes",
                "label_ar": "Bellegarde-du-Razes",
                "label_fr": "Bellegarde-du-Razes"
            },
            {
                "value": 34881,
                "label": "Saint-Maximin",
                "label_ar": "سانت ماكسيمين",
                "label_fr": "Saint-Maximin"
            },
            {
                "value": 34882,
                "label": "Saint-Pierre-la-Bourlhonne",
                "label_ar": "سان بيير لا بورلون",
                "label_fr": "Saint-Pierre-la-Bourlhonne"
            },
            {
                "value": 34883,
                "label": "Puilacher",
                "label_ar": "بويلاتشر",
                "label_fr": "Puilacher"
            },
            {
                "value": 34884,
                "label": "Capendu",
                "label_ar": "كابيندو",
                "label_fr": "Capendu"
            },
            {
                "value": 34885,
                "label": "Pouzols-Minervois",
                "label_ar": "Pouzols-Minervois",
                "label_fr": "Pouzols-Minervois"
            },
            {
                "value": 34886,
                "label": "Fontjoncouse",
                "label_ar": "Fontjoncouse",
                "label_fr": "Fontjoncouse"
            },
            {
                "value": 34887,
                "label": "Saint-Amand-Magnazeix",
                "label_ar": "سان اماند ماجنازي",
                "label_fr": "Saint-Amand-Magnazeix"
            },
            {
                "value": 34888,
                "label": "Vignolles",
                "label_ar": "فيجنول",
                "label_fr": "Vignolles"
            },
            {
                "value": 34889,
                "label": "Londigny",
                "label_ar": "لوندجني",
                "label_fr": "Londigny"
            },
            {
                "value": 34890,
                "label": "Saint-Martin-Lacaussade",
                "label_ar": "سان مارتن لاكوساد",
                "label_fr": "Saint-Martin-Lacaussade"
            },
            {
                "value": 34891,
                "label": "Pontours",
                "label_ar": "بونتور",
                "label_fr": "Pontours"
            },
            {
                "value": 34892,
                "label": "Carlucet",
                "label_ar": "كارلوسيت",
                "label_fr": "Carlucet"
            },
            {
                "value": 34893,
                "label": "Germond-Rouvre",
                "label_ar": "جيرموند روفر",
                "label_fr": "Germond-Rouvre"
            },
            {
                "value": 34894,
                "label": "Vasles",
                "label_ar": "فازليس",
                "label_fr": "Vasles"
            },
            {
                "value": 34895,
                "label": "Vindelle",
                "label_ar": "Vindelle",
                "label_fr": "Vindelle"
            },
            {
                "value": 34896,
                "label": "La Treille",
                "label_ar": "لا تريل",
                "label_fr": "La Treille"
            },
            {
                "value": 34897,
                "label": "Richemont",
                "label_ar": "ريتشمونت",
                "label_fr": "Richemont"
            },
            {
                "value": 34898,
                "label": "Varaignes",
                "label_ar": "فراجينز",
                "label_fr": "Varaignes"
            },
            {
                "value": 34899,
                "label": "Caumont-sur-Garonne",
                "label_ar": "كومونت سور غارون",
                "label_fr": "Caumont-sur-Garonne"
            },
            {
                "value": 34900,
                "label": "Lusignan-Grand",
                "label_ar": "لوزينيان جراند",
                "label_fr": "Lusignan-Grand"
            },
            {
                "value": 34901,
                "label": "Marigny-Brizay",
                "label_ar": "ماريني بريزاي",
                "label_fr": "Marigny-Brizay"
            },
            {
                "value": 34902,
                "label": "Ax-les-Thermes",
                "label_ar": "آكس ليه تيرمز",
                "label_fr": "Ax-les-Thermes"
            },
            {
                "value": 34903,
                "label": "Le Bez",
                "label_ar": "لو بيز",
                "label_fr": "Le Bez"
            },
            {
                "value": 34904,
                "label": "Lespinasse",
                "label_ar": "ليسبيناس",
                "label_fr": "Lespinasse"
            },
            {
                "value": 34905,
                "label": "Escazeaux",
                "label_ar": "اسكازو",
                "label_fr": "Escazeaux"
            },
            {
                "value": 34906,
                "label": "Avignonet-Lauragais",
                "label_ar": "أفينيونيت لوراجيس",
                "label_fr": "Avignonet-Lauragais"
            },
            {
                "value": 34907,
                "label": "Cassagne",
                "label_ar": "كاساني",
                "label_fr": "Cassagne"
            },
            {
                "value": 34908,
                "label": "Geaune",
                "label_ar": "جيون",
                "label_fr": "Geaune"
            },
            {
                "value": 34909,
                "label": "Noirterre",
                "label_ar": "نويرتير",
                "label_fr": "Noirterre"
            },
            {
                "value": 34910,
                "label": "Civrac-de-Blaye",
                "label_ar": "Civrac-de-Blaye",
                "label_fr": "Civrac-de-Blaye"
            },
            {
                "value": 34911,
                "label": "Marsaneix",
                "label_ar": "مارسانيكس",
                "label_fr": "Marsaneix"
            },
            {
                "value": 34912,
                "label": "Augignac",
                "label_ar": "أوجينياك",
                "label_fr": "Augignac"
            },
            {
                "value": 34913,
                "label": "Mauzens-et-Miremont",
                "label_ar": "Mauzens-et-Miremont",
                "label_fr": "Mauzens-et-Miremont"
            },
            {
                "value": 34914,
                "label": "Journiac",
                "label_ar": "رحلة",
                "label_fr": "Journiac"
            },
            {
                "value": 34915,
                "label": "La Bastide",
                "label_ar": "لا باستيد",
                "label_fr": "La Bastide"
            },
            {
                "value": 34916,
                "label": "Viven",
                "label_ar": "فيفين",
                "label_fr": "Viven"
            },
            {
                "value": 34917,
                "label": "Nieuil",
                "label_ar": "نيويل",
                "label_fr": "Nieuil"
            },
            {
                "value": 34918,
                "label": "Torxe",
                "label_ar": "توركس",
                "label_fr": "Torxe"
            },
            {
                "value": 34919,
                "label": "Cherac",
                "label_ar": "تشيراك",
                "label_fr": "Cherac"
            },
            {
                "value": 34920,
                "label": "Rouvre",
                "label_ar": "روفر",
                "label_fr": "Rouvre"
            },
            {
                "value": 34921,
                "label": "Saint-Meard-de-Gurcon",
                "label_ar": "سان ميرد دي جوركون",
                "label_fr": "Saint-Meard-de-Gurcon"
            },
            {
                "value": 34922,
                "label": "Pleumartin",
                "label_ar": "بليومارتين",
                "label_fr": "Pleumartin"
            },
            {
                "value": 34923,
                "label": "Bois-de-la-Pierre",
                "label_ar": "بوا دو لا بيير",
                "label_fr": "Bois-de-la-Pierre"
            },
            {
                "value": 34924,
                "label": "Peyrilles",
                "label_ar": "بيريل",
                "label_fr": "Peyrilles"
            },
            {
                "value": 34925,
                "label": "Marignac-Lasclares",
                "label_ar": "Marignac-Lasclares",
                "label_fr": "Marignac-Lasclares"
            },
            {
                "value": 34926,
                "label": "Nadillac",
                "label_ar": "ناديلاك",
                "label_fr": "Nadillac"
            },
            {
                "value": 34927,
                "label": "Rayssac",
                "label_ar": "ريساك",
                "label_fr": "Rayssac"
            },
            {
                "value": 34928,
                "label": "Asson",
                "label_ar": "أسون",
                "label_fr": "Asson"
            },
            {
                "value": 34929,
                "label": "Bonnegarde",
                "label_ar": "بونيغارد",
                "label_fr": "Bonnegarde"
            },
            {
                "value": 34930,
                "label": "Treffrin",
                "label_ar": "تريفرين",
                "label_fr": "Treffrin"
            },
            {
                "value": 34931,
                "label": "Guiler-sur-Goyen",
                "label_ar": "Guiler-sur-Goyen",
                "label_fr": "Guiler-sur-Goyen"
            },
            {
                "value": 34932,
                "label": "Lanveoc",
                "label_ar": "لانفيوك",
                "label_fr": "Lanvéoc"
            },
            {
                "value": 34933,
                "label": "Averton",
                "label_ar": "افرتون",
                "label_fr": "Averton"
            },
            {
                "value": 34934,
                "label": "Amanlis",
                "label_ar": "أمانليس",
                "label_fr": "Amanlis"
            },
            {
                "value": 34935,
                "label": "Plelo",
                "label_ar": "بليلو",
                "label_fr": "Plelo"
            },
            {
                "value": 34936,
                "label": "Guilliers",
                "label_ar": "Guilliers",
                "label_fr": "Guilliers"
            },
            {
                "value": 34937,
                "label": "Querrien",
                "label_ar": "كويرين",
                "label_fr": "Querrien"
            },
            {
                "value": 34938,
                "label": "Kerlouan",
                "label_ar": "كيرلوان",
                "label_fr": "Kerlouan"
            },
            {
                "value": 34939,
                "label": "Brasparts",
                "label_ar": "براسارتس",
                "label_fr": "Brasparts"
            },
            {
                "value": 34940,
                "label": "Meneac",
                "label_ar": "مينياك",
                "label_fr": "Meneac"
            },
            {
                "value": 34941,
                "label": "Loyat",
                "label_ar": "لويات",
                "label_fr": "Loyat"
            },
            {
                "value": 34942,
                "label": "Landunvez",
                "label_ar": "لاندونفيز",
                "label_fr": "Landunvez"
            },
            {
                "value": 34943,
                "label": "Plehedel",
                "label_ar": "بليديل",
                "label_fr": "Pléhédel"
            },
            {
                "value": 34944,
                "label": "Pire-sur-Seiche",
                "label_ar": "بيير سور سيش",
                "label_fr": "Pire-sur-Seiche"
            },
            {
                "value": 34945,
                "label": "Talensac",
                "label_ar": "تالينساك",
                "label_fr": "Talensac"
            },
            {
                "value": 34946,
                "label": "Lannebert",
                "label_ar": "لانبرت",
                "label_fr": "Lannebert"
            },
            {
                "value": 34947,
                "label": "Rieux",
                "label_ar": "ريو",
                "label_fr": "Rieux"
            },
            {
                "value": 34948,
                "label": "Trelivan",
                "label_ar": "تريليفان",
                "label_fr": "Trelivan"
            },
            {
                "value": 34949,
                "label": "Monsac",
                "label_ar": "مونساك",
                "label_fr": "Monsac"
            },
            {
                "value": 34950,
                "label": "Colombier",
                "label_ar": "كولومبير",
                "label_fr": "Colombier"
            },
            {
                "value": 34951,
                "label": "Roquebrune",
                "label_ar": "روكبرون",
                "label_fr": "Roquebrune"
            },
            {
                "value": 34952,
                "label": "Gimeux",
                "label_ar": "جيموكس",
                "label_fr": "Gimeux"
            },
            {
                "value": 34953,
                "label": "Neuvicq-le-Chateau",
                "label_ar": "نيوفيك لو شاتو",
                "label_fr": "Neuvicq-le-Château"
            },
            {
                "value": 34954,
                "label": "Pouffonds",
                "label_ar": "بوفوندس",
                "label_fr": "Pouffonds"
            },
            {
                "value": 34955,
                "label": "Chize",
                "label_ar": "تشيز",
                "label_fr": "Chize"
            },
            {
                "value": 34956,
                "label": "Bonrepos-Riquet",
                "label_ar": "بونريبوس ريكيه",
                "label_fr": "Bonrepos-Riquet"
            },
            {
                "value": 34957,
                "label": "Vieillevigne",
                "label_ar": "فيليفين",
                "label_fr": "Vieillevigne"
            },
            {
                "value": 34958,
                "label": "Asque",
                "label_ar": "أسك",
                "label_fr": "Asque"
            },
            {
                "value": 34959,
                "label": "Ganac",
                "label_ar": "جاناك",
                "label_fr": "Ganac"
            },
            {
                "value": 34960,
                "label": "Frontignan-Saves",
                "label_ar": "فرونتيجنان-ينقذ",
                "label_fr": "Frontignan-Save"
            },
            {
                "value": 34961,
                "label": "Valdurenque",
                "label_ar": "فالدورينك",
                "label_fr": "Valdurenque"
            },
            {
                "value": 34962,
                "label": "Linxe",
                "label_ar": "لينكس",
                "label_fr": "Linxe"
            },
            {
                "value": 34963,
                "label": "Auriac",
                "label_ar": "أورياك",
                "label_fr": "Auriac"
            },
            {
                "value": 34964,
                "label": "Sus",
                "label_ar": "سوس",
                "label_fr": "Sus"
            },
            {
                "value": 34965,
                "label": "Arboucave",
                "label_ar": "أربوكاف",
                "label_fr": "Arboucave"
            },
            {
                "value": 34966,
                "label": "Montpeyroux",
                "label_ar": "مونبيروكس",
                "label_fr": "Montpeyroux"
            },
            {
                "value": 34967,
                "label": "Villefranche-d'Albigeois",
                "label_ar": "فيلفرانش دالبجوا",
                "label_fr": "Villefranche-d'Albigeois"
            },
            {
                "value": 34968,
                "label": "Le Vigen",
                "label_ar": "لو فيغن",
                "label_fr": "Le Vigen"
            },
            {
                "value": 34969,
                "label": "Saint-Simon-de-Pellouaille",
                "label_ar": "سان سيمون دي بيلويل",
                "label_fr": "Saint-Simon-de-Pellouaille"
            },
            {
                "value": 34970,
                "label": "Fenioux",
                "label_ar": "فينيوكس",
                "label_fr": "Fenioux"
            },
            {
                "value": 34971,
                "label": "Jabreilles-les-Bordes",
                "label_ar": "جبريل ليه بورديس",
                "label_fr": "Jabreilles-les-Bordes"
            },
            {
                "value": 34972,
                "label": "Mauprevoir",
                "label_ar": "موبريفوير",
                "label_fr": "Mauprevoir"
            },
            {
                "value": 34973,
                "label": "Les Vallees",
                "label_ar": "ليه فالي",
                "label_fr": "Les Vallées"
            },
            {
                "value": 34974,
                "label": "Mirambeau",
                "label_ar": "ميرامبو",
                "label_fr": "Mirambeau"
            },
            {
                "value": 34975,
                "label": "Millac",
                "label_ar": "ميلاك",
                "label_fr": "Millac"
            },
            {
                "value": 34976,
                "label": "Vallegue",
                "label_ar": "فاليج",
                "label_fr": "Vallegue"
            },
            {
                "value": 34977,
                "label": "Pont-de-Salars",
                "label_ar": "بونت دي سالار",
                "label_fr": "Pont-de-Salars"
            },
            {
                "value": 34978,
                "label": "Flagnac",
                "label_ar": "Flagnac",
                "label_fr": "Flagnac"
            },
            {
                "value": 34979,
                "label": "Montfort-en-Chalosse",
                "label_ar": "مونتفورت أون تشالوس",
                "label_fr": "Montfort-en-Chalosse"
            },
            {
                "value": 34980,
                "label": "Saint-Nazaire-de-Valentane",
                "label_ar": "سان نازير دي فالنتين",
                "label_fr": "Saint-Nazaire-de-Valentane"
            },
            {
                "value": 34981,
                "label": "Labastide-Cezeracq",
                "label_ar": "لاباستيد- سيزيراك",
                "label_fr": "Labastide-Cezeracq"
            },
            {
                "value": 34982,
                "label": "La Tour-dAuvergne",
                "label_ar": "لا تور-دوفيرني",
                "label_fr": "La Tour-d'Auvergne"
            },
            {
                "value": 34983,
                "label": "Montmiral",
                "label_ar": "مونميرال",
                "label_fr": "Montmiral"
            },
            {
                "value": 34984,
                "label": "Les Places",
                "label_ar": "ليه بليسز",
                "label_fr": "Les Places"
            },
            {
                "value": 34985,
                "label": "Mauves",
                "label_ar": "موف",
                "label_fr": "Mauves"
            },
            {
                "value": 34986,
                "label": "Beauvallon",
                "label_ar": "بوفالون",
                "label_fr": "Beauvallon"
            },
            {
                "value": 34987,
                "label": "Vaux-en-Bugey",
                "label_ar": "فو-أون-بوجي",
                "label_fr": "Vaux-en-Bugey"
            },
            {
                "value": 34988,
                "label": "Piegros-la-Clastre",
                "label_ar": "بيجروس لا كلاستر",
                "label_fr": "Piegros-la-Clastre"
            },
            {
                "value": 34989,
                "label": "Marsaz",
                "label_ar": "مرساز",
                "label_fr": "Marsaz"
            },
            {
                "value": 34990,
                "label": "Limony",
                "label_ar": "ليمون",
                "label_fr": "Limony"
            },
            {
                "value": 34991,
                "label": "Taulignan",
                "label_ar": "تولينان",
                "label_fr": "Taulignan"
            },
            {
                "value": 34992,
                "label": "Parnans",
                "label_ar": "بارنانس",
                "label_fr": "Parnans"
            },
            {
                "value": 34993,
                "label": "Saint-Romain-d'Ay",
                "label_ar": "سان رومان داي",
                "label_fr": "Saint-Romain-d'Ay"
            },
            {
                "value": 34994,
                "label": "Le Chateau",
                "label_ar": "لو شاتو",
                "label_fr": "Le Château"
            },
            {
                "value": 34995,
                "label": "Montagut",
                "label_ar": "مونتاجوت",
                "label_fr": "Montagut"
            },
            {
                "value": 34996,
                "label": "Aouste-sur-Sye",
                "label_ar": "أوستي سور سي",
                "label_fr": "Aouste-sur-Sye"
            },
            {
                "value": 34997,
                "label": "Saint-Sauveur-de-Montagut",
                "label_ar": "سان سوفور دي مونتاجوت",
                "label_fr": "Saint-Sauveur-de-Montagut"
            },
            {
                "value": 34998,
                "label": "Cherier",
                "label_ar": "شيرير",
                "label_fr": "Cherier"
            },
            {
                "value": 34999,
                "label": "Rignieux-le-Franc",
                "label_ar": "Rignieux-le-Franc",
                "label_fr": "Rignieux-le-Franc"
            },
            {
                "value": 35000,
                "label": "Epercieux-Saint-Paul",
                "label_ar": "Epercieux-Saint-Paul",
                "label_fr": "Épercieux-Saint-Paul"
            },
            {
                "value": 35001,
                "label": "Planzolles",
                "label_ar": "بلانزول",
                "label_fr": "Planzolles"
            },
            {
                "value": 35001,
                "label": "Polliat",
                "label_ar": "بوليات",
                "label_fr": "Polliat"
            },
            {
                "value": 35002,
                "label": "Cors",
                "label_ar": "كورس",
                "label_fr": "Cors"
            },
            {
                "value": 35003,
                "label": "Le Bessat",
                "label_ar": "لو بيسات",
                "label_fr": "Le Bessat"
            },
            {
                "value": 35004,
                "label": "Sauzet",
                "label_ar": "سوزيت",
                "label_fr": "Sauzet"
            },
            {
                "value": 35005,
                "label": "Charantonnay",
                "label_ar": "شارانتوني",
                "label_fr": "Charantonnay"
            },
            {
                "value": 35006,
                "label": "Dommartin",
                "label_ar": "دومارتين",
                "label_fr": "Dommartin"
            },
            {
                "value": 35007,
                "label": "Saint-Verand",
                "label_ar": "سان فيران",
                "label_fr": "Saint-Vérand"
            },
            {
                "value": 35008,
                "label": "Soyons",
                "label_ar": "الصويون",
                "label_fr": "Soyons"
            },
            {
                "value": 35009,
                "label": "Saint-Rirand",
                "label_ar": "سان ريران",
                "label_fr": "Saint-Rirand"
            },
            {
                "value": 35010,
                "label": "Ceignes",
                "label_ar": "سيجن",
                "label_fr": "Ceignes"
            },
            {
                "value": 35011,
                "label": "Courtelevant",
                "label_ar": "ذو صلة",
                "label_fr": "Courtel pertinent"
            },
            {
                "value": 35012,
                "label": "Sologny",
                "label_ar": "سولوجني",
                "label_fr": "Sologny"
            },
            {
                "value": 35013,
                "label": "Vallerois-Lorioz",
                "label_ar": "Vallerois-Lorioz",
                "label_fr": "Vallerois-Lorioz"
            },
            {
                "value": 35014,
                "label": "Blondefontaine",
                "label_ar": "بلونديفونتين",
                "label_fr": "Blondefontaine"
            },
            {
                "value": 35015,
                "label": "Mervans",
                "label_ar": "ميرفان",
                "label_fr": "Mervans"
            },
            {
                "value": 35016,
                "label": "Anchenoncourt-et-Chazel",
                "label_ar": "Anchenoncourt-et-Chazel",
                "label_fr": "Anchenoncourt-et-Chazel"
            },
            {
                "value": 35017,
                "label": "Junay",
                "label_ar": "جوناي",
                "label_fr": "Juin"
            },
            {
                "value": 35018,
                "label": "Rochefort-sur-Nenon",
                "label_ar": "روشيفور سور نينون",
                "label_fr": "Rochefort-sur-Nenon"
            },
            {
                "value": 35019,
                "label": "Saint-Boil",
                "label_ar": "سان بويل",
                "label_fr": "Saint-ébullition"
            },
            {
                "value": 35020,
                "label": "Cogna",
                "label_ar": "كوجنا",
                "label_fr": "Cogna"
            },
            {
                "value": 35021,
                "label": "Bois-d'Amont",
                "label_ar": "بوا دامونت",
                "label_fr": "Bois-d'Amont"
            },
            {
                "value": 35022,
                "label": "Belleneuve",
                "label_ar": "بيلينوف",
                "label_fr": "Belleneuve"
            },
            {
                "value": 35023,
                "label": "Sommecaise",
                "label_ar": "السوميكايز",
                "label_fr": "Sommecaise"
            },
            {
                "value": 35024,
                "label": "Bining",
                "label_ar": "بينينغ",
                "label_fr": "Bining"
            },
            {
                "value": 35025,
                "label": "Harprich",
                "label_ar": "هاربريتش",
                "label_fr": "Harprich"
            },
            {
                "value": 35026,
                "label": "Mangonville",
                "label_ar": "مانجونفيل",
                "label_fr": "Mangonville"
            },
            {
                "value": 35027,
                "label": "Leintrey",
                "label_ar": "لينتري",
                "label_fr": "Leintrey"
            },
            {
                "value": 35028,
                "label": "Munster",
                "label_ar": "مونستر",
                "label_fr": "Munster"
            },
            {
                "value": 35029,
                "label": "Eteimbes",
                "label_ar": "إتيمبيس",
                "label_fr": "Eteimbes"
            },
            {
                "value": 35030,
                "label": "Amathay-Vesigneux",
                "label_ar": "Amathay-Vesigneux",
                "label_fr": "Amathay-Vesigneux"
            },
            {
                "value": 35031,
                "label": "Vaumort",
                "label_ar": "فومورت",
                "label_fr": "Vaumort"
            },
            {
                "value": 35032,
                "label": "Milly-Lamartine",
                "label_ar": "ميلي لامارتين",
                "label_fr": "Milly-Lamartine"
            },
            {
                "value": 35033,
                "label": "Voisin",
                "label_ar": "فويسين",
                "label_fr": "Voisin"
            },
            {
                "value": 35034,
                "label": "Amancey",
                "label_ar": "امانسي",
                "label_fr": "Amancey"
            },
            {
                "value": 35035,
                "label": "Cerilly",
                "label_ar": "سيريلي",
                "label_fr": "Cerilly"
            },
            {
                "value": 35036,
                "label": "Crissey",
                "label_ar": "كريسي",
                "label_fr": "Crissey"
            },
            {
                "value": 35037,
                "label": "Marcigny",
                "label_ar": "مارسيني",
                "label_fr": "Marcigny"
            },
            {
                "value": 35038,
                "label": "Rehon",
                "label_ar": "ريهون",
                "label_fr": "Rehon"
            },
            {
                "value": 35039,
                "label": "Raon-aux-Bois",
                "label_ar": "راون أوكس بوا",
                "label_fr": "Raon-aux-Bois"
            },
            {
                "value": 35040,
                "label": "Racrange",
                "label_ar": "راكرانج",
                "label_fr": "Racrange"
            },
            {
                "value": 35041,
                "label": "Rochesson",
                "label_ar": "روشيسون",
                "label_fr": "Rochesson"
            },
            {
                "value": 35042,
                "label": "Gezoncourt",
                "label_ar": "Gezoncourt",
                "label_fr": "Gezoncourt"
            },
            {
                "value": 35043,
                "label": "Vahl-les-Benestroff",
                "label_ar": "Vahl-les-Benestroff",
                "label_fr": "Vahl-les-Benestroff"
            },
            {
                "value": 35044,
                "label": "Francaltroff",
                "label_ar": "فرانكالتروف",
                "label_fr": "Francaltroff"
            },
            {
                "value": 35045,
                "label": "Ville",
                "label_ar": "فيل",
                "label_fr": "Ville"
            },
            {
                "value": 35046,
                "label": "Muespach-le-Haut",
                "label_ar": "Muespach-le-Haut",
                "label_fr": "Muespach-le-Haut"
            },
            {
                "value": 35047,
                "label": "Jungholtz",
                "label_ar": "Jungholtz",
                "label_fr": "Jungholtz"
            },
            {
                "value": 35048,
                "label": "Grandfontaine",
                "label_ar": "غراندفونتين",
                "label_fr": "Grandfontaine"
            },
            {
                "value": 35049,
                "label": "Thiefosse",
                "label_ar": "ثيفوس",
                "label_fr": "Voleur"
            },
            {
                "value": 35050,
                "label": "Champ-le-Duc",
                "label_ar": "تشامب لو دوك",
                "label_fr": "Champ-le-Duc"
            },
            {
                "value": 35051,
                "label": "Saint-Etienne-en-Cogles",
                "label_ar": "سانت إتيان أون كوجليس",
                "label_fr": "Saint-Étienne-en-Cogles"
            },
            {
                "value": 35052,
                "label": "Quintenic",
                "label_ar": "كوينتينيك",
                "label_fr": "Quinténique"
            },
            {
                "value": 35053,
                "label": "Pleudihen-sur-Rance",
                "label_ar": "Pleudihen-sur-Rance",
                "label_fr": "Pleudihen-sur-Rance"
            },
            {
                "value": 35054,
                "label": "Miniac-sous-Becherel",
                "label_ar": "مينياك صوص بيشيريل",
                "label_fr": "Miniac-sous-Becherel"
            },
            {
                "value": 35055,
                "label": "La Noe",
                "label_ar": "لا نوي",
                "label_fr": "La Noe"
            },
            {
                "value": 35056,
                "label": "Nevez",
                "label_ar": "نيفيز",
                "label_fr": "Névez"
            },
            {
                "value": 35057,
                "label": "Plumergat",
                "label_ar": "بلوميرغات",
                "label_fr": "Plumergat"
            },
            {
                "value": 35058,
                "label": "Mellionnec",
                "label_ar": "Mellionnec",
                "label_fr": "Mellionnec"
            },
            {
                "value": 35059,
                "label": "Hemonstoir",
                "label_ar": "Hemonstoir",
                "label_fr": "Hemonstoir"
            },
            {
                "value": 35060,
                "label": "Treglamus",
                "label_ar": "تريجلاموس",
                "label_fr": "Treglamus"
            },
            {
                "value": 35061,
                "label": "Erce-pres-Liffre",
                "label_ar": "إرس بريس ليفر",
                "label_fr": "Erce-pres-Liffre"
            },
            {
                "value": 35062,
                "label": "Loperhet",
                "label_ar": "لوريه",
                "label_fr": "Loperhet"
            },
            {
                "value": 35063,
                "label": "Saint-Maudan",
                "label_ar": "سان مودان",
                "label_fr": "Saint-Maudan"
            },
            {
                "value": 35064,
                "label": "Saint-Segal",
                "label_ar": "سان سيغال",
                "label_fr": "Saint-Segal"
            },
            {
                "value": 35065,
                "label": "Soisy-sur-Ecole",
                "label_ar": "Soisy-sur-Ecole",
                "label_fr": "Soisy-sur-Ecole"
            },
            {
                "value": 35066,
                "label": "Nouan-le-Fuzelier",
                "label_ar": "Nouan-le-Fuzelier",
                "label_fr": "Nouan-le-Fuzelier"
            },
            {
                "value": 35067,
                "label": "Pezou",
                "label_ar": "بيزو",
                "label_fr": "Pezou"
            },
            {
                "value": 35068,
                "label": "Sully-la-Chapelle",
                "label_ar": "سولي لا شابيل",
                "label_fr": "Sully-la-Chapelle"
            },
            {
                "value": 35069,
                "label": "Marcilly-en-Beauce",
                "label_ar": "مارسيلي أون بوس",
                "label_fr": "Marcilly-en-Beauce"
            },
            {
                "value": 35070,
                "label": "Avoine",
                "label_ar": "أفوين",
                "label_fr": "Avoine"
            },
            {
                "value": 35071,
                "label": "Mareau-aux-Bois",
                "label_ar": "مارو أو بوا",
                "label_fr": "Mareau-aux-Bois"
            },
            {
                "value": 35072,
                "label": "Poisieux",
                "label_ar": "Poisieux",
                "label_fr": "Poisieux"
            },
            {
                "value": 35073,
                "label": "Panzoult",
                "label_ar": "بانزولت",
                "label_fr": "Panzoult"
            },
            {
                "value": 35074,
                "label": "Veaugues",
                "label_ar": "فيوجوس",
                "label_fr": "Veaugues"
            },
            {
                "value": 35075,
                "label": "Châteauneuf-sur-Cher",
                "label_ar": "شاتونوف سور شير",
                "label_fr": "Châteauneuf-sur-Cher"
            },
            {
                "value": 35076,
                "label": "Theuville",
                "label_ar": "ثيوفيل",
                "label_fr": "Theuville"
            },
            {
                "value": 35077,
                "label": "Echarcon",
                "label_ar": "إشاركون",
                "label_fr": "Echarcon"
            },
            {
                "value": 35078,
                "label": "Hundsbach",
                "label_ar": "هوندسباخ",
                "label_fr": "Hundsbach"
            },
            {
                "value": 35079,
                "label": "Eulmont",
                "label_ar": "أولمونت",
                "label_fr": "Eulmont"
            },
            {
                "value": 35080,
                "label": "Fremifontaine",
                "label_ar": "فريميفونتين",
                "label_fr": "Frémifontaine"
            },
            {
                "value": 35081,
                "label": "Lautenbach",
                "label_ar": "لاوتنباخ",
                "label_fr": "Lautenbach"
            },
            {
                "value": 35082,
                "label": "Wuenheim",
                "label_ar": "ووينهايم",
                "label_fr": "Wuenheim"
            },
            {
                "value": 35083,
                "label": "Jebsheim",
                "label_ar": "Jebsheim",
                "label_fr": "Jebsheim"
            },
            {
                "value": 35084,
                "label": "Hartmannswiller",
                "label_ar": "هارتمانسفيلر",
                "label_fr": "Hartmannswiller"
            },
            {
                "value": 35085,
                "label": "Fouday",
                "label_ar": "فدائي",
                "label_fr": "Fouday"
            },
            {
                "value": 35086,
                "label": "Lorey",
                "label_ar": "لوري",
                "label_fr": "Lorey"
            },
            {
                "value": 35087,
                "label": "Vecoux",
                "label_ar": "فيكوكس",
                "label_fr": "Vecoux"
            },
            {
                "value": 35088,
                "label": "Wittersheim",
                "label_ar": "ويترشيم",
                "label_fr": "Wittersheim"
            },
            {
                "value": 35089,
                "label": "Strueth",
                "label_ar": "ستروث",
                "label_fr": "Strueth"
            },
            {
                "value": 35090,
                "label": "Saales",
                "label_ar": "ساليس",
                "label_fr": "Saales"
            },
            {
                "value": 35091,
                "label": "Gimbrett",
                "label_ar": "جيمبريت",
                "label_fr": "Gimbrett"
            },
            {
                "value": 35092,
                "label": "Sauvage",
                "label_ar": "سوفاج",
                "label_fr": "Sauvage"
            },
            {
                "value": 35093,
                "label": "Vecqueville",
                "label_ar": "فيكوفيل",
                "label_fr": "Vecqueville"
            },
            {
                "value": 35094,
                "label": "Ambonnay",
                "label_ar": "أمبوناي",
                "label_fr": "Ambonnay"
            },
            {
                "value": 35095,
                "label": "Connantray-Vaurefroy",
                "label_ar": "كونانتراي فوريفروي",
                "label_fr": "Connantray-Vaurefroy"
            },
            {
                "value": 35096,
                "label": "Wissant",
                "label_ar": "ويسانت",
                "label_fr": "Wissant"
            },
            {
                "value": 35097,
                "label": "Vieux-Moulin",
                "label_ar": "Vieux-Moulin",
                "label_fr": "Vieux-Moulin"
            },
            {
                "value": 35098,
                "label": "Montreuil-sur-Breche",
                "label_ar": "مونتروي سور بريشي",
                "label_fr": "Montreuil-sur-Brèche"
            },
            {
                "value": 35099,
                "label": "Provencheres-sur-Meuse",
                "label_ar": "بروفينشيريس سور ميوز",
                "label_fr": "Provencheres-sur-Meuse"
            },
            {
                "value": 35100,
                "label": "Moeslains",
                "label_ar": "مويسلين",
                "label_fr": "Moeslains"
            },
            {
                "value": 35101,
                "label": "Givron",
                "label_ar": "جيفرون",
                "label_fr": "Givron"
            },
            {
                "value": 35102,
                "label": "Achiet-le-Grand",
                "label_ar": "آشيت لو جراند",
                "label_fr": "Achiet-le-Grand"
            },
            {
                "value": 35103,
                "label": "L'Epine",
                "label_ar": "لابين",
                "label_fr": "L'Épine"
            },
            {
                "value": 35104,
                "label": "Bercenay-en-Othe",
                "label_ar": "بيرسيناي أون أوثي",
                "label_fr": "Bercenay-en-Othe"
            },
            {
                "value": 35105,
                "label": "Margut",
                "label_ar": "مارغوت",
                "label_fr": "Margut"
            },
            {
                "value": 35106,
                "label": "Culmont",
                "label_ar": "كولمونت",
                "label_fr": "Culmont"
            },
            {
                "value": 35107,
                "label": "Cramant",
                "label_ar": "كرامانت",
                "label_fr": "Cramant"
            },
            {
                "value": 35108,
                "label": "Fort-Mahon-Plage",
                "label_ar": "فورت ماهون بلاج",
                "label_fr": "Fort-Mahon-Plage"
            },
            {
                "value": 35109,
                "label": "Epenancourt",
                "label_ar": "إبينانكور",
                "label_fr": "Épénancourt"
            },
            {
                "value": 35110,
                "label": "Hebuterne",
                "label_ar": "هيبوتيرن",
                "label_fr": "Hebuterne"
            },
            {
                "value": 35111,
                "label": "Fouilleuse",
                "label_ar": "فوليوز",
                "label_fr": "Fouilleuse"
            },
            {
                "value": 35112,
                "label": "Laleu",
                "label_ar": "Laleu",
                "label_fr": "Laleu"
            },
            {
                "value": 35113,
                "label": "Chezy-sur-Marne",
                "label_ar": "تشيزي سور مارن",
                "label_fr": "Chezy-sur-Marne"
            },
            {
                "value": 35114,
                "label": "Jussy",
                "label_ar": "جوسي",
                "label_fr": "Jussy"
            },
            {
                "value": 35115,
                "label": "Pargny-les-Bois",
                "label_ar": "Pargny-les-Bois",
                "label_fr": "Pargny-les-Bois"
            },
            {
                "value": 35116,
                "label": "Villers-Allerand",
                "label_ar": "فيليرز أليران",
                "label_fr": "Villers-Allerand"
            },
            {
                "value": 35117,
                "label": "Vailly",
                "label_ar": "فيللي",
                "label_fr": "Vailly"
            },
            {
                "value": 35118,
                "label": "Jouy-sous-Thelle",
                "label_ar": "جوي سوس ثيل",
                "label_fr": "Jouy-sous-Thelle"
            },
            {
                "value": 35119,
                "label": "Havrincourt",
                "label_ar": "هافرينكور",
                "label_fr": "Havrincourt"
            },
            {
                "value": 35120,
                "label": "Trosly-Breuil",
                "label_ar": "تروسلي برويل",
                "label_fr": "Trosly-Breuil"
            },
            {
                "value": 35121,
                "label": "Boisbergues",
                "label_ar": "Boisbergues",
                "label_fr": "Boisbergues"
            },
            {
                "value": 35122,
                "label": "Andechy",
                "label_ar": "أنديشي",
                "label_fr": "Andechy"
            },
            {
                "value": 35123,
                "label": "Ferreux-Quincey",
                "label_ar": "فيرو كوينسي",
                "label_fr": "Ferreux-Quincey"
            },
            {
                "value": 35124,
                "label": "Abbecourt",
                "label_ar": "أبيكور",
                "label_fr": "Abbecourt"
            },
            {
                "value": 35125,
                "label": "Manicamp",
                "label_ar": "مانيكامب",
                "label_fr": "Manicamp"
            },
            {
                "value": 35126,
                "label": "Brombos",
                "label_ar": "برومبوس",
                "label_fr": "Brombos"
            },
            {
                "value": 35127,
                "label": "Bonneil",
                "label_ar": "بونيل",
                "label_fr": "Bonneil"
            },
            {
                "value": 35128,
                "label": "Chierry",
                "label_ar": "شيري",
                "label_fr": "Chierry"
            },
            {
                "value": 35129,
                "label": "Malras",
                "label_ar": "مالراس",
                "label_fr": "Malras"
            },
            {
                "value": 35130,
                "label": "Jonquieres",
                "label_ar": "جونكيريس",
                "label_fr": "Jonquières"
            },
            {
                "value": 35131,
                "label": "Fabrezan",
                "label_ar": "فابريزان",
                "label_fr": "Fabrezan"
            },
            {
                "value": 35132,
                "label": "Felix",
                "label_ar": "فيليكس",
                "label_fr": "Félix"
            },
            {
                "value": 35133,
                "label": "Cassagnes",
                "label_ar": "كاساجنس",
                "label_fr": "Cassagnes"
            },
            {
                "value": 35134,
                "label": "Corbere-les-Cabanes",
                "label_ar": "كوربير ليه كابانيس",
                "label_fr": "Corbère-les-Cabanes"
            },
            {
                "value": 35135,
                "label": "Magrie",
                "label_ar": "ماجري",
                "label_fr": "Magrie"
            },
            {
                "value": 35136,
                "label": "Villesequelande",
                "label_ar": "فيليسكيلاندي",
                "label_fr": "Villesequelande"
            },
            {
                "value": 35137,
                "label": "Villeneuve-Minervois",
                "label_ar": "فيلنوف مينيرفوا",
                "label_fr": "Villeneuve-Minervois"
            },
            {
                "value": 35138,
                "label": "Les Cabanes",
                "label_ar": "ليه كابانيس",
                "label_fr": "Les Cabanes"
            },
            {
                "value": 35139,
                "label": "Saint-Victor-la-Coste",
                "label_ar": "سان فيكتور لا كوست",
                "label_fr": "Saint-Victor-la-Coste"
            },
            {
                "value": 35140,
                "label": "St Laurent des Arbres",
                "label_ar": "سانت لوران دي أربريس",
                "label_fr": "St Laurent des Arbres"
            },
            {
                "value": 35141,
                "label": "Galargues",
                "label_ar": "غالارج",
                "label_fr": "Galargues"
            },
            {
                "value": 35142,
                "label": "Le Martinet",
                "label_ar": "لو مارتينيه",
                "label_fr": "Le Martinet"
            },
            {
                "value": 35143,
                "label": "Carlipa",
                "label_ar": "كارليبا",
                "label_fr": "Carlipa"
            },
            {
                "value": 35144,
                "label": "Saint-Series",
                "label_ar": "سلسلة سانت",
                "label_fr": "Saint-Série"
            },
            {
                "value": 35145,
                "label": "Castelnau-Valence",
                "label_ar": "كاستيلناو فالينس",
                "label_fr": "Castelnau-Valence"
            },
            {
                "value": 35146,
                "label": "Villie-Morgon",
                "label_ar": "فيلي مورجون",
                "label_fr": "Villie-Morgon"
            },
            {
                "value": 35147,
                "label": "Civens",
                "label_ar": "سيفينز",
                "label_fr": "Civens"
            },
            {
                "value": 35148,
                "label": "Mont",
                "label_ar": "مونت",
                "label_fr": "Mont"
            },
            {
                "value": 35149,
                "label": "Drom",
                "label_ar": "دروم",
                "label_fr": "Drom"
            },
            {
                "value": 35150,
                "label": "Le Breuil",
                "label_ar": "لو بريل",
                "label_fr": "Le Breuil"
            },
            {
                "value": 35151,
                "label": "Saint-Paul-les-Romans",
                "label_ar": "سان بول ليس رومان",
                "label_fr": "Saint-Paul-les-Romains"
            },
            {
                "value": 35152,
                "label": "Colonzelle",
                "label_ar": "كولونزيل",
                "label_fr": "Colonzelle"
            },
            {
                "value": 35153,
                "label": "Besayes",
                "label_ar": "بيسايس",
                "label_fr": "Besayes"
            },
            {
                "value": 35154,
                "label": "Marcilly-d'Azergues",
                "label_ar": "مارسيلي دازرجس",
                "label_fr": "Marcilly-d'Azergues"
            },
            {
                "value": 35155,
                "label": "Chateaudouble",
                "label_ar": "شاتودوبل",
                "label_fr": "Châteaudouble"
            },
            {
                "value": 35156,
                "label": "Pouilly-le-Monial",
                "label_ar": "بويلي لو مونيال",
                "label_fr": "Pouilly-le-Monial"
            },
            {
                "value": 35157,
                "label": "Oriol-en-Royans",
                "label_ar": "Oriol-en-Royans",
                "label_fr": "Oriol-en-Royans"
            },
            {
                "value": 35158,
                "label": "Condillac",
                "label_ar": "كونديلاك",
                "label_fr": "Condillac"
            },
            {
                "value": 35159,
                "label": "La Coucourde",
                "label_ar": "لا Coucourde",
                "label_fr": "La Coucourde"
            },
            {
                "value": 35160,
                "label": "La Tuiliere",
                "label_ar": "لا تويليري",
                "label_fr": "La Tuilière"
            },
            {
                "value": 35161,
                "label": "Chassiers",
                "label_ar": "Chassiers",
                "label_fr": "Chassiers"
            },
            {
                "value": 35162,
                "label": "Pont-de-Barret",
                "label_ar": "بونت دي باريت",
                "label_fr": "Pont-de-Barret"
            },
            {
                "value": 35163,
                "label": "Saint-Desirat",
                "label_ar": "سانت ديسيرات",
                "label_fr": "Saint-Désirat"
            },
            {
                "value": 35164,
                "label": "Alix",
                "label_ar": "أليكس",
                "label_fr": "Alix"
            },
            {
                "value": 35165,
                "label": "Albigny-sur-Saone",
                "label_ar": "ألبيني سور سون",
                "label_fr": "Albigny-sur-Saône"
            },
            {
                "value": 35166,
                "label": "Maringes",
                "label_ar": "الأفراس",
                "label_fr": "Maringes"
            },
            {
                "value": 35167,
                "label": "Perreux",
                "label_ar": "Perreux",
                "label_fr": "Perreux"
            },
            {
                "value": 35168,
                "label": "Montcarra",
                "label_ar": "مونتكارا",
                "label_fr": "Montcarra"
            },
            {
                "value": 35169,
                "label": "Boisset-et-Gaujac",
                "label_ar": "Boisset-et-Gaujac",
                "label_fr": "Boisset-et-Gaujac"
            },
            {
                "value": 35170,
                "label": "Saint-Feliu-d'Amont",
                "label_ar": "سان فيليو دامونت",
                "label_fr": "Saint-Feliu-d'Amont"
            },
            {
                "value": 35171,
                "label": "Sant Andreu de Sureda",
                "label_ar": "سانت أندرو دي سوريدا",
                "label_fr": "Sant Andreu de Sureda"
            },
            {
                "value": 35172,
                "label": "Aigues-Vives",
                "label_ar": "إيغيس فيفز",
                "label_fr": "Aigues-Vives"
            },
            {
                "value": 35173,
                "label": "Sainte-Valiere",
                "label_ar": "سانت فاليير",
                "label_fr": "Sainte-Valière"
            },
            {
                "value": 35174,
                "label": "Saint-Jean-de-Serres",
                "label_ar": "سان جان دي سيريس",
                "label_fr": "Saint-Jean-de-Serres"
            },
            {
                "value": 35175,
                "label": "Cassagnoles",
                "label_ar": "الكاساغنول",
                "label_fr": "Cassagnoles"
            },
            {
                "value": 35176,
                "label": "Florac",
                "label_ar": "فلوراك",
                "label_fr": "Florac"
            },
            {
                "value": 35177,
                "label": "Garrigues",
                "label_ar": "غاريغ",
                "label_fr": "Garrigues"
            },
            {
                "value": 35178,
                "label": "Mejannes-les-Ales",
                "label_ar": "Mejannes-les-Ales",
                "label_fr": "Méjannes-les-Alès"
            },
            {
                "value": 35179,
                "label": "Saint-Marcel-sur-Aude",
                "label_ar": "سان مارسيل سور اود",
                "label_fr": "Saint-Marcel-sur-Aude"
            },
            {
                "value": 35180,
                "label": "Roquefort-des-Corbieres",
                "label_ar": "روكفور دي كوربيريس",
                "label_fr": "Roquefort-des-Corbières"
            },
            {
                "value": 35181,
                "label": "Roubia",
                "label_ar": "روبية",
                "label_fr": "Roubia"
            },
            {
                "value": 35182,
                "label": "Saint-Benezet",
                "label_ar": "سان بينيزيه",
                "label_fr": "Saint-Bénézet"
            },
            {
                "value": 35183,
                "label": "Teyjat",
                "label_ar": "تيجات",
                "label_fr": "Teyjat"
            },
            {
                "value": 35184,
                "label": "Urval",
                "label_ar": "أورفال",
                "label_fr": "Urval"
            },
            {
                "value": 35185,
                "label": "Vitrac",
                "label_ar": "فيتراك",
                "label_fr": "Vitrac"
            },
            {
                "value": 35186,
                "label": "Aubas",
                "label_ar": "اوباس",
                "label_fr": "Aubas"
            },
            {
                "value": 35187,
                "label": "Parzac",
                "label_ar": "بارزاك",
                "label_fr": "Parzac"
            },
            {
                "value": 35188,
                "label": "Perigne",
                "label_ar": "Perigne",
                "label_fr": "Périgné"
            },
            {
                "value": 35189,
                "label": "Annepont",
                "label_ar": "أنيبونت",
                "label_fr": "Annepont"
            },
            {
                "value": 35190,
                "label": "Villamblard",
                "label_ar": "فيلامبلارد",
                "label_fr": "Villamblard"
            },
            {
                "value": 35191,
                "label": "Bonnetan",
                "label_ar": "بونيتين",
                "label_fr": "Bonnetan"
            },
            {
                "value": 35192,
                "label": "Cherves-Richemont",
                "label_ar": "تشيرفز ريتشمونت",
                "label_fr": "Cherves-Richemont"
            },
            {
                "value": 35193,
                "label": "Queaux",
                "label_ar": "كوو",
                "label_fr": "Queaux"
            },
            {
                "value": 35194,
                "label": "Vernon",
                "label_ar": "فيرنون",
                "label_fr": "Vernon"
            },
            {
                "value": 35195,
                "label": "La Puye",
                "label_ar": "لا بوي",
                "label_fr": "La Puye"
            },
            {
                "value": 35196,
                "label": "Mainxe",
                "label_ar": "مينكس",
                "label_fr": "Mainxe"
            },
            {
                "value": 35197,
                "label": "Albussac",
                "label_ar": "البوساك",
                "label_fr": "Albussac"
            },
            {
                "value": 35198,
                "label": "Corignac",
                "label_ar": "كوريناك",
                "label_fr": "Corignac"
            },
            {
                "value": 35199,
                "label": "Saint-Denis-des-Murs",
                "label_ar": "سان دوني دي مور",
                "label_fr": "Saint-Denis-des-Murs"
            },
            {
                "value": 35200,
                "label": "Vianne",
                "label_ar": "فيان",
                "label_fr": "Vianne"
            },
            {
                "value": 35201,
                "label": "La Fouillade",
                "label_ar": "لا فيليد",
                "label_fr": "La Fouillade"
            },
            {
                "value": 35202,
                "label": "Aubrac",
                "label_ar": "اوبراك",
                "label_fr": "Aubrac"
            },
            {
                "value": 35203,
                "label": "Mouzieys-Teulet",
                "label_ar": "موزيس تيوليت",
                "label_fr": "Mouzieys-Teulet"
            },
            {
                "value": 35204,
                "label": "Boulogne-sur-Gesse",
                "label_ar": "بولوني سور جيسي",
                "label_fr": "Boulogne-sur-Gesse"
            },
            {
                "value": 35205,
                "label": "Saint-Lary-Boujean",
                "label_ar": "سان لاري بوجيان",
                "label_fr": "Saint-Lary-Boujean"
            },
            {
                "value": 35206,
                "label": "Saint-Jean-du-Bruel",
                "label_ar": "سان جان دو برويل",
                "label_fr": "Saint-Jean-du-Bruel"
            },
            {
                "value": 35207,
                "label": "Bouhet",
                "label_ar": "بوهيت",
                "label_fr": "Bouhet"
            },
            {
                "value": 35208,
                "label": "Saint-Sulpice-de-Royan",
                "label_ar": "سان سولبيس دي رويان",
                "label_fr": "Saint-Sulpice-de-Royan"
            },
            {
                "value": 35209,
                "label": "Villegats",
                "label_ar": "فيليجاتس",
                "label_fr": "Villegats"
            },
            {
                "value": 35210,
                "label": "Champsac",
                "label_ar": "تشامبساك",
                "label_fr": "Champsac"
            },
            {
                "value": 35211,
                "label": "Villiers-en-Plaine",
                "label_ar": "فيلير أون بلين",
                "label_fr": "Villiers-en-Plaine"
            },
            {
                "value": 35212,
                "label": "Marsilly",
                "label_ar": "مارسيلي",
                "label_fr": "Marsilly"
            },
            {
                "value": 35213,
                "label": "Verrieres",
                "label_ar": "فيريريز",
                "label_fr": "Verrières"
            },
            {
                "value": 35214,
                "label": "Chantemerle-sur-la-Soie",
                "label_ar": "Chantemerle-sur-la-Soie",
                "label_fr": "Chantemerle-sur-la-Soie"
            },
            {
                "value": 35215,
                "label": "Xaintray",
                "label_ar": "Xaintray",
                "label_fr": "Xaintray"
            },
            {
                "value": 35216,
                "label": "Courcon",
                "label_ar": "كوركون",
                "label_fr": "Courcon"
            },
            {
                "value": 35217,
                "label": "Cherves-Chatelars",
                "label_ar": "Cherves-Chatelars",
                "label_fr": "Cherves-Châtelars"
            },
            {
                "value": 35218,
                "label": "Surin",
                "label_ar": "سورين",
                "label_fr": "Surin"
            },
            {
                "value": 35219,
                "label": "Colayrac-Saint-Cirq",
                "label_ar": "كولايراك سانت سيرك",
                "label_fr": "Colayrac-Saint-Cirq"
            },
            {
                "value": 35220,
                "label": "Castillon-la-Bataille",
                "label_ar": "كاستيلون لا باتاي",
                "label_fr": "Castillon-la-Bataille"
            },
            {
                "value": 35221,
                "label": "Livernon",
                "label_ar": "ليفيرنون",
                "label_fr": "Livernon"
            },
            {
                "value": 35222,
                "label": "Serres-sur-Arget",
                "label_ar": "سيريس سور ارجيت",
                "label_fr": "Serres-sur-Arget"
            },
            {
                "value": 35223,
                "label": "Buanes",
                "label_ar": "Buanes",
                "label_fr": "Buanes"
            },
            {
                "value": 35224,
                "label": "Carnin",
                "label_ar": "كارنين",
                "label_fr": "Carnin"
            },
            {
                "value": 35225,
                "label": "Verchin",
                "label_ar": "فيرشين",
                "label_fr": "Verchin"
            },
            {
                "value": 35226,
                "label": "Saint-Pierre-Brouck",
                "label_ar": "سان بيير بروك",
                "label_fr": "Saint-Pierre-Brouck"
            },
            {
                "value": 35227,
                "label": "Rochy-Conde",
                "label_ar": "روشى كوندي",
                "label_fr": "Rochy-Condé"
            },
            {
                "value": 35228,
                "label": "Cerisy-la-Salle",
                "label_ar": "Cerisy-la-Salle",
                "label_fr": "Cerisy-la-Salle"
            },
            {
                "value": 35229,
                "label": "Velye",
                "label_ar": "فيلي",
                "label_fr": "Velye"
            },
            {
                "value": 35230,
                "label": "Ambleny",
                "label_ar": "أمبليني",
                "label_fr": "Ambleny"
            },
            {
                "value": 35231,
                "label": "Beaurevoir",
                "label_ar": "بيوريفير",
                "label_fr": "Beaurevoir"
            },
            {
                "value": 35232,
                "label": "Demuin",
                "label_ar": "ديمين",
                "label_fr": "Démuin"
            },
            {
                "value": 35233,
                "label": "Chiry-Ourscamp",
                "label_ar": "Chiry-Ourscamp",
                "label_fr": "Chiry-Ourscamp"
            },
            {
                "value": 35234,
                "label": "Muirancourt",
                "label_ar": "ميرانكورت",
                "label_fr": "Muirancourt"
            },
            {
                "value": 35235,
                "label": "Vraux",
                "label_ar": "Vraux",
                "label_fr": "Vraux"
            },
            {
                "value": 35236,
                "label": "Osne-le-Val",
                "label_ar": "أوسن لو فال",
                "label_fr": "Osne-le-Val"
            },
            {
                "value": 35237,
                "label": "Juvigny",
                "label_ar": "جوفيني",
                "label_fr": "Juvigny"
            },
            {
                "value": 35238,
                "label": "Quernes",
                "label_ar": "Quernes",
                "label_fr": "Quernes"
            },
            {
                "value": 35239,
                "label": "Sailly-le-Sec",
                "label_ar": "Sailly-le-Sec",
                "label_fr": "Sailly-le-Sec"
            },
            {
                "value": 35240,
                "label": "Therdonne",
                "label_ar": "ثيردون",
                "label_fr": "Therdonne"
            },
            {
                "value": 35241,
                "label": "Mareuil-en-Brie",
                "label_ar": "مريل أون بري",
                "label_fr": "Mareuil-en-Brie"
            },
            {
                "value": 35242,
                "label": "Chevillon",
                "label_ar": "شيفيلون",
                "label_fr": "Chevillon"
            },
            {
                "value": 35243,
                "label": "Flavigny",
                "label_ar": "فلافيني",
                "label_fr": "Flavigny"
            },
            {
                "value": 35244,
                "label": "Chalindrey",
                "label_ar": "تشاليندري",
                "label_fr": "Chalindrey"
            },
            {
                "value": 35245,
                "label": "Oger",
                "label_ar": "أوجيه",
                "label_fr": "Oger"
            },
            {
                "value": 35246,
                "label": "Herchies",
                "label_ar": "هرشيش",
                "label_fr": "Herchies"
            },
            {
                "value": 35247,
                "label": "Cagny",
                "label_ar": "كاجني",
                "label_fr": "Cagny"
            },
            {
                "value": 35248,
                "label": "Blacourt",
                "label_ar": "بلاكور",
                "label_fr": "Blacourt"
            },
            {
                "value": 35249,
                "label": "Saint-Urbain-Maconcourt",
                "label_ar": "سان أوربين ماكونكور",
                "label_fr": "Saint-Urbain-Maconcourt"
            },
            {
                "value": 35250,
                "label": "Curel",
                "label_ar": "Curel",
                "label_fr": "Curel"
            },
            {
                "value": 35251,
                "label": "Boyer",
                "label_ar": "بوير",
                "label_fr": "Boyer"
            },
            {
                "value": 35252,
                "label": "Oiselay-et-Grachaux",
                "label_ar": "Oiselay-et-Grachaux",
                "label_fr": "Oiselay-et-Grachaux"
            },
            {
                "value": 35253,
                "label": "Marsannay-le-Bois",
                "label_ar": "مارساني لو بوا",
                "label_fr": "Marsannay-le-Bois"
            },
            {
                "value": 35254,
                "label": "Ottonville",
                "label_ar": "أوتونفيل",
                "label_fr": "Ottonville"
            },
            {
                "value": 35255,
                "label": "Bellemagny",
                "label_ar": "بيلماجني",
                "label_fr": "Bellemagny"
            },
            {
                "value": 35256,
                "label": "Hammeville",
                "label_ar": "هامفيل",
                "label_fr": "Hammeville"
            },
            {
                "value": 35257,
                "label": "Clemery",
                "label_ar": "كليمري",
                "label_fr": "Clemery"
            },
            {
                "value": 35258,
                "label": "Malaumont",
                "label_ar": "مالومونت",
                "label_fr": "Malaumont"
            },
            {
                "value": 35259,
                "label": "Bremenil",
                "label_ar": "بريمينيل",
                "label_fr": "Bréménil"
            },
            {
                "value": 35260,
                "label": "Loisy",
                "label_ar": "Loisy",
                "label_fr": "Loisy"
            },
            {
                "value": 35261,
                "label": "Berthelange",
                "label_ar": "بيرثيلانج",
                "label_fr": "Berthelange"
            },
            {
                "value": 35262,
                "label": "Irancy",
                "label_ar": "إيرانسي",
                "label_fr": "Irancy"
            },
            {
                "value": 35263,
                "label": "Montenoison",
                "label_ar": "مونتنويسون",
                "label_fr": "Monténoison"
            },
            {
                "value": 35264,
                "label": "Saint-Romain-le-Preux",
                "label_ar": "سان رومان لو بريوكس",
                "label_fr": "Saint-Romain-le-Preux"
            },
            {
                "value": 35265,
                "label": "Cerisiers",
                "label_ar": "سيريسيرس",
                "label_fr": "Cerisiers"
            },
            {
                "value": 35266,
                "label": "Malay-le-Grand",
                "label_ar": "الملايو لي جراند",
                "label_fr": "Malais-le-grand"
            },
            {
                "value": 35267,
                "label": "Saint-Albain",
                "label_ar": "سان ألبين",
                "label_fr": "Saint-Albain"
            },
            {
                "value": 35268,
                "label": "Waville",
                "label_ar": "وافيل",
                "label_fr": "Waville"
            },
            {
                "value": 35269,
                "label": "Dugny-sur-Meuse",
                "label_ar": "دوجني سور ميوز",
                "label_fr": "Dugny-sur-Meuse"
            },
            {
                "value": 35270,
                "label": "Abreschviller",
                "label_ar": "ابريشفيلر",
                "label_fr": "Abreschviller"
            },
            {
                "value": 35271,
                "label": "Rauwiller",
                "label_ar": "راوفيلر",
                "label_fr": "Rauwiller"
            },
            {
                "value": 35272,
                "label": "Cirey-sur-Vezouze",
                "label_ar": "سيري سور فيزوز",
                "label_fr": "Cirey-sur-Vezouze"
            },
            {
                "value": 35273,
                "label": "Valleroy-le-Sec",
                "label_ar": "فاليروي لو سيك",
                "label_fr": "Valleroy-le-Sec"
            },
            {
                "value": 35274,
                "label": "Rainville",
                "label_ar": "رينفيل",
                "label_fr": "Rainville"
            },
            {
                "value": 35275,
                "label": "Allain",
                "label_ar": "ألين",
                "label_fr": "Allain"
            },
            {
                "value": 35276,
                "label": "Betting",
                "label_ar": "الرهان",
                "label_fr": "Pari"
            },
            {
                "value": 35277,
                "label": "Prayssas",
                "label_ar": "برايساس",
                "label_fr": "Prayssas"
            },
            {
                "value": 35278,
                "label": "Sorges",
                "label_ar": "سورج",
                "label_fr": "Sorges"
            },
            {
                "value": 35279,
                "label": "Gornac",
                "label_ar": "جورناك",
                "label_fr": "Gornac"
            },
            {
                "value": 35280,
                "label": "Puymiclan",
                "label_ar": "Puymiclan",
                "label_fr": "Puymiclan"
            },
            {
                "value": 35281,
                "label": "Galapian",
                "label_ar": "جالابيان",
                "label_fr": "Galapian"
            },
            {
                "value": 35282,
                "label": "Saint-Quentin-du-Dropt",
                "label_ar": "سانت كوينتين دو دروب",
                "label_fr": "Saint-Quentin-du-Dropt"
            },
            {
                "value": 35283,
                "label": "Montdragon",
                "label_ar": "مونتراجون",
                "label_fr": "Montdragon"
            },
            {
                "value": 35284,
                "label": "Beurlay",
                "label_ar": "بيرلاي",
                "label_fr": "Beurlay"
            },
            {
                "value": 35285,
                "label": "Allonne",
                "label_ar": "ألون",
                "label_fr": "Allonne"
            },
            {
                "value": 35286,
                "label": "Princay",
                "label_ar": "برينكاي",
                "label_fr": "Princay"
            },
            {
                "value": 35287,
                "label": "Calmels-et-le-Viala",
                "label_ar": "Calmels-et-le-Viala",
                "label_fr": "Calmels-et-le-Viala"
            },
            {
                "value": 35288,
                "label": "Cuqueron",
                "label_ar": "كوكيرون",
                "label_fr": "Cuqueron"
            },
            {
                "value": 35289,
                "label": "Prechac",
                "label_ar": "بريشاك",
                "label_fr": "Préchac"
            },
            {
                "value": 35290,
                "label": "Cahuzac-sur-Vere",
                "label_ar": "كاهوزاك سور فير",
                "label_fr": "Cahuzac-sur-Vere"
            },
            {
                "value": 35291,
                "label": "Puybrun",
                "label_ar": "بويبرون",
                "label_fr": "Puybrun"
            },
            {
                "value": 35292,
                "label": "Campet-et-Lamolere",
                "label_ar": "Campet-et-Lamolere",
                "label_fr": "Campet-et-Lamolère"
            },
            {
                "value": 35293,
                "label": "La Riviere",
                "label_ar": "لا ريفيير",
                "label_fr": "La Rivière"
            },
            {
                "value": 35294,
                "label": "Piegut-Pluviers",
                "label_ar": "Piegut-Pluviers",
                "label_fr": "Piegut-Pluviers"
            },
            {
                "value": 35295,
                "label": "Mazion",
                "label_ar": "مزيون",
                "label_fr": "Mazion"
            },
            {
                "value": 35296,
                "label": "Veyrignac",
                "label_ar": "فيرينياك",
                "label_fr": "Veyrignac"
            },
            {
                "value": 35297,
                "label": "Parisot",
                "label_ar": "باريسوت",
                "label_fr": "Parisot"
            },
            {
                "value": 35298,
                "label": "Sigogne",
                "label_ar": "سيجوجن",
                "label_fr": "Sigogne"
            },
            {
                "value": 35299,
                "label": "Les Billanges",
                "label_ar": "ليه بيلانج",
                "label_fr": "Les Billanges"
            },
            {
                "value": 35300,
                "label": "Saint-Maurice-les-Brousses",
                "label_ar": "سان موريس ليس بروس",
                "label_fr": "Saint-Maurice-les-Brousses"
            },
            {
                "value": 35301,
                "label": "Angeac-Champagne",
                "label_ar": "أنجيك الشمبانيا",
                "label_fr": "Angeac-Champagne"
            },
            {
                "value": 35302,
                "label": "Autevielle-Saint-Martin-Bideren",
                "label_ar": "Autevielle-Saint-Martin-Bideren",
                "label_fr": "Autevielle-Saint-Martin-Bideren"
            },
            {
                "value": 35303,
                "label": "Lisle",
                "label_ar": "ليسل",
                "label_fr": "Lisle"
            },
            {
                "value": 35304,
                "label": "Bretenoux",
                "label_ar": "بريتنو",
                "label_fr": "Bretenoux"
            },
            {
                "value": 35305,
                "label": "Pauilhac",
                "label_ar": "باويلاك",
                "label_fr": "Pauilhac"
            },
            {
                "value": 35306,
                "label": "Biars-sur-Cere",
                "label_ar": "بيارس سور سيري",
                "label_fr": "Biars-sur-Cère"
            },
            {
                "value": 35307,
                "label": "Saint-Paul-de-Jarrat",
                "label_ar": "سان بول دي جارات",
                "label_fr": "Saint-Paul-de-Jarrat"
            },
            {
                "value": 35308,
                "label": "Espinasses",
                "label_ar": "اسبيناس",
                "label_fr": "Espinasses"
            },
            {
                "value": 35309,
                "label": "Penta-di-Casinca",
                "label_ar": "بنتا دي كاسينكا",
                "label_fr": "Penta-di-Casinca"
            },
            {
                "value": 35310,
                "label": "Castellare-di-Mercurio",
                "label_ar": "كاستيلاري دي ميركوريو",
                "label_fr": "Castellare-di-Mercurio"
            },
            {
                "value": 35311,
                "label": "Appietto",
                "label_ar": "أبييتو",
                "label_fr": "Appietto"
            },
            {
                "value": 35312,
                "label": "Moules",
                "label_ar": "مول",
                "label_fr": "Moules"
            },
            {
                "value": 35313,
                "label": "Nessa",
                "label_ar": "نيسا",
                "label_fr": "Nessa"
            },
            {
                "value": 35314,
                "label": "Beauvezer",
                "label_ar": "بوفيزر",
                "label_fr": "Beauvezer"
            },
            {
                "value": 35315,
                "label": "Hermeray",
                "label_ar": "هيرميري",
                "label_fr": "Hermeray"
            },
            {
                "value": 35316,
                "label": "Cheptainville",
                "label_ar": "تشيبتينفيل",
                "label_fr": "Cheptainville"
            },
            {
                "value": 35317,
                "label": "Boisville-la-Saint-Pere",
                "label_ar": "Boisville-la-Saint-Pere",
                "label_fr": "Boisville-la-Saint-Père"
            },
            {
                "value": 35318,
                "label": "Petit Chaumont",
                "label_ar": "بيتي شومون",
                "label_fr": "Petit Chaumont"
            },
            {
                "value": 35319,
                "label": "Buzancais",
                "label_ar": "Buzancais",
                "label_fr": "Buzancais"
            },
            {
                "value": 35320,
                "label": "Villevillon",
                "label_ar": "فيليفيلون",
                "label_fr": "Villevillon"
            },
            {
                "value": 35321,
                "label": "Greneville-en-Beauce",
                "label_ar": "جرينفيل أون بوس",
                "label_fr": "Greneville-en-Beauce"
            },
            {
                "value": 35322,
                "label": "Mery-es-Bois",
                "label_ar": "ميري إس بوا",
                "label_fr": "Mery-es-Bois"
            },
            {
                "value": 35323,
                "label": "Autreche",
                "label_ar": "Autreche",
                "label_fr": "Autreche"
            },
            {
                "value": 35324,
                "label": "Saint-Viatre",
                "label_ar": "سانت فياتري",
                "label_fr": "Saint-Viatre"
            },
            {
                "value": 35325,
                "label": "Fussy",
                "label_ar": "صعب",
                "label_fr": "Pointilleux"
            },
            {
                "value": 35326,
                "label": "Beaumont-les-Autels",
                "label_ar": "بومونت ليه أوتيلز",
                "label_fr": "Beaumont-les-Autels"
            },
            {
                "value": 35327,
                "label": "Palluau-sur-Indre",
                "label_ar": "Palluau-sur-Indre",
                "label_fr": "Palluau-sur-Indre"
            },
            {
                "value": 35328,
                "label": "Bleury",
                "label_ar": "بليوري",
                "label_fr": "Bleury"
            },
            {
                "value": 35329,
                "label": "Poville",
                "label_ar": "بوفيل",
                "label_fr": "Poville"
            },
            {
                "value": 35330,
                "label": "Bazincourt-sur-Epte",
                "label_ar": "بازينكور سور إبت",
                "label_fr": "Bazincourt-sur-Epte"
            },
            {
                "value": 35331,
                "label": "La Londe",
                "label_ar": "لا لوند",
                "label_fr": "La Londe"
            },
            {
                "value": 35332,
                "label": "Dampsmesnil",
                "label_ar": "Dampsmesnil",
                "label_fr": "Dampsmesnil"
            },
            {
                "value": 35333,
                "label": "Bezu-Saint-Eloi",
                "label_ar": "Bezu-Saint-Eloi",
                "label_fr": "Bezu-Saint-Eloi"
            },
            {
                "value": 35334,
                "label": "Notre-Dame-d'Aliermont",
                "label_ar": "نوتردام داليرمونت",
                "label_fr": "Notre-Dame-d'Aliermont"
            },
            {
                "value": 35335,
                "label": "Sainte-Opportune-du-Bosc",
                "label_ar": "Sainte-Opportune-du-Bosc",
                "label_fr": "Sainte-Opportune-du-Bosc"
            },
            {
                "value": 35336,
                "label": "Bosc-Mesnil",
                "label_ar": "Bosc-Mesnil",
                "label_fr": "Bosc-Mesnil"
            },
            {
                "value": 35337,
                "label": "Torcy-le-Grand",
                "label_ar": "Torcy-le-Grand",
                "label_fr": "Torcy-le-Grand"
            },
            {
                "value": 35338,
                "label": "Argueil",
                "label_ar": "ارغيل",
                "label_fr": "Argueil"
            },
            {
                "value": 35339,
                "label": "Montreuil-en-Caux",
                "label_ar": "مونتروي أون كو",
                "label_fr": "Montreuil-en-Caux"
            },
            {
                "value": 35340,
                "label": "Bosguerard-de-Marcouville",
                "label_ar": "بوسجيرارد دي ماركوفيل",
                "label_fr": "Bosguerard-de-Marcouville"
            },
            {
                "value": 35341,
                "label": "Cuttoli-Corticchiato",
                "label_ar": "Cuttoli-Corticchiato",
                "label_fr": "Cuttoli-Corticchiato"
            },
            {
                "value": 35342,
                "label": "Saint-Etienne-du-Gres",
                "label_ar": "سانت إتيان دو جريس",
                "label_fr": "Saint-Étienne-du-Gres"
            },
            {
                "value": 35343,
                "label": "Banon",
                "label_ar": "بانون",
                "label_fr": "Interdiction de"
            },
            {
                "value": 35344,
                "label": "Valle-di-Mezzana",
                "label_ar": "فالي دي ميزانا",
                "label_fr": "Valle-di-Mezzana"
            },
            {
                "value": 35345,
                "label": "Corbieres",
                "label_ar": "كوربيريس",
                "label_fr": "Corbières"
            },
            {
                "value": 35346,
                "label": "Cervieres",
                "label_ar": "سيرفيريس",
                "label_fr": "Cervières"
            },
            {
                "value": 35347,
                "label": "Isigny-sur-Mer",
                "label_ar": "إيسيني سور مير",
                "label_fr": "Isigny-sur-Mer"
            },
            {
                "value": 35348,
                "label": "Ouezy",
                "label_ar": "اوزي",
                "label_fr": "Ouezy"
            },
            {
                "value": 35349,
                "label": "Courson",
                "label_ar": "كورسون",
                "label_fr": "Courson"
            },
            {
                "value": 35350,
                "label": "Saint-Georges-Montcocq",
                "label_ar": "سان جورج مونكوك",
                "label_fr": "Saint-Georges-Montcocq"
            },
            {
                "value": 35351,
                "label": "Chailloue",
                "label_ar": "Chailloue",
                "label_fr": "Chailloue"
            },
            {
                "value": 35352,
                "label": "Carolles",
                "label_ar": "كارولليس",
                "label_fr": "Carolles"
            },
            {
                "value": 35353,
                "label": "Montilly-sur-Noireau",
                "label_ar": "مونتيلي سور نويرو",
                "label_fr": "Montilly-sur-Noireau"
            },
            {
                "value": 35354,
                "label": "Saint-Germain-de-Tournebut",
                "label_ar": "سان جيرمان دي تورنبوت",
                "label_fr": "Saint-Germain-de-Tournebut"
            },
            {
                "value": 35355,
                "label": "Sottevast",
                "label_ar": "سوتيفاست",
                "label_fr": "Sottevast"
            },
            {
                "value": 35356,
                "label": "Courtonne-la-Meurdrac",
                "label_ar": "كورتون لا ميردراك",
                "label_fr": "Courtonne-la-Meurdrac"
            },
            {
                "value": 35357,
                "label": "La Chapelle-Yvon",
                "label_ar": "لا شابيل إيفون",
                "label_fr": "La Chapelle-Yvon"
            },
            {
                "value": 35358,
                "label": "Les Bordes",
                "label_ar": "ليه بوردس",
                "label_fr": "Les Bordes"
            },
            {
                "value": 35359,
                "label": "Tagnon",
                "label_ar": "تاغنون",
                "label_fr": "Tagnon"
            },
            {
                "value": 35360,
                "label": "Eleu-dit-Leauwette",
                "label_ar": "إليو ديت لياويت",
                "label_fr": "Eleu-dit-Leauwette"
            },
            {
                "value": 35361,
                "label": "Dennebroeucq",
                "label_ar": "Dennebroeucq",
                "label_fr": "Dennebroeucq"
            },
            {
                "value": 35362,
                "label": "Wasnes-au-Bac",
                "label_ar": "Wasnes-au-Bac",
                "label_fr": "Wasnes-au-Bac"
            },
            {
                "value": 35363,
                "label": "Remaucourt",
                "label_ar": "ريماوكورت",
                "label_fr": "Remaucourt"
            },
            {
                "value": 35364,
                "label": "Beaudricourt",
                "label_ar": "بيودريكورت",
                "label_fr": "Beaudricourt"
            },
            {
                "value": 35365,
                "label": "Morbecque",
                "label_ar": "موربيك",
                "label_fr": "Morbecque"
            },
            {
                "value": 35366,
                "label": "Houchin",
                "label_ar": "حوشين",
                "label_fr": "Houchin"
            },
            {
                "value": 35367,
                "label": "Helesmes",
                "label_ar": "هيليس",
                "label_fr": "Helesmes"
            },
            {
                "value": 35368,
                "label": "Domart-sur-la-Luce",
                "label_ar": "دومارت سور لا لوس",
                "label_fr": "Domart-sur-la-Luce"
            },
            {
                "value": 35369,
                "label": "Hodenc-en-Bray",
                "label_ar": "هودينك أون براي",
                "label_fr": "Hodenc-en-Bray"
            },
            {
                "value": 35370,
                "label": "Tille",
                "label_ar": "تيل",
                "label_fr": "Tille"
            },
            {
                "value": 35371,
                "label": "Les Ageux",
                "label_ar": "ليه أجيو",
                "label_fr": "Les Ageux"
            },
            {
                "value": 35372,
                "label": "Allery",
                "label_ar": "أليري",
                "label_fr": "Allery"
            },
            {
                "value": 35373,
                "label": "Silly-Tillard",
                "label_ar": "سخيف تيلارد",
                "label_fr": "Silly-Tillard"
            },
            {
                "value": 35374,
                "label": "Varesnes",
                "label_ar": "فاريسنيس",
                "label_fr": "Varesnes"
            },
            {
                "value": 35375,
                "label": "Le Quesnel",
                "label_ar": "لو كويسنيل",
                "label_fr": "Le Quesnel"
            },
            {
                "value": 35376,
                "label": "Favieres",
                "label_ar": "فافيريس",
                "label_fr": "Favieres"
            },
            {
                "value": 35377,
                "label": "Rollot",
                "label_ar": "رولو",
                "label_fr": "Rollot"
            },
            {
                "value": 35378,
                "label": "Brousseval",
                "label_ar": "بروسيفال",
                "label_fr": "Brousseval"
            },
            {
                "value": 35379,
                "label": "Saint-Amand-sur-Fion",
                "label_ar": "سان اماند سور فيون",
                "label_fr": "Saint-Amand-sur-Fion"
            },
            {
                "value": 35380,
                "label": "Audincthun",
                "label_ar": "أوديكنتشون",
                "label_fr": "Audincthun"
            },
            {
                "value": 35381,
                "label": "Sorrus",
                "label_ar": "حزن",
                "label_fr": "Sorrus"
            },
            {
                "value": 35382,
                "label": "Cappelle-en-Pevele",
                "label_ar": "كابيل أون بيفيل",
                "label_fr": "Cappelle-en-Pevele"
            },
            {
                "value": 35383,
                "label": "Vecquemont",
                "label_ar": "فيكويمونت",
                "label_fr": "Vecquemont"
            },
            {
                "value": 35384,
                "label": "Villembray",
                "label_ar": "فيليمبري",
                "label_fr": "Villembray"
            },
            {
                "value": 35385,
                "label": "Bachy",
                "label_ar": "باتشي",
                "label_fr": "Bachy"
            },
            {
                "value": 35386,
                "label": "Gonnehem",
                "label_ar": "جونهم",
                "label_fr": "Gonnehem"
            },
            {
                "value": 35387,
                "label": "Thelus",
                "label_ar": "ثيلوس",
                "label_fr": "Thélus"
            },
            {
                "value": 35388,
                "label": "Ligescourt",
                "label_ar": "ليجسكورت",
                "label_fr": "Ligescourt"
            },
            {
                "value": 35389,
                "label": "Montmacq",
                "label_ar": "Montmacq",
                "label_fr": "Montmacq"
            },
            {
                "value": 35390,
                "label": "Ribemont-sur-Ancre",
                "label_ar": "ريبمونت سور أنكر",
                "label_fr": "Ribemont-sur-Ancre"
            },
            {
                "value": 35391,
                "label": "Mont-Notre-Dame",
                "label_ar": "مونت نوتردام",
                "label_fr": "Mont-Notre-Dame"
            },
            {
                "value": 35392,
                "label": "Cayeux-sur-Mer",
                "label_ar": "كايوكس سور مير",
                "label_fr": "Cayeux-sur-Mer"
            },
            {
                "value": 35393,
                "label": "Salvagnac",
                "label_ar": "سالفناك",
                "label_fr": "Salvagnac"
            },
            {
                "value": 35394,
                "label": "Grezillac",
                "label_ar": "جريزيلاك",
                "label_fr": "Grezillac"
            },
            {
                "value": 35395,
                "label": "Lafitole",
                "label_ar": "لافيتول",
                "label_fr": "Lafitole"
            },
            {
                "value": 35396,
                "label": "Auxances",
                "label_ar": "المساعدين",
                "label_fr": "Auxances"
            },
            {
                "value": 35397,
                "label": "Loix",
                "label_ar": "لويكس",
                "label_fr": "Loix"
            },
            {
                "value": 35398,
                "label": "Largeasse",
                "label_ar": "كبير",
                "label_fr": "Largeasse"
            },
            {
                "value": 35399,
                "label": "Prin-Deyrancon",
                "label_ar": "برين ديرانكون",
                "label_fr": "Prin-Deyrancon"
            },
            {
                "value": 35400,
                "label": "Tuzaguet",
                "label_ar": "توزاجيت",
                "label_fr": "Tuzaguet"
            },
            {
                "value": 35401,
                "label": "Cassagnes-Begonhes",
                "label_ar": "Cassagnes-Begonhes",
                "label_fr": "Cassagnes-Bégonhes"
            },
            {
                "value": 35402,
                "label": "Noe",
                "label_ar": "نوي",
                "label_fr": "Noe"
            },
            {
                "value": 35403,
                "label": "Seissan",
                "label_ar": "سيسان",
                "label_fr": "Seissan"
            },
            {
                "value": 35404,
                "label": "Ancizan",
                "label_ar": "Ancizan",
                "label_fr": "Ancizan"
            },
            {
                "value": 35405,
                "label": "Lacaussade",
                "label_ar": "لاكوساد",
                "label_fr": "Lacaussade"
            },
            {
                "value": 35406,
                "label": "Saint-Seurin-de-Cursac",
                "label_ar": "سان سورين دي كورساك",
                "label_fr": "Saint-Seurin-de-Cursac"
            },
            {
                "value": 35407,
                "label": "Maubourguet",
                "label_ar": "موبورجت",
                "label_fr": "Maubourguet"
            },
            {
                "value": 35408,
                "label": "Lalbenque",
                "label_ar": "لبنك",
                "label_fr": "Lalbenque"
            },
            {
                "value": 35409,
                "label": "Ballon",
                "label_ar": "بالون",
                "label_fr": "Ballon"
            },
            {
                "value": 35410,
                "label": "L'Isle-Jourdain",
                "label_ar": "L'Isle-Jourdain",
                "label_fr": "L'Isle-Jourdain"
            },
            {
                "value": 35411,
                "label": "Place",
                "label_ar": "مكان",
                "label_fr": "Endroit"
            },
            {
                "value": 35412,
                "label": "Charroux",
                "label_ar": "شارو",
                "label_fr": "Charroux"
            },
            {
                "value": 35413,
                "label": "Villiers-sur-Chize",
                "label_ar": "فيلير سور تشيز",
                "label_fr": "Villiers-sur-Chize"
            },
            {
                "value": 35414,
                "label": "La Clisse",
                "label_ar": "لا كليسيه",
                "label_fr": "La Clisse"
            },
            {
                "value": 35415,
                "label": "Saint-Hilaire-Peyroux",
                "label_ar": "سان هيلير بيروكس",
                "label_fr": "Saint-Hilaire-Peyroux"
            },
            {
                "value": 35416,
                "label": "Bazian",
                "label_ar": "بازيان",
                "label_fr": "Bazian"
            },
            {
                "value": 35417,
                "label": "Peyrilhac",
                "label_ar": "بيريلاك",
                "label_fr": "Peyrilhac"
            },
            {
                "value": 35418,
                "label": "Lalanne-Arque",
                "label_ar": "لالان أرك",
                "label_fr": "Lalanne-Arque"
            },
            {
                "value": 35419,
                "label": "Lacapelle-Cabanac",
                "label_ar": "لاكابيل كاباناك",
                "label_fr": "Lacapelle-Cabanac"
            },
            {
                "value": 35420,
                "label": "Panassac",
                "label_ar": "باناساك",
                "label_fr": "Panassac"
            },
            {
                "value": 35421,
                "label": "Saint-Pandelon",
                "label_ar": "سان بانديلون",
                "label_fr": "Saint-Pandelon"
            },
            {
                "value": 35422,
                "label": "Brussieu",
                "label_ar": "بروسيو",
                "label_fr": "Brussieu"
            },
            {
                "value": 35423,
                "label": "Lemps",
                "label_ar": "ليمبس",
                "label_fr": "Lemps"
            },
            {
                "value": 35424,
                "label": "Souspierre",
                "label_ar": "سوسبيير",
                "label_fr": "Souspierre"
            },
            {
                "value": 35425,
                "label": "Allan",
                "label_ar": "ألان",
                "label_fr": "Allan"
            },
            {
                "value": 35426,
                "label": "Vanosc",
                "label_ar": "فانوسك",
                "label_fr": "Vanosc"
            },
            {
                "value": 35427,
                "label": "Montceau",
                "label_ar": "مونتسو",
                "label_fr": "Montceau"
            },
            {
                "value": 35428,
                "label": "Ampuis",
                "label_ar": "أمبويس",
                "label_fr": "Ampuis"
            },
            {
                "value": 35429,
                "label": "Bois-de-Gand",
                "label_ar": "Bois-de-Gand",
                "label_fr": "Bois-de-Gand"
            },
            {
                "value": 35430,
                "label": "Chateauneuf-de-Galaure",
                "label_ar": "Chateauneuf-de-Galaure",
                "label_fr": "Châteauneuf-de-Galaure"
            },
            {
                "value": 35431,
                "label": "Saint-Just-de-Claix",
                "label_ar": "سان جوست دي كليكس",
                "label_fr": "Saint-Just-de-Claix"
            },
            {
                "value": 35432,
                "label": "Saint-Maurice-sur-Eygues",
                "label_ar": "سان موريس سور إيج",
                "label_fr": "Saint-Maurice-sur-Eygues"
            },
            {
                "value": 35433,
                "label": "Eyzin-Pinet",
                "label_ar": "ايزين بينيت",
                "label_fr": "Eyzin-Pinet"
            },
            {
                "value": 35434,
                "label": "Saint-Etienne-du-Bois",
                "label_ar": "سانت إتيان دو بوا",
                "label_fr": "Saint-Étienne-du-Bois"
            },
            {
                "value": 35435,
                "label": "Saint-Julien",
                "label_ar": "سانت جوليان",
                "label_fr": "Saint-Julien"
            },
            {
                "value": 35436,
                "label": "Arcens",
                "label_ar": "آركينز",
                "label_fr": "Arcens"
            },
            {
                "value": 35437,
                "label": "Chambost-Longessaigne",
                "label_ar": "شامبوست لونجيسيني",
                "label_fr": "Chambost-Longessaigne"
            },
            {
                "value": 35438,
                "label": "Gevrieux",
                "label_ar": "جيفريوكس",
                "label_fr": "Gevrieux"
            },
            {
                "value": 35439,
                "label": "Ratieres",
                "label_ar": "راتيريس",
                "label_fr": "Ratières"
            },
            {
                "value": 35440,
                "label": "Saint-Etienne-le-Molard",
                "label_ar": "سانت إتيان لو مولارد",
                "label_fr": "Saint-Étienne-le-Molard"
            },
            {
                "value": 35441,
                "label": "Saint-Denis-en-Bugey",
                "label_ar": "سان دوني أون بوجي",
                "label_fr": "Saint-Denis-en-Bugey"
            },
            {
                "value": 35442,
                "label": "Ansouis",
                "label_ar": "أنسويس",
                "label_fr": "Ansouis"
            },
            {
                "value": 35443,
                "label": "Lafare",
                "label_ar": "لافير",
                "label_fr": "Lafare"
            },
            {
                "value": 35444,
                "label": "Les Baux de Provence",
                "label_ar": "لي بو دو بروفانس",
                "label_fr": "Les Baux de Provence"
            },
            {
                "value": 35445,
                "label": "Jonquerettes",
                "label_ar": "جونكيريتس",
                "label_fr": "Jonquerettes"
            },
            {
                "value": 35446,
                "label": "Mezel",
                "label_ar": "ميزل",
                "label_fr": "Mezel"
            },
            {
                "value": 35447,
                "label": "Esparron-de-Verdon",
                "label_ar": "اسبارون دي فيردون",
                "label_fr": "Esparron-de-Verdon"
            },
            {
                "value": 35448,
                "label": "Chabestan",
                "label_ar": "تشابستان",
                "label_fr": "Chabestan"
            },
            {
                "value": 35449,
                "label": "La Bastide",
                "label_ar": "لا باستيد",
                "label_fr": "La Bastide"
            },
            {
                "value": 35450,
                "label": "Taillades",
                "label_ar": "تيلاديس",
                "label_fr": "Taillades"
            },
            {
                "value": 35451,
                "label": "Aubigney",
                "label_ar": "أوبيجني",
                "label_fr": "Aubigney"
            },
            {
                "value": 35452,
                "label": "Suin",
                "label_ar": "سوين",
                "label_fr": "Suin"
            },
            {
                "value": 35453,
                "label": "Baudemont",
                "label_ar": "بودمونت",
                "label_fr": "Baudemont"
            },
            {
                "value": 35454,
                "label": "Crimolois",
                "label_ar": "كريمولويس",
                "label_fr": "Crimolois"
            },
            {
                "value": 35455,
                "label": "L'Etoile",
                "label_ar": "ليتوال",
                "label_fr": "L'Étoile"
            },
            {
                "value": 35456,
                "label": "Conliege",
                "label_ar": "كونليج",
                "label_fr": "Conliege"
            },
            {
                "value": 35457,
                "label": "Cour-et-Buis",
                "label_ar": "Cour-et-Buis",
                "label_fr": "Cour-et-Buis"
            },
            {
                "value": 35458,
                "label": "Chaussin",
                "label_ar": "شوسين",
                "label_fr": "Chaussin"
            },
            {
                "value": 35459,
                "label": "Montecheroux",
                "label_ar": "مونتيشيرو",
                "label_fr": "Montecheroux"
            },
            {
                "value": 35460,
                "label": "Quarre-les-Tombes",
                "label_ar": "كواري ليه تومب",
                "label_fr": "Quarre-les-Tombes"
            },
            {
                "value": 35461,
                "label": "Laning",
                "label_ar": "لانينج",
                "label_fr": "Laning"
            },
            {
                "value": 35462,
                "label": "Gondrecourt-le-Chateau",
                "label_ar": "Gondrecourt-le-Chateau",
                "label_fr": "Gondrecourt-le-Château"
            },
            {
                "value": 35463,
                "label": "Vaudeville",
                "label_ar": "فودفيل",
                "label_fr": "Vaudeville"
            },
            {
                "value": 35464,
                "label": "Courcelles",
                "label_ar": "المحاكم",
                "label_fr": "Courcelles"
            },
            {
                "value": 35465,
                "label": "Chatenois",
                "label_ar": "شاتينوا",
                "label_fr": "Chatenois"
            },
            {
                "value": 35466,
                "label": "Relanges",
                "label_ar": "ينقل",
                "label_fr": "Relanges"
            },
            {
                "value": 35467,
                "label": "Spicheren",
                "label_ar": "سبيشيرين",
                "label_fr": "Spicheren"
            },
            {
                "value": 35468,
                "label": "Urbeis",
                "label_ar": "أوربيس",
                "label_fr": "Urbeis"
            },
            {
                "value": 35469,
                "label": "Fouchy",
                "label_ar": "فوشي",
                "label_fr": "Fouchy"
            },
            {
                "value": 35470,
                "label": "Sougy-sur-Loire",
                "label_ar": "سوجي سور لوار",
                "label_fr": "Sougy-sur-Loire"
            },
            {
                "value": 35471,
                "label": "Bessey-les-Citeaux",
                "label_ar": "بيسي ليه سيتو",
                "label_fr": "Bessey-les-Citeaux"
            },
            {
                "value": 35472,
                "label": "Varennes-sous-Dun",
                "label_ar": "فارينيس سوس دون",
                "label_fr": "Varennes-sous-Dun"
            },
            {
                "value": 35473,
                "label": "Rahon",
                "label_ar": "راهون",
                "label_fr": "Rahon"
            },
            {
                "value": 35474,
                "label": "Blamont",
                "label_ar": "بلامونت",
                "label_fr": "Blamont"
            },
            {
                "value": 35475,
                "label": "Til-Chatel",
                "label_ar": "سمسم شاتيل",
                "label_fr": "Til-Châtel"
            },
            {
                "value": 35476,
                "label": "Le Deschaux",
                "label_ar": "لو ديشو",
                "label_fr": "Le Deschaux"
            },
            {
                "value": 35477,
                "label": "Saulieu",
                "label_ar": "سوليو",
                "label_fr": "Saulieu"
            },
            {
                "value": 35478,
                "label": "Morelmaison",
                "label_ar": "مورلميزون",
                "label_fr": "Morelmaison"
            },
            {
                "value": 35479,
                "label": "Villers-la-Montagne",
                "label_ar": "فيليرز لا مونتاني",
                "label_fr": "Villers-la-Montagne"
            },
            {
                "value": 35480,
                "label": "Haroue",
                "label_ar": "هارو",
                "label_fr": "Haroue"
            },
            {
                "value": 35481,
                "label": "Obermorschwihr",
                "label_ar": "أوبرمورشوير",
                "label_fr": "Obermorschwihr"
            },
            {
                "value": 35482,
                "label": "Balgau",
                "label_ar": "بالغاو",
                "label_fr": "Balgau"
            },
            {
                "value": 35483,
                "label": "Katzenthal",
                "label_ar": "كاتزينثال",
                "label_fr": "Katzenthal"
            },
            {
                "value": 35484,
                "label": "Eichhoffen",
                "label_ar": "إيشهوفن",
                "label_fr": "Eichhoffen"
            },
            {
                "value": 35485,
                "label": "Chelan",
                "label_ar": "شيلان",
                "label_fr": "Chelan"
            },
            {
                "value": 35486,
                "label": "Lourties-Monbrun",
                "label_ar": "لوريتيس مونبرون",
                "label_fr": "Lourties-Monbrun"
            },
            {
                "value": 35487,
                "label": "Tauriac-de-Camares",
                "label_ar": "تورياك دي كاماريس",
                "label_fr": "Tauriac-de-Camares"
            },
            {
                "value": 35488,
                "label": "Bagnac-sur-Cele",
                "label_ar": "باجناك سور سيلي",
                "label_fr": "Bagnac-sur-Célé"
            },
            {
                "value": 35489,
                "label": "Montfaucon",
                "label_ar": "مونتفوكون",
                "label_fr": "Montfaucon"
            },
            {
                "value": 35490,
                "label": "Leyme",
                "label_ar": "Leyme",
                "label_fr": "Leyme"
            },
            {
                "value": 35491,
                "label": "Beautiran",
                "label_ar": "بيوتيران",
                "label_fr": "Beautiran"
            },
            {
                "value": 35492,
                "label": "Sainte-Foy-la-Grande",
                "label_ar": "سانت فوي لا غراندي",
                "label_fr": "Sainte-Foy-la-Grande"
            },
            {
                "value": 35493,
                "label": "Champcevinel",
                "label_ar": "شامبسفينيل",
                "label_fr": "Champcevinel"
            },
            {
                "value": 35494,
                "label": "Coulx",
                "label_ar": "كولكس",
                "label_fr": "Coulx"
            },
            {
                "value": 35495,
                "label": "Civrac-en-Medoc",
                "label_ar": "سيفراك أون ميدوك",
                "label_fr": "Civrac-en-Médoc"
            },
            {
                "value": 35496,
                "label": "Houeilles",
                "label_ar": "هويل",
                "label_fr": "Houeilles"
            },
            {
                "value": 35497,
                "label": "Saint-André-dAllas",
                "label_ar": "سان أندريه دالا",
                "label_fr": "Saint-André-d'Allas"
            },
            {
                "value": 35498,
                "label": "La Villedieu",
                "label_ar": "لا فيليدو",
                "label_fr": "La Villedieu"
            },
            {
                "value": 35499,
                "label": "Sers",
                "label_ar": "سيرز",
                "label_fr": "Sers"
            },
            {
                "value": 35500,
                "label": "Mougon",
                "label_ar": "موجون",
                "label_fr": "Mougon"
            },
            {
                "value": 35501,
                "label": "Bonnes",
                "label_ar": "بونز",
                "label_fr": "Bonnes"
            },
            {
                "value": 35502,
                "label": "Vouzan",
                "label_ar": "فوزان",
                "label_fr": "Vouzan"
            },
            {
                "value": 35503,
                "label": "Saint-Cesaire",
                "label_ar": "سان سيزير",
                "label_fr": "Saint-Césaire"
            },
            {
                "value": 35504,
                "label": "Arrenes",
                "label_ar": "أرينيس",
                "label_fr": "Arrenes"
            },
            {
                "value": 35505,
                "label": "Pamproux",
                "label_ar": "بامبروكس",
                "label_fr": "Pamproux"
            },
            {
                "value": 35506,
                "label": "Migron",
                "label_ar": "ميغرون",
                "label_fr": "Migron"
            },
            {
                "value": 35507,
                "label": "La Pointe",
                "label_ar": "لا بوينت",
                "label_fr": "La Pointe"
            },
            {
                "value": 35508,
                "label": "Bannieres",
                "label_ar": "بانيريس",
                "label_fr": "Bannières"
            },
            {
                "value": 35509,
                "label": "Caucalieres",
                "label_ar": "Caucalieres",
                "label_fr": "Caucalières"
            },
            {
                "value": 35510,
                "label": "Cazes-Mondenard",
                "label_ar": "كازيس موندينارد",
                "label_fr": "Cazes-Mondenard"
            },
            {
                "value": 35511,
                "label": "Saint-Cirgues",
                "label_ar": "سان سيرج",
                "label_fr": "Saint-Cirgues"
            },
            {
                "value": 35512,
                "label": "Lanzac",
                "label_ar": "لانزاك",
                "label_fr": "Lanzac"
            },
            {
                "value": 35513,
                "label": "Saint-Front-d'Alemps",
                "label_ar": "Saint-Front-d'Alemps",
                "label_fr": "Saint-Front-d'Alemps"
            },
            {
                "value": 35514,
                "label": "Monbahus",
                "label_ar": "Monbahus",
                "label_fr": "Monbahus"
            },
            {
                "value": 35515,
                "label": "Bagas",
                "label_ar": "باجاس",
                "label_fr": "Bagas"
            },
            {
                "value": 35516,
                "label": "Saint-Seurin-de-Prats",
                "label_ar": "سان سورين دي براتس",
                "label_fr": "Saint-Seurin-de-Prats"
            },
            {
                "value": 35517,
                "label": "Anlhiac",
                "label_ar": "أنليهياك",
                "label_fr": "Anlhiac"
            },
            {
                "value": 35518,
                "label": "Fors",
                "label_ar": "فورس",
                "label_fr": "Fors"
            },
            {
                "value": 35519,
                "label": "Les Grands-Chezeaux",
                "label_ar": "ليه غراند شيزو",
                "label_fr": "Les Grands-Chezeaux"
            },
            {
                "value": 35520,
                "label": "Burie",
                "label_ar": "بوري",
                "label_fr": "Burie"
            },
            {
                "value": 35521,
                "label": "Paizay-le-Sec",
                "label_ar": "بايزاي لو سيك",
                "label_fr": "Paizay-le-Sec"
            },
            {
                "value": 35522,
                "label": "Reims-la-Brulee",
                "label_ar": "ريمس لا برولي",
                "label_fr": "Reims-la-Brulee"
            },
            {
                "value": 35523,
                "label": "Azet",
                "label_ar": "ازيت",
                "label_fr": "Azet"
            },
            {
                "value": 35524,
                "label": "Hary",
                "label_ar": "هاري",
                "label_fr": "Hary"
            },
            {
                "value": 35525,
                "label": "Salles-d'Angles",
                "label_ar": "ساليس دانجليس",
                "label_fr": "Salles-d'Angles"
            },
            {
                "value": 35526,
                "label": "Montcuq",
                "label_ar": "مونتكوك",
                "label_fr": "Montcuq"
            },
            {
                "value": 35527,
                "label": "Uvernet-Fours",
                "label_ar": "أوفرنيت فور",
                "label_fr": "Uvernet-Fours"
            },
            {
                "value": 35528,
                "label": "Brianconnet",
                "label_ar": "بريانكونيت",
                "label_fr": "Brianconnet"
            },
            {
                "value": 35529,
                "label": "Bellesserre",
                "label_ar": "بيلسيري",
                "label_fr": "Bellesserre"
            },
            {
                "value": 35530,
                "label": "Lumeau",
                "label_ar": "لومو",
                "label_fr": "Lumeau"
            },
            {
                "value": 35531,
                "label": "Lentiol",
                "label_ar": "لينتيول",
                "label_fr": "Lentiol"
            },
            {
                "value": 35532,
                "label": "Chavaignes",
                "label_ar": "شافينيس",
                "label_fr": "Chavaignes"
            },
            {
                "value": 35533,
                "label": "Warcq",
                "label_ar": "ورق",
                "label_fr": "Warcq"
            },
            {
                "value": 35534,
                "label": "Saints-Geosmes",
                "label_ar": "القديسين جيوسمز",
                "label_fr": "Saints-Geosmes"
            },
            {
                "value": 35535,
                "label": "Rang",
                "label_ar": "رن",
                "label_fr": "A sonné"
            },
            {
                "value": 35536,
                "label": "Saint-Jean-de-Soudain",
                "label_ar": "سان جان دي سودان",
                "label_fr": "Saint-Jean-de-Soudain"
            },
            {
                "value": 35537,
                "label": "Villerable",
                "label_ar": "حقير",
                "label_fr": "Villerable"
            },
            {
                "value": 35538,
                "label": "Bierne",
                "label_ar": "بييرن",
                "label_fr": "Bierne"
            },
            {
                "value": 35539,
                "label": "Sablons",
                "label_ar": "سابلونس",
                "label_fr": "Sablons"
            },
            {
                "value": 35540,
                "label": "Ambleteuse",
                "label_ar": "أمبلتيوز",
                "label_fr": "Ambleteuse"
            },
            {
                "value": 35541,
                "label": "Solignac",
                "label_ar": "سولينياك",
                "label_fr": "Solignac"
            },
            {
                "value": 35542,
                "label": "Pringy",
                "label_ar": "بريجي",
                "label_fr": "Pringy"
            },
            {
                "value": 35543,
                "label": "Ribiers",
                "label_ar": "ريبيرس",
                "label_fr": "Ribiers"
            },
            {
                "value": 35544,
                "label": "Vorges",
                "label_ar": "فورجز",
                "label_fr": "Vorges"
            },
            {
                "value": 35545,
                "label": "Bru",
                "label_ar": "برو",
                "label_fr": "Bru"
            },
            {
                "value": 35546,
                "label": "Vourles",
                "label_ar": "فورليس",
                "label_fr": "Vourles"
            },
            {
                "value": 35547,
                "label": "Charmant",
                "label_ar": "تشارمانت",
                "label_fr": "Charmant"
            },
            {
                "value": 35548,
                "label": "Val-de-Fier",
                "label_ar": "فال دي فير",
                "label_fr": "Val-de-Fier"
            },
            {
                "value": 35549,
                "label": "Bouge-Chambalud",
                "label_ar": "بوج شامبالود",
                "label_fr": "Bouge-Chambalud"
            },
            {
                "value": 35550,
                "label": "Auxon-Dessus",
                "label_ar": "اوكسون ديسوس",
                "label_fr": "Auxon-Dessus"
            },
            {
                "value": 35551,
                "label": "Telgruc-sur-Mer",
                "label_ar": "Telgruc-sur-Mer",
                "label_fr": "Telgruc-sur-Mer"
            },
            {
                "value": 35552,
                "label": "Moulins-sur-Yevre",
                "label_ar": "مولين سور يفري",
                "label_fr": "Moulins-sur-Yèvre"
            },
            {
                "value": 35553,
                "label": "Gosné",
                "label_ar": "جوسني",
                "label_fr": "Gosné"
            },
            {
                "value": 35554,
                "label": "Prunieres",
                "label_ar": "المقلم",
                "label_fr": "Prunières"
            },
            {
                "value": 35555,
                "label": "Montsalvy",
                "label_ar": "مونتسالفي",
                "label_fr": "Montsalvy"
            },
            {
                "value": 35556,
                "label": "Sauvat",
                "label_ar": "سوفات",
                "label_fr": "Sauvat"
            },
            {
                "value": 35557,
                "label": "Maurs",
                "label_ar": "مورس",
                "label_fr": "Maurs"
            },
            {
                "value": 35558,
                "label": "Comblanchien",
                "label_ar": "كومبلانتشين",
                "label_fr": "Comblanchien"
            },
            {
                "value": 35559,
                "label": "Septvaux",
                "label_ar": "سبتفو",
                "label_fr": "Septvaux"
            },
            {
                "value": 35560,
                "label": "Etigny",
                "label_ar": "إتيني",
                "label_fr": "Etigny"
            },
            {
                "value": 35561,
                "label": "Lihons",
                "label_ar": "Lihons",
                "label_fr": "Lihons"
            },
            {
                "value": 35562,
                "label": "Menil-sur-Belvitte",
                "label_ar": "مينيل سور بلفيت",
                "label_fr": "Ménil-sur-Belvitte"
            },
            {
                "value": 35563,
                "label": "Uriage-les-Bains",
                "label_ar": "Uriage-les-Bains",
                "label_fr": "Uriage-les-Bains"
            },
            {
                "value": 35564,
                "label": "Mouledous",
                "label_ar": "المولدوس",
                "label_fr": "Mouledous"
            },
            {
                "value": 35565,
                "label": "Pouilly-sous-Charlieu",
                "label_ar": "بويلي سو تشارليو",
                "label_fr": "Pouilly-sous-Charlieu"
            },
            {
                "value": 35566,
                "label": "Charrecey",
                "label_ar": "تشاريسي",
                "label_fr": "Charrecey"
            },
            {
                "value": 35567,
                "label": "Illiat",
                "label_ar": "إليات",
                "label_fr": "Illiat"
            },
            {
                "value": 35568,
                "label": "Ners",
                "label_ar": "نيرس",
                "label_fr": "Ners"
            },
            {
                "value": 35569,
                "label": "Courtenay",
                "label_ar": "كورتيناي",
                "label_fr": "Courtenay"
            },
            {
                "value": 35570,
                "label": "Bondigoux",
                "label_ar": "بونديجوكس",
                "label_fr": "Bondigoux"
            },
            {
                "value": 35571,
                "label": "Auriac-sur-Vendinelle",
                "label_ar": "Auriac-sur-Vendinelle",
                "label_fr": "Auriac-sur-Vendinelle"
            },
            {
                "value": 35572,
                "label": "Villards",
                "label_ar": "فيلاردز",
                "label_fr": "Villards"
            },
            {
                "value": 35573,
                "label": "Cormeilles-en-Vexin",
                "label_ar": "كورمييل أون فيكسين",
                "label_fr": "Cormeilles-en-Vexin"
            },
            {
                "value": 35574,
                "label": "Sanguinet",
                "label_ar": "سانجينيت",
                "label_fr": "Sanguinet"
            },
            {
                "value": 35575,
                "label": "Hauterive",
                "label_ar": "Hauterive",
                "label_fr": "Hauterive"
            },
            {
                "value": 35576,
                "label": "Chevillon",
                "label_ar": "شيفيلون",
                "label_fr": "Chevillon"
            },
            {
                "value": 35577,
                "label": "Orbais-l'Abbaye",
                "label_ar": "Orbais-l'Abbaye",
                "label_fr": "Orbais-l'Abbaye"
            },
            {
                "value": 35578,
                "label": "Lassy",
                "label_ar": "لاسي",
                "label_fr": "Lassy"
            },
            {
                "value": 35579,
                "label": "Etouvy",
                "label_ar": "إتوفي",
                "label_fr": "Etouvy"
            },
            {
                "value": 35580,
                "label": "Essia",
                "label_ar": "إسيا",
                "label_fr": "Essia"
            },
            {
                "value": 35581,
                "label": "Bornay",
                "label_ar": "بورناي",
                "label_fr": "Bornay"
            },
            {
                "value": 35582,
                "label": "Bief-du-Fourg",
                "label_ar": "بيف دو فورج",
                "label_fr": "Bief-du-Fourg"
            },
            {
                "value": 35583,
                "label": "Les Essards-Taignevaux",
                "label_ar": "ليه إيساردس تاينيفو",
                "label_fr": "Les Essards-Taignevaux"
            },
            {
                "value": 35584,
                "label": "Brimeux",
                "label_ar": "بريمو",
                "label_fr": "Brimeux"
            },
            {
                "value": 35585,
                "label": "Gouy-Saint-Andre",
                "label_ar": "جوي سانت أندريه",
                "label_fr": "Gouy-Saint-André"
            },
            {
                "value": 35586,
                "label": "Galametz",
                "label_ar": "جالاميتز",
                "label_fr": "Galametz"
            },
            {
                "value": 35587,
                "label": "Marenla",
                "label_ar": "مارينلا",
                "label_fr": "Marenla"
            },
            {
                "value": 35588,
                "label": "Boismont",
                "label_ar": "Boismont",
                "label_fr": "Boismont"
            },
            {
                "value": 35589,
                "label": "Gonfreville-Caillot",
                "label_ar": "جونفريفيل كايو",
                "label_fr": "Gonfreville-Caillot"
            },
            {
                "value": 35590,
                "label": "Plouasne",
                "label_ar": "بلواسن",
                "label_fr": "Plouasne"
            },
            {
                "value": 35591,
                "label": "Saint-Launeuc",
                "label_ar": "سان لونيوك",
                "label_fr": "Saint-Launeuc"
            },
            {
                "value": 35592,
                "label": "Fenouillet",
                "label_ar": "فينويله",
                "label_fr": "Fenouillet"
            },
            {
                "value": 35593,
                "label": "Ur",
                "label_ar": "اور",
                "label_fr": "Ur"
            },
            {
                "value": 35594,
                "label": "Marquixanes",
                "label_ar": "ماركيكسانيس",
                "label_fr": "Marquixanes"
            },
            {
                "value": 35595,
                "label": "Soize",
                "label_ar": "سويزي",
                "label_fr": "Soize"
            },
            {
                "value": 35596,
                "label": "Miserey",
                "label_ar": "بائس",
                "label_fr": "Miserey"
            },
            {
                "value": 35597,
                "label": "Hebecourt",
                "label_ar": "هيبيكورت",
                "label_fr": "Hebecourt"
            },
            {
                "value": 35598,
                "label": "Antigny",
                "label_ar": "أنتيجني",
                "label_fr": "Antigny"
            },
            {
                "value": 35599,
                "label": "Franvillers",
                "label_ar": "فرانفيلرز",
                "label_fr": "Franvillers"
            },
            {
                "value": 35600,
                "label": "Maison-Ponthieu",
                "label_ar": "ميزون بونثيو",
                "label_fr": "Maison-Ponthieu"
            },
            {
                "value": 35601,
                "label": "Sourdon",
                "label_ar": "سوردون",
                "label_fr": "Sourdon"
            },
            {
                "value": 35602,
                "label": "Saisseval",
                "label_ar": "سيسيفال",
                "label_fr": "Saisseval"
            },
            {
                "value": 35603,
                "label": "Neuilly-l'Hopital",
                "label_ar": "Neuilly-l'Hopital",
                "label_fr": "Neuilly-l'Hôpital"
            },
            {
                "value": 35604,
                "label": "Louvrechy",
                "label_ar": "لوفريشي",
                "label_fr": "Louvrechy"
            },
            {
                "value": 35605,
                "label": "Blaignan",
                "label_ar": "بلينان",
                "label_fr": "Blaignan"
            },
            {
                "value": 35606,
                "label": "Sery",
                "label_ar": "سيري",
                "label_fr": "Sery"
            },
            {
                "value": 35607,
                "label": "Sery-les-Mezieres",
                "label_ar": "سيري ليه ميزيريس",
                "label_fr": "Sery-les-Mézières"
            },
            {
                "value": 35608,
                "label": "Ouchamps",
                "label_ar": "أوشامب",
                "label_fr": "Ouchamps"
            },
            {
                "value": 35609,
                "label": "Goutrens",
                "label_ar": "جوترينز",
                "label_fr": "Goutrens"
            },
            {
                "value": 35610,
                "label": "Viviez",
                "label_ar": "فيفيز",
                "label_fr": "Viviez"
            },
            {
                "value": 35611,
                "label": "Saint-Thibault",
                "label_ar": "سان تيبو",
                "label_fr": "Saint-Thibault"
            },
            {
                "value": 35612,
                "label": "Boury-en-Vexin",
                "label_ar": "بوري أون فيكسين",
                "label_fr": "Boury-en-Vexin"
            },
            {
                "value": 35613,
                "label": "Mignieres",
                "label_ar": "Mignieres",
                "label_fr": "Mignières"
            },
            {
                "value": 35614,
                "label": "Roquefort-de-Sault",
                "label_ar": "روكفور دي سولت",
                "label_fr": "Roquefort-de-Sault"
            },
            {
                "value": 35615,
                "label": "Saint-Martin-Lalande",
                "label_ar": "سان مارتن لالاند",
                "label_fr": "Saint-Martin-Lalande"
            },
            {
                "value": 35616,
                "label": "Feuilla",
                "label_ar": "فويلا",
                "label_fr": "Feuilla"
            },
            {
                "value": 35617,
                "label": "Urcay",
                "label_ar": "Urcay",
                "label_fr": "Urcay"
            },
            {
                "value": 35618,
                "label": "Buxieres-les-Mines",
                "label_ar": "Buxieres-les-Mines",
                "label_fr": "Buxières-les-Mines"
            },
            {
                "value": 35619,
                "label": "Blomard",
                "label_ar": "بلومارد",
                "label_fr": "Blomard"
            },
            {
                "value": 35620,
                "label": "Saint-Judoce",
                "label_ar": "سان جودوس",
                "label_fr": "Saint-Judoce"
            },
            {
                "value": 35621,
                "label": "Remungol",
                "label_ar": "ريمنجول",
                "label_fr": "Remungol"
            },
            {
                "value": 35622,
                "label": "Malansac",
                "label_ar": "مالانساك",
                "label_fr": "Malansac"
            },
            {
                "value": 35623,
                "label": "Velines",
                "label_ar": "الفلين",
                "label_fr": "Velines"
            },
            {
                "value": 35624,
                "label": "Saint-Felix-de-Villadeix",
                "label_ar": "Saint-Felix-de-Villadeix",
                "label_fr": "Saint-Félix-de-Villadeix"
            },
            {
                "value": 35625,
                "label": "Brossac",
                "label_ar": "بروساك",
                "label_fr": "Brossac"
            },
            {
                "value": 35626,
                "label": "Villeneuve-Lecussan",
                "label_ar": "فيلنوف ليكوسان",
                "label_fr": "Villeneuve-Lecussan"
            },
            {
                "value": 35627,
                "label": "Saint-Beat",
                "label_ar": "سانت بيت",
                "label_fr": "Saint-Beat"
            },
            {
                "value": 35628,
                "label": "Murol",
                "label_ar": "مورول",
                "label_fr": "Murol"
            },
            {
                "value": 35629,
                "label": "Merlines",
                "label_ar": "ميرلاينز",
                "label_fr": "Merlines"
            },
            {
                "value": 35630,
                "label": "Colleret",
                "label_ar": "كوليريت",
                "label_fr": "Colleret"
            },
            {
                "value": 35631,
                "label": "Estaing",
                "label_ar": "إستينج",
                "label_fr": "Estaing"
            },
            {
                "value": 35632,
                "label": "Livinhac-le-Haut",
                "label_ar": "ليفينهاك لو أوت",
                "label_fr": "Livinhac-le-Haut"
            },
            {
                "value": 35633,
                "label": "Piseux",
                "label_ar": "بيسوكس",
                "label_fr": "Piseux"
            },
            {
                "value": 35634,
                "label": "Prudhomat",
                "label_ar": "برودومات",
                "label_fr": "Prudhomat"
            },
            {
                "value": 35635,
                "label": "Espedaillac",
                "label_ar": "اسبيداك",
                "label_fr": "Espedaillac"
            },
            {
                "value": 35636,
                "label": "Prayssac",
                "label_ar": "بريساك",
                "label_fr": "Prayssac"
            },
            {
                "value": 35637,
                "label": "Bourgneuf",
                "label_ar": "بورجنوف",
                "label_fr": "Bourgneuf"
            },
            {
                "value": 35638,
                "label": "Cours-les-Bains",
                "label_ar": "كورس ليه با",
                "label_fr": "Cours-les-Bains"
            },
            {
                "value": 35639,
                "label": "Campeaux",
                "label_ar": "كامبو",
                "label_fr": "Campeaux"
            },
            {
                "value": 35640,
                "label": "Floing",
                "label_ar": "عائم",
                "label_fr": "Flottant"
            },
            {
                "value": 35641,
                "label": "Matour",
                "label_ar": "ماتور",
                "label_fr": "Matour"
            },
            {
                "value": 35642,
                "label": "Naux",
                "label_ar": "ناوكس",
                "label_fr": "Naux"
            },
            {
                "value": 35643,
                "label": "Calavante",
                "label_ar": "كالافانتي",
                "label_fr": "Calavante"
            },
            {
                "value": 35644,
                "label": "Esquieze-Sere",
                "label_ar": "Esquieze-Sere",
                "label_fr": "Esquieze-Sere"
            },
            {
                "value": 35645,
                "label": "Breux-sur-Avre",
                "label_ar": "بروكس سور افر",
                "label_fr": "Breux-sur-Avre"
            },
            {
                "value": 35646,
                "label": "Solignac-sous-Roche",
                "label_ar": "Solignac-sous-Roche",
                "label_fr": "Solignac-sous-Roche"
            },
            {
                "value": 35647,
                "label": "La Chapelle-d'Alagnon",
                "label_ar": "لا شابيل دالغنون",
                "label_fr": "La Chapelle-d'Alagnon"
            },
            {
                "value": 35648,
                "label": "Lavoute-Chilhac",
                "label_ar": "لافوت تشيلاك",
                "label_fr": "Lavoute-Chilhac"
            },
            {
                "value": 35649,
                "label": "Riotord",
                "label_ar": "مثيري الشغب",
                "label_fr": "Riotord"
            },
            {
                "value": 35650,
                "label": "Le Chatelet-sur-Retourne",
                "label_ar": "لو شاتليت سور ريتورن",
                "label_fr": "Le Châtelet-sur-Retourne"
            },
            {
                "value": 35651,
                "label": "Drouville",
                "label_ar": "دروفيل",
                "label_fr": "Drouville"
            },
            {
                "value": 35652,
                "label": "Joch",
                "label_ar": "جوتش",
                "label_fr": "Joch"
            },
            {
                "value": 35653,
                "label": "Louzy",
                "label_ar": "لوزي",
                "label_fr": "Louzy"
            },
            {
                "value": 35654,
                "label": "Frambouhans",
                "label_ar": "فرامبوهانس",
                "label_fr": "Frambouhans"
            },
            {
                "value": 35655,
                "label": "Bazens",
                "label_ar": "بازنز",
                "label_fr": "Bazens"
            },
            {
                "value": 35656,
                "label": "Damazan",
                "label_ar": "دامازان",
                "label_fr": "Damazan"
            },
            {
                "value": 35657,
                "label": "Bricon",
                "label_ar": "بريكون",
                "label_fr": "Bricon"
            },
            {
                "value": 35658,
                "label": "Fayl-Billot",
                "label_ar": "فايل بيلوت",
                "label_fr": "Fayl-Billot"
            },
            {
                "value": 35659,
                "label": "Aast",
                "label_ar": "أست",
                "label_fr": "Aast"
            },
            {
                "value": 35660,
                "label": "Tajan",
                "label_ar": "تاجان",
                "label_fr": "Tajan"
            },
            {
                "value": 35661,
                "label": "Wolschheim",
                "label_ar": "Wolschheim",
                "label_fr": "Wolschheim"
            },
            {
                "value": 35662,
                "label": "Saint-Blaise-la-Roche",
                "label_ar": "سانت بليز لاروش",
                "label_fr": "Saint-Blaise-la-Roche"
            },
            {
                "value": 35663,
                "label": "Raizeux",
                "label_ar": "رايزوكس",
                "label_fr": "Raizeux"
            },
            {
                "value": 35664,
                "label": "Yzeures-sur-Creuse",
                "label_ar": "Yzeures-sur-Creuse",
                "label_fr": "Yzeures-sur-Creuse"
            },
            {
                "value": 35665,
                "label": "Limeray",
                "label_ar": "Limeray",
                "label_fr": "Limeray"
            },
            {
                "value": 35666,
                "label": "Montaudin",
                "label_ar": "مونتودين",
                "label_fr": "Montaudin"
            },
            {
                "value": 35667,
                "label": "Villaines-la-Juhel",
                "label_ar": "Villaines-la-Juhel",
                "label_fr": "Villaines-la-Juhel"
            },
            {
                "value": 35668,
                "label": "Chemere-le-Roi",
                "label_ar": "كيمير لو روي",
                "label_fr": "Chemère-le-Roi"
            },
            {
                "value": 35669,
                "label": "Saint-Pierre-des-Echaubrognes",
                "label_ar": "سان بيير دي إيكوبرووني",
                "label_fr": "Saint-Pierre-des-Echaubrognes"
            },
            {
                "value": 35670,
                "label": "Montbrun-Bocage",
                "label_ar": "مونبرون بوكاج",
                "label_fr": "Montbrun-Bocage"
            },
            {
                "value": 35671,
                "label": "Moncoutant",
                "label_ar": "مونكوتانت",
                "label_fr": "Moncoutant"
            },
            {
                "value": 35672,
                "label": "Thenezay",
                "label_ar": "ثينيزاي",
                "label_fr": "Thenezay"
            },
            {
                "value": 35673,
                "label": "Bazouges-sous-Hede",
                "label_ar": "بازوج صوص هيدي",
                "label_fr": "Bazouges-sous-Hede"
            },
            {
                "value": 35674,
                "label": "Nesles-la-Montagne",
                "label_ar": "نيسليس لا مونتاني",
                "label_fr": "Nesles-la-Montagne"
            },
            {
                "value": 35675,
                "label": "Saulnieres",
                "label_ar": "Saulnieres",
                "label_fr": "Saulnières"
            },
            {
                "value": 35676,
                "label": "Bailleul-Sir-Berthoult",
                "label_ar": "بايليول سير بيرتهولت",
                "label_fr": "Bailleul-Sir-Berthoult"
            },
            {
                "value": 35677,
                "label": "Etaing",
                "label_ar": "ايتينج",
                "label_fr": "Etaing"
            },
            {
                "value": 35678,
                "label": "Luxe",
                "label_ar": "لوكس",
                "label_fr": "Luxe"
            },
            {
                "value": 35679,
                "label": "Champagnat",
                "label_ar": "الشمبانيا",
                "label_fr": "Champagnat"
            },
            {
                "value": 35680,
                "label": "Bray-Saint-Christophe",
                "label_ar": "براي سانت كريستوف",
                "label_fr": "Bray-Saint-Christophe"
            },
            {
                "value": 35681,
                "label": "Aumont-en-Halatte",
                "label_ar": "أومون أون هالات",
                "label_fr": "Aumont-en-Halatte"
            },
            {
                "value": 35682,
                "label": "Meharicourt",
                "label_ar": "مهاريكورت",
                "label_fr": "Méharicourt"
            },
            {
                "value": 35683,
                "label": "Wavignies",
                "label_ar": "الأمواج",
                "label_fr": "Wavignies"
            },
            {
                "value": 35684,
                "label": "Le Vieux Bourg",
                "label_ar": "لو فيو بورغ",
                "label_fr": "Le Vieux Bourg"
            },
            {
                "value": 35685,
                "label": "Chaussoy-Epagny",
                "label_ar": "Chaussoy-Epagny",
                "label_fr": "Chaussoy-Epagny"
            },
            {
                "value": 35686,
                "label": "Framerville-Rainecourt",
                "label_ar": "Framerville-Rainecourt",
                "label_fr": "Framerville-Rainecourt"
            },
            {
                "value": 35687,
                "label": "Lihus",
                "label_ar": "ليهوس",
                "label_fr": "Lihus"
            },
            {
                "value": 35688,
                "label": "Curchy",
                "label_ar": "كورشي",
                "label_fr": "Curchy"
            },
            {
                "value": 35689,
                "label": "Mesnil-Saint-Nicaise",
                "label_ar": "Mesnil-Saint-Nicaise",
                "label_fr": "Mesnil-Saint-Nicaise"
            },
            {
                "value": 35690,
                "label": "Villers-Saint-Genest",
                "label_ar": "فيليرز سان جينست",
                "label_fr": "Villers-Saint-Genest"
            },
            {
                "value": 35691,
                "label": "Remerangles",
                "label_ar": "يعيد تشكيل",
                "label_fr": "Remérangles"
            },
            {
                "value": 35692,
                "label": "Precy-sous-Thil",
                "label_ar": "بريسي سوس ثيل",
                "label_fr": "Précy-sous-Thil"
            },
            {
                "value": 35693,
                "label": "Morgny",
                "label_ar": "مورني",
                "label_fr": "Morgny"
            },
            {
                "value": 35694,
                "label": "Cavan",
                "label_ar": "كافان",
                "label_fr": "Cavan"
            },
            {
                "value": 35695,
                "label": "Saint-Clet",
                "label_ar": "سانت كليت",
                "label_fr": "Saint-Clet"
            },
            {
                "value": 35696,
                "label": "Plenee-Jugon",
                "label_ar": "بليني جوجون",
                "label_fr": "Plénée-Jugon"
            },
            {
                "value": 35697,
                "label": "Germigny-des-Prés",
                "label_ar": "Germigny-des-Prés",
                "label_fr": "Germigny-des-Prés"
            },
            {
                "value": 35698,
                "label": "Aigues-Vives",
                "label_ar": "إيغيس فيفز",
                "label_fr": "Aigues-Vives"
            },
            {
                "value": 35699,
                "label": "Saint-Felix-de-Rieutord",
                "label_ar": "سان فيليكس دي ريوتورد",
                "label_fr": "Saint-Félix-de-Rieutord"
            },
            {
                "value": 35700,
                "label": "Mercenac",
                "label_ar": "Mercenac",
                "label_fr": "Mercenac"
            },
            {
                "value": 35701,
                "label": "Tourtrol",
                "label_ar": "تورترول",
                "label_fr": "Tourtrol"
            },
            {
                "value": 35702,
                "label": "Tarascon-sur-Ariege",
                "label_ar": "تاراسكون سور أريج",
                "label_fr": "Tarascon-sur-Ariège"
            },
            {
                "value": 35703,
                "label": "Artigat",
                "label_ar": "أرتيغات",
                "label_fr": "Artigat"
            },
            {
                "value": 35704,
                "label": "Bouan",
                "label_ar": "بوان",
                "label_fr": "Bouan"
            },
            {
                "value": 35705,
                "label": "Bulhon",
                "label_ar": "بولهون",
                "label_fr": "Bulhon"
            },
            {
                "value": 35706,
                "label": "Kappelen",
                "label_ar": "كابلين",
                "label_fr": "Kappelen"
            },
            {
                "value": 35707,
                "label": "Wintzfelden",
                "label_ar": "Wintzfelden",
                "label_fr": "Wintzfelden"
            },
            {
                "value": 35708,
                "label": "Oberentzen",
                "label_ar": "اوبرنتزن",
                "label_fr": "Oberentzen"
            },
            {
                "value": 35709,
                "label": "Spechbach-le-Bas",
                "label_ar": "سبيتشباخ لو باس",
                "label_fr": "Spechbach-le-Bas"
            },
            {
                "value": 35710,
                "label": "Husseren-les-Chateaux",
                "label_ar": "هوسرين لي شاتو",
                "label_fr": "Husseren-les-Châteaux"
            },
            {
                "value": 35711,
                "label": "Friesen",
                "label_ar": "فريزين",
                "label_fr": "Friesen"
            },
            {
                "value": 35712,
                "label": "Lutter",
                "label_ar": "لوتر",
                "label_fr": "Lutter"
            },
            {
                "value": 35713,
                "label": "Saubrigues",
                "label_ar": "Saubrigues",
                "label_fr": "Saubrigues"
            },
            {
                "value": 35714,
                "label": "Cruzy",
                "label_ar": "كروزي",
                "label_fr": "Cruzy"
            },
            {
                "value": 35715,
                "label": "Corbieres",
                "label_ar": "كوربيريس",
                "label_fr": "Corbières"
            },
            {
                "value": 35716,
                "label": "Premian",
                "label_ar": "بريميان",
                "label_fr": "Premian"
            },
            {
                "value": 35717,
                "label": "Montpeyroux",
                "label_ar": "مونبيروكس",
                "label_fr": "Montpeyroux"
            },
            {
                "value": 35718,
                "label": "Le Pouget",
                "label_ar": "لو بوجيت",
                "label_fr": "Le Pouget"
            },
            {
                "value": 35719,
                "label": "Bouvante",
                "label_ar": "بوفانت",
                "label_fr": "Bouvante"
            },
            {
                "value": 35720,
                "label": "Saint-Lambert-sur-Dive",
                "label_ar": "سان لامبرت سور دايف",
                "label_fr": "Saint-Lambert-sur-Dive"
            },
            {
                "value": 35721,
                "label": "Bonnieux",
                "label_ar": "بونيوكس",
                "label_fr": "Bonnieux"
            },
            {
                "value": 35722,
                "label": "Prechac",
                "label_ar": "بريشاك",
                "label_fr": "Préchac"
            },
            {
                "value": 35723,
                "label": "Dalmas",
                "label_ar": "دالماس",
                "label_fr": "Dalmas"
            },
            {
                "value": 35724,
                "label": "Cirey",
                "label_ar": "سيري",
                "label_fr": "Cirey"
            },
            {
                "value": 35725,
                "label": "Seveux",
                "label_ar": "سيفوكس",
                "label_fr": "Seveux"
            },
            {
                "value": 35726,
                "label": "Brue-Auriac",
                "label_ar": "برو-أورياك",
                "label_fr": "Brue-Auriac"
            },
            {
                "value": 35727,
                "label": "Morogues",
                "label_ar": "مروج",
                "label_fr": "Morogues"
            },
            {
                "value": 35728,
                "label": "Antignac",
                "label_ar": "أنتيجناك",
                "label_fr": "Antignac"
            },
            {
                "value": 35729,
                "label": "Champagne-Mouton",
                "label_ar": "شامبين موتون",
                "label_fr": "Champagne-Mouton"
            },
            {
                "value": 35730,
                "label": "Saint-Vincent-sur-Graon",
                "label_ar": "سانت فنسنت سور غراون",
                "label_fr": "Saint-Vincent-sur-Graon"
            },
            {
                "value": 35731,
                "label": "Anguerny",
                "label_ar": "الغضب",
                "label_fr": "Anguerny"
            },
            {
                "value": 35732,
                "label": "Montrevel-en-Bresse",
                "label_ar": "مونتريفيل إن بريس",
                "label_fr": "Montrevel-en-Bresse"
            },
            {
                "value": 35733,
                "label": "Villers-Pol",
                "label_ar": "فيليرز بول",
                "label_fr": "Villers-Pol"
            },
            {
                "value": 35734,
                "label": "Lurais",
                "label_ar": "لورايس",
                "label_fr": "Lurais"
            },
            {
                "value": 35735,
                "label": "Fontaine-les-Gres",
                "label_ar": "فونتين ليه جريس",
                "label_fr": "Fontaine-les-Grès"
            },
            {
                "value": 35736,
                "label": "Lechelle",
                "label_ar": "ليتشيل",
                "label_fr": "Lechelle"
            },
            {
                "value": 35737,
                "label": "Saint-Cyr-du-Gault",
                "label_ar": "سان سير دو غولت",
                "label_fr": "Saint-Cyr-du-Gault"
            },
            {
                "value": 35738,
                "label": "Vivieres",
                "label_ar": "فيفيريس",
                "label_fr": "Vivières"
            },
            {
                "value": 35739,
                "label": "Jeufosse",
                "label_ar": "جوفوس",
                "label_fr": "Jeufosse"
            },
            {
                "value": 35740,
                "label": "Vanville",
                "label_ar": "فانفيل",
                "label_fr": "Vanville"
            },
            {
                "value": 35741,
                "label": "Saint-Martin-en-Campagne",
                "label_ar": "سان مارتن أون كامباني",
                "label_fr": "Saint-Martin-en-Campagne"
            },
            {
                "value": 35742,
                "label": "Lieurey",
                "label_ar": "ليوري",
                "label_fr": "Lieurey"
            },
            {
                "value": 35743,
                "label": "Fultot",
                "label_ar": "فولوت",
                "label_fr": "Fultot"
            },
            {
                "value": 35744,
                "label": "Chevire-le-Rouge",
                "label_ar": "شيفير لو روج",
                "label_fr": "Chevire-le-Rouge"
            },
            {
                "value": 35745,
                "label": "Mouthier-en-Bresse",
                "label_ar": "Mouthier-en-Bresse",
                "label_fr": "Mouthier-en-Bresse"
            },
            {
                "value": 35746,
                "label": "Beauchamps-sur-Huillard",
                "label_ar": "بوشامب سور هويار",
                "label_fr": "Beauchamps-sur-Huillard"
            },
            {
                "value": 35747,
                "label": "Saint-Brevin-l'Ocean",
                "label_ar": "سان بريفين لوسان",
                "label_fr": "Saint-Brévin-l'Océan"
            },
            {
                "value": 35748,
                "label": "Kernascleden",
                "label_ar": "كيرناسكلدن",
                "label_fr": "Kernascleden"
            },
            {
                "value": 35749,
                "label": "Bains-les-Bains",
                "label_ar": "Bains-les-Bains",
                "label_fr": "Bains-les-Bains"
            },
            {
                "value": 35750,
                "label": "Coulonges-Thouarsais",
                "label_ar": "Coulonges-Thouarsais",
                "label_fr": "Coulonges-Thouarsais"
            },
            {
                "value": 35751,
                "label": "Taller",
                "label_ar": "أطول",
                "label_fr": "Plus grand"
            },
            {
                "value": 35752,
                "label": "Vigneulles-les-Hattonchatel",
                "label_ar": "Vigneulles-les-Hattonchatel",
                "label_fr": "Vigneulles-les-Hattonchatel"
            },
            {
                "value": 35753,
                "label": "Jametz",
                "label_ar": "جاميتز",
                "label_fr": "Jametz"
            },
            {
                "value": 35754,
                "label": "Chenailler-Mascheix",
                "label_ar": "Chenailler-Mascheix",
                "label_fr": "Chenailler-Mascheix"
            },
            {
                "value": 35755,
                "label": "Chatelus-le-Marcheix",
                "label_ar": "Chatelus-le-Marcheix",
                "label_fr": "Châtelus-le-Marcheix"
            },
            {
                "value": 35756,
                "label": "Marval",
                "label_ar": "مارفال",
                "label_fr": "Marval"
            },
            {
                "value": 35757,
                "label": "Chambon-sur-Voueize",
                "label_ar": "شامبون سور فويز",
                "label_fr": "Chambon-sur-Voueize"
            },
            {
                "value": 35758,
                "label": "Cresse",
                "label_ar": "كريس",
                "label_fr": "Cresse"
            },
            {
                "value": 35759,
                "label": "Tramoyes",
                "label_ar": "ترامويز",
                "label_fr": "Tramoyes"
            },
            {
                "value": 35760,
                "label": "Saint-Lager",
                "label_ar": "سان لاجر",
                "label_fr": "Saint-Lager"
            },
            {
                "value": 35761,
                "label": "Doazit",
                "label_ar": "دوزيت",
                "label_fr": "Doazit"
            },
            {
                "value": 35762,
                "label": "Hulluch",
                "label_ar": "هالوتش",
                "label_fr": "Hulluch"
            },
            {
                "value": 35763,
                "label": "Colembert",
                "label_ar": "كولمبيرت",
                "label_fr": "Colembert"
            },
            {
                "value": 35764,
                "label": "Chanveaux",
                "label_ar": "تشانفو",
                "label_fr": "Chanveaux"
            },
            {
                "value": 35765,
                "label": "Les Ecrennes",
                "label_ar": "ليه Ecrennes",
                "label_fr": "Les Ecrennes"
            },
            {
                "value": 35766,
                "label": "Sore",
                "label_ar": "تقرح",
                "label_fr": "Endolori"
            },
            {
                "value": 35767,
                "label": "Lescheraines",
                "label_ar": "ليشرين",
                "label_fr": "Lescheraines"
            },
            {
                "value": 35768,
                "label": "Collobrieres",
                "label_ar": "كولوبيريز",
                "label_fr": "Collobrières"
            },
            {
                "value": 35769,
                "label": "Fletre",
                "label_ar": "فليتر",
                "label_fr": "Fletre"
            },
            {
                "value": 35770,
                "label": "Corcy",
                "label_ar": "كورسي",
                "label_fr": "Corcy"
            },
            {
                "value": 35771,
                "label": "Villerest",
                "label_ar": "فيليريست",
                "label_fr": "Villerest"
            },
            {
                "value": 35772,
                "label": "Blauzac",
                "label_ar": "بلوزاك",
                "label_fr": "Blauzac"
            },
            {
                "value": 35773,
                "label": "Vauvillers",
                "label_ar": "فوفيلرز",
                "label_fr": "Vauvillers"
            },
            {
                "value": 35774,
                "label": "Lusigny",
                "label_ar": "لوزيني",
                "label_fr": "Lusigny"
            },
            {
                "value": 35775,
                "label": "Lamagdelaine",
                "label_ar": "لاماجديلين",
                "label_fr": "Lamagdelaine"
            },
            {
                "value": 35776,
                "label": "Saint-Cere",
                "label_ar": "سان سير",
                "label_fr": "Saint-Céré"
            },
            {
                "value": 35777,
                "label": "Cajarc",
                "label_ar": "كاجارك",
                "label_fr": "Cajarc"
            },
            {
                "value": 35778,
                "label": "Marquefave",
                "label_ar": "ماركيف",
                "label_fr": "Marquefave"
            },
            {
                "value": 35779,
                "label": "Saint-Nabord",
                "label_ar": "سان نابورد",
                "label_fr": "Saint-Nabord"
            },
            {
                "value": 35780,
                "label": "Roche",
                "label_ar": "روش",
                "label_fr": "Roche"
            },
            {
                "value": 35781,
                "label": "Mattaincourt",
                "label_ar": "ماتينكورت",
                "label_fr": "Mattaincourt"
            },
            {
                "value": 35782,
                "label": "Reuilly",
                "label_ar": "Reuilly",
                "label_fr": "Reuilly"
            },
            {
                "value": 35783,
                "label": "Capvern",
                "label_ar": "Capvern",
                "label_fr": "Capvern"
            },
            {
                "value": 35784,
                "label": "Charentay",
                "label_ar": "تشارينتاي",
                "label_fr": "Charentay"
            },
            {
                "value": 35785,
                "label": "Serrieres-de-Briord",
                "label_ar": "Serrieres-de-Briord",
                "label_fr": "Serrières-de-Briord"
            },
            {
                "value": 35786,
                "label": "La Gree-Saint-Laurent",
                "label_ar": "لا جري سان لوران",
                "label_fr": "La Gree-Saint-Laurent"
            },
            {
                "value": 35787,
                "label": "Clarafond",
                "label_ar": "كلارافوند",
                "label_fr": "Clarafond"
            },
            {
                "value": 35788,
                "label": "Minzier",
                "label_ar": "مينزير",
                "label_fr": "Minzier"
            },
            {
                "value": 35789,
                "label": "Marcellaz-Albanais",
                "label_ar": "Marcellaz-Albanais",
                "label_fr": "Marcellaz-Albanais"
            },
            {
                "value": 35790,
                "label": "Bailly-Carrois",
                "label_ar": "بايلي كاروا",
                "label_fr": "Bailly-Carrois"
            },
            {
                "value": 35791,
                "label": "Verrey-sous-Salmaise",
                "label_ar": "فيري سوس سلميزه",
                "label_fr": "Verrey-sous-Salmaise"
            },
            {
                "value": 35792,
                "label": "Gerland",
                "label_ar": "جيرلاند",
                "label_fr": "Gerland"
            },
            {
                "value": 35793,
                "label": "Fontaine-Milon",
                "label_ar": "فونتين ميلون",
                "label_fr": "Fontaine-Milon"
            },
            {
                "value": 35794,
                "label": "Moncheaux-les-Frevent",
                "label_ar": "مونشو ليه فريفينت",
                "label_fr": "Moncheaux-les-Frevent"
            },
            {
                "value": 35795,
                "label": "Les Hogues",
                "label_ar": "ليه هوغ",
                "label_fr": "Les Hogues"
            },
            {
                "value": 35796,
                "label": "Vraiville",
                "label_ar": "فرايفيل",
                "label_fr": "Vraiville"
            },
            {
                "value": 35797,
                "label": "Serquigny",
                "label_ar": "سيركيني",
                "label_fr": "Serquigny"
            },
            {
                "value": 35798,
                "label": "Neffes",
                "label_ar": "نيفيس",
                "label_fr": "Neffes"
            },
            {
                "value": 35799,
                "label": "Chezy",
                "label_ar": "تشيزي",
                "label_fr": "Chezy"
            },
            {
                "value": 35800,
                "label": "Thin-le-Moutier",
                "label_ar": "ثن لو موتير",
                "label_fr": "Thin-le-Moutier"
            },
            {
                "value": 35801,
                "label": "Mogues",
                "label_ar": "أباطرة",
                "label_fr": "Mogues"
            },
            {
                "value": 35802,
                "label": "Allogny",
                "label_ar": "ألوجني",
                "label_fr": "Allogny"
            },
            {
                "value": 35803,
                "label": "Prunay-sur-Essonne",
                "label_ar": "Prunay-sur-Essonne",
                "label_fr": "Prunay-sur-Essonne"
            },
            {
                "value": 35804,
                "label": "Benifontaine",
                "label_ar": "بينيفونتين",
                "label_fr": "Benifontaine"
            },
            {
                "value": 35805,
                "label": "Pressagny-le-Val",
                "label_ar": "بريساني لو فال",
                "label_fr": "Pressagny-le-Val"
            },
            {
                "value": 35806,
                "label": "Saint-Martin-la-Campagne",
                "label_ar": "سان مارتن لا كامباني",
                "label_fr": "Saint-Martin-la-Campagne"
            },
            {
                "value": 35807,
                "label": "Le Meix-Saint-Epoing",
                "label_ar": "لو ميكس سان إيبوينج",
                "label_fr": "Le Meix-Saint-Epoing"
            },
            {
                "value": 35808,
                "label": "Soulieres",
                "label_ar": "سولييه",
                "label_fr": "Soulières"
            },
            {
                "value": 35809,
                "label": "La Barben",
                "label_ar": "لا باربين",
                "label_fr": "La Barben"
            },
            {
                "value": 35810,
                "label": "Troyon",
                "label_ar": "ترويون",
                "label_fr": "Troyon"
            },
            {
                "value": 35811,
                "label": "Mollkirch",
                "label_ar": "مولكيرش",
                "label_fr": "Mollkirch"
            },
            {
                "value": 35812,
                "label": "Barjols",
                "label_ar": "بارجولس",
                "label_fr": "Barjols"
            },
            {
                "value": 35813,
                "label": "Lorcieres",
                "label_ar": "لورسيريس",
                "label_fr": "Lorcières"
            },
            {
                "value": 35814,
                "label": "Molliens-au-Bois",
                "label_ar": "Molliens-au-Bois",
                "label_fr": "Molliens-au-Bois"
            },
            {
                "value": 35815,
                "label": "Cases-de-Pene",
                "label_ar": "حالات دي بيني",
                "label_fr": "Cases-de-Pene"
            },
            {
                "value": 35816,
                "label": "Saint-Python",
                "label_ar": "سان بيثون",
                "label_fr": "Saint-Python"
            },
            {
                "value": 35817,
                "label": "Mazeres-sur-Salat",
                "label_ar": "Mazeres-sur-Salat",
                "label_fr": "Mazères-sur-Salat"
            },
            {
                "value": 35818,
                "label": "Veria",
                "label_ar": "فيريا",
                "label_fr": "Veria"
            },
            {
                "value": 35819,
                "label": "Curgies",
                "label_ar": "كورجيس",
                "label_fr": "Curgies"
            },
            {
                "value": 35820,
                "label": "Gorcy",
                "label_ar": "جورسي",
                "label_fr": "Gorcy"
            },
            {
                "value": 35821,
                "label": "Trevignin",
                "label_ar": "تريفجنين",
                "label_fr": "Trevignin"
            },
            {
                "value": 35822,
                "label": "Saint-Porchaire",
                "label_ar": "سانت بورتشاير",
                "label_fr": "Saint-Porchaire"
            },
            {
                "value": 35823,
                "label": "Charleville",
                "label_ar": "شارلفيل",
                "label_fr": "Charleville"
            },
            {
                "value": 35824,
                "label": "Paillet",
                "label_ar": "بيليه",
                "label_fr": "Paillet"
            },
            {
                "value": 35825,
                "label": "Abancourt",
                "label_ar": "أبانكور",
                "label_fr": "Abancourt"
            },
            {
                "value": 35826,
                "label": "Vigoulet-Auzil",
                "label_ar": "فيجوليه اوزيل",
                "label_fr": "Vigoulet-Auzil"
            },
            {
                "value": 35827,
                "label": "Bours",
                "label_ar": "بورس",
                "label_fr": "Bours"
            },
            {
                "value": 35828,
                "label": "Llupia",
                "label_ar": "لوبيا",
                "label_fr": "Llupia"
            },
            {
                "value": 35829,
                "label": "Girancourt",
                "label_ar": "جيرانكور",
                "label_fr": "Girancourt"
            },
            {
                "value": 35830,
                "label": "Noyant-d'Allier",
                "label_ar": "نويانت داليير",
                "label_fr": "Noyant-d'Allier"
            },
            {
                "value": 35831,
                "label": "La Poterie",
                "label_ar": "لا بوتيري",
                "label_fr": "La Poterie"
            },
            {
                "value": 35832,
                "label": "Longeves",
                "label_ar": "طويلة الأكمام",
                "label_fr": "Longues"
            },
            {
                "value": 35833,
                "label": "Saint-Victor-d'Epine",
                "label_ar": "سان فيكتور ديبين",
                "label_fr": "Saint-Victor-d'Épine"
            },
            {
                "value": 35834,
                "label": "Echevis",
                "label_ar": "إيشيفيس",
                "label_fr": "Echevis"
            },
            {
                "value": 35835,
                "label": "Cleon-d'Andran",
                "label_ar": "كليون داندران",
                "label_fr": "Cléon-d'Andran"
            },
            {
                "value": 35836,
                "label": "Neauphlette",
                "label_ar": "Neauphlette",
                "label_fr": "Neauphlette"
            },
            {
                "value": 35837,
                "label": "Andrest",
                "label_ar": "و الراحة",
                "label_fr": "Andrest"
            },
            {
                "value": 35838,
                "label": "Pierrefitte-Nestalas",
                "label_ar": "بييرفيت نستالاس",
                "label_fr": "Pierrefitte-Nestalas"
            },
            {
                "value": 35839,
                "label": "Saint-Laurent-de-Neste",
                "label_ar": "سان لوران دي نيستي",
                "label_fr": "Saint-Laurent-de-Neste"
            },
            {
                "value": 35840,
                "label": "Horgues",
                "label_ar": "هورجس",
                "label_fr": "Horgues"
            },
            {
                "value": 35841,
                "label": "Villarzel-Cabardes",
                "label_ar": "فيلارزيل كابارديس",
                "label_fr": "Villarzel-Cabardes"
            },
            {
                "value": 35842,
                "label": "Beauville",
                "label_ar": "بوفيل",
                "label_fr": "Beauville"
            },
            {
                "value": 35843,
                "label": "Grezolles",
                "label_ar": "Grezolles",
                "label_fr": "Grezolles"
            },
            {
                "value": 35844,
                "label": "Vouel",
                "label_ar": "صوت",
                "label_fr": "Vouel"
            },
            {
                "value": 35845,
                "label": "Sennece-les-Macon",
                "label_ar": "سنيس ليه ماكون",
                "label_fr": "Sennece-les-Macon"
            },
            {
                "value": 35846,
                "label": "Brens",
                "label_ar": "برنس",
                "label_fr": "Brens"
            },
            {
                "value": 35847,
                "label": "Etampes-sur-Marne",
                "label_ar": "إتامبيس سور مارن",
                "label_fr": "Etampes-sur-Marne"
            },
            {
                "value": 35848,
                "label": "Frieres-Faillouel",
                "label_ar": "فاريرز فيول",
                "label_fr": "Frières-Faillouel"
            },
            {
                "value": 35849,
                "label": "Nouvion-sur-Meuse",
                "label_ar": "نوفيون سور ميوز",
                "label_fr": "Nouvion-sur-Meuse"
            },
            {
                "value": 35850,
                "label": "Haraucourt",
                "label_ar": "هاراوكورت",
                "label_fr": "Haraucourt"
            },
            {
                "value": 35851,
                "label": "Leffincourt",
                "label_ar": "ليفينكور",
                "label_fr": "Leffincourt"
            },
            {
                "value": 35852,
                "label": "Saint-Loup-Hors",
                "label_ar": "سان لوب هورز",
                "label_fr": "Saint-Loup-Hors"
            },
            {
                "value": 35853,
                "label": "Saint-Roch",
                "label_ar": "سان روش",
                "label_fr": "Saint-Roch"
            },
            {
                "value": 35854,
                "label": "Thodure",
                "label_ar": "Thodure",
                "label_fr": "Thodure"
            },
            {
                "value": 35855,
                "label": "Saint-Georges-d'Esperanche",
                "label_ar": "سان جورج ديسبيرانش",
                "label_fr": "Saint-Georges-d'Esperanche"
            },
            {
                "value": 35856,
                "label": "Gatey",
                "label_ar": "بوابة",
                "label_fr": "Gatey"
            },
            {
                "value": 35857,
                "label": "Falletans",
                "label_ar": "فاليتان",
                "label_fr": "Falletans"
            },
            {
                "value": 35858,
                "label": "Arcon",
                "label_ar": "أركون",
                "label_fr": "Arcon"
            },
            {
                "value": 35859,
                "label": "Mende",
                "label_ar": "ميندي",
                "label_fr": "Mende"
            },
            {
                "value": 35860,
                "label": "Baconnes",
                "label_ar": "بيكون",
                "label_fr": "Baconnes"
            },
            {
                "value": 35861,
                "label": "Bourghelles",
                "label_ar": "بورغيلس",
                "label_fr": "Bourghelles"
            },
            {
                "value": 35862,
                "label": "Winnezeele",
                "label_ar": "وينزيل",
                "label_fr": "Winnezeele"
            },
            {
                "value": 35863,
                "label": "Cuinchy",
                "label_ar": "كوينشي",
                "label_fr": "Cuinchy"
            },
            {
                "value": 35864,
                "label": "Perignat-les-Sarlieve",
                "label_ar": "Perignat-les-Sarlieve",
                "label_fr": "Pérignat-les-Sarlieve"
            },
            {
                "value": 35865,
                "label": "Servance",
                "label_ar": "الخدمة",
                "label_fr": "Servance"
            },
            {
                "value": 35866,
                "label": "Emance",
                "label_ar": "Emance",
                "label_fr": "Émance"
            },
            {
                "value": 35867,
                "label": "Saint-Etienne-de-Brillouet",
                "label_ar": "سانت إتيان دو بريلويه",
                "label_fr": "Saint-Étienne-de-Brillouet"
            },
            {
                "value": 35868,
                "label": "Saint-Georges-les-Baillargeaux",
                "label_ar": "Saint-Georges-les-Baillargeaux",
                "label_fr": "Saint-Georges-les-Baillargeaux"
            },
            {
                "value": 35869,
                "label": "Frontenay-sur-Dive",
                "label_ar": "Frontenay-sur-Dive",
                "label_fr": "Frontenay-sur-Dive"
            },
            {
                "value": 35870,
                "label": "Seraincourt",
                "label_ar": "سيرينكورت",
                "label_fr": "Seraincourt"
            },
            {
                "value": 35871,
                "label": "Cours",
                "label_ar": "كورس",
                "label_fr": "Cours"
            },
            {
                "value": 35872,
                "label": "Beugin",
                "label_ar": "بيوجين",
                "label_fr": "Beugin"
            },
            {
                "value": 35873,
                "label": "Labeuvriere",
                "label_ar": "لابوفرير",
                "label_fr": "Labeuvrière"
            },
            {
                "value": 35874,
                "label": "Aix-en-Issart",
                "label_ar": "إيكس أون إيسارت",
                "label_fr": "Aix-en-Issart"
            },
            {
                "value": 35875,
                "label": "Cuffies",
                "label_ar": "الأصفاد",
                "label_fr": "Manchettes"
            },
            {
                "value": 35876,
                "label": "Villeneuve-Saint-Germain",
                "label_ar": "فيلنوف سان جيرمان",
                "label_fr": "Villeneuve-Saint-Germain"
            },
            {
                "value": 35877,
                "label": "Virignin",
                "label_ar": "فيريجنين",
                "label_fr": "Virignine"
            },
            {
                "value": 35878,
                "label": "Villemoyenne",
                "label_ar": "فيلموين",
                "label_fr": "Villemoyenne"
            },
            {
                "value": 35879,
                "label": "Bretigny",
                "label_ar": "بريتيجني",
                "label_fr": "Brétigny"
            },
            {
                "value": 35880,
                "label": "Yvoire",
                "label_ar": "إيفوار",
                "label_fr": "Yvoire"
            },
            {
                "value": 35881,
                "label": "Conde-sur-Suippe",
                "label_ar": "كوندي سور سويبي",
                "label_fr": "Condé-sur-Suippe"
            },
            {
                "value": 35882,
                "label": "Labastide-Gabausse",
                "label_ar": "لاباستيد-غابوس",
                "label_fr": "Labastide-Gabausse"
            },
            {
                "value": 35883,
                "label": "La Chaumusse",
                "label_ar": "لا تشوموس",
                "label_fr": "La Chaumusse"
            },
            {
                "value": 35884,
                "label": "Saint-Romain-la-Motte",
                "label_ar": "سان رومان لا موت",
                "label_fr": "Saint-Romain-la-Motte"
            },
            {
                "value": 35885,
                "label": "Voves",
                "label_ar": "الحركات",
                "label_fr": "Voves"
            },
            {
                "value": 35886,
                "label": "Champeaux",
                "label_ar": "تشامبو",
                "label_fr": "Champeaux"
            },
            {
                "value": 35887,
                "label": "Juvaincourt",
                "label_ar": "جوفينكورت",
                "label_fr": "Juvaincourt"
            },
            {
                "value": 35888,
                "label": "Iteuil",
                "label_ar": "إتويل",
                "label_fr": "Iteuil"
            },
            {
                "value": 35889,
                "label": "Limetz-Villez",
                "label_ar": "ليميتز فيليز",
                "label_fr": "Limetz-Villez"
            },
            {
                "value": 35890,
                "label": "Maennolsheim",
                "label_ar": "مينولشيم",
                "label_fr": "Maennolsheim"
            },
            {
                "value": 35891,
                "label": "Marle",
                "label_ar": "مارل",
                "label_fr": "Marle"
            },
            {
                "value": 35892,
                "label": "Chavanay",
                "label_ar": "شافاني",
                "label_fr": "Chavanay"
            },
            {
                "value": 35893,
                "label": "Cours-la-Ville",
                "label_ar": "كورس لا فيل",
                "label_fr": "Cours-la-Ville"
            },
            {
                "value": 35894,
                "label": "Sermoise",
                "label_ar": "Sermoise",
                "label_fr": "Sermoise"
            },
            {
                "value": 35895,
                "label": "Bois-Grenier",
                "label_ar": "بوا غرينير",
                "label_fr": "Bois-Grenier"
            },
            {
                "value": 35896,
                "label": "Bruille-Saint-Amand",
                "label_ar": "برويل سانت اماند",
                "label_fr": "Bruille-Saint-Amand"
            },
            {
                "value": 35897,
                "label": "Saint-Gengoux-de-Scisse",
                "label_ar": "Saint-Gengoux-de-Scisse",
                "label_fr": "Saint-Gengoux-de-Scisse"
            },
            {
                "value": 35898,
                "label": "Busque",
                "label_ar": "بوسكي",
                "label_fr": "Busque"
            },
            {
                "value": 35899,
                "label": "Theil-sur-Vanne",
                "label_ar": "ثيل سور فاني",
                "label_fr": "Theil-sur-Vanne"
            },
            {
                "value": 35900,
                "label": "Pin",
                "label_ar": "دبوس",
                "label_fr": "Épingle"
            },
            {
                "value": 35901,
                "label": "Torcieu",
                "label_ar": "تورسيو",
                "label_fr": "Torcieu"
            },
            {
                "value": 35902,
                "label": "Mouchin",
                "label_ar": "موشين",
                "label_fr": "Mouchin"
            },
            {
                "value": 35903,
                "label": "Bourg",
                "label_ar": "بورغ",
                "label_fr": "Bourg"
            },
            {
                "value": 35904,
                "label": "Chivres-en-Laonnois",
                "label_ar": "Chivres-en-Laonnois",
                "label_fr": "Chivres-en-Laonnois"
            },
            {
                "value": 35905,
                "label": "Roullens",
                "label_ar": "رولينز",
                "label_fr": "Roullens"
            },
            {
                "value": 35906,
                "label": "Codolet",
                "label_ar": "كودوليت",
                "label_fr": "Codolet"
            },
            {
                "value": 35907,
                "label": "Beaujeu",
                "label_ar": "بوجيو",
                "label_fr": "Beaujeu"
            },
            {
                "value": 35908,
                "label": "Les Adrets-de-l'Esterel",
                "label_ar": "ليه Adrets-de-l'Esterel",
                "label_fr": "Les Adrets-de-l'Esterel"
            },
            {
                "value": 35909,
                "label": "Ceton",
                "label_ar": "سيتون",
                "label_fr": "Ceton"
            },
            {
                "value": 35910,
                "label": "Rolampont",
                "label_ar": "رولامبونت",
                "label_fr": "Rolampont"
            },
            {
                "value": 35911,
                "label": "Sormonne",
                "label_ar": "سورمون",
                "label_fr": "Sormonne"
            },
            {
                "value": 35912,
                "label": "Touvre",
                "label_ar": "توفري",
                "label_fr": "Touvre"
            },
            {
                "value": 35913,
                "label": "Rieux-Minervois",
                "label_ar": "ريو مينيرفوا",
                "label_fr": "Rieux-Minervois"
            },
            {
                "value": 35914,
                "label": "Plobannalec-Lesconil",
                "label_ar": "بلوباناليك ليسكونيل",
                "label_fr": "Plobannalec-Lesconil"
            },
            {
                "value": 35915,
                "label": "Cerons",
                "label_ar": "سييرون",
                "label_fr": "Cérons"
            },
            {
                "value": 35916,
                "label": "Arsac",
                "label_ar": "أرساك",
                "label_fr": "Arsac"
            },
            {
                "value": 35917,
                "label": "Les Roches",
                "label_ar": "ليه روشيه",
                "label_fr": "Les Roches"
            },
            {
                "value": 35918,
                "label": "Saint-Hippolyte",
                "label_ar": "سانت هيبوليت",
                "label_fr": "Saint-Hippolyte"
            },
            {
                "value": 35919,
                "label": "Rimogne",
                "label_ar": "ريموجن",
                "label_fr": "Rimogne"
            },
            {
                "value": 35920,
                "label": "Solers",
                "label_ar": "Solers",
                "label_fr": "Solers"
            },
            {
                "value": 35921,
                "label": "Houppeville",
                "label_ar": "هوبفيل",
                "label_fr": "Houppeville"
            },
            {
                "value": 35922,
                "label": "Sommedieue",
                "label_ar": "Sommedieue",
                "label_fr": "Sommedieue"
            },
            {
                "value": 35923,
                "label": "Fontaines",
                "label_ar": "فونتين",
                "label_fr": "Fontaines"
            },
            {
                "value": 35924,
                "label": "Quevillon",
                "label_ar": "كيفيلون",
                "label_fr": "Quevillon"
            },
            {
                "value": 35925,
                "label": "Rouxmesnil-Bouteilles",
                "label_ar": "Rouxmesnil-Bouteilles",
                "label_fr": "Rouxmesnil-Bouteilles"
            },
            {
                "value": 35926,
                "label": "Regniowez",
                "label_ar": "ريجنويز",
                "label_fr": "Regniowez"
            },
            {
                "value": 35927,
                "label": "Saint-Seurin-sur-l'Isle",
                "label_ar": "Saint-Seurin-sur-l'Isle",
                "label_fr": "Saint-Seurin-sur-l'Isle"
            },
            {
                "value": 35928,
                "label": "Remigny",
                "label_ar": "ريميني",
                "label_fr": "Remigny"
            },
            {
                "value": 35929,
                "label": "Bessonies",
                "label_ar": "بيسوني",
                "label_fr": "Bessonies"
            },
            {
                "value": 35930,
                "label": "Javrezac",
                "label_ar": "جافريزاك",
                "label_fr": "Javrezac"
            },
            {
                "value": 35931,
                "label": "Borderes-sur-l'Echez",
                "label_ar": "حدود سور ليشيز",
                "label_fr": "Borderes-sur-l'Echez"
            },
            {
                "value": 35932,
                "label": "Collorgues",
                "label_ar": "Collorgues",
                "label_fr": "Collorgues"
            },
            {
                "value": 35933,
                "label": "La Calmette",
                "label_ar": "لا كالميت",
                "label_fr": "La Calmette"
            },
            {
                "value": 35934,
                "label": "Saint-Genies-de-Malgoires",
                "label_ar": "سان جيني دي مالغوار",
                "label_fr": "Saint-Genies-de-Malgoires"
            },
            {
                "value": 35935,
                "label": "Ouilly-le-Tesson",
                "label_ar": "Ouilly-le-Tesson",
                "label_fr": "Ouilly-le-Tesson"
            },
            {
                "value": 35936,
                "label": "Saint-Remy-du-Nord",
                "label_ar": "سان ريمي دو نور",
                "label_fr": "Saint-Rémy-du-Nord"
            },
            {
                "value": 35937,
                "label": "Sours",
                "label_ar": "تعكر",
                "label_fr": "Aigre"
            },
            {
                "value": 35938,
                "label": "Sandouville",
                "label_ar": "ساندوفيل",
                "label_fr": "Sandouville"
            },
            {
                "value": 35939,
                "label": "Mouroux",
                "label_ar": "مورو",
                "label_fr": "Mouroux"
            },
            {
                "value": 35940,
                "label": "Bassou",
                "label_ar": "باسو",
                "label_fr": "Bassou"
            },
            {
                "value": 35941,
                "label": "Laduz",
                "label_ar": "لادوز",
                "label_fr": "Laduz"
            },
            {
                "value": 35942,
                "label": "Nogent-sur-Eure",
                "label_ar": "نوجينت سور يور",
                "label_fr": "Nogent-sur-Eure"
            },
            {
                "value": 35943,
                "label": "Olle",
                "label_ar": "أوللي",
                "label_fr": "Olle"
            },
            {
                "value": 35944,
                "label": "Bannes",
                "label_ar": "بانز",
                "label_fr": "Bannes"
            },
            {
                "value": 35945,
                "label": "Bertrimoutier",
                "label_ar": "بيرتريموتييه",
                "label_fr": "Bertrimoutier"
            },
            {
                "value": 35946,
                "label": "Fontaine-Uterte",
                "label_ar": "فونتين أوتيرتي",
                "label_fr": "Fontaine-Uterte"
            },
            {
                "value": 35947,
                "label": "Quillan",
                "label_ar": "كيلان",
                "label_fr": "Quillan"
            },
            {
                "value": 35948,
                "label": "Claouey",
                "label_ar": "كلوي",
                "label_fr": "Claouey"
            },
            {
                "value": 35949,
                "label": "Erbree",
                "label_ar": "أربري",
                "label_fr": "Erbree"
            },
            {
                "value": 35950,
                "label": "Largillay-Marsonnay",
                "label_ar": "Largillay-Marsonnay",
                "label_fr": "Largillay-Marsonnay"
            },
            {
                "value": 35951,
                "label": "Lentigny",
                "label_ar": "Lentigny",
                "label_fr": "Lentigny"
            },
            {
                "value": 35952,
                "label": "Lavans-les-Saint-Claude",
                "label_ar": "لافانز ليه سان كلود",
                "label_fr": "Lavans-les-Saint-Claude"
            },
            {
                "value": 35953,
                "label": "Wambaix",
                "label_ar": "وامبايكس",
                "label_fr": "Wambaix"
            },
            {
                "value": 35954,
                "label": "Azille",
                "label_ar": "أزيل",
                "label_fr": "Azille"
            },
            {
                "value": 35955,
                "label": "Saint-Leonard",
                "label_ar": "سانت ليونارد",
                "label_fr": "Saint-Léonard"
            },
            {
                "value": 35956,
                "label": "Lespesses",
                "label_ar": "فتيات",
                "label_fr": "Lespesses"
            },
            {
                "value": 35957,
                "label": "Wissignicourt",
                "label_ar": "Wissignicourt",
                "label_fr": "Wissignicourt"
            },
            {
                "value": 35958,
                "label": "Hondschoote",
                "label_ar": "هوندشوت",
                "label_fr": "Hondschoote"
            },
            {
                "value": 35959,
                "label": "Vieux-Conde",
                "label_ar": "فيو كوندي",
                "label_fr": "Vieux-Condé"
            },
            {
                "value": 35960,
                "label": "La Frenaye",
                "label_ar": "لا فريناي",
                "label_fr": "La Frenaye"
            },
            {
                "value": 35961,
                "label": "Bazoilles-sur-Meuse",
                "label_ar": "بازويل سور ميوز",
                "label_fr": "Bazoilles-sur-Meuse"
            },
            {
                "value": 35962,
                "label": "Colomby-sur-Thaon",
                "label_ar": "كولومبي سور ثون",
                "label_fr": "Colomby-sur-Thaon"
            },
            {
                "value": 35963,
                "label": "Fresnicourt-le-Dolmen",
                "label_ar": "فريسنيكورت لو دولمن",
                "label_fr": "Fresnicourt-le-Dolmen"
            },
            {
                "value": 35964,
                "label": "Blaye-les-Mines",
                "label_ar": "Blaye-les-Mines",
                "label_fr": "Blaye-les-Mines"
            },
            {
                "value": 35965,
                "label": "Strazeele",
                "label_ar": "سترازيل",
                "label_fr": "Strazeele"
            },
            {
                "value": 35966,
                "label": "Caestre",
                "label_ar": "كيستر",
                "label_fr": "Caestre"
            },
            {
                "value": 35967,
                "label": "Bucy-les-Cerny",
                "label_ar": "بوسي ليه سيرني",
                "label_fr": "Bucy-les-Cerny"
            },
            {
                "value": 35968,
                "label": "Royas",
                "label_ar": "روياس",
                "label_fr": "Royas"
            },
            {
                "value": 35969,
                "label": "Gommerville",
                "label_ar": "جومرفيل",
                "label_fr": "Gommerville"
            },
            {
                "value": 35970,
                "label": "Crouttes",
                "label_ar": "كروت",
                "label_fr": "Crouttes"
            },
            {
                "value": 35971,
                "label": "Nordausques",
                "label_ar": "نوردوسكيس",
                "label_fr": "Nordausques"
            },
            {
                "value": 35972,
                "label": "Flaux",
                "label_ar": "الجريان",
                "label_fr": "Flaux"
            },
            {
                "value": 35973,
                "label": "Bagnols-en-Foret",
                "label_ar": "Bagnols-en-Foret",
                "label_fr": "Bagnols-en-Foret"
            },
            {
                "value": 35974,
                "label": "Saint-Pierre-des-Fleurs",
                "label_ar": "سان بيير دي فلور",
                "label_fr": "Saint-Pierre-des-Fleurs"
            },
            {
                "value": 35975,
                "label": "L'Orbrie",
                "label_ar": "لوريبري",
                "label_fr": "L'Orbrie"
            },
            {
                "value": 35976,
                "label": "Saint-Oulph",
                "label_ar": "سان أولف",
                "label_fr": "Saint-Oulph"
            },
            {
                "value": 35977,
                "label": "Capinghem",
                "label_ar": "كابينجيم",
                "label_fr": "Capinghem"
            },
            {
                "value": 35978,
                "label": "Reyssouze",
                "label_ar": "ريسوز",
                "label_fr": "Reyssouze"
            },
            {
                "value": 35979,
                "label": "Plouedern",
                "label_ar": "بلويديرن",
                "label_fr": "Plouedern"
            },
            {
                "value": 35980,
                "label": "Boigny",
                "label_ar": "بويني",
                "label_fr": "Boigny"
            },
            {
                "value": 35981,
                "label": "Mortagne-du-Nord",
                "label_ar": "مورتاني دو نور",
                "label_fr": "Mortagne-du-Nord"
            },
            {
                "value": 35982,
                "label": "Anhiers",
                "label_ar": "أنهير",
                "label_fr": "Anhiers"
            },
            {
                "value": 35983,
                "label": "Saint-Michel-sur-Savasse",
                "label_ar": "سان ميشيل سور سافاس",
                "label_fr": "Saint-Michel-sur-Savasse"
            },
            {
                "value": 35984,
                "label": "Cizely",
                "label_ar": "عزيزي",
                "label_fr": "Cizely"
            },
            {
                "value": 35985,
                "label": "Folles",
                "label_ar": "فوليز",
                "label_fr": "Folles"
            },
            {
                "value": 35986,
                "label": "Blanzay-sur-Boutonne",
                "label_ar": "بلانزي سور بوتون",
                "label_fr": "Blanzay-sur-Boutonne"
            },
            {
                "value": 35987,
                "label": "Cenon-sur-Vienne",
                "label_ar": "سينون سور فيين",
                "label_fr": "Cenon-sur-Vienne"
            },
            {
                "value": 35988,
                "label": "Vaubecourt",
                "label_ar": "فوبيكورت",
                "label_fr": "Vaubecourt"
            },
            {
                "value": 35989,
                "label": "Velleches",
                "label_ar": "Velleches",
                "label_fr": "Velleches"
            },
            {
                "value": 35990,
                "label": "Moustier-en-Fagne",
                "label_ar": "موستير أون فاني",
                "label_fr": "Moustier-en-Fagne"
            },
            {
                "value": 35991,
                "label": "Acheville",
                "label_ar": "أشفيل",
                "label_fr": "Acheville"
            },
            {
                "value": 35992,
                "label": "Vigueron",
                "label_ar": "فيجيرون",
                "label_fr": "Vigueron"
            },
            {
                "value": 35993,
                "label": "Sourribes",
                "label_ar": "صوريبس",
                "label_fr": "Sourribes"
            },
            {
                "value": 35994,
                "label": "Corbara",
                "label_ar": "كوربارا",
                "label_fr": "Corbara"
            },
            {
                "value": 35995,
                "label": "Goult",
                "label_ar": "جولت",
                "label_fr": "Goult"
            },
            {
                "value": 35996,
                "label": "Monticello",
                "label_ar": "مونتايسلو",
                "label_fr": "Monticello"
            },
            {
                "value": 35997,
                "label": "Omessa",
                "label_ar": "أومسا",
                "label_fr": "Omessa"
            },
            {
                "value": 35998,
                "label": "Francardo",
                "label_ar": "فرانكاردو",
                "label_fr": "Francardo"
            },
            {
                "value": 35999,
                "label": "La Rochegiron",
                "label_ar": "لاروشيجرون",
                "label_fr": "La Rochegiron"
            },
            {
                "value": 36000,
                "label": "Roumoules",
                "label_ar": "رومول",
                "label_fr": "Roumoules"
            },
            {
                "value": 36001,
                "label": "Fredille",
                "label_ar": "فريدل",
                "label_fr": "Fredille"
            },
            {
                "value": 36002,
                "label": "Villorceau",
                "label_ar": "فيلورسو",
                "label_fr": "Villorceau"
            },
            {
                "value": 36003,
                "label": "Thimert-Gatelles",
                "label_ar": "ثيمرت جاتيل",
                "label_fr": "Thimert-Gatelles"
            },
            {
                "value": 36004,
                "label": "Charge",
                "label_ar": "الشحنة",
                "label_fr": "Charge"
            },
            {
                "value": 36005,
                "label": "Saint-Eloy-de-Gy",
                "label_ar": "سان إلوي دي جي",
                "label_fr": "Saint-Eloy-de-Gy"
            },
            {
                "value": 36006,
                "label": "Quincy",
                "label_ar": "كوينسي",
                "label_fr": "Quincy"
            },
            {
                "value": 36007,
                "label": "Bouzonville-en-Beauce",
                "label_ar": "Bouzonville-en-Beauce",
                "label_fr": "Bouzonville-en-Beauce"
            },
            {
                "value": 36008,
                "label": "Outarville",
                "label_ar": "أوتارفيل",
                "label_fr": "Outarville"
            },
            {
                "value": 36009,
                "label": "Saumeray",
                "label_ar": "سومراي",
                "label_fr": "Saumeray"
            },
            {
                "value": 36010,
                "label": "Ciron",
                "label_ar": "سيرون",
                "label_fr": "Ciron"
            },
            {
                "value": 36011,
                "label": "Cussay",
                "label_ar": "كساي",
                "label_fr": "Cussay"
            },
            {
                "value": 36012,
                "label": "Menetou-Salon",
                "label_ar": "صالون مينتو",
                "label_fr": "Menetou-Salon"
            },
            {
                "value": 36013,
                "label": "Dun-le-Poelier",
                "label_ar": "دان لو بوولييه",
                "label_fr": "Dun-le-Poelier"
            },
            {
                "value": 36014,
                "label": "Couy",
                "label_ar": "Couy",
                "label_fr": "Couy"
            },
            {
                "value": 36015,
                "label": "La Gaillarde",
                "label_ar": "لا جيلارد",
                "label_fr": "La Gaillarde"
            },
            {
                "value": 36016,
                "label": "Bourneville",
                "label_ar": "بورنفيل",
                "label_fr": "Bourneville"
            },
            {
                "value": 36017,
                "label": "Asnieres",
                "label_ar": "أسنيريس",
                "label_fr": "Asnières"
            },
            {
                "value": 36018,
                "label": "Londinieres",
                "label_ar": "لوندينير",
                "label_fr": "Londinières"
            },
            {
                "value": 36019,
                "label": "Vieux-Rouen-sur-Bresle",
                "label_ar": "Vieux-Rouen-sur-Bresle",
                "label_fr": "Vieux-Rouen-sur-Bresle"
            },
            {
                "value": 36020,
                "label": "Tocqueville",
                "label_ar": "توكفيل",
                "label_fr": "Tocqueville"
            },
            {
                "value": 36021,
                "label": "Beautot",
                "label_ar": "بيوتوت",
                "label_fr": "Beautot"
            },
            {
                "value": 36022,
                "label": "Port-Mort",
                "label_ar": "بورت مورت",
                "label_fr": "Port-Mort"
            },
            {
                "value": 36023,
                "label": "Tourville-la-Chapelle",
                "label_ar": "تورفيل لا شابيل",
                "label_fr": "Tourville-la-Chapelle"
            },
            {
                "value": 36024,
                "label": "Etalondes",
                "label_ar": "إتالوندس",
                "label_fr": "Etalondes"
            },
            {
                "value": 36025,
                "label": "Berville-la-Campagne",
                "label_ar": "بيرفيل لا كامباني",
                "label_fr": "Berville-la-Campagne"
            },
            {
                "value": 36026,
                "label": "Le Manoir",
                "label_ar": "لو مانوار",
                "label_fr": "Le Manoir"
            },
            {
                "value": 36027,
                "label": "Saint-Vaast-du-Val",
                "label_ar": "سان فاست دو فال",
                "label_fr": "Saint-Vaast-du-Val"
            },
            {
                "value": 36028,
                "label": "Saints",
                "label_ar": "القديسين",
                "label_fr": "Les saints"
            },
            {
                "value": 36029,
                "label": "Torderes",
                "label_ar": "الحدود",
                "label_fr": "Torderes"
            },
            {
                "value": 36030,
                "label": "Saint-Paulet-de-Caisson",
                "label_ar": "سان بوليه دي كايسون",
                "label_fr": "Saint-Paulet-de-Caisson"
            },
            {
                "value": 36031,
                "label": "Vialas",
                "label_ar": "فيالاس",
                "label_fr": "Flacons"
            },
            {
                "value": 36032,
                "label": "Sauveterre",
                "label_ar": "سوفيتير",
                "label_fr": "Sauveterre"
            },
            {
                "value": 36033,
                "label": "Targassonne",
                "label_ar": "Targassonne",
                "label_fr": "Targassonne"
            },
            {
                "value": 36034,
                "label": "Narbonne-Plage",
                "label_ar": "ناربون بلاج",
                "label_fr": "Narbonne-Plage"
            },
            {
                "value": 36035,
                "label": "Cepie",
                "label_ar": "سيبي",
                "label_fr": "Cepie"
            },
            {
                "value": 36036,
                "label": "Cervera de la Marenda",
                "label_ar": "سيرفيرا دي لا مارندا",
                "label_fr": "Cervera de la Marenda"
            },
            {
                "value": 36037,
                "label": "Los Masos",
                "label_ar": "لوس ماسوس",
                "label_fr": "Los Masos"
            },
            {
                "value": 36038,
                "label": "Saint-Alexandre",
                "label_ar": "سان الكسندر",
                "label_fr": "Saint-Alexandre"
            },
            {
                "value": 36039,
                "label": "La Liviniere",
                "label_ar": "لا ليفينير",
                "label_fr": "La Livinière"
            },
            {
                "value": 36040,
                "label": "La Canourgue",
                "label_ar": "لا كانورج",
                "label_fr": "La Canourgue"
            },
            {
                "value": 36041,
                "label": "Valflaunes",
                "label_ar": "فالفلاونيس",
                "label_fr": "Valflaunes"
            },
            {
                "value": 36042,
                "label": "Saint-Chely-d'Apcher",
                "label_ar": "سان تشيلي دابشر",
                "label_fr": "Saint-Chely-d'Apcher"
            },
            {
                "value": 36043,
                "label": "Venejan",
                "label_ar": "الفينجان",
                "label_fr": "Vénéjan"
            },
            {
                "value": 36044,
                "label": "Ferrals-les-Corbieres",
                "label_ar": "Ferrals-les-Corbieres",
                "label_fr": "Ferrals-les-Corbières"
            },
            {
                "value": 36045,
                "label": "La Jumelliere",
                "label_ar": "لا جوميليري",
                "label_fr": "La Jumellière"
            },
            {
                "value": 36046,
                "label": "Puyravault",
                "label_ar": "بويرافولت",
                "label_fr": "Puyravault"
            },
            {
                "value": 36047,
                "label": "Ruaudin",
                "label_ar": "Ruaudin",
                "label_fr": "Ruaudin"
            },
            {
                "value": 36048,
                "label": "Le Puy",
                "label_ar": "لو بوي",
                "label_fr": "Le Puy"
            },
            {
                "value": 36049,
                "label": "Saint-Laurent-sur-Sevre",
                "label_ar": "سان لوران سور سيفري",
                "label_fr": "Saint-Laurent-sur-Sèvre"
            },
            {
                "value": 36050,
                "label": "Falleron",
                "label_ar": "فاليرون",
                "label_fr": "Falleron"
            },
            {
                "value": 36051,
                "label": "Chaix",
                "label_ar": "تشايكس",
                "label_fr": "Chaix"
            },
            {
                "value": 36052,
                "label": "La Meilleraie-Tillay",
                "label_ar": "لا Meilleraie-Tillay",
                "label_fr": "La Meilleraie-Tillay"
            },
            {
                "value": 36053,
                "label": "La Pommeraye",
                "label_ar": "لا بوميراي",
                "label_fr": "La Pommeraye"
            },
            {
                "value": 36054,
                "label": "Aron",
                "label_ar": "آرون",
                "label_fr": "Aron"
            },
            {
                "value": 36055,
                "label": "La Jaille-Yvon",
                "label_ar": "لا جيل إيفون",
                "label_fr": "La Jaille-Yvon"
            },
            {
                "value": 36056,
                "label": "Roeze-sur-Sarthe",
                "label_ar": "روز سور سارث",
                "label_fr": "Roeze-sur-Sarthe"
            },
            {
                "value": 36057,
                "label": "La Poueze",
                "label_ar": "لابوز",
                "label_fr": "La Poueze"
            },
            {
                "value": 36058,
                "label": "Solesmes",
                "label_ar": "سولسمس",
                "label_fr": "Solesmes"
            },
            {
                "value": 36059,
                "label": "Fresnay-en-Retz",
                "label_ar": "فريسناي أون ريتز",
                "label_fr": "Fresnay-en-Retz"
            },
            {
                "value": 36060,
                "label": "Chaille-les-Marais",
                "label_ar": "Chaille-les-Marais",
                "label_fr": "Chaille-les-Marais"
            },
            {
                "value": 36061,
                "label": "Ardenay-sur-Merize",
                "label_ar": "أردني سور ميريز",
                "label_fr": "Ardenay-sur-Merize"
            },
            {
                "value": 36062,
                "label": "Saint-Colomban",
                "label_ar": "سان كولومبان",
                "label_fr": "Saint-Colomban"
            },
            {
                "value": 36063,
                "label": "Pierric",
                "label_ar": "بيريك",
                "label_fr": "Pierric"
            },
            {
                "value": 36064,
                "label": "La Bosse",
                "label_ar": "لا بوس",
                "label_fr": "La Bosse"
            },
            {
                "value": 36065,
                "label": "Nesmy",
                "label_ar": "نسمي",
                "label_fr": "Nesmy"
            },
            {
                "value": 36066,
                "label": "Saint-Just-Malmont",
                "label_ar": "سان جوست مالمونت",
                "label_fr": "Saint-Just-Malmont"
            },
            {
                "value": 36067,
                "label": "Vertrieu",
                "label_ar": "فيرتريو",
                "label_fr": "Vertrieu"
            },
            {
                "value": 36068,
                "label": "Magneux-Haute-Rive",
                "label_ar": "Magneux-Haute-Rive",
                "label_fr": "Magneux-Haute-Rive"
            },
            {
                "value": 36069,
                "label": "Lancie",
                "label_ar": "لانسي",
                "label_fr": "Lancie"
            },
            {
                "value": 36070,
                "label": "Saint-Etienne-de-Fontbellon",
                "label_ar": "سانت إتيان دي فونتبلون",
                "label_fr": "Saint-Étienne-de-Fontbellon"
            },
            {
                "value": 36071,
                "label": "Seyssuel",
                "label_ar": "سيسويل",
                "label_fr": "Seyssuel"
            },
            {
                "value": 36072,
                "label": "Bonnet",
                "label_ar": "غطاء محرك السيارة",
                "label_fr": "Bonnet"
            },
            {
                "value": 36073,
                "label": "Sarcey",
                "label_ar": "سارسي",
                "label_fr": "Sarcey"
            },
            {
                "value": 36074,
                "label": "Soleymieux",
                "label_ar": "Soleymieux",
                "label_fr": "Soleymieux"
            },
            {
                "value": 36075,
                "label": "Champoly",
                "label_ar": "شامبولي",
                "label_fr": "Champoly"
            },
            {
                "value": 36076,
                "label": "Ambronay",
                "label_ar": "أمبروناي",
                "label_fr": "Ambronay"
            },
            {
                "value": 36077,
                "label": "Villeneuve-de-Marc",
                "label_ar": "فيلنوف دي مارك",
                "label_fr": "Villeneuve-de-Marc"
            },
            {
                "value": 36078,
                "label": "Douvres",
                "label_ar": "دوفرز",
                "label_fr": "Douvres"
            },
            {
                "value": 36079,
                "label": "Ourches",
                "label_ar": "أورشيس",
                "label_fr": "Ourches"
            },
            {
                "value": 36080,
                "label": "Brullioles",
                "label_ar": "Brullioles",
                "label_fr": "Brullioles"
            },
            {
                "value": 36081,
                "label": "Satolas-et-Bonce",
                "label_ar": "ساتولاس إت بونس",
                "label_fr": "Satolas-et-Bonce"
            },
            {
                "value": 36082,
                "label": "Ternay",
                "label_ar": "تيرناي",
                "label_fr": "Ternay"
            },
            {
                "value": 36083,
                "label": "Agnin",
                "label_ar": "اجنين",
                "label_fr": "Agnin"
            },
            {
                "value": 36084,
                "label": "Fleurie",
                "label_ar": "فلوري",
                "label_fr": "Fleurie"
            },
            {
                "value": 36085,
                "label": "Moire",
                "label_ar": "تموج في النسيج",
                "label_fr": "Moiré"
            },
            {
                "value": 36086,
                "label": "Chambles",
                "label_ar": "تشويش",
                "label_fr": "Chambles"
            },
            {
                "value": 36087,
                "label": "Leigneux",
                "label_ar": "لينيوكس",
                "label_fr": "Léigneux"
            },
            {
                "value": 36088,
                "label": "Poncins",
                "label_ar": "بونسينس",
                "label_fr": "Poncins"
            },
            {
                "value": 36089,
                "label": "Villemotier",
                "label_ar": "فيلموتييه",
                "label_fr": "Villemotier"
            },
            {
                "value": 36090,
                "label": "Tramole",
                "label_ar": "ترامول",
                "label_fr": "Tramole"
            },
            {
                "value": 36091,
                "label": "Precieux",
                "label_ar": "بريسيو",
                "label_fr": "Précieux"
            },
            {
                "value": 36092,
                "label": "Saint-Romain-de-Lerps",
                "label_ar": "سان رومان دي ليربس",
                "label_fr": "Saint-Romain-de-Lerps"
            },
            {
                "value": 36093,
                "label": "Bagnols",
                "label_ar": "Bagnols",
                "label_fr": "Bagnols"
            },
            {
                "value": 36094,
                "label": "Salvizinet",
                "label_ar": "سالفيزينيت",
                "label_fr": "Salvizinet"
            },
            {
                "value": 36095,
                "label": "La Palud",
                "label_ar": "لا بالود",
                "label_fr": "La Palud"
            },
            {
                "value": 36096,
                "label": "Lamaire",
                "label_ar": "لامير",
                "label_fr": "Lamaire"
            },
            {
                "value": 36097,
                "label": "Bunzac",
                "label_ar": "بونزاك",
                "label_fr": "Bunzac"
            },
            {
                "value": 36098,
                "label": "Chiche",
                "label_ar": "شيشي",
                "label_fr": "Chiche"
            },
            {
                "value": 36099,
                "label": "Lescure-d'Albigeois",
                "label_ar": "ليسكيور دي البيجوا",
                "label_fr": "Lescure-d'Albigeois"
            },
            {
                "value": 36100,
                "label": "Bellegarde-Sainte-Marie",
                "label_ar": "بيلجارد سانت ماري",
                "label_fr": "Bellegarde-Sainte-Marie"
            },
            {
                "value": 36101,
                "label": "Montignac-le-Coq",
                "label_ar": "مونتينياك لو كوك",
                "label_fr": "Montignac-le-Coq"
            },
            {
                "value": 36102,
                "label": "Beychac-et-Caillau",
                "label_ar": "Beychac-et-Caillau",
                "label_fr": "Beychac-et-Caillau"
            },
            {
                "value": 36103,
                "label": "Saint-Pompont",
                "label_ar": "سان بومبونت",
                "label_fr": "Saint-Pompont"
            },
            {
                "value": 36104,
                "label": "Verac",
                "label_ar": "فيراك",
                "label_fr": "Verac"
            },
            {
                "value": 36105,
                "label": "Campugnan",
                "label_ar": "كامبوجنان",
                "label_fr": "Campugnan"
            },
            {
                "value": 36106,
                "label": "Frontenac",
                "label_ar": "فرونتيناك",
                "label_fr": "Frontenac"
            },
            {
                "value": 36107,
                "label": "Hautefage-la-Tour",
                "label_ar": "أوتيفاج لا تور",
                "label_fr": "Hautefage-la-Tour"
            },
            {
                "value": 36108,
                "label": "Monbrun",
                "label_ar": "مونبرون",
                "label_fr": "Monbrun"
            },
            {
                "value": 36109,
                "label": "Montpitol",
                "label_ar": "مونبيتول",
                "label_fr": "Montpitol"
            },
            {
                "value": 36110,
                "label": "Saint-Mary",
                "label_ar": "القديس ماري",
                "label_fr": "Sainte Marie"
            },
            {
                "value": 36111,
                "label": "Le Castera",
                "label_ar": "لو كاستيرا",
                "label_fr": "Le Castera"
            },
            {
                "value": 36112,
                "label": "Allemans-du-Dropt",
                "label_ar": "أليمانس دو دروب",
                "label_fr": "Allemans-du-Dropt"
            },
            {
                "value": 36113,
                "label": "Sauveterre-de-Comminges",
                "label_ar": "Sauveterre-de-Comminges",
                "label_fr": "Sauveterre-de-Comminges"
            },
            {
                "value": 36114,
                "label": "Montbartier",
                "label_ar": "مونبارتييه",
                "label_fr": "Montbartier"
            },
            {
                "value": 36115,
                "label": "Corme-Ecluse",
                "label_ar": "كورمي إكلوز",
                "label_fr": "Corme-Ecluse"
            },
            {
                "value": 36116,
                "label": "Seyches",
                "label_ar": "سيش",
                "label_fr": "Seyches"
            },
            {
                "value": 36117,
                "label": "Vendays-Montalivet",
                "label_ar": "Vendays-Montalivet",
                "label_fr": "Vendays-Montalivet"
            },
            {
                "value": 36118,
                "label": "La Clotte",
                "label_ar": "لا كلوت",
                "label_fr": "La Clotte"
            },
            {
                "value": 36119,
                "label": "Varaize",
                "label_ar": "فارايزي",
                "label_fr": "Varaize"
            },
            {
                "value": 36120,
                "label": "Croix-Chapeau",
                "label_ar": "كروا تشابو",
                "label_fr": "Croix-Chapeau"
            },
            {
                "value": 36121,
                "label": "Saint-Meme-les-Carrieres",
                "label_ar": "سان ميمي ليه كاريير",
                "label_fr": "Saint-Meme-les-Carrières"
            },
            {
                "value": 36122,
                "label": "Melleran",
                "label_ar": "ميليران",
                "label_fr": "Melleran"
            },
            {
                "value": 36123,
                "label": "Soublecause",
                "label_ar": "Soublecause",
                "label_fr": "Soublecause"
            },
            {
                "value": 36124,
                "label": "Belmont-sur-Rance",
                "label_ar": "بلمونت سور رانس",
                "label_fr": "Belmont-sur-Rance"
            },
            {
                "value": 36125,
                "label": "Roqueseriere",
                "label_ar": "روكسيريير",
                "label_fr": "Roqueserière"
            },
            {
                "value": 36126,
                "label": "Frespech",
                "label_ar": "فريسبيك",
                "label_fr": "Frespech"
            },
            {
                "value": 36127,
                "label": "Rauzan",
                "label_ar": "روزان",
                "label_fr": "Rauzan"
            },
            {
                "value": 36128,
                "label": "Labretonie",
                "label_ar": "لابريتوني",
                "label_fr": "Labretonie"
            },
            {
                "value": 36129,
                "label": "Roquelaure-Saint-Aubin",
                "label_ar": "روكيلور سانت أوبين",
                "label_fr": "Roquelaure-Saint-Aubin"
            },
            {
                "value": 36130,
                "label": "Thaims",
                "label_ar": "التايمز",
                "label_fr": "Thaims"
            },
            {
                "value": 36131,
                "label": "Muron",
                "label_ar": "مورون",
                "label_fr": "Muron"
            },
            {
                "value": 36132,
                "label": "Toulx-Sainte-Croix",
                "label_ar": "تولكس سانت كروا",
                "label_fr": "Toulx-Sainte-Croix"
            },
            {
                "value": 36133,
                "label": "Camon",
                "label_ar": "كامون",
                "label_fr": "Camon"
            },
            {
                "value": 36134,
                "label": "Layrisse",
                "label_ar": "ليريس",
                "label_fr": "Layrisse"
            },
            {
                "value": 36135,
                "label": "Saint-Lieux-les-Lavaur",
                "label_ar": "سان ليوكس ليس لافور",
                "label_fr": "Saint-Lieux-les-Lavaur"
            },
            {
                "value": 36136,
                "label": "Carlus",
                "label_ar": "كارلس",
                "label_fr": "Carlus"
            },
            {
                "value": 36137,
                "label": "Saint-Pierre-de-Mons",
                "label_ar": "سان بيير دي مونس",
                "label_fr": "Saint-Pierre-de-Mons"
            },
            {
                "value": 36138,
                "label": "Pomarez",
                "label_ar": "بوماريز",
                "label_fr": "Pomarez"
            },
            {
                "value": 36139,
                "label": "Terves",
                "label_ar": "ترفيس",
                "label_fr": "Terves"
            },
            {
                "value": 36140,
                "label": "Gorre",
                "label_ar": "غوري",
                "label_fr": "Gorre"
            },
            {
                "value": 36141,
                "label": "Romegoux",
                "label_ar": "روميجوكس",
                "label_fr": "Romegoux"
            },
            {
                "value": 36142,
                "label": "Les Cars",
                "label_ar": "ليه كارز",
                "label_fr": "Les Cars"
            },
            {
                "value": 36143,
                "label": "La Riviere",
                "label_ar": "لا ريفيير",
                "label_fr": "La Rivière"
            },
            {
                "value": 36144,
                "label": "Saint-Lary-Soulan",
                "label_ar": "سان لاري سولان",
                "label_fr": "Saint-Lary-Soulan"
            },
            {
                "value": 36145,
                "label": "Cocumont",
                "label_ar": "كوكومونت",
                "label_fr": "Cocumont"
            },
            {
                "value": 36146,
                "label": "Jumilhac-le-Grand",
                "label_ar": "جوميلهاش لو جراند",
                "label_fr": "Jumilhac-le-Grand"
            },
            {
                "value": 36147,
                "label": "Loupiac-de-la-Reole",
                "label_ar": "لوباك دي لا ريول",
                "label_fr": "Loupiac-de-la-Reole"
            },
            {
                "value": 36148,
                "label": "Montesquieu",
                "label_ar": "مونتسكيو",
                "label_fr": "Montesquieu"
            },
            {
                "value": 36149,
                "label": "Lasserre",
                "label_ar": "لاسير",
                "label_fr": "Lasserre"
            },
            {
                "value": 36150,
                "label": "Villejoubert",
                "label_ar": "فيليجوبيرت",
                "label_fr": "Villejoubert"
            },
            {
                "value": 36151,
                "label": "Sablonceaux",
                "label_ar": "سابلونسو",
                "label_fr": "Sablonceaux"
            },
            {
                "value": 36152,
                "label": "Chail",
                "label_ar": "تشايل",
                "label_fr": "Chail"
            },
            {
                "value": 36153,
                "label": "Preguillac",
                "label_ar": "بريجويلاك",
                "label_fr": "Preguillac"
            },
            {
                "value": 36154,
                "label": "Aumagne",
                "label_ar": "أوماني",
                "label_fr": "Aumagne"
            },
            {
                "value": 36155,
                "label": "Luquet",
                "label_ar": "لوكيه",
                "label_fr": "Luquet"
            },
            {
                "value": 36156,
                "label": "Saint-Savin",
                "label_ar": "سان سافين",
                "label_fr": "Saint-Savin"
            },
            {
                "value": 36157,
                "label": "Vivonne",
                "label_ar": "فيفون",
                "label_fr": "Vivonne"
            },
            {
                "value": 36158,
                "label": "Saint-Androny",
                "label_ar": "سانت أندروني",
                "label_fr": "Saint-Androny"
            },
            {
                "value": 36159,
                "label": "Bouniagues",
                "label_ar": "بونياج",
                "label_fr": "Bouniagues"
            },
            {
                "value": 36160,
                "label": "Douville",
                "label_ar": "دوفيل",
                "label_fr": "Douville"
            },
            {
                "value": 36161,
                "label": "La Bastide",
                "label_ar": "لا باستيد",
                "label_fr": "La Bastide"
            },
            {
                "value": 36162,
                "label": "Mons",
                "label_ar": "مونس",
                "label_fr": "Mons"
            },
            {
                "value": 36163,
                "label": "Saint-Reverien",
                "label_ar": "سانت ريفيرين",
                "label_fr": "Saint-Reverien"
            },
            {
                "value": 36164,
                "label": "Dampierre",
                "label_ar": "دامبيير",
                "label_fr": "Dampierre"
            },
            {
                "value": 36165,
                "label": "Audelange",
                "label_ar": "أوديلانج",
                "label_fr": "Audelange"
            },
            {
                "value": 36166,
                "label": "Saulon-la-Chapelle",
                "label_ar": "ساولون لا شابيل",
                "label_fr": "Saulon-la-Chapelle"
            },
            {
                "value": 36167,
                "label": "Bolandoz",
                "label_ar": "بولاندوز",
                "label_fr": "Bolandoz"
            },
            {
                "value": 36168,
                "label": "Marigny",
                "label_ar": "ماريني",
                "label_fr": "Marigny"
            },
            {
                "value": 36169,
                "label": "Bulgneville",
                "label_ar": "بولجنيفيل",
                "label_fr": "Bulgneville"
            },
            {
                "value": 36170,
                "label": "Logelheim",
                "label_ar": "لوجلهيم",
                "label_fr": "Logelheim"
            },
            {
                "value": 36171,
                "label": "Flaxlanden",
                "label_ar": "كتان",
                "label_fr": "Flaxlanden"
            },
            {
                "value": 36172,
                "label": "Seppois-le-Haut",
                "label_ar": "سيبوا لو أوت",
                "label_fr": "Seppois-le-Haut"
            },
            {
                "value": 36173,
                "label": "Melsheim",
                "label_ar": "ميلشيم",
                "label_fr": "Melsheim"
            },
            {
                "value": 36174,
                "label": "Ligsdorf",
                "label_ar": "ليجسدورف",
                "label_fr": "Ligsdorf"
            },
            {
                "value": 36175,
                "label": "Serocourt",
                "label_ar": "سيروكورت",
                "label_fr": "Serocourt"
            },
            {
                "value": 36176,
                "label": "Domjevin",
                "label_ar": "دومجيفين",
                "label_fr": "Domjevin"
            },
            {
                "value": 36177,
                "label": "Commissey",
                "label_ar": "كوميسي",
                "label_fr": "Commissey"
            },
            {
                "value": 36178,
                "label": "Saint-Hippolyte",
                "label_ar": "سانت هيبوليت",
                "label_fr": "Saint-Hippolyte"
            },
            {
                "value": 36179,
                "label": "Chatillon-sur-Saone",
                "label_ar": "شاتيلون سور سون",
                "label_fr": "Châtillon-sur-Saône"
            },
            {
                "value": 36180,
                "label": "Sermamagny",
                "label_ar": "Sermamagny",
                "label_fr": "Sermamagny"
            },
            {
                "value": 36181,
                "label": "Virey-le-Grand",
                "label_ar": "فيري لو جراند",
                "label_fr": "Virey-le-Grand"
            },
            {
                "value": 36182,
                "label": "Planay",
                "label_ar": "بلاناي",
                "label_fr": "Planay"
            },
            {
                "value": 36183,
                "label": "Rothau",
                "label_ar": "روثاو",
                "label_fr": "Rothau"
            },
            {
                "value": 36184,
                "label": "Russ",
                "label_ar": "روس",
                "label_fr": "Russ"
            },
            {
                "value": 36185,
                "label": "Pfaffenheim",
                "label_ar": "بفافنهايم",
                "label_fr": "Pfaffenheim"
            },
            {
                "value": 36186,
                "label": "Wahlenheim",
                "label_ar": "Wahlenheim",
                "label_fr": "Wahlenheim"
            },
            {
                "value": 36187,
                "label": "Rossfeld",
                "label_ar": "روسفيلد",
                "label_fr": "Rossfeld"
            },
            {
                "value": 36188,
                "label": "Eblange",
                "label_ar": "إبلانج",
                "label_fr": "Eblange"
            },
            {
                "value": 36189,
                "label": "La Chapelle-aux-Bois",
                "label_ar": "لا شابيل أو بوا",
                "label_fr": "La Chapelle-aux-Bois"
            },
            {
                "value": 36190,
                "label": "Solgne",
                "label_ar": "Solgne",
                "label_fr": "Solgne"
            },
            {
                "value": 36191,
                "label": "Henridorff",
                "label_ar": "هنريدورف",
                "label_fr": "Henridorff"
            },
            {
                "value": 36192,
                "label": "Pleboulle",
                "label_ar": "بليبول",
                "label_fr": "Pléboulle"
            },
            {
                "value": 36193,
                "label": "Lanleff",
                "label_ar": "لانليف",
                "label_fr": "Lanleff"
            },
            {
                "value": 36194,
                "label": "Le Folgoet",
                "label_ar": "لو فولجويت",
                "label_fr": "Le Folgoet"
            },
            {
                "value": 36195,
                "label": "Malguenac",
                "label_ar": "مالغيناك",
                "label_fr": "Malguenac"
            },
            {
                "value": 36196,
                "label": "Landeda",
                "label_ar": "لانديدا",
                "label_fr": "Landeda"
            },
            {
                "value": 36197,
                "label": "Ploumoguer",
                "label_ar": "بلوموجوير",
                "label_fr": "Ploumoguer"
            },
            {
                "value": 36198,
                "label": "Corlay",
                "label_ar": "كورلاي",
                "label_fr": "Corlay"
            },
            {
                "value": 36199,
                "label": "Lampaul-Guimiliau",
                "label_ar": "لامبول غيميليو",
                "label_fr": "Lampaul-Guimiliau"
            },
            {
                "value": 36200,
                "label": "Cast",
                "label_ar": "المصبوب",
                "label_fr": "Jeter"
            },
            {
                "value": 36201,
                "label": "Rochefort-en-Terre",
                "label_ar": "روشفورت أون تير",
                "label_fr": "Rochefort-en-Terre"
            },
            {
                "value": 36202,
                "label": "Plounevez-Lochrist",
                "label_ar": "Plounevez-Lochrist",
                "label_fr": "Plounevez-Lochrist"
            },
            {
                "value": 36203,
                "label": "Graces",
                "label_ar": "النعم",
                "label_fr": "Grâces"
            },
            {
                "value": 36204,
                "label": "Prat",
                "label_ar": "برات",
                "label_fr": "Imbécile"
            },
            {
                "value": 36205,
                "label": "La Malhoure",
                "label_ar": "لا مالهور",
                "label_fr": "La Malhoure"
            },
            {
                "value": 36206,
                "label": "Garlan",
                "label_ar": "جارلان",
                "label_fr": "Garlan"
            },
            {
                "value": 36207,
                "label": "Mello",
                "label_ar": "ميلو",
                "label_fr": "Mello"
            },
            {
                "value": 36208,
                "label": "Dreuil-les-Amiens",
                "label_ar": "دريل ليه اميان",
                "label_fr": "Dreuil-les-Amiens"
            },
            {
                "value": 36209,
                "label": "Senuc",
                "label_ar": "سينوك",
                "label_fr": "Senuc"
            },
            {
                "value": 36210,
                "label": "Origny-le-Sec",
                "label_ar": "Origny-le-Sec",
                "label_fr": "Origny-le-Sec"
            },
            {
                "value": 36211,
                "label": "Saint-Germainmont",
                "label_ar": "سان جيرمانمونت",
                "label_fr": "Saint-Germainmont"
            },
            {
                "value": 36212,
                "label": "Leuvrigny",
                "label_ar": "لوفريني",
                "label_fr": "Leuvrigny"
            },
            {
                "value": 36213,
                "label": "Tauxieres-Mutry",
                "label_ar": "توكسيريس موتري",
                "label_fr": "Tauxières-Mutry"
            },
            {
                "value": 36214,
                "label": "Thilay",
                "label_ar": "ثيلاي",
                "label_fr": "Thilay"
            },
            {
                "value": 36215,
                "label": "Lavau",
                "label_ar": "لافاو",
                "label_fr": "Lavau"
            },
            {
                "value": 36216,
                "label": "Crancey",
                "label_ar": "كرانسي",
                "label_fr": "Crancey"
            },
            {
                "value": 36217,
                "label": "Sacy",
                "label_ar": "ساسي",
                "label_fr": "Sacy"
            },
            {
                "value": 36218,
                "label": "Mairieux",
                "label_ar": "ميريوكس",
                "label_fr": "Mairieux"
            },
            {
                "value": 36219,
                "label": "Marcelcave",
                "label_ar": "مارسيلكاف",
                "label_fr": "Marcelcave"
            },
            {
                "value": 36220,
                "label": "Rouy-le-Grand",
                "label_ar": "روي لو جراند",
                "label_fr": "Rouy-le-Grand"
            },
            {
                "value": 36221,
                "label": "Saint-Etienne-sur-Suippe",
                "label_ar": "سانت إتيان سور سويبي",
                "label_fr": "Saint-Étienne-sur-Suippe"
            },
            {
                "value": 36222,
                "label": "Bussy-Lettree",
                "label_ar": "بوسي ليتري",
                "label_fr": "Bussy-Lettree"
            },
            {
                "value": 36223,
                "label": "Soulaines-Dhuys",
                "label_ar": "Soulaines-Dhuys",
                "label_fr": "Soulaines-Dhuys"
            },
            {
                "value": 36224,
                "label": "Chateauvillain",
                "label_ar": "شاتوفيلان",
                "label_fr": "Châteauvillain"
            },
            {
                "value": 36225,
                "label": "Huiron",
                "label_ar": "هويرون",
                "label_fr": "Huiron"
            },
            {
                "value": 36226,
                "label": "Blaise-sous-Arzillieres",
                "label_ar": "Blaise-sous-Arzillieres",
                "label_fr": "Blaise-sous-Arzillières"
            },
            {
                "value": 36227,
                "label": "Lealvillers",
                "label_ar": "ليفيلرس",
                "label_fr": "Lealvillers"
            },
            {
                "value": 36228,
                "label": "Roquefort",
                "label_ar": "روكفور",
                "label_fr": "roquefort"
            },
            {
                "value": 36229,
                "label": "Arancou",
                "label_ar": "أرانكو",
                "label_fr": "Arancou"
            },
            {
                "value": 36230,
                "label": "Eyliac",
                "label_ar": "ايلياك",
                "label_fr": "Eyliac"
            },
            {
                "value": 36231,
                "label": "Azas",
                "label_ar": "آزاس",
                "label_fr": "Azas"
            },
            {
                "value": 36232,
                "label": "Saint-Lanne",
                "label_ar": "سانت لان",
                "label_fr": "Saint-Lanne"
            },
            {
                "value": 36233,
                "label": "Savigne",
                "label_ar": "سافيني",
                "label_fr": "Savigne"
            },
            {
                "value": 36234,
                "label": "Bussiere-Poitevine",
                "label_ar": "بوسير بويتفين",
                "label_fr": "Bussière-Poitevine"
            },
            {
                "value": 36235,
                "label": "Montpinier",
                "label_ar": "مونتبينييه",
                "label_fr": "Montpinier"
            },
            {
                "value": 36236,
                "label": "La Faye",
                "label_ar": "لا فاي",
                "label_fr": "La Faye"
            },
            {
                "value": 36237,
                "label": "Saint-Claud",
                "label_ar": "سان كلود",
                "label_fr": "Saint-Claud"
            },
            {
                "value": 36238,
                "label": "Angeac-Charente",
                "label_ar": "أنجيك شارينت",
                "label_fr": "Angeac-Charente"
            },
            {
                "value": 36239,
                "label": "Marestaing",
                "label_ar": "مارستاينج",
                "label_fr": "Marestaing"
            },
            {
                "value": 36240,
                "label": "Saint-Pierre-d'Irube",
                "label_ar": "سان بيير دوروب",
                "label_fr": "Saint-Pierre-d'Irube"
            },
            {
                "value": 36241,
                "label": "Vielle-Saint-Girons",
                "label_ar": "فيل سان جيرونز",
                "label_fr": "Vielle-Saint-Girons"
            },
            {
                "value": 36242,
                "label": "Cannet",
                "label_ar": "كانيت",
                "label_fr": "Cannet"
            },
            {
                "value": 36243,
                "label": "Lucq-de-Bearn",
                "label_ar": "لوك دي بيرن",
                "label_fr": "Lucq-de-Bearn"
            },
            {
                "value": 36244,
                "label": "Villeve",
                "label_ar": "فيليف",
                "label_fr": "Villeve"
            },
            {
                "value": 36245,
                "label": "Meauzac",
                "label_ar": "موزاك",
                "label_fr": "Meauzac"
            },
            {
                "value": 36246,
                "label": "Pranzac",
                "label_ar": "برانزاك",
                "label_fr": "Pranzac"
            },
            {
                "value": 36247,
                "label": "Bussiere-Galant",
                "label_ar": "بوسير جالانت",
                "label_fr": "Bussière-Galant"
            },
            {
                "value": 36248,
                "label": "Le Breuil",
                "label_ar": "لو بريل",
                "label_fr": "Le Breuil"
            },
            {
                "value": 36249,
                "label": "La Magdelaine-sur-Tarn",
                "label_ar": "لا ماجديلين سور تارن",
                "label_fr": "La Magdelaine-sur-Tarn"
            },
            {
                "value": 36250,
                "label": "Condac",
                "label_ar": "كونداك",
                "label_fr": "Condac"
            },
            {
                "value": 36251,
                "label": "Pey",
                "label_ar": "باي",
                "label_fr": "Pey"
            },
            {
                "value": 36252,
                "label": "Fons",
                "label_ar": "فونس",
                "label_fr": "Fons"
            },
            {
                "value": 36253,
                "label": "Belfort-du-Quercy",
                "label_ar": "بلفور دو كيرسي",
                "label_fr": "Belfort-du-Quercy"
            },
            {
                "value": 36254,
                "label": "Fougax-et-Barrineuf",
                "label_ar": "Fougax-et-Barrineuf",
                "label_fr": "Fougax-et-Barrineuf"
            },
            {
                "value": 36255,
                "label": "Niderhoff",
                "label_ar": "نيدرهوف",
                "label_fr": "Niderhoff"
            },
            {
                "value": 36256,
                "label": "Domevre-sur-Durbion",
                "label_ar": "دوميفر سور دوربيون",
                "label_fr": "Dômevre-sur-Durbion"
            },
            {
                "value": 36257,
                "label": "Fresse-sur-Moselle",
                "label_ar": "فريس سور موسيل",
                "label_fr": "Fresse-sur-Moselle"
            },
            {
                "value": 36258,
                "label": "Blamont",
                "label_ar": "بلامونت",
                "label_fr": "Blamont"
            },
            {
                "value": 36259,
                "label": "Ringendorf",
                "label_ar": "رينجندورف",
                "label_fr": "Ringendorf"
            },
            {
                "value": 36260,
                "label": "Wolxheim",
                "label_ar": "Wolxheim",
                "label_fr": "Wolxheim"
            },
            {
                "value": 36261,
                "label": "Jeanmenil",
                "label_ar": "جينمينيل",
                "label_fr": "Jeanmenil"
            },
            {
                "value": 36262,
                "label": "Adamswiller",
                "label_ar": "ادمزويلر",
                "label_fr": "Adamswiller"
            },
            {
                "value": 36263,
                "label": "Kientzheim",
                "label_ar": "كينتزهايم",
                "label_fr": "Kientzheim"
            },
            {
                "value": 36264,
                "label": "Niederschaeffolsheim",
                "label_ar": "نيدرشايفولشيم",
                "label_fr": "Niederschaeffolsheim"
            },
            {
                "value": 36265,
                "label": "Juville",
                "label_ar": "جوفيل",
                "label_fr": "Juville"
            },
            {
                "value": 36266,
                "label": "Oberlauterbach",
                "label_ar": "أوبرلاوترباخ",
                "label_fr": "Oberlauterbach"
            },
            {
                "value": 36267,
                "label": "Melay",
                "label_ar": "ميلاي",
                "label_fr": "Melay"
            },
            {
                "value": 36268,
                "label": "Savigny-le-Sec",
                "label_ar": "Savigny-le-Sec",
                "label_fr": "Savigny-le-Sec"
            },
            {
                "value": 36269,
                "label": "Villers-sur-Port",
                "label_ar": "فيليرز سور بورت",
                "label_fr": "Villers-sur-Port"
            },
            {
                "value": 36270,
                "label": "Champvans",
                "label_ar": "Champvans",
                "label_fr": "Champvans"
            },
            {
                "value": 36271,
                "label": "Lugny-les-Charolles",
                "label_ar": "لوجني ليه شارول",
                "label_fr": "Lugny-les-Charolles"
            },
            {
                "value": 36272,
                "label": "Belrupt-en-Verdunois",
                "label_ar": "بيلروب أون فردونوا",
                "label_fr": "Belrupt-en-Verdunois"
            },
            {
                "value": 36273,
                "label": "Sanchey",
                "label_ar": "سانشي",
                "label_fr": "Sanchey"
            },
            {
                "value": 36274,
                "label": "Waldwisse",
                "label_ar": "والدويس",
                "label_fr": "Waldwisse"
            },
            {
                "value": 36275,
                "label": "Docelles",
                "label_ar": "Docelles",
                "label_fr": "Docelles"
            },
            {
                "value": 36276,
                "label": "Durrenentzen",
                "label_ar": "Durrenentzen",
                "label_fr": "Durrenentzen"
            },
            {
                "value": 36277,
                "label": "Rohr",
                "label_ar": "روهر",
                "label_fr": "Rohr"
            },
            {
                "value": 36278,
                "label": "Lafox",
                "label_ar": "لافوكس",
                "label_fr": "Lafox"
            },
            {
                "value": 36279,
                "label": "Belhade",
                "label_ar": "بلحادي",
                "label_fr": "Belhade"
            },
            {
                "value": 36280,
                "label": "Meilhac",
                "label_ar": "ميلاك",
                "label_fr": "Meilhac"
            },
            {
                "value": 36281,
                "label": "Lacroix-Falgarde",
                "label_ar": "لاكروا فالجاردي",
                "label_fr": "Lacroix-Falgarde"
            },
            {
                "value": 36282,
                "label": "La Loubiere",
                "label_ar": "لا لوبير",
                "label_fr": "La Loubière"
            },
            {
                "value": 36283,
                "label": "Escorneboeuf",
                "label_ar": "اسكورنبوف",
                "label_fr": "Escorneboeuf"
            },
            {
                "value": 36284,
                "label": "Tosse",
                "label_ar": "توسي",
                "label_fr": "Tosse"
            },
            {
                "value": 36285,
                "label": "Momy",
                "label_ar": "أمي",
                "label_fr": "Momy"
            },
            {
                "value": 36286,
                "label": "Fosses-et-Baleyssac",
                "label_ar": "Fosses-et-Baleyssac",
                "label_fr": "Fosses-et-Baleyssac"
            },
            {
                "value": 36287,
                "label": "Montiron",
                "label_ar": "مونتيرون",
                "label_fr": "Montiron"
            },
            {
                "value": 36288,
                "label": "Cabara",
                "label_ar": "كابارا",
                "label_fr": "Cabara"
            },
            {
                "value": 36289,
                "label": "Merignas",
                "label_ar": "ميريناس",
                "label_fr": "Merignas"
            },
            {
                "value": 36290,
                "label": "Suzanne",
                "label_ar": "سوزان",
                "label_fr": "Suzanne"
            },
            {
                "value": 36291,
                "label": "Nieul-les-Saintes",
                "label_ar": "نيول ليه سينتس",
                "label_fr": "Nieul-les-Saintes"
            },
            {
                "value": 36292,
                "label": "Le Fossat",
                "label_ar": "لو فوسات",
                "label_fr": "Le Fossat"
            },
            {
                "value": 36293,
                "label": "Cayrac",
                "label_ar": "كيراك",
                "label_fr": "Cayrac"
            },
            {
                "value": 36294,
                "label": "La Rouquette",
                "label_ar": "لا روكيت",
                "label_fr": "La Rouquette"
            },
            {
                "value": 36295,
                "label": "La Ronde",
                "label_ar": "لا روند",
                "label_fr": "La Ronde"
            },
            {
                "value": 36296,
                "label": "Cieurac",
                "label_ar": "سيوراك",
                "label_fr": "Cieurac"
            },
            {
                "value": 36297,
                "label": "Fontanes",
                "label_ar": "فونتانيس",
                "label_fr": "Fontanes"
            },
            {
                "value": 36298,
                "label": "Gurs",
                "label_ar": "جورس",
                "label_fr": "Gurs"
            },
            {
                "value": 36299,
                "label": "Lamothe-Landerron",
                "label_ar": "لاموث لاندرون",
                "label_fr": "Lamothe-Landerron"
            },
            {
                "value": 36300,
                "label": "Chantecorps",
                "label_ar": "شانتكوربس",
                "label_fr": "Chantecorps"
            },
            {
                "value": 36301,
                "label": "Castagnede",
                "label_ar": "كاستاغنيدي",
                "label_fr": "Castagnède"
            },
            {
                "value": 36302,
                "label": "Caudecoste",
                "label_ar": "Caudecoste",
                "label_fr": "Caudecoste"
            },
            {
                "value": 36303,
                "label": "Vignonet",
                "label_ar": "فيجنونت",
                "label_fr": "Vignonet"
            },
            {
                "value": 36304,
                "label": "Saint-Martin-Sainte-Catherine",
                "label_ar": "سانت مارتن سانت كاترين",
                "label_fr": "Saint-Martin-Sainte-Catherine"
            },
            {
                "value": 36305,
                "label": "Trouley-Labarthe",
                "label_ar": "ترولي لابارث",
                "label_fr": "Trouley-Labarthe"
            },
            {
                "value": 36306,
                "label": "Plaisance",
                "label_ar": "السعادة",
                "label_fr": "Plaisance"
            },
            {
                "value": 36307,
                "label": "Saint-Martin-d'Arrossa",
                "label_ar": "سان مارتن دروسا",
                "label_fr": "Saint-Martin-d'Arrossa"
            },
            {
                "value": 36308,
                "label": "Arberats-Sillegue",
                "label_ar": "أربيراتس سيليج",
                "label_fr": "Arberats-Sillegue"
            },
            {
                "value": 36309,
                "label": "Vielle-Tursan",
                "label_ar": "فيل تورسان",
                "label_fr": "Vielle-Tursan"
            },
            {
                "value": 36310,
                "label": "Misson",
                "label_ar": "ميسون",
                "label_fr": "Misson"
            },
            {
                "value": 36311,
                "label": "Aubie-et-Espessas",
                "label_ar": "Aubie-et-Espessas",
                "label_fr": "Aubie-et-Espessas"
            },
            {
                "value": 36312,
                "label": "Hautefort",
                "label_ar": "أوتفورت",
                "label_fr": "Hautefort"
            },
            {
                "value": 36313,
                "label": "Han-sur-Nied",
                "label_ar": "هان سور نيد",
                "label_fr": "Han-sur-Nied"
            },
            {
                "value": 36314,
                "label": "Marsilly",
                "label_ar": "مارسيلي",
                "label_fr": "Marsilly"
            },
            {
                "value": 36315,
                "label": "Latille",
                "label_ar": "لاتيل",
                "label_fr": "Latille"
            },
            {
                "value": 36316,
                "label": "Prefontaines",
                "label_ar": "بريفونتينيس",
                "label_fr": "Préfontaines"
            },
            {
                "value": 36317,
                "label": "Ruitz",
                "label_ar": "روتز",
                "label_fr": "Ruitz"
            },
            {
                "value": 36318,
                "label": "Plerneuf",
                "label_ar": "بليرنوف",
                "label_fr": "Plerneuf"
            },
            {
                "value": 36319,
                "label": "Saint-Gilles-les-Bois",
                "label_ar": "سان جيل ليه بوا",
                "label_fr": "Saint-Gilles-les-Bois"
            },
            {
                "value": 36320,
                "label": "Pommerit-Jaudy",
                "label_ar": "بوميريت جودي",
                "label_fr": "Pommerit-Jaudy"
            },
            {
                "value": 36321,
                "label": "Saint-Brandan",
                "label_ar": "سانت براندان",
                "label_fr": "Saint-Brandan"
            },
            {
                "value": 36322,
                "label": "Orsonville",
                "label_ar": "أورسونفيل",
                "label_fr": "Orsonville"
            },
            {
                "value": 36323,
                "label": "Sabatier",
                "label_ar": "ساباتير",
                "label_fr": "Sabatier"
            },
            {
                "value": 36324,
                "label": "Eletot",
                "label_ar": "إليت",
                "label_fr": "Eletot"
            },
            {
                "value": 36325,
                "label": "Sainte-Croix-aux-Mines",
                "label_ar": "Sainte-Croix-aux-Mines",
                "label_fr": "Sainte-Croix-aux-Mines"
            },
            {
                "value": 36326,
                "label": "Meymac",
                "label_ar": "ميماك",
                "label_fr": "Meymac"
            },
            {
                "value": 36327,
                "label": "Milon-la-Chapelle",
                "label_ar": "ميلون لا شابيل",
                "label_fr": "Milon-la-Chapelle"
            },
            {
                "value": 36328,
                "label": "Renault",
                "label_ar": "رينو",
                "label_fr": "Renault"
            },
            {
                "value": 36329,
                "label": "Laas",
                "label_ar": "لاس",
                "label_fr": "Laas"
            },
            {
                "value": 36330,
                "label": "Champcenest",
                "label_ar": "تشامبينست",
                "label_fr": "Champcenest"
            },
            {
                "value": 36331,
                "label": "San-Giuliano",
                "label_ar": "سان جوليانو",
                "label_fr": "San-Giuliano"
            },
            {
                "value": 36332,
                "label": "Bretigny",
                "label_ar": "بريتيجني",
                "label_fr": "Brétigny"
            },
            {
                "value": 36333,
                "label": "Epinoy",
                "label_ar": "Epinoy",
                "label_fr": "Epinoy"
            },
            {
                "value": 36334,
                "label": "Les Bordes-sur-Lez",
                "label_ar": "ليه بورد سور ليز",
                "label_fr": "Les Bordes-sur-Lez"
            },
            {
                "value": 36335,
                "label": "Plateau",
                "label_ar": "هضبة",
                "label_fr": "Plateau"
            },
            {
                "value": 36336,
                "label": "Vendeville",
                "label_ar": "فيندفيل",
                "label_fr": "Vendeville"
            },
            {
                "value": 36337,
                "label": "Jeux-les-Bard",
                "label_ar": "Jeux-les-Bard",
                "label_fr": "Jeux-les-Bard"
            },
            {
                "value": 36338,
                "label": "Saint-Paul",
                "label_ar": "القديس بول",
                "label_fr": "Saint Paul"
            },
            {
                "value": 36339,
                "label": "Tredarzec",
                "label_ar": "تريدارزيك",
                "label_fr": "Tredarzec"
            },
            {
                "value": 36340,
                "label": "Saint-Jean-Kerdaniel",
                "label_ar": "القديس جان كردانيال",
                "label_fr": "Saint-Jean-Kerdaniel"
            },
            {
                "value": 36341,
                "label": "Bouquet",
                "label_ar": "باقة أزهار",
                "label_fr": "Bouquet"
            },
            {
                "value": 36342,
                "label": "Souzy-la-Briche",
                "label_ar": "سوزي لا بريش",
                "label_fr": "Souzy-la-Briche"
            },
            {
                "value": 36343,
                "label": "Saint-Malo-de-Guersac",
                "label_ar": "سان مالو دي جيرساك",
                "label_fr": "Saint-Malo-de-Guersac"
            },
            {
                "value": 36344,
                "label": "Pretot-Vicquemare",
                "label_ar": "Pretot-Vicquemare",
                "label_fr": "Pretot-Vicquemare"
            },
            {
                "value": 36345,
                "label": "Griesbach-au-Val",
                "label_ar": "جريسباتش أو فال",
                "label_fr": "Griesbach-au-Val"
            },
            {
                "value": 36346,
                "label": "Saint-Senoch",
                "label_ar": "سانت سينوك",
                "label_fr": "Saint-Senoch"
            },
            {
                "value": 36347,
                "label": "Montagrier",
                "label_ar": "مونتاجرييه",
                "label_fr": "Montagrier"
            },
            {
                "value": 36348,
                "label": "Ormersviller",
                "label_ar": "أورميرسفيلر",
                "label_fr": "Ormersviller"
            },
            {
                "value": 36349,
                "label": "Schorbach",
                "label_ar": "شورباتش",
                "label_fr": "Schorbach"
            },
            {
                "value": 36350,
                "label": "Trouville",
                "label_ar": "تروفيل",
                "label_fr": "Trouville"
            },
            {
                "value": 36351,
                "label": "Saint-Theodorit",
                "label_ar": "سانت ثيودوريت",
                "label_fr": "Saint-Théodorit"
            },
            {
                "value": 36352,
                "label": "La Poste",
                "label_ar": "لا بوست",
                "label_fr": "La Poste"
            },
            {
                "value": 36353,
                "label": "Meisenthal",
                "label_ar": "ميسينثال",
                "label_fr": "Meisenthal"
            },
            {
                "value": 36354,
                "label": "Bettviller",
                "label_ar": "بيتفيلر",
                "label_fr": "Bettviller"
            },
            {
                "value": 36355,
                "label": "Giron",
                "label_ar": "جيرون",
                "label_fr": "Giron"
            },
            {
                "value": 36356,
                "label": "Leval",
                "label_ar": "ليفال",
                "label_fr": "Leval"
            },
            {
                "value": 36357,
                "label": "La Defense",
                "label_ar": "لا ديفينس",
                "label_fr": "La Défense"
            },
            {
                "value": 36358,
                "label": "Doncourt-les-Conflans",
                "label_ar": "دونكورت لي كونفلان",
                "label_fr": "Doncourt-les-Conflans"
            },
            {
                "value": 36359,
                "label": "Haute-Vigneulles",
                "label_ar": "Haute-Vigneulles",
                "label_fr": "Haute-Vigneulles"
            },
            {
                "value": 36360,
                "label": "Neufchef",
                "label_ar": "نيوفشيف",
                "label_fr": "Neufchef"
            },
            {
                "value": 36361,
                "label": "Tressange",
                "label_ar": "تريسانج",
                "label_fr": "Tressange"
            },
            {
                "value": 36362,
                "label": "Marange-Zondrange",
                "label_ar": "مارانج زوندرانج",
                "label_fr": "Marange-Zondrange"
            },
            {
                "value": 36363,
                "label": "Brouviller",
                "label_ar": "بروفيلر",
                "label_fr": "Brouviller"
            },
            {
                "value": 36364,
                "label": "Argancy",
                "label_ar": "أرجانسي",
                "label_fr": "Argancy"
            },
            {
                "value": 36365,
                "label": "Koeur-la-Petite",
                "label_ar": "Koeur-la-Petite",
                "label_fr": "Koeur-la-Petite"
            },
            {
                "value": 36366,
                "label": "Aumont-Aubrac",
                "label_ar": "اومون اوبراك",
                "label_fr": "Aumont-Aubrac"
            },
            {
                "value": 36367,
                "label": "Villers-sur-Meuse",
                "label_ar": "فيليرز سور ميوز",
                "label_fr": "Villers-sur-Meuse"
            },
            {
                "value": 36368,
                "label": "Beaulieu-sous-Parthenay",
                "label_ar": "بوليو سو بارثيناي",
                "label_fr": "Beaulieu-sous-Parthenay"
            },
            {
                "value": 36369,
                "label": "Misse",
                "label_ar": "ميس",
                "label_fr": "Misse"
            },
            {
                "value": 36370,
                "label": "Loubigne",
                "label_ar": "لوبيني",
                "label_fr": "Loubigne"
            },
            {
                "value": 36371,
                "label": "Landepereuse",
                "label_ar": "Landepereuse",
                "label_fr": "Landepereuse"
            },
            {
                "value": 36372,
                "label": "La Chapelle-Gaudin",
                "label_ar": "لا شابيل جاودين",
                "label_fr": "La Chapelle-Gaudin"
            },
            {
                "value": 36373,
                "label": "Colombier-Fontaine",
                "label_ar": "كولومبييه فونتين",
                "label_fr": "Colombier-Fontaine"
            },
            {
                "value": 36374,
                "label": "Bourg-sous-Chatelet",
                "label_ar": "برج صوص شاتيليه",
                "label_fr": "Bourg-sous-Châtelet"
            },
            {
                "value": 36375,
                "label": "Denney",
                "label_ar": "ديني",
                "label_fr": "Denney"
            },
            {
                "value": 36376,
                "label": "Vandoncourt",
                "label_ar": "فاندونكورت",
                "label_fr": "Vandoncourt"
            },
            {
                "value": 36377,
                "label": "Bessoncourt",
                "label_ar": "بيسونكور",
                "label_fr": "Bessoncourt"
            },
            {
                "value": 36378,
                "label": "Tavey",
                "label_ar": "تافي",
                "label_fr": "Tavey"
            },
            {
                "value": 36379,
                "label": "Itxassou",
                "label_ar": "Itxassou",
                "label_fr": "Itxassou"
            },
            {
                "value": 36380,
                "label": "Igon",
                "label_ar": "ذهبت",
                "label_fr": "Igon"
            },
            {
                "value": 36381,
                "label": "Moumour",
                "label_ar": "مومور",
                "label_fr": "Moumour"
            },
            {
                "value": 36382,
                "label": "Corbere-Aberes",
                "label_ar": "كوربير أبريس",
                "label_fr": "Corbère-Aberes"
            },
            {
                "value": 36383,
                "label": "Saint-Sulpice-la-Foret",
                "label_ar": "سان سولبيس لا فوريه",
                "label_fr": "Saint-Sulpice-la-Foret"
            },
            {
                "value": 36384,
                "label": "Noisy-Rudignon",
                "label_ar": "صاخبة-روديجنون",
                "label_fr": "Noisy-Rudignon"
            },
            {
                "value": 36385,
                "label": "Pietralba",
                "label_ar": "بيترالبا",
                "label_fr": "Pietralba"
            },
            {
                "value": 36386,
                "label": "Ota",
                "label_ar": "اوتا",
                "label_fr": "Ota"
            },
            {
                "value": 36387,
                "label": "Auffreville-Brasseuil",
                "label_ar": "Auffreville-Brasseuil",
                "label_fr": "Auffreville-Brasseuil"
            },
            {
                "value": 36388,
                "label": "Mathieu",
                "label_ar": "ماتيو",
                "label_fr": "Mathieu"
            },
            {
                "value": 36389,
                "label": "Auvillers-les-Forges",
                "label_ar": "اوفيلر ليه فورج",
                "label_fr": "Auvillers-les-Forges"
            },
            {
                "value": 36390,
                "label": "Biot",
                "label_ar": "بيوت",
                "label_fr": "Biot"
            },
            {
                "value": 36391,
                "label": "Longnes",
                "label_ar": "لونجين",
                "label_fr": "Longnes"
            },
            {
                "value": 36392,
                "label": "Eth",
                "label_ar": "Eth",
                "label_fr": "Eth"
            },
            {
                "value": 36393,
                "label": "Rocher",
                "label_ar": "روشيه",
                "label_fr": "Rocher"
            },
            {
                "value": 36394,
                "label": "Neuvy",
                "label_ar": "نيوفي",
                "label_fr": "Neuvy"
            },
            {
                "value": 36395,
                "label": "Lens-Lestang",
                "label_ar": "لنس ليستانغ",
                "label_fr": "Lens-Lestang"
            },
            {
                "value": 36396,
                "label": "Meillonnas",
                "label_ar": "Meillonnas",
                "label_fr": "Meillonnas"
            },
            {
                "value": 36397,
                "label": "Bailly-le-Franc",
                "label_ar": "بايلي لو فرنك",
                "label_fr": "Bailly-le-Franc"
            },
            {
                "value": 36398,
                "label": "Saint-Leger-aux-Bois",
                "label_ar": "سان ليجيه أو بوا",
                "label_fr": "Saint-Léger-aux-Bois"
            },
            {
                "value": 36399,
                "label": "Saint-Pierre-le-Vieux",
                "label_ar": "سان بيير لو فيو",
                "label_fr": "Saint-Pierre-le-Vieux"
            },
            {
                "value": 36400,
                "label": "Douy-la-Ramee",
                "label_ar": "دوي لا رامي",
                "label_fr": "Douy-la-Ramée"
            },
            {
                "value": 36401,
                "label": "Vigny",
                "label_ar": "زاهي",
                "label_fr": "Vigny"
            },
            {
                "value": 36402,
                "label": "La Roche",
                "label_ar": "لاروش",
                "label_fr": "La Roche"
            },
            {
                "value": 36403,
                "label": "Piazza",
                "label_ar": "ساحة",
                "label_fr": "Piazza"
            },
            {
                "value": 36404,
                "label": "Cervione",
                "label_ar": "سيرفيوني",
                "label_fr": "Cervione"
            },
            {
                "value": 36405,
                "label": "Vico",
                "label_ar": "فيكو",
                "label_fr": "Vico"
            },
            {
                "value": 36406,
                "label": "Le Bleymard",
                "label_ar": "لو بليمار",
                "label_fr": "Le Bleymard"
            },
            {
                "value": 36407,
                "label": "Labastide-de-Virac",
                "label_ar": "لاباستيد دي فيراك",
                "label_fr": "Labastide-de-Virac"
            },
            {
                "value": 36408,
                "label": "Porto",
                "label_ar": "بورتو",
                "label_fr": "Porto"
            },
            {
                "value": 36409,
                "label": "Ance",
                "label_ar": "تعصب",
                "label_fr": "Ance"
            },
            {
                "value": 36410,
                "label": "Ferriere-Larcon",
                "label_ar": "Ferriere-Larcon",
                "label_fr": "Ferrière-Larcon"
            },
            {
                "value": 36411,
                "label": "Poix",
                "label_ar": "بويكس",
                "label_fr": "Poix"
            },
            {
                "value": 36412,
                "label": "Dunes",
                "label_ar": "كثبان",
                "label_fr": "Dunes"
            },
            {
                "value": 36413,
                "label": "Cousolre",
                "label_ar": "كوسولر",
                "label_fr": "Cousolre"
            },
            {
                "value": 36414,
                "label": "Bord",
                "label_ar": "بورد",
                "label_fr": "Bord"
            },
            {
                "value": 36415,
                "label": "Grosseto-Prugna",
                "label_ar": "غروسيتو بروغنا",
                "label_fr": "Grosseto-Prugna"
            },
            {
                "value": 36416,
                "label": "Crocq",
                "label_ar": "كروك",
                "label_fr": "Crocq"
            },
            {
                "value": 36417,
                "label": "Villemur",
                "label_ar": "فيلمور",
                "label_fr": "Villemur"
            },
            {
                "value": 36418,
                "label": "Bray",
                "label_ar": "نهيق",
                "label_fr": "Braire"
            },
            {
                "value": 36419,
                "label": "Signy",
                "label_ar": "سيني",
                "label_fr": "Signy"
            },
            {
                "value": 36420,
                "label": "Cadours",
                "label_ar": "كوادر",
                "label_fr": "Cadours"
            },
            {
                "value": 36421,
                "label": "Montastruc-de-Salies",
                "label_ar": "مونتاستروك دي ساليس",
                "label_fr": "Montastruc-de-Salies"
            },
            {
                "value": 36422,
                "label": "Rouvroy",
                "label_ar": "روفروي",
                "label_fr": "Rouvroy"
            },
            {
                "value": 36423,
                "label": "Mons",
                "label_ar": "مونس",
                "label_fr": "Mons"
            },
            {
                "value": 36424,
                "label": "Aubigny-en-Laonnois",
                "label_ar": "Aubigny-en-Laonnois",
                "label_fr": "Aubigny-en-Laonnois"
            },
            {
                "value": 36425,
                "label": "Montaigu",
                "label_ar": "مونتايجو",
                "label_fr": "Montaigu"
            },
            {
                "value": 36426,
                "label": "Orangis",
                "label_ar": "أورانجيس",
                "label_fr": "Orangis"
            },
            {
                "value": 36427,
                "label": "Neuville",
                "label_ar": "نوفيل",
                "label_fr": "Neuville"
            },
            {
                "value": 36428,
                "label": "Paris 17 Batignolles-Monceau",
                "label_ar": "باريس 17 باتينيول مونسو",
                "label_fr": "Paris 17 Batignolles-Monceau"
            },
            {
                "value": 36429,
                "label": "Castiglione",
                "label_ar": "كاستيجليون",
                "label_fr": "Castiglione"
            },
            {
                "value": 36430,
                "label": "Vaillant",
                "label_ar": "فيلان",
                "label_fr": "Vaillant"
            },
            {
                "value": 36431,
                "label": "Englos",
                "label_ar": "إنجلوس",
                "label_fr": "Englos"
            },
            {
                "value": 36432,
                "label": "Roussy",
                "label_ar": "روسي",
                "label_fr": "Roussy"
            },
            {
                "value": 36433,
                "label": "Merenvielle",
                "label_ar": "Merenvielle",
                "label_fr": "Merenvielle"
            },
            {
                "value": 36434,
                "label": "Ecoche",
                "label_ar": "إيكوش",
                "label_fr": "Ecoche"
            },
            {
                "value": 36435,
                "label": "Gousse",
                "label_ar": "جوس",
                "label_fr": "Gousse"
            },
            {
                "value": 36436,
                "label": "Savy",
                "label_ar": "سافى",
                "label_fr": "Savy"
            },
            {
                "value": 36437,
                "label": "Les Palais",
                "label_ar": "ليه باليه",
                "label_fr": "Les Palais"
            },
            {
                "value": 36438,
                "label": "Liernais",
                "label_ar": "ليرنيه",
                "label_fr": "Liernais"
            },
            {
                "value": 36439,
                "label": "Gouaux",
                "label_ar": "جوو",
                "label_fr": "Gouaux"
            },
            {
                "value": 36440,
                "label": "Chasseneuil",
                "label_ar": "شاسينويل",
                "label_fr": "Chasseneuil"
            },
            {
                "value": 36441,
                "label": "Cros-de-Ronesque",
                "label_ar": "كروس دي رونيسك",
                "label_fr": "Cros-de-Ronesque"
            },
            {
                "value": 36442,
                "label": "Liart",
                "label_ar": "ليارت",
                "label_fr": "Liart"
            },
            {
                "value": 36443,
                "label": "Precey",
                "label_ar": "بريسي",
                "label_fr": "Precey"
            },
            {
                "value": 36444,
                "label": "Vienville",
                "label_ar": "فينفيل",
                "label_fr": "Vienville"
            },
            {
                "value": 36445,
                "label": "Saint-Nauphary",
                "label_ar": "سانت نوفاري",
                "label_fr": "Saint-Nauphary"
            },
            {
                "value": 36446,
                "label": "Furchhausen",
                "label_ar": "فورشهاوزن",
                "label_fr": "Furchhausen"
            },
            {
                "value": 36447,
                "label": "Varennes",
                "label_ar": "فارينيس",
                "label_fr": "Varennes"
            },
            {
                "value": 36448,
                "label": "Le Val-dAjol",
                "label_ar": "لو فال داجول",
                "label_fr": "Le Val-d'Ajol"
            },
            {
                "value": 36449,
                "label": "Châteauponsac",
                "label_ar": "شاتوبونساك",
                "label_fr": "Châteauponsac"
            },
            {
                "value": 36450,
                "label": "Fregimont",
                "label_ar": "فريغيمونت",
                "label_fr": "Fregimont"
            },
            {
                "value": 36451,
                "label": "Munchhouse",
                "label_ar": "مونشهاوس",
                "label_fr": "Munchhouse"
            },
            {
                "value": 36452,
                "label": "Bagnols",
                "label_ar": "Bagnols",
                "label_fr": "Bagnols"
            },
            {
                "value": 36453,
                "label": "Aymeries",
                "label_ar": "ايميريس",
                "label_fr": "Aymeries"
            },
            {
                "value": 36454,
                "label": "Perols",
                "label_ar": "بيرولس",
                "label_fr": "Pérols"
            },
            {
                "value": 36455,
                "label": "Faches",
                "label_ar": "فاشيس",
                "label_fr": "Faches"
            },
            {
                "value": 36456,
                "label": "Heuchin",
                "label_ar": "هيوتشين",
                "label_fr": "Heuchin"
            },
            {
                "value": 36457,
                "label": "Lastic",
                "label_ar": "لاستيك",
                "label_fr": "Lastic"
            },
            {
                "value": 36458,
                "label": "Hucqueliers",
                "label_ar": "Hucqueliers",
                "label_fr": "Hucqueliers"
            },
            {
                "value": 36459,
                "label": "Mortagne",
                "label_ar": "مورتان",
                "label_fr": "Mortagne"
            },
            {
                "value": 36460,
                "label": "Verne",
                "label_ar": "فيرن",
                "label_fr": "Verne"
            },
            {
                "value": 36461,
                "label": "Grandpre",
                "label_ar": "الجد",
                "label_fr": "Grandpré"
            },
            {
                "value": 36462,
                "label": "Parsac",
                "label_ar": "بارساك",
                "label_fr": "Parsac"
            },
            {
                "value": 36463,
                "label": "Coupigny",
                "label_ar": "كوبيني",
                "label_fr": "Coupigny"
            },
            {
                "value": 36464,
                "label": "Pionsat",
                "label_ar": "Pionsat",
                "label_fr": "Pionsat"
            },
            {
                "value": 36465,
                "label": "Venant",
                "label_ar": "فينانت",
                "label_fr": "Venant"
            },
            {
                "value": 36466,
                "label": "Sabres",
                "label_ar": "سيابر",
                "label_fr": "Sabres"
            },
            {
                "value": 36467,
                "label": "Sainte-Marie-aux-Chenes",
                "label_ar": "سانت ماري أو شينيس",
                "label_fr": "Sainte-Marie-aux-Chenes"
            },
            {
                "value": 36468,
                "label": "Jumelles",
                "label_ar": "جوميل",
                "label_fr": "Jumelles"
            },
            {
                "value": 36469,
                "label": "Berneval-le-Grand",
                "label_ar": "برنيفال لو جراند",
                "label_fr": "Berneval-le-Grand"
            },
            {
                "value": 36470,
                "label": "Thizy-les-Bourgs",
                "label_ar": "تيزي لي بورج",
                "label_fr": "Thizy-les-Bourgs"
            },
            {
                "value": 36471,
                "label": "Marnand",
                "label_ar": "مارناند",
                "label_fr": "Marnand"
            },
            {
                "value": 36472,
                "label": "Ternand",
                "label_ar": "تيرناند",
                "label_fr": "Ternand"
            },
            {
                "value": 36473,
                "label": "Hochstatt",
                "label_ar": "هوشستات",
                "label_fr": "Hochstatt"
            },
            {
                "value": 36474,
                "label": "Ferrette",
                "label_ar": "فيريت",
                "label_fr": "Ferrette"
            },
            {
                "value": 36475,
                "label": "Savoie",
                "label_ar": "سافوا",
                "label_fr": "Savoie"
            },
            {
                "value": 36476,
                "label": "Chevremont",
                "label_ar": "شيفريمونت",
                "label_fr": "Chevremont"
            },
            {
                "value": 36477,
                "label": "Sorel-en-Vimeu",
                "label_ar": "سوريل أون فيميو",
                "label_fr": "Sorel-en-Vimeu"
            },
            {
                "value": 36478,
                "label": "Fournier",
                "label_ar": "فورنييه",
                "label_fr": "Fournier"
            },
            {
                "value": 36479,
                "label": "Saint-Simon",
                "label_ar": "سان سيمون",
                "label_fr": "Saint-Simon"
            },
            {
                "value": 36480,
                "label": "Mondetour",
                "label_ar": "مونديتور",
                "label_fr": "Mondetour"
            },
            {
                "value": 36481,
                "label": "Aragon",
                "label_ar": "أراجون",
                "label_fr": "Aragon"
            },
            {
                "value": 36482,
                "label": "Nonneville",
                "label_ar": "نونفيل",
                "label_fr": "Nonneville"
            },
            {
                "value": 36483,
                "label": "Cravans",
                "label_ar": "كرافان",
                "label_fr": "Cravans"
            },
            {
                "value": 36484,
                "label": "La Copechagniere",
                "label_ar": "لا كوبيشاجنيير",
                "label_fr": "La Copechagnière"
            },
            {
                "value": 36485,
                "label": "La Poiteviniere",
                "label_ar": "لا بواتفينير",
                "label_fr": "La Poitevinière"
            },
            {
                "value": 36486,
                "label": "Sevres-Anxaumont",
                "label_ar": "سيفرس أنكسومونت",
                "label_fr": "Sèvres-Anxaumont"
            },
            {
                "value": 36487,
                "label": "Sceaux-du-Gatinais",
                "label_ar": "Sceaux-du-Gatinais",
                "label_fr": "Sceaux-du-Gatinais"
            },
            {
                "value": 36488,
                "label": "Gumbrechtshoffen",
                "label_ar": "جومبريشتشوفن",
                "label_fr": "Gumbrechtshoffen"
            },
            {
                "value": 36489,
                "label": "Hohfrankenheim",
                "label_ar": "Hohfrankenheim",
                "label_fr": "Hohfrankenheim"
            },
            {
                "value": 36490,
                "label": "Valmy",
                "label_ar": "فالمي",
                "label_fr": "Valmy"
            },
            {
                "value": 36491,
                "label": "Besson",
                "label_ar": "بيسون",
                "label_fr": "Besson"
            },
            {
                "value": 36492,
                "label": "Rimbaud",
                "label_ar": "رامبو",
                "label_fr": "Rimbaud"
            },
            {
                "value": 36493,
                "label": "Pompidou",
                "label_ar": "بومبيدو",
                "label_fr": "Pompidou"
            },
            {
                "value": 36494,
                "label": "Adam-les-Vercel",
                "label_ar": "آدم ليس فيرسل",
                "label_fr": "Adam-les-Vercel"
            },
            {
                "value": 36495,
                "label": "Grandville-Gaudreville",
                "label_ar": "جراندفيل جودريفيل",
                "label_fr": "Grandville-Gaudreville"
            },
            {
                "value": 36496,
                "label": "Paix",
                "label_ar": "بايكس",
                "label_fr": "Paix"
            },
            {
                "value": 36497,
                "label": "Le Parc",
                "label_ar": "لو بارك",
                "label_fr": "Le Parc"
            },
            {
                "value": 36498,
                "label": "Gouvernes",
                "label_ar": "جوفيرنيس",
                "label_fr": "Gouvernes"
            },
            {
                "value": 36499,
                "label": "Casanova",
                "label_ar": "كازانوفا",
                "label_fr": "Casanova"
            },
            {
                "value": 36500,
                "label": "Dorval",
                "label_ar": "دورفال",
                "label_fr": "Dorval"
            },
            {
                "value": 36501,
                "label": "Brain-sur-Vilaine",
                "label_ar": "برين سور فيلان",
                "label_fr": "Brain-sur-Vilaine"
            },
            {
                "value": 36502,
                "label": "Les Landes",
                "label_ar": "ليه لانديس",
                "label_fr": "Les Landes"
            },
            {
                "value": 36503,
                "label": "Lesches",
                "label_ar": "ليشش",
                "label_fr": "Lesches"
            },
            {
                "value": 36504,
                "label": "Mont-Cauvaire",
                "label_ar": "مونت كوفير",
                "label_fr": "Mont-Cauvaire"
            },
            {
                "value": 36505,
                "label": "Collandres-Quincarnon",
                "label_ar": "Collandres-Quincarnon",
                "label_fr": "Collandres-Quincarnon"
            },
            {
                "value": 36506,
                "label": "Henansal",
                "label_ar": "هينانسال",
                "label_fr": "Henansal"
            },
            {
                "value": 36507,
                "label": "Montrouveau",
                "label_ar": "مونتروفو",
                "label_fr": "Montrouveau"
            },
            {
                "value": 36508,
                "label": "Les Laumes",
                "label_ar": "ليه لومز",
                "label_fr": "Les Laumes"
            },
            {
                "value": 36509,
                "label": "Levie",
                "label_ar": "ليفي",
                "label_fr": "Levie"
            },
            {
                "value": 36510,
                "label": "Meyssac",
                "label_ar": "ميساك",
                "label_fr": "Meyssac"
            },
            {
                "value": 36511,
                "label": "Olliergues",
                "label_ar": "أوليرج",
                "label_fr": "Olliergues"
            },
            {
                "value": 36512,
                "label": "Epegard",
                "label_ar": "إبيجارد",
                "label_fr": "Épégarde"
            },
            {
                "value": 36513,
                "label": "Pontaumur",
                "label_ar": "بونتومور",
                "label_fr": "Pontaumur"
            },
            {
                "value": 36514,
                "label": "Saint-Sever-Calvados",
                "label_ar": "سان سيفر كالفادوس",
                "label_fr": "Saint-Sever-Calvados"
            },
            {
                "value": 36515,
                "label": "Vigneulles",
                "label_ar": "Vigneulles",
                "label_fr": "Vigneulles"
            },
            {
                "value": 36516,
                "label": "Luttange",
                "label_ar": "Luttange",
                "label_fr": "Luttange"
            },
            {
                "value": 36517,
                "label": "Gremecey",
                "label_ar": "Gremecey",
                "label_fr": "Gremecey"
            },
            {
                "value": 36518,
                "label": "Haraucourt",
                "label_ar": "هاراوكورت",
                "label_fr": "Haraucourt"
            },
            {
                "value": 36519,
                "label": "Houdelmont",
                "label_ar": "هوديلمونت",
                "label_fr": "Houdelmont"
            },
            {
                "value": 36520,
                "label": "Barisey-la-Cote",
                "label_ar": "باريزي لا كوت",
                "label_fr": "Barisey-la-Côte"
            },
            {
                "value": 36521,
                "label": "Saint-Boingt",
                "label_ar": "سان بوينجت",
                "label_fr": "Saint-Boingt"
            },
            {
                "value": 36522,
                "label": "Veho",
                "label_ar": "فيهو",
                "label_fr": "Veho"
            },
            {
                "value": 36523,
                "label": "Adelans-et-le-Val-de-Bithaine",
                "label_ar": "Adelans-et-le-Val-de-Bithaine",
                "label_fr": "Adelans-et-le-Val-de-Bithaine"
            },
            {
                "value": 36524,
                "label": "Moussy-le-Vieux",
                "label_ar": "موسي لو فيو",
                "label_fr": "Moussy-le-Vieux"
            },
            {
                "value": 36525,
                "label": "Bantzenheim",
                "label_ar": "بانتزنهايم",
                "label_fr": "Bantzenheim"
            },
            {
                "value": 36526,
                "label": "Duttlenheim",
                "label_ar": "Duttlenheim",
                "label_fr": "Duttlenheim"
            },
            {
                "value": 36527,
                "label": "Jallerange",
                "label_ar": "جاليرانج",
                "label_fr": "Jallerange"
            },
            {
                "value": 36528,
                "label": "Fourg",
                "label_ar": "فورغ",
                "label_fr": "Fourg"
            },
            {
                "value": 36529,
                "label": "Saint-Germain-Laxis",
                "label_ar": "سان جيرمان لاكسيس",
                "label_fr": "Saint-Germain-Laxis"
            },
            {
                "value": 36530,
                "label": "Montpincon",
                "label_ar": "مونبينكون",
                "label_fr": "Montpincon"
            },
            {
                "value": 36531,
                "label": "Saulcy-sur-Meurthe",
                "label_ar": "سولسي سور مورث",
                "label_fr": "Saulcy-sur-Meurthe"
            },
            {
                "value": 36532,
                "label": "Longeault",
                "label_ar": "Longeault",
                "label_fr": "Longeault"
            },
            {
                "value": 36533,
                "label": "Parcieux",
                "label_ar": "باركيو",
                "label_fr": "Parcieux"
            },
            {
                "value": 36534,
                "label": "Montrond",
                "label_ar": "مونتروند",
                "label_fr": "Montrond"
            },
            {
                "value": 36535,
                "label": "Saint-Germain",
                "label_ar": "سان جيرمان",
                "label_fr": "Saint Germain"
            },
            {
                "value": 36536,
                "label": "Somme",
                "label_ar": "السوم",
                "label_fr": "Somme"
            },
            {
                "value": 36537,
                "label": "Serres",
                "label_ar": "سيريس",
                "label_fr": "Serres"
            },
            {
                "value": 36538,
                "label": "Aussonce",
                "label_ar": "أوسونسي",
                "label_fr": "Aussonce"
            },
            {
                "value": 36539,
                "label": "Braquis",
                "label_ar": "براكيز",
                "label_fr": "Braquis"
            },
            {
                "value": 36540,
                "label": "Guiche",
                "label_ar": "جيش",
                "label_fr": "Guiche"
            },
            {
                "value": 36541,
                "label": "Anneville-Ambourville",
                "label_ar": "أنيفيل أمبورفيل",
                "label_fr": "Anneville-Ambourville"
            },
            {
                "value": 36542,
                "label": "La Motte-Saint-Martin",
                "label_ar": "لا موت سانت مارتن",
                "label_fr": "La Motte-Saint-Martin"
            },
            {
                "value": 36543,
                "label": "Lacroix-sur-Meuse",
                "label_ar": "لاكروا سور ميوز",
                "label_fr": "Lacroix-sur-Meuse"
            },
            {
                "value": 36544,
                "label": "Courdimanche-sur-Essonne",
                "label_ar": "كورديمانش سور إيسون",
                "label_fr": "Courdimanche-sur-Essonne"
            },
            {
                "value": 36545,
                "label": "Saint-Pierre-de-Lages",
                "label_ar": "سان بيير دي لاجيز",
                "label_fr": "Saint-Pierre-de-Lages"
            },
            {
                "value": 36546,
                "label": "Segny",
                "label_ar": "سيجني",
                "label_fr": "Segny"
            },
            {
                "value": 36547,
                "label": "Daubeuf-la-Campagne",
                "label_ar": "Daubeuf-la-Campagne",
                "label_fr": "Daubeuf-la-Campagne"
            },
            {
                "value": 36548,
                "label": "Salins-les-Thermes",
                "label_ar": "سالينز ليه تيرمز",
                "label_fr": "Salins-les-Thermes"
            },
            {
                "value": 36549,
                "label": "Grisy-sur-Seine",
                "label_ar": "جريسي سور سين",
                "label_fr": "Grisy-sur-Seine"
            }
        ]
    },
    {
        "country": "Germany",
        "cities": [
            {
                "value": 36550,
                "label": "Langgons",
                "label_ar": "لانغونس",
                "label_fr": "Langgons"
            },
            {
                "value": 36551,
                "label": "Holle",
                "label_ar": "هول",
                "label_fr": "Holle"
            },
            {
                "value": 36552,
                "label": "Tespe",
                "label_ar": "تسبي",
                "label_fr": "Tespe"
            },
            {
                "value": 36553,
                "label": "Walsrode",
                "label_ar": "والسرود",
                "label_fr": "Walsrode"
            },
            {
                "value": 36554,
                "label": "Salzgitter",
                "label_ar": "سالزجيتر",
                "label_fr": "Salzgitter"
            },
            {
                "value": 36555,
                "label": "Bad Sassendorf",
                "label_ar": "باد ساسيندورف",
                "label_fr": "Bad Sassendorf"
            },
            {
                "value": 36556,
                "label": "Vienenburg",
                "label_ar": "فييننبورغ",
                "label_fr": "Vienenburg"
            },
            {
                "value": 36557,
                "label": "Rosdorf",
                "label_ar": "روزدورف",
                "label_fr": "Rosdorf"
            },
            {
                "value": 36558,
                "label": "Einbeck",
                "label_ar": "اينبك",
                "label_fr": "Einbeck"
            },
            {
                "value": 36559,
                "label": "Markersbach",
                "label_ar": "ماركرسباخ",
                "label_fr": "Markersbach"
            },
            {
                "value": 36560,
                "label": "Westensee",
                "label_ar": "Westensee",
                "label_fr": "Westensee"
            },
            {
                "value": 36561,
                "label": "Lauterbach",
                "label_ar": "لوترباخ",
                "label_fr": "Lauterbach"
            },
            {
                "value": 36562,
                "label": "Marburg",
                "label_ar": "ماربورغ",
                "label_fr": "Marbourg"
            },
            {
                "value": 36563,
                "label": "Dornburg",
                "label_ar": "دورنبرغ",
                "label_fr": "Dornburg"
            },
            {
                "value": 36564,
                "label": "Mainz",
                "label_ar": "ماينز",
                "label_fr": "Mayence"
            },
            {
                "value": 36565,
                "label": "Weinbach",
                "label_ar": "وينباخ",
                "label_fr": "Weinbach"
            },
            {
                "value": 36566,
                "label": "Cologne",
                "label_ar": "كولونيا",
                "label_fr": "Eau de Cologne"
            },
            {
                "value": 36567,
                "label": "Epfenbach",
                "label_ar": "إيبفنباخ",
                "label_fr": "Epfenbach"
            },
            {
                "value": 36568,
                "label": "Essen",
                "label_ar": "إيسن",
                "label_fr": "Essen"
            },
            {
                "value": 36569,
                "label": "Huttenberg",
                "label_ar": "Huttenberg",
                "label_fr": "Huttenberg"
            },
            {
                "value": 36570,
                "label": "Borgentreich",
                "label_ar": "بورجنتريتش",
                "label_fr": "Borgentreich"
            },
            {
                "value": 36571,
                "label": "Mettmann",
                "label_ar": "ميتمان",
                "label_fr": "Mettmann"
            },
            {
                "value": 36572,
                "label": "Schutzbach",
                "label_ar": "شوتزباخ",
                "label_fr": "Schutzbach"
            },
            {
                "value": 36573,
                "label": "Florstadt",
                "label_ar": "فلورشتات",
                "label_fr": "Florstadt"
            },
            {
                "value": 36574,
                "label": "Markdorf",
                "label_ar": "ماركدورف",
                "label_fr": "Markdorf"
            },
            {
                "value": 36575,
                "label": "Sponheim",
                "label_ar": "سبونهايم",
                "label_fr": "Sponheim"
            },
            {
                "value": 36576,
                "label": "Warstein",
                "label_ar": "وارستين",
                "label_fr": "Warstein"
            },
            {
                "value": 36577,
                "label": "Gelsenkirchen",
                "label_ar": "جيلسنكيرشن",
                "label_fr": "Gelsenkirchen"
            },
            {
                "value": 36578,
                "label": "Sprockhovel",
                "label_ar": "سبروكوفيل",
                "label_fr": "Sprockhovel"
            },
            {
                "value": 36579,
                "label": "Miellen",
                "label_ar": "ميلين",
                "label_fr": "Miellen"
            },
            {
                "value": 36580,
                "label": "Grebenau",
                "label_ar": "غريبيناو",
                "label_fr": "Grebenau"
            },
            {
                "value": 36581,
                "label": "Pohl",
                "label_ar": "بوهل",
                "label_fr": "Pohl"
            },
            {
                "value": 36582,
                "label": "Sohren",
                "label_ar": "سوهرين",
                "label_fr": "Sohren"
            },
            {
                "value": 36583,
                "label": "Grosslittgen",
                "label_ar": "جروسليتجن",
                "label_fr": "Grosslittgen"
            },
            {
                "value": 36584,
                "label": "Dillenburg",
                "label_ar": "ديلنبورغ",
                "label_fr": "Dillenburg"
            },
            {
                "value": 36585,
                "label": "Frankfurt am Main",
                "label_ar": "فرانكفورت أم ماين",
                "label_fr": "Francfort-sur-le-Main"
            },
            {
                "value": 36586,
                "label": "Tholey",
                "label_ar": "ثولي",
                "label_fr": "Tholey"
            },
            {
                "value": 36587,
                "label": "Runkel",
                "label_ar": "رانكل",
                "label_fr": "Runkel"
            },
            {
                "value": 36588,
                "label": "Aerzen",
                "label_ar": "ايرزين",
                "label_fr": "Aerzen"
            },
            {
                "value": 36589,
                "label": "Chemnitz",
                "label_ar": "كيمنتس",
                "label_fr": "Chemnitz"
            },
            {
                "value": 36590,
                "label": "Worrstadt",
                "label_ar": "ورشتات",
                "label_fr": "Worrstadt"
            },
            {
                "value": 36591,
                "label": "Windischleuba",
                "label_ar": "Windischleuba",
                "label_fr": "Windischleuba"
            },
            {
                "value": 36592,
                "label": "Unnau",
                "label_ar": "أوناو",
                "label_fr": "Unnau"
            },
            {
                "value": 36593,
                "label": "Waldbrol",
                "label_ar": "والدبرول",
                "label_fr": "Waldbrol"
            },
            {
                "value": 36594,
                "label": "Gebhardshain",
                "label_ar": "جبهاردشاين",
                "label_fr": "Gebhardshain"
            },
            {
                "value": 36595,
                "label": "Berg",
                "label_ar": "بيرج",
                "label_fr": "Berg"
            },
            {
                "value": 36596,
                "label": "Rheinbreitbach",
                "label_ar": "راينبريتباخ",
                "label_fr": "Rheinbreitbach"
            },
            {
                "value": 36597,
                "label": "Wandlitz",
                "label_ar": "واندليتس",
                "label_fr": "Wandlitz"
            },
            {
                "value": 36598,
                "label": "Euskirchen",
                "label_ar": "أوسكيرشن",
                "label_fr": "Euskirchen"
            },
            {
                "value": 36599,
                "label": "Gau-Bickelheim",
                "label_ar": "جاو بيكلهايم",
                "label_fr": "Gau-Bickelheim"
            },
            {
                "value": 36600,
                "label": "Leitzkau",
                "label_ar": "ليتزكاو",
                "label_fr": "Leitzkau"
            },
            {
                "value": 36601,
                "label": "Pfreimd",
                "label_ar": "بفريمد",
                "label_fr": "Pfreimd"
            },
            {
                "value": 36602,
                "label": "Biederitz",
                "label_ar": "بيدريتز",
                "label_fr": "Biederitz"
            },
            {
                "value": 36603,
                "label": "Langenwetzendorf",
                "label_ar": "لانجينويتزيندورف",
                "label_fr": "Langenwetzendorf"
            },
            {
                "value": 36604,
                "label": "Donndorf",
                "label_ar": "دونندورف",
                "label_fr": "Donndorf"
            },
            {
                "value": 36605,
                "label": "Stadtkyll",
                "label_ar": "ستادتكيل",
                "label_fr": "Stadtkyll"
            },
            {
                "value": 36606,
                "label": "Pressath",
                "label_ar": "بريساث",
                "label_fr": "Pressath"
            },
            {
                "value": 36607,
                "label": "Bockau",
                "label_ar": "بوكاو",
                "label_fr": "Bockau"
            },
            {
                "value": 36608,
                "label": "Nossen",
                "label_ar": "نوسن",
                "label_fr": "Nossen"
            },
            {
                "value": 36609,
                "label": "Homberg",
                "label_ar": "هومبرج",
                "label_fr": "Homberg"
            },
            {
                "value": 36610,
                "label": "Borken",
                "label_ar": "بوركين",
                "label_fr": "Borken"
            },
            {
                "value": 36611,
                "label": "Tobertitz",
                "label_ar": "توبرتيتز",
                "label_fr": "Tobertitz"
            },
            {
                "value": 36612,
                "label": "Haren",
                "label_ar": "هارين",
                "label_fr": "Haren"
            },
            {
                "value": 36613,
                "label": "Zossen",
                "label_ar": "زوسين",
                "label_fr": "Zossen"
            },
            {
                "value": 36614,
                "label": "Grundau",
                "label_ar": "جرونداو",
                "label_fr": "Grundau"
            },
            {
                "value": 36615,
                "label": "Schlotfeld",
                "label_ar": "شلوتفيلد",
                "label_fr": "Schlotfeld"
            },
            {
                "value": 36616,
                "label": "Apolda",
                "label_ar": "أبولدا",
                "label_fr": "Apolda"
            },
            {
                "value": 36617,
                "label": "Limbach-Oberfrohna",
                "label_ar": "ليمباخ أوبيرفروهنا",
                "label_fr": "Limbach-Oberfrohna"
            },
            {
                "value": 36618,
                "label": "Konigs Wusterhausen",
                "label_ar": "كونيجز ووسترهاوزن",
                "label_fr": "Konigs Wusterhausen"
            },
            {
                "value": 36619,
                "label": "Netphen",
                "label_ar": "نتفين",
                "label_fr": "Netphen"
            },
            {
                "value": 36620,
                "label": "Altkirchen",
                "label_ar": "التكيرشين",
                "label_fr": "Altkirchen"
            },
            {
                "value": 36621,
                "label": "Steyerberg",
                "label_ar": "شتايربيرج",
                "label_fr": "Steyerberg"
            },
            {
                "value": 36622,
                "label": "Hohndorf",
                "label_ar": "هوندورف",
                "label_fr": "Hohndorf"
            },
            {
                "value": 36623,
                "label": "Dortmund",
                "label_ar": "دورتموند",
                "label_fr": "Dortmund"
            },
            {
                "value": 36624,
                "label": "Spenge",
                "label_ar": "سبنج",
                "label_fr": "Spenge"
            },
            {
                "value": 36625,
                "label": "Selm",
                "label_ar": "سلم",
                "label_fr": "Selm"
            },
            {
                "value": 36626,
                "label": "Espenau",
                "label_ar": "اسبيناو",
                "label_fr": "Espenau"
            },
            {
                "value": 36627,
                "label": "Lemgo",
                "label_ar": "ليمغو",
                "label_fr": "Lemgo"
            },
            {
                "value": 36628,
                "label": "Bochum",
                "label_ar": "بوخوم",
                "label_fr": "Bochum"
            },
            {
                "value": 36629,
                "label": "Halver",
                "label_ar": "هالفر",
                "label_fr": "Halver"
            },
            {
                "value": 36630,
                "label": "Bergkamen",
                "label_ar": "بيرجامين",
                "label_fr": "Bergkamen"
            },
            {
                "value": 36631,
                "label": "Erndtebruck",
                "label_ar": "ارندبروك",
                "label_fr": "Erndtebruck"
            },
            {
                "value": 36632,
                "label": "Rudersdorf",
                "label_ar": "رودرسدورف",
                "label_fr": "Rudersdorf"
            },
            {
                "value": 36633,
                "label": "Lübeck",
                "label_ar": "لوبيك",
                "label_fr": "Lübeck"
            },
            {
                "value": 36634,
                "label": "Berlin",
                "label_ar": "برلين",
                "label_fr": "Berlin"
            },
            {
                "value": 36635,
                "label": "Gluckstadt",
                "label_ar": "جلوكشتات",
                "label_fr": "Gluckstadt"
            },
            {
                "value": 36636,
                "label": "Bernau bei Berlin",
                "label_ar": "بيرناو باي برلين",
                "label_fr": "Bernau bei Berlin"
            },
            {
                "value": 36637,
                "label": "Moor",
                "label_ar": "مور",
                "label_fr": "Lande"
            },
            {
                "value": 36638,
                "label": "Dresden",
                "label_ar": "دريسدن",
                "label_fr": "Dresde"
            },
            {
                "value": 36639,
                "label": "Groditz",
                "label_ar": "جروديتز",
                "label_fr": "Groditz"
            },
            {
                "value": 36640,
                "label": "Nitz",
                "label_ar": "نيتز",
                "label_fr": "Nitz"
            },
            {
                "value": 36641,
                "label": "Sebnitz",
                "label_ar": "سبنيتز",
                "label_fr": "Sebnitz"
            },
            {
                "value": 36642,
                "label": "Colmnitz",
                "label_ar": "كولمنيتز",
                "label_fr": "Colmnitz"
            },
            {
                "value": 36643,
                "label": "Dobeln",
                "label_ar": "دوبيلن",
                "label_fr": "Dobeln"
            },
            {
                "value": 36644,
                "label": "Lauchhammer",
                "label_ar": "لاوشهامر",
                "label_fr": "Lauchhammer"
            },
            {
                "value": 36645,
                "label": "Olbernhau",
                "label_ar": "Olbernhau",
                "label_fr": "Olbernhau"
            },
            {
                "value": 36646,
                "label": "Frankenberg",
                "label_ar": "فرانكنبيرج",
                "label_fr": "Frankenberg"
            },
            {
                "value": 36647,
                "label": "Rabenau",
                "label_ar": "رابيناو",
                "label_fr": "Rabenau"
            },
            {
                "value": 36648,
                "label": "Finsterwalde",
                "label_ar": "فينستيروالد",
                "label_fr": "Finsterwalde"
            },
            {
                "value": 36649,
                "label": "Ebersbach",
                "label_ar": "إبيرسباخ",
                "label_fr": "Ebersbach"
            },
            {
                "value": 36650,
                "label": "Hockendorf",
                "label_ar": "هوكيندورف",
                "label_fr": "Hockendorf"
            },
            {
                "value": 36651,
                "label": "Friedersdorf",
                "label_ar": "فريدرسدورف",
                "label_fr": "Friedersdorf"
            },
            {
                "value": 36652,
                "label": "Coswig",
                "label_ar": "كوسويج",
                "label_fr": "Coswig"
            },
            {
                "value": 36653,
                "label": "Radebeul",
                "label_ar": "رادبول",
                "label_fr": "Radebeul"
            },
            {
                "value": 36654,
                "label": "Weinbohla",
                "label_ar": "وينبوهلا",
                "label_fr": "Weinbohla"
            },
            {
                "value": 36655,
                "label": "Klettwitz",
                "label_ar": "كليتويتز",
                "label_fr": "Klettwitz"
            },
            {
                "value": 36656,
                "label": "Rammenau",
                "label_ar": "راميناو",
                "label_fr": "Rammenau"
            },
            {
                "value": 36657,
                "label": "Freital",
                "label_ar": "فريتال",
                "label_fr": "Freital"
            },
            {
                "value": 36658,
                "label": "Weisswasser",
                "label_ar": "ويسفاسر",
                "label_fr": "Weisswasser"
            },
            {
                "value": 36659,
                "label": "Thiendorf",
                "label_ar": "ثيندورف",
                "label_fr": "Thiendorf"
            },
            {
                "value": 36660,
                "label": "Brand-Erbisdorf",
                "label_ar": "براند- Erbisdorf",
                "label_fr": "Marque-Erbisdorf"
            },
            {
                "value": 36661,
                "label": "Senftenberg",
                "label_ar": "سينفتنبرغ",
                "label_fr": "Senftenberg"
            },
            {
                "value": 36662,
                "label": "Lunzenau",
                "label_ar": "لونزيناو",
                "label_fr": "Lunzenau"
            },
            {
                "value": 36663,
                "label": "Halsbrucke",
                "label_ar": "هالسبروك",
                "label_fr": "Halsbrucke"
            },
            {
                "value": 36664,
                "label": "Zwickau",
                "label_ar": "تسفيكاو",
                "label_fr": "Zwickau"
            },
            {
                "value": 36665,
                "label": "Dippoldiswalde",
                "label_ar": "Dippoldiswalde",
                "label_fr": "Dippoldiswalde"
            },
            {
                "value": 36666,
                "label": "Ottendorf-Okrilla",
                "label_ar": "Ottendorf-Okrilla",
                "label_fr": "Ottendorf-Okrilla"
            },
            {
                "value": 36667,
                "label": "Pirna",
                "label_ar": "بيرنا",
                "label_fr": "Pirna"
            },
            {
                "value": 36668,
                "label": "Niederwiesa",
                "label_ar": "Niederwiesa",
                "label_fr": "Niederwiesa"
            },
            {
                "value": 36669,
                "label": "Frauendorf",
                "label_ar": "فراوندورف",
                "label_fr": "Frauendorf"
            },
            {
                "value": 36670,
                "label": "Wittgensdorf",
                "label_ar": "Wittgensdorf",
                "label_fr": "Wittgensdorf"
            },
            {
                "value": 36671,
                "label": "Falkenberg",
                "label_ar": "فالكنبرج",
                "label_fr": "Falkenberg"
            },
            {
                "value": 36672,
                "label": "Geringswalde",
                "label_ar": "Geringswalde",
                "label_fr": "Geringswalde"
            },
            {
                "value": 36673,
                "label": "Burkhardtsdorf",
                "label_ar": "بوركهاردتسدورف",
                "label_fr": "Burkhardtsdorf"
            },
            {
                "value": 36674,
                "label": "Bannewitz",
                "label_ar": "بانيويتز",
                "label_fr": "Bannewitz"
            },
            {
                "value": 36675,
                "label": "Lichtenberg",
                "label_ar": "ليشتنبرغ",
                "label_fr": "Lichtenberg"
            },
            {
                "value": 36676,
                "label": "Freiberg",
                "label_ar": "فرايبرغ",
                "label_fr": "Freiberg"
            },
            {
                "value": 36677,
                "label": "Lichtenstein",
                "label_ar": "ليختنشتاين",
                "label_fr": "Lichtenstein"
            },
            {
                "value": 36678,
                "label": "Niesky",
                "label_ar": "نيسكي",
                "label_fr": "Niesky"
            },
            {
                "value": 36679,
                "label": "Penig",
                "label_ar": "Penig",
                "label_fr": "Penig"
            },
            {
                "value": 36680,
                "label": "Calau",
                "label_ar": "كالاو",
                "label_fr": "Calau"
            },
            {
                "value": 36681,
                "label": "Frauenstein",
                "label_ar": "فراونشتاين",
                "label_fr": "Frauenstein"
            },
            {
                "value": 36682,
                "label": "Bischofswerda",
                "label_ar": "Bischofswerda",
                "label_fr": "Bischofswerda"
            },
            {
                "value": 36683,
                "label": "Görlitz",
                "label_ar": "جورليتس",
                "label_fr": "Görlitz"
            },
            {
                "value": 36684,
                "label": "Halle",
                "label_ar": "هالي",
                "label_fr": "Halle"
            },
            {
                "value": 36685,
                "label": "Marienberg",
                "label_ar": "مارينبيرج",
                "label_fr": "Marienberg"
            },
            {
                "value": 36686,
                "label": "Rudolstadt",
                "label_ar": "رودولشتات",
                "label_fr": "Rudolstadt"
            },
            {
                "value": 36687,
                "label": "Neukirchen",
                "label_ar": "نويكيرشن",
                "label_fr": "Neukirchen"
            },
            {
                "value": 36688,
                "label": "Riesa",
                "label_ar": "ريسا",
                "label_fr": "Riesa"
            },
            {
                "value": 36689,
                "label": "Heidenau",
                "label_ar": "هايدناو",
                "label_fr": "Heidenau"
            },
            {
                "value": 36690,
                "label": "Priestewitz",
                "label_ar": "بريستويتز",
                "label_fr": "Priestewitz"
            },
            {
                "value": 36691,
                "label": "Zittau",
                "label_ar": "زيتاو",
                "label_fr": "Zittau"
            },
            {
                "value": 36692,
                "label": "Oelsnitz",
                "label_ar": "اويلسنيتز",
                "label_fr": "Oelsnitz"
            },
            {
                "value": 36693,
                "label": "Grossrohrsdorf",
                "label_ar": "جروسروهرسدورف",
                "label_fr": "Grossrohrsdorf"
            },
            {
                "value": 36694,
                "label": "Fraureuth",
                "label_ar": "فراوروث",
                "label_fr": "Fraureuth"
            },
            {
                "value": 36695,
                "label": "Altenburg",
                "label_ar": "ألتنبرغ",
                "label_fr": "Altenburg"
            },
            {
                "value": 36696,
                "label": "Ruckersdorf",
                "label_ar": "روكرسدورف",
                "label_fr": "Ruckersdorf"
            },
            {
                "value": 36697,
                "label": "Leipzig",
                "label_ar": "لايبزيغ",
                "label_fr": "Leipzig"
            },
            {
                "value": 36698,
                "label": "Annaberg-Buchholz",
                "label_ar": "أنابيرج بوخولز",
                "label_fr": "Annaberg-Buchholz"
            },
            {
                "value": 36699,
                "label": "Sohland",
                "label_ar": "سوهلاند",
                "label_fr": "Sohland"
            },
            {
                "value": 36700,
                "label": "Schwarzenberg",
                "label_ar": "شوارزنبرج",
                "label_fr": "Schwarzenberg"
            },
            {
                "value": 36701,
                "label": "Mittweida",
                "label_ar": "ميتويدا",
                "label_fr": "Mittweida"
            },
            {
                "value": 36702,
                "label": "Nunchritz",
                "label_ar": "نونشريتز",
                "label_fr": "Nunchritz"
            },
            {
                "value": 36703,
                "label": "Goldberg",
                "label_ar": "غولدبرغ",
                "label_fr": "Goldberg"
            },
            {
                "value": 36704,
                "label": "Schenkenhorst",
                "label_ar": "شينكينهورست",
                "label_fr": "Schenkenhorst"
            },
            {
                "value": 36705,
                "label": "Woltersdorf",
                "label_ar": "ولترسدورف",
                "label_fr": "Woltersdorf"
            },
            {
                "value": 36706,
                "label": "Malchow",
                "label_ar": "Malchow",
                "label_fr": "Malchow"
            },
            {
                "value": 36707,
                "label": "Velten",
                "label_ar": "فيلتن",
                "label_fr": "Velten"
            },
            {
                "value": 36708,
                "label": "Havelberg",
                "label_ar": "هافيلبيرج",
                "label_fr": "Havelberg"
            },
            {
                "value": 36709,
                "label": "Brandenburg",
                "label_ar": "براندنبورغ",
                "label_fr": "Brandebourg"
            },
            {
                "value": 36710,
                "label": "Mullrose",
                "label_ar": "مولروز",
                "label_fr": "Mullrose"
            },
            {
                "value": 36711,
                "label": "Magdeburg",
                "label_ar": "ماغدبورغ",
                "label_fr": "Magdebourg"
            },
            {
                "value": 36712,
                "label": "Lubtheen",
                "label_ar": "لوبثين",
                "label_fr": "Lubtheen"
            },
            {
                "value": 36713,
                "label": "Waren",
                "label_ar": "وارن",
                "label_fr": "Waren"
            },
            {
                "value": 36714,
                "label": "Gransee",
                "label_ar": "جرانسي",
                "label_fr": "Gransee"
            },
            {
                "value": 36715,
                "label": "Templin",
                "label_ar": "تمبلن",
                "label_fr": "Templin"
            },
            {
                "value": 36716,
                "label": "Genthin",
                "label_ar": "جينثين",
                "label_fr": "Genthin"
            },
            {
                "value": 36717,
                "label": "Klein Rogahn",
                "label_ar": "كلاين روغان",
                "label_fr": "Klein Rogahn"
            },
            {
                "value": 36718,
                "label": "Vellahn",
                "label_ar": "فيلان",
                "label_fr": "Vellahn"
            },
            {
                "value": 36719,
                "label": "Wefensleben",
                "label_ar": "Wefensleben",
                "label_fr": "Wefensleben"
            },
            {
                "value": 36720,
                "label": "Potsdam",
                "label_ar": "السد الخلفي",
                "label_fr": "Potsdam"
            },
            {
                "value": 36721,
                "label": "Luckenwalde",
                "label_ar": "Luckenwalde",
                "label_fr": "Luckenwalde"
            },
            {
                "value": 36722,
                "label": "Werneuchen",
                "label_ar": "ويرنوشن",
                "label_fr": "Werneuchen"
            },
            {
                "value": 36723,
                "label": "Werder",
                "label_ar": "فيردر",
                "label_fr": "Werder"
            },
            {
                "value": 36724,
                "label": "Brodhagen",
                "label_ar": "برودهاغن",
                "label_fr": "Brodhagen"
            },
            {
                "value": 36725,
                "label": "Eisenhüttenstadt",
                "label_ar": "أيزنهوتنشتات",
                "label_fr": "Eisenhüttenstadt"
            },
            {
                "value": 36726,
                "label": "Schermen",
                "label_ar": "شيرمن",
                "label_fr": "Schermen"
            },
            {
                "value": 36727,
                "label": "Teltow",
                "label_ar": "تيلتو",
                "label_fr": "Teltow"
            },
            {
                "value": 36728,
                "label": "Schollene",
                "label_ar": "شولين",
                "label_fr": "Schollène"
            },
            {
                "value": 36729,
                "label": "Plate",
                "label_ar": "طبق",
                "label_fr": "assiette"
            },
            {
                "value": 36730,
                "label": "Hamburg",
                "label_ar": "هامبورغ",
                "label_fr": "Hambourg"
            },
            {
                "value": 36731,
                "label": "Ganderkesee",
                "label_ar": "غاندركسي",
                "label_fr": "Ganderkesee"
            },
            {
                "value": 36732,
                "label": "Seth",
                "label_ar": "سيث",
                "label_fr": "Seth"
            },
            {
                "value": 36733,
                "label": "Laatzen",
                "label_ar": "لاتزين",
                "label_fr": "Laatzen"
            },
            {
                "value": 36734,
                "label": "Stadthagen",
                "label_ar": "شتاتهاجين",
                "label_fr": "Stadthagen"
            },
            {
                "value": 36735,
                "label": "Hanover",
                "label_ar": "هانوفر",
                "label_fr": "Hanovre"
            },
            {
                "value": 36736,
                "label": "Varel",
                "label_ar": "فاريل",
                "label_fr": "Varel"
            },
            {
                "value": 36737,
                "label": "Winsen",
                "label_ar": "وينسن",
                "label_fr": "Winsen"
            },
            {
                "value": 36738,
                "label": "Isenbuttel",
                "label_ar": "Isenbuttel",
                "label_fr": "Isenbuttel"
            },
            {
                "value": 36739,
                "label": "Jevenstedt",
                "label_ar": "جيفينستيد",
                "label_fr": "Jevenstedt"
            },
            {
                "value": 36740,
                "label": "Achim",
                "label_ar": "أخيم",
                "label_fr": "Achim"
            },
            {
                "value": 36741,
                "label": "Uetze",
                "label_ar": "يوتزي",
                "label_fr": "Uetze"
            },
            {
                "value": 36742,
                "label": "Bargfeld-Stegen",
                "label_ar": "Bargfeld-Stegen",
                "label_fr": "Bargfeld-Stegen"
            },
            {
                "value": 36743,
                "label": "Kiel",
                "label_ar": "كيل",
                "label_fr": "Kiel"
            },
            {
                "value": 36744,
                "label": "Oldenburg",
                "label_ar": "أولدنبورغ",
                "label_fr": "Oldenbourg"
            },
            {
                "value": 36745,
                "label": "Bremen",
                "label_ar": "بريمن",
                "label_fr": "Brême"
            },
            {
                "value": 36746,
                "label": "Bad Zwischenahn",
                "label_ar": "باد زفيشينان",
                "label_fr": "Bad Zwischenahn"
            },
            {
                "value": 36747,
                "label": "Ratekau",
                "label_ar": "راتكاو",
                "label_fr": "Ratekau"
            },
            {
                "value": 36748,
                "label": "Heist",
                "label_ar": "سرقة",
                "label_fr": "Braquage"
            },
            {
                "value": 36749,
                "label": "Elmshorn",
                "label_ar": "Elmshorn",
                "label_fr": "Elmshorn"
            },
            {
                "value": 36750,
                "label": "Aurich",
                "label_ar": "أوريتش",
                "label_fr": "Aurich"
            },
            {
                "value": 36751,
                "label": "Padelugge",
                "label_ar": "باديلوج",
                "label_fr": "Padelugge"
            },
            {
                "value": 36752,
                "label": "Buchholz",
                "label_ar": "بوخولز",
                "label_fr": "Buchholz"
            },
            {
                "value": 36753,
                "label": "Seevetal",
                "label_ar": "سيفيتال",
                "label_fr": "Seevetal"
            },
            {
                "value": 36754,
                "label": "Meldorf",
                "label_ar": "Meldorf",
                "label_fr": "Meldorf"
            },
            {
                "value": 36755,
                "label": "Bonnigheim",
                "label_ar": "بونيجهايم",
                "label_fr": "Bonnigheim"
            },
            {
                "value": 36756,
                "label": "Uberlingen",
                "label_ar": "أوبرلينجن",
                "label_fr": "Uberlingen"
            },
            {
                "value": 36757,
                "label": "Geislingen",
                "label_ar": "Geislingen",
                "label_fr": "Geislingen"
            },
            {
                "value": 36758,
                "label": "Sandhausen",
                "label_ar": "ساندهاوزن",
                "label_fr": "Sandhausen"
            },
            {
                "value": 36759,
                "label": "Rottenburg",
                "label_ar": "روتنبورغ",
                "label_fr": "Rottenburg"
            },
            {
                "value": 36760,
                "label": "Hessheim",
                "label_ar": "هيسهايم",
                "label_fr": "Hessheim"
            },
            {
                "value": 36761,
                "label": "Ittlingen",
                "label_ar": "إيتلينجن",
                "label_fr": "Ittlingen"
            },
            {
                "value": 36762,
                "label": "Mannheim",
                "label_ar": "مانهايم",
                "label_fr": "Mannheim"
            },
            {
                "value": 36763,
                "label": "Niefern-Oschelbronn",
                "label_ar": "نيفرن أوشيلبرون",
                "label_fr": "Niefern-Oschelbronn"
            },
            {
                "value": 36764,
                "label": "Friedenweiler",
                "label_ar": "فريدنويلر",
                "label_fr": "Friedenweiler"
            },
            {
                "value": 36765,
                "label": "Bietigheim-Bissingen",
                "label_ar": "بيتيغهايم بيسينغين",
                "label_fr": "Bietigheim-Bissingen"
            },
            {
                "value": 36766,
                "label": "Elmstein",
                "label_ar": "إلمستين",
                "label_fr": "Elmstein"
            },
            {
                "value": 36767,
                "label": "Backnang",
                "label_ar": "باكنانج",
                "label_fr": "Backnang"
            },
            {
                "value": 36768,
                "label": "Zwiefalten",
                "label_ar": "Zwiefalten",
                "label_fr": "Zwiefalten"
            },
            {
                "value": 36769,
                "label": "Rheinau",
                "label_ar": "رايناو",
                "label_fr": "Rheinau"
            },
            {
                "value": 36770,
                "label": "Eigeltingen",
                "label_ar": "إيجلتينجن",
                "label_fr": "Eigeltingen"
            },
            {
                "value": 36771,
                "label": "Freudenweiler",
                "label_ar": "فرويدنويلر",
                "label_fr": "Freudenweiler"
            },
            {
                "value": 36772,
                "label": "Bad Liebenzell",
                "label_ar": "باد ليبنزيل",
                "label_fr": "Bad Liebenzell"
            },
            {
                "value": 36773,
                "label": "Landau",
                "label_ar": "لانداو",
                "label_fr": "Landau"
            },
            {
                "value": 36774,
                "label": "Stuttgart",
                "label_ar": "شتوتغارت",
                "label_fr": "Stuttgart"
            },
            {
                "value": 36775,
                "label": "Worms",
                "label_ar": "الديدان",
                "label_fr": "Vers"
            },
            {
                "value": 36776,
                "label": "Ludwigshafen am Rhein",
                "label_ar": "لودفيجشافن ام راين",
                "label_fr": "Ludwigshafen am Rhein"
            },
            {
                "value": 36777,
                "label": "Karlsruhe",
                "label_ar": "كارلسروه",
                "label_fr": "Karlsruhe"
            },
            {
                "value": 36778,
                "label": "Pforzheim",
                "label_ar": "بفورتسهايم",
                "label_fr": "Pforzheim"
            },
            {
                "value": 36779,
                "label": "Bad Duerkheim",
                "label_ar": "باد دوركهايم",
                "label_fr": "Bad Dürkheim"
            },
            {
                "value": 36780,
                "label": "Mersheim",
                "label_ar": "ميرشيم",
                "label_fr": "Mersheim"
            },
            {
                "value": 36781,
                "label": "Ostfildern",
                "label_ar": "أوستفيلدن",
                "label_fr": "Ostfildern"
            },
            {
                "value": 36782,
                "label": "Hamm",
                "label_ar": "هام",
                "label_fr": "Hamm"
            },
            {
                "value": 36783,
                "label": "Bielefeld",
                "label_ar": "بيليفيلد",
                "label_fr": "Bielefeld"
            },
            {
                "value": 36784,
                "label": "Bad Oeynhausen",
                "label_ar": "باد اوينهاوزن",
                "label_fr": "Bad Oeynhausen"
            },
            {
                "value": 36785,
                "label": "Münster",
                "label_ar": "مونستر",
                "label_fr": "Münster"
            },
            {
                "value": 36786,
                "label": "Dusseldorf",
                "label_ar": "دوسلدورف",
                "label_fr": "Düsseldorf"
            },
            {
                "value": 36787,
                "label": "Lubbecke",
                "label_ar": "لوبيك",
                "label_fr": "Lubbecke"
            },
            {
                "value": 36788,
                "label": "Point",
                "label_ar": "نقطة",
                "label_fr": "Point"
            },
            {
                "value": 36789,
                "label": "Lohfelden",
                "label_ar": "Lohfelden",
                "label_fr": "Lohfelden"
            },
            {
                "value": 36790,
                "label": "Nordkirchen",
                "label_ar": "نوردكيرشن",
                "label_fr": "Nordkirchen"
            },
            {
                "value": 36791,
                "label": "Kassel",
                "label_ar": "كاسل",
                "label_fr": "Kassel"
            },
            {
                "value": 36792,
                "label": "Hagen",
                "label_ar": "هاغن",
                "label_fr": "Hagen"
            },
            {
                "value": 36793,
                "label": "Soest",
                "label_ar": "Soest",
                "label_fr": "Soest"
            },
            {
                "value": 36794,
                "label": "Recklinghausen",
                "label_ar": "ريكلينغهاوزن",
                "label_fr": "Recklinghausen"
            },
            {
                "value": 36795,
                "label": "Paderborn",
                "label_ar": "بادربورن",
                "label_fr": "Paderborn"
            },
            {
                "value": 36796,
                "label": "Buende",
                "label_ar": "بويندي",
                "label_fr": "Buende"
            },
            {
                "value": 36797,
                "label": "Geseke",
                "label_ar": "جيسيك",
                "label_fr": "Geseke"
            },
            {
                "value": 36798,
                "label": "Neuenrade",
                "label_ar": "نوينريد",
                "label_fr": "Neuenrade"
            },
            {
                "value": 36799,
                "label": "Pinneberg",
                "label_ar": "بينبيرج",
                "label_fr": "Pinneberg"
            },
            {
                "value": 36800,
                "label": "Neustadt-Glewe",
                "label_ar": "نويشتات جليوي",
                "label_fr": "Neustadt-Glewe"
            },
            {
                "value": 36801,
                "label": "Gingst",
                "label_ar": "جينجست",
                "label_fr": "Gingst"
            },
            {
                "value": 36802,
                "label": "Calbe",
                "label_ar": "كالب",
                "label_fr": "Calbe"
            },
            {
                "value": 36803,
                "label": "Ploen",
                "label_ar": "بلوين",
                "label_fr": "Ploen"
            },
            {
                "value": 36804,
                "label": "Seefeld",
                "label_ar": "سيفيلد",
                "label_fr": "Seefeld"
            },
            {
                "value": 36805,
                "label": "Wesenberg",
                "label_ar": "Wesenberg",
                "label_fr": "Wesenberg"
            },
            {
                "value": 36806,
                "label": "Falkensee",
                "label_ar": "فالكينسي",
                "label_fr": "Falkensee"
            },
            {
                "value": 36807,
                "label": "Barleben",
                "label_ar": "بارليبن",
                "label_fr": "Barleben"
            },
            {
                "value": 36808,
                "label": "Neuruppin",
                "label_ar": "نيوروبين",
                "label_fr": "Neuruppin"
            },
            {
                "value": 36809,
                "label": "Zehdenick",
                "label_ar": "زيدنيك",
                "label_fr": "Zehdenick"
            },
            {
                "value": 36810,
                "label": "Weddingstedt",
                "label_ar": "ويدينجستيدت",
                "label_fr": "Weddingstedt"
            },
            {
                "value": 36811,
                "label": "Flensburg",
                "label_ar": "فلنسبورغ",
                "label_fr": "Flensburg"
            },
            {
                "value": 36812,
                "label": "Heide",
                "label_ar": "هايد",
                "label_fr": "Heide"
            },
            {
                "value": 36813,
                "label": "Bargteheide",
                "label_ar": "Bargteheide",
                "label_fr": "Bargteheide"
            },
            {
                "value": 36814,
                "label": "Kaltenkirchen",
                "label_ar": "كالتنكيرشن",
                "label_fr": "Kaltenkirchen"
            },
            {
                "value": 36815,
                "label": "Geesthacht",
                "label_ar": "Geesthacht",
                "label_fr": "Geesthacht"
            },
            {
                "value": 36816,
                "label": "Ahrensburg",
                "label_ar": "Ahrensburg",
                "label_fr": "Ahrensburg"
            },
            {
                "value": 36817,
                "label": "Norderstedt",
                "label_ar": "نوردرستيدت",
                "label_fr": "Norderstedt"
            },
            {
                "value": 36818,
                "label": "Einhaus",
                "label_ar": "اينهاوس",
                "label_fr": "Einhaus"
            },
            {
                "value": 36819,
                "label": "Alveslohe",
                "label_ar": "ألفيسلوهي",
                "label_fr": "Alveslohe"
            },
            {
                "value": 36820,
                "label": "Itzehoe",
                "label_ar": "Itzehoe",
                "label_fr": "Itzehoe"
            },
            {
                "value": 36821,
                "label": "Reinbek",
                "label_ar": "Reinbek",
                "label_fr": "Reinbek"
            },
            {
                "value": 36822,
                "label": "Wedel",
                "label_ar": "ويديل",
                "label_fr": "Wedel"
            },
            {
                "value": 36823,
                "label": "Bad Segeberg",
                "label_ar": "سيئة سيجبرغ",
                "label_fr": "Bad Segeberg"
            },
            {
                "value": 36824,
                "label": "Grosshansdorf",
                "label_ar": "غروشانسدورف",
                "label_fr": "Grosshansdorf"
            },
            {
                "value": 36825,
                "label": "Glinde",
                "label_ar": "جليندي",
                "label_fr": "Glinde"
            },
            {
                "value": 36826,
                "label": "Bad Bramstedt",
                "label_ar": "سيئة برامستيدت",
                "label_fr": "Bad Bramstedt"
            },
            {
                "value": 36827,
                "label": "Barmstedt",
                "label_ar": "بارمستيدت",
                "label_fr": "Barmstedt"
            },
            {
                "value": 36828,
                "label": "Schenefeld",
                "label_ar": "شينفيلد",
                "label_fr": "Schenefeld"
            },
            {
                "value": 36829,
                "label": "Borstel-Hohenraden",
                "label_ar": "بورستل هوهنرادين",
                "label_fr": "Borstel-Hohenraden"
            },
            {
                "value": 36830,
                "label": "Henstedt-Ulzburg",
                "label_ar": "Henstedt-Ulzburg",
                "label_fr": "Henstedt-Ulzburg"
            },
            {
                "value": 36831,
                "label": "Tornesch",
                "label_ar": "تورنش",
                "label_fr": "Tornesch"
            },
            {
                "value": 36832,
                "label": "Ratzeburg",
                "label_ar": "راتزبورج",
                "label_fr": "Ratzeburg"
            },
            {
                "value": 36833,
                "label": "Wentorf bei Hamburg",
                "label_ar": "Wentorf bei Hamburg",
                "label_fr": "Wentorf bei Hambourg"
            },
            {
                "value": 36834,
                "label": "Neu Wulmstorf",
                "label_ar": "نيو وولمستورف",
                "label_fr": "Neu Wulmstorf"
            },
            {
                "value": 36835,
                "label": "Kroppelshagen-Fahrendorf",
                "label_ar": "Kroppelshagen-Fahrendorf",
                "label_fr": "Kroppelshagen-Fahrendorf"
            },
            {
                "value": 36836,
                "label": "Molln",
                "label_ar": "مولن",
                "label_fr": "Molln"
            },
            {
                "value": 36837,
                "label": "Husum",
                "label_ar": "هوسوم",
                "label_fr": "Husum"
            },
            {
                "value": 36838,
                "label": "Fahrendorf",
                "label_ar": "فهرندورف",
                "label_fr": "Fahrendorf"
            },
            {
                "value": 36839,
                "label": "Halstenbek",
                "label_ar": "هالستنبيك",
                "label_fr": "Halstenbek"
            },
            {
                "value": 36840,
                "label": "Wilhelmshaven",
                "label_ar": "فيلهلمسهافن",
                "label_fr": "Wilhelmshaven"
            },
            {
                "value": 36841,
                "label": "Diepholz",
                "label_ar": "ديبهولز",
                "label_fr": "Diepholz"
            },
            {
                "value": 36842,
                "label": "Cloppenburg",
                "label_ar": "كلوبنبورغ",
                "label_fr": "Cloppenburg"
            },
            {
                "value": 36843,
                "label": "Schortens",
                "label_ar": "شورتنز",
                "label_fr": "Schortens"
            },
            {
                "value": 36844,
                "label": "Jever",
                "label_ar": "جيفر",
                "label_fr": "Jever"
            },
            {
                "value": 36845,
                "label": "Lohne",
                "label_ar": "Lohne",
                "label_fr": "Lohne"
            },
            {
                "value": 36846,
                "label": "Damme",
                "label_ar": "دام",
                "label_fr": "Damme"
            },
            {
                "value": 36847,
                "label": "Vechta",
                "label_ar": "فيشتا",
                "label_fr": "Vechta"
            },
            {
                "value": 36848,
                "label": "Wittmund",
                "label_ar": "ويتموند",
                "label_fr": "Wittmund"
            },
            {
                "value": 36849,
                "label": "Delmenhorst",
                "label_ar": "دلمنهورست",
                "label_fr": "Delmenhorst"
            },
            {
                "value": 36850,
                "label": "Nuremberg",
                "label_ar": "نورمبرغ",
                "label_fr": "Nuremberg"
            },
            {
                "value": 36851,
                "label": "Stein",
                "label_ar": "شتاين",
                "label_fr": "Stein"
            },
            {
                "value": 36852,
                "label": "Gunzenhausen",
                "label_ar": "Gunzenhausen",
                "label_fr": "Gunzenhausen"
            },
            {
                "value": 36853,
                "label": "Erlangen",
                "label_ar": "إرلنغن",
                "label_fr": "Erlangen"
            },
            {
                "value": 36854,
                "label": "Oberasbach",
                "label_ar": "أوبراسباخ",
                "label_fr": "Oberasbach"
            },
            {
                "value": 36855,
                "label": "Neumarkt-Sankt Veit",
                "label_ar": "نيوماركت سانكت فيت",
                "label_fr": "Neumarkt-Sankt Veit"
            },
            {
                "value": 36856,
                "label": "Burgthann",
                "label_ar": "بورغثان",
                "label_fr": "Burgthann"
            },
            {
                "value": 36857,
                "label": "Herzogenaurach",
                "label_ar": "هيرتسوجيناوراخ",
                "label_fr": "Herzogenaurach"
            },
            {
                "value": 36858,
                "label": "Feucht",
                "label_ar": "Feucht",
                "label_fr": "Feucht"
            },
            {
                "value": 36859,
                "label": "Ansbach",
                "label_ar": "أنسباخ",
                "label_fr": "Ansbach"
            },
            {
                "value": 36860,
                "label": "Bamberg",
                "label_ar": "بامبرج",
                "label_fr": "Bamberg"
            },
            {
                "value": 36861,
                "label": "Lauf an der Pegnitz",
                "label_ar": "Lauf an der Pegnitz",
                "label_fr": "Lauf an der Pegnitz"
            },
            {
                "value": 36862,
                "label": "Gnotzheim",
                "label_ar": "غنوتزهايم",
                "label_fr": "Gnotzheim"
            },
            {
                "value": 36863,
                "label": "Amberg",
                "label_ar": "أمبرج",
                "label_fr": "Amberg"
            },
            {
                "value": 36864,
                "label": "Fürth",
                "label_ar": "فورث",
                "label_fr": "Fürth"
            },
            {
                "value": 36865,
                "label": "Katzwang",
                "label_ar": "كاتزوانج",
                "label_fr": "Katzwang"
            },
            {
                "value": 36866,
                "label": "Schwarzenbruck",
                "label_ar": "شوارزنبروك",
                "label_fr": "Schwarzenbruck"
            },
            {
                "value": 36867,
                "label": "Roth",
                "label_ar": "روث",
                "label_fr": "Roth"
            },
            {
                "value": 36868,
                "label": "Schwabach",
                "label_ar": "شواباخ",
                "label_fr": "Schwabach"
            },
            {
                "value": 36869,
                "label": "Sulzbach-Rosenberg",
                "label_ar": "سولزباخ روزنبرغ",
                "label_fr": "Sulzbach-Rosenberg"
            },
            {
                "value": 36870,
                "label": "Heroldsberg",
                "label_ar": "هيرولدسبيرغ",
                "label_fr": "Heroldsberg"
            },
            {
                "value": 36871,
                "label": "Kummersbruck",
                "label_ar": "كومرسبروك",
                "label_fr": "Kummersbruck"
            },
            {
                "value": 36872,
                "label": "Rosstal",
                "label_ar": "روزستال",
                "label_fr": "Rosstal"
            },
            {
                "value": 36873,
                "label": "Neunkirchen am Sand",
                "label_ar": "نيونكيرشن آم ساند",
                "label_fr": "Neunkirchen am Sand"
            },
            {
                "value": 36874,
                "label": "Baiersdorf",
                "label_ar": "بايرسدورف",
                "label_fr": "Baiersdorf"
            },
            {
                "value": 36875,
                "label": "Zirndorf",
                "label_ar": "زيرندورف",
                "label_fr": "Zirndorf"
            },
            {
                "value": 36876,
                "label": "Pegnitz",
                "label_ar": "بيجنيتز",
                "label_fr": "Pegnitz"
            },
            {
                "value": 36877,
                "label": "Coburg",
                "label_ar": "كوبورغ",
                "label_fr": "Cobourg"
            },
            {
                "value": 36878,
                "label": "Schwerte",
                "label_ar": "شويرت",
                "label_fr": "Schwerte"
            },
            {
                "value": 36879,
                "label": "Unna",
                "label_ar": "أونا",
                "label_fr": "Unna"
            },
            {
                "value": 36880,
                "label": "Werl",
                "label_ar": "ويرل",
                "label_fr": "Werl"
            },
            {
                "value": 36881,
                "label": "Waltrop",
                "label_ar": "والتروب",
                "label_fr": "Waltrop"
            },
            {
                "value": 36882,
                "label": "Munich",
                "label_ar": "ميونيخ",
                "label_fr": "Munich"
            },
            {
                "value": 36883,
                "label": "Ahlen",
                "label_ar": "اهلين",
                "label_fr": "Ahlen"
            },
            {
                "value": 36884,
                "label": "Lüdenscheid",
                "label_ar": "Lüdenscheid",
                "label_fr": "Lüdenscheid"
            },
            {
                "value": 36885,
                "label": "Arnsberg",
                "label_ar": "ارنسبرج",
                "label_fr": "Arnsberg"
            },
            {
                "value": 36886,
                "label": "Werne",
                "label_ar": "ويرن",
                "label_fr": "Werne"
            },
            {
                "value": 36887,
                "label": "Kierspe",
                "label_ar": "كيرسبي",
                "label_fr": "Kierspe"
            },
            {
                "value": 36888,
                "label": "Iserlohn",
                "label_ar": "إيسيرلون",
                "label_fr": "Iserlohn"
            },
            {
                "value": 36889,
                "label": "Marienheide",
                "label_ar": "مارينهايد",
                "label_fr": "Marienheide"
            },
            {
                "value": 36890,
                "label": "Beckum",
                "label_ar": "بيكوم",
                "label_fr": "Beckum"
            },
            {
                "value": 36891,
                "label": "Warendorf",
                "label_ar": "وارندورف",
                "label_fr": "Warendorf"
            },
            {
                "value": 36892,
                "label": "Olsberg",
                "label_ar": "أولسبيرج",
                "label_fr": "Olsberg"
            },
            {
                "value": 36893,
                "label": "Duisburg",
                "label_ar": "دويسبورغ",
                "label_fr": "Duisbourg"
            },
            {
                "value": 36894,
                "label": "Sundern",
                "label_ar": "سوندرن",
                "label_fr": "Sundern"
            },
            {
                "value": 36895,
                "label": "Lünen",
                "label_ar": "لونين",
                "label_fr": "Lünen"
            },
            {
                "value": 36896,
                "label": "Dorsten",
                "label_ar": "دورستن",
                "label_fr": "Dorsten"
            },
            {
                "value": 36897,
                "label": "Frondenberg",
                "label_ar": "Frondenberg",
                "label_fr": "Frondenberg"
            },
            {
                "value": 36898,
                "label": "Wickede",
                "label_ar": "ويكيد",
                "label_fr": "Wickede"
            },
            {
                "value": 36899,
                "label": "Brilon",
                "label_ar": "بريلون",
                "label_fr": "Brilon"
            },
            {
                "value": 36900,
                "label": "Herdecke",
                "label_ar": "هيرديك",
                "label_fr": "Herdecke"
            },
            {
                "value": 36901,
                "label": "Menden",
                "label_ar": "مندن",
                "label_fr": "Menden"
            },
            {
                "value": 36902,
                "label": "Holzwickede",
                "label_ar": "هولزويكيد",
                "label_fr": "Holzwickede"
            },
            {
                "value": 36903,
                "label": "Hemer",
                "label_ar": "هيمر",
                "label_fr": "Hemer"
            },
            {
                "value": 36904,
                "label": "Deutsch",
                "label_ar": "الألمانية",
                "label_fr": "Deutsch"
            },
            {
                "value": 36905,
                "label": "Kamen",
                "label_ar": "كامين",
                "label_fr": "Kamen"
            },
            {
                "value": 36906,
                "label": "Bingen am Rhein",
                "label_ar": "بينجن ام راين",
                "label_fr": "Bingen am Rhein"
            },
            {
                "value": 36907,
                "label": "Bobenheim-Roxheim",
                "label_ar": "بوبنهايم روكسهايم",
                "label_fr": "Bobenheim-Roxheim"
            },
            {
                "value": 36908,
                "label": "Pirmasens",
                "label_ar": "بيرماسينس",
                "label_fr": "Pirmasens"
            },
            {
                "value": 36909,
                "label": "Eisenberg",
                "label_ar": "أيزنبرغ",
                "label_fr": "Eisenberg"
            },
            {
                "value": 36910,
                "label": "Speyer",
                "label_ar": "شباير",
                "label_fr": "Speyer"
            },
            {
                "value": 36911,
                "label": "Landstuhl",
                "label_ar": "لاندشتول",
                "label_fr": "Landstuhl"
            },
            {
                "value": 36912,
                "label": "Viernheim",
                "label_ar": "فيرنهايم",
                "label_fr": "Viernheim"
            },
            {
                "value": 36913,
                "label": "Bad Kreuznach",
                "label_ar": "سيئة كروزناتش",
                "label_fr": "Bad Kreuznach"
            },
            {
                "value": 36914,
                "label": "Hettenleidelheim",
                "label_ar": "Hettenleidelheim",
                "label_fr": "Hettenleidelheim"
            },
            {
                "value": 36915,
                "label": "Frankenthal",
                "label_ar": "فرانكنثال",
                "label_fr": "Frankenthal"
            },
            {
                "value": 36916,
                "label": "Homburg",
                "label_ar": "هومبورغ",
                "label_fr": "Homburg"
            },
            {
                "value": 36917,
                "label": "Weinheim",
                "label_ar": "وينهايم",
                "label_fr": "Weinheim"
            },
            {
                "value": 36918,
                "label": "Dudenhofen",
                "label_ar": "Dudenhofen",
                "label_fr": "Dudenhofen"
            },
            {
                "value": 36919,
                "label": "Mutterstadt",
                "label_ar": "موترشتات",
                "label_fr": "Mutterstadt"
            },
            {
                "value": 36920,
                "label": "Grunstadt",
                "label_ar": "جرونشتات",
                "label_fr": "Grunstadt"
            },
            {
                "value": 36921,
                "label": "Munster-Sarmsheim",
                "label_ar": "مونستر سارمشيم",
                "label_fr": "Munster-Sarmsheim"
            },
            {
                "value": 36922,
                "label": "Rodalben",
                "label_ar": "رودالبين",
                "label_fr": "Rodalben"
            },
            {
                "value": 36923,
                "label": "Zweibrücken",
                "label_ar": "Zweibrücken",
                "label_fr": "Zweibrücken"
            },
            {
                "value": 36924,
                "label": "Neustadt/Westerwald",
                "label_ar": "نويشتات / فيسترفالد",
                "label_fr": "Neustadt / Westerwald"
            },
            {
                "value": 36925,
                "label": "Birkenau",
                "label_ar": "بيركيناو",
                "label_fr": "Birkenau"
            },
            {
                "value": 36926,
                "label": "Germersheim",
                "label_ar": "جيرميرشيم",
                "label_fr": "Germersheim"
            },
            {
                "value": 36927,
                "label": "Altrip",
                "label_ar": "التريب",
                "label_fr": "Altrip"
            },
            {
                "value": 36928,
                "label": "Schriesheim",
                "label_ar": "شريسهايم",
                "label_fr": "Schriesheim"
            },
            {
                "value": 36929,
                "label": "Hordt",
                "label_ar": "هوردت",
                "label_fr": "Hordt"
            },
            {
                "value": 36930,
                "label": "Ramstein-Miesenbach",
                "label_ar": "رامشتاين ميسينباخ",
                "label_fr": "Ramstein-Miesenbach"
            },
            {
                "value": 36931,
                "label": "Mehlbach",
                "label_ar": "مهلباخ",
                "label_fr": "Mehlbach"
            },
            {
                "value": 36932,
                "label": "Langen",
                "label_ar": "لانجين",
                "label_fr": "Langen"
            },
            {
                "value": 36933,
                "label": "Neustadt",
                "label_ar": "نيوشتات",
                "label_fr": "Neustadt"
            },
            {
                "value": 36934,
                "label": "Heidelberg",
                "label_ar": "هايدلبرغ",
                "label_fr": "Heidelberg"
            },
            {
                "value": 36935,
                "label": "Neckargemund",
                "label_ar": "نيكارجيموند",
                "label_fr": "Neckargemund"
            },
            {
                "value": 36936,
                "label": "Leimen",
                "label_ar": "ليمن",
                "label_fr": "Leimen"
            },
            {
                "value": 36937,
                "label": "Dannstadt-Schauernheim",
                "label_ar": "Dannstadt-Schauernheim",
                "label_fr": "Dannstadt-Schauernheim"
            },
            {
                "value": 36938,
                "label": "Schifferstadt",
                "label_ar": "شيفرشتات",
                "label_fr": "Schifferstadt"
            },
            {
                "value": 36939,
                "label": "Limburgerhof",
                "label_ar": "ليمبورجرهوف",
                "label_fr": "Limburgerhof"
            },
            {
                "value": 36940,
                "label": "Eppelheim",
                "label_ar": "إبيلهايم",
                "label_fr": "Eppelheim"
            },
            {
                "value": 36941,
                "label": "Bad Mergentheim",
                "label_ar": "باد ميرجينثيم",
                "label_fr": "Bad Mergentheim"
            },
            {
                "value": 36942,
                "label": "Schwetzingen",
                "label_ar": "شفيتزنجن",
                "label_fr": "Schwetzingen"
            },
            {
                "value": 36943,
                "label": "Lampertheim",
                "label_ar": "لامبيرثيم",
                "label_fr": "Lampertheim"
            },
            {
                "value": 36944,
                "label": "Dossenheim",
                "label_ar": "دوسنهايم",
                "label_fr": "Dossenheim"
            },
            {
                "value": 36945,
                "label": "Nussloch",
                "label_ar": "نوسلوخ",
                "label_fr": "Nussloch"
            },
            {
                "value": 36946,
                "label": "Ellerstadt",
                "label_ar": "Ellerstadt",
                "label_fr": "Ellerstadt"
            },
            {
                "value": 36947,
                "label": "Neuhofen",
                "label_ar": "نيوهوفن",
                "label_fr": "Neuhofen"
            },
            {
                "value": 36948,
                "label": "Kahl am Main",
                "label_ar": "كال عم مين",
                "label_fr": "Kahl am Main"
            },
            {
                "value": 36949,
                "label": "Ketsch",
                "label_ar": "كيتش",
                "label_fr": "Ketsch"
            },
            {
                "value": 36950,
                "label": "Rodersheim-Gronau",
                "label_ar": "رودرشيم جروناو",
                "label_fr": "Rodersheim-Gronau"
            },
            {
                "value": 36951,
                "label": "Rubke",
                "label_ar": "روبيك",
                "label_fr": "Rubke"
            },
            {
                "value": 36952,
                "label": "Naila",
                "label_ar": "نائلة",
                "label_fr": "Naila"
            },
            {
                "value": 36953,
                "label": "Steinwiesen",
                "label_ar": "شتاينويسين",
                "label_fr": "Steinwiesen"
            },
            {
                "value": 36954,
                "label": "Selbitz",
                "label_ar": "سيلبيتز",
                "label_fr": "Selbitz"
            },
            {
                "value": 36955,
                "label": "Radolfzell",
                "label_ar": "رادولفزيل",
                "label_fr": "Radolfzell"
            },
            {
                "value": 36956,
                "label": "Konstanz",
                "label_ar": "كونستانس",
                "label_fr": "Constance"
            },
            {
                "value": 36957,
                "label": "Rastatt",
                "label_ar": "راستات",
                "label_fr": "Rastatt"
            },
            {
                "value": 36958,
                "label": "Rulzheim",
                "label_ar": "رولزهايم",
                "label_fr": "Rulzheim"
            },
            {
                "value": 36959,
                "label": "Schwäbisch Hall",
                "label_ar": "شفيبيش هول",
                "label_fr": "Salle Schwäbisch"
            },
            {
                "value": 36960,
                "label": "Gaildorf",
                "label_ar": "جيلدورف",
                "label_fr": "Gaildorf"
            },
            {
                "value": 36961,
                "label": "Giengen an der Brenz",
                "label_ar": "Giengen an der Brenz",
                "label_fr": "Giengen an der Brenz"
            },
            {
                "value": 36962,
                "label": "Lauchheim",
                "label_ar": "لاوشيم",
                "label_fr": "Lauchheim"
            },
            {
                "value": 36963,
                "label": "Wasser",
                "label_ar": "واسر",
                "label_fr": "Wasser"
            },
            {
                "value": 36964,
                "label": "Freiburg",
                "label_ar": "فرايبورغ",
                "label_fr": "Fribourg"
            },
            {
                "value": 36965,
                "label": "Bad Krozingen",
                "label_ar": "باد كروزينجين",
                "label_fr": "Bad Krozingen"
            },
            {
                "value": 36966,
                "label": "Schopfheim",
                "label_ar": "شوبفهايم",
                "label_fr": "Schopfheim"
            },
            {
                "value": 36967,
                "label": "Lörrach",
                "label_ar": "لوراتش",
                "label_fr": "Lörrach"
            },
            {
                "value": 36968,
                "label": "Bad Sackingen",
                "label_ar": "باد ساكينجين",
                "label_fr": "Bad Sackingen"
            },
            {
                "value": 36969,
                "label": "Leinfelden-Echterdingen",
                "label_ar": "لينفيلدن إشتردينجن",
                "label_fr": "Leinfelden-Echterdingen"
            },
            {
                "value": 36970,
                "label": "Echterdingen",
                "label_ar": "اشتردينجن",
                "label_fr": "Echterdingen"
            },
            {
                "value": 36971,
                "label": "Wuppertal",
                "label_ar": "فوبرتال",
                "label_fr": "Wuppertal"
            },
            {
                "value": 36972,
                "label": "Krefeld",
                "label_ar": "كريفيلد",
                "label_fr": "Krefeld"
            },
            {
                "value": 36973,
                "label": "Alfter",
                "label_ar": "بعد",
                "label_fr": "Après"
            },
            {
                "value": 36974,
                "label": "Hilchenbach",
                "label_ar": "هيلشينباخ",
                "label_fr": "Hilchenbach"
            },
            {
                "value": 36975,
                "label": "Kaarst",
                "label_ar": "كارست",
                "label_fr": "Kaarst"
            },
            {
                "value": 36976,
                "label": "Winterberg",
                "label_ar": "وينتربرغ",
                "label_fr": "Winterberg"
            },
            {
                "value": 36977,
                "label": "Steinbach am Taunus",
                "label_ar": "Steinbach am Taunus",
                "label_fr": "Steinbach am Taunus"
            },
            {
                "value": 36978,
                "label": "Remscheid",
                "label_ar": "ريمشايد",
                "label_fr": "Remscheid"
            },
            {
                "value": 36979,
                "label": "Rheinbach",
                "label_ar": "راينباخ",
                "label_fr": "Rheinbach"
            },
            {
                "value": 36980,
                "label": "Preussisch Oldendorf",
                "label_ar": "Preussisch Oldendorf",
                "label_fr": "Preussisch Oldendorf"
            },
            {
                "value": 36981,
                "label": "Huellhorst",
                "label_ar": "هويلهورست",
                "label_fr": "Huellhorst"
            },
            {
                "value": 36982,
                "label": "Grossbottwar",
                "label_ar": "غروسبوتوار",
                "label_fr": "Grossbottwar"
            },
            {
                "value": 36983,
                "label": "Erftstadt",
                "label_ar": "إرفتشتات",
                "label_fr": "Erftstadt"
            },
            {
                "value": 36984,
                "label": "Burgkunstadt",
                "label_ar": "بورغكونشتات",
                "label_fr": "Burgkunstadt"
            },
            {
                "value": 36985,
                "label": "Langfurth",
                "label_ar": "لانغفورث",
                "label_fr": "Langfurth"
            },
            {
                "value": 36986,
                "label": "Gleissenberg",
                "label_ar": "جليسنبيرج",
                "label_fr": "Gleissenberg"
            },
            {
                "value": 36987,
                "label": "Viechtach",
                "label_ar": "فيختاش",
                "label_fr": "Viechtach"
            },
            {
                "value": 36988,
                "label": "Nittenau",
                "label_ar": "نيتيناو",
                "label_fr": "Nittenau"
            },
            {
                "value": 36989,
                "label": "Chamerau",
                "label_ar": "شاميراو",
                "label_fr": "Chamerau"
            },
            {
                "value": 36990,
                "label": "Jena",
                "label_ar": "جينا",
                "label_fr": "Iéna"
            },
            {
                "value": 36991,
                "label": "Bad Durrenberg",
                "label_ar": "باد دورنبرغ",
                "label_fr": "Bad Durrenberg"
            },
            {
                "value": 36992,
                "label": "Oranienburg",
                "label_ar": "أورانينبورغ",
                "label_fr": "Oranienburg"
            },
            {
                "value": 36993,
                "label": "Bernburg",
                "label_ar": "بيرنبورج",
                "label_fr": "Bernbourg"
            },
            {
                "value": 36994,
                "label": "Wernigerode",
                "label_ar": "ويرنيجرود",
                "label_fr": "Wernigerode"
            },
            {
                "value": 36995,
                "label": "Cottbus",
                "label_ar": "كوتبوس",
                "label_fr": "Cottbus"
            },
            {
                "value": 36996,
                "label": "Lubbenau",
                "label_ar": "لوبيناو",
                "label_fr": "Lubbenau"
            },
            {
                "value": 36997,
                "label": "Schonefeld",
                "label_ar": "شونفيلد",
                "label_fr": "Schonefeld"
            },
            {
                "value": 36998,
                "label": "Plauen",
                "label_ar": "بلاوين",
                "label_fr": "Plauen"
            },
            {
                "value": 36999,
                "label": "Bodenfelde",
                "label_ar": "بودنفيلد",
                "label_fr": "Bodenfelde"
            },
            {
                "value": 37000,
                "label": "Wolfhagen",
                "label_ar": "ولفهاجين",
                "label_fr": "Wolfhagen"
            },
            {
                "value": 37001,
                "label": "Host",
                "label_ar": "مضيف",
                "label_fr": "Hôte"
            },
            {
                "value": 37002,
                "label": "Kirchheim unter Teck",
                "label_ar": "كيرشهايم أونتر تيك",
                "label_fr": "Kirchheim unter Teck"
            },
            {
                "value": 37003,
                "label": "Kurnbach",
                "label_ar": "كورنباخ",
                "label_fr": "Kurnbach"
            },
            {
                "value": 37004,
                "label": "Weiden",
                "label_ar": "Weiden",
                "label_fr": "Weiden"
            },
            {
                "value": 37005,
                "label": "Hemmingen",
                "label_ar": "هيمنجين",
                "label_fr": "Hemmingen"
            },
            {
                "value": 37006,
                "label": "Igensdorf",
                "label_ar": "إيجنسدورف",
                "label_fr": "Igensdorf"
            },
            {
                "value": 37007,
                "label": "Volkach",
                "label_ar": "فولكاتش",
                "label_fr": "Volkach"
            },
            {
                "value": 37008,
                "label": "Bergtheim",
                "label_ar": "بيرجثيم",
                "label_fr": "Bergtheim"
            },
            {
                "value": 37009,
                "label": "Sulzheim",
                "label_ar": "سولزهايم",
                "label_fr": "Sulzheim"
            },
            {
                "value": 37010,
                "label": "Baar-Ebenhausen",
                "label_ar": "بار إبنهاوزن",
                "label_fr": "Baar-Ebenhausen"
            },
            {
                "value": 37011,
                "label": "Beilngries",
                "label_ar": "بيلنغريز",
                "label_fr": "Beilngries"
            },
            {
                "value": 37012,
                "label": "Altmannstein",
                "label_ar": "التمانستين",
                "label_fr": "Altmannstein"
            },
            {
                "value": 37013,
                "label": "Rottweil",
                "label_ar": "روتويل",
                "label_fr": "Rottweil"
            },
            {
                "value": 37014,
                "label": "Blumberg",
                "label_ar": "بلومبرج",
                "label_fr": "Blumberg"
            },
            {
                "value": 37015,
                "label": "Dauchingen",
                "label_ar": "دوتشينجين",
                "label_fr": "Dauchingen"
            },
            {
                "value": 37016,
                "label": "Lauda-Konigshofen",
                "label_ar": "لاودا كونيجسهوفن",
                "label_fr": "Lauda-Konigshofen"
            },
            {
                "value": 37017,
                "label": "Tauberbischofsheim",
                "label_ar": "Tauberbischofsheim",
                "label_fr": "Tauberbischofsheim"
            },
            {
                "value": 37018,
                "label": "Wertheim am Main",
                "label_ar": "Wertheim am Main",
                "label_fr": "Wertheim am Main"
            },
            {
                "value": 37019,
                "label": "Crailsheim",
                "label_ar": "كريلسهايم",
                "label_fr": "Crailsheim"
            },
            {
                "value": 37020,
                "label": "Neckarsulm",
                "label_ar": "نيكارسولم",
                "label_fr": "Neckarsulm"
            },
            {
                "value": 37021,
                "label": "Bretzfeld",
                "label_ar": "بريتزفيلد",
                "label_fr": "Bretzfeld"
            },
            {
                "value": 37022,
                "label": "Heilbronn",
                "label_ar": "هايلبرون",
                "label_fr": "Heilbronn"
            },
            {
                "value": 37023,
                "label": "Rheinfelden",
                "label_ar": "راينفيلدن",
                "label_fr": "Rheinfelden"
            },
            {
                "value": 37024,
                "label": "Tuttlingen",
                "label_ar": "توتلينجن",
                "label_fr": "Tuttlingen"
            },
            {
                "value": 37025,
                "label": "Fridingen an der Donau",
                "label_ar": "Fridingen an der Donau",
                "label_fr": "Fridingen an der Donau"
            },
            {
                "value": 37026,
                "label": "Owingen",
                "label_ar": "أوينجن",
                "label_fr": "Owingen"
            },
            {
                "value": 37027,
                "label": "Berghausen",
                "label_ar": "بيرجهاوزن",
                "label_fr": "Berghausen"
            },
            {
                "value": 37028,
                "label": "Appenweier",
                "label_ar": "أبنوير",
                "label_fr": "Appenweier"
            },
            {
                "value": 37029,
                "label": "Bruchsal",
                "label_ar": "بروكسال",
                "label_fr": "Bruchsal"
            },
            {
                "value": 37030,
                "label": "Gondelsheim",
                "label_ar": "جوندلشيم",
                "label_fr": "Gondelsheim"
            },
            {
                "value": 37031,
                "label": "Friolzheim",
                "label_ar": "فريولزهايم",
                "label_fr": "Friolzheim"
            },
            {
                "value": 37032,
                "label": "Bad Wildbad im Schwarzwald",
                "label_ar": "باد فيلدباد إم شوارزوالد",
                "label_fr": "Bad Wildbad im Schwarzwald"
            },
            {
                "value": 37033,
                "label": "Olbronn-Durrn",
                "label_ar": "أولبرون دورن",
                "label_fr": "Olbronn-Durrn"
            },
            {
                "value": 37034,
                "label": "Wildberg",
                "label_ar": "وايلدبرغ",
                "label_fr": "Wildberg"
            },
            {
                "value": 37035,
                "label": "Althengstett",
                "label_ar": "Althengstett",
                "label_fr": "Althengstett"
            },
            {
                "value": 37036,
                "label": "Ludwigsburg",
                "label_ar": "لودفيغسبورغ",
                "label_fr": "Ludwigsburg"
            },
            {
                "value": 37037,
                "label": "Tamm",
                "label_ar": "تم",
                "label_fr": "Tamm"
            },
            {
                "value": 37038,
                "label": "Sindelfingen",
                "label_ar": "سينديلفينجين",
                "label_fr": "Sindelfingen"
            },
            {
                "value": 37039,
                "label": "Herrenberg",
                "label_ar": "هيرينبرغ",
                "label_fr": "Herrenberg"
            },
            {
                "value": 37040,
                "label": "Ehningen",
                "label_ar": "Ehningen",
                "label_fr": "Ehningen"
            },
            {
                "value": 37041,
                "label": "Renningen",
                "label_ar": "ريننينجين",
                "label_fr": "Renningen"
            },
            {
                "value": 37042,
                "label": "Gosheim",
                "label_ar": "جوشيم",
                "label_fr": "Gosheim"
            },
            {
                "value": 37043,
                "label": "Bad Durrheim",
                "label_ar": "باد دورهايم",
                "label_fr": "Bad Durrheim"
            },
            {
                "value": 37044,
                "label": "Wehingen",
                "label_ar": "وينجين",
                "label_fr": "Wehingen"
            },
            {
                "value": 37045,
                "label": "Villingen-Schwenningen",
                "label_ar": "فيلينجين شوينينجن",
                "label_fr": "Villingen-Schwenningen"
            },
            {
                "value": 37046,
                "label": "Esslingen am Neckar",
                "label_ar": "إيسلينجين آم نيكار",
                "label_fr": "Esslingen am Neckar"
            },
            {
                "value": 37047,
                "label": "Ellwangen",
                "label_ar": "Ellwangen",
                "label_fr": "Ellwangen"
            },
            {
                "value": 37048,
                "label": "Wiesloch",
                "label_ar": "ويسلوخ",
                "label_fr": "Wiesloch"
            },
            {
                "value": 37049,
                "label": "Friedrichshafen",
                "label_ar": "فريدريشهافين",
                "label_fr": "Friedrichshafen"
            },
            {
                "value": 37050,
                "label": "Tettnang Castle",
                "label_ar": "قلعة Tettnang",
                "label_fr": "Château de Tettnang"
            },
            {
                "value": 37051,
                "label": "Epfendorf",
                "label_ar": "ابفندورف",
                "label_fr": "Epfendorf"
            },
            {
                "value": 37052,
                "label": "Bohringen",
                "label_ar": "بورينجن",
                "label_fr": "Bohringen"
            },
            {
                "value": 37053,
                "label": "Horb am Neckar",
                "label_ar": "هورب آم نيكار",
                "label_fr": "Horb am Neckar"
            },
            {
                "value": 37054,
                "label": "Bad Uberkingen",
                "label_ar": "سيئة Uberkingen",
                "label_fr": "Bad Uberkingen"
            },
            {
                "value": 37055,
                "label": "Göppingen",
                "label_ar": "غوبينغن",
                "label_fr": "Göppingen"
            },
            {
                "value": 37056,
                "label": "Gladbeck",
                "label_ar": "جلادبك",
                "label_fr": "Gladbeck"
            },
            {
                "value": 37057,
                "label": "Herten",
                "label_ar": "هيرتن",
                "label_fr": "Herten"
            },
            {
                "value": 37058,
                "label": "Gera",
                "label_ar": "جيرا",
                "label_fr": "Gera"
            },
            {
                "value": 37059,
                "label": "Stahl",
                "label_ar": "ستال",
                "label_fr": "Stahl"
            },
            {
                "value": 37060,
                "label": "Wintrich",
                "label_ar": "وينتريش",
                "label_fr": "Wintrich"
            },
            {
                "value": 37061,
                "label": "Hermersberg",
                "label_ar": "هيرمرسبرج",
                "label_fr": "Hermersberg"
            },
            {
                "value": 37062,
                "label": "Thallichtenberg",
                "label_ar": "ثالشتنبرغ",
                "label_fr": "Thallichtenberg"
            },
            {
                "value": 37063,
                "label": "Merzig",
                "label_ar": "مرزيق",
                "label_fr": "Merzig"
            },
            {
                "value": 37064,
                "label": "Saarlouis",
                "label_ar": "سارلويس",
                "label_fr": "Saarlouis"
            },
            {
                "value": 37065,
                "label": "Engen",
                "label_ar": "إنجين",
                "label_fr": "Engen"
            },
            {
                "value": 37066,
                "label": "Kaufungen",
                "label_ar": "كاوفونجن",
                "label_fr": "Kaufungen"
            },
            {
                "value": 37067,
                "label": "Aachen",
                "label_ar": "آخن",
                "label_fr": "Aix-la-Chapelle"
            },
            {
                "value": 37068,
                "label": "Herzogenrath",
                "label_ar": "هيرتسوجينراث",
                "label_fr": "Herzogenrath"
            },
            {
                "value": 37069,
                "label": "Frechen",
                "label_ar": "فريتشن",
                "label_fr": "Frechen"
            },
            {
                "value": 37070,
                "label": "Wetzlar",
                "label_ar": "ويتزلار",
                "label_fr": "Wetzlar"
            },
            {
                "value": 37071,
                "label": "Herne",
                "label_ar": "هيرن",
                "label_fr": "Herne"
            },
            {
                "value": 37072,
                "label": "Babenhausen",
                "label_ar": "بابنهاوزن",
                "label_fr": "Babenhausen"
            },
            {
                "value": 37073,
                "label": "Darmstadt",
                "label_ar": "دارمشتات",
                "label_fr": "Darmstadt"
            },
            {
                "value": 37074,
                "label": "Morfelden-Walldorf",
                "label_ar": "مورفيلدن فالدورف",
                "label_fr": "Morfelden-Walldorf"
            },
            {
                "value": 37075,
                "label": "Taunusstein",
                "label_ar": "تاونوسشتاين",
                "label_fr": "Taunusstein"
            },
            {
                "value": 37076,
                "label": "Offenbach",
                "label_ar": "أوفنباخ",
                "label_fr": "Offenbach"
            },
            {
                "value": 37077,
                "label": "Oberhausen",
                "label_ar": "أوبرهاوزن",
                "label_fr": "Oberhausen"
            },
            {
                "value": 37078,
                "label": "Castrop-Rauxel",
                "label_ar": "كاستروب روكسيل",
                "label_fr": "Castrop-Rauxel"
            },
            {
                "value": 37079,
                "label": "Oberursel",
                "label_ar": "أوبرأورسل",
                "label_fr": "Oberursel"
            },
            {
                "value": 37080,
                "label": "Bad Soden am Taunus",
                "label_ar": "Bad Soden am Taunus",
                "label_fr": "Bad Soden am Taunus"
            },
            {
                "value": 37081,
                "label": "Jestadt",
                "label_ar": "جيشتات",
                "label_fr": "Jestadt"
            },
            {
                "value": 37082,
                "label": "Minden",
                "label_ar": "ميندن",
                "label_fr": "Minden"
            },
            {
                "value": 37083,
                "label": "Wesseling",
                "label_ar": "ويسلينج",
                "label_fr": "Wesseling"
            },
            {
                "value": 37084,
                "label": "Inden",
                "label_ar": "إندين",
                "label_fr": "Inden"
            },
            {
                "value": 37085,
                "label": "Hattingen",
                "label_ar": "هاتنغن",
                "label_fr": "Hattingen"
            },
            {
                "value": 37086,
                "label": "Kelkheim (Taunus)",
                "label_ar": "كيلكهايم (تاونوس)",
                "label_fr": "Kelkheim (Taunus)"
            },
            {
                "value": 37087,
                "label": "Wiesbaden",
                "label_ar": "فيسبادن",
                "label_fr": "Wiesbaden"
            },
            {
                "value": 37088,
                "label": "Kall",
                "label_ar": "كال",
                "label_fr": "Kall"
            },
            {
                "value": 37089,
                "label": "Mechernich",
                "label_ar": "ميشرنيش",
                "label_fr": "Mechernich"
            },
            {
                "value": 37090,
                "label": "Sankt Augustin",
                "label_ar": "سانكت أوغستين",
                "label_fr": "Sankt Augustin"
            },
            {
                "value": 37091,
                "label": "Bonn",
                "label_ar": "بون",
                "label_fr": "Bonn"
            },
            {
                "value": 37092,
                "label": "Leichlingen",
                "label_ar": "ليتشلينجن",
                "label_fr": "Leichlingen"
            },
            {
                "value": 37093,
                "label": "Leverkusen",
                "label_ar": "ليفركوزن",
                "label_fr": "Leverkusen"
            },
            {
                "value": 37094,
                "label": "Mülheim",
                "label_ar": "مولهايم",
                "label_fr": "Mülheim"
            },
            {
                "value": 37095,
                "label": "Bad Vilbel",
                "label_ar": "باد فيلبيل",
                "label_fr": "Bad Vilbel"
            },
            {
                "value": 37096,
                "label": "Kempen",
                "label_ar": "كيمبين",
                "label_fr": "Kempen"
            },
            {
                "value": 37097,
                "label": "Florsheim",
                "label_ar": "فلورشيم",
                "label_fr": "Florsheim"
            },
            {
                "value": 37098,
                "label": "Ginsheim-Gustavsburg",
                "label_ar": "جينشيم جوستافسبورغ",
                "label_fr": "Ginsheim-Gustavsburg"
            },
            {
                "value": 37099,
                "label": "Heusenstamm",
                "label_ar": "Heusenstamm",
                "label_fr": "Heusenstamm"
            },
            {
                "value": 37100,
                "label": "Hofheim am Taunus",
                "label_ar": "هوفهايم ام تاونوس",
                "label_fr": "Hofheim am Taunus"
            },
            {
                "value": 37101,
                "label": "Obertshausen",
                "label_ar": "أوبرتسهاوزن",
                "label_fr": "Obertshausen"
            },
            {
                "value": 37102,
                "label": "Nauheim",
                "label_ar": "نوهايم",
                "label_fr": "Nauheim"
            },
            {
                "value": 37103,
                "label": "Rüsselsheim",
                "label_ar": "روسلسهايم",
                "label_fr": "Rüsselsheim"
            },
            {
                "value": 37104,
                "label": "Hochheim am Main",
                "label_ar": "Hochheim am Main",
                "label_fr": "Hochheim am Main"
            },
            {
                "value": 37105,
                "label": "Kelsterbach",
                "label_ar": "كيلسترباخ",
                "label_fr": "Kelsterbach"
            },
            {
                "value": 37106,
                "label": "Bischofsheim",
                "label_ar": "بيشوفشيم",
                "label_fr": "Bischofsheim"
            },
            {
                "value": 37107,
                "label": "Assenheim",
                "label_ar": "أسنهايم",
                "label_fr": "Assenheim"
            },
            {
                "value": 37108,
                "label": "Kleve",
                "label_ar": "كليف",
                "label_fr": "Kleve"
            },
            {
                "value": 37109,
                "label": "Willich",
                "label_ar": "ويليش",
                "label_fr": "Willich"
            },
            {
                "value": 37110,
                "label": "Bedburg",
                "label_ar": "بيدبورغ",
                "label_fr": "Bedburg"
            },
            {
                "value": 37111,
                "label": "Senden",
                "label_ar": "سيندن",
                "label_fr": "Senden"
            },
            {
                "value": 37112,
                "label": "Friedberg",
                "label_ar": "فريدبرج",
                "label_fr": "Friedberg"
            },
            {
                "value": 37113,
                "label": "Eriskirch",
                "label_ar": "إريسكيرتش",
                "label_fr": "Eriskirch"
            },
            {
                "value": 37114,
                "label": "Ravensburg",
                "label_ar": "رافينسبورج",
                "label_fr": "Ravensburg"
            },
            {
                "value": 37115,
                "label": "Immenstaad am Bodensee",
                "label_ar": "Immenstaad am Bodensee",
                "label_fr": "Immenstaad am Bodensee"
            },
            {
                "value": 37116,
                "label": "Hockenheim",
                "label_ar": "هوكنهايم",
                "label_fr": "Hockenheim"
            },
            {
                "value": 37117,
                "label": "Albstadt",
                "label_ar": "البشتات",
                "label_fr": "Albstadt"
            },
            {
                "value": 37118,
                "label": "Wurmlingen",
                "label_ar": "وورملينجن",
                "label_fr": "Wurmlingen"
            },
            {
                "value": 37119,
                "label": "Rielasingen-Worblingen",
                "label_ar": "ريلاسينجين ووربلينجن",
                "label_fr": "Rielasingen-Worblingen"
            },
            {
                "value": 37120,
                "label": "Singen",
                "label_ar": "سينجين",
                "label_fr": "Singen"
            },
            {
                "value": 37121,
                "label": "Pfullendorf",
                "label_ar": "بفوليندورف",
                "label_fr": "Pfullendorf"
            },
            {
                "value": 37122,
                "label": "Waiblingen",
                "label_ar": "ويبلينجين",
                "label_fr": "Waiblingen"
            },
            {
                "value": 37123,
                "label": "Kuchen",
                "label_ar": "كوتشين",
                "label_fr": "Kuchen"
            },
            {
                "value": 37124,
                "label": "Salach",
                "label_ar": "سالاتش",
                "label_fr": "Salach"
            },
            {
                "value": 37125,
                "label": "Lorch",
                "label_ar": "ورش",
                "label_fr": "Lorch"
            },
            {
                "value": 37126,
                "label": "Gerlingen",
                "label_ar": "جيرلينجن",
                "label_fr": "Gerlingen"
            },
            {
                "value": 37127,
                "label": "Kressbronn am Bodensee",
                "label_ar": "Kressbronn am Bodensee",
                "label_fr": "Kressbronn am Bodensee"
            },
            {
                "value": 37128,
                "label": "Oftersheim",
                "label_ar": "أوفترسهايم",
                "label_fr": "Oftersheim"
            },
            {
                "value": 37129,
                "label": "Geisingen",
                "label_ar": "Geisingen",
                "label_fr": "Geisingen"
            },
            {
                "value": 37130,
                "label": "Wolfsburg",
                "label_ar": "فولفسبورج",
                "label_fr": "Wolfsburg"
            },
            {
                "value": 37131,
                "label": "Wahrenholz",
                "label_ar": "واهرينهولز",
                "label_fr": "Wahrenholz"
            },
            {
                "value": 37132,
                "label": "Braunschweig",
                "label_ar": "براونشفايغ",
                "label_fr": "Braunschweig"
            },
            {
                "value": 37133,
                "label": "Meine",
                "label_ar": "مين",
                "label_fr": "Meine"
            },
            {
                "value": 37134,
                "label": "Lentfohrden",
                "label_ar": "Lentfohrden",
                "label_fr": "Lentfohrden"
            },
            {
                "value": 37135,
                "label": "Holm",
                "label_ar": "هولم",
                "label_fr": "Holm"
            },
            {
                "value": 37136,
                "label": "Bonningstedt",
                "label_ar": "بونينجستيدت",
                "label_fr": "Bonningstedt"
            },
            {
                "value": 37137,
                "label": "Hasloh",
                "label_ar": "هاسلوه",
                "label_fr": "Hasloh"
            },
            {
                "value": 37138,
                "label": "Moritz",
                "label_ar": "موريتز",
                "label_fr": "Moritz"
            },
            {
                "value": 37139,
                "label": "Ulm",
                "label_ar": "أولم",
                "label_fr": "Ulm"
            },
            {
                "value": 37140,
                "label": "Neu-Ulm",
                "label_ar": "نيو أولم",
                "label_fr": "Neu-Ulm"
            },
            {
                "value": 37141,
                "label": "Rellingen",
                "label_ar": "ريلينجين",
                "label_fr": "Rellingen"
            },
            {
                "value": 37142,
                "label": "Erkner",
                "label_ar": "إركنر",
                "label_fr": "Erkner"
            },
            {
                "value": 37143,
                "label": "Neuenhagen",
                "label_ar": "نويننهاغن",
                "label_fr": "Neuenhagen"
            },
            {
                "value": 37144,
                "label": "Uelzen",
                "label_ar": "أولزين",
                "label_fr": "Uelzen"
            },
            {
                "value": 37145,
                "label": "Wustermark",
                "label_ar": "ويسترمارك",
                "label_fr": "Wustermark"
            },
            {
                "value": 37146,
                "label": "Spremberg",
                "label_ar": "سبرمبرج",
                "label_fr": "Spremberg"
            },
            {
                "value": 37147,
                "label": "Bestensee",
                "label_ar": "Bestensee",
                "label_fr": "Bestensee"
            },
            {
                "value": 37148,
                "label": "Woltersdorf",
                "label_ar": "ولترسدورف",
                "label_fr": "Woltersdorf"
            },
            {
                "value": 37149,
                "label": "Mittenwalde",
                "label_ar": "ميتينوالدي",
                "label_fr": "Mittenwalde"
            },
            {
                "value": 37150,
                "label": "Furstenwalde",
                "label_ar": "فورستينوالد",
                "label_fr": "Furstenwalde"
            },
            {
                "value": 37151,
                "label": "Cuxhaven",
                "label_ar": "كوكسهافن",
                "label_fr": "Cuxhaven"
            },
            {
                "value": 37152,
                "label": "Grevesmuhlen",
                "label_ar": "جريفسمولن",
                "label_fr": "Grevesmuhlen"
            },
            {
                "value": 37153,
                "label": "Wismar",
                "label_ar": "فيسمار",
                "label_fr": "Wismar"
            },
            {
                "value": 37154,
                "label": "Osnabrück",
                "label_ar": "أوسنابروك",
                "label_fr": "Osnabrück"
            },
            {
                "value": 37155,
                "label": "Rostock",
                "label_ar": "روستوك",
                "label_fr": "Rostock"
            },
            {
                "value": 37156,
                "label": "Bredstedt",
                "label_ar": "بريدستيدت",
                "label_fr": "Bredstedt"
            },
            {
                "value": 37157,
                "label": "Niebull",
                "label_ar": "نيبول",
                "label_fr": "Niebull"
            },
            {
                "value": 37158,
                "label": "Eckernforde",
                "label_ar": "إكرنفورد",
                "label_fr": "Eckernforde"
            },
            {
                "value": 37159,
                "label": "Georgsmarienhutte",
                "label_ar": "جورجسمارينهوت",
                "label_fr": "Georgsmarienhutte"
            },
            {
                "value": 37160,
                "label": "Bad Iburg",
                "label_ar": "باد ايبورغ",
                "label_fr": "Bad Iburg"
            },
            {
                "value": 37161,
                "label": "Hildesheim",
                "label_ar": "هيلدسهايم",
                "label_fr": "Hildesheim"
            },
            {
                "value": 37162,
                "label": "Hamelin",
                "label_ar": "هاملين",
                "label_fr": "Hamelin"
            },
            {
                "value": 37163,
                "label": "Hinte",
                "label_ar": "هينتي",
                "label_fr": "Hinte"
            },
            {
                "value": 37164,
                "label": "Emden",
                "label_ar": "إمدن",
                "label_fr": "Emden"
            },
            {
                "value": 37165,
                "label": "Goslar",
                "label_ar": "جوسلار",
                "label_fr": "Goslar"
            },
            {
                "value": 37166,
                "label": "Peine",
                "label_ar": "Peine",
                "label_fr": "Peine"
            },
            {
                "value": 37167,
                "label": "Helmstedt",
                "label_ar": "هيلمستيدت",
                "label_fr": "Helmstedt"
            },
            {
                "value": 37168,
                "label": "Konigslutter am Elm",
                "label_ar": "Konigslutter am Elm",
                "label_fr": "Konigslutter am Elm"
            },
            {
                "value": 37169,
                "label": "Göttingen",
                "label_ar": "جوتنجن",
                "label_fr": "Göttingen"
            },
            {
                "value": 37170,
                "label": "Stuhr",
                "label_ar": "ستور",
                "label_fr": "Stuhr"
            },
            {
                "value": 37171,
                "label": "Langen",
                "label_ar": "لانجين",
                "label_fr": "Langen"
            },
            {
                "value": 37172,
                "label": "Nordenham",
                "label_ar": "نوردنهام",
                "label_fr": "Nordenham"
            },
            {
                "value": 37173,
                "label": "Bremerhaven",
                "label_ar": "بريمرهافن",
                "label_fr": "Bremerhaven"
            },
            {
                "value": 37174,
                "label": "Lehrte",
                "label_ar": "ليرتي",
                "label_fr": "Lehrte"
            },
            {
                "value": 37175,
                "label": "Wolfenbüttel",
                "label_ar": "Wolfenbüttel",
                "label_fr": "Wolfenbüttel"
            },
            {
                "value": 37176,
                "label": "Ahstedt",
                "label_ar": "Ahstedt",
                "label_fr": "Ahstedt"
            },
            {
                "value": 37177,
                "label": "Nienburg",
                "label_ar": "نينبورغ",
                "label_fr": "Nienburg"
            },
            {
                "value": 37178,
                "label": "Rotenburg",
                "label_ar": "روتنبورغ",
                "label_fr": "Rotenburg"
            },
            {
                "value": 37179,
                "label": "Herzberg am Harz",
                "label_ar": "هيرزبرج ام هارتس",
                "label_fr": "Herzberg am Harz"
            },
            {
                "value": 37180,
                "label": "Hemelingen",
                "label_ar": "هيملينجن",
                "label_fr": "Hemelingen"
            },
            {
                "value": 37181,
                "label": "Hohenkirchen-Siegertsbrunn",
                "label_ar": "Hohenkirchen-Siegertsbrunn",
                "label_fr": "Hohenkirchen-Siegertsbrunn"
            },
            {
                "value": 37182,
                "label": "Deisenhofen",
                "label_ar": "ديزنهوفن",
                "label_fr": "Deisenhofen"
            },
            {
                "value": 37183,
                "label": "Halfing",
                "label_ar": "نصف",
                "label_fr": "Demi"
            },
            {
                "value": 37184,
                "label": "Weilheim",
                "label_ar": "ويلهايم",
                "label_fr": "Weilheim"
            },
            {
                "value": 37185,
                "label": "Lindenberg im Allgau",
                "label_ar": "Lindenberg im Allgau",
                "label_fr": "Lindenberg im Allgau"
            },
            {
                "value": 37186,
                "label": "Oberstaufen",
                "label_ar": "اوبرشتاوفن",
                "label_fr": "Oberstaufen"
            },
            {
                "value": 37187,
                "label": "Scheidegg",
                "label_ar": "شيدج",
                "label_fr": "Scheidegg"
            },
            {
                "value": 37188,
                "label": "Bayreuth",
                "label_ar": "بايرويت",
                "label_fr": "Bayreuth"
            },
            {
                "value": 37189,
                "label": "Burghausen",
                "label_ar": "بورغهاوزن",
                "label_fr": "Burghausen"
            },
            {
                "value": 37190,
                "label": "Schweinfurt",
                "label_ar": "شفاينفورت",
                "label_fr": "Schweinfurt"
            },
            {
                "value": 37191,
                "label": "Markt Wald",
                "label_ar": "ماركت والد",
                "label_fr": "Markt Wald"
            },
            {
                "value": 37192,
                "label": "Weissenburg",
                "label_ar": "فايسنبورغ",
                "label_fr": "Weissenburg"
            },
            {
                "value": 37193,
                "label": "Treuchtlingen",
                "label_ar": "Treuchtlingen",
                "label_fr": "Treuchtlingen"
            },
            {
                "value": 37194,
                "label": "Emmering",
                "label_ar": "إمرينغ",
                "label_fr": "Emmering"
            },
            {
                "value": 37195,
                "label": "Fürstenfeldbruck",
                "label_ar": "فورستينفيلدبروك",
                "label_fr": "Fürstenfeldbruck"
            },
            {
                "value": 37196,
                "label": "Gilching",
                "label_ar": "جلشينج",
                "label_fr": "Gilching"
            },
            {
                "value": 37197,
                "label": "Schwabhausen",
                "label_ar": "شوابهاوزن",
                "label_fr": "Schwabhausen"
            },
            {
                "value": 37198,
                "label": "Grobenzell",
                "label_ar": "جروبنزيل",
                "label_fr": "Grobenzell"
            },
            {
                "value": 37199,
                "label": "Planegg",
                "label_ar": "بلانيج",
                "label_fr": "Planegg"
            },
            {
                "value": 37200,
                "label": "Kempten (Allgaeu)",
                "label_ar": "كمبتن (ألغيو)",
                "label_fr": "Kempten (Allgaeu)"
            },
            {
                "value": 37201,
                "label": "Olching",
                "label_ar": "قرع",
                "label_fr": "Olching"
            },
            {
                "value": 37202,
                "label": "Maisach",
                "label_ar": "ميساش",
                "label_fr": "Maisach"
            },
            {
                "value": 37203,
                "label": "Murnau am Staffelsee",
                "label_ar": "مورناو ام ستافيلسي",
                "label_fr": "Murnau am Staffelsee"
            },
            {
                "value": 37204,
                "label": "Oberau",
                "label_ar": "أوبيراو",
                "label_fr": "Oberau"
            },
            {
                "value": 37205,
                "label": "Füssen",
                "label_ar": "فوسن",
                "label_fr": "Füssen"
            },
            {
                "value": 37206,
                "label": "Waldkraiburg",
                "label_ar": "فالدكريبورغ",
                "label_fr": "Waldkraiburg"
            },
            {
                "value": 37207,
                "label": "Passau",
                "label_ar": "باساو",
                "label_fr": "Passau"
            },
            {
                "value": 37208,
                "label": "Guenzburg",
                "label_ar": "Guenzburg",
                "label_fr": "Guenzburg"
            },
            {
                "value": 37209,
                "label": "Burgau",
                "label_ar": "بورغاو",
                "label_fr": "Burgau"
            },
            {
                "value": 37210,
                "label": "Krumbach",
                "label_ar": "كرومباخ",
                "label_fr": "Krumbach"
            },
            {
                "value": 37211,
                "label": "Werningshausen",
                "label_ar": "Werningshausen",
                "label_fr": "Werningshausen"
            },
            {
                "value": 37212,
                "label": "Ilmenau",
                "label_ar": "إلميناو",
                "label_fr": "Ilmenau"
            },
            {
                "value": 37213,
                "label": "Handewitt",
                "label_ar": "هاندويت",
                "label_fr": "Handewitt"
            },
            {
                "value": 37214,
                "label": "Harrislee",
                "label_ar": "هاريسلي",
                "label_fr": "Harrislee"
            },
            {
                "value": 37215,
                "label": "Kappeln",
                "label_ar": "كابيلن",
                "label_fr": "Kappeln"
            },
            {
                "value": 37216,
                "label": "Bremervorde",
                "label_ar": "بريميرفورد",
                "label_fr": "Bremervorde"
            },
            {
                "value": 37217,
                "label": "Kuddeworde",
                "label_ar": "كودوورد",
                "label_fr": "Kuddeworde"
            },
            {
                "value": 37218,
                "label": "Greifswald",
                "label_ar": "جرايفسفالد",
                "label_fr": "Greifswald"
            },
            {
                "value": 37219,
                "label": "Preetz",
                "label_ar": "بريتز",
                "label_fr": "Preetz"
            },
            {
                "value": 37220,
                "label": "Bordesholm",
                "label_ar": "بوردشولم",
                "label_fr": "Bordesholm"
            },
            {
                "value": 37221,
                "label": "Jesteburg",
                "label_ar": "جيستبورغ",
                "label_fr": "Jesteburg"
            },
            {
                "value": 37222,
                "label": "Buchholz in der Nordheide",
                "label_ar": "بوخهولز في دير نوردهايد",
                "label_fr": "Buchholz in der Nordheide"
            },
            {
                "value": 37223,
                "label": "Lüneburg",
                "label_ar": "لونبورغ",
                "label_fr": "Lunebourg"
            },
            {
                "value": 37224,
                "label": "Winsen",
                "label_ar": "وينسن",
                "label_fr": "Winsen"
            },
            {
                "value": 37225,
                "label": "Bad Schwartau",
                "label_ar": "باد شفارتاو",
                "label_fr": "Bad Schwartau"
            },
            {
                "value": 37226,
                "label": "Herrnburg",
                "label_ar": "هيرنبورج",
                "label_fr": "Herrnburg"
            },
            {
                "value": 37227,
                "label": "Raisdorf",
                "label_ar": "رايزدورف",
                "label_fr": "Raisdorf"
            },
            {
                "value": 37228,
                "label": "Parchim",
                "label_ar": "بارشيم",
                "label_fr": "Parchim"
            },
            {
                "value": 37229,
                "label": "Ueckermunde",
                "label_ar": "Ueckermunde",
                "label_fr": "Ueckermunde"
            },
            {
                "value": 37230,
                "label": "Melsdorf",
                "label_ar": "ميلسدورف",
                "label_fr": "Melsdorf"
            },
            {
                "value": 37231,
                "label": "Stralsund",
                "label_ar": "شترالسوند",
                "label_fr": "Stralsund"
            },
            {
                "value": 37232,
                "label": "Simonsberg",
                "label_ar": "سيمونسبرج",
                "label_fr": "Simonsberg"
            },
            {
                "value": 37233,
                "label": "Scharbeutz",
                "label_ar": "شاربيوتس",
                "label_fr": "Scharbeutz"
            },
            {
                "value": 37234,
                "label": "Heikendorf",
                "label_ar": "هيكيندورف",
                "label_fr": "Heikendorf"
            },
            {
                "value": 37235,
                "label": "Schwerin",
                "label_ar": "شفيرين",
                "label_fr": "Schwerin"
            },
            {
                "value": 37236,
                "label": "Bruel",
                "label_ar": "برويل",
                "label_fr": "Bruel"
            },
            {
                "value": 37237,
                "label": "Wittenforden",
                "label_ar": "ويتنفوردن",
                "label_fr": "Wittenforden"
            },
            {
                "value": 37238,
                "label": "Neustrelitz",
                "label_ar": "Neustrelitz",
                "label_fr": "Neustrelitz"
            },
            {
                "value": 37239,
                "label": "Robel",
                "label_ar": "روبيل",
                "label_fr": "Robel"
            },
            {
                "value": 37240,
                "label": "Loitz",
                "label_ar": "لويتز",
                "label_fr": "Loitz"
            },
            {
                "value": 37241,
                "label": "Ostseebad Kuhlungsborn",
                "label_ar": "أوستسيباد كوهلونجسبورن",
                "label_fr": "Ostseebad Kuhlungsborn"
            },
            {
                "value": 37242,
                "label": "Langwedel",
                "label_ar": "لانغويديل",
                "label_fr": "Langwedel"
            },
            {
                "value": 37243,
                "label": "Hassel",
                "label_ar": "هاسل",
                "label_fr": "Hassel"
            },
            {
                "value": 37244,
                "label": "Mildstedt",
                "label_ar": "ميلدستيدت",
                "label_fr": "Mildstedt"
            },
            {
                "value": 37245,
                "label": "Stade",
                "label_ar": "ستاد",
                "label_fr": "Stade"
            },
            {
                "value": 37246,
                "label": "Deutsch Evern",
                "label_ar": "دويتش إيفرن",
                "label_fr": "Deutsch Evern"
            },
            {
                "value": 37247,
                "label": "Pasewalk",
                "label_ar": "باسووك",
                "label_fr": "Pasewalk"
            },
            {
                "value": 37248,
                "label": "Neubrandenburg",
                "label_ar": "نيوبراندنبورغ",
                "label_fr": "Neubrandenburg"
            },
            {
                "value": 37249,
                "label": "Schneverdingen",
                "label_ar": "شنيفردينجن",
                "label_fr": "Schneverdingen"
            },
            {
                "value": 37250,
                "label": "Soltau",
                "label_ar": "سولتو",
                "label_fr": "Soltau"
            },
            {
                "value": 37251,
                "label": "Fahrenkrug",
                "label_ar": "فهرنكروج",
                "label_fr": "Fahrenkrug"
            },
            {
                "value": 37252,
                "label": "Dorverden",
                "label_ar": "دورفيردين",
                "label_fr": "Dorverden"
            },
            {
                "value": 37253,
                "label": "Schonberg",
                "label_ar": "شونبيرج",
                "label_fr": "Schonberg"
            },
            {
                "value": 37254,
                "label": "Neumünster",
                "label_ar": "نيومونستر",
                "label_fr": "Neumünster"
            },
            {
                "value": 37255,
                "label": "Wahlstedt",
                "label_ar": "والستيدت",
                "label_fr": "Wahlstedt"
            },
            {
                "value": 37256,
                "label": "Rendsburg",
                "label_ar": "ريندسبورج",
                "label_fr": "Rendsburg"
            },
            {
                "value": 37257,
                "label": "Borgstedt",
                "label_ar": "بورغستدت",
                "label_fr": "Borgstedt"
            },
            {
                "value": 37258,
                "label": "Westerronfeld",
                "label_ar": "ويستيرونفيلد",
                "label_fr": "Westerronfeld"
            },
            {
                "value": 37259,
                "label": "Osterronfeld",
                "label_ar": "أوسترونفيلد",
                "label_fr": "Osterronfeld"
            },
            {
                "value": 37260,
                "label": "Kremperheide",
                "label_ar": "كريمرهيد",
                "label_fr": "Kremperheide"
            },
            {
                "value": 37261,
                "label": "Oelixdorf",
                "label_ar": "Oelixdorf",
                "label_fr": "Oelixdorf"
            },
            {
                "value": 37262,
                "label": "Oststeinbek",
                "label_ar": "اوستشتاينبيك",
                "label_fr": "Oststeinbek"
            },
            {
                "value": 37263,
                "label": "Schleswig",
                "label_ar": "شليسفيغ",
                "label_fr": "Schleswig"
            },
            {
                "value": 37264,
                "label": "Appen",
                "label_ar": "أبين",
                "label_fr": "Appen"
            },
            {
                "value": 37265,
                "label": "Maschen",
                "label_ar": "ماشين",
                "label_fr": "Maschen"
            },
            {
                "value": 37266,
                "label": "Dassendorf",
                "label_ar": "داسندورف",
                "label_fr": "Dassendorf"
            },
            {
                "value": 37267,
                "label": "Schwarzenbek",
                "label_ar": "شوارزنبيك",
                "label_fr": "Schwarzenbek"
            },
            {
                "value": 37268,
                "label": "Wohltorf",
                "label_ar": "فولتورف",
                "label_fr": "Wohltorf"
            },
            {
                "value": 37269,
                "label": "Wees",
                "label_ar": "ويس",
                "label_fr": "Wees"
            },
            {
                "value": 37270,
                "label": "Verden an der Aller",
                "label_ar": "Verden an der Aller",
                "label_fr": "Verden an der Aller"
            },
            {
                "value": 37271,
                "label": "Schonkirchen",
                "label_ar": "شونكيرشن",
                "label_fr": "Schonkirchen"
            },
            {
                "value": 37272,
                "label": "Sarkwitz",
                "label_ar": "ساركويتز",
                "label_fr": "Sarkwitz"
            },
            {
                "value": 37273,
                "label": "Hamberge",
                "label_ar": "هامبرج",
                "label_fr": "Hamberge"
            },
            {
                "value": 37274,
                "label": "Kronshagen",
                "label_ar": "كرونشاجين",
                "label_fr": "Kronshagen"
            },
            {
                "value": 37275,
                "label": "Ebstorf",
                "label_ar": "إبستورف",
                "label_fr": "Ebstorf"
            },
            {
                "value": 37276,
                "label": "Molfsee",
                "label_ar": "مولفسي",
                "label_fr": "Molfsee"
            },
            {
                "value": 37277,
                "label": "Mielkendorf",
                "label_ar": "ميلكندورف",
                "label_fr": "Mielkendorf"
            },
            {
                "value": 37278,
                "label": "Ellerau",
                "label_ar": "إليراو",
                "label_fr": "Ellerau"
            },
            {
                "value": 37279,
                "label": "Timmendorf Beach",
                "label_ar": "شاطئ تيميندورف",
                "label_fr": "Plage de Timmendorf"
            },
            {
                "value": 37280,
                "label": "Buedelsdorf",
                "label_ar": "بويدلسدورف",
                "label_fr": "Buedelsdorf"
            },
            {
                "value": 37281,
                "label": "Bendestorf",
                "label_ar": "بنديستورف",
                "label_fr": "Bendestorf"
            },
            {
                "value": 37282,
                "label": "Uetersen",
                "label_ar": "يوترسن",
                "label_fr": "Uetersen"
            },
            {
                "value": 37283,
                "label": "Lauenburg",
                "label_ar": "لاوينبورغ",
                "label_fr": "Lauenburg"
            },
            {
                "value": 37284,
                "label": "Neustadt in Holstein",
                "label_ar": "نويشتات في هولشتاين",
                "label_fr": "Neustadt à Holstein"
            },
            {
                "value": 37285,
                "label": "Heiligenhafen",
                "label_ar": "هيليغنهافن",
                "label_fr": "Heiligenhafen"
            },
            {
                "value": 37286,
                "label": "Lutjenburg",
                "label_ar": "Lutjenburg",
                "label_fr": "Lutjenburg"
            },
            {
                "value": 37287,
                "label": "Quickborn",
                "label_ar": "كويكبورن",
                "label_fr": "Quickborn"
            },
            {
                "value": 37288,
                "label": "Tangstedt",
                "label_ar": "تانغستيدت",
                "label_fr": "Tangstedt"
            },
            {
                "value": 37289,
                "label": "Tangstedt",
                "label_ar": "تانغستيدت",
                "label_fr": "Tangstedt"
            },
            {
                "value": 37290,
                "label": "Negast",
                "label_ar": "نيغاست",
                "label_fr": "Negast"
            },
            {
                "value": 37291,
                "label": "Langendorf",
                "label_ar": "لانجندورف",
                "label_fr": "Langendorf"
            },
            {
                "value": 37292,
                "label": "Stockelsdorf",
                "label_ar": "ستوكيلسدورف",
                "label_fr": "Stockelsdorf"
            },
            {
                "value": 37293,
                "label": "Prangendorf",
                "label_ar": "برانجندورف",
                "label_fr": "Prangendorf"
            },
            {
                "value": 37294,
                "label": "Lagerdorf",
                "label_ar": "لاغيردورف",
                "label_fr": "Lagerdorf"
            },
            {
                "value": 37295,
                "label": "Jersbek",
                "label_ar": "جيرسبك",
                "label_fr": "Jersbek"
            },
            {
                "value": 37296,
                "label": "Lutjensee",
                "label_ar": "Lutjensee",
                "label_fr": "Lutjensee"
            },
            {
                "value": 37297,
                "label": "Hoisdorf",
                "label_ar": "Hoisdorf",
                "label_fr": "Hoisdorf"
            },
            {
                "value": 37298,
                "label": "Tremsbuttel",
                "label_ar": "تريمسبوتيل",
                "label_fr": "Tremsbuttel"
            },
            {
                "value": 37299,
                "label": "Nahe",
                "label_ar": "ناهي",
                "label_fr": "Nahe"
            },
            {
                "value": 37300,
                "label": "Itzstedt",
                "label_ar": "Itzstedt",
                "label_fr": "Itzstedt"
            },
            {
                "value": 37301,
                "label": "Dabelow",
                "label_ar": "Dabelow",
                "label_fr": "Dabelow"
            },
            {
                "value": 37302,
                "label": "Monkeberg",
                "label_ar": "مونكبيرج",
                "label_fr": "Monkeberg"
            },
            {
                "value": 37303,
                "label": "Altenholz",
                "label_ar": "التنهولز",
                "label_fr": "Altenholz"
            },
            {
                "value": 37304,
                "label": "Buxtehude",
                "label_ar": "بوكستهود",
                "label_fr": "Buxtehude"
            },
            {
                "value": 37305,
                "label": "Escheburg",
                "label_ar": "Escheburg",
                "label_fr": "Escheburg"
            },
            {
                "value": 37306,
                "label": "Barsbuettel",
                "label_ar": "بارسبويتل",
                "label_fr": "Barsbuettel"
            },
            {
                "value": 37307,
                "label": "Ludwigslust",
                "label_ar": "لودفيغسلوست",
                "label_fr": "Ludwigslust"
            },
            {
                "value": 37308,
                "label": "Elmenhorst",
                "label_ar": "المنهورست",
                "label_fr": "Elmenhorst"
            },
            {
                "value": 37309,
                "label": "Grabau",
                "label_ar": "جراباو",
                "label_fr": "Grabau"
            },
            {
                "value": 37310,
                "label": "Sassnitz",
                "label_ar": "ساسنيتز",
                "label_fr": "Sassnitz"
            },
            {
                "value": 37311,
                "label": "Sagard",
                "label_ar": "ساجارد",
                "label_fr": "Sagard"
            },
            {
                "value": 37312,
                "label": "Kisdorf",
                "label_ar": "كيسدورف",
                "label_fr": "Kisdorf"
            },
            {
                "value": 37313,
                "label": "Selmsdorf",
                "label_ar": "سيلمسدورف",
                "label_fr": "Selmsdorf"
            },
            {
                "value": 37314,
                "label": "Kummerfeld",
                "label_ar": "كومرفيلد",
                "label_fr": "Kummerfeld"
            },
            {
                "value": 37315,
                "label": "Krempe",
                "label_ar": "كريمبي",
                "label_fr": "Krempe"
            },
            {
                "value": 37316,
                "label": "Hanstedt",
                "label_ar": "هانستيدت",
                "label_fr": "Hanstedt"
            },
            {
                "value": 37317,
                "label": "Demmin",
                "label_ar": "ديممين",
                "label_fr": "Demmin"
            },
            {
                "value": 37318,
                "label": "Prisdorf",
                "label_ar": "بريسدورف",
                "label_fr": "Prisdorf"
            },
            {
                "value": 37319,
                "label": "Schwalingen",
                "label_ar": "شوالينغن",
                "label_fr": "Schwalingen"
            },
            {
                "value": 37320,
                "label": "Sprengel",
                "label_ar": "Sprengel",
                "label_fr": "Sprengel"
            },
            {
                "value": 37321,
                "label": "Neuenkirchen",
                "label_ar": "نوينكيرشن",
                "label_fr": "Neuenkirchen"
            },
            {
                "value": 37322,
                "label": "Munster",
                "label_ar": "مونستر",
                "label_fr": "Munster"
            },
            {
                "value": 37323,
                "label": "Uelvesbull",
                "label_ar": "أولفسبول",
                "label_fr": "Uelvesbull"
            },
            {
                "value": 37324,
                "label": "Neu Duvenstedt",
                "label_ar": "Neu Duvenstedt",
                "label_fr": "Neu Duvenstedt"
            },
            {
                "value": 37325,
                "label": "Wendisch Evern",
                "label_ar": "Wendisch Evern",
                "label_fr": "Wendisch Evern"
            },
            {
                "value": 37326,
                "label": "Adendorf",
                "label_ar": "Adendorf",
                "label_fr": "Adendorf"
            },
            {
                "value": 37327,
                "label": "Marne",
                "label_ar": "مارن",
                "label_fr": "Marne"
            },
            {
                "value": 37328,
                "label": "Celle",
                "label_ar": "سيل",
                "label_fr": "Celle"
            },
            {
                "value": 37329,
                "label": "Jork",
                "label_ar": "جورك",
                "label_fr": "Jork"
            },
            {
                "value": 37330,
                "label": "Reppenstedt",
                "label_ar": "ريبينستيدت",
                "label_fr": "Reppenstedt"
            },
            {
                "value": 37331,
                "label": "Bad Bevensen",
                "label_ar": "باد بيفينسن",
                "label_fr": "Bad Bevensen"
            },
            {
                "value": 37332,
                "label": "Wrestedt",
                "label_ar": "انتزع",
                "label_fr": "Wrestedt"
            },
            {
                "value": 37333,
                "label": "Vogelsen",
                "label_ar": "فوجيلسن",
                "label_fr": "Vogelsen"
            },
            {
                "value": 37334,
                "label": "Bardowick",
                "label_ar": "باردويك",
                "label_fr": "Bardowick"
            },
            {
                "value": 37335,
                "label": "Apensen",
                "label_ar": "أبينسن",
                "label_fr": "Apensen"
            },
            {
                "value": 37336,
                "label": "Hemmoor",
                "label_ar": "حيمور",
                "label_fr": "Hemmoor"
            },
            {
                "value": 37337,
                "label": "Rosengarten",
                "label_ar": "روزنغارتن",
                "label_fr": "Rosengarten"
            },
            {
                "value": 37338,
                "label": "Eggingen",
                "label_ar": "ايجينجين",
                "label_fr": "Eggingen"
            },
            {
                "value": 37339,
                "label": "Kadelburg",
                "label_ar": "كاديلبرج",
                "label_fr": "Kadelburg"
            },
            {
                "value": 37340,
                "label": "Wiernsheim",
                "label_ar": "ويرنسهايم",
                "label_fr": "Wiernsheim"
            },
            {
                "value": 37341,
                "label": "Mittelstenweiler",
                "label_ar": "Mittelstenweiler",
                "label_fr": "Mittelstenweiler"
            },
            {
                "value": 37342,
                "label": "Nagold",
                "label_ar": "ناجولد",
                "label_fr": "Nagold"
            },
            {
                "value": 37343,
                "label": "Leonberg",
                "label_ar": "ليونبرج",
                "label_fr": "Leonberg"
            },
            {
                "value": 37344,
                "label": "Schwäbisch Gmünd",
                "label_ar": "شفيبيش غموند",
                "label_fr": "Schwäbisch Gmünd"
            },
            {
                "value": 37345,
                "label": "Hochdorf",
                "label_ar": "هوشدورف",
                "label_fr": "Hochdorf"
            },
            {
                "value": 37346,
                "label": "Ingoldingen",
                "label_ar": "إنجولدين",
                "label_fr": "Ingoldingen"
            },
            {
                "value": 37347,
                "label": "Mutlangen",
                "label_ar": "موتلنغن",
                "label_fr": "Mutlangen"
            },
            {
                "value": 37348,
                "label": "Mittelbiberach",
                "label_ar": "ميتلبيبراتش",
                "label_fr": "Mittelbiberach"
            },
            {
                "value": 37349,
                "label": "Biberach an der Riss",
                "label_ar": "Biberach an der Riss",
                "label_fr": "Biberach an der Riss"
            },
            {
                "value": 37350,
                "label": "Loxstedt",
                "label_ar": "Loxstedt",
                "label_fr": "Loxstedt"
            },
            {
                "value": 37351,
                "label": "Stinstedt",
                "label_ar": "ستينستيدت",
                "label_fr": "Stinstedt"
            },
            {
                "value": 37352,
                "label": "Wanna",
                "label_ar": "اريد",
                "label_fr": "Veux"
            },
            {
                "value": 37353,
                "label": "Brake (Unterweser)",
                "label_ar": "الفرامل (Unterweser)",
                "label_fr": "Frein (Unterweser)"
            },
            {
                "value": 37354,
                "label": "Hechthausen",
                "label_ar": "هيشتهاوزن",
                "label_fr": "Hechthausen"
            },
            {
                "value": 37355,
                "label": "Hambergen",
                "label_ar": "هامبيرجين",
                "label_fr": "Hambergen"
            },
            {
                "value": 37356,
                "label": "Schiffdorf",
                "label_ar": "شيفدورف",
                "label_fr": "Schiffdorf"
            },
            {
                "value": 37357,
                "label": "Lamstedt",
                "label_ar": "لامستيدت",
                "label_fr": "Lamstedt"
            },
            {
                "value": 37358,
                "label": "Hagen",
                "label_ar": "هاغن",
                "label_fr": "Hagen"
            },
            {
                "value": 37359,
                "label": "Himmelpforten",
                "label_ar": "هيملبفورتن",
                "label_fr": "Himmelpforten"
            },
            {
                "value": 37360,
                "label": "Vollersode",
                "label_ar": "فولرسود",
                "label_fr": "Vollersode"
            },
            {
                "value": 37361,
                "label": "Nordholz",
                "label_ar": "نوردهولز",
                "label_fr": "Nordholz"
            },
            {
                "value": 37362,
                "label": "Bulkau",
                "label_ar": "بولكاو",
                "label_fr": "Bulkau"
            },
            {
                "value": 37363,
                "label": "Drochtersen",
                "label_ar": "دروتشر",
                "label_fr": "Drochtersen"
            },
            {
                "value": 37364,
                "label": "Bockhorn",
                "label_ar": "بوكهورن",
                "label_fr": "Bockhorn"
            },
            {
                "value": 37365,
                "label": "Sande",
                "label_ar": "ساندي",
                "label_fr": "Sande"
            },
            {
                "value": 37366,
                "label": "Zetel",
                "label_ar": "زيتل",
                "label_fr": "Zetel"
            },
            {
                "value": 37367,
                "label": "Friedeburg",
                "label_ar": "فريدبورغ",
                "label_fr": "Friedeburg"
            },
            {
                "value": 37368,
                "label": "Wiesmoor",
                "label_ar": "ويسمور",
                "label_fr": "Wiesmoor"
            },
            {
                "value": 37369,
                "label": "Schweiburg",
                "label_ar": "شويبورغ",
                "label_fr": "Schweiburg"
            },
            {
                "value": 37370,
                "label": "Sottrum",
                "label_ar": "Sottrum",
                "label_fr": "Sottrum"
            },
            {
                "value": 37371,
                "label": "Dohren",
                "label_ar": "دوهرين",
                "label_fr": "Dohren"
            },
            {
                "value": 37372,
                "label": "Tarmstedt",
                "label_ar": "ترمستيدت",
                "label_fr": "Tarmstedt"
            },
            {
                "value": 37373,
                "label": "Tostedt",
                "label_ar": "توستيدت",
                "label_fr": "Tostedt"
            },
            {
                "value": 37374,
                "label": "Hemsbunde",
                "label_ar": "هيمسبوند",
                "label_fr": "Hemsbunde"
            },
            {
                "value": 37375,
                "label": "Zeven",
                "label_ar": "زيفين",
                "label_fr": "Zeven"
            },
            {
                "value": 37376,
                "label": "Helvesiek",
                "label_ar": "هيلفسيك",
                "label_fr": "Helvesiek"
            },
            {
                "value": 37377,
                "label": "Otter",
                "label_ar": "قندس",
                "label_fr": "Loutre"
            },
            {
                "value": 37378,
                "label": "Worpswede",
                "label_ar": "Worpswede",
                "label_fr": "Worpswede"
            },
            {
                "value": 37379,
                "label": "Schwanewede",
                "label_ar": "شوانيويد",
                "label_fr": "Schwanewede"
            },
            {
                "value": 37380,
                "label": "Gnarrenburg",
                "label_ar": "غنارينبورغ",
                "label_fr": "Gnarrenburg"
            },
            {
                "value": 37381,
                "label": "Mittelnkirchen",
                "label_ar": "ميتيلنكيرشن",
                "label_fr": "Mittelnkirchen"
            },
            {
                "value": 37382,
                "label": "Lilienthal",
                "label_ar": "ليلينثال",
                "label_fr": "Lilienthal"
            },
            {
                "value": 37383,
                "label": "Syke",
                "label_ar": "سايكي",
                "label_fr": "Syke"
            },
            {
                "value": 37384,
                "label": "Rastede",
                "label_ar": "راستيد",
                "label_fr": "Rastede"
            },
            {
                "value": 37385,
                "label": "Westerstede",
                "label_ar": "ويسترستيد",
                "label_fr": "Westerstede"
            },
            {
                "value": 37386,
                "label": "Hatten",
                "label_ar": "حطين",
                "label_fr": "Hatten"
            },
            {
                "value": 37387,
                "label": "Garrel",
                "label_ar": "جاريل",
                "label_fr": "Garrel"
            },
            {
                "value": 37388,
                "label": "Friesoythe",
                "label_ar": "فريزويث",
                "label_fr": "Friesoythe"
            },
            {
                "value": 37389,
                "label": "Norden",
                "label_ar": "نوردن",
                "label_fr": "Norden"
            },
            {
                "value": 37390,
                "label": "Blomberg",
                "label_ar": "بلومبيرج",
                "label_fr": "Blomberg"
            },
            {
                "value": 37391,
                "label": "Hage",
                "label_ar": "الحاج",
                "label_fr": "Hage"
            },
            {
                "value": 37392,
                "label": "Mittegrossefehn",
                "label_ar": "Mittegrossefehn",
                "label_fr": "Mittegrossefehn"
            },
            {
                "value": 37393,
                "label": "Eversmeer",
                "label_ar": "إيفرسمير",
                "label_fr": "Eversmeer"
            },
            {
                "value": 37394,
                "label": "Esens",
                "label_ar": "إسينس",
                "label_fr": "Esens"
            },
            {
                "value": 37395,
                "label": "Upgant-Schott",
                "label_ar": "ترقية شوت",
                "label_fr": "Upgant-Schott"
            },
            {
                "value": 37396,
                "label": "Hesel",
                "label_ar": "هيسيل",
                "label_fr": "Hesel"
            },
            {
                "value": 37397,
                "label": "Ihlow",
                "label_ar": "اهلو",
                "label_fr": "Ihlow"
            },
            {
                "value": 37398,
                "label": "Westerholt",
                "label_ar": "ويسترهولت",
                "label_fr": "Westerholt"
            },
            {
                "value": 37399,
                "label": "Wirdum",
                "label_ar": "ويردوم",
                "label_fr": "Wirdum"
            },
            {
                "value": 37400,
                "label": "Berumbur",
                "label_ar": "بيرومبور",
                "label_fr": "Berumbur"
            },
            {
                "value": 37401,
                "label": "Dornum",
                "label_ar": "دورنوم",
                "label_fr": "Dornum"
            },
            {
                "value": 37402,
                "label": "Grossheide",
                "label_ar": "جروسهيد",
                "label_fr": "Grossheide"
            },
            {
                "value": 37403,
                "label": "Remels",
                "label_ar": "يعيد",
                "label_fr": "Remels"
            },
            {
                "value": 37404,
                "label": "Norderney",
                "label_ar": "نورديرني",
                "label_fr": "Norderney"
            },
            {
                "value": 37405,
                "label": "Herford",
                "label_ar": "هيرفورد",
                "label_fr": "Herford"
            },
            {
                "value": 37406,
                "label": "Melle",
                "label_ar": "ميلي",
                "label_fr": "Melle"
            },
            {
                "value": 37407,
                "label": "Hiddenhausen",
                "label_ar": "هيدينهاوزن",
                "label_fr": "Hiddenhausen"
            },
            {
                "value": 37408,
                "label": "Espelkamp",
                "label_ar": "Espelkamp",
                "label_fr": "Espelkamp"
            },
            {
                "value": 37409,
                "label": "Lohne",
                "label_ar": "Lohne",
                "label_fr": "Lohne"
            },
            {
                "value": 37410,
                "label": "Lauenhagen",
                "label_ar": "لوينهاغن",
                "label_fr": "Lauenhagen"
            },
            {
                "value": 37411,
                "label": "Enger",
                "label_ar": "إنجر",
                "label_fr": "Enger"
            },
            {
                "value": 37412,
                "label": "Adorf",
                "label_ar": "أدورف",
                "label_fr": "Adorf"
            },
            {
                "value": 37413,
                "label": "Trostberg an der Alz",
                "label_ar": "Trostberg an der Alz",
                "label_fr": "Trostberg an der Alz"
            },
            {
                "value": 37414,
                "label": "Insingen",
                "label_ar": "Insingen",
                "label_fr": "Insingen"
            },
            {
                "value": 37415,
                "label": "Dietramszell",
                "label_ar": "ديترامزيل",
                "label_fr": "Dietramszell"
            },
            {
                "value": 37416,
                "label": "Rabenholz",
                "label_ar": "رابينهولز",
                "label_fr": "Rabenholz"
            },
            {
                "value": 37417,
                "label": "Dassow",
                "label_ar": "داسو",
                "label_fr": "Dassow"
            },
            {
                "value": 37418,
                "label": "Weinsfeld",
                "label_ar": "وينسفيلد",
                "label_fr": "Weinsfeld"
            },
            {
                "value": 37419,
                "label": "Barkow",
                "label_ar": "باركو",
                "label_fr": "Barkow"
            },
            {
                "value": 37420,
                "label": "Wermelskirchen",
                "label_ar": "Wermelskirchen",
                "label_fr": "Wermelskirchen"
            },
            {
                "value": 37421,
                "label": "Poing",
                "label_ar": "بوينج",
                "label_fr": "Poing"
            },
            {
                "value": 37422,
                "label": "Riedering",
                "label_ar": "ريدينغ",
                "label_fr": "Riedering"
            },
            {
                "value": 37423,
                "label": "Maintal",
                "label_ar": "ماينتال",
                "label_fr": "Maintal"
            },
            {
                "value": 37424,
                "label": "Rothenstein",
                "label_ar": "روثنشتاين",
                "label_fr": "Rothenstein"
            },
            {
                "value": 37425,
                "label": "Erfurt",
                "label_ar": "إرفورت",
                "label_fr": "Erfurt"
            },
            {
                "value": 37426,
                "label": "Auma",
                "label_ar": "أوما",
                "label_fr": "Auma"
            },
            {
                "value": 37427,
                "label": "Heidenburg",
                "label_ar": "هايدنبورغ",
                "label_fr": "Heidenburg"
            },
            {
                "value": 37428,
                "label": "Laufach",
                "label_ar": "لافاتش",
                "label_fr": "Laufach"
            },
            {
                "value": 37429,
                "label": "Freilassing",
                "label_ar": "Freilassing",
                "label_fr": "Freilassing"
            },
            {
                "value": 37430,
                "label": "Bad Reichenhall",
                "label_ar": "سيئة Reichenhall",
                "label_fr": "Bad Reichenhall"
            },
            {
                "value": 37431,
                "label": "Piding",
                "label_ar": "بايدنج",
                "label_fr": "Piding"
            },
            {
                "value": 37432,
                "label": "Saaldorf",
                "label_ar": "Saaldorf",
                "label_fr": "Saaldorf"
            },
            {
                "value": 37433,
                "label": "Weissenhorn",
                "label_ar": "فايسينهورن",
                "label_fr": "Weissenhorn"
            },
            {
                "value": 37434,
                "label": "Gauting",
                "label_ar": "القياس",
                "label_fr": "Gauting"
            },
            {
                "value": 37435,
                "label": "Grafelfing",
                "label_ar": "جرافلفينج",
                "label_fr": "Grafelfing"
            },
            {
                "value": 37436,
                "label": "Senden",
                "label_ar": "سيندن",
                "label_fr": "Senden"
            },
            {
                "value": 37437,
                "label": "Pfaffenhofen an der Roth",
                "label_ar": "بفافنهوفن أن دير روث",
                "label_fr": "Pfaffenhofen an der Roth"
            },
            {
                "value": 37438,
                "label": "Poppenricht",
                "label_ar": "بوبنريخت",
                "label_fr": "Poppenricht"
            },
            {
                "value": 37439,
                "label": "Ebermannsdorf",
                "label_ar": "إبرماننسدورف",
                "label_fr": "Ebermannsdorf"
            },
            {
                "value": 37440,
                "label": "Geretsried",
                "label_ar": "جيريتسريد",
                "label_fr": "Geretsried"
            },
            {
                "value": 37441,
                "label": "Starnberg",
                "label_ar": "شتارنبرج",
                "label_fr": "Starnberg"
            },
            {
                "value": 37442,
                "label": "Wolfratshausen",
                "label_ar": "ولفراتسهاوزن",
                "label_fr": "Wolfratshausen"
            },
            {
                "value": 37443,
                "label": "Pocking",
                "label_ar": "يتأرجح",
                "label_fr": "Pocking"
            },
            {
                "value": 37444,
                "label": "Freising",
                "label_ar": "فريسينج",
                "label_fr": "Freising"
            },
            {
                "value": 37445,
                "label": "Mindelheim",
                "label_ar": "ميندلهايم",
                "label_fr": "Mindelheim"
            },
            {
                "value": 37446,
                "label": "Altusried",
                "label_ar": "ألتوسريد",
                "label_fr": "Altusried"
            },
            {
                "value": 37447,
                "label": "Neufahrn bei Freising",
                "label_ar": "نيوفارن باي فريسينج",
                "label_fr": "Neufahrn bei Freising"
            },
            {
                "value": 37448,
                "label": "Hallbergmoos",
                "label_ar": "هالبيرغموس",
                "label_fr": "Hallbergmoos"
            },
            {
                "value": 37449,
                "label": "Konigsbrunn",
                "label_ar": "كونيغسبرون",
                "label_fr": "Königsbrunn"
            },
            {
                "value": 37450,
                "label": "Buchloe",
                "label_ar": "بوشلو",
                "label_fr": "Buchloe"
            },
            {
                "value": 37451,
                "label": "Regensburg",
                "label_ar": "ريغنسبورغ",
                "label_fr": "Ratisbonne"
            },
            {
                "value": 37452,
                "label": "Weiden",
                "label_ar": "Weiden",
                "label_fr": "Weiden"
            },
            {
                "value": 37453,
                "label": "Marktredwitz",
                "label_ar": "ماركتريدويتز",
                "label_fr": "Marktredwitz"
            },
            {
                "value": 37454,
                "label": "Arzberg",
                "label_ar": "أرزبرغ",
                "label_fr": "Arzberg"
            },
            {
                "value": 37455,
                "label": "Bad Alexandersbad",
                "label_ar": "سيئة الكسندرسباد",
                "label_fr": "Bad Alexandersbad"
            },
            {
                "value": 37456,
                "label": "Hallstadt",
                "label_ar": "هالشتات",
                "label_fr": "Hallstadt"
            },
            {
                "value": 37457,
                "label": "Viereth-Trunstadt",
                "label_ar": "فيريث ترونشتات",
                "label_fr": "Viereth-Trunstadt"
            },
            {
                "value": 37458,
                "label": "Pressig",
                "label_ar": "بريسيج",
                "label_fr": "Pressig"
            },
            {
                "value": 37459,
                "label": "Stockstadt am Main",
                "label_ar": "ستوكشتات أم ماين",
                "label_fr": "Stockstadt am Main"
            },
            {
                "value": 37460,
                "label": "Aschaffenburg",
                "label_ar": "أشافنبورغ",
                "label_fr": "Aschaffenburg"
            },
            {
                "value": 37461,
                "label": "Amorbach",
                "label_ar": "امورباخ",
                "label_fr": "Amorbach"
            },
            {
                "value": 37462,
                "label": "Friedrichsdorf",
                "label_ar": "فريدريشسدورف",
                "label_fr": "Friedrichsdorf"
            },
            {
                "value": 37463,
                "label": "Kleinostheim",
                "label_ar": "كلاينوستيم",
                "label_fr": "Kleinostheim"
            },
            {
                "value": 37464,
                "label": "Hosbach",
                "label_ar": "هوسباخ",
                "label_fr": "Hosbach"
            },
            {
                "value": 37465,
                "label": "Niedernberg",
                "label_ar": "نيدرنبيرج",
                "label_fr": "Niedernberg"
            },
            {
                "value": 37466,
                "label": "Emtmannsberg",
                "label_ar": "إتمانسبرج",
                "label_fr": "Emtmannsberg"
            },
            {
                "value": 37467,
                "label": "Speichersdorf",
                "label_ar": "Speichersdorf",
                "label_fr": "Speichersdorf"
            },
            {
                "value": 37468,
                "label": "Bindlach",
                "label_ar": "بيندلاش",
                "label_fr": "Bindlach"
            },
            {
                "value": 37469,
                "label": "Weidenberg",
                "label_ar": "وايدنبرغ",
                "label_fr": "Weidenberg"
            },
            {
                "value": 37470,
                "label": "Osterode",
                "label_ar": "أوستيرود",
                "label_fr": "Osterode"
            },
            {
                "value": 37471,
                "label": "Andreasberg",
                "label_ar": "أندرياسبرج",
                "label_fr": "Andreasberg"
            },
            {
                "value": 37472,
                "label": "Bad Pyrmont",
                "label_ar": "باد بيرمونت",
                "label_fr": "Bad Pyrmont"
            },
            {
                "value": 37473,
                "label": "Bad Gandersheim",
                "label_ar": "باد غاندرشيم",
                "label_fr": "Bad Gandersheim"
            },
            {
                "value": 37474,
                "label": "Dassel",
                "label_ar": "داسيل",
                "label_fr": "Dassel"
            },
            {
                "value": 37475,
                "label": "Kreiensen",
                "label_ar": "كرينسن",
                "label_fr": "Kreiensen"
            },
            {
                "value": 37476,
                "label": "Rinteln",
                "label_ar": "رينتلن",
                "label_fr": "Rinteln"
            },
            {
                "value": 37477,
                "label": "Belm",
                "label_ar": "بيلم",
                "label_fr": "Belm"
            },
            {
                "value": 37478,
                "label": "Meppen",
                "label_ar": "ميبين",
                "label_fr": "Meppen"
            },
            {
                "value": 37479,
                "label": "Osterholz-Scharmbeck",
                "label_ar": "أوسترهولز-شارمبيك",
                "label_fr": "Osterholz-Scharmbeck"
            },
            {
                "value": 37480,
                "label": "Papenburg",
                "label_ar": "بابنبورغ",
                "label_fr": "Papenburg"
            },
            {
                "value": 37481,
                "label": "Langenhagen",
                "label_ar": "لانغنهاغن",
                "label_fr": "Langenhagen"
            },
            {
                "value": 37482,
                "label": "Wangerooge",
                "label_ar": "وانجيروج",
                "label_fr": "Wangerooge"
            },
            {
                "value": 37483,
                "label": "Gifhorn",
                "label_ar": "جيفهورن",
                "label_fr": "Gifhorn"
            },
            {
                "value": 37484,
                "label": "Calberlah",
                "label_ar": "كالبرلاه",
                "label_fr": "Calberlah"
            },
            {
                "value": 37485,
                "label": "Northeim",
                "label_ar": "نورثيم",
                "label_fr": "Northeim"
            },
            {
                "value": 37486,
                "label": "Uslar",
                "label_ar": "أوسلار",
                "label_fr": "Uslar"
            },
            {
                "value": 37487,
                "label": "Langelsheim",
                "label_ar": "لانجلشيم",
                "label_fr": "Langelsheim"
            },
            {
                "value": 37488,
                "label": "Springe",
                "label_ar": "سبرينج",
                "label_fr": "Springe"
            },
            {
                "value": 37489,
                "label": "Stolzenau",
                "label_ar": "ستولزيناو",
                "label_fr": "Stolzenau"
            },
            {
                "value": 37490,
                "label": "Sulingen",
                "label_ar": "سولينجن",
                "label_fr": "Sulingen"
            },
            {
                "value": 37491,
                "label": "Schoneiche",
                "label_ar": "شونيش",
                "label_fr": "Schoneiche"
            },
            {
                "value": 37492,
                "label": "Marklohe",
                "label_ar": "ماركلوه",
                "label_fr": "Marklohe"
            },
            {
                "value": 37493,
                "label": "Liebenau",
                "label_ar": "ليبيناو",
                "label_fr": "Liebenau"
            },
            {
                "value": 37494,
                "label": "Landesbergen",
                "label_ar": "Landesbergen",
                "label_fr": "Landesbergen"
            },
            {
                "value": 37495,
                "label": "Wriezen",
                "label_ar": "فريزين",
                "label_fr": "Wriezen"
            },
            {
                "value": 37496,
                "label": "Strausberg",
                "label_ar": "شتراوسبرغ",
                "label_fr": "Strausberg"
            },
            {
                "value": 37497,
                "label": "Wennigsen",
                "label_ar": "Wennigsen",
                "label_fr": "Wennigsen"
            },
            {
                "value": 37498,
                "label": "Hagenow",
                "label_ar": "هاجنو",
                "label_fr": "Hagenow"
            },
            {
                "value": 37499,
                "label": "Boizenburg",
                "label_ar": "Boizenburg",
                "label_fr": "Boizenburg"
            },
            {
                "value": 37500,
                "label": "Bad Rothenfelde",
                "label_ar": "باد روثنفيلد",
                "label_fr": "Bad Rothenfelde"
            },
            {
                "value": 37501,
                "label": "Trappenkamp",
                "label_ar": "ترابينكامب",
                "label_fr": "Trappenkamp"
            },
            {
                "value": 37502,
                "label": "Kalefeld",
                "label_ar": "كاليفيلد",
                "label_fr": "Kalefeld"
            },
            {
                "value": 37503,
                "label": "Erding",
                "label_ar": "إردينغ",
                "label_fr": "Erding"
            },
            {
                "value": 37504,
                "label": "Pliening",
                "label_ar": "بللين",
                "label_fr": "Pliening"
            },
            {
                "value": 37505,
                "label": "Markt Schwaben",
                "label_ar": "ماركت شوابين",
                "label_fr": "Markt Schwaben"
            },
            {
                "value": 37506,
                "label": "Oberding",
                "label_ar": "أوبردينغ",
                "label_fr": "Oberding"
            },
            {
                "value": 37507,
                "label": "Diedorf",
                "label_ar": "ديدورف",
                "label_fr": "Diedorf"
            },
            {
                "value": 37508,
                "label": "Neusass",
                "label_ar": "نيساس",
                "label_fr": "Neusass"
            },
            {
                "value": 37509,
                "label": "Burgkirchen an der Alz",
                "label_ar": "Burgkirchen an der Alz",
                "label_fr": "Burgkirchen an der Alz"
            },
            {
                "value": 37510,
                "label": "Altoetting",
                "label_ar": "Altoetting",
                "label_fr": "Altoetting"
            },
            {
                "value": 37511,
                "label": "Haiming",
                "label_ar": "هايمنج",
                "label_fr": "Haiming"
            },
            {
                "value": 37512,
                "label": "Neuotting",
                "label_ar": "نيوتينغ",
                "label_fr": "Neuotting"
            },
            {
                "value": 37513,
                "label": "Oberhaching",
                "label_ar": "Oberhaching",
                "label_fr": "Oberhaching"
            },
            {
                "value": 37514,
                "label": "Burglengenfeld",
                "label_ar": "بورغلينغنفيلد",
                "label_fr": "Burglengenfeld"
            },
            {
                "value": 37515,
                "label": "Lappersdorf",
                "label_ar": "لابرسدورف",
                "label_fr": "Lappersdorf"
            },
            {
                "value": 37516,
                "label": "Polling",
                "label_ar": "الاقتراع",
                "label_fr": "Vote"
            },
            {
                "value": 37517,
                "label": "Augsburg",
                "label_ar": "اوغسبورغ",
                "label_fr": "Augsbourg"
            },
            {
                "value": 37518,
                "label": "Penzberg",
                "label_ar": "بينزبرج",
                "label_fr": "Penzberg"
            },
            {
                "value": 37519,
                "label": "Wielenbach",
                "label_ar": "فيلينباخ",
                "label_fr": "Wielenbach"
            },
            {
                "value": 37520,
                "label": "Peissenberg",
                "label_ar": "بيسنبرغ",
                "label_fr": "Peissenberg"
            },
            {
                "value": 37521,
                "label": "Miesbach",
                "label_ar": "ميسباخ",
                "label_fr": "Miesbach"
            },
            {
                "value": 37522,
                "label": "Kaufbeuren",
                "label_ar": "كاوفبورين",
                "label_fr": "Kaufbeuren"
            },
            {
                "value": 37523,
                "label": "Gundelfingen",
                "label_ar": "غوندلفينجين",
                "label_fr": "Gundelfingen"
            },
            {
                "value": 37524,
                "label": "Sauerlach",
                "label_ar": "سورلاخ",
                "label_fr": "Sauerlach"
            },
            {
                "value": 37525,
                "label": "Mehring",
                "label_ar": "ميرينج",
                "label_fr": "Mehring"
            },
            {
                "value": 37526,
                "label": "Oberkotzau",
                "label_ar": "أوبيركوتزاو",
                "label_fr": "Oberkotzau"
            },
            {
                "value": 37527,
                "label": "Hof",
                "label_ar": "هوف",
                "label_fr": "Hof"
            },
            {
                "value": 37528,
                "label": "Otterloh",
                "label_ar": "أوترلوه",
                "label_fr": "Otterloh"
            },
            {
                "value": 37529,
                "label": "Landshut",
                "label_ar": "لاندشوت",
                "label_fr": "Landshut"
            },
            {
                "value": 37530,
                "label": "Glattbach",
                "label_ar": "جلاتباخ",
                "label_fr": "Glattbach"
            },
            {
                "value": 37531,
                "label": "Johannesberg",
                "label_ar": "جوهانسبرغ",
                "label_fr": "Johannesberg"
            },
            {
                "value": 37532,
                "label": "Unterschleissheim",
                "label_ar": "أونتيرشليسيم",
                "label_fr": "Unterschleissheim"
            },
            {
                "value": 37533,
                "label": "Grafing bei Munchen",
                "label_ar": "غرافينغ باي ميونخ",
                "label_fr": "Grafing bei Munchen"
            },
            {
                "value": 37534,
                "label": "Kirchseeon",
                "label_ar": "كيرشسيون",
                "label_fr": "Kirchseeon"
            },
            {
                "value": 37535,
                "label": "Haar",
                "label_ar": "هار",
                "label_fr": "Haar"
            },
            {
                "value": 37536,
                "label": "Kaiserslautern",
                "label_ar": "كايزرسلاوترن",
                "label_fr": "Kaiserslautern"
            },
            {
                "value": 37537,
                "label": "Enkenbach-Alsenborn",
                "label_ar": "Enkenbach-Alsenborn",
                "label_fr": "Enkenbach-Alsenborn"
            },
            {
                "value": 37538,
                "label": "Reich",
                "label_ar": "الرايخ",
                "label_fr": "Reich"
            },
            {
                "value": 37539,
                "label": "Umkirch",
                "label_ar": "أمكيرش",
                "label_fr": "Umkirch"
            },
            {
                "value": 37540,
                "label": "Baden-Baden",
                "label_ar": "بادن بادن",
                "label_fr": "Baden-Baden"
            },
            {
                "value": 37541,
                "label": "Efringen-Kirchen",
                "label_ar": "إيفرينجن كيرشين",
                "label_fr": "Efringen-Kirchen"
            },
            {
                "value": 37542,
                "label": "Eschweiler",
                "label_ar": "إيشويلر",
                "label_fr": "Eschweiler"
            },
            {
                "value": 37543,
                "label": "Alsdorf",
                "label_ar": "Alsdorf",
                "label_fr": "Alsdorf"
            },
            {
                "value": 37544,
                "label": "Bad Homburg",
                "label_ar": "مدينة باد هومبورغ",
                "label_fr": "Bad Homburg"
            },
            {
                "value": 37545,
                "label": "Niederkruchten",
                "label_ar": "نيدركروشتن",
                "label_fr": "Niederkruchten"
            },
            {
                "value": 37546,
                "label": "Meerbusch",
                "label_ar": "ميربوش",
                "label_fr": "Meerbusch"
            },
            {
                "value": 37547,
                "label": "Marl",
                "label_ar": "مارل",
                "label_fr": "Marne"
            },
            {
                "value": 37548,
                "label": "Bad Wildungen",
                "label_ar": "باد فيلدونجين",
                "label_fr": "Bad Wildungen"
            },
            {
                "value": 37549,
                "label": "Erkelenz",
                "label_ar": "إركلينز",
                "label_fr": "Erkelenz"
            },
            {
                "value": 37550,
                "label": "Haltern",
                "label_ar": "هالترن",
                "label_fr": "Haltern"
            },
            {
                "value": 37551,
                "label": "Ratingen",
                "label_ar": "راتينجين",
                "label_fr": "Ratingen"
            },
            {
                "value": 37552,
                "label": "Bocholt",
                "label_ar": "Bocholt",
                "label_fr": "Bocholt"
            },
            {
                "value": 37553,
                "label": "Bergisch Gladbach",
                "label_ar": "بيرجيش جلادباخ",
                "label_fr": "Bergisch Gladbach"
            },
            {
                "value": 37554,
                "label": "Gelnhausen",
                "label_ar": "جيلنهاوزن",
                "label_fr": "Gelnhausen"
            },
            {
                "value": 37555,
                "label": "Seligenstadt",
                "label_ar": "سيليجنشتات",
                "label_fr": "Seligenstadt"
            },
            {
                "value": 37556,
                "label": "Hanau",
                "label_ar": "هاناو",
                "label_fr": "Hanau"
            },
            {
                "value": 37557,
                "label": "Bruhl",
                "label_ar": "برول",
                "label_fr": "Bruhl"
            },
            {
                "value": 37558,
                "label": "Lohmar",
                "label_ar": "لوهمار",
                "label_fr": "Lohmar"
            },
            {
                "value": 37559,
                "label": "Mönchengladbach",
                "label_ar": "مونشنغلادباخ",
                "label_fr": "Mönchengladbach"
            },
            {
                "value": 37560,
                "label": "Neuss",
                "label_ar": "نيوس",
                "label_fr": "Neuss"
            },
            {
                "value": 37561,
                "label": "Troisdorf",
                "label_ar": "ترويسدورف",
                "label_fr": "Troisdorf"
            },
            {
                "value": 37562,
                "label": "Geldern",
                "label_ar": "جيلديرن",
                "label_fr": "Geldern"
            },
            {
                "value": 37563,
                "label": "Egenhausen",
                "label_ar": "إيجنهاوزن",
                "label_fr": "Egenhausen"
            },
            {
                "value": 37564,
                "label": "Altensteig",
                "label_ar": "التنستيج",
                "label_fr": "Altensteig"
            },
            {
                "value": 37565,
                "label": "Grombach",
                "label_ar": "جرومباخ",
                "label_fr": "Grombach"
            },
            {
                "value": 37566,
                "label": "Calw",
                "label_ar": "كالو",
                "label_fr": "Calw"
            },
            {
                "value": 37567,
                "label": "Inzigkofen",
                "label_ar": "إنزيجكوفين",
                "label_fr": "Inzigkofen"
            },
            {
                "value": 37568,
                "label": "Muhlacker",
                "label_ar": "مهلاكير",
                "label_fr": "Muhlacker"
            },
            {
                "value": 37569,
                "label": "Ostrach",
                "label_ar": "اوستراخ",
                "label_fr": "Ostrach"
            },
            {
                "value": 37570,
                "label": "Flein",
                "label_ar": "فلين",
                "label_fr": "Flein"
            },
            {
                "value": 37571,
                "label": "Buhl",
                "label_ar": "بوهل",
                "label_fr": "Buhl"
            },
            {
                "value": 37572,
                "label": "Wolfschlugen",
                "label_ar": "وولفشلوجين",
                "label_fr": "Wolfschlugen"
            },
            {
                "value": 37573,
                "label": "Wendlingen am Neckar",
                "label_ar": "Wendlingen am Neckar",
                "label_fr": "Wendlingen am Neckar"
            },
            {
                "value": 37574,
                "label": "Volkertshausen",
                "label_ar": "فولكرتسهاوزن",
                "label_fr": "Volkertshausen"
            },
            {
                "value": 37575,
                "label": "Teningen",
                "label_ar": "تينينجين",
                "label_fr": "Teningen"
            },
            {
                "value": 37576,
                "label": "Albershausen",
                "label_ar": "ألبيرشاوسين",
                "label_fr": "Albershausen"
            },
            {
                "value": 37577,
                "label": "Zell unter Aichelberg",
                "label_ar": "Zell unter Aichelberg",
                "label_fr": "Zell unter Aichelberg"
            },
            {
                "value": 37578,
                "label": "Nurtingen",
                "label_ar": "نورتنغن",
                "label_fr": "Nurtingen"
            },
            {
                "value": 37579,
                "label": "Kirchentellinsfurt",
                "label_ar": "كيرشينتيلينسفورت",
                "label_fr": "Kirchentellinsfurt"
            },
            {
                "value": 37580,
                "label": "Wannweil",
                "label_ar": "وانويل",
                "label_fr": "Wannweil"
            },
            {
                "value": 37581,
                "label": "Schiltach",
                "label_ar": "شيلتاش",
                "label_fr": "Schiltach"
            },
            {
                "value": 37582,
                "label": "Unterkirnach",
                "label_ar": "Unterkirnach",
                "label_fr": "Unterkirnach"
            },
            {
                "value": 37583,
                "label": "Monchweiler",
                "label_ar": "مونشويلر",
                "label_fr": "Monchweiler"
            },
            {
                "value": 37584,
                "label": "Spaichingen",
                "label_ar": "سبايتشنجين",
                "label_fr": "Spaichingen"
            },
            {
                "value": 37585,
                "label": "Weinstadt-Endersbach",
                "label_ar": "Weinstadt-Endersbach",
                "label_fr": "Weinstadt-Endersbach"
            },
            {
                "value": 37586,
                "label": "Urbach",
                "label_ar": "أورباخ",
                "label_fr": "Urbach"
            },
            {
                "value": 37587,
                "label": "Vogt",
                "label_ar": "فوغت",
                "label_fr": "Vogt"
            },
            {
                "value": 37588,
                "label": "Aulendorf",
                "label_ar": "أولندورف",
                "label_fr": "Aulendorf"
            },
            {
                "value": 37589,
                "label": "Reutlingen",
                "label_ar": "ريوتلنجن",
                "label_fr": "Reutlingen"
            },
            {
                "value": 37590,
                "label": "Balingen",
                "label_ar": "بالينغين",
                "label_fr": "Balingen"
            },
            {
                "value": 37591,
                "label": "Konigsbronn",
                "label_ar": "كونيغسبرون",
                "label_fr": "Königsbronn"
            },
            {
                "value": 37592,
                "label": "Aalen",
                "label_ar": "آلين",
                "label_fr": "Aalen"
            },
            {
                "value": 37593,
                "label": "Pluderhausen",
                "label_ar": "بلودرهاوزن",
                "label_fr": "Pluderhausen"
            },
            {
                "value": 37594,
                "label": "Neuhausen auf den Fildern",
                "label_ar": "Neuhausen auf den Fildern",
                "label_fr": "Neuhausen auf den Fildern"
            },
            {
                "value": 37595,
                "label": "Dietenheim",
                "label_ar": "ديتينهايم",
                "label_fr": "Dietenheim"
            },
            {
                "value": 37596,
                "label": "Ummendorf",
                "label_ar": "اومندورف",
                "label_fr": "Ummendorf"
            },
            {
                "value": 37597,
                "label": "Mengen",
                "label_ar": "منجن",
                "label_fr": "Mengen"
            },
            {
                "value": 37598,
                "label": "Saulgau",
                "label_ar": "Saulgau",
                "label_fr": "Saulgau"
            },
            {
                "value": 37599,
                "label": "Unlingen",
                "label_ar": "أونلينجين",
                "label_fr": "Unlingen"
            },
            {
                "value": 37600,
                "label": "Metzingen",
                "label_ar": "ميتسينجين",
                "label_fr": "Metzingen"
            },
            {
                "value": 37601,
                "label": "Sinsheim",
                "label_ar": "سينشيم",
                "label_fr": "Sinsheim"
            },
            {
                "value": 37602,
                "label": "Obrigheim",
                "label_ar": "Obrigheim",
                "label_fr": "Obrigheim"
            },
            {
                "value": 37603,
                "label": "Oedheim",
                "label_ar": "أويدهايم",
                "label_fr": "Oedheim"
            },
            {
                "value": 37604,
                "label": "Ditzingen",
                "label_ar": "ديتزينجين",
                "label_fr": "Ditzingen"
            },
            {
                "value": 37605,
                "label": "Fellbach",
                "label_ar": "فيلباخ",
                "label_fr": "Fellbach"
            },
            {
                "value": 37606,
                "label": "Langenau",
                "label_ar": "لانجيناو",
                "label_fr": "Langenau"
            },
            {
                "value": 37607,
                "label": "Laupheim",
                "label_ar": "لوفهايم",
                "label_fr": "Laupheim"
            },
            {
                "value": 37608,
                "label": "Warthausen",
                "label_ar": "وارتهاوزن",
                "label_fr": "Warthausen"
            },
            {
                "value": 37609,
                "label": "Dornstadt",
                "label_ar": "دورنشتات",
                "label_fr": "Dornstadt"
            },
            {
                "value": 37610,
                "label": "Winnenden",
                "label_ar": "وينيندين",
                "label_fr": "Winnenden"
            },
            {
                "value": 37611,
                "label": "Schwieberdingen",
                "label_ar": "شفيبردينجن",
                "label_fr": "Schwieberdingen"
            },
            {
                "value": 37612,
                "label": "Maulburg",
                "label_ar": "مولبورغ",
                "label_fr": "Maulburg"
            },
            {
                "value": 37613,
                "label": "Lauchringen",
                "label_ar": "لورينجين",
                "label_fr": "Lauchringen"
            },
            {
                "value": 37614,
                "label": "Inzlingen",
                "label_ar": "إنزلينجن",
                "label_fr": "Inzlingen"
            },
            {
                "value": 37615,
                "label": "Philippsburg",
                "label_ar": "فيليبسبورغ",
                "label_fr": "Philippsburg"
            },
            {
                "value": 37616,
                "label": "Waghausel",
                "label_ar": "واغوسيل",
                "label_fr": "Waghausel"
            },
            {
                "value": 37617,
                "label": "Ettlingen",
                "label_ar": "إتلينجن",
                "label_fr": "Ettlingen"
            },
            {
                "value": 37618,
                "label": "Bietigheim",
                "label_ar": "بيتيغيم",
                "label_fr": "Bietigheim"
            },
            {
                "value": 37619,
                "label": "Kehl",
                "label_ar": "كيهل",
                "label_fr": "Kehl"
            },
            {
                "value": 37620,
                "label": "Berghaupten",
                "label_ar": "بيرغوبتن",
                "label_fr": "Berghaupten"
            },
            {
                "value": 37621,
                "label": "Altlussheim",
                "label_ar": "ألتلوسهايم",
                "label_fr": "Altlussheim"
            },
            {
                "value": 37622,
                "label": "Frickingen",
                "label_ar": "فريكشن",
                "label_fr": "Frickingen"
            },
            {
                "value": 37623,
                "label": "Freudenstadt",
                "label_ar": "فرويدنشتات",
                "label_fr": "Freudenstadt"
            },
            {
                "value": 37624,
                "label": "Saarbrücken",
                "label_ar": "ساربروكن",
                "label_fr": "Sarrebruck"
            },
            {
                "value": 37625,
                "label": "Kemnath",
                "label_ar": "كيمناث",
                "label_fr": "Kemnath"
            },
            {
                "value": 37626,
                "label": "Lindau",
                "label_ar": "لينداو",
                "label_fr": "Lindau"
            },
            {
                "value": 37627,
                "label": "Wangen",
                "label_ar": "فانجين",
                "label_fr": "Wangen"
            },
            {
                "value": 37628,
                "label": "Rodental",
                "label_ar": "القوارض",
                "label_fr": "Rodental"
            },
            {
                "value": 37629,
                "label": "Burgrieden",
                "label_ar": "بورغريدن",
                "label_fr": "Burgrieden"
            },
            {
                "value": 37630,
                "label": "Schuttorf",
                "label_ar": "شوتورف",
                "label_fr": "Schuttorf"
            },
            {
                "value": 37631,
                "label": "Emlichheim",
                "label_ar": "امليشهايم",
                "label_fr": "Emlichheim"
            },
            {
                "value": 37632,
                "label": "Borger",
                "label_ar": "بورغر",
                "label_fr": "Borger"
            },
            {
                "value": 37633,
                "label": "Neuenhaus",
                "label_ar": "نوينهاوس",
                "label_fr": "Neuenhaus"
            },
            {
                "value": 37634,
                "label": "Osterwald",
                "label_ar": "أوسترفالد",
                "label_fr": "Osterwald"
            },
            {
                "value": 37635,
                "label": "Visselhovede",
                "label_ar": "فيسيلهوفيد",
                "label_fr": "Visselhovede"
            },
            {
                "value": 37636,
                "label": "Scheessel",
                "label_ar": "شيسل",
                "label_fr": "Scheessel"
            },
            {
                "value": 37637,
                "label": "Harsefeld",
                "label_ar": "هارسفيلد",
                "label_fr": "Harsefeld"
            },
            {
                "value": 37638,
                "label": "Vierhofen",
                "label_ar": "فيرهوفن",
                "label_fr": "Vierhofen"
            },
            {
                "value": 37639,
                "label": "Salzhausen",
                "label_ar": "سالزهاوزن",
                "label_fr": "Salzhausen"
            },
            {
                "value": 37640,
                "label": "Hollenstedt",
                "label_ar": "هولينستيدت",
                "label_fr": "Hollenstedt"
            },
            {
                "value": 37641,
                "label": "Garlstorf",
                "label_ar": "جارلستورف",
                "label_fr": "Garlstorf"
            },
            {
                "value": 37642,
                "label": "Egestorf",
                "label_ar": "ايجستورف",
                "label_fr": "Egestorf"
            },
            {
                "value": 37643,
                "label": "Asendorf",
                "label_ar": "أسندورف",
                "label_fr": "Asendorf"
            },
            {
                "value": 37644,
                "label": "Hemslingen",
                "label_ar": "هيمسلنغن",
                "label_fr": "Hemslingen"
            },
            {
                "value": 37645,
                "label": "Sittensen",
                "label_ar": "سيتينسن",
                "label_fr": "Sittensen"
            },
            {
                "value": 37646,
                "label": "Ruspel",
                "label_ar": "روسبيل",
                "label_fr": "Ruspel"
            },
            {
                "value": 37647,
                "label": "Hetzwege",
                "label_ar": "هتزويجي",
                "label_fr": "Hetzwege"
            },
            {
                "value": 37648,
                "label": "Vierden",
                "label_ar": "فيردن",
                "label_fr": "Vierden"
            },
            {
                "value": 37649,
                "label": "Selsingen",
                "label_ar": "سيلسينجين",
                "label_fr": "Selsingen"
            },
            {
                "value": 37650,
                "label": "Grasberg",
                "label_ar": "جراسبيرج",
                "label_fr": "Grasberg"
            },
            {
                "value": 37651,
                "label": "Hammah",
                "label_ar": "حمة",
                "label_fr": "Hammah"
            },
            {
                "value": 37652,
                "label": "Edewecht",
                "label_ar": "Edewecht",
                "label_fr": "Edewecht"
            },
            {
                "value": 37653,
                "label": "Harpstedt",
                "label_ar": "هاربستيدت",
                "label_fr": "Harpstedt"
            },
            {
                "value": 37654,
                "label": "Bassum",
                "label_ar": "بسوم",
                "label_fr": "Bassum"
            },
            {
                "value": 37655,
                "label": "Holtinghausen",
                "label_ar": "هولتينغهاوزن",
                "label_fr": "Holtinghausen"
            },
            {
                "value": 37656,
                "label": "Grossenkneten",
                "label_ar": "Grossenkneten",
                "label_fr": "Grossenkneten"
            },
            {
                "value": 37657,
                "label": "Beckstedt",
                "label_ar": "بيكستيدت",
                "label_fr": "Beckstedt"
            },
            {
                "value": 37658,
                "label": "Bosel",
                "label_ar": "بوسل",
                "label_fr": "Bosel"
            },
            {
                "value": 37659,
                "label": "Dingstede",
                "label_ar": "Dingstede",
                "label_fr": "Dingstede"
            },
            {
                "value": 37660,
                "label": "Mehr",
                "label_ar": "مهر",
                "label_fr": "Mehr"
            },
            {
                "value": 37661,
                "label": "Bank",
                "label_ar": "مصرف",
                "label_fr": "Banque"
            },
            {
                "value": 37662,
                "label": "Memmingerberg",
                "label_ar": "Memmingerberg",
                "label_fr": "Memmingerberg"
            },
            {
                "value": 37663,
                "label": "Hetzlinshofen",
                "label_ar": "هيتزلنسوفين",
                "label_fr": "Hetzlinshofen"
            },
            {
                "value": 37664,
                "label": "Lachen",
                "label_ar": "لاتشين",
                "label_fr": "Lachen"
            },
            {
                "value": 37665,
                "label": "Zusmarshausen",
                "label_ar": "Zusmarshausen",
                "label_fr": "Zusmarshausen"
            },
            {
                "value": 37666,
                "label": "Stolberg",
                "label_ar": "ستولبرغ",
                "label_fr": "Stolberg"
            },
            {
                "value": 37667,
                "label": "Gütersloh",
                "label_ar": "جوترسلوه",
                "label_fr": "Gütersloh"
            },
            {
                "value": 37668,
                "label": "Vellmar",
                "label_ar": "فيلمار",
                "label_fr": "Vellmar"
            },
            {
                "value": 37669,
                "label": "Ennepetal",
                "label_ar": "Ennepetal",
                "label_fr": "Ennepetal"
            },
            {
                "value": 37670,
                "label": "Neuwerk",
                "label_ar": "نويرك",
                "label_fr": "Neuwerk"
            },
            {
                "value": 37671,
                "label": "Viersen",
                "label_ar": "فيرسين",
                "label_fr": "Viersen"
            },
            {
                "value": 37672,
                "label": "Korschenbroich",
                "label_ar": "كورشنبرويتش",
                "label_fr": "Korschenbroich"
            },
            {
                "value": 37673,
                "label": "Nidda",
                "label_ar": "ندة",
                "label_fr": "Nidda"
            },
            {
                "value": 37674,
                "label": "Erkrath",
                "label_ar": "اركراث",
                "label_fr": "Erkrath"
            },
            {
                "value": 37675,
                "label": "Hilden",
                "label_ar": "هيلدن",
                "label_fr": "Hilden"
            },
            {
                "value": 37676,
                "label": "Isenburg",
                "label_ar": "ايسنبرج",
                "label_fr": "Isenburg"
            },
            {
                "value": 37677,
                "label": "Biedenkopf",
                "label_ar": "بيدنكوف",
                "label_fr": "Biedenkopf"
            },
            {
                "value": 37678,
                "label": "Langenfeld",
                "label_ar": "لانغنفيلد",
                "label_fr": "Langenfeld"
            },
            {
                "value": 37679,
                "label": "Rosrath",
                "label_ar": "روسرات",
                "label_fr": "Rosrath"
            },
            {
                "value": 37680,
                "label": "Monheim am Rhein",
                "label_ar": "مونهايم ام راين",
                "label_fr": "Monheim am Rhein"
            },
            {
                "value": 37681,
                "label": "Frankenberg",
                "label_ar": "فرانكنبيرج",
                "label_fr": "Frankenberg"
            },
            {
                "value": 37682,
                "label": "Dreieich",
                "label_ar": "درييش",
                "label_fr": "Dreieich"
            },
            {
                "value": 37683,
                "label": "Fulda",
                "label_ar": "فولدا",
                "label_fr": "Fulda"
            },
            {
                "value": 37684,
                "label": "Düren",
                "label_ar": "دورين",
                "label_fr": "Düren"
            },
            {
                "value": 37685,
                "label": "Kerpen",
                "label_ar": "كيربين",
                "label_fr": "Kerpen"
            },
            {
                "value": 37686,
                "label": "Julich",
                "label_ar": "جوليتش",
                "label_fr": "Julich"
            },
            {
                "value": 37687,
                "label": "Kreuzau",
                "label_ar": "كروزاو",
                "label_fr": "Kreuzau"
            },
            {
                "value": 37688,
                "label": "Espelkamp-Mittwald",
                "label_ar": "اسبيلكامب ميتوالد",
                "label_fr": "Espelkamp-Mittwald"
            },
            {
                "value": 37689,
                "label": "Sigmaringen",
                "label_ar": "سيجمارينجين",
                "label_fr": "Sigmaringen"
            },
            {
                "value": 37690,
                "label": "Schlaitdorf",
                "label_ar": "شلايتدورف",
                "label_fr": "Schlaitdorf"
            },
            {
                "value": 37691,
                "label": "Tübingen",
                "label_ar": "توبنغن",
                "label_fr": "Tübingen"
            },
            {
                "value": 37692,
                "label": "Ettenheim",
                "label_ar": "إتنهايم",
                "label_fr": "Ettenheim"
            },
            {
                "value": 37693,
                "label": "Offenburg",
                "label_ar": "أوفنبرج",
                "label_fr": "Offenbourg"
            },
            {
                "value": 37694,
                "label": "Staufen im Breisgau",
                "label_ar": "Staufen im Breisgau",
                "label_fr": "Staufen im Breisgau"
            },
            {
                "value": 37695,
                "label": "Nufringen",
                "label_ar": "نوفرينجن",
                "label_fr": "Nufringen"
            },
            {
                "value": 37696,
                "label": "Böblingen",
                "label_ar": "بوبلينجن",
                "label_fr": "Böblingen"
            },
            {
                "value": 37697,
                "label": "Hildrizhausen",
                "label_ar": "هيلدريزهاوزن",
                "label_fr": "Hildrizhausen"
            },
            {
                "value": 37698,
                "label": "Heidenheim",
                "label_ar": "هايدنهايم",
                "label_fr": "Heidenheim"
            },
            {
                "value": 37699,
                "label": "Rosengarten",
                "label_ar": "روزنغارتن",
                "label_fr": "Rosengarten"
            },
            {
                "value": 37700,
                "label": "Leutkirch im Allgau",
                "label_ar": "Leutkirch im Allgau",
                "label_fr": "Leutkirch im Allgau"
            },
            {
                "value": 37701,
                "label": "Sankt Leon-Rot",
                "label_ar": "سانكت ليون روت",
                "label_fr": "Sankt Léon-Rot"
            },
            {
                "value": 37702,
                "label": "Heddesheim",
                "label_ar": "هيديسهايم",
                "label_fr": "Heddesheim"
            },
            {
                "value": 37703,
                "label": "Reichenbach",
                "label_ar": "Reichenbach",
                "label_fr": "Reichenbach"
            },
            {
                "value": 37704,
                "label": "Talheim",
                "label_ar": "تالهايم",
                "label_fr": "Talheim"
            },
            {
                "value": 37705,
                "label": "Steinheim am der Murr",
                "label_ar": "Steinheim am der Murr",
                "label_fr": "Steinheim am der Murr"
            },
            {
                "value": 37706,
                "label": "Lochgau",
                "label_ar": "Lochgau",
                "label_fr": "Lochgau"
            },
            {
                "value": 37707,
                "label": "Sipplingen",
                "label_ar": "سيبلنجن",
                "label_fr": "Sipplingen"
            },
            {
                "value": 37708,
                "label": "Sulz am Neckar",
                "label_ar": "سولز آم نيكار",
                "label_fr": "Sulz am Neckar"
            },
            {
                "value": 37709,
                "label": "Ilsfeld",
                "label_ar": "إلسفيلد",
                "label_fr": "Ilsfeld"
            },
            {
                "value": 37710,
                "label": "Mosbach",
                "label_ar": "موسباخ",
                "label_fr": "Mosbach"
            },
            {
                "value": 37711,
                "label": "Friesenheim",
                "label_ar": "فريزنهايم",
                "label_fr": "Friesenheim"
            },
            {
                "value": 37712,
                "label": "Durbach",
                "label_ar": "دورباخ",
                "label_fr": "Durbach"
            },
            {
                "value": 37713,
                "label": "Welzheim",
                "label_ar": "ويلزهايم",
                "label_fr": "Welzheim"
            },
            {
                "value": 37714,
                "label": "Donzdorf",
                "label_ar": "دونزدورف",
                "label_fr": "Donzdorf"
            },
            {
                "value": 37715,
                "label": "Dettingen unter Teck",
                "label_ar": "Dettingen unter Teck",
                "label_fr": "Dettingen unter Teck"
            },
            {
                "value": 37716,
                "label": "Filderstadt",
                "label_ar": "فيلدرشتات",
                "label_fr": "Filderstadt"
            },
            {
                "value": 37717,
                "label": "Ohringen",
                "label_ar": "أوهرينجين",
                "label_fr": "Ohringen"
            },
            {
                "value": 37718,
                "label": "Rietheim",
                "label_ar": "ريثيم",
                "label_fr": "Rietheim"
            },
            {
                "value": 37719,
                "label": "Tuningen",
                "label_ar": "تونينجن",
                "label_fr": "Tuningen"
            },
            {
                "value": 37720,
                "label": "Karben",
                "label_ar": "كاربين",
                "label_fr": "Karben"
            },
            {
                "value": 37721,
                "label": "Heusweiler",
                "label_ar": "Heusweiler",
                "label_fr": "Heusweiler"
            },
            {
                "value": 37722,
                "label": "Sankt Ingbert",
                "label_ar": "سانكت انجبرت",
                "label_fr": "Sankt Ingbert"
            },
            {
                "value": 37723,
                "label": "Illingen",
                "label_ar": "إلينجن",
                "label_fr": "Illingen"
            },
            {
                "value": 37724,
                "label": "Volklingen",
                "label_ar": "فولكلينجن",
                "label_fr": "Volklingen"
            },
            {
                "value": 37725,
                "label": "Grossrosseln",
                "label_ar": "جروسروسيلن",
                "label_fr": "Grossrosseln"
            },
            {
                "value": 37726,
                "label": "Sankt Wendel",
                "label_ar": "سانكت ويندل",
                "label_fr": "Sankt Wendel"
            },
            {
                "value": 37727,
                "label": "Riegelsberg",
                "label_ar": "ريجلسبيرج",
                "label_fr": "Riegelsberg"
            },
            {
                "value": 37728,
                "label": "Quierschied",
                "label_ar": "Quierschied",
                "label_fr": "Quierschied"
            },
            {
                "value": 37729,
                "label": "Saarwellingen",
                "label_ar": "سارويلجين",
                "label_fr": "Saarwellingen"
            },
            {
                "value": 37730,
                "label": "Remmesweiler",
                "label_ar": "ريميسفايلر",
                "label_fr": "Remmesweiler"
            },
            {
                "value": 37731,
                "label": "Dessau",
                "label_ar": "ديساو",
                "label_fr": "Dessau"
            },
            {
                "value": 37732,
                "label": "Bernsdorf",
                "label_ar": "بيرنسدورف",
                "label_fr": "Bernsdorf"
            },
            {
                "value": 37733,
                "label": "Grimma",
                "label_ar": "جريم",
                "label_fr": "Grimma"
            },
            {
                "value": 37734,
                "label": "Saalfeld",
                "label_ar": "Saalfeld",
                "label_fr": "Saalfeld"
            },
            {
                "value": 37735,
                "label": "Neumark",
                "label_ar": "نيومارك",
                "label_fr": "Neumark"
            },
            {
                "value": 37736,
                "label": "Erlenmoos",
                "label_ar": "إرلينموس",
                "label_fr": "Erlenmoos"
            },
            {
                "value": 37737,
                "label": "Vaihingen an der Enz",
                "label_ar": "Vaihingen an der Enz",
                "label_fr": "Vaihingen an der Enz"
            },
            {
                "value": 37738,
                "label": "Erdmannhausen",
                "label_ar": "إردمانهاوزن",
                "label_fr": "Erdmannhausen"
            },
            {
                "value": 37739,
                "label": "Weitnau",
                "label_ar": "ويتناو",
                "label_fr": "Weitnau"
            },
            {
                "value": 37740,
                "label": "Bad Waldsee",
                "label_ar": "باد فالدسي",
                "label_fr": "Bad Waldsee"
            },
            {
                "value": 37741,
                "label": "Kornwestheim",
                "label_ar": "كورنويستهايم",
                "label_fr": "Kornwestheim"
            },
            {
                "value": 37742,
                "label": "Bad Herrenalb",
                "label_ar": "باد هيرينالب",
                "label_fr": "Bad Herrenalb"
            },
            {
                "value": 37743,
                "label": "Loffenau",
                "label_ar": "لوفيناو",
                "label_fr": "Loffenau"
            },
            {
                "value": 37744,
                "label": "Weingarten",
                "label_ar": "وينجارتن",
                "label_fr": "Weingarten"
            },
            {
                "value": 37745,
                "label": "Wangen im Allgau",
                "label_ar": "وانجين إم ألغاو",
                "label_fr": "Wangen im Allgau"
            },
            {
                "value": 37746,
                "label": "Herrischried",
                "label_ar": "هريشريد",
                "label_fr": "Herrischried"
            },
            {
                "value": 37747,
                "label": "Denkingen",
                "label_ar": "دينكينجين",
                "label_fr": "Denkingen"
            },
            {
                "value": 37748,
                "label": "Loffingen",
                "label_ar": "لوفينغن",
                "label_fr": "Loffingen"
            },
            {
                "value": 37749,
                "label": "Schorndorf",
                "label_ar": "شورندورف",
                "label_fr": "Schorndorf"
            },
            {
                "value": 37750,
                "label": "Denzlingen",
                "label_ar": "دينزلنغن",
                "label_fr": "Denzlingen"
            },
            {
                "value": 37751,
                "label": "Eschenbach",
                "label_ar": "إشنباخ",
                "label_fr": "Eschenbach"
            },
            {
                "value": 37752,
                "label": "Bissingen an der Teck",
                "label_ar": "Bissingen an der Teck",
                "label_fr": "Bissingen an der Teck"
            },
            {
                "value": 37753,
                "label": "Eislingen",
                "label_ar": "ايسلينجن",
                "label_fr": "Eislingen"
            },
            {
                "value": 37754,
                "label": "Unterensingen",
                "label_ar": "Unterensingen",
                "label_fr": "Unterensingen"
            },
            {
                "value": 37755,
                "label": "Bad Ditzenbach",
                "label_ar": "باد ديتزنباخ",
                "label_fr": "Bad Ditzenbach"
            },
            {
                "value": 37756,
                "label": "Neuenburg am Rhein",
                "label_ar": "نوينبورغ ام راين",
                "label_fr": "Neuenburg am Rhein"
            },
            {
                "value": 37757,
                "label": "Walldorf",
                "label_ar": "فالدورف",
                "label_fr": "Walldorf"
            },
            {
                "value": 37758,
                "label": "Laudenbach",
                "label_ar": "لودنباخ",
                "label_fr": "Laudenbach"
            },
            {
                "value": 37759,
                "label": "Durmersheim",
                "label_ar": "دورمرشيم",
                "label_fr": "Durmersheim"
            },
            {
                "value": 37760,
                "label": "Untergruppenbach",
                "label_ar": "أونتيرجروبنباخ",
                "label_fr": "Untergruppenbach"
            },
            {
                "value": 37761,
                "label": "Eberstadt",
                "label_ar": "إبرستادت",
                "label_fr": "Eberstadt"
            },
            {
                "value": 37762,
                "label": "Bad Rappenau",
                "label_ar": "باد رابيناو",
                "label_fr": "Bad Rappenau"
            },
            {
                "value": 37763,
                "label": "Raibach",
                "label_ar": "رايباتش",
                "label_fr": "Raibach"
            },
            {
                "value": 37764,
                "label": "Braunsbach",
                "label_ar": "براونسباخ",
                "label_fr": "Braunsbach"
            },
            {
                "value": 37765,
                "label": "Heubach",
                "label_ar": "هيوباتش",
                "label_fr": "Heubach"
            },
            {
                "value": 37766,
                "label": "Ladenburg",
                "label_ar": "لادنبرغ",
                "label_fr": "Ladenburg"
            },
            {
                "value": 37767,
                "label": "Bruhl",
                "label_ar": "برول",
                "label_fr": "Bruhl"
            },
            {
                "value": 37768,
                "label": "Plankstadt",
                "label_ar": "بلانكشتات",
                "label_fr": "Plankstadt"
            },
            {
                "value": 37769,
                "label": "Schwaikheim",
                "label_ar": "شفايكيم",
                "label_fr": "Schwaikheim"
            },
            {
                "value": 37770,
                "label": "Kuppenheim",
                "label_ar": "كوبنهايم",
                "label_fr": "Kuppenheim"
            },
            {
                "value": 37771,
                "label": "Hirrlingen",
                "label_ar": "هيرلينجن",
                "label_fr": "Hirrlingen"
            },
            {
                "value": 37772,
                "label": "Rangendingen",
                "label_ar": "رانجيندين",
                "label_fr": "Rangendingen"
            },
            {
                "value": 37773,
                "label": "Weikersheim",
                "label_ar": "Weikersheim",
                "label_fr": "Weikersheim"
            },
            {
                "value": 37774,
                "label": "Pliezhausen",
                "label_ar": "بليزهاوزن",
                "label_fr": "Pliezhausen"
            },
            {
                "value": 37775,
                "label": "Erbach",
                "label_ar": "ارباخ",
                "label_fr": "Erbach"
            },
            {
                "value": 37776,
                "label": "Mietingen",
                "label_ar": "ميتينغن",
                "label_fr": "Mietingen"
            },
            {
                "value": 37777,
                "label": "Huttisheim",
                "label_ar": "هوتيشيم",
                "label_fr": "Huttisheim"
            },
            {
                "value": 37778,
                "label": "Rottenacker",
                "label_ar": "روتينكر",
                "label_fr": "Rottenacker"
            },
            {
                "value": 37779,
                "label": "Zaberfeld",
                "label_ar": "زابيرفيلد",
                "label_fr": "Zaberfeld"
            },
            {
                "value": 37780,
                "label": "Eisingen",
                "label_ar": "ايسينجين",
                "label_fr": "Eisingen"
            },
            {
                "value": 37781,
                "label": "Weil der Stadt",
                "label_ar": "Weil der Stadt",
                "label_fr": "Weil der Stadt"
            },
            {
                "value": 37782,
                "label": "Schlat",
                "label_ar": "شلات",
                "label_fr": "Schlat"
            },
            {
                "value": 37783,
                "label": "Kongen",
                "label_ar": "كونجين",
                "label_fr": "Kongen"
            },
            {
                "value": 37784,
                "label": "Walldurn",
                "label_ar": "والديرن",
                "label_fr": "Walldurn"
            },
            {
                "value": 37785,
                "label": "Niederstetten",
                "label_ar": "نيدرستيتن",
                "label_fr": "Niederstetten"
            },
            {
                "value": 37786,
                "label": "Bad Wimpfen",
                "label_ar": "باد ويمبفن",
                "label_fr": "Bad Wimpfen"
            },
            {
                "value": 37787,
                "label": "Zweiflingen",
                "label_ar": "زفايفلينجن",
                "label_fr": "Zweiflingen"
            },
            {
                "value": 37788,
                "label": "Siegelsbach",
                "label_ar": "سيجيلسباخ",
                "label_fr": "Siegelsbach"
            },
            {
                "value": 37789,
                "label": "Brackenheim",
                "label_ar": "براكنهايم",
                "label_fr": "Brackenheim"
            },
            {
                "value": 37790,
                "label": "Asperg",
                "label_ar": "أسبيرج",
                "label_fr": "Asperg"
            },
            {
                "value": 37791,
                "label": "Aspach",
                "label_ar": "اسباش",
                "label_fr": "Aspach"
            },
            {
                "value": 37792,
                "label": "Dusslingen",
                "label_ar": "دوسلينجن",
                "label_fr": "Dusslingen"
            },
            {
                "value": 37793,
                "label": "Hechingen",
                "label_ar": "Hechingen",
                "label_fr": "Hechingen"
            },
            {
                "value": 37794,
                "label": "Grossbettlingen",
                "label_ar": "جروسبيتلينجن",
                "label_fr": "Grossbettlingen"
            },
            {
                "value": 37795,
                "label": "Herdwangen-Schonach",
                "label_ar": "Herdwangen-Schonach",
                "label_fr": "Herdwangen-Schonach"
            },
            {
                "value": 37796,
                "label": "Schallstadt",
                "label_ar": "شالشتات",
                "label_fr": "Schallstadt"
            },
            {
                "value": 37797,
                "label": "Schliengen",
                "label_ar": "شلينجن",
                "label_fr": "Schliengen"
            },
            {
                "value": 37798,
                "label": "Gernsbach",
                "label_ar": "غيرنسباخ",
                "label_fr": "Gernsbach"
            },
            {
                "value": 37799,
                "label": "Kuenzelsau",
                "label_ar": "كوينزلساو",
                "label_fr": "Kuenzelsau"
            },
            {
                "value": 37800,
                "label": "Lautenbach",
                "label_ar": "لاوتنباخ",
                "label_fr": "Lautenbach"
            },
            {
                "value": 37801,
                "label": "Kippenheim",
                "label_ar": "كيبنهايم",
                "label_fr": "Kippenheim"
            },
            {
                "value": 37802,
                "label": "Niederstotzingen",
                "label_ar": "نيدرشتوتزينجين",
                "label_fr": "Niederstotzingen"
            },
            {
                "value": 37803,
                "label": "Baltringen",
                "label_ar": "بالترينجين",
                "label_fr": "Baltringen"
            },
            {
                "value": 37804,
                "label": "Rehburg-Loccum",
                "label_ar": "ريبورغ-لوكوم",
                "label_fr": "Rehburg-Loccum"
            },
            {
                "value": 37805,
                "label": "Stockse",
                "label_ar": "ستوكسي",
                "label_fr": "Stockse"
            },
            {
                "value": 37806,
                "label": "Neustadt am Rubenberge",
                "label_ar": "نيوستادت آم روبنبرج",
                "label_fr": "Neustadt am Rubenberge"
            },
            {
                "value": 37807,
                "label": "Husum",
                "label_ar": "هوسوم",
                "label_fr": "Husum"
            },
            {
                "value": 37808,
                "label": "Uchte",
                "label_ar": "أوخت",
                "label_fr": "Uchte"
            },
            {
                "value": 37809,
                "label": "Dornheim",
                "label_ar": "دورنهايم",
                "label_fr": "Dornheim"
            },
            {
                "value": 37810,
                "label": "Premnitz",
                "label_ar": "بريمنيتز",
                "label_fr": "Premnitz"
            },
            {
                "value": 37811,
                "label": "Ingolstadt",
                "label_ar": "إنغولشتات",
                "label_fr": "Ingolstadt"
            },
            {
                "value": 37812,
                "label": "Dachau",
                "label_ar": "داخاو",
                "label_fr": "Dachau"
            },
            {
                "value": 37813,
                "label": "Pullhausen",
                "label_ar": "بولهاوزن",
                "label_fr": "Pullhausen"
            },
            {
                "value": 37814,
                "label": "Sochtenau",
                "label_ar": "سوتشتيناو",
                "label_fr": "Sochtenau"
            },
            {
                "value": 37815,
                "label": "Rosenheim",
                "label_ar": "روزنهايم",
                "label_fr": "Rosenheim"
            },
            {
                "value": 37816,
                "label": "Radevormwald",
                "label_ar": "راديفورموالد",
                "label_fr": "Radevormwald"
            },
            {
                "value": 37817,
                "label": "Boostedt",
                "label_ar": "عززت",
                "label_fr": "Boostedt"
            },
            {
                "value": 37818,
                "label": "Gross Kummerfeld",
                "label_ar": "جروس كومرفيلد",
                "label_fr": "Gross Kummerfeld"
            },
            {
                "value": 37819,
                "label": "Bonebuttel",
                "label_ar": "بونبوتيل",
                "label_fr": "Bonebuttel"
            },
            {
                "value": 37820,
                "label": "Ehndorf",
                "label_ar": "ايندورف",
                "label_fr": "Ehndorf"
            },
            {
                "value": 37821,
                "label": "Grossenaspe",
                "label_ar": "جروسيناسبي",
                "label_fr": "Grossenaspe"
            },
            {
                "value": 37822,
                "label": "Bruckberg",
                "label_ar": "بروكبرج",
                "label_fr": "Bruckberg"
            },
            {
                "value": 37823,
                "label": "Krugzell",
                "label_ar": "كروغزيل",
                "label_fr": "Krugzell"
            },
            {
                "value": 37824,
                "label": "Oesterdeichstrich",
                "label_ar": "Oesterdeichstrich",
                "label_fr": "Oesterdeichstrich"
            },
            {
                "value": 37825,
                "label": "Kolpien",
                "label_ar": "كولبين",
                "label_fr": "Kolpien"
            },
            {
                "value": 37826,
                "label": "Numbrecht",
                "label_ar": "نومبريخت",
                "label_fr": "Numbrecht"
            },
            {
                "value": 37827,
                "label": "Eppingen",
                "label_ar": "إبينغن",
                "label_fr": "Eppingen"
            },
            {
                "value": 37828,
                "label": "Oppenweiler",
                "label_ar": "أوبنويلر",
                "label_fr": "Oppenweiler"
            },
            {
                "value": 37829,
                "label": "Wald-Michelbach",
                "label_ar": "والد ميشيلباخ",
                "label_fr": "Wald-Michelbach"
            },
            {
                "value": 37830,
                "label": "Trebur",
                "label_ar": "تريبور",
                "label_fr": "Trebur"
            },
            {
                "value": 37831,
                "label": "Tellingstedt",
                "label_ar": "Tellingstedt",
                "label_fr": "Tellingstedt"
            },
            {
                "value": 37832,
                "label": "Proseken",
                "label_ar": "نثر",
                "label_fr": "Proseken"
            },
            {
                "value": 37833,
                "label": "Inzell",
                "label_ar": "إنزيل",
                "label_fr": "Inzell"
            },
            {
                "value": 37834,
                "label": "Aichwald",
                "label_ar": "ايشوالد",
                "label_fr": "Aichwald"
            },
            {
                "value": 37835,
                "label": "Hude",
                "label_ar": "هود",
                "label_fr": "Hude"
            },
            {
                "value": 37836,
                "label": "Pleckhausen",
                "label_ar": "بليكهاوزن",
                "label_fr": "Pleckhausen"
            },
            {
                "value": 37837,
                "label": "Velbert",
                "label_ar": "فيلبرت",
                "label_fr": "Velbert"
            },
            {
                "value": 37838,
                "label": "Pulheim",
                "label_ar": "بولهايم",
                "label_fr": "Pulheim"
            },
            {
                "value": 37839,
                "label": "Delitzsch",
                "label_ar": "Delitzsch",
                "label_fr": "Delitzsch"
            },
            {
                "value": 37840,
                "label": "Westerburg",
                "label_ar": "ويستربرغ",
                "label_fr": "Westerburg"
            },
            {
                "value": 37841,
                "label": "Hürth",
                "label_ar": "هورت",
                "label_fr": "Hürth"
            },
            {
                "value": 37842,
                "label": "Solingen",
                "label_ar": "سولينجن",
                "label_fr": "Solingen"
            },
            {
                "value": 37843,
                "label": "Elz",
                "label_ar": "إلز",
                "label_fr": "Elz"
            },
            {
                "value": 37844,
                "label": "Quedlinburg",
                "label_ar": "كيدلينبرج",
                "label_fr": "Quedlinburg"
            },
            {
                "value": 37845,
                "label": "Frankfurt (Oder)",
                "label_ar": "فرانكفورت (أودر)",
                "label_fr": "Francfort (Oder)"
            },
            {
                "value": 37846,
                "label": "Unterfoehring",
                "label_ar": "Unterfoehring",
                "label_fr": "Unterfoehring"
            },
            {
                "value": 37847,
                "label": "Fohren",
                "label_ar": "فوهرين",
                "label_fr": "Fohren"
            },
            {
                "value": 37848,
                "label": "Ottobrunn",
                "label_ar": "Ottobrunn",
                "label_fr": "Ottobrunn"
            },
            {
                "value": 37849,
                "label": "Weil am Rhein",
                "label_ar": "وايل ام راين",
                "label_fr": "Weil am Rhein"
            },
            {
                "value": 37850,
                "label": "Heimstetten",
                "label_ar": "Heimstetten",
                "label_fr": "Heimstetten"
            },
            {
                "value": 37851,
                "label": "Schacht-Audorf",
                "label_ar": "شاخت أودورف",
                "label_fr": "Schacht-Audorf"
            },
            {
                "value": 37852,
                "label": "Bohmstedt",
                "label_ar": "بومستيدت",
                "label_fr": "Bohmstedt"
            },
            {
                "value": 37853,
                "label": "Rumohr",
                "label_ar": "رومهر",
                "label_fr": "Rumohr"
            },
            {
                "value": 37854,
                "label": "Probsteierhagen",
                "label_ar": "Probsteierhagen",
                "label_fr": "Probsteierhagen"
            },
            {
                "value": 37855,
                "label": "Schellhorn",
                "label_ar": "شيلهورن",
                "label_fr": "Schellhorn"
            },
            {
                "value": 37856,
                "label": "Laboe",
                "label_ar": "لابو",
                "label_fr": "Laboe"
            },
            {
                "value": 37857,
                "label": "Lebrade",
                "label_ar": "ليبرادي",
                "label_fr": "Lebrade"
            },
            {
                "value": 37858,
                "label": "Schonberg",
                "label_ar": "شونبيرج",
                "label_fr": "Schonberg"
            },
            {
                "value": 37859,
                "label": "Flintbek",
                "label_ar": "فلينتبيك",
                "label_fr": "Flintbek"
            },
            {
                "value": 37860,
                "label": "Wisch",
                "label_ar": "ويش",
                "label_fr": "Wisch"
            },
            {
                "value": 37861,
                "label": "Quarnbek",
                "label_ar": "كوارنبيك",
                "label_fr": "Quarnbek"
            },
            {
                "value": 37862,
                "label": "Felde",
                "label_ar": "فيلد",
                "label_fr": "Felde"
            },
            {
                "value": 37863,
                "label": "Schoenberg",
                "label_ar": "شوينبيرج",
                "label_fr": "Schoenberg"
            },
            {
                "value": 37864,
                "label": "Drelsdorf",
                "label_ar": "دريلسدورف",
                "label_fr": "Drelsdorf"
            },
            {
                "value": 37865,
                "label": "Langwieden",
                "label_ar": "لانغويدين",
                "label_fr": "Langwieden"
            },
            {
                "value": 37866,
                "label": "Sippersfeld",
                "label_ar": "سيبرزفيلد",
                "label_fr": "Sippersfeld"
            },
            {
                "value": 37867,
                "label": "Morschheim",
                "label_ar": "مورشهايم",
                "label_fr": "Morschheim"
            },
            {
                "value": 37868,
                "label": "Fohren-Linden",
                "label_ar": "فوهرين ليندن",
                "label_fr": "Fohren-Linden"
            },
            {
                "value": 37869,
                "label": "Altenglan",
                "label_ar": "ألتنجلان",
                "label_fr": "Altenglan"
            },
            {
                "value": 37870,
                "label": "Perl",
                "label_ar": "بيرل",
                "label_fr": "Perl"
            },
            {
                "value": 37871,
                "label": "Kirchheimbolanden",
                "label_ar": "كيرشيمبولاندين",
                "label_fr": "Kirchheimbolanden"
            },
            {
                "value": 37872,
                "label": "Schellweiler",
                "label_ar": "شيلويلر",
                "label_fr": "Schellweiler"
            },
            {
                "value": 37873,
                "label": "Losheim",
                "label_ar": "لوسهايم",
                "label_fr": "Losheim"
            },
            {
                "value": 37874,
                "label": "Saalstadt",
                "label_ar": "Saalstadt",
                "label_fr": "Saalstadt"
            },
            {
                "value": 37875,
                "label": "Hoppstadten-Weiersbach",
                "label_ar": "Hoppstadten-Weiersbach",
                "label_fr": "Hoppstadten-Weiersbach"
            },
            {
                "value": 37876,
                "label": "Hoheinod",
                "label_ar": "هوهينود",
                "label_fr": "Hoheinod"
            },
            {
                "value": 37877,
                "label": "Schiffweiler",
                "label_ar": "شيفويلر",
                "label_fr": "Schiffweiler"
            },
            {
                "value": 37878,
                "label": "Hefersweiler",
                "label_ar": "هيفرسويلر",
                "label_fr": "Hefersweiler"
            },
            {
                "value": 37879,
                "label": "Battenberg",
                "label_ar": "باتنبرغ",
                "label_fr": "Battenberg"
            },
            {
                "value": 37880,
                "label": "Lotzbeuren",
                "label_ar": "Lotzbeuren",
                "label_fr": "Lotzbeuren"
            },
            {
                "value": 37881,
                "label": "Pfeffelbach",
                "label_ar": "بفيفيلباخ",
                "label_fr": "Pfeffelbach"
            },
            {
                "value": 37882,
                "label": "Gollheim",
                "label_ar": "جولهايم",
                "label_fr": "Gollheim"
            },
            {
                "value": 37883,
                "label": "Hohenollen",
                "label_ar": "هوهنولين",
                "label_fr": "Hohenollen"
            },
            {
                "value": 37884,
                "label": "Nonnweiler",
                "label_ar": "نونويلر",
                "label_fr": "Nonnweiler"
            },
            {
                "value": 37885,
                "label": "Herschweiler-Pettersheim",
                "label_ar": "هيرشويلر بيترشيم",
                "label_fr": "Herschweiler-Pettersheim"
            },
            {
                "value": 37886,
                "label": "Weingarten",
                "label_ar": "وينجارتن",
                "label_fr": "Weingarten"
            },
            {
                "value": 37887,
                "label": "Etschberg",
                "label_ar": "إتشبرغ",
                "label_fr": "Etschberg"
            },
            {
                "value": 37888,
                "label": "Ayl",
                "label_ar": "أيل",
                "label_fr": "Ayl"
            },
            {
                "value": 37889,
                "label": "Wattenheim",
                "label_ar": "فاتنهايم",
                "label_fr": "Wattenheim"
            },
            {
                "value": 37890,
                "label": "Klingenmunster",
                "label_ar": "كلينجنمونستر",
                "label_fr": "Klingenmunster"
            },
            {
                "value": 37891,
                "label": "Ulmet",
                "label_ar": "أولمت",
                "label_fr": "Ulmet"
            },
            {
                "value": 37892,
                "label": "Kirchheim an der Weinstrasse",
                "label_ar": "Kirchheim an der Weinstrasse",
                "label_fr": "Kirchheim an der Weinstrasse"
            },
            {
                "value": 37893,
                "label": "Dillingen",
                "label_ar": "ديلينجن",
                "label_fr": "Dillingen"
            },
            {
                "value": 37894,
                "label": "Weitersweiler",
                "label_ar": "Weitersweiler",
                "label_fr": "Weitersweiler"
            },
            {
                "value": 37895,
                "label": "Bolanden",
                "label_ar": "بولاندين",
                "label_fr": "Bolanden"
            },
            {
                "value": 37896,
                "label": "Bubenheim",
                "label_ar": "بوبنهايم",
                "label_fr": "Bubenheim"
            },
            {
                "value": 37897,
                "label": "Molsheim",
                "label_ar": "مولشيم",
                "label_fr": "Molsheim"
            },
            {
                "value": 37898,
                "label": "Esselborn",
                "label_ar": "ايسيلبورن",
                "label_fr": "Esselborn"
            },
            {
                "value": 37899,
                "label": "Brucken",
                "label_ar": "بروكن",
                "label_fr": "Brucken"
            },
            {
                "value": 37900,
                "label": "Bernstadt",
                "label_ar": "برنشتات",
                "label_fr": "Bernstadt"
            },
            {
                "value": 37901,
                "label": "Dinkelsbühl",
                "label_ar": "دينكلسبول",
                "label_fr": "Dinkelsbühl"
            },
            {
                "value": 37902,
                "label": "Zipplingen",
                "label_ar": "زيبلينجن",
                "label_fr": "Zipplingen"
            },
            {
                "value": 37903,
                "label": "Neresheim",
                "label_ar": "نيريشيم",
                "label_fr": "Neresheim"
            },
            {
                "value": 37904,
                "label": "Rammingen",
                "label_ar": "رامينجين",
                "label_fr": "Rammingen"
            },
            {
                "value": 37905,
                "label": "Donaueschingen",
                "label_ar": "دوناوشينغن",
                "label_fr": "Donaueschingen"
            },
            {
                "value": 37906,
                "label": "Villingendorf",
                "label_ar": "فيلينجندورف",
                "label_fr": "Villingendorf"
            },
            {
                "value": 37907,
                "label": "Bad Griesbach",
                "label_ar": "باد جريسباخ",
                "label_fr": "Bad Griesbach"
            },
            {
                "value": 37908,
                "label": "Lahr",
                "label_ar": "لار",
                "label_fr": "Lahr"
            },
            {
                "value": 37909,
                "label": "Achern",
                "label_ar": "أخيرن",
                "label_fr": "Achern"
            },
            {
                "value": 37910,
                "label": "Hambrucken",
                "label_ar": "هامبروكن",
                "label_fr": "Hambrucken"
            },
            {
                "value": 37911,
                "label": "Eggenstein-Leopoldshafen",
                "label_ar": "اغنشتاين ليوبولدشافين",
                "label_fr": "Eggenstein-Leopoldshafen"
            },
            {
                "value": 37912,
                "label": "Dielheim",
                "label_ar": "ديلهيم",
                "label_fr": "Dielheim"
            },
            {
                "value": 37913,
                "label": "Ilvesheim",
                "label_ar": "إلفيشيم",
                "label_fr": "Ilvesheim"
            },
            {
                "value": 37914,
                "label": "Guglingen",
                "label_ar": "جوجلينجن",
                "label_fr": "Guglingen"
            },
            {
                "value": 37915,
                "label": "Abstatt",
                "label_ar": "أبستات",
                "label_fr": "Abstatt"
            },
            {
                "value": 37916,
                "label": "Grenzach-Wyhlen",
                "label_ar": "جرينزاش ويلن",
                "label_fr": "Grenzach-Wyhlen"
            },
            {
                "value": 37917,
                "label": "Auggen",
                "label_ar": "اوجين",
                "label_fr": "Auggen"
            },
            {
                "value": 37918,
                "label": "Wehr",
                "label_ar": "وير",
                "label_fr": "Wehr"
            },
            {
                "value": 37919,
                "label": "Deizisau",
                "label_ar": "ديزيساو",
                "label_fr": "Deizisau"
            },
            {
                "value": 37920,
                "label": "Steinenbronn",
                "label_ar": "شتاينبرون",
                "label_fr": "Steinenbronn"
            },
            {
                "value": 37921,
                "label": "Aldingen",
                "label_ar": "ألدنغن",
                "label_fr": "Aldingen"
            },
            {
                "value": 37922,
                "label": "Weissach",
                "label_ar": "Weissach",
                "label_fr": "Weissach"
            },
            {
                "value": 37923,
                "label": "Bisingen",
                "label_ar": "بيسينجين",
                "label_fr": "Bisingen"
            },
            {
                "value": 37924,
                "label": "Vellberg",
                "label_ar": "فيلبرغ",
                "label_fr": "Vellberg"
            },
            {
                "value": 37925,
                "label": "Schechingen",
                "label_ar": "شيشينغن",
                "label_fr": "Schechingen"
            },
            {
                "value": 37926,
                "label": "Eschach",
                "label_ar": "Eschach",
                "label_fr": "Eschach"
            },
            {
                "value": 37927,
                "label": "Gartringen",
                "label_ar": "جارترينجن",
                "label_fr": "Gartringen"
            },
            {
                "value": 37928,
                "label": "Freudental",
                "label_ar": "فرويدتال",
                "label_fr": "Freudental"
            },
            {
                "value": 37929,
                "label": "Breisach",
                "label_ar": "بريساتش",
                "label_fr": "Breisach"
            },
            {
                "value": 37930,
                "label": "Freiberg am Neckar",
                "label_ar": "فرايبرغ آم نيكار",
                "label_fr": "Freiberg am Neckar"
            },
            {
                "value": 37931,
                "label": "Murrhardt",
                "label_ar": "مورهاردت",
                "label_fr": "Murrhardt"
            },
            {
                "value": 37932,
                "label": "Weinsberg",
                "label_ar": "واينسبرغ",
                "label_fr": "Weinsberg"
            },
            {
                "value": 37933,
                "label": "Ehingen",
                "label_ar": "اينجين",
                "label_fr": "Ehingen"
            },
            {
                "value": 37934,
                "label": "Weil im Schonbuch",
                "label_ar": "وايل ايم شونبوخ",
                "label_fr": "Weil im Schonbuch"
            },
            {
                "value": 37935,
                "label": "Offenau",
                "label_ar": "أوفيناو",
                "label_fr": "Offenau"
            },
            {
                "value": 37936,
                "label": "Ortenberg",
                "label_ar": "أورتنبرغ",
                "label_fr": "Ortenberg"
            },
            {
                "value": 37937,
                "label": "Hohberg",
                "label_ar": "Hohberg",
                "label_fr": "Hohberg"
            },
            {
                "value": 37938,
                "label": "Gemmrigheim",
                "label_ar": "جيمريغيم",
                "label_fr": "Gemmrigheim"
            },
            {
                "value": 37939,
                "label": "Baiersbronn",
                "label_ar": "بايرسبرون",
                "label_fr": "Baiersbronn"
            },
            {
                "value": 37940,
                "label": "Jettingen-Scheppach",
                "label_ar": "Jettingen-Scheppach",
                "label_fr": "Jettingen-Scheppach"
            },
            {
                "value": 37941,
                "label": "Bosingen",
                "label_ar": "بوسينغين",
                "label_fr": "Bosingen"
            },
            {
                "value": 37942,
                "label": "Bad Urach",
                "label_ar": "سيء أوراش",
                "label_fr": "Bad Urach"
            },
            {
                "value": 37943,
                "label": "Dogern",
                "label_ar": "دوجرن",
                "label_fr": "Dogern"
            },
            {
                "value": 37944,
                "label": "Hilzingen",
                "label_ar": "هيلزنجين",
                "label_fr": "Hilzingen"
            },
            {
                "value": 37945,
                "label": "Oberkirch",
                "label_ar": "أوبيركيرش",
                "label_fr": "Oberkirch"
            },
            {
                "value": 37946,
                "label": "Schutterwald",
                "label_ar": "شاتروالد",
                "label_fr": "Schutterwald"
            },
            {
                "value": 37947,
                "label": "Thalheim",
                "label_ar": "ثالهايم",
                "label_fr": "Thalheim"
            },
            {
                "value": 37948,
                "label": "Oberndorf",
                "label_ar": "اوبرندورف",
                "label_fr": "Oberndorf"
            },
            {
                "value": 37949,
                "label": "Langenargen",
                "label_ar": "لانجينارجين",
                "label_fr": "Langenargen"
            },
            {
                "value": 37950,
                "label": "Weilheim",
                "label_ar": "ويلهايم",
                "label_fr": "Weilheim"
            },
            {
                "value": 37951,
                "label": "Hirschberg",
                "label_ar": "هيرشبيرج",
                "label_fr": "Hirschberg"
            },
            {
                "value": 37952,
                "label": "Ebersbach an der Fils",
                "label_ar": "Ebersbach an der Fils",
                "label_fr": "Ebersbach an der Fils"
            },
            {
                "value": 37953,
                "label": "Beuren",
                "label_ar": "بورين",
                "label_fr": "Beuren"
            },
            {
                "value": 37954,
                "label": "Deggingen",
                "label_ar": "ديجينجين",
                "label_fr": "Deggingen"
            },
            {
                "value": 37955,
                "label": "Notzingen",
                "label_ar": "نوتشينغين",
                "label_fr": "Notzingen"
            },
            {
                "value": 37956,
                "label": "Neuffen",
                "label_ar": "نيوفن",
                "label_fr": "Neuffen"
            },
            {
                "value": 37957,
                "label": "Karlsdorf-Neuthard",
                "label_ar": "كارلسدورف نوثارد",
                "label_fr": "Karlsdorf-Neuthard"
            },
            {
                "value": 37958,
                "label": "Otigheim",
                "label_ar": "Otigheim",
                "label_fr": "Otigheim"
            },
            {
                "value": 37959,
                "label": "Blaubeuren Abbey",
                "label_ar": "دير بلوبيورين",
                "label_fr": "Abbaye de Blaubeuren"
            },
            {
                "value": 37960,
                "label": "Schnurpflingen",
                "label_ar": "شنوربفلينجن",
                "label_fr": "Schnurpflingen"
            },
            {
                "value": 37961,
                "label": "Stockach",
                "label_ar": "ستوكاش",
                "label_fr": "Stockach"
            },
            {
                "value": 37962,
                "label": "Amstetten",
                "label_ar": "أمستيتن",
                "label_fr": "Amstetten"
            },
            {
                "value": 37963,
                "label": "Riedlingen",
                "label_ar": "ريدلينجن",
                "label_fr": "Riedlingen"
            },
            {
                "value": 37964,
                "label": "Teublitz",
                "label_ar": "تيوبليتز",
                "label_fr": "Teublitz"
            },
            {
                "value": 37965,
                "label": "Straubing",
                "label_ar": "شتراوبينج",
                "label_fr": "Straubing"
            },
            {
                "value": 37966,
                "label": "Weilmunster",
                "label_ar": "ويلمونستر",
                "label_fr": "Weilmunster"
            },
            {
                "value": 37967,
                "label": "Wehrheim",
                "label_ar": "ويرهايم",
                "label_fr": "Wehrheim"
            },
            {
                "value": 37968,
                "label": "Hungen",
                "label_ar": "جائع",
                "label_fr": "Hungen"
            },
            {
                "value": 37969,
                "label": "Floha",
                "label_ar": "فلوها",
                "label_fr": "Floha"
            },
            {
                "value": 37970,
                "label": "Klaus",
                "label_ar": "كلاوس",
                "label_fr": "Klaus"
            },
            {
                "value": 37971,
                "label": "Holzgerlingen",
                "label_ar": "هولزغيرلينجن",
                "label_fr": "Holzgerlingen"
            },
            {
                "value": 37972,
                "label": "Wurmberg",
                "label_ar": "وورمبرغ",
                "label_fr": "Wurmberg"
            },
            {
                "value": 37973,
                "label": "Treffelhausen",
                "label_ar": "تريفيلهاوزن",
                "label_fr": "Treffelhausen"
            },
            {
                "value": 37974,
                "label": "Dettingen an der Erms",
                "label_ar": "Dettingen an der Erms",
                "label_fr": "Dettingen an der Erms"
            },
            {
                "value": 37975,
                "label": "Müllheim",
                "label_ar": "مولهايم",
                "label_fr": "Müllheim"
            },
            {
                "value": 37976,
                "label": "Uhingen",
                "label_ar": "اوهينغين",
                "label_fr": "Uhingen"
            },
            {
                "value": 37977,
                "label": "Ohmden",
                "label_ar": "أومدن",
                "label_fr": "Ohmden"
            },
            {
                "value": 37978,
                "label": "Riedhausen",
                "label_ar": "ريدهاوزن",
                "label_fr": "Riedhausen"
            },
            {
                "value": 37979,
                "label": "Denkendorf",
                "label_ar": "دنكندورف",
                "label_fr": "Denkendorf"
            },
            {
                "value": 37980,
                "label": "Kreuzwertheim",
                "label_ar": "كروزفيرثيم",
                "label_fr": "Kreuzwertheim"
            },
            {
                "value": 37981,
                "label": "Mossingen",
                "label_ar": "موسينغن",
                "label_fr": "Mossingen"
            },
            {
                "value": 37982,
                "label": "Sindringen",
                "label_ar": "سيندرينجين",
                "label_fr": "Sindringen"
            },
            {
                "value": 37983,
                "label": "Bad Konig",
                "label_ar": "كونيج السيئ",
                "label_fr": "Bad Konig"
            },
            {
                "value": 37984,
                "label": "Heppenheim an der Bergstrasse",
                "label_ar": "Heppenheim an der Bergstrasse",
                "label_fr": "Heppenheim an der Bergstrasse"
            },
            {
                "value": 37985,
                "label": "Michelstadt",
                "label_ar": "ميشيلشتات",
                "label_fr": "Michelstadt"
            },
            {
                "value": 37986,
                "label": "Hochst im Odenwald",
                "label_ar": "Hochst im Odenwald",
                "label_fr": "Hochst im Odenwald"
            },
            {
                "value": 37987,
                "label": "Finkenbach",
                "label_ar": "فينكينباخ",
                "label_fr": "Finkenbach"
            },
            {
                "value": 37988,
                "label": "Beerfelden",
                "label_ar": "بيرفيلدن",
                "label_fr": "Beerfelden"
            },
            {
                "value": 37989,
                "label": "Langerringen",
                "label_ar": "لانجيرينجين",
                "label_fr": "Langerringen"
            },
            {
                "value": 37990,
                "label": "Unterhaching",
                "label_ar": "غير متقلب",
                "label_fr": "Unterhaching"
            },
            {
                "value": 37991,
                "label": "Holzheim",
                "label_ar": "هولزهايم",
                "label_fr": "Holzheim"
            },
            {
                "value": 37992,
                "label": "Denklingen",
                "label_ar": "دنكلنغن",
                "label_fr": "Denklingen"
            },
            {
                "value": 37993,
                "label": "Landsberg am Lech",
                "label_ar": "لاندسبيرج آم ليش",
                "label_fr": "Landsberg am Lech"
            },
            {
                "value": 37994,
                "label": "Dillingen an der Donau",
                "label_ar": "Dillingen an der Donau",
                "label_fr": "Dillingen an der Donau"
            },
            {
                "value": 37995,
                "label": "Meitingen",
                "label_ar": "Meitingen",
                "label_fr": "Meitingen"
            },
            {
                "value": 37996,
                "label": "Langweid",
                "label_ar": "لانجويد",
                "label_fr": "Langweid"
            },
            {
                "value": 37997,
                "label": "Schwifting",
                "label_ar": "شويفت",
                "label_fr": "Schwifting"
            },
            {
                "value": 37998,
                "label": "Rednitzhembach",
                "label_ar": "ريدنيتزيمباخ",
                "label_fr": "Rednitzhembach"
            },
            {
                "value": 37999,
                "label": "Schrobenhausen",
                "label_ar": "شروبنهاوزن",
                "label_fr": "Schrobenhausen"
            },
            {
                "value": 38000,
                "label": "Schwaig",
                "label_ar": "شفايج",
                "label_fr": "Schwaig"
            },
            {
                "value": 38001,
                "label": "Seukendorf",
                "label_ar": "سيوكيندورف",
                "label_fr": "Seukendorf"
            },
            {
                "value": 38002,
                "label": "Hamminkeln",
                "label_ar": "هامينكلن",
                "label_fr": "Hamminkeln"
            },
            {
                "value": 38003,
                "label": "Ahaus",
                "label_ar": "آهاوس",
                "label_fr": "Ahaus"
            },
            {
                "value": 38004,
                "label": "Heiden",
                "label_ar": "هايدن",
                "label_fr": "Heiden"
            },
            {
                "value": 38005,
                "label": "Bad Berka",
                "label_ar": "باد بركا",
                "label_fr": "Bad Berka"
            },
            {
                "value": 38006,
                "label": "Ramerberg",
                "label_ar": "رامربيرج",
                "label_fr": "Ramerberg"
            },
            {
                "value": 38007,
                "label": "Seeon-Seebruck",
                "label_ar": "سيون سيبروك",
                "label_fr": "Seeon-Seebruck"
            },
            {
                "value": 38008,
                "label": "Pfaffing",
                "label_ar": "بفافينج",
                "label_fr": "Pfaffing"
            },
            {
                "value": 38009,
                "label": "Prien am Chiemsee",
                "label_ar": "Prien am Chiemsee",
                "label_fr": "Prien am Chiemsee"
            },
            {
                "value": 38010,
                "label": "Artlenburg",
                "label_ar": "ارتلينبرج",
                "label_fr": "Artlenburg"
            },
            {
                "value": 38011,
                "label": "Amelinghausen",
                "label_ar": "Amelinghausen",
                "label_fr": "Amelinghausen"
            },
            {
                "value": 38012,
                "label": "Oldendorf",
                "label_ar": "أولدندورف",
                "label_fr": "Oldendorf"
            },
            {
                "value": 38013,
                "label": "Prebberede",
                "label_ar": "Prebberede",
                "label_fr": "Prebberede"
            },
            {
                "value": 38014,
                "label": "Michendorf",
                "label_ar": "ميشيندورف",
                "label_fr": "Michendorf"
            },
            {
                "value": 38015,
                "label": "Badow",
                "label_ar": "Badow",
                "label_fr": "Badow"
            },
            {
                "value": 38016,
                "label": "Linow",
                "label_ar": "لينو",
                "label_fr": "Linow"
            },
            {
                "value": 38017,
                "label": "Gorschendorf",
                "label_ar": "جورشندورف",
                "label_fr": "Gorschendorf"
            },
            {
                "value": 38018,
                "label": "Tieplitz",
                "label_ar": "تيبليتز",
                "label_fr": "Tieplitz"
            },
            {
                "value": 38019,
                "label": "Fincken",
                "label_ar": "فينكين",
                "label_fr": "Fincken"
            },
            {
                "value": 38020,
                "label": "Stolzenhagen",
                "label_ar": "Stolzenhagen",
                "label_fr": "Stolzenhagen"
            },
            {
                "value": 38021,
                "label": "Protzen",
                "label_ar": "بروتزن",
                "label_fr": "Protzen"
            },
            {
                "value": 38022,
                "label": "Siedenbrunzow",
                "label_ar": "سيدنبرونزو",
                "label_fr": "Siedenbrunzow"
            },
            {
                "value": 38023,
                "label": "Warin",
                "label_ar": "وارين",
                "label_fr": "Warin"
            },
            {
                "value": 38024,
                "label": "Neutrebbin",
                "label_ar": "نيوتريبين",
                "label_fr": "Neutrebbin"
            },
            {
                "value": 38025,
                "label": "Klotze",
                "label_ar": "كلوتسي",
                "label_fr": "Klotze"
            },
            {
                "value": 38026,
                "label": "Ahrensfelde",
                "label_ar": "Ahrensfelde",
                "label_fr": "Ahrensfelde"
            },
            {
                "value": 38027,
                "label": "Beelitz",
                "label_ar": "بيليتز",
                "label_fr": "Beelitz"
            },
            {
                "value": 38028,
                "label": "Guestrow",
                "label_ar": "Guestrow",
                "label_fr": "Guestrow"
            },
            {
                "value": 38029,
                "label": "Warrenzin",
                "label_ar": "وارينزين",
                "label_fr": "Warrenzin"
            },
            {
                "value": 38030,
                "label": "Rovershagen",
                "label_ar": "روفشاجين",
                "label_fr": "Rovershagen"
            },
            {
                "value": 38031,
                "label": "Ankershagen",
                "label_ar": "أنكرشاجين",
                "label_fr": "Ankershagen"
            },
            {
                "value": 38032,
                "label": "Burg bei Magdeburg",
                "label_ar": "بورغ باي ماغديبورغ",
                "label_fr": "Burg bei Magdebourg"
            },
            {
                "value": 38033,
                "label": "Schwaneberg",
                "label_ar": "شوانيبيرج",
                "label_fr": "Schwaneberg"
            },
            {
                "value": 38034,
                "label": "Zerbst",
                "label_ar": "زيربست",
                "label_fr": "Zerbst"
            },
            {
                "value": 38035,
                "label": "Steinhagen",
                "label_ar": "شتاينهاجين",
                "label_fr": "Steinhagen"
            },
            {
                "value": 38036,
                "label": "Kirchlengern",
                "label_ar": "كيرشلنغرن",
                "label_fr": "Kirchlengern"
            },
            {
                "value": 38037,
                "label": "Vohl",
                "label_ar": "فول",
                "label_fr": "Vohl"
            },
            {
                "value": 38038,
                "label": "Lugde",
                "label_ar": "لوجدي",
                "label_fr": "Lugde"
            },
            {
                "value": 38039,
                "label": "Rollshausen",
                "label_ar": "رولشوزين",
                "label_fr": "Rollshausen"
            },
            {
                "value": 38040,
                "label": "Lengerich",
                "label_ar": "Lengerich",
                "label_fr": "Lengerich"
            },
            {
                "value": 38041,
                "label": "Niederaula",
                "label_ar": "نيديراولا",
                "label_fr": "Niederaula"
            },
            {
                "value": 38042,
                "label": "Immenhausen",
                "label_ar": "إيمينهاوزن",
                "label_fr": "Immenhausen"
            },
            {
                "value": 38043,
                "label": "Blomberg",
                "label_ar": "بلومبيرج",
                "label_fr": "Blomberg"
            },
            {
                "value": 38044,
                "label": "Grebenstein",
                "label_ar": "جريبنشتاين",
                "label_fr": "Grebenstein"
            },
            {
                "value": 38045,
                "label": "Emstal",
                "label_ar": "إمستال",
                "label_fr": "Emstal"
            },
            {
                "value": 38046,
                "label": "Rotenburg an der Fulda",
                "label_ar": "روتنبورغ أن دير فولدا",
                "label_fr": "Rotenburg an der Fulda"
            },
            {
                "value": 38047,
                "label": "Meinerzhagen",
                "label_ar": "مينرزاجين",
                "label_fr": "Meinerzhagen"
            },
            {
                "value": 38048,
                "label": "Attendorn",
                "label_ar": "حاضر",
                "label_fr": "Assister"
            },
            {
                "value": 38049,
                "label": "Dorentrup",
                "label_ar": "دورينتروب",
                "label_fr": "Dorentrup"
            },
            {
                "value": 38050,
                "label": "Neuenstein",
                "label_ar": "نوينشتاين",
                "label_fr": "Neuenstein"
            },
            {
                "value": 38051,
                "label": "Eslohe",
                "label_ar": "اسلوه",
                "label_fr": "Eslohe"
            },
            {
                "value": 38052,
                "label": "Warburg",
                "label_ar": "واربورغ",
                "label_fr": "Warburg"
            },
            {
                "value": 38053,
                "label": "Horn-Bad Meinberg",
                "label_ar": "هورن باد مينبرج",
                "label_fr": "Horn-Bad Meinberg"
            },
            {
                "value": 38054,
                "label": "Medebach",
                "label_ar": "ميديباخ",
                "label_fr": "Medebach"
            },
            {
                "value": 38055,
                "label": "Dulmen",
                "label_ar": "دولمن",
                "label_fr": "Dulmen"
            },
            {
                "value": 38056,
                "label": "Oelde",
                "label_ar": "Oelde",
                "label_fr": "Oelde"
            },
            {
                "value": 38057,
                "label": "Halle",
                "label_ar": "هالي",
                "label_fr": "Halle"
            },
            {
                "value": 38058,
                "label": "Werdohl",
                "label_ar": "ويردهل",
                "label_fr": "Werdohl"
            },
            {
                "value": 38059,
                "label": "Felsberg",
                "label_ar": "فيلسبرغ",
                "label_fr": "Felsberg"
            },
            {
                "value": 38060,
                "label": "Siegen",
                "label_ar": "سيغن",
                "label_fr": "Siegen"
            },
            {
                "value": 38061,
                "label": "Alheim",
                "label_ar": "ألهايم",
                "label_fr": "Alheim"
            },
            {
                "value": 38062,
                "label": "Beverungen",
                "label_ar": "بيفيرونجين",
                "label_fr": "Beverungen"
            },
            {
                "value": 38063,
                "label": "Petershagen",
                "label_ar": "بيترشاجين",
                "label_fr": "Petershagen"
            },
            {
                "value": 38064,
                "label": "Kreuztal",
                "label_ar": "كروزتال",
                "label_fr": "Kreuztal"
            },
            {
                "value": 38065,
                "label": "Bad Hersfeld",
                "label_ar": "باد هيرسفيلد",
                "label_fr": "Bad Hersfeld"
            },
            {
                "value": 38066,
                "label": "Glandorf",
                "label_ar": "جلاندورف",
                "label_fr": "Glandorf"
            },
            {
                "value": 38067,
                "label": "Bramsche",
                "label_ar": "برامش",
                "label_fr": "Bramsche"
            },
            {
                "value": 38068,
                "label": "Baunatal",
                "label_ar": "بوناتال",
                "label_fr": "Baunatal"
            },
            {
                "value": 38069,
                "label": "Stelle",
                "label_ar": "ستيل",
                "label_fr": "Stelle"
            },
            {
                "value": 38070,
                "label": "Brokdorf",
                "label_ar": "بروكدورف",
                "label_fr": "Brokdorf"
            },
            {
                "value": 38071,
                "label": "Sulfeld",
                "label_ar": "سولفيلد",
                "label_fr": "Sulfeld"
            },
            {
                "value": 38072,
                "label": "Bomlitz",
                "label_ar": "بومليتس",
                "label_fr": "Bomlitz"
            },
            {
                "value": 38073,
                "label": "Osterrade",
                "label_ar": "أوستيرادي",
                "label_fr": "Osterrade"
            },
            {
                "value": 38074,
                "label": "Bad Oldesloe",
                "label_ar": "Oldesloe سيئة",
                "label_fr": "Bad Oldesloe"
            },
            {
                "value": 38075,
                "label": "Bosau",
                "label_ar": "بوساو",
                "label_fr": "Bosau"
            },
            {
                "value": 38076,
                "label": "Sommerland",
                "label_ar": "سومرلاند",
                "label_fr": "Sommerland"
            },
            {
                "value": 38077,
                "label": "Triptis",
                "label_ar": "تريبتيس",
                "label_fr": "Triptis"
            },
            {
                "value": 38078,
                "label": "Wundersleben",
                "label_ar": "Wundersleben",
                "label_fr": "Wundersleben"
            },
            {
                "value": 38079,
                "label": "Limbach",
                "label_ar": "ليمباخ",
                "label_fr": "Limbach"
            },
            {
                "value": 38080,
                "label": "Mannichswalde",
                "label_ar": "مانيشوالد",
                "label_fr": "Mannichswalde"
            },
            {
                "value": 38081,
                "label": "Fuchshain",
                "label_ar": "فوششين",
                "label_fr": "Fuchshain"
            },
            {
                "value": 38082,
                "label": "Wetterzeube",
                "label_ar": "ويتيرزيوب",
                "label_fr": "Wetterzeube"
            },
            {
                "value": 38083,
                "label": "Osterhausen",
                "label_ar": "أوسترهاوزن",
                "label_fr": "Osterhausen"
            },
            {
                "value": 38084,
                "label": "Mechelroda",
                "label_ar": "ميتشلرودا",
                "label_fr": "Mechelroda"
            },
            {
                "value": 38085,
                "label": "Bornitz",
                "label_ar": "بورنيتز",
                "label_fr": "Bornitz"
            },
            {
                "value": 38086,
                "label": "Schkolen",
                "label_ar": "شكولين",
                "label_fr": "Schkolen"
            },
            {
                "value": 38087,
                "label": "Friedrichroda",
                "label_ar": "فريدريشرودا",
                "label_fr": "Friedrichroda"
            },
            {
                "value": 38088,
                "label": "Meuselwitz",
                "label_ar": "Meuselwitz",
                "label_fr": "Meuselwitz"
            },
            {
                "value": 38089,
                "label": "Saara",
                "label_ar": "سارا",
                "label_fr": "Saara"
            },
            {
                "value": 38090,
                "label": "Sangerhausen",
                "label_ar": "سانجرهاوزن",
                "label_fr": "Sangerhausen"
            },
            {
                "value": 38091,
                "label": "Plaue",
                "label_ar": "Plaue",
                "label_fr": "Plaue"
            },
            {
                "value": 38092,
                "label": "Ilshofen",
                "label_ar": "إيلشوفين",
                "label_fr": "Ilshofen"
            },
            {
                "value": 38093,
                "label": "Konigsfeld im Schwarzwald",
                "label_ar": "Konigsfeld im Schwarzwald",
                "label_fr": "Konigsfeld im Schwarzwald"
            },
            {
                "value": 38094,
                "label": "Bopfingen",
                "label_ar": "بوبفينغن",
                "label_fr": "Bopfingen"
            },
            {
                "value": 38095,
                "label": "Otisheim",
                "label_ar": "أوتيسهايم",
                "label_fr": "Otisheim"
            },
            {
                "value": 38096,
                "label": "Grevenbroich",
                "label_ar": "جريفينبرويتش",
                "label_fr": "Grevenbroich"
            },
            {
                "value": 38097,
                "label": "Radefeld",
                "label_ar": "رادفيلد",
                "label_fr": "Radefeld"
            },
            {
                "value": 38098,
                "label": "Domsdorf",
                "label_ar": "دومسدورف",
                "label_fr": "Domsdorf"
            },
            {
                "value": 38099,
                "label": "Weischlitz",
                "label_ar": "Weischlitz",
                "label_fr": "Weischlitz"
            },
            {
                "value": 38100,
                "label": "Luebben",
                "label_ar": "لويبين",
                "label_fr": "Luebben"
            },
            {
                "value": 38101,
                "label": "Berka",
                "label_ar": "بركا",
                "label_fr": "Berka"
            },
            {
                "value": 38102,
                "label": "Hohenmolsen",
                "label_ar": "هوهنمولسن",
                "label_fr": "Hohenmolsen"
            },
            {
                "value": 38103,
                "label": "Kodersdorf",
                "label_ar": "كودرسدورف",
                "label_fr": "Kodersdorf"
            },
            {
                "value": 38104,
                "label": "Zeithain",
                "label_ar": "زيتين",
                "label_fr": "Zeithain"
            },
            {
                "value": 38105,
                "label": "Holzhausen",
                "label_ar": "هولزهاوزن",
                "label_fr": "Holzhausen"
            },
            {
                "value": 38106,
                "label": "Hohen",
                "label_ar": "هوهين",
                "label_fr": "Hohen"
            },
            {
                "value": 38107,
                "label": "Nohra",
                "label_ar": "نهرا",
                "label_fr": "Nohra"
            },
            {
                "value": 38108,
                "label": "Hundhaupten",
                "label_ar": "هوندهوبتن",
                "label_fr": "Hundhaupten"
            },
            {
                "value": 38109,
                "label": "Deiningen",
                "label_ar": "دينينجين",
                "label_fr": "Deiningen"
            },
            {
                "value": 38110,
                "label": "Siegenburg",
                "label_ar": "سيجنبرج",
                "label_fr": "Siegenburg"
            },
            {
                "value": 38111,
                "label": "Mettenheim",
                "label_ar": "ميتنهايم",
                "label_fr": "Mettenheim"
            },
            {
                "value": 38112,
                "label": "Mainburg",
                "label_ar": "ماينبورغ",
                "label_fr": "Mainburg"
            },
            {
                "value": 38113,
                "label": "Parsdorf",
                "label_ar": "بارسدورف",
                "label_fr": "Parsdorf"
            },
            {
                "value": 38114,
                "label": "Antdorf",
                "label_ar": "أنتدورف",
                "label_fr": "Antdorf"
            },
            {
                "value": 38115,
                "label": "Puchheim",
                "label_ar": "بوشيم",
                "label_fr": "Puchheim"
            },
            {
                "value": 38116,
                "label": "Wolfgang",
                "label_ar": "وولفجانج",
                "label_fr": "Wolfgang"
            },
            {
                "value": 38117,
                "label": "Taufkirchen",
                "label_ar": "Taufkirchen",
                "label_fr": "Taufkirchen"
            },
            {
                "value": 38118,
                "label": "Petersdorf",
                "label_ar": "بيترسدورف",
                "label_fr": "Petersdorf"
            },
            {
                "value": 38119,
                "label": "Neufarn",
                "label_ar": "نيوفارن",
                "label_fr": "Neufarn"
            },
            {
                "value": 38120,
                "label": "Pottmes",
                "label_ar": "بوتمس",
                "label_fr": "Pottmes"
            },
            {
                "value": 38121,
                "label": "Weichering",
                "label_ar": "Weichering",
                "label_fr": "Weichering"
            },
            {
                "value": 38122,
                "label": "Saal",
                "label_ar": "سال",
                "label_fr": "Saal"
            },
            {
                "value": 38123,
                "label": "Steinhoring",
                "label_ar": "شتاينهورنج",
                "label_fr": "Steinhoring"
            },
            {
                "value": 38124,
                "label": "Soyen",
                "label_ar": "صوين",
                "label_fr": "Soyen"
            },
            {
                "value": 38125,
                "label": "Hagelstadt",
                "label_ar": "هاجلشتات",
                "label_fr": "Hagelstadt"
            },
            {
                "value": 38126,
                "label": "Westendorf",
                "label_ar": "وستندورف",
                "label_fr": "Westendorf"
            },
            {
                "value": 38127,
                "label": "Gersthofen",
                "label_ar": "جيرستهوفن",
                "label_fr": "Gersthofen"
            },
            {
                "value": 38128,
                "label": "Mitwitz",
                "label_ar": "ميتويتز",
                "label_fr": "Mitwitz"
            },
            {
                "value": 38129,
                "label": "Kirchberg",
                "label_ar": "كيرشبرج",
                "label_fr": "Kirchberg"
            },
            {
                "value": 38130,
                "label": "Haselbach",
                "label_ar": "هاسيلباخ",
                "label_fr": "Haselbach"
            },
            {
                "value": 38131,
                "label": "Oberschneiding",
                "label_ar": "Oberschneiding",
                "label_fr": "Oberschneiding"
            },
            {
                "value": 38132,
                "label": "Hadamar",
                "label_ar": "هادامار",
                "label_fr": "Hadamar"
            },
            {
                "value": 38133,
                "label": "Wittlich",
                "label_ar": "ويتليش",
                "label_fr": "Wittlich"
            },
            {
                "value": 38134,
                "label": "Dietzenbach",
                "label_ar": "ديتزنباخ",
                "label_fr": "Dietzenbach"
            },
            {
                "value": 38135,
                "label": "Sinn",
                "label_ar": "الشين",
                "label_fr": "Sinn"
            },
            {
                "value": 38136,
                "label": "Plein",
                "label_ar": "بلين",
                "label_fr": "Plein"
            },
            {
                "value": 38137,
                "label": "Asslar",
                "label_ar": "Asslar",
                "label_fr": "Asslar"
            },
            {
                "value": 38138,
                "label": "Eschborn",
                "label_ar": "اشبورن",
                "label_fr": "Eschborn"
            },
            {
                "value": 38139,
                "label": "Holzhausen an der Haide",
                "label_ar": "Holzhausen an der Haide",
                "label_fr": "Holzhausen an der Haide"
            },
            {
                "value": 38140,
                "label": "Bad Soden-Salmunster",
                "label_ar": "باد سودين سالمونستر",
                "label_fr": "Bad Soden-Salmunster"
            },
            {
                "value": 38141,
                "label": "Berg",
                "label_ar": "بيرج",
                "label_fr": "Berg"
            },
            {
                "value": 38142,
                "label": "Idstein",
                "label_ar": "إدشتاين",
                "label_fr": "Idstein"
            },
            {
                "value": 38143,
                "label": "Dexheim",
                "label_ar": "ديكسهايم",
                "label_fr": "Dexheim"
            },
            {
                "value": 38144,
                "label": "Butzbach",
                "label_ar": "بوتزباخ",
                "label_fr": "Butzbach"
            },
            {
                "value": 38145,
                "label": "Koblenz",
                "label_ar": "كوبلنز",
                "label_fr": "Coblence"
            },
            {
                "value": 38146,
                "label": "Massbach",
                "label_ar": "ماساتباخ",
                "label_fr": "Massbach"
            },
            {
                "value": 38147,
                "label": "Reiskirchen",
                "label_ar": "Reiskirchen",
                "label_fr": "Reiskirchen"
            },
            {
                "value": 38148,
                "label": "Montabaur",
                "label_ar": "مونتابور",
                "label_fr": "Montabaur"
            },
            {
                "value": 38149,
                "label": "Becheln",
                "label_ar": "بيشلن",
                "label_fr": "Becheln"
            },
            {
                "value": 38150,
                "label": "Obermeilingen",
                "label_ar": "Obermeilingen",
                "label_fr": "Obermeilingen"
            },
            {
                "value": 38151,
                "label": "Diefflen",
                "label_ar": "ديفلن",
                "label_fr": "Diefflen"
            },
            {
                "value": 38152,
                "label": "Hundsangen",
                "label_ar": "Hundsangen",
                "label_fr": "Hundsangen"
            },
            {
                "value": 38153,
                "label": "Glashutten",
                "label_ar": "جلاشتن",
                "label_fr": "Glashutten"
            },
            {
                "value": 38154,
                "label": "Neuwied",
                "label_ar": "نويفيد",
                "label_fr": "Neuwied"
            },
            {
                "value": 38155,
                "label": "Udenheim",
                "label_ar": "أودينهايم",
                "label_fr": "Udenheim"
            },
            {
                "value": 38156,
                "label": "Waldbrunn",
                "label_ar": "والدبرون",
                "label_fr": "Waldbrunn"
            },
            {
                "value": 38157,
                "label": "Wolfersheim",
                "label_ar": "ولفرسهايم",
                "label_fr": "Wolfersheim"
            },
            {
                "value": 38158,
                "label": "Hunfeld",
                "label_ar": "هونفيلد",
                "label_fr": "Hunfeld"
            },
            {
                "value": 38159,
                "label": "Hunzel",
                "label_ar": "هونزل",
                "label_fr": "Hunzel"
            },
            {
                "value": 38160,
                "label": "Mandel",
                "label_ar": "ماندل",
                "label_fr": "Mandel"
            },
            {
                "value": 38161,
                "label": "Zeitlofs",
                "label_ar": "زيتلوفس",
                "label_fr": "Zeitlofs"
            },
            {
                "value": 38162,
                "label": "Mehlingen",
                "label_ar": "ميلينجن",
                "label_fr": "Mehlingen"
            },
            {
                "value": 38163,
                "label": "Grunberg",
                "label_ar": "جرونبيرج",
                "label_fr": "Grunberg"
            },
            {
                "value": 38164,
                "label": "Altenstadt",
                "label_ar": "التنشتات",
                "label_fr": "Altenstadt"
            },
            {
                "value": 38165,
                "label": "Rodenbach",
                "label_ar": "رودنباخ",
                "label_fr": "Rodenbach"
            },
            {
                "value": 38166,
                "label": "Sterzhausen",
                "label_ar": "سترزهاوزن",
                "label_fr": "Sterzhausen"
            },
            {
                "value": 38167,
                "label": "Bad Camberg",
                "label_ar": "سيئة كامبرج",
                "label_fr": "Bad Camberg"
            },
            {
                "value": 38168,
                "label": "Landsberg",
                "label_ar": "لاندسبيرج",
                "label_fr": "Landsberg"
            },
            {
                "value": 38169,
                "label": "Pockau",
                "label_ar": "بوكاو",
                "label_fr": "Pockau"
            },
            {
                "value": 38170,
                "label": "Ettersburg",
                "label_ar": "إتيرسبيرغ",
                "label_fr": "Ettersburg"
            },
            {
                "value": 38171,
                "label": "Zeitz",
                "label_ar": "زيتز",
                "label_fr": "Zeitz"
            },
            {
                "value": 38172,
                "label": "Kelbra",
                "label_ar": "كيلبرا",
                "label_fr": "Kelbra"
            },
            {
                "value": 38173,
                "label": "Riethnordhausen",
                "label_ar": "Riethnordhausen",
                "label_fr": "Riethnordhausen"
            },
            {
                "value": 38174,
                "label": "Schwerstedt",
                "label_ar": "شويرستيدت",
                "label_fr": "Schwerstedt"
            },
            {
                "value": 38175,
                "label": "Naumburg",
                "label_ar": "نومبورغ",
                "label_fr": "Naumburg"
            },
            {
                "value": 38176,
                "label": "Oranienbaum",
                "label_ar": "أورانينباوم",
                "label_fr": "Oranienbaum"
            },
            {
                "value": 38177,
                "label": "Rechenberg-Bienenmuhle",
                "label_ar": "Rechenberg-Bienenmuhle",
                "label_fr": "Rechenberg-Bienenmuhle"
            },
            {
                "value": 38178,
                "label": "Berga",
                "label_ar": "بيرجا",
                "label_fr": "Berga"
            },
            {
                "value": 38179,
                "label": "Glauchau",
                "label_ar": "جلوتشاو",
                "label_fr": "Glauchau"
            },
            {
                "value": 38180,
                "label": "Waltershausen",
                "label_ar": "والترسهاوزن",
                "label_fr": "Waltershausen"
            },
            {
                "value": 38181,
                "label": "Arensdorf",
                "label_ar": "ارنسدورف",
                "label_fr": "Arensdorf"
            },
            {
                "value": 38182,
                "label": "Waldau",
                "label_ar": "فالداو",
                "label_fr": "Waldau"
            },
            {
                "value": 38183,
                "label": "Wilhelmsdorf",
                "label_ar": "فيلهلمسدورف",
                "label_fr": "Wilhelmsdorf"
            },
            {
                "value": 38184,
                "label": "Isseroda",
                "label_ar": "ايسيرودا",
                "label_fr": "Isseroda"
            },
            {
                "value": 38185,
                "label": "Lugau",
                "label_ar": "لوغاو",
                "label_fr": "Lugau"
            },
            {
                "value": 38186,
                "label": "Eberstadt",
                "label_ar": "إبرستادت",
                "label_fr": "Eberstadt"
            },
            {
                "value": 38187,
                "label": "Callenberg",
                "label_ar": "كالينبيرج",
                "label_fr": "Callenberg"
            },
            {
                "value": 38188,
                "label": "Fambach",
                "label_ar": "فامباخ",
                "label_fr": "Fambach"
            },
            {
                "value": 38189,
                "label": "Erlau",
                "label_ar": "إرلاو",
                "label_fr": "Erlau"
            },
            {
                "value": 38190,
                "label": "Freyburg",
                "label_ar": "فرايبورغ",
                "label_fr": "Freyburg"
            },
            {
                "value": 38191,
                "label": "Tanna",
                "label_ar": "تانا",
                "label_fr": "Tanna"
            },
            {
                "value": 38192,
                "label": "Kleinschwabhausen",
                "label_ar": "كلاينشوابهاوزن",
                "label_fr": "Kleinschwabhausen"
            },
            {
                "value": 38193,
                "label": "Lederhose",
                "label_ar": "ليدرهوز",
                "label_fr": "Lederhose"
            },
            {
                "value": 38194,
                "label": "Unterwellenborn",
                "label_ar": "أونتيرويلنبورن",
                "label_fr": "Unterwellenborn"
            },
            {
                "value": 38195,
                "label": "Grossposna",
                "label_ar": "جروسبوسنا",
                "label_fr": "Grossposna"
            },
            {
                "value": 38196,
                "label": "Kirchhasel",
                "label_ar": "كيرشاسيل",
                "label_fr": "Kirchhasel"
            },
            {
                "value": 38197,
                "label": "Schleid",
                "label_ar": "شليد",
                "label_fr": "Schleid"
            },
            {
                "value": 38198,
                "label": "Neunheilingen",
                "label_ar": "نيونهيلينجن",
                "label_fr": "Neunheilingen"
            },
            {
                "value": 38199,
                "label": "Goch",
                "label_ar": "غوتش",
                "label_fr": "Goch"
            },
            {
                "value": 38200,
                "label": "Elsdorf",
                "label_ar": "إلسدورف",
                "label_fr": "Elsdorf"
            },
            {
                "value": 38201,
                "label": "Blankenheim",
                "label_ar": "بلانكنهايم",
                "label_fr": "Blankenheim"
            },
            {
                "value": 38202,
                "label": "Kevelaer",
                "label_ar": "كيفيلاير",
                "label_fr": "Kevelaer"
            },
            {
                "value": 38203,
                "label": "Dormagen",
                "label_ar": "دورماغن",
                "label_fr": "Dormagen"
            },
            {
                "value": 38204,
                "label": "Titz",
                "label_ar": "تيتز",
                "label_fr": "Titz"
            },
            {
                "value": 38205,
                "label": "Dinslaken",
                "label_ar": "دينسلاكن",
                "label_fr": "Dinslaken"
            },
            {
                "value": 38206,
                "label": "Bergheim",
                "label_ar": "بيرجهايم",
                "label_fr": "Bergheim"
            },
            {
                "value": 38207,
                "label": "Nettersheim",
                "label_ar": "نيتيرشيم",
                "label_fr": "Nettersheim"
            },
            {
                "value": 38208,
                "label": "Neunkirchen",
                "label_ar": "نيونكيرشن",
                "label_fr": "Neunkirchen"
            },
            {
                "value": 38209,
                "label": "Heimbach",
                "label_ar": "Heimbach",
                "label_fr": "Heimbach"
            },
            {
                "value": 38210,
                "label": "Zulpich",
                "label_ar": "زولبيش",
                "label_fr": "Zulpich"
            },
            {
                "value": 38211,
                "label": "Wesel",
                "label_ar": "فيزل",
                "label_fr": "Wesel"
            },
            {
                "value": 38212,
                "label": "Konigswinter",
                "label_ar": "Konigswinter",
                "label_fr": "Konigswinter"
            },
            {
                "value": 38213,
                "label": "Gummersbach",
                "label_ar": "جومرسباخ",
                "label_fr": "Gummersbach"
            },
            {
                "value": 38214,
                "label": "Kirchlinteln",
                "label_ar": "كيرشلينتلن",
                "label_fr": "Kirchlinteln"
            },
            {
                "value": 38215,
                "label": "Bienenbuttel",
                "label_ar": "Bienenbuttel",
                "label_fr": "Bienenbuttel"
            },
            {
                "value": 38216,
                "label": "Schuby",
                "label_ar": "شوبي",
                "label_fr": "Schuby"
            },
            {
                "value": 38217,
                "label": "Blender",
                "label_ar": "الخلاط",
                "label_fr": "Mixeur"
            },
            {
                "value": 38218,
                "label": "Friedrichsholm",
                "label_ar": "فريدريشولم",
                "label_fr": "Friedrichsholm"
            },
            {
                "value": 38219,
                "label": "Schwienau",
                "label_ar": "شويناو",
                "label_fr": "Schwienau"
            },
            {
                "value": 38220,
                "label": "Anderlingen",
                "label_ar": "أندرلينجن",
                "label_fr": "Anderlingen"
            },
            {
                "value": 38221,
                "label": "Gommern",
                "label_ar": "جومرن",
                "label_fr": "Gommern"
            },
            {
                "value": 38222,
                "label": "Kritzow",
                "label_ar": "كريتزوف",
                "label_fr": "Kritzow"
            },
            {
                "value": 38223,
                "label": "Mieste",
                "label_ar": "ميستي",
                "label_fr": "Mieste"
            },
            {
                "value": 38224,
                "label": "Ringenwalde",
                "label_ar": "رينغنوالد",
                "label_fr": "Ringenwalde"
            },
            {
                "value": 38225,
                "label": "Boldebuck",
                "label_ar": "بولديباك",
                "label_fr": "Boldebuck"
            },
            {
                "value": 38226,
                "label": "Gulzow",
                "label_ar": "جولزو",
                "label_fr": "Gulzow"
            },
            {
                "value": 38227,
                "label": "Nauen",
                "label_ar": "نوين",
                "label_fr": "Nauen"
            },
            {
                "value": 38228,
                "label": "Bastorf",
                "label_ar": "باستورف",
                "label_fr": "Bastorf"
            },
            {
                "value": 38229,
                "label": "Rehfelde",
                "label_ar": "Rehfelde",
                "label_fr": "Rehfelde"
            },
            {
                "value": 38230,
                "label": "Rossau",
                "label_ar": "روساو",
                "label_fr": "Rossau"
            },
            {
                "value": 38231,
                "label": "Bautzen",
                "label_ar": "باوتسن",
                "label_fr": "Bautzen"
            },
            {
                "value": 38232,
                "label": "Luckau",
                "label_ar": "لوكاو",
                "label_fr": "Luckau"
            },
            {
                "value": 38233,
                "label": "Welzow",
                "label_ar": "ويلزو",
                "label_fr": "Welzow"
            },
            {
                "value": 38234,
                "label": "Pinnow",
                "label_ar": "بينو",
                "label_fr": "Pinnow"
            },
            {
                "value": 38235,
                "label": "Grossschirma",
                "label_ar": "جروسشيرما",
                "label_fr": "Grossschirma"
            },
            {
                "value": 38236,
                "label": "Kamenz",
                "label_ar": "كامينز",
                "label_fr": "Kamenz"
            },
            {
                "value": 38237,
                "label": "Berggiesshubel",
                "label_ar": "Berggiesshubel",
                "label_fr": "Berggiesshubel"
            },
            {
                "value": 38238,
                "label": "Kesselsdorf",
                "label_ar": "كيسيلسدورف",
                "label_fr": "Kesselsdorf"
            },
            {
                "value": 38239,
                "label": "Merschwitz",
                "label_ar": "ميرشويتز",
                "label_fr": "Merschwitz"
            },
            {
                "value": 38240,
                "label": "Hoyerswerda",
                "label_ar": "هويرسفيردا",
                "label_fr": "Hoyerswerda"
            },
            {
                "value": 38241,
                "label": "Malschwitz",
                "label_ar": "مالشويتز",
                "label_fr": "Malschwitz"
            },
            {
                "value": 38242,
                "label": "Pfaffroda",
                "label_ar": "بفافرودا",
                "label_fr": "Pfaffroda"
            },
            {
                "value": 38243,
                "label": "Kurort Hartha",
                "label_ar": "كورورت هارثا",
                "label_fr": "Kurort Hartha"
            },
            {
                "value": 38244,
                "label": "Anzing",
                "label_ar": "أنزينغ",
                "label_fr": "Anzing"
            },
            {
                "value": 38245,
                "label": "Ottenhofen",
                "label_ar": "أوتينهوفن",
                "label_fr": "Ottenhofen"
            },
            {
                "value": 38246,
                "label": "Irschenberg",
                "label_ar": "ايرشنبرغ",
                "label_fr": "Irschenberg"
            },
            {
                "value": 38247,
                "label": "Riegsee",
                "label_ar": "Riegsee",
                "label_fr": "Riegsee"
            },
            {
                "value": 38248,
                "label": "Selb",
                "label_ar": "سيلب",
                "label_fr": "Selb"
            },
            {
                "value": 38249,
                "label": "Bolsterlang",
                "label_ar": "بولسترلانغ",
                "label_fr": "Bolsterlang"
            },
            {
                "value": 38250,
                "label": "Rottenburg an der Laaber",
                "label_ar": "روتنبورغ أن دير لابير",
                "label_fr": "Rottenburg an der Laaber"
            },
            {
                "value": 38251,
                "label": "Cham",
                "label_ar": "شام",
                "label_fr": "Cham"
            },
            {
                "value": 38252,
                "label": "Wessobrunn Abbey",
                "label_ar": "دير ويسوبرون",
                "label_fr": "Abbaye de Wessobrunn"
            },
            {
                "value": 38253,
                "label": "Moschenfeld",
                "label_ar": "موشنفيلد",
                "label_fr": "Moschenfeld"
            },
            {
                "value": 38254,
                "label": "Dietmannsried",
                "label_ar": "ديتمانسريد",
                "label_fr": "Dietmannsried"
            },
            {
                "value": 38255,
                "label": "Dollern",
                "label_ar": "دولرن",
                "label_fr": "Dollern"
            },
            {
                "value": 38256,
                "label": "Berne",
                "label_ar": "برن",
                "label_fr": "Berne"
            },
            {
                "value": 38257,
                "label": "Breuna",
                "label_ar": "بريونا",
                "label_fr": "Breuna"
            },
            {
                "value": 38258,
                "label": "Eppstein",
                "label_ar": "ابشتاين",
                "label_fr": "Eppstein"
            },
            {
                "value": 38259,
                "label": "Girod",
                "label_ar": "جيرود",
                "label_fr": "Girod"
            },
            {
                "value": 38260,
                "label": "Schwalbach",
                "label_ar": "شوالباخ",
                "label_fr": "Schwalbach"
            },
            {
                "value": 38261,
                "label": "Chiemsee",
                "label_ar": "Chiemsee",
                "label_fr": "Chiemsee"
            },
            {
                "value": 38262,
                "label": "Pattensen",
                "label_ar": "باتنسن",
                "label_fr": "Pattensen"
            },
            {
                "value": 38263,
                "label": "Gaishofen",
                "label_ar": "Gaishofen",
                "label_fr": "Gaishofen"
            },
            {
                "value": 38264,
                "label": "Sarstedt",
                "label_ar": "سارستيدت",
                "label_fr": "Sarstedt"
            },
            {
                "value": 38265,
                "label": "Bad Salzungen",
                "label_ar": "Bad Salzungen",
                "label_fr": "Bad Salzungen"
            },
            {
                "value": 38266,
                "label": "Gerstetten",
                "label_ar": "جيرستيتن",
                "label_fr": "Gerstetten"
            },
            {
                "value": 38267,
                "label": "Laichingen",
                "label_ar": "لاتشينجين",
                "label_fr": "Laichingen"
            },
            {
                "value": 38268,
                "label": "Essingen",
                "label_ar": "إسينجين",
                "label_fr": "Essingen"
            },
            {
                "value": 38269,
                "label": "Bobingen",
                "label_ar": "بوبينغن",
                "label_fr": "Bobingen"
            },
            {
                "value": 38270,
                "label": "Machtolsheim",
                "label_ar": "ماتولشيم",
                "label_fr": "Machtolsheim"
            },
            {
                "value": 38271,
                "label": "Abtsgmuend",
                "label_ar": "أبتسجمويند",
                "label_fr": "Abtsgmuend"
            },
            {
                "value": 38272,
                "label": "Hauzenberg",
                "label_ar": "هوزنبرج",
                "label_fr": "Hauzenberg"
            },
            {
                "value": 38273,
                "label": "Wegscheid",
                "label_ar": "Wegscheid",
                "label_fr": "Wegscheid"
            },
            {
                "value": 38274,
                "label": "Hunxe",
                "label_ar": "هونكس",
                "label_fr": "Hunxe"
            },
            {
                "value": 38275,
                "label": "Greven",
                "label_ar": "جريفن",
                "label_fr": "Greven"
            },
            {
                "value": 38276,
                "label": "Borken",
                "label_ar": "بوركين",
                "label_fr": "Borken"
            },
            {
                "value": 38277,
                "label": "Linz am Rhein",
                "label_ar": "لينز آم راين",
                "label_fr": "Linz am Rhein"
            },
            {
                "value": 38278,
                "label": "Hattersheim",
                "label_ar": "هاترشيم",
                "label_fr": "Hattersheim"
            },
            {
                "value": 38279,
                "label": "Poppenhausen",
                "label_ar": "بوبنهاوزن",
                "label_fr": "Poppenhausen"
            },
            {
                "value": 38280,
                "label": "Eiterfeld",
                "label_ar": "إيتيرفيلد",
                "label_fr": "Eiterfeld"
            },
            {
                "value": 38281,
                "label": "Ranstadt",
                "label_ar": "رانشتات",
                "label_fr": "Ranstadt"
            },
            {
                "value": 38282,
                "label": "Kleinkahl",
                "label_ar": "كلينكال",
                "label_fr": "Kleinkahl"
            },
            {
                "value": 38283,
                "label": "Happurg",
                "label_ar": "هابورج",
                "label_fr": "Happurg"
            },
            {
                "value": 38284,
                "label": "Hannover",
                "label_ar": "هانوفر",
                "label_fr": "Hanovre"
            },
            {
                "value": 38285,
                "label": "Streich",
                "label_ar": "شترايش",
                "label_fr": "Streich"
            },
            {
                "value": 38286,
                "label": "Schutzenhof",
                "label_ar": "شوتزينهوف",
                "label_fr": "Schutzenhof"
            },
            {
                "value": 38287,
                "label": "Markt",
                "label_ar": "ماركت",
                "label_fr": "Markt"
            },
            {
                "value": 38288,
                "label": "Alexander",
                "label_ar": "الكسندر",
                "label_fr": "Alexandre"
            },
            {
                "value": 38289,
                "label": "Breckerfeld",
                "label_ar": "بريكرفيلد",
                "label_fr": "Breckerfeld"
            },
            {
                "value": 38290,
                "label": "Dolgesheim",
                "label_ar": "دولجيشيم",
                "label_fr": "Dolgesheim"
            },
            {
                "value": 38291,
                "label": "Reinhartshausen",
                "label_ar": "راينهارتسهاوزن",
                "label_fr": "Reinhartshausen"
            },
            {
                "value": 38292,
                "label": "Monschau",
                "label_ar": "مونشاو",
                "label_fr": "Monschau"
            },
            {
                "value": 38293,
                "label": "Simmerath",
                "label_ar": "سيمراث",
                "label_fr": "Simmerath"
            },
            {
                "value": 38294,
                "label": "Hartenstein",
                "label_ar": "هارتنشتاين",
                "label_fr": "Hartenstein"
            },
            {
                "value": 38295,
                "label": "Massenheim",
                "label_ar": "ماسينهايم",
                "label_fr": "Massenheim"
            },
            {
                "value": 38296,
                "label": "Herzfeld",
                "label_ar": "هرتسفيلد",
                "label_fr": "Herzfeld"
            },
            {
                "value": 38297,
                "label": "Volkmarsen",
                "label_ar": "فولكمارسن",
                "label_fr": "Volkmarsen"
            },
            {
                "value": 38298,
                "label": "Kamp-Lintfort",
                "label_ar": "كامب لينفورت",
                "label_fr": "Kamp-Lintfort"
            },
            {
                "value": 38299,
                "label": "Mohnesee",
                "label_ar": "موهنيزي",
                "label_fr": "Mohnesee"
            },
            {
                "value": 38300,
                "label": "Witten",
                "label_ar": "ويتن",
                "label_fr": "Witten"
            },
            {
                "value": 38301,
                "label": "Georgensgmund",
                "label_ar": "جورجنسجموند",
                "label_fr": "Georgensgmund"
            },
            {
                "value": 38302,
                "label": "Westhausen",
                "label_ar": "ويستهاوزن",
                "label_fr": "Westhausen"
            },
            {
                "value": 38303,
                "label": "Weiltingen",
                "label_ar": "ويلتينجن",
                "label_fr": "Weiltingen"
            },
            {
                "value": 38304,
                "label": "Sontheim an der Brenz",
                "label_ar": "Sontheim an der Brenz",
                "label_fr": "Sontheim an der Brenz"
            },
            {
                "value": 38305,
                "label": "Nördlingen",
                "label_ar": "نوردلينجن",
                "label_fr": "Nördlingen"
            },
            {
                "value": 38306,
                "label": "Huttlingen",
                "label_ar": "هاتلينجن",
                "label_fr": "Huttlingen"
            },
            {
                "value": 38307,
                "label": "Tannhausen",
                "label_ar": "تانهاوزن",
                "label_fr": "Tannhausen"
            },
            {
                "value": 38308,
                "label": "Steinweiler",
                "label_ar": "شتاينويلر",
                "label_fr": "Steinweiler"
            },
            {
                "value": 38309,
                "label": "Nachrodt-Wiblingwerde",
                "label_ar": "Nachrodt-Wiblingwerde",
                "label_fr": "Nachrodt-Wiblingwerde"
            },
            {
                "value": 38310,
                "label": "Herscheid",
                "label_ar": "هيرشيد",
                "label_fr": "Herscheid"
            },
            {
                "value": 38311,
                "label": "Grenzau",
                "label_ar": "جرينزاو",
                "label_fr": "Grenzau"
            },
            {
                "value": 38312,
                "label": "Dobler",
                "label_ar": "دوبلر",
                "label_fr": "Dobler"
            },
            {
                "value": 38313,
                "label": "Mulheim-Karlich",
                "label_ar": "مولهايم كارليش",
                "label_fr": "Mulheim-Karlich"
            },
            {
                "value": 38314,
                "label": "Karlstein",
                "label_ar": "كارلشتاين",
                "label_fr": "Karlstein"
            },
            {
                "value": 38315,
                "label": "Gehrden",
                "label_ar": "جيردن",
                "label_fr": "Gehrden"
            },
            {
                "value": 38316,
                "label": "Wurselen",
                "label_ar": "فورسيلين",
                "label_fr": "Wurselen"
            },
            {
                "value": 38317,
                "label": "Detmold",
                "label_ar": "ديتمولد",
                "label_fr": "Detmold"
            },
            {
                "value": 38318,
                "label": "Altena",
                "label_ar": "ألتينا",
                "label_fr": "Altena"
            },
            {
                "value": 38319,
                "label": "Schmolln",
                "label_ar": "شمولن",
                "label_fr": "Schmolln"
            },
            {
                "value": 38320,
                "label": "Kanzlei",
                "label_ar": "Kanzlei",
                "label_fr": "Kanzlei"
            },
            {
                "value": 38321,
                "label": "Erika",
                "label_ar": "إريكا",
                "label_fr": "Erika"
            },
            {
                "value": 38322,
                "label": "Ergersheim",
                "label_ar": "إرجرسهايم",
                "label_fr": "Ergersheim"
            },
            {
                "value": 38323,
                "label": "Ebermannstadt",
                "label_ar": "ابرمانشتات",
                "label_fr": "Ebermannstadt"
            },
            {
                "value": 38324,
                "label": "Kranenburg",
                "label_ar": "كرانين",
                "label_fr": "Kranenburg"
            },
            {
                "value": 38325,
                "label": "Freund",
                "label_ar": "فرويند",
                "label_fr": "Freund"
            },
            {
                "value": 38326,
                "label": "Hohenhaus",
                "label_ar": "هوهنهاوس",
                "label_fr": "Hohenhaus"
            },
            {
                "value": 38327,
                "label": "Hirschau",
                "label_ar": "هيرشاو",
                "label_fr": "Hirschau"
            },
            {
                "value": 38328,
                "label": "Bruckmuhl",
                "label_ar": "بروكمول",
                "label_fr": "Bruckmuhl"
            },
            {
                "value": 38329,
                "label": "Worfelden",
                "label_ar": "Worfelden",
                "label_fr": "Worfelden"
            },
            {
                "value": 38330,
                "label": "Gronau",
                "label_ar": "جروناو",
                "label_fr": "Gronau"
            },
            {
                "value": 38331,
                "label": "Heiligenroth",
                "label_ar": "هيليغنروث",
                "label_fr": "Heiligenroth"
            },
            {
                "value": 38332,
                "label": "Roding",
                "label_ar": "رودينج",
                "label_fr": "Roding"
            },
            {
                "value": 38333,
                "label": "Wohlsborn",
                "label_ar": "فولسبورن",
                "label_fr": "Wohlsborn"
            },
            {
                "value": 38334,
                "label": "Butzow",
                "label_ar": "بوتزو",
                "label_fr": "Butzow"
            },
            {
                "value": 38335,
                "label": "Neu Sanitz",
                "label_ar": "نيو سانيتز",
                "label_fr": "Neu Sanitz"
            },
            {
                "value": 38336,
                "label": "Gremersdorf",
                "label_ar": "جريمرسدورف",
                "label_fr": "Gremersdorf"
            },
            {
                "value": 38337,
                "label": "Neubukow",
                "label_ar": "نيوبوكو",
                "label_fr": "Neubukow"
            },
            {
                "value": 38338,
                "label": "Stabelow",
                "label_ar": "ستابيلو",
                "label_fr": "Stabelow"
            },
            {
                "value": 38339,
                "label": "Glasewitz",
                "label_ar": "غلازويتز",
                "label_fr": "Glasewitz"
            },
            {
                "value": 38340,
                "label": "Dettmannsdorf",
                "label_ar": "ديتمانسدورف",
                "label_fr": "Dettmannsdorf"
            },
            {
                "value": 38341,
                "label": "Wattmannshagen",
                "label_ar": "واتمانشاجين",
                "label_fr": "Wattmannshagen"
            },
            {
                "value": 38342,
                "label": "Tutow",
                "label_ar": "توتو",
                "label_fr": "Tutow"
            },
            {
                "value": 38343,
                "label": "Splietsdorf",
                "label_ar": "سبليتسدورف",
                "label_fr": "Splietsdorf"
            },
            {
                "value": 38344,
                "label": "Gravenwiesbach",
                "label_ar": "جرافينويزباخ",
                "label_fr": "Gravenwiesbach"
            },
            {
                "value": 38345,
                "label": "Usingen",
                "label_ar": "استخدام",
                "label_fr": "Usingen"
            },
            {
                "value": 38346,
                "label": "Konigstein im Taunus",
                "label_ar": "Konigstein im Taunus",
                "label_fr": "Konigstein im Taunus"
            },
            {
                "value": 38347,
                "label": "Urspringen",
                "label_ar": "أورسبرينجين",
                "label_fr": "Urspringen"
            },
            {
                "value": 38348,
                "label": "Eichenbuhl",
                "label_ar": "ايتشنبول",
                "label_fr": "Eichenbuhl"
            },
            {
                "value": 38349,
                "label": "Konigsberg in Bayern",
                "label_ar": "كونيجسبيرج في بايرن ميونيخ",
                "label_fr": "Königsberg dans le Bayern"
            },
            {
                "value": 38350,
                "label": "Theilheim",
                "label_ar": "ثيلهايم",
                "label_fr": "Theilheim"
            },
            {
                "value": 38351,
                "label": "Schwanfeld",
                "label_ar": "شوانفيلد",
                "label_fr": "Schwanfeld"
            },
            {
                "value": 38352,
                "label": "Wurzburg",
                "label_ar": "فورتسبورغ",
                "label_fr": "Würzburg"
            },
            {
                "value": 38353,
                "label": "Knetzgau",
                "label_ar": "كنيتزغاو",
                "label_fr": "Knetzgau"
            },
            {
                "value": 38354,
                "label": "Wiesentheid",
                "label_ar": "Wiesentheid",
                "label_fr": "Wiesentheid"
            },
            {
                "value": 38355,
                "label": "Dombuhl",
                "label_ar": "دومبول",
                "label_fr": "Dombuhl"
            },
            {
                "value": 38356,
                "label": "Munchaurach",
                "label_ar": "مونشاراش",
                "label_fr": "Munchaurach"
            },
            {
                "value": 38357,
                "label": "Neunkirchen",
                "label_ar": "نيونكيرشن",
                "label_fr": "Neunkirchen"
            },
            {
                "value": 38358,
                "label": "Weisendorf",
                "label_ar": "وايزندورف",
                "label_fr": "Weisendorf"
            },
            {
                "value": 38359,
                "label": "Bubenreuth",
                "label_ar": "بوبنروث",
                "label_fr": "Bubenreuth"
            },
            {
                "value": 38360,
                "label": "Telgte",
                "label_ar": "Telgte",
                "label_fr": "Telgte"
            },
            {
                "value": 38361,
                "label": "Limburg an der Lahn",
                "label_ar": "Limburg an der Lahn",
                "label_fr": "Limburg an der Lahn"
            },
            {
                "value": 38362,
                "label": "Marbach am Neckar",
                "label_ar": "مارباخ آم نيكار",
                "label_fr": "Marbach am Neckar"
            },
            {
                "value": 38363,
                "label": "Kitzingen",
                "label_ar": "كيتزينجين",
                "label_fr": "Kitzingen"
            },
            {
                "value": 38364,
                "label": "Gettorf",
                "label_ar": "جيتورف",
                "label_fr": "Gettorf"
            },
            {
                "value": 38365,
                "label": "Wesselburen",
                "label_ar": "Wesselburen",
                "label_fr": "Wesselburen"
            },
            {
                "value": 38366,
                "label": "Gross Kreutz",
                "label_ar": "جروس كروتز",
                "label_fr": "Gross Kreutz"
            },
            {
                "value": 38367,
                "label": "Ochsenhausen",
                "label_ar": "أوشسينهاوزن",
                "label_fr": "Ochsenhausen"
            },
            {
                "value": 38368,
                "label": "Bad Wurzach",
                "label_ar": "باد ورزاتش",
                "label_fr": "Bad Wurzach"
            },
            {
                "value": 38369,
                "label": "Bissendorf",
                "label_ar": "بيسندورف",
                "label_fr": "Bissendorf"
            },
            {
                "value": 38370,
                "label": "Berg",
                "label_ar": "بيرج",
                "label_fr": "Berg"
            },
            {
                "value": 38371,
                "label": "Werdau",
                "label_ar": "ويرداو",
                "label_fr": "Werdau"
            },
            {
                "value": 38372,
                "label": "Berching",
                "label_ar": "ترسو",
                "label_fr": "Berching"
            },
            {
                "value": 38373,
                "label": "Anklam",
                "label_ar": "انكلام",
                "label_fr": "Anklam"
            },
            {
                "value": 38374,
                "label": "Much",
                "label_ar": "كثير",
                "label_fr": "Beaucoup"
            },
            {
                "value": 38375,
                "label": "Hauser",
                "label_ar": "هاوزر",
                "label_fr": "Hauser"
            },
            {
                "value": 38376,
                "label": "Huglfing",
                "label_ar": "Huglfing",
                "label_fr": "Huglfing"
            },
            {
                "value": 38377,
                "label": "Penzing",
                "label_ar": "بينسينغ",
                "label_fr": "Penzing"
            },
            {
                "value": 38378,
                "label": "Garmisch-Partenkirchen",
                "label_ar": "جارمش بارتنكيرشن",
                "label_fr": "Garmisch-Partenkirchen"
            },
            {
                "value": 38379,
                "label": "Rohrmoos",
                "label_ar": "روهرموس",
                "label_fr": "Rohrmoos"
            },
            {
                "value": 38380,
                "label": "Forstern",
                "label_ar": "فورسترن",
                "label_fr": "Forstern"
            },
            {
                "value": 38381,
                "label": "Bad Aibling",
                "label_ar": "سيء ايبلنج",
                "label_fr": "Mauvais Aibling"
            },
            {
                "value": 38382,
                "label": "Krailling",
                "label_ar": "كريلينج",
                "label_fr": "Tuer"
            },
            {
                "value": 38383,
                "label": "Hohenkammer",
                "label_ar": "هوهنكامر",
                "label_fr": "Hohenkammer"
            },
            {
                "value": 38384,
                "label": "Feldkirchen-Westerham",
                "label_ar": "فيلدكيرشن ويسترهام",
                "label_fr": "Feldkirchen-Westerham"
            },
            {
                "value": 38385,
                "label": "Hohenpeissenberg",
                "label_ar": "Hohenpeissenberg",
                "label_fr": "Hohenpeissenberg"
            },
            {
                "value": 38386,
                "label": "Klais",
                "label_ar": "كلايس",
                "label_fr": "Klais"
            },
            {
                "value": 38387,
                "label": "Supplingen",
                "label_ar": "مرن",
                "label_fr": "Supplingen"
            },
            {
                "value": 38388,
                "label": "Clausthal-Zellerfeld",
                "label_ar": "كلاوستال زيلرفيلد",
                "label_fr": "Clausthal-Zellerfeld"
            },
            {
                "value": 38389,
                "label": "Altenau",
                "label_ar": "التيناو",
                "label_fr": "Altenau"
            },
            {
                "value": 38390,
                "label": "Sassenburg",
                "label_ar": "ساسينبورغ",
                "label_fr": "Sassenburg"
            },
            {
                "value": 38391,
                "label": "Korntal",
                "label_ar": "كورنال",
                "label_fr": "Korntal"
            },
            {
                "value": 38392,
                "label": "Offenbach an der Queich",
                "label_ar": "أوفنباخ أن دير كويش",
                "label_fr": "Offenbach an der Queich"
            },
            {
                "value": 38393,
                "label": "Rheurdt",
                "label_ar": "روردت",
                "label_fr": "Rheurdt"
            },
            {
                "value": 38394,
                "label": "Moers",
                "label_ar": "مويرس",
                "label_fr": "Moers"
            },
            {
                "value": 38395,
                "label": "Hasloch",
                "label_ar": "هاسلوش",
                "label_fr": "Hasloch"
            },
            {
                "value": 38396,
                "label": "Friedberg",
                "label_ar": "فريدبرج",
                "label_fr": "Friedberg"
            },
            {
                "value": 38397,
                "label": "Buttenwiesen",
                "label_ar": "بوتينويزين",
                "label_fr": "Buttenwiesen"
            },
            {
                "value": 38398,
                "label": "Munster",
                "label_ar": "مونستر",
                "label_fr": "Munster"
            },
            {
                "value": 38399,
                "label": "Marxheim",
                "label_ar": "ماركسهايم",
                "label_fr": "Marxheim"
            },
            {
                "value": 38400,
                "label": "Ziemetshausen",
                "label_ar": "زيميتسهاوزن",
                "label_fr": "Ziemetshausen"
            },
            {
                "value": 38401,
                "label": "Althegnenberg",
                "label_ar": "Althegnenberg",
                "label_fr": "Althegnenberg"
            },
            {
                "value": 38402,
                "label": "Wessiszell",
                "label_ar": "ويسزيل",
                "label_fr": "Wessiszell"
            },
            {
                "value": 38403,
                "label": "Schmiechen",
                "label_ar": "شميتشن",
                "label_fr": "Schmiechen"
            },
            {
                "value": 38404,
                "label": "Langenneufnach",
                "label_ar": "لانجينوفناخ",
                "label_fr": "Langenneufnach"
            },
            {
                "value": 38405,
                "label": "Nordendorf",
                "label_ar": "نوردندورف",
                "label_fr": "Nordendorf"
            },
            {
                "value": 38406,
                "label": "Leipheim",
                "label_ar": "لايبهايم",
                "label_fr": "Leipheim"
            },
            {
                "value": 38407,
                "label": "Fahrenzhausen",
                "label_ar": "فهرنهاوزن",
                "label_fr": "Fahrenzhausen"
            },
            {
                "value": 38408,
                "label": "Apfeldorf",
                "label_ar": "أبفلدورف",
                "label_fr": "Apfeldorf"
            },
            {
                "value": 38409,
                "label": "Peiting",
                "label_ar": "المضايقة",
                "label_fr": "Peiting"
            },
            {
                "value": 38410,
                "label": "Gaimersheim",
                "label_ar": "جايمرسهايم",
                "label_fr": "Gaimersheim"
            },
            {
                "value": 38411,
                "label": "Loiching",
                "label_ar": "Loiching",
                "label_fr": "Loiching"
            },
            {
                "value": 38412,
                "label": "Rhinow",
                "label_ar": "رينو",
                "label_fr": "Rhinow"
            },
            {
                "value": 38413,
                "label": "Wiesenburg",
                "label_ar": "Wiesenburg",
                "label_fr": "Wiesenburg"
            },
            {
                "value": 38414,
                "label": "Verl",
                "label_ar": "فيرل",
                "label_fr": "Verl"
            },
            {
                "value": 38415,
                "label": "Wipperfürth",
                "label_ar": "ويبرفورث",
                "label_fr": "Wipperfürth"
            },
            {
                "value": 38416,
                "label": "Geiselbach",
                "label_ar": "Geiselbach",
                "label_fr": "Geiselbach"
            },
            {
                "value": 38417,
                "label": "Ingelheim am Rhein",
                "label_ar": "إنجلهايم ام راين",
                "label_fr": "Ingelheim am Rhein"
            },
            {
                "value": 38418,
                "label": "Barsinghausen",
                "label_ar": "بارسينجهاوزن",
                "label_fr": "Barsinghausen"
            },
            {
                "value": 38419,
                "label": "Oestrich-Winkel",
                "label_ar": "اوستريش فينكل",
                "label_fr": "Oestrich-Winkel"
            },
            {
                "value": 38420,
                "label": "Seebad Bansin",
                "label_ar": "سيباد بانسين",
                "label_fr": "Seebad Bansin"
            },
            {
                "value": 38421,
                "label": "Bayerisch Gmain",
                "label_ar": "بايريش جمين",
                "label_fr": "Bayerisch Gmain"
            },
            {
                "value": 38422,
                "label": "Ismaning",
                "label_ar": "Ismaning",
                "label_fr": "Ismaning"
            },
            {
                "value": 38423,
                "label": "Garbsen",
                "label_ar": "غاربسن",
                "label_fr": "Garbsen"
            },
            {
                "value": 38424,
                "label": "Stellshagen",
                "label_ar": "Stellshagen",
                "label_fr": "Stellshagen"
            },
            {
                "value": 38425,
                "label": "Usedom",
                "label_ar": "يوزدوم",
                "label_fr": "Usedom"
            },
            {
                "value": 38426,
                "label": "Wilhelmsthal",
                "label_ar": "فيلهلمستال",
                "label_fr": "Wilhelmsthal"
            },
            {
                "value": 38427,
                "label": "Dornstetten",
                "label_ar": "دورنشتيتن",
                "label_fr": "Dornstetten"
            },
            {
                "value": 38428,
                "label": "Dieburg",
                "label_ar": "ديبورغ",
                "label_fr": "Dieburg"
            },
            {
                "value": 38429,
                "label": "Mombris",
                "label_ar": "مومبريس",
                "label_fr": "Mombris"
            },
            {
                "value": 38430,
                "label": "Forsthaus",
                "label_ar": "فورستهاوس",
                "label_fr": "Forsthaus"
            },
            {
                "value": 38431,
                "label": "Albertshausen",
                "label_ar": "ألبيرتسهاوزن",
                "label_fr": "Albertshausen"
            },
            {
                "value": 38432,
                "label": "Osloss",
                "label_ar": "أوسلوس",
                "label_fr": "Osloss"
            },
            {
                "value": 38433,
                "label": "Tappenbeck",
                "label_ar": "تابينبيك",
                "label_fr": "Tappenbeck"
            },
            {
                "value": 38434,
                "label": "Raubling",
                "label_ar": "راوبلينج",
                "label_fr": "Raubling"
            },
            {
                "value": 38435,
                "label": "Waldfeucht",
                "label_ar": "والدفيخت",
                "label_fr": "Waldfeucht"
            },
            {
                "value": 38436,
                "label": "Dockingen",
                "label_ar": "دوكينغين",
                "label_fr": "Dockingen"
            },
            {
                "value": 38437,
                "label": "Donauwörth",
                "label_ar": "دوناوورث",
                "label_fr": "Donauwörth"
            },
            {
                "value": 38438,
                "label": "Monheim",
                "label_ar": "مونهايم",
                "label_fr": "Monheim"
            },
            {
                "value": 38439,
                "label": "Niederschonenfeld",
                "label_ar": "نيدرشوننفيلد",
                "label_fr": "Niederschonenfeld"
            },
            {
                "value": 38440,
                "label": "Ellzee",
                "label_ar": "إلزي",
                "label_fr": "Ellzee"
            },
            {
                "value": 38441,
                "label": "Aletshausen",
                "label_ar": "أليتشاوسين",
                "label_fr": "Aletshausen"
            },
            {
                "value": 38442,
                "label": "Ebershausen",
                "label_ar": "إبيرشوزن",
                "label_fr": "Ebershausen"
            },
            {
                "value": 38443,
                "label": "Kettershausen",
                "label_ar": "Kettershausen",
                "label_fr": "Kettershausen"
            },
            {
                "value": 38444,
                "label": "Oberndorf",
                "label_ar": "اوبرندورف",
                "label_fr": "Oberndorf"
            },
            {
                "value": 38445,
                "label": "Grassau",
                "label_ar": "جراسو",
                "label_fr": "Grassau"
            },
            {
                "value": 38446,
                "label": "Maximilian",
                "label_ar": "ماكسيميليان",
                "label_fr": "Maximilien"
            },
            {
                "value": 38447,
                "label": "Bonen",
                "label_ar": "بونين",
                "label_fr": "Bonen"
            },
            {
                "value": 38448,
                "label": "Aschheim",
                "label_ar": "أشهايم",
                "label_fr": "Aschheim"
            },
            {
                "value": 38449,
                "label": "Margertshausen",
                "label_ar": "مارجيرتسهاوزن",
                "label_fr": "Margertshausen"
            },
            {
                "value": 38450,
                "label": "Memmingen",
                "label_ar": "ميمينجين",
                "label_fr": "Memmingen"
            },
            {
                "value": 38451,
                "label": "Beiersdorf",
                "label_ar": "بيرسدورف",
                "label_fr": "Beiersdorf"
            },
            {
                "value": 38452,
                "label": "Bingen",
                "label_ar": "بينغن",
                "label_fr": "Bingen"
            },
            {
                "value": 38453,
                "label": "Hausen ob Verena",
                "label_ar": "هاوزن أوب فيرينا",
                "label_fr": "Hausen ob Verena"
            },
            {
                "value": 38454,
                "label": "Messkirch",
                "label_ar": "مسكيرش",
                "label_fr": "Messkirch"
            },
            {
                "value": 38455,
                "label": "Immendingen",
                "label_ar": "ممتنع",
                "label_fr": "Immendingen"
            },
            {
                "value": 38456,
                "label": "Bondorf",
                "label_ar": "بوندورف",
                "label_fr": "Bondorf"
            },
            {
                "value": 38457,
                "label": "Muhlheim an der Donau",
                "label_ar": "Muhlheim an der Donau",
                "label_fr": "Muhlheim an der Donau"
            },
            {
                "value": 38458,
                "label": "Schwenningen",
                "label_ar": "شوينينغين",
                "label_fr": "Schwenningen"
            },
            {
                "value": 38459,
                "label": "Bietingen",
                "label_ar": "بيتينغن",
                "label_fr": "Bietingen"
            },
            {
                "value": 38460,
                "label": "Leibertingen",
                "label_ar": "ليبرتينجن",
                "label_fr": "Leibertingen"
            },
            {
                "value": 38461,
                "label": "Veringenstadt",
                "label_ar": "فيرينجنشتات",
                "label_fr": "Veringenstadt"
            },
            {
                "value": 38462,
                "label": "Emmingen-Liptingen",
                "label_ar": "إمينجين ليبتينغين",
                "label_fr": "Emmingen-Liptingen"
            },
            {
                "value": 38463,
                "label": "Grunheide",
                "label_ar": "جرونهايد",
                "label_fr": "Grunheide"
            },
            {
                "value": 38464,
                "label": "Jacobsdorf",
                "label_ar": "جاكوبسدورف",
                "label_fr": "Jacobsdorf"
            },
            {
                "value": 38465,
                "label": "Hennickendorf",
                "label_ar": "هينكيندورف",
                "label_fr": "Hennickendorf"
            },
            {
                "value": 38466,
                "label": "Rauen",
                "label_ar": "راوين",
                "label_fr": "Rauen"
            },
            {
                "value": 38467,
                "label": "Angermunde",
                "label_ar": "أنجيرموندي",
                "label_fr": "Angermunde"
            },
            {
                "value": 38468,
                "label": "Pfedelbach",
                "label_ar": "فيديلباخ",
                "label_fr": "Pfedelbach"
            },
            {
                "value": 38469,
                "label": "Kupferzell",
                "label_ar": "كوبفيرزيل",
                "label_fr": "Kupferzell"
            },
            {
                "value": 38470,
                "label": "Schnelldorf",
                "label_ar": "شنيلدورف",
                "label_fr": "Schnelldorf"
            },
            {
                "value": 38471,
                "label": "Untereisesheim",
                "label_ar": "Untereisesheim",
                "label_fr": "Untereisesheim"
            },
            {
                "value": 38472,
                "label": "Igersheim",
                "label_ar": "إيجرسهايم",
                "label_fr": "Igersheim"
            },
            {
                "value": 38473,
                "label": "Grosserlach",
                "label_ar": "غروسيرلاخ",
                "label_fr": "Grosserlach"
            },
            {
                "value": 38474,
                "label": "Wustenrot",
                "label_ar": "Wustenrot",
                "label_fr": "Wustenrot"
            },
            {
                "value": 38475,
                "label": "Erlenbach",
                "label_ar": "إرلينباخ",
                "label_fr": "Erlenbach"
            },
            {
                "value": 38476,
                "label": "Sulzbach",
                "label_ar": "سولزباخ",
                "label_fr": "Sulzbach"
            },
            {
                "value": 38477,
                "label": "Katzenstein",
                "label_ar": "كاتزنشتاين",
                "label_fr": "Katzenstein"
            },
            {
                "value": 38478,
                "label": "Wiesau",
                "label_ar": "فيساو",
                "label_fr": "Wiesau"
            },
            {
                "value": 38479,
                "label": "Ostseebad Boltenhagen",
                "label_ar": "أوستسيباد بولتينهاجين",
                "label_fr": "Ostseebad Boltenhagen"
            },
            {
                "value": 38480,
                "label": "Eden",
                "label_ar": "عدن",
                "label_fr": "Eden"
            },
            {
                "value": 38481,
                "label": "Oschersleben",
                "label_ar": "Oschersleben",
                "label_fr": "Oschersleben"
            },
            {
                "value": 38482,
                "label": "Timmendorf",
                "label_ar": "تيميندورف",
                "label_fr": "Timmendorf"
            },
            {
                "value": 38483,
                "label": "Lage",
                "label_ar": "لاج",
                "label_fr": "Lage"
            },
            {
                "value": 38484,
                "label": "Oberboihingen",
                "label_ar": "أوبربوهينغن",
                "label_fr": "Oberboihingen"
            },
            {
                "value": 38485,
                "label": "Lennestadt",
                "label_ar": "لينشتات",
                "label_fr": "Lennestadt"
            },
            {
                "value": 38486,
                "label": "Niederzier",
                "label_ar": "نيدرزير",
                "label_fr": "Niederzier"
            },
            {
                "value": 38487,
                "label": "Bemerode",
                "label_ar": "بيمروده",
                "label_fr": "Bemerode"
            },
            {
                "value": 38488,
                "label": "Gerbrunn",
                "label_ar": "جيربرون",
                "label_fr": "Gerbrunn"
            },
            {
                "value": 38489,
                "label": "Mainaschaff",
                "label_ar": "مايناشاف",
                "label_fr": "Mainaschaff"
            },
            {
                "value": 38490,
                "label": "Randersacker",
                "label_ar": "راندرساكر",
                "label_fr": "Randersacker"
            },
            {
                "value": 38491,
                "label": "Kleinwallstadt",
                "label_ar": "Kleinwallstadt",
                "label_fr": "Kleinwallstadt"
            },
            {
                "value": 38492,
                "label": "Zell am Main",
                "label_ar": "زيل أم ماين",
                "label_fr": "Zell am Main"
            },
            {
                "value": 38493,
                "label": "Thungersheim",
                "label_ar": "ثونجيرشيم",
                "label_fr": "Thungersheim"
            },
            {
                "value": 38494,
                "label": "Weibersbrunn",
                "label_ar": "Weibersbrunn",
                "label_fr": "Weibersbrunn"
            },
            {
                "value": 38495,
                "label": "Schollkrippen",
                "label_ar": "شولكريبن",
                "label_fr": "Schollkrippen"
            },
            {
                "value": 38496,
                "label": "Krombach",
                "label_ar": "كرومباخ",
                "label_fr": "Krombach"
            },
            {
                "value": 38497,
                "label": "Haibach",
                "label_ar": "هيباتش",
                "label_fr": "Haibach"
            },
            {
                "value": 38498,
                "label": "Kohren-Sahlis",
                "label_ar": "كوهرين سهل",
                "label_fr": "Kohren-Sahlis"
            },
            {
                "value": 38499,
                "label": "Rheine",
                "label_ar": "راين",
                "label_fr": "Rheine"
            },
            {
                "value": 38500,
                "label": "Frohburg",
                "label_ar": "فروهبرج",
                "label_fr": "Frohburg"
            },
            {
                "value": 38501,
                "label": "Thallwitz",
                "label_ar": "ثالويتز",
                "label_fr": "Thallwitz"
            },
            {
                "value": 38502,
                "label": "Voerde",
                "label_ar": "فويردي",
                "label_fr": "Voerde"
            },
            {
                "value": 38503,
                "label": "Raesfeld",
                "label_ar": "Raesfeld",
                "label_fr": "Raesfeld"
            },
            {
                "value": 38504,
                "label": "Sonsbeck",
                "label_ar": "سونسبيك",
                "label_fr": "Sonsbeck"
            },
            {
                "value": 38505,
                "label": "Schermbeck",
                "label_ar": "شيرمبيك",
                "label_fr": "Schermbeck"
            },
            {
                "value": 38506,
                "label": "Weeze",
                "label_ar": "ويز",
                "label_fr": "Weeze"
            },
            {
                "value": 38507,
                "label": "Rees",
                "label_ar": "ريس",
                "label_fr": "Rees"
            },
            {
                "value": 38508,
                "label": "Affing",
                "label_ar": "التقرب",
                "label_fr": "Affing"
            },
            {
                "value": 38509,
                "label": "Obergriesbach",
                "label_ar": "اوبرجريسباخ",
                "label_fr": "Obergriesbach"
            },
            {
                "value": 38510,
                "label": "Thierhaupten",
                "label_ar": "ثيرهاوبتن",
                "label_fr": "Thierhaupten"
            },
            {
                "value": 38511,
                "label": "Wertingen",
                "label_ar": "فيرتينغن",
                "label_fr": "Wertingen"
            },
            {
                "value": 38512,
                "label": "Finningen",
                "label_ar": "Finningen",
                "label_fr": "Finningen"
            },
            {
                "value": 38513,
                "label": "Bissingen",
                "label_ar": "بيسينغين",
                "label_fr": "Bissingen"
            },
            {
                "value": 38514,
                "label": "Rain",
                "label_ar": "تمطر",
                "label_fr": "Pluie"
            },
            {
                "value": 38515,
                "label": "Mering",
                "label_ar": "ميرينغ",
                "label_fr": "Mering"
            },
            {
                "value": 38516,
                "label": "Mertingen",
                "label_ar": "ميرتينجن",
                "label_fr": "Mertingen"
            },
            {
                "value": 38517,
                "label": "Zoschingen",
                "label_ar": "زوشينجن",
                "label_fr": "Zoschingen"
            },
            {
                "value": 38518,
                "label": "Untermeitingen",
                "label_ar": "Untermeitingen",
                "label_fr": "Untermeitingen"
            },
            {
                "value": 38519,
                "label": "Welden",
                "label_ar": "ويلدن",
                "label_fr": "Welden"
            },
            {
                "value": 38520,
                "label": "Hainsfarth",
                "label_ar": "هينسفارث",
                "label_fr": "Hainsfarth"
            },
            {
                "value": 38521,
                "label": "Bad Laasphe",
                "label_ar": "سيئة لاسب",
                "label_fr": "Bad Laasphe"
            },
            {
                "value": 38522,
                "label": "Freudenberg",
                "label_ar": "فرويدنبرغ",
                "label_fr": "Freudenberg"
            },
            {
                "value": 38523,
                "label": "Bad Berleburg",
                "label_ar": "باد بيرليبورج",
                "label_fr": "Bad Berleburg"
            },
            {
                "value": 38524,
                "label": "Bergneustadt",
                "label_ar": "بيرجنيوستادت",
                "label_fr": "Bergneustadt"
            },
            {
                "value": 38525,
                "label": "Wenden",
                "label_ar": "ويندين",
                "label_fr": "Wenden"
            },
            {
                "value": 38526,
                "label": "Schnega",
                "label_ar": "شنيجا",
                "label_fr": "Schnega"
            },
            {
                "value": 38527,
                "label": "Bodenteich",
                "label_ar": "بودينتيش",
                "label_fr": "Bodenteich"
            },
            {
                "value": 38528,
                "label": "Ruhen",
                "label_ar": "روهين",
                "label_fr": "Ruhen"
            },
            {
                "value": 38529,
                "label": "Warberg",
                "label_ar": "واربرغ",
                "label_fr": "Warberg"
            },
            {
                "value": 38530,
                "label": "Zernien",
                "label_ar": "زيرنين",
                "label_fr": "Zernien"
            },
            {
                "value": 38531,
                "label": "Neu Darchau",
                "label_ar": "نيو دارشاو",
                "label_fr": "Neu Darchau"
            },
            {
                "value": 38532,
                "label": "Meinersen",
                "label_ar": "مينرسن",
                "label_fr": "Meinersen"
            },
            {
                "value": 38533,
                "label": "Vechelde",
                "label_ar": "فيشلدي",
                "label_fr": "Vechelde"
            },
            {
                "value": 38534,
                "label": "Kissenbruck",
                "label_ar": "كيسنبروك",
                "label_fr": "Kissenbruck"
            },
            {
                "value": 38535,
                "label": "Neetze",
                "label_ar": "نيتزي",
                "label_fr": "Neetze"
            },
            {
                "value": 38536,
                "label": "Wagenhoff",
                "label_ar": "واجنهوف",
                "label_fr": "Wagenhoff"
            },
            {
                "value": 38537,
                "label": "Bottrop",
                "label_ar": "بوتروب",
                "label_fr": "Bottrop"
            },
            {
                "value": 38538,
                "label": "Neukirchen-Vluyn",
                "label_ar": "نويكيرشن فلوين",
                "label_fr": "Neukirchen-Vluyn"
            },
            {
                "value": 38539,
                "label": "Weener",
                "label_ar": "وينر",
                "label_fr": "Weener"
            },
            {
                "value": 38540,
                "label": "Spetzerfehn",
                "label_ar": "سبيتزرفين",
                "label_fr": "Spetzerfehn"
            },
            {
                "value": 38541,
                "label": "Leezdorf",
                "label_ar": "ليزدورف",
                "label_fr": "Leezdorf"
            },
            {
                "value": 38542,
                "label": "Detern",
                "label_ar": "ديترن",
                "label_fr": "Detern"
            },
            {
                "value": 38543,
                "label": "Borkum",
                "label_ar": "بوركوم",
                "label_fr": "Borkum"
            },
            {
                "value": 38544,
                "label": "Leer",
                "label_ar": "لير",
                "label_fr": "Lorgner"
            },
            {
                "value": 38545,
                "label": "Holtland",
                "label_ar": "هولتلاند",
                "label_fr": "Holtland"
            },
            {
                "value": 38546,
                "label": "Firrel",
                "label_ar": "فيريل",
                "label_fr": "Firrel"
            },
            {
                "value": 38547,
                "label": "Aschersleben",
                "label_ar": "Aschersleben",
                "label_fr": "Aschersleben"
            },
            {
                "value": 38548,
                "label": "Jessnitz",
                "label_ar": "جيسنيتز",
                "label_fr": "Jessnitz"
            },
            {
                "value": 38549,
                "label": "Lutzen",
                "label_ar": "لوتزن",
                "label_fr": "Lutzen"
            },
            {
                "value": 38550,
                "label": "Nauendorf",
                "label_ar": "ناويندورف",
                "label_fr": "Nauendorf"
            },
            {
                "value": 38551,
                "label": "Mansfeld",
                "label_ar": "مانسفيلد",
                "label_fr": "Mansfeld"
            },
            {
                "value": 38552,
                "label": "Abberode",
                "label_ar": "أبيرود",
                "label_fr": "Abberode"
            },
            {
                "value": 38553,
                "label": "Petersberg",
                "label_ar": "بيترسبرغ",
                "label_fr": "Petersberg"
            },
            {
                "value": 38554,
                "label": "Jessen",
                "label_ar": "جيسن",
                "label_fr": "Jessen"
            },
            {
                "value": 38555,
                "label": "Weissenfels",
                "label_ar": "فايسنفيلس",
                "label_fr": "Weissenfels"
            },
            {
                "value": 38556,
                "label": "Bad Lauchstadt",
                "label_ar": "Bad Lauchstadt",
                "label_fr": "Bad Lauchstadt"
            },
            {
                "value": 38557,
                "label": "Raguhn",
                "label_ar": "راجون",
                "label_fr": "Raguhn"
            },
            {
                "value": 38558,
                "label": "Schkopau",
                "label_ar": "Schkopau",
                "label_fr": "Schkopau"
            },
            {
                "value": 38559,
                "label": "Köthen",
                "label_ar": "كوثن",
                "label_fr": "Köthen"
            },
            {
                "value": 38560,
                "label": "Wengelsdorf",
                "label_ar": "Wengelsdorf",
                "label_fr": "Wengelsdorf"
            },
            {
                "value": 38561,
                "label": "Bad Bibra",
                "label_ar": "الببرا السيئة",
                "label_fr": "Bad Bibra"
            },
            {
                "value": 38562,
                "label": "Sandersleben",
                "label_ar": "ساندرسليبن",
                "label_fr": "Sandersleben"
            },
            {
                "value": 38563,
                "label": "Wolfen",
                "label_ar": "ولفين",
                "label_fr": "Wolfen"
            },
            {
                "value": 38564,
                "label": "Allstedt",
                "label_ar": "ألستيدت",
                "label_fr": "Allstedt"
            },
            {
                "value": 38565,
                "label": "Grossrinderfeld",
                "label_ar": "غروسريندرفيلد",
                "label_fr": "Grossrinderfeld"
            },
            {
                "value": 38566,
                "label": "Schwegenheim",
                "label_ar": "شويجنهايم",
                "label_fr": "Schwegenheim"
            },
            {
                "value": 38567,
                "label": "Billigheim-Ingenheim",
                "label_ar": "بيليغهايم إنجنهايم",
                "label_fr": "Billigheim-Ingenheim"
            },
            {
                "value": 38568,
                "label": "Hemsbach",
                "label_ar": "همسباخ",
                "label_fr": "Hemsbach"
            },
            {
                "value": 38569,
                "label": "Albersweiler",
                "label_ar": "البرسفايلر",
                "label_fr": "Albersweiler"
            },
            {
                "value": 38570,
                "label": "Steinweiler",
                "label_ar": "شتاينويلر",
                "label_fr": "Steinweiler"
            },
            {
                "value": 38571,
                "label": "Buchen",
                "label_ar": "بوكن",
                "label_fr": "Buchen"
            },
            {
                "value": 38572,
                "label": "Kuhardt",
                "label_ar": "كوهاردت",
                "label_fr": "Kuhardt"
            },
            {
                "value": 38573,
                "label": "Gerolsheim",
                "label_ar": "جيرولشيم",
                "label_fr": "Gerolsheim"
            },
            {
                "value": 38574,
                "label": "Sulzfeld",
                "label_ar": "سولزفيلد",
                "label_fr": "Sulzfeld"
            },
            {
                "value": 38575,
                "label": "Oberviechtach",
                "label_ar": "Oberviechtach",
                "label_fr": "Oberviechtach"
            },
            {
                "value": 38576,
                "label": "Schwarzenbach an der Saale",
                "label_ar": "شوارزنباخ أن دير زال",
                "label_fr": "Schwarzenbach an der Saale"
            },
            {
                "value": 38577,
                "label": "Tannesberg",
                "label_ar": "تانيسبيرج",
                "label_fr": "Tannesberg"
            },
            {
                "value": 38578,
                "label": "Dohlau",
                "label_ar": "دوهلاو",
                "label_fr": "Dohlau"
            },
            {
                "value": 38579,
                "label": "Helmbrechts",
                "label_ar": "هيلمبرختس",
                "label_fr": "Casques"
            },
            {
                "value": 38580,
                "label": "Fichtelberg",
                "label_ar": "فيشتيلبيرج",
                "label_fr": "Fichtelberg"
            },
            {
                "value": 38581,
                "label": "Mehlmeisel",
                "label_ar": "مهلميسل",
                "label_fr": "Mehlmeisel"
            },
            {
                "value": 38582,
                "label": "Hohenberg",
                "label_ar": "هوهنبرج",
                "label_fr": "Hohenberg"
            },
            {
                "value": 38583,
                "label": "Thierstein",
                "label_ar": "ثيرشتاين",
                "label_fr": "Thierstein"
            },
            {
                "value": 38584,
                "label": "Marsberg",
                "label_ar": "مارسبرغ",
                "label_fr": "Marsberg"
            },
            {
                "value": 38585,
                "label": "Anrochte",
                "label_ar": "أنروشتي",
                "label_fr": "Anrochte"
            },
            {
                "value": 38586,
                "label": "Schotten",
                "label_ar": "شوتين",
                "label_fr": "Schotten"
            },
            {
                "value": 38587,
                "label": "Bad Harzburg",
                "label_ar": "باد هاغزبورغ",
                "label_fr": "Bad Harzburg"
            },
            {
                "value": 38588,
                "label": "Liebenburg",
                "label_ar": "ليبنبورغ",
                "label_fr": "Liebenburg"
            },
            {
                "value": 38589,
                "label": "Steinlah",
                "label_ar": "ستينلاه",
                "label_fr": "Steinlah"
            },
            {
                "value": 38590,
                "label": "Ebersberg",
                "label_ar": "إبيرسبيرغ",
                "label_fr": "Ebersberg"
            },
            {
                "value": 38591,
                "label": "Vaterstetten",
                "label_ar": "فاترستيتن",
                "label_fr": "Vaterstetten"
            },
            {
                "value": 38592,
                "label": "Garching bei Munchen",
                "label_ar": "جارشينج باي ميونخ",
                "label_fr": "Garching bei Munchen"
            },
            {
                "value": 38593,
                "label": "Schondorf am Ammersee",
                "label_ar": "شوندورف ام اميرسي",
                "label_fr": "Schondorf am Ammersee"
            },
            {
                "value": 38594,
                "label": "Grasbrunn",
                "label_ar": "جراسبرون",
                "label_fr": "Grasbrunn"
            },
            {
                "value": 38595,
                "label": "Feldkirchen",
                "label_ar": "فيلدكيرشن",
                "label_fr": "Feldkirchen"
            },
            {
                "value": 38596,
                "label": "Brunnthal",
                "label_ar": "برونثال",
                "label_fr": "Brunnthal"
            },
            {
                "value": 38597,
                "label": "Oberschleissheim",
                "label_ar": "Oberschleissheim",
                "label_fr": "Oberschleissheim"
            },
            {
                "value": 38598,
                "label": "Giessen",
                "label_ar": "جيسين",
                "label_fr": "Giessen"
            },
            {
                "value": 38599,
                "label": "Lich",
                "label_ar": "ليش",
                "label_fr": "Liche"
            },
            {
                "value": 38600,
                "label": "Hessen",
                "label_ar": "هيسن",
                "label_fr": "Hesse"
            },
            {
                "value": 38601,
                "label": "Seeheim-Jugenheim",
                "label_ar": "سيهايم يوجنهايم",
                "label_fr": "Seeheim-Jugenheim"
            },
            {
                "value": 38602,
                "label": "Pfungstadt",
                "label_ar": "بفونغشتات",
                "label_fr": "Pfungstadt"
            },
            {
                "value": 38603,
                "label": "Erzhausen",
                "label_ar": "ارزهاوزن",
                "label_fr": "Erzhausen"
            },
            {
                "value": 38604,
                "label": "Griesheim",
                "label_ar": "جريشيم",
                "label_fr": "Griesheim"
            },
            {
                "value": 38605,
                "label": "Bendorf",
                "label_ar": "بيندورف",
                "label_fr": "Bendorf"
            },
            {
                "value": 38606,
                "label": "Lahnstein",
                "label_ar": "لانشتاين",
                "label_fr": "Lahnstein"
            },
            {
                "value": 38607,
                "label": "Hohr-Grenzhausen",
                "label_ar": "Hohr-Grenzhausen",
                "label_fr": "Hohr-Grenzhausen"
            },
            {
                "value": 38608,
                "label": "Emmelshausen",
                "label_ar": "إيملسهاوزن",
                "label_fr": "Emmelshausen"
            },
            {
                "value": 38609,
                "label": "Heuchelheim",
                "label_ar": "هيوتشيلهايم",
                "label_fr": "Heuchelheim"
            },
            {
                "value": 38610,
                "label": "Lollar",
                "label_ar": "Lollar",
                "label_fr": "Lollar"
            },
            {
                "value": 38611,
                "label": "Bad Nauheim",
                "label_ar": "باد ناوهايم",
                "label_fr": "Bad Nauheim"
            },
            {
                "value": 38612,
                "label": "Staufenberg",
                "label_ar": "شتاوفنبرغ",
                "label_fr": "Staufenberg"
            },
            {
                "value": 38613,
                "label": "Marktheidenfeld",
                "label_ar": "ماركثيدينفيلد",
                "label_fr": "Marktheidenfeld"
            },
            {
                "value": 38614,
                "label": "Bad Neustadt an der Saale",
                "label_ar": "Bad Neustadt an der Saale",
                "label_fr": "Bad Neustadt an der Saale"
            },
            {
                "value": 38615,
                "label": "Niederwerrn",
                "label_ar": "Niederwerrn",
                "label_fr": "Niederwerrn"
            },
            {
                "value": 38616,
                "label": "Sennfeld",
                "label_ar": "سينفيلد",
                "label_fr": "Sennfeld"
            },
            {
                "value": 38617,
                "label": "Zellingen",
                "label_ar": "زيلينجن",
                "label_fr": "Zellingen"
            },
            {
                "value": 38618,
                "label": "Hochberg",
                "label_ar": "هوشبيرج",
                "label_fr": "Hochberg"
            },
            {
                "value": 38619,
                "label": "Bad Kissingen",
                "label_ar": "كيسينجين السيئ",
                "label_fr": "Bad Kissingen"
            },
            {
                "value": 38620,
                "label": "Fuchsstadt",
                "label_ar": "فوششتات",
                "label_fr": "Fuchsstadt"
            },
            {
                "value": 38621,
                "label": "Leinach",
                "label_ar": "ليناخ",
                "label_fr": "Leinach"
            },
            {
                "value": 38622,
                "label": "Geldersheim",
                "label_ar": "جيلدرشيم",
                "label_fr": "Geldersheim"
            },
            {
                "value": 38623,
                "label": "Schwalbach am Taunus",
                "label_ar": "شوالباخ آم تاونوس",
                "label_fr": "Schwalbach am Taunus"
            },
            {
                "value": 38624,
                "label": "Kronberg",
                "label_ar": "كرونبرج",
                "label_fr": "Kronberg"
            },
            {
                "value": 38625,
                "label": "Raunheim",
                "label_ar": "راونهايم",
                "label_fr": "Raunheim"
            },
            {
                "value": 38626,
                "label": "Ostheim",
                "label_ar": "أوستيم",
                "label_fr": "Ostheim"
            },
            {
                "value": 38627,
                "label": "Vallendar",
                "label_ar": "فاليندار",
                "label_fr": "Vallendar"
            },
            {
                "value": 38628,
                "label": "Albig",
                "label_ar": "ألبيج",
                "label_fr": "Albig"
            },
            {
                "value": 38629,
                "label": "Bruchkobel",
                "label_ar": "Bruchkobel",
                "label_fr": "Bruchkobel"
            },
            {
                "value": 38630,
                "label": "Veitshochheim",
                "label_ar": "فيتشوتشهايم",
                "label_fr": "Veitshochheim"
            },
            {
                "value": 38631,
                "label": "Neu-Anspach",
                "label_ar": "نيو أنسباش",
                "label_fr": "Neu-Anspach"
            },
            {
                "value": 38632,
                "label": "Grossostheim",
                "label_ar": "جروسوستيم",
                "label_fr": "Grossostheim"
            },
            {
                "value": 38633,
                "label": "Nidderau",
                "label_ar": "نيدراو",
                "label_fr": "Nidderau"
            },
            {
                "value": 38634,
                "label": "Sulzbach am Main",
                "label_ar": "سولزباخ ام ماين",
                "label_fr": "Sulzbach am Main"
            },
            {
                "value": 38635,
                "label": "Kriftel",
                "label_ar": "Kriftel",
                "label_fr": "Kriftel"
            },
            {
                "value": 38636,
                "label": "Erlensee",
                "label_ar": "إرلينسي",
                "label_fr": "Erlensee"
            },
            {
                "value": 38637,
                "label": "Momlingen",
                "label_ar": "موملينجين",
                "label_fr": "Momlingen"
            },
            {
                "value": 38638,
                "label": "Gross-Umstadt",
                "label_ar": "جروس أومشتات",
                "label_fr": "Gross-Umstadt"
            },
            {
                "value": 38639,
                "label": "Lorsch",
                "label_ar": "لورش",
                "label_fr": "Lorsch"
            },
            {
                "value": 38640,
                "label": "Ober-Ramstadt",
                "label_ar": "اوبر رامشتات",
                "label_fr": "Ober-Ramstadt"
            },
            {
                "value": 38641,
                "label": "Grosswallstadt",
                "label_ar": "غروسوالشتات",
                "label_fr": "Grosswallstadt"
            },
            {
                "value": 38642,
                "label": "Reinheim",
                "label_ar": "رينهايم",
                "label_fr": "Reinheim"
            },
            {
                "value": 38643,
                "label": "Morlenbach",
                "label_ar": "مورلينباخ",
                "label_fr": "Morlenbach"
            },
            {
                "value": 38644,
                "label": "Gross-Bieberau",
                "label_ar": "جروس بيبيراو",
                "label_fr": "Gross-Bieberau"
            },
            {
                "value": 38645,
                "label": "Rodgau",
                "label_ar": "رودجاو",
                "label_fr": "Rodgau"
            },
            {
                "value": 38646,
                "label": "Bensheim",
                "label_ar": "بنشيم",
                "label_fr": "Bensheim"
            },
            {
                "value": 38647,
                "label": "Zwingenberg",
                "label_ar": "زوينجنبرج",
                "label_fr": "Zwingenberg"
            },
            {
                "value": 38648,
                "label": "Gross-Gerau",
                "label_ar": "جروس جيراو",
                "label_fr": "Gross-Gerau"
            },
            {
                "value": 38649,
                "label": "Richen",
                "label_ar": "ثري",
                "label_fr": "Richen"
            },
            {
                "value": 38650,
                "label": "Sulzbach",
                "label_ar": "سولزباخ",
                "label_fr": "Sulzbach"
            },
            {
                "value": 38651,
                "label": "Lautersheim",
                "label_ar": "لوترشيم",
                "label_fr": "Lautersheim"
            },
            {
                "value": 38652,
                "label": "Staudt",
                "label_ar": "ستودت",
                "label_fr": "Staudt"
            },
            {
                "value": 38653,
                "label": "Schoneck",
                "label_ar": "شونيك",
                "label_fr": "Schoneck"
            },
            {
                "value": 38654,
                "label": "Niederzeuzheim",
                "label_ar": "نيدرزوزهايم",
                "label_fr": "Niederzeuzheim"
            },
            {
                "value": 38655,
                "label": "Andernach",
                "label_ar": "أندرناخ",
                "label_fr": "Andernach"
            },
            {
                "value": 38656,
                "label": "Langenselbold",
                "label_ar": "لانجينسيلبولد",
                "label_fr": "Langenselbold"
            },
            {
                "value": 38657,
                "label": "Buhlertal",
                "label_ar": "بوهلرتال",
                "label_fr": "Buhlertal"
            },
            {
                "value": 38658,
                "label": "Rauenberg",
                "label_ar": "راوينبيرج",
                "label_fr": "Rauenberg"
            },
            {
                "value": 38659,
                "label": "Gaggenau",
                "label_ar": "جاجيناو",
                "label_fr": "Gaggenau"
            },
            {
                "value": 38660,
                "label": "Ostringen",
                "label_ar": "اوسترينجين",
                "label_fr": "Ostringen"
            },
            {
                "value": 38661,
                "label": "Ubstadt-Weiher",
                "label_ar": "Ubstadt-Weiher",
                "label_fr": "Ubstadt-Weiher"
            },
            {
                "value": 38662,
                "label": "Eningen unter Achalm",
                "label_ar": "Eningen unter Achalm",
                "label_fr": "Eningen unter Achalm"
            },
            {
                "value": 38663,
                "label": "Neckartailfingen",
                "label_ar": "نيكارتيلفينجين",
                "label_fr": "Neckartailfingen"
            },
            {
                "value": 38664,
                "label": "Dagersheim",
                "label_ar": "داجرسهايم",
                "label_fr": "Dagersheim"
            },
            {
                "value": 38665,
                "label": "Meckenbeuren",
                "label_ar": "ميكنبورن",
                "label_fr": "Meckenbeuren"
            },
            {
                "value": 38666,
                "label": "Gottmadingen",
                "label_ar": "Gottmadingen",
                "label_fr": "Gottmadingen"
            },
            {
                "value": 38667,
                "label": "Cleebronn",
                "label_ar": "كليبرون",
                "label_fr": "Cleebronn"
            },
            {
                "value": 38668,
                "label": "Lauffen am Neckar",
                "label_ar": "لافين ام نيكار",
                "label_fr": "Lauffen am Neckar"
            },
            {
                "value": 38669,
                "label": "Schonborn",
                "label_ar": "شونبورن",
                "label_fr": "Schonborn"
            },
            {
                "value": 38670,
                "label": "Gundelfingen",
                "label_ar": "غوندلفينجين",
                "label_fr": "Gundelfingen"
            },
            {
                "value": 38671,
                "label": "Waldenbuch",
                "label_ar": "والدنبوخ",
                "label_fr": "Waldenbuch"
            },
            {
                "value": 38672,
                "label": "Odenheim",
                "label_ar": "اودينهايم",
                "label_fr": "Odenheim"
            },
            {
                "value": 38673,
                "label": "Sinzheim",
                "label_ar": "سينزهايم",
                "label_fr": "Sinzheim"
            },
            {
                "value": 38674,
                "label": "Bretten",
                "label_ar": "بريتن",
                "label_fr": "Bretten"
            },
            {
                "value": 38675,
                "label": "Muhlhausen",
                "label_ar": "مولهاوزن",
                "label_fr": "Muhlhausen"
            },
            {
                "value": 38676,
                "label": "Bischweier",
                "label_ar": "بيشفاير",
                "label_fr": "Bischweier"
            },
            {
                "value": 38677,
                "label": "Rechberghausen",
                "label_ar": "Rechberghausen",
                "label_fr": "Rechberghausen"
            },
            {
                "value": 38678,
                "label": "Ispringen",
                "label_ar": "اسبرينجين",
                "label_fr": "Ispringen"
            },
            {
                "value": 38679,
                "label": "Waldkirch",
                "label_ar": "والدكيرش",
                "label_fr": "Waldkirch"
            },
            {
                "value": 38680,
                "label": "Irsee",
                "label_ar": "ايرسي",
                "label_fr": "Irsee"
            },
            {
                "value": 38681,
                "label": "Schwabmunchen",
                "label_ar": "شوابمنشن",
                "label_fr": "Schwabmunchen"
            },
            {
                "value": 38682,
                "label": "Traunreut",
                "label_ar": "تراونروت",
                "label_fr": "Traunreut"
            },
            {
                "value": 38683,
                "label": "Traunstein",
                "label_ar": "تراونستين",
                "label_fr": "Traunstein"
            },
            {
                "value": 38684,
                "label": "Sulzberg",
                "label_ar": "سولزبيرج",
                "label_fr": "Sulzberg"
            },
            {
                "value": 38685,
                "label": "Sonthofen",
                "label_ar": "سونثوفين",
                "label_fr": "Sonthofen"
            },
            {
                "value": 38686,
                "label": "Durach",
                "label_ar": "دوراش",
                "label_fr": "Durach"
            },
            {
                "value": 38687,
                "label": "Oberstdorf",
                "label_ar": "اوبرستدورف",
                "label_fr": "Oberstdorf"
            },
            {
                "value": 38688,
                "label": "Prutting",
                "label_ar": "بروتنج",
                "label_fr": "Prutting"
            },
            {
                "value": 38689,
                "label": "Germering",
                "label_ar": "جيرمينغ",
                "label_fr": "Germer"
            },
            {
                "value": 38690,
                "label": "Eching",
                "label_ar": "أكل",
                "label_fr": "Eching"
            },
            {
                "value": 38691,
                "label": "Karlsfeld",
                "label_ar": "كارلسفيلد",
                "label_fr": "Karlsfeld"
            },
            {
                "value": 38692,
                "label": "Toging am Inn",
                "label_ar": "توجينج آم إن",
                "label_fr": "Toging am Inn"
            },
            {
                "value": 38693,
                "label": "Bergkirchen",
                "label_ar": "بيرجكيرشن",
                "label_fr": "Bergkirchen"
            },
            {
                "value": 38694,
                "label": "Grafenau",
                "label_ar": "جرافيناو",
                "label_fr": "Grafenau"
            },
            {
                "value": 38695,
                "label": "Aichach",
                "label_ar": "ايشاش",
                "label_fr": "Aichach"
            },
            {
                "value": 38696,
                "label": "Bad Worishofen",
                "label_ar": "سيئة Worishofen",
                "label_fr": "Mauvais Worishofen"
            },
            {
                "value": 38697,
                "label": "Immenstadt im Allgau",
                "label_ar": "Immenstadt im Allgau",
                "label_fr": "Immenstadt im Allgau"
            },
            {
                "value": 38698,
                "label": "Gessertshausen",
                "label_ar": "جيسيرتسهاوزن",
                "label_fr": "Gessertshausen"
            },
            {
                "value": 38699,
                "label": "Marktoberdorf",
                "label_ar": "ماركتوبردورف",
                "label_fr": "Marktoberdorf"
            },
            {
                "value": 38700,
                "label": "Zorneding",
                "label_ar": "زورندينغ",
                "label_fr": "Zorneding"
            },
            {
                "value": 38701,
                "label": "Bad Toelz",
                "label_ar": "باد تويلز",
                "label_fr": "Bad Toelz"
            },
            {
                "value": 38702,
                "label": "Schechen",
                "label_ar": "شيشين",
                "label_fr": "Schechen"
            },
            {
                "value": 38703,
                "label": "Ergolding",
                "label_ar": "إرغولدينغ",
                "label_fr": "Ergolding"
            },
            {
                "value": 38704,
                "label": "Aresing",
                "label_ar": "أريسينج",
                "label_fr": "Aresing"
            },
            {
                "value": 38705,
                "label": "Kirchreit",
                "label_ar": "كيرشريت",
                "label_fr": "Kirchreit"
            },
            {
                "value": 38706,
                "label": "Kissing",
                "label_ar": "تقبيل",
                "label_fr": "Embrasser"
            },
            {
                "value": 38707,
                "label": "Arnstadt",
                "label_ar": "Arnstadt",
                "label_fr": "Arnstadt"
            },
            {
                "value": 38708,
                "label": "Suhl",
                "label_ar": "سوهل",
                "label_fr": "Suhl"
            },
            {
                "value": 38709,
                "label": "Weimar",
                "label_ar": "فايمار",
                "label_fr": "Weimar"
            },
            {
                "value": 38710,
                "label": "Eisenach",
                "label_ar": "أيزناتش",
                "label_fr": "Eisenach"
            },
            {
                "value": 38711,
                "label": "Schmalkalden",
                "label_ar": "Schmalkalden",
                "label_fr": "Schmalkalden"
            },
            {
                "value": 38712,
                "label": "Oschatz",
                "label_ar": "أوشاتز",
                "label_fr": "Oschatz"
            },
            {
                "value": 38713,
                "label": "Markranstadt",
                "label_ar": "ماركرانشتات",
                "label_fr": "Markranstadt"
            },
            {
                "value": 38714,
                "label": "Markkleeberg",
                "label_ar": "مارككليبيرج",
                "label_fr": "Markkleeberg"
            },
            {
                "value": 38715,
                "label": "Taucha",
                "label_ar": "تاوتشا",
                "label_fr": "Taucha"
            },
            {
                "value": 38716,
                "label": "Wurzen",
                "label_ar": "وورزين",
                "label_fr": "Wurzen"
            },
            {
                "value": 38717,
                "label": "Crimmitschau",
                "label_ar": "كريميتشاو",
                "label_fr": "Crimmitschau"
            },
            {
                "value": 38718,
                "label": "Wiedemar",
                "label_ar": "فيديمار",
                "label_fr": "Wiedemar"
            },
            {
                "value": 38719,
                "label": "Schkeuditz",
                "label_ar": "شكوديتز",
                "label_fr": "Schkeuditz"
            },
            {
                "value": 38720,
                "label": "Meerane",
                "label_ar": "ميران",
                "label_fr": "Meerane"
            },
            {
                "value": 38721,
                "label": "Wilkau-Hasslau",
                "label_ar": "ويلكاو هسلو",
                "label_fr": "Wilkau-Hasslau"
            },
            {
                "value": 38722,
                "label": "Radeberg",
                "label_ar": "راديبرج",
                "label_fr": "Radeberg"
            },
            {
                "value": 38723,
                "label": "Meissen",
                "label_ar": "ميسن",
                "label_fr": "Meissen"
            },
            {
                "value": 38724,
                "label": "Schneeberg",
                "label_ar": "شنيبرغ",
                "label_fr": "Schneeberg"
            },
            {
                "value": 38725,
                "label": "Bad Muskau",
                "label_ar": "سيء موسكاو",
                "label_fr": "Bad Muskau"
            },
            {
                "value": 38726,
                "label": "Glücksburg",
                "label_ar": "جلوكسبورغ",
                "label_fr": "Glücksburg"
            },
            {
                "value": 38727,
                "label": "Eutin",
                "label_ar": "يوتين",
                "label_fr": "Eutine"
            },
            {
                "value": 38728,
                "label": "Falkendorf",
                "label_ar": "فالكيندورف",
                "label_fr": "Falkendorf"
            },
            {
                "value": 38729,
                "label": "Busdorf",
                "label_ar": "بوسدورف",
                "label_fr": "Busdorf"
            },
            {
                "value": 38730,
                "label": "Ammerndorf",
                "label_ar": "أمرندورف",
                "label_fr": "Ammerndorf"
            },
            {
                "value": 38731,
                "label": "Rothenbach an der Pegnitz",
                "label_ar": "Rothenbach an der Pegnitz",
                "label_fr": "Rothenbach an der Pegnitz"
            },
            {
                "value": 38732,
                "label": "Kulmbach",
                "label_ar": "كولمباخ",
                "label_fr": "Kulmbach"
            },
            {
                "value": 38733,
                "label": "Mohrendorf",
                "label_ar": "موهرندورف",
                "label_fr": "Mohrendorf"
            },
            {
                "value": 38734,
                "label": "Bischberg",
                "label_ar": "بيشبيرج",
                "label_fr": "Bischberg"
            },
            {
                "value": 38735,
                "label": "Veitsbronn",
                "label_ar": "فيتسبرون",
                "label_fr": "Veitsbronn"
            },
            {
                "value": 38736,
                "label": "Heisede",
                "label_ar": "هايسد",
                "label_fr": "Heisede"
            },
            {
                "value": 38737,
                "label": "Ronnenberg",
                "label_ar": "رونينبيرج",
                "label_fr": "Ronnenberg"
            },
            {
                "value": 38738,
                "label": "Nordstemmen",
                "label_ar": "نوردستمين",
                "label_fr": "Nordstemmen"
            },
            {
                "value": 38739,
                "label": "Vieselbach",
                "label_ar": "فيسيلباخ",
                "label_fr": "Vieselbach"
            },
            {
                "value": 38740,
                "label": "Nordhausen",
                "label_ar": "نوردهاوزن",
                "label_fr": "Nordhausen"
            },
            {
                "value": 38741,
                "label": "Gotha",
                "label_ar": "جوتا",
                "label_fr": "Gotha"
            },
            {
                "value": 38742,
                "label": "Greiz",
                "label_ar": "جريز",
                "label_fr": "Greiz"
            },
            {
                "value": 38743,
                "label": "Possneck",
                "label_ar": "بوسنيك",
                "label_fr": "Possneck"
            },
            {
                "value": 38744,
                "label": "Bucha",
                "label_ar": "بوتشا",
                "label_fr": "Bucha"
            },
            {
                "value": 38745,
                "label": "Soemmerda",
                "label_ar": "سوميردا",
                "label_fr": "Soemmerda"
            },
            {
                "value": 38746,
                "label": "Bad Langensalza",
                "label_ar": "باد لانجنسالزا",
                "label_fr": "Bad Langensalza"
            },
            {
                "value": 38747,
                "label": "Sonneberg",
                "label_ar": "سونبيرج",
                "label_fr": "Sonneberg"
            },
            {
                "value": 38748,
                "label": "Mühlhausen",
                "label_ar": "مولهاوزن",
                "label_fr": "Mühlhausen"
            },
            {
                "value": 38749,
                "label": "Leinefelde",
                "label_ar": "لينفيلد",
                "label_fr": "Leinefelde"
            },
            {
                "value": 38750,
                "label": "Mettingen",
                "label_ar": "ميتينغن",
                "label_fr": "Mettingen"
            },
            {
                "value": 38751,
                "label": "Quakenbruck",
                "label_ar": "Quakenbruck",
                "label_fr": "Quakenbruck"
            },
            {
                "value": 38752,
                "label": "Harsewinkel",
                "label_ar": "هارسوينكل",
                "label_fr": "Harsewinkel"
            },
            {
                "value": 38753,
                "label": "Korbach",
                "label_ar": "كورباخ",
                "label_fr": "Korbach"
            },
            {
                "value": 38754,
                "label": "Buckeburg",
                "label_ar": "باكبيرج",
                "label_fr": "Buckeburg"
            },
            {
                "value": 38755,
                "label": "Rheda-Wiedenbruck",
                "label_ar": "Rheda-Wiedenbruck",
                "label_fr": "Rheda-Wiedenbruck"
            },
            {
                "value": 38756,
                "label": "Ochtrup",
                "label_ar": "أوختروب",
                "label_fr": "Ochtrup"
            },
            {
                "value": 38757,
                "label": "Nordhorn",
                "label_ar": "نوردهورن",
                "label_fr": "Nordhorn"
            },
            {
                "value": 38758,
                "label": "Lotte",
                "label_ar": "لوت",
                "label_fr": "Lotte"
            },
            {
                "value": 38759,
                "label": "Altenbeken",
                "label_ar": "التنبيكن",
                "label_fr": "Altenbeken"
            },
            {
                "value": 38760,
                "label": "Delbruck",
                "label_ar": "ديلبروك",
                "label_fr": "Delbruck"
            },
            {
                "value": 38761,
                "label": "Bad Lippspringe",
                "label_ar": "Bad Lippspringe",
                "label_fr": "Bad Lippspringe"
            },
            {
                "value": 38762,
                "label": "Höxter",
                "label_ar": "هوكستر",
                "label_fr": "Höxter"
            },
            {
                "value": 38763,
                "label": "Bad Salzuflen",
                "label_ar": "باد سالزوفلين",
                "label_fr": "Bad Salzuflen"
            },
            {
                "value": 38764,
                "label": "Rietberg",
                "label_ar": "ريتبرج",
                "label_fr": "Rietberg"
            },
            {
                "value": 38765,
                "label": "Oerlinghausen",
                "label_ar": "أورلينغهاوزن",
                "label_fr": "Oerlinghausen"
            },
            {
                "value": 38766,
                "label": "Versmold",
                "label_ar": "فيرسمولد",
                "label_fr": "Versmold"
            },
            {
                "value": 38767,
                "label": "Rahden",
                "label_ar": "راهدن",
                "label_fr": "Rahden"
            },
            {
                "value": 38768,
                "label": "Calden",
                "label_ar": "كالدن",
                "label_fr": "Calden"
            },
            {
                "value": 38769,
                "label": "Hannoversch Münden",
                "label_ar": "هانوفرش موندن",
                "label_fr": "Hannoversch Münden"
            },
            {
                "value": 38770,
                "label": "Hofgeismar",
                "label_ar": "هوفجيسمار",
                "label_fr": "Hofgeismar"
            },
            {
                "value": 38771,
                "label": "Ibbenbueren",
                "label_ar": "إبينبورين",
                "label_fr": "Ibbenbueren"
            },
            {
                "value": 38772,
                "label": "Lippstadt",
                "label_ar": "يبستات",
                "label_fr": "Lippstadt"
            },
            {
                "value": 38773,
                "label": "Borgholzhausen",
                "label_ar": "بورغولزهاوزن",
                "label_fr": "Borgholzhausen"
            },
            {
                "value": 38774,
                "label": "Neuenkirchen",
                "label_ar": "نوينكيرشن",
                "label_fr": "Neuenkirchen"
            },
            {
                "value": 38775,
                "label": "Hovelhof",
                "label_ar": "هوفلهوف",
                "label_fr": "Hovelhof"
            },
            {
                "value": 38776,
                "label": "Bad Driburg",
                "label_ar": "باد دريبورغ",
                "label_fr": "Bad Driburg"
            },
            {
                "value": 38777,
                "label": "Duderstadt",
                "label_ar": "Duderstadt",
                "label_fr": "Duderstadt"
            },
            {
                "value": 38778,
                "label": "Lingen",
                "label_ar": "لينغن",
                "label_fr": "Lingen"
            },
            {
                "value": 38779,
                "label": "Salzkotten",
                "label_ar": "Salzkotten",
                "label_fr": "Salzkotten"
            },
            {
                "value": 38780,
                "label": "Bovenden",
                "label_ar": "بوفيندين",
                "label_fr": "Bovenden"
            },
            {
                "value": 38781,
                "label": "Nettetal",
                "label_ar": "Nettetal",
                "label_fr": "Nettetal"
            },
            {
                "value": 38782,
                "label": "Wilnsdorf",
                "label_ar": "ويلنسدورف",
                "label_fr": "Wilnsdorf"
            },
            {
                "value": 38783,
                "label": "Pesch",
                "label_ar": "بيش",
                "label_fr": "Pesch"
            },
            {
                "value": 38784,
                "label": "Kalkar",
                "label_ar": "كالكار",
                "label_fr": "Kalkar"
            },
            {
                "value": 38785,
                "label": "Mudersbach",
                "label_ar": "مودرسباخ",
                "label_fr": "Mudersbach"
            },
            {
                "value": 38786,
                "label": "Scheuerfeld",
                "label_ar": "شويرفيلد",
                "label_fr": "Scheuerfeld"
            },
            {
                "value": 38787,
                "label": "Olpe",
                "label_ar": "أولبي",
                "label_fr": "Olpe"
            },
            {
                "value": 38788,
                "label": "Betzdorf",
                "label_ar": "بيتزدورف",
                "label_fr": "Betzdorf"
            },
            {
                "value": 38789,
                "label": "Tonisvorst",
                "label_ar": "تونيسفورست",
                "label_fr": "Tonisvorst"
            },
            {
                "value": 38790,
                "label": "Gevelsberg",
                "label_ar": "جيفيلسبيرغ",
                "label_fr": "Gevelsberg"
            },
            {
                "value": 38791,
                "label": "Heiligenhaus",
                "label_ar": "هيليغنهاوس",
                "label_fr": "Heiligenhaus"
            },
            {
                "value": 38792,
                "label": "Wuelfrath",
                "label_ar": "ولفراث",
                "label_fr": "Wuelfrath"
            },
            {
                "value": 38793,
                "label": "Wetter (Ruhr)",
                "label_ar": "وتر (رور)",
                "label_fr": "Plus humide (Ruhr)"
            },
            {
                "value": 38794,
                "label": "Emmerich",
                "label_ar": "إمريش",
                "label_fr": "Emmerich"
            },
            {
                "value": 38795,
                "label": "Styrum",
                "label_ar": "Styrum",
                "label_fr": "Styrum"
            },
            {
                "value": 38796,
                "label": "Oer-Erkenschwick",
                "label_ar": "Oer-Erkenschwick",
                "label_fr": "Oer-Erkenschwick"
            },
            {
                "value": 38797,
                "label": "Eitorf",
                "label_ar": "إيتورف",
                "label_fr": "Eitorf"
            },
            {
                "value": 38798,
                "label": "Rheinberg",
                "label_ar": "راينبرغ",
                "label_fr": "Rheinberg"
            },
            {
                "value": 38799,
                "label": "Schwalmtal",
                "label_ar": "شوالمتال",
                "label_fr": "Schwalmtal"
            },
            {
                "value": 38800,
                "label": "Wildeshausen",
                "label_ar": "وايلدشاوسين",
                "label_fr": "Wildeshausen"
            },
            {
                "value": 38801,
                "label": "Oyten",
                "label_ar": "أويتن",
                "label_fr": "Oyten"
            },
            {
                "value": 38802,
                "label": "Ritterhude",
                "label_ar": "ريتيرهود",
                "label_fr": "Ritterhude"
            },
            {
                "value": 38803,
                "label": "Siegburg",
                "label_ar": "سيغبورغ",
                "label_fr": "Siegburg"
            },
            {
                "value": 38804,
                "label": "Overath",
                "label_ar": "Overath",
                "label_fr": "Overath"
            },
            {
                "value": 38805,
                "label": "Bad Honnef",
                "label_ar": "باد هونيف",
                "label_fr": "Bad Honnef"
            },
            {
                "value": 38806,
                "label": "Rommerskirchen",
                "label_ar": "رومرسكيرشن",
                "label_fr": "Rommerskirchen"
            },
            {
                "value": 38807,
                "label": "Ubach-Palenberg",
                "label_ar": "أوباخ بالينبيرج",
                "label_fr": "Ubach-Palenberg"
            },
            {
                "value": 38808,
                "label": "Wegberg",
                "label_ar": "Wegberg",
                "label_fr": "Wegberg"
            },
            {
                "value": 38809,
                "label": "Baesweiler",
                "label_ar": "بيسويلر",
                "label_fr": "Baesweiler"
            },
            {
                "value": 38810,
                "label": "Bornheim",
                "label_ar": "بورنهايم",
                "label_fr": "Bornheim"
            },
            {
                "value": 38811,
                "label": "Linnich",
                "label_ar": "لينيتش",
                "label_fr": "Linnich"
            },
            {
                "value": 38812,
                "label": "Hirzlei",
                "label_ar": "هيرزلي",
                "label_fr": "Hirzlei"
            },
            {
                "value": 38813,
                "label": "Neunkirchen",
                "label_ar": "نيونكيرشن",
                "label_fr": "Neunkirchen"
            },
            {
                "value": 38814,
                "label": "Ottweiler",
                "label_ar": "أوتويلر",
                "label_fr": "Ottweiler"
            },
            {
                "value": 38815,
                "label": "Trier",
                "label_ar": "ترير",
                "label_fr": "Trèves"
            },
            {
                "value": 38816,
                "label": "Gillenfeld",
                "label_ar": "جيلينفيلد",
                "label_fr": "Gillenfeld"
            },
            {
                "value": 38817,
                "label": "Wasserliesch",
                "label_ar": "واسيرليش",
                "label_fr": "Wasserliesch"
            },
            {
                "value": 38818,
                "label": "Konz",
                "label_ar": "كونز",
                "label_fr": "Konz"
            },
            {
                "value": 38819,
                "label": "Leeheim",
                "label_ar": "ليهايم",
                "label_fr": "Leeheim"
            },
            {
                "value": 38820,
                "label": "Rossdorf",
                "label_ar": "روسدورف",
                "label_fr": "Rossdorf"
            },
            {
                "value": 38821,
                "label": "Eppertshausen",
                "label_ar": "إبيرتسهاوزن",
                "label_fr": "Eppertshausen"
            },
            {
                "value": 38822,
                "label": "Schaafheim",
                "label_ar": "شافهايم",
                "label_fr": "Schaafheim"
            },
            {
                "value": 38823,
                "label": "Birkenwerder",
                "label_ar": "بيركينفيردر",
                "label_fr": "Birkenwerder"
            },
            {
                "value": 38824,
                "label": "Fredersdorf",
                "label_ar": "فريدرسدورف",
                "label_fr": "Fredersdorf"
            },
            {
                "value": 38825,
                "label": "Eberswalde",
                "label_ar": "إبيرسوالد",
                "label_fr": "Eberswalde"
            },
            {
                "value": 38826,
                "label": "Hennigsdorf",
                "label_ar": "هينيجسدورف",
                "label_fr": "Hennigsdorf"
            },
            {
                "value": 38827,
                "label": "Hohen Neuendorf",
                "label_ar": "هوهن نويندورف",
                "label_fr": "Hohen Neuendorf"
            },
            {
                "value": 38828,
                "label": "Eichwalde",
                "label_ar": "إيشوالد",
                "label_fr": "Eichwalde"
            },
            {
                "value": 38829,
                "label": "Schwedt (Oder)",
                "label_ar": "شويدت (أودر)",
                "label_fr": "Schwedt (Oder)"
            },
            {
                "value": 38830,
                "label": "Finowfurt",
                "label_ar": "فينوفورت",
                "label_fr": "Finowfurt"
            },
            {
                "value": 38831,
                "label": "Buckow",
                "label_ar": "بوكو",
                "label_fr": "Buckow"
            },
            {
                "value": 38832,
                "label": "Ludwigsfelde",
                "label_ar": "لودفيغسفيلد",
                "label_fr": "Ludwigsfelde"
            },
            {
                "value": 38833,
                "label": "Waldsieversdorf",
                "label_ar": "فالدسيفرسدورف",
                "label_fr": "Waldsieversdorf"
            },
            {
                "value": 38834,
                "label": "Rathenow",
                "label_ar": "راثينو",
                "label_fr": "Rathenow"
            },
            {
                "value": 38835,
                "label": "Halberstadt",
                "label_ar": "هالبرشتات",
                "label_fr": "Halberstadt"
            },
            {
                "value": 38836,
                "label": "Obernburg am Main",
                "label_ar": "أوبرنبورغ أم ماين",
                "label_fr": "Obernburg am Main"
            },
            {
                "value": 38837,
                "label": "Gross-Zimmern",
                "label_ar": "جروس زيمرن",
                "label_fr": "Gross-Zimmern"
            },
            {
                "value": 38838,
                "label": "Burgstadt",
                "label_ar": "بورغشتات",
                "label_fr": "Burgstadt"
            },
            {
                "value": 38839,
                "label": "Grunwald",
                "label_ar": "جرونوالد",
                "label_fr": "Grunwald"
            },
            {
                "value": 38840,
                "label": "Bad Neuenahr-Ahrweiler",
                "label_ar": "Bad Neuenahr-Ahrweiler",
                "label_fr": "Bad Neuenahr-Ahrweiler"
            },
            {
                "value": 38841,
                "label": "Hennef",
                "label_ar": "حنيف",
                "label_fr": "Hennef"
            },
            {
                "value": 38842,
                "label": "Nassau",
                "label_ar": "ناسو",
                "label_fr": "Nassau"
            },
            {
                "value": 38843,
                "label": "Remagen",
                "label_ar": "ريماجين",
                "label_fr": "Remagen"
            },
            {
                "value": 38844,
                "label": "Grossheubach",
                "label_ar": "غروشيوباتش",
                "label_fr": "Grossheubach"
            },
            {
                "value": 38845,
                "label": "Wunstorf",
                "label_ar": "Wunstorf",
                "label_fr": "Wunstorf"
            },
            {
                "value": 38846,
                "label": "Hagenburg",
                "label_ar": "هاجنبرج",
                "label_fr": "Hagenburg"
            },
            {
                "value": 38847,
                "label": "Alfeld",
                "label_ar": "ألفيلد",
                "label_fr": "Alfeld"
            },
            {
                "value": 38848,
                "label": "Lindhorst",
                "label_ar": "ليندهورست",
                "label_fr": "Lindhorst"
            },
            {
                "value": 38849,
                "label": "Ilsede",
                "label_ar": "إلسيد",
                "label_fr": "Ilsede"
            },
            {
                "value": 38850,
                "label": "Luhden",
                "label_ar": "لودين",
                "label_fr": "Luhden"
            },
            {
                "value": 38851,
                "label": "Obernkirchen",
                "label_ar": "اوبرنكيرشن",
                "label_fr": "Obernkirchen"
            },
            {
                "value": 38852,
                "label": "Heuerssen",
                "label_ar": "هيويرسن",
                "label_fr": "Heuerssen"
            },
            {
                "value": 38853,
                "label": "Bad Salzdetfurth",
                "label_ar": "Bad Salzdetfurth",
                "label_fr": "Bad Salzdetfurth"
            },
            {
                "value": 38854,
                "label": "Seelze",
                "label_ar": "سيلز",
                "label_fr": "Seelze"
            },
            {
                "value": 38855,
                "label": "Seesen",
                "label_ar": "سيزين",
                "label_fr": "Seesen"
            },
            {
                "value": 38856,
                "label": "Salzgitter-Bad",
                "label_ar": "سالزجيتر باد",
                "label_fr": "Salzgitter-Bad"
            },
            {
                "value": 38857,
                "label": "Sandkrug",
                "label_ar": "ساندكروج",
                "label_fr": "Sandkrug"
            },
            {
                "value": 38858,
                "label": "Burgdorf, Hanover",
                "label_ar": "بورغدورف ، هانوفر",
                "label_fr": "Burgdorf, Hanovre"
            },
            {
                "value": 38859,
                "label": "Wallenhorst",
                "label_ar": "والنهورست",
                "label_fr": "Wallenhorst"
            },
            {
                "value": 38860,
                "label": "Hasbergen",
                "label_ar": "هاسبيرجين",
                "label_fr": "Hasbergen"
            },
            {
                "value": 38861,
                "label": "Norten-Hardenberg",
                "label_ar": "نورتن هاردنبيرج",
                "label_fr": "Norten-Hardenberg"
            },
            {
                "value": 38862,
                "label": "Bad Lauterberg im Harz",
                "label_ar": "Bad Lauterberg im Harz",
                "label_fr": "Bad Lauterberg im Harz"
            },
            {
                "value": 38863,
                "label": "Diekholzen",
                "label_ar": "ديخلزن",
                "label_fr": "Diekholzen"
            },
            {
                "value": 38864,
                "label": "Bad Munder am Deister",
                "label_ar": "باد موندر ام ديستر",
                "label_fr": "Bad Munder am Deister"
            },
            {
                "value": 38865,
                "label": "Walkenried",
                "label_ar": "والكنريد",
                "label_fr": "Walkenried"
            },
            {
                "value": 38866,
                "label": "Bueddenstedt",
                "label_ar": "بويدنشتيدت",
                "label_fr": "Bueddenstedt"
            },
            {
                "value": 38867,
                "label": "Grunenplan",
                "label_ar": "Grunenplan",
                "label_fr": "Grunenplan"
            },
            {
                "value": 38868,
                "label": "Visbek",
                "label_ar": "فيسبيك",
                "label_fr": "Visbek"
            },
            {
                "value": 38869,
                "label": "Lengede",
                "label_ar": "لينجيد",
                "label_fr": "Lengede"
            },
            {
                "value": 38870,
                "label": "Vorden",
                "label_ar": "فوردين",
                "label_fr": "Vorden"
            },
            {
                "value": 38871,
                "label": "Bad Grund",
                "label_ar": "باد جروند",
                "label_fr": "Bad Grund"
            },
            {
                "value": 38872,
                "label": "Burgwedel",
                "label_ar": "بورغويدل",
                "label_fr": "Burgwedel"
            },
            {
                "value": 38873,
                "label": "Bad Nenndorf",
                "label_ar": "باد نيندورف",
                "label_fr": "Bad Nenndorf"
            },
            {
                "value": 38874,
                "label": "Hemmingen",
                "label_ar": "هيمنجين",
                "label_fr": "Hemmingen"
            },
            {
                "value": 38875,
                "label": "Lehre",
                "label_ar": "لير",
                "label_fr": "Lehre"
            },
            {
                "value": 38876,
                "label": "Schoningen",
                "label_ar": "شونينغن",
                "label_fr": "Schoningen"
            },
            {
                "value": 38877,
                "label": "Loheide",
                "label_ar": "لوهيد",
                "label_fr": "Loheide"
            },
            {
                "value": 38878,
                "label": "Walle",
                "label_ar": "وال",
                "label_fr": "Walle"
            },
            {
                "value": 38879,
                "label": "Osterode am Harz",
                "label_ar": "أوستيرود هارتس",
                "label_fr": "Osterode am Harz"
            },
            {
                "value": 38880,
                "label": "Dinklage",
                "label_ar": "دينكلاج",
                "label_fr": "Dinklage"
            },
            {
                "value": 38881,
                "label": "Wardenburg",
                "label_ar": "واردنبرغ",
                "label_fr": "Wardenburg"
            },
            {
                "value": 38882,
                "label": "Weyhe",
                "label_ar": "ويهي",
                "label_fr": "Weyhe"
            },
            {
                "value": 38883,
                "label": "Bodenwerder",
                "label_ar": "Bodenwerder",
                "label_fr": "Bodenwerder"
            },
            {
                "value": 38884,
                "label": "Holzminden",
                "label_ar": "هولزميندن",
                "label_fr": "Holzminden"
            },
            {
                "value": 38885,
                "label": "Stadtoldendorf",
                "label_ar": "ستادتولدندورف",
                "label_fr": "Stadtoldendorf"
            },
            {
                "value": 38886,
                "label": "Steinfeld",
                "label_ar": "شتاينفيلد",
                "label_fr": "Steinfeld"
            },
            {
                "value": 38887,
                "label": "Minsen",
                "label_ar": "مينسن",
                "label_fr": "Minsen"
            },
            {
                "value": 38888,
                "label": "Klausheide",
                "label_ar": "كلاوشيدي",
                "label_fr": "Klausheide"
            },
            {
                "value": 38889,
                "label": "Wietmarschen",
                "label_ar": "فيتمارشين",
                "label_fr": "Wietmarschen"
            },
            {
                "value": 38890,
                "label": "Lemwerder",
                "label_ar": "ليمويردر",
                "label_fr": "Lemwerder"
            },
            {
                "value": 38891,
                "label": "Nudlingen",
                "label_ar": "نودلينجن",
                "label_fr": "Nudlingen"
            },
            {
                "value": 38892,
                "label": "Ostrhauderfehn",
                "label_ar": "Ostrhauderfehn",
                "label_fr": "Ostrhauderfehn"
            },
            {
                "value": 38893,
                "label": "Dissen",
                "label_ar": "ديسن",
                "label_fr": "Dissen"
            },
            {
                "value": 38894,
                "label": "Rattelsdorf",
                "label_ar": "راتيلسدورف",
                "label_fr": "Rattelsdorf"
            },
            {
                "value": 38895,
                "label": "Neubiberg",
                "label_ar": "نوبيبرج",
                "label_fr": "Neubiberg"
            },
            {
                "value": 38896,
                "label": "Assling",
                "label_ar": "أسلينغ",
                "label_fr": "Assling"
            },
            {
                "value": 38897,
                "label": "Obertrubach",
                "label_ar": "أوبيرتروباخ",
                "label_fr": "Obertrubach"
            },
            {
                "value": 38898,
                "label": "Schnabelwaid",
                "label_ar": "شنابلويد",
                "label_fr": "Schnabelwaid"
            },
            {
                "value": 38899,
                "label": "Putzbrunn",
                "label_ar": "بوتزبرون",
                "label_fr": "Putzbrunn"
            },
            {
                "value": 38900,
                "label": "Gmund am Tegernsee",
                "label_ar": "جموند ام تيغرنسي",
                "label_fr": "Gmund am Tegernsee"
            },
            {
                "value": 38901,
                "label": "Isen",
                "label_ar": "ايسن",
                "label_fr": "Isen"
            },
            {
                "value": 38902,
                "label": "Wittislingen",
                "label_ar": "ويتيسلينجن",
                "label_fr": "Wittislingen"
            },
            {
                "value": 38903,
                "label": "Aindling",
                "label_ar": "Aindling",
                "label_fr": "Aindling"
            },
            {
                "value": 38904,
                "label": "Langenmosen",
                "label_ar": "لانغنموزين",
                "label_fr": "Langenmosen"
            },
            {
                "value": 38905,
                "label": "Konigsdorf",
                "label_ar": "كونيجسدورف",
                "label_fr": "Königsdorf"
            },
            {
                "value": 38906,
                "label": "Eckersdorf",
                "label_ar": "إكرسدورف",
                "label_fr": "Eckersdorf"
            },
            {
                "value": 38907,
                "label": "Glonn",
                "label_ar": "جلون",
                "label_fr": "Glonn"
            },
            {
                "value": 38908,
                "label": "Schonbrunn",
                "label_ar": "شونبرون",
                "label_fr": "Schonbrunn"
            },
            {
                "value": 38909,
                "label": "Hassmersheim",
                "label_ar": "هاسميرشيم",
                "label_fr": "Hassmersheim"
            },
            {
                "value": 38910,
                "label": "Winden",
                "label_ar": "ويندين",
                "label_fr": "Winden"
            },
            {
                "value": 38911,
                "label": "Neufra",
                "label_ar": "نيوفرا",
                "label_fr": "Neufra"
            },
            {
                "value": 38912,
                "label": "Erlabrunn",
                "label_ar": "إرلابرون",
                "label_fr": "Erlabrunn"
            },
            {
                "value": 38913,
                "label": "Himmelstadt",
                "label_ar": "هيميلشتات",
                "label_fr": "Himmelstadt"
            },
            {
                "value": 38914,
                "label": "Guntersleben",
                "label_ar": "Guntersleben",
                "label_fr": "Guntersleben"
            },
            {
                "value": 38915,
                "label": "Leidersbach",
                "label_ar": "لايدرسباخ",
                "label_fr": "Leidersbach"
            },
            {
                "value": 38916,
                "label": "Barth",
                "label_ar": "بارث",
                "label_fr": "Barth"
            },
            {
                "value": 38917,
                "label": "Poppenlauer",
                "label_ar": "بوبينلاور",
                "label_fr": "Poppenlauer"
            },
            {
                "value": 38918,
                "label": "Munnerstadt",
                "label_ar": "مونرشتات",
                "label_fr": "Munnerstadt"
            },
            {
                "value": 38919,
                "label": "Bitterfeld-Wolfen",
                "label_ar": "بيترفيلد وولفن",
                "label_fr": "Bitterfeld-Wolfen"
            },
            {
                "value": 38920,
                "label": "Friedrichsthal",
                "label_ar": "فريدريشستال",
                "label_fr": "Friedrichsthal"
            },
            {
                "value": 38921,
                "label": "Stassfurt",
                "label_ar": "ستاسفورت",
                "label_fr": "Stassfurt"
            },
            {
                "value": 38922,
                "label": "Malsch",
                "label_ar": "مالش",
                "label_fr": "Malsch"
            },
            {
                "value": 38923,
                "label": "Dettighofen",
                "label_ar": "ديتيجوفين",
                "label_fr": "Dettighofen"
            },
            {
                "value": 38924,
                "label": "Stendal",
                "label_ar": "ستندال",
                "label_fr": "Stendal"
            },
            {
                "value": 38925,
                "label": "Besitz",
                "label_ar": "بيسيتز",
                "label_fr": "Besitz"
            },
            {
                "value": 38926,
                "label": "Werther",
                "label_ar": "ويرثر",
                "label_fr": "Werther"
            },
            {
                "value": 38927,
                "label": "Dettelbach",
                "label_ar": "ديتيلباخ",
                "label_fr": "Dettelbach"
            },
            {
                "value": 38928,
                "label": "Untereuerheim",
                "label_ar": "Untereuerheim",
                "label_fr": "Untereuerheim"
            },
            {
                "value": 38929,
                "label": "Werneck",
                "label_ar": "ويرنيك",
                "label_fr": "Werneck"
            },
            {
                "value": 38930,
                "label": "Atteln",
                "label_ar": "أتيلن",
                "label_fr": "Atteln"
            },
            {
                "value": 38931,
                "label": "Halle",
                "label_ar": "هالي",
                "label_fr": "Halle"
            },
            {
                "value": 38932,
                "label": "Billerbeck",
                "label_ar": "بيلربك",
                "label_fr": "Billerbeck"
            },
            {
                "value": 38933,
                "label": "Stadtlohn",
                "label_ar": "ستاتلون",
                "label_fr": "Stadtlohn"
            },
            {
                "value": 38934,
                "label": "Steinfurt",
                "label_ar": "شتاينفورت",
                "label_fr": "Steinfurt"
            },
            {
                "value": 38935,
                "label": "Coesfeld",
                "label_ar": "كوزفيلد",
                "label_fr": "Coesfeld"
            },
            {
                "value": 38936,
                "label": "Issum",
                "label_ar": "العدد",
                "label_fr": "Issum"
            },
            {
                "value": 38937,
                "label": "Vreden",
                "label_ar": "فريدن",
                "label_fr": "Vreden"
            },
            {
                "value": 38938,
                "label": "Fritzlar",
                "label_ar": "فريتزلار",
                "label_fr": "Fritzlar"
            },
            {
                "value": 38939,
                "label": "Hessisch Lichtenau",
                "label_ar": "Hessisch Lichtenau",
                "label_fr": "Hessisch Lichtenau"
            },
            {
                "value": 38940,
                "label": "Morshausen",
                "label_ar": "مورشوزن",
                "label_fr": "Morshausen"
            },
            {
                "value": 38941,
                "label": "Porta Westfalica",
                "label_ar": "بورتا ويستفاليكا",
                "label_fr": "Porta Westfalica"
            },
            {
                "value": 38942,
                "label": "Havixbeck",
                "label_ar": "هافيكسبيك",
                "label_fr": "Havixbeck"
            },
            {
                "value": 38943,
                "label": "Westerkappeln",
                "label_ar": "Westerkappeln",
                "label_fr": "Westerkappeln"
            },
            {
                "value": 38944,
                "label": "Menslage",
                "label_ar": "مينسلاج",
                "label_fr": "Menslage"
            },
            {
                "value": 38945,
                "label": "Emsdetten",
                "label_ar": "Emsdetten",
                "label_fr": "Emsdetten"
            },
            {
                "value": 38946,
                "label": "Gescher",
                "label_ar": "جيشر",
                "label_fr": "Gescher"
            },
            {
                "value": 38947,
                "label": "Rhede",
                "label_ar": "ريدي",
                "label_fr": "Rhede"
            },
            {
                "value": 38948,
                "label": "Eltville",
                "label_ar": "إلتفيل",
                "label_fr": "Eltville"
            },
            {
                "value": 38949,
                "label": "Saint Egidien",
                "label_ar": "القديس إجيديان",
                "label_fr": "Saint Egidien"
            },
            {
                "value": 38950,
                "label": "Dohna",
                "label_ar": "دهنا",
                "label_fr": "Dohna"
            },
            {
                "value": 38951,
                "label": "Forst",
                "label_ar": "فورست",
                "label_fr": "Forst"
            },
            {
                "value": 38952,
                "label": "Gornsdorf",
                "label_ar": "جورنسدورف",
                "label_fr": "Gornsdorf"
            },
            {
                "value": 38953,
                "label": "Eppendorf",
                "label_ar": "إيبندورف",
                "label_fr": "Eppendorf"
            },
            {
                "value": 38954,
                "label": "Hohenstein-Ernstthal",
                "label_ar": "هوهنشتاين إرنشتال",
                "label_fr": "Hohenstein-Ernstthal"
            },
            {
                "value": 38955,
                "label": "Grossenhain",
                "label_ar": "جروسينهاين",
                "label_fr": "Grossenhain"
            },
            {
                "value": 38956,
                "label": "Eilenburg",
                "label_ar": "ايلنبورغ",
                "label_fr": "Eilenburg"
            },
            {
                "value": 38957,
                "label": "Oberhermsdorf",
                "label_ar": "اوبرهيرمسدورف",
                "label_fr": "Oberhermsdorf"
            },
            {
                "value": 38958,
                "label": "Bernsdorf",
                "label_ar": "بيرنسدورف",
                "label_fr": "Bernsdorf"
            },
            {
                "value": 38959,
                "label": "Stolpen",
                "label_ar": "مسروق",
                "label_fr": "Stolpen"
            },
            {
                "value": 38960,
                "label": "Winkwitz",
                "label_ar": "وينكويتز",
                "label_fr": "Winkwitz"
            },
            {
                "value": 38961,
                "label": "Meckenheim",
                "label_ar": "ميكنهايم",
                "label_fr": "Meckenheim"
            },
            {
                "value": 38962,
                "label": "Niederkassel",
                "label_ar": "نيدركاسيل",
                "label_fr": "Niederkassel"
            },
            {
                "value": 38963,
                "label": "Weilerswist",
                "label_ar": "Weilerswist",
                "label_fr": "Weilerswist"
            },
            {
                "value": 38964,
                "label": "Burscheid",
                "label_ar": "بورشيد",
                "label_fr": "Burscheid"
            },
            {
                "value": 38965,
                "label": "Sinzig",
                "label_ar": "سينزيغ",
                "label_fr": "Sinzig"
            },
            {
                "value": 38966,
                "label": "Bruggen",
                "label_ar": "بروجن",
                "label_fr": "Bruggen"
            },
            {
                "value": 38967,
                "label": "Langerwehe",
                "label_ar": "لانجيرويهي",
                "label_fr": "Langerwehe"
            },
            {
                "value": 38968,
                "label": "Xanten",
                "label_ar": "زانتين",
                "label_fr": "Xanten"
            },
            {
                "value": 38969,
                "label": "Luchem",
                "label_ar": "لوتشيم",
                "label_fr": "Luchem"
            },
            {
                "value": 38970,
                "label": "Haan",
                "label_ar": "هان",
                "label_fr": "Haan"
            },
            {
                "value": 38971,
                "label": "Barssel",
                "label_ar": "بارسيل",
                "label_fr": "Barssel"
            },
            {
                "value": 38972,
                "label": "Samern",
                "label_ar": "سامرن",
                "label_fr": "Samern"
            },
            {
                "value": 38973,
                "label": "Niedernhausen",
                "label_ar": "نيدرهاوزن",
                "label_fr": "Niedernhausen"
            },
            {
                "value": 38974,
                "label": "Sondershausen",
                "label_ar": "سوندرسهاوزن",
                "label_fr": "Sondershausen"
            },
            {
                "value": 38975,
                "label": "Mylau",
                "label_ar": "ميلو",
                "label_fr": "Mylau"
            },
            {
                "value": 38976,
                "label": "Reichenbach/Vogtland",
                "label_ar": "Reichenbach / Vogtland",
                "label_fr": "Reichenbach / Vogtland"
            },
            {
                "value": 38977,
                "label": "Puttlingen",
                "label_ar": "بوتلينجن",
                "label_fr": "Puttlingen"
            },
            {
                "value": 38978,
                "label": "Eppelborn",
                "label_ar": "ابيلبورن",
                "label_fr": "Eppelborn"
            },
            {
                "value": 38979,
                "label": "Wittenberg",
                "label_ar": "ويتنبرغ",
                "label_fr": "Wittenberg"
            },
            {
                "value": 38980,
                "label": "Merseburg",
                "label_ar": "مرسبورغ",
                "label_fr": "Merseburg"
            },
            {
                "value": 38981,
                "label": "Leuna",
                "label_ar": "ليونا",
                "label_fr": "Leuna"
            },
            {
                "value": 38982,
                "label": "Zeuthen",
                "label_ar": "زيوثن",
                "label_fr": "Zeuthen"
            },
            {
                "value": 38983,
                "label": "Mahlow",
                "label_ar": "ماهلو",
                "label_fr": "Mahlow"
            },
            {
                "value": 38984,
                "label": "Bergfelde",
                "label_ar": "بيرجفيلدي",
                "label_fr": "Bergfelde"
            },
            {
                "value": 38985,
                "label": "Thyrow",
                "label_ar": "رمي",
                "label_fr": "Thyrow"
            },
            {
                "value": 38986,
                "label": "Leisnig",
                "label_ar": "ليسنيج",
                "label_fr": "Leisnig"
            },
            {
                "value": 38987,
                "label": "Schonebeck",
                "label_ar": "شونبيك",
                "label_fr": "Schönebeck"
            },
            {
                "value": 38988,
                "label": "Gnadau",
                "label_ar": "غناداو",
                "label_fr": "Gnadau"
            },
            {
                "value": 38989,
                "label": "Blankenburg",
                "label_ar": "بلانكنبورغ",
                "label_fr": "Blankenburg"
            },
            {
                "value": 38990,
                "label": "Wittstock",
                "label_ar": "ويتستوك",
                "label_fr": "Wittstock"
            },
            {
                "value": 38991,
                "label": "Kremmen",
                "label_ar": "كرمن",
                "label_fr": "Kremmen"
            },
            {
                "value": 38992,
                "label": "Furstenwald",
                "label_ar": "فورستينوالد",
                "label_fr": "Furstenwald"
            },
            {
                "value": 38993,
                "label": "Wusterhausen",
                "label_ar": "Wusterhausen",
                "label_fr": "Wusterhausen"
            },
            {
                "value": 38994,
                "label": "Flothe",
                "label_ar": "فلوث",
                "label_fr": "Flothe"
            },
            {
                "value": 38995,
                "label": "Sehnde",
                "label_ar": "Sehnde",
                "label_fr": "Sehnde"
            },
            {
                "value": 38996,
                "label": "Borgsdorf",
                "label_ar": "بورغسدورف",
                "label_fr": "Borgsdorf"
            },
            {
                "value": 38997,
                "label": "Schulzendorf",
                "label_ar": "شولزيندورف",
                "label_fr": "Schulzendorf"
            },
            {
                "value": 38998,
                "label": "Horst",
                "label_ar": "هورست",
                "label_fr": "Horst"
            },
            {
                "value": 38999,
                "label": "Krauschwitz",
                "label_ar": "كراوشويتز",
                "label_fr": "Krauschwitz"
            },
            {
                "value": 39000,
                "label": "Burkau",
                "label_ar": "بوركاو",
                "label_fr": "Burkau"
            },
            {
                "value": 39001,
                "label": "Wachau",
                "label_ar": "واتشاو",
                "label_fr": "Wachau"
            },
            {
                "value": 39001,
                "label": "Oberlungwitz",
                "label_ar": "أوبرلونجويتز",
                "label_fr": "Oberlungwitz"
            },
            {
                "value": 39002,
                "label": "Lobau",
                "label_ar": "لوباو",
                "label_fr": "Lobau"
            },
            {
                "value": 39003,
                "label": "Cannewitz",
                "label_ar": "كانيويتز",
                "label_fr": "Cannewitz"
            },
            {
                "value": 39004,
                "label": "Lichtentanne",
                "label_ar": "ليشتنتان",
                "label_fr": "Lichtentanne"
            },
            {
                "value": 39005,
                "label": "Uttenreuth",
                "label_ar": "أوتينروث",
                "label_fr": "Uttenreuth"
            },
            {
                "value": 39006,
                "label": "Kosching",
                "label_ar": "كوشينغ",
                "label_fr": "Kosching"
            },
            {
                "value": 39007,
                "label": "Rohrenfels",
                "label_ar": "روهرينفيلس",
                "label_fr": "Rohrenfels"
            },
            {
                "value": 39008,
                "label": "Neunburg vorm Wald",
                "label_ar": "نيونبورغ فورم والد",
                "label_fr": "Neunburg vorm Wald"
            },
            {
                "value": 39009,
                "label": "Langensendelbach",
                "label_ar": "لانجينسيندلباخ",
                "label_fr": "Langensendelbach"
            },
            {
                "value": 39010,
                "label": "Kronach",
                "label_ar": "كروناتش",
                "label_fr": "Kronach"
            },
            {
                "value": 39011,
                "label": "Mainleus",
                "label_ar": "مينليوس",
                "label_fr": "Mainleus"
            },
            {
                "value": 39012,
                "label": "Johanngeorgenstadt",
                "label_ar": "Johanngeorgenstadt",
                "label_fr": "Johanngeorgenstadt"
            },
            {
                "value": 39013,
                "label": "Oberwiesenthal",
                "label_ar": "اوبروييسينثال",
                "label_fr": "Oberwiesenthal"
            },
            {
                "value": 39014,
                "label": "Grunhainichen",
                "label_ar": "Grunhainichen",
                "label_fr": "Grunhainichen"
            },
            {
                "value": 39015,
                "label": "Aue",
                "label_ar": "اوي",
                "label_fr": "Aue"
            },
            {
                "value": 39016,
                "label": "Stollberg",
                "label_ar": "ستولبرغ",
                "label_fr": "Stollberg"
            },
            {
                "value": 39017,
                "label": "Bach",
                "label_ar": "باخ",
                "label_fr": "Bach"
            },
            {
                "value": 39018,
                "label": "Wallerfangen",
                "label_ar": "Wallerfangen",
                "label_fr": "Wallerfangen"
            },
            {
                "value": 39019,
                "label": "Kaisersesch",
                "label_ar": "كايزرسش",
                "label_fr": "Kaisersesch"
            },
            {
                "value": 39020,
                "label": "Eichenzell",
                "label_ar": "ايتشنزيل",
                "label_fr": "Eichenzell"
            },
            {
                "value": 39021,
                "label": "Ehrenfeld",
                "label_ar": "إهرنفيلد",
                "label_fr": "Ehrenfeld"
            },
            {
                "value": 39022,
                "label": "Zollstock",
                "label_ar": "زولستوك",
                "label_fr": "Zollstock"
            },
            {
                "value": 39023,
                "label": "Odenthal",
                "label_ar": "أودنتال",
                "label_fr": "Odenthal"
            },
            {
                "value": 39024,
                "label": "Kendenich",
                "label_ar": "Kendenich",
                "label_fr": "Kendenich"
            },
            {
                "value": 39025,
                "label": "Wachtberg",
                "label_ar": "Wachtberg",
                "label_fr": "Wachtberg"
            },
            {
                "value": 39026,
                "label": "Merzenich",
                "label_ar": "ميرزينيتش",
                "label_fr": "Merzenich"
            },
            {
                "value": 39027,
                "label": "Ensen",
                "label_ar": "إنسن",
                "label_fr": "Ensen"
            },
            {
                "value": 39028,
                "label": "Bayenthal",
                "label_ar": "باينثال",
                "label_fr": "Bayenthal"
            },
            {
                "value": 39029,
                "label": "Wiehl",
                "label_ar": "ويل",
                "label_fr": "Wiehl"
            },
            {
                "value": 39030,
                "label": "Mogglingen",
                "label_ar": "موغلينغن",
                "label_fr": "Mogglingen"
            },
            {
                "value": 39031,
                "label": "Untermunkheim",
                "label_ar": "أونترمونكهايم",
                "label_fr": "Untermunkheim"
            },
            {
                "value": 39032,
                "label": "Magstadt",
                "label_ar": "ماجستادت",
                "label_fr": "Magstadt"
            },
            {
                "value": 39033,
                "label": "Birenbach",
                "label_ar": "بيرنباخ",
                "label_fr": "Birenbach"
            },
            {
                "value": 39034,
                "label": "Pleidelsheim",
                "label_ar": "بليديلشايم",
                "label_fr": "Pleidelsheim"
            },
            {
                "value": 39035,
                "label": "Kirchberg",
                "label_ar": "كيرشبرج",
                "label_fr": "Kirchberg"
            },
            {
                "value": 39036,
                "label": "Hessigheim",
                "label_ar": "هيسيغيم",
                "label_fr": "Hessigheim"
            },
            {
                "value": 39037,
                "label": "Besigheim",
                "label_ar": "بيسيجهايم",
                "label_fr": "Besigheim"
            },
            {
                "value": 39038,
                "label": "Rust",
                "label_ar": "الصدأ",
                "label_fr": "Rouille"
            },
            {
                "value": 39039,
                "label": "Zell am Harmersbach",
                "label_ar": "زيل أم هارمرسباخ",
                "label_fr": "Zell am Harmersbach"
            },
            {
                "value": 39040,
                "label": "Eberbach",
                "label_ar": "إيبرباخ",
                "label_fr": "Eberbach"
            },
            {
                "value": 39041,
                "label": "Neulussheim",
                "label_ar": "نيولوسهايم",
                "label_fr": "Neulussheim"
            },
            {
                "value": 39042,
                "label": "Kronau",
                "label_ar": "كروناو",
                "label_fr": "Kronau"
            },
            {
                "value": 39043,
                "label": "Malsch",
                "label_ar": "مالش",
                "label_fr": "Malsch"
            },
            {
                "value": 39044,
                "label": "Dietingen",
                "label_ar": "ديتينجين",
                "label_fr": "Dietingen"
            },
            {
                "value": 39045,
                "label": "Trossingen",
                "label_ar": "تروسينجين",
                "label_fr": "Trossingen"
            },
            {
                "value": 39046,
                "label": "Vogtsburg",
                "label_ar": "فوجتسبرج",
                "label_fr": "Vogtsburg"
            },
            {
                "value": 39047,
                "label": "Trochtelfingen",
                "label_ar": "Trochtelfingen",
                "label_fr": "Trochtelfingen"
            },
            {
                "value": 39048,
                "label": "Meersburg",
                "label_ar": "ميرسبرغ",
                "label_fr": "Meersburg"
            },
            {
                "value": 39049,
                "label": "Orsingen-Nenzingen",
                "label_ar": "Orsingen-Nenzingen",
                "label_fr": "Orsingen-Nenzingen"
            },
            {
                "value": 39050,
                "label": "Allensbach",
                "label_ar": "Allensbach",
                "label_fr": "Allensbach"
            },
            {
                "value": 39051,
                "label": "Deidesheim",
                "label_ar": "ديديسهايم",
                "label_fr": "Deidesheim"
            },
            {
                "value": 39052,
                "label": "Edingen-Neckarhausen",
                "label_ar": "إدينجن نيكارهاوزن",
                "label_fr": "Edingen-Neckarhausen"
            },
            {
                "value": 39053,
                "label": "Herbrechtingen",
                "label_ar": "Herbrechtingen",
                "label_fr": "Herbrechtingen"
            },
            {
                "value": 39054,
                "label": "Sachsenheim",
                "label_ar": "ساكسنهايم",
                "label_fr": "Sachsenheim"
            },
            {
                "value": 39055,
                "label": "Affalterbach",
                "label_ar": "أفالترباخ",
                "label_fr": "Affalterbach"
            },
            {
                "value": 39056,
                "label": "Neubrunn",
                "label_ar": "نيوبرون",
                "label_fr": "Neubrunn"
            },
            {
                "value": 39057,
                "label": "Schramberg",
                "label_ar": "شرامبرج",
                "label_fr": "Schramberg"
            },
            {
                "value": 39058,
                "label": "Durnau",
                "label_ar": "دورناو",
                "label_fr": "Durnau"
            },
            {
                "value": 39059,
                "label": "Hirschhorn",
                "label_ar": "هيرشورن",
                "label_fr": "Hirschhorn"
            },
            {
                "value": 39060,
                "label": "Waldshut-Tiengen",
                "label_ar": "والدشوت تينغين",
                "label_fr": "Waldshut-Tiengen"
            },
            {
                "value": 39061,
                "label": "Schworstadt",
                "label_ar": "شفورشتات",
                "label_fr": "Schworstadt"
            },
            {
                "value": 39062,
                "label": "Botzingen",
                "label_ar": "بوتسينجين",
                "label_fr": "Botzingen"
            },
            {
                "value": 39063,
                "label": "Oberteuringen",
                "label_ar": "Oberteuringen",
                "label_fr": "Oberteuringen"
            },
            {
                "value": 39064,
                "label": "Hopfingen",
                "label_ar": "هوبفينغن",
                "label_fr": "Hopfingen"
            },
            {
                "value": 39065,
                "label": "Mulfingen",
                "label_ar": "مولفينغين",
                "label_fr": "Mulfingen"
            },
            {
                "value": 39066,
                "label": "Riederich",
                "label_ar": "ريدريش",
                "label_fr": "Riederich"
            },
            {
                "value": 39067,
                "label": "Grosselfingen",
                "label_ar": "Grosselfingen",
                "label_fr": "Grosselfingen"
            },
            {
                "value": 39068,
                "label": "Schonaich",
                "label_ar": "شونايش",
                "label_fr": "Schonaich"
            },
            {
                "value": 39069,
                "label": "Waschenbeuren",
                "label_ar": "Waschenbeuren",
                "label_fr": "Waschenbeuren"
            },
            {
                "value": 39070,
                "label": "Aichelberg",
                "label_ar": "ايشيلبيرج",
                "label_fr": "Aichelberg"
            },
            {
                "value": 39071,
                "label": "Titisee-Neustadt",
                "label_ar": "تيتيسي نويشتات",
                "label_fr": "Titisee-Neustadt"
            },
            {
                "value": 39072,
                "label": "Beimerstetten",
                "label_ar": "Beimerstetten",
                "label_fr": "Beimerstetten"
            },
            {
                "value": 39073,
                "label": "Westerstetten",
                "label_ar": "ويسترستيتن",
                "label_fr": "Westerstetten"
            },
            {
                "value": 39074,
                "label": "Maselheim",
                "label_ar": "ماسيلهايم",
                "label_fr": "Maselheim"
            },
            {
                "value": 39075,
                "label": "Schwendi",
                "label_ar": "شويندي",
                "label_fr": "Schwendi"
            },
            {
                "value": 39076,
                "label": "Dellmensingen",
                "label_ar": "ديلمينسينجين",
                "label_fr": "Dellmensingen"
            },
            {
                "value": 39077,
                "label": "Illerrieden",
                "label_ar": "إيليريدن",
                "label_fr": "Illerrieden"
            },
            {
                "value": 39078,
                "label": "Buch",
                "label_ar": "بوخ",
                "label_fr": "Buch"
            },
            {
                "value": 39079,
                "label": "Moglingen",
                "label_ar": "موغلينغن",
                "label_fr": "Moglingen"
            },
            {
                "value": 39080,
                "label": "Birkenlohe",
                "label_ar": "بيركينلوه",
                "label_fr": "Birkenlohe"
            },
            {
                "value": 39081,
                "label": "Oberkochen",
                "label_ar": "أوبيركوشن",
                "label_fr": "Oberkochen"
            },
            {
                "value": 39082,
                "label": "Forchtenberg",
                "label_ar": "فورتشتنبرغ",
                "label_fr": "Forchtenberg"
            },
            {
                "value": 39083,
                "label": "Markgroningen",
                "label_ar": "ماركجرونينجن",
                "label_fr": "Markgroningen"
            },
            {
                "value": 39084,
                "label": "Erligheim",
                "label_ar": "إرليغهايم",
                "label_fr": "Erligheim"
            },
            {
                "value": 39085,
                "label": "Pfullingen",
                "label_ar": "بفولينجن",
                "label_fr": "Pfullingen"
            },
            {
                "value": 39086,
                "label": "Neckartenzlingen",
                "label_ar": "نيكارتنزلينجين",
                "label_fr": "Neckartenzlingen"
            },
            {
                "value": 39087,
                "label": "Ofterdingen",
                "label_ar": "Ofterdingen",
                "label_fr": "Ofterdingen"
            },
            {
                "value": 39088,
                "label": "Hulben",
                "label_ar": "هولبن",
                "label_fr": "Hulben"
            },
            {
                "value": 39089,
                "label": "Munsingen",
                "label_ar": "مونسينجين",
                "label_fr": "Munsingen"
            },
            {
                "value": 39090,
                "label": "Niedernhall",
                "label_ar": "نيدرنهول",
                "label_fr": "Niedernhall"
            },
            {
                "value": 39091,
                "label": "Straelen",
                "label_ar": "ستريلين",
                "label_fr": "Straelen"
            },
            {
                "value": 39092,
                "label": "Herzebrock",
                "label_ar": "هيرزبروك",
                "label_fr": "Herzebrock"
            },
            {
                "value": 39093,
                "label": "Ludinghausen",
                "label_ar": "لودينغهاوزن",
                "label_fr": "Ludinghausen"
            },
            {
                "value": 39094,
                "label": "Sendenhorst",
                "label_ar": "سيندنهورست",
                "label_fr": "Sendenhorst"
            },
            {
                "value": 39095,
                "label": "Grefrath",
                "label_ar": "جريفراث",
                "label_fr": "Grefrath"
            },
            {
                "value": 39096,
                "label": "Beesten",
                "label_ar": "بيستين",
                "label_fr": "Beesten"
            },
            {
                "value": 39097,
                "label": "Huckelhoven",
                "label_ar": "هاكلهوفن",
                "label_fr": "Huckelhoven"
            },
            {
                "value": 39098,
                "label": "Bilk",
                "label_ar": "بيلك",
                "label_fr": "Blouser"
            },
            {
                "value": 39099,
                "label": "Altenberge",
                "label_ar": "التنبيرج",
                "label_fr": "Altenberge"
            },
            {
                "value": 39100,
                "label": "Schlangen",
                "label_ar": "شلانجين",
                "label_fr": "Schlangen"
            },
            {
                "value": 39101,
                "label": "Ahrensbok",
                "label_ar": "Ahrensbok",
                "label_fr": "Ahrensbok"
            },
            {
                "value": 39102,
                "label": "Nottuln",
                "label_ar": "نوتولن",
                "label_fr": "Nottuln"
            },
            {
                "value": 39103,
                "label": "Herborn",
                "label_ar": "هيربورن",
                "label_fr": "Herborn"
            },
            {
                "value": 39104,
                "label": "Vlotho",
                "label_ar": "فلوتو",
                "label_fr": "Vlotho"
            },
            {
                "value": 39105,
                "label": "Schwelm",
                "label_ar": "شويلم",
                "label_fr": "Schwelm"
            },
            {
                "value": 39106,
                "label": "Schonberg",
                "label_ar": "شونبيرج",
                "label_fr": "Schonberg"
            },
            {
                "value": 39107,
                "label": "Wendelstein",
                "label_ar": "ويندلشتاين",
                "label_fr": "Wendelstein"
            },
            {
                "value": 39108,
                "label": "Lain",
                "label_ar": "لاين",
                "label_fr": "Lain"
            },
            {
                "value": 39109,
                "label": "Gemmingen",
                "label_ar": "جيمينجين",
                "label_fr": "Gemmingen"
            },
            {
                "value": 39110,
                "label": "Gau-Bischofsheim",
                "label_ar": "جاو بيشوفشيم",
                "label_fr": "Gau-Bischofsheim"
            },
            {
                "value": 39111,
                "label": "Nierstein",
                "label_ar": "نيرشتاين",
                "label_fr": "Nierstein"
            },
            {
                "value": 39112,
                "label": "Waibstadt",
                "label_ar": "ويبستدات",
                "label_fr": "Waibstadt"
            },
            {
                "value": 39113,
                "label": "Grosskrotzenburg",
                "label_ar": "جروسكروتزينبرج",
                "label_fr": "Grosskrotzenburg"
            },
            {
                "value": 39114,
                "label": "Buchen",
                "label_ar": "بوكن",
                "label_fr": "Buchen"
            },
            {
                "value": 39115,
                "label": "Ebhausen",
                "label_ar": "ابهاوزن",
                "label_fr": "Ebhausen"
            },
            {
                "value": 39116,
                "label": "Liederbach",
                "label_ar": "ليدرباخ",
                "label_fr": "Liederbach"
            },
            {
                "value": 39117,
                "label": "Waldaschaff",
                "label_ar": "فالداشاف",
                "label_fr": "Waldaschaff"
            },
            {
                "value": 39118,
                "label": "Blankenfelde",
                "label_ar": "بلانكنفيلد",
                "label_fr": "Blankenfelde"
            },
            {
                "value": 39119,
                "label": "Neu Isenburg",
                "label_ar": "نيو إيسنبورغ",
                "label_fr": "Neu Isenburg"
            },
            {
                "value": 39120,
                "label": "Hermsdorf",
                "label_ar": "هيرمسدورف",
                "label_fr": "Hermsdorf"
            },
            {
                "value": 39121,
                "label": "Zella-Mehlis",
                "label_ar": "زيلا ميليس",
                "label_fr": "Zella-Mehlis"
            },
            {
                "value": 39122,
                "label": "Hoppegarten",
                "label_ar": "Hoppegarten",
                "label_fr": "Hoppegarten"
            },
            {
                "value": 39123,
                "label": "Kahla",
                "label_ar": "كحلة",
                "label_fr": "Kahla"
            },
            {
                "value": 39124,
                "label": "Reilingen",
                "label_ar": "رايلينجين",
                "label_fr": "Reilingen"
            },
            {
                "value": 39125,
                "label": "Reinfeld",
                "label_ar": "راينفيلد",
                "label_fr": "Reinfeld"
            },
            {
                "value": 39126,
                "label": "Hemdingen",
                "label_ar": "هيمدينجن",
                "label_fr": "Hemdingen"
            },
            {
                "value": 39127,
                "label": "Bentwisch",
                "label_ar": "بينتويش",
                "label_fr": "Bentwisch"
            },
            {
                "value": 39128,
                "label": "Klein Lichtenhagen",
                "label_ar": "كلاين ليشتنهاغن",
                "label_fr": "Klein Lichtenhagen"
            },
            {
                "value": 39129,
                "label": "Zingst",
                "label_ar": "زينجست",
                "label_fr": "Zingst"
            },
            {
                "value": 39130,
                "label": "Schenefeld",
                "label_ar": "شينفيلد",
                "label_fr": "Schenefeld"
            },
            {
                "value": 39131,
                "label": "Hittbergen",
                "label_ar": "Hittbergen",
                "label_fr": "Hittbergen"
            },
            {
                "value": 39132,
                "label": "Doberlug-Kirchhain",
                "label_ar": "دوبرلوج كيرشين",
                "label_fr": "Doberlug-Kirchhain"
            },
            {
                "value": 39133,
                "label": "Hohenwarthe",
                "label_ar": "هوهينوارث",
                "label_fr": "Hohenwarthe"
            },
            {
                "value": 39134,
                "label": "Lietzen",
                "label_ar": "ليتزن",
                "label_fr": "Lietzen"
            },
            {
                "value": 39135,
                "label": "Burg Stargard",
                "label_ar": "بورج ستارغارد",
                "label_fr": "Burg Stargard"
            },
            {
                "value": 39136,
                "label": "Treuenbrietzen",
                "label_ar": "Treuenbrietzen",
                "label_fr": "Treuenbrietzen"
            },
            {
                "value": 39137,
                "label": "Wolmirstedt",
                "label_ar": "Wolmirstedt",
                "label_fr": "Wolmirstedt"
            },
            {
                "value": 39138,
                "label": "Linden",
                "label_ar": "الزيزفون",
                "label_fr": "Tilleul"
            },
            {
                "value": 39139,
                "label": "Weiterstadt",
                "label_ar": "Weiterstadt",
                "label_fr": "Weiterstadt"
            },
            {
                "value": 39140,
                "label": "Holte",
                "label_ar": "هولت",
                "label_fr": "Holte"
            },
            {
                "value": 39141,
                "label": "Green",
                "label_ar": "أخضر",
                "label_fr": "vert"
            },
            {
                "value": 39142,
                "label": "Gendorf",
                "label_ar": "جندورف",
                "label_fr": "Gendorf"
            },
            {
                "value": 39143,
                "label": "Winnen",
                "label_ar": "وينين",
                "label_fr": "Winnen"
            },
            {
                "value": 39144,
                "label": "Wenzenbach",
                "label_ar": "Wenzenbach",
                "label_fr": "Wenzenbach"
            },
            {
                "value": 39145,
                "label": "Geiselhoring",
                "label_ar": "Geiselhoring",
                "label_fr": "Geiselhoring"
            },
            {
                "value": 39146,
                "label": "Bad Abbach",
                "label_ar": "باد اباخ",
                "label_fr": "Bad Abbach"
            },
            {
                "value": 39147,
                "label": "Pentling",
                "label_ar": "بنتلينج",
                "label_fr": "Pentling"
            },
            {
                "value": 39148,
                "label": "Pfatter",
                "label_ar": "بفاتر",
                "label_fr": "Pfatter"
            },
            {
                "value": 39149,
                "label": "Bruck in der Oberpfalz",
                "label_ar": "Bruck in der Oberpfalz",
                "label_fr": "Bruck in der Oberpfalz"
            },
            {
                "value": 39150,
                "label": "Regenstauf",
                "label_ar": "ريجينشتوف",
                "label_fr": "Regenstauf"
            },
            {
                "value": 39151,
                "label": "Neutraubling",
                "label_ar": "نيوتروبلنج",
                "label_fr": "Neutraubling"
            },
            {
                "value": 39152,
                "label": "Sunching",
                "label_ar": "الضرب",
                "label_fr": "Sunching"
            },
            {
                "value": 39153,
                "label": "Stamsried",
                "label_ar": "جربت",
                "label_fr": "Stamsried"
            },
            {
                "value": 39154,
                "label": "Wiesenfelden",
                "label_ar": "Wiesenfelden",
                "label_fr": "Wiesenfelden"
            },
            {
                "value": 39155,
                "label": "Wackersdorf",
                "label_ar": "واكرسدورف",
                "label_fr": "Wackersdorf"
            },
            {
                "value": 39156,
                "label": "Falkenstein",
                "label_ar": "فالكنشتاين",
                "label_fr": "Falkenstein"
            },
            {
                "value": 39157,
                "label": "Zwiesel",
                "label_ar": "زفيزل",
                "label_fr": "Zwiesel"
            },
            {
                "value": 39158,
                "label": "Schwandorf in Bayern",
                "label_ar": "شفاندورف في بايرن ميونيخ",
                "label_fr": "Schwandorf dans le Bayern"
            },
            {
                "value": 39159,
                "label": "Donaustauf",
                "label_ar": "دوناوستوف",
                "label_fr": "Donaustauf"
            },
            {
                "value": 39160,
                "label": "Schrozberg",
                "label_ar": "شروزبرغ",
                "label_fr": "Schrozberg"
            },
            {
                "value": 39161,
                "label": "Eggesin",
                "label_ar": "إيجسين",
                "label_fr": "Eggesin"
            },
            {
                "value": 39162,
                "label": "Altentreptow",
                "label_ar": "Altentreptow",
                "label_fr": "Altentreptow"
            },
            {
                "value": 39163,
                "label": "Prenzlau",
                "label_ar": "برينزلو",
                "label_fr": "Prenzlau"
            },
            {
                "value": 39164,
                "label": "Friedland",
                "label_ar": "فريدلاند",
                "label_fr": "Friedland"
            },
            {
                "value": 39165,
                "label": "Rangsdorf",
                "label_ar": "رانجسدورف",
                "label_fr": "Rangsdorf"
            },
            {
                "value": 39166,
                "label": "Fahrland",
                "label_ar": "فهرلاند",
                "label_fr": "Fahrland"
            },
            {
                "value": 39167,
                "label": "Pritzwalk",
                "label_ar": "بريتزواك",
                "label_fr": "Pritzwalk"
            },
            {
                "value": 39168,
                "label": "Freckenhorst",
                "label_ar": "فريكينهورست",
                "label_fr": "Freckenhorst"
            },
            {
                "value": 39169,
                "label": "Ascheberg",
                "label_ar": "أشيبيرغ",
                "label_fr": "Ascheberg"
            },
            {
                "value": 39170,
                "label": "Salzbergen",
                "label_ar": "Salzbergen",
                "label_fr": "Salzbergen"
            },
            {
                "value": 39171,
                "label": "Worthsee",
                "label_ar": "ورثسي",
                "label_fr": "Worthsee"
            },
            {
                "value": 39172,
                "label": "Strasslach-Dingharting",
                "label_ar": "Strasslach-Dingharting",
                "label_fr": "Strasslach-Dingharting"
            },
            {
                "value": 39173,
                "label": "Raisting",
                "label_ar": "رفع",
                "label_fr": "Raisting"
            },
            {
                "value": 39174,
                "label": "Windach",
                "label_ar": "Windach",
                "label_fr": "Windach"
            },
            {
                "value": 39175,
                "label": "Diessen am Ammersee",
                "label_ar": "ديسن ام اميرسي",
                "label_fr": "Diessen am Ammersee"
            },
            {
                "value": 39176,
                "label": "Juterbog",
                "label_ar": "جوتيربوج",
                "label_fr": "Juterbog"
            },
            {
                "value": 39177,
                "label": "Kleinmachnow",
                "label_ar": "Kleinmachnow",
                "label_fr": "Kleinmachnow"
            },
            {
                "value": 39178,
                "label": "Baruth",
                "label_ar": "باروث",
                "label_fr": "Baruth"
            },
            {
                "value": 39179,
                "label": "Gittelde",
                "label_ar": "Gittelde",
                "label_fr": "Gittelde"
            },
            {
                "value": 39180,
                "label": "Hehlen",
                "label_ar": "هيلين",
                "label_fr": "Hehlen"
            },
            {
                "value": 39181,
                "label": "Badenhausen",
                "label_ar": "بادنهاوزن",
                "label_fr": "Badenhausen"
            },
            {
                "value": 39182,
                "label": "Grossalmerode",
                "label_ar": "جروسالميرود",
                "label_fr": "Grossalmerode"
            },
            {
                "value": 39183,
                "label": "Fuldatal",
                "label_ar": "فولداتال",
                "label_fr": "Fuldatal"
            },
            {
                "value": 39184,
                "label": "Melsungen",
                "label_ar": "ميلسونجن",
                "label_fr": "Melsungen"
            },
            {
                "value": 39185,
                "label": "Guxhagen",
                "label_ar": "Guxhagen",
                "label_fr": "Guxhagen"
            },
            {
                "value": 39186,
                "label": "Klosterhauser",
                "label_ar": "كلوسترهاوزر",
                "label_fr": "Klosterhauser"
            },
            {
                "value": 39187,
                "label": "Belzig",
                "label_ar": "بلزيج",
                "label_fr": "Belzig"
            },
            {
                "value": 39188,
                "label": "Ziesar",
                "label_ar": "زيسار",
                "label_fr": "Ziesar"
            },
            {
                "value": 39189,
                "label": "Biesenthal",
                "label_ar": "بيسينثال",
                "label_fr": "Biesenthal"
            },
            {
                "value": 39190,
                "label": "Zechlin Dorf",
                "label_ar": "زيكلين دورف",
                "label_fr": "Zechlin Dorf"
            },
            {
                "value": 39191,
                "label": "Leegebruch",
                "label_ar": "ليجبروخ",
                "label_fr": "Leegebruch"
            },
            {
                "value": 39192,
                "label": "Schildow",
                "label_ar": "شيلدو",
                "label_fr": "Schildow"
            },
            {
                "value": 39193,
                "label": "Glienicke",
                "label_ar": "جلينيك",
                "label_fr": "Glienicke"
            },
            {
                "value": 39194,
                "label": "Fehrbellin",
                "label_ar": "فيربلين",
                "label_fr": "Fehrbellin"
            },
            {
                "value": 39195,
                "label": "Alt Ruppin",
                "label_ar": "ألت روبين",
                "label_fr": "Alt Ruppin"
            },
            {
                "value": 39196,
                "label": "Kaisersbach",
                "label_ar": "كايزرسباخ",
                "label_fr": "Kaisersbach"
            },
            {
                "value": 39197,
                "label": "Rudersberg",
                "label_ar": "رودرسبرغ",
                "label_fr": "Rudersberg"
            },
            {
                "value": 39198,
                "label": "Feldberg",
                "label_ar": "فيلدبرج",
                "label_fr": "Feldberg"
            },
            {
                "value": 39199,
                "label": "Wredenhagen",
                "label_ar": "Wredenhagen",
                "label_fr": "Wredenhagen"
            },
            {
                "value": 39200,
                "label": "Viecheln",
                "label_ar": "فيتشيلن",
                "label_fr": "Viecheln"
            },
            {
                "value": 39201,
                "label": "Ribnitz-Damgarten",
                "label_ar": "ريبنيتز دامغارتن",
                "label_fr": "Ribnitz-Damgarten"
            },
            {
                "value": 39202,
                "label": "Malchin",
                "label_ar": "مالتشين",
                "label_fr": "Malchin"
            },
            {
                "value": 39203,
                "label": "Teterow",
                "label_ar": "تيتيرو",
                "label_fr": "Teterow"
            },
            {
                "value": 39204,
                "label": "Leizen",
                "label_ar": "ليزين",
                "label_fr": "Leizen"
            },
            {
                "value": 39205,
                "label": "Gnoien",
                "label_ar": "غنوين",
                "label_fr": "Gnoien"
            },
            {
                "value": 39206,
                "label": "Brussow",
                "label_ar": "بروسو",
                "label_fr": "Brussow"
            },
            {
                "value": 39207,
                "label": "Leopoldshagen",
                "label_ar": "ليوبولدشاجين",
                "label_fr": "Léopoldshagen"
            },
            {
                "value": 39208,
                "label": "Ribnitz",
                "label_ar": "ريبنيتز",
                "label_fr": "Ribnitz"
            },
            {
                "value": 39209,
                "label": "Woldegk",
                "label_ar": "Woldegk",
                "label_fr": "Woldegk"
            },
            {
                "value": 39210,
                "label": "Freden",
                "label_ar": "فريدن",
                "label_fr": "Freden"
            },
            {
                "value": 39211,
                "label": "Eime",
                "label_ar": "ايم",
                "label_fr": "Eime"
            },
            {
                "value": 39212,
                "label": "Everode",
                "label_ar": "إيفرود",
                "label_fr": "Everode"
            },
            {
                "value": 39213,
                "label": "Warmsen",
                "label_ar": "وارمسن",
                "label_fr": "Warmsen"
            },
            {
                "value": 39214,
                "label": "Kemberg",
                "label_ar": "كيمبيرج",
                "label_fr": "Kemberg"
            },
            {
                "value": 39215,
                "label": "Zahna",
                "label_ar": "زهنا",
                "label_fr": "Zahna"
            },
            {
                "value": 39216,
                "label": "Krauschwitz",
                "label_ar": "كراوشويتز",
                "label_fr": "Krauschwitz"
            },
            {
                "value": 39217,
                "label": "Coswig",
                "label_ar": "كوسويج",
                "label_fr": "Coswig"
            },
            {
                "value": 39218,
                "label": "Labejum",
                "label_ar": "لابيجوم",
                "label_fr": "Labejum"
            },
            {
                "value": 39219,
                "label": "Ockenfels",
                "label_ar": "أوكنفيلس",
                "label_fr": "Ockenfels"
            },
            {
                "value": 39220,
                "label": "Ransbach-Baumbach",
                "label_ar": "رانسباخ بومباخ",
                "label_fr": "Ransbach-Baumbach"
            },
            {
                "value": 39221,
                "label": "Scharnhorst",
                "label_ar": "شارنهورست",
                "label_fr": "Scharnhorst"
            },
            {
                "value": 39222,
                "label": "Puderbach",
                "label_ar": "بودربخ",
                "label_fr": "Puderbach"
            },
            {
                "value": 39223,
                "label": "Burstadt",
                "label_ar": "بورستادت",
                "label_fr": "Burstadt"
            },
            {
                "value": 39224,
                "label": "Herxheim",
                "label_ar": "هيركسهايم",
                "label_fr": "Herxheim"
            },
            {
                "value": 39225,
                "label": "Lochau",
                "label_ar": "لوكاو",
                "label_fr": "Lochau"
            },
            {
                "value": 39226,
                "label": "Erdeborn",
                "label_ar": "إردبورن",
                "label_fr": "Erdeborn"
            },
            {
                "value": 39227,
                "label": "Hoya",
                "label_ar": "هويا",
                "label_fr": "Hoya"
            },
            {
                "value": 39228,
                "label": "Wachtendonk",
                "label_ar": "واتشتندونك",
                "label_fr": "Wachtendonk"
            },
            {
                "value": 39229,
                "label": "Velen",
                "label_ar": "فيلين",
                "label_fr": "Velen"
            },
            {
                "value": 39230,
                "label": "Sudlohn",
                "label_ar": "سودلون",
                "label_fr": "Sudlohn"
            },
            {
                "value": 39231,
                "label": "Dirmingen",
                "label_ar": "ديرمينجين",
                "label_fr": "Dirmingen"
            },
            {
                "value": 39232,
                "label": "Urweiler",
                "label_ar": "أورويلر",
                "label_fr": "Urweiler"
            },
            {
                "value": 39233,
                "label": "Kleinblittersdorf",
                "label_ar": "Kleinblittersdorf",
                "label_fr": "Kleinblittersdorf"
            },
            {
                "value": 39234,
                "label": "Reimsbach",
                "label_ar": "ريمسباخ",
                "label_fr": "Reimsbach"
            },
            {
                "value": 39235,
                "label": "Beckingen",
                "label_ar": "بيكينجن",
                "label_fr": "Beckingen"
            },
            {
                "value": 39236,
                "label": "Mettlach",
                "label_ar": "ميتلاش",
                "label_fr": "Mettlach"
            },
            {
                "value": 39237,
                "label": "Wadgassen",
                "label_ar": "Wadgassen",
                "label_fr": "Wadgassen"
            },
            {
                "value": 39238,
                "label": "Merchweiler",
                "label_ar": "ميرشويلر",
                "label_fr": "Merchweiler"
            },
            {
                "value": 39239,
                "label": "Sulzbach",
                "label_ar": "سولزباخ",
                "label_fr": "Sulzbach"
            },
            {
                "value": 39240,
                "label": "Lebach",
                "label_ar": "ليباتش",
                "label_fr": "Lebach"
            },
            {
                "value": 39241,
                "label": "Bexbach",
                "label_ar": "بيكسباخ",
                "label_fr": "Bexbach"
            },
            {
                "value": 39242,
                "label": "Hohenfurch",
                "label_ar": "هوهنفورش",
                "label_fr": "Hohenfurch"
            },
            {
                "value": 39243,
                "label": "Rottach-Egern",
                "label_ar": "روتاش إيجرن",
                "label_fr": "Rottach-Egern"
            },
            {
                "value": 39244,
                "label": "Gerzen",
                "label_ar": "جيرزن",
                "label_fr": "Gerzen"
            },
            {
                "value": 39245,
                "label": "Willebadessen",
                "label_ar": "ويلبادسن",
                "label_fr": "Willebadessen"
            },
            {
                "value": 39246,
                "label": "Buren",
                "label_ar": "بورين",
                "label_fr": "Buren"
            },
            {
                "value": 39247,
                "label": "Schloss Holte-Stukenbrock",
                "label_ar": "شلوس هولت ستوكينبروك",
                "label_fr": "Schloss Holte-Stukenbrock"
            },
            {
                "value": 39248,
                "label": "Steinheim",
                "label_ar": "شتاينهايم",
                "label_fr": "Steinheim"
            },
            {
                "value": 39249,
                "label": "Brakel",
                "label_ar": "براكيل",
                "label_fr": "Brakel"
            },
            {
                "value": 39250,
                "label": "Peckelsheim",
                "label_ar": "بيكلسهايم",
                "label_fr": "Peckelsheim"
            },
            {
                "value": 39251,
                "label": "Barntrup",
                "label_ar": "بارنتروب",
                "label_fr": "Barntrup"
            },
            {
                "value": 39252,
                "label": "Oldendorf",
                "label_ar": "أولدندورف",
                "label_fr": "Oldendorf"
            },
            {
                "value": 39253,
                "label": "Bad Essen",
                "label_ar": "باد إيسن",
                "label_fr": "Bad Essen"
            },
            {
                "value": 39254,
                "label": "Hille",
                "label_ar": "هيل",
                "label_fr": "Hille"
            },
            {
                "value": 39255,
                "label": "Pullach im Isartal",
                "label_ar": "Pullach im Isartal",
                "label_fr": "Pullach im Isartal"
            },
            {
                "value": 39256,
                "label": "Lingerhahn",
                "label_ar": "Lingerhahn",
                "label_fr": "Lingerhahn"
            },
            {
                "value": 39257,
                "label": "Morsfeld",
                "label_ar": "مورسفيلد",
                "label_fr": "Morsfeld"
            },
            {
                "value": 39258,
                "label": "Klein-Winternheim",
                "label_ar": "كلاين وينترنهايم",
                "label_fr": "Klein-Winternheim"
            },
            {
                "value": 39259,
                "label": "Bechtolsheim",
                "label_ar": "بيشتولشيم",
                "label_fr": "Bechtolsheim"
            },
            {
                "value": 39260,
                "label": "Eich",
                "label_ar": "ايش",
                "label_fr": "Eich"
            },
            {
                "value": 39261,
                "label": "Seifhennersdorf",
                "label_ar": "سيفينرسدورف",
                "label_fr": "Seifhennersdorf"
            },
            {
                "value": 39262,
                "label": "Stukenbrock",
                "label_ar": "ستوكنبروك",
                "label_fr": "Stukenbrock"
            },
            {
                "value": 39263,
                "label": "Oberderdingen",
                "label_ar": "اوبرديردين",
                "label_fr": "Oberderdingen"
            },
            {
                "value": 39264,
                "label": "Monsheim",
                "label_ar": "مونشيم",
                "label_fr": "Monsheim"
            },
            {
                "value": 39265,
                "label": "Illingen",
                "label_ar": "إلينجن",
                "label_fr": "Illingen"
            },
            {
                "value": 39266,
                "label": "Maulbronn",
                "label_ar": "مولبرون",
                "label_fr": "Maulbronn"
            },
            {
                "value": 39267,
                "label": "Wernau",
                "label_ar": "ويرناو",
                "label_fr": "Wernau"
            },
            {
                "value": 39268,
                "label": "Hankensbuttel",
                "label_ar": "هانكينزبوتيل",
                "label_fr": "Hankensbuttel"
            },
            {
                "value": 39269,
                "label": "Langeln",
                "label_ar": "لانجيلن",
                "label_fr": "Langeln"
            },
            {
                "value": 39270,
                "label": "Dedelstorf",
                "label_ar": "ديدلستورف",
                "label_fr": "Dedelstorf"
            },
            {
                "value": 39271,
                "label": "Lautenthal",
                "label_ar": "لوتنثال",
                "label_fr": "Lautenthal"
            },
            {
                "value": 39272,
                "label": "Vordorf",
                "label_ar": "فوردورف",
                "label_fr": "Vordorf"
            },
            {
                "value": 39273,
                "label": "Parsau",
                "label_ar": "بارسو",
                "label_fr": "Parsau"
            },
            {
                "value": 39274,
                "label": "Winnigstedt",
                "label_ar": "Winnigstedt",
                "label_fr": "Winnigstedt"
            },
            {
                "value": 39275,
                "label": "Schladen",
                "label_ar": "شلادين",
                "label_fr": "Schladen"
            },
            {
                "value": 39276,
                "label": "Remlingen",
                "label_ar": "ريلينجين",
                "label_fr": "Remlingen"
            },
            {
                "value": 39277,
                "label": "Naunheim",
                "label_ar": "نونهايم",
                "label_fr": "Naunheim"
            },
            {
                "value": 39278,
                "label": "Altenkirchen",
                "label_ar": "التنكيرشن",
                "label_fr": "Altenkirchen"
            },
            {
                "value": 39279,
                "label": "Brensbach",
                "label_ar": "برينسباخ",
                "label_fr": "Brensbach"
            },
            {
                "value": 39280,
                "label": "Messel",
                "label_ar": "ميسل",
                "label_fr": "Messel"
            },
            {
                "value": 39281,
                "label": "Gerau",
                "label_ar": "جيراو",
                "label_fr": "Gerau"
            },
            {
                "value": 39282,
                "label": "Gernsheim",
                "label_ar": "جيرنسهايم",
                "label_fr": "Gernsheim"
            },
            {
                "value": 39283,
                "label": "Schoppenstedt",
                "label_ar": "شوبنشتيدت",
                "label_fr": "Schoppenstedt"
            },
            {
                "value": 39284,
                "label": "Woltersdorf",
                "label_ar": "ولترسدورف",
                "label_fr": "Woltersdorf"
            },
            {
                "value": 39285,
                "label": "Evessen",
                "label_ar": "إيفيسن",
                "label_fr": "Evessen"
            },
            {
                "value": 39286,
                "label": "Brome",
                "label_ar": "بروم",
                "label_fr": "Brome"
            },
            {
                "value": 39287,
                "label": "Destedt",
                "label_ar": "ديستيدت",
                "label_fr": "Destedt"
            },
            {
                "value": 39288,
                "label": "Wittingen",
                "label_ar": "Wittingen",
                "label_fr": "Wittingen"
            },
            {
                "value": 39289,
                "label": "Knesebeck",
                "label_ar": "كنيسبيك",
                "label_fr": "Knesebeck"
            },
            {
                "value": 39290,
                "label": "Cremlingen",
                "label_ar": "كريملينجن",
                "label_fr": "Cremlingen"
            },
            {
                "value": 39291,
                "label": "Hofbieber",
                "label_ar": "هوفبيبر",
                "label_fr": "Hofbieber"
            },
            {
                "value": 39292,
                "label": "Schwalmstadt",
                "label_ar": "شوالمشتات",
                "label_fr": "Schwalmstadt"
            },
            {
                "value": 39293,
                "label": "Grebenhain",
                "label_ar": "غريبنهاين",
                "label_fr": "Grebenhain"
            },
            {
                "value": 39294,
                "label": "Schluchtern",
                "label_ar": "شلوتشرن",
                "label_fr": "Schluchtern"
            },
            {
                "value": 39295,
                "label": "Herbstein",
                "label_ar": "هيربستين",
                "label_fr": "Herbstein"
            },
            {
                "value": 39296,
                "label": "Neuhof",
                "label_ar": "نيوهوف",
                "label_fr": "Neuhof"
            },
            {
                "value": 39297,
                "label": "Steinau an der Strasse",
                "label_ar": "Steinau an der Strasse",
                "label_fr": "Steinau an der Strasse"
            },
            {
                "value": 39298,
                "label": "Oberaula",
                "label_ar": "أوبيراولا",
                "label_fr": "Oberaula"
            },
            {
                "value": 39299,
                "label": "Alsfeld",
                "label_ar": "Alsfeld",
                "label_fr": "Alsfeld"
            },
            {
                "value": 39300,
                "label": "Birstein",
                "label_ar": "بيرستين",
                "label_fr": "Birstein"
            },
            {
                "value": 39301,
                "label": "Bermuthshain",
                "label_ar": "برموثشاين",
                "label_fr": "Bermuthshain"
            },
            {
                "value": 39302,
                "label": "Metzlos-Gehag",
                "label_ar": "ميتزلوس جهاج",
                "label_fr": "Metzlos-Gehag"
            },
            {
                "value": 39303,
                "label": "Gehaus",
                "label_ar": "جيهوس",
                "label_fr": "Gehaus"
            },
            {
                "value": 39304,
                "label": "Frielendorf",
                "label_ar": "فريليندورف",
                "label_fr": "Frielendorf"
            },
            {
                "value": 39305,
                "label": "Bad Salzschlirf",
                "label_ar": "باد سالزشليرف",
                "label_fr": "Bad Salzschlirf"
            },
            {
                "value": 39306,
                "label": "Kunzell",
                "label_ar": "كونزيل",
                "label_fr": "Kunzell"
            },
            {
                "value": 39307,
                "label": "Odendorf",
                "label_ar": "أودندورف",
                "label_fr": "Odendorf"
            },
            {
                "value": 39308,
                "label": "Neunkirchen",
                "label_ar": "نيونكيرشن",
                "label_fr": "Neunkirchen"
            },
            {
                "value": 39309,
                "label": "Bad Munstereifel",
                "label_ar": "سيئة Munstereifel",
                "label_fr": "Bad Munstereifel"
            },
            {
                "value": 39310,
                "label": "Villmar",
                "label_ar": "فيلمار",
                "label_fr": "Villmar"
            },
            {
                "value": 39311,
                "label": "Gladenbach",
                "label_ar": "جلادنباخ",
                "label_fr": "Gladenbach"
            },
            {
                "value": 39312,
                "label": "Bad Endbach",
                "label_ar": "باد إندباخ",
                "label_fr": "Bad Endbach"
            },
            {
                "value": 39313,
                "label": "Niederweidbach",
                "label_ar": "نيدرويدباخ",
                "label_fr": "Niederweidbach"
            },
            {
                "value": 39314,
                "label": "Breidenbach",
                "label_ar": "بريدنباخ",
                "label_fr": "Breidenbach"
            },
            {
                "value": 39315,
                "label": "Niederfischbach",
                "label_ar": "نيدرفيشباخ",
                "label_fr": "Niederfischbach"
            },
            {
                "value": 39316,
                "label": "Berod bei Wallmerod",
                "label_ar": "بيرود باي والميرود",
                "label_fr": "Berod bei Wallmerod"
            },
            {
                "value": 39317,
                "label": "Schoeffengrund",
                "label_ar": "شوفينغروند",
                "label_fr": "Schoeffengrund"
            },
            {
                "value": 39318,
                "label": "Ewersbach",
                "label_ar": "اويرسباخ",
                "label_fr": "Ewersbach"
            },
            {
                "value": 39319,
                "label": "Kirschhofen",
                "label_ar": "كيرشوفن",
                "label_fr": "Kirschhofen"
            },
            {
                "value": 39320,
                "label": "Katzenelnbogen",
                "label_ar": "كاتزينيلنبوجن",
                "label_fr": "Katzenelnbogen"
            },
            {
                "value": 39321,
                "label": "Rettert",
                "label_ar": "ريترت",
                "label_fr": "Rettert"
            },
            {
                "value": 39322,
                "label": "Treffelstein",
                "label_ar": "تريفيلشتاين",
                "label_fr": "Treffelstein"
            },
            {
                "value": 39323,
                "label": "Stammbach",
                "label_ar": "ستامباخ",
                "label_fr": "Stammbach"
            },
            {
                "value": 39324,
                "label": "Munchberg",
                "label_ar": "مونشبرغ",
                "label_fr": "Munchberg"
            },
            {
                "value": 39325,
                "label": "Nagel",
                "label_ar": "ناجل",
                "label_fr": "Nagel"
            },
            {
                "value": 39326,
                "label": "Weissdorf",
                "label_ar": "فايسدورف",
                "label_fr": "Weissdorf"
            },
            {
                "value": 39327,
                "label": "Erbendorf",
                "label_ar": "اربندورف",
                "label_fr": "Erbendorf"
            },
            {
                "value": 39328,
                "label": "Kirchenlamitz",
                "label_ar": "كيرشنلاميتس",
                "label_fr": "Kirchenlamitz"
            },
            {
                "value": 39329,
                "label": "Waldershof",
                "label_ar": "Waldershof",
                "label_fr": "Waldershof"
            },
            {
                "value": 39330,
                "label": "Gefrees",
                "label_ar": "جيفريز",
                "label_fr": "Gefrees"
            },
            {
                "value": 39331,
                "label": "Neustadt an der Aisch",
                "label_ar": "Neustadt an der Aisch",
                "label_fr": "Neustadt an der Aisch"
            },
            {
                "value": 39332,
                "label": "Konigstein",
                "label_ar": "كونيجشتاين",
                "label_fr": "Konigstein"
            },
            {
                "value": 39333,
                "label": "Thurnau",
                "label_ar": "ثورناو",
                "label_fr": "Thurnau"
            },
            {
                "value": 39334,
                "label": "Schonwald",
                "label_ar": "شونوالد",
                "label_fr": "Schonwald"
            },
            {
                "value": 39335,
                "label": "Kastl",
                "label_ar": "كاستل",
                "label_fr": "Kastl"
            },
            {
                "value": 39336,
                "label": "Konradsreuth",
                "label_ar": "كونرادسروث",
                "label_fr": "Konradsreuth"
            },
            {
                "value": 39337,
                "label": "Hollfeld",
                "label_ar": "هولفيلد",
                "label_fr": "Hollfeld"
            },
            {
                "value": 39338,
                "label": "Neustadt an der Waldnaab",
                "label_ar": "Neustadt an der Waldnaab",
                "label_fr": "Neustadt an der Waldnaab"
            },
            {
                "value": 39339,
                "label": "Weiherhammer",
                "label_ar": "Weiherhammer",
                "label_fr": "Weiherhammer"
            },
            {
                "value": 39340,
                "label": "Plossberg",
                "label_ar": "بلوسبرغ",
                "label_fr": "Plossberg"
            },
            {
                "value": 39341,
                "label": "Witzenhausen",
                "label_ar": "Witzenhausen",
                "label_fr": "Witzenhausen"
            },
            {
                "value": 39342,
                "label": "Gieboldehausen",
                "label_ar": "جيبولدهاوزن",
                "label_fr": "Gieboldehausen"
            },
            {
                "value": 39343,
                "label": "Braunlage",
                "label_ar": "براونلاج",
                "label_fr": "Braunlage"
            },
            {
                "value": 39344,
                "label": "Gleichen",
                "label_ar": "جليشن",
                "label_fr": "Gleichen"
            },
            {
                "value": 39345,
                "label": "Hattorf",
                "label_ar": "حتورف",
                "label_fr": "Hattorf"
            },
            {
                "value": 39346,
                "label": "Luerdissen",
                "label_ar": "لورديسن",
                "label_fr": "Luerdissen"
            },
            {
                "value": 39347,
                "label": "Eschershausen",
                "label_ar": "إيشرسهاوزن",
                "label_fr": "Eschershausen"
            },
            {
                "value": 39348,
                "label": "Moringen",
                "label_ar": "مورينجين",
                "label_fr": "Moringen"
            },
            {
                "value": 39349,
                "label": "Hardegsen",
                "label_ar": "هارديغسين",
                "label_fr": "Hardegsen"
            },
            {
                "value": 39350,
                "label": "Ulbersdorf",
                "label_ar": "أولبيرسدورف",
                "label_fr": "Ulbersdorf"
            },
            {
                "value": 39351,
                "label": "Moritzburg",
                "label_ar": "موريتسبورغ",
                "label_fr": "Moritzburg"
            },
            {
                "value": 39352,
                "label": "Spechtshausen",
                "label_ar": "Spechtshausen",
                "label_fr": "Spechtshausen"
            },
            {
                "value": 39353,
                "label": "Neustadt in Sachsen",
                "label_ar": "نويشتات في ساكسن",
                "label_fr": "Neustadt à Sachsen"
            },
            {
                "value": 39354,
                "label": "Schwarmstedt",
                "label_ar": "شوارمستيدت",
                "label_fr": "Schwarmstedt"
            },
            {
                "value": 39355,
                "label": "Steimbke",
                "label_ar": "Steimbke",
                "label_fr": "Steimbke"
            },
            {
                "value": 39356,
                "label": "Oldendorf",
                "label_ar": "أولدندورف",
                "label_fr": "Oldendorf"
            },
            {
                "value": 39357,
                "label": "Elze",
                "label_ar": "إيلز",
                "label_fr": "Elze"
            },
            {
                "value": 39358,
                "label": "Essel",
                "label_ar": "Essel",
                "label_fr": "Essel"
            },
            {
                "value": 39359,
                "label": "Hessisch Oldendorf",
                "label_ar": "هيسش أولدندورف",
                "label_fr": "Hessisch Oldendorf"
            },
            {
                "value": 39360,
                "label": "Horgau",
                "label_ar": "هورغاو",
                "label_fr": "Horgau"
            },
            {
                "value": 39361,
                "label": "Ichenhausen",
                "label_ar": "ايتشنهاوزن",
                "label_fr": "Ichenhausen"
            },
            {
                "value": 39362,
                "label": "Rehling",
                "label_ar": "ريلينج",
                "label_fr": "Rehling"
            },
            {
                "value": 39363,
                "label": "Fischach",
                "label_ar": "فيشاخ",
                "label_fr": "Fischach"
            },
            {
                "value": 39364,
                "label": "Mottingen",
                "label_ar": "موتنغن",
                "label_fr": "Mottingen"
            },
            {
                "value": 39365,
                "label": "Welden",
                "label_ar": "ويلدن",
                "label_fr": "Welden"
            },
            {
                "value": 39366,
                "label": "Klosterlechfeld",
                "label_ar": "كلوسترليشفيلد",
                "label_fr": "Klosterlechfeld"
            },
            {
                "value": 39367,
                "label": "Hollenbach",
                "label_ar": "هولينباخ",
                "label_fr": "Hollenbach"
            },
            {
                "value": 39368,
                "label": "Offingen",
                "label_ar": "أوفينجين",
                "label_fr": "Offingen"
            },
            {
                "value": 39369,
                "label": "Gablingen",
                "label_ar": "جابلينجن",
                "label_fr": "Gablingen"
            },
            {
                "value": 39370,
                "label": "Lauingen",
                "label_ar": "لاوينجين",
                "label_fr": "Lauingen"
            },
            {
                "value": 39371,
                "label": "Inchenhofen",
                "label_ar": "Inchenhofen",
                "label_fr": "Inchenhofen"
            },
            {
                "value": 39372,
                "label": "Nienhagen",
                "label_ar": "نينهاجين",
                "label_fr": "Nienhagen"
            },
            {
                "value": 39373,
                "label": "Eicklingen",
                "label_ar": "إيكلنغن",
                "label_fr": "Eicklingen"
            },
            {
                "value": 39374,
                "label": "Rodenberg",
                "label_ar": "رودنبرغ",
                "label_fr": "Rodenberg"
            },
            {
                "value": 39375,
                "label": "Coppengrave",
                "label_ar": "كوبينجراف",
                "label_fr": "Coppengrave"
            },
            {
                "value": 39376,
                "label": "Wathlingen",
                "label_ar": "واتلينجين",
                "label_fr": "Wathlingen"
            },
            {
                "value": 39377,
                "label": "Hohnhorst",
                "label_ar": "هوهنهورست",
                "label_fr": "Hohnhorst"
            },
            {
                "value": 39378,
                "label": "Delligsen",
                "label_ar": "ديليجسن",
                "label_fr": "Delligsen"
            },
            {
                "value": 39379,
                "label": "Giesen",
                "label_ar": "جيسين",
                "label_fr": "Giesen"
            },
            {
                "value": 39380,
                "label": "Rheden",
                "label_ar": "ريدين",
                "label_fr": "Rheden"
            },
            {
                "value": 39381,
                "label": "Nienhagen",
                "label_ar": "نينهاجين",
                "label_fr": "Nienhagen"
            },
            {
                "value": 39382,
                "label": "Marienhagen",
                "label_ar": "مارينهاجين",
                "label_fr": "Marienhagen"
            },
            {
                "value": 39383,
                "label": "Schoneberg",
                "label_ar": "شونبيرج",
                "label_fr": "Schöneberg"
            },
            {
                "value": 39384,
                "label": "Seelow",
                "label_ar": "سيلو",
                "label_fr": "Seelow"
            },
            {
                "value": 39385,
                "label": "Lieberose",
                "label_ar": "ليبروز",
                "label_fr": "Lieberose"
            },
            {
                "value": 39386,
                "label": "Vorland",
                "label_ar": "فورلاند",
                "label_fr": "Vorland"
            },
            {
                "value": 39387,
                "label": "Alt Buchhorst",
                "label_ar": "بديل بوخهورست",
                "label_fr": "Alt Buchhorst"
            },
            {
                "value": 39388,
                "label": "Barendorf",
                "label_ar": "باريندورف",
                "label_fr": "Barendorf"
            },
            {
                "value": 39389,
                "label": "Radbruch",
                "label_ar": "رادبروخ",
                "label_fr": "Radbruch"
            },
            {
                "value": 39390,
                "label": "Wendhausen",
                "label_ar": "Wendhausen",
                "label_fr": "Wendhausen"
            },
            {
                "value": 39391,
                "label": "Kolln-Reisiek",
                "label_ar": "كولن ريسيك",
                "label_fr": "Kolln-Reisiek"
            },
            {
                "value": 39392,
                "label": "Aschendorf",
                "label_ar": "أشندورف",
                "label_fr": "Aschendorf"
            },
            {
                "value": 39393,
                "label": "Heede",
                "label_ar": "هيدي",
                "label_fr": "Heede"
            },
            {
                "value": 39394,
                "label": "Marienhafe",
                "label_ar": "مارينهافي",
                "label_fr": "Marienhafe"
            },
            {
                "value": 39395,
                "label": "Juist",
                "label_ar": "جويست",
                "label_fr": "Juist"
            },
            {
                "value": 39396,
                "label": "Baltrum",
                "label_ar": "بالتروم",
                "label_fr": "Baltrum"
            },
            {
                "value": 39397,
                "label": "Alttechau",
                "label_ar": "التيشاو",
                "label_fr": "Alttechau"
            },
            {
                "value": 39398,
                "label": "Hohenaspe",
                "label_ar": "هوهينسبي",
                "label_fr": "Hohenaspe"
            },
            {
                "value": 39399,
                "label": "Klein Ammensleben",
                "label_ar": "كلاين امينسلبين",
                "label_fr": "Klein Ammensleben"
            },
            {
                "value": 39400,
                "label": "Ilsenburg",
                "label_ar": "إلسنبرج",
                "label_fr": "Ilsenburg"
            },
            {
                "value": 39401,
                "label": "Wanzleben",
                "label_ar": "Wanzleben",
                "label_fr": "Wanzleben"
            },
            {
                "value": 39402,
                "label": "Heringsdorf",
                "label_ar": "هيرينغسدورف",
                "label_fr": "Heringsdorf"
            },
            {
                "value": 39403,
                "label": "Hemmingstedt",
                "label_ar": "هيمنجستيدت",
                "label_fr": "Hemmingstedt"
            },
            {
                "value": 39404,
                "label": "Hohenschaftlarn",
                "label_ar": "Hohenschaftlarn",
                "label_fr": "Hohenschaftlarn"
            },
            {
                "value": 39405,
                "label": "Manching",
                "label_ar": "مانشينغ",
                "label_fr": "Manching"
            },
            {
                "value": 39406,
                "label": "Geisenfeld",
                "label_ar": "جايزنفيلد",
                "label_fr": "Geisenfeld"
            },
            {
                "value": 39407,
                "label": "Wasserburg am Inn",
                "label_ar": "Wasserburg am Inn",
                "label_fr": "Wasserburg am Inn"
            },
            {
                "value": 39408,
                "label": "Wessling",
                "label_ar": "ويسلينج",
                "label_fr": "Wessling"
            },
            {
                "value": 39409,
                "label": "Egling",
                "label_ar": "إغلينج",
                "label_fr": "Egling"
            },
            {
                "value": 39410,
                "label": "Eurasburg",
                "label_ar": "أوراسبورغ",
                "label_fr": "Eurasbourg"
            },
            {
                "value": 39411,
                "label": "Pforring",
                "label_ar": "بفورينج",
                "label_fr": "Pforring"
            },
            {
                "value": 39412,
                "label": "Wilsum",
                "label_ar": "ويلسوم",
                "label_fr": "Wilsum"
            },
            {
                "value": 39413,
                "label": "Niederlangen",
                "label_ar": "نيدرلنغن",
                "label_fr": "Niederlangen"
            },
            {
                "value": 39414,
                "label": "Sogel",
                "label_ar": "سوجل",
                "label_fr": "Sogel"
            },
            {
                "value": 39415,
                "label": "Twist",
                "label_ar": "إلتواء",
                "label_fr": "Torsion"
            },
            {
                "value": 39416,
                "label": "Engden",
                "label_ar": "إنجدن",
                "label_fr": "Engden"
            },
            {
                "value": 39417,
                "label": "Bad Bentheim",
                "label_ar": "سيئة بينثيم",
                "label_fr": "Bad Bentheim"
            },
            {
                "value": 39418,
                "label": "Getelo",
                "label_ar": "جيتيلو",
                "label_fr": "Getelo"
            },
            {
                "value": 39419,
                "label": "Solms",
                "label_ar": "سولمز",
                "label_fr": "Solms"
            },
            {
                "value": 39420,
                "label": "Gemunden",
                "label_ar": "جيموندن",
                "label_fr": "Gemunden"
            },
            {
                "value": 39421,
                "label": "Kirchhain",
                "label_ar": "كيرشين",
                "label_fr": "Kirchhain"
            },
            {
                "value": 39422,
                "label": "Friedensdorf",
                "label_ar": "فريدنسدورف",
                "label_fr": "Friedensdorf"
            },
            {
                "value": 39423,
                "label": "Lohnberg",
                "label_ar": "لونبيرج",
                "label_fr": "Lohnberg"
            },
            {
                "value": 39424,
                "label": "Dreikirchen",
                "label_ar": "دريكيرشن",
                "label_fr": "Dreikirchen"
            },
            {
                "value": 39425,
                "label": "Braunfels",
                "label_ar": "براونفيلس",
                "label_fr": "Braunfels"
            },
            {
                "value": 39426,
                "label": "Gemunden an der Wohra",
                "label_ar": "Gemunden an der Wohra",
                "label_fr": "Gemunden an der Wohra"
            },
            {
                "value": 39427,
                "label": "Wetter",
                "label_ar": "رطب",
                "label_fr": "Plus humide"
            },
            {
                "value": 39428,
                "label": "Nordwalde",
                "label_ar": "نوردوالدي",
                "label_fr": "Nordwalde"
            },
            {
                "value": 39429,
                "label": "Leer",
                "label_ar": "لير",
                "label_fr": "Lorgner"
            },
            {
                "value": 39430,
                "label": "Spelle",
                "label_ar": "سبيل",
                "label_fr": "Spelle"
            },
            {
                "value": 39431,
                "label": "Beelen",
                "label_ar": "بيلين",
                "label_fr": "Beelen"
            },
            {
                "value": 39432,
                "label": "Sassenberg",
                "label_ar": "ساسينبيرج",
                "label_fr": "Sassenberg"
            },
            {
                "value": 39433,
                "label": "Horstmar",
                "label_ar": "هورستمار",
                "label_fr": "Horstmar"
            },
            {
                "value": 39434,
                "label": "Metelen",
                "label_ar": "ميتيلين",
                "label_fr": "Metelen"
            },
            {
                "value": 39435,
                "label": "Geltendorf",
                "label_ar": "جيلتندورف",
                "label_fr": "Geltendorf"
            },
            {
                "value": 39436,
                "label": "Reichertshausen",
                "label_ar": "Reichertshausen",
                "label_fr": "Reichertshausen"
            },
            {
                "value": 39437,
                "label": "Greifenberg",
                "label_ar": "غريفنبرغ",
                "label_fr": "Greifenberg"
            },
            {
                "value": 39438,
                "label": "Vierkirchen",
                "label_ar": "فيركيرشن",
                "label_fr": "Vierkirchen"
            },
            {
                "value": 39439,
                "label": "Walleshausen",
                "label_ar": "Walleshausen",
                "label_fr": "Walleshausen"
            },
            {
                "value": 39440,
                "label": "Welshofen",
                "label_ar": "ويلسهوفن",
                "label_fr": "Welshofen"
            },
            {
                "value": 39441,
                "label": "Ostbevern",
                "label_ar": "أوستبيفرن",
                "label_fr": "Ostbevern"
            },
            {
                "value": 39442,
                "label": "Tecklenburg",
                "label_ar": "تكلنبورغ",
                "label_fr": "Tecklenburg"
            },
            {
                "value": 39443,
                "label": "Rhade",
                "label_ar": "رادي",
                "label_fr": "Rhade"
            },
            {
                "value": 39444,
                "label": "Beverstedt",
                "label_ar": "بيفيرستيدت",
                "label_fr": "Beverstedt"
            },
            {
                "value": 39445,
                "label": "Wietzen",
                "label_ar": "Wietzen",
                "label_fr": "Wietzen"
            },
            {
                "value": 39446,
                "label": "Kirchseelte",
                "label_ar": "كيرشسيلت",
                "label_fr": "Kirchseelte"
            },
            {
                "value": 39447,
                "label": "Rhadereistedt",
                "label_ar": "راديريست",
                "label_fr": "Rhadereistedt"
            },
            {
                "value": 39448,
                "label": "Horstedt",
                "label_ar": "هورستيدت",
                "label_fr": "Horstedt"
            },
            {
                "value": 39449,
                "label": "Scharrel",
                "label_ar": "شارل",
                "label_fr": "Scharrel"
            },
            {
                "value": 39450,
                "label": "Ovelgoenne",
                "label_ar": "أوفيلجوين",
                "label_fr": "Ovelgoenne"
            },
            {
                "value": 39451,
                "label": "Carolinensiel",
                "label_ar": "كاروليننسيل",
                "label_fr": "Carolinensiel"
            },
            {
                "value": 39452,
                "label": "Ovelgonne",
                "label_ar": "Ovelgonne",
                "label_fr": "Ovelgonne"
            },
            {
                "value": 39453,
                "label": "Bakum",
                "label_ar": "باكوم",
                "label_fr": "Bakum"
            },
            {
                "value": 39454,
                "label": "Dotlingen",
                "label_ar": "دوتلينجن",
                "label_fr": "Dotlingen"
            },
            {
                "value": 39455,
                "label": "Ramsloh",
                "label_ar": "رامسلوه",
                "label_fr": "Ramsloh"
            },
            {
                "value": 39456,
                "label": "Kirn",
                "label_ar": "كيرن",
                "label_fr": "Kirn"
            },
            {
                "value": 39457,
                "label": "Furtwangen im Schwarzwald",
                "label_ar": "Furtwangen im Schwarzwald",
                "label_fr": "Furtwangen im Schwarzwald"
            },
            {
                "value": 39458,
                "label": "Hufingen",
                "label_ar": "هوفينغن",
                "label_fr": "Hufingen"
            },
            {
                "value": 39459,
                "label": "Niedereschach",
                "label_ar": "نيديريشاخ",
                "label_fr": "Niedereschach"
            },
            {
                "value": 39460,
                "label": "Gaienhofen",
                "label_ar": "جاينهوفن",
                "label_fr": "Gaienhofen"
            },
            {
                "value": 39461,
                "label": "Uhldingen-Muhlhofen",
                "label_ar": "Uhldingen-Muhlhofen",
                "label_fr": "Uhldingen-Muhlhofen"
            },
            {
                "value": 39462,
                "label": "Triberg im Schwarzwald",
                "label_ar": "تريبرغ إم شوارزوالد",
                "label_fr": "Triberg im Schwarzwald"
            },
            {
                "value": 39463,
                "label": "Dahlewitz",
                "label_ar": "داليويتز",
                "label_fr": "Dahlewitz"
            },
            {
                "value": 39464,
                "label": "Putlitz",
                "label_ar": "بوتليتز",
                "label_fr": "Putlitz"
            },
            {
                "value": 39465,
                "label": "Bad Boll",
                "label_ar": "سيئة بول",
                "label_fr": "Bad Boll"
            },
            {
                "value": 39466,
                "label": "Nattheim",
                "label_ar": "ناتيم",
                "label_fr": "Nattheim"
            },
            {
                "value": 39467,
                "label": "Sievershutten",
                "label_ar": "سيفيرشوتن",
                "label_fr": "Sievershutten"
            },
            {
                "value": 39468,
                "label": "Seedorf",
                "label_ar": "سيدورف",
                "label_fr": "Seedorf"
            },
            {
                "value": 39469,
                "label": "Hilter",
                "label_ar": "هيلتر",
                "label_fr": "Hilter"
            },
            {
                "value": 39470,
                "label": "Alfhausen",
                "label_ar": "ألفهاوزن",
                "label_fr": "Alfhausen"
            },
            {
                "value": 39471,
                "label": "Neuenkirchen",
                "label_ar": "نوينكيرشن",
                "label_fr": "Neuenkirchen"
            },
            {
                "value": 39472,
                "label": "Bersenbruck",
                "label_ar": "بيرسنبروك",
                "label_fr": "Bersenbruck"
            },
            {
                "value": 39473,
                "label": "Gehrde",
                "label_ar": "غيردي",
                "label_fr": "Gehrde"
            },
            {
                "value": 39474,
                "label": "Trittau",
                "label_ar": "تريتاو",
                "label_fr": "Trittau"
            },
            {
                "value": 39475,
                "label": "Gronwohld",
                "label_ar": "جرونوولد",
                "label_fr": "Gronwohld"
            },
            {
                "value": 39476,
                "label": "Todesfelde",
                "label_ar": "تودسفيلد",
                "label_fr": "Todesfelde"
            },
            {
                "value": 39477,
                "label": "Todendorf",
                "label_ar": "تودندورف",
                "label_fr": "Todendorf"
            },
            {
                "value": 39478,
                "label": "Wunnenberg",
                "label_ar": "ونينبيرج",
                "label_fr": "Wunnenberg"
            },
            {
                "value": 39479,
                "label": "Lichtenau",
                "label_ar": "ليشتناو",
                "label_fr": "Lichtenau"
            },
            {
                "value": 39480,
                "label": "Weiberg",
                "label_ar": "Weiberg",
                "label_fr": "Weiberg"
            },
            {
                "value": 39481,
                "label": "Etteln",
                "label_ar": "إتيلن",
                "label_fr": "Etteln"
            },
            {
                "value": 39482,
                "label": "Kruden",
                "label_ar": "كرودين",
                "label_fr": "Kruden"
            },
            {
                "value": 39483,
                "label": "Haldensleben I",
                "label_ar": "Haldensleben أنا",
                "label_fr": "Haldensleben I"
            },
            {
                "value": 39484,
                "label": "Hecklingen",
                "label_ar": "هيكلينجن",
                "label_fr": "Hecklingen"
            },
            {
                "value": 39485,
                "label": "Lostau",
                "label_ar": "لوستاو",
                "label_fr": "Lostau"
            },
            {
                "value": 39486,
                "label": "Biere",
                "label_ar": "بيير",
                "label_fr": "Bière"
            },
            {
                "value": 39487,
                "label": "Atzendorf",
                "label_ar": "أتزيندورف",
                "label_fr": "Atzendorf"
            },
            {
                "value": 39488,
                "label": "Mahndorf",
                "label_ar": "ماهندورف",
                "label_fr": "Mahndorf"
            },
            {
                "value": 39489,
                "label": "Igel",
                "label_ar": "إيجل",
                "label_fr": "Igel"
            },
            {
                "value": 39490,
                "label": "Neroth",
                "label_ar": "نيروث",
                "label_fr": "Neroth"
            },
            {
                "value": 39491,
                "label": "Urzig",
                "label_ar": "أورزيغ",
                "label_fr": "Urzig"
            },
            {
                "value": 39492,
                "label": "Mastershausen",
                "label_ar": "ماسترهاوزن",
                "label_fr": "Mastershausen"
            },
            {
                "value": 39493,
                "label": "Sensweiler",
                "label_ar": "سينسويلر",
                "label_fr": "Sensweiler"
            },
            {
                "value": 39494,
                "label": "Enkirch",
                "label_ar": "إنكيرش",
                "label_fr": "Enkirch"
            },
            {
                "value": 39495,
                "label": "Tawern",
                "label_ar": "تاورن",
                "label_fr": "Tawern"
            },
            {
                "value": 39496,
                "label": "Herrstein",
                "label_ar": "هيرشتاين",
                "label_fr": "Herrstein"
            },
            {
                "value": 39497,
                "label": "Idar-Oberstein",
                "label_ar": "ايدار اوبرشتاين",
                "label_fr": "Idar-Oberstein"
            },
            {
                "value": 39498,
                "label": "Geisfeld",
                "label_ar": "جيسفيلد",
                "label_fr": "Geisfeld"
            },
            {
                "value": 39499,
                "label": "Morschied",
                "label_ar": "مرشد",
                "label_fr": "Morschied"
            },
            {
                "value": 39500,
                "label": "Pellingen",
                "label_ar": "بيلينجين",
                "label_fr": "Pellingen"
            },
            {
                "value": 39501,
                "label": "Bausendorf",
                "label_ar": "باوسندورف",
                "label_fr": "Bausendorf"
            },
            {
                "value": 39502,
                "label": "Thomm",
                "label_ar": "ثوم",
                "label_fr": "Thomm"
            },
            {
                "value": 39503,
                "label": "Newel",
                "label_ar": "نيويل",
                "label_fr": "Newel"
            },
            {
                "value": 39504,
                "label": "Horbruch",
                "label_ar": "هوربروخ",
                "label_fr": "Horbruch"
            },
            {
                "value": 39505,
                "label": "Ralingen",
                "label_ar": "رالينجين",
                "label_fr": "Ralingen"
            },
            {
                "value": 39506,
                "label": "Sonnschied",
                "label_ar": "سونشيد",
                "label_fr": "Sonnschied"
            },
            {
                "value": 39507,
                "label": "Pelm",
                "label_ar": "بيلم",
                "label_fr": "Pelm"
            },
            {
                "value": 39508,
                "label": "Kenn",
                "label_ar": "كين",
                "label_fr": "Kenn"
            },
            {
                "value": 39509,
                "label": "Olzheim",
                "label_ar": "أولزهايم",
                "label_fr": "Olzheim"
            },
            {
                "value": 39510,
                "label": "Serrig",
                "label_ar": "سيريج",
                "label_fr": "Serrig"
            },
            {
                "value": 39511,
                "label": "Steineberg",
                "label_ar": "شتاينبرغ",
                "label_fr": "Steineberg"
            },
            {
                "value": 39512,
                "label": "Pluwig",
                "label_ar": "بلويج",
                "label_fr": "Pluwig"
            },
            {
                "value": 39513,
                "label": "Feusdorf",
                "label_ar": "Feusdorf",
                "label_fr": "Feusdorf"
            },
            {
                "value": 39514,
                "label": "Lautzenhausen",
                "label_ar": "لاوتزينهاوزن",
                "label_fr": "Lautzenhausen"
            },
            {
                "value": 39515,
                "label": "Helfant",
                "label_ar": "هيلفانت",
                "label_fr": "Helfant"
            },
            {
                "value": 39516,
                "label": "Iffezheim",
                "label_ar": "ايفيزهايم",
                "label_fr": "Iffezheim"
            },
            {
                "value": 39517,
                "label": "Hugelsheim",
                "label_ar": "هوجلشيم",
                "label_fr": "Hugelsheim"
            },
            {
                "value": 39518,
                "label": "Gengenbach",
                "label_ar": "جينجينباخ",
                "label_fr": "Gengenbach"
            },
            {
                "value": 39519,
                "label": "Neuried",
                "label_ar": "عصبي",
                "label_fr": "Neuried"
            },
            {
                "value": 39520,
                "label": "Lichtenau",
                "label_ar": "ليشتناو",
                "label_fr": "Lichtenau"
            },
            {
                "value": 39521,
                "label": "Ringsheim",
                "label_ar": "رينغشيم",
                "label_fr": "Ringsheim"
            },
            {
                "value": 39522,
                "label": "Ottenhofen",
                "label_ar": "أوتينهوفن",
                "label_fr": "Ottenhofen"
            },
            {
                "value": 39523,
                "label": "Kappelrodeck",
                "label_ar": "كابيلروديك",
                "label_fr": "Kappelrodeck"
            },
            {
                "value": 39524,
                "label": "Forbach",
                "label_ar": "فورباخ",
                "label_fr": "Forbach"
            },
            {
                "value": 39525,
                "label": "Meissenheim",
                "label_ar": "ميسنهايم",
                "label_fr": "Meissenheim"
            },
            {
                "value": 39526,
                "label": "Lauf",
                "label_ar": "لاوف",
                "label_fr": "Lauf"
            },
            {
                "value": 39527,
                "label": "Dasing",
                "label_ar": "تبليل",
                "label_fr": "Dasing"
            },
            {
                "value": 39528,
                "label": "Affaltern",
                "label_ar": "شجار",
                "label_fr": "Affaltern"
            },
            {
                "value": 39529,
                "label": "Ahlhorn",
                "label_ar": "أهلهورن",
                "label_fr": "Ahlhorn"
            },
            {
                "value": 39530,
                "label": "Pfaffenhausen",
                "label_ar": "بفافنهاوزن",
                "label_fr": "Pfaffenhausen"
            },
            {
                "value": 39531,
                "label": "Weiler-Simmerberg",
                "label_ar": "وايلر سيمربيرج",
                "label_fr": "Weiler-Simmerberg"
            },
            {
                "value": 39532,
                "label": "Wertach",
                "label_ar": "فيرتاش",
                "label_fr": "Wertach"
            },
            {
                "value": 39533,
                "label": "Oberrieden",
                "label_ar": "أوبيرريدن",
                "label_fr": "Oberrieden"
            },
            {
                "value": 39534,
                "label": "Markt Rettenbach",
                "label_ar": "ماركت ريتنباخ",
                "label_fr": "Markt Rettenbach"
            },
            {
                "value": 39535,
                "label": "Buchenberg",
                "label_ar": "بوخنبرج",
                "label_fr": "Buchenberg"
            },
            {
                "value": 39536,
                "label": "Eppishausen",
                "label_ar": "إبيشوزن",
                "label_fr": "Eppishausen"
            },
            {
                "value": 39537,
                "label": "Eggenthal",
                "label_ar": "اغينثال",
                "label_fr": "Eggenthal"
            },
            {
                "value": 39538,
                "label": "Waal",
                "label_ar": "وال",
                "label_fr": "Waal"
            },
            {
                "value": 39539,
                "label": "Zaisertshofen",
                "label_ar": "Zaisertshofen",
                "label_fr": "Zaisertshofen"
            },
            {
                "value": 39540,
                "label": "Heimenkirch",
                "label_ar": "هيمنكيرش",
                "label_fr": "Heimenkirch"
            },
            {
                "value": 39541,
                "label": "Waltenhofen",
                "label_ar": "والتنهوفن",
                "label_fr": "Waltenhofen"
            },
            {
                "value": 39542,
                "label": "Ruckholz",
                "label_ar": "روكهولز",
                "label_fr": "Ruckholz"
            },
            {
                "value": 39543,
                "label": "Sigmarszell",
                "label_ar": "سيغمارسيل",
                "label_fr": "Sigmarszell"
            },
            {
                "value": 39544,
                "label": "Turkheim",
                "label_ar": "تركهايم",
                "label_fr": "Turkheim"
            },
            {
                "value": 39545,
                "label": "Ascha",
                "label_ar": "أشكا",
                "label_fr": "Ascha"
            },
            {
                "value": 39546,
                "label": "Parnsberg",
                "label_ar": "بارنسبرغ",
                "label_fr": "Parnsberg"
            },
            {
                "value": 39547,
                "label": "Bodenmais",
                "label_ar": "بودنمايس",
                "label_fr": "Bodenmais"
            },
            {
                "value": 39548,
                "label": "Kelheim",
                "label_ar": "كيلهايم",
                "label_fr": "Kelheim"
            },
            {
                "value": 39549,
                "label": "Strasskirchen",
                "label_ar": "ستراسكيرشن",
                "label_fr": "Strasskirchen"
            },
            {
                "value": 39550,
                "label": "Wolfsegg",
                "label_ar": "وولفسيج",
                "label_fr": "Wolfsegg"
            },
            {
                "value": 39551,
                "label": "Hemau",
                "label_ar": "هيماو",
                "label_fr": "Hemau"
            },
            {
                "value": 39552,
                "label": "Mariaposching",
                "label_ar": "ماريابوشينغ",
                "label_fr": "Mariaposching"
            },
            {
                "value": 39553,
                "label": "Thalmassing",
                "label_ar": "تالماسينج",
                "label_fr": "Thalmassage"
            },
            {
                "value": 39554,
                "label": "Eichendorf",
                "label_ar": "ايشندورف",
                "label_fr": "Eichendorf"
            },
            {
                "value": 39555,
                "label": "Pemfling",
                "label_ar": "بيمفلينج",
                "label_fr": "Pemfling"
            },
            {
                "value": 39556,
                "label": "Bischofsmais",
                "label_ar": "بيشوفسميس",
                "label_fr": "Bischofsmais"
            },
            {
                "value": 39557,
                "label": "Beratzhausen",
                "label_ar": "بيراتزهاوزن",
                "label_fr": "Beratzhausen"
            },
            {
                "value": 39558,
                "label": "Breitenbrunn",
                "label_ar": "بريتنبرون",
                "label_fr": "Breitenbrunn"
            },
            {
                "value": 39559,
                "label": "Rastenberg",
                "label_ar": "راستنبرغ",
                "label_fr": "Rastenberg"
            },
            {
                "value": 39560,
                "label": "Worbis",
                "label_ar": "Worbis",
                "label_fr": "Worbis"
            },
            {
                "value": 39561,
                "label": "Weilar",
                "label_ar": "ويلار",
                "label_fr": "Weilar"
            },
            {
                "value": 39562,
                "label": "Blankenhain",
                "label_ar": "بلانكنهاين",
                "label_fr": "Blankenhain"
            },
            {
                "value": 39563,
                "label": "Brotterode",
                "label_ar": "بروتيرود",
                "label_fr": "Brotterode"
            },
            {
                "value": 39564,
                "label": "Rausdorf",
                "label_ar": "راوزدورف",
                "label_fr": "Rausdorf"
            },
            {
                "value": 39565,
                "label": "Oechsen",
                "label_ar": "أوشسن",
                "label_fr": "Oechsen"
            },
            {
                "value": 39566,
                "label": "Singenbach",
                "label_ar": "سينجينباخ",
                "label_fr": "Singenbach"
            },
            {
                "value": 39567,
                "label": "Dorfen",
                "label_ar": "دورفين",
                "label_fr": "Dorfen"
            },
            {
                "value": 39568,
                "label": "Amerang",
                "label_ar": "أميرانج",
                "label_fr": "Amerang"
            },
            {
                "value": 39569,
                "label": "Gerolsbach",
                "label_ar": "جيرولسباخ",
                "label_fr": "Gerolsbach"
            },
            {
                "value": 39570,
                "label": "Ernsgaden",
                "label_ar": "ارنسجادن",
                "label_fr": "Ernsgaden"
            },
            {
                "value": 39571,
                "label": "Lenggries",
                "label_ar": "لينغريس",
                "label_fr": "Lenggries"
            },
            {
                "value": 39572,
                "label": "Vohburg an der Donau",
                "label_ar": "Vohburg an der Donau",
                "label_fr": "Vohburg an der Donau"
            },
            {
                "value": 39573,
                "label": "Brunsbuttel",
                "label_ar": "Brunsbuttel",
                "label_fr": "Brunsbuttel"
            },
            {
                "value": 39574,
                "label": "Burg-Grambke",
                "label_ar": "بورغ غرامبك",
                "label_fr": "Burg-Grambke"
            },
            {
                "value": 39575,
                "label": "Olderup",
                "label_ar": "أولدراب",
                "label_fr": "Olderup"
            },
            {
                "value": 39576,
                "label": "Pansdorf",
                "label_ar": "بانسدورف",
                "label_fr": "Pansdorf"
            },
            {
                "value": 39577,
                "label": "Rantzau",
                "label_ar": "رانتزاو",
                "label_fr": "Rantzau"
            },
            {
                "value": 39578,
                "label": "Gersheim",
                "label_ar": "غيرشيم",
                "label_fr": "Gersheim"
            },
            {
                "value": 39579,
                "label": "Namborn",
                "label_ar": "نامبورن",
                "label_fr": "Namborn"
            },
            {
                "value": 39580,
                "label": "Weiskirchen",
                "label_ar": "Weiskirchen",
                "label_fr": "Weiskirchen"
            },
            {
                "value": 39581,
                "label": "Blieskastel",
                "label_ar": "بليسكاستيل",
                "label_fr": "Blieskastel"
            },
            {
                "value": 39582,
                "label": "Marpingen",
                "label_ar": "ماربينغن",
                "label_fr": "Marpingen"
            },
            {
                "value": 39583,
                "label": "Kollerbach",
                "label_ar": "كولرباخ",
                "label_fr": "Kollerbach"
            },
            {
                "value": 39584,
                "label": "Freisen",
                "label_ar": "فريزن",
                "label_fr": "Freisen"
            },
            {
                "value": 39585,
                "label": "Siesbach",
                "label_ar": "سيسباخ",
                "label_fr": "Siesbach"
            },
            {
                "value": 39586,
                "label": "Rodach",
                "label_ar": "روداخ",
                "label_fr": "Rodach"
            },
            {
                "value": 39587,
                "label": "Baunach",
                "label_ar": "بوناتش",
                "label_fr": "Baunach"
            },
            {
                "value": 39588,
                "label": "Dentlein am Forst",
                "label_ar": "Dentlein am Forst",
                "label_fr": "Dentlein am Forst"
            },
            {
                "value": 39589,
                "label": "Rothenburg",
                "label_ar": "روتنبورغ",
                "label_fr": "Rothenburg"
            },
            {
                "value": 39590,
                "label": "Uffenheim",
                "label_ar": "أوفنهايم",
                "label_fr": "Uffenheim"
            },
            {
                "value": 39591,
                "label": "Langenzenn",
                "label_ar": "لانجينزين",
                "label_fr": "Langenzenn"
            },
            {
                "value": 39592,
                "label": "Weismain",
                "label_ar": "Weismain",
                "label_fr": "Weismain"
            },
            {
                "value": 39593,
                "label": "Wirsberg",
                "label_ar": "ويرسبيرغ",
                "label_fr": "Wirsberg"
            },
            {
                "value": 39594,
                "label": "Kasendorf",
                "label_ar": "كاسندورف",
                "label_fr": "Kasendorf"
            },
            {
                "value": 39595,
                "label": "Vilseck",
                "label_ar": "فيلسك",
                "label_fr": "Vilseck"
            },
            {
                "value": 39596,
                "label": "Topen",
                "label_ar": "توبين",
                "label_fr": "Topen"
            },
            {
                "value": 39597,
                "label": "Kodnitz",
                "label_ar": "كودنيتز",
                "label_fr": "Kodnitz"
            },
            {
                "value": 39598,
                "label": "Stockheim",
                "label_ar": "ستوكهايم",
                "label_fr": "Stockheim"
            },
            {
                "value": 39599,
                "label": "Grafenwohr",
                "label_ar": "جرافنوهر",
                "label_fr": "Grafenwohr"
            },
            {
                "value": 39600,
                "label": "Nordhalben",
                "label_ar": "نوردهالبن",
                "label_fr": "Nordhalben"
            },
            {
                "value": 39601,
                "label": "Tirschenreuth",
                "label_ar": "Tirschenreuth",
                "label_fr": "Tirschenreuth"
            },
            {
                "value": 39602,
                "label": "Gossweinstein",
                "label_ar": "جوسوينشتاين",
                "label_fr": "Gossweinstein"
            },
            {
                "value": 39603,
                "label": "Goldkronach",
                "label_ar": "Goldkronach",
                "label_fr": "Goldkronach"
            },
            {
                "value": 39604,
                "label": "Obernzell",
                "label_ar": "اوبرنزيل",
                "label_fr": "Obernzell"
            },
            {
                "value": 39605,
                "label": "Unterdietfurt",
                "label_ar": "Unterdietfurt",
                "label_fr": "Unterdietfurt"
            },
            {
                "value": 39606,
                "label": "Dingolfing",
                "label_ar": "دينجولفينج",
                "label_fr": "Dingolfing"
            },
            {
                "value": 39607,
                "label": "Untergriesbach",
                "label_ar": "أونترجريسباخ",
                "label_fr": "Untergriesbach"
            },
            {
                "value": 39608,
                "label": "Furstenzell",
                "label_ar": "فورستينزيل",
                "label_fr": "Furstenzell"
            },
            {
                "value": 39609,
                "label": "Spiegelau",
                "label_ar": "شبيجيلو",
                "label_fr": "Spiegelau"
            },
            {
                "value": 39610,
                "label": "Tittling",
                "label_ar": "النغمة",
                "label_fr": "Tittling"
            },
            {
                "value": 39611,
                "label": "Stubenberg",
                "label_ar": "ستوبنبرغ",
                "label_fr": "Stubenberg"
            },
            {
                "value": 39612,
                "label": "Ruderting",
                "label_ar": "Ruderting",
                "label_fr": "Ruderting"
            },
            {
                "value": 39613,
                "label": "Tann",
                "label_ar": "تان",
                "label_fr": "Tann"
            },
            {
                "value": 39614,
                "label": "Vilshofen",
                "label_ar": "فيلسوفين",
                "label_fr": "Vilshofen"
            },
            {
                "value": 39615,
                "label": "Fursteneck",
                "label_ar": "فورستينيك",
                "label_fr": "Fursteneck"
            },
            {
                "value": 39616,
                "label": "Hutthurm",
                "label_ar": "هوثرم",
                "label_fr": "Hutthurm"
            },
            {
                "value": 39617,
                "label": "Perlesreut",
                "label_ar": "بيرلسروت",
                "label_fr": "Perlesreut"
            },
            {
                "value": 39618,
                "label": "Ergoldsbach",
                "label_ar": "إرغولدسباخ",
                "label_fr": "Ergoldsbach"
            },
            {
                "value": 39619,
                "label": "Frontenhausen",
                "label_ar": "فرونتنهاوزن",
                "label_fr": "Frontenhausen"
            },
            {
                "value": 39620,
                "label": "Lindlar",
                "label_ar": "ليندلار",
                "label_fr": "Lindlar"
            },
            {
                "value": 39621,
                "label": "Engelskirchen",
                "label_ar": "إنجلسكيرشن",
                "label_fr": "Engelskirchen"
            },
            {
                "value": 39622,
                "label": "Pilsting",
                "label_ar": "تجريب",
                "label_fr": "Pilsting"
            },
            {
                "value": 39623,
                "label": "Landau an der Isar",
                "label_ar": "لانداو أن دير إيزار",
                "label_fr": "Landau an der Isar"
            },
            {
                "value": 39624,
                "label": "Obertraubling",
                "label_ar": "Obertraubling",
                "label_fr": "Obertraubling"
            },
            {
                "value": 39625,
                "label": "Schnaittenbach",
                "label_ar": "شنايتينباخ",
                "label_fr": "Schnaittenbach"
            },
            {
                "value": 39626,
                "label": "Brunn",
                "label_ar": "برون",
                "label_fr": "Brunn"
            },
            {
                "value": 39627,
                "label": "Seubersdorf",
                "label_ar": "Seubersdorf",
                "label_fr": "Seubersdorf"
            },
            {
                "value": 39628,
                "label": "Maxhutte-Haidhof",
                "label_ar": "Maxhutte-Haidhof",
                "label_fr": "Maxhutte-Haidhof"
            },
            {
                "value": 39629,
                "label": "Nittendorf",
                "label_ar": "نيتندورف",
                "label_fr": "Nittendorf"
            },
            {
                "value": 39630,
                "label": "Ringsberg",
                "label_ar": "رينغسبيرغ",
                "label_fr": "Ringsberg"
            },
            {
                "value": 39631,
                "label": "Teising",
                "label_ar": "التسنين",
                "label_fr": "Teising"
            },
            {
                "value": 39632,
                "label": "Muehldorf",
                "label_ar": "Muehldorf",
                "label_fr": "Muehldorf"
            },
            {
                "value": 39633,
                "label": "Bischofswiesen",
                "label_ar": "Bischofswiesen",
                "label_fr": "Bischofswiesen"
            },
            {
                "value": 39634,
                "label": "Winhoring",
                "label_ar": "وينهورنج",
                "label_fr": "Winhoring"
            },
            {
                "value": 39635,
                "label": "Berchtesgaden",
                "label_ar": "بيرشتسجادن",
                "label_fr": "Berchtesgaden"
            },
            {
                "value": 39636,
                "label": "Pleiskirchen",
                "label_ar": "بليسكيرشين",
                "label_fr": "Pleiskirchen"
            },
            {
                "value": 39637,
                "label": "Tittmoning",
                "label_ar": "تيتمونينج",
                "label_fr": "Tittmoning"
            },
            {
                "value": 39638,
                "label": "Kleinrinderfeld",
                "label_ar": "كلاينريندرفيلد",
                "label_fr": "Kleinrinderfeld"
            },
            {
                "value": 39639,
                "label": "Bastheim",
                "label_ar": "باستايم",
                "label_fr": "Bastheim"
            },
            {
                "value": 39640,
                "label": "Rottendorf",
                "label_ar": "روتندورف",
                "label_fr": "Rottendorf"
            },
            {
                "value": 39641,
                "label": "Gerolzhofen",
                "label_ar": "جيرولزهوفن",
                "label_fr": "Gerolzhofen"
            },
            {
                "value": 39642,
                "label": "Rollbach",
                "label_ar": "رولباخ",
                "label_fr": "Rollbach"
            },
            {
                "value": 39643,
                "label": "Schonau",
                "label_ar": "شوناو",
                "label_fr": "Schonau"
            },
            {
                "value": 39644,
                "label": "Rothenfels",
                "label_ar": "روثنفيلز",
                "label_fr": "Rothenfels"
            },
            {
                "value": 39645,
                "label": "Karlstadt am Main",
                "label_ar": "Karlstadt am Main",
                "label_fr": "Karlstadt am Main"
            },
            {
                "value": 39646,
                "label": "Kleinheubach",
                "label_ar": "كلاينهيوباتش",
                "label_fr": "Kleinheubach"
            },
            {
                "value": 39647,
                "label": "Zell",
                "label_ar": "زيل",
                "label_fr": "Zell"
            },
            {
                "value": 39648,
                "label": "Rothhausen",
                "label_ar": "روثهاوزن",
                "label_fr": "Rothhausen"
            },
            {
                "value": 39649,
                "label": "Lutzelbach",
                "label_ar": "لوتزيلباخ",
                "label_fr": "Lutzelbach"
            },
            {
                "value": 39650,
                "label": "Wunsiedel",
                "label_ar": "Wunsiedel",
                "label_fr": "Wunsiedel"
            },
            {
                "value": 39651,
                "label": "Trabitz",
                "label_ar": "ترابيتز",
                "label_fr": "Trabitz"
            },
            {
                "value": 39652,
                "label": "Hochstadt an der Aisch",
                "label_ar": "Hochstadt an der Aisch",
                "label_fr": "Hochstadt an der Aisch"
            },
            {
                "value": 39653,
                "label": "Auerbach",
                "label_ar": "أورباخ",
                "label_fr": "Auerbach"
            },
            {
                "value": 39654,
                "label": "Freihung",
                "label_ar": "فرايهونج",
                "label_fr": "Freihung"
            },
            {
                "value": 39655,
                "label": "Wernberg-Koblitz",
                "label_ar": "Wernberg-Koblitz",
                "label_fr": "Wernberg-Koblitz"
            },
            {
                "value": 39656,
                "label": "Stornstein",
                "label_ar": "ستورنشتاين",
                "label_fr": "Stornstein"
            },
            {
                "value": 39657,
                "label": "Pullenreuth",
                "label_ar": "بولينروث",
                "label_fr": "Pullenreuth"
            },
            {
                "value": 39658,
                "label": "Kirchenthumbach",
                "label_ar": "كيرشنثومباخ",
                "label_fr": "Kirchenthumbach"
            },
            {
                "value": 39659,
                "label": "Rehau",
                "label_ar": "Rehau",
                "label_fr": "Rehau"
            },
            {
                "value": 39660,
                "label": "Eilenburg, Berg",
                "label_ar": "ايلنبورغ ، بيرج",
                "label_fr": "Eilenburg, Berg"
            },
            {
                "value": 39661,
                "label": "Pleystein",
                "label_ar": "بليستين",
                "label_fr": "Pléystéine"
            },
            {
                "value": 39662,
                "label": "Mantel",
                "label_ar": "رف",
                "label_fr": "Mantel"
            },
            {
                "value": 39663,
                "label": "Mitterteich",
                "label_ar": "ميترتيش",
                "label_fr": "Mitterteich"
            },
            {
                "value": 39664,
                "label": "Pottenstein",
                "label_ar": "بوتنشتاين",
                "label_fr": "Pottenstein"
            },
            {
                "value": 39665,
                "label": "Eslarn",
                "label_ar": "اسلارن",
                "label_fr": "Eslarn"
            },
            {
                "value": 39666,
                "label": "Windischeschenbach",
                "label_ar": "Windischeschenbach",
                "label_fr": "Windischeschenbach"
            },
            {
                "value": 39667,
                "label": "Timmenrode",
                "label_ar": "تيمينرود",
                "label_fr": "Timmenrode"
            },
            {
                "value": 39668,
                "label": "Budenheim",
                "label_ar": "بودنهايم",
                "label_fr": "Budenheim"
            },
            {
                "value": 39669,
                "label": "Gensingen",
                "label_ar": "جينسينجين",
                "label_fr": "Gensingen"
            },
            {
                "value": 39670,
                "label": "Schlangenbad",
                "label_ar": "شلانجنباد",
                "label_fr": "Schlangenbad"
            },
            {
                "value": 39671,
                "label": "Hausen uber Aar",
                "label_ar": "Hausen uber Aar",
                "label_fr": "Hausen uber Aar"
            },
            {
                "value": 39672,
                "label": "Langenlonsheim",
                "label_ar": "لانغنلونشايم",
                "label_fr": "Langenlonsheim"
            },
            {
                "value": 39673,
                "label": "Heidesheim",
                "label_ar": "هيديسهايم",
                "label_fr": "Heidesheim"
            },
            {
                "value": 39674,
                "label": "Grolsheim",
                "label_ar": "جرولشيم",
                "label_fr": "Grolsheim"
            },
            {
                "value": 39675,
                "label": "Oppenheim",
                "label_ar": "أوبنهايم",
                "label_fr": "Oppenheim"
            },
            {
                "value": 39676,
                "label": "Ober-Hilbersheim",
                "label_ar": "أوبر هيلبيرشيم",
                "label_fr": "Ober-Hilbersheim"
            },
            {
                "value": 39677,
                "label": "Ellgau",
                "label_ar": "Ellgau",
                "label_fr": "Ellgau"
            },
            {
                "value": 39678,
                "label": "Thannhausen",
                "label_ar": "ثانهاوزن",
                "label_fr": "Thannhausen"
            },
            {
                "value": 39679,
                "label": "Altomunster",
                "label_ar": "التومونستر",
                "label_fr": "Altomunster"
            },
            {
                "value": 39680,
                "label": "Wemding",
                "label_ar": "يمدينغ",
                "label_fr": "Wemding"
            },
            {
                "value": 39681,
                "label": "Dinkelscherben",
                "label_ar": "Dinkelscherben",
                "label_fr": "Dinkelscherben"
            },
            {
                "value": 39682,
                "label": "Eichstaett",
                "label_ar": "Eichstaett",
                "label_fr": "Eichstaett"
            },
            {
                "value": 39683,
                "label": "Muhr am See",
                "label_ar": "مهر ام سي",
                "label_fr": "Muhr am See"
            },
            {
                "value": 39684,
                "label": "Freystadt",
                "label_ar": "فرايستادت",
                "label_fr": "Freystadt"
            },
            {
                "value": 39685,
                "label": "Hirschaid",
                "label_ar": "هيرشيد",
                "label_fr": "Hirschaid"
            },
            {
                "value": 39686,
                "label": "Oberreichenbach",
                "label_ar": "Oberreichenbach",
                "label_fr": "Oberreichenbach"
            },
            {
                "value": 39687,
                "label": "Oberreichenbach",
                "label_ar": "Oberreichenbach",
                "label_fr": "Oberreichenbach"
            },
            {
                "value": 39688,
                "label": "Waldbockelheim",
                "label_ar": "والدبوكيلهايم",
                "label_fr": "Waldbockelheim"
            },
            {
                "value": 39689,
                "label": "Merenberg",
                "label_ar": "ميرينبرغ",
                "label_fr": "Merenberg"
            },
            {
                "value": 39690,
                "label": "Schwabenheim",
                "label_ar": "شوابنهايم",
                "label_fr": "Schwabenheim"
            },
            {
                "value": 39691,
                "label": "Nastatten",
                "label_ar": "ناستاتين",
                "label_fr": "Nastatten"
            },
            {
                "value": 39692,
                "label": "Offstein",
                "label_ar": "أوفشتاين",
                "label_fr": "Offstein"
            },
            {
                "value": 39693,
                "label": "Boppard",
                "label_ar": "بوبارد",
                "label_fr": "Boppard"
            },
            {
                "value": 39694,
                "label": "Hergenfeld",
                "label_ar": "هرجنفيلد",
                "label_fr": "Hergenfeld"
            },
            {
                "value": 39695,
                "label": "Auen",
                "label_ar": "أوين",
                "label_fr": "Auen"
            },
            {
                "value": 39696,
                "label": "Gau-Algesheim",
                "label_ar": "جاو ألجشيم",
                "label_fr": "Gau-Algesheim"
            },
            {
                "value": 39697,
                "label": "Thalheim",
                "label_ar": "ثالهايم",
                "label_fr": "Thalheim"
            },
            {
                "value": 39698,
                "label": "Schlema",
                "label_ar": "شليما",
                "label_fr": "Schlema"
            },
            {
                "value": 39699,
                "label": "Eibenstock",
                "label_ar": "ايبينستوك",
                "label_fr": "Eibenstock"
            },
            {
                "value": 39700,
                "label": "Burgstadt",
                "label_ar": "بورغشتات",
                "label_fr": "Burgstadt"
            },
            {
                "value": 39701,
                "label": "Ehrenfriedersdorf",
                "label_ar": "إهرنفريدرسدورف",
                "label_fr": "Ehrenfriedersdorf"
            },
            {
                "value": 39702,
                "label": "Zwonitz",
                "label_ar": "زونيتز",
                "label_fr": "Zwonitz"
            },
            {
                "value": 39703,
                "label": "Rochlitz",
                "label_ar": "روشليتس",
                "label_fr": "Rochlitz"
            },
            {
                "value": 39704,
                "label": "Lengenfeld",
                "label_ar": "لينجينفيلد",
                "label_fr": "Lengenfeld"
            },
            {
                "value": 39705,
                "label": "Hilbersdorf",
                "label_ar": "هيلبيرسدورف",
                "label_fr": "Hilbersdorf"
            },
            {
                "value": 39706,
                "label": "Drebach",
                "label_ar": "دريباخ",
                "label_fr": "Drebach"
            },
            {
                "value": 39707,
                "label": "Crottendorf",
                "label_ar": "كروتندورف",
                "label_fr": "Crottendorf"
            },
            {
                "value": 39708,
                "label": "Schoenheide",
                "label_ar": "شوينهيد",
                "label_fr": "Schoenheide"
            },
            {
                "value": 39709,
                "label": "Augustusburg",
                "label_ar": "أغسطس",
                "label_fr": "Augustusburg"
            },
            {
                "value": 39710,
                "label": "Stutzengrun",
                "label_ar": "Stutzengrun",
                "label_fr": "Stutzengrun"
            },
            {
                "value": 39711,
                "label": "Filsen",
                "label_ar": "فيلسن",
                "label_fr": "Filsen"
            },
            {
                "value": 39712,
                "label": "Oberhausen",
                "label_ar": "أوبرهاوزن",
                "label_fr": "Oberhausen"
            },
            {
                "value": 39713,
                "label": "Meckenbach",
                "label_ar": "ميكنباخ",
                "label_fr": "Meckenbach"
            },
            {
                "value": 39714,
                "label": "Kaub",
                "label_ar": "كاوب",
                "label_fr": "Kaub"
            },
            {
                "value": 39715,
                "label": "Hahnheim",
                "label_ar": "هانهايم",
                "label_fr": "Hahnheim"
            },
            {
                "value": 39716,
                "label": "Kirchzell",
                "label_ar": "كيرشزيل",
                "label_fr": "Kirchzell"
            },
            {
                "value": 39717,
                "label": "Worth",
                "label_ar": "يستحق",
                "label_fr": "Vaut"
            },
            {
                "value": 39718,
                "label": "Worth am Rhein",
                "label_ar": "يستحق أنا راين",
                "label_fr": "Worth am Rhein"
            },
            {
                "value": 39719,
                "label": "Grettstadt",
                "label_ar": "جريتشتات",
                "label_fr": "Grettstadt"
            },
            {
                "value": 39720,
                "label": "Obernbreit",
                "label_ar": "اوبرنبريت",
                "label_fr": "Obernbreit"
            },
            {
                "value": 39721,
                "label": "Goldbach",
                "label_ar": "جولدباخ",
                "label_fr": "Goldbach"
            },
            {
                "value": 39722,
                "label": "Kirchlauter",
                "label_ar": "كيرشلوتر",
                "label_fr": "Kirchlauter"
            },
            {
                "value": 39723,
                "label": "Elsenfeld",
                "label_ar": "إلسنفيلد",
                "label_fr": "Elsenfeld"
            },
            {
                "value": 39724,
                "label": "Hammelburg",
                "label_ar": "هاميلبورغ",
                "label_fr": "Hammelburg"
            },
            {
                "value": 39725,
                "label": "Miltenberg",
                "label_ar": "ميلتنبرغ",
                "label_fr": "Miltenberg"
            },
            {
                "value": 39726,
                "label": "Pfarrweisach",
                "label_ar": "بفارويزاخ",
                "label_fr": "Pfarrweisach"
            },
            {
                "value": 39727,
                "label": "Eibelstadt",
                "label_ar": "ايبيلشتات",
                "label_fr": "Eibelstadt"
            },
            {
                "value": 39728,
                "label": "Hassfurt",
                "label_ar": "هاسفورت",
                "label_fr": "Hassfurt"
            },
            {
                "value": 39729,
                "label": "Klingenberg am Main",
                "label_ar": "Klingenberg am Main",
                "label_fr": "Klingenberg am Main"
            },
            {
                "value": 39730,
                "label": "Ettleben",
                "label_ar": "إتلبن",
                "label_fr": "Ettleben"
            },
            {
                "value": 39731,
                "label": "Lohr a. Main",
                "label_ar": "لوهر أ. الأساسية",
                "label_fr": "Lohr a. Principale"
            },
            {
                "value": 39732,
                "label": "Erlenbach am Main",
                "label_ar": "ارلينباخ ام ماين",
                "label_fr": "Erlenbach am Main"
            },
            {
                "value": 39733,
                "label": "Wachtersbach",
                "label_ar": "Wachtersbach",
                "label_fr": "Wachtersbach"
            },
            {
                "value": 39734,
                "label": "Budingen",
                "label_ar": "بودنغن",
                "label_fr": "Budingen"
            },
            {
                "value": 39735,
                "label": "Gedern",
                "label_ar": "جيديرن",
                "label_fr": "Gedern"
            },
            {
                "value": 39736,
                "label": "Ober-Morlen",
                "label_ar": "أوبر مورلين",
                "label_fr": "Ober-Morlen"
            },
            {
                "value": 39737,
                "label": "Bad Orb",
                "label_ar": "الجرم السماوي السيئ",
                "label_fr": "Mauvais orbe"
            },
            {
                "value": 39738,
                "label": "Westergellersen",
                "label_ar": "Westergellersen",
                "label_fr": "Westergellersen"
            },
            {
                "value": 39739,
                "label": "Moisburg",
                "label_ar": "مويسبورغ",
                "label_fr": "Moisburg"
            },
            {
                "value": 39740,
                "label": "Cadenberge",
                "label_ar": "كادنبيرج",
                "label_fr": "Cadenberge"
            },
            {
                "value": 39741,
                "label": "Wingst",
                "label_ar": "وينجست",
                "label_fr": "Wingst"
            },
            {
                "value": 39742,
                "label": "Burweg",
                "label_ar": "بورويج",
                "label_fr": "Burweg"
            },
            {
                "value": 39743,
                "label": "Kirchgellersen",
                "label_ar": "كيرشجليرسن",
                "label_fr": "Kirchgellersen"
            },
            {
                "value": 39744,
                "label": "Kollmar",
                "label_ar": "كولمار",
                "label_fr": "Kollmar"
            },
            {
                "value": 39745,
                "label": "Untereglfing",
                "label_ar": "Untereglfing",
                "label_fr": "Untereglfing"
            },
            {
                "value": 39746,
                "label": "Eichenau",
                "label_ar": "ايشيناو",
                "label_fr": "Eichenau"
            },
            {
                "value": 39747,
                "label": "Bruchweiler",
                "label_ar": "Bruchweiler",
                "label_fr": "Bruchweiler"
            },
            {
                "value": 39748,
                "label": "Zemmer",
                "label_ar": "زيمر",
                "label_fr": "Zemmer"
            },
            {
                "value": 39749,
                "label": "Zeltingen-Rachtig",
                "label_ar": "Zeltingen-Rachtig",
                "label_fr": "Zeltingen-Rachtig"
            },
            {
                "value": 39750,
                "label": "Baumholder",
                "label_ar": "بومهولدر",
                "label_fr": "Baumholder"
            },
            {
                "value": 39751,
                "label": "Hermeskeil",
                "label_ar": "هيرمسكيل",
                "label_fr": "Hermeskeil"
            },
            {
                "value": 39752,
                "label": "Monzelfeld",
                "label_ar": "مونزلفيلد",
                "label_fr": "Monzelfeld"
            },
            {
                "value": 39753,
                "label": "Nordrach",
                "label_ar": "نوردراخ",
                "label_fr": "Nordrach"
            },
            {
                "value": 39754,
                "label": "Aldenhoven",
                "label_ar": "الدينهوفن",
                "label_fr": "Aldenhoven"
            },
            {
                "value": 39755,
                "label": "Hellenthal",
                "label_ar": "هيلينثال",
                "label_fr": "Hellenthal"
            },
            {
                "value": 39756,
                "label": "Nideggen",
                "label_ar": "نيدجن",
                "label_fr": "Nideggen"
            },
            {
                "value": 39757,
                "label": "Heinsberg",
                "label_ar": "هاينسبرغ",
                "label_fr": "Heinsberg"
            },
            {
                "value": 39758,
                "label": "Wassenberg",
                "label_ar": "واسنبرغ",
                "label_fr": "Wassenberg"
            },
            {
                "value": 39759,
                "label": "Pahl",
                "label_ar": "باهل",
                "label_fr": "Pahl"
            },
            {
                "value": 39760,
                "label": "Markt Indersdorf",
                "label_ar": "ماركت إندرسدورف",
                "label_fr": "Markt Indersdorf"
            },
            {
                "value": 39761,
                "label": "Marienstein",
                "label_ar": "مارينشتاين",
                "label_fr": "Marienstein"
            },
            {
                "value": 39762,
                "label": "Rottenbuch",
                "label_ar": "روتنبوخ",
                "label_fr": "Rottenbuch"
            },
            {
                "value": 39763,
                "label": "Schongau",
                "label_ar": "شونغاو",
                "label_fr": "Schongau"
            },
            {
                "value": 39764,
                "label": "Bad Feilnbach",
                "label_ar": "باد فيلنباخ",
                "label_fr": "Bad Feilnbach"
            },
            {
                "value": 39765,
                "label": "Baiern",
                "label_ar": "بايرن",
                "label_fr": "Baiern"
            },
            {
                "value": 39766,
                "label": "Holzkirchen",
                "label_ar": "هولزكيرشن",
                "label_fr": "Holzkirchen"
            },
            {
                "value": 39767,
                "label": "Eschenlohe",
                "label_ar": "Eschenlohe",
                "label_fr": "Eschenlohe"
            },
            {
                "value": 39768,
                "label": "Winterbach",
                "label_ar": "وينترباخ",
                "label_fr": "Winterbach"
            },
            {
                "value": 39769,
                "label": "Murr",
                "label_ar": "المر",
                "label_fr": "Murr"
            },
            {
                "value": 39770,
                "label": "Heringen",
                "label_ar": "هرينجن",
                "label_fr": "Heringen"
            },
            {
                "value": 39771,
                "label": "Barchfeld",
                "label_ar": "بارشفيلد",
                "label_fr": "Barchfeld"
            },
            {
                "value": 39772,
                "label": "Windehausen",
                "label_ar": "فينديهاوزن",
                "label_fr": "Windehausen"
            },
            {
                "value": 39773,
                "label": "Gerstungen",
                "label_ar": "Gerstungen",
                "label_fr": "Gerstungen"
            },
            {
                "value": 39774,
                "label": "Stadtilm",
                "label_ar": "ستاديلم",
                "label_fr": "Stadtilm"
            },
            {
                "value": 39775,
                "label": "Breitungen",
                "label_ar": "بريتونجين",
                "label_fr": "Breitungen"
            },
            {
                "value": 39776,
                "label": "Dingelstadt",
                "label_ar": "دينجلشتات",
                "label_fr": "Dingelstadt"
            },
            {
                "value": 39777,
                "label": "Ernstroda",
                "label_ar": "ارنسترودا",
                "label_fr": "Ernstroda"
            },
            {
                "value": 39778,
                "label": "Stadtroda",
                "label_ar": "ستادترودا",
                "label_fr": "Stadtroda"
            },
            {
                "value": 39779,
                "label": "Bischofferode",
                "label_ar": "بيشوفيرود",
                "label_fr": "Bischofferode"
            },
            {
                "value": 39780,
                "label": "Ilfeld",
                "label_ar": "إلفيلد",
                "label_fr": "Ilfeld"
            },
            {
                "value": 39781,
                "label": "Bad Sulza",
                "label_ar": "باد سولزا",
                "label_fr": "Bad Sulza"
            },
            {
                "value": 39782,
                "label": "Mihla",
                "label_ar": "ميهلا",
                "label_fr": "Mihla"
            },
            {
                "value": 39783,
                "label": "Neuhaus am Rennweg",
                "label_ar": "نيوهاوس ام رينويج",
                "label_fr": "Neuhaus am Rennweg"
            },
            {
                "value": 39784,
                "label": "Kindelbruck",
                "label_ar": "كيندلبروك",
                "label_fr": "Kindelbruck"
            },
            {
                "value": 39785,
                "label": "Seligenthal",
                "label_ar": "سيليجينثال",
                "label_fr": "Seligenthal"
            },
            {
                "value": 39786,
                "label": "Urbach",
                "label_ar": "أورباخ",
                "label_fr": "Urbach"
            },
            {
                "value": 39787,
                "label": "Zottelstedt",
                "label_ar": "Zottelstedt",
                "label_fr": "Zottelstedt"
            },
            {
                "value": 39788,
                "label": "Oberhof",
                "label_ar": "أوبرهوف",
                "label_fr": "Oberhof"
            },
            {
                "value": 39789,
                "label": "Lichte",
                "label_ar": "ليشتي",
                "label_fr": "Lichte"
            },
            {
                "value": 39790,
                "label": "Mellenbach-Glasbach",
                "label_ar": "ميلينباخ جلاسباخ",
                "label_fr": "Mellenbach-Glasbach"
            },
            {
                "value": 39791,
                "label": "Olbersleben",
                "label_ar": "أولبيرسليبن",
                "label_fr": "Olbersleben"
            },
            {
                "value": 39792,
                "label": "Thale",
                "label_ar": "ثيل",
                "label_fr": "Thale"
            },
            {
                "value": 39793,
                "label": "Meiningen",
                "label_ar": "مينينجين",
                "label_fr": "Meiningen"
            },
            {
                "value": 39794,
                "label": "Heilbad Heiligenstadt",
                "label_ar": "هيلباد هيليغنشتات",
                "label_fr": "Heilbad Heiligenstadt"
            },
            {
                "value": 39795,
                "label": "Hohenstein",
                "label_ar": "هوهنشتاين",
                "label_fr": "Hohenstein"
            },
            {
                "value": 39796,
                "label": "Wernrode",
                "label_ar": "ويرنرود",
                "label_fr": "Wernrode"
            },
            {
                "value": 39797,
                "label": "Walldorf",
                "label_ar": "فالدورف",
                "label_fr": "Walldorf"
            },
            {
                "value": 39798,
                "label": "Crossen",
                "label_ar": "كروسين",
                "label_fr": "Crossen"
            },
            {
                "value": 39799,
                "label": "Weida",
                "label_ar": "وييدا",
                "label_fr": "Weida"
            },
            {
                "value": 39800,
                "label": "Bad Lobenstein",
                "label_ar": "باد لوبينشتاين",
                "label_fr": "Bad Lobenstein"
            },
            {
                "value": 39801,
                "label": "Kamsdorf",
                "label_ar": "كامسدورف",
                "label_fr": "Kamsdorf"
            },
            {
                "value": 39802,
                "label": "Eisenberg",
                "label_ar": "أيزنبرغ",
                "label_fr": "Eisenberg"
            },
            {
                "value": 39803,
                "label": "Schleiz",
                "label_ar": "شليز",
                "label_fr": "Schleiz"
            },
            {
                "value": 39804,
                "label": "Gossnitz",
                "label_ar": "جوسنيتز",
                "label_fr": "Gossnitz"
            },
            {
                "value": 39805,
                "label": "Bocka",
                "label_ar": "بوكا",
                "label_fr": "Bocka"
            },
            {
                "value": 39806,
                "label": "Blankenstein",
                "label_ar": "بلانكنشتاين",
                "label_fr": "Blankenstein"
            },
            {
                "value": 39807,
                "label": "Bad Blankenburg",
                "label_ar": "سيئة بلانكنبورج",
                "label_fr": "Bad Blankenburg"
            },
            {
                "value": 39808,
                "label": "Zeulenroda",
                "label_ar": "زيولينرودا",
                "label_fr": "Zeulenroda"
            },
            {
                "value": 39809,
                "label": "Muhltroff",
                "label_ar": "مولتروف",
                "label_fr": "Muhltroff"
            },
            {
                "value": 39810,
                "label": "Remptendorf",
                "label_ar": "ريمبتندورف",
                "label_fr": "Remptendorf"
            },
            {
                "value": 39811,
                "label": "Zuhlsdorf",
                "label_ar": "زولسدورف",
                "label_fr": "Zuhlsdorf"
            },
            {
                "value": 39812,
                "label": "Vogelsdorf",
                "label_ar": "فوجيلسدورف",
                "label_fr": "Vogelsdorf"
            },
            {
                "value": 39813,
                "label": "Trebbin",
                "label_ar": "تريبين",
                "label_fr": "Trebbin"
            },
            {
                "value": 39814,
                "label": "Am Mellensee",
                "label_ar": "أنا ميلينسي",
                "label_fr": "Suis Mellensee"
            },
            {
                "value": 39815,
                "label": "Bad Kostritz",
                "label_ar": "باد كوستريتز",
                "label_fr": "Bad Kostritz"
            },
            {
                "value": 39816,
                "label": "Neustadt (Orla)",
                "label_ar": "نيوستادت (أورلا)",
                "label_fr": "Neustadt (Orla)"
            },
            {
                "value": 39817,
                "label": "Munchenbernsdorf",
                "label_ar": "مونشينبيرنسدورف",
                "label_fr": "Munchenbernsdorf"
            },
            {
                "value": 39818,
                "label": "Wadern",
                "label_ar": "وادرن",
                "label_fr": "Wadern"
            },
            {
                "value": 39819,
                "label": "Rehlingen-Siersburg",
                "label_ar": "ريلينجن سيرسبورغ",
                "label_fr": "Rehlingen-Siersburg"
            },
            {
                "value": 39820,
                "label": "Muehlheim am Main",
                "label_ar": "Muehlheim am Main",
                "label_fr": "Muehlheim am Main"
            },
            {
                "value": 39821,
                "label": "Zainingen",
                "label_ar": "زينين",
                "label_fr": "Zainingen"
            },
            {
                "value": 39822,
                "label": "Grethem",
                "label_ar": "جريتيم",
                "label_fr": "Grethem"
            },
            {
                "value": 39823,
                "label": "Haste",
                "label_ar": "تسرع",
                "label_fr": "Hâte"
            },
            {
                "value": 39824,
                "label": "Salzhemmendorf",
                "label_ar": "سالزيميندورف",
                "label_fr": "Salzhemmendorf"
            },
            {
                "value": 39825,
                "label": "Burg",
                "label_ar": "بورغ",
                "label_fr": "Burg"
            },
            {
                "value": 39826,
                "label": "Burladingen",
                "label_ar": "بورلدينجن",
                "label_fr": "Burladingen"
            },
            {
                "value": 39827,
                "label": "Haigerloch",
                "label_ar": "هايجرلوش",
                "label_fr": "Haigerloch"
            },
            {
                "value": 39828,
                "label": "Lossburg",
                "label_ar": "لوسبرغ",
                "label_fr": "Lossburg"
            },
            {
                "value": 39829,
                "label": "Grunenberg",
                "label_ar": "جرونينبيرج",
                "label_fr": "Grunenberg"
            },
            {
                "value": 39830,
                "label": "Zimmern",
                "label_ar": "زيمرن",
                "label_fr": "Zimmern"
            },
            {
                "value": 39831,
                "label": "Rosenfeld",
                "label_ar": "روزنفيلد",
                "label_fr": "Rosenfeld"
            },
            {
                "value": 39832,
                "label": "Rheinsberg",
                "label_ar": "راينسبرغ",
                "label_fr": "Rheinsberg"
            },
            {
                "value": 39833,
                "label": "Krummensee",
                "label_ar": "كرومينسي",
                "label_fr": "Krummensee"
            },
            {
                "value": 39834,
                "label": "Wildau",
                "label_ar": "ويلداو",
                "label_fr": "Wildau"
            },
            {
                "value": 39835,
                "label": "Muncheberg",
                "label_ar": "مونشبرغ",
                "label_fr": "Muncheberg"
            },
            {
                "value": 39836,
                "label": "Altlandsberg",
                "label_ar": "ألتاندسبرج",
                "label_fr": "Altlandsberg"
            },
            {
                "value": 39837,
                "label": "Asbach",
                "label_ar": "أسباخ",
                "label_fr": "Asbach"
            },
            {
                "value": 39838,
                "label": "Alsbach-Hahnlein",
                "label_ar": "Alsbach-Hahnlein",
                "label_fr": "Alsbach-Hahnlein"
            },
            {
                "value": 39839,
                "label": "Buttelborn",
                "label_ar": "باتيلبورن",
                "label_fr": "Buttelborn"
            },
            {
                "value": 39840,
                "label": "Lindenfels",
                "label_ar": "ليندينفيلس",
                "label_fr": "Lindenfels"
            },
            {
                "value": 39841,
                "label": "Einhausen",
                "label_ar": "أينهاوزن",
                "label_fr": "Einhausen"
            },
            {
                "value": 39842,
                "label": "Kefenrod",
                "label_ar": "كيفنرود",
                "label_fr": "Kefenrod"
            },
            {
                "value": 39843,
                "label": "Leingarten",
                "label_ar": "لينجارتن",
                "label_fr": "Leingarten"
            },
            {
                "value": 39844,
                "label": "Massenbachhausen",
                "label_ar": "ماسينباهاوزن",
                "label_fr": "Massenbachhausen"
            },
            {
                "value": 39845,
                "label": "Nordheim",
                "label_ar": "نوردهايم",
                "label_fr": "Nordheim"
            },
            {
                "value": 39846,
                "label": "Neuenstadt am Kocher",
                "label_ar": "نوينشتات ام كوتشير",
                "label_fr": "Neuenstadt am Kocher"
            },
            {
                "value": 39847,
                "label": "Laubach",
                "label_ar": "لوباتش",
                "label_fr": "Laubach"
            },
            {
                "value": 39848,
                "label": "Aull",
                "label_ar": "أول",
                "label_fr": "Aull"
            },
            {
                "value": 39849,
                "label": "Mittenaar",
                "label_ar": "ميتينار",
                "label_fr": "Mittenaar"
            },
            {
                "value": 39850,
                "label": "Ehringshausen",
                "label_ar": "إهرينجهاوزن",
                "label_fr": "Ehringshausen"
            },
            {
                "value": 39851,
                "label": "Diez",
                "label_ar": "دييز",
                "label_fr": "Diez"
            },
            {
                "value": 39852,
                "label": "Wiesenbach",
                "label_ar": "فيزنباخ",
                "label_fr": "Wiesenbach"
            },
            {
                "value": 39853,
                "label": "Hassloch",
                "label_ar": "هاسلوخ",
                "label_fr": "Hassloch"
            },
            {
                "value": 39854,
                "label": "Rieseby",
                "label_ar": "ريسيبي",
                "label_fr": "Rieseby"
            },
            {
                "value": 39855,
                "label": "Reher",
                "label_ar": "ريهير",
                "label_fr": "Reher"
            },
            {
                "value": 39856,
                "label": "Tonning",
                "label_ar": "تونينغ",
                "label_fr": "Tonning"
            },
            {
                "value": 39857,
                "label": "Burg auf Fehmarn",
                "label_ar": "بورغ عوف فيهمارن",
                "label_fr": "Burg auf Fehmarn"
            },
            {
                "value": 39858,
                "label": "Lensahn",
                "label_ar": "لينسان",
                "label_fr": "Lensahn"
            },
            {
                "value": 39859,
                "label": "Fehmarn",
                "label_ar": "فيهمارن",
                "label_fr": "Fehmarn"
            },
            {
                "value": 39860,
                "label": "Kellinghusen",
                "label_ar": "Kellinghusen",
                "label_fr": "Kellinghusen"
            },
            {
                "value": 39861,
                "label": "Hohenwestedt",
                "label_ar": "هوهنويستدت",
                "label_fr": "Hohenwestedt"
            },
            {
                "value": 39862,
                "label": "Ahrensboek",
                "label_ar": "أرينسبوك",
                "label_fr": "Ahrensboek"
            },
            {
                "value": 39863,
                "label": "Unterschneidheim",
                "label_ar": "أونتيرشنيدهايم",
                "label_fr": "Unterschneidheim"
            },
            {
                "value": 39864,
                "label": "Satteldorf",
                "label_ar": "ساتلدورف",
                "label_fr": "Satteldorf"
            },
            {
                "value": 39865,
                "label": "Buhlerzell",
                "label_ar": "بوهلرزيل",
                "label_fr": "Buhlerzell"
            },
            {
                "value": 39866,
                "label": "Wolpertshausen",
                "label_ar": "Wolpertshausen",
                "label_fr": "Wolpertshausen"
            },
            {
                "value": 39867,
                "label": "Obersontheim",
                "label_ar": "أوبيرسونثيم",
                "label_fr": "Obersontheim"
            },
            {
                "value": 39868,
                "label": "Mainhardt",
                "label_ar": "مينهاردت",
                "label_fr": "Mainhardt"
            },
            {
                "value": 39869,
                "label": "Twiste",
                "label_ar": "تويست",
                "label_fr": "Twiste"
            },
            {
                "value": 39870,
                "label": "Gudensberg",
                "label_ar": "جودنسبرج",
                "label_fr": "Gudensberg"
            },
            {
                "value": 39871,
                "label": "Schwarzenborn",
                "label_ar": "شوارزنبورن",
                "label_fr": "Schwarzenborn"
            },
            {
                "value": 39872,
                "label": "Wabern",
                "label_ar": "وابيرن",
                "label_fr": "Wabern"
            },
            {
                "value": 39873,
                "label": "Helsa",
                "label_ar": "هيلسا",
                "label_fr": "Helsa"
            },
            {
                "value": 39874,
                "label": "Zierenberg",
                "label_ar": "زيرينبيرج",
                "label_fr": "Zierenberg"
            },
            {
                "value": 39875,
                "label": "Nieste",
                "label_ar": "نيستي",
                "label_fr": "Nieste"
            },
            {
                "value": 39876,
                "label": "Korle",
                "label_ar": "كورلي",
                "label_fr": "Korle"
            },
            {
                "value": 39877,
                "label": "Fuldabruck",
                "label_ar": "فولدابروك",
                "label_fr": "Fuldabruck"
            },
            {
                "value": 39878,
                "label": "Sachsenhagen",
                "label_ar": "ساكسنهاجين",
                "label_fr": "Sachsenhagen"
            },
            {
                "value": 39879,
                "label": "Fassberg",
                "label_ar": "فاسبيرج",
                "label_fr": "Fassberg"
            },
            {
                "value": 39880,
                "label": "Konigheim",
                "label_ar": "كونيجهايم",
                "label_fr": "Konigheim"
            },
            {
                "value": 39881,
                "label": "Schonfeld",
                "label_ar": "شونفيلد",
                "label_fr": "Schonfeld"
            },
            {
                "value": 39882,
                "label": "Gernrode",
                "label_ar": "جيرنرود",
                "label_fr": "Gernrode"
            },
            {
                "value": 39883,
                "label": "Magdala",
                "label_ar": "مجدلا",
                "label_fr": "Magdala"
            },
            {
                "value": 39884,
                "label": "Kolleda",
                "label_ar": "كوليدا",
                "label_fr": "Kolleda"
            },
            {
                "value": 39885,
                "label": "Arenshausen",
                "label_ar": "ارينسهاوزن",
                "label_fr": "Arenshausen"
            },
            {
                "value": 39886,
                "label": "Steinach",
                "label_ar": "ستيناخ",
                "label_fr": "Steinach"
            },
            {
                "value": 39887,
                "label": "Kallstadt",
                "label_ar": "كالشتات",
                "label_fr": "Kallstadt"
            },
            {
                "value": 39888,
                "label": "Gundelsheim",
                "label_ar": "جونديلشيم",
                "label_fr": "Gundelsheim"
            },
            {
                "value": 39889,
                "label": "Neunkirchen",
                "label_ar": "نيونكيرشن",
                "label_fr": "Neunkirchen"
            },
            {
                "value": 39890,
                "label": "Wilhelmsfeld",
                "label_ar": "فيلهلمسفيلد",
                "label_fr": "Wilhelmsfeld"
            },
            {
                "value": 39891,
                "label": "Oberotterbach",
                "label_ar": "أوبيروترباخ",
                "label_fr": "Oberotterbach"
            },
            {
                "value": 39892,
                "label": "Huffenhardt",
                "label_ar": "هافنهاردت",
                "label_fr": "Huffenhardt"
            },
            {
                "value": 39893,
                "label": "Bohl-Iggelheim",
                "label_ar": "بوهل إيجلهايم",
                "label_fr": "Bohl-Iggelheim"
            },
            {
                "value": 39894,
                "label": "Jockgrim",
                "label_ar": "جوكجريم",
                "label_fr": "Jockgrim"
            },
            {
                "value": 39895,
                "label": "Rhein",
                "label_ar": "راين",
                "label_fr": "Rhein"
            },
            {
                "value": 39896,
                "label": "Aglasterhausen",
                "label_ar": "أغلاسترهاوزن",
                "label_fr": "Aglasterhausen"
            },
            {
                "value": 39897,
                "label": "Beindersheim",
                "label_ar": "بيندرسهايم",
                "label_fr": "Beindersheim"
            },
            {
                "value": 39898,
                "label": "Lambrecht",
                "label_ar": "لامبرخت",
                "label_fr": "Lambrecht"
            },
            {
                "value": 39899,
                "label": "Leinsweiler",
                "label_ar": "لينسويلر",
                "label_fr": "Leinsweiler"
            },
            {
                "value": 39900,
                "label": "Waldhambach",
                "label_ar": "فالدهامباخ",
                "label_fr": "Waldhambach"
            },
            {
                "value": 39901,
                "label": "Gonnheim",
                "label_ar": "جونهايم",
                "label_fr": "Gonnheim"
            },
            {
                "value": 39902,
                "label": "Hatzenbuhl",
                "label_ar": "Hatzenbuhl",
                "label_fr": "Hatzenbühl"
            },
            {
                "value": 39903,
                "label": "Niederkirchen bei Deidesheim",
                "label_ar": "Niederkirchen باي Deidesheim",
                "label_fr": "Niederkirchen bei Deidesheim"
            },
            {
                "value": 39904,
                "label": "Heiligkreuzsteinach",
                "label_ar": "Heiligkreuzsteinach",
                "label_fr": "Heiligkreuzsteinach"
            },
            {
                "value": 39905,
                "label": "Neupotz",
                "label_ar": "نيوبوتس",
                "label_fr": "Neupotz"
            },
            {
                "value": 39906,
                "label": "Graben-Neudorf",
                "label_ar": "جرابين نيودورف",
                "label_fr": "Graben-Neudorf"
            },
            {
                "value": 39907,
                "label": "Funfstetten",
                "label_ar": "فونفستيتن",
                "label_fr": "Funfstetten"
            },
            {
                "value": 39908,
                "label": "Altenmunster",
                "label_ar": "ألتنمونستر",
                "label_fr": "Altenmunster"
            },
            {
                "value": 39909,
                "label": "Forheim",
                "label_ar": "فورهايم",
                "label_fr": "Forheim"
            },
            {
                "value": 39910,
                "label": "Hirzenhain",
                "label_ar": "هيرزينهاين",
                "label_fr": "Hirzenhain"
            },
            {
                "value": 39911,
                "label": "Ortenberg",
                "label_ar": "أورتنبرغ",
                "label_fr": "Ortenberg"
            },
            {
                "value": 39912,
                "label": "Rosbach vor der Hohe",
                "label_ar": "Rosbach vor der Hohe",
                "label_fr": "Rosbach vor der Hohe"
            },
            {
                "value": 39913,
                "label": "Schlitz",
                "label_ar": "شليتز",
                "label_fr": "Schlitz"
            },
            {
                "value": 39914,
                "label": "Mindelstetten",
                "label_ar": "Mindelstetten",
                "label_fr": "Mindelstetten"
            },
            {
                "value": 39915,
                "label": "Icking",
                "label_ar": "ايكينج",
                "label_fr": "Glaçage"
            },
            {
                "value": 39916,
                "label": "Tutzing",
                "label_ar": "توتزينج",
                "label_fr": "Tutzing"
            },
            {
                "value": 39917,
                "label": "Krautheim",
                "label_ar": "كراوثيم",
                "label_fr": "Krautheim"
            },
            {
                "value": 39918,
                "label": "Osterburken",
                "label_ar": "أوستربوركن",
                "label_fr": "Osterburken"
            },
            {
                "value": 39919,
                "label": "Glauburg",
                "label_ar": "جلوبورج",
                "label_fr": "Glauburg"
            },
            {
                "value": 39920,
                "label": "Neuhausen",
                "label_ar": "نيوهاوزن",
                "label_fr": "Neuhausen"
            },
            {
                "value": 39921,
                "label": "Birkenfeld",
                "label_ar": "بيركينفيلد",
                "label_fr": "Birkenfeld"
            },
            {
                "value": 39922,
                "label": "Kieselbronn",
                "label_ar": "كيسيلبرون",
                "label_fr": "Kieselbronn"
            },
            {
                "value": 39923,
                "label": "Steinegg",
                "label_ar": "شتاينج",
                "label_fr": "Steinegg"
            },
            {
                "value": 39924,
                "label": "Konigsbach-Stein",
                "label_ar": "كونيغسباخ شتاين",
                "label_fr": "Königsbach-Stein"
            },
            {
                "value": 39925,
                "label": "Rath",
                "label_ar": "راث",
                "label_fr": "Rath"
            },
            {
                "value": 39926,
                "label": "Vetschau",
                "label_ar": "فيتشاو",
                "label_fr": "Vetschau"
            },
            {
                "value": 39927,
                "label": "Grossraschen",
                "label_ar": "جروسراشن",
                "label_fr": "Grossraschen"
            },
            {
                "value": 39928,
                "label": "Neuhausen",
                "label_ar": "نيوهاوزن",
                "label_fr": "Neuhausen"
            },
            {
                "value": 39929,
                "label": "Borna",
                "label_ar": "بورنا",
                "label_fr": "Borna"
            },
            {
                "value": 39930,
                "label": "Hartha",
                "label_ar": "حرثا",
                "label_fr": "Hartha"
            },
            {
                "value": 39931,
                "label": "Bad Lausick",
                "label_ar": "سيئة لاوسيك",
                "label_fr": "Bad Lausick"
            },
            {
                "value": 39932,
                "label": "Schnaittach",
                "label_ar": "شنيتاش",
                "label_fr": "Schnaittach"
            },
            {
                "value": 39933,
                "label": "Hillersleben",
                "label_ar": "هيلرسليبن",
                "label_fr": "Hillersleben"
            },
            {
                "value": 39934,
                "label": "Gross Santersleben",
                "label_ar": "جروس سانترسليبن",
                "label_fr": "Gross Santersleben"
            },
            {
                "value": 39935,
                "label": "Wedringen",
                "label_ar": "ويدرينجن",
                "label_fr": "Wedringen"
            },
            {
                "value": 39936,
                "label": "Tangermunde",
                "label_ar": "Tangermunde",
                "label_fr": "Tangermunde"
            },
            {
                "value": 39937,
                "label": "Samswegen",
                "label_ar": "Samswegen",
                "label_fr": "Samswegen"
            },
            {
                "value": 39938,
                "label": "Elend",
                "label_ar": "إليند",
                "label_fr": "Elend"
            },
            {
                "value": 39939,
                "label": "Gusten",
                "label_ar": "جوستين",
                "label_fr": "Gusten"
            },
            {
                "value": 39940,
                "label": "Moser",
                "label_ar": "موسر",
                "label_fr": "Moser"
            },
            {
                "value": 39941,
                "label": "Osterwieck",
                "label_ar": "أوستيرفيك",
                "label_fr": "Osterwieck"
            },
            {
                "value": 39942,
                "label": "Zielitz",
                "label_ar": "زيليتز",
                "label_fr": "Zielitz"
            },
            {
                "value": 39943,
                "label": "Eilsleben",
                "label_ar": "ايلسلين",
                "label_fr": "Eilsleben"
            },
            {
                "value": 39944,
                "label": "Tangerhutte",
                "label_ar": "Tangerhutte",
                "label_fr": "Tangerhutte"
            },
            {
                "value": 39945,
                "label": "Weferlingen",
                "label_ar": "ويفرلينجن",
                "label_fr": "Weferlingen"
            },
            {
                "value": 39946,
                "label": "Flechtingen",
                "label_ar": "فليشتينغن",
                "label_fr": "Flechtingen"
            },
            {
                "value": 39947,
                "label": "Obing",
                "label_ar": "أوبينج",
                "label_fr": "Obing"
            },
            {
                "value": 39948,
                "label": "Pittenhart",
                "label_ar": "بيتينهارت",
                "label_fr": "Pittenhart"
            },
            {
                "value": 39949,
                "label": "Rimsting",
                "label_ar": "ريمستينج",
                "label_fr": "Rimsting"
            },
            {
                "value": 39950,
                "label": "Waging am See",
                "label_ar": "يشن am See",
                "label_fr": "Waging am See"
            },
            {
                "value": 39951,
                "label": "Flinsbach",
                "label_ar": "فلينسباخ",
                "label_fr": "Flinsbach"
            },
            {
                "value": 39952,
                "label": "Gimbsheim",
                "label_ar": "جيمبشيم",
                "label_fr": "Gimbsheim"
            },
            {
                "value": 39953,
                "label": "Mockmuhl",
                "label_ar": "موكمل",
                "label_fr": "Mockmuhl"
            },
            {
                "value": 39954,
                "label": "Plochingen",
                "label_ar": "بلوشينغن",
                "label_fr": "Plochingen"
            },
            {
                "value": 39955,
                "label": "Benningen am Neckar",
                "label_ar": "Benningen am Neckar",
                "label_fr": "Benningen am Neckar"
            },
            {
                "value": 39956,
                "label": "Illertissen",
                "label_ar": "إرتيسن",
                "label_fr": "Illertissen"
            },
            {
                "value": 39957,
                "label": "Holzgunz",
                "label_ar": "هولزغونز",
                "label_fr": "Holzgunz"
            },
            {
                "value": 39958,
                "label": "Aitrang",
                "label_ar": "أيترانج",
                "label_fr": "Aitrang"
            },
            {
                "value": 39959,
                "label": "Ottobeuren",
                "label_ar": "Ottobeuren",
                "label_fr": "Ottobeuren"
            },
            {
                "value": 39960,
                "label": "Gronenbach",
                "label_ar": "جرونينباخ",
                "label_fr": "Gronenbach"
            },
            {
                "value": 39961,
                "label": "Altenstadt",
                "label_ar": "التنشتات",
                "label_fr": "Altenstadt"
            },
            {
                "value": 39962,
                "label": "Friesenried",
                "label_ar": "فريزينريد",
                "label_fr": "Frites"
            },
            {
                "value": 39963,
                "label": "Rieden",
                "label_ar": "ريدن",
                "label_fr": "Rieden"
            },
            {
                "value": 39964,
                "label": "Rosshaupten",
                "label_ar": "روسهاوبتن",
                "label_fr": "Rosshaupten"
            },
            {
                "value": 39965,
                "label": "Unterthingau",
                "label_ar": "انتيرثينجاو",
                "label_fr": "Unterthingau"
            },
            {
                "value": 39966,
                "label": "Altenstadt",
                "label_ar": "التنشتات",
                "label_fr": "Altenstadt"
            },
            {
                "value": 39967,
                "label": "Leopoldshohe",
                "label_ar": "ليوبولدشوهي",
                "label_fr": "Leopoldshohe"
            },
            {
                "value": 39968,
                "label": "Muhlen",
                "label_ar": "مهلين",
                "label_fr": "Muhlen"
            },
            {
                "value": 39969,
                "label": "Augustdorf",
                "label_ar": "أوغستدورف",
                "label_fr": "Augustdorf"
            },
            {
                "value": 39970,
                "label": "Rieden",
                "label_ar": "ريدن",
                "label_fr": "Rieden"
            },
            {
                "value": 39971,
                "label": "Lemforde",
                "label_ar": "ليمفورد",
                "label_fr": "Lemforde"
            },
            {
                "value": 39972,
                "label": "Essen",
                "label_ar": "إيسن",
                "label_fr": "Essen"
            },
            {
                "value": 39973,
                "label": "Lienen",
                "label_ar": "لينين",
                "label_fr": "Lienen"
            },
            {
                "value": 39974,
                "label": "Rehden",
                "label_ar": "ريدين",
                "label_fr": "Rehden"
            },
            {
                "value": 39975,
                "label": "Bohmte",
                "label_ar": "بومتي",
                "label_fr": "Bohmte"
            },
            {
                "value": 39976,
                "label": "Unkel",
                "label_ar": "أونكل",
                "label_fr": "Unkel"
            },
            {
                "value": 39977,
                "label": "Rettigheim",
                "label_ar": "ريتيجيم",
                "label_fr": "Rettigheim"
            },
            {
                "value": 39978,
                "label": "Bockenem",
                "label_ar": "بوكينيم",
                "label_fr": "Bockenem"
            },
            {
                "value": 39979,
                "label": "Bleckede",
                "label_ar": "Bleckede",
                "label_fr": "Bleckede"
            },
            {
                "value": 39980,
                "label": "Astfeld",
                "label_ar": "أستفيلد",
                "label_fr": "Astfeld"
            },
            {
                "value": 39981,
                "label": "Unterluss",
                "label_ar": "أونترلس",
                "label_fr": "Unterluss"
            },
            {
                "value": 39982,
                "label": "Tulau",
                "label_ar": "تولاو",
                "label_fr": "Tulau"
            },
            {
                "value": 39983,
                "label": "Lehmke",
                "label_ar": "Lehmke",
                "label_fr": "Lehmke"
            },
            {
                "value": 39984,
                "label": "Eschwege",
                "label_ar": "Eschwege",
                "label_fr": "Eschwege"
            },
            {
                "value": 39985,
                "label": "Bad Arolsen",
                "label_ar": "باد أرولسن",
                "label_fr": "Bad Arolsen"
            },
            {
                "value": 39986,
                "label": "Waldkappel",
                "label_ar": "والدكابل",
                "label_fr": "Waldkappel"
            },
            {
                "value": 39987,
                "label": "Trendelburg",
                "label_ar": "ترندلبورج",
                "label_fr": "Trendelburg"
            },
            {
                "value": 39988,
                "label": "Wanfried",
                "label_ar": "وانفريد",
                "label_fr": "Wanfried"
            },
            {
                "value": 39989,
                "label": "Westerdeichstrich",
                "label_ar": "Westerdeichstrich",
                "label_fr": "Westerdeichstrich"
            },
            {
                "value": 39990,
                "label": "Schwabstedt",
                "label_ar": "شوابستيدت",
                "label_fr": "Schwabstedt"
            },
            {
                "value": 39991,
                "label": "Rodewisch",
                "label_ar": "رودويش",
                "label_fr": "Rodewisch"
            },
            {
                "value": 39992,
                "label": "Geilenkirchen",
                "label_ar": "جيلينكيرشن",
                "label_fr": "Geilenkirchen"
            },
            {
                "value": 39993,
                "label": "Hurtgenwald",
                "label_ar": "هرتجنوالد",
                "label_fr": "Hurtgenwald"
            },
            {
                "value": 39994,
                "label": "Juchen",
                "label_ar": "جوتشين",
                "label_fr": "Juchen"
            },
            {
                "value": 39995,
                "label": "Schleiden",
                "label_ar": "شلايدن",
                "label_fr": "Schleiden"
            },
            {
                "value": 39996,
                "label": "Gangelt",
                "label_ar": "جانجيلت",
                "label_fr": "Gangelt"
            },
            {
                "value": 39997,
                "label": "Rutesheim",
                "label_ar": "روتسيم",
                "label_fr": "Rutesheim"
            },
            {
                "value": 39998,
                "label": "Kastellaun",
                "label_ar": "كاستلاون",
                "label_fr": "Kastellaun"
            },
            {
                "value": 39999,
                "label": "Simmern",
                "label_ar": "سيمرن",
                "label_fr": "Simmern"
            },
            {
                "value": 40000,
                "label": "Eppelsheim",
                "label_ar": "ابيلشيم",
                "label_fr": "Eppelsheim"
            },
            {
                "value": 40001,
                "label": "Gau-Odernheim",
                "label_ar": "جاو أودرنهايم",
                "label_fr": "Gau-Odernheim"
            },
            {
                "value": 40002,
                "label": "Miehlen",
                "label_ar": "ميلين",
                "label_fr": "Miehlen"
            },
            {
                "value": 40003,
                "label": "Rheinbollen",
                "label_ar": "راينبولين",
                "label_fr": "Rheinbollen"
            },
            {
                "value": 40004,
                "label": "Ustersbach",
                "label_ar": "أوسترسباخ",
                "label_fr": "Ustersbach"
            },
            {
                "value": 40005,
                "label": "Waidhaus",
                "label_ar": "ويدهاوس",
                "label_fr": "Waidhaus"
            },
            {
                "value": 40006,
                "label": "Schirnding",
                "label_ar": "شيرندنج",
                "label_fr": "Schirnding"
            },
            {
                "value": 40007,
                "label": "Pechbrunn",
                "label_ar": "بيشبرون",
                "label_fr": "Pechbrunn"
            },
            {
                "value": 40008,
                "label": "Vohenstrauss",
                "label_ar": "فوهينشتراوس",
                "label_fr": "Vohenstrauss"
            },
            {
                "value": 40009,
                "label": "Marktleugast",
                "label_ar": "ماركتلوغاست",
                "label_fr": "Marktleugast"
            },
            {
                "value": 40010,
                "label": "Parkstein",
                "label_ar": "باركستين",
                "label_fr": "Parkstein"
            },
            {
                "value": 40011,
                "label": "Kueps Oberfranken",
                "label_ar": "كويبس أوبرفرانكن",
                "label_fr": "Kueps Oberfranken"
            },
            {
                "value": 40012,
                "label": "Gestungshausen",
                "label_ar": "جيستونغسهاوزن",
                "label_fr": "Gestungshausen"
            },
            {
                "value": 40013,
                "label": "Bad Berneck im Fichtelgebirge",
                "label_ar": "Bad Berneck im Fichtelgebirge",
                "label_fr": "Bad Berneck im Fichtelgebirge"
            },
            {
                "value": 40014,
                "label": "Etzenricht",
                "label_ar": "إتزنريخت",
                "label_fr": "Etzenricht"
            },
            {
                "value": 40015,
                "label": "Gleiritsch",
                "label_ar": "جليريتش",
                "label_fr": "Gleiritsch"
            },
            {
                "value": 40016,
                "label": "Schauenstein",
                "label_ar": "شاونشتاين",
                "label_fr": "Schauenstein"
            },
            {
                "value": 40017,
                "label": "Leonberg",
                "label_ar": "ليونبرج",
                "label_fr": "Leonberg"
            },
            {
                "value": 40018,
                "label": "Marktleuthen",
                "label_ar": "ماركتلوثن",
                "label_fr": "Marktleuthen"
            },
            {
                "value": 40019,
                "label": "Buchbach",
                "label_ar": "بوخباخ",
                "label_fr": "Buchbach"
            },
            {
                "value": 40020,
                "label": "Niederneuching",
                "label_ar": "Niederneuching",
                "label_fr": "Niederneuching"
            },
            {
                "value": 40021,
                "label": "Haag",
                "label_ar": "هاج",
                "label_fr": "Haag"
            },
            {
                "value": 40022,
                "label": "Reithofen",
                "label_ar": "ريثوفين",
                "label_fr": "Reithofen"
            },
            {
                "value": 40023,
                "label": "Gross Gronau",
                "label_ar": "جروس جروناو",
                "label_fr": "Gross Gronau"
            },
            {
                "value": 40024,
                "label": "Hasenmoor",
                "label_ar": "هاسنمور",
                "label_fr": "Hasenmoor"
            },
            {
                "value": 40025,
                "label": "Rethwisch",
                "label_ar": "ريثويش",
                "label_fr": "Rethwisch"
            },
            {
                "value": 40026,
                "label": "Stahnsdorf",
                "label_ar": "ستاهنسدورف",
                "label_fr": "Stahnsdorf"
            },
            {
                "value": 40027,
                "label": "Schonfliess",
                "label_ar": "شونفليس",
                "label_fr": "Schonfliess"
            },
            {
                "value": 40028,
                "label": "Borkheide",
                "label_ar": "بوركيدي",
                "label_fr": "Borkheide"
            },
            {
                "value": 40029,
                "label": "Borkwalde",
                "label_ar": "بوركوالد",
                "label_fr": "Borkwalde"
            },
            {
                "value": 40030,
                "label": "Nennhausen",
                "label_ar": "نينهاوزن",
                "label_fr": "Nennhausen"
            },
            {
                "value": 40031,
                "label": "Gorenzen",
                "label_ar": "جورينزن",
                "label_fr": "Gorenzen"
            },
            {
                "value": 40032,
                "label": "Nebra",
                "label_ar": "نبرا",
                "label_fr": "Nebra"
            },
            {
                "value": 40033,
                "label": "Wulfen",
                "label_ar": "وولفين",
                "label_fr": "Wulfen"
            },
            {
                "value": 40034,
                "label": "Bennungen",
                "label_ar": "بينونجن",
                "label_fr": "Bennungen"
            },
            {
                "value": 40035,
                "label": "Bad Schmiedeberg",
                "label_ar": "باد شميدبرج",
                "label_fr": "Bad Schmiedeberg"
            },
            {
                "value": 40036,
                "label": "Goseck",
                "label_ar": "جوسيك",
                "label_fr": "Goseck"
            },
            {
                "value": 40037,
                "label": "Eckartsberga",
                "label_ar": "إيكارتسبيرجا",
                "label_fr": "Eckartsberga"
            },
            {
                "value": 40038,
                "label": "Apen",
                "label_ar": "قلم",
                "label_fr": "Un stylo"
            },
            {
                "value": 40039,
                "label": "Voslapp",
                "label_ar": "فوسلاب",
                "label_fr": "Voslapp"
            },
            {
                "value": 40040,
                "label": "Emstek",
                "label_ar": "Emstek",
                "label_fr": "Emstek"
            },
            {
                "value": 40041,
                "label": "Linkenheim-Hochstetten",
                "label_ar": "لينكينهايم هوشستيتين",
                "label_fr": "Linkenheim-Hochstetten"
            },
            {
                "value": 40042,
                "label": "Forst",
                "label_ar": "فورست",
                "label_fr": "Forst"
            },
            {
                "value": 40043,
                "label": "Marschacht",
                "label_ar": "مارشخت",
                "label_fr": "Marschacht"
            },
            {
                "value": 40044,
                "label": "Kothel",
                "label_ar": "Kothel",
                "label_fr": "Kothel"
            },
            {
                "value": 40045,
                "label": "Weddelbrook",
                "label_ar": "ويدلبروك",
                "label_fr": "Weddelbrook"
            },
            {
                "value": 40046,
                "label": "Grossensee",
                "label_ar": "جروسينسي",
                "label_fr": "Grossensee"
            },
            {
                "value": 40047,
                "label": "Rausdorf",
                "label_ar": "راوزدورف",
                "label_fr": "Rausdorf"
            },
            {
                "value": 40048,
                "label": "Krummesse",
                "label_ar": "كروميسي",
                "label_fr": "Krummesse"
            },
            {
                "value": 40049,
                "label": "Niendorf",
                "label_ar": "نيندورف",
                "label_fr": "Niendorf"
            },
            {
                "value": 40050,
                "label": "Ellhofen",
                "label_ar": "الهوفن",
                "label_fr": "Ellhofen"
            },
            {
                "value": 40051,
                "label": "Waldenburg",
                "label_ar": "والدنبورغ",
                "label_fr": "Waldenburg"
            },
            {
                "value": 40052,
                "label": "Hohenlinden",
                "label_ar": "هوهنليندن",
                "label_fr": "Hohenlinden"
            },
            {
                "value": 40053,
                "label": "Buch am Buchrain",
                "label_ar": "بوخ آم بوخرين",
                "label_fr": "Buch am Buchrain"
            },
            {
                "value": 40054,
                "label": "Oberornau",
                "label_ar": "أوبيرورناو",
                "label_fr": "Oberornau"
            },
            {
                "value": 40055,
                "label": "Edling",
                "label_ar": "إيدلينج",
                "label_fr": "Edling"
            },
            {
                "value": 40056,
                "label": "Mittbach",
                "label_ar": "ميتباخ",
                "label_fr": "Mittbach"
            },
            {
                "value": 40057,
                "label": "Gars",
                "label_ar": "غارس",
                "label_fr": "Gars"
            },
            {
                "value": 40058,
                "label": "Schliersee",
                "label_ar": "شليرسي",
                "label_fr": "Schliersee"
            },
            {
                "value": 40059,
                "label": "Andechs",
                "label_ar": "أنديكس",
                "label_fr": "Andechs"
            },
            {
                "value": 40060,
                "label": "Velden",
                "label_ar": "فيلدين",
                "label_fr": "Velden"
            },
            {
                "value": 40061,
                "label": "Egmating",
                "label_ar": "تذمر",
                "label_fr": "Egmating"
            },
            {
                "value": 40062,
                "label": "Herrsching am Ammersee",
                "label_ar": "Herrsching am Ammersee",
                "label_fr": "Herrsching am Ammersee"
            },
            {
                "value": 40063,
                "label": "Seefeld",
                "label_ar": "سيفيلد",
                "label_fr": "Seefeld"
            },
            {
                "value": 40064,
                "label": "Bernried",
                "label_ar": "بيرنريد",
                "label_fr": "Bernried"
            },
            {
                "value": 40065,
                "label": "Baldham",
                "label_ar": "بالدهام",
                "label_fr": "Baldham"
            },
            {
                "value": 40066,
                "label": "Babensham",
                "label_ar": "بابينشام",
                "label_fr": "Babensham"
            },
            {
                "value": 40067,
                "label": "Hausham",
                "label_ar": "هوشام",
                "label_fr": "Hausham"
            },
            {
                "value": 40068,
                "label": "Schwaig",
                "label_ar": "شفايج",
                "label_fr": "Schwaig"
            },
            {
                "value": 40069,
                "label": "Kirchen",
                "label_ar": "كيرشن",
                "label_fr": "Kirchen"
            },
            {
                "value": 40070,
                "label": "Tabarz",
                "label_ar": "طبرز",
                "label_fr": "Tabarz"
            },
            {
                "value": 40071,
                "label": "Gorkwitz",
                "label_ar": "جوركويتز",
                "label_fr": "Gorkwitz"
            },
            {
                "value": 40072,
                "label": "Schwabhausen",
                "label_ar": "شوابهاوزن",
                "label_fr": "Schwabhausen"
            },
            {
                "value": 40073,
                "label": "Grainau",
                "label_ar": "جرايناو",
                "label_fr": "Grainau"
            },
            {
                "value": 40074,
                "label": "Forstinning",
                "label_ar": "فورستينينج",
                "label_fr": "Forstinning"
            },
            {
                "value": 40075,
                "label": "Benediktbeuern",
                "label_ar": "Benediktbeuern",
                "label_fr": "Benediktbeuern"
            },
            {
                "value": 40076,
                "label": "Farchant",
                "label_ar": "فارتشانت",
                "label_fr": "Farchant"
            },
            {
                "value": 40077,
                "label": "Odelzhausen",
                "label_ar": "Odelzhausen",
                "label_fr": "Odelzhausen"
            },
            {
                "value": 40078,
                "label": "Hodenhagen",
                "label_ar": "هودينهاغن",
                "label_fr": "Hodenhagen"
            },
            {
                "value": 40079,
                "label": "Hoheneggelsen",
                "label_ar": "Hoheneggelsen",
                "label_fr": "Hoheneggelsen"
            },
            {
                "value": 40080,
                "label": "Kirchhundem",
                "label_ar": "كيرشوندم",
                "label_fr": "Kirchhundem"
            },
            {
                "value": 40081,
                "label": "Horgertshausen",
                "label_ar": "هورجيرتسهاوزن",
                "label_fr": "Horgertshausen"
            },
            {
                "value": 40082,
                "label": "Tiefenbach",
                "label_ar": "تيفينباخ",
                "label_fr": "Tiefenbach"
            },
            {
                "value": 40083,
                "label": "Berglern",
                "label_ar": "بيرجليرن",
                "label_fr": "Berglern"
            },
            {
                "value": 40084,
                "label": "Wartenberg",
                "label_ar": "وارتنبرغ",
                "label_fr": "Wartenberg"
            },
            {
                "value": 40085,
                "label": "Winzeln",
                "label_ar": "Winzeln",
                "label_fr": "Winzeln"
            },
            {
                "value": 40086,
                "label": "Schomberg",
                "label_ar": "شومبرج",
                "label_fr": "Schomberg"
            },
            {
                "value": 40087,
                "label": "Durchhausen",
                "label_ar": "دورشهاوزن",
                "label_fr": "Durchhausen"
            },
            {
                "value": 40088,
                "label": "Empfingen",
                "label_ar": "امبفينجين",
                "label_fr": "Empfingen"
            },
            {
                "value": 40089,
                "label": "Dornhan",
                "label_ar": "دورنان",
                "label_fr": "Dornhan"
            },
            {
                "value": 40090,
                "label": "Frasdorf",
                "label_ar": "فراسدورف",
                "label_fr": "Frasdorf"
            },
            {
                "value": 40091,
                "label": "Unterneukirchen",
                "label_ar": "Unterneukirchen",
                "label_fr": "Unterneukirchen"
            },
            {
                "value": 40092,
                "label": "Ruhpolding",
                "label_ar": "روهبولدينج",
                "label_fr": "Ruhpolding"
            },
            {
                "value": 40093,
                "label": "Hoslwang",
                "label_ar": "هوسلوانغ",
                "label_fr": "Hoslwang"
            },
            {
                "value": 40094,
                "label": "Bad Endorf",
                "label_ar": "سيئة إندورف",
                "label_fr": "Bad Endorf"
            },
            {
                "value": 40095,
                "label": "Eggstatt",
                "label_ar": "إيجستات",
                "label_fr": "Eggstatt"
            },
            {
                "value": 40096,
                "label": "Engelsberg",
                "label_ar": "إنجلسبيرج",
                "label_fr": "Engelsberg"
            },
            {
                "value": 40097,
                "label": "Kirchweidach",
                "label_ar": "كيرشويداخ",
                "label_fr": "Kirchweidach"
            },
            {
                "value": 40098,
                "label": "Neuberg",
                "label_ar": "نوبيرج",
                "label_fr": "Neuberg"
            },
            {
                "value": 40099,
                "label": "Neuberg",
                "label_ar": "نوبيرج",
                "label_fr": "Neuberg"
            },
            {
                "value": 40100,
                "label": "Leimbach",
                "label_ar": "ليمباخ",
                "label_fr": "Leimbach"
            },
            {
                "value": 40101,
                "label": "Breitenbach",
                "label_ar": "بريتينباخ",
                "label_fr": "Breitenbach"
            },
            {
                "value": 40102,
                "label": "Homberg",
                "label_ar": "هومبرج",
                "label_fr": "Homberg"
            },
            {
                "value": 40103,
                "label": "Nentershausen",
                "label_ar": "نينترسهاوزن",
                "label_fr": "Nentershausen"
            },
            {
                "value": 40104,
                "label": "Jesberg",
                "label_ar": "جيسبرج",
                "label_fr": "Jesberg"
            },
            {
                "value": 40105,
                "label": "Bergen",
                "label_ar": "بيرغن",
                "label_fr": "Bergen"
            },
            {
                "value": 40106,
                "label": "Coppenbrugge",
                "label_ar": "كوبينبروج",
                "label_fr": "Coppenbrugge"
            },
            {
                "value": 40107,
                "label": "Hofer",
                "label_ar": "هوفر",
                "label_fr": "Hofer"
            },
            {
                "value": 40108,
                "label": "Hademstorf",
                "label_ar": "هادمستورف",
                "label_fr": "Hademstorf"
            },
            {
                "value": 40109,
                "label": "Niedernwohren",
                "label_ar": "نيديرنورين",
                "label_fr": "Niedernwohren"
            },
            {
                "value": 40110,
                "label": "Hespe",
                "label_ar": "هيسبي",
                "label_fr": "Hespe"
            },
            {
                "value": 40111,
                "label": "Dankmarshausen",
                "label_ar": "Dankmarshausen",
                "label_fr": "Dankmarshausen"
            },
            {
                "value": 40112,
                "label": "Tann",
                "label_ar": "تان",
                "label_fr": "Tann"
            },
            {
                "value": 40113,
                "label": "Schwalmtal",
                "label_ar": "شوالمتال",
                "label_fr": "Schwalmtal"
            },
            {
                "value": 40114,
                "label": "Sterbfritz",
                "label_ar": "ستيربفريتز",
                "label_fr": "Sterbfritz"
            },
            {
                "value": 40115,
                "label": "Kirchheim",
                "label_ar": "كيرشهايم",
                "label_fr": "Kirchheim"
            },
            {
                "value": 40116,
                "label": "Obersuhl",
                "label_ar": "أوبيرسول",
                "label_fr": "Obersuhl"
            },
            {
                "value": 40117,
                "label": "Bebra",
                "label_ar": "بيبرا",
                "label_fr": "Bebra"
            },
            {
                "value": 40118,
                "label": "Heringen",
                "label_ar": "هرينجن",
                "label_fr": "Heringen"
            },
            {
                "value": 40119,
                "label": "Gilserberg",
                "label_ar": "جيلسيربيرج",
                "label_fr": "Gilserberg"
            },
            {
                "value": 40120,
                "label": "Hilders",
                "label_ar": "هيلدرز",
                "label_fr": "Hilders"
            },
            {
                "value": 40121,
                "label": "Petersberg",
                "label_ar": "بيترسبرغ",
                "label_fr": "Petersberg"
            },
            {
                "value": 40122,
                "label": "Mottgers",
                "label_ar": "موتجرز",
                "label_fr": "Mottgers"
            },
            {
                "value": 40123,
                "label": "Schenklengsfeld",
                "label_ar": "شينكلنغسفيلد",
                "label_fr": "Schenklengsfeld"
            },
            {
                "value": 40124,
                "label": "Philippsthal",
                "label_ar": "فيليبسثال",
                "label_fr": "Philippsthal"
            },
            {
                "value": 40125,
                "label": "Wippershain",
                "label_ar": "Wippershain",
                "label_fr": "Wippershain"
            },
            {
                "value": 40126,
                "label": "Meuro",
                "label_ar": "ميورو",
                "label_fr": "Meuro"
            },
            {
                "value": 40127,
                "label": "Plessa",
                "label_ar": "بليسا",
                "label_fr": "Plessa"
            },
            {
                "value": 40128,
                "label": "Brauweiler",
                "label_ar": "براويلر",
                "label_fr": "Brauweiler"
            },
            {
                "value": 40129,
                "label": "Vettweiss",
                "label_ar": "فيتويس",
                "label_fr": "Vettweiss"
            },
            {
                "value": 40130,
                "label": "Winkelhaid",
                "label_ar": "وينكلهيد",
                "label_fr": "Winkelhaid"
            },
            {
                "value": 40131,
                "label": "Uehlfeld",
                "label_ar": "أوليفيلد",
                "label_fr": "Uehlfeld"
            },
            {
                "value": 40132,
                "label": "Cadolzburg",
                "label_ar": "كادولزبورغ",
                "label_fr": "Cadolzburg"
            },
            {
                "value": 40133,
                "label": "Strullendorf",
                "label_ar": "ستروليندورف",
                "label_fr": "Strullendorf"
            },
            {
                "value": 40134,
                "label": "Meeder",
                "label_ar": "ميدر",
                "label_fr": "Meeder"
            },
            {
                "value": 40135,
                "label": "Bad Staffelstein",
                "label_ar": "باد ستافيلشتاين",
                "label_fr": "Bad Staffelstein"
            },
            {
                "value": 40136,
                "label": "Altdorf",
                "label_ar": "التدورف",
                "label_fr": "Altdorf"
            },
            {
                "value": 40137,
                "label": "Winkelhaid",
                "label_ar": "وينكلهيد",
                "label_fr": "Winkelhaid"
            },
            {
                "value": 40138,
                "label": "Memmelsdorf",
                "label_ar": "ميميلسدورف",
                "label_fr": "Memmelsdorf"
            },
            {
                "value": 40139,
                "label": "Jagstzell",
                "label_ar": "جاغستزيل",
                "label_fr": "Jagstzell"
            },
            {
                "value": 40140,
                "label": "Obermarchtal",
                "label_ar": "أوبيرمارشتال",
                "label_fr": "Obermarchtal"
            },
            {
                "value": 40141,
                "label": "Spraitbach",
                "label_ar": "شبرايتباخ",
                "label_fr": "Spraitbach"
            },
            {
                "value": 40142,
                "label": "Ingelfingen",
                "label_ar": "Ingelfingen",
                "label_fr": "Ingelfingen"
            },
            {
                "value": 40143,
                "label": "Alfdorf",
                "label_ar": "ألفدورف",
                "label_fr": "Alfdorf"
            },
            {
                "value": 40144,
                "label": "Fichtenberg",
                "label_ar": "فيشتنبرغ",
                "label_fr": "Fichtenberg"
            },
            {
                "value": 40145,
                "label": "Kaldenkirchen",
                "label_ar": "كالدينكيرشن",
                "label_fr": "Kaldenkirchen"
            },
            {
                "value": 40146,
                "label": "Aach",
                "label_ar": "آخ",
                "label_fr": "Aach"
            },
            {
                "value": 40147,
                "label": "Bodman-Ludwigshafen",
                "label_ar": "بودمان لودفيجشافن",
                "label_fr": "Bodman-Ludwigshafen"
            },
            {
                "value": 40148,
                "label": "Willstatt",
                "label_ar": "ويلستات",
                "label_fr": "Willstatt"
            },
            {
                "value": 40149,
                "label": "Rheinau",
                "label_ar": "رايناو",
                "label_fr": "Rheinau"
            },
            {
                "value": 40150,
                "label": "Seelbach",
                "label_ar": "سيلباخ",
                "label_fr": "Seelbach"
            },
            {
                "value": 40151,
                "label": "Hornberg",
                "label_ar": "هورنبرج",
                "label_fr": "Hornberg"
            },
            {
                "value": 40152,
                "label": "Oppenau",
                "label_ar": "اوبيناو",
                "label_fr": "Oppenau"
            },
            {
                "value": 40153,
                "label": "Wolfach",
                "label_ar": "وولفاتش",
                "label_fr": "Wolfach"
            },
            {
                "value": 40154,
                "label": "Balve",
                "label_ar": "بالفي",
                "label_fr": "Balve"
            },
            {
                "value": 40155,
                "label": "Saerbeck",
                "label_ar": "سيربيك",
                "label_fr": "Saerbeck"
            },
            {
                "value": 40156,
                "label": "Lichtenstein",
                "label_ar": "ليختنشتاين",
                "label_fr": "Lichtenstein"
            },
            {
                "value": 40157,
                "label": "Bohmenkirch",
                "label_ar": "Bohmenkirch",
                "label_fr": "Bohmenkirch"
            },
            {
                "value": 40158,
                "label": "Schelklingen",
                "label_ar": "شيلكلينجن",
                "label_fr": "Schelklingen"
            },
            {
                "value": 40159,
                "label": "Gingen an der Fils",
                "label_ar": "Gingen an der Fils",
                "label_fr": "Gingen an der Fils"
            },
            {
                "value": 40160,
                "label": "Durlangen",
                "label_ar": "دورلانجين",
                "label_fr": "Durlangen"
            },
            {
                "value": 40161,
                "label": "Isny im Allgau",
                "label_ar": "إيسني إم ألغاو",
                "label_fr": "Isny im Allgau"
            },
            {
                "value": 40162,
                "label": "Hattenhofen",
                "label_ar": "هاتنهوفن",
                "label_fr": "Hattenhofen"
            },
            {
                "value": 40163,
                "label": "Erolzheim",
                "label_ar": "إيرولزهايم",
                "label_fr": "Erolzheim"
            },
            {
                "value": 40164,
                "label": "Nellingen",
                "label_ar": "نلينجين",
                "label_fr": "Nellingen"
            },
            {
                "value": 40165,
                "label": "Bad Buchau",
                "label_ar": "باد بوتشاو",
                "label_fr": "Bad Buchau"
            },
            {
                "value": 40166,
                "label": "Gruibingen",
                "label_ar": "Gruibingen",
                "label_fr": "Gruibingen"
            },
            {
                "value": 40167,
                "label": "Drensteinfurt",
                "label_ar": "درينستينفورت",
                "label_fr": "Drensteinfurt"
            },
            {
                "value": 40168,
                "label": "Heek",
                "label_ar": "هيك",
                "label_fr": "Heek"
            },
            {
                "value": 40169,
                "label": "Ennigerloh",
                "label_ar": "Ennigerloh",
                "label_fr": "Ennigerloh"
            },
            {
                "value": 40170,
                "label": "Rattenkirchen",
                "label_ar": "راتينكيرشن",
                "label_fr": "Rattenkirchen"
            },
            {
                "value": 40171,
                "label": "Ubersee",
                "label_ar": "اوبرسي",
                "label_fr": "Ubersee"
            },
            {
                "value": 40172,
                "label": "Kraiburg am Inn",
                "label_ar": "كرايبورغ آم إن",
                "label_fr": "Kraiburg am Inn"
            },
            {
                "value": 40173,
                "label": "Reit im Winkl",
                "label_ar": "ريت ايم وينكل",
                "label_fr": "Reit im Winkl"
            },
            {
                "value": 40174,
                "label": "Taching am See",
                "label_ar": "تاشينج صباحا سيري",
                "label_fr": "Taching am See"
            },
            {
                "value": 40175,
                "label": "Ampfing",
                "label_ar": "Ampfing",
                "label_fr": "Ampfing"
            },
            {
                "value": 40176,
                "label": "Tacherting",
                "label_ar": "تاشيرتينج",
                "label_fr": "Tacherting"
            },
            {
                "value": 40177,
                "label": "Marktl",
                "label_ar": "ماركتل",
                "label_fr": "Marktl"
            },
            {
                "value": 40178,
                "label": "Siegsdorf",
                "label_ar": "سيغسدورف",
                "label_fr": "Siegsdorf"
            },
            {
                "value": 40179,
                "label": "Anger",
                "label_ar": "الغضب",
                "label_fr": "Colère"
            },
            {
                "value": 40180,
                "label": "Ohningen",
                "label_ar": "Ohningen",
                "label_fr": "Ohningen"
            },
            {
                "value": 40181,
                "label": "Moos",
                "label_ar": "موس",
                "label_fr": "Moos"
            },
            {
                "value": 40182,
                "label": "Schonach im Schwarzwald",
                "label_ar": "Schonach im Schwarzwald",
                "label_fr": "Schonach im Schwarzwald"
            },
            {
                "value": 40183,
                "label": "Aftholderberg",
                "label_ar": "Aftholderberg",
                "label_fr": "Aftholderberg"
            },
            {
                "value": 40184,
                "label": "Neufrach",
                "label_ar": "نيوفراتش",
                "label_fr": "Neufrach"
            },
            {
                "value": 40185,
                "label": "Gailingen",
                "label_ar": "غيلنغن",
                "label_fr": "Gailingen"
            },
            {
                "value": 40186,
                "label": "Muhlhausen-Ehingen",
                "label_ar": "مولهاوزن إيهينغن",
                "label_fr": "Muhlhausen-Ehingen"
            },
            {
                "value": 40187,
                "label": "Ronsberg",
                "label_ar": "رونسبرج",
                "label_fr": "Ronsberg"
            },
            {
                "value": 40188,
                "label": "Pforzen",
                "label_ar": "بفورزين",
                "label_fr": "Pforzen"
            },
            {
                "value": 40189,
                "label": "Niederrieden",
                "label_ar": "نيدريدن",
                "label_fr": "Niederrieden"
            },
            {
                "value": 40190,
                "label": "Pfronten",
                "label_ar": "بفرونتن",
                "label_fr": "Pfronten"
            },
            {
                "value": 40191,
                "label": "Boos",
                "label_ar": "بوو",
                "label_fr": "Boos"
            },
            {
                "value": 40192,
                "label": "Jengen",
                "label_ar": "جينجين",
                "label_fr": "Jengen"
            },
            {
                "value": 40193,
                "label": "Lautrach",
                "label_ar": "لوتراش",
                "label_fr": "Lautrach"
            },
            {
                "value": 40194,
                "label": "Missen-Wilhams",
                "label_ar": "ميسن ويلهامز",
                "label_fr": "Missen-Wilhams"
            },
            {
                "value": 40195,
                "label": "Westerheim",
                "label_ar": "ويسترهايم",
                "label_fr": "Westerheim"
            },
            {
                "value": 40196,
                "label": "Kirchdorf",
                "label_ar": "كيرشدورف",
                "label_fr": "Kirchdorf"
            },
            {
                "value": 40197,
                "label": "Mulsen",
                "label_ar": "مولسن",
                "label_fr": "Mulsen"
            },
            {
                "value": 40198,
                "label": "Neuhausen",
                "label_ar": "نيوهاوزن",
                "label_fr": "Neuhausen"
            },
            {
                "value": 40199,
                "label": "Klingenthal",
                "label_ar": "كلينجنثال",
                "label_fr": "Klingenthal"
            },
            {
                "value": 40200,
                "label": "Muhlau",
                "label_ar": "موهلاو",
                "label_fr": "Muhlau"
            },
            {
                "value": 40201,
                "label": "Oberschona",
                "label_ar": "أوبيرشونا",
                "label_fr": "Oberschona"
            },
            {
                "value": 40202,
                "label": "Oederan",
                "label_ar": "Oederan",
                "label_fr": "Oederan"
            },
            {
                "value": 40203,
                "label": "Hartmannsdorf",
                "label_ar": "هارتمانسدورف",
                "label_fr": "Hartmannsdorf"
            },
            {
                "value": 40204,
                "label": "Auerbach",
                "label_ar": "أورباخ",
                "label_fr": "Auerbach"
            },
            {
                "value": 40205,
                "label": "Lauter",
                "label_ar": "لوتر",
                "label_fr": "Lauter"
            },
            {
                "value": 40206,
                "label": "Taura",
                "label_ar": "تورا",
                "label_fr": "Taura"
            },
            {
                "value": 40207,
                "label": "Gelenau",
                "label_ar": "جيليناو",
                "label_fr": "Gelenau"
            },
            {
                "value": 40208,
                "label": "Zschopau",
                "label_ar": "زشوباو",
                "label_fr": "Zschopau"
            },
            {
                "value": 40209,
                "label": "Werda",
                "label_ar": "ويردا",
                "label_fr": "Werda"
            },
            {
                "value": 40210,
                "label": "Neckarsteinach",
                "label_ar": "نيكارستيناخ",
                "label_fr": "Neckarsteinach"
            },
            {
                "value": 40211,
                "label": "Meckesheim",
                "label_ar": "ميكيشيم",
                "label_fr": "Meckesheim"
            },
            {
                "value": 40212,
                "label": "Helmstadt",
                "label_ar": "هيلمشتات",
                "label_fr": "Helmstadt"
            },
            {
                "value": 40213,
                "label": "Schieder-Schwalenberg",
                "label_ar": "شيدر شوالنبرغ",
                "label_fr": "Schieder-Schwalenberg"
            },
            {
                "value": 40214,
                "label": "Kurort Steinbach-Hallenberg",
                "label_ar": "كورورت شتاينباخ هالينبيرج",
                "label_fr": "Kurort Steinbach-Hallenberg"
            },
            {
                "value": 40215,
                "label": "Bad Liebenstein",
                "label_ar": "باد ليبنشتاين",
                "label_fr": "Bad Liebenstein"
            },
            {
                "value": 40216,
                "label": "Hattstedt",
                "label_ar": "هاتشتيدت",
                "label_fr": "Hattstedt"
            },
            {
                "value": 40217,
                "label": "Friedrichstadt",
                "label_ar": "فريدريشتات",
                "label_fr": "Friedrichstadt"
            },
            {
                "value": 40218,
                "label": "Dahme",
                "label_ar": "دهمي",
                "label_fr": "Dahme"
            },
            {
                "value": 40219,
                "label": "Brokstedt",
                "label_ar": "بروكسيدت",
                "label_fr": "Brokstedt"
            },
            {
                "value": 40220,
                "label": "Erfde",
                "label_ar": "ايرفدي",
                "label_fr": "Erfde"
            },
            {
                "value": 40221,
                "label": "Behrendorf",
                "label_ar": "بهرندورف",
                "label_fr": "Behrendorf"
            },
            {
                "value": 40222,
                "label": "Suderstapel",
                "label_ar": "سودرستابل",
                "label_fr": "Suderstapel"
            },
            {
                "value": 40223,
                "label": "Buchwalde",
                "label_ar": "بوكوالد",
                "label_fr": "Buchwalde"
            },
            {
                "value": 40224,
                "label": "Langenberg",
                "label_ar": "لانجينبيرج",
                "label_fr": "Langenberg"
            },
            {
                "value": 40225,
                "label": "Ehrenburg",
                "label_ar": "إرينبورغ",
                "label_fr": "Ehrenbourg"
            },
            {
                "value": 40226,
                "label": "Suhlendorf",
                "label_ar": "سوهليندورف",
                "label_fr": "Suhlendorf"
            },
            {
                "value": 40227,
                "label": "Driedorf",
                "label_ar": "دريدورف",
                "label_fr": "Driedorf"
            },
            {
                "value": 40228,
                "label": "Haiger",
                "label_ar": "هايجر",
                "label_fr": "Haiger"
            },
            {
                "value": 40229,
                "label": "Colbe",
                "label_ar": "كولب",
                "label_fr": "Colbe"
            },
            {
                "value": 40230,
                "label": "Wolframs-Eschenbach",
                "label_ar": "ولفرامز إشنباخ",
                "label_fr": "Wolframs-Eschenbach"
            },
            {
                "value": 40231,
                "label": "Lichtenfels",
                "label_ar": "ليشتنفيلس",
                "label_fr": "Lichtenfels"
            },
            {
                "value": 40232,
                "label": "Lauterhofen",
                "label_ar": "لوترهوفن",
                "label_fr": "Lauterhofen"
            },
            {
                "value": 40233,
                "label": "Petershagen",
                "label_ar": "بيترشاجين",
                "label_fr": "Petershagen"
            },
            {
                "value": 40234,
                "label": "Arnstorf",
                "label_ar": "Arnstorf",
                "label_fr": "Arnstorf"
            },
            {
                "value": 40235,
                "label": "Postmunster",
                "label_ar": "Postmunster",
                "label_fr": "Postmunster"
            },
            {
                "value": 40236,
                "label": "Neuhaus",
                "label_ar": "نيوهاوس",
                "label_fr": "Neuhaus"
            },
            {
                "value": 40237,
                "label": "Eggenfelden",
                "label_ar": "إغينفيلدن",
                "label_fr": "Eggenfelden"
            },
            {
                "value": 40238,
                "label": "Simbach am Inn",
                "label_ar": "سيمباخ آم إن",
                "label_fr": "Simbach am Inn"
            },
            {
                "value": 40239,
                "label": "Neukirchen am Teisenberg",
                "label_ar": "Neukirchen am Teisenberg",
                "label_fr": "Neukirchen am Teisenberg"
            },
            {
                "value": 40240,
                "label": "Pfarrkirchen",
                "label_ar": "بفاركيرشين",
                "label_fr": "Pfarrkirchen"
            },
            {
                "value": 40241,
                "label": "Gottfrieding",
                "label_ar": "جوتفريدنج",
                "label_fr": "Gottfrieding"
            },
            {
                "value": 40242,
                "label": "Neuhaus",
                "label_ar": "نيوهاوس",
                "label_fr": "Neuhaus"
            },
            {
                "value": 40243,
                "label": "Aldersbach",
                "label_ar": "ألدرسباخ",
                "label_fr": "Aldersbach"
            },
            {
                "value": 40244,
                "label": "Mallersdorf-Pfaffenberg",
                "label_ar": "مالرسدورف فافنبرج",
                "label_fr": "Mallersdorf-Pfaffenberg"
            },
            {
                "value": 40245,
                "label": "Ortenburg",
                "label_ar": "أورتنبرغ",
                "label_fr": "Ortenburg"
            },
            {
                "value": 40246,
                "label": "Chostlarn",
                "label_ar": "تشوستلارن",
                "label_fr": "Chostlarn"
            },
            {
                "value": 40247,
                "label": "Falkenberg",
                "label_ar": "فالكنبرج",
                "label_fr": "Falkenberg"
            },
            {
                "value": 40248,
                "label": "Wietze",
                "label_ar": "ويتز",
                "label_fr": "Wietze"
            },
            {
                "value": 40249,
                "label": "Eschede",
                "label_ar": "Eschede",
                "label_fr": "Eschede"
            },
            {
                "value": 40250,
                "label": "Haenigsen",
                "label_ar": "هاينيغسين",
                "label_fr": "Haenigsen"
            },
            {
                "value": 40251,
                "label": "Laubenheim",
                "label_ar": "لوبنهايم",
                "label_fr": "Laubenheim"
            },
            {
                "value": 40252,
                "label": "Biebelsheim",
                "label_ar": "بيبلشيم",
                "label_fr": "Biebelsheim"
            },
            {
                "value": 40253,
                "label": "Norheim",
                "label_ar": "نورهايم",
                "label_fr": "Norheim"
            },
            {
                "value": 40254,
                "label": "Simmertal",
                "label_ar": "سيميرتال",
                "label_fr": "Simmertal"
            },
            {
                "value": 40255,
                "label": "Sommerloch",
                "label_ar": "سومرلوخ",
                "label_fr": "Sommerloch"
            },
            {
                "value": 40256,
                "label": "Windesheim",
                "label_ar": "Windesheim",
                "label_fr": "Windesheim"
            },
            {
                "value": 40257,
                "label": "Welgesheim",
                "label_ar": "ويلجسهايم",
                "label_fr": "Welgesheim"
            },
            {
                "value": 40258,
                "label": "Boos",
                "label_ar": "بوو",
                "label_fr": "Boos"
            },
            {
                "value": 40259,
                "label": "Odernheim",
                "label_ar": "اودرنهايم",
                "label_fr": "Odernheim"
            },
            {
                "value": 40260,
                "label": "Pfaffen-Schwabenheim",
                "label_ar": "بفافين شوابنهايم",
                "label_fr": "Pfaffen-Schwabenheim"
            },
            {
                "value": 40261,
                "label": "Rudesheim",
                "label_ar": "روديشيم",
                "label_fr": "Rudesheim"
            },
            {
                "value": 40262,
                "label": "Altenberg",
                "label_ar": "ألتنبرغ",
                "label_fr": "Altenberg"
            },
            {
                "value": 40263,
                "label": "Bahratal",
                "label_ar": "بحرطل",
                "label_fr": "Bahratal"
            },
            {
                "value": 40264,
                "label": "Klipphausen",
                "label_ar": "كليبهاوزن",
                "label_fr": "Klipphausen"
            },
            {
                "value": 40265,
                "label": "Merkendorf",
                "label_ar": "ميركندورف",
                "label_fr": "Merkendorf"
            },
            {
                "value": 40266,
                "label": "Faulbach",
                "label_ar": "فولباخ",
                "label_fr": "Faulbach"
            },
            {
                "value": 40267,
                "label": "Kutenholz",
                "label_ar": "كوتنهولز",
                "label_fr": "Kutenholz"
            },
            {
                "value": 40268,
                "label": "Bederkesa",
                "label_ar": "بيدركسا",
                "label_fr": "Bederkesa"
            },
            {
                "value": 40269,
                "label": "Nordholz",
                "label_ar": "نوردهولز",
                "label_fr": "Nordholz"
            },
            {
                "value": 40270,
                "label": "Ruhland",
                "label_ar": "روهلاند",
                "label_fr": "Ruhland"
            },
            {
                "value": 40271,
                "label": "Lohsa",
                "label_ar": "Lohsa",
                "label_fr": "Lohsa"
            },
            {
                "value": 40272,
                "label": "Tschernitz",
                "label_ar": "Tschernitz",
                "label_fr": "Tschernitz"
            },
            {
                "value": 40273,
                "label": "Kolkwitz",
                "label_ar": "كولكويتز",
                "label_fr": "Kolkwitz"
            },
            {
                "value": 40274,
                "label": "Broeckel",
                "label_ar": "برويكيل",
                "label_fr": "Broeckel"
            },
            {
                "value": 40275,
                "label": "Eldingen",
                "label_ar": "إلدنغن",
                "label_fr": "Eldingen"
            },
            {
                "value": 40276,
                "label": "Steinhorst",
                "label_ar": "شتاينهورست",
                "label_fr": "Steinhorst"
            },
            {
                "value": 40277,
                "label": "Adelheidsdorf",
                "label_ar": "أديلهيددورف",
                "label_fr": "Adelheidsdorf"
            },
            {
                "value": 40278,
                "label": "Bad Fallingbostel",
                "label_ar": "سيئة Fallingbostel",
                "label_fr": "Bad Fallingbostel"
            },
            {
                "value": 40279,
                "label": "Algermissen",
                "label_ar": "الجيرميسن",
                "label_fr": "Algermissen"
            },
            {
                "value": 40280,
                "label": "Harsum",
                "label_ar": "هارسوم",
                "label_fr": "Harsum"
            },
            {
                "value": 40281,
                "label": "Edemissen",
                "label_ar": "إدميسن",
                "label_fr": "Edemissen"
            },
            {
                "value": 40282,
                "label": "Hambuhren",
                "label_ar": "هامبوهرين",
                "label_fr": "Hambuhren"
            },
            {
                "value": 40283,
                "label": "Unteruhldingen",
                "label_ar": "أونتيروهلدنغن",
                "label_fr": "Unteruhldingen"
            },
            {
                "value": 40284,
                "label": "Villingen",
                "label_ar": "فيلينجين",
                "label_fr": "Villingen"
            },
            {
                "value": 40285,
                "label": "Salem",
                "label_ar": "سالم",
                "label_fr": "Salem"
            },
            {
                "value": 40286,
                "label": "Wiesensteig",
                "label_ar": "Wiesensteig",
                "label_fr": "Wiesensteig"
            },
            {
                "value": 40287,
                "label": "Genkingen",
                "label_ar": "جينكينجين",
                "label_fr": "Genkingen"
            },
            {
                "value": 40288,
                "label": "Munderkingen",
                "label_ar": "Munderkingen",
                "label_fr": "Munderkingen"
            },
            {
                "value": 40289,
                "label": "Neukirch",
                "label_ar": "نويكيرش",
                "label_fr": "Neukirch"
            },
            {
                "value": 40290,
                "label": "Mochenwangen",
                "label_ar": "Mochenwangen",
                "label_fr": "Mochenwangen"
            },
            {
                "value": 40291,
                "label": "Schlier",
                "label_ar": "شلير",
                "label_fr": "Schlier"
            },
            {
                "value": 40292,
                "label": "Eberhardzell",
                "label_ar": "إبرهاردزيل",
                "label_fr": "Eberhardzell"
            },
            {
                "value": 40293,
                "label": "Aitrach",
                "label_ar": "ايتراك",
                "label_fr": "Aitrach"
            },
            {
                "value": 40294,
                "label": "Amtzell",
                "label_ar": "أمتزل",
                "label_fr": "Amtzell"
            },
            {
                "value": 40295,
                "label": "Waldburg",
                "label_ar": "والدبرج",
                "label_fr": "Waldburg"
            },
            {
                "value": 40296,
                "label": "Hettstadt",
                "label_ar": "هيتشتات",
                "label_fr": "Hettstadt"
            },
            {
                "value": 40297,
                "label": "Eltmann",
                "label_ar": "التمان",
                "label_fr": "Eltmann"
            },
            {
                "value": 40298,
                "label": "Donnersdorf",
                "label_ar": "دونرسدورف",
                "label_fr": "Donnersdorf"
            },
            {
                "value": 40299,
                "label": "Bad Konigshofen im Grabfeld",
                "label_ar": "Bad Konigshofen im Grabfeld",
                "label_fr": "Bad Konigshofen im Grabfeld"
            },
            {
                "value": 40300,
                "label": "Gochsheim",
                "label_ar": "Gochsheim",
                "label_fr": "Gochsheim"
            },
            {
                "value": 40301,
                "label": "Ochsenfurt",
                "label_ar": "Ochsenfurt",
                "label_fr": "Ochsenfurt"
            },
            {
                "value": 40302,
                "label": "Oberickelsheim",
                "label_ar": "Oberickelsheim",
                "label_fr": "Oberickelsheim"
            },
            {
                "value": 40303,
                "label": "Kirschfurt",
                "label_ar": "كيرشفورت",
                "label_fr": "Kirschfurt"
            },
            {
                "value": 40304,
                "label": "Gollhofen",
                "label_ar": "جولهوفن",
                "label_fr": "Gollhofen"
            },
            {
                "value": 40305,
                "label": "Ebelsbach",
                "label_ar": "إيبلسباخ",
                "label_fr": "Ebelsbach"
            },
            {
                "value": 40306,
                "label": "Frammersbach",
                "label_ar": "فراميرسباخ",
                "label_fr": "Frammersbach"
            },
            {
                "value": 40307,
                "label": "Estenfeld",
                "label_ar": "إستنفيلد",
                "label_fr": "Estenfeld"
            },
            {
                "value": 40308,
                "label": "Munster",
                "label_ar": "مونستر",
                "label_fr": "Munster"
            },
            {
                "value": 40309,
                "label": "Muhltal",
                "label_ar": "مهلتال",
                "label_fr": "Muhltal"
            },
            {
                "value": 40310,
                "label": "Dransfeld",
                "label_ar": "دانسفيلد",
                "label_fr": "Dransfeld"
            },
            {
                "value": 40311,
                "label": "Golmbach",
                "label_ar": "جولمباخ",
                "label_fr": "Golmbach"
            },
            {
                "value": 40312,
                "label": "Hardenberg",
                "label_ar": "هاردنبيرج",
                "label_fr": "Hardenberg"
            },
            {
                "value": 40313,
                "label": "Duderode",
                "label_ar": "دودرود",
                "label_fr": "Duderode"
            },
            {
                "value": 40314,
                "label": "Neuendorf",
                "label_ar": "نويندورف",
                "label_fr": "Neuendorf"
            },
            {
                "value": 40315,
                "label": "Brieskow-Finkenheerd",
                "label_ar": "Brieskow-Finkenheerd",
                "label_fr": "Brieskow-Finkenheerd"
            },
            {
                "value": 40316,
                "label": "Mixdorf",
                "label_ar": "ميكسدورف",
                "label_fr": "Mixdorf"
            },
            {
                "value": 40317,
                "label": "Steintoch",
                "label_ar": "Steintoch",
                "label_fr": "Steintoch"
            },
            {
                "value": 40318,
                "label": "Bad Freienwalde",
                "label_ar": "باد فراينوالد",
                "label_fr": "Bad Freienwalde"
            },
            {
                "value": 40319,
                "label": "Spree",
                "label_ar": "مرح",
                "label_fr": "Fête"
            },
            {
                "value": 40320,
                "label": "Beeskow",
                "label_ar": "بيسكو",
                "label_fr": "Beeskow"
            },
            {
                "value": 40321,
                "label": "Wendisch Rietz",
                "label_ar": "Wendisch Rietz",
                "label_fr": "Wendisch Rietz"
            },
            {
                "value": 40322,
                "label": "Gross Lindow",
                "label_ar": "إجمالي ليندو",
                "label_fr": "Gross Lindow"
            },
            {
                "value": 40323,
                "label": "Mark",
                "label_ar": "علامة",
                "label_fr": "marque"
            },
            {
                "value": 40324,
                "label": "Bad Saarow",
                "label_ar": "باد سارو",
                "label_fr": "Bad Saarow"
            },
            {
                "value": 40325,
                "label": "Waldstetten",
                "label_ar": "والدستيتن",
                "label_fr": "Waldstetten"
            },
            {
                "value": 40326,
                "label": "Westerheim",
                "label_ar": "ويسترهايم",
                "label_fr": "Westerheim"
            },
            {
                "value": 40327,
                "label": "Geislingen an der Steige",
                "label_ar": "Geislingen an der Steige",
                "label_fr": "Geislingen an der Steige"
            },
            {
                "value": 40328,
                "label": "Iggingen",
                "label_ar": "إيجينجين",
                "label_fr": "Iggingen"
            },
            {
                "value": 40329,
                "label": "Merklingen",
                "label_ar": "ميركلنجن",
                "label_fr": "Merklingen"
            },
            {
                "value": 40330,
                "label": "Grabenstetten",
                "label_ar": "Grabenstetten",
                "label_fr": "Grabenstetten"
            },
            {
                "value": 40331,
                "label": "Lonsee",
                "label_ar": "لونسي",
                "label_fr": "Lonsee"
            },
            {
                "value": 40332,
                "label": "Alpen",
                "label_ar": "ألبن",
                "label_fr": "Alpen"
            },
            {
                "value": 40333,
                "label": "Lauenau",
                "label_ar": "لاوينو",
                "label_fr": "Lauenau"
            },
            {
                "value": 40334,
                "label": "Muden",
                "label_ar": "مودن",
                "label_fr": "Muden"
            },
            {
                "value": 40335,
                "label": "Schoneworde",
                "label_ar": "شونيورد",
                "label_fr": "Schoneworde"
            },
            {
                "value": 40336,
                "label": "Luechow",
                "label_ar": "Luechow",
                "label_fr": "Luechow"
            },
            {
                "value": 40337,
                "label": "Hitzacker",
                "label_ar": "هيتساكر",
                "label_fr": "Hitzacker"
            },
            {
                "value": 40338,
                "label": "Elbe",
                "label_ar": "إلبه",
                "label_fr": "Elbe"
            },
            {
                "value": 40339,
                "label": "Gielde",
                "label_ar": "جيلد",
                "label_fr": "Gielde"
            },
            {
                "value": 40340,
                "label": "Baddeckenstedt",
                "label_ar": "Baddeckenstedt",
                "label_fr": "Baddeckenstedt"
            },
            {
                "value": 40341,
                "label": "Everswinkel",
                "label_ar": "إيفرسوينكل",
                "label_fr": "Everswinkel"
            },
            {
                "value": 40342,
                "label": "Olfen",
                "label_ar": "أولفين",
                "label_fr": "Olfen"
            },
            {
                "value": 40343,
                "label": "Wadersloh",
                "label_ar": "وادرسوه",
                "label_fr": "Wadersloh"
            },
            {
                "value": 40344,
                "label": "Schoppingen",
                "label_ar": "شوبنغن",
                "label_fr": "Schoppingen"
            },
            {
                "value": 40345,
                "label": "Kiedrich",
                "label_ar": "كيدريش",
                "label_fr": "Kiedrich"
            },
            {
                "value": 40346,
                "label": "Geisenheim",
                "label_ar": "جايزنهايم",
                "label_fr": "Geisenheim"
            },
            {
                "value": 40347,
                "label": "Uhler",
                "label_ar": "أولر",
                "label_fr": "Uhler"
            },
            {
                "value": 40348,
                "label": "Kirchberg",
                "label_ar": "كيرشبرج",
                "label_fr": "Kirchberg"
            },
            {
                "value": 40349,
                "label": "Reichelsheim",
                "label_ar": "Reichelsheim",
                "label_fr": "Reichelsheim"
            },
            {
                "value": 40350,
                "label": "Westkirchen",
                "label_ar": "ويستكيرشن",
                "label_fr": "Westkirchen"
            },
            {
                "value": 40351,
                "label": "Pflummern",
                "label_ar": "بفلومرن",
                "label_fr": "Pflummern"
            },
            {
                "value": 40352,
                "label": "Wain",
                "label_ar": "فوز",
                "label_fr": "Wain"
            },
            {
                "value": 40353,
                "label": "Lauterach",
                "label_ar": "لوتراش",
                "label_fr": "Lauterach"
            },
            {
                "value": 40354,
                "label": "Jungingen",
                "label_ar": "يونغينغين",
                "label_fr": "Jungingen"
            },
            {
                "value": 40355,
                "label": "Kisslegg",
                "label_ar": "كيسليج",
                "label_fr": "Kisslegg"
            },
            {
                "value": 40356,
                "label": "Schwarzenfeld",
                "label_ar": "شوارزينفيلد",
                "label_fr": "Schwarzenfeld"
            },
            {
                "value": 40357,
                "label": "Rosendahl",
                "label_ar": "روزندال",
                "label_fr": "Rosendahl"
            },
            {
                "value": 40358,
                "label": "Arnschwang",
                "label_ar": "Arnschwang",
                "label_fr": "Arnschwang"
            },
            {
                "value": 40359,
                "label": "Kakenstorf",
                "label_ar": "Kakenstorf",
                "label_fr": "Kakenstorf"
            },
            {
                "value": 40360,
                "label": "Wittorf",
                "label_ar": "ويتورف",
                "label_fr": "Wittorf"
            },
            {
                "value": 40361,
                "label": "Gardelegen",
                "label_ar": "Gardelegen",
                "label_fr": "Gardelegen"
            },
            {
                "value": 40362,
                "label": "Garbek",
                "label_ar": "غاربيك",
                "label_fr": "Garbek"
            },
            {
                "value": 40363,
                "label": "Siebenbaumen",
                "label_ar": "سيبينباومين",
                "label_fr": "Siebenbaumen"
            },
            {
                "value": 40364,
                "label": "Berkenthin",
                "label_ar": "بيركنثين",
                "label_fr": "Berkenthin"
            },
            {
                "value": 40365,
                "label": "Gronau",
                "label_ar": "جروناو",
                "label_fr": "Gronau"
            },
            {
                "value": 40366,
                "label": "Schmilau",
                "label_ar": "شميلاو",
                "label_fr": "Schmilau"
            },
            {
                "value": 40367,
                "label": "Rondeshagen",
                "label_ar": "روندشاجين",
                "label_fr": "Rondeshagen"
            },
            {
                "value": 40368,
                "label": "Lucka",
                "label_ar": "لوكا",
                "label_fr": "Lucka"
            },
            {
                "value": 40369,
                "label": "Langenbernsdorf",
                "label_ar": "لانجينبيرنسدورف",
                "label_fr": "Langenbernsdorf"
            },
            {
                "value": 40370,
                "label": "Wurzbach",
                "label_ar": "فورزباخ",
                "label_fr": "Wurzbach"
            },
            {
                "value": 40371,
                "label": "Leutenberg",
                "label_ar": "ليوتنبرج",
                "label_fr": "Leutenberg"
            },
            {
                "value": 40372,
                "label": "Hartmannsdorf",
                "label_ar": "هارتمانسدورف",
                "label_fr": "Hartmannsdorf"
            },
            {
                "value": 40373,
                "label": "Dobitschen",
                "label_ar": "دوبيتشين",
                "label_fr": "Dobitschen"
            },
            {
                "value": 40374,
                "label": "Kaulsdorf",
                "label_ar": "كاولسدورف",
                "label_fr": "Kaulsdorf"
            },
            {
                "value": 40375,
                "label": "Pausa",
                "label_ar": "بوسا",
                "label_fr": "Pausa"
            },
            {
                "value": 40376,
                "label": "Mohlsdorf",
                "label_ar": "موهلسدورف",
                "label_fr": "Mohlsdorf"
            },
            {
                "value": 40377,
                "label": "Dahn",
                "label_ar": "دان",
                "label_fr": "Dahn"
            },
            {
                "value": 40378,
                "label": "Hutschenhausen",
                "label_ar": "هوتشنهاوزن",
                "label_fr": "Hutschenhausen"
            },
            {
                "value": 40379,
                "label": "Thaleischweiler-Froschen",
                "label_ar": "Thaleischweiler-Froschen",
                "label_fr": "Thaleischweiler-Froschen"
            },
            {
                "value": 40380,
                "label": "Weilerbach",
                "label_ar": "فايلرباخ",
                "label_fr": "Weilerbach"
            },
            {
                "value": 40381,
                "label": "Bruchmuhlbach-Miesau",
                "label_ar": "Bruchmuhlbach-Miesau",
                "label_fr": "Bruchmuhlbach-Miesau"
            },
            {
                "value": 40382,
                "label": "Rammelsbach",
                "label_ar": "راملسباخ",
                "label_fr": "Rammelsbach"
            },
            {
                "value": 40383,
                "label": "Imsbach",
                "label_ar": "إمسباخ",
                "label_fr": "Imsbach"
            },
            {
                "value": 40384,
                "label": "Winnweiler",
                "label_ar": "وينويللر",
                "label_fr": "Winnweiler"
            },
            {
                "value": 40385,
                "label": "Glan-Munchweiler",
                "label_ar": "جلان مونشويلر",
                "label_fr": "Glan-Munchweiler"
            },
            {
                "value": 40386,
                "label": "Waldfischbach-Burgalben",
                "label_ar": "فالدفيشباخ بورغالبين",
                "label_fr": "Waldfischbach-Burgalben"
            },
            {
                "value": 40387,
                "label": "Rudelzhausen",
                "label_ar": "روديلزهاوزن",
                "label_fr": "Rudelzhausen"
            },
            {
                "value": 40388,
                "label": "Tauberfeld",
                "label_ar": "تاوبرفيلد",
                "label_fr": "Tauberfeld"
            },
            {
                "value": 40389,
                "label": "Au in der Hallertau",
                "label_ar": "Au in der Hallertau",
                "label_fr": "Au in der Hallertau"
            },
            {
                "value": 40390,
                "label": "Wolfersdorf",
                "label_ar": "ولفرسدورف",
                "label_fr": "Wolfersdorf"
            },
            {
                "value": 40391,
                "label": "Fraunberg",
                "label_ar": "فراونبيرج",
                "label_fr": "Fraunberg"
            },
            {
                "value": 40392,
                "label": "Schweitenkirchen",
                "label_ar": "شفايتنكيرشن",
                "label_fr": "Schweitenkirchen"
            },
            {
                "value": 40393,
                "label": "Reitmehring",
                "label_ar": "Reitmehring",
                "label_fr": "Reitmehring"
            },
            {
                "value": 40394,
                "label": "Allershausen",
                "label_ar": "أليرسهاوزن",
                "label_fr": "Allershausen"
            },
            {
                "value": 40395,
                "label": "Ohren",
                "label_ar": "أورين",
                "label_fr": "Ohren"
            },
            {
                "value": 40396,
                "label": "Weilburg",
                "label_ar": "ويلبورغ",
                "label_fr": "Weilburg"
            },
            {
                "value": 40397,
                "label": "Mengerskirchen",
                "label_ar": "مينجرزكيرشن",
                "label_fr": "Mengerskirchen"
            },
            {
                "value": 40398,
                "label": "Fleisbach",
                "label_ar": "فلييسباخ",
                "label_fr": "Fleisbach"
            },
            {
                "value": 40399,
                "label": "Weimar",
                "label_ar": "فايمار",
                "label_fr": "Weimar"
            },
            {
                "value": 40400,
                "label": "Leun",
                "label_ar": "ليون",
                "label_fr": "Leun"
            },
            {
                "value": 40401,
                "label": "Seeburg",
                "label_ar": "سيبورغ",
                "label_fr": "Seeburg"
            },
            {
                "value": 40402,
                "label": "Adelebsen",
                "label_ar": "اديلبسن",
                "label_fr": "Adelebsen"
            },
            {
                "value": 40403,
                "label": "Wendeburg",
                "label_ar": "وينديبورغ",
                "label_fr": "Wendeburg"
            },
            {
                "value": 40404,
                "label": "Barwedel",
                "label_ar": "بارويدل",
                "label_fr": "Barwedel"
            },
            {
                "value": 40405,
                "label": "Ruppichteroth",
                "label_ar": "Ruppichteroth",
                "label_fr": "Ruppichteroth"
            },
            {
                "value": 40406,
                "label": "Hausern",
                "label_ar": "هوسرن",
                "label_fr": "Hausern"
            },
            {
                "value": 40407,
                "label": "Weisweil",
                "label_ar": "ويسويل",
                "label_fr": "Weisweil"
            },
            {
                "value": 40408,
                "label": "Heitersheim",
                "label_ar": "هيترشيم",
                "label_fr": "Heitersheim"
            },
            {
                "value": 40409,
                "label": "Zell",
                "label_ar": "زيل",
                "label_fr": "Zell"
            },
            {
                "value": 40410,
                "label": "Gorwihl",
                "label_ar": "جورويل",
                "label_fr": "Gorwihl"
            },
            {
                "value": 40411,
                "label": "Endingen",
                "label_ar": "إندينجن",
                "label_fr": "Fin"
            },
            {
                "value": 40412,
                "label": "Eberdingen",
                "label_ar": "إبردينجن",
                "label_fr": "Eberdingen"
            },
            {
                "value": 40413,
                "label": "Fintel",
                "label_ar": "فينتل",
                "label_fr": "Fintel"
            },
            {
                "value": 40414,
                "label": "Neuenkirchen",
                "label_ar": "نوينكيرشن",
                "label_fr": "Neuenkirchen"
            },
            {
                "value": 40415,
                "label": "Affinghausen",
                "label_ar": "أفينغهاوزن",
                "label_fr": "Affinghausen"
            },
            {
                "value": 40416,
                "label": "Alpirsbach",
                "label_ar": "Alpirsbach",
                "label_fr": "Alpirsbach"
            },
            {
                "value": 40417,
                "label": "Winterlingen",
                "label_ar": "وينترلينجين",
                "label_fr": "Winterlingen"
            },
            {
                "value": 40418,
                "label": "Zimmern ob Rottweil",
                "label_ar": "زيمرن أوب روتويل",
                "label_fr": "Zimmern ob Rottweil"
            },
            {
                "value": 40419,
                "label": "Hohentengen am Hochrhein",
                "label_ar": "Hohentengen am Hochrhein",
                "label_fr": "Hohentengen am Hochrhein"
            },
            {
                "value": 40420,
                "label": "Gammertingen",
                "label_ar": "جامرتينغن",
                "label_fr": "Gammertingen"
            },
            {
                "value": 40421,
                "label": "Bitz",
                "label_ar": "بيتز",
                "label_fr": "Bitz"
            },
            {
                "value": 40422,
                "label": "Schafflund",
                "label_ar": "شافلوند",
                "label_fr": "Schafflund"
            },
            {
                "value": 40423,
                "label": "Satrup",
                "label_ar": "ساتراب",
                "label_fr": "Satrup"
            },
            {
                "value": 40424,
                "label": "Jubek",
                "label_ar": "جوبيك",
                "label_fr": "Jubek"
            },
            {
                "value": 40425,
                "label": "Struxdorf",
                "label_ar": "ستروسدورف",
                "label_fr": "Struxdorf"
            },
            {
                "value": 40426,
                "label": "Bargum",
                "label_ar": "برغم",
                "label_fr": "Bargum"
            },
            {
                "value": 40427,
                "label": "Dorpstedt",
                "label_ar": "دوربستيدت",
                "label_fr": "Dorpstedt"
            },
            {
                "value": 40428,
                "label": "Brodersby",
                "label_ar": "برودرسبي",
                "label_fr": "Brodersby"
            },
            {
                "value": 40429,
                "label": "Bollingstedt",
                "label_ar": "بولينجستيدت",
                "label_fr": "Bollingstedt"
            },
            {
                "value": 40430,
                "label": "Schaalby",
                "label_ar": "شالبي",
                "label_fr": "Schaalby"
            },
            {
                "value": 40431,
                "label": "Kampen",
                "label_ar": "كامبن",
                "label_fr": "Kampen"
            },
            {
                "value": 40432,
                "label": "Leutershausen",
                "label_ar": "ليوترهاوسن",
                "label_fr": "Leutershausen"
            },
            {
                "value": 40433,
                "label": "Burk",
                "label_ar": "بيرك",
                "label_fr": "Burk"
            },
            {
                "value": 40434,
                "label": "Petersaurach",
                "label_ar": "بيترساوراخ",
                "label_fr": "Petersaurach"
            },
            {
                "value": 40435,
                "label": "Ornbau",
                "label_ar": "Ornbau",
                "label_fr": "Ornbau"
            },
            {
                "value": 40436,
                "label": "Diebach",
                "label_ar": "ديباتش",
                "label_fr": "Diebach"
            },
            {
                "value": 40437,
                "label": "Megesheim",
                "label_ar": "ميجيشيم",
                "label_fr": "Megesheim"
            },
            {
                "value": 40438,
                "label": "Ederheim",
                "label_ar": "إديرهايم",
                "label_fr": "Ederheim"
            },
            {
                "value": 40439,
                "label": "Sielenbach",
                "label_ar": "سيلينباخ",
                "label_fr": "Sielenbach"
            },
            {
                "value": 40440,
                "label": "Kaisheim",
                "label_ar": "كايشيم",
                "label_fr": "Kaisheim"
            },
            {
                "value": 40441,
                "label": "Ernstthal",
                "label_ar": "إرنشتال",
                "label_fr": "Ernstthal"
            },
            {
                "value": 40442,
                "label": "Ronneburg",
                "label_ar": "رونيبورغ",
                "label_fr": "Ronneburg"
            },
            {
                "value": 40443,
                "label": "Buttenheim",
                "label_ar": "بوتنهايم",
                "label_fr": "Buttenheim"
            },
            {
                "value": 40444,
                "label": "Neundorf",
                "label_ar": "نوندورف",
                "label_fr": "Neundorf"
            },
            {
                "value": 40445,
                "label": "Haiterbach",
                "label_ar": "هايترباخ",
                "label_fr": "Haiterbach"
            },
            {
                "value": 40446,
                "label": "Sigmaringendorf",
                "label_ar": "سيجمارينندورف",
                "label_fr": "Sigmaringendorf"
            },
            {
                "value": 40447,
                "label": "Oschingen",
                "label_ar": "أوشينجن",
                "label_fr": "Oschingen"
            },
            {
                "value": 40448,
                "label": "Arnstein",
                "label_ar": "أرنستين",
                "label_fr": "Arnstein"
            },
            {
                "value": 40449,
                "label": "Uettingen",
                "label_ar": "يوتينجين",
                "label_fr": "Uettingen"
            },
            {
                "value": 40450,
                "label": "Alzenau in Unterfranken",
                "label_ar": "Alzenau في Unterfranken",
                "label_fr": "Alzenau à Unterfranken"
            },
            {
                "value": 40451,
                "label": "Kurnach",
                "label_ar": "كورناش",
                "label_fr": "Kurnach"
            },
            {
                "value": 40452,
                "label": "Giebelstadt",
                "label_ar": "جيبلشتات",
                "label_fr": "Giebelstadt"
            },
            {
                "value": 40453,
                "label": "Weisen",
                "label_ar": "وايزن",
                "label_fr": "Weisen"
            },
            {
                "value": 40454,
                "label": "Galenbeck",
                "label_ar": "جالينبيك",
                "label_fr": "Galenbeck"
            },
            {
                "value": 40455,
                "label": "Perleberg",
                "label_ar": "بيرلبرغ",
                "label_fr": "Perleberg"
            },
            {
                "value": 40456,
                "label": "Zerrenthin",
                "label_ar": "زرينثين",
                "label_fr": "Zerrenthin"
            },
            {
                "value": 40457,
                "label": "Reisbach",
                "label_ar": "ريسباخ",
                "label_fr": "Reisbach"
            },
            {
                "value": 40458,
                "label": "Neureichenau",
                "label_ar": "نويريتشيناو",
                "label_fr": "Neureichenau"
            },
            {
                "value": 40459,
                "label": "Hinterschmiding",
                "label_ar": "Hinterschmiding",
                "label_fr": "Hinterschmiding"
            },
            {
                "value": 40460,
                "label": "Am See",
                "label_ar": "أنا أرى",
                "label_fr": "Je vois"
            },
            {
                "value": 40461,
                "label": "Waldkirchen",
                "label_ar": "والدكيرشن",
                "label_fr": "Waldkirchen"
            },
            {
                "value": 40462,
                "label": "Querfurt",
                "label_ar": "كويرفورت",
                "label_fr": "Querfurt"
            },
            {
                "value": 40463,
                "label": "Karsdorf",
                "label_ar": "كارسدورف",
                "label_fr": "Karsdorf"
            },
            {
                "value": 40464,
                "label": "Mucheln",
                "label_ar": "موشلن",
                "label_fr": "Mucheln"
            },
            {
                "value": 40465,
                "label": "Nienburg/Saale",
                "label_ar": "نينبورغ / سال",
                "label_fr": "Nienburg / Saale"
            },
            {
                "value": 40466,
                "label": "Steigra",
                "label_ar": "ستيغرا",
                "label_fr": "Steigra"
            },
            {
                "value": 40467,
                "label": "Elster",
                "label_ar": "إلستر",
                "label_fr": "Elster"
            },
            {
                "value": 40468,
                "label": "Markt Einersheim",
                "label_ar": "Markt Einersheim",
                "label_fr": "Markt Einersheim"
            },
            {
                "value": 40469,
                "label": "Stettfeld",
                "label_ar": "ستيتفيلد",
                "label_fr": "Stettfeld"
            },
            {
                "value": 40470,
                "label": "Karbach",
                "label_ar": "كارباخ",
                "label_fr": "Karbach"
            },
            {
                "value": 40471,
                "label": "Zeil",
                "label_ar": "زيل",
                "label_fr": "Zeil"
            },
            {
                "value": 40472,
                "label": "Iphofen",
                "label_ar": "ايفوفين",
                "label_fr": "Iphofène"
            },
            {
                "value": 40473,
                "label": "Roetgen",
                "label_ar": "رويتجن",
                "label_fr": "Roetgen"
            },
            {
                "value": 40474,
                "label": "Lahden",
                "label_ar": "لادن",
                "label_fr": "Lahden"
            },
            {
                "value": 40475,
                "label": "Grossruckerswalde",
                "label_ar": "جروسروكرسفالد",
                "label_fr": "Grossruckerswalde"
            },
            {
                "value": 40476,
                "label": "Schwarzheide",
                "label_ar": "شوارزيدي",
                "label_fr": "Schwarzheide"
            },
            {
                "value": 40477,
                "label": "Hainichen",
                "label_ar": "هينيشن",
                "label_fr": "Hainichen"
            },
            {
                "value": 40478,
                "label": "Elterlein",
                "label_ar": "التيرلين",
                "label_fr": "Elterlein"
            },
            {
                "value": 40479,
                "label": "Plettenberg",
                "label_ar": "بليتنبرغ",
                "label_fr": "Plettenberg"
            },
            {
                "value": 40480,
                "label": "Osthofen",
                "label_ar": "أوستهوفن",
                "label_fr": "Osthofen"
            },
            {
                "value": 40481,
                "label": "Geinsheim",
                "label_ar": "جينشيم",
                "label_fr": "Geinsheim"
            },
            {
                "value": 40482,
                "label": "Biblis",
                "label_ar": "ببليس",
                "label_fr": "Biblis"
            },
            {
                "value": 40483,
                "label": "Dienheim",
                "label_ar": "دينهايم",
                "label_fr": "Dienheim"
            },
            {
                "value": 40484,
                "label": "Bermersheim",
                "label_ar": "بيرميرشيم",
                "label_fr": "Bermersheim"
            },
            {
                "value": 40485,
                "label": "Westhofen",
                "label_ar": "ويستهوفن",
                "label_fr": "Westhofen"
            },
            {
                "value": 40486,
                "label": "Bad Elster",
                "label_ar": "باد إلستر",
                "label_fr": "Bad Elster"
            },
            {
                "value": 40487,
                "label": "Bernsbach",
                "label_ar": "بيرنسباخ",
                "label_fr": "Bernsbach"
            },
            {
                "value": 40488,
                "label": "Kirchberg",
                "label_ar": "كيرشبرج",
                "label_fr": "Kirchberg"
            },
            {
                "value": 40489,
                "label": "Zaulsdorf",
                "label_ar": "زاولسدورف",
                "label_fr": "Zaulsdorf"
            },
            {
                "value": 40490,
                "label": "Zschorlau",
                "label_ar": "زكورلاو",
                "label_fr": "Zschorlau"
            },
            {
                "value": 40491,
                "label": "Windsbach",
                "label_ar": "ويندزباخ",
                "label_fr": "Windsbach"
            },
            {
                "value": 40492,
                "label": "Muhlhausen",
                "label_ar": "مولهاوزن",
                "label_fr": "Muhlhausen"
            },
            {
                "value": 40493,
                "label": "Dietersheim",
                "label_ar": "ديترشيم",
                "label_fr": "Dietersheim"
            },
            {
                "value": 40494,
                "label": "Puschendorf",
                "label_ar": "بوشندورف",
                "label_fr": "Puschendorf"
            },
            {
                "value": 40495,
                "label": "Litzendorf",
                "label_ar": "ليتزندورف",
                "label_fr": "Litzendorf"
            },
            {
                "value": 40496,
                "label": "Stegaurach",
                "label_ar": "ستيغوراش",
                "label_fr": "Stegaurach"
            },
            {
                "value": 40497,
                "label": "Podeldorf",
                "label_ar": "بودلدورف",
                "label_fr": "Podeldorf"
            },
            {
                "value": 40498,
                "label": "Gutenstetten",
                "label_ar": "جوتنستيتن",
                "label_fr": "Gutenstetten"
            },
            {
                "value": 40499,
                "label": "Wiefelstede",
                "label_ar": "Wiefelstede",
                "label_fr": "Wiefelstede"
            },
            {
                "value": 40500,
                "label": "Wagenfeld",
                "label_ar": "واجينفيلد",
                "label_fr": "Wagenfeld"
            },
            {
                "value": 40501,
                "label": "Wetschen",
                "label_ar": "ويتشين",
                "label_fr": "Wetschen"
            },
            {
                "value": 40502,
                "label": "Egelsbach",
                "label_ar": "إيجلسباخ",
                "label_fr": "Egelsbach"
            },
            {
                "value": 40503,
                "label": "Niederdorfelden",
                "label_ar": "نيدردورفلدن",
                "label_fr": "Niederdorfelden"
            },
            {
                "value": 40504,
                "label": "Wehlheiden",
                "label_ar": "وهلهايدن",
                "label_fr": "Wehlheiden"
            },
            {
                "value": 40505,
                "label": "Willingen",
                "label_ar": "ويلينجين",
                "label_fr": "Willingen"
            },
            {
                "value": 40506,
                "label": "Naumburg",
                "label_ar": "نومبورغ",
                "label_fr": "Naumburg"
            },
            {
                "value": 40507,
                "label": "Spangenberg",
                "label_ar": "سبانجينبيرج",
                "label_fr": "Spangenberg"
            },
            {
                "value": 40508,
                "label": "Mehmels",
                "label_ar": "مهميلز",
                "label_fr": "Mehmels"
            },
            {
                "value": 40509,
                "label": "Romhild",
                "label_ar": "رومهيلد",
                "label_fr": "Romhild"
            },
            {
                "value": 40510,
                "label": "Catterfeld",
                "label_ar": "كاترفيلد",
                "label_fr": "Catterfeld"
            },
            {
                "value": 40511,
                "label": "Linden",
                "label_ar": "الزيزفون",
                "label_fr": "Tilleul"
            },
            {
                "value": 40512,
                "label": "Bad Breisig",
                "label_ar": "باد بريسيج",
                "label_fr": "Bad Breisig"
            },
            {
                "value": 40513,
                "label": "Ruppach-Goldhausen",
                "label_ar": "Ruppach-Goldhausen",
                "label_fr": "Ruppach-Goldhausen"
            },
            {
                "value": 40514,
                "label": "Nauort",
                "label_ar": "نورت",
                "label_fr": "Nauort"
            },
            {
                "value": 40515,
                "label": "Mendig",
                "label_ar": "منديج",
                "label_fr": "Mendig"
            },
            {
                "value": 40516,
                "label": "Plaidt",
                "label_ar": "بلايدت",
                "label_fr": "Plaidt"
            },
            {
                "value": 40517,
                "label": "Wassenach",
                "label_ar": "Wassenach",
                "label_fr": "Wassenach"
            },
            {
                "value": 40518,
                "label": "Heinz",
                "label_ar": "هاينز",
                "label_fr": "Heinz"
            },
            {
                "value": 40519,
                "label": "Heinzenberg",
                "label_ar": "هاينزنبرج",
                "label_fr": "Heinzenberg"
            },
            {
                "value": 40520,
                "label": "Langenbach",
                "label_ar": "لانجينباخ",
                "label_fr": "Langenbach"
            },
            {
                "value": 40521,
                "label": "Neustadt bei Coburg",
                "label_ar": "نيوستادت باي كوبورغ",
                "label_fr": "Neustadt bei Coburg"
            },
            {
                "value": 40522,
                "label": "Pessenburgheim",
                "label_ar": "بيسينبورغهايم",
                "label_fr": "Pessenburgheim"
            },
            {
                "value": 40523,
                "label": "Ruthnick",
                "label_ar": "روثنيك",
                "label_fr": "Ruthnick"
            },
            {
                "value": 40524,
                "label": "Bismark",
                "label_ar": "بسمارك",
                "label_fr": "Bismark"
            },
            {
                "value": 40525,
                "label": "Kyritz",
                "label_ar": "كيريتز",
                "label_fr": "Kyritz"
            },
            {
                "value": 40526,
                "label": "Langewiesen",
                "label_ar": "لانجويزين",
                "label_fr": "Langewiesen"
            },
            {
                "value": 40527,
                "label": "Grossbreitenbach",
                "label_ar": "جروسبرايتنباخ",
                "label_fr": "Grossbreitenbach"
            },
            {
                "value": 40528,
                "label": "Wallersdorf",
                "label_ar": "والرسدورف",
                "label_fr": "Wallersdorf"
            },
            {
                "value": 40529,
                "label": "Irlbach",
                "label_ar": "إيرلباخ",
                "label_fr": "Irlbach"
            },
            {
                "value": 40530,
                "label": "Taufkirchen",
                "label_ar": "Taufkirchen",
                "label_fr": "Taufkirchen"
            },
            {
                "value": 40531,
                "label": "Grafschaft",
                "label_ar": "جرافشافت",
                "label_fr": "Grafschaft"
            },
            {
                "value": 40532,
                "label": "Wohnbach",
                "label_ar": "ونباخ",
                "label_fr": "Wohnbach"
            },
            {
                "value": 40533,
                "label": "Waldems",
                "label_ar": "فالدمز",
                "label_fr": "Waldems"
            },
            {
                "value": 40534,
                "label": "Hammersbach",
                "label_ar": "هامرسباخ",
                "label_fr": "Hammersbach"
            },
            {
                "value": 40535,
                "label": "Mengkofen",
                "label_ar": "مينجكوفين",
                "label_fr": "Mengkofen"
            },
            {
                "value": 40536,
                "label": "Rohr",
                "label_ar": "روهر",
                "label_fr": "Rohr"
            },
            {
                "value": 40537,
                "label": "Mauth",
                "label_ar": "ماوث",
                "label_fr": "Mauth"
            },
            {
                "value": 40538,
                "label": "Marklkofen",
                "label_ar": "ماركلكوفين",
                "label_fr": "Marklkofen"
            },
            {
                "value": 40539,
                "label": "Hohenau",
                "label_ar": "هوهيناو",
                "label_fr": "Hohenau"
            },
            {
                "value": 40540,
                "label": "Bad Fussing",
                "label_ar": "ضجة سيئة",
                "label_fr": "Bad Fussing"
            },
            {
                "value": 40541,
                "label": "Hofkirchen",
                "label_ar": "هوفكيرشن",
                "label_fr": "Hofkirchen"
            },
            {
                "value": 40542,
                "label": "Kirchham",
                "label_ar": "كيرشام",
                "label_fr": "Kirchham"
            },
            {
                "value": 40543,
                "label": "Rotthalmunster",
                "label_ar": "روثالمونستر",
                "label_fr": "Rotthalmunster"
            },
            {
                "value": 40544,
                "label": "Deggendorf",
                "label_ar": "ديجيندورف",
                "label_fr": "Deggendorf"
            },
            {
                "value": 40545,
                "label": "Aidenbach",
                "label_ar": "ايدنباخ",
                "label_fr": "Aidenbach"
            },
            {
                "value": 40546,
                "label": "Haidmuhle",
                "label_ar": "هايدموهل",
                "label_fr": "Haidmuhle"
            },
            {
                "value": 40547,
                "label": "Massing",
                "label_ar": "حشد",
                "label_fr": "Masse"
            },
            {
                "value": 40548,
                "label": "Egglham",
                "label_ar": "إيجلهام",
                "label_fr": "Egglham"
            },
            {
                "value": 40549,
                "label": "Ruhstorf",
                "label_ar": "روهستورف",
                "label_fr": "Ruhstorf"
            },
            {
                "value": 40550,
                "label": "Neuhaus am Inn",
                "label_ar": "نيوهاوس أم إن",
                "label_fr": "Neuhaus am Inn"
            },
            {
                "value": 40551,
                "label": "Legden",
                "label_ar": "ليجدن",
                "label_fr": "Legden"
            },
            {
                "value": 40552,
                "label": "Burgbernheim",
                "label_ar": "بورغبيرنهايم",
                "label_fr": "Burgbernheim"
            },
            {
                "value": 40553,
                "label": "Neuzelle",
                "label_ar": "نيوزيل",
                "label_fr": "Neuzelle"
            },
            {
                "value": 40554,
                "label": "Fischbach",
                "label_ar": "فيشباخ",
                "label_fr": "Fischbach"
            },
            {
                "value": 40555,
                "label": "Mittelneufnach",
                "label_ar": "ميتيلنوفناخ",
                "label_fr": "Mittelneufnach"
            },
            {
                "value": 40556,
                "label": "Kirkel",
                "label_ar": "كيركيل",
                "label_fr": "Kirkel"
            },
            {
                "value": 40557,
                "label": "Horst",
                "label_ar": "هورست",
                "label_fr": "Horst"
            },
            {
                "value": 40558,
                "label": "Burbach",
                "label_ar": "بورباخ",
                "label_fr": "Burbach"
            },
            {
                "value": 40559,
                "label": "Bullay",
                "label_ar": "بول",
                "label_fr": "Bullay"
            },
            {
                "value": 40560,
                "label": "Traben-Trarbach",
                "label_ar": "ترابين ترارباخ",
                "label_fr": "Traben-Trarbach"
            },
            {
                "value": 40561,
                "label": "Klausen",
                "label_ar": "كلاوسن",
                "label_fr": "Klausen"
            },
            {
                "value": 40562,
                "label": "Gondenbrett",
                "label_ar": "جوندينبريت",
                "label_fr": "Gondenbrett"
            },
            {
                "value": 40563,
                "label": "Zell",
                "label_ar": "زيل",
                "label_fr": "Zell"
            },
            {
                "value": 40564,
                "label": "Hallschlag",
                "label_ar": "هالشلاغ",
                "label_fr": "Hallschlag"
            },
            {
                "value": 40565,
                "label": "Prum",
                "label_ar": "بروم",
                "label_fr": "Prum"
            },
            {
                "value": 40566,
                "label": "Schweich",
                "label_ar": "شويش",
                "label_fr": "Schweich"
            },
            {
                "value": 40567,
                "label": "Schwirzheim",
                "label_ar": "شويرزهايم",
                "label_fr": "Schwirzheim"
            },
            {
                "value": 40568,
                "label": "Bernkastel-Kues",
                "label_ar": "بيرنكاستيل كويس",
                "label_fr": "Bernkastel-Kues"
            },
            {
                "value": 40569,
                "label": "Badem",
                "label_ar": "بادم",
                "label_fr": "Badem"
            },
            {
                "value": 40570,
                "label": "Irmenach",
                "label_ar": "إيرميناخ",
                "label_fr": "Irmenach"
            },
            {
                "value": 40571,
                "label": "Lieser",
                "label_ar": "لايزر",
                "label_fr": "Lieser"
            },
            {
                "value": 40572,
                "label": "Dreis",
                "label_ar": "دريس",
                "label_fr": "Dreis"
            },
            {
                "value": 40573,
                "label": "Rhaunen",
                "label_ar": "رونين",
                "label_fr": "Rhaunen"
            },
            {
                "value": 40574,
                "label": "Losheim",
                "label_ar": "لوسهايم",
                "label_fr": "Losheim"
            },
            {
                "value": 40575,
                "label": "Seffern",
                "label_ar": "سيفرن",
                "label_fr": "Seffern"
            },
            {
                "value": 40576,
                "label": "Morbach",
                "label_ar": "مورباخ",
                "label_fr": "Morbach"
            },
            {
                "value": 40577,
                "label": "Leiwen",
                "label_ar": "ليوين",
                "label_fr": "Leiwen"
            },
            {
                "value": 40578,
                "label": "Malberg",
                "label_ar": "مالبرج",
                "label_fr": "Malberg"
            },
            {
                "value": 40579,
                "label": "Daun",
                "label_ar": "داون",
                "label_fr": "Daun"
            },
            {
                "value": 40580,
                "label": "Hochscheid",
                "label_ar": "هوششيد",
                "label_fr": "Hochscheid"
            },
            {
                "value": 40581,
                "label": "Kordel",
                "label_ar": "كورديل",
                "label_fr": "Kordel"
            },
            {
                "value": 40582,
                "label": "Hillesheim",
                "label_ar": "هيلشيم",
                "label_fr": "Hillesheim"
            },
            {
                "value": 40583,
                "label": "Sehlem",
                "label_ar": "Sehlem",
                "label_fr": "Sehlem"
            },
            {
                "value": 40584,
                "label": "Eschfeld",
                "label_ar": "ايشفيلد",
                "label_fr": "Eschfeld"
            },
            {
                "value": 40585,
                "label": "Osterburg",
                "label_ar": "اوستربيرغ",
                "label_fr": "Osterburg"
            },
            {
                "value": 40586,
                "label": "Schwanebeck",
                "label_ar": "شوانيبيك",
                "label_fr": "Schwanebeck"
            },
            {
                "value": 40587,
                "label": "Irxleben",
                "label_ar": "إركسليبن",
                "label_fr": "Irxleben"
            },
            {
                "value": 40588,
                "label": "Hohenerxleben",
                "label_ar": "هوهنركسليبن",
                "label_fr": "Hohenerxleben"
            },
            {
                "value": 40589,
                "label": "Kroppenstedt",
                "label_ar": "كروبينشتيدت",
                "label_fr": "Kroppenstedt"
            },
            {
                "value": 40590,
                "label": "Bismark",
                "label_ar": "بسمارك",
                "label_fr": "Bismark"
            },
            {
                "value": 40591,
                "label": "Welsleben",
                "label_ar": "ويلسليبن",
                "label_fr": "Welsleben"
            },
            {
                "value": 40592,
                "label": "Wegeleben",
                "label_ar": "Wegeleben",
                "label_fr": "Wegeleben"
            },
            {
                "value": 40593,
                "label": "Huckeswagen",
                "label_ar": "Huckeswagen",
                "label_fr": "Huckeswagen"
            },
            {
                "value": 40594,
                "label": "Uberfeld",
                "label_ar": "أوبرفيلد",
                "label_fr": "Uberfeld"
            },
            {
                "value": 40595,
                "label": "Zorbig",
                "label_ar": "زوربيج",
                "label_fr": "Zorbig"
            },
            {
                "value": 40596,
                "label": "Braunsbedra",
                "label_ar": "Braunsbedra",
                "label_fr": "Braunsbedra"
            },
            {
                "value": 40597,
                "label": "Wimmelburg",
                "label_ar": "ويملبورغ",
                "label_fr": "Wimmelburg"
            },
            {
                "value": 40598,
                "label": "Pouch",
                "label_ar": "كيس",
                "label_fr": "Poche"
            },
            {
                "value": 40599,
                "label": "Teutschenthal",
                "label_ar": "تويتشينثال",
                "label_fr": "Teutschenthal"
            },
            {
                "value": 40600,
                "label": "Annaburg",
                "label_ar": "أنابورغ",
                "label_fr": "Annaburg"
            },
            {
                "value": 40601,
                "label": "Norvenich",
                "label_ar": "نورفينيتش",
                "label_fr": "Norvenich"
            },
            {
                "value": 40602,
                "label": "Drolshagen",
                "label_ar": "درولشاجين",
                "label_fr": "Drolshagen"
            },
            {
                "value": 40603,
                "label": "Lehrberg",
                "label_ar": "ليربيرج",
                "label_fr": "Lehrberg"
            },
            {
                "value": 40604,
                "label": "Burgoberbach",
                "label_ar": "بورغوبرباخ",
                "label_fr": "Burgoberbach"
            },
            {
                "value": 40605,
                "label": "Kottenheim",
                "label_ar": "كوتينهايم",
                "label_fr": "Kottenheim"
            },
            {
                "value": 40606,
                "label": "Hinterzarten",
                "label_ar": "هينترتزارتن",
                "label_fr": "Hinterzarten"
            },
            {
                "value": 40607,
                "label": "Haimhausen",
                "label_ar": "هايمهاوزن",
                "label_fr": "Haimhausen"
            },
            {
                "value": 40608,
                "label": "Wurmsham",
                "label_ar": "ورمسهام",
                "label_fr": "Wurmsham"
            },
            {
                "value": 40609,
                "label": "Petershausen",
                "label_ar": "بيترسهاوزن",
                "label_fr": "Petershausen"
            },
            {
                "value": 40610,
                "label": "Elstra",
                "label_ar": "إلسترا",
                "label_fr": "Elstra"
            },
            {
                "value": 40611,
                "label": "Radeburg",
                "label_ar": "رادبرج",
                "label_fr": "Radeburg"
            },
            {
                "value": 40612,
                "label": "Konigswartha",
                "label_ar": "كونيجسوارثا",
                "label_fr": "Konigswartha"
            },
            {
                "value": 40613,
                "label": "Prietitz",
                "label_ar": "بريتيتز",
                "label_fr": "Prietitz"
            },
            {
                "value": 40614,
                "label": "Langebruck",
                "label_ar": "لانجبروك",
                "label_fr": "Langebruck"
            },
            {
                "value": 40615,
                "label": "Leutenbach",
                "label_ar": "ليوتنباخ",
                "label_fr": "Leutenbach"
            },
            {
                "value": 40616,
                "label": "Burgstall",
                "label_ar": "بورغستال",
                "label_fr": "Burgstall"
            },
            {
                "value": 40617,
                "label": "Beilstein",
                "label_ar": "بيلشتاين",
                "label_fr": "Beilstein"
            },
            {
                "value": 40618,
                "label": "Lowenstein",
                "label_ar": "لوينشتاين",
                "label_fr": "Lowenstein"
            },
            {
                "value": 40619,
                "label": "Calvorde",
                "label_ar": "كالفورد",
                "label_fr": "Calvorde"
            },
            {
                "value": 40620,
                "label": "Stadtallendorf",
                "label_ar": "ستادتاليندورف",
                "label_fr": "Stadtallendorf"
            },
            {
                "value": 40621,
                "label": "Friedland",
                "label_ar": "فريدلاند",
                "label_fr": "Friedland"
            },
            {
                "value": 40622,
                "label": "Heltersberg",
                "label_ar": "هيلترسبيرج",
                "label_fr": "Heltersberg"
            },
            {
                "value": 40623,
                "label": "Horbach",
                "label_ar": "حورباتش",
                "label_fr": "Horbach"
            },
            {
                "value": 40624,
                "label": "Nalbach",
                "label_ar": "نالباخ",
                "label_fr": "Nalbach"
            },
            {
                "value": 40625,
                "label": "Gerlfangen",
                "label_ar": "جيرلفانجن",
                "label_fr": "Gerlfangen"
            },
            {
                "value": 40626,
                "label": "Queidersbach",
                "label_ar": "كويدرسباخ",
                "label_fr": "Queidersbach"
            },
            {
                "value": 40627,
                "label": "Kallmunz",
                "label_ar": "كالمونز",
                "label_fr": "Kallmunz"
            },
            {
                "value": 40628,
                "label": "Leiblfing",
                "label_ar": "ليبلفينغ",
                "label_fr": "Leiblfing"
            },
            {
                "value": 40629,
                "label": "Iggensbach",
                "label_ar": "إيجينسباخ",
                "label_fr": "Iggensbach"
            },
            {
                "value": 40630,
                "label": "Kirchroth",
                "label_ar": "كيركروث",
                "label_fr": "Kirchroth"
            },
            {
                "value": 40631,
                "label": "Abensberg",
                "label_ar": "أبينسبيرج",
                "label_fr": "Abensberg"
            },
            {
                "value": 40632,
                "label": "Haidhof",
                "label_ar": "هايدهوف",
                "label_fr": "Haidhof"
            },
            {
                "value": 40633,
                "label": "Morsbach",
                "label_ar": "مورسباخ",
                "label_fr": "Morsbach"
            },
            {
                "value": 40634,
                "label": "Nohfelden",
                "label_ar": "Nohfelden",
                "label_fr": "Nohfelden"
            },
            {
                "value": 40635,
                "label": "Grafenroda",
                "label_ar": "جرافينرودا",
                "label_fr": "Grafenroda"
            },
            {
                "value": 40636,
                "label": "Dippach",
                "label_ar": "ديباخ",
                "label_fr": "Dippach"
            },
            {
                "value": 40637,
                "label": "Ruhla",
                "label_ar": "روهلا",
                "label_fr": "Ruhla"
            },
            {
                "value": 40638,
                "label": "Schleusingen",
                "label_ar": "شليوسينغن",
                "label_fr": "Schleusingen"
            },
            {
                "value": 40639,
                "label": "Vacha",
                "label_ar": "فاكا",
                "label_fr": "Vacha"
            },
            {
                "value": 40640,
                "label": "Schonwald im Schwarzwald",
                "label_ar": "شونوالد إم شوارزوالد",
                "label_fr": "Schonwald im Schwarzwald"
            },
            {
                "value": 40641,
                "label": "Lampertswalde",
                "label_ar": "لامبيرتسوالد",
                "label_fr": "Lampertswalde"
            },
            {
                "value": 40642,
                "label": "Kreischa",
                "label_ar": "كريشا",
                "label_fr": "Kreischa"
            },
            {
                "value": 40643,
                "label": "Ostseebad Nienhagen",
                "label_ar": "أوستسيباد نينهاجين",
                "label_fr": "Ostseebad Nienhagen"
            },
            {
                "value": 40644,
                "label": "Seeheilbad Graal-Muritz",
                "label_ar": "Seeheilbad Graal-Muritz",
                "label_fr": "Seeheilbad Graal-Muritz"
            },
            {
                "value": 40645,
                "label": "Marlow",
                "label_ar": "مارلو",
                "label_fr": "Marlow"
            },
            {
                "value": 40646,
                "label": "Glashagen",
                "label_ar": "غلاشاجين",
                "label_fr": "Glashagen"
            },
            {
                "value": 40647,
                "label": "Vietgest",
                "label_ar": "فيتجيست",
                "label_fr": "Vietgest"
            },
            {
                "value": 40648,
                "label": "Schwaan",
                "label_ar": "شوان",
                "label_fr": "Schwaan"
            },
            {
                "value": 40649,
                "label": "Blankenhagen",
                "label_ar": "بلانكنهاغن",
                "label_fr": "Blankenhagen"
            },
            {
                "value": 40650,
                "label": "Weitenhagen",
                "label_ar": "ويتنهاغن",
                "label_fr": "Weitenhagen"
            },
            {
                "value": 40651,
                "label": "Hebertshausen",
                "label_ar": "هيبيرتسهاوزن",
                "label_fr": "Hebertshausen"
            },
            {
                "value": 40652,
                "label": "Gaissach",
                "label_ar": "Gaissach",
                "label_fr": "Gaissach"
            },
            {
                "value": 40653,
                "label": "Greiling",
                "label_ar": "جشيم",
                "label_fr": "Greiling"
            },
            {
                "value": 40654,
                "label": "Kirspenich",
                "label_ar": "كيرسبينيتش",
                "label_fr": "Kirspenich"
            },
            {
                "value": 40655,
                "label": "Heimerzheim",
                "label_ar": "هيمرزهايم",
                "label_fr": "Heimerzheim"
            },
            {
                "value": 40656,
                "label": "Nortrup",
                "label_ar": "نورتروب",
                "label_fr": "Nortrup"
            },
            {
                "value": 40657,
                "label": "Voltlage",
                "label_ar": "فولتلاج",
                "label_fr": "Voltlage"
            },
            {
                "value": 40658,
                "label": "Loningen",
                "label_ar": "لونينجن",
                "label_fr": "Loningen"
            },
            {
                "value": 40659,
                "label": "Bad Laer",
                "label_ar": "سيئة لاير",
                "label_fr": "Bad Laer"
            },
            {
                "value": 40660,
                "label": "Bad Teinach-Zavelstein",
                "label_ar": "باد تيناش زافيلشتاين",
                "label_fr": "Bad Teinach-Zavelstein"
            },
            {
                "value": 40661,
                "label": "Meschede",
                "label_ar": "ميشيد",
                "label_fr": "Meschede"
            },
            {
                "value": 40662,
                "label": "Kammerforst",
                "label_ar": "كاميرفورست",
                "label_fr": "Kammerforst"
            },
            {
                "value": 40663,
                "label": "Hohenleimbach",
                "label_ar": "هوهنليمباخ",
                "label_fr": "Hohenleimbach"
            },
            {
                "value": 40664,
                "label": "Leubsdorf",
                "label_ar": "ليوبسدورف",
                "label_fr": "Leubsdorf"
            },
            {
                "value": 40665,
                "label": "Helferskirchen",
                "label_ar": "هيلفرسكيرشن",
                "label_fr": "Helferskirchen"
            },
            {
                "value": 40666,
                "label": "Kempenich",
                "label_ar": "كمبينيتش",
                "label_fr": "Kempenich"
            },
            {
                "value": 40667,
                "label": "Bell",
                "label_ar": "جرس",
                "label_fr": "Cloche"
            },
            {
                "value": 40668,
                "label": "Rheinbrohl",
                "label_ar": "راينبروهل",
                "label_fr": "Rheinbrohl"
            },
            {
                "value": 40669,
                "label": "Eitelborn",
                "label_ar": "إيتلبورن",
                "label_fr": "Eitelborn"
            },
            {
                "value": 40670,
                "label": "Schmitten",
                "label_ar": "شميتن",
                "label_fr": "Schmitten"
            },
            {
                "value": 40671,
                "label": "Hepberg",
                "label_ar": "هيبيرج",
                "label_fr": "Hepberg"
            },
            {
                "value": 40672,
                "label": "Jetzendorf",
                "label_ar": "جيتزيندورف",
                "label_fr": "Jetzendorf"
            },
            {
                "value": 40673,
                "label": "Bad Heilbrunn",
                "label_ar": "باد هيلبرون",
                "label_fr": "Bad Heilbrunn"
            },
            {
                "value": 40674,
                "label": "Singhofen",
                "label_ar": "سينجهوفن",
                "label_fr": "Singhofen"
            },
            {
                "value": 40675,
                "label": "Freckenfeld",
                "label_ar": "فريكنفيلد",
                "label_fr": "Freckenfeld"
            },
            {
                "value": 40676,
                "label": "Burgstetten",
                "label_ar": "بورغستيتن",
                "label_fr": "Burgstetten"
            },
            {
                "value": 40677,
                "label": "Thyrnau",
                "label_ar": "ثيرناو",
                "label_fr": "Thyrnau"
            },
            {
                "value": 40678,
                "label": "Kirchdorf",
                "label_ar": "كيرشدورف",
                "label_fr": "Kirchdorf"
            },
            {
                "value": 40679,
                "label": "Triftern",
                "label_ar": "تريفتيرن",
                "label_fr": "Triftern"
            },
            {
                "value": 40680,
                "label": "Pfeffenhausen",
                "label_ar": "بفيفنهاوزن",
                "label_fr": "Pfeffenhausen"
            },
            {
                "value": 40681,
                "label": "Freyung",
                "label_ar": "فرايونغ",
                "label_fr": "Freyung"
            },
            {
                "value": 40682,
                "label": "Bad Schwalbach",
                "label_ar": "باد شفالباخ",
                "label_fr": "Bad Schwalbach"
            },
            {
                "value": 40683,
                "label": "Nackenheim",
                "label_ar": "ناكنهايم",
                "label_fr": "Nackenheim"
            },
            {
                "value": 40684,
                "label": "Rudesheim am Rhein",
                "label_ar": "روديسهايم ام راين",
                "label_fr": "Rudesheim am Rhein"
            },
            {
                "value": 40685,
                "label": "Kiefersfelden",
                "label_ar": "كيفرسفيلدن",
                "label_fr": "Kiefersfelden"
            },
            {
                "value": 40686,
                "label": "Teisendorf",
                "label_ar": "تيسندورف",
                "label_fr": "Teisendorf"
            },
            {
                "value": 40687,
                "label": "Isselburg",
                "label_ar": "ايسلبورغ",
                "label_fr": "Isselburg"
            },
            {
                "value": 40688,
                "label": "Billigheim",
                "label_ar": "بيليغهايم",
                "label_fr": "Billigheim"
            },
            {
                "value": 40689,
                "label": "Zwingenberg",
                "label_ar": "زوينجنبرج",
                "label_fr": "Zwingenberg"
            },
            {
                "value": 40690,
                "label": "Weisenheim am Berg",
                "label_ar": "Weisenheim am Berg",
                "label_fr": "Weisenheim am Berg"
            },
            {
                "value": 40691,
                "label": "Krauchenwies",
                "label_ar": "Krauchenwies",
                "label_fr": "Krauchenwies"
            },
            {
                "value": 40692,
                "label": "Bevern",
                "label_ar": "بيفيرن",
                "label_fr": "Bevern"
            },
            {
                "value": 40693,
                "label": "Ravenstein",
                "label_ar": "رافنشتاين",
                "label_fr": "Ravenstein"
            },
            {
                "value": 40694,
                "label": "Gaiberg",
                "label_ar": "Gaiberg",
                "label_fr": "Gaiberg"
            },
            {
                "value": 40695,
                "label": "Seckach",
                "label_ar": "سيكاتش",
                "label_fr": "Seckach"
            },
            {
                "value": 40696,
                "label": "Bammental",
                "label_ar": "بامنتال",
                "label_fr": "Bammental"
            },
            {
                "value": 40697,
                "label": "Adelsheim",
                "label_ar": "Adelsheim",
                "label_fr": "Adelsheim"
            },
            {
                "value": 40698,
                "label": "Burbach",
                "label_ar": "بورباخ",
                "label_fr": "Burbach"
            },
            {
                "value": 40699,
                "label": "Derschen",
                "label_ar": "ديرشين",
                "label_fr": "Derschen"
            },
            {
                "value": 40700,
                "label": "Erwitte",
                "label_ar": "ارويت",
                "label_fr": "Erwitte"
            },
            {
                "value": 40701,
                "label": "Mastholte",
                "label_ar": "ماستولت",
                "label_fr": "Mastholte"
            },
            {
                "value": 40702,
                "label": "Arnbach",
                "label_ar": "ارنباخ",
                "label_fr": "Arnbach"
            },
            {
                "value": 40703,
                "label": "Ampermoching",
                "label_ar": "أمبرموشينج",
                "label_fr": "Ampermoching"
            },
            {
                "value": 40704,
                "label": "Vilsbiburg",
                "label_ar": "فيلسبورغ",
                "label_fr": "Vilsbiburg"
            },
            {
                "value": 40705,
                "label": "Wettstetten",
                "label_ar": "Wettstetten",
                "label_fr": "Wettstetten"
            },
            {
                "value": 40706,
                "label": "Moorenweis",
                "label_ar": "مورينويس",
                "label_fr": "Moorenweis"
            },
            {
                "value": 40707,
                "label": "Datteln",
                "label_ar": "داتيلن",
                "label_fr": "Datteln"
            },
            {
                "value": 40708,
                "label": "Schonstett",
                "label_ar": "شونستيت",
                "label_fr": "Schonstett"
            },
            {
                "value": 40709,
                "label": "Chieming",
                "label_ar": "شيمينغ",
                "label_fr": "Chieming"
            },
            {
                "value": 40710,
                "label": "Tuszshing",
                "label_ar": "Tuszshing",
                "label_fr": "Tuszshing"
            },
            {
                "value": 40711,
                "label": "Aschau",
                "label_ar": "أشاو",
                "label_fr": "Aschau"
            },
            {
                "value": 40712,
                "label": "Oberneukirchen",
                "label_ar": "Oberneukirchen",
                "label_fr": "Oberneukirchen"
            },
            {
                "value": 40713,
                "label": "Laufen",
                "label_ar": "لوفين",
                "label_fr": "Laufen"
            },
            {
                "value": 40714,
                "label": "Ramsau",
                "label_ar": "رامساو",
                "label_fr": "Ramsau"
            },
            {
                "value": 40715,
                "label": "Schleching",
                "label_ar": "شليشينج",
                "label_fr": "Schleching"
            },
            {
                "value": 40716,
                "label": "Petting",
                "label_ar": "الملاعبة",
                "label_fr": "Caresse"
            },
            {
                "value": 40717,
                "label": "Vachendorf",
                "label_ar": "فاشيندورف",
                "label_fr": "Vachendorf"
            },
            {
                "value": 40718,
                "label": "Grabenstatt",
                "label_ar": "غرابينستات",
                "label_fr": "Grabenstatt"
            },
            {
                "value": 40719,
                "label": "Breest",
                "label_ar": "بريست",
                "label_fr": "Breest"
            },
            {
                "value": 40720,
                "label": "Grambin",
                "label_ar": "جرامبين",
                "label_fr": "Grambin"
            },
            {
                "value": 40721,
                "label": "Carmzow",
                "label_ar": "كارمزوف",
                "label_fr": "Carmzow"
            },
            {
                "value": 40722,
                "label": "Torgelow",
                "label_ar": "Torgelow",
                "label_fr": "Torgelow"
            },
            {
                "value": 40723,
                "label": "Wilhelmsburg",
                "label_ar": "فيلهلمسبورج",
                "label_fr": "Wilhelmsburg"
            },
            {
                "value": 40724,
                "label": "Thurkow",
                "label_ar": "ثوركوف",
                "label_fr": "Thurkow"
            },
            {
                "value": 40725,
                "label": "Horstel",
                "label_ar": "هورستل",
                "label_fr": "Horstel"
            },
            {
                "value": 40726,
                "label": "Dahlem",
                "label_ar": "داهليم",
                "label_fr": "Dahlem"
            },
            {
                "value": 40727,
                "label": "Dunningen",
                "label_ar": "دانينغين",
                "label_fr": "Dunningen"
            },
            {
                "value": 40728,
                "label": "Oberopfingen",
                "label_ar": "أوبيروبفينجين",
                "label_fr": "Oberopfingen"
            },
            {
                "value": 40729,
                "label": "Oberkammlach",
                "label_ar": "Oberkammlach",
                "label_fr": "Oberkammlach"
            },
            {
                "value": 40730,
                "label": "Egg an der Gunz",
                "label_ar": "Egg an der Gunz",
                "label_fr": "Egg an der Gunz"
            },
            {
                "value": 40731,
                "label": "Blaichach",
                "label_ar": "Blaichach",
                "label_fr": "Blaichach"
            },
            {
                "value": 40732,
                "label": "Seeg",
                "label_ar": "Seeg",
                "label_fr": "Seeg"
            },
            {
                "value": 40733,
                "label": "Buhlertann",
                "label_ar": "بولرتان",
                "label_fr": "Buhlertann"
            },
            {
                "value": 40734,
                "label": "Bonndorf im Schwarzwald",
                "label_ar": "Bonndorf im Schwarzwald",
                "label_fr": "Bonndorf im Schwarzwald"
            },
            {
                "value": 40735,
                "label": "Buchenbach",
                "label_ar": "بوخينباخ",
                "label_fr": "Buchenbach"
            },
            {
                "value": 40736,
                "label": "Schluchsee",
                "label_ar": "شلوشسي",
                "label_fr": "Schluchsee"
            },
            {
                "value": 40737,
                "label": "Sexau",
                "label_ar": "سيساو",
                "label_fr": "Sexau"
            },
            {
                "value": 40738,
                "label": "Bahlingen",
                "label_ar": "باهلينجن",
                "label_fr": "Bahlingen"
            },
            {
                "value": 40739,
                "label": "Merdingen",
                "label_ar": "Merdingen",
                "label_fr": "Merdingen"
            },
            {
                "value": 40740,
                "label": "Solden",
                "label_ar": "سولدين",
                "label_fr": "Solden"
            },
            {
                "value": 40741,
                "label": "Blankenrath",
                "label_ar": "بلانكنراث",
                "label_fr": "Blankenrath"
            },
            {
                "value": 40742,
                "label": "Fell",
                "label_ar": "سقط",
                "label_fr": "Est tombée"
            },
            {
                "value": 40743,
                "label": "Trierweiler",
                "label_ar": "تريرويلر",
                "label_fr": "Trierweiler"
            },
            {
                "value": 40744,
                "label": "Arenrath",
                "label_ar": "أرينراث",
                "label_fr": "Arenrath"
            },
            {
                "value": 40745,
                "label": "Saarburg",
                "label_ar": "ساربورغ",
                "label_fr": "Saarburg"
            },
            {
                "value": 40746,
                "label": "Irrel",
                "label_ar": "ايرل",
                "label_fr": "Irrel"
            },
            {
                "value": 40747,
                "label": "Beuren",
                "label_ar": "بورين",
                "label_fr": "Beuren"
            },
            {
                "value": 40748,
                "label": "Longuich",
                "label_ar": "Longuich",
                "label_fr": "Longuich"
            },
            {
                "value": 40749,
                "label": "Gerolstein",
                "label_ar": "جيرولشتاين",
                "label_fr": "Gerolstein"
            },
            {
                "value": 40750,
                "label": "Osterspai",
                "label_ar": "أوسترسباي",
                "label_fr": "Osterspai"
            },
            {
                "value": 40751,
                "label": "Bad Honningen",
                "label_ar": "باد هونينغين",
                "label_fr": "Bad Hönningen"
            },
            {
                "value": 40752,
                "label": "Willroth",
                "label_ar": "ويلروث",
                "label_fr": "Willroth"
            },
            {
                "value": 40753,
                "label": "Hausten",
                "label_ar": "Hausten",
                "label_fr": "Hausten"
            },
            {
                "value": 40754,
                "label": "Hachenburg",
                "label_ar": "هاشينبورج",
                "label_fr": "Hachenburg"
            },
            {
                "value": 40755,
                "label": "Mertloch",
                "label_ar": "ميرتلوخ",
                "label_fr": "Mertloch"
            },
            {
                "value": 40756,
                "label": "Wissen",
                "label_ar": "ويسن",
                "label_fr": "Wissen"
            },
            {
                "value": 40757,
                "label": "Cochem",
                "label_ar": "كوشيم",
                "label_fr": "Cochem"
            },
            {
                "value": 40758,
                "label": "Kruft",
                "label_ar": "كروفت",
                "label_fr": "Kruft"
            },
            {
                "value": 40759,
                "label": "Faid",
                "label_ar": "فايد",
                "label_fr": "Faid"
            },
            {
                "value": 40760,
                "label": "Bad Marienberg",
                "label_ar": "باد مارينبيرج",
                "label_fr": "Bad Marienberg"
            },
            {
                "value": 40761,
                "label": "Dernau",
                "label_ar": "ديرناو",
                "label_fr": "Dernau"
            },
            {
                "value": 40762,
                "label": "Oberelbert",
                "label_ar": "أوبيرلبرت",
                "label_fr": "Oberelbert"
            },
            {
                "value": 40763,
                "label": "Alpenrod",
                "label_ar": "ألبينرود",
                "label_fr": "Alpenrod"
            },
            {
                "value": 40764,
                "label": "Welver",
                "label_ar": "ويلفر",
                "label_fr": "Welver"
            },
            {
                "value": 40765,
                "label": "Reichertshofen",
                "label_ar": "Reichertshofen",
                "label_fr": "Reichertshofen"
            },
            {
                "value": 40766,
                "label": "Karlskron",
                "label_ar": "كارلسكرون",
                "label_fr": "Karlskron"
            },
            {
                "value": 40767,
                "label": "Bestwig",
                "label_ar": "Bestwig",
                "label_fr": "Bestwig"
            },
            {
                "value": 40768,
                "label": "Schmallenberg",
                "label_ar": "شمالينبيرج",
                "label_fr": "Schmallenberg"
            },
            {
                "value": 40769,
                "label": "Ruthen",
                "label_ar": "روثن",
                "label_fr": "Ruthen"
            },
            {
                "value": 40770,
                "label": "Kervenheim",
                "label_ar": "كيرفينهايم",
                "label_fr": "Kervenheim"
            },
            {
                "value": 40771,
                "label": "Treudelberg",
                "label_ar": "Treudelberg",
                "label_fr": "Treudelberg"
            },
            {
                "value": 40772,
                "label": "Winden",
                "label_ar": "ويندين",
                "label_fr": "Winden"
            },
            {
                "value": 40773,
                "label": "Albbruck",
                "label_ar": "ألبروك",
                "label_fr": "Albbruck"
            },
            {
                "value": 40774,
                "label": "Neuhausen ob Eck",
                "label_ar": "نويهاوزن أوب إيك",
                "label_fr": "Neuhausen ob Eck"
            },
            {
                "value": 40775,
                "label": "Bad Steben",
                "label_ar": "باد ستيبين",
                "label_fr": "Bad Steben"
            },
            {
                "value": 40776,
                "label": "Seebad Ahlbeck",
                "label_ar": "سيباد احبك",
                "label_fr": "Seebad Ahlbeck"
            },
            {
                "value": 40777,
                "label": "Hallenberg",
                "label_ar": "هالينبيرج",
                "label_fr": "Hallenberg"
            },
            {
                "value": 40778,
                "label": "Finnentrop",
                "label_ar": "فينينتروب",
                "label_fr": "Finnentrop"
            },
            {
                "value": 40779,
                "label": "Orbis",
                "label_ar": "أوربيس",
                "label_fr": "Orbis"
            },
            {
                "value": 40780,
                "label": "Hofen an der Enz",
                "label_ar": "هوفن ان دير انز",
                "label_fr": "Hofen an der Enz"
            },
            {
                "value": 40781,
                "label": "Darstein",
                "label_ar": "دارستين",
                "label_fr": "Darstein"
            },
            {
                "value": 40782,
                "label": "Moyland",
                "label_ar": "مويلاند",
                "label_fr": "Moyland"
            },
            {
                "value": 40783,
                "label": "Borsfleth",
                "label_ar": "بورسفلث",
                "label_fr": "Borsfleth"
            },
            {
                "value": 40784,
                "label": "Rackwitz",
                "label_ar": "راكويتز",
                "label_fr": "Rackwitz"
            },
            {
                "value": 40785,
                "label": "Maierhofen",
                "label_ar": "مايرهوفن",
                "label_fr": "Maierhofen"
            },
            {
                "value": 40786,
                "label": "Waldschlosschen",
                "label_ar": "فالدشلوسشين",
                "label_fr": "Waldschlosschen"
            },
            {
                "value": 40787,
                "label": "Kirburg",
                "label_ar": "كيربورغ",
                "label_fr": "Kirburg"
            },
            {
                "value": 40788,
                "label": "Mulda",
                "label_ar": "مولدا",
                "label_fr": "Mulda"
            },
            {
                "value": 40789,
                "label": "Vollkofen",
                "label_ar": "فولكوفين",
                "label_fr": "Vollkofen"
            },
            {
                "value": 40790,
                "label": "Spessart",
                "label_ar": "سبيسارت",
                "label_fr": "Spessart"
            },
            {
                "value": 40791,
                "label": "Grosskugel",
                "label_ar": "جروسكوغل",
                "label_fr": "Grosskugel"
            },
            {
                "value": 40792,
                "label": "Weiding",
                "label_ar": "Weiding",
                "label_fr": "Weiding"
            },
            {
                "value": 40793,
                "label": "Woltingerode",
                "label_ar": "وولتينجيرود",
                "label_fr": "Woltingerode"
            },
            {
                "value": 40794,
                "label": "Eibsee",
                "label_ar": "ايبسي",
                "label_fr": "Eibsee"
            },
            {
                "value": 40795,
                "label": "Schlierbach",
                "label_ar": "شليرباخ",
                "label_fr": "Schlierbach"
            },
            {
                "value": 40796,
                "label": "Hermannsburg",
                "label_ar": "هيرمانسبورج",
                "label_fr": "Hermannsburg"
            },
            {
                "value": 40797,
                "label": "Wehnrath",
                "label_ar": "وينراث",
                "label_fr": "Wehnrath"
            },
            {
                "value": 40798,
                "label": "Neuental",
                "label_ar": "Neuental",
                "label_fr": "Neuental"
            },
            {
                "value": 40799,
                "label": "Kurten",
                "label_ar": "كورتن",
                "label_fr": "Kurten"
            },
            {
                "value": 40800,
                "label": "Purgen",
                "label_ar": "بورغن",
                "label_fr": "Purgen"
            },
            {
                "value": 40801,
                "label": "Hahn",
                "label_ar": "هان",
                "label_fr": "Hahn"
            },
            {
                "value": 40802,
                "label": "Festenburg",
                "label_ar": "فيستنبرغ",
                "label_fr": "Festenburg"
            },
            {
                "value": 40803,
                "label": "Stockhausen",
                "label_ar": "ستوكهاوزن",
                "label_fr": "Stockhausen"
            },
            {
                "value": 40804,
                "label": "Thoma",
                "label_ar": "توما",
                "label_fr": "Thoma"
            },
            {
                "value": 40805,
                "label": "Teschow",
                "label_ar": "تيشو",
                "label_fr": "Teschow"
            },
            {
                "value": 40806,
                "label": "Ballendorf",
                "label_ar": "باليندورف",
                "label_fr": "Ballendorf"
            },
            {
                "value": 40807,
                "label": "Hohne",
                "label_ar": "هوهن",
                "label_fr": "Hohne"
            },
            {
                "value": 40808,
                "label": "Wentorf",
                "label_ar": "وينتورف",
                "label_fr": "Wentorf"
            },
            {
                "value": 40809,
                "label": "Vettelschoss",
                "label_ar": "فيتيلشوس",
                "label_fr": "Vettelschoss"
            },
            {
                "value": 40810,
                "label": "Lubmin",
                "label_ar": "لوبمين",
                "label_fr": "Lubmin"
            },
            {
                "value": 40811,
                "label": "Stapelfeld",
                "label_ar": "ستابلفيلد",
                "label_fr": "Stapelfeld"
            },
            {
                "value": 40812,
                "label": "Klein Offenseth-Sparrieshoop",
                "label_ar": "كلاين Offenseth-Sparrieshoop",
                "label_fr": "Klein Offenseth-Sparrieshoop"
            },
            {
                "value": 40813,
                "label": "Hinterm Horn",
                "label_ar": "هنترم هورن",
                "label_fr": "Corne Hinterm"
            },
            {
                "value": 40814,
                "label": "Altenmedingen",
                "label_ar": "التنمدينجن",
                "label_fr": "Altenmedingen"
            },
            {
                "value": 40815,
                "label": "Spalt",
                "label_ar": "سبالت",
                "label_fr": "Spalt"
            },
            {
                "value": 40816,
                "label": "Hilpoltstein",
                "label_ar": "هيلبولتشتاين",
                "label_fr": "Hilpoltstein"
            },
            {
                "value": 40817,
                "label": "Kettenkamp",
                "label_ar": "كيتنكامب",
                "label_fr": "Kettenkamp"
            },
            {
                "value": 40818,
                "label": "Haselunne",
                "label_ar": "هاسيلون",
                "label_fr": "Haselunne"
            },
            {
                "value": 40819,
                "label": "Jaderberg",
                "label_ar": "جادربرج",
                "label_fr": "Jaderberg"
            },
            {
                "value": 40820,
                "label": "Metjendorf",
                "label_ar": "Metjendorf",
                "label_fr": "Metjendorf"
            },
            {
                "value": 40821,
                "label": "Recke",
                "label_ar": "ريكي",
                "label_fr": "Recke"
            },
            {
                "value": 40822,
                "label": "Salzburg",
                "label_ar": "سالزبورغ",
                "label_fr": "Salzbourg"
            },
            {
                "value": 40823,
                "label": "Basel",
                "label_ar": "باسل",
                "label_fr": "Bâle"
            },
            {
                "value": 40824,
                "label": "Bulow",
                "label_ar": "بولو",
                "label_fr": "Bulow"
            },
            {
                "value": 40825,
                "label": "Buchwaldchen",
                "label_ar": "Buchwaldchen",
                "label_fr": "Buchwaldchen"
            },
            {
                "value": 40826,
                "label": "Wadelsdorf",
                "label_ar": "وادلسدورف",
                "label_fr": "Wadelsdorf"
            },
            {
                "value": 40827,
                "label": "Beilrode",
                "label_ar": "بيلرود",
                "label_fr": "Beilrode"
            },
            {
                "value": 40828,
                "label": "Schwarz",
                "label_ar": "شوارتز",
                "label_fr": "Schwarz"
            },
            {
                "value": 40829,
                "label": "Grove",
                "label_ar": "بستان",
                "label_fr": "Bosquet"
            },
            {
                "value": 40830,
                "label": "Sanssouci",
                "label_ar": "سانسوسي",
                "label_fr": "Sanssouci"
            },
            {
                "value": 40831,
                "label": "Tegernsee",
                "label_ar": "تيغرنسي",
                "label_fr": "Tegernsee"
            },
            {
                "value": 40832,
                "label": "Anker",
                "label_ar": "أنكر",
                "label_fr": "Anker"
            },
            {
                "value": 40833,
                "label": "Seehof",
                "label_ar": "سيهوف",
                "label_fr": "Seehof"
            },
            {
                "value": 40834,
                "label": "Kettig",
                "label_ar": "كيتيج",
                "label_fr": "Kettig"
            },
            {
                "value": 40835,
                "label": "Marschall",
                "label_ar": "مارشال",
                "label_fr": "Marschall"
            },
            {
                "value": 40836,
                "label": "Pixel",
                "label_ar": "بكسل",
                "label_fr": "Pixel"
            },
            {
                "value": 40837,
                "label": "Flieden",
                "label_ar": "فلايدين",
                "label_fr": "Flieden"
            },
            {
                "value": 40838,
                "label": "Watzenborn",
                "label_ar": "واتزنبورن",
                "label_fr": "Watzenborn"
            },
            {
                "value": 40839,
                "label": "Delingsdorf",
                "label_ar": "ديلينجسدورف",
                "label_fr": "Delingsdorf"
            },
            {
                "value": 40840,
                "label": "Dorpen",
                "label_ar": "دوربن",
                "label_fr": "Dorpen"
            },
            {
                "value": 40841,
                "label": "Hennstedt",
                "label_ar": "هينستيدت",
                "label_fr": "Hennstedt"
            },
            {
                "value": 40842,
                "label": "Drage",
                "label_ar": "دراج",
                "label_fr": "Drage"
            },
            {
                "value": 40843,
                "label": "Deinste",
                "label_ar": "دينستي",
                "label_fr": "Deinste"
            },
            {
                "value": 40844,
                "label": "Melbeck",
                "label_ar": "ميلبيك",
                "label_fr": "Melbeck"
            },
            {
                "value": 40845,
                "label": "Estorf",
                "label_ar": "إستورف",
                "label_fr": "Estorf"
            },
            {
                "value": 40846,
                "label": "Wankendorf",
                "label_ar": "وانكيندورف",
                "label_fr": "Wankendorf"
            },
            {
                "value": 40847,
                "label": "Heidehof",
                "label_ar": "هيديهوف",
                "label_fr": "Heidehof"
            },
            {
                "value": 40848,
                "label": "Horhausen",
                "label_ar": "هورهاوزن",
                "label_fr": "Horhausen"
            },
            {
                "value": 40849,
                "label": "Dernbach",
                "label_ar": "درنباخ",
                "label_fr": "Dernbach"
            },
            {
                "value": 40850,
                "label": "Selters",
                "label_ar": "زلترس",
                "label_fr": "Selters"
            },
            {
                "value": 40851,
                "label": "Kurtscheid",
                "label_ar": "كورتشايد",
                "label_fr": "Kurtscheid"
            },
            {
                "value": 40852,
                "label": "Kaltenengers",
                "label_ar": "كالتينينجرز",
                "label_fr": "Kaltenengers"
            },
            {
                "value": 40853,
                "label": "Rengsdorf",
                "label_ar": "رينجزدورف",
                "label_fr": "Rengsdorf"
            },
            {
                "value": 40854,
                "label": "Waldesch",
                "label_ar": "فالديش",
                "label_fr": "Waldesch"
            },
            {
                "value": 40855,
                "label": "Spay",
                "label_ar": "سباي",
                "label_fr": "Stériliser"
            },
            {
                "value": 40856,
                "label": "Bassenheim",
                "label_ar": "باسنهايم",
                "label_fr": "Bassenheim"
            },
            {
                "value": 40857,
                "label": "Breitscheid",
                "label_ar": "بريتشيد",
                "label_fr": "Breitscheid"
            },
            {
                "value": 40858,
                "label": "Linkenbach",
                "label_ar": "لينكينباخ",
                "label_fr": "Linkenbach"
            },
            {
                "value": 40859,
                "label": "Dierdorf",
                "label_ar": "ديردورف",
                "label_fr": "Dierdorf"
            },
            {
                "value": 40860,
                "label": "Mellingen",
                "label_ar": "ميلينجن",
                "label_fr": "Mellingen"
            },
            {
                "value": 40861,
                "label": "Komp",
                "label_ar": "كومب",
                "label_fr": "Komp"
            },
            {
                "value": 40862,
                "label": "Rhein",
                "label_ar": "راين",
                "label_fr": "Rhein"
            },
            {
                "value": 40863,
                "label": "Ostercappeln",
                "label_ar": "أوستركابيلن",
                "label_fr": "Ostercappeln"
            },
            {
                "value": 40864,
                "label": "Dorf Doberitz",
                "label_ar": "دورف دوبريتز",
                "label_fr": "Dorf Doberitz"
            },
            {
                "value": 40865,
                "label": "Gutzkow",
                "label_ar": "جوتزكو",
                "label_fr": "Gutzkow"
            },
            {
                "value": 40866,
                "label": "Staig",
                "label_ar": "ستيج",
                "label_fr": "Staig"
            },
            {
                "value": 40867,
                "label": "Achstetten",
                "label_ar": "أشتيتن",
                "label_fr": "Achstetten"
            },
            {
                "value": 40868,
                "label": "Kirchzarten",
                "label_ar": "كيرشزارتن",
                "label_fr": "Kirchzarten"
            },
            {
                "value": 40869,
                "label": "Gunningen",
                "label_ar": "جونينغين",
                "label_fr": "Gunningen"
            },
            {
                "value": 40870,
                "label": "Kapellenberg",
                "label_ar": "كابلينبيرج",
                "label_fr": "Kapellenberg"
            },
            {
                "value": 40871,
                "label": "Bogen",
                "label_ar": "بوجين",
                "label_fr": "Bogen"
            },
            {
                "value": 40872,
                "label": "Ehekirchen",
                "label_ar": "إيكيرشن",
                "label_fr": "Ehekirchen"
            },
            {
                "value": 40873,
                "label": "Hitzhofen",
                "label_ar": "هيتزهوفن",
                "label_fr": "Hitzhofen"
            },
            {
                "value": 40874,
                "label": "Kirchweg",
                "label_ar": "كيرشويج",
                "label_fr": "Kirchweg"
            },
            {
                "value": 40875,
                "label": "Netze",
                "label_ar": "نيتز",
                "label_fr": "Netze"
            },
            {
                "value": 40876,
                "label": "Lohra",
                "label_ar": "لوهرا",
                "label_fr": "Lohra"
            },
            {
                "value": 40877,
                "label": "Merching",
                "label_ar": "الدمج",
                "label_fr": "Merching"
            },
            {
                "value": 40878,
                "label": "Herringen",
                "label_ar": "هيرينجين",
                "label_fr": "Herringen"
            },
            {
                "value": 40879,
                "label": "Birkenhof",
                "label_ar": "بيركينهوف",
                "label_fr": "Birkenhof"
            },
            {
                "value": 40880,
                "label": "Badenweiler",
                "label_ar": "بادنويللر",
                "label_fr": "Badenweiler"
            },
            {
                "value": 40881,
                "label": "Bodensee",
                "label_ar": "بودينسي",
                "label_fr": "Bodensee"
            },
            {
                "value": 40882,
                "label": "Bensberg",
                "label_ar": "بنسبيرج",
                "label_fr": "Bensberg"
            },
            {
                "value": 40883,
                "label": "Stromberg",
                "label_ar": "سترومبرغ",
                "label_fr": "Stromberg"
            },
            {
                "value": 40884,
                "label": "Ohlsdorf",
                "label_ar": "اهلسدورف",
                "label_fr": "Ohlsdorf"
            },
            {
                "value": 40885,
                "label": "Walpertshofen",
                "label_ar": "Walpertshofen",
                "label_fr": "Walpertshofen"
            },
            {
                "value": 40886,
                "label": "Rothenburg",
                "label_ar": "روتنبورغ",
                "label_fr": "Rothenburg"
            },
            {
                "value": 40887,
                "label": "Gravenbruch",
                "label_ar": "جرافنبروش",
                "label_fr": "Gravenbruch"
            },
            {
                "value": 40888,
                "label": "Osch",
                "label_ar": "أوش",
                "label_fr": "Osch"
            },
            {
                "value": 40889,
                "label": "Wall",
                "label_ar": "حائط",
                "label_fr": "mur"
            },
            {
                "value": 40890,
                "label": "Munich",
                "label_ar": "ميونيخ",
                "label_fr": "Munich"
            },
            {
                "value": 40891,
                "label": "Bad Schachen",
                "label_ar": "باد شاشين",
                "label_fr": "Bad Schachen"
            },
            {
                "value": 40892,
                "label": "Vorbach",
                "label_ar": "فورباخ",
                "label_fr": "Vorbach"
            },
            {
                "value": 40893,
                "label": "Garden",
                "label_ar": "حديقة",
                "label_fr": "Jardin"
            },
            {
                "value": 40894,
                "label": "Im Loh",
                "label_ar": "ايم لوه",
                "label_fr": "Im Loh"
            },
            {
                "value": 40895,
                "label": "Teistungenburg",
                "label_ar": "تيستونغنبورغ",
                "label_fr": "Teistungenburg"
            },
            {
                "value": 40896,
                "label": "Nobitz",
                "label_ar": "نوبيتز",
                "label_fr": "Nobitz"
            },
            {
                "value": 40897,
                "label": "Brandl",
                "label_ar": "براندل",
                "label_fr": "Brandl"
            },
            {
                "value": 40898,
                "label": "Heideck",
                "label_ar": "هايدك",
                "label_fr": "Heideck"
            },
            {
                "value": 40899,
                "label": "Postbauer-Heng",
                "label_ar": "Postbauer-Heng",
                "label_fr": "Postbauer-Heng"
            },
            {
                "value": 40900,
                "label": "Berngau",
                "label_ar": "بيرنجاو",
                "label_fr": "Berngau"
            },
            {
                "value": 40901,
                "label": "Timmaspe",
                "label_ar": "تيمماس",
                "label_fr": "Timmaspe"
            },
            {
                "value": 40902,
                "label": "Gnutz",
                "label_ar": "غنوتز",
                "label_fr": "Gnutz"
            },
            {
                "value": 40903,
                "label": "Wattenbek",
                "label_ar": "فاتنبيك",
                "label_fr": "Wattenbek"
            },
            {
                "value": 40904,
                "label": "Kayhude",
                "label_ar": "كيهود",
                "label_fr": "Kayhude"
            },
            {
                "value": 40905,
                "label": "Bimohlen",
                "label_ar": "بيموهلين",
                "label_fr": "Bimohlen"
            },
            {
                "value": 40906,
                "label": "Stephanskirchen",
                "label_ar": "ستيفانسكيرشن",
                "label_fr": "Stephanskirchen"
            },
            {
                "value": 40907,
                "label": "Martinsried",
                "label_ar": "Martinsried",
                "label_fr": "Martinsried"
            },
            {
                "value": 40908,
                "label": "Geisenhausen",
                "label_ar": "جيزنهاوزن",
                "label_fr": "Geisenhausen"
            },
            {
                "value": 40909,
                "label": "Forchheim",
                "label_ar": "فورشهايم",
                "label_fr": "Forchheim"
            },
            {
                "value": 40910,
                "label": "Adelsdorf",
                "label_ar": "Adelsdorf",
                "label_fr": "Adelsdorf"
            },
            {
                "value": 40911,
                "label": "Hausen",
                "label_ar": "Hausen",
                "label_fr": "Hausen"
            },
            {
                "value": 40912,
                "label": "Ihringen",
                "label_ar": "إهرنجن",
                "label_fr": "Ihringen"
            },
            {
                "value": 40913,
                "label": "Kenzingen",
                "label_ar": "كنزينجين",
                "label_fr": "Kenzingen"
            },
            {
                "value": 40914,
                "label": "Altdorf",
                "label_ar": "التدورف",
                "label_fr": "Altdorf"
            },
            {
                "value": 40915,
                "label": "Rechberg",
                "label_ar": "Rechberg",
                "label_fr": "Rechberg"
            },
            {
                "value": 40916,
                "label": "Erzingen",
                "label_ar": "إرزنجن",
                "label_fr": "Erzingen"
            },
            {
                "value": 40917,
                "label": "Altbach",
                "label_ar": "ألتباخ",
                "label_fr": "Altbach"
            },
            {
                "value": 40918,
                "label": "Althutte",
                "label_ar": "Althutte",
                "label_fr": "Althutte"
            },
            {
                "value": 40919,
                "label": "Eitensheim",
                "label_ar": "إيتنشايم",
                "label_fr": "Eitensheim"
            },
            {
                "value": 40920,
                "label": "Kutzenhausen",
                "label_ar": "كوتزنهاوزن",
                "label_fr": "Kutzenhausen"
            },
            {
                "value": 40921,
                "label": "Osburg",
                "label_ar": "أوسبورغ",
                "label_fr": "Osburg"
            },
            {
                "value": 40922,
                "label": "Bickenbach",
                "label_ar": "بيكنباخ",
                "label_fr": "Bickenbach"
            },
            {
                "value": 40923,
                "label": "Erbach im Odenwald",
                "label_ar": "Erbach im Odenwald",
                "label_fr": "Erbach im Odenwald"
            },
            {
                "value": 40924,
                "label": "Furth",
                "label_ar": "فورث",
                "label_fr": "Furth"
            },
            {
                "value": 40925,
                "label": "Alzey",
                "label_ar": "ألزي",
                "label_fr": "Alzey"
            },
            {
                "value": 40926,
                "label": "Biebesheim",
                "label_ar": "Biebesheim",
                "label_fr": "Biebesheim"
            },
            {
                "value": 40927,
                "label": "Wallmerod",
                "label_ar": "والميرود",
                "label_fr": "Wallmerod"
            },
            {
                "value": 40928,
                "label": "Partenheim",
                "label_ar": "بارتنهايم",
                "label_fr": "Partenheim"
            },
            {
                "value": 40929,
                "label": "Harxheim",
                "label_ar": "هاركسهايم",
                "label_fr": "Harxheim"
            },
            {
                "value": 40930,
                "label": "Laurenburg",
                "label_ar": "لورنبرج",
                "label_fr": "Laurenburg"
            },
            {
                "value": 40931,
                "label": "Sprendlingen",
                "label_ar": "Sprendlingen",
                "label_fr": "Sprendlingen"
            },
            {
                "value": 40932,
                "label": "Friesenhagen",
                "label_ar": "فريزينهاغن",
                "label_fr": "Friesenhagen"
            },
            {
                "value": 40933,
                "label": "Macken",
                "label_ar": "ماكين",
                "label_fr": "Macken"
            },
            {
                "value": 40934,
                "label": "Bardenberg",
                "label_ar": "باردنبرغ",
                "label_fr": "Bardenberg"
            },
            {
                "value": 40935,
                "label": "Berlin",
                "label_ar": "برلين",
                "label_fr": "Berlin"
            },
            {
                "value": 40936,
                "label": "Uelsen",
                "label_ar": "أولسن",
                "label_fr": "Uelsen"
            },
            {
                "value": 40937,
                "label": "Suddendorf",
                "label_ar": "سودديندورف",
                "label_fr": "Suddendorf"
            },
            {
                "value": 40938,
                "label": "Bawinkel",
                "label_ar": "باوينكل",
                "label_fr": "Bawinkel"
            },
            {
                "value": 40939,
                "label": "Hoogstede",
                "label_ar": "هوجستيد",
                "label_fr": "Hoogstede"
            },
            {
                "value": 40940,
                "label": "Georgsdorf",
                "label_ar": "جورجسدورف",
                "label_fr": "Georgsdorf"
            },
            {
                "value": 40941,
                "label": "Lage",
                "label_ar": "لاج",
                "label_fr": "Lage"
            },
            {
                "value": 40942,
                "label": "Furstenau",
                "label_ar": "فورستيناو",
                "label_fr": "Furstenau"
            },
            {
                "value": 40943,
                "label": "Freren",
                "label_ar": "فرين",
                "label_fr": "Freren"
            },
            {
                "value": 40944,
                "label": "Renchen",
                "label_ar": "رينشين",
                "label_fr": "Renchen"
            },
            {
                "value": 40945,
                "label": "Hofstetten",
                "label_ar": "هوفستيتن",
                "label_fr": "Hofstetten"
            },
            {
                "value": 40946,
                "label": "Fischerbach",
                "label_ar": "فيشرباخ",
                "label_fr": "Fischerbach"
            },
            {
                "value": 40947,
                "label": "Oberharmersbach",
                "label_ar": "أوبرهارميرسباخ",
                "label_fr": "Oberharmersbach"
            },
            {
                "value": 40948,
                "label": "Haslach im Kinzigtal",
                "label_ar": "Haslach im Kinzigtal",
                "label_fr": "Haslach im Kinzigtal"
            },
            {
                "value": 40949,
                "label": "Schwaibach",
                "label_ar": "شويباخ",
                "label_fr": "Schwaibach"
            },
            {
                "value": 40950,
                "label": "Hausach",
                "label_ar": "هوساش",
                "label_fr": "Hausach"
            },
            {
                "value": 40951,
                "label": "Hesedorf",
                "label_ar": "هيسدورف",
                "label_fr": "Hesedorf"
            },
            {
                "value": 40952,
                "label": "Peitz",
                "label_ar": "بيتز",
                "label_fr": "Peitz"
            },
            {
                "value": 40953,
                "label": "Bad Liebenwerda",
                "label_ar": "باد ليبنويردا",
                "label_fr": "Bad Liebenwerda"
            },
            {
                "value": 40954,
                "label": "Munchweiler an der Alsenz",
                "label_ar": "Munchweiler an der Alsenz",
                "label_fr": "Munchweiler an der Alsenz"
            },
            {
                "value": 40955,
                "label": "Dietrichingen",
                "label_ar": "ديتريشينجين",
                "label_fr": "Dietrichingen"
            },
            {
                "value": 40956,
                "label": "Eppenbrunn",
                "label_ar": "إيبينبرون",
                "label_fr": "Eppenbrunn"
            },
            {
                "value": 40957,
                "label": "Rosenkopf",
                "label_ar": "روزنكوف",
                "label_fr": "Rosenkopf"
            },
            {
                "value": 40958,
                "label": "Rockenhausen",
                "label_ar": "روكينهاوزن",
                "label_fr": "Rockenhausen"
            },
            {
                "value": 40959,
                "label": "Krickenbach",
                "label_ar": "Krickenbach",
                "label_fr": "Krickenbach"
            },
            {
                "value": 40960,
                "label": "Walshausen",
                "label_ar": "Walshausen",
                "label_fr": "Walshausen"
            },
            {
                "value": 40961,
                "label": "Oberndorf",
                "label_ar": "اوبرندورف",
                "label_fr": "Oberndorf"
            },
            {
                "value": 40962,
                "label": "Alfstedt",
                "label_ar": "ألفستيدت",
                "label_fr": "Alfstedt"
            },
            {
                "value": 40963,
                "label": "Sontra",
                "label_ar": "سونترا",
                "label_fr": "Sontra"
            },
            {
                "value": 40964,
                "label": "Bahrenfleth",
                "label_ar": "باهرينفلث",
                "label_fr": "Bahrenfleth"
            },
            {
                "value": 40965,
                "label": "Neuenbrook",
                "label_ar": "نوينبروك",
                "label_fr": "Neuenbrook"
            },
            {
                "value": 40966,
                "label": "Steinburg",
                "label_ar": "شتاينبورغ",
                "label_fr": "Steinburg"
            },
            {
                "value": 40967,
                "label": "Neuwittenbek",
                "label_ar": "نيويتنبيك",
                "label_fr": "Neuwittenbek"
            },
            {
                "value": 40968,
                "label": "Strande",
                "label_ar": "ستراند",
                "label_fr": "Strande"
            },
            {
                "value": 40969,
                "label": "Danischenhagen",
                "label_ar": "Danischenhagen",
                "label_fr": "Danischenhagen"
            },
            {
                "value": 40970,
                "label": "Gleschendorf",
                "label_ar": "جليشندورف",
                "label_fr": "Gleschendorf"
            },
            {
                "value": 40971,
                "label": "Colnrade",
                "label_ar": "كولنريد",
                "label_fr": "Colnrade"
            },
            {
                "value": 40972,
                "label": "Cappeln",
                "label_ar": "كابيلن",
                "label_fr": "Cappeln"
            },
            {
                "value": 40973,
                "label": "Buchlberg",
                "label_ar": "بوشلبرغ",
                "label_fr": "Buchlberg"
            },
            {
                "value": 40974,
                "label": "Hebertsfelden",
                "label_ar": "Hebertsfelden",
                "label_fr": "Hebertsfelden"
            },
            {
                "value": 40975,
                "label": "Haarbach",
                "label_ar": "هارباخ",
                "label_fr": "Haarbach"
            },
            {
                "value": 40976,
                "label": "Roehrnbach",
                "label_ar": "روهرنباخ",
                "label_fr": "Roehrnbach"
            },
            {
                "value": 40977,
                "label": "Hamb",
                "label_ar": "هامب",
                "label_fr": "Hamb"
            },
            {
                "value": 40978,
                "label": "Gronau",
                "label_ar": "جروناو",
                "label_fr": "Gronau"
            },
            {
                "value": 40979,
                "label": "Bruggen",
                "label_ar": "بروجن",
                "label_fr": "Bruggen"
            },
            {
                "value": 40980,
                "label": "Haina",
                "label_ar": "هينا",
                "label_fr": "Haina"
            },
            {
                "value": 40981,
                "label": "Breitscheid",
                "label_ar": "بريتشيد",
                "label_fr": "Breitscheid"
            },
            {
                "value": 40982,
                "label": "Schonecken",
                "label_ar": "شونكن",
                "label_fr": "Schonecken"
            },
            {
                "value": 40983,
                "label": "Bitburg",
                "label_ar": "Bitburg",
                "label_fr": "Bitburg"
            },
            {
                "value": 40984,
                "label": "Kinheim",
                "label_ar": "كينهايم",
                "label_fr": "Kinheim"
            },
            {
                "value": 40985,
                "label": "Mehring",
                "label_ar": "ميرينج",
                "label_fr": "Mehring"
            },
            {
                "value": 40986,
                "label": "Neuhutten",
                "label_ar": "نويهوتن",
                "label_fr": "Neuhutten"
            },
            {
                "value": 40987,
                "label": "Wolsfeld",
                "label_ar": "وولسفيلد",
                "label_fr": "Wolsfeld"
            },
            {
                "value": 40988,
                "label": "Berglicht",
                "label_ar": "بيرجليشت",
                "label_fr": "Berglicht"
            },
            {
                "value": 40989,
                "label": "Binsfeld",
                "label_ar": "بينسفيلد",
                "label_fr": "Binsfeld"
            },
            {
                "value": 40990,
                "label": "Rittersdorf",
                "label_ar": "ريترسدورف",
                "label_fr": "Rittersdorf"
            },
            {
                "value": 40991,
                "label": "Murlenbach",
                "label_ar": "مورلينباخ",
                "label_fr": "Murlenbach"
            },
            {
                "value": 40992,
                "label": "Neuerburg",
                "label_ar": "نويربورغ",
                "label_fr": "Neuerburg"
            },
            {
                "value": 40993,
                "label": "Dettenhausen",
                "label_ar": "ديتينهاوزن",
                "label_fr": "Dettenhausen"
            },
            {
                "value": 40994,
                "label": "Drestedt",
                "label_ar": "دريستدت",
                "label_fr": "Drestedt"
            },
            {
                "value": 40995,
                "label": "Halsenbach",
                "label_ar": "هالسينباخ",
                "label_fr": "Halsenbach"
            },
            {
                "value": 40996,
                "label": "Welterod",
                "label_ar": "ويلتيرود",
                "label_fr": "Welterod"
            },
            {
                "value": 40997,
                "label": "Duchroth",
                "label_ar": "Duchroth",
                "label_fr": "Duchroth"
            },
            {
                "value": 40998,
                "label": "Beckedorf",
                "label_ar": "بيكيدورف",
                "label_fr": "Beckedorf"
            },
            {
                "value": 40999,
                "label": "Schellerten",
                "label_ar": "شيلرتن",
                "label_fr": "Schellerten"
            },
            {
                "value": 41000,
                "label": "Wollstein",
                "label_ar": "وولشتاين",
                "label_fr": "Wollstein"
            },
            {
                "value": 41001,
                "label": "Ober-Olm",
                "label_ar": "أوبر أولم",
                "label_fr": "Ober-Olm"
            },
            {
                "value": 41002,
                "label": "Bad Munster am Stein-Ebernburg",
                "label_ar": "باد مونستر آم شتاين إيبرنبورغ",
                "label_fr": "Bad Munster am Stein-Ebernburg"
            },
            {
                "value": 41003,
                "label": "Trollenhagen",
                "label_ar": "ترولنهاغن",
                "label_fr": "Trollenhagen"
            },
            {
                "value": 41004,
                "label": "Hilwartshausen",
                "label_ar": "هيلوارتسهاوزن",
                "label_fr": "Hilwartshausen"
            },
            {
                "value": 41005,
                "label": "Lachendorf",
                "label_ar": "لاكيندورف",
                "label_fr": "Lachendorf"
            },
            {
                "value": 41006,
                "label": "Bad Sooden-Allendorf",
                "label_ar": "باد سودين-أليندورف",
                "label_fr": "Bad Sooden-Allendorf"
            },
            {
                "value": 41007,
                "label": "Malterdingen",
                "label_ar": "مالتيردينجن",
                "label_fr": "Malterdingen"
            },
            {
                "value": 41008,
                "label": "Katzenmoos",
                "label_ar": "كاتزنموس",
                "label_fr": "Katzenmoos"
            },
            {
                "value": 41009,
                "label": "Oldersum",
                "label_ar": "أولدرسوم",
                "label_fr": "Oldersum"
            },
            {
                "value": 41010,
                "label": "Langeoog",
                "label_ar": "لانجيوج",
                "label_fr": "Langeoog"
            },
            {
                "value": 41011,
                "label": "Neuborger",
                "label_ar": "نيوبورجيه",
                "label_fr": "Neuborger"
            },
            {
                "value": 41012,
                "label": "Nieder-Olm",
                "label_ar": "نيدر أولم",
                "label_fr": "Nieder-Olm"
            },
            {
                "value": 41013,
                "label": "Bad Sachsa",
                "label_ar": "باد ساشسا",
                "label_fr": "Bad Sachsa"
            },
            {
                "value": 41014,
                "label": "Harste",
                "label_ar": "هارست",
                "label_fr": "Harste"
            },
            {
                "value": 41015,
                "label": "Wulften",
                "label_ar": "وولفتن",
                "label_fr": "Wulften"
            },
            {
                "value": 41016,
                "label": "Lottstetten",
                "label_ar": "لوتستيتن",
                "label_fr": "Lottstetten"
            },
            {
                "value": 41017,
                "label": "Brachbach",
                "label_ar": "براشباخ",
                "label_fr": "Brachbach"
            },
            {
                "value": 41018,
                "label": "Daaden",
                "label_ar": "دادن",
                "label_fr": "Daaden"
            },
            {
                "value": 41019,
                "label": "Dorstadt",
                "label_ar": "دورستادت",
                "label_fr": "Dorstadt"
            },
            {
                "value": 41020,
                "label": "Hoitlingen",
                "label_ar": "هويتلينجن",
                "label_fr": "Hoitlingen"
            },
            {
                "value": 41021,
                "label": "Brandis",
                "label_ar": "برانديز",
                "label_fr": "Brandis"
            },
            {
                "value": 41022,
                "label": "Borsdorf",
                "label_ar": "بورسدورف",
                "label_fr": "Borsdorf"
            },
            {
                "value": 41023,
                "label": "Roklum",
                "label_ar": "روكلوم",
                "label_fr": "Roklum"
            },
            {
                "value": 41024,
                "label": "Jelmstorf",
                "label_ar": "جيلمستورف",
                "label_fr": "Jelmstorf"
            },
            {
                "value": 41025,
                "label": "Suderburg",
                "label_ar": "سودربورغ",
                "label_fr": "Suderburg"
            },
            {
                "value": 41026,
                "label": "Leiferde",
                "label_ar": "ليفيردي",
                "label_fr": "Leiferde"
            },
            {
                "value": 41027,
                "label": "Vohringen",
                "label_ar": "Vohringen",
                "label_fr": "Vohringen"
            },
            {
                "value": 41028,
                "label": "Oggelshausen",
                "label_ar": "أوجلشاوسين",
                "label_fr": "Oggelshausen"
            },
            {
                "value": 41029,
                "label": "Reichenbach im Tale",
                "label_ar": "Reichenbach im Tale",
                "label_fr": "Reichenbach im Tale"
            },
            {
                "value": 41030,
                "label": "Aichstetten",
                "label_ar": "ايشستيتن",
                "label_fr": "Aichstetten"
            },
            {
                "value": 41031,
                "label": "Frickenhausen",
                "label_ar": "فريكنهاوزن",
                "label_fr": "Frickenhausen"
            },
            {
                "value": 41032,
                "label": "Sehlde",
                "label_ar": "Sehlde",
                "label_fr": "Sehlde"
            },
            {
                "value": 41033,
                "label": "Bieber",
                "label_ar": "بيبر",
                "label_fr": "Bieber"
            },
            {
                "value": 41034,
                "label": "Wiggensbach",
                "label_ar": "ويجنزباخ",
                "label_fr": "Wiggensbach"
            },
            {
                "value": 41035,
                "label": "Buxheim",
                "label_ar": "بوكشيم",
                "label_fr": "Buxheim"
            },
            {
                "value": 41036,
                "label": "Halblech",
                "label_ar": "هالبلتش",
                "label_fr": "Halblech"
            },
            {
                "value": 41037,
                "label": "Konigsbruck",
                "label_ar": "كونيجسبروك",
                "label_fr": "Konigsbruck"
            },
            {
                "value": 41038,
                "label": "Hohnstein",
                "label_ar": "هونشتاين",
                "label_fr": "Hohnstein"
            },
            {
                "value": 41039,
                "label": "Glashutte",
                "label_ar": "جلاشوتي",
                "label_fr": "Glashutte"
            },
            {
                "value": 41040,
                "label": "Diepenau",
                "label_ar": "ديبيناو",
                "label_fr": "Diepenau"
            },
            {
                "value": 41041,
                "label": "Mauer",
                "label_ar": "ماور",
                "label_fr": "Mauer"
            },
            {
                "value": 41042,
                "label": "Hardheim",
                "label_ar": "هارديم",
                "label_fr": "Hardheim"
            },
            {
                "value": 41043,
                "label": "Kulsheim",
                "label_ar": "كولشيم",
                "label_fr": "Kulsheim"
            },
            {
                "value": 41044,
                "label": "Hopsten",
                "label_ar": "هوبستن",
                "label_fr": "Hopsten"
            },
            {
                "value": 41045,
                "label": "Lembruch",
                "label_ar": "ليمبروخ",
                "label_fr": "Lembruch"
            },
            {
                "value": 41046,
                "label": "Windhagen",
                "label_ar": "وندهاجين",
                "label_fr": "Windhagen"
            },
            {
                "value": 41047,
                "label": "Braubach",
                "label_ar": "براوباتش",
                "label_fr": "Braubach"
            },
            {
                "value": 41048,
                "label": "Oberrod",
                "label_ar": "أوبيررود",
                "label_fr": "Oberrod"
            },
            {
                "value": 41049,
                "label": "Woldert",
                "label_ar": "وولرت",
                "label_fr": "Woldert"
            },
            {
                "value": 41050,
                "label": "Selbach",
                "label_ar": "سيلباخ",
                "label_fr": "Selbach"
            },
            {
                "value": 41051,
                "label": "Steimel",
                "label_ar": "Steimel",
                "label_fr": "Steimel"
            },
            {
                "value": 41052,
                "label": "Buchholz",
                "label_ar": "بوخولز",
                "label_fr": "Buchholz"
            },
            {
                "value": 41053,
                "label": "Steuden",
                "label_ar": "ستودين",
                "label_fr": "Steuden"
            },
            {
                "value": 41054,
                "label": "Britz",
                "label_ar": "بريتز",
                "label_fr": "Britz"
            },
            {
                "value": 41055,
                "label": "Twistringen",
                "label_ar": "تويسترينجن",
                "label_fr": "Twistringen"
            },
            {
                "value": 41056,
                "label": "Bexhovede",
                "label_ar": "بيكسوفيد",
                "label_fr": "Bexhovede"
            },
            {
                "value": 41057,
                "label": "Binzen",
                "label_ar": "بينزن",
                "label_fr": "Binzen"
            },
            {
                "value": 41058,
                "label": "Schweringen",
                "label_ar": "شفيرينجن",
                "label_fr": "Schweringen"
            },
            {
                "value": 41059,
                "label": "Hassel",
                "label_ar": "هاسل",
                "label_fr": "Hassel"
            },
            {
                "value": 41060,
                "label": "Schwarme",
                "label_ar": "شوارم",
                "label_fr": "Schwarme"
            },
            {
                "value": 41061,
                "label": "Stadtprozelten",
                "label_ar": "Stadtprozelten",
                "label_fr": "Stadtprozelten"
            },
            {
                "value": 41062,
                "label": "Weilbach",
                "label_ar": "ويلباخ",
                "label_fr": "Weilbach"
            },
            {
                "value": 41063,
                "label": "Sailauf",
                "label_ar": "سيلوف",
                "label_fr": "Sailauf"
            },
            {
                "value": 41064,
                "label": "Riedenberg",
                "label_ar": "ريدنبرج",
                "label_fr": "Riedenberg"
            },
            {
                "value": 41065,
                "label": "Kalbach",
                "label_ar": "كالباخ",
                "label_fr": "Kalbach"
            },
            {
                "value": 41066,
                "label": "Rothlein",
                "label_ar": "روثلين",
                "label_fr": "Rothlein"
            },
            {
                "value": 41067,
                "label": "Zwesten",
                "label_ar": "زويستين",
                "label_fr": "Zwesten"
            },
            {
                "value": 41068,
                "label": "Liebenau",
                "label_ar": "ليبيناو",
                "label_fr": "Liebenau"
            },
            {
                "value": 41069,
                "label": "Berkheim",
                "label_ar": "بيركهايم",
                "label_fr": "Berkheim"
            },
            {
                "value": 41070,
                "label": "Winterrieden",
                "label_ar": "وينترريدن",
                "label_fr": "Winterrieden"
            },
            {
                "value": 41071,
                "label": "Lauben",
                "label_ar": "لوبين",
                "label_fr": "Lauben"
            },
            {
                "value": 41072,
                "label": "Eichelgarten",
                "label_ar": "Eichelgarten",
                "label_fr": "Eichelgarten"
            },
            {
                "value": 41073,
                "label": "Waldeck",
                "label_ar": "فالديك",
                "label_fr": "Waldeck"
            },
            {
                "value": 41074,
                "label": "Herleshausen",
                "label_ar": "هيرليسهاوزن",
                "label_fr": "Herleshausen"
            },
            {
                "value": 41075,
                "label": "Cornberg",
                "label_ar": "كورنبرغ",
                "label_fr": "Cornberg"
            },
            {
                "value": 41076,
                "label": "Weissenbrunn",
                "label_ar": "فايسنبرون",
                "label_fr": "Weissenbrunn"
            },
            {
                "value": 41077,
                "label": "Ludwigsstadt",
                "label_ar": "لودفيغشتات",
                "label_fr": "Ludwigsstadt"
            },
            {
                "value": 41078,
                "label": "Stadtsteinach",
                "label_ar": "Stadtsteinach",
                "label_fr": "Stadtsteinach"
            },
            {
                "value": 41079,
                "label": "Eystrup",
                "label_ar": "إستراب",
                "label_fr": "Eystrup"
            },
            {
                "value": 41080,
                "label": "Martfeld",
                "label_ar": "مارتفيلد",
                "label_fr": "Martfeld"
            },
            {
                "value": 41081,
                "label": "Mudau",
                "label_ar": "موداو",
                "label_fr": "Mudau"
            },
            {
                "value": 41082,
                "label": "Schwarzach",
                "label_ar": "شوارزاتش",
                "label_fr": "Schwarzach"
            },
            {
                "value": 41083,
                "label": "Tessin",
                "label_ar": "تيسين",
                "label_fr": "Tessin"
            },
            {
                "value": 41084,
                "label": "Suderholz",
                "label_ar": "سودرهولز",
                "label_fr": "Suderholz"
            },
            {
                "value": 41085,
                "label": "Loddin",
                "label_ar": "لودين",
                "label_fr": "Loddin"
            },
            {
                "value": 41086,
                "label": "Hohen Schwarfs",
                "label_ar": "هوهن شوارفس",
                "label_fr": "Hohen Schwarfs"
            },
            {
                "value": 41087,
                "label": "Broderstorf",
                "label_ar": "برودرستورف",
                "label_fr": "Broderstorf"
            },
            {
                "value": 41088,
                "label": "Ducherow",
                "label_ar": "دوتشيرو",
                "label_fr": "Ducherow"
            },
            {
                "value": 41089,
                "label": "Horst",
                "label_ar": "هورست",
                "label_fr": "Horst"
            },
            {
                "value": 41090,
                "label": "Steinhagen",
                "label_ar": "شتاينهاجين",
                "label_fr": "Steinhagen"
            },
            {
                "value": 41091,
                "label": "Kritzmow",
                "label_ar": "كريتزمو",
                "label_fr": "Kritzmow"
            },
            {
                "value": 41092,
                "label": "Volkenshagen",
                "label_ar": "فولكينشاجين",
                "label_fr": "Volkenshagen"
            },
            {
                "value": 41093,
                "label": "Gribow",
                "label_ar": "غريبو",
                "label_fr": "Gribow"
            },
            {
                "value": 41094,
                "label": "Bad Doberan",
                "label_ar": "باد دوبران",
                "label_fr": "Bad Doberan"
            },
            {
                "value": 41095,
                "label": "Brandshagen",
                "label_ar": "براندشاجين",
                "label_fr": "Brandshagen"
            },
            {
                "value": 41096,
                "label": "Zempin",
                "label_ar": "زيمبين",
                "label_fr": "Zempin"
            },
            {
                "value": 41097,
                "label": "Dummerstorf",
                "label_ar": "دومرستورف",
                "label_fr": "Dummerstorf"
            },
            {
                "value": 41098,
                "label": "Kassow",
                "label_ar": "كاسو",
                "label_fr": "Kassow"
            },
            {
                "value": 41099,
                "label": "Kramerhof",
                "label_ar": "كرامرهوف",
                "label_fr": "Kramerhof"
            },
            {
                "value": 41100,
                "label": "Grammow",
                "label_ar": "غرامو",
                "label_fr": "Grammow"
            },
            {
                "value": 41101,
                "label": "Birkheim",
                "label_ar": "بيركهايم",
                "label_fr": "Birkheim"
            },
            {
                "value": 41102,
                "label": "Weinsheim",
                "label_ar": "وينشيم",
                "label_fr": "Weinsheim"
            },
            {
                "value": 41103,
                "label": "Bacharach",
                "label_ar": "باشاراش",
                "label_fr": "Bacharach"
            },
            {
                "value": 41104,
                "label": "Suckow",
                "label_ar": "مص",
                "label_fr": "Sucer"
            },
            {
                "value": 41105,
                "label": "Rehna",
                "label_ar": "رينا",
                "label_fr": "Rehna"
            },
            {
                "value": 41106,
                "label": "Crivitz",
                "label_ar": "كريفيتز",
                "label_fr": "Crivitz"
            },
            {
                "value": 41107,
                "label": "Banzkow",
                "label_ar": "بانزكو",
                "label_fr": "Banzkow"
            },
            {
                "value": 41108,
                "label": "Cambs",
                "label_ar": "كامبس",
                "label_fr": "Cambs"
            },
            {
                "value": 41109,
                "label": "Wardow",
                "label_ar": "وردو",
                "label_fr": "Wardow"
            },
            {
                "value": 41110,
                "label": "Zarrentin",
                "label_ar": "زارنتين",
                "label_fr": "Zarrentin"
            },
            {
                "value": 41111,
                "label": "Spornitz",
                "label_ar": "سبورنيتز",
                "label_fr": "Spornitz"
            },
            {
                "value": 41112,
                "label": "Mecklenburg",
                "label_ar": "مكلنبورغ",
                "label_fr": "Mecklembourg"
            },
            {
                "value": 41113,
                "label": "Gadebusch",
                "label_ar": "جاديبوش",
                "label_fr": "Gadebusch"
            },
            {
                "value": 41114,
                "label": "Laage",
                "label_ar": "لاج",
                "label_fr": "Laage"
            },
            {
                "value": 41115,
                "label": "Lubz",
                "label_ar": "لوبز",
                "label_fr": "Lubz"
            },
            {
                "value": 41116,
                "label": "Krakow am See",
                "label_ar": "كراكوف ام سي",
                "label_fr": "Cracovie am See"
            },
            {
                "value": 41117,
                "label": "Eldena",
                "label_ar": "إلدنا",
                "label_fr": "Eldena"
            },
            {
                "value": 41118,
                "label": "Neu Vitense",
                "label_ar": "نيو فيتنس",
                "label_fr": "Neu Vitense"
            },
            {
                "value": 41119,
                "label": "Camin",
                "label_ar": "كامين",
                "label_fr": "Camin"
            },
            {
                "value": 41120,
                "label": "Gross Bengerstorf",
                "label_ar": "جروس بينغيرستورف",
                "label_fr": "Gross Bengerstorf"
            },
            {
                "value": 41121,
                "label": "Niepars",
                "label_ar": "نيبارس",
                "label_fr": "Niepars"
            },
            {
                "value": 41122,
                "label": "Wittenberge",
                "label_ar": "ويتنبرج",
                "label_fr": "Wittenberge"
            },
            {
                "value": 41123,
                "label": "Brusewitz",
                "label_ar": "بروسويتز",
                "label_fr": "Brusewitz"
            },
            {
                "value": 41124,
                "label": "Leezen",
                "label_ar": "ليزين",
                "label_fr": "Leezen"
            },
            {
                "value": 41125,
                "label": "Rehhorst",
                "label_ar": "ريهورست",
                "label_fr": "Rehhorst"
            },
            {
                "value": 41126,
                "label": "Rettin",
                "label_ar": "ريتين",
                "label_fr": "Rettin"
            },
            {
                "value": 41127,
                "label": "Brunstorf",
                "label_ar": "برونستورف",
                "label_fr": "Brunstorf"
            },
            {
                "value": 41128,
                "label": "Huttblek",
                "label_ar": "Huttblek",
                "label_fr": "Huttblek"
            },
            {
                "value": 41129,
                "label": "Spangdahlem",
                "label_ar": "سبانجدالم",
                "label_fr": "Spangdahlem"
            },
            {
                "value": 41130,
                "label": "Bettingen",
                "label_ar": "بيتينغن",
                "label_fr": "Bettingen"
            },
            {
                "value": 41131,
                "label": "Landscheid",
                "label_ar": "لاندشايد",
                "label_fr": "Landscheid"
            },
            {
                "value": 41132,
                "label": "Thalfang",
                "label_ar": "تالفانج",
                "label_fr": "Thalfang"
            },
            {
                "value": 41133,
                "label": "Ebenweiler",
                "label_ar": "إبينويلر",
                "label_fr": "Ebenweiler"
            },
            {
                "value": 41134,
                "label": "Wolpertswende",
                "label_ar": "Wolpertswende",
                "label_fr": "Wolpertswende"
            },
            {
                "value": 41135,
                "label": "Todenbuttel",
                "label_ar": "تودينبوتيل",
                "label_fr": "Todenbuttel"
            },
            {
                "value": 41136,
                "label": "Bargenstedt",
                "label_ar": "بارجينستيدت",
                "label_fr": "Bargenstedt"
            },
            {
                "value": 41137,
                "label": "Altwittenbek",
                "label_ar": "التويتنبك",
                "label_fr": "Altwittenbek"
            },
            {
                "value": 41138,
                "label": "Hanerau-Hademarschen",
                "label_ar": "Hanerau-Hademarschen",
                "label_fr": "Hanerau-Hademarschen"
            },
            {
                "value": 41139,
                "label": "Warnau",
                "label_ar": "وارناو",
                "label_fr": "Warnau"
            },
            {
                "value": 41140,
                "label": "Sankt Peter-Ording",
                "label_ar": "سانكت بيتر أوردنج",
                "label_fr": "Sankt Peter-Ording"
            },
            {
                "value": 41141,
                "label": "Tetenbull",
                "label_ar": "Tetenbull",
                "label_fr": "Tetenbull"
            },
            {
                "value": 41142,
                "label": "Witzwort",
                "label_ar": "ويتزورت",
                "label_fr": "Witzwort"
            },
            {
                "value": 41143,
                "label": "Oldenswort",
                "label_ar": "أولدنسورت",
                "label_fr": "Oldenswort"
            },
            {
                "value": 41144,
                "label": "Ascheberg",
                "label_ar": "أشيبيرغ",
                "label_fr": "Ascheberg"
            },
            {
                "value": 41145,
                "label": "Quarnstedt",
                "label_ar": "Quarnstedt",
                "label_fr": "Quarnstedt"
            },
            {
                "value": 41146,
                "label": "Bornhoved",
                "label_ar": "ولد",
                "label_fr": "Bornhoved"
            },
            {
                "value": 41147,
                "label": "Kasseedorf",
                "label_ar": "كاسيدورف",
                "label_fr": "Kasseedorf"
            },
            {
                "value": 41148,
                "label": "Elsdorf-Westermuhlen",
                "label_ar": "Elsdorf-Westermuhlen",
                "label_fr": "Elsdorf-Westermuhlen"
            },
            {
                "value": 41149,
                "label": "Ascheffel",
                "label_ar": "أشيفيل",
                "label_fr": "Ascheffel"
            },
            {
                "value": 41150,
                "label": "Pellworm",
                "label_ar": "الدودة الحلزونية",
                "label_fr": "Pellworm"
            },
            {
                "value": 41151,
                "label": "Arlewatt",
                "label_ar": "أرلووات",
                "label_fr": "Arlewatt"
            },
            {
                "value": 41152,
                "label": "Bistensee",
                "label_ar": "بستنسي",
                "label_fr": "Bistensee"
            },
            {
                "value": 41153,
                "label": "Hochdonn",
                "label_ar": "هوشدون",
                "label_fr": "Hochdonn"
            },
            {
                "value": 41154,
                "label": "Brickeln",
                "label_ar": "بريكلن",
                "label_fr": "Brickeln"
            },
            {
                "value": 41155,
                "label": "Sarlhusen",
                "label_ar": "Sarlhusen",
                "label_fr": "Sarlhusen"
            },
            {
                "value": 41156,
                "label": "Schulldorf",
                "label_ar": "شولدورف",
                "label_fr": "Schulldorf"
            },
            {
                "value": 41157,
                "label": "Breitenburg",
                "label_ar": "بريتنبورغ",
                "label_fr": "Breitenburg"
            },
            {
                "value": 41158,
                "label": "Elisabeth-Sophien-Koog",
                "label_ar": "إليزابيث صوفين كوج",
                "label_fr": "Élisabeth-Sophien-Koog"
            },
            {
                "value": 41159,
                "label": "Schonwalde",
                "label_ar": "شونوالد",
                "label_fr": "Schonwalde"
            },
            {
                "value": 41160,
                "label": "Witzhave",
                "label_ar": "يتزهاف",
                "label_fr": "Witzhave"
            },
            {
                "value": 41161,
                "label": "Lichtenberg",
                "label_ar": "ليشتنبرغ",
                "label_fr": "Lichtenberg"
            },
            {
                "value": 41162,
                "label": "Warmensteinach",
                "label_ar": "Warmensteinach",
                "label_fr": "Warmensteinach"
            },
            {
                "value": 41163,
                "label": "Grafengehaig",
                "label_ar": "جرافينجهايج",
                "label_fr": "Grafengehaig"
            },
            {
                "value": 41164,
                "label": "Wittenburg",
                "label_ar": "فيتنبرغ",
                "label_fr": "Wittenburg"
            },
            {
                "value": 41165,
                "label": "Wenzendorf",
                "label_ar": "Wenzendorf",
                "label_fr": "Wenzendorf"
            },
            {
                "value": 41166,
                "label": "Vastorf",
                "label_ar": "فاستورف",
                "label_fr": "Vastorf"
            },
            {
                "value": 41167,
                "label": "Bad Schussenried",
                "label_ar": "باد شوسنريد",
                "label_fr": "Bad Schussenried"
            },
            {
                "value": 41168,
                "label": "Hosskirch",
                "label_ar": "Hosskirch",
                "label_fr": "Hosskirch"
            },
            {
                "value": 41169,
                "label": "Dieblich",
                "label_ar": "ديبليش",
                "label_fr": "Dieblich"
            },
            {
                "value": 41170,
                "label": "Asbach",
                "label_ar": "أسباخ",
                "label_fr": "Asbach"
            },
            {
                "value": 41171,
                "label": "Kotterichen",
                "label_ar": "Kotterichen",
                "label_fr": "Kotterichen"
            },
            {
                "value": 41172,
                "label": "Arzbach",
                "label_ar": "أرزباخ",
                "label_fr": "Arzbach"
            },
            {
                "value": 41173,
                "label": "Rennerod",
                "label_ar": "رينيرود",
                "label_fr": "Rennerod"
            },
            {
                "value": 41174,
                "label": "Flammersfeld",
                "label_ar": "فلاميرسفيلد",
                "label_fr": "Flammersfeld"
            },
            {
                "value": 41175,
                "label": "Kobern-Gondorf",
                "label_ar": "كوبرن جوندورف",
                "label_fr": "Kobern-Gondorf"
            },
            {
                "value": 41176,
                "label": "Niederzissen",
                "label_ar": "نيدرزيسن",
                "label_fr": "Niederzissen"
            },
            {
                "value": 41177,
                "label": "Assel",
                "label_ar": "أسيل",
                "label_fr": "Assel"
            },
            {
                "value": 41178,
                "label": "Helmste",
                "label_ar": "هيلمست",
                "label_fr": "Helmste"
            },
            {
                "value": 41179,
                "label": "Kennenburg",
                "label_ar": "كيننبورغ",
                "label_fr": "Kennenburg"
            },
            {
                "value": 41180,
                "label": "Korb",
                "label_ar": "كورب",
                "label_fr": "Korb"
            },
            {
                "value": 41181,
                "label": "Freiburg",
                "label_ar": "فرايبورغ",
                "label_fr": "Fribourg"
            },
            {
                "value": 41182,
                "label": "Brackel",
                "label_ar": "براكيل",
                "label_fr": "Brackel"
            },
            {
                "value": 41183,
                "label": "Stadelhofen",
                "label_ar": "ستادلهوفن",
                "label_fr": "Stadelhofen"
            },
            {
                "value": 41184,
                "label": "Wallenfels",
                "label_ar": "والينفيلس",
                "label_fr": "Wallenfels"
            },
            {
                "value": 41185,
                "label": "Wonsees",
                "label_ar": "ونسيز",
                "label_fr": "Wonsees"
            },
            {
                "value": 41186,
                "label": "Wagersrott",
                "label_ar": "Wagersrott",
                "label_fr": "Wagersrott"
            },
            {
                "value": 41187,
                "label": "Taarstedt",
                "label_ar": "تارستيدت",
                "label_fr": "Taarstedt"
            },
            {
                "value": 41188,
                "label": "Langstedt",
                "label_ar": "لانجستيدت",
                "label_fr": "Langstedt"
            },
            {
                "value": 41189,
                "label": "Hasselberg",
                "label_ar": "هاسيلبيرج",
                "label_fr": "Hasselberg"
            },
            {
                "value": 41190,
                "label": "Suderbrarup",
                "label_ar": "سودرباروب",
                "label_fr": "Suderbrarup"
            },
            {
                "value": 41191,
                "label": "Keitum",
                "label_ar": "كيتوم",
                "label_fr": "Keitum"
            },
            {
                "value": 41192,
                "label": "Borgsum",
                "label_ar": "برجسوم",
                "label_fr": "Borgsum"
            },
            {
                "value": 41193,
                "label": "Rantum",
                "label_ar": "رانتوم",
                "label_fr": "Rantum"
            },
            {
                "value": 41194,
                "label": "Stolk",
                "label_ar": "ستولك",
                "label_fr": "Stolk"
            },
            {
                "value": 41195,
                "label": "Boel",
                "label_ar": "بويل",
                "label_fr": "Boel"
            },
            {
                "value": 41196,
                "label": "Burgsinn",
                "label_ar": "بورغسين",
                "label_fr": "Burgsinn"
            },
            {
                "value": 41197,
                "label": "Mellrichstadt",
                "label_ar": "ميلريشتات",
                "label_fr": "Mellrichstadt"
            },
            {
                "value": 41198,
                "label": "Pettstadt",
                "label_ar": "بيتشتات",
                "label_fr": "Pettstadt"
            },
            {
                "value": 41199,
                "label": "Wipfeld",
                "label_ar": "ويبفيلد",
                "label_fr": "Wipfeld"
            },
            {
                "value": 41200,
                "label": "Grossbardorf",
                "label_ar": "جروسباردورف",
                "label_fr": "Grossbardorf"
            },
            {
                "value": 41201,
                "label": "Egenhausen",
                "label_ar": "إيجنهاوزن",
                "label_fr": "Egenhausen"
            },
            {
                "value": 41202,
                "label": "Burgwindheim",
                "label_ar": "بورغويندهايم",
                "label_fr": "Burgwindheim"
            },
            {
                "value": 41203,
                "label": "Heustreu",
                "label_ar": "هيوستريو",
                "label_fr": "Heustreu"
            },
            {
                "value": 41204,
                "label": "Bergrheinfeld",
                "label_ar": "بيرغرهينفيلد",
                "label_fr": "Bergrheinfeld"
            },
            {
                "value": 41205,
                "label": "Monchsroth",
                "label_ar": "Monchsroth",
                "label_fr": "Monchsroth"
            },
            {
                "value": 41206,
                "label": "Gillersdorf",
                "label_ar": "جيلرسدورف",
                "label_fr": "Gillersdorf"
            },
            {
                "value": 41207,
                "label": "Beinerstadt",
                "label_ar": "بينرشتات",
                "label_fr": "Beinerstadt"
            },
            {
                "value": 41208,
                "label": "Palling",
                "label_ar": "يرقص",
                "label_fr": "Palissage"
            },
            {
                "value": 41209,
                "label": "Kienberg",
                "label_ar": "كينبيرج",
                "label_fr": "Kienberg"
            },
            {
                "value": 41210,
                "label": "Bernau am Chiemsee",
                "label_ar": "Bernau am Chiemsee",
                "label_fr": "Bernau am Chiemsee"
            },
            {
                "value": 41211,
                "label": "Surheim",
                "label_ar": "سورهايم",
                "label_fr": "Surheim"
            },
            {
                "value": 41212,
                "label": "Halsbach",
                "label_ar": "هالسباخ",
                "label_fr": "Halsbach"
            },
            {
                "value": 41213,
                "label": "Ering",
                "label_ar": "قرع",
                "label_fr": "Ering"
            },
            {
                "value": 41214,
                "label": "Geratskirchen",
                "label_ar": "جيراتسكيرشن",
                "label_fr": "Geratskirchen"
            },
            {
                "value": 41215,
                "label": "Rimbach",
                "label_ar": "Rimbach",
                "label_fr": "Rimbach"
            },
            {
                "value": 41216,
                "label": "Wolfegg",
                "label_ar": "وولفيج",
                "label_fr": "Wolfegg"
            },
            {
                "value": 41217,
                "label": "Nersingen",
                "label_ar": "نيرسينجين",
                "label_fr": "Nersingen"
            },
            {
                "value": 41218,
                "label": "Uttenweiler",
                "label_ar": "Uttenweiler",
                "label_fr": "Uttenweiler"
            },
            {
                "value": 41219,
                "label": "Bachingen an der Brenz",
                "label_ar": "Bachingen an der Brenz",
                "label_fr": "Bachingen an der Brenz"
            },
            {
                "value": 41220,
                "label": "Bodenwohr",
                "label_ar": "بودنوهر",
                "label_fr": "Bodenwohr"
            },
            {
                "value": 41221,
                "label": "Kirchdorf im Wald",
                "label_ar": "كيرشدورف ايم والد",
                "label_fr": "Kirchdorf im Wald"
            },
            {
                "value": 41222,
                "label": "Schierling",
                "label_ar": "شيرلينج",
                "label_fr": "Schierling"
            },
            {
                "value": 41223,
                "label": "Nabburg",
                "label_ar": "ناببورغ",
                "label_fr": "Nabburg"
            },
            {
                "value": 41224,
                "label": "Bernhardswald",
                "label_ar": "بيرنهاردسفالد",
                "label_fr": "Bernhardswald"
            },
            {
                "value": 41225,
                "label": "Geisling",
                "label_ar": "جيزلينج",
                "label_fr": "Geisling"
            },
            {
                "value": 41226,
                "label": "Tegernheim",
                "label_ar": "تيغيرنهايم",
                "label_fr": "Tegernheim"
            },
            {
                "value": 41227,
                "label": "Riedenburg",
                "label_ar": "ريدنبورغ",
                "label_fr": "Riedenburg"
            },
            {
                "value": 41228,
                "label": "Wiesent",
                "label_ar": "ويسنت",
                "label_fr": "Wiesent"
            },
            {
                "value": 41229,
                "label": "Painten",
                "label_ar": "رسم",
                "label_fr": "Painten"
            },
            {
                "value": 41230,
                "label": "Metten",
                "label_ar": "ميتن",
                "label_fr": "Metten"
            },
            {
                "value": 41231,
                "label": "Wildenberg",
                "label_ar": "ويلدنبيرج",
                "label_fr": "Wildenberg"
            },
            {
                "value": 41232,
                "label": "Offenberg",
                "label_ar": "أوفنبيرج",
                "label_fr": "Offenberg"
            },
            {
                "value": 41233,
                "label": "Bayern",
                "label_ar": "بايرن",
                "label_fr": "Bayern"
            },
            {
                "value": 41234,
                "label": "Brenz",
                "label_ar": "برينز",
                "label_fr": "Brenz"
            },
            {
                "value": 41235,
                "label": "Altenriet",
                "label_ar": "التينريت",
                "label_fr": "Altenriet"
            },
            {
                "value": 41236,
                "label": "Blaibach",
                "label_ar": "Blaibach",
                "label_fr": "Blaibach"
            },
            {
                "value": 41237,
                "label": "Eschlkam",
                "label_ar": "إشلكام",
                "label_fr": "Eschlkam"
            },
            {
                "value": 41238,
                "label": "Rinchnach",
                "label_ar": "Rinchnach",
                "label_fr": "Rinchnach"
            },
            {
                "value": 41239,
                "label": "Hunderdorf",
                "label_ar": "هوندردورف",
                "label_fr": "Hunderdorf"
            },
            {
                "value": 41240,
                "label": "Bayerisch Eisenstein",
                "label_ar": "بايريش أيزنشتاين",
                "label_fr": "Bayerisch Eisenstein"
            },
            {
                "value": 41241,
                "label": "Gotteszell",
                "label_ar": "جوتزيل",
                "label_fr": "Gotteszell"
            },
            {
                "value": 41242,
                "label": "Plattling",
                "label_ar": "ثرثرة",
                "label_fr": "Plattling"
            },
            {
                "value": 41243,
                "label": "Leinburg",
                "label_ar": "لينبورغ",
                "label_fr": "Leinburg"
            },
            {
                "value": 41244,
                "label": "Oettersdorf",
                "label_ar": "Oettersdorf",
                "label_fr": "Oettersdorf"
            },
            {
                "value": 41245,
                "label": "Tengen",
                "label_ar": "تنجن",
                "label_fr": "Tengen"
            },
            {
                "value": 41246,
                "label": "Eschenbach",
                "label_ar": "إشنباخ",
                "label_fr": "Eschenbach"
            },
            {
                "value": 41247,
                "label": "Kohlberg",
                "label_ar": "كولبرج",
                "label_fr": "Kohlberg"
            },
            {
                "value": 41248,
                "label": "Teuschnitz",
                "label_ar": "Teuschnitz",
                "label_fr": "Teuschnitz"
            },
            {
                "value": 41249,
                "label": "Creussen",
                "label_ar": "كروسن",
                "label_fr": "Creussen"
            },
            {
                "value": 41250,
                "label": "Roslau",
                "label_ar": "روسلاو",
                "label_fr": "Roslau"
            },
            {
                "value": 41251,
                "label": "Regnitzlosau",
                "label_ar": "Regnitzlosau",
                "label_fr": "Regnitzlosau"
            },
            {
                "value": 41252,
                "label": "Rimbach",
                "label_ar": "Rimbach",
                "label_fr": "Rimbach"
            },
            {
                "value": 41253,
                "label": "Pfalzgrafenweiler",
                "label_ar": "بفالزغرافينويلر",
                "label_fr": "Pfalzgrafenweiler"
            },
            {
                "value": 41254,
                "label": "Hersbruck",
                "label_ar": "هيرسبروك",
                "label_fr": "Hersbruck"
            },
            {
                "value": 41255,
                "label": "Hallerndorf",
                "label_ar": "هاليرندورف",
                "label_fr": "Hallerndorf"
            },
            {
                "value": 41256,
                "label": "Durrbrunn",
                "label_ar": "دوربرون",
                "label_fr": "Durrbrunn"
            },
            {
                "value": 41257,
                "label": "Grafenberg",
                "label_ar": "جرافينبيرج",
                "label_fr": "Grafenberg"
            },
            {
                "value": 41258,
                "label": "Hemhofen",
                "label_ar": "هيمهوفن",
                "label_fr": "Hemhofen"
            },
            {
                "value": 41259,
                "label": "Rottenbach",
                "label_ar": "روتنباخ",
                "label_fr": "Rottenbach"
            },
            {
                "value": 41260,
                "label": "Kirchehrenbach",
                "label_ar": "كيرتشيرنباخ",
                "label_fr": "Kirchehrenbach"
            },
            {
                "value": 41261,
                "label": "Finsing",
                "label_ar": "تقطيع",
                "label_fr": "Finition"
            },
            {
                "value": 41262,
                "label": "Warngau",
                "label_ar": "وارنجو",
                "label_fr": "Warngau"
            },
            {
                "value": 41263,
                "label": "Waakirchen",
                "label_ar": "واكيرشن",
                "label_fr": "Waakirchen"
            },
            {
                "value": 41264,
                "label": "Seeshaupt",
                "label_ar": "سيشوبت",
                "label_fr": "Seeshaupt"
            },
            {
                "value": 41265,
                "label": "Otterfing",
                "label_ar": "Otterfing",
                "label_fr": "Otterfing"
            },
            {
                "value": 41266,
                "label": "Kochel",
                "label_ar": "كوتشيل",
                "label_fr": "Kochel"
            },
            {
                "value": 41267,
                "label": "Kipfenberg",
                "label_ar": "كيبفينبيرج",
                "label_fr": "Kipfenberg"
            },
            {
                "value": 41268,
                "label": "Diepersdorf",
                "label_ar": "ديبيرسدورف",
                "label_fr": "Diepersdorf"
            },
            {
                "value": 41269,
                "label": "Muggendorf",
                "label_ar": "موجندورف",
                "label_fr": "Muggendorf"
            },
            {
                "value": 41270,
                "label": "Spardorf",
                "label_ar": "سباردورف",
                "label_fr": "Spardorf"
            },
            {
                "value": 41271,
                "label": "Greding",
                "label_ar": "ينزف",
                "label_fr": "Growing"
            },
            {
                "value": 41272,
                "label": "Leutenbach",
                "label_ar": "ليوتنباخ",
                "label_fr": "Leutenbach"
            },
            {
                "value": 41273,
                "label": "Buckenhof",
                "label_ar": "باكنهوف",
                "label_fr": "Buckenhof"
            },
            {
                "value": 41274,
                "label": "Ottensoos",
                "label_ar": "أوتنسوس",
                "label_fr": "Ottensoos"
            },
            {
                "value": 41275,
                "label": "Grossenseebach",
                "label_ar": "جروسينسيباخ",
                "label_fr": "Grossenseebach"
            },
            {
                "value": 41276,
                "label": "Rechtenbach",
                "label_ar": "ريختنباخ",
                "label_fr": "Rechtenbach"
            },
            {
                "value": 41277,
                "label": "Rothenbuch",
                "label_ar": "روثنبوخ",
                "label_fr": "Rothenbuch"
            },
            {
                "value": 41278,
                "label": "Hollstadt",
                "label_ar": "هولستادت",
                "label_fr": "Hollstadt"
            },
            {
                "value": 41279,
                "label": "Hergolshausen",
                "label_ar": "هيرغولسهاوزن",
                "label_fr": "Hergolshausen"
            },
            {
                "value": 41280,
                "label": "Bischofsheim an der Rhon",
                "label_ar": "Bischofsheim ان دير رون",
                "label_fr": "Bischofsheim an der Rhon"
            },
            {
                "value": 41281,
                "label": "Eussenheim",
                "label_ar": "يوسنهايم",
                "label_fr": "Eussenheim"
            },
            {
                "value": 41282,
                "label": "Stockheim",
                "label_ar": "ستوكهايم",
                "label_fr": "Stockheim"
            },
            {
                "value": 41283,
                "label": "Oberelsbach",
                "label_ar": "Oberelsbach",
                "label_fr": "Oberelsbach"
            },
            {
                "value": 41284,
                "label": "Schillingsfurst",
                "label_ar": "شيلينجفورست",
                "label_fr": "Schillingsfurst"
            },
            {
                "value": 41285,
                "label": "Neuhof",
                "label_ar": "نيوهوف",
                "label_fr": "Neuhof"
            },
            {
                "value": 41286,
                "label": "Sonnefeld",
                "label_ar": "سونيفيلد",
                "label_fr": "Sonnefeld"
            },
            {
                "value": 41287,
                "label": "Neuhof",
                "label_ar": "نيوهوف",
                "label_fr": "Neuhof"
            },
            {
                "value": 41288,
                "label": "Gundelsheim",
                "label_ar": "جونديلشيم",
                "label_fr": "Gundelsheim"
            },
            {
                "value": 41289,
                "label": "Adelschlag",
                "label_ar": "Adelschlag",
                "label_fr": "Adelschlag"
            },
            {
                "value": 41290,
                "label": "Markt Erlbach",
                "label_ar": "ماركت إرلباخ",
                "label_fr": "Markt Erlbach"
            },
            {
                "value": 41291,
                "label": "Adelshofen",
                "label_ar": "Adelshofen",
                "label_fr": "Adelshofen"
            },
            {
                "value": 41292,
                "label": "Sesslach",
                "label_ar": "سيسلاش",
                "label_fr": "Sesslach"
            },
            {
                "value": 41293,
                "label": "Ahorn",
                "label_ar": "قرن",
                "label_fr": "Une corne"
            },
            {
                "value": 41294,
                "label": "Bellershausen",
                "label_ar": "بيلرسهاوزن",
                "label_fr": "Bellershausen"
            },
            {
                "value": 41295,
                "label": "Dollnstein",
                "label_ar": "دولنشتاين",
                "label_fr": "Dollnstein"
            },
            {
                "value": 41296,
                "label": "Wicklesgreuth",
                "label_ar": "ويكلسجروث",
                "label_fr": "Wicklesgreuth"
            },
            {
                "value": 41297,
                "label": "Langenbach",
                "label_ar": "لانجينباخ",
                "label_fr": "Langenbach"
            },
            {
                "value": 41298,
                "label": "Lenting",
                "label_ar": "الصوم",
                "label_fr": "Carême"
            },
            {
                "value": 41299,
                "label": "Essenbach",
                "label_ar": "إيسينباخ",
                "label_fr": "Essenbach"
            },
            {
                "value": 41300,
                "label": "Riemerling",
                "label_ar": "ريمرلينغ",
                "label_fr": "Riemerling"
            },
            {
                "value": 41301,
                "label": "Nassenfels",
                "label_ar": "ناسينفيلس",
                "label_fr": "Nassenfels"
            },
            {
                "value": 41302,
                "label": "Herrieden",
                "label_ar": "هيريدين",
                "label_fr": "Herrieden"
            },
            {
                "value": 41303,
                "label": "Fatschenbrunn",
                "label_ar": "فاتشينبرون",
                "label_fr": "Fatschenbrunn"
            },
            {
                "value": 41304,
                "label": "Lichtenau",
                "label_ar": "ليشتناو",
                "label_fr": "Lichtenau"
            },
            {
                "value": 41305,
                "label": "Lichtenau",
                "label_ar": "ليشتناو",
                "label_fr": "Lichtenau"
            },
            {
                "value": 41306,
                "label": "Wilburgstetten",
                "label_ar": "ويلبورغستيتين",
                "label_fr": "Wilburgstetten"
            },
            {
                "value": 41307,
                "label": "Pleinfeld",
                "label_ar": "بلينفيلد",
                "label_fr": "Pleinfeld"
            },
            {
                "value": 41308,
                "label": "Weitramsdorf",
                "label_ar": "ويترامسدورف",
                "label_fr": "Weitramsdorf"
            },
            {
                "value": 41309,
                "label": "Feuchtwangen",
                "label_ar": "Feuchtwangen",
                "label_fr": "Feuchtwangen"
            },
            {
                "value": 41310,
                "label": "Leibelbach",
                "label_ar": "ليبلباخ",
                "label_fr": "Leibelbach"
            },
            {
                "value": 41311,
                "label": "Gerolfingen",
                "label_ar": "جيرولفينغن",
                "label_fr": "Gerolfingen"
            },
            {
                "value": 41312,
                "label": "Ehingen",
                "label_ar": "اينجين",
                "label_fr": "Ehingen"
            },
            {
                "value": 41313,
                "label": "Deining",
                "label_ar": "التهام",
                "label_fr": "Déminage"
            },
            {
                "value": 41314,
                "label": "Allersberg",
                "label_ar": "أليرسبرج",
                "label_fr": "Allersberg"
            },
            {
                "value": 41315,
                "label": "Heimbuchenthal",
                "label_ar": "هيمبوشنثال",
                "label_fr": "Heimbuchenthal"
            },
            {
                "value": 41316,
                "label": "Gaukonigshofen",
                "label_ar": "Gaukonigshofen",
                "label_fr": "Gaukonigshofen"
            },
            {
                "value": 41317,
                "label": "Wiesthal",
                "label_ar": "Wiesthal",
                "label_fr": "Wiesthal"
            },
            {
                "value": 41318,
                "label": "Poppenhausen",
                "label_ar": "بوبنهاوزن",
                "label_fr": "Poppenhausen"
            },
            {
                "value": 41319,
                "label": "Unterpleichfeld",
                "label_ar": "Unterpleichfeld",
                "label_fr": "Unterpleichfeld"
            },
            {
                "value": 41320,
                "label": "Gemünden am Main",
                "label_ar": "Gemünden am Main",
                "label_fr": "Gemünden am Main"
            },
            {
                "value": 41321,
                "label": "Frensdorf",
                "label_ar": "فرينسدورف",
                "label_fr": "Frensdorf"
            },
            {
                "value": 41322,
                "label": "Flachslanden",
                "label_ar": "فلاتشلاندن",
                "label_fr": "Flachslanden"
            },
            {
                "value": 41323,
                "label": "Kammerstein",
                "label_ar": "كامرشتاين",
                "label_fr": "Kammerstein"
            },
            {
                "value": 41324,
                "label": "Kemmern",
                "label_ar": "كيميرن",
                "label_fr": "Kemmern"
            },
            {
                "value": 41325,
                "label": "Obermichelbach",
                "label_ar": "Obermichelbach",
                "label_fr": "Obermichelbach"
            },
            {
                "value": 41326,
                "label": "Retzstadt",
                "label_ar": "ريتزشتات",
                "label_fr": "Retzstadt"
            },
            {
                "value": 41327,
                "label": "Rottingen",
                "label_ar": "روتنغن",
                "label_fr": "Rottingen"
            },
            {
                "value": 41328,
                "label": "Butthard",
                "label_ar": "بوتارد",
                "label_fr": "Butthard"
            },
            {
                "value": 41329,
                "label": "Schonungen",
                "label_ar": "شونجن",
                "label_fr": "Schonungen"
            },
            {
                "value": 41330,
                "label": "Schollbrunn",
                "label_ar": "شولبرون",
                "label_fr": "Schollbrunn"
            },
            {
                "value": 41331,
                "label": "Umpfenbach",
                "label_ar": "أمبفنباخ",
                "label_fr": "Umpfenbach"
            },
            {
                "value": 41332,
                "label": "Bad Bocklet",
                "label_ar": "سيئة بوكليت",
                "label_fr": "Bad Bocklet"
            },
            {
                "value": 41333,
                "label": "Schondra",
                "label_ar": "شوندرا",
                "label_fr": "Schondra"
            },
            {
                "value": 41334,
                "label": "Hafenlohr",
                "label_ar": "هافنلوهر",
                "label_fr": "Hafenlohr"
            },
            {
                "value": 41335,
                "label": "Seligenstadt",
                "label_ar": "سيليجنشتات",
                "label_fr": "Seligenstadt"
            },
            {
                "value": 41336,
                "label": "Mainstockheim",
                "label_ar": "ماينستوكهايم",
                "label_fr": "Mainstockheim"
            },
            {
                "value": 41337,
                "label": "Riedmoos",
                "label_ar": "ريدموس",
                "label_fr": "Riedmoos"
            },
            {
                "value": 41338,
                "label": "Tuntenhausen",
                "label_ar": "تونتنهاوزن",
                "label_fr": "Tuntenhausen"
            },
            {
                "value": 41339,
                "label": "Emmering",
                "label_ar": "إمرينغ",
                "label_fr": "Emmering"
            },
            {
                "value": 41340,
                "label": "Weyarn",
                "label_ar": "ويارن",
                "label_fr": "Weyarn"
            },
            {
                "value": 41341,
                "label": "Inning am Ammersee",
                "label_ar": "إنينغ صباحا أميرسي",
                "label_fr": "Inning am Ammersee"
            },
            {
                "value": 41342,
                "label": "Uffing",
                "label_ar": "يصرخ",
                "label_fr": "Uffing"
            },
            {
                "value": 41343,
                "label": "Valley",
                "label_ar": "الوادي",
                "label_fr": "Vallée"
            },
            {
                "value": 41344,
                "label": "Rott am Inn",
                "label_ar": "روت آم إن",
                "label_fr": "Rott am Inn"
            },
            {
                "value": 41345,
                "label": "Bad Wiessee",
                "label_ar": "سيئة Wiessee",
                "label_fr": "Bad Wiessee"
            },
            {
                "value": 41346,
                "label": "Utting",
                "label_ar": "يوتينغ",
                "label_fr": "Utting"
            },
            {
                "value": 41347,
                "label": "Groitzsch",
                "label_ar": "جروتزش",
                "label_fr": "Groitzsch"
            },
            {
                "value": 41348,
                "label": "Husby",
                "label_ar": "حسبي",
                "label_fr": "Husby"
            },
            {
                "value": 41349,
                "label": "Wyk auf Fohr",
                "label_ar": "ويك عوف فور",
                "label_fr": "Wyk auf Fohr"
            },
            {
                "value": 41350,
                "label": "Grafrath",
                "label_ar": "جرافراث",
                "label_fr": "Grafrath"
            },
            {
                "value": 41351,
                "label": "Kolbermoor",
                "label_ar": "كولبرمور",
                "label_fr": "Kolbermoor"
            },
            {
                "value": 41352,
                "label": "Kuhbach",
                "label_ar": "كوهباخ",
                "label_fr": "Kuhbach"
            },
            {
                "value": 41353,
                "label": "Iffeldorf",
                "label_ar": "ايفلدورف",
                "label_fr": "Iffeldorf"
            },
            {
                "value": 41354,
                "label": "Oberammergau",
                "label_ar": "أوبراميرغاو",
                "label_fr": "Oberammergau"
            },
            {
                "value": 41355,
                "label": "Eydelstedt",
                "label_ar": "إيدلشتيدت",
                "label_fr": "Eydelstedt"
            },
            {
                "value": 41356,
                "label": "Barnstorf",
                "label_ar": "بارنستورف",
                "label_fr": "Barnstorf"
            },
            {
                "value": 41357,
                "label": "Bippen",
                "label_ar": "بيبين",
                "label_fr": "Bippen"
            },
            {
                "value": 41358,
                "label": "Badbergen",
                "label_ar": "بادبيرجين",
                "label_fr": "Badbergen"
            },
            {
                "value": 41359,
                "label": "Berge",
                "label_ar": "بيرج",
                "label_fr": "Berge"
            },
            {
                "value": 41360,
                "label": "Pentenried",
                "label_ar": "بنتنريد",
                "label_fr": "Pentenried"
            },
            {
                "value": 41361,
                "label": "Wildenfels",
                "label_ar": "ويلدينفيلس",
                "label_fr": "Wildenfels"
            },
            {
                "value": 41362,
                "label": "Oberdolling",
                "label_ar": "Oberdolling",
                "label_fr": "Oberdolling"
            },
            {
                "value": 41363,
                "label": "Rohrbach",
                "label_ar": "روهرباخ",
                "label_fr": "Rohrbach"
            },
            {
                "value": 41364,
                "label": "Gammelsdorf",
                "label_ar": "جاميلسدورف",
                "label_fr": "Gammelsdorf"
            },
            {
                "value": 41365,
                "label": "Feldafing",
                "label_ar": "فيلدافينج",
                "label_fr": "Feldafing"
            },
            {
                "value": 41366,
                "label": "Albaching",
                "label_ar": "الباتشينغ",
                "label_fr": "Albaching"
            },
            {
                "value": 41367,
                "label": "Wolnzach",
                "label_ar": "Wolnzach",
                "label_fr": "Wolnzach"
            },
            {
                "value": 41368,
                "label": "Neukeferloh",
                "label_ar": "نيوكيفرلوه",
                "label_fr": "Neukeferloh"
            },
            {
                "value": 41369,
                "label": "Rieden",
                "label_ar": "ريدن",
                "label_fr": "Rieden"
            },
            {
                "value": 41370,
                "label": "Batzhausen",
                "label_ar": "باتزهاوزن",
                "label_fr": "Batzhausen"
            },
            {
                "value": 41371,
                "label": "Aholming",
                "label_ar": "اهولمينغ",
                "label_fr": "Aholming"
            },
            {
                "value": 41372,
                "label": "Parkstetten",
                "label_ar": "باركستيتين",
                "label_fr": "Parkstetten"
            },
            {
                "value": 41373,
                "label": "Schmidmuhlen",
                "label_ar": "شميدموهلين",
                "label_fr": "Schmidmuhlen"
            },
            {
                "value": 41374,
                "label": "Laaber",
                "label_ar": "لابير",
                "label_fr": "Laaber"
            },
            {
                "value": 41375,
                "label": "Moosburg",
                "label_ar": "موسبرغ",
                "label_fr": "Moosburg"
            },
            {
                "value": 41376,
                "label": "Wang",
                "label_ar": "وانغ",
                "label_fr": "Wang"
            },
            {
                "value": 41377,
                "label": "Lutau",
                "label_ar": "لوتاو",
                "label_fr": "Lutau"
            },
            {
                "value": 41378,
                "label": "Grossbeeren",
                "label_ar": "جروسبيرن",
                "label_fr": "Grossbeeren"
            },
            {
                "value": 41379,
                "label": "Pfaffenhofen an der Ilm",
                "label_ar": "Pfaffenhofen an der Ilm",
                "label_fr": "Pfaffenhofen an der Ilm"
            },
            {
                "value": 41380,
                "label": "Munsing",
                "label_ar": "مونسينغ",
                "label_fr": "Munsing"
            },
            {
                "value": 41381,
                "label": "Moosach",
                "label_ar": "موساش",
                "label_fr": "Moosach"
            },
            {
                "value": 41382,
                "label": "Hohenwart",
                "label_ar": "هوهينوارت",
                "label_fr": "Hohenwart"
            },
            {
                "value": 41383,
                "label": "Neuburg an der Donau",
                "label_ar": "نيوبورغ أن دير دوناو",
                "label_fr": "Neuburg an der Donau"
            },
            {
                "value": 41384,
                "label": "Kottgeisering",
                "label_ar": "Kottgeisering",
                "label_fr": "Kottgeisering"
            },
            {
                "value": 41385,
                "label": "Attenkirchen",
                "label_ar": "أتنكيرشن",
                "label_fr": "Attenkirchen"
            },
            {
                "value": 41386,
                "label": "Sulzemoos",
                "label_ar": "سولزيموس",
                "label_fr": "Sulzemoos"
            },
            {
                "value": 41387,
                "label": "Langenpreising",
                "label_ar": "لانجينبريسينج",
                "label_fr": "Langenpreising"
            },
            {
                "value": 41388,
                "label": "Rachelsbach",
                "label_ar": "راشيلسباخ",
                "label_fr": "Rachelsbach"
            },
            {
                "value": 41389,
                "label": "Zuchering",
                "label_ar": "زوشرينغ",
                "label_fr": "Zuchering"
            },
            {
                "value": 41390,
                "label": "Zolling",
                "label_ar": "زولينج",
                "label_fr": "Zolling"
            },
            {
                "value": 41391,
                "label": "Tuerkenfeld",
                "label_ar": "توركينفيلد",
                "label_fr": "Tuerkenfeld"
            },
            {
                "value": 41392,
                "label": "Walpertskirchen",
                "label_ar": "Walpertskirchen",
                "label_fr": "Walpertskirchen"
            },
            {
                "value": 41393,
                "label": "Allendorf",
                "label_ar": "أليندورف",
                "label_fr": "Allendorf"
            },
            {
                "value": 41394,
                "label": "Wieseck",
                "label_ar": "فييسك",
                "label_fr": "Wieseck"
            },
            {
                "value": 41395,
                "label": "Gutmadingen",
                "label_ar": "Gutmadingen",
                "label_fr": "Gutmadingen"
            },
            {
                "value": 41396,
                "label": "Stuhlingen",
                "label_ar": "ستوهلينجن",
                "label_fr": "Stuhlingen"
            },
            {
                "value": 41397,
                "label": "Niederstaufenbach",
                "label_ar": "نيدرشتاوفنباخ",
                "label_fr": "Niederstaufenbach"
            },
            {
                "value": 41398,
                "label": "Kusel",
                "label_ar": "كوسل",
                "label_fr": "Kusel"
            },
            {
                "value": 41399,
                "label": "Trippstadt",
                "label_ar": "تريبشتات",
                "label_fr": "Trippstadt"
            },
            {
                "value": 41400,
                "label": "Rimschweiler",
                "label_ar": "ريمشويلر",
                "label_fr": "Rimschweiler"
            },
            {
                "value": 41401,
                "label": "Haschbach an der Glan",
                "label_ar": "هاشباخ أن دير جلان",
                "label_fr": "Haschbach an der Glan"
            },
            {
                "value": 41402,
                "label": "Hochspeyer",
                "label_ar": "هوشسبير",
                "label_fr": "Hochspeyer"
            },
            {
                "value": 41403,
                "label": "Bundenthal",
                "label_ar": "بندنتال",
                "label_fr": "Bundenthal"
            },
            {
                "value": 41404,
                "label": "Stelzenberg",
                "label_ar": "Stelzenberg",
                "label_fr": "Stelzenberg"
            },
            {
                "value": 41405,
                "label": "Otterberg",
                "label_ar": "اوتربيرج",
                "label_fr": "Otterberg"
            },
            {
                "value": 41406,
                "label": "Nanzweiler",
                "label_ar": "نانزويلر",
                "label_fr": "Nanzweiler"
            },
            {
                "value": 41407,
                "label": "Sembach",
                "label_ar": "سيمباش",
                "label_fr": "Sembach"
            },
            {
                "value": 41408,
                "label": "Allmannshofen",
                "label_ar": "ألمانشوفين",
                "label_fr": "Allmannshofen"
            },
            {
                "value": 41409,
                "label": "Genderkingen",
                "label_ar": "جندركينجين",
                "label_fr": "Genderkingen"
            },
            {
                "value": 41410,
                "label": "Leitershofen",
                "label_ar": "ليترشوفن",
                "label_fr": "Leitershofen"
            },
            {
                "value": 41411,
                "label": "Graben",
                "label_ar": "جرابين",
                "label_fr": "Graben"
            },
            {
                "value": 41412,
                "label": "Villenbach",
                "label_ar": "فيلينباخ",
                "label_fr": "Villenbach"
            },
            {
                "value": 41413,
                "label": "Fremdingen",
                "label_ar": "فريمدينغن",
                "label_fr": "Fremdingen"
            },
            {
                "value": 41414,
                "label": "Schwaben",
                "label_ar": "شوابين",
                "label_fr": "Schwaben"
            },
            {
                "value": 41415,
                "label": "Stadtbergen",
                "label_ar": "Stadtbergen",
                "label_fr": "Stadtbergen"
            },
            {
                "value": 41416,
                "label": "Monchsdeggingen",
                "label_ar": "Monchsdeggingen",
                "label_fr": "Monchsdeggingen"
            },
            {
                "value": 41417,
                "label": "Mickhausen",
                "label_ar": "ميكهاوزن",
                "label_fr": "Mickhausen"
            },
            {
                "value": 41418,
                "label": "Dielingen",
                "label_ar": "ديلينجن",
                "label_fr": "Dielingen"
            },
            {
                "value": 41419,
                "label": "Mahlberg",
                "label_ar": "ماهلبيرج",
                "label_fr": "Mahlberg"
            },
            {
                "value": 41420,
                "label": "Rugland",
                "label_ar": "روجلاند",
                "label_fr": "Rugland"
            },
            {
                "value": 41421,
                "label": "Ehingen",
                "label_ar": "اينجين",
                "label_fr": "Ehingen"
            },
            {
                "value": 41422,
                "label": "Wallerstein",
                "label_ar": "والرستين",
                "label_fr": "Wallerstein"
            },
            {
                "value": 41423,
                "label": "Bachhagel",
                "label_ar": "باخهاجل",
                "label_fr": "Bachhagel"
            },
            {
                "value": 41424,
                "label": "Zusamaltheim",
                "label_ar": "Zusamaltheim",
                "label_fr": "Zusamaltheim"
            },
            {
                "value": 41425,
                "label": "Schiltberg",
                "label_ar": "شيلتبرج",
                "label_fr": "Schiltberg"
            },
            {
                "value": 41426,
                "label": "Oettingen in Bayern",
                "label_ar": "اوتينجن في بايرن ميونيخ",
                "label_fr": "Oettingen dans le Bayern"
            },
            {
                "value": 41427,
                "label": "Mechelgrun",
                "label_ar": "ميشيلجرون",
                "label_fr": "Malines"
            },
            {
                "value": 41428,
                "label": "Beierfeld",
                "label_ar": "بييرفيلد",
                "label_fr": "Beierfeld"
            },
            {
                "value": 41429,
                "label": "Lossnitz",
                "label_ar": "لوسنيتز",
                "label_fr": "Lossnitz"
            },
            {
                "value": 41430,
                "label": "Markneukirchen",
                "label_ar": "ماركنيوكيرشن",
                "label_fr": "Markneukirchen"
            },
            {
                "value": 41431,
                "label": "Breitenbrunn",
                "label_ar": "بريتنبرون",
                "label_fr": "Breitenbrunn"
            },
            {
                "value": 41432,
                "label": "Herfatz",
                "label_ar": "هيرفاتز",
                "label_fr": "Herfatz"
            },
            {
                "value": 41433,
                "label": "Opfenbach",
                "label_ar": "أوبفينباخ",
                "label_fr": "Opfenbach"
            },
            {
                "value": 41434,
                "label": "Betzigau",
                "label_ar": "بيتزيغاو",
                "label_fr": "Betzigau"
            },
            {
                "value": 41435,
                "label": "Rettenberg",
                "label_ar": "ريتنبرغ",
                "label_fr": "Rettenberg"
            },
            {
                "value": 41436,
                "label": "Schwangau",
                "label_ar": "شفانغو",
                "label_fr": "Schwangau"
            },
            {
                "value": 41437,
                "label": "Untrasried",
                "label_ar": "غير مجرب",
                "label_fr": "Sans échographie"
            },
            {
                "value": 41438,
                "label": "Eisenberg",
                "label_ar": "أيزنبرغ",
                "label_fr": "Eisenberg"
            },
            {
                "value": 41439,
                "label": "Bad Hindelang",
                "label_ar": "Bad Hindelang",
                "label_fr": "Bad Hindelang"
            },
            {
                "value": 41440,
                "label": "Herzlake",
                "label_ar": "هرتسليك",
                "label_fr": "Herzlake"
            },
            {
                "value": 41441,
                "label": "Lunne",
                "label_ar": "لون",
                "label_fr": "Lunne"
            },
            {
                "value": 41442,
                "label": "Hohenstein",
                "label_ar": "هوهنشتاين",
                "label_fr": "Hohenstein"
            },
            {
                "value": 41443,
                "label": "Heiningen",
                "label_ar": "هينينجين",
                "label_fr": "Heiningen"
            },
            {
                "value": 41444,
                "label": "Baienfurt",
                "label_ar": "باينفورت",
                "label_fr": "Baienfurt"
            },
            {
                "value": 41445,
                "label": "Bunde",
                "label_ar": "بوندي",
                "label_fr": "Bunde"
            },
            {
                "value": 41446,
                "label": "Konigseggwald",
                "label_ar": "كونيجسيجوالد",
                "label_fr": "Konigseggwald"
            },
            {
                "value": 41447,
                "label": "Bartholoma",
                "label_ar": "بارثولوما",
                "label_fr": "Bartholoma"
            },
            {
                "value": 41448,
                "label": "Attenweiler",
                "label_ar": "أتنويلر",
                "label_fr": "Attenweiler"
            },
            {
                "value": 41449,
                "label": "Buhlen",
                "label_ar": "بوهلين",
                "label_fr": "Buhlen"
            },
            {
                "value": 41450,
                "label": "Niedenstein",
                "label_ar": "نيدنشتاين",
                "label_fr": "Niedenstein"
            },
            {
                "value": 41451,
                "label": "Emerkingen",
                "label_ar": "إيمركينجن",
                "label_fr": "Emerkingen"
            },
            {
                "value": 41452,
                "label": "Griesingen",
                "label_ar": "جريسينجين",
                "label_fr": "Griesingen"
            },
            {
                "value": 41453,
                "label": "Altshausen",
                "label_ar": "ألتشاوسين",
                "label_fr": "Altshausen"
            },
            {
                "value": 41454,
                "label": "Dettingen an der Iller",
                "label_ar": "Dettingen an der Iller",
                "label_fr": "Dettingen an der Iller"
            },
            {
                "value": 41455,
                "label": "Owen",
                "label_ar": "أوين",
                "label_fr": "Owen"
            },
            {
                "value": 41456,
                "label": "Bierlingen",
                "label_ar": "بيرلينجن",
                "label_fr": "Bierlingen"
            },
            {
                "value": 41457,
                "label": "Motzingen",
                "label_ar": "موتسينجن",
                "label_fr": "Motzingen"
            },
            {
                "value": 41458,
                "label": "Aichhalden",
                "label_ar": "ايشالدين",
                "label_fr": "Aichhalden"
            },
            {
                "value": 41459,
                "label": "Bad Rippoldsau-Schapbach",
                "label_ar": "باد ريبولدسو شابباخ",
                "label_fr": "Bad Rippoldsau-Schapbach"
            },
            {
                "value": 41460,
                "label": "Deisslingen",
                "label_ar": "ديسلينجن",
                "label_fr": "Deisslingen"
            },
            {
                "value": 41461,
                "label": "Sauldorf",
                "label_ar": "Sauldorf",
                "label_fr": "Sauldorf"
            },
            {
                "value": 41462,
                "label": "Pulsnitz",
                "label_ar": "بولسنيتز",
                "label_fr": "Pulsnitz"
            },
            {
                "value": 41463,
                "label": "Olbersdorf",
                "label_ar": "أولبيرسدورف",
                "label_fr": "Olbersdorf"
            },
            {
                "value": 41464,
                "label": "Ostritz",
                "label_ar": "أوستريتز",
                "label_fr": "Ostritz"
            },
            {
                "value": 41465,
                "label": "Rohrsdorf",
                "label_ar": "روهرسدورف",
                "label_fr": "Rohrsdorf"
            },
            {
                "value": 41466,
                "label": "Bad Duben",
                "label_ar": "باد دوبين",
                "label_fr": "Bad Duben"
            },
            {
                "value": 41467,
                "label": "Nerchau",
                "label_ar": "نيرشاو",
                "label_fr": "Nerchau"
            },
            {
                "value": 41468,
                "label": "Belgern",
                "label_ar": "بلجرن",
                "label_fr": "Belgern"
            },
            {
                "value": 41469,
                "label": "Torgau",
                "label_ar": "تورجاو",
                "label_fr": "Torgau"
            },
            {
                "value": 41470,
                "label": "Pegau",
                "label_ar": "بيغاو",
                "label_fr": "Pegau"
            },
            {
                "value": 41471,
                "label": "Oeversee",
                "label_ar": "أويفرسي",
                "label_fr": "Oeversee"
            },
            {
                "value": 41472,
                "label": "Leck",
                "label_ar": "ليك",
                "label_fr": "Leck"
            },
            {
                "value": 41473,
                "label": "Westerland",
                "label_ar": "ويسترلاند",
                "label_fr": "Westerland"
            },
            {
                "value": 41474,
                "label": "Kropp",
                "label_ar": "كروب",
                "label_fr": "Kropp"
            },
            {
                "value": 41475,
                "label": "Sollerup",
                "label_ar": "سوليروب",
                "label_fr": "Sollerup"
            },
            {
                "value": 41476,
                "label": "Grossenwiehe",
                "label_ar": "Grossenwiehe",
                "label_fr": "Grossenwiehe"
            },
            {
                "value": 41477,
                "label": "Tetenhusen",
                "label_ar": "Tetenhusen",
                "label_fr": "Tetenhusen"
            },
            {
                "value": 41478,
                "label": "Brebel",
                "label_ar": "بريبيل",
                "label_fr": "Brebel"
            },
            {
                "value": 41479,
                "label": "Heudorf",
                "label_ar": "هيودورف",
                "label_fr": "Heudorf"
            },
            {
                "value": 41480,
                "label": "Altheim",
                "label_ar": "ألتهايم",
                "label_fr": "Altheim"
            },
            {
                "value": 41481,
                "label": "Wyhl",
                "label_ar": "ويل",
                "label_fr": "Wyhl"
            },
            {
                "value": 41482,
                "label": "Breitnau",
                "label_ar": "بريتناو",
                "label_fr": "Breitnau"
            },
            {
                "value": 41483,
                "label": "Steinen",
                "label_ar": "شتاينين",
                "label_fr": "Steinen"
            },
            {
                "value": 41484,
                "label": "Kandern",
                "label_ar": "كاندير",
                "label_fr": "Kandern"
            },
            {
                "value": 41485,
                "label": "Ibach",
                "label_ar": "يباتش",
                "label_fr": "Ibach"
            },
            {
                "value": 41486,
                "label": "Herbolzheim",
                "label_ar": "هيربولزهايم",
                "label_fr": "Herbolzheim"
            },
            {
                "value": 41487,
                "label": "Laufenburg",
                "label_ar": "لوفنبورغ",
                "label_fr": "Laufenburg"
            },
            {
                "value": 41488,
                "label": "Todtmoos",
                "label_ar": "تودتموس",
                "label_fr": "Todtmoos"
            },
            {
                "value": 41489,
                "label": "Ebringen",
                "label_ar": "ابرينجن",
                "label_fr": "Ebringen"
            },
            {
                "value": 41490,
                "label": "Eschbach",
                "label_ar": "ايشباخ",
                "label_fr": "Eschbach"
            },
            {
                "value": 41491,
                "label": "Sasbach",
                "label_ar": "ساسباخ",
                "label_fr": "Sasbach"
            },
            {
                "value": 41492,
                "label": "Uhlingen-Birkendorf",
                "label_ar": "أولينجن بيركيندورف",
                "label_fr": "Uhlingen-Birkendorf"
            },
            {
                "value": 41493,
                "label": "Ballrechten",
                "label_ar": "بالريشتن",
                "label_fr": "Ballrechten"
            },
            {
                "value": 41494,
                "label": "Utzenfeld",
                "label_ar": "أوتزنفيلد",
                "label_fr": "Utzenfeld"
            },
            {
                "value": 41495,
                "label": "Schalksmuhle",
                "label_ar": "Schalksmuhle",
                "label_fr": "Schalksmuhle"
            },
            {
                "value": 41496,
                "label": "Schomberg",
                "label_ar": "شومبرج",
                "label_fr": "Schomberg"
            },
            {
                "value": 41497,
                "label": "Engelsbrand",
                "label_ar": "إنجلبراند",
                "label_fr": "Engelsbrand"
            },
            {
                "value": 41498,
                "label": "Ostelsheim",
                "label_ar": "أوستلسهايم",
                "label_fr": "Ostelsheim"
            },
            {
                "value": 41499,
                "label": "Gomaringen",
                "label_ar": "جومارينجين",
                "label_fr": "Gomaringen"
            },
            {
                "value": 41500,
                "label": "Raubach",
                "label_ar": "راوباتش",
                "label_fr": "Raubach"
            },
            {
                "value": 41501,
                "label": "Munstermaifeld",
                "label_ar": "مونسترمايفيلد",
                "label_fr": "Munstermaifeld"
            },
            {
                "value": 41502,
                "label": "Stockhausen-Illfurth",
                "label_ar": "ستوكهاوزن إلفورث",
                "label_fr": "Stockhausen-Illfurth"
            },
            {
                "value": 41503,
                "label": "Mundersbach",
                "label_ar": "موندرسباخ",
                "label_fr": "Mundersbach"
            },
            {
                "value": 41504,
                "label": "Alsbach",
                "label_ar": "السباخ",
                "label_fr": "Alsbach"
            },
            {
                "value": 41505,
                "label": "Ulmen",
                "label_ar": "أولمن",
                "label_fr": "Ulmen"
            },
            {
                "value": 41506,
                "label": "Kleinmaischeid",
                "label_ar": "كلاينمايشيد",
                "label_fr": "Kleinmaischeid"
            },
            {
                "value": 41507,
                "label": "Neuweiler",
                "label_ar": "نيوويلر",
                "label_fr": "Neuweiler"
            },
            {
                "value": 41508,
                "label": "Sternenfels",
                "label_ar": "ستيرنفيلس",
                "label_fr": "Sternenfels"
            },
            {
                "value": 41509,
                "label": "Neugersdorf",
                "label_ar": "نيوجرسدورف",
                "label_fr": "Neugersdorf"
            },
            {
                "value": 41510,
                "label": "Oppach",
                "label_ar": "أباتش",
                "label_fr": "Oppach"
            },
            {
                "value": 41511,
                "label": "Goda",
                "label_ar": "جودا",
                "label_fr": "Goda"
            },
            {
                "value": 41512,
                "label": "Mistelbach",
                "label_ar": "ميستلباخ",
                "label_fr": "Mistelbach"
            },
            {
                "value": 41513,
                "label": "Himmelkron",
                "label_ar": "هيميلكرون",
                "label_fr": "Himmelkron"
            },
            {
                "value": 41514,
                "label": "Luhe-Wildenau",
                "label_ar": "لوهي ويلديناو",
                "label_fr": "Luhe-Wildenau"
            },
            {
                "value": 41515,
                "label": "Altenstadt",
                "label_ar": "التنشتات",
                "label_fr": "Altenstadt"
            },
            {
                "value": 41516,
                "label": "Speinshart",
                "label_ar": "سبينشارت",
                "label_fr": "Speinshart"
            },
            {
                "value": 41517,
                "label": "Partenstein",
                "label_ar": "بارتنشتاين",
                "label_fr": "Partenstein"
            },
            {
                "value": 41518,
                "label": "Heigenbrucken",
                "label_ar": "هايجنبروكن",
                "label_fr": "Heigenbrucken"
            },
            {
                "value": 41519,
                "label": "Bodenkirchen",
                "label_ar": "بودنكيرشن",
                "label_fr": "Bodenkirchen"
            },
            {
                "value": 41520,
                "label": "Hohenbrunn",
                "label_ar": "هوهنبرون",
                "label_fr": "Hohenbrunn"
            },
            {
                "value": 41521,
                "label": "Dorum",
                "label_ar": "دوروم",
                "label_fr": "Dorum"
            },
            {
                "value": 41522,
                "label": "Seefeld",
                "label_ar": "سيفيلد",
                "label_fr": "Seefeld"
            },
            {
                "value": 41523,
                "label": "Bichl",
                "label_ar": "بيشل",
                "label_fr": "Bichl"
            },
            {
                "value": 41524,
                "label": "Rottleberode",
                "label_ar": "روتليبرود",
                "label_fr": "Rottleberode"
            },
            {
                "value": 41525,
                "label": "Neukieritzsch",
                "label_ar": "نويكيريتزش",
                "label_fr": "Neukieritzsch"
            },
            {
                "value": 41526,
                "label": "Colditz",
                "label_ar": "كولديتز",
                "label_fr": "Colditz"
            },
            {
                "value": 41527,
                "label": "Rosswein",
                "label_ar": "روسوين",
                "label_fr": "Rosswein"
            },
            {
                "value": 41528,
                "label": "Mutzschen",
                "label_ar": "موتشين",
                "label_fr": "Mutzschen"
            },
            {
                "value": 41529,
                "label": "Ottersweier",
                "label_ar": "Ottersweier",
                "label_fr": "Ottersweier"
            },
            {
                "value": 41530,
                "label": "Oberwolfach",
                "label_ar": "أوبيرفولفاتش",
                "label_fr": "Oberwolfach"
            },
            {
                "value": 41531,
                "label": "Muggensturm",
                "label_ar": "موجنسترم",
                "label_fr": "Muggensturm"
            },
            {
                "value": 41532,
                "label": "Kappel-Grafenhausen",
                "label_ar": "كابيل جرافنهاوزن",
                "label_fr": "Kappel-Grafenhausen"
            },
            {
                "value": 41533,
                "label": "Greifenstein",
                "label_ar": "غريفنشتاين",
                "label_fr": "Greifenstein"
            },
            {
                "value": 41534,
                "label": "Selters",
                "label_ar": "زلترس",
                "label_fr": "Selters"
            },
            {
                "value": 41535,
                "label": "Niederquembach",
                "label_ar": "نيدركيمباش",
                "label_fr": "Niederquembach"
            },
            {
                "value": 41536,
                "label": "Rodheim",
                "label_ar": "رودهايم",
                "label_fr": "Rodheim"
            },
            {
                "value": 41537,
                "label": "Schornsheim",
                "label_ar": "شورنشيم",
                "label_fr": "Schornsheim"
            },
            {
                "value": 41538,
                "label": "Sulzheim",
                "label_ar": "سولزهايم",
                "label_fr": "Sulzheim"
            },
            {
                "value": 41539,
                "label": "Mengerschied",
                "label_ar": "مينجيرشيد",
                "label_fr": "Mengerschied"
            },
            {
                "value": 41540,
                "label": "Oberhausen-Rheinhausen",
                "label_ar": "أوبرهاوزن راينهاوزن",
                "label_fr": "Oberhausen-Rheinhausen"
            },
            {
                "value": 41541,
                "label": "Unterliezheim",
                "label_ar": "أونترليزهايم",
                "label_fr": "Unterliezheim"
            },
            {
                "value": 41542,
                "label": "Asbach",
                "label_ar": "أسباخ",
                "label_fr": "Asbach"
            },
            {
                "value": 41543,
                "label": "Lutzingen",
                "label_ar": "لوتزنجين",
                "label_fr": "Lutzingen"
            },
            {
                "value": 41544,
                "label": "Gerabronn",
                "label_ar": "جيرابرون",
                "label_fr": "Gerabronn"
            },
            {
                "value": 41545,
                "label": "Jagsthausen",
                "label_ar": "جاغستهاوزن",
                "label_fr": "Jagsthausen"
            },
            {
                "value": 41546,
                "label": "Burtenbach",
                "label_ar": "بيرتينباخ",
                "label_fr": "Burtenbach"
            },
            {
                "value": 41547,
                "label": "Gachenbach",
                "label_ar": "جاتشينباخ",
                "label_fr": "Gachenbach"
            },
            {
                "value": 41548,
                "label": "Oberrot",
                "label_ar": "اوبروت",
                "label_fr": "Oberrot"
            },
            {
                "value": 41549,
                "label": "Allmersbach im Tal",
                "label_ar": "ألمرسباخ إم تال",
                "label_fr": "Allmersbach im Tal"
            },
            {
                "value": 41550,
                "label": "Scheuerhalden",
                "label_ar": "شويرهولدين",
                "label_fr": "Scheuerhalden"
            },
            {
                "value": 41551,
                "label": "Fischbach",
                "label_ar": "فيشباخ",
                "label_fr": "Fischbach"
            },
            {
                "value": 41552,
                "label": "Kirtorf",
                "label_ar": "كيرتورف",
                "label_fr": "Kirtorf"
            },
            {
                "value": 41553,
                "label": "Rasdorf",
                "label_ar": "راسدورف",
                "label_fr": "Rasdorf"
            },
            {
                "value": 41554,
                "label": "Hosenfeld",
                "label_ar": "هوسنفيلد",
                "label_fr": "Hosenfeld"
            },
            {
                "value": 41555,
                "label": "Nennig",
                "label_ar": "نينيغ",
                "label_fr": "Nennig"
            },
            {
                "value": 41556,
                "label": "Ensdorf",
                "label_ar": "انسدورف",
                "label_fr": "Ensdorf"
            },
            {
                "value": 41557,
                "label": "Hirstein",
                "label_ar": "هيرستين",
                "label_fr": "Hirstein"
            },
            {
                "value": 41558,
                "label": "Gusterath",
                "label_ar": "جوستراث",
                "label_fr": "Gusterath"
            },
            {
                "value": 41559,
                "label": "Burgen",
                "label_ar": "بورغن",
                "label_fr": "Burgen"
            },
            {
                "value": 41560,
                "label": "Auw",
                "label_ar": "أوو",
                "label_fr": "Auw"
            },
            {
                "value": 41561,
                "label": "Maring-Noviand",
                "label_ar": "مارينغ نوفياند",
                "label_fr": "Maring-Noviand"
            },
            {
                "value": 41562,
                "label": "Mandern",
                "label_ar": "ماندرن",
                "label_fr": "Mandern"
            },
            {
                "value": 41563,
                "label": "Baasem",
                "label_ar": "باعاصم",
                "label_fr": "Baasem"
            },
            {
                "value": 41564,
                "label": "Eckfeld",
                "label_ar": "إيكفيلد",
                "label_fr": "Eckfeld"
            },
            {
                "value": 41565,
                "label": "Korperich",
                "label_ar": "كوربيريتش",
                "label_fr": "Korperich"
            },
            {
                "value": 41566,
                "label": "Sefferweich",
                "label_ar": "صفيرويتش",
                "label_fr": "Sefferweich"
            },
            {
                "value": 41567,
                "label": "Schleid",
                "label_ar": "شليد",
                "label_fr": "Schleid"
            },
            {
                "value": 41568,
                "label": "Platten",
                "label_ar": "بلاتين",
                "label_fr": "Platten"
            },
            {
                "value": 41569,
                "label": "Prumzurlay",
                "label_ar": "برمزورلاي",
                "label_fr": "Prumzurlay"
            },
            {
                "value": 41570,
                "label": "Lambertsberg",
                "label_ar": "لامبيرتسبرج",
                "label_fr": "Lambertsberg"
            },
            {
                "value": 41571,
                "label": "Hilscheid",
                "label_ar": "هيلشايد",
                "label_fr": "Hilscheid"
            },
            {
                "value": 41572,
                "label": "Altenkirchen",
                "label_ar": "التنكيرشن",
                "label_fr": "Altenkirchen"
            },
            {
                "value": 41573,
                "label": "Niederkirchen",
                "label_ar": "نيدركيرشن",
                "label_fr": "Niederkirchen"
            },
            {
                "value": 41574,
                "label": "Schindhard",
                "label_ar": "شندارد",
                "label_fr": "Schindhard"
            },
            {
                "value": 41575,
                "label": "Clausen",
                "label_ar": "كلوزين",
                "label_fr": "Clausen"
            },
            {
                "value": 41576,
                "label": "Heinzenhausen",
                "label_ar": "هاينزينهاوزن",
                "label_fr": "Heinzenhausen"
            },
            {
                "value": 41577,
                "label": "Konken",
                "label_ar": "كونكن",
                "label_fr": "Konken"
            },
            {
                "value": 41578,
                "label": "Hornbach",
                "label_ar": "هورنباخ",
                "label_fr": "Hornbach"
            },
            {
                "value": 41579,
                "label": "Contwig",
                "label_ar": "كونتويج",
                "label_fr": "Contwig"
            },
            {
                "value": 41580,
                "label": "Medard",
                "label_ar": "ميدارد",
                "label_fr": "Médard"
            },
            {
                "value": 41581,
                "label": "Offenbach-Hundheim",
                "label_ar": "أوفنباخ هوندهايم",
                "label_fr": "Offenbach-Hundheim"
            },
            {
                "value": 41582,
                "label": "Waldmohr",
                "label_ar": "والدموهر",
                "label_fr": "Waldmohr"
            },
            {
                "value": 41583,
                "label": "Olsbrucken",
                "label_ar": "أولسبروكن",
                "label_fr": "Olsbrucken"
            },
            {
                "value": 41584,
                "label": "Einselthum",
                "label_ar": "اينسلثوم",
                "label_fr": "Einselthum"
            },
            {
                "value": 41585,
                "label": "Breitenbach",
                "label_ar": "بريتينباخ",
                "label_fr": "Breitenbach"
            },
            {
                "value": 41586,
                "label": "Dittweiler",
                "label_ar": "ديتويلر",
                "label_fr": "Dittweiler"
            },
            {
                "value": 41587,
                "label": "Wilgartswiesen",
                "label_ar": "ويلجارتسويسن",
                "label_fr": "Wilgartswiesen"
            },
            {
                "value": 41588,
                "label": "Papendorf",
                "label_ar": "بابيندورف",
                "label_fr": "Papendorf"
            },
            {
                "value": 41589,
                "label": "Rullstorf",
                "label_ar": "رولستورف",
                "label_fr": "Rullstorf"
            },
            {
                "value": 41590,
                "label": "Scharnebeck",
                "label_ar": "شارنيبيك",
                "label_fr": "Scharnebeck"
            },
            {
                "value": 41591,
                "label": "Klein-Gerau",
                "label_ar": "كلاين جيراو",
                "label_fr": "Klein-Gerau"
            },
            {
                "value": 41592,
                "label": "Rieneck",
                "label_ar": "رينيك",
                "label_fr": "Rieneck"
            },
            {
                "value": 41593,
                "label": "Ippesheim",
                "label_ar": "إيبسهايم",
                "label_fr": "Ippesheim"
            },
            {
                "value": 41594,
                "label": "Lautertal",
                "label_ar": "لوترتال",
                "label_fr": "Lautertal"
            },
            {
                "value": 41595,
                "label": "Klein-Zimmern",
                "label_ar": "كلاين زيمرن",
                "label_fr": "Klein-Zimmern"
            },
            {
                "value": 41596,
                "label": "Billings",
                "label_ar": "بيلينغز",
                "label_fr": "Facturation"
            },
            {
                "value": 41597,
                "label": "Oberstenfeld",
                "label_ar": "اوبرستينفيلد",
                "label_fr": "Oberstenfeld"
            },
            {
                "value": 41598,
                "label": "Dorzbach",
                "label_ar": "دورزباخ",
                "label_fr": "Dorzbach"
            },
            {
                "value": 41599,
                "label": "Stimpfach",
                "label_ar": "Stimpfach",
                "label_fr": "Stimpfach"
            },
            {
                "value": 41600,
                "label": "Schmidthachenbach",
                "label_ar": "شميدثشينباخ",
                "label_fr": "Schmidthachenbach"
            },
            {
                "value": 41601,
                "label": "Hohen",
                "label_ar": "هوهين",
                "label_fr": "Hohen"
            },
            {
                "value": 41602,
                "label": "Oberdiebach",
                "label_ar": "Oberdiebach",
                "label_fr": "Oberdiebach"
            },
            {
                "value": 41603,
                "label": "Sankt Georgen im Schwarzwald",
                "label_ar": "سانكت جورجين إم شوارزوالد",
                "label_fr": "Sankt Georgen im Schwarzwald"
            },
            {
                "value": 41604,
                "label": "Norsingen",
                "label_ar": "نورسينجين",
                "label_fr": "Norsingen"
            },
            {
                "value": 41605,
                "label": "Vohrenbach",
                "label_ar": "فوهرينباخ",
                "label_fr": "Vohrenbach"
            },
            {
                "value": 41606,
                "label": "Waldsassen",
                "label_ar": "فالدساسن",
                "label_fr": "Waldsassen"
            },
            {
                "value": 41607,
                "label": "Waldalgesheim",
                "label_ar": "فالدالجشيم",
                "label_fr": "Waldalgesheim"
            },
            {
                "value": 41608,
                "label": "Kesselbach",
                "label_ar": "كيسيلباخ",
                "label_fr": "Kesselbach"
            },
            {
                "value": 41609,
                "label": "Sargenroth",
                "label_ar": "سارجينروث",
                "label_fr": "Sargenroth"
            },
            {
                "value": 41610,
                "label": "Pfalzfeld",
                "label_ar": "بفالزفيلد",
                "label_fr": "Pfalzfeld"
            },
            {
                "value": 41611,
                "label": "Pleizenhausen",
                "label_ar": "بليزنهاوزن",
                "label_fr": "Pleizenhausen"
            },
            {
                "value": 41612,
                "label": "Engelstadt",
                "label_ar": "إنجلشتات",
                "label_fr": "Engelstadt"
            },
            {
                "value": 41613,
                "label": "Erbes-Budesheim",
                "label_ar": "Erbes-Budesheim",
                "label_fr": "Erbes-Budesheim"
            },
            {
                "value": 41614,
                "label": "Abenberg",
                "label_ar": "أبينبرغ",
                "label_fr": "Abenberg"
            },
            {
                "value": 41615,
                "label": "Walsdorf",
                "label_ar": "Walsdorf",
                "label_fr": "Walsdorf"
            },
            {
                "value": 41616,
                "label": "Heroldsbach",
                "label_ar": "هيرولدسباخ",
                "label_fr": "Heroldsbach"
            },
            {
                "value": 41617,
                "label": "Burgebrach",
                "label_ar": "برجبراش",
                "label_fr": "Burgebrach"
            },
            {
                "value": 41618,
                "label": "Buchenbach",
                "label_ar": "بوخينباخ",
                "label_fr": "Buchenbach"
            },
            {
                "value": 41619,
                "label": "Kinding",
                "label_ar": "اللطف",
                "label_fr": "Kinding"
            },
            {
                "value": 41620,
                "label": "Oberwesel",
                "label_ar": "Oberwesel",
                "label_fr": "Oberwesel"
            },
            {
                "value": 41621,
                "label": "Hochstetten-Dhaun",
                "label_ar": "Hochstetten-Dhaun",
                "label_fr": "Hochstetten-Dhaun"
            },
            {
                "value": 41622,
                "label": "Kasdorf",
                "label_ar": "كاسدورف",
                "label_fr": "Kasdorf"
            },
            {
                "value": 41623,
                "label": "Bergen",
                "label_ar": "بيرغن",
                "label_fr": "Bergen"
            },
            {
                "value": 41624,
                "label": "Geisig",
                "label_ar": "Geisig",
                "label_fr": "Geisig"
            },
            {
                "value": 41625,
                "label": "Bornich",
                "label_ar": "بورنيتش",
                "label_fr": "Bornich"
            },
            {
                "value": 41626,
                "label": "Merxheim",
                "label_ar": "ميركسهايم",
                "label_fr": "Merxheim"
            },
            {
                "value": 41627,
                "label": "Monsheim",
                "label_ar": "مونشيم",
                "label_fr": "Monsheim"
            },
            {
                "value": 41628,
                "label": "Seesbach",
                "label_ar": "سيسباخ",
                "label_fr": "Seesbach"
            },
            {
                "value": 41629,
                "label": "Breitscheid",
                "label_ar": "بريتشيد",
                "label_fr": "Breitscheid"
            },
            {
                "value": 41630,
                "label": "Neubeckum",
                "label_ar": "نوبيكوم",
                "label_fr": "Neubeckum"
            },
            {
                "value": 41631,
                "label": "Mommenheim",
                "label_ar": "مومنهايم",
                "label_fr": "Mommenheim"
            },
            {
                "value": 41632,
                "label": "Flonheim",
                "label_ar": "فلونهايم",
                "label_fr": "Flonheim"
            },
            {
                "value": 41633,
                "label": "Pyrbaum",
                "label_ar": "بيرباوم",
                "label_fr": "Pyrbaum"
            },
            {
                "value": 41634,
                "label": "Priesendorf",
                "label_ar": "بريسندورف",
                "label_fr": "Priesendorf"
            },
            {
                "value": 41635,
                "label": "Euerdorf",
                "label_ar": "يوردورف",
                "label_fr": "Euerdorf"
            },
            {
                "value": 41636,
                "label": "Rimpar",
                "label_ar": "ريبار",
                "label_fr": "Rimpar"
            },
            {
                "value": 41637,
                "label": "Stadtlauringen",
                "label_ar": "Stadtlauringen",
                "label_fr": "Stadtlauringen"
            },
            {
                "value": 41638,
                "label": "Kolitzheim",
                "label_ar": "كوليتزهايم",
                "label_fr": "Kolitzheim"
            },
            {
                "value": 41639,
                "label": "Stammheim",
                "label_ar": "ستامهايم",
                "label_fr": "Stammheim"
            },
            {
                "value": 41640,
                "label": "Waldbuttelbrunn",
                "label_ar": "والدبوتيلبرون",
                "label_fr": "Waldbuttelbrunn"
            },
            {
                "value": 41641,
                "label": "Dittelbrunn",
                "label_ar": "ديتيلبرون",
                "label_fr": "Dittelbrunn"
            },
            {
                "value": 41642,
                "label": "Tauberrettersheim",
                "label_ar": "تاوبررتسهايم",
                "label_fr": "Tauberrettersheim"
            },
            {
                "value": 41643,
                "label": "Unterbaldingen",
                "label_ar": "أونتربالدينجين",
                "label_fr": "Unterbaldingen"
            },
            {
                "value": 41644,
                "label": "Malborn",
                "label_ar": "مالبورن",
                "label_fr": "Malborn"
            },
            {
                "value": 41645,
                "label": "Speicher",
                "label_ar": "سبايكر",
                "label_fr": "Speicher"
            },
            {
                "value": 41646,
                "label": "Oberbillig",
                "label_ar": "Oberbillig",
                "label_fr": "Oberbillig"
            },
            {
                "value": 41647,
                "label": "Mechenried",
                "label_ar": "ميشينريد",
                "label_fr": "Mechenried"
            },
            {
                "value": 41648,
                "label": "Burkardroth",
                "label_ar": "بوركاردروث",
                "label_fr": "Burkardroth"
            },
            {
                "value": 41649,
                "label": "Schlusselfeld",
                "label_ar": "شلوسلفيلد",
                "label_fr": "Schlusselfeld"
            },
            {
                "value": 41650,
                "label": "Grafenrheinfeld",
                "label_ar": "جرافينرهينفيلد",
                "label_fr": "Grafenrheinfeld"
            },
            {
                "value": 41651,
                "label": "Heppdiel",
                "label_ar": "هيبديل",
                "label_fr": "Heppdiel"
            },
            {
                "value": 41652,
                "label": "Hofheim",
                "label_ar": "هوفهايم",
                "label_fr": "Hofheim"
            },
            {
                "value": 41653,
                "label": "Possenheim",
                "label_ar": "بوسنهايم",
                "label_fr": "Possenheim"
            },
            {
                "value": 41654,
                "label": "Heinrichsthal",
                "label_ar": "هاينريشستال",
                "label_fr": "Heinrichsthal"
            },
            {
                "value": 41655,
                "label": "Sand",
                "label_ar": "الرمل",
                "label_fr": "Le sable"
            },
            {
                "value": 41656,
                "label": "Neukirchen",
                "label_ar": "نويكيرشن",
                "label_fr": "Neukirchen"
            },
            {
                "value": 41657,
                "label": "Ulrichstein",
                "label_ar": "أولريشستين",
                "label_fr": "Ulrichstein"
            },
            {
                "value": 41658,
                "label": "Kandel",
                "label_ar": "كاندل",
                "label_fr": "Kandel"
            },
            {
                "value": 41659,
                "label": "Schmiechen",
                "label_ar": "شميتشن",
                "label_fr": "Schmiechen"
            },
            {
                "value": 41660,
                "label": "Berghulen",
                "label_ar": "بيرغولين",
                "label_fr": "Berghulen"
            },
            {
                "value": 41661,
                "label": "Steinheim am Albuch",
                "label_ar": "Steinheim am Albuch",
                "label_fr": "Steinheim am Albuch"
            },
            {
                "value": 41662,
                "label": "Roigheim",
                "label_ar": "رويجهايم",
                "label_fr": "Roigheim"
            },
            {
                "value": 41663,
                "label": "Dirmstein",
                "label_ar": "ديرمستين",
                "label_fr": "Dirmstein"
            },
            {
                "value": 41664,
                "label": "Minfeld",
                "label_ar": "مينفيلد",
                "label_fr": "Minfeld"
            },
            {
                "value": 41665,
                "label": "Eichtersheim",
                "label_ar": "ايخترشيم",
                "label_fr": "Eichtersheim"
            },
            {
                "value": 41666,
                "label": "Edenkoben",
                "label_ar": "إيدنكوبين",
                "label_fr": "Edenkoben"
            },
            {
                "value": 41667,
                "label": "Lambsheim",
                "label_ar": "لامبشيم",
                "label_fr": "Lambsheim"
            },
            {
                "value": 41668,
                "label": "Gerlachsheim",
                "label_ar": "غيرلاخشيم",
                "label_fr": "Gerlachsheim"
            },
            {
                "value": 41669,
                "label": "Jebenhausen",
                "label_ar": "يبنهاوزن",
                "label_fr": "Jebenhausen"
            },
            {
                "value": 41670,
                "label": "Laumersheim",
                "label_ar": "لوميرشايم",
                "label_fr": "Laumersheim"
            },
            {
                "value": 41671,
                "label": "Schonau im Schwarzwald",
                "label_ar": "شوناو إم شوارزوالد",
                "label_fr": "Schonau im Schwarzwald"
            },
            {
                "value": 41672,
                "label": "Ruchheim",
                "label_ar": "روتشيم",
                "label_fr": "Ruchheim"
            },
            {
                "value": 41673,
                "label": "Neubulach",
                "label_ar": "نويبولاخ",
                "label_fr": "Neubulach"
            },
            {
                "value": 41674,
                "label": "Aidlingen",
                "label_ar": "ايدلينجن",
                "label_fr": "Aidlingen"
            },
            {
                "value": 41675,
                "label": "Leimersheim",
                "label_ar": "Leimersheim",
                "label_fr": "Leimersheim"
            },
            {
                "value": 41676,
                "label": "Neckargerach",
                "label_ar": "نيكارجيراتش",
                "label_fr": "Neckargerach"
            },
            {
                "value": 41677,
                "label": "Hagenbach",
                "label_ar": "هاجنباخ",
                "label_fr": "Hagenbach"
            },
            {
                "value": 41678,
                "label": "Karlshuld",
                "label_ar": "كارلشولد",
                "label_fr": "Karlshuld"
            },
            {
                "value": 41679,
                "label": "Brannenburg",
                "label_ar": "برانينبورغ",
                "label_fr": "Brannenburg"
            },
            {
                "value": 41680,
                "label": "Oberpframmern",
                "label_ar": "Oberpframmern",
                "label_fr": "Oberpframmern"
            },
            {
                "value": 41681,
                "label": "Eicherloh",
                "label_ar": "Eicherloh",
                "label_fr": "Eicherloh"
            },
            {
                "value": 41682,
                "label": "Eitting",
                "label_ar": "الأكل",
                "label_fr": "Manger"
            },
            {
                "value": 41683,
                "label": "Maikammer",
                "label_ar": "مايكامر",
                "label_fr": "Maikammer"
            },
            {
                "value": 41684,
                "label": "Maxdorf",
                "label_ar": "ماكسدورف",
                "label_fr": "Maxdorf"
            },
            {
                "value": 41685,
                "label": "Roschbach",
                "label_ar": "روسشباخ",
                "label_fr": "Roschbach"
            },
            {
                "value": 41686,
                "label": "Neuleiningen",
                "label_ar": "نيولينجين",
                "label_fr": "Neuleiningen"
            },
            {
                "value": 41687,
                "label": "Putbus",
                "label_ar": "بوتبوس",
                "label_fr": "Putbus"
            },
            {
                "value": 41688,
                "label": "Lassentin",
                "label_ar": "لاسينتين",
                "label_fr": "Lassentin"
            },
            {
                "value": 41689,
                "label": "Gagern",
                "label_ar": "جاجيرن",
                "label_fr": "Gagern"
            },
            {
                "value": 41690,
                "label": "Prohn",
                "label_ar": "بروهن",
                "label_fr": "Prohn"
            },
            {
                "value": 41691,
                "label": "Ostseebad Zinnowitz",
                "label_ar": "أوستسيباد زينوفيتس",
                "label_fr": "Ostseebad Zinnowitz"
            },
            {
                "value": 41692,
                "label": "Wiek",
                "label_ar": "ويك",
                "label_fr": "Wiek"
            },
            {
                "value": 41693,
                "label": "Obrigheim",
                "label_ar": "Obrigheim",
                "label_fr": "Obrigheim"
            },
            {
                "value": 41694,
                "label": "Harthausen",
                "label_ar": "هارتهاوزن",
                "label_fr": "Harthausen"
            },
            {
                "value": 41695,
                "label": "Thum",
                "label_ar": "ثوم",
                "label_fr": "Thum"
            },
            {
                "value": 41696,
                "label": "Niederdorf",
                "label_ar": "نيدردورف",
                "label_fr": "Niederdorf"
            },
            {
                "value": 41697,
                "label": "Neudorf",
                "label_ar": "نيودورف",
                "label_fr": "Neudorf"
            },
            {
                "value": 41698,
                "label": "Sehma",
                "label_ar": "سيما",
                "label_fr": "Sehma"
            },
            {
                "value": 41699,
                "label": "Gersdorf",
                "label_ar": "غيرسدورف",
                "label_fr": "Gersdorf"
            },
            {
                "value": 41700,
                "label": "Erda",
                "label_ar": "اردا",
                "label_fr": "Erda"
            },
            {
                "value": 41701,
                "label": "Annerod",
                "label_ar": "أنيرود",
                "label_fr": "Annerod"
            },
            {
                "value": 41702,
                "label": "Rauschenberg",
                "label_ar": "روشنبرغ",
                "label_fr": "Rauschenberg"
            },
            {
                "value": 41703,
                "label": "Fronhausen",
                "label_ar": "فرونهاوزن",
                "label_fr": "Fronhausen"
            },
            {
                "value": 41704,
                "label": "Eschau",
                "label_ar": "إيشاو",
                "label_fr": "Eschau"
            },
            {
                "value": 41705,
                "label": "Roden",
                "label_ar": "رودين",
                "label_fr": "Roden"
            },
            {
                "value": 41706,
                "label": "Monchberg",
                "label_ar": "مونشبرغ",
                "label_fr": "Monchberg"
            },
            {
                "value": 41707,
                "label": "Rentweinsdorf",
                "label_ar": "رينتوينسدورف",
                "label_fr": "Rentweinsdorf"
            },
            {
                "value": 41708,
                "label": "Beuern",
                "label_ar": "بويرن",
                "label_fr": "Beuern"
            },
            {
                "value": 41709,
                "label": "Herschbach",
                "label_ar": "هيرشباخ",
                "label_fr": "Herschbach"
            },
            {
                "value": 41710,
                "label": "Allendorf",
                "label_ar": "أليندورف",
                "label_fr": "Allendorf"
            },
            {
                "value": 41711,
                "label": "Altendiez",
                "label_ar": "ألتندييز",
                "label_fr": "Altendiez"
            },
            {
                "value": 41712,
                "label": "Hausen",
                "label_ar": "Hausen",
                "label_fr": "Hausen"
            },
            {
                "value": 41713,
                "label": "Rummingen",
                "label_ar": "رومينجين",
                "label_fr": "Rummingen"
            },
            {
                "value": 41714,
                "label": "Oberried",
                "label_ar": "أوبيرريد",
                "label_fr": "Oberried"
            },
            {
                "value": 41715,
                "label": "Lehmen",
                "label_ar": "ليمن",
                "label_fr": "Lehmen"
            },
            {
                "value": 41716,
                "label": "Melsbach",
                "label_ar": "ميلسباخ",
                "label_fr": "Melsbach"
            },
            {
                "value": 41717,
                "label": "Mayen",
                "label_ar": "ماين",
                "label_fr": "Mayen"
            },
            {
                "value": 41718,
                "label": "Gieleroth",
                "label_ar": "جيليروث",
                "label_fr": "Gieleroth"
            },
            {
                "value": 41719,
                "label": "Willmenrod",
                "label_ar": "ويلمنرود",
                "label_fr": "Willmenrod"
            },
            {
                "value": 41720,
                "label": "Steinen",
                "label_ar": "شتاينين",
                "label_fr": "Steinen"
            },
            {
                "value": 41721,
                "label": "Uxheim",
                "label_ar": "أوكشيم",
                "label_fr": "Uxheim"
            },
            {
                "value": 41722,
                "label": "Werlau",
                "label_ar": "ويرلاو",
                "label_fr": "Werlau"
            },
            {
                "value": 41723,
                "label": "Kelberg",
                "label_ar": "كيلبيرج",
                "label_fr": "Kelberg"
            },
            {
                "value": 41724,
                "label": "Oberraden",
                "label_ar": "أوبرادين",
                "label_fr": "Oberraden"
            },
            {
                "value": 41725,
                "label": "Bad Gottleuba",
                "label_ar": "باد جوتليوبا",
                "label_fr": "Bad Gottleuba"
            },
            {
                "value": 41726,
                "label": "Bodenheim",
                "label_ar": "بودنهايم",
                "label_fr": "Bodenheim"
            },
            {
                "value": 41727,
                "label": "Haide",
                "label_ar": "هايد",
                "label_fr": "Haide"
            },
            {
                "value": 41728,
                "label": "Neusitz",
                "label_ar": "Neusitz",
                "label_fr": "Neusitz"
            },
            {
                "value": 41729,
                "label": "Ohorn",
                "label_ar": "أوهورن",
                "label_fr": "Ohorn"
            },
            {
                "value": 41730,
                "label": "Hirschfelde",
                "label_ar": "هيرشفيلد",
                "label_fr": "Hirschfelde"
            },
            {
                "value": 41731,
                "label": "Oberseifersdorf",
                "label_ar": "Oberseifersdorf",
                "label_fr": "Oberseifersdorf"
            },
            {
                "value": 41732,
                "label": "Dittelsdorf",
                "label_ar": "ديتلسدورف",
                "label_fr": "Dittelsdorf"
            },
            {
                "value": 41733,
                "label": "Spitzkunnersdorf",
                "label_ar": "سبيتزكونرسدورف",
                "label_fr": "Spitzkunnersdorf"
            },
            {
                "value": 41734,
                "label": "Grossschonau",
                "label_ar": "غروسشوناو",
                "label_fr": "Grossschonau"
            },
            {
                "value": 41735,
                "label": "Burgsponheim",
                "label_ar": "بورغسبونهايم",
                "label_fr": "Burgsponheim"
            },
            {
                "value": 41736,
                "label": "Schmittweiler",
                "label_ar": "شميتويلر",
                "label_fr": "Schmittweiler"
            },
            {
                "value": 41737,
                "label": "Monzingen",
                "label_ar": "مونزينجين",
                "label_fr": "Monzingen"
            },
            {
                "value": 41738,
                "label": "Lauschied",
                "label_ar": "ضحك",
                "label_fr": "Lauschied"
            },
            {
                "value": 41739,
                "label": "Lindewitt",
                "label_ar": "لينديويت",
                "label_fr": "Lindewitt"
            },
            {
                "value": 41740,
                "label": "Goldelund",
                "label_ar": "غولدلوند",
                "label_fr": "Goldelund"
            },
            {
                "value": 41741,
                "label": "Suderlugum",
                "label_ar": "سودرلوجوم",
                "label_fr": "Suderlugum"
            },
            {
                "value": 41742,
                "label": "Humptrup",
                "label_ar": "هومتروب",
                "label_fr": "Humptrup"
            },
            {
                "value": 41743,
                "label": "Langenweddingen",
                "label_ar": "Langenweddingen",
                "label_fr": "Langenweddingen"
            },
            {
                "value": 41744,
                "label": "Oebisfelde",
                "label_ar": "Oebisfelde",
                "label_fr": "Oebisfelde"
            },
            {
                "value": 41745,
                "label": "Dardesheim",
                "label_ar": "دارديسهايم",
                "label_fr": "Dardesheim"
            },
            {
                "value": 41746,
                "label": "Stapelburg",
                "label_ar": "ستابيلبرج",
                "label_fr": "Stapelburg"
            },
            {
                "value": 41747,
                "label": "Colbitz",
                "label_ar": "كولبيتز",
                "label_fr": "Colbitz"
            },
            {
                "value": 41748,
                "label": "Harzgerode",
                "label_ar": "Harzgerode",
                "label_fr": "Harzgerode"
            },
            {
                "value": 41749,
                "label": "Pfaffenhausen",
                "label_ar": "بفافنهاوزن",
                "label_fr": "Pfaffenhausen"
            },
            {
                "value": 41750,
                "label": "Uedem",
                "label_ar": "أوديم",
                "label_fr": "Uedem"
            },
            {
                "value": 41751,
                "label": "Kagsdorf",
                "label_ar": "كاجسدورف",
                "label_fr": "Kagsdorf"
            },
            {
                "value": 41752,
                "label": "Pfaffenhofen",
                "label_ar": "بفافنهوفن",
                "label_fr": "Pfaffenhofen"
            },
            {
                "value": 41753,
                "label": "Schneizlreuth",
                "label_ar": "شنيزلروث",
                "label_fr": "Schneizlreuth"
            },
            {
                "value": 41754,
                "label": "Fridolfing",
                "label_ar": "فريدولفينج",
                "label_fr": "Fridolfing"
            },
            {
                "value": 41755,
                "label": "Hohenbergham",
                "label_ar": "هوهنبيرجهام",
                "label_fr": "Hohenbergham"
            },
            {
                "value": 41756,
                "label": "Reichelsheim",
                "label_ar": "Reichelsheim",
                "label_fr": "Reichelsheim"
            },
            {
                "value": 41757,
                "label": "Echzell",
                "label_ar": "Echzell",
                "label_fr": "Echzell"
            },
            {
                "value": 41758,
                "label": "Lauta",
                "label_ar": "لوتا",
                "label_fr": "Lauta"
            },
            {
                "value": 41759,
                "label": "Bad Schandau",
                "label_ar": "سيئة Schandau",
                "label_fr": "Bad Schandau"
            },
            {
                "value": 41760,
                "label": "Arnsdorf",
                "label_ar": "ارنسدورف",
                "label_fr": "Arnsdorf"
            },
            {
                "value": 41761,
                "label": "Klingenberg",
                "label_ar": "كلينجنبرج",
                "label_fr": "Klingenberg"
            },
            {
                "value": 41762,
                "label": "Hegge",
                "label_ar": "هيجي",
                "label_fr": "Hegge"
            },
            {
                "value": 41763,
                "label": "Haldenwang",
                "label_ar": "هالدينوانغ",
                "label_fr": "Haldenwang"
            },
            {
                "value": 41764,
                "label": "Burgberg",
                "label_ar": "برجبيرج",
                "label_fr": "Burgberg"
            },
            {
                "value": 41765,
                "label": "Obergunzburg",
                "label_ar": "Obergunzburg",
                "label_fr": "Obergunzburg"
            },
            {
                "value": 41766,
                "label": "Harsleben",
                "label_ar": "هارسليبن",
                "label_fr": "Harsleben"
            },
            {
                "value": 41767,
                "label": "Wilsdruff",
                "label_ar": "ويلسدروف",
                "label_fr": "Wilsdruff"
            },
            {
                "value": 41768,
                "label": "Langenwolmsdorf",
                "label_ar": "لانجينفولمسدورف",
                "label_fr": "Langenwolmsdorf"
            },
            {
                "value": 41769,
                "label": "Geising",
                "label_ar": "Geising",
                "label_fr": "Geising"
            },
            {
                "value": 41770,
                "label": "Aukrug",
                "label_ar": "اوكروج",
                "label_fr": "Aukrug"
            },
            {
                "value": 41771,
                "label": "Norderstapel",
                "label_ar": "نوردرستابيل",
                "label_fr": "Norderstapel"
            },
            {
                "value": 41772,
                "label": "Hohenlockstedt",
                "label_ar": "هوهنلوكستيدت",
                "label_fr": "Hohenlockstedt"
            },
            {
                "value": 41773,
                "label": "Schlettau",
                "label_ar": "شليتو",
                "label_fr": "Schlettau"
            },
            {
                "value": 41774,
                "label": "Wechselburg",
                "label_ar": "Wechselburg",
                "label_fr": "Wechselburg"
            },
            {
                "value": 41775,
                "label": "Mildenau",
                "label_ar": "ميلديناو",
                "label_fr": "Mildenau"
            },
            {
                "value": 41776,
                "label": "Jahnsdorf",
                "label_ar": "ياهنسدورف",
                "label_fr": "Jahnsdorf"
            },
            {
                "value": 41777,
                "label": "Reinsdorf",
                "label_ar": "رينسدورف",
                "label_fr": "Reinsdorf"
            },
            {
                "value": 41778,
                "label": "Syrau",
                "label_ar": "سيراو",
                "label_fr": "Syrau"
            },
            {
                "value": 41779,
                "label": "Lichtenau",
                "label_ar": "ليشتناو",
                "label_fr": "Lichtenau"
            },
            {
                "value": 41780,
                "label": "Leubsdorf",
                "label_ar": "ليوبسدورف",
                "label_fr": "Leubsdorf"
            },
            {
                "value": 41781,
                "label": "Dahlenburg",
                "label_ar": "داهلينبورغ",
                "label_fr": "Dahlenburg"
            },
            {
                "value": 41782,
                "label": "Herrnhut",
                "label_ar": "هيرنهوت",
                "label_fr": "Herrnhut"
            },
            {
                "value": 41783,
                "label": "Doberschau",
                "label_ar": "Doberschau",
                "label_fr": "Doberschau"
            },
            {
                "value": 41784,
                "label": "Wilthen",
                "label_ar": "ويلثين",
                "label_fr": "Wilthen"
            },
            {
                "value": 41785,
                "label": "Mittelherwigsdorf",
                "label_ar": "ميتلهيرويغسدورف",
                "label_fr": "Mittelherwigsdorf"
            },
            {
                "value": 41786,
                "label": "Bernstadt",
                "label_ar": "برنشتات",
                "label_fr": "Bernstadt"
            },
            {
                "value": 41787,
                "label": "Cunewalde",
                "label_ar": "كونوالد",
                "label_fr": "Cunewalde"
            },
            {
                "value": 41788,
                "label": "Eibau",
                "label_ar": "إيبو",
                "label_fr": "Eibau"
            },
            {
                "value": 41789,
                "label": "Rennersdorf",
                "label_ar": "رينرسدورف",
                "label_fr": "Rennersdorf"
            },
            {
                "value": 41790,
                "label": "Obergurig",
                "label_ar": "Obergurig",
                "label_fr": "Obergurig"
            },
            {
                "value": 41791,
                "label": "Holdorf",
                "label_ar": "هولدورف",
                "label_fr": "Holdorf"
            },
            {
                "value": 41792,
                "label": "Unterwossen",
                "label_ar": "Unterwossen",
                "label_fr": "Unterwossen"
            },
            {
                "value": 41793,
                "label": "Perach",
                "label_ar": "بيراش",
                "label_fr": "Perach"
            },
            {
                "value": 41794,
                "label": "Bergen",
                "label_ar": "بيرغن",
                "label_fr": "Bergen"
            },
            {
                "value": 41795,
                "label": "Seebruck",
                "label_ar": "سيبروك",
                "label_fr": "Seebruck"
            },
            {
                "value": 41796,
                "label": "Breitbrunn am Chiemsee",
                "label_ar": "Breitbrunn am Chiemsee",
                "label_fr": "Breitbrunn am Chiemsee"
            },
            {
                "value": 41797,
                "label": "Feilitzsch",
                "label_ar": "Feilitzsch",
                "label_fr": "Feilitzsch"
            },
            {
                "value": 41798,
                "label": "Neuenmarkt",
                "label_ar": "نوينماركت",
                "label_fr": "Neuenmarkt"
            },
            {
                "value": 41799,
                "label": "Trostau",
                "label_ar": "تروستو",
                "label_fr": "Trostau"
            },
            {
                "value": 41800,
                "label": "Sandesneben",
                "label_ar": "Sandesneben",
                "label_fr": "Sandesneben"
            },
            {
                "value": 41801,
                "label": "Wakendorf",
                "label_ar": "واكيندورف",
                "label_fr": "Wakendorf"
            },
            {
                "value": 41802,
                "label": "Zarpen",
                "label_ar": "زاربين",
                "label_fr": "Zarpen"
            },
            {
                "value": 41803,
                "label": "Breitengussbach",
                "label_ar": "بريتنغوسباخ",
                "label_fr": "Breitengussbach"
            },
            {
                "value": 41804,
                "label": "Thalmassing",
                "label_ar": "تالماسينج",
                "label_fr": "Thalmassage"
            },
            {
                "value": 41805,
                "label": "Gremsdorf",
                "label_ar": "جريمسدورف",
                "label_fr": "Gremsdorf"
            },
            {
                "value": 41806,
                "label": "Neustadt an der Orla",
                "label_ar": "نيوستادت أن دير أورلا",
                "label_fr": "Neustadt an der Orla"
            },
            {
                "value": 41807,
                "label": "Konigsee",
                "label_ar": "كونيجسي",
                "label_fr": "Konigsee"
            },
            {
                "value": 41808,
                "label": "Niederpollnitz",
                "label_ar": "نيدربولنيتز",
                "label_fr": "Niederpollnitz"
            },
            {
                "value": 41809,
                "label": "Lehndorf",
                "label_ar": "ليندورف",
                "label_fr": "Lehndorf"
            },
            {
                "value": 41810,
                "label": "Salzwedel",
                "label_ar": "سالزويديل",
                "label_fr": "Salzwedel"
            },
            {
                "value": 41811,
                "label": "Gatersleben",
                "label_ar": "جاترسليبن",
                "label_fr": "Gatersleben"
            },
            {
                "value": 41812,
                "label": "Jerichow",
                "label_ar": "أريحا",
                "label_fr": "Jérichow"
            },
            {
                "value": 41813,
                "label": "Buden",
                "label_ar": "بودن",
                "label_fr": "Buden"
            },
            {
                "value": 41814,
                "label": "Luftkurort Arendsee",
                "label_ar": "لوفتكورورت أرندسي",
                "label_fr": "Luftkurort Arendsee"
            },
            {
                "value": 41815,
                "label": "Neenstetten",
                "label_ar": "نينشتيتن",
                "label_fr": "Neenstetten"
            },
            {
                "value": 41816,
                "label": "Gerbstedt",
                "label_ar": "جيربستيدت",
                "label_fr": "Gerbstedt"
            },
            {
                "value": 41817,
                "label": "Dieskau",
                "label_ar": "ديسكاو",
                "label_fr": "Dieskau"
            },
            {
                "value": 41818,
                "label": "Aken",
                "label_ar": "أكين",
                "label_fr": "Aken"
            },
            {
                "value": 41819,
                "label": "Baasdorf",
                "label_ar": "باسدورف",
                "label_fr": "Baasdorf"
            },
            {
                "value": 41820,
                "label": "Weissandt-Golzau",
                "label_ar": "فايساندت جولزاو",
                "label_fr": "Weissandt-Golzau"
            },
            {
                "value": 41821,
                "label": "Kretzschau",
                "label_ar": "كريتزشاو",
                "label_fr": "Kretzschau"
            },
            {
                "value": 41822,
                "label": "Bobenheim am Berg",
                "label_ar": "بوبنهايم أم بيرج",
                "label_fr": "Bobenheim am Berg"
            },
            {
                "value": 41823,
                "label": "Ilbesheim",
                "label_ar": "Ilbesheim",
                "label_fr": "Ilbesheim"
            },
            {
                "value": 41824,
                "label": "Neckarbischofsheim",
                "label_ar": "نيكاربيشوفشيم",
                "label_fr": "Neckarbischofsheim"
            },
            {
                "value": 41825,
                "label": "Kirchardt",
                "label_ar": "كيرتشاردت",
                "label_fr": "Kirchardt"
            },
            {
                "value": 41826,
                "label": "Lingenfeld",
                "label_ar": "Lingenfeld",
                "label_fr": "Lingenfeld"
            },
            {
                "value": 41827,
                "label": "Bad Bergzabern",
                "label_ar": "باد بيرجزابيرن",
                "label_fr": "Bad Bergzabern"
            },
            {
                "value": 41828,
                "label": "Wettin",
                "label_ar": "ويتين",
                "label_fr": "Wettin"
            },
            {
                "value": 41829,
                "label": "Milzau",
                "label_ar": "ميلزاو",
                "label_fr": "Milzau"
            },
            {
                "value": 41830,
                "label": "Zschornewitz",
                "label_ar": "زشورنيويتز",
                "label_fr": "Zschornewitz"
            },
            {
                "value": 41831,
                "label": "Rosslau",
                "label_ar": "روسلاو",
                "label_fr": "Rosslau"
            },
            {
                "value": 41832,
                "label": "Dederstedt",
                "label_ar": "Dederstedt",
                "label_fr": "Dederstedt"
            },
            {
                "value": 41833,
                "label": "Horstdorf",
                "label_ar": "هورستدورف",
                "label_fr": "Horstdorf"
            },
            {
                "value": 41834,
                "label": "Grafenhainichen",
                "label_ar": "جرافنهاينيشن",
                "label_fr": "Grafenhainichen"
            },
            {
                "value": 41835,
                "label": "Frose",
                "label_ar": "فروس",
                "label_fr": "Frose"
            },
            {
                "value": 41836,
                "label": "Helbra",
                "label_ar": "هيلبرا",
                "label_fr": "Helbra"
            },
            {
                "value": 41837,
                "label": "Nachterstedt",
                "label_ar": "Nachterstedt",
                "label_fr": "Nachterstedt"
            },
            {
                "value": 41838,
                "label": "Droyssig",
                "label_ar": "درويسيغ",
                "label_fr": "Droyssig"
            },
            {
                "value": 41839,
                "label": "Konnern",
                "label_ar": "كونيرن",
                "label_fr": "Konnern"
            },
            {
                "value": 41840,
                "label": "Lieskau",
                "label_ar": "ليسكاو",
                "label_fr": "Lieskau"
            },
            {
                "value": 41841,
                "label": "Langendorf",
                "label_ar": "لانجندورف",
                "label_fr": "Langendorf"
            },
            {
                "value": 41842,
                "label": "Niemberg",
                "label_ar": "نمبرغ",
                "label_fr": "Niemberg"
            },
            {
                "value": 41843,
                "label": "Seeburg",
                "label_ar": "سيبورغ",
                "label_fr": "Seeburg"
            },
            {
                "value": 41844,
                "label": "Helmershausen",
                "label_ar": "هيلمرسهاوزن",
                "label_fr": "Helmershausen"
            },
            {
                "value": 41845,
                "label": "Altengottern",
                "label_ar": "Altengottern",
                "label_fr": "Altengottern"
            },
            {
                "value": 41846,
                "label": "Herbsleben",
                "label_ar": "أعشاب",
                "label_fr": "Herbsleben"
            },
            {
                "value": 41847,
                "label": "Niederspier",
                "label_ar": "نيدرسبير",
                "label_fr": "Niederspier"
            },
            {
                "value": 41848,
                "label": "Grossengottern",
                "label_ar": "جروسينجوترن",
                "label_fr": "Grossengottern"
            },
            {
                "value": 41849,
                "label": "Georgenthal",
                "label_ar": "جورجينثال",
                "label_fr": "Georgenthal"
            },
            {
                "value": 41850,
                "label": "Vesser",
                "label_ar": "السفينة",
                "label_fr": "Vesser"
            },
            {
                "value": 41851,
                "label": "Bad Frankenhausen",
                "label_ar": "باد فرانكنهاوزن",
                "label_fr": "Bad Frankenhausen"
            },
            {
                "value": 41852,
                "label": "Bleicherode",
                "label_ar": "بليشرود",
                "label_fr": "Bleicherode"
            },
            {
                "value": 41853,
                "label": "Bad Tennstedt",
                "label_ar": "سيئة تينستيد",
                "label_fr": "Bad Tennstedt"
            },
            {
                "value": 41854,
                "label": "Geraberg",
                "label_ar": "جيرابيرج",
                "label_fr": "Geraberg"
            },
            {
                "value": 41855,
                "label": "Schmiedefeld am Rennsteig",
                "label_ar": "شميدفيلد ام رينستيج",
                "label_fr": "Schmiedefeld am Rennsteig"
            },
            {
                "value": 41856,
                "label": "Uder",
                "label_ar": "ادر",
                "label_fr": "Uder"
            },
            {
                "value": 41857,
                "label": "Laucha",
                "label_ar": "لاوتشا",
                "label_fr": "Laucha"
            },
            {
                "value": 41858,
                "label": "Sollstedt",
                "label_ar": "سولستيدت",
                "label_fr": "Sollstedt"
            },
            {
                "value": 41859,
                "label": "Greussen",
                "label_ar": "غروسن",
                "label_fr": "Greussen"
            },
            {
                "value": 41860,
                "label": "Thalwenden",
                "label_ar": "ثالويندن",
                "label_fr": "Thalwenden"
            },
            {
                "value": 41861,
                "label": "Wachenroth",
                "label_ar": "واشنروث",
                "label_fr": "Wachenroth"
            },
            {
                "value": 41862,
                "label": "Gebesee",
                "label_ar": "جبسي",
                "label_fr": "Gebesee"
            },
            {
                "value": 41863,
                "label": "Bosleben-Wullersleben",
                "label_ar": "Bosleben-Wullersleben",
                "label_fr": "Bosleben-Wullersleben"
            },
            {
                "value": 41864,
                "label": "Oberdorla",
                "label_ar": "أوبيردورلا",
                "label_fr": "Oberdorla"
            },
            {
                "value": 41865,
                "label": "Hildburghausen",
                "label_ar": "هيلدبورغهاوزن",
                "label_fr": "Hildburghausen"
            },
            {
                "value": 41866,
                "label": "Buttstedt",
                "label_ar": "بوتستيدت",
                "label_fr": "Buttstedt"
            },
            {
                "value": 41867,
                "label": "Wechmar",
                "label_ar": "ويشمار",
                "label_fr": "Wechmar"
            },
            {
                "value": 41868,
                "label": "Elxleben",
                "label_ar": "Elxleben",
                "label_fr": "Elxleben"
            },
            {
                "value": 41869,
                "label": "Niederdorla",
                "label_ar": "نيدردورلا",
                "label_fr": "Niederdorla"
            },
            {
                "value": 41870,
                "label": "Horselgau",
                "label_ar": "هورسيلغاو",
                "label_fr": "Horselgau"
            },
            {
                "value": 41871,
                "label": "Stotternheim",
                "label_ar": "Stotternheim",
                "label_fr": "Stotternheim"
            },
            {
                "value": 41872,
                "label": "Noda",
                "label_ar": "نودا",
                "label_fr": "Noda"
            },
            {
                "value": 41873,
                "label": "Kaltenwestheim",
                "label_ar": "كالتينويستهايم",
                "label_fr": "Kaltenwestheim"
            },
            {
                "value": 41874,
                "label": "Geschwenda",
                "label_ar": "غيشويندا",
                "label_fr": "Geschwenda"
            },
            {
                "value": 41875,
                "label": "Buch",
                "label_ar": "بوخ",
                "label_fr": "Buch"
            },
            {
                "value": 41876,
                "label": "Essenheim",
                "label_ar": "إيسينهايم",
                "label_fr": "Essenheim"
            },
            {
                "value": 41877,
                "label": "Waldheim",
                "label_ar": "فالدهايم",
                "label_fr": "Waldheim"
            },
            {
                "value": 41878,
                "label": "Grethen",
                "label_ar": "جريتن",
                "label_fr": "Grethen"
            },
            {
                "value": 41879,
                "label": "Krostitz",
                "label_ar": "كروستيتس",
                "label_fr": "Krostitz"
            },
            {
                "value": 41880,
                "label": "Regis-Breitingen",
                "label_ar": "ريجيس بريتينجن",
                "label_fr": "Regis-Breitingen"
            },
            {
                "value": 41881,
                "label": "Mochau",
                "label_ar": "موكا",
                "label_fr": "Mochau"
            },
            {
                "value": 41882,
                "label": "Dommitzsch",
                "label_ar": "دوميتسش",
                "label_fr": "Dommitzsch"
            },
            {
                "value": 41883,
                "label": "Lauta",
                "label_ar": "لوتا",
                "label_fr": "Lauta"
            },
            {
                "value": 41884,
                "label": "Heide",
                "label_ar": "هايد",
                "label_fr": "Heide"
            },
            {
                "value": 41885,
                "label": "Straupitz",
                "label_ar": "ستروبيتز",
                "label_fr": "Straupitz"
            },
            {
                "value": 41886,
                "label": "Burg",
                "label_ar": "بورغ",
                "label_fr": "Burg"
            },
            {
                "value": 41887,
                "label": "Altdobern",
                "label_ar": "التدوبرن",
                "label_fr": "Altdobern"
            },
            {
                "value": 41888,
                "label": "Spremberg",
                "label_ar": "سبرمبرج",
                "label_fr": "Spremberg"
            },
            {
                "value": 41889,
                "label": "Guben",
                "label_ar": "جوبين",
                "label_fr": "Guben"
            },
            {
                "value": 41890,
                "label": "Schonewalde",
                "label_ar": "شونيوالد",
                "label_fr": "Schonewalde"
            },
            {
                "value": 41891,
                "label": "Neupetershain",
                "label_ar": "نيوبترشاين",
                "label_fr": "Neupetershain"
            },
            {
                "value": 41892,
                "label": "Hellstein",
                "label_ar": "هيلشتاين",
                "label_fr": "Hellstein"
            },
            {
                "value": 41893,
                "label": "Stegen",
                "label_ar": "شتيجن",
                "label_fr": "Stegen"
            },
            {
                "value": 41894,
                "label": "Grafenhausen",
                "label_ar": "جرافنهاوزن",
                "label_fr": "Grafenhausen"
            },
            {
                "value": 41895,
                "label": "Wutoschingen",
                "label_ar": "Wutoschingen",
                "label_fr": "Wutoschingen"
            },
            {
                "value": 41896,
                "label": "Waldshut",
                "label_ar": "والدشوت",
                "label_fr": "Waldshut"
            },
            {
                "value": 41897,
                "label": "Istein",
                "label_ar": "ايستين",
                "label_fr": "Istein"
            },
            {
                "value": 41898,
                "label": "Feldberg-Ort",
                "label_ar": "فيلدبرج أورت",
                "label_fr": "Feldberg-Ort"
            },
            {
                "value": 41899,
                "label": "Cappel",
                "label_ar": "كابيل",
                "label_fr": "Cappel"
            },
            {
                "value": 41900,
                "label": "Gross-Rohrheim",
                "label_ar": "جروس روهرهايم",
                "label_fr": "Gross-Rohrheim"
            },
            {
                "value": 41901,
                "label": "Otzing",
                "label_ar": "Otzing",
                "label_fr": "Otzing"
            },
            {
                "value": 41902,
                "label": "Wald",
                "label_ar": "والد",
                "label_fr": "Wald"
            },
            {
                "value": 41903,
                "label": "Langquaid",
                "label_ar": "لانجكويد",
                "label_fr": "Langquaid"
            },
            {
                "value": 41904,
                "label": "Laer",
                "label_ar": "لاير",
                "label_fr": "Laer"
            },
            {
                "value": 41905,
                "label": "Birnbach",
                "label_ar": "بيرنباخ",
                "label_fr": "Birnbach"
            },
            {
                "value": 41906,
                "label": "Heringsdorf",
                "label_ar": "هيرينغسدورف",
                "label_fr": "Heringsdorf"
            },
            {
                "value": 41907,
                "label": "Jarmen",
                "label_ar": "جارمن",
                "label_fr": "Jarmen"
            },
            {
                "value": 41908,
                "label": "Karlshagen",
                "label_ar": "Karlshagen",
                "label_fr": "Karlshagen"
            },
            {
                "value": 41909,
                "label": "Gramzow",
                "label_ar": "جرامزو",
                "label_fr": "Gramzow"
            },
            {
                "value": 41910,
                "label": "Mirow",
                "label_ar": "ميرو",
                "label_fr": "Mirow"
            },
            {
                "value": 41911,
                "label": "Mallin",
                "label_ar": "مالين",
                "label_fr": "Mallin"
            },
            {
                "value": 41912,
                "label": "Grambow",
                "label_ar": "جرامبو",
                "label_fr": "Grambow"
            },
            {
                "value": 41913,
                "label": "Saal",
                "label_ar": "سال",
                "label_fr": "Saal"
            },
            {
                "value": 41914,
                "label": "Grossenluder",
                "label_ar": "Grossenluder",
                "label_fr": "Grossenluder"
            },
            {
                "value": 41915,
                "label": "Altengronau",
                "label_ar": "التنغروناو",
                "label_fr": "Altengronau"
            },
            {
                "value": 41916,
                "label": "Banteln",
                "label_ar": "بانتلن",
                "label_fr": "Banteln"
            },
            {
                "value": 41917,
                "label": "Vehlow",
                "label_ar": "فيهلو",
                "label_fr": "Vehlow"
            },
            {
                "value": 41918,
                "label": "Walsleben",
                "label_ar": "والسليبن",
                "label_fr": "Walsleben"
            },
            {
                "value": 41919,
                "label": "Zerf",
                "label_ar": "زرف",
                "label_fr": "Zerf"
            },
            {
                "value": 41920,
                "label": "Dienstweiler",
                "label_ar": "دينستويلر",
                "label_fr": "Dienstweiler"
            },
            {
                "value": 41921,
                "label": "Waxweiler",
                "label_ar": "واكسويلر",
                "label_fr": "Waxweiler"
            },
            {
                "value": 41922,
                "label": "Dabergotz",
                "label_ar": "دابيرجوتز",
                "label_fr": "Dabergotz"
            },
            {
                "value": 41923,
                "label": "Flecken Zechlin",
                "label_ar": "فليكين زيكلين",
                "label_fr": "Flecken Zechlin"
            },
            {
                "value": 41924,
                "label": "Dreetz",
                "label_ar": "دريتز",
                "label_fr": "Dreetz"
            },
            {
                "value": 41925,
                "label": "Hohendodeleben",
                "label_ar": "Hohendodeleben",
                "label_fr": "Hohendodeleben"
            },
            {
                "value": 41926,
                "label": "Zerben",
                "label_ar": "زربن",
                "label_fr": "Zerben"
            },
            {
                "value": 41927,
                "label": "Hundisburg",
                "label_ar": "هونديسبورغ",
                "label_fr": "Hundisburg"
            },
            {
                "value": 41928,
                "label": "Derben",
                "label_ar": "دربين",
                "label_fr": "Derben"
            },
            {
                "value": 41929,
                "label": "Egeln",
                "label_ar": "إيجلن",
                "label_fr": "Egeln"
            },
            {
                "value": 41930,
                "label": "Parey",
                "label_ar": "باري",
                "label_fr": "Parey"
            },
            {
                "value": 41931,
                "label": "Eggersdorf",
                "label_ar": "إيجرسدورف",
                "label_fr": "Eggersdorf"
            },
            {
                "value": 41932,
                "label": "Dedeleben",
                "label_ar": "ديدلبين",
                "label_fr": "Dedeleben"
            },
            {
                "value": 41933,
                "label": "Bruck",
                "label_ar": "بروك",
                "label_fr": "Bruck"
            },
            {
                "value": 41934,
                "label": "Betzenstein",
                "label_ar": "بيتزنشتاين",
                "label_fr": "Betzenstein"
            },
            {
                "value": 41935,
                "label": "Mistelgau",
                "label_ar": "ميستلغاو",
                "label_fr": "Mistelgau"
            },
            {
                "value": 41936,
                "label": "Glien",
                "label_ar": "جلين",
                "label_fr": "Glien"
            },
            {
                "value": 41937,
                "label": "Oberirsen",
                "label_ar": "أوبيررسن",
                "label_fr": "Oberirsen"
            },
            {
                "value": 41938,
                "label": "Friedewald",
                "label_ar": "فريدوالد",
                "label_fr": "Friedewald"
            },
            {
                "value": 41939,
                "label": "Wolferlingen",
                "label_ar": "ولفيرلينجن",
                "label_fr": "Wolferlingen"
            },
            {
                "value": 41940,
                "label": "Lonnig",
                "label_ar": "لونيغ",
                "label_fr": "Lonnig"
            },
            {
                "value": 41941,
                "label": "Streithausen",
                "label_ar": "ستريتهاوزن",
                "label_fr": "Streithausen"
            },
            {
                "value": 41942,
                "label": "Fachbach",
                "label_ar": "فاخباخ",
                "label_fr": "Fachbach"
            },
            {
                "value": 41943,
                "label": "Herdorf",
                "label_ar": "هيردورف",
                "label_fr": "Herdorf"
            },
            {
                "value": 41944,
                "label": "Muschenbach",
                "label_ar": "موشنباخ",
                "label_fr": "Muschenbach"
            },
            {
                "value": 41945,
                "label": "Halbs",
                "label_ar": "الهالب",
                "label_fr": "Halbs"
            },
            {
                "value": 41946,
                "label": "Schonwalde",
                "label_ar": "شونوالد",
                "label_fr": "Schonwalde"
            },
            {
                "value": 41947,
                "label": "Beim Kronsberg",
                "label_ar": "بيم كرونسبيرغ",
                "label_fr": "Beim Kronsberg"
            },
            {
                "value": 41948,
                "label": "Haus",
                "label_ar": "هاوس",
                "label_fr": "Haus"
            },
            {
                "value": 41949,
                "label": "Ankum",
                "label_ar": "أنكوم",
                "label_fr": "Ankum"
            },
            {
                "value": 41950,
                "label": "Icker",
                "label_ar": "ايكر",
                "label_fr": "Icker"
            },
            {
                "value": 41951,
                "label": "Lathen",
                "label_ar": "لاثين",
                "label_fr": "Lathen"
            },
            {
                "value": 41952,
                "label": "Goldenstedt",
                "label_ar": "جولدنستيدت",
                "label_fr": "Goldenstedt"
            },
            {
                "value": 41953,
                "label": "Lastrup",
                "label_ar": "Lastrup",
                "label_fr": "Lastrup"
            },
            {
                "value": 41954,
                "label": "Toppenstedt",
                "label_ar": "توبينستيدت",
                "label_fr": "Toppenstedt"
            },
            {
                "value": 41955,
                "label": "Kuhstedt",
                "label_ar": "كوشتيدت",
                "label_fr": "Kuhstedt"
            },
            {
                "value": 41956,
                "label": "Siedenburg",
                "label_ar": "سيدنبورغ",
                "label_fr": "Siedenburg"
            },
            {
                "value": 41957,
                "label": "Wischhafen",
                "label_ar": "Wischhafen",
                "label_fr": "Wischhafen"
            },
            {
                "value": 41958,
                "label": "Osten",
                "label_ar": "أوستن",
                "label_fr": "Osten"
            },
            {
                "value": 41959,
                "label": "Storkow",
                "label_ar": "ستوركوو",
                "label_fr": "Cigogne"
            },
            {
                "value": 41960,
                "label": "Rechtenfleth",
                "label_ar": "ريختنفليته",
                "label_fr": "Rechtenfleth"
            },
            {
                "value": 41961,
                "label": "Elsfleth",
                "label_ar": "الصفليث",
                "label_fr": "Elsfleth"
            },
            {
                "value": 41962,
                "label": "Butjadingen",
                "label_ar": "بوتجدينجين",
                "label_fr": "Butjadingen"
            },
            {
                "value": 41963,
                "label": "Ohrensen",
                "label_ar": "أورينسن",
                "label_fr": "Ohrensen"
            },
            {
                "value": 41964,
                "label": "Elstorf",
                "label_ar": "Elstorf",
                "label_fr": "Elstorf"
            },
            {
                "value": 41965,
                "label": "Hamwarde",
                "label_ar": "هاموردي",
                "label_fr": "Hamwarde"
            },
            {
                "value": 41966,
                "label": "Ottersberg",
                "label_ar": "أوترسبيرج",
                "label_fr": "Ottersberg"
            },
            {
                "value": 41967,
                "label": "Brieselang",
                "label_ar": "بريسلانج",
                "label_fr": "Brieselang"
            },
            {
                "value": 41968,
                "label": "Herzberg",
                "label_ar": "هيرزبرج",
                "label_fr": "Herzberg"
            },
            {
                "value": 41969,
                "label": "Bad Windsheim",
                "label_ar": "باد ويندشيم",
                "label_fr": "Bad Windsheim"
            },
            {
                "value": 41970,
                "label": "Lychen",
                "label_ar": "ليتشين",
                "label_fr": "Lychen"
            },
            {
                "value": 41971,
                "label": "Gromitz",
                "label_ar": "جروميتز",
                "label_fr": "Gromitz"
            },
            {
                "value": 41972,
                "label": "Bergen auf Ruegen",
                "label_ar": "بيرغن عوف روجين",
                "label_fr": "Bergen auf Ruegen"
            },
            {
                "value": 41973,
                "label": "Lynow",
                "label_ar": "لينو",
                "label_fr": "Lynow"
            },
            {
                "value": 41974,
                "label": "Dallgow-Doeberitz",
                "label_ar": "دالغو دوبيريتز",
                "label_fr": "Dallgow-Doeberitz"
            },
            {
                "value": 41975,
                "label": "Alt Golm",
                "label_ar": "ألت غولم",
                "label_fr": "Alt Golm"
            },
            {
                "value": 41976,
                "label": "Tutzpatz",
                "label_ar": "Tutzpatz",
                "label_fr": "Tutzpatz"
            },
            {
                "value": 41977,
                "label": "Krugsdorf",
                "label_ar": "كروغسدورف",
                "label_fr": "Krugsdorf"
            },
            {
                "value": 41978,
                "label": "Paradies",
                "label_ar": "الجنة",
                "label_fr": "Paradies"
            },
            {
                "value": 41979,
                "label": "Walheim",
                "label_ar": "والهايم",
                "label_fr": "Walheim"
            },
            {
                "value": 41980,
                "label": "Frittlingen",
                "label_ar": "فريتلينجن",
                "label_fr": "Frittlingen"
            },
            {
                "value": 41981,
                "label": "Nusplingen",
                "label_ar": "نوسبلينجن",
                "label_fr": "Nusplingen"
            },
            {
                "value": 41982,
                "label": "Kusterdingen",
                "label_ar": "Kusterdingen",
                "label_fr": "Kusterdingen"
            },
            {
                "value": 41983,
                "label": "Entringen",
                "label_ar": "إنترينجين",
                "label_fr": "Entringen"
            },
            {
                "value": 41984,
                "label": "Ruppertshofen",
                "label_ar": "روبيرتسهوفن",
                "label_fr": "Ruppertshofen"
            },
            {
                "value": 41985,
                "label": "Deilingen",
                "label_ar": "ديلينجين",
                "label_fr": "Deilingen"
            },
            {
                "value": 41986,
                "label": "Riegel",
                "label_ar": "ريجل",
                "label_fr": "Riegel"
            },
            {
                "value": 41987,
                "label": "Silling",
                "label_ar": "سيلينج",
                "label_fr": "Silling"
            },
            {
                "value": 41988,
                "label": "Karsbach",
                "label_ar": "كارسباخ",
                "label_fr": "Karsbach"
            },
            {
                "value": 41989,
                "label": "Bogenhausen",
                "label_ar": "بوغنهاوزن",
                "label_fr": "Bogenhausen"
            },
            {
                "value": 41990,
                "label": "Gadegast",
                "label_ar": "جاديجاست",
                "label_fr": "Gadegast"
            },
            {
                "value": 41991,
                "label": "Blumenthal",
                "label_ar": "بلومنتال",
                "label_fr": "Blumenthal"
            },
            {
                "value": 41992,
                "label": "Bornstein",
                "label_ar": "بورنشتاين",
                "label_fr": "Bornstein"
            },
            {
                "value": 41993,
                "label": "Marienburg",
                "label_ar": "مارينبورغ",
                "label_fr": "Marienburg"
            },
            {
                "value": 41994,
                "label": "Fockbek",
                "label_ar": "فوكبيك",
                "label_fr": "Fockbek"
            },
            {
                "value": 41995,
                "label": "Boklund",
                "label_ar": "بوكلوند",
                "label_fr": "Boklund"
            },
            {
                "value": 41996,
                "label": "Tarp",
                "label_ar": "قماش القنب",
                "label_fr": "Bâche"
            },
            {
                "value": 41997,
                "label": "Borgdorf-Seedorf",
                "label_ar": "بورجدورف سيدورف",
                "label_fr": "Borgdorf-Seedorf"
            },
            {
                "value": 41998,
                "label": "Wasbek",
                "label_ar": "واسبيك",
                "label_fr": "Wasbek"
            },
            {
                "value": 41999,
                "label": "Fleckeby",
                "label_ar": "فليكبي",
                "label_fr": "Fleckeby"
            },
            {
                "value": 42000,
                "label": "Owschlag",
                "label_ar": "أوشلاغ",
                "label_fr": "Owschlag"
            },
            {
                "value": 42001,
                "label": "Rickert",
                "label_ar": "ريكرت",
                "label_fr": "Rickert"
            },
            {
                "value": 42001,
                "label": "Padenstedt",
                "label_ar": "Padenstedt",
                "label_fr": "Padenstedt"
            },
            {
                "value": 42002,
                "label": "Nubbel",
                "label_ar": "نبل",
                "label_fr": "Nubbel"
            },
            {
                "value": 42003,
                "label": "Kirchhellen",
                "label_ar": "كيرشيلين",
                "label_fr": "Kirchhellen"
            },
            {
                "value": 42004,
                "label": "Neuendettelsau",
                "label_ar": "نوينديتلساو",
                "label_fr": "Neuendettelsau"
            },
            {
                "value": 42005,
                "label": "Stulln",
                "label_ar": "ستولن",
                "label_fr": "Stulln"
            },
            {
                "value": 42006,
                "label": "Geithain",
                "label_ar": "جيثين",
                "label_fr": "Geithain"
            },
            {
                "value": 42007,
                "label": "Tettau",
                "label_ar": "تيتاو",
                "label_fr": "Tettau"
            },
            {
                "value": 42008,
                "label": "Konigsfeld",
                "label_ar": "كونيجسفيلد",
                "label_fr": "Königsfeld"
            },
            {
                "value": 42009,
                "label": "Etterschlag",
                "label_ar": "إتيرشلاغ",
                "label_fr": "Etterschlag"
            },
            {
                "value": 42010,
                "label": "Spatzenhausen",
                "label_ar": "سباتزينهاوزن",
                "label_fr": "Spatzenhausen"
            },
            {
                "value": 42011,
                "label": "Leezen",
                "label_ar": "ليزين",
                "label_fr": "Leezen"
            },
            {
                "value": 42012,
                "label": "Nakensdorf",
                "label_ar": "ناكنسدورف",
                "label_fr": "Nakensdorf"
            },
            {
                "value": 42013,
                "label": "Cramonshagen",
                "label_ar": "كرامونشاجين",
                "label_fr": "Cramonshagen"
            },
            {
                "value": 42014,
                "label": "Domsuhl",
                "label_ar": "دومسهل",
                "label_fr": "Domsuhl"
            },
            {
                "value": 42015,
                "label": "Retgendorf",
                "label_ar": "ريتجندورف",
                "label_fr": "Retgendorf"
            },
            {
                "value": 42016,
                "label": "Witzin",
                "label_ar": "ويتزين",
                "label_fr": "Witzin"
            },
            {
                "value": 42017,
                "label": "Bornsen",
                "label_ar": "بورنسن",
                "label_fr": "Bornsen"
            },
            {
                "value": 42018,
                "label": "Trent",
                "label_ar": "ترينت",
                "label_fr": "Trent"
            },
            {
                "value": 42019,
                "label": "Rambin",
                "label_ar": "رامبين",
                "label_fr": "Rambin"
            },
            {
                "value": 42020,
                "label": "Verchen",
                "label_ar": "فيرشين",
                "label_fr": "Verchen"
            },
            {
                "value": 42021,
                "label": "Neuenkirchen",
                "label_ar": "نوينكيرشن",
                "label_fr": "Neuenkirchen"
            },
            {
                "value": 42022,
                "label": "Grimmen",
                "label_ar": "جريمين",
                "label_fr": "Grimmen"
            },
            {
                "value": 42023,
                "label": "Neuenkirchen",
                "label_ar": "نوينكيرشن",
                "label_fr": "Neuenkirchen"
            },
            {
                "value": 42024,
                "label": "Dipbach",
                "label_ar": "ديبباخ",
                "label_fr": "Dipbach"
            },
            {
                "value": 42025,
                "label": "Benshausen",
                "label_ar": "بنشوزن",
                "label_fr": "Benshausen"
            },
            {
                "value": 42026,
                "label": "Weissensee",
                "label_ar": "فايسنسي",
                "label_fr": "Weissensee"
            },
            {
                "value": 42027,
                "label": "Bregenstedt",
                "label_ar": "بريجنستيدت",
                "label_fr": "Bregenstedt"
            },
            {
                "value": 42028,
                "label": "Barby",
                "label_ar": "باربي",
                "label_fr": "Barby"
            },
            {
                "value": 42029,
                "label": "Dahlen",
                "label_ar": "داهلين",
                "label_fr": "Dahlen"
            },
            {
                "value": 42030,
                "label": "Mugeln",
                "label_ar": "موغلن",
                "label_fr": "Mugeln"
            },
            {
                "value": 42031,
                "label": "Ursensollen",
                "label_ar": "Ursensollen",
                "label_fr": "Ursensollen"
            },
            {
                "value": 42032,
                "label": "Freudenberg",
                "label_ar": "فرويدنبرغ",
                "label_fr": "Freudenberg"
            },
            {
                "value": 42033,
                "label": "Claussnitz",
                "label_ar": "كلاوسنيتز",
                "label_fr": "Claussnitz"
            },
            {
                "value": 42034,
                "label": "Klutz",
                "label_ar": "كلوتز",
                "label_fr": "Klutz"
            },
            {
                "value": 42035,
                "label": "Strohkirchen",
                "label_ar": "Strohkirchen",
                "label_fr": "Strohkirchen"
            },
            {
                "value": 42036,
                "label": "Lauenbruck",
                "label_ar": "لوينبروك",
                "label_fr": "Lauenbruck"
            },
            {
                "value": 42037,
                "label": "Wirges",
                "label_ar": "الأسلاك",
                "label_fr": "Wirges"
            },
            {
                "value": 42038,
                "label": "Gershasen",
                "label_ar": "غيرشاسن",
                "label_fr": "Gershasen"
            },
            {
                "value": 42039,
                "label": "Luckenbach",
                "label_ar": "لوكنباخ",
                "label_fr": "Luckenbach"
            },
            {
                "value": 42040,
                "label": "Burgbrohl",
                "label_ar": "بورجبروهل",
                "label_fr": "Burgbrohl"
            },
            {
                "value": 42041,
                "label": "Idenheim",
                "label_ar": "إيدنهايم",
                "label_fr": "Idenheim"
            },
            {
                "value": 42042,
                "label": "Werlte",
                "label_ar": "ويرلت",
                "label_fr": "Werlte"
            },
            {
                "value": 42043,
                "label": "Lindern",
                "label_ar": "ليندرن",
                "label_fr": "Lindern"
            },
            {
                "value": 42044,
                "label": "Oberthulba",
                "label_ar": "أوبرثلبا",
                "label_fr": "Oberthulba"
            },
            {
                "value": 42045,
                "label": "Weissenthurm",
                "label_ar": "فايسنثورم",
                "label_fr": "Weissenthurm"
            },
            {
                "value": 42046,
                "label": "Ohrdruf",
                "label_ar": "أوردروف",
                "label_fr": "Ohrdruf"
            },
            {
                "value": 42047,
                "label": "Kranichfeld",
                "label_ar": "كرانيشفيلد",
                "label_fr": "Kranichfeld"
            },
            {
                "value": 42048,
                "label": "Burghaun",
                "label_ar": "بورغون",
                "label_fr": "Burghaun"
            },
            {
                "value": 42049,
                "label": "Ainring",
                "label_ar": "أينرينج",
                "label_fr": "Ainring"
            },
            {
                "value": 42050,
                "label": "Lehesten",
                "label_ar": "ليستين",
                "label_fr": "Lehesten"
            },
            {
                "value": 42051,
                "label": "Gefell",
                "label_ar": "جيفيل",
                "label_fr": "Gefell"
            },
            {
                "value": 42052,
                "label": "Lonnerstadt",
                "label_ar": "لونرشتات",
                "label_fr": "Lonnerstadt"
            },
            {
                "value": 42053,
                "label": "Ellingen",
                "label_ar": "إلينجن",
                "label_fr": "Ellingen"
            },
            {
                "value": 42054,
                "label": "Wellheim",
                "label_ar": "ويلهايم",
                "label_fr": "Wellheim"
            },
            {
                "value": 42055,
                "label": "Vahlbruch",
                "label_ar": "فاهلبروخ",
                "label_fr": "Vahlbruch"
            },
            {
                "value": 42056,
                "label": "Obernfeld",
                "label_ar": "اوبرنفيلد",
                "label_fr": "Obernfeld"
            },
            {
                "value": 42057,
                "label": "Ostseebad Binz",
                "label_ar": "Ostseebad Binz",
                "label_fr": "Ostseebad Binz"
            },
            {
                "value": 42058,
                "label": "Wolgast",
                "label_ar": "وولجاست",
                "label_fr": "Wolgast"
            },
            {
                "value": 42059,
                "label": "Erzenhausen",
                "label_ar": "ارزينهاوزن",
                "label_fr": "Erzenhausen"
            },
            {
                "value": 42060,
                "label": "Busenberg",
                "label_ar": "بوسنبرغ",
                "label_fr": "Busenberg"
            },
            {
                "value": 42061,
                "label": "Leimen",
                "label_ar": "ليمن",
                "label_fr": "Leimen"
            },
            {
                "value": 42062,
                "label": "Lauterecken",
                "label_ar": "لوتركن",
                "label_fr": "Lauterecken"
            },
            {
                "value": 42063,
                "label": "Linden",
                "label_ar": "الزيزفون",
                "label_fr": "Tilleul"
            },
            {
                "value": 42064,
                "label": "Bernterode",
                "label_ar": "بيرنترود",
                "label_fr": "Bernterode"
            },
            {
                "value": 42065,
                "label": "Schwallungen",
                "label_ar": "Schwallungen",
                "label_fr": "Schwallungen"
            },
            {
                "value": 42066,
                "label": "Munchhausen",
                "label_ar": "مونشهاوزن",
                "label_fr": "Munchhausen"
            },
            {
                "value": 42067,
                "label": "Battenberg",
                "label_ar": "باتنبرغ",
                "label_fr": "Battenberg"
            },
            {
                "value": 42068,
                "label": "Kirberg",
                "label_ar": "كيربيرج",
                "label_fr": "Kirberg"
            },
            {
                "value": 42069,
                "label": "Ballersbach",
                "label_ar": "بالرسباخ",
                "label_fr": "Ballersbach"
            },
            {
                "value": 42070,
                "label": "Teuchern",
                "label_ar": "تيوخرن",
                "label_fr": "Teuchern"
            },
            {
                "value": 42071,
                "label": "Jestetten",
                "label_ar": "جيستيتين",
                "label_fr": "Jestetten"
            },
            {
                "value": 42072,
                "label": "Bollschweil",
                "label_ar": "بولشويل",
                "label_fr": "Bollschweil"
            },
            {
                "value": 42073,
                "label": "Linsburg",
                "label_ar": "لينسبورغ",
                "label_fr": "Linsburg"
            },
            {
                "value": 42074,
                "label": "Westfeld",
                "label_ar": "ويستفيلد",
                "label_fr": "Westfeld"
            },
            {
                "value": 42075,
                "label": "Werlaburgdorf",
                "label_ar": "ويرلابورجدورف",
                "label_fr": "Werlaburgdorf"
            },
            {
                "value": 42076,
                "label": "Treuen",
                "label_ar": "تريوين",
                "label_fr": "Treuen"
            },
            {
                "value": 42077,
                "label": "Gomadingen",
                "label_ar": "جومدينجين",
                "label_fr": "Gomadingen"
            },
            {
                "value": 42078,
                "label": "Winzer",
                "label_ar": "وينزر",
                "label_fr": "Winzer"
            },
            {
                "value": 42079,
                "label": "Weiler",
                "label_ar": "وايلر",
                "label_fr": "Weiler"
            },
            {
                "value": 42080,
                "label": "Bromskirchen",
                "label_ar": "برومسكيرشن",
                "label_fr": "Bromskirchen"
            },
            {
                "value": 42081,
                "label": "Sickte",
                "label_ar": "سيكتي",
                "label_fr": "Sickte"
            },
            {
                "value": 42082,
                "label": "Fallersleben",
                "label_ar": "فالرسليبن",
                "label_fr": "Fallersleben"
            },
            {
                "value": 42083,
                "label": "Dannenberg",
                "label_ar": "دانينبرغ",
                "label_fr": "Dannenberg"
            },
            {
                "value": 42084,
                "label": "Sachsenkam",
                "label_ar": "ساشسينكام",
                "label_fr": "Sachsenkam"
            },
            {
                "value": 42085,
                "label": "Fischbachau",
                "label_ar": "فيشباشو",
                "label_fr": "Fischbachau"
            },
            {
                "value": 42086,
                "label": "Neubeuern",
                "label_ar": "نيوبورن",
                "label_fr": "Neubeuern"
            },
            {
                "value": 42087,
                "label": "Obermoschel",
                "label_ar": "أوبرموشل",
                "label_fr": "Obermoschel"
            },
            {
                "value": 42088,
                "label": "Hirschhorn",
                "label_ar": "هيرشورن",
                "label_fr": "Hirschhorn"
            },
            {
                "value": 42089,
                "label": "Grosskarolinenfeld",
                "label_ar": "جروسكاروليننفيلد",
                "label_fr": "Grosskarolinenfeld"
            },
            {
                "value": 42090,
                "label": "Buggingen",
                "label_ar": "بوجينجين",
                "label_fr": "Buggingen"
            },
            {
                "value": 42091,
                "label": "Quaal",
                "label_ar": "قوال",
                "label_fr": "Quaal"
            },
            {
                "value": 42092,
                "label": "Pohnstorf",
                "label_ar": "بوهنستورف",
                "label_fr": "Pohnstorf"
            },
            {
                "value": 42093,
                "label": "Heilsbronn",
                "label_ar": "هيلسبرون",
                "label_fr": "Heilsbronn"
            },
            {
                "value": 42094,
                "label": "Pommelsbrunn",
                "label_ar": "بوميلبرون",
                "label_fr": "Pommelsbrunn"
            },
            {
                "value": 42095,
                "label": "Velburg",
                "label_ar": "فيلبورغ",
                "label_fr": "Velburg"
            },
            {
                "value": 42096,
                "label": "Breitenberg",
                "label_ar": "بريتنبرغ",
                "label_fr": "Breitenberg"
            },
            {
                "value": 42097,
                "label": "Strohn",
                "label_ar": "سترون",
                "label_fr": "Strohn"
            },
            {
                "value": 42098,
                "label": "Hobeck",
                "label_ar": "هوبيك",
                "label_fr": "Hobeck"
            },
            {
                "value": 42099,
                "label": "Atting",
                "label_ar": "أتنج",
                "label_fr": "Atting"
            },
            {
                "value": 42100,
                "label": "Traitsching",
                "label_ar": "تريتشينج",
                "label_fr": "Traitsching"
            },
            {
                "value": 42101,
                "label": "Hohenlimburg",
                "label_ar": "هوهنليمبورغ",
                "label_fr": "Hohenlimburg"
            },
            {
                "value": 42102,
                "label": "Bellheim",
                "label_ar": "بيلهايم",
                "label_fr": "Bellheim"
            },
            {
                "value": 42103,
                "label": "Hochstadten",
                "label_ar": "Hochstadten",
                "label_fr": "Hochstadten"
            },
            {
                "value": 42104,
                "label": "Wolfmannshausen",
                "label_ar": "ولفمانسهاوزن",
                "label_fr": "Wolfmannshausen"
            },
            {
                "value": 42105,
                "label": "Steisslingen",
                "label_ar": "Steisslingen",
                "label_fr": "Steisslingen"
            },
            {
                "value": 42106,
                "label": "Elsterberg",
                "label_ar": "Elsterberg",
                "label_fr": "Elsterberg"
            },
            {
                "value": 42107,
                "label": "Kranzberg",
                "label_ar": "كرانزبيرج",
                "label_fr": "Kranzberg"
            },
            {
                "value": 42108,
                "label": "Oberaudorf",
                "label_ar": "أوبيرودورف",
                "label_fr": "Oberaudorf"
            },
            {
                "value": 42109,
                "label": "Sosa",
                "label_ar": "سوسا",
                "label_fr": "Sosa"
            },
            {
                "value": 42110,
                "label": "Bruchhausen-Vilsen",
                "label_ar": "Bruchhausen-Vilsen",
                "label_fr": "Bruchhausen-Vilsen"
            },
            {
                "value": 42111,
                "label": "Ahausen",
                "label_ar": "اهاوزن",
                "label_fr": "Ahausen"
            },
            {
                "value": 42112,
                "label": "Boddenstedt",
                "label_ar": "بودنشتيدت",
                "label_fr": "Boddenstedt"
            },
            {
                "value": 42113,
                "label": "Bankewitz",
                "label_ar": "بانكويتز",
                "label_fr": "Bankewitz"
            },
            {
                "value": 42114,
                "label": "Muhl Rosin",
                "label_ar": "مهل روزين",
                "label_fr": "Muhl Rosin"
            },
            {
                "value": 42115,
                "label": "Alling",
                "label_ar": "كلينغ",
                "label_fr": "Alling"
            },
            {
                "value": 42116,
                "label": "Hofen",
                "label_ar": "هوفن",
                "label_fr": "Hofen"
            },
            {
                "value": 42117,
                "label": "Egenhofen",
                "label_ar": "إيجنهوفن",
                "label_fr": "Egenhofen"
            },
            {
                "value": 42118,
                "label": "Sotterhausen",
                "label_ar": "سوترهاوزن",
                "label_fr": "Sotterhausen"
            },
            {
                "value": 42119,
                "label": "Merzalben",
                "label_ar": "مرزالبن",
                "label_fr": "Merzalben"
            },
            {
                "value": 42120,
                "label": "Schmalenberg",
                "label_ar": "شمالينبيرج",
                "label_fr": "Schmalenberg"
            },
            {
                "value": 42121,
                "label": "Bunsoh",
                "label_ar": "بنصوح",
                "label_fr": "Bunsoh"
            },
            {
                "value": 42122,
                "label": "Schmalensee",
                "label_ar": "شمالينسي",
                "label_fr": "Schmalensee"
            },
            {
                "value": 42123,
                "label": "Hundsdorf",
                "label_ar": "هوندسدورف",
                "label_fr": "Hundsdorf"
            },
            {
                "value": 42124,
                "label": "Grossmaischeid",
                "label_ar": "Grossmaischeid",
                "label_fr": "Grossmaischeid"
            },
            {
                "value": 42125,
                "label": "Brey",
                "label_ar": "بري",
                "label_fr": "Brey"
            },
            {
                "value": 42126,
                "label": "Pesterwitz",
                "label_ar": "بيستيرويتز",
                "label_fr": "Pesterwitz"
            },
            {
                "value": 42127,
                "label": "Arnbruck",
                "label_ar": "ارنبروك",
                "label_fr": "Arnbruck"
            },
            {
                "value": 42128,
                "label": "Bayrischzell",
                "label_ar": "بايريشزيل",
                "label_fr": "Bayrischzell"
            },
            {
                "value": 42129,
                "label": "Haag an der Amper",
                "label_ar": "هاج أن دير أمبير",
                "label_fr": "Haag an der Amper"
            },
            {
                "value": 42130,
                "label": "Hasselbach",
                "label_ar": "هاسيلباخ",
                "label_fr": "Hasselbach"
            },
            {
                "value": 42131,
                "label": "Riede",
                "label_ar": "ريد",
                "label_fr": "Riede"
            },
            {
                "value": 42132,
                "label": "Hamdorf",
                "label_ar": "هامدورف",
                "label_fr": "Hamdorf"
            },
            {
                "value": 42133,
                "label": "Aurach",
                "label_ar": "أوراخ",
                "label_fr": "Aurach"
            },
            {
                "value": 42134,
                "label": "Domitz",
                "label_ar": "دوميتز",
                "label_fr": "Domitz"
            },
            {
                "value": 42135,
                "label": "Dreveskirchen",
                "label_ar": "دريفيسكيرشن",
                "label_fr": "Dreveskirchen"
            },
            {
                "value": 42136,
                "label": "Oelerse",
                "label_ar": "أويلرس",
                "label_fr": "Oelerse"
            },
            {
                "value": 42137,
                "label": "Regen",
                "label_ar": "ريجين",
                "label_fr": "Regen"
            },
            {
                "value": 42138,
                "label": "Walderbach",
                "label_ar": "والديرباخ",
                "label_fr": "Walderbach"
            },
            {
                "value": 42139,
                "label": "Morslingen",
                "label_ar": "مورسلينجن",
                "label_fr": "Morslingen"
            },
            {
                "value": 42140,
                "label": "Munzingen",
                "label_ar": "مونزينجين",
                "label_fr": "Munzingen"
            },
            {
                "value": 42141,
                "label": "Grossaitingen",
                "label_ar": "Grossaitingen",
                "label_fr": "Grossaitingen"
            },
            {
                "value": 42142,
                "label": "Landensberg",
                "label_ar": "لاندنسبرج",
                "label_fr": "Landensberg"
            },
            {
                "value": 42143,
                "label": "Rogling",
                "label_ar": "روجلينج",
                "label_fr": "Rogling"
            },
            {
                "value": 42144,
                "label": "Elbingerode",
                "label_ar": "Elbingerode",
                "label_fr": "Elbingerode"
            },
            {
                "value": 42145,
                "label": "Benneckenstein",
                "label_ar": "بينكنشتاين",
                "label_fr": "Benneckenstein"
            },
            {
                "value": 42146,
                "label": "Friedrichswerth",
                "label_ar": "فريدريشويرث",
                "label_fr": "Friedrichswerth"
            },
            {
                "value": 42147,
                "label": "Hasselfelde",
                "label_ar": "هاسلفيلد",
                "label_fr": "Hasselfelde"
            },
            {
                "value": 42148,
                "label": "Nandlstadt",
                "label_ar": "ناندلشتات",
                "label_fr": "Nandlstadt"
            },
            {
                "value": 42149,
                "label": "Dingolshausen",
                "label_ar": "Dingolshausen",
                "label_fr": "Dingolshausen"
            },
            {
                "value": 42150,
                "label": "Nieder-Beerbach",
                "label_ar": "نيدر بيرباخ",
                "label_fr": "Nieder-Beerbach"
            },
            {
                "value": 42151,
                "label": "Gundernhausen",
                "label_ar": "جوندرنهاوزن",
                "label_fr": "Gundernhausen"
            },
            {
                "value": 42152,
                "label": "Erlbach",
                "label_ar": "إرلباخ",
                "label_fr": "Erlbach"
            },
            {
                "value": 42153,
                "label": "Eschelbronn",
                "label_ar": "ايشيلبرون",
                "label_fr": "Eschelbronn"
            },
            {
                "value": 42154,
                "label": "Hergisdorf",
                "label_ar": "هيرجيسدورف",
                "label_fr": "Hergisdorf"
            },
            {
                "value": 42155,
                "label": "Hengersberg",
                "label_ar": "هينجرزبرج",
                "label_fr": "Hengersberg"
            },
            {
                "value": 42156,
                "label": "Runding",
                "label_ar": "Runding",
                "label_fr": "Runding"
            },
            {
                "value": 42157,
                "label": "Kollnburg",
                "label_ar": "كولنبورج",
                "label_fr": "Kollnburg"
            },
            {
                "value": 42158,
                "label": "Lam",
                "label_ar": "لام",
                "label_fr": "Lam"
            },
            {
                "value": 42159,
                "label": "Waldsee",
                "label_ar": "فالدسي",
                "label_fr": "Waldsee"
            },
            {
                "value": 42160,
                "label": "Birkenheide",
                "label_ar": "بيركينهايد",
                "label_fr": "Birkenheide"
            },
            {
                "value": 42161,
                "label": "Frankenstein",
                "label_ar": "فرانكشتاين",
                "label_fr": "Frankenstein"
            },
            {
                "value": 42162,
                "label": "Rohrbach",
                "label_ar": "روهرباخ",
                "label_fr": "Rohrbach"
            },
            {
                "value": 42163,
                "label": "Wachenheim an der Weinstrasse",
                "label_ar": "Wachenheim an der Weinstrasse",
                "label_fr": "Wachenheim an der Weinstrasse"
            },
            {
                "value": 42164,
                "label": "Carlsberg",
                "label_ar": "كارلسبرغ",
                "label_fr": "Carlsberg"
            },
            {
                "value": 42165,
                "label": "Hochdorf-Assenheim",
                "label_ar": "Hochdorf-Assenheim",
                "label_fr": "Hochdorf-Assenheim"
            },
            {
                "value": 42166,
                "label": "Heudeber",
                "label_ar": "هوديبر",
                "label_fr": "Heudeber"
            },
            {
                "value": 42167,
                "label": "Engelthal",
                "label_ar": "إنجلثال",
                "label_fr": "Engelthal"
            },
            {
                "value": 42168,
                "label": "Hartenstein",
                "label_ar": "هارتنشتاين",
                "label_fr": "Hartenstein"
            },
            {
                "value": 42169,
                "label": "Hausen",
                "label_ar": "Hausen",
                "label_fr": "Hausen"
            },
            {
                "value": 42170,
                "label": "Simmelsdorf",
                "label_ar": "سيميلسدورف",
                "label_fr": "Simmelsdorf"
            },
            {
                "value": 42171,
                "label": "Rottenstuben",
                "label_ar": "روتنستوبين",
                "label_fr": "Rottenstuben"
            },
            {
                "value": 42172,
                "label": "Offenhausen",
                "label_ar": "أوفنهاوزن",
                "label_fr": "Offenhausen"
            },
            {
                "value": 42173,
                "label": "Alfeld",
                "label_ar": "ألفيلد",
                "label_fr": "Alfeld"
            },
            {
                "value": 42174,
                "label": "Adelsdorf",
                "label_ar": "Adelsdorf",
                "label_fr": "Adelsdorf"
            },
            {
                "value": 42175,
                "label": "Hessdorf",
                "label_ar": "هيسدورف",
                "label_fr": "Hessdorf"
            },
            {
                "value": 42176,
                "label": "Vestenbergsgreuth",
                "label_ar": "فيستينبيرجسجروث",
                "label_fr": "Vestenbergsgreuth"
            },
            {
                "value": 42177,
                "label": "Oberhundem",
                "label_ar": "اوبرهوندم",
                "label_fr": "Oberhundem"
            },
            {
                "value": 42178,
                "label": "Hohe",
                "label_ar": "هوهي",
                "label_fr": "Hohe"
            },
            {
                "value": 42179,
                "label": "Wallertheim",
                "label_ar": "Wallertheim",
                "label_fr": "Wallertheim"
            },
            {
                "value": 42180,
                "label": "Horrweiler",
                "label_ar": "هورويلر",
                "label_fr": "Horrweiler"
            },
            {
                "value": 42181,
                "label": "Friesenheim",
                "label_ar": "فريزنهايم",
                "label_fr": "Friesenheim"
            },
            {
                "value": 42182,
                "label": "Alsheim",
                "label_ar": "مرض الزهايم",
                "label_fr": "Alsheim"
            },
            {
                "value": 42183,
                "label": "Selzen",
                "label_ar": "سيلزن",
                "label_fr": "Selzen"
            },
            {
                "value": 42184,
                "label": "Horperath",
                "label_ar": "هوربيراث",
                "label_fr": "Horperath"
            },
            {
                "value": 42185,
                "label": "Bretthausen",
                "label_ar": "بريتهاوزن",
                "label_fr": "Bretthausen"
            },
            {
                "value": 42186,
                "label": "Marienhausen",
                "label_ar": "مارينهاوزن",
                "label_fr": "Marienhausen"
            },
            {
                "value": 42187,
                "label": "Schuld",
                "label_ar": "شولد",
                "label_fr": "Schuld"
            },
            {
                "value": 42188,
                "label": "Pracht",
                "label_ar": "براخت",
                "label_fr": "Pracht"
            },
            {
                "value": 42189,
                "label": "Horressen",
                "label_ar": "هورسين",
                "label_fr": "Horressen"
            },
            {
                "value": 42190,
                "label": "Moosinning",
                "label_ar": "Moosinning",
                "label_fr": "Moosinning"
            },
            {
                "value": 42191,
                "label": "Bayersoien",
                "label_ar": "بايرسوين",
                "label_fr": "Bayersoien"
            },
            {
                "value": 42192,
                "label": "Scheuring",
                "label_ar": "Scheuring",
                "label_fr": "Scheuring"
            },
            {
                "value": 42193,
                "label": "Buch",
                "label_ar": "بوخ",
                "label_fr": "Buch"
            },
            {
                "value": 42194,
                "label": "Steingaden",
                "label_ar": "ستينغادن",
                "label_fr": "Steingaden"
            },
            {
                "value": 42195,
                "label": "Griesstatt",
                "label_ar": "جريسستات",
                "label_fr": "Griesstatt"
            },
            {
                "value": 42196,
                "label": "Spiesen-Elversberg",
                "label_ar": "Spiesen-Elversberg",
                "label_fr": "Spiesen-Elversberg"
            },
            {
                "value": 42197,
                "label": "Mitterfels",
                "label_ar": "ميترفيلز",
                "label_fr": "Mitterfels"
            },
            {
                "value": 42198,
                "label": "Sankt Englmar",
                "label_ar": "سانكت انجلمار",
                "label_fr": "Sankt Englmar"
            },
            {
                "value": 42199,
                "label": "Bad Karlshafen",
                "label_ar": "باد كارلسهافن",
                "label_fr": "Bad Karlshafen"
            },
            {
                "value": 42200,
                "label": "Soller",
                "label_ar": "سولير",
                "label_fr": "Soller"
            },
            {
                "value": 42201,
                "label": "Hastenrath",
                "label_ar": "هاستنراث",
                "label_fr": "Hastenrath"
            },
            {
                "value": 42202,
                "label": "Niederdorf",
                "label_ar": "نيدردورف",
                "label_fr": "Niederdorf"
            },
            {
                "value": 42203,
                "label": "Bofingen",
                "label_ar": "بوفينغن",
                "label_fr": "Bofingen"
            },
            {
                "value": 42204,
                "label": "Hoym",
                "label_ar": "هويم",
                "label_fr": "Hoym"
            },
            {
                "value": 42205,
                "label": "Hettstedt",
                "label_ar": "Hettstedt",
                "label_fr": "Hettstedt"
            },
            {
                "value": 42206,
                "label": "Neutz-Lettewitz",
                "label_ar": "Neutz-Lettewitz",
                "label_fr": "Neutz-Lettewitz"
            },
            {
                "value": 42207,
                "label": "Neustadt",
                "label_ar": "نيوشتات",
                "label_fr": "Neustadt"
            },
            {
                "value": 42208,
                "label": "Altleiningen",
                "label_ar": "ألتلينجين",
                "label_fr": "Altleiningen"
            },
            {
                "value": 42209,
                "label": "Schweigen-Rechtenbach",
                "label_ar": "شفايجن ريختنباخ",
                "label_fr": "Schweigen-Rechtenbach"
            },
            {
                "value": 42210,
                "label": "Prestewitz",
                "label_ar": "بريستويتز",
                "label_fr": "Prestewitz"
            },
            {
                "value": 42211,
                "label": "Leuthen",
                "label_ar": "ليوثن",
                "label_fr": "Leuthen"
            },
            {
                "value": 42212,
                "label": "Elsterwerda",
                "label_ar": "Elsterwerda",
                "label_fr": "Elsterwerda"
            },
            {
                "value": 42213,
                "label": "Otterbach",
                "label_ar": "أوترباخ",
                "label_fr": "Otterbach"
            },
            {
                "value": 42214,
                "label": "Ruppertsweiler",
                "label_ar": "Ruppertsweiler",
                "label_fr": "Ruppertsweiler"
            },
            {
                "value": 42215,
                "label": "Kleinbundenbach",
                "label_ar": "كلاينبوندنباخ",
                "label_fr": "Kleinbundenbach"
            },
            {
                "value": 42216,
                "label": "Dellfeld",
                "label_ar": "ديلفيلد",
                "label_fr": "Dellfeld"
            },
            {
                "value": 42217,
                "label": "Guntersblum",
                "label_ar": "Guntersblum",
                "label_fr": "Guntersblum"
            },
            {
                "value": 42218,
                "label": "Gundersheim",
                "label_ar": "جوندرشيم",
                "label_fr": "Gundersheim"
            },
            {
                "value": 42219,
                "label": "Hochborn",
                "label_ar": "هوشبورن",
                "label_fr": "Hochborn"
            },
            {
                "value": 42220,
                "label": "Walluf",
                "label_ar": "والوف",
                "label_fr": "Walluf"
            },
            {
                "value": 42221,
                "label": "Meisenheim",
                "label_ar": "ميسنهايم",
                "label_fr": "Meisenheim"
            },
            {
                "value": 42222,
                "label": "Eimsheim",
                "label_ar": "ايمشيم",
                "label_fr": "Eimsheim"
            },
            {
                "value": 42223,
                "label": "Laudert",
                "label_ar": "لودرت",
                "label_fr": "Laudert"
            },
            {
                "value": 42224,
                "label": "Kisselbach",
                "label_ar": "كيسيلباخ",
                "label_fr": "Kisselbach"
            },
            {
                "value": 42225,
                "label": "Bockenau",
                "label_ar": "بوكيناو",
                "label_fr": "Bockenau"
            },
            {
                "value": 42226,
                "label": "Fischerhude",
                "label_ar": "فيشرهود",
                "label_fr": "Fischerhude"
            },
            {
                "value": 42227,
                "label": "Thedinghausen",
                "label_ar": "ثيندينهاوزن",
                "label_fr": "Thedinghausen"
            },
            {
                "value": 42228,
                "label": "Fernthal",
                "label_ar": "فرنثال",
                "label_fr": "Fernthal"
            },
            {
                "value": 42229,
                "label": "Oberzissen",
                "label_ar": "أوبيرزيسن",
                "label_fr": "Oberzissen"
            },
            {
                "value": 42230,
                "label": "Urbar",
                "label_ar": "أوربار",
                "label_fr": "Urbar"
            },
            {
                "value": 42231,
                "label": "Steineroth",
                "label_ar": "شتاينروث",
                "label_fr": "Steineroth"
            },
            {
                "value": 42232,
                "label": "Lahrbach",
                "label_ar": "لارباخ",
                "label_fr": "Lahrbach"
            },
            {
                "value": 42233,
                "label": "Jembke",
                "label_ar": "Jembke",
                "label_fr": "Jembke"
            },
            {
                "value": 42234,
                "label": "Wesendorf",
                "label_ar": "ويسندورف",
                "label_fr": "Wesendorf"
            },
            {
                "value": 42235,
                "label": "Beierstedt",
                "label_ar": "بيرستيدت",
                "label_fr": "Beierstedt"
            },
            {
                "value": 42236,
                "label": "Lommatzsch",
                "label_ar": "لوماتزش",
                "label_fr": "Lommatzsch"
            },
            {
                "value": 42237,
                "label": "Weissenborn",
                "label_ar": "فايسنبورن",
                "label_fr": "Weissenborn"
            },
            {
                "value": 42238,
                "label": "Stromberg",
                "label_ar": "سترومبرغ",
                "label_fr": "Stromberg"
            },
            {
                "value": 42239,
                "label": "Hatzfeld",
                "label_ar": "هاتزفيلد",
                "label_fr": "Hatzfeld"
            },
            {
                "value": 42240,
                "label": "Eppenrod",
                "label_ar": "إبينرود",
                "label_fr": "Eppenrod"
            },
            {
                "value": 42241,
                "label": "Thomasburg",
                "label_ar": "توماسبرج",
                "label_fr": "Thomasburg"
            },
            {
                "value": 42242,
                "label": "Schnackenburg",
                "label_ar": "شناكنبورج",
                "label_fr": "Schnackenburg"
            },
            {
                "value": 42243,
                "label": "Gohrde",
                "label_ar": "جوهردي",
                "label_fr": "Gohrde"
            },
            {
                "value": 42244,
                "label": "Soltendieck",
                "label_ar": "Soltendieck",
                "label_fr": "Soltendieck"
            },
            {
                "value": 42245,
                "label": "Schirgiswalde",
                "label_ar": "شيرجيسوالد",
                "label_fr": "Schirgiswalde"
            },
            {
                "value": 42246,
                "label": "Waltersdorf",
                "label_ar": "والترزدورف",
                "label_fr": "Waltersdorf"
            },
            {
                "value": 42247,
                "label": "Crostau",
                "label_ar": "كروستاو",
                "label_fr": "Crostau"
            },
            {
                "value": 42248,
                "label": "Demitz-Thumitz",
                "label_ar": "ديميتز ثوميتز",
                "label_fr": "Demitz-Thumitz"
            },
            {
                "value": 42249,
                "label": "Hainewalde",
                "label_ar": "هينوالد",
                "label_fr": "Hainewalde"
            },
            {
                "value": 42250,
                "label": "Neusalza-Spremberg",
                "label_ar": "نيوسالزا سبرمبرج",
                "label_fr": "Neusalza-Spremberg"
            },
            {
                "value": 42251,
                "label": "Neukirch/Lausitz",
                "label_ar": "نويكيرش / لاوزيتس",
                "label_fr": "Neukirch / Lausitz"
            },
            {
                "value": 42252,
                "label": "Kubschutz",
                "label_ar": "كوبشوتز",
                "label_fr": "Kubschutz"
            },
            {
                "value": 42253,
                "label": "Barenstein",
                "label_ar": "بارنشتاين",
                "label_fr": "Barenstein"
            },
            {
                "value": 42254,
                "label": "Reinsberg",
                "label_ar": "رينسبيرغ",
                "label_fr": "Reinsberg"
            },
            {
                "value": 42255,
                "label": "Oelsnitz",
                "label_ar": "اويلسنيتز",
                "label_fr": "Oelsnitz"
            },
            {
                "value": 42256,
                "label": "Thermalbad Wiesenbad",
                "label_ar": "ثيرمال باد ويسنباد",
                "label_fr": "Thermalbad Wiesenbad"
            },
            {
                "value": 42257,
                "label": "Herbertingen",
                "label_ar": "هيربيرتينغن",
                "label_fr": "Herbertingen"
            },
            {
                "value": 42258,
                "label": "Dirlewang",
                "label_ar": "ديليوانج",
                "label_fr": "Dirlewang"
            },
            {
                "value": 42259,
                "label": "Hinterhermsdorf",
                "label_ar": "هينترهيرمسدورف",
                "label_fr": "Hinterhermsdorf"
            },
            {
                "value": 42260,
                "label": "Neumarkt in der Oberpfalz",
                "label_ar": "Neumarkt in der Oberpfalz",
                "label_fr": "Neumarkt in der Oberpfalz"
            },
            {
                "value": 42261,
                "label": "Dorndorf",
                "label_ar": "دورندورف",
                "label_fr": "Dorndorf"
            },
            {
                "value": 42262,
                "label": "Oberweissbach",
                "label_ar": "أوبيرويسباخ",
                "label_fr": "Oberweissbach"
            },
            {
                "value": 42263,
                "label": "Effelder",
                "label_ar": "ايفيلدر",
                "label_fr": "Effelder"
            },
            {
                "value": 42264,
                "label": "Obergebra",
                "label_ar": "أوبرجبرا",
                "label_fr": "Obergebra"
            },
            {
                "value": 42265,
                "label": "Aspach",
                "label_ar": "اسباش",
                "label_fr": "Aspach"
            },
            {
                "value": 42266,
                "label": "Tiefenort",
                "label_ar": "تيفينورت",
                "label_fr": "Tiefenort"
            },
            {
                "value": 42267,
                "label": "Hohenleuben",
                "label_ar": "Hohenleuben",
                "label_fr": "Hohenleuben"
            },
            {
                "value": 42268,
                "label": "Berga",
                "label_ar": "بيرجا",
                "label_fr": "Berga"
            },
            {
                "value": 42269,
                "label": "Teichwolframsdorf",
                "label_ar": "Teichwolframsdorf",
                "label_fr": "Teichwolframsdorf"
            },
            {
                "value": 42270,
                "label": "Spora",
                "label_ar": "سبورا",
                "label_fr": "Spora"
            },
            {
                "value": 42271,
                "label": "Solkwitz",
                "label_ar": "سولكويتز",
                "label_fr": "Solkwitz"
            },
            {
                "value": 42272,
                "label": "Dobra",
                "label_ar": "دوبرا",
                "label_fr": "Dobra"
            },
            {
                "value": 42273,
                "label": "Gossersweiler-Stein",
                "label_ar": "جوسرسويلر شتاين",
                "label_fr": "Gossersweiler-Stein"
            },
            {
                "value": 42274,
                "label": "Au am Rhein",
                "label_ar": "آم راين",
                "label_fr": "Au am Rhein"
            },
            {
                "value": 42275,
                "label": "Sternberg",
                "label_ar": "ستيرنبرغ",
                "label_fr": "Sternberg"
            },
            {
                "value": 42276,
                "label": "Spielberg",
                "label_ar": "سبيلبرغ",
                "label_fr": "Spielberg"
            },
            {
                "value": 42277,
                "label": "Treben",
                "label_ar": "تريبن",
                "label_fr": "Treben"
            },
            {
                "value": 42278,
                "label": "Seeland",
                "label_ar": "سيلاند",
                "label_fr": "Seeland"
            },
            {
                "value": 42279,
                "label": "Holzweissig",
                "label_ar": "هولزويسيج",
                "label_fr": "Holzweissig"
            },
            {
                "value": 42280,
                "label": "Sandersdorf",
                "label_ar": "ساندرسدورف",
                "label_fr": "Sandersdorf"
            },
            {
                "value": 42281,
                "label": "Wallhausen",
                "label_ar": "والهاوزن",
                "label_fr": "Wallhausen"
            },
            {
                "value": 42282,
                "label": "Zwenkau",
                "label_ar": "زوينكاو",
                "label_fr": "Zwenkau"
            },
            {
                "value": 42283,
                "label": "Kriebstein",
                "label_ar": "كريبستين",
                "label_fr": "Kriebstein"
            },
            {
                "value": 42284,
                "label": "Gruna",
                "label_ar": "جرونا",
                "label_fr": "Gruna"
            },
            {
                "value": 42285,
                "label": "Belgershain",
                "label_ar": "بيلجيرشين",
                "label_fr": "Belgershain"
            },
            {
                "value": 42286,
                "label": "Altenhasslau",
                "label_ar": "التنهاسلاو",
                "label_fr": "Altenhasslau"
            },
            {
                "value": 42287,
                "label": "Salm",
                "label_ar": "سالم",
                "label_fr": "Salm"
            },
            {
                "value": 42288,
                "label": "Garz",
                "label_ar": "جارز",
                "label_fr": "Garz"
            },
            {
                "value": 42289,
                "label": "Wenddorf",
                "label_ar": "Wenddorf",
                "label_fr": "Wenddorf"
            },
            {
                "value": 42290,
                "label": "Kladen",
                "label_ar": "كلادين",
                "label_fr": "Kladen"
            },
            {
                "value": 42291,
                "label": "Jersleben",
                "label_ar": "جيرسليبن",
                "label_fr": "Jersleben"
            },
            {
                "value": 42292,
                "label": "Schwarzholz",
                "label_ar": "شوارتزولز",
                "label_fr": "Schwarzholz"
            },
            {
                "value": 42293,
                "label": "Gerwisch",
                "label_ar": "جيرويش",
                "label_fr": "Gerwisch"
            },
            {
                "value": 42294,
                "label": "Eichstedt",
                "label_ar": "ايشستيدت",
                "label_fr": "Eichstedt"
            },
            {
                "value": 42295,
                "label": "Eichenbarleben",
                "label_ar": "Eichenbarleben",
                "label_fr": "Eichenbarleben"
            },
            {
                "value": 42296,
                "label": "Apenburg",
                "label_ar": "أبينبورج",
                "label_fr": "Apenburg"
            },
            {
                "value": 42297,
                "label": "Niederndodeleben",
                "label_ar": "Niederndodeleben",
                "label_fr": "Niederndodeleben"
            },
            {
                "value": 42298,
                "label": "Seehausen",
                "label_ar": "سيهاوزن",
                "label_fr": "Seehausen"
            },
            {
                "value": 42299,
                "label": "Lenzkirch",
                "label_ar": "لينزكيرش",
                "label_fr": "Lenzkirch"
            },
            {
                "value": 42300,
                "label": "Elzach",
                "label_ar": "الزاك",
                "label_fr": "Elzach"
            },
            {
                "value": 42301,
                "label": "Heimertingen",
                "label_ar": "هيمرتينغن",
                "label_fr": "Heimertingen"
            },
            {
                "value": 42302,
                "label": "Legau",
                "label_ar": "ليغو",
                "label_fr": "Legau"
            },
            {
                "value": 42303,
                "label": "Babenhausen",
                "label_ar": "بابنهاوزن",
                "label_fr": "Babenhausen"
            },
            {
                "value": 42304,
                "label": "Erkheim",
                "label_ar": "إركهايم",
                "label_fr": "Erkheim"
            },
            {
                "value": 42305,
                "label": "Fischen",
                "label_ar": "فيشن",
                "label_fr": "Fischen"
            },
            {
                "value": 42306,
                "label": "Ottersheim",
                "label_ar": "أوترشيم",
                "label_fr": "Ottersheim"
            },
            {
                "value": 42307,
                "label": "Lengdorf",
                "label_ar": "لينجدورف",
                "label_fr": "Lengdorf"
            },
            {
                "value": 42308,
                "label": "Altfraunhofen",
                "label_ar": "التفراونهوفن",
                "label_fr": "Altfraunhofen"
            },
            {
                "value": 42309,
                "label": "Volkenschwand",
                "label_ar": "فولكينشفاند",
                "label_fr": "Volkenschwand"
            },
            {
                "value": 42310,
                "label": "Sankt Wolfgang",
                "label_ar": "سانكت ولفجانج",
                "label_fr": "Sankt Wolfgang"
            },
            {
                "value": 42311,
                "label": "Inning am Holz",
                "label_ar": "إنينغ أم هولز",
                "label_fr": "Inning am Holz"
            },
            {
                "value": 42312,
                "label": "Gunthersleben",
                "label_ar": "غونثرسليبن",
                "label_fr": "Gunthersleben"
            },
            {
                "value": 42313,
                "label": "Wumbach",
                "label_ar": "وامباك",
                "label_fr": "Wumbach"
            },
            {
                "value": 42314,
                "label": "Wiehe",
                "label_ar": "Wiehe",
                "label_fr": "Wiehe"
            },
            {
                "value": 42315,
                "label": "Rauenstein",
                "label_ar": "راونستين",
                "label_fr": "Rauenstein"
            },
            {
                "value": 42316,
                "label": "Rottach",
                "label_ar": "روتاش",
                "label_fr": "Rottach"
            },
            {
                "value": 42317,
                "label": "Kaufering",
                "label_ar": "كوفيرنج",
                "label_fr": "Kaufering"
            },
            {
                "value": 42318,
                "label": "Rohrdorf",
                "label_ar": "روردورف",
                "label_fr": "Rohrdorf"
            },
            {
                "value": 42319,
                "label": "Ettenstatt",
                "label_ar": "إتنستات",
                "label_fr": "Ettenstatt"
            },
            {
                "value": 42320,
                "label": "Kunreuth",
                "label_ar": "كونروث",
                "label_fr": "Kunreuth"
            },
            {
                "value": 42321,
                "label": "Oberscheinfeld",
                "label_ar": "أوبيرشينفيلد",
                "label_fr": "Oberscheinfeld"
            },
            {
                "value": 42322,
                "label": "Munchsteinach",
                "label_ar": "مونشستيناخ",
                "label_fr": "Munchsteinach"
            },
            {
                "value": 42323,
                "label": "Hiltrup",
                "label_ar": "هيلتراب",
                "label_fr": "Hiltrup"
            },
            {
                "value": 42324,
                "label": "Ladbergen",
                "label_ar": "لادبيرجين",
                "label_fr": "Ladbergen"
            },
            {
                "value": 42325,
                "label": "Wettringen",
                "label_ar": "ويترينجين",
                "label_fr": "Wettringen"
            },
            {
                "value": 42326,
                "label": "Untersiemau",
                "label_ar": "أونتيرزيماو",
                "label_fr": "Untersiemau"
            },
            {
                "value": 42327,
                "label": "Nennslingen",
                "label_ar": "نينسلينجن",
                "label_fr": "Nennslingen"
            },
            {
                "value": 42328,
                "label": "Ebensfeld",
                "label_ar": "إيبنسفيلد",
                "label_fr": "Ebensfeld"
            },
            {
                "value": 42329,
                "label": "Wettelsheim",
                "label_ar": "Wettelsheim",
                "label_fr": "Wettelsheim"
            },
            {
                "value": 42330,
                "label": "Obernzenn",
                "label_ar": "اوبرنزين",
                "label_fr": "Obernzenn"
            },
            {
                "value": 42331,
                "label": "Dorfprozelten",
                "label_ar": "دورفبروزيلتن",
                "label_fr": "Dorfprozelten"
            },
            {
                "value": 42332,
                "label": "Schneppenbach",
                "label_ar": "شنيبنباخ",
                "label_fr": "Schneppenbach"
            },
            {
                "value": 42333,
                "label": "Oerlenbach",
                "label_ar": "أورلينباخ",
                "label_fr": "Oerlenbach"
            },
            {
                "value": 42334,
                "label": "Falls",
                "label_ar": "السقوط",
                "label_fr": "Des chutes"
            },
            {
                "value": 42335,
                "label": "Weilbach",
                "label_ar": "ويلباخ",
                "label_fr": "Weilbach"
            },
            {
                "value": 42336,
                "label": "Billingshausen",
                "label_ar": "بيلينجسهاوزن",
                "label_fr": "Billingshausen"
            },
            {
                "value": 42337,
                "label": "Ermershausen",
                "label_ar": "ارمرسهاوزن",
                "label_fr": "Ermershausen"
            },
            {
                "value": 42338,
                "label": "Erdweg",
                "label_ar": "إردويج",
                "label_fr": "Erdweg"
            },
            {
                "value": 42339,
                "label": "Neuburg",
                "label_ar": "نيوبورج",
                "label_fr": "Neubourg"
            },
            {
                "value": 42340,
                "label": "Bubesheim",
                "label_ar": "Bubesheim",
                "label_fr": "Bubesheim"
            },
            {
                "value": 42341,
                "label": "Rofingen",
                "label_ar": "روفينغن",
                "label_fr": "Rofingen"
            },
            {
                "value": 42342,
                "label": "Biberbach",
                "label_ar": "بيبرباخ",
                "label_fr": "Biberbach"
            },
            {
                "value": 42343,
                "label": "Todtnauberg",
                "label_ar": "تودتناوبيرج",
                "label_fr": "Todtnauberg"
            },
            {
                "value": 42344,
                "label": "Hochenschwand",
                "label_ar": "هوشينشفاند",
                "label_fr": "Hochenschwand"
            },
            {
                "value": 42345,
                "label": "Bad Bellingen",
                "label_ar": "باد بيلينجين",
                "label_fr": "Bad Bellingen"
            },
            {
                "value": 42346,
                "label": "Eichstetten",
                "label_ar": "ايشستيتن",
                "label_fr": "Eichstetten"
            },
            {
                "value": 42347,
                "label": "Todtnau",
                "label_ar": "تودتناو",
                "label_fr": "Todtnau"
            },
            {
                "value": 42348,
                "label": "Gutach im Breisgau",
                "label_ar": "جوتاش إم بريسغاو",
                "label_fr": "Gutach im Breisgau"
            },
            {
                "value": 42349,
                "label": "Bamlach",
                "label_ar": "باملاش",
                "label_fr": "Bamlach"
            },
            {
                "value": 42350,
                "label": "Morscheid",
                "label_ar": "مرشيد",
                "label_fr": "Morscheid"
            },
            {
                "value": 42351,
                "label": "Nittel",
                "label_ar": "نيتل",
                "label_fr": "Nittel"
            },
            {
                "value": 42352,
                "label": "Lissendorf",
                "label_ar": "ليسندورف",
                "label_fr": "Lissendorf"
            },
            {
                "value": 42353,
                "label": "Bous",
                "label_ar": "بوس",
                "label_fr": "Bous"
            },
            {
                "value": 42354,
                "label": "Rodenbach",
                "label_ar": "رودنباخ",
                "label_fr": "Rodenbach"
            },
            {
                "value": 42355,
                "label": "Mackenbach",
                "label_ar": "ماكنباخ",
                "label_fr": "Mackenbach"
            },
            {
                "value": 42356,
                "label": "Rutsweiler an der Lauter",
                "label_ar": "روتسويلر أن دير لوتر",
                "label_fr": "Rutsweiler an der Lauter"
            },
            {
                "value": 42357,
                "label": "Schwedelbach",
                "label_ar": "شفيديلباخ",
                "label_fr": "Schwedelbach"
            },
            {
                "value": 42358,
                "label": "Gehrweiler",
                "label_ar": "جيرفايلر",
                "label_fr": "Gehrweiler"
            },
            {
                "value": 42359,
                "label": "Steinwenden",
                "label_ar": "شتاينويندن",
                "label_fr": "Steinwenden"
            },
            {
                "value": 42360,
                "label": "Obermohr",
                "label_ar": "أوبرمر",
                "label_fr": "Obermohr"
            },
            {
                "value": 42361,
                "label": "Herschberg",
                "label_ar": "هيرشبيرج",
                "label_fr": "Herschberg"
            },
            {
                "value": 42362,
                "label": "Niederwuerschnitz",
                "label_ar": "Niederwuerschnitz",
                "label_fr": "Niederwuerschnitz"
            },
            {
                "value": 42363,
                "label": "Geyer",
                "label_ar": "جيير",
                "label_fr": "Geyer"
            },
            {
                "value": 42364,
                "label": "Wachtum",
                "label_ar": "واتشتوم",
                "label_fr": "Wachtum"
            },
            {
                "value": 42365,
                "label": "Brockum",
                "label_ar": "بروكوم",
                "label_fr": "Brockum"
            },
            {
                "value": 42366,
                "label": "Ellrich",
                "label_ar": "إلريتش",
                "label_fr": "Ellrich"
            },
            {
                "value": 42367,
                "label": "Schnaitsee",
                "label_ar": "شنيتسي",
                "label_fr": "Schnaitsee"
            },
            {
                "value": 42368,
                "label": "Jesendorf",
                "label_ar": "جيسيندورف",
                "label_fr": "Jesendorf"
            },
            {
                "value": 42369,
                "label": "Marzling",
                "label_ar": "مرزلنج",
                "label_fr": "Marzling"
            },
            {
                "value": 42370,
                "label": "Freimersheim",
                "label_ar": "Freimersheim",
                "label_fr": "Freimersheim"
            },
            {
                "value": 42371,
                "label": "Mauer",
                "label_ar": "ماور",
                "label_fr": "Mauer"
            },
            {
                "value": 42372,
                "label": "Schonau",
                "label_ar": "شوناو",
                "label_fr": "Schonau"
            },
            {
                "value": 42373,
                "label": "Sasbach",
                "label_ar": "ساسباخ",
                "label_fr": "Sasbach"
            },
            {
                "value": 42374,
                "label": "Sasbachwalden",
                "label_ar": "ساسباشفالدن",
                "label_fr": "Sasbachwalden"
            },
            {
                "value": 42375,
                "label": "Tiddische",
                "label_ar": "تيديشي",
                "label_fr": "Tiddische"
            },
            {
                "value": 42376,
                "label": "Lahm",
                "label_ar": "لام",
                "label_fr": "Lahm"
            },
            {
                "value": 42377,
                "label": "Oberleichtersbach",
                "label_ar": "Oberleichtersbach",
                "label_fr": "Oberleichtersbach"
            },
            {
                "value": 42378,
                "label": "Erlenbach bei Marktheidenfeld",
                "label_ar": "إرلينباخ باي ماركثيدينفيلد",
                "label_fr": "Erlenbach bei Marktheidenfeld"
            },
            {
                "value": 42379,
                "label": "Kolbingen",
                "label_ar": "كولبنجن",
                "label_fr": "Kolbingen"
            },
            {
                "value": 42380,
                "label": "Scheer",
                "label_ar": "شير",
                "label_fr": "Scheer"
            },
            {
                "value": 42381,
                "label": "Marksuhl",
                "label_ar": "ماركسوهل",
                "label_fr": "Marksuhl"
            },
            {
                "value": 42382,
                "label": "Stutzerbach",
                "label_ar": "شتوتزيرباخ",
                "label_fr": "Stutzerbach"
            },
            {
                "value": 42383,
                "label": "Ohlstadt",
                "label_ar": "Ohlstadt",
                "label_fr": "Ohlstadt"
            },
            {
                "value": 42384,
                "label": "Burgheim",
                "label_ar": "بورغهايم",
                "label_fr": "Burgheim"
            },
            {
                "value": 42385,
                "label": "Elm",
                "label_ar": "الدردار",
                "label_fr": "Orme"
            },
            {
                "value": 42386,
                "label": "Donsieders",
                "label_ar": "Donsieders",
                "label_fr": "Donsieders"
            },
            {
                "value": 42387,
                "label": "Schmelz",
                "label_ar": "شميلز",
                "label_fr": "Schmelz"
            },
            {
                "value": 42388,
                "label": "Uberherrn",
                "label_ar": "اوبرهيرن",
                "label_fr": "Uberherrn"
            },
            {
                "value": 42389,
                "label": "Walhausen",
                "label_ar": "والهاوزن",
                "label_fr": "Walhausen"
            },
            {
                "value": 42390,
                "label": "Wiedergeltingen",
                "label_ar": "Wiedergeltingen",
                "label_fr": "Wiedergeltingen"
            },
            {
                "value": 42391,
                "label": "Ruderatshofen",
                "label_ar": "روديراتسهوفن",
                "label_fr": "Ruderatshofen"
            },
            {
                "value": 42392,
                "label": "Auenstein",
                "label_ar": "أوينشتاين",
                "label_fr": "Auenstein"
            },
            {
                "value": 42393,
                "label": "Weissbach",
                "label_ar": "فايسباخ",
                "label_fr": "Weissbach"
            },
            {
                "value": 42394,
                "label": "Michelbach an der Bilz",
                "label_ar": "ميشيلباخ أن دير بيلز",
                "label_fr": "Michelbach an der Bilz"
            },
            {
                "value": 42395,
                "label": "Wimsheim",
                "label_ar": "ويمشيم",
                "label_fr": "Wimsheim"
            },
            {
                "value": 42396,
                "label": "Ottrau",
                "label_ar": "أوتراو",
                "label_fr": "Ottrau"
            },
            {
                "value": 42397,
                "label": "Wallhausen",
                "label_ar": "والهاوزن",
                "label_fr": "Wallhausen"
            },
            {
                "value": 42398,
                "label": "Schwaigern",
                "label_ar": "شفايجرن",
                "label_fr": "Schwaigern"
            },
            {
                "value": 42399,
                "label": "Baindt",
                "label_ar": "بيندت",
                "label_fr": "Baindt"
            },
            {
                "value": 42400,
                "label": "Gutenzell-Hurbel",
                "label_ar": "جوتنزيل هربيل",
                "label_fr": "Gutenzell-Hurbel"
            },
            {
                "value": 42401,
                "label": "Deckenpfronn",
                "label_ar": "ديكينبفرون",
                "label_fr": "Deckenpfronn"
            },
            {
                "value": 42402,
                "label": "Bempflingen",
                "label_ar": "بيمبفلينجن",
                "label_fr": "Bempflingen"
            },
            {
                "value": 42403,
                "label": "Bahrdorf",
                "label_ar": "باردورف",
                "label_fr": "Bahrdorf"
            },
            {
                "value": 42404,
                "label": "Oldenburg in Holstein",
                "label_ar": "أولدنبورغ في هولشتاين",
                "label_fr": "Oldenburg en Holstein"
            },
            {
                "value": 42405,
                "label": "Reutlingendorf",
                "label_ar": "ريوتلنغندورف",
                "label_fr": "Reutlingendorf"
            },
            {
                "value": 42406,
                "label": "Oberdischingen",
                "label_ar": "Oberdischingen",
                "label_fr": "Oberdischingen"
            },
            {
                "value": 42407,
                "label": "Ertingen",
                "label_ar": "ارتينجن",
                "label_fr": "Ertingen"
            },
            {
                "value": 42408,
                "label": "Schaulings",
                "label_ar": "شولينج",
                "label_fr": "Schaulings"
            },
            {
                "value": 42409,
                "label": "Neuravensburg",
                "label_ar": "نيرافينسبورج",
                "label_fr": "Neuravensburg"
            },
            {
                "value": 42410,
                "label": "Bortlingen",
                "label_ar": "بورتلينجن",
                "label_fr": "Bortlingen"
            },
            {
                "value": 42411,
                "label": "Ersingen",
                "label_ar": "ارسينجين",
                "label_fr": "Ersingen"
            },
            {
                "value": 42412,
                "label": "Bellamont",
                "label_ar": "بيلامونت",
                "label_fr": "Bellamont"
            },
            {
                "value": 42413,
                "label": "Veltheim",
                "label_ar": "فيلتهايم",
                "label_fr": "Veltheim"
            },
            {
                "value": 42414,
                "label": "Sohlde",
                "label_ar": "Sohlde",
                "label_fr": "Sohlde"
            },
            {
                "value": 42415,
                "label": "Ramsla",
                "label_ar": "رمسلا",
                "label_fr": "Ramsla"
            },
            {
                "value": 42416,
                "label": "Oldisleben",
                "label_ar": "Oldisleben",
                "label_fr": "Oldisleben"
            },
            {
                "value": 42417,
                "label": "Wustheuterode",
                "label_ar": "Wustheuterode",
                "label_fr": "Wustheuterode"
            },
            {
                "value": 42418,
                "label": "Frankenheim",
                "label_ar": "فرانكنهايم",
                "label_fr": "Frankenheim"
            },
            {
                "value": 42419,
                "label": "Kaltennordheim",
                "label_ar": "كالتينوردهايم",
                "label_fr": "Kaltennordheim"
            },
            {
                "value": 42420,
                "label": "Grosswechsungen",
                "label_ar": "Grosswechsungen",
                "label_fr": "Grosswechsungen"
            },
            {
                "value": 42421,
                "label": "Sattelstadt",
                "label_ar": "ساتيلشتات",
                "label_fr": "Sattelstadt"
            },
            {
                "value": 42422,
                "label": "Dermbach",
                "label_ar": "ديرمباخ",
                "label_fr": "Dermbach"
            },
            {
                "value": 42423,
                "label": "Empfertshausen",
                "label_ar": "إمبفيرتسهاوزن",
                "label_fr": "Empfertshausen"
            },
            {
                "value": 42424,
                "label": "Lengerich",
                "label_ar": "Lengerich",
                "label_fr": "Lengerich"
            },
            {
                "value": 42425,
                "label": "Geeste",
                "label_ar": "جيستي",
                "label_fr": "Geeste"
            },
            {
                "value": 42426,
                "label": "Obristfeld",
                "label_ar": "أوبريستفيلد",
                "label_fr": "Obristfeld"
            },
            {
                "value": 42427,
                "label": "Oberhaid",
                "label_ar": "أوبرهيد",
                "label_fr": "Oberhaid"
            },
            {
                "value": 42428,
                "label": "Wassermungenau",
                "label_ar": "Wassermungenau",
                "label_fr": "Wassermungenau"
            },
            {
                "value": 42429,
                "label": "Schesslitz",
                "label_ar": "شيسليتز",
                "label_fr": "Schesslitz"
            },
            {
                "value": 42430,
                "label": "Pappenheim",
                "label_ar": "بابنهايم",
                "label_fr": "Pappenheim"
            },
            {
                "value": 42431,
                "label": "Scheinfeld",
                "label_ar": "شينفيلد",
                "label_fr": "Scheinfeld"
            },
            {
                "value": 42432,
                "label": "Michelau",
                "label_ar": "ميشيلاو",
                "label_fr": "Michelau"
            },
            {
                "value": 42433,
                "label": "Marktgraitz",
                "label_ar": "Marktgraitz",
                "label_fr": "Marktgraitz"
            },
            {
                "value": 42434,
                "label": "Ebersdorf",
                "label_ar": "إبيرسدورف",
                "label_fr": "Ebersdorf"
            },
            {
                "value": 42435,
                "label": "Bockelwitz",
                "label_ar": "بوكيلويتز",
                "label_fr": "Bockelwitz"
            },
            {
                "value": 42436,
                "label": "Alterhofen",
                "label_ar": "التيرهوفن",
                "label_fr": "Alterhofen"
            },
            {
                "value": 42437,
                "label": "Mamming",
                "label_ar": "مامينج",
                "label_fr": "Mamming"
            },
            {
                "value": 42438,
                "label": "Kunzing",
                "label_ar": "كونزينغ",
                "label_fr": "Kunzing"
            },
            {
                "value": 42439,
                "label": "Baltmannsweiler",
                "label_ar": "بالتماننسويلر",
                "label_fr": "Baltmannsweiler"
            },
            {
                "value": 42440,
                "label": "Bedburdyck",
                "label_ar": "بدبيرديك",
                "label_fr": "Bedburdyck"
            },
            {
                "value": 42441,
                "label": "Gladbach",
                "label_ar": "جلادباخ",
                "label_fr": "Gladbach"
            },
            {
                "value": 42442,
                "label": "Hohn",
                "label_ar": "هون",
                "label_fr": "Hohn"
            },
            {
                "value": 42443,
                "label": "Eddelak",
                "label_ar": "إدلاك",
                "label_fr": "Eddelak"
            },
            {
                "value": 42444,
                "label": "Herforst",
                "label_ar": "هيرفورست",
                "label_fr": "Herforst"
            },
            {
                "value": 42445,
                "label": "Langweiler",
                "label_ar": "لانغويلر",
                "label_fr": "Langweiler"
            },
            {
                "value": 42446,
                "label": "Nattenheim",
                "label_ar": "ناتنهايم",
                "label_fr": "Nattenheim"
            },
            {
                "value": 42447,
                "label": "Bollendorf",
                "label_ar": "بوليندورف",
                "label_fr": "Bollendorf"
            },
            {
                "value": 42448,
                "label": "Welschbillig",
                "label_ar": "ويلشبيليج",
                "label_fr": "Welschbillig"
            },
            {
                "value": 42449,
                "label": "Karlshausen",
                "label_ar": "كارلسهاوزن",
                "label_fr": "Karlshausen"
            },
            {
                "value": 42450,
                "label": "Piesport",
                "label_ar": "بيسبورت",
                "label_fr": "Piesport"
            },
            {
                "value": 42451,
                "label": "Breit",
                "label_ar": "بريت",
                "label_fr": "Breit"
            },
            {
                "value": 42452,
                "label": "Wiltingen",
                "label_ar": "ويلتينجن",
                "label_fr": "Wiltingen"
            },
            {
                "value": 42453,
                "label": "Hetzerath",
                "label_ar": "Hetzerath",
                "label_fr": "Hetzerath"
            },
            {
                "value": 42454,
                "label": "Metterich",
                "label_ar": "ميتيريش",
                "label_fr": "Metterich"
            },
            {
                "value": 42455,
                "label": "Krov",
                "label_ar": "كروف",
                "label_fr": "Krov"
            },
            {
                "value": 42456,
                "label": "Wistedt",
                "label_ar": "ويست",
                "label_fr": "Wistedt"
            },
            {
                "value": 42457,
                "label": "Geversdorf",
                "label_ar": "جيفرسدورف",
                "label_fr": "Geversdorf"
            },
            {
                "value": 42458,
                "label": "Handeloh",
                "label_ar": "هاندلوه",
                "label_fr": "Handeloh"
            },
            {
                "value": 42459,
                "label": "Arlaching",
                "label_ar": "ارلاشينج",
                "label_fr": "Arlaching"
            },
            {
                "value": 42460,
                "label": "Reischach",
                "label_ar": "ريشاخ",
                "label_fr": "Reischach"
            },
            {
                "value": 42461,
                "label": "Aindorf",
                "label_ar": "أيندورف",
                "label_fr": "Aindorf"
            },
            {
                "value": 42462,
                "label": "Halbe",
                "label_ar": "هالبي",
                "label_fr": "Halbe"
            },
            {
                "value": 42463,
                "label": "Senzig",
                "label_ar": "سنزيغ",
                "label_fr": "Senzig"
            },
            {
                "value": 42464,
                "label": "Gersfeld",
                "label_ar": "غيرسفيلد",
                "label_fr": "Gersfeld"
            },
            {
                "value": 42465,
                "label": "Hochkirch",
                "label_ar": "Hochkirch",
                "label_fr": "Hochkirch"
            },
            {
                "value": 42466,
                "label": "Gelting",
                "label_ar": "الهلام",
                "label_fr": "Gelting"
            },
            {
                "value": 42467,
                "label": "Bockhorn",
                "label_ar": "بوكهورن",
                "label_fr": "Bockhorn"
            },
            {
                "value": 42468,
                "label": "Kloster",
                "label_ar": "كلوستر",
                "label_fr": "Kloster"
            },
            {
                "value": 42469,
                "label": "Ferchesar",
                "label_ar": "فيرتشسار",
                "label_fr": "Ferchesar"
            },
            {
                "value": 42470,
                "label": "Ketzin",
                "label_ar": "كيتزين",
                "label_fr": "Ketzin"
            },
            {
                "value": 42471,
                "label": "Heinsdorf",
                "label_ar": "هاينزدورف",
                "label_fr": "Heinsdorf"
            },
            {
                "value": 42472,
                "label": "Ermke",
                "label_ar": "ارمكي",
                "label_fr": "Ermke"
            },
            {
                "value": 42473,
                "label": "Strucklingen",
                "label_ar": "ستراكلينجن",
                "label_fr": "Strucklingen"
            },
            {
                "value": 42474,
                "label": "Gross Koris",
                "label_ar": "جروس كوريس",
                "label_fr": "Gross Koris"
            },
            {
                "value": 42475,
                "label": "Oberkirchen",
                "label_ar": "Oberkirchen",
                "label_fr": "Oberkirchen"
            },
            {
                "value": 42476,
                "label": "Plech",
                "label_ar": "بلتش",
                "label_fr": "Plech"
            },
            {
                "value": 42477,
                "label": "Schonsee",
                "label_ar": "شونسي",
                "label_fr": "Schonsee"
            },
            {
                "value": 42478,
                "label": "Marktschorgast",
                "label_ar": "ماركتشورغاست",
                "label_fr": "Marktschorgast"
            },
            {
                "value": 42479,
                "label": "Kupferberg",
                "label_ar": "كوبفيربيرج",
                "label_fr": "Kupferberg"
            },
            {
                "value": 42480,
                "label": "Lubstorf",
                "label_ar": "لوبستورف",
                "label_fr": "Lubstorf"
            },
            {
                "value": 42481,
                "label": "Passow",
                "label_ar": "باسو",
                "label_fr": "Passow"
            },
            {
                "value": 42482,
                "label": "Dabel",
                "label_ar": "دابل",
                "label_fr": "Dabel"
            },
            {
                "value": 42483,
                "label": "Carlow",
                "label_ar": "كارلو",
                "label_fr": "Carlow"
            },
            {
                "value": 42484,
                "label": "Pinnow",
                "label_ar": "بينو",
                "label_fr": "Pinnow"
            },
            {
                "value": 42485,
                "label": "Aumuhle",
                "label_ar": "أوموله",
                "label_fr": "Aumuhle"
            },
            {
                "value": 42486,
                "label": "Wusterhusen",
                "label_ar": "Wusterhusen",
                "label_fr": "Wusterhusen"
            },
            {
                "value": 42487,
                "label": "Kropelin",
                "label_ar": "كروبلين",
                "label_fr": "Kropelin"
            },
            {
                "value": 42488,
                "label": "Ostseebad Prerow",
                "label_ar": "Ostseebad Prerow",
                "label_fr": "Ostseebad Prerow"
            },
            {
                "value": 42489,
                "label": "Ziesendorf",
                "label_ar": "زيسندورف",
                "label_fr": "Ziesendorf"
            },
            {
                "value": 42490,
                "label": "Gelbensande",
                "label_ar": "جيلبينساندي",
                "label_fr": "Gelbensande"
            },
            {
                "value": 42491,
                "label": "Breesen",
                "label_ar": "بريسين",
                "label_fr": "Breesen"
            },
            {
                "value": 42492,
                "label": "Rhumspringe",
                "label_ar": "Rhumspringe",
                "label_fr": "Rhumspringe"
            },
            {
                "value": 42493,
                "label": "Muldenstein",
                "label_ar": "مولدنشتاين",
                "label_fr": "Muldenstein"
            },
            {
                "value": 42494,
                "label": "Farnroda",
                "label_ar": "فارنرودا",
                "label_fr": "Farnroda"
            },
            {
                "value": 42495,
                "label": "Breitenbach",
                "label_ar": "بريتينباخ",
                "label_fr": "Breitenbach"
            },
            {
                "value": 42496,
                "label": "Eisfeld",
                "label_ar": "إيسفيلد",
                "label_fr": "Eisfeld"
            },
            {
                "value": 42497,
                "label": "Willmersdorf",
                "label_ar": "ويلميرسدورف",
                "label_fr": "Willmersdorf"
            },
            {
                "value": 42498,
                "label": "Schwansee",
                "label_ar": "شوانسي",
                "label_fr": "Schwansee"
            },
            {
                "value": 42499,
                "label": "Stepfershausen",
                "label_ar": "ستيففرشاوسين",
                "label_fr": "Stepfershausen"
            },
            {
                "value": 42500,
                "label": "Wilhelmsthal",
                "label_ar": "فيلهلمستال",
                "label_fr": "Wilhelmsthal"
            },
            {
                "value": 42501,
                "label": "Altstadt",
                "label_ar": "ألتشتات",
                "label_fr": "Altstadt"
            },
            {
                "value": 42502,
                "label": "Weissen",
                "label_ar": "فايسن",
                "label_fr": "Weissen"
            },
            {
                "value": 42503,
                "label": "Spiegelsberge",
                "label_ar": "Spiegelsberge",
                "label_fr": "Spiegelsberge"
            },
            {
                "value": 42504,
                "label": "Rosengarten",
                "label_ar": "روزنغارتن",
                "label_fr": "Rosengarten"
            },
            {
                "value": 42505,
                "label": "Hofgarten",
                "label_ar": "هوفجارتن",
                "label_fr": "Hofgarten"
            },
            {
                "value": 42506,
                "label": "Deutsches Haus",
                "label_ar": "دويتشيز هاوس",
                "label_fr": "Deutsches Haus"
            },
            {
                "value": 42507,
                "label": "Tonbach",
                "label_ar": "تونباخ",
                "label_fr": "Tonbach"
            },
            {
                "value": 42508,
                "label": "Diana",
                "label_ar": "ديانا",
                "label_fr": "Diane"
            },
            {
                "value": 42509,
                "label": "Dunnwald",
                "label_ar": "دنوالد",
                "label_fr": "Dunnwald"
            },
            {
                "value": 42510,
                "label": "Ernst",
                "label_ar": "إرنست",
                "label_fr": "Ernst"
            },
            {
                "value": 42511,
                "label": "Linde",
                "label_ar": "ليندي",
                "label_fr": "Linde"
            },
            {
                "value": 42512,
                "label": "Haverkamp",
                "label_ar": "هافركامب",
                "label_fr": "Haverkamp"
            },
            {
                "value": 42513,
                "label": "Bad Godesberg",
                "label_ar": "باد جوديسبيرج",
                "label_fr": "Bad Godesberg"
            },
            {
                "value": 42514,
                "label": "Lausitz",
                "label_ar": "لاوزيتس",
                "label_fr": "Lausitz"
            },
            {
                "value": 42515,
                "label": "Oberwinter",
                "label_ar": "أوبروينتر",
                "label_fr": "Oberwinter"
            },
            {
                "value": 42516,
                "label": "Wittekindshof",
                "label_ar": "Wittekindshof",
                "label_fr": "Wittekindshof"
            },
            {
                "value": 42517,
                "label": "Lehen",
                "label_ar": "لين",
                "label_fr": "Lehen"
            },
            {
                "value": 42518,
                "label": "Bredeney",
                "label_ar": "بريديني",
                "label_fr": "Bredeney"
            },
            {
                "value": 42519,
                "label": "Allee",
                "label_ar": "ألي",
                "label_fr": "Allée"
            },
            {
                "value": 42520,
                "label": "Konigsdorf",
                "label_ar": "كونيجسدورف",
                "label_fr": "Königsdorf"
            },
            {
                "value": 42521,
                "label": "Schaumburg",
                "label_ar": "شاومبورغ",
                "label_fr": "Schaumburg"
            },
            {
                "value": 42522,
                "label": "Rodinghausen",
                "label_ar": "رودينغهاوزن",
                "label_fr": "Rodinghausen"
            },
            {
                "value": 42523,
                "label": "Eimeldingen",
                "label_ar": "إيملدين",
                "label_fr": "Eimeldingen"
            },
            {
                "value": 42524,
                "label": "Nesselwang",
                "label_ar": "نيسيلوانج",
                "label_fr": "Nesselwang"
            },
            {
                "value": 42525,
                "label": "Sussen",
                "label_ar": "سوسن",
                "label_fr": "Sussen"
            },
            {
                "value": 42526,
                "label": "Mittenwald",
                "label_ar": "ميتنوالد",
                "label_fr": "Mittenwald"
            },
            {
                "value": 42527,
                "label": "Mammendorf",
                "label_ar": "مامندورف",
                "label_fr": "Mammendorf"
            },
            {
                "value": 42528,
                "label": "Wustenriet",
                "label_ar": "Wustenriet",
                "label_fr": "Wustenriet"
            },
            {
                "value": 42529,
                "label": "Rudersdorf",
                "label_ar": "رودرسدورف",
                "label_fr": "Rudersdorf"
            },
            {
                "value": 42530,
                "label": "Munchsmunster",
                "label_ar": "مونشسمونستر",
                "label_fr": "Munchsmunster"
            },
            {
                "value": 42531,
                "label": "Traunwalchen",
                "label_ar": "تراونوالشين",
                "label_fr": "Traunwalchen"
            },
            {
                "value": 42532,
                "label": "Weichs",
                "label_ar": "ويتش",
                "label_fr": "Weichs"
            },
            {
                "value": 42533,
                "label": "Wangen",
                "label_ar": "فانجين",
                "label_fr": "Wangen"
            },
            {
                "value": 42534,
                "label": "Wasserlosen",
                "label_ar": "Wasserlosen",
                "label_fr": "Wasserlosen"
            },
            {
                "value": 42535,
                "label": "Hohenberg",
                "label_ar": "هوهنبرج",
                "label_fr": "Hohenberg"
            },
            {
                "value": 42536,
                "label": "Rosenthal",
                "label_ar": "روزنتال",
                "label_fr": "Rosenthal"
            },
            {
                "value": 42537,
                "label": "Ellerbek",
                "label_ar": "إليربك",
                "label_fr": "Ellerbek"
            },
            {
                "value": 42538,
                "label": "Velpke",
                "label_ar": "فيلبكي",
                "label_fr": "Velpke"
            },
            {
                "value": 42539,
                "label": "Rosendahl",
                "label_ar": "روزندال",
                "label_fr": "Rosendahl"
            },
            {
                "value": 42540,
                "label": "Hohenhameln",
                "label_ar": "هوهنهاميلن",
                "label_fr": "Hohenhameln"
            },
            {
                "value": 42541,
                "label": "Oppurg",
                "label_ar": "أوبيرج",
                "label_fr": "Oppurg"
            },
            {
                "value": 42542,
                "label": "Pauscha",
                "label_ar": "Pauscha",
                "label_fr": "Pauscha"
            },
            {
                "value": 42543,
                "label": "Lalendorf",
                "label_ar": "لاليندورف",
                "label_fr": "Lalendorf"
            },
            {
                "value": 42544,
                "label": "Rastow",
                "label_ar": "راستو",
                "label_fr": "Rastow"
            },
            {
                "value": 42545,
                "label": "Funfeichen",
                "label_ar": "Funfeichen",
                "label_fr": "Funfeichen"
            },
            {
                "value": 42546,
                "label": "Harra",
                "label_ar": "حراء",
                "label_fr": "Harra"
            },
            {
                "value": 42547,
                "label": "Grossharthau",
                "label_ar": "غروشارتاو",
                "label_fr": "Grossharthau"
            },
            {
                "value": 42548,
                "label": "Rauda",
                "label_ar": "روضة",
                "label_fr": "Rauda"
            },
            {
                "value": 42549,
                "label": "Plau am See",
                "label_ar": "بلاو ام سي",
                "label_fr": "Plau am See"
            },
            {
                "value": 42550,
                "label": "Goldewin",
                "label_ar": "غولدوين",
                "label_fr": "Goldewin"
            },
            {
                "value": 42551,
                "label": "Neuhausel",
                "label_ar": "نيوهاوسيل",
                "label_fr": "Neuhausel"
            },
            {
                "value": 42552,
                "label": "Weiden",
                "label_ar": "Weiden",
                "label_fr": "Weiden"
            },
            {
                "value": 42553,
                "label": "Kirchsahr",
                "label_ar": "كيرششار",
                "label_fr": "Kirchsahr"
            },
            {
                "value": 42554,
                "label": "Altenahr",
                "label_ar": "التينهر",
                "label_fr": "Altenahr"
            },
            {
                "value": 42555,
                "label": "Gross Twulpstedt",
                "label_ar": "إجمالي Twulpstedt",
                "label_fr": "Twulpstedt brut"
            },
            {
                "value": 42556,
                "label": "Weiden",
                "label_ar": "Weiden",
                "label_fr": "Weiden"
            },
            {
                "value": 42557,
                "label": "Altheim",
                "label_ar": "ألتهايم",
                "label_fr": "Altheim"
            },
            {
                "value": 42558,
                "label": "Unterreit",
                "label_ar": "أونتيريت",
                "label_fr": "Unterreit"
            },
            {
                "value": 42559,
                "label": "Buch am Erlbach",
                "label_ar": "بوخ آم إرلباخ",
                "label_fr": "Buch am Erlbach"
            },
            {
                "value": 42560,
                "label": "Saldenburg",
                "label_ar": "سالدينبورغ",
                "label_fr": "Saldenburg"
            },
            {
                "value": 42561,
                "label": "Innernzell",
                "label_ar": "انرنزيل",
                "label_fr": "Innernzell"
            },
            {
                "value": 42562,
                "label": "Thannberg",
                "label_ar": "ثانبرج",
                "label_fr": "Thannberg"
            },
            {
                "value": 42563,
                "label": "Ruckeroth",
                "label_ar": "روكروث",
                "label_fr": "Ruckeroth"
            },
            {
                "value": 42564,
                "label": "Breitenau",
                "label_ar": "بريتيناو",
                "label_fr": "Breitenau"
            },
            {
                "value": 42565,
                "label": "Vielbach",
                "label_ar": "فيلباخ",
                "label_fr": "Vielbach"
            },
            {
                "value": 42566,
                "label": "Nordhofen",
                "label_ar": "نوردهوفن",
                "label_fr": "Nordhofen"
            },
            {
                "value": 42567,
                "label": "Rentrisch",
                "label_ar": "رينتريش",
                "label_fr": "Rentrisch"
            },
            {
                "value": 42568,
                "label": "Otterstadt",
                "label_ar": "Otterstadt",
                "label_fr": "Otterstadt"
            },
            {
                "value": 42569,
                "label": "Polch",
                "label_ar": "بولش",
                "label_fr": "Polch"
            },
            {
                "value": 42570,
                "label": "Monreal",
                "label_ar": "مونريال",
                "label_fr": "Monreal"
            },
            {
                "value": 42571,
                "label": "Wackernheim",
                "label_ar": "واكرنهايم",
                "label_fr": "Wackernheim"
            },
            {
                "value": 42572,
                "label": "Marnheim",
                "label_ar": "مارنهايم",
                "label_fr": "Marnheim"
            },
            {
                "value": 42573,
                "label": "Freirachdorf",
                "label_ar": "فرايراشدورف",
                "label_fr": "Freirachdorf"
            },
            {
                "value": 42574,
                "label": "Oberthal",
                "label_ar": "اوبرثال",
                "label_fr": "Oberthal"
            },
            {
                "value": 42575,
                "label": "Hanhofen",
                "label_ar": "هانهوفن",
                "label_fr": "Hanhofen"
            },
            {
                "value": 42576,
                "label": "Wittgert",
                "label_ar": "ويتجيرت",
                "label_fr": "Wittgert"
            },
            {
                "value": 42577,
                "label": "Stadecken-Elsheim",
                "label_ar": "Stadecken-Elsheim",
                "label_fr": "Stadecken-Elsheim"
            },
            {
                "value": 42578,
                "label": "Fusternberg",
                "label_ar": "فوسترنبرغ",
                "label_fr": "Fusternberg"
            },
            {
                "value": 42579,
                "label": "Holthausen",
                "label_ar": "هولتهاوزن",
                "label_fr": "Holthausen"
            },
            {
                "value": 42580,
                "label": "Niederau",
                "label_ar": "نيدراو",
                "label_fr": "Niederau"
            },
            {
                "value": 42581,
                "label": "Roxel",
                "label_ar": "روكسيل",
                "label_fr": "Roxel"
            },
            {
                "value": 42582,
                "label": "Konigsbrunn",
                "label_ar": "كونيغسبرون",
                "label_fr": "Königsbrunn"
            },
            {
                "value": 42583,
                "label": "Holzheim",
                "label_ar": "هولزهايم",
                "label_fr": "Holzheim"
            },
            {
                "value": 42584,
                "label": "Ruckersdorf",
                "label_ar": "روكرسدورف",
                "label_fr": "Ruckersdorf"
            },
            {
                "value": 42585,
                "label": "Langwasser",
                "label_ar": "لانغفاسر",
                "label_fr": "Langwasser"
            },
            {
                "value": 42586,
                "label": "Hurlach",
                "label_ar": "هيرلاخ",
                "label_fr": "Hurlach"
            },
            {
                "value": 42587,
                "label": "Aislingen",
                "label_ar": "ايسلينجن",
                "label_fr": "Aislingen"
            },
            {
                "value": 42588,
                "label": "Oy-Mittelberg",
                "label_ar": "أوي ميتيلبيرج",
                "label_fr": "Oy-Mittelberg"
            },
            {
                "value": 42589,
                "label": "Vier",
                "label_ar": "فير",
                "label_fr": "Vier"
            },
            {
                "value": 42590,
                "label": "Uckendorf",
                "label_ar": "أوكندورف",
                "label_fr": "Uckendorf"
            },
            {
                "value": 42591,
                "label": "Sindorf",
                "label_ar": "سيندورف",
                "label_fr": "Sindorf"
            },
            {
                "value": 42592,
                "label": "Illschwang",
                "label_ar": "إيلشوانغ",
                "label_fr": "Illschwang"
            },
            {
                "value": 42593,
                "label": "Wartenberg-Rohrbach",
                "label_ar": "Wartenberg-Rohrbach",
                "label_fr": "Wartenberg-Rohrbach"
            },
            {
                "value": 42594,
                "label": "Womrath",
                "label_ar": "ومرات",
                "label_fr": "Womrath"
            },
            {
                "value": 42595,
                "label": "Wallhausen",
                "label_ar": "والهاوزن",
                "label_fr": "Wallhausen"
            },
            {
                "value": 42596,
                "label": "Blatzheim",
                "label_ar": "بلاتزهايم",
                "label_fr": "Blatzheim"
            },
            {
                "value": 42597,
                "label": "Sachsen",
                "label_ar": "ساكسن",
                "label_fr": "Sachsen"
            },
            {
                "value": 42598,
                "label": "Eggersdorf",
                "label_ar": "إيجرسدورف",
                "label_fr": "Eggersdorf"
            },
            {
                "value": 42599,
                "label": "Venne",
                "label_ar": "فين",
                "label_fr": "Venne"
            },
            {
                "value": 42600,
                "label": "Adlum",
                "label_ar": "عدلوم",
                "label_fr": "Adlum"
            },
            {
                "value": 42601,
                "label": "Heiningen",
                "label_ar": "هينينجين",
                "label_fr": "Heiningen"
            },
            {
                "value": 42602,
                "label": "Otze",
                "label_ar": "Otze",
                "label_fr": "Otze"
            },
            {
                "value": 42603,
                "label": "Stadt",
                "label_ar": "شتات",
                "label_fr": "Stadt"
            },
            {
                "value": 42604,
                "label": "Gerichshain",
                "label_ar": "Gerichshain",
                "label_fr": "Gerichshain"
            },
            {
                "value": 42605,
                "label": "Weede",
                "label_ar": "عشبة",
                "label_fr": "Weede"
            },
            {
                "value": 42606,
                "label": "Ellerdorf",
                "label_ar": "إليردورف",
                "label_fr": "Ellerdorf"
            },
            {
                "value": 42607,
                "label": "Merzen",
                "label_ar": "ميرزين",
                "label_fr": "Merzen"
            },
            {
                "value": 42608,
                "label": "Heiligenstedtenerkamp",
                "label_ar": "هيليغنستيدتينركامب",
                "label_fr": "Heiligenstedtenerkamp"
            },
            {
                "value": 42609,
                "label": "Nienborstel",
                "label_ar": "نينبورستيل",
                "label_fr": "Nienborstel"
            },
            {
                "value": 42610,
                "label": "Oersdorf",
                "label_ar": "أورسدورف",
                "label_fr": "Oersdorf"
            },
            {
                "value": 42611,
                "label": "Klein Nordende",
                "label_ar": "كلاين نوردندي",
                "label_fr": "Klein Nordende"
            },
            {
                "value": 42612,
                "label": "Huje",
                "label_ar": "هوجي",
                "label_fr": "Huje"
            },
            {
                "value": 42613,
                "label": "Nindorf",
                "label_ar": "نيندورف",
                "label_fr": "Nindorf"
            },
            {
                "value": 42614,
                "label": "Niederfrohna",
                "label_ar": "Niederfrohna",
                "label_fr": "Niederfrohna"
            },
            {
                "value": 42615,
                "label": "Pfaffenweiler",
                "label_ar": "بفافينويلر",
                "label_fr": "Pfaffenweiler"
            },
            {
                "value": 42616,
                "label": "Nienstadt",
                "label_ar": "نينشتات",
                "label_fr": "Nienstadt"
            },
            {
                "value": 42617,
                "label": "Deutzen",
                "label_ar": "دويتزن",
                "label_fr": "Deutzen"
            },
            {
                "value": 42618,
                "label": "Naunhof",
                "label_ar": "نونهوف",
                "label_fr": "Naunhof"
            },
            {
                "value": 42619,
                "label": "Ranis",
                "label_ar": "رانيس",
                "label_fr": "Ranis"
            },
            {
                "value": 42620,
                "label": "Rieder",
                "label_ar": "ريدر",
                "label_fr": "Rieder"
            },
            {
                "value": 42621,
                "label": "Welbsleben",
                "label_ar": "ويلبسليبن",
                "label_fr": "Welbsleben"
            },
            {
                "value": 42622,
                "label": "Abtsbessingen",
                "label_ar": "Abtsbessingen",
                "label_fr": "Abtsbessingen"
            },
            {
                "value": 42623,
                "label": "Bad Kosen",
                "label_ar": "باد كوسين",
                "label_fr": "Bad Kosen"
            },
            {
                "value": 42624,
                "label": "Altbelgern",
                "label_ar": "التبلجيرن",
                "label_fr": "Altbelgern"
            },
            {
                "value": 42625,
                "label": "Klostermansfeld",
                "label_ar": "كلوسترمانسفيلد",
                "label_fr": "Klostermansfeld"
            },
            {
                "value": 42626,
                "label": "Gangloff",
                "label_ar": "جانجلوف",
                "label_fr": "Gangloff"
            },
            {
                "value": 42627,
                "label": "Lauscha",
                "label_ar": "لاوش",
                "label_fr": "Lauscha"
            },
            {
                "value": 42628,
                "label": "Schwarzhausen",
                "label_ar": "شوارزهاوزن",
                "label_fr": "Schwarzhausen"
            },
            {
                "value": 42629,
                "label": "Niederau",
                "label_ar": "نيدراو",
                "label_fr": "Niederau"
            },
            {
                "value": 42630,
                "label": "Moosthenning",
                "label_ar": "موستينينج",
                "label_fr": "Moosthenning"
            },
            {
                "value": 42631,
                "label": "Julbach",
                "label_ar": "جولباخ",
                "label_fr": "Julbach"
            },
            {
                "value": 42632,
                "label": "Wormstedt",
                "label_ar": "ورمستيدت",
                "label_fr": "Wormstedt"
            },
            {
                "value": 42633,
                "label": "Artern",
                "label_ar": "أرترن",
                "label_fr": "Artern"
            },
            {
                "value": 42634,
                "label": "Kirchworbis",
                "label_ar": "كيرشوربيس",
                "label_fr": "Kirchworbis"
            },
            {
                "value": 42635,
                "label": "Ichtershausen",
                "label_ar": "Ichtershausen",
                "label_fr": "Ichtershausen"
            },
            {
                "value": 42636,
                "label": "Heldrungen",
                "label_ar": "هيلدرونجين",
                "label_fr": "Heldrungen"
            },
            {
                "value": 42637,
                "label": "Kleinfurra",
                "label_ar": "كلينفورا",
                "label_fr": "Kleinfurra"
            },
            {
                "value": 42638,
                "label": "Schalkau",
                "label_ar": "شالكاو",
                "label_fr": "Schalkau"
            },
            {
                "value": 42639,
                "label": "Niederorschel",
                "label_ar": "نيدرورشيل",
                "label_fr": "Niederorschel"
            },
            {
                "value": 42640,
                "label": "Seebergen",
                "label_ar": "سيبيرجين",
                "label_fr": "Seebergen"
            },
            {
                "value": 42641,
                "label": "Steinach",
                "label_ar": "ستيناخ",
                "label_fr": "Steinach"
            },
            {
                "value": 42642,
                "label": "Sinzing",
                "label_ar": "سينسينغ",
                "label_fr": "Sinzing"
            },
            {
                "value": 42643,
                "label": "Hohenfels",
                "label_ar": "هوهينفيلس",
                "label_fr": "Hohenfels"
            },
            {
                "value": 42644,
                "label": "Malgersdorf",
                "label_ar": "مالغرسدورف",
                "label_fr": "Malgersdorf"
            },
            {
                "value": 42645,
                "label": "Hahnbach",
                "label_ar": "هانباخ",
                "label_fr": "Hahnbach"
            },
            {
                "value": 42646,
                "label": "Weyhausen",
                "label_ar": "ويهاوزن",
                "label_fr": "Weyhausen"
            },
            {
                "value": 42647,
                "label": "Stoetze",
                "label_ar": "ستويتز",
                "label_fr": "Stoetze"
            },
            {
                "value": 42648,
                "label": "Wasbuttel",
                "label_ar": "Wasbuttel",
                "label_fr": "Wasbuttel"
            },
            {
                "value": 42649,
                "label": "Gross Oesingen",
                "label_ar": "جروس أوسينجين",
                "label_fr": "Gross Oesingen"
            },
            {
                "value": 42650,
                "label": "Emmendorf",
                "label_ar": "ايميندورف",
                "label_fr": "Emmendorf"
            },
            {
                "value": 42651,
                "label": "Mariental",
                "label_ar": "مارينتال",
                "label_fr": "Mariental"
            },
            {
                "value": 42652,
                "label": "Osingen",
                "label_ar": "أوسينجين",
                "label_fr": "Osingen"
            },
            {
                "value": 42653,
                "label": "Lessien",
                "label_ar": "ليسين",
                "label_fr": "Lessien"
            },
            {
                "value": 42654,
                "label": "Nahrendorf",
                "label_ar": "نهريندورف",
                "label_fr": "Nahrendorf"
            },
            {
                "value": 42655,
                "label": "Messstetten",
                "label_ar": "ميسستيتين",
                "label_fr": "Messstetten"
            },
            {
                "value": 42656,
                "label": "Heinade",
                "label_ar": "هاينادي",
                "label_fr": "Heinade"
            },
            {
                "value": 42657,
                "label": "Eichenberg",
                "label_ar": "ايشنبرغ",
                "label_fr": "Eichenberg"
            },
            {
                "value": 42658,
                "label": "Willensen",
                "label_ar": "ويلنسن",
                "label_fr": "Willensen"
            },
            {
                "value": 42659,
                "label": "Morenhoven",
                "label_ar": "مورينهوفن",
                "label_fr": "Morenhoven"
            },
            {
                "value": 42660,
                "label": "Heinkenborstel",
                "label_ar": "Heinkenborstel",
                "label_fr": "Heinkenborstel"
            },
            {
                "value": 42661,
                "label": "Habersdorf",
                "label_ar": "هابرسدورف",
                "label_fr": "Habersdorf"
            },
            {
                "value": 42662,
                "label": "Gschwend",
                "label_ar": "غشويند",
                "label_fr": "Gschwend"
            },
            {
                "value": 42663,
                "label": "Lindwedel",
                "label_ar": "ليندويديل",
                "label_fr": "Lindwedel"
            },
            {
                "value": 42664,
                "label": "Betheln",
                "label_ar": "بيثيلن",
                "label_fr": "Betheln"
            },
            {
                "value": 42665,
                "label": "Helpsen",
                "label_ar": "هيلبسن",
                "label_fr": "Aide"
            },
            {
                "value": 42666,
                "label": "Almstedt",
                "label_ar": "المستيدت",
                "label_fr": "Almstedt"
            },
            {
                "value": 42667,
                "label": "Beedenbostel",
                "label_ar": "بيدنبوستل",
                "label_fr": "Beedenbostel"
            },
            {
                "value": 42668,
                "label": "Sibbesse",
                "label_ar": "سيبيس",
                "label_fr": "Sibbesse"
            },
            {
                "value": 42669,
                "label": "Furholzen",
                "label_ar": "فورهولزن",
                "label_fr": "Furholzen"
            },
            {
                "value": 42670,
                "label": "Dingelstedt",
                "label_ar": "Dingelstedt",
                "label_fr": "Dingelstedt"
            },
            {
                "value": 42671,
                "label": "Groeningen",
                "label_ar": "جرونينغين",
                "label_fr": "Groeningen"
            },
            {
                "value": 42672,
                "label": "Luttgenrode",
                "label_ar": "لوتجنرود",
                "label_fr": "Luttgenrode"
            },
            {
                "value": 42673,
                "label": "Mockern",
                "label_ar": "موكيرن",
                "label_fr": "Mockern"
            },
            {
                "value": 42674,
                "label": "Ditfurt",
                "label_ar": "ديتفورت",
                "label_fr": "Ditfurt"
            },
            {
                "value": 42675,
                "label": "Ballenstedt",
                "label_ar": "بالينستيدت",
                "label_fr": "Ballenstedt"
            },
            {
                "value": 42676,
                "label": "Schopsdorf",
                "label_ar": "شوبسدورف",
                "label_fr": "Schopsdorf"
            },
            {
                "value": 42677,
                "label": "Harmstorf",
                "label_ar": "هارمستورف",
                "label_fr": "Harmstorf"
            },
            {
                "value": 42678,
                "label": "Seester",
                "label_ar": "سيستر",
                "label_fr": "Seester"
            },
            {
                "value": 42679,
                "label": "Sorup",
                "label_ar": "سورب",
                "label_fr": "Sorup"
            },
            {
                "value": 42680,
                "label": "Niesgrau",
                "label_ar": "نيسجراو",
                "label_fr": "Niesgrau"
            },
            {
                "value": 42681,
                "label": "Mohrkirch",
                "label_ar": "Mohrkirch",
                "label_fr": "Mohrkirch"
            },
            {
                "value": 42682,
                "label": "Wrixum",
                "label_ar": "وريكسوم",
                "label_fr": "Wrixum"
            },
            {
                "value": 42683,
                "label": "Wittdun",
                "label_ar": "ويتدون",
                "label_fr": "Wittdun"
            },
            {
                "value": 42684,
                "label": "Gross Rheide",
                "label_ar": "جروس رايد",
                "label_fr": "Rhéide brut"
            },
            {
                "value": 42685,
                "label": "Pommerby",
                "label_ar": "بوميربي",
                "label_fr": "Pommerby"
            },
            {
                "value": 42686,
                "label": "Linden",
                "label_ar": "الزيزفون",
                "label_fr": "Tilleul"
            },
            {
                "value": 42687,
                "label": "Friedrichskoog",
                "label_ar": "فريدريشسكوج",
                "label_fr": "Friedrichskoog"
            },
            {
                "value": 42688,
                "label": "Damp",
                "label_ar": "رطب",
                "label_fr": "Humide"
            },
            {
                "value": 42689,
                "label": "Barkenholm",
                "label_ar": "باركينهولم",
                "label_fr": "Barkenholm"
            },
            {
                "value": 42690,
                "label": "Bockhorst",
                "label_ar": "بوكهورست",
                "label_fr": "Bockhorst"
            },
            {
                "value": 42691,
                "label": "Neermoor",
                "label_ar": "نيرمور",
                "label_fr": "Neermoor"
            },
            {
                "value": 42692,
                "label": "Furth im Wald",
                "label_ar": "فورث ايم والد",
                "label_fr": "Furth im Wald"
            },
            {
                "value": 42693,
                "label": "Borne",
                "label_ar": "تحمل",
                "label_fr": "Borne"
            },
            {
                "value": 42694,
                "label": "Eresing",
                "label_ar": "إريسينج",
                "label_fr": "Eresing"
            },
            {
                "value": 42695,
                "label": "Lengenfeld",
                "label_ar": "لينجينفيلد",
                "label_fr": "Lengenfeld"
            },
            {
                "value": 42696,
                "label": "Bad Kohlgrub",
                "label_ar": "باد كولجروب",
                "label_fr": "Bad Kohlgrub"
            },
            {
                "value": 42697,
                "label": "Rennertshofen",
                "label_ar": "رينرتشوفن",
                "label_fr": "Rennertshofen"
            },
            {
                "value": 42698,
                "label": "Schwindegg",
                "label_ar": "شوينديغ",
                "label_fr": "Schwindegg"
            },
            {
                "value": 42699,
                "label": "Au",
                "label_ar": "Au",
                "label_fr": "Au"
            },
            {
                "value": 42700,
                "label": "Todtenweis",
                "label_ar": "تودتينويس",
                "label_fr": "Todtenweis"
            },
            {
                "value": 42701,
                "label": "Wechingen",
                "label_ar": "فيتشينغن",
                "label_fr": "Wechingen"
            },
            {
                "value": 42702,
                "label": "Asbach-Baumenheim",
                "label_ar": "Asbach-Baumenheim",
                "label_fr": "Asbach-Baumenheim"
            },
            {
                "value": 42703,
                "label": "Hilgertshausen-Tandern",
                "label_ar": "Hilgertshausen-Tandern",
                "label_fr": "Hilgertshausen-Tandern"
            },
            {
                "value": 42704,
                "label": "Marktschellenberg",
                "label_ar": "ماركتشلينبيرج",
                "label_fr": "Marktschellenberg"
            },
            {
                "value": 42705,
                "label": "Gmain bei Weidach",
                "label_ar": "جمين باي ويداخ",
                "label_fr": "Gmain bei Weidach"
            },
            {
                "value": 42706,
                "label": "Schonberg",
                "label_ar": "شونبيرج",
                "label_fr": "Schonberg"
            },
            {
                "value": 42707,
                "label": "Weissenohe",
                "label_ar": "ويسنوهي",
                "label_fr": "Weissenohe"
            },
            {
                "value": 42708,
                "label": "Vorra",
                "label_ar": "فورا",
                "label_fr": "Vorra"
            },
            {
                "value": 42709,
                "label": "Bredenbek",
                "label_ar": "بريدنبيك",
                "label_fr": "Bredenbek"
            },
            {
                "value": 42710,
                "label": "Kaaks",
                "label_ar": "كعك",
                "label_fr": "Kaaks"
            },
            {
                "value": 42711,
                "label": "Wilhelm",
                "label_ar": "فيلهلم",
                "label_fr": "Wilhelm"
            },
            {
                "value": 42712,
                "label": "Thaden",
                "label_ar": "ثادن",
                "label_fr": "Thaden"
            },
            {
                "value": 42713,
                "label": "Bendfeld",
                "label_ar": "بيندفيلد",
                "label_fr": "Bendfeld"
            },
            {
                "value": 42714,
                "label": "Wendtorf",
                "label_ar": "فيندورف",
                "label_fr": "Wendtorf"
            },
            {
                "value": 42715,
                "label": "Puls",
                "label_ar": "نبض",
                "label_fr": "Puls"
            },
            {
                "value": 42716,
                "label": "Oldenburg",
                "label_ar": "أولدنبورغ",
                "label_fr": "Oldenbourg"
            },
            {
                "value": 42717,
                "label": "Damlos",
                "label_ar": "دملوس",
                "label_fr": "Damlos"
            },
            {
                "value": 42718,
                "label": "Waischenfeld",
                "label_ar": "Waischenfeld",
                "label_fr": "Waischenfeld"
            },
            {
                "value": 42719,
                "label": "Hiltpoltstein",
                "label_ar": "هيلتبولتشتاين",
                "label_fr": "Hiltpoltstein"
            },
            {
                "value": 42720,
                "label": "Buchbach",
                "label_ar": "بوخباخ",
                "label_fr": "Buchbach"
            },
            {
                "value": 42721,
                "label": "Furstenberg",
                "label_ar": "فورستنبرج",
                "label_fr": "Furstenberg"
            },
            {
                "value": 42722,
                "label": "Langsur",
                "label_ar": "لانغسور",
                "label_fr": "Langsur"
            },
            {
                "value": 42723,
                "label": "Koxhausen",
                "label_ar": "كوكسهاوزن",
                "label_fr": "Koxhausen"
            },
            {
                "value": 42724,
                "label": "Strickscheid",
                "label_ar": "ستريكشايد",
                "label_fr": "Strickscheid"
            },
            {
                "value": 42725,
                "label": "Birkenfeld",
                "label_ar": "بيركينفيلد",
                "label_fr": "Birkenfeld"
            },
            {
                "value": 42726,
                "label": "Ochtendung",
                "label_ar": "Ochtendung",
                "label_fr": "Ochtendung"
            },
            {
                "value": 42727,
                "label": "Ruschberg",
                "label_ar": "روشبرج",
                "label_fr": "Ruschberg"
            },
            {
                "value": 42728,
                "label": "Herborn",
                "label_ar": "هيربورن",
                "label_fr": "Herborn"
            },
            {
                "value": 42729,
                "label": "Rinzenberg",
                "label_ar": "رينزنبرج",
                "label_fr": "Rinzenberg"
            },
            {
                "value": 42730,
                "label": "Bispingen",
                "label_ar": "بيسبينجين",
                "label_fr": "Bispingen"
            },
            {
                "value": 42731,
                "label": "Pollhagen",
                "label_ar": "بولهاغن",
                "label_fr": "Pollhagen"
            },
            {
                "value": 42732,
                "label": "Machern",
                "label_ar": "ماكيرن",
                "label_fr": "Machern"
            },
            {
                "value": 42733,
                "label": "Posing",
                "label_ar": "تظاهر",
                "label_fr": "Poser"
            },
            {
                "value": 42734,
                "label": "Kofering",
                "label_ar": "كوفيرينغ",
                "label_fr": "Kofering"
            },
            {
                "value": 42735,
                "label": "Altenthann",
                "label_ar": "ألتينثان",
                "label_fr": "Altenthann"
            },
            {
                "value": 42736,
                "label": "Gartow",
                "label_ar": "جارتو",
                "label_fr": "Gartow"
            },
            {
                "value": 42737,
                "label": "Jameln",
                "label_ar": "جمالن",
                "label_fr": "Jameln"
            },
            {
                "value": 42738,
                "label": "Weisenheim am Sand",
                "label_ar": "Weisenheim am Sand",
                "label_fr": "Weisenheim am Sand"
            },
            {
                "value": 42739,
                "label": "Limbach",
                "label_ar": "ليمباخ",
                "label_fr": "Limbach"
            },
            {
                "value": 42740,
                "label": "Wilhermsdorf",
                "label_ar": "ويلهيرمسدورف",
                "label_fr": "Wilhermsdorf"
            },
            {
                "value": 42741,
                "label": "Sugenheim",
                "label_ar": "سوغنهايم",
                "label_fr": "Sugenheim"
            },
            {
                "value": 42742,
                "label": "Oberkail",
                "label_ar": "اوبركيل",
                "label_fr": "Oberkail"
            },
            {
                "value": 42743,
                "label": "Bescheid",
                "label_ar": "بشيد",
                "label_fr": "Bescheid"
            },
            {
                "value": 42744,
                "label": "Kempfeld",
                "label_ar": "كيمبفيلد",
                "label_fr": "Kempfeld"
            },
            {
                "value": 42745,
                "label": "Osann-Monzel",
                "label_ar": "أوسان مونزيل",
                "label_fr": "Osann-Monzel"
            },
            {
                "value": 42746,
                "label": "Neumagen-Dhron",
                "label_ar": "نيوماجين - درون",
                "label_fr": "Neumagen-Dhron"
            },
            {
                "value": 42747,
                "label": "Naurath",
                "label_ar": "نوراث",
                "label_fr": "Naurath"
            },
            {
                "value": 42748,
                "label": "Graach",
                "label_ar": "جراش",
                "label_fr": "Graach"
            },
            {
                "value": 42749,
                "label": "Junkerath",
                "label_ar": "Junkerath",
                "label_fr": "Junkerath"
            },
            {
                "value": 42750,
                "label": "Veldenz",
                "label_ar": "فيلدينز",
                "label_fr": "Veldenz"
            },
            {
                "value": 42751,
                "label": "Oberstadtfeld",
                "label_ar": "أوبرشتاتفيلد",
                "label_fr": "Oberstadtfeld"
            },
            {
                "value": 42752,
                "label": "Schwollen",
                "label_ar": "شولن",
                "label_fr": "Schwollen"
            },
            {
                "value": 42753,
                "label": "Freudenburg",
                "label_ar": "فرويدنبورغ",
                "label_fr": "Freudenburg"
            },
            {
                "value": 42754,
                "label": "Longkamp",
                "label_ar": "لونجكامب",
                "label_fr": "Longkamp"
            },
            {
                "value": 42755,
                "label": "Bekond",
                "label_ar": "بيكوند",
                "label_fr": "Bekond"
            },
            {
                "value": 42756,
                "label": "Horath",
                "label_ar": "هوراث",
                "label_fr": "Horath"
            },
            {
                "value": 42757,
                "label": "Alsdorf",
                "label_ar": "Alsdorf",
                "label_fr": "Alsdorf"
            },
            {
                "value": 42758,
                "label": "Kell",
                "label_ar": "كيل",
                "label_fr": "Kell"
            },
            {
                "value": 42759,
                "label": "Reuth",
                "label_ar": "رعوت",
                "label_fr": "Reuth"
            },
            {
                "value": 42760,
                "label": "Brietlingen",
                "label_ar": "بريتلينجن",
                "label_fr": "Brietlingen"
            },
            {
                "value": 42761,
                "label": "Appel",
                "label_ar": "أبيل",
                "label_fr": "Appel"
            },
            {
                "value": 42762,
                "label": "Neuendorf",
                "label_ar": "نويندورف",
                "label_fr": "Neuendorf"
            },
            {
                "value": 42763,
                "label": "Bad Kleinen",
                "label_ar": "باد كلاينين",
                "label_fr": "Bad Kleinen"
            },
            {
                "value": 42764,
                "label": "Neukloster",
                "label_ar": "نيكلوستر",
                "label_fr": "Neukloster"
            },
            {
                "value": 42765,
                "label": "Grabow",
                "label_ar": "انتزاع",
                "label_fr": "Grabow"
            },
            {
                "value": 42766,
                "label": "Sternberg",
                "label_ar": "ستيرنبرغ",
                "label_fr": "Sternberg"
            },
            {
                "value": 42767,
                "label": "Oberhof",
                "label_ar": "أوبرهوف",
                "label_fr": "Oberhof"
            },
            {
                "value": 42768,
                "label": "Gustavel",
                "label_ar": "جوستافيل",
                "label_fr": "Gustavel"
            },
            {
                "value": 42769,
                "label": "Herren Steinfeld",
                "label_ar": "هيرين شتاينفيلد",
                "label_fr": "Herren Steinfeld"
            },
            {
                "value": 42770,
                "label": "Uelitz",
                "label_ar": "أوليتس",
                "label_fr": "Uelitz"
            },
            {
                "value": 42771,
                "label": "Birkenfeld",
                "label_ar": "بيركينفيلد",
                "label_fr": "Birkenfeld"
            },
            {
                "value": 42772,
                "label": "Wonfurt",
                "label_ar": "وونفورت",
                "label_fr": "Wonfurt"
            },
            {
                "value": 42773,
                "label": "Winterhausen",
                "label_ar": "وينترهاوزن",
                "label_fr": "Winterhausen"
            },
            {
                "value": 42774,
                "label": "Sulzthal",
                "label_ar": "سولزثال",
                "label_fr": "Sulzthal"
            },
            {
                "value": 42775,
                "label": "Marktbreit",
                "label_ar": "ماركتبريت",
                "label_fr": "Marktbreit"
            },
            {
                "value": 42776,
                "label": "Westheim",
                "label_ar": "ويستهايم",
                "label_fr": "Westheim"
            },
            {
                "value": 42777,
                "label": "Weidhausen",
                "label_ar": "Weidhausen",
                "label_fr": "Weidhausen"
            },
            {
                "value": 42778,
                "label": "Oberneuses",
                "label_ar": "أوبرنيوس",
                "label_fr": "Oberneuses"
            },
            {
                "value": 42779,
                "label": "Mitteleschenbach",
                "label_ar": "ميتيليشينباخ",
                "label_fr": "Mitteleschenbach"
            },
            {
                "value": 42780,
                "label": "Emskirchen",
                "label_ar": "Emskirchen",
                "label_fr": "Emskirchen"
            },
            {
                "value": 42781,
                "label": "Buchberg",
                "label_ar": "بوخبيرج",
                "label_fr": "Buchberg"
            },
            {
                "value": 42782,
                "label": "Theilenhofen",
                "label_ar": "ثيلينهوفن",
                "label_fr": "Theilenhofen"
            },
            {
                "value": 42783,
                "label": "Sengenthal",
                "label_ar": "سينجينثال",
                "label_fr": "Sengenthal"
            },
            {
                "value": 42784,
                "label": "Tiefenbronn",
                "label_ar": "تيفينبرون",
                "label_fr": "Tiefenbronn"
            },
            {
                "value": 42785,
                "label": "Vestenberg",
                "label_ar": "فيستنبرغ",
                "label_fr": "Vestenberg"
            },
            {
                "value": 42786,
                "label": "Alesheim",
                "label_ar": "أليشيم",
                "label_fr": "Alesheim"
            },
            {
                "value": 42787,
                "label": "Wassertrudingen",
                "label_ar": "فاسرترودينجن",
                "label_fr": "Wassertrudingen"
            },
            {
                "value": 42788,
                "label": "Pollenfeld",
                "label_ar": "بولينفيلد",
                "label_fr": "Pollenfeld"
            },
            {
                "value": 42789,
                "label": "Rothenbach",
                "label_ar": "روثنباخ",
                "label_fr": "Rothenbach"
            },
            {
                "value": 42790,
                "label": "Karweiler",
                "label_ar": "كارويلر",
                "label_fr": "Karweiler"
            },
            {
                "value": 42791,
                "label": "Ahrbruck",
                "label_ar": "Ahrbruck",
                "label_fr": "Ahrbruck"
            },
            {
                "value": 42792,
                "label": "Weitersburg",
                "label_ar": "Weitersburg",
                "label_fr": "Weitersburg"
            },
            {
                "value": 42793,
                "label": "Bad Ems",
                "label_ar": "سيئة Ems",
                "label_fr": "Mauvais Ems"
            },
            {
                "value": 42794,
                "label": "Insul",
                "label_ar": "انسول",
                "label_fr": "Insul"
            },
            {
                "value": 42795,
                "label": "Adenau",
                "label_ar": "أديناو",
                "label_fr": "Adenau"
            },
            {
                "value": 42796,
                "label": "Bonefeld",
                "label_ar": "بونفيلد",
                "label_fr": "Bonefeld"
            },
            {
                "value": 42797,
                "label": "Brucktal",
                "label_ar": "Brucktal",
                "label_fr": "Brucktal"
            },
            {
                "value": 42798,
                "label": "Waldbreitbach",
                "label_ar": "والدبريتباخ",
                "label_fr": "Waldbreitbach"
            },
            {
                "value": 42799,
                "label": "Westerhorn",
                "label_ar": "ويسترهورن",
                "label_fr": "Westerhorn"
            },
            {
                "value": 42800,
                "label": "Haseldorf",
                "label_ar": "هاسيلدورف",
                "label_fr": "Haseldorf"
            },
            {
                "value": 42801,
                "label": "Leimbach",
                "label_ar": "ليمباخ",
                "label_fr": "Leimbach"
            },
            {
                "value": 42802,
                "label": "Hartenfels",
                "label_ar": "هارتنفيلز",
                "label_fr": "Hartenfels"
            },
            {
                "value": 42803,
                "label": "Sondheim",
                "label_ar": "سونديم",
                "label_fr": "Sondheim"
            },
            {
                "value": 42804,
                "label": "Willmars",
                "label_ar": "ويلمارز",
                "label_fr": "Willmars"
            },
            {
                "value": 42805,
                "label": "Weihenzell",
                "label_ar": "Weihenzell",
                "label_fr": "Weihenzell"
            },
            {
                "value": 42806,
                "label": "Dietfurt",
                "label_ar": "ديتفورت",
                "label_fr": "Dietfurt"
            },
            {
                "value": 42807,
                "label": "Wittelshofen",
                "label_ar": "Wittelshofen",
                "label_fr": "Wittelshofen"
            },
            {
                "value": 42808,
                "label": "Burghaslach",
                "label_ar": "بورغاسلاش",
                "label_fr": "Burghaslach"
            },
            {
                "value": 42809,
                "label": "Denkendorf",
                "label_ar": "دنكندورف",
                "label_fr": "Denkendorf"
            },
            {
                "value": 42810,
                "label": "Zapfendorf",
                "label_ar": "زابفيندورف",
                "label_fr": "Zapfendorf"
            },
            {
                "value": 42811,
                "label": "Belecke",
                "label_ar": "بيليك",
                "label_fr": "Belecke"
            },
            {
                "value": 42812,
                "label": "Wittighausen",
                "label_ar": "Wittighausen",
                "label_fr": "Wittighausen"
            },
            {
                "value": 42813,
                "label": "Abtswind",
                "label_ar": "Abtswind",
                "label_fr": "Abtswind"
            },
            {
                "value": 42814,
                "label": "Schneeberg",
                "label_ar": "شنيبرغ",
                "label_fr": "Schneeberg"
            },
            {
                "value": 42815,
                "label": "Sulzfeld",
                "label_ar": "سولزفيلد",
                "label_fr": "Sulzfeld"
            },
            {
                "value": 42816,
                "label": "Untergimpern",
                "label_ar": "أونتيرجيمبيرن",
                "label_fr": "Untergimpern"
            },
            {
                "value": 42817,
                "label": "Knoringen",
                "label_ar": "كنورينجين",
                "label_fr": "Knoringen"
            },
            {
                "value": 42818,
                "label": "Fahrenbach",
                "label_ar": "فهرنباخ",
                "label_fr": "Fahrenbach"
            },
            {
                "value": 42819,
                "label": "Oberschefflenz",
                "label_ar": "أوبيرشيفلينز",
                "label_fr": "Oberschefflenz"
            },
            {
                "value": 42820,
                "label": "Lehrensteinsfeld",
                "label_ar": "ليرينستينسفيلد",
                "label_fr": "Lehrensteinsfeld"
            },
            {
                "value": 42821,
                "label": "Rot am See",
                "label_ar": "روت آم سي",
                "label_fr": "Rot am See"
            },
            {
                "value": 42822,
                "label": "Adelmannsfelden",
                "label_ar": "أدلماننسفيلدن",
                "label_fr": "Adelmannsfelden"
            },
            {
                "value": 42823,
                "label": "Ellenberg",
                "label_ar": "إلينبيرج",
                "label_fr": "Ellenberg"
            },
            {
                "value": 42824,
                "label": "Spiegelberg",
                "label_ar": "شبيجلبرج",
                "label_fr": "Spiegelberg"
            },
            {
                "value": 42825,
                "label": "Unterreichenbach",
                "label_ar": "أونتيريشنباخ",
                "label_fr": "Unterreichenbach"
            },
            {
                "value": 42826,
                "label": "Zaisenhausen",
                "label_ar": "زايسنهاوزن",
                "label_fr": "Zaisenhausen"
            },
            {
                "value": 42827,
                "label": "Reute",
                "label_ar": "ريوت",
                "label_fr": "Reute"
            },
            {
                "value": 42828,
                "label": "Trebendorf",
                "label_ar": "تريبندورف",
                "label_fr": "Trebendorf"
            },
            {
                "value": 42829,
                "label": "Laubusch",
                "label_ar": "لوبوش",
                "label_fr": "Laubusch"
            },
            {
                "value": 42830,
                "label": "Gehofen",
                "label_ar": "جيهوفن",
                "label_fr": "Gehofen"
            },
            {
                "value": 42831,
                "label": "Niendorf",
                "label_ar": "نيندورف",
                "label_fr": "Niendorf"
            },
            {
                "value": 42832,
                "label": "Roseburg",
                "label_ar": "روزبورغ",
                "label_fr": "Roseburg"
            },
            {
                "value": 42833,
                "label": "Haffkrug",
                "label_ar": "هافكروج",
                "label_fr": "Haffkrug"
            },
            {
                "value": 42834,
                "label": "Oberweimar",
                "label_ar": "أوبيرفايمار",
                "label_fr": "Oberweimar"
            },
            {
                "value": 42835,
                "label": "Oberndorf",
                "label_ar": "اوبرندورف",
                "label_fr": "Oberndorf"
            },
            {
                "value": 42836,
                "label": "Wolkenstein",
                "label_ar": "فولكنشتاين",
                "label_fr": "Wolkenstein"
            },
            {
                "value": 42837,
                "label": "Weyerbusch",
                "label_ar": "ويربوش",
                "label_fr": "Weyerbusch"
            },
            {
                "value": 42838,
                "label": "Harschbach",
                "label_ar": "هرشباخ",
                "label_fr": "Harschbach"
            },
            {
                "value": 42839,
                "label": "Elkenroth",
                "label_ar": "Elkenroth",
                "label_fr": "Elkenroth"
            },
            {
                "value": 42840,
                "label": "Niederwambach",
                "label_ar": "نيدروامباخ",
                "label_fr": "Niederwambach"
            },
            {
                "value": 42841,
                "label": "Waldorf",
                "label_ar": "والدورف",
                "label_fr": "Waldorf"
            },
            {
                "value": 42842,
                "label": "Katzwinkel",
                "label_ar": "كاتزوينكل",
                "label_fr": "Katzwinkel"
            },
            {
                "value": 42843,
                "label": "Uess",
                "label_ar": "أويس",
                "label_fr": "Uess"
            },
            {
                "value": 42844,
                "label": "Treis-Karden",
                "label_ar": "تريس كاردن",
                "label_fr": "Treis-Karden"
            },
            {
                "value": 42845,
                "label": "Eichen",
                "label_ar": "ايتشن",
                "label_fr": "Eichen"
            },
            {
                "value": 42846,
                "label": "Hillscheid",
                "label_ar": "هيلشايد",
                "label_fr": "Hillscheid"
            },
            {
                "value": 42847,
                "label": "Uersfeld",
                "label_ar": "أورسفيلد",
                "label_fr": "Uersfeld"
            },
            {
                "value": 42848,
                "label": "Nickenich",
                "label_ar": "نيكنيتش",
                "label_fr": "Nickenich"
            },
            {
                "value": 42849,
                "label": "Dietlingen",
                "label_ar": "ديتلينجين",
                "label_fr": "Dietlingen"
            },
            {
                "value": 42850,
                "label": "Radegast",
                "label_ar": "راديغاست",
                "label_fr": "Radegast"
            },
            {
                "value": 42851,
                "label": "Kuhndorf",
                "label_ar": "كوندورف",
                "label_fr": "Kuhndorf"
            },
            {
                "value": 42852,
                "label": "Kleinfahner",
                "label_ar": "كلاينفانر",
                "label_fr": "Kleinfahner"
            },
            {
                "value": 42853,
                "label": "Dachwig",
                "label_ar": "داتشويغ",
                "label_fr": "Dachwig"
            },
            {
                "value": 42854,
                "label": "Gierstadt",
                "label_ar": "جيرشتات",
                "label_fr": "Gierstadt"
            },
            {
                "value": 42855,
                "label": "Badra",
                "label_ar": "بدرة",
                "label_fr": "Badra"
            },
            {
                "value": 42856,
                "label": "Woffleben",
                "label_ar": "Woffleben",
                "label_fr": "Woffleben"
            },
            {
                "value": 42857,
                "label": "Schnellmannshausen",
                "label_ar": "شنيلمانسهاوزن",
                "label_fr": "Schnellmannshausen"
            },
            {
                "value": 42858,
                "label": "Wiesenfeld",
                "label_ar": "فيزنفيلد",
                "label_fr": "Wiesenfeld"
            },
            {
                "value": 42859,
                "label": "Kefferhausen",
                "label_ar": "كيففرهاوزن",
                "label_fr": "Kefferhausen"
            },
            {
                "value": 42860,
                "label": "Rohr",
                "label_ar": "روهر",
                "label_fr": "Rohr"
            },
            {
                "value": 42861,
                "label": "Halle Neustadt",
                "label_ar": "هالي نيوستادت",
                "label_fr": "Halle Neustadt"
            },
            {
                "value": 42862,
                "label": "Grosslobichau",
                "label_ar": "Grosslobichau",
                "label_fr": "Grosslobichau"
            },
            {
                "value": 42863,
                "label": "Vollersroda",
                "label_ar": "فولرسرودا",
                "label_fr": "Vollersroda"
            },
            {
                "value": 42864,
                "label": "Buchfart",
                "label_ar": "بوشفارت",
                "label_fr": "Buchfart"
            },
            {
                "value": 42865,
                "label": "Pohl",
                "label_ar": "بوهل",
                "label_fr": "Pohl"
            },
            {
                "value": 42866,
                "label": "Mittelbach",
                "label_ar": "ميتلباخ",
                "label_fr": "Mittelbach"
            },
            {
                "value": 42867,
                "label": "Hormersdorf",
                "label_ar": "هورمرسدورف",
                "label_fr": "Hormersdorf"
            },
            {
                "value": 42868,
                "label": "Annweiler am Trifels",
                "label_ar": "Annweiler am Trifels",
                "label_fr": "Annweiler am Trifels"
            },
            {
                "value": 42869,
                "label": "Friedelsheim",
                "label_ar": "فريدلشيم",
                "label_fr": "Friedelsheim"
            },
            {
                "value": 42870,
                "label": "Bischheim",
                "label_ar": "بيشهايم",
                "label_fr": "Bischheim"
            },
            {
                "value": 42871,
                "label": "Freinsheim",
                "label_ar": "فرينشيم",
                "label_fr": "Freinsheim"
            },
            {
                "value": 42872,
                "label": "Albisheim",
                "label_ar": "ألبيشيم",
                "label_fr": "Albisheim"
            },
            {
                "value": 42873,
                "label": "Salgen",
                "label_ar": "سالجين",
                "label_fr": "Salgen"
            },
            {
                "value": 42874,
                "label": "Nieder Ohmen",
                "label_ar": "نيدر أومين",
                "label_fr": "Nieder Ohmen"
            },
            {
                "value": 42875,
                "label": "Boxberg",
                "label_ar": "بوكسبيرغ",
                "label_fr": "Boxberg"
            },
            {
                "value": 42876,
                "label": "Himbergen",
                "label_ar": "هيمبيرجن",
                "label_fr": "Himbergen"
            },
            {
                "value": 42877,
                "label": "Rummer",
                "label_ar": "كوب كبير",
                "label_fr": "Grand verre à pied"
            },
            {
                "value": 42878,
                "label": "Weste",
                "label_ar": "ويست",
                "label_fr": "Weste"
            },
            {
                "value": 42879,
                "label": "Grafhorst",
                "label_ar": "جرافهورست",
                "label_fr": "Grafhorst"
            },
            {
                "value": 42880,
                "label": "Danndorf",
                "label_ar": "داندورف",
                "label_fr": "Danndorf"
            },
            {
                "value": 42881,
                "label": "Barum",
                "label_ar": "باروم",
                "label_fr": "Barum"
            },
            {
                "value": 42882,
                "label": "Mitterstetten",
                "label_ar": "ميترستيتن",
                "label_fr": "Mitterstetten"
            },
            {
                "value": 42883,
                "label": "Pornbach",
                "label_ar": "بورنباخ",
                "label_fr": "Pornbach"
            },
            {
                "value": 42884,
                "label": "Elsendorf",
                "label_ar": "إلسندورف",
                "label_fr": "Elsendorf"
            },
            {
                "value": 42885,
                "label": "Hergolding",
                "label_ar": "هيرجولدينج",
                "label_fr": "Hergolding"
            },
            {
                "value": 42886,
                "label": "Kumhausen",
                "label_ar": "كومهاوزن",
                "label_fr": "Kumhausen"
            },
            {
                "value": 42887,
                "label": "Treia",
                "label_ar": "تريا",
                "label_fr": "Treia"
            },
            {
                "value": 42888,
                "label": "Sieverstedt",
                "label_ar": "سيفيرستيدت",
                "label_fr": "Sieverstedt"
            },
            {
                "value": 42889,
                "label": "Fahrdorf",
                "label_ar": "فهردورف",
                "label_fr": "Fahrdorf"
            },
            {
                "value": 42890,
                "label": "Jorl",
                "label_ar": "جورل",
                "label_fr": "Jorl"
            },
            {
                "value": 42891,
                "label": "Lurschau",
                "label_ar": "لورشاو",
                "label_fr": "Lurschau"
            },
            {
                "value": 42892,
                "label": "Grosssolt",
                "label_ar": "جروسولت",
                "label_fr": "Grosssolt"
            },
            {
                "value": 42893,
                "label": "Karlum",
                "label_ar": "كارلوم",
                "label_fr": "Karlum"
            },
            {
                "value": 42894,
                "label": "Klein Wittensee",
                "label_ar": "كلاين ويتينسي",
                "label_fr": "Klein Wittensee"
            },
            {
                "value": 42895,
                "label": "Langballig",
                "label_ar": "لانغباليج",
                "label_fr": "Langballig"
            },
            {
                "value": 42896,
                "label": "Schlotheim",
                "label_ar": "شلوتهايم",
                "label_fr": "Schlotheim"
            },
            {
                "value": 42897,
                "label": "Mattstedt",
                "label_ar": "ماتستيدت",
                "label_fr": "Mattstedt"
            },
            {
                "value": 42898,
                "label": "Themar",
                "label_ar": "ثمار",
                "label_fr": "Themar"
            },
            {
                "value": 42899,
                "label": "Sundhausen",
                "label_ar": "سوندهاوزن",
                "label_fr": "Sundhausen"
            },
            {
                "value": 42900,
                "label": "Possendorf",
                "label_ar": "بوسندورف",
                "label_fr": "Possendorf"
            },
            {
                "value": 42901,
                "label": "Furthen",
                "label_ar": "فورثين",
                "label_fr": "Furthen"
            },
            {
                "value": 42902,
                "label": "Bannberscheid",
                "label_ar": "بانبيرشايد",
                "label_fr": "Bannberscheid"
            },
            {
                "value": 42903,
                "label": "Winningen",
                "label_ar": "وينينغين",
                "label_fr": "Winningen"
            },
            {
                "value": 42904,
                "label": "Mannebach",
                "label_ar": "مانيباخ",
                "label_fr": "Mannebach"
            },
            {
                "value": 42905,
                "label": "Mogendorf",
                "label_ar": "موغندورف",
                "label_fr": "Mogendorf"
            },
            {
                "value": 42906,
                "label": "Weibern",
                "label_ar": "ويبرن",
                "label_fr": "Weibern"
            },
            {
                "value": 42907,
                "label": "Fehl-Ritzhausen",
                "label_ar": "Fehl-Ritzhausen",
                "label_fr": "Fehl-Ritzhausen"
            },
            {
                "value": 42908,
                "label": "Welkenbach",
                "label_ar": "ويلكينباخ",
                "label_fr": "Welkenbach"
            },
            {
                "value": 42909,
                "label": "Niederalsen",
                "label_ar": "نيديرالسن",
                "label_fr": "Niederalsen"
            },
            {
                "value": 42910,
                "label": "Marienrachdorf",
                "label_ar": "مارينراتشدورف",
                "label_fr": "Marienrachdorf"
            },
            {
                "value": 42911,
                "label": "Vohringen",
                "label_ar": "Vohringen",
                "label_fr": "Vohringen"
            },
            {
                "value": 42912,
                "label": "Warza",
                "label_ar": "ورزة",
                "label_fr": "Warza"
            },
            {
                "value": 42913,
                "label": "Neuhaus-Schierschnitz",
                "label_ar": "نيوهاوس شيرشنيتز",
                "label_fr": "Neuhaus-Schierschnitz"
            },
            {
                "value": 42914,
                "label": "Reinstadt",
                "label_ar": "رينشتات",
                "label_fr": "Reinstadt"
            },
            {
                "value": 42915,
                "label": "Trusetal",
                "label_ar": "تروسيتال",
                "label_fr": "Trusetal"
            },
            {
                "value": 42916,
                "label": "Neudietendorf",
                "label_ar": "نيوديتندورف",
                "label_fr": "Neudietendorf"
            },
            {
                "value": 42917,
                "label": "Kirchheim",
                "label_ar": "كيرشهايم",
                "label_fr": "Kirchheim"
            },
            {
                "value": 42918,
                "label": "Goritz",
                "label_ar": "جوريتز",
                "label_fr": "Goritz"
            },
            {
                "value": 42919,
                "label": "Veilsdorf",
                "label_ar": "فيلسدورف",
                "label_fr": "Veilsdorf"
            },
            {
                "value": 42920,
                "label": "Ingersleben",
                "label_ar": "انجرسليبن",
                "label_fr": "Ingersleben"
            },
            {
                "value": 42921,
                "label": "Seck",
                "label_ar": "Seck",
                "label_fr": "Seck"
            },
            {
                "value": 42922,
                "label": "Thur",
                "label_ar": "ثور",
                "label_fr": "Jeudi"
            },
            {
                "value": 42923,
                "label": "Weitefeld",
                "label_ar": "ويتفيلد",
                "label_fr": "Weitefeld"
            },
            {
                "value": 42924,
                "label": "Ebernhahn",
                "label_ar": "إبرنهان",
                "label_fr": "Ebernhahn"
            },
            {
                "value": 42925,
                "label": "Dreisbach",
                "label_ar": "دريسباخ",
                "label_fr": "Dreisbach"
            },
            {
                "value": 42926,
                "label": "Meinsdorf",
                "label_ar": "مينسدورف",
                "label_fr": "Meinsdorf"
            },
            {
                "value": 42927,
                "label": "Kleinbeeren",
                "label_ar": "كلاينبيرن",
                "label_fr": "Kleinbeeren"
            },
            {
                "value": 42928,
                "label": "Diedorf",
                "label_ar": "ديدورف",
                "label_fr": "Diedorf"
            },
            {
                "value": 42929,
                "label": "Albrechts",
                "label_ar": "ألبريشتس",
                "label_fr": "Albrechts"
            },
            {
                "value": 42930,
                "label": "Othenstorf",
                "label_ar": "أوثينستورف",
                "label_fr": "Othenstorf"
            },
            {
                "value": 42931,
                "label": "Altenkrempe",
                "label_ar": "التنكرمبي",
                "label_fr": "Altenkrempe"
            },
            {
                "value": 42932,
                "label": "Malente",
                "label_ar": "مالينتي",
                "label_fr": "Malente"
            },
            {
                "value": 42933,
                "label": "Klein Ronnau",
                "label_ar": "كلاين روناو",
                "label_fr": "Klein Ronnau"
            },
            {
                "value": 42934,
                "label": "Talkau",
                "label_ar": "تالكاو",
                "label_fr": "Talkau"
            },
            {
                "value": 42935,
                "label": "Hartenholm",
                "label_ar": "هارتنهولم",
                "label_fr": "Hartenholm"
            },
            {
                "value": 42936,
                "label": "Politz",
                "label_ar": "بوليتز",
                "label_fr": "Politz"
            },
            {
                "value": 42937,
                "label": "Merzhausen",
                "label_ar": "ميرزهاوزن",
                "label_fr": "Merzhausen"
            },
            {
                "value": 42938,
                "label": "Schwarzwald",
                "label_ar": "شوارزوالد",
                "label_fr": "Schwarzwald"
            },
            {
                "value": 42939,
                "label": "March",
                "label_ar": "مارس",
                "label_fr": "Mars"
            },
            {
                "value": 42940,
                "label": "Neu Zauche",
                "label_ar": "نيو زوش",
                "label_fr": "Neu Zauche"
            },
            {
                "value": 42941,
                "label": "Schacksdorf",
                "label_ar": "شاكسدورف",
                "label_fr": "Schacksdorf"
            },
            {
                "value": 42942,
                "label": "Kasel-Golzig",
                "label_ar": "كاسل جولزيغ",
                "label_fr": "Kasel-Golzig"
            },
            {
                "value": 42943,
                "label": "Drebkau",
                "label_ar": "دريبكو",
                "label_fr": "Drebkau"
            },
            {
                "value": 42944,
                "label": "Axstedt",
                "label_ar": "اكستيدت",
                "label_fr": "Axstedt"
            },
            {
                "value": 42945,
                "label": "Bohlen",
                "label_ar": "بوهلين",
                "label_fr": "Bohlen"
            },
            {
                "value": 42946,
                "label": "Schildau",
                "label_ar": "شيلداو",
                "label_fr": "Schildau"
            },
            {
                "value": 42947,
                "label": "Hattenhofen",
                "label_ar": "هاتنهوفن",
                "label_fr": "Hattenhofen"
            },
            {
                "value": 42948,
                "label": "Vilsheim",
                "label_ar": "فيلشيم",
                "label_fr": "Vilsheim"
            },
            {
                "value": 42949,
                "label": "Niederviehbach",
                "label_ar": "نيديرفيهباخ",
                "label_fr": "Niederviehbach"
            },
            {
                "value": 42950,
                "label": "Adlkofen",
                "label_ar": "أدلكوفين",
                "label_fr": "Adlkofen"
            },
            {
                "value": 42951,
                "label": "Weihmichl",
                "label_ar": "ويهميشل",
                "label_fr": "Weihmichl"
            },
            {
                "value": 42952,
                "label": "Neufraunhofen",
                "label_ar": "نيوفراونهوفن",
                "label_fr": "Neufraunhofen"
            },
            {
                "value": 42953,
                "label": "Trebsen",
                "label_ar": "تريبسن",
                "label_fr": "Trebsen"
            },
            {
                "value": 42954,
                "label": "Bucha",
                "label_ar": "بوتشا",
                "label_fr": "Bucha"
            },
            {
                "value": 42955,
                "label": "Doberschutz",
                "label_ar": "Doberschutz",
                "label_fr": "Doberschutz"
            },
            {
                "value": 42956,
                "label": "Rockenberg",
                "label_ar": "روكينبيرج",
                "label_fr": "Rockenberg"
            },
            {
                "value": 42957,
                "label": "Munzenberg",
                "label_ar": "مونزينبرج",
                "label_fr": "Munzenberg"
            },
            {
                "value": 42958,
                "label": "Schwarzach",
                "label_ar": "شوارزاتش",
                "label_fr": "Schwarzach"
            },
            {
                "value": 42959,
                "label": "Tettenwang",
                "label_ar": "Tettenwang",
                "label_fr": "Tettenwang"
            },
            {
                "value": 42960,
                "label": "Bartow",
                "label_ar": "بارتو",
                "label_fr": "Bartow"
            },
            {
                "value": 42961,
                "label": "Medow",
                "label_ar": "ميدو",
                "label_fr": "Medow"
            },
            {
                "value": 42962,
                "label": "Klein Kedingshagen",
                "label_ar": "كلاين كيدنجشاجين",
                "label_fr": "Klein Kedingshagen"
            },
            {
                "value": 42963,
                "label": "Ernsthof",
                "label_ar": "إرنستهوف",
                "label_fr": "Ernsthof"
            },
            {
                "value": 42964,
                "label": "Patzig",
                "label_ar": "باتزيغ",
                "label_fr": "Patzig"
            },
            {
                "value": 42965,
                "label": "Vitte",
                "label_ar": "فيت",
                "label_fr": "Vitte"
            },
            {
                "value": 42966,
                "label": "Gross Miltzow",
                "label_ar": "جروس ميلتزوف",
                "label_fr": "Gross Miltzow"
            },
            {
                "value": 42967,
                "label": "Murchin",
                "label_ar": "مورشين",
                "label_fr": "Murchin"
            },
            {
                "value": 42968,
                "label": "Meesiger",
                "label_ar": "ميسيجر",
                "label_fr": "Meesiger"
            },
            {
                "value": 42969,
                "label": "Samtens",
                "label_ar": "سامتينز",
                "label_fr": "Samtens"
            },
            {
                "value": 42970,
                "label": "Sellin",
                "label_ar": "بيع في",
                "label_fr": "Vendre dans"
            },
            {
                "value": 42971,
                "label": "Kluis",
                "label_ar": "كلويس",
                "label_fr": "Kluis"
            },
            {
                "value": 42972,
                "label": "Ostseebad Baabe",
                "label_ar": "Ostseebad Baabe",
                "label_fr": "Ostseebad Baabe"
            },
            {
                "value": 42973,
                "label": "Hiddensee",
                "label_ar": "هيدينسي",
                "label_fr": "Hiddensee"
            },
            {
                "value": 42974,
                "label": "Wackerow",
                "label_ar": "واكيرو",
                "label_fr": "Wackerow"
            },
            {
                "value": 42975,
                "label": "Aspisheim",
                "label_ar": "أسبيشيم",
                "label_fr": "Aspisheim"
            },
            {
                "value": 42976,
                "label": "Oelsberg",
                "label_ar": "اويلسبيرغ",
                "label_fr": "Oelsberg"
            },
            {
                "value": 42977,
                "label": "Beltheim",
                "label_ar": "بيلثيم",
                "label_fr": "Beltheim"
            },
            {
                "value": 42978,
                "label": "Schnellbach",
                "label_ar": "شنيلباخ",
                "label_fr": "Schnellbach"
            },
            {
                "value": 42979,
                "label": "Barstadt",
                "label_ar": "بارستادت",
                "label_fr": "Barstadt"
            },
            {
                "value": 42980,
                "label": "Stauchitz",
                "label_ar": "شتاوتشيتز",
                "label_fr": "Stauchitz"
            },
            {
                "value": 42981,
                "label": "Panschwitz-Kuckau",
                "label_ar": "بانشفيتز كوكاو",
                "label_fr": "Panschwitz-Kuckau"
            },
            {
                "value": 42982,
                "label": "Groditz",
                "label_ar": "جروديتز",
                "label_fr": "Groditz"
            },
            {
                "value": 42983,
                "label": "Hirschstein",
                "label_ar": "هيرشستين",
                "label_fr": "Hirschstein"
            },
            {
                "value": 42984,
                "label": "Hof",
                "label_ar": "هوف",
                "label_fr": "Hof"
            },
            {
                "value": 42985,
                "label": "Muhlhausen",
                "label_ar": "مولهاوزن",
                "label_fr": "Muhlhausen"
            },
            {
                "value": 42986,
                "label": "Ecklingerode",
                "label_ar": "Ecklingerode",
                "label_fr": "Ecklingerode"
            },
            {
                "value": 42987,
                "label": "Seega",
                "label_ar": "سيجا",
                "label_fr": "Seega"
            },
            {
                "value": 42988,
                "label": "Bendeleben",
                "label_ar": "Bendeleben",
                "label_fr": "Bendeleben"
            },
            {
                "value": 42989,
                "label": "Isserstedt",
                "label_ar": "إيسرستيدت",
                "label_fr": "Isserstedt"
            },
            {
                "value": 42990,
                "label": "Tonndorf",
                "label_ar": "توندورف",
                "label_fr": "Tonndorf"
            },
            {
                "value": 42991,
                "label": "Bienstadt",
                "label_ar": "بينشتات",
                "label_fr": "Bienstadt"
            },
            {
                "value": 42992,
                "label": "Bleckenrode",
                "label_ar": "بليكنرود",
                "label_fr": "Bleckenrode"
            },
            {
                "value": 42993,
                "label": "Neundorf",
                "label_ar": "نوندورف",
                "label_fr": "Neundorf"
            },
            {
                "value": 42994,
                "label": "Mengersgereuth-Hammern",
                "label_ar": "Mengersgereuth-Hammern",
                "label_fr": "Mengersgereuth-Hammern"
            },
            {
                "value": 42995,
                "label": "Hauteroda",
                "label_ar": "هاوتيرودا",
                "label_fr": "Hauteroda"
            },
            {
                "value": 42996,
                "label": "Wolfis",
                "label_ar": "وولفيس",
                "label_fr": "Wolfis"
            },
            {
                "value": 42997,
                "label": "Grobzig",
                "label_ar": "جروبزيغ",
                "label_fr": "Grobzig"
            },
            {
                "value": 42998,
                "label": "Wansleben",
                "label_ar": "وانسليبن",
                "label_fr": "Wansleben"
            },
            {
                "value": 42999,
                "label": "Bodelshausen",
                "label_ar": "بودلسهاوزن",
                "label_fr": "Bodelshausen"
            },
            {
                "value": 43000,
                "label": "Bermatingen",
                "label_ar": "بيرماتينجين",
                "label_fr": "Bermatingen"
            },
            {
                "value": 43001,
                "label": "Merkers",
                "label_ar": "ميركرز",
                "label_fr": "Merkers"
            },
            {
                "value": 43002,
                "label": "Westhausen",
                "label_ar": "ويستهاوزن",
                "label_fr": "Westhausen"
            },
            {
                "value": 43003,
                "label": "Viernau",
                "label_ar": "فيرناو",
                "label_fr": "Viernau"
            },
            {
                "value": 43004,
                "label": "Springstille",
                "label_ar": "سبرينجستيل",
                "label_fr": "Springstille"
            },
            {
                "value": 43005,
                "label": "Seebach",
                "label_ar": "سيباخ",
                "label_fr": "Seebach"
            },
            {
                "value": 43006,
                "label": "Rotterode",
                "label_ar": "روتيرود",
                "label_fr": "Rotterode"
            },
            {
                "value": 43007,
                "label": "Heyerode",
                "label_ar": "هيرود",
                "label_fr": "Heyerode"
            },
            {
                "value": 43008,
                "label": "Hetschburg",
                "label_ar": "هيتشبرج",
                "label_fr": "Hetschburg"
            },
            {
                "value": 43009,
                "label": "Hildebrandshausen",
                "label_ar": "هيلدبراندسهاوزن",
                "label_fr": "Hildebrandshausen"
            },
            {
                "value": 43010,
                "label": "Ammern",
                "label_ar": "Ammern",
                "label_fr": "Ammern"
            },
            {
                "value": 43011,
                "label": "Elgersburg",
                "label_ar": "Elgersburg",
                "label_fr": "Elgersburg"
            },
            {
                "value": 43012,
                "label": "Wohnrod",
                "label_ar": "ونرود",
                "label_fr": "Wohnrod"
            },
            {
                "value": 43013,
                "label": "Heidenau",
                "label_ar": "هايدناو",
                "label_fr": "Heidenau"
            },
            {
                "value": 43014,
                "label": "Hilgermissen",
                "label_ar": "هيلجيرميسن",
                "label_fr": "Hilgermissen"
            },
            {
                "value": 43015,
                "label": "Luttum",
                "label_ar": "Luttum",
                "label_fr": "Luttum"
            },
            {
                "value": 43016,
                "label": "Wurmannsquick",
                "label_ar": "ورمانسكويك",
                "label_fr": "Wurmannsquick"
            },
            {
                "value": 43017,
                "label": "Rickenbach",
                "label_ar": "ريكنباخ",
                "label_fr": "Rickenbach"
            },
            {
                "value": 43018,
                "label": "Erfelden",
                "label_ar": "إيرفيلدن",
                "label_fr": "Erfelden"
            },
            {
                "value": 43019,
                "label": "Heiligenhagen",
                "label_ar": "هيليغنهاجين",
                "label_fr": "Heiligenhagen"
            },
            {
                "value": 43020,
                "label": "Pruchten",
                "label_ar": "بروشتن",
                "label_fr": "Pruchten"
            },
            {
                "value": 43021,
                "label": "Wittichenau",
                "label_ar": "Wittichenau",
                "label_fr": "Wittichenau"
            },
            {
                "value": 43022,
                "label": "Rietschen",
                "label_ar": "ريتشن",
                "label_fr": "Rietschen"
            },
            {
                "value": 43023,
                "label": "Tettau",
                "label_ar": "تيتاو",
                "label_fr": "Tettau"
            },
            {
                "value": 43024,
                "label": "Ortrand",
                "label_ar": "Ortrand",
                "label_fr": "Ortrand"
            },
            {
                "value": 43025,
                "label": "Loch",
                "label_ar": "بحيرة لوخ",
                "label_fr": "Loch"
            },
            {
                "value": 43026,
                "label": "Reichenbach-Steegen",
                "label_ar": "Reichenbach-Steegen",
                "label_fr": "Reichenbach-Steegen"
            },
            {
                "value": 43027,
                "label": "Becherbach",
                "label_ar": "بيشيرباخ",
                "label_fr": "Becherbach"
            },
            {
                "value": 43028,
                "label": "Schneckenhausen",
                "label_ar": "شنيكنهاوزن",
                "label_fr": "Schneckenhausen"
            },
            {
                "value": 43029,
                "label": "Ransweiler",
                "label_ar": "رانسويلر",
                "label_fr": "Ransweiler"
            },
            {
                "value": 43030,
                "label": "Reipoltskirchen",
                "label_ar": "Reipoltskirchen",
                "label_fr": "Reipoltskirchen"
            },
            {
                "value": 43031,
                "label": "Kindsbach",
                "label_ar": "كيندسباخ",
                "label_fr": "Kindsbach"
            },
            {
                "value": 43032,
                "label": "Hettenrodt",
                "label_ar": "Hettenrodt",
                "label_fr": "Hettenrodt"
            },
            {
                "value": 43033,
                "label": "Weidenbach",
                "label_ar": "Weidenbach",
                "label_fr": "Weidenbach"
            },
            {
                "value": 43034,
                "label": "Niederstadtfeld",
                "label_ar": "نيدرشتاتفيلد",
                "label_fr": "Niederstadtfeld"
            },
            {
                "value": 43035,
                "label": "Wilferdingen",
                "label_ar": "ويلفردينجن",
                "label_fr": "Wilferdingen"
            },
            {
                "value": 43036,
                "label": "Schmidgaden",
                "label_ar": "شميدجادن",
                "label_fr": "Schmidgaden"
            },
            {
                "value": 43037,
                "label": "Prackenbach",
                "label_ar": "براكينباخ",
                "label_fr": "Prackenbach"
            },
            {
                "value": 43038,
                "label": "Alteglofsheim",
                "label_ar": "Alteglofsheim",
                "label_fr": "Alteglofsheim"
            },
            {
                "value": 43039,
                "label": "Hardt",
                "label_ar": "هاردت",
                "label_fr": "Hardt"
            },
            {
                "value": 43040,
                "label": "Eutingen",
                "label_ar": "يوتينجين",
                "label_fr": "Eutingen"
            },
            {
                "value": 43041,
                "label": "Hermaringen",
                "label_ar": "هيرمارينجن",
                "label_fr": "Hermaringen"
            },
            {
                "value": 43042,
                "label": "Nehren",
                "label_ar": "نهرين",
                "label_fr": "Nehren"
            },
            {
                "value": 43043,
                "label": "Renquishausen",
                "label_ar": "رينكيسهاوزن",
                "label_fr": "Renquishausen"
            },
            {
                "value": 43044,
                "label": "Unterroth",
                "label_ar": "Unterroth",
                "label_fr": "Unterroth"
            },
            {
                "value": 43045,
                "label": "Heimsheim",
                "label_ar": "هيمشيم",
                "label_fr": "Heimsheim"
            },
            {
                "value": 43046,
                "label": "Knittlingen",
                "label_ar": "كنيتلينجن",
                "label_fr": "Knittlingen"
            },
            {
                "value": 43047,
                "label": "Johstadt",
                "label_ar": "جوهشتات",
                "label_fr": "Johstadt"
            },
            {
                "value": 43048,
                "label": "Gechingen",
                "label_ar": "جيشينجين",
                "label_fr": "Gechingen"
            },
            {
                "value": 43049,
                "label": "Ramsen",
                "label_ar": "رامسن",
                "label_fr": "Ramsen"
            },
            {
                "value": 43050,
                "label": "Reichartshausen",
                "label_ar": "Reichartshausen",
                "label_fr": "Reichartshausen"
            },
            {
                "value": 43051,
                "label": "Neckarzimmern",
                "label_ar": "نيكارزيمرن",
                "label_fr": "Neckarzimmern"
            },
            {
                "value": 43052,
                "label": "Neidenstein",
                "label_ar": "نيدنشتاين",
                "label_fr": "Neidenstein"
            },
            {
                "value": 43053,
                "label": "Opfingen",
                "label_ar": "أوبفينجين",
                "label_fr": "Opfingen"
            },
            {
                "value": 43054,
                "label": "Rechtenstein",
                "label_ar": "ريشتنشتاين",
                "label_fr": "Rechtenstein"
            },
            {
                "value": 43055,
                "label": "Durmentingen",
                "label_ar": "دورمينتينجين",
                "label_fr": "Durmentingen"
            },
            {
                "value": 43056,
                "label": "Alleshausen",
                "label_ar": "أليشاوسين",
                "label_fr": "Alleshausen"
            },
            {
                "value": 43057,
                "label": "Ebnat",
                "label_ar": "ابنات",
                "label_fr": "Ebnat"
            },
            {
                "value": 43058,
                "label": "Steinhausen",
                "label_ar": "شتاينهاوزن",
                "label_fr": "Steinhausen"
            },
            {
                "value": 43059,
                "label": "Lauterburg",
                "label_ar": "لوتربرغ",
                "label_fr": "Lauterburg"
            },
            {
                "value": 43060,
                "label": "Niederbreitbach",
                "label_ar": "نيدربريتباخ",
                "label_fr": "Niederbreitbach"
            },
            {
                "value": 43061,
                "label": "Braunlingen",
                "label_ar": "برونلينجن",
                "label_fr": "Braunlingen"
            },
            {
                "value": 43062,
                "label": "Rappenau",
                "label_ar": "رابيناو",
                "label_fr": "Rappenau"
            },
            {
                "value": 43063,
                "label": "Frankweiler",
                "label_ar": "فرانكويلر",
                "label_fr": "Frankweiler"
            },
            {
                "value": 43064,
                "label": "Erpolzheim",
                "label_ar": "اربولزهايم",
                "label_fr": "Erpolzheim"
            },
            {
                "value": 43065,
                "label": "Botersen",
                "label_ar": "بوترسن",
                "label_fr": "Botersen"
            },
            {
                "value": 43066,
                "label": "Michelfeld",
                "label_ar": "ميشيلفيلد",
                "label_fr": "Michelfeld"
            },
            {
                "value": 43067,
                "label": "Emtinghausen",
                "label_ar": "إمتينغهاوزن",
                "label_fr": "Emtinghausen"
            },
            {
                "value": 43068,
                "label": "Sandbostel",
                "label_ar": "ساندبوستيل",
                "label_fr": "Sandbostel"
            },
            {
                "value": 43069,
                "label": "Schonenberg-Kubelberg",
                "label_ar": "شونينبيرج كوبيلبرج",
                "label_fr": "Schonenberg-Kubelberg"
            },
            {
                "value": 43070,
                "label": "Bad Peterstal-Griesbach",
                "label_ar": "باد بيترستال جريسباخ",
                "label_fr": "Bad Peterstal-Griesbach"
            },
            {
                "value": 43071,
                "label": "Wittgendorf",
                "label_ar": "Wittgendorf",
                "label_fr": "Wittgendorf"
            },
            {
                "value": 43072,
                "label": "Steinigtwolmsdorf",
                "label_ar": "Steinigtwolmsdorf",
                "label_fr": "Steinigtwolmsdorf"
            },
            {
                "value": 43073,
                "label": "Blievenstorf",
                "label_ar": "بليفينستورف",
                "label_fr": "Blievenstorf"
            },
            {
                "value": 43074,
                "label": "Lamspringe",
                "label_ar": "لامسبرينج",
                "label_fr": "Lamspringe"
            },
            {
                "value": 43075,
                "label": "Bad Eilsen",
                "label_ar": "باد ايلسن",
                "label_fr": "Bad Eilsen"
            },
            {
                "value": 43076,
                "label": "Salzstetten",
                "label_ar": "Salzstetten",
                "label_fr": "Salzstetten"
            },
            {
                "value": 43077,
                "label": "Stetten",
                "label_ar": "ستيتن",
                "label_fr": "Stetten"
            },
            {
                "value": 43078,
                "label": "Pastetten",
                "label_ar": "باستيتين",
                "label_fr": "Pastetten"
            },
            {
                "value": 43079,
                "label": "Baierbrunn",
                "label_ar": "بايربرون",
                "label_fr": "Baierbrunn"
            },
            {
                "value": 43080,
                "label": "Ilmmunster",
                "label_ar": "Ilmmunster",
                "label_fr": "Ilmmunster"
            },
            {
                "value": 43081,
                "label": "Jemgum",
                "label_ar": "جمجم",
                "label_fr": "Jemgum"
            },
            {
                "value": 43082,
                "label": "Terborg",
                "label_ar": "تيربورغ",
                "label_fr": "Terborg"
            },
            {
                "value": 43083,
                "label": "Rechtsupweg",
                "label_ar": "Rechtsupweg",
                "label_fr": "Rechtsupweg"
            },
            {
                "value": 43084,
                "label": "Rhede",
                "label_ar": "ريدي",
                "label_fr": "Rhede"
            },
            {
                "value": 43085,
                "label": "Esterwegen",
                "label_ar": "Esterwegen",
                "label_fr": "Esterwegen"
            },
            {
                "value": 43086,
                "label": "Osterbrock",
                "label_ar": "أوستربروك",
                "label_fr": "Osterbrock"
            },
            {
                "value": 43087,
                "label": "Emsburen",
                "label_ar": "إمسبورن",
                "label_fr": "Emsburen"
            },
            {
                "value": 43088,
                "label": "Graveneck",
                "label_ar": "جرافينيك",
                "label_fr": "Graveneck"
            },
            {
                "value": 43089,
                "label": "Frankenau",
                "label_ar": "فرانكيناو",
                "label_fr": "Frankenau"
            },
            {
                "value": 43090,
                "label": "Linden",
                "label_ar": "الزيزفون",
                "label_fr": "Tilleul"
            },
            {
                "value": 43091,
                "label": "Bischoffen",
                "label_ar": "بيشوفن",
                "label_fr": "Bischoffen"
            },
            {
                "value": 43092,
                "label": "Lahr",
                "label_ar": "لار",
                "label_fr": "Lahr"
            },
            {
                "value": 43093,
                "label": "Ebeleben",
                "label_ar": "إبيلبن",
                "label_fr": "Ebeleben"
            },
            {
                "value": 43094,
                "label": "Wildbergerhutte",
                "label_ar": "ويلدبيرجيرهوت",
                "label_fr": "Wildbergerhutte"
            },
            {
                "value": 43095,
                "label": "Weddel",
                "label_ar": "ويديل",
                "label_fr": "Weddel"
            },
            {
                "value": 43096,
                "label": "Veltheim",
                "label_ar": "فيلتهايم",
                "label_fr": "Veltheim"
            },
            {
                "value": 43097,
                "label": "Wriedel",
                "label_ar": "ويريدل",
                "label_fr": "Wriedel"
            },
            {
                "value": 43098,
                "label": "Gerdau",
                "label_ar": "جيرداو",
                "label_fr": "Gerdau"
            },
            {
                "value": 43099,
                "label": "Ribbesbuttel",
                "label_ar": "ريبسبوتيل",
                "label_fr": "Ribbesbuttel"
            },
            {
                "value": 43100,
                "label": "Vogtareuth",
                "label_ar": "فوجتاروث",
                "label_fr": "Vogtareuth"
            },
            {
                "value": 43101,
                "label": "Einsbach",
                "label_ar": "أينسباخ",
                "label_fr": "Einsbach"
            },
            {
                "value": 43102,
                "label": "Samerberg",
                "label_ar": "سامربرج",
                "label_fr": "Samerberg"
            },
            {
                "value": 43103,
                "label": "Golzow",
                "label_ar": "جولزو",
                "label_fr": "Golzow"
            },
            {
                "value": 43104,
                "label": "Wusterwitz",
                "label_ar": "Wusterwitz",
                "label_fr": "Wusterwitz"
            },
            {
                "value": 43105,
                "label": "Kiez",
                "label_ar": "كيز",
                "label_fr": "Kiez"
            },
            {
                "value": 43106,
                "label": "Raben",
                "label_ar": "رابين",
                "label_fr": "Raben"
            },
            {
                "value": 43107,
                "label": "Wenzlow",
                "label_ar": "وينزلو",
                "label_fr": "Wenzlow"
            },
            {
                "value": 43108,
                "label": "Niemegk",
                "label_ar": "نيميك",
                "label_fr": "Niemegk"
            },
            {
                "value": 43109,
                "label": "Milow",
                "label_ar": "ميلو",
                "label_fr": "Milow"
            },
            {
                "value": 43110,
                "label": "Hausen am Tann",
                "label_ar": "Hausen am Tann",
                "label_fr": "Hausen am Tann"
            },
            {
                "value": 43111,
                "label": "Barenthal",
                "label_ar": "بارينثال",
                "label_fr": "Barenthal"
            },
            {
                "value": 43112,
                "label": "Boich",
                "label_ar": "بويش",
                "label_fr": "Boich"
            },
            {
                "value": 43113,
                "label": "Mutzenich",
                "label_ar": "موتزينيتش",
                "label_fr": "Mutzenich"
            },
            {
                "value": 43114,
                "label": "Osterhofen",
                "label_ar": "أوسترهوفن",
                "label_fr": "Osterhofen"
            },
            {
                "value": 43115,
                "label": "Salzweg",
                "label_ar": "سالزويج",
                "label_fr": "Salzweg"
            },
            {
                "value": 43116,
                "label": "Eggebek",
                "label_ar": "Eggebek",
                "label_fr": "Eggebek"
            },
            {
                "value": 43117,
                "label": "Langenhorn",
                "label_ar": "لانجينهورن",
                "label_fr": "Langenhorn"
            },
            {
                "value": 43118,
                "label": "Gammellund",
                "label_ar": "جاميلوند",
                "label_fr": "Gammellund"
            },
            {
                "value": 43119,
                "label": "Ulsnis",
                "label_ar": "أولسنيس",
                "label_fr": "Ulsnis"
            },
            {
                "value": 43120,
                "label": "Bordelum",
                "label_ar": "بورديلوم",
                "label_fr": "Bordelum"
            },
            {
                "value": 43121,
                "label": "Klanxbull",
                "label_ar": "Klanxbull",
                "label_fr": "Klanxbull"
            },
            {
                "value": 43122,
                "label": "Fargau-Pratjau",
                "label_ar": "فارغاو براتجاو",
                "label_fr": "Fargau-Pratjau"
            },
            {
                "value": 43123,
                "label": "Delve",
                "label_ar": "يخوض",
                "label_fr": "Creuser"
            },
            {
                "value": 43124,
                "label": "Landrecht",
                "label_ar": "لاندريخت",
                "label_fr": "Landrecht"
            },
            {
                "value": 43125,
                "label": "Dietersburg",
                "label_ar": "ديترسبيرغ",
                "label_fr": "Dietersburg"
            },
            {
                "value": 43126,
                "label": "Hohenthann",
                "label_ar": "هوهينثان",
                "label_fr": "Hohenthann"
            },
            {
                "value": 43127,
                "label": "Greussenheim",
                "label_ar": "جراوسنهايم",
                "label_fr": "Greussenheim"
            },
            {
                "value": 43128,
                "label": "Margetshochheim",
                "label_ar": "Margetshochheim",
                "label_fr": "Margetshochheim"
            },
            {
                "value": 43129,
                "label": "Euerbach",
                "label_ar": "يورباخ",
                "label_fr": "Euerbach"
            },
            {
                "value": 43130,
                "label": "Vluyn",
                "label_ar": "فلوين",
                "label_fr": "Vluyn"
            },
            {
                "value": 43131,
                "label": "Suderau",
                "label_ar": "سودراو",
                "label_fr": "Suderau"
            },
            {
                "value": 43132,
                "label": "Volkersweiler",
                "label_ar": "فولكرسويلر",
                "label_fr": "Volkersweiler"
            },
            {
                "value": 43133,
                "label": "Esthal",
                "label_ar": "إسثال",
                "label_fr": "Esthal"
            },
            {
                "value": 43134,
                "label": "Bockenheim",
                "label_ar": "بوكينهايم",
                "label_fr": "Bockenheim"
            },
            {
                "value": 43135,
                "label": "Kirchtimke",
                "label_ar": "كيرشتمكي",
                "label_fr": "Kirchtimke"
            },
            {
                "value": 43136,
                "label": "Neverin",
                "label_ar": "نيفرين",
                "label_fr": "Neverin"
            },
            {
                "value": 43137,
                "label": "Driftsethe",
                "label_ar": "الانجراف",
                "label_fr": "Dérive"
            },
            {
                "value": 43138,
                "label": "Wremen",
                "label_ar": "وريمين",
                "label_fr": "Wremen"
            },
            {
                "value": 43139,
                "label": "Ueterlande",
                "label_ar": "Ueterlande",
                "label_fr": "Ueterlande"
            },
            {
                "value": 43140,
                "label": "Bramstedt",
                "label_ar": "برامستيدت",
                "label_fr": "Bramstedt"
            },
            {
                "value": 43141,
                "label": "Midlum",
                "label_ar": "ميدلوم",
                "label_fr": "Midlum"
            },
            {
                "value": 43142,
                "label": "Mulsum",
                "label_ar": "مولسوم",
                "label_fr": "Mulsum"
            },
            {
                "value": 43143,
                "label": "Tapfheim",
                "label_ar": "تابفهايم",
                "label_fr": "Tapfheim"
            },
            {
                "value": 43144,
                "label": "Obermeitingen",
                "label_ar": "Obermeitingen",
                "label_fr": "Obermeitingen"
            },
            {
                "value": 43145,
                "label": "Ahrensmoor",
                "label_ar": "أرينسمور",
                "label_fr": "Ahrensmoor"
            },
            {
                "value": 43146,
                "label": "Albersdorf",
                "label_ar": "ألبيرسدورف",
                "label_fr": "Albersdorf"
            },
            {
                "value": 43147,
                "label": "Martensrade",
                "label_ar": "Martensrade",
                "label_fr": "Martensrade"
            },
            {
                "value": 43148,
                "label": "Riepsdorf",
                "label_ar": "ريبسدورف",
                "label_fr": "Riepsdorf"
            },
            {
                "value": 43149,
                "label": "Selent",
                "label_ar": "سيلينت",
                "label_fr": "Selent"
            },
            {
                "value": 43150,
                "label": "Kellenhusen",
                "label_ar": "Kellenhusen",
                "label_fr": "Kellenhusen"
            },
            {
                "value": 43151,
                "label": "Loose",
                "label_ar": "واسع",
                "label_fr": "Ample"
            },
            {
                "value": 43152,
                "label": "Gross Wittensee",
                "label_ar": "جروس ويتينسي",
                "label_fr": "Gross Wittensee"
            },
            {
                "value": 43153,
                "label": "Nordhastedt",
                "label_ar": "نوردهاستيدت",
                "label_fr": "Nordhastedt"
            },
            {
                "value": 43154,
                "label": "Wester-Ohrstedt",
                "label_ar": "ويستر أورستدت",
                "label_fr": "Wester-Ohrstedt"
            },
            {
                "value": 43155,
                "label": "Sehlen",
                "label_ar": "سيلين",
                "label_fr": "Sehlen"
            },
            {
                "value": 43156,
                "label": "Tating",
                "label_ar": "تاتينج",
                "label_fr": "Tating"
            },
            {
                "value": 43157,
                "label": "Breiholz",
                "label_ar": "بريهولز",
                "label_fr": "Breiholz"
            },
            {
                "value": 43158,
                "label": "Susel",
                "label_ar": "سوسيل",
                "label_fr": "Susel"
            },
            {
                "value": 43159,
                "label": "Wesseln",
                "label_ar": "ويسلن",
                "label_fr": "Wesseln"
            },
            {
                "value": 43160,
                "label": "Krumstedt",
                "label_ar": "كرومستيدت",
                "label_fr": "Krumstedt"
            },
            {
                "value": 43161,
                "label": "Satjendorf",
                "label_ar": "ساتجيندورف",
                "label_fr": "Satjendorf"
            },
            {
                "value": 43162,
                "label": "Barkelsby",
                "label_ar": "باركيلسبي",
                "label_fr": "Barkelsby"
            },
            {
                "value": 43163,
                "label": "Lohe-Rickelshof",
                "label_ar": "Lohe-Rickelshof",
                "label_fr": "Lohe-Rickelshof"
            },
            {
                "value": 43164,
                "label": "Otterndorf",
                "label_ar": "أوترندورف",
                "label_fr": "Otterndorf"
            },
            {
                "value": 43165,
                "label": "Belum",
                "label_ar": "بيلوم",
                "label_fr": "Belum"
            },
            {
                "value": 43166,
                "label": "Schopfloch",
                "label_ar": "Schopfloch",
                "label_fr": "Schopfloch"
            },
            {
                "value": 43167,
                "label": "Glatten",
                "label_ar": "جلاتين",
                "label_fr": "Glatten"
            },
            {
                "value": 43168,
                "label": "Strassberg",
                "label_ar": "ستراسبيرغ",
                "label_fr": "Strassberg"
            },
            {
                "value": 43169,
                "label": "Bischofsgrun",
                "label_ar": "Bischofsgrun",
                "label_fr": "Bischofsgrun"
            },
            {
                "value": 43170,
                "label": "Schlammersdorf",
                "label_ar": "شالامرسدورف",
                "label_fr": "Schlammersdorf"
            },
            {
                "value": 43171,
                "label": "Barnau",
                "label_ar": "بارناو",
                "label_fr": "Barnau"
            },
            {
                "value": 43172,
                "label": "Glashutten",
                "label_ar": "جلاشتن",
                "label_fr": "Glashutten"
            },
            {
                "value": 43173,
                "label": "Fichtenwalde",
                "label_ar": "فيشتنفالد",
                "label_fr": "Fichtenwalde"
            },
            {
                "value": 43174,
                "label": "Ribbeck",
                "label_ar": "ريبيك",
                "label_fr": "Ribbeck"
            },
            {
                "value": 43175,
                "label": "Babow",
                "label_ar": "بابو",
                "label_fr": "Babow"
            },
            {
                "value": 43176,
                "label": "Brieske",
                "label_ar": "بريسكي",
                "label_fr": "Brieske"
            },
            {
                "value": 43177,
                "label": "Hennweiler",
                "label_ar": "هينويلر",
                "label_fr": "Hennweiler"
            },
            {
                "value": 43178,
                "label": "Flacht",
                "label_ar": "فلاخت",
                "label_fr": "Flacht"
            },
            {
                "value": 43179,
                "label": "Birlenbach",
                "label_ar": "بيرلينباخ",
                "label_fr": "Birlenbach"
            },
            {
                "value": 43180,
                "label": "Bergweiler",
                "label_ar": "برجفايلر",
                "label_fr": "Bergweiler"
            },
            {
                "value": 43181,
                "label": "Dausenau",
                "label_ar": "Dausenau",
                "label_fr": "Dausenau"
            },
            {
                "value": 43182,
                "label": "Liebenscheid",
                "label_ar": "ليبينشايد",
                "label_fr": "Liebenscheid"
            },
            {
                "value": 43183,
                "label": "Bach",
                "label_ar": "باخ",
                "label_fr": "Bach"
            },
            {
                "value": 43184,
                "label": "Hattert",
                "label_ar": "هاترت",
                "label_fr": "Hattert"
            },
            {
                "value": 43185,
                "label": "Wieselbach",
                "label_ar": "فيسيلباخ",
                "label_fr": "Wieselbach"
            },
            {
                "value": 43186,
                "label": "Lochum",
                "label_ar": "بحيرة لوخوم",
                "label_fr": "Lochum"
            },
            {
                "value": 43187,
                "label": "Hochstenbach",
                "label_ar": "Hochstenbach",
                "label_fr": "Hochstenbach"
            },
            {
                "value": 43188,
                "label": "Sankt Katharinen",
                "label_ar": "سانكت كاثرينين",
                "label_fr": "Sankt Katharinen"
            },
            {
                "value": 43189,
                "label": "Niederburg",
                "label_ar": "نيدربيرغ",
                "label_fr": "Niederburg"
            },
            {
                "value": 43190,
                "label": "Erpel",
                "label_ar": "اربيل",
                "label_fr": "Erpel"
            },
            {
                "value": 43191,
                "label": "Dattenberg",
                "label_ar": "داتنبرغ",
                "label_fr": "Dattenberg"
            },
            {
                "value": 43192,
                "label": "Wincheringen",
                "label_ar": "Wincheringen",
                "label_fr": "Wincheringen"
            },
            {
                "value": 43193,
                "label": "Riol",
                "label_ar": "ريول",
                "label_fr": "Riol"
            },
            {
                "value": 43194,
                "label": "Hof",
                "label_ar": "هوف",
                "label_fr": "Hof"
            },
            {
                "value": 43195,
                "label": "Hohn",
                "label_ar": "هون",
                "label_fr": "Hohn"
            },
            {
                "value": 43196,
                "label": "Norken",
                "label_ar": "نوركين",
                "label_fr": "Norken"
            },
            {
                "value": 43197,
                "label": "Hellenhahn-Schellenberg",
                "label_ar": "هيلينهان شلينبيرج",
                "label_fr": "Hellenhahn-Schellenberg"
            },
            {
                "value": 43198,
                "label": "Gutenacker",
                "label_ar": "جوتينكر",
                "label_fr": "Gutenacker"
            },
            {
                "value": 43199,
                "label": "Forst",
                "label_ar": "فورست",
                "label_fr": "Forst"
            },
            {
                "value": 43200,
                "label": "Steinmauern",
                "label_ar": "شتاينماورن",
                "label_fr": "Steinmauern"
            },
            {
                "value": 43201,
                "label": "Oberriexingen",
                "label_ar": "Oberriexingen",
                "label_fr": "Oberriexingen"
            },
            {
                "value": 43202,
                "label": "Rohrdorf",
                "label_ar": "روردورف",
                "label_fr": "Rohrdorf"
            },
            {
                "value": 43203,
                "label": "Borstel",
                "label_ar": "بورستل",
                "label_fr": "Borstel"
            },
            {
                "value": 43204,
                "label": "Beckeln",
                "label_ar": "بيكلن",
                "label_fr": "Beckeln"
            },
            {
                "value": 43205,
                "label": "Jeddeloh Eins",
                "label_ar": "جدلوه عين",
                "label_fr": "Djeddeloh Eins"
            },
            {
                "value": 43206,
                "label": "Marxen",
                "label_ar": "ماركسين",
                "label_fr": "Marxen"
            },
            {
                "value": 43207,
                "label": "Tangendorf",
                "label_ar": "تانجندورف",
                "label_fr": "Tangendorf"
            },
            {
                "value": 43208,
                "label": "Bargstedt",
                "label_ar": "بارجستيدت",
                "label_fr": "Bargstedt"
            },
            {
                "value": 43209,
                "label": "Wilstedt",
                "label_ar": "ويلستيدت",
                "label_fr": "Wilstedt"
            },
            {
                "value": 43210,
                "label": "Elmlohe",
                "label_ar": "الملوح",
                "label_fr": "Elmlohe"
            },
            {
                "value": 43211,
                "label": "Gyhum",
                "label_ar": "جيهم",
                "label_fr": "Gyhum"
            },
            {
                "value": 43212,
                "label": "Nortmoor",
                "label_ar": "نورتمور",
                "label_fr": "Nortmoor"
            },
            {
                "value": 43213,
                "label": "Augustfehn",
                "label_ar": "Augustfehn",
                "label_fr": "Augustfehn"
            },
            {
                "value": 43214,
                "label": "Molbergen",
                "label_ar": "مولبيرجين",
                "label_fr": "Molbergen"
            },
            {
                "value": 43215,
                "label": "Neukamperfehn",
                "label_ar": "نويكامبيرفين",
                "label_fr": "Neukamperfehn"
            },
            {
                "value": 43216,
                "label": "Westrhauderfehn",
                "label_ar": "Westrhauderfehn",
                "label_fr": "Westrhauderfehn"
            },
            {
                "value": 43217,
                "label": "Filsum",
                "label_ar": "الفلسوم",
                "label_fr": "Filsum"
            },
            {
                "value": 43218,
                "label": "Heinbockel",
                "label_ar": "هاينبوكل",
                "label_fr": "Heinbockel"
            },
            {
                "value": 43219,
                "label": "Horneburg",
                "label_ar": "هورنبرج",
                "label_fr": "Horneburg"
            },
            {
                "value": 43220,
                "label": "Hepstedt",
                "label_ar": "هيبستيدت",
                "label_fr": "Hepstedt"
            },
            {
                "value": 43221,
                "label": "Heeslingen",
                "label_ar": "هيسلينجن",
                "label_fr": "Heeslingen"
            },
            {
                "value": 43222,
                "label": "Lorup",
                "label_ar": "لوروب",
                "label_fr": "Lorup"
            },
            {
                "value": 43223,
                "label": "Hasel",
                "label_ar": "هاسيل",
                "label_fr": "Hasel"
            },
            {
                "value": 43224,
                "label": "Breitenfelde",
                "label_ar": "بريتنفيلد",
                "label_fr": "Breitenfelde"
            },
            {
                "value": 43225,
                "label": "Nusse",
                "label_ar": "نوس",
                "label_fr": "Nusse"
            },
            {
                "value": 43226,
                "label": "Schiphorst",
                "label_ar": "شيفورست",
                "label_fr": "Schiphorst"
            },
            {
                "value": 43227,
                "label": "Labenz",
                "label_ar": "لابينز",
                "label_fr": "Labenz"
            },
            {
                "value": 43228,
                "label": "Klein Wesenberg",
                "label_ar": "كلاين ويزنبرغ",
                "label_fr": "Klein Wesenberg"
            },
            {
                "value": 43229,
                "label": "Bliesdorf",
                "label_ar": "بليسدورف",
                "label_fr": "Bliesdorf"
            },
            {
                "value": 43230,
                "label": "Schulendorf",
                "label_ar": "شوليندورف",
                "label_fr": "Schulendorf"
            },
            {
                "value": 43231,
                "label": "Kastorf",
                "label_ar": "كاستورف",
                "label_fr": "Kastorf"
            },
            {
                "value": 43232,
                "label": "Duchelsdorf",
                "label_ar": "دوشلسدورف",
                "label_fr": "Duchelsdorf"
            },
            {
                "value": 43233,
                "label": "Lasbek",
                "label_ar": "لاسبك",
                "label_fr": "Lasbek"
            },
            {
                "value": 43234,
                "label": "Kirchheim am Ries",
                "label_ar": "كيرشهايم ام ريس",
                "label_fr": "Kirchheim am Ries"
            },
            {
                "value": 43235,
                "label": "Dischingen",
                "label_ar": "ديسكينج",
                "label_fr": "Dischingen"
            },
            {
                "value": 43236,
                "label": "Eging",
                "label_ar": "شيخ",
                "label_fr": "Eging"
            },
            {
                "value": 43237,
                "label": "Hilgert",
                "label_ar": "هيلجرت",
                "label_fr": "Hilgert"
            },
            {
                "value": 43238,
                "label": "Helmern",
                "label_ar": "هيلمرن",
                "label_fr": "Helmern"
            },
            {
                "value": 43239,
                "label": "Markt",
                "label_ar": "ماركت",
                "label_fr": "Markt"
            },
            {
                "value": 43240,
                "label": "Norddeich",
                "label_ar": "نوردديتش",
                "label_fr": "Norddeich"
            },
            {
                "value": 43241,
                "label": "Juliusburg",
                "label_ar": "جوليوسبرغ",
                "label_fr": "Juliusburg"
            },
            {
                "value": 43242,
                "label": "Wilster",
                "label_ar": "ويلستر",
                "label_fr": "Wilster"
            },
            {
                "value": 43243,
                "label": "Schenefeld",
                "label_ar": "شينفيلد",
                "label_fr": "Schenefeld"
            },
            {
                "value": 43244,
                "label": "Brande-Hornerkirchen",
                "label_ar": "براندي-هورنركيرشن",
                "label_fr": "Brande-Hornerkirchen"
            },
            {
                "value": 43245,
                "label": "Tharandt",
                "label_ar": "ثارانت",
                "label_fr": "Tharandt"
            },
            {
                "value": 43246,
                "label": "Netzschkau",
                "label_ar": "Netzschkau",
                "label_fr": "Netzschkau"
            },
            {
                "value": 43247,
                "label": "Marwitz",
                "label_ar": "مارويتز",
                "label_fr": "Marwitz"
            },
            {
                "value": 43248,
                "label": "Arft",
                "label_ar": "ارفت",
                "label_fr": "Arft"
            },
            {
                "value": 43249,
                "label": "Pomster",
                "label_ar": "بومستر",
                "label_fr": "Pomster"
            },
            {
                "value": 43250,
                "label": "Herresbach",
                "label_ar": "هيريسباخ",
                "label_fr": "Herresbach"
            },
            {
                "value": 43251,
                "label": "Ebertsheim",
                "label_ar": "إبرتسهايم",
                "label_fr": "Ebertsheim"
            },
            {
                "value": 43252,
                "label": "Niederding",
                "label_ar": "نيدردينغ",
                "label_fr": "Niederding"
            },
            {
                "value": 43253,
                "label": "Neustadt",
                "label_ar": "نيوشتات",
                "label_fr": "Neustadt"
            },
            {
                "value": 43254,
                "label": "Schloss",
                "label_ar": "شلوس",
                "label_fr": "Schloss"
            },
            {
                "value": 43255,
                "label": "Weidenhof",
                "label_ar": "ويدنهوف",
                "label_fr": "Weidenhof"
            },
            {
                "value": 43256,
                "label": "Karin",
                "label_ar": "كارين",
                "label_fr": "Karin"
            },
            {
                "value": 43257,
                "label": "Kreuz",
                "label_ar": "كروز",
                "label_fr": "Kreuz"
            },
            {
                "value": 43258,
                "label": "Vegesack",
                "label_ar": "فيجيساك",
                "label_fr": "Vegesack"
            },
            {
                "value": 43259,
                "label": "Beaumarais",
                "label_ar": "بوماريه",
                "label_fr": "Beaumarais"
            },
            {
                "value": 43260,
                "label": "Klostersee",
                "label_ar": "كلوسترسي",
                "label_fr": "Klostersee"
            },
            {
                "value": 43261,
                "label": "Ruit",
                "label_ar": "روت",
                "label_fr": "Ruit"
            },
            {
                "value": 43262,
                "label": "Balgheim",
                "label_ar": "بالجيم",
                "label_fr": "Balgheim"
            },
            {
                "value": 43263,
                "label": "Degerloch",
                "label_ar": "ديجيرلوش",
                "label_fr": "Degerloch"
            },
            {
                "value": 43264,
                "label": "Westeregeln",
                "label_ar": "ويستيرجيلن",
                "label_fr": "Westeregeln"
            },
            {
                "value": 43265,
                "label": "Plattenburg",
                "label_ar": "بلاتينبورغ",
                "label_fr": "Plattenburg"
            },
            {
                "value": 43266,
                "label": "Ziegendorf",
                "label_ar": "زييجندورف",
                "label_fr": "Ziegendorf"
            },
            {
                "value": 43267,
                "label": "Osternienburg",
                "label_ar": "أوسترنينبورغ",
                "label_fr": "Osternienburg"
            },
            {
                "value": 43268,
                "label": "Possendorf",
                "label_ar": "بوسندورف",
                "label_fr": "Possendorf"
            },
            {
                "value": 43269,
                "label": "Kitzscher",
                "label_ar": "كيتشر",
                "label_fr": "Kitzscher"
            },
            {
                "value": 43270,
                "label": "Freiwalde",
                "label_ar": "فرايوالد",
                "label_fr": "Freiwalde"
            },
            {
                "value": 43271,
                "label": "Dahme",
                "label_ar": "دهمي",
                "label_fr": "Dahme"
            },
            {
                "value": 43272,
                "label": "Lindenau",
                "label_ar": "لينديناو",
                "label_fr": "Lindenau"
            },
            {
                "value": 43273,
                "label": "Mittelsaida",
                "label_ar": "ميتالسيدا",
                "label_fr": "Mittelsaida"
            },
            {
                "value": 43274,
                "label": "Ritschenhausen",
                "label_ar": "ريتشنهاوزن",
                "label_fr": "Ritschenhausen"
            },
            {
                "value": 43275,
                "label": "Buttelstedt",
                "label_ar": "Buttelstedt",
                "label_fr": "Buttelstedt"
            },
            {
                "value": 43276,
                "label": "Rettersen",
                "label_ar": "إعادة",
                "label_fr": "Rettersen"
            },
            {
                "value": 43277,
                "label": "Blomesche Wildnis",
                "label_ar": "بلوميش ويلدنيس",
                "label_fr": "Blomesche Wildnis"
            },
            {
                "value": 43278,
                "label": "Nortorf",
                "label_ar": "نورتورف",
                "label_fr": "Nortorf"
            },
            {
                "value": 43279,
                "label": "Hollingstedt",
                "label_ar": "هولينجستيدت",
                "label_fr": "Hollingstedt"
            },
            {
                "value": 43280,
                "label": "Duingen",
                "label_ar": "دوينجين",
                "label_fr": "Duingen"
            },
            {
                "value": 43281,
                "label": "Barghorst",
                "label_ar": "بارغورست",
                "label_fr": "Barghorst"
            },
            {
                "value": 43282,
                "label": "Simmersfeld",
                "label_ar": "سيمرسفيلد",
                "label_fr": "Simmersfeld"
            },
            {
                "value": 43283,
                "label": "Tennenbronn",
                "label_ar": "تينينبرون",
                "label_fr": "Tennenbronn"
            },
            {
                "value": 43284,
                "label": "Moos",
                "label_ar": "موس",
                "label_fr": "Moos"
            },
            {
                "value": 43285,
                "label": "Ober-Erlenbach",
                "label_ar": "أوبر إرلينباخ",
                "label_fr": "Ober-Erlenbach"
            },
            {
                "value": 43286,
                "label": "Hohenfelde",
                "label_ar": "هوهنفيلدي",
                "label_fr": "Hohenfelde"
            },
            {
                "value": 43287,
                "label": "Kirchweyhe",
                "label_ar": "كيرشويهي",
                "label_fr": "Kirchweyhe"
            },
            {
                "value": 43288,
                "label": "Poggensee",
                "label_ar": "بوجينسي",
                "label_fr": "Poggensee"
            },
            {
                "value": 43289,
                "label": "Wanne-Eickel",
                "label_ar": "وان ايكل",
                "label_fr": "Wanne-Eickel"
            },
            {
                "value": 43290,
                "label": "Neuhardenberg",
                "label_ar": "نويهاردينبيرج",
                "label_fr": "Neuhardenberg"
            },
            {
                "value": 43291,
                "label": "Wettenberg",
                "label_ar": "Wettenberg",
                "label_fr": "Wettenberg"
            },
            {
                "value": 43292,
                "label": "Hemmelzen",
                "label_ar": "هيميلزين",
                "label_fr": "Hemmelzen"
            },
            {
                "value": 43293,
                "label": "Lochhausen",
                "label_ar": "لوشهاوزن",
                "label_fr": "Lochhausen"
            },
            {
                "value": 43294,
                "label": "Milbertshofen",
                "label_ar": "Milbertshofen",
                "label_fr": "Milbertshofen"
            },
            {
                "value": 43295,
                "label": "Laussnitz",
                "label_ar": "لاوسنيتز",
                "label_fr": "Laussnitz"
            },
            {
                "value": 43296,
                "label": "Osede",
                "label_ar": "أوسيدي",
                "label_fr": "Osede"
            },
            {
                "value": 43297,
                "label": "Rieste",
                "label_ar": "ريستي",
                "label_fr": "Rieste"
            },
            {
                "value": 43298,
                "label": "Rugheim",
                "label_ar": "روجيم",
                "label_fr": "Rugheim"
            },
            {
                "value": 43299,
                "label": "Weil",
                "label_ar": "ويل",
                "label_fr": "Weil"
            },
            {
                "value": 43300,
                "label": "Weissenfeld",
                "label_ar": "فايسنفيلد",
                "label_fr": "Weissenfeld"
            },
            {
                "value": 43301,
                "label": "Hopferau",
                "label_ar": "هوبفيراو",
                "label_fr": "Hopferau"
            },
            {
                "value": 43302,
                "label": "Stetten",
                "label_ar": "ستيتن",
                "label_fr": "Stetten"
            },
            {
                "value": 43303,
                "label": "Lutzerath",
                "label_ar": "لوتزيرات",
                "label_fr": "Lutzerath"
            },
            {
                "value": 43304,
                "label": "Filzen",
                "label_ar": "فيلزن",
                "label_fr": "Filzen"
            },
            {
                "value": 43305,
                "label": "Oberporlitz",
                "label_ar": "أوبربورليتز",
                "label_fr": "Oberporlitz"
            },
            {
                "value": 43306,
                "label": "Lindigshof",
                "label_ar": "لينديجشوف",
                "label_fr": "Lindigshof"
            },
            {
                "value": 43307,
                "label": "Kammerforst",
                "label_ar": "كاميرفورست",
                "label_fr": "Kammerforst"
            },
            {
                "value": 43308,
                "label": "In der Meer",
                "label_ar": "في دير مير",
                "label_fr": "In der Meer"
            },
            {
                "value": 43309,
                "label": "Giesenkirchen",
                "label_ar": "جيسينكيرشن",
                "label_fr": "Giesenkirchen"
            },
            {
                "value": 43310,
                "label": "Sulzgries",
                "label_ar": "سولزغريس",
                "label_fr": "Sulzgries"
            },
            {
                "value": 43311,
                "label": "Hochdorf",
                "label_ar": "هوشدورف",
                "label_fr": "Hochdorf"
            },
            {
                "value": 43312,
                "label": "Altingen",
                "label_ar": "ألتنغن",
                "label_fr": "Altingen"
            },
            {
                "value": 43313,
                "label": "Barbing",
                "label_ar": "الشائكة",
                "label_fr": "Barbelé"
            },
            {
                "value": 43314,
                "label": "Scheyern",
                "label_ar": "شييرن",
                "label_fr": "Scheyern"
            },
            {
                "value": 43315,
                "label": "Waffenbrunn",
                "label_ar": "وافينبرون",
                "label_fr": "Waffenbrunn"
            },
            {
                "value": 43316,
                "label": "Ihrlerstein",
                "label_ar": "ايرلرشتاين",
                "label_fr": "Ihrlerstein"
            },
            {
                "value": 43317,
                "label": "Krumhermersdorf",
                "label_ar": "كرومهيرمرسدورف",
                "label_fr": "Krumhermersdorf"
            },
            {
                "value": 43318,
                "label": "Hellweg",
                "label_ar": "هيلويج",
                "label_fr": "Hellweg"
            },
            {
                "value": 43319,
                "label": "Felm",
                "label_ar": "فيلم",
                "label_fr": "Felm"
            },
            {
                "value": 43320,
                "label": "Marienfeld",
                "label_ar": "مارينفيلد",
                "label_fr": "Marienfeld"
            },
            {
                "value": 43321,
                "label": "Hohenhorn",
                "label_ar": "هوهنهورن",
                "label_fr": "Hohenhorn"
            },
            {
                "value": 43322,
                "label": "Osdorf",
                "label_ar": "أوسدورف",
                "label_fr": "Osdorf"
            },
            {
                "value": 43323,
                "label": "Holtsee",
                "label_ar": "هولتسي",
                "label_fr": "Holtsee"
            },
            {
                "value": 43324,
                "label": "Nonnenhorn",
                "label_ar": "نونينهورن",
                "label_fr": "Nonnenhorn"
            },
            {
                "value": 43325,
                "label": "Oberpleichfeld",
                "label_ar": "Oberpleichfeld",
                "label_fr": "Oberpleichfeld"
            },
            {
                "value": 43326,
                "label": "Pleisweiler-Oberhofen",
                "label_ar": "بليسويلر أوبرهوفن",
                "label_fr": "Pleisweiler-Oberhofen"
            },
            {
                "value": 43327,
                "label": "Anrath",
                "label_ar": "أنرات",
                "label_fr": "Anrath"
            },
            {
                "value": 43328,
                "label": "Wienhausen",
                "label_ar": "وينهاوزن",
                "label_fr": "Wienhausen"
            },
            {
                "value": 43329,
                "label": "Altersbach",
                "label_ar": "الترسباخ",
                "label_fr": "Altersbach"
            },
            {
                "value": 43330,
                "label": "Salmtal",
                "label_ar": "Salmtal",
                "label_fr": "Salmtal"
            },
            {
                "value": 43331,
                "label": "Wolsendorf",
                "label_ar": "وولسيندورف",
                "label_fr": "Wolsendorf"
            },
            {
                "value": 43332,
                "label": "Roetz",
                "label_ar": "رويتز",
                "label_fr": "Roetz"
            },
            {
                "value": 43333,
                "label": "Bad Gogging",
                "label_ar": "تحضيرات سيئة",
                "label_fr": "Bad Gogging"
            },
            {
                "value": 43334,
                "label": "Frauenau",
                "label_ar": "فراوينو",
                "label_fr": "Frauenau"
            },
            {
                "value": 43335,
                "label": "Waldmunchen",
                "label_ar": "والدمنشن",
                "label_fr": "Waldmunchen"
            },
            {
                "value": 43336,
                "label": "Lupburg",
                "label_ar": "لوببورغ",
                "label_fr": "Lupburg"
            },
            {
                "value": 43337,
                "label": "Kleinwolmsdorf",
                "label_ar": "كلاينفولمسدورف",
                "label_fr": "Kleinwolmsdorf"
            },
            {
                "value": 43338,
                "label": "Stadt Wehlen",
                "label_ar": "شتات ويلين",
                "label_fr": "Stadt Wehlen"
            },
            {
                "value": 43339,
                "label": "Wisper",
                "label_ar": "ويسبر",
                "label_fr": "Wisper"
            },
            {
                "value": 43340,
                "label": "Niederneisen",
                "label_ar": "نيدرنايزن",
                "label_fr": "Niederneisen"
            },
            {
                "value": 43341,
                "label": "Mudershausen",
                "label_ar": "مودرسهاوزن",
                "label_fr": "Mudershausen"
            },
            {
                "value": 43342,
                "label": "Niederbrechen",
                "label_ar": "نيدربريشن",
                "label_fr": "Niederbrechen"
            },
            {
                "value": 43343,
                "label": "Hahnstatten",
                "label_ar": "هانشتاتن",
                "label_fr": "Hahnstatten"
            },
            {
                "value": 43344,
                "label": "Zorge",
                "label_ar": "زورج",
                "label_fr": "Zorge"
            },
            {
                "value": 43345,
                "label": "Sankt Andreasberg",
                "label_ar": "سانكت أندرياسبرج",
                "label_fr": "Sankt Andreasberg"
            },
            {
                "value": 43346,
                "label": "Betzendorf",
                "label_ar": "بيتزندورف",
                "label_fr": "Betzendorf"
            },
            {
                "value": 43347,
                "label": "Hohnstorf",
                "label_ar": "Hohnstorf",
                "label_fr": "Hohnstorf"
            },
            {
                "value": 43348,
                "label": "Prebitz",
                "label_ar": "بريبيتز",
                "label_fr": "Prebitz"
            },
            {
                "value": 43349,
                "label": "Gattendorf",
                "label_ar": "جاتندورف",
                "label_fr": "Gattendorf"
            },
            {
                "value": 43350,
                "label": "Eschenbach",
                "label_ar": "إشنباخ",
                "label_fr": "Eschenbach"
            },
            {
                "value": 43351,
                "label": "Caputh",
                "label_ar": "كابوث",
                "label_fr": "Caputh"
            },
            {
                "value": 43352,
                "label": "Ellefeld",
                "label_ar": "إليفيلد",
                "label_fr": "Ellefeld"
            },
            {
                "value": 43353,
                "label": "Haaren",
                "label_ar": "هارين",
                "label_fr": "Haaren"
            },
            {
                "value": 43354,
                "label": "Golssen",
                "label_ar": "جولسن",
                "label_fr": "Golssen"
            },
            {
                "value": 43355,
                "label": "Kraupa",
                "label_ar": "كراوبا",
                "label_fr": "Kraupa"
            },
            {
                "value": 43356,
                "label": "Hohenleipisch",
                "label_ar": "هوهنليبيش",
                "label_fr": "Hohenleipisch"
            },
            {
                "value": 43357,
                "label": "Schlieben",
                "label_ar": "شليبن",
                "label_fr": "Schlieben"
            },
            {
                "value": 43358,
                "label": "Lebusa",
                "label_ar": "ليبوسا",
                "label_fr": "Lebusa"
            },
            {
                "value": 43359,
                "label": "Crinitz",
                "label_ar": "كرينيتز",
                "label_fr": "Crinitz"
            },
            {
                "value": 43360,
                "label": "Breddorf",
                "label_ar": "بريدورف",
                "label_fr": "Breddorf"
            },
            {
                "value": 43361,
                "label": "Puchersreuth",
                "label_ar": "بوشرزروث",
                "label_fr": "Puchersreuth"
            },
            {
                "value": 43362,
                "label": "Edelsfeld",
                "label_ar": "ايدلسفيلد",
                "label_fr": "Edelsfeld"
            },
            {
                "value": 43363,
                "label": "Meerbeck",
                "label_ar": "ميربيك",
                "label_fr": "Meerbeck"
            },
            {
                "value": 43364,
                "label": "Zarrendorf",
                "label_ar": "زارندورف",
                "label_fr": "Zarrendorf"
            },
            {
                "value": 43365,
                "label": "Pantelitz",
                "label_ar": "بانتيليتز",
                "label_fr": "Pantelitz"
            },
            {
                "value": 43366,
                "label": "Bannemin",
                "label_ar": "بانيمين",
                "label_fr": "Bannemin"
            },
            {
                "value": 43367,
                "label": "Gahlkow",
                "label_ar": "جهلكو",
                "label_fr": "Gahlkow"
            },
            {
                "value": 43368,
                "label": "Molschow",
                "label_ar": "مولشوف",
                "label_fr": "Molschow"
            },
            {
                "value": 43369,
                "label": "Greppin",
                "label_ar": "جريبين",
                "label_fr": "Greppin"
            },
            {
                "value": 43370,
                "label": "Schmerz",
                "label_ar": "شمرز",
                "label_fr": "Schmerz"
            },
            {
                "value": 43371,
                "label": "Niederselters",
                "label_ar": "نيدرسلترس",
                "label_fr": "Niederselters"
            },
            {
                "value": 43372,
                "label": "Wohra",
                "label_ar": "الوهرة",
                "label_fr": "Wohra"
            },
            {
                "value": 43373,
                "label": "Amoneburg",
                "label_ar": "امونبورغ",
                "label_fr": "Amoneburg"
            },
            {
                "value": 43374,
                "label": "Eibelshausen",
                "label_ar": "ايبلسهاوزن",
                "label_fr": "Eibelshausen"
            },
            {
                "value": 43375,
                "label": "Scherenbostel",
                "label_ar": "شيرينبوستل",
                "label_fr": "Scherenbostel"
            },
            {
                "value": 43376,
                "label": "Gangkofen",
                "label_ar": "جانجكوفين",
                "label_fr": "Gangkofen"
            },
            {
                "value": 43377,
                "label": "Bayerbach",
                "label_ar": "بايرباخ",
                "label_fr": "Bayerbach"
            },
            {
                "value": 43378,
                "label": "Windorf",
                "label_ar": "ويندورف",
                "label_fr": "Windorf"
            },
            {
                "value": 43379,
                "label": "Furstenstein",
                "label_ar": "فورستنشتاين",
                "label_fr": "Furstenstein"
            },
            {
                "value": 43380,
                "label": "Niederalteich",
                "label_ar": "Niederalteich",
                "label_fr": "Niederalteich"
            },
            {
                "value": 43381,
                "label": "Brennberg",
                "label_ar": "برينبرغ",
                "label_fr": "Brennberg"
            },
            {
                "value": 43382,
                "label": "Niederwinkling",
                "label_ar": "نيدروينكلينج",
                "label_fr": "Niederwinkling"
            },
            {
                "value": 43383,
                "label": "Kinsau",
                "label_ar": "كينساو",
                "label_fr": "Kinsau"
            },
            {
                "value": 43384,
                "label": "Anzing",
                "label_ar": "أنزينغ",
                "label_fr": "Anzing"
            },
            {
                "value": 43385,
                "label": "Berzdorf",
                "label_ar": "بيرزدورف",
                "label_fr": "Berzdorf"
            },
            {
                "value": 43386,
                "label": "Paunzhausen",
                "label_ar": "باونزهاوزن",
                "label_fr": "Paunzhausen"
            },
            {
                "value": 43387,
                "label": "Hohenbocka",
                "label_ar": "هوهنبوكا",
                "label_fr": "Hohenbocka"
            },
            {
                "value": 43388,
                "label": "Benningen",
                "label_ar": "بنينجين",
                "label_fr": "Benningen"
            },
            {
                "value": 43389,
                "label": "Kraftisried",
                "label_ar": "كرافتيسريد",
                "label_fr": "Kraftisried"
            },
            {
                "value": 43390,
                "label": "Neckarwestheim",
                "label_ar": "نيكارويستيم",
                "label_fr": "Neckarwestheim"
            },
            {
                "value": 43391,
                "label": "Hohenfelde",
                "label_ar": "هوهنفيلدي",
                "label_fr": "Hohenfelde"
            },
            {
                "value": 43392,
                "label": "Wohrden",
                "label_ar": "Wohrden",
                "label_fr": "Wohrden"
            },
            {
                "value": 43393,
                "label": "Windeby",
                "label_ar": "عاصف",
                "label_fr": "Windeby"
            },
            {
                "value": 43394,
                "label": "Petersdorf",
                "label_ar": "بيترسدورف",
                "label_fr": "Petersdorf"
            },
            {
                "value": 43395,
                "label": "Langwitz",
                "label_ar": "لانغفيتز",
                "label_fr": "Langwitz"
            },
            {
                "value": 43396,
                "label": "Mollenhagen",
                "label_ar": "مولينهاغن",
                "label_fr": "Mollenhagen"
            },
            {
                "value": 43397,
                "label": "Peetsch",
                "label_ar": "بيتش",
                "label_fr": "Peetsch"
            },
            {
                "value": 43398,
                "label": "Schossin",
                "label_ar": "شوسين",
                "label_fr": "Schossin"
            },
            {
                "value": 43399,
                "label": "Fischingen",
                "label_ar": "فيشينغن",
                "label_fr": "Fischingen"
            },
            {
                "value": 43400,
                "label": "Heuweiler",
                "label_ar": "هوويلر",
                "label_fr": "Heuweiler"
            },
            {
                "value": 43401,
                "label": "Schackendorf",
                "label_ar": "شاكيندورف",
                "label_fr": "Schackendorf"
            },
            {
                "value": 43402,
                "label": "Neversdorf",
                "label_ar": "نيفرسدورف",
                "label_fr": "Neversdorf"
            },
            {
                "value": 43403,
                "label": "Kukels",
                "label_ar": "كوكلس",
                "label_fr": "Kukels"
            },
            {
                "value": 43404,
                "label": "Wittenborn",
                "label_ar": "ويتنبورن",
                "label_fr": "Wittenborn"
            },
            {
                "value": 43405,
                "label": "Hobbersdorf",
                "label_ar": "هوبيرسدورف",
                "label_fr": "Hobbersdorf"
            },
            {
                "value": 43406,
                "label": "Lindhorst",
                "label_ar": "ليندهورست",
                "label_fr": "Lindhorst"
            },
            {
                "value": 43407,
                "label": "Drubeck",
                "label_ar": "دروبيك",
                "label_fr": "Drubeck"
            },
            {
                "value": 43408,
                "label": "Derenburg",
                "label_ar": "ديرينبرج",
                "label_fr": "Derenburg"
            },
            {
                "value": 43409,
                "label": "Loderburg",
                "label_ar": "لوديربيرغ",
                "label_fr": "Loderburg"
            },
            {
                "value": 43410,
                "label": "Wienrode",
                "label_ar": "وينرود",
                "label_fr": "Wienrode"
            },
            {
                "value": 43411,
                "label": "Harmsdorf",
                "label_ar": "هارمسدورف",
                "label_fr": "Harmsdorf"
            },
            {
                "value": 43412,
                "label": "Klein-Schlamin",
                "label_ar": "كلاين شلامين",
                "label_fr": "Klein-Schlamin"
            },
            {
                "value": 43413,
                "label": "Wotersen",
                "label_ar": "ووترسن",
                "label_fr": "Wotersen"
            },
            {
                "value": 43414,
                "label": "Bentfeld",
                "label_ar": "بنتفيلد",
                "label_fr": "Bentfeld"
            },
            {
                "value": 43415,
                "label": "Unterammergau",
                "label_ar": "أونتيراميرغاو",
                "label_fr": "Unterammergau"
            },
            {
                "value": 43416,
                "label": "Wiesenbach",
                "label_ar": "فيزنباخ",
                "label_fr": "Wiesenbach"
            },
            {
                "value": 43417,
                "label": "Pickliessem",
                "label_ar": "مخلل",
                "label_fr": "Pickliessem"
            },
            {
                "value": 43418,
                "label": "Krettnach",
                "label_ar": "كريتناش",
                "label_fr": "Krettnach"
            },
            {
                "value": 43419,
                "label": "Densborn",
                "label_ar": "دينسبورن",
                "label_fr": "Densborn"
            },
            {
                "value": 43420,
                "label": "Neuschonau",
                "label_ar": "نويشوناو",
                "label_fr": "Neuschonau"
            },
            {
                "value": 43421,
                "label": "Lemberg",
                "label_ar": "ليمبيرج",
                "label_fr": "Lemberg"
            },
            {
                "value": 43422,
                "label": "Neuried",
                "label_ar": "عصبي",
                "label_fr": "Neuried"
            },
            {
                "value": 43423,
                "label": "Dabendorf",
                "label_ar": "دابندورف",
                "label_fr": "Dabendorf"
            },
            {
                "value": 43424,
                "label": "Stocksee",
                "label_ar": "ستوكسي",
                "label_fr": "Stocksee"
            },
            {
                "value": 43425,
                "label": "Grosshabersdorf",
                "label_ar": "Grosshabersdorf",
                "label_fr": "Grosshabersdorf"
            },
            {
                "value": 43426,
                "label": "Eggolsheim",
                "label_ar": "إيجولشيم",
                "label_fr": "Eggolsheim"
            },
            {
                "value": 43427,
                "label": "Oberpleis",
                "label_ar": "أوبربليس",
                "label_fr": "Oberpleis"
            },
            {
                "value": 43428,
                "label": "Effeltrich",
                "label_ar": "إيفلتريتش",
                "label_fr": "Effeltrich"
            },
            {
                "value": 43429,
                "label": "Poxdorf",
                "label_ar": "بوكسدورف",
                "label_fr": "Poxdorf"
            },
            {
                "value": 43430,
                "label": "Frankisch-Crumbach",
                "label_ar": "فرانكش كرومباخ",
                "label_fr": "Frankisch-Crumbach"
            },
            {
                "value": 43431,
                "label": "Konzell",
                "label_ar": "كونزيل",
                "label_fr": "Konzell"
            },
            {
                "value": 43432,
                "label": "Simbach",
                "label_ar": "سيمباخ",
                "label_fr": "Simbach"
            },
            {
                "value": 43433,
                "label": "Bobrach",
                "label_ar": "بوبراش",
                "label_fr": "Bobrach"
            },
            {
                "value": 43434,
                "label": "Rattenberg",
                "label_ar": "راتنبرغ",
                "label_fr": "Rattenberg"
            },
            {
                "value": 43435,
                "label": "Geiersthal",
                "label_ar": "جيرستال",
                "label_fr": "Geiersthal"
            },
            {
                "value": 43436,
                "label": "Wittenbeck",
                "label_ar": "ويتينبيك",
                "label_fr": "Wittenbeck"
            },
            {
                "value": 43437,
                "label": "Roggentin",
                "label_ar": "روجينتين",
                "label_fr": "Roggentine"
            },
            {
                "value": 43438,
                "label": "Reddelich",
                "label_ar": "ريديليتش",
                "label_fr": "Reddelich"
            },
            {
                "value": 43439,
                "label": "Neu Kaliss",
                "label_ar": "نيو كاليس",
                "label_fr": "Neu Kaliss"
            },
            {
                "value": 43440,
                "label": "Boldela",
                "label_ar": "بولديلا",
                "label_fr": "Boldela"
            },
            {
                "value": 43441,
                "label": "Lubesse",
                "label_ar": "لوبيس",
                "label_fr": "Lubesse"
            },
            {
                "value": 43442,
                "label": "Katlenburg-Lindau",
                "label_ar": "كاتلينبورغ لينداو",
                "label_fr": "Katlenburg-Lindau"
            },
            {
                "value": 43443,
                "label": "Mielenhausen",
                "label_ar": "ميلينهاوزن",
                "label_fr": "Mielenhausen"
            },
            {
                "value": 43444,
                "label": "Scheden",
                "label_ar": "جدولة",
                "label_fr": "Planifier"
            },
            {
                "value": 43445,
                "label": "Fredelsloh",
                "label_ar": "فريدلسلوه",
                "label_fr": "Fredelsloh"
            },
            {
                "value": 43446,
                "label": "Neckarrems",
                "label_ar": "نيكارريمس",
                "label_fr": "Neckarrems"
            },
            {
                "value": 43447,
                "label": "Kleinsteinhausen",
                "label_ar": "كلاينشتاينهاوزن",
                "label_fr": "Kleinsteinhausen"
            },
            {
                "value": 43448,
                "label": "Wahnwegen",
                "label_ar": "Wahnwegen",
                "label_fr": "Wahnwegen"
            },
            {
                "value": 43449,
                "label": "Niederalben",
                "label_ar": "نيدرالبين",
                "label_fr": "Niederalben"
            },
            {
                "value": 43450,
                "label": "Perkam",
                "label_ar": "بيركام",
                "label_fr": "Perkam"
            },
            {
                "value": 43451,
                "label": "Aholfing",
                "label_ar": "أهولفينج",
                "label_fr": "Aholfing"
            },
            {
                "value": 43452,
                "label": "Deggenhausen",
                "label_ar": "ديجينهاوزن",
                "label_fr": "Deggenhausen"
            },
            {
                "value": 43453,
                "label": "Hagnau",
                "label_ar": "هاغناو",
                "label_fr": "Hagnau"
            },
            {
                "value": 43454,
                "label": "Wollbrandshausen",
                "label_ar": "Wollbrandshausen",
                "label_fr": "Wollbrandshausen"
            },
            {
                "value": 43455,
                "label": "Kirchdorf am Inn",
                "label_ar": "كيرشدورف أم إن",
                "label_fr": "Kirchdorf am Inn"
            },
            {
                "value": 43456,
                "label": "Aicha vorm Wald",
                "label_ar": "عائشة فورم والد",
                "label_fr": "Aicha vorm Wald"
            },
            {
                "value": 43457,
                "label": "Schuttertal",
                "label_ar": "شوترال",
                "label_fr": "Schuttertal"
            },
            {
                "value": 43458,
                "label": "Weisenbach",
                "label_ar": "وايزنباخ",
                "label_fr": "Weisenbach"
            },
            {
                "value": 43459,
                "label": "Fronhofen",
                "label_ar": "فرونهوفن",
                "label_fr": "Fronhofen"
            },
            {
                "value": 43460,
                "label": "Kestert",
                "label_ar": "كيسترت",
                "label_fr": "Kestert"
            },
            {
                "value": 43461,
                "label": "Zornheim",
                "label_ar": "زورنهايم",
                "label_fr": "Zornheim"
            },
            {
                "value": 43462,
                "label": "Weitersborn",
                "label_ar": "ويترسبورن",
                "label_fr": "Weitersborn"
            },
            {
                "value": 43463,
                "label": "Hungenroth",
                "label_ar": "هنغنروث",
                "label_fr": "Hungenroth"
            },
            {
                "value": 43464,
                "label": "Lipporn",
                "label_ar": "ليبورن",
                "label_fr": "Lipporn"
            },
            {
                "value": 43465,
                "label": "Wiebelsheim",
                "label_ar": "Wiebelsheim",
                "label_fr": "Wiebelsheim"
            },
            {
                "value": 43466,
                "label": "Ruchsen",
                "label_ar": "روشسن",
                "label_fr": "Ruchsen"
            },
            {
                "value": 43467,
                "label": "Habach",
                "label_ar": "حبش",
                "label_fr": "Habach"
            },
            {
                "value": 43468,
                "label": "Hemmersdorf",
                "label_ar": "هيمرسدورف",
                "label_fr": "Hemmersdorf"
            },
            {
                "value": 43469,
                "label": "Trulben",
                "label_ar": "ترولبن",
                "label_fr": "Trulben"
            },
            {
                "value": 43470,
                "label": "Rohrbach",
                "label_ar": "روهرباخ",
                "label_fr": "Rohrbach"
            },
            {
                "value": 43471,
                "label": "Wustweiler",
                "label_ar": "Wustweiler",
                "label_fr": "Wustweiler"
            },
            {
                "value": 43472,
                "label": "Warnow",
                "label_ar": "وارنو",
                "label_fr": "Avertir"
            },
            {
                "value": 43473,
                "label": "Franzenheim",
                "label_ar": "فرانزينهايم",
                "label_fr": "Franzenheim"
            },
            {
                "value": 43474,
                "label": "Sellerich",
                "label_ar": "سيليريتش",
                "label_fr": "Sellerich"
            },
            {
                "value": 43475,
                "label": "Dackscheid",
                "label_ar": "Dackscheid",
                "label_fr": "Dackscheid"
            },
            {
                "value": 43476,
                "label": "Klotten",
                "label_ar": "كلوتن",
                "label_fr": "Klotten"
            },
            {
                "value": 43477,
                "label": "Aach",
                "label_ar": "آخ",
                "label_fr": "Aach"
            },
            {
                "value": 43478,
                "label": "Oberpierscheid",
                "label_ar": "Oberpierscheid",
                "label_fr": "Oberpierscheid"
            },
            {
                "value": 43479,
                "label": "Oberweis",
                "label_ar": "أوبيرفايس",
                "label_fr": "Oberweis"
            },
            {
                "value": 43480,
                "label": "Kalenborn-Scheuern",
                "label_ar": "كالينبورن شويرن",
                "label_fr": "Kalenborn-Scheuern"
            },
            {
                "value": 43481,
                "label": "Bleialf",
                "label_ar": "بليالف",
                "label_fr": "Bleialf"
            },
            {
                "value": 43482,
                "label": "Harburg",
                "label_ar": "هاربورغ",
                "label_fr": "Harburg"
            },
            {
                "value": 43483,
                "label": "Laugna",
                "label_ar": "لوجنا",
                "label_fr": "Laugna"
            },
            {
                "value": 43484,
                "label": "Nußdorf (Chiemgau)",
                "label_ar": "Nußdorf (Chiemgau)",
                "label_fr": "Nußdorf (Chiemgau)"
            },
            {
                "value": 43485,
                "label": "Oberndorf",
                "label_ar": "اوبرندورف",
                "label_fr": "Oberndorf"
            },
            {
                "value": 43486,
                "label": "Halvesbostel",
                "label_ar": "هالفيسبوستيل",
                "label_fr": "Halvesbostel"
            },
            {
                "value": 43487,
                "label": "Embsen",
                "label_ar": "امبسن",
                "label_fr": "Embsen"
            },
            {
                "value": 43488,
                "label": "Oberstreu",
                "label_ar": "اوبرسترو",
                "label_fr": "Oberstreu"
            },
            {
                "value": 43489,
                "label": "Neuhutten",
                "label_ar": "نويهوتن",
                "label_fr": "Neuhutten"
            },
            {
                "value": 43490,
                "label": "Ettringen",
                "label_ar": "إترينجن",
                "label_fr": "Ettringen"
            },
            {
                "value": 43491,
                "label": "Stotten",
                "label_ar": "ستوتن",
                "label_fr": "Stotten"
            },
            {
                "value": 43492,
                "label": "Lamerdingen",
                "label_ar": "لاميردينجن",
                "label_fr": "Lamerdingen"
            },
            {
                "value": 43493,
                "label": "Bad Wilsnack",
                "label_ar": "باد ويلسناك",
                "label_fr": "Bad Wilsnack"
            },
            {
                "value": 43494,
                "label": "Hohen Wangelin",
                "label_ar": "هوهن وانجلين",
                "label_fr": "Hohen Wangelin"
            },
            {
                "value": 43495,
                "label": "Vollrathsruhe",
                "label_ar": "فولراتسروه",
                "label_fr": "Vollrathsruhe"
            },
            {
                "value": 43496,
                "label": "Alt Schwerin",
                "label_ar": "ألت شفيرين",
                "label_fr": "Alt Schwerin"
            },
            {
                "value": 43497,
                "label": "Liepgarten",
                "label_ar": "ليبغارتن",
                "label_fr": "Liepgarten"
            },
            {
                "value": 43498,
                "label": "Satzvey",
                "label_ar": "ساتزفي",
                "label_fr": "Satzvey"
            },
            {
                "value": 43499,
                "label": "Tinnum",
                "label_ar": "تينوم",
                "label_fr": "Tinnum"
            },
            {
                "value": 43500,
                "label": "Rabenkirchen-Fauluck",
                "label_ar": "رابنكيرشن فولوك",
                "label_fr": "Rabenkirchen-Fauluck"
            },
            {
                "value": 43501,
                "label": "Grundhof",
                "label_ar": "جروندهوف",
                "label_fr": "Grundhof"
            },
            {
                "value": 43502,
                "label": "Meyn",
                "label_ar": "مين",
                "label_fr": "Meyn"
            },
            {
                "value": 43503,
                "label": "Medelby",
                "label_ar": "ميديلبي",
                "label_fr": "Medelby"
            },
            {
                "value": 43504,
                "label": "Lindenthal",
                "label_ar": "ليندينتال",
                "label_fr": "Lindenthal"
            },
            {
                "value": 43505,
                "label": "Ipsheim",
                "label_ar": "ايبشيم",
                "label_fr": "Ipsheim"
            },
            {
                "value": 43506,
                "label": "Wittnau",
                "label_ar": "ويتناو",
                "label_fr": "Wittnau"
            },
            {
                "value": 43507,
                "label": "Wasenweiler",
                "label_ar": "Wasenweiler",
                "label_fr": "Wasenweiler"
            },
            {
                "value": 43508,
                "label": "Bernau",
                "label_ar": "برناو",
                "label_fr": "Bernau"
            },
            {
                "value": 43509,
                "label": "Zell im Wiesental",
                "label_ar": "Zell im Wiesental",
                "label_fr": "Zell im Wiesental"
            },
            {
                "value": 43510,
                "label": "Kirchheilingen",
                "label_ar": "كيرشيلينجن",
                "label_fr": "Kirchheilingen"
            },
            {
                "value": 43511,
                "label": "Creuzburg",
                "label_ar": "كروزبرج",
                "label_fr": "Creuzburg"
            },
            {
                "value": 43512,
                "label": "Schwarzbach",
                "label_ar": "شوارزباخ",
                "label_fr": "Schwarzbach"
            },
            {
                "value": 43513,
                "label": "Oelzschau",
                "label_ar": "Oelzschau",
                "label_fr": "Oelzschau"
            },
            {
                "value": 43514,
                "label": "Wermsdorf",
                "label_ar": "ويرمسدورف",
                "label_fr": "Wermsdorf"
            },
            {
                "value": 43515,
                "label": "Espenhain",
                "label_ar": "اسبنهاين",
                "label_fr": "Espenhain"
            },
            {
                "value": 43516,
                "label": "Kossa",
                "label_ar": "كوسا",
                "label_fr": "Kossa"
            },
            {
                "value": 43517,
                "label": "Otterwisch",
                "label_ar": "Otterwisch",
                "label_fr": "Otterwisch"
            },
            {
                "value": 43518,
                "label": "Osterberg",
                "label_ar": "اوستربرغ",
                "label_fr": "Osterberg"
            },
            {
                "value": 43519,
                "label": "Sontheim",
                "label_ar": "سونتهايم",
                "label_fr": "Sontheim"
            },
            {
                "value": 43520,
                "label": "Bodolz",
                "label_ar": "بودولز",
                "label_fr": "Bodolz"
            },
            {
                "value": 43521,
                "label": "Wolpinghausen",
                "label_ar": "Wolpinghausen",
                "label_fr": "Wolpinghausen"
            },
            {
                "value": 43522,
                "label": "Schlepzig",
                "label_ar": "شليبزيغ",
                "label_fr": "Schlepzig"
            },
            {
                "value": 43523,
                "label": "Zerre",
                "label_ar": "زير",
                "label_fr": "Zerre"
            },
            {
                "value": 43524,
                "label": "Fichtenberg",
                "label_ar": "فيشتنبرغ",
                "label_fr": "Fichtenberg"
            },
            {
                "value": 43525,
                "label": "Paulinenaue",
                "label_ar": "Paulinenaue",
                "label_fr": "Paulinenaue"
            },
            {
                "value": 43526,
                "label": "Friesack",
                "label_ar": "فرايزاك",
                "label_fr": "Fritesack"
            },
            {
                "value": 43527,
                "label": "Dessow",
                "label_ar": "ديسو",
                "label_fr": "Dessow"
            },
            {
                "value": 43528,
                "label": "Kirschau",
                "label_ar": "كيرشاو",
                "label_fr": "Kirschau"
            },
            {
                "value": 43529,
                "label": "Gnaschwitz",
                "label_ar": "جناشويتز",
                "label_fr": "Gnaschwitz"
            },
            {
                "value": 43530,
                "label": "Schlegel",
                "label_ar": "شليغل",
                "label_fr": "Schlegel"
            },
            {
                "value": 43531,
                "label": "Aldekerk",
                "label_ar": "الديكيرك",
                "label_fr": "Aldekerk"
            },
            {
                "value": 43532,
                "label": "Emmerzhausen",
                "label_ar": "إيمرزهاوزن",
                "label_fr": "Emmerzhausen"
            },
            {
                "value": 43533,
                "label": "Dungenheim",
                "label_ar": "دونجنهايم",
                "label_fr": "Dungenheim"
            },
            {
                "value": 43534,
                "label": "Siershahn",
                "label_ar": "سيرشاهن",
                "label_fr": "Siershahn"
            },
            {
                "value": 43535,
                "label": "Oberlahr",
                "label_ar": "أوبيرلهر",
                "label_fr": "Oberlahr"
            },
            {
                "value": 43536,
                "label": "Sorth",
                "label_ar": "سورث",
                "label_fr": "Sorth"
            },
            {
                "value": 43537,
                "label": "Oberfell",
                "label_ar": "أوبرفيل",
                "label_fr": "Oberfell"
            },
            {
                "value": 43538,
                "label": "Bell",
                "label_ar": "جرس",
                "label_fr": "Cloche"
            },
            {
                "value": 43539,
                "label": "Neitersen",
                "label_ar": "نيترين",
                "label_fr": "Neitersen"
            },
            {
                "value": 43540,
                "label": "Freisbach",
                "label_ar": "فريسباخ",
                "label_fr": "Freisbach"
            },
            {
                "value": 43541,
                "label": "Binau",
                "label_ar": "بينو",
                "label_fr": "Binau"
            },
            {
                "value": 43542,
                "label": "Bornheim",
                "label_ar": "بورنهايم",
                "label_fr": "Bornheim"
            },
            {
                "value": 43543,
                "label": "Mertendorf",
                "label_ar": "ميرتندورف",
                "label_fr": "Mertendorf"
            },
            {
                "value": 43544,
                "label": "Reichardtswerben",
                "label_ar": "Reichardtswerben",
                "label_fr": "Reichardtswerben"
            },
            {
                "value": 43545,
                "label": "Wolferode",
                "label_ar": "ولفرود",
                "label_fr": "Wolferode"
            },
            {
                "value": 43546,
                "label": "Farnstadt",
                "label_ar": "فارنشتات",
                "label_fr": "Farnstadt"
            },
            {
                "value": 43547,
                "label": "Riedbach",
                "label_ar": "ريدباخ",
                "label_fr": "Riedbach"
            },
            {
                "value": 43548,
                "label": "Unterschleichach",
                "label_ar": "Unterschleichach",
                "label_fr": "Unterschleichach"
            },
            {
                "value": 43549,
                "label": "Riedbach",
                "label_ar": "ريدباخ",
                "label_fr": "Riedbach"
            },
            {
                "value": 43550,
                "label": "Uchtelhausen",
                "label_ar": "أوشتيلهاوزن",
                "label_fr": "Uchtelhausen"
            },
            {
                "value": 43551,
                "label": "Prichsenstadt",
                "label_ar": "Prichsenstadt",
                "label_fr": "Prichsenstadt"
            },
            {
                "value": 43552,
                "label": "Allmendingen",
                "label_ar": "ألميندين",
                "label_fr": "Allmendingen"
            },
            {
                "value": 43553,
                "label": "Tomerdingen",
                "label_ar": "تومردينجن",
                "label_fr": "Tomerdingen"
            },
            {
                "value": 43554,
                "label": "Titting",
                "label_ar": "دغدغة",
                "label_fr": "Seins"
            },
            {
                "value": 43555,
                "label": "Teugn",
                "label_ar": "تيوجن",
                "label_fr": "Teugn"
            },
            {
                "value": 43556,
                "label": "Hornburg",
                "label_ar": "هورنبرج",
                "label_fr": "Hornburg"
            },
            {
                "value": 43557,
                "label": "Melchow",
                "label_ar": "Melchow",
                "label_fr": "Melchow"
            },
            {
                "value": 43558,
                "label": "Ivenack",
                "label_ar": "إيفيناك",
                "label_fr": "Ivenack"
            },
            {
                "value": 43559,
                "label": "Stockstadt am Rhein",
                "label_ar": "ستوكشتات ام راين",
                "label_fr": "Stockstadt am Rhein"
            },
            {
                "value": 43560,
                "label": "Burgschwalbach",
                "label_ar": "بورغشوالباخ",
                "label_fr": "Burgschwalbach"
            },
            {
                "value": 43561,
                "label": "Immenreuth",
                "label_ar": "ايمينروث",
                "label_fr": "Immenreuth"
            },
            {
                "value": 43562,
                "label": "Neudrossenfeld",
                "label_ar": "نيودروسنفيلد",
                "label_fr": "Neudrossenfeld"
            },
            {
                "value": 43563,
                "label": "Romrod",
                "label_ar": "رومرود",
                "label_fr": "Romrod"
            },
            {
                "value": 43564,
                "label": "Breitenbach am Herzberg",
                "label_ar": "بريتنباخ ام هيرزبرج",
                "label_fr": "Breitenbach am Herzberg"
            },
            {
                "value": 43565,
                "label": "Heubach",
                "label_ar": "هيوباتش",
                "label_fr": "Heubach"
            },
            {
                "value": 43566,
                "label": "Furstenberg",
                "label_ar": "فورستنبرج",
                "label_fr": "Furstenberg"
            },
            {
                "value": 43567,
                "label": "Sauensiek",
                "label_ar": "سوينسيك",
                "label_fr": "Sauensiek"
            },
            {
                "value": 43568,
                "label": "Twielenfleth",
                "label_ar": "تويلنفليث",
                "label_fr": "Twielenfleth"
            },
            {
                "value": 43569,
                "label": "Hollern",
                "label_ar": "هوليرن",
                "label_fr": "Hollern"
            },
            {
                "value": 43570,
                "label": "Beckdorf",
                "label_ar": "بيكدورف",
                "label_fr": "Beckdorf"
            },
            {
                "value": 43571,
                "label": "Oring",
                "label_ar": "أورينج",
                "label_fr": "Oring"
            },
            {
                "value": 43572,
                "label": "Unterhausen",
                "label_ar": "أونترهاوزن",
                "label_fr": "Unterhausen"
            },
            {
                "value": 43573,
                "label": "Oberhausen",
                "label_ar": "أوبرهاوزن",
                "label_fr": "Oberhausen"
            },
            {
                "value": 43574,
                "label": "Alten",
                "label_ar": "ألتن",
                "label_fr": "Alten"
            },
            {
                "value": 43575,
                "label": "Raschau",
                "label_ar": "راشاو",
                "label_fr": "Raschau"
            },
            {
                "value": 43576,
                "label": "Brekendorf",
                "label_ar": "بريكندورف",
                "label_fr": "Brekendorf"
            },
            {
                "value": 43577,
                "label": "Schwesing",
                "label_ar": "شويسينغ",
                "label_fr": "Schwesing"
            },
            {
                "value": 43578,
                "label": "Gutach (Schwarzwaldbahn)",
                "label_ar": "جوتاش (شوارزوالدبان)",
                "label_fr": "Gutach (Schwarzwaldbahn)"
            },
            {
                "value": 43579,
                "label": "Zuzenhausen",
                "label_ar": "زوزنهاوزن",
                "label_fr": "Zuzenhausen"
            },
            {
                "value": 43580,
                "label": "Rechberg",
                "label_ar": "Rechberg",
                "label_fr": "Rechberg"
            },
            {
                "value": 43581,
                "label": "Argenthal",
                "label_ar": "أرجينتال",
                "label_fr": "Argenthal"
            },
            {
                "value": 43582,
                "label": "Vendersheim",
                "label_ar": "فيندرسهايم",
                "label_fr": "Vendersheim"
            },
            {
                "value": 43583,
                "label": "Lorscheid",
                "label_ar": "لورشيد",
                "label_fr": "Lorscheid"
            },
            {
                "value": 43584,
                "label": "Nachtsheim",
                "label_ar": "ناتشتشايم",
                "label_fr": "Nachtsheim"
            },
            {
                "value": 43585,
                "label": "Auerbach",
                "label_ar": "أورباخ",
                "label_fr": "Auerbach"
            },
            {
                "value": 43586,
                "label": "Angern",
                "label_ar": "انجرن",
                "label_fr": "Angern"
            },
            {
                "value": 43587,
                "label": "Karstadt",
                "label_ar": "كارستادت",
                "label_fr": "Karstadt"
            },
            {
                "value": 43588,
                "label": "Neukirchen",
                "label_ar": "نويكيرشن",
                "label_fr": "Neukirchen"
            },
            {
                "value": 43589,
                "label": "Redefin",
                "label_ar": "إعادة تعريف",
                "label_fr": "Redéfinir"
            },
            {
                "value": 43590,
                "label": "Zapel",
                "label_ar": "زابل",
                "label_fr": "Zapel"
            },
            {
                "value": 43591,
                "label": "Bollstein",
                "label_ar": "بولستين",
                "label_fr": "Bollstein"
            },
            {
                "value": 43592,
                "label": "Romsthal",
                "label_ar": "رومستال",
                "label_fr": "Romsthal"
            },
            {
                "value": 43593,
                "label": "Dibbersen",
                "label_ar": "ديبيرسن",
                "label_fr": "Dibbersen"
            },
            {
                "value": 43594,
                "label": "Jheringsfehn",
                "label_ar": "جيرينغسفين",
                "label_fr": "Jheringsfehn"
            },
            {
                "value": 43595,
                "label": "Thuine",
                "label_ar": "حقيقي",
                "label_fr": "Thuine"
            },
            {
                "value": 43596,
                "label": "Dersum",
                "label_ar": "ديرسوم",
                "label_fr": "Dersum"
            },
            {
                "value": 43597,
                "label": "Breddenberg",
                "label_ar": "Breddenberg",
                "label_fr": "Breddenberg"
            },
            {
                "value": 43598,
                "label": "Walchum",
                "label_ar": "والشوم",
                "label_fr": "Walchum"
            },
            {
                "value": 43599,
                "label": "Klein Meckelsen",
                "label_ar": "كلاين ميكلسن",
                "label_fr": "Klein Meckelsen"
            },
            {
                "value": 43600,
                "label": "Habenhausen",
                "label_ar": "هابنهاوزن",
                "label_fr": "Habenhausen"
            },
            {
                "value": 43601,
                "label": "Wallendorf",
                "label_ar": "واليندورف",
                "label_fr": "Wallendorf"
            },
            {
                "value": 43602,
                "label": "Huffelsheim",
                "label_ar": "هافلسهايم",
                "label_fr": "Huffelsheim"
            },
            {
                "value": 43603,
                "label": "Biesingen",
                "label_ar": "بيسينجين",
                "label_fr": "Biesingen"
            },
            {
                "value": 43604,
                "label": "Feilbingert",
                "label_ar": "فيلبنجيرت",
                "label_fr": "Feilbingert"
            },
            {
                "value": 43605,
                "label": "Traisen",
                "label_ar": "ترايسن",
                "label_fr": "Traisen"
            },
            {
                "value": 43606,
                "label": "Meudt",
                "label_ar": "ميودت",
                "label_fr": "Meudt"
            },
            {
                "value": 43607,
                "label": "Wiesbach",
                "label_ar": "فيزباخ",
                "label_fr": "Wiesbach"
            },
            {
                "value": 43608,
                "label": "Armsheim",
                "label_ar": "ارمشيم",
                "label_fr": "Armsheim"
            },
            {
                "value": 43609,
                "label": "Keidelheim",
                "label_ar": "كيدلهايم",
                "label_fr": "Keidelheim"
            },
            {
                "value": 43610,
                "label": "Mutterschied",
                "label_ar": "تمتم",
                "label_fr": "Mutterschied"
            },
            {
                "value": 43611,
                "label": "Theley",
                "label_ar": "ثيلي",
                "label_fr": "Theley"
            },
            {
                "value": 43612,
                "label": "Bad Klosterlausnitz",
                "label_ar": "باد كلوسترلاوسنيتز",
                "label_fr": "Bad Klosterlausnitz"
            },
            {
                "value": 43613,
                "label": "Bann",
                "label_ar": "بان",
                "label_fr": "Bann"
            },
            {
                "value": 43614,
                "label": "Gries",
                "label_ar": "جريس",
                "label_fr": "Gries"
            },
            {
                "value": 43615,
                "label": "Petersberg",
                "label_ar": "بيترسبرغ",
                "label_fr": "Petersberg"
            },
            {
                "value": 43616,
                "label": "Kerzenheim",
                "label_ar": "كيرزينهايم",
                "label_fr": "Kerzenheim"
            },
            {
                "value": 43617,
                "label": "Merkelbach",
                "label_ar": "ميركلباخ",
                "label_fr": "Merkelbach"
            },
            {
                "value": 43618,
                "label": "Rotenhain",
                "label_ar": "روتينهاين",
                "label_fr": "Rotenhain"
            },
            {
                "value": 43619,
                "label": "Guckheim",
                "label_ar": "جوكهايم",
                "label_fr": "Guckheim"
            },
            {
                "value": 43620,
                "label": "Urexweiler",
                "label_ar": "أوريكسويلر",
                "label_fr": "Urexweiler"
            },
            {
                "value": 43621,
                "label": "Bliesmengen-Bolchen",
                "label_ar": "Bliesmengen-Bolchen",
                "label_fr": "Bliesmengen-Bolchen"
            },
            {
                "value": 43622,
                "label": "Barbelroth",
                "label_ar": "Barbelroth",
                "label_fr": "Barbelroth"
            },
            {
                "value": 43623,
                "label": "Obernheim-Kirchenarnbach",
                "label_ar": "اوبرنهايم كيرشينارنباخ",
                "label_fr": "Obernheim-Kirchenarnbach"
            },
            {
                "value": 43624,
                "label": "Hahnenbach",
                "label_ar": "هانينباخ",
                "label_fr": "Hahnenbach"
            },
            {
                "value": 43625,
                "label": "Munchweiler am Klingbach",
                "label_ar": "Munchweiler am Klingbach",
                "label_fr": "Munchweiler am Klingbach"
            },
            {
                "value": 43626,
                "label": "Rheinzabern",
                "label_ar": "راينزابيرن",
                "label_fr": "Rheinzabern"
            },
            {
                "value": 43627,
                "label": "Gommersheim",
                "label_ar": "جومرسهايم",
                "label_fr": "Gommersheim"
            },
            {
                "value": 43628,
                "label": "Geinsheim",
                "label_ar": "جينشيم",
                "label_fr": "Geinsheim"
            },
            {
                "value": 43629,
                "label": "Hargesheim",
                "label_ar": "هارجشيم",
                "label_fr": "Hargesheim"
            },
            {
                "value": 43630,
                "label": "Kaden",
                "label_ar": "كادن",
                "label_fr": "Kaden"
            },
            {
                "value": 43631,
                "label": "Kerlingen",
                "label_ar": "كيرلينجن",
                "label_fr": "Kerlingen"
            },
            {
                "value": 43632,
                "label": "Jugenheim",
                "label_ar": "يوجنهايم",
                "label_fr": "Jugenheim"
            },
            {
                "value": 43633,
                "label": "Hackenheim",
                "label_ar": "هاكنهايم",
                "label_fr": "Hackenheim"
            },
            {
                "value": 43634,
                "label": "Pretzfeld",
                "label_ar": "بريتزفيلد",
                "label_fr": "Pretzfeld"
            },
            {
                "value": 43635,
                "label": "Arberg",
                "label_ar": "اربيرج",
                "label_fr": "Arberg"
            },
            {
                "value": 43636,
                "label": "Flomborn",
                "label_ar": "Flomborn",
                "label_fr": "Flomborn"
            },
            {
                "value": 43637,
                "label": "Eyendorf",
                "label_ar": "ايندورف",
                "label_fr": "Eyendorf"
            },
            {
                "value": 43638,
                "label": "Frommern",
                "label_ar": "فرومرن",
                "label_fr": "Frommern"
            },
            {
                "value": 43639,
                "label": "Reimlingen",
                "label_ar": "Reimlingen",
                "label_fr": "Reimlingen"
            },
            {
                "value": 43640,
                "label": "Rimhorn",
                "label_ar": "ريمهورن",
                "label_fr": "Rimhorn"
            },
            {
                "value": 43641,
                "label": "Sereetz",
                "label_ar": "سيريتس",
                "label_fr": "Sereetz"
            },
            {
                "value": 43642,
                "label": "Schongeising",
                "label_ar": "شونجيزينج",
                "label_fr": "Schongeising"
            },
            {
                "value": 43643,
                "label": "Schenkenzell",
                "label_ar": "شينكينزل",
                "label_fr": "Schenkenzell"
            },
            {
                "value": 43644,
                "label": "Gammelshausen",
                "label_ar": "جاميلسهاوزن",
                "label_fr": "Gammelshausen"
            },
            {
                "value": 43645,
                "label": "Holzmaden",
                "label_ar": "هولزمادن",
                "label_fr": "Holzmaden"
            },
            {
                "value": 43646,
                "label": "Mittelrot",
                "label_ar": "ميتيلروت",
                "label_fr": "Mittelrot"
            },
            {
                "value": 43647,
                "label": "Gurtweil",
                "label_ar": "جورتويل",
                "label_fr": "Gurtweil"
            },
            {
                "value": 43648,
                "label": "Lichtenbroich",
                "label_ar": "Lichtenbroich",
                "label_fr": "Lichtenbroich"
            },
            {
                "value": 43649,
                "label": "Deutz",
                "label_ar": "دويتز",
                "label_fr": "Deutz"
            },
            {
                "value": 43650,
                "label": "Durbheim",
                "label_ar": "دوربهايم",
                "label_fr": "Durbheim"
            },
            {
                "value": 43651,
                "label": "Wilhelmsdorf",
                "label_ar": "فيلهلمسدورف",
                "label_fr": "Wilhelmsdorf"
            },
            {
                "value": 43652,
                "label": "Koditz",
                "label_ar": "كوديتز",
                "label_fr": "Koditz"
            },
            {
                "value": 43653,
                "label": "Berg",
                "label_ar": "بيرج",
                "label_fr": "Berg"
            },
            {
                "value": 43654,
                "label": "Hetzenhausen",
                "label_ar": "هيتزنهاوزن",
                "label_fr": "Hetzenhausen"
            },
            {
                "value": 43655,
                "label": "Salching",
                "label_ar": "سلق",
                "label_fr": "Salching"
            },
            {
                "value": 43656,
                "label": "Vilshoven",
                "label_ar": "فيلسهوفن",
                "label_fr": "Vilshoven"
            },
            {
                "value": 43657,
                "label": "Altstadt",
                "label_ar": "ألتشتات",
                "label_fr": "Altstadt"
            },
            {
                "value": 43658,
                "label": "Gutow",
                "label_ar": "جوتو",
                "label_fr": "Gutow"
            },
            {
                "value": 43659,
                "label": "Ebendorf",
                "label_ar": "إيبندورف",
                "label_fr": "Ebendorf"
            },
            {
                "value": 43660,
                "label": "Insel",
                "label_ar": "إنسل",
                "label_fr": "Insel"
            },
            {
                "value": 43661,
                "label": "Redekin",
                "label_ar": "ريديكين",
                "label_fr": "Redekin"
            },
            {
                "value": 43662,
                "label": "Born",
                "label_ar": "مولود",
                "label_fr": "Née"
            },
            {
                "value": 43663,
                "label": "Boddin",
                "label_ar": "بودن",
                "label_fr": "Boddin"
            },
            {
                "value": 43664,
                "label": "Dahlenwarsleben",
                "label_ar": "Dahlenwarsleben",
                "label_fr": "Dahlenwarsleben"
            },
            {
                "value": 43665,
                "label": "Grebbin",
                "label_ar": "غريبين",
                "label_fr": "Grebbin"
            },
            {
                "value": 43666,
                "label": "Boden",
                "label_ar": "بودن",
                "label_fr": "Boden"
            },
            {
                "value": 43667,
                "label": "Zimmern",
                "label_ar": "زيمرن",
                "label_fr": "Zimmern"
            },
            {
                "value": 43668,
                "label": "Bothkamp",
                "label_ar": "بوثكامب",
                "label_fr": "Bothkamp"
            },
            {
                "value": 43669,
                "label": "Grossmonra",
                "label_ar": "جروسمونرا",
                "label_fr": "Grossmonra"
            },
            {
                "value": 43670,
                "label": "Ostramondra",
                "label_ar": "اوستراموندرا",
                "label_fr": "Ostramondra"
            },
            {
                "value": 43671,
                "label": "Blankenhain",
                "label_ar": "بلانكنهاين",
                "label_fr": "Blankenhain"
            },
            {
                "value": 43672,
                "label": "Graitschen",
                "label_ar": "جريتشن",
                "label_fr": "Graitschen"
            },
            {
                "value": 43673,
                "label": "Kalkreuth",
                "label_ar": "كالكروث",
                "label_fr": "Kalkreuth"
            },
            {
                "value": 43674,
                "label": "Blankenloch",
                "label_ar": "بلانكنلوخ",
                "label_fr": "Blankenloch"
            },
            {
                "value": 43675,
                "label": "Dormettingen",
                "label_ar": "دورميتينغن",
                "label_fr": "Dormettingen"
            },
            {
                "value": 43676,
                "label": "Lauterbach/Schwarzwald",
                "label_ar": "لوترباخ / شوارزوالد",
                "label_fr": "Lauterbach / Schwarzwald"
            },
            {
                "value": 43677,
                "label": "Dobel",
                "label_ar": "دوبيل",
                "label_fr": "Dobel"
            },
            {
                "value": 43678,
                "label": "Weilheim an der Teck",
                "label_ar": "Weilheim an der Teck",
                "label_fr": "Weilheim an der Teck"
            },
            {
                "value": 43679,
                "label": "Neidlingen",
                "label_ar": "نيدلينجين",
                "label_fr": "Neidlingen"
            },
            {
                "value": 43680,
                "label": "Teisnach",
                "label_ar": "Teisnach",
                "label_fr": "Teisnach"
            },
            {
                "value": 43681,
                "label": "Gebenbach",
                "label_ar": "جيبينباخ",
                "label_fr": "Gebenbach"
            },
            {
                "value": 43682,
                "label": "Schonthal",
                "label_ar": "شونثال",
                "label_fr": "Schonthal"
            },
            {
                "value": 43683,
                "label": "Knittelsheim",
                "label_ar": "Knittelsheim",
                "label_fr": "Knittelsheim"
            },
            {
                "value": 43684,
                "label": "Platkow",
                "label_ar": "بلاتكو",
                "label_fr": "Platkow"
            },
            {
                "value": 43685,
                "label": "Falkenfels",
                "label_ar": "فالكنفيلس",
                "label_fr": "Falkenfels"
            },
            {
                "value": 43686,
                "label": "Birlinghoven",
                "label_ar": "بيرلينجهوفن",
                "label_fr": "Birlinghoven"
            },
            {
                "value": 43687,
                "label": "Luft",
                "label_ar": "لوفت",
                "label_fr": "Luft"
            },
            {
                "value": 43688,
                "label": "Seehof",
                "label_ar": "سيهوف",
                "label_fr": "Seehof"
            },
            {
                "value": 43689,
                "label": "Dotternhausen",
                "label_ar": "دوتيرنهاوزن",
                "label_fr": "Dotternhausen"
            },
            {
                "value": 43690,
                "label": "Ottenbach",
                "label_ar": "أوتينباخ",
                "label_fr": "Ottenbach"
            },
            {
                "value": 43691,
                "label": "Altdorf",
                "label_ar": "التدورف",
                "label_fr": "Altdorf"
            },
            {
                "value": 43692,
                "label": "Eisenbach",
                "label_ar": "أيزنباخ",
                "label_fr": "Eisenbach"
            },
            {
                "value": 43693,
                "label": "Ohlsbach",
                "label_ar": "Ohlsbach",
                "label_fr": "Ohlsbach"
            },
            {
                "value": 43694,
                "label": "Apfelstadt",
                "label_ar": "أبفيلشتات",
                "label_fr": "Apfelstadt"
            },
            {
                "value": 43695,
                "label": "Clausthal",
                "label_ar": "كلاوستال",
                "label_fr": "Clausthal"
            },
            {
                "value": 43696,
                "label": "Kottweiler-Schwanden",
                "label_ar": "كوتويلر شواندين",
                "label_fr": "Kottweiler-Schwanden"
            },
            {
                "value": 43697,
                "label": "Temmels",
                "label_ar": "تيميلز",
                "label_fr": "Temmels"
            },
            {
                "value": 43698,
                "label": "Friemar",
                "label_ar": "فريمار",
                "label_fr": "Friemar"
            },
            {
                "value": 43699,
                "label": "Schonenberg",
                "label_ar": "شونينبيرج",
                "label_fr": "Schonenberg"
            },
            {
                "value": 43700,
                "label": "Radibor",
                "label_ar": "راديبور",
                "label_fr": "Radibor"
            },
            {
                "value": 43701,
                "label": "Zschortau",
                "label_ar": "زشورتاو",
                "label_fr": "Zschortau"
            },
            {
                "value": 43702,
                "label": "Grafenthal",
                "label_ar": "جرافينثال",
                "label_fr": "Grafenthal"
            },
            {
                "value": 43703,
                "label": "Wulfen",
                "label_ar": "وولفين",
                "label_fr": "Wulfen"
            },
            {
                "value": 43704,
                "label": "Goggingen",
                "label_ar": "Goggingen",
                "label_fr": "Goggingen"
            },
            {
                "value": 43705,
                "label": "Dipperz",
                "label_ar": "ديبرز",
                "label_fr": "Dipperz"
            },
            {
                "value": 43706,
                "label": "Golm",
                "label_ar": "جولم",
                "label_fr": "Golm"
            },
            {
                "value": 43707,
                "label": "Langenbach",
                "label_ar": "لانجينباخ",
                "label_fr": "Langenbach"
            },
            {
                "value": 43708,
                "label": "Schadeleben",
                "label_ar": "شاديلبين",
                "label_fr": "Schadeleben"
            },
            {
                "value": 43709,
                "label": "Alperstedt",
                "label_ar": "ألبيرستيدت",
                "label_fr": "Alperstedt"
            },
            {
                "value": 43710,
                "label": "Gossel",
                "label_ar": "جوسيل",
                "label_fr": "Gossel"
            },
            {
                "value": 43711,
                "label": "Flurstedt",
                "label_ar": "فلورستدت",
                "label_fr": "Flurstedt"
            },
            {
                "value": 43712,
                "label": "Behringen",
                "label_ar": "بيرينجن",
                "label_fr": "Behringen"
            },
            {
                "value": 43713,
                "label": "Liebenstein",
                "label_ar": "ليبنشتاين",
                "label_fr": "Liebenstein"
            },
            {
                "value": 43714,
                "label": "Prittriching",
                "label_ar": "تثري",
                "label_fr": "Prittriching"
            },
            {
                "value": 43715,
                "label": "Hard",
                "label_ar": "الصعب",
                "label_fr": "Dur"
            },
            {
                "value": 43716,
                "label": "Malsfeld",
                "label_ar": "مالسفيلد",
                "label_fr": "Malsfeld"
            },
            {
                "value": 43717,
                "label": "Bermbach",
                "label_ar": "بيرمباخ",
                "label_fr": "Bermbach"
            },
            {
                "value": 43718,
                "label": "Wald",
                "label_ar": "والد",
                "label_fr": "Wald"
            },
            {
                "value": 43719,
                "label": "Lippe",
                "label_ar": "ليبي",
                "label_fr": "Lippe"
            },
            {
                "value": 43720,
                "label": "Joachimsthal",
                "label_ar": "يواكيمستال",
                "label_fr": "Joachimsthal"
            },
            {
                "value": 43721,
                "label": "Oderberg",
                "label_ar": "اودربيرغ",
                "label_fr": "Oderberg"
            },
            {
                "value": 43722,
                "label": "Wiederau",
                "label_ar": "فيديراو",
                "label_fr": "Wiederau"
            },
            {
                "value": 43723,
                "label": "Kayna",
                "label_ar": "كاينا",
                "label_fr": "Kayna"
            },
            {
                "value": 43724,
                "label": "Weidenhahn",
                "label_ar": "Weidenhahn",
                "label_fr": "Weidenhahn"
            },
            {
                "value": 43725,
                "label": "Niederfell",
                "label_ar": "نيدرفيل",
                "label_fr": "Niederfell"
            },
            {
                "value": 43726,
                "label": "Eiselfing",
                "label_ar": "آيزلفين",
                "label_fr": "Eiselfing"
            },
            {
                "value": 43727,
                "label": "Mintraching",
                "label_ar": "النعناع",
                "label_fr": "Mintraching"
            },
            {
                "value": 43728,
                "label": "Kotzting",
                "label_ar": "كوتزتينغ",
                "label_fr": "Kotzting"
            },
            {
                "value": 43729,
                "label": "Ensdorf",
                "label_ar": "انسدورف",
                "label_fr": "Ensdorf"
            },
            {
                "value": 43730,
                "label": "Heiligenthal",
                "label_ar": "هيليغنثال",
                "label_fr": "Heiligenthal"
            },
            {
                "value": 43731,
                "label": "Friedrichsort",
                "label_ar": "فريدريشسورت",
                "label_fr": "Friedrichsort"
            },
            {
                "value": 43732,
                "label": "Langlingen",
                "label_ar": "لانغلينغن",
                "label_fr": "Langlingen"
            },
            {
                "value": 43733,
                "label": "Dorf",
                "label_ar": "دورف",
                "label_fr": "Dorf"
            },
            {
                "value": 43734,
                "label": "Niedersachswerfen",
                "label_ar": "نيدرساشويرفن",
                "label_fr": "Niedersachswerfen"
            },
            {
                "value": 43735,
                "label": "Einhausen",
                "label_ar": "أينهاوزن",
                "label_fr": "Einhausen"
            },
            {
                "value": 43736,
                "label": "Neehausen",
                "label_ar": "نيهاوزن",
                "label_fr": "Neehausen"
            },
            {
                "value": 43737,
                "label": "Holzengel",
                "label_ar": "هولزنجيل",
                "label_fr": "Holzengel"
            },
            {
                "value": 43738,
                "label": "Kasbach-Ohlenberg",
                "label_ar": "كاسباخ اولينبيرج",
                "label_fr": "Kasbach-Ohlenberg"
            },
            {
                "value": 43739,
                "label": "Henfenfeld",
                "label_ar": "Henfenfeld",
                "label_fr": "Henfenfeld"
            },
            {
                "value": 43740,
                "label": "Bobbau",
                "label_ar": "بوباو",
                "label_fr": "Bobbau"
            },
            {
                "value": 43741,
                "label": "Muldenhammer",
                "label_ar": "مولدينهامر",
                "label_fr": "Muldenhammer"
            },
            {
                "value": 43742,
                "label": "Murg",
                "label_ar": "مورغ",
                "label_fr": "Murg"
            },
            {
                "value": 43743,
                "label": "Wintersdorf",
                "label_ar": "وينترسدورف",
                "label_fr": "Wintersdorf"
            },
            {
                "value": 43744,
                "label": "Bechhofen",
                "label_ar": "بيشهوفن",
                "label_fr": "Bechhofen"
            },
            {
                "value": 43745,
                "label": "Harlachen",
                "label_ar": "هارلاشن",
                "label_fr": "Harlachen"
            },
            {
                "value": 43746,
                "label": "Rugendorf",
                "label_ar": "روجندورف",
                "label_fr": "Rugendorf"
            },
            {
                "value": 43747,
                "label": "Wacken",
                "label_ar": "واكين",
                "label_fr": "Wacken"
            },
            {
                "value": 43748,
                "label": "Rabke",
                "label_ar": "رابك",
                "label_fr": "Rabke"
            },
            {
                "value": 43749,
                "label": "Ballstadt",
                "label_ar": "بالشتات",
                "label_fr": "Ballstadt"
            },
            {
                "value": 43750,
                "label": "Wernersberg",
                "label_ar": "فيرنرسبرج",
                "label_fr": "Wernersberg"
            },
            {
                "value": 43751,
                "label": "Inzmuhlen",
                "label_ar": "إنزمولن",
                "label_fr": "Inzmuhlen"
            },
            {
                "value": 43752,
                "label": "Wilhelmsheim",
                "label_ar": "فيلهلمسهايم",
                "label_fr": "Wilhelmsheim"
            },
            {
                "value": 43753,
                "label": "Krolpa",
                "label_ar": "كرولبا",
                "label_fr": "Krolpa"
            },
            {
                "value": 43754,
                "label": "Wehrbleck",
                "label_ar": "ويربلك",
                "label_fr": "Wehrbleck"
            },
            {
                "value": 43755,
                "label": "Ellerhoop",
                "label_ar": "إليرهوب",
                "label_fr": "Ellerhoop"
            },
            {
                "value": 43756,
                "label": "Herzogsweiler",
                "label_ar": "هيرزوغسويلر",
                "label_fr": "Herzogsweiler"
            },
            {
                "value": 43757,
                "label": "Somborn",
                "label_ar": "سومبورن",
                "label_fr": "Somborn"
            },
            {
                "value": 43758,
                "label": "Schmidt",
                "label_ar": "شميت",
                "label_fr": "Schmidt"
            },
            {
                "value": 43759,
                "label": "Oelsen",
                "label_ar": "اويلسن",
                "label_fr": "Oelsen"
            },
            {
                "value": 43760,
                "label": "Petriroda",
                "label_ar": "بيتريرودا",
                "label_fr": "Petriroda"
            },
            {
                "value": 43761,
                "label": "Hohenroth",
                "label_ar": "هوهنروث",
                "label_fr": "Hohenroth"
            },
            {
                "value": 43762,
                "label": "Ramsthal",
                "label_ar": "رامستال",
                "label_fr": "Ramsthal"
            },
            {
                "value": 43763,
                "label": "Salz",
                "label_ar": "سالز",
                "label_fr": "Salz"
            },
            {
                "value": 43764,
                "label": "Mittelberg",
                "label_ar": "ميتيلبيرج",
                "label_fr": "Mittelberg"
            },
            {
                "value": 43765,
                "label": "Altenmarkt",
                "label_ar": "التنماركت",
                "label_fr": "Altenmarkt"
            },
            {
                "value": 43766,
                "label": "Stein an der Traun",
                "label_ar": "شتاين أن دير تراون",
                "label_fr": "Stein an der Traun"
            },
            {
                "value": 43767,
                "label": "Weilersbach",
                "label_ar": "Weilersbach",
                "label_fr": "Weilersbach"
            },
            {
                "value": 43768,
                "label": "Flintsbach",
                "label_ar": "فلينتسباخ",
                "label_fr": "Flintsbach"
            },
            {
                "value": 43769,
                "label": "Nussdorf am Inn",
                "label_ar": "نوسدورف أم إن",
                "label_fr": "Nussdorf am Inn"
            },
            {
                "value": 43770,
                "label": "Krun",
                "label_ar": "كرون",
                "label_fr": "Krun"
            },
            {
                "value": 43771,
                "label": "Jettenbach",
                "label_ar": "جيتنباخ",
                "label_fr": "Jettenbach"
            },
            {
                "value": 43772,
                "label": "Reichersbeuern",
                "label_ar": "Reichersbeuern",
                "label_fr": "Reichersbeuern"
            },
            {
                "value": 43773,
                "label": "Trunkelsberg",
                "label_ar": "ترونكلسبرغ",
                "label_fr": "Trunkelsberg"
            },
            {
                "value": 43774,
                "label": "Hawangen",
                "label_ar": "هوانجين",
                "label_fr": "Hawangen"
            },
            {
                "value": 43775,
                "label": "Reichertshausen",
                "label_ar": "Reichertshausen",
                "label_fr": "Reichertshausen"
            },
            {
                "value": 43776,
                "label": "Mitterfelden",
                "label_ar": "ميترفيلدن",
                "label_fr": "Mitterfelden"
            },
            {
                "value": 43777,
                "label": "Werpeloh",
                "label_ar": "ويربلوه",
                "label_fr": "Werpeloh"
            },
            {
                "value": 43778,
                "label": "Klein Berssen",
                "label_ar": "كلاين بيرسن",
                "label_fr": "Klein Berssen"
            },
            {
                "value": 43779,
                "label": "Fresenburg",
                "label_ar": "فريزنبورغ",
                "label_fr": "Fresenburg"
            },
            {
                "value": 43780,
                "label": "Rastdorf",
                "label_ar": "راستدورف",
                "label_fr": "Rastdorf"
            },
            {
                "value": 43781,
                "label": "Lintig",
                "label_ar": "لينتيج",
                "label_fr": "Lintig"
            },
            {
                "value": 43782,
                "label": "Keller",
                "label_ar": "كيلر",
                "label_fr": "Keller"
            },
            {
                "value": 43783,
                "label": "Gulzowshof",
                "label_ar": "جولزوفشوف",
                "label_fr": "Gulzowshof"
            },
            {
                "value": 43784,
                "label": "Malsburg",
                "label_ar": "مالسبورغ",
                "label_fr": "Malsburg"
            },
            {
                "value": 43785,
                "label": "Buborn",
                "label_ar": "بوبورن",
                "label_fr": "Buborn"
            },
            {
                "value": 43786,
                "label": "Lollbach",
                "label_ar": "لولباخ",
                "label_fr": "Lollbach"
            },
            {
                "value": 43787,
                "label": "Herren-Sulzbach",
                "label_ar": "هيرين سولزباخ",
                "label_fr": "Herren-Sulzbach"
            },
            {
                "value": 43788,
                "label": "Abtweiler",
                "label_ar": "أبتويلر",
                "label_fr": "Abtweiler"
            },
            {
                "value": 43789,
                "label": "Beetzendorf",
                "label_ar": "بيتزيندورف",
                "label_fr": "Beetzendorf"
            },
            {
                "value": 43790,
                "label": "Vorstetten",
                "label_ar": "فورستيتين",
                "label_fr": "Vorstetten"
            },
            {
                "value": 43791,
                "label": "Ludersdorf",
                "label_ar": "لودرسدورف",
                "label_fr": "Ludersdorf"
            },
            {
                "value": 43792,
                "label": "Dittelsheim-Hessloch",
                "label_ar": "Dittelsheim-Hessloch",
                "label_fr": "Dittelsheim-Hessloch"
            },
            {
                "value": 43793,
                "label": "Preith",
                "label_ar": "بريث",
                "label_fr": "Preith"
            },
            {
                "value": 43794,
                "label": "Jagel",
                "label_ar": "جاجل",
                "label_fr": "Jagel"
            },
            {
                "value": 43795,
                "label": "Hartheim",
                "label_ar": "هارثيم",
                "label_fr": "Hartheim"
            },
            {
                "value": 43796,
                "label": "Eisenbach",
                "label_ar": "أيزنباخ",
                "label_fr": "Eisenbach"
            },
            {
                "value": 43797,
                "label": "Schuller",
                "label_ar": "شولر",
                "label_fr": "Schuller"
            },
            {
                "value": 43798,
                "label": "Heilingen",
                "label_ar": "هيلينجين",
                "label_fr": "Heilingen"
            },
            {
                "value": 43799,
                "label": "Neuhemsbach",
                "label_ar": "نويهمسباخ",
                "label_fr": "Neuhemsbach"
            },
            {
                "value": 43800,
                "label": "Sack",
                "label_ar": "كيس",
                "label_fr": "Sac"
            },
            {
                "value": 43801,
                "label": "Plan",
                "label_ar": "خطة",
                "label_fr": "Plan"
            },
            {
                "value": 43802,
                "label": "Sudheide",
                "label_ar": "سودهيد",
                "label_fr": "Sudheide"
            },
            {
                "value": 43803,
                "label": "Ludersfeld",
                "label_ar": "لودرسفيلد",
                "label_fr": "Ludersfeld"
            },
            {
                "value": 43804,
                "label": "Neuengors",
                "label_ar": "Neuengors",
                "label_fr": "Neuengors"
            },
            {
                "value": 43805,
                "label": "Volxheim",
                "label_ar": "فولكسهايم",
                "label_fr": "Volxheim"
            },
            {
                "value": 43806,
                "label": "Sersheim",
                "label_ar": "سيرشيم",
                "label_fr": "Sersheim"
            },
            {
                "value": 43807,
                "label": "Oberstadion",
                "label_ar": "أوبرستاديون",
                "label_fr": "Oberstadion"
            },
            {
                "value": 43808,
                "label": "Korchow",
                "label_ar": "كورتشو",
                "label_fr": "Korchow"
            },
            {
                "value": 43809,
                "label": "Traubing",
                "label_ar": "تروبينج",
                "label_fr": "Traubing"
            },
            {
                "value": 43810,
                "label": "Reichenschwand",
                "label_ar": "Reichenschwand",
                "label_fr": "Reichenschwand"
            },
            {
                "value": 43811,
                "label": "Krautheim",
                "label_ar": "كراوثيم",
                "label_fr": "Krautheim"
            },
            {
                "value": 43812,
                "label": "Waldenburg",
                "label_ar": "والدنبورغ",
                "label_fr": "Waldenburg"
            },
            {
                "value": 43813,
                "label": "Steudnitz",
                "label_ar": "ستودنيتز",
                "label_fr": "Steudnitz"
            },
            {
                "value": 43814,
                "label": "Seelitz",
                "label_ar": "سيليتز",
                "label_fr": "Seelitz"
            },
            {
                "value": 43815,
                "label": "Kastel",
                "label_ar": "كاستل",
                "label_fr": "Kastel"
            },
            {
                "value": 43816,
                "label": "Lutter am Barenberge",
                "label_ar": "Lutter am Barenberge",
                "label_fr": "Lutter am Barenberge"
            },
            {
                "value": 43817,
                "label": "Heidekamp",
                "label_ar": "هييدكامب",
                "label_fr": "Heidekamp"
            },
            {
                "value": 43818,
                "label": "Trimbs",
                "label_ar": "تقليم",
                "label_fr": "Trimbs"
            },
            {
                "value": 43819,
                "label": "Schwarzenbach am Wald",
                "label_ar": "شوارزنباخ آم والد",
                "label_fr": "Schwarzenbach am Wald"
            },
            {
                "value": 43820,
                "label": "Schwarzenbach",
                "label_ar": "شوارزنباخ",
                "label_fr": "Schwarzenbach"
            },
            {
                "value": 43821,
                "label": "Runthe",
                "label_ar": "Runthe",
                "label_fr": "Runthe"
            },
            {
                "value": 43822,
                "label": "Oberottmarshausen",
                "label_ar": "أوبيروت مارشاوسين",
                "label_fr": "Oberottmarshausen"
            },
            {
                "value": 43823,
                "label": "Mittelstetten",
                "label_ar": "ميتلستيتن",
                "label_fr": "Mittelstetten"
            },
            {
                "value": 43824,
                "label": "Gundremmingen",
                "label_ar": "جوندرمينجين",
                "label_fr": "Gundremmingen"
            },
            {
                "value": 43825,
                "label": "Schwabniederhofen",
                "label_ar": "شوابنيديرهوفن",
                "label_fr": "Schwabniederhofen"
            },
            {
                "value": 43826,
                "label": "Tussenhausen",
                "label_ar": "توسنهاوزن",
                "label_fr": "Tussenhausen"
            },
            {
                "value": 43827,
                "label": "Dittenheim",
                "label_ar": "ديتينهايم",
                "label_fr": "Dittenheim"
            },
            {
                "value": 43828,
                "label": "Oberlaindern",
                "label_ar": "اوبرلاينديرن",
                "label_fr": "Oberlaindern"
            },
            {
                "value": 43829,
                "label": "Arzbach",
                "label_ar": "أرزباخ",
                "label_fr": "Arzbach"
            },
            {
                "value": 43830,
                "label": "Truchtlaching",
                "label_ar": "Truchtlaching",
                "label_fr": "Truchtlaching"
            },
            {
                "value": 43831,
                "label": "Garching an der Alz",
                "label_ar": "Garching an der Alz",
                "label_fr": "Garching an der Alz"
            },
            {
                "value": 43832,
                "label": "Biessenhofen",
                "label_ar": "Biessenhofen",
                "label_fr": "Biessenhofen"
            },
            {
                "value": 43833,
                "label": "Mauerstetten",
                "label_ar": "ماورستيتن",
                "label_fr": "Mauerstetten"
            },
            {
                "value": 43834,
                "label": "Westheim",
                "label_ar": "ويستهايم",
                "label_fr": "Westheim"
            },
            {
                "value": 43835,
                "label": "Elfershausen",
                "label_ar": "إلفيرشاوسين",
                "label_fr": "Elfershausen"
            },
            {
                "value": 43836,
                "label": "Gerach",
                "label_ar": "جيراتش",
                "label_fr": "Gerach"
            },
            {
                "value": 43837,
                "label": "Untersteinach",
                "label_ar": "Untersteinach",
                "label_fr": "Untersteinach"
            },
            {
                "value": 43838,
                "label": "Marktrodach",
                "label_ar": "ماركتروداخ",
                "label_fr": "Marktrodach"
            },
            {
                "value": 43839,
                "label": "Hesselbach",
                "label_ar": "هيسيلباخ",
                "label_fr": "Hesselbach"
            },
            {
                "value": 43840,
                "label": "Altenkunstadt",
                "label_ar": "التنكونشتات",
                "label_fr": "Altenkunstadt"
            },
            {
                "value": 43841,
                "label": "Schney",
                "label_ar": "شني",
                "label_fr": "Schney"
            },
            {
                "value": 43842,
                "label": "Schwebheim",
                "label_ar": "شويبهايم",
                "label_fr": "Schwebheim"
            },
            {
                "value": 43843,
                "label": "Ebern",
                "label_ar": "إبيرن",
                "label_fr": "Ebern"
            },
            {
                "value": 43844,
                "label": "Dorfles",
                "label_ar": "دورفليس",
                "label_fr": "Dorfles"
            },
            {
                "value": 43845,
                "label": "Grossheirath",
                "label_ar": "Grossheirath",
                "label_fr": "Grossheirath"
            },
            {
                "value": 43846,
                "label": "Kirchendemenreuth",
                "label_ar": "كيرشينديمينروث",
                "label_fr": "Kirchendemenreuth"
            },
            {
                "value": 43847,
                "label": "Schirmitz",
                "label_ar": "شيرميتس",
                "label_fr": "Schirmitz"
            },
            {
                "value": 43848,
                "label": "Pirk",
                "label_ar": "بيرك",
                "label_fr": "Pirk"
            },
            {
                "value": 43849,
                "label": "Eisingen",
                "label_ar": "ايسينجين",
                "label_fr": "Eisingen"
            },
            {
                "value": 43850,
                "label": "Heinersreuth",
                "label_ar": "هاينرسروث",
                "label_fr": "Heinersreuth"
            },
            {
                "value": 43851,
                "label": "Bellenberg",
                "label_ar": "بيلنبرغ",
                "label_fr": "Bellenberg"
            },
            {
                "value": 43852,
                "label": "Grossmehring",
                "label_ar": "غروسمرينغ",
                "label_fr": "Grossmehring"
            },
            {
                "value": 43853,
                "label": "Lautertal",
                "label_ar": "لوترتال",
                "label_fr": "Lautertal"
            },
            {
                "value": 43854,
                "label": "Wildpoldsried",
                "label_ar": "Wildpoldsried",
                "label_fr": "Wildpoldsried"
            },
            {
                "value": 43855,
                "label": "Marxgrun",
                "label_ar": "ماركسغرون",
                "label_fr": "Marxgrun"
            },
            {
                "value": 43856,
                "label": "Schnee",
                "label_ar": "شني",
                "label_fr": "Schnee"
            },
            {
                "value": 43857,
                "label": "Stahlhofen",
                "label_ar": "ستاهلهوفن",
                "label_fr": "Stahlhofen"
            },
            {
                "value": 43858,
                "label": "Grunenthal",
                "label_ar": "جروننتال",
                "label_fr": "Grunenthal"
            },
            {
                "value": 43859,
                "label": "Pressen",
                "label_ar": "اضغط",
                "label_fr": "Appuyez sur"
            },
            {
                "value": 43860,
                "label": "Isenburg",
                "label_ar": "ايسنبرج",
                "label_fr": "Isenburg"
            },
            {
                "value": 43861,
                "label": "Vorbach",
                "label_ar": "فورباخ",
                "label_fr": "Vorbach"
            },
            {
                "value": 43862,
                "label": "Heeg",
                "label_ar": "هيغ",
                "label_fr": "Heeg"
            },
            {
                "value": 43863,
                "label": "Vorwerk",
                "label_ar": "فورويرك",
                "label_fr": "Vorwerk"
            },
            {
                "value": 43864,
                "label": "Bellenberg",
                "label_ar": "بيلنبرغ",
                "label_fr": "Bellenberg"
            },
            {
                "value": 43865,
                "label": "Wachsenburg",
                "label_ar": "واشسينبورغ",
                "label_fr": "Wachsenburg"
            },
            {
                "value": 43866,
                "label": "Rechtmehring",
                "label_ar": "Rechtmehring",
                "label_fr": "Rechtmehring"
            },
            {
                "value": 43867,
                "label": "Jagerhof",
                "label_ar": "جاغيرهوف",
                "label_fr": "Jagerhof"
            },
            {
                "value": 43868,
                "label": "Dierkow",
                "label_ar": "ديركوف",
                "label_fr": "Dierkow"
            },
            {
                "value": 43869,
                "label": "Weissenstadt",
                "label_ar": "فايسنشتات",
                "label_fr": "Weissenstadt"
            },
            {
                "value": 43870,
                "label": "Sierksdorf",
                "label_ar": "سيركسدورف",
                "label_fr": "Sierksdorf"
            },
            {
                "value": 43871,
                "label": "Seehaus",
                "label_ar": "سيهاوس",
                "label_fr": "Seehaus"
            },
            {
                "value": 43872,
                "label": "Berlingerode",
                "label_ar": "بيرلينجيرود",
                "label_fr": "Berlingerode"
            },
            {
                "value": 43873,
                "label": "Linden",
                "label_ar": "الزيزفون",
                "label_fr": "Tilleul"
            },
            {
                "value": 43874,
                "label": "Welle",
                "label_ar": "فيله",
                "label_fr": "Welle"
            },
            {
                "value": 43875,
                "label": "Theissen",
                "label_ar": "ثيسن",
                "label_fr": "Theissen"
            },
            {
                "value": 43876,
                "label": "Kuhn",
                "label_ar": "كوهن",
                "label_fr": "Kuhn"
            },
            {
                "value": 43877,
                "label": "Blechhammer",
                "label_ar": "Blechhammer",
                "label_fr": "Blechhammer"
            },
            {
                "value": 43878,
                "label": "Wasser",
                "label_ar": "واسر",
                "label_fr": "Wasser"
            },
            {
                "value": 43879,
                "label": "Weisel",
                "label_ar": "وايزل",
                "label_fr": "Weisel"
            },
            {
                "value": 43880,
                "label": "Gutenberg",
                "label_ar": "جوتنبرج",
                "label_fr": "Gutenberg"
            },
            {
                "value": 43881,
                "label": "Bergbau",
                "label_ar": "بيرجباو",
                "label_fr": "Bergbau"
            },
            {
                "value": 43882,
                "label": "Halberg",
                "label_ar": "هالبرج",
                "label_fr": "Halberg"
            },
            {
                "value": 43883,
                "label": "Gossen",
                "label_ar": "جوسين",
                "label_fr": "Gossen"
            },
            {
                "value": 43884,
                "label": "Konigstein",
                "label_ar": "كونيجشتاين",
                "label_fr": "Konigstein"
            },
            {
                "value": 43885,
                "label": "Karlsbad",
                "label_ar": "كارلسباد",
                "label_fr": "Karlsbad"
            },
            {
                "value": 43886,
                "label": "Dohle",
                "label_ar": "دوهلي",
                "label_fr": "Dohle"
            },
            {
                "value": 43887,
                "label": "Gehlenbeck",
                "label_ar": "جلينبيك",
                "label_fr": "Gehlenbeck"
            },
            {
                "value": 43888,
                "label": "Blume",
                "label_ar": "بلوم",
                "label_fr": "Blume"
            },
            {
                "value": 43889,
                "label": "Kraft",
                "label_ar": "كرافت",
                "label_fr": "Kraft"
            },
            {
                "value": 43890,
                "label": "Hohenroda",
                "label_ar": "هوهنرودا",
                "label_fr": "Hohenroda"
            },
            {
                "value": 43891,
                "label": "Rasselstein",
                "label_ar": "راسيلشتاين",
                "label_fr": "Rasselstein"
            },
            {
                "value": 43892,
                "label": "Wichte",
                "label_ar": "ويتشتي",
                "label_fr": "Wichte"
            },
            {
                "value": 43893,
                "label": "Kleinsendelbach",
                "label_ar": "كلاينسندلباخ",
                "label_fr": "Kleinsendelbach"
            },
            {
                "value": 43894,
                "label": "Klink",
                "label_ar": "كلينك",
                "label_fr": "Klink"
            },
            {
                "value": 43895,
                "label": "Ostseebad Sellin",
                "label_ar": "أوستسيباد سيلين",
                "label_fr": "Ostseebad Sellin"
            },
            {
                "value": 43896,
                "label": "Benz",
                "label_ar": "بنز",
                "label_fr": "Benz"
            },
            {
                "value": 43897,
                "label": "Klinge",
                "label_ar": "كلينج",
                "label_fr": "Klinge"
            },
            {
                "value": 43898,
                "label": "Cranz",
                "label_ar": "كرانز",
                "label_fr": "Cranz"
            },
            {
                "value": 43899,
                "label": "Ostseebad Ahrenshoop",
                "label_ar": "Ostseebad Ahrenshoop",
                "label_fr": "Ostseebad Ahrenshoop"
            },
            {
                "value": 43900,
                "label": "Autenzell",
                "label_ar": "أوتنزيل",
                "label_fr": "Autenzell"
            },
            {
                "value": 43901,
                "label": "Schutz",
                "label_ar": "شوتز",
                "label_fr": "Schutz"
            },
            {
                "value": 43902,
                "label": "Waschenbach",
                "label_ar": "واشنباخ",
                "label_fr": "Waschenbach"
            },
            {
                "value": 43903,
                "label": "Buhler",
                "label_ar": "بوهلر",
                "label_fr": "Buhler"
            },
            {
                "value": 43904,
                "label": "Schillingshof",
                "label_ar": "شيلينجسهوف",
                "label_fr": "Schillingshof"
            },
            {
                "value": 43905,
                "label": "Lerbach",
                "label_ar": "ليرباخ",
                "label_fr": "Lerbach"
            },
            {
                "value": 43906,
                "label": "Ludwigshafen",
                "label_ar": "لودفيجشافن",
                "label_fr": "Ludwigshafen"
            },
            {
                "value": 43907,
                "label": "Wieske",
                "label_ar": "Wieske",
                "label_fr": "Wieske"
            },
            {
                "value": 43908,
                "label": "Feldkirchen",
                "label_ar": "فيلدكيرشن",
                "label_fr": "Feldkirchen"
            },
            {
                "value": 43909,
                "label": "Roes",
                "label_ar": "رو",
                "label_fr": "Oeufs"
            },
            {
                "value": 43910,
                "label": "Wiesbach",
                "label_ar": "فيزباخ",
                "label_fr": "Wiesbach"
            },
            {
                "value": 43911,
                "label": "Buschhausen",
                "label_ar": "بوشهاوزن",
                "label_fr": "Buschhausen"
            },
            {
                "value": 43912,
                "label": "Karl",
                "label_ar": "كارل",
                "label_fr": "Karl"
            },
            {
                "value": 43913,
                "label": "Ostseebad Dierhagen",
                "label_ar": "Ostseebad Dierhagen",
                "label_fr": "Ostseebad Dierhagen"
            },
            {
                "value": 43914,
                "label": "Seelscheid",
                "label_ar": "سيلشيد",
                "label_fr": "Seelscheid"
            },
            {
                "value": 43915,
                "label": "Blitzenreute",
                "label_ar": "Blitzenreute",
                "label_fr": "Blitzenreute"
            },
            {
                "value": 43916,
                "label": "Langenenslingen",
                "label_ar": "لانجيننسلينجين",
                "label_fr": "Langenenslingen"
            },
            {
                "value": 43917,
                "label": "Thulba",
                "label_ar": "ثلبة",
                "label_fr": "Thulba"
            },
            {
                "value": 43918,
                "label": "Diedersdorf",
                "label_ar": "ديدرسدورف",
                "label_fr": "Diedersdorf"
            },
            {
                "value": 43919,
                "label": "Oberberg",
                "label_ar": "اوبربرج",
                "label_fr": "Oberberg"
            },
            {
                "value": 43920,
                "label": "Todtenried",
                "label_ar": "تودت",
                "label_fr": "Todtenried"
            },
            {
                "value": 43921,
                "label": "Lawalde",
                "label_ar": "لاوالدي",
                "label_fr": "Lawalde"
            },
            {
                "value": 43922,
                "label": "Erlach",
                "label_ar": "إرلاتش",
                "label_fr": "Erlach"
            },
            {
                "value": 43923,
                "label": "Hallnberg",
                "label_ar": "هالنبيرج",
                "label_fr": "Hallnberg"
            },
            {
                "value": 43924,
                "label": "Eisenbach",
                "label_ar": "أيزنباخ",
                "label_fr": "Eisenbach"
            },
            {
                "value": 43925,
                "label": "Schlossberg",
                "label_ar": "شلوسبرغ",
                "label_fr": "Schlossberg"
            },
            {
                "value": 43926,
                "label": "Buhlerhohe",
                "label_ar": "بوهلرهوهي",
                "label_fr": "Buhlerhohe"
            },
            {
                "value": 43927,
                "label": "Gleisweiler",
                "label_ar": "جليزويلر",
                "label_fr": "Gleisweiler"
            },
            {
                "value": 43928,
                "label": "Reichenbach",
                "label_ar": "Reichenbach",
                "label_fr": "Reichenbach"
            },
            {
                "value": 43929,
                "label": "Burgerhof",
                "label_ar": "برجرهوف",
                "label_fr": "Burgerhof"
            },
            {
                "value": 43930,
                "label": "Zirkow",
                "label_ar": "زركوف",
                "label_fr": "Zirkow"
            },
            {
                "value": 43931,
                "label": "Gevensleben",
                "label_ar": "جيفينسلبين",
                "label_fr": "Gevensleben"
            },
            {
                "value": 43932,
                "label": "Wolsdorf",
                "label_ar": "Wolsdorf",
                "label_fr": "Wolsdorf"
            },
            {
                "value": 43933,
                "label": "Hochstrasse",
                "label_ar": "Hochstrasse",
                "label_fr": "Hochstrasse"
            },
            {
                "value": 43934,
                "label": "Albeck",
                "label_ar": "ألبيك",
                "label_fr": "Albeck"
            },
            {
                "value": 43935,
                "label": "Heimbach",
                "label_ar": "Heimbach",
                "label_fr": "Heimbach"
            },
            {
                "value": 43936,
                "label": "Neuburg",
                "label_ar": "نيوبورج",
                "label_fr": "Neubourg"
            },
            {
                "value": 43937,
                "label": "Feldrennach",
                "label_ar": "فيلدريناش",
                "label_fr": "Feldrennach"
            },
            {
                "value": 43938,
                "label": "Oberreichenbach",
                "label_ar": "Oberreichenbach",
                "label_fr": "Oberreichenbach"
            },
            {
                "value": 43939,
                "label": "Engelsburg",
                "label_ar": "إنجلسبورغ",
                "label_fr": "Engelsburg"
            },
            {
                "value": 43940,
                "label": "Grunkraut",
                "label_ar": "Grunkraut",
                "label_fr": "Grunkraut"
            },
            {
                "value": 43941,
                "label": "Lichtenwald",
                "label_ar": "ليشتنفالد",
                "label_fr": "Lichtenwald"
            },
            {
                "value": 43942,
                "label": "Otterbach",
                "label_ar": "أوترباخ",
                "label_fr": "Otterbach"
            },
            {
                "value": 43943,
                "label": "Oppendorf",
                "label_ar": "اوبندورف",
                "label_fr": "Oppendorf"
            },
            {
                "value": 43944,
                "label": "Mohren",
                "label_ar": "محرين",
                "label_fr": "Mohren"
            },
            {
                "value": 43945,
                "label": "Werste",
                "label_ar": "فيرست",
                "label_fr": "Werste"
            },
            {
                "value": 43946,
                "label": "Zeiskam",
                "label_ar": "زيسكام",
                "label_fr": "Zeiskam"
            },
            {
                "value": 43947,
                "label": "Bach an der Donau",
                "label_ar": "باخ ان دير دوناو",
                "label_fr": "Bach an der Donau"
            },
            {
                "value": 43948,
                "label": "Steinfeld",
                "label_ar": "شتاينفيلد",
                "label_fr": "Steinfeld"
            },
            {
                "value": 43949,
                "label": "Blankensee",
                "label_ar": "بلانكينسي",
                "label_fr": "Blankensee"
            },
            {
                "value": 43950,
                "label": "Dorth",
                "label_ar": "دورث",
                "label_fr": "Dorth"
            },
            {
                "value": 43951,
                "label": "Friedensau",
                "label_ar": "فريدنسو",
                "label_fr": "Friedensau"
            },
            {
                "value": 43952,
                "label": "Buschbell",
                "label_ar": "بوشبل",
                "label_fr": "Buschbell"
            },
            {
                "value": 43953,
                "label": "Mailing",
                "label_ar": "المراسلات",
                "label_fr": "Envoi postal"
            },
            {
                "value": 43954,
                "label": "Huhnerhof",
                "label_ar": "هوهنرهوف",
                "label_fr": "Huhnerhof"
            },
            {
                "value": 43955,
                "label": "Munchhausen",
                "label_ar": "مونشهاوزن",
                "label_fr": "Munchhausen"
            },
            {
                "value": 43956,
                "label": "Lutz",
                "label_ar": "لوتز",
                "label_fr": "Lutz"
            },
            {
                "value": 43957,
                "label": "Maasholm",
                "label_ar": "معشولم",
                "label_fr": "Maasholm"
            },
            {
                "value": 43958,
                "label": "Laasdorf",
                "label_ar": "لاسدورف",
                "label_fr": "Laasdorf"
            },
            {
                "value": 43959,
                "label": "Redwitz an der Rodach",
                "label_ar": "Redwitz an der Rodach",
                "label_fr": "Redwitz an der Rodach"
            },
            {
                "value": 43960,
                "label": "Eckartsberg",
                "label_ar": "إيكارتسبرغ",
                "label_fr": "Eckartsberg"
            },
            {
                "value": 43961,
                "label": "Osterfeld",
                "label_ar": "اوسترفيلد",
                "label_fr": "Osterfeld"
            },
            {
                "value": 43962,
                "label": "Rackith",
                "label_ar": "راكيث",
                "label_fr": "Rackith"
            },
            {
                "value": 43963,
                "label": "Lebus",
                "label_ar": "ليبوس",
                "label_fr": "Lebus"
            },
            {
                "value": 43964,
                "label": "Tauche",
                "label_ar": "تاشي",
                "label_fr": "Tauche"
            },
            {
                "value": 43965,
                "label": "Tantow",
                "label_ar": "تانتو",
                "label_fr": "Tantow"
            },
            {
                "value": 43966,
                "label": "Werben",
                "label_ar": "ويربين",
                "label_fr": "Werben"
            },
            {
                "value": 43967,
                "label": "Bruch",
                "label_ar": "بروخ",
                "label_fr": "Bruch"
            },
            {
                "value": 43968,
                "label": "Abbensen",
                "label_ar": "أبينسن",
                "label_fr": "Abbensen"
            },
            {
                "value": 43969,
                "label": "Weenzen",
                "label_ar": "وينزن",
                "label_fr": "Weenzen"
            },
            {
                "value": 43970,
                "label": "Ostenfeld",
                "label_ar": "أوستينفيلد",
                "label_fr": "Ostenfeld"
            },
            {
                "value": 43971,
                "label": "Osterstedt",
                "label_ar": "أوسترستيدت",
                "label_fr": "Osterstedt"
            },
            {
                "value": 43972,
                "label": "Landscheide",
                "label_ar": "أرض",
                "label_fr": "Landscheide"
            },
            {
                "value": 43973,
                "label": "Jahnshof",
                "label_ar": "جهنشوف",
                "label_fr": "Jahnshof"
            },
            {
                "value": 43974,
                "label": "Margarethen",
                "label_ar": "مارجريتن",
                "label_fr": "Margarethen"
            },
            {
                "value": 43975,
                "label": "Saint Michaelisdonn",
                "label_ar": "القديس ميكاييسدون",
                "label_fr": "Saint Michaelisdonn"
            },
            {
                "value": 43976,
                "label": "Tarbek",
                "label_ar": "طربك",
                "label_fr": "Tarbek"
            },
            {
                "value": 43977,
                "label": "Schafstedt",
                "label_ar": "شافستيدت",
                "label_fr": "Schafstedt"
            },
            {
                "value": 43978,
                "label": "Massenhausen",
                "label_ar": "ماسينهاوزن",
                "label_fr": "Massenhausen"
            },
            {
                "value": 43979,
                "label": "Aying",
                "label_ar": "عايش",
                "label_fr": "Aying"
            },
            {
                "value": 43980,
                "label": "Maitenbeth",
                "label_ar": "مايتنبيث",
                "label_fr": "Maitenbeth"
            },
            {
                "value": 43981,
                "label": "Giggenhausen",
                "label_ar": "جيجينهاوزن",
                "label_fr": "Giggenhausen"
            },
            {
                "value": 43982,
                "label": "Kapellen-Drusweiler",
                "label_ar": "كابيلين دروسويلر",
                "label_fr": "Kapellen-Drusweiler"
            },
            {
                "value": 43983,
                "label": "Lagesbuttel",
                "label_ar": "Lagesbuttel",
                "label_fr": "Lagesbuttel"
            },
            {
                "value": 43984,
                "label": "Geiselwind",
                "label_ar": "جيزيلويند",
                "label_fr": "Geiselwind"
            },
            {
                "value": 43985,
                "label": "Ebrach",
                "label_ar": "إبراش",
                "label_fr": "Ebrach"
            },
            {
                "value": 43986,
                "label": "Burglauer",
                "label_ar": "بورغلاور",
                "label_fr": "Cambrioleur"
            },
            {
                "value": 43987,
                "label": "Thungen",
                "label_ar": "ثونغين",
                "label_fr": "Thungen"
            },
            {
                "value": 43988,
                "label": "Rumeltshausen",
                "label_ar": "روميلتسهاوزن",
                "label_fr": "Rumeltshausen"
            },
            {
                "value": 43989,
                "label": "Esgrus",
                "label_ar": "إسجروس",
                "label_fr": "Esgrus"
            },
            {
                "value": 43990,
                "label": "Sterup",
                "label_ar": "ستروب",
                "label_fr": "Sterup"
            },
            {
                "value": 43991,
                "label": "Neckarweihingen",
                "label_ar": "نيكارفاينجن",
                "label_fr": "Neckarweihingen"
            },
            {
                "value": 43992,
                "label": "Tailfingen",
                "label_ar": "تيلفينغن",
                "label_fr": "Tailfingen"
            },
            {
                "value": 43993,
                "label": "Schopfloch",
                "label_ar": "Schopfloch",
                "label_fr": "Schopfloch"
            },
            {
                "value": 43994,
                "label": "Walting",
                "label_ar": "Walting",
                "label_fr": "Walting"
            },
            {
                "value": 43995,
                "label": "Gerhardshofen",
                "label_ar": "جيرهاردشوفن",
                "label_fr": "Gerhardshofen"
            },
            {
                "value": 43996,
                "label": "Trautskirchen",
                "label_ar": "تراوتسكيرشن",
                "label_fr": "Trautskirchen"
            },
            {
                "value": 43997,
                "label": "Lettenreuth",
                "label_ar": "ليتنروث",
                "label_fr": "Lettenreuth"
            },
            {
                "value": 43998,
                "label": "Scherstetten",
                "label_ar": "شيرستيتن",
                "label_fr": "Scherstetten"
            },
            {
                "value": 43999,
                "label": "Lohnsfeld",
                "label_ar": "Lohnsfeld",
                "label_fr": "Lohnsfeld"
            },
            {
                "value": 44000,
                "label": "Schauerberg",
                "label_ar": "شويربيرج",
                "label_fr": "Schauerberg"
            },
            {
                "value": 44001,
                "label": "Zuffenhausen",
                "label_ar": "زوفنهاوزن",
                "label_fr": "Zuffenhausen"
            },
            {
                "value": 44002,
                "label": "Liebenwalde",
                "label_ar": "ليبينوالد",
                "label_fr": "Liebenwalde"
            },
            {
                "value": 44003,
                "label": "Tarmow",
                "label_ar": "تارمو",
                "label_fr": "Tarmow"
            },
            {
                "value": 44004,
                "label": "Waldlaubersheim",
                "label_ar": "فالدلاوبرشيم",
                "label_fr": "Waldlaubersheim"
            },
            {
                "value": 44005,
                "label": "Niederheimbach",
                "label_ar": "نيدرهيمباخ",
                "label_fr": "Niederheimbach"
            },
            {
                "value": 44006,
                "label": "Holzbach",
                "label_ar": "هولزباخ",
                "label_fr": "Holzbach"
            },
            {
                "value": 44007,
                "label": "Kamp-Bornhofen",
                "label_ar": "كامب بورنهوفن",
                "label_fr": "Kamp-Bornhofen"
            },
            {
                "value": 44008,
                "label": "Niederlindhart",
                "label_ar": "نيدرليندهارت",
                "label_fr": "Niederlindhart"
            },
            {
                "value": 44009,
                "label": "Elmpt",
                "label_ar": "Elmpt",
                "label_fr": "Elmpt"
            },
            {
                "value": 44010,
                "label": "Windberg",
                "label_ar": "ويندبرج",
                "label_fr": "Windberg"
            },
            {
                "value": 44011,
                "label": "Listerfehrda",
                "label_ar": "ليسترفهردا",
                "label_fr": "Listerfehrda"
            },
            {
                "value": 44012,
                "label": "Wasungen",
                "label_ar": "واسونغين",
                "label_fr": "Wasungen"
            },
            {
                "value": 44013,
                "label": "Insheim",
                "label_ar": "إنشيم",
                "label_fr": "Insheim"
            },
            {
                "value": 44014,
                "label": "Hochstadt",
                "label_ar": "هوشستادت",
                "label_fr": "Hochstadt"
            },
            {
                "value": 44015,
                "label": "Boffzen",
                "label_ar": "بوفزن",
                "label_fr": "Boffzen"
            },
            {
                "value": 44016,
                "label": "Wolfsheim",
                "label_ar": "ولفشيم",
                "label_fr": "Wolfsheim"
            },
            {
                "value": 44017,
                "label": "Lautern",
                "label_ar": "لوترن",
                "label_fr": "Lautern"
            },
            {
                "value": 44018,
                "label": "Nebringen",
                "label_ar": "نيبرينجين",
                "label_fr": "Nebringen"
            },
            {
                "value": 44019,
                "label": "Egesheim",
                "label_ar": "اجيشيم",
                "label_fr": "Egesheim"
            },
            {
                "value": 44020,
                "label": "Woltershausen",
                "label_ar": "ولترسهاوزن",
                "label_fr": "Woltershausen"
            },
            {
                "value": 44021,
                "label": "Wietzendorf",
                "label_ar": "Wietzendorf",
                "label_fr": "Wietzendorf"
            },
            {
                "value": 44022,
                "label": "Wenzingen",
                "label_ar": "Wenzingen",
                "label_fr": "Wenzingen"
            },
            {
                "value": 44023,
                "label": "Pilsach",
                "label_ar": "بيلساك",
                "label_fr": "Pilsach"
            },
            {
                "value": 44024,
                "label": "Kirchhaslach",
                "label_ar": "كيرشاسلاش",
                "label_fr": "Kirchhaslach"
            },
            {
                "value": 44025,
                "label": "Lengthal",
                "label_ar": "Lengthal",
                "label_fr": "Lengthal"
            },
            {
                "value": 44026,
                "label": "Adelsried",
                "label_ar": "Adelsried",
                "label_fr": "Adelsried"
            },
            {
                "value": 44027,
                "label": "Ehrenberg",
                "label_ar": "إهرنبرغ",
                "label_fr": "Ehrenberg"
            },
            {
                "value": 44028,
                "label": "Floss",
                "label_ar": "الخيط",
                "label_fr": "Soie"
            },
            {
                "value": 44029,
                "label": "Trogen",
                "label_ar": "تروجين",
                "label_fr": "Trogen"
            },
            {
                "value": 44030,
                "label": "Etzelwang",
                "label_ar": "Etzelwang",
                "label_fr": "Etzelwang"
            },
            {
                "value": 44031,
                "label": "Issigau",
                "label_ar": "ايسيجاو",
                "label_fr": "Issigau"
            },
            {
                "value": 44032,
                "label": "Neusorg",
                "label_ar": "نيوسورج",
                "label_fr": "Neusorg"
            },
            {
                "value": 44033,
                "label": "Reitsch",
                "label_ar": "ريتش",
                "label_fr": "Reitsch"
            },
            {
                "value": 44034,
                "label": "Kulz",
                "label_ar": "كولز",
                "label_fr": "Kulz"
            },
            {
                "value": 44035,
                "label": "Brand",
                "label_ar": "الماركة",
                "label_fr": "Marque"
            },
            {
                "value": 44036,
                "label": "Tiefenbach",
                "label_ar": "تيفينباخ",
                "label_fr": "Tiefenbach"
            },
            {
                "value": 44037,
                "label": "Gablenz",
                "label_ar": "جابلينز",
                "label_fr": "Gablenz"
            },
            {
                "value": 44038,
                "label": "Uebigau",
                "label_ar": "أوبيجاو",
                "label_fr": "Uebigau"
            },
            {
                "value": 44039,
                "label": "Schleife",
                "label_ar": "شلايف",
                "label_fr": "Schleife"
            },
            {
                "value": 44040,
                "label": "Schweizerhof",
                "label_ar": "شفايتزرهوف",
                "label_fr": "Schweizerhof"
            },
            {
                "value": 44041,
                "label": "Aschau",
                "label_ar": "أشاو",
                "label_fr": "Aschau"
            },
            {
                "value": 44042,
                "label": "Wittenberg",
                "label_ar": "ويتنبرغ",
                "label_fr": "Wittenberg"
            },
            {
                "value": 44043,
                "label": "Lechbruck",
                "label_ar": "ليشبروك",
                "label_fr": "Lechbruck"
            },
            {
                "value": 44044,
                "label": "Querum",
                "label_ar": "Querum",
                "label_fr": "Querum"
            },
            {
                "value": 44045,
                "label": "Riding",
                "label_ar": "يركب",
                "label_fr": "Équitation"
            },
            {
                "value": 44046,
                "label": "Rothof",
                "label_ar": "روثوف",
                "label_fr": "Rothof"
            },
            {
                "value": 44047,
                "label": "Wassersleben",
                "label_ar": "فاسرسليبن",
                "label_fr": "Wassersleben"
            },
            {
                "value": 44048,
                "label": "Steglitz Bezirk",
                "label_ar": "Steglitz Bezirk",
                "label_fr": "Steglitz Bezirk"
            },
            {
                "value": 44049,
                "label": "Grunhof",
                "label_ar": "جرونهوف",
                "label_fr": "Grunhof"
            },
            {
                "value": 44050,
                "label": "Ober-Roden",
                "label_ar": "أوبر رودين",
                "label_fr": "Ober-Roden"
            },
            {
                "value": 44051,
                "label": "Birnbach",
                "label_ar": "بيرنباخ",
                "label_fr": "Birnbach"
            },
            {
                "value": 44052,
                "label": "Stiege",
                "label_ar": "ستيج",
                "label_fr": "Stiege"
            },
            {
                "value": 44053,
                "label": "Schlanstedt",
                "label_ar": "شلانستيدت",
                "label_fr": "Schlanstedt"
            },
            {
                "value": 44054,
                "label": "Freudenberg",
                "label_ar": "فرويدنبرغ",
                "label_fr": "Freudenberg"
            },
            {
                "value": 44055,
                "label": "Weidenthal",
                "label_ar": "وايدنثال",
                "label_fr": "Weidenthal"
            },
            {
                "value": 44056,
                "label": "Meckenheim",
                "label_ar": "ميكنهايم",
                "label_fr": "Meckenheim"
            },
            {
                "value": 44057,
                "label": "Ruppertsberg",
                "label_ar": "روبيرتسبرج",
                "label_fr": "Ruppertsberg"
            },
            {
                "value": 44058,
                "label": "Edesheim",
                "label_ar": "Edesheim",
                "label_fr": "Edesheim"
            },
            {
                "value": 44059,
                "label": "Urmitz",
                "label_ar": "أورميتز",
                "label_fr": "Urmitz"
            },
            {
                "value": 44060,
                "label": "Saffig",
                "label_ar": "Saffig",
                "label_fr": "Saffig"
            },
            {
                "value": 44061,
                "label": "Helmenzen",
                "label_ar": "هيلمنزين",
                "label_fr": "Helmenzen"
            },
            {
                "value": 44062,
                "label": "Breitscheidt",
                "label_ar": "بريتشيدت",
                "label_fr": "Breitscheidt"
            },
            {
                "value": 44063,
                "label": "Ehlscheid",
                "label_ar": "اهلشايد",
                "label_fr": "Ehlscheid"
            },
            {
                "value": 44064,
                "label": "Weinahr",
                "label_ar": "وينهر",
                "label_fr": "Weinahr"
            },
            {
                "value": 44065,
                "label": "Wilhelmsdorf",
                "label_ar": "فيلهلمسدورف",
                "label_fr": "Wilhelmsdorf"
            },
            {
                "value": 44066,
                "label": "Egloffstein",
                "label_ar": "اغلوفشتاين",
                "label_fr": "Egloffstein"
            },
            {
                "value": 44067,
                "label": "Ochtmersleben",
                "label_ar": "Ochtmersleben",
                "label_fr": "Ochtmersleben"
            },
            {
                "value": 44068,
                "label": "Rosenburg",
                "label_ar": "روزنبورغ",
                "label_fr": "Rosenburg"
            },
            {
                "value": 44069,
                "label": "Zeppernick",
                "label_ar": "زيبيرنيك",
                "label_fr": "Zeppernick"
            },
            {
                "value": 44070,
                "label": "Gernrode",
                "label_ar": "جيرنرود",
                "label_fr": "Gernrode"
            },
            {
                "value": 44071,
                "label": "Sandberg",
                "label_ar": "ساندبرج",
                "label_fr": "Sandberg"
            },
            {
                "value": 44072,
                "label": "Worth am Main",
                "label_ar": "يستحق الأمر الرئيسي",
                "label_fr": "Worth am Main"
            },
            {
                "value": 44073,
                "label": "Neuendorf",
                "label_ar": "نويندورف",
                "label_fr": "Neuendorf"
            },
            {
                "value": 44074,
                "label": "Benzingerode",
                "label_ar": "بنزينجيرود",
                "label_fr": "Benzingerode"
            },
            {
                "value": 44075,
                "label": "Morsleben",
                "label_ar": "مورسليبن",
                "label_fr": "Morsleben"
            },
            {
                "value": 44076,
                "label": "Vahldorf",
                "label_ar": "فالدورف",
                "label_fr": "Vahldorf"
            },
            {
                "value": 44077,
                "label": "Hotensleben",
                "label_ar": "Hotensleben",
                "label_fr": "Hotensleben"
            },
            {
                "value": 44078,
                "label": "Arrach",
                "label_ar": "Arrach",
                "label_fr": "Arrach"
            },
            {
                "value": 44079,
                "label": "Leukersdorf",
                "label_ar": "Leukersdorf",
                "label_fr": "Leukersdorf"
            },
            {
                "value": 44080,
                "label": "Heiligengrabe",
                "label_ar": "Heiligengrabe",
                "label_fr": "Heiligengrabe"
            },
            {
                "value": 44081,
                "label": "Klausdorf",
                "label_ar": "كلاوسدورف",
                "label_fr": "Klausdorf"
            },
            {
                "value": 44082,
                "label": "Honebach",
                "label_ar": "هونباخ",
                "label_fr": "Honebach"
            },
            {
                "value": 44083,
                "label": "Plodda",
                "label_ar": "بلوددا",
                "label_fr": "Plodda"
            },
            {
                "value": 44084,
                "label": "Krumpa",
                "label_ar": "كرومبا",
                "label_fr": "Krumpa"
            },
            {
                "value": 44085,
                "label": "Stangerode",
                "label_ar": "ستانجرود",
                "label_fr": "Stangerode"
            },
            {
                "value": 44086,
                "label": "Tilleda",
                "label_ar": "تيلدا",
                "label_fr": "Tilleda"
            },
            {
                "value": 44087,
                "label": "Tiefenthal",
                "label_ar": "تيفينثال",
                "label_fr": "Tiefenthal"
            },
            {
                "value": 44088,
                "label": "Rorichum",
                "label_ar": "روريشوم",
                "label_fr": "Rorichum"
            },
            {
                "value": 44089,
                "label": "Pewsum",
                "label_ar": "بيوسوم",
                "label_fr": "Pewsum"
            },
            {
                "value": 44090,
                "label": "Dalum",
                "label_ar": "دالوم",
                "label_fr": "Dalum"
            },
            {
                "value": 44091,
                "label": "Heiligenstadt",
                "label_ar": "هيليغنشتات",
                "label_fr": "Heiligenstadt"
            },
            {
                "value": 44092,
                "label": "Mainbernheim",
                "label_ar": "ماينبيرنهايم",
                "label_fr": "Mainbernheim"
            },
            {
                "value": 44093,
                "label": "Riedenheim",
                "label_ar": "ريدنهايم",
                "label_fr": "Riedenheim"
            },
            {
                "value": 44094,
                "label": "Kist",
                "label_ar": "كيست",
                "label_fr": "Kist"
            },
            {
                "value": 44095,
                "label": "Mittelstreu",
                "label_ar": "ميتيلسترو",
                "label_fr": "Mittelstreu"
            },
            {
                "value": 44096,
                "label": "Aub",
                "label_ar": "أوب",
                "label_fr": "Aub"
            },
            {
                "value": 44097,
                "label": "Dietenhofen",
                "label_ar": "ديدينهوفن",
                "label_fr": "Dietenhofen"
            },
            {
                "value": 44098,
                "label": "Langenfeld",
                "label_ar": "لانغنفيلد",
                "label_fr": "Langenfeld"
            },
            {
                "value": 44099,
                "label": "Baudenbach",
                "label_ar": "بودنباخ",
                "label_fr": "Baudenbach"
            },
            {
                "value": 44100,
                "label": "Spechtsbrunn",
                "label_ar": "Spechtsbrunn",
                "label_fr": "Spechtsbrunn"
            },
            {
                "value": 44101,
                "label": "Niedereisenhausen",
                "label_ar": "Niedereisenhausen",
                "label_fr": "Niedereisenhausen"
            },
            {
                "value": 44102,
                "label": "Dauborn",
                "label_ar": "دوبورن",
                "label_fr": "Dauborn"
            },
            {
                "value": 44103,
                "label": "Kirrweiler",
                "label_ar": "كيرفايلر",
                "label_fr": "Kirrweiler"
            },
            {
                "value": 44104,
                "label": "Glindenberg",
                "label_ar": "جليندنبرج",
                "label_fr": "Glindenberg"
            },
            {
                "value": 44105,
                "label": "Veckenstedt",
                "label_ar": "Veckenstedt",
                "label_fr": "Veckenstedt"
            },
            {
                "value": 44106,
                "label": "Wasserleben",
                "label_ar": "Wasserleben",
                "label_fr": "Wasserleben"
            },
            {
                "value": 44107,
                "label": "Garz",
                "label_ar": "جارز",
                "label_fr": "Garz"
            },
            {
                "value": 44108,
                "label": "Behrenhoff",
                "label_ar": "بهرنهوف",
                "label_fr": "Behrenhoff"
            },
            {
                "value": 44109,
                "label": "Katzow",
                "label_ar": "كاتسو",
                "label_fr": "Katzow"
            },
            {
                "value": 44110,
                "label": "Ostseebad Gohren",
                "label_ar": "Ostseebad Gohren",
                "label_fr": "Ostseebad Gohren"
            },
            {
                "value": 44111,
                "label": "Kroslin",
                "label_ar": "كروسلين",
                "label_fr": "Kroslin"
            },
            {
                "value": 44112,
                "label": "Gohren",
                "label_ar": "جوهرين",
                "label_fr": "Gohren"
            },
            {
                "value": 44113,
                "label": "Seebad Heringsdorf",
                "label_ar": "سيباد هيرينغسدورف",
                "label_fr": "Seebad Heringsdorf"
            },
            {
                "value": 44114,
                "label": "Dranske",
                "label_ar": "درنسك",
                "label_fr": "Dranske"
            },
            {
                "value": 44115,
                "label": "Hinrichshagen",
                "label_ar": "Hinrichshagen",
                "label_fr": "Hinrichshagen"
            },
            {
                "value": 44116,
                "label": "Altenkirchen",
                "label_ar": "التنكيرشن",
                "label_fr": "Altenkirchen"
            },
            {
                "value": 44117,
                "label": "Gross Kiesow",
                "label_ar": "جروس كيسو",
                "label_fr": "Gross Kiesow"
            },
            {
                "value": 44118,
                "label": "Lassan",
                "label_ar": "لاسان",
                "label_fr": "Lassan"
            },
            {
                "value": 44119,
                "label": "Zussow",
                "label_ar": "زوسو",
                "label_fr": "Zussow"
            },
            {
                "value": 44120,
                "label": "Hochwang",
                "label_ar": "هوشوانغ",
                "label_fr": "Hochwang"
            },
            {
                "value": 44121,
                "label": "Duggendorf",
                "label_ar": "دوجندورف",
                "label_fr": "Duggendorf"
            },
            {
                "value": 44122,
                "label": "Weigendorf",
                "label_ar": "Weigendorf",
                "label_fr": "Weigendorf"
            },
            {
                "value": 44123,
                "label": "Joehlingen",
                "label_ar": "Joehlingen",
                "label_fr": "Joehlingen"
            },
            {
                "value": 44124,
                "label": "Ittersbach",
                "label_ar": "إترسباخ",
                "label_fr": "Ittersbach"
            },
            {
                "value": 44125,
                "label": "Rosdorf",
                "label_ar": "روزدورف",
                "label_fr": "Rosdorf"
            },
            {
                "value": 44126,
                "label": "Wrist",
                "label_ar": "المعصم",
                "label_fr": "Poignet"
            },
            {
                "value": 44127,
                "label": "Grosskorbetha",
                "label_ar": "جروسكوربيتا",
                "label_fr": "Grosskorbetha"
            },
            {
                "value": 44128,
                "label": "Ruscheid",
                "label_ar": "رشيد",
                "label_fr": "Ruscheid"
            },
            {
                "value": 44129,
                "label": "Stein-Neukirch",
                "label_ar": "شتاين نويكيرش",
                "label_fr": "Stein-Neukirch"
            },
            {
                "value": 44130,
                "label": "Rieden",
                "label_ar": "ريدن",
                "label_fr": "Rieden"
            },
            {
                "value": 44131,
                "label": "Buchel",
                "label_ar": "بوشل",
                "label_fr": "Buchel"
            },
            {
                "value": 44132,
                "label": "Thalhausen",
                "label_ar": "ثالهاوزن",
                "label_fr": "Thalhausen"
            },
            {
                "value": 44133,
                "label": "Oberhonnefeld-Gierend",
                "label_ar": "أوبيرهونيفيلد جيريند",
                "label_fr": "Oberhonnefeld-Gierend"
            },
            {
                "value": 44134,
                "label": "Weitzschen",
                "label_ar": "Weitzschen",
                "label_fr": "Weitzschen"
            },
            {
                "value": 44135,
                "label": "Kohra",
                "label_ar": "كوهرا",
                "label_fr": "Kohra"
            },
            {
                "value": 44136,
                "label": "Hohenfinow",
                "label_ar": "هوهينفينوف",
                "label_fr": "Hohenfinow"
            },
            {
                "value": 44137,
                "label": "Spreenhagen",
                "label_ar": "سبرينهاجين",
                "label_fr": "Spreenhagen"
            },
            {
                "value": 44138,
                "label": "Freudenberg",
                "label_ar": "فرويدنبرغ",
                "label_fr": "Freudenberg"
            },
            {
                "value": 44139,
                "label": "Ziltendorf",
                "label_ar": "زيلتندورف",
                "label_fr": "Ziltendorf"
            },
            {
                "value": 44140,
                "label": "Arnsdorf",
                "label_ar": "ارنسدورف",
                "label_fr": "Arnsdorf"
            },
            {
                "value": 44141,
                "label": "Dintesheim",
                "label_ar": "دينتشيم",
                "label_fr": "Dintesheim"
            },
            {
                "value": 44142,
                "label": "Ludwigshohe",
                "label_ar": "لودفيجشوه",
                "label_fr": "Ludwigshohe"
            },
            {
                "value": 44143,
                "label": "Dahnsdorf",
                "label_ar": "دانسدورف",
                "label_fr": "Dahnsdorf"
            },
            {
                "value": 44144,
                "label": "Oberporing",
                "label_ar": "Oberporing",
                "label_fr": "Oberporing"
            },
            {
                "value": 44145,
                "label": "Drachselsried",
                "label_ar": "دراتشلسريد",
                "label_fr": "Drachselsried"
            },
            {
                "value": 44146,
                "label": "Aufhausen",
                "label_ar": "اوفهاوزن",
                "label_fr": "Aufhausen"
            },
            {
                "value": 44147,
                "label": "Niedermurach",
                "label_ar": "نيدرموراخ",
                "label_fr": "Niedermurach"
            },
            {
                "value": 44148,
                "label": "Roggenburg",
                "label_ar": "روجينبورغ",
                "label_fr": "Roggenburg"
            },
            {
                "value": 44149,
                "label": "Veringendorf",
                "label_ar": "فيرينجيندورف",
                "label_fr": "Veringendorf"
            },
            {
                "value": 44150,
                "label": "Konigsheim",
                "label_ar": "كونيغشيم",
                "label_fr": "Königsheim"
            },
            {
                "value": 44151,
                "label": "Marquartstein",
                "label_ar": "ماركوارتشتاين",
                "label_fr": "Marquartstein"
            },
            {
                "value": 44152,
                "label": "Marsdorf",
                "label_ar": "مارسدورف",
                "label_fr": "Marsdorf"
            },
            {
                "value": 44153,
                "label": "Muhle",
                "label_ar": "Muhle",
                "label_fr": "Muhle"
            },
            {
                "value": 44154,
                "label": "Beucha",
                "label_ar": "بيوتشا",
                "label_fr": "Beucha"
            },
            {
                "value": 44155,
                "label": "Mockrehna",
                "label_ar": "موكريهنا",
                "label_fr": "Mockrehna"
            },
            {
                "value": 44156,
                "label": "Knobelsdorf",
                "label_ar": "كنوبلسدورف",
                "label_fr": "Knobelsdorf"
            },
            {
                "value": 44157,
                "label": "Rotha",
                "label_ar": "روثا",
                "label_fr": "Rotha"
            },
            {
                "value": 44158,
                "label": "Fahrenbach",
                "label_ar": "فهرنباخ",
                "label_fr": "Fahrenbach"
            },
            {
                "value": 44159,
                "label": "Strahlungen",
                "label_ar": "Strahlungen",
                "label_fr": "Strahlungen"
            },
            {
                "value": 44160,
                "label": "Dambeck",
                "label_ar": "دامبيك",
                "label_fr": "Dambeck"
            },
            {
                "value": 44161,
                "label": "Quitzow",
                "label_ar": "كيتزو",
                "label_fr": "Quitzow"
            },
            {
                "value": 44162,
                "label": "Adenbuettel",
                "label_ar": "Adenbuettel",
                "label_fr": "Adenbuettel"
            },
            {
                "value": 44163,
                "label": "Schollnach",
                "label_ar": "شولناتش",
                "label_fr": "Schollnach"
            },
            {
                "value": 44164,
                "label": "Gulzow",
                "label_ar": "جولزو",
                "label_fr": "Gulzow"
            },
            {
                "value": 44165,
                "label": "Parum",
                "label_ar": "باروم",
                "label_fr": "Parum"
            },
            {
                "value": 44166,
                "label": "Ahrenshagen",
                "label_ar": "Ahrenshagen",
                "label_fr": "Ahrenshagen"
            },
            {
                "value": 44167,
                "label": "Biburg",
                "label_ar": "بيبورغ",
                "label_fr": "Biburg"
            },
            {
                "value": 44168,
                "label": "Oberschweinbach",
                "label_ar": "أوبيرشوينباخ",
                "label_fr": "Oberschweinbach"
            },
            {
                "value": 44169,
                "label": "Hayingen",
                "label_ar": "هاينجن",
                "label_fr": "Hayingen"
            },
            {
                "value": 44170,
                "label": "Bulowerburg",
                "label_ar": "بولويربورغ",
                "label_fr": "Bulowerburg"
            },
            {
                "value": 44171,
                "label": "Lohberg",
                "label_ar": "Lohberg",
                "label_fr": "Lohberg"
            },
            {
                "value": 44172,
                "label": "Kapsweyer",
                "label_ar": "Kapsweyer",
                "label_fr": "Kapsweyer"
            },
            {
                "value": 44173,
                "label": "Rositz",
                "label_ar": "روزيتز",
                "label_fr": "Rositz"
            },
            {
                "value": 44174,
                "label": "Schlehdorf",
                "label_ar": "شليهدورف",
                "label_fr": "Schlehdorf"
            },
            {
                "value": 44175,
                "label": "Tieringen",
                "label_ar": "تيرينجين",
                "label_fr": "Tieringen"
            },
            {
                "value": 44176,
                "label": "Weixerau",
                "label_ar": "Weixerau",
                "label_fr": "Weixerau"
            },
            {
                "value": 44177,
                "label": "Kriebitzsch",
                "label_ar": "Kriebitzsch",
                "label_fr": "Kriebitzsch"
            },
            {
                "value": 44178,
                "label": "Straussfurt",
                "label_ar": "شتراوسفورت",
                "label_fr": "Straussfurt"
            },
            {
                "value": 44179,
                "label": "Gerbershausen",
                "label_ar": "جيربيرشهاوزن",
                "label_fr": "Gerbershausen"
            },
            {
                "value": 44180,
                "label": "Kieselbach",
                "label_ar": "كيسيلباخ",
                "label_fr": "Kieselbach"
            },
            {
                "value": 44181,
                "label": "Geisenhain",
                "label_ar": "جيزنهاين",
                "label_fr": "Geisenhain"
            },
            {
                "value": 44182,
                "label": "Oberbodnitz",
                "label_ar": "أوبيربودنيتز",
                "label_fr": "Oberbodnitz"
            },
            {
                "value": 44183,
                "label": "Callbach",
                "label_ar": "كالباخ",
                "label_fr": "Callbach"
            },
            {
                "value": 44184,
                "label": "Dankenfeld",
                "label_ar": "دانكينفيلد",
                "label_fr": "Dankenfeld"
            },
            {
                "value": 44185,
                "label": "Schammelsdorf",
                "label_ar": "شاملسدورف",
                "label_fr": "Schammelsdorf"
            },
            {
                "value": 44186,
                "label": "Nieheim",
                "label_ar": "نيهايم",
                "label_fr": "Nieheim"
            },
            {
                "value": 44187,
                "label": "Bruchhausen",
                "label_ar": "Bruchhausen",
                "label_fr": "Bruchhausen"
            },
            {
                "value": 44188,
                "label": "Eichen",
                "label_ar": "ايتشن",
                "label_fr": "Eichen"
            },
            {
                "value": 44189,
                "label": "Haswede",
                "label_ar": "هاسويدي",
                "label_fr": "Haswede"
            },
            {
                "value": 44190,
                "label": "Bonstetten",
                "label_ar": "بونستيتين",
                "label_fr": "Bonstetten"
            },
            {
                "value": 44191,
                "label": "Beetzsee",
                "label_ar": "بيتسي",
                "label_fr": "Beetzsee"
            },
            {
                "value": 44192,
                "label": "Hoppegarten",
                "label_ar": "Hoppegarten",
                "label_fr": "Hoppegarten"
            },
            {
                "value": 44193,
                "label": "Breitenworbis",
                "label_ar": "بريتينوربيس",
                "label_fr": "Breitenworbis"
            },
            {
                "value": 44194,
                "label": "Unterdiessen",
                "label_ar": "أونترديسن",
                "label_fr": "Unterdiessen"
            },
            {
                "value": 44195,
                "label": "Stottwang",
                "label_ar": "ستوتوانج",
                "label_fr": "Stottwang"
            },
            {
                "value": 44196,
                "label": "Obertaufkirchen",
                "label_ar": "Obertaufkirchen",
                "label_fr": "Obertaufkirchen"
            },
            {
                "value": 44197,
                "label": "Niederaichbach",
                "label_ar": "نيديريتشباخ",
                "label_fr": "Niederaichbach"
            },
            {
                "value": 44198,
                "label": "Aichtal",
                "label_ar": "ايشتال",
                "label_fr": "Aichtal"
            },
            {
                "value": 44199,
                "label": "Dunzweiler",
                "label_ar": "دونزويلر",
                "label_fr": "Dunzweiler"
            },
            {
                "value": 44200,
                "label": "Weselberg",
                "label_ar": "Weselberg",
                "label_fr": "Weselberg"
            },
            {
                "value": 44201,
                "label": "Holler",
                "label_ar": "صرخة",
                "label_fr": "Brailler"
            },
            {
                "value": 44202,
                "label": "Heuchlingen",
                "label_ar": "هيوتشلينجن",
                "label_fr": "Heuchlingen"
            },
            {
                "value": 44203,
                "label": "Lonsingen",
                "label_ar": "لونسينجين",
                "label_fr": "Lonsingen"
            },
            {
                "value": 44204,
                "label": "Munsterdorf",
                "label_ar": "مونستردورف",
                "label_fr": "Munsterdorf"
            },
            {
                "value": 44205,
                "label": "Alt Bukow",
                "label_ar": "ألت بوكو",
                "label_fr": "Alt Bukow"
            },
            {
                "value": 44206,
                "label": "Winsen",
                "label_ar": "وينسن",
                "label_fr": "Winsen"
            },
            {
                "value": 44207,
                "label": "Trassem",
                "label_ar": "تراسم",
                "label_fr": "Trassem"
            },
            {
                "value": 44208,
                "label": "Engelshof",
                "label_ar": "إنجلشوف",
                "label_fr": "Engelshof"
            },
            {
                "value": 44209,
                "label": "Winterbach",
                "label_ar": "وينترباخ",
                "label_fr": "Winterbach"
            },
            {
                "value": 44210,
                "label": "Neidenfels",
                "label_ar": "نيدنفيلس",
                "label_fr": "Neidenfels"
            },
            {
                "value": 44211,
                "label": "Kemerting",
                "label_ar": "كيمرتينج",
                "label_fr": "Kemerting"
            },
            {
                "value": 44212,
                "label": "Aurachtal",
                "label_ar": "أوراتشتال",
                "label_fr": "Aurachtal"
            },
            {
                "value": 44213,
                "label": "Frankenwinheim",
                "label_ar": "فرانكنوينهايم",
                "label_fr": "Frankenwinheim"
            },
            {
                "value": 44214,
                "label": "Armstorf",
                "label_ar": "أرمستورف",
                "label_fr": "Armstorf"
            }
        ]
    },
    {
        "country": "Israel",
        "cities": [
            {
                "value": 44343,
                "label": "HaKarmel",
                "label_ar": "هكرمل",
                "label_fr": "HaKarmel"
            },
            {
                "value": 44224,
                "label": "Ashdod",
                "label_ar": "أشدود",
                "label_fr": "Ashdod"
            },
            {
                "value": 44275,
                "label": "Bet Oren",
                "label_ar": "رهان أورين",
                "label_fr": "Bet Oren"
            },
            {
                "value": 44296,
                "label": "Tirat Yehuda",
                "label_ar": "طيرات يهودا",
                "label_fr": "Tirat Yehuda"
            },
            {
                "value": 44262,
                "label": "Et Taiyiba",
                "label_ar": "إت الطيبة",
                "label_fr": "Et Taiyiba"
            },
            {
                "value": 44317,
                "label": "Maghar",
                "label_ar": "المغار",
                "label_fr": "Maghar"
            },
            {
                "value": 44276,
                "label": "Haluz",
                "label_ar": "حلوز",
                "label_fr": "Haluz"
            },
            {
                "value": 44348,
                "label": "Ramat Ef`al",
                "label_ar": "رمات ايفال",
                "label_fr": "Ramat Efʻal"
            },
            {
                "value": 44355,
                "label": "Sde Boker",
                "label_ar": "سديه بوكر",
                "label_fr": "Sde Boker"
            },
            {
                "value": 44234,
                "label": "Haifa",
                "label_ar": "حيفا",
                "label_fr": "Haïfa"
            },
            {
                "value": 44249,
                "label": "Ofaqim",
                "label_ar": "آفاق",
                "label_fr": "Ofaqim"
            },
            {
                "value": 44243,
                "label": "Petah Tikwah",
                "label_ar": "بتاح تكوة",
                "label_fr": "Petah Tikwah"
            },
            {
                "value": 44290,
                "label": "Mazor",
                "label_ar": "مازور",
                "label_fr": "Mazor"
            },
            {
                "value": 44252,
                "label": "Qiryat Bialik",
                "label_ar": "كريات بياليك",
                "label_fr": "Qiryat Bialik"
            },
            {
                "value": 44235,
                "label": "Shave Ziyyon",
                "label_ar": "حلق زيون",
                "label_fr": "Rasez Ziyyon"
            },
            {
                "value": 44321,
                "label": "Bet Dagan",
                "label_ar": "بيت دغان",
                "label_fr": "Bet Dagan"
            },
            {
                "value": 44267,
                "label": "Baraq",
                "label_ar": "براق",
                "label_fr": "Baraq"
            },
            {
                "value": 44334,
                "label": "Hever",
                "label_ar": "حيفر",
                "label_fr": "Hever"
            },
            {
                "value": 44353,
                "label": "Rishpon",
                "label_ar": "ريشبون",
                "label_fr": "Rishpon"
            },
            {
                "value": 44273,
                "label": "Or `Aqiva",
                "label_ar": "او عقيفة",
                "label_fr": "Ou ʻAqiva"
            },
            {
                "value": 44285,
                "label": "Gedera",
                "label_ar": "جيدرا",
                "label_fr": "Gedera"
            },
            {
                "value": 44312,
                "label": "Ramat Dawid",
                "label_ar": "رمات داويد",
                "label_fr": "Ramat Dawid"
            },
            {
                "value": 44244,
                "label": "Ashqelon",
                "label_ar": "أشكلون",
                "label_fr": "Ashqelon"
            },
            {
                "value": 44254,
                "label": "Hod HaSharon",
                "label_ar": "هود هشارون",
                "label_fr": "Hod HaSharon"
            },
            {
                "value": 44295,
                "label": "Zoran",
                "label_ar": "زوران",
                "label_fr": "Zoran"
            },
            {
                "value": 44246,
                "label": "Naham",
                "label_ar": "نهام",
                "label_fr": "Naham"
            },
            {
                "value": 44230,
                "label": "Eilat",
                "label_ar": "إيلات",
                "label_fr": "Eilat"
            },
            {
                "value": 44231,
                "label": "Rehovot",
                "label_ar": "رحوفوت",
                "label_fr": "Rehovot"
            },
            {
                "value": 44247,
                "label": "Mazkeret Batya",
                "label_ar": "مزكيريت باتيا",
                "label_fr": "Mazkeret Batya"
            },
            {
                "value": 44371,
                "label": "Qiryat Hayyim",
                "label_ar": "كريات هاييم",
                "label_fr": "Qiryat Hayyim"
            },
            {
                "value": 44241,
                "label": "Netanya",
                "label_ar": "نتانيا",
                "label_fr": "Netanya"
            },
            {
                "value": 44320,
                "label": "Elyaqim",
                "label_ar": "الياقم",
                "label_fr": "Elyaqim"
            },
            {
                "value": 44322,
                "label": "Ginnosar",
                "label_ar": "جينوسار",
                "label_fr": "Ginnosar"
            },
            {
                "value": 44261,
                "label": "Nazareth",
                "label_ar": "الناصرة",
                "label_fr": "Nazareth"
            },
            {
                "value": 44259,
                "label": "`Alma",
                "label_ar": "ألما",
                "label_fr": "ʻAlma"
            },
            {
                "value": 44356,
                "label": "Yaqum",
                "label_ar": "يقوم",
                "label_fr": "Yaqum"
            },
            {
                "value": 44338,
                "label": "Mesillat Ziyyon",
                "label_ar": "مسيلات زيون",
                "label_fr": "Mesillat Ziyyon"
            },
            {
                "value": 44302,
                "label": "Hazor Ashdod",
                "label_ar": "حاصور أشدود",
                "label_fr": "Hazor Ashdod"
            },
            {
                "value": 44346,
                "label": "Sarid",
                "label_ar": "سريد",
                "label_fr": "Sarid"
            },
            {
                "value": 44304,
                "label": "Newe Efrayim",
                "label_ar": "نيو افرايم",
                "label_fr": "Newe Efrayim"
            },
            {
                "value": 44278,
                "label": "Gan Hayyim",
                "label_ar": "غان حاييم",
                "label_fr": "Gan Hayyim"
            },
            {
                "value": 44283,
                "label": "Misgav Regional Council",
                "label_ar": "المجلس الإقليمي مسغاف",
                "label_fr": "Conseil régional de Misgav"
            },
            {
                "value": 44216,
                "label": "Tel Aviv",
                "label_ar": "تل أبيب",
                "label_fr": "Tel Aviv"
            },
            {
                "value": 44363,
                "label": "Giv`at Hayyim",
                "label_ar": "جفعات هاييم",
                "label_fr": "Givʻat Hayyim"
            },
            {
                "value": 44309,
                "label": "Shoval",
                "label_ar": "شوفال",
                "label_fr": "Shoval"
            },
            {
                "value": 44226,
                "label": "Yehud",
                "label_ar": "يهود",
                "label_fr": "Yehud"
            },
            {
                "value": 44361,
                "label": "Hazor HaGelilit",
                "label_ar": "حاصور هجليليت",
                "label_fr": "Hazor HaGelilit"
            },
            {
                "value": 44326,
                "label": "Ahituv",
                "label_ar": "أهيتوف",
                "label_fr": "Ahituv"
            },
            {
                "value": 44264,
                "label": "Yavne",
                "label_ar": "يفني",
                "label_fr": "Yavne"
            },
            {
                "value": 44266,
                "label": "Nazerat `Illit",
                "label_ar": "نظرات إيليت",
                "label_fr": "Nazerat ʻIllit"
            },
            {
                "value": 44294,
                "label": "Rosh Ha'Ayin",
                "label_ar": "روش هاعين",
                "label_fr": "Rosh Ha'Ayin"
            },
            {
                "value": 44347,
                "label": "Nir Zevi",
                "label_ar": "نير زيفي",
                "label_fr": "Nir Zevi"
            },
            {
                "value": 44291,
                "label": "Shamir",
                "label_ar": "شامير",
                "label_fr": "Shamir"
            },
            {
                "value": 44240,
                "label": "Pardesiyya",
                "label_ar": "البرديسية",
                "label_fr": "Pardesiyya"
            },
            {
                "value": 44360,
                "label": "`Evron",
                "label_ar": "ʻ إيفرون",
                "label_fr": "ʻEvron"
            },
            {
                "value": 44308,
                "label": "Moran",
                "label_ar": "موران",
                "label_fr": "Moran"
            },
            {
                "value": 44217,
                "label": "Jerusalem",
                "label_ar": "بيت المقدس",
                "label_fr": "Jérusalem"
            },
            {
                "value": 44232,
                "label": "Herzliya",
                "label_ar": "هرتسليا",
                "label_fr": "Herzliya"
            },
            {
                "value": 44299,
                "label": "Hadid",
                "label_ar": "حديد",
                "label_fr": "Hadid"
            },
            {
                "value": 44236,
                "label": "Ramat HaSharon",
                "label_ar": "رمات هشارون",
                "label_fr": "Ramat HaSharon"
            },
            {
                "value": 44329,
                "label": "Bat Hefer",
                "label_ar": "بات حيفر",
                "label_fr": "Bat Hefer"
            },
            {
                "value": 44303,
                "label": "Kefar Daniyyel",
                "label_ar": "كفار دانيال",
                "label_fr": "Kefar Daniyyel"
            },
            {
                "value": 44310,
                "label": "Ramat Aviv",
                "label_ar": "رمات افيف",
                "label_fr": "Ramat Aviv"
            },
            {
                "value": 44359,
                "label": "Allonim",
                "label_ar": "ألونيم",
                "label_fr": "Allonim"
            },
            {
                "value": 44306,
                "label": "Kefar Netter",
                "label_ar": "كفار نتر",
                "label_fr": "Kefar Netter"
            },
            {
                "value": 44250,
                "label": "Shetulim",
                "label_ar": "شيتوليم",
                "label_fr": "Shetulim"
            },
            {
                "value": 44257,
                "label": "Binyamina",
                "label_ar": "بنيامينا",
                "label_fr": "Binyamina"
            },
            {
                "value": 44307,
                "label": "Mazliah",
                "label_ar": "Mazliah",
                "label_fr": "Mazliah"
            },
            {
                "value": 44242,
                "label": "Gan Yavne",
                "label_ar": "غان يفني",
                "label_fr": "Gan Yavne"
            },
            {
                "value": 44274,
                "label": "Kefar Yona",
                "label_ar": "كفار يونا",
                "label_fr": "Kefar Yona"
            },
            {
                "value": 44221,
                "label": "Bat Yam",
                "label_ar": "بات يام",
                "label_fr": "Bat Yam"
            },
            {
                "value": 44335,
                "label": "Ramat Yishay",
                "label_ar": "رمات يشاي",
                "label_fr": "Ramat Yishay"
            },
            {
                "value": 44256,
                "label": "Shelomi",
                "label_ar": "شلومي",
                "label_fr": "Shelomi"
            },
            {
                "value": 44263,
                "label": "Rinnatya",
                "label_ar": "ريناتيا",
                "label_fr": "Rinnatya"
            },
            {
                "value": 44323,
                "label": "Qiryat Mal'akhi",
                "label_ar": "قريات ملاخي",
                "label_fr": "Qiryat Mal'akhi"
            },
            {
                "value": 44293,
                "label": "Bat Hadar",
                "label_ar": "بات هادار",
                "label_fr": "Bat Hadar"
            },
            {
                "value": 44316,
                "label": "Qiryat Ata",
                "label_ar": "قريات عطا",
                "label_fr": "Qiryat Ata"
            },
            {
                "value": 44368,
                "label": "`Amir",
                "label_ar": "أمير",
                "label_fr": "ʻAmir"
            },
            {
                "value": 44349,
                "label": "Bene Ziyyon",
                "label_ar": "بني زيون",
                "label_fr": "Bene Ziyyon"
            },
            {
                "value": 44352,
                "label": "Dabburiya",
                "label_ar": "دبورية",
                "label_fr": "Dabburiya"
            },
            {
                "value": 44301,
                "label": "Modiin",
                "label_ar": "موديعين",
                "label_fr": "Modiin"
            },
            {
                "value": 44239,
                "label": "Karmi'el",
                "label_ar": "كرميئيل",
                "label_fr": "Karmi'el"
            },
            {
                "value": 44258,
                "label": "Liman",
                "label_ar": "ليمان",
                "label_fr": "Liman"
            },
            {
                "value": 44332,
                "label": "Tiberias",
                "label_ar": "طبريا",
                "label_fr": "Tibériade"
            },
            {
                "value": 44227,
                "label": "Rishon LeZion",
                "label_ar": "ريشون لتسيون",
                "label_fr": "Rishon LeZion"
            },
            {
                "value": 44350,
                "label": "Hurfeish",
                "label_ar": "حرفيش",
                "label_fr": "Hurfeish"
            },
            {
                "value": 44255,
                "label": "Qiryat Yam",
                "label_ar": "قريات يام",
                "label_fr": "Qiryat Yam"
            },
            {
                "value": 44311,
                "label": "Afiqim",
                "label_ar": "أفيقيم",
                "label_fr": "Afiqim"
            },
            {
                "value": 44286,
                "label": "Dimona",
                "label_ar": "ديمونة",
                "label_fr": "Dimona"
            },
            {
                "value": 44265,
                "label": "Acre",
                "label_ar": "فدان",
                "label_fr": "Acre"
            },
            {
                "value": 44336,
                "label": "Sderot",
                "label_ar": "سديروت",
                "label_fr": "Sderot"
            },
            {
                "value": 44292,
                "label": "Ra'anana",
                "label_ar": "رعنانا",
                "label_fr": "Raanana"
            },
            {
                "value": 44372,
                "label": "Kefar Witqin",
                "label_ar": "كفار وتقين",
                "label_fr": "Kefar Witqin"
            },
            {
                "value": 44260,
                "label": "Ramla",
                "label_ar": "الرملة",
                "label_fr": "Ramla"
            },
            {
                "value": 44288,
                "label": "`En HaShelosha",
                "label_ar": "أين هاشيلوشا",
                "label_fr": "ʻEn HaShelosha"
            },
            {
                "value": 44218,
                "label": "Kfar Saba",
                "label_ar": "كفار سابا",
                "label_fr": "Kfar Saba"
            },
            {
                "value": 44284,
                "label": "Safed",
                "label_ar": "صفد",
                "label_fr": "Safed"
            },
            {
                "value": 44220,
                "label": "Azor",
                "label_ar": "أزور",
                "label_fr": "Azor"
            },
            {
                "value": 44354,
                "label": "Mizpe Netofa",
                "label_ar": "ميزبي نتوفا",
                "label_fr": "Mizpe Netofa"
            },
            {
                "value": 44328,
                "label": "Migdal",
                "label_ar": "مجدال",
                "label_fr": "Migdal"
            },
            {
                "value": 44269,
                "label": "Nesher",
                "label_ar": "نيشر",
                "label_fr": "Nesher"
            },
            {
                "value": 44282,
                "label": "`En Ayyala",
                "label_ar": "ʻ عين عيالة",
                "label_fr": "ʻEn Ayyala"
            },
            {
                "value": 44313,
                "label": "Sde Warburg",
                "label_ar": "سديه واربورغ",
                "label_fr": "Sde Warburg"
            },
            {
                "value": 44358,
                "label": "Jaffa",
                "label_ar": "يافا",
                "label_fr": "Jaffa"
            },
            {
                "value": 44279,
                "label": "Qiryat Tiv`on",
                "label_ar": "كريات طبعون",
                "label_fr": "Qiryat Tivʻon"
            },
            {
                "value": 44223,
                "label": "Holon",
                "label_ar": "حولون",
                "label_fr": "Holon"
            },
            {
                "value": 44272,
                "label": "Tirat Karmel",
                "label_ar": "طيرة كرمل",
                "label_fr": "Tirat Karmel"
            },
            {
                "value": 44233,
                "label": "Nahariya",
                "label_ar": "نهاريا",
                "label_fr": "Nahariya"
            },
            {
                "value": 44315,
                "label": "Karkur",
                "label_ar": "كركور",
                "label_fr": "Karkur"
            },
            {
                "value": 44319,
                "label": "Timrat",
                "label_ar": "تيمرات",
                "label_fr": "Timrat"
            },
            {
                "value": 44287,
                "label": "Bet Shemesh",
                "label_ar": "بيت شيمش",
                "label_fr": "Bet Shemesh"
            },
            {
                "value": 44339,
                "label": "Pasuta",
                "label_ar": "باسوتا",
                "label_fr": "Pasuta"
            },
            {
                "value": 44369,
                "label": "Ganne Tiqwa",
                "label_ar": "جن تكفا",
                "label_fr": "Ganne Tiqwa"
            },
            {
                "value": 44351,
                "label": "Shefayim",
                "label_ar": "شفايم",
                "label_fr": "Shefayim"
            },
            {
                "value": 44298,
                "label": "Elyakhin",
                "label_ar": "الياخين",
                "label_fr": "Elyakhin"
            },
            {
                "value": 44270,
                "label": "Pardes Hanna Karkur",
                "label_ar": "برديس حنا كركور",
                "label_fr": "Pardes Hanna Karkur"
            },
            {
                "value": 44314,
                "label": "Magen",
                "label_ar": "ماجن",
                "label_fr": "Magen"
            },
            {
                "value": 44337,
                "label": "Gimzo",
                "label_ar": "جيمزو",
                "label_fr": "Gimzo"
            },
            {
                "value": 44331,
                "label": "Emunim",
                "label_ar": "ايمونيم",
                "label_fr": "Emunim"
            },
            {
                "value": 44253,
                "label": "Tel Mond",
                "label_ar": "تل موند",
                "label_fr": "Tel Mond"
            },
            {
                "value": 44330,
                "label": "Ben Shemen-Kefar Hano`ar",
                "label_ar": "بن شيمن كفار حنوكار",
                "label_fr": "Ben Shemen-Kefar Hanoʻar"
            },
            {
                "value": 44248,
                "label": "`Arugot",
                "label_ar": "ʻ Arugot",
                "label_fr": "ʻArugot"
            },
            {
                "value": 44344,
                "label": "Gibbeton",
                "label_ar": "جيبتون",
                "label_fr": "Gibbeton"
            },
            {
                "value": 44280,
                "label": "Qiryat Gat",
                "label_ar": "كريات جات",
                "label_fr": "Qiryat Gat"
            },
            {
                "value": 44300,
                "label": "Hadar Ramatayim",
                "label_ar": "حضر رماتايم",
                "label_fr": "Hadar Ramatayim"
            },
            {
                "value": 44324,
                "label": "Hadar `Am",
                "label_ar": "هاد عم",
                "label_fr": "Hadar ʻAm"
            },
            {
                "value": 44245,
                "label": "Savyon",
                "label_ar": "سافيون",
                "label_fr": "Savyon"
            },
            {
                "value": 44364,
                "label": "Urim",
                "label_ar": "اوريم",
                "label_fr": "Urim"
            },
            {
                "value": 44251,
                "label": "Hadera",
                "label_ar": "الخضيرة",
                "label_fr": "Hadera"
            },
            {
                "value": 44345,
                "label": "Qiryat Ono",
                "label_ar": "قريات اونو",
                "label_fr": "Qiryat Ono"
            },
            {
                "value": 44219,
                "label": "Or Yehuda",
                "label_ar": "أو يهودا",
                "label_fr": "Ou Yehuda"
            },
            {
                "value": 44281,
                "label": "Be'er Toviyya",
                "label_ar": "بئر توفية",
                "label_fr": "Be'er Toviyya"
            },
            {
                "value": 44367,
                "label": "Nirim",
                "label_ar": "نيريم",
                "label_fr": "Nirim"
            },
            {
                "value": 44289,
                "label": "Herut",
                "label_ar": "حيروت",
                "label_fr": "Herut"
            },
            {
                "value": 44357,
                "label": "Bahan",
                "label_ar": "باهان",
                "label_fr": "Bahan"
            },
            {
                "value": 44229,
                "label": "Ness Ziona",
                "label_ar": "نيس زيونا",
                "label_fr": "Ness Ziona"
            },
            {
                "value": 44277,
                "label": "Biriyya",
                "label_ar": "برييا",
                "label_fr": "Biriyya"
            },
            {
                "value": 44305,
                "label": "Talme Menashe",
                "label_ar": "تلمي منشيه",
                "label_fr": "Talme Menashe"
            },
            {
                "value": 44342,
                "label": "Nordiyya",
                "label_ar": "نوردية",
                "label_fr": "Nordiyya"
            },
            {
                "value": 44340,
                "label": "Bet She'an",
                "label_ar": "الرهان شيعان",
                "label_fr": "Je parie que She'an"
            },
            {
                "value": 44333,
                "label": "Mikhmoret",
                "label_ar": "مخمورة",
                "label_fr": "Mikhmoret"
            },
            {
                "value": 44341,
                "label": "Newe Yamin",
                "label_ar": "نيوي يامين",
                "label_fr": "Newe Yamin"
            },
            {
                "value": 44362,
                "label": "Ramot Naftali",
                "label_ar": "راموت نفتالي",
                "label_fr": "Ramot Naftali"
            },
            {
                "value": 44225,
                "label": "Beersheba",
                "label_ar": "بئر السبع",
                "label_fr": "Beersheba"
            },
            {
                "value": 44370,
                "label": "Ma`agan Mikha'el",
                "label_ar": "معجان ميخائيل",
                "label_fr": "Maʻagan Mikha'el"
            },
            {
                "value": 44327,
                "label": "Qiryat Motzkin",
                "label_ar": "كريات موتسكين",
                "label_fr": "Qiryat Motzkin"
            },
            {
                "value": 44237,
                "label": "Ramat Poleg",
                "label_ar": "رمات بوليج",
                "label_fr": "Ramat Poleg"
            },
            {
                "value": 44365,
                "label": "Sedot Yam",
                "label_ar": "سيدوت يام",
                "label_fr": "Sedot Yam"
            },
            {
                "value": 44325,
                "label": "maalot Tarshiha",
                "label_ar": "معلوت ترشيحا",
                "label_fr": "maalot Tarshiha"
            },
            {
                "value": 44228,
                "label": "Hazav",
                "label_ar": "حازاف",
                "label_fr": "Hazav"
            },
            {
                "value": 44268,
                "label": "Even Yehuda",
                "label_ar": "حتى يهودا",
                "label_fr": "Même Yehuda"
            },
            {
                "value": 44366,
                "label": "`Aseret",
                "label_ar": "ʻأسيريت",
                "label_fr": "ʻAseret"
            },
            {
                "value": 44318,
                "label": "Bet Alfa",
                "label_ar": "رهان الفا",
                "label_fr": "Bet Alfa"
            },
            {
                "value": 44238,
                "label": "Kadima",
                "label_ar": "كديما",
                "label_fr": "Kadima"
            },
            {
                "value": 44222,
                "label": "Ramat Gan",
                "label_ar": "رمات جان",
                "label_fr": "Ramat Gan"
            },
            {
                "value": 44215,
                "label": "Lod",
                "label_ar": "اللد",
                "label_fr": "Lod"
            },
            {
                "value": 44297,
                "label": "Bareqet",
                "label_ar": "بركات",
                "label_fr": "Bareqet"
            },
            {
                "value": 44271,
                "label": "Netivot",
                "label_ar": "نتيفوت",
                "label_fr": "Netivot"
            }
        ]
    },
    {
        "country": "Sweden",
        "cities": [
            {
                "value": 44787,
                "label": "Haesselby",
                "label_ar": "هيسيلبي",
                "label_fr": "Haesselby"
            },
            {
                "value": 45434,
                "label": "Stugun",
                "label_ar": "ستوجون",
                "label_fr": "Stugun"
            },
            {
                "value": 44394,
                "label": "Norrköping",
                "label_ar": "نورشوبينغ",
                "label_fr": "Norrköping"
            },
            {
                "value": 45051,
                "label": "Norrsundet",
                "label_ar": "نورسونديت",
                "label_fr": "Norrsundet"
            },
            {
                "value": 44932,
                "label": "Halleforsnas",
                "label_ar": "هاليفورسناس",
                "label_fr": "Halleforsnas"
            },
            {
                "value": 44589,
                "label": "Knivsta",
                "label_ar": "كنيفستا",
                "label_fr": "Knivsta"
            },
            {
                "value": 44439,
                "label": "Mora",
                "label_ar": "مورا",
                "label_fr": "Mora"
            },
            {
                "value": 45266,
                "label": "Ljungaverk",
                "label_ar": "Ljungaverk",
                "label_fr": "Ljungaverk"
            },
            {
                "value": 45600,
                "label": "Koppom",
                "label_ar": "كوبوم",
                "label_fr": "Koppom"
            },
            {
                "value": 44961,
                "label": "Vintrosa",
                "label_ar": "فينتروسا",
                "label_fr": "Vintrosa"
            },
            {
                "value": 44512,
                "label": "Tranas",
                "label_ar": "تراناس",
                "label_fr": "Tranas"
            },
            {
                "value": 45529,
                "label": "Anderslov",
                "label_ar": "أندرسلوف",
                "label_fr": "Anderslov"
            },
            {
                "value": 44411,
                "label": "Nynashamn",
                "label_ar": "نينسهامن",
                "label_fr": "Nynashamn"
            },
            {
                "value": 45029,
                "label": "Kalix",
                "label_ar": "كاليكس",
                "label_fr": "Kalix"
            },
            {
                "value": 44697,
                "label": "Navlinge",
                "label_ar": "نافلينج",
                "label_fr": "Navlinge"
            },
            {
                "value": 44627,
                "label": "Torshalla",
                "label_ar": "تورشالا",
                "label_fr": "Torshalla"
            },
            {
                "value": 44862,
                "label": "Fjardhundra",
                "label_ar": "فياردهوندرا",
                "label_fr": "Fjardhundra"
            },
            {
                "value": 45343,
                "label": "Varsas",
                "label_ar": "فارساس",
                "label_fr": "Varsas"
            },
            {
                "value": 45473,
                "label": "Malma",
                "label_ar": "مالما",
                "label_fr": "Malma"
            },
            {
                "value": 45476,
                "label": "Hasslarp",
                "label_ar": "هاسلارب",
                "label_fr": "Hasslarp"
            },
            {
                "value": 45181,
                "label": "Ostra",
                "label_ar": "أوسترا",
                "label_fr": "Ostra"
            },
            {
                "value": 44629,
                "label": "Dalaro",
                "label_ar": "دالارو",
                "label_fr": "Dalaro"
            },
            {
                "value": 44683,
                "label": "Limhamn",
                "label_ar": "ليمهامن",
                "label_fr": "Limhamn"
            },
            {
                "value": 45228,
                "label": "Stora Mellosa",
                "label_ar": "ستورا ميلوزا",
                "label_fr": "Stora Mellosa"
            },
            {
                "value": 45382,
                "label": "Myresjo",
                "label_ar": "ميريسجو",
                "label_fr": "Myresjo"
            },
            {
                "value": 45317,
                "label": "Dosjebro",
                "label_ar": "دوسجبرو",
                "label_fr": "Dosjebro"
            },
            {
                "value": 44377,
                "label": "Skaerholmen",
                "label_ar": "سكيرهولمن",
                "label_fr": "Skaerholmen"
            },
            {
                "value": 45603,
                "label": "Hallestad",
                "label_ar": "هالستاد",
                "label_fr": "Hallestad"
            },
            {
                "value": 45393,
                "label": "Korpilombolo",
                "label_ar": "كوربيلومبولو",
                "label_fr": "Korpilombolo"
            },
            {
                "value": 45412,
                "label": "Almeboda",
                "label_ar": "المبودة",
                "label_fr": "Almeboda"
            },
            {
                "value": 45300,
                "label": "Jarlasa",
                "label_ar": "جارلاسا",
                "label_fr": "Jarlasa"
            },
            {
                "value": 44527,
                "label": "Graddo",
                "label_ar": "جراددو",
                "label_fr": "Graddo"
            },
            {
                "value": 45039,
                "label": "Hede",
                "label_ar": "هيدي",
                "label_fr": "Hede"
            },
            {
                "value": 45141,
                "label": "Emmaboda",
                "label_ar": "Emmaboda",
                "label_fr": "Emmaboda"
            },
            {
                "value": 45619,
                "label": "Sunnansjo",
                "label_ar": "سنانسجو",
                "label_fr": "Sunnansjo"
            },
            {
                "value": 45334,
                "label": "Harads",
                "label_ar": "حراد",
                "label_fr": "Harads"
            },
            {
                "value": 45079,
                "label": "Dorotea",
                "label_ar": "دوروتيا",
                "label_fr": "Dorotea"
            },
            {
                "value": 45438,
                "label": "Horn",
                "label_ar": "بوق",
                "label_fr": "klaxon"
            },
            {
                "value": 45540,
                "label": "Bua",
                "label_ar": "بوا",
                "label_fr": "Bua"
            },
            {
                "value": 44416,
                "label": "Flen",
                "label_ar": "فلن",
                "label_fr": "Flen"
            },
            {
                "value": 45325,
                "label": "Pilgrimstad",
                "label_ar": "بيلجريمستاد",
                "label_fr": "Pilgrimstad"
            },
            {
                "value": 44727,
                "label": "Ransater",
                "label_ar": "Ransater",
                "label_fr": "Ransater"
            },
            {
                "value": 45111,
                "label": "Bramhult",
                "label_ar": "برامهولت",
                "label_fr": "Bramhult"
            },
            {
                "value": 44688,
                "label": "Morarp",
                "label_ar": "مورارب",
                "label_fr": "Morarp"
            },
            {
                "value": 44980,
                "label": "Malmberget",
                "label_ar": "مالمبيرجيت",
                "label_fr": "Malmberget"
            },
            {
                "value": 44761,
                "label": "Munkfors",
                "label_ar": "مونكفورس",
                "label_fr": "Munkfors"
            },
            {
                "value": 44455,
                "label": "Varberg",
                "label_ar": "فاربرغ",
                "label_fr": "Varberg"
            },
            {
                "value": 44739,
                "label": "Gimo",
                "label_ar": "جيمو",
                "label_fr": "Gimo"
            },
            {
                "value": 45252,
                "label": "Styrso",
                "label_ar": "ستيرسو",
                "label_fr": "Styrso"
            },
            {
                "value": 44960,
                "label": "Storfors",
                "label_ar": "ستورفورز",
                "label_fr": "Storfors"
            },
            {
                "value": 44556,
                "label": "Lonashult",
                "label_ar": "لوناشولت",
                "label_fr": "Lonashult"
            },
            {
                "value": 44572,
                "label": "Nodinge",
                "label_ar": "الإيماء",
                "label_fr": "Hochement de tête"
            },
            {
                "value": 44526,
                "label": "Skarblacka",
                "label_ar": "سكاربلاكا",
                "label_fr": "Skarblacka"
            },
            {
                "value": 45460,
                "label": "Stora Vika",
                "label_ar": "ستورا فيكا",
                "label_fr": "Stora Vika"
            },
            {
                "value": 45166,
                "label": "Vasterljung",
                "label_ar": "فاسترلونج",
                "label_fr": "Vasterljung"
            },
            {
                "value": 44460,
                "label": "Linköping",
                "label_ar": "لينشوبينغ",
                "label_fr": "Linköping"
            },
            {
                "value": 45159,
                "label": "Bjorkvik",
                "label_ar": "بجوركفيك",
                "label_fr": "Bjorkvik"
            },
            {
                "value": 45419,
                "label": "Pixbo",
                "label_ar": "بيكسبو",
                "label_fr": "Pixbo"
            },
            {
                "value": 45461,
                "label": "Bergkvara",
                "label_ar": "بيرجكفارا",
                "label_fr": "Bergkvara"
            },
            {
                "value": 44602,
                "label": "Lysekil",
                "label_ar": "ليسكيل",
                "label_fr": "Lysekil"
            },
            {
                "value": 44427,
                "label": "Örebro",
                "label_ar": "أوريبرو",
                "label_fr": "Örebro"
            },
            {
                "value": 44424,
                "label": "Aled",
                "label_ar": "العيد",
                "label_fr": "Aled"
            },
            {
                "value": 44668,
                "label": "Åhus",
                "label_ar": "Åه",
                "label_fr": "Åhus"
            },
            {
                "value": 45097,
                "label": "Valberg",
                "label_ar": "فالبرج",
                "label_fr": "Valberg"
            },
            {
                "value": 44847,
                "label": "Tenhult",
                "label_ar": "تينهولت",
                "label_fr": "Tenhult"
            },
            {
                "value": 45225,
                "label": "Skruv",
                "label_ar": "سكروف",
                "label_fr": "Skruv"
            },
            {
                "value": 44972,
                "label": "Hoernefors",
                "label_ar": "هورنفورز",
                "label_fr": "Hoernefors"
            },
            {
                "value": 45246,
                "label": "AElmhult",
                "label_ar": "ألمهولت",
                "label_fr": "AElmhult"
            },
            {
                "value": 44483,
                "label": "Visby",
                "label_ar": "فيسبي",
                "label_fr": "Visby"
            },
            {
                "value": 44608,
                "label": "Tegeltorp",
                "label_ar": "تيجلتورب",
                "label_fr": "Tegeltorp"
            },
            {
                "value": 45351,
                "label": "Svensbyn",
                "label_ar": "سفينسبين",
                "label_fr": "Svensbyn"
            },
            {
                "value": 44869,
                "label": "Eldsberga",
                "label_ar": "إلدسبيرجا",
                "label_fr": "Eldsberga"
            },
            {
                "value": 44422,
                "label": "Gabo",
                "label_ar": "جابو",
                "label_fr": "Gabo"
            },
            {
                "value": 44754,
                "label": "Harplinge",
                "label_ar": "هاربلينج",
                "label_fr": "Harplinge"
            },
            {
                "value": 44503,
                "label": "Luleå",
                "label_ar": "لوليا",
                "label_fr": "Luleå"
            },
            {
                "value": 44680,
                "label": "Sibbhult",
                "label_ar": "سبهولت",
                "label_fr": "Sibbhult"
            },
            {
                "value": 44637,
                "label": "Hoganas",
                "label_ar": "هوجاناس",
                "label_fr": "Hoganas"
            },
            {
                "value": 45229,
                "label": "Malilla",
                "label_ar": "مليلة",
                "label_fr": "Malilla"
            },
            {
                "value": 45406,
                "label": "Halta",
                "label_ar": "هالتا",
                "label_fr": "Halta"
            },
            {
                "value": 45424,
                "label": "Kulla",
                "label_ar": "كولا",
                "label_fr": "Kulla"
            },
            {
                "value": 44790,
                "label": "Bjorkoby",
                "label_ar": "بجوركوبي",
                "label_fr": "Bjorkoby"
            },
            {
                "value": 44622,
                "label": "Morgongava",
                "label_ar": "مورغونغافا",
                "label_fr": "Morgongava"
            },
            {
                "value": 45335,
                "label": "Stode",
                "label_ar": "وقف",
                "label_fr": "Stode"
            },
            {
                "value": 44973,
                "label": "Saevar",
                "label_ar": "سيفار",
                "label_fr": "Saevar"
            },
            {
                "value": 44616,
                "label": "Herrljunga",
                "label_ar": "هيرلجونجا",
                "label_fr": "Herrljunga"
            },
            {
                "value": 44923,
                "label": "Stravalla",
                "label_ar": "سترافالا",
                "label_fr": "Stravalla"
            },
            {
                "value": 45264,
                "label": "Ange",
                "label_ar": "أنجي",
                "label_fr": "Ange"
            },
            {
                "value": 45172,
                "label": "Bjorneborg",
                "label_ar": "بجورنيبورغ",
                "label_fr": "Bjorneborg"
            },
            {
                "value": 44499,
                "label": "Munkedal",
                "label_ar": "منكدال",
                "label_fr": "Munkedal"
            },
            {
                "value": 45570,
                "label": "Rinkaby",
                "label_ar": "رينكابي",
                "label_fr": "Rinkaby"
            },
            {
                "value": 44567,
                "label": "Ojersjo",
                "label_ar": "أوجيرسجو",
                "label_fr": "Ojersjo"
            },
            {
                "value": 44926,
                "label": "Sunne",
                "label_ar": "سون",
                "label_fr": "Sunne"
            },
            {
                "value": 44511,
                "label": "Bara",
                "label_ar": "بارا",
                "label_fr": "Bara"
            },
            {
                "value": 44781,
                "label": "Härnösand",
                "label_ar": "هارنوساند",
                "label_fr": "Härnösand"
            },
            {
                "value": 44765,
                "label": "Hudiksvall",
                "label_ar": "هوديكسفال",
                "label_fr": "Hudiksvall"
            },
            {
                "value": 45327,
                "label": "Soderfors",
                "label_ar": "سودرفورس",
                "label_fr": "Soderfors"
            },
            {
                "value": 45091,
                "label": "Brakne-Hoby",
                "label_ar": "براكن هوبي",
                "label_fr": "Brakne-Hoby"
            },
            {
                "value": 44709,
                "label": "Haljarp",
                "label_ar": "الهالجرة",
                "label_fr": "Haljarp"
            },
            {
                "value": 44864,
                "label": "Gusum",
                "label_ar": "جوسوم",
                "label_fr": "Gusum"
            },
            {
                "value": 45263,
                "label": "Frovi",
                "label_ar": "فروفى",
                "label_fr": "Frovi"
            },
            {
                "value": 44517,
                "label": "Jönköping",
                "label_ar": "يونشوبينغ",
                "label_fr": "Jönköping"
            },
            {
                "value": 44805,
                "label": "Kungsaengen",
                "label_ar": "Kungsaengen",
                "label_fr": "Kungsaengen"
            },
            {
                "value": 44539,
                "label": "Gualov",
                "label_ar": "جوالوف",
                "label_fr": "Gualov"
            },
            {
                "value": 44773,
                "label": "Hebo",
                "label_ar": "هيبو",
                "label_fr": "Hebo"
            },
            {
                "value": 45610,
                "label": "Varnhem",
                "label_ar": "فارنم",
                "label_fr": "Varnhem"
            },
            {
                "value": 45345,
                "label": "Sundsbruk",
                "label_ar": "سوندسبروك",
                "label_fr": "Sundsbruk"
            },
            {
                "value": 44530,
                "label": "Ludvika",
                "label_ar": "لودفيكا",
                "label_fr": "Ludvika"
            },
            {
                "value": 45134,
                "label": "Hok",
                "label_ar": "هوك",
                "label_fr": "Hok"
            },
            {
                "value": 45069,
                "label": "Garpenberg",
                "label_ar": "جاربينبيرج",
                "label_fr": "Garpenberg"
            },
            {
                "value": 45475,
                "label": "Berga",
                "label_ar": "بيرجا",
                "label_fr": "Berga"
            },
            {
                "value": 44476,
                "label": "Varnamo",
                "label_ar": "فارنامو",
                "label_fr": "Varnamo"
            },
            {
                "value": 45511,
                "label": "Ovanaker",
                "label_ar": "أوفاناكر",
                "label_fr": "Ovanaker"
            },
            {
                "value": 45546,
                "label": "Storlien",
                "label_ar": "ستورلين",
                "label_fr": "Storlien"
            },
            {
                "value": 44706,
                "label": "Olofstroem",
                "label_ar": "أولوفستروم",
                "label_fr": "Olofstroem"
            },
            {
                "value": 44485,
                "label": "Ronninge",
                "label_ar": "رونينج",
                "label_fr": "Ronninge"
            },
            {
                "value": 44418,
                "label": "Moelndal",
                "label_ar": "مولندال",
                "label_fr": "Moelndal"
            },
            {
                "value": 44873,
                "label": "Grillby",
                "label_ar": "جريلبي",
                "label_fr": "Grillby"
            },
            {
                "value": 44402,
                "label": "Storvreta",
                "label_ar": "ستورفريتا",
                "label_fr": "Storvreta"
            },
            {
                "value": 44920,
                "label": "Alandsbro",
                "label_ar": "Alandsbro",
                "label_fr": "Alandsbro"
            },
            {
                "value": 44825,
                "label": "Kramfors",
                "label_ar": "كرامفورس",
                "label_fr": "Kramfors"
            },
            {
                "value": 44795,
                "label": "Nassjo",
                "label_ar": "ناسجو",
                "label_fr": "Nassjo"
            },
            {
                "value": 44666,
                "label": "Malsryd",
                "label_ar": "مالسريد",
                "label_fr": "Malsryd"
            },
            {
                "value": 44504,
                "label": "Östersund",
                "label_ar": "اوسترشوند",
                "label_fr": "Östersund"
            },
            {
                "value": 45371,
                "label": "Stehag",
                "label_ar": "ستهاغ",
                "label_fr": "Stehag"
            },
            {
                "value": 44536,
                "label": "Tollarp",
                "label_ar": "تولارب",
                "label_fr": "Tollarp"
            },
            {
                "value": 44432,
                "label": "Hjo",
                "label_ar": "هجو",
                "label_fr": "Hjo"
            },
            {
                "value": 44768,
                "label": "Mariefred",
                "label_ar": "ماريفريد",
                "label_fr": "Mariefred"
            },
            {
                "value": 45220,
                "label": "AElvsbyn",
                "label_ar": "ألفسبين",
                "label_fr": "AElvsbyn"
            },
            {
                "value": 44879,
                "label": "Kimstad",
                "label_ar": "كيمستاد",
                "label_fr": "Kimstad"
            },
            {
                "value": 45313,
                "label": "Sorombacken",
                "label_ar": "سورومباكن",
                "label_fr": "Sorombacken"
            },
            {
                "value": 44785,
                "label": "Svartinge",
                "label_ar": "سفارتينجي",
                "label_fr": "Svartinge"
            },
            {
                "value": 45096,
                "label": "Arjaeng",
                "label_ar": "أرجينج",
                "label_fr": "Arjaeng"
            },
            {
                "value": 44437,
                "label": "Billesholm",
                "label_ar": "بيليشولم",
                "label_fr": "Billesholm"
            },
            {
                "value": 44443,
                "label": "Skepplanda",
                "label_ar": "Skepplanda",
                "label_fr": "Skepplanda"
            },
            {
                "value": 44911,
                "label": "Fellingsbro",
                "label_ar": "فيلينجسبرو",
                "label_fr": "Fellingsbro"
            },
            {
                "value": 44726,
                "label": "Bergshamra",
                "label_ar": "برجشامرا",
                "label_fr": "Bergshamra"
            },
            {
                "value": 45118,
                "label": "Rejmyre",
                "label_ar": "ريجمير",
                "label_fr": "Rejmyre"
            },
            {
                "value": 45429,
                "label": "Glostorp",
                "label_ar": "جلوستورب",
                "label_fr": "Glostorp"
            },
            {
                "value": 45597,
                "label": "Tjornarp",
                "label_ar": "تجورنارب",
                "label_fr": "Tjornarp"
            },
            {
                "value": 44687,
                "label": "Klagstorp",
                "label_ar": "كلاجستورب",
                "label_fr": "Klagstorp"
            },
            {
                "value": 45066,
                "label": "Vilhelmina",
                "label_ar": "فيلهلمينا",
                "label_fr": "Vilhelmina"
            },
            {
                "value": 45061,
                "label": "Ekenassjon",
                "label_ar": "إكيناسجون",
                "label_fr": "Ekenassjon"
            },
            {
                "value": 44431,
                "label": "Enkoping",
                "label_ar": "Enkoping",
                "label_fr": "Enkoping"
            },
            {
                "value": 44778,
                "label": "Billdal",
                "label_ar": "بيلدال",
                "label_fr": "Billdal"
            },
            {
                "value": 45333,
                "label": "Skollersta",
                "label_ar": "سكولرستا",
                "label_fr": "Skollersta"
            },
            {
                "value": 45174,
                "label": "Stanga",
                "label_ar": "ستانغا",
                "label_fr": "Stanga"
            },
            {
                "value": 44771,
                "label": "Varekil",
                "label_ar": "فاريكيل",
                "label_fr": "Varekil"
            },
            {
                "value": 44814,
                "label": "Dalstorp",
                "label_ar": "دالستورب",
                "label_fr": "Dalstorp"
            },
            {
                "value": 45493,
                "label": "Vackelsang",
                "label_ar": "فاكيلسانغ",
                "label_fr": "Vackelsang"
            },
            {
                "value": 44880,
                "label": "Stra",
                "label_ar": "سترا",
                "label_fr": "Stra"
            },
            {
                "value": 44423,
                "label": "Savedalen",
                "label_ar": "Savedalen",
                "label_fr": "Savedalen"
            },
            {
                "value": 44959,
                "label": "Grythyttan",
                "label_ar": "جريثيتان",
                "label_fr": "Grythyttan"
            },
            {
                "value": 44798,
                "label": "Finspang",
                "label_ar": "Finspang",
                "label_fr": "Finspang"
            },
            {
                "value": 44863,
                "label": "Amal",
                "label_ar": "أمل",
                "label_fr": "Amal"
            },
            {
                "value": 44672,
                "label": "Paarp",
                "label_ar": "بارب",
                "label_fr": "Paarp"
            },
            {
                "value": 45503,
                "label": "Loderup",
                "label_ar": "لودروب",
                "label_fr": "Loderup"
            },
            {
                "value": 44396,
                "label": "Vaermdoe",
                "label_ar": "Vaermdoe",
                "label_fr": "Vaermdoe"
            },
            {
                "value": 44742,
                "label": "Ekshaerad",
                "label_ar": "اكشيراد",
                "label_fr": "Ekshaerad"
            },
            {
                "value": 45222,
                "label": "Vikmanshyttan",
                "label_ar": "فيكمانشيتان",
                "label_fr": "Vikmanshyttan"
            },
            {
                "value": 44496,
                "label": "Hallevadsholm",
                "label_ar": "هاليفادشولم",
                "label_fr": "Hallevadsholm"
            },
            {
                "value": 44942,
                "label": "Skee",
                "label_ar": "سكي",
                "label_fr": "Skee"
            },
            {
                "value": 44870,
                "label": "Skummeslovsstrand",
                "label_ar": "Skummeslovsstrand",
                "label_fr": "Skummeslovsstrand"
            },
            {
                "value": 45020,
                "label": "Eksjoe",
                "label_ar": "Eksjoe",
                "label_fr": "Eksjoe"
            },
            {
                "value": 45577,
                "label": "Forsheda",
                "label_ar": "فورشيدا",
                "label_fr": "Forsheda"
            },
            {
                "value": 45561,
                "label": "Traslovslage",
                "label_ar": "تراسلوفسلج",
                "label_fr": "Traslovslage"
            },
            {
                "value": 45257,
                "label": "Slutarp",
                "label_ar": "سلوتارب",
                "label_fr": "Salope"
            },
            {
                "value": 44596,
                "label": "Vingaker",
                "label_ar": "فينجكر",
                "label_fr": "Vingaker"
            },
            {
                "value": 45291,
                "label": "Duved",
                "label_ar": "دوفيد",
                "label_fr": "Couché"
            },
            {
                "value": 45276,
                "label": "Orrefors",
                "label_ar": "Orrefors",
                "label_fr": "Orrefors"
            },
            {
                "value": 45260,
                "label": "Vattholma",
                "label_ar": "فاتولما",
                "label_fr": "Vattholma"
            },
            {
                "value": 45070,
                "label": "Ska",
                "label_ar": "سكا",
                "label_fr": "Ska"
            },
            {
                "value": 44953,
                "label": "Ballingslov",
                "label_ar": "بالينجسلوف",
                "label_fr": "Ballingslov"
            },
            {
                "value": 44893,
                "label": "Svenstavik",
                "label_ar": "سفينستافيك",
                "label_fr": "Svenstavik"
            },
            {
                "value": 44587,
                "label": "Edsbro",
                "label_ar": "إدسبرو",
                "label_fr": "Edsbro"
            },
            {
                "value": 44417,
                "label": "Kungsbacka",
                "label_ar": "Kungsbacka",
                "label_fr": "Kungsbacka"
            },
            {
                "value": 44425,
                "label": "Eskilstuna",
                "label_ar": "اسكيلستونا",
                "label_fr": "Eskilstuna"
            },
            {
                "value": 44733,
                "label": "Sennan",
                "label_ar": "سنان",
                "label_fr": "Sennan"
            },
            {
                "value": 44818,
                "label": "Svanesund",
                "label_ar": "سفانسوند",
                "label_fr": "Svanesund"
            },
            {
                "value": 44448,
                "label": "Tierp",
                "label_ar": "تيرب",
                "label_fr": "Tierp"
            },
            {
                "value": 44597,
                "label": "Sandviken",
                "label_ar": "ساندفيكن",
                "label_fr": "Sandviken"
            },
            {
                "value": 44729,
                "label": "Kungshamn",
                "label_ar": "كونغشامن",
                "label_fr": "Kungshamn"
            },
            {
                "value": 44645,
                "label": "Mardaklev",
                "label_ar": "مارداكليف",
                "label_fr": "Mardaklev"
            },
            {
                "value": 45241,
                "label": "Hamburgsund",
                "label_ar": "هامبورغسوند",
                "label_fr": "Hamburgsund"
            },
            {
                "value": 44848,
                "label": "Lomma",
                "label_ar": "لوما",
                "label_fr": "Lomma"
            },
            {
                "value": 45459,
                "label": "Glumslov",
                "label_ar": "غلامسلوف",
                "label_fr": "Glumslov"
            },
            {
                "value": 44612,
                "label": "Hedemora",
                "label_ar": "هيديمورا",
                "label_fr": "Hedemora"
            },
            {
                "value": 44908,
                "label": "Charlottenberg",
                "label_ar": "شارلوتينبيرج",
                "label_fr": "Charlottenberg"
            },
            {
                "value": 44555,
                "label": "Vargarda",
                "label_ar": "فارغاردا",
                "label_fr": "Vargarda"
            },
            {
                "value": 45211,
                "label": "Uppharad",
                "label_ar": "أوبراد",
                "label_fr": "Uppharad"
            },
            {
                "value": 44741,
                "label": "Uddeholm",
                "label_ar": "أوديهولم",
                "label_fr": "Uddeholm"
            },
            {
                "value": 45288,
                "label": "Overkovland",
                "label_ar": "أوفركوفلاند",
                "label_fr": "Overkovland"
            },
            {
                "value": 45105,
                "label": "Finnerodja",
                "label_ar": "فينيرودجا",
                "label_fr": "Finnerodja"
            },
            {
                "value": 44481,
                "label": "Reso",
                "label_ar": "ريسو",
                "label_fr": "Reso"
            },
            {
                "value": 45279,
                "label": "Linghem",
                "label_ar": "Linghem",
                "label_fr": "Linghem"
            },
            {
                "value": 45498,
                "label": "Vrena",
                "label_ar": "فرينا",
                "label_fr": "Vrena"
            },
            {
                "value": 44965,
                "label": "Alfta",
                "label_ar": "ألفتا",
                "label_fr": "Alfta"
            },
            {
                "value": 45032,
                "label": "Tungelsta",
                "label_ar": "Tungelsta",
                "label_fr": "Tungelsta"
            },
            {
                "value": 45002,
                "label": "Enviken",
                "label_ar": "إنفيكن",
                "label_fr": "Enviken"
            },
            {
                "value": 45060,
                "label": "Hoegsby",
                "label_ar": "هوجسبي",
                "label_fr": "Hoegsby"
            },
            {
                "value": 45123,
                "label": "Alem",
                "label_ar": "عالم",
                "label_fr": "Alem"
            },
            {
                "value": 44478,
                "label": "Västerås",
                "label_ar": "فاستيراس",
                "label_fr": "Västerås"
            },
            {
                "value": 44444,
                "label": "Nyköping",
                "label_ar": "نيكوبينغ",
                "label_fr": "Nyköping"
            },
            {
                "value": 45481,
                "label": "Osterbybruk",
                "label_ar": "Osterbybruk",
                "label_fr": "Osterbybruk"
            },
            {
                "value": 44830,
                "label": "Bygdea",
                "label_ar": "Bygdea",
                "label_fr": "Bygdea"
            },
            {
                "value": 44376,
                "label": "Handen",
                "label_ar": "هاندن",
                "label_fr": "Handen"
            },
            {
                "value": 45273,
                "label": "Zinkgruvan",
                "label_ar": "Zinkgruvan",
                "label_fr": "Zinkgruvan"
            },
            {
                "value": 44545,
                "label": "Nykvarn",
                "label_ar": "نيكفارن",
                "label_fr": "Nykvarn"
            },
            {
                "value": 45094,
                "label": "Gustavsberg",
                "label_ar": "جوستافسبيرغ",
                "label_fr": "Gustavsberg"
            },
            {
                "value": 45604,
                "label": "Ring",
                "label_ar": "حلقة",
                "label_fr": "Bague"
            },
            {
                "value": 44447,
                "label": "Saro",
                "label_ar": "سارو",
                "label_fr": "Saro"
            },
            {
                "value": 45565,
                "label": "Lillpite",
                "label_ar": "ليلبيتي",
                "label_fr": "Lillpite"
            },
            {
                "value": 45248,
                "label": "Rosson",
                "label_ar": "روسون",
                "label_fr": "Rosson"
            },
            {
                "value": 45353,
                "label": "Lindas",
                "label_ar": "لينداس",
                "label_fr": "Lindas"
            },
            {
                "value": 44823,
                "label": "Kyrkobyn",
                "label_ar": "كيركوبين",
                "label_fr": "Kyrkobyn"
            },
            {
                "value": 44738,
                "label": "Torslanda",
                "label_ar": "تورسلاندا",
                "label_fr": "Torslanda"
            },
            {
                "value": 45203,
                "label": "Rangedala",
                "label_ar": "رانجدالا",
                "label_fr": "Rangedala"
            },
            {
                "value": 44457,
                "label": "Slottsbron",
                "label_ar": "Slottsbron",
                "label_fr": "Slottsbron"
            },
            {
                "value": 44579,
                "label": "Aseda",
                "label_ar": "أسيدا",
                "label_fr": "Aseda"
            },
            {
                "value": 44907,
                "label": "Klassbol",
                "label_ar": "كلاسبول",
                "label_fr": "Klassbol"
            },
            {
                "value": 45022,
                "label": "Farnas",
                "label_ar": "فرناس",
                "label_fr": "Farnas"
            },
            {
                "value": 45265,
                "label": "Fransta",
                "label_ar": "فرانستا",
                "label_fr": "Fransta"
            },
            {
                "value": 44746,
                "label": "Tidaholm",
                "label_ar": "تيداهولم",
                "label_fr": "Tidaholm"
            },
            {
                "value": 44756,
                "label": "Bollnas",
                "label_ar": "بولناس",
                "label_fr": "Bollnas"
            },
            {
                "value": 45156,
                "label": "Eriksmala",
                "label_ar": "إريكسمالا",
                "label_fr": "Eriksmala"
            },
            {
                "value": 44849,
                "label": "Vissefjarda",
                "label_ar": "فيسفجاردا",
                "label_fr": "Vissefjarda"
            },
            {
                "value": 44657,
                "label": "Jonsered",
                "label_ar": "جونسرد",
                "label_fr": "Jonsered"
            },
            {
                "value": 45160,
                "label": "Bettna",
                "label_ar": "بتنا",
                "label_fr": "Bettna"
            },
            {
                "value": 44772,
                "label": "Liden",
                "label_ar": "ليدن",
                "label_fr": "Liden"
            },
            {
                "value": 44844,
                "label": "Tomelilla",
                "label_ar": "تومليلا",
                "label_fr": "Tomelilla"
            },
            {
                "value": 45072,
                "label": "Skondal",
                "label_ar": "سكوندال",
                "label_fr": "Skondal"
            },
            {
                "value": 44549,
                "label": "Vanersborg",
                "label_ar": "فانيرسبورج",
                "label_fr": "Vanersborg"
            },
            {
                "value": 44747,
                "label": "Likenas",
                "label_ar": "ليكيناس",
                "label_fr": "Likenas"
            },
            {
                "value": 44388,
                "label": "Haegersten",
                "label_ar": "هايجرستن",
                "label_fr": "Haegersten"
            },
            {
                "value": 44819,
                "label": "Rydboholm",
                "label_ar": "ريدبوهولم",
                "label_fr": "Rydboholm"
            },
            {
                "value": 44571,
                "label": "Bromoella",
                "label_ar": "برومويلا",
                "label_fr": "Bromoella"
            },
            {
                "value": 44934,
                "label": "Aneby",
                "label_ar": "آنبي",
                "label_fr": "Aneby"
            },
            {
                "value": 44486,
                "label": "Stocksund",
                "label_ar": "ستوكسوند",
                "label_fr": "Stocksund"
            },
            {
                "value": 45214,
                "label": "Hanaskog",
                "label_ar": "هاناسكوغ",
                "label_fr": "Hanaskog"
            },
            {
                "value": 45348,
                "label": "Bottnaryd",
                "label_ar": "بوتنارد",
                "label_fr": "Bottnaryd"
            },
            {
                "value": 44494,
                "label": "Staffanstorp",
                "label_ar": "ستافانستورب",
                "label_fr": "Staffanstorp"
            },
            {
                "value": 45204,
                "label": "Hultsfred",
                "label_ar": "هولتسفريد",
                "label_fr": "Hultsfred"
            },
            {
                "value": 44820,
                "label": "Faergelanda",
                "label_ar": "فيرغيلاندا",
                "label_fr": "Faergelanda"
            },
            {
                "value": 45504,
                "label": "Blackstad",
                "label_ar": "بلاكستاد",
                "label_fr": "Blackstad"
            },
            {
                "value": 44856,
                "label": "Nybro",
                "label_ar": "نيبرو",
                "label_fr": "Nybro"
            },
            {
                "value": 44440,
                "label": "Stora",
                "label_ar": "ستورا",
                "label_fr": "Stora"
            },
            {
                "value": 45397,
                "label": "Tingstade",
                "label_ar": "تينجستاد",
                "label_fr": "Tingstade"
            },
            {
                "value": 44459,
                "label": "Oregrund",
                "label_ar": "أورغروند",
                "label_fr": "Oregrund"
            },
            {
                "value": 44762,
                "label": "Harmanger",
                "label_ar": "ضرر",
                "label_fr": "Harmanger"
            },
            {
                "value": 45127,
                "label": "Storebro",
                "label_ar": "ستوربرو",
                "label_fr": "Storebro"
            },
            {
                "value": 44763,
                "label": "Alno",
                "label_ar": "ألنو",
                "label_fr": "Alno"
            },
            {
                "value": 45014,
                "label": "Overhornas",
                "label_ar": "أبورناس",
                "label_fr": "Overhornas"
            },
            {
                "value": 45435,
                "label": "Virserum",
                "label_ar": "فيرسيروم",
                "label_fr": "Virsérum"
            },
            {
                "value": 45322,
                "label": "Gallo",
                "label_ar": "جالو",
                "label_fr": "Gallo"
            },
            {
                "value": 44674,
                "label": "Loddekopinge",
                "label_ar": "لودكوبينجي",
                "label_fr": "Loddekopinge"
            },
            {
                "value": 45383,
                "label": "Sandhem",
                "label_ar": "Sandhem",
                "label_fr": "Sandhem"
            },
            {
                "value": 44392,
                "label": "Sollentuna",
                "label_ar": "سولينتونا",
                "label_fr": "Sollentuna"
            },
            {
                "value": 44714,
                "label": "Tvaralund",
                "label_ar": "تفارالوند",
                "label_fr": "Tvaralund"
            },
            {
                "value": 45347,
                "label": "Osterskar",
                "label_ar": "اوسترسكار",
                "label_fr": "Osterskar"
            },
            {
                "value": 44569,
                "label": "Piteå",
                "label_ar": "بيتيو",
                "label_fr": "Piteå"
            },
            {
                "value": 44815,
                "label": "Sodra Sunderbyn",
                "label_ar": "سودرا سندربين",
                "label_fr": "Sodra Sunderbyn"
            },
            {
                "value": 45315,
                "label": "Fredriksberg",
                "label_ar": "فريدريكسبيرغ",
                "label_fr": "Fredriksberg"
            },
            {
                "value": 45547,
                "label": "Ingarp",
                "label_ar": "إنجارب",
                "label_fr": "Ingarp"
            },
            {
                "value": 44435,
                "label": "Sigtuna",
                "label_ar": "سيجتونا",
                "label_fr": "Sigtuna"
            },
            {
                "value": 44442,
                "label": "Marsta",
                "label_ar": "مارستا",
                "label_fr": "Marsta"
            },
            {
                "value": 45158,
                "label": "Kinnarp",
                "label_ar": "كينارب",
                "label_fr": "Kinnarp"
            },
            {
                "value": 44660,
                "label": "Kullavik",
                "label_ar": "كولافيك",
                "label_fr": "Kullavik"
            },
            {
                "value": 44704,
                "label": "Harby",
                "label_ar": "حربي",
                "label_fr": "Harby"
            },
            {
                "value": 45571,
                "label": "Hestra",
                "label_ar": "هيسترا",
                "label_fr": "Hestra"
            },
            {
                "value": 44381,
                "label": "Lindome",
                "label_ar": "ليندوم",
                "label_fr": "Lindome"
            },
            {
                "value": 44985,
                "label": "Kolback",
                "label_ar": "كولباك",
                "label_fr": "Kolback"
            },
            {
                "value": 45076,
                "label": "Hova",
                "label_ar": "هوفا",
                "label_fr": "Hova"
            },
            {
                "value": 44482,
                "label": "Morrum",
                "label_ar": "موروم",
                "label_fr": "Morrum"
            },
            {
                "value": 44803,
                "label": "Froso",
                "label_ar": "فروسو",
                "label_fr": "Froso"
            },
            {
                "value": 44395,
                "label": "Taby",
                "label_ar": "تابي",
                "label_fr": "Taby"
            },
            {
                "value": 45399,
                "label": "Blattnicksele",
                "label_ar": "Blattnicksele",
                "label_fr": "Blattnicksele"
            },
            {
                "value": 45589,
                "label": "Kallo",
                "label_ar": "كالو",
                "label_fr": "Kallo"
            },
            {
                "value": 45373,
                "label": "Skivarp",
                "label_ar": "سكيفارب",
                "label_fr": "Skivarp"
            },
            {
                "value": 45036,
                "label": "Alunda",
                "label_ar": "ألوندا",
                "label_fr": "Alunda"
            },
            {
                "value": 44537,
                "label": "Gislaved",
                "label_ar": "جيسلافيد",
                "label_fr": "Gislaved"
            },
            {
                "value": 44393,
                "label": "Akersberga",
                "label_ar": "أكيرسبيرجا",
                "label_fr": "Akersberga"
            },
            {
                "value": 45365,
                "label": "Stigen",
                "label_ar": "ستيجن",
                "label_fr": "Stigen"
            },
            {
                "value": 44886,
                "label": "Harbo",
                "label_ar": "Harbo",
                "label_fr": "Harbo"
            },
            {
                "value": 44477,
                "label": "Bergsjoe",
                "label_ar": "بيرجسجو",
                "label_fr": "Bergsjoe"
            },
            {
                "value": 45133,
                "label": "Odakra",
                "label_ar": "Odakra",
                "label_fr": "Odakra"
            },
            {
                "value": 44962,
                "label": "Gyttorp",
                "label_ar": "جيتورب",
                "label_fr": "Gyttorp"
            },
            {
                "value": 44559,
                "label": "Sundbyberg",
                "label_ar": "سوندبيبيرج",
                "label_fr": "Sundbyberg"
            },
            {
                "value": 45284,
                "label": "Stjarnhov",
                "label_ar": "ستيارنهوف",
                "label_fr": "Stjarnhov"
            },
            {
                "value": 45609,
                "label": "Palsboda",
                "label_ar": "بالسبودا",
                "label_fr": "Palsboda"
            },
            {
                "value": 45027,
                "label": "Orsundsbro",
                "label_ar": "Orsundsbro",
                "label_fr": "Orsundsbro"
            },
            {
                "value": 45385,
                "label": "Ljungbyholm",
                "label_ar": "Ljungbyholm",
                "label_fr": "Ljungbyholm"
            },
            {
                "value": 45427,
                "label": "Fors",
                "label_ar": "فورس",
                "label_fr": "Fors"
            },
            {
                "value": 45164,
                "label": "Tollered",
                "label_ar": "تلقاها",
                "label_fr": "Péage"
            },
            {
                "value": 44626,
                "label": "Stallarholmen",
                "label_ar": "Stallarholmen",
                "label_fr": "Stallarholmen"
            },
            {
                "value": 44580,
                "label": "Oskarshamn",
                "label_ar": "أوسكارسهامن",
                "label_fr": "Oskarshamn"
            },
            {
                "value": 44642,
                "label": "Skara",
                "label_ar": "سكارا",
                "label_fr": "Skara"
            },
            {
                "value": 45409,
                "label": "Sovde",
                "label_ar": "سوفدي",
                "label_fr": "Sovde"
            },
            {
                "value": 44850,
                "label": "Bralanda",
                "label_ar": "برالاندا",
                "label_fr": "Bralanda"
            },
            {
                "value": 44827,
                "label": "Svardsjo",
                "label_ar": "Svardsjo",
                "label_fr": "Svardsjo"
            },
            {
                "value": 45362,
                "label": "Karesuando",
                "label_ar": "كاريسواندو",
                "label_fr": "Karesuando"
            },
            {
                "value": 44521,
                "label": "Brunflo",
                "label_ar": "برونفلو",
                "label_fr": "Brunflo"
            },
            {
                "value": 45388,
                "label": "Skyllberg",
                "label_ar": "سكيلبيرج",
                "label_fr": "Skyllberg"
            },
            {
                "value": 44473,
                "label": "Lidkoping",
                "label_ar": "ليدكوبينج",
                "label_fr": "Lidkoping"
            },
            {
                "value": 44621,
                "label": "Degerfors",
                "label_ar": "ديجرفورس",
                "label_fr": "Degerfors"
            },
            {
                "value": 45149,
                "label": "Torsby",
                "label_ar": "تورسبي",
                "label_fr": "Torsby"
            },
            {
                "value": 45267,
                "label": "Torpshammar",
                "label_ar": "تورشمار",
                "label_fr": "Torpshammar"
            },
            {
                "value": 44649,
                "label": "Molnlycke",
                "label_ar": "مولنليك",
                "label_fr": "Molnlycke"
            },
            {
                "value": 45109,
                "label": "Indal",
                "label_ar": "إندال",
                "label_fr": "Indal"
            },
            {
                "value": 45274,
                "label": "Katthammarsvik",
                "label_ar": "كاثمرسفيك",
                "label_fr": "Katthammarsvik"
            },
            {
                "value": 44421,
                "label": "Vastra Frolunda",
                "label_ar": "فاسترا فرولوندا",
                "label_fr": "Vastra Frolunda"
            },
            {
                "value": 45311,
                "label": "Vega",
                "label_ar": "فيجا",
                "label_fr": "Vega"
            },
            {
                "value": 44685,
                "label": "Tingsryd",
                "label_ar": "تينجزريد",
                "label_fr": "Tingsryd"
            },
            {
                "value": 45296,
                "label": "Virsbo Bruk",
                "label_ar": "فيرسبو بروك",
                "label_fr": "Virsbo Bruk"
            },
            {
                "value": 44936,
                "label": "Flisby",
                "label_ar": "فليسبي",
                "label_fr": "Flisby"
            },
            {
                "value": 44993,
                "label": "Soderhamn",
                "label_ar": "سودرهامن",
                "label_fr": "Söderhamn"
            },
            {
                "value": 44782,
                "label": "Veinge",
                "label_ar": "فينج",
                "label_fr": "Veinge"
            },
            {
                "value": 44620,
                "label": "Angered",
                "label_ar": "غاضب",
                "label_fr": "En colère"
            },
            {
                "value": 45542,
                "label": "Grimeton",
                "label_ar": "جريميتون",
                "label_fr": "Grimeton"
            },
            {
                "value": 45150,
                "label": "Ryd",
                "label_ar": "ريد",
                "label_fr": "Ryd"
            },
            {
                "value": 45526,
                "label": "Bofors",
                "label_ar": "بوفورز",
                "label_fr": "Bofors"
            },
            {
                "value": 45332,
                "label": "Mullhyttan",
                "label_ar": "Mullhyttan",
                "label_fr": "Mullhyttan"
            },
            {
                "value": 44999,
                "label": "Loberod",
                "label_ar": "لوبيرود",
                "label_fr": "Loberod"
            },
            {
                "value": 44996,
                "label": "Djura",
                "label_ar": "جورا",
                "label_fr": "Djura"
            },
            {
                "value": 44523,
                "label": "Lerum",
                "label_ar": "ليروم",
                "label_fr": "Lerum"
            },
            {
                "value": 45152,
                "label": "Balinge",
                "label_ar": "Balinge",
                "label_fr": "Balinge"
            },
            {
                "value": 45224,
                "label": "Norrfjarden",
                "label_ar": "نورفجاردين",
                "label_fr": "Norrfjarden"
            },
            {
                "value": 45433,
                "label": "Los",
                "label_ar": "لوس",
                "label_fr": "Los"
            },
            {
                "value": 45200,
                "label": "Moerbylanga",
                "label_ar": "موربيلانجا",
                "label_fr": "Moerbylanga"
            },
            {
                "value": 45358,
                "label": "Anderstorp",
                "label_ar": "أندرستورب",
                "label_fr": "Anderstorp"
            },
            {
                "value": 45056,
                "label": "Fagersanna",
                "label_ar": "فاجرسانا",
                "label_fr": "Fagersanna"
            },
            {
                "value": 44590,
                "label": "Saltsjo-Duvnas",
                "label_ar": "سالتسجو دوفناس",
                "label_fr": "Saltsjo-Duvnas"
            },
            {
                "value": 45195,
                "label": "Ingelstad",
                "label_ar": "إنجلستاد",
                "label_fr": "Ingelstad"
            },
            {
                "value": 44971,
                "label": "Skinnskatteberg",
                "label_ar": "سكينسكاتبيرج",
                "label_fr": "Skinnskatteberg"
            },
            {
                "value": 44852,
                "label": "Frandefors",
                "label_ar": "فراندفورز",
                "label_fr": "Frandefors"
            },
            {
                "value": 44578,
                "label": "Ulricehamn",
                "label_ar": "أولريسهامن",
                "label_fr": "Ulricehamn"
            },
            {
                "value": 45068,
                "label": "Asele",
                "label_ar": "اسيلي",
                "label_fr": "Asele"
            },
            {
                "value": 44935,
                "label": "Ormaryd",
                "label_ar": "Ormaryd",
                "label_fr": "Ormaryd"
            },
            {
                "value": 45023,
                "label": "Veddige",
                "label_ar": "فيديج",
                "label_fr": "Veddige"
            },
            {
                "value": 45062,
                "label": "Nossebro",
                "label_ar": "نوسيبرو",
                "label_fr": "Nossebro"
            },
            {
                "value": 44928,
                "label": "Timmersdala",
                "label_ar": "تيمرسدالا",
                "label_fr": "Timmersdala"
            },
            {
                "value": 44745,
                "label": "Stenstorp",
                "label_ar": "ستينستورب",
                "label_fr": "Stenstorp"
            },
            {
                "value": 45226,
                "label": "Dio",
                "label_ar": "ديو",
                "label_fr": "Dio"
            },
            {
                "value": 45337,
                "label": "Ljungbyhed",
                "label_ar": "Ljungbyhed",
                "label_fr": "Ljungbyhed"
            },
            {
                "value": 44702,
                "label": "Billeberga",
                "label_ar": "بيلبرجا",
                "label_fr": "Billeberga"
            },
            {
                "value": 45384,
                "label": "Donso",
                "label_ar": "دونسو",
                "label_fr": "Donso"
            },
            {
                "value": 44651,
                "label": "Olofstorp",
                "label_ar": "أولوفستورب",
                "label_fr": "Olofstorp"
            },
            {
                "value": 45193,
                "label": "Tranemo",
                "label_ar": "ترانيمو",
                "label_fr": "Tranemo"
            },
            {
                "value": 44541,
                "label": "Vagnharad",
                "label_ar": "فاغنهاراد",
                "label_fr": "Vagnharad"
            },
            {
                "value": 44656,
                "label": "Hono",
                "label_ar": "هونو",
                "label_fr": "Hono"
            },
            {
                "value": 44450,
                "label": "Partille",
                "label_ar": "بارتيل",
                "label_fr": "Partille"
            },
            {
                "value": 44619,
                "label": "Domsjo",
                "label_ar": "دومسجو",
                "label_fr": "Domsjo"
            },
            {
                "value": 44684,
                "label": "Tagarp",
                "label_ar": "تاجارب",
                "label_fr": "Tagarpe"
            },
            {
                "value": 45515,
                "label": "Rydaholm",
                "label_ar": "ريداهولم",
                "label_fr": "Rydaholm"
            },
            {
                "value": 45312,
                "label": "Rolfstorp",
                "label_ar": "رولفستورب",
                "label_fr": "Rolfstorp"
            },
            {
                "value": 45286,
                "label": "Fagervik",
                "label_ar": "فاجيرفيك",
                "label_fr": "Fagervik"
            },
            {
                "value": 44403,
                "label": "Molnbo",
                "label_ar": "مولنبو",
                "label_fr": "Molnbo"
            },
            {
                "value": 45116,
                "label": "Rydobruk",
                "label_ar": "ريدوبروك",
                "label_fr": "Rydobruk"
            },
            {
                "value": 45138,
                "label": "Mansarp",
                "label_ar": "مانسارب",
                "label_fr": "Mansarp"
            },
            {
                "value": 45235,
                "label": "Veberod",
                "label_ar": "فيبيرود",
                "label_fr": "Veberod"
            },
            {
                "value": 45117,
                "label": "Gunnilse",
                "label_ar": "جونيلسي",
                "label_fr": "Gunnilse"
            },
            {
                "value": 44945,
                "label": "Glimakra",
                "label_ar": "جليماكرا",
                "label_fr": "Glimakra"
            },
            {
                "value": 44981,
                "label": "Kvissleby",
                "label_ar": "Kvissleby",
                "label_fr": "Kvissleby"
            },
            {
                "value": 44413,
                "label": "Norrtalje",
                "label_ar": "نورتالي",
                "label_fr": "Norrtalje"
            },
            {
                "value": 44831,
                "label": "Robertsfors",
                "label_ar": "روبرتسفورس",
                "label_fr": "Robertsfors"
            },
            {
                "value": 45000,
                "label": "Storuman",
                "label_ar": "ستورومان",
                "label_fr": "Storuman"
            },
            {
                "value": 44664,
                "label": "Roro",
                "label_ar": "رورو",
                "label_fr": "Roro"
            },
            {
                "value": 45037,
                "label": "Hallsberg",
                "label_ar": "هالسبرج",
                "label_fr": "Hallsberg"
            },
            {
                "value": 45085,
                "label": "Soderala",
                "label_ar": "سوديرالا",
                "label_fr": "Soderala"
            },
            {
                "value": 44641,
                "label": "Uttran",
                "label_ar": "أوتران",
                "label_fr": "Uttran"
            },
            {
                "value": 45615,
                "label": "Svaneholm",
                "label_ar": "سفانيهولم",
                "label_fr": "Svaneholm"
            },
            {
                "value": 45474,
                "label": "Timmernabben",
                "label_ar": "تيمرنابن",
                "label_fr": "Timmernabben"
            },
            {
                "value": 44531,
                "label": "Borlänge",
                "label_ar": "بورلانج",
                "label_fr": "Borlänge"
            },
            {
                "value": 45221,
                "label": "Rodeby",
                "label_ar": "روديبي",
                "label_fr": "Rodeby"
            },
            {
                "value": 45044,
                "label": "Mala",
                "label_ar": "مالا",
                "label_fr": "Mala"
            },
            {
                "value": 45114,
                "label": "Seglora",
                "label_ar": "سيجلورا",
                "label_fr": "Seglora"
            },
            {
                "value": 45049,
                "label": "Sturefors",
                "label_ar": "Sturefors",
                "label_fr": "Sturefors"
            },
            {
                "value": 44976,
                "label": "Alunda",
                "label_ar": "ألوندا",
                "label_fr": "Alunda"
            },
            {
                "value": 45017,
                "label": "Husum",
                "label_ar": "هوسوم",
                "label_fr": "Husum"
            },
            {
                "value": 44895,
                "label": "Vittaryd",
                "label_ar": "فيتارد",
                "label_fr": "Vittaryd"
            },
            {
                "value": 45331,
                "label": "Ganghester",
                "label_ar": "جانجستر",
                "label_fr": "Ganghester"
            },
            {
                "value": 44957,
                "label": "Ljustero",
                "label_ar": "Ljustero",
                "label_fr": "Ljustero"
            },
            {
                "value": 44658,
                "label": "Grabo",
                "label_ar": "جرابو",
                "label_fr": "Grabo"
            },
            {
                "value": 45592,
                "label": "Hallestad",
                "label_ar": "هالستاد",
                "label_fr": "Hallestad"
            },
            {
                "value": 44669,
                "label": "Ramdala",
                "label_ar": "رامدالا",
                "label_fr": "Ramdala"
            },
            {
                "value": 44607,
                "label": "Orbyhus",
                "label_ar": "أوربيوس",
                "label_fr": "Orbyhus"
            },
            {
                "value": 45026,
                "label": "Vara",
                "label_ar": "فارا",
                "label_fr": "Vara"
            },
            {
                "value": 45387,
                "label": "Arla",
                "label_ar": "آرلا",
                "label_fr": "Arla"
            },
            {
                "value": 45502,
                "label": "Undersaker",
                "label_ar": "وكيل",
                "label_fr": "Undersaker"
            },
            {
                "value": 44838,
                "label": "Norsholm",
                "label_ar": "نورشولم",
                "label_fr": "Norsholm"
            },
            {
                "value": 45490,
                "label": "Fageras",
                "label_ar": "فاجيراس",
                "label_fr": "Fageras"
            },
            {
                "value": 44515,
                "label": "Rottne",
                "label_ar": "روتني",
                "label_fr": "Rottne"
            },
            {
                "value": 45289,
                "label": "Ljustorp",
                "label_ar": "Ljustorp",
                "label_fr": "Ljustorp"
            },
            {
                "value": 44487,
                "label": "Danderyd",
                "label_ar": "دانديريد",
                "label_fr": "Danderyd"
            },
            {
                "value": 45516,
                "label": "Bjorbo",
                "label_ar": "بجوربو",
                "label_fr": "Bjorbo"
            },
            {
                "value": 45190,
                "label": "Rostanga",
                "label_ar": "روستانجا",
                "label_fr": "Rostanga"
            },
            {
                "value": 44598,
                "label": "Karra",
                "label_ar": "كره",
                "label_fr": "Karra"
            },
            {
                "value": 44397,
                "label": "Halmstad",
                "label_ar": "هالمستاد",
                "label_fr": "Halmstad"
            },
            {
                "value": 45349,
                "label": "Vikarbyn",
                "label_ar": "فيكاربين",
                "label_fr": "Vikarbyn"
            },
            {
                "value": 44492,
                "label": "Barseback",
                "label_ar": "بارسيباك",
                "label_fr": "Barseback"
            },
            {
                "value": 44770,
                "label": "Stenkullen",
                "label_ar": "ستينكولين",
                "label_fr": "Stenkullen"
            },
            {
                "value": 44916,
                "label": "Rosersberg",
                "label_ar": "روزرسبرغ",
                "label_fr": "Rosersberg"
            },
            {
                "value": 45457,
                "label": "Gota",
                "label_ar": "حصلت على",
                "label_fr": "Avoir un"
            },
            {
                "value": 45054,
                "label": "Jaervsoe",
                "label_ar": "Jaervsoe",
                "label_fr": "Jaervsoe"
            },
            {
                "value": 44604,
                "label": "Tumba",
                "label_ar": "تومبا",
                "label_fr": "Tumba"
            },
            {
                "value": 44776,
                "label": "Arbra",
                "label_ar": "أربرا",
                "label_fr": "Arbra"
            },
            {
                "value": 45463,
                "label": "Klavrestrom",
                "label_ar": "كلافيستروم",
                "label_fr": "Klavrestrom"
            },
            {
                "value": 45151,
                "label": "Malung",
                "label_ar": "مالونج",
                "label_fr": "Malung"
            },
            {
                "value": 44671,
                "label": "Hoeoer",
                "label_ar": "هويور",
                "label_fr": "Hoeoer"
            },
            {
                "value": 44708,
                "label": "Nattraby",
                "label_ar": "النترابى",
                "label_fr": "Nattraby"
            },
            {
                "value": 45100,
                "label": "Vaggeryd",
                "label_ar": "فاجريد",
                "label_fr": "Vaggeryd"
            },
            {
                "value": 44988,
                "label": "Vannasby",
                "label_ar": "فاناسبي",
                "label_fr": "Vannasby"
            },
            {
                "value": 44897,
                "label": "Kiruna",
                "label_ar": "كيرونا",
                "label_fr": "Kiruna"
            },
            {
                "value": 44661,
                "label": "Goetene",
                "label_ar": "جوتين",
                "label_fr": "Goetène"
            },
            {
                "value": 44471,
                "label": "Nysater",
                "label_ar": "نياتر",
                "label_fr": "Nysater"
            },
            {
                "value": 44583,
                "label": "Sundsvall",
                "label_ar": "سوندسفال",
                "label_fr": "Sundsvall"
            },
            {
                "value": 45432,
                "label": "Tallasen",
                "label_ar": "تالاسين",
                "label_fr": "Tallasen"
            },
            {
                "value": 44679,
                "label": "Karlskrona",
                "label_ar": "كارلسكرونا",
                "label_fr": "Karlskrona"
            },
            {
                "value": 45191,
                "label": "Skillingaryd",
                "label_ar": "سكيلينجار د",
                "label_fr": "Skillingaryd"
            },
            {
                "value": 44836,
                "label": "Alvangen",
                "label_ar": "ألفانجين",
                "label_fr": "Alvangen"
            },
            {
                "value": 44623,
                "label": "Bjornlunda",
                "label_ar": "بيورنلوندا",
                "label_fr": "Bjornlunda"
            },
            {
                "value": 45013,
                "label": "Langviksmon",
                "label_ar": "لانجفيكسمون",
                "label_fr": "Langviksmon"
            },
            {
                "value": 44633,
                "label": "Laholm",
                "label_ar": "لاهولم",
                "label_fr": "Laholm"
            },
            {
                "value": 44686,
                "label": "Simrishamn",
                "label_ar": "سيمريسهامن",
                "label_fr": "Simrishamn"
            },
            {
                "value": 44766,
                "label": "Savsjostrom",
                "label_ar": "Savsjostrom",
                "label_fr": "Savsjostrom"
            },
            {
                "value": 45124,
                "label": "Dala-Floda",
                "label_ar": "دالا فلودا",
                "label_fr": "Dala-Floda"
            },
            {
                "value": 44878,
                "label": "Brosarp",
                "label_ar": "بروسارب",
                "label_fr": "Brosarp"
            },
            {
                "value": 45420,
                "label": "Hindas",
                "label_ar": "هنداس",
                "label_fr": "Hindas"
            },
            {
                "value": 45034,
                "label": "Frillesas",
                "label_ar": "فريليساس",
                "label_fr": "Frillesas"
            },
            {
                "value": 45344,
                "label": "Bjuraker",
                "label_ar": "بجوراكر",
                "label_fr": "Bjuraker"
            },
            {
                "value": 44743,
                "label": "Stollet",
                "label_ar": "ستوليت",
                "label_fr": "Stollet"
            },
            {
                "value": 45071,
                "label": "Koping",
                "label_ar": "كوبينج",
                "label_fr": "Koping"
            },
            {
                "value": 44950,
                "label": "Finja",
                "label_ar": "فينجا",
                "label_fr": "Finja"
            },
            {
                "value": 45403,
                "label": "Graso",
                "label_ar": "جراسو",
                "label_fr": "Graso"
            },
            {
                "value": 45309,
                "label": "Vessigebro",
                "label_ar": "فيسيجيبرو",
                "label_fr": "Vessigebro"
            },
            {
                "value": 45015,
                "label": "Sjalevad",
                "label_ar": "سجاليفاد",
                "label_fr": "Sjalevad"
            },
            {
                "value": 44783,
                "label": "Ockelbo",
                "label_ar": "أوكيلبو",
                "label_fr": "Ockelbo"
            },
            {
                "value": 44997,
                "label": "Almunge",
                "label_ar": "المونج",
                "label_fr": "Almunge"
            },
            {
                "value": 44725,
                "label": "Lindesberg",
                "label_ar": "ليندسبرغ",
                "label_fr": "Lindesberg"
            },
            {
                "value": 45518,
                "label": "Asenhoga",
                "label_ar": "أسنهوجا",
                "label_fr": "Asenhoga"
            },
            {
                "value": 44696,
                "label": "Trelleborg",
                "label_ar": "تريلبورج",
                "label_fr": "Trelleborg"
            },
            {
                "value": 45402,
                "label": "Brokind",
                "label_ar": "بروكيند",
                "label_fr": "Brokind"
            },
            {
                "value": 44644,
                "label": "Mjolby",
                "label_ar": "مجولبي",
                "label_fr": "Mjolby"
            },
            {
                "value": 44992,
                "label": "Nasviken",
                "label_ar": "Nasviken",
                "label_fr": "Nasviken"
            },
            {
                "value": 44550,
                "label": "Lilla Edet",
                "label_ar": "ليلا إيديت",
                "label_fr": "Lilla Edet"
            },
            {
                "value": 45509,
                "label": "Lekeryd",
                "label_ar": "ليكريد",
                "label_fr": "Lekeryd"
            },
            {
                "value": 45019,
                "label": "Moliden",
                "label_ar": "موليدين",
                "label_fr": "Moliden"
            },
            {
                "value": 44592,
                "label": "Vasterfarnebo",
                "label_ar": "فاسترفارنيبو",
                "label_fr": "Vasterfarnebo"
            },
            {
                "value": 44853,
                "label": "Ucklum",
                "label_ar": "أوكلوم",
                "label_fr": "Ucklum"
            },
            {
                "value": 45395,
                "label": "Orno",
                "label_ar": "أو لا",
                "label_fr": "Ou pas"
            },
            {
                "value": 45136,
                "label": "Vallda",
                "label_ar": "فالدا",
                "label_fr": "Vallda"
            },
            {
                "value": 45185,
                "label": "Kvicksund",
                "label_ar": "كفيكسوند",
                "label_fr": "Kvicksund"
            },
            {
                "value": 44456,
                "label": "Hallstavik",
                "label_ar": "هالستافيك",
                "label_fr": "Hallstavik"
            },
            {
                "value": 44861,
                "label": "Oviken",
                "label_ar": "أوفيكين",
                "label_fr": "Oviken"
            },
            {
                "value": 44654,
                "label": "Fjaeras station",
                "label_ar": "محطة فجراس",
                "label_fr": "Gare de Fjaeras"
            },
            {
                "value": 44711,
                "label": "Delsbo",
                "label_ar": "دلسبو",
                "label_fr": "Delsbo"
            },
            {
                "value": 45368,
                "label": "Simlangsdalen",
                "label_ar": "Simlangsdalen",
                "label_fr": "Simlangsdalen"
            },
            {
                "value": 45209,
                "label": "Kvibille",
                "label_ar": "Kvibille",
                "label_fr": "Kvibille"
            },
            {
                "value": 45418,
                "label": "Follinge",
                "label_ar": "فولينج",
                "label_fr": "Follinge"
            },
            {
                "value": 45488,
                "label": "Moholm",
                "label_ar": "موهولم",
                "label_fr": "Moholm"
            },
            {
                "value": 44568,
                "label": "Gnosjoe",
                "label_ar": "غنوسجو",
                "label_fr": "Gnosjoe"
            },
            {
                "value": 45004,
                "label": "Bollstabruk",
                "label_ar": "بولستبروك",
                "label_fr": "Bollstabruk"
            },
            {
                "value": 45608,
                "label": "Annerstad",
                "label_ar": "أنيرستاد",
                "label_fr": "Annerstad"
            },
            {
                "value": 45596,
                "label": "Roke",
                "label_ar": "روك",
                "label_fr": "Roke"
            },
            {
                "value": 45379,
                "label": "Roback",
                "label_ar": "روباك",
                "label_fr": "Roback"
            },
            {
                "value": 44502,
                "label": "Gammelstad",
                "label_ar": "جاميلستاد",
                "label_fr": "Gammelstad"
            },
            {
                "value": 44966,
                "label": "Kristdala",
                "label_ar": "كريستدالا",
                "label_fr": "Kristdala"
            },
            {
                "value": 45271,
                "label": "Mariannelund",
                "label_ar": "ماريانيلوند",
                "label_fr": "Mariannelund"
            },
            {
                "value": 44860,
                "label": "Asarna",
                "label_ar": "اسارنا",
                "label_fr": "Asarna"
            },
            {
                "value": 45035,
                "label": "Skogstorp",
                "label_ar": "Skogstorp",
                "label_fr": "Skogstorp"
            },
            {
                "value": 45122,
                "label": "Palange",
                "label_ar": "Palange",
                "label_fr": "Palange"
            },
            {
                "value": 45184,
                "label": "Gullbrandstorp",
                "label_ar": "جولبراندستورب",
                "label_fr": "Gullbrandstorp"
            },
            {
                "value": 45554,
                "label": "Hackas",
                "label_ar": "هاكاس",
                "label_fr": "Hackas"
            },
            {
                "value": 45199,
                "label": "Borgholm",
                "label_ar": "بورغولم",
                "label_fr": "Borgholm"
            },
            {
                "value": 44678,
                "label": "Sturko",
                "label_ar": "ستوركو",
                "label_fr": "Sturko"
            },
            {
                "value": 45064,
                "label": "Stromsbruk",
                "label_ar": "سترومسبروك",
                "label_fr": "Stromsbruk"
            },
            {
                "value": 44498,
                "label": "Hedekas",
                "label_ar": "Hedekas",
                "label_fr": "Hedekas"
            },
            {
                "value": 44779,
                "label": "Vellinge",
                "label_ar": "فيلينج",
                "label_fr": "Vellinge"
            },
            {
                "value": 45126,
                "label": "Bjoringe",
                "label_ar": "بجورينغ",
                "label_fr": "Bjoringe"
            },
            {
                "value": 44716,
                "label": "Byske",
                "label_ar": "بيسكي",
                "label_fr": "Byske"
            },
            {
                "value": 45045,
                "label": "Glommerstrask",
                "label_ar": "جلومرستراسك",
                "label_fr": "Glommerstrask"
            },
            {
                "value": 44894,
                "label": "Åre",
                "label_ar": "Åre",
                "label_fr": "Sont"
            },
            {
                "value": 45533,
                "label": "Nyland",
                "label_ar": "نيلاند",
                "label_fr": "Nyland"
            },
            {
                "value": 45585,
                "label": "Marieholm",
                "label_ar": "ماريهولم",
                "label_fr": "Marieholm"
            },
            {
                "value": 45563,
                "label": "Brunnby",
                "label_ar": "برونبي",
                "label_fr": "Brunnby"
            },
            {
                "value": 45583,
                "label": "Rorvik",
                "label_ar": "رورفيك",
                "label_fr": "Rorvik"
            },
            {
                "value": 45293,
                "label": "Sloinge",
                "label_ar": "سلونجي",
                "label_fr": "Sloinge"
            },
            {
                "value": 44699,
                "label": "Hyllinge",
                "label_ar": "هايلينج",
                "label_fr": "Hyllinge"
            },
            {
                "value": 44495,
                "label": "Solleftea",
                "label_ar": "Solleftea",
                "label_fr": "Solleftea"
            },
            {
                "value": 45262,
                "label": "Stora Skedvi",
                "label_ar": "ستورا سكيدفي",
                "label_fr": "Stora Skedvi"
            },
            {
                "value": 44600,
                "label": "Sjomarken",
                "label_ar": "سجوماركين",
                "label_fr": "Sjomarken"
            },
            {
                "value": 45356,
                "label": "Aspas",
                "label_ar": "أسباس",
                "label_fr": "Aspas"
            },
            {
                "value": 44650,
                "label": "Falköping",
                "label_ar": "فالشوبينغ",
                "label_fr": "Falköping"
            },
            {
                "value": 44466,
                "label": "Mariestad",
                "label_ar": "ماريستاد",
                "label_fr": "Mariestad"
            },
            {
                "value": 44909,
                "label": "Guldsmedshyttan",
                "label_ar": "جولدسميدشيتان",
                "label_fr": "Guldsmedshyttan"
            },
            {
                "value": 44451,
                "label": "Immeln",
                "label_ar": "إميلن",
                "label_fr": "Immeln"
            },
            {
                "value": 44705,
                "label": "Vankiva",
                "label_ar": "فانكيفا",
                "label_fr": "Vankiva"
            },
            {
                "value": 44707,
                "label": "Bjarred",
                "label_ar": "بياريد",
                "label_fr": "Bjarred"
            },
            {
                "value": 45250,
                "label": "Langhem",
                "label_ar": "لانغيم",
                "label_fr": "Langhem"
            },
            {
                "value": 44474,
                "label": "Hollviken",
                "label_ar": "هولفيكن",
                "label_fr": "Hollviken"
            },
            {
                "value": 44584,
                "label": "Lycksele",
                "label_ar": "ليكسيلي",
                "label_fr": "Lycksele"
            },
            {
                "value": 45067,
                "label": "Bjurholm",
                "label_ar": "بيورهولم",
                "label_fr": "Bjurholm"
            },
            {
                "value": 44375,
                "label": "Malmo",
                "label_ar": "مالمو",
                "label_fr": "Malmö"
            },
            {
                "value": 44954,
                "label": "Hasslo",
                "label_ar": "هاسلو",
                "label_fr": "Hasslo"
            },
            {
                "value": 45298,
                "label": "Hovmantorp",
                "label_ar": "هوفمانتورب",
                "label_fr": "Hovmantorp"
            },
            {
                "value": 45302,
                "label": "Sand",
                "label_ar": "الرمل",
                "label_fr": "Le sable"
            },
            {
                "value": 45046,
                "label": "Bullmark",
                "label_ar": "بولمارك",
                "label_fr": "Bullmark"
            },
            {
                "value": 45587,
                "label": "Rosvik",
                "label_ar": "روسفيك",
                "label_fr": "Rosvik"
            },
            {
                "value": 45580,
                "label": "Landeryd",
                "label_ar": "لاندريد",
                "label_fr": "Landeryd"
            },
            {
                "value": 44841,
                "label": "Kivik",
                "label_ar": "كيفيك",
                "label_fr": "Kivik"
            },
            {
                "value": 44389,
                "label": "Tyreso Strand",
                "label_ar": "تيريسو ستراند",
                "label_fr": "Tyreso Strand"
            },
            {
                "value": 45028,
                "label": "Valbo",
                "label_ar": "فالبو",
                "label_fr": "Valbo"
            },
            {
                "value": 45261,
                "label": "Bjursas",
                "label_ar": "بجورساس",
                "label_fr": "Bjursas"
            },
            {
                "value": 45144,
                "label": "Morlunda",
                "label_ar": "مورلوندا",
                "label_fr": "Morlunda"
            },
            {
                "value": 44532,
                "label": "Sala",
                "label_ar": "سالا",
                "label_fr": "Sala"
            },
            {
                "value": 45120,
                "label": "Gustafs",
                "label_ar": "جوستافس",
                "label_fr": "Gustafs"
            },
            {
                "value": 45391,
                "label": "Pajala",
                "label_ar": "باجالا",
                "label_fr": "Pajala"
            },
            {
                "value": 45363,
                "label": "Junosuando",
                "label_ar": "جونوسواندو",
                "label_fr": "Junosuando"
            },
            {
                "value": 44758,
                "label": "Knivsta",
                "label_ar": "كنيفستا",
                "label_fr": "Knivsta"
            },
            {
                "value": 44379,
                "label": "Lidingoe",
                "label_ar": "Lidingoe",
                "label_fr": "Lidingoe"
            },
            {
                "value": 44433,
                "label": "Kista",
                "label_ar": "كيستا",
                "label_fr": "Kista"
            },
            {
                "value": 44797,
                "label": "Knared",
                "label_ar": "Knared",
                "label_fr": "Knared"
            },
            {
                "value": 44995,
                "label": "Siljansnas",
                "label_ar": "Siljansnas",
                "label_fr": "Siljansnas"
            },
            {
                "value": 44944,
                "label": "Tygelsjo",
                "label_ar": "Tygelsjo",
                "label_fr": "Tygelsjo"
            },
            {
                "value": 45016,
                "label": "Bjorna",
                "label_ar": "بجورنا",
                "label_fr": "Bjorna"
            },
            {
                "value": 45489,
                "label": "Lysvik",
                "label_ar": "ليسفيك",
                "label_fr": "Lysvik"
            },
            {
                "value": 44724,
                "label": "Oxelosund",
                "label_ar": "أوكسيلوسوند",
                "label_fr": "Oxelosund"
            },
            {
                "value": 45582,
                "label": "Landsbro",
                "label_ar": "لاندسبرو",
                "label_fr": "Landsbro"
            },
            {
                "value": 45083,
                "label": "Botsmark",
                "label_ar": "بوتسمارك",
                "label_fr": "Botsmark"
            },
            {
                "value": 45396,
                "label": "Sandhult",
                "label_ar": "ساندهولت",
                "label_fr": "Sandhult"
            },
            {
                "value": 44721,
                "label": "Restad",
                "label_ar": "ريستاد",
                "label_fr": "Restad"
            },
            {
                "value": 44509,
                "label": "Spanga",
                "label_ar": "سبانجا",
                "label_fr": "Spanga"
            },
            {
                "value": 44542,
                "label": "Arboga",
                "label_ar": "أربوجا",
                "label_fr": "Arboga"
            },
            {
                "value": 45440,
                "label": "Borghamn",
                "label_ar": "بورغامن",
                "label_fr": "Borghamn"
            },
            {
                "value": 45364,
                "label": "Lannavaara",
                "label_ar": "لانافارا",
                "label_fr": "Lannavaara"
            },
            {
                "value": 45030,
                "label": "Kil",
                "label_ar": "كيل",
                "label_fr": "Kil"
            },
            {
                "value": 45414,
                "label": "Hillared",
                "label_ar": "هيلاريد",
                "label_fr": "Hillared"
            },
            {
                "value": 45428,
                "label": "Alsen",
                "label_ar": "ألسن",
                "label_fr": "Alsen"
            },
            {
                "value": 45328,
                "label": "Mankarbo",
                "label_ar": "مانكاربو",
                "label_fr": "Mankarbo"
            },
            {
                "value": 45594,
                "label": "Huarod",
                "label_ar": "هورود",
                "label_fr": "Huarod"
            },
            {
                "value": 44924,
                "label": "Kungsoer",
                "label_ar": "كونغسور",
                "label_fr": "Kungsoer"
            },
            {
                "value": 45330,
                "label": "Svaneholm",
                "label_ar": "سفانيهولم",
                "label_fr": "Svaneholm"
            },
            {
                "value": 45040,
                "label": "Sveg",
                "label_ar": "Sveg",
                "label_fr": "Sveg"
            },
            {
                "value": 44949,
                "label": "Tormestorp",
                "label_ar": "تورمستورب",
                "label_fr": "Tormestorp"
            },
            {
                "value": 44529,
                "label": "Bjuv",
                "label_ar": "بجوف",
                "label_fr": "Bjuv"
            },
            {
                "value": 45129,
                "label": "Alsterbro",
                "label_ar": "الستربرو",
                "label_fr": "Alsterbro"
            },
            {
                "value": 44540,
                "label": "Toereboda",
                "label_ar": "Toereboda",
                "label_fr": "Toereboda"
            },
            {
                "value": 45169,
                "label": "Kinnared",
                "label_ar": "كيناريد",
                "label_fr": "Kinnared"
            },
            {
                "value": 44614,
                "label": "Astorp",
                "label_ar": "أستورب",
                "label_fr": "Astorp"
            },
            {
                "value": 44609,
                "label": "Oxie",
                "label_ar": "Oxie",
                "label_fr": "Oxie"
            },
            {
                "value": 44458,
                "label": "Halso",
                "label_ar": "هالسو",
                "label_fr": "Halso"
            },
            {
                "value": 44581,
                "label": "Ytterby",
                "label_ar": "يتيربي",
                "label_fr": "Ytterby"
            },
            {
                "value": 45290,
                "label": "Njurundabommen",
                "label_ar": "Njurundabommen",
                "label_fr": "Njurundabommen"
            },
            {
                "value": 45201,
                "label": "Riddarhyttan",
                "label_ar": "ريدارهيتان",
                "label_fr": "Riddarhyttan"
            },
            {
                "value": 45251,
                "label": "Aspered",
                "label_ar": "أسبر",
                "label_fr": "Aspered"
            },
            {
                "value": 44449,
                "label": "Helsingborg",
                "label_ar": "هيلسينجبورج",
                "label_fr": "Helsingborg"
            },
            {
                "value": 44875,
                "label": "Västervik",
                "label_ar": "فاسترفيك",
                "label_fr": "Västervik"
            },
            {
                "value": 45244,
                "label": "Ankarsrum",
                "label_ar": "أنكارسرم",
                "label_fr": "Ankarsrum"
            },
            {
                "value": 45339,
                "label": "Traryd",
                "label_ar": "ترييد",
                "label_fr": "Traryd"
            },
            {
                "value": 45307,
                "label": "Allerum",
                "label_ar": "أليروم",
                "label_fr": "Allerum"
            },
            {
                "value": 45451,
                "label": "Asmundtorp",
                "label_ar": "أسموندورب",
                "label_fr": "Asmundtorp"
            },
            {
                "value": 45281,
                "label": "Lovikka",
                "label_ar": "لوفيكا",
                "label_fr": "Lovikka"
            },
            {
                "value": 44813,
                "label": "Fristad",
                "label_ar": "فريستاد",
                "label_fr": "Fristad"
            },
            {
                "value": 44947,
                "label": "Killeberg",
                "label_ar": "كيليبرغ",
                "label_fr": "Killeberg"
            },
            {
                "value": 45047,
                "label": "Dalby",
                "label_ar": "دالبي",
                "label_fr": "Dalby"
            },
            {
                "value": 44939,
                "label": "Hajom",
                "label_ar": "حاجوم",
                "label_fr": "Hajom"
            },
            {
                "value": 44454,
                "label": "Krokom",
                "label_ar": "كروكوم",
                "label_fr": "Krokom"
            },
            {
                "value": 44730,
                "label": "Vansbro",
                "label_ar": "فانسبرو",
                "label_fr": "Vansbro"
            },
            {
                "value": 44998,
                "label": "Skultuna",
                "label_ar": "Skultuna",
                "label_fr": "Skultuna"
            },
            {
                "value": 45552,
                "label": "Otterbacken",
                "label_ar": "أوترباكين",
                "label_fr": "Otterbacken"
            },
            {
                "value": 45223,
                "label": "Lerberget",
                "label_ar": "ليربيرجيت",
                "label_fr": "Lerberget"
            },
            {
                "value": 44588,
                "label": "Surahammar",
                "label_ar": "Surahammar",
                "label_fr": "Surahammar"
            },
            {
                "value": 45545,
                "label": "Fiskeby",
                "label_ar": "فيسكبي",
                "label_fr": "Fiskeby"
            },
            {
                "value": 45205,
                "label": "Sandby",
                "label_ar": "ساندبي",
                "label_fr": "Sandby"
            },
            {
                "value": 44720,
                "label": "Villan",
                "label_ar": "فيلان",
                "label_fr": "Villan"
            },
            {
                "value": 44467,
                "label": "Marback",
                "label_ar": "مارباك",
                "label_fr": "Marback"
            },
            {
                "value": 45478,
                "label": "Stromsnasbruk",
                "label_ar": "سترومسناسبروك",
                "label_fr": "Stromsnasbruk"
            },
            {
                "value": 45398,
                "label": "Forsbacka",
                "label_ar": "فورسباكا",
                "label_fr": "Forsbacka"
            },
            {
                "value": 45444,
                "label": "Flyinge",
                "label_ar": "فلاينج",
                "label_fr": "Flyinge"
            },
            {
                "value": 44876,
                "label": "Blentarp",
                "label_ar": "بلنتارب",
                "label_fr": "Blentarp"
            },
            {
                "value": 44898,
                "label": "Lit",
                "label_ar": "أشعل",
                "label_fr": "Allumé"
            },
            {
                "value": 44821,
                "label": "Jamshog",
                "label_ar": "جامشوغ",
                "label_fr": "Jamshog"
            },
            {
                "value": 45471,
                "label": "Onnestad",
                "label_ar": "اونستاد",
                "label_fr": "Onnestad"
            },
            {
                "value": 45549,
                "label": "Holmen",
                "label_ar": "هولمن",
                "label_fr": "Holmen"
            },
            {
                "value": 44969,
                "label": "Nykil",
                "label_ar": "نيكيل",
                "label_fr": "Nykil"
            },
            {
                "value": 44744,
                "label": "Tidan",
                "label_ar": "تيدان",
                "label_fr": "Tidan"
            },
            {
                "value": 44405,
                "label": "Djurhamn",
                "label_ar": "دجرهامن",
                "label_fr": "Djurhamn"
            },
            {
                "value": 44889,
                "label": "Forserum",
                "label_ar": "قوام",
                "label_fr": "Forserum"
            },
            {
                "value": 45147,
                "label": "Fagelfors",
                "label_ar": "فاجلفورز",
                "label_fr": "Fagelfors"
            },
            {
                "value": 45329,
                "label": "Aplared",
                "label_ar": "بلاريد",
                "label_fr": "Aplared"
            },
            {
                "value": 45445,
                "label": "Taernaby",
                "label_ar": "Taernaby",
                "label_fr": "Taernaby"
            },
            {
                "value": 44919,
                "label": "Harslov",
                "label_ar": "هارسلوف",
                "label_fr": "Harslov"
            },
            {
                "value": 45602,
                "label": "Segmon",
                "label_ar": "سيغمون",
                "label_fr": "Segmon"
            },
            {
                "value": 45080,
                "label": "Dikanaes",
                "label_ar": "ديكانايس",
                "label_fr": "Dikanaes"
            },
            {
                "value": 44386,
                "label": "Vaxjo",
                "label_ar": "فاكخو",
                "label_fr": "Vaxjo"
            },
            {
                "value": 45212,
                "label": "Floby",
                "label_ar": "مرن",
                "label_fr": "Floby"
            },
            {
                "value": 44391,
                "label": "Alta",
                "label_ar": "ألتا",
                "label_fr": "Alta"
            },
            {
                "value": 44546,
                "label": "Falun",
                "label_ar": "فالون",
                "label_fr": "Falun"
            },
            {
                "value": 44719,
                "label": "Moelle",
                "label_ar": "مويل",
                "label_fr": "Moelle"
            },
            {
                "value": 44516,
                "label": "Umeå",
                "label_ar": "أوميو",
                "label_fr": "Umeå"
            },
            {
                "value": 45530,
                "label": "Alstermo",
                "label_ar": "ألسترمو",
                "label_fr": "Alstermo"
            },
            {
                "value": 45447,
                "label": "Grimsas",
                "label_ar": "جريمساس",
                "label_fr": "Grimsas"
            },
            {
                "value": 45487,
                "label": "Hoviksnas",
                "label_ar": "Hoviksnas",
                "label_fr": "Hoviksnas"
            },
            {
                "value": 44884,
                "label": "Hovas",
                "label_ar": "هوفاس",
                "label_fr": "Hovas"
            },
            {
                "value": 44465,
                "label": "Molkom",
                "label_ar": "مولكوم",
                "label_fr": "Molkom"
            },
            {
                "value": 44655,
                "label": "Landvetter",
                "label_ar": "لاندفيتر",
                "label_fr": "Landvetter"
            },
            {
                "value": 45591,
                "label": "Saevast",
                "label_ar": "سيفاست",
                "label_fr": "Saevast"
            },
            {
                "value": 44429,
                "label": "Nacka",
                "label_ar": "ناكا",
                "label_fr": "Nacka"
            },
            {
                "value": 44899,
                "label": "Hyltebruk",
                "label_ar": "هيلتبروك",
                "label_fr": "Hyltebruk"
            },
            {
                "value": 44461,
                "label": "Romakloster",
                "label_ar": "روماكلوستر",
                "label_fr": "Romakloster"
            },
            {
                "value": 44735,
                "label": "OEckeroe",
                "label_ar": "OEckeroe",
                "label_fr": "OEckeroe"
            },
            {
                "value": 45215,
                "label": "Ramvik",
                "label_ar": "رامفيك",
                "label_fr": "Ramvik"
            },
            {
                "value": 44892,
                "label": "Anderstorp",
                "label_ar": "أندرستورب",
                "label_fr": "Anderstorp"
            },
            {
                "value": 44903,
                "label": "Ashammar",
                "label_ar": "أشمر",
                "label_fr": "Ashammar"
            },
            {
                "value": 44419,
                "label": "Asa",
                "label_ar": "ك",
                "label_fr": "Comme un"
            },
            {
                "value": 45210,
                "label": "Lycke",
                "label_ar": "ليك",
                "label_fr": "Lycke"
            },
            {
                "value": 44420,
                "label": "Hoerby",
                "label_ar": "هوربي",
                "label_fr": "Hoerby"
            },
            {
                "value": 44582,
                "label": "Jordbro",
                "label_ar": "جوردبرو",
                "label_fr": "Jordbro"
            },
            {
                "value": 45355,
                "label": "Larbro",
                "label_ar": "Larbro",
                "label_fr": "Larbro"
            },
            {
                "value": 45295,
                "label": "Kolsva",
                "label_ar": "كولسفا",
                "label_fr": "Kolsva"
            },
            {
                "value": 44631,
                "label": "Trosa",
                "label_ar": "تروسا",
                "label_fr": "Trosa"
            },
            {
                "value": 45617,
                "label": "Ljusfallshammar",
                "label_ar": "Ljusfallshammar",
                "label_fr": "Ljusfallshammar"
            },
            {
                "value": 45048,
                "label": "Vreta Kloster",
                "label_ar": "فريتا كلوستر",
                "label_fr": "Vreta Kloster"
            },
            {
                "value": 45318,
                "label": "Kvanum",
                "label_ar": "كفانوم",
                "label_fr": "Kvanum"
            },
            {
                "value": 45513,
                "label": "Jonaker",
                "label_ar": "جونكر",
                "label_fr": "Jonaker"
            },
            {
                "value": 45586,
                "label": "Horndal",
                "label_ar": "هورندال",
                "label_fr": "Horndal"
            },
            {
                "value": 45537,
                "label": "Varmland",
                "label_ar": "فارملاند",
                "label_fr": "Varmland"
            },
            {
                "value": 44833,
                "label": "Lagan",
                "label_ar": "لاجان",
                "label_fr": "Lagan"
            },
            {
                "value": 44646,
                "label": "Dals Rostock",
                "label_ar": "دالس روستوك",
                "label_fr": "Dals Rostock"
            },
            {
                "value": 44610,
                "label": "Ingmarso",
                "label_ar": "انغمارسو",
                "label_fr": "Ingmarso"
            },
            {
                "value": 45559,
                "label": "Torekov",
                "label_ar": "توريكوف",
                "label_fr": "Torekov"
            },
            {
                "value": 44948,
                "label": "Hastveda",
                "label_ar": "هاستفيدا",
                "label_fr": "Hastveda"
            },
            {
                "value": 45090,
                "label": "Fjugesta",
                "label_ar": "فجويستا",
                "label_fr": "Fjugesta"
            },
            {
                "value": 45192,
                "label": "Vretstorp",
                "label_ar": "فريتستورب",
                "label_fr": "Vretstorp"
            },
            {
                "value": 45186,
                "label": "Hallsta",
                "label_ar": "هالستا",
                "label_fr": "Hallsta"
            },
            {
                "value": 45175,
                "label": "Drottningholm",
                "label_ar": "دروتنينغهولم",
                "label_fr": "Drottningholm"
            },
            {
                "value": 44732,
                "label": "Getinge",
                "label_ar": "جيتينج",
                "label_fr": "Getinge"
            },
            {
                "value": 45270,
                "label": "Saxtorp",
                "label_ar": "ساكستورب",
                "label_fr": "Saxtorp"
            },
            {
                "value": 45135,
                "label": "Saeter",
                "label_ar": "Saeter",
                "label_fr": "Saeter"
            },
            {
                "value": 45410,
                "label": "Grangarde",
                "label_ar": "جرانجارد",
                "label_fr": "Grangarde"
            },
            {
                "value": 45137,
                "label": "Figeholm",
                "label_ar": "فيجهولم",
                "label_fr": "Figeholm"
            },
            {
                "value": 45012,
                "label": "Bjasta",
                "label_ar": "بجاستا",
                "label_fr": "Bjasta"
            },
            {
                "value": 44896,
                "label": "Elloes",
                "label_ar": "إلويس",
                "label_fr": "Elloes"
            },
            {
                "value": 45340,
                "label": "Vedum",
                "label_ar": "فيدوم",
                "label_fr": "Vedum"
            },
            {
                "value": 44728,
                "label": "Viken",
                "label_ar": "فيكن",
                "label_fr": "Viken"
            },
            {
                "value": 44717,
                "label": "Jarved",
                "label_ar": "جارفيد",
                "label_fr": "Jarved"
            },
            {
                "value": 45219,
                "label": "Kungsgarden",
                "label_ar": "Kungsgarden",
                "label_fr": "Kungsgarden"
            },
            {
                "value": 44547,
                "label": "Langshyttan",
                "label_ar": "لانغشيتان",
                "label_fr": "Langshyttan"
            },
            {
                "value": 45477,
                "label": "Tun",
                "label_ar": "تون",
                "label_fr": "Tonneau"
            },
            {
                "value": 45052,
                "label": "Ljusdal",
                "label_ar": "لجوسدال",
                "label_fr": "Ljusdal"
            },
            {
                "value": 45093,
                "label": "Hammaro",
                "label_ar": "هامارو",
                "label_fr": "Hammaro"
            },
            {
                "value": 45497,
                "label": "Braas",
                "label_ar": "براس",
                "label_fr": "Braas"
            },
            {
                "value": 45441,
                "label": "Holsbybrunn",
                "label_ar": "هولسبيبرون",
                "label_fr": "Holsbybrunn"
            },
            {
                "value": 44788,
                "label": "Vaxholm",
                "label_ar": "فكسهولم",
                "label_fr": "Vaxholm"
            },
            {
                "value": 45456,
                "label": "Hallekis",
                "label_ar": "هاليكيس",
                "label_fr": "Hallekis"
            },
            {
                "value": 45611,
                "label": "Ljugarn",
                "label_ar": "ليوغارن",
                "label_fr": "Ljugarn"
            },
            {
                "value": 45422,
                "label": "Sagmyra",
                "label_ar": "ساغميرا",
                "label_fr": "Sagmyra"
            },
            {
                "value": 45521,
                "label": "Hogsaters",
                "label_ar": "Hogsaters",
                "label_fr": "Hogsaters"
            },
            {
                "value": 44675,
                "label": "Ronneby",
                "label_ar": "رونيبي",
                "label_fr": "Ronneby"
            },
            {
                "value": 45324,
                "label": "Stroemsund",
                "label_ar": "سترومسوند",
                "label_fr": "Stroemsund"
            },
            {
                "value": 45551,
                "label": "Travad",
                "label_ar": "ترافاد",
                "label_fr": "Travad"
            },
            {
                "value": 44937,
                "label": "Malmback",
                "label_ar": "مالمباك",
                "label_fr": "Malmback"
            },
            {
                "value": 45536,
                "label": "Skaraborg",
                "label_ar": "سكارابورج",
                "label_fr": "Skaraborg"
            },
            {
                "value": 44490,
                "label": "Karlshamn",
                "label_ar": "كارلسهامن",
                "label_fr": "Karlshamn"
            },
            {
                "value": 45360,
                "label": "Ostra Frolunda",
                "label_ar": "أوسترا فرولوندا",
                "label_fr": "Ostra Frolunda"
            },
            {
                "value": 45005,
                "label": "Helgum",
                "label_ar": "هيلجوم",
                "label_fr": "Helgum"
            },
            {
                "value": 44986,
                "label": "Degeberga",
                "label_ar": "ديجيبيرجا",
                "label_fr": "Degeberga"
            },
            {
                "value": 45165,
                "label": "Dala-Jarna",
                "label_ar": "دلع جرنا",
                "label_fr": "Dala-Jarna"
            },
            {
                "value": 45297,
                "label": "Toecksfors",
                "label_ar": "Toecksfors",
                "label_fr": "Toecksfors"
            },
            {
                "value": 44906,
                "label": "Amotfors",
                "label_ar": "Amotfors",
                "label_fr": "Amotfors"
            },
            {
                "value": 45336,
                "label": "Skelleftehamn",
                "label_ar": "سكيلفتهامن",
                "label_fr": "Skelleftehamn"
            },
            {
                "value": 44378,
                "label": "Gothenburg",
                "label_ar": "جوتنبرج",
                "label_fr": "Göteborg"
            },
            {
                "value": 44855,
                "label": "Stora Hoga",
                "label_ar": "ستورا هوجا",
                "label_fr": "Stora Hoga"
            },
            {
                "value": 45443,
                "label": "Hammar",
                "label_ar": "حمر",
                "label_fr": "Hammar"
            },
            {
                "value": 45595,
                "label": "Arkelstorp",
                "label_ar": "Arkelstorp",
                "label_fr": "Arkelstorp"
            },
            {
                "value": 44507,
                "label": "Djursholm",
                "label_ar": "دجورشولم",
                "label_fr": "Djursholm"
            },
            {
                "value": 45007,
                "label": "Nyland",
                "label_ar": "نيلاند",
                "label_fr": "Nyland"
            },
            {
                "value": 45238,
                "label": "Boxholm",
                "label_ar": "بوكسهولم",
                "label_fr": "Boxholm"
            },
            {
                "value": 44786,
                "label": "Nasum",
                "label_ar": "ناسوم",
                "label_fr": "Nasum"
            },
            {
                "value": 44811,
                "label": "Sjotofta",
                "label_ar": "سجوتوفتا",
                "label_fr": "Sjotofta"
            },
            {
                "value": 45539,
                "label": "Jude",
                "label_ar": "جود",
                "label_fr": "Jude"
            },
            {
                "value": 44519,
                "label": "Karlskoga",
                "label_ar": "كارلسكوجا",
                "label_fr": "Karlskoga"
            },
            {
                "value": 44624,
                "label": "Fritsla",
                "label_ar": "فريتسلا",
                "label_fr": "Fritsla"
            },
            {
                "value": 44777,
                "label": "Segersta",
                "label_ar": "سيغيرستا",
                "label_fr": "Segersta"
            },
            {
                "value": 44774,
                "label": "Rengsjo",
                "label_ar": "رينجسجو",
                "label_fr": "Rengsjo"
            },
            {
                "value": 44663,
                "label": "Vargon",
                "label_ar": "فارجون",
                "label_fr": "Vargon"
            },
            {
                "value": 44508,
                "label": "Tullinge",
                "label_ar": "تولينج",
                "label_fr": "Tullinge"
            },
            {
                "value": 45305,
                "label": "Dingle",
                "label_ar": "دينجل",
                "label_fr": "Dingle"
            },
            {
                "value": 44470,
                "label": "Svangsta",
                "label_ar": "سفانجستا",
                "label_fr": "Svangsta"
            },
            {
                "value": 45319,
                "label": "Lodose",
                "label_ar": "لودوز",
                "label_fr": "Lodose"
            },
            {
                "value": 44698,
                "label": "Fjalkinge",
                "label_ar": "Fjalkinge",
                "label_fr": "Fjalkinge"
            },
            {
                "value": 44930,
                "label": "Svartsjo",
                "label_ar": "سفارتسجو",
                "label_fr": "Svartsjo"
            },
            {
                "value": 44956,
                "label": "Viskafors",
                "label_ar": "أقنعة",
                "label_fr": "Viskafors"
            },
            {
                "value": 44382,
                "label": "Solna",
                "label_ar": "سولنا",
                "label_fr": "Solna"
            },
            {
                "value": 45568,
                "label": "Gargnas",
                "label_ar": "جارجناس",
                "label_fr": "Gargnas"
            },
            {
                "value": 45564,
                "label": "Ljungsarp",
                "label_ar": "Ljungsarp",
                "label_fr": "Ljungsarp"
            },
            {
                "value": 45501,
                "label": "Arsunda",
                "label_ar": "Arsunda",
                "label_fr": "Arsunda"
            },
            {
                "value": 44694,
                "label": "Sjoebo",
                "label_ar": "سجوبو",
                "label_fr": "Sjoebo"
            },
            {
                "value": 44712,
                "label": "Forslov",
                "label_ar": "فورسلوف",
                "label_fr": "Forslov"
            },
            {
                "value": 44806,
                "label": "Alvesta",
                "label_ar": "الفيستا",
                "label_fr": "Alvesta"
            },
            {
                "value": 45417,
                "label": "Fagered",
                "label_ar": "مقلد",
                "label_fr": "Fagered"
            },
            {
                "value": 44462,
                "label": "Bro",
                "label_ar": "أخ",
                "label_fr": "Bro"
            },
            {
                "value": 44982,
                "label": "Tanumshede",
                "label_ar": "تانومشيد",
                "label_fr": "Tanumshede"
            },
            {
                "value": 44736,
                "label": "Leksand",
                "label_ar": "ليكساند",
                "label_fr": "Leksand"
            },
            {
                "value": 45140,
                "label": "Hemse",
                "label_ar": "همس",
                "label_fr": "Hemse"
            },
            {
                "value": 44415,
                "label": "Graestorp",
                "label_ar": "جرايستورب",
                "label_fr": "Graestorp"
            },
            {
                "value": 44921,
                "label": "Vato",
                "label_ar": "فاتو",
                "label_fr": "Vato"
            },
            {
                "value": 45086,
                "label": "Norrala",
                "label_ar": "نورالا",
                "label_fr": "Norrala"
            },
            {
                "value": 44384,
                "label": "Bromma",
                "label_ar": "بروما",
                "label_fr": "Bromma"
            },
            {
                "value": 44904,
                "label": "Ankarsvik",
                "label_ar": "أنكارسفيك",
                "label_fr": "Ankarsvik"
            },
            {
                "value": 45189,
                "label": "Agnesberg",
                "label_ar": "أغنيسبرج",
                "label_fr": "Agnesberg"
            },
            {
                "value": 44915,
                "label": "Gällivare",
                "label_ar": "جاليفر",
                "label_fr": "Gällivare"
            },
            {
                "value": 44901,
                "label": "Billingsfors",
                "label_ar": "بيلينغسفورس",
                "label_fr": "Billingsfors"
            },
            {
                "value": 44737,
                "label": "Deje",
                "label_ar": "ديجي",
                "label_fr": "Deje"
            },
            {
                "value": 45196,
                "label": "Kumla",
                "label_ar": "كوملا",
                "label_fr": "Kumla"
            },
            {
                "value": 45194,
                "label": "Backefors",
                "label_ar": "الخلفية",
                "label_fr": "Backefors"
            },
            {
                "value": 45450,
                "label": "AElvkarleby",
                "label_ar": "ألفكارليبي",
                "label_fr": "AElvkarleby"
            },
            {
                "value": 44840,
                "label": "Garsnas",
                "label_ar": "جارسناس",
                "label_fr": "Garsnas"
            },
            {
                "value": 44563,
                "label": "Harestad",
                "label_ar": "هارستاد",
                "label_fr": "Harestad"
            },
            {
                "value": 45128,
                "label": "Langasjo",
                "label_ar": "لانجاسجو",
                "label_fr": "Langasjo"
            },
            {
                "value": 45454,
                "label": "Lessebo",
                "label_ar": "ليسبو",
                "label_fr": "Lessebo"
            },
            {
                "value": 44488,
                "label": "Bollebygd",
                "label_ar": "بولبيجد",
                "label_fr": "Bollebygd"
            },
            {
                "value": 45452,
                "label": "Gallstad",
                "label_ar": "غالستاد",
                "label_fr": "Gallstad"
            },
            {
                "value": 44796,
                "label": "Mellbystrand",
                "label_ar": "ميلبيستراند",
                "label_fr": "Mellbystrand"
            },
            {
                "value": 45003,
                "label": "Stoby",
                "label_ar": "ستوبي",
                "label_fr": "Stoby"
            },
            {
                "value": 45256,
                "label": "Edshultshall",
                "label_ar": "Edshultshall",
                "label_fr": "Edshultshall"
            },
            {
                "value": 45607,
                "label": "Herrangen",
                "label_ar": "هيرانجين",
                "label_fr": "Herrangen"
            },
            {
                "value": 44682,
                "label": "Osby",
                "label_ar": "أوسبي",
                "label_fr": "Osby"
            },
            {
                "value": 44927,
                "label": "Gullspang",
                "label_ar": "جولسبانج",
                "label_fr": "Gullspang"
            },
            {
                "value": 44430,
                "label": "Arloev",
                "label_ar": "أرلوف",
                "label_fr": "Arloev"
            },
            {
                "value": 45436,
                "label": "Holmsjo",
                "label_ar": "هولمسجو",
                "label_fr": "Holmsjo"
            },
            {
                "value": 44648,
                "label": "Hunnestad",
                "label_ar": "هونستاد",
                "label_fr": "Hunnestad"
            },
            {
                "value": 45087,
                "label": "Bergvik",
                "label_ar": "بيرجفيك",
                "label_fr": "Bergvik"
            },
            {
                "value": 44593,
                "label": "Hallstahammar",
                "label_ar": "هالستهامر",
                "label_fr": "Hallstahammar"
            },
            {
                "value": 45033,
                "label": "Sjuntorp",
                "label_ar": "سجونتورب",
                "label_fr": "Sjuntorp"
            },
            {
                "value": 45494,
                "label": "Urshult",
                "label_ar": "Urshult",
                "label_fr": "Urshult"
            },
            {
                "value": 44887,
                "label": "OEstervala",
                "label_ar": "OEstervala",
                "label_fr": "OEstervala"
            },
            {
                "value": 44677,
                "label": "Ekeby",
                "label_ar": "إيكيبي",
                "label_fr": "Ekeby"
            },
            {
                "value": 44842,
                "label": "Sankt Olof",
                "label_ar": "سانكت أولوف",
                "label_fr": "Sankt Olof"
            },
            {
                "value": 44566,
                "label": "Bohus",
                "label_ar": "بوهوس",
                "label_fr": "Bohus"
            },
            {
                "value": 45519,
                "label": "Kulltorp",
                "label_ar": "كولتورب",
                "label_fr": "Kulltorp"
            },
            {
                "value": 45367,
                "label": "Vastra Torup",
                "label_ar": "فاسترا توروب",
                "label_fr": "Vastra Torup"
            },
            {
                "value": 45065,
                "label": "Sorsele",
                "label_ar": "سورسيل",
                "label_fr": "Sorsele"
            },
            {
                "value": 44858,
                "label": "Torsas",
                "label_ar": "تورساس",
                "label_fr": "Les torses"
            },
            {
                "value": 45346,
                "label": "Garsas",
                "label_ar": "غارساس",
                "label_fr": "Garsas"
            },
            {
                "value": 44867,
                "label": "Haparanda",
                "label_ar": "هاباراندا",
                "label_fr": "Haparanda"
            },
            {
                "value": 45372,
                "label": "Rydsgard",
                "label_ar": "ريدسجارد",
                "label_fr": "Rydsgard"
            },
            {
                "value": 45187,
                "label": "Lottorp",
                "label_ar": "لوتورب",
                "label_fr": "Lottorp"
            },
            {
                "value": 45038,
                "label": "Storvik",
                "label_ar": "ستورفيك",
                "label_fr": "Storvik"
            },
            {
                "value": 45243,
                "label": "Sodergard",
                "label_ar": "Sodergard",
                "label_fr": "Sodergard"
            },
            {
                "value": 45125,
                "label": "Genarp",
                "label_ar": "جينارب",
                "label_fr": "Genarp"
            },
            {
                "value": 44426,
                "label": "Oskarstrom",
                "label_ar": "أوسكارستروم",
                "label_fr": "Oskarström"
            },
            {
                "value": 44812,
                "label": "Ed",
                "label_ar": "إد",
                "label_fr": "Ed"
            },
            {
                "value": 45268,
                "label": "Kagerod",
                "label_ar": "كاجرود",
                "label_fr": "Kagerod"
            },
            {
                "value": 44475,
                "label": "Borgstena",
                "label_ar": "بورغستينا",
                "label_fr": "Borgstena"
            },
            {
                "value": 45455,
                "label": "Hasselfors",
                "label_ar": "هاسلفورز",
                "label_fr": "Hasselfors"
            },
            {
                "value": 45375,
                "label": "Taljo",
                "label_ar": "تالجو",
                "label_fr": "Taljo"
            },
            {
                "value": 45506,
                "label": "Kallby",
                "label_ar": "كالبي",
                "label_fr": "Kallby"
            },
            {
                "value": 44558,
                "label": "Kristinehamn",
                "label_ar": "كريستينهامن",
                "label_fr": "Kristinehamn"
            },
            {
                "value": 45446,
                "label": "Hokerum",
                "label_ar": "Hokerum",
                "label_fr": "Hokerum"
            },
            {
                "value": 44618,
                "label": "Ängelholm",
                "label_ar": "أنجلهولم",
                "label_fr": "Ängelholm"
            },
            {
                "value": 45426,
                "label": "Arvidsjaur",
                "label_ar": "أرفيدسياور",
                "label_fr": "Arvidsjaur"
            },
            {
                "value": 44398,
                "label": "Varby",
                "label_ar": "فاربي",
                "label_fr": "Varby"
            },
            {
                "value": 44940,
                "label": "Horred",
                "label_ar": "ممنوع",
                "label_fr": "Horrifié"
            },
            {
                "value": 44446,
                "label": "OEdeshoeg",
                "label_ar": "OEdeshoeg",
                "label_fr": "OEdeshoeg"
            },
            {
                "value": 44826,
                "label": "Edsele",
                "label_ar": "إدسيلي",
                "label_fr": "Edsele"
            },
            {
                "value": 45541,
                "label": "Halland",
                "label_ar": "هالاند",
                "label_fr": "Halland"
            },
            {
                "value": 44577,
                "label": "Alafors",
                "label_ar": "الافورس",
                "label_fr": "Alafors"
            },
            {
                "value": 45053,
                "label": "Farila",
                "label_ar": "فاريلا",
                "label_fr": "Farila"
            },
            {
                "value": 45176,
                "label": "Vadstena",
                "label_ar": "فادستينا",
                "label_fr": "Vadstena"
            },
            {
                "value": 44387,
                "label": "Motala",
                "label_ar": "موتالا",
                "label_fr": "Motala"
            },
            {
                "value": 45303,
                "label": "Smoegen",
                "label_ar": "سموجن",
                "label_fr": "Smoegen"
            },
            {
                "value": 45292,
                "label": "Morsil",
                "label_ar": "مرسيل",
                "label_fr": "Morsil"
            },
            {
                "value": 45113,
                "label": "Ljung",
                "label_ar": "ليونغ",
                "label_fr": "Ljung"
            },
            {
                "value": 45058,
                "label": "Vejbystrand",
                "label_ar": "فيجبيستراند",
                "label_fr": "Vejbystrand"
            },
            {
                "value": 45323,
                "label": "Follinge",
                "label_ar": "فولينج",
                "label_fr": "Follinge"
            },
            {
                "value": 45622,
                "label": "Nalden",
                "label_ar": "نالدين",
                "label_fr": "Nalden"
            },
            {
                "value": 45466,
                "label": "Ryssby",
                "label_ar": "ريسبي",
                "label_fr": "Ryssby"
            },
            {
                "value": 45084,
                "label": "Matfors",
                "label_ar": "ماتفورز",
                "label_fr": "Matfors"
            },
            {
                "value": 45386,
                "label": "Vallakra",
                "label_ar": "فالاكرا",
                "label_fr": "Vallakra"
            },
            {
                "value": 44406,
                "label": "Gränna",
                "label_ar": "جرانا",
                "label_fr": "Gränna"
            },
            {
                "value": 45401,
                "label": "Taenndalen",
                "label_ar": "Taenndalen",
                "label_fr": "Taenndalen"
            },
            {
                "value": 45063,
                "label": "Sollebrunn",
                "label_ar": "سولبرون",
                "label_fr": "Sollebrunn"
            },
            {
                "value": 45404,
                "label": "Marieholm",
                "label_ar": "ماريهولم",
                "label_fr": "Marieholm"
            },
            {
                "value": 45110,
                "label": "Soraker",
                "label_ar": "سوراكر",
                "label_fr": "Soraker"
            },
            {
                "value": 44410,
                "label": "Skanninge",
                "label_ar": "سكانينج",
                "label_fr": "Skanninge"
            },
            {
                "value": 45575,
                "label": "Landfjarden",
                "label_ar": "لاندفجاردين",
                "label_fr": "Landfjarden"
            },
            {
                "value": 45338,
                "label": "Skanes Fagerhult",
                "label_ar": "Skanes Fagerhult",
                "label_fr": "Skanes Fagerhult"
            },
            {
                "value": 45620,
                "label": "Kladesholmen",
                "label_ar": "بنجلاديشولمين",
                "label_fr": "Kladesholmen"
            },
            {
                "value": 44693,
                "label": "Asarum",
                "label_ar": "أساروم",
                "label_fr": "Asarum"
            },
            {
                "value": 44591,
                "label": "Holo",
                "label_ar": "هولو",
                "label_fr": "Holo"
            },
            {
                "value": 45177,
                "label": "Bjorklinge",
                "label_ar": "بيوركلينج",
                "label_fr": "Bjorklinge"
            },
            {
                "value": 44801,
                "label": "Hemmingsmark",
                "label_ar": "Hemmingsmark",
                "label_fr": "Hemmingsmark"
            },
            {
                "value": 44695,
                "label": "Svaloev",
                "label_ar": "سفالوف",
                "label_fr": "Svaloev"
            },
            {
                "value": 45467,
                "label": "Vittaryd",
                "label_ar": "فيتارد",
                "label_fr": "Vittaryd"
            },
            {
                "value": 45616,
                "label": "Lotorp",
                "label_ar": "لوتورب",
                "label_fr": "Lotorp"
            },
            {
                "value": 44673,
                "label": "Akarp",
                "label_ar": "أكارب",
                "label_fr": "Akarp"
            },
            {
                "value": 45543,
                "label": "Ansvar",
                "label_ar": "أنسفار",
                "label_fr": "Ansvar"
            },
            {
                "value": 45130,
                "label": "Mockfjard",
                "label_ar": "موكفجارد",
                "label_fr": "Mockfjard"
            },
            {
                "value": 45153,
                "label": "Transtrand",
                "label_ar": "ترانستراند",
                "label_fr": "Transtrand"
            },
            {
                "value": 45562,
                "label": "Farna",
                "label_ar": "فارنا",
                "label_fr": "Farna"
            },
            {
                "value": 45566,
                "label": "Nordingra",
                "label_ar": "نوردجرا",
                "label_fr": "Nordingra"
            },
            {
                "value": 44630,
                "label": "Ransta",
                "label_ar": "رانستا",
                "label_fr": "Ransta"
            },
            {
                "value": 45074,
                "label": "Vindeln",
                "label_ar": "فيندلن",
                "label_fr": "Vindeln"
            },
            {
                "value": 44639,
                "label": "Orsa",
                "label_ar": "أورسا",
                "label_fr": "Orsa"
            },
            {
                "value": 44647,
                "label": "Vinninga",
                "label_ar": "فيننجا",
                "label_fr": "Vinninga"
            },
            {
                "value": 44522,
                "label": "Boden",
                "label_ar": "بودن",
                "label_fr": "Boden"
            },
            {
                "value": 44885,
                "label": "Hammerdal",
                "label_ar": "هامردال",
                "label_fr": "Hammerdal"
            },
            {
                "value": 45173,
                "label": "Tystberga",
                "label_ar": "تيستبرجا",
                "label_fr": "Tystberga"
            },
            {
                "value": 45148,
                "label": "Atran",
                "label_ar": "اتران",
                "label_fr": "Atran"
            },
            {
                "value": 44951,
                "label": "Sosdala",
                "label_ar": "سوسدالا",
                "label_fr": "Sosdala"
            },
            {
                "value": 44632,
                "label": "Jarna",
                "label_ar": "جارنا",
                "label_fr": "Jarna"
            },
            {
                "value": 44463,
                "label": "Enskede-Arsta-Vantoer",
                "label_ar": "Enskede-Arsta-Vantoer",
                "label_fr": "Enskede-Arsta-Vantoer"
            },
            {
                "value": 45101,
                "label": "Odensbacken",
                "label_ar": "Odensbacken",
                "label_fr": "Odensbacken"
            },
            {
                "value": 44871,
                "label": "Genevad",
                "label_ar": "جينيفاد",
                "label_fr": "Genevad"
            },
            {
                "value": 44689,
                "label": "Hässleholm",
                "label_ar": "Hässleholm",
                "label_fr": "Hässleholm"
            },
            {
                "value": 44837,
                "label": "Romelanda",
                "label_ar": "روميلاندا",
                "label_fr": "Romelanda"
            },
            {
                "value": 44866,
                "label": "Markaryd",
                "label_ar": "ماركارد",
                "label_fr": "Markaryd"
            },
            {
                "value": 45524,
                "label": "Rundvik",
                "label_ar": "روندفيك",
                "label_fr": "Rundvik"
            },
            {
                "value": 45499,
                "label": "Fallfors",
                "label_ar": "الشلالات",
                "label_fr": "Fallfors"
            },
            {
                "value": 45078,
                "label": "Stensele",
                "label_ar": "ستينسلي",
                "label_fr": "Stensele"
            },
            {
                "value": 45183,
                "label": "Stalldalen",
                "label_ar": "Stalldalen",
                "label_fr": "Stalldalen"
            },
            {
                "value": 44493,
                "label": "Vimmerby",
                "label_ar": "فيمربي",
                "label_fr": "Vimmerby"
            },
            {
                "value": 45492,
                "label": "Dyltabruk",
                "label_ar": "ديلتبروك",
                "label_fr": "Dyltabruk"
            },
            {
                "value": 45171,
                "label": "Hunnebostrand",
                "label_ar": "هونبوستراند",
                "label_fr": "Hunnebostrand"
            },
            {
                "value": 45495,
                "label": "Konga",
                "label_ar": "كونجا",
                "label_fr": "Konga"
            },
            {
                "value": 44564,
                "label": "Eslov",
                "label_ar": "Eslov",
                "label_fr": "Eslov"
            },
            {
                "value": 44755,
                "label": "Kilafors",
                "label_ar": "كيلافورز",
                "label_fr": "Kilafors"
            },
            {
                "value": 44922,
                "label": "Arholma",
                "label_ar": "أرهولما",
                "label_fr": "Arholma"
            },
            {
                "value": 45370,
                "label": "Klagerup",
                "label_ar": "كلاجيروب",
                "label_fr": "Klagerup"
            },
            {
                "value": 45614,
                "label": "Myggenas",
                "label_ar": "ميغيناس",
                "label_fr": "Myggenas"
            },
            {
                "value": 45098,
                "label": "Toere",
                "label_ar": "تويري",
                "label_fr": "Toere"
            },
            {
                "value": 44791,
                "label": "Kage",
                "label_ar": "كاجي",
                "label_fr": "Kage"
            },
            {
                "value": 44832,
                "label": "Bastad",
                "label_ar": "باستاد",
                "label_fr": "Bastad"
            },
            {
                "value": 45534,
                "label": "Lugnvik",
                "label_ar": "لوغنفيك",
                "label_fr": "Lugnvik"
            },
            {
                "value": 45465,
                "label": "Aryd",
                "label_ar": "اريد",
                "label_fr": "Aryd"
            },
            {
                "value": 44914,
                "label": "Vinberg",
                "label_ar": "فينبرغ",
                "label_fr": "Vinberg"
            },
            {
                "value": 45601,
                "label": "Hamrangefjarden",
                "label_ar": "هامرانجفجاردين",
                "label_fr": "Hamrangefjarden"
            },
            {
                "value": 44905,
                "label": "Arvika",
                "label_ar": "أرفيكا",
                "label_fr": "Arvika"
            },
            {
                "value": 45308,
                "label": "Strovelstorp",
                "label_ar": "ستروفلستورب",
                "label_fr": "Strovelstorp"
            },
            {
                "value": 44691,
                "label": "Vinslov",
                "label_ar": "فينسلوف",
                "label_fr": "Vinslov"
            },
            {
                "value": 44990,
                "label": "Skoghall",
                "label_ar": "Skoghall",
                "label_fr": "Skoghall"
            },
            {
                "value": 45269,
                "label": "Nyvang",
                "label_ar": "نيفانغ",
                "label_fr": "Nyvang"
            },
            {
                "value": 44822,
                "label": "Agunnaryd",
                "label_ar": "Agunnaryd",
                "label_fr": "Agunnaryd"
            },
            {
                "value": 45107,
                "label": "Gnarp",
                "label_ar": "غنارب",
                "label_fr": "Gnarp"
            },
            {
                "value": 44390,
                "label": "Borås",
                "label_ar": "بوراس",
                "label_fr": "Borås"
            },
            {
                "value": 45178,
                "label": "Overum",
                "label_ar": "أوفروم",
                "label_fr": "Overum"
            },
            {
                "value": 44670,
                "label": "Kristianstad",
                "label_ar": "كريستيانستاد",
                "label_fr": "Kristianstad"
            },
            {
                "value": 44453,
                "label": "Rockneby",
                "label_ar": "روكنيبي",
                "label_fr": "Rockneby"
            },
            {
                "value": 45208,
                "label": "Ranea",
                "label_ar": "رانيا",
                "label_fr": "Ranea"
            },
            {
                "value": 45233,
                "label": "Gards Kopinge",
                "label_ar": "جاردس كوبينج",
                "label_fr": "Gards Kopinge"
            },
            {
                "value": 44917,
                "label": "Valskog",
                "label_ar": "فالسكوغ",
                "label_fr": "Valskog"
            },
            {
                "value": 45314,
                "label": "Lima",
                "label_ar": "ليما",
                "label_fr": "Lima"
            },
            {
                "value": 44548,
                "label": "Backa",
                "label_ar": "باكا",
                "label_fr": "Backa"
            },
            {
                "value": 45073,
                "label": "Hogsjo",
                "label_ar": "هوجسجو",
                "label_fr": "Hogsjo"
            },
            {
                "value": 45480,
                "label": "Boliden",
                "label_ar": "بوليدين",
                "label_fr": "Boliden"
            },
            {
                "value": 44845,
                "label": "Vitaby",
                "label_ar": "فيتابي",
                "label_fr": "Vitaby"
            },
            {
                "value": 45253,
                "label": "Hortlax",
                "label_ar": "هورتلاكس",
                "label_fr": "Hortlax"
            },
            {
                "value": 45001,
                "label": "Haknas",
                "label_ar": "هاكناس",
                "label_fr": "Haknas"
            },
            {
                "value": 44974,
                "label": "Hjaerup",
                "label_ar": "هجيروب",
                "label_fr": "Hjaerup"
            },
            {
                "value": 44603,
                "label": "Skarpnaeck",
                "label_ar": "سكاربنيك",
                "label_fr": "Skarpnaeck"
            },
            {
                "value": 44793,
                "label": "Burtraesk",
                "label_ar": "بيرتريسك",
                "label_fr": "Burtraesk"
            },
            {
                "value": 45188,
                "label": "Norberg",
                "label_ar": "نوربيرج",
                "label_fr": "Norberg"
            },
            {
                "value": 44804,
                "label": "Tvaaker",
                "label_ar": "تفاكير",
                "label_fr": "Tvaaker"
            },
            {
                "value": 44605,
                "label": "Johanneshov",
                "label_ar": "يوهانشوف",
                "label_fr": "Johanneshov"
            },
            {
                "value": 44843,
                "label": "Hammenhog",
                "label_ar": "هامنهوج",
                "label_fr": "Hammenhog"
            },
            {
                "value": 44407,
                "label": "Trollhattan",
                "label_ar": "ترولهاتان",
                "label_fr": "Trollhattan"
            },
            {
                "value": 45508,
                "label": "Gryt",
                "label_ar": "جريت",
                "label_fr": "Gryt"
            },
            {
                "value": 44374,
                "label": "Uppsala",
                "label_ar": "أوبسالا",
                "label_fr": "Uppsala"
            },
            {
                "value": 45182,
                "label": "Faro",
                "label_ar": "فارو",
                "label_fr": "Faro"
            },
            {
                "value": 45523,
                "label": "Billinge",
                "label_ar": "الفواتير",
                "label_fr": "Billinge"
            },
            {
                "value": 44553,
                "label": "Rotebro",
                "label_ar": "روتبرو",
                "label_fr": "Rotebro"
            },
            {
                "value": 45527,
                "label": "Sparsor",
                "label_ar": "سبارسور",
                "label_fr": "Sparsor"
            },
            {
                "value": 45132,
                "label": "Grangesberg",
                "label_ar": "جرانجسبرج",
                "label_fr": "Grangesberg"
            },
            {
                "value": 45231,
                "label": "Hedared",
                "label_ar": "حيدارد",
                "label_fr": "Hedared"
            },
            {
                "value": 44468,
                "label": "Skövde",
                "label_ar": "سكوفده",
                "label_fr": "Skövde"
            },
            {
                "value": 44970,
                "label": "Saeffle",
                "label_ar": "سيفل",
                "label_fr": "Saeffle"
            },
            {
                "value": 45278,
                "label": "Bockara",
                "label_ar": "بوكارا",
                "label_fr": "Bockara"
            },
            {
                "value": 45584,
                "label": "Stockaryd",
                "label_ar": "ستوكارد",
                "label_fr": "Stockaryd"
            },
            {
                "value": 44964,
                "label": "Edsbyn",
                "label_ar": "إدسبين",
                "label_fr": "Edsbyn"
            },
            {
                "value": 45374,
                "label": "Musko",
                "label_ar": "موسكو",
                "label_fr": "Musko"
            },
            {
                "value": 44628,
                "label": "Katrineholm",
                "label_ar": "كاترينهولم",
                "label_fr": "Katrineholm"
            },
            {
                "value": 44615,
                "label": "Trangsund",
                "label_ar": "ترانجسوند",
                "label_fr": "Trangsund"
            },
            {
                "value": 44809,
                "label": "Svenljunga",
                "label_ar": "سفينلجونجا",
                "label_fr": "Svenljunga"
            },
            {
                "value": 44412,
                "label": "Ekerö",
                "label_ar": "إيكيرو",
                "label_fr": "Ekerö"
            },
            {
                "value": 44514,
                "label": "Karlstad",
                "label_ar": "كارلستاد",
                "label_fr": "Karlstad"
            },
            {
                "value": 45496,
                "label": "Sodra Vi",
                "label_ar": "سودرا السادس",
                "label_fr": "Sodra Vi"
            },
            {
                "value": 45018,
                "label": "Sidensjo",
                "label_ar": "Sidensjo",
                "label_fr": "Sidensjo"
            },
            {
                "value": 45354,
                "label": "Ugglarp",
                "label_ar": "أوغجلارب",
                "label_fr": "Ugglarp"
            },
            {
                "value": 45522,
                "label": "Lovanger",
                "label_ar": "لوفانجر",
                "label_fr": "Lovanger"
            },
            {
                "value": 45548,
                "label": "Talje",
                "label_ar": "تالج",
                "label_fr": "Talje"
            },
            {
                "value": 44606,
                "label": "Brottby",
                "label_ar": "بروتبي",
                "label_fr": "Brottby"
            },
            {
                "value": 45168,
                "label": "Ostra Hindalebyn",
                "label_ar": "أوسترا هيندالبين",
                "label_fr": "Ostra Hindalebyn"
            },
            {
                "value": 44710,
                "label": "Vastra Ramlosa",
                "label_ar": "فاسترا راملوسا",
                "label_fr": "Vastra Ramlosa"
            },
            {
                "value": 44748,
                "label": "Hagfors",
                "label_ar": "هاغفور",
                "label_fr": "Hagfors"
            },
            {
                "value": 44910,
                "label": "Gusselby",
                "label_ar": "جوسيلبي",
                "label_fr": "Gusselby"
            },
            {
                "value": 44552,
                "label": "Saevsjoe",
                "label_ar": "Saevsjoe",
                "label_fr": "Saevsjoe"
            },
            {
                "value": 44975,
                "label": "Kopparberg",
                "label_ar": "كوباربرغ",
                "label_fr": "Kopparberg"
            },
            {
                "value": 45464,
                "label": "Lammhult",
                "label_ar": "لامهولت",
                "label_fr": "Lammhult"
            },
            {
                "value": 44525,
                "label": "Varsta",
                "label_ar": "فارستا",
                "label_fr": "Varsta"
            },
            {
                "value": 44634,
                "label": "Jokkmokk",
                "label_ar": "جوكموك",
                "label_fr": "Jokkmokk"
            },
            {
                "value": 45055,
                "label": "Tibro",
                "label_ar": "تيبرو",
                "label_fr": "Tibro"
            },
            {
                "value": 45352,
                "label": "Sundborn",
                "label_ar": "سوندبورن",
                "label_fr": "Sundborn"
            },
            {
                "value": 44479,
                "label": "Sölvesborg",
                "label_ar": "Sölvesborg",
                "label_fr": "Sölvesborg"
            },
            {
                "value": 44701,
                "label": "Listerby",
                "label_ar": "ليستربي",
                "label_fr": "Listerby"
            },
            {
                "value": 44854,
                "label": "Jorlanda",
                "label_ar": "يورلاندا",
                "label_fr": "Jorlanda"
            },
            {
                "value": 44441,
                "label": "Svedala",
                "label_ar": "سفيدالا",
                "label_fr": "Svedala"
            },
            {
                "value": 44617,
                "label": "Timra",
                "label_ar": "تيمرا",
                "label_fr": "Timra"
            },
            {
                "value": 44534,
                "label": "Stenhamra",
                "label_ar": "ستينهامرا",
                "label_fr": "Stenhamra"
            },
            {
                "value": 44464,
                "label": "Laxa",
                "label_ar": "لاكسا",
                "label_fr": "Laxa"
            },
            {
                "value": 45514,
                "label": "Bredaryd",
                "label_ar": "بريدارد",
                "label_fr": "Bredaryd"
            },
            {
                "value": 44640,
                "label": "Raettvik",
                "label_ar": "رايتفيك",
                "label_fr": "Raettvik"
            },
            {
                "value": 44594,
                "label": "Akers Styckebruk",
                "label_ar": "أكيرز ستيكيبروك",
                "label_fr": "Akers Styckebruk"
            },
            {
                "value": 45576,
                "label": "Bor",
                "label_ar": "بور",
                "label_fr": "Bor"
            },
            {
                "value": 45240,
                "label": "Mellansel",
                "label_ar": "ميلانسل",
                "label_fr": "Mellansel"
            },
            {
                "value": 44750,
                "label": "Henan",
                "label_ar": "حنان",
                "label_fr": "Henan"
            },
            {
                "value": 44933,
                "label": "Valla",
                "label_ar": "فالا",
                "label_fr": "Valla"
            },
            {
                "value": 45161,
                "label": "Hammarstrand",
                "label_ar": "هامرستراند",
                "label_fr": "Hammarstrand"
            },
            {
                "value": 44659,
                "label": "Askim",
                "label_ar": "اسكيم",
                "label_fr": "Askim"
            },
            {
                "value": 45400,
                "label": "Blasmark",
                "label_ar": "بلاسمارك",
                "label_fr": "Blasmark"
            },
            {
                "value": 44497,
                "label": "Stroemstad",
                "label_ar": "سترومستاد",
                "label_fr": "Stroemstad"
            },
            {
                "value": 44428,
                "label": "Södertälje",
                "label_ar": "سودرتاليا",
                "label_fr": "Södertälje"
            },
            {
                "value": 45321,
                "label": "Joern",
                "label_ar": "جويرن",
                "label_fr": "Joern"
            },
            {
                "value": 45050,
                "label": "Hedesunda",
                "label_ar": "هيديسوندا",
                "label_fr": "Hedesunda"
            },
            {
                "value": 45180,
                "label": "Forsa",
                "label_ar": "فرصة",
                "label_fr": "Forsa"
            },
            {
                "value": 44452,
                "label": "Kalmar",
                "label_ar": "كالمار",
                "label_fr": "Kalmar"
            },
            {
                "value": 44404,
                "label": "Rimbo",
                "label_ar": "ريمبو",
                "label_fr": "Rimbo"
            },
            {
                "value": 44560,
                "label": "Kallered",
                "label_ar": "كاليرد",
                "label_fr": "Kallered"
            },
            {
                "value": 44635,
                "label": "Grums",
                "label_ar": "جرومس",
                "label_fr": "Grums"
            },
            {
                "value": 45606,
                "label": "Karl Gustav",
                "label_ar": "كارل جوستاف",
                "label_fr": "Karl Gustav"
            },
            {
                "value": 44808,
                "label": "Molltorp",
                "label_ar": "مولتورب",
                "label_fr": "Molltorp"
            },
            {
                "value": 45612,
                "label": "Vastervik",
                "label_ar": "فاسترفيك",
                "label_fr": "Vastervik"
            },
            {
                "value": 45249,
                "label": "Frufallan",
                "label_ar": "فروفالان",
                "label_fr": "Frufallan"
            },
            {
                "value": 45491,
                "label": "Skattkarr",
                "label_ar": "سكاتكر",
                "label_fr": "Skattkarr"
            },
            {
                "value": 44757,
                "label": "Lanna",
                "label_ar": "لانا",
                "label_fr": "Lanna"
            },
            {
                "value": 44718,
                "label": "Taberg",
                "label_ar": "تابيرج",
                "label_fr": "Taberg"
            },
            {
                "value": 44723,
                "label": "Faerjestaden",
                "label_ar": "فرجستادن",
                "label_fr": "Faerjestaden"
            },
            {
                "value": 44385,
                "label": "Vallentuna",
                "label_ar": "فالينتونا",
                "label_fr": "Vallentuna"
            },
            {
                "value": 45423,
                "label": "Ravlanda",
                "label_ar": "رافلاندا",
                "label_fr": "Ravlanda"
            },
            {
                "value": 44946,
                "label": "Broby",
                "label_ar": "بروبي",
                "label_fr": "Broby"
            },
            {
                "value": 45112,
                "label": "Bredared",
                "label_ar": "ولدت",
                "label_fr": "Bredared"
            },
            {
                "value": 45380,
                "label": "Kattilstorp",
                "label_ar": "كاتيلستورب",
                "label_fr": "Kattilstorp"
            },
            {
                "value": 44829,
                "label": "Arjeplog",
                "label_ar": "Arjeplog",
                "label_fr": "Arjeplog"
            },
            {
                "value": 45507,
                "label": "Njutanger",
                "label_ar": "نجوتانجر",
                "label_fr": "Njutanger"
            },
            {
                "value": 45544,
                "label": "Hagglund",
                "label_ar": "هاغلوند",
                "label_fr": "Hagglund"
            },
            {
                "value": 45538,
                "label": "Fleninge",
                "label_ar": "Fleninge",
                "label_fr": "Fleninge"
            },
            {
                "value": 45407,
                "label": "Skane",
                "label_ar": "سكين",
                "label_fr": "Skane"
            },
            {
                "value": 44991,
                "label": "Marma",
                "label_ar": "مارما",
                "label_fr": "Marma"
            },
            {
                "value": 45088,
                "label": "Stratjara",
                "label_ar": "ستراتجارا",
                "label_fr": "Stratjara"
            },
            {
                "value": 44409,
                "label": "Nodinge-Nol",
                "label_ar": "Nodinge-Nol",
                "label_fr": "Nodinge-Nol"
            },
            {
                "value": 44554,
                "label": "Kaevlinge",
                "label_ar": "كيفلينج",
                "label_fr": "Kaevlinge"
            },
            {
                "value": 44859,
                "label": "Smalandsstenar",
                "label_ar": "Smalandsstenar",
                "label_fr": "Smalandsstenar"
            },
            {
                "value": 45163,
                "label": "Sjovik",
                "label_ar": "سيجوفيك",
                "label_fr": "Sjovik"
            },
            {
                "value": 44638,
                "label": "Surte",
                "label_ar": "سورتي",
                "label_fr": "Surte"
            },
            {
                "value": 44978,
                "label": "Bankeryd",
                "label_ar": "بانكريد",
                "label_fr": "Bankeryd"
            },
            {
                "value": 45470,
                "label": "Timmele",
                "label_ar": "تيميلي",
                "label_fr": "Timmele"
            },
            {
                "value": 45366,
                "label": "Nissafors",
                "label_ar": "نيسافورز",
                "label_fr": "Nissafors"
            },
            {
                "value": 45082,
                "label": "Lavsjo",
                "label_ar": "لافسجو",
                "label_fr": "Lavsjo"
            },
            {
                "value": 45115,
                "label": "Unnaryd",
                "label_ar": "أناريد",
                "label_fr": "Unnaryd"
            },
            {
                "value": 45031,
                "label": "Ljungsbro",
                "label_ar": "Ljungsbro",
                "label_fr": "Ljungsbro"
            },
            {
                "value": 44513,
                "label": "Nynas",
                "label_ar": "نينس",
                "label_fr": "Nynas"
            },
            {
                "value": 45197,
                "label": "Uddebo",
                "label_ar": "أوديبو",
                "label_fr": "Uddebo"
            },
            {
                "value": 45245,
                "label": "Kosta",
                "label_ar": "كوستا",
                "label_fr": "Kosta"
            },
            {
                "value": 44891,
                "label": "Kisa",
                "label_ar": "كيزا",
                "label_fr": "Kisa"
            },
            {
                "value": 44846,
                "label": "Kopingebro",
                "label_ar": "كوبينجيبرو",
                "label_fr": "Kopingebro"
            },
            {
                "value": 44544,
                "label": "Aby",
                "label_ar": "أبي",
                "label_fr": "Aby"
            },
            {
                "value": 44613,
                "label": "Furulund",
                "label_ar": "فورولوند",
                "label_fr": "Furulund"
            },
            {
                "value": 45449,
                "label": "Olsfors",
                "label_ar": "أولسفورس",
                "label_fr": "Olsfors"
            },
            {
                "value": 44941,
                "label": "Kinna",
                "label_ar": "كينا",
                "label_fr": "Kinna"
            },
            {
                "value": 44872,
                "label": "Munka-Ljungby",
                "label_ar": "مونكا ليونجبي",
                "label_fr": "Munka-Ljungby"
            },
            {
                "value": 44653,
                "label": "Skene",
                "label_ar": "سكين",
                "label_fr": "Skene"
            },
            {
                "value": 45357,
                "label": "Valadalen",
                "label_ar": "فالادالين",
                "label_fr": "Valadalen"
            },
            {
                "value": 44882,
                "label": "Johannishus",
                "label_ar": "يوهاننيشوس",
                "label_fr": "Johannishus"
            },
            {
                "value": 44807,
                "label": "Karlsborg",
                "label_ar": "كارلسبرج",
                "label_fr": "Karlsborg"
            },
            {
                "value": 44925,
                "label": "Rekarne",
                "label_ar": "ريكارن",
                "label_fr": "Rekarne"
            },
            {
                "value": 44510,
                "label": "Rydeback",
                "label_ar": "ريديباك",
                "label_fr": "Rydeback"
            },
            {
                "value": 45484,
                "label": "Aspero",
                "label_ar": "اسبيرو",
                "label_fr": "Aspero"
            },
            {
                "value": 44824,
                "label": "Glommen",
                "label_ar": "غلومين",
                "label_fr": "Glommen"
            },
            {
                "value": 44562,
                "label": "Kareby",
                "label_ar": "كاريبي",
                "label_fr": "Kareby"
            },
            {
                "value": 44570,
                "label": "Skellefteå",
                "label_ar": "سكيلفتيا",
                "label_fr": "Skellefteå"
            },
            {
                "value": 45573,
                "label": "Beddinge Lage",
                "label_ar": "Beddinge Lage",
                "label_fr": "Beddinge Lage"
            },
            {
                "value": 45567,
                "label": "Hyssna",
                "label_ar": "حسنا",
                "label_fr": "Hyssna"
            },
            {
                "value": 45294,
                "label": "Ramnas",
                "label_ar": "رامناس",
                "label_fr": "Ramnas"
            },
            {
                "value": 44967,
                "label": "Atvidaberg",
                "label_ar": "أتفيدابيرج",
                "label_fr": "Atvidaberg"
            },
            {
                "value": 45415,
                "label": "Stigtomta",
                "label_ar": "ستيجتومتا",
                "label_fr": "Stigtomta"
            },
            {
                "value": 45092,
                "label": "Backaryd",
                "label_ar": "باكارد",
                "label_fr": "Backaryd"
            },
            {
                "value": 45143,
                "label": "Ostansjo",
                "label_ar": "أوستانسجو",
                "label_fr": "Ostansjo"
            },
            {
                "value": 44533,
                "label": "Strangnas",
                "label_ar": "Strangnas",
                "label_fr": "Strangnas"
            },
            {
                "value": 44528,
                "label": "Hallingsjo",
                "label_ar": "Hallingsjo",
                "label_fr": "Hallingsjo"
            },
            {
                "value": 44373,
                "label": "Stockholm",
                "label_ar": "ستوكهولم",
                "label_fr": "Stockholm"
            },
            {
                "value": 45430,
                "label": "Korskrogen",
                "label_ar": "كورسكروجين",
                "label_fr": "Korskrogen"
            },
            {
                "value": 45517,
                "label": "Orviken",
                "label_ar": "أورفيكن",
                "label_fr": "Orviken"
            },
            {
                "value": 45408,
                "label": "Vollsjo",
                "label_ar": "فولسجو",
                "label_fr": "Vollsjo"
            },
            {
                "value": 45381,
                "label": "Backe",
                "label_ar": "باك",
                "label_fr": "Backe"
            },
            {
                "value": 44469,
                "label": "Vaddo",
                "label_ar": "فادو",
                "label_fr": "Vaddo"
            },
            {
                "value": 45394,
                "label": "Soderby",
                "label_ar": "سودربي",
                "label_fr": "Soderby"
            },
            {
                "value": 44681,
                "label": "Kattarp",
                "label_ar": "قطارة",
                "label_fr": "Kattarp"
            },
            {
                "value": 44734,
                "label": "OEsthammar",
                "label_ar": "اسثامر",
                "label_fr": "OEsthammar"
            },
            {
                "value": 44676,
                "label": "Tyringe",
                "label_ar": "تيرينج",
                "label_fr": "Tyringe"
            },
            {
                "value": 44625,
                "label": "Skogas",
                "label_ar": "Skogas",
                "label_fr": "Skogas"
            },
            {
                "value": 44472,
                "label": "Skanor med Falsterbo",
                "label_ar": "سكانور ميد فالستربو",
                "label_fr": "Skanor med Falsterbo"
            },
            {
                "value": 45157,
                "label": "Linneryd",
                "label_ar": "لينيرد",
                "label_fr": "Linneryd"
            },
            {
                "value": 44780,
                "label": "Balsta",
                "label_ar": "بالستا",
                "label_fr": "Balsta"
            },
            {
                "value": 44414,
                "label": "Malma",
                "label_ar": "مالما",
                "label_fr": "Malma"
            },
            {
                "value": 45359,
                "label": "Hillerstorp",
                "label_ar": "هيلرستورب",
                "label_fr": "Hillerstorp"
            },
            {
                "value": 45162,
                "label": "Braecke",
                "label_ar": "بريك",
                "label_fr": "Braecke"
            },
            {
                "value": 45468,
                "label": "Svenshogen",
                "label_ar": "سفينشوجين",
                "label_fr": "Svenshogen"
            },
            {
                "value": 45021,
                "label": "Klintehamn",
                "label_ar": "كلينتهامن",
                "label_fr": "Klintehamn"
            },
            {
                "value": 45230,
                "label": "Larv",
                "label_ar": "يرقات",
                "label_fr": "Larv"
            },
            {
                "value": 45057,
                "label": "Ostra Karup",
                "label_ar": "اوسترا كاروب",
                "label_fr": "Ostra Karup"
            },
            {
                "value": 44722,
                "label": "Mullsjoe",
                "label_ar": "مولسجو",
                "label_fr": "Mullsjoe"
            },
            {
                "value": 44652,
                "label": "Langas",
                "label_ar": "لانجاس",
                "label_fr": "Langas"
            },
            {
                "value": 45572,
                "label": "Svarta",
                "label_ar": "سفارتا",
                "label_fr": "Svarta"
            },
            {
                "value": 44839,
                "label": "Soederkoeping",
                "label_ar": "Soederkoeping",
                "label_fr": "Soederkoeping"
            },
            {
                "value": 44918,
                "label": "Nashulta",
                "label_ar": "ناشولتا",
                "label_fr": "Nashulta"
            },
            {
                "value": 44565,
                "label": "Kode",
                "label_ar": "كود",
                "label_fr": "Kode"
            },
            {
                "value": 45108,
                "label": "Jarbo",
                "label_ar": "جربو",
                "label_fr": "Jarbo"
            },
            {
                "value": 45106,
                "label": "Gronskara",
                "label_ar": "جرونسكارا",
                "label_fr": "Gronskara"
            },
            {
                "value": 44383,
                "label": "OErkelljunga",
                "label_ar": "OErkelljunga",
                "label_fr": "OErkelljunga"
            },
            {
                "value": 45574,
                "label": "Hjartum",
                "label_ar": "هجارتوم",
                "label_fr": "Hjartum"
            },
            {
                "value": 44611,
                "label": "Bodafors",
                "label_ar": "بودافورز",
                "label_fr": "Bodafors"
            },
            {
                "value": 45557,
                "label": "Hakkas",
                "label_ar": "هكاس",
                "label_fr": "Hakkas"
            },
            {
                "value": 44643,
                "label": "Landskrona",
                "label_ar": "لاندسكرونا",
                "label_fr": "Landskrona"
            },
            {
                "value": 44438,
                "label": "Upplands Vasby",
                "label_ar": "Upplands Vasby",
                "label_fr": "Upplands Vasby"
            },
            {
                "value": 45155,
                "label": "Satila",
                "label_ar": "ساتيلا",
                "label_fr": "Satila"
            },
            {
                "value": 45316,
                "label": "Ludvigsborg",
                "label_ar": "لودفيغسبورج",
                "label_fr": "Ludvigsborg"
            },
            {
                "value": 45350,
                "label": "Vartofta",
                "label_ar": "فارتوفتا",
                "label_fr": "Vartofta"
            },
            {
                "value": 45089,
                "label": "Holmsveden",
                "label_ar": "هولمسفيدن",
                "label_fr": "Holmsveden"
            },
            {
                "value": 44520,
                "label": "Skallinge",
                "label_ar": "سكالينجي",
                "label_fr": "Skallinge"
            },
            {
                "value": 44573,
                "label": "Ornskoldsvik",
                "label_ar": "Ornskoldsvik",
                "label_fr": "Ornskoldsvik"
            },
            {
                "value": 45099,
                "label": "Norrahammar",
                "label_ar": "نوراهامار",
                "label_fr": "Norrahammar"
            },
            {
                "value": 45059,
                "label": "Vastra Karup",
                "label_ar": "فاسترا كاروب",
                "label_fr": "Vastra Karup"
            },
            {
                "value": 44436,
                "label": "Hasselby",
                "label_ar": "هاسيلبي",
                "label_fr": "Hasselby"
            },
            {
                "value": 45285,
                "label": "Vase",
                "label_ar": "مزهرية",
                "label_fr": "Vase"
            },
            {
                "value": 45599,
                "label": "Nykroppa",
                "label_ar": "نيكروبا",
                "label_fr": "Nykroppa"
            },
            {
                "value": 45102,
                "label": "Trekanten",
                "label_ar": "تريكانتن",
                "label_fr": "Trekanten"
            },
            {
                "value": 44692,
                "label": "Klippan",
                "label_ar": "كليبان",
                "label_fr": "Klippan"
            },
            {
                "value": 45131,
                "label": "Farlov",
                "label_ar": "فارلوف",
                "label_fr": "Farlov"
            },
            {
                "value": 44775,
                "label": "Vallsta",
                "label_ar": "فالستا",
                "label_fr": "Vallsta"
            },
            {
                "value": 45555,
                "label": "Altersbruk",
                "label_ar": "الترسبروك",
                "label_fr": "Altersbruk"
            },
            {
                "value": 45139,
                "label": "Enanger",
                "label_ar": "Enanger",
                "label_fr": "Enanger"
            },
            {
                "value": 44586,
                "label": "Vendelso",
                "label_ar": "فينديلسو",
                "label_fr": "Vendelso"
            },
            {
                "value": 44399,
                "label": "Forsby",
                "label_ar": "فورسبي",
                "label_fr": "Forsby"
            },
            {
                "value": 44557,
                "label": "Gemla",
                "label_ar": "جيملا",
                "label_fr": "Gemla"
            },
            {
                "value": 45389,
                "label": "Ringarum",
                "label_ar": "رينجاروم",
                "label_fr": "Ringarum"
            },
            {
                "value": 44713,
                "label": "Obbola",
                "label_ar": "أوبولا",
                "label_fr": "Obbola"
            },
            {
                "value": 45280,
                "label": "Lidhult",
                "label_ar": "ليدهولت",
                "label_fr": "Lidhult"
            },
            {
                "value": 45442,
                "label": "Tolg",
                "label_ar": "تولج",
                "label_fr": "Tolg"
            },
            {
                "value": 45167,
                "label": "Gamleby",
                "label_ar": "جامليبي",
                "label_fr": "Gamleby"
            },
            {
                "value": 45011,
                "label": "Gidea",
                "label_ar": "جيديا",
                "label_fr": "Gidea"
            },
            {
                "value": 44938,
                "label": "Grimstorp",
                "label_ar": "جريمستورب",
                "label_fr": "Grimstorp"
            },
            {
                "value": 44865,
                "label": "Bunkeflostrand",
                "label_ar": "Bunkeflostrand",
                "label_fr": "Bunkeflostrand"
            },
            {
                "value": 44501,
                "label": "Falkenberg",
                "label_ar": "فالكنبرج",
                "label_fr": "Falkenberg"
            },
            {
                "value": 45458,
                "label": "Odeborg",
                "label_ar": "أودبورغ",
                "label_fr": "Odeborg"
            },
            {
                "value": 44506,
                "label": "Gävle",
                "label_ar": "جافل",
                "label_fr": "Gävle"
            },
            {
                "value": 44585,
                "label": "Krylbo",
                "label_ar": "كريلبو",
                "label_fr": "Krylbo"
            },
            {
                "value": 45482,
                "label": "Lenhovda",
                "label_ar": "لينهوفدا",
                "label_fr": "Lenhovda"
            },
            {
                "value": 45077,
                "label": "Norsjoe",
                "label_ar": "نورسجو",
                "label_fr": "Norsjoe"
            },
            {
                "value": 44800,
                "label": "Ojebyn",
                "label_ar": "أوجيبين",
                "label_fr": "Ojebyn"
            },
            {
                "value": 44690,
                "label": "Smedstorp",
                "label_ar": "سميدستورب",
                "label_fr": "Smedstorp"
            },
            {
                "value": 44931,
                "label": "Malmkoping",
                "label_ar": "مالمكوبينج",
                "label_fr": "Malmkoping"
            },
            {
                "value": 45255,
                "label": "Vintrie",
                "label_ar": "فينتري",
                "label_fr": "Vintrie"
            },
            {
                "value": 45361,
                "label": "Norrhult",
                "label_ar": "نورهولت",
                "label_fr": "Norrhult"
            },
            {
                "value": 45254,
                "label": "Jarpen",
                "label_ar": "جاربين",
                "label_fr": "Jarpen"
            },
            {
                "value": 44703,
                "label": "Ystad",
                "label_ar": "يستاد",
                "label_fr": "Ystad"
            },
            {
                "value": 45075,
                "label": "Sandarne",
                "label_ar": "ساندرن",
                "label_fr": "Sandarne"
            },
            {
                "value": 45104,
                "label": "Garphyttan",
                "label_ar": "غارفيتان",
                "label_fr": "Garphyttan"
            },
            {
                "value": 44857,
                "label": "Jaemjoe",
                "label_ar": "Jaemjoe",
                "label_fr": "Jaemjoe"
            },
            {
                "value": 44952,
                "label": "Vittsjo",
                "label_ar": "فيتسجو",
                "label_fr": "Vittsjo"
            },
            {
                "value": 44380,
                "label": "Vaellingby",
                "label_ar": "فيلنجبي",
                "label_fr": "Vaellingby"
            },
            {
                "value": 45528,
                "label": "Traslovslage",
                "label_ar": "تراسلوفسلج",
                "label_fr": "Traslovslage"
            },
            {
                "value": 44792,
                "label": "Ursviken",
                "label_ar": "أورسفيكن",
                "label_fr": "Ursviken"
            },
            {
                "value": 45623,
                "label": "Koskullskulle",
                "label_ar": "كوسكولسكول",
                "label_fr": "Koskullskulle"
            },
            {
                "value": 44868,
                "label": "Vaxtorp",
                "label_ar": "فاكستورب",
                "label_fr": "Vaxtorp"
            },
            {
                "value": 45448,
                "label": "Holsljunga",
                "label_ar": "هولسلجونجا",
                "label_fr": "Holsljunga"
            },
            {
                "value": 44665,
                "label": "Varobacka",
                "label_ar": "فاروباكا",
                "label_fr": "Varobacka"
            },
            {
                "value": 44799,
                "label": "Vaennaes",
                "label_ar": "فاينيس",
                "label_fr": "Vaennaes"
            },
            {
                "value": 44968,
                "label": "Vikingstad",
                "label_ar": "فايكنغستاد",
                "label_fr": "Vikingstad"
            },
            {
                "value": 45479,
                "label": "Navekvarn",
                "label_ar": "نافيكفارن",
                "label_fr": "Navekvarn"
            },
            {
                "value": 45227,
                "label": "Glanshammar",
                "label_ar": "جلان شمار",
                "label_fr": "Glanshammar"
            },
            {
                "value": 44987,
                "label": "AElvdalen",
                "label_ar": "إلفدالين",
                "label_fr": "AElvdalen"
            },
            {
                "value": 45485,
                "label": "As",
                "label_ar": "مثل",
                "label_fr": "Comme"
            },
            {
                "value": 45425,
                "label": "Furuvik",
                "label_ar": "فوروفيك",
                "label_fr": "Furuvik"
            },
            {
                "value": 45202,
                "label": "Nyhammar",
                "label_ar": "نيهمار",
                "label_fr": "Nyhammar"
            },
            {
                "value": 45010,
                "label": "Mjallom",
                "label_ar": "مجلوم",
                "label_fr": "Mjallom"
            },
            {
                "value": 44715,
                "label": "Bygdsiljum",
                "label_ar": "Bygdsiljum",
                "label_fr": "Bygdsiljum"
            },
            {
                "value": 45411,
                "label": "Ulrika",
                "label_ar": "أولريكا",
                "label_fr": "Ulrika"
            },
            {
                "value": 45416,
                "label": "Hjalteby",
                "label_ar": "حجلتيبي",
                "label_fr": "Hjalteby"
            },
            {
                "value": 45009,
                "label": "Kopmanholmen",
                "label_ar": "كوبمانهولمين",
                "label_fr": "Kopmanholmen"
            },
            {
                "value": 44740,
                "label": "Forshaga",
                "label_ar": "فورشاجا",
                "label_fr": "Forshaga"
            },
            {
                "value": 44977,
                "label": "Osterbybruk",
                "label_ar": "Osterbybruk",
                "label_fr": "Osterbybruk"
            },
            {
                "value": 45282,
                "label": "Soderakra",
                "label_ar": "سودراكرا",
                "label_fr": "Soderakra"
            },
            {
                "value": 45198,
                "label": "Branno",
                "label_ar": "برانو",
                "label_fr": "Branno"
            },
            {
                "value": 45378,
                "label": "Grasmyr",
                "label_ar": "جراسمير",
                "label_fr": "Grasmyr"
            },
            {
                "value": 44535,
                "label": "Vetlanda",
                "label_ar": "فيتلاندا",
                "label_fr": "Vetlanda"
            },
            {
                "value": 44575,
                "label": "Tving",
                "label_ar": "تفينغ",
                "label_fr": "Tving"
            },
            {
                "value": 45043,
                "label": "Vislanda",
                "label_ar": "Vislanda",
                "label_fr": "Vislanda"
            },
            {
                "value": 44877,
                "label": "Borrby",
                "label_ar": "بوربي",
                "label_fr": "Borrby"
            },
            {
                "value": 45437,
                "label": "Sommen",
                "label_ar": "سومين",
                "label_fr": "Sommen"
            },
            {
                "value": 44636,
                "label": "Skurup",
                "label_ar": "سكوروب",
                "label_fr": "Skurup"
            },
            {
                "value": 45483,
                "label": "Lonsboda",
                "label_ar": "لونسبودا",
                "label_fr": "Lonsboda"
            },
            {
                "value": 44760,
                "label": "Sexdrega",
                "label_ar": "سكسدريجا",
                "label_fr": "Sexdrega"
            },
            {
                "value": 45119,
                "label": "Klagshamn",
                "label_ar": "كلاغشامن",
                "label_fr": "Klagshamn"
            },
            {
                "value": 44662,
                "label": "Floda",
                "label_ar": "فلودا",
                "label_fr": "Floda"
            },
            {
                "value": 45613,
                "label": "Barsebackshamn",
                "label_ar": "Barsebackshamn",
                "label_fr": "Barsebackshamn"
            },
            {
                "value": 44759,
                "label": "Overlida",
                "label_ar": "اوفرليدا",
                "label_fr": "Overlida"
            },
            {
                "value": 45421,
                "label": "Gagnef",
                "label_ar": "جاجنيف",
                "label_fr": "Gagnef"
            },
            {
                "value": 45579,
                "label": "Sandared",
                "label_ar": "ساندارد",
                "label_fr": "Sandared"
            },
            {
                "value": 44561,
                "label": "Alingsas",
                "label_ar": "الينجساس",
                "label_fr": "Alingsas"
            },
            {
                "value": 45431,
                "label": "Ramsjo",
                "label_ar": "رامسجو",
                "label_fr": "Ramsjo"
            },
            {
                "value": 44881,
                "label": "Ostra Ryd",
                "label_ar": "اوسترا ريد",
                "label_fr": "Ostra Ryd"
            },
            {
                "value": 45520,
                "label": "Byske",
                "label_ar": "بيسكي",
                "label_fr": "Byske"
            },
            {
                "value": 45301,
                "label": "Utansjo",
                "label_ar": "أوتانسجو",
                "label_fr": "Utansjo"
            },
            {
                "value": 45299,
                "label": "Grimslov",
                "label_ar": "جريمسلوف",
                "label_fr": "Grimslov"
            },
            {
                "value": 44505,
                "label": "Gnesta",
                "label_ar": "غنيستا",
                "label_fr": "Gnesta"
            },
            {
                "value": 45310,
                "label": "Kvidinge",
                "label_ar": "كفيدينجه",
                "label_fr": "Kvidinge"
            },
            {
                "value": 44434,
                "label": "Alvsjo",
                "label_ar": "ألفسجو",
                "label_fr": "Alvsjo"
            },
            {
                "value": 45179,
                "label": "Hacksvik",
                "label_ar": "هاكفيك",
                "label_fr": "Hacksvik"
            },
            {
                "value": 45207,
                "label": "OEverkalix",
                "label_ar": "OEverkalix",
                "label_fr": "OEverkalix"
            },
            {
                "value": 45283,
                "label": "Vassmolosa",
                "label_ar": "فاسموسا",
                "label_fr": "Vassmolosa"
            },
            {
                "value": 45469,
                "label": "Morup",
                "label_ar": "موروب",
                "label_fr": "Morup"
            },
            {
                "value": 45239,
                "label": "Lerdala",
                "label_ar": "ليردالا",
                "label_fr": "Lerdala"
            },
            {
                "value": 44789,
                "label": "Holmsund",
                "label_ar": "هولمسوند",
                "label_fr": "Holmsund"
            },
            {
                "value": 44764,
                "label": "Bergeforsen",
                "label_ar": "بيرجفورسن",
                "label_fr": "Bergeforsen"
            },
            {
                "value": 44989,
                "label": "Iggesund",
                "label_ar": "إيجيسوند",
                "label_fr": "Iggesund"
            },
            {
                "value": 44445,
                "label": "Haerryda",
                "label_ar": "هايريدا",
                "label_fr": "Haerryda"
            },
            {
                "value": 45217,
                "label": "Knutby",
                "label_ar": "كنوتبي",
                "label_fr": "Knutby"
            },
            {
                "value": 45535,
                "label": "Kristineberg",
                "label_ar": "كريستينبرغ",
                "label_fr": "Kristineberg"
            },
            {
                "value": 44480,
                "label": "Hultafors",
                "label_ar": "هولتافورز",
                "label_fr": "Hultafors"
            },
            {
                "value": 45320,
                "label": "Nyhamnslage",
                "label_ar": "نيهامنسلاج",
                "label_fr": "Nyhamnslage"
            },
            {
                "value": 44912,
                "label": "Borensberg",
                "label_ar": "بورينسبيرج",
                "label_fr": "Borensberg"
            },
            {
                "value": 44574,
                "label": "Lyckeby",
                "label_ar": "ليكبي",
                "label_fr": "Lyckeby"
            },
            {
                "value": 44752,
                "label": "Edsvalla",
                "label_ar": "Edsvalla",
                "label_fr": "Edsvalla"
            },
            {
                "value": 44595,
                "label": "Fagersta",
                "label_ar": "فاجرستا",
                "label_fr": "Fagersta"
            },
            {
                "value": 45081,
                "label": "Nordmaling",
                "label_ar": "نوردمالينغ",
                "label_fr": "Nordmaling"
            },
            {
                "value": 45304,
                "label": "Grundsund",
                "label_ar": "جروندسوند",
                "label_fr": "Grundsund"
            },
            {
                "value": 45510,
                "label": "Vastra Amtervik",
                "label_ar": "فاسترا أميرفيك",
                "label_fr": "Vastra Amtervik"
            },
            {
                "value": 45525,
                "label": "Lovestad",
                "label_ar": "لوفستاد",
                "label_fr": "Lovestad"
            },
            {
                "value": 45103,
                "label": "Dalsjofors",
                "label_ar": "Dalsjofors",
                "label_fr": "Dalsjofors"
            },
            {
                "value": 44816,
                "label": "Grebbestad",
                "label_ar": "غريبستاد",
                "label_fr": "Grebbestad"
            },
            {
                "value": 45376,
                "label": "Hallabro",
                "label_ar": "هالابرو",
                "label_fr": "Hallabro"
            },
            {
                "value": 45377,
                "label": "Karna",
                "label_ar": "كارنا",
                "label_fr": "Karna"
            },
            {
                "value": 45121,
                "label": "Atorp",
                "label_ar": "أتورب",
                "label_fr": "Atorp"
            },
            {
                "value": 45605,
                "label": "Ruda",
                "label_ar": "روضة",
                "label_fr": "Ruda"
            },
            {
                "value": 44828,
                "label": "Grycksbo",
                "label_ar": "جريكسبو",
                "label_fr": "Grycksbo"
            },
            {
                "value": 44601,
                "label": "Teckomatorp",
                "label_ar": "تيكوماتورب",
                "label_fr": "Teckomatorp"
            },
            {
                "value": 45146,
                "label": "Erikslund",
                "label_ar": "إريكسلوند",
                "label_fr": "Erikslund"
            },
            {
                "value": 45213,
                "label": "Askloster",
                "label_ar": "أسكلوستر",
                "label_fr": "Askloster"
            },
            {
                "value": 44700,
                "label": "Bjarnum",
                "label_ar": "بجارنوم",
                "label_fr": "Bjarnum"
            },
            {
                "value": 45369,
                "label": "Knislinge",
                "label_ar": "Knislinge",
                "label_fr": "Knislinge"
            },
            {
                "value": 44983,
                "label": "Insjon",
                "label_ar": "انسجون",
                "label_fr": "Insjon"
            },
            {
                "value": 45342,
                "label": "Yngsjo",
                "label_ar": "ينجسجو",
                "label_fr": "Yngsjo"
            },
            {
                "value": 44751,
                "label": "Ljungskile",
                "label_ar": "Ljungskile",
                "label_fr": "Ljungskile"
            },
            {
                "value": 44984,
                "label": "Asbro",
                "label_ar": "أسبرو",
                "label_fr": "Asbro"
            },
            {
                "value": 45558,
                "label": "Bjorkhaga",
                "label_ar": "بجورخاغا",
                "label_fr": "Bjorkhaga"
            },
            {
                "value": 44749,
                "label": "Ambjornarp",
                "label_ar": "أمبجورنارب",
                "label_fr": "Ambjornarp"
            },
            {
                "value": 44913,
                "label": "Asensbruk",
                "label_ar": "أسينسبروك",
                "label_fr": "Asensbruk"
            },
            {
                "value": 45008,
                "label": "Bjartra",
                "label_ar": "بجارترا",
                "label_fr": "Bjartra"
            },
            {
                "value": 45042,
                "label": "Mantorp",
                "label_ar": "مانتورب",
                "label_fr": "Mantorp"
            },
            {
                "value": 44753,
                "label": "Torup",
                "label_ar": "توروب",
                "label_fr": "Torup"
            },
            {
                "value": 45505,
                "label": "Hamneda",
                "label_ar": "هامندا",
                "label_fr": "Hamneda"
            },
            {
                "value": 45025,
                "label": "Ronnang",
                "label_ar": "رونانج",
                "label_fr": "Ronnang"
            },
            {
                "value": 45390,
                "label": "Moheda",
                "label_ar": "موهيدا",
                "label_fr": "Moheda"
            },
            {
                "value": 44979,
                "label": "Nora",
                "label_ar": "نورا",
                "label_fr": "Nora"
            },
            {
                "value": 45598,
                "label": "Hallarod",
                "label_ar": "هالارود",
                "label_fr": "Hallarod"
            },
            {
                "value": 45569,
                "label": "Hallberg",
                "label_ar": "هالبرج",
                "label_fr": "Hallberg"
            },
            {
                "value": 44888,
                "label": "Tarnsjo",
                "label_ar": "تارنجو",
                "label_fr": "Tarnsjo"
            },
            {
                "value": 44543,
                "label": "Smedjebacken",
                "label_ar": "سميديباكين",
                "label_fr": "Smedjebacken"
            },
            {
                "value": 44851,
                "label": "Mellerud",
                "label_ar": "ميلرود",
                "label_fr": "Mellerud"
            },
            {
                "value": 44883,
                "label": "Kallinge",
                "label_ar": "كالينج",
                "label_fr": "Kallinge"
            },
            {
                "value": 45024,
                "label": "Varing",
                "label_ar": "فارينج",
                "label_fr": "Varing"
            },
            {
                "value": 44489,
                "label": "Huskvarna",
                "label_ar": "هوسكفارنا",
                "label_fr": "Huskvarna"
            },
            {
                "value": 45439,
                "label": "Rimforsa",
                "label_ar": "ريمفورسا",
                "label_fr": "Rimforsa"
            },
            {
                "value": 44769,
                "label": "Vidsel",
                "label_ar": "فيدسل",
                "label_fr": "Vidsel"
            },
            {
                "value": 44963,
                "label": "Skaerhamn",
                "label_ar": "سكيرهامن",
                "label_fr": "Skaerhamn"
            },
            {
                "value": 45472,
                "label": "Stromsholm",
                "label_ar": "سترومشولم",
                "label_fr": "Stromsholm"
            },
            {
                "value": 44731,
                "label": "Bengtsfors",
                "label_ar": "بينغتسفورس",
                "label_fr": "Bengtsfors"
            },
            {
                "value": 44929,
                "label": "Skoldinge",
                "label_ar": "Skoldinge",
                "label_fr": "Skoldinge"
            },
            {
                "value": 44500,
                "label": "Kungälv",
                "label_ar": "Kungälv",
                "label_fr": "Kungälv"
            },
            {
                "value": 45216,
                "label": "Dals Langed",
                "label_ar": "دالس لانجد",
                "label_fr": "Dals Langed"
            },
            {
                "value": 44524,
                "label": "Osterhaninge",
                "label_ar": "أوستيرهانينج",
                "label_fr": "Osterhaninge"
            },
            {
                "value": 44518,
                "label": "Saltsjobaden",
                "label_ar": "سالتسجوبادن",
                "label_fr": "Saltsjobaden"
            },
            {
                "value": 44767,
                "label": "Avesta",
                "label_ar": "أفستا",
                "label_fr": "Avesta"
            },
            {
                "value": 45234,
                "label": "Reftele",
                "label_ar": "ريفتيل",
                "label_fr": "Reftele"
            },
            {
                "value": 44599,
                "label": "Ullared",
                "label_ar": "أولاريد",
                "label_fr": "Ullared"
            },
            {
                "value": 44401,
                "label": "Huddinge",
                "label_ar": "هودينج",
                "label_fr": "Huddinge"
            },
            {
                "value": 44491,
                "label": "Lund",
                "label_ar": "لوند",
                "label_fr": "Lund"
            },
            {
                "value": 45237,
                "label": "Skillinge",
                "label_ar": "سكيلنج",
                "label_fr": "Skillinge"
            },
            {
                "value": 45462,
                "label": "Eneryda",
                "label_ar": "انيريدا",
                "label_fr": "Eneryda"
            },
            {
                "value": 45531,
                "label": "Grondal",
                "label_ar": "جروندال",
                "label_fr": "Grondal"
            },
            {
                "value": 45170,
                "label": "Grisslehamn",
                "label_ar": "جريسليهامن",
                "label_fr": "Grisslehamn"
            },
            {
                "value": 44890,
                "label": "Tallberg",
                "label_ar": "تالبرج",
                "label_fr": "Tallberg"
            },
            {
                "value": 45258,
                "label": "Raa",
                "label_ar": "را",
                "label_fr": "Raa"
            },
            {
                "value": 44484,
                "label": "Stenungsund",
                "label_ar": "ستينونجسوند",
                "label_fr": "Stenungsund"
            },
            {
                "value": 44900,
                "label": "Kolmarden",
                "label_ar": "كولماردين",
                "label_fr": "Kolmarden"
            },
            {
                "value": 44810,
                "label": "Taftea",
                "label_ar": "تفتيا",
                "label_fr": "Taftea"
            },
            {
                "value": 45247,
                "label": "Havdhem",
                "label_ar": "هافضم",
                "label_fr": "Havdhem"
            },
            {
                "value": 44400,
                "label": "Norsborg",
                "label_ar": "نورسبورج",
                "label_fr": "Norsborg"
            },
            {
                "value": 44955,
                "label": "Oxaback",
                "label_ar": "أوكساباك",
                "label_fr": "Oxaback"
            },
            {
                "value": 44667,
                "label": "Orby",
                "label_ar": "او بواسطة",
                "label_fr": "Orby"
            },
            {
                "value": 45486,
                "label": "Fengersfors",
                "label_ar": "منتقمون",
                "label_fr": "Fengersfors"
            },
            {
                "value": 44817,
                "label": "Hofors",
                "label_ar": "هوفورس",
                "label_fr": "Hofors"
            },
            {
                "value": 44958,
                "label": "Haellefors",
                "label_ar": "هيلليفورس",
                "label_fr": "Haellefors"
            },
            {
                "value": 45618,
                "label": "Gunnebo",
                "label_ar": "غونيبو",
                "label_fr": "Gunnebo"
            },
            {
                "value": 45006,
                "label": "Langsele",
                "label_ar": "لانجسيلي",
                "label_fr": "Langsele"
            },
            {
                "value": 44874,
                "label": "Paskallavik",
                "label_ar": "باسكالافيك",
                "label_fr": "Paskallavik"
            },
            {
                "value": 44576,
                "label": "Hjarnarp",
                "label_ar": "هجارنارة",
                "label_fr": "Hjarnarp"
            },
            {
                "value": 44794,
                "label": "Burea",
                "label_ar": "Burea",
                "label_fr": "Burea"
            },
            {
                "value": 45550,
                "label": "Fagerberg",
                "label_ar": "فاجربيرج",
                "label_fr": "Fagerberg"
            },
            {
                "value": 45218,
                "label": "Nygard",
                "label_ar": "نيجارد",
                "label_fr": "Nygard"
            },
            {
                "value": 44994,
                "label": "Ljusne",
                "label_ar": "لجوسن",
                "label_fr": "Ljusne"
            },
            {
                "value": 45588,
                "label": "Farbo",
                "label_ar": "فاربو",
                "label_fr": "Farbo"
            },
            {
                "value": 45272,
                "label": "OEsterbymo",
                "label_ar": "أوستيربيمو",
                "label_fr": "OEsterbymo"
            },
            {
                "value": 44784,
                "label": "Torsaker",
                "label_ar": "تورساكر",
                "label_fr": "Torsaker"
            },
            {
                "value": 44408,
                "label": "Uddevalla",
                "label_ar": "Uddevalla",
                "label_fr": "Uddevalla"
            },
            {
                "value": 44902,
                "label": "Perstorp",
                "label_ar": "بيرستورب",
                "label_fr": "Perstorp"
            },
            {
                "value": 45581,
                "label": "Skyttorp",
                "label_ar": "سكايتورب",
                "label_fr": "Skyttorp"
            },
            {
                "value": 44802,
                "label": "Tavelsjo",
                "label_ar": "تافيلسجو",
                "label_fr": "Tavelsjo"
            },
            {
                "value": 44834,
                "label": "Valdemarsvik",
                "label_ar": "فالديمارسفيك",
                "label_fr": "Valdemarsvik"
            },
            {
                "value": 45242,
                "label": "Arnasvall",
                "label_ar": "أرناسفال",
                "label_fr": "Arnasvall"
            },
            {
                "value": 44943,
                "label": "Moensteras",
                "label_ar": "مونستيراس",
                "label_fr": "Moensteras"
            },
            {
                "value": 45041,
                "label": "Sifferbo",
                "label_ar": "سيفربو",
                "label_fr": "Sifferbo"
            },
            {
                "value": 45453,
                "label": "Sunnemo",
                "label_ar": "سنيمو",
                "label_fr": "Sunnemo"
            },
            {
                "value": 45142,
                "label": "Slite",
                "label_ar": "سليت",
                "label_fr": "Slite"
            },
            {
                "value": 45095,
                "label": "Enebyberg",
                "label_ar": "انبيبرغ",
                "label_fr": "Enebyberg"
            },
            {
                "value": 44835,
                "label": "Sodra Sandby",
                "label_ar": "سودرا ساندبي",
                "label_fr": "Sodra Sandby"
            },
            {
                "value": 44551,
                "label": "Ljungby",
                "label_ar": "Ljungby",
                "label_fr": "Ljungby"
            },
            {
                "value": 45154,
                "label": "Askersund",
                "label_ar": "اسكيرسوند",
                "label_fr": "Askersund"
            },
            {
                "value": 45556,
                "label": "Barkarby",
                "label_ar": "باركاربي",
                "label_fr": "Barkarby"
            },
            {
                "value": 45512,
                "label": "Viksjofors",
                "label_ar": "فيكسوفورس",
                "label_fr": "Viksjofors"
            },
            {
                "value": 45236,
                "label": "Soederbaerke",
                "label_ar": "Soederbaerke",
                "label_fr": "Soederbaerke"
            },
            {
                "value": 45232,
                "label": "Vegby",
                "label_ar": "فيجبي",
                "label_fr": "Vegby"
            },
            {
                "value": 44538,
                "label": "Osmo",
                "label_ar": "أوزمو",
                "label_fr": "Osmo"
            },
            {
                "value": 45326,
                "label": "Skarplinge",
                "label_ar": "سكاربلينج",
                "label_fr": "Skarplinge"
            },
            {
                "value": 45206,
                "label": "Axvall",
                "label_ar": "أكسفال",
                "label_fr": "Axvall"
            },
            {
                "value": 45275,
                "label": "OEvertornea",
                "label_ar": "أوفرتورنيا",
                "label_fr": "OEvertornea"
            },
            {
                "value": 45532,
                "label": "Fagerhult",
                "label_ar": "فاجرهولت",
                "label_fr": "Fagerhult"
            },
            {
                "value": 45578,
                "label": "Spekerod",
                "label_ar": "سبيكيرود",
                "label_fr": "Spekerod"
            },
            {
                "value": 45287,
                "label": "Sorberge",
                "label_ar": "سوربيرج",
                "label_fr": "Sorberge"
            },
            {
                "value": 45593,
                "label": "Annelov",
                "label_ar": "أنيلوف",
                "label_fr": "Annelov"
            },
            {
                "value": 45341,
                "label": "Limmared",
                "label_ar": "مطحون",
                "label_fr": "Limmared"
            },
            {
                "value": 45392,
                "label": "Djuras",
                "label_ar": "دجوراس",
                "label_fr": "Djuras"
            },
            {
                "value": 45560,
                "label": "Vaeja",
                "label_ar": "فايجا",
                "label_fr": "Vaeja"
            },
            {
                "value": 45590,
                "label": "Vaderstad",
                "label_ar": "فيدرستاد",
                "label_fr": "Vaderstad"
            },
            {
                "value": 45553,
                "label": "Gamla stan",
                "label_ar": "جاملا ستان",
                "label_fr": "Gamla Stan"
            },
            {
                "value": 45405,
                "label": "Marstrand",
                "label_ar": "مارستراند",
                "label_fr": "Marstrand"
            },
            {
                "value": 45277,
                "label": "Maleras",
                "label_ar": "ماليراس",
                "label_fr": "Maleras"
            },
            {
                "value": 45413,
                "label": "Korsberga",
                "label_ar": "كورسبيرجا",
                "label_fr": "Korsberga"
            },
            {
                "value": 45500,
                "label": "Trehorningsjo",
                "label_ar": "تريورنينججو",
                "label_fr": "Trehorningsjo"
            },
            {
                "value": 45306,
                "label": "Brastad",
                "label_ar": "براستاد",
                "label_fr": "Brastad"
            },
            {
                "value": 45621,
                "label": "Klovedal",
                "label_ar": "كلوفيدال",
                "label_fr": "Klovedal"
            },
            {
                "value": 45259,
                "label": "Ramsele",
                "label_ar": "رامسيلي",
                "label_fr": "Ramsele"
            },
            {
                "value": 45145,
                "label": "Filipstad",
                "label_ar": "فيليبستاد",
                "label_fr": "Filipstad"
            }
        ]
    },
    {
        "country": "Italy",
        "cities": [
            {
                "value": 45624,
                "label": "Ferrara",
                "label_ar": "فيرارا",
                "label_fr": "Ferrare"
            },
            {
                "value": 45625,
                "label": "Sarnano",
                "label_ar": "سارنانو",
                "label_fr": "Sarnano"
            },
            {
                "value": 45626,
                "label": "Rome",
                "label_ar": "روما",
                "label_fr": "Rome"
            },
            {
                "value": 45627,
                "label": "Gabicce Mare",
                "label_ar": "جابيتشي ماري",
                "label_fr": "Gabicce Mare"
            },
            {
                "value": 45628,
                "label": "Sasso Marconi",
                "label_ar": "ساسو ماركوني",
                "label_fr": "Sasso Marconi"
            },
            {
                "value": 45629,
                "label": "Sarno",
                "label_ar": "سارنو",
                "label_fr": "Sarno"
            },
            {
                "value": 45630,
                "label": "Collegno",
                "label_ar": "كوليجنو",
                "label_fr": "Collegno"
            },
            {
                "value": 45631,
                "label": "Fasano",
                "label_ar": "فاسانو",
                "label_fr": "Fasano"
            },
            {
                "value": 45632,
                "label": "Bitritto",
                "label_ar": "بيريتو",
                "label_fr": "Bitritto"
            },
            {
                "value": 45633,
                "label": "Bitetto",
                "label_ar": "بيتيتو",
                "label_fr": "Bitetto"
            },
            {
                "value": 45634,
                "label": "Bari",
                "label_ar": "باري",
                "label_fr": "Bari"
            },
            {
                "value": 45635,
                "label": "Florence",
                "label_ar": "فلورنسا",
                "label_fr": "Florence"
            },
            {
                "value": 45636,
                "label": "San Filippo",
                "label_ar": "سان فيليبو",
                "label_fr": "San Filippo"
            },
            {
                "value": 45637,
                "label": "Giardini-Naxos",
                "label_ar": "جيارديني ناكسوس",
                "label_fr": "Giardini-Naxos"
            },
            {
                "value": 45638,
                "label": "Augusta",
                "label_ar": "أوغوستا",
                "label_fr": "Augusta"
            },
            {
                "value": 45639,
                "label": "Messina",
                "label_ar": "ميسينا",
                "label_fr": "Messine"
            },
            {
                "value": 45640,
                "label": "Carlentini",
                "label_ar": "كارلينتيني",
                "label_fr": "Carlentini"
            },
            {
                "value": 45641,
                "label": "Troina",
                "label_ar": "تروينا",
                "label_fr": "Troina"
            },
            {
                "value": 45642,
                "label": "Piaggine",
                "label_ar": "بياجين",
                "label_fr": "Piaggine"
            },
            {
                "value": 45643,
                "label": "Tito",
                "label_ar": "تيتو",
                "label_fr": "Tito"
            },
            {
                "value": 45644,
                "label": "Lampedusa",
                "label_ar": "لامبيدوزا",
                "label_fr": "Lampedusa"
            },
            {
                "value": 45645,
                "label": "San Mango Piemonte",
                "label_ar": "سان مانجو بيمونتي",
                "label_fr": "San Mango Piemonte"
            },
            {
                "value": 45646,
                "label": "Mercato San Severino",
                "label_ar": "ميركاتو سان سيفيرينو",
                "label_fr": "Mercato San Severino"
            },
            {
                "value": 45647,
                "label": "San Chirico Raparo",
                "label_ar": "سان شيريكو رابارو",
                "label_fr": "San Chirico Raparo"
            },
            {
                "value": 45648,
                "label": "Gesualdo",
                "label_ar": "جيسوالدو",
                "label_fr": "Gesualdo"
            },
            {
                "value": 45649,
                "label": "Taranto",
                "label_ar": "تارانتو",
                "label_fr": "Tarente"
            },
            {
                "value": 45650,
                "label": "Torricella",
                "label_ar": "توريسيلا",
                "label_fr": "Torricella"
            },
            {
                "value": 45651,
                "label": "Cicognolo",
                "label_ar": "سيكونولو",
                "label_fr": "Cicognolo"
            },
            {
                "value": 45652,
                "label": "Pedaso",
                "label_ar": "بيداسو",
                "label_fr": "Pedaso"
            },
            {
                "value": 45653,
                "label": "Cesena",
                "label_ar": "تشيزينا",
                "label_fr": "Cesena"
            },
            {
                "value": 45654,
                "label": "Casorezzo",
                "label_ar": "كاسوريزو",
                "label_fr": "Casorezzo"
            },
            {
                "value": 45655,
                "label": "Brescia",
                "label_ar": "بريشيا",
                "label_fr": "Brescia"
            },
            {
                "value": 45656,
                "label": "Loreto",
                "label_ar": "لوريتو",
                "label_fr": "Loreto"
            },
            {
                "value": 45657,
                "label": "Vaiano Cremasco",
                "label_ar": "فايانو كريماسكو",
                "label_fr": "Vaiano Cremasco"
            },
            {
                "value": 45658,
                "label": "Milan",
                "label_ar": "ميلان",
                "label_fr": "Milan"
            },
            {
                "value": 45659,
                "label": "Monza",
                "label_ar": "مونزا",
                "label_fr": "Monza"
            },
            {
                "value": 45660,
                "label": "Monterosso Calabro",
                "label_ar": "مونتيروسو كالابرو",
                "label_fr": "Monterosso Calabro"
            },
            {
                "value": 45661,
                "label": "Pesaro",
                "label_ar": "بيزارو",
                "label_fr": "Pesaro"
            },
            {
                "value": 45662,
                "label": "Militello Rosmarino",
                "label_ar": "ميليتيللو روزمارينو",
                "label_fr": "Militello Rosmarino"
            },
            {
                "value": 45663,
                "label": "Floridia",
                "label_ar": "فلوريديا",
                "label_fr": "Floridia"
            },
            {
                "value": 45664,
                "label": "Rionero in Vulture",
                "label_ar": "ريونيرو في نسر",
                "label_fr": "Rionero dans Vulture"
            },
            {
                "value": 45665,
                "label": "San Giorgio del Sannio",
                "label_ar": "سان جورجيو ديل سانيو",
                "label_fr": "San Giorgio del Sannio"
            },
            {
                "value": 45666,
                "label": "Modena",
                "label_ar": "مودينا",
                "label_fr": "Modène"
            },
            {
                "value": 45667,
                "label": "Reggio nell'Emilia",
                "label_ar": "ريجيو نيل إيميليا",
                "label_fr": "Reggio nell'Emilia"
            },
            {
                "value": 45668,
                "label": "Vicenza",
                "label_ar": "فيتشنزا",
                "label_fr": "Vicence"
            },
            {
                "value": 45669,
                "label": "Rimini",
                "label_ar": "ريميني",
                "label_fr": "Rimini"
            },
            {
                "value": 45670,
                "label": "Piacenza",
                "label_ar": "بياتشينزا",
                "label_fr": "Plaisance"
            },
            {
                "value": 45671,
                "label": "Saronno",
                "label_ar": "سارونو",
                "label_fr": "Saronno"
            },
            {
                "value": 45672,
                "label": "Ancona",
                "label_ar": "أنكونا",
                "label_fr": "Ancône"
            },
            {
                "value": 45673,
                "label": "Verona",
                "label_ar": "فيرونا",
                "label_fr": "Vérone"
            },
            {
                "value": 45674,
                "label": "Asti",
                "label_ar": "أستي",
                "label_fr": "Asti"
            },
            {
                "value": 45675,
                "label": "Legnano",
                "label_ar": "ليجنانو",
                "label_fr": "Legnano"
            },
            {
                "value": 45676,
                "label": "Castellanza",
                "label_ar": "كاستيلانزا",
                "label_fr": "Castellanza"
            },
            {
                "value": 45677,
                "label": "Massa e Cozzile",
                "label_ar": "ماسا كوزيل",
                "label_fr": "Massa e Cozzile"
            },
            {
                "value": 45678,
                "label": "Montecatini Terme",
                "label_ar": "مونتيكاتيني تيرمي",
                "label_fr": "Montecatini Terme"
            },
            {
                "value": 45679,
                "label": "Pescara",
                "label_ar": "بيسكارا",
                "label_fr": "Pescara"
            },
            {
                "value": 45680,
                "label": "Bergamo",
                "label_ar": "بيرغامو",
                "label_fr": "Bergame"
            },
            {
                "value": 45681,
                "label": "Bollate",
                "label_ar": "بولات",
                "label_fr": "Bollate"
            },
            {
                "value": 45682,
                "label": "San Donato Milanese",
                "label_ar": "سان دوناتو ميلانيز",
                "label_fr": "San Donato Milanese"
            },
            {
                "value": 45683,
                "label": "Termoli",
                "label_ar": "تيرمولي",
                "label_fr": "Termoli"
            },
            {
                "value": 45684,
                "label": "Turin",
                "label_ar": "تورينو",
                "label_fr": "Turin"
            },
            {
                "value": 45685,
                "label": "Pisa",
                "label_ar": "بيزا",
                "label_fr": "Pise"
            },
            {
                "value": 45686,
                "label": "Pioltello",
                "label_ar": "Pioltello",
                "label_fr": "Pioltello"
            },
            {
                "value": 45687,
                "label": "Gallarate",
                "label_ar": "غالاراتي",
                "label_fr": "Gallarate"
            },
            {
                "value": 45688,
                "label": "Varese",
                "label_ar": "فاريزي",
                "label_fr": "Varèse"
            },
            {
                "value": 45689,
                "label": "Gaeta",
                "label_ar": "جايتا",
                "label_fr": "Gaeta"
            },
            {
                "value": 45690,
                "label": "Parma",
                "label_ar": "بارما",
                "label_fr": "Parme"
            },
            {
                "value": 45691,
                "label": "Teramo",
                "label_ar": "تيرامو",
                "label_fr": "Teramo"
            },
            {
                "value": 45692,
                "label": "Busto Arsizio",
                "label_ar": "بوستو أرسيزيو",
                "label_fr": "Busto Arsizio"
            },
            {
                "value": 45693,
                "label": "Padua",
                "label_ar": "بادوفا",
                "label_fr": "Padoue"
            },
            {
                "value": 45694,
                "label": "Naples",
                "label_ar": "نابولي",
                "label_fr": "Naples"
            },
            {
                "value": 45695,
                "label": "Catania",
                "label_ar": "كاتانيا",
                "label_fr": "Catane"
            },
            {
                "value": 45696,
                "label": "Novara",
                "label_ar": "نوفارا",
                "label_fr": "Novara"
            },
            {
                "value": 45697,
                "label": "Bologna",
                "label_ar": "بولونيا",
                "label_fr": "Bologne"
            },
            {
                "value": 45698,
                "label": "Sestri Levante",
                "label_ar": "سيستري ليفانتي",
                "label_fr": "Sestri Levante"
            },
            {
                "value": 45699,
                "label": "San Giovanni Valdarno",
                "label_ar": "سان جيوفاني فالدارنو",
                "label_fr": "San Giovanni Valdarno"
            },
            {
                "value": 45700,
                "label": "Sesto San Giovanni",
                "label_ar": "سيستو سان جيوفاني",
                "label_fr": "Sesto San Giovanni"
            },
            {
                "value": 45701,
                "label": "Cinisello Balsamo",
                "label_ar": "شينسيلو بالسامو",
                "label_fr": "Cinisello Balsamo"
            },
            {
                "value": 45702,
                "label": "Genoa",
                "label_ar": "جنوة",
                "label_fr": "Gênes"
            },
            {
                "value": 45703,
                "label": "Bitonto",
                "label_ar": "بيتونتو",
                "label_fr": "Bitonto"
            },
            {
                "value": 45704,
                "label": "Forlì",
                "label_ar": "فورلي",
                "label_fr": "Forlì"
            },
            {
                "value": 45705,
                "label": "La Spezia",
                "label_ar": "لا سبيتسيا",
                "label_fr": "la Spezia"
            },
            {
                "value": 45706,
                "label": "Latina",
                "label_ar": "لاتينا",
                "label_fr": "Latina"
            },
            {
                "value": 45707,
                "label": "Segrate",
                "label_ar": "انفصال",
                "label_fr": "Segrate"
            },
            {
                "value": 45708,
                "label": "Peschiera Borromeo",
                "label_ar": "بشيرا بوروميو",
                "label_fr": "Peschiera Borromeo"
            },
            {
                "value": 45709,
                "label": "Treviso",
                "label_ar": "تريفيزو",
                "label_fr": "Trévise"
            },
            {
                "value": 45710,
                "label": "Ravenna",
                "label_ar": "رافينا",
                "label_fr": "Ravenne"
            },
            {
                "value": 45711,
                "label": "Rho",
                "label_ar": "رو",
                "label_fr": "Rho"
            },
            {
                "value": 45712,
                "label": "Rosignano Solvay-Castiglioncello",
                "label_ar": "روزينيانو سولفاي كاستيجليونسيلو",
                "label_fr": "Rosignano Solvay-Castiglioncello"
            },
            {
                "value": 45713,
                "label": "Rosignano Marittimo",
                "label_ar": "روزينيانو ماريتيمو",
                "label_fr": "Rosignano Marittimo"
            },
            {
                "value": 45714,
                "label": "Fondi",
                "label_ar": "فوندي",
                "label_fr": "Fondi"
            },
            {
                "value": 45715,
                "label": "Formia",
                "label_ar": "فورميا",
                "label_fr": "Formia"
            },
            {
                "value": 45716,
                "label": "Carpaneto Piacentino",
                "label_ar": "كاربانيتو بياسينتينو",
                "label_fr": "Carpaneto Piacentino"
            },
            {
                "value": 45717,
                "label": "Petacciato",
                "label_ar": "بيتاتشاتو",
                "label_fr": "Petacciato"
            },
            {
                "value": 45718,
                "label": "Como",
                "label_ar": "كومو",
                "label_fr": "Côme"
            },
            {
                "value": 45719,
                "label": "Siena",
                "label_ar": "سيينا",
                "label_fr": "Sienne"
            },
            {
                "value": 45720,
                "label": "Iglesias",
                "label_ar": "إغليسياس",
                "label_fr": "Iglesias"
            },
            {
                "value": 45721,
                "label": "Gerano",
                "label_ar": "جيرانو",
                "label_fr": "Gerano"
            },
            {
                "value": 45722,
                "label": "Pogno",
                "label_ar": "بوغنو",
                "label_fr": "Pogno"
            },
            {
                "value": 45723,
                "label": "Vicolungo",
                "label_ar": "فيكولونجو",
                "label_fr": "Vicolungo"
            },
            {
                "value": 45724,
                "label": "Corzano",
                "label_ar": "كورزانو",
                "label_fr": "Corzano"
            },
            {
                "value": 45725,
                "label": "Albenga",
                "label_ar": "ألبينجا",
                "label_fr": "Albenga"
            },
            {
                "value": 45726,
                "label": "Pero",
                "label_ar": "بيرو",
                "label_fr": "Pero"
            },
            {
                "value": 45727,
                "label": "Pieve Ligure",
                "label_ar": "بيفي ليغور",
                "label_fr": "Pieve Ligure"
            },
            {
                "value": 45728,
                "label": "Domaso",
                "label_ar": "دوماسو",
                "label_fr": "Domaso"
            },
            {
                "value": 45729,
                "label": "Sarnico",
                "label_ar": "سارنيكو",
                "label_fr": "Sarnico"
            },
            {
                "value": 45730,
                "label": "Verdellino",
                "label_ar": "فيرديلينو",
                "label_fr": "Verdellino"
            },
            {
                "value": 45731,
                "label": "Melegnano",
                "label_ar": "ميليجنانو",
                "label_fr": "Melegnano"
            },
            {
                "value": 45732,
                "label": "Alessandria",
                "label_ar": "اليساندريا",
                "label_fr": "Alessandria"
            },
            {
                "value": 45733,
                "label": "Pavia",
                "label_ar": "بافيا",
                "label_fr": "Pavie"
            },
            {
                "value": 45734,
                "label": "Racconigi",
                "label_ar": "راكونيجي",
                "label_fr": "Racconigi"
            },
            {
                "value": 45735,
                "label": "Perosa Argentina",
                "label_ar": "Perosa Argentina",
                "label_fr": "Perosa Argentine"
            },
            {
                "value": 45736,
                "label": "Moncalvo",
                "label_ar": "مونكالفو",
                "label_fr": "Moncalvo"
            },
            {
                "value": 45737,
                "label": "Vigevano",
                "label_ar": "فيجيفانو",
                "label_fr": "Vigevano"
            },
            {
                "value": 45738,
                "label": "Rivoli",
                "label_ar": "ريفولي",
                "label_fr": "Rivoli"
            },
            {
                "value": 45739,
                "label": "Brandizzo",
                "label_ar": "برانديزو",
                "label_fr": "Brandizzo"
            },
            {
                "value": 45740,
                "label": "Castellamonte",
                "label_ar": "كاستيلامونتي",
                "label_fr": "Castellamonte"
            },
            {
                "value": 45741,
                "label": "Gorgonzola",
                "label_ar": "جورجونزولا",
                "label_fr": "Gorgonzola"
            },
            {
                "value": 45742,
                "label": "Ghedi",
                "label_ar": "غيدي",
                "label_fr": "Ghedi"
            },
            {
                "value": 45743,
                "label": "Tradate",
                "label_ar": "تداول",
                "label_fr": "Tradate"
            },
            {
                "value": 45744,
                "label": "Clusone",
                "label_ar": "كلوسون",
                "label_fr": "Clusone"
            },
            {
                "value": 45745,
                "label": "Alzano Lombardo",
                "label_ar": "ألزانو لومباردو",
                "label_fr": "Alzano Lombardo"
            },
            {
                "value": 45746,
                "label": "Cremona",
                "label_ar": "كريمونا",
                "label_fr": "Crémone"
            },
            {
                "value": 45747,
                "label": "Curno",
                "label_ar": "كورنو",
                "label_fr": "Curno"
            },
            {
                "value": 45748,
                "label": "Ovada",
                "label_ar": "عوفادا",
                "label_fr": "Ovada"
            },
            {
                "value": 45749,
                "label": "Arenzano",
                "label_ar": "أرينزانو",
                "label_fr": "Arenzano"
            },
            {
                "value": 45750,
                "label": "Savona",
                "label_ar": "سافونا",
                "label_fr": "Savona"
            },
            {
                "value": 45751,
                "label": "Sanremo",
                "label_ar": "سانريمو",
                "label_fr": "Sanremo"
            },
            {
                "value": 45752,
                "label": "Viareggio",
                "label_ar": "فياريجيو",
                "label_fr": "Viareggio"
            },
            {
                "value": 45753,
                "label": "Brugherio",
                "label_ar": "بروغيريو",
                "label_fr": "Brugherio"
            },
            {
                "value": 45754,
                "label": "Novate Milanese",
                "label_ar": "نوفات ميلانيز",
                "label_fr": "Novate milanais"
            },
            {
                "value": 45755,
                "label": "Cesano Maderno",
                "label_ar": "سيسانو ماديرنو",
                "label_fr": "Cesano Maderno"
            },
            {
                "value": 45756,
                "label": "Abbiategrasso",
                "label_ar": "أبياتغراسو",
                "label_fr": "Abbiategrasso"
            },
            {
                "value": 45757,
                "label": "Arona",
                "label_ar": "أرونا",
                "label_fr": "Arona"
            },
            {
                "value": 45758,
                "label": "Chieri",
                "label_ar": "شيري",
                "label_fr": "Chieri"
            },
            {
                "value": 45759,
                "label": "Pinerolo",
                "label_ar": "بينيرولو",
                "label_fr": "Pinerolo"
            },
            {
                "value": 45760,
                "label": "Nichelino",
                "label_ar": "نيشلينو",
                "label_fr": "Nichelino"
            },
            {
                "value": 45761,
                "label": "Vigliano Biellese",
                "label_ar": "فيجليانو بيليس",
                "label_fr": "Vigliano Biellese"
            },
            {
                "value": 45762,
                "label": "Romagnano Sesia",
                "label_ar": "روماجنانو سيسيا",
                "label_fr": "Romagnano Sesia"
            },
            {
                "value": 45763,
                "label": "Biella",
                "label_ar": "بييلا",
                "label_fr": "Biella"
            },
            {
                "value": 45764,
                "label": "Settimo Torinese",
                "label_ar": "سيتيمو تورينيس",
                "label_fr": "Settimo Torinese"
            },
            {
                "value": 45765,
                "label": "Rivalta di Torino",
                "label_ar": "ريفالتا دي تورينو",
                "label_fr": "Rivalta di Torino"
            },
            {
                "value": 45766,
                "label": "Domodossola",
                "label_ar": "دومودوسولا",
                "label_fr": "Domodossola"
            },
            {
                "value": 45767,
                "label": "Piossasco",
                "label_ar": "بيوساسكو",
                "label_fr": "Piossasco"
            },
            {
                "value": 45768,
                "label": "San Carlo",
                "label_ar": "سان كارلو",
                "label_fr": "San Carlo"
            },
            {
                "value": 45769,
                "label": "Terlan",
                "label_ar": "تيرلان",
                "label_fr": "Terlan"
            },
            {
                "value": 45770,
                "label": "Tolentino",
                "label_ar": "تولينتينو",
                "label_fr": "Tolentino"
            },
            {
                "value": 45771,
                "label": "Casalecchio di Reno",
                "label_ar": "Casalecchio di Reno",
                "label_fr": "Casalecchio di Reno"
            },
            {
                "value": 45772,
                "label": "Casier",
                "label_ar": "كاسيير",
                "label_fr": "Casier"
            },
            {
                "value": 45773,
                "label": "Scorzè",
                "label_ar": "سكورزي",
                "label_fr": "Scorzè"
            },
            {
                "value": 45774,
                "label": "San Giovanni in Persiceto",
                "label_ar": "سان جيوفاني في بيرسيسيتو",
                "label_fr": "San Giovanni in Persiceto"
            },
            {
                "value": 45775,
                "label": "Cento",
                "label_ar": "سينتو",
                "label_fr": "Cento"
            },
            {
                "value": 45776,
                "label": "Borgonovo Val Tidone",
                "label_ar": "بورجونوفو فال تيدون",
                "label_fr": "Borgonovo Val Tidone"
            },
            {
                "value": 45777,
                "label": "Albinea",
                "label_ar": "ألبينيا",
                "label_fr": "Albinea"
            },
            {
                "value": 45778,
                "label": "Vascon",
                "label_ar": "فاسكون",
                "label_fr": "Vascon"
            },
            {
                "value": 45779,
                "label": "Rivignano",
                "label_ar": "Rivignano",
                "label_fr": "Rivignano"
            },
            {
                "value": 45780,
                "label": "Grado",
                "label_ar": "جرادو",
                "label_fr": "Grado"
            },
            {
                "value": 45781,
                "label": "Asolo",
                "label_ar": "منفردا",
                "label_fr": "Asolo"
            },
            {
                "value": 45782,
                "label": "Annone Veneto",
                "label_ar": "أنوني فينيتو",
                "label_fr": "Annone Veneto"
            },
            {
                "value": 45783,
                "label": "San Michele all'Adige",
                "label_ar": "سان ميشيل all'Adige",
                "label_fr": "San Michele all'Adige"
            },
            {
                "value": 45784,
                "label": "Illasi",
                "label_ar": "إيلاسي",
                "label_fr": "Illasi"
            },
            {
                "value": 45785,
                "label": "San Giovanni Lupatoto",
                "label_ar": "سان جيوفاني لوباتوتو",
                "label_fr": "San Giovanni Lupatoto"
            },
            {
                "value": 45786,
                "label": "Garda",
                "label_ar": "جاردا",
                "label_fr": "Garda"
            },
            {
                "value": 45787,
                "label": "Volpago del Montello",
                "label_ar": "فولباغو ديل مونتيلو",
                "label_fr": "Volpago del Montello"
            },
            {
                "value": 45788,
                "label": "Trivignano Udinese",
                "label_ar": "تريفيجنانو أودينيزي",
                "label_fr": "Trivignano Udinese"
            },
            {
                "value": 45789,
                "label": "Cavallino",
                "label_ar": "كافالينو",
                "label_fr": "Cavallino"
            },
            {
                "value": 45790,
                "label": "Zero Branco",
                "label_ar": "زيرو برانكو",
                "label_fr": "Zéro Branco"
            },
            {
                "value": 45791,
                "label": "Castello di Godego",
                "label_ar": "كاستيلو دي جوديغو",
                "label_fr": "Castello di Godego"
            },
            {
                "value": 45792,
                "label": "Sedico",
                "label_ar": "سيديكو",
                "label_fr": "Sedico"
            },
            {
                "value": 45793,
                "label": "Alberoni",
                "label_ar": "ألبيروني",
                "label_fr": "Alberoni"
            },
            {
                "value": 45794,
                "label": "La Valle Agordina",
                "label_ar": "لا فالي أجوردينا",
                "label_fr": "La Valle Agordina"
            },
            {
                "value": 45795,
                "label": "Pasian di Prato",
                "label_ar": "باسيان دي براتو",
                "label_fr": "Pasian di Prato"
            },
            {
                "value": 45796,
                "label": "Tavagnacco",
                "label_ar": "تافاجناكو",
                "label_fr": "Tavagnacco"
            },
            {
                "value": 45797,
                "label": "Brugnera",
                "label_ar": "بروجنيرا",
                "label_fr": "Brugnera"
            },
            {
                "value": 45798,
                "label": "Ponzano Veneto",
                "label_ar": "بونزانو فينيتو",
                "label_fr": "Ponzano Veneto"
            },
            {
                "value": 45799,
                "label": "Mira",
                "label_ar": "ميرا",
                "label_fr": "Mira"
            },
            {
                "value": 45800,
                "label": "Ponte di Piave",
                "label_ar": "بونتي دي بيافي",
                "label_fr": "Ponte di Piave"
            },
            {
                "value": 45801,
                "label": "Marcon",
                "label_ar": "ماركون",
                "label_fr": "Marcon"
            },
            {
                "value": 45802,
                "label": "Porcia",
                "label_ar": "بورسيا",
                "label_fr": "Porcia"
            },
            {
                "value": 45803,
                "label": "Dosson",
                "label_ar": "دوسون",
                "label_fr": "Dosson"
            },
            {
                "value": 45804,
                "label": "Pravisdomini",
                "label_ar": "برافيسدوميني",
                "label_fr": "Pravisdomini"
            },
            {
                "value": 45805,
                "label": "Pianiga",
                "label_ar": "Pianiga",
                "label_fr": "Pianiga"
            },
            {
                "value": 45806,
                "label": "Sagrado",
                "label_ar": "ساجرادو",
                "label_fr": "Sagrado"
            },
            {
                "value": 45807,
                "label": "Turriaco",
                "label_ar": "توريكو",
                "label_fr": "Turriaco"
            },
            {
                "value": 45808,
                "label": "Roncade",
                "label_ar": "رونكاد",
                "label_fr": "Roncade"
            },
            {
                "value": 45809,
                "label": "Vittorio Veneto",
                "label_ar": "فيتوريو فينيتو",
                "label_fr": "Vittorio Veneto"
            },
            {
                "value": 45810,
                "label": "Trieste",
                "label_ar": "تريست",
                "label_fr": "Trieste"
            },
            {
                "value": 45811,
                "label": "Chioggia",
                "label_ar": "كيودجا",
                "label_fr": "Chioggia"
            },
            {
                "value": 45812,
                "label": "Venice",
                "label_ar": "مدينة البندقية",
                "label_fr": "Venise"
            },
            {
                "value": 45813,
                "label": "Spinea",
                "label_ar": "سبينيا",
                "label_fr": "Spinea"
            },
            {
                "value": 45814,
                "label": "Udine",
                "label_ar": "أوديني",
                "label_fr": "Udine"
            },
            {
                "value": 45815,
                "label": "Mogliano Veneto",
                "label_ar": "موجليانو فينيتو",
                "label_fr": "Mogliano Veneto"
            },
            {
                "value": 45816,
                "label": "Pordenone",
                "label_ar": "بوردينوني",
                "label_fr": "Pordenone"
            },
            {
                "value": 45817,
                "label": "Legnago",
                "label_ar": "ليجناغو",
                "label_fr": "Legnago"
            },
            {
                "value": 45818,
                "label": "Bolzano",
                "label_ar": "بولزانو",
                "label_fr": "Bolzano"
            },
            {
                "value": 45819,
                "label": "Trento",
                "label_ar": "ترينتو",
                "label_fr": "Trente"
            },
            {
                "value": 45820,
                "label": "San Bonifacio",
                "label_ar": "سان بونيفاسيو",
                "label_fr": "San Bonifacio"
            },
            {
                "value": 45821,
                "label": "Bussolengo",
                "label_ar": "بوسولينغو",
                "label_fr": "Bussolengo"
            },
            {
                "value": 45822,
                "label": "Isola della Scala",
                "label_ar": "ايزولا ديلا سكالا",
                "label_fr": "Isola della Scala"
            },
            {
                "value": 45823,
                "label": "Belluno",
                "label_ar": "بيلونو",
                "label_fr": "Belluno"
            },
            {
                "value": 45824,
                "label": "Bovolone",
                "label_ar": "بوفولون",
                "label_fr": "Bovolone"
            },
            {
                "value": 45825,
                "label": "Portomaggiore",
                "label_ar": "بورتوماجيوري",
                "label_fr": "Portomaggiore"
            },
            {
                "value": 45826,
                "label": "Cittàdi Castello",
                "label_ar": "سيتادي كاستيلو",
                "label_fr": "Cittàdi Castello"
            },
            {
                "value": 45827,
                "label": "Dozza",
                "label_ar": "دوزا",
                "label_fr": "Dozza"
            },
            {
                "value": 45828,
                "label": "Budrio",
                "label_ar": "بودريو",
                "label_fr": "Budrio"
            },
            {
                "value": 45829,
                "label": "Imola",
                "label_ar": "إيمولا",
                "label_fr": "Imola"
            },
            {
                "value": 45830,
                "label": "Sassuolo",
                "label_ar": "ساسولو",
                "label_fr": "Sassuolo"
            },
            {
                "value": 45831,
                "label": "Bastia umbra",
                "label_ar": "باستيا أومبرا",
                "label_fr": "Bastia umbra"
            },
            {
                "value": 45832,
                "label": "Monghidoro",
                "label_ar": "مونجهيدورو",
                "label_fr": "Monghidoro"
            },
            {
                "value": 45833,
                "label": "Cervia",
                "label_ar": "سيرفيا",
                "label_fr": "Cervia"
            },
            {
                "value": 45834,
                "label": "Formigine",
                "label_ar": "فورميجين",
                "label_fr": "Formigine"
            },
            {
                "value": 45835,
                "label": "Castel San Pietro Terme",
                "label_ar": "قلعة سان بيترو تيرمي",
                "label_fr": "Castel San Pietro Terme"
            },
            {
                "value": 45836,
                "label": "Santarcangelo di Romagna",
                "label_ar": "Santarcangelo di Romagna",
                "label_fr": "Santarcangelo di Romagna"
            },
            {
                "value": 45837,
                "label": "Perugia",
                "label_ar": "بيروجيا",
                "label_fr": "Pérouse"
            },
            {
                "value": 45838,
                "label": "Schio",
                "label_ar": "سكيو",
                "label_fr": "Schio"
            },
            {
                "value": 45839,
                "label": "Rovigo",
                "label_ar": "روفيغو",
                "label_fr": "Rovigo"
            },
            {
                "value": 45840,
                "label": "Malo",
                "label_ar": "مالو",
                "label_fr": "Malo"
            },
            {
                "value": 45841,
                "label": "Sarcedo",
                "label_ar": "سارسيدو",
                "label_fr": "Sarcedo"
            },
            {
                "value": 45842,
                "label": "Somma Lombardo",
                "label_ar": "سوما لومباردو",
                "label_fr": "Somma Lombardo"
            },
            {
                "value": 45843,
                "label": "Erba",
                "label_ar": "اربا",
                "label_fr": "Erba"
            },
            {
                "value": 45844,
                "label": "Casalpusterlengo",
                "label_ar": "Casalpusterlengo",
                "label_fr": "Casalpusterlengo"
            },
            {
                "value": 45845,
                "label": "Cermenate",
                "label_ar": "سيرمينيت",
                "label_fr": "Cerménate"
            },
            {
                "value": 45846,
                "label": "Luino",
                "label_ar": "لوينو",
                "label_fr": "Luino"
            },
            {
                "value": 45847,
                "label": "Solbiate Olona",
                "label_ar": "Solbiate أولونا",
                "label_fr": "Solbiate Olona"
            },
            {
                "value": 45848,
                "label": "Codogno",
                "label_ar": "كودوجنو",
                "label_fr": "Codogno"
            },
            {
                "value": 45849,
                "label": "Mariano Comense",
                "label_ar": "ماريانو كومينس",
                "label_fr": "Mariano Comense"
            },
            {
                "value": 45850,
                "label": "Bresso",
                "label_ar": "بريسو",
                "label_fr": "Bresso"
            },
            {
                "value": 45851,
                "label": "Lodi",
                "label_ar": "لودي",
                "label_fr": "Lodi"
            },
            {
                "value": 45852,
                "label": "Cologno al Serio",
                "label_ar": "Cologno al Serio",
                "label_fr": "Cologno al Serio"
            },
            {
                "value": 45853,
                "label": "Cologno Monzese",
                "label_ar": "كولونو مونزيز",
                "label_fr": "Cologno Monzese"
            },
            {
                "value": 45854,
                "label": "Moncalieri",
                "label_ar": "Moncalieri",
                "label_fr": "Moncalieri"
            },
            {
                "value": 45855,
                "label": "Grugliasco",
                "label_ar": "جروجلياسكو",
                "label_fr": "Grugliasco"
            },
            {
                "value": 45856,
                "label": "Casale Monferrato",
                "label_ar": "كاسال مونفيراتو",
                "label_fr": "Casale Monferrato"
            },
            {
                "value": 45857,
                "label": "Aosta",
                "label_ar": "أوستا",
                "label_fr": "Aoste"
            },
            {
                "value": 45858,
                "label": "Gessate",
                "label_ar": "جيسيت",
                "label_fr": "Gessate"
            },
            {
                "value": 45859,
                "label": "Brivio",
                "label_ar": "بريفيو",
                "label_fr": "Brivio"
            },
            {
                "value": 45860,
                "label": "Calolziocorte",
                "label_ar": "كالزيوكورتي",
                "label_fr": "Calolziocorte"
            },
            {
                "value": 45861,
                "label": "Parabiago",
                "label_ar": "بارابياغو",
                "label_fr": "Parabiago"
            },
            {
                "value": 45862,
                "label": "Zelo",
                "label_ar": "زيلو",
                "label_fr": "Zelo"
            },
            {
                "value": 45863,
                "label": "Albiate",
                "label_ar": "ألبيات",
                "label_fr": "Albiate"
            },
            {
                "value": 45864,
                "label": "Lomagna",
                "label_ar": "لوماجنا",
                "label_fr": "Lomagna"
            },
            {
                "value": 45865,
                "label": "Venegono Superiore",
                "label_ar": "Venegono Superiore",
                "label_fr": "Venegono Superiore"
            },
            {
                "value": 45866,
                "label": "Lainate",
                "label_ar": "لينيت",
                "label_fr": "Lainate"
            },
            {
                "value": 45867,
                "label": "Villa Cortese",
                "label_ar": "فيلا كورتيز",
                "label_fr": "Villa Cortese"
            },
            {
                "value": 45868,
                "label": "Locate di Triulzi",
                "label_ar": "حدد موقع دي تريولزي",
                "label_fr": "Localiser di Triulzi"
            },
            {
                "value": 45869,
                "label": "Inzago",
                "label_ar": "إنزاغو",
                "label_fr": "Inzago"
            },
            {
                "value": 45870,
                "label": "Besana in Brianza",
                "label_ar": "بيسانا في بريانزا",
                "label_fr": "Besana en Brianza"
            },
            {
                "value": 45871,
                "label": "Biassono",
                "label_ar": "بياسونو",
                "label_fr": "Biassono"
            },
            {
                "value": 45872,
                "label": "Osnago",
                "label_ar": "أوسناغو",
                "label_fr": "Osnago"
            },
            {
                "value": 45873,
                "label": "Gorla Maggiore",
                "label_ar": "جورلا ماجوري",
                "label_fr": "Gorla Maggiore"
            },
            {
                "value": 45874,
                "label": "Triuggio",
                "label_ar": "تريوجيو",
                "label_fr": "Triuggio"
            },
            {
                "value": 45875,
                "label": "Cerro Maggiore",
                "label_ar": "سيرو ماجوري",
                "label_fr": "Cerro Maggiore"
            },
            {
                "value": 45876,
                "label": "Cornate d'Adda",
                "label_ar": "كورنات دادا",
                "label_fr": "Cornate d'Adda"
            },
            {
                "value": 45877,
                "label": "Binasco",
                "label_ar": "بيناسكو",
                "label_fr": "Binasco"
            },
            {
                "value": 45878,
                "label": "Broni",
                "label_ar": "بروني",
                "label_fr": "Broni"
            },
            {
                "value": 45879,
                "label": "Lurago d'Erba",
                "label_ar": "لوراغو ديربا",
                "label_fr": "Lurago d'Erba"
            },
            {
                "value": 45880,
                "label": "Vaprio d'Adda",
                "label_ar": "فابريو دادا",
                "label_fr": "Vaprio d'Adda"
            },
            {
                "value": 45881,
                "label": "Calizzano",
                "label_ar": "كاليزانو",
                "label_fr": "Calizzano"
            },
            {
                "value": 45882,
                "label": "Mezzanego",
                "label_ar": "ميزانيغو",
                "label_fr": "Mezzanego"
            },
            {
                "value": 45883,
                "label": "Carcare",
                "label_ar": "كار كير",
                "label_fr": "Carcare"
            },
            {
                "value": 45884,
                "label": "Azzate",
                "label_ar": "عزت",
                "label_fr": "Azzate"
            },
            {
                "value": 45885,
                "label": "Missaglia",
                "label_ar": "Missaglia",
                "label_fr": "Missaglia"
            },
            {
                "value": 45886,
                "label": "Giussano",
                "label_ar": "جيوسانو",
                "label_fr": "Giussano"
            },
            {
                "value": 45887,
                "label": "Paderno Dugnano",
                "label_ar": "باديرنو دوجنانو",
                "label_fr": "Paderno Dugnano"
            },
            {
                "value": 45888,
                "label": "Seveso",
                "label_ar": "سيفيسو",
                "label_fr": "Seveso"
            },
            {
                "value": 45889,
                "label": "Villasanta",
                "label_ar": "فيلاسانتا",
                "label_fr": "Villasanta"
            },
            {
                "value": 45890,
                "label": "Lentate sul Seveso",
                "label_ar": "الصوم الكبير سول سيفيسو",
                "label_fr": "Lentate sul Seveso"
            },
            {
                "value": 45891,
                "label": "Corsico",
                "label_ar": "كورسيكو",
                "label_fr": "Corsico"
            },
            {
                "value": 45892,
                "label": "Avezzano",
                "label_ar": "أفيزانو",
                "label_fr": "Avezzano"
            },
            {
                "value": 45893,
                "label": "Senigallia",
                "label_ar": "سينيغاليا",
                "label_fr": "Senigallia"
            },
            {
                "value": 45894,
                "label": "San Benedetto dei Marsi",
                "label_ar": "سان بنديتو دي مارسي",
                "label_fr": "San Benedetto dei Marsi"
            },
            {
                "value": 45895,
                "label": "Massa",
                "label_ar": "ماسا",
                "label_fr": "Massa"
            },
            {
                "value": 45896,
                "label": "Arezzo",
                "label_ar": "أريتسو",
                "label_fr": "Arezzo"
            },
            {
                "value": 45897,
                "label": "Forlimpopoli",
                "label_ar": "فورليمبوبولي",
                "label_fr": "Forlimpopoli"
            },
            {
                "value": 45898,
                "label": "Pontedera",
                "label_ar": "بونتيديرا",
                "label_fr": "Pontedera"
            },
            {
                "value": 45899,
                "label": "Livorno",
                "label_ar": "ليفورنو",
                "label_fr": "Livourne"
            },
            {
                "value": 45900,
                "label": "Formello",
                "label_ar": "فورميلو",
                "label_fr": "Formello"
            },
            {
                "value": 45901,
                "label": "Anzio",
                "label_ar": "أنزيو",
                "label_fr": "Anzio"
            },
            {
                "value": 45902,
                "label": "Frascati",
                "label_ar": "فراسكاتي",
                "label_fr": "Frascati"
            },
            {
                "value": 45903,
                "label": "Guidonia",
                "label_ar": "غيدونيا",
                "label_fr": "Guidonia"
            },
            {
                "value": 45904,
                "label": "Aprilia",
                "label_ar": "ابريليا",
                "label_fr": "Aprilia"
            },
            {
                "value": 45905,
                "label": "Sansepolcro",
                "label_ar": "سانسيبولكرو",
                "label_fr": "Sansepolcro"
            },
            {
                "value": 45906,
                "label": "Sesto Fiorentino",
                "label_ar": "سيستو فيورنتينو",
                "label_fr": "Sesto Fiorentino"
            },
            {
                "value": 45907,
                "label": "Pistoia",
                "label_ar": "بستويا",
                "label_fr": "Pistoia"
            },
            {
                "value": 45908,
                "label": "Prato",
                "label_ar": "براتو",
                "label_fr": "Prato"
            },
            {
                "value": 45909,
                "label": "Montecarlo",
                "label_ar": "مونتي كارلو",
                "label_fr": "Monte Carlo"
            },
            {
                "value": 45910,
                "label": "Riccione",
                "label_ar": "ريتشوني",
                "label_fr": "Riccione"
            },
            {
                "value": 45911,
                "label": "Castelfiorentino",
                "label_ar": "كاستلفورينتينو",
                "label_fr": "Castelfiorentino"
            },
            {
                "value": 45912,
                "label": "Arconate",
                "label_ar": "أركونات",
                "label_fr": "Arconate"
            },
            {
                "value": 45913,
                "label": "Varedo",
                "label_ar": "فاريدو",
                "label_fr": "Varedo"
            },
            {
                "value": 45914,
                "label": "Barzanò",
                "label_ar": "برزاني",
                "label_fr": "Barzanò"
            },
            {
                "value": 45915,
                "label": "Trezzano sul Naviglio",
                "label_ar": "تريزانو سول نافيجليو",
                "label_fr": "Trezzano sul Naviglio"
            },
            {
                "value": 45916,
                "label": "Usmate Velate",
                "label_ar": "Usmate فيلات",
                "label_fr": "Usmate Velate"
            },
            {
                "value": 45917,
                "label": "Lonigo",
                "label_ar": "لونيجو",
                "label_fr": "Lonigo"
            },
            {
                "value": 45918,
                "label": "Occhiobello",
                "label_ar": "أوكشيوبيلو",
                "label_fr": "Occhiobello"
            },
            {
                "value": 45919,
                "label": "Albignasego",
                "label_ar": "Albignasego",
                "label_fr": "Albignasego"
            },
            {
                "value": 45920,
                "label": "Cittadella",
                "label_ar": "سيتاديلا",
                "label_fr": "Cittadella"
            },
            {
                "value": 45921,
                "label": "Maseràdi Padova",
                "label_ar": "ماسيرادي بادوفا",
                "label_fr": "Maseràdi Padova"
            },
            {
                "value": 45922,
                "label": "Longiano",
                "label_ar": "لونجيانو",
                "label_fr": "Longiano"
            },
            {
                "value": 45923,
                "label": "Rovereto",
                "label_ar": "روفيريتو",
                "label_fr": "Rovereto"
            },
            {
                "value": 45924,
                "label": "Spoltore",
                "label_ar": "سبولتور",
                "label_fr": "Spoltore"
            },
            {
                "value": 45925,
                "label": "Figline Valdarno",
                "label_ar": "فيجلين فالدارنو",
                "label_fr": "Figline Valdarno"
            },
            {
                "value": 45926,
                "label": "Palermo",
                "label_ar": "باليرمو",
                "label_fr": "Palerme"
            },
            {
                "value": 45927,
                "label": "Benevento",
                "label_ar": "بينيفينتو",
                "label_fr": "Bénévent"
            },
            {
                "value": 45928,
                "label": "Alcamo",
                "label_ar": "الكامو",
                "label_fr": "Alcamo"
            },
            {
                "value": 45929,
                "label": "Trecastagni",
                "label_ar": "تريكاستاجني",
                "label_fr": "Trecastagni"
            },
            {
                "value": 45930,
                "label": "Syracuse",
                "label_ar": "سيراكيوز",
                "label_fr": "Syracuse"
            },
            {
                "value": 45931,
                "label": "Giarre",
                "label_ar": "جيار",
                "label_fr": "Giarre"
            },
            {
                "value": 45932,
                "label": "Acireale",
                "label_ar": "Acireale",
                "label_fr": "Acireale"
            },
            {
                "value": 45933,
                "label": "Monreale",
                "label_ar": "مونريالي",
                "label_fr": "Monreale"
            },
            {
                "value": 45934,
                "label": "Alba",
                "label_ar": "ألبا",
                "label_fr": "Alba"
            },
            {
                "value": 45935,
                "label": "Rapallo",
                "label_ar": "رابالو",
                "label_fr": "Rapallo"
            },
            {
                "value": 45936,
                "label": "Albissola Marina",
                "label_ar": "مرسى البيسولا",
                "label_fr": "Marina d'Albissola"
            },
            {
                "value": 45937,
                "label": "Cuneo",
                "label_ar": "كونيو",
                "label_fr": "Cuneo"
            },
            {
                "value": 45938,
                "label": "Magenta",
                "label_ar": "أرجواني",
                "label_fr": "Magenta"
            },
            {
                "value": 45939,
                "label": "Novi Ligure",
                "label_ar": "نوفي ليغور",
                "label_fr": "Novi Ligure"
            },
            {
                "value": 45940,
                "label": "Mortara",
                "label_ar": "مورتارا",
                "label_fr": "Mortara"
            },
            {
                "value": 45941,
                "label": "Ivrea",
                "label_ar": "إيفريا",
                "label_fr": "Ivrée"
            },
            {
                "value": 45942,
                "label": "Alpignano",
                "label_ar": "Alpignano",
                "label_fr": "Alpignano"
            },
            {
                "value": 45943,
                "label": "Pianezza",
                "label_ar": "بيانزا",
                "label_fr": "Pianezza"
            },
            {
                "value": 45944,
                "label": "Valenza",
                "label_ar": "فالينزا",
                "label_fr": "Valenza"
            },
            {
                "value": 45945,
                "label": "Tortona",
                "label_ar": "تورتونا",
                "label_fr": "Tortona"
            },
            {
                "value": 45946,
                "label": "Lungavilla",
                "label_ar": "لونجافيلا",
                "label_fr": "Lungavilla"
            },
            {
                "value": 45947,
                "label": "Olgiate Molgora",
                "label_ar": "أولجيات مولجورا",
                "label_fr": "Olgiate Molgora"
            },
            {
                "value": 45948,
                "label": "Arcore",
                "label_ar": "آركور",
                "label_fr": "Arcore"
            },
            {
                "value": 45949,
                "label": "Garlasco",
                "label_ar": "جارلاسكو",
                "label_fr": "Garlasco"
            },
            {
                "value": 45950,
                "label": "San Martino in Strada",
                "label_ar": "سان مارتينو في سترادا",
                "label_fr": "San Martino à Strada"
            },
            {
                "value": 45951,
                "label": "Cislago",
                "label_ar": "سيسلاغو",
                "label_fr": "Cislago"
            },
            {
                "value": 45952,
                "label": "Landriano",
                "label_ar": "لاندريانو",
                "label_fr": "Landriano"
            },
            {
                "value": 45953,
                "label": "Limbiate",
                "label_ar": "ليمبييت",
                "label_fr": "Limbiate"
            },
            {
                "value": 45954,
                "label": "Vermezzo",
                "label_ar": "فيرميزو",
                "label_fr": "Vermezzo"
            },
            {
                "value": 45955,
                "label": "Siziano",
                "label_ar": "سيزيانو",
                "label_fr": "Siziano"
            },
            {
                "value": 45956,
                "label": "San Genesio ed Uniti",
                "label_ar": "San Genesio ed Uniti",
                "label_fr": "San Genesio ed Uniti"
            },
            {
                "value": 45957,
                "label": "Liscate",
                "label_ar": "فاسق",
                "label_fr": "Liscate"
            },
            {
                "value": 45958,
                "label": "Fombio",
                "label_ar": "فومبيو",
                "label_fr": "Fombio"
            },
            {
                "value": 45959,
                "label": "Campertogno",
                "label_ar": "كامبيرتوجنو",
                "label_fr": "Campertogno"
            },
            {
                "value": 45960,
                "label": "Trezzo sull'Adda",
                "label_ar": "Trezzo sull'Adda",
                "label_fr": "Trezzo sull'Adda"
            },
            {
                "value": 45961,
                "label": "Lugagnano",
                "label_ar": "لوغانيانو",
                "label_fr": "Lugagnano"
            },
            {
                "value": 45962,
                "label": "Trescore Balneario",
                "label_ar": "تريسكور بالنيريو",
                "label_fr": "Trescore Balneario"
            },
            {
                "value": 45963,
                "label": "Mantova",
                "label_ar": "مانتوفا",
                "label_fr": "Mantova"
            },
            {
                "value": 45964,
                "label": "Pieve",
                "label_ar": "بيفي",
                "label_fr": "Pieve"
            },
            {
                "value": 45965,
                "label": "Vertova",
                "label_ar": "فيرتوفا",
                "label_fr": "Vertova"
            },
            {
                "value": 45966,
                "label": "Darfo",
                "label_ar": "دارفو",
                "label_fr": "Darfo"
            },
            {
                "value": 45967,
                "label": "Chiari",
                "label_ar": "خياري",
                "label_fr": "Chiari"
            },
            {
                "value": 45968,
                "label": "Seriate",
                "label_ar": "مسلسل",
                "label_fr": "Seriate"
            },
            {
                "value": 45969,
                "label": "Travagliato",
                "label_ar": "ترافالياتو",
                "label_fr": "Travagliato"
            },
            {
                "value": 45970,
                "label": "Cene",
                "label_ar": "سين",
                "label_fr": "Cene"
            },
            {
                "value": 45971,
                "label": "Gussago",
                "label_ar": "غوساغو",
                "label_fr": "Gussago"
            },
            {
                "value": 45972,
                "label": "Urgnano",
                "label_ar": "Urgnano",
                "label_fr": "Urgnano"
            },
            {
                "value": 45973,
                "label": "Casnigo",
                "label_ar": "كاسنيغو",
                "label_fr": "Casnigo"
            },
            {
                "value": 45974,
                "label": "Saluzzo",
                "label_ar": "سالوزو",
                "label_fr": "Saluzzo"
            },
            {
                "value": 45975,
                "label": "Orbassano",
                "label_ar": "أورباسانو",
                "label_fr": "Orbassano"
            },
            {
                "value": 45976,
                "label": "Gattinara",
                "label_ar": "جاتينارا",
                "label_fr": "Gattinara"
            },
            {
                "value": 45977,
                "label": "Borgosesia",
                "label_ar": "بورجوسيا",
                "label_fr": "Borgosesia"
            },
            {
                "value": 45978,
                "label": "Carmagnola",
                "label_ar": "كارماغنولا",
                "label_fr": "Carmagnole"
            },
            {
                "value": 45979,
                "label": "Fidenza",
                "label_ar": "فيدينزا",
                "label_fr": "Fidenza"
            },
            {
                "value": 45980,
                "label": "Bagnolo Cremasco",
                "label_ar": "بانولو كريماسكو",
                "label_fr": "Bagnolo Cremasco"
            },
            {
                "value": 45981,
                "label": "Ventimiglia",
                "label_ar": "فينتيميليا",
                "label_fr": "Vintimille"
            },
            {
                "value": 45982,
                "label": "Desio",
                "label_ar": "ديسيو",
                "label_fr": "Desio"
            },
            {
                "value": 45983,
                "label": "Cornaredo",
                "label_ar": "كورناريدو",
                "label_fr": "Cornaredo"
            },
            {
                "value": 45984,
                "label": "Vimercate",
                "label_ar": "فيميركات",
                "label_fr": "Vimercate"
            },
            {
                "value": 45985,
                "label": "Rozzano",
                "label_ar": "روزانو",
                "label_fr": "Rozzano"
            },
            {
                "value": 45986,
                "label": "Milanesi",
                "label_ar": "ميلانيسي",
                "label_fr": "Milanesi"
            },
            {
                "value": 45987,
                "label": "Favria",
                "label_ar": "فافريا",
                "label_fr": "Favria"
            },
            {
                "value": 45988,
                "label": "Giaveno",
                "label_ar": "جيافينو",
                "label_fr": "Giaveno"
            },
            {
                "value": 45989,
                "label": "Mondovì",
                "label_ar": "موندوفي",
                "label_fr": "Mondovì"
            },
            {
                "value": 45990,
                "label": "Fossano",
                "label_ar": "فوسانو",
                "label_fr": "Fossano"
            },
            {
                "value": 45991,
                "label": "San Michele Mondovì",
                "label_ar": "سان ميشيل موندوفي",
                "label_fr": "San Michele Mondovì"
            },
            {
                "value": 45992,
                "label": "Lessona",
                "label_ar": "ليسونا",
                "label_fr": "Lessona"
            },
            {
                "value": 45993,
                "label": "Sandigliano",
                "label_ar": "سانديجليانو",
                "label_fr": "Sandigliano"
            },
            {
                "value": 45994,
                "label": "Oglianico",
                "label_ar": "اوليانيكو",
                "label_fr": "Oglianico"
            },
            {
                "value": 45995,
                "label": "Omegna",
                "label_ar": "أوميغا",
                "label_fr": "Omegna"
            },
            {
                "value": 45996,
                "label": "Bricherasio",
                "label_ar": "بريشيراسيو",
                "label_fr": "Bricherasio"
            },
            {
                "value": 45997,
                "label": "Castelnuovo Don Bosco",
                "label_ar": "Castelnuovo Don Bosco",
                "label_fr": "Castelnuovo Don Bosco"
            },
            {
                "value": 45998,
                "label": "Rivarolo Ligure",
                "label_ar": "ريفارولو ليغور",
                "label_fr": "Rivarolo Ligure"
            },
            {
                "value": 45999,
                "label": "Occhieppo Inferiore",
                "label_ar": "Occhieppo Inferiore",
                "label_fr": "Occhieppo Inferiore"
            },
            {
                "value": 46000,
                "label": "Varallo Sesia",
                "label_ar": "فارالو سيسيا",
                "label_fr": "Varallo Sesia"
            },
            {
                "value": 46001,
                "label": "Varallo Pombia",
                "label_ar": "فارالو بومبيا",
                "label_fr": "Varallo Pombia"
            },
            {
                "value": 46002,
                "label": "Momo",
                "label_ar": "مومو",
                "label_fr": "Momo"
            },
            {
                "value": 46003,
                "label": "San Giovanni",
                "label_ar": "سان جيوفاني",
                "label_fr": "San Giovanni"
            },
            {
                "value": 46004,
                "label": "Finale Ligure",
                "label_ar": "خاتمة ليغور",
                "label_fr": "Finale Ligure"
            },
            {
                "value": 46005,
                "label": "Calusco d'Adda",
                "label_ar": "كالوسكو دادا",
                "label_fr": "Calusco d'Adda"
            },
            {
                "value": 46006,
                "label": "Marano Ticino",
                "label_ar": "مارانو تيتشينو",
                "label_fr": "Marano Tessin"
            },
            {
                "value": 46007,
                "label": "Gressoney-Saint-Jean",
                "label_ar": "جريسوني سان جان",
                "label_fr": "Gressoney-Saint-Jean"
            },
            {
                "value": 46008,
                "label": "San Martino di Lupari",
                "label_ar": "سان مارتينو دي لوباري",
                "label_fr": "San Martino di Lupari"
            },
            {
                "value": 46009,
                "label": "Rosà",
                "label_ar": "روزا",
                "label_fr": "Rosà"
            },
            {
                "value": 46010,
                "label": "Tricesimo",
                "label_ar": "تريتيسيمو",
                "label_fr": "Tricesimo"
            },
            {
                "value": 46011,
                "label": "Sant'Ilario d'Enza",
                "label_ar": "سانت إيلاريو دي إنزا",
                "label_fr": "Sant'Ilario d'Enza"
            },
            {
                "value": 46012,
                "label": "Zola Predosa",
                "label_ar": "زولا بريدوسا",
                "label_fr": "Zola Predosa"
            },
            {
                "value": 46013,
                "label": "Peschiera del Garda",
                "label_ar": "بشيرا ديل جاردا",
                "label_fr": "Peschiera del Garda"
            },
            {
                "value": 46014,
                "label": "Tregnago",
                "label_ar": "تريغناغو",
                "label_fr": "Tregnago"
            },
            {
                "value": 46015,
                "label": "San Pietro in Casale",
                "label_ar": "سان بيترو في كاسالي",
                "label_fr": "San Pietro in Casale"
            },
            {
                "value": 46016,
                "label": "Maranello",
                "label_ar": "مارانيلو",
                "label_fr": "Maranello"
            },
            {
                "value": 46017,
                "label": "Camposampiero",
                "label_ar": "كامبوسامبيرو",
                "label_fr": "Camposampiero"
            },
            {
                "value": 46018,
                "label": "Abano Terme",
                "label_ar": "أبانو تيرمي",
                "label_fr": "Abano Terme"
            },
            {
                "value": 46019,
                "label": "Brixen",
                "label_ar": "بريكسين",
                "label_fr": "Brixen"
            },
            {
                "value": 46020,
                "label": "Marostica",
                "label_ar": "ماروستيكا",
                "label_fr": "Marostica"
            },
            {
                "value": 46021,
                "label": "Civezzano",
                "label_ar": "سيفيزانو",
                "label_fr": "Civezzano"
            },
            {
                "value": 46022,
                "label": "Arco",
                "label_ar": "أركو",
                "label_fr": "Arco"
            },
            {
                "value": 46023,
                "label": "Portogruaro",
                "label_ar": "بورتوغروارو",
                "label_fr": "Portogruaro"
            },
            {
                "value": 46024,
                "label": "Crema",
                "label_ar": "كريما",
                "label_fr": "Crema"
            },
            {
                "value": 46025,
                "label": "Lecco",
                "label_ar": "ليكو",
                "label_fr": "Lecco"
            },
            {
                "value": 46026,
                "label": "Camparada",
                "label_ar": "كامبارادا",
                "label_fr": "Camparada"
            },
            {
                "value": 46027,
                "label": "Massa Lombarda",
                "label_ar": "ماسا لومباردا",
                "label_fr": "Massa Lombarda"
            },
            {
                "value": 46028,
                "label": "Savignano sul Panaro",
                "label_ar": "سافينانو سول بانارو",
                "label_fr": "Savignano sul Panaro"
            },
            {
                "value": 46029,
                "label": "Baricella",
                "label_ar": "بارسيلا",
                "label_fr": "Baricella"
            },
            {
                "value": 46030,
                "label": "Castelfranco Emilia",
                "label_ar": "كاستلفرانكو إميليا",
                "label_fr": "Castelfranco Emilia"
            },
            {
                "value": 46031,
                "label": "Comacchio",
                "label_ar": "كوماكيو",
                "label_fr": "Comacchio"
            },
            {
                "value": 46032,
                "label": "Masi Torello",
                "label_ar": "ماسي توريلو",
                "label_fr": "Masi Torello"
            },
            {
                "value": 46033,
                "label": "Cotignola",
                "label_ar": "كوتيجنولا",
                "label_fr": "Cotignola"
            },
            {
                "value": 46034,
                "label": "Mordano",
                "label_ar": "موردانو",
                "label_fr": "Mordano"
            },
            {
                "value": 46035,
                "label": "Montecchio Emilia",
                "label_ar": "مونتيكيو إميليا",
                "label_fr": "Montecchio Emilia"
            },
            {
                "value": 46036,
                "label": "Bagnacavallo",
                "label_ar": "Bagnacavallo",
                "label_fr": "Bagnacavallo"
            },
            {
                "value": 46037,
                "label": "Bagnolo in Piano",
                "label_ar": "Bagnolo في بيانو",
                "label_fr": "Bagnolo au piano"
            },
            {
                "value": 46038,
                "label": "Copparo",
                "label_ar": "كوبارو",
                "label_fr": "Copparo"
            },
            {
                "value": 46039,
                "label": "Pianoro",
                "label_ar": "بيانورو",
                "label_fr": "Pianoro"
            },
            {
                "value": 46040,
                "label": "Savignano sul Rubicone",
                "label_ar": "سافينانو سول روبيكون",
                "label_fr": "Savignano sul Rubicone"
            },
            {
                "value": 46041,
                "label": "Finale Emilia",
                "label_ar": "خاتمة إميليا",
                "label_fr": "Finale Emilia"
            },
            {
                "value": 46042,
                "label": "Rosolina",
                "label_ar": "روزولينا",
                "label_fr": "Rosolina"
            },
            {
                "value": 46043,
                "label": "Ficarolo",
                "label_ar": "فيكارولو",
                "label_fr": "Ficarolo"
            },
            {
                "value": 46044,
                "label": "Brugine",
                "label_ar": "بروجين",
                "label_fr": "Brugine"
            },
            {
                "value": 46045,
                "label": "Oppeano",
                "label_ar": "أوبيانو",
                "label_fr": "Oppeano"
            },
            {
                "value": 46046,
                "label": "Marano Vicentino",
                "label_ar": "مارانو فيسينتينو",
                "label_fr": "Marano Vicentino"
            },
            {
                "value": 46047,
                "label": "San Pietro in Gu",
                "label_ar": "سان بيترو في جو",
                "label_fr": "San Pietro in Gu"
            },
            {
                "value": 46048,
                "label": "Villadose",
                "label_ar": "فيلاادوز",
                "label_fr": "Villadose"
            },
            {
                "value": 46049,
                "label": "Agna",
                "label_ar": "اجنا",
                "label_fr": "Agna"
            },
            {
                "value": 46050,
                "label": "Noventa Vicentina",
                "label_ar": "نوفينتا فيسينتينا",
                "label_fr": "Noventa Vicentina"
            },
            {
                "value": 46051,
                "label": "Villaggio Montegrappa",
                "label_ar": "فيلاجيو مونتيغرابا",
                "label_fr": "Villaggio Montegrappa"
            },
            {
                "value": 46052,
                "label": "Castelmassa",
                "label_ar": "كاستلماسا",
                "label_fr": "Castelmassa"
            },
            {
                "value": 46053,
                "label": "Altavilla Vicentina",
                "label_ar": "ألتافيلا فيسينتينا",
                "label_fr": "Altavilla Vicentina"
            },
            {
                "value": 46054,
                "label": "Tezze sul Brenta",
                "label_ar": "Tezze sul Brenta",
                "label_fr": "Tezze sul Brenta"
            },
            {
                "value": 46055,
                "label": "Vescovana",
                "label_ar": "فيسكوفانا",
                "label_fr": "Vescovana"
            },
            {
                "value": 46056,
                "label": "Teolo",
                "label_ar": "تيولو",
                "label_fr": "Teolo"
            },
            {
                "value": 46057,
                "label": "Vigonza",
                "label_ar": "فيجونزا",
                "label_fr": "Vigonza"
            },
            {
                "value": 46058,
                "label": "Zevio",
                "label_ar": "زيفيو",
                "label_fr": "Zevio"
            },
            {
                "value": 46059,
                "label": "Recoaro Terme",
                "label_ar": "ريكوارو تيرمي",
                "label_fr": "Recoaro Terme"
            },
            {
                "value": 46060,
                "label": "Sterzing",
                "label_ar": "التعقيم",
                "label_fr": "Sterzing"
            },
            {
                "value": 46061,
                "label": "Castel",
                "label_ar": "كاستل",
                "label_fr": "Castel"
            },
            {
                "value": 46062,
                "label": "Sanguinetto",
                "label_ar": "سانجينيتو",
                "label_fr": "Sanguinetto"
            },
            {
                "value": 46063,
                "label": "Sommacampagna",
                "label_ar": "Sommacampagna",
                "label_fr": "Sommacampagna"
            },
            {
                "value": 46064,
                "label": "Maniago",
                "label_ar": "مانياجو",
                "label_fr": "Maniago"
            },
            {
                "value": 46065,
                "label": "Casinalbo",
                "label_ar": "Casinalbo",
                "label_fr": "Casinalbo"
            },
            {
                "value": 46066,
                "label": "Felino",
                "label_ar": "فيلينو",
                "label_fr": "Felino"
            },
            {
                "value": 46067,
                "label": "Mirandola",
                "label_ar": "ميراندولا",
                "label_fr": "Mirandola"
            },
            {
                "value": 46068,
                "label": "Mansuè",
                "label_ar": "مانسو",
                "label_fr": "Mansuè"
            },
            {
                "value": 46069,
                "label": "Martellago",
                "label_ar": "Martellago",
                "label_fr": "Martellago"
            },
            {
                "value": 46070,
                "label": "Motta di Livenza",
                "label_ar": "موتا دي ليفينزا",
                "label_fr": "Motta di Livenza"
            },
            {
                "value": 46071,
                "label": "Azzano Decimo",
                "label_ar": "أزانو ديسيمو",
                "label_fr": "Azzano Decimo"
            },
            {
                "value": 46072,
                "label": "Urbisaglia",
                "label_ar": "أوربيساجليا",
                "label_fr": "Urbisaglia"
            },
            {
                "value": 46073,
                "label": "L'Aquila",
                "label_ar": "لاكويلا",
                "label_fr": "L'Aquila"
            },
            {
                "value": 46074,
                "label": "Notaresco",
                "label_ar": "نوتارسكو",
                "label_fr": "Notaresco"
            },
            {
                "value": 46075,
                "label": "Civitanova Marche",
                "label_ar": "سيفيتانوفا ماركي",
                "label_fr": "Civitanova Marche"
            },
            {
                "value": 46076,
                "label": "Castorano",
                "label_ar": "كاستورانو",
                "label_fr": "Castorano"
            },
            {
                "value": 46077,
                "label": "Montegranaro",
                "label_ar": "مونتيغرانارو",
                "label_fr": "Montegranaro"
            },
            {
                "value": 46078,
                "label": "Folignano",
                "label_ar": "فولينيانو",
                "label_fr": "Folignano"
            },
            {
                "value": 46079,
                "label": "Lettomanoppello",
                "label_ar": "ليتومانوبيلو",
                "label_fr": "Lettomanoppello"
            },
            {
                "value": 46080,
                "label": "Maiolati Spontini",
                "label_ar": "مايولاتي سبونتيني",
                "label_fr": "Maiolati Spontini"
            },
            {
                "value": 46081,
                "label": "Cupello",
                "label_ar": "كوبيلو",
                "label_fr": "Cupello"
            },
            {
                "value": 46082,
                "label": "Urbania",
                "label_ar": "أوربانيا",
                "label_fr": "Urbania"
            },
            {
                "value": 46083,
                "label": "Agugliano",
                "label_ar": "أجوجليانو",
                "label_fr": "Agugliano"
            },
            {
                "value": 46084,
                "label": "Manoppello",
                "label_ar": "مانوبيلو",
                "label_fr": "Manoppello"
            },
            {
                "value": 46085,
                "label": "Mondolfo",
                "label_ar": "موندولفو",
                "label_fr": "Mondolfo"
            },
            {
                "value": 46086,
                "label": "Castelbellino",
                "label_ar": "كاستيلبيلينو",
                "label_fr": "Castelbellino"
            },
            {
                "value": 46087,
                "label": "Orsogna",
                "label_ar": "أورسوجنا",
                "label_fr": "Orsogna"
            },
            {
                "value": 46088,
                "label": "Ascoli Piceno",
                "label_ar": "أسكولي بيتشينو",
                "label_fr": "Ascoli Piceno"
            },
            {
                "value": 46089,
                "label": "Montesilvano Colle",
                "label_ar": "مونتيسيلفانو كولي",
                "label_fr": "Montesilvano Colle"
            },
            {
                "value": 46090,
                "label": "Casalbordino",
                "label_ar": "كاسالبوردينو",
                "label_fr": "Casalbordino"
            },
            {
                "value": 46091,
                "label": "Porto San Giorgio",
                "label_ar": "بورتو سان جورجيو",
                "label_fr": "Porto San Giorgio"
            },
            {
                "value": 46092,
                "label": "Sant'Angelo in Pontano",
                "label_ar": "سانت أنجيلو في بونتانو",
                "label_fr": "Sant'Angelo à Pontano"
            },
            {
                "value": 46093,
                "label": "Chieti",
                "label_ar": "كييتي",
                "label_fr": "Chieti"
            },
            {
                "value": 46094,
                "label": "Polverigi",
                "label_ar": "بولفيريجي",
                "label_fr": "Polverigi"
            },
            {
                "value": 46095,
                "label": "Oratino",
                "label_ar": "اوراتينو",
                "label_fr": "Oratino"
            },
            {
                "value": 46096,
                "label": "Frosinone",
                "label_ar": "فروزينوني",
                "label_fr": "Frosinone"
            },
            {
                "value": 46097,
                "label": "Silvi Paese",
                "label_ar": "سيلفي بايسي",
                "label_fr": "Silvi Paese"
            },
            {
                "value": 46098,
                "label": "Iesi",
                "label_ar": "إيسي",
                "label_fr": "Iesi"
            },
            {
                "value": 46099,
                "label": "San Benedetto del Tronto",
                "label_ar": "سان بنديتو ديل ترونتو",
                "label_fr": "San Benedetto del Tronto"
            },
            {
                "value": 46100,
                "label": "Porto",
                "label_ar": "بورتو",
                "label_fr": "Porto"
            },
            {
                "value": 46101,
                "label": "Lucca",
                "label_ar": "لوكا",
                "label_fr": "Lucques"
            },
            {
                "value": 46102,
                "label": "Empoli",
                "label_ar": "إمبولي",
                "label_fr": "Empoli"
            },
            {
                "value": 46103,
                "label": "Ferentino",
                "label_ar": "فيرينتينو",
                "label_fr": "Ferentino"
            },
            {
                "value": 46104,
                "label": "Sermoneta",
                "label_ar": "سيرمونيتا",
                "label_fr": "Sermoneta"
            },
            {
                "value": 46105,
                "label": "Santa Marinella",
                "label_ar": "سانتا مارينيلا",
                "label_fr": "Santa Marinella"
            },
            {
                "value": 46106,
                "label": "Fiumicino",
                "label_ar": "فيوميتشينو",
                "label_fr": "Fiumicino"
            },
            {
                "value": 46107,
                "label": "Ardea",
                "label_ar": "أرديا",
                "label_fr": "Ardea"
            },
            {
                "value": 46108,
                "label": "Castel Madama",
                "label_ar": "كاستل ماداما",
                "label_fr": "Castel Madama"
            },
            {
                "value": 46109,
                "label": "Ischia di Castro",
                "label_ar": "ايشيا دي كاسترو",
                "label_fr": "Ischia di Castro"
            },
            {
                "value": 46110,
                "label": "Bassano Romano",
                "label_ar": "باسانو رومانو",
                "label_fr": "Bassano Romano"
            },
            {
                "value": 46111,
                "label": "Mineo",
                "label_ar": "مينيو",
                "label_fr": "Mineo"
            },
            {
                "value": 46112,
                "label": "Bronte",
                "label_ar": "برونتي",
                "label_fr": "Bronte"
            },
            {
                "value": 46113,
                "label": "Capo d'Orlando",
                "label_ar": "كابو دورلاندو",
                "label_fr": "Capo d'Orlando"
            },
            {
                "value": 46114,
                "label": "Motta San Giovanni",
                "label_ar": "موتا سان جيوفاني",
                "label_fr": "Motta San Giovanni"
            },
            {
                "value": 46115,
                "label": "Aci Sant'Antonio",
                "label_ar": "Aci Sant'Antonio",
                "label_fr": "Aci Sant'Antonio"
            },
            {
                "value": 46116,
                "label": "Melilli",
                "label_ar": "ميليلي",
                "label_fr": "Melilli"
            },
            {
                "value": 46117,
                "label": "Piedimonte Etneo",
                "label_ar": "بيديمونتي إتنيو",
                "label_fr": "Piedimonte Etneo"
            },
            {
                "value": 46118,
                "label": "Leonforte",
                "label_ar": "ليونفورتي",
                "label_fr": "Léonforte"
            },
            {
                "value": 46119,
                "label": "Agira",
                "label_ar": "أجيرا",
                "label_fr": "Agira"
            },
            {
                "value": 46120,
                "label": "Patti",
                "label_ar": "باتي",
                "label_fr": "Patti"
            },
            {
                "value": 46121,
                "label": "Pedara",
                "label_ar": "بيدارا",
                "label_fr": "Pedara"
            },
            {
                "value": 46122,
                "label": "Biancavilla",
                "label_ar": "بيانكافيلا",
                "label_fr": "Biancavilla"
            },
            {
                "value": 46123,
                "label": "Nicosia",
                "label_ar": "نيقوسيا",
                "label_fr": "Nicosie"
            },
            {
                "value": 46124,
                "label": "Spadafora",
                "label_ar": "سبادافورا",
                "label_fr": "Spadafora"
            },
            {
                "value": 46125,
                "label": "Cardito",
                "label_ar": "كارديتو",
                "label_fr": "Cardito"
            },
            {
                "value": 46126,
                "label": "Torre del Greco",
                "label_ar": "توري ديل جريكو",
                "label_fr": "Torre del Greco"
            },
            {
                "value": 46127,
                "label": "Pomigliano d'Arco",
                "label_ar": "بوميجليانو داركو",
                "label_fr": "Pomigliano d'Arco"
            },
            {
                "value": 46128,
                "label": "Telese",
                "label_ar": "تيليز",
                "label_fr": "Telese"
            },
            {
                "value": 46129,
                "label": "Giugliano in Campania",
                "label_ar": "جيوجليانو في كامبانيا",
                "label_fr": "Giugliano en Campanie"
            },
            {
                "value": 46130,
                "label": "Belmonte Mezzagno",
                "label_ar": "بلمونتي ميزاجنو",
                "label_fr": "Belmonte Mezzagno"
            },
            {
                "value": 46131,
                "label": "Termini Imerese",
                "label_ar": "تيرميني إيميريز",
                "label_fr": "Termini Imerese"
            },
            {
                "value": 46132,
                "label": "Salve",
                "label_ar": "مرهم",
                "label_fr": "Baume"
            },
            {
                "value": 46133,
                "label": "Andrano",
                "label_ar": "أندرانو",
                "label_fr": "Andrano"
            },
            {
                "value": 46134,
                "label": "San Marco Argentano",
                "label_ar": "سان ماركو أرجينتانو",
                "label_fr": "San Marco Argentano"
            },
            {
                "value": 46135,
                "label": "Reggio Calabria",
                "label_ar": "ريجيو كالابريا",
                "label_fr": "Reggio de Calabre"
            },
            {
                "value": 46136,
                "label": "Novoli",
                "label_ar": "نوفولي",
                "label_fr": "Novoli"
            },
            {
                "value": 46137,
                "label": "Cosenza",
                "label_ar": "كوزنسا",
                "label_fr": "Cosenza"
            },
            {
                "value": 46138,
                "label": "Triggiano",
                "label_ar": "تريجيانو",
                "label_fr": "Triggiano"
            },
            {
                "value": 46139,
                "label": "Trapani",
                "label_ar": "تراباني",
                "label_fr": "Trapani"
            },
            {
                "value": 46140,
                "label": "Aci Catena",
                "label_ar": "اسي كاتينا",
                "label_fr": "Aci Catena"
            },
            {
                "value": 46141,
                "label": "Sant'Agata di Militello",
                "label_ar": "Sant'Agata di Militello",
                "label_fr": "Sant'Agata di Militello"
            },
            {
                "value": 46142,
                "label": "Castenedolo",
                "label_ar": "كاستينيدولو",
                "label_fr": "Castenedolo"
            },
            {
                "value": 46143,
                "label": "Iseo",
                "label_ar": "إيزيو",
                "label_fr": "Iseo"
            },
            {
                "value": 46144,
                "label": "Candiolo",
                "label_ar": "كانديولو",
                "label_fr": "Candiolo"
            },
            {
                "value": 46145,
                "label": "Candelo",
                "label_ar": "كانديلو",
                "label_fr": "Candelo"
            },
            {
                "value": 46146,
                "label": "Zimone",
                "label_ar": "زيمون",
                "label_fr": "Zimone"
            },
            {
                "value": 46147,
                "label": "Sestriere",
                "label_ar": "سيستريير",
                "label_fr": "Sestrières"
            },
            {
                "value": 46148,
                "label": "Cogoleto",
                "label_ar": "كوجوليتو",
                "label_fr": "Cogoleto"
            },
            {
                "value": 46149,
                "label": "Borgomanero",
                "label_ar": "بورغومانيرو",
                "label_fr": "Borgomanero"
            },
            {
                "value": 46150,
                "label": "Porlezza",
                "label_ar": "بورليزا",
                "label_fr": "Porlezza"
            },
            {
                "value": 46151,
                "label": "Pontevico",
                "label_ar": "بونتيفيكو",
                "label_fr": "Pontevico"
            },
            {
                "value": 46152,
                "label": "Zogno",
                "label_ar": "زوجنو",
                "label_fr": "Zogno"
            },
            {
                "value": 46153,
                "label": "Belforte",
                "label_ar": "بلفورتي",
                "label_fr": "Belforte"
            },
            {
                "value": 46154,
                "label": "Piazza Brembana",
                "label_ar": "ساحة بريمبانا",
                "label_fr": "Piazza Brembana"
            },
            {
                "value": 46155,
                "label": "Rudiano",
                "label_ar": "روديانو",
                "label_fr": "Rudiano"
            },
            {
                "value": 46156,
                "label": "Nerviano",
                "label_ar": "نيرفيانو",
                "label_fr": "Nerviano"
            },
            {
                "value": 46157,
                "label": "Montemurlo",
                "label_ar": "مونتيمورلو",
                "label_fr": "Montemurlo"
            },
            {
                "value": 46158,
                "label": "Pomezia",
                "label_ar": "بوميزيا",
                "label_fr": "Pomezia"
            },
            {
                "value": 46159,
                "label": "Cisterna di Latina",
                "label_ar": "سيستيرنا دي لاتينا",
                "label_fr": "Cisterna di Latina"
            },
            {
                "value": 46160,
                "label": "Nettuno",
                "label_ar": "نيتونو",
                "label_fr": "Nettuno"
            },
            {
                "value": 46161,
                "label": "Civitavecchia",
                "label_ar": "سيفيتافيكيا",
                "label_fr": "Civitavecchia"
            },
            {
                "value": 46162,
                "label": "Campagnano di Roma",
                "label_ar": "كامباجنانو دي روما",
                "label_fr": "Campagnano di Roma"
            },
            {
                "value": 46163,
                "label": "Rieti",
                "label_ar": "رييتي",
                "label_fr": "Rieti"
            },
            {
                "value": 46164,
                "label": "Calcata",
                "label_ar": "كالكاتا",
                "label_fr": "Calcata"
            },
            {
                "value": 46165,
                "label": "Sabaudia",
                "label_ar": "الصابودية",
                "label_fr": "Sabaudia"
            },
            {
                "value": 46166,
                "label": "Genazzano",
                "label_ar": "جينازانو",
                "label_fr": "Genazzano"
            },
            {
                "value": 46167,
                "label": "Pontinia",
                "label_ar": "بونتينيا",
                "label_fr": "Pontinia"
            },
            {
                "value": 46168,
                "label": "Viterbo",
                "label_ar": "فيتربو",
                "label_fr": "Viterbe"
            },
            {
                "value": 46169,
                "label": "Olevano Romano",
                "label_ar": "اوليفانو رومانو",
                "label_fr": "Olevano Romano"
            },
            {
                "value": 46170,
                "label": "Cerveteri",
                "label_ar": "سيرفيتيري",
                "label_fr": "Cerveteri"
            },
            {
                "value": 46171,
                "label": "Sacrofano",
                "label_ar": "ساكروفانو",
                "label_fr": "Sacrofano"
            },
            {
                "value": 46172,
                "label": "Nepi",
                "label_ar": "نيبي",
                "label_fr": "Nepi"
            },
            {
                "value": 46173,
                "label": "Monte Porzio Catone",
                "label_ar": "مونتي بورزيو كاتوني",
                "label_fr": "Monte Porzio Catone"
            },
            {
                "value": 46174,
                "label": "Veroli",
                "label_ar": "فيرولي",
                "label_fr": "Veroli"
            },
            {
                "value": 46175,
                "label": "Ladispoli",
                "label_ar": "لاديسبولي",
                "label_fr": "Ladispoli"
            },
            {
                "value": 46176,
                "label": "Fornaci di Barga",
                "label_ar": "Fornaci di Barga",
                "label_fr": "Fornaci di Barga"
            },
            {
                "value": 46177,
                "label": "Signa",
                "label_ar": "Signa",
                "label_fr": "Signa"
            },
            {
                "value": 46178,
                "label": "Cascina",
                "label_ar": "كاسينا",
                "label_fr": "Cascina"
            },
            {
                "value": 46179,
                "label": "Porcari",
                "label_ar": "بوركاري",
                "label_fr": "Porcari"
            },
            {
                "value": 46180,
                "label": "Pescia",
                "label_ar": "بيسشيا",
                "label_fr": "Pescia"
            },
            {
                "value": 46181,
                "label": "Corciano",
                "label_ar": "كورشيانو",
                "label_fr": "Corciano"
            },
            {
                "value": 46182,
                "label": "Fucecchio",
                "label_ar": "فوتشيو",
                "label_fr": "Fucecchio"
            },
            {
                "value": 46183,
                "label": "Colle di Val d'Elsa",
                "label_ar": "كولي دي فال ديلسا",
                "label_fr": "Colle di Val d'Elsa"
            },
            {
                "value": 46184,
                "label": "Grosseto",
                "label_ar": "غروسيتو",
                "label_fr": "Grosseto"
            },
            {
                "value": 46185,
                "label": "Capannoli",
                "label_ar": "كابانولي",
                "label_fr": "Capannoli"
            },
            {
                "value": 46186,
                "label": "Quarrata",
                "label_ar": "Quarrata",
                "label_fr": "Quarrata"
            },
            {
                "value": 46187,
                "label": "Montelupo Fiorentino",
                "label_ar": "مونتيلوبو فيورنتينو",
                "label_fr": "Montelupo Fiorentino"
            },
            {
                "value": 46188,
                "label": "Magliano",
                "label_ar": "ماجليانو",
                "label_fr": "Magliano"
            },
            {
                "value": 46189,
                "label": "Cervaro",
                "label_ar": "سيرفارو",
                "label_fr": "Cervaro"
            },
            {
                "value": 46190,
                "label": "Sarroch",
                "label_ar": "ساروتش",
                "label_fr": "Sarroch"
            },
            {
                "value": 46191,
                "label": "Villaurbana",
                "label_ar": "فيلاوربانا",
                "label_fr": "Villaurbana"
            },
            {
                "value": 46192,
                "label": "Elmas",
                "label_ar": "إلماس",
                "label_fr": "Elmas"
            },
            {
                "value": 46193,
                "label": "Muravera",
                "label_ar": "مورافيرا",
                "label_fr": "Muravera"
            },
            {
                "value": 46194,
                "label": "Sassari",
                "label_ar": "ساساري",
                "label_fr": "Sassari"
            },
            {
                "value": 46195,
                "label": "Sorso",
                "label_ar": "سورسو",
                "label_fr": "Sorso"
            },
            {
                "value": 46196,
                "label": "Arbus",
                "label_ar": "أربوس",
                "label_fr": "Arbus"
            },
            {
                "value": 46197,
                "label": "Atzara",
                "label_ar": "أتزارا",
                "label_fr": "Atzara"
            },
            {
                "value": 46198,
                "label": "Soleminis",
                "label_ar": "Soleminis",
                "label_fr": "Soleminis"
            },
            {
                "value": 46199,
                "label": "Olmedo",
                "label_ar": "أولميدو",
                "label_fr": "Olmedo"
            },
            {
                "value": 46200,
                "label": "Gonnostramatza",
                "label_ar": "جونوستراماتزا",
                "label_fr": "Gonnostramatza"
            },
            {
                "value": 46201,
                "label": "Terralba",
                "label_ar": "تيرالبا",
                "label_fr": "Terralba"
            },
            {
                "value": 46202,
                "label": "Villanovafranca",
                "label_ar": "فيلانوفافرانكا",
                "label_fr": "Villanovafranca"
            },
            {
                "value": 46203,
                "label": "Marrubiu",
                "label_ar": "ماروبيو",
                "label_fr": "Marrubiu"
            },
            {
                "value": 46204,
                "label": "Masainas",
                "label_ar": "ماسيناس",
                "label_fr": "Masainas"
            },
            {
                "value": 46205,
                "label": "Ussana",
                "label_ar": "أوسانا",
                "label_fr": "Ussana"
            },
            {
                "value": 46206,
                "label": "Alghero",
                "label_ar": "ألغيرو",
                "label_fr": "Alghero"
            },
            {
                "value": 46207,
                "label": "Ossi",
                "label_ar": "أوسي",
                "label_fr": "Ossi"
            },
            {
                "value": 46208,
                "label": "Villa San Pietro",
                "label_ar": "فيلا سان بيترو",
                "label_fr": "Villa San Pietro"
            },
            {
                "value": 46209,
                "label": "Oristano",
                "label_ar": "أوريستانو",
                "label_fr": "Oristano"
            },
            {
                "value": 46210,
                "label": "Musei",
                "label_ar": "موسى",
                "label_fr": "Musei"
            },
            {
                "value": 46211,
                "label": "Samassi",
                "label_ar": "سماسي",
                "label_fr": "Samassi"
            },
            {
                "value": 46212,
                "label": "Villaputzu",
                "label_ar": "فيلابوتسو",
                "label_fr": "Villaputzu"
            },
            {
                "value": 46213,
                "label": "Siniscola",
                "label_ar": "سينيسكولا",
                "label_fr": "Siniscola"
            },
            {
                "value": 46214,
                "label": "Cusano Milanino",
                "label_ar": "كوسانو ميلانينو",
                "label_fr": "Cusano Milanino"
            },
            {
                "value": 46215,
                "label": "San Giuliano Milanese",
                "label_ar": "سان جوليانو ميلانيز",
                "label_fr": "San Giuliano Milanese"
            },
            {
                "value": 46216,
                "label": "Grandate",
                "label_ar": "جراند",
                "label_fr": "Grandat"
            },
            {
                "value": 46217,
                "label": "Bareggio",
                "label_ar": "باريجيو",
                "label_fr": "Bareggio"
            },
            {
                "value": 46218,
                "label": "Seregno",
                "label_ar": "سيريجنو",
                "label_fr": "Seregno"
            },
            {
                "value": 46219,
                "label": "Cantu",
                "label_ar": "كانتو",
                "label_fr": "Cantu"
            },
            {
                "value": 46220,
                "label": "Baranzate",
                "label_ar": "بارانزات",
                "label_fr": "Baranzate"
            },
            {
                "value": 46221,
                "label": "Colico",
                "label_ar": "كوليكو",
                "label_fr": "Colico"
            },
            {
                "value": 46222,
                "label": "Merate",
                "label_ar": "ميريت",
                "label_fr": "Merate"
            },
            {
                "value": 46223,
                "label": "Romano di Lombardia",
                "label_ar": "رومانو دي لومبارديا",
                "label_fr": "Romano di Lombardia"
            },
            {
                "value": 46224,
                "label": "Fiorenzuola d'Arda",
                "label_ar": "Fiorenzuola d'Arda",
                "label_fr": "Fiorenzuola d'Arda"
            },
            {
                "value": 46225,
                "label": "Sarezzo",
                "label_ar": "ساريزو",
                "label_fr": "Sarezzo"
            },
            {
                "value": 46226,
                "label": "Stresa",
                "label_ar": "ستريسا",
                "label_fr": "Stresa"
            },
            {
                "value": 46227,
                "label": "Forno Canavese",
                "label_ar": "فورنو كانافيزي",
                "label_fr": "Forno Canavese"
            },
            {
                "value": 46228,
                "label": "Chivasso",
                "label_ar": "شيفاسو",
                "label_fr": "Chivasso"
            },
            {
                "value": 46229,
                "label": "Crevalcore",
                "label_ar": "كريفالكور",
                "label_fr": "Crevalcore"
            },
            {
                "value": 46230,
                "label": "Granarolo dell'Emilia e Viadagola",
                "label_ar": "Granarolo dell'Emilia e Viadagola",
                "label_fr": "Granarolo dell'Emilia e Viadagola"
            },
            {
                "value": 46231,
                "label": "Cervignano del Friuli",
                "label_ar": "سيرفيغنانو ديل فريولي",
                "label_fr": "Cervignano del Friuli"
            },
            {
                "value": 46232,
                "label": "Conegliano",
                "label_ar": "كونجليانو",
                "label_fr": "Conegliano"
            },
            {
                "value": 46233,
                "label": "Novellara",
                "label_ar": "نوفيلارا",
                "label_fr": "Novellara"
            },
            {
                "value": 46234,
                "label": "Assisi",
                "label_ar": "أسيزي",
                "label_fr": "Assise"
            },
            {
                "value": 46235,
                "label": "Foligno",
                "label_ar": "فولينيو",
                "label_fr": "Foligno"
            },
            {
                "value": 46236,
                "label": "Dolo",
                "label_ar": "دولو",
                "label_fr": "Dolo"
            },
            {
                "value": 46237,
                "label": "Cadelbosco di Sopra",
                "label_ar": "كادلبوسكو دي سوبرا",
                "label_fr": "Cadelbosco di Sopra"
            },
            {
                "value": 46238,
                "label": "Gualdo Tadino",
                "label_ar": "جوالدو تادينو",
                "label_fr": "Gualdo Tadino"
            },
            {
                "value": 46239,
                "label": "Vignola",
                "label_ar": "فيجنولا",
                "label_fr": "Vignola"
            },
            {
                "value": 46240,
                "label": "Faenza",
                "label_ar": "فاينزا",
                "label_fr": "Faenza"
            },
            {
                "value": 46241,
                "label": "Valdobbiadene",
                "label_ar": "فالدوبيادين",
                "label_fr": "Valdobbiadene"
            },
            {
                "value": 46242,
                "label": "Monterenzio",
                "label_ar": "مونترينزيو",
                "label_fr": "Monterenzio"
            },
            {
                "value": 46243,
                "label": "Novi di Modena",
                "label_ar": "نوفي دي مودينا",
                "label_fr": "Novi di Modena"
            },
            {
                "value": 46244,
                "label": "Medicina",
                "label_ar": "ميديسينا",
                "label_fr": "Médicine"
            },
            {
                "value": 46245,
                "label": "Palau",
                "label_ar": "بالاو",
                "label_fr": "Palau"
            },
            {
                "value": 46246,
                "label": "Villaspeciosa",
                "label_ar": "فيلاسبيسيوزا",
                "label_fr": "Villaspeciosa"
            },
            {
                "value": 46247,
                "label": "Cagliari",
                "label_ar": "كالياري",
                "label_fr": "Cagliari"
            },
            {
                "value": 46248,
                "label": "Quartu Sant'Elena",
                "label_ar": "كوارتو سانت إلينا",
                "label_fr": "Quartu Sant'Elena"
            },
            {
                "value": 46249,
                "label": "Guasila",
                "label_ar": "جواسيلا",
                "label_fr": "Guasila"
            },
            {
                "value": 46250,
                "label": "Castelfidardo",
                "label_ar": "كاستلفيداردو",
                "label_fr": "Castelfidardo"
            },
            {
                "value": 46251,
                "label": "Ostra Vetere",
                "label_ar": "اوسترا فيتيري",
                "label_fr": "Ostra Vetere"
            },
            {
                "value": 46252,
                "label": "San Giovanni",
                "label_ar": "سان جيوفاني",
                "label_fr": "San Giovanni"
            },
            {
                "value": 46253,
                "label": "Macerata",
                "label_ar": "ماشيراتا",
                "label_fr": "Macerata"
            },
            {
                "value": 46254,
                "label": "Vasto",
                "label_ar": "فاستو",
                "label_fr": "Vasto"
            },
            {
                "value": 46255,
                "label": "Osimo",
                "label_ar": "اوسيمو",
                "label_fr": "Osimo"
            },
            {
                "value": 46256,
                "label": "Fabriano",
                "label_ar": "فابريانو",
                "label_fr": "Fabriano"
            },
            {
                "value": 46257,
                "label": "Chiaravalle",
                "label_ar": "Chiaravalle",
                "label_fr": "Chiaravalle"
            },
            {
                "value": 46258,
                "label": "Campobasso",
                "label_ar": "كامبوباسو",
                "label_fr": "Campobasso"
            },
            {
                "value": 46259,
                "label": "Recanati",
                "label_ar": "ريكاناتي",
                "label_fr": "Recanati"
            },
            {
                "value": 46260,
                "label": "San Casciano in Val di Pesa",
                "label_ar": "سان كاسيانو في فال دي بيسا",
                "label_fr": "San Casciano à Val di Pesa"
            },
            {
                "value": 46261,
                "label": "Montespertoli",
                "label_ar": "مونتيسبيرتولي",
                "label_fr": "Montespertoli"
            },
            {
                "value": 46262,
                "label": "Genzano di Roma",
                "label_ar": "جينزانو دي روما",
                "label_fr": "Genzano di Roma"
            },
            {
                "value": 46263,
                "label": "Bagno a Ripoli",
                "label_ar": "Bagno a Ripoli",
                "label_fr": "Bagno a Ripoli"
            },
            {
                "value": 46264,
                "label": "Piombino",
                "label_ar": "بيومبينو",
                "label_fr": "Piombino"
            },
            {
                "value": 46265,
                "label": "Poggibonsi",
                "label_ar": "بوجيبونسي",
                "label_fr": "Poggibonsi"
            },
            {
                "value": 46266,
                "label": "Loro Ciuffenna",
                "label_ar": "لورو سيفينا",
                "label_fr": "Loro Ciuffenna"
            },
            {
                "value": 46267,
                "label": "Bocchignano",
                "label_ar": "بوكينيانو",
                "label_fr": "Bocchignano"
            },
            {
                "value": 46268,
                "label": "Bracciano",
                "label_ar": "براتشيانو",
                "label_fr": "Bracciano"
            },
            {
                "value": 46269,
                "label": "Foggia",
                "label_ar": "فوجيا",
                "label_fr": "Foggia"
            },
            {
                "value": 46270,
                "label": "Melfi",
                "label_ar": "ملفي",
                "label_fr": "Melfi"
            },
            {
                "value": 46271,
                "label": "Caivano",
                "label_ar": "كيفانو",
                "label_fr": "Caivano"
            },
            {
                "value": 46272,
                "label": "Ariano Irpino",
                "label_ar": "اريانو ايربينو",
                "label_fr": "Ariano Irpino"
            },
            {
                "value": 46273,
                "label": "Avella",
                "label_ar": "أفيلا",
                "label_fr": "Avella"
            },
            {
                "value": 46274,
                "label": "Portici",
                "label_ar": "بورتيشي",
                "label_fr": "Portici"
            },
            {
                "value": 46275,
                "label": "Caserta",
                "label_ar": "كاسيرتا",
                "label_fr": "Caserte"
            },
            {
                "value": 46276,
                "label": "Catanzaro",
                "label_ar": "كاتانزارو",
                "label_fr": "Catanzaro"
            },
            {
                "value": 46277,
                "label": "Andria",
                "label_ar": "أندريا",
                "label_fr": "Andria"
            },
            {
                "value": 46278,
                "label": "Santeramo in Colle",
                "label_ar": "سانترامو في كولي",
                "label_fr": "Santeramo à Colle"
            },
            {
                "value": 46279,
                "label": "Terlizzi",
                "label_ar": "Terlizzi",
                "label_fr": "Terlizzi"
            },
            {
                "value": 46280,
                "label": "Altamura",
                "label_ar": "ألتامورا",
                "label_fr": "Altamura"
            },
            {
                "value": 46281,
                "label": "Caltagirone",
                "label_ar": "كالتاجيرون",
                "label_fr": "Caltagirone"
            },
            {
                "value": 46282,
                "label": "Piazza Armerina",
                "label_ar": "ساحة أرميرينا",
                "label_fr": "Piazza Armerina"
            },
            {
                "value": 46283,
                "label": "Niscemi",
                "label_ar": "نيسيمي",
                "label_fr": "Niscemi"
            },
            {
                "value": 46284,
                "label": "Lentini",
                "label_ar": "لينتيني",
                "label_fr": "Lentini"
            },
            {
                "value": 46285,
                "label": "Caltanissetta",
                "label_ar": "كالتانيسيتا",
                "label_fr": "Caltanissetta"
            },
            {
                "value": 46286,
                "label": "Palma di Montechiaro",
                "label_ar": "بالما دي مونتيشيارو",
                "label_fr": "Palma di Montechiaro"
            },
            {
                "value": 46287,
                "label": "Piano di Sorrento",
                "label_ar": "بيانو دي سورينتو",
                "label_fr": "Piano di Sorrento"
            },
            {
                "value": 46288,
                "label": "Manduria",
                "label_ar": "ماندوريا",
                "label_fr": "Manduria"
            },
            {
                "value": 46289,
                "label": "Acquaviva delle Fonti",
                "label_ar": "أكوافيفا ديلي فونتي",
                "label_fr": "Acquaviva delle Fonti"
            },
            {
                "value": 46290,
                "label": "Pieve d'Olmi",
                "label_ar": "بيف دولمي",
                "label_fr": "Pieve d'Olmi"
            },
            {
                "value": 46291,
                "label": "Misinto",
                "label_ar": "ميسينتو",
                "label_fr": "Misinto"
            },
            {
                "value": 46292,
                "label": "Torre",
                "label_ar": "توري",
                "label_fr": "Torre"
            },
            {
                "value": 46293,
                "label": "Zerfaliu",
                "label_ar": "زرفاليو",
                "label_fr": "Zerfaliu"
            },
            {
                "value": 46294,
                "label": "Lacchiarella",
                "label_ar": "لاكياريلا",
                "label_fr": "Lacchiarella"
            },
            {
                "value": 46295,
                "label": "Mirano",
                "label_ar": "ميرانو",
                "label_fr": "Mirano"
            },
            {
                "value": 46296,
                "label": "Tivoli",
                "label_ar": "تيفولي",
                "label_fr": "Tivoli"
            },
            {
                "value": 46297,
                "label": "Villetta",
                "label_ar": "فيليتا",
                "label_fr": "Villetta"
            },
            {
                "value": 46298,
                "label": "Lido",
                "label_ar": "ليدو",
                "label_fr": "Piscine"
            },
            {
                "value": 46299,
                "label": "Urtijëi",
                "label_ar": "Urtijëi",
                "label_fr": "Urtijëi"
            },
            {
                "value": 46300,
                "label": "Fiume Veneto",
                "label_ar": "فيومي فينيتو",
                "label_fr": "Fiume Veneto"
            },
            {
                "value": 46301,
                "label": "Olivetta",
                "label_ar": "أوليفيتا",
                "label_fr": "Olivetta"
            },
            {
                "value": 46302,
                "label": "Airole",
                "label_ar": "الهوائي",
                "label_fr": "Airole"
            },
            {
                "value": 46303,
                "label": "Quart",
                "label_ar": "ربع",
                "label_fr": "Litre"
            },
            {
                "value": 46304,
                "label": "Vallecrosia",
                "label_ar": "فاليكروزيا",
                "label_fr": "Vallecrosia"
            },
            {
                "value": 46305,
                "label": "Rovello Porro",
                "label_ar": "روفيلو بورو",
                "label_fr": "Rovello Porro"
            },
            {
                "value": 46306,
                "label": "Moso",
                "label_ar": "موسو",
                "label_fr": "Moso"
            },
            {
                "value": 46307,
                "label": "Villagrazia",
                "label_ar": "فيلاجرازيا",
                "label_fr": "Villagrazia"
            },
            {
                "value": 46308,
                "label": "Piemonte",
                "label_ar": "بيمونتي",
                "label_fr": "Piémont"
            },
            {
                "value": 46309,
                "label": "Antico di Maiolo",
                "label_ar": "أنتيكو دي مايولو",
                "label_fr": "Antico di Maiolo"
            },
            {
                "value": 46310,
                "label": "Romana",
                "label_ar": "رومانا",
                "label_fr": "Romana"
            },
            {
                "value": 46311,
                "label": "San Miniato",
                "label_ar": "سان مينياتو",
                "label_fr": "San Miniato"
            },
            {
                "value": 46312,
                "label": "Afragola",
                "label_ar": "أفراجولا",
                "label_fr": "Afragola"
            },
            {
                "value": 46313,
                "label": "Cava de' Tirreni",
                "label_ar": "كافا دي تيريني",
                "label_fr": "Cava de 'Tirreni"
            },
            {
                "value": 46314,
                "label": "Ragusa",
                "label_ar": "راغوزا",
                "label_fr": "Raguse"
            },
            {
                "value": 46315,
                "label": "Ferrari",
                "label_ar": "فيراري",
                "label_fr": "Ferrari"
            },
            {
                "value": 46316,
                "label": "Torgiano",
                "label_ar": "تورجيانو",
                "label_fr": "Torgiano"
            },
            {
                "value": 46317,
                "label": "Anna",
                "label_ar": "آنا",
                "label_fr": "Anna"
            },
            {
                "value": 46318,
                "label": "Silvano d'Orba",
                "label_ar": "سيلفانو دوربا",
                "label_fr": "Silvano d'Orba"
            },
            {
                "value": 46319,
                "label": "Pineta",
                "label_ar": "بينيتا",
                "label_fr": "Pineta"
            },
            {
                "value": 46320,
                "label": "Cola",
                "label_ar": "الكولا",
                "label_fr": "Cola"
            },
            {
                "value": 46321,
                "label": "Modugno",
                "label_ar": "مودوجنو",
                "label_fr": "Modugno"
            },
            {
                "value": 46322,
                "label": "San Raineri",
                "label_ar": "سان راينري",
                "label_fr": "San Raineri"
            },
            {
                "value": 46323,
                "label": "San Giorgio",
                "label_ar": "سان جورجيو",
                "label_fr": "San Giorgio"
            },
            {
                "value": 46324,
                "label": "Salerno",
                "label_ar": "ساليرنو",
                "label_fr": "Salerne"
            },
            {
                "value": 46325,
                "label": "Campagna",
                "label_ar": "كامبانا",
                "label_fr": "Campagna"
            },
            {
                "value": 46326,
                "label": "San Cipriano Picentino",
                "label_ar": "سان سيبريانو بيسينتينو",
                "label_fr": "San Cipriano Picentino"
            },
            {
                "value": 46327,
                "label": "Brunella",
                "label_ar": "برونيلا",
                "label_fr": "Brunella"
            },
            {
                "value": 46328,
                "label": "Rosa",
                "label_ar": "روزا",
                "label_fr": "Rosa"
            },
            {
                "value": 46329,
                "label": "Fantina",
                "label_ar": "فانتينا",
                "label_fr": "Fantina"
            },
            {
                "value": 46330,
                "label": "San Giorgio",
                "label_ar": "سان جورجيو",
                "label_fr": "San Giorgio"
            },
            {
                "value": 46331,
                "label": "San Francesco",
                "label_ar": "سان فرانشيسكو",
                "label_fr": "San Francesco"
            },
            {
                "value": 46332,
                "label": "Piove di Sacco",
                "label_ar": "بيوفي دي ساكو",
                "label_fr": "Piove di Sacco"
            },
            {
                "value": 46333,
                "label": "Gioia",
                "label_ar": "جويا",
                "label_fr": "Gioia"
            },
            {
                "value": 46334,
                "label": "Gazzo Veronese",
                "label_ar": "جازو فيرونيز",
                "label_fr": "Gazzo Véronèse"
            },
            {
                "value": 46335,
                "label": "Corona",
                "label_ar": "كورونا",
                "label_fr": "Couronne"
            },
            {
                "value": 46336,
                "label": "Vado Ligure",
                "label_ar": "فادو ليغور",
                "label_fr": "Vado Ligure"
            },
            {
                "value": 46337,
                "label": "Zanetti",
                "label_ar": "زانيتي",
                "label_fr": "Zanetti"
            },
            {
                "value": 46338,
                "label": "Bruno",
                "label_ar": "برونو",
                "label_fr": "Bruno"
            },
            {
                "value": 46339,
                "label": "Bianco",
                "label_ar": "بيانكو",
                "label_fr": "Bianco"
            },
            {
                "value": 46340,
                "label": "Sale",
                "label_ar": "تخفيض السعر",
                "label_fr": "Vente"
            },
            {
                "value": 46341,
                "label": "Altofonte",
                "label_ar": "ألتوفونتي",
                "label_fr": "Altofonte"
            },
            {
                "value": 46342,
                "label": "Salsomaggiore Terme",
                "label_ar": "سالسوماجيوري تيرمي",
                "label_fr": "Salsomaggiore Terme"
            },
            {
                "value": 46343,
                "label": "Monteverde",
                "label_ar": "مونتيفردي",
                "label_fr": "Monteverde"
            },
            {
                "value": 46344,
                "label": "Marco",
                "label_ar": "ماركو",
                "label_fr": "Marco"
            },
            {
                "value": 46345,
                "label": "San Michele al Tagliamento",
                "label_ar": "سان ميشيل آل تاليامنتو",
                "label_fr": "San Michele al Tagliamento"
            },
            {
                "value": 46346,
                "label": "Sestu",
                "label_ar": "سيستو",
                "label_fr": "Sestu"
            },
            {
                "value": 46347,
                "label": "Positano",
                "label_ar": "بوسيتانو",
                "label_fr": "Positano"
            },
            {
                "value": 46348,
                "label": "Ravanusa",
                "label_ar": "رافانوسا",
                "label_fr": "Ravanusa"
            },
            {
                "value": 46349,
                "label": "Lecce",
                "label_ar": "ليتشي",
                "label_fr": "Lecce"
            },
            {
                "value": 46350,
                "label": "Portoscuso",
                "label_ar": "بورتوسكوسو",
                "label_fr": "Portoscuso"
            },
            {
                "value": 46351,
                "label": "Ranieri",
                "label_ar": "رانييري",
                "label_fr": "Ranieri"
            },
            {
                "value": 46352,
                "label": "Casali",
                "label_ar": "كاسالي",
                "label_fr": "Casali"
            },
            {
                "value": 46353,
                "label": "Forte dei Marmi",
                "label_ar": "فورتي دي مارمي",
                "label_fr": "Forte dei Marmi"
            },
            {
                "value": 46354,
                "label": "Nogarole Rocca",
                "label_ar": "نوغارول روكا",
                "label_fr": "Nogarole Rocca"
            },
            {
                "value": 46355,
                "label": "Crescentino",
                "label_ar": "كريسنتينو",
                "label_fr": "Crescentino"
            },
            {
                "value": 46356,
                "label": "Rivanazzano",
                "label_ar": "ريفاناتسانو",
                "label_fr": "Rivanazzano"
            },
            {
                "value": 46357,
                "label": "Taglio di Po",
                "label_ar": "تاجليو دي بو",
                "label_fr": "Taglio di Po"
            },
            {
                "value": 46358,
                "label": "Filottrano",
                "label_ar": "فيلوترانو",
                "label_fr": "Filottrano"
            },
            {
                "value": 46359,
                "label": "Cairo Montenotte",
                "label_ar": "كايرو مونتنوت",
                "label_fr": "Le Caire Montenotte"
            },
            {
                "value": 46360,
                "label": "Sesto al Reghena",
                "label_ar": "سيستو الرغينة",
                "label_fr": "Sesto al Reghena"
            },
            {
                "value": 46361,
                "label": "Cernusco sul Naviglio",
                "label_ar": "سيرنوسكو سول نافيجليو",
                "label_fr": "Cernusco sul Naviglio"
            },
            {
                "value": 46362,
                "label": "Dello",
                "label_ar": "ديلو",
                "label_fr": "Dello"
            },
            {
                "value": 46363,
                "label": "Vitorchiano",
                "label_ar": "فيتورشيانو",
                "label_fr": "Vitorchiano"
            },
            {
                "value": 46364,
                "label": "Modica",
                "label_ar": "موديكا",
                "label_fr": "Modica"
            },
            {
                "value": 46365,
                "label": "Lanusei",
                "label_ar": "لانوسي",
                "label_fr": "Lanusei"
            },
            {
                "value": 46366,
                "label": "Bassano del Grappa",
                "label_ar": "باسانو ديل جرابا",
                "label_fr": "Bassano del Grappa"
            },
            {
                "value": 46367,
                "label": "Angolo Terme",
                "label_ar": "أنجولو تيرمي",
                "label_fr": "Angolo Terme"
            },
            {
                "value": 46368,
                "label": "Bagheria",
                "label_ar": "باغيريا",
                "label_fr": "Bagheria"
            },
            {
                "value": 46369,
                "label": "Piedimonte Matese",
                "label_ar": "بيديمونتي ماتيسي",
                "label_fr": "Piedimonte Matese"
            },
            {
                "value": 46370,
                "label": "Sant Angelo",
                "label_ar": "سانت أنجيلو",
                "label_fr": "Sant Angelo"
            },
            {
                "value": 46371,
                "label": "Cortenova",
                "label_ar": "كورتينوفا",
                "label_fr": "Cortenova"
            },
            {
                "value": 46372,
                "label": "Bomporto",
                "label_ar": "بومبورتو",
                "label_fr": "Bomporto"
            },
            {
                "value": 46373,
                "label": "Pozzo di Gotto",
                "label_ar": "بوزو دي جوتو",
                "label_fr": "Pozzo di Gotto"
            },
            {
                "value": 46374,
                "label": "Braccagni",
                "label_ar": "Braccagni",
                "label_fr": "Braccagni"
            },
            {
                "value": 46375,
                "label": "Cassino",
                "label_ar": "كاسينو",
                "label_fr": "Cassino"
            },
            {
                "value": 46376,
                "label": "San Vendemiano",
                "label_ar": "سان فيندميانو",
                "label_fr": "San Vendemiano"
            },
            {
                "value": 46377,
                "label": "Carpeneto",
                "label_ar": "كاربينيتو",
                "label_fr": "Carpeneto"
            },
            {
                "value": 46378,
                "label": "Monticello",
                "label_ar": "مونتايسلو",
                "label_fr": "Monticello"
            },
            {
                "value": 46379,
                "label": "Mestre",
                "label_ar": "ميستر",
                "label_fr": "Mestre"
            },
            {
                "value": 46380,
                "label": "Cerasolo",
                "label_ar": "سيراسولو",
                "label_fr": "Cerasolo"
            },
            {
                "value": 46381,
                "label": "Ronchi dei Legionari",
                "label_ar": "Ronchi dei Legionari",
                "label_fr": "Ronchi dei Legionari"
            },
            {
                "value": 46382,
                "label": "Colorno",
                "label_ar": "كولورنو",
                "label_fr": "Colorno"
            },
            {
                "value": 46383,
                "label": "San Giovanni in Marignano",
                "label_ar": "سان جيوفاني في ماريجنانو",
                "label_fr": "San Giovanni in Marignano"
            },
            {
                "value": 46384,
                "label": "San Martino Buon Albergo",
                "label_ar": "سان مارتينو بون ألبيرغو",
                "label_fr": "San Martino Buon Albergo"
            },
            {
                "value": 46385,
                "label": "Castel Guelfo di Bologna",
                "label_ar": "قلعة جيلفو دي بولونيا",
                "label_fr": "Castel Guelfo di Bologne"
            },
            {
                "value": 46386,
                "label": "Monselice",
                "label_ar": "مونسيليس",
                "label_fr": "Monselice"
            },
            {
                "value": 46387,
                "label": "Aviano",
                "label_ar": "أفيانو",
                "label_fr": "Aviano"
            },
            {
                "value": 46388,
                "label": "Thiene",
                "label_ar": "ثيين",
                "label_fr": "Thiene"
            },
            {
                "value": 46389,
                "label": "Coriano",
                "label_ar": "كوريانو",
                "label_fr": "Coriano"
            },
            {
                "value": 46390,
                "label": "Adria",
                "label_ar": "أدريا",
                "label_fr": "Adria"
            },
            {
                "value": 46391,
                "label": "Fossò",
                "label_ar": "فوسو",
                "label_fr": "Fossò"
            },
            {
                "value": 46392,
                "label": "Vas",
                "label_ar": "فاس",
                "label_fr": "Vas"
            },
            {
                "value": 46393,
                "label": "Bertinoro",
                "label_ar": "بيرتينورو",
                "label_fr": "Bertinoro"
            },
            {
                "value": 46394,
                "label": "Podenzano",
                "label_ar": "بودينزانو",
                "label_fr": "Podenzano"
            },
            {
                "value": 46395,
                "label": "Caldogno",
                "label_ar": "كالدوجنو",
                "label_fr": "Caldogno"
            },
            {
                "value": 46396,
                "label": "Zoppola",
                "label_ar": "زوبولا",
                "label_fr": "Zoppola"
            },
            {
                "value": 46397,
                "label": "Cesenatico",
                "label_ar": "سيسيناتيكو",
                "label_fr": "Cesenatico"
            },
            {
                "value": 46398,
                "label": "Argelato",
                "label_ar": "أرجيلاتو",
                "label_fr": "Argelato"
            },
            {
                "value": 46399,
                "label": "Sandon",
                "label_ar": "ساندون",
                "label_fr": "Sandon"
            },
            {
                "value": 46400,
                "label": "San Pietro",
                "label_ar": "سان بيترو",
                "label_fr": "San Pietro"
            },
            {
                "value": 46401,
                "label": "Brendola",
                "label_ar": "بريندولا",
                "label_fr": "Brendola"
            },
            {
                "value": 46402,
                "label": "Cordenons",
                "label_ar": "كوردنونس",
                "label_fr": "Cordenons"
            },
            {
                "value": 46403,
                "label": "Pescantina",
                "label_ar": "بيسكانتينا",
                "label_fr": "Pescantina"
            },
            {
                "value": 46404,
                "label": "Roverbella",
                "label_ar": "Roverbella",
                "label_fr": "Roverbella"
            },
            {
                "value": 46405,
                "label": "Paese",
                "label_ar": "بايس",
                "label_fr": "Paese"
            },
            {
                "value": 46406,
                "label": "Carpi",
                "label_ar": "كاربي",
                "label_fr": "Carpi"
            },
            {
                "value": 46407,
                "label": "Sacile",
                "label_ar": "سكيل",
                "label_fr": "Sacile"
            },
            {
                "value": 46408,
                "label": "Cividale del Friuli",
                "label_ar": "سيفيدال ديل فريولي",
                "label_fr": "Cividale del Friuli"
            },
            {
                "value": 46409,
                "label": "Staranzano",
                "label_ar": "ستارانزانو",
                "label_fr": "Staranzano"
            },
            {
                "value": 46410,
                "label": "Latisana",
                "label_ar": "لاتيسانا",
                "label_fr": "Latisana"
            },
            {
                "value": 46411,
                "label": "Misano Adriatico",
                "label_ar": "ميسانو أدرياتيكو",
                "label_fr": "Misano Adriatico"
            },
            {
                "value": 46412,
                "label": "Campodarsego",
                "label_ar": "كامبودارسيغو",
                "label_fr": "Campodarsego"
            },
            {
                "value": 46413,
                "label": "Fogliano Redipuglia",
                "label_ar": "فوغليانو ريديبوغليا",
                "label_fr": "Fogliano Redipuglia"
            },
            {
                "value": 46414,
                "label": "Correggio",
                "label_ar": "كوريجيو",
                "label_fr": "Corrège"
            },
            {
                "value": 46415,
                "label": "Castelfranco di Sopra",
                "label_ar": "كاستلفرانكو دي سوبرا",
                "label_fr": "Castelfranco di Sopra"
            },
            {
                "value": 46416,
                "label": "Cadoneghe",
                "label_ar": "كادونيغي",
                "label_fr": "Cadonéghe"
            },
            {
                "value": 46417,
                "label": "Dozza",
                "label_ar": "دوزا",
                "label_fr": "Dozza"
            },
            {
                "value": 46418,
                "label": "Ala",
                "label_ar": "علاء",
                "label_fr": "Ala"
            },
            {
                "value": 46419,
                "label": "Marzabotto",
                "label_ar": "مرزبوتو",
                "label_fr": "Marzabotto"
            },
            {
                "value": 46420,
                "label": "Casale sul Sile",
                "label_ar": "كاسال سول سيل",
                "label_fr": "Casale sul Sile"
            },
            {
                "value": 46421,
                "label": "Borgo San Lorenzo",
                "label_ar": "بورجو سان لورينزو",
                "label_fr": "Borgo San Lorenzo"
            },
            {
                "value": 46422,
                "label": "Santa Vittoria d'Alba",
                "label_ar": "سانتا فيتوريا دي ألبا",
                "label_fr": "Santa Vittoria d'Alba"
            },
            {
                "value": 46423,
                "label": "Zanica",
                "label_ar": "زانيكا",
                "label_fr": "Zanica"
            },
            {
                "value": 46424,
                "label": "Magliano Alpi",
                "label_ar": "ماجليانو ألبي",
                "label_fr": "Magliano Alpi"
            },
            {
                "value": 46425,
                "label": "Ronco Scrivia",
                "label_ar": "رونكو سكريفيا",
                "label_fr": "Ronco Scrivia"
            },
            {
                "value": 46426,
                "label": "Moniga del Garda",
                "label_ar": "مونيغا ديل جاردا",
                "label_fr": "Moniga del Garda"
            },
            {
                "value": 46427,
                "label": "Canelli",
                "label_ar": "كانيلي",
                "label_fr": "Canelli"
            },
            {
                "value": 46428,
                "label": "Castel Mella",
                "label_ar": "كاستل ميلا",
                "label_fr": "Castel Mella"
            },
            {
                "value": 46429,
                "label": "Baldissero Torinese",
                "label_ar": "Baldissero Torinese",
                "label_fr": "Baldissero Torinese"
            },
            {
                "value": 46430,
                "label": "Vercelli",
                "label_ar": "فرشيلي",
                "label_fr": "Verceil"
            },
            {
                "value": 46431,
                "label": "Ortovero",
                "label_ar": "أورتوفيرو",
                "label_fr": "Ortovero"
            },
            {
                "value": 46432,
                "label": "Barlassina",
                "label_ar": "بارلاسينا",
                "label_fr": "Barlassina"
            },
            {
                "value": 46433,
                "label": "Castano Primo",
                "label_ar": "كاستانو بريمو",
                "label_fr": "Castano Primo"
            },
            {
                "value": 46434,
                "label": "Calcinato",
                "label_ar": "كالسيناتو",
                "label_fr": "Calcinato"
            },
            {
                "value": 46435,
                "label": "Verdello",
                "label_ar": "فيرديلو",
                "label_fr": "Verdello"
            },
            {
                "value": 46436,
                "label": "Varazze",
                "label_ar": "فاراتسي",
                "label_fr": "Varazze"
            },
            {
                "value": 46437,
                "label": "Cascine Maggio",
                "label_ar": "كاسين ماجيو",
                "label_fr": "Cascine Maggio"
            },
            {
                "value": 46438,
                "label": "Cossato",
                "label_ar": "كوساتو",
                "label_fr": "Cossato"
            },
            {
                "value": 46439,
                "label": "Uscio",
                "label_ar": "أوشيو",
                "label_fr": "Uscio"
            },
            {
                "value": 46440,
                "label": "Muggiò",
                "label_ar": "موجيتش",
                "label_fr": "Muggiò"
            },
            {
                "value": 46441,
                "label": "Vignate",
                "label_ar": "نقش",
                "label_fr": "Vignate"
            },
            {
                "value": 46442,
                "label": "Treviglio",
                "label_ar": "تريفيجليو",
                "label_fr": "Treviglio"
            },
            {
                "value": 46443,
                "label": "Sedriano",
                "label_ar": "سيدريانو",
                "label_fr": "Sedriano"
            },
            {
                "value": 46444,
                "label": "Roncadelle",
                "label_ar": "رونكاديل",
                "label_fr": "Roncadelle"
            },
            {
                "value": 46445,
                "label": "Quarona",
                "label_ar": "كوارونا",
                "label_fr": "Quarona"
            },
            {
                "value": 46446,
                "label": "Nova Milanese",
                "label_ar": "نوفا ميلانيز",
                "label_fr": "Nova Milanese"
            },
            {
                "value": 46447,
                "label": "Castiglione Olona",
                "label_ar": "كاستيجليون أولونا",
                "label_fr": "Castiglione Olona"
            },
            {
                "value": 46448,
                "label": "Sesto Calende",
                "label_ar": "سيستو كاليندي",
                "label_fr": "Sesto Calende"
            },
            {
                "value": 46449,
                "label": "Rovagnate",
                "label_ar": "روفاجنيت",
                "label_fr": "Rovagnate"
            },
            {
                "value": 46450,
                "label": "Chiavari",
                "label_ar": "شيافاري",
                "label_fr": "Chiavari"
            },
            {
                "value": 46451,
                "label": "Besozzo",
                "label_ar": "بيسوزو",
                "label_fr": "Besozzo"
            },
            {
                "value": 46452,
                "label": "Olgiate Olona",
                "label_ar": "أولجيات أولونا",
                "label_fr": "Olgiate Olona"
            },
            {
                "value": 46453,
                "label": "Pavone Canavese",
                "label_ar": "بافوني كانافيزي",
                "label_fr": "Pavone Canavese"
            },
            {
                "value": 46454,
                "label": "Ponte Nossa",
                "label_ar": "بونتي نوسا",
                "label_fr": "Ponte Nossa"
            },
            {
                "value": 46455,
                "label": "Melzo",
                "label_ar": "ميلزو",
                "label_fr": "Melzo"
            },
            {
                "value": 46456,
                "label": "Crispiano",
                "label_ar": "كريسبيانو",
                "label_fr": "Crispiano"
            },
            {
                "value": 46457,
                "label": "Agropoli",
                "label_ar": "أغروبولي",
                "label_fr": "Agropoli"
            },
            {
                "value": 46458,
                "label": "Scalea",
                "label_ar": "سكاليا",
                "label_fr": "Scalea"
            },
            {
                "value": 46459,
                "label": "Toritto",
                "label_ar": "توريتو",
                "label_fr": "Toritto"
            },
            {
                "value": 46460,
                "label": "Pozzallo",
                "label_ar": "بوزالو",
                "label_fr": "Pozzallo"
            },
            {
                "value": 46461,
                "label": "Manfredonia",
                "label_ar": "مانفريدونيا",
                "label_fr": "Manfredonia"
            },
            {
                "value": 46462,
                "label": "Corridonia",
                "label_ar": "كريدونيا",
                "label_fr": "Corridonia"
            },
            {
                "value": 46463,
                "label": "Montecorvino Rovella",
                "label_ar": "مونتيكورفينو روفيلا",
                "label_fr": "Montecorvino Rovella"
            },
            {
                "value": 46464,
                "label": "Ripe",
                "label_ar": "ناضج",
                "label_fr": "Mûr"
            },
            {
                "value": 46465,
                "label": "San Donaci",
                "label_ar": "سان دوناتشي",
                "label_fr": "San Donaci"
            },
            {
                "value": 46466,
                "label": "Villaricca",
                "label_ar": "فيلاريكا",
                "label_fr": "Villaricca"
            },
            {
                "value": 46467,
                "label": "Nocera Superiore",
                "label_ar": "نوسيرا سوبريوري",
                "label_fr": "Nocera Superiore"
            },
            {
                "value": 46468,
                "label": "Misterbianco",
                "label_ar": "Misterbianco",
                "label_fr": "Misterbianco"
            },
            {
                "value": 46469,
                "label": "Aversa",
                "label_ar": "أفيرسا",
                "label_fr": "Aversa"
            },
            {
                "value": 46470,
                "label": "Trentola-Ducenta",
                "label_ar": "ترينتولا دوسينتا",
                "label_fr": "Trentola-Ducenta"
            },
            {
                "value": 46471,
                "label": "Battipaglia",
                "label_ar": "باتيباجليا",
                "label_fr": "Battipaglia"
            },
            {
                "value": 46472,
                "label": "Saviano",
                "label_ar": "سافيانو",
                "label_fr": "Saviano"
            },
            {
                "value": 46473,
                "label": "San Giovanni la Punta",
                "label_ar": "سان جيوفاني لا بونتا",
                "label_fr": "San Giovanni la Punta"
            },
            {
                "value": 46474,
                "label": "Vico Equense",
                "label_ar": "فيكو إكوينس",
                "label_fr": "Vico Equense"
            },
            {
                "value": 46475,
                "label": "Laterza",
                "label_ar": "لاتيرزا",
                "label_fr": "Laterza"
            },
            {
                "value": 46476,
                "label": "Mazara del Vallo",
                "label_ar": "مازارا ديل فالو",
                "label_fr": "Mazara del Vallo"
            },
            {
                "value": 46477,
                "label": "Palo del Colle",
                "label_ar": "بالو ديل كولي",
                "label_fr": "Palo del Colle"
            },
            {
                "value": 46478,
                "label": "Torano Castello",
                "label_ar": "تورانو كاستيلو",
                "label_fr": "Torano Castello"
            },
            {
                "value": 46479,
                "label": "Castellammare di Stabia",
                "label_ar": "كاستيلاماري دي ستابيا",
                "label_fr": "Castellammare di Stabia"
            },
            {
                "value": 46480,
                "label": "Veglie",
                "label_ar": "فيجلي",
                "label_fr": "Veglie"
            },
            {
                "value": 46481,
                "label": "San Giorgio a Cremano",
                "label_ar": "سان جورجيو كريمانو",
                "label_fr": "San Giorgio a Cremano"
            },
            {
                "value": 46482,
                "label": "Marcianise",
                "label_ar": "مارسيانيس",
                "label_fr": "Marcianise"
            },
            {
                "value": 46483,
                "label": "Barletta",
                "label_ar": "بارليتا",
                "label_fr": "Barletta"
            },
            {
                "value": 46484,
                "label": "Trani",
                "label_ar": "تراني",
                "label_fr": "Trani"
            },
            {
                "value": 46485,
                "label": "Ercolano",
                "label_ar": "إركولانو",
                "label_fr": "Ercolano"
            },
            {
                "value": 46486,
                "label": "Frattamaggiore",
                "label_ar": "فراتاماجيوري",
                "label_fr": "Frattamaggiore"
            },
            {
                "value": 46487,
                "label": "San Tammaro",
                "label_ar": "سان تامارو",
                "label_fr": "San Tammaro"
            },
            {
                "value": 46488,
                "label": "Falconara Marittima",
                "label_ar": "فالكونارا ماريتيما",
                "label_fr": "Falconara Marittima"
            },
            {
                "value": 46489,
                "label": "Brindisi",
                "label_ar": "برينديزي",
                "label_fr": "Brindisi"
            },
            {
                "value": 46490,
                "label": "Ceglie Messapica",
                "label_ar": "سيجلي ميسابيكا",
                "label_fr": "Ceglie Messapica"
            },
            {
                "value": 46491,
                "label": "Nardò",
                "label_ar": "ناردي",
                "label_fr": "Nardò"
            },
            {
                "value": 46492,
                "label": "Matera",
                "label_ar": "ماتيرا",
                "label_fr": "Matera"
            },
            {
                "value": 46493,
                "label": "Molfetta",
                "label_ar": "مولفتا",
                "label_fr": "Molfetta"
            },
            {
                "value": 46494,
                "label": "Partinico",
                "label_ar": "بارتينيكو",
                "label_fr": "Partinico"
            },
            {
                "value": 46495,
                "label": "Balestrate-Foce",
                "label_ar": "باليسترات فوس",
                "label_fr": "Balestrate-Foce"
            },
            {
                "value": 46496,
                "label": "Carmiano",
                "label_ar": "كارميانو",
                "label_fr": "Carmiano"
            },
            {
                "value": 46497,
                "label": "Noicattaro",
                "label_ar": "Noicattaro",
                "label_fr": "Noicattaro"
            },
            {
                "value": 46498,
                "label": "Giovinazzo",
                "label_ar": "جيوفينازو",
                "label_fr": "Giovinazzo"
            },
            {
                "value": 46499,
                "label": "Grumo Nevano",
                "label_ar": "جرومو نيفانو",
                "label_fr": "Grumo Nevano"
            },
            {
                "value": 46500,
                "label": "Scorrano",
                "label_ar": "سكورانو",
                "label_fr": "Scorrano"
            },
            {
                "value": 46501,
                "label": "Ischia",
                "label_ar": "ايشيا",
                "label_fr": "Ischia"
            },
            {
                "value": 46502,
                "label": "Castelvenere",
                "label_ar": "كاستلفينير",
                "label_fr": "Castelvenere"
            },
            {
                "value": 46503,
                "label": "San Severo",
                "label_ar": "سان سيفيرو",
                "label_fr": "San Severo"
            },
            {
                "value": 46504,
                "label": "Sant'Arpino",
                "label_ar": "سانت أربينو",
                "label_fr": "Sant'Arpino"
            },
            {
                "value": 46505,
                "label": "Potenza",
                "label_ar": "بوتينزا",
                "label_fr": "Potenza"
            },
            {
                "value": 46506,
                "label": "Pagani",
                "label_ar": "باجاني",
                "label_fr": "Pagani"
            },
            {
                "value": 46507,
                "label": "Regalbuto",
                "label_ar": "Regalbuto",
                "label_fr": "Regalbuto"
            },
            {
                "value": 46508,
                "label": "Casapulla",
                "label_ar": "كاسابولا",
                "label_fr": "Casapulla"
            },
            {
                "value": 46509,
                "label": "Acerra",
                "label_ar": "اسيرا",
                "label_fr": "Acerra"
            },
            {
                "value": 46510,
                "label": "Casoria",
                "label_ar": "كاسوريا",
                "label_fr": "Casoria"
            },
            {
                "value": 46511,
                "label": "Terni",
                "label_ar": "تيرني",
                "label_fr": "Terni"
            },
            {
                "value": 46512,
                "label": "Crespina",
                "label_ar": "كريسبينا",
                "label_fr": "Crespina"
            },
            {
                "value": 46513,
                "label": "Raiano",
                "label_ar": "رايانو",
                "label_fr": "Raiano"
            },
            {
                "value": 46514,
                "label": "Sulmona",
                "label_ar": "سولمونا",
                "label_fr": "Sulmona"
            },
            {
                "value": 46515,
                "label": "Ariccia",
                "label_ar": "أريكيا",
                "label_fr": "Ariccia"
            },
            {
                "value": 46516,
                "label": "San Donato",
                "label_ar": "سان دوناتو",
                "label_fr": "San Donato"
            },
            {
                "value": 46517,
                "label": "Marino",
                "label_ar": "مارينو",
                "label_fr": "Marino"
            },
            {
                "value": 46518,
                "label": "Saltocchio",
                "label_ar": "سالتوتشيو",
                "label_fr": "Saltocchio"
            },
            {
                "value": 46519,
                "label": "Morlupo",
                "label_ar": "مورلوبو",
                "label_fr": "Morlupo"
            },
            {
                "value": 46520,
                "label": "Monterotondo",
                "label_ar": "مونتيروتوندو",
                "label_fr": "Monterotondo"
            },
            {
                "value": 46521,
                "label": "Assemini",
                "label_ar": "Assemini",
                "label_fr": "Assemini"
            },
            {
                "value": 46522,
                "label": "San Salvo",
                "label_ar": "سان سالفو",
                "label_fr": "San Salvo"
            },
            {
                "value": 46523,
                "label": "Nazzano",
                "label_ar": "نازانو",
                "label_fr": "Nazzano"
            },
            {
                "value": 46524,
                "label": "Scandicci",
                "label_ar": "سكانديكي",
                "label_fr": "Scandicci"
            },
            {
                "value": 46525,
                "label": "Sora",
                "label_ar": "سورا",
                "label_fr": "Sora"
            },
            {
                "value": 46526,
                "label": "Montemassi",
                "label_ar": "مونتماسي",
                "label_fr": "Montemassi"
            },
            {
                "value": 46527,
                "label": "Vetralla",
                "label_ar": "فيترالا",
                "label_fr": "Vetralla"
            },
            {
                "value": 46528,
                "label": "Santa Croce",
                "label_ar": "سانتا كروتشي",
                "label_fr": "Santa Croce"
            },
            {
                "value": 46529,
                "label": "Fonni",
                "label_ar": "فوني",
                "label_fr": "Fonni"
            },
            {
                "value": 46530,
                "label": "Castelnuovo di Garfagnana",
                "label_ar": "Castelnuovo di Garfagnana",
                "label_fr": "Castelnuovo di Garfagnana"
            },
            {
                "value": 46531,
                "label": "CittàSant'Angelo",
                "label_ar": "سيتاسانت أنجيلو",
                "label_fr": "CittàSant'Angelo"
            },
            {
                "value": 46532,
                "label": "Monteroni d'Arbia",
                "label_ar": "مونتيروني داربيا",
                "label_fr": "Monteroni d'Arbia"
            },
            {
                "value": 46533,
                "label": "Mentana",
                "label_ar": "مينتانا",
                "label_fr": "Mentana"
            },
            {
                "value": 46534,
                "label": "Cecina",
                "label_ar": "سيسينا",
                "label_fr": "Cecina"
            },
            {
                "value": 46535,
                "label": "Olbia",
                "label_ar": "أولبيا",
                "label_fr": "Olbia"
            },
            {
                "value": 46536,
                "label": "Le Castella",
                "label_ar": "لو كاستيلا",
                "label_fr": "Le Castella"
            },
            {
                "value": 46537,
                "label": "Giulianova",
                "label_ar": "جوليانوفا",
                "label_fr": "Giulianova"
            },
            {
                "value": 46538,
                "label": "Nuoro",
                "label_ar": "نوورو",
                "label_fr": "Nuoro"
            },
            {
                "value": 46539,
                "label": "Silvi",
                "label_ar": "سيلفي",
                "label_fr": "Silvi"
            },
            {
                "value": 46540,
                "label": "Ficarazzi",
                "label_ar": "فيكارازي",
                "label_fr": "Ficarazzi"
            },
            {
                "value": 46541,
                "label": "Piana degli Albanesi",
                "label_ar": "بيانا ديجلي ألبانيزي",
                "label_fr": "Piana degli Albanesi"
            },
            {
                "value": 46542,
                "label": "Civita Castellana",
                "label_ar": "سيفيتا كاستيلانا",
                "label_fr": "Civita Castellana"
            },
            {
                "value": 46543,
                "label": "Luco dei Marsi",
                "label_ar": "لوكو دي مارسي",
                "label_fr": "Luco dei Marsi"
            },
            {
                "value": 46544,
                "label": "Licata",
                "label_ar": "ليكاتا",
                "label_fr": "Licata"
            },
            {
                "value": 46545,
                "label": "Acri",
                "label_ar": "أكري",
                "label_fr": "Acri"
            },
            {
                "value": 46546,
                "label": "Rignano sull'Arno",
                "label_ar": "ريجنانو سولارنو",
                "label_fr": "Rignano sull'Arno"
            },
            {
                "value": 46547,
                "label": "Truccazzano",
                "label_ar": "تروكاتسانو",
                "label_fr": "Truccazzano"
            },
            {
                "value": 46548,
                "label": "Spotorno",
                "label_ar": "سبوتورنو",
                "label_fr": "Spotorno"
            },
            {
                "value": 46549,
                "label": "Villalago",
                "label_ar": "فيلالاجو",
                "label_fr": "Villalago"
            },
            {
                "value": 46550,
                "label": "Vicari",
                "label_ar": "فيكاري",
                "label_fr": "Vicari"
            },
            {
                "value": 46551,
                "label": "Monopoli",
                "label_ar": "مونوبولي",
                "label_fr": "Monopoli"
            },
            {
                "value": 46552,
                "label": "Sandrigo",
                "label_ar": "ساندريغو",
                "label_fr": "Sandrigo"
            },
            {
                "value": 46553,
                "label": "Gusti",
                "label_ar": "غوستي",
                "label_fr": "Gusti"
            },
            {
                "value": 46554,
                "label": "Patù",
                "label_ar": "باتو",
                "label_fr": "Patù"
            },
            {
                "value": 46555,
                "label": "Montecchio Maggiore",
                "label_ar": "مونتيكيو ماجوري",
                "label_fr": "Montecchio Maggiore"
            },
            {
                "value": 46556,
                "label": "Pramaggiore",
                "label_ar": "براماغيور",
                "label_fr": "Pramaggiore"
            },
            {
                "value": 46557,
                "label": "San Giuseppe Vesuviano",
                "label_ar": "سان جوزيبي فيزوفيانو",
                "label_fr": "San Giuseppe Vesuviano"
            },
            {
                "value": 46558,
                "label": "Corbetta",
                "label_ar": "كوربيتا",
                "label_fr": "Corbetta"
            },
            {
                "value": 46559,
                "label": "Offanengo",
                "label_ar": "Offanengo",
                "label_fr": "Offanengo"
            },
            {
                "value": 46560,
                "label": "Lancenigo-Villorba",
                "label_ar": "لانسينيجو فيلوربا",
                "label_fr": "Lancenigo-Villorba"
            },
            {
                "value": 46561,
                "label": "Andorno Micca",
                "label_ar": "أندورنو ميكا",
                "label_fr": "Andorno Micca"
            },
            {
                "value": 46562,
                "label": "Vimodrone",
                "label_ar": "فيمودرون",
                "label_fr": "Vimodrone"
            },
            {
                "value": 46563,
                "label": "Cassano d'Adda",
                "label_ar": "كاسانو دادا",
                "label_fr": "Cassano d'Adda"
            },
            {
                "value": 46564,
                "label": "Campi Bisenzio",
                "label_ar": "كامبي بيسينسيو",
                "label_fr": "Campi Bisenzio"
            },
            {
                "value": 46565,
                "label": "Tavullia",
                "label_ar": "تافوليا",
                "label_fr": "Tavullia"
            },
            {
                "value": 46566,
                "label": "Cellamare",
                "label_ar": "سيلاماري",
                "label_fr": "Cellamare"
            },
            {
                "value": 46567,
                "label": "Millesimo",
                "label_ar": "ميليسيمو",
                "label_fr": "Millesimo"
            },
            {
                "value": 46568,
                "label": "Ardesio",
                "label_ar": "أرديسيو",
                "label_fr": "Ardesio"
            },
            {
                "value": 46569,
                "label": "Recco",
                "label_ar": "ريكو",
                "label_fr": "Recco"
            },
            {
                "value": 46570,
                "label": "Ponte San Pietro",
                "label_ar": "بونتي سان بيترو",
                "label_fr": "Ponte San Pietro"
            },
            {
                "value": 46571,
                "label": "Sarzana",
                "label_ar": "سارزانا",
                "label_fr": "Sarzana"
            },
            {
                "value": 46572,
                "label": "Carate Brianza",
                "label_ar": "كاراتيه بريانزا",
                "label_fr": "Carate Brianza"
            },
            {
                "value": 46573,
                "label": "Nave",
                "label_ar": "صحن الكنيسة",
                "label_fr": "Nef"
            },
            {
                "value": 46574,
                "label": "Bovisio-Masciago",
                "label_ar": "بوفيسيو ماسكياجو",
                "label_fr": "Bovisio-Masciago"
            },
            {
                "value": 46575,
                "label": "Castegnato",
                "label_ar": "كاستيجناتو",
                "label_fr": "Castegnato"
            },
            {
                "value": 46576,
                "label": "Poirino",
                "label_ar": "بويرينو",
                "label_fr": "Poirino"
            },
            {
                "value": 46577,
                "label": "Malnate",
                "label_ar": "مالنات",
                "label_fr": "Malnate"
            },
            {
                "value": 46578,
                "label": "Cava Manara",
                "label_ar": "كافا المنارة",
                "label_fr": "Cava Manara"
            },
            {
                "value": 46579,
                "label": "Bra",
                "label_ar": "حمالة صدر",
                "label_fr": "Soutien-gorge"
            },
            {
                "value": 46580,
                "label": "Imperia",
                "label_ar": "إمبيريا",
                "label_fr": "Imperia"
            },
            {
                "value": 46581,
                "label": "Monte",
                "label_ar": "مونتي",
                "label_fr": "Monte"
            },
            {
                "value": 46582,
                "label": "San Mauro Torinese",
                "label_ar": "سان ماورو تورينيزي",
                "label_fr": "San Mauro Torinese"
            },
            {
                "value": 46583,
                "label": "Castelnuovo Nigra",
                "label_ar": "كاستيلنوفو نيجرا",
                "label_fr": "Castelnuovo Nigra"
            },
            {
                "value": 46584,
                "label": "Lomazzo",
                "label_ar": "لومازو",
                "label_fr": "Lomazzo"
            },
            {
                "value": 46585,
                "label": "Baratte",
                "label_ar": "بارات",
                "label_fr": "Baratte"
            },
            {
                "value": 46586,
                "label": "Trofarello",
                "label_ar": "تروفاريلو",
                "label_fr": "Trofarello"
            },
            {
                "value": 46587,
                "label": "Carnate",
                "label_ar": "قرنفل",
                "label_fr": "Carnate"
            },
            {
                "value": 46588,
                "label": "Garbagnate Milanese",
                "label_ar": "Garbagnate ميلانيز",
                "label_fr": "Garbagnate milanais"
            },
            {
                "value": 46589,
                "label": "Appiano Gentile",
                "label_ar": "أبيانو غير اليهود",
                "label_fr": "Appiano Gentile"
            },
            {
                "value": 46590,
                "label": "Concorezzo",
                "label_ar": "كونكورزو",
                "label_fr": "Concorezzo"
            },
            {
                "value": 46591,
                "label": "Canegrate",
                "label_ar": "Canegrate",
                "label_fr": "Canegrate"
            },
            {
                "value": 46592,
                "label": "Opera",
                "label_ar": "أوبرا",
                "label_fr": "Opéra"
            },
            {
                "value": 46593,
                "label": "Mozzanica",
                "label_ar": "موزانيكا",
                "label_fr": "Mozzanica"
            },
            {
                "value": 46594,
                "label": "Desenzano del Garda",
                "label_ar": "ديسينسانو ديل جاردا",
                "label_fr": "Desenzano del Garda"
            },
            {
                "value": 46595,
                "label": "Robassomero",
                "label_ar": "روباسوميرو",
                "label_fr": "Robassomero"
            },
            {
                "value": 46596,
                "label": "Crespiatica",
                "label_ar": "كريسباتيكا",
                "label_fr": "Crespiatica"
            },
            {
                "value": 46597,
                "label": "Acqui Terme",
                "label_ar": "أكوي تيرمي",
                "label_fr": "Acqui Terme"
            },
            {
                "value": 46598,
                "label": "Grosio",
                "label_ar": "جروسيو",
                "label_fr": "Grosio"
            },
            {
                "value": 46599,
                "label": "Torre Boldone",
                "label_ar": "توري بولدون",
                "label_fr": "Torre Boldone"
            },
            {
                "value": 46600,
                "label": "Carrara",
                "label_ar": "كارارا",
                "label_fr": "Carrare"
            },
            {
                "value": 46601,
                "label": "Massa",
                "label_ar": "ماسا",
                "label_fr": "Massa"
            },
            {
                "value": 46602,
                "label": "Velletri",
                "label_ar": "فيليتري",
                "label_fr": "Velletri"
            },
            {
                "value": 46603,
                "label": "Serravalle Pistoiese",
                "label_ar": "سيرافالي بيستويز",
                "label_fr": "Serravalle Pistoiese"
            },
            {
                "value": 46604,
                "label": "Paliano",
                "label_ar": "باليانو",
                "label_fr": "Paliano"
            },
            {
                "value": 46605,
                "label": "Bosa",
                "label_ar": "بوسا",
                "label_fr": "Bosa"
            },
            {
                "value": 46606,
                "label": "Campomarino",
                "label_ar": "كامبومارينو",
                "label_fr": "Campomarino"
            },
            {
                "value": 46607,
                "label": "Senorbì",
                "label_ar": "سينوربو",
                "label_fr": "Senorbì"
            },
            {
                "value": 46608,
                "label": "Cortona",
                "label_ar": "كورتونا",
                "label_fr": "Cortona"
            },
            {
                "value": 46609,
                "label": "San Gemini",
                "label_ar": "سان الجوزاء",
                "label_fr": "San Gemini"
            },
            {
                "value": 46610,
                "label": "Camaiore",
                "label_ar": "كاميور",
                "label_fr": "Camaiore"
            },
            {
                "value": 46611,
                "label": "Calcinaia",
                "label_ar": "كالسينيا",
                "label_fr": "Calcinaia"
            },
            {
                "value": 46612,
                "label": "Roseto degli Abruzzi",
                "label_ar": "روزيتو ديجلي أبروتسي",
                "label_fr": "Roseto degli Abruzzi"
            },
            {
                "value": 46613,
                "label": "Riano",
                "label_ar": "ريانو",
                "label_fr": "Riano"
            },
            {
                "value": 46614,
                "label": "Santa Giusta",
                "label_ar": "سانتا جوستا",
                "label_fr": "Santa Giusta"
            },
            {
                "value": 46615,
                "label": "Fiano Romano",
                "label_ar": "فيانو رومانو",
                "label_fr": "Fiano Romano"
            },
            {
                "value": 46616,
                "label": "Impruneta",
                "label_ar": "إمبرونيتا",
                "label_fr": "Impruneta"
            },
            {
                "value": 46617,
                "label": "Grottaferrata",
                "label_ar": "جروتافيراتا",
                "label_fr": "Grottaferrata"
            },
            {
                "value": 46618,
                "label": "Marina di Cerveteri",
                "label_ar": "مارينا دي سيرفيتيري",
                "label_fr": "Marina di Cerveteri"
            },
            {
                "value": 46619,
                "label": "San Sebastiano al Vesuvio",
                "label_ar": "سان سيباستيانو آل فيسوفيو",
                "label_fr": "San Sebastiano al Vesuvio"
            },
            {
                "value": 46620,
                "label": "San Gregorio di Catania",
                "label_ar": "سان جريجوريو دي كاتانيا",
                "label_fr": "San Gregorio di Catania"
            },
            {
                "value": 46621,
                "label": "Scafa",
                "label_ar": "سكافا",
                "label_fr": "Scafa"
            },
            {
                "value": 46622,
                "label": "Nola",
                "label_ar": "نولا",
                "label_fr": "Nola"
            },
            {
                "value": 46623,
                "label": "Caccamo",
                "label_ar": "كاكامو",
                "label_fr": "Caccamo"
            },
            {
                "value": 46624,
                "label": "Fano",
                "label_ar": "فانو",
                "label_fr": "Fano"
            },
            {
                "value": 46625,
                "label": "Martina Franca",
                "label_ar": "مارتينا فرانكا",
                "label_fr": "Martina Franca"
            },
            {
                "value": 46626,
                "label": "Santa Maria a Vico",
                "label_ar": "سانتا ماريا فيكو",
                "label_fr": "Santa Maria a Vico"
            },
            {
                "value": 46627,
                "label": "Salemi",
                "label_ar": "سالمي",
                "label_fr": "Salemi"
            },
            {
                "value": 46628,
                "label": "Cerignola",
                "label_ar": "سيرينولا",
                "label_fr": "Cerignola"
            },
            {
                "value": 46629,
                "label": "Agrigento",
                "label_ar": "أجريجينتو",
                "label_fr": "Agrigente"
            },
            {
                "value": 46630,
                "label": "Riposto",
                "label_ar": "ريبوستو",
                "label_fr": "Riposto"
            },
            {
                "value": 46631,
                "label": "Atripalda",
                "label_ar": "أتريبالدا",
                "label_fr": "Atripalda"
            },
            {
                "value": 46632,
                "label": "Cittanova",
                "label_ar": "سيتانوفا",
                "label_fr": "Cittanova"
            },
            {
                "value": 46633,
                "label": "Pineto",
                "label_ar": "بينيتو",
                "label_fr": "Pineto"
            },
            {
                "value": 46634,
                "label": "Calcinelli",
                "label_ar": "كالسينيلي",
                "label_fr": "Calcinelli"
            },
            {
                "value": 46635,
                "label": "Lizzano",
                "label_ar": "ليزانو",
                "label_fr": "Lizzano"
            },
            {
                "value": 46636,
                "label": "Fermo",
                "label_ar": "فيرمو",
                "label_fr": "Fermo"
            },
            {
                "value": 46637,
                "label": "Santo Pietro",
                "label_ar": "سانتو بيترو",
                "label_fr": "Santo Pietro"
            },
            {
                "value": 46638,
                "label": "Cicciano",
                "label_ar": "سيسيانو",
                "label_fr": "Cicciano"
            },
            {
                "value": 46639,
                "label": "Sant'Agata de' Goti",
                "label_ar": "Sant'Agata de 'Goti",
                "label_fr": "Sant'Agata de 'Goti"
            },
            {
                "value": 46640,
                "label": "Nocera Inferiore",
                "label_ar": "نوسيرا إنفيريور",
                "label_fr": "Nocera Inferiore"
            },
            {
                "value": 46641,
                "label": "Favara",
                "label_ar": "فافارا",
                "label_fr": "Favara"
            },
            {
                "value": 46642,
                "label": "Mugnano del Cardinale",
                "label_ar": "موغنانو ديل كاردينالي",
                "label_fr": "Mugnano del Cardinale"
            },
            {
                "value": 46643,
                "label": "Villarosa",
                "label_ar": "فيلاروسا",
                "label_fr": "Villarosa"
            },
            {
                "value": 46644,
                "label": "Qualiano",
                "label_ar": "كواليانو",
                "label_fr": "Qualiano"
            },
            {
                "value": 46645,
                "label": "Caltavuturo",
                "label_ar": "كالتافوتورو",
                "label_fr": "Caltavuturo"
            },
            {
                "value": 46646,
                "label": "Erice",
                "label_ar": "ايريس",
                "label_fr": "Erice"
            },
            {
                "value": 46647,
                "label": "Ruvo di Puglia",
                "label_ar": "روفو دي بوليا",
                "label_fr": "Ruvo di Puglia"
            },
            {
                "value": 46648,
                "label": "Poli",
                "label_ar": "بولي",
                "label_fr": "Poli"
            },
            {
                "value": 46649,
                "label": "Mussomeli",
                "label_ar": "موسوميلي",
                "label_fr": "Mussomeli"
            },
            {
                "value": 46650,
                "label": "Villa Castelli",
                "label_ar": "فيلا كاستيلي",
                "label_fr": "Villa Castelli"
            },
            {
                "value": 46651,
                "label": "Avellino",
                "label_ar": "أفيلينو",
                "label_fr": "Avellino"
            },
            {
                "value": 46652,
                "label": "Alvignano",
                "label_ar": "ألفينيانو",
                "label_fr": "Alvignano"
            },
            {
                "value": 46653,
                "label": "Mercogliano",
                "label_ar": "ميركوجليانو",
                "label_fr": "Mercogliano"
            },
            {
                "value": 46654,
                "label": "San Giuseppe Jato",
                "label_ar": "سان جوزيبي جاتو",
                "label_fr": "San Giuseppe Jato"
            },
            {
                "value": 46655,
                "label": "San Nicola",
                "label_ar": "سان نيكولا",
                "label_fr": "San Nicola"
            },
            {
                "value": 46656,
                "label": "Boscoreale",
                "label_ar": "Boscoreale",
                "label_fr": "Boscoreale"
            },
            {
                "value": 46657,
                "label": "Belpasso",
                "label_ar": "بيلباسو",
                "label_fr": "Belpasso"
            },
            {
                "value": 46658,
                "label": "Urbino",
                "label_ar": "أوربينو",
                "label_fr": "Urbino"
            },
            {
                "value": 46659,
                "label": "Lissone",
                "label_ar": "ليسوني",
                "label_fr": "Lissone"
            },
            {
                "value": 46660,
                "label": "Ozzero",
                "label_ar": "أوزيرو",
                "label_fr": "Ozzero"
            },
            {
                "value": 46661,
                "label": "Travedona Monate",
                "label_ar": "ترافيدونا مونيت",
                "label_fr": "Travedona Monate"
            },
            {
                "value": 46662,
                "label": "Gavirate",
                "label_ar": "جافيراتي",
                "label_fr": "Gavirate"
            },
            {
                "value": 46663,
                "label": "Santo Stefano",
                "label_ar": "سانتو ستيفانو",
                "label_fr": "Santo Stefano"
            },
            {
                "value": 46664,
                "label": "Beinasco",
                "label_ar": "بيناسكو",
                "label_fr": "Beinasco"
            },
            {
                "value": 46665,
                "label": "Saint-Vincent",
                "label_ar": "سانت فنسنت",
                "label_fr": "Saint-Vincent"
            },
            {
                "value": 46666,
                "label": "Fossalta di Portogruaro",
                "label_ar": "فوسالتا دي بورتوغروارو",
                "label_fr": "Fossalta di Portogruaro"
            },
            {
                "value": 46667,
                "label": "San Martino",
                "label_ar": "سان مارتينو",
                "label_fr": "San Martino"
            },
            {
                "value": 46668,
                "label": "Rovato",
                "label_ar": "روفاتو",
                "label_fr": "Rovato"
            },
            {
                "value": 46669,
                "label": "Gambolò",
                "label_ar": "جامبولو",
                "label_fr": "Gambolò"
            },
            {
                "value": 46670,
                "label": "Stradella",
                "label_ar": "ستراديلا",
                "label_fr": "Stradella"
            },
            {
                "value": 46671,
                "label": "Trecate",
                "label_ar": "تريكاتي",
                "label_fr": "Trecate"
            },
            {
                "value": 46672,
                "label": "Fino Mornasco",
                "label_ar": "فينو مورناسكو",
                "label_fr": "Fino Mornasco"
            },
            {
                "value": 46673,
                "label": "Venaria Reale",
                "label_ar": "فيناريا ريالي",
                "label_fr": "Venaria Reale"
            },
            {
                "value": 46674,
                "label": "Aquileia",
                "label_ar": "أكويليا",
                "label_fr": "Aquilée"
            },
            {
                "value": 46675,
                "label": "Valdagno",
                "label_ar": "فالداجنو",
                "label_fr": "Valdagno"
            },
            {
                "value": 46676,
                "label": "Asiago",
                "label_ar": "أسياجو",
                "label_fr": "Asiago"
            },
            {
                "value": 46677,
                "label": "San Lazzaro di Savena",
                "label_ar": "سان لازارو دي سافينا",
                "label_fr": "San Lazzaro di Savena"
            },
            {
                "value": 46678,
                "label": "Limana",
                "label_ar": "ليمانا",
                "label_fr": "Limana"
            },
            {
                "value": 46679,
                "label": "Lavis",
                "label_ar": "لافيس",
                "label_fr": "Lavis"
            },
            {
                "value": 46680,
                "label": "Castel d'Ario",
                "label_ar": "كاستل دياريو",
                "label_fr": "Castel d'Ario"
            },
            {
                "value": 46681,
                "label": "Cormons",
                "label_ar": "كورمون",
                "label_fr": "Cormons"
            },
            {
                "value": 46682,
                "label": "Curtarolo",
                "label_ar": "كورتارولو",
                "label_fr": "Curtarolo"
            },
            {
                "value": 46683,
                "label": "Feltre",
                "label_ar": "فيلتر",
                "label_fr": "Feltre"
            },
            {
                "value": 46684,
                "label": "Rubano",
                "label_ar": "روبانو",
                "label_fr": "Rubano"
            },
            {
                "value": 46685,
                "label": "Bardolino",
                "label_ar": "باردولينو",
                "label_fr": "Bardolino"
            },
            {
                "value": 46686,
                "label": "Zanè",
                "label_ar": "زاني",
                "label_fr": "Zanè"
            },
            {
                "value": 46687,
                "label": "Farra di Soligo",
                "label_ar": "فارا دي سوليجو",
                "label_fr": "Farra di Soligo"
            },
            {
                "value": 46688,
                "label": "Castelnuovo Rangone",
                "label_ar": "Castelnuovo Rangone",
                "label_fr": "Castelnuovo Rangone"
            },
            {
                "value": 46689,
                "label": "Castelvetro di Modena",
                "label_ar": "كاستلفيترو دي مودينا",
                "label_fr": "Castelvetro di Modena"
            },
            {
                "value": 46690,
                "label": "Noale",
                "label_ar": "نويل",
                "label_fr": "Noale"
            },
            {
                "value": 46691,
                "label": "Pievepelago",
                "label_ar": "بيفيبيلاغو",
                "label_fr": "Pievepelago"
            },
            {
                "value": 46692,
                "label": "Castelfranco Veneto",
                "label_ar": "كاستلفرانكو فينيتو",
                "label_fr": "Castelfranco Veneto"
            },
            {
                "value": 46693,
                "label": "San Dona",
                "label_ar": "سان دونا",
                "label_fr": "San Dona"
            },
            {
                "value": 46694,
                "label": "Selvazzano Dentro",
                "label_ar": "سيلفاتسانو دينترو",
                "label_fr": "Selvazzano Dentro"
            },
            {
                "value": 46695,
                "label": "Merano",
                "label_ar": "ميرانو",
                "label_fr": "Merano"
            },
            {
                "value": 46696,
                "label": "Palmanova",
                "label_ar": "بالمانوفا",
                "label_fr": "Palmanova"
            },
            {
                "value": 46697,
                "label": "Villafranca di Verona",
                "label_ar": "فيلافرانكا دي فيرونا",
                "label_fr": "Villafranca di Verona"
            },
            {
                "value": 46698,
                "label": "Collecchio",
                "label_ar": "كوليتشيو",
                "label_fr": "Collecchio"
            },
            {
                "value": 46699,
                "label": "Montechiarugolo",
                "label_ar": "مونتيتشياروغولو",
                "label_fr": "Montechiarugolo"
            },
            {
                "value": 46700,
                "label": "Massa",
                "label_ar": "ماسا",
                "label_fr": "Massa"
            },
            {
                "value": 46701,
                "label": "Casalgrande",
                "label_ar": "كاسالغراند",
                "label_fr": "Casalgrande"
            },
            {
                "value": 46702,
                "label": "Cappella Maggiore",
                "label_ar": "كابيلا ماجوري",
                "label_fr": "Cappella Maggiore"
            },
            {
                "value": 46703,
                "label": "Gattatico",
                "label_ar": "جاتيكو",
                "label_fr": "Gattatico"
            },
            {
                "value": 46704,
                "label": "San Donàdi Piave",
                "label_ar": "سان دونادي بيافي",
                "label_fr": "Piave San Donàdi"
            },
            {
                "value": 46705,
                "label": "Preganziol",
                "label_ar": "بريغانزيول",
                "label_fr": "Préganziol"
            },
            {
                "value": 46706,
                "label": "Castenaso",
                "label_ar": "كاستيناسو",
                "label_fr": "Castenaso"
            },
            {
                "value": 46707,
                "label": "Decima",
                "label_ar": "عشري",
                "label_fr": "Décima"
            },
            {
                "value": 46708,
                "label": "San Giovanni in Croce",
                "label_ar": "سان جيوفاني في كروتشي",
                "label_fr": "San Giovanni in Croce"
            },
            {
                "value": 46709,
                "label": "Nervesa della Battaglia",
                "label_ar": "نيرفيسا ديلا باتاغليا",
                "label_fr": "Nervesa della Battaglia"
            },
            {
                "value": 46710,
                "label": "Toirano",
                "label_ar": "تويرانو",
                "label_fr": "Toirano"
            },
            {
                "value": 46711,
                "label": "Voghera",
                "label_ar": "فوجيرا",
                "label_fr": "Voghera"
            },
            {
                "value": 46712,
                "label": "Cardano",
                "label_ar": "كاردانو",
                "label_fr": "Cardano"
            },
            {
                "value": 46713,
                "label": "Cameri",
                "label_ar": "كاميري",
                "label_fr": "Cameri"
            },
            {
                "value": 46714,
                "label": "Oggiono",
                "label_ar": "Oggiono",
                "label_fr": "Oggiono"
            },
            {
                "value": 46715,
                "label": "Lonate Pozzolo",
                "label_ar": "لونيت بوزولو",
                "label_fr": "Pozzolo solitaire"
            },
            {
                "value": 46716,
                "label": "Dairago",
                "label_ar": "دايراغو",
                "label_fr": "Dairago"
            },
            {
                "value": 46717,
                "label": "Villastellone",
                "label_ar": "فيلاستيلون",
                "label_fr": "Villastellone"
            },
            {
                "value": 46718,
                "label": "Montoggio",
                "label_ar": "مونتوجيو",
                "label_fr": "Montoggio"
            },
            {
                "value": 46719,
                "label": "Vicoforte",
                "label_ar": "فيكوفورتي",
                "label_fr": "Vicoforte"
            },
            {
                "value": 46720,
                "label": "Almè",
                "label_ar": "ألمي",
                "label_fr": "Almè"
            },
            {
                "value": 46721,
                "label": "Vittuone",
                "label_ar": "فيتون",
                "label_fr": "Vittuone"
            },
            {
                "value": 46722,
                "label": "Caselle Torinese",
                "label_ar": "كاسيل تورينيزي",
                "label_fr": "Caselle Torinese"
            },
            {
                "value": 46723,
                "label": "Altessano",
                "label_ar": "ألتيسانو",
                "label_fr": "Altessano"
            },
            {
                "value": 46724,
                "label": "Altare",
                "label_ar": "مذبح",
                "label_fr": "Altare"
            },
            {
                "value": 46725,
                "label": "Carugate",
                "label_ar": "كاروجيت",
                "label_fr": "Carugate"
            },
            {
                "value": 46726,
                "label": "Sondrio",
                "label_ar": "سوندريو",
                "label_fr": "Sondrio"
            },
            {
                "value": 46727,
                "label": "Agrate Brianza",
                "label_ar": "اغريت بريانزا",
                "label_fr": "Agrate Brianza"
            },
            {
                "value": 46728,
                "label": "Spino d'Adda",
                "label_ar": "سبينو دادا",
                "label_fr": "Spino d'Adda"
            },
            {
                "value": 46729,
                "label": "Pietrasanta",
                "label_ar": "بيتراسانتا",
                "label_fr": "Pietrasanta"
            },
            {
                "value": 46730,
                "label": "Monte",
                "label_ar": "مونتي",
                "label_fr": "Monte"
            },
            {
                "value": 46731,
                "label": "Segni",
                "label_ar": "Segni",
                "label_fr": "Segni"
            },
            {
                "value": 46732,
                "label": "Terranuova Bracciolini",
                "label_ar": "تيرانوفا براتشيوليني",
                "label_fr": "Terranuova Bracciolini"
            },
            {
                "value": 46733,
                "label": "Monsummano Terme",
                "label_ar": "مونسومانو تيرمي",
                "label_fr": "Monsummano Terme"
            },
            {
                "value": 46734,
                "label": "Labico",
                "label_ar": "لابيكو",
                "label_fr": "Labico"
            },
            {
                "value": 46735,
                "label": "Grottammare",
                "label_ar": "جروتاماري",
                "label_fr": "Grottammare"
            },
            {
                "value": 46736,
                "label": "Palestrina",
                "label_ar": "باليسترينا",
                "label_fr": "Palestrina"
            },
            {
                "value": 46737,
                "label": "Putignano",
                "label_ar": "بوتينيانو",
                "label_fr": "Putignano"
            },
            {
                "value": 46738,
                "label": "Marano di Napoli",
                "label_ar": "مارانو دي نابولي",
                "label_fr": "Marano di Napoli"
            },
            {
                "value": 46739,
                "label": "Pozzuoli",
                "label_ar": "بوزولي",
                "label_fr": "Pozzuoli"
            },
            {
                "value": 46740,
                "label": "Sant'Anastasia",
                "label_ar": "سانت انستازيا",
                "label_fr": "Sant'Anastasia"
            },
            {
                "value": 46741,
                "label": "Angri",
                "label_ar": "غاضب",
                "label_fr": "Angri"
            },
            {
                "value": 46742,
                "label": "Cercola",
                "label_ar": "سيركولا",
                "label_fr": "Cercola"
            },
            {
                "value": 46743,
                "label": "Sciacca",
                "label_ar": "شاكا",
                "label_fr": "Sciacca"
            },
            {
                "value": 46744,
                "label": "Auletta",
                "label_ar": "اوليتا",
                "label_fr": "Auletta"
            },
            {
                "value": 46745,
                "label": "Ortona",
                "label_ar": "أورتونا",
                "label_fr": "Ortona"
            },
            {
                "value": 46746,
                "label": "Fiumefreddo di Sicilia",
                "label_ar": "فيومفريددو دي صقلية",
                "label_fr": "Fiumefreddo di Sicilia"
            },
            {
                "value": 46747,
                "label": "Sant'Elpidio a Mare",
                "label_ar": "Sant'Elpidio a Mare",
                "label_fr": "Sant'Elpidio a Mare"
            },
            {
                "value": 46748,
                "label": "San Michele Salentino",
                "label_ar": "سان ميشيل سالينتينو",
                "label_fr": "San Michele Salentino"
            },
            {
                "value": 46749,
                "label": "Matelica",
                "label_ar": "ماتيليكا",
                "label_fr": "Matelica"
            },
            {
                "value": 46750,
                "label": "Casaluce",
                "label_ar": "كاسالوس",
                "label_fr": "Casaluce"
            },
            {
                "value": 46751,
                "label": "Grammichele",
                "label_ar": "جراميشيلي",
                "label_fr": "Grammichele"
            },
            {
                "value": 46752,
                "label": "Statte",
                "label_ar": "ستاتي",
                "label_fr": "Statte"
            },
            {
                "value": 46753,
                "label": "Martinsicuro",
                "label_ar": "مارتينسيكورو",
                "label_fr": "Martinsicuro"
            },
            {
                "value": 46754,
                "label": "Capurso",
                "label_ar": "كابورسو",
                "label_fr": "Capurso"
            },
            {
                "value": 46755,
                "label": "Maderno",
                "label_ar": "ماديرنو",
                "label_fr": "Maderno"
            },
            {
                "value": 46756,
                "label": "Casamassima",
                "label_ar": "كازاماسيما",
                "label_fr": "Casamassima"
            },
            {
                "value": 46757,
                "label": "Bisceglie",
                "label_ar": "بيسجلي",
                "label_fr": "Bisceglie"
            },
            {
                "value": 46758,
                "label": "Mariglianella",
                "label_ar": "ماريجليانيلا",
                "label_fr": "Mariglianella"
            },
            {
                "value": 46759,
                "label": "Castiglione di Sicilia",
                "label_ar": "كاستيجليون دي صقلية",
                "label_fr": "Castiglione di Sicilia"
            },
            {
                "value": 46760,
                "label": "Cotronei",
                "label_ar": "كوتروني",
                "label_fr": "Cotronei"
            },
            {
                "value": 46761,
                "label": "Tortora",
                "label_ar": "تورتورا",
                "label_fr": "Tortora"
            },
            {
                "value": 46762,
                "label": "Petrosino",
                "label_ar": "بتروسينو",
                "label_fr": "Petrosino"
            },
            {
                "value": 46763,
                "label": "Pergola",
                "label_ar": "برجولا",
                "label_fr": "Pergola"
            },
            {
                "value": 46764,
                "label": "Cervino",
                "label_ar": "سيرفينو",
                "label_fr": "Cervino"
            },
            {
                "value": 46765,
                "label": "Lioni",
                "label_ar": "ليوني",
                "label_fr": "Lioni"
            },
            {
                "value": 46766,
                "label": "Mascalucia",
                "label_ar": "مسكالوسيا",
                "label_fr": "Mascalucia"
            },
            {
                "value": 46767,
                "label": "San Prisco",
                "label_ar": "سان بريسكو",
                "label_fr": "San Prisco"
            },
            {
                "value": 46768,
                "label": "Brusciano",
                "label_ar": "بروشيانو",
                "label_fr": "Brusciano"
            },
            {
                "value": 46769,
                "label": "Camposano",
                "label_ar": "كامبوسانو",
                "label_fr": "Camposano"
            },
            {
                "value": 46770,
                "label": "Rende",
                "label_ar": "ريندي",
                "label_fr": "Rende"
            },
            {
                "value": 46771,
                "label": "Offida",
                "label_ar": "أوفيدا",
                "label_fr": "Offida"
            },
            {
                "value": 46772,
                "label": "Piana",
                "label_ar": "بيانا",
                "label_fr": "Piana"
            },
            {
                "value": 46773,
                "label": "Adro",
                "label_ar": "أدرو",
                "label_fr": "Adro"
            },
            {
                "value": 46774,
                "label": "Santo Stefano Ticino",
                "label_ar": "سانتو ستيفانو تيسينو",
                "label_fr": "Santo Stefano Tessin"
            },
            {
                "value": 46775,
                "label": "Arluno",
                "label_ar": "أرلونو",
                "label_fr": "Arluno"
            },
            {
                "value": 46776,
                "label": "Fagnano Olona",
                "label_ar": "فاجنانو أولونا",
                "label_fr": "Fagnano Olona"
            },
            {
                "value": 46777,
                "label": "Monterosso al Mare",
                "label_ar": "مونتيروسو الماري",
                "label_fr": "Monterosso al Mare"
            },
            {
                "value": 46778,
                "label": "Grumello Cremonese",
                "label_ar": "جروميلو كريمونيز",
                "label_fr": "Grumello Cremonese"
            },
            {
                "value": 46779,
                "label": "Certosa di Pavia",
                "label_ar": "سيرتوزا دي بافيا",
                "label_fr": "Certosa di Pavia"
            },
            {
                "value": 46780,
                "label": "Castiglione Chiavarese",
                "label_ar": "كاستيجليون شيافاريس",
                "label_fr": "Castiglione Chiavarese"
            },
            {
                "value": 46781,
                "label": "Bussoleno",
                "label_ar": "بوسولينو",
                "label_fr": "Bussoleno"
            },
            {
                "value": 46782,
                "label": "Savigliano",
                "label_ar": "سافيجليانو",
                "label_fr": "Savigliano"
            },
            {
                "value": 46783,
                "label": "Solaro",
                "label_ar": "سولارو",
                "label_fr": "Solaro"
            },
            {
                "value": 46784,
                "label": "Stezzano",
                "label_ar": "ستيزانو",
                "label_fr": "Stezzano"
            },
            {
                "value": 46785,
                "label": "Belgioioso",
                "label_ar": "بلجيووسو",
                "label_fr": "Belgioioso"
            },
            {
                "value": 46786,
                "label": "Monte Compatri",
                "label_ar": "مونت كومباتري",
                "label_fr": "Monte Compatri"
            },
            {
                "value": 46787,
                "label": "Celano",
                "label_ar": "سيلانو",
                "label_fr": "Celano"
            },
            {
                "value": 46788,
                "label": "Lamporecchio",
                "label_ar": "لامبوركيو",
                "label_fr": "Lamporecchio"
            },
            {
                "value": 46789,
                "label": "Francavilla al Mare",
                "label_ar": "فرانكافيلا الماري",
                "label_fr": "Francavilla al Mare"
            },
            {
                "value": 46790,
                "label": "Albano Laziale",
                "label_ar": "ألبانو لازيالي",
                "label_fr": "Albano Laziale"
            },
            {
                "value": 46791,
                "label": "Fregene",
                "label_ar": "فريغين",
                "label_fr": "Fregene"
            },
            {
                "value": 46792,
                "label": "Castel Viscardo",
                "label_ar": "كاستل فيسكاردو",
                "label_fr": "Castel Viscardo"
            },
            {
                "value": 46793,
                "label": "Morciano di Romagna",
                "label_ar": "مورسيانو دي رومانيا",
                "label_fr": "Morciano di Romagna"
            },
            {
                "value": 46794,
                "label": "Casina",
                "label_ar": "كاسينا",
                "label_fr": "Casina"
            },
            {
                "value": 46795,
                "label": "Romano Canavese",
                "label_ar": "رومانو كانافيزي",
                "label_fr": "Romano Canavese"
            },
            {
                "value": 46796,
                "label": "Cesiomaggiore",
                "label_ar": "سيسيوماجيوري",
                "label_fr": "Cesiomaggiore"
            },
            {
                "value": 46797,
                "label": "Porto Viro",
                "label_ar": "بورتو فيرو",
                "label_fr": "Porto Viro"
            },
            {
                "value": 46798,
                "label": "Nogaredo al Torre",
                "label_ar": "نوجاريدو توري",
                "label_fr": "Nogaredo al Torre"
            },
            {
                "value": 46799,
                "label": "Villaverla",
                "label_ar": "فيلافيرلا",
                "label_fr": "Villaverla"
            },
            {
                "value": 46800,
                "label": "Quinto Vicentino",
                "label_ar": "كوينتو فيسينتينو",
                "label_fr": "Quinto Vicentino"
            },
            {
                "value": 46801,
                "label": "Mandello del Lario",
                "label_ar": "مانديلو ديل لاريو",
                "label_fr": "Mandello del Lario"
            },
            {
                "value": 46802,
                "label": "Cizzago-Comezzano",
                "label_ar": "سيزاغو كوميزانو",
                "label_fr": "Cizzago-Comezzano"
            },
            {
                "value": 46803,
                "label": "Manerbio",
                "label_ar": "مانيربيو",
                "label_fr": "Manerbio"
            },
            {
                "value": 46804,
                "label": "Campofranco",
                "label_ar": "كامبوفرانكو",
                "label_fr": "Campofranco"
            },
            {
                "value": 46805,
                "label": "Gadoni",
                "label_ar": "جادوني",
                "label_fr": "Gadoni"
            },
            {
                "value": 46806,
                "label": "Carema",
                "label_ar": "كاريما",
                "label_fr": "Carema"
            },
            {
                "value": 46807,
                "label": "San Martino Siccomario",
                "label_ar": "سان مارتينو سيكوماريو",
                "label_fr": "San Martino Siccomario"
            },
            {
                "value": 46808,
                "label": "Pontecchio Polesine",
                "label_ar": "بونتيكيو بوليسين",
                "label_fr": "Pontecchio Polesine"
            },
            {
                "value": 46809,
                "label": "Apricena",
                "label_ar": "مشمش",
                "label_fr": "Apricena"
            },
            {
                "value": 46810,
                "label": "Legnaro",
                "label_ar": "ليجنارو",
                "label_fr": "Legnaro"
            },
            {
                "value": 46811,
                "label": "Alviano",
                "label_ar": "ألفيانو",
                "label_fr": "Alviano"
            },
            {
                "value": 46812,
                "label": "San Mauro Pascoli",
                "label_ar": "سان ماورو باسكولي",
                "label_fr": "San Mauro Pascoli"
            },
            {
                "value": 46813,
                "label": "Guarda Veneta",
                "label_ar": "جواردا فينيتا",
                "label_fr": "Guarda Veneta"
            },
            {
                "value": 46814,
                "label": "Golasecca",
                "label_ar": "جولاسيكا",
                "label_fr": "Golasecca"
            },
            {
                "value": 46815,
                "label": "Forenza",
                "label_ar": "فورنزا",
                "label_fr": "Forenza"
            },
            {
                "value": 46816,
                "label": "Cerveno",
                "label_ar": "سيرفينو",
                "label_fr": "Cerveno"
            },
            {
                "value": 46817,
                "label": "Campodimele",
                "label_ar": "كامبوديميل",
                "label_fr": "Campodimele"
            },
            {
                "value": 46818,
                "label": "Piancogno",
                "label_ar": "بيانكوجنو",
                "label_fr": "Piancogno"
            },
            {
                "value": 46819,
                "label": "Cepagatti",
                "label_ar": "سيباجاتي",
                "label_fr": "Cepagatti"
            },
            {
                "value": 46820,
                "label": "Ailoche",
                "label_ar": "ايلوش",
                "label_fr": "Ailoche"
            },
            {
                "value": 46821,
                "label": "Suzzara",
                "label_ar": "سوزارا",
                "label_fr": "Suzzara"
            },
            {
                "value": 46822,
                "label": "Vailate",
                "label_ar": "فايلت",
                "label_fr": "Vailate"
            },
            {
                "value": 46823,
                "label": "Bassano Bresciano",
                "label_ar": "باسانو بريشيانو",
                "label_fr": "Bassano Bresciano"
            },
            {
                "value": 46824,
                "label": "Valeggio sul Mincio",
                "label_ar": "Valeggio sul Mincio",
                "label_fr": "Valeggio sul Mincio"
            },
            {
                "value": 46825,
                "label": "Gera Lario",
                "label_ar": "جيرا لاريو",
                "label_fr": "Gera Lario"
            },
            {
                "value": 46826,
                "label": "San Martino Dall'Argine",
                "label_ar": "سان مارتينو دال أرجين",
                "label_fr": "San Martino Dall'Argine"
            },
            {
                "value": 46827,
                "label": "Arabba",
                "label_ar": "عرابة",
                "label_fr": "Arabba"
            },
            {
                "value": 46828,
                "label": "Casale di Scodosia",
                "label_ar": "كاسال دي سكودوسيا",
                "label_fr": "Casale di Scodosia"
            },
            {
                "value": 46829,
                "label": "Murano",
                "label_ar": "مورانو",
                "label_fr": "Murano"
            },
            {
                "value": 46830,
                "label": "Posta Fibreno",
                "label_ar": "بوستا فيبرينو",
                "label_fr": "Posta Fibreno"
            },
            {
                "value": 46831,
                "label": "Carceri",
                "label_ar": "كارسري",
                "label_fr": "Carceri"
            },
            {
                "value": 46832,
                "label": "Cortina",
                "label_ar": "كورتينا",
                "label_fr": "Cortina"
            },
            {
                "value": 46833,
                "label": "Isola Vicentina",
                "label_ar": "ايزولا فيسينتينا",
                "label_fr": "Isola Vicentina"
            },
            {
                "value": 46834,
                "label": "San Daniele del Friuli",
                "label_ar": "سان دانييل ديل فريولي",
                "label_fr": "San Daniele del Friuli"
            },
            {
                "value": 46835,
                "label": "San Giorgio",
                "label_ar": "سان جورجيو",
                "label_fr": "San Giorgio"
            },
            {
                "value": 46836,
                "label": "Este",
                "label_ar": "إستي",
                "label_fr": "Este"
            },
            {
                "value": 46837,
                "label": "Falcone",
                "label_ar": "فالكون",
                "label_fr": "Falcone"
            },
            {
                "value": 46838,
                "label": "Telgate",
                "label_ar": "Telgate",
                "label_fr": "Telgate"
            },
            {
                "value": 46839,
                "label": "Crotone",
                "label_ar": "كروتوني",
                "label_fr": "Crotone"
            },
            {
                "value": 46840,
                "label": "Castel Maggiore",
                "label_ar": "كاستل ماجوري",
                "label_fr": "Castel Maggiore"
            },
            {
                "value": 46841,
                "label": "Mercato Saraceno",
                "label_ar": "ميركاتو ساراسينو",
                "label_fr": "Mercato Saraceno"
            },
            {
                "value": 46842,
                "label": "Pomarance",
                "label_ar": "بومارانس",
                "label_fr": "Pomarance"
            },
            {
                "value": 46843,
                "label": "Sirignano",
                "label_ar": "سيرينانو",
                "label_fr": "Sirignano"
            },
            {
                "value": 46844,
                "label": "Rosolini",
                "label_ar": "روزوليني",
                "label_fr": "Rosolini"
            },
            {
                "value": 46845,
                "label": "Comiso",
                "label_ar": "كوميسو",
                "label_fr": "Comiso"
            },
            {
                "value": 46846,
                "label": "Costa",
                "label_ar": "كوستا",
                "label_fr": "Costa"
            },
            {
                "value": 46847,
                "label": "Santa Maria di Licodia",
                "label_ar": "سانتا ماريا دي ليكوديا",
                "label_fr": "Santa Maria di Licodia"
            },
            {
                "value": 46848,
                "label": "Covo",
                "label_ar": "كوفو",
                "label_fr": "Covo"
            },
            {
                "value": 46849,
                "label": "Santa Eufemia Lamezia",
                "label_ar": "سانتا يوفيميا لاميزيا",
                "label_fr": "Santa Eufemia Lamezia"
            },
            {
                "value": 46850,
                "label": "San Severino",
                "label_ar": "سان سيفيرينو",
                "label_fr": "San Severino"
            },
            {
                "value": 46851,
                "label": "San Gimignano",
                "label_ar": "سان جيميجنانو",
                "label_fr": "San Gimignano"
            },
            {
                "value": 46852,
                "label": "Serramazzoni",
                "label_ar": "سيرامازوني",
                "label_fr": "Serramazzoni"
            },
            {
                "value": 46853,
                "label": "Farnese",
                "label_ar": "فارنيز",
                "label_fr": "Farnèse"
            },
            {
                "value": 46854,
                "label": "Castiglione della Pescaia",
                "label_ar": "كاستيجليون ديلا بيسكايا",
                "label_fr": "Castiglione della Pescaia"
            },
            {
                "value": 46855,
                "label": "Carapelle",
                "label_ar": "كارابيل",
                "label_fr": "Carapelle"
            },
            {
                "value": 46856,
                "label": "Belvedere di Spinello",
                "label_ar": "بلفيدير دي سبينيلو",
                "label_fr": "Belvédère de Spinello"
            },
            {
                "value": 46857,
                "label": "Montecarotto",
                "label_ar": "مونتيكاروتو",
                "label_fr": "Montecarotto"
            },
            {
                "value": 46858,
                "label": "Marotta",
                "label_ar": "ماروتا",
                "label_fr": "Marotta"
            },
            {
                "value": 46859,
                "label": "Arcevia",
                "label_ar": "أرسيفيا",
                "label_fr": "Arcevia"
            },
            {
                "value": 46860,
                "label": "Calcara",
                "label_ar": "كلكارا",
                "label_fr": "Calcara"
            },
            {
                "value": 46861,
                "label": "Milano",
                "label_ar": "ميلانو",
                "label_fr": "Milan"
            },
            {
                "value": 46862,
                "label": "Bibbiano",
                "label_ar": "بيبيانو",
                "label_fr": "Bibbiano"
            },
            {
                "value": 46863,
                "label": "Bellagio",
                "label_ar": "بيلاجيو",
                "label_fr": "Bellagio"
            },
            {
                "value": 46864,
                "label": "Palombara Sabina",
                "label_ar": "بالومبارا سابينا",
                "label_fr": "Palombara Sabina"
            },
            {
                "value": 46865,
                "label": "Pavona",
                "label_ar": "بافونا",
                "label_fr": "Pavona"
            },
            {
                "value": 46866,
                "label": "Anagni",
                "label_ar": "أناجني",
                "label_fr": "Anagni"
            },
            {
                "value": 46867,
                "label": "Zagarolo",
                "label_ar": "زاغارولو",
                "label_fr": "Zagarolo"
            },
            {
                "value": 46868,
                "label": "Lograto",
                "label_ar": "لوغراتو",
                "label_fr": "Lograto"
            },
            {
                "value": 46869,
                "label": "Colloredo di Prato",
                "label_ar": "كولوريدو دي براتو",
                "label_fr": "Colloredo di Prato"
            },
            {
                "value": 46870,
                "label": "Gravedona",
                "label_ar": "جرافيدونا",
                "label_fr": "Gravedona"
            },
            {
                "value": 46871,
                "label": "Vogogna",
                "label_ar": "فوجوجنا",
                "label_fr": "Vogogna"
            },
            {
                "value": 46872,
                "label": "Gorno",
                "label_ar": "جورنو",
                "label_fr": "Gorno"
            },
            {
                "value": 46873,
                "label": "Gignese",
                "label_ar": "جينيز",
                "label_fr": "Gignese"
            },
            {
                "value": 46874,
                "label": "Monte di Malo",
                "label_ar": "مونتي دي مالو",
                "label_fr": "Monte di Malo"
            },
            {
                "value": 46875,
                "label": "Felitto",
                "label_ar": "فيليتو",
                "label_fr": "Felitto"
            },
            {
                "value": 46876,
                "label": "Manta",
                "label_ar": "مانتا",
                "label_fr": "Manta"
            },
            {
                "value": 46877,
                "label": "Vigone",
                "label_ar": "فيجون",
                "label_fr": "Vigone"
            },
            {
                "value": 46878,
                "label": "Monastero",
                "label_ar": "موناستيرو",
                "label_fr": "Monastero"
            },
            {
                "value": 46879,
                "label": "Castelletto",
                "label_ar": "كاستيليتو",
                "label_fr": "Castelletto"
            },
            {
                "value": 46880,
                "label": "None",
                "label_ar": "لا شيء",
                "label_fr": "Aucun"
            },
            {
                "value": 46881,
                "label": "Montignoso",
                "label_ar": "مونتينوسو",
                "label_fr": "Montignoso"
            },
            {
                "value": 46882,
                "label": "Verzuolo",
                "label_ar": "فيرزولو",
                "label_fr": "Verzuolo"
            },
            {
                "value": 46883,
                "label": "Demonte",
                "label_ar": "ديمونتي",
                "label_fr": "Demonte"
            },
            {
                "value": 46884,
                "label": "Sarsina",
                "label_ar": "سارسينا",
                "label_fr": "Sarsina"
            },
            {
                "value": 46885,
                "label": "Sovicille",
                "label_ar": "سوفيسيل",
                "label_fr": "Sovicille"
            },
            {
                "value": 46886,
                "label": "Gaiole in Chianti",
                "label_ar": "Gaiole in Chianti",
                "label_fr": "Gaiole in Chianti"
            },
            {
                "value": 46887,
                "label": "Soliera",
                "label_ar": "سوليرا",
                "label_fr": "Soliera"
            },
            {
                "value": 46888,
                "label": "Borgoricco",
                "label_ar": "بورجوريكو",
                "label_fr": "Borgoricco"
            },
            {
                "value": 46889,
                "label": "Barrali",
                "label_ar": "بارالي",
                "label_fr": "Barrali"
            },
            {
                "value": 46890,
                "label": "Monterosi",
                "label_ar": "مونتيروسي",
                "label_fr": "Monterosi"
            },
            {
                "value": 46891,
                "label": "Calamandrana",
                "label_ar": "كالاماندرانا",
                "label_fr": "Calamandrana"
            },
            {
                "value": 46892,
                "label": "Mezzomerico",
                "label_ar": "ميزوميريكو",
                "label_fr": "Mezzomerico"
            },
            {
                "value": 46893,
                "label": "Cerea",
                "label_ar": "سيريا",
                "label_fr": "Cerea"
            },
            {
                "value": 46894,
                "label": "Gazzaniga",
                "label_ar": "جزانيجا",
                "label_fr": "Gazzaniga"
            },
            {
                "value": 46895,
                "label": "Ambrogio",
                "label_ar": "أمبروجيو",
                "label_fr": "Ambrogio"
            },
            {
                "value": 46896,
                "label": "Lignano Sabbiadoro",
                "label_ar": "ليجنانو سابيادورو",
                "label_fr": "Lignano Sabbiadoro"
            },
            {
                "value": 46897,
                "label": "Arre",
                "label_ar": "آري",
                "label_fr": "Arre"
            },
            {
                "value": 46898,
                "label": "Orio Litta",
                "label_ar": "أوريو ليتا",
                "label_fr": "Orio Litta"
            },
            {
                "value": 46899,
                "label": "Cedessano",
                "label_ar": "سيدسانو",
                "label_fr": "Cedessano"
            },
            {
                "value": 46900,
                "label": "Todi",
                "label_ar": "تودي",
                "label_fr": "Todi"
            },
            {
                "value": 46901,
                "label": "Cannara",
                "label_ar": "كانارا",
                "label_fr": "Cannara"
            },
            {
                "value": 46902,
                "label": "Stefania",
                "label_ar": "ستيفانيا",
                "label_fr": "Stefania"
            },
            {
                "value": 46903,
                "label": "Massignano",
                "label_ar": "ماسينيانو",
                "label_fr": "Massignano"
            },
            {
                "value": 46904,
                "label": "Pioraco",
                "label_ar": "بيوراكو",
                "label_fr": "Pioraco"
            },
            {
                "value": 46905,
                "label": "Contigliano",
                "label_ar": "كونتيجليانو",
                "label_fr": "Contigliano"
            },
            {
                "value": 46906,
                "label": "San Giorgio Piacentino",
                "label_ar": "سان جورجيو بياسينتينو",
                "label_fr": "San Giorgio Piacentino"
            },
            {
                "value": 46907,
                "label": "Campolongo Maggiore",
                "label_ar": "كامبولونجو ماجيوري",
                "label_fr": "Campolongo Maggiore"
            },
            {
                "value": 46908,
                "label": "Montaldo",
                "label_ar": "مونتالدو",
                "label_fr": "Montaldo"
            },
            {
                "value": 46909,
                "label": "Borgo San Dalmazzo",
                "label_ar": "بورجو سان دالماتسو",
                "label_fr": "Borgo San Dalmazzo"
            },
            {
                "value": 46910,
                "label": "Susa",
                "label_ar": "سوزا",
                "label_fr": "Susa"
            },
            {
                "value": 46911,
                "label": "Sant'Angelo Lodigiano",
                "label_ar": "سانت أنجيلو لوديجيانو",
                "label_fr": "Sant'Angelo Lodigiano"
            },
            {
                "value": 46912,
                "label": "Castelli Calepio",
                "label_ar": "كاستيلي كاليبيو",
                "label_fr": "Castelli Calepio"
            },
            {
                "value": 46913,
                "label": "Casa Paradiso",
                "label_ar": "كاسا باراديسو",
                "label_fr": "Casa Paradiso"
            },
            {
                "value": 46914,
                "label": "Camponogara",
                "label_ar": "كامبونوجارا",
                "label_fr": "Camponogara"
            },
            {
                "value": 46915,
                "label": "Roncoferraro",
                "label_ar": "رونكوفيرارو",
                "label_fr": "Roncoferraro"
            },
            {
                "value": 46916,
                "label": "Collalto",
                "label_ar": "كولالتو",
                "label_fr": "Collalto"
            },
            {
                "value": 46917,
                "label": "Carbonera",
                "label_ar": "كاربونيرا",
                "label_fr": "Carbonera"
            },
            {
                "value": 46918,
                "label": "Pico",
                "label_ar": "بيكو",
                "label_fr": "Pico"
            },
            {
                "value": 46919,
                "label": "Mirabella",
                "label_ar": "ميرابيلا",
                "label_fr": "Mirabella"
            },
            {
                "value": 46920,
                "label": "Cattolica",
                "label_ar": "كاتوليكا",
                "label_fr": "Cattolica"
            },
            {
                "value": 46921,
                "label": "Russo",
                "label_ar": "روسو",
                "label_fr": "Russo"
            },
            {
                "value": 46922,
                "label": "San Niccolo",
                "label_ar": "سان نيكولو",
                "label_fr": "San Niccolo"
            },
            {
                "value": 46923,
                "label": "Parigi",
                "label_ar": "باريجي",
                "label_fr": "Parigi"
            },
            {
                "value": 46924,
                "label": "Lurate Caccivio",
                "label_ar": "لورات كاتشيفيو",
                "label_fr": "Lurate Caccivio"
            },
            {
                "value": 46925,
                "label": "Ospedaletto-Expopisa",
                "label_ar": "Ospedaletto-Expopisa",
                "label_fr": "Ospedaletto-Expopisa"
            },
            {
                "value": 46926,
                "label": "Morgano",
                "label_ar": "مورغانو",
                "label_fr": "Morgano"
            },
            {
                "value": 46927,
                "label": "Campiglio",
                "label_ar": "كامبيليو",
                "label_fr": "Campiglio"
            },
            {
                "value": 46928,
                "label": "Mestrino",
                "label_ar": "ميسترينو",
                "label_fr": "Mestrino"
            },
            {
                "value": 46929,
                "label": "Noventa di Piave",
                "label_ar": "نوفينتا دي بيافي",
                "label_fr": "Noventa di Piave"
            },
            {
                "value": 46930,
                "label": "Cimadolmo",
                "label_ar": "سيمادولمو",
                "label_fr": "Cimadolmo"
            },
            {
                "value": 46931,
                "label": "Leno",
                "label_ar": "لينو",
                "label_fr": "Leno"
            },
            {
                "value": 46932,
                "label": "Sumirago",
                "label_ar": "سوميراغو",
                "label_fr": "Sumirago"
            },
            {
                "value": 46933,
                "label": "Pontecchio",
                "label_ar": "بونتيكيو",
                "label_fr": "Pontecchio"
            },
            {
                "value": 46934,
                "label": "Ponsacco",
                "label_ar": "بونساكو",
                "label_fr": "Ponsacco"
            },
            {
                "value": 46935,
                "label": "Mirabella Eclano",
                "label_ar": "ميرابيلا إكلانو",
                "label_fr": "Mirabella Eclano"
            },
            {
                "value": 46936,
                "label": "Carosino",
                "label_ar": "كاروزينو",
                "label_fr": "Carosino"
            },
            {
                "value": 46937,
                "label": "Castelfranco di Sotto",
                "label_ar": "كاستلفرانكو دي سوتو",
                "label_fr": "Castelfranco di Sotto"
            },
            {
                "value": 46938,
                "label": "Pietrabbondante",
                "label_ar": "بيترابوندانتي",
                "label_fr": "Pietrabbondante"
            },
            {
                "value": 46939,
                "label": "Bojano",
                "label_ar": "بوجانو",
                "label_fr": "Bojano"
            },
            {
                "value": 46940,
                "label": "Montemurro",
                "label_ar": "مونتيمورو",
                "label_fr": "Montemurro"
            },
            {
                "value": 46941,
                "label": "Monserrato",
                "label_ar": "مونسيراتو",
                "label_fr": "Monserrato"
            },
            {
                "value": 46942,
                "label": "Gela",
                "label_ar": "جيلا",
                "label_fr": "Gela"
            },
            {
                "value": 46943,
                "label": "Passetto",
                "label_ar": "باسيتو",
                "label_fr": "Passetto"
            },
            {
                "value": 46944,
                "label": "Bruneck",
                "label_ar": "برونيك",
                "label_fr": "Bruneck"
            },
            {
                "value": 46945,
                "label": "Trappeto",
                "label_ar": "ترابيتو",
                "label_fr": "Trappeto"
            },
            {
                "value": 46946,
                "label": "Montebelluna",
                "label_ar": "مونتيبيلونا",
                "label_fr": "Montebelluna"
            },
            {
                "value": 46947,
                "label": "Crocetta del Montello",
                "label_ar": "كروشيتا ديل مونتيلو",
                "label_fr": "Crocetta del Montello"
            },
            {
                "value": 46948,
                "label": "San Fior",
                "label_ar": "سان فيور",
                "label_fr": "San Fior"
            },
            {
                "value": 46949,
                "label": "Frassineto Po",
                "label_ar": "فراسينيتو بو",
                "label_fr": "Frassineto Po"
            },
            {
                "value": 46950,
                "label": "Casteggio",
                "label_ar": "كاستيجيو",
                "label_fr": "Casteggio"
            },
            {
                "value": 46951,
                "label": "Fortunago",
                "label_ar": "فورتوناغو",
                "label_fr": "Fortunago"
            },
            {
                "value": 46952,
                "label": "Villanova Mondovì",
                "label_ar": "فيلانوفا موندوفي",
                "label_fr": "Villanova Mondovì"
            },
            {
                "value": 46953,
                "label": "Valle",
                "label_ar": "فالي",
                "label_fr": "Valle"
            },
            {
                "value": 46954,
                "label": "Gioia Tauro",
                "label_ar": "جويا تاورو",
                "label_fr": "Gioia Tauro"
            },
            {
                "value": 46955,
                "label": "Stella Cilento",
                "label_ar": "ستيلا سيلينتو",
                "label_fr": "Stella Cilento"
            },
            {
                "value": 46956,
                "label": "Sava",
                "label_ar": "سافا",
                "label_fr": "Sava"
            },
            {
                "value": 46957,
                "label": "Pozzuolo del Friuli",
                "label_ar": "Pozzuolo del Friuli",
                "label_fr": "Pozzuolo del Friuli"
            },
            {
                "value": 46958,
                "label": "Montanaro",
                "label_ar": "مونتانارو",
                "label_fr": "Montanaro"
            },
            {
                "value": 46959,
                "label": "Balangero",
                "label_ar": "بالانجيرو",
                "label_fr": "Balangero"
            },
            {
                "value": 46960,
                "label": "Benna",
                "label_ar": "بينا",
                "label_fr": "Benna"
            },
            {
                "value": 46961,
                "label": "Caluso",
                "label_ar": "كالوسو",
                "label_fr": "Caluso"
            },
            {
                "value": 46962,
                "label": "Andezeno",
                "label_ar": "أنديزينو",
                "label_fr": "Andezeno"
            },
            {
                "value": 46963,
                "label": "Feletto",
                "label_ar": "فيليتو",
                "label_fr": "Feletto"
            },
            {
                "value": 46964,
                "label": "Tollegno",
                "label_ar": "Tollegno",
                "label_fr": "Tollegno"
            },
            {
                "value": 46965,
                "label": "Cassano",
                "label_ar": "كاسانو",
                "label_fr": "Cassano"
            },
            {
                "value": 46966,
                "label": "Vigolo Vattaro",
                "label_ar": "فيجولو فاتارو",
                "label_fr": "Vigolo Vattaro"
            },
            {
                "value": 46967,
                "label": "Caldaro sulla Strada del Vino",
                "label_ar": "كالدارو سولا سترادا ديل فينو",
                "label_fr": "Caldaro sulla Strada del Vino"
            },
            {
                "value": 46968,
                "label": "Costabissara",
                "label_ar": "كوستابيسارا",
                "label_fr": "Costabissara"
            },
            {
                "value": 46969,
                "label": "Orgiano",
                "label_ar": "Orgiano",
                "label_fr": "Orgiano"
            },
            {
                "value": 46970,
                "label": "Roncegno",
                "label_ar": "Roncegno",
                "label_fr": "Roncegno"
            },
            {
                "value": 46971,
                "label": "Quarto d'Altino",
                "label_ar": "كوارتو دالتينو",
                "label_fr": "Quarto d'Altino"
            },
            {
                "value": 46972,
                "label": "Riva del Garda",
                "label_ar": "ريفا ديل جاردا",
                "label_fr": "Riva del Garda"
            },
            {
                "value": 46973,
                "label": "Pedemonte",
                "label_ar": "بيدمونتي",
                "label_fr": "Pedemonte"
            },
            {
                "value": 46974,
                "label": "Laives",
                "label_ar": "يعيش",
                "label_fr": "Laives"
            },
            {
                "value": 46975,
                "label": "Calenzano",
                "label_ar": "كالينزانو",
                "label_fr": "Calenzano"
            },
            {
                "value": 46976,
                "label": "Lanciano",
                "label_ar": "لانسيانو",
                "label_fr": "Lanciano"
            },
            {
                "value": 46977,
                "label": "Arsago Seprio",
                "label_ar": "أرساغو سيبريو",
                "label_fr": "Arsago Seprio"
            },
            {
                "value": 46978,
                "label": "Vergiate",
                "label_ar": "فيرجيات",
                "label_fr": "Vergiate"
            },
            {
                "value": 46979,
                "label": "Casorate Sempione",
                "label_ar": "كاسورات سيمبيون",
                "label_fr": "Casorate Sempione"
            },
            {
                "value": 46980,
                "label": "Torri di Quartesolo",
                "label_ar": "توري دي كوارتسولو",
                "label_fr": "Torri di Quartesolo"
            },
            {
                "value": 46981,
                "label": "Chiampo",
                "label_ar": "شيامبو",
                "label_fr": "Chiampo"
            },
            {
                "value": 46982,
                "label": "Monticello Conte Otto",
                "label_ar": "مونتايسلو كونتي أوتو",
                "label_fr": "Monticello Conte Otto"
            },
            {
                "value": 46983,
                "label": "Gandino",
                "label_ar": "جاندينو",
                "label_fr": "Gandino"
            },
            {
                "value": 46984,
                "label": "Marnate",
                "label_ar": "مارنات",
                "label_fr": "Marnate"
            },
            {
                "value": 46985,
                "label": "Carnago",
                "label_ar": "كارناجو",
                "label_fr": "Carnago"
            },
            {
                "value": 46986,
                "label": "Albino",
                "label_ar": "ألبينو",
                "label_fr": "Albinos"
            },
            {
                "value": 46987,
                "label": "Artogne",
                "label_ar": "أرتوجن",
                "label_fr": "Artogne"
            },
            {
                "value": 46988,
                "label": "Santa Giustina",
                "label_ar": "سانتا جوستينا",
                "label_fr": "Santa Giustina"
            },
            {
                "value": 46989,
                "label": "Oderzo",
                "label_ar": "أوديرزو",
                "label_fr": "Oderzo"
            },
            {
                "value": 46990,
                "label": "Vedelago",
                "label_ar": "فيديلاغو",
                "label_fr": "Vedelago"
            },
            {
                "value": 46991,
                "label": "Rubiera",
                "label_ar": "روبيرا",
                "label_fr": "Rubiera"
            },
            {
                "value": 46992,
                "label": "Acquasparta",
                "label_ar": "أكواسبارتا",
                "label_fr": "Acquasparta"
            },
            {
                "value": 46993,
                "label": "Ruda",
                "label_ar": "روضة",
                "label_fr": "Ruda"
            },
            {
                "value": 46994,
                "label": "Comerio",
                "label_ar": "كوميريو",
                "label_fr": "Comerio"
            },
            {
                "value": 46995,
                "label": "Zibido San Giacomo",
                "label_ar": "زيبيدو سان جياكومو",
                "label_fr": "Zibido San Giacomo"
            },
            {
                "value": 46996,
                "label": "Arese",
                "label_ar": "أريس",
                "label_fr": "Arese"
            },
            {
                "value": 46997,
                "label": "Robbiate",
                "label_ar": "سلب",
                "label_fr": "Robbiate"
            },
            {
                "value": 46998,
                "label": "Meda",
                "label_ar": "ميدا",
                "label_fr": "Meda"
            },
            {
                "value": 46999,
                "label": "Ospedaletto Euganeo",
                "label_ar": "Ospedaletto Euganeo",
                "label_fr": "Ospedaletto Euganeo"
            },
            {
                "value": 47000,
                "label": "Malcesine",
                "label_ar": "مالسيسين",
                "label_fr": "Malcesine"
            },
            {
                "value": 47001,
                "label": "Valenzano",
                "label_ar": "فالينزانو",
                "label_fr": "Valenzano"
            },
            {
                "value": 47002,
                "label": "Palagiano",
                "label_ar": "بالاجيانو",
                "label_fr": "Palagiano"
            },
            {
                "value": 47003,
                "label": "San Giorgio Ionico",
                "label_ar": "سان جورجيو ايونيكو",
                "label_fr": "San Giorgio Ionico"
            },
            {
                "value": 47004,
                "label": "Adelfia",
                "label_ar": "Adelfia",
                "label_fr": "Adelfia"
            },
            {
                "value": 47005,
                "label": "San Vito dei Normanni",
                "label_ar": "سان فيتو دي نورماني",
                "label_fr": "San Vito dei Normanni"
            },
            {
                "value": 47006,
                "label": "Costa",
                "label_ar": "كوستا",
                "label_fr": "Costa"
            },
            {
                "value": 47007,
                "label": "Cuorgnè",
                "label_ar": "كورني",
                "label_fr": "Cuorgnè"
            },
            {
                "value": 47008,
                "label": "Vignale Monferrato",
                "label_ar": "فيجنال مونفيراتو",
                "label_fr": "Vignale Monferrato"
            },
            {
                "value": 47009,
                "label": "Casanova",
                "label_ar": "كازانوفا",
                "label_fr": "Casanova"
            },
            {
                "value": 47010,
                "label": "Selci",
                "label_ar": "سيلسي",
                "label_fr": "Selci"
            },
            {
                "value": 47011,
                "label": "Castelnuovo Scrivia",
                "label_ar": "Castelnuovo Scrivia",
                "label_fr": "Castelnuovo Scrivia"
            },
            {
                "value": 47012,
                "label": "Bubbio",
                "label_ar": "بوبيو",
                "label_fr": "Bubbio"
            },
            {
                "value": 47013,
                "label": "Bellinzago Novarese",
                "label_ar": "بيلينزاغو نوفاريس",
                "label_fr": "Bellinzago Novarese"
            },
            {
                "value": 47014,
                "label": "Trino",
                "label_ar": "ترينو",
                "label_fr": "Trino"
            },
            {
                "value": 47015,
                "label": "Madonna",
                "label_ar": "مادونا",
                "label_fr": "Madone"
            },
            {
                "value": 47016,
                "label": "Paullo",
                "label_ar": "باولو",
                "label_fr": "Paullo"
            },
            {
                "value": 47017,
                "label": "Cenaia",
                "label_ar": "سينايا",
                "label_fr": "Cenaia"
            },
            {
                "value": 47018,
                "label": "Lucino",
                "label_ar": "لوسينو",
                "label_fr": "Lucino"
            },
            {
                "value": 47019,
                "label": "Casazza",
                "label_ar": "كاسازا",
                "label_fr": "Casazza"
            },
            {
                "value": 47020,
                "label": "Ponte San Nicolo",
                "label_ar": "بونتي سان نيكولو",
                "label_fr": "Ponte San Nicolo"
            },
            {
                "value": 47021,
                "label": "Locri",
                "label_ar": "لوكري",
                "label_fr": "Locri"
            },
            {
                "value": 47022,
                "label": "Laigueglia",
                "label_ar": "لايجيليا",
                "label_fr": "Laigueglia"
            },
            {
                "value": 47023,
                "label": "Lerma",
                "label_ar": "ليرما",
                "label_fr": "Lerma"
            },
            {
                "value": 47024,
                "label": "Nembro",
                "label_ar": "نمبرو",
                "label_fr": "Nembro"
            },
            {
                "value": 47025,
                "label": "Predosa",
                "label_ar": "بريدوسا",
                "label_fr": "Predosa"
            },
            {
                "value": 47026,
                "label": "Bobbio",
                "label_ar": "بوبيو",
                "label_fr": "Bobbio"
            },
            {
                "value": 47027,
                "label": "Riccò del Golfo di Spezia",
                "label_ar": "Riccò del Golfo di Spezia",
                "label_fr": "Riccò del Golfo di Spezia"
            },
            {
                "value": 47028,
                "label": "Saluggia",
                "label_ar": "سالوجيا",
                "label_fr": "Saluggia"
            },
            {
                "value": 47029,
                "label": "Valperga",
                "label_ar": "فالبيرجا",
                "label_fr": "Valperga"
            },
            {
                "value": 47030,
                "label": "Grignasco",
                "label_ar": "Grignasco",
                "label_fr": "Grignasco"
            },
            {
                "value": 47031,
                "label": "Pozzolo Formigaro",
                "label_ar": "بوزولو فورميغارو",
                "label_fr": "Pozzolo Formigaro"
            },
            {
                "value": 47032,
                "label": "San Piero a Sieve",
                "label_ar": "سان بييرو غربال",
                "label_fr": "San Piero a Sieve"
            },
            {
                "value": 47033,
                "label": "Trebaseleghe",
                "label_ar": "Trebaseleghe",
                "label_fr": "Trebaseleghe"
            },
            {
                "value": 47034,
                "label": "Castelluccio Inferiore",
                "label_ar": "كاستيلوتشيو إنفيريوري",
                "label_fr": "Castelluccio Inferiore"
            },
            {
                "value": 47035,
                "label": "Fontaniva",
                "label_ar": "فونتانيفا",
                "label_fr": "Fontaniva"
            },
            {
                "value": 47036,
                "label": "Lucito",
                "label_ar": "لوسيتو",
                "label_fr": "Lucito"
            },
            {
                "value": 47037,
                "label": "Storo",
                "label_ar": "ستورو",
                "label_fr": "Storo"
            },
            {
                "value": 47038,
                "label": "Alfonsine",
                "label_ar": "ألفونسين",
                "label_fr": "Alfonsine"
            },
            {
                "value": 47039,
                "label": "Montebello",
                "label_ar": "مونتيبيلو",
                "label_fr": "Montebello"
            },
            {
                "value": 47040,
                "label": "Fauglia",
                "label_ar": "فوغليا",
                "label_fr": "Fauglia"
            },
            {
                "value": 47041,
                "label": "Ripa-Pozzi-Querceta-Ponterosso",
                "label_ar": "ريبا-بوتزي-كويرسيتا-بونتيروسو",
                "label_fr": "Ripa-Pozzi-Querceta-Ponterosso"
            },
            {
                "value": 47042,
                "label": "Sannicandro Garganico",
                "label_ar": "سانيكاندرو جارجانيكو",
                "label_fr": "Sannicandro Garganico"
            },
            {
                "value": 47043,
                "label": "Ponzone",
                "label_ar": "بونزون",
                "label_fr": "Ponzone"
            },
            {
                "value": 47044,
                "label": "Levanto",
                "label_ar": "ليفانتو",
                "label_fr": "Levanto"
            },
            {
                "value": 47045,
                "label": "Maresca",
                "label_ar": "ماريسكا",
                "label_fr": "Maresca"
            },
            {
                "value": 47046,
                "label": "Ponte San Giovanni",
                "label_ar": "بونتي سان جيوفاني",
                "label_fr": "Ponte San Giovanni"
            },
            {
                "value": 47047,
                "label": "Vittoria",
                "label_ar": "فيتوريا",
                "label_fr": "Vittoria"
            },
            {
                "value": 47048,
                "label": "Gorgo al Monticano",
                "label_ar": "Gorgo al Monticano",
                "label_fr": "Gorgo al Monticano"
            },
            {
                "value": 47049,
                "label": "Revigliasco",
                "label_ar": "Revigliasco",
                "label_fr": "Revigliasco"
            },
            {
                "value": 47050,
                "label": "Pederobba",
                "label_ar": "بيدربا",
                "label_fr": "Pederobba"
            },
            {
                "value": 47051,
                "label": "Carobbio",
                "label_ar": "كاروبيو",
                "label_fr": "Carobbio"
            },
            {
                "value": 47052,
                "label": "Fontana",
                "label_ar": "فونتانا",
                "label_fr": "Fontana"
            },
            {
                "value": 47053,
                "label": "Castelvecchio",
                "label_ar": "كاستلفيكيو",
                "label_fr": "Castelvecchio"
            },
            {
                "value": 47054,
                "label": "Castelnuovo",
                "label_ar": "كاستلنوفو",
                "label_fr": "Castelnuovo"
            },
            {
                "value": 47055,
                "label": "Santa Maria degli Angeli",
                "label_ar": "سانتا ماريا ديجلي أنجيلي",
                "label_fr": "Santa Maria degli Angeli"
            },
            {
                "value": 47056,
                "label": "Carbonara di Bari",
                "label_ar": "كاربونارا دي باري",
                "label_fr": "Carbonara di Bari"
            },
            {
                "value": 47057,
                "label": "Albuzzano",
                "label_ar": "البزانو",
                "label_fr": "Albuzzano"
            },
            {
                "value": 47058,
                "label": "Plan da Tieja",
                "label_ar": "خطة دا تيجا",
                "label_fr": "Plan da Tieja"
            },
            {
                "value": 47059,
                "label": "San Giorgio in Bosco",
                "label_ar": "سان جورجيو في بوسكو",
                "label_fr": "San Giorgio à Bosco"
            },
            {
                "value": 47060,
                "label": "Santa Giustina in Colle",
                "label_ar": "سانتا جوستينا في كولي",
                "label_fr": "Santa Giustina à Colle"
            },
            {
                "value": 47061,
                "label": "Casalvecchio Siculo",
                "label_ar": "كاسالفيكيو سيكولو",
                "label_fr": "Casalvecchio Siculo"
            },
            {
                "value": 47062,
                "label": "Fossombrone",
                "label_ar": "فوسومبروني",
                "label_fr": "Fossombrone"
            },
            {
                "value": 47063,
                "label": "Azzano San Paolo",
                "label_ar": "أزانو سان باولو",
                "label_fr": "Azzano San Paolo"
            },
            {
                "value": 47064,
                "label": "Marmirolo",
                "label_ar": "مارميرولو",
                "label_fr": "Marmirolo"
            },
            {
                "value": 47065,
                "label": "Frontone",
                "label_ar": "فرونتون",
                "label_fr": "Frontone"
            },
            {
                "value": 47066,
                "label": "Colmurano",
                "label_ar": "كولمورانو",
                "label_fr": "Colmurano"
            },
            {
                "value": 47067,
                "label": "Castelplanio",
                "label_ar": "Castelplanio",
                "label_fr": "Castelplanio"
            },
            {
                "value": 47068,
                "label": "Orciano di Pesaro",
                "label_ar": "Orciano di Pesaro",
                "label_fr": "Orciano di Pesaro"
            },
            {
                "value": 47069,
                "label": "San Marcello",
                "label_ar": "سان مارسيلو",
                "label_fr": "San Marcello"
            },
            {
                "value": 47070,
                "label": "Montemarciano",
                "label_ar": "مونتيمارسيانو",
                "label_fr": "Montemarciano"
            },
            {
                "value": 47071,
                "label": "Deruta",
                "label_ar": "ديروتا",
                "label_fr": "Deruta"
            },
            {
                "value": 47072,
                "label": "Antea",
                "label_ar": "أنتي",
                "label_fr": "Antea"
            },
            {
                "value": 47073,
                "label": "Certaldo",
                "label_ar": "سيرتالدو",
                "label_fr": "Certaldo"
            },
            {
                "value": 47074,
                "label": "Fastello",
                "label_ar": "فاستيلو",
                "label_fr": "Fastello"
            },
            {
                "value": 47075,
                "label": "Montenerodomo",
                "label_ar": "مونتينيرودومو",
                "label_fr": "Montenerodomo"
            },
            {
                "value": 47076,
                "label": "Guardiagrele",
                "label_ar": "Guardiagrele",
                "label_fr": "Guardiagrele"
            },
            {
                "value": 47077,
                "label": "Pretoro",
                "label_ar": "بريتورو",
                "label_fr": "Pretoro"
            },
            {
                "value": 47078,
                "label": "Colledimezzo",
                "label_ar": "كوليديميزو",
                "label_fr": "Colledimezzo"
            },
            {
                "value": 47079,
                "label": "Resana",
                "label_ar": "ريسانا",
                "label_fr": "Resana"
            },
            {
                "value": 47080,
                "label": "Navacchio",
                "label_ar": "نافاتشيو",
                "label_fr": "Navacchio"
            },
            {
                "value": 47081,
                "label": "Trissino",
                "label_ar": "تريسينو",
                "label_fr": "Trissino"
            },
            {
                "value": 47082,
                "label": "Fumane",
                "label_ar": "فوماني",
                "label_fr": "Fumane"
            },
            {
                "value": 47083,
                "label": "Palmi",
                "label_ar": "بالمي",
                "label_fr": "Palmi"
            },
            {
                "value": 47084,
                "label": "Milazzo",
                "label_ar": "ميلاتسو",
                "label_fr": "Milazzo"
            },
            {
                "value": 47085,
                "label": "Eboli",
                "label_ar": "إبولي",
                "label_fr": "Eboli"
            },
            {
                "value": 47086,
                "label": "Asciano",
                "label_ar": "Asciano",
                "label_fr": "Asciano"
            },
            {
                "value": 47087,
                "label": "Marineo",
                "label_ar": "مارينو",
                "label_fr": "Marineo"
            },
            {
                "value": 47088,
                "label": "Casalvieri",
                "label_ar": "كاسالفييري",
                "label_fr": "Casalvieri"
            },
            {
                "value": 47089,
                "label": "Pietra Ligure",
                "label_ar": "بيترا ليغور",
                "label_fr": "Pietra Ligure"
            },
            {
                "value": 47090,
                "label": "Mareno di Piave",
                "label_ar": "مارينو دي بيافي",
                "label_fr": "Mareno di Piave"
            },
            {
                "value": 47091,
                "label": "Bagnolo",
                "label_ar": "بانولو",
                "label_fr": "Bagnolo"
            },
            {
                "value": 47092,
                "label": "Zoldo Alto",
                "label_ar": "زولدو ألتو",
                "label_fr": "Zoldo Alto"
            },
            {
                "value": 47093,
                "label": "Veneto",
                "label_ar": "فينيتو",
                "label_fr": "Vénétie"
            },
            {
                "value": 47094,
                "label": "Costa di Rovigo",
                "label_ar": "كوستا دي روفيغو",
                "label_fr": "Costa di Rovigo"
            },
            {
                "value": 47095,
                "label": "Affi",
                "label_ar": "أفي",
                "label_fr": "Affi"
            },
            {
                "value": 47096,
                "label": "Vaglia",
                "label_ar": "فاجليا",
                "label_fr": "Vaglia"
            },
            {
                "value": 47097,
                "label": "Tomba",
                "label_ar": "تومبا",
                "label_fr": "Tomba"
            },
            {
                "value": 47098,
                "label": "Tredozio",
                "label_ar": "تريدوزيو",
                "label_fr": "Tredozio"
            },
            {
                "value": 47099,
                "label": "Venetico",
                "label_ar": "فينيتيكو",
                "label_fr": "Venetico"
            },
            {
                "value": 47100,
                "label": "Caccuri",
                "label_ar": "الكاكوري",
                "label_fr": "Caccuri"
            },
            {
                "value": 47101,
                "label": "Mendicino",
                "label_ar": "مينديسينو",
                "label_fr": "Mendicino"
            },
            {
                "value": 47102,
                "label": "Tempio Pausania",
                "label_ar": "تيمبيو بوسانيا",
                "label_fr": "Tempio Pausania"
            },
            {
                "value": 47103,
                "label": "Porto Torres",
                "label_ar": "بورتو توريس",
                "label_fr": "Porto Torres"
            },
            {
                "value": 47104,
                "label": "Macomer",
                "label_ar": "ماكومر",
                "label_fr": "Macomer"
            },
            {
                "value": 47105,
                "label": "Sennori",
                "label_ar": "سنوري",
                "label_fr": "Sennori"
            },
            {
                "value": 47106,
                "label": "Mezzano",
                "label_ar": "ميزانو",
                "label_fr": "Mezzano"
            },
            {
                "value": 47107,
                "label": "Bozzolo",
                "label_ar": "بوزولو",
                "label_fr": "Bozzolo"
            },
            {
                "value": 47108,
                "label": "Barberino di Mugello",
                "label_ar": "باربيرينو دي موجيلو",
                "label_fr": "Barberino di Mugello"
            },
            {
                "value": 47109,
                "label": "Greve in Chianti",
                "label_ar": "جريف في كيانتي",
                "label_fr": "Greve in Chianti"
            },
            {
                "value": 47110,
                "label": "Fiesole",
                "label_ar": "فيزول",
                "label_fr": "Fiesole"
            },
            {
                "value": 47111,
                "label": "Casarano",
                "label_ar": "كاسارانو",
                "label_fr": "Casarano"
            },
            {
                "value": 47112,
                "label": "Bagnoli del Trigno",
                "label_ar": "Bagnoli del Trigno",
                "label_fr": "Bagnoli del Trigno"
            },
            {
                "value": 47113,
                "label": "Cavriglia",
                "label_ar": "كافريجليا",
                "label_fr": "Cavriglia"
            },
            {
                "value": 47114,
                "label": "Castilenti",
                "label_ar": "قشتالة",
                "label_fr": "Castilenti"
            },
            {
                "value": 47115,
                "label": "Collecorvino",
                "label_ar": "كوليكورفينو",
                "label_fr": "Collecorvino"
            },
            {
                "value": 47116,
                "label": "Carpenedolo",
                "label_ar": "كاربينيدولو",
                "label_fr": "Carpenedolo"
            },
            {
                "value": 47117,
                "label": "Santo Stefano di Magra",
                "label_ar": "سانتو ستيفانو دي ماجرا",
                "label_fr": "Santo Stefano di Magra"
            },
            {
                "value": 47118,
                "label": "Negrar",
                "label_ar": "نيجرار",
                "label_fr": "Negrar"
            },
            {
                "value": 47119,
                "label": "Piasco",
                "label_ar": "بياسكو",
                "label_fr": "Piasco"
            },
            {
                "value": 47120,
                "label": "Barbarasco",
                "label_ar": "بارباراسكو",
                "label_fr": "Barbarasco"
            },
            {
                "value": 47121,
                "label": "Asso",
                "label_ar": "اسو",
                "label_fr": "Asso"
            },
            {
                "value": 47122,
                "label": "Belvedere",
                "label_ar": "بلفيدير",
                "label_fr": "Belvédère"
            },
            {
                "value": 47123,
                "label": "Seravezza",
                "label_ar": "سيرافزا",
                "label_fr": "Seravezza"
            },
            {
                "value": 47124,
                "label": "San Piero in Bagno",
                "label_ar": "سان بييرو في بانيو",
                "label_fr": "San Piero à Bagno"
            },
            {
                "value": 47125,
                "label": "Monteprandone",
                "label_ar": "مونتيبراندون",
                "label_fr": "Monteprandone"
            },
            {
                "value": 47126,
                "label": "Monteveglio",
                "label_ar": "مونتيفيليو",
                "label_fr": "Monteveglio"
            },
            {
                "value": 47127,
                "label": "Grumello del Monte",
                "label_ar": "جروميلو ديل مونتي",
                "label_fr": "Grumello del Monte"
            },
            {
                "value": 47128,
                "label": "Cazzago San Martino",
                "label_ar": "كاتساجو سان مارتينو",
                "label_fr": "Cazzago San Martino"
            },
            {
                "value": 47129,
                "label": "Monticelli",
                "label_ar": "مونتيتشيلي",
                "label_fr": "Monticelli"
            },
            {
                "value": 47130,
                "label": "Paderno Franciacorta",
                "label_ar": "باديرنو فرانشاكورتا",
                "label_fr": "Paderno Franciacorta"
            },
            {
                "value": 47131,
                "label": "Scafati",
                "label_ar": "سكافاتي",
                "label_fr": "Scafati"
            },
            {
                "value": 47132,
                "label": "Calvizzano",
                "label_ar": "كالفيزانو",
                "label_fr": "Calvizzano"
            },
            {
                "value": 47133,
                "label": "Sant'Antimo",
                "label_ar": "سانت أنتيمو",
                "label_fr": "Sant'Antimo"
            },
            {
                "value": 47134,
                "label": "Vietri sul Mare",
                "label_ar": "فيتري سول ماري",
                "label_fr": "Vietri sul Mare"
            },
            {
                "value": 47135,
                "label": "Sant'Antonio Abate",
                "label_ar": "سانت أنطونيو أباتي",
                "label_fr": "Sant'Antonio Abate"
            },
            {
                "value": 47136,
                "label": "Sant'Antonio",
                "label_ar": "سانت أنطونيو",
                "label_fr": "Sant'Antonio"
            },
            {
                "value": 47137,
                "label": "Marigliano",
                "label_ar": "ماريجليانو",
                "label_fr": "Marigliano"
            },
            {
                "value": 47138,
                "label": "Boscotrecase",
                "label_ar": "Boscotrecase",
                "label_fr": "Boscotrecase"
            },
            {
                "value": 47139,
                "label": "Pompei",
                "label_ar": "بومبي",
                "label_fr": "Pompéi"
            },
            {
                "value": 47140,
                "label": "Pugliano",
                "label_ar": "بوجليانو",
                "label_fr": "Pugliano"
            },
            {
                "value": 47141,
                "label": "Sorrento",
                "label_ar": "سورينتو",
                "label_fr": "Sorrento"
            },
            {
                "value": 47142,
                "label": "Casalnuovo di Napoli",
                "label_ar": "كاسالنوفو دي نابولي",
                "label_fr": "Casalnuovo di Napoli"
            },
            {
                "value": 47143,
                "label": "Frattaminore",
                "label_ar": "فراتامينور",
                "label_fr": "Frattaminore"
            },
            {
                "value": 47144,
                "label": "Atella",
                "label_ar": "أتيلا",
                "label_fr": "Atella"
            },
            {
                "value": 47145,
                "label": "Casandrino",
                "label_ar": "كاساندرينو",
                "label_fr": "Casandrino"
            },
            {
                "value": 47146,
                "label": "Torre Annunziata",
                "label_ar": "توري أنونزياتا",
                "label_fr": "Torre Annunziata"
            },
            {
                "value": 47147,
                "label": "Ottaviano",
                "label_ar": "أوتافيانو",
                "label_fr": "Ottaviano"
            },
            {
                "value": 47148,
                "label": "Appiano sulla strada del vino",
                "label_ar": "Appiano sulla strada del vino",
                "label_fr": "Appiano sulla strada del vino"
            },
            {
                "value": 47149,
                "label": "Amaro",
                "label_ar": "أمارو",
                "label_fr": "Amaro"
            },
            {
                "value": 47150,
                "label": "Piano",
                "label_ar": "بيانو",
                "label_fr": "Piano"
            },
            {
                "value": 47151,
                "label": "Pozza-San Marco",
                "label_ar": "بوزا سان ماركو",
                "label_fr": "Pozza-San Marco"
            },
            {
                "value": 47152,
                "label": "Casto",
                "label_ar": "كاستو",
                "label_fr": "Casto"
            },
            {
                "value": 47153,
                "label": "Ruviano",
                "label_ar": "روفيانو",
                "label_fr": "Ruviano"
            },
            {
                "value": 47154,
                "label": "Arielli",
                "label_ar": "أرييلي",
                "label_fr": "Arielli"
            },
            {
                "value": 47155,
                "label": "Torrita Tiberina",
                "label_ar": "توريتا تيبيرينا",
                "label_fr": "Torrita Tiberina"
            },
            {
                "value": 47156,
                "label": "Castelnuovo di Verona",
                "label_ar": "Castelnuovo di Verona",
                "label_fr": "Castelnuovo di Verona"
            },
            {
                "value": 47157,
                "label": "Allerona",
                "label_ar": "أليرونا",
                "label_fr": "Allerona"
            },
            {
                "value": 47158,
                "label": "Pellezzano",
                "label_ar": "بيلزانو",
                "label_fr": "Pellezzano"
            },
            {
                "value": 47159,
                "label": "Procida",
                "label_ar": "بروسيدا",
                "label_fr": "Procida"
            },
            {
                "value": 47160,
                "label": "Montalbano Jonico",
                "label_ar": "مونتالبانو جونيكو",
                "label_fr": "Montalbano Jonico"
            },
            {
                "value": 47161,
                "label": "Nova Siri",
                "label_ar": "نوفا سيري",
                "label_fr": "Nova Siri"
            },
            {
                "value": 47162,
                "label": "Avigliano",
                "label_ar": "أفيجليانو",
                "label_fr": "Avigliano"
            },
            {
                "value": 47163,
                "label": "San Giorgio",
                "label_ar": "سان جورجيو",
                "label_fr": "San Giorgio"
            },
            {
                "value": 47164,
                "label": "Soave",
                "label_ar": "سواف",
                "label_fr": "Soave"
            },
            {
                "value": 47165,
                "label": "Filiano",
                "label_ar": "فيليانو",
                "label_fr": "Filiano"
            },
            {
                "value": 47166,
                "label": "Cologna Veneta",
                "label_ar": "كولونيا فينيتا",
                "label_fr": "Cologna Veneta"
            },
            {
                "value": 47167,
                "label": "Mafalda",
                "label_ar": "مافالدا",
                "label_fr": "Mafalda"
            },
            {
                "value": 47168,
                "label": "Aquino",
                "label_ar": "أكينو",
                "label_fr": "Aquino"
            },
            {
                "value": 47169,
                "label": "Vicopisano",
                "label_ar": "فيكوبيسانو",
                "label_fr": "Vicopisano"
            },
            {
                "value": 47170,
                "label": "Mezzolombardo",
                "label_ar": "Mezzolombardo",
                "label_fr": "Mezzolombardo"
            },
            {
                "value": 47171,
                "label": "Cirié",
                "label_ar": "Cirié",
                "label_fr": "Cirié"
            },
            {
                "value": 47172,
                "label": "Gozzano",
                "label_ar": "جوزانو",
                "label_fr": "Gozzano"
            },
            {
                "value": 47173,
                "label": "Ghemme",
                "label_ar": "Ghemme",
                "label_fr": "Ghemme"
            },
            {
                "value": 47174,
                "label": "Chiomonte",
                "label_ar": "شيومونتي",
                "label_fr": "Chiomonte"
            },
            {
                "value": 47175,
                "label": "San Giorgio di Piano",
                "label_ar": "سان جورجيو دي بيانو",
                "label_fr": "San Giorgio di Piano"
            },
            {
                "value": 47176,
                "label": "Case Franchini",
                "label_ar": "قضية فرانشيني",
                "label_fr": "Cas Franchini"
            },
            {
                "value": 47177,
                "label": "Valdengo",
                "label_ar": "فالدينغو",
                "label_fr": "Valdengo"
            },
            {
                "value": 47178,
                "label": "Bagnolo Mella",
                "label_ar": "بانولو ميلا",
                "label_fr": "Bagnolo Mella"
            },
            {
                "value": 47179,
                "label": "Palazzolo sull'Oglio",
                "label_ar": "Palazzolo sull'Oglio",
                "label_fr": "Palazzolo sull'Oglio"
            },
            {
                "value": 47180,
                "label": "Roccavione",
                "label_ar": "روكافيون",
                "label_fr": "Roccavione"
            },
            {
                "value": 47181,
                "label": "Bosconero",
                "label_ar": "بوسكونيرو",
                "label_fr": "Bosconero"
            },
            {
                "value": 47182,
                "label": "Volpiano",
                "label_ar": "فولبيانو",
                "label_fr": "Volpiano"
            },
            {
                "value": 47183,
                "label": "Silea",
                "label_ar": "سيليا",
                "label_fr": "Silea"
            },
            {
                "value": 47184,
                "label": "Pofi",
                "label_ar": "بوفي",
                "label_fr": "Pofi"
            },
            {
                "value": 47185,
                "label": "Grotte di Castro",
                "label_ar": "جروت دي كاسترو",
                "label_fr": "Grotte di Castro"
            },
            {
                "value": 47186,
                "label": "Anghiari",
                "label_ar": "أنغياري",
                "label_fr": "Anghiari"
            },
            {
                "value": 47187,
                "label": "Carini",
                "label_ar": "كاريني",
                "label_fr": "Carini"
            },
            {
                "value": 47188,
                "label": "Collesano",
                "label_ar": "كوليسانو",
                "label_fr": "Collesano"
            },
            {
                "value": 47189,
                "label": "Agerola",
                "label_ar": "أجيرولا",
                "label_fr": "Agerola"
            },
            {
                "value": 47190,
                "label": "Province of Messina",
                "label_ar": "مقاطعة ميسينا",
                "label_fr": "Province de Messine"
            },
            {
                "value": 47191,
                "label": "Ispica",
                "label_ar": "إيسبيكا",
                "label_fr": "Ispica"
            },
            {
                "value": 47192,
                "label": "Rutigliano",
                "label_ar": "روتيجليانو",
                "label_fr": "Rutigliano"
            },
            {
                "value": 47193,
                "label": "Gioia del Colle",
                "label_ar": "جويا ديل كولي",
                "label_fr": "Gioia del Colle"
            },
            {
                "value": 47194,
                "label": "Arnesano",
                "label_ar": "أرنيسانو",
                "label_fr": "Arnesano"
            },
            {
                "value": 47195,
                "label": "Sant'Angelo Muxaro",
                "label_ar": "سانت أنجيلو موكسارو",
                "label_fr": "Sant'Angelo Muxaro"
            },
            {
                "value": 47196,
                "label": "Padula",
                "label_ar": "بادولا",
                "label_fr": "Padula"
            },
            {
                "value": 47197,
                "label": "Anzano del Parco",
                "label_ar": "أنزانو ديل باركو",
                "label_fr": "Anzano del Parco"
            },
            {
                "value": 47198,
                "label": "Olginate",
                "label_ar": "أولجينات",
                "label_fr": "Olginate"
            },
            {
                "value": 47199,
                "label": "Olevano sul Tusciano",
                "label_ar": "Olevano sul Tusciano",
                "label_fr": "Olevano sul Tusciano"
            },
            {
                "value": 47200,
                "label": "Miravalle",
                "label_ar": "مرافال",
                "label_fr": "Miravalle"
            },
            {
                "value": 47201,
                "label": "Il Ciocco",
                "label_ar": "Il Ciocco",
                "label_fr": "Il Ciocco"
            },
            {
                "value": 47202,
                "label": "Fontanella",
                "label_ar": "فونتانيلا",
                "label_fr": "Fontanella"
            },
            {
                "value": 47203,
                "label": "Torri",
                "label_ar": "توري",
                "label_fr": "Torri"
            },
            {
                "value": 47204,
                "label": "Aurelia",
                "label_ar": "أوريليا",
                "label_fr": "Aurelia"
            },
            {
                "value": 47205,
                "label": "Villanova",
                "label_ar": "فيلانوفا",
                "label_fr": "Villanova"
            },
            {
                "value": 47206,
                "label": "Monaco",
                "label_ar": "موناكو",
                "label_fr": "Monaco"
            },
            {
                "value": 47207,
                "label": "Bascapè",
                "label_ar": "باسكاب",
                "label_fr": "Bascapè"
            },
            {
                "value": 47208,
                "label": "Cuggiono",
                "label_ar": "كوجيونو",
                "label_fr": "Cuggiono"
            },
            {
                "value": 47209,
                "label": "Viola",
                "label_ar": "فيولا",
                "label_fr": "Alto"
            },
            {
                "value": 47210,
                "label": "Diamante",
                "label_ar": "ديامانت",
                "label_fr": "Diamante"
            },
            {
                "value": 47211,
                "label": "Carpegna",
                "label_ar": "كاربينا",
                "label_fr": "Carpegna"
            },
            {
                "value": 47212,
                "label": "Uggiate Trevano",
                "label_ar": "يوجيات تريفانو",
                "label_fr": "Uggiate Trevano"
            },
            {
                "value": 47213,
                "label": "Orio al Serio",
                "label_ar": "أوريو آل سيريو",
                "label_fr": "Orio al Serio"
            },
            {
                "value": 47214,
                "label": "Nizza Monferrato",
                "label_ar": "نيزا مونفيراتو",
                "label_fr": "Nizza Monferrato"
            },
            {
                "value": 47215,
                "label": "Quattordio",
                "label_ar": "كواتورديو",
                "label_fr": "Quattordio"
            },
            {
                "value": 47216,
                "label": "Nonantola",
                "label_ar": "نونانتولا",
                "label_fr": "Nonantola"
            },
            {
                "value": 47217,
                "label": "Ozzano Monferrato",
                "label_ar": "أوزانو مونفيراتو",
                "label_fr": "Ozzano Monferrato"
            },
            {
                "value": 47218,
                "label": "Crespellano",
                "label_ar": "كريسبيلانو",
                "label_fr": "Crespellano"
            },
            {
                "value": 47219,
                "label": "Minerbio",
                "label_ar": "مينيربيو",
                "label_fr": "Minerbio"
            },
            {
                "value": 47220,
                "label": "Bentivoglio",
                "label_ar": "بنتيفوجليو",
                "label_fr": "Bentivoglio"
            },
            {
                "value": 47221,
                "label": "Colbordolo",
                "label_ar": "كولبوردولو",
                "label_fr": "Colbordolo"
            },
            {
                "value": 47222,
                "label": "Monte San Pietro",
                "label_ar": "مونتي سان بيترو",
                "label_fr": "Monte San Pietro"
            },
            {
                "value": 47223,
                "label": "Montefiore Conca",
                "label_ar": "مونتيفيوري كونكا",
                "label_fr": "Montefiore Conca"
            },
            {
                "value": 47224,
                "label": "Saludecio",
                "label_ar": "Saludecio",
                "label_fr": "Saludecio"
            },
            {
                "value": 47225,
                "label": "San Leo",
                "label_ar": "سان ليو",
                "label_fr": "San Leo"
            },
            {
                "value": 47226,
                "label": "Lugo",
                "label_ar": "لوغو",
                "label_fr": "Lugo"
            },
            {
                "value": 47227,
                "label": "Magreta",
                "label_ar": "ماجريتا",
                "label_fr": "Magreta"
            },
            {
                "value": 47228,
                "label": "Montese",
                "label_ar": "مونتيس",
                "label_fr": "Montese"
            },
            {
                "value": 47229,
                "label": "Montecchio",
                "label_ar": "مونتيكيو",
                "label_fr": "Montecchio"
            },
            {
                "value": 47230,
                "label": "Pratola Serra",
                "label_ar": "براتولا سيرا",
                "label_fr": "Pratola Serra"
            },
            {
                "value": 47231,
                "label": "Berlingo",
                "label_ar": "بيرلينجو",
                "label_fr": "Berlingo"
            },
            {
                "value": 47232,
                "label": "Castagneto Po",
                "label_ar": "كاستانييتو بو",
                "label_fr": "Castagneto Po"
            },
            {
                "value": 47233,
                "label": "Strambino",
                "label_ar": "سترامبينو",
                "label_fr": "Strambino"
            },
            {
                "value": 47234,
                "label": "Agliè",
                "label_ar": "Agliè",
                "label_fr": "Agliè"
            },
            {
                "value": 47235,
                "label": "Centallo",
                "label_ar": "سنتالو",
                "label_fr": "Centallo"
            },
            {
                "value": 47236,
                "label": "Bagnolo Piemonte",
                "label_ar": "Bagnolo Piemonte",
                "label_fr": "Bagnolo Piemonte"
            },
            {
                "value": 47237,
                "label": "Morozzo",
                "label_ar": "موروزو",
                "label_fr": "Morozzo"
            },
            {
                "value": 47238,
                "label": "Rocca",
                "label_ar": "جرجير",
                "label_fr": "Rocca"
            },
            {
                "value": 47239,
                "label": "Lanzo Torinese",
                "label_ar": "لانزو تورينيزي",
                "label_fr": "Lanzo Torinese"
            },
            {
                "value": 47240,
                "label": "Almese",
                "label_ar": "ألميس",
                "label_fr": "Almese"
            },
            {
                "value": 47241,
                "label": "Scalenghe",
                "label_ar": "Scalenghe",
                "label_fr": "Scalenghe"
            },
            {
                "value": 47242,
                "label": "Volvera",
                "label_ar": "فولفيرا",
                "label_fr": "Volvera"
            },
            {
                "value": 47243,
                "label": "Sommariva del Bosco",
                "label_ar": "سوماريفا ديل بوسكو",
                "label_fr": "Sommariva del Bosco"
            },
            {
                "value": 47244,
                "label": "Quargnento",
                "label_ar": "Quargnento",
                "label_fr": "Quargnento"
            },
            {
                "value": 47245,
                "label": "Cassano Spinola",
                "label_ar": "كاسانو سبينولا",
                "label_fr": "Cassano Spinola"
            },
            {
                "value": 47246,
                "label": "Lonate Ceppino",
                "label_ar": "لونيت سيبينو",
                "label_fr": "Ceppino solitaire"
            },
            {
                "value": 47247,
                "label": "Olgiate Comasco",
                "label_ar": "أولجيات كوماسكو",
                "label_fr": "Olgiate Comasco"
            },
            {
                "value": 47248,
                "label": "Verrone",
                "label_ar": "فيروني",
                "label_fr": "Verrone"
            },
            {
                "value": 47249,
                "label": "Erbè",
                "label_ar": "Erbè",
                "label_fr": "Erbè"
            },
            {
                "value": 47250,
                "label": "Pomponesco",
                "label_ar": "بومبونيسكو",
                "label_fr": "Pomponesco"
            },
            {
                "value": 47251,
                "label": "Castel Goffredo",
                "label_ar": "كاستل جوفريدو",
                "label_fr": "Castel Goffredo"
            },
            {
                "value": 47252,
                "label": "Serravalle Sesia",
                "label_ar": "سيرافالي سيسيا",
                "label_fr": "Serravalle Sesia"
            },
            {
                "value": 47253,
                "label": "Valduggia",
                "label_ar": "فالدوجيا",
                "label_fr": "Valduggia"
            },
            {
                "value": 47254,
                "label": "Coggiola",
                "label_ar": "كوجيولا",
                "label_fr": "Coggiola"
            },
            {
                "value": 47255,
                "label": "Agrano",
                "label_ar": "Agrano",
                "label_fr": "Agrano"
            },
            {
                "value": 47256,
                "label": "San Maurizio d'Opaglio",
                "label_ar": "سان موريزيو دي أوباجليو",
                "label_fr": "San Maurizio d'Opaglio"
            },
            {
                "value": 47257,
                "label": "Casaleggio Novara",
                "label_ar": "كاساليجيو نوفارا",
                "label_fr": "Casaleggio Novara"
            },
            {
                "value": 47258,
                "label": "Mottalciata",
                "label_ar": "موتالشياتا",
                "label_fr": "Mottalciata"
            },
            {
                "value": 47259,
                "label": "Masserano",
                "label_ar": "ماسيرانو",
                "label_fr": "Masserano"
            },
            {
                "value": 47260,
                "label": "Poncarale",
                "label_ar": "بونكارالي",
                "label_fr": "Poncarale"
            },
            {
                "value": 47261,
                "label": "Buscate",
                "label_ar": "بوسكاتي",
                "label_fr": "Buscate"
            },
            {
                "value": 47262,
                "label": "Fabbri",
                "label_ar": "فابري",
                "label_fr": "Fabbri"
            },
            {
                "value": 47263,
                "label": "Dueville",
                "label_ar": "دوفيل",
                "label_fr": "Dueville"
            },
            {
                "value": 47264,
                "label": "Seglia San Bernardo",
                "label_ar": "سيجليا سان برناردو",
                "label_fr": "Seglia San Bernardo"
            },
            {
                "value": 47265,
                "label": "Fornace di Miramare",
                "label_ar": "Fornace di Miramare",
                "label_fr": "Fornace di Miramare"
            },
            {
                "value": 47266,
                "label": "Busalla",
                "label_ar": "بوسالا",
                "label_fr": "Busalla"
            },
            {
                "value": 47267,
                "label": "Valentina",
                "label_ar": "فالنتينا",
                "label_fr": "Valentina"
            },
            {
                "value": 47268,
                "label": "Rizzi",
                "label_ar": "رزي",
                "label_fr": "Rizzi"
            },
            {
                "value": 47269,
                "label": "Rino",
                "label_ar": "رينو",
                "label_fr": "Rino"
            },
            {
                "value": 47270,
                "label": "Guido",
                "label_ar": "جيدو",
                "label_fr": "Guido"
            },
            {
                "value": 47271,
                "label": "Nicola",
                "label_ar": "نقولا",
                "label_fr": "Nicola"
            },
            {
                "value": 47272,
                "label": "Saline",
                "label_ar": "محلول ملحي",
                "label_fr": "Saline"
            },
            {
                "value": 47273,
                "label": "Manzoni",
                "label_ar": "مانزوني",
                "label_fr": "Manzoni"
            },
            {
                "value": 47274,
                "label": "Pellegrino",
                "label_ar": "بيليجرينو",
                "label_fr": "Pellegrino"
            },
            {
                "value": 47275,
                "label": "Poviglio",
                "label_ar": "بوفيجليو",
                "label_fr": "Poviglio"
            },
            {
                "value": 47276,
                "label": "San Pietro",
                "label_ar": "سان بيترو",
                "label_fr": "San Pietro"
            },
            {
                "value": 47277,
                "label": "Pace",
                "label_ar": "سرعة",
                "label_fr": "Rythme"
            },
            {
                "value": 47278,
                "label": "Riviera",
                "label_ar": "ريفيرا",
                "label_fr": "Riviera"
            },
            {
                "value": 47279,
                "label": "Venafro",
                "label_ar": "فينافرو",
                "label_fr": "Venafro"
            },
            {
                "value": 47280,
                "label": "Ciciliano",
                "label_ar": "سيسيليانو",
                "label_fr": "Ciciliano"
            },
            {
                "value": 47281,
                "label": "Baglio Rizzo",
                "label_ar": "باجليو ريزو",
                "label_fr": "Baglio Rizzo"
            },
            {
                "value": 47282,
                "label": "Aragona",
                "label_ar": "أراجونا",
                "label_fr": "Aragona"
            },
            {
                "value": 47283,
                "label": "San Giuseppe",
                "label_ar": "سان جوزيبي",
                "label_fr": "San Giuseppe"
            },
            {
                "value": 47284,
                "label": "Atena Lucana",
                "label_ar": "اتينا لوكانا",
                "label_fr": "Atena Lucana"
            },
            {
                "value": 47285,
                "label": "Venturina",
                "label_ar": "فينتورينا",
                "label_fr": "Venturina"
            },
            {
                "value": 47286,
                "label": "Mariano",
                "label_ar": "ماريانو",
                "label_fr": "Mariano"
            },
            {
                "value": 47287,
                "label": "Lipari",
                "label_ar": "ليباري",
                "label_fr": "Lipari"
            },
            {
                "value": 47288,
                "label": "Corno",
                "label_ar": "كورنو",
                "label_fr": "Corno"
            },
            {
                "value": 47289,
                "label": "Fontevivo",
                "label_ar": "فونتيفيفو",
                "label_fr": "Fontevivo"
            },
            {
                "value": 47290,
                "label": "Paola",
                "label_ar": "باولا",
                "label_fr": "Paola"
            },
            {
                "value": 47291,
                "label": "Sasso",
                "label_ar": "ساسو",
                "label_fr": "Sasso"
            },
            {
                "value": 47292,
                "label": "Ameglia",
                "label_ar": "Ameglia",
                "label_fr": "Ameglia"
            },
            {
                "value": 47293,
                "label": "Centrale",
                "label_ar": "مركزي",
                "label_fr": "Centrale"
            },
            {
                "value": 47294,
                "label": "Portofino",
                "label_ar": "بورتوفينو",
                "label_fr": "Portofino"
            },
            {
                "value": 47295,
                "label": "San Francesco",
                "label_ar": "سان فرانشيسكو",
                "label_fr": "San Francesco"
            },
            {
                "value": 47296,
                "label": "Castelletto",
                "label_ar": "كاستيليتو",
                "label_fr": "Castelletto"
            },
            {
                "value": 47297,
                "label": "Fabbrico",
                "label_ar": "فابريكو",
                "label_fr": "Fabbrico"
            },
            {
                "value": 47298,
                "label": "Villanova Monferrato",
                "label_ar": "فيلانوفا مونفيراتو",
                "label_fr": "Villanova Monferrato"
            },
            {
                "value": 47299,
                "label": "Lido di Ostia",
                "label_ar": "ليدو دي أوستيا",
                "label_fr": "Lido di Ostia"
            },
            {
                "value": 47300,
                "label": "Marina di Carrara",
                "label_ar": "مارينا دي كارارا",
                "label_fr": "Marina di Carrara"
            },
            {
                "value": 47301,
                "label": "Sant'Oreste",
                "label_ar": "سانت اوريستي",
                "label_fr": "Sant'Oreste"
            },
            {
                "value": 47302,
                "label": "Melicucco",
                "label_ar": "ميليكوكو",
                "label_fr": "Mélicucco"
            },
            {
                "value": 47303,
                "label": "Gemona",
                "label_ar": "جيمونا",
                "label_fr": "Gemona"
            },
            {
                "value": 47304,
                "label": "Fiumicello",
                "label_ar": "فيوميسيلو",
                "label_fr": "Fiumicello"
            },
            {
                "value": 47305,
                "label": "Rottofreno",
                "label_ar": "روتوفرينو",
                "label_fr": "Rottofreno"
            },
            {
                "value": 47306,
                "label": "Ittiri",
                "label_ar": "إتيري",
                "label_fr": "Ittiri"
            },
            {
                "value": 47307,
                "label": "Sedilo",
                "label_ar": "Sedilo",
                "label_fr": "Sedilo"
            },
            {
                "value": 47308,
                "label": "Villasalto",
                "label_ar": "فيلاسالتو",
                "label_fr": "Villasalto"
            },
            {
                "value": 47309,
                "label": "Ploaghe",
                "label_ar": "بلاغ",
                "label_fr": "Ploaghe"
            },
            {
                "value": 47310,
                "label": "Selegas",
                "label_ar": "سيليجاس",
                "label_fr": "Selegas"
            },
            {
                "value": 47311,
                "label": "Cornuda",
                "label_ar": "كورنودا",
                "label_fr": "Cornuda"
            },
            {
                "value": 47312,
                "label": "Nurachi",
                "label_ar": "نوراشي",
                "label_fr": "Nurachi"
            },
            {
                "value": 47313,
                "label": "Paternò",
                "label_ar": "باتيرن",
                "label_fr": "Paternò"
            },
            {
                "value": 47314,
                "label": "Aci Castello",
                "label_ar": "اسي كاستيلو",
                "label_fr": "Aci Castello"
            },
            {
                "value": 47315,
                "label": "Sinnai",
                "label_ar": "سيناي",
                "label_fr": "Sinnai"
            },
            {
                "value": 47316,
                "label": "Dolianova",
                "label_ar": "دوليانوفا",
                "label_fr": "Dolianova"
            },
            {
                "value": 47317,
                "label": "Siliqua",
                "label_ar": "سيليكا",
                "label_fr": "Siliqua"
            },
            {
                "value": 47318,
                "label": "Carbonia",
                "label_ar": "كاربونيا",
                "label_fr": "Carbonia"
            },
            {
                "value": 47319,
                "label": "Selargius",
                "label_ar": "سيلارجيوس",
                "label_fr": "Selargius"
            },
            {
                "value": 47320,
                "label": "Sant'Antioco",
                "label_ar": "سانت أنتيوكو",
                "label_fr": "Sant'Antioco"
            },
            {
                "value": 47321,
                "label": "Villacidro",
                "label_ar": "فيلاسيدرو",
                "label_fr": "Villacidro"
            },
            {
                "value": 47322,
                "label": "Capoterra",
                "label_ar": "كابوتيرا",
                "label_fr": "Capoterra"
            },
            {
                "value": 47323,
                "label": "Quartucciu",
                "label_ar": "Quartucciu",
                "label_fr": "Quartucciu"
            },
            {
                "value": 47324,
                "label": "Alleghe",
                "label_ar": "أليج",
                "label_fr": "Alleghe"
            },
            {
                "value": 47325,
                "label": "Bagno di Romagna",
                "label_ar": "باجنو دي رومانيا",
                "label_fr": "Bagno di Romagna"
            },
            {
                "value": 47326,
                "label": "Ballabio",
                "label_ar": "بالابيو",
                "label_fr": "Ballabio"
            },
            {
                "value": 47327,
                "label": "Baveno",
                "label_ar": "بافينو",
                "label_fr": "Baveno"
            },
            {
                "value": 47328,
                "label": "Castronno",
                "label_ar": "كاسترونو",
                "label_fr": "Castronno"
            },
            {
                "value": 47329,
                "label": "San Zenone degli Ezzelini",
                "label_ar": "سان زينون دجلي إزيليني",
                "label_fr": "San Zenone degli Ezzelini"
            },
            {
                "value": 47330,
                "label": "Salara",
                "label_ar": "سالارا",
                "label_fr": "Salara"
            },
            {
                "value": 47331,
                "label": "Carlazzo",
                "label_ar": "كارلازو",
                "label_fr": "Carlazzo"
            },
            {
                "value": 47332,
                "label": "Ternate",
                "label_ar": "تيرنات",
                "label_fr": "Ternate"
            },
            {
                "value": 47333,
                "label": "Premosello-Chiovenda",
                "label_ar": "بريموسيلو تشيوفيندا",
                "label_fr": "Premosello-Chiovenda"
            },
            {
                "value": 47334,
                "label": "Campi",
                "label_ar": "كامبي",
                "label_fr": "Campi"
            },
            {
                "value": 47335,
                "label": "Santa Lucia",
                "label_ar": "سانتا لوسيا",
                "label_fr": "Santa Lucia"
            },
            {
                "value": 47336,
                "label": "Gravellona Toce",
                "label_ar": "جرافيلونا توس",
                "label_fr": "Gravellona Toce"
            },
            {
                "value": 47337,
                "label": "Prevalle",
                "label_ar": "بريفالي",
                "label_fr": "Prevalle"
            },
            {
                "value": 47338,
                "label": "Cirimido",
                "label_ar": "سيريميدو",
                "label_fr": "Cirimido"
            },
            {
                "value": 47339,
                "label": "Breganze",
                "label_ar": "بريغانز",
                "label_fr": "Breganze"
            },
            {
                "value": 47340,
                "label": "Borgo Ticino",
                "label_ar": "بورجو تيتشينو",
                "label_fr": "Borgo Ticino"
            },
            {
                "value": 47341,
                "label": "Pallanza-Intra-Suna",
                "label_ar": "بالانزا انترا سونا",
                "label_fr": "Pallanza-Intra-Suna"
            },
            {
                "value": 47342,
                "label": "Oliveto Lario",
                "label_ar": "أوليفيتو لاريو",
                "label_fr": "Oliveto Lario"
            },
            {
                "value": 47343,
                "label": "Canzo",
                "label_ar": "كانزو",
                "label_fr": "Canzo"
            },
            {
                "value": 47344,
                "label": "Caprino Veronese",
                "label_ar": "كابرينو فيرونيز",
                "label_fr": "Caprino Veronese"
            },
            {
                "value": 47345,
                "label": "Montagnana",
                "label_ar": "مونتانيانا",
                "label_fr": "Montagnana"
            },
            {
                "value": 47346,
                "label": "San Zeno",
                "label_ar": "سان زينو",
                "label_fr": "San Zeno"
            },
            {
                "value": 47347,
                "label": "Gurro",
                "label_ar": "جورو",
                "label_fr": "Gurro"
            },
            {
                "value": 47348,
                "label": "Cambiago",
                "label_ar": "كامبياغو",
                "label_fr": "Cambiago"
            },
            {
                "value": 47349,
                "label": "Brembilla",
                "label_ar": "بريمبيلا",
                "label_fr": "Brembilla"
            },
            {
                "value": 47350,
                "label": "Castello",
                "label_ar": "كاستيلو",
                "label_fr": "Castello"
            },
            {
                "value": 47351,
                "label": "Livigno",
                "label_ar": "ليفينو",
                "label_fr": "Livigno"
            },
            {
                "value": 47352,
                "label": "Sondalo",
                "label_ar": "سوندالو",
                "label_fr": "Sondalo"
            },
            {
                "value": 47353,
                "label": "Trecenta",
                "label_ar": "تريسينتا",
                "label_fr": "Trecenta"
            },
            {
                "value": 47354,
                "label": "Falze-Signoressa",
                "label_ar": "فالز سينيوريسا",
                "label_fr": "Falze-Signoressa"
            },
            {
                "value": 47355,
                "label": "Pasturo",
                "label_ar": "باستورو",
                "label_fr": "Pasturo"
            },
            {
                "value": 47356,
                "label": "Cannobio",
                "label_ar": "كانوبيو",
                "label_fr": "Cannobio"
            },
            {
                "value": 47357,
                "label": "Mel",
                "label_ar": "ميل",
                "label_fr": "Mel"
            },
            {
                "value": 47358,
                "label": "Bianzone",
                "label_ar": "بيانزون",
                "label_fr": "Bianzone"
            },
            {
                "value": 47359,
                "label": "Rancio Valcuvia",
                "label_ar": "رانسيو فالكوفيا",
                "label_fr": "Rancio Valcuvia"
            },
            {
                "value": 47360,
                "label": "Barni",
                "label_ar": "بارني",
                "label_fr": "Barni"
            },
            {
                "value": 47361,
                "label": "Rosate",
                "label_ar": "روزات",
                "label_fr": "Rosate"
            },
            {
                "value": 47362,
                "label": "Camisano Vicentino",
                "label_ar": "كاميسانو فيسينتينو",
                "label_fr": "Camisano Vicentino"
            },
            {
                "value": 47363,
                "label": "Altopascio",
                "label_ar": "ألتوباسيو",
                "label_fr": "Altopascio"
            },
            {
                "value": 47364,
                "label": "Vecchiano-Nodica",
                "label_ar": "فيشيانو نوديكا",
                "label_fr": "Vecchiano-Nodica"
            },
            {
                "value": 47365,
                "label": "Passarella",
                "label_ar": "باساريلا",
                "label_fr": "Passarella"
            },
            {
                "value": 47366,
                "label": "Minerbe",
                "label_ar": "مينيرب",
                "label_fr": "Minerbe"
            },
            {
                "value": 47367,
                "label": "Asola",
                "label_ar": "أسولا",
                "label_fr": "Asola"
            },
            {
                "value": 47368,
                "label": "Piazza",
                "label_ar": "ساحة",
                "label_fr": "Piazza"
            },
            {
                "value": 47369,
                "label": "Pallerone",
                "label_ar": "باليرون",
                "label_fr": "Pallerone"
            },
            {
                "value": 47370,
                "label": "Pianello Val Tidone",
                "label_ar": "بيانيلو فال تيدون",
                "label_fr": "Pianello Val Tidone"
            },
            {
                "value": 47371,
                "label": "San Giuliano Terme",
                "label_ar": "سان جوليانو تيرمي",
                "label_fr": "San Giuliano Terme"
            },
            {
                "value": 47372,
                "label": "Sutri",
                "label_ar": "سوتري",
                "label_fr": "Sutri"
            },
            {
                "value": 47373,
                "label": "Anguillara",
                "label_ar": "أنغيلارا",
                "label_fr": "Anguillara"
            },
            {
                "value": 47374,
                "label": "Sala Bolognese",
                "label_ar": "سالا بولونيز",
                "label_fr": "Sala bolognaise"
            },
            {
                "value": 47375,
                "label": "Montalto delle Marche",
                "label_ar": "مونتالتو ديلي ماركي",
                "label_fr": "Montalto delle Marche"
            },
            {
                "value": 47376,
                "label": "Vigolzone",
                "label_ar": "فيجولزون",
                "label_fr": "Vigolzone"
            },
            {
                "value": 47377,
                "label": "Fontanellato",
                "label_ar": "فونتانيلاتو",
                "label_fr": "Fontanellato"
            },
            {
                "value": 47378,
                "label": "Gallicano nel Lazio",
                "label_ar": "جاليكانو نيل لاتسيو",
                "label_fr": "Gallicano nel Lazio"
            },
            {
                "value": 47379,
                "label": "Caerano di San Marco",
                "label_ar": "كايرانو دي سان ماركو",
                "label_fr": "Caerano di San Marco"
            },
            {
                "value": 47380,
                "label": "Follonica",
                "label_ar": "فولونيكا",
                "label_fr": "Follonica"
            },
            {
                "value": 47381,
                "label": "San Salvatore",
                "label_ar": "سان سالفاتور",
                "label_fr": "San Salvatore"
            },
            {
                "value": 47382,
                "label": "Santhià",
                "label_ar": "سانثيا",
                "label_fr": "Santhià"
            },
            {
                "value": 47383,
                "label": "Cavallirio",
                "label_ar": "كافاليريو",
                "label_fr": "Cavallirio"
            },
            {
                "value": 47384,
                "label": "Comun Nuovo",
                "label_ar": "كومون نوفو",
                "label_fr": "Comun Nuovo"
            },
            {
                "value": 47385,
                "label": "Caravaggio",
                "label_ar": "كارافاجيو",
                "label_fr": "Caravage"
            },
            {
                "value": 47386,
                "label": "Dalmine",
                "label_ar": "Dalmine",
                "label_fr": "Dalmine"
            },
            {
                "value": 47387,
                "label": "Coccaglio",
                "label_ar": "كوكاجليو",
                "label_fr": "Coccaglio"
            },
            {
                "value": 47388,
                "label": "Sabbioneta",
                "label_ar": "سابيونيتا",
                "label_fr": "Sabbioneta"
            },
            {
                "value": 47389,
                "label": "Pergine Valsugana",
                "label_ar": "برجين فالسوجانا",
                "label_fr": "Pergine Valsugana"
            },
            {
                "value": 47390,
                "label": "Cavareno",
                "label_ar": "كافارينو",
                "label_fr": "Cavareno"
            },
            {
                "value": 47391,
                "label": "Taufers im Münstertal",
                "label_ar": "Taufers im Münstertal",
                "label_fr": "Taufers im Münstertal"
            },
            {
                "value": 47392,
                "label": "Mori",
                "label_ar": "موري",
                "label_fr": "Mori"
            },
            {
                "value": 47393,
                "label": "Dervio",
                "label_ar": "درفيو",
                "label_fr": "Dervio"
            },
            {
                "value": 47394,
                "label": "Cogorno",
                "label_ar": "كوجورنو",
                "label_fr": "Cogorno"
            },
            {
                "value": 47395,
                "label": "Santa Margherita Ligure",
                "label_ar": "سانتا مارغريتا ليغور",
                "label_fr": "Santa Margherita Ligure"
            },
            {
                "value": 47396,
                "label": "Gazzada Schianno",
                "label_ar": "جازادا شيانو",
                "label_fr": "Gazzada Schianno"
            },
            {
                "value": 47397,
                "label": "Gropello Cairoli",
                "label_ar": "جروبيلو كايرولي",
                "label_fr": "Gropello Cairoli"
            },
            {
                "value": 47398,
                "label": "Robecchetto con Induno",
                "label_ar": "Robecchetto con Induno",
                "label_fr": "Robecchetto con Induno"
            },
            {
                "value": 47399,
                "label": "Godiasco",
                "label_ar": "جودياسكو",
                "label_fr": "Godiasco"
            },
            {
                "value": 47400,
                "label": "Aulla",
                "label_ar": "اولا",
                "label_fr": "Aulla"
            },
            {
                "value": 47401,
                "label": "Angera",
                "label_ar": "انجيرا",
                "label_fr": "Angera"
            },
            {
                "value": 47402,
                "label": "Cumiana",
                "label_ar": "كوميانا",
                "label_fr": "Cumiana"
            },
            {
                "value": 47403,
                "label": "Ariano nel Polesine",
                "label_ar": "أريانو نيل بوليسين",
                "label_fr": "Ariano nel Polesine"
            },
            {
                "value": 47404,
                "label": "Castiglione delle Stiviere",
                "label_ar": "كاستيجليون ديل ستيفيير",
                "label_fr": "Castiglione delle Stiviere"
            },
            {
                "value": 47405,
                "label": "Vigodarzere",
                "label_ar": "فيجودارزيري",
                "label_fr": "Vigodarzere"
            },
            {
                "value": 47406,
                "label": "Torreglia",
                "label_ar": "توريجليا",
                "label_fr": "Torreglia"
            },
            {
                "value": 47407,
                "label": "Stanghella",
                "label_ar": "ستانغيلا",
                "label_fr": "Stanghella"
            },
            {
                "value": 47408,
                "label": "Campo San Martino",
                "label_ar": "كامبو سان مارتينو",
                "label_fr": "Campo San Martino"
            },
            {
                "value": 47409,
                "label": "Limena",
                "label_ar": "ليمينا",
                "label_fr": "Limena"
            },
            {
                "value": 47410,
                "label": "Roveredo",
                "label_ar": "روفيردو",
                "label_fr": "Roveredo"
            },
            {
                "value": 47411,
                "label": "Taggia",
                "label_ar": "تاجيا",
                "label_fr": "Taggia"
            },
            {
                "value": 47412,
                "label": "Villa Faraldi",
                "label_ar": "فيلا فارالدي",
                "label_fr": "Villa Faraldi"
            },
            {
                "value": 47413,
                "label": "Montalto Dora",
                "label_ar": "مونتالتو دورا",
                "label_fr": "Montalto Dora"
            },
            {
                "value": 47414,
                "label": "Azeglio",
                "label_ar": "Azeglio",
                "label_fr": "Azeglio"
            },
            {
                "value": 47415,
                "label": "Mombercelli",
                "label_ar": "مومبرسيلي",
                "label_fr": "Mombercelli"
            },
            {
                "value": 47416,
                "label": "Burolo",
                "label_ar": "بورولو",
                "label_fr": "Burolo"
            },
            {
                "value": 47417,
                "label": "Villafranca Piemonte",
                "label_ar": "فيلافرانكا بيمونتي",
                "label_fr": "Villafranca Piemonte"
            },
            {
                "value": 47418,
                "label": "Santa Margherita d'Adige",
                "label_ar": "سانتا مارغريتا د أديجي",
                "label_fr": "Santa Margherita d'Adige"
            },
            {
                "value": 47419,
                "label": "San Francesco al Campo",
                "label_ar": "سان فرانسيسكو آل كامبو",
                "label_fr": "San Francesco al Campo"
            },
            {
                "value": 47420,
                "label": "Samone",
                "label_ar": "سامون",
                "label_fr": "Samone"
            },
            {
                "value": 47421,
                "label": "Torre Pellice",
                "label_ar": "توري بيليس",
                "label_fr": "Torre Pellice"
            },
            {
                "value": 47422,
                "label": "Corio",
                "label_ar": "كوريو",
                "label_fr": "Corio"
            },
            {
                "value": 47423,
                "label": "Dogliani",
                "label_ar": "دوغلياني",
                "label_fr": "Dogliani"
            },
            {
                "value": 47424,
                "label": "Gradisca d'Isonzo",
                "label_ar": "جراديكا ديسونزو",
                "label_fr": "Gradisca d'Isonzo"
            },
            {
                "value": 47425,
                "label": "Monfalcone",
                "label_ar": "مونفالكون",
                "label_fr": "Monfalcone"
            },
            {
                "value": 47426,
                "label": "San Vito al Tagliamento",
                "label_ar": "سان فيتو آل تاليامنتو",
                "label_fr": "San Vito al Tagliamento"
            },
            {
                "value": 47427,
                "label": "Muggia",
                "label_ar": "موجيا",
                "label_fr": "Muggia"
            },
            {
                "value": 47428,
                "label": "Cortina d'Ampezzo",
                "label_ar": "كورتينا دامبيزو",
                "label_fr": "Cortina d'Ampezzo"
            },
            {
                "value": 47429,
                "label": "Vigasio",
                "label_ar": "فيجاسيو",
                "label_fr": "Vigasio"
            },
            {
                "value": 47430,
                "label": "Militello in Val di Catania",
                "label_ar": "ميليتيلو في فال دي كاتانيا",
                "label_fr": "Militello dans le Val di Catania"
            },
            {
                "value": 47431,
                "label": "Noto",
                "label_ar": "نوتو",
                "label_fr": "Noto"
            },
            {
                "value": 47432,
                "label": "San Martino in Rio",
                "label_ar": "سان مارتينو في ريو",
                "label_fr": "San Martino à Rio"
            },
            {
                "value": 47433,
                "label": "Montesarchio",
                "label_ar": "مونتيسارشيو",
                "label_fr": "Montesarchio"
            },
            {
                "value": 47434,
                "label": "Bisaccia",
                "label_ar": "بيساشيا",
                "label_fr": "Bisaccia"
            },
            {
                "value": 47435,
                "label": "Dugenta",
                "label_ar": "دوجينتا",
                "label_fr": "Dugenta"
            },
            {
                "value": 47436,
                "label": "Capannori",
                "label_ar": "كابانوري",
                "label_fr": "Capannori"
            },
            {
                "value": 47437,
                "label": "Valmontone",
                "label_ar": "فالمونتون",
                "label_fr": "Valmontone"
            },
            {
                "value": 47438,
                "label": "Tossicia",
                "label_ar": "توسيسيا",
                "label_fr": "Tossicia"
            },
            {
                "value": 47439,
                "label": "Tolfa",
                "label_ar": "تولفا",
                "label_fr": "Tolfa"
            },
            {
                "value": 47440,
                "label": "Corato",
                "label_ar": "كوراتو",
                "label_fr": "Corato"
            },
            {
                "value": 47441,
                "label": "Nogara",
                "label_ar": "نوغارا",
                "label_fr": "Nogara"
            },
            {
                "value": 47442,
                "label": "Ronco all'Adige",
                "label_ar": "رونكو all'Adige",
                "label_fr": "Ronco all'Adige"
            },
            {
                "value": 47443,
                "label": "Santa Lucia di Piave",
                "label_ar": "سانتا لوسيا دي بيافي",
                "label_fr": "Santa Lucia di Piave"
            },
            {
                "value": 47444,
                "label": "Braone",
                "label_ar": "براون",
                "label_fr": "Braone"
            },
            {
                "value": 47445,
                "label": "Isola Dovarese",
                "label_ar": "ايزولا دوفاريس",
                "label_fr": "Isola Dovarese"
            },
            {
                "value": 47446,
                "label": "Villa Vicentina",
                "label_ar": "فيلا فيسينتينا",
                "label_fr": "Villa Vicentina"
            },
            {
                "value": 47447,
                "label": "Povegliano Veronese",
                "label_ar": "بوفيجليانو فيرونيز",
                "label_fr": "Povegliano Veronese"
            },
            {
                "value": 47448,
                "label": "Serravalle Scrivia",
                "label_ar": "سيرافالي سكريفيا",
                "label_fr": "Serravalle Scrivia"
            },
            {
                "value": 47449,
                "label": "Galliate",
                "label_ar": "غالي",
                "label_fr": "Galliate"
            },
            {
                "value": 47450,
                "label": "Ciserano",
                "label_ar": "سيسيرانو",
                "label_fr": "Ciserano"
            },
            {
                "value": 47451,
                "label": "Cisano Bergamasco",
                "label_ar": "سيسانو بيرغاماسكو",
                "label_fr": "Cisano Bergamasco"
            },
            {
                "value": 47452,
                "label": "Galbiate",
                "label_ar": "Galbiate",
                "label_fr": "Galbiate"
            },
            {
                "value": 47453,
                "label": "Viadana",
                "label_ar": "فيادانا",
                "label_fr": "Viadana"
            },
            {
                "value": 47454,
                "label": "Guidizzolo",
                "label_ar": "جويديزولو",
                "label_fr": "Guidizzolo"
            },
            {
                "value": 47455,
                "label": "Ceresara",
                "label_ar": "سيريسارا",
                "label_fr": "Ceresara"
            },
            {
                "value": 47456,
                "label": "Orzivecchi",
                "label_ar": "أورزيفتشي",
                "label_fr": "Orzivecchi"
            },
            {
                "value": 47457,
                "label": "Taio",
                "label_ar": "تايو",
                "label_fr": "Taio"
            },
            {
                "value": 47458,
                "label": "Castelnuovo Magra",
                "label_ar": "Castelnuovo Magra",
                "label_fr": "Castelnuovo Magra"
            },
            {
                "value": 47459,
                "label": "Licciana Nardi",
                "label_ar": "ليسيانا ناردي",
                "label_fr": "Licciana Nardi"
            },
            {
                "value": 47460,
                "label": "Capriate San Gervasio",
                "label_ar": "كابريت سان جيرفاسيو",
                "label_fr": "Capriate San Gervasio"
            },
            {
                "value": 47461,
                "label": "Vanzago",
                "label_ar": "فانزاغو",
                "label_fr": "Vanzago"
            },
            {
                "value": 47462,
                "label": "San Colombano al Lambro",
                "label_ar": "سان كولومبانو أل لامبرو",
                "label_fr": "San Colombano al Lambro"
            },
            {
                "value": 47463,
                "label": "Tromello",
                "label_ar": "تروميلو",
                "label_fr": "Tromello"
            },
            {
                "value": 47464,
                "label": "Spineto",
                "label_ar": "سبينيتو",
                "label_fr": "Spineto"
            },
            {
                "value": 47465,
                "label": "Palazzo Canavese",
                "label_ar": "بالازو كانافيز",
                "label_fr": "Palais Canavese"
            },
            {
                "value": 47466,
                "label": "Calimera",
                "label_ar": "كاليميرا",
                "label_fr": "Calimera"
            },
            {
                "value": 47467,
                "label": "Piovene Rocchette",
                "label_ar": "بيوفيني روتشيت",
                "label_fr": "Piovène Rocchette"
            },
            {
                "value": 47468,
                "label": "Guastalla",
                "label_ar": "Guastalla",
                "label_fr": "Guastalla"
            },
            {
                "value": 47469,
                "label": "Cengio",
                "label_ar": "سينجيو",
                "label_fr": "Cengio"
            },
            {
                "value": 47470,
                "label": "Loano",
                "label_ar": "Loano",
                "label_fr": "Loano"
            },
            {
                "value": 47471,
                "label": "Candia Canavese",
                "label_ar": "كانديا كانافيز",
                "label_fr": "Candia Canavese"
            },
            {
                "value": 47472,
                "label": "Mercenasco",
                "label_ar": "Mercenasco",
                "label_fr": "Mercenasco"
            },
            {
                "value": 47473,
                "label": "Pessinetto",
                "label_ar": "بيسينيتو",
                "label_fr": "Pessinetto"
            },
            {
                "value": 47474,
                "label": "Verolengo",
                "label_ar": "فيرولينجو",
                "label_fr": "Verolengo"
            },
            {
                "value": 47475,
                "label": "Rivara",
                "label_ar": "ريفارا",
                "label_fr": "Rivara"
            },
            {
                "value": 47476,
                "label": "Fontanafredda",
                "label_ar": "فونتانافريددا",
                "label_fr": "Fontanafredda"
            },
            {
                "value": 47477,
                "label": "Spilimbergo",
                "label_ar": "سبيلمبيرجو",
                "label_fr": "Spilimbergo"
            },
            {
                "value": 47478,
                "label": "Basiliano",
                "label_ar": "باسيليانو",
                "label_fr": "Basiliano"
            },
            {
                "value": 47479,
                "label": "Arzene",
                "label_ar": "أرزين",
                "label_fr": "Arzène"
            },
            {
                "value": 47480,
                "label": "Spresiano",
                "label_ar": "سبريسيانو",
                "label_fr": "Spresiano"
            },
            {
                "value": 47481,
                "label": "Codroipo",
                "label_ar": "كودرويبو",
                "label_fr": "Codroipo"
            },
            {
                "value": 47482,
                "label": "Solagna",
                "label_ar": "سولاجنا",
                "label_fr": "Solagna"
            },
            {
                "value": 47483,
                "label": "Sona",
                "label_ar": "سونا",
                "label_fr": "Sona"
            },
            {
                "value": 47484,
                "label": "Cefalù",
                "label_ar": "سيفالو",
                "label_fr": "Cefalù"
            },
            {
                "value": 47485,
                "label": "Camporeale",
                "label_ar": "كامبوريل",
                "label_fr": "Camporeale"
            },
            {
                "value": 47486,
                "label": "Montelepre",
                "label_ar": "مونتيليبر",
                "label_fr": "Montelepre"
            },
            {
                "value": 47487,
                "label": "Granitola Torretta",
                "label_ar": "جرانيتولا توريتا",
                "label_fr": "Granitola Torretta"
            },
            {
                "value": 47488,
                "label": "Altavilla Irpina",
                "label_ar": "التافيلا ايربينا",
                "label_fr": "Altavilla Irpina"
            },
            {
                "value": 47489,
                "label": "Piane Crati",
                "label_ar": "بيان كراتي",
                "label_fr": "Piane Crati"
            },
            {
                "value": 47490,
                "label": "Scandiano",
                "label_ar": "سكانديانو",
                "label_fr": "Scandiano"
            },
            {
                "value": 47491,
                "label": "Spilamberto",
                "label_ar": "سبيلامبرتو",
                "label_fr": "Spilamberto"
            },
            {
                "value": 47492,
                "label": "Pavullo nel Frignano",
                "label_ar": "بافولو نيل فرينيانو",
                "label_fr": "Pavullo nel Frignano"
            },
            {
                "value": 47493,
                "label": "Polesine Parmense",
                "label_ar": "بوليسين بارمينس",
                "label_fr": "Polésine Parmense"
            },
            {
                "value": 47494,
                "label": "Medesano",
                "label_ar": "ميديسانو",
                "label_fr": "Medesano"
            },
            {
                "value": 47495,
                "label": "Sant'Agata Bolognese",
                "label_ar": "سانت أجاتا بولونيز",
                "label_fr": "Sant'Agata Bolognese"
            },
            {
                "value": 47496,
                "label": "Mirabello",
                "label_ar": "ميرابيلو",
                "label_fr": "Mirabello"
            },
            {
                "value": 47497,
                "label": "Morrovalle",
                "label_ar": "موروفال",
                "label_fr": "Morrovalle"
            },
            {
                "value": 47498,
                "label": "Sannicandro di Bari",
                "label_ar": "سانيكاندرو دي باري",
                "label_fr": "Sannicandro di Bari"
            },
            {
                "value": 47499,
                "label": "Massafra",
                "label_ar": "مصفرة",
                "label_fr": "Massafra"
            },
            {
                "value": 47500,
                "label": "Celico",
                "label_ar": "سيليكو",
                "label_fr": "Celico"
            },
            {
                "value": 47501,
                "label": "San Gregorio d'Ippona",
                "label_ar": "سان جريجوريو ديبونا",
                "label_fr": "San Gregorio d'Ippona"
            },
            {
                "value": 47502,
                "label": "Villasmundo",
                "label_ar": "فيلاسموندو",
                "label_fr": "Villasmundo"
            },
            {
                "value": 47503,
                "label": "Ramacca",
                "label_ar": "راماكا",
                "label_fr": "Ramacca"
            },
            {
                "value": 47504,
                "label": "Roccalumera",
                "label_ar": "روكالوميرا",
                "label_fr": "Roccalumera"
            },
            {
                "value": 47505,
                "label": "Dipignano",
                "label_ar": "ديبينيانو",
                "label_fr": "Dipignano"
            },
            {
                "value": 47506,
                "label": "San Demetrio Corone",
                "label_ar": "سان ديميتريو كوروني",
                "label_fr": "San Demetrio Corone"
            },
            {
                "value": 47507,
                "label": "Calci",
                "label_ar": "كالسي",
                "label_fr": "Calci"
            },
            {
                "value": 47508,
                "label": "San Marco Evangelista",
                "label_ar": "سان ماركو إيفانجليستا",
                "label_fr": "San Marco Evangelista"
            },
            {
                "value": 47509,
                "label": "Villa Literno",
                "label_ar": "فيلا ليتيرنو",
                "label_fr": "Villa Literno"
            },
            {
                "value": 47510,
                "label": "Caiatia",
                "label_ar": "كاياتيا",
                "label_fr": "Caïatie"
            },
            {
                "value": 47511,
                "label": "Monte di Procida",
                "label_ar": "مونتي دي بروسيدا",
                "label_fr": "Monte di Procida"
            },
            {
                "value": 47512,
                "label": "Santa Maria Capua Vetere",
                "label_ar": "سانتا ماريا كابوا فيتيري",
                "label_fr": "Santa Maria Capua Vetere"
            },
            {
                "value": 47513,
                "label": "Villamagna",
                "label_ar": "فيلاماجنا",
                "label_fr": "Villamagna"
            },
            {
                "value": 47514,
                "label": "Somma Vesuviana",
                "label_ar": "سوما فيزوفيانا",
                "label_fr": "Somma Vesuviana"
            },
            {
                "value": 47515,
                "label": "Marsala",
                "label_ar": "مارسالا",
                "label_fr": "Marsala"
            },
            {
                "value": 47516,
                "label": "Campobello di Licata",
                "label_ar": "كامبوبيلو دي ليكاتا",
                "label_fr": "Campobello di Licata"
            },
            {
                "value": 47517,
                "label": "Squinzano",
                "label_ar": "سكوينزانو",
                "label_fr": "Squinzano"
            },
            {
                "value": 47518,
                "label": "Alessano",
                "label_ar": "اليسانو",
                "label_fr": "Alessano"
            },
            {
                "value": 47519,
                "label": "Casacalenda",
                "label_ar": "Casacalenda",
                "label_fr": "Casacalenda"
            },
            {
                "value": 47520,
                "label": "San Giovanni",
                "label_ar": "سان جيوفاني",
                "label_fr": "San Giovanni"
            },
            {
                "value": 47521,
                "label": "Sigillo",
                "label_ar": "سيجيلو",
                "label_fr": "Sigillo"
            },
            {
                "value": 47522,
                "label": "Mesagne",
                "label_ar": "ميساني",
                "label_fr": "Mesagne"
            },
            {
                "value": 47523,
                "label": "Cellino San Marco",
                "label_ar": "سيلينو سان ماركو",
                "label_fr": "Cellino San Marco"
            },
            {
                "value": 47524,
                "label": "Soleto",
                "label_ar": "سوليتو",
                "label_fr": "Soleto"
            },
            {
                "value": 47525,
                "label": "Torchiarolo",
                "label_ar": "تورتشيارولو",
                "label_fr": "Torchiarolo"
            },
            {
                "value": 47526,
                "label": "Narni",
                "label_ar": "نارني",
                "label_fr": "Narni"
            },
            {
                "value": 47527,
                "label": "Passignano sul Trasimeno",
                "label_ar": "Passignano sul Trasimeno",
                "label_fr": "Passignano sul Trasimeno"
            },
            {
                "value": 47528,
                "label": "Vernole",
                "label_ar": "فيرنول",
                "label_fr": "Vernole"
            },
            {
                "value": 47529,
                "label": "Trepuzzi",
                "label_ar": "تريبوزي",
                "label_fr": "Trepuzzi"
            },
            {
                "value": 47530,
                "label": "Cartoceto",
                "label_ar": "كارتوسيتو",
                "label_fr": "Cartoceto"
            },
            {
                "value": 47531,
                "label": "Scauri",
                "label_ar": "سكوري",
                "label_fr": "Scauri"
            },
            {
                "value": 47532,
                "label": "Sezze",
                "label_ar": "سيز",
                "label_fr": "Sezze"
            },
            {
                "value": 47533,
                "label": "Camerano",
                "label_ar": "كاميرانو",
                "label_fr": "Camerano"
            },
            {
                "value": 47534,
                "label": "Cerreto d'Esi",
                "label_ar": "سيريتو ديسي",
                "label_fr": "Cerreto d'Esi"
            },
            {
                "value": 47535,
                "label": "Pulsano",
                "label_ar": "بولسانو",
                "label_fr": "Pulsano"
            },
            {
                "value": 47536,
                "label": "Ostuni",
                "label_ar": "أوستوني",
                "label_fr": "Ostuni"
            },
            {
                "value": 47537,
                "label": "Palma Campania",
                "label_ar": "بالما كامبانيا",
                "label_fr": "Palma Campanie"
            },
            {
                "value": 47538,
                "label": "San Lucido",
                "label_ar": "سان لوسيدو",
                "label_fr": "San Lucido"
            },
            {
                "value": 47539,
                "label": "Marina di Campo",
                "label_ar": "مارينا دي كامبو",
                "label_fr": "Marina di Campo"
            },
            {
                "value": 47540,
                "label": "Terracina",
                "label_ar": "تيراسينا",
                "label_fr": "Terracina"
            },
            {
                "value": 47541,
                "label": "Barga",
                "label_ar": "برجا",
                "label_fr": "Barga"
            },
            {
                "value": 47542,
                "label": "Mondavio",
                "label_ar": "موندافيو",
                "label_fr": "Mondavio"
            },
            {
                "value": 47543,
                "label": "Ripe San Ginesio",
                "label_ar": "رايب سان جينسيو",
                "label_fr": "San Ginesio mûr"
            },
            {
                "value": 47544,
                "label": "Servigliano",
                "label_ar": "سيرفيجليانو",
                "label_fr": "Servigliano"
            },
            {
                "value": 47545,
                "label": "Ostra",
                "label_ar": "أوسترا",
                "label_fr": "Ostra"
            },
            {
                "value": 47546,
                "label": "Traversetolo",
                "label_ar": "ترافرسيتولو",
                "label_fr": "Traversetolo"
            },
            {
                "value": 47547,
                "label": "Ponte dell'Olio",
                "label_ar": "بونتي ديل أوليو",
                "label_fr": "Ponte dell'Olio"
            },
            {
                "value": 47548,
                "label": "Porto Sant'Elpidio",
                "label_ar": "بورتو سانت إلبيديو",
                "label_fr": "Porto Sant'Elpidio"
            },
            {
                "value": 47549,
                "label": "Acerno",
                "label_ar": "ايسرنو",
                "label_fr": "Acerno"
            },
            {
                "value": 47550,
                "label": "Moliterno",
                "label_ar": "موليتيرنو",
                "label_fr": "Moliterno"
            },
            {
                "value": 47551,
                "label": "Acquaviva Picena",
                "label_ar": "أكوافيفا بيسينا",
                "label_fr": "Acquaviva Picena"
            },
            {
                "value": 47552,
                "label": "Trinitapoli",
                "label_ar": "ترينيتابولي",
                "label_fr": "Trinitapoli"
            },
            {
                "value": 47553,
                "label": "Sammichele di Bari",
                "label_ar": "Sammichele di Bari",
                "label_fr": "Sammichele di Bari"
            },
            {
                "value": 47554,
                "label": "Castelpagano",
                "label_ar": "كاستيلباغانو",
                "label_fr": "Castelpagano"
            },
            {
                "value": 47555,
                "label": "Santa Croce Camerina",
                "label_ar": "سانتا كروتش كاميرينا",
                "label_fr": "Santa Croce Camerina"
            },
            {
                "value": 47556,
                "label": "Mazzarino",
                "label_ar": "مازارينو",
                "label_fr": "Mazzarino"
            },
            {
                "value": 47557,
                "label": "Castrovillari",
                "label_ar": "كاستروفيلاري",
                "label_fr": "Castrovillari"
            },
            {
                "value": 47558,
                "label": "Giffoni Valle Piana",
                "label_ar": "Giffoni Valle Piana",
                "label_fr": "Giffoni Valle Piana"
            },
            {
                "value": 47559,
                "label": "Torregrotta",
                "label_ar": "توريغروتا",
                "label_fr": "Torregrotta"
            },
            {
                "value": 47560,
                "label": "Casole d'Elsa",
                "label_ar": "كاسول ديلسا",
                "label_fr": "Casole d'Elsa"
            },
            {
                "value": 47561,
                "label": "San Quirico d'Orcia",
                "label_ar": "سان كيريكو دورشيا",
                "label_fr": "San Quirico d'Orcia"
            },
            {
                "value": 47562,
                "label": "Cesa",
                "label_ar": "سيزا",
                "label_fr": "Cesa"
            },
            {
                "value": 47563,
                "label": "Amalfi",
                "label_ar": "أمالفي",
                "label_fr": "Amalfi"
            },
            {
                "value": 47564,
                "label": "Bacoli",
                "label_ar": "باكولي",
                "label_fr": "Bacoli"
            },
            {
                "value": 47565,
                "label": "Corleone",
                "label_ar": "كورليون",
                "label_fr": "Corleone"
            },
            {
                "value": 47566,
                "label": "Colledara",
                "label_ar": "كوليدارا",
                "label_fr": "Colledara"
            },
            {
                "value": 47567,
                "label": "Spinete",
                "label_ar": "سبينيت",
                "label_fr": "Spinete"
            },
            {
                "value": 47568,
                "label": "Sambuceto",
                "label_ar": "Sambuceto",
                "label_fr": "Sambuceto"
            },
            {
                "value": 47569,
                "label": "Jolanda di Savoia",
                "label_ar": "جولاندا دي سافويا",
                "label_fr": "Jolanda di Savoia"
            },
            {
                "value": 47570,
                "label": "San Vito Lo Capo",
                "label_ar": "سان فيتو لو كابو",
                "label_fr": "San Vito Lo Capo"
            },
            {
                "value": 47571,
                "label": "Pontenure",
                "label_ar": "الحبر",
                "label_fr": "Pontenure"
            },
            {
                "value": 47572,
                "label": "Orte",
                "label_ar": "أورتي",
                "label_fr": "Orte"
            },
            {
                "value": 47573,
                "label": "Filettino",
                "label_ar": "فيليتينو",
                "label_fr": "Filettino"
            },
            {
                "value": 47574,
                "label": "Barbiano",
                "label_ar": "باربيانو",
                "label_fr": "Barbiano"
            },
            {
                "value": 47575,
                "label": "Stazione Ponte Rio",
                "label_ar": "Stazione Ponte Rio",
                "label_fr": "Stazione Ponte Rio"
            },
            {
                "value": 47576,
                "label": "Gallipoli",
                "label_ar": "جاليبولي",
                "label_fr": "Gallipoli"
            },
            {
                "value": 47577,
                "label": "Morro d'Alba",
                "label_ar": "مورو دالبا",
                "label_fr": "Morro d'Alba"
            },
            {
                "value": 47578,
                "label": "Ponte a Evola",
                "label_ar": "Ponte a Evola",
                "label_fr": "Ponte a Evola"
            },
            {
                "value": 47579,
                "label": "Ripatransone",
                "label_ar": "ريباترانسون",
                "label_fr": "Ripatransone"
            },
            {
                "value": 47580,
                "label": "Baldichieri d'Asti",
                "label_ar": "Baldichieri d'Asti",
                "label_fr": "Baldichieri d'Asti"
            },
            {
                "value": 47581,
                "label": "Caorso",
                "label_ar": "Caorso",
                "label_fr": "Caorso"
            },
            {
                "value": 47582,
                "label": "Gatteo a Mare",
                "label_ar": "جاتيو ماري",
                "label_fr": "Gatteo a Mare"
            },
            {
                "value": 47583,
                "label": "Cavarzere",
                "label_ar": "كافارزيري",
                "label_fr": "Cavarzere"
            },
            {
                "value": 47584,
                "label": "Ormea",
                "label_ar": "أورميا",
                "label_fr": "Ormea"
            },
            {
                "value": 47585,
                "label": "Bellaria-Igea Marina",
                "label_ar": "بيلاريا - إيجيا مارينا",
                "label_fr": "Marina de Bellaria-Igea"
            },
            {
                "value": 47586,
                "label": "Scarlino",
                "label_ar": "سكارلينو",
                "label_fr": "Scarlino"
            },
            {
                "value": 47587,
                "label": "Garlenda",
                "label_ar": "جارليندا",
                "label_fr": "Garlenda"
            },
            {
                "value": 47588,
                "label": "Montichiari",
                "label_ar": "مونتيشياري",
                "label_fr": "Montichiari"
            },
            {
                "value": 47589,
                "label": "Montelparo",
                "label_ar": "مونتيلبارو",
                "label_fr": "Montelparo"
            },
            {
                "value": 47590,
                "label": "Rezzato",
                "label_ar": "Rezzato",
                "label_fr": "Rezzato"
            },
            {
                "value": 47591,
                "label": "Apiro",
                "label_ar": "أبيرو",
                "label_fr": "Apiro"
            },
            {
                "value": 47592,
                "label": "Castelnuovo dei Sabbioni",
                "label_ar": "Castelnuovo dei Sabbioni",
                "label_fr": "Castelnuovo dei Sabbioni"
            },
            {
                "value": 47593,
                "label": "Bondeno",
                "label_ar": "بوندينو",
                "label_fr": "Bondeno"
            },
            {
                "value": 47594,
                "label": "Valguarnera Caropepe",
                "label_ar": "فالغارنيرا كاروبيبي",
                "label_fr": "Valguarnera Caropepe"
            },
            {
                "value": 47595,
                "label": "Margherita di Savoia",
                "label_ar": "مارغريتا دي سافويا",
                "label_fr": "Margherita di Savoia"
            },
            {
                "value": 47596,
                "label": "Racalmuto",
                "label_ar": "Racalmuto",
                "label_fr": "Racalmuto"
            },
            {
                "value": 47597,
                "label": "Sant'Egidio alla Vibrata",
                "label_ar": "Sant'Egidio alla Vibrata",
                "label_fr": "Sant'Egidio alla Vibrata"
            },
            {
                "value": 47598,
                "label": "Priolo Gargallo",
                "label_ar": "بريولو جارجالو",
                "label_fr": "Priolo Gargallo"
            },
            {
                "value": 47599,
                "label": "Prizzi",
                "label_ar": "بريزي",
                "label_fr": "Prizzi"
            },
            {
                "value": 47600,
                "label": "Villa San Sebastiano",
                "label_ar": "فيلا سان سيباستيانو",
                "label_fr": "Villa San Sebastiano"
            },
            {
                "value": 47601,
                "label": "Isernia",
                "label_ar": "إيزرنيا",
                "label_fr": "Isernia"
            },
            {
                "value": 47602,
                "label": "Barrafranca",
                "label_ar": "باررافانكا",
                "label_fr": "Barrafranca"
            },
            {
                "value": 47603,
                "label": "Capri",
                "label_ar": "كابري",
                "label_fr": "Corsaire"
            },
            {
                "value": 47604,
                "label": "Controguerra",
                "label_ar": "كونتروجيرا",
                "label_fr": "Controguerra"
            },
            {
                "value": 47605,
                "label": "Carinola",
                "label_ar": "كارينولا",
                "label_fr": "Carinola"
            },
            {
                "value": 47606,
                "label": "Almenno San Bartolomeo",
                "label_ar": "ألمينو سان بارتولوميو",
                "label_fr": "Almenno San Bartolomeo"
            },
            {
                "value": 47607,
                "label": "Solbiate Arno",
                "label_ar": "Solbiate أرنو",
                "label_fr": "Solbiate Arno"
            },
            {
                "value": 47608,
                "label": "Medolago",
                "label_ar": "ميدولاغو",
                "label_fr": "Medolago"
            },
            {
                "value": 47609,
                "label": "Vercurago",
                "label_ar": "فيركوراغو",
                "label_fr": "Vercurago"
            },
            {
                "value": 47610,
                "label": "Casaglia",
                "label_ar": "كاساجليا",
                "label_fr": "Casaglia"
            },
            {
                "value": 47611,
                "label": "Sabbio Chiese",
                "label_ar": "Sabbio Chiese",
                "label_fr": "Sabbio Chiese"
            },
            {
                "value": 47612,
                "label": "Portoferraio",
                "label_ar": "بورتوفيرايو",
                "label_fr": "Portoferraio"
            },
            {
                "value": 47613,
                "label": "Jesolo",
                "label_ar": "جيسولو",
                "label_fr": "Jesolo"
            },
            {
                "value": 47614,
                "label": "Arcade",
                "label_ar": "ممر",
                "label_fr": "Arcade"
            },
            {
                "value": 47615,
                "label": "Lancenigo",
                "label_ar": "لانسينيجو",
                "label_fr": "Lancenigo"
            },
            {
                "value": 47616,
                "label": "San Salvatore",
                "label_ar": "سان سالفاتور",
                "label_fr": "San Salvatore"
            },
            {
                "value": 47617,
                "label": "Strevi",
                "label_ar": "ستريفي",
                "label_fr": "Strevi"
            },
            {
                "value": 47618,
                "label": "Oleggio",
                "label_ar": "أوليجيو",
                "label_fr": "Oleggio"
            },
            {
                "value": 47619,
                "label": "Pontecurone",
                "label_ar": "بونتيكوروني",
                "label_fr": "Pontecurone"
            },
            {
                "value": 47620,
                "label": "San Pellegrino Terme",
                "label_ar": "سان بيليجرينو تيرمي",
                "label_fr": "San Pellegrino Terme"
            },
            {
                "value": 47621,
                "label": "Valmadrera",
                "label_ar": "فالمادريرا",
                "label_fr": "Valmadrera"
            },
            {
                "value": 47622,
                "label": "Vadena",
                "label_ar": "فادينا",
                "label_fr": "Vadena"
            },
            {
                "value": 47623,
                "label": "Dorno",
                "label_ar": "دورنو",
                "label_fr": "Dorno"
            },
            {
                "value": 47624,
                "label": "Montù Beccaria",
                "label_ar": "مونتو بيكاريا",
                "label_fr": "Montù Beccaria"
            },
            {
                "value": 47625,
                "label": "Rescaldina",
                "label_ar": "ريسكالدينا",
                "label_fr": "Rescaldina"
            },
            {
                "value": 47626,
                "label": "Borghetto Santo Spirito",
                "label_ar": "بورغيتو سانتو سبيريتو",
                "label_fr": "Borghetto Santo Spirito"
            },
            {
                "value": 47627,
                "label": "Alassio",
                "label_ar": "ألاسيو",
                "label_fr": "Alassio"
            },
            {
                "value": 47628,
                "label": "Cona",
                "label_ar": "كونا",
                "label_fr": "Cona"
            },
            {
                "value": 47629,
                "label": "Bolzano Vicentino",
                "label_ar": "بولزانو فيسينتينو",
                "label_fr": "Bolzano Vicentino"
            },
            {
                "value": 47630,
                "label": "Casalgrasso",
                "label_ar": "كاسالغراسو",
                "label_fr": "Casalgrasso"
            },
            {
                "value": 47631,
                "label": "Saliceto",
                "label_ar": "ساليسيتو",
                "label_fr": "Saliceto"
            },
            {
                "value": 47632,
                "label": "Vallo",
                "label_ar": "فالو",
                "label_fr": "Vallo"
            },
            {
                "value": 47633,
                "label": "Lentiai",
                "label_ar": "Lentiai",
                "label_fr": "Lentiai"
            },
            {
                "value": 47634,
                "label": "Cinto Caomaggiore",
                "label_ar": "سينتو كاوماجيوري",
                "label_fr": "Cinto Caomaggiore"
            },
            {
                "value": 47635,
                "label": "Grezzana",
                "label_ar": "جريزانا",
                "label_fr": "Grezzana"
            },
            {
                "value": 47636,
                "label": "Calalzo di Cadore",
                "label_ar": "كالالزو دي كادوري",
                "label_fr": "Calalzo di Cadore"
            },
            {
                "value": 47637,
                "label": "Cantagallo",
                "label_ar": "كانتاجالو",
                "label_fr": "Cantagallo"
            },
            {
                "value": 47638,
                "label": "Puianello",
                "label_ar": "بويانيلو",
                "label_fr": "Puianello"
            },
            {
                "value": 47639,
                "label": "Marghera",
                "label_ar": "مارغيرا",
                "label_fr": "Marghera"
            },
            {
                "value": 47640,
                "label": "Milena",
                "label_ar": "ميلينا",
                "label_fr": "Milena"
            },
            {
                "value": 47641,
                "label": "Santa Flavia",
                "label_ar": "سانتا فلافيا",
                "label_fr": "Santa Flavia"
            },
            {
                "value": 47642,
                "label": "Pieris",
                "label_ar": "بيريس",
                "label_fr": "Pieris"
            },
            {
                "value": 47643,
                "label": "Pradamano",
                "label_ar": "برادامانو",
                "label_fr": "Pradamano"
            },
            {
                "value": 47644,
                "label": "Paluzza",
                "label_ar": "بالوزا",
                "label_fr": "Paluzza"
            },
            {
                "value": 47645,
                "label": "Pressana",
                "label_ar": "برسانا",
                "label_fr": "Pressana"
            },
            {
                "value": 47646,
                "label": "Tarcento",
                "label_ar": "Tarcento",
                "label_fr": "Tarcento"
            },
            {
                "value": 47647,
                "label": "Maserada sul Piave",
                "label_ar": "مازيرادا سول بيافي",
                "label_fr": "Maserada sul Piave"
            },
            {
                "value": 47648,
                "label": "Castro dei Volsci",
                "label_ar": "كاسترو دي فولشي",
                "label_fr": "Castro dei Volsci"
            },
            {
                "value": 47649,
                "label": "Borgo Vercelli",
                "label_ar": "بورجو فيرشيلي",
                "label_fr": "Borgo Vercelli"
            },
            {
                "value": 47650,
                "label": "Suno",
                "label_ar": "سونو",
                "label_fr": "Suno"
            },
            {
                "value": 47651,
                "label": "Mongrando",
                "label_ar": "مونجراندو",
                "label_fr": "Mongrando"
            },
            {
                "value": 47652,
                "label": "Castelleone",
                "label_ar": "كاستليوني",
                "label_fr": "Castelleone"
            },
            {
                "value": 47653,
                "label": "Gallignano",
                "label_ar": "جالينانو",
                "label_fr": "Gallignano"
            },
            {
                "value": 47654,
                "label": "Mapello",
                "label_ar": "مابيلو",
                "label_fr": "Mapello"
            },
            {
                "value": 47655,
                "label": "Palazzo",
                "label_ar": "بالازو",
                "label_fr": "Palais"
            },
            {
                "value": 47656,
                "label": "Vezzano Ligure",
                "label_ar": "فيزانو ليغور",
                "label_fr": "Vezzano Ligure"
            },
            {
                "value": 47657,
                "label": "Bolano",
                "label_ar": "بولانو",
                "label_fr": "Bolano"
            },
            {
                "value": 47658,
                "label": "Cologne",
                "label_ar": "كولونيا",
                "label_fr": "Eau de Cologne"
            },
            {
                "value": 47659,
                "label": "Toscolano-Maderno",
                "label_ar": "توسكولانو ماديرنو",
                "label_fr": "Toscolano-Maderno"
            },
            {
                "value": 47660,
                "label": "Ospitaletto",
                "label_ar": "أوسبيتاليتو",
                "label_fr": "Ospitaletto"
            },
            {
                "value": 47661,
                "label": "Ceto",
                "label_ar": "سيتو",
                "label_fr": "Ceto"
            },
            {
                "value": 47662,
                "label": "Gazoldo degli Ippoliti",
                "label_ar": "غازولدو ديجلي إيبوليتي",
                "label_fr": "Gazoldo degli Ippoliti"
            },
            {
                "value": 47663,
                "label": "Cembra",
                "label_ar": "سيمبرا",
                "label_fr": "Cembra"
            },
            {
                "value": 47664,
                "label": "Cencenighe Agordino",
                "label_ar": "سينسيني أجوردينو",
                "label_fr": "Cencenighe Agordino"
            },
            {
                "value": 47665,
                "label": "Lozzo di Cadore",
                "label_ar": "لوزو دي كادوري",
                "label_fr": "Lozzo di Cadore"
            },
            {
                "value": 47666,
                "label": "Arosio",
                "label_ar": "أروسيو",
                "label_fr": "Arosio"
            },
            {
                "value": 47667,
                "label": "Dicomano",
                "label_ar": "ديكومانو",
                "label_fr": "Dicomano"
            },
            {
                "value": 47668,
                "label": "San Giorgio di Lomellina",
                "label_ar": "سان جورجيو دي لوميلينا",
                "label_fr": "San Giorgio di Lomellina"
            },
            {
                "value": 47669,
                "label": "Santa Maria",
                "label_ar": "سانتا ماريا",
                "label_fr": "Sainte Marie"
            },
            {
                "value": 47670,
                "label": "Candia Lomellina",
                "label_ar": "كانديا لوميلينا",
                "label_fr": "Candia Lomellina"
            },
            {
                "value": 47671,
                "label": "Lodi Vecchio",
                "label_ar": "لودي فيكيو",
                "label_fr": "Lodi Vecchio"
            },
            {
                "value": 47672,
                "label": "Motta Visconti",
                "label_ar": "موتا فيسكونتي",
                "label_fr": "Motta Visconti"
            },
            {
                "value": 47673,
                "label": "Maslianico",
                "label_ar": "Maslianico",
                "label_fr": "Maslianico"
            },
            {
                "value": 47674,
                "label": "Rondissone",
                "label_ar": "رونديسون",
                "label_fr": "Rondissone"
            },
            {
                "value": 47675,
                "label": "Maddaloni",
                "label_ar": "مادالوني",
                "label_fr": "Maddaloni"
            },
            {
                "value": 47676,
                "label": "Montoro",
                "label_ar": "مونتورو",
                "label_fr": "Montoro"
            },
            {
                "value": 47677,
                "label": "Tombolo",
                "label_ar": "تومبولو",
                "label_fr": "Tombolo"
            },
            {
                "value": 47678,
                "label": "Castellarano",
                "label_ar": "كاستيلارانو",
                "label_fr": "Castellarano"
            },
            {
                "value": 47679,
                "label": "Ceriale",
                "label_ar": "سيريال",
                "label_fr": "Ceriale"
            },
            {
                "value": 47680,
                "label": "Casalmaiocco",
                "label_ar": "كاسالمايوكو",
                "label_fr": "Casalmaiocco"
            },
            {
                "value": 47681,
                "label": "Busca",
                "label_ar": "بوسكا",
                "label_fr": "Busca"
            },
            {
                "value": 47682,
                "label": "Druento",
                "label_ar": "دروينتو",
                "label_fr": "Druento"
            },
            {
                "value": 47683,
                "label": "Cogne",
                "label_ar": "كوجن",
                "label_fr": "Cogne"
            },
            {
                "value": 47684,
                "label": "Castelnuovo",
                "label_ar": "كاستلنوفو",
                "label_fr": "Castelnuovo"
            },
            {
                "value": 47685,
                "label": "Bibione",
                "label_ar": "بيبيوني",
                "label_fr": "Bibione"
            },
            {
                "value": 47686,
                "label": "Romans d'Isonzo",
                "label_ar": "رومان ديسونزو",
                "label_fr": "Romains d'Isonzo"
            },
            {
                "value": 47687,
                "label": "Trichiana",
                "label_ar": "تريشيانا",
                "label_fr": "Trichiana"
            },
            {
                "value": 47688,
                "label": "Gruaro",
                "label_ar": "جروارو",
                "label_fr": "Gruaro"
            },
            {
                "value": 47689,
                "label": "Migliarino",
                "label_ar": "ميغليارينو",
                "label_fr": "Migliarino"
            },
            {
                "value": 47690,
                "label": "Gioiosa Marea",
                "label_ar": "جيويوسا ماريا",
                "label_fr": "Gioiosa Marea"
            },
            {
                "value": 47691,
                "label": "Francavilla di Sicilia",
                "label_ar": "فرانكافيلا دي صقلية",
                "label_fr": "Francavilla di Sicilia"
            },
            {
                "value": 47692,
                "label": "Massarosa",
                "label_ar": "مساروسا",
                "label_fr": "Massarosa"
            },
            {
                "value": 47693,
                "label": "Canossa",
                "label_ar": "كانوسا",
                "label_fr": "Canossa"
            },
            {
                "value": 47694,
                "label": "Fiorano Modenese",
                "label_ar": "فيورانو مودينيز",
                "label_fr": "Fiorano Modenese"
            },
            {
                "value": 47695,
                "label": "Montefalcione",
                "label_ar": "مونتيفالسيون",
                "label_fr": "Montefalcione"
            },
            {
                "value": 47696,
                "label": "Montecalvoli",
                "label_ar": "مونتكالفولي",
                "label_fr": "Montecalvoli"
            },
            {
                "value": 47697,
                "label": "Bazzano",
                "label_ar": "بازانو",
                "label_fr": "Bazzano"
            },
            {
                "value": 47698,
                "label": "Castello di Serravalle",
                "label_ar": "كاستيلو دي سيرافالي",
                "label_fr": "Castello di Serravalle"
            },
            {
                "value": 47699,
                "label": "Gallo",
                "label_ar": "جالو",
                "label_fr": "Gallo"
            },
            {
                "value": 47700,
                "label": "Bovino",
                "label_ar": "بوفينو",
                "label_fr": "Bovino"
            },
            {
                "value": 47701,
                "label": "Monte Urano",
                "label_ar": "مونتي أورانو",
                "label_fr": "Monte Urano"
            },
            {
                "value": 47702,
                "label": "Camerino",
                "label_ar": "كاميرينو",
                "label_fr": "Camerino"
            },
            {
                "value": 47703,
                "label": "San Costanzo",
                "label_ar": "سان كوستانزو",
                "label_fr": "San Costanzo"
            },
            {
                "value": 47704,
                "label": "Mola di Bari",
                "label_ar": "مولا دي باري",
                "label_fr": "Mola di Bari"
            },
            {
                "value": 47705,
                "label": "San Nicolò d'Arcidano",
                "label_ar": "سان نيكولو دارسيدانو",
                "label_fr": "San Nicolò d'Arcidano"
            },
            {
                "value": 47706,
                "label": "Grumo Appula",
                "label_ar": "جرومو أبولا",
                "label_fr": "Grumo Appula"
            },
            {
                "value": 47707,
                "label": "Taormina",
                "label_ar": "تاورمينا",
                "label_fr": "Taormina"
            },
            {
                "value": 47708,
                "label": "Valverde",
                "label_ar": "فالفيردي",
                "label_fr": "Valverde"
            },
            {
                "value": 47709,
                "label": "Nizza di Sicilia",
                "label_ar": "نيزا دي صقلية",
                "label_fr": "Nizza di Sicilia"
            },
            {
                "value": 47710,
                "label": "Casale",
                "label_ar": "كاسال",
                "label_fr": "Casale"
            },
            {
                "value": 47711,
                "label": "Roccapiemonte",
                "label_ar": "روكابيمونتي",
                "label_fr": "Roccapiemonte"
            },
            {
                "value": 47712,
                "label": "Castel Volturno",
                "label_ar": "كاستل فولتورنو",
                "label_fr": "Castel Volturno"
            },
            {
                "value": 47713,
                "label": "Montevarchi",
                "label_ar": "مونتيفارتشي",
                "label_fr": "Montevarchi"
            },
            {
                "value": 47714,
                "label": "Scarperia",
                "label_ar": "سكاربيريا",
                "label_fr": "Scarperia"
            },
            {
                "value": 47715,
                "label": "Solofra",
                "label_ar": "سولوفرا",
                "label_fr": "Solofra"
            },
            {
                "value": 47716,
                "label": "Baselice",
                "label_ar": "بازل",
                "label_fr": "Baselice"
            },
            {
                "value": 47717,
                "label": "Meta",
                "label_ar": "ميتا",
                "label_fr": "Meta"
            },
            {
                "value": 47718,
                "label": "Calatafimi",
                "label_ar": "كالاتافيمي",
                "label_fr": "Calatafimi"
            },
            {
                "value": 47719,
                "label": "Paceco",
                "label_ar": "باسيكو",
                "label_fr": "Paceco"
            },
            {
                "value": 47720,
                "label": "Sant'Angelo d'Alife",
                "label_ar": "سانت أنجيلو دي لايف",
                "label_fr": "Sant'Angelo d'Alife"
            },
            {
                "value": 47721,
                "label": "Castellalto",
                "label_ar": "كاستيلالتو",
                "label_fr": "Castellalto"
            },
            {
                "value": 47722,
                "label": "Spoleto",
                "label_ar": "سبوليتو",
                "label_fr": "Spolète"
            },
            {
                "value": 47723,
                "label": "Gualdo Cattaneo",
                "label_ar": "جوالدو كاتانيو",
                "label_fr": "Gualdo Cattaneo"
            },
            {
                "value": 47724,
                "label": "Recale",
                "label_ar": "إعادة",
                "label_fr": "Recale"
            },
            {
                "value": 47725,
                "label": "Montelabbate",
                "label_ar": "Montelabbate",
                "label_fr": "Montelabbate"
            },
            {
                "value": 47726,
                "label": "Piglio",
                "label_ar": "بيجليو",
                "label_fr": "Piglio"
            },
            {
                "value": 47727,
                "label": "Cinisi",
                "label_ar": "سينيسي",
                "label_fr": "Cinisi"
            },
            {
                "value": 47728,
                "label": "Capena",
                "label_ar": "كابينا",
                "label_fr": "Capena"
            },
            {
                "value": 47729,
                "label": "Pontecorvo",
                "label_ar": "بونتيكورفو",
                "label_fr": "Pontecorvo"
            },
            {
                "value": 47730,
                "label": "Colleferro",
                "label_ar": "كوليفيرو",
                "label_fr": "Colleferro"
            },
            {
                "value": 47731,
                "label": "Gatteo",
                "label_ar": "جاتيو",
                "label_fr": "Gatteo"
            },
            {
                "value": 47732,
                "label": "Rocca San Casciano",
                "label_ar": "روكا سان كاسيانو",
                "label_fr": "Rocca San Casciano"
            },
            {
                "value": 47733,
                "label": "Punta Marina",
                "label_ar": "بونتا مارينا",
                "label_fr": "Punta Marina"
            },
            {
                "value": 47734,
                "label": "Montecastrilli",
                "label_ar": "مونكاستريللي",
                "label_fr": "Montecastrilli"
            },
            {
                "value": 47735,
                "label": "Taviano",
                "label_ar": "تافيانو",
                "label_fr": "Taviano"
            },
            {
                "value": 47736,
                "label": "Aradeo",
                "label_ar": "ارديو",
                "label_fr": "Aradeo"
            },
            {
                "value": 47737,
                "label": "Sannicola",
                "label_ar": "سانيكولا",
                "label_fr": "Sannicola"
            },
            {
                "value": 47738,
                "label": "Chiusi della Verna",
                "label_ar": "Chiusi della Verna",
                "label_fr": "Chiusi della Verna"
            },
            {
                "value": 47739,
                "label": "Vaiano",
                "label_ar": "فايانو",
                "label_fr": "Vaiano"
            },
            {
                "value": 47740,
                "label": "Fossato di Vico",
                "label_ar": "فوساتو دي فيكو",
                "label_fr": "Fossato di Vico"
            },
            {
                "value": 47741,
                "label": "San Vincenzo",
                "label_ar": "سان فينسينزو",
                "label_fr": "San Vincenzo"
            },
            {
                "value": 47742,
                "label": "Soragna",
                "label_ar": "سورانيا",
                "label_fr": "Soragna"
            },
            {
                "value": 47743,
                "label": "Serino",
                "label_ar": "سيرينو",
                "label_fr": "Serino"
            },
            {
                "value": 47744,
                "label": "Capua",
                "label_ar": "كابوا",
                "label_fr": "Capoue"
            },
            {
                "value": 47745,
                "label": "San Ginesio",
                "label_ar": "سان جينسيو",
                "label_fr": "San Ginesio"
            },
            {
                "value": 47746,
                "label": "Cingoli",
                "label_ar": "سينجولي",
                "label_fr": "Cingoli"
            },
            {
                "value": 47747,
                "label": "Gravina in Puglia",
                "label_ar": "جرافينا في بوليا",
                "label_fr": "Gravina dans les Pouilles"
            },
            {
                "value": 47748,
                "label": "San Giorgio la Molara",
                "label_ar": "سان جورجيو لا مولارا",
                "label_fr": "San Giorgio la Molara"
            },
            {
                "value": 47749,
                "label": "Cassano delle Murge",
                "label_ar": "كاسانو ديلي مورج",
                "label_fr": "Cassano delle Murge"
            },
            {
                "value": 47750,
                "label": "Solarino",
                "label_ar": "سولارينو",
                "label_fr": "Solarino"
            },
            {
                "value": 47751,
                "label": "Maletto",
                "label_ar": "ماليتو",
                "label_fr": "Maletto"
            },
            {
                "value": 47752,
                "label": "San Fratello",
                "label_ar": "سان فراتيلو",
                "label_fr": "San Fratello"
            },
            {
                "value": 47753,
                "label": "Squillace",
                "label_ar": "سكويلاس",
                "label_fr": "Squillace"
            },
            {
                "value": 47754,
                "label": "Roccella Ionica",
                "label_ar": "روكيلا ايونيكا",
                "label_fr": "Roccella Ionica"
            },
            {
                "value": 47755,
                "label": "Calangianus",
                "label_ar": "كالانجيانوس",
                "label_fr": "Calangianus"
            },
            {
                "value": 47756,
                "label": "Vinci",
                "label_ar": "فينشي",
                "label_fr": "Vinci"
            },
            {
                "value": 47757,
                "label": "Sinalunga",
                "label_ar": "سينالونجا",
                "label_fr": "Sinalunga"
            },
            {
                "value": 47758,
                "label": "Pimonte",
                "label_ar": "بيمونتي",
                "label_fr": "Pimonte"
            },
            {
                "value": 47759,
                "label": "Monte San Giovanni Campano",
                "label_ar": "مونتي سان جيوفاني كامبانو",
                "label_fr": "Monte San Giovanni Campano"
            },
            {
                "value": 47760,
                "label": "Grumento Nova",
                "label_ar": "جرومينتو نوفا",
                "label_fr": "Grumento Nova"
            },
            {
                "value": 47761,
                "label": "Menfi",
                "label_ar": "منفي",
                "label_fr": "Menfi"
            },
            {
                "value": 47762,
                "label": "Nereto",
                "label_ar": "نريتو",
                "label_fr": "Nereto"
            },
            {
                "value": 47763,
                "label": "Ciminna",
                "label_ar": "سيمينا",
                "label_fr": "Ciminna"
            },
            {
                "value": 47764,
                "label": "Monte San Savino",
                "label_ar": "مونتي سان سافينو",
                "label_fr": "Monte San Savino"
            },
            {
                "value": 47765,
                "label": "San Gregorio",
                "label_ar": "سان جريجوريو",
                "label_fr": "San Gregorio"
            },
            {
                "value": 47766,
                "label": "Campo Tizzoro",
                "label_ar": "كامبو تيزورو",
                "label_fr": "Campo Tizzoro"
            },
            {
                "value": 47767,
                "label": "Arzano",
                "label_ar": "أرزانو",
                "label_fr": "Arzano"
            },
            {
                "value": 47768,
                "label": "Minturno",
                "label_ar": "مينتورنو",
                "label_fr": "Minturno"
            },
            {
                "value": 47769,
                "label": "Trevignano Romano",
                "label_ar": "تريفيجنانو رومانو",
                "label_fr": "Trevignano Romano"
            },
            {
                "value": 47770,
                "label": "Galatone",
                "label_ar": "جالاتون",
                "label_fr": "Galatone"
            },
            {
                "value": 47771,
                "label": "Vitigliano",
                "label_ar": "فيتيجليانو",
                "label_fr": "Vitigliano"
            },
            {
                "value": 47772,
                "label": "Tricase",
                "label_ar": "تريكس",
                "label_fr": "Tricase"
            },
            {
                "value": 47773,
                "label": "Anacapri",
                "label_ar": "أناكابري",
                "label_fr": "Anacapri"
            },
            {
                "value": 47774,
                "label": "Leporano",
                "label_ar": "ليبورانو",
                "label_fr": "Leporano"
            },
            {
                "value": 47775,
                "label": "Catenanuova",
                "label_ar": "كاتينانوفا",
                "label_fr": "Catenanuova"
            },
            {
                "value": 47776,
                "label": "Brolo",
                "label_ar": "برولو",
                "label_fr": "Brolo"
            },
            {
                "value": 47777,
                "label": "San Ferdinando",
                "label_ar": "سان فرديناندو",
                "label_fr": "San Ferdinando"
            },
            {
                "value": 47778,
                "label": "Grazzano Badoglio",
                "label_ar": "جرازانو بادوليو",
                "label_fr": "Grazzano Badoglio"
            },
            {
                "value": 47779,
                "label": "Gavardo",
                "label_ar": "جافاردو",
                "label_fr": "Gavardo"
            },
            {
                "value": 47780,
                "label": "Castelverde",
                "label_ar": "كاستلفيردي",
                "label_fr": "Castelverde"
            },
            {
                "value": 47781,
                "label": "Levico Terme",
                "label_ar": "ليفيكو تيرمي",
                "label_fr": "Levico Terme"
            },
            {
                "value": 47782,
                "label": "Feldthurns",
                "label_ar": "فيلدثرنس",
                "label_fr": "Feldthurns"
            },
            {
                "value": 47783,
                "label": "Klausen",
                "label_ar": "كلاوسن",
                "label_fr": "Klausen"
            },
            {
                "value": 47784,
                "label": "Cles",
                "label_ar": "كليس",
                "label_fr": "Cles"
            },
            {
                "value": 47785,
                "label": "Lierna",
                "label_ar": "ليرنا",
                "label_fr": "Lierna"
            },
            {
                "value": 47786,
                "label": "Abbadia Lariana",
                "label_ar": "عباديا لاريانا",
                "label_fr": "Abbadia Lariana"
            },
            {
                "value": 47787,
                "label": "Lavagna",
                "label_ar": "لافاجنا",
                "label_fr": "Lavagna"
            },
            {
                "value": 47788,
                "label": "Varano",
                "label_ar": "فارانو",
                "label_fr": "Varano"
            },
            {
                "value": 47789,
                "label": "Cavacurta",
                "label_ar": "كافاكورتا",
                "label_fr": "Cavacurta"
            },
            {
                "value": 47790,
                "label": "Casatenovo",
                "label_ar": "كاساتينوفو",
                "label_fr": "Casatenovo"
            },
            {
                "value": 47791,
                "label": "Albano Sant'Alessandro",
                "label_ar": "ألبانو سانت أليساندرو",
                "label_fr": "Albano Sant'Alessandro"
            },
            {
                "value": 47792,
                "label": "Busto Garolfo",
                "label_ar": "بوستو جارولفو",
                "label_fr": "Busto Garolfo"
            },
            {
                "value": 47793,
                "label": "Cesana Torinese",
                "label_ar": "سيسانا تورينيزي",
                "label_fr": "Cesana Torinese"
            },
            {
                "value": 47794,
                "label": "Miradolo Terme",
                "label_ar": "ميرادولو تيرمي",
                "label_fr": "Miradolo Terme"
            },
            {
                "value": 47795,
                "label": "Mozzate",
                "label_ar": "Mozzate",
                "label_fr": "Mozzate"
            },
            {
                "value": 47796,
                "label": "Comazzo",
                "label_ar": "كومازو",
                "label_fr": "Comazzo"
            },
            {
                "value": 47797,
                "label": "Villanova d'Asti",
                "label_ar": "فيلانوفا داستي",
                "label_fr": "Villanova d'Asti"
            },
            {
                "value": 47798,
                "label": "Pettorazza Grimani",
                "label_ar": "بيتورازا جريماني",
                "label_fr": "Pettorazza Grimani"
            },
            {
                "value": 47799,
                "label": "Castelnuovo",
                "label_ar": "كاستلنوفو",
                "label_fr": "Castelnuovo"
            },
            {
                "value": 47800,
                "label": "Tavernola Bergamasca",
                "label_ar": "Tavernola Bergamasca",
                "label_fr": "Tavernola Bergamasca"
            },
            {
                "value": 47801,
                "label": "Capaci",
                "label_ar": "كاباسي",
                "label_fr": "Capaci"
            },
            {
                "value": 47802,
                "label": "Foglizzo",
                "label_ar": "فوجليزو",
                "label_fr": "Foglizzo"
            },
            {
                "value": 47803,
                "label": "San Maurizio Canavese",
                "label_ar": "سان موريزيو كانافيزي",
                "label_fr": "San Maurizio Canavese"
            },
            {
                "value": 47804,
                "label": "San Maurizio",
                "label_ar": "سان موريزيو",
                "label_fr": "San Maurizio"
            },
            {
                "value": 47805,
                "label": "Gassino Torinese",
                "label_ar": "جاسينو تورينيزي",
                "label_fr": "Gassino Torinese"
            },
            {
                "value": 47806,
                "label": "Rocca Canavese",
                "label_ar": "روكا كانافيزي",
                "label_fr": "Rocca Canavese"
            },
            {
                "value": 47807,
                "label": "Valle Mosso",
                "label_ar": "فالي موسو",
                "label_fr": "Valle Mosso"
            },
            {
                "value": 47808,
                "label": "Castelnuovo",
                "label_ar": "كاستلنوفو",
                "label_fr": "Castelnuovo"
            },
            {
                "value": 47809,
                "label": "Anguillara Veneta",
                "label_ar": "أنغيلارا فينيتا",
                "label_fr": "Anguillara Veneta"
            },
            {
                "value": 47810,
                "label": "Morbegno",
                "label_ar": "موربيجنو",
                "label_fr": "Morbegno"
            },
            {
                "value": 47811,
                "label": "Ossona",
                "label_ar": "أوسونا",
                "label_fr": "Ossona"
            },
            {
                "value": 47812,
                "label": "Isorella",
                "label_ar": "إيزوريلا",
                "label_fr": "Isorella"
            },
            {
                "value": 47813,
                "label": "Marina di Gioiosa Ionica",
                "label_ar": "مارينا دي جيويوسا أيونيكا",
                "label_fr": "Marina di Gioiosa Ionica"
            },
            {
                "value": 47814,
                "label": "Casal Velino",
                "label_ar": "كاسال فيلينو",
                "label_fr": "Casal Velino"
            },
            {
                "value": 47815,
                "label": "Galdo",
                "label_ar": "جالدو",
                "label_fr": "Galdo"
            },
            {
                "value": 47816,
                "label": "Gibellina",
                "label_ar": "جيبلينا",
                "label_fr": "Gibellina"
            },
            {
                "value": 47817,
                "label": "Falconara",
                "label_ar": "فالكونارا",
                "label_fr": "Falconara"
            },
            {
                "value": 47818,
                "label": "Altomonte",
                "label_ar": "التومونتي",
                "label_fr": "Altomonte"
            },
            {
                "value": 47819,
                "label": "Lovere",
                "label_ar": "لوفيري",
                "label_fr": "Lovere"
            },
            {
                "value": 47820,
                "label": "Pontestura",
                "label_ar": "بونتستورا",
                "label_fr": "Pontestura"
            },
            {
                "value": 47821,
                "label": "Sotto il Monte Giovanni XXIII",
                "label_ar": "سوتو إل مونتي جيوفاني الثالث والعشرون",
                "label_fr": "Sotto il Monte Giovanni XXIII"
            },
            {
                "value": 47822,
                "label": "Colle",
                "label_ar": "كولي",
                "label_fr": "Colle"
            },
            {
                "value": 47823,
                "label": "Capergnanica",
                "label_ar": "كابيرجنانيكا",
                "label_fr": "Capergnanica"
            },
            {
                "value": 47824,
                "label": "Volta Mantovana",
                "label_ar": "فولتا مانتوفانا",
                "label_fr": "Volta Mantovana"
            },
            {
                "value": 47825,
                "label": "Borgo di Terzo",
                "label_ar": "بورجو دي تيرزو",
                "label_fr": "Borgo di Terzo"
            },
            {
                "value": 47826,
                "label": "Terzo",
                "label_ar": "تيرزو",
                "label_fr": "Terzo"
            },
            {
                "value": 47827,
                "label": "Camogli",
                "label_ar": "كاموجلي",
                "label_fr": "Camogli"
            },
            {
                "value": 47828,
                "label": "Pieve Albignola",
                "label_ar": "Pieve Albignola",
                "label_fr": "Pieve Albignola"
            },
            {
                "value": 47829,
                "label": "Rovellasca",
                "label_ar": "روفيلاسكا",
                "label_fr": "Rovellasca"
            },
            {
                "value": 47830,
                "label": "Castelnuovo",
                "label_ar": "كاستلنوفو",
                "label_fr": "Castelnuovo"
            },
            {
                "value": 47831,
                "label": "Gaggiano",
                "label_ar": "جاجيانو",
                "label_fr": "Gaggiano"
            },
            {
                "value": 47832,
                "label": "Mason",
                "label_ar": "ميسون",
                "label_fr": "le maçon"
            },
            {
                "value": 47833,
                "label": "Villanova del Ghebbo-Valdentro",
                "label_ar": "فيلانوفا ديل جيبو فالدينترو",
                "label_fr": "Villanova del Ghebbo-Valdentro"
            },
            {
                "value": 47834,
                "label": "Torricella",
                "label_ar": "توريسيلا",
                "label_fr": "Torricella"
            },
            {
                "value": 47835,
                "label": "Vedano Olona",
                "label_ar": "فيدانو أولونا",
                "label_fr": "Vedano Olona"
            },
            {
                "value": 47836,
                "label": "Caprie",
                "label_ar": "كابري",
                "label_fr": "Caprie"
            },
            {
                "value": 47837,
                "label": "Ovaro",
                "label_ar": "أوفارو",
                "label_fr": "Ovaro"
            },
            {
                "value": 47838,
                "label": "San Rocco",
                "label_ar": "سان روكو",
                "label_fr": "San Rocco"
            },
            {
                "value": 47839,
                "label": "Paterno",
                "label_ar": "باترنو",
                "label_fr": "Paterno"
            },
            {
                "value": 47840,
                "label": "Cutrofiano",
                "label_ar": "كوتروفيانو",
                "label_fr": "Cutrofiano"
            },
            {
                "value": 47841,
                "label": "Morano Calabro",
                "label_ar": "مورانو كالابرو",
                "label_fr": "Morano Calabro"
            },
            {
                "value": 47842,
                "label": "Sogliano Cavour",
                "label_ar": "سوجليانو كافور",
                "label_fr": "Sogliano Cavour"
            },
            {
                "value": 47843,
                "label": "Maglie",
                "label_ar": "ماجلي",
                "label_fr": "Maglie"
            },
            {
                "value": 47844,
                "label": "Porretta Terme",
                "label_ar": "بوريتا تيرمي",
                "label_fr": "Porretta Terme"
            },
            {
                "value": 47845,
                "label": "Torre San Patrizio",
                "label_ar": "توري سان باتريزيو",
                "label_fr": "Torre San Patrizio"
            },
            {
                "value": 47846,
                "label": "Lesignano de' Bagni",
                "label_ar": "ليسينيانو دي باجني",
                "label_fr": "Lesignano de 'Bagni"
            },
            {
                "value": 47847,
                "label": "Molinella",
                "label_ar": "مولينيلا",
                "label_fr": "Molinella"
            },
            {
                "value": 47848,
                "label": "Calderara di Reno",
                "label_ar": "كالديرارا دي رينو",
                "label_fr": "Calderara di Reno"
            },
            {
                "value": 47849,
                "label": "San Benedetto Val di Sambro",
                "label_ar": "سان بينيديتو فال دي سامبرو",
                "label_fr": "San Benedetto Val di Sambro"
            },
            {
                "value": 47850,
                "label": "San Cesareo",
                "label_ar": "سان سيزاريو",
                "label_fr": "San Cesareo"
            },
            {
                "value": 47851,
                "label": "Bellocchi",
                "label_ar": "بيلوتشي",
                "label_fr": "Bellocchi"
            },
            {
                "value": 47852,
                "label": "Grottazzolina",
                "label_ar": "جروتازولينا",
                "label_fr": "Grottazzolina"
            },
            {
                "value": 47853,
                "label": "San Pietro in Lama",
                "label_ar": "سان بيترو في لاما",
                "label_fr": "San Pietro in Lama"
            },
            {
                "value": 47854,
                "label": "Conversano",
                "label_ar": "كونفيرسانو",
                "label_fr": "Conversano"
            },
            {
                "value": 47855,
                "label": "San Marcello Pistoiese",
                "label_ar": "سان مارسيلو بيستويزي",
                "label_fr": "San Marcello Pistoiese"
            },
            {
                "value": 47856,
                "label": "Belvedere Marittimo",
                "label_ar": "بلفيدير ماريتيمو",
                "label_fr": "Belvédère Marittimo"
            },
            {
                "value": 47857,
                "label": "Fontana Liri",
                "label_ar": "فونتانا ليري",
                "label_fr": "Fontana Liri"
            },
            {
                "value": 47858,
                "label": "Centuripe",
                "label_ar": "سنتوريب",
                "label_fr": "Centuripe"
            },
            {
                "value": 47859,
                "label": "Scordia",
                "label_ar": "سكورديا",
                "label_fr": "Scordia"
            },
            {
                "value": 47860,
                "label": "Riva",
                "label_ar": "ريفا",
                "label_fr": "Riva"
            },
            {
                "value": 47861,
                "label": "Scaletta Zanclea",
                "label_ar": "Scaletta Zanclea",
                "label_fr": "Scaletta Zanclea"
            },
            {
                "value": 47862,
                "label": "Serra d'Aiello",
                "label_ar": "سيرا دييلو",
                "label_fr": "Serra d'Aiello"
            },
            {
                "value": 47863,
                "label": "Vibo Valentia",
                "label_ar": "فيبو فالينتيا",
                "label_fr": "Vibo Valentia"
            },
            {
                "value": 47864,
                "label": "San Casciano dei Bagni",
                "label_ar": "سان كاسيانو دي باني",
                "label_fr": "San Casciano dei Bagni"
            },
            {
                "value": 47865,
                "label": "Terzigno",
                "label_ar": "Terzigno",
                "label_fr": "Terzigno"
            },
            {
                "value": 47866,
                "label": "Montesano sulla Marcellana",
                "label_ar": "مونتيسانو سولا مارسيلانا",
                "label_fr": "Montesano sulla Marcellana"
            },
            {
                "value": 47867,
                "label": "Vairano Patenora",
                "label_ar": "فايرانو باتينورا",
                "label_fr": "Vairano Patenora"
            },
            {
                "value": 47868,
                "label": "Baronissi",
                "label_ar": "البارونيسي",
                "label_fr": "Baronissi"
            },
            {
                "value": 47869,
                "label": "Ripa Teatina",
                "label_ar": "ريبا تياتينا",
                "label_fr": "Ripa Teatina"
            },
            {
                "value": 47870,
                "label": "San Vito Chietino",
                "label_ar": "سان فيتو كييتينو",
                "label_fr": "San Vito Chietino"
            },
            {
                "value": 47871,
                "label": "Trevi",
                "label_ar": "تريفي",
                "label_fr": "Trevi"
            },
            {
                "value": 47872,
                "label": "Castiglione",
                "label_ar": "كاستيجليون",
                "label_fr": "Castiglione"
            },
            {
                "value": 47873,
                "label": "Fisciano",
                "label_ar": "Fisciano",
                "label_fr": "Fisciano"
            },
            {
                "value": 47874,
                "label": "Lake Bolsena",
                "label_ar": "بحيرة بولسينا",
                "label_fr": "Lac de Bolsena"
            },
            {
                "value": 47875,
                "label": "Corleto Perticara",
                "label_ar": "كورليتو بيرتيكارا",
                "label_fr": "Corleto Perticara"
            },
            {
                "value": 47876,
                "label": "Grottaglie",
                "label_ar": "جروتجلي",
                "label_fr": "Grottaglie"
            },
            {
                "value": 47877,
                "label": "Specchia",
                "label_ar": "سبيشيا",
                "label_fr": "Specchia"
            },
            {
                "value": 47878,
                "label": "Castrolibero",
                "label_ar": "كاسترولبيرو",
                "label_fr": "Castrolibero"
            },
            {
                "value": 47879,
                "label": "Atessa",
                "label_ar": "أتسا",
                "label_fr": "Atessa"
            },
            {
                "value": 47880,
                "label": "Altino",
                "label_ar": "ألتينو",
                "label_fr": "Altino"
            },
            {
                "value": 47881,
                "label": "Rio Saliceto",
                "label_ar": "ريو ساليسيتو",
                "label_fr": "Rio Saliceto"
            },
            {
                "value": 47882,
                "label": "Cortemaggiore",
                "label_ar": "Cortemaggiore",
                "label_fr": "Cortemaggiore"
            },
            {
                "value": 47883,
                "label": "Gossolengo",
                "label_ar": "جوسولينجو",
                "label_fr": "Gossolengo"
            },
            {
                "value": 47884,
                "label": "Ribera",
                "label_ar": "ريبيرا",
                "label_fr": "Ribera"
            },
            {
                "value": 47885,
                "label": "Santa Margherita di Belice",
                "label_ar": "سانتا مارغريتا دي بيليس",
                "label_fr": "Santa Margherita di Belice"
            },
            {
                "value": 47886,
                "label": "Alberobello",
                "label_ar": "ألبيروبيلو",
                "label_fr": "Alberobello"
            },
            {
                "value": 47887,
                "label": "Poggiomarino",
                "label_ar": "بوجيومارينو",
                "label_fr": "Poggiomarino"
            },
            {
                "value": 47888,
                "label": "Sant'Agnello",
                "label_ar": "سانت انجيلو",
                "label_fr": "Sant'Agnello"
            },
            {
                "value": 47889,
                "label": "Calatabiano",
                "label_ar": "كالاتابيانو",
                "label_fr": "Calatabiano"
            },
            {
                "value": 47890,
                "label": "Acate",
                "label_ar": "أكات",
                "label_fr": "Acate"
            },
            {
                "value": 47891,
                "label": "Acquaro",
                "label_ar": "أكوارو",
                "label_fr": "Acquaro"
            },
            {
                "value": 47892,
                "label": "Pizzo",
                "label_ar": "بيتزو",
                "label_fr": "Pizzo"
            },
            {
                "value": 47893,
                "label": "Cetraro",
                "label_ar": "سيترارو",
                "label_fr": "Cetraro"
            },
            {
                "value": 47894,
                "label": "Bibbiena",
                "label_ar": "بيبينا",
                "label_fr": "Bibbiena"
            },
            {
                "value": 47895,
                "label": "Cittàdella Pieve",
                "label_ar": "سيتاديلا بيفي",
                "label_fr": "Cittàdella Pieve"
            },
            {
                "value": 47896,
                "label": "Scanzano",
                "label_ar": "سكانزانو",
                "label_fr": "Scanzano"
            },
            {
                "value": 47897,
                "label": "Alessandria della Rocca",
                "label_ar": "اليساندريا ديلا روكا",
                "label_fr": "Alessandria della Rocca"
            },
            {
                "value": 47898,
                "label": "Castelvetrano",
                "label_ar": "كاستيلفيترانو",
                "label_fr": "Castelvetrano"
            },
            {
                "value": 47899,
                "label": "Rosciano",
                "label_ar": "روسيانو",
                "label_fr": "Rosciano"
            },
            {
                "value": 47900,
                "label": "Mosciano Sant'Angelo",
                "label_ar": "موسيانو سانت أنجيلو",
                "label_fr": "Mosciano Sant'Angelo"
            },
            {
                "value": 47901,
                "label": "Otricoli",
                "label_ar": "أوتريكولي",
                "label_fr": "Otricoli"
            },
            {
                "value": 47902,
                "label": "Magione",
                "label_ar": "ماجوني",
                "label_fr": "Magione"
            },
            {
                "value": 47903,
                "label": "San Venanzo",
                "label_ar": "سان فينانزو",
                "label_fr": "San Venanzo"
            },
            {
                "value": 47904,
                "label": "Monte Sant'Angelo",
                "label_ar": "مونتي سانت أنجيلو",
                "label_fr": "Monte Sant'Angelo"
            },
            {
                "value": 47905,
                "label": "Toffia",
                "label_ar": "توفيا",
                "label_fr": "Toffia"
            },
            {
                "value": 47906,
                "label": "Torrice",
                "label_ar": "توريس",
                "label_fr": "Torrice"
            },
            {
                "value": 47907,
                "label": "Fragagnano",
                "label_ar": "فراغانانو",
                "label_fr": "Fragagnano"
            },
            {
                "value": 47908,
                "label": "Ruffano",
                "label_ar": "روفانو",
                "label_fr": "Ruffano"
            },
            {
                "value": 47909,
                "label": "Diano Marina",
                "label_ar": "ديانو مارينا",
                "label_fr": "Diano Marina"
            },
            {
                "value": 47910,
                "label": "Susegana",
                "label_ar": "سوسيجانا",
                "label_fr": "Susegana"
            },
            {
                "value": 47911,
                "label": "Cassacco",
                "label_ar": "كاساكو",
                "label_fr": "Cassacco"
            },
            {
                "value": 47912,
                "label": "Cernobbio",
                "label_ar": "سيرنوبيو",
                "label_fr": "Cernobbio"
            },
            {
                "value": 47913,
                "label": "Sassoferrato",
                "label_ar": "ساسوفيراتو",
                "label_fr": "Sassoferrato"
            },
            {
                "value": 47914,
                "label": "Porto Potenza Picena",
                "label_ar": "بورتو بوتينزا بيسينا",
                "label_fr": "Porto Potenza Picena"
            },
            {
                "value": 47915,
                "label": "Vestone",
                "label_ar": "فيستون",
                "label_fr": "Vestone"
            },
            {
                "value": 47916,
                "label": "Colle Umberto",
                "label_ar": "كولي امبرتو",
                "label_fr": "Colle Umberto"
            },
            {
                "value": 47917,
                "label": "Montegrotto Terme",
                "label_ar": "مونتيغروتو تيرمي",
                "label_fr": "Montegrotto Terme"
            },
            {
                "value": 47918,
                "label": "Canale",
                "label_ar": "كانال",
                "label_fr": "Canale"
            },
            {
                "value": 47919,
                "label": "Spinetoli",
                "label_ar": "سبينيتولي",
                "label_fr": "Spinetoli"
            },
            {
                "value": 47920,
                "label": "Villafrati",
                "label_ar": "فيلافراتي",
                "label_fr": "Villafrati"
            },
            {
                "value": 47921,
                "label": "Pula",
                "label_ar": "بولا",
                "label_fr": "Pula"
            },
            {
                "value": 47922,
                "label": "Gioiosa Ionica",
                "label_ar": "جيويوسا أيونيكا",
                "label_fr": "Gioiosa Ionica"
            },
            {
                "value": 47923,
                "label": "Cellole",
                "label_ar": "سيلول",
                "label_fr": "Cellole"
            },
            {
                "value": 47924,
                "label": "Sicignano degli Alburni",
                "label_ar": "سيسينيانو ديجلي ألبورني",
                "label_fr": "Sicignano degli Alburni"
            },
            {
                "value": 47925,
                "label": "San Cassiano",
                "label_ar": "سان كاسيانو",
                "label_fr": "San Cassiano"
            },
            {
                "value": 47926,
                "label": "Castrignano del Capo",
                "label_ar": "كاسترينيانو ديل كابو",
                "label_fr": "Castrignano del Capo"
            },
            {
                "value": 47927,
                "label": "Besnate",
                "label_ar": "بيسنات",
                "label_fr": "Besnate"
            },
            {
                "value": 47928,
                "label": "Civitella del Tronto",
                "label_ar": "سيفيتيلا ديل ترونتو",
                "label_fr": "Civitella del Tronto"
            },
            {
                "value": 47929,
                "label": "Falciano",
                "label_ar": "فالشيانو",
                "label_fr": "Falciano"
            },
            {
                "value": 47930,
                "label": "Lariano",
                "label_ar": "لاريانو",
                "label_fr": "Lariano"
            },
            {
                "value": 47931,
                "label": "Gragnano",
                "label_ar": "جراجنانو",
                "label_fr": "Gragnano"
            },
            {
                "value": 47932,
                "label": "Cantalupo in Sabina",
                "label_ar": "كانتالوبو في سابينا",
                "label_fr": "Cantalupo à Sabina"
            },
            {
                "value": 47933,
                "label": "Castel Frentano",
                "label_ar": "كاستل فرينتانو",
                "label_fr": "Castel Frentano"
            },
            {
                "value": 47934,
                "label": "Fagagna",
                "label_ar": "فاجانا",
                "label_fr": "Fagagna"
            },
            {
                "value": 47935,
                "label": "Montebello Vicentino",
                "label_ar": "مونتيبيلو فيسينتينو",
                "label_fr": "Montebello Vicentino"
            },
            {
                "value": 47936,
                "label": "Castiglion Fibocchi",
                "label_ar": "كاستيجليون فيبوتشي",
                "label_fr": "Castiglion Fibocchi"
            },
            {
                "value": 47937,
                "label": "Castel San Giovanni",
                "label_ar": "قلعة سان جيوفاني",
                "label_fr": "Castel San Giovanni"
            },
            {
                "value": 47938,
                "label": "Incisa in Val d'Arno",
                "label_ar": "Incisa في Val d'Arno",
                "label_fr": "Incisa dans le Val d'Arno"
            },
            {
                "value": 47939,
                "label": "Fermignano",
                "label_ar": "فيرمينانو",
                "label_fr": "Fermignano"
            },
            {
                "value": 47940,
                "label": "Magliano in Toscana",
                "label_ar": "ماجليانو في توسكانا",
                "label_fr": "Magliano en Toscane"
            },
            {
                "value": 47941,
                "label": "Santa Maria a Monte",
                "label_ar": "سانتا ماريا مونتي",
                "label_fr": "Santa Maria a Monte"
            },
            {
                "value": 47942,
                "label": "Fumone",
                "label_ar": "فومون",
                "label_fr": "Fumone"
            },
            {
                "value": 47943,
                "label": "Agliano",
                "label_ar": "اجليانو",
                "label_fr": "Agliano"
            },
            {
                "value": 47944,
                "label": "Tronzano Vercellese",
                "label_ar": "ترونزانو فيرسيليسي",
                "label_fr": "Tronzano Vercellese"
            },
            {
                "value": 47945,
                "label": "Occhieppo Superiore",
                "label_ar": "Occhieppo Superiore",
                "label_fr": "Occhieppo Superiore"
            },
            {
                "value": 47946,
                "label": "Riva presso Chieri",
                "label_ar": "ريفا بريسو شيري",
                "label_fr": "Riva presso Chieri"
            },
            {
                "value": 47947,
                "label": "Palosco",
                "label_ar": "بالوسكو",
                "label_fr": "Palosco"
            },
            {
                "value": 47948,
                "label": "Villa di Serio",
                "label_ar": "فيلا دي سيريو",
                "label_fr": "Villa di Serio"
            },
            {
                "value": 47949,
                "label": "Chiavenna",
                "label_ar": "شيافينا",
                "label_fr": "Chiavenna"
            },
            {
                "value": 47950,
                "label": "Vobarno",
                "label_ar": "فوبارنو",
                "label_fr": "Vobarno"
            },
            {
                "value": 47951,
                "label": "Calvatone",
                "label_ar": "كالفاتون",
                "label_fr": "Calvatone"
            },
            {
                "value": 47952,
                "label": "Salò",
                "label_ar": "سالو",
                "label_fr": "Salò"
            },
            {
                "value": 47953,
                "label": "Mezzocorona",
                "label_ar": "ميزوكورونا",
                "label_fr": "Mezzocorona"
            },
            {
                "value": 47954,
                "label": "Lerici",
                "label_ar": "ليريسي",
                "label_fr": "Lerici"
            },
            {
                "value": 47955,
                "label": "Campo Ligure",
                "label_ar": "كامبو ليغور",
                "label_fr": "Campo Ligure"
            },
            {
                "value": 47956,
                "label": "Serra Riccò",
                "label_ar": "سيرا ريكو",
                "label_fr": "Serra Riccò"
            },
            {
                "value": 47957,
                "label": "Lago",
                "label_ar": "لاغو",
                "label_fr": "Lago"
            },
            {
                "value": 47958,
                "label": "Zeme",
                "label_ar": "زيم",
                "label_fr": "Zeme"
            },
            {
                "value": 47959,
                "label": "Zelo Buon Persico",
                "label_ar": "زيلو بون بيرسيكو",
                "label_fr": "Zelo Buon Persico"
            },
            {
                "value": 47960,
                "label": "Cassina de' Pecchi",
                "label_ar": "كاسينا دي بيكي",
                "label_fr": "Cassina de 'Pecchi"
            },
            {
                "value": 47961,
                "label": "Mornago",
                "label_ar": "مورناجو",
                "label_fr": "Mornago"
            },
            {
                "value": 47962,
                "label": "Trezzano Rosa",
                "label_ar": "تريزانو روزا",
                "label_fr": "Trezzano Rosa"
            },
            {
                "value": 47963,
                "label": "Carmignano di Brenta",
                "label_ar": "كارمينانو دي برينتا",
                "label_fr": "Carmignano di Brenta"
            },
            {
                "value": 47964,
                "label": "Front",
                "label_ar": "أمامي",
                "label_fr": "De face"
            },
            {
                "value": 47965,
                "label": "Cervere",
                "label_ar": "سيرفير",
                "label_fr": "Cervere"
            },
            {
                "value": 47966,
                "label": "Sant'Ambrogio di Torino",
                "label_ar": "سانت أمبروجيو دي تورينو",
                "label_fr": "Sant'Ambrogio di Torino"
            },
            {
                "value": 47967,
                "label": "Gonars",
                "label_ar": "جونارس",
                "label_fr": "Gonars"
            },
            {
                "value": 47968,
                "label": "Chions",
                "label_ar": "شيون",
                "label_fr": "Chions"
            },
            {
                "value": 47969,
                "label": "San Gregorio da Sassola",
                "label_ar": "سان جريجوريو دا ساسولا",
                "label_fr": "San Gregorio da Sassola"
            },
            {
                "value": 47970,
                "label": "Rapagnano",
                "label_ar": "راباجنانو",
                "label_fr": "Rapagnano"
            },
            {
                "value": 47971,
                "label": "Lastra a Signa",
                "label_ar": "Lastra a Signa",
                "label_fr": "Lastra a Signa"
            },
            {
                "value": 47972,
                "label": "Contursi Terme",
                "label_ar": "كونتورسي تيرمي",
                "label_fr": "Contursi Terme"
            },
            {
                "value": 47973,
                "label": "Lanuvio",
                "label_ar": "لانوفيو",
                "label_fr": "Lanuvio"
            },
            {
                "value": 47974,
                "label": "Agordo",
                "label_ar": "اجوردو",
                "label_fr": "Agordo"
            },
            {
                "value": 47975,
                "label": "Castel San Pietro Romano",
                "label_ar": "قلعة سان بيترو رومانو",
                "label_fr": "Castel San Pietro Romano"
            },
            {
                "value": 47976,
                "label": "Cerano",
                "label_ar": "سيرانو",
                "label_fr": "Cerano"
            },
            {
                "value": 47977,
                "label": "Spigno Saturnia",
                "label_ar": "Spigno Saturnia",
                "label_fr": "Spigno Saturnia"
            },
            {
                "value": 47978,
                "label": "Martinengo",
                "label_ar": "مارتينينجو",
                "label_fr": "Martinengo"
            },
            {
                "value": 47979,
                "label": "Dongo",
                "label_ar": "دونغو",
                "label_fr": "Dongo"
            },
            {
                "value": 47980,
                "label": "Colere",
                "label_ar": "كولير",
                "label_fr": "Colère"
            },
            {
                "value": 47981,
                "label": "Calascibetta",
                "label_ar": "Calascibetta",
                "label_fr": "Calascibetta"
            },
            {
                "value": 47982,
                "label": "Lonato",
                "label_ar": "لوناتو",
                "label_fr": "Lonato"
            },
            {
                "value": 47983,
                "label": "Ospedaletto",
                "label_ar": "أوسبيداليتو",
                "label_fr": "Ospedaletto"
            },
            {
                "value": 47984,
                "label": "Calliano",
                "label_ar": "كاليانو",
                "label_fr": "Calliano"
            },
            {
                "value": 47985,
                "label": "Ortonovo",
                "label_ar": "أورتونوفو",
                "label_fr": "Ortonovo"
            },
            {
                "value": 47986,
                "label": "Guamaggiore",
                "label_ar": "غواماجيوري",
                "label_fr": "Guamaggiore"
            },
            {
                "value": 47987,
                "label": "Canneto Pavese",
                "label_ar": "كانيتو بافيس",
                "label_fr": "Canneto Pavese"
            },
            {
                "value": 47988,
                "label": "Lana",
                "label_ar": "لانا",
                "label_fr": "Lana"
            },
            {
                "value": 47989,
                "label": "Campione",
                "label_ar": "كامبيون",
                "label_fr": "Campione"
            },
            {
                "value": 47990,
                "label": "Cavagnolo",
                "label_ar": "كافاجنولو",
                "label_fr": "Cavagnolo"
            },
            {
                "value": 47991,
                "label": "Pastrengo",
                "label_ar": "باسترينغو",
                "label_fr": "Pastrengo"
            },
            {
                "value": 47992,
                "label": "ArquàPetrarca",
                "label_ar": "ArquàPetrarca",
                "label_fr": "ArquàPetrarca"
            },
            {
                "value": 47993,
                "label": "Loreggia",
                "label_ar": "لوريجيا",
                "label_fr": "Loreggia"
            },
            {
                "value": 47994,
                "label": "Trequanda",
                "label_ar": "تريكواندا",
                "label_fr": "Trequanda"
            },
            {
                "value": 47995,
                "label": "Cocquio-Trevisago",
                "label_ar": "كوكيو تريفيساغو",
                "label_fr": "Cocquio-Trévise"
            },
            {
                "value": 47996,
                "label": "Albano Vercellese",
                "label_ar": "ألبانو فيرسيليسي",
                "label_fr": "Albano Vercellese"
            },
            {
                "value": 47997,
                "label": "Rignano Flaminio",
                "label_ar": "ريجنانو فلامينيو",
                "label_fr": "Rignano Flaminio"
            },
            {
                "value": 47998,
                "label": "Trevignano",
                "label_ar": "تريفينيانو",
                "label_fr": "Trevignano"
            },
            {
                "value": 47999,
                "label": "Postioma",
                "label_ar": "الورم الخلفي",
                "label_fr": "Postiome"
            },
            {
                "value": 48000,
                "label": "Teggiano",
                "label_ar": "تيجيانو",
                "label_fr": "Teggiano"
            },
            {
                "value": 48001,
                "label": "Rapolla",
                "label_ar": "رابولا",
                "label_fr": "Rapolla"
            },
            {
                "value": 48002,
                "label": "San Giovanni Rotondo",
                "label_ar": "سان جيوفاني روتوندو",
                "label_fr": "San Giovanni Rotondo"
            },
            {
                "value": 48003,
                "label": "Saponara",
                "label_ar": "سابونارا",
                "label_fr": "Saponara"
            },
            {
                "value": 48004,
                "label": "San Sosti",
                "label_ar": "سان سوستي",
                "label_fr": "San Sosti"
            },
            {
                "value": 48005,
                "label": "Badolato",
                "label_ar": "بادولاتو",
                "label_fr": "Badolato"
            },
            {
                "value": 48006,
                "label": "Rubbiano",
                "label_ar": "روبيانو",
                "label_fr": "Rubbiano"
            },
            {
                "value": 48007,
                "label": "Casale di Mezzani",
                "label_ar": "كاسال دي ميزاني",
                "label_fr": "Casale di Mezzani"
            },
            {
                "value": 48008,
                "label": "Noceto",
                "label_ar": "Noceto",
                "label_fr": "Noceto"
            },
            {
                "value": 48009,
                "label": "Anzola dell'Emilia",
                "label_ar": "أنزولا ديل إيميليا",
                "label_fr": "Anzola dell'Emilia"
            },
            {
                "value": 48010,
                "label": "Porto Garibaldi",
                "label_ar": "بورتو غاريبالدي",
                "label_fr": "Porto Garibaldi"
            },
            {
                "value": 48011,
                "label": "Monzuno",
                "label_ar": "مونزونو",
                "label_fr": "Monzuno"
            },
            {
                "value": 48012,
                "label": "Noci",
                "label_ar": "نوسي",
                "label_fr": "Noci"
            },
            {
                "value": 48013,
                "label": "Mogoro",
                "label_ar": "موغورو",
                "label_fr": "Mogoro"
            },
            {
                "value": 48014,
                "label": "Serrenti",
                "label_ar": "سيرينتي",
                "label_fr": "Serrenti"
            },
            {
                "value": 48015,
                "label": "Narcao",
                "label_ar": "ناركاو",
                "label_fr": "Narcao"
            },
            {
                "value": 48016,
                "label": "Enna",
                "label_ar": "اينا",
                "label_fr": "Enna"
            },
            {
                "value": 48017,
                "label": "Santa Venerina",
                "label_ar": "سانتا فينيرينا",
                "label_fr": "Santa Venerina"
            },
            {
                "value": 48018,
                "label": "Santa Teresa di Riva",
                "label_ar": "سانتا تيريزا دي ريفا",
                "label_fr": "Santa Teresa di Riva"
            },
            {
                "value": 48019,
                "label": "Santa Caterina Villarmosa",
                "label_ar": "سانتا كاترينا فيلارموسا",
                "label_fr": "Santa Caterina Villarmosa"
            },
            {
                "value": 48020,
                "label": "Stalettì",
                "label_ar": "ستاليتو",
                "label_fr": "Stalettì"
            },
            {
                "value": 48021,
                "label": "Civitella in Val di Chiana",
                "label_ar": "سيفيتيلا في فال دي شيانا",
                "label_fr": "Civitella à Val di Chiana"
            },
            {
                "value": 48022,
                "label": "Rufina",
                "label_ar": "روفينا",
                "label_fr": "Rufina"
            },
            {
                "value": 48023,
                "label": "Camerata Picena",
                "label_ar": "كاميراتا بيسينا",
                "label_fr": "Camerata Picena"
            },
            {
                "value": 48024,
                "label": "Macerata Campania",
                "label_ar": "ماشيراتا كامبانيا",
                "label_fr": "Macerata Campanie"
            },
            {
                "value": 48025,
                "label": "Abbasanta",
                "label_ar": "عباسانتا",
                "label_fr": "Abbasanta"
            },
            {
                "value": 48026,
                "label": "Bucchianico",
                "label_ar": "بوكشيانيكو",
                "label_fr": "Bucchianico"
            },
            {
                "value": 48027,
                "label": "Bellante",
                "label_ar": "بيلانت",
                "label_fr": "Bellante"
            },
            {
                "value": 48028,
                "label": "Canistro",
                "label_ar": "كانيسترو",
                "label_fr": "Canistro"
            },
            {
                "value": 48029,
                "label": "Penne",
                "label_ar": "بيني",
                "label_fr": "Penne"
            },
            {
                "value": 48030,
                "label": "Castiglione del Lago",
                "label_ar": "كاستيجليون ديل لاغو",
                "label_fr": "Castiglione del Lago"
            },
            {
                "value": 48031,
                "label": "Novafeltria",
                "label_ar": "نوفافيلتريا",
                "label_fr": "Novafeltria"
            },
            {
                "value": 48032,
                "label": "Corfinio",
                "label_ar": "كورفينيو",
                "label_fr": "Corfinio"
            },
            {
                "value": 48033,
                "label": "Riccia",
                "label_ar": "Riccia",
                "label_fr": "Riccia"
            },
            {
                "value": 48034,
                "label": "Oppido Lucano",
                "label_ar": "اوبيدو لوكانو",
                "label_fr": "Oppido Lucano"
            },
            {
                "value": 48035,
                "label": "San Martino",
                "label_ar": "سان مارتينو",
                "label_fr": "San Martino"
            },
            {
                "value": 48036,
                "label": "Torella dei Lombardi",
                "label_ar": "توريلا دي لومباردي",
                "label_fr": "Torella dei Lombardi"
            },
            {
                "value": 48037,
                "label": "Marcellina",
                "label_ar": "مارسيلينا",
                "label_fr": "Marcellina"
            },
            {
                "value": 48038,
                "label": "Allumiere",
                "label_ar": "ألوميير",
                "label_fr": "Allumière"
            },
            {
                "value": 48039,
                "label": "Mazzano Romano",
                "label_ar": "مازانو رومانو",
                "label_fr": "Mazzano Romano"
            },
            {
                "value": 48040,
                "label": "San Cesario di Lecce",
                "label_ar": "سان سيساريو دي ليتشي",
                "label_fr": "San Cesario di Lecce"
            },
            {
                "value": 48041,
                "label": "Parabita",
                "label_ar": "بارابيتا",
                "label_fr": "Parabita"
            },
            {
                "value": 48042,
                "label": "Striano",
                "label_ar": "ستريانو",
                "label_fr": "Striano"
            },
            {
                "value": 48043,
                "label": "Cancello ed Arnone",
                "label_ar": "إلغاء إد أرنوني",
                "label_fr": "Cancello ed Arnone"
            },
            {
                "value": 48044,
                "label": "Amorosi",
                "label_ar": "أموروسي",
                "label_fr": "Amorosi"
            },
            {
                "value": 48045,
                "label": "Francavilla in Sinni",
                "label_ar": "Francavilla في Sinni",
                "label_fr": "Francavilla à Sinni"
            },
            {
                "value": 48046,
                "label": "Amelia",
                "label_ar": "اميليا",
                "label_fr": "Amelia"
            },
            {
                "value": 48047,
                "label": "Castellabate",
                "label_ar": "كاستيلاباتي",
                "label_fr": "Castellabate"
            },
            {
                "value": 48048,
                "label": "Bova Marina",
                "label_ar": "بوفا مارينا",
                "label_fr": "Bova Marina"
            },
            {
                "value": 48049,
                "label": "Sorbolo",
                "label_ar": "سوربولو",
                "label_fr": "Sorbolo"
            },
            {
                "value": 48050,
                "label": "Reno",
                "label_ar": "رينو",
                "label_fr": "Reno"
            },
            {
                "value": 48051,
                "label": "Falerone",
                "label_ar": "فاليرون",
                "label_fr": "Falerone"
            },
            {
                "value": 48052,
                "label": "Colli del Tronto",
                "label_ar": "كولي ديل ترونتو",
                "label_fr": "Colli del Tronto"
            },
            {
                "value": 48053,
                "label": "Castel Colonna",
                "label_ar": "كاستل كولونا",
                "label_fr": "Castel Colonna"
            },
            {
                "value": 48054,
                "label": "Cisternino",
                "label_ar": "سيستيرنينو",
                "label_fr": "Cisternino"
            },
            {
                "value": 48055,
                "label": "Polignano a Mare",
                "label_ar": "بولينيانو أ ماري",
                "label_fr": "Polignano a Mare"
            },
            {
                "value": 48056,
                "label": "Domusnovas",
                "label_ar": "دوموسنوفاس",
                "label_fr": "Domusnovas"
            },
            {
                "value": 48057,
                "label": "Alife",
                "label_ar": "حياة",
                "label_fr": "Une vie"
            },
            {
                "value": 48058,
                "label": "Itri",
                "label_ar": "ايتري",
                "label_fr": "J'essaie"
            },
            {
                "value": 48059,
                "label": "Antrodoco",
                "label_ar": "أنترودوكو",
                "label_fr": "Antrodoco"
            },
            {
                "value": 48060,
                "label": "Itala",
                "label_ar": "إيتالا",
                "label_fr": "Itala"
            },
            {
                "value": 48061,
                "label": "Piraino",
                "label_ar": "بيراينو",
                "label_fr": "Piraino"
            },
            {
                "value": 48062,
                "label": "Rometta",
                "label_ar": "روميتا",
                "label_fr": "Rometta"
            },
            {
                "value": 48063,
                "label": "Castellina in Chianti",
                "label_ar": "كاستيلينا في كيانتي",
                "label_fr": "Castellina in Chianti"
            },
            {
                "value": 48064,
                "label": "Cerreto Guidi",
                "label_ar": "سيريتو جيدي",
                "label_fr": "Cerreto Guidi"
            },
            {
                "value": 48065,
                "label": "Reggello",
                "label_ar": "ريجيلو",
                "label_fr": "Reggello"
            },
            {
                "value": 48066,
                "label": "Poggio",
                "label_ar": "بوجيو",
                "label_fr": "Poggio"
            },
            {
                "value": 48067,
                "label": "Leverano",
                "label_ar": "ليفرانو",
                "label_fr": "Leverano"
            },
            {
                "value": 48068,
                "label": "Castellana Sicula",
                "label_ar": "كاستيلانا سيكولا",
                "label_fr": "Castellana Sicula"
            },
            {
                "value": 48069,
                "label": "Gubbio",
                "label_ar": "جوبيو",
                "label_fr": "Gubbio"
            },
            {
                "value": 48070,
                "label": "Nicotera",
                "label_ar": "نيكوتيرا",
                "label_fr": "Nicotera"
            },
            {
                "value": 48071,
                "label": "Ripafratta-Farneta",
                "label_ar": "ريبافراتا فارنيتا",
                "label_fr": "Ripafratta-Farneta"
            },
            {
                "value": 48072,
                "label": "Priverno",
                "label_ar": "بريفيرنو",
                "label_fr": "Priverno"
            },
            {
                "value": 48073,
                "label": "Cittaducale",
                "label_ar": "سيتادوكالي",
                "label_fr": "Cittaducale"
            },
            {
                "value": 48074,
                "label": "Poiana Maggiore",
                "label_ar": "بويانا ماجوري",
                "label_fr": "Poiana Maggiore"
            },
            {
                "value": 48075,
                "label": "Santorso",
                "label_ar": "سانتورسو",
                "label_fr": "Santorso"
            },
            {
                "value": 48076,
                "label": "Costermano",
                "label_ar": "كوسترمانو",
                "label_fr": "Costermano"
            },
            {
                "value": 48077,
                "label": "Ampezzo",
                "label_ar": "أمبيزو",
                "label_fr": "Ampezzo"
            },
            {
                "value": 48078,
                "label": "Rivarolo Mantovano",
                "label_ar": "ريفارولو مانتوفانو",
                "label_fr": "Rivarolo Mantovano"
            },
            {
                "value": 48079,
                "label": "Cimego",
                "label_ar": "سيميجو",
                "label_fr": "Cimego"
            },
            {
                "value": 48080,
                "label": "Cavaglià",
                "label_ar": "كافاجليا",
                "label_fr": "Cavaglià"
            },
            {
                "value": 48081,
                "label": "Frascaro",
                "label_ar": "فراسكارو",
                "label_fr": "Frascaro"
            },
            {
                "value": 48082,
                "label": "Esino Lario",
                "label_ar": "اسينو لاريو",
                "label_fr": "Esino Lario"
            },
            {
                "value": 48083,
                "label": "Trescore Cremasco",
                "label_ar": "تريسكور كريماسكو",
                "label_fr": "Trescore Cremasco"
            },
            {
                "value": 48084,
                "label": "Pisogne",
                "label_ar": "بيسوجني",
                "label_fr": "Pisogne"
            },
            {
                "value": 48085,
                "label": "Arcene",
                "label_ar": "أرسين",
                "label_fr": "Arcène"
            },
            {
                "value": 48086,
                "label": "Rovetta",
                "label_ar": "روفيتا",
                "label_fr": "Rovetta"
            },
            {
                "value": 48087,
                "label": "Prata Camportaccio",
                "label_ar": "براتا كامبورتاشيو",
                "label_fr": "Prata Camportaccio"
            },
            {
                "value": 48088,
                "label": "Pizzighettone",
                "label_ar": "Pizzighettone",
                "label_fr": "Pizzighettone"
            },
            {
                "value": 48089,
                "label": "Pegognaga",
                "label_ar": "بيجوجناغا",
                "label_fr": "Pegognaga"
            },
            {
                "value": 48090,
                "label": "Villa Lagarina",
                "label_ar": "فيلا لاجارينا",
                "label_fr": "Villa Lagarina"
            },
            {
                "value": 48091,
                "label": "Lasino",
                "label_ar": "لاسينو",
                "label_fr": "Lasino"
            },
            {
                "value": 48092,
                "label": "Castelletto sopra Ticino",
                "label_ar": "كاستيليتو سوبرا تيتشينو",
                "label_fr": "Castelletto sopra Tessin"
            },
            {
                "value": 48093,
                "label": "Verolavecchia",
                "label_ar": "فيرولافيكيا",
                "label_fr": "Verolavecchia"
            },
            {
                "value": 48094,
                "label": "Airuno",
                "label_ar": "ايرونو",
                "label_fr": "Airuno"
            },
            {
                "value": 48095,
                "label": "Valle",
                "label_ar": "فالي",
                "label_fr": "Valle"
            },
            {
                "value": 48096,
                "label": "Ornago",
                "label_ar": "أورناغو",
                "label_fr": "Ornago"
            },
            {
                "value": 48097,
                "label": "Peglio",
                "label_ar": "بيجليو",
                "label_fr": "Peglio"
            },
            {
                "value": 48098,
                "label": "Rubiana",
                "label_ar": "روبيانا",
                "label_fr": "Rubiana"
            },
            {
                "value": 48099,
                "label": "Chambave",
                "label_ar": "شامباف",
                "label_fr": "Chambave"
            },
            {
                "value": 48100,
                "label": "Cherasco",
                "label_ar": "شيراسكو",
                "label_fr": "Cherasco"
            },
            {
                "value": 48101,
                "label": "Sant'Albano Stura",
                "label_ar": "سانت ألبانو ستورا",
                "label_fr": "Sant'Albano Stura"
            },
            {
                "value": 48102,
                "label": "Gorizia",
                "label_ar": "غوريزيا",
                "label_fr": "Gorizia"
            },
            {
                "value": 48103,
                "label": "Dignano",
                "label_ar": "دينيانو",
                "label_fr": "Dignano"
            },
            {
                "value": 48104,
                "label": "Musile di Piave",
                "label_ar": "موسيلي دي بيافي",
                "label_fr": "Musile di Piave"
            },
            {
                "value": 48105,
                "label": "Borgo",
                "label_ar": "بورجو",
                "label_fr": "Borgo"
            },
            {
                "value": 48106,
                "label": "Monforte d'Alba",
                "label_ar": "مونفورتي دي ألبا",
                "label_fr": "Monforte d'Alba"
            },
            {
                "value": 48107,
                "label": "Pompiano",
                "label_ar": "بومبيانو",
                "label_fr": "Pompiano"
            },
            {
                "value": 48108,
                "label": "Cavaion Veronese",
                "label_ar": "كافايون فيرونيز",
                "label_fr": "Cavaion Veronese"
            },
            {
                "value": 48109,
                "label": "Tolmezzo",
                "label_ar": "تولميزو",
                "label_fr": "Tolmezzo"
            },
            {
                "value": 48110,
                "label": "Cordovado",
                "label_ar": "كوردوفادو",
                "label_fr": "Cordovado"
            },
            {
                "value": 48111,
                "label": "Sospirolo",
                "label_ar": "سوسبيرولو",
                "label_fr": "Sospirolo"
            },
            {
                "value": 48112,
                "label": "Samarate",
                "label_ar": "ساماراتي",
                "label_fr": "Samarate"
            },
            {
                "value": 48113,
                "label": "Pandino",
                "label_ar": "باندينو",
                "label_fr": "Pandino"
            },
            {
                "value": 48114,
                "label": "Bottanuco",
                "label_ar": "بوتانوكو",
                "label_fr": "Bottanuco"
            },
            {
                "value": 48115,
                "label": "Sergnano",
                "label_ar": "سيرجنانو",
                "label_fr": "Sergnano"
            },
            {
                "value": 48116,
                "label": "Buscoldo",
                "label_ar": "بوسكولدو",
                "label_fr": "Buscoldo"
            },
            {
                "value": 48117,
                "label": "Bovezzo",
                "label_ar": "بوفيزو",
                "label_fr": "Bovezzo"
            },
            {
                "value": 48118,
                "label": "Villa Guardia",
                "label_ar": "فيلا جوارديا",
                "label_fr": "Villa Guardia"
            },
            {
                "value": 48119,
                "label": "Trivero",
                "label_ar": "تريفيرو",
                "label_fr": "Trivero"
            },
            {
                "value": 48120,
                "label": "Quinto di Treviso",
                "label_ar": "كوينتو دي تريفيزو",
                "label_fr": "Quinto di Treviso"
            },
            {
                "value": 48121,
                "label": "Brembate",
                "label_ar": "Brembate",
                "label_fr": "Brembate"
            },
            {
                "value": 48122,
                "label": "Magnago",
                "label_ar": "ماجناغو",
                "label_fr": "Magnago"
            },
            {
                "value": 48123,
                "label": "Saletto",
                "label_ar": "ساليتو",
                "label_fr": "Saletto"
            },
            {
                "value": 48124,
                "label": "Stra",
                "label_ar": "سترا",
                "label_fr": "Stra"
            },
            {
                "value": 48125,
                "label": "Pontedassio",
                "label_ar": "بونتيداسيو",
                "label_fr": "Pontedassio"
            },
            {
                "value": 48126,
                "label": "Cigliano",
                "label_ar": "سيجليانو",
                "label_fr": "Cigliano"
            },
            {
                "value": 48127,
                "label": "Ceres",
                "label_ar": "سيريس",
                "label_fr": "Cérès"
            },
            {
                "value": 48128,
                "label": "Peveragno",
                "label_ar": "بيفراغنو",
                "label_fr": "Peveragno"
            },
            {
                "value": 48129,
                "label": "Verrès",
                "label_ar": "فيريس",
                "label_fr": "Verrès"
            },
            {
                "value": 48130,
                "label": "Alimena",
                "label_ar": "اليمينا",
                "label_fr": "Alimena"
            },
            {
                "value": 48131,
                "label": "Casarsa della Delizia",
                "label_ar": "كازارسا ديلا ديليزيا",
                "label_fr": "Casarsa della Delizia"
            },
            {
                "value": 48132,
                "label": "Buia",
                "label_ar": "بويا",
                "label_fr": "Buia"
            },
            {
                "value": 48133,
                "label": "Cerete",
                "label_ar": "سيريت",
                "label_fr": "Cérete"
            },
            {
                "value": 48134,
                "label": "Monte San Vito",
                "label_ar": "مونتي سان فيتو",
                "label_fr": "Monte San Vito"
            },
            {
                "value": 48135,
                "label": "Cologna",
                "label_ar": "كولونيا",
                "label_fr": "Cologna"
            },
            {
                "value": 48136,
                "label": "Domanico",
                "label_ar": "دومانيكو",
                "label_fr": "Domanico"
            },
            {
                "value": 48137,
                "label": "Sommatino",
                "label_ar": "سوماتينو",
                "label_fr": "Sommatino"
            },
            {
                "value": 48138,
                "label": "Altavilla Silentina",
                "label_ar": "ألتافيلا سايلنتينا",
                "label_fr": "Altavilla Silentina"
            },
            {
                "value": 48139,
                "label": "Palazzolo Acreide",
                "label_ar": "بالاتسولو أكريد",
                "label_fr": "Palazzolo Acreide"
            },
            {
                "value": 48140,
                "label": "Monteodorisio",
                "label_ar": "مونتودوريسيو",
                "label_fr": "Monteodorisio"
            },
            {
                "value": 48141,
                "label": "Stornarella",
                "label_ar": "ستورناريلا",
                "label_fr": "Stornarella"
            },
            {
                "value": 48142,
                "label": "Cassine Superiore",
                "label_ar": "كاسين سوبريوري",
                "label_fr": "Cassine Superiore"
            },
            {
                "value": 48143,
                "label": "Turi",
                "label_ar": "توري",
                "label_fr": "Turi"
            },
            {
                "value": 48144,
                "label": "Ales",
                "label_ar": "أليس",
                "label_fr": "Alès"
            },
            {
                "value": 48145,
                "label": "Scilla",
                "label_ar": "سيلا",
                "label_fr": "Scilla"
            },
            {
                "value": 48146,
                "label": "Soverato Marina",
                "label_ar": "سوفيراتو مارينا",
                "label_fr": "Marina de Soverato"
            },
            {
                "value": 48147,
                "label": "Mesoraca",
                "label_ar": "ميسوراكا",
                "label_fr": "Mesoraca"
            },
            {
                "value": 48148,
                "label": "Bovalino",
                "label_ar": "بوفالينو",
                "label_fr": "Bovalino"
            },
            {
                "value": 48149,
                "label": "Marina",
                "label_ar": "مارينا",
                "label_fr": "Marina"
            },
            {
                "value": 48150,
                "label": "San Pietro a Maida",
                "label_ar": "سان بيترو مايدا",
                "label_fr": "San Pietro a Maida"
            },
            {
                "value": 48151,
                "label": "Trebisacce",
                "label_ar": "تريبيساكي",
                "label_fr": "Trebisacce"
            },
            {
                "value": 48152,
                "label": "Castelpoto",
                "label_ar": "Castelpoto",
                "label_fr": "Castelpoto"
            },
            {
                "value": 48153,
                "label": "Quarto",
                "label_ar": "كوارتو",
                "label_fr": "Quarto"
            },
            {
                "value": 48154,
                "label": "Morrone",
                "label_ar": "موروني",
                "label_fr": "Morrone"
            },
            {
                "value": 48155,
                "label": "Barra",
                "label_ar": "بارا",
                "label_fr": "Barra"
            },
            {
                "value": 48156,
                "label": "Bracigliano",
                "label_ar": "براسيجليانو",
                "label_fr": "Bracigliano"
            },
            {
                "value": 48157,
                "label": "Sgurgola",
                "label_ar": "Sgurgola",
                "label_fr": "Sgurgola"
            },
            {
                "value": 48158,
                "label": "Sessa Aurunca",
                "label_ar": "سيسا أورنكا",
                "label_fr": "Sessa Aurunca"
            },
            {
                "value": 48159,
                "label": "Escalaplano",
                "label_ar": "إسكالابلانو",
                "label_fr": "Escalaplano"
            },
            {
                "value": 48160,
                "label": "Codaruina",
                "label_ar": "كوداروينا",
                "label_fr": "Codaruina"
            },
            {
                "value": 48161,
                "label": "Orvieto",
                "label_ar": "أورفيتو",
                "label_fr": "Orvieto"
            },
            {
                "value": 48162,
                "label": "Russi",
                "label_ar": "روسي",
                "label_fr": "Russi"
            },
            {
                "value": 48163,
                "label": "Vitulazio",
                "label_ar": "فيتولازيو",
                "label_fr": "Vitulazio"
            },
            {
                "value": 48164,
                "label": "Salice Salentino",
                "label_ar": "ساليس سالينتينو",
                "label_fr": "Salice Salentino"
            },
            {
                "value": 48165,
                "label": "Zollino",
                "label_ar": "زولينو",
                "label_fr": "Zollino"
            },
            {
                "value": 48166,
                "label": "Puglianello",
                "label_ar": "بوجليانيلو",
                "label_fr": "Puglianello"
            },
            {
                "value": 48167,
                "label": "Ailano",
                "label_ar": "ايلانو",
                "label_fr": "Ailano"
            },
            {
                "value": 48168,
                "label": "Arienzo",
                "label_ar": "أرينزو",
                "label_fr": "Arienzo"
            },
            {
                "value": 48169,
                "label": "Medolla",
                "label_ar": "ميدولا",
                "label_fr": "Medolla"
            },
            {
                "value": 48170,
                "label": "Guardavalle",
                "label_ar": "غواردافال",
                "label_fr": "Guardavalle"
            },
            {
                "value": 48171,
                "label": "Bientina",
                "label_ar": "بينتينا",
                "label_fr": "Bientina"
            },
            {
                "value": 48172,
                "label": "San Gavino Monreale",
                "label_ar": "سان جافينو مونريالي",
                "label_fr": "San Gavino Monreale"
            },
            {
                "value": 48173,
                "label": "Baunei",
                "label_ar": "باوني",
                "label_fr": "Baunei"
            },
            {
                "value": 48174,
                "label": "Ozieri",
                "label_ar": "Ozieri",
                "label_fr": "Ozieri"
            },
            {
                "value": 48175,
                "label": "Erchie",
                "label_ar": "ارشي",
                "label_fr": "Erchie"
            },
            {
                "value": 48176,
                "label": "Muro Leccese",
                "label_ar": "مورو ليتشيز",
                "label_fr": "Muro Leccese"
            },
            {
                "value": 48177,
                "label": "Gimigliano",
                "label_ar": "جيميجليانو",
                "label_fr": "Gimigliano"
            },
            {
                "value": 48178,
                "label": "Chiesina Uzzanese",
                "label_ar": "تشيسينا أوزانيز",
                "label_fr": "Chiesina Uzzanese"
            },
            {
                "value": 48179,
                "label": "Santa Caterina dello Ionio",
                "label_ar": "سانتا كاترينا ديلو إيونيو",
                "label_fr": "Santa Caterina dello Ionio"
            },
            {
                "value": 48180,
                "label": "Morcone",
                "label_ar": "موركون",
                "label_fr": "Morcone"
            },
            {
                "value": 48181,
                "label": "Buccino",
                "label_ar": "بوتشينو",
                "label_fr": "Buccino"
            },
            {
                "value": 48182,
                "label": "Norcia",
                "label_ar": "نورسيا",
                "label_fr": "Norcia"
            },
            {
                "value": 48183,
                "label": "Narni Scalo",
                "label_ar": "نارني سكالو",
                "label_fr": "Narni Scalo"
            },
            {
                "value": 48184,
                "label": "Spello",
                "label_ar": "سبيلو",
                "label_fr": "Spello"
            },
            {
                "value": 48185,
                "label": "Ceccano",
                "label_ar": "سيسكانو",
                "label_fr": "Ceccano"
            },
            {
                "value": 48186,
                "label": "Artena",
                "label_ar": "ارتينا",
                "label_fr": "Artena"
            },
            {
                "value": 48187,
                "label": "Rocca Massima",
                "label_ar": "روكا ماسيما",
                "label_fr": "Rocca Massima"
            },
            {
                "value": 48188,
                "label": "Muros",
                "label_ar": "موروس",
                "label_fr": "Muros"
            },
            {
                "value": 48189,
                "label": "Reggiolo",
                "label_ar": "ريجيولو",
                "label_fr": "Reggiolo"
            },
            {
                "value": 48190,
                "label": "Roccastrada",
                "label_ar": "روكاسترادا",
                "label_fr": "Roccastrada"
            },
            {
                "value": 48191,
                "label": "Romentino",
                "label_ar": "رومنتينو",
                "label_fr": "Romentino"
            },
            {
                "value": 48192,
                "label": "Zumaglia",
                "label_ar": "زوماجليا",
                "label_fr": "Zumaglia"
            },
            {
                "value": 48193,
                "label": "Ornavasso",
                "label_ar": "أورنافاسو",
                "label_fr": "Ornavasso"
            },
            {
                "value": 48194,
                "label": "Cressa",
                "label_ar": "كريسا",
                "label_fr": "Cressa"
            },
            {
                "value": 48195,
                "label": "Buronzo",
                "label_ar": "بورونزو",
                "label_fr": "Buronzo"
            },
            {
                "value": 48196,
                "label": "Creazzo",
                "label_ar": "كرياتسو",
                "label_fr": "Creazzo"
            },
            {
                "value": 48197,
                "label": "Villafranca in Lunigiana",
                "label_ar": "فيلافرانكا في لونيجيانا",
                "label_fr": "Villafranca en Lunigiana"
            },
            {
                "value": 48198,
                "label": "Suardi",
                "label_ar": "Suardi",
                "label_fr": "Suardi"
            },
            {
                "value": 48199,
                "label": "San Giovanni Bianco",
                "label_ar": "سان جيوفاني بيانكو",
                "label_fr": "San Giovanni Bianco"
            },
            {
                "value": 48200,
                "label": "Sermide",
                "label_ar": "Sermide",
                "label_fr": "Sermide"
            },
            {
                "value": 48201,
                "label": "Sirmione",
                "label_ar": "سيرميوني",
                "label_fr": "Sirmione"
            },
            {
                "value": 48202,
                "label": "Welsberg-Taisten",
                "label_ar": "ويلسبيرج تايستين",
                "label_fr": "Welsberg-Taisten"
            },
            {
                "value": 48203,
                "label": "Coseano",
                "label_ar": "كوسيانو",
                "label_fr": "Coseano"
            },
            {
                "value": 48204,
                "label": "Menaggio",
                "label_ar": "ميناجيو",
                "label_fr": "Menaggio"
            },
            {
                "value": 48205,
                "label": "Casargo",
                "label_ar": "كاسارجو",
                "label_fr": "Casargo"
            },
            {
                "value": 48206,
                "label": "Carasco",
                "label_ar": "كاراسكو",
                "label_fr": "Carasco"
            },
            {
                "value": 48207,
                "label": "Caravino",
                "label_ar": "كارافينو",
                "label_fr": "Caravino"
            },
            {
                "value": 48208,
                "label": "Rivalba",
                "label_ar": "ريفالبا",
                "label_fr": "Rivalba"
            },
            {
                "value": 48209,
                "label": "Villafranca di Forli",
                "label_ar": "فيلافرانكا دي فورلي",
                "label_fr": "Villafranca di Forli"
            },
            {
                "value": 48210,
                "label": "Crespino",
                "label_ar": "كريسبينو",
                "label_fr": "Crespino"
            },
            {
                "value": 48211,
                "label": "San Biagio di Callalta",
                "label_ar": "سان بياجيو دي كالالتا",
                "label_fr": "San Biagio di Callalta"
            },
            {
                "value": 48212,
                "label": "Celle Ligure",
                "label_ar": "سيل ليغور",
                "label_fr": "Celle Ligure"
            },
            {
                "value": 48213,
                "label": "Carignano",
                "label_ar": "Carignano",
                "label_fr": "Carignano"
            },
            {
                "value": 48214,
                "label": "Avigliana",
                "label_ar": "أفيجليانا",
                "label_fr": "Avigliana"
            },
            {
                "value": 48215,
                "label": "Leinì",
                "label_ar": "لينو",
                "label_fr": "Leinì"
            },
            {
                "value": 48216,
                "label": "Bagnara Calabra",
                "label_ar": "باجنارا كالابرا",
                "label_fr": "Bagnara Calabra"
            },
            {
                "value": 48217,
                "label": "Manzano",
                "label_ar": "مانزانو",
                "label_fr": "Manzano"
            },
            {
                "value": 48218,
                "label": "Torri del Benaco",
                "label_ar": "توري ديل بيناكو",
                "label_fr": "Torri del Benaco"
            },
            {
                "value": 48219,
                "label": "Verucchio",
                "label_ar": "فيروكيو",
                "label_fr": "Verucchio"
            },
            {
                "value": 48220,
                "label": "Giavera del Montello",
                "label_ar": "جيافيرا ديل مونتيلو",
                "label_fr": "Giavera del Montello"
            },
            {
                "value": 48221,
                "label": "Santa Maria di Sala",
                "label_ar": "سانتا ماريا دي سالا",
                "label_fr": "Santa Maria di Sala"
            },
            {
                "value": 48222,
                "label": "Izano",
                "label_ar": "ايزانو",
                "label_fr": "Izano"
            },
            {
                "value": 48223,
                "label": "Amandola",
                "label_ar": "اماندولا",
                "label_fr": "Amandola"
            },
            {
                "value": 48224,
                "label": "San Giorgio di Nogaro",
                "label_ar": "سان جورجيو دي نوجارو",
                "label_fr": "San Giorgio di Nogaro"
            },
            {
                "value": 48225,
                "label": "Gaiarine",
                "label_ar": "غايارين",
                "label_fr": "Gaiarine"
            },
            {
                "value": 48226,
                "label": "Capistrano",
                "label_ar": "كابيسترانو",
                "label_fr": "Capistrano"
            },
            {
                "value": 48227,
                "label": "San Cataldo",
                "label_ar": "سان كاتالدو",
                "label_fr": "San Cataldo"
            },
            {
                "value": 48228,
                "label": "Salvirola",
                "label_ar": "سالفيرولا",
                "label_fr": "Salvirola"
            },
            {
                "value": 48229,
                "label": "Brignano",
                "label_ar": "برينيانو",
                "label_fr": "Brignano"
            },
            {
                "value": 48230,
                "label": "Ponte",
                "label_ar": "بونتي",
                "label_fr": "Ponte"
            },
            {
                "value": 48231,
                "label": "Borno",
                "label_ar": "بورنو",
                "label_fr": "Borno"
            },
            {
                "value": 48232,
                "label": "Passirano",
                "label_ar": "باسيرانو",
                "label_fr": "Passirano"
            },
            {
                "value": 48233,
                "label": "Dimaro",
                "label_ar": "ديمارو",
                "label_fr": "Dimaro"
            },
            {
                "value": 48234,
                "label": "Livinallongo del Col di Lana",
                "label_ar": "Livinallongo del Col di Lana",
                "label_fr": "Livinallongo del Col di Lana"
            },
            {
                "value": 48235,
                "label": "Arsiè",
                "label_ar": "Arsiè",
                "label_fr": "Arsiè"
            },
            {
                "value": 48236,
                "label": "Subiaco",
                "label_ar": "سوبياكو",
                "label_fr": "Subiaco"
            },
            {
                "value": 48237,
                "label": "Borzonasca",
                "label_ar": "بورزوناسكا",
                "label_fr": "Borzonasca"
            },
            {
                "value": 48238,
                "label": "Castello",
                "label_ar": "كاستيلو",
                "label_fr": "Castello"
            },
            {
                "value": 48239,
                "label": "Cavenago di Brianza",
                "label_ar": "كافيناغو دي بريانزا",
                "label_fr": "Cavenago di Brianza"
            },
            {
                "value": 48240,
                "label": "Zerbo",
                "label_ar": "زيربو",
                "label_fr": "Zerbo"
            },
            {
                "value": 48241,
                "label": "Gavi",
                "label_ar": "جافي",
                "label_fr": "Gavi"
            },
            {
                "value": 48242,
                "label": "Laveno-Mombello",
                "label_ar": "لافينو مومبيلو",
                "label_fr": "Laveno-Mombello"
            },
            {
                "value": 48243,
                "label": "Domegliara",
                "label_ar": "دوميجليارا",
                "label_fr": "Domegliara"
            },
            {
                "value": 48244,
                "label": "Grumolo delle Abbadesse",
                "label_ar": "Grumolo delle Abbadesse",
                "label_fr": "Grumolo delle Abbadesse"
            },
            {
                "value": 48245,
                "label": "Paderno d'Adda",
                "label_ar": "باديرنو دادا",
                "label_fr": "Paderno d'Adda"
            },
            {
                "value": 48246,
                "label": "Vahrn",
                "label_ar": "فاهرن",
                "label_fr": "Vahrn"
            },
            {
                "value": 48247,
                "label": "Margarita",
                "label_ar": "مارجريتا",
                "label_fr": "Margarita"
            },
            {
                "value": 48248,
                "label": "Badia Calavena",
                "label_ar": "باديا كالافينا",
                "label_fr": "Badia Calavena"
            },
            {
                "value": 48249,
                "label": "Stretti",
                "label_ar": "Stretti",
                "label_fr": "Stretti"
            },
            {
                "value": 48250,
                "label": "Castellana Grotte",
                "label_ar": "كاستيلانا جروت",
                "label_fr": "Castellana Grotte"
            },
            {
                "value": 48251,
                "label": "Rivello",
                "label_ar": "ريفيلو",
                "label_fr": "Rivello"
            },
            {
                "value": 48252,
                "label": "Altavilla Milicia",
                "label_ar": "ألتافيلا ميليسيا",
                "label_fr": "Altavilla Milicia"
            },
            {
                "value": 48253,
                "label": "Cascia",
                "label_ar": "كاسيا",
                "label_fr": "Cascia"
            },
            {
                "value": 48254,
                "label": "Roccaromana",
                "label_ar": "روكارومانا",
                "label_fr": "Roccaromana"
            },
            {
                "value": 48255,
                "label": "Zangarona",
                "label_ar": "زنجرونا",
                "label_fr": "Zangarona"
            },
            {
                "value": 48256,
                "label": "Boretto",
                "label_ar": "بوريتو",
                "label_fr": "Boretto"
            },
            {
                "value": 48257,
                "label": "Langhirano",
                "label_ar": "لانغيرانو",
                "label_fr": "Langhirano"
            },
            {
                "value": 48258,
                "label": "Ferriere",
                "label_ar": "فيرير",
                "label_fr": "Ferrière"
            },
            {
                "value": 48259,
                "label": "Piandimeleto",
                "label_ar": "Piandimeleto",
                "label_fr": "Piandimeleto"
            },
            {
                "value": 48260,
                "label": "Monte San Giusto",
                "label_ar": "مونتي سان جوستو",
                "label_fr": "Monte San Giusto"
            },
            {
                "value": 48261,
                "label": "Sorgono",
                "label_ar": "سورجونو",
                "label_fr": "Sorgono"
            },
            {
                "value": 48262,
                "label": "Pietraperzia",
                "label_ar": "بيترابرسيا",
                "label_fr": "Pietraperzia"
            },
            {
                "value": 48263,
                "label": "Zambrone",
                "label_ar": "زامبروني",
                "label_fr": "Zambrone"
            },
            {
                "value": 48264,
                "label": "Vernio",
                "label_ar": "فيرنيو",
                "label_fr": "Vernio"
            },
            {
                "value": 48265,
                "label": "Chiusano di San Domenico",
                "label_ar": "Chiusano di San Domenico",
                "label_fr": "Chiusano di San Domenico"
            },
            {
                "value": 48266,
                "label": "Tagliacozzo",
                "label_ar": "تاجلياكوزو",
                "label_fr": "Tagliacozzo"
            },
            {
                "value": 48267,
                "label": "Palagonia",
                "label_ar": "بالاجونيا",
                "label_fr": "Palagonie"
            },
            {
                "value": 48268,
                "label": "Mondragone",
                "label_ar": "موندراجون",
                "label_fr": "Mondragone"
            },
            {
                "value": 48269,
                "label": "Ponte Buggianese",
                "label_ar": "بونتي بوجيانيس",
                "label_fr": "Ponte Buggianese"
            },
            {
                "value": 48270,
                "label": "Poggiardo",
                "label_ar": "بوجياردو",
                "label_fr": "Poggiardo"
            },
            {
                "value": 48271,
                "label": "Isola del Giglio",
                "label_ar": "ايزولا ديل جيليو",
                "label_fr": "Isola del Giglio"
            },
            {
                "value": 48272,
                "label": "Volterra",
                "label_ar": "فولتيرا",
                "label_fr": "Volterra"
            },
            {
                "value": 48273,
                "label": "Taurianova",
                "label_ar": "توريانوفا",
                "label_fr": "Taurianova"
            },
            {
                "value": 48274,
                "label": "Africo Nuovo",
                "label_ar": "أفريكو نوفو",
                "label_fr": "Africo Nuovo"
            },
            {
                "value": 48275,
                "label": "Porto",
                "label_ar": "بورتو",
                "label_fr": "Porto"
            },
            {
                "value": 48276,
                "label": "Cugnoli",
                "label_ar": "كوجنولي",
                "label_fr": "Cugnoli"
            },
            {
                "value": 48277,
                "label": "Gissi",
                "label_ar": "جيسي",
                "label_fr": "Gissi"
            },
            {
                "value": 48278,
                "label": "Villafranca Tirrena",
                "label_ar": "فيلافرانكا تيرينا",
                "label_fr": "Villafranca Tirrena"
            },
            {
                "value": 48279,
                "label": "Francofonte",
                "label_ar": "فرانكوفونتي",
                "label_fr": "Francofonte"
            },
            {
                "value": 48280,
                "label": "Serra Pedace",
                "label_ar": "سيرا بيداس",
                "label_fr": "Serra Pedace"
            },
            {
                "value": 48281,
                "label": "San Gennaro Vesuviano",
                "label_ar": "سان جينارو فيزوفيانو",
                "label_fr": "San Gennaro Vesuviano"
            },
            {
                "value": 48282,
                "label": "Serre",
                "label_ar": "سيري",
                "label_fr": "Serre"
            },
            {
                "value": 48283,
                "label": "Picerno",
                "label_ar": "بيكيرنو",
                "label_fr": "Picerno"
            },
            {
                "value": 48284,
                "label": "Frasso Telesino",
                "label_ar": "فراسو تيليسينو",
                "label_fr": "Frasso Telesino"
            },
            {
                "value": 48285,
                "label": "Concordia sulla Secchia",
                "label_ar": "كونكورديا سولا سيكيا",
                "label_fr": "Concordia sulla Secchia"
            },
            {
                "value": 48286,
                "label": "Vacri",
                "label_ar": "فاكري",
                "label_fr": "Vacri"
            },
            {
                "value": 48287,
                "label": "Tortoreto",
                "label_ar": "تورتوريتو",
                "label_fr": "Tortoreto"
            },
            {
                "value": 48288,
                "label": "Buggiano",
                "label_ar": "بوجيانو",
                "label_fr": "Buggiano"
            },
            {
                "value": 48289,
                "label": "Pignataro Interamna",
                "label_ar": "Pignataro Interamna",
                "label_fr": "Pignataro Interamna"
            },
            {
                "value": 48290,
                "label": "Santi Cosma e Damiano",
                "label_ar": "سانتي كوزما وداميانو",
                "label_fr": "Santi Cosma e Damiano"
            },
            {
                "value": 48291,
                "label": "Tuglie",
                "label_ar": "توجلي",
                "label_fr": "Tuglie"
            },
            {
                "value": 48292,
                "label": "Zanca-Sant'Andrea",
                "label_ar": "زانكا سانت أندريا",
                "label_fr": "Zanca-Sant'Andrea"
            },
            {
                "value": 48293,
                "label": "Sulzano",
                "label_ar": "سولزانو",
                "label_fr": "Sulzano"
            },
            {
                "value": 48294,
                "label": "Bruino",
                "label_ar": "بروينو",
                "label_fr": "Bruino"
            },
            {
                "value": 48295,
                "label": "Marone",
                "label_ar": "ماروني",
                "label_fr": "Marone"
            },
            {
                "value": 48296,
                "label": "Brusnengo",
                "label_ar": "Brusnengo",
                "label_fr": "Brusnengo"
            },
            {
                "value": 48297,
                "label": "San Benigno Canavese",
                "label_ar": "سان بينينو كانافيزي",
                "label_fr": "San Benigno Canavese"
            },
            {
                "value": 48298,
                "label": "Origgio",
                "label_ar": "اوريجيو",
                "label_fr": "Origgio"
            },
            {
                "value": 48299,
                "label": "Marignano",
                "label_ar": "مارينانو",
                "label_fr": "Marignano"
            },
            {
                "value": 48300,
                "label": "Selva",
                "label_ar": "سيلفا",
                "label_fr": "Selva"
            },
            {
                "value": 48301,
                "label": "Bastiglia",
                "label_ar": "باستيجليا",
                "label_fr": "Bastiglia"
            },
            {
                "value": 48302,
                "label": "Ravarino",
                "label_ar": "رافارينو",
                "label_fr": "Ravarino"
            },
            {
                "value": 48303,
                "label": "Tirrenia",
                "label_ar": "تيرينيا",
                "label_fr": "Tirrenia"
            },
            {
                "value": 48304,
                "label": "Palmas Arborea",
                "label_ar": "بالماس أربوريا",
                "label_fr": "Palmas Arborea"
            },
            {
                "value": 48305,
                "label": "Colliano",
                "label_ar": "كوليانو",
                "label_fr": "Colliano"
            },
            {
                "value": 48306,
                "label": "Cavallino",
                "label_ar": "كافالينو",
                "label_fr": "Cavallino"
            },
            {
                "value": 48307,
                "label": "San Piero Patti",
                "label_ar": "سان بييرو باتي",
                "label_fr": "San Piero Patti"
            },
            {
                "value": 48308,
                "label": "Casamarciano",
                "label_ar": "Casamarciano",
                "label_fr": "Casamarciano"
            },
            {
                "value": 48309,
                "label": "Montefredane",
                "label_ar": "مونتيفريدان",
                "label_fr": "Montefredane"
            },
            {
                "value": 48310,
                "label": "Manocalzati",
                "label_ar": "مانوكالزاتي",
                "label_fr": "Manocalzati"
            },
            {
                "value": 48311,
                "label": "Sortino",
                "label_ar": "سورتينو",
                "label_fr": "Sortino"
            },
            {
                "value": 48312,
                "label": "Golfo Aranci",
                "label_ar": "جولفو أرانشي",
                "label_fr": "Golfo Aranci"
            },
            {
                "value": 48313,
                "label": "Serramezzana",
                "label_ar": "سيرامزانا",
                "label_fr": "Serramezzana"
            },
            {
                "value": 48314,
                "label": "Airola",
                "label_ar": "ايرولا",
                "label_fr": "Airola"
            },
            {
                "value": 48315,
                "label": "Roccabernarda",
                "label_ar": "روكابيرناردا",
                "label_fr": "Roccabernarda"
            },
            {
                "value": 48316,
                "label": "Filadelfia",
                "label_ar": "Filadelfia",
                "label_fr": "Filadelfia"
            },
            {
                "value": 48317,
                "label": "Soveria Mannelli",
                "label_ar": "سوفريا مانيلي",
                "label_fr": "Soveria Mannelli"
            },
            {
                "value": 48318,
                "label": "Caldarola",
                "label_ar": "كالدارولا",
                "label_fr": "Caldarola"
            },
            {
                "value": 48319,
                "label": "Vizzini",
                "label_ar": "فيزيني",
                "label_fr": "Vizzini"
            },
            {
                "value": 48320,
                "label": "Ponte a Moriano",
                "label_ar": "بونتي موريانو",
                "label_fr": "Ponte a Moriano"
            },
            {
                "value": 48321,
                "label": "Santa Fiora",
                "label_ar": "سانتا فيورا",
                "label_fr": "Santa Fiora"
            },
            {
                "value": 48322,
                "label": "Podenzana",
                "label_ar": "بودينزانا",
                "label_fr": "Podenzana"
            },
            {
                "value": 48323,
                "label": "Santo Stefano d'Aveto",
                "label_ar": "سانتو ستيفانو دافيتو",
                "label_fr": "Santo Stefano d'Aveto"
            },
            {
                "value": 48324,
                "label": "San Colombano Certénoli",
                "label_ar": "سان كولومبانو سيرتينولي",
                "label_fr": "San Colombano Certénoli"
            },
            {
                "value": 48325,
                "label": "Moneglia",
                "label_ar": "مونيليا",
                "label_fr": "Moneglia"
            },
            {
                "value": 48326,
                "label": "Mignanego",
                "label_ar": "Mignanego",
                "label_fr": "Mignanego"
            },
            {
                "value": 48327,
                "label": "Campofilone",
                "label_ar": "كامبوفيلون",
                "label_fr": "Campofilone"
            },
            {
                "value": 48328,
                "label": "Petritoli",
                "label_ar": "بيتريتولي",
                "label_fr": "Petritoli"
            },
            {
                "value": 48329,
                "label": "Colle",
                "label_ar": "كولي",
                "label_fr": "Colle"
            },
            {
                "value": 48330,
                "label": "Mantovana",
                "label_ar": "مانتوفانا",
                "label_fr": "Mantovana"
            },
            {
                "value": 48331,
                "label": "Montanara",
                "label_ar": "مونتانارا",
                "label_fr": "Montanara"
            },
            {
                "value": 48332,
                "label": "Alcara li Fusi",
                "label_ar": "الكارا لي فوزي",
                "label_fr": "Alcara li Fusi"
            },
            {
                "value": 48333,
                "label": "Seminara",
                "label_ar": "ندوة",
                "label_fr": "Seminara"
            },
            {
                "value": 48334,
                "label": "Moglia",
                "label_ar": "موغليا",
                "label_fr": "Moglia"
            },
            {
                "value": 48335,
                "label": "Acquafredda",
                "label_ar": "أكوافريددا",
                "label_fr": "Acquafredda"
            },
            {
                "value": 48336,
                "label": "Gualtieri",
                "label_ar": "جوالتيري",
                "label_fr": "Gualtieri"
            },
            {
                "value": 48337,
                "label": "Gottolengo",
                "label_ar": "جوتولينجو",
                "label_fr": "Gottolengo"
            },
            {
                "value": 48338,
                "label": "Orzinuovi",
                "label_ar": "أورزينوفي",
                "label_fr": "Orzinuovi"
            },
            {
                "value": 48339,
                "label": "Bronzolo",
                "label_ar": "برونزولو",
                "label_fr": "Bronzolo"
            },
            {
                "value": 48340,
                "label": "Aldeno",
                "label_ar": "الدينو",
                "label_fr": "Aldeno"
            },
            {
                "value": 48341,
                "label": "Dro",
                "label_ar": "درو",
                "label_fr": "Dro"
            },
            {
                "value": 48342,
                "label": "Bosisio Parini",
                "label_ar": "Bosisio Parini",
                "label_fr": "Bosisio Parini"
            },
            {
                "value": 48343,
                "label": "Casaloldo",
                "label_ar": "كاسالولدو",
                "label_fr": "Casaloldo"
            },
            {
                "value": 48344,
                "label": "Rivarolo del Re ed Uniti",
                "label_ar": "Rivarolo del Re ed Uniti",
                "label_fr": "Rivarolo del Re ed Uniti"
            },
            {
                "value": 48345,
                "label": "Mesola",
                "label_ar": "ميسولا",
                "label_fr": "Mesola"
            },
            {
                "value": 48346,
                "label": "Piano di Coreglia-Ghivizzano",
                "label_ar": "بيانو دي كوريجليا جيفيزانو",
                "label_fr": "Piano di Coreglia-Ghivizzano"
            },
            {
                "value": 48347,
                "label": "Viserba",
                "label_ar": "فيسربا",
                "label_fr": "Viserba"
            },
            {
                "value": 48348,
                "label": "Robecco sul Naviglio",
                "label_ar": "Robecco sul Naviglio",
                "label_fr": "Robecco sul Naviglio"
            },
            {
                "value": 48349,
                "label": "Marsciano",
                "label_ar": "مارسيانو",
                "label_fr": "Marsciano"
            },
            {
                "value": 48350,
                "label": "Nanto",
                "label_ar": "نانتو",
                "label_fr": "Nanto"
            },
            {
                "value": 48351,
                "label": "Chiusanico",
                "label_ar": "شيوسانيكو",
                "label_fr": "Chiusanico"
            },
            {
                "value": 48352,
                "label": "Feroleto della Chiesa",
                "label_ar": "فيروليتو ديلا كييزا",
                "label_fr": "Feroleto della Chiesa"
            },
            {
                "value": 48353,
                "label": "Valdieri",
                "label_ar": "فالديري",
                "label_fr": "Valdieri"
            },
            {
                "value": 48354,
                "label": "Montalenghe",
                "label_ar": "مونتالينجي",
                "label_fr": "Montalenghe"
            },
            {
                "value": 48355,
                "label": "Cintano",
                "label_ar": "سينتانو",
                "label_fr": "Cintano"
            },
            {
                "value": 48356,
                "label": "Costigliole d'Asti",
                "label_ar": "Costigliole d'Asti",
                "label_fr": "Costigliole d'Asti"
            },
            {
                "value": 48357,
                "label": "Pessione",
                "label_ar": "بيسوني",
                "label_fr": "Pessione"
            },
            {
                "value": 48358,
                "label": "Casalmaggiore",
                "label_ar": "Casalmaggiore",
                "label_fr": "Casalmaggiore"
            },
            {
                "value": 48359,
                "label": "Nimis",
                "label_ar": "نيمس",
                "label_fr": "Nimis"
            },
            {
                "value": 48360,
                "label": "Lizzola",
                "label_ar": "ليزولا",
                "label_fr": "Lizzola"
            },
            {
                "value": 48361,
                "label": "Piadena",
                "label_ar": "بيادينا",
                "label_fr": "Piadena"
            },
            {
                "value": 48362,
                "label": "Rodeneck-Rodengo",
                "label_ar": "Rodeneck-Rodengo",
                "label_fr": "Rodeneck-Rodengo"
            },
            {
                "value": 48363,
                "label": "Auronzo di Cadore",
                "label_ar": "Auronzo di Cadore",
                "label_fr": "Auronzo di Cadore"
            },
            {
                "value": 48364,
                "label": "Santa Lucia",
                "label_ar": "سانتا لوسيا",
                "label_fr": "Santa Lucia"
            },
            {
                "value": 48365,
                "label": "Tarquinia",
                "label_ar": "تاركوينيا",
                "label_fr": "Tarquinia"
            },
            {
                "value": 48366,
                "label": "Terrasini",
                "label_ar": "تيراسيني",
                "label_fr": "Terrasini"
            },
            {
                "value": 48367,
                "label": "Castelraimondo",
                "label_ar": "كاستيلرايموندو",
                "label_fr": "Castelraimondo"
            },
            {
                "value": 48368,
                "label": "Pontida",
                "label_ar": "بونتيدا",
                "label_fr": "Pontida"
            },
            {
                "value": 48369,
                "label": "Fontanella",
                "label_ar": "فونتانيلا",
                "label_fr": "Fontanella"
            },
            {
                "value": 48370,
                "label": "Carenno",
                "label_ar": "كارينو",
                "label_fr": "Carenno"
            },
            {
                "value": 48371,
                "label": "Casalromano",
                "label_ar": "كاسالرومانو",
                "label_fr": "Casalromano"
            },
            {
                "value": 48372,
                "label": "Poggio Rusco",
                "label_ar": "بوجيو روسكو",
                "label_fr": "Poggio Rusco"
            },
            {
                "value": 48373,
                "label": "San Vito di Cadore",
                "label_ar": "سان فيتو دي كادوري",
                "label_fr": "San Vito di Cadore"
            },
            {
                "value": 48374,
                "label": "Cassolnovo",
                "label_ar": "كاسولنوفو",
                "label_fr": "Cassolnovo"
            },
            {
                "value": 48375,
                "label": "Casorate Primo",
                "label_ar": "كاسوراتي بريمو",
                "label_fr": "Casorate Primo"
            },
            {
                "value": 48376,
                "label": "Robbio",
                "label_ar": "روبيو",
                "label_fr": "Robbio"
            },
            {
                "value": 48377,
                "label": "Viagrande",
                "label_ar": "Viagrande",
                "label_fr": "Viagrande"
            },
            {
                "value": 48378,
                "label": "Torrebelvicino",
                "label_ar": "توريبيلفيتشينو",
                "label_fr": "Torrebelvicino"
            },
            {
                "value": 48379,
                "label": "San Nazario",
                "label_ar": "سان نازاريو",
                "label_fr": "San Nazario"
            },
            {
                "value": 48380,
                "label": "Salzano",
                "label_ar": "سالزانو",
                "label_fr": "Salzano"
            },
            {
                "value": 48381,
                "label": "Vellezzo Bellini",
                "label_ar": "فيليتسو بيليني",
                "label_fr": "Vellezzo Bellini"
            },
            {
                "value": 48382,
                "label": "Bressana Bottarone",
                "label_ar": "بريسانا بوتاروني",
                "label_fr": "Bressana Bottarone"
            },
            {
                "value": 48383,
                "label": "La Morra",
                "label_ar": "لا مورا",
                "label_fr": "La Morra"
            },
            {
                "value": 48384,
                "label": "Bollengo",
                "label_ar": "بولينجو",
                "label_fr": "Bollengo"
            },
            {
                "value": 48385,
                "label": "Badia Polesine",
                "label_ar": "بادية بوليسين",
                "label_fr": "Badia Polesine"
            },
            {
                "value": 48386,
                "label": "Mereto di Tomba",
                "label_ar": "ميريتو دي تومبا",
                "label_fr": "Mereto di Tomba"
            },
            {
                "value": 48387,
                "label": "Campoformido",
                "label_ar": "كامبوفورميدو",
                "label_fr": "Campoformido"
            },
            {
                "value": 48388,
                "label": "Cagliari",
                "label_ar": "كالياري",
                "label_fr": "Cagliari"
            },
            {
                "value": 48389,
                "label": "Inveruno",
                "label_ar": "إينفيرونو",
                "label_fr": "Inveruno"
            },
            {
                "value": 48390,
                "label": "Berceto",
                "label_ar": "بيرسيتو",
                "label_fr": "Berceto"
            },
            {
                "value": 48391,
                "label": "Guiglia",
                "label_ar": "Guiglia",
                "label_fr": "Guiglia"
            },
            {
                "value": 48392,
                "label": "Roncalceci",
                "label_ar": "رونكالسي",
                "label_fr": "Roncalceci"
            },
            {
                "value": 48393,
                "label": "Barano d'Ischia",
                "label_ar": "بارانو دي ايشيا",
                "label_fr": "Barano d'Ischia"
            },
            {
                "value": 48394,
                "label": "San Felice sul Panaro",
                "label_ar": "سان فيليس سول بانارو",
                "label_fr": "San Felice sul Panaro"
            },
            {
                "value": 48395,
                "label": "Ferrara",
                "label_ar": "فيرارا",
                "label_fr": "Ferrare"
            },
            {
                "value": 48396,
                "label": "Galliera",
                "label_ar": "جاليرا",
                "label_fr": "Galliera"
            },
            {
                "value": 48397,
                "label": "Vigarano Pieve",
                "label_ar": "فيجارانو بيفي",
                "label_fr": "Vigarano Pieve"
            },
            {
                "value": 48398,
                "label": "Bari Sardo",
                "label_ar": "باري ساردو",
                "label_fr": "Bari Sardo"
            },
            {
                "value": 48399,
                "label": "Corigliano d'Otranto",
                "label_ar": "كوريجليانو دي أوترانتو",
                "label_fr": "Corigliano d'Otranto"
            },
            {
                "value": 48400,
                "label": "Francavilla Fontana",
                "label_ar": "فرانكافيلا فونتانا",
                "label_fr": "Francavilla Fontana"
            },
            {
                "value": 48401,
                "label": "Riesi",
                "label_ar": "ريسي",
                "label_fr": "Riesi"
            },
            {
                "value": 48402,
                "label": "Chiaravalle Centrale",
                "label_ar": "Chiaravalle Centrale",
                "label_fr": "Chiaravalle Centrale"
            },
            {
                "value": 48403,
                "label": "Esperia",
                "label_ar": "اسبيريا",
                "label_fr": "Esperia"
            },
            {
                "value": 48404,
                "label": "Partanna",
                "label_ar": "بارتانا",
                "label_fr": "Partanna"
            },
            {
                "value": 48405,
                "label": "Capistrello",
                "label_ar": "كابستريللو",
                "label_fr": "Capistrello"
            },
            {
                "value": 48406,
                "label": "San Giustino",
                "label_ar": "سان جوستينو",
                "label_fr": "San Giustino"
            },
            {
                "value": 48407,
                "label": "Giove",
                "label_ar": "جيوف",
                "label_fr": "Giove"
            },
            {
                "value": 48408,
                "label": "Montefranco",
                "label_ar": "مونتفرانكو",
                "label_fr": "Montefranco"
            },
            {
                "value": 48409,
                "label": "Alatri",
                "label_ar": "الاتري",
                "label_fr": "Alatri"
            },
            {
                "value": 48410,
                "label": "Norma",
                "label_ar": "نورما",
                "label_fr": "Norma"
            },
            {
                "value": 48411,
                "label": "San Felice Circeo",
                "label_ar": "سان فيليس سيرسيو",
                "label_fr": "San Felice Circeo"
            },
            {
                "value": 48412,
                "label": "Ugento",
                "label_ar": "يوجينتو",
                "label_fr": "Ugento"
            },
            {
                "value": 48413,
                "label": "Caggiano",
                "label_ar": "كاجيانو",
                "label_fr": "Caggiano"
            },
            {
                "value": 48414,
                "label": "Roccamonfina",
                "label_ar": "روكامونفينا",
                "label_fr": "Roccamonfina"
            },
            {
                "value": 48415,
                "label": "Locorotondo",
                "label_ar": "لوكوروتوندو",
                "label_fr": "Locorotondo"
            },
            {
                "value": 48416,
                "label": "Grimaldi",
                "label_ar": "جريمالدي",
                "label_fr": "Grimaldi"
            },
            {
                "value": 48417,
                "label": "San Rufo",
                "label_ar": "سان روفو",
                "label_fr": "San Rufo"
            },
            {
                "value": 48418,
                "label": "Scanzano Jonico",
                "label_ar": "سكانزانو جونيكو",
                "label_fr": "Scanzano Jonico"
            },
            {
                "value": 48419,
                "label": "Castelbuono",
                "label_ar": "كاستيلبونو",
                "label_fr": "Castelbuono"
            },
            {
                "value": 48420,
                "label": "Misilmeri",
                "label_ar": "Misilmeri",
                "label_fr": "Misilmeri"
            },
            {
                "value": 48421,
                "label": "Cave",
                "label_ar": "كهف",
                "label_fr": "la grotte"
            },
            {
                "value": 48422,
                "label": "Stagno",
                "label_ar": "ستاغنو",
                "label_fr": "Stagno"
            },
            {
                "value": 48423,
                "label": "Ribolla",
                "label_ar": "ريبولا",
                "label_fr": "Ribolla"
            },
            {
                "value": 48424,
                "label": "San Pancrazio Salentino",
                "label_ar": "سان بانكرازيو سالينتينو",
                "label_fr": "San Pancrazio Salentino"
            },
            {
                "value": 48425,
                "label": "Davoli",
                "label_ar": "دافولي",
                "label_fr": "Davoli"
            },
            {
                "value": 48426,
                "label": "Castiglion Fiorentino",
                "label_ar": "كاستيجليون فيورنتينو",
                "label_fr": "Castiglion Fiorentino"
            },
            {
                "value": 48427,
                "label": "Brancaleone",
                "label_ar": "برانكليون",
                "label_fr": "Brancaleone"
            },
            {
                "value": 48428,
                "label": "Vallefiorita",
                "label_ar": "فاليفيوريتا",
                "label_fr": "Vallefiorita"
            },
            {
                "value": 48429,
                "label": "Prata",
                "label_ar": "براتا",
                "label_fr": "Prata"
            },
            {
                "value": 48430,
                "label": "San Mango d'Aquino",
                "label_ar": "سان مانجو دي أكينو",
                "label_fr": "San Mango d'Aquino"
            },
            {
                "value": 48431,
                "label": "Casaratta",
                "label_ar": "كاساراتا",
                "label_fr": "Casaratta"
            },
            {
                "value": 48432,
                "label": "Volla",
                "label_ar": "فولا",
                "label_fr": "Volla"
            },
            {
                "value": 48433,
                "label": "Minervino Murge",
                "label_ar": "مينرفينو مورج",
                "label_fr": "Minervino Murge"
            },
            {
                "value": 48434,
                "label": "Canosa di Puglia",
                "label_ar": "كانوسا دي بوليا",
                "label_fr": "Canosa di Puglia"
            },
            {
                "value": 48435,
                "label": "Altidona",
                "label_ar": "ألتيدونا",
                "label_fr": "Altidona"
            },
            {
                "value": 48436,
                "label": "Vezzano sul Crostolo",
                "label_ar": "فيزانو سول كروستولو",
                "label_fr": "Vezzano sul Crostolo"
            },
            {
                "value": 48437,
                "label": "Monte Vidon Corrado",
                "label_ar": "مونتي فيدون كورادو",
                "label_fr": "Monte Vidon Corrado"
            },
            {
                "value": 48438,
                "label": "San Ferdinando di Puglia",
                "label_ar": "سان فرديناندو دي بوليا",
                "label_fr": "San Ferdinando di Puglia"
            },
            {
                "value": 48439,
                "label": "Thiesi",
                "label_ar": "ثيسي",
                "label_fr": "Thiesi"
            },
            {
                "value": 48440,
                "label": "Silanus",
                "label_ar": "سيلانوس",
                "label_fr": "Silanus"
            },
            {
                "value": 48441,
                "label": "Adrano",
                "label_ar": "أدرانو",
                "label_fr": "Adrano"
            },
            {
                "value": 48442,
                "label": "Spezzano Albanese",
                "label_ar": "سبيتسانو ألبانيز",
                "label_fr": "Spezzano Albanese"
            },
            {
                "value": 48443,
                "label": "Tropea",
                "label_ar": "تروبيا",
                "label_fr": "Tropea"
            },
            {
                "value": 48444,
                "label": "Civitella Marittima",
                "label_ar": "سيفيتيلا ماريتيما",
                "label_fr": "Civitella Marittima"
            },
            {
                "value": 48445,
                "label": "Barberino Val d'Elsa",
                "label_ar": "باربيرينو فال ديلسا",
                "label_fr": "Barberino Val d'Elsa"
            },
            {
                "value": 48446,
                "label": "Seano",
                "label_ar": "سيانو",
                "label_fr": "Seano"
            },
            {
                "value": 48447,
                "label": "Aiello del Sabato",
                "label_ar": "ايلو ديل ساباتو",
                "label_fr": "Aiello del Sabato"
            },
            {
                "value": 48448,
                "label": "Cattolica Eraclea",
                "label_ar": "كاتوليكا إراكليا",
                "label_fr": "Cattolica Eraclea"
            },
            {
                "value": 48449,
                "label": "Atri",
                "label_ar": "أتري",
                "label_fr": "Atri"
            },
            {
                "value": 48450,
                "label": "Rio Marina",
                "label_ar": "ريو مارينا",
                "label_fr": "Rio Marina"
            },
            {
                "value": 48451,
                "label": "Blera",
                "label_ar": "بليرا",
                "label_fr": "Blera"
            },
            {
                "value": 48452,
                "label": "Fusignano",
                "label_ar": "فوسينيانو",
                "label_fr": "Fusignano"
            },
            {
                "value": 48453,
                "label": "Talsano",
                "label_ar": "تالسانو",
                "label_fr": "Talsano"
            },
            {
                "value": 48454,
                "label": "Riolo Terme",
                "label_ar": "ريولو تيرمي",
                "label_fr": "Riolo Terme"
            },
            {
                "value": 48455,
                "label": "Castelnovo di Sotto",
                "label_ar": "Castelnovo di Sotto",
                "label_fr": "Castelnovo di Sotto"
            },
            {
                "value": 48456,
                "label": "Migliarino",
                "label_ar": "ميغليارينو",
                "label_fr": "Migliarino"
            },
            {
                "value": 48457,
                "label": "Bernate Ticino",
                "label_ar": "بيرنات تيتشينو",
                "label_fr": "Bernate Tessin"
            },
            {
                "value": 48458,
                "label": "Murlo",
                "label_ar": "مورلو",
                "label_fr": "Murlo"
            },
            {
                "value": 48459,
                "label": "Cusano Mutri",
                "label_ar": "كوسانو موتري",
                "label_fr": "Cusano Mutri"
            },
            {
                "value": 48460,
                "label": "Buonabitacolo",
                "label_ar": "بونابيتاكولو",
                "label_fr": "Buonabitacolo"
            },
            {
                "value": 48461,
                "label": "Bompietro",
                "label_ar": "بومبيترو",
                "label_fr": "Bompietro"
            },
            {
                "value": 48462,
                "label": "Montorio al Vomano",
                "label_ar": "مونتوريو آل فومانو",
                "label_fr": "Montorio al Vomano"
            },
            {
                "value": 48463,
                "label": "Castiglione di Garfagnana",
                "label_ar": "كاستيجليون دي جارفانيانا",
                "label_fr": "Castiglione di Garfagnana"
            },
            {
                "value": 48464,
                "label": "Bagnara di Romagna",
                "label_ar": "Bagnara di Romagna",
                "label_fr": "Bagnara di Romagna"
            },
            {
                "value": 48465,
                "label": "San Pietro Vernotico",
                "label_ar": "سان بيترو فيرنوتيكو",
                "label_fr": "San Pietro Vernotico"
            },
            {
                "value": 48466,
                "label": "Passo Corese",
                "label_ar": "باسو كوريس",
                "label_fr": "Passo Corese"
            },
            {
                "value": 48467,
                "label": "Montalto Uffugo",
                "label_ar": "مونتالتو أوفوجو",
                "label_fr": "Montalto Uffugo"
            },
            {
                "value": 48468,
                "label": "Pontecagnano",
                "label_ar": "بونتيكاغنانو",
                "label_fr": "Pontecagnano"
            },
            {
                "value": 48469,
                "label": "Vieste",
                "label_ar": "فيستي",
                "label_fr": "Vieste"
            },
            {
                "value": 48470,
                "label": "Butera",
                "label_ar": "بوتيرا",
                "label_fr": "Butera"
            },
            {
                "value": 48471,
                "label": "Siderno",
                "label_ar": "سيدرنو",
                "label_fr": "Siderno"
            },
            {
                "value": 48472,
                "label": "Spezzano della Sila",
                "label_ar": "سبيتزانو ديلا سيلا",
                "label_fr": "Spezzano della Sila"
            },
            {
                "value": 48473,
                "label": "Lauro",
                "label_ar": "لاورو",
                "label_fr": "Lauro"
            },
            {
                "value": 48474,
                "label": "Montevago",
                "label_ar": "مونتيفاجو",
                "label_fr": "Montevago"
            },
            {
                "value": 48475,
                "label": "Vanzaghello",
                "label_ar": "فانزاغيلو",
                "label_fr": "Vanzaghello"
            },
            {
                "value": 48476,
                "label": "Saturnia",
                "label_ar": "زحل",
                "label_fr": "Saturnia"
            },
            {
                "value": 48477,
                "label": "Civitella San Paolo",
                "label_ar": "سيفيتيلا سان باولو",
                "label_fr": "Civitella San Paolo"
            },
            {
                "value": 48478,
                "label": "San Secondo Parmense",
                "label_ar": "سان سيكوندو بارمنسي",
                "label_fr": "San Secondo Parmense"
            },
            {
                "value": 48479,
                "label": "Pietracuta",
                "label_ar": "بيتراكوتا",
                "label_fr": "Pietracuta"
            },
            {
                "value": 48480,
                "label": "Arquata Scrivia",
                "label_ar": "أركواتا سكريفيا",
                "label_fr": "Arquata Scrivia"
            },
            {
                "value": 48481,
                "label": "Pogliano Milanese",
                "label_ar": "بوجليانو ميلانيز",
                "label_fr": "Pogliano Milanese"
            },
            {
                "value": 48482,
                "label": "Flero",
                "label_ar": "فليرو",
                "label_fr": "Flero"
            },
            {
                "value": 48483,
                "label": "Caorle",
                "label_ar": "كاورلي",
                "label_fr": "Caorle"
            },
            {
                "value": 48484,
                "label": "Mortegliano",
                "label_ar": "مورتجليانو",
                "label_fr": "Mortegliano"
            },
            {
                "value": 48485,
                "label": "Villanuova sul Clisi",
                "label_ar": "فيلانوفا سول كليسي",
                "label_fr": "Villanuova sul Clisi"
            },
            {
                "value": 48486,
                "label": "Cairate",
                "label_ar": "القاهرة",
                "label_fr": "Cairate"
            },
            {
                "value": 48487,
                "label": "Sant'Antonino",
                "label_ar": "سانت أنتونينو",
                "label_fr": "Sant'Antonino"
            },
            {
                "value": 48488,
                "label": "Monteriggioni",
                "label_ar": "مونتيريجوني",
                "label_fr": "Monteriggioni"
            },
            {
                "value": 48489,
                "label": "Ponso",
                "label_ar": "بونسو",
                "label_fr": "Ponso"
            },
            {
                "value": 48490,
                "label": "Corno Giovine",
                "label_ar": "كورنو جيوفين",
                "label_fr": "Corno Giovine"
            },
            {
                "value": 48491,
                "label": "Mascali",
                "label_ar": "ماسكالي",
                "label_fr": "Mascali"
            },
            {
                "value": 48492,
                "label": "Rossano",
                "label_ar": "روسانو",
                "label_fr": "Rossano"
            },
            {
                "value": 48493,
                "label": "Rocca di Papa",
                "label_ar": "روكا دي بابا",
                "label_fr": "Rocca di Papa"
            },
            {
                "value": 48494,
                "label": "Polla",
                "label_ar": "بولا",
                "label_fr": "Polla"
            },
            {
                "value": 48495,
                "label": "Poggio Mirteto",
                "label_ar": "بوجيو ميرتو",
                "label_fr": "Poggio Mirteto"
            },
            {
                "value": 48496,
                "label": "Sant'Andrea",
                "label_ar": "سانت أندريا",
                "label_fr": "Sant'Andrea"
            },
            {
                "value": 48497,
                "label": "Corigliano Calabro",
                "label_ar": "كوريجليانو كالابرو",
                "label_fr": "Corigliano Calabro"
            },
            {
                "value": 48498,
                "label": "Futani",
                "label_ar": "فوتاني",
                "label_fr": "Futani"
            },
            {
                "value": 48499,
                "label": "San Cipirello",
                "label_ar": "سان سيبيرلو",
                "label_fr": "San Cipirello"
            },
            {
                "value": 48500,
                "label": "Sala Consilina",
                "label_ar": "سالا كونسيلينا",
                "label_fr": "Sala Consilina"
            },
            {
                "value": 48501,
                "label": "Torviscosa",
                "label_ar": "تورفيسكوسا",
                "label_fr": "Torviscosa"
            },
            {
                "value": 48502,
                "label": "Quinto Vercellese",
                "label_ar": "كوينتو فيرسيليز",
                "label_fr": "Quinto Vercellese"
            },
            {
                "value": 48503,
                "label": "Mergozzo",
                "label_ar": "ميرغوزو",
                "label_fr": "Mergozzo"
            },
            {
                "value": 48504,
                "label": "Livorno Ferraris",
                "label_ar": "ليفورنو فيراريس",
                "label_fr": "Livourne Ferraris"
            },
            {
                "value": 48505,
                "label": "Breno",
                "label_ar": "برينو",
                "label_fr": "Breno"
            },
            {
                "value": 48506,
                "label": "Arignano",
                "label_ar": "Arignano",
                "label_fr": "Arignano"
            },
            {
                "value": 48507,
                "label": "San Gillio",
                "label_ar": "سان جيليو",
                "label_fr": "San Gillio"
            },
            {
                "value": 48508,
                "label": "Moretta",
                "label_ar": "موريتا",
                "label_fr": "Moretta"
            },
            {
                "value": 48509,
                "label": "La Salle",
                "label_ar": "لا سال",
                "label_fr": "la salle"
            },
            {
                "value": 48510,
                "label": "Foiano della Chiana",
                "label_ar": "فويانو ديلا شيانا",
                "label_fr": "Foiano della Chiana"
            },
            {
                "value": 48511,
                "label": "Borgaro Torinese",
                "label_ar": "بورغارو تورينيزي",
                "label_fr": "Borgaro Torinese"
            },
            {
                "value": 48512,
                "label": "Trana",
                "label_ar": "ترانا",
                "label_fr": "Trana"
            },
            {
                "value": 48513,
                "label": "Chianciano Terme",
                "label_ar": "تشيانشانو تيرمي",
                "label_fr": "Chianciano Terme"
            },
            {
                "value": 48514,
                "label": "Ospedaletti",
                "label_ar": "أوسبيداليتي",
                "label_fr": "Ospedaletti"
            },
            {
                "value": 48515,
                "label": "Soncino",
                "label_ar": "سونسينو",
                "label_fr": "Soncino"
            },
            {
                "value": 48516,
                "label": "Dubino",
                "label_ar": "دوبينو",
                "label_fr": "Dubino"
            },
            {
                "value": 48517,
                "label": "Vescovato",
                "label_ar": "فيسكوفاتو",
                "label_fr": "Vescovato"
            },
            {
                "value": 48518,
                "label": "Bienno",
                "label_ar": "بينو",
                "label_fr": "Bienno"
            },
            {
                "value": 48519,
                "label": "Acquafredda Inferiore",
                "label_ar": "أكوافريدا إنفيريوري",
                "label_fr": "Acquafredda Inferiore"
            },
            {
                "value": 48520,
                "label": "Prad am Stilfser Joch",
                "label_ar": "براد ام ستيلفسير جوتش",
                "label_fr": "Prad am Stilfser Joch"
            },
            {
                "value": 48521,
                "label": "Pignone",
                "label_ar": "بيجنون",
                "label_fr": "Pignone"
            },
            {
                "value": 48522,
                "label": "Campomorone",
                "label_ar": "كامبومورون",
                "label_fr": "Campomorone"
            },
            {
                "value": 48523,
                "label": "San Fiorano",
                "label_ar": "سان فيورانو",
                "label_fr": "San Fiorano"
            },
            {
                "value": 48524,
                "label": "Confienza",
                "label_ar": "Confienza",
                "label_fr": "Confienza"
            },
            {
                "value": 48525,
                "label": "Canaro",
                "label_ar": "كانارو",
                "label_fr": "Canaro"
            },
            {
                "value": 48526,
                "label": "Ozegna",
                "label_ar": "Ozegna",
                "label_fr": "Ozegna"
            },
            {
                "value": 48527,
                "label": "Gaggio Montano",
                "label_ar": "جاجيو مونتانو",
                "label_fr": "Gaggio Montano"
            },
            {
                "value": 48528,
                "label": "Collepasso",
                "label_ar": "كوليباسو",
                "label_fr": "Collepasso"
            },
            {
                "value": 48529,
                "label": "Lugnano in Teverina",
                "label_ar": "لوجنانو في تيفرينا",
                "label_fr": "Lugnano à Teverina"
            },
            {
                "value": 48530,
                "label": "Sangano",
                "label_ar": "سانغانو",
                "label_fr": "Sangano"
            },
            {
                "value": 48531,
                "label": "Carlino",
                "label_ar": "كارلينو",
                "label_fr": "Carlino"
            },
            {
                "value": 48532,
                "label": "Loria",
                "label_ar": "لوريا",
                "label_fr": "Loria"
            },
            {
                "value": 48533,
                "label": "Bagnolo San Vito",
                "label_ar": "بانولو سان فيتو",
                "label_fr": "Bagnolo San Vito"
            },
            {
                "value": 48534,
                "label": "La Salute di Livenza",
                "label_ar": "لا سالوت دي ليفينزا",
                "label_fr": "La Salute di Livenza"
            },
            {
                "value": 48535,
                "label": "Custonaci",
                "label_ar": "كوستوناتشي",
                "label_fr": "Custonaci"
            },
            {
                "value": 48536,
                "label": "Porto Empedocle",
                "label_ar": "بورتو إمبيدوكلي",
                "label_fr": "Porto Empedocle"
            },
            {
                "value": 48537,
                "label": "Roasio",
                "label_ar": "رواسيو",
                "label_fr": "Roasio"
            },
            {
                "value": 48538,
                "label": "Beinette",
                "label_ar": "بينيت",
                "label_fr": "Beinette"
            },
            {
                "value": 48539,
                "label": "Edolo",
                "label_ar": "ايدولو",
                "label_fr": "Edolo"
            },
            {
                "value": 48540,
                "label": "Neumarkt",
                "label_ar": "نيوماركت",
                "label_fr": "Neumarkt"
            },
            {
                "value": 48541,
                "label": "Naturns",
                "label_ar": "ناتورنز",
                "label_fr": "Naturns"
            },
            {
                "value": 48542,
                "label": "Bertonico",
                "label_ar": "بيرتونيكو",
                "label_fr": "Bertonico"
            },
            {
                "value": 48543,
                "label": "Turbigo",
                "label_ar": "توربيجو",
                "label_fr": "Turbigo"
            },
            {
                "value": 48544,
                "label": "Filighera",
                "label_ar": "Filighera",
                "label_fr": "Filighera"
            },
            {
                "value": 48545,
                "label": "Giussago",
                "label_ar": "جيوساغو",
                "label_fr": "Giussago"
            },
            {
                "value": 48546,
                "label": "Senago",
                "label_ar": "سيناغو",
                "label_fr": "Senago"
            },
            {
                "value": 48547,
                "label": "Follina",
                "label_ar": "فولينا",
                "label_fr": "Follina"
            },
            {
                "value": 48548,
                "label": "San Germano Vercellese",
                "label_ar": "سان جيرمانو فيرسيليسي",
                "label_fr": "San Germano Vercellese"
            },
            {
                "value": 48549,
                "label": "Buriasco",
                "label_ar": "بورياسكو",
                "label_fr": "Buriasco"
            },
            {
                "value": 48550,
                "label": "Oulx",
                "label_ar": "أولكس",
                "label_fr": "Oulx"
            },
            {
                "value": 48551,
                "label": "San Lorenzo Isontino",
                "label_ar": "سان لورينزو إيسونتينو",
                "label_fr": "San Lorenzo Isontino"
            },
            {
                "value": 48552,
                "label": "San Quirino",
                "label_ar": "سان كيرينو",
                "label_fr": "San Quirino"
            },
            {
                "value": 48553,
                "label": "Marina di Ragusa",
                "label_ar": "مارينا دي راغوزا",
                "label_fr": "Marina di Ragusa"
            },
            {
                "value": 48554,
                "label": "Tramonti",
                "label_ar": "ترامونتي",
                "label_fr": "Tramonti"
            },
            {
                "value": 48555,
                "label": "Sibari",
                "label_ar": "سيباري",
                "label_fr": "Sibari"
            },
            {
                "value": 48556,
                "label": "Praia a Mare",
                "label_ar": "برايا ماري",
                "label_fr": "Praia a Mare"
            },
            {
                "value": 48557,
                "label": "Rogliano",
                "label_ar": "روجليانو",
                "label_fr": "Rogliano"
            },
            {
                "value": 48558,
                "label": "Codigoro",
                "label_ar": "كوديجورو",
                "label_fr": "Codigoro"
            },
            {
                "value": 48559,
                "label": "Altedo",
                "label_ar": "ألتيدو",
                "label_fr": "Altedo"
            },
            {
                "value": 48560,
                "label": "Serra San Quirico",
                "label_ar": "سيرا سان كيريكو",
                "label_fr": "Serra San Quirico"
            },
            {
                "value": 48561,
                "label": "Curcuris",
                "label_ar": "كوركوريس",
                "label_fr": "Curcuris"
            },
            {
                "value": 48562,
                "label": "Scicli",
                "label_ar": "شيكلي",
                "label_fr": "Scicli"
            },
            {
                "value": 48563,
                "label": "Baiano",
                "label_ar": "بايانو",
                "label_fr": "Baiano"
            },
            {
                "value": 48564,
                "label": "Villa San Giovanni",
                "label_ar": "فيلا سان جيوفاني",
                "label_fr": "Villa San Giovanni"
            },
            {
                "value": 48565,
                "label": "Casteldaccia",
                "label_ar": "كاستيلداشيا",
                "label_fr": "Casteldaccia"
            },
            {
                "value": 48566,
                "label": "Santo Stefano di Briga",
                "label_ar": "سانتو ستيفانو دي بريجا",
                "label_fr": "Santo Stefano di Briga"
            },
            {
                "value": 48567,
                "label": "Pianella",
                "label_ar": "بيانيلا",
                "label_fr": "Pianella"
            },
            {
                "value": 48568,
                "label": "Moscufo",
                "label_ar": "موسكوفو",
                "label_fr": "Moscufo"
            },
            {
                "value": 48569,
                "label": "Lettopalena",
                "label_ar": "ليتوبالينا",
                "label_fr": "Lettopalena"
            },
            {
                "value": 48570,
                "label": "Giove",
                "label_ar": "جيوف",
                "label_fr": "Giove"
            },
            {
                "value": 48571,
                "label": "Caianello",
                "label_ar": "كيانيلو",
                "label_fr": "Caianello"
            },
            {
                "value": 48572,
                "label": "Lauria",
                "label_ar": "لوريا",
                "label_fr": "Lauria"
            },
            {
                "value": 48573,
                "label": "Lari",
                "label_ar": "لاري",
                "label_fr": "Lari"
            },
            {
                "value": 48574,
                "label": "Lugagnano Val d'Arda",
                "label_ar": "لوغانيانو فال داردا",
                "label_fr": "Lugagnano Val d'Arda"
            },
            {
                "value": 48575,
                "label": "Santa Sofia",
                "label_ar": "سانتا صوفيا",
                "label_fr": "Santa Sofia"
            },
            {
                "value": 48576,
                "label": "Latiano",
                "label_ar": "لاتيانو",
                "label_fr": "Latiano"
            },
            {
                "value": 48577,
                "label": "Troia",
                "label_ar": "ترويا",
                "label_fr": "Troia"
            },
            {
                "value": 48578,
                "label": "Nicolosi",
                "label_ar": "نيكولوسي",
                "label_fr": "Nicolosi"
            },
            {
                "value": 48579,
                "label": "Casal di Principe",
                "label_ar": "كاسال دي برينسيبي",
                "label_fr": "Casal di Principe"
            },
            {
                "value": 48580,
                "label": "Voghenza",
                "label_ar": "فوجينزا",
                "label_fr": "Voghenza"
            },
            {
                "value": 48581,
                "label": "Benestare",
                "label_ar": "بينيستاري",
                "label_fr": "Benestare"
            },
            {
                "value": 48582,
                "label": "Monastir",
                "label_ar": "المنستير",
                "label_fr": "Monastir"
            },
            {
                "value": 48583,
                "label": "Tertenia",
                "label_ar": "ترتينيا",
                "label_fr": "Tertenia"
            },
            {
                "value": 48584,
                "label": "Masullas",
                "label_ar": "ماسولاس",
                "label_fr": "Masullas"
            },
            {
                "value": 48585,
                "label": "Formicola",
                "label_ar": "فورميكولا",
                "label_fr": "Formicola"
            },
            {
                "value": 48586,
                "label": "Zafferana Etnea",
                "label_ar": "زافيرانا إتنيا",
                "label_fr": "Zafferana Etnea"
            },
            {
                "value": 48587,
                "label": "Canicattini Bagni",
                "label_ar": "كانيكاتيني باني",
                "label_fr": "Canicattini Bagni"
            },
            {
                "value": 48588,
                "label": "Siculiana",
                "label_ar": "سيكوليانا",
                "label_fr": "Siculiana"
            },
            {
                "value": 48589,
                "label": "Miglianico",
                "label_ar": "ميغليانيكو",
                "label_fr": "Miglianico"
            },
            {
                "value": 48590,
                "label": "San Giovanni Suergiu",
                "label_ar": "سان جيوفاني سويرجيو",
                "label_fr": "San Giovanni Suergiu"
            },
            {
                "value": 48591,
                "label": "Vigarano Mainarda",
                "label_ar": "فيجارانو مايناردا",
                "label_fr": "Vigarano Mainarda"
            },
            {
                "value": 48592,
                "label": "Rocca Priora",
                "label_ar": "روكا بريورا",
                "label_fr": "Rocca Priora"
            },
            {
                "value": 48593,
                "label": "Carpineto Romano",
                "label_ar": "كاربينيتو رومانو",
                "label_fr": "Carpineto Romano"
            },
            {
                "value": 48594,
                "label": "Sant'Agata sul Santerno",
                "label_ar": "Sant'Agata sul Santerno",
                "label_fr": "Sant'Agata sul Santerno"
            },
            {
                "value": 48595,
                "label": "Gambara",
                "label_ar": "جامبارا",
                "label_fr": "Gambara"
            },
            {
                "value": 48596,
                "label": "Serraglio",
                "label_ar": "سيراجليو",
                "label_fr": "Serraglio"
            },
            {
                "value": 48597,
                "label": "Cimitile",
                "label_ar": "سيميتيل",
                "label_fr": "Cimitile"
            },
            {
                "value": 48598,
                "label": "Popoli",
                "label_ar": "بوبولي",
                "label_fr": "Popoli"
            },
            {
                "value": 48599,
                "label": "Cortandone",
                "label_ar": "كورتاندون",
                "label_fr": "Cortandone"
            },
            {
                "value": 48600,
                "label": "Bianzè",
                "label_ar": "بيانزي",
                "label_fr": "Bianzè"
            },
            {
                "value": 48601,
                "label": "Terruggia",
                "label_ar": "Terruggia",
                "label_fr": "Terruggia"
            },
            {
                "value": 48602,
                "label": "Barzana",
                "label_ar": "برزانا",
                "label_fr": "Barzana"
            },
            {
                "value": 48603,
                "label": "San Severino Marche",
                "label_ar": "سان سيفيرينو ماركي",
                "label_fr": "San Severino Marche"
            },
            {
                "value": 48604,
                "label": "San Lorenzo",
                "label_ar": "سان لورينزو",
                "label_fr": "San Lorenzo"
            },
            {
                "value": 48605,
                "label": "Sexten",
                "label_ar": "سيكستين",
                "label_fr": "Sexten"
            },
            {
                "value": 48606,
                "label": "Avio",
                "label_ar": "افيو",
                "label_fr": "Avio"
            },
            {
                "value": 48607,
                "label": "Figino",
                "label_ar": "فيجينو",
                "label_fr": "Figino"
            },
            {
                "value": 48608,
                "label": "Cozzo",
                "label_ar": "كوزو",
                "label_fr": "Cozzo"
            },
            {
                "value": 48609,
                "label": "Caravate",
                "label_ar": "كارافيت",
                "label_fr": "Caravate"
            },
            {
                "value": 48610,
                "label": "Copiano",
                "label_ar": "كوبيانو",
                "label_fr": "Copiano"
            },
            {
                "value": 48611,
                "label": "Valle Lomellina",
                "label_ar": "فالي لوميلينا",
                "label_fr": "Valle Lomellina"
            },
            {
                "value": 48612,
                "label": "Arqua Polesine",
                "label_ar": "أركوا بوليسين",
                "label_fr": "Arqua Polesine"
            },
            {
                "value": 48613,
                "label": "Lombardore",
                "label_ar": "لومباردور",
                "label_fr": "Lombardore"
            },
            {
                "value": 48614,
                "label": "Scarmagno",
                "label_ar": "سكارماغنو",
                "label_fr": "Scarmagno"
            },
            {
                "value": 48615,
                "label": "Borgone",
                "label_ar": "بورغون",
                "label_fr": "Borgone"
            },
            {
                "value": 48616,
                "label": "San Pietro di Cadore",
                "label_ar": "سان بيترو دي كادوري",
                "label_fr": "San Pietro di Cadore"
            },
            {
                "value": 48617,
                "label": "Montegaldella",
                "label_ar": "مونتيغالديلا",
                "label_fr": "Montegaldella"
            },
            {
                "value": 48618,
                "label": "Arzignano",
                "label_ar": "أرزينيانو",
                "label_fr": "Arzignano"
            },
            {
                "value": 48619,
                "label": "Lendinara",
                "label_ar": "ليندينارا",
                "label_fr": "Lendinara"
            },
            {
                "value": 48620,
                "label": "Fara Gera d'Adda",
                "label_ar": "فارا جيرا دادا",
                "label_fr": "Fara Gera d'Adda"
            },
            {
                "value": 48621,
                "label": "Montalbano",
                "label_ar": "مونتالبانو",
                "label_fr": "Montalbano"
            },
            {
                "value": 48622,
                "label": "Graglia",
                "label_ar": "جراجليا",
                "label_fr": "Graglia"
            },
            {
                "value": 48623,
                "label": "San Carlo",
                "label_ar": "سان كارلو",
                "label_fr": "San Carlo"
            },
            {
                "value": 48624,
                "label": "Pezzana",
                "label_ar": "بيزانا",
                "label_fr": "Pezzana"
            },
            {
                "value": 48625,
                "label": "Ghisalba",
                "label_ar": "غصالة",
                "label_fr": "Ghisalba"
            },
            {
                "value": 48626,
                "label": "Calvisano",
                "label_ar": "كالفيسانو",
                "label_fr": "Calvisano"
            },
            {
                "value": 48627,
                "label": "Corte Franca",
                "label_ar": "كورتي فرانكا",
                "label_fr": "Corte Franca"
            },
            {
                "value": 48628,
                "label": "Villastrada",
                "label_ar": "فيلاسترادا",
                "label_fr": "Villastrada"
            },
            {
                "value": 48629,
                "label": "Lajen",
                "label_ar": "لاجين",
                "label_fr": "Lajen"
            },
            {
                "value": 48630,
                "label": "Cittiglio",
                "label_ar": "سيتيجليو",
                "label_fr": "Cittiglio"
            },
            {
                "value": 48631,
                "label": "Caselle Lurani",
                "label_ar": "كاسيل لوراني",
                "label_fr": "Caselle Lurani"
            },
            {
                "value": 48632,
                "label": "Pantigliate",
                "label_ar": "بانتيجليات",
                "label_fr": "Pantigliate"
            },
            {
                "value": 48633,
                "label": "Conselve",
                "label_ar": "اثنا عشر",
                "label_fr": "Conselve"
            },
            {
                "value": 48634,
                "label": "Bevilacqua",
                "label_ar": "بيفيلاكوا",
                "label_fr": "Bevilacqua"
            },
            {
                "value": 48635,
                "label": "Cervo",
                "label_ar": "سيرفو",
                "label_fr": "Cervo"
            },
            {
                "value": 48636,
                "label": "Moimacco",
                "label_ar": "مويماكو",
                "label_fr": "Moimacco"
            },
            {
                "value": 48637,
                "label": "Ponte nelle Alpi",
                "label_ar": "بونتي نيل ألبي",
                "label_fr": "Ponte nelle Alpi"
            },
            {
                "value": 48638,
                "label": "Ponte",
                "label_ar": "بونتي",
                "label_fr": "Ponte"
            },
            {
                "value": 48639,
                "label": "Agnone",
                "label_ar": "اجنون",
                "label_fr": "Agnone"
            },
            {
                "value": 48640,
                "label": "Castelfranci",
                "label_ar": "كاستلفرانسي",
                "label_fr": "Castelfranci"
            },
            {
                "value": 48641,
                "label": "Paternopoli",
                "label_ar": "باترنوبولي",
                "label_fr": "Paternopoli"
            },
            {
                "value": 48642,
                "label": "Villamar",
                "label_ar": "فيلامار",
                "label_fr": "Villamar"
            },
            {
                "value": 48643,
                "label": "Barumini",
                "label_ar": "باروميني",
                "label_fr": "Barumini"
            },
            {
                "value": 48644,
                "label": "Rizziconi",
                "label_ar": "ريزكوني",
                "label_fr": "Rizziconi"
            },
            {
                "value": 48645,
                "label": "Cinquefrondi",
                "label_ar": "سينكويفروندي",
                "label_fr": "Cinquefrondi"
            },
            {
                "value": 48646,
                "label": "Montemiletto",
                "label_ar": "مونتيميليتو",
                "label_fr": "Montemiletto"
            },
            {
                "value": 48647,
                "label": "Pelago",
                "label_ar": "بيلاجو",
                "label_fr": "Pelago"
            },
            {
                "value": 48648,
                "label": "Buti",
                "label_ar": "لكن أنا",
                "label_fr": "Mais je"
            },
            {
                "value": 48649,
                "label": "Ancarano",
                "label_ar": "Ancarano",
                "label_fr": "Ancarano"
            },
            {
                "value": 48650,
                "label": "Larino",
                "label_ar": "لارينو",
                "label_fr": "Larino"
            },
            {
                "value": 48651,
                "label": "Arrone",
                "label_ar": "ارون",
                "label_fr": "Arrone"
            },
            {
                "value": 48652,
                "label": "Castagneto Carducci",
                "label_ar": "كاستاغنيتو كاردوتشي",
                "label_fr": "Castagneto Carducci"
            },
            {
                "value": 48653,
                "label": "Lido di Camaiore",
                "label_ar": "ليدو دي كاميور",
                "label_fr": "Lido di Camaiore"
            },
            {
                "value": 48654,
                "label": "Castelnuovo di Val di Cecina",
                "label_ar": "Castelnuovo di Val di Cecina",
                "label_fr": "Castelnuovo di Val di Cecina"
            },
            {
                "value": 48655,
                "label": "Marlia",
                "label_ar": "مارليا",
                "label_fr": "Marlia"
            },
            {
                "value": 48656,
                "label": "Vasanello",
                "label_ar": "فاسانيلو",
                "label_fr": "Vasanello"
            },
            {
                "value": 48657,
                "label": "Lenola",
                "label_ar": "لينولا",
                "label_fr": "Lenola"
            },
            {
                "value": 48658,
                "label": "Camerlona",
                "label_ar": "كاميرلونا",
                "label_fr": "Camerlona"
            },
            {
                "value": 48659,
                "label": "Tufino",
                "label_ar": "توفينو",
                "label_fr": "Tufino"
            },
            {
                "value": 48660,
                "label": "Rovito",
                "label_ar": "روفيتو",
                "label_fr": "Rovito"
            },
            {
                "value": 48661,
                "label": "Muro Lucano",
                "label_ar": "مورو لوكانو",
                "label_fr": "Muro Lucano"
            },
            {
                "value": 48662,
                "label": "Moio della Civitella",
                "label_ar": "مويو ديلا سيفيتيلا",
                "label_fr": "Moio della Civitella"
            },
            {
                "value": 48663,
                "label": "Bellona",
                "label_ar": "بيلونا",
                "label_fr": "Bellona"
            },
            {
                "value": 48664,
                "label": "Strongoli",
                "label_ar": "سترونجولي",
                "label_fr": "Strongoli"
            },
            {
                "value": 48665,
                "label": "Loiano",
                "label_ar": "لويانو",
                "label_fr": "Loiano"
            },
            {
                "value": 48666,
                "label": "Mergo",
                "label_ar": "ميرغو",
                "label_fr": "Mergo"
            },
            {
                "value": 48667,
                "label": "Bernalda",
                "label_ar": "برنالدا",
                "label_fr": "Bernalda"
            },
            {
                "value": 48668,
                "label": "Verbicaro",
                "label_ar": "فيربيكارو",
                "label_fr": "Verbicaro"
            },
            {
                "value": 48669,
                "label": "Longobardi",
                "label_ar": "لونجوباردي",
                "label_fr": "Longobardi"
            },
            {
                "value": 48670,
                "label": "Pontassieve",
                "label_ar": "بونتاسيف",
                "label_fr": "Pontassieve"
            },
            {
                "value": 48671,
                "label": "Sant'Andrea di Conza",
                "label_ar": "سانت أندريا دي كونزا",
                "label_fr": "Sant'Andrea di Conza"
            },
            {
                "value": 48672,
                "label": "Ventimiglia di Sicilia",
                "label_ar": "فينتيميليا دي صقلية",
                "label_fr": "Ventimiglia di Sicilia"
            },
            {
                "value": 48673,
                "label": "Corropoli",
                "label_ar": "كروبولي",
                "label_fr": "Corropoli"
            },
            {
                "value": 48674,
                "label": "Ponza",
                "label_ar": "بونزا",
                "label_fr": "Ponza"
            },
            {
                "value": 48675,
                "label": "Policoro",
                "label_ar": "بوليكورو",
                "label_fr": "Policoro"
            },
            {
                "value": 48676,
                "label": "Monteiasi",
                "label_ar": "مونتياسي",
                "label_fr": "Monteiasi"
            },
            {
                "value": 48677,
                "label": "Mottola",
                "label_ar": "موتولا",
                "label_fr": "Mottola"
            },
            {
                "value": 48678,
                "label": "Calcio",
                "label_ar": "كالتشيو",
                "label_fr": "Calcio"
            },
            {
                "value": 48679,
                "label": "Roveredo in Piano",
                "label_ar": "Roveredo في بيانو",
                "label_fr": "Roveredo au piano"
            },
            {
                "value": 48680,
                "label": "Bovolenta",
                "label_ar": "بوفولينتا",
                "label_fr": "Bovolenta"
            },
            {
                "value": 48681,
                "label": "Corsano",
                "label_ar": "كورسانو",
                "label_fr": "Corsano"
            },
            {
                "value": 48682,
                "label": "Dronero",
                "label_ar": "درونيرو",
                "label_fr": "Dronero"
            },
            {
                "value": 48683,
                "label": "Farra d'Alpago",
                "label_ar": "Farra d'Alpago",
                "label_fr": "Farra d'Alpago"
            },
            {
                "value": 48684,
                "label": "Balvano",
                "label_ar": "بالفانو",
                "label_fr": "Balvano"
            },
            {
                "value": 48685,
                "label": "Panaro",
                "label_ar": "بانارو",
                "label_fr": "Panaro"
            },
            {
                "value": 48686,
                "label": "Druogno",
                "label_ar": "دروونو",
                "label_fr": "Druogno"
            },
            {
                "value": 48687,
                "label": "Traona",
                "label_ar": "تراونا",
                "label_fr": "Traona"
            },
            {
                "value": 48688,
                "label": "Filo",
                "label_ar": "فيلو",
                "label_fr": "Filo"
            },
            {
                "value": 48689,
                "label": "Ascoli Satriano",
                "label_ar": "أسكولي ساتريانو",
                "label_fr": "Ascoli Satriano"
            },
            {
                "value": 48690,
                "label": "Circello",
                "label_ar": "سيريلو",
                "label_fr": "Circello"
            },
            {
                "value": 48691,
                "label": "Fornovo di Taro",
                "label_ar": "فورنوفو دي تارو",
                "label_fr": "Fornovo di Taro"
            },
            {
                "value": 48692,
                "label": "Argenta",
                "label_ar": "أرجنتا",
                "label_fr": "Argenta"
            },
            {
                "value": 48693,
                "label": "Monteforte Irpino",
                "label_ar": "مونتيفورت ايربينو",
                "label_fr": "Monteforte Irpino"
            },
            {
                "value": 48694,
                "label": "Avola",
                "label_ar": "أفولا",
                "label_fr": "Avola"
            },
            {
                "value": 48695,
                "label": "Milo",
                "label_ar": "ميلو",
                "label_fr": "Milo"
            },
            {
                "value": 48696,
                "label": "Fossacesia",
                "label_ar": "الحفريات",
                "label_fr": "Fossacesia"
            },
            {
                "value": 48697,
                "label": "Abbadia San Salvatore",
                "label_ar": "أباديا سان سالفاتور",
                "label_fr": "Abbadia San Salvatore"
            },
            {
                "value": 48698,
                "label": "Locati",
                "label_ar": "لوكاتي",
                "label_fr": "Locati"
            },
            {
                "value": 48699,
                "label": "Santo Stefano Quisquina",
                "label_ar": "سانتو ستيفانو كيسكينا",
                "label_fr": "Santo Stefano Quisquina"
            },
            {
                "value": 48700,
                "label": "Sant'Omero",
                "label_ar": "سانت أوميرو",
                "label_fr": "Sant'Omero"
            },
            {
                "value": 48701,
                "label": "Castello",
                "label_ar": "كاستيلو",
                "label_fr": "Castello"
            },
            {
                "value": 48702,
                "label": "Castelnuovo di Porto",
                "label_ar": "Castelnuovo di Porto",
                "label_fr": "Castelnuovo di Porto"
            },
            {
                "value": 48703,
                "label": "Carovigno",
                "label_ar": "كاروفيجنو",
                "label_fr": "Carovigno"
            },
            {
                "value": 48704,
                "label": "Malalbergo",
                "label_ar": "مالالبيرغو",
                "label_fr": "Malalbergo"
            },
            {
                "value": 48705,
                "label": "Montegiorgio",
                "label_ar": "مونتيجيورجيو",
                "label_fr": "Montegiorgio"
            },
            {
                "value": 48706,
                "label": "Torre de' Passeri",
                "label_ar": "توري دي باسيري",
                "label_fr": "Torre de 'Passeri"
            },
            {
                "value": 48707,
                "label": "Loreto Aprutino",
                "label_ar": "لوريتو أبروتينو",
                "label_fr": "Loreto Aprutino"
            },
            {
                "value": 48708,
                "label": "Monsampolo del Tronto",
                "label_ar": "مونسامبولو ديل ترونتو",
                "label_fr": "Monsampolo del Tronto"
            },
            {
                "value": 48709,
                "label": "Rivalta",
                "label_ar": "ريفالتا",
                "label_fr": "Rivalta"
            },
            {
                "value": 48710,
                "label": "Cadeo",
                "label_ar": "كاديو",
                "label_fr": "Cadeo"
            },
            {
                "value": 48711,
                "label": "Ghiffa",
                "label_ar": "غيفة",
                "label_fr": "Ghiffa"
            },
            {
                "value": 48712,
                "label": "Castellar Guidobono",
                "label_ar": "كاستيلار جويدوبونو",
                "label_fr": "Castellar Guidobono"
            },
            {
                "value": 48713,
                "label": "Valfenera",
                "label_ar": "فالفينيرا",
                "label_fr": "Valfenera"
            },
            {
                "value": 48714,
                "label": "Camisano",
                "label_ar": "كاميسانو",
                "label_fr": "Camisano"
            },
            {
                "value": 48715,
                "label": "Gazzuolo",
                "label_ar": "جازولو",
                "label_fr": "Gazzuolo"
            },
            {
                "value": 48716,
                "label": "Comano",
                "label_ar": "كومانو",
                "label_fr": "Comano"
            },
            {
                "value": 48717,
                "label": "Sale Marasino",
                "label_ar": "بيع ماراسينو",
                "label_fr": "Vente Marasino"
            },
            {
                "value": 48718,
                "label": "Longarone",
                "label_ar": "لونجارون",
                "label_fr": "Longarone"
            },
            {
                "value": 48719,
                "label": "San Marco",
                "label_ar": "سان ماركو",
                "label_fr": "San Marco"
            },
            {
                "value": 48720,
                "label": "San Giorgio Canavese",
                "label_ar": "سان جورجيو كانافيزي",
                "label_fr": "San Giorgio Canavese"
            },
            {
                "value": 48721,
                "label": "Ala di Stura",
                "label_ar": "علاء دي ستورا",
                "label_fr": "Ala di Stura"
            },
            {
                "value": 48722,
                "label": "Montecassiano",
                "label_ar": "مونتيكاسيانو",
                "label_fr": "Montecassiano"
            },
            {
                "value": 48723,
                "label": "Buttrio",
                "label_ar": "بوتريو",
                "label_fr": "Buttrio"
            },
            {
                "value": 48724,
                "label": "Favaro Veneto",
                "label_ar": "فافارو فينيتو",
                "label_fr": "Favaro Veneto"
            },
            {
                "value": 48725,
                "label": "Mühlbach",
                "label_ar": "موهلباخ",
                "label_fr": "Mühlbach"
            },
            {
                "value": 48726,
                "label": "Caldonazzo",
                "label_ar": "كالدونازو",
                "label_fr": "Caldonazzo"
            },
            {
                "value": 48727,
                "label": "Ponte Basso",
                "label_ar": "بونتي باسو",
                "label_fr": "Ponte Basso"
            },
            {
                "value": 48728,
                "label": "Calitri",
                "label_ar": "كاليتري",
                "label_fr": "Calitri"
            },
            {
                "value": 48729,
                "label": "San Mango",
                "label_ar": "سان مانجو",
                "label_fr": "San Mango"
            },
            {
                "value": 48730,
                "label": "Nocera Terinese",
                "label_ar": "نوسيرا تيرينيس",
                "label_fr": "Nocera Terinese"
            },
            {
                "value": 48731,
                "label": "Armeno",
                "label_ar": "أرمينو",
                "label_fr": "Armeno"
            },
            {
                "value": 48732,
                "label": "Gardone Val Trompia",
                "label_ar": "جاردون فال ترومبيا",
                "label_fr": "Gardone Val Trompia"
            },
            {
                "value": 48733,
                "label": "Gonzaga",
                "label_ar": "جونزاغا",
                "label_fr": "Gonzaga"
            },
            {
                "value": 48734,
                "label": "Toblach",
                "label_ar": "توبلاش",
                "label_fr": "Toblach"
            },
            {
                "value": 48735,
                "label": "Villafranca d'Asti",
                "label_ar": "فيلافرانكا ديستي",
                "label_fr": "Villafranca d'Asti"
            },
            {
                "value": 48736,
                "label": "Bornasco",
                "label_ar": "بورناسكو",
                "label_fr": "Bornasco"
            },
            {
                "value": 48737,
                "label": "Lomello",
                "label_ar": "لوميلو",
                "label_fr": "Lomello"
            },
            {
                "value": 48738,
                "label": "Arcugnano",
                "label_ar": "اركوجنانو",
                "label_fr": "Arcugnano"
            },
            {
                "value": 48739,
                "label": "Pocenia",
                "label_ar": "بوسينيا",
                "label_fr": "Pocénie"
            },
            {
                "value": 48740,
                "label": "Moiano",
                "label_ar": "مويانو",
                "label_fr": "Moiano"
            },
            {
                "value": 48741,
                "label": "Triggianello",
                "label_ar": "تريجيانيلو",
                "label_fr": "Triggianello"
            },
            {
                "value": 48742,
                "label": "Nuraminis",
                "label_ar": "نورامينيس",
                "label_fr": "Nuraminis"
            },
            {
                "value": 48743,
                "label": "Nurri",
                "label_ar": "نوري",
                "label_fr": "Nurri"
            },
            {
                "value": 48744,
                "label": "Chiaramonte Gulfi",
                "label_ar": "Chiaramonte Gulfi",
                "label_fr": "Chiaramonte Gulfi"
            },
            {
                "value": 48745,
                "label": "Borgia",
                "label_ar": "بورجيا",
                "label_fr": "Borgia"
            },
            {
                "value": 48746,
                "label": "Laureana di Borrello",
                "label_ar": "لوريانا دي بوريلو",
                "label_fr": "Laureana di Borrello"
            },
            {
                "value": 48747,
                "label": "Laino Borgo",
                "label_ar": "لاينو بورجو",
                "label_fr": "Laino Borgo"
            },
            {
                "value": 48748,
                "label": "Castel San Lorenzo",
                "label_ar": "قلعة سان لورينزو",
                "label_fr": "Castel San Lorenzo"
            },
            {
                "value": 48749,
                "label": "Pisticci",
                "label_ar": "بيستيكسي",
                "label_fr": "Pisticci"
            },
            {
                "value": 48750,
                "label": "Raffadali",
                "label_ar": "رفادالي",
                "label_fr": "Raffadali"
            },
            {
                "value": 48751,
                "label": "Bagni di Lucca",
                "label_ar": "باني دي لوكا",
                "label_fr": "Bagni di Lucca"
            },
            {
                "value": 48752,
                "label": "Arce",
                "label_ar": "Arce",
                "label_fr": "Arce"
            },
            {
                "value": 48753,
                "label": "Lesina",
                "label_ar": "ليسينا",
                "label_fr": "Lesina"
            },
            {
                "value": 48754,
                "label": "Surbo",
                "label_ar": "سوربو",
                "label_fr": "Surbo"
            },
            {
                "value": 48755,
                "label": "Alliste",
                "label_ar": "أليست",
                "label_fr": "Alliste"
            },
            {
                "value": 48756,
                "label": "Poggio Moiano",
                "label_ar": "بوجيو مويانو",
                "label_fr": "Poggio Moiano"
            },
            {
                "value": 48757,
                "label": "Cerreto Laziale",
                "label_ar": "سيريتو لازيالي",
                "label_fr": "Cerreto Laziale"
            },
            {
                "value": 48758,
                "label": "San Filippo",
                "label_ar": "سان فيليبو",
                "label_fr": "San Filippo"
            },
            {
                "value": 48759,
                "label": "Pianella",
                "label_ar": "بيانيلا",
                "label_fr": "Pianella"
            },
            {
                "value": 48760,
                "label": "Potenza Picena",
                "label_ar": "بوتينزا بيسينا",
                "label_fr": "Potenza Picena"
            },
            {
                "value": 48761,
                "label": "Ricadi",
                "label_ar": "ريكادي",
                "label_fr": "Ricadi"
            },
            {
                "value": 48762,
                "label": "Poggio a Caiano",
                "label_ar": "بوجيو كايانو",
                "label_fr": "Poggio a Caiano"
            },
            {
                "value": 48763,
                "label": "Aquilonia",
                "label_ar": "أكويلونيا",
                "label_fr": "Aquilonia"
            },
            {
                "value": 48764,
                "label": "Colonnella",
                "label_ar": "كولونيلا",
                "label_fr": "Colonnella"
            },
            {
                "value": 48765,
                "label": "Mugnano",
                "label_ar": "موغنانو",
                "label_fr": "Mugnano"
            },
            {
                "value": 48766,
                "label": "Atina",
                "label_ar": "أتينا",
                "label_fr": "Atina"
            },
            {
                "value": 48767,
                "label": "Campi Salentina",
                "label_ar": "كامبي سالينتينا",
                "label_fr": "Campi Salentina"
            },
            {
                "value": 48768,
                "label": "San Giovanni al Natisone",
                "label_ar": "سان جيوفاني آل ناتيسوني",
                "label_fr": "San Giovanni al Natisone"
            },
            {
                "value": 48769,
                "label": "Neive",
                "label_ar": "نيف",
                "label_fr": "Neive"
            },
            {
                "value": 48770,
                "label": "Castagnole",
                "label_ar": "كاستانول",
                "label_fr": "Castagnole"
            },
            {
                "value": 48771,
                "label": "Crespano del Grappa",
                "label_ar": "كريسبانو ديل جرابا",
                "label_fr": "Crespano del Grappa"
            },
            {
                "value": 48772,
                "label": "Molazzana",
                "label_ar": "مولازانا",
                "label_fr": "Molazzana"
            },
            {
                "value": 48773,
                "label": "Ottiglio",
                "label_ar": "Ottiglio",
                "label_fr": "Ottiglio"
            },
            {
                "value": 48774,
                "label": "Issime",
                "label_ar": "العدد",
                "label_fr": "Issime"
            },
            {
                "value": 48775,
                "label": "Cavriana",
                "label_ar": "كافريانا",
                "label_fr": "Cavriana"
            },
            {
                "value": 48776,
                "label": "Tresenda",
                "label_ar": "تريسيندا",
                "label_fr": "Tresenda"
            },
            {
                "value": 48777,
                "label": "Grigno",
                "label_ar": "Grigno",
                "label_fr": "Grigno"
            },
            {
                "value": 48778,
                "label": "Rossiglione",
                "label_ar": "روسيليوني",
                "label_fr": "Rossiglione"
            },
            {
                "value": 48779,
                "label": "Mede",
                "label_ar": "ميد",
                "label_fr": "Mede"
            },
            {
                "value": 48780,
                "label": "Rognano",
                "label_ar": "روجنانو",
                "label_fr": "Rognano"
            },
            {
                "value": 48781,
                "label": "Vernate",
                "label_ar": "فيرنات",
                "label_fr": "Vernate"
            },
            {
                "value": 48782,
                "label": "Solesino",
                "label_ar": "سوليسينو",
                "label_fr": "Solesino"
            },
            {
                "value": 48783,
                "label": "Ceregnano",
                "label_ar": "سيريجنانو",
                "label_fr": "Ceregnano"
            },
            {
                "value": 48784,
                "label": "Cuceglio",
                "label_ar": "كوجيليو",
                "label_fr": "Cuceglio"
            },
            {
                "value": 48785,
                "label": "Torrazza",
                "label_ar": "Torrazza",
                "label_fr": "Torrazza"
            },
            {
                "value": 48786,
                "label": "Carrù",
                "label_ar": "كارو",
                "label_fr": "Carrù"
            },
            {
                "value": 48787,
                "label": "San Martino al Tagliamento",
                "label_ar": "سان مارتينو آل تاليامنتو",
                "label_fr": "San Martino al Tagliamento"
            },
            {
                "value": 48788,
                "label": "Revine",
                "label_ar": "Revine",
                "label_fr": "Revine"
            },
            {
                "value": 48789,
                "label": "Pian Camuno",
                "label_ar": "بيان كامونو",
                "label_fr": "Pian Camuno"
            },
            {
                "value": 48790,
                "label": "Zimella",
                "label_ar": "زيميلا",
                "label_fr": "Zimella"
            },
            {
                "value": 48791,
                "label": "Taverna",
                "label_ar": "تافيرنا",
                "label_fr": "Taverne"
            },
            {
                "value": 48792,
                "label": "Poggio Berni",
                "label_ar": "بوجيو بيرني",
                "label_fr": "Poggio Berni"
            },
            {
                "value": 48793,
                "label": "Gambettola",
                "label_ar": "غامبيتولا",
                "label_fr": "Gambettola"
            },
            {
                "value": 48794,
                "label": "Sant'Angelo in Vado",
                "label_ar": "سانت أنجيلو في فادو",
                "label_fr": "Sant'Angelo in Vado"
            },
            {
                "value": 48795,
                "label": "Venarotta",
                "label_ar": "فيناروتا",
                "label_fr": "Venarotta"
            },
            {
                "value": 48796,
                "label": "San Marco in Lamis",
                "label_ar": "سان ماركو في لاميس",
                "label_fr": "San Marco à Lamis"
            },
            {
                "value": 48797,
                "label": "Fluminimaggiore",
                "label_ar": "فلومينيماجيوري",
                "label_fr": "Fluminimaggiore"
            },
            {
                "value": 48798,
                "label": "Cavriago",
                "label_ar": "كافرياغو",
                "label_fr": "Cavriago"
            },
            {
                "value": 48799,
                "label": "San Giorgio a Liri",
                "label_ar": "سان جورجيو ليري",
                "label_fr": "San Giorgio a Liri"
            },
            {
                "value": 48800,
                "label": "Biancano",
                "label_ar": "بيانكانو",
                "label_fr": "Biancano"
            },
            {
                "value": 48801,
                "label": "San Marco",
                "label_ar": "سان ماركو",
                "label_fr": "San Marco"
            },
            {
                "value": 48802,
                "label": "Gallese",
                "label_ar": "غاليس",
                "label_fr": "Gallese"
            },
            {
                "value": 48803,
                "label": "Portico di Caserta",
                "label_ar": "بورتيكو دي كاسيرتا",
                "label_fr": "Portique de Caserte"
            },
            {
                "value": 48804,
                "label": "Santa Elisabetta",
                "label_ar": "سانتا إليزابيتا",
                "label_fr": "Santa Elisabetta"
            },
            {
                "value": 48805,
                "label": "Vallo della Lucania",
                "label_ar": "فالو ديلا لوكانيا",
                "label_fr": "Vallo della Lucania"
            },
            {
                "value": 48806,
                "label": "Cappelle sul Tavo",
                "label_ar": "كابيل سول تافو",
                "label_fr": "Cappelle sul Tavo"
            },
            {
                "value": 48807,
                "label": "Baschi",
                "label_ar": "الباسكي",
                "label_fr": "Baschi"
            },
            {
                "value": 48808,
                "label": "Vajont",
                "label_ar": "فاجونت",
                "label_fr": "Vajont"
            },
            {
                "value": 48809,
                "label": "Camino",
                "label_ar": "كامينو",
                "label_fr": "Camino"
            },
            {
                "value": 48810,
                "label": "Barile",
                "label_ar": "باريل",
                "label_fr": "Barile"
            },
            {
                "value": 48811,
                "label": "Diecimo",
                "label_ar": "ديسيمو",
                "label_fr": "Diecimo"
            },
            {
                "value": 48812,
                "label": "Campiglia Marittima",
                "label_ar": "كامبيجليا ماريتيما",
                "label_fr": "Campiglia Marittima"
            },
            {
                "value": 48813,
                "label": "Castel Giorgio",
                "label_ar": "كاستل جورجيو",
                "label_fr": "Castel Giorgio"
            },
            {
                "value": 48814,
                "label": "Galatina",
                "label_ar": "جالاتينا",
                "label_fr": "Galatina"
            },
            {
                "value": 48815,
                "label": "Goito",
                "label_ar": "جويتو",
                "label_fr": "Goito"
            },
            {
                "value": 48816,
                "label": "Oliva",
                "label_ar": "أوليفا",
                "label_fr": "Oliva"
            },
            {
                "value": 48817,
                "label": "Anfo",
                "label_ar": "Anfo",
                "label_fr": "Anfo"
            },
            {
                "value": 48818,
                "label": "Sant'Antonino di Susa",
                "label_ar": "سانت أنتونينو دي سوسا",
                "label_fr": "Sant'Antonino di Susa"
            },
            {
                "value": 48819,
                "label": "Fontanarosa",
                "label_ar": "فونتاناروسا",
                "label_fr": "Fontanarosa"
            },
            {
                "value": 48820,
                "label": "San Pietro Viminario",
                "label_ar": "سان بيترو فيميناريو",
                "label_fr": "San Pietro Viminario"
            },
            {
                "value": 48821,
                "label": "Annicco",
                "label_ar": "أنيكو",
                "label_fr": "Annicco"
            },
            {
                "value": 48822,
                "label": "Pianezze",
                "label_ar": "بيانز",
                "label_fr": "Pianezze"
            },
            {
                "value": 48823,
                "label": "San Martino",
                "label_ar": "سان مارتينو",
                "label_fr": "San Martino"
            },
            {
                "value": 48824,
                "label": "Bellariva",
                "label_ar": "بيلاريفا",
                "label_fr": "Bellariva"
            },
            {
                "value": 48825,
                "label": "Bedollo",
                "label_ar": "بيدولو",
                "label_fr": "Bedollo"
            },
            {
                "value": 48826,
                "label": "Bosco Chiesanuova",
                "label_ar": "بوسكو كيزانوفا",
                "label_fr": "Bosco Chiesanuova"
            },
            {
                "value": 48827,
                "label": "Spin",
                "label_ar": "غزل",
                "label_fr": "Tourner"
            },
            {
                "value": 48828,
                "label": "Rivodutri",
                "label_ar": "ريفودوتري",
                "label_fr": "Rivodutri"
            },
            {
                "value": 48829,
                "label": "Poggio Nativo",
                "label_ar": "بوجيو ناتيفو",
                "label_fr": "Poggio Nativo"
            },
            {
                "value": 48830,
                "label": "Fara in Sabina",
                "label_ar": "فارا في سابينا",
                "label_fr": "Fara à Sabina"
            },
            {
                "value": 48831,
                "label": "Cantalice",
                "label_ar": "كانتاليس",
                "label_fr": "Cantalice"
            },
            {
                "value": 48832,
                "label": "Battaglia Terme",
                "label_ar": "باتاغليا تيرمي",
                "label_fr": "Battaglia Terme"
            },
            {
                "value": 48833,
                "label": "Fiera",
                "label_ar": "فييرا",
                "label_fr": "Fiera"
            },
            {
                "value": 48834,
                "label": "Regina",
                "label_ar": "ريجينا",
                "label_fr": "Regina"
            },
            {
                "value": 48835,
                "label": "Pian-Fossal",
                "label_ar": "بيان فوسال",
                "label_fr": "Pian-Fossal"
            },
            {
                "value": 48836,
                "label": "Gabbiano",
                "label_ar": "جابيانو",
                "label_fr": "Gabbiano"
            },
            {
                "value": 48837,
                "label": "Postiglione",
                "label_ar": "بوستيجليون",
                "label_fr": "Postiglione"
            },
            {
                "value": 48838,
                "label": "Abetone",
                "label_ar": "أبيتون",
                "label_fr": "Abetone"
            },
            {
                "value": 48839,
                "label": "Roccella",
                "label_ar": "روكسيلا",
                "label_fr": "Roccella"
            },
            {
                "value": 48840,
                "label": "Montorso Vicentino",
                "label_ar": "مونتورسو فيسينتينو",
                "label_fr": "Montorso Vicentino"
            },
            {
                "value": 48841,
                "label": "Coppe",
                "label_ar": "كوب",
                "label_fr": "Coppe"
            },
            {
                "value": 48842,
                "label": "San Polo",
                "label_ar": "سان بولو",
                "label_fr": "San Polo"
            },
            {
                "value": 48843,
                "label": "Medea",
                "label_ar": "المدية",
                "label_fr": "Médée"
            },
            {
                "value": 48844,
                "label": "Lapio",
                "label_ar": "لابيو",
                "label_fr": "Lapio"
            },
            {
                "value": 48845,
                "label": "Fiesso d'Artico",
                "label_ar": "Fiesso d'Artico",
                "label_fr": "Fiesso d'Artico"
            },
            {
                "value": 48846,
                "label": "San Vittore",
                "label_ar": "سان فيتوري",
                "label_fr": "San Vittore"
            },
            {
                "value": 48847,
                "label": "Sotto Castello",
                "label_ar": "سوتو كاستيلو",
                "label_fr": "Sotto Castello"
            },
            {
                "value": 48848,
                "label": "Gorle",
                "label_ar": "غورل",
                "label_fr": "Gorle"
            },
            {
                "value": 48849,
                "label": "Varese Ligure",
                "label_ar": "فاريزي ليغور",
                "label_fr": "Varèse Ligure"
            },
            {
                "value": 48850,
                "label": "Rocchetta di Vara",
                "label_ar": "روتشيتا دي فارا",
                "label_fr": "Rocchetta di Vara"
            },
            {
                "value": 48851,
                "label": "Gazzola",
                "label_ar": "جازولا",
                "label_fr": "Gazzola"
            },
            {
                "value": 48852,
                "label": "Sernaglia della Battaglia",
                "label_ar": "سيرناجليا ديلا باتاغليا",
                "label_fr": "Sernaglia della Battaglia"
            },
            {
                "value": 48853,
                "label": "Cordignano",
                "label_ar": "كوردينيانو",
                "label_fr": "Cordignano"
            },
            {
                "value": 48854,
                "label": "Pero",
                "label_ar": "بيرو",
                "label_fr": "Pero"
            },
            {
                "value": 48855,
                "label": "Pieve del Cairo",
                "label_ar": "بيفي ديل كايرو",
                "label_fr": "Pieve del Cairo"
            },
            {
                "value": 48856,
                "label": "Villetta",
                "label_ar": "فيليتا",
                "label_fr": "Villetta"
            },
            {
                "value": 48857,
                "label": "Capriolo",
                "label_ar": "كابريولو",
                "label_fr": "Capriolo"
            },
            {
                "value": 48858,
                "label": "Poggio Renatico",
                "label_ar": "بوجيو ريناتيكو",
                "label_fr": "Poggio Renatico"
            },
            {
                "value": 48859,
                "label": "Spineda",
                "label_ar": "سبينيدا",
                "label_fr": "Spineda"
            },
            {
                "value": 48860,
                "label": "San Giorgio della Richinvelda",
                "label_ar": "سان جورجيو ديلا ريتشنفيلدا",
                "label_fr": "San Giorgio della Richinvelda"
            },
            {
                "value": 48861,
                "label": "Scaldasole",
                "label_ar": "سكالداسول",
                "label_fr": "Scaldasole"
            },
            {
                "value": 48862,
                "label": "Rodigo",
                "label_ar": "روديجو",
                "label_fr": "Rodigo"
            },
            {
                "value": 48863,
                "label": "Mathi",
                "label_ar": "ماتي",
                "label_fr": "Mathi"
            },
            {
                "value": 48864,
                "label": "Duino-Aurisina",
                "label_ar": "دوينو أوريسينا",
                "label_fr": "Duino-Aurisina"
            },
            {
                "value": 48865,
                "label": "Villa Bartolomea",
                "label_ar": "فيلا بارتولوميا",
                "label_fr": "Villa Bartolomea"
            },
            {
                "value": 48866,
                "label": "Bagnoli di Sopra",
                "label_ar": "باجنولي دي سوبرا",
                "label_fr": "Bagnoli di Sopra"
            },
            {
                "value": 48867,
                "label": "Numana",
                "label_ar": "نعمانه",
                "label_fr": "Numana"
            },
            {
                "value": 48868,
                "label": "Ceprano",
                "label_ar": "سيبرانو",
                "label_fr": "Ceprano"
            },
            {
                "value": 48869,
                "label": "Minori",
                "label_ar": "مينوري",
                "label_fr": "Minori"
            },
            {
                "value": 48870,
                "label": "San Miniato Basso",
                "label_ar": "سان مينياتو باسو",
                "label_fr": "San Miniato Basso"
            },
            {
                "value": 48871,
                "label": "Lercara Friddi",
                "label_ar": "ليركارا فريدي",
                "label_fr": "Lercara Friddi"
            },
            {
                "value": 48872,
                "label": "Castello",
                "label_ar": "كاستيلو",
                "label_fr": "Castello"
            },
            {
                "value": 48873,
                "label": "Renazzo",
                "label_ar": "ريناتسو",
                "label_fr": "Renazzo"
            },
            {
                "value": 48874,
                "label": "Ramiseto",
                "label_ar": "راميسيتو",
                "label_fr": "Ramiseto"
            },
            {
                "value": 48875,
                "label": "San Giovanni in Fiore",
                "label_ar": "سان جيوفاني في فيوري",
                "label_fr": "San Giovanni in Fiore"
            },
            {
                "value": 48876,
                "label": "Robecco d'Oglio",
                "label_ar": "روبيكو دوجليو",
                "label_fr": "Robecco d'Oglio"
            },
            {
                "value": 48877,
                "label": "Pozzonovo",
                "label_ar": "بوزونوفو",
                "label_fr": "Pozzonovo"
            },
            {
                "value": 48878,
                "label": "Villa Verucchio",
                "label_ar": "فيلا Verucchio",
                "label_fr": "Villa Verucchio"
            },
            {
                "value": 48879,
                "label": "Gardone Riviera",
                "label_ar": "جاردوني ريفييرا",
                "label_fr": "Gardone Riviera"
            },
            {
                "value": 48880,
                "label": "Tassullo",
                "label_ar": "تاسولو",
                "label_fr": "Tassullo"
            },
            {
                "value": 48881,
                "label": "Agliana",
                "label_ar": "اجليانا",
                "label_fr": "Agliana"
            },
            {
                "value": 48882,
                "label": "Piancastagnaio",
                "label_ar": "بيانكاستاجنايو",
                "label_fr": "Piancastagnaio"
            },
            {
                "value": 48883,
                "label": "Roseto Valfortore",
                "label_ar": "روزيتو فالفورتور",
                "label_fr": "Roseto Valfortore"
            },
            {
                "value": 48884,
                "label": "Villa Basilica",
                "label_ar": "فيلا بازيليكا",
                "label_fr": "Villa Basilique"
            },
            {
                "value": 48885,
                "label": "Roccarainola",
                "label_ar": "روكارينولا",
                "label_fr": "Roccarainola"
            },
            {
                "value": 48886,
                "label": "Niederdorf",
                "label_ar": "نيدردورف",
                "label_fr": "Niederdorf"
            },
            {
                "value": 48887,
                "label": "Venegono Inferiore",
                "label_ar": "Venegono Inferiore",
                "label_fr": "Venegono Inferiore"
            },
            {
                "value": 48888,
                "label": "Valli del Pasubio",
                "label_ar": "فالي ديل باسوبيو",
                "label_fr": "Valli del Pasubio"
            },
            {
                "value": 48889,
                "label": "Marcallo con Casone",
                "label_ar": "Marcallo يخدع Casone",
                "label_fr": "Marcallo avec Casone"
            },
            {
                "value": 48890,
                "label": "San Lorenzo al Mare",
                "label_ar": "سان لورينزو الماري",
                "label_fr": "San Lorenzo al Mare"
            },
            {
                "value": 48891,
                "label": "Cavallermaggiore",
                "label_ar": "كافاليرماجوري",
                "label_fr": "Cavallermaggiore"
            },
            {
                "value": 48892,
                "label": "Montecchio",
                "label_ar": "مونتيكيو",
                "label_fr": "Montecchio"
            },
            {
                "value": 48893,
                "label": "Martignacco",
                "label_ar": "مارتينياكو",
                "label_fr": "Martignacco"
            },
            {
                "value": 48894,
                "label": "Gonnesa",
                "label_ar": "غونيزا",
                "label_fr": "Gonnesa"
            },
            {
                "value": 48895,
                "label": "Revello",
                "label_ar": "Revello",
                "label_fr": "Revello"
            },
            {
                "value": 48896,
                "label": "Castellengo",
                "label_ar": "كاستلينجو",
                "label_fr": "Castellengo"
            },
            {
                "value": 48897,
                "label": "Torre del Lago Puccini",
                "label_ar": "توري ديل لاغو بوتشيني",
                "label_fr": "Torre del Lago Puccini"
            },
            {
                "value": 48898,
                "label": "Orta Nova",
                "label_ar": "أورتا نوفا",
                "label_fr": "Orta Nova"
            },
            {
                "value": 48899,
                "label": "Castellammare del Golfo",
                "label_ar": "كاستيلاماري ديل جولفو",
                "label_fr": "Castellammare del Golfo"
            },
            {
                "value": 48900,
                "label": "Cuglieri",
                "label_ar": "كوجلييري",
                "label_fr": "Cuglieri"
            },
            {
                "value": 48901,
                "label": "Ripi",
                "label_ar": "ريبي",
                "label_fr": "Ripi"
            },
            {
                "value": 48902,
                "label": "Umbertide",
                "label_ar": "أمبرتايد",
                "label_fr": "Umbertide"
            },
            {
                "value": 48903,
                "label": "San Sperate",
                "label_ar": "سان سبيراتي",
                "label_fr": "San Sperate"
            },
            {
                "value": 48904,
                "label": "Bonea",
                "label_ar": "بونيا",
                "label_fr": "Bonea"
            },
            {
                "value": 48905,
                "label": "Torre Santa Susanna",
                "label_ar": "توري سانتا سوزانا",
                "label_fr": "Torre Santa Susanna"
            },
            {
                "value": 48906,
                "label": "Monteroni di Lecce",
                "label_ar": "مونتيروني دي ليتشي",
                "label_fr": "Monteroni di Lecce"
            },
            {
                "value": 48907,
                "label": "Isola del Liri",
                "label_ar": "ايزولا ديل ليري",
                "label_fr": "Isola del Liri"
            },
            {
                "value": 48908,
                "label": "Briatico",
                "label_ar": "برياتيكو",
                "label_fr": "Briatico"
            },
            {
                "value": 48909,
                "label": "Porano",
                "label_ar": "بورانو",
                "label_fr": "Porano"
            },
            {
                "value": 48910,
                "label": "Ticengo",
                "label_ar": "تيسينغو",
                "label_fr": "Ticengo"
            },
            {
                "value": 48911,
                "label": "Montafia",
                "label_ar": "المنتافية",
                "label_fr": "Montafia"
            },
            {
                "value": 48912,
                "label": "Ginosa",
                "label_ar": "جينوزا",
                "label_fr": "Ginosa"
            },
            {
                "value": 48913,
                "label": "Sassetta",
                "label_ar": "ساسيتا",
                "label_fr": "Sassetta"
            },
            {
                "value": 48914,
                "label": "Bussi sul Tirino",
                "label_ar": "بوسي سول تيرينو",
                "label_fr": "Bussi sul Tirino"
            },
            {
                "value": 48915,
                "label": "Ronciglione",
                "label_ar": "رونسيجليوني",
                "label_fr": "Ronciglione"
            },
            {
                "value": 48916,
                "label": "Vistrorio",
                "label_ar": "Vistrorio",
                "label_fr": "Vistrorio"
            },
            {
                "value": 48917,
                "label": "Cicagna",
                "label_ar": "سيكاجنا",
                "label_fr": "Cicagne"
            },
            {
                "value": 48918,
                "label": "Cesara",
                "label_ar": "سيزارا",
                "label_fr": "Cesara"
            },
            {
                "value": 48919,
                "label": "Fiuggi",
                "label_ar": "فيوجي",
                "label_fr": "Fiuggi"
            },
            {
                "value": 48920,
                "label": "Liveri",
                "label_ar": "ليفيري",
                "label_fr": "Liveri"
            },
            {
                "value": 48921,
                "label": "Torre di Ruggiero",
                "label_ar": "توري دي روجيرو",
                "label_fr": "Torre di Ruggiero"
            },
            {
                "value": 48922,
                "label": "Valganna",
                "label_ar": "فالجانا",
                "label_fr": "Valganna"
            },
            {
                "value": 48923,
                "label": "Vena di Maida",
                "label_ar": "فينا دي مايدا",
                "label_fr": "Vena di Maida"
            },
            {
                "value": 48924,
                "label": "Casa Bartolacelli-Stella",
                "label_ar": "كاسا بارتولاسيلي ستيلا",
                "label_fr": "Casa Bartolacelli-Stella"
            },
            {
                "value": 48925,
                "label": "Arcole",
                "label_ar": "أركول",
                "label_fr": "Arcole"
            },
            {
                "value": 48926,
                "label": "Piegaro",
                "label_ar": "بيغارو",
                "label_fr": "Piegaro"
            },
            {
                "value": 48927,
                "label": "Novalesa",
                "label_ar": "نوفاليسا",
                "label_fr": "Novalesa"
            },
            {
                "value": 48928,
                "label": "Torrile",
                "label_ar": "توريل",
                "label_fr": "Torrile"
            },
            {
                "value": 48929,
                "label": "Polverara",
                "label_ar": "بولفيرا",
                "label_fr": "Polverara"
            },
            {
                "value": 48930,
                "label": "Molino",
                "label_ar": "مولينو",
                "label_fr": "Molino"
            },
            {
                "value": 48931,
                "label": "Soresina",
                "label_ar": "سوريسينا",
                "label_fr": "Soresina"
            },
            {
                "value": 48932,
                "label": "Casalbuttano ed Uniti",
                "label_ar": "Casalbuttano ed Uniti",
                "label_fr": "Casalbuttano ed Uniti"
            },
            {
                "value": 48933,
                "label": "Cignone",
                "label_ar": "سيجنون",
                "label_fr": "Cignone"
            },
            {
                "value": 48934,
                "label": "Ripalta Cremasca",
                "label_ar": "ريبالتا كريماسكا",
                "label_fr": "Ripalta Cremasca"
            },
            {
                "value": 48935,
                "label": "Pescarolo ed Uniti",
                "label_ar": "بيسكارولو إد يونيتي",
                "label_fr": "Pescarolo ed Uniti"
            },
            {
                "value": 48936,
                "label": "Cappella de' Picenardi",
                "label_ar": "كابيلا دي بيكيناردي",
                "label_fr": "Cappella de 'Picenardi"
            },
            {
                "value": 48937,
                "label": "Garibaldi",
                "label_ar": "غاريبالدي",
                "label_fr": "Garibaldi"
            },
            {
                "value": 48938,
                "label": "Doria",
                "label_ar": "دوريا",
                "label_fr": "Doria"
            },
            {
                "value": 48939,
                "label": "Riese Pio X",
                "label_ar": "ريسي بيو العاشر",
                "label_fr": "Riese Pio X"
            },
            {
                "value": 48940,
                "label": "Giuliana",
                "label_ar": "جوليانا",
                "label_fr": "Giuliana"
            },
            {
                "value": 48941,
                "label": "Inverigo",
                "label_ar": "إنفيريجو",
                "label_fr": "Inverigo"
            },
            {
                "value": 48942,
                "label": "Cantalupo Ligure",
                "label_ar": "كانتالوبو ليغور",
                "label_fr": "Cantalupo Ligure"
            },
            {
                "value": 48943,
                "label": "Alice Castello",
                "label_ar": "أليس كاستيلو",
                "label_fr": "Alice Castello"
            },
            {
                "value": 48944,
                "label": "Pallanzeno",
                "label_ar": "بالانزينو",
                "label_fr": "Pallanzeno"
            },
            {
                "value": 48945,
                "label": "Briga Novarese",
                "label_ar": "بريجا نوفاريس",
                "label_fr": "Briga Novarese"
            },
            {
                "value": 48946,
                "label": "Garlate",
                "label_ar": "Garlate",
                "label_fr": "Garlate"
            },
            {
                "value": 48947,
                "label": "San Zenone al Lambro",
                "label_ar": "سان زينون اللامبرو",
                "label_fr": "San Zenone al Lambro"
            },
            {
                "value": 48948,
                "label": "Zocca",
                "label_ar": "زوكا",
                "label_fr": "Zocca"
            },
            {
                "value": 48949,
                "label": "Tessera",
                "label_ar": "تيسيرا",
                "label_fr": "Tessère"
            },
            {
                "value": 48950,
                "label": "Castellazzo Bormida",
                "label_ar": "كاستيلازو بورميدا",
                "label_fr": "Castellazzo Bormida"
            },
            {
                "value": 48951,
                "label": "Cremolino",
                "label_ar": "كريمولينو",
                "label_fr": "Crémolino"
            },
            {
                "value": 48952,
                "label": "Beura-Cardezza",
                "label_ar": "بيورا كارديزا",
                "label_fr": "Beura-Cardezza"
            },
            {
                "value": 48953,
                "label": "Pagazzano",
                "label_ar": "باجازانو",
                "label_fr": "Pagazzano"
            },
            {
                "value": 48954,
                "label": "Sanluri",
                "label_ar": "سانلوري",
                "label_fr": "Sanluri"
            },
            {
                "value": 48955,
                "label": "Lenna",
                "label_ar": "لينا",
                "label_fr": "Lenna"
            },
            {
                "value": 48956,
                "label": "Roccafranca",
                "label_ar": "روكافرانكا",
                "label_fr": "Roccafranca"
            },
            {
                "value": 48957,
                "label": "Villanterio",
                "label_ar": "فيلانتيريو",
                "label_fr": "Villanterio"
            },
            {
                "value": 48958,
                "label": "Casaletto Spartano",
                "label_ar": "كاساليتو سبارتانو",
                "label_fr": "Casaletto Spartano"
            },
            {
                "value": 48959,
                "label": "Montappone",
                "label_ar": "مونتابوني",
                "label_fr": "Montappone"
            },
            {
                "value": 48960,
                "label": "Belmonte Piceno",
                "label_ar": "بيلمونتي بيتشينو",
                "label_fr": "Belmonte Piceno"
            },
            {
                "value": 48961,
                "label": "Santa Maria",
                "label_ar": "سانتا ماريا",
                "label_fr": "Sainte Marie"
            },
            {
                "value": 48962,
                "label": "Vicchio",
                "label_ar": "فيتشيو",
                "label_fr": "Vicchio"
            },
            {
                "value": 48963,
                "label": "Ceppaloni",
                "label_ar": "سيبالوني",
                "label_fr": "Ceppaloni"
            },
            {
                "value": 48964,
                "label": "Gaggi",
                "label_ar": "جاجي",
                "label_fr": "Gaggi"
            },
            {
                "value": 48965,
                "label": "Isola del Gran Sasso",
                "label_ar": "ايزولا ديل جران ساسو",
                "label_fr": "Isola del Gran Sasso"
            },
            {
                "value": 48966,
                "label": "San Sossio Baronia",
                "label_ar": "سان سوسيو بارونيا",
                "label_fr": "San Sossio Baronia"
            },
            {
                "value": 48967,
                "label": "Montegrosso d'Asti",
                "label_ar": "مونتيغروسو داستي",
                "label_fr": "Montegrosso d'Asti"
            },
            {
                "value": 48968,
                "label": "Castel Bolognese",
                "label_ar": "قلعة بولونيز",
                "label_fr": "Castel Bolognese"
            },
            {
                "value": 48969,
                "label": "Roncone",
                "label_ar": "رونكوني",
                "label_fr": "Roncone"
            },
            {
                "value": 48970,
                "label": "Borgo San Giacomo",
                "label_ar": "بورجو سان جياكومو",
                "label_fr": "Borgo San Giacomo"
            },
            {
                "value": 48971,
                "label": "Montanaso Lombardo",
                "label_ar": "مونتاناسو لومباردو",
                "label_fr": "Montanaso Lombardo"
            },
            {
                "value": 48972,
                "label": "Algund",
                "label_ar": "ألغوند",
                "label_fr": "Algund"
            },
            {
                "value": 48973,
                "label": "Strada San Zeno",
                "label_ar": "سترادا سان زينو",
                "label_fr": "Strada San Zeno"
            },
            {
                "value": 48974,
                "label": "Pontelongo",
                "label_ar": "بونتيلونجو",
                "label_fr": "Pontelongo"
            },
            {
                "value": 48975,
                "label": "Bordighera",
                "label_ar": "بورديغيرا",
                "label_fr": "Bordighera"
            },
            {
                "value": 48976,
                "label": "Casarile",
                "label_ar": "كاساريلي",
                "label_fr": "Casarile"
            },
            {
                "value": 48977,
                "label": "Terricciola",
                "label_ar": "Terricciola",
                "label_fr": "Terricciola"
            },
            {
                "value": 48978,
                "label": "Remanzacco",
                "label_ar": "ريمانزاكو",
                "label_fr": "Remanzacco"
            },
            {
                "value": 48979,
                "label": "Casteltermini",
                "label_ar": "كاستيلترميني",
                "label_fr": "Casteltermini"
            },
            {
                "value": 48980,
                "label": "Castel del Piano",
                "label_ar": "كاستل ديل بيانو",
                "label_fr": "Castel del Piano"
            },
            {
                "value": 48981,
                "label": "Cropani",
                "label_ar": "كروباني",
                "label_fr": "Cropani"
            },
            {
                "value": 48982,
                "label": "Torrita di Siena",
                "label_ar": "توريتا دي سيينا",
                "label_fr": "Torrita di Siena"
            },
            {
                "value": 48983,
                "label": "Poppi",
                "label_ar": "بوبي",
                "label_fr": "Poppi"
            },
            {
                "value": 48984,
                "label": "Ocre",
                "label_ar": "أوكر",
                "label_fr": "Ocre"
            },
            {
                "value": 48985,
                "label": "Castiglione di Cervia",
                "label_ar": "كاستيجليون دي سيرفيا",
                "label_fr": "Castiglione di Cervia"
            },
            {
                "value": 48986,
                "label": "Paciano",
                "label_ar": "باتشيانو",
                "label_fr": "Paciano"
            },
            {
                "value": 48987,
                "label": "San Marzano sul Sarno",
                "label_ar": "سان مارزانو سول سارنو",
                "label_fr": "San Marzano sul Sarno"
            },
            {
                "value": 48988,
                "label": "Carentino",
                "label_ar": "كارنتينو",
                "label_fr": "Carentino"
            },
            {
                "value": 48989,
                "label": "Madignano",
                "label_ar": "مادينيانو",
                "label_fr": "Madignano"
            },
            {
                "value": 48990,
                "label": "San Lazzaro degli Armeni",
                "label_ar": "سان لازارو ديجلي أرميني",
                "label_fr": "San Lazzaro degli Armeni"
            },
            {
                "value": 48991,
                "label": "Morter",
                "label_ar": "مورتر",
                "label_fr": "Morter"
            },
            {
                "value": 48992,
                "label": "Taino",
                "label_ar": "تاينو",
                "label_fr": "Taino"
            },
            {
                "value": 48993,
                "label": "Biandronno",
                "label_ar": "Biandronno",
                "label_fr": "Biandronno"
            },
            {
                "value": 48994,
                "label": "Lavena Ponte Tresa",
                "label_ar": "لافينا بونتي تريزا",
                "label_fr": "Lavena Ponte Tresa"
            },
            {
                "value": 48995,
                "label": "Felegara",
                "label_ar": "فيليغارا",
                "label_fr": "Felegara"
            },
            {
                "value": 48996,
                "label": "Campogalliano",
                "label_ar": "كامبوغاليانو",
                "label_fr": "Campogalliano"
            },
            {
                "value": 48997,
                "label": "Villa",
                "label_ar": "فيلا",
                "label_fr": "Villa"
            },
            {
                "value": 48998,
                "label": "Casalserugo",
                "label_ar": "كاسالسيروجو",
                "label_fr": "Casalserugo"
            },
            {
                "value": 48999,
                "label": "Piombino Dese",
                "label_ar": "بيومبينو ديزي",
                "label_fr": "Piombino Dese"
            },
            {
                "value": 49000,
                "label": "Pecetto",
                "label_ar": "بيسيتو",
                "label_fr": "Pecetto"
            },
            {
                "value": 49001,
                "label": "Venaus",
                "label_ar": "فيناوس",
                "label_fr": "Venaus"
            },
            {
                "value": 49002,
                "label": "Strigno",
                "label_ar": "سترينو",
                "label_fr": "Strigno"
            },
            {
                "value": 49003,
                "label": "Raldon",
                "label_ar": "رالدون",
                "label_fr": "Raldon"
            },
            {
                "value": 49004,
                "label": "Campodenno",
                "label_ar": "كامبودينو",
                "label_fr": "Campodenno"
            },
            {
                "value": 49005,
                "label": "Guspini",
                "label_ar": "جوسبيني",
                "label_fr": "Guspini"
            },
            {
                "value": 49006,
                "label": "Montemaggiore al Metauro",
                "label_ar": "Montemaggiore al Metauro",
                "label_fr": "Montemaggiore al Metauro"
            },
            {
                "value": 49007,
                "label": "Assoro",
                "label_ar": "أسورو",
                "label_fr": "Assoro"
            },
            {
                "value": 49008,
                "label": "Fornaci",
                "label_ar": "فورناسي",
                "label_fr": "Fornaci"
            },
            {
                "value": 49009,
                "label": "Curinga",
                "label_ar": "كورينجا",
                "label_fr": "Curinga"
            },
            {
                "value": 49010,
                "label": "Montalcino",
                "label_ar": "مونتالسينو",
                "label_fr": "Montalcino"
            },
            {
                "value": 49011,
                "label": "Pago Veiano",
                "label_ar": "باجو فيانو",
                "label_fr": "Pago Veiano"
            },
            {
                "value": 49012,
                "label": "Campobello di Mazara",
                "label_ar": "كامبوبيلو دي مازارا",
                "label_fr": "Campobello di Mazara"
            },
            {
                "value": 49013,
                "label": "Catignano",
                "label_ar": "كاتينيانو",
                "label_fr": "Catignano"
            },
            {
                "value": 49014,
                "label": "Oriolo Romano",
                "label_ar": "اوريولو رومانو",
                "label_fr": "Oriolo Romano"
            },
            {
                "value": 49015,
                "label": "Tuscania",
                "label_ar": "توسكانيا",
                "label_fr": "Tuscania"
            },
            {
                "value": 49016,
                "label": "Roviano",
                "label_ar": "روفيانو",
                "label_fr": "Roviano"
            },
            {
                "value": 49017,
                "label": "Stimigliano",
                "label_ar": "ستيميجليانو",
                "label_fr": "Stimigliano"
            },
            {
                "value": 49018,
                "label": "Erve",
                "label_ar": "إرف",
                "label_fr": "Erve"
            },
            {
                "value": 49019,
                "label": "Cividate Camuno",
                "label_ar": "Cividate Camuno",
                "label_fr": "Cividate Camuno"
            },
            {
                "value": 49020,
                "label": "Settimo Vittone",
                "label_ar": "سيتيمو فيتوني",
                "label_fr": "Settimo Vittone"
            },
            {
                "value": 49021,
                "label": "Lallio",
                "label_ar": "لاليو",
                "label_fr": "Lallio"
            },
            {
                "value": 49022,
                "label": "Leffe",
                "label_ar": "ليف",
                "label_fr": "Leffe"
            },
            {
                "value": 49023,
                "label": "Salorno",
                "label_ar": "سالورنو",
                "label_fr": "Salorno"
            },
            {
                "value": 49024,
                "label": "Canneto sull'Oglio",
                "label_ar": "كانيتو سول اوليو",
                "label_fr": "Canneto sull'Oglio"
            },
            {
                "value": 49025,
                "label": "Cornedo Vicentino",
                "label_ar": "كورنيو فيسينتينو",
                "label_fr": "Cornedo Vicentino"
            },
            {
                "value": 49026,
                "label": "Sillavengo",
                "label_ar": "سيلافينجو",
                "label_fr": "Sillavengo"
            },
            {
                "value": 49027,
                "label": "Barbarano Vicentino",
                "label_ar": "باربارانو فيسينتينو",
                "label_fr": "Barbarano Vicentino"
            },
            {
                "value": 49028,
                "label": "Grantorto",
                "label_ar": "جرانتورتو",
                "label_fr": "Grantorto"
            },
            {
                "value": 49029,
                "label": "Eraclea",
                "label_ar": "إيراكليا",
                "label_fr": "Eraclea"
            },
            {
                "value": 49030,
                "label": "La Maddalena",
                "label_ar": "لا مادالينا",
                "label_fr": "La Maddalena"
            },
            {
                "value": 49031,
                "label": "Frascineto",
                "label_ar": "فراسسينيتو",
                "label_fr": "Frascineto"
            },
            {
                "value": 49032,
                "label": "Tolve",
                "label_ar": "حل",
                "label_fr": "Tolve"
            },
            {
                "value": 49033,
                "label": "Santa Maria",
                "label_ar": "سانتا ماريا",
                "label_fr": "Sainte Marie"
            },
            {
                "value": 49034,
                "label": "Scerni",
                "label_ar": "شيرني",
                "label_fr": "Scerni"
            },
            {
                "value": 49035,
                "label": "Meldola",
                "label_ar": "ميلدولا",
                "label_fr": "Meldola"
            },
            {
                "value": 49036,
                "label": "Cori",
                "label_ar": "كوري",
                "label_fr": "Cori"
            },
            {
                "value": 49037,
                "label": "Calcinate",
                "label_ar": "تكلس",
                "label_fr": "Calciner"
            },
            {
                "value": 49038,
                "label": "Foresto",
                "label_ar": "فورستو",
                "label_fr": "Foresto"
            },
            {
                "value": 49039,
                "label": "Marcaria",
                "label_ar": "ماركاريا",
                "label_fr": "Marcaria"
            },
            {
                "value": 49040,
                "label": "Seniga",
                "label_ar": "سينيغا",
                "label_fr": "Seniga"
            },
            {
                "value": 49041,
                "label": "Castellucchio",
                "label_ar": "كاستلوتشيو",
                "label_fr": "Castellucchio"
            },
            {
                "value": 49042,
                "label": "Costa Volpino",
                "label_ar": "كوستا فولبينو",
                "label_fr": "Costa Volpino"
            },
            {
                "value": 49043,
                "label": "Bellano",
                "label_ar": "بيلانو",
                "label_fr": "Bellano"
            },
            {
                "value": 49044,
                "label": "Viscone",
                "label_ar": "فيسكون",
                "label_fr": "Viscone"
            },
            {
                "value": 49045,
                "label": "Ossago Lodigiano",
                "label_ar": "أوساغو لوديجيانو",
                "label_fr": "Ossago Lodigiano"
            },
            {
                "value": 49046,
                "label": "Carpineti",
                "label_ar": "كاربينيتي",
                "label_fr": "Carpineti"
            },
            {
                "value": 49047,
                "label": "Piazzola sul Brenta",
                "label_ar": "بيازولا سول برينتا",
                "label_fr": "Piazzola sul Brenta"
            },
            {
                "value": 49048,
                "label": "Terzorio",
                "label_ar": "تيرزوريو",
                "label_fr": "Terzorio"
            },
            {
                "value": 49049,
                "label": "Dego",
                "label_ar": "ديجو",
                "label_fr": "Dego"
            },
            {
                "value": 49050,
                "label": "Dolceacqua",
                "label_ar": "دولسياكوا",
                "label_fr": "Dolceacqua"
            },
            {
                "value": 49051,
                "label": "Bardonecchia",
                "label_ar": "باردونيكيا",
                "label_fr": "Bardonecchia"
            },
            {
                "value": 49052,
                "label": "Crevacuore",
                "label_ar": "كريفاكور",
                "label_fr": "Crevacuore"
            },
            {
                "value": 49053,
                "label": "Pollein",
                "label_ar": "بولين",
                "label_fr": "Pollein"
            },
            {
                "value": 49054,
                "label": "Sgonico",
                "label_ar": "Sgonico",
                "label_fr": "Sgonico"
            },
            {
                "value": 49055,
                "label": "Sutrio",
                "label_ar": "سوتريو",
                "label_fr": "Sutrio"
            },
            {
                "value": 49056,
                "label": "Lazise",
                "label_ar": "Lazise",
                "label_fr": "Lazise"
            },
            {
                "value": 49057,
                "label": "Appignano",
                "label_ar": "أبينيانو",
                "label_fr": "Appignano"
            },
            {
                "value": 49058,
                "label": "San Lorenzo in Campo",
                "label_ar": "سان لورينزو في كامبو",
                "label_fr": "San Lorenzo à Campo"
            },
            {
                "value": 49059,
                "label": "Case Catania",
                "label_ar": "حالة كاتانيا",
                "label_fr": "Cas Catane"
            },
            {
                "value": 49060,
                "label": "Sant'Arcangelo",
                "label_ar": "سانت اركانجيلو",
                "label_fr": "Sant'Arcangelo"
            },
            {
                "value": 49061,
                "label": "Coppito",
                "label_ar": "كوبيتو",
                "label_fr": "Coppito"
            },
            {
                "value": 49062,
                "label": "Stia",
                "label_ar": "ستيا",
                "label_fr": "Stia"
            },
            {
                "value": 49063,
                "label": "San Zaccaria",
                "label_ar": "سان زكريا",
                "label_fr": "San Zaccaria"
            },
            {
                "value": 49064,
                "label": "Castellina Marittima",
                "label_ar": "كاستيلينا ماريتيما",
                "label_fr": "Castellina Marittima"
            },
            {
                "value": 49065,
                "label": "Vallerano",
                "label_ar": "فاليرانو",
                "label_fr": "Vallerano"
            },
            {
                "value": 49066,
                "label": "San Donato",
                "label_ar": "سان دوناتو",
                "label_fr": "San Donato"
            },
            {
                "value": 49067,
                "label": "Monsano",
                "label_ar": "مونسانو",
                "label_fr": "Monsano"
            },
            {
                "value": 49068,
                "label": "Gradara",
                "label_ar": "جرادارا",
                "label_fr": "Gradara"
            },
            {
                "value": 49069,
                "label": "San Benedetto Po",
                "label_ar": "سان بينيديتو بو",
                "label_fr": "San Benedetto Po"
            },
            {
                "value": 49070,
                "label": "Gerenzago",
                "label_ar": "Gerenzago",
                "label_fr": "Gerenzago"
            },
            {
                "value": 49071,
                "label": "Chiarano",
                "label_ar": "كيارانو",
                "label_fr": "Chiarano"
            },
            {
                "value": 49072,
                "label": "Maccagno",
                "label_ar": "ماكاغنو",
                "label_fr": "Maccagno"
            },
            {
                "value": 49073,
                "label": "Corteolona",
                "label_ar": "كورتيولونا",
                "label_fr": "Corteolona"
            },
            {
                "value": 49074,
                "label": "Fiesso Umbertiano",
                "label_ar": "فيسو أومبرتيانو",
                "label_fr": "Fiesso Umbertiano"
            },
            {
                "value": 49075,
                "label": "Deiva Marina",
                "label_ar": "ديفا مارينا",
                "label_fr": "Deiva Marina"
            },
            {
                "value": 49076,
                "label": "Terrarossa",
                "label_ar": "تيراروسا",
                "label_fr": "Terrarossa"
            },
            {
                "value": 49077,
                "label": "Premariacco",
                "label_ar": "بريماريكو",
                "label_fr": "Premariacco"
            },
            {
                "value": 49078,
                "label": "Bianconese",
                "label_ar": "بيانكونيز",
                "label_fr": "Bianconais"
            },
            {
                "value": 49079,
                "label": "Basaldella",
                "label_ar": "Basaldella",
                "label_fr": "Basaldella"
            },
            {
                "value": 49080,
                "label": "Lamon",
                "label_ar": "لامون",
                "label_fr": "Lamon"
            },
            {
                "value": 49081,
                "label": "Settimo San Pietro",
                "label_ar": "سيتيمو سان بيترو",
                "label_fr": "Settimo San Pietro"
            },
            {
                "value": 49082,
                "label": "Escolca",
                "label_ar": "اسكولكا",
                "label_fr": "Escolca"
            },
            {
                "value": 49083,
                "label": "Santo Stefano di Rogliano",
                "label_ar": "سانتو ستيفانو دي روجليانو",
                "label_fr": "Santo Stefano di Rogliano"
            },
            {
                "value": 49084,
                "label": "Sersale",
                "label_ar": "سيرسيل",
                "label_fr": "Vente"
            },
            {
                "value": 49085,
                "label": "Croce",
                "label_ar": "كروس",
                "label_fr": "Croce"
            },
            {
                "value": 49086,
                "label": "Sarteano",
                "label_ar": "سارتينو",
                "label_fr": "Sarteano"
            },
            {
                "value": 49087,
                "label": "Prata Sannita",
                "label_ar": "براتا سانيتا",
                "label_fr": "Prata Sannita"
            },
            {
                "value": 49088,
                "label": "Mas",
                "label_ar": "ماس",
                "label_fr": "Mas"
            },
            {
                "value": 49089,
                "label": "Giuliano Teatino",
                "label_ar": "جوليانو تياتينو",
                "label_fr": "Giuliano Teatino"
            },
            {
                "value": 49090,
                "label": "Larciano",
                "label_ar": "لارسيانو",
                "label_fr": "Larciano"
            },
            {
                "value": 49091,
                "label": "Gagliano del Capo",
                "label_ar": "جاجليانو ديل كابو",
                "label_fr": "Gagliano del Capo"
            },
            {
                "value": 49092,
                "label": "Faggiano",
                "label_ar": "فاجيانو",
                "label_fr": "Faggiano"
            },
            {
                "value": 49093,
                "label": "Alezio",
                "label_ar": "أليزيو",
                "label_fr": "Alezio"
            },
            {
                "value": 49094,
                "label": "Carvico",
                "label_ar": "كارفيكو",
                "label_fr": "Carvico"
            },
            {
                "value": 49095,
                "label": "Capaccio",
                "label_ar": "كاباشيو",
                "label_fr": "Capaccio"
            },
            {
                "value": 49096,
                "label": "Offlaga",
                "label_ar": "أوفلاجا",
                "label_fr": "Offlaga"
            },
            {
                "value": 49097,
                "label": "Pacentro",
                "label_ar": "باسنترو",
                "label_fr": "Pacentro"
            },
            {
                "value": 49098,
                "label": "Fosdinovo",
                "label_ar": "فوسدينوفو",
                "label_fr": "Fosdinovo"
            },
            {
                "value": 49099,
                "label": "Cavenago d'Adda",
                "label_ar": "كافيناغو دادا",
                "label_fr": "Cavenago d'Adda"
            },
            {
                "value": 49100,
                "label": "Paratico",
                "label_ar": "باراتيكو",
                "label_fr": "Paratico"
            },
            {
                "value": 49101,
                "label": "Torriana",
                "label_ar": "توريانا",
                "label_fr": "Torriana"
            },
            {
                "value": 49102,
                "label": "Lesa",
                "label_ar": "ليسا",
                "label_fr": "Lesa"
            },
            {
                "value": 49103,
                "label": "Cavour",
                "label_ar": "كافور",
                "label_fr": "Cavour"
            },
            {
                "value": 49104,
                "label": "Tarvisio",
                "label_ar": "تارفيسيو",
                "label_fr": "Tarvisio"
            },
            {
                "value": 49105,
                "label": "Pinzano al Tagliamento",
                "label_ar": "بينزانو تاليامنتو",
                "label_fr": "Pinzano al Tagliamento"
            },
            {
                "value": 49106,
                "label": "Pollenza",
                "label_ar": "بولينزا",
                "label_fr": "Pollenza"
            },
            {
                "value": 49107,
                "label": "Montefortino",
                "label_ar": "مونتيفورتينو",
                "label_fr": "Montefortino"
            },
            {
                "value": 49108,
                "label": "Levane",
                "label_ar": "ليفان",
                "label_fr": "Levane"
            },
            {
                "value": 49109,
                "label": "Castignano",
                "label_ar": "كاستينيانو",
                "label_fr": "Castignano"
            },
            {
                "value": 49110,
                "label": "Valle Agricola",
                "label_ar": "فالي أجريكولا",
                "label_fr": "Valle Agricola"
            },
            {
                "value": 49111,
                "label": "Vallata",
                "label_ar": "فالاتا",
                "label_fr": "Vallata"
            },
            {
                "value": 49112,
                "label": "Sala Baganza",
                "label_ar": "سالا باغانزا",
                "label_fr": "Sala Baganza"
            },
            {
                "value": 49113,
                "label": "Campegine",
                "label_ar": "كامبيجين",
                "label_fr": "Campegine"
            },
            {
                "value": 49114,
                "label": "Bucine",
                "label_ar": "بوسين",
                "label_fr": "Bucine"
            },
            {
                "value": 49115,
                "label": "Guagnano",
                "label_ar": "Guagnano",
                "label_fr": "Guagnano"
            },
            {
                "value": 49116,
                "label": "Roccadaspide",
                "label_ar": "روكاداسبيد",
                "label_fr": "Roccadaspide"
            },
            {
                "value": 49117,
                "label": "Galliera Veneta",
                "label_ar": "جاليرا فينيتا",
                "label_fr": "Galliera Veneta"
            },
            {
                "value": 49118,
                "label": "Sant'Agostino",
                "label_ar": "سانت أغوستينو",
                "label_fr": "Sant'Agostino"
            },
            {
                "value": 49119,
                "label": "Mazzo di Valtellina",
                "label_ar": "ماتزو دي فالتيلينا",
                "label_fr": "Mazzo di Valtellina"
            },
            {
                "value": 49120,
                "label": "Villasor",
                "label_ar": "فيلاسور",
                "label_fr": "Villas ou"
            },
            {
                "value": 49121,
                "label": "Paulilatino",
                "label_ar": "باوليلاتينو",
                "label_fr": "Paulilatino"
            },
            {
                "value": 49122,
                "label": "Gabbro",
                "label_ar": "جابرو",
                "label_fr": "Gabbro"
            },
            {
                "value": 49123,
                "label": "Sindia",
                "label_ar": "السنديا",
                "label_fr": "Sindia"
            },
            {
                "value": 49124,
                "label": "Regina Margherita",
                "label_ar": "ريجينا مارغريتا",
                "label_fr": "Regina Margherita"
            },
            {
                "value": 49125,
                "label": "Flussio",
                "label_ar": "فلوسيو",
                "label_fr": "Flussio"
            },
            {
                "value": 49126,
                "label": "Gavoi",
                "label_ar": "جافوي",
                "label_fr": "Gavoi"
            },
            {
                "value": 49127,
                "label": "Santo Stefano Belbo",
                "label_ar": "سانتو ستيفانو بيلبو",
                "label_fr": "Santo Stefano Belbo"
            },
            {
                "value": 49128,
                "label": "Migliaro",
                "label_ar": "ميليارو",
                "label_fr": "Migliaro"
            },
            {
                "value": 49129,
                "label": "Vo",
                "label_ar": "Vo",
                "label_fr": "Vo"
            },
            {
                "value": 49130,
                "label": "Sorisole",
                "label_ar": "سوريسول",
                "label_fr": "Sorisole"
            },
            {
                "value": 49131,
                "label": "Manziana",
                "label_ar": "مانزيانا",
                "label_fr": "Manziana"
            },
            {
                "value": 49132,
                "label": "Valentano",
                "label_ar": "فالنتينو",
                "label_fr": "Valentano"
            },
            {
                "value": 49133,
                "label": "Guardamiglio",
                "label_ar": "Guardamiglio",
                "label_fr": "Guardamiglio"
            },
            {
                "value": 49134,
                "label": "Bettola",
                "label_ar": "بتولا",
                "label_fr": "Bettola"
            },
            {
                "value": 49135,
                "label": "Perinaldo",
                "label_ar": "برينالدو",
                "label_fr": "Perinaldo"
            },
            {
                "value": 49136,
                "label": "Brosso",
                "label_ar": "بروسو",
                "label_fr": "Brosso"
            },
            {
                "value": 49137,
                "label": "Codevigo",
                "label_ar": "كودفيجو",
                "label_fr": "Codevigo"
            },
            {
                "value": 49138,
                "label": "Fanna",
                "label_ar": "فانا",
                "label_fr": "Fanna"
            },
            {
                "value": 49139,
                "label": "Tuili",
                "label_ar": "تويلي",
                "label_fr": "Tuili"
            },
            {
                "value": 49140,
                "label": "Pratovecchio",
                "label_ar": "براتوفيتشيو",
                "label_fr": "Pratovecchio"
            },
            {
                "value": 49141,
                "label": "Vignanello",
                "label_ar": "فيجنانيلو",
                "label_fr": "Vignanello"
            },
            {
                "value": 49142,
                "label": "Massa d'Albe",
                "label_ar": "ماسا دالب",
                "label_fr": "Massa d'Albe"
            },
            {
                "value": 49143,
                "label": "Montenero di Bisaccia",
                "label_ar": "مونتينيرو دي بيساشيا",
                "label_fr": "Montenero di Bisaccia"
            },
            {
                "value": 49144,
                "label": "Panicale",
                "label_ar": "بانيكال",
                "label_fr": "Panicale"
            },
            {
                "value": 49145,
                "label": "Cupramontana",
                "label_ar": "كوبرامونتانا",
                "label_fr": "Cupramontana"
            },
            {
                "value": 49146,
                "label": "Cagli",
                "label_ar": "كالي",
                "label_fr": "Cagli"
            },
            {
                "value": 49147,
                "label": "Vallombrosa",
                "label_ar": "فالومبروسا",
                "label_fr": "Vallombrosa"
            },
            {
                "value": 49148,
                "label": "Tavarnelle Val di Pesa",
                "label_ar": "تافارنيل فال دي بيسا",
                "label_fr": "Tavarnelle Val di Pesa"
            },
            {
                "value": 49149,
                "label": "Massa",
                "label_ar": "ماسا",
                "label_fr": "Massa"
            },
            {
                "value": 49150,
                "label": "Borrello",
                "label_ar": "بوريلو",
                "label_fr": "Borrello"
            },
            {
                "value": 49151,
                "label": "Avigliano Umbro",
                "label_ar": "أفيجليانو أمبرو",
                "label_fr": "Avigliano Umbro"
            },
            {
                "value": 49152,
                "label": "Ravi",
                "label_ar": "رافي",
                "label_fr": "Ravi"
            },
            {
                "value": 49153,
                "label": "Ortelle",
                "label_ar": "أورتيل",
                "label_fr": "Ortelle"
            },
            {
                "value": 49154,
                "label": "Gordona",
                "label_ar": "جوردونا",
                "label_fr": "Gordona"
            },
            {
                "value": 49155,
                "label": "Esine",
                "label_ar": "Esine",
                "label_fr": "Esine"
            },
            {
                "value": 49156,
                "label": "Arcola",
                "label_ar": "اركولا",
                "label_fr": "Arcola"
            },
            {
                "value": 49157,
                "label": "Pellegrino Parmense",
                "label_ar": "بيليجرينو بارمينسي",
                "label_fr": "Pellegrino Parmense"
            },
            {
                "value": 49158,
                "label": "Campodoro",
                "label_ar": "كامبودورو",
                "label_fr": "Campodoro"
            },
            {
                "value": 49159,
                "label": "Villa",
                "label_ar": "فيلا",
                "label_fr": "Villa"
            },
            {
                "value": 49160,
                "label": "Vergato",
                "label_ar": "فيرغاتو",
                "label_fr": "Vergato"
            },
            {
                "value": 49161,
                "label": "Settimo",
                "label_ar": "سيتيمو",
                "label_fr": "Settimo"
            },
            {
                "value": 49162,
                "label": "Flaibano",
                "label_ar": "فليبانو",
                "label_fr": "Flaibano"
            },
            {
                "value": 49163,
                "label": "Folgaria",
                "label_ar": "فولاريا",
                "label_fr": "Folgaria"
            },
            {
                "value": 49164,
                "label": "Boffalora sopra Ticino",
                "label_ar": "بوفالورا سوبرا تيتشينو",
                "label_fr": "Boffalora sopra Tessin"
            },
            {
                "value": 49165,
                "label": "Albizzate",
                "label_ar": "البيزات",
                "label_fr": "Albizzate"
            },
            {
                "value": 49166,
                "label": "Monticiano",
                "label_ar": "مونتيسيانو",
                "label_fr": "Monticiano"
            },
            {
                "value": 49167,
                "label": "Castel Gandolfo",
                "label_ar": "كاستل جاندولفو",
                "label_fr": "Castel Gandolfo"
            },
            {
                "value": 49168,
                "label": "Envie",
                "label_ar": "الحسد",
                "label_fr": "Envie"
            },
            {
                "value": 49169,
                "label": "Busseto",
                "label_ar": "بوسيتو",
                "label_fr": "Busseto"
            },
            {
                "value": 49170,
                "label": "Virgilio",
                "label_ar": "فيرجيليو",
                "label_fr": "Virgilio"
            },
            {
                "value": 49171,
                "label": "Sëlva",
                "label_ar": "سيلفا",
                "label_fr": "Sëlva"
            },
            {
                "value": 49172,
                "label": "Schiavon",
                "label_ar": "شيافون",
                "label_fr": "Schiavon"
            },
            {
                "value": 49173,
                "label": "Motta San Guglielmo",
                "label_ar": "موتا سان جوجليلمو",
                "label_fr": "Motta San Guglielmo"
            },
            {
                "value": 49174,
                "label": "Baia",
                "label_ar": "بايا",
                "label_fr": "Baia"
            },
            {
                "value": 49175,
                "label": "Gazzo",
                "label_ar": "جازو",
                "label_fr": "Gazzo"
            },
            {
                "value": 49176,
                "label": "Melara",
                "label_ar": "ميلارا",
                "label_fr": "Melara"
            },
            {
                "value": 49177,
                "label": "Dossobuono",
                "label_ar": "دوسوبونو",
                "label_fr": "Dossobuono"
            },
            {
                "value": 49178,
                "label": "Neviano degli Arduini",
                "label_ar": "نيفيانو ديجلي اردويني",
                "label_fr": "Neviano degli Arduini"
            },
            {
                "value": 49179,
                "label": "Compiano",
                "label_ar": "كومبيانو",
                "label_fr": "Compiano"
            },
            {
                "value": 49180,
                "label": "Piano",
                "label_ar": "بيانو",
                "label_fr": "Piano"
            },
            {
                "value": 49181,
                "label": "Neirone",
                "label_ar": "نيرون",
                "label_fr": "Neirone"
            },
            {
                "value": 49182,
                "label": "Osiglia",
                "label_ar": "أوسيجليا",
                "label_fr": "Osiglia"
            },
            {
                "value": 49183,
                "label": "Borghetto di Vara",
                "label_ar": "بورغيتو دي فارا",
                "label_fr": "Borghetto di Vara"
            },
            {
                "value": 49184,
                "label": "Caravonica",
                "label_ar": "كارافونيكا",
                "label_fr": "Caravonica"
            },
            {
                "value": 49185,
                "label": "Localita della Chiesa",
                "label_ar": "لوكاليتا ديلا كييزا",
                "label_fr": "Localita della Chiesa"
            },
            {
                "value": 49186,
                "label": "San Bartolomeo",
                "label_ar": "سان بارتولوميو",
                "label_fr": "San Bartolomeo"
            },
            {
                "value": 49187,
                "label": "Diano San Pietro",
                "label_ar": "ديانو سان بيترو",
                "label_fr": "Diano San Pietro"
            },
            {
                "value": 49188,
                "label": "Lacco Ameno",
                "label_ar": "لاكو امينو",
                "label_fr": "Lacco Ameno"
            },
            {
                "value": 49189,
                "label": "Arborio",
                "label_ar": "أربوريو",
                "label_fr": "Arborio"
            },
            {
                "value": 49190,
                "label": "Cambiano",
                "label_ar": "كامبيانو",
                "label_fr": "Cambiano"
            },
            {
                "value": 49191,
                "label": "Cerredolo",
                "label_ar": "سيردولو",
                "label_fr": "Cerredolo"
            },
            {
                "value": 49192,
                "label": "Pian di Scò",
                "label_ar": "بيان دي سكو",
                "label_fr": "Pian di Scò"
            },
            {
                "value": 49193,
                "label": "Rassina",
                "label_ar": "راسينا",
                "label_fr": "Rassina"
            },
            {
                "value": 49194,
                "label": "Chitignano",
                "label_ar": "شيتيغنانو",
                "label_fr": "Chitignano"
            },
            {
                "value": 49195,
                "label": "Altavilla",
                "label_ar": "ألتافيلا",
                "label_fr": "Altavilla"
            },
            {
                "value": 49196,
                "label": "Buttigliera Alta",
                "label_ar": "بوتيجلييرا ألتا",
                "label_fr": "Buttigliera Alta"
            },
            {
                "value": 49197,
                "label": "San Giovanni",
                "label_ar": "سان جيوفاني",
                "label_fr": "San Giovanni"
            },
            {
                "value": 49198,
                "label": "Coredo",
                "label_ar": "كوريدو",
                "label_fr": "Coredo"
            },
            {
                "value": 49199,
                "label": "Pannarano",
                "label_ar": "بانارانو",
                "label_fr": "Pannarano"
            },
            {
                "value": 49200,
                "label": "Francolise",
                "label_ar": "فرانكوليس",
                "label_fr": "Francolise"
            },
            {
                "value": 49201,
                "label": "San Cesario sul Panaro",
                "label_ar": "سان سيزاريو سول بانارو",
                "label_fr": "San Cesario sul Panaro"
            },
            {
                "value": 49202,
                "label": "Salvaterra",
                "label_ar": "سالفاتيرا",
                "label_fr": "Salvaterra"
            },
            {
                "value": 49203,
                "label": "Corvara",
                "label_ar": "كورفارا",
                "label_fr": "Corvara"
            },
            {
                "value": 49204,
                "label": "Deutschnofen",
                "label_ar": "دويتشنوفن",
                "label_fr": "Deutschnofen"
            },
            {
                "value": 49205,
                "label": "Marche",
                "label_ar": "ماركي",
                "label_fr": "Marche"
            },
            {
                "value": 49206,
                "label": "Frattocchie",
                "label_ar": "فراتوكي",
                "label_fr": "Frattocchie"
            },
            {
                "value": 49207,
                "label": "Villa Minozzo",
                "label_ar": "فيلا مينوزو",
                "label_fr": "Villa Minozzo"
            },
            {
                "value": 49208,
                "label": "Gonnosfanadiga",
                "label_ar": "Gonnosfanadiga",
                "label_fr": "Gonnosfanadiga"
            },
            {
                "value": 49209,
                "label": "Arborea",
                "label_ar": "أربوريا",
                "label_fr": "Arborea"
            },
            {
                "value": 49210,
                "label": "Albaredo",
                "label_ar": "الباريدو",
                "label_fr": "Albaredo"
            },
            {
                "value": 49211,
                "label": "Saint-Pierre",
                "label_ar": "سان بيير",
                "label_fr": "Saint-Pierre"
            },
            {
                "value": 49212,
                "label": "Istrana",
                "label_ar": "استراحة",
                "label_fr": "Istrana"
            },
            {
                "value": 49213,
                "label": "Pont-Saint-Martin",
                "label_ar": "بونت سانت مارتن",
                "label_fr": "Pont-Saint-Martin"
            },
            {
                "value": 49214,
                "label": "Castiglione",
                "label_ar": "كاستيجليون",
                "label_fr": "Castiglione"
            },
            {
                "value": 49215,
                "label": "Lavello",
                "label_ar": "لافيلو",
                "label_fr": "Lavello"
            },
            {
                "value": 49216,
                "label": "Uzzano",
                "label_ar": "عوزانو",
                "label_fr": "Uzzano"
            },
            {
                "value": 49217,
                "label": "Montale",
                "label_ar": "مونتال",
                "label_fr": "Montale"
            },
            {
                "value": 49218,
                "label": "Tribiano",
                "label_ar": "تريبيانو",
                "label_fr": "Tribiano"
            },
            {
                "value": 49219,
                "label": "Simaxis",
                "label_ar": "سيماكسيس",
                "label_fr": "Simaxis"
            },
            {
                "value": 49220,
                "label": "Codrongianos",
                "label_ar": "كودرونجيانوس",
                "label_fr": "Codrongianos"
            },
            {
                "value": 49221,
                "label": "Isili",
                "label_ar": "إيسيلي",
                "label_fr": "Isili"
            },
            {
                "value": 49222,
                "label": "Siapiccia",
                "label_ar": "سيابكيا",
                "label_fr": "Siapiccia"
            },
            {
                "value": 49223,
                "label": "Orsenigo",
                "label_ar": "أورسينيجو",
                "label_fr": "Orsenigo"
            },
            {
                "value": 49224,
                "label": "Castello",
                "label_ar": "كاستيلو",
                "label_fr": "Castello"
            },
            {
                "value": 49225,
                "label": "Nave",
                "label_ar": "صحن الكنيسة",
                "label_fr": "Nef"
            },
            {
                "value": 49226,
                "label": "Tione di Trento",
                "label_ar": "تيوني دي ترينتو",
                "label_fr": "Tione di Trento"
            },
            {
                "value": 49227,
                "label": "San Lorenzo del Vallo",
                "label_ar": "سان لورينزو ديل فالو",
                "label_fr": "San Lorenzo del Vallo"
            },
            {
                "value": 49228,
                "label": "Caulonia",
                "label_ar": "Caulonia",
                "label_fr": "Caulonia"
            },
            {
                "value": 49229,
                "label": "Vidigulfo",
                "label_ar": "فيديجولفو",
                "label_fr": "Vidigulfo"
            },
            {
                "value": 49230,
                "label": "Fontanelle",
                "label_ar": "فونتانيلي",
                "label_fr": "Fontanelle"
            },
            {
                "value": 49231,
                "label": "Santa Barbara",
                "label_ar": "سانتا باربارا",
                "label_fr": "Santa Barbara"
            },
            {
                "value": 49232,
                "label": "Santi",
                "label_ar": "سانتي",
                "label_fr": "Santi"
            },
            {
                "value": 49233,
                "label": "Selvino",
                "label_ar": "سيلفينو",
                "label_fr": "Selvino"
            },
            {
                "value": 49234,
                "label": "San Martino Canavese",
                "label_ar": "سان مارتينو كانافيزي",
                "label_fr": "San Martino Canavese"
            },
            {
                "value": 49235,
                "label": "Brebbia",
                "label_ar": "بريبيا",
                "label_fr": "Brebbia"
            },
            {
                "value": 49236,
                "label": "Tenno",
                "label_ar": "تينو",
                "label_fr": "Tenno"
            },
            {
                "value": 49237,
                "label": "Seborga",
                "label_ar": "سيبورجا",
                "label_fr": "Seborga"
            },
            {
                "value": 49238,
                "label": "Caminetto",
                "label_ar": "كامينيتو",
                "label_fr": "Caminetto"
            },
            {
                "value": 49239,
                "label": "Tina",
                "label_ar": "تينا",
                "label_fr": "Tina"
            },
            {
                "value": 49240,
                "label": "Castiglione",
                "label_ar": "كاستيجليون",
                "label_fr": "Castiglione"
            },
            {
                "value": 49241,
                "label": "Albaredo d'Adige",
                "label_ar": "الباريدو دي أديجي",
                "label_fr": "Albaredo d'Adige"
            },
            {
                "value": 49242,
                "label": "San Pietro Mosezzo",
                "label_ar": "سان بيترو موسيزو",
                "label_fr": "San Pietro Mosezzo"
            },
            {
                "value": 49243,
                "label": "Marini",
                "label_ar": "ماريني",
                "label_fr": "Marini"
            },
            {
                "value": 49244,
                "label": "Maggio",
                "label_ar": "ماجيو",
                "label_fr": "Maggio"
            },
            {
                "value": 49245,
                "label": "Palazzolo dello Stella",
                "label_ar": "بالازولو ديلو ستيلا",
                "label_fr": "Palazzolo dello Stella"
            },
            {
                "value": 49246,
                "label": "Meran",
                "label_ar": "ميران",
                "label_fr": "Meran"
            },
            {
                "value": 49247,
                "label": "Grotta",
                "label_ar": "غروتا",
                "label_fr": "Grotta"
            },
            {
                "value": 49248,
                "label": "Narbolia",
                "label_ar": "ناربوليا",
                "label_fr": "Narbolia"
            },
            {
                "value": 49249,
                "label": "Posta Vecchia",
                "label_ar": "بوستا فيكيا",
                "label_fr": "Posta Vecchia"
            },
            {
                "value": 49250,
                "label": "Castello",
                "label_ar": "كاستيلو",
                "label_fr": "Castello"
            },
            {
                "value": 49251,
                "label": "Rose",
                "label_ar": "ارتفع",
                "label_fr": "Rose"
            },
            {
                "value": 49252,
                "label": "Fontanelle",
                "label_ar": "فونتانيلي",
                "label_fr": "Fontanelle"
            },
            {
                "value": 49253,
                "label": "San Luca",
                "label_ar": "سان لوكا",
                "label_fr": "San Luca"
            },
            {
                "value": 49254,
                "label": "Tornaco",
                "label_ar": "تورناكو",
                "label_fr": "Tornaco"
            },
            {
                "value": 49255,
                "label": "Limatola",
                "label_ar": "ليماتولا",
                "label_fr": "Limatola"
            },
            {
                "value": 49256,
                "label": "Viano",
                "label_ar": "فيانو",
                "label_fr": "Viano"
            },
            {
                "value": 49257,
                "label": "Baiso",
                "label_ar": "بايسو",
                "label_fr": "Baiso"
            },
            {
                "value": 49258,
                "label": "Montefiorino",
                "label_ar": "مونتيفورينو",
                "label_fr": "Montefiorino"
            },
            {
                "value": 49259,
                "label": "Maser",
                "label_ar": "مازر",
                "label_fr": "Maser"
            },
            {
                "value": 49260,
                "label": "Voltago",
                "label_ar": "فولتاجو",
                "label_fr": "Voltago"
            },
            {
                "value": 49261,
                "label": "Vigo Rendena",
                "label_ar": "فيغو ريندينا",
                "label_fr": "Vigo Rendena"
            },
            {
                "value": 49262,
                "label": "Predazzo",
                "label_ar": "بريداتسو",
                "label_fr": "Predazzo"
            },
            {
                "value": 49263,
                "label": "Vigo di Fassa",
                "label_ar": "فيجو دي فاسا",
                "label_fr": "Vigo di Fassa"
            },
            {
                "value": 49264,
                "label": "Ronco",
                "label_ar": "رونكو",
                "label_fr": "Ronco"
            },
            {
                "value": 49265,
                "label": "Torre de' Busi",
                "label_ar": "توري دي بوسي",
                "label_fr": "Torre de 'Busi"
            },
            {
                "value": 49266,
                "label": "Villavesco",
                "label_ar": "فيلافيسكو",
                "label_fr": "Villavesco"
            },
            {
                "value": 49267,
                "label": "Pilcante",
                "label_ar": "بيلكانتي",
                "label_fr": "Pilcante"
            },
            {
                "value": 49268,
                "label": "Quistello",
                "label_ar": "كويستيلو",
                "label_fr": "Quistello"
            },
            {
                "value": 49269,
                "label": "Rossano Veneto",
                "label_ar": "روسانو فينيتو",
                "label_fr": "Rossano Veneto"
            },
            {
                "value": 49270,
                "label": "Antignano",
                "label_ar": "أنتيجنانو",
                "label_fr": "Antignano"
            },
            {
                "value": 49271,
                "label": "Cerrione",
                "label_ar": "سيريوني",
                "label_fr": "Cerrione"
            },
            {
                "value": 49272,
                "label": "Campagnola",
                "label_ar": "كامباجنولا",
                "label_fr": "Campagnola"
            },
            {
                "value": 49273,
                "label": "Caselle",
                "label_ar": "كاسيل",
                "label_fr": "Caselle"
            },
            {
                "value": 49274,
                "label": "Villanova",
                "label_ar": "فيلانوفا",
                "label_fr": "Villanova"
            },
            {
                "value": 49275,
                "label": "Cavaria con Premezzo",
                "label_ar": "Cavaria con Premezzo",
                "label_fr": "Cavaria con Premezzo"
            },
            {
                "value": 49276,
                "label": "Castello",
                "label_ar": "كاستيلو",
                "label_fr": "Castello"
            },
            {
                "value": 49277,
                "label": "Brissago-Valtravaglia",
                "label_ar": "بريساجو فالترافاليا",
                "label_fr": "Brissago-Valtravaglia"
            },
            {
                "value": 49278,
                "label": "Lozzo Atestino",
                "label_ar": "لوزو أتيستينو",
                "label_fr": "Lozzo Atestino"
            },
            {
                "value": 49279,
                "label": "Soldano",
                "label_ar": "سولدانو",
                "label_fr": "Soldano"
            },
            {
                "value": 49280,
                "label": "Castellaro",
                "label_ar": "كاستيلارو",
                "label_fr": "Castellaro"
            },
            {
                "value": 49281,
                "label": "Santa Vittoria di Libiola",
                "label_ar": "سانتا فيتوريا دي ليبيولا",
                "label_fr": "Santa Vittoria di Libiola"
            },
            {
                "value": 49282,
                "label": "Châtillon",
                "label_ar": "شاتيلون",
                "label_fr": "Châtillon"
            },
            {
                "value": 49283,
                "label": "Breda di Piave",
                "label_ar": "بريدا دي بيافي",
                "label_fr": "Breda di Piave"
            },
            {
                "value": 49284,
                "label": "Delianuova",
                "label_ar": "ديليانوفا",
                "label_fr": "Delianuova"
            },
            {
                "value": 49285,
                "label": "Lungro",
                "label_ar": "لونغرو",
                "label_fr": "Lungro"
            },
            {
                "value": 49286,
                "label": "Corinaldo",
                "label_ar": "كورينالدو",
                "label_fr": "Corinaldo"
            },
            {
                "value": 49287,
                "label": "Lucera",
                "label_ar": "لوسيرا",
                "label_fr": "Lucera"
            },
            {
                "value": 49288,
                "label": "Castroreale",
                "label_ar": "كاسترويلي",
                "label_fr": "Castroreale"
            },
            {
                "value": 49289,
                "label": "Fuscaldo",
                "label_ar": "فوسكالدو",
                "label_fr": "Fuscaldo"
            },
            {
                "value": 49290,
                "label": "Castiglione Cosentino",
                "label_ar": "كاستيجليون كوسينتينو",
                "label_fr": "Castiglione Cosentino"
            },
            {
                "value": 49291,
                "label": "Corea Castelluccia San Paolo",
                "label_ar": "كوريا كاستيلوتشيا سان باولو",
                "label_fr": "Corea Castelluccia San Paolo"
            },
            {
                "value": 49292,
                "label": "San Giovanni a Piro",
                "label_ar": "سان جيوفاني أ بيرو",
                "label_fr": "San Giovanni a Piro"
            },
            {
                "value": 49293,
                "label": "Prezza",
                "label_ar": "بريزا",
                "label_fr": "Prezza"
            },
            {
                "value": 49294,
                "label": "Lucugnano",
                "label_ar": "لوكوجنانو",
                "label_fr": "Lucugnano"
            },
            {
                "value": 49295,
                "label": "Quero",
                "label_ar": "كويرو",
                "label_fr": "Quero"
            },
            {
                "value": 49296,
                "label": "Concordia Sagittaria",
                "label_ar": "كونكورديا ساجيتاريا",
                "label_fr": "Concordia Sagittaria"
            },
            {
                "value": 49297,
                "label": "Tarzo",
                "label_ar": "طرزو",
                "label_fr": "Tarzo"
            },
            {
                "value": 49298,
                "label": "Burano",
                "label_ar": "بورانو",
                "label_fr": "Burano"
            },
            {
                "value": 49299,
                "label": "Bogliasco",
                "label_ar": "بوغلياسكو",
                "label_fr": "Bogliasco"
            },
            {
                "value": 49300,
                "label": "San Polo di Piave",
                "label_ar": "سان بولو دي بيافي",
                "label_fr": "San Polo di Piave"
            },
            {
                "value": 49301,
                "label": "Castione della Presolana",
                "label_ar": "كاستيون ديلا بريسولانا",
                "label_fr": "Castione della Presolana"
            },
            {
                "value": 49302,
                "label": "Castions di Strada",
                "label_ar": "Castions di Strada",
                "label_fr": "Castions di Strada"
            },
            {
                "value": 49303,
                "label": "Mossa",
                "label_ar": "موسى",
                "label_fr": "Mossa"
            },
            {
                "value": 49304,
                "label": "San Polo",
                "label_ar": "سان بولو",
                "label_fr": "San Polo"
            },
            {
                "value": 49305,
                "label": "Ponti",
                "label_ar": "بونتي",
                "label_fr": "Ponti"
            },
            {
                "value": 49306,
                "label": "Ponte di Barbarano",
                "label_ar": "بونتي دي باربارانو",
                "label_fr": "Ponte di Barbarano"
            },
            {
                "value": 49307,
                "label": "Monteroduni",
                "label_ar": "مونتيرودوني",
                "label_fr": "Monteroduni"
            },
            {
                "value": 49308,
                "label": "Borgosatollo",
                "label_ar": "بورغوساتولو",
                "label_fr": "Borgosatollo"
            },
            {
                "value": 49309,
                "label": "Villapiana",
                "label_ar": "فيلابيانا",
                "label_fr": "Villapiana"
            },
            {
                "value": 49310,
                "label": "Osoppo",
                "label_ar": "أوسوبو",
                "label_fr": "Osoppo"
            },
            {
                "value": 49311,
                "label": "Sant'Agata Feltria",
                "label_ar": "سانت اجاتا فيلتريا",
                "label_fr": "Sant'Agata Feltria"
            },
            {
                "value": 49312,
                "label": "Monterubbiano",
                "label_ar": "مونتيربيانو",
                "label_fr": "Monterubbiano"
            },
            {
                "value": 49313,
                "label": "Montefano",
                "label_ar": "مونتيفانو",
                "label_fr": "Montefano"
            },
            {
                "value": 49314,
                "label": "Siamaggiore",
                "label_ar": "سياماجيوري",
                "label_fr": "Siamaggiore"
            },
            {
                "value": 49315,
                "label": "Usellus",
                "label_ar": "أوسيلوس",
                "label_fr": "Usellus"
            },
            {
                "value": 49316,
                "label": "Carmignano",
                "label_ar": "كارمينانو",
                "label_fr": "Carmignano"
            },
            {
                "value": 49317,
                "label": "Roccavivi",
                "label_ar": "روكافيفي",
                "label_fr": "Roccavivi"
            },
            {
                "value": 49318,
                "label": "Roccasecca",
                "label_ar": "روكاسيكا",
                "label_fr": "Roccasecca"
            },
            {
                "value": 49319,
                "label": "Solarolo",
                "label_ar": "سولارولو",
                "label_fr": "Solarolo"
            },
            {
                "value": 49320,
                "label": "Vattaro",
                "label_ar": "فاتارو",
                "label_fr": "Vattaro"
            },
            {
                "value": 49321,
                "label": "Verolanuova",
                "label_ar": "فيرولانوفا",
                "label_fr": "Verolanuova"
            },
            {
                "value": 49322,
                "label": "Strona",
                "label_ar": "سترونا",
                "label_fr": "Strona"
            },
            {
                "value": 49323,
                "label": "Cedrasco",
                "label_ar": "سيدراسكو",
                "label_fr": "Cedrasco"
            },
            {
                "value": 49324,
                "label": "Breguzzo",
                "label_ar": "بريجوزو",
                "label_fr": "Breguzzo"
            },
            {
                "value": 49325,
                "label": "Portovenere",
                "label_ar": "بورتوفينيري",
                "label_fr": "Portovenere"
            },
            {
                "value": 49326,
                "label": "Sparanise",
                "label_ar": "سبارانيس",
                "label_fr": "Sparaniser"
            },
            {
                "value": 49327,
                "label": "Calice al Cornoviglio",
                "label_ar": "كاليس آل كورنوفيجليو",
                "label_fr": "Calice al Cornoviglio"
            },
            {
                "value": 49328,
                "label": "Cisano sul Neva",
                "label_ar": "سيسانو سول نيفا",
                "label_fr": "Cisano sul Neva"
            },
            {
                "value": 49329,
                "label": "Airasca",
                "label_ar": "أيراسكا",
                "label_fr": "Airasca"
            },
            {
                "value": 49330,
                "label": "San Pietro di Feletto",
                "label_ar": "سان بيترو دي فيليتو",
                "label_fr": "San Pietro di Feletto"
            },
            {
                "value": 49331,
                "label": "Nuvolento",
                "label_ar": "نوفولينتو",
                "label_fr": "Nuvolento"
            },
            {
                "value": 49332,
                "label": "Ferno",
                "label_ar": "فيرنو",
                "label_fr": "Ferno"
            },
            {
                "value": 49333,
                "label": "Stroppiana",
                "label_ar": "ستروببيانا",
                "label_fr": "Stroppiana"
            },
            {
                "value": 49334,
                "label": "Schlanders",
                "label_ar": "شلاندرز",
                "label_fr": "Schlanders"
            },
            {
                "value": 49335,
                "label": "Canazei",
                "label_ar": "كانازي",
                "label_fr": "Canazei"
            },
            {
                "value": 49336,
                "label": "Riomaggiore",
                "label_ar": "ريوماجيوري",
                "label_fr": "Riomaggiore"
            },
            {
                "value": 49337,
                "label": "Santa Giuletta",
                "label_ar": "سانتا جوليتا",
                "label_fr": "Santa Giuletta"
            },
            {
                "value": 49338,
                "label": "Riva Ligure",
                "label_ar": "ريفا ليغور",
                "label_fr": "Riva Ligure"
            },
            {
                "value": 49339,
                "label": "Tarantasca",
                "label_ar": "تارانتاسكا",
                "label_fr": "Tarantasca"
            },
            {
                "value": 49340,
                "label": "Salassa",
                "label_ar": "سلاسة",
                "label_fr": "Salassa"
            },
            {
                "value": 49341,
                "label": "Viguzzolo",
                "label_ar": "فيجوزولو",
                "label_fr": "Viguzzolo"
            },
            {
                "value": 49342,
                "label": "Borgo Val di Taro",
                "label_ar": "بورجو فال دي تارو",
                "label_fr": "Borgo Val di Taro"
            },
            {
                "value": 49343,
                "label": "Rolo",
                "label_ar": "رولو",
                "label_fr": "Rolo"
            },
            {
                "value": 49344,
                "label": "Castiglione dei Pepoli",
                "label_ar": "كاستيجليون دي بيبولي",
                "label_fr": "Castiglione dei Pepoli"
            },
            {
                "value": 49345,
                "label": "Appignano del Tronto",
                "label_ar": "أبينانو ديل ترونتو",
                "label_fr": "Appignano del Tronto"
            },
            {
                "value": 49346,
                "label": "Uta",
                "label_ar": "يوتا",
                "label_fr": "Uta"
            },
            {
                "value": 49347,
                "label": "Girifalco",
                "label_ar": "جيريفالكو",
                "label_fr": "Girifalco"
            },
            {
                "value": 49348,
                "label": "Campo",
                "label_ar": "كامبو",
                "label_fr": "Campo"
            },
            {
                "value": 49349,
                "label": "Forio",
                "label_ar": "فوريو",
                "label_fr": "Forio"
            },
            {
                "value": 49350,
                "label": "Campolieto",
                "label_ar": "كامبوليتو",
                "label_fr": "Campolieto"
            },
            {
                "value": 49351,
                "label": "San Vito Romano",
                "label_ar": "سان فيتو رومانو",
                "label_fr": "San Vito Romano"
            },
            {
                "value": 49352,
                "label": "Gradoli",
                "label_ar": "جرادولي",
                "label_fr": "Gradoli"
            },
            {
                "value": 49353,
                "label": "Patrica",
                "label_ar": "باتريكا",
                "label_fr": "Patrica"
            },
            {
                "value": 49354,
                "label": "Otranto",
                "label_ar": "اوترانتو",
                "label_fr": "Otranto"
            },
            {
                "value": 49355,
                "label": "Cerreto Sannita",
                "label_ar": "سيريتو سانيتا",
                "label_fr": "Cerreto Sannita"
            },
            {
                "value": 49356,
                "label": "Terranova da Sibari",
                "label_ar": "تيرانوفا دا سيباري",
                "label_fr": "Terranova da Sibari"
            },
            {
                "value": 49357,
                "label": "Cupra Marittima",
                "label_ar": "كوبرا ماريتيما",
                "label_fr": "Cupra Marittima"
            },
            {
                "value": 49358,
                "label": "Laterina",
                "label_ar": "لاترينا",
                "label_fr": "Laterina"
            },
            {
                "value": 49359,
                "label": "Serrara Fontana",
                "label_ar": "سيرارا فونتانا",
                "label_fr": "Serrara Fontana"
            },
            {
                "value": 49360,
                "label": "Siano",
                "label_ar": "سيانو",
                "label_fr": "Siano"
            },
            {
                "value": 49361,
                "label": "Castello",
                "label_ar": "كاستيلو",
                "label_fr": "Castello"
            },
            {
                "value": 49362,
                "label": "Grottolella",
                "label_ar": "جروتوليلا",
                "label_fr": "Grottolella"
            },
            {
                "value": 49363,
                "label": "Piedimonte San Germano",
                "label_ar": "بيديمونتي سان جيرمانو",
                "label_fr": "Piedimonte San Germano"
            },
            {
                "value": 49364,
                "label": "Civitella di Romagna",
                "label_ar": "سيفيتيلا دي روماجنا",
                "label_fr": "Civitella di Romagna"
            },
            {
                "value": 49365,
                "label": "Presicce",
                "label_ar": "بريسيتش",
                "label_fr": "Presicce"
            },
            {
                "value": 49366,
                "label": "Castelcucco",
                "label_ar": "Castelcucco",
                "label_fr": "Castelcucco"
            },
            {
                "value": 49367,
                "label": "Piavon",
                "label_ar": "بيافون",
                "label_fr": "Piavon"
            },
            {
                "value": 49368,
                "label": "Capriva del Friuli",
                "label_ar": "كابريفا دل فريولي",
                "label_fr": "Capriva del Friuli"
            },
            {
                "value": 49369,
                "label": "Pieve di Cadore",
                "label_ar": "بيف دي كادور",
                "label_fr": "Pieve di Cadore"
            },
            {
                "value": 49370,
                "label": "San Biagio",
                "label_ar": "سان بياجيو",
                "label_fr": "San Biagio"
            },
            {
                "value": 49371,
                "label": "Conco",
                "label_ar": "كونكو",
                "label_fr": "Conco"
            },
            {
                "value": 49372,
                "label": "Provesano",
                "label_ar": "بروفيسانو",
                "label_fr": "Provesano"
            },
            {
                "value": 49373,
                "label": "Deggiano",
                "label_ar": "ديجيانو",
                "label_fr": "Deggiano"
            },
            {
                "value": 49374,
                "label": "Sizzano",
                "label_ar": "سيزانو",
                "label_fr": "Sizzano"
            },
            {
                "value": 49375,
                "label": "Tres",
                "label_ar": "تريس",
                "label_fr": "Tres"
            },
            {
                "value": 49376,
                "label": "Marola",
                "label_ar": "مارولا",
                "label_fr": "Marola"
            },
            {
                "value": 49377,
                "label": "Correzzola",
                "label_ar": "كوريتزولا",
                "label_fr": "Correzzola"
            },
            {
                "value": 49378,
                "label": "Nove",
                "label_ar": "نوف",
                "label_fr": "Nove"
            },
            {
                "value": 49379,
                "label": "Ceva",
                "label_ar": "سيفا",
                "label_fr": "Ceva"
            },
            {
                "value": 49380,
                "label": "Sparone",
                "label_ar": "سباروني",
                "label_fr": "Sparone"
            },
            {
                "value": 49381,
                "label": "Caselette",
                "label_ar": "كاسليت",
                "label_fr": "Caselette"
            },
            {
                "value": 49382,
                "label": "Montefiascone",
                "label_ar": "مونتيفياسكوني",
                "label_fr": "Montefiascone"
            },
            {
                "value": 49383,
                "label": "Melissano",
                "label_ar": "ميليسانو",
                "label_fr": "Melissano"
            },
            {
                "value": 49384,
                "label": "Luzzano",
                "label_ar": "لوزانو",
                "label_fr": "Luzzano"
            },
            {
                "value": 49385,
                "label": "Capodrise",
                "label_ar": "Capodrise",
                "label_fr": "Capodrise"
            },
            {
                "value": 49386,
                "label": "Staffolo",
                "label_ar": "ستافولو",
                "label_fr": "Staffolo"
            },
            {
                "value": 49387,
                "label": "Santadi",
                "label_ar": "سانتادي",
                "label_fr": "Santadi"
            },
            {
                "value": 49388,
                "label": "Librizzi",
                "label_ar": "ليبريزي",
                "label_fr": "Librizzi"
            },
            {
                "value": 49389,
                "label": "Oliveri",
                "label_ar": "أوليفيري",
                "label_fr": "Oliveri"
            },
            {
                "value": 49390,
                "label": "Chiusi",
                "label_ar": "تشيوسي",
                "label_fr": "Chiusi"
            },
            {
                "value": 49391,
                "label": "San Lorenzello",
                "label_ar": "سان لورينزيلو",
                "label_fr": "San Lorenzello"
            },
            {
                "value": 49392,
                "label": "Isola delle Femmine",
                "label_ar": "Isola delle Femmine",
                "label_fr": "Isola delle Femmine"
            },
            {
                "value": 49393,
                "label": "Tornareccio",
                "label_ar": "تورناريتشيو",
                "label_fr": "Tornareccio"
            },
            {
                "value": 49394,
                "label": "Valfabbrica",
                "label_ar": "فالفابريكا",
                "label_fr": "Valfabbrica"
            },
            {
                "value": 49395,
                "label": "Senerchia",
                "label_ar": "سينيرشيا",
                "label_fr": "Senerchia"
            },
            {
                "value": 49396,
                "label": "Torre Orsaia",
                "label_ar": "توري أورسايا",
                "label_fr": "Torre Orsaia"
            },
            {
                "value": 49397,
                "label": "Maratea",
                "label_ar": "ماراتيا",
                "label_fr": "Maratea"
            },
            {
                "value": 49398,
                "label": "Castell'Arquato",
                "label_ar": "كاستيل أركواتو",
                "label_fr": "Castell'Arquato"
            },
            {
                "value": 49399,
                "label": "Brescello",
                "label_ar": "بريسيلو",
                "label_fr": "Brescello"
            },
            {
                "value": 49400,
                "label": "Piangipane",
                "label_ar": "بيانجيباني",
                "label_fr": "Piangipane"
            },
            {
                "value": 49401,
                "label": "Subbiano",
                "label_ar": "سوبيانو",
                "label_fr": "Subbiano"
            },
            {
                "value": 49402,
                "label": "Condofuri",
                "label_ar": "كوندوفوري",
                "label_fr": "Condofuri"
            },
            {
                "value": 49403,
                "label": "Crosia",
                "label_ar": "كروسيا",
                "label_fr": "Crosie"
            },
            {
                "value": 49404,
                "label": "Polistena",
                "label_ar": "بوليستينا",
                "label_fr": "Polistena"
            },
            {
                "value": 49405,
                "label": "Montepaone",
                "label_ar": "مونتيباوني",
                "label_fr": "Montepaone"
            },
            {
                "value": 49406,
                "label": "Trasacco",
                "label_ar": "تراساكو",
                "label_fr": "Trasacco"
            },
            {
                "value": 49407,
                "label": "Roccavivara",
                "label_ar": "روكافيفارا",
                "label_fr": "Roccavivara"
            },
            {
                "value": 49408,
                "label": "Stigliano",
                "label_ar": "ستيجليانو",
                "label_fr": "Stigliano"
            },
            {
                "value": 49409,
                "label": "Porto Azzurro",
                "label_ar": "بورتو أزورو",
                "label_fr": "Porto Azzurro"
            },
            {
                "value": 49410,
                "label": "Paglieta",
                "label_ar": "باجلييتا",
                "label_fr": "Paglieta"
            },
            {
                "value": 49411,
                "label": "Arsita",
                "label_ar": "أرسيتا",
                "label_fr": "Arsita"
            },
            {
                "value": 49412,
                "label": "Fabrica di Roma",
                "label_ar": "فابريكا دي روما",
                "label_fr": "Fabrica di Roma"
            },
            {
                "value": 49413,
                "label": "Boves",
                "label_ar": "بوفس",
                "label_fr": "Boves"
            },
            {
                "value": 49414,
                "label": "Calvenzano",
                "label_ar": "كالفينزانو",
                "label_fr": "Calvenzano"
            },
            {
                "value": 49415,
                "label": "Monzambano",
                "label_ar": "مونزامبانو",
                "label_fr": "Monzambano"
            },
            {
                "value": 49416,
                "label": "Spinazzola",
                "label_ar": "سبينازولا",
                "label_fr": "Spinazzola"
            },
            {
                "value": 49417,
                "label": "Campofelice di Roccella",
                "label_ar": "كامبوفيليتسي دي روكيلا",
                "label_fr": "Campofelice di Roccella"
            },
            {
                "value": 49418,
                "label": "Massa Marittima",
                "label_ar": "ماسا ماريتيما",
                "label_fr": "Massa Marittima"
            },
            {
                "value": 49419,
                "label": "Naro",
                "label_ar": "نارو",
                "label_fr": "Naro"
            },
            {
                "value": 49420,
                "label": "Castel San Niccolò",
                "label_ar": "قلعة سان نيكولو",
                "label_fr": "Castel San Niccolò"
            },
            {
                "value": 49421,
                "label": "Casalmorano",
                "label_ar": "كاسالمورانو",
                "label_fr": "Casalmorano"
            },
            {
                "value": 49422,
                "label": "Soave",
                "label_ar": "سواف",
                "label_fr": "Soave"
            },
            {
                "value": 49423,
                "label": "Lesegno",
                "label_ar": "ليسجنو",
                "label_fr": "Lesegno"
            },
            {
                "value": 49424,
                "label": "Calendasco",
                "label_ar": "كالينداسكو",
                "label_fr": "Calendasco"
            },
            {
                "value": 49425,
                "label": "Volpedo",
                "label_ar": "فولبيدو",
                "label_fr": "Volpedo"
            },
            {
                "value": 49426,
                "label": "Serramanna",
                "label_ar": "سيرامانا",
                "label_fr": "Serramanna"
            },
            {
                "value": 49427,
                "label": "Fontanelice",
                "label_ar": "فونتانيليس",
                "label_fr": "Fontanelice"
            },
            {
                "value": 49428,
                "label": "Casa Pace",
                "label_ar": "كاسا بيس",
                "label_fr": "Casa Pace"
            },
            {
                "value": 49429,
                "label": "Fara Filiorum Petri",
                "label_ar": "فارا فيليوروم بيتري",
                "label_fr": "Fara Filiorum Petri"
            },
            {
                "value": 49430,
                "label": "Lappato",
                "label_ar": "لاباتو",
                "label_fr": "Lappato"
            },
            {
                "value": 49431,
                "label": "Melendugno",
                "label_ar": "Melendugno",
                "label_fr": "Melendugno"
            },
            {
                "value": 49432,
                "label": "Casale Corte Cerro",
                "label_ar": "كاسال كورت سيرو",
                "label_fr": "Casale Corte Cerro"
            },
            {
                "value": 49433,
                "label": "Caselle",
                "label_ar": "كاسيل",
                "label_fr": "Caselle"
            },
            {
                "value": 49434,
                "label": "Gargnano",
                "label_ar": "جارنانو",
                "label_fr": "Gargnano"
            },
            {
                "value": 49435,
                "label": "Fiano",
                "label_ar": "فيانو",
                "label_fr": "Fiano"
            },
            {
                "value": 49436,
                "label": "Loranzè",
                "label_ar": "لورانزي",
                "label_fr": "Loranzè"
            },
            {
                "value": 49437,
                "label": "Isola",
                "label_ar": "ايزولا",
                "label_fr": "Isola"
            },
            {
                "value": 49438,
                "label": "Villar",
                "label_ar": "فيلار",
                "label_fr": "Villar"
            },
            {
                "value": 49439,
                "label": "Forno Alpi Graie",
                "label_ar": "فورنو ألبي جراي",
                "label_fr": "Forno Alpi Graie"
            },
            {
                "value": 49440,
                "label": "Caraglio",
                "label_ar": "كاراجليو",
                "label_fr": "Caraglio"
            },
            {
                "value": 49441,
                "label": "Rivolta d'Adda",
                "label_ar": "ريفولتا دادا",
                "label_fr": "Rivolta d'Adda"
            },
            {
                "value": 49442,
                "label": "Bonavicina",
                "label_ar": "بونافيسينا",
                "label_fr": "Bonavicina"
            },
            {
                "value": 49443,
                "label": "San Damiano d'Asti",
                "label_ar": "سان داميانو داستي",
                "label_fr": "San Damiano d'Asti"
            },
            {
                "value": 49444,
                "label": "Monasterolo del Castello",
                "label_ar": "موناستيرولو ديل كاستيلو",
                "label_fr": "Monasterolo del Castello"
            },
            {
                "value": 49445,
                "label": "Acquaviva",
                "label_ar": "أكوافيفا",
                "label_fr": "Acquaviva"
            },
            {
                "value": 49446,
                "label": "Trevenzuolo",
                "label_ar": "تريفنزولو",
                "label_fr": "Trevenzuolo"
            },
            {
                "value": 49447,
                "label": "Bovegno",
                "label_ar": "بوفيجنو",
                "label_fr": "Bovegno"
            },
            {
                "value": 49448,
                "label": "Qualso",
                "label_ar": "كوالسو",
                "label_fr": "Qualso"
            },
            {
                "value": 49449,
                "label": "Riccovolto",
                "label_ar": "ريكوفولتو",
                "label_fr": "Riccovolto"
            },
            {
                "value": 49450,
                "label": "Castel d'Aiano",
                "label_ar": "كاستل ديانو",
                "label_fr": "Castel d'Aiano"
            },
            {
                "value": 49451,
                "label": "Mandas",
                "label_ar": "ماندا",
                "label_fr": "Mandas"
            },
            {
                "value": 49452,
                "label": "Siamanna",
                "label_ar": "سيامانا",
                "label_fr": "Siamanna"
            },
            {
                "value": 49453,
                "label": "Guardia Piemontese",
                "label_ar": "جوارديا بيمونتيز",
                "label_fr": "Guardia Piemontese"
            },
            {
                "value": 49454,
                "label": "Taurano",
                "label_ar": "تورانو",
                "label_fr": "Taurano"
            },
            {
                "value": 49455,
                "label": "Lugnano",
                "label_ar": "لوغنانو",
                "label_fr": "Lugnano"
            },
            {
                "value": 49456,
                "label": "Santa Maria Codifiume",
                "label_ar": "سانتا ماريا كودفيوم",
                "label_fr": "Santa Maria Codifiume"
            },
            {
                "value": 49457,
                "label": "Capoliveri",
                "label_ar": "كابوليفيري",
                "label_fr": "Capoliveri"
            },
            {
                "value": 49458,
                "label": "Rotondi",
                "label_ar": "روتوندي",
                "label_fr": "Rotondi"
            },
            {
                "value": 49459,
                "label": "Basilicanova",
                "label_ar": "بازيليكانوفا",
                "label_fr": "Basilicanova"
            },
            {
                "value": 49460,
                "label": "Amantea",
                "label_ar": "امانتيا",
                "label_fr": "Amantea"
            },
            {
                "value": 49461,
                "label": "San Marco dei Cavoti",
                "label_ar": "سان ماركو دي كافوتي",
                "label_fr": "San Marco dei Cavoti"
            },
            {
                "value": 49462,
                "label": "Marzano di Nola",
                "label_ar": "مارزانو دي نولا",
                "label_fr": "Marzano di Nola"
            },
            {
                "value": 49463,
                "label": "Volturara Irpina",
                "label_ar": "فولتورارا ايربينا",
                "label_fr": "Volturara Irpina"
            },
            {
                "value": 49464,
                "label": "Caronia",
                "label_ar": "كارونيا",
                "label_fr": "Caronia"
            },
            {
                "value": 49465,
                "label": "Porto Ercole",
                "label_ar": "بورتو إركول",
                "label_fr": "Porto Ercole"
            },
            {
                "value": 49466,
                "label": "Palaia",
                "label_ar": "بالايا",
                "label_fr": "Palaia"
            },
            {
                "value": 49467,
                "label": "Castrocaro Terme",
                "label_ar": "كاستروكارو تيرمي",
                "label_fr": "Castrocaro Terme"
            },
            {
                "value": 49468,
                "label": "Conselice",
                "label_ar": "إستشارة",
                "label_fr": "Conselice"
            },
            {
                "value": 49469,
                "label": "Mercatino Conca",
                "label_ar": "ميركاتينو كونكا",
                "label_fr": "Mercatino Conca"
            },
            {
                "value": 49470,
                "label": "Torre a Mare",
                "label_ar": "توري أ ماري",
                "label_fr": "Torre a Mare"
            },
            {
                "value": 49471,
                "label": "Arpino",
                "label_ar": "اربينو",
                "label_fr": "Arpino"
            },
            {
                "value": 49472,
                "label": "Orani",
                "label_ar": "أوراني",
                "label_fr": "Orani"
            },
            {
                "value": 49473,
                "label": "Arpaia",
                "label_ar": "أربيا",
                "label_fr": "Arpaia"
            },
            {
                "value": 49474,
                "label": "Foiano di Val Fortore",
                "label_ar": "فويانو دي فال فورتور",
                "label_fr": "Foiano di Val Fortore"
            },
            {
                "value": 49475,
                "label": "Delia",
                "label_ar": "ديليا",
                "label_fr": "Délia"
            },
            {
                "value": 49476,
                "label": "Pescina",
                "label_ar": "بيسينا",
                "label_fr": "Pescina"
            },
            {
                "value": 49477,
                "label": "Trevico",
                "label_ar": "تريفيكو",
                "label_fr": "Trevico"
            },
            {
                "value": 49478,
                "label": "Giarratana",
                "label_ar": "جيراتانا",
                "label_fr": "Giarratana"
            },
            {
                "value": 49479,
                "label": "Falconara Albanese",
                "label_ar": "فالكونارا ألبانيز",
                "label_fr": "Falconara Albanese"
            },
            {
                "value": 49480,
                "label": "Palizzi Marina",
                "label_ar": "مارينا باليزي",
                "label_fr": "Marina de Palizzi"
            },
            {
                "value": 49481,
                "label": "Capodimonte",
                "label_ar": "كابوديمونتي",
                "label_fr": "Capodimonte"
            },
            {
                "value": 49482,
                "label": "Sperlonga",
                "label_ar": "سبيرلونجا",
                "label_fr": "Sperlonga"
            },
            {
                "value": 49483,
                "label": "Pennabilli",
                "label_ar": "بينابيلي",
                "label_fr": "Pennabilli"
            },
            {
                "value": 49484,
                "label": "Monterchi",
                "label_ar": "مونتيرشي",
                "label_fr": "Monterchi"
            },
            {
                "value": 49485,
                "label": "Sassinoro",
                "label_ar": "ساسينورو",
                "label_fr": "Sassinoro"
            },
            {
                "value": 49486,
                "label": "Tramutola",
                "label_ar": "تراموتولا",
                "label_fr": "Tramutola"
            },
            {
                "value": 49487,
                "label": "Ponte",
                "label_ar": "بونتي",
                "label_fr": "Ponte"
            },
            {
                "value": 49488,
                "label": "Pistrino",
                "label_ar": "بيسترينو",
                "label_fr": "Pistrino"
            },
            {
                "value": 49489,
                "label": "San Mariano",
                "label_ar": "سان ماريانو",
                "label_fr": "San Mariano"
            },
            {
                "value": 49490,
                "label": "Marzano",
                "label_ar": "مارزانو",
                "label_fr": "Marzano"
            },
            {
                "value": 49491,
                "label": "Palagianello",
                "label_ar": "بالاجيانيلو",
                "label_fr": "Palagianello"
            },
            {
                "value": 49492,
                "label": "Luzzi",
                "label_ar": "لوزي",
                "label_fr": "Luzzi"
            },
            {
                "value": 49493,
                "label": "Lama Mocogno",
                "label_ar": "لاما موكوجنو",
                "label_fr": "Lama Mocogno"
            },
            {
                "value": 49494,
                "label": "Predore",
                "label_ar": "بريدور",
                "label_fr": "Prédore"
            },
            {
                "value": 49495,
                "label": "Tiriolo",
                "label_ar": "تيريولو",
                "label_fr": "Tiriolo"
            },
            {
                "value": 49496,
                "label": "Ghilarza",
                "label_ar": "جيلارزا",
                "label_fr": "Ghilarza"
            },
            {
                "value": 49497,
                "label": "Cercepiccola",
                "label_ar": "كرسبيكولا",
                "label_fr": "Cercepiccola"
            },
            {
                "value": 49498,
                "label": "Forano",
                "label_ar": "فورانو",
                "label_fr": "Forano"
            },
            {
                "value": 49499,
                "label": "Vivaro",
                "label_ar": "فيفارو",
                "label_fr": "Vivaro"
            },
            {
                "value": 49500,
                "label": "Masera",
                "label_ar": "ماسيرا",
                "label_fr": "Masera"
            },
            {
                "value": 49501,
                "label": "Cartura",
                "label_ar": "كارتورا",
                "label_fr": "Cartura"
            },
            {
                "value": 49502,
                "label": "Innichen",
                "label_ar": "إنيشن",
                "label_fr": "Innichen"
            },
            {
                "value": 49503,
                "label": "Visinale",
                "label_ar": "فيزينال",
                "label_fr": "Visinale"
            },
            {
                "value": 49504,
                "label": "Castelgomberto",
                "label_ar": "كاستلجومبيرتو",
                "label_fr": "Castelgomberto"
            },
            {
                "value": 49505,
                "label": "San Paolo",
                "label_ar": "سان باولو",
                "label_fr": "San Paolo"
            },
            {
                "value": 49506,
                "label": "Gavinana",
                "label_ar": "جافينانا",
                "label_fr": "Gavinana"
            },
            {
                "value": 49507,
                "label": "Bivongi",
                "label_ar": "بيفونجي",
                "label_fr": "Bivongi"
            },
            {
                "value": 49508,
                "label": "Nusco",
                "label_ar": "نوسكو",
                "label_fr": "Nusco"
            },
            {
                "value": 49509,
                "label": "San Giovanni Gemini",
                "label_ar": "سان جيوفاني الجوزاء",
                "label_fr": "San Giovanni Gemini"
            },
            {
                "value": 49510,
                "label": "Berbenno di Valtellina",
                "label_ar": "Berbenno di Valtellina",
                "label_fr": "Berbenno di Valtellina"
            },
            {
                "value": 49511,
                "label": "Paderno Ponchielli",
                "label_ar": "باديرنو بونشيلي",
                "label_fr": "Paderno Ponchielli"
            },
            {
                "value": 49512,
                "label": "Santo Stefano di Cadore",
                "label_ar": "سانتو ستيفانو دي كادوري",
                "label_fr": "Santo Stefano di Cadore"
            },
            {
                "value": 49513,
                "label": "Noli",
                "label_ar": "نولي",
                "label_fr": "Noli"
            },
            {
                "value": 49514,
                "label": "Buttapietra",
                "label_ar": "بوتابيترا",
                "label_fr": "Buttapietra"
            },
            {
                "value": 49515,
                "label": "Ispra",
                "label_ar": "إسبرا",
                "label_fr": "Ispra"
            },
            {
                "value": 49516,
                "label": "Charvensod",
                "label_ar": "شارفنسود",
                "label_fr": "Charvensod"
            },
            {
                "value": 49517,
                "label": "Pescocostanzo",
                "label_ar": "بيسكوكوستانزو",
                "label_fr": "Pescocostanzo"
            },
            {
                "value": 49518,
                "label": "Castellaneta",
                "label_ar": "كاستيلانيتا",
                "label_fr": "Castellaneta"
            },
            {
                "value": 49519,
                "label": "Montepulciano Stazione",
                "label_ar": "Montepulciano Stazione",
                "label_fr": "Montepulciano Stazione"
            },
            {
                "value": 49520,
                "label": "Ronchi",
                "label_ar": "رونشي",
                "label_fr": "Ronchi"
            },
            {
                "value": 49521,
                "label": "Pieve",
                "label_ar": "بيفي",
                "label_fr": "Pieve"
            },
            {
                "value": 49522,
                "label": "Povegliano",
                "label_ar": "بوفيجليانو",
                "label_fr": "Povegliano"
            },
            {
                "value": 49523,
                "label": "Tusa",
                "label_ar": "توسا",
                "label_fr": "Tusa"
            },
            {
                "value": 49524,
                "label": "Monteforte d'Alpone",
                "label_ar": "مونتيفورت دالبوني",
                "label_fr": "Monteforte d'Alpone"
            },
            {
                "value": 49525,
                "label": "Meolo",
                "label_ar": "ميولو",
                "label_fr": "Meolo"
            },
            {
                "value": 49526,
                "label": "Loro Piceno",
                "label_ar": "لورو بيتشينو",
                "label_fr": "Loro Piceno"
            },
            {
                "value": 49527,
                "label": "Prata di Pordenone",
                "label_ar": "براتا دي بوردينوني",
                "label_fr": "Prata di Pordenone"
            },
            {
                "value": 49528,
                "label": "Ratschings",
                "label_ar": "راتشينغز",
                "label_fr": "Ratschings"
            },
            {
                "value": 49529,
                "label": "Torrenova",
                "label_ar": "تورينوفا",
                "label_fr": "Torrenova"
            },
            {
                "value": 49530,
                "label": "San Marzano di San Giuseppe",
                "label_ar": "سان مارزانو دي سان جوزيبي",
                "label_fr": "San Marzano di San Giuseppe"
            },
            {
                "value": 49531,
                "label": "Greci",
                "label_ar": "غريسي",
                "label_fr": "Greci"
            },
            {
                "value": 49532,
                "label": "Pellestrina",
                "label_ar": "بيليسترينا",
                "label_fr": "Pellestrina"
            },
            {
                "value": 49533,
                "label": "Pettinengo",
                "label_ar": "بيتينينجو",
                "label_fr": "Pettinengo"
            },
            {
                "value": 49534,
                "label": "Orotelli",
                "label_ar": "أوروتيلي",
                "label_fr": "Orotelli"
            },
            {
                "value": 49535,
                "label": "Pettoranello del Molise",
                "label_ar": "بيتورانيلو ديل موليز",
                "label_fr": "Pettoranello del Molise"
            },
            {
                "value": 49536,
                "label": "Santo Stino di Livenza",
                "label_ar": "سانتو ستينو دي ليفينزا",
                "label_fr": "Santo Stino di Livenza"
            },
            {
                "value": 49537,
                "label": "Panzano",
                "label_ar": "بانزانو",
                "label_fr": "Panzano"
            },
            {
                "value": 49538,
                "label": "Marina di Castagneto Carducci",
                "label_ar": "مارينا دي كاستانييتو كاردوتشي",
                "label_fr": "Marina di Castagneto Carducci"
            },
            {
                "value": 49539,
                "label": "Valle",
                "label_ar": "فالي",
                "label_fr": "Valle"
            },
            {
                "value": 49540,
                "label": "Lingotto",
                "label_ar": "لينغوتو",
                "label_fr": "Lingotto"
            },
            {
                "value": 49541,
                "label": "Albina",
                "label_ar": "ألبينا",
                "label_fr": "Albina"
            },
            {
                "value": 49542,
                "label": "San Pietro",
                "label_ar": "سان بيترو",
                "label_fr": "San Pietro"
            },
            {
                "value": 49543,
                "label": "Bosco",
                "label_ar": "بوسكو",
                "label_fr": "Bosco"
            },
            {
                "value": 49544,
                "label": "Fiore",
                "label_ar": "فيوري",
                "label_fr": "Fiore"
            },
            {
                "value": 49545,
                "label": "Corte",
                "label_ar": "كورتي",
                "label_fr": "Corte"
            },
            {
                "value": 49546,
                "label": "Porta",
                "label_ar": "بورتا",
                "label_fr": "Porta"
            },
            {
                "value": 49547,
                "label": "Pavia di Udine",
                "label_ar": "بافيا دي أوديني",
                "label_fr": "Pavia di Udine"
            },
            {
                "value": 49548,
                "label": "Collefontana-Fontana Liri Inferiore",
                "label_ar": "Collefontana-Fontana Liri Inferiore",
                "label_fr": "Collefontana-Fontana Liri Inferiore"
            },
            {
                "value": 49549,
                "label": "Torre",
                "label_ar": "توري",
                "label_fr": "Torre"
            },
            {
                "value": 49550,
                "label": "Brenner",
                "label_ar": "برينر",
                "label_fr": "Brenner"
            },
            {
                "value": 49551,
                "label": "Mora",
                "label_ar": "مورا",
                "label_fr": "Mora"
            },
            {
                "value": 49552,
                "label": "Bosco",
                "label_ar": "بوسكو",
                "label_fr": "Bosco"
            },
            {
                "value": 49553,
                "label": "Rocco",
                "label_ar": "روكو",
                "label_fr": "Rocco"
            },
            {
                "value": 49554,
                "label": "Amato",
                "label_ar": "أماتو",
                "label_fr": "Amato"
            },
            {
                "value": 49555,
                "label": "Meduna di Livenza",
                "label_ar": "ميدونا دي ليفينزا",
                "label_fr": "Meduna di Livenza"
            },
            {
                "value": 49556,
                "label": "Fossalta di Piave",
                "label_ar": "فوسالتا دي بيافي",
                "label_fr": "Fossalta di Piave"
            },
            {
                "value": 49557,
                "label": "Giuliano",
                "label_ar": "جوليانو",
                "label_fr": "Giuliano"
            },
            {
                "value": 49558,
                "label": "Fosco",
                "label_ar": "فوسكو",
                "label_fr": "Fosco"
            },
            {
                "value": 49559,
                "label": "Rossi",
                "label_ar": "روسي",
                "label_fr": "Rossi"
            },
            {
                "value": 49560,
                "label": "Bagnoli Irpino",
                "label_ar": "باجنولي ايربينو",
                "label_fr": "Bagnoli Irpino"
            },
            {
                "value": 49561,
                "label": "Albergo",
                "label_ar": "البرغو",
                "label_fr": "Albergo"
            },
            {
                "value": 49562,
                "label": "Castelli",
                "label_ar": "كاستيلي",
                "label_fr": "Castelli"
            },
            {
                "value": 49563,
                "label": "Palazzo",
                "label_ar": "بالازو",
                "label_fr": "Palais"
            },
            {
                "value": 49564,
                "label": "Carcereri",
                "label_ar": "كارسيريري",
                "label_fr": "Carcereri"
            },
            {
                "value": 49565,
                "label": "Luca",
                "label_ar": "لوكا",
                "label_fr": "Luca"
            },
            {
                "value": 49566,
                "label": "Giardini",
                "label_ar": "جيارديني",
                "label_fr": "Giardini"
            },
            {
                "value": 49567,
                "label": "Palladio",
                "label_ar": "بالاديو",
                "label_fr": "Palladio"
            },
            {
                "value": 49568,
                "label": "La Gala",
                "label_ar": "لا جالا",
                "label_fr": "La Gala"
            },
            {
                "value": 49569,
                "label": "Aiello",
                "label_ar": "ايلو",
                "label_fr": "Aiello"
            },
            {
                "value": 49570,
                "label": "Villaggio",
                "label_ar": "فيلاجيو",
                "label_fr": "Villaggio"
            },
            {
                "value": 49571,
                "label": "Trabia",
                "label_ar": "ترابيا",
                "label_fr": "Trabia"
            },
            {
                "value": 49572,
                "label": "Ai Palazzi",
                "label_ar": "آي بلازي",
                "label_fr": "Ai Palazzi"
            },
            {
                "value": 49573,
                "label": "Cecchini",
                "label_ar": "سيتشيني",
                "label_fr": "Cecchini"
            },
            {
                "value": 49574,
                "label": "Palazzina",
                "label_ar": "بالازينا",
                "label_fr": "Palazzina"
            },
            {
                "value": 49575,
                "label": "Borghetto",
                "label_ar": "بورغيتو",
                "label_fr": "Borghetto"
            },
            {
                "value": 49576,
                "label": "Luciana",
                "label_ar": "لوسيانا",
                "label_fr": "Luciana"
            },
            {
                "value": 49577,
                "label": "Certosa",
                "label_ar": "سيرتوزا",
                "label_fr": "Certosa"
            },
            {
                "value": 49578,
                "label": "Grottaminarda",
                "label_ar": "جروتاميناردا",
                "label_fr": "Grottaminarda"
            },
            {
                "value": 49579,
                "label": "Sabatino",
                "label_ar": "ساباتينو",
                "label_fr": "Sabatino"
            },
            {
                "value": 49580,
                "label": "Mariae",
                "label_ar": "ماريا",
                "label_fr": "Mariae"
            },
            {
                "value": 49581,
                "label": "Sacco",
                "label_ar": "ساكو",
                "label_fr": "Sacco"
            },
            {
                "value": 49582,
                "label": "Boville Ernica",
                "label_ar": "بوفيل ارنيكا",
                "label_fr": "Boville Ernica"
            },
            {
                "value": 49583,
                "label": "Attimis",
                "label_ar": "أتيميس",
                "label_fr": "Attimis"
            },
            {
                "value": 49584,
                "label": "Macello",
                "label_ar": "ماسيلو",
                "label_fr": "Macello"
            },
            {
                "value": 49585,
                "label": "Barge",
                "label_ar": "بارج",
                "label_fr": "Barge"
            },
            {
                "value": 49586,
                "label": "La Loggia",
                "label_ar": "لا لوجيا",
                "label_fr": "La Loggia"
            },
            {
                "value": 49587,
                "label": "Ramponio",
                "label_ar": "رامبونيو",
                "label_fr": "Ramponio"
            },
            {
                "value": 49588,
                "label": "Casate Raffa",
                "label_ar": "كاساتي رافا",
                "label_fr": "Casate Raffa"
            },
            {
                "value": 49589,
                "label": "Dolcè",
                "label_ar": "بلطف",
                "label_fr": "Dolcè"
            },
            {
                "value": 49590,
                "label": "Premana",
                "label_ar": "بريمانا",
                "label_fr": "Premana"
            },
            {
                "value": 49591,
                "label": "Ormelle",
                "label_ar": "أورميل",
                "label_fr": "Ormelle"
            },
            {
                "value": 49592,
                "label": "Furore",
                "label_ar": "غضب",
                "label_fr": "Scandale"
            },
            {
                "value": 49593,
                "label": "Giungano",
                "label_ar": "جيونجانو",
                "label_fr": "Giungano"
            },
            {
                "value": 49594,
                "label": "Pollica",
                "label_ar": "بوليكا",
                "label_fr": "Pollica"
            },
            {
                "value": 49595,
                "label": "Sordevolo",
                "label_ar": "سوردفولو",
                "label_fr": "Sordevolo"
            },
            {
                "value": 49596,
                "label": "Vespolate",
                "label_ar": "احتفال",
                "label_fr": "Vespolate"
            },
            {
                "value": 49597,
                "label": "Tirano",
                "label_ar": "تيرانو",
                "label_fr": "Tirano"
            },
            {
                "value": 49598,
                "label": "Zoagli",
                "label_ar": "Zoagli",
                "label_fr": "Zoagli"
            },
            {
                "value": 49599,
                "label": "Palù",
                "label_ar": "بالو",
                "label_fr": "Palù"
            },
            {
                "value": 49600,
                "label": "Cese",
                "label_ar": "Cese",
                "label_fr": "Cese"
            },
            {
                "value": 49601,
                "label": "Belvedere Ostrense",
                "label_ar": "بلفيدير أوسترينس",
                "label_fr": "Belvédère Ostrense"
            },
            {
                "value": 49602,
                "label": "Villa Marina",
                "label_ar": "فيلا مارينا",
                "label_fr": "Villa Marina"
            },
            {
                "value": 49603,
                "label": "San Secondo",
                "label_ar": "سان سيكوندو",
                "label_fr": "San Secondo"
            },
            {
                "value": 49604,
                "label": "Cannella",
                "label_ar": "كانيلا",
                "label_fr": "Cannella"
            },
            {
                "value": 49605,
                "label": "San Gregorio",
                "label_ar": "سان جريجوريو",
                "label_fr": "San Gregorio"
            },
            {
                "value": 49606,
                "label": "Martignano",
                "label_ar": "Martignano",
                "label_fr": "Martignano"
            },
            {
                "value": 49607,
                "label": "Roiano",
                "label_ar": "رويانو",
                "label_fr": "Roiano"
            },
            {
                "value": 49608,
                "label": "Borgo Tossignano",
                "label_ar": "بورجو توسينيانو",
                "label_fr": "Borgo Tossignano"
            },
            {
                "value": 49609,
                "label": "Agugliaro",
                "label_ar": "Agugliaro",
                "label_fr": "Agugliaro"
            },
            {
                "value": 49610,
                "label": "Sossano",
                "label_ar": "سوسانو",
                "label_fr": "Sossano"
            },
            {
                "value": 49611,
                "label": "Sand in Taufers",
                "label_ar": "الرمل في Taufers",
                "label_fr": "Sable de Taufers"
            },
            {
                "value": 49612,
                "label": "Termeno",
                "label_ar": "تيرمينو",
                "label_fr": "Termeno"
            },
            {
                "value": 49613,
                "label": "Rignano Garganico",
                "label_ar": "ريجنانو جارجانيكو",
                "label_fr": "Rignano Garganico"
            },
            {
                "value": 49614,
                "label": "Castelnuovo della Daunia",
                "label_ar": "Castelnuovo della Daunia",
                "label_fr": "Castelnuovo della Daunia"
            },
            {
                "value": 49615,
                "label": "Volturino",
                "label_ar": "فولتورينو",
                "label_fr": "Volturino"
            },
            {
                "value": 49616,
                "label": "Carpignano Sesia",
                "label_ar": "كاربينيانو سيسيا",
                "label_fr": "Carpignano Sesia"
            },
            {
                "value": 49617,
                "label": "Colombaro",
                "label_ar": "كولومبارو",
                "label_fr": "Colombaro"
            },
            {
                "value": 49618,
                "label": "Limite",
                "label_ar": "ليميت",
                "label_fr": "Limite"
            },
            {
                "value": 49619,
                "label": "Perfugas",
                "label_ar": "بيرفوجاس",
                "label_fr": "Perfugas"
            },
            {
                "value": 49620,
                "label": "Ferrero",
                "label_ar": "فيريرو",
                "label_fr": "Ferrero"
            },
            {
                "value": 49621,
                "label": "Guardialfiera",
                "label_ar": "Guardialfiera",
                "label_fr": "Guardialfiera"
            },
            {
                "value": 49622,
                "label": "Novate Mezzola",
                "label_ar": "نوفات ميزولا",
                "label_fr": "Novate Mezzola"
            },
            {
                "value": 49623,
                "label": "Pieve Vecchia",
                "label_ar": "بيفي فيكيا",
                "label_fr": "Pieve Vecchia"
            },
            {
                "value": 49624,
                "label": "Endine Gaiano",
                "label_ar": "Endine Gaiano",
                "label_fr": "Endine Gaiano"
            },
            {
                "value": 49625,
                "label": "Giano Vetusto",
                "label_ar": "جيانو فيتوستو",
                "label_fr": "Giano Vetusto"
            },
            {
                "value": 49626,
                "label": "Castelliri",
                "label_ar": "كاستيليري",
                "label_fr": "Castelliri"
            },
            {
                "value": 49627,
                "label": "Campoli Appennino",
                "label_ar": "كامبولي أبينينو",
                "label_fr": "Campoli Appennino"
            },
            {
                "value": 49628,
                "label": "Scansano",
                "label_ar": "سكانسانو",
                "label_fr": "Scansano"
            },
            {
                "value": 49629,
                "label": "Decollatura",
                "label_ar": "ديكولاتورا",
                "label_fr": "Décollature"
            },
            {
                "value": 49630,
                "label": "Nocelleto",
                "label_ar": "Nocelleto",
                "label_fr": "Nocelleto"
            },
            {
                "value": 49631,
                "label": "Rivergaro",
                "label_ar": "ريفارجارو",
                "label_fr": "Rivergaro"
            },
            {
                "value": 49632,
                "label": "Mairano",
                "label_ar": "مايرانو",
                "label_fr": "Mairano"
            },
            {
                "value": 49633,
                "label": "Ferrandina",
                "label_ar": "فيراندينا",
                "label_fr": "Ferrandina"
            },
            {
                "value": 49634,
                "label": "Castelvecchio Pascoli",
                "label_ar": "كاستلفيكيو باسكولي",
                "label_fr": "Castelvecchio Pascoli"
            },
            {
                "value": 49635,
                "label": "Ceggia",
                "label_ar": "سيجيا",
                "label_fr": "Ceggia"
            },
            {
                "value": 49636,
                "label": "Vignacastrisi",
                "label_ar": "Vignacastrisi",
                "label_fr": "Vignacastrisi"
            },
            {
                "value": 49637,
                "label": "Tratalias",
                "label_ar": "تراتالياس",
                "label_fr": "Tratalias"
            },
            {
                "value": 49638,
                "label": "Serra",
                "label_ar": "سيرا",
                "label_fr": "Serra"
            },
            {
                "value": 49639,
                "label": "Marina di Andora",
                "label_ar": "مارينا دي أندورا",
                "label_fr": "Marina di Andora"
            },
            {
                "value": 49640,
                "label": "Romanengo",
                "label_ar": "رومانينجو",
                "label_fr": "Romanengo"
            },
            {
                "value": 49641,
                "label": "Casirate d'Adda",
                "label_ar": "كاسيرات دادا",
                "label_fr": "Casirate d'Adda"
            },
            {
                "value": 49642,
                "label": "Carbonara al Ticino",
                "label_ar": "كاربونارا آل تيسينو",
                "label_fr": "Carbonara al Ticino"
            },
            {
                "value": 49643,
                "label": "Mulazzano",
                "label_ar": "مولازانو",
                "label_fr": "Mulazzano"
            },
            {
                "value": 49644,
                "label": "Agazzano",
                "label_ar": "اجازانو",
                "label_fr": "Agazzano"
            },
            {
                "value": 49645,
                "label": "Malgrate",
                "label_ar": "Malgrate",
                "label_fr": "Malgrate"
            },
            {
                "value": 49646,
                "label": "San Fedele",
                "label_ar": "سان فيديل",
                "label_fr": "San Fedele"
            },
            {
                "value": 49647,
                "label": "Brunate",
                "label_ar": "برونات",
                "label_fr": "Brunate"
            },
            {
                "value": 49648,
                "label": "Brignano Gera d'Adda",
                "label_ar": "برينيانو جيرا دادا",
                "label_fr": "Brignano Gera d'Adda"
            },
            {
                "value": 49649,
                "label": "Trigolo",
                "label_ar": "تريغولو",
                "label_fr": "Trigolo"
            },
            {
                "value": 49650,
                "label": "Campagnola Emilia",
                "label_ar": "كامبانيولا إميليا",
                "label_fr": "Campagnola Émilie"
            },
            {
                "value": 49651,
                "label": "Monticelli d'Ongina",
                "label_ar": "Monticelli d'Ongina",
                "label_fr": "Monticelli d'Ongina"
            },
            {
                "value": 49652,
                "label": "Madonna di Campiglio",
                "label_ar": "مادونا دي كامبيليو",
                "label_fr": "Madonna di Campiglio"
            },
            {
                "value": 49653,
                "label": "Pieve di Soligo",
                "label_ar": "بيف دي سوليجو",
                "label_fr": "Pieve di Soligo"
            },
            {
                "value": 49654,
                "label": "Longare",
                "label_ar": "Longare",
                "label_fr": "Longare"
            },
            {
                "value": 49655,
                "label": "Vigonovo",
                "label_ar": "فيجونوفو",
                "label_fr": "Vigonovo"
            },
            {
                "value": 49656,
                "label": "Etroubles",
                "label_ar": "إتروبلز",
                "label_fr": "Étroubles"
            },
            {
                "value": 49657,
                "label": "Limone Piemonte",
                "label_ar": "ليمون بيمونتي",
                "label_fr": "Limone Piemonte"
            },
            {
                "value": 49658,
                "label": "Stazzano",
                "label_ar": "ستازانو",
                "label_fr": "Stazzano"
            },
            {
                "value": 49659,
                "label": "Terenzo",
                "label_ar": "تيرينزو",
                "label_fr": "Terenzo"
            },
            {
                "value": 49660,
                "label": "Nurallao",
                "label_ar": "نورالاو",
                "label_fr": "Nurallao"
            },
            {
                "value": 49661,
                "label": "Capranica",
                "label_ar": "كابرانيكا",
                "label_fr": "Capranica"
            },
            {
                "value": 49662,
                "label": "Ficulle",
                "label_ar": "فيكول",
                "label_fr": "Ficulle"
            },
            {
                "value": 49663,
                "label": "Albanella",
                "label_ar": "البانيلا",
                "label_fr": "Albanella"
            },
            {
                "value": 49664,
                "label": "Bisacquino",
                "label_ar": "بيساكوينو",
                "label_fr": "Bisacquino"
            },
            {
                "value": 49665,
                "label": "Canicattì",
                "label_ar": "كانيكاتو",
                "label_fr": "Canicattì"
            },
            {
                "value": 49666,
                "label": "Alanno",
                "label_ar": "ألانو",
                "label_fr": "Alanno"
            },
            {
                "value": 49667,
                "label": "Modigliana",
                "label_ar": "موديجليانا",
                "label_fr": "Modigliana"
            },
            {
                "value": 49668,
                "label": "Treia",
                "label_ar": "تريا",
                "label_fr": "Treia"
            },
            {
                "value": 49669,
                "label": "Campli",
                "label_ar": "كامبلي",
                "label_fr": "Campli"
            },
            {
                "value": 49670,
                "label": "Serra de' Conti",
                "label_ar": "سيرا دي كونتي",
                "label_fr": "Serra de 'Conti"
            },
            {
                "value": 49671,
                "label": "Serracapriola",
                "label_ar": "سيرا كابريولا",
                "label_fr": "Serracapriola"
            },
            {
                "value": 49672,
                "label": "Rodi Garganico",
                "label_ar": "رودي جارجانيكو",
                "label_fr": "Rodi Garganico"
            },
            {
                "value": 49673,
                "label": "San Donato di Lecce",
                "label_ar": "سان دوناتو دي ليتشي",
                "label_fr": "San Donato di Lecce"
            },
            {
                "value": 49674,
                "label": "Solopaca",
                "label_ar": "سولوباكا",
                "label_fr": "Solopaca"
            },
            {
                "value": 49675,
                "label": "Pignataro Maggiore",
                "label_ar": "بيناتارو ماجيوري",
                "label_fr": "Pignataro Maggiore"
            },
            {
                "value": 49676,
                "label": "Pachino",
                "label_ar": "باتشينو",
                "label_fr": "Pachino"
            },
            {
                "value": 49677,
                "label": "Guarrato",
                "label_ar": "غاراتو",
                "label_fr": "Guarrato"
            },
            {
                "value": 49678,
                "label": "Perdasdefogu",
                "label_ar": "Perdasdefogu",
                "label_fr": "Perdasdefogu"
            },
            {
                "value": 49679,
                "label": "Riparbella",
                "label_ar": "ريباربيلا",
                "label_fr": "Riparbella"
            },
            {
                "value": 49680,
                "label": "Orbetello",
                "label_ar": "أوربيتيلو",
                "label_fr": "Orbetello"
            },
            {
                "value": 49681,
                "label": "Rodano",
                "label_ar": "رودانو",
                "label_fr": "Rodano"
            },
            {
                "value": 49682,
                "label": "Soriano nel Cimino",
                "label_ar": "سوريانو نيل سيمينو",
                "label_fr": "Soriano nel Cimino"
            },
            {
                "value": 49683,
                "label": "Santa Maria",
                "label_ar": "سانتا ماريا",
                "label_fr": "Sainte Marie"
            },
            {
                "value": 49684,
                "label": "Miglionico",
                "label_ar": "ميغليونيكو",
                "label_fr": "Miglionico"
            },
            {
                "value": 49685,
                "label": "Latronico",
                "label_ar": "لاترونيكو",
                "label_fr": "Latronico"
            },
            {
                "value": 49686,
                "label": "Magliano Sabina",
                "label_ar": "ماجليانو سابينا",
                "label_fr": "Magliano Sabina"
            },
            {
                "value": 49687,
                "label": "Isola del Cantone",
                "label_ar": "ايزولا ديل كانتوني",
                "label_fr": "Isola del Cantone"
            },
            {
                "value": 49688,
                "label": "Costarainera",
                "label_ar": "كوستارينيرا",
                "label_fr": "Costarainera"
            },
            {
                "value": 49689,
                "label": "Condove",
                "label_ar": "يؤدى",
                "label_fr": "Condove"
            },
            {
                "value": 49690,
                "label": "Frigento",
                "label_ar": "فريجينتو",
                "label_fr": "Frigento"
            },
            {
                "value": 49691,
                "label": "Montecosaro",
                "label_ar": "مونتيكوسارو",
                "label_fr": "Montecosaro"
            },
            {
                "value": 49692,
                "label": "Montescudo",
                "label_ar": "مونتيسكودو",
                "label_fr": "Montescudo"
            },
            {
                "value": 49693,
                "label": "Carloforte",
                "label_ar": "كارلوفورت",
                "label_fr": "Carloforte"
            },
            {
                "value": 49694,
                "label": "Villabate",
                "label_ar": "فيلاباتي",
                "label_fr": "Villabate"
            },
            {
                "value": 49695,
                "label": "Attigliano",
                "label_ar": "أتيجليانو",
                "label_fr": "Attigliano"
            },
            {
                "value": 49696,
                "label": "Antella",
                "label_ar": "أنتيلا",
                "label_fr": "Antella"
            },
            {
                "value": 49697,
                "label": "Serrano",
                "label_ar": "سيرانو",
                "label_fr": "Serrano"
            },
            {
                "value": 49698,
                "label": "Maltignano",
                "label_ar": "مالتينيانو",
                "label_fr": "Maltignano"
            },
            {
                "value": 49699,
                "label": "Polesella",
                "label_ar": "بوليسلا",
                "label_fr": "Polesella"
            },
            {
                "value": 49700,
                "label": "Quaregna",
                "label_ar": "كواريجنا",
                "label_fr": "Quaregna"
            },
            {
                "value": 49701,
                "label": "Cison di Valmarino",
                "label_ar": "سيسون دي فالمارينو",
                "label_fr": "Cison di Valmarino"
            },
            {
                "value": 49702,
                "label": "Cavalese",
                "label_ar": "كافاليس",
                "label_fr": "Cavalese"
            },
            {
                "value": 49703,
                "label": "Badia",
                "label_ar": "البادية",
                "label_fr": "Badia"
            },
            {
                "value": 49704,
                "label": "Morolo",
                "label_ar": "مورولو",
                "label_fr": "Morolo"
            },
            {
                "value": 49705,
                "label": "Castiglione d'Adda",
                "label_ar": "كاستيجليون دادا",
                "label_fr": "Castiglione d'Adda"
            },
            {
                "value": 49706,
                "label": "Viggiù",
                "label_ar": "فيجيو",
                "label_fr": "Viggiù"
            },
            {
                "value": 49707,
                "label": "Vicarello",
                "label_ar": "فيكاريلو",
                "label_fr": "Vicarello"
            },
            {
                "value": 49708,
                "label": "Montepulciano",
                "label_ar": "مونتيبولتشانو",
                "label_fr": "Montepulciano"
            },
            {
                "value": 49709,
                "label": "Tavarnuzze",
                "label_ar": "تافارنوزي",
                "label_fr": "Tavarnuzze"
            },
            {
                "value": 49710,
                "label": "Lezzeno",
                "label_ar": "ليزينو",
                "label_fr": "Lezzeno"
            },
            {
                "value": 49711,
                "label": "Induno Olona",
                "label_ar": "إندونو أولونا",
                "label_fr": "Induno Olona"
            },
            {
                "value": 49712,
                "label": "Benetutti",
                "label_ar": "بينيتوتي",
                "label_fr": "Benetutti"
            },
            {
                "value": 49713,
                "label": "Bonarcado",
                "label_ar": "بوناركادو",
                "label_fr": "Bonarcado"
            },
            {
                "value": 49714,
                "label": "Alpette",
                "label_ar": "ألبيت",
                "label_fr": "Alpette"
            },
            {
                "value": 49715,
                "label": "Ostigliano",
                "label_ar": "أوستيجليانو",
                "label_fr": "Ostigliano"
            },
            {
                "value": 49716,
                "label": "Quiliano",
                "label_ar": "كيليانو",
                "label_fr": "Quiliano"
            },
            {
                "value": 49717,
                "label": "Pieve di Cento",
                "label_ar": "بيفي دي سينتو",
                "label_fr": "Pieve di Cento"
            },
            {
                "value": 49718,
                "label": "Veronella",
                "label_ar": "فيرونيلا",
                "label_fr": "Veronella"
            },
            {
                "value": 49719,
                "label": "Tortolì",
                "label_ar": "تورتولو",
                "label_fr": "Tortolì"
            },
            {
                "value": 49720,
                "label": "Ceriano Laghetto",
                "label_ar": "سيريانو لاغيتو",
                "label_fr": "Ceriano Laghetto"
            },
            {
                "value": 49721,
                "label": "Sovere",
                "label_ar": "سيفر",
                "label_fr": "Sovere"
            },
            {
                "value": 49722,
                "label": "Azzano",
                "label_ar": "أزانو",
                "label_fr": "Azzano"
            },
            {
                "value": 49723,
                "label": "Capralba",
                "label_ar": "كابرالبا",
                "label_fr": "Capralba"
            },
            {
                "value": 49724,
                "label": "Quinzano d'Oglio",
                "label_ar": "كوينزانو دوجليو",
                "label_fr": "Quinzano d'Oglio"
            },
            {
                "value": 49725,
                "label": "Mirabello Monferrato",
                "label_ar": "ميرابيلو مونفيراتو",
                "label_fr": "Mirabello Monferrato"
            },
            {
                "value": 49726,
                "label": "Parre",
                "label_ar": "باري",
                "label_fr": "Parre"
            },
            {
                "value": 49727,
                "label": "Francenigo",
                "label_ar": "فرانسينيجو",
                "label_fr": "Francenigo"
            },
            {
                "value": 49728,
                "label": "Castelnuovo Berardenga",
                "label_ar": "Castelnuovo Berardenga",
                "label_fr": "Castelnuovo Berardenga"
            },
            {
                "value": 49729,
                "label": "Marciana Marina",
                "label_ar": "مارسيانا مارينا",
                "label_fr": "Marciana Marina"
            },
            {
                "value": 49730,
                "label": "Soci",
                "label_ar": "سوسي",
                "label_fr": "Soci"
            },
            {
                "value": 49731,
                "label": "Arcidosso",
                "label_ar": "أركيدسو",
                "label_fr": "Arcidosso"
            },
            {
                "value": 49732,
                "label": "Centola",
                "label_ar": "سينتولا",
                "label_fr": "Centola"
            },
            {
                "value": 49733,
                "label": "Gragnano Trebbiense",
                "label_ar": "جرانانو تريبينسي",
                "label_fr": "Gragnano Trebbiense"
            },
            {
                "value": 49734,
                "label": "Ronchis",
                "label_ar": "رونشيس",
                "label_fr": "Ronchis"
            },
            {
                "value": 49735,
                "label": "Puos d'Alpago",
                "label_ar": "بوس ألباغو",
                "label_fr": "Puos d'Alpago"
            },
            {
                "value": 49736,
                "label": "Cariati",
                "label_ar": "كارياتي",
                "label_fr": "Cariati"
            },
            {
                "value": 49737,
                "label": "Montalto di Castro",
                "label_ar": "مونتالتو دي كاسترو",
                "label_fr": "Montalto di Castro"
            },
            {
                "value": 49738,
                "label": "Sonnino",
                "label_ar": "سونينو",
                "label_fr": "Sonnino"
            },
            {
                "value": 49739,
                "label": "Torri in Sabina",
                "label_ar": "توري في سابينا",
                "label_fr": "Torri à Sabina"
            },
            {
                "value": 49740,
                "label": "Borgo Priolo",
                "label_ar": "بورجو بريولو",
                "label_fr": "Borgo Priolo"
            },
            {
                "value": 49741,
                "label": "Besano",
                "label_ar": "بيسانو",
                "label_fr": "Besano"
            },
            {
                "value": 49742,
                "label": "Levata",
                "label_ar": "ليفاتا",
                "label_fr": "Levata"
            },
            {
                "value": 49743,
                "label": "Berzo Demo",
                "label_ar": "بيرزو ديمو",
                "label_fr": "Démo Berzo"
            },
            {
                "value": 49744,
                "label": "Revere",
                "label_ar": "احترام",
                "label_fr": "Révérer"
            },
            {
                "value": 49745,
                "label": "Pieve San Giacomo",
                "label_ar": "بيفي سان جياكومو",
                "label_fr": "Pieve San Giacomo"
            },
            {
                "value": 49746,
                "label": "San Giusto Canavese",
                "label_ar": "سان جوستو كانافيزي",
                "label_fr": "San Giusto Canavese"
            },
            {
                "value": 49747,
                "label": "Ameno",
                "label_ar": "امينو",
                "label_fr": "Ameno"
            },
            {
                "value": 49748,
                "label": "Omignano",
                "label_ar": "أوميجنانو",
                "label_fr": "Omignano"
            },
            {
                "value": 49749,
                "label": "Foglianise",
                "label_ar": "Foglianise",
                "label_fr": "Foglianise"
            },
            {
                "value": 49750,
                "label": "Bassano in Teverina",
                "label_ar": "باسانو في تيفرينا",
                "label_fr": "Bassano à Teverina"
            },
            {
                "value": 49751,
                "label": "Lunamatrona",
                "label_ar": "لوناماترونا",
                "label_fr": "Lunamatrona"
            },
            {
                "value": 49752,
                "label": "Moricone",
                "label_ar": "موريكون",
                "label_fr": "Moricone"
            },
            {
                "value": 49753,
                "label": "Camburzano",
                "label_ar": "كامبورزانو",
                "label_fr": "Camburzano"
            },
            {
                "value": 49754,
                "label": "Sasso d'Ombrone",
                "label_ar": "ساسو دي أومبروني",
                "label_fr": "Sasso d'Ombrone"
            },
            {
                "value": 49755,
                "label": "Martiniana Po",
                "label_ar": "مارتينيانا بو",
                "label_fr": "Martiniana Po"
            },
            {
                "value": 49756,
                "label": "San Pietro",
                "label_ar": "سان بيترو",
                "label_fr": "San Pietro"
            },
            {
                "value": 49757,
                "label": "Sassello",
                "label_ar": "ساسيلو",
                "label_fr": "Sassello"
            },
            {
                "value": 49758,
                "label": "Fara Novarese",
                "label_ar": "فارا نوفاريس",
                "label_fr": "Fara Novarese"
            },
            {
                "value": 49759,
                "label": "Copertino",
                "label_ar": "كوبرتينو",
                "label_fr": "Copertino"
            },
            {
                "value": 49760,
                "label": "Carolei",
                "label_ar": "كارولي",
                "label_fr": "Carolei"
            },
            {
                "value": 49761,
                "label": "Maccarese",
                "label_ar": "ماكاريز",
                "label_fr": "Maccarese"
            },
            {
                "value": 49762,
                "label": "Pirri",
                "label_ar": "بيري",
                "label_fr": "Pirri"
            },
            {
                "value": 49763,
                "label": "Santo Spirito",
                "label_ar": "سانتو سبيريتو",
                "label_fr": "Santo Spirito"
            },
            {
                "value": 49764,
                "label": "Sapri",
                "label_ar": "سابري",
                "label_fr": "Sapri"
            },
            {
                "value": 49765,
                "label": "Venosa",
                "label_ar": "فينوسا",
                "label_fr": "Venosa"
            },
            {
                "value": 49766,
                "label": "Basciano",
                "label_ar": "باشيانو",
                "label_fr": "Basciano"
            },
            {
                "value": 49767,
                "label": "Cellino Attanasio",
                "label_ar": "تشيلينو أتاناسيو",
                "label_fr": "Cellino Attanasio"
            },
            {
                "value": 49768,
                "label": "Lastra",
                "label_ar": "Lastra",
                "label_fr": "Lastra"
            },
            {
                "value": 49769,
                "label": "Montecchia di Crosara",
                "label_ar": "مونتيكيا دي كروسارا",
                "label_fr": "Montecchia di Crosara"
            },
            {
                "value": 49770,
                "label": "Monteviale",
                "label_ar": "مونتيفيالي",
                "label_fr": "Monteviale"
            },
            {
                "value": 49771,
                "label": "Fiavè",
                "label_ar": "فياف",
                "label_fr": "Fiavè"
            },
            {
                "value": 49772,
                "label": "Lago",
                "label_ar": "لاغو",
                "label_fr": "Lago"
            },
            {
                "value": 49773,
                "label": "Pertosa",
                "label_ar": "بيرتوزا",
                "label_fr": "Pertosa"
            },
            {
                "value": 49774,
                "label": "Ronco-Chiesa",
                "label_ar": "رونكو كييزا",
                "label_fr": "Ronco-Chiesa"
            },
            {
                "value": 49775,
                "label": "Pray",
                "label_ar": "صلى",
                "label_fr": "Prier"
            },
            {
                "value": 49776,
                "label": "Carpinello",
                "label_ar": "كاربينيلو",
                "label_fr": "Carpinello"
            },
            {
                "value": 49777,
                "label": "Lago",
                "label_ar": "لاغو",
                "label_fr": "Lago"
            },
            {
                "value": 49778,
                "label": "Luciano",
                "label_ar": "لوتشيانو",
                "label_fr": "Luciano"
            },
            {
                "value": 49779,
                "label": "Camisa",
                "label_ar": "كاميسا",
                "label_fr": "Camisa"
            },
            {
                "value": 49780,
                "label": "Sommariva Perno",
                "label_ar": "سوماريفا بيرنو",
                "label_fr": "Sommariva Perno"
            },
            {
                "value": 49781,
                "label": "Boschetto",
                "label_ar": "بوشيتو",
                "label_fr": "Boschetto"
            },
            {
                "value": 49782,
                "label": "Ville",
                "label_ar": "فيل",
                "label_fr": "Ville"
            },
            {
                "value": 49783,
                "label": "Casa Gherardi",
                "label_ar": "كازا غيراردي",
                "label_fr": "Casa Gherardi"
            },
            {
                "value": 49784,
                "label": "Malè",
                "label_ar": "الذكر",
                "label_fr": "Masculin"
            },
            {
                "value": 49785,
                "label": "Rovere",
                "label_ar": "روفر",
                "label_fr": "Rovere"
            },
            {
                "value": 49786,
                "label": "Giardinetto Vecchio",
                "label_ar": "جياردينيتو فيكيو",
                "label_fr": "Giardinetto Vecchio"
            },
            {
                "value": 49787,
                "label": "Pellegrini",
                "label_ar": "بيليجريني",
                "label_fr": "Pellegrini"
            },
            {
                "value": 49788,
                "label": "Bettolle",
                "label_ar": "بيتول",
                "label_fr": "Bettolle"
            },
            {
                "value": 49789,
                "label": "Leonessa",
                "label_ar": "ليونيسا",
                "label_fr": "Léonessa"
            },
            {
                "value": 49790,
                "label": "Chiara",
                "label_ar": "كيارا",
                "label_fr": "Chiara"
            },
            {
                "value": 49791,
                "label": "Spinetta Marengo",
                "label_ar": "سبينيتا مارينغو",
                "label_fr": "Spinetta Marengo"
            },
            {
                "value": 49792,
                "label": "Balzola",
                "label_ar": "بالزولا",
                "label_fr": "Balzola"
            },
            {
                "value": 49793,
                "label": "Lustra",
                "label_ar": "لوسترا",
                "label_fr": "Lustra"
            },
            {
                "value": 49794,
                "label": "Bressanvido",
                "label_ar": "بريسانفيدو",
                "label_fr": "Bressanvido"
            },
            {
                "value": 49795,
                "label": "Gangi",
                "label_ar": "جانجي",
                "label_fr": "Gangi"
            },
            {
                "value": 49796,
                "label": "Benevello",
                "label_ar": "بينيفيلو",
                "label_fr": "Benevello"
            },
            {
                "value": 49797,
                "label": "Perteole",
                "label_ar": "بيرتيول",
                "label_fr": "Perteole"
            },
            {
                "value": 49798,
                "label": "Cusino",
                "label_ar": "كوزينو",
                "label_fr": "Cusino"
            },
            {
                "value": 49799,
                "label": "Torre d'Isola",
                "label_ar": "توري ديسولا",
                "label_fr": "Torre d'Isola"
            },
            {
                "value": 49800,
                "label": "Frugarolo",
                "label_ar": "فروغارولو",
                "label_fr": "Frugarolo"
            },
            {
                "value": 49801,
                "label": "Teglio Veneto",
                "label_ar": "تيجليو فينيتو",
                "label_fr": "Teglio Veneto"
            },
            {
                "value": 49802,
                "label": "San Valentino Torio",
                "label_ar": "سان فالنتينو توريو",
                "label_fr": "San Valentino Torio"
            },
            {
                "value": 49803,
                "label": "Baruchella",
                "label_ar": "باروتشيلا",
                "label_fr": "Baruchella"
            },
            {
                "value": 49804,
                "label": "Cilavegna",
                "label_ar": "سيلافيجنا",
                "label_fr": "Cilavegna"
            },
            {
                "value": 49805,
                "label": "Vidardo",
                "label_ar": "فيداردو",
                "label_fr": "Vidardo"
            },
            {
                "value": 49806,
                "label": "Montemarano",
                "label_ar": "مونتيمارانو",
                "label_fr": "Montemarano"
            },
            {
                "value": 49807,
                "label": "Marta",
                "label_ar": "مارتا",
                "label_fr": "Marta"
            },
            {
                "value": 49808,
                "label": "Germagnano",
                "label_ar": "جيرمانانو",
                "label_fr": "Germagnano"
            },
            {
                "value": 49809,
                "label": "Lutago",
                "label_ar": "لوتاغو",
                "label_fr": "Lutago"
            },
            {
                "value": 49810,
                "label": "Bargagli",
                "label_ar": "Bargagli",
                "label_fr": "Bargagli"
            },
            {
                "value": 49811,
                "label": "San Prospero",
                "label_ar": "سان بروسبيرو",
                "label_fr": "San Prospero"
            },
            {
                "value": 49812,
                "label": "Mozzecane",
                "label_ar": "موزيكاني",
                "label_fr": "Mozzécane"
            },
            {
                "value": 49813,
                "label": "San Mauro",
                "label_ar": "سان ماورو",
                "label_fr": "San Mauro"
            },
            {
                "value": 49814,
                "label": "Montesano Salentino",
                "label_ar": "مونتيسانو سالينتينو",
                "label_fr": "Montesano Salentino"
            },
            {
                "value": 49815,
                "label": "Ponticelli",
                "label_ar": "بونتيشيلي",
                "label_fr": "Ponticelli"
            },
            {
                "value": 49816,
                "label": "Camporosso",
                "label_ar": "كامبوروسو",
                "label_fr": "Camporosso"
            },
            {
                "value": 49817,
                "label": "Porto d'Ascoli",
                "label_ar": "بورتو داسكولي",
                "label_fr": "Porto d'Ascoli"
            },
            {
                "value": 49818,
                "label": "Monforte San Giorgio",
                "label_ar": "مونفورتي سان جورجيو",
                "label_fr": "Monforte San Giorgio"
            },
            {
                "value": 49819,
                "label": "Quarto Inferiore",
                "label_ar": "Quarto Inferiore",
                "label_fr": "Quarto Inferiore"
            },
            {
                "value": 49820,
                "label": "Zermeghedo",
                "label_ar": "زيرمغيدو",
                "label_fr": "Zermeghedo"
            },
            {
                "value": 49821,
                "label": "Bolzano",
                "label_ar": "بولزانو",
                "label_fr": "Bolzano"
            },
            {
                "value": 49822,
                "label": "Faiano",
                "label_ar": "فايانو",
                "label_fr": "Faiano"
            },
            {
                "value": 49823,
                "label": "Orgosolo",
                "label_ar": "Orgosolo",
                "label_fr": "Orgosolo"
            },
            {
                "value": 49824,
                "label": "Piobesi Torinese",
                "label_ar": "Piobesi Torinese",
                "label_fr": "Piobesi Torinese"
            },
            {
                "value": 49825,
                "label": "Bonassola",
                "label_ar": "بوناسولا",
                "label_fr": "Bonassola"
            },
            {
                "value": 49826,
                "label": "Peschici",
                "label_ar": "Peschici",
                "label_fr": "Peschici"
            },
            {
                "value": 49827,
                "label": "Fonzaso",
                "label_ar": "Fonzaso",
                "label_fr": "Fonzaso"
            },
            {
                "value": 49828,
                "label": "Mistretta",
                "label_ar": "ميستريتا",
                "label_fr": "Mistretta"
            },
            {
                "value": 49829,
                "label": "Petriano",
                "label_ar": "بيتريانو",
                "label_fr": "Petriano"
            },
            {
                "value": 49830,
                "label": "Courmayeur",
                "label_ar": "كورمايور",
                "label_fr": "Courmayeur"
            },
            {
                "value": 49831,
                "label": "Serradifalco",
                "label_ar": "سيراديفالكو",
                "label_fr": "Serradifalco"
            },
            {
                "value": 49832,
                "label": "Roggiano Gravina",
                "label_ar": "روجيانو جرافينا",
                "label_fr": "Roggiano Gravina"
            },
            {
                "value": 49833,
                "label": "Riola Sardo",
                "label_ar": "ريولا ساردو",
                "label_fr": "Riola Sardo"
            },
            {
                "value": 49834,
                "label": "Guardia Sanframondi",
                "label_ar": "جوارديا سانفراموندي",
                "label_fr": "Guardia Sanframondi"
            },
            {
                "value": 49835,
                "label": "Pietrapaola",
                "label_ar": "بيتراباولا",
                "label_fr": "Pietrapaola"
            },
            {
                "value": 49836,
                "label": "Falerna",
                "label_ar": "فاليرنا",
                "label_fr": "Falerna"
            },
            {
                "value": 49837,
                "label": "Civitanova Alta",
                "label_ar": "تشيفيتانوفا ألتا",
                "label_fr": "Civitanova Alta"
            },
            {
                "value": 49838,
                "label": "Sedegliano",
                "label_ar": "Sedegliano",
                "label_fr": "Sedegliano"
            },
            {
                "value": 49839,
                "label": "Savio",
                "label_ar": "سافيو",
                "label_fr": "Savio"
            },
            {
                "value": 49840,
                "label": "Cellio",
                "label_ar": "تشيلو",
                "label_fr": "Cellio"
            },
            {
                "value": 49841,
                "label": "Pozzo",
                "label_ar": "بوزو",
                "label_fr": "Pozzo"
            },
            {
                "value": 49842,
                "label": "Castel Gabbiano",
                "label_ar": "كاستل جابيانو",
                "label_fr": "Castel Gabbiano"
            },
            {
                "value": 49843,
                "label": "Sospiro",
                "label_ar": "سوسبيرو",
                "label_fr": "Sospiro"
            },
            {
                "value": 49844,
                "label": "San Giacomo",
                "label_ar": "سان جياكومو",
                "label_fr": "San Giacomo"
            },
            {
                "value": 49845,
                "label": "Trovo",
                "label_ar": "تروفو",
                "label_fr": "Trovo"
            },
            {
                "value": 49846,
                "label": "Villa Estense",
                "label_ar": "فيلا استينس",
                "label_fr": "Villa Estense"
            },
            {
                "value": 49847,
                "label": "Boara",
                "label_ar": "بوارا",
                "label_fr": "Boara"
            },
            {
                "value": 49848,
                "label": "Villa Santa Lucia",
                "label_ar": "فيلا سانتا لوسيا",
                "label_fr": "Villa Santa Lucia"
            },
            {
                "value": 49849,
                "label": "Binanuova",
                "label_ar": "بينانوفا",
                "label_fr": "Binanuova"
            },
            {
                "value": 49850,
                "label": "Pombia",
                "label_ar": "بومبيا",
                "label_fr": "Pombia"
            },
            {
                "value": 49851,
                "label": "Medole",
                "label_ar": "ميدول",
                "label_fr": "Médole"
            },
            {
                "value": 49852,
                "label": "Ostiano",
                "label_ar": "أوستيانو",
                "label_fr": "Ostiano"
            },
            {
                "value": 49853,
                "label": "Pozza di Fassa",
                "label_ar": "بوزا دي فاسا",
                "label_fr": "Pozza di Fassa"
            },
            {
                "value": 49854,
                "label": "Pinzolo",
                "label_ar": "بينزولو",
                "label_fr": "Pinzolo"
            },
            {
                "value": 49855,
                "label": "Bucciano",
                "label_ar": "بوكيانو",
                "label_fr": "Bucciano"
            },
            {
                "value": 49856,
                "label": "Pratola Peligna",
                "label_ar": "براتولا بيليغنا",
                "label_fr": "Pratola Peligna"
            },
            {
                "value": 49857,
                "label": "Santa Maria Imbaro",
                "label_ar": "سانتا ماريا إمبارو",
                "label_fr": "Santa Maria Imbaro"
            },
            {
                "value": 49858,
                "label": "Chiusavecchia",
                "label_ar": "تشيوسافيكيا",
                "label_fr": "Chiusavecchia"
            },
            {
                "value": 49859,
                "label": "Mirabella Imbaccari",
                "label_ar": "ميرابيلا إمبكاري",
                "label_fr": "Mirabella Imbaccari"
            },
            {
                "value": 49860,
                "label": "Montescaglioso",
                "label_ar": "مونتيسكاليوسو",
                "label_fr": "Montescaglioso"
            },
            {
                "value": 49861,
                "label": "Bonorva",
                "label_ar": "بونورفا",
                "label_fr": "Bonorva"
            },
            {
                "value": 49862,
                "label": "Trivignano",
                "label_ar": "تريفيجنانو",
                "label_fr": "Trivignano"
            },
            {
                "value": 49863,
                "label": "Sarmede",
                "label_ar": "سارميدي",
                "label_fr": "Sarmède"
            },
            {
                "value": 49864,
                "label": "San Martino in Pensilis",
                "label_ar": "سان مارتينو في بينسيليس",
                "label_fr": "San Martino à Pensilis"
            },
            {
                "value": 49865,
                "label": "San Giacomo degli Schiavoni",
                "label_ar": "سان جياكومو ديجلي شيافوني",
                "label_fr": "San Giacomo degli Schiavoni"
            },
            {
                "value": 49866,
                "label": "Rota Greca",
                "label_ar": "روتا جريكا",
                "label_fr": "Rota Greca"
            },
            {
                "value": 49867,
                "label": "Vado",
                "label_ar": "فادو",
                "label_fr": "Vado"
            },
            {
                "value": 49868,
                "label": "Villamassargia",
                "label_ar": "فيلاماسارجيا",
                "label_fr": "Villamassargia"
            },
            {
                "value": 49869,
                "label": "Cabras",
                "label_ar": "كابراس",
                "label_fr": "Cabras"
            },
            {
                "value": 49870,
                "label": "San Vito",
                "label_ar": "سان فيتو",
                "label_fr": "San Vito"
            },
            {
                "value": 49871,
                "label": "Perdaxius",
                "label_ar": "بيرداكسيوس",
                "label_fr": "Perdaxius"
            },
            {
                "value": 49872,
                "label": "Collinas",
                "label_ar": "كوليناس",
                "label_fr": "Collinas"
            },
            {
                "value": 49873,
                "label": "Martirano",
                "label_ar": "مارتيرانو",
                "label_fr": "Martirano"
            },
            {
                "value": 49874,
                "label": "Grassano",
                "label_ar": "غراسانو",
                "label_fr": "Grassano"
            },
            {
                "value": 49875,
                "label": "Castrofilippo",
                "label_ar": "كاستروفيليبو",
                "label_fr": "Castrofilippo"
            },
            {
                "value": 49876,
                "label": "Mozzagrogna",
                "label_ar": "Mozzagrogna",
                "label_fr": "Mozzagrogna"
            },
            {
                "value": 49877,
                "label": "Scanno",
                "label_ar": "سكانو",
                "label_fr": "Scanno"
            },
            {
                "value": 49878,
                "label": "Contrada",
                "label_ar": "كونترادا",
                "label_fr": "Contrada"
            },
            {
                "value": 49879,
                "label": "Piazza al Serchio",
                "label_ar": "ساحة السيرشيو",
                "label_fr": "Piazza al Serchio"
            },
            {
                "value": 49880,
                "label": "Magliano",
                "label_ar": "ماجليانو",
                "label_fr": "Magliano"
            },
            {
                "value": 49881,
                "label": "Piazza Roma",
                "label_ar": "بيازا روما",
                "label_fr": "Piazza Roma"
            },
            {
                "value": 49882,
                "label": "Mignano Monte Lungo",
                "label_ar": "مينانو مونتي لونغو",
                "label_fr": "Mignano Monte Lungo"
            },
            {
                "value": 49883,
                "label": "Villanova",
                "label_ar": "فيلانوفا",
                "label_fr": "Villanova"
            },
            {
                "value": 49884,
                "label": "Petilia Policastro",
                "label_ar": "بيتيليا بوليكاسترو",
                "label_fr": "Petilia Policastro"
            },
            {
                "value": 49885,
                "label": "Montepiano",
                "label_ar": "مونتيبيانو",
                "label_fr": "Montepiano"
            },
            {
                "value": 49886,
                "label": "Pauli Arbarei",
                "label_ar": "باولي أرباري",
                "label_fr": "Pauli Arbarei"
            },
            {
                "value": 49887,
                "label": "Riva",
                "label_ar": "ريفا",
                "label_fr": "Riva"
            },
            {
                "value": 49888,
                "label": "Campodipietra",
                "label_ar": "كامبوديبيترا",
                "label_fr": "Campodipietra"
            },
            {
                "value": 49889,
                "label": "Parghelia",
                "label_ar": "بارغيليا",
                "label_fr": "Parghelia"
            },
            {
                "value": 49890,
                "label": "Matino",
                "label_ar": "ماتينو",
                "label_fr": "Matino"
            },
            {
                "value": 49891,
                "label": "Castri di Lecce",
                "label_ar": "كاستري دي ليتشي",
                "label_fr": "Castri di Lecce"
            },
            {
                "value": 49892,
                "label": "Roccabianca",
                "label_ar": "روكابيانكا",
                "label_fr": "Roccabianca"
            },
            {
                "value": 49893,
                "label": "Santa Maria",
                "label_ar": "سانتا ماريا",
                "label_fr": "Sainte Marie"
            },
            {
                "value": 49894,
                "label": "Montelanico",
                "label_ar": "مونتيلانيكو",
                "label_fr": "Montelanico"
            },
            {
                "value": 49895,
                "label": "Arizzano",
                "label_ar": "أريزانو",
                "label_fr": "Arizzano"
            },
            {
                "value": 49896,
                "label": "Gais",
                "label_ar": "جايس",
                "label_fr": "Gais"
            },
            {
                "value": 49897,
                "label": "Zambana",
                "label_ar": "زامبانا",
                "label_fr": "Zambana"
            },
            {
                "value": 49898,
                "label": "Canonica",
                "label_ar": "كانونيكا",
                "label_fr": "Canonica"
            },
            {
                "value": 49899,
                "label": "Sannazzaro de' Burgondi",
                "label_ar": "Sannazzaro de 'Burgondi",
                "label_fr": "Sannazzaro de 'Burgondi"
            },
            {
                "value": 49900,
                "label": "Donorì",
                "label_ar": "المانح",
                "label_fr": "Donateur"
            },
            {
                "value": 49901,
                "label": "Alvignanello",
                "label_ar": "الفيجنانيلو",
                "label_fr": "Alvignanello"
            },
            {
                "value": 49902,
                "label": "Ponte Lambro",
                "label_ar": "بونتي لامبرو",
                "label_fr": "Ponte Lambro"
            },
            {
                "value": 49903,
                "label": "Mogliano",
                "label_ar": "موغليانو",
                "label_fr": "Mogliano"
            },
            {
                "value": 49904,
                "label": "Oliena",
                "label_ar": "اولينا",
                "label_fr": "Oliena"
            },
            {
                "value": 49905,
                "label": "San Teodoro",
                "label_ar": "سان تيودورو",
                "label_fr": "San Teodoro"
            },
            {
                "value": 49906,
                "label": "Meana Sardo",
                "label_ar": "مينا ساردو",
                "label_fr": "Meana Sardo"
            },
            {
                "value": 49907,
                "label": "Sperone",
                "label_ar": "سبيرون",
                "label_fr": "Sperone"
            },
            {
                "value": 49908,
                "label": "Lammari",
                "label_ar": "العماري",
                "label_fr": "Lammari"
            },
            {
                "value": 49909,
                "label": "Campora",
                "label_ar": "كامبورا",
                "label_fr": "Campora"
            },
            {
                "value": 49910,
                "label": "Forino",
                "label_ar": "فورينو",
                "label_fr": "Forino"
            },
            {
                "value": 49911,
                "label": "Melito di Porto Salvo",
                "label_ar": "ميليتو دي بورتو سالفو",
                "label_fr": "Melito di Porto Salvo"
            },
            {
                "value": 49912,
                "label": "Peccioli",
                "label_ar": "بيتشيولي",
                "label_fr": "Peccioli"
            },
            {
                "value": 49913,
                "label": "Ravello",
                "label_ar": "رافيلو",
                "label_fr": "Ravello"
            },
            {
                "value": 49914,
                "label": "Gallicano",
                "label_ar": "جاليكانو",
                "label_fr": "Gallicano"
            },
            {
                "value": 49915,
                "label": "Casperia",
                "label_ar": "كاسبيريا",
                "label_fr": "Casperia"
            },
            {
                "value": 49916,
                "label": "Solato",
                "label_ar": "سولاتو",
                "label_fr": "Solato"
            },
            {
                "value": 49917,
                "label": "Chiavazza",
                "label_ar": "شيافازا",
                "label_fr": "Chiavazza"
            },
            {
                "value": 49918,
                "label": "Welschnofen",
                "label_ar": "ويلشنوفين",
                "label_fr": "Welschnofen"
            },
            {
                "value": 49919,
                "label": "Casola di Napoli",
                "label_ar": "كاسولا دي نابولي",
                "label_fr": "Casola di Napoli"
            },
            {
                "value": 49920,
                "label": "Cannero Riviera",
                "label_ar": "كانيرو ريفييرا",
                "label_fr": "Cannero Riviera"
            },
            {
                "value": 49921,
                "label": "Lurago Marinone",
                "label_ar": "لوراغو مارينوني",
                "label_fr": "Lurago Marinone"
            },
            {
                "value": 49922,
                "label": "Lenno",
                "label_ar": "لينو",
                "label_fr": "Lenno"
            },
            {
                "value": 49923,
                "label": "Nocciano",
                "label_ar": "نوكيانو",
                "label_fr": "Nocciano"
            },
            {
                "value": 49924,
                "label": "Grisignano di Zocco",
                "label_ar": "Grisignano di Zocco",
                "label_fr": "Grisignano di Zocco"
            },
            {
                "value": 49925,
                "label": "Quattro Castella",
                "label_ar": "كواترو كاستيلا",
                "label_fr": "Quattro Castella"
            },
            {
                "value": 49926,
                "label": "Ragogna",
                "label_ar": "راجوجنا",
                "label_fr": "Ragogna"
            },
            {
                "value": 49927,
                "label": "Piumazzo",
                "label_ar": "بيومازو",
                "label_fr": "Piumazzo"
            },
            {
                "value": 49928,
                "label": "Castello",
                "label_ar": "كاستيلو",
                "label_fr": "Castello"
            },
            {
                "value": 49929,
                "label": "Ischitella",
                "label_ar": "إيشيتيلا",
                "label_fr": "Ischitella"
            },
            {
                "value": 49930,
                "label": "Grazzanise",
                "label_ar": "جرازانيس",
                "label_fr": "Grazzanise"
            },
            {
                "value": 49931,
                "label": "Cagnano Varano",
                "label_ar": "كاجنانو فارانو",
                "label_fr": "Cagnano Varano"
            },
            {
                "value": 49932,
                "label": "Montopoli in Val d'Arno",
                "label_ar": "مونتوبولي في فال دارو",
                "label_fr": "Montopoli dans le Val d'Arno"
            },
            {
                "value": 49933,
                "label": "Arzachena",
                "label_ar": "أرزاتشينا",
                "label_fr": "Arzachena"
            },
            {
                "value": 49934,
                "label": "San Dorligo della Valle",
                "label_ar": "سان دورليجو ديلا فالي",
                "label_fr": "San Dorligo della Valle"
            },
            {
                "value": 49935,
                "label": "Corno di Rosazzo",
                "label_ar": "كورنو دي روزاتسو",
                "label_fr": "Corno di Rosazzo"
            },
            {
                "value": 49936,
                "label": "Zelarino",
                "label_ar": "زيلارينو",
                "label_fr": "Zelarino"
            },
            {
                "value": 49937,
                "label": "Pietra",
                "label_ar": "بيترا",
                "label_fr": "Pietra"
            },
            {
                "value": 49938,
                "label": "Zinasco",
                "label_ar": "زيناسكو",
                "label_fr": "Zinasco"
            },
            {
                "value": 49939,
                "label": "Tonezza del Cimone",
                "label_ar": "Tonezza del Cimone",
                "label_fr": "Tonezza del Cimone"
            },
            {
                "value": 49940,
                "label": "Vitulano",
                "label_ar": "فيتولانو",
                "label_fr": "Vitulano"
            },
            {
                "value": 49941,
                "label": "San Martino Sannita",
                "label_ar": "سان مارتينو سانيتا",
                "label_fr": "San Martino Sannita"
            },
            {
                "value": 49942,
                "label": "Sturno",
                "label_ar": "ستورنو",
                "label_fr": "Sturno"
            },
            {
                "value": 49943,
                "label": "Rotello",
                "label_ar": "روتيللو",
                "label_fr": "Rotello"
            },
            {
                "value": 49944,
                "label": "Santa Maria Nuova",
                "label_ar": "سانتا ماريا نوفا",
                "label_fr": "Santa Maria Nuova"
            },
            {
                "value": 49945,
                "label": "Magliano di Tenna",
                "label_ar": "ماجليانو دي تينا",
                "label_fr": "Magliano di Tenna"
            },
            {
                "value": 49946,
                "label": "Boccheggiano",
                "label_ar": "بوكيجيانو",
                "label_fr": "Boccheggiano"
            },
            {
                "value": 49947,
                "label": "Maierato",
                "label_ar": "مايراتو",
                "label_fr": "Maierato"
            },
            {
                "value": 49948,
                "label": "Monasterace",
                "label_ar": "Monasterace",
                "label_fr": "Monasterace"
            },
            {
                "value": 49949,
                "label": "San Fili",
                "label_ar": "سان فيلي",
                "label_fr": "San Fili"
            },
            {
                "value": 49950,
                "label": "Roseto Capo Spulico",
                "label_ar": "روزيتو كابو سبوليكو",
                "label_fr": "Roseto Capo Spulico"
            },
            {
                "value": 49951,
                "label": "Segariu",
                "label_ar": "سيجاريو",
                "label_fr": "Segariu"
            },
            {
                "value": 49952,
                "label": "Varapodio",
                "label_ar": "فارابوديو",
                "label_fr": "Varapodio"
            },
            {
                "value": 49953,
                "label": "San Sisto",
                "label_ar": "سان سيستو",
                "label_fr": "San Sisto"
            },
            {
                "value": 49954,
                "label": "San Nicola Arcella",
                "label_ar": "سان نيكولا ارسيلا",
                "label_fr": "San Nicola Arcella"
            },
            {
                "value": 49955,
                "label": "Pastene",
                "label_ar": "باستين",
                "label_fr": "Pastène"
            },
            {
                "value": 49956,
                "label": "Pozzilli",
                "label_ar": "بوزيلي",
                "label_fr": "Pozzilli"
            },
            {
                "value": 49957,
                "label": "Trivento",
                "label_ar": "تريفينتو",
                "label_fr": "Trivento"
            },
            {
                "value": 49958,
                "label": "Ficarra",
                "label_ar": "فيكارا",
                "label_fr": "Ficarra"
            },
            {
                "value": 49959,
                "label": "Mammola",
                "label_ar": "مامولا",
                "label_fr": "Mammola"
            },
            {
                "value": 49960,
                "label": "Apice",
                "label_ar": "Apice",
                "label_fr": "Apice"
            },
            {
                "value": 49961,
                "label": "Melizzano",
                "label_ar": "ميليزانو",
                "label_fr": "Melizzano"
            },
            {
                "value": 49962,
                "label": "Praiano",
                "label_ar": "برايانو",
                "label_fr": "Praiano"
            },
            {
                "value": 49963,
                "label": "Borgo Sabotino-Foce Verde",
                "label_ar": "بورجو سابوتينو فوس فيردي",
                "label_fr": "Borgo Sabotino-Foce Verde"
            },
            {
                "value": 49964,
                "label": "Solto Collina",
                "label_ar": "سولتو كولينا",
                "label_fr": "Solto Collina"
            },
            {
                "value": 49965,
                "label": "Caprino Bergamasco",
                "label_ar": "كابرينو برغاماسكو",
                "label_fr": "Caprino Bergamasco"
            },
            {
                "value": 49966,
                "label": "Meano",
                "label_ar": "مينو",
                "label_fr": "Meano"
            },
            {
                "value": 49967,
                "label": "Porpetto",
                "label_ar": "بوربيتو",
                "label_fr": "Porpetto"
            },
            {
                "value": 49968,
                "label": "Cancello",
                "label_ar": "إلغاء",
                "label_fr": "Cancello"
            },
            {
                "value": 49969,
                "label": "Morro d'Oro",
                "label_ar": "مورو دورو",
                "label_fr": "Morro d'Oro"
            },
            {
                "value": 49970,
                "label": "Santa Vittoria in Matenano",
                "label_ar": "سانتا فيتوريا في ماتينانو",
                "label_fr": "Santa Vittoria à Matenano"
            },
            {
                "value": 49971,
                "label": "Bitti",
                "label_ar": "بيتي",
                "label_fr": "Bitti"
            },
            {
                "value": 49972,
                "label": "Armento",
                "label_ar": "ارمينتو",
                "label_fr": "Armento"
            },
            {
                "value": 49973,
                "label": "Taranta Peligna",
                "label_ar": "تارانتا بيليغنا",
                "label_fr": "Taranta Peligna"
            },
            {
                "value": 49974,
                "label": "Ferrazzano",
                "label_ar": "فيرازانو",
                "label_fr": "Ferrazzano"
            },
            {
                "value": 49975,
                "label": "Castello d'Argile",
                "label_ar": "كاستيلو دارجيل",
                "label_fr": "Castello d'Argile"
            },
            {
                "value": 49976,
                "label": "Racale",
                "label_ar": "راكال",
                "label_fr": "Racale"
            },
            {
                "value": 49977,
                "label": "Cavezzo",
                "label_ar": "كافيزو",
                "label_fr": "Cavezzo"
            },
            {
                "value": 49978,
                "label": "Montefiore dell'Aso",
                "label_ar": "مونتيفيوري ديل أسو",
                "label_fr": "Montefiore dell'Aso"
            },
            {
                "value": 49979,
                "label": "Stroncone",
                "label_ar": "سترونكون",
                "label_fr": "Stroncone"
            },
            {
                "value": 49980,
                "label": "Acquappesa",
                "label_ar": "أكوابيسا",
                "label_fr": "Acquappesa"
            },
            {
                "value": 49981,
                "label": "Passo di Treia",
                "label_ar": "باسو دي تريا",
                "label_fr": "Passo di Treia"
            },
            {
                "value": 49982,
                "label": "Caspoggio",
                "label_ar": "Caspoggio",
                "label_fr": "Caspoggio"
            },
            {
                "value": 49983,
                "label": "Capalbio",
                "label_ar": "كابالبيو",
                "label_fr": "Capalbio"
            },
            {
                "value": 49984,
                "label": "Ururi",
                "label_ar": "أوروري",
                "label_fr": "Ururi"
            },
            {
                "value": 49985,
                "label": "Castagnaro",
                "label_ar": "كاستانيارو",
                "label_fr": "Castagnaro"
            },
            {
                "value": 49986,
                "label": "Torgnon",
                "label_ar": "تورجنون",
                "label_fr": "Torgnon"
            },
            {
                "value": 49987,
                "label": "San Salvatore Monferrato",
                "label_ar": "سان سالفاتور مونفيراتو",
                "label_fr": "San Salvatore Monferrato"
            },
            {
                "value": 49988,
                "label": "Pralungo",
                "label_ar": "برالونجو",
                "label_fr": "Pralungo"
            },
            {
                "value": 49989,
                "label": "San Martino",
                "label_ar": "سان مارتينو",
                "label_fr": "San Martino"
            },
            {
                "value": 49990,
                "label": "Vidiciatico",
                "label_ar": "فيديسياتيكو",
                "label_fr": "Vidiciatico"
            },
            {
                "value": 49991,
                "label": "San Martino",
                "label_ar": "سان مارتينو",
                "label_fr": "San Martino"
            },
            {
                "value": 49992,
                "label": "Varzo",
                "label_ar": "فارزو",
                "label_fr": "Varzo"
            },
            {
                "value": 49993,
                "label": "Moena",
                "label_ar": "موينا",
                "label_fr": "Moena"
            },
            {
                "value": 49994,
                "label": "Magno",
                "label_ar": "ماجنو",
                "label_fr": "Magno"
            },
            {
                "value": 49995,
                "label": "Forno",
                "label_ar": "فورنو",
                "label_fr": "Forno"
            },
            {
                "value": 49996,
                "label": "Case",
                "label_ar": "قضية",
                "label_fr": "Cas"
            },
            {
                "value": 49997,
                "label": "San Pietro",
                "label_ar": "سان بيترو",
                "label_fr": "San Pietro"
            },
            {
                "value": 49998,
                "label": "Pinarella",
                "label_ar": "بيناريلا",
                "label_fr": "Pinarella"
            },
            {
                "value": 49999,
                "label": "Milano Marittima",
                "label_ar": "ميلانو ماريتيما",
                "label_fr": "Milano Marittima"
            },
            {
                "value": 50000,
                "label": "Barisciano",
                "label_ar": "باريشيانو",
                "label_fr": "Barisciano"
            },
            {
                "value": 50001,
                "label": "Scoppito",
                "label_ar": "سكوبيتو",
                "label_fr": "Scoppito"
            },
            {
                "value": 50002,
                "label": "Casignana",
                "label_ar": "Casignana",
                "label_fr": "Casignana"
            },
            {
                "value": 50003,
                "label": "Frabosa Sottana",
                "label_ar": "فرابوزا سوتانا",
                "label_fr": "Frabosa Sottana"
            },
            {
                "value": 50004,
                "label": "Pontremoli",
                "label_ar": "بونتريمولي",
                "label_fr": "Pontremoli"
            },
            {
                "value": 50005,
                "label": "Santo Stefano al Mare",
                "label_ar": "سانتو ستيفانو الماري",
                "label_fr": "Santo Stefano al Mare"
            },
            {
                "value": 50006,
                "label": "Ostiglia",
                "label_ar": "أوستيجليا",
                "label_fr": "Ostiglia"
            },
            {
                "value": 50007,
                "label": "Villa di Tirano",
                "label_ar": "فيلا دي تيرانو",
                "label_fr": "Villa di Tirano"
            },
            {
                "value": 50008,
                "label": "Castello dell'Acqua",
                "label_ar": "كاستيلو ديل أكوا",
                "label_fr": "Castello dell'Acqua"
            },
            {
                "value": 50009,
                "label": "Metaponto",
                "label_ar": "ميتابونتو",
                "label_fr": "Metaponto"
            },
            {
                "value": 50010,
                "label": "Paruzzaro",
                "label_ar": "باروزارو",
                "label_fr": "Paruzzaro"
            },
            {
                "value": 50011,
                "label": "Champdepraz",
                "label_ar": "تشامبديبراز",
                "label_fr": "Champdepraz"
            },
            {
                "value": 50012,
                "label": "Pancalieri",
                "label_ar": "بانكاليري",
                "label_fr": "Pancalieri"
            },
            {
                "value": 50013,
                "label": "Pievebovigliana",
                "label_ar": "بيفيبوفيجليانا",
                "label_fr": "Pievebovigliana"
            },
            {
                "value": 50014,
                "label": "Petruro",
                "label_ar": "بيتروورو",
                "label_fr": "Petruro"
            },
            {
                "value": 50015,
                "label": "Novi Velia",
                "label_ar": "نوفي فيليا",
                "label_fr": "Novi Velia"
            },
            {
                "value": 50016,
                "label": "Perdifumo",
                "label_ar": "Perdifumo",
                "label_fr": "Perdifumo"
            },
            {
                "value": 50017,
                "label": "Montecorice",
                "label_ar": "مونتكوريس",
                "label_fr": "Montecorice"
            },
            {
                "value": 50018,
                "label": "Piano-Vetrale",
                "label_ar": "بيانو فيتريل",
                "label_fr": "Piano-Vetrale"
            },
            {
                "value": 50019,
                "label": "Scuola",
                "label_ar": "سكولا",
                "label_fr": "Scuola"
            },
            {
                "value": 50020,
                "label": "Casalzuigno",
                "label_ar": "كاسالزوينو",
                "label_fr": "Casalzuigno"
            },
            {
                "value": 50021,
                "label": "Orta San Giulio",
                "label_ar": "أورتا سان جوليو",
                "label_fr": "Orta San Giulio"
            },
            {
                "value": 50022,
                "label": "Sartirana Lomellina",
                "label_ar": "سارتيرانا لوميلينا",
                "label_fr": "Sartirana Lomellina"
            },
            {
                "value": 50023,
                "label": "Stazione Castelguelfo",
                "label_ar": "Stazione Castelguelfo",
                "label_fr": "Stazione Castelguelfo"
            },
            {
                "value": 50024,
                "label": "Toro",
                "label_ar": "تورو",
                "label_fr": "Toro"
            },
            {
                "value": 50025,
                "label": "Larderello",
                "label_ar": "لارديريلو",
                "label_fr": "Larderello"
            },
            {
                "value": 50026,
                "label": "Belsito",
                "label_ar": "بيلسيتو",
                "label_fr": "Belsito"
            },
            {
                "value": 50027,
                "label": "Ripa",
                "label_ar": "ريبا",
                "label_fr": "Ripa"
            },
            {
                "value": 50028,
                "label": "Bossolasco",
                "label_ar": "بوسولاسكو",
                "label_fr": "Bossolasco"
            },
            {
                "value": 50029,
                "label": "San Vero Milis",
                "label_ar": "سان فيرو ميليس",
                "label_fr": "San Vero Milis"
            },
            {
                "value": 50030,
                "label": "Poggio di Roio",
                "label_ar": "بوجيو دي رويو",
                "label_fr": "Poggio di Roio"
            },
            {
                "value": 50031,
                "label": "Belfiore",
                "label_ar": "بلفيور",
                "label_fr": "Belfiore"
            },
            {
                "value": 50032,
                "label": "Alvito",
                "label_ar": "ألفيتو",
                "label_fr": "Alvito"
            },
            {
                "value": 50033,
                "label": "San Lorenzo",
                "label_ar": "سان لورينزو",
                "label_fr": "San Lorenzo"
            },
            {
                "value": 50034,
                "label": "Lido",
                "label_ar": "ليدو",
                "label_fr": "Piscine"
            },
            {
                "value": 50035,
                "label": "Candiana",
                "label_ar": "كانديانا",
                "label_fr": "Candiana"
            },
            {
                "value": 50036,
                "label": "Cornaiano",
                "label_ar": "كورنيانو",
                "label_fr": "Cornaiano"
            },
            {
                "value": 50037,
                "label": "Casei Gerola",
                "label_ar": "كاسي جيرولا",
                "label_fr": "Casei Gerola"
            },
            {
                "value": 50038,
                "label": "Pozzolo",
                "label_ar": "بوزولو",
                "label_fr": "Pozzolo"
            },
            {
                "value": 50039,
                "label": "Santa Cristina",
                "label_ar": "سانتا كريستينا",
                "label_fr": "Santa Cristina"
            },
            {
                "value": 50040,
                "label": "Cisliano",
                "label_ar": "سيسليانو",
                "label_fr": "Cisliano"
            },
            {
                "value": 50041,
                "label": "Crodo",
                "label_ar": "كرودو",
                "label_fr": "Crodo"
            },
            {
                "value": 50042,
                "label": "Pogliano",
                "label_ar": "بوجليانو",
                "label_fr": "Pogliano"
            },
            {
                "value": 50043,
                "label": "Castelseprio",
                "label_ar": "كاستيلسبريو",
                "label_fr": "Castelseprio"
            },
            {
                "value": 50044,
                "label": "Acquapendente",
                "label_ar": "Acquapendente",
                "label_fr": "Acquapendente"
            },
            {
                "value": 50045,
                "label": "Vicovaro",
                "label_ar": "فيكوفارو",
                "label_fr": "Vicovaro"
            },
            {
                "value": 50046,
                "label": "Corchiano",
                "label_ar": "كورشيانو",
                "label_fr": "Corchiano"
            },
            {
                "value": 50047,
                "label": "Borgorose",
                "label_ar": "برجوروز",
                "label_fr": "Borgorose"
            },
            {
                "value": 50048,
                "label": "Ponzano",
                "label_ar": "بونزانو",
                "label_fr": "Ponzano"
            },
            {
                "value": 50049,
                "label": "Canino",
                "label_ar": "كانينو",
                "label_fr": "Canino"
            },
            {
                "value": 50050,
                "label": "Supino",
                "label_ar": "سوبينو",
                "label_fr": "Supino"
            },
            {
                "value": 50051,
                "label": "Castello di Annone",
                "label_ar": "كاستيلو دي أنوني",
                "label_fr": "Castello di Annone"
            },
            {
                "value": 50052,
                "label": "Caprese Michelangelo",
                "label_ar": "كابريزي مايكل أنجلو",
                "label_fr": "Caprese Michelangelo"
            },
            {
                "value": 50053,
                "label": "Limiti di Greccio",
                "label_ar": "Limiti di Greccio",
                "label_fr": "Limiti di Greccio"
            },
            {
                "value": 50054,
                "label": "Godo",
                "label_ar": "تذهب تفعل",
                "label_fr": "Va faire"
            },
            {
                "value": 50055,
                "label": "Monticelli",
                "label_ar": "مونتيتشيلي",
                "label_fr": "Monticelli"
            },
            {
                "value": 50056,
                "label": "Filetto",
                "label_ar": "فيليتو",
                "label_fr": "Filetto"
            },
            {
                "value": 50057,
                "label": "Lestizza",
                "label_ar": "ليستيزا",
                "label_fr": "Lestizza"
            },
            {
                "value": 50058,
                "label": "Godega di Sant'Urbano",
                "label_ar": "جوديجا دي سانت أوربانو",
                "label_fr": "Godega di Sant'Urbano"
            },
            {
                "value": 50059,
                "label": "Begliano",
                "label_ar": "بيغليانو",
                "label_fr": "Begliano"
            },
            {
                "value": 50060,
                "label": "Paderno",
                "label_ar": "باديرنو",
                "label_fr": "Paderno"
            },
            {
                "value": 50061,
                "label": "Villa del Conte",
                "label_ar": "فيلا ديل كونتي",
                "label_fr": "Villa del Conte"
            },
            {
                "value": 50062,
                "label": "Maerne",
                "label_ar": "ميرن",
                "label_fr": "Maerne"
            },
            {
                "value": 50063,
                "label": "Sottomarina",
                "label_ar": "سوتومارينا",
                "label_fr": "Sottomarina"
            },
            {
                "value": 50064,
                "label": "Sambruson",
                "label_ar": "سامبروسون",
                "label_fr": "Sambruson"
            },
            {
                "value": 50065,
                "label": "Nociglia",
                "label_ar": "نوسيجليا",
                "label_fr": "Nociglia"
            },
            {
                "value": 50066,
                "label": "Palmariggi",
                "label_ar": "بالماريجي",
                "label_fr": "Palmariggi"
            },
            {
                "value": 50067,
                "label": "Cirò",
                "label_ar": "سيرو",
                "label_fr": "Cirò"
            },
            {
                "value": 50068,
                "label": "Drapia",
                "label_ar": "درابيا",
                "label_fr": "Drapia"
            },
            {
                "value": 50069,
                "label": "Pezze di Greco",
                "label_ar": "Pezze di Greco",
                "label_fr": "Pezze di Greco"
            },
            {
                "value": 50070,
                "label": "Lizzanello",
                "label_ar": "ليزانيلو",
                "label_fr": "Lizzanello"
            },
            {
                "value": 50071,
                "label": "Martano",
                "label_ar": "مارتانو",
                "label_fr": "Martano"
            },
            {
                "value": 50072,
                "label": "Mareo",
                "label_ar": "ماريو",
                "label_fr": "Mareo"
            },
            {
                "value": 50073,
                "label": "Enemonzo-Quinis",
                "label_ar": "إنيمونزو كوينيس",
                "label_fr": "Enemonzo-Quinis"
            },
            {
                "value": 50074,
                "label": "Valle di Cadore",
                "label_ar": "فالي دي كادور",
                "label_fr": "Valle di Cadore"
            },
            {
                "value": 50075,
                "label": "Faedis",
                "label_ar": "فيديس",
                "label_fr": "Faedis"
            },
            {
                "value": 50076,
                "label": "Borgetto",
                "label_ar": "بورغيتو",
                "label_fr": "Borgetto"
            },
            {
                "value": 50077,
                "label": "Giardinello",
                "label_ar": "جياردينيلو",
                "label_fr": "Giardinello"
            },
            {
                "value": 50078,
                "label": "Grotte",
                "label_ar": "جروت",
                "label_fr": "Grotte"
            },
            {
                "value": 50079,
                "label": "Montodine",
                "label_ar": "مونتودين",
                "label_fr": "Montodine"
            },
            {
                "value": 50080,
                "label": "Cumignano sul Naviglio",
                "label_ar": "كومينانو سول نافيجليو",
                "label_fr": "Cumignano sul Naviglio"
            },
            {
                "value": 50081,
                "label": "Zandobbio",
                "label_ar": "زاندوبيو",
                "label_fr": "Zandobbio"
            },
            {
                "value": 50082,
                "label": "Gergei",
                "label_ar": "جرجي",
                "label_fr": "Gergei"
            },
            {
                "value": 50083,
                "label": "Castiadas",
                "label_ar": "كاستياداس",
                "label_fr": "Castiadas"
            },
            {
                "value": 50084,
                "label": "Santu Lussurgiu",
                "label_ar": "سانتو لوسورغيو",
                "label_fr": "Santu Lussurgiu"
            },
            {
                "value": 50085,
                "label": "Oria",
                "label_ar": "أوريا",
                "label_fr": "Oria"
            },
            {
                "value": 50086,
                "label": "Transacqua",
                "label_ar": "ترانساكوا",
                "label_fr": "Transacqua"
            },
            {
                "value": 50087,
                "label": "Cocconato",
                "label_ar": "كوكوناتو",
                "label_fr": "Cocconato"
            },
            {
                "value": 50088,
                "label": "Pralormo",
                "label_ar": "برالورمو",
                "label_fr": "Pralormo"
            },
            {
                "value": 50089,
                "label": "Garessio",
                "label_ar": "غارسيو",
                "label_fr": "Garessio"
            },
            {
                "value": 50090,
                "label": "Fenile",
                "label_ar": "فينيل",
                "label_fr": "Fenile"
            },
            {
                "value": 50091,
                "label": "Paesana",
                "label_ar": "بيسانا",
                "label_fr": "Paesana"
            },
            {
                "value": 50092,
                "label": "Prato Sesia",
                "label_ar": "براتو سيسيا",
                "label_fr": "Prato Sesia"
            },
            {
                "value": 50093,
                "label": "Quagliuzzo",
                "label_ar": "كواليوزو",
                "label_fr": "Quagliuzzo"
            },
            {
                "value": 50094,
                "label": "San Bernardino",
                "label_ar": "سان برناردينو",
                "label_fr": "San Bernardino"
            },
            {
                "value": 50095,
                "label": "Vaprio d'Agogna",
                "label_ar": "Vaprio d'Agogna",
                "label_fr": "Vaprio d'Agogna"
            },
            {
                "value": 50096,
                "label": "Volano",
                "label_ar": "فولانو",
                "label_fr": "Volano"
            },
            {
                "value": 50097,
                "label": "Ripoli",
                "label_ar": "ريبولي",
                "label_fr": "Ripoli"
            },
            {
                "value": 50098,
                "label": "Celenza Valfortore",
                "label_ar": "سيلينزا فالفورتور",
                "label_fr": "Celenza Valfortore"
            },
            {
                "value": 50099,
                "label": "Carpiano",
                "label_ar": "كاربيانو",
                "label_fr": "Carpiano"
            },
            {
                "value": 50100,
                "label": "Fanano",
                "label_ar": "فانانو",
                "label_fr": "Fanano"
            },
            {
                "value": 50101,
                "label": "Solignano Nuovo",
                "label_ar": "سولينانو نوفو",
                "label_fr": "Solignano Nuovo"
            },
            {
                "value": 50102,
                "label": "Santa Ninfa",
                "label_ar": "سانتا نينفا",
                "label_fr": "Santa Ninfa"
            },
            {
                "value": 50103,
                "label": "Bagnara",
                "label_ar": "باجنارا",
                "label_fr": "Bagnara"
            },
            {
                "value": 50104,
                "label": "Pignola",
                "label_ar": "بينولا",
                "label_fr": "Pignola"
            },
            {
                "value": 50105,
                "label": "Villa Morelli",
                "label_ar": "فيلا موريلي",
                "label_fr": "Villa Morelli"
            },
            {
                "value": 50106,
                "label": "Secondigliano",
                "label_ar": "سكندجليانو",
                "label_fr": "Secondigliano"
            },
            {
                "value": 50107,
                "label": "Furci Siculo",
                "label_ar": "فورسي سيكولو",
                "label_fr": "Furci Siculo"
            },
            {
                "value": 50108,
                "label": "Randazzo",
                "label_ar": "راندازو",
                "label_fr": "Randazzo"
            },
            {
                "value": 50109,
                "label": "Palazzolo",
                "label_ar": "بالازولو",
                "label_fr": "Palazzolo"
            },
            {
                "value": 50110,
                "label": "Senise",
                "label_ar": "مجلس الشيوخ",
                "label_fr": "Senise"
            },
            {
                "value": 50111,
                "label": "San Salvatore Telesino",
                "label_ar": "سان سالفاتور تيليسينو",
                "label_fr": "San Salvatore Telesino"
            },
            {
                "value": 50112,
                "label": "Pisciotta",
                "label_ar": "بيسكيوتا",
                "label_fr": "Pisciotta"
            },
            {
                "value": 50113,
                "label": "Quindici",
                "label_ar": "كوينديسي",
                "label_fr": "Quindici"
            },
            {
                "value": 50114,
                "label": "Scala",
                "label_ar": "سكالا",
                "label_fr": "Scala"
            },
            {
                "value": 50115,
                "label": "Montecchio",
                "label_ar": "مونتيكيو",
                "label_fr": "Montecchio"
            },
            {
                "value": 50116,
                "label": "Porto Recanati",
                "label_ar": "بورتو ريكاناتي",
                "label_fr": "Porto Recanati"
            },
            {
                "value": 50117,
                "label": "Tuoro sul Trasimeno",
                "label_ar": "Tuoro sul Trasimeno",
                "label_fr": "Tuoro sul Trasimène"
            },
            {
                "value": 50118,
                "label": "Monsampietro Morico",
                "label_ar": "مونسامبيترو موريكو",
                "label_fr": "Monsampietro Morico"
            },
            {
                "value": 50119,
                "label": "Frisa",
                "label_ar": "فريسا",
                "label_fr": "Frisa"
            },
            {
                "value": 50120,
                "label": "Civitella Roveto",
                "label_ar": "سيفيتيلا روفيتو",
                "label_fr": "Civitella Roveto"
            },
            {
                "value": 50121,
                "label": "Carsoli",
                "label_ar": "كارسولي",
                "label_fr": "Carsoli"
            },
            {
                "value": 50122,
                "label": "Garrufo",
                "label_ar": "جاروفو",
                "label_fr": "Garrufo"
            },
            {
                "value": 50123,
                "label": "Casoli",
                "label_ar": "كاسولي",
                "label_fr": "Casoli"
            },
            {
                "value": 50124,
                "label": "Mercato",
                "label_ar": "ميركاتو",
                "label_fr": "Mercato"
            },
            {
                "value": 50125,
                "label": "Agnadello",
                "label_ar": "اجناديلو",
                "label_fr": "Agnadello"
            },
            {
                "value": 50126,
                "label": "Collazzone",
                "label_ar": "Collazzone",
                "label_fr": "Collazzone"
            },
            {
                "value": 50127,
                "label": "Monte Castello di Vibio",
                "label_ar": "مونتي كاستيلو دي فيبيو",
                "label_fr": "Monte Castello di Vibio"
            },
            {
                "value": 50128,
                "label": "Spina",
                "label_ar": "السنسنة",
                "label_fr": "Spina"
            },
            {
                "value": 50129,
                "label": "Collelungo",
                "label_ar": "كوليلونجو",
                "label_fr": "Collelungo"
            },
            {
                "value": 50130,
                "label": "Saccolongo",
                "label_ar": "ساكولونجو",
                "label_fr": "Saccolongo"
            },
            {
                "value": 50131,
                "label": "Vergano-Villa",
                "label_ar": "فيرغانو فيلا",
                "label_fr": "Villa Vergano"
            },
            {
                "value": 50132,
                "label": "Casciana Terme",
                "label_ar": "كاسيانا تيرمي",
                "label_fr": "Casciana Terme"
            },
            {
                "value": 50133,
                "label": "Mottafollone",
                "label_ar": "موتافولون",
                "label_fr": "Mottafollone"
            },
            {
                "value": 50134,
                "label": "Serle",
                "label_ar": "سيرل",
                "label_fr": "Serle"
            },
            {
                "value": 50135,
                "label": "Arta Terme",
                "label_ar": "ارتا تيرمي",
                "label_fr": "Arta Terme"
            },
            {
                "value": 50136,
                "label": "Rotella",
                "label_ar": "روتيللا",
                "label_fr": "Rotella"
            },
            {
                "value": 50137,
                "label": "Monte Giberto",
                "label_ar": "مونتي جيبيرتو",
                "label_fr": "Monte Giberto"
            },
            {
                "value": 50138,
                "label": "Capitignano",
                "label_ar": "كابيتينانو",
                "label_fr": "Capitignano"
            },
            {
                "value": 50139,
                "label": "Montereale",
                "label_ar": "مونتيريلي",
                "label_fr": "Montereale"
            },
            {
                "value": 50140,
                "label": "Fossa",
                "label_ar": "فوسا",
                "label_fr": "Fosse"
            },
            {
                "value": 50141,
                "label": "Castel di Judica",
                "label_ar": "كاستل دي جوديكا",
                "label_fr": "Castel di Judica"
            },
            {
                "value": 50142,
                "label": "Gessopalena",
                "label_ar": "جيسوبالينا",
                "label_fr": "Gessopalena"
            },
            {
                "value": 50143,
                "label": "Quadri",
                "label_ar": "Quadri",
                "label_fr": "Quadri"
            },
            {
                "value": 50144,
                "label": "Massa Fiscaglia",
                "label_ar": "ماسا فيسكاليا",
                "label_fr": "Massa Fiscaglia"
            },
            {
                "value": 50145,
                "label": "Villa Ottone",
                "label_ar": "فيلا أوتون",
                "label_fr": "Villa Ottone"
            },
            {
                "value": 50146,
                "label": "Casalvolone",
                "label_ar": "كاسالفولون",
                "label_fr": "Casalvolone"
            },
            {
                "value": 50147,
                "label": "Albareto",
                "label_ar": "الباريتو",
                "label_fr": "Albareto"
            },
            {
                "value": 50148,
                "label": "Carbonara di Po",
                "label_ar": "كاربونارا دي بو",
                "label_fr": "Carbonara di Po"
            },
            {
                "value": 50149,
                "label": "Albano di Lucania",
                "label_ar": "ألبانو دي لوكانيا",
                "label_fr": "Albano di Lucania"
            },
            {
                "value": 50150,
                "label": "Aglientu",
                "label_ar": "اجلينتو",
                "label_fr": "Aglientu"
            },
            {
                "value": 50151,
                "label": "Travo",
                "label_ar": "ترافو",
                "label_fr": "Travo"
            },
            {
                "value": 50152,
                "label": "Simone",
                "label_ar": "سيمون",
                "label_fr": "Simone"
            },
            {
                "value": 50153,
                "label": "Agro",
                "label_ar": "الزراعة",
                "label_fr": "Agro"
            },
            {
                "value": 50154,
                "label": "Poggio San Lorenzo",
                "label_ar": "بوجيو سان لورينزو",
                "label_fr": "Poggio San Lorenzo"
            },
            {
                "value": 50155,
                "label": "Mauro",
                "label_ar": "ماورو",
                "label_fr": "Mauro"
            },
            {
                "value": 50156,
                "label": "Sinopoli",
                "label_ar": "سينوبولي",
                "label_fr": "Sinopoli"
            },
            {
                "value": 50157,
                "label": "Tursi",
                "label_ar": "تورسي",
                "label_fr": "Tursi"
            },
            {
                "value": 50158,
                "label": "Pantano",
                "label_ar": "بانتانو",
                "label_fr": "Pantano"
            },
            {
                "value": 50159,
                "label": "Field",
                "label_ar": "حقل",
                "label_fr": "Champ"
            },
            {
                "value": 50160,
                "label": "Capo Rizzuto",
                "label_ar": "كابو ريزوتو",
                "label_fr": "Capo Rizzuto"
            },
            {
                "value": 50161,
                "label": "Lardirago",
                "label_ar": "لارديراجو",
                "label_fr": "Lardirago"
            },
            {
                "value": 50162,
                "label": "Novello",
                "label_ar": "نوفيلو",
                "label_fr": "Novello"
            },
            {
                "value": 50163,
                "label": "Solarolo",
                "label_ar": "سولارولو",
                "label_fr": "Solarolo"
            },
            {
                "value": 50164,
                "label": "Pollina",
                "label_ar": "بولينا",
                "label_fr": "Pollina"
            },
            {
                "value": 50165,
                "label": "Mezzolara",
                "label_ar": "ميزولارا",
                "label_fr": "Mezzolara"
            },
            {
                "value": 50166,
                "label": "Pozzolengo",
                "label_ar": "بوزولينغو",
                "label_fr": "Pouzzolengo"
            },
            {
                "value": 50167,
                "label": "Dosolo",
                "label_ar": "دوسولو",
                "label_fr": "Dosolo"
            },
            {
                "value": 50168,
                "label": "Castel di Casio",
                "label_ar": "كاستل دي كاسيو",
                "label_fr": "Castel di Casio"
            },
            {
                "value": 50169,
                "label": "Teglio",
                "label_ar": "تيجليو",
                "label_fr": "Teglio"
            },
            {
                "value": 50170,
                "label": "Sori",
                "label_ar": "صوري",
                "label_fr": "Sori"
            },
            {
                "value": 50171,
                "label": "Sant'Angelo dei Lombardi",
                "label_ar": "سانت أنجيلو دي لومباردي",
                "label_fr": "Sant'Angelo dei Lombardi"
            },
            {
                "value": 50172,
                "label": "Piateda",
                "label_ar": "بياتيدا",
                "label_fr": "Piateda"
            },
            {
                "value": 50173,
                "label": "Marina di Ginosa",
                "label_ar": "مارينا دي جينوزا",
                "label_fr": "Marina di Ginosa"
            },
            {
                "value": 50174,
                "label": "Lajatico",
                "label_ar": "لاجاتيكو",
                "label_fr": "Lajatico"
            },
            {
                "value": 50175,
                "label": "Morgex",
                "label_ar": "مورجكس",
                "label_fr": "Morgex"
            },
            {
                "value": 50176,
                "label": "Pitigliano",
                "label_ar": "بيتيجليانو",
                "label_fr": "Pitigliano"
            },
            {
                "value": 50177,
                "label": "Lagosanto",
                "label_ar": "لاغوسانتو",
                "label_fr": "Lagosanto"
            },
            {
                "value": 50178,
                "label": "Ariano Ferrarese",
                "label_ar": "أريانو فيراريس",
                "label_fr": "Ariano Ferrarese"
            },
            {
                "value": 50179,
                "label": "Gallio",
                "label_ar": "جاليو",
                "label_fr": "Gallio"
            },
            {
                "value": 50180,
                "label": "Pozzoleone",
                "label_ar": "بوزوليون",
                "label_fr": "Pozzoleone"
            },
            {
                "value": 50181,
                "label": "Stienta",
                "label_ar": "ستينتا",
                "label_fr": "Stienta"
            },
            {
                "value": 50182,
                "label": "Massa Martana",
                "label_ar": "ماسا مارتانا",
                "label_fr": "Massa Martana"
            },
            {
                "value": 50183,
                "label": "Roncà",
                "label_ar": "رونكا",
                "label_fr": "Roncà"
            },
            {
                "value": 50184,
                "label": "San Bassano",
                "label_ar": "سان باسانو",
                "label_fr": "San Bassano"
            },
            {
                "value": 50185,
                "label": "Isola Sant'Antonio",
                "label_ar": "ايزولا سانت انطونيو",
                "label_fr": "Isola Sant'Antonio"
            },
            {
                "value": 50186,
                "label": "Maruggio",
                "label_ar": "ماروجيو",
                "label_fr": "Maruggio"
            },
            {
                "value": 50187,
                "label": "Corbola",
                "label_ar": "كوربولا",
                "label_fr": "Corbola"
            },
            {
                "value": 50188,
                "label": "Albettone",
                "label_ar": "ألبيتون",
                "label_fr": "Albettone"
            },
            {
                "value": 50189,
                "label": "Gambellara",
                "label_ar": "جامبيلارا",
                "label_fr": "Gambellara"
            },
            {
                "value": 50190,
                "label": "Calvanico",
                "label_ar": "كالفانيكو",
                "label_fr": "Calvanico"
            },
            {
                "value": 50191,
                "label": "Montereale Valcellina",
                "label_ar": "مونتيريل فالسيلينا",
                "label_fr": "Montereale Valcellina"
            },
            {
                "value": 50192,
                "label": "Pedavena",
                "label_ar": "بيدافينا",
                "label_fr": "Pedavena"
            },
            {
                "value": 50193,
                "label": "Feletto Umberto",
                "label_ar": "فيليتو أمبرتو",
                "label_fr": "Feletto Umberto"
            },
            {
                "value": 50194,
                "label": "Piobbico",
                "label_ar": "Piobbico",
                "label_fr": "Piobbico"
            },
            {
                "value": 50195,
                "label": "Torrevecchia Teatina",
                "label_ar": "توريفيكيا تياتينا",
                "label_fr": "Torrevecchia Teatina"
            },
            {
                "value": 50196,
                "label": "Baselga di Pinè",
                "label_ar": "باسيلجا دي بينيه",
                "label_fr": "Baselga di Pinè"
            },
            {
                "value": 50197,
                "label": "Taibon Agordino",
                "label_ar": "Taibon Agordino",
                "label_fr": "Taibon Agordino"
            },
            {
                "value": 50198,
                "label": "Orosei",
                "label_ar": "أوروسي",
                "label_fr": "Orosei"
            },
            {
                "value": 50199,
                "label": "Usini",
                "label_ar": "يوسيني",
                "label_fr": "Usini"
            },
            {
                "value": 50200,
                "label": "Santa Teresa Gallura",
                "label_ar": "سانتا تيريزا غالورا",
                "label_fr": "Santa Teresa Gallura"
            },
            {
                "value": 50201,
                "label": "Serra San Bruno",
                "label_ar": "سيرا سان برونو",
                "label_fr": "Serra San Bruno"
            },
            {
                "value": 50202,
                "label": "Tricarico",
                "label_ar": "تريكاريكو",
                "label_fr": "Tricarico"
            },
            {
                "value": 50203,
                "label": "Porto Tolle",
                "label_ar": "بورتو تول",
                "label_fr": "Porto Tolle"
            },
            {
                "value": 50204,
                "label": "San Zenone al Po",
                "label_ar": "سان زينون آل بو",
                "label_fr": "San Zenone al Po"
            },
            {
                "value": 50205,
                "label": "Bettona",
                "label_ar": "بيتونا",
                "label_fr": "Bettona"
            },
            {
                "value": 50206,
                "label": "Borgo",
                "label_ar": "بورجو",
                "label_fr": "Borgo"
            },
            {
                "value": 50207,
                "label": "Vigo di Cadore",
                "label_ar": "فيجو دي كادور",
                "label_fr": "Vigo di Cadore"
            },
            {
                "value": 50208,
                "label": "Biancade",
                "label_ar": "بيانكاد",
                "label_fr": "Biancade"
            },
            {
                "value": 50209,
                "label": "Lazzaro",
                "label_ar": "لازارو",
                "label_fr": "Lazzaro"
            },
            {
                "value": 50210,
                "label": "Casaccia II",
                "label_ar": "كاساشيا الثاني",
                "label_fr": "Casaccia II"
            },
            {
                "value": 50211,
                "label": "Paganica",
                "label_ar": "باجانيكا",
                "label_fr": "Paganica"
            },
            {
                "value": 50212,
                "label": "Dovadola",
                "label_ar": "دوفادولا",
                "label_fr": "Dovadola"
            },
            {
                "value": 50213,
                "label": "San Michele Prazzo",
                "label_ar": "سان ميشيل براتسو",
                "label_fr": "San Michele Prazzo"
            },
            {
                "value": 50214,
                "label": "Pont",
                "label_ar": "بونت",
                "label_fr": "Pont"
            },
            {
                "value": 50215,
                "label": "Molise",
                "label_ar": "موليز",
                "label_fr": "Molise"
            },
            {
                "value": 50216,
                "label": "Ramon",
                "label_ar": "رامون",
                "label_fr": "Ramon"
            },
            {
                "value": 50217,
                "label": "Pont-Canavese",
                "label_ar": "بونت كانافيزي",
                "label_fr": "Pont-Canavese"
            },
            {
                "value": 50218,
                "label": "Maddalena di Cazzano",
                "label_ar": "مادالينا دي كازانو",
                "label_fr": "Maddalena di Cazzano"
            },
            {
                "value": 50219,
                "label": "Galeata",
                "label_ar": "جاليتا",
                "label_fr": "Galeata"
            },
            {
                "value": 50220,
                "label": "Sassa",
                "label_ar": "ساسا",
                "label_fr": "Sassa"
            },
            {
                "value": 50221,
                "label": "Corti",
                "label_ar": "كورتي",
                "label_fr": "Corti"
            },
            {
                "value": 50222,
                "label": "Marsico Nuovo",
                "label_ar": "مارسيكو نوفو",
                "label_fr": "Marsico Nuovo"
            },
            {
                "value": 50223,
                "label": "Baragiano",
                "label_ar": "باراجيانو",
                "label_fr": "Baragiano"
            },
            {
                "value": 50224,
                "label": "Ruoti",
                "label_ar": "روتي",
                "label_fr": "Ruoti"
            },
            {
                "value": 50225,
                "label": "Castelgrande",
                "label_ar": "كاستيلجراند",
                "label_fr": "Castelgrande"
            },
            {
                "value": 50226,
                "label": "Bella",
                "label_ar": "بيلا",
                "label_fr": "Bella"
            },
            {
                "value": 50227,
                "label": "Vaglio Basilicata",
                "label_ar": "Vaglio Basilicata",
                "label_fr": "Vaglio Basilicate"
            },
            {
                "value": 50228,
                "label": "Cantarana",
                "label_ar": "كانتارانا",
                "label_fr": "Cantarana"
            },
            {
                "value": 50229,
                "label": "Cunico",
                "label_ar": "كونيكو",
                "label_fr": "Cunico"
            },
            {
                "value": 50230,
                "label": "Casalborgone",
                "label_ar": "كاسالبورجون",
                "label_fr": "Casalborgone"
            },
            {
                "value": 50231,
                "label": "Camino",
                "label_ar": "كامينو",
                "label_fr": "Camino"
            },
            {
                "value": 50232,
                "label": "Canosa Sannita",
                "label_ar": "كانوسا سانيتا",
                "label_fr": "Canosa Sannita"
            },
            {
                "value": 50233,
                "label": "Castiglione Messer Raimondo",
                "label_ar": "كاستيجليون ميسر ريموندو",
                "label_fr": "Castiglione Messer Raimondo"
            },
            {
                "value": 50234,
                "label": "Capannelle",
                "label_ar": "كابانيل",
                "label_fr": "Capannelle"
            },
            {
                "value": 50235,
                "label": "Fontaneto d'Agogna",
                "label_ar": "Fontaneto d'Agogna",
                "label_fr": "Fontaneto d'Agogna"
            },
            {
                "value": 50236,
                "label": "Perignano",
                "label_ar": "بيرينيانو",
                "label_fr": "Perignano"
            },
            {
                "value": 50237,
                "label": "Bibbona",
                "label_ar": "بيبونا",
                "label_fr": "Bibbona"
            },
            {
                "value": 50238,
                "label": "Borgonovo",
                "label_ar": "بورجونوفو",
                "label_fr": "Borgonovo"
            },
            {
                "value": 50239,
                "label": "Sant'Angelo Romano",
                "label_ar": "سانت أنجيلو رومانو",
                "label_fr": "Sant'Angelo Romano"
            },
            {
                "value": 50240,
                "label": "Cameriano",
                "label_ar": "كاميريانو",
                "label_fr": "Cameriano"
            },
            {
                "value": 50241,
                "label": "Pertengo",
                "label_ar": "بيرتنغو",
                "label_fr": "Pertengo"
            },
            {
                "value": 50242,
                "label": "Caresana",
                "label_ar": "كاريسانا",
                "label_fr": "Caresana"
            },
            {
                "value": 50243,
                "label": "Offagna",
                "label_ar": "Offagna",
                "label_fr": "Offagna"
            },
            {
                "value": 50244,
                "label": "La Villa",
                "label_ar": "لا فيلا",
                "label_fr": "La Villa"
            },
            {
                "value": 50245,
                "label": "Malonno",
                "label_ar": "مالونو",
                "label_fr": "Malonno"
            },
            {
                "value": 50246,
                "label": "Badia",
                "label_ar": "البادية",
                "label_fr": "Badia"
            },
            {
                "value": 50247,
                "label": "Mals",
                "label_ar": "مالز",
                "label_fr": "Mals"
            },
            {
                "value": 50248,
                "label": "Natz-Schabs",
                "label_ar": "ناتز شابس",
                "label_fr": "Natz-Schabs"
            },
            {
                "value": 50249,
                "label": "Casateia",
                "label_ar": "كاساتيا",
                "label_fr": "Casateia"
            },
            {
                "value": 50250,
                "label": "Sarnthein",
                "label_ar": "سارنتين",
                "label_fr": "Sarnthein"
            },
            {
                "value": 50251,
                "label": "Ponte Galeria-La Pisana",
                "label_ar": "بونتي جاليريا لا بيسانا",
                "label_fr": "Ponte Galeria-La Pisana"
            },
            {
                "value": 50252,
                "label": "Minervino di Lecce",
                "label_ar": "مينرفينو دي ليتشي",
                "label_fr": "Minervino di Lecce"
            },
            {
                "value": 50253,
                "label": "Neviano",
                "label_ar": "نيفيانو",
                "label_fr": "Neviano"
            },
            {
                "value": 50254,
                "label": "Morciano di Leuca",
                "label_ar": "مورسيانو دي لوكا",
                "label_fr": "Morciano di Leuca"
            },
            {
                "value": 50255,
                "label": "Monte Roberto",
                "label_ar": "مونتي روبرتو",
                "label_fr": "Monte Roberto"
            },
            {
                "value": 50256,
                "label": "Treglio",
                "label_ar": "تريجليو",
                "label_fr": "Treglio"
            },
            {
                "value": 50257,
                "label": "Casacanditella",
                "label_ar": "Casacanditella",
                "label_fr": "Casacanditella"
            },
            {
                "value": 50258,
                "label": "Mojo Alcantara",
                "label_ar": "موجو الكانتارا",
                "label_fr": "Mojo Alcantara"
            },
            {
                "value": 50259,
                "label": "Mühlwald",
                "label_ar": "مولوالد",
                "label_fr": "Mühlwald"
            },
            {
                "value": 50260,
                "label": "Terenten",
                "label_ar": "Terenten",
                "label_fr": "Terenten"
            },
            {
                "value": 50261,
                "label": "Resia",
                "label_ar": "ريسيا",
                "label_fr": "Resia"
            },
            {
                "value": 50262,
                "label": "Anterselva di Mezzo",
                "label_ar": "Anterselva di Mezzo",
                "label_fr": "Anterselva di Mezzo"
            },
            {
                "value": 50263,
                "label": "Solda",
                "label_ar": "سولدا",
                "label_fr": "Solda"
            },
            {
                "value": 50264,
                "label": "Polinago",
                "label_ar": "بوليناغو",
                "label_fr": "Polinago"
            },
            {
                "value": 50265,
                "label": "San Venanzio",
                "label_ar": "سان فينانزيو",
                "label_fr": "San Venanzio"
            },
            {
                "value": 50266,
                "label": "Pernumia",
                "label_ar": "بيرنوميا",
                "label_fr": "Pernumie"
            },
            {
                "value": 50267,
                "label": "Castel Focognano",
                "label_ar": "كاستل فوكوجنانو",
                "label_fr": "Castel Focognano"
            },
            {
                "value": 50268,
                "label": "Bagni",
                "label_ar": "باني",
                "label_fr": "Bagni"
            },
            {
                "value": 50269,
                "label": "Spirano",
                "label_ar": "سبيرانو",
                "label_fr": "Spirano"
            },
            {
                "value": 50270,
                "label": "Salento",
                "label_ar": "سالينتو",
                "label_fr": "Salento"
            },
            {
                "value": 50271,
                "label": "Molinari",
                "label_ar": "موليناري",
                "label_fr": "Molinari"
            },
            {
                "value": 50272,
                "label": "Colonna",
                "label_ar": "كولونا",
                "label_fr": "Colonna"
            },
            {
                "value": 50273,
                "label": "Cinecitta",
                "label_ar": "Cinecitta",
                "label_fr": "Cinecitta"
            },
            {
                "value": 50274,
                "label": "Borgo a Mozzano",
                "label_ar": "بورجو موزانو",
                "label_fr": "Borgo a Mozzano"
            },
            {
                "value": 50275,
                "label": "Brossasco",
                "label_ar": "بروساسكو",
                "label_fr": "Brossasco"
            },
            {
                "value": 50276,
                "label": "Salmour",
                "label_ar": "سلمور",
                "label_fr": "Salmour"
            },
            {
                "value": 50277,
                "label": "Malcontenta",
                "label_ar": "مالكونتينتا",
                "label_fr": "Malcontenta"
            },
            {
                "value": 50278,
                "label": "Baluello",
                "label_ar": "بالويلو",
                "label_fr": "Baluello"
            },
            {
                "value": 50279,
                "label": "Loreo",
                "label_ar": "لوريو",
                "label_fr": "Loreo"
            },
            {
                "value": 50280,
                "label": "Pieve Fosciana",
                "label_ar": "بيفي فوسيانا",
                "label_fr": "Pieve Fosciana"
            },
            {
                "value": 50281,
                "label": "Campoleone",
                "label_ar": "كامبولوني",
                "label_fr": "Campoléone"
            },
            {
                "value": 50282,
                "label": "Casa Zola",
                "label_ar": "كاسا زولا",
                "label_fr": "Casa Zola"
            },
            {
                "value": 50283,
                "label": "San Lorenzo",
                "label_ar": "سان لورينزو",
                "label_fr": "San Lorenzo"
            },
            {
                "value": 50284,
                "label": "Torretta",
                "label_ar": "توريتا",
                "label_fr": "Torretta"
            },
            {
                "value": 50285,
                "label": "Sella",
                "label_ar": "سيلا",
                "label_fr": "Sella"
            },
            {
                "value": 50286,
                "label": "Bagnasco",
                "label_ar": "باجناسكو",
                "label_fr": "Bagnasco"
            },
            {
                "value": 50287,
                "label": "Fabbrica",
                "label_ar": "فابريكا",
                "label_fr": "Fabbrica"
            },
            {
                "value": 50288,
                "label": "San Nicolo",
                "label_ar": "سان نيكولو",
                "label_fr": "San Nicolo"
            },
            {
                "value": 50289,
                "label": "San Nicola",
                "label_ar": "سان نيكولا",
                "label_fr": "San Nicola"
            },
            {
                "value": 50290,
                "label": "Ambra",
                "label_ar": "أمبرا",
                "label_fr": "Ambra"
            },
            {
                "value": 50291,
                "label": "Oliveto",
                "label_ar": "أوليفيتو",
                "label_fr": "Oliveto"
            },
            {
                "value": 50292,
                "label": "Passo",
                "label_ar": "باسو",
                "label_fr": "Passo"
            },
            {
                "value": 50293,
                "label": "Casavecchia",
                "label_ar": "كاسافيكيا",
                "label_fr": "Casavecchia"
            },
            {
                "value": 50294,
                "label": "Santa Severa",
                "label_ar": "سانتا سيفيرا",
                "label_fr": "Santa Severa"
            },
            {
                "value": 50295,
                "label": "Torrecuso",
                "label_ar": "توريكوسو",
                "label_fr": "Torrecuso"
            },
            {
                "value": 50296,
                "label": "Coltura",
                "label_ar": "كولتورا",
                "label_fr": "Coltura"
            },
            {
                "value": 50297,
                "label": "Antonimina",
                "label_ar": "أنتونيمينا",
                "label_fr": "Antonimina"
            },
            {
                "value": 50298,
                "label": "Genivolta",
                "label_ar": "جينيفولتا",
                "label_fr": "Genivolta"
            },
            {
                "value": 50299,
                "label": "Crotta d'Adda",
                "label_ar": "كروتا دادا",
                "label_fr": "Crotta d'Adda"
            },
            {
                "value": 50300,
                "label": "Oliveto Citra",
                "label_ar": "أوليفيتو سيترا",
                "label_fr": "Oliveto Citra"
            },
            {
                "value": 50301,
                "label": "Prarolo",
                "label_ar": "برارولو",
                "label_fr": "Prarolo"
            },
            {
                "value": 50302,
                "label": "La Via",
                "label_ar": "لا فيا",
                "label_fr": "La Via"
            },
            {
                "value": 50303,
                "label": "Radda in Chianti",
                "label_ar": "رادا في كيانتي",
                "label_fr": "Radda in Chianti"
            },
            {
                "value": 50304,
                "label": "Riale",
                "label_ar": "ريالي",
                "label_fr": "Riale"
            }
        ]
    },
    {
        "country": "Netherlands",
        "cities": [
            {
                "value": 50779,
                "label": "Boerakker",
                "label_ar": "Boerakker",
                "label_fr": "Boerakker"
            },
            {
                "value": 50814,
                "label": "Bavel",
                "label_ar": "بافل",
                "label_fr": "Bavel"
            },
            {
                "value": 51675,
                "label": "Rijckholt",
                "label_ar": "ريجكهولت",
                "label_fr": "Rijckholt"
            },
            {
                "value": 50538,
                "label": "Velden",
                "label_ar": "فيلدين",
                "label_fr": "Velden"
            },
            {
                "value": 50915,
                "label": "Tricht",
                "label_ar": "تريخت",
                "label_fr": "Tricht"
            },
            {
                "value": 51060,
                "label": "Montfort",
                "label_ar": "مونتفورت",
                "label_fr": "Montfort"
            },
            {
                "value": 51522,
                "label": "Brakel",
                "label_ar": "براكيل",
                "label_fr": "Brakel"
            },
            {
                "value": 50482,
                "label": "Heesch",
                "label_ar": "هيش",
                "label_fr": "Heesch"
            },
            {
                "value": 50910,
                "label": "Veenoord",
                "label_ar": "فينورد",
                "label_fr": "Veenoord"
            },
            {
                "value": 50634,
                "label": "Sittard",
                "label_ar": "سيتارد",
                "label_fr": "Sittard"
            },
            {
                "value": 50553,
                "label": "Vleuten",
                "label_ar": "فلوتين",
                "label_fr": "Vleuten"
            },
            {
                "value": 51737,
                "label": "Ravenswoud",
                "label_ar": "Ravenswoud",
                "label_fr": "Ravenswoud"
            },
            {
                "value": 51437,
                "label": "Benthuizen",
                "label_ar": "بنتويزن",
                "label_fr": "Benthuizen"
            },
            {
                "value": 50544,
                "label": "De Steeg",
                "label_ar": "دي شتيج",
                "label_fr": "De Steeg"
            },
            {
                "value": 50904,
                "label": "Monnickendam",
                "label_ar": "Monnickendam",
                "label_fr": "Monnickendam"
            },
            {
                "value": 51435,
                "label": "Benningbroek",
                "label_ar": "بنينجبروك",
                "label_fr": "Benningbroek"
            },
            {
                "value": 51040,
                "label": "Enter",
                "label_ar": "أدخل",
                "label_fr": "Entrer"
            },
            {
                "value": 51430,
                "label": "Wijdenes",
                "label_ar": "ويجدينيس",
                "label_fr": "Wijdenes"
            },
            {
                "value": 50866,
                "label": "Achthuizen",
                "label_ar": "أشثويزن",
                "label_fr": "Achthuizen"
            },
            {
                "value": 51298,
                "label": "Puth",
                "label_ar": "بوث",
                "label_fr": "Puth"
            },
            {
                "value": 51463,
                "label": "Keijenborg",
                "label_ar": "كيجينبورغ",
                "label_fr": "Keijenborg"
            },
            {
                "value": 51711,
                "label": "Den Ilp",
                "label_ar": "دن إيلب",
                "label_fr": "Den Ilp"
            },
            {
                "value": 50490,
                "label": "Maastricht",
                "label_ar": "ماستريخت",
                "label_fr": "Maastricht"
            },
            {
                "value": 50756,
                "label": "Sneek",
                "label_ar": "سنيك",
                "label_fr": "Sneek"
            },
            {
                "value": 51431,
                "label": "Hem",
                "label_ar": "هدب",
                "label_fr": "Ourlet"
            },
            {
                "value": 51567,
                "label": "Hoogmade",
                "label_ar": "Hoogmade",
                "label_fr": "Hoogmade"
            },
            {
                "value": 51622,
                "label": "Slagharen",
                "label_ar": "سلاغارين",
                "label_fr": "Slagharen"
            },
            {
                "value": 50654,
                "label": "Hoek van Holland",
                "label_ar": "هوك فان هولاند",
                "label_fr": "Hoek van Holland"
            },
            {
                "value": 51520,
                "label": "Breugel",
                "label_ar": "بروجيل",
                "label_fr": "Breugel"
            },
            {
                "value": 50938,
                "label": "Ingen",
                "label_ar": "إنجين",
                "label_fr": "Ingen"
            },
            {
                "value": 51191,
                "label": "Wormer",
                "label_ar": "ورمر",
                "label_fr": "Vermifuge"
            },
            {
                "value": 51359,
                "label": "Mechelen",
                "label_ar": "ميكلين",
                "label_fr": "Malines"
            },
            {
                "value": 51176,
                "label": "Oudeschild",
                "label_ar": "Oudeschild",
                "label_fr": "Oudeschild"
            },
            {
                "value": 51447,
                "label": "Wijdewormer",
                "label_ar": "Wijdewormer",
                "label_fr": "Wijdewormer"
            },
            {
                "value": 51411,
                "label": "IJzendijke",
                "label_ar": "IJzendijke",
                "label_fr": "IJzendijke"
            },
            {
                "value": 50708,
                "label": "Oostburg",
                "label_ar": "أوستبورغ",
                "label_fr": "Oostburg"
            },
            {
                "value": 50868,
                "label": "Belfeld",
                "label_ar": "بلفيلد",
                "label_fr": "Belfeld"
            },
            {
                "value": 51024,
                "label": "Sint Agatha",
                "label_ar": "سينت أجاثا",
                "label_fr": "Sint Agatha"
            },
            {
                "value": 51116,
                "label": "Haarle",
                "label_ar": "هارلي",
                "label_fr": "Haarle"
            },
            {
                "value": 51248,
                "label": "Maartensdijk",
                "label_ar": "مارتنسديجك",
                "label_fr": "Maartensdijk"
            },
            {
                "value": 50669,
                "label": "Puiflijk",
                "label_ar": "بويفليجك",
                "label_fr": "Puiflijk"
            },
            {
                "value": 50635,
                "label": "Eibergen",
                "label_ar": "ايبيرجين",
                "label_fr": "Eibergen"
            },
            {
                "value": 50842,
                "label": "Leiderdorp",
                "label_ar": "لايدردورب",
                "label_fr": "Leiderdorp"
            },
            {
                "value": 50418,
                "label": "Ridderkerk",
                "label_ar": "ريدركيرك",
                "label_fr": "Ridderkerk"
            },
            {
                "value": 50943,
                "label": "Twijzelerheide",
                "label_ar": "تويزلرهيد",
                "label_fr": "Twijzelerheide"
            },
            {
                "value": 51717,
                "label": "Exloo",
                "label_ar": "إكسلو",
                "label_fr": "Exloo"
            },
            {
                "value": 50305,
                "label": "Amsterdam",
                "label_ar": "أمستردام",
                "label_fr": "Amsterdam"
            },
            {
                "value": 51180,
                "label": "Steenwijk",
                "label_ar": "ستينويك",
                "label_fr": "Steenwijk"
            },
            {
                "value": 51255,
                "label": "Oud-Zuilen",
                "label_ar": "عود زويلين",
                "label_fr": "Oud-Zuilen"
            },
            {
                "value": 51327,
                "label": "Buchten",
                "label_ar": "بوختن",
                "label_fr": "Buchten"
            },
            {
                "value": 51602,
                "label": "De Wilp",
                "label_ar": "دي ويلب",
                "label_fr": "De Wilp"
            },
            {
                "value": 50803,
                "label": "Ankeveen",
                "label_ar": "أنكيفين",
                "label_fr": "Ankeveen"
            },
            {
                "value": 50751,
                "label": "Hoenderloo",
                "label_ar": "هويندرلو",
                "label_fr": "Hoenderloo"
            },
            {
                "value": 51123,
                "label": "Nijland",
                "label_ar": "نيجلاند",
                "label_fr": "Nijland"
            },
            {
                "value": 51277,
                "label": "Groessen",
                "label_ar": "جروسين",
                "label_fr": "Groessen"
            },
            {
                "value": 50393,
                "label": "Amstelveen",
                "label_ar": "أمستلفين",
                "label_fr": "Amstelveen"
            },
            {
                "value": 51772,
                "label": "Egmond-Binnen",
                "label_ar": "إغموند بينين",
                "label_fr": "Egmond-Binnen"
            },
            {
                "value": 51581,
                "label": "Oudesluis",
                "label_ar": "Oudesluis",
                "label_fr": "Oudesluis"
            },
            {
                "value": 51509,
                "label": "Slijk-Ewijk",
                "label_ar": "Slijk-Ewijk",
                "label_fr": "Slijk-Ewijk"
            },
            {
                "value": 51001,
                "label": "Venray",
                "label_ar": "فينراي",
                "label_fr": "Venray"
            },
            {
                "value": 51561,
                "label": "Alde Leie",
                "label_ar": "ألد لي",
                "label_fr": "Alde Leie"
            },
            {
                "value": 50877,
                "label": "Tegelen",
                "label_ar": "تيجلين",
                "label_fr": "Tegelen"
            },
            {
                "value": 51192,
                "label": "Oisterwijk",
                "label_ar": "Oisterwijk",
                "label_fr": "Oisterwijk"
            },
            {
                "value": 50549,
                "label": "Arcen",
                "label_ar": "آرسين",
                "label_fr": "Arcen"
            },
            {
                "value": 50881,
                "label": "Geleen",
                "label_ar": "جيلين",
                "label_fr": "Geleen"
            },
            {
                "value": 51474,
                "label": "Zieuwent",
                "label_ar": "زيووينت",
                "label_fr": "Zieuwent"
            },
            {
                "value": 51282,
                "label": "Millingen",
                "label_ar": "ميلينجن",
                "label_fr": "Millingen"
            },
            {
                "value": 50621,
                "label": "Krimpen aan de Lek",
                "label_ar": "Krimpen aan de Lek",
                "label_fr": "Krimpen aan de Lek"
            },
            {
                "value": 50837,
                "label": "Wervershoof",
                "label_ar": "Wervershoof",
                "label_fr": "Wervershoof"
            },
            {
                "value": 50639,
                "label": "Middelharnis",
                "label_ar": "ميدلهارنيس",
                "label_fr": "Middelharnis"
            },
            {
                "value": 51265,
                "label": "Berghem",
                "label_ar": "بيرغيم",
                "label_fr": "Berghem"
            },
            {
                "value": 51305,
                "label": "Nieuw-Vossemeer",
                "label_ar": "نيو فوسيمير",
                "label_fr": "Nieuw-Vossemeer"
            },
            {
                "value": 51369,
                "label": "Siebengewald",
                "label_ar": "سيبينجوالد",
                "label_fr": "Siebengewald"
            },
            {
                "value": 51008,
                "label": "Boxtel",
                "label_ar": "Boxtel",
                "label_fr": "Boxtel"
            },
            {
                "value": 51113,
                "label": "Daarle",
                "label_ar": "دارل",
                "label_fr": "Daarle"
            },
            {
                "value": 50431,
                "label": "Beek",
                "label_ar": "بيك",
                "label_fr": "Beek"
            },
            {
                "value": 51689,
                "label": "Oldehove",
                "label_ar": "أولدهوف",
                "label_fr": "Oldehove"
            },
            {
                "value": 51331,
                "label": "Meijel",
                "label_ar": "ميجيل",
                "label_fr": "Meijel"
            },
            {
                "value": 51511,
                "label": "Haulerwijk",
                "label_ar": "Haulerwijk",
                "label_fr": "Haulerwijk"
            },
            {
                "value": 51534,
                "label": "Zorgvlied",
                "label_ar": "Zorgvlied",
                "label_fr": "Zorgvlied"
            },
            {
                "value": 50370,
                "label": "Amersfoort",
                "label_ar": "أمرسفورت",
                "label_fr": "Amersfoort"
            },
            {
                "value": 50788,
                "label": "Alblasserdam",
                "label_ar": "البلاسيردام",
                "label_fr": "Alblasserdam"
            },
            {
                "value": 51145,
                "label": "Hindeloopen",
                "label_ar": "Hindeloopen",
                "label_fr": "Hindeloopen"
            },
            {
                "value": 51228,
                "label": "Nieuwveen",
                "label_ar": "نيوفين",
                "label_fr": "Nieuwveen"
            },
            {
                "value": 51668,
                "label": "De Punt",
                "label_ar": "دي بونت",
                "label_fr": "De Punt"
            },
            {
                "value": 50453,
                "label": "Rijswijk",
                "label_ar": "Rijswijk",
                "label_fr": "Rijswijk"
            },
            {
                "value": 51312,
                "label": "Nistelrode",
                "label_ar": "نيستلرود",
                "label_fr": "Nistelrode"
            },
            {
                "value": 51238,
                "label": "Ammerstol",
                "label_ar": "أميرستول",
                "label_fr": "Ammerstol"
            },
            {
                "value": 51627,
                "label": "Beerta",
                "label_ar": "بيرتا",
                "label_fr": "Beerta"
            },
            {
                "value": 51402,
                "label": "Uitwellingerga",
                "label_ar": "يوتويلنجرجا",
                "label_fr": "Uitwellingerga"
            },
            {
                "value": 51497,
                "label": "Leimuiden",
                "label_ar": "Leimuiden",
                "label_fr": "Leimuiden"
            },
            {
                "value": 51676,
                "label": "Nijnsel",
                "label_ar": "نيجنسيل",
                "label_fr": "Nijnsel"
            },
            {
                "value": 51127,
                "label": "Jutrijp",
                "label_ar": "جوتريجب",
                "label_fr": "Jutrijp"
            },
            {
                "value": 51150,
                "label": "Wijster",
                "label_ar": "ويجستر",
                "label_fr": "Wijster"
            },
            {
                "value": 50438,
                "label": "Hardenberg",
                "label_ar": "هاردنبيرج",
                "label_fr": "Hardenberg"
            },
            {
                "value": 50536,
                "label": "Overasselt",
                "label_ar": "أوفاسيلت",
                "label_fr": "Overasselt"
            },
            {
                "value": 50965,
                "label": "Pernis",
                "label_ar": "بيرنيس",
                "label_fr": "Pernis"
            },
            {
                "value": 51669,
                "label": "Cruquius",
                "label_ar": "كروكيوس",
                "label_fr": "Cruquius"
            },
            {
                "value": 51611,
                "label": "Emmer-Compascuum",
                "label_ar": "إيمير كومباسكوم",
                "label_fr": "Emmer-Compaspirateur"
            },
            {
                "value": 50665,
                "label": "Hoge Donk",
                "label_ar": "هوج دونك",
                "label_fr": "Hoge Donk"
            },
            {
                "value": 51501,
                "label": "Oostendam",
                "label_ar": "اوستندام",
                "label_fr": "Oostendam"
            },
            {
                "value": 51697,
                "label": "Dirkshorn",
                "label_ar": "ديركشورن",
                "label_fr": "Dirkshorn"
            },
            {
                "value": 50631,
                "label": "Born",
                "label_ar": "مولود",
                "label_fr": "Née"
            },
            {
                "value": 50678,
                "label": "Weesp",
                "label_ar": "ويسب",
                "label_fr": "Weesp"
            },
            {
                "value": 50850,
                "label": "Schoonhoven",
                "label_ar": "شونهوفن",
                "label_fr": "Schoonhoven"
            },
            {
                "value": 50477,
                "label": "Veghel",
                "label_ar": "فيجيل",
                "label_fr": "Veghel"
            },
            {
                "value": 50735,
                "label": "Giessendam",
                "label_ar": "جيسيندام",
                "label_fr": "Giessendam"
            },
            {
                "value": 51000,
                "label": "Linschoten",
                "label_ar": "لينشوتين",
                "label_fr": "Linschoten"
            },
            {
                "value": 51608,
                "label": "Nieuw-Schoonebeek",
                "label_ar": "نيو شونبيك",
                "label_fr": "Nieuw-Schoonebeek"
            },
            {
                "value": 50645,
                "label": "Nieuwe-Tonge",
                "label_ar": "نيوي تونج",
                "label_fr": "Nieuwe-Tonge"
            },
            {
                "value": 50598,
                "label": "Nederhemert",
                "label_ar": "نديرهيميرت",
                "label_fr": "Nederhemert"
            },
            {
                "value": 50548,
                "label": "Uithoorn",
                "label_ar": "أوثورن",
                "label_fr": "Uithoorn"
            },
            {
                "value": 50604,
                "label": "Oss",
                "label_ar": "أوس",
                "label_fr": "Oss"
            },
            {
                "value": 51408,
                "label": "Bovenkarspel",
                "label_ar": "بوفينكارسبيل",
                "label_fr": "Bovenkarspel"
            },
            {
                "value": 50513,
                "label": "Zevenaar",
                "label_ar": "زيفينار",
                "label_fr": "Zevenaar"
            },
            {
                "value": 50835,
                "label": "Tynaarlo",
                "label_ar": "تاينارلو",
                "label_fr": "Tynaarlo"
            },
            {
                "value": 50306,
                "label": "The Hague",
                "label_ar": "لاهاي",
                "label_fr": "La Haye"
            },
            {
                "value": 51006,
                "label": "De Waal",
                "label_ar": "دي وال",
                "label_fr": "De Waal"
            },
            {
                "value": 51460,
                "label": "Opmeer",
                "label_ar": "أوبير",
                "label_fr": "Opmeer"
            },
            {
                "value": 50996,
                "label": "Naarden",
                "label_ar": "ناردين",
                "label_fr": "Naarden"
            },
            {
                "value": 51293,
                "label": "Sweikhuizen",
                "label_ar": "سويخويزن",
                "label_fr": "Sweikhuizen"
            },
            {
                "value": 50701,
                "label": "Axel",
                "label_ar": "أكسل",
                "label_fr": "Axel"
            },
            {
                "value": 50721,
                "label": "Bruinisse",
                "label_ar": "Bruinisse",
                "label_fr": "Bruinisse"
            },
            {
                "value": 50557,
                "label": "IJsselstein",
                "label_ar": "IJsselstein",
                "label_fr": "IJsselstein"
            },
            {
                "value": 51114,
                "label": "Geesteren",
                "label_ar": "جيستيرن",
                "label_fr": "Geesteren"
            },
            {
                "value": 50531,
                "label": "Wittem",
                "label_ar": "ويتيم",
                "label_fr": "Wittem"
            },
            {
                "value": 51383,
                "label": "Zonnemaire",
                "label_ar": "زونيماير",
                "label_fr": "Zonnemaire"
            },
            {
                "value": 51706,
                "label": "Kuitaart",
                "label_ar": "Kuitaart",
                "label_fr": "Kuitaart"
            },
            {
                "value": 50589,
                "label": "Monster",
                "label_ar": "وحش",
                "label_fr": "Monstre"
            },
            {
                "value": 50347,
                "label": "Hoofddorp",
                "label_ar": "هوفدورب",
                "label_fr": "Hoofddorp"
            },
            {
                "value": 50748,
                "label": "Wieringerwerf",
                "label_ar": "Wieringerwerf",
                "label_fr": "Wieringerwerf"
            },
            {
                "value": 51548,
                "label": "Ugchelen",
                "label_ar": "أوغشيلين",
                "label_fr": "Ugchelen"
            },
            {
                "value": 50331,
                "label": "Eindhoven",
                "label_ar": "أيندهوفن",
                "label_fr": "Eindhoven"
            },
            {
                "value": 51121,
                "label": "Weidum",
                "label_ar": "ويدوم",
                "label_fr": "Weidum"
            },
            {
                "value": 51560,
                "label": "Meerkerk",
                "label_ar": "ميركيرك",
                "label_fr": "Meerkerk"
            },
            {
                "value": 51156,
                "label": "Rietveld",
                "label_ar": "ريتفيلد",
                "label_fr": "Rietveld"
            },
            {
                "value": 50609,
                "label": "Volendam",
                "label_ar": "فولندام",
                "label_fr": "Volendam"
            },
            {
                "value": 51106,
                "label": "Vries",
                "label_ar": "فريس",
                "label_fr": "Vries"
            },
            {
                "value": 50512,
                "label": "Elst",
                "label_ar": "إلست",
                "label_fr": "Elst"
            },
            {
                "value": 51547,
                "label": "Houtakker",
                "label_ar": "Houtakker",
                "label_fr": "Houtakker"
            },
            {
                "value": 50641,
                "label": "Lochem",
                "label_ar": "لوتشيم",
                "label_fr": "Lochem"
            },
            {
                "value": 51256,
                "label": "Loosdrecht",
                "label_ar": "لوسدريخت",
                "label_fr": "Loosdrecht"
            },
            {
                "value": 51353,
                "label": "Margraten",
                "label_ar": "مارغراتن",
                "label_fr": "Margraten"
            },
            {
                "value": 50420,
                "label": "Papendrecht",
                "label_ar": "بابندريخت",
                "label_fr": "Papendrecht"
            },
            {
                "value": 50585,
                "label": "Maasdijk",
                "label_ar": "Maasdijk",
                "label_fr": "Maasdijk"
            },
            {
                "value": 50767,
                "label": "Noordwijkerhout",
                "label_ar": "نوردويكرهوت",
                "label_fr": "Noordwijkerhout"
            },
            {
                "value": 51484,
                "label": "Marienberg",
                "label_ar": "مارينبيرج",
                "label_fr": "Marienberg"
            },
            {
                "value": 51687,
                "label": "Witharen",
                "label_ar": "ويثارين",
                "label_fr": "Witharen"
            },
            {
                "value": 50831,
                "label": "Dinxperlo",
                "label_ar": "دينكسبيرلو",
                "label_fr": "Dinxperlo"
            },
            {
                "value": 50863,
                "label": "Tytsjerk",
                "label_ar": "Tytsjerk",
                "label_fr": "Tytsjerk"
            },
            {
                "value": 50352,
                "label": "Oud-Beijerland",
                "label_ar": "عود بايرلاند",
                "label_fr": "Oud-Beijerland"
            },
            {
                "value": 50718,
                "label": "Sint Philipsland",
                "label_ar": "سينت فيلبس لاند",
                "label_fr": "Sint Philipsland"
            },
            {
                "value": 51725,
                "label": "Hollum",
                "label_ar": "هولوم",
                "label_fr": "Hollum"
            },
            {
                "value": 50607,
                "label": "Spaubeek",
                "label_ar": "سباوبيك",
                "label_fr": "Spaubeek"
            },
            {
                "value": 50507,
                "label": "Sint Anthonis",
                "label_ar": "سينت أنطونيس",
                "label_fr": "Sint Anthonis"
            },
            {
                "value": 51646,
                "label": "Zuidermeer",
                "label_ar": "زويدرمير",
                "label_fr": "Zuidermeer"
            },
            {
                "value": 50643,
                "label": "Heerjansdam",
                "label_ar": "هيرجانسدام",
                "label_fr": "Heerjansdam"
            },
            {
                "value": 51471,
                "label": "Nigtevecht",
                "label_ar": "Nigtevecht",
                "label_fr": "Nigtevecht"
            },
            {
                "value": 50340,
                "label": "Arnhem",
                "label_ar": "أرنهيم",
                "label_fr": "Arnhem"
            },
            {
                "value": 50357,
                "label": "Maasdam",
                "label_ar": "ماصدم",
                "label_fr": "Maasdam"
            },
            {
                "value": 50558,
                "label": "Enkhuizen",
                "label_ar": "Enkhuizen",
                "label_fr": "Enkhuizen"
            },
            {
                "value": 50633,
                "label": "Roelofarendsveen",
                "label_ar": "Roelofarendsveen",
                "label_fr": "Roelofarendsveen"
            },
            {
                "value": 50994,
                "label": "Oldenzaal",
                "label_ar": "أولدنزال",
                "label_fr": "Oldenzaal"
            },
            {
                "value": 51525,
                "label": "Randwijk",
                "label_ar": "راندويجك",
                "label_fr": "Randwijk"
            },
            {
                "value": 50707,
                "label": "Koewacht",
                "label_ar": "كويوت",
                "label_fr": "Koewacht"
            },
            {
                "value": 50324,
                "label": "Veendam",
                "label_ar": "فيندام",
                "label_fr": "Veendam"
            },
            {
                "value": 51609,
                "label": "Schoonebeek",
                "label_ar": "شونيبيك",
                "label_fr": "Schoonebeek"
            },
            {
                "value": 51722,
                "label": "Achterberg",
                "label_ar": "أشتربيرج",
                "label_fr": "Achterberg"
            },
            {
                "value": 51184,
                "label": "Kampen",
                "label_ar": "كامبن",
                "label_fr": "Kampen"
            },
            {
                "value": 50499,
                "label": "Nootdorp",
                "label_ar": "نوتدورب",
                "label_fr": "Nootdorp"
            },
            {
                "value": 50906,
                "label": "Zevenbergschen Hoek",
                "label_ar": "زيفينبيرجشين هوك",
                "label_fr": "Zevenbergschen Hoek"
            },
            {
                "value": 51012,
                "label": "Nieuwerbrug",
                "label_ar": "نيويربروغ",
                "label_fr": "Nieuwerbrug"
            },
            {
                "value": 51213,
                "label": "Serooskerke",
                "label_ar": "سيروسكيركي",
                "label_fr": "Serooskerke"
            },
            {
                "value": 51692,
                "label": "Driel",
                "label_ar": "دريل",
                "label_fr": "Driel"
            },
            {
                "value": 51756,
                "label": "Lemele",
                "label_ar": "ليميلي",
                "label_fr": "Lemele"
            },
            {
                "value": 50894,
                "label": "Harich",
                "label_ar": "هاريش",
                "label_fr": "Harich"
            },
            {
                "value": 50405,
                "label": "Uddel",
                "label_ar": "أوديل",
                "label_fr": "Uddel"
            },
            {
                "value": 50475,
                "label": "Oosterhout",
                "label_ar": "اوسترهاوت",
                "label_fr": "Oosterhout"
            },
            {
                "value": 51212,
                "label": "Sint Maartensdijk",
                "label_ar": "سينت مارتنسديجك",
                "label_fr": "Sint Maartensdijk"
            },
            {
                "value": 51495,
                "label": "Doornenburg",
                "label_ar": "دورننبورغ",
                "label_fr": "Doornenburg"
            },
            {
                "value": 51569,
                "label": "Noorden",
                "label_ar": "نوردن",
                "label_fr": "Noorden"
            },
            {
                "value": 50367,
                "label": "Wageningen",
                "label_ar": "فاغينينغين",
                "label_fr": "Wageningen"
            },
            {
                "value": 50382,
                "label": "Valkenburg",
                "label_ar": "فالكنبورغ",
                "label_fr": "Valkenburg"
            },
            {
                "value": 51549,
                "label": "Molenaarsgraaf",
                "label_ar": "مولينارسجراف",
                "label_fr": "Molenaarsgraaf"
            },
            {
                "value": 50743,
                "label": "Venhuizen",
                "label_ar": "فينهويزين",
                "label_fr": "Venhuizen"
            },
            {
                "value": 51167,
                "label": "Sint Willebrord",
                "label_ar": "سينت ويلبرورد",
                "label_fr": "Sint Willebrord"
            },
            {
                "value": 51584,
                "label": "Mussel",
                "label_ar": "بلح البحر",
                "label_fr": "Moule"
            },
            {
                "value": 50630,
                "label": "Roosendaal",
                "label_ar": "روزندال",
                "label_fr": "Roosendaal"
            },
            {
                "value": 50396,
                "label": "Op den Bosch",
                "label_ar": "أب دن بوش",
                "label_fr": "Op den Bosch"
            },
            {
                "value": 51079,
                "label": "Herkingen",
                "label_ar": "هيركينجين",
                "label_fr": "Herkingen"
            },
            {
                "value": 51476,
                "label": "Stoutenburg",
                "label_ar": "ستوتينبورج",
                "label_fr": "Stoutenburg"
            },
            {
                "value": 51658,
                "label": "Ammerzoden",
                "label_ar": "أميرزودن",
                "label_fr": "Ammerzoden"
            },
            {
                "value": 50597,
                "label": "Geldermalsen",
                "label_ar": "جيلديرمالسن",
                "label_fr": "Geldermalsen"
            },
            {
                "value": 51508,
                "label": "Garmerwolde",
                "label_ar": "جارمروولد",
                "label_fr": "Garmerwolde"
            },
            {
                "value": 51443,
                "label": "De Rijp",
                "label_ar": "دي ريجب",
                "label_fr": "De Rijp"
            },
            {
                "value": 51479,
                "label": "Woerdense Verlaat",
                "label_ar": "Woerdense Verlaat",
                "label_fr": "Woerdense Verlaat"
            },
            {
                "value": 50661,
                "label": "Winterswijk",
                "label_ar": "وينترسفيك",
                "label_fr": "Winterswijk"
            },
            {
                "value": 50602,
                "label": "Voorschoten",
                "label_ar": "فورشوتين",
                "label_fr": "Voorschoten"
            },
            {
                "value": 50961,
                "label": "Baambrugge",
                "label_ar": "بامبروج",
                "label_fr": "Baambrugge"
            },
            {
                "value": 51612,
                "label": "Klazienaveen",
                "label_ar": "كلازينافين",
                "label_fr": "Klazienaveen"
            },
            {
                "value": 51258,
                "label": "Zevenhuizen",
                "label_ar": "زيفينهويزن",
                "label_fr": "Zevenhuizen"
            },
            {
                "value": 50359,
                "label": "Zuilichem",
                "label_ar": "Zuilichem",
                "label_fr": "Zuilichem"
            },
            {
                "value": 50827,
                "label": "Oosterbierum",
                "label_ar": "أوستربيروم",
                "label_fr": "Oosterbierum"
            },
            {
                "value": 50733,
                "label": "Sluiskil",
                "label_ar": "سلايسكيل",
                "label_fr": "Sluiskil"
            },
            {
                "value": 50575,
                "label": "Geesteren",
                "label_ar": "جيستيرن",
                "label_fr": "Geesteren"
            },
            {
                "value": 50962,
                "label": "Gaanderen",
                "label_ar": "غاندير",
                "label_fr": "Gaanderen"
            },
            {
                "value": 51652,
                "label": "Halfweg",
                "label_ar": "Halfweg",
                "label_fr": "Halfweg"
            },
            {
                "value": 51619,
                "label": "Hollandscheveld",
                "label_ar": "هولاندشيفيلد",
                "label_fr": "Hollandscheveld"
            },
            {
                "value": 51754,
                "label": "Urk",
                "label_ar": "أورك",
                "label_fr": "Urk"
            },
            {
                "value": 51670,
                "label": "De Klomp",
                "label_ar": "دي كلومب",
                "label_fr": "De Klomp"
            },
            {
                "value": 51245,
                "label": "Odijk",
                "label_ar": "أوديجك",
                "label_fr": "Odijk"
            },
            {
                "value": 51032,
                "label": "Sambeek",
                "label_ar": "سامبيك",
                "label_fr": "Sambeek"
            },
            {
                "value": 51665,
                "label": "Zwaagdijk-Oost",
                "label_ar": "Zwaagdijk-Oost",
                "label_fr": "Zwaagdijk-Oost"
            },
            {
                "value": 50413,
                "label": "Veldhoven",
                "label_ar": "فيلدهوفن",
                "label_fr": "Veldhoven"
            },
            {
                "value": 51239,
                "label": "Bergambacht",
                "label_ar": "بيرجامباخت",
                "label_fr": "Bergambacht"
            },
            {
                "value": 50366,
                "label": "Lelystad",
                "label_ar": "ليليستاد",
                "label_fr": "Lelystad"
            },
            {
                "value": 50753,
                "label": "Buitenpost",
                "label_ar": "Buitenpost",
                "label_fr": "Buitenpost"
            },
            {
                "value": 51570,
                "label": "Wagenberg",
                "label_ar": "واجنبرج",
                "label_fr": "Wagenberg"
            },
            {
                "value": 50716,
                "label": "Sint Annaland",
                "label_ar": "سينت أنالاند",
                "label_fr": "Sint Annaland"
            },
            {
                "value": 50749,
                "label": "Grootebroek",
                "label_ar": "جروتبروك",
                "label_fr": "Grootebroek"
            },
            {
                "value": 50503,
                "label": "Leusden",
                "label_ar": "ليوسدن",
                "label_fr": "Leusden"
            },
            {
                "value": 51407,
                "label": "Sint Pancras",
                "label_ar": "سينت بانكراس",
                "label_fr": "Sint Pancras"
            },
            {
                "value": 51770,
                "label": "Zwaanshoek",
                "label_ar": "زوانشوك",
                "label_fr": "Zwaanshoek"
            },
            {
                "value": 51719,
                "label": "Doenrade",
                "label_ar": "Doenrade",
                "label_fr": "Doenrade"
            },
            {
                "value": 50336,
                "label": "Haarlem",
                "label_ar": "هارلم",
                "label_fr": "Haarlem"
            },
            {
                "value": 50425,
                "label": "Wolfheze",
                "label_ar": "ولفيز",
                "label_fr": "Wolfheze"
            },
            {
                "value": 50594,
                "label": "Leidschendam",
                "label_ar": "لايدسيندام",
                "label_fr": "Leidschendam"
            },
            {
                "value": 51169,
                "label": "Stampersgat",
                "label_ar": "Stampersgat",
                "label_fr": "Stampersgat"
            },
            {
                "value": 51261,
                "label": "Berg",
                "label_ar": "بيرج",
                "label_fr": "Berg"
            },
            {
                "value": 51352,
                "label": "Stevensbeek",
                "label_ar": "ستيفنسبيك",
                "label_fr": "Stevensbeek"
            },
            {
                "value": 51543,
                "label": "Ootmarsum",
                "label_ar": "أوتمارسوم",
                "label_fr": "Ootmarsum"
            },
            {
                "value": 50931,
                "label": "Tolkamer",
                "label_ar": "تولكامر",
                "label_fr": "Tolkamer"
            },
            {
                "value": 50670,
                "label": "Grou",
                "label_ar": "جرو",
                "label_fr": "Grou"
            },
            {
                "value": 51134,
                "label": "Koudum",
                "label_ar": "كودوم",
                "label_fr": "Koudum"
            },
            {
                "value": 51399,
                "label": "Ruurlo",
                "label_ar": "رورلو",
                "label_fr": "Ruurlo"
            },
            {
                "value": 50320,
                "label": "Heerenveen",
                "label_ar": "هيرينفين",
                "label_fr": "Heerenveen"
            },
            {
                "value": 50561,
                "label": "Hummelo",
                "label_ar": "هاميلو",
                "label_fr": "Hummelo"
            },
            {
                "value": 50998,
                "label": "Ter Apel",
                "label_ar": "تير ابيل",
                "label_fr": "Ter Apel"
            },
            {
                "value": 51494,
                "label": "Huijbergen",
                "label_ar": "Huijbergen",
                "label_fr": "Huijbergen"
            },
            {
                "value": 51542,
                "label": "Kaag",
                "label_ar": "كاج",
                "label_fr": "Kaag"
            },
            {
                "value": 50983,
                "label": "Andijk",
                "label_ar": "أنديجك",
                "label_fr": "Andijk"
            },
            {
                "value": 51221,
                "label": "Aardenburg",
                "label_ar": "اردينبورغ",
                "label_fr": "Aardenburg"
            },
            {
                "value": 51442,
                "label": "Tweede Valthermond",
                "label_ar": "تويد فالثيرموند",
                "label_fr": "Tweede Valthermond"
            },
            {
                "value": 50378,
                "label": "Schiedam",
                "label_ar": "شيدام",
                "label_fr": "Schiedam"
            },
            {
                "value": 50720,
                "label": "Noordgouwe",
                "label_ar": "نوردجوي",
                "label_fr": "Noordgouwe"
            },
            {
                "value": 51075,
                "label": "Hartwerd",
                "label_ar": "هارتويرد",
                "label_fr": "Hartwerd"
            },
            {
                "value": 51733,
                "label": "Herbaijum",
                "label_ar": "الأعشاب",
                "label_fr": "Herbaijum"
            },
            {
                "value": 50388,
                "label": "Wellerlooi",
                "label_ar": "ويلرلوي",
                "label_fr": "Wellerlooi"
            },
            {
                "value": 51090,
                "label": "Farmsum",
                "label_ar": "مزرعة",
                "label_fr": "Farmsum"
            },
            {
                "value": 50494,
                "label": "Oudenbosch",
                "label_ar": "أودينبوش",
                "label_fr": "Oudenbosch"
            },
            {
                "value": 51274,
                "label": "Wijk aan Zee",
                "label_ar": "Wijk aan Zee",
                "label_fr": "Wijk aan Zee"
            },
            {
                "value": 51621,
                "label": "Kloosterhaar",
                "label_ar": "كلوسترهار",
                "label_fr": "Kloosterhaar"
            },
            {
                "value": 51699,
                "label": "Sint Hubert",
                "label_ar": "سينت هوبرت",
                "label_fr": "Saint-Hubert"
            },
            {
                "value": 50592,
                "label": "Pijnacker",
                "label_ar": "بيجناكر",
                "label_fr": "Pijnacker"
            },
            {
                "value": 50973,
                "label": "Lexmond",
                "label_ar": "ليكسموند",
                "label_fr": "Lexmond"
            },
            {
                "value": 51385,
                "label": "Nieuw-Balinge",
                "label_ar": "نيو بالينج",
                "label_fr": "Nieuw-Balinge"
            },
            {
                "value": 51391,
                "label": "Tijnje",
                "label_ar": "تيجني",
                "label_fr": "Tijnje"
            },
            {
                "value": 51632,
                "label": "Giethoorn",
                "label_ar": "جيثورن",
                "label_fr": "Giethoorn"
            },
            {
                "value": 51347,
                "label": "Wanroij",
                "label_ar": "وانروج",
                "label_fr": "Wanroij"
            },
            {
                "value": 51310,
                "label": "Helvoirt",
                "label_ar": "هيلفورت",
                "label_fr": "Helvoirt"
            },
            {
                "value": 50773,
                "label": "Rozenburg",
                "label_ar": "روزنبورغ",
                "label_fr": "Rozenburg"
            },
            {
                "value": 50371,
                "label": "Renkum",
                "label_ar": "رينكوم",
                "label_fr": "Renkum"
            },
            {
                "value": 50434,
                "label": "Domburg",
                "label_ar": "دومبورغ",
                "label_fr": "Domburg"
            },
            {
                "value": 51194,
                "label": "Made",
                "label_ar": "مصنوع",
                "label_fr": "Fait"
            },
            {
                "value": 51487,
                "label": "Mortel",
                "label_ar": "مورتل",
                "label_fr": "Mortel"
            },
            {
                "value": 51112,
                "label": "Uithuizermeeden",
                "label_ar": "Uithuizermeeden",
                "label_fr": "Uithuizermeeden"
            },
            {
                "value": 50684,
                "label": "Nieuwendijk",
                "label_ar": "Nieuwendijk",
                "label_fr": "Nieuwendijk"
            },
            {
                "value": 50930,
                "label": "Ameide",
                "label_ar": "أميد",
                "label_fr": "Ameide"
            },
            {
                "value": 50658,
                "label": "Bennekom",
                "label_ar": "بينيكوم",
                "label_fr": "Bennekom"
            },
            {
                "value": 50330,
                "label": "Erp",
                "label_ar": "ERP",
                "label_fr": "Erp"
            },
            {
                "value": 50760,
                "label": "Hoogerheide",
                "label_ar": "هوجرهيد",
                "label_fr": "Hoogerheide"
            },
            {
                "value": 50975,
                "label": "Mookhoek",
                "label_ar": "موكهوك",
                "label_fr": "Mookhoek"
            },
            {
                "value": 50624,
                "label": "Kerkrade",
                "label_ar": "كيركراد",
                "label_fr": "Kerkrade"
            },
            {
                "value": 50700,
                "label": "Laren",
                "label_ar": "لارين",
                "label_fr": "Laren"
            },
            {
                "value": 50360,
                "label": "Zutphen",
                "label_ar": "زوتفين",
                "label_fr": "Zutphen"
            },
            {
                "value": 50812,
                "label": "Haren",
                "label_ar": "هارين",
                "label_fr": "Haren"
            },
            {
                "value": 50793,
                "label": "Espel",
                "label_ar": "إسبيل",
                "label_fr": "Espel"
            },
            {
                "value": 51582,
                "label": "Zoutkamp",
                "label_ar": "Zoutkamp",
                "label_fr": "Zoutkamp"
            },
            {
                "value": 50774,
                "label": "Holthone",
                "label_ar": "هولثون",
                "label_fr": "Holthone"
            },
            {
                "value": 51206,
                "label": "Brouwershaven",
                "label_ar": "برويرشافين",
                "label_fr": "Brouwershaven"
            },
            {
                "value": 51657,
                "label": "Bolsward",
                "label_ar": "بولسوارد",
                "label_fr": "Bolsward"
            },
            {
                "value": 50783,
                "label": "Sint Annaparochie",
                "label_ar": "سينت أناباروتشي",
                "label_fr": "Sint Annaparochie"
            },
            {
                "value": 51346,
                "label": "Vortum-Mullem",
                "label_ar": "فورتم-مولم",
                "label_fr": "Vortum-Mullem"
            },
            {
                "value": 51440,
                "label": "Obdam",
                "label_ar": "Obdam",
                "label_fr": "Obdam"
            },
            {
                "value": 51034,
                "label": "Enschot",
                "label_ar": "انشكوت",
                "label_fr": "Enschot"
            },
            {
                "value": 50925,
                "label": "Budel-Dorplein",
                "label_ar": "بودل دوربلين",
                "label_fr": "Budel-Dorplein"
            },
            {
                "value": 50892,
                "label": "Fijnaart",
                "label_ar": "فيينارت",
                "label_fr": "Fijnaart"
            },
            {
                "value": 50912,
                "label": "Spakenburg",
                "label_ar": "سباكينبورغ",
                "label_fr": "Spakenburg"
            },
            {
                "value": 51061,
                "label": "Maasbree",
                "label_ar": "ماسبري",
                "label_fr": "Maasbree"
            },
            {
                "value": 51103,
                "label": "Eenrum",
                "label_ar": "اينروم",
                "label_fr": "Eenrum"
            },
            {
                "value": 51519,
                "label": "Eckart",
                "label_ar": "إيكارت",
                "label_fr": "Eckart"
            },
            {
                "value": 51554,
                "label": "Vierpolders",
                "label_ar": "Vierpolders",
                "label_fr": "Vierpolders"
            },
            {
                "value": 51301,
                "label": "Eijsden",
                "label_ar": "إيجسدن",
                "label_fr": "Eijsden"
            },
            {
                "value": 51375,
                "label": "Horst",
                "label_ar": "هورست",
                "label_fr": "Horst"
            },
            {
                "value": 50924,
                "label": "Rekken",
                "label_ar": "ريكين",
                "label_fr": "Rekken"
            },
            {
                "value": 51371,
                "label": "Woudrichem",
                "label_ar": "Woudrichem",
                "label_fr": "Woudrichem"
            },
            {
                "value": 51604,
                "label": "Paterswolde",
                "label_ar": "باترسولد",
                "label_fr": "Paterswolde"
            },
            {
                "value": 50309,
                "label": "Beek",
                "label_ar": "بيك",
                "label_fr": "Beek"
            },
            {
                "value": 50539,
                "label": "Lies",
                "label_ar": "الأكاذيب",
                "label_fr": "Mensonges"
            },
            {
                "value": 50820,
                "label": "Roodeschool",
                "label_ar": "روديسكول",
                "label_fr": "Roodeschool"
            },
            {
                "value": 50801,
                "label": "Noord-Scharwoude",
                "label_ar": "نورد شروود",
                "label_fr": "Noord-Scharwoude"
            },
            {
                "value": 51641,
                "label": "Heilig Landstichting",
                "label_ar": "هيليج Landstichting",
                "label_fr": "Heilig Landstichting"
            },
            {
                "value": 51777,
                "label": "Weerselo",
                "label_ar": "ويرسيلو",
                "label_fr": "Weerselo"
            },
            {
                "value": 51640,
                "label": "Lienden",
                "label_ar": "ليندن",
                "label_fr": "Lienden"
            },
            {
                "value": 50608,
                "label": "Lievelde",
                "label_ar": "ليفيلدي",
                "label_fr": "Lievelde"
            },
            {
                "value": 51020,
                "label": "Valkenburg",
                "label_ar": "فالكنبورغ",
                "label_fr": "Valkenburg"
            },
            {
                "value": 51529,
                "label": "Rhenoy",
                "label_ar": "رينوي",
                "label_fr": "Rhenoy"
            },
            {
                "value": 51461,
                "label": "Spanbroek",
                "label_ar": "سبانبروك",
                "label_fr": "Spanbroek"
            },
            {
                "value": 50326,
                "label": "Sevenum",
                "label_ar": "سيفينوم",
                "label_fr": "Sevenum"
            },
            {
                "value": 50487,
                "label": "Rozenburg",
                "label_ar": "روزنبورغ",
                "label_fr": "Rozenburg"
            },
            {
                "value": 50403,
                "label": "Haalderen",
                "label_ar": "هالديرين",
                "label_fr": "Haalderen"
            },
            {
                "value": 51624,
                "label": "Vorstenbosch",
                "label_ar": "فورستنبوش",
                "label_fr": "Vorstenbosch"
            },
            {
                "value": 51742,
                "label": "Midlum",
                "label_ar": "ميدلوم",
                "label_fr": "Midlum"
            },
            {
                "value": 50500,
                "label": "Herten",
                "label_ar": "هيرتن",
                "label_fr": "Herten"
            },
            {
                "value": 50887,
                "label": "Haaften",
                "label_ar": "هافتن",
                "label_fr": "Haaften"
            },
            {
                "value": 51366,
                "label": "Ottersum",
                "label_ar": "أوترسوم",
                "label_fr": "Ottersum"
            },
            {
                "value": 51702,
                "label": "Burgerveen",
                "label_ar": "برجرفين",
                "label_fr": "Burgerveen"
            },
            {
                "value": 51140,
                "label": "Schildwolde",
                "label_ar": "شيلدولد",
                "label_fr": "Schildwolde"
            },
            {
                "value": 51047,
                "label": "Gieten",
                "label_ar": "جيتين",
                "label_fr": "Gieten"
            },
            {
                "value": 51425,
                "label": "Nijkerkerveen",
                "label_ar": "نيجيركيرفين",
                "label_fr": "Nijkerkerveen"
            },
            {
                "value": 51550,
                "label": "Hekelingen",
                "label_ar": "هيكلينجن",
                "label_fr": "Hekelingen"
            },
            {
                "value": 50332,
                "label": "Greup",
                "label_ar": "جراب",
                "label_fr": "Greup"
            },
            {
                "value": 50920,
                "label": "Beesd",
                "label_ar": "بيسد",
                "label_fr": "Beesd"
            },
            {
                "value": 51057,
                "label": "Willemstad",
                "label_ar": "ويلمستاد",
                "label_fr": "Willemstad"
            },
            {
                "value": 51601,
                "label": "Tolbert",
                "label_ar": "تولبرت",
                "label_fr": "Tolbert"
            },
            {
                "value": 51230,
                "label": "Galder",
                "label_ar": "جالدر",
                "label_fr": "Galder"
            },
            {
                "value": 51404,
                "label": "Ulicoten",
                "label_ar": "يوليكوتين",
                "label_fr": "Ulicoten"
            },
            {
                "value": 50529,
                "label": "Wehl",
                "label_ar": "ويل",
                "label_fr": "Wehl"
            },
            {
                "value": 50473,
                "label": "Zeist",
                "label_ar": "زيست",
                "label_fr": "Zeist"
            },
            {
                "value": 51197,
                "label": "Kerkenveld",
                "label_ar": "كيركينفيلد",
                "label_fr": "Kerkenveld"
            },
            {
                "value": 51263,
                "label": "Zuidhorn",
                "label_ar": "زويدورن",
                "label_fr": "Zuidhorn"
            },
            {
                "value": 51749,
                "label": "Slootdorp",
                "label_ar": "سلوتدورب",
                "label_fr": "Slootdorp"
            },
            {
                "value": 50476,
                "label": "Norg",
                "label_ar": "Norg",
                "label_fr": "Norg"
            },
            {
                "value": 51744,
                "label": "Oldeholtpade",
                "label_ar": "أولدهولتباد",
                "label_fr": "Oldeholtpade"
            },
            {
                "value": 51694,
                "label": "Burum",
                "label_ar": "بوروم",
                "label_fr": "Burum"
            },
            {
                "value": 50796,
                "label": "Hedel",
                "label_ar": "هديل",
                "label_fr": "Hedel"
            },
            {
                "value": 51203,
                "label": "Schoondijke",
                "label_ar": "Schoondijke",
                "label_fr": "Schoondijke"
            },
            {
                "value": 50960,
                "label": "Marrum",
                "label_ar": "ماروم",
                "label_fr": "Marrum"
            },
            {
                "value": 50999,
                "label": "Eemnes",
                "label_ar": "إيمنيس",
                "label_fr": "Eemnes"
            },
            {
                "value": 51160,
                "label": "Wilp",
                "label_ar": "ويلب",
                "label_fr": "Wilp"
            },
            {
                "value": 51517,
                "label": "IJsselmuiden",
                "label_ar": "IJsselmuiden",
                "label_fr": "IJsselmuiden"
            },
            {
                "value": 50570,
                "label": "Wateringen",
                "label_ar": "واترينجين",
                "label_fr": "Wateringen"
            },
            {
                "value": 51177,
                "label": "Geertruidenberg",
                "label_ar": "غيرترودينبيرج",
                "label_fr": "Geertruidenberg"
            },
            {
                "value": 51515,
                "label": "Nieuwleusen",
                "label_ar": "نيولوسن",
                "label_fr": "Nieuwleusen"
            },
            {
                "value": 51589,
                "label": "Briltil",
                "label_ar": "بريلتيل",
                "label_fr": "Briltil"
            },
            {
                "value": 51262,
                "label": "Achterveld",
                "label_ar": "أشترفيلد",
                "label_fr": "Achterveld"
            },
            {
                "value": 51459,
                "label": "Hoogwoud",
                "label_ar": "Hoogwoud",
                "label_fr": "Hoogwoud"
            },
            {
                "value": 50400,
                "label": "Megchelen",
                "label_ar": "ميشيلين",
                "label_fr": "Megchelen"
            },
            {
                "value": 50614,
                "label": "Heerlen",
                "label_ar": "هيرلين",
                "label_fr": "Heerlen"
            },
            {
                "value": 50759,
                "label": "Ossendrecht",
                "label_ar": "أوسيندريخت",
                "label_fr": "Ossendrecht"
            },
            {
                "value": 51083,
                "label": "Oene",
                "label_ar": "أويني",
                "label_fr": "Oene"
            },
            {
                "value": 50692,
                "label": "Moergestel",
                "label_ar": "مويرجيستل",
                "label_fr": "Moergestel"
            },
            {
                "value": 50980,
                "label": "Haaksbergen",
                "label_ar": "هاكسبيرجين",
                "label_fr": "Haaksbergen"
            },
            {
                "value": 50909,
                "label": "Schalkhaar",
                "label_ar": "شلكار",
                "label_fr": "Schalkhaar"
            },
            {
                "value": 51048,
                "label": "Schipborg",
                "label_ar": "شيبورج",
                "label_fr": "Schipborg"
            },
            {
                "value": 51064,
                "label": "Eext",
                "label_ar": "Eext",
                "label_fr": "Eext"
            },
            {
                "value": 50466,
                "label": "Waalwijk",
                "label_ar": "والويجك",
                "label_fr": "Waalwijk"
            },
            {
                "value": 50976,
                "label": "Broek op Langedijk",
                "label_ar": "Broek op Langedijk",
                "label_fr": "Broek op Langedijk"
            },
            {
                "value": 51455,
                "label": "Medemblik",
                "label_ar": "ميديمبليك",
                "label_fr": "Medemblik"
            },
            {
                "value": 50394,
                "label": "De Cocksdorp",
                "label_ar": "دي كوكسدورب",
                "label_fr": "De Cocksdorp"
            },
            {
                "value": 50851,
                "label": "Nederhorst den Berg",
                "label_ar": "نديرهورست دن بيرج",
                "label_fr": "Nederhorst den Berg"
            },
            {
                "value": 51337,
                "label": "Blerick",
                "label_ar": "بليريك",
                "label_fr": "Blerick"
            },
            {
                "value": 51603,
                "label": "Noordlaren",
                "label_ar": "نوردلارين",
                "label_fr": "Noordlaren"
            },
            {
                "value": 51610,
                "label": "Noord-Sleen",
                "label_ar": "نورد سلين",
                "label_fr": "Noord-Sleen"
            },
            {
                "value": 50935,
                "label": "Reusel",
                "label_ar": "Reusel",
                "label_fr": "Reusel"
            },
            {
                "value": 50830,
                "label": "Rosmalen",
                "label_ar": "روزمالين",
                "label_fr": "Rosmalen"
            },
            {
                "value": 50940,
                "label": "Varik",
                "label_ar": "فاريك",
                "label_fr": "Varik"
            },
            {
                "value": 51187,
                "label": "Oudehaske",
                "label_ar": "Oudehaske",
                "label_fr": "Oudehaske"
            },
            {
                "value": 51596,
                "label": "Glimmen",
                "label_ar": "جليمين",
                "label_fr": "Glimmen"
            },
            {
                "value": 50757,
                "label": "Joure",
                "label_ar": "جوري",
                "label_fr": "Joure"
            },
            {
                "value": 50461,
                "label": "Dokkum",
                "label_ar": "دقم",
                "label_fr": "Dokkum"
            },
            {
                "value": 51538,
                "label": "Princenhof",
                "label_ar": "برينسينهوف",
                "label_fr": "Princenhof"
            },
            {
                "value": 51531,
                "label": "Wildervank",
                "label_ar": "ويلدرفانك",
                "label_fr": "Wildervank"
            },
            {
                "value": 50401,
                "label": "Netterden",
                "label_ar": "نيتيردن",
                "label_fr": "Netterden"
            },
            {
                "value": 50404,
                "label": "Mildam",
                "label_ar": "ميلدام",
                "label_fr": "Mildam"
            },
            {
                "value": 51027,
                "label": "Walterswald",
                "label_ar": "والترسوالد",
                "label_fr": "Walterswald"
            },
            {
                "value": 51225,
                "label": "Genderen",
                "label_ar": "بين الجنسين",
                "label_fr": "Genre"
            },
            {
                "value": 51195,
                "label": "Guttecoven",
                "label_ar": "جوتكوفن",
                "label_fr": "Guttecoven"
            },
            {
                "value": 51504,
                "label": "Brucht",
                "label_ar": "بروخت",
                "label_fr": "Brucht"
            },
            {
                "value": 50897,
                "label": "Beek",
                "label_ar": "بيك",
                "label_fr": "Beek"
            },
            {
                "value": 51128,
                "label": "Wommels",
                "label_ar": "وميلز",
                "label_fr": "Wommels"
            },
            {
                "value": 51682,
                "label": "Holwierde",
                "label_ar": "هولويردي",
                "label_fr": "Holwierde"
            },
            {
                "value": 50450,
                "label": "Nieuwkoop",
                "label_ar": "نيوكوب",
                "label_fr": "Nieuwkoop"
            },
            {
                "value": 51634,
                "label": "Ruinerwold",
                "label_ar": "روينروولد",
                "label_fr": "Ruinerwold"
            },
            {
                "value": 51426,
                "label": "Ter Apelkanaal",
                "label_ar": "تير ابلكانال",
                "label_fr": "Ter Apelkanaal"
            },
            {
                "value": 51708,
                "label": "Oude Wetering",
                "label_ar": "عود ويترينغ",
                "label_fr": "Oude Wetering"
            },
            {
                "value": 51276,
                "label": "Rinsumageast",
                "label_ar": "رينسوماجيست",
                "label_fr": "Rinsumageast"
            },
            {
                "value": 51124,
                "label": "IJlst",
                "label_ar": "IJlst",
                "label_fr": "IJlst"
            },
            {
                "value": 51287,
                "label": "Heeswijk",
                "label_ar": "هيسويجك",
                "label_fr": "Heeswijk"
            },
            {
                "value": 51606,
                "label": "Diepenveen",
                "label_ar": "ديبينفين",
                "label_fr": "Diepenveen"
            },
            {
                "value": 50990,
                "label": "Balk",
                "label_ar": "يعوق. يحبط",
                "label_fr": "Balk"
            },
            {
                "value": 51639,
                "label": "Ooltgensplaat",
                "label_ar": "Ooltgensplaat",
                "label_fr": "Ooltgensplaat"
            },
            {
                "value": 51244,
                "label": "Driebergen",
                "label_ar": "دريبيرجين",
                "label_fr": "Driebergen"
            },
            {
                "value": 51155,
                "label": "Kamperland",
                "label_ar": "كامبرلاند",
                "label_fr": "Kamperland"
            },
            {
                "value": 51470,
                "label": "Heveadorp",
                "label_ar": "هيفيدورب",
                "label_fr": "Heveadorp"
            },
            {
                "value": 51774,
                "label": "Wieringerwaard",
                "label_ar": "Wieringerwaard",
                "label_fr": "Wieringerwaard"
            },
            {
                "value": 50769,
                "label": "Lage Mierde",
                "label_ar": "لاج ميردي",
                "label_fr": "Lage Mierde"
            },
            {
                "value": 51299,
                "label": "Eygelshoven",
                "label_ar": "Eygelshoven",
                "label_fr": "Eygelshoven"
            },
            {
                "value": 50787,
                "label": "Eelderwolde",
                "label_ar": "Eelderwolde",
                "label_fr": "Eelderwolde"
            },
            {
                "value": 50944,
                "label": "Molenhoek",
                "label_ar": "مولينهوك",
                "label_fr": "Molenhoek"
            },
            {
                "value": 51219,
                "label": "Wolphaartsdijk",
                "label_ar": "Wolphaartsdijk",
                "label_fr": "Wolphaartsdijk"
            },
            {
                "value": 50676,
                "label": "Kekerdom",
                "label_ar": "كيكردوم",
                "label_fr": "Kekerdom"
            },
            {
                "value": 50807,
                "label": "Damwald",
                "label_ar": "داموالد",
                "label_fr": "Damwald"
            },
            {
                "value": 51257,
                "label": "Noordwijk aan Zee",
                "label_ar": "Noordwijk aan Zee",
                "label_fr": "Noordwijk aan Zee"
            },
            {
                "value": 51765,
                "label": "Nieuw-Dordrecht",
                "label_ar": "نيو دوردريخت",
                "label_fr": "Nieuw-Dordrecht"
            },
            {
                "value": 50308,
                "label": "Helmond",
                "label_ar": "هيلموند",
                "label_fr": "Helmond"
            },
            {
                "value": 50565,
                "label": "Wolvega",
                "label_ar": "وولفيجا",
                "label_fr": "Wolvega"
            },
            {
                "value": 50864,
                "label": "Zuidland",
                "label_ar": "زويدلاند",
                "label_fr": "Zuidland"
            },
            {
                "value": 51523,
                "label": "Elst",
                "label_ar": "إلست",
                "label_fr": "Elst"
            },
            {
                "value": 51710,
                "label": "Nieuwehorne",
                "label_ar": "نيوويهورن",
                "label_fr": "Nieuwehorne"
            },
            {
                "value": 50501,
                "label": "Bilthoven",
                "label_ar": "بيلتهوفن",
                "label_fr": "Bilthoven"
            },
            {
                "value": 50672,
                "label": "Akkrum",
                "label_ar": "Akkrum",
                "label_fr": "Akkrum"
            },
            {
                "value": 50754,
                "label": "Montfoort",
                "label_ar": "مونتفورت",
                "label_fr": "Montfoort"
            },
            {
                "value": 51269,
                "label": "'s-Heer-Hendrikskinderen",
                "label_ar": "'s-Heer-Hendrikskinderen",
                "label_fr": "'s-Heer-Hendrikskinderen"
            },
            {
                "value": 50479,
                "label": "Scherpenzeel",
                "label_ar": "شيربنزيل",
                "label_fr": "Scherpenzeel"
            },
            {
                "value": 51530,
                "label": "Lijnden",
                "label_ar": "ليندن",
                "label_fr": "Lijnden"
            },
            {
                "value": 51175,
                "label": "Appeltern",
                "label_ar": "أبيلترن",
                "label_fr": "Appeltern"
            },
            {
                "value": 51295,
                "label": "Klimmen",
                "label_ar": "كليمين",
                "label_fr": "Klimmen"
            },
            {
                "value": 51330,
                "label": "Roosteren",
                "label_ar": "روستيرن",
                "label_fr": "Coq"
            },
            {
                "value": 50397,
                "label": "Leek",
                "label_ar": "شخص عادي",
                "label_fr": "Poireau"
            },
            {
                "value": 50618,
                "label": "Rodenrijs",
                "label_ar": "رودنجس",
                "label_fr": "Rodenrijs"
            },
            {
                "value": 51102,
                "label": "Bedum",
                "label_ar": "بيدوم",
                "label_fr": "Bedum"
            },
            {
                "value": 51078,
                "label": "Moerkapelle",
                "label_ar": "Moerkapelle",
                "label_fr": "Moerkapelle"
            },
            {
                "value": 51387,
                "label": "Vuren",
                "label_ar": "فورين",
                "label_fr": "Vuren"
            },
            {
                "value": 50826,
                "label": "Loozen",
                "label_ar": "لوزين",
                "label_fr": "Loozen"
            },
            {
                "value": 50399,
                "label": "Terborg",
                "label_ar": "تيربورغ",
                "label_fr": "Terborg"
            },
            {
                "value": 50776,
                "label": "Hoogeveen",
                "label_ar": "هوغفين",
                "label_fr": "Hoogeveen"
            },
            {
                "value": 51231,
                "label": "Prinsenbeek",
                "label_ar": "برينسنبيك",
                "label_fr": "Prinsenbeek"
            },
            {
                "value": 50932,
                "label": "Earnewald",
                "label_ar": "إيرنيوالد",
                "label_fr": "Earnewald"
            },
            {
                "value": 51016,
                "label": "Hillegom",
                "label_ar": "هيليجوم",
                "label_fr": "Hillegom"
            },
            {
                "value": 51216,
                "label": "Heikant",
                "label_ar": "هيكانت",
                "label_fr": "Heikant"
            },
            {
                "value": 51014,
                "label": "Broekhin",
                "label_ar": "بروخين",
                "label_fr": "Broekhin"
            },
            {
                "value": 51626,
                "label": "Kolham",
                "label_ar": "كولهام",
                "label_fr": "Kolham"
            },
            {
                "value": 51028,
                "label": "Anjum",
                "label_ar": "أنجوم",
                "label_fr": "Anjum"
            },
            {
                "value": 50939,
                "label": "Hoogeloon",
                "label_ar": "Hoogeloon",
                "label_fr": "Hoogeloon"
            },
            {
                "value": 50936,
                "label": "Dronryp",
                "label_ar": "درونريب",
                "label_fr": "Dronryp"
            },
            {
                "value": 51493,
                "label": "Homoet",
                "label_ar": "هومويت",
                "label_fr": "Homoet"
            },
            {
                "value": 50928,
                "label": "Riethoven",
                "label_ar": "ريتوفن",
                "label_fr": "Riethoven"
            },
            {
                "value": 51110,
                "label": "Albergen",
                "label_ar": "ألبيرجن",
                "label_fr": "Albergen"
            },
            {
                "value": 51363,
                "label": "Wanssum",
                "label_ar": "وانسوم",
                "label_fr": "Wanssum"
            },
            {
                "value": 51392,
                "label": "Ursem",
                "label_ar": "أورسم",
                "label_fr": "Ursem"
            },
            {
                "value": 51587,
                "label": "Kropswolde",
                "label_ar": "كروبسوولد",
                "label_fr": "Kropswolde"
            },
            {
                "value": 51544,
                "label": "Groot-Agelo",
                "label_ar": "جروت أجيلو",
                "label_fr": "Groot-Agelo"
            },
            {
                "value": 51750,
                "label": "Kolhorn",
                "label_ar": "كولهورن",
                "label_fr": "Kolhorn"
            },
            {
                "value": 50345,
                "label": "Westervoort",
                "label_ar": "ويستيرفورت",
                "label_fr": "Westervoort"
            },
            {
                "value": 50560,
                "label": "Assen",
                "label_ar": "أسن",
                "label_fr": "Assen"
            },
            {
                "value": 50527,
                "label": "Beekbergen",
                "label_ar": "بيكبيرجين",
                "label_fr": "Beekbergen"
            },
            {
                "value": 50593,
                "label": "Rhoon",
                "label_ar": "رون",
                "label_fr": "Rhoon"
            },
            {
                "value": 50792,
                "label": "Uden",
                "label_ar": "أودن",
                "label_fr": "Uden"
            },
            {
                "value": 50859,
                "label": "Diepenheim",
                "label_ar": "ديبنهايم",
                "label_fr": "Diepenheim"
            },
            {
                "value": 50532,
                "label": "Bladel",
                "label_ar": "بلاديل",
                "label_fr": "Bladel"
            },
            {
                "value": 51111,
                "label": "Wierden",
                "label_ar": "فيردن",
                "label_fr": "Wierden"
            },
            {
                "value": 51655,
                "label": "Hulshorst",
                "label_ar": "هولشورست",
                "label_fr": "Hulshorst"
            },
            {
                "value": 51117,
                "label": "Vroomshoop",
                "label_ar": "فرومشوب",
                "label_fr": "Vroomshoop"
            },
            {
                "value": 51572,
                "label": "Amerongen",
                "label_ar": "أميرونجين",
                "label_fr": "Amerongen"
            },
            {
                "value": 50423,
                "label": "Wijchen",
                "label_ar": "Wijchen",
                "label_fr": "Wijchen"
            },
            {
                "value": 50572,
                "label": "Hendrik-Ido-Ambacht",
                "label_ar": "هندريك إيدو أمباخت",
                "label_fr": "Hendrik-Ido-Ambacht"
            },
            {
                "value": 50832,
                "label": "Gorssel",
                "label_ar": "جورسيل",
                "label_fr": "Gorssel"
            },
            {
                "value": 50784,
                "label": "Kesteren",
                "label_ar": "كيستيرن",
                "label_fr": "Kesteren"
            },
            {
                "value": 51215,
                "label": "Sint Jansteen",
                "label_ar": "سينت جانستين",
                "label_fr": "Sint Jansteen"
            },
            {
                "value": 51334,
                "label": "Grashoek",
                "label_ar": "جراشوك",
                "label_fr": "Grashoek"
            },
            {
                "value": 50439,
                "label": "Bergen",
                "label_ar": "بيرغن",
                "label_fr": "Bergen"
            },
            {
                "value": 51157,
                "label": "Rossum",
                "label_ar": "روسوم",
                "label_fr": "Rossum"
            },
            {
                "value": 51068,
                "label": "Heteren",
                "label_ar": "هيترين",
                "label_fr": "Heteren"
            },
            {
                "value": 51349,
                "label": "Schaijk",
                "label_ar": "شايك",
                "label_fr": "Schaijk"
            },
            {
                "value": 51433,
                "label": "Nibbixwoud",
                "label_ar": "نيبكسود",
                "label_fr": "Nibbixwoud"
            },
            {
                "value": 50504,
                "label": "Veenendaal",
                "label_ar": "فينندال",
                "label_fr": "Veenendaal"
            },
            {
                "value": 51309,
                "label": "Nuland",
                "label_ar": "نولاند",
                "label_fr": "Nuland"
            },
            {
                "value": 51630,
                "label": "Havelte",
                "label_ar": "هافيلتي",
                "label_fr": "Havelte"
            },
            {
                "value": 50610,
                "label": "Rijswijk",
                "label_ar": "Rijswijk",
                "label_fr": "Rijswijk"
            },
            {
                "value": 50637,
                "label": "Surhuisterveen",
                "label_ar": "Surhuisterveen",
                "label_fr": "Surhuisterveen"
            },
            {
                "value": 51253,
                "label": "Voorhout",
                "label_ar": "فورهوت",
                "label_fr": "Voorhout"
            },
            {
                "value": 51580,
                "label": "Breezand",
                "label_ar": "بريزند",
                "label_fr": "Breezand"
            },
            {
                "value": 51598,
                "label": "Harkstede",
                "label_ar": "هاركستيد",
                "label_fr": "Harkstede"
            },
            {
                "value": 50982,
                "label": "Holten",
                "label_ar": "هولتن",
                "label_fr": "Holten"
            },
            {
                "value": 51052,
                "label": "Amstenrade",
                "label_ar": "أمستينراد",
                "label_fr": "Amstenrade"
            },
            {
                "value": 51573,
                "label": "Breukeleveen",
                "label_ar": "بروكيليفين",
                "label_fr": "Breukeleveen"
            },
            {
                "value": 51740,
                "label": "West-Terschelling",
                "label_ar": "ويست تيرشيلينج",
                "label_fr": "West-Terschelling"
            },
            {
                "value": 50705,
                "label": "Westdorpe",
                "label_ar": "ويستدورب",
                "label_fr": "Westdorpe"
            },
            {
                "value": 50891,
                "label": "Gemeente Rotterdam",
                "label_ar": "Gemeente روتردام",
                "label_fr": "Gemeente Rotterdam"
            },
            {
                "value": 50916,
                "label": "Boskoop",
                "label_ar": "بوسكوب",
                "label_fr": "Boskoop"
            },
            {
                "value": 51380,
                "label": "Beinsdorp",
                "label_ar": "بينسدورب",
                "label_fr": "Beinsdorp"
            },
            {
                "value": 50556,
                "label": "Overloon",
                "label_ar": "تفرط",
                "label_fr": "Overloon"
            },
            {
                "value": 50586,
                "label": "Culemborg",
                "label_ar": "كوليمبورغ",
                "label_fr": "Culemborg"
            },
            {
                "value": 51017,
                "label": "Hierden",
                "label_ar": "هيردن",
                "label_fr": "Hierden"
            },
            {
                "value": 51250,
                "label": "Zijderveld",
                "label_ar": "زيديرفيلد",
                "label_fr": "Zijderveld"
            },
            {
                "value": 51656,
                "label": "Zaanstad",
                "label_ar": "زانستاد",
                "label_fr": "Zaanstad"
            },
            {
                "value": 50715,
                "label": "Westkapelle",
                "label_ar": "ويستكابيل",
                "label_fr": "Westkapelle"
            },
            {
                "value": 50605,
                "label": "Weert",
                "label_ar": "ويرت",
                "label_fr": "Weert"
            },
            {
                "value": 50550,
                "label": "Wognum",
                "label_ar": "ووغنوم",
                "label_fr": "Wognum"
            },
            {
                "value": 51739,
                "label": "Rottum",
                "label_ar": "روتوم",
                "label_fr": "Rottum"
            },
            {
                "value": 50789,
                "label": "Gemonde",
                "label_ar": "جيموند",
                "label_fr": "Gemonde"
            },
            {
                "value": 50509,
                "label": "Soesterberg",
                "label_ar": "Soesterberg",
                "label_fr": "Soesterberg"
            },
            {
                "value": 50334,
                "label": "Burgum",
                "label_ar": "بورغوم",
                "label_fr": "Burgum"
            },
            {
                "value": 51080,
                "label": "Hernen",
                "label_ar": "هيرنين",
                "label_fr": "Hernen"
            },
            {
                "value": 51291,
                "label": "Schinveld",
                "label_ar": "شينفيلد",
                "label_fr": "Schinveld"
            },
            {
                "value": 50952,
                "label": "Klaaswaal",
                "label_ar": "كلاسوال",
                "label_fr": "Klaaswaal"
            },
            {
                "value": 51292,
                "label": "Obbicht",
                "label_ar": "Obbicht",
                "label_fr": "Obbicht"
            },
            {
                "value": 51405,
                "label": "Ulestraten",
                "label_ar": "Ulestraten",
                "label_fr": "Ulestraten"
            },
            {
                "value": 50627,
                "label": "Hedel",
                "label_ar": "هديل",
                "label_fr": "Hedel"
            },
            {
                "value": 51264,
                "label": "Overveen",
                "label_ar": "يفرط",
                "label_fr": "Overveen"
            },
            {
                "value": 51010,
                "label": "Katwijk",
                "label_ar": "كاتفيك",
                "label_fr": "Katwijk"
            },
            {
                "value": 50841,
                "label": "Zoeterwoude",
                "label_ar": "Zoeterwoude",
                "label_fr": "Zoeterwoude"
            },
            {
                "value": 50886,
                "label": "Meerssen",
                "label_ar": "ميرسن",
                "label_fr": "Meerssen"
            },
            {
                "value": 51449,
                "label": "Westbeemster",
                "label_ar": "ويستبيمستر",
                "label_fr": "Westbeemster"
            },
            {
                "value": 51557,
                "label": "Babberich",
                "label_ar": "بابريش",
                "label_fr": "Babberich"
            },
            {
                "value": 51704,
                "label": "Wijngaarden",
                "label_ar": "ويجنجاردين",
                "label_fr": "Wijngaarden"
            },
            {
                "value": 50714,
                "label": "Koudekerke",
                "label_ar": "كودكيركي",
                "label_fr": "Koudekerke"
            },
            {
                "value": 50687,
                "label": "Boven-Hardinxveld",
                "label_ar": "بوفن هاردينكسفيلد",
                "label_fr": "Boven-Hardinxveld"
            },
            {
                "value": 50758,
                "label": "Boesingheliede",
                "label_ar": "Boesingheliede",
                "label_fr": "Boesingheliede"
            },
            {
                "value": 50364,
                "label": "Vaassen",
                "label_ar": "فاسين",
                "label_fr": "Vaassen"
            },
            {
                "value": 51097,
                "label": "Zuidbroek",
                "label_ar": "زويدبروك",
                "label_fr": "Zuidbroek"
            },
            {
                "value": 51705,
                "label": "Nieuw-Namen",
                "label_ar": "نيو نامين",
                "label_fr": "Nieuw-Namen"
            },
            {
                "value": 51377,
                "label": "Diessen",
                "label_ar": "ديسن",
                "label_fr": "Diessen"
            },
            {
                "value": 50766,
                "label": "Tweede Exloermond",
                "label_ar": "تويد Exloermond",
                "label_fr": "Tweede Exloermond"
            },
            {
                "value": 51506,
                "label": "Vreeland",
                "label_ar": "فريلاند",
                "label_fr": "Vreeland"
            },
            {
                "value": 50942,
                "label": "Drogeham",
                "label_ar": "دروجهام",
                "label_fr": "Drogeham"
            },
            {
                "value": 50647,
                "label": "Biddinghuizen",
                "label_ar": "Biddinghuizen",
                "label_fr": "Biddinghuizen"
            },
            {
                "value": 51482,
                "label": "Surhuizum",
                "label_ar": "سورهويزوم",
                "label_fr": "Surhuizum"
            },
            {
                "value": 51374,
                "label": "Tienraij",
                "label_ar": "تينراج",
                "label_fr": "Tienraij"
            },
            {
                "value": 50646,
                "label": "Brummen",
                "label_ar": "برومين",
                "label_fr": "Brummen"
            },
            {
                "value": 50798,
                "label": "Hollandsche Rading",
                "label_ar": "هولاندشه رادينغ",
                "label_fr": "Hollandsche Rading"
            },
            {
                "value": 51294,
                "label": "Voerendaal",
                "label_ar": "Voerendaal",
                "label_fr": "Voerendaal"
            },
            {
                "value": 51242,
                "label": "Neer-Andel",
                "label_ar": "نير أنديل",
                "label_fr": "Neer-Andel"
            },
            {
                "value": 51338,
                "label": "Baarlo",
                "label_ar": "بارلو",
                "label_fr": "Baarlo"
            },
            {
                "value": 51532,
                "label": "Vledder",
                "label_ar": "Vledder",
                "label_fr": "Vledder"
            },
            {
                "value": 50628,
                "label": "Aagtekerke",
                "label_ar": "Aagtekerke",
                "label_fr": "Aagtekerke"
            },
            {
                "value": 50392,
                "label": "Loenen",
                "label_ar": "Loenen",
                "label_fr": "Loenen"
            },
            {
                "value": 51088,
                "label": "Gronsveld",
                "label_ar": "جرونسفيلد",
                "label_fr": "Gronsveld"
            },
            {
                "value": 51015,
                "label": "Oranjewoud",
                "label_ar": "أورانجود",
                "label_fr": "Oranjewoud"
            },
            {
                "value": 51729,
                "label": "Hantumhuizen",
                "label_ar": "هانتومهويزين",
                "label_fr": "Hantumhuizen"
            },
            {
                "value": 50956,
                "label": "Sommelsdijk",
                "label_ar": "سوميلسديك",
                "label_fr": "Sommelsdijk"
            },
            {
                "value": 50636,
                "label": "Harkema",
                "label_ar": "هاركيما",
                "label_fr": "Harkema"
            },
            {
                "value": 51152,
                "label": "Westerhaar-Vriezenveensewijk",
                "label_ar": "Westerhaar-Vriezenveensewijk",
                "label_fr": "Westerhaar-Vriezenveensewijk"
            },
            {
                "value": 51721,
                "label": "Middelbeers",
                "label_ar": "ميدلبيرز",
                "label_fr": "Middelbeers"
            },
            {
                "value": 50318,
                "label": "Son en Breugel",
                "label_ar": "Son en Breugel",
                "label_fr": "Son en Breugel"
            },
            {
                "value": 50375,
                "label": "Koog aan de Zaan",
                "label_ar": "كوج آن دي زان",
                "label_fr": "Koog aan de Zaan"
            },
            {
                "value": 51074,
                "label": "Groet",
                "label_ar": "جروت",
                "label_fr": "Groet"
            },
            {
                "value": 50626,
                "label": "Retranchement",
                "label_ar": "القفزة",
                "label_fr": "Retranchement"
            },
            {
                "value": 50432,
                "label": "Hellendoorn",
                "label_ar": "هيليندورن",
                "label_fr": "Hellendoorn"
            },
            {
                "value": 50467,
                "label": "Hapert",
                "label_ar": "هابيرت",
                "label_fr": "Hapert"
            },
            {
                "value": 51240,
                "label": "Hank",
                "label_ar": "هانك",
                "label_fr": "Hank"
            },
            {
                "value": 51308,
                "label": "Heerle",
                "label_ar": "هيرل",
                "label_fr": "Heerle"
            },
            {
                "value": 50582,
                "label": "Doorn",
                "label_ar": "الباب",
                "label_fr": "Doorn"
            },
            {
                "value": 51384,
                "label": "Tjerkwerd",
                "label_ar": "تجركفيرد",
                "label_fr": "Tjerkwerd"
            },
            {
                "value": 50741,
                "label": "Anloo",
                "label_ar": "أنلو",
                "label_fr": "Anloo"
            },
            {
                "value": 51091,
                "label": "Appingedam",
                "label_ar": "أبينجدام",
                "label_fr": "Appingedam"
            },
            {
                "value": 50968,
                "label": "Plasmolen",
                "label_ar": "بلازمولين",
                "label_fr": "Plasmolen"
            },
            {
                "value": 50719,
                "label": "Scherpenisse",
                "label_ar": "شيربينيس",
                "label_fr": "Scherpenisse"
            },
            {
                "value": 50927,
                "label": "Heelsum",
                "label_ar": "كعب",
                "label_fr": "Heelsum"
            },
            {
                "value": 50882,
                "label": "Westmaas",
                "label_ar": "ويستماس",
                "label_fr": "Westmaas"
            },
            {
                "value": 50815,
                "label": "Heijningen",
                "label_ar": "هيجنينجن",
                "label_fr": "Heijningen"
            },
            {
                "value": 51466,
                "label": "Gelselaar",
                "label_ar": "جيلسيلار",
                "label_fr": "Gelselaar"
            },
            {
                "value": 51555,
                "label": "Pannerden",
                "label_ar": "بانيردين",
                "label_fr": "Pannerden"
            },
            {
                "value": 50764,
                "label": "Oijen",
                "label_ar": "أوين",
                "label_fr": "Oijen"
            },
            {
                "value": 50899,
                "label": "Nijverdal",
                "label_ar": "نيجفيردال",
                "label_fr": "Nijverdal"
            },
            {
                "value": 50950,
                "label": "Goudswaard",
                "label_ar": "جودسوارد",
                "label_fr": "Goudswaard"
            },
            {
                "value": 51707,
                "label": "Rijpwetering",
                "label_ar": "ريجبويتيرينغ",
                "label_fr": "Rijpwetering"
            },
            {
                "value": 50819,
                "label": "Nieuwe Pekela",
                "label_ar": "نيوي بيكيلا",
                "label_fr": "Nieuwe Pekela"
            },
            {
                "value": 51038,
                "label": "Maurik",
                "label_ar": "موريك",
                "label_fr": "Maurik"
            },
            {
                "value": 51247,
                "label": "Tull",
                "label_ar": "تال",
                "label_fr": "Tull"
            },
            {
                "value": 51401,
                "label": "Mastenbroek",
                "label_ar": "ماستنبروك",
                "label_fr": "Mastenbroek"
            },
            {
                "value": 51767,
                "label": "Rijsenhout",
                "label_ar": "ريسنهوت",
                "label_fr": "Rijsenhout"
            },
            {
                "value": 50690,
                "label": "Steenbergen",
                "label_ar": "ستينبيرجين",
                "label_fr": "Steenbergen"
            },
            {
                "value": 50791,
                "label": "Overdinkel",
                "label_ar": "أوفردينكل",
                "label_fr": "Overdinkel"
            },
            {
                "value": 51082,
                "label": "Bredevoort",
                "label_ar": "بريدفور",
                "label_fr": "Bredevoort"
            },
            {
                "value": 50744,
                "label": "Pieterburen",
                "label_ar": "بيتربورين",
                "label_fr": "Pieterburen"
            },
            {
                "value": 51464,
                "label": "Nieuw-Beijerland",
                "label_ar": "نيو بايرلاند",
                "label_fr": "Nieuw-Beijerland"
            },
            {
                "value": 50660,
                "label": "Voorst",
                "label_ar": "فورست",
                "label_fr": "Voorst"
            },
            {
                "value": 51518,
                "label": "Uitdam",
                "label_ar": "أويتدام",
                "label_fr": "Uitdam"
            },
            {
                "value": 51623,
                "label": "Sibculo",
                "label_ar": "سيبكولو",
                "label_fr": "Sibculo"
            },
            {
                "value": 51552,
                "label": "Zwartewaal",
                "label_ar": "Zwartewaal",
                "label_fr": "Zwartewaal"
            },
            {
                "value": 50625,
                "label": "Annen",
                "label_ar": "أنين",
                "label_fr": "Annen"
            },
            {
                "value": 50588,
                "label": "Honselersdijk",
                "label_ar": "Honselersdijk",
                "label_fr": "Honselersdijk"
            },
            {
                "value": 50448,
                "label": "Winschoten",
                "label_ar": "Winschoten",
                "label_fr": "Winschoten"
            },
            {
                "value": 50587,
                "label": "Maasland",
                "label_ar": "ماسلاند",
                "label_fr": "Maasland"
            },
            {
                "value": 50679,
                "label": "Erichem",
                "label_ar": "اريشم",
                "label_fr": "Erichem"
            },
            {
                "value": 51107,
                "label": "Zuidlaren",
                "label_ar": "زويدلارين",
                "label_fr": "Zuidlaren"
            },
            {
                "value": 51696,
                "label": "Beetsterzwaag",
                "label_ar": "Beetsterzwaag",
                "label_fr": "Beetsterzwaag"
            },
            {
                "value": 51643,
                "label": "Stroe",
                "label_ar": "سترو",
                "label_fr": "Stroe"
            },
            {
                "value": 50569,
                "label": "De Lier",
                "label_ar": "دي لير",
                "label_fr": "De Lier"
            },
            {
                "value": 50709,
                "label": "Arnemuiden",
                "label_ar": "Arnemuiden",
                "label_fr": "Arnemuiden"
            },
            {
                "value": 50941,
                "label": "Rozendaal",
                "label_ar": "روزندال",
                "label_fr": "Rozendaal"
            },
            {
                "value": 50541,
                "label": "Someren",
                "label_ar": "سومرين",
                "label_fr": "Someren"
            },
            {
                "value": 50338,
                "label": "Nunspeet",
                "label_ar": "نونسبيت",
                "label_fr": "Nunspeet"
            },
            {
                "value": 50497,
                "label": "Dijk",
                "label_ar": "ديك",
                "label_fr": "Dijk"
            },
            {
                "value": 50351,
                "label": "Zaandam",
                "label_ar": "زاندام",
                "label_fr": "Zaandam"
            },
            {
                "value": 50923,
                "label": "Gendringen",
                "label_ar": "جيندرينجين",
                "label_fr": "Gendringen"
            },
            {
                "value": 51351,
                "label": "Mill",
                "label_ar": "مطحنة",
                "label_fr": "Moulin"
            },
            {
                "value": 51341,
                "label": "Vierlingsbeek",
                "label_ar": "Vierlingsbeek",
                "label_fr": "Vierlingsbeek"
            },
            {
                "value": 50407,
                "label": "Nederweert",
                "label_ar": "نديرويرت",
                "label_fr": "Nederweert"
            },
            {
                "value": 50861,
                "label": "Appelscha",
                "label_ar": "أبيلشا",
                "label_fr": "Appelscha"
            },
            {
                "value": 50576,
                "label": "Hilvarenbeek",
                "label_ar": "هيلفارينبيك",
                "label_fr": "Hilvarenbeek"
            },
            {
                "value": 50745,
                "label": "Zandvoort",
                "label_ar": "زاندفورت",
                "label_fr": "Zandvoort"
            },
            {
                "value": 50459,
                "label": "Haamstede",
                "label_ar": "هامستيد",
                "label_fr": "Haamstede"
            },
            {
                "value": 51566,
                "label": "Alphen",
                "label_ar": "ألفين",
                "label_fr": "Alphen"
            },
            {
                "value": 50843,
                "label": "Almere Haven",
                "label_ar": "ألمير هافن",
                "label_fr": "Almere Haven"
            },
            {
                "value": 51196,
                "label": "Stadskanaal",
                "label_ar": "Stadskanaal",
                "label_fr": "Stadskanaal"
            },
            {
                "value": 51205,
                "label": "Kats",
                "label_ar": "كاتس",
                "label_fr": "Kats"
            },
            {
                "value": 51651,
                "label": "Baken",
                "label_ar": "باكن",
                "label_fr": "Baken"
            },
            {
                "value": 50349,
                "label": "Sliedrecht",
                "label_ar": "سليدريخت",
                "label_fr": "Sliedrecht"
            },
            {
                "value": 50991,
                "label": "Noordwolde",
                "label_ar": "نوردولد",
                "label_fr": "Noordwolde"
            },
            {
                "value": 50988,
                "label": "Asperen",
                "label_ar": "أسبيرين",
                "label_fr": "Asperen"
            },
            {
                "value": 51490,
                "label": "Koedijk",
                "label_ar": "كوديجك",
                "label_fr": "Koedijk"
            },
            {
                "value": 51579,
                "label": "Julianadorp",
                "label_ar": "جوليانادورب",
                "label_fr": "Julianadorp"
            },
            {
                "value": 51183,
                "label": "Balgoij",
                "label_ar": "بالجويج",
                "label_fr": "Balgoij"
            },
            {
                "value": 50668,
                "label": "Afferden",
                "label_ar": "أفيردين",
                "label_fr": "Afferden"
            },
            {
                "value": 50525,
                "label": "West-Knollendam",
                "label_ar": "ويست نوليندام",
                "label_fr": "West-Knollendam"
            },
            {
                "value": 50804,
                "label": "Philippine",
                "label_ar": "فلبيني",
                "label_fr": "Philippine"
            },
            {
                "value": 51182,
                "label": "Zwartemeer",
                "label_ar": "زوارتمير",
                "label_fr": "Zwartemeer"
            },
            {
                "value": 51614,
                "label": "Nieuwlande",
                "label_ar": "نيولاند",
                "label_fr": "Nieuwlande"
            },
            {
                "value": 51049,
                "label": "Grijpskerk",
                "label_ar": "جريجبسكيرك",
                "label_fr": "Grijpskerk"
            },
            {
                "value": 50390,
                "label": "Raalte",
                "label_ar": "راالت",
                "label_fr": "Raalte"
            },
            {
                "value": 50849,
                "label": "Baarle-Nassau",
                "label_ar": "بارلي ناسو",
                "label_fr": "Baarle-Nassau"
            },
            {
                "value": 50869,
                "label": "De Wijk",
                "label_ar": "دي ويجك",
                "label_fr": "De Wijk"
            },
            {
                "value": 51556,
                "label": "Lobith",
                "label_ar": "لوبيث",
                "label_fr": "Lobith"
            },
            {
                "value": 51444,
                "label": "Schermerhorn",
                "label_ar": "شيرمرهورن",
                "label_fr": "Schermerhorn"
            },
            {
                "value": 50452,
                "label": "Gilze",
                "label_ar": "جيلز",
                "label_fr": "Gilze"
            },
            {
                "value": 51324,
                "label": "Stevensweert",
                "label_ar": "ستيفنسويرت",
                "label_fr": "Stevensweert"
            },
            {
                "value": 51677,
                "label": "Oeffelt",
                "label_ar": "أويفلت",
                "label_fr": "Oeffelt"
            },
            {
                "value": 51618,
                "label": "Zwinderen",
                "label_ar": "زويندرين",
                "label_fr": "Zwinderen"
            },
            {
                "value": 51644,
                "label": "Herveld",
                "label_ar": "هيرفيلد",
                "label_fr": "Herveld"
            },
            {
                "value": 50406,
                "label": "Abcoude",
                "label_ar": "أبكود",
                "label_fr": "Abcoude"
            },
            {
                "value": 50567,
                "label": "Centrum",
                "label_ar": "سنتروم",
                "label_fr": "Centrum"
            },
            {
                "value": 51631,
                "label": "Oosterwolde",
                "label_ar": "أوستروولد",
                "label_fr": "Oosterwolde"
            },
            {
                "value": 50652,
                "label": "Boven-Leeuwen",
                "label_ar": "بوفين ليوين",
                "label_fr": "Boven-Leeuwen"
            },
            {
                "value": 51304,
                "label": "Kruisland",
                "label_ar": "كرويسلاند",
                "label_fr": "Kruisland"
            },
            {
                "value": 51713,
                "label": "Ten Post",
                "label_ar": "عشرة بوست",
                "label_fr": "Dix Post"
            },
            {
                "value": 50810,
                "label": "Gorinchem",
                "label_ar": "جورينشيم",
                "label_fr": "Gorinchem"
            },
            {
                "value": 51361,
                "label": "Nieuw-Bergen",
                "label_ar": "نيو بيرجن",
                "label_fr": "Nieuw-Bergen"
            },
            {
                "value": 51541,
                "label": "Broekhuizen",
                "label_ar": "Broekhuizen",
                "label_fr": "Broekhuizen"
            },
            {
                "value": 51661,
                "label": "Dorp",
                "label_ar": "دورب",
                "label_fr": "Dorp"
            },
            {
                "value": 50573,
                "label": "Gouda",
                "label_ar": "جودة",
                "label_fr": "Gouda"
            },
            {
                "value": 51326,
                "label": "Stein",
                "label_ar": "شتاين",
                "label_fr": "Stein"
            },
            {
                "value": 50373,
                "label": "Oude-Tonge",
                "label_ar": "عود تونج",
                "label_fr": "Oude-Tonge"
            },
            {
                "value": 50365,
                "label": "Groesbeek",
                "label_ar": "جروسبيك",
                "label_fr": "Groesbeek"
            },
            {
                "value": 50581,
                "label": "Biest",
                "label_ar": "متحمس",
                "label_fr": "Biest"
            },
            {
                "value": 51144,
                "label": "Workum",
                "label_ar": "العمل",
                "label_fr": "Workum"
            },
            {
                "value": 51499,
                "label": "Westbroek",
                "label_ar": "ويستبروك",
                "label_fr": "Westbroek"
            },
            {
                "value": 51743,
                "label": "De Blesse",
                "label_ar": "دي بليس",
                "label_fr": "De Blesse"
            },
            {
                "value": 50683,
                "label": "Bemmel",
                "label_ar": "بيميل",
                "label_fr": "Bemmel"
            },
            {
                "value": 51071,
                "label": "Ureterp",
                "label_ar": "الحالب",
                "label_fr": "Uretère"
            },
            {
                "value": 51451,
                "label": "Avenhorn",
                "label_ar": "أفينهورن",
                "label_fr": "Avenhorn"
            },
            {
                "value": 51416,
                "label": "Weurt",
                "label_ar": "يورت",
                "label_fr": "Weurt"
            },
            {
                "value": 51445,
                "label": "Beets",
                "label_ar": "البنجر",
                "label_fr": "Betteraves"
            },
            {
                "value": 50310,
                "label": "Breukelen",
                "label_ar": "بروكيلين",
                "label_fr": "Breukelen"
            },
            {
                "value": 50387,
                "label": "Breda",
                "label_ar": "بريدا",
                "label_fr": "Breda"
            },
            {
                "value": 51148,
                "label": "Rolde",
                "label_ar": "رولد",
                "label_fr": "Rolde"
            },
            {
                "value": 51536,
                "label": "Bemelen",
                "label_ar": "بيملين",
                "label_fr": "Bemelen"
            },
            {
                "value": 51575,
                "label": "Stationsbuurt",
                "label_ar": "المحطات",
                "label_fr": "Stationsbuurt"
            },
            {
                "value": 50342,
                "label": "Vlaardingen",
                "label_ar": "فلاردينجن",
                "label_fr": "Vlaardingen"
            },
            {
                "value": 50464,
                "label": "Rheden",
                "label_ar": "ريدين",
                "label_fr": "Rheden"
            },
            {
                "value": 51154,
                "label": "Hengevelde",
                "label_ar": "هينجفيلدي",
                "label_fr": "Hengevelde"
            },
            {
                "value": 50584,
                "label": "Poortugaal",
                "label_ar": "Poortugaal",
                "label_fr": "Poortugaal"
            },
            {
                "value": 50710,
                "label": "Oudelande",
                "label_ar": "Oudelande",
                "label_fr": "Oudelande"
            },
            {
                "value": 50772,
                "label": "Oirsbeek",
                "label_ar": "أورسبيك",
                "label_fr": "Oirsbeek"
            },
            {
                "value": 51475,
                "label": "Langezwaag",
                "label_ar": "لانجيزواج",
                "label_fr": "Langezwaag"
            },
            {
                "value": 51055,
                "label": "Eys",
                "label_ar": "Eys",
                "label_fr": "Eys"
            },
            {
                "value": 51022,
                "label": "Borsele",
                "label_ar": "بورسيل",
                "label_fr": "Borsele"
            },
            {
                "value": 50462,
                "label": "Nes",
                "label_ar": "متنوع",
                "label_fr": "Nda"
            },
            {
                "value": 50914,
                "label": "Heino",
                "label_ar": "هينو",
                "label_fr": "Heino"
            },
            {
                "value": 50947,
                "label": "Strijen",
                "label_ar": "Strijen",
                "label_fr": "Strijen"
            },
            {
                "value": 50860,
                "label": "Augustinusga",
                "label_ar": "أوغسطينوسغا",
                "label_fr": "Augustinusga"
            },
            {
                "value": 51166,
                "label": "Bosschenhoofd",
                "label_ar": "Bosschenhoofd",
                "label_fr": "Bosschenhoofd"
            },
            {
                "value": 51345,
                "label": "Maashees",
                "label_ar": "ماشي",
                "label_fr": "Maashees"
            },
            {
                "value": 51259,
                "label": "Hooglanderveen",
                "label_ar": "Hooglanderveen",
                "label_fr": "Hooglanderveen"
            },
            {
                "value": 50765,
                "label": "Herwijnen",
                "label_ar": "هيروينين",
                "label_fr": "Herwijnen"
            },
            {
                "value": 51319,
                "label": "Echt",
                "label_ar": "Echt",
                "label_fr": "Echt"
            },
            {
                "value": 50486,
                "label": "Eethen",
                "label_ar": "ايثن",
                "label_fr": "Eethen"
            },
            {
                "value": 51004,
                "label": "Boeicop",
                "label_ar": "بويكوب",
                "label_fr": "Boeicop"
            },
            {
                "value": 51588,
                "label": "Aduard",
                "label_ar": "أدوارد",
                "label_fr": "Aduard"
            },
            {
                "value": 50489,
                "label": "Leiden",
                "label_ar": "ليدن",
                "label_fr": "Leiden"
            },
            {
                "value": 50454,
                "label": "Venlo",
                "label_ar": "Venlo",
                "label_fr": "Venlo"
            },
            {
                "value": 50872,
                "label": "Heeten",
                "label_ar": "هيتين",
                "label_fr": "Heeten"
            },
            {
                "value": 51141,
                "label": "Stedum",
                "label_ar": "ستيدوم",
                "label_fr": "Stedum"
            },
            {
                "value": 51171,
                "label": "Oud-Gastel",
                "label_ar": "عود جاستل",
                "label_fr": "Oud-Gastel"
            },
            {
                "value": 50564,
                "label": "Foxhol",
                "label_ar": "فوكهول",
                "label_fr": "Foxhol"
            },
            {
                "value": 50694,
                "label": "De Koog",
                "label_ar": "دي كوج",
                "label_fr": "De Koog"
            },
            {
                "value": 50782,
                "label": "Opheusden",
                "label_ar": "أوفيوسدن",
                "label_fr": "Opheusden"
            },
            {
                "value": 50551,
                "label": "Boxmeer",
                "label_ar": "بوكسمير",
                "label_fr": "Boxmeer"
            },
            {
                "value": 50989,
                "label": "Chaam",
                "label_ar": "تشام",
                "label_fr": "Chaam"
            },
            {
                "value": 51716,
                "label": "Sint Nicolaasga",
                "label_ar": "سينت نيكولاسجا",
                "label_fr": "Sint Nicolaasga"
            },
            {
                "value": 51329,
                "label": "Nieuwstadt",
                "label_ar": "نيوستادت",
                "label_fr": "Nieuwstadt"
            },
            {
                "value": 50856,
                "label": "Bellingwolde",
                "label_ar": "بيلينجولد",
                "label_fr": "Bellingwolde"
            },
            {
                "value": 51199,
                "label": "Rijssen",
                "label_ar": "ريسن",
                "label_fr": "Rijssen"
            },
            {
                "value": 51753,
                "label": "Hasselt",
                "label_ar": "هاسيلت",
                "label_fr": "Hasselt"
            },
            {
                "value": 50747,
                "label": "Langedijk",
                "label_ar": "لانجيديك",
                "label_fr": "Langedijk"
            },
            {
                "value": 51201,
                "label": "Steijl",
                "label_ar": "ستيجل",
                "label_fr": "Steijl"
            },
            {
                "value": 51333,
                "label": "Beringe",
                "label_ar": "بيرنج",
                "label_fr": "Beringe"
            },
            {
                "value": 51724,
                "label": "Woensdrecht",
                "label_ar": "Woensdrecht",
                "label_fr": "Woensdrecht"
            },
            {
                "value": 51759,
                "label": "Schagerbrug",
                "label_ar": "شغيربروغ",
                "label_fr": "Schagerbrug"
            },
            {
                "value": 50878,
                "label": "Kessel",
                "label_ar": "كيسيل",
                "label_fr": "Kessel"
            },
            {
                "value": 50839,
                "label": "Cuijk",
                "label_ar": "كويك",
                "label_fr": "Cuijk"
            },
            {
                "value": 51096,
                "label": "Meeden",
                "label_ar": "ميدان",
                "label_fr": "Meeden"
            },
            {
                "value": 51210,
                "label": "Gapinge",
                "label_ar": "غابينغ",
                "label_fr": "Gapinge"
            },
            {
                "value": 51745,
                "label": "Kimswerd",
                "label_ar": "Kimswerd",
                "label_fr": "Kimswerd"
            },
            {
                "value": 51025,
                "label": "Den Hoorn",
                "label_ar": "دن هورن",
                "label_fr": "Den Hoorn"
            },
            {
                "value": 51133,
                "label": "Echtenerbrug",
                "label_ar": "إختنربروج",
                "label_fr": "Echtenerbrug"
            },
            {
                "value": 51562,
                "label": "Westerhoven",
                "label_ar": "ويسترهوفن",
                "label_fr": "Westerhoven"
            },
            {
                "value": 51635,
                "label": "Frederiksoord",
                "label_ar": "فريدريكسور",
                "label_fr": "Frederiksoord"
            },
            {
                "value": 51458,
                "label": "Den Oever",
                "label_ar": "دن أويفر",
                "label_fr": "Den Oever"
            },
            {
                "value": 50369,
                "label": "Druten",
                "label_ar": "دروتن",
                "label_fr": "Druten"
            },
            {
                "value": 51105,
                "label": "Vriezenveen",
                "label_ar": "فريزنفين",
                "label_fr": "Vriezenveen"
            },
            {
                "value": 51286,
                "label": "Waspik",
                "label_ar": "واسبيك",
                "label_fr": "Waspik"
            },
            {
                "value": 51208,
                "label": "Kortgene",
                "label_ar": "كورتجين",
                "label_fr": "Kortgene"
            },
            {
                "value": 51241,
                "label": "Sleeuwijk",
                "label_ar": "سليويجك",
                "label_fr": "Sleeuwijk"
            },
            {
                "value": 51585,
                "label": "Gieterveen",
                "label_ar": "جيترفين",
                "label_fr": "Gieterveen"
            },
            {
                "value": 51755,
                "label": "Rutten",
                "label_ar": "روتن",
                "label_fr": "Rutten"
            },
            {
                "value": 51663,
                "label": "Bourtange",
                "label_ar": "بورتانج",
                "label_fr": "Bourtange"
            },
            {
                "value": 50785,
                "label": "Overberg",
                "label_ar": "أوفربيرغ",
                "label_fr": "Overberg"
            },
            {
                "value": 50853,
                "label": "Waarland",
                "label_ar": "وارلاند",
                "label_fr": "Waarland"
            },
            {
                "value": 51227,
                "label": "Hoornaar",
                "label_ar": "هورنار",
                "label_fr": "Hoornaar"
            },
            {
                "value": 50514,
                "label": "Best",
                "label_ar": "الأفضل",
                "label_fr": "Meilleur"
            },
            {
                "value": 51400,
                "label": "Halle",
                "label_ar": "هالي",
                "label_fr": "Halle"
            },
            {
                "value": 51410,
                "label": "Milheeze",
                "label_ar": "ميلهيز",
                "label_fr": "Milheeze"
            },
            {
                "value": 50763,
                "label": "Munstergeleen",
                "label_ar": "مونسترجلين",
                "label_fr": "Munstergeleen"
            },
            {
                "value": 51129,
                "label": "Burgwerd",
                "label_ar": "بورغفيرد",
                "label_fr": "Burgwerd"
            },
            {
                "value": 50917,
                "label": "Mierlo",
                "label_ar": "ميرلو",
                "label_fr": "Mierlo"
            },
            {
                "value": 51033,
                "label": "Vlijmen",
                "label_ar": "فليجمين",
                "label_fr": "Vlijmen"
            },
            {
                "value": 51172,
                "label": "Putte",
                "label_ar": "بوت",
                "label_fr": "Putte"
            },
            {
                "value": 51565,
                "label": "Ballum",
                "label_ar": "بلوم",
                "label_fr": "Ballum"
            },
            {
                "value": 50908,
                "label": "Eefde",
                "label_ar": "إيفدي",
                "label_fr": "Eefde"
            },
            {
                "value": 51297,
                "label": "Schimmert",
                "label_ar": "شيميرت",
                "label_fr": "Schimmert"
            },
            {
                "value": 51453,
                "label": "Hensbroek",
                "label_ar": "هينسبروك",
                "label_fr": "Hensbroek"
            },
            {
                "value": 50417,
                "label": "Ermelo",
                "label_ar": "إرميلو",
                "label_fr": "Ermelo"
            },
            {
                "value": 50433,
                "label": "Hoorn",
                "label_ar": "هورن",
                "label_fr": "Hoorn"
            },
            {
                "value": 51036,
                "label": "Lutjebroek",
                "label_ar": "لوتيبروك",
                "label_fr": "Lutjebroek"
            },
            {
                "value": 50967,
                "label": "Zwartebroek",
                "label_ar": "زوارتبروك",
                "label_fr": "Zwartebroek"
            },
            {
                "value": 51018,
                "label": "Ederveen",
                "label_ar": "إديرفين",
                "label_fr": "Ederveen"
            },
            {
                "value": 51104,
                "label": "Ulrum",
                "label_ar": "أولرم",
                "label_fr": "Ulrum"
            },
            {
                "value": 51026,
                "label": "Feanwalden",
                "label_ar": "فيانوالدن",
                "label_fr": "Feanwalden"
            },
            {
                "value": 51432,
                "label": "Twisk",
                "label_ar": "تويسك",
                "label_fr": "Twisk"
            },
            {
                "value": 51680,
                "label": "Akersloot",
                "label_ar": "أكيرسلوت",
                "label_fr": "Akersloot"
            },
            {
                "value": 50552,
                "label": "Edam",
                "label_ar": "ايدام",
                "label_fr": "Edam"
            },
            {
                "value": 50954,
                "label": "Oudorp",
                "label_ar": "أودورب",
                "label_fr": "Oudorp"
            },
            {
                "value": 50579,
                "label": "Malden",
                "label_ar": "مالدن",
                "label_fr": "Malden"
            },
            {
                "value": 51254,
                "label": "Katwijk aan Zee",
                "label_ar": "Katwijk aan Zee",
                "label_fr": "Katwijk aan Zee"
            },
            {
                "value": 51396,
                "label": "Finsterwolde",
                "label_ar": "فينسترولد",
                "label_fr": "Finsterwolde"
            },
            {
                "value": 51344,
                "label": "Zeeland",
                "label_ar": "زيلاند",
                "label_fr": "Zélande"
            },
            {
                "value": 51181,
                "label": "Hout",
                "label_ar": "هوت",
                "label_fr": "Hout"
            },
            {
                "value": 50905,
                "label": "Vinkeveen",
                "label_ar": "فينكفين",
                "label_fr": "Vinkeveen"
            },
            {
                "value": 51674,
                "label": "Terschuur",
                "label_ar": "ترشور",
                "label_fr": "Terschuur"
            },
            {
                "value": 50574,
                "label": "Borculo",
                "label_ar": "بوركولو",
                "label_fr": "Borculo"
            },
            {
                "value": 50890,
                "label": "IJzendoorn",
                "label_ar": "IJzendoorn",
                "label_fr": "IJzendoorn"
            },
            {
                "value": 51246,
                "label": "Renswoude",
                "label_ar": "رينسوود",
                "label_fr": "Renswoude"
            },
            {
                "value": 51583,
                "label": "Steggerda",
                "label_ar": "ستيجردا",
                "label_fr": "Steggerda"
            },
            {
                "value": 51723,
                "label": "Zuiderpark",
                "label_ar": "زويدربارك",
                "label_fr": "Zuiderpark"
            },
            {
                "value": 51703,
                "label": "Anderen",
                "label_ar": "أنديرين",
                "label_fr": "Anderen"
            },
            {
                "value": 51119,
                "label": "Vasse",
                "label_ar": "فاس",
                "label_fr": "Vasse"
            },
            {
                "value": 51354,
                "label": "Banholt",
                "label_ar": "بانهولت",
                "label_fr": "Banholt"
            },
            {
                "value": 51492,
                "label": "Bloemendaal",
                "label_ar": "بلومندال",
                "label_fr": "Bloemendaal"
            },
            {
                "value": 51768,
                "label": "Aalsmeerderbrug",
                "label_ar": "السميردربروغ",
                "label_fr": "Aalsmeerderbrug"
            },
            {
                "value": 50341,
                "label": "Apeldoorn",
                "label_ar": "أبلدورن",
                "label_fr": "Apeldoorn"
            },
            {
                "value": 51679,
                "label": "Schiermonnikoog",
                "label_ar": "شييرمونيكوج",
                "label_fr": "Schiermonnikoog"
            },
            {
                "value": 50969,
                "label": "Gytsjerk",
                "label_ar": "Gytsjerk",
                "label_fr": "Gytsjerk"
            },
            {
                "value": 51306,
                "label": "Wouwsche Plantage",
                "label_ar": "Wouwsche Plantage",
                "label_fr": "Wouwsche Plantage"
            },
            {
                "value": 51660,
                "label": "Ressen",
                "label_ar": "ريسن",
                "label_fr": "Ressen"
            },
            {
                "value": 50799,
                "label": "Engelen",
                "label_ar": "إنجلين",
                "label_fr": "Engelen"
            },
            {
                "value": 51510,
                "label": "Woltersum",
                "label_ar": "ولترسوم",
                "label_fr": "Woltersum"
            },
            {
                "value": 50543,
                "label": "Oostwold",
                "label_ar": "أوستولد",
                "label_fr": "Oostwold"
            },
            {
                "value": 51462,
                "label": "Spierdijk",
                "label_ar": "سبيرديك",
                "label_fr": "Spierdijk"
            },
            {
                "value": 51620,
                "label": "Gramsbergen",
                "label_ar": "غرامسبيرجين",
                "label_fr": "Gramsbergen"
            },
            {
                "value": 51678,
                "label": "Zalk",
                "label_ar": "زالك",
                "label_fr": "Zalk"
            },
            {
                "value": 50480,
                "label": "Middelburg",
                "label_ar": "ميدلبورغ",
                "label_fr": "Middelburg"
            },
            {
                "value": 50722,
                "label": "Oosterland",
                "label_ar": "اوسترلاند",
                "label_fr": "Oosterland"
            },
            {
                "value": 50449,
                "label": "Rockanje",
                "label_ar": "روكانج",
                "label_fr": "Rockanje"
            },
            {
                "value": 51130,
                "label": "Hoogersmilde",
                "label_ar": "Hoogersmilde",
                "label_fr": "Hoogersmilde"
            },
            {
                "value": 51427,
                "label": "Hoevelaken",
                "label_ar": "هوفيلاكن",
                "label_fr": "Hoevelaken"
            },
            {
                "value": 50391,
                "label": "Utrecht",
                "label_ar": "أوتريخت",
                "label_fr": "Utrecht"
            },
            {
                "value": 50739,
                "label": "Blaricum",
                "label_ar": "بلاريكوم",
                "label_fr": "Blaricum"
            },
            {
                "value": 50871,
                "label": "Ane",
                "label_ar": "أني",
                "label_fr": "Ane"
            },
            {
                "value": 50948,
                "label": "Lent",
                "label_ar": "أقرض",
                "label_fr": "Prêté"
            },
            {
                "value": 50723,
                "label": "Rilland",
                "label_ar": "ريلاند",
                "label_fr": "Rilland"
            },
            {
                "value": 50385,
                "label": "Oudega",
                "label_ar": "اوديغا",
                "label_fr": "Oudega"
            },
            {
                "value": 50506,
                "label": "'s-Hertogenbosch",
                "label_ar": "'s-Hertogenbosch",
                "label_fr": "'s-Hertogenbosch"
            },
            {
                "value": 50852,
                "label": "Beugen",
                "label_ar": "بيوجين",
                "label_fr": "Beugen"
            },
            {
                "value": 51315,
                "label": "Den Dungen",
                "label_ar": "دن دونجن",
                "label_fr": "Den Dungen"
            },
            {
                "value": 51376,
                "label": "America",
                "label_ar": "أمريكا",
                "label_fr": "Amérique"
            },
            {
                "value": 51578,
                "label": "Ell",
                "label_ar": "Ell",
                "label_fr": "Aune"
            },
            {
                "value": 51524,
                "label": "Ochten",
                "label_ar": "أوختن",
                "label_fr": "Ochten"
            },
            {
                "value": 51465,
                "label": "Stad aan 't Haringvliet",
                "label_ar": "Stad aan 't Haringvliet",
                "label_fr": "Stad aan 't Haringvliet"
            },
            {
                "value": 50568,
                "label": "'s-Gravenzande",
                "label_ar": "'s-Gravenzande",
                "label_fr": "'s-Gravenzande"
            },
            {
                "value": 51421,
                "label": "Purmerland",
                "label_ar": "بورمرلاند",
                "label_fr": "Purmerland"
            },
            {
                "value": 51688,
                "label": "Vorden",
                "label_ar": "فوردين",
                "label_fr": "Vorden"
            },
            {
                "value": 50822,
                "label": "Oud-Alblas",
                "label_ar": "عود البلاس",
                "label_fr": "Oud-Alblas"
            },
            {
                "value": 51685,
                "label": "Taarlo",
                "label_ar": "تارلو",
                "label_fr": "Taarlo"
            },
            {
                "value": 50876,
                "label": "Holtum",
                "label_ar": "هولتوم",
                "label_fr": "Holtum"
            },
            {
                "value": 51062,
                "label": "Berkhout",
                "label_ar": "برخوت",
                "label_fr": "Berkhout"
            },
            {
                "value": 50335,
                "label": "Hilversum",
                "label_ar": "هيلفرسوم",
                "label_fr": "Hilversum"
            },
            {
                "value": 51275,
                "label": "Oosthuizen",
                "label_ar": "أوستويزن",
                "label_fr": "Oosthuizen"
            },
            {
                "value": 51757,
                "label": "Lemelerveld",
                "label_ar": "ليمليرفيلد",
                "label_fr": "Lemelerveld"
            },
            {
                "value": 51200,
                "label": "Vlodrop",
                "label_ar": "فلودروب",
                "label_fr": "Vlodrop"
            },
            {
                "value": 50555,
                "label": "Baars",
                "label_ar": "بارس",
                "label_fr": "Baars"
            },
            {
                "value": 51653,
                "label": "Zuid-Beijerland",
                "label_ar": "زويد بيجيرلاند",
                "label_fr": "Zuid-Beijerland"
            },
            {
                "value": 50818,
                "label": "Sint Joost",
                "label_ar": "سينت جوست",
                "label_fr": "Saint-Joost"
            },
            {
                "value": 51473,
                "label": "Tuitjenhorn",
                "label_ar": "تويتجنهورن",
                "label_fr": "Tuitjenhorn"
            },
            {
                "value": 50667,
                "label": "Oudehorne",
                "label_ar": "اودهورن",
                "label_fr": "Oudehorne"
            },
            {
                "value": 51468,
                "label": "Gennep",
                "label_ar": "جينيب",
                "label_fr": "Gennep"
            },
            {
                "value": 51438,
                "label": "Katwoude",
                "label_ar": "Katwoude",
                "label_fr": "Katwoude"
            },
            {
                "value": 50650,
                "label": "Waalre",
                "label_ar": "والار",
                "label_fr": "Waalre"
            },
            {
                "value": 50445,
                "label": "Horn",
                "label_ar": "بوق",
                "label_fr": "klaxon"
            },
            {
                "value": 50879,
                "label": "Grathem",
                "label_ar": "غراثم",
                "label_fr": "Grathem"
            },
            {
                "value": 51251,
                "label": "Elsendorp",
                "label_ar": "السندورب",
                "label_fr": "Elsendorp"
            },
            {
                "value": 51454,
                "label": "Blokker",
                "label_ar": "بلكر",
                "label_fr": "Blokker"
            },
            {
                "value": 51467,
                "label": "Werkhoven",
                "label_ar": "ويركهوفن",
                "label_fr": "Werkhoven"
            },
            {
                "value": 51190,
                "label": "Oudendijk",
                "label_ar": "أودينديك",
                "label_fr": "Oudendijk"
            },
            {
                "value": 51357,
                "label": "Noorbeek",
                "label_ar": "نوربيك",
                "label_fr": "Noorbeek"
            },
            {
                "value": 51546,
                "label": "Vrouwenpolder",
                "label_ar": "Vrouwenpolder",
                "label_fr": "Vrouwenpolder"
            },
            {
                "value": 51087,
                "label": "Bocholtz",
                "label_ar": "Bocholtz",
                "label_fr": "Bocholtz"
            },
            {
                "value": 51302,
                "label": "Rijsbergen",
                "label_ar": "ريجسبيرجين",
                "label_fr": "Rijsbergen"
            },
            {
                "value": 51613,
                "label": "Oosterhesselen",
                "label_ar": "أوسترهيسلين",
                "label_fr": "Oosterhesselen"
            },
            {
                "value": 51533,
                "label": "Rossum",
                "label_ar": "روسوم",
                "label_fr": "Rossum"
            },
            {
                "value": 50655,
                "label": "Hurdegaryp",
                "label_ar": "Hurdegaryp",
                "label_fr": "Hurdegaryp"
            },
            {
                "value": 51429,
                "label": "Den Hout",
                "label_ar": "دن هوت",
                "label_fr": "Den Hout"
            },
            {
                "value": 50325,
                "label": "Vught",
                "label_ar": "فوخت",
                "label_fr": "Vught"
            },
            {
                "value": 50937,
                "label": "Kerkdriel",
                "label_ar": "Kerkdriel",
                "label_fr": "Kerkdriel"
            },
            {
                "value": 51109,
                "label": "Tubbergen",
                "label_ar": "توببرجين",
                "label_fr": "Tubbergen"
            },
            {
                "value": 50674,
                "label": "Wintelre",
                "label_ar": "وينتلر",
                "label_fr": "Wintelre"
            },
            {
                "value": 50411,
                "label": "Numansdorp",
                "label_ar": "نومسدورب",
                "label_fr": "Numansdorp"
            },
            {
                "value": 50458,
                "label": "Voorburg",
                "label_ar": "فوربورغ",
                "label_fr": "Voorburg"
            },
            {
                "value": 50734,
                "label": "Kapelle",
                "label_ar": "كابيل",
                "label_fr": "Kapelle"
            },
            {
                "value": 50794,
                "label": "De Meern",
                "label_ar": "دي ميرن",
                "label_fr": "De Meern"
            },
            {
                "value": 51335,
                "label": "Helden",
                "label_ar": "هيلدن",
                "label_fr": "Helden"
            },
            {
                "value": 50970,
                "label": "Lienden",
                "label_ar": "ليندن",
                "label_fr": "Lienden"
            },
            {
                "value": 51283,
                "label": "Rijen",
                "label_ar": "ريجين",
                "label_fr": "Rijen"
            },
            {
                "value": 51551,
                "label": "Tinte",
                "label_ar": "تينت",
                "label_fr": "Tinte"
            },
            {
                "value": 50770,
                "label": "Elburg",
                "label_ar": "إلبورج",
                "label_fr": "Elburg"
            },
            {
                "value": 50346,
                "label": "Nijmegen",
                "label_ar": "نيميغن",
                "label_fr": "Nimègue"
            },
            {
                "value": 51011,
                "label": "Gendt",
                "label_ar": "جندت",
                "label_fr": "Gendt"
            },
            {
                "value": 51288,
                "label": "Aalburg",
                "label_ar": "ألبورغ",
                "label_fr": "Aalburg"
            },
            {
                "value": 51336,
                "label": "Egchel",
                "label_ar": "إيجل",
                "label_fr": "Egchel"
            },
            {
                "value": 50737,
                "label": "Oudemirdum",
                "label_ar": "أودميردوم",
                "label_fr": "Oudemirdum"
            },
            {
                "value": 50828,
                "label": "Lutten",
                "label_ar": "لوتن",
                "label_fr": "Lutten"
            },
            {
                "value": 51056,
                "label": "Joppe",
                "label_ar": "جوبي",
                "label_fr": "Joppe"
            },
            {
                "value": 51446,
                "label": "Broek in Waterland",
                "label_ar": "بروك في ووترلاند",
                "label_fr": "Broek dans Waterland"
            },
            {
                "value": 51233,
                "label": "Giessenburg",
                "label_ar": "جيسينبورج",
                "label_fr": "Giessenburg"
            },
            {
                "value": 50726,
                "label": "Oost-Souburg",
                "label_ar": "أوست سوبورغ",
                "label_fr": "Oost-Souburg"
            },
            {
                "value": 50746,
                "label": "Emmen",
                "label_ar": "إمين",
                "label_fr": "Emmen"
            },
            {
                "value": 50806,
                "label": "Oegstgeest",
                "label_ar": "Oegstgeest",
                "label_fr": "Oegstgeest"
            },
            {
                "value": 50615,
                "label": "Ulvenhout",
                "label_ar": "Ulvenhout",
                "label_fr": "Ulvenhout"
            },
            {
                "value": 50656,
                "label": "Leuth",
                "label_ar": "لوث",
                "label_fr": "Leuth"
            },
            {
                "value": 51686,
                "label": "Ruinen",
                "label_ar": "روينين",
                "label_fr": "Ruinen"
            },
            {
                "value": 50402,
                "label": "Heerde",
                "label_ar": "هيردي",
                "label_fr": "Heerde"
            },
            {
                "value": 51629,
                "label": "Siddeburen",
                "label_ar": "سديبورين",
                "label_fr": "Siddeburen"
            },
            {
                "value": 50374,
                "label": "Duiven",
                "label_ar": "Duiven",
                "label_fr": "Duiven"
            },
            {
                "value": 50416,
                "label": "Putten",
                "label_ar": "ضع",
                "label_fr": "Putten"
            },
            {
                "value": 51042,
                "label": "Hooghalen",
                "label_ar": "هوغالين",
                "label_fr": "Hooghalen"
            },
            {
                "value": 50730,
                "label": "'s-Gravenpolder",
                "label_ar": "'s-Gravenpolder",
                "label_fr": "'s-Gravenpolder"
            },
            {
                "value": 51472,
                "label": "Wamel",
                "label_ar": "وامل",
                "label_fr": "Wamel"
            },
            {
                "value": 50632,
                "label": "Noordwelle",
                "label_ar": "نوردويل",
                "label_fr": "Noordwelle"
            },
            {
                "value": 50591,
                "label": "Delfgauw",
                "label_ar": "دلفجاو",
                "label_fr": "Delfgauw"
            },
            {
                "value": 50816,
                "label": "Dinteloord",
                "label_ar": "دينتلورد",
                "label_fr": "Dinteloord"
            },
            {
                "value": 51597,
                "label": "Scharmer",
                "label_ar": "شارمر",
                "label_fr": "Scharmer"
            },
            {
                "value": 51478,
                "label": "Hooge Zwaluwe",
                "label_ar": "هوج زوالوي",
                "label_fr": "Hooge Zwaluwe"
            },
            {
                "value": 50838,
                "label": "Heemskerk",
                "label_ar": "هيمسكيرك",
                "label_fr": "Heemskerk"
            },
            {
                "value": 51388,
                "label": "Erm",
                "label_ar": "Erm",
                "label_fr": "Euh"
            },
            {
                "value": 51485,
                "label": "Batenburg",
                "label_ar": "باتنبورغ",
                "label_fr": "Batenburg"
            },
            {
                "value": 50929,
                "label": "Stiens",
                "label_ar": "ستينز",
                "label_fr": "Stiens"
            },
            {
                "value": 51316,
                "label": "Maaskantje",
                "label_ar": "ماسكانتجي",
                "label_fr": "Maaskantje"
            },
            {
                "value": 51505,
                "label": "Stolwijk",
                "label_ar": "ستولفايك",
                "label_fr": "Stolwijk"
            },
            {
                "value": 51234,
                "label": "Hoogblokland",
                "label_ar": "Hoogblokland",
                "label_fr": "Hoogblokland"
            },
            {
                "value": 51731,
                "label": "Harlingen",
                "label_ar": "هارلينجن",
                "label_fr": "Harlingen"
            },
            {
                "value": 50761,
                "label": "Hoogland",
                "label_ar": "هوغلاند",
                "label_fr": "Hoogland"
            },
            {
                "value": 51642,
                "label": "Berg en Dal",
                "label_ar": "بيرج إن دال",
                "label_fr": "Berg en Dal"
            },
            {
                "value": 51496,
                "label": "Warder",
                "label_ar": "الحارس",
                "label_fr": "Gardien"
            },
            {
                "value": 50571,
                "label": "Maassluis",
                "label_ar": "ماسلوس",
                "label_fr": "Maassluis"
            },
            {
                "value": 50383,
                "label": "Brunssum",
                "label_ar": "برونسوم",
                "label_fr": "Brunssum"
            },
            {
                "value": 51072,
                "label": "Partij",
                "label_ar": "بارتيج",
                "label_fr": "Partij"
            },
            {
                "value": 51270,
                "label": "Merkelbeek",
                "label_ar": "ميركيلبيك",
                "label_fr": "Merkelbeek"
            },
            {
                "value": 50977,
                "label": "De Glind",
                "label_ar": "دي جليند",
                "label_fr": "De Glind"
            },
            {
                "value": 51709,
                "label": "Nieuwe Wetering",
                "label_ar": "نيو ويترينغ",
                "label_fr": "Nieuwe Wetering"
            },
            {
                "value": 50695,
                "label": "Slenaken",
                "label_ar": "سليناكن",
                "label_fr": "Slenaken"
            },
            {
                "value": 51005,
                "label": "Zevenhoven",
                "label_ar": "زيفينهوفن",
                "label_fr": "Zevenhoven"
            },
            {
                "value": 51714,
                "label": "Wagenborgen",
                "label_ar": "واجينبورغ",
                "label_fr": "Wagenborgen"
            },
            {
                "value": 51284,
                "label": "Goirle",
                "label_ar": "جويرل",
                "label_fr": "Goirle"
            },
            {
                "value": 51281,
                "label": "Uithuizen",
                "label_ar": "Uithuizen",
                "label_fr": "Uithuizen"
            },
            {
                "value": 51395,
                "label": "Limmen",
                "label_ar": "ليمن",
                "label_fr": "Limmen"
            },
            {
                "value": 51607,
                "label": "Lettele",
                "label_ar": "ليتيل",
                "label_fr": "Lettele"
            },
            {
                "value": 50363,
                "label": "Purmerend",
                "label_ar": "بورميريند",
                "label_fr": "Purmerend"
            },
            {
                "value": 51249,
                "label": "Nieuwer-Ter-Aa",
                "label_ar": "Nieuwer-Ter-Aa",
                "label_fr": "Nieuwer-Ter-Aa"
            },
            {
                "value": 51415,
                "label": "Zwartsluis",
                "label_ar": "Zwartsluis",
                "label_fr": "Zwartsluis"
            },
            {
                "value": 50653,
                "label": "De Zilk",
                "label_ar": "دي زيلك",
                "label_fr": "De Zilk"
            },
            {
                "value": 51084,
                "label": "Ingelum",
                "label_ar": "إنجلوم",
                "label_fr": "Ingelum"
            },
            {
                "value": 51126,
                "label": "Hennaard",
                "label_ar": "الحناء",
                "label_fr": "Henné"
            },
            {
                "value": 51132,
                "label": "Hemelum",
                "label_ar": "هيميلوم",
                "label_fr": "Hemelum"
            },
            {
                "value": 50362,
                "label": "Spijkenisse",
                "label_ar": "Spijkenisse",
                "label_fr": "Spijkenisse"
            },
            {
                "value": 51009,
                "label": "Zwaag",
                "label_ar": "زواج",
                "label_fr": "Zwaag"
            },
            {
                "value": 51086,
                "label": "Eexterzandvoort",
                "label_ar": "إكسترزاندفورت",
                "label_fr": "Eexterzandvoort"
            },
            {
                "value": 50926,
                "label": "Suwald",
                "label_ar": "Suwald",
                "label_fr": "Suwald"
            },
            {
                "value": 50889,
                "label": "Lathum",
                "label_ar": "لاتوم",
                "label_fr": "Lathum"
            },
            {
                "value": 50966,
                "label": "Lieshout",
                "label_ar": "ليشوت",
                "label_fr": "Lieshout"
            },
            {
                "value": 51313,
                "label": "Heusden",
                "label_ar": "هيوسدن",
                "label_fr": "Heusden"
            },
            {
                "value": 51198,
                "label": "Wijhe",
                "label_ar": "ويجي",
                "label_fr": "Wijhe"
            },
            {
                "value": 50498,
                "label": "Heiloo",
                "label_ar": "هيلو",
                "label_fr": "Heiloo"
            },
            {
                "value": 50995,
                "label": "De Hoef",
                "label_ar": "دي هوف",
                "label_fr": "De Hoef"
            },
            {
                "value": 51131,
                "label": "Lemmer",
                "label_ar": "ليمر",
                "label_fr": "Lemmer"
            },
            {
                "value": 50409,
                "label": "Barneveld",
                "label_ar": "بارنيفيلد",
                "label_fr": "Barneveld"
            },
            {
                "value": 50699,
                "label": "Almelo",
                "label_ar": "ألميلو",
                "label_fr": "Almelo"
            },
            {
                "value": 51066,
                "label": "Vaals",
                "label_ar": "فالس",
                "label_fr": "Vaals"
            },
            {
                "value": 51311,
                "label": "Cromvoirt",
                "label_ar": "كرومفيرت",
                "label_fr": "Cromvoirt"
            },
            {
                "value": 51420,
                "label": "Angeren",
                "label_ar": "أنجرين",
                "label_fr": "Angeren"
            },
            {
                "value": 51595,
                "label": "Opeinde",
                "label_ar": "أوبيندي",
                "label_fr": "Opeinde"
            },
            {
                "value": 50629,
                "label": "Eersel",
                "label_ar": "ايرسيل",
                "label_fr": "Eersel"
            },
            {
                "value": 50478,
                "label": "Gulpen",
                "label_ar": "غالبن",
                "label_fr": "Gulpen"
            },
            {
                "value": 50802,
                "label": "Reeuwijk",
                "label_ar": "رويويك",
                "label_fr": "Reeuwijk"
            },
            {
                "value": 50693,
                "label": "Den Burg",
                "label_ar": "دن بورغ",
                "label_fr": "Den Burg"
            },
            {
                "value": 51151,
                "label": "Tilligte",
                "label_ar": "تيليجتي",
                "label_fr": "Tilligte"
            },
            {
                "value": 51067,
                "label": "Kortenhoef",
                "label_ar": "كورتنهوف",
                "label_fr": "Kortenhoef"
            },
            {
                "value": 51092,
                "label": "Spijk",
                "label_ar": "سبيجك",
                "label_fr": "Spijk"
            },
            {
                "value": 51367,
                "label": "Grubbenvorst",
                "label_ar": "جروبنفورست",
                "label_fr": "Grubbenvorst"
            },
            {
                "value": 51736,
                "label": "Lippenhuizen",
                "label_ar": "Lippenhuizen",
                "label_fr": "Lippenhuizen"
            },
            {
                "value": 50343,
                "label": "Hellevoetsluis",
                "label_ar": "هيليفوتسلاوس",
                "label_fr": "Hellevoetsluis"
            },
            {
                "value": 51120,
                "label": "Ysbrechtum",
                "label_ar": "Ysbrechtum",
                "label_fr": "Ysbrechtum"
            },
            {
                "value": 51397,
                "label": "Slochteren",
                "label_ar": "Slochteren",
                "label_fr": "Slochteren"
            },
            {
                "value": 50596,
                "label": "Landsmeer",
                "label_ar": "لاندسمير",
                "label_fr": "Landsmeer"
            },
            {
                "value": 51636,
                "label": "IJhorst",
                "label_ar": "IJhorst",
                "label_fr": "IJhorst"
            },
            {
                "value": 51715,
                "label": "Legemeer",
                "label_ar": "ليجمير",
                "label_fr": "Legemeer"
            },
            {
                "value": 50979,
                "label": "Yde",
                "label_ar": "يدي",
                "label_fr": "Yde"
            },
            {
                "value": 51135,
                "label": "Oosterwolde",
                "label_ar": "أوستروولد",
                "label_fr": "Oosterwolde"
            },
            {
                "value": 51029,
                "label": "Oosternijkerk",
                "label_ar": "أوسترنيكيرك",
                "label_fr": "Oosternijkerk"
            },
            {
                "value": 51477,
                "label": "Boekel",
                "label_ar": "بويكل",
                "label_fr": "Boekel"
            },
            {
                "value": 50717,
                "label": "Tholen",
                "label_ar": "ثولين",
                "label_fr": "Tholen"
            },
            {
                "value": 50327,
                "label": "Asten",
                "label_ar": "أستين",
                "label_fr": "Asten"
            },
            {
                "value": 50307,
                "label": "Zoetermeer",
                "label_ar": "زوترمير",
                "label_fr": "Zoetermeer"
            },
            {
                "value": 51307,
                "label": "Oudemolen",
                "label_ar": "اودمولين",
                "label_fr": "Oudemolen"
            },
            {
                "value": 51350,
                "label": "Escharen",
                "label_ar": "إشارين",
                "label_fr": "Escharen"
            },
            {
                "value": 50376,
                "label": "Bleiswijk",
                "label_ar": "بليسويك",
                "label_fr": "Bleiswijk"
            },
            {
                "value": 50577,
                "label": "Schipluiden",
                "label_ar": "شبلويدن",
                "label_fr": "Schipluiden"
            },
            {
                "value": 51364,
                "label": "Merselo",
                "label_ar": "ميرسيلو",
                "label_fr": "Merselo"
            },
            {
                "value": 50451,
                "label": "Wormerveer",
                "label_ar": "Wormerveer",
                "label_fr": "Wormerveer"
            },
            {
                "value": 50836,
                "label": "Borne",
                "label_ar": "تحمل",
                "label_fr": "Borne"
            },
            {
                "value": 51045,
                "label": "Lunteren",
                "label_ar": "لونتيرين",
                "label_fr": "Lunteren"
            },
            {
                "value": 50959,
                "label": "Ellecom",
                "label_ar": "إليكوم",
                "label_fr": "Ellecom"
            },
            {
                "value": 50963,
                "label": "Goutum",
                "label_ar": "النقرس",
                "label_fr": "Goutum"
            },
            {
                "value": 51480,
                "label": "Nederasselt",
                "label_ar": "نديراسلت",
                "label_fr": "Nederasselt"
            },
            {
                "value": 51586,
                "label": "Garsthuizen",
                "label_ar": "جارثويزن",
                "label_fr": "Garsthuizen"
            },
            {
                "value": 51178,
                "label": "Nieuw-Buinen",
                "label_ar": "نيو بوينين",
                "label_fr": "Nieuw-Buinen"
            },
            {
                "value": 50355,
                "label": "Drachten",
                "label_ar": "دراشتن",
                "label_fr": "Drachten"
            },
            {
                "value": 50857,
                "label": "Kantens",
                "label_ar": "كانتينز",
                "label_fr": "Kantens"
            },
            {
                "value": 51378,
                "label": "Molenschot",
                "label_ar": "مولينسكوت",
                "label_fr": "Molenschot"
            },
            {
                "value": 50595,
                "label": "Velp",
                "label_ar": "فيلب",
                "label_fr": "Velp"
            },
            {
                "value": 50424,
                "label": "Tiel",
                "label_ar": "تيل",
                "label_fr": "Tiel"
            },
            {
                "value": 51094,
                "label": "Muntendam",
                "label_ar": "مونتندام",
                "label_fr": "Muntendam"
            },
            {
                "value": 50616,
                "label": "Goes",
                "label_ar": "يذهب",
                "label_fr": "Va"
            },
            {
                "value": 51637,
                "label": "Diever",
                "label_ar": "ديفر",
                "label_fr": "Diever"
            },
            {
                "value": 50546,
                "label": "Bergen op Zoom",
                "label_ar": "بيرغن أوب زووم",
                "label_fr": "Bergen op Zoom"
            },
            {
                "value": 51081,
                "label": "Berkenwoude",
                "label_ar": "Berkenwoude",
                "label_fr": "Berkenwoude"
            },
            {
                "value": 51574,
                "label": "Tienhoven",
                "label_ar": "تينهوفن",
                "label_fr": "Tienhoven"
            },
            {
                "value": 50648,
                "label": "Doesburg",
                "label_ar": "دوسبرغ",
                "label_fr": "Doesburg"
            },
            {
                "value": 50354,
                "label": "Strijensas",
                "label_ar": "ستريجينساس",
                "label_fr": "Strijensas"
            },
            {
                "value": 51170,
                "label": "Sprundel",
                "label_ar": "سبروندل",
                "label_fr": "Sprundel"
            },
            {
                "value": 51214,
                "label": "Oostkapelle",
                "label_ar": "Oostkapelle",
                "label_fr": "Oostkapelle"
            },
            {
                "value": 51273,
                "label": "Gasselternijveen",
                "label_ar": "جاسيلترنيجفين",
                "label_fr": "Gasselternijveen"
            },
            {
                "value": 51300,
                "label": "Hulsberg",
                "label_ar": "هلسبرغ",
                "label_fr": "Hulsberg"
            },
            {
                "value": 51065,
                "label": "Losser",
                "label_ar": "الخاسر",
                "label_fr": "Perte"
            },
            {
                "value": 50992,
                "label": "Kootstertille",
                "label_ar": "Kootstertille",
                "label_fr": "Kootstertille"
            },
            {
                "value": 51412,
                "label": "Valthermond",
                "label_ar": "فالثيرموند",
                "label_fr": "Valthermond"
            },
            {
                "value": 50333,
                "label": "Ede",
                "label_ar": "إيدي",
                "label_fr": "Ede"
            },
            {
                "value": 50901,
                "label": "Driebruggen",
                "label_ar": "دريبروجين",
                "label_fr": "Driebruggen"
            },
            {
                "value": 51053,
                "label": "Delden",
                "label_ar": "ديلدن",
                "label_fr": "Delden"
            },
            {
                "value": 50580,
                "label": "Harderwijk",
                "label_ar": "هاردرفيك",
                "label_fr": "Harderwijk"
            },
            {
                "value": 50934,
                "label": "Garyp",
                "label_ar": "جاريب",
                "label_fr": "Garyp"
            },
            {
                "value": 50356,
                "label": "'s-Gravendeel",
                "label_ar": "'s-Gravendeel",
                "label_fr": "'s-Gravendeel"
            },
            {
                "value": 50415,
                "label": "Bussum",
                "label_ar": "بوسوم",
                "label_fr": "Bussum"
            },
            {
                "value": 50800,
                "label": "Beverwijk",
                "label_ar": "بيفيرفايك",
                "label_fr": "Beverwijk"
            },
            {
                "value": 51514,
                "label": "Ilpendam",
                "label_ar": "إيلبندام",
                "label_fr": "Ilpendam"
            },
            {
                "value": 50704,
                "label": "Hoek",
                "label_ar": "هوك",
                "label_fr": "Hoek"
            },
            {
                "value": 51365,
                "label": "Bergen",
                "label_ar": "بيرغن",
                "label_fr": "Bergen"
            },
            {
                "value": 51647,
                "label": "Budel-Schoot",
                "label_ar": "بودل شوت",
                "label_fr": "Budel-Schoot"
            },
            {
                "value": 51356,
                "label": "Terblijt",
                "label_ar": "تربليجت",
                "label_fr": "Terblijt"
            },
            {
                "value": 51779,
                "label": "Ooij",
                "label_ar": "وويج",
                "label_fr": "Ooij"
            },
            {
                "value": 51681,
                "label": "Schin op Geul",
                "label_ar": "Schin op Geul",
                "label_fr": "Schin op Geul"
            },
            {
                "value": 50957,
                "label": "Gameren",
                "label_ar": "Gameren",
                "label_fr": "Gameren"
            },
            {
                "value": 51339,
                "label": "Well",
                "label_ar": "حسنا",
                "label_fr": "bien"
            },
            {
                "value": 51439,
                "label": "Oterleek",
                "label_ar": "Oterleek",
                "label_fr": "Oterleek"
            },
            {
                "value": 51389,
                "label": "Hengelo",
                "label_ar": "هينجيلو",
                "label_fr": "Hengelo"
            },
            {
                "value": 51502,
                "label": "Neer",
                "label_ar": "نير",
                "label_fr": "Neer"
            },
            {
                "value": 51340,
                "label": "Groeningen",
                "label_ar": "جرونينغين",
                "label_fr": "Groeningen"
            },
            {
                "value": 51758,
                "label": "Blokzijl",
                "label_ar": "بلوكزيل",
                "label_fr": "Blokzijl"
            },
            {
                "value": 50526,
                "label": "Zaandijk",
                "label_ar": "زانديجك",
                "label_fr": "Zaandijk"
            },
            {
                "value": 51413,
                "label": "Papekop",
                "label_ar": "بابيكوب",
                "label_fr": "Papekop"
            },
            {
                "value": 51188,
                "label": "Dreumel",
                "label_ar": "دريوميل",
                "label_fr": "Dreumel"
            },
            {
                "value": 50559,
                "label": "Haarlemmerliede",
                "label_ar": "هارلميرليد",
                "label_fr": "Haarlemmerliede"
            },
            {
                "value": 50583,
                "label": "Kudelstaart",
                "label_ar": "Kudelstaart",
                "label_fr": "Kudelstaart"
            },
            {
                "value": 50893,
                "label": "Aalden",
                "label_ar": "آلدن",
                "label_fr": "Aalden"
            },
            {
                "value": 51044,
                "label": "Veen",
                "label_ar": "فين",
                "label_fr": "Veen"
            },
            {
                "value": 51149,
                "label": "Hijken",
                "label_ar": "هيجين",
                "label_fr": "Hijken"
            },
            {
                "value": 51503,
                "label": "Kootwijkerbroek",
                "label_ar": "Kootwijkerbroek",
                "label_fr": "Kootwijkerbroek"
            },
            {
                "value": 50492,
                "label": "Witteveen",
                "label_ar": "ويتيفين",
                "label_fr": "Witteveen"
            },
            {
                "value": 51761,
                "label": "Warmenhuizen",
                "label_ar": "وارمنهويزين",
                "label_fr": "Warmenhuizen"
            },
            {
                "value": 50703,
                "label": "Terneuzen",
                "label_ar": "تيرنوزن",
                "label_fr": "Terneuzen"
            },
            {
                "value": 50862,
                "label": "Oldemarkt",
                "label_ar": "أولدماركت",
                "label_fr": "Oldemarkt"
            },
            {
                "value": 50311,
                "label": "Almere",
                "label_ar": "المير",
                "label_fr": "Almere"
            },
            {
                "value": 51002,
                "label": "Dedemsvaart",
                "label_ar": "ديدمسفارت",
                "label_fr": "Dedemsvaart"
            },
            {
                "value": 51348,
                "label": "Haps",
                "label_ar": "المصائب",
                "label_fr": "Haps"
            },
            {
                "value": 51673,
                "label": "Buitenkaag",
                "label_ar": "Buitenkaag",
                "label_fr": "Buitenkaag"
            },
            {
                "value": 51220,
                "label": "Kloetinge",
                "label_ar": "كلويتنج",
                "label_fr": "Kloetinge"
            },
            {
                "value": 51039,
                "label": "Melick",
                "label_ar": "ميليك",
                "label_fr": "Melick"
            },
            {
                "value": 51691,
                "label": "Amstelhoek",
                "label_ar": "أمستلهوك",
                "label_fr": "Amstelhoek"
            },
            {
                "value": 50329,
                "label": "Bakel",
                "label_ar": "باكل",
                "label_fr": "Bakel"
            },
            {
                "value": 50313,
                "label": "Alphen aan den Rijn",
                "label_ar": "ألفين آن دن راين",
                "label_fr": "Alphen aan den Rijn"
            },
            {
                "value": 50824,
                "label": "Sint Odilienberg",
                "label_ar": "سينت أوديلينبيرج",
                "label_fr": "Sint Odilienberg"
            },
            {
                "value": 51469,
                "label": "Driemond",
                "label_ar": "ديريموند",
                "label_fr": "Driemond"
            },
            {
                "value": 50337,
                "label": "Bleskensgraaf",
                "label_ar": "Bleskensgraaf",
                "label_fr": "Bleskensgraaf"
            },
            {
                "value": 50677,
                "label": "Soerendonk",
                "label_ar": "Soerendonk",
                "label_fr": "Soerendonk"
            },
            {
                "value": 51013,
                "label": "Hoogezand",
                "label_ar": "Hoogezand",
                "label_fr": "Hoogezand"
            },
            {
                "value": 51063,
                "label": "Vijfhuizen",
                "label_ar": "فيجفويزن",
                "label_fr": "Vijfhuizen"
            },
            {
                "value": 50689,
                "label": "Hallum",
                "label_ar": "حلوم",
                "label_fr": "Hallum"
            },
            {
                "value": 50517,
                "label": "Ewijk",
                "label_ar": "يويجك",
                "label_fr": "Ewijk"
            },
            {
                "value": 50955,
                "label": "Sumar",
                "label_ar": "سومار",
                "label_fr": "Sumar"
            },
            {
                "value": 50984,
                "label": "Colmschate",
                "label_ar": "كولمسكات",
                "label_fr": "Colmschate"
            },
            {
                "value": 51698,
                "label": "Keldonk",
                "label_ar": "كيلدونك",
                "label_fr": "Keldonk"
            },
            {
                "value": 50642,
                "label": "Epse",
                "label_ar": "epse",
                "label_fr": "Epse"
            },
            {
                "value": 50502,
                "label": "Naaldwijk",
                "label_ar": "نالدويك",
                "label_fr": "Naaldwijk"
            },
            {
                "value": 51159,
                "label": "Roggel",
                "label_ar": "روجيل",
                "label_fr": "Roggel"
            },
            {
                "value": 51638,
                "label": "Den Bommel",
                "label_ar": "دن بوميل",
                "label_fr": "Den Bommel"
            },
            {
                "value": 50913,
                "label": "Lichtenvoorde",
                "label_ar": "ليشتنفورد",
                "label_fr": "Lichtenvoorde"
            },
            {
                "value": 50696,
                "label": "Vierhouten",
                "label_ar": "فيرهوتين",
                "label_fr": "Vierhouten"
            },
            {
                "value": 50997,
                "label": "Dwingeloo",
                "label_ar": "دوينجيلو",
                "label_fr": "Dwingeloo"
            },
            {
                "value": 51035,
                "label": "Halsteren",
                "label_ar": "هالستيرن",
                "label_fr": "Halsteren"
            },
            {
                "value": 50380,
                "label": "Waardenburg",
                "label_ar": "Waardenburg",
                "label_fr": "Waardenburg"
            },
            {
                "value": 51563,
                "label": "Rooth",
                "label_ar": "روث",
                "label_fr": "Rooth"
            },
            {
                "value": 51571,
                "label": "Lage Zwaluwe",
                "label_ar": "لاج زوالوي",
                "label_fr": "Lage Zwaluwe"
            },
            {
                "value": 50623,
                "label": "Urmond",
                "label_ar": "أورموند",
                "label_fr": "Urmond"
            },
            {
                "value": 50472,
                "label": "Mijdrecht",
                "label_ar": "مجدريخت",
                "label_fr": "Mijdrecht"
            },
            {
                "value": 50563,
                "label": "De Bilt",
                "label_ar": "دي بيلت",
                "label_fr": "De Bilt"
            },
            {
                "value": 50481,
                "label": "Roermond",
                "label_ar": "رويرموند",
                "label_fr": "Roermond"
            },
            {
                "value": 50921,
                "label": "Jubbega",
                "label_ar": "جوبيجا",
                "label_fr": "Jubbega"
            },
            {
                "value": 51746,
                "label": "Wons",
                "label_ar": "وونس",
                "label_fr": "Gagné"
            },
            {
                "value": 50522,
                "label": "Bergschenhoek",
                "label_ar": "بيرجشينهوك",
                "label_fr": "Bergschenhoek"
            },
            {
                "value": 51568,
                "label": "Woubrugge",
                "label_ar": "ووبروج",
                "label_fr": "Woubrugge"
            },
            {
                "value": 51649,
                "label": "Nieuweschoot",
                "label_ar": "نيوشوت",
                "label_fr": "Nieuweschoot"
            },
            {
                "value": 51058,
                "label": "Erica",
                "label_ar": "إيريكا",
                "label_fr": "Erica"
            },
            {
                "value": 51720,
                "label": "Wijnandsrade",
                "label_ar": "Wijnandsrade",
                "label_fr": "Wijnandsrade"
            },
            {
                "value": 50496,
                "label": "Schinnen",
                "label_ar": "شينين",
                "label_fr": "Schinnen"
            },
            {
                "value": 51158,
                "label": "Wilnis",
                "label_ar": "ويلنيس",
                "label_fr": "Wilnis"
            },
            {
                "value": 50698,
                "label": "Coevorden",
                "label_ar": "كوفوردين",
                "label_fr": "Coevorden"
            },
            {
                "value": 50444,
                "label": "Lemiers",
                "label_ar": "ليمييرز",
                "label_fr": "Lemiers"
            },
            {
                "value": 50870,
                "label": "Ens",
                "label_ar": "إن",
                "label_fr": "Ens"
            },
            {
                "value": 50880,
                "label": "Maarssen",
                "label_ar": "مارسن",
                "label_fr": "Maarssen"
            },
            {
                "value": 51666,
                "label": "Zuidzande",
                "label_ar": "زويدزاندي",
                "label_fr": "Zuidzande"
            },
            {
                "value": 50900,
                "label": "Rijnsburg",
                "label_ar": "رينسبرج",
                "label_fr": "Rijnsburg"
            },
            {
                "value": 51332,
                "label": "Panningen",
                "label_ar": "بانينغين",
                "label_fr": "Panningen"
            },
            {
                "value": 51489,
                "label": "Bergentheim",
                "label_ar": "بيرجينثيم",
                "label_fr": "Bergentheim"
            },
            {
                "value": 50933,
                "label": "Noardburgum",
                "label_ar": "Noardburgum",
                "label_fr": "Noardburgum"
            },
            {
                "value": 50620,
                "label": "Velsen",
                "label_ar": "فيلسن",
                "label_fr": "Velsen"
            },
            {
                "value": 50465,
                "label": "Schijndel",
                "label_ar": "شيجنديل",
                "label_fr": "Schijndel"
            },
            {
                "value": 50848,
                "label": "Vilt",
                "label_ar": "فيلت",
                "label_fr": "Vilt"
            },
            {
                "value": 51059,
                "label": "Lottum",
                "label_ar": "لوتوم",
                "label_fr": "Lottum"
            },
            {
                "value": 51535,
                "label": "Ferwert",
                "label_ar": "فيرويرت",
                "label_fr": "Ferwert"
            },
            {
                "value": 50617,
                "label": "Lisse",
                "label_ar": "ليس",
                "label_fr": "Lisse"
            },
            {
                "value": 50888,
                "label": "Zaltbommel",
                "label_ar": "زالتبوميل",
                "label_fr": "Zaltbommel"
            },
            {
                "value": 51163,
                "label": "Poortvliet",
                "label_ar": "Poortvliet",
                "label_fr": "Poortvliet"
            },
            {
                "value": 51237,
                "label": "Lopik",
                "label_ar": "لوبيك",
                "label_fr": "Lopik"
            },
            {
                "value": 51527,
                "label": "'t Harde",
                "label_ar": "ر هاردي",
                "label_fr": "'t Harde"
            },
            {
                "value": 51594,
                "label": "Pieterzijl",
                "label_ar": "بيترسجل",
                "label_fr": "Pieterzijl"
            },
            {
                "value": 50521,
                "label": "Dirksland",
                "label_ar": "ديركسلاند",
                "label_fr": "Dirksland"
            },
            {
                "value": 50805,
                "label": "Dongen",
                "label_ar": "دونجن",
                "label_fr": "Dongen"
            },
            {
                "value": 51763,
                "label": "Sleen",
                "label_ar": "سلين",
                "label_fr": "Sleen"
            },
            {
                "value": 50381,
                "label": "Woerden",
                "label_ar": "ووردن",
                "label_fr": "Woerden"
            },
            {
                "value": 50456,
                "label": "De Lutte",
                "label_ar": "دي لوت",
                "label_fr": "De Lutte"
            },
            {
                "value": 50811,
                "label": "Zuidoostbeemster",
                "label_ar": "زويدوستبيمستر",
                "label_fr": "Zuidoostbeemster"
            },
            {
                "value": 51186,
                "label": "Peize",
                "label_ar": "بييز",
                "label_fr": "Peize"
            },
            {
                "value": 51617,
                "label": "Zuidwolde",
                "label_ar": "Zuidwolde",
                "label_fr": "Zuidwolde"
            },
            {
                "value": 51537,
                "label": "Callantsoog",
                "label_ar": "كالانتسوغ",
                "label_fr": "Callantsoog"
            },
            {
                "value": 50520,
                "label": "Duivendrecht",
                "label_ar": "Duivendrecht",
                "label_fr": "Duivendrecht"
            },
            {
                "value": 50315,
                "label": "Leeuwarden",
                "label_ar": "ليوواردن",
                "label_fr": "Leeuwarden"
            },
            {
                "value": 51593,
                "label": "Valthe",
                "label_ar": "فالثي",
                "label_fr": "Valthe"
            },
            {
                "value": 50483,
                "label": "Renesse",
                "label_ar": "رينيسي",
                "label_fr": "Renesse"
            },
            {
                "value": 50468,
                "label": "De Westereen",
                "label_ar": "دي ويسترين",
                "label_fr": "De Westereen"
            },
            {
                "value": 50946,
                "label": "Britsum",
                "label_ar": "بريتسوم",
                "label_fr": "Britsum"
            },
            {
                "value": 51164,
                "label": "Helenaveen",
                "label_ar": "هيلينفين",
                "label_fr": "Helenaveen"
            },
            {
                "value": 51279,
                "label": "Koudekerk aan den Rijn",
                "label_ar": "Koudekerk aan den Rijn",
                "label_fr": "Koudekerk aan den Rijn"
            },
            {
                "value": 50547,
                "label": "Kaatsheuvel",
                "label_ar": "كاتشيفيل",
                "label_fr": "Kaatsheuvel"
            },
            {
                "value": 51266,
                "label": "Maasbommel",
                "label_ar": "ماسبوميل",
                "label_fr": "Maasbommel"
            },
            {
                "value": 51393,
                "label": "Varsseveld",
                "label_ar": "فارسفيلد",
                "label_fr": "Varsseveld"
            },
            {
                "value": 50379,
                "label": "Heerhugowaard",
                "label_ar": "هيرهوجوفارد",
                "label_fr": "Heerhugowaard"
            },
            {
                "value": 50533,
                "label": "Tilburg",
                "label_ar": "تيلبورغ",
                "label_fr": "Tilburg"
            },
            {
                "value": 51089,
                "label": "Bierum",
                "label_ar": "بيروم",
                "label_fr": "Bierum"
            },
            {
                "value": 51095,
                "label": "Noordbroek",
                "label_ar": "نوردبروك",
                "label_fr": "Noordbroek"
            },
            {
                "value": 51207,
                "label": "Wissenkerke",
                "label_ar": "Wissenkerke",
                "label_fr": "Wissenkerke"
            },
            {
                "value": 50907,
                "label": "Zelhem",
                "label_ar": "زلهم",
                "label_fr": "Zelhem"
            },
            {
                "value": 50321,
                "label": "Sint-Oedenrode",
                "label_ar": "سينت أودينرود",
                "label_fr": "Sint-Oedenrode"
            },
            {
                "value": 50470,
                "label": "Buren",
                "label_ar": "بورين",
                "label_fr": "Buren"
            },
            {
                "value": 50780,
                "label": "Simpelveld",
                "label_ar": "سيمبلفيلد",
                "label_fr": "Simpelveld"
            },
            {
                "value": 50613,
                "label": "Beneden-Leeuwen",
                "label_ar": "بنيدن ليوين",
                "label_fr": "Beneden-Leeuwen"
            },
            {
                "value": 50858,
                "label": "Nieuwolda",
                "label_ar": "نيولدا",
                "label_fr": "Nieuwolda"
            },
            {
                "value": 51019,
                "label": "Genemuiden",
                "label_ar": "جينيمودن",
                "label_fr": "Genemuiden"
            },
            {
                "value": 51741,
                "label": "Echten",
                "label_ar": "إختن",
                "label_fr": "Echten"
            },
            {
                "value": 50855,
                "label": "Volkel",
                "label_ar": "فولكل",
                "label_fr": "Volkel"
            },
            {
                "value": 50312,
                "label": "Enschede",
                "label_ar": "انشيده",
                "label_fr": "Enschede"
            },
            {
                "value": 51023,
                "label": "Velsen-Noord",
                "label_ar": "فيلسن نورد",
                "label_fr": "Velsen-Noord"
            },
            {
                "value": 51031,
                "label": "Vogelenzang",
                "label_ar": "فوجلينزانغ",
                "label_fr": "Vogelenzang"
            },
            {
                "value": 50542,
                "label": "Zevenbergen",
                "label_ar": "زيفينبيرجين",
                "label_fr": "Zevenbergen"
            },
            {
                "value": 51143,
                "label": "Oosterzee",
                "label_ar": "اوسترزي",
                "label_fr": "Oosterzee"
            },
            {
                "value": 51073,
                "label": "'t Zand",
                "label_ar": "ر زاند",
                "label_fr": "'t Zand"
            },
            {
                "value": 50663,
                "label": "Hoogvliet",
                "label_ar": "هوجفليت",
                "label_fr": "Hoogvliet"
            },
            {
                "value": 50523,
                "label": "'s-Graveland",
                "label_ar": "'s-Graveland",
                "label_fr": "'s-Graveland"
            },
            {
                "value": 50829,
                "label": "Nieuw-Lekkerland",
                "label_ar": "نيو ليكيرلاند",
                "label_fr": "Nieuw-Lekkerland"
            },
            {
                "value": 51368,
                "label": "Blitterswijck",
                "label_ar": "بليترسويجك",
                "label_fr": "Blitterswijck"
            },
            {
                "value": 50441,
                "label": "Kinderdijk",
                "label_ar": "Kinderdijk",
                "label_fr": "Kinderdijk"
            },
            {
                "value": 50844,
                "label": "Swalmen",
                "label_ar": "Swalmen",
                "label_fr": "Swalmen"
            },
            {
                "value": 51732,
                "label": "Wijnaldum",
                "label_ar": "Wijnaldum",
                "label_fr": "Wijnaldum"
            },
            {
                "value": 50681,
                "label": "Heinenoord",
                "label_ar": "هاينورد",
                "label_fr": "Heinenoord"
            },
            {
                "value": 50408,
                "label": "Liessel",
                "label_ar": "ليسيل",
                "label_fr": "Liessel"
            },
            {
                "value": 50823,
                "label": "Kerk-Avezaath",
                "label_ar": "Kerk-Avezaath",
                "label_fr": "Kerk-Avezaath"
            },
            {
                "value": 51085,
                "label": "Abbekerk",
                "label_ar": "ابكيرك",
                "label_fr": "Abbekerk"
            },
            {
                "value": 50447,
                "label": "Ouddorp",
                "label_ar": "أودورب",
                "label_fr": "Ouddorp"
            },
            {
                "value": 51235,
                "label": "Meeuwen",
                "label_ar": "Meeuwen",
                "label_fr": "Meeuwen"
            },
            {
                "value": 50314,
                "label": "Dordrecht",
                "label_ar": "دوردريخت",
                "label_fr": "Dordrecht"
            },
            {
                "value": 50377,
                "label": "Hengelo",
                "label_ar": "هينجيلو",
                "label_fr": "Hengelo"
            },
            {
                "value": 50902,
                "label": "Ravenstein",
                "label_ar": "رافنشتاين",
                "label_fr": "Ravenstein"
            },
            {
                "value": 51165,
                "label": "De Kwakel",
                "label_ar": "دي كواكل",
                "label_fr": "De Kwakel"
            },
            {
                "value": 51372,
                "label": "Heijen",
                "label_ar": "هيجين",
                "label_fr": "Heijen"
            },
            {
                "value": 50750,
                "label": "Rhenen",
                "label_ar": "رينين",
                "label_fr": "Rhenen"
            },
            {
                "value": 50457,
                "label": "Nieuwerkerk aan den IJssel",
                "label_ar": "Nieuwerkerk aan den IJssel",
                "label_fr": "Nieuwerkerk aan den IJssel"
            },
            {
                "value": 50535,
                "label": "Delft",
                "label_ar": "دلفت",
                "label_fr": "Delft"
            },
            {
                "value": 51289,
                "label": "De Moer",
                "label_ar": "دي موير",
                "label_fr": "De Moer"
            },
            {
                "value": 51355,
                "label": "Wijlre",
                "label_ar": "Wijlre",
                "label_fr": "Wijlre"
            },
            {
                "value": 50422,
                "label": "Westzaan",
                "label_ar": "ويستزان",
                "label_fr": "Westzaan"
            },
            {
                "value": 50460,
                "label": "Baarn",
                "label_ar": "بارن",
                "label_fr": "Baarn"
            },
            {
                "value": 50649,
                "label": "Rixtel",
                "label_ar": "ريكستيل",
                "label_fr": "Rixtel"
            },
            {
                "value": 51189,
                "label": "Rivierenwijk",
                "label_ar": "ريفيرينفايك",
                "label_fr": "Rivierenwijk"
            },
            {
                "value": 51161,
                "label": "Spaarndam",
                "label_ar": "سبارندام",
                "label_fr": "Spaarndam"
            },
            {
                "value": 51778,
                "label": "Lewenborg",
                "label_ar": "لوينبورغ",
                "label_fr": "Lewenborg"
            },
            {
                "value": 50736,
                "label": "Bosch en Duin",
                "label_ar": "بوش أون دوين",
                "label_fr": "Bosch en Duin"
            },
            {
                "value": 50911,
                "label": "Bunschoten",
                "label_ar": "بونشوتين",
                "label_fr": "Bunschoten"
            },
            {
                "value": 50884,
                "label": "De Horst",
                "label_ar": "دي هورست",
                "label_fr": "De Horst"
            },
            {
                "value": 51321,
                "label": "Maasbracht",
                "label_ar": "ماسبراخت",
                "label_fr": "Maasbracht"
            },
            {
                "value": 50732,
                "label": "Capelle aan den IJssel",
                "label_ar": "Capelle aan den IJssel",
                "label_fr": "Capelle aan den IJssel"
            },
            {
                "value": 50430,
                "label": "Heythuysen",
                "label_ar": "هيثويسين",
                "label_fr": "Heythuysen"
            },
            {
                "value": 50867,
                "label": "Maarn",
                "label_ar": "معارن",
                "label_fr": "Maarn"
            },
            {
                "value": 51659,
                "label": "Laag-Soeren",
                "label_ar": "لاج-سورين",
                "label_fr": "Laag-Soeren"
            },
            {
                "value": 50437,
                "label": "Petten",
                "label_ar": "بيتن",
                "label_fr": "Petten"
            },
            {
                "value": 50323,
                "label": "Groningen",
                "label_ar": "جرونينجن",
                "label_fr": "Groningen"
            },
            {
                "value": 50518,
                "label": "Molenhoek",
                "label_ar": "مولينهوك",
                "label_fr": "Molenhoek"
            },
            {
                "value": 51204,
                "label": "Hoofdplaat",
                "label_ar": "هوفدبلات",
                "label_fr": "Hoofdplaat"
            },
            {
                "value": 51728,
                "label": "De Falom",
                "label_ar": "دي فالوم",
                "label_fr": "De Falom"
            },
            {
                "value": 51662,
                "label": "Venhorst",
                "label_ar": "فينهورست",
                "label_fr": "Venhorst"
            },
            {
                "value": 50328,
                "label": "Gemert",
                "label_ar": "جيميرت",
                "label_fr": "Gemert"
            },
            {
                "value": 50491,
                "label": "Bodegraven",
                "label_ar": "بوديجرافن",
                "label_fr": "Bodegraven"
            },
            {
                "value": 51232,
                "label": "Teteringen",
                "label_ar": "تيترنغن",
                "label_fr": "Teteringen"
            },
            {
                "value": 50797,
                "label": "Buren",
                "label_ar": "بورين",
                "label_fr": "Buren"
            },
            {
                "value": 51628,
                "label": "Scheemda",
                "label_ar": "شيمدة",
                "label_fr": "Scheemda"
            },
            {
                "value": 51507,
                "label": "Gorredijk",
                "label_ar": "جوريديك",
                "label_fr": "Gorredijk"
            },
            {
                "value": 50712,
                "label": "Clinge",
                "label_ar": "تتشبث",
                "label_fr": "Clinge"
            },
            {
                "value": 51564,
                "label": "Wierum",
                "label_ar": "ويروم",
                "label_fr": "Wierum"
            },
            {
                "value": 50386,
                "label": "Emmeloord",
                "label_ar": "Emmeloord",
                "label_fr": "Emmeloord"
            },
            {
                "value": 50790,
                "label": "Smilde",
                "label_ar": "سميلد",
                "label_fr": "Smilde"
            },
            {
                "value": 50540,
                "label": "Delfzijl",
                "label_ar": "دلفزيل",
                "label_fr": "Delfzijl"
            },
            {
                "value": 50972,
                "label": "Sterksel",
                "label_ar": "ستيركسيل",
                "label_fr": "Sterksel"
            },
            {
                "value": 51390,
                "label": "Gees",
                "label_ar": "جيز",
                "label_fr": "Gees"
            },
            {
                "value": 50443,
                "label": "Dalfsen",
                "label_ar": "دالفسن",
                "label_fr": "Dalfsen"
            },
            {
                "value": 50469,
                "label": "Kollum",
                "label_ar": "كلوم",
                "label_fr": "Kollum"
            },
            {
                "value": 51243,
                "label": "Harmelen",
                "label_ar": "هارملين",
                "label_fr": "Harmelen"
            },
            {
                "value": 50874,
                "label": "Brielle",
                "label_ar": "بريل",
                "label_fr": "Brielle"
            },
            {
                "value": 50728,
                "label": "Schore",
                "label_ar": "شور",
                "label_fr": "Schore"
            },
            {
                "value": 50435,
                "label": "Wassenaar",
                "label_ar": "فاسينار",
                "label_fr": "Wassenaar"
            },
            {
                "value": 51322,
                "label": "Reuver",
                "label_ar": "ريفر",
                "label_fr": "Reuver"
            },
            {
                "value": 50578,
                "label": "Aalsmeer",
                "label_ar": "آلسمير",
                "label_fr": "Aalsmeer"
            },
            {
                "value": 51317,
                "label": "Herkenbosch",
                "label_ar": "هيركينبوش",
                "label_fr": "Herkenbosch"
            },
            {
                "value": 51174,
                "label": "Bunde",
                "label_ar": "بوندي",
                "label_fr": "Bunde"
            },
            {
                "value": 51498,
                "label": "Emst",
                "label_ar": "إمست",
                "label_fr": "Emst"
            },
            {
                "value": 50729,
                "label": "Wemeldinge",
                "label_ar": "ويلدينج",
                "label_fr": "Wemeldinge"
            },
            {
                "value": 51671,
                "label": "Makkum",
                "label_ar": "مككوم",
                "label_fr": "Makkum"
            },
            {
                "value": 50493,
                "label": "Badhoevedorp",
                "label_ar": "بادوفيدورب",
                "label_fr": "Badhoevedorp"
            },
            {
                "value": 51146,
                "label": "Hoogkerk",
                "label_ar": "Hoogkerk",
                "label_fr": "Hoogkerk"
            },
            {
                "value": 51730,
                "label": "Baarland",
                "label_ar": "بارلاند",
                "label_fr": "Baarland"
            },
            {
                "value": 51539,
                "label": "Cadzand",
                "label_ar": "Cadzand",
                "label_fr": "Cadzand"
            },
            {
                "value": 50675,
                "label": "Aalten",
                "label_ar": "آلتن",
                "label_fr": "Aalten"
            },
            {
                "value": 51683,
                "label": "Den Deijl",
                "label_ar": "دن ديجل",
                "label_fr": "Den Deijl"
            },
            {
                "value": 51394,
                "label": "Maarssenbroek",
                "label_ar": "Maarssenbroek",
                "label_fr": "Maarssenbroek"
            },
            {
                "value": 51512,
                "label": "Enspijk",
                "label_ar": "Enspijk",
                "label_fr": "Enspijk"
            },
            {
                "value": 50702,
                "label": "Zaamslag",
                "label_ar": "زامسلاغ",
                "label_fr": "Zaamslag"
            },
            {
                "value": 51236,
                "label": "Noordeloos",
                "label_ar": "نوردلوس",
                "label_fr": "Noordeloos"
            },
            {
                "value": 51616,
                "label": "Stuifzand",
                "label_ar": "Stuifzand",
                "label_fr": "Stuifzand"
            },
            {
                "value": 51700,
                "label": "Limmel",
                "label_ar": "ليميل",
                "label_fr": "Limmel"
            },
            {
                "value": 51735,
                "label": "Oldeberkoop",
                "label_ar": "أولديبيركوب",
                "label_fr": "Oldeberkoop"
            },
            {
                "value": 50795,
                "label": "Zetten",
                "label_ar": "زيتن",
                "label_fr": "Zetten"
            },
            {
                "value": 51558,
                "label": "Aerdt",
                "label_ar": "ايردت",
                "label_fr": "Aerdt"
            },
            {
                "value": 50603,
                "label": "Meppel",
                "label_ar": "ميبل",
                "label_fr": "Meppel"
            },
            {
                "value": 50971,
                "label": "Stellendam",
                "label_ar": "ستيليندام",
                "label_fr": "Stellendam"
            },
            {
                "value": 50528,
                "label": "Zwijndrecht",
                "label_ar": "Zwijndrecht",
                "label_fr": "Zwijndrecht"
            },
            {
                "value": 51179,
                "label": "Elsloo",
                "label_ar": "Elsloo",
                "label_fr": "Elsloo"
            },
            {
                "value": 50316,
                "label": "Rotterdam",
                "label_ar": "روتردام",
                "label_fr": "Rotterdam"
            },
            {
                "value": 50519,
                "label": "Vragender",
                "label_ar": "فراجندر",
                "label_fr": "Vragender"
            },
            {
                "value": 50845,
                "label": "Goor",
                "label_ar": "جوور",
                "label_fr": "Goor"
            },
            {
                "value": 51422,
                "label": "Hazerswoude-Rijndijk",
                "label_ar": "Hazerswoude-Rijndijk",
                "label_fr": "Hazerswoude-Rijndijk"
            },
            {
                "value": 51428,
                "label": "Dorst",
                "label_ar": "دورست",
                "label_fr": "Dorst"
            },
            {
                "value": 50372,
                "label": "Deest",
                "label_ar": "إله",
                "label_fr": "Deest"
            },
            {
                "value": 51218,
                "label": "Sas van Gent",
                "label_ar": "ساس فان جينت",
                "label_fr": "Sas van Gent"
            },
            {
                "value": 51272,
                "label": "Holthees",
                "label_ar": "هولثيز",
                "label_fr": "Holthees"
            },
            {
                "value": 51173,
                "label": "Liempde",
                "label_ar": "ليمبدي",
                "label_fr": "Liempde"
            },
            {
                "value": 51760,
                "label": "Sint Maarten",
                "label_ar": "سينت مارتن",
                "label_fr": "Sint Maarten"
            },
            {
                "value": 51738,
                "label": "Sintjohannesga",
                "label_ar": "Sintjohannesga",
                "label_fr": "Sintjohannesga"
            },
            {
                "value": 50421,
                "label": "Valburg",
                "label_ar": "فالبورغ",
                "label_fr": "Valburg"
            },
            {
                "value": 50414,
                "label": "Ospel",
                "label_ar": "أوسبيل",
                "label_fr": "Ospel"
            },
            {
                "value": 50978,
                "label": "Baexem",
                "label_ar": "بيكسيم",
                "label_fr": "Baexem"
            },
            {
                "value": 51771,
                "label": "Zwanenburg",
                "label_ar": "زوانينبورغ",
                "label_fr": "Zwanenburg"
            },
            {
                "value": 50410,
                "label": "Deurne",
                "label_ar": "ديورن",
                "label_fr": "Deurne"
            },
            {
                "value": 50854,
                "label": "Hazerswoude-Dorp",
                "label_ar": "Hazerswoude-Dorp",
                "label_fr": "Hazerswoude-Dorp"
            },
            {
                "value": 51125,
                "label": "Heeg",
                "label_ar": "هيغ",
                "label_fr": "Heeg"
            },
            {
                "value": 51491,
                "label": "Marknesse",
                "label_ar": "ماركنس",
                "label_fr": "Marknesse"
            },
            {
                "value": 50685,
                "label": "Aalst",
                "label_ar": "آلست",
                "label_fr": "Alost"
            },
            {
                "value": 51615,
                "label": "Noordscheschut",
                "label_ar": "Noordscheschut",
                "label_fr": "Noordscheschut"
            },
            {
                "value": 50778,
                "label": "Beilen",
                "label_ar": "بيلين",
                "label_fr": "Beilen"
            },
            {
                "value": 50686,
                "label": "Heukelum",
                "label_ar": "هيوكيلوم",
                "label_fr": "Heukelum"
            },
            {
                "value": 50599,
                "label": "Uitgeest",
                "label_ar": "Uitgeest",
                "label_fr": "Uitgeest"
            },
            {
                "value": 50865,
                "label": "Groot-Ammers",
                "label_ar": "جروت آمرز",
                "label_fr": "Groot-Ammers"
            },
            {
                "value": 50895,
                "label": "Dodewaard",
                "label_ar": "دوديوارد",
                "label_fr": "Dodewaard"
            },
            {
                "value": 51373,
                "label": "Meerlo",
                "label_ar": "ميرلو",
                "label_fr": "Meerlo"
            },
            {
                "value": 51751,
                "label": "Nieuwe-Niedorp",
                "label_ar": "نيوي نيدورب",
                "label_fr": "Nieuwe-Niedorp"
            },
            {
                "value": 50775,
                "label": "Biervliet",
                "label_ar": "بيرفليت",
                "label_fr": "Biervliet"
            },
            {
                "value": 50566,
                "label": "Zoutelande",
                "label_ar": "Zoutelande",
                "label_fr": "Zoutelande"
            },
            {
                "value": 50534,
                "label": "Oranje",
                "label_ar": "أورانج",
                "label_fr": "Oranje"
            },
            {
                "value": 50951,
                "label": "Beusichem",
                "label_ar": "Beusichem",
                "label_fr": "Beusichem"
            },
            {
                "value": 51605,
                "label": "Onnen",
                "label_ar": "أونين",
                "label_fr": "Onnen"
            },
            {
                "value": 51139,
                "label": "Loppersum",
                "label_ar": "Loppersum",
                "label_fr": "Loppersum"
            },
            {
                "value": 50511,
                "label": "Beuningen",
                "label_ar": "بونينجن",
                "label_fr": "Beuningen"
            },
            {
                "value": 51343,
                "label": "Grave",
                "label_ar": "قبر",
                "label_fr": "La tombe"
            },
            {
                "value": 51645,
                "label": "Franeker",
                "label_ar": "فرانكر",
                "label_fr": "Franeker"
            },
            {
                "value": 50664,
                "label": "Duin",
                "label_ar": "دوين",
                "label_fr": "Duin"
            },
            {
                "value": 51764,
                "label": "Nieuw-Amsterdam",
                "label_ar": "نيو أمستردام",
                "label_fr": "Nieuw-Amsterdam"
            },
            {
                "value": 51625,
                "label": "Linne",
                "label_ar": "لين",
                "label_fr": "Linne"
            },
            {
                "value": 50317,
                "label": "Geldrop",
                "label_ar": "جيلدروب",
                "label_fr": "Geldrop"
            },
            {
                "value": 50395,
                "label": "Silvolde",
                "label_ar": "سيلفولد",
                "label_fr": "Silvolde"
            },
            {
                "value": 50508,
                "label": "Soest",
                "label_ar": "Soest",
                "label_fr": "Soest"
            },
            {
                "value": 50515,
                "label": "Ommen",
                "label_ar": "أومين",
                "label_fr": "Ommen"
            },
            {
                "value": 51093,
                "label": "Oude Pekela",
                "label_ar": "عود بيكيلا",
                "label_fr": "Oude Pekela"
            },
            {
                "value": 51136,
                "label": "Haule",
                "label_ar": "هول",
                "label_fr": "Haule"
            },
            {
                "value": 50389,
                "label": "Huizen",
                "label_ar": "Huizen",
                "label_fr": "Huizen"
            },
            {
                "value": 50918,
                "label": "Luyksgestel",
                "label_ar": "Luyksgestel",
                "label_fr": "Luyksgestel"
            },
            {
                "value": 51325,
                "label": "Susteren",
                "label_ar": "سوستيرن",
                "label_fr": "Susteren"
            },
            {
                "value": 51456,
                "label": "Hauwert",
                "label_ar": "هويرت",
                "label_fr": "Hauwert"
            },
            {
                "value": 50731,
                "label": "Drunen",
                "label_ar": "Drunen",
                "label_fr": "Drunen"
            },
            {
                "value": 50427,
                "label": "Moordrecht",
                "label_ar": "موردرخت",
                "label_fr": "Moordrecht"
            },
            {
                "value": 50706,
                "label": "Hulst",
                "label_ar": "هولست",
                "label_fr": "Hulst"
            },
            {
                "value": 50896,
                "label": "Olst",
                "label_ar": "أولست",
                "label_fr": "Olst"
            },
            {
                "value": 51077,
                "label": "Aerdenhout",
                "label_ar": "ايردينهاوت",
                "label_fr": "Aerdenhout"
            },
            {
                "value": 51069,
                "label": "Achtmaal",
                "label_ar": "أشتمال",
                "label_fr": "Achtmaal"
            },
            {
                "value": 51654,
                "label": "Piershil",
                "label_ar": "بيرشيل",
                "label_fr": "Piershil"
            },
            {
                "value": 51734,
                "label": "Tzummarum",
                "label_ar": "تسوماروم",
                "label_fr": "Tzummarum"
            },
            {
                "value": 50813,
                "label": "Westerbork",
                "label_ar": "ويستيربورك",
                "label_fr": "Westerbork"
            },
            {
                "value": 50344,
                "label": "Voorthuizen",
                "label_ar": "Voorthuizen",
                "label_fr": "Voorthuizen"
            },
            {
                "value": 50808,
                "label": "Hoensbroek",
                "label_ar": "Hoensbroek",
                "label_fr": "Hoensbroek"
            },
            {
                "value": 51030,
                "label": "Nieuw-Vennep",
                "label_ar": "نيو فينب",
                "label_fr": "Nieuw-Vennep"
            },
            {
                "value": 51403,
                "label": "Nuis",
                "label_ar": "نويس",
                "label_fr": "Nuis"
            },
            {
                "value": 50384,
                "label": "Egmond aan Zee",
                "label_ar": "إغموند أن زي",
                "label_fr": "Egmond aan Zee"
            },
            {
                "value": 50524,
                "label": "Krommenie",
                "label_ar": "كروميني",
                "label_fr": "Krommenie"
            },
            {
                "value": 50986,
                "label": "Bunnik",
                "label_ar": "بونيك",
                "label_fr": "Bunnik"
            },
            {
                "value": 51684,
                "label": "Heeswijk-Dinther",
                "label_ar": "هيسويجك دينثر",
                "label_fr": "Heeswijk-Dinther"
            },
            {
                "value": 50516,
                "label": "Deventer",
                "label_ar": "ديفينتر",
                "label_fr": "Deventer"
            },
            {
                "value": 50949,
                "label": "Budel",
                "label_ar": "بودل",
                "label_fr": "Budel"
            },
            {
                "value": 51414,
                "label": "Heeze",
                "label_ar": "هيزي",
                "label_fr": "Heeze"
            },
            {
                "value": 50768,
                "label": "Sprang",
                "label_ar": "قفز",
                "label_fr": "Sprang"
            },
            {
                "value": 50612,
                "label": "Limbricht",
                "label_ar": "ليمبريشت",
                "label_fr": "Limbricht"
            },
            {
                "value": 50873,
                "label": "Raamsdonksveer",
                "label_ar": "رامسدونكسفير",
                "label_fr": "Raamsdonksveer"
            },
            {
                "value": 50510,
                "label": "Woudenberg",
                "label_ar": "Woudenberg",
                "label_fr": "Woudenberg"
            },
            {
                "value": 50922,
                "label": "Swifterbant",
                "label_ar": "سويفتيربانت",
                "label_fr": "Swifterbant"
            },
            {
                "value": 51051,
                "label": "Herpen",
                "label_ar": "هيربين",
                "label_fr": "Son stylo"
            },
            {
                "value": 51021,
                "label": "Markelo",
                "label_ar": "ماركيلو",
                "label_fr": "Markelo"
            },
            {
                "value": 51436,
                "label": "Hoorn",
                "label_ar": "هورن",
                "label_fr": "Hoorn"
            },
            {
                "value": 51576,
                "label": "Beegden",
                "label_ar": "بيجدن",
                "label_fr": "Beegden"
            },
            {
                "value": 51766,
                "label": "Bennebroek",
                "label_ar": "بنبروك",
                "label_fr": "Bennebroek"
            },
            {
                "value": 50474,
                "label": "Dieren",
                "label_ar": "ديرين",
                "label_fr": "Dieren"
            },
            {
                "value": 50666,
                "label": "Oostzaan",
                "label_ar": "Oostzaan",
                "label_fr": "Oostzaan"
            },
            {
                "value": 50846,
                "label": "Nijkerk",
                "label_ar": "نيجيرك",
                "label_fr": "Nijkerk"
            },
            {
                "value": 51633,
                "label": "Koekange",
                "label_ar": "كوكانج",
                "label_fr": "Koekange"
            },
            {
                "value": 51318,
                "label": "Beesel",
                "label_ar": "النحل",
                "label_fr": "Beesel"
            },
            {
                "value": 51672,
                "label": "Fluitenberg",
                "label_ar": "فلوتينبيرج",
                "label_fr": "Fluitenberg"
            },
            {
                "value": 50611,
                "label": "Palemig",
                "label_ar": "باليميج",
                "label_fr": "Palemig"
            },
            {
                "value": 50419,
                "label": "Velddriel",
                "label_ar": "فيلدريل",
                "label_fr": "Velddriel"
            },
            {
                "value": 50740,
                "label": "Zeewolde",
                "label_ar": "زيوولد",
                "label_fr": "Zeewolde"
            },
            {
                "value": 51419,
                "label": "Sappemeer",
                "label_ar": "Sappemeer",
                "label_fr": "Sapemeer"
            },
            {
                "value": 51434,
                "label": "Oostwoud",
                "label_ar": "اوستوود",
                "label_fr": "Oostwoud"
            },
            {
                "value": 51452,
                "label": "Schellinkhout",
                "label_ar": "شلينكوت",
                "label_fr": "Schellinkhout"
            },
            {
                "value": 50361,
                "label": "Huissen",
                "label_ar": "هويسن",
                "label_fr": "Huissen"
            },
            {
                "value": 51590,
                "label": "Oldekerk",
                "label_ar": "أولديكيرك",
                "label_fr": "Oldekerk"
            },
            {
                "value": 51488,
                "label": "Nieuwpoort",
                "label_ar": "نيوبورت",
                "label_fr": "Nieuport"
            },
            {
                "value": 50398,
                "label": "Ulft",
                "label_ar": "أولفت",
                "label_fr": "Ulft"
            },
            {
                "value": 50825,
                "label": "Dalen",
                "label_ar": "دالين",
                "label_fr": "Dalen"
            },
            {
                "value": 50619,
                "label": "Sassenheim",
                "label_ar": "ساسنهايم",
                "label_fr": "Sassenheim"
            },
            {
                "value": 50885,
                "label": "Geervliet",
                "label_ar": "جيرفليت",
                "label_fr": "Geervliet"
            },
            {
                "value": 51769,
                "label": "Abbenes",
                "label_ar": "أبينيس",
                "label_fr": "Abbène"
            },
            {
                "value": 50651,
                "label": "Wezep",
                "label_ar": "ويزيب",
                "label_fr": "Wezep"
            },
            {
                "value": 50738,
                "label": "Schagen",
                "label_ar": "شاجن",
                "label_fr": "Schagen"
            },
            {
                "value": 50682,
                "label": "Giesbeek",
                "label_ar": "جيسبيك",
                "label_fr": "Giesbeek"
            },
            {
                "value": 50847,
                "label": "Terheijden",
                "label_ar": "تيرهايدن",
                "label_fr": "Terheijden"
            },
            {
                "value": 50562,
                "label": "Borger",
                "label_ar": "بورغر",
                "label_fr": "Borger"
            },
            {
                "value": 50945,
                "label": "Drempt",
                "label_ar": "إكتاف",
                "label_fr": "Drempt"
            },
            {
                "value": 51122,
                "label": "Woudsend",
                "label_ar": "Woudsend",
                "label_fr": "Woudsend"
            },
            {
                "value": 50898,
                "label": "Warmond",
                "label_ar": "وارموند",
                "label_fr": "Warmond"
            },
            {
                "value": 51323,
                "label": "Wessem",
                "label_ar": "وسيم",
                "label_fr": "Wessem"
            },
            {
                "value": 50691,
                "label": "Sluis",
                "label_ar": "سلايس",
                "label_fr": "Sluis"
            },
            {
                "value": 51099,
                "label": "Winsum",
                "label_ar": "وينسوم",
                "label_fr": "Winsum"
            },
            {
                "value": 51727,
                "label": "Oudwoude",
                "label_ar": "عود",
                "label_fr": "Oudwoude"
            },
            {
                "value": 50426,
                "label": "Roden",
                "label_ar": "رودين",
                "label_fr": "Roden"
            },
            {
                "value": 50834,
                "label": "Toldijk",
                "label_ar": "Toldijk",
                "label_fr": "Toldijk"
            },
            {
                "value": 51229,
                "label": "Ter Aar",
                "label_ar": "تير آر",
                "label_fr": "Ter Aar"
            },
            {
                "value": 51599,
                "label": "Eelde-Paterswolde",
                "label_ar": "إيلدي باترسولد",
                "label_fr": "Eelde-Paterswolde"
            },
            {
                "value": 50412,
                "label": "Mijnsheerenland",
                "label_ar": "Mijnsheerenland",
                "label_fr": "Mijnsheerenland"
            },
            {
                "value": 50644,
                "label": "Doetinchem",
                "label_ar": "Doetinchem",
                "label_fr": "Doetinchem"
            },
            {
                "value": 51037,
                "label": "Leersum",
                "label_ar": "ليرسم",
                "label_fr": "Leersum"
            },
            {
                "value": 51076,
                "label": "Haelen",
                "label_ar": "هيلين",
                "label_fr": "Haelen"
            },
            {
                "value": 51268,
                "label": "Kamerik",
                "label_ar": "كامريك",
                "label_fr": "Kamerik"
            },
            {
                "value": 51050,
                "label": "Gasselte",
                "label_ar": "جاسيلت",
                "label_fr": "Gasselte"
            },
            {
                "value": 51043,
                "label": "Bathmen",
                "label_ar": "حمام",
                "label_fr": "Baigneurs"
            },
            {
                "value": 51267,
                "label": "Bitgum",
                "label_ar": "بيتجوم",
                "label_fr": "Bitgum"
            },
            {
                "value": 50781,
                "label": "Wijk bij Duurstede",
                "label_ar": "Wijk bij Duurstede",
                "label_fr": "Wijk bij Duurstede"
            },
            {
                "value": 50883,
                "label": "s-Heerenberg",
                "label_ar": "s- هيرينبيرج",
                "label_fr": "s-Heerenberg"
            },
            {
                "value": 50762,
                "label": "Didam",
                "label_ar": "ديدام",
                "label_fr": "Didam"
            },
            {
                "value": 51600,
                "label": "Enumatil",
                "label_ar": "إنوماتيل",
                "label_fr": "Enumatil"
            },
            {
                "value": 51222,
                "label": "Bruchem",
                "label_ar": "بروكيم",
                "label_fr": "Bruchem"
            },
            {
                "value": 51193,
                "label": "Leerdam",
                "label_ar": "ليردام",
                "label_fr": "Leerdam"
            },
            {
                "value": 51226,
                "label": "Stompwijk",
                "label_ar": "ستومبويجك",
                "label_fr": "Stompwijk"
            },
            {
                "value": 51360,
                "label": "Milsbeek",
                "label_ar": "ميلسبيك",
                "label_fr": "Milsbeek"
            },
            {
                "value": 51528,
                "label": "Lekkerkerk",
                "label_ar": "ليككيرك",
                "label_fr": "Lekkerkerk"
            },
            {
                "value": 51108,
                "label": "Buinerveen",
                "label_ar": "بوينيرفين",
                "label_fr": "Buinerveen"
            },
            {
                "value": 51209,
                "label": "Colijnsplaat",
                "label_ar": "Colijnsplaat",
                "label_fr": "Colijnsplaat"
            },
            {
                "value": 51450,
                "label": "De Goorn",
                "label_ar": "دي جورن",
                "label_fr": "De Goorn"
            },
            {
                "value": 50488,
                "label": "Oostvoorne",
                "label_ar": "أوستفورني",
                "label_fr": "Oostvoorne"
            },
            {
                "value": 50640,
                "label": "Barendrecht",
                "label_ar": "باريندريخت",
                "label_fr": "Barendrecht"
            },
            {
                "value": 51185,
                "label": "Doornspijk",
                "label_ar": "دورنشبيك",
                "label_fr": "Doornspijk"
            },
            {
                "value": 51559,
                "label": "Groenlo",
                "label_ar": "جروينلو",
                "label_fr": "Groenlo"
            },
            {
                "value": 50752,
                "label": "Garderen",
                "label_ar": "غارديرين",
                "label_fr": "Garderen"
            },
            {
                "value": 51046,
                "label": "Ten Boer",
                "label_ar": "عشرة بوير",
                "label_fr": "Dix Boer"
            },
            {
                "value": 51137,
                "label": "Wijckel",
                "label_ar": "Wijckel",
                "label_fr": "Wijckel"
            },
            {
                "value": 51003,
                "label": "Huis ter Heide",
                "label_ar": "هويس تير هايد",
                "label_fr": "Huis ter Heide"
            },
            {
                "value": 51486,
                "label": "Marum",
                "label_ar": "ماروم",
                "label_fr": "Marum"
            },
            {
                "value": 51162,
                "label": "Castricum",
                "label_ar": "كاستريكوم",
                "label_fr": "Castricum"
            },
            {
                "value": 50727,
                "label": "Eede",
                "label_ar": "إيدي",
                "label_fr": "Eede"
            },
            {
                "value": 50368,
                "label": "Warnsveld",
                "label_ar": "يحذر",
                "label_fr": "Warnsveld"
            },
            {
                "value": 50833,
                "label": "Den Dolder",
                "label_ar": "دن دولدر",
                "label_fr": "Den Dolder"
            },
            {
                "value": 50903,
                "label": "Middenbeemster",
                "label_ar": "ميدينبيمستر",
                "label_fr": "Middenbeemster"
            },
            {
                "value": 51115,
                "label": "Fleringen",
                "label_ar": "فليرينغن",
                "label_fr": "Fleringen"
            },
            {
                "value": 51406,
                "label": "Gouderak",
                "label_ar": "جوديراك",
                "label_fr": "Gouderak"
            },
            {
                "value": 51545,
                "label": "Oudewater",
                "label_ar": "ماء العود",
                "label_fr": "Oudewater"
            },
            {
                "value": 51054,
                "label": "Einighausen",
                "label_ar": "اينيجهاوزن",
                "label_fr": "Einighausen"
            },
            {
                "value": 51521,
                "label": "Rips",
                "label_ar": "مزقت",
                "label_fr": "Déchirures"
            },
            {
                "value": 50786,
                "label": "Epe",
                "label_ar": "إيبي",
                "label_fr": "Epe"
            },
            {
                "value": 51382,
                "label": "Kraggenburg",
                "label_ar": "كراغينبورغ",
                "label_fr": "Kraggenburg"
            },
            {
                "value": 51726,
                "label": "Hantum",
                "label_ar": "هانتوم",
                "label_fr": "Hantum"
            },
            {
                "value": 50974,
                "label": "Santpoort-Noord",
                "label_ar": "سانتبورت نورد",
                "label_fr": "Santpoort-Noord"
            },
            {
                "value": 51252,
                "label": "Everdingen",
                "label_ar": "إيفردينجن",
                "label_fr": "Everdingen"
            },
            {
                "value": 50442,
                "label": "Udenhout",
                "label_ar": "أودنهاوت",
                "label_fr": "Udenhout"
            },
            {
                "value": 51417,
                "label": "Kalenberg",
                "label_ar": "كالينبيرج",
                "label_fr": "Kalenberg"
            },
            {
                "value": 50673,
                "label": "Oosterbeek",
                "label_ar": "أوستربيك",
                "label_fr": "Oosterbeek"
            },
            {
                "value": 50755,
                "label": "Ezinge",
                "label_ar": "إيزينج",
                "label_fr": "Ezinge"
            },
            {
                "value": 51007,
                "label": "Westerwijtwerd",
                "label_ar": "Westerwijtwerd",
                "label_fr": "Westerwijtwerd"
            },
            {
                "value": 51370,
                "label": "Almkerk",
                "label_ar": "المكيرك",
                "label_fr": "Almkerk"
            },
            {
                "value": 51650,
                "label": "'t Kabel",
                "label_ar": "كابيل",
                "label_fr": "'t Kabel"
            },
            {
                "value": 50697,
                "label": "IJmuiden",
                "label_ar": "IJmuiden",
                "label_fr": "IJmuiden"
            },
            {
                "value": 50817,
                "label": "Loon op Zand",
                "label_ar": "لون أوب زاند",
                "label_fr": "Loon op Zand"
            },
            {
                "value": 51592,
                "label": "Grootegast",
                "label_ar": "جروتيغاست",
                "label_fr": "Grootegast"
            },
            {
                "value": 50554,
                "label": "Etten",
                "label_ar": "إتن",
                "label_fr": "Etten"
            },
            {
                "value": 51100,
                "label": "Zandeweer",
                "label_ar": "زاندوير",
                "label_fr": "Zandeweer"
            },
            {
                "value": 51098,
                "label": "Rottum",
                "label_ar": "روتوم",
                "label_fr": "Rottum"
            },
            {
                "value": 50428,
                "label": "Waddinxveen",
                "label_ar": "وادينكسفين",
                "label_fr": "Waddinxveen"
            },
            {
                "value": 51358,
                "label": "Cadier en Keer",
                "label_ar": "كاديير أون كير",
                "label_fr": "Cadier en Keer"
            },
            {
                "value": 50339,
                "label": "Valkenswaard",
                "label_ar": "فالكنسوارد",
                "label_fr": "Valkenswaard"
            },
            {
                "value": 50484,
                "label": "Sellingen",
                "label_ar": "بيع",
                "label_fr": "Sellingen"
            },
            {
                "value": 50348,
                "label": "Diemen",
                "label_ar": "ديمن",
                "label_fr": "Diemen"
            },
            {
                "value": 51296,
                "label": "Jabeek",
                "label_ar": "جابيك",
                "label_fr": "Jabeek"
            },
            {
                "value": 51748,
                "label": "Hippolytushoef",
                "label_ar": "هيبوليتوشوف",
                "label_fr": "Hippolyte"
            },
            {
                "value": 50742,
                "label": "Houten",
                "label_ar": "هوتين",
                "label_fr": "Houten"
            },
            {
                "value": 51303,
                "label": "Zundert",
                "label_ar": "Zundert",
                "label_fr": "Zundert"
            },
            {
                "value": 51577,
                "label": "Nunhem",
                "label_ar": "نونهم",
                "label_fr": "Nunhem"
            },
            {
                "value": 51168,
                "label": "Rucphen",
                "label_ar": "روسفين",
                "label_fr": "Rucphen"
            },
            {
                "value": 50657,
                "label": "Winssen",
                "label_ar": "وينسن",
                "label_fr": "Winssen"
            },
            {
                "value": 50463,
                "label": "Zwolle",
                "label_ar": "زفول",
                "label_fr": "Zwolle"
            },
            {
                "value": 50485,
                "label": "Epen",
                "label_ar": "إيبين",
                "label_fr": "Epen"
            },
            {
                "value": 50440,
                "label": "Oirschot",
                "label_ar": "أويرشوت",
                "label_fr": "Oirschot"
            },
            {
                "value": 50981,
                "label": "Werkendam",
                "label_ar": "ويركيندام",
                "label_fr": "Werkendam"
            },
            {
                "value": 50985,
                "label": "Zeddam",
                "label_ar": "زيدام",
                "label_fr": "Zeddam"
            },
            {
                "value": 50455,
                "label": "Bergeijk",
                "label_ar": "برجيجك",
                "label_fr": "Bergeijk"
            },
            {
                "value": 50777,
                "label": "Neede",
                "label_ar": "نيد",
                "label_fr": "Besoin"
            },
            {
                "value": 51379,
                "label": "Barchem",
                "label_ar": "باركيم",
                "label_fr": "Barchem"
            },
            {
                "value": 51448,
                "label": "Jisp",
                "label_ar": "جسب",
                "label_fr": "Jisp"
            },
            {
                "value": 51138,
                "label": "Bakhuizen",
                "label_ar": "باخويزن",
                "label_fr": "Bakhuizen"
            },
            {
                "value": 50601,
                "label": "Den Helder",
                "label_ar": "دن هيلدر",
                "label_fr": "Den Helder"
            },
            {
                "value": 51278,
                "label": "Nuth",
                "label_ar": "نوث",
                "label_fr": "Nuth"
            },
            {
                "value": 50680,
                "label": "Berlicum",
                "label_ar": "بيرليكوم",
                "label_fr": "Berlicum"
            },
            {
                "value": 50429,
                "label": "Mook",
                "label_ar": "موك",
                "label_fr": "Mook"
            },
            {
                "value": 50505,
                "label": "Bergharen",
                "label_ar": "بيرغارين",
                "label_fr": "Bergharen"
            },
            {
                "value": 51142,
                "label": "Zeerijp",
                "label_ar": "زريجة",
                "label_fr": "Zeerijp"
            },
            {
                "value": 50600,
                "label": "Doorwerth",
                "label_ar": "دورويرث",
                "label_fr": "Doorwerth"
            },
            {
                "value": 51667,
                "label": "Otterlo",
                "label_ar": "أوتيرلو",
                "label_fr": "Otterlo"
            },
            {
                "value": 50446,
                "label": "Flushing",
                "label_ar": "تدفق مائى - صرف",
                "label_fr": "Rinçage"
            },
            {
                "value": 50964,
                "label": "Ouderkerk aan de Amstel",
                "label_ar": "أودركيرك آن دي أمستل",
                "label_fr": "Ouderkerk aan de Amstel"
            },
            {
                "value": 50322,
                "label": "Dronten",
                "label_ar": "درونتين",
                "label_fr": "Dronten"
            },
            {
                "value": 50671,
                "label": "Steensel",
                "label_ar": "Steensel",
                "label_fr": "Steensel"
            },
            {
                "value": 50840,
                "label": "Oldebroek",
                "label_ar": "أولدبروك",
                "label_fr": "Oldebroek"
            },
            {
                "value": 51457,
                "label": "Middenmeer",
                "label_ar": "ميدنمير",
                "label_fr": "Middenmeer"
            },
            {
                "value": 51224,
                "label": "Moerdijk",
                "label_ar": "مورديك",
                "label_fr": "Moerdijk"
            },
            {
                "value": 50821,
                "label": "Boelenslaan",
                "label_ar": "بويلينسلان",
                "label_fr": "Boelenslaan"
            },
            {
                "value": 50725,
                "label": "Yerseke",
                "label_ar": "يرسكي",
                "label_fr": "Yerseke"
            },
            {
                "value": 51285,
                "label": "'s Gravenmoer",
                "label_ar": "جرافنمور",
                "label_fr": "Gravenmoer de"
            },
            {
                "value": 50537,
                "label": "Noordwijk-Binnen",
                "label_ar": "نوردفيك بينين",
                "label_fr": "Noordwijk-Binnen"
            },
            {
                "value": 51147,
                "label": "Bovensmilde",
                "label_ar": "بوفنسميلد",
                "label_fr": "Bovensmilde"
            },
            {
                "value": 50688,
                "label": "Muiderberg",
                "label_ar": "Muiderberg",
                "label_fr": "Muiderberg"
            },
            {
                "value": 51648,
                "label": "Oud-Ade",
                "label_ar": "عود آدي",
                "label_fr": "Oud-Ade"
            },
            {
                "value": 51424,
                "label": "Winkel",
                "label_ar": "وينكل",
                "label_fr": "Winkel"
            },
            {
                "value": 51211,
                "label": "Breskens",
                "label_ar": "بريسكينز",
                "label_fr": "Breskens"
            },
            {
                "value": 50993,
                "label": "Denekamp",
                "label_ar": "دينكامب",
                "label_fr": "Denekamp"
            },
            {
                "value": 50545,
                "label": "Krimpen aan den IJssel",
                "label_ar": "Krimpen aan den IJssel",
                "label_fr": "Krimpen aan den IJssel"
            },
            {
                "value": 51773,
                "label": "Lutjewinkel",
                "label_ar": "Lutjewinkel",
                "label_fr": "Lutjewinkel"
            },
            {
                "value": 51217,
                "label": "Vogelwaarde",
                "label_ar": "فوجلواردي",
                "label_fr": "Vogelwaarde"
            },
            {
                "value": 51290,
                "label": "Haren",
                "label_ar": "هارين",
                "label_fr": "Haren"
            },
            {
                "value": 51320,
                "label": "Ittervoort",
                "label_ar": "إترفورت",
                "label_fr": "Ittervoort"
            },
            {
                "value": 50987,
                "label": "Poeldijk",
                "label_ar": "بولديك",
                "label_fr": "Poeldijk"
            },
            {
                "value": 51526,
                "label": "Hellouw",
                "label_ar": "مرحبا",
                "label_fr": "Hellouw"
            },
            {
                "value": 51101,
                "label": "Warffum",
                "label_ar": "وارفوم",
                "label_fr": "Warffum"
            },
            {
                "value": 50711,
                "label": "Hoedekenskerke",
                "label_ar": "Hoedekenskerke",
                "label_fr": "Hoedekenskerke"
            },
            {
                "value": 51513,
                "label": "Ophemert",
                "label_ar": "أوبيميرت",
                "label_fr": "Ophemert"
            },
            {
                "value": 50606,
                "label": "Posterholt",
                "label_ar": "بوسترهولت",
                "label_fr": "Posterholt"
            },
            {
                "value": 50919,
                "label": "Muiden",
                "label_ar": "Muiden",
                "label_fr": "Muiden"
            },
            {
                "value": 51398,
                "label": "Anna Paulowna",
                "label_ar": "آنا بولونا",
                "label_fr": "Anna Paulowna"
            },
            {
                "value": 51409,
                "label": "Lithoijen",
                "label_ar": "ليثويجين",
                "label_fr": "Lithoijen"
            },
            {
                "value": 51483,
                "label": "Wapenveld",
                "label_ar": "وابينفيلد",
                "label_fr": "Wapenveld"
            },
            {
                "value": 51500,
                "label": "Noordhorn",
                "label_ar": "نوردهورن",
                "label_fr": "Noordhorn"
            },
            {
                "value": 50319,
                "label": "Vianen",
                "label_ar": "فيانن",
                "label_fr": "Vianen"
            },
            {
                "value": 51118,
                "label": "Manderveen",
                "label_ar": "ماندرفين",
                "label_fr": "Manderveen"
            },
            {
                "value": 50358,
                "label": "Twello",
                "label_ar": "Twello",
                "label_fr": "Twello"
            },
            {
                "value": 51776,
                "label": "Saasveld",
                "label_ar": "ساسفيلد",
                "label_fr": "Saasveld"
            },
            {
                "value": 50530,
                "label": "Assendelft",
                "label_ar": "أسينديلفت",
                "label_fr": "Assendelft"
            },
            {
                "value": 51314,
                "label": "Sint-Michielsgestel",
                "label_ar": "سينت ميشيلجيستيل",
                "label_fr": "Sint-Michielsgestel"
            },
            {
                "value": 51712,
                "label": "Schoorl",
                "label_ar": "شورل",
                "label_fr": "Schoorl"
            },
            {
                "value": 50471,
                "label": "Nieuwegein",
                "label_ar": "نيويجين",
                "label_fr": "Nieuwegein"
            },
            {
                "value": 51481,
                "label": "Waarder",
                "label_ar": "الواردر",
                "label_fr": "Waarder"
            },
            {
                "value": 51153,
                "label": "Delden",
                "label_ar": "ديلدن",
                "label_fr": "Delden"
            },
            {
                "value": 51752,
                "label": "Zuid-Scharwoude",
                "label_ar": "زويد شارود",
                "label_fr": "Zuid-Scharwoude"
            },
            {
                "value": 50353,
                "label": "Puttershoek",
                "label_ar": "بوترشوك",
                "label_fr": "Puttershoek"
            },
            {
                "value": 51386,
                "label": "Nieuwdorp",
                "label_ar": "نيودورب",
                "label_fr": "Nieuwdorp"
            },
            {
                "value": 50495,
                "label": "Nieuwkuijk",
                "label_ar": "نيوكويك",
                "label_fr": "Nieuwkuijk"
            },
            {
                "value": 51202,
                "label": "Sloterdijk",
                "label_ar": "سلوترديك",
                "label_fr": "Sloterdijk"
            },
            {
                "value": 50659,
                "label": "Eerbeek",
                "label_ar": "ايربيك",
                "label_fr": "Eerbeek"
            },
            {
                "value": 51664,
                "label": "Vlieland",
                "label_ar": "فليلاند",
                "label_fr": "Vlieland"
            },
            {
                "value": 51591,
                "label": "Kommerzijl",
                "label_ar": "كوميرزيجل",
                "label_fr": "Kommerzijl"
            },
            {
                "value": 51701,
                "label": "Waterland",
                "label_ar": "أرض الماء",
                "label_fr": "Pays de l'eau"
            },
            {
                "value": 51441,
                "label": "Stompetoren",
                "label_ar": "ستومبيتورين",
                "label_fr": "Stompetoren"
            },
            {
                "value": 51342,
                "label": "Rijkevoort",
                "label_ar": "ريجيفورت",
                "label_fr": "Rijkevoort"
            },
            {
                "value": 51540,
                "label": "Alem",
                "label_ar": "عالم",
                "label_fr": "Alem"
            },
            {
                "value": 50771,
                "label": "Oosterend",
                "label_ar": "أوستريند",
                "label_fr": "Oosterend"
            },
            {
                "value": 51693,
                "label": "Meer",
                "label_ar": "مير",
                "label_fr": "Meer"
            },
            {
                "value": 50662,
                "label": "Nuenen",
                "label_ar": "Nuenen",
                "label_fr": "Nuenen"
            },
            {
                "value": 50638,
                "label": "Heemstede",
                "label_ar": "هيمستيد",
                "label_fr": "Heemstede"
            },
            {
                "value": 50436,
                "label": "Zierikzee",
                "label_ar": "Zierikzee",
                "label_fr": "Zierikzee"
            },
            {
                "value": 50953,
                "label": "Millingen aan de Rijn",
                "label_ar": "ميلينجن آن دي رين",
                "label_fr": "Millingen aan de Rijn"
            },
            {
                "value": 50713,
                "label": "'s-Heerenhoek",
                "label_ar": "'s-Heerenhoek",
                "label_fr": "'s-Heerenhoek"
            },
            {
                "value": 51280,
                "label": "Oudkarspel",
                "label_ar": "أودكارسبيل",
                "label_fr": "Oudkarspel"
            },
            {
                "value": 51718,
                "label": "Veenhuizen",
                "label_ar": "فينهويزين",
                "label_fr": "Veenhuizen"
            },
            {
                "value": 50622,
                "label": "Staphorst",
                "label_ar": "ستافورست",
                "label_fr": "Staphorst"
            },
            {
                "value": 51516,
                "label": "Beerzerveld",
                "label_ar": "بيرزرفيلد",
                "label_fr": "Beerzerveld"
            },
            {
                "value": 51070,
                "label": "Vlagtwedde",
                "label_ar": "فلاجتويد",
                "label_fr": "Vlagtwedde"
            },
            {
                "value": 50958,
                "label": "Maarheeze",
                "label_ar": "مرهيزه",
                "label_fr": "Maarheeze"
            },
            {
                "value": 51260,
                "label": "Craailo",
                "label_ar": "كرايلو",
                "label_fr": "Craailo"
            },
            {
                "value": 51041,
                "label": "Haaren",
                "label_ar": "هارين",
                "label_fr": "Haaren"
            },
            {
                "value": 51418,
                "label": "Kockengen",
                "label_ar": "كوكنجن",
                "label_fr": "Kockengen"
            },
            {
                "value": 51690,
                "label": "Nijhuizum",
                "label_ar": "نيجويزم",
                "label_fr": "Nijhuizum"
            },
            {
                "value": 50350,
                "label": "Alkmaar",
                "label_ar": "الكمار",
                "label_fr": "Alkmaar"
            },
            {
                "value": 50724,
                "label": "Kruiningen",
                "label_ar": "كروينجين",
                "label_fr": "Kruiningen"
            },
            {
                "value": 50590,
                "label": "Kwintsheul",
                "label_ar": "كوينتشيول",
                "label_fr": "Kwintsheul"
            },
            {
                "value": 51328,
                "label": "Dieteren",
                "label_ar": "ديتيرين",
                "label_fr": "Dieteren"
            },
            {
                "value": 51271,
                "label": "Hattem",
                "label_ar": "حاتم",
                "label_fr": "Hattem"
            },
            {
                "value": 50875,
                "label": "Klundert",
                "label_ar": "كلندر",
                "label_fr": "Klundert"
            },
            {
                "value": 50809,
                "label": "Hoogkarspel",
                "label_ar": "Hoogkarspel",
                "label_fr": "Hoogkarspel"
            },
            {
                "value": 51362,
                "label": "Ven-Zelderheide",
                "label_ar": "فين زلدرهيد",
                "label_fr": "Ven-Zelderheide"
            },
            {
                "value": 51695,
                "label": "Ovezande",
                "label_ar": "Ovezande",
                "label_fr": "Ovezande"
            },
            {
                "value": 51223,
                "label": "Arkel",
                "label_ar": "آركيل",
                "label_fr": "Arkel"
            },
            {
                "value": 51381,
                "label": "Buurse",
                "label_ar": "ينفجر",
                "label_fr": "Buurse"
            },
            {
                "value": 51553,
                "label": "Heenvliet",
                "label_ar": "هينفليت",
                "label_fr": "Heenvliet"
            },
            {
                "value": 51762,
                "label": "Groenveld",
                "label_ar": "جروينفيلد",
                "label_fr": "Groenveld"
            },
            {
                "value": 51423,
                "label": "Nijbroek",
                "label_ar": "نيجبروك",
                "label_fr": "Nijbroek"
            },
            {
                "value": 51747,
                "label": "Pingjum",
                "label_ar": "بينججوم",
                "label_fr": "Pingjum"
            },
            {
                "value": 51775,
                "label": "Driehuis",
                "label_ar": "دريهيس",
                "label_fr": "Driehuis"
            }
        ]
    },
    {
        "country": "Greece",
        "cities": [
            {
                "value": 51850,
                "label": "Galatsi",
                "label_ar": "جالاتسي",
                "label_fr": "Galatsi"
            },
            {
                "value": 51817,
                "label": "Lamia",
                "label_ar": "لمياء",
                "label_fr": "Lamia"
            },
            {
                "value": 51861,
                "label": "Melissia",
                "label_ar": "ميليسيا",
                "label_fr": "Melissia"
            },
            {
                "value": 51916,
                "label": "Nafpaktos",
                "label_ar": "نافباكتوس",
                "label_fr": "Nafpaktos"
            },
            {
                "value": 51886,
                "label": "Tripoli",
                "label_ar": "طرابلس",
                "label_fr": "Tripoli"
            },
            {
                "value": 51858,
                "label": "Pefki",
                "label_ar": "بيفكي",
                "label_fr": "Pefki"
            },
            {
                "value": 51902,
                "label": "Argyroupoli",
                "label_ar": "أرجروبولي",
                "label_fr": "Argyroupoli"
            },
            {
                "value": 51868,
                "label": "Vari",
                "label_ar": "فاري",
                "label_fr": "Vari"
            },
            {
                "value": 51927,
                "label": "Pella",
                "label_ar": "بيلا",
                "label_fr": "Pella"
            },
            {
                "value": 51843,
                "label": "Perama",
                "label_ar": "بيراما",
                "label_fr": "Perama"
            },
            {
                "value": 51881,
                "label": "Andravida",
                "label_ar": "أندرافيدا",
                "label_fr": "Andravida"
            },
            {
                "value": 51816,
                "label": "Kilkis",
                "label_ar": "كيلكيس",
                "label_fr": "Kilkis"
            },
            {
                "value": 51814,
                "label": "Aliartos",
                "label_ar": "أليارتوس",
                "label_fr": "Aliartos"
            },
            {
                "value": 51857,
                "label": "Amaliada",
                "label_ar": "أماليادا",
                "label_fr": "Amaliada"
            },
            {
                "value": 51846,
                "label": "Elliniko",
                "label_ar": "إلينيكو",
                "label_fr": "Elliniko"
            },
            {
                "value": 51888,
                "label": "Peristeri",
                "label_ar": "بيريستيري",
                "label_fr": "Peristeri"
            },
            {
                "value": 51784,
                "label": "Aegina",
                "label_ar": "ايجينا",
                "label_fr": "Égine"
            },
            {
                "value": 51798,
                "label": "Larisa",
                "label_ar": "لاريسا",
                "label_fr": "Larisa"
            },
            {
                "value": 51849,
                "label": "Ayios Stefanos",
                "label_ar": "أيوس ستيفانوس",
                "label_fr": "Ayios Stefanos"
            },
            {
                "value": 51939,
                "label": "Polichni",
                "label_ar": "Polichni",
                "label_fr": "Polichni"
            },
            {
                "value": 51787,
                "label": "Rafina",
                "label_ar": "رافينا",
                "label_fr": "Rafina"
            },
            {
                "value": 51801,
                "label": "Alexandroupoli",
                "label_ar": "الكسندروبولي",
                "label_fr": "Alexandroupoli"
            },
            {
                "value": 51856,
                "label": "Farsala",
                "label_ar": "فارسالا",
                "label_fr": "Farsala"
            },
            {
                "value": 51919,
                "label": "Kalymnos",
                "label_ar": "كاليمنوس",
                "label_fr": "Kalymnos"
            },
            {
                "value": 51799,
                "label": "Elassona",
                "label_ar": "إلاسونا",
                "label_fr": "Elassona"
            },
            {
                "value": 51934,
                "label": "Pikermi",
                "label_ar": "بيكيرمي",
                "label_fr": "Pikermi"
            },
            {
                "value": 51906,
                "label": "Parga",
                "label_ar": "برجا",
                "label_fr": "Parga"
            },
            {
                "value": 51921,
                "label": "Mesagros",
                "label_ar": "ميساغروس",
                "label_fr": "Mesagros"
            },
            {
                "value": 51870,
                "label": "Chania",
                "label_ar": "خانيا",
                "label_fr": "Chania"
            },
            {
                "value": 51884,
                "label": "Xylokastro",
                "label_ar": "زيلوكاسترو",
                "label_fr": "Xylokastro"
            },
            {
                "value": 51878,
                "label": "Koropi",
                "label_ar": "كوروبي",
                "label_fr": "Koropi"
            },
            {
                "value": 51867,
                "label": "Florina",
                "label_ar": "فلورينا",
                "label_fr": "Florina"
            },
            {
                "value": 51908,
                "label": "Litochoro",
                "label_ar": "ليتوتشورو",
                "label_fr": "Litochoro"
            },
            {
                "value": 51912,
                "label": "Mytilene",
                "label_ar": "ميتيليني",
                "label_fr": "Mytilène"
            },
            {
                "value": 51917,
                "label": "Oropos",
                "label_ar": "أوروبوس",
                "label_fr": "Oropos"
            },
            {
                "value": 51885,
                "label": "Nea Kios",
                "label_ar": "نيا كيوس",
                "label_fr": "Nea Kios"
            },
            {
                "value": 51833,
                "label": "Giannitsa",
                "label_ar": "جانيتسا",
                "label_fr": "Giannitsa"
            },
            {
                "value": 51848,
                "label": "Salamina",
                "label_ar": "سلامينا",
                "label_fr": "Salamine"
            },
            {
                "value": 51945,
                "label": "Tavros",
                "label_ar": "تافروس",
                "label_fr": "Tavros"
            },
            {
                "value": 51918,
                "label": "Drama",
                "label_ar": "دراما",
                "label_fr": "Drame"
            },
            {
                "value": 51905,
                "label": "Lefkimmi",
                "label_ar": "Lefkimmi",
                "label_fr": "Lefkimmi"
            },
            {
                "value": 51828,
                "label": "Karditsa",
                "label_ar": "كارديتسا",
                "label_fr": "Karditsa"
            },
            {
                "value": 51946,
                "label": "Leontario",
                "label_ar": "ليونتاريو",
                "label_fr": "Léontario"
            },
            {
                "value": 51926,
                "label": "Ormylia",
                "label_ar": "أورميليا",
                "label_fr": "Ormylie"
            },
            {
                "value": 51794,
                "label": "Kozani",
                "label_ar": "كوزاني",
                "label_fr": "Kozani"
            },
            {
                "value": 51937,
                "label": "Agioi Anargyroi",
                "label_ar": "أجيوي أنارجيروي",
                "label_fr": "Agioi Anargyroi"
            },
            {
                "value": 51823,
                "label": "Keratsini",
                "label_ar": "كيراتسيني",
                "label_fr": "Keratsini"
            },
            {
                "value": 51910,
                "label": "Spata",
                "label_ar": "سباتا",
                "label_fr": "Spata"
            },
            {
                "value": 51825,
                "label": "Pátrai",
                "label_ar": "باتراي",
                "label_fr": "Pátrai"
            },
            {
                "value": 51897,
                "label": "Pyrgos",
                "label_ar": "بيرغوس",
                "label_fr": "Pyrgos"
            },
            {
                "value": 51803,
                "label": "Marathon",
                "label_ar": "ماراثون",
                "label_fr": "Marathon"
            },
            {
                "value": 51903,
                "label": "Thermi",
                "label_ar": "ثيرمي",
                "label_fr": "Thermi"
            },
            {
                "value": 51791,
                "label": "Glyfada",
                "label_ar": "جليفادا",
                "label_fr": "Glyfada"
            },
            {
                "value": 51894,
                "label": "Dafni",
                "label_ar": "دافني",
                "label_fr": "Dafni"
            },
            {
                "value": 51839,
                "label": "Moires",
                "label_ar": "موريس",
                "label_fr": "Moires"
            },
            {
                "value": 51879,
                "label": "Trikala",
                "label_ar": "تريكالا",
                "label_fr": "Trikala"
            },
            {
                "value": 51901,
                "label": "Peristeri",
                "label_ar": "بيريستيري",
                "label_fr": "Peristeri"
            },
            {
                "value": 51853,
                "label": "Aspropyrgos",
                "label_ar": "Aspropyrgos",
                "label_fr": "Aspropyrgos"
            },
            {
                "value": 51781,
                "label": "Ekali",
                "label_ar": "إيكالي",
                "label_fr": "Ekali"
            },
            {
                "value": 51830,
                "label": "Lefkada",
                "label_ar": "ليفكادا",
                "label_fr": "Lefkada"
            },
            {
                "value": 51796,
                "label": "Panorama",
                "label_ar": "بانوراما",
                "label_fr": "Panorama"
            },
            {
                "value": 51838,
                "label": "Sparta",
                "label_ar": "سبارتا",
                "label_fr": "Sparte"
            },
            {
                "value": 51935,
                "label": "Kamatero",
                "label_ar": "كاماتيرو",
                "label_fr": "Kamatero"
            },
            {
                "value": 51844,
                "label": "Attica",
                "label_ar": "أتيكا",
                "label_fr": "Attique"
            },
            {
                "value": 51922,
                "label": "Skiathos",
                "label_ar": "سكياثوس",
                "label_fr": "Skiathos"
            },
            {
                "value": 51899,
                "label": "Mandra",
                "label_ar": "ماندرا",
                "label_fr": "Mandra"
            },
            {
                "value": 51869,
                "label": "Skala",
                "label_ar": "سكالا",
                "label_fr": "Skala"
            },
            {
                "value": 51834,
                "label": "Vergina",
                "label_ar": "فيرجينا",
                "label_fr": "Vergina"
            },
            {
                "value": 51947,
                "label": "Naousa",
                "label_ar": "ناوسا",
                "label_fr": "Naoussa"
            },
            {
                "value": 51790,
                "label": "Alimos",
                "label_ar": "أليموس",
                "label_fr": "Alimos"
            },
            {
                "value": 51940,
                "label": "Platy",
                "label_ar": "بلاتي",
                "label_fr": "Platy"
            },
            {
                "value": 51841,
                "label": "Nea Peramos",
                "label_ar": "نيا بيراموس",
                "label_fr": "Nea Peramos"
            },
            {
                "value": 51800,
                "label": "Komotini",
                "label_ar": "كوموتيني",
                "label_fr": "Komotini"
            },
            {
                "value": 51808,
                "label": "Megalochari",
                "label_ar": "ميغالوتشاري",
                "label_fr": "Megalochari"
            },
            {
                "value": 51847,
                "label": "Nafplion",
                "label_ar": "نافبليون",
                "label_fr": "Nauplie"
            },
            {
                "value": 51930,
                "label": "Filippiada",
                "label_ar": "Filippiada",
                "label_fr": "Filippiada"
            },
            {
                "value": 51864,
                "label": "Kalamaria",
                "label_ar": "كالاماريا",
                "label_fr": "Kalamaria"
            },
            {
                "value": 51933,
                "label": "Nea Filadelfeia",
                "label_ar": "نيا Filadelfeia",
                "label_fr": "Nea Filadelfeia"
            },
            {
                "value": 51938,
                "label": "Archaia Nemea",
                "label_ar": "أرشايا نيميا",
                "label_fr": "Archaia Nemea"
            },
            {
                "value": 51896,
                "label": "Thebes",
                "label_ar": "طيبة",
                "label_fr": "Thèbes"
            },
            {
                "value": 51891,
                "label": "Kouvaras",
                "label_ar": "كوفاراس",
                "label_fr": "Kouvaras"
            },
            {
                "value": 51874,
                "label": "Ialysos",
                "label_ar": "ياليسوس",
                "label_fr": "Ialysos"
            },
            {
                "value": 51780,
                "label": "Athens",
                "label_ar": "أثينا",
                "label_fr": "Athènes"
            },
            {
                "value": 51871,
                "label": "Kaisariani",
                "label_ar": "قيصرياني",
                "label_fr": "Kaisariani"
            },
            {
                "value": 51806,
                "label": "Tinos",
                "label_ar": "تينوس",
                "label_fr": "Tinos"
            },
            {
                "value": 51862,
                "label": "Volos",
                "label_ar": "فولوس",
                "label_fr": "Volos"
            },
            {
                "value": 51845,
                "label": "Magoula",
                "label_ar": "ماجولا",
                "label_fr": "Magoula"
            },
            {
                "value": 51842,
                "label": "Chios",
                "label_ar": "خيوس",
                "label_fr": "Chios"
            },
            {
                "value": 51786,
                "label": "Kos",
                "label_ar": "كوس",
                "label_fr": "Kos"
            },
            {
                "value": 51819,
                "label": "Cholargos",
                "label_ar": "تشولارجوس",
                "label_fr": "Cholargos"
            },
            {
                "value": 51854,
                "label": "Nea Ionia",
                "label_ar": "نيا إيونيا",
                "label_fr": "Nea Ionia"
            },
            {
                "value": 51860,
                "label": "Alexandreia",
                "label_ar": "الإسكندرية",
                "label_fr": "Alexandreia"
            },
            {
                "value": 51876,
                "label": "Kassiopi",
                "label_ar": "كاسيوبي",
                "label_fr": "Kassiopi"
            },
            {
                "value": 51795,
                "label": "Serres",
                "label_ar": "سيريس",
                "label_fr": "Serres"
            },
            {
                "value": 51863,
                "label": "Sindos",
                "label_ar": "السندوس",
                "label_fr": "Sindos"
            },
            {
                "value": 51900,
                "label": "Megara",
                "label_ar": "ميغارا",
                "label_fr": "Megara"
            },
            {
                "value": 51818,
                "label": "Heraklion",
                "label_ar": "هيراكليون",
                "label_fr": "Héraklion"
            },
            {
                "value": 51809,
                "label": "Kallithea",
                "label_ar": "كاليثيا",
                "label_fr": "Kallithea"
            },
            {
                "value": 51883,
                "label": "Hydra",
                "label_ar": "العدار",
                "label_fr": "Hydre"
            },
            {
                "value": 51880,
                "label": "Varkiza",
                "label_ar": "فاركيزا",
                "label_fr": "Varkiza"
            },
            {
                "value": 51783,
                "label": "Chalcis",
                "label_ar": "خالكيذا",
                "label_fr": "Chalcis"
            },
            {
                "value": 51789,
                "label": "Vouliagmeni",
                "label_ar": "فولياجميني",
                "label_fr": "Vouliagmeni"
            },
            {
                "value": 51909,
                "label": "Naxos",
                "label_ar": "ناكسوس",
                "label_fr": "Naxos"
            },
            {
                "value": 51898,
                "label": "Rio",
                "label_ar": "ريو",
                "label_fr": "Rio"
            },
            {
                "value": 51826,
                "label": "Aigio",
                "label_ar": "ايجيو",
                "label_fr": "Aigio"
            },
            {
                "value": 51887,
                "label": "Ano Liosia",
                "label_ar": "آنو ليوزيا",
                "label_fr": "Ano Liosia"
            },
            {
                "value": 51865,
                "label": "Goumenissa",
                "label_ar": "جومنيسا",
                "label_fr": "Goumenissa"
            },
            {
                "value": 51836,
                "label": "Xanthi",
                "label_ar": "زانثي",
                "label_fr": "Xanthi"
            },
            {
                "value": 51890,
                "label": "Keratea",
                "label_ar": "القرنية",
                "label_fr": "Keratea"
            },
            {
                "value": 51875,
                "label": "Patmos",
                "label_ar": "بطمس",
                "label_fr": "Patmos"
            },
            {
                "value": 51882,
                "label": "Kranidi",
                "label_ar": "كرانيدي",
                "label_fr": "Kranidi"
            },
            {
                "value": 51793,
                "label": "Servia",
                "label_ar": "سيرفيا",
                "label_fr": "Servie"
            },
            {
                "value": 51928,
                "label": "Pylaia",
                "label_ar": "بيلايا",
                "label_fr": "Pylaia"
            },
            {
                "value": 51829,
                "label": "Corfu",
                "label_ar": "كورفو",
                "label_fr": "Corfou"
            },
            {
                "value": 51792,
                "label": "Corinth",
                "label_ar": "كورنثوس",
                "label_fr": "Corinthe"
            },
            {
                "value": 51797,
                "label": "Katerini",
                "label_ar": "كاتريني",
                "label_fr": "Katerini"
            },
            {
                "value": 51893,
                "label": "Paloukia",
                "label_ar": "Paloukia",
                "label_fr": "Paloukia"
            },
            {
                "value": 51932,
                "label": "Lykovrysi",
                "label_ar": "ليكوفريسي",
                "label_fr": "Lykovrysi"
            },
            {
                "value": 51822,
                "label": "Piraeus",
                "label_ar": "بيرايوس",
                "label_fr": "Pirée"
            },
            {
                "value": 51913,
                "label": "Zakynthos",
                "label_ar": "زاكينثوس",
                "label_fr": "Zakynthos"
            },
            {
                "value": 51824,
                "label": "Aigaleo",
                "label_ar": "ايجاليو",
                "label_fr": "Aigaleo"
            },
            {
                "value": 51911,
                "label": "Nea Smyrni",
                "label_ar": "نيا سميرني",
                "label_fr": "Nea Smyrni"
            },
            {
                "value": 51931,
                "label": "Nea Alikarnassos",
                "label_ar": "نيا اليكارناسوس",
                "label_fr": "Nea Alikarnassos"
            },
            {
                "value": 51785,
                "label": "Rhodes",
                "label_ar": "رودس",
                "label_fr": "Rhodes"
            },
            {
                "value": 51812,
                "label": "Ioannina",
                "label_ar": "يوانينا",
                "label_fr": "Ioannina"
            },
            {
                "value": 51827,
                "label": "Messolonghi",
                "label_ar": "ميسولونجي",
                "label_fr": "Messolonghi"
            },
            {
                "value": 51810,
                "label": "Pallini",
                "label_ar": "باليني",
                "label_fr": "Pallini"
            },
            {
                "value": 51805,
                "label": "Paiania",
                "label_ar": "بايانيا",
                "label_fr": "Paiania"
            },
            {
                "value": 51866,
                "label": "Kastoria",
                "label_ar": "كاستوريا",
                "label_fr": "Kastoria"
            },
            {
                "value": 51941,
                "label": "Dionysos",
                "label_ar": "ديونيسوس",
                "label_fr": "Dionysos"
            },
            {
                "value": 51915,
                "label": "Lixouri",
                "label_ar": "ليكسوري",
                "label_fr": "Lixouri"
            },
            {
                "value": 51802,
                "label": "Metaxades",
                "label_ar": "Metaxades",
                "label_fr": "Métaxades"
            },
            {
                "value": 51872,
                "label": "Igoumenitsa",
                "label_ar": "إيغومينيتسا",
                "label_fr": "Igoumenitsa"
            },
            {
                "value": 51851,
                "label": "Kymi",
                "label_ar": "كيمي",
                "label_fr": "Kymi"
            },
            {
                "value": 51782,
                "label": "Thessaloniki",
                "label_ar": "ثيسالونيكي",
                "label_fr": "Thessalonique"
            },
            {
                "value": 51855,
                "label": "Kifissia",
                "label_ar": "كيفيسيا",
                "label_fr": "Kifissia"
            },
            {
                "value": 51788,
                "label": "Voula",
                "label_ar": "فولا",
                "label_fr": "Voula"
            },
            {
                "value": 51811,
                "label": "Nikaia",
                "label_ar": "نيكايا",
                "label_fr": "Nikaia"
            },
            {
                "value": 51821,
                "label": "Markopoulo Mesogaias",
                "label_ar": "ماركوبولو ميسوجياس",
                "label_fr": "Markopoulo Mesogéas"
            },
            {
                "value": 51924,
                "label": "Makrygialos",
                "label_ar": "ماكريجيالوس",
                "label_fr": "Makrygialos"
            },
            {
                "value": 51832,
                "label": "Veroia",
                "label_ar": "فيرويا",
                "label_fr": "Veroia"
            },
            {
                "value": 51837,
                "label": "Kavala",
                "label_ar": "كافالا",
                "label_fr": "Kavala"
            },
            {
                "value": 51895,
                "label": "Eretria",
                "label_ar": "إريتريا",
                "label_fr": "Érétrie"
            },
            {
                "value": 51907,
                "label": "Preveza",
                "label_ar": "بريفيزا",
                "label_fr": "Preveza"
            },
            {
                "value": 51813,
                "label": "Loutraki",
                "label_ar": "لوتراكي",
                "label_fr": "Loutraki"
            },
            {
                "value": 51873,
                "label": "Ierapetra",
                "label_ar": "إيرابيترا",
                "label_fr": "Ierapetra"
            },
            {
                "value": 51820,
                "label": "Agrinio",
                "label_ar": "أجرينيو",
                "label_fr": "Agrinio"
            },
            {
                "value": 51936,
                "label": "Gonnoi",
                "label_ar": "جونوي",
                "label_fr": "Gonnoi"
            },
            {
                "value": 51892,
                "label": "Porto Rafti",
                "label_ar": "بورتو رافتي",
                "label_fr": "Porto Rafti"
            },
            {
                "value": 51807,
                "label": "Psychiko",
                "label_ar": "بسيتشيكو",
                "label_fr": "Psychiko"
            },
            {
                "value": 51943,
                "label": "Symi",
                "label_ar": "سيمي",
                "label_fr": "Symi"
            },
            {
                "value": 51835,
                "label": "Ptolemaida",
                "label_ar": "بطليموس",
                "label_fr": "Ptolemaida"
            },
            {
                "value": 51889,
                "label": "Orestiada",
                "label_ar": "أوريستيادا",
                "label_fr": "Orestiada"
            },
            {
                "value": 51815,
                "label": "Argos",
                "label_ar": "أرغوس",
                "label_fr": "Argos"
            },
            {
                "value": 51804,
                "label": "Filothei",
                "label_ar": "فيلوثي",
                "label_fr": "Filothei"
            },
            {
                "value": 51840,
                "label": "Kalamata",
                "label_ar": "كالاماتا",
                "label_fr": "Kalamata"
            },
            {
                "value": 51944,
                "label": "Agios Nikolaos",
                "label_ar": "أجيوس نيكولاوس",
                "label_fr": "Agios Nikolaos"
            },
            {
                "value": 51852,
                "label": "Elefsina",
                "label_ar": "إلفسينا",
                "label_fr": "Elefsina"
            },
            {
                "value": 51925,
                "label": "Samothraki",
                "label_ar": "ساموثراكي",
                "label_fr": "Samothrace"
            },
            {
                "value": 51920,
                "label": "Kalamos",
                "label_ar": "كالاموس",
                "label_fr": "Kalamos"
            },
            {
                "value": 51831,
                "label": "Chania",
                "label_ar": "خانيا",
                "label_fr": "Chania"
            },
            {
                "value": 51942,
                "label": "Kryonerion",
                "label_ar": "كريونيريون",
                "label_fr": "Kryonerion"
            },
            {
                "value": 51859,
                "label": "Nea Makri",
                "label_ar": "نيا ماكري",
                "label_fr": "Nea Makri"
            },
            {
                "value": 51923,
                "label": "Skala Oropou",
                "label_ar": "سكالا أوروبو",
                "label_fr": "Skala Oropou"
            },
            {
                "value": 51914,
                "label": "Argostoli",
                "label_ar": "أرغوستولي",
                "label_fr": "Argostoli"
            },
            {
                "value": 51929,
                "label": "Rethymno",
                "label_ar": "ريثيمنو",
                "label_fr": "Réthymnon"
            },
            {
                "value": 51877,
                "label": "Anavyssos",
                "label_ar": "أنافيزوس",
                "label_fr": "Anavyssos"
            },
            {
                "value": 51904,
                "label": "Arta",
                "label_ar": "عرتا",
                "label_fr": "Arta"
            }
        ]
    },
    {
        "country": "Spain",
        "cities": [
            {
                "value": 53250,
                "label": "Llagostera",
                "label_ar": "Llagostera",
                "label_fr": "Llagostera"
            },
            {
                "value": 54403,
                "label": "Valdetorres",
                "label_ar": "فالديتوريس",
                "label_fr": "Valdetorres"
            },
            {
                "value": 53394,
                "label": "Odon",
                "label_ar": "أودون",
                "label_fr": "Odon"
            },
            {
                "value": 54328,
                "label": "Benicolet",
                "label_ar": "بينيكوليت",
                "label_fr": "Benicolet"
            },
            {
                "value": 53129,
                "label": "Coca",
                "label_ar": "كوكا",
                "label_fr": "Coca"
            },
            {
                "value": 52068,
                "label": "Bilbao",
                "label_ar": "بلباو",
                "label_fr": "Bilbao"
            },
            {
                "value": 52091,
                "label": "Valle",
                "label_ar": "فالي",
                "label_fr": "Valle"
            },
            {
                "value": 52033,
                "label": "Huercal Overa",
                "label_ar": "هويركال أوفر",
                "label_fr": "Huercal Overa"
            },
            {
                "value": 52381,
                "label": "Santiago Pena",
                "label_ar": "سانتياغو بينا",
                "label_fr": "Santiago Pena"
            },
            {
                "value": 54051,
                "label": "Gelsa",
                "label_ar": "جيلسا",
                "label_fr": "Gelsa"
            },
            {
                "value": 54310,
                "label": "Huerto",
                "label_ar": "هويرتو",
                "label_fr": "Huerto"
            },
            {
                "value": 53633,
                "label": "Fortia",
                "label_ar": "فورتيا",
                "label_fr": "Fortia"
            },
            {
                "value": 52843,
                "label": "Sabinanigo",
                "label_ar": "Sabinanigo",
                "label_fr": "Sabinanigo"
            },
            {
                "value": 53635,
                "label": "Illana",
                "label_ar": "إيلانا",
                "label_fr": "Illana"
            },
            {
                "value": 54449,
                "label": "Zahinos",
                "label_ar": "زاهينوس",
                "label_fr": "Zahinos"
            },
            {
                "value": 52966,
                "label": "Coria",
                "label_ar": "كوريا",
                "label_fr": "Coria"
            },
            {
                "value": 52606,
                "label": "Cabra",
                "label_ar": "كابرا",
                "label_fr": "Cabra"
            },
            {
                "value": 52392,
                "label": "Balaguer",
                "label_ar": "بالاغير",
                "label_fr": "Balaguer"
            },
            {
                "value": 52563,
                "label": "Puerto",
                "label_ar": "بويرتو",
                "label_fr": "Puerto"
            },
            {
                "value": 54379,
                "label": "Redes",
                "label_ar": "ريديس",
                "label_fr": "Redes"
            },
            {
                "value": 53629,
                "label": "Sevares",
                "label_ar": "سيفاريس",
                "label_fr": "Sevares"
            },
            {
                "value": 54068,
                "label": "Olivella",
                "label_ar": "أوليفيلا",
                "label_fr": "Olivella"
            },
            {
                "value": 52731,
                "label": "Ceuta",
                "label_ar": "سبتة",
                "label_fr": "Ceuta"
            },
            {
                "value": 52350,
                "label": "Oliva",
                "label_ar": "أوليفا",
                "label_fr": "Oliva"
            },
            {
                "value": 53566,
                "label": "Berastegui",
                "label_ar": "بيراستيغي",
                "label_fr": "Berastegui"
            },
            {
                "value": 53701,
                "label": "La Granja de San Vicente",
                "label_ar": "لا جرانجا دي سان فيسنتي",
                "label_fr": "La Granja de San Vicente"
            },
            {
                "value": 53805,
                "label": "Jerez de los Caballeros",
                "label_ar": "خيريز دي لوس كاباليروس",
                "label_fr": "Jerez de los Caballeros"
            },
            {
                "value": 53328,
                "label": "Espiel",
                "label_ar": "إسبيل",
                "label_fr": "Espiel"
            },
            {
                "value": 53334,
                "label": "Lagartera",
                "label_ar": "لاغارتيرا",
                "label_fr": "Lagartera"
            },
            {
                "value": 54351,
                "label": "San Vicente de Alcantara",
                "label_ar": "سان فيسنتي دي الكانتارا",
                "label_fr": "San Vicente de Alcantara"
            },
            {
                "value": 51926,
                "label": "Mostoles",
                "label_ar": "موستوليس",
                "label_fr": "Mostoles"
            },
            {
                "value": 52213,
                "label": "Nigran",
                "label_ar": "نيغران",
                "label_fr": "Nigran"
            },
            {
                "value": 52265,
                "label": "Alboraya",
                "label_ar": "البورايا",
                "label_fr": "Alboraya"
            },
            {
                "value": 53357,
                "label": "Los Montes",
                "label_ar": "لوس مونتيس",
                "label_fr": "Los Montes"
            },
            {
                "value": 52991,
                "label": "Torrijos",
                "label_ar": "توريخوس",
                "label_fr": "Torrijos"
            },
            {
                "value": 54457,
                "label": "Las Ventanas",
                "label_ar": "لاس فينتاناس",
                "label_fr": "Las Ventanas"
            },
            {
                "value": 53198,
                "label": "Cuarte de Huerva",
                "label_ar": "كوارتي دي هويرفا",
                "label_fr": "Cuarte de Huerva"
            },
            {
                "value": 53329,
                "label": "Recas",
                "label_ar": "ريكاس",
                "label_fr": "Recas"
            },
            {
                "value": 52695,
                "label": "Miguelturra",
                "label_ar": "ميغيلتورا",
                "label_fr": "Miguelturra"
            },
            {
                "value": 52850,
                "label": "Alcarras",
                "label_ar": "الكراس",
                "label_fr": "Alcarras"
            },
            {
                "value": 52665,
                "label": "Berga",
                "label_ar": "بيرجا",
                "label_fr": "Berga"
            },
            {
                "value": 52907,
                "label": "Igualeja",
                "label_ar": "إيغاليجا",
                "label_fr": "Igualeja"
            },
            {
                "value": 53450,
                "label": "La Granja",
                "label_ar": "لا جرانجا",
                "label_fr": "La Granja"
            },
            {
                "value": 52130,
                "label": "Fuentelapena",
                "label_ar": "فوينتيلابينا",
                "label_fr": "Fuentelapena"
            },
            {
                "value": 54404,
                "label": "Vilatenim",
                "label_ar": "فيلاتينيم",
                "label_fr": "Vilatenim"
            },
            {
                "value": 53649,
                "label": "Morro del Jable",
                "label_ar": "مورو ديل جيبلي",
                "label_fr": "Morro del Jable"
            },
            {
                "value": 51945,
                "label": "Illescas",
                "label_ar": "إليسكاس",
                "label_fr": "Illescas"
            },
            {
                "value": 52906,
                "label": "Cabanillas del Campo",
                "label_ar": "كابانيلاس ديل كامبو",
                "label_fr": "Cabanillas del Campo"
            },
            {
                "value": 53325,
                "label": "Salcedo",
                "label_ar": "سالسيدو",
                "label_fr": "Salcedo"
            },
            {
                "value": 52310,
                "label": "Anora",
                "label_ar": "أنورا",
                "label_fr": "Anora"
            },
            {
                "value": 53408,
                "label": "Llano",
                "label_ar": "لانو",
                "label_fr": "Llano"
            },
            {
                "value": 53931,
                "label": "Chozas de Canales",
                "label_ar": "تشوزاس دي كاناليس",
                "label_fr": "Chozas de Canales"
            },
            {
                "value": 53126,
                "label": "Pedrajas de San Esteban",
                "label_ar": "بيدراجاس دي سان إستيبان",
                "label_fr": "Pedrajas de San Esteban"
            },
            {
                "value": 54464,
                "label": "Sobremazas",
                "label_ar": "سوبريمازاس",
                "label_fr": "Sobremazas"
            },
            {
                "value": 52411,
                "label": "Alhaurin el Grande",
                "label_ar": "Alhaurin el Grande",
                "label_fr": "Alhaurin el Grande"
            },
            {
                "value": 54311,
                "label": "Panillo",
                "label_ar": "بانيلو",
                "label_fr": "Panillo"
            },
            {
                "value": 54172,
                "label": "Cabezarrubias del Puerto",
                "label_ar": "كابيزاروبياس ديل بويرتو",
                "label_fr": "Cabezarrubias del Puerto"
            },
            {
                "value": 52292,
                "label": "Abrera",
                "label_ar": "أبريرا",
                "label_fr": "Abrera"
            },
            {
                "value": 54049,
                "label": "Calig",
                "label_ar": "كاليج",
                "label_fr": "Calig"
            },
            {
                "value": 54181,
                "label": "Alpicat",
                "label_ar": "ألبيكات",
                "label_fr": "Alpicat"
            },
            {
                "value": 52254,
                "label": "Palma de Mallorca",
                "label_ar": "بالما دي مايوركا",
                "label_fr": "Palma de Majorque"
            },
            {
                "value": 53646,
                "label": "Sotogrande",
                "label_ar": "سوتوجراندي",
                "label_fr": "Sotogrande"
            },
            {
                "value": 53090,
                "label": "Marin",
                "label_ar": "مارين",
                "label_fr": "Marin"
            },
            {
                "value": 52520,
                "label": "Pilas",
                "label_ar": "بيلاس",
                "label_fr": "Pilas"
            },
            {
                "value": 52536,
                "label": "Alcoletge",
                "label_ar": "الكوليتج",
                "label_fr": "Alcoletge"
            },
            {
                "value": 53992,
                "label": "Legasa",
                "label_ar": "ليغاسا",
                "label_fr": "Legasa"
            },
            {
                "value": 53850,
                "label": "Lorqui",
                "label_ar": "لوركي",
                "label_fr": "Lorqui"
            },
            {
                "value": 53441,
                "label": "Zarzuela",
                "label_ar": "زارزويلا",
                "label_fr": "Zarzuela"
            },
            {
                "value": 53120,
                "label": "Benameji",
                "label_ar": "بناميجي",
                "label_fr": "Benameji"
            },
            {
                "value": 52438,
                "label": "Llanera",
                "label_ar": "يانيرا",
                "label_fr": "Llanera"
            },
            {
                "value": 52335,
                "label": "Rojales",
                "label_ar": "روجاليس",
                "label_fr": "Rojales"
            },
            {
                "value": 53195,
                "label": "Medinya",
                "label_ar": "المدينة المنورة",
                "label_fr": "Medinya"
            },
            {
                "value": 54167,
                "label": "Pioz",
                "label_ar": "بيوز",
                "label_fr": "Pioz"
            },
            {
                "value": 54398,
                "label": "Guzman",
                "label_ar": "جوزمان",
                "label_fr": "Guzman"
            },
            {
                "value": 52060,
                "label": "Bonares",
                "label_ar": "بوناريس",
                "label_fr": "Bonares"
            },
            {
                "value": 54133,
                "label": "Real de Gandia",
                "label_ar": "ريال دي جانديا",
                "label_fr": "Real de Gandia"
            },
            {
                "value": 54145,
                "label": "Valdepenas de la Sierra",
                "label_ar": "فالديبيناس دي لا سييرا",
                "label_fr": "Valdepenas de la Sierra"
            },
            {
                "value": 52111,
                "label": "Corrales",
                "label_ar": "كوراليس",
                "label_fr": "Corrales"
            },
            {
                "value": 53190,
                "label": "Puerto",
                "label_ar": "بويرتو",
                "label_fr": "Puerto"
            },
            {
                "value": 53317,
                "label": "Mula",
                "label_ar": "مولا",
                "label_fr": "Mula"
            },
            {
                "value": 53229,
                "label": "Pravia",
                "label_ar": "برافيا",
                "label_fr": "Pravia"
            },
            {
                "value": 54071,
                "label": "Villaviciosa",
                "label_ar": "فيلافيسيوزا",
                "label_fr": "Villaviciosa"
            },
            {
                "value": 52363,
                "label": "Nules",
                "label_ar": "نولي",
                "label_fr": "Nules"
            },
            {
                "value": 52912,
                "label": "La Puerta de Segura",
                "label_ar": "لا بويرتا دي سيجورا",
                "label_fr": "La Puerta de Segura"
            },
            {
                "value": 53078,
                "label": "Gironella",
                "label_ar": "جيرونيلا",
                "label_fr": "Gironella"
            },
            {
                "value": 52151,
                "label": "Marchena",
                "label_ar": "مارشينا",
                "label_fr": "Marchena"
            },
            {
                "value": 54143,
                "label": "Borben",
                "label_ar": "بوربن",
                "label_fr": "Borben"
            },
            {
                "value": 54065,
                "label": "Cigales",
                "label_ar": "سيغال",
                "label_fr": "Cigales"
            },
            {
                "value": 54183,
                "label": "Navezuelas",
                "label_ar": "نافيزولاس",
                "label_fr": "Navezuelas"
            },
            {
                "value": 53796,
                "label": "Mont",
                "label_ar": "مونت",
                "label_fr": "Mont"
            },
            {
                "value": 53991,
                "label": "San Adrian",
                "label_ar": "سان ادريان",
                "label_fr": "San Adrian"
            },
            {
                "value": 52069,
                "label": "Guadix",
                "label_ar": "جواديكس",
                "label_fr": "Guadix"
            },
            {
                "value": 51989,
                "label": "Granollers",
                "label_ar": "جرانولرز",
                "label_fr": "Granollers"
            },
            {
                "value": 52041,
                "label": "Ametlla",
                "label_ar": "أميتلا",
                "label_fr": "Ametlla"
            },
            {
                "value": 54316,
                "label": "Catadau",
                "label_ar": "كاتادو",
                "label_fr": "Catadau"
            },
            {
                "value": 53517,
                "label": "Benissa",
                "label_ar": "بن عيسى",
                "label_fr": "Benissa"
            },
            {
                "value": 54209,
                "label": "Barcena de Cicero",
                "label_ar": "باركينا دي شيشرون",
                "label_fr": "Barcena de Cicero"
            },
            {
                "value": 54178,
                "label": "la Baronia de Rialb",
                "label_ar": "لا بارونيا دي ريالب",
                "label_fr": "la Baronia de Rialb"
            },
            {
                "value": 53745,
                "label": "Bullas",
                "label_ar": "بولس",
                "label_fr": "Bullas"
            },
            {
                "value": 52077,
                "label": "Azuaga",
                "label_ar": "أزواجا",
                "label_fr": "Azuaga"
            },
            {
                "value": 52103,
                "label": "Icod de los Vinos",
                "label_ar": "آيكود دي لوس فينوس",
                "label_fr": "Icod de los Vinos"
            },
            {
                "value": 52782,
                "label": "Piedrabuena",
                "label_ar": "بيدرابوينا",
                "label_fr": "Piedrabuena"
            },
            {
                "value": 52281,
                "label": "Alfafar",
                "label_ar": "الفافار",
                "label_fr": "Alfafar"
            },
            {
                "value": 54066,
                "label": "Fuentes de Valdepero",
                "label_ar": "فوينتيس دي فالديبيرو",
                "label_fr": "Fuentes de Valdepero"
            },
            {
                "value": 54138,
                "label": "Tenteniguada",
                "label_ar": "Tenteniguada",
                "label_fr": "Tenteniguada"
            },
            {
                "value": 53460,
                "label": "Santa Maria del Campo",
                "label_ar": "سانتا ماريا ديل كامبو",
                "label_fr": "Santa Maria del Campo"
            },
            {
                "value": 53634,
                "label": "Mutiloa",
                "label_ar": "موتيلوا",
                "label_fr": "Mutiloa"
            },
            {
                "value": 52678,
                "label": "Monzon",
                "label_ar": "مونزون",
                "label_fr": "Monzon"
            },
            {
                "value": 53596,
                "label": "Cabezo de Torres",
                "label_ar": "كابيزو دي توريس",
                "label_fr": "Cabezo de Torres"
            },
            {
                "value": 53356,
                "label": "Sanlucar la Mayor",
                "label_ar": "سانلوكار لا مايور",
                "label_fr": "Sanlucar la Mayor"
            },
            {
                "value": 54302,
                "label": "Lezo",
                "label_ar": "ليزو",
                "label_fr": "Lezo"
            },
            {
                "value": 54293,
                "label": "Almuna",
                "label_ar": "المنى",
                "label_fr": "Almuna"
            },
            {
                "value": 52948,
                "label": "Oliana",
                "label_ar": "أوليانا",
                "label_fr": "Oliana"
            },
            {
                "value": 52287,
                "label": "Calafell",
                "label_ar": "كالافيل",
                "label_fr": "Calafell"
            },
            {
                "value": 52432,
                "label": "Benidorm",
                "label_ar": "بنيدورم",
                "label_fr": "Benidorm"
            },
            {
                "value": 52388,
                "label": "Cheste",
                "label_ar": "تشيستي",
                "label_fr": "Cheste"
            },
            {
                "value": 54154,
                "label": "Pruna",
                "label_ar": "برونا",
                "label_fr": "Pruna"
            },
            {
                "value": 53215,
                "label": "Villatuerta",
                "label_ar": "فيلاتويرتا",
                "label_fr": "Villatuerta"
            },
            {
                "value": 53131,
                "label": "Huercanos",
                "label_ar": "هويركانوس",
                "label_fr": "Huercanos"
            },
            {
                "value": 52990,
                "label": "Cangas de Onis",
                "label_ar": "كانغاس دي اونيس",
                "label_fr": "Cangas de Onis"
            },
            {
                "value": 53756,
                "label": "Itza",
                "label_ar": "إيتزا",
                "label_fr": "Itza"
            },
            {
                "value": 53420,
                "label": "Guardamar del Segura",
                "label_ar": "جواردامار ديل سيجورا",
                "label_fr": "Guardamar del Segura"
            },
            {
                "value": 52121,
                "label": "El Astillero",
                "label_ar": "إل أستيليرو",
                "label_fr": "El Astillero"
            },
            {
                "value": 52437,
                "label": "Jaraiz de la Vera",
                "label_ar": "Jaraiz de la Vera",
                "label_fr": "Jaraiz de la Vera"
            },
            {
                "value": 54122,
                "label": "Sierra de Luna",
                "label_ar": "سييرا دي لونا",
                "label_fr": "Sierra de Luna"
            },
            {
                "value": 52832,
                "label": "Portocolom",
                "label_ar": "بورتوكولوم",
                "label_fr": "Portocolom"
            },
            {
                "value": 53894,
                "label": "San Isidro",
                "label_ar": "سان ايسيدرو",
                "label_fr": "San Isidro"
            },
            {
                "value": 52172,
                "label": "Castro Urdiales",
                "label_ar": "كاسترو أوردياليس",
                "label_fr": "Castro Urdiales"
            },
            {
                "value": 52668,
                "label": "Mieres",
                "label_ar": "ميريس",
                "label_fr": "Mieres"
            },
            {
                "value": 53692,
                "label": "El Viso del Alcor",
                "label_ar": "El Viso del Alcor",
                "label_fr": "El Viso del Alcor"
            },
            {
                "value": 52848,
                "label": "Villarrubia de Santiago",
                "label_ar": "فيلاروبيا دي سانتياغو",
                "label_fr": "Villarrubia de Santiago"
            },
            {
                "value": 52028,
                "label": "Cuevas del Almanzora",
                "label_ar": "كويفاس ديل المنصورة",
                "label_fr": "Cuevas del Almanzora"
            },
            {
                "value": 52454,
                "label": "Melilla",
                "label_ar": "مليلية",
                "label_fr": "Melilla"
            },
            {
                "value": 53653,
                "label": "Costur",
                "label_ar": "كوستور",
                "label_fr": "Costur"
            },
            {
                "value": 53269,
                "label": "Lamadrid",
                "label_ar": "لامادريد",
                "label_fr": "Lamadrid"
            },
            {
                "value": 53312,
                "label": "Calasparra",
                "label_ar": "كالاسبارا",
                "label_fr": "Calasparra"
            },
            {
                "value": 52221,
                "label": "Estella-Lizarra",
                "label_ar": "إستيلا ليزارا",
                "label_fr": "Estella-Lizarra"
            },
            {
                "value": 52587,
                "label": "Ordes",
                "label_ar": "أورديس",
                "label_fr": "Ordes"
            },
            {
                "value": 53428,
                "label": "Calo",
                "label_ar": "كالو",
                "label_fr": "Calo"
            },
            {
                "value": 53331,
                "label": "Balsareny",
                "label_ar": "بالساريني",
                "label_fr": "Balsareny"
            },
            {
                "value": 52805,
                "label": "Prado del Rey",
                "label_ar": "برادو ديل ري",
                "label_fr": "Prado del Rey"
            },
            {
                "value": 53860,
                "label": "Castellvi de Rosanes",
                "label_ar": "كاستيلفي دي روزانيس",
                "label_fr": "Castellvi de Rosanes"
            },
            {
                "value": 52508,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 54105,
                "label": "Guijuelo",
                "label_ar": "جويويلو",
                "label_fr": "Guijuelo"
            },
            {
                "value": 53835,
                "label": "Zubiri",
                "label_ar": "الزبيري",
                "label_fr": "Zubiri"
            },
            {
                "value": 52786,
                "label": "Santalla",
                "label_ar": "سانتالا",
                "label_fr": "Santalla"
            },
            {
                "value": 52641,
                "label": "La Línea de la Concepción",
                "label_ar": "لا لينيا دي لا كونسيبسيون",
                "label_fr": "La Línea de la Concepción"
            },
            {
                "value": 51963,
                "label": "Tarragona",
                "label_ar": "تاراغونا",
                "label_fr": "Tarragone"
            },
            {
                "value": 52910,
                "label": "Minas de Riotinto",
                "label_ar": "ميناس دي ريوتينتو",
                "label_fr": "Minas de Riotinto"
            },
            {
                "value": 53209,
                "label": "Elorrio",
                "label_ar": "إلوريو",
                "label_fr": "Elorrio"
            },
            {
                "value": 54352,
                "label": "Cervo",
                "label_ar": "سيرفو",
                "label_fr": "Cervo"
            },
            {
                "value": 54124,
                "label": "Gamonal",
                "label_ar": "جامونال",
                "label_fr": "Gamonal"
            },
            {
                "value": 52954,
                "label": "Castro",
                "label_ar": "كاسترو",
                "label_fr": "Castro"
            },
            {
                "value": 52223,
                "label": "Berriozar",
                "label_ar": "بريوزار",
                "label_fr": "Berriozar"
            },
            {
                "value": 54358,
                "label": "Alcala del Obispo",
                "label_ar": "الكالا ديل أوبيسبو",
                "label_fr": "Alcala del Obispo"
            },
            {
                "value": 52282,
                "label": "Corbera de Llobregat",
                "label_ar": "كوربيرا دي يوبريغات",
                "label_fr": "Corbera de Llobregat"
            },
            {
                "value": 54013,
                "label": "Santo Angel",
                "label_ar": "سانتو انجيل",
                "label_fr": "Santo Angel"
            },
            {
                "value": 52640,
                "label": "Lardero",
                "label_ar": "لارديرو",
                "label_fr": "Lardero"
            },
            {
                "value": 52127,
                "label": "Azuqueca de Henares",
                "label_ar": "أزوكويكا دي إيناريس",
                "label_fr": "Azuqueca de Henares"
            },
            {
                "value": 53617,
                "label": "Bigues i Riells",
                "label_ar": "Bigues i Riells",
                "label_fr": "Bigues et Riells"
            },
            {
                "value": 52189,
                "label": "Pedreguer",
                "label_ar": "بيدريغير",
                "label_fr": "Pedreguer"
            },
            {
                "value": 53071,
                "label": "Albalat dels Tarongers",
                "label_ar": "البلات dels Tarongers",
                "label_fr": "Albalat dels Tarongers"
            },
            {
                "value": 53376,
                "label": "Torredembarra",
                "label_ar": "توريديمبارا",
                "label_fr": "Torredembarra"
            },
            {
                "value": 52777,
                "label": "Santa Lucia",
                "label_ar": "سانتا لوسيا",
                "label_fr": "Santa Lucia"
            },
            {
                "value": 52974,
                "label": "Chelva",
                "label_ar": "شيلفا",
                "label_fr": "Chelva"
            },
            {
                "value": 54317,
                "label": "Cala Ratjada",
                "label_ar": "كالا راتجادا",
                "label_fr": "Cala Ratjada"
            },
            {
                "value": 53551,
                "label": "La Serna",
                "label_ar": "لا سيرنا",
                "label_fr": "La Serna"
            },
            {
                "value": 52977,
                "label": "Ocana",
                "label_ar": "أوكانا",
                "label_fr": "Ocana"
            },
            {
                "value": 54254,
                "label": "Villanueva de Azoague",
                "label_ar": "فيلانويفا دي أزواج",
                "label_fr": "Villanueva de Azoague"
            },
            {
                "value": 52067,
                "label": "Sabiote",
                "label_ar": "Sabiote",
                "label_fr": "Sabiote"
            },
            {
                "value": 51987,
                "label": "L'Hospitalet de Llobregat",
                "label_ar": "لهوسبيتالت دي لوبريغات",
                "label_fr": "L'Hospitalet de Llobregat"
            },
            {
                "value": 54258,
                "label": "Serena",
                "label_ar": "سيرينا",
                "label_fr": "Serena"
            },
            {
                "value": 52813,
                "label": "Breda",
                "label_ar": "بريدا",
                "label_fr": "Breda"
            },
            {
                "value": 54249,
                "label": "Valverde del Majano",
                "label_ar": "فالفيردي ديل ماجانو",
                "label_fr": "Valverde del Majano"
            },
            {
                "value": 52423,
                "label": "Oviedo",
                "label_ar": "أوفييدو",
                "label_fr": "Oviedo"
            },
            {
                "value": 53020,
                "label": "Pueyo",
                "label_ar": "بويو",
                "label_fr": "Pueyo"
            },
            {
                "value": 51930,
                "label": "Humanes de Madrid",
                "label_ar": "البشر دي مدريد",
                "label_fr": "Humanes de Madrid"
            },
            {
                "value": 53297,
                "label": "Constanti",
                "label_ar": "كونستانتى",
                "label_fr": "Constanti"
            },
            {
                "value": 53686,
                "label": "Cuellar",
                "label_ar": "كويلار",
                "label_fr": "Cuellar"
            },
            {
                "value": 53958,
                "label": "Súria",
                "label_ar": "سوريا",
                "label_fr": "Súria"
            },
            {
                "value": 54318,
                "label": "Carbajosa de la Sagrada",
                "label_ar": "Carbajosa de la Sagrada",
                "label_fr": "Carbajosa de la Sagrada"
            },
            {
                "value": 51924,
                "label": "Alcorcón",
                "label_ar": "ألكوركون",
                "label_fr": "Alcorcón"
            },
            {
                "value": 54440,
                "label": "Canal",
                "label_ar": "قناة",
                "label_fr": "Canal"
            },
            {
                "value": 52171,
                "label": "Manzanares",
                "label_ar": "مانزاناريس",
                "label_fr": "Manzanares"
            },
            {
                "value": 53009,
                "label": "La Yedra",
                "label_ar": "لا يدرا",
                "label_fr": "La Yedra"
            },
            {
                "value": 52758,
                "label": "Catral",
                "label_ar": "كاترال",
                "label_fr": "Catral"
            },
            {
                "value": 52543,
                "label": "Urtuella",
                "label_ar": "Urtuella",
                "label_fr": "Urtuella"
            },
            {
                "value": 52814,
                "label": "La Bisbal",
                "label_ar": "لا بيسبال",
                "label_fr": "La Bisbal"
            },
            {
                "value": 52315,
                "label": "Los Palacios y Villafranca",
                "label_ar": "Los Palacios y Villafranca",
                "label_fr": "Los Palacios y Villafranca"
            },
            {
                "value": 53182,
                "label": "Son Curt",
                "label_ar": "سون كيرت",
                "label_fr": "Fils Curt"
            },
            {
                "value": 53659,
                "label": "La Campana",
                "label_ar": "لا كامبانا",
                "label_fr": "La Campana"
            },
            {
                "value": 51943,
                "label": "Alcalá de Henares",
                "label_ar": "الكالا دي إيناريس",
                "label_fr": "Alcalá de Henares"
            },
            {
                "value": 52311,
                "label": "Penarroya-Pueblonuevo",
                "label_ar": "Penarroya-Pueblonuevo",
                "label_fr": "Penarroya-Pueblonuevo"
            },
            {
                "value": 53339,
                "label": "Alora",
                "label_ar": "الورا",
                "label_fr": "Alora"
            },
            {
                "value": 53130,
                "label": "Segovia",
                "label_ar": "سيغوفيا",
                "label_fr": "Ségovie"
            },
            {
                "value": 53293,
                "label": "Vallbona d'Anoia",
                "label_ar": "فالبونا دانويا",
                "label_fr": "Vallbona d'Anoia"
            },
            {
                "value": 52994,
                "label": "Horta de Sant Joan",
                "label_ar": "هورتا دي سانت جوان",
                "label_fr": "Horta de Sant Joan"
            },
            {
                "value": 54326,
                "label": "Santa Cristina",
                "label_ar": "سانتا كريستينا",
                "label_fr": "Santa Cristina"
            },
            {
                "value": 53134,
                "label": "Cenicientos",
                "label_ar": "سينتوس",
                "label_fr": "Cenicientos"
            },
            {
                "value": 53220,
                "label": "Cubas",
                "label_ar": "كوباس",
                "label_fr": "Cubas"
            },
            {
                "value": 52521,
                "label": "Alcossebre",
                "label_ar": "الكوسيبري",
                "label_fr": "Alcossebre"
            },
            {
                "value": 52804,
                "label": "Poblete",
                "label_ar": "بوبليت",
                "label_fr": "Poblete"
            },
            {
                "value": 53956,
                "label": "el Catllar",
                "label_ar": "ش كاتلار",
                "label_fr": "el Catllar"
            },
            {
                "value": 53891,
                "label": "San Rafael",
                "label_ar": "سان رافائيل",
                "label_fr": "San Rafael"
            },
            {
                "value": 53873,
                "label": "Brihuega",
                "label_ar": "بريهويغا",
                "label_fr": "Brihuega"
            },
            {
                "value": 51919,
                "label": "Pallejà",
                "label_ar": "باليجا",
                "label_fr": "Pallejà"
            },
            {
                "value": 51947,
                "label": "Mataelpino",
                "label_ar": "ماتيلبينو",
                "label_fr": "Mataelpino"
            },
            {
                "value": 54321,
                "label": "Rafal",
                "label_ar": "رافال",
                "label_fr": "Rafal"
            },
            {
                "value": 54466,
                "label": "A Cidade",
                "label_ar": "سيداد",
                "label_fr": "Une cidade"
            },
            {
                "value": 53665,
                "label": "La Perdoma",
                "label_ar": "لا بيردوما",
                "label_fr": "La Perdoma"
            },
            {
                "value": 53083,
                "label": "Albalate de Cinca",
                "label_ar": "البالات دي سينكا",
                "label_fr": "Albalate de Cinca"
            },
            {
                "value": 52857,
                "label": "Alcantara",
                "label_ar": "الكانتارا",
                "label_fr": "Alcantara"
            },
            {
                "value": 52650,
                "label": "Irura",
                "label_ar": "إيرورا",
                "label_fr": "Irura"
            },
            {
                "value": 53309,
                "label": "Santiago de la Ribera",
                "label_ar": "سانتياغو دي لا ريبيرا",
                "label_fr": "Santiago de la Ribera"
            },
            {
                "value": 52975,
                "label": "San Nicolas",
                "label_ar": "سان نيكولاس",
                "label_fr": "San Nicolas"
            },
            {
                "value": 53151,
                "label": "Playa Blanca",
                "label_ar": "بلايا بلانكا",
                "label_fr": "Playa Blanca"
            },
            {
                "value": 52938,
                "label": "Cabrerizos",
                "label_ar": "Cabrerizos",
                "label_fr": "Cabrerizos"
            },
            {
                "value": 52131,
                "label": "Torroella de Montgri",
                "label_ar": "Torroella de Montgri",
                "label_fr": "Torroella de Montgri"
            },
            {
                "value": 54102,
                "label": "Tordillos",
                "label_ar": "تورديلوس",
                "label_fr": "Tordillos"
            },
            {
                "value": 54491,
                "label": "Lires",
                "label_ar": "ليريس",
                "label_fr": "Lires"
            },
            {
                "value": 52810,
                "label": "Borriol",
                "label_ar": "بوريول",
                "label_fr": "Borriol"
            },
            {
                "value": 52946,
                "label": "Boltana",
                "label_ar": "بولتانا",
                "label_fr": "Boltana"
            },
            {
                "value": 53742,
                "label": "Pajara",
                "label_ar": "باجارا",
                "label_fr": "Pajara"
            },
            {
                "value": 53056,
                "label": "Andoain",
                "label_ar": "أندوين",
                "label_fr": "Andoain"
            },
            {
                "value": 53679,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 54058,
                "label": "Trebujena",
                "label_ar": "تريبوجينا",
                "label_fr": "Trebujena"
            },
            {
                "value": 53310,
                "label": "Padrón",
                "label_ar": "بادرون",
                "label_fr": "Padrón"
            },
            {
                "value": 53980,
                "label": "Terrer",
                "label_ar": "تيرير",
                "label_fr": "Terrer"
            },
            {
                "value": 53668,
                "label": "Motilla del Palancar",
                "label_ar": "موتيلا ديل بالانكار",
                "label_fr": "Motilla del Palancar"
            },
            {
                "value": 51997,
                "label": "Perales del Alfambra",
                "label_ar": "بيراليس ديل ألفامبرا",
                "label_fr": "Perales del Alfambra"
            },
            {
                "value": 53000,
                "label": "Sarria",
                "label_ar": "ساريا",
                "label_fr": "Sarria"
            },
            {
                "value": 52642,
                "label": "Amposta",
                "label_ar": "امبوستا",
                "label_fr": "Amposta"
            },
            {
                "value": 53378,
                "label": "Alfondeguilla",
                "label_ar": "الفونديغيلا",
                "label_fr": "Alfondeguilla"
            },
            {
                "value": 52639,
                "label": "Vila",
                "label_ar": "فيلا",
                "label_fr": "Vila"
            },
            {
                "value": 53968,
                "label": "Torrenueva",
                "label_ar": "تورينويفا",
                "label_fr": "Torrenueva"
            },
            {
                "value": 52297,
                "label": "Martorell",
                "label_ar": "مارتوريل",
                "label_fr": "Martorell"
            },
            {
                "value": 52764,
                "label": "Escalonilla",
                "label_ar": "اسكالونيلا",
                "label_fr": "Escalonilla"
            },
            {
                "value": 54427,
                "label": "Moreda",
                "label_ar": "موريدا",
                "label_fr": "Moreda"
            },
            {
                "value": 54227,
                "label": "Colera",
                "label_ar": "كوليرا",
                "label_fr": "Colera"
            },
            {
                "value": 54472,
                "label": "Segura",
                "label_ar": "سيجورا",
                "label_fr": "Segura"
            },
            {
                "value": 53380,
                "label": "La Puebla de Almoradiel",
                "label_ar": "لا بويبلا دي المراديل",
                "label_fr": "La Puebla de Almoradiel"
            },
            {
                "value": 54343,
                "label": "Illa",
                "label_ar": "إلا",
                "label_fr": "Illa"
            },
            {
                "value": 54226,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 52182,
                "label": "Santa Lucia",
                "label_ar": "سانتا لوسيا",
                "label_fr": "Santa Lucia"
            },
            {
                "value": 52567,
                "label": "Capela",
                "label_ar": "كابيلا",
                "label_fr": "Capela"
            },
            {
                "value": 54489,
                "label": "Beznar",
                "label_ar": "بيزنار",
                "label_fr": "Beznar"
            },
            {
                "value": 52229,
                "label": "Narón",
                "label_ar": "نارون",
                "label_fr": "Narón"
            },
            {
                "value": 54313,
                "label": "Otura",
                "label_ar": "أوتورا",
                "label_fr": "Otura"
            },
            {
                "value": 52405,
                "label": "Arenys de Munt",
                "label_ar": "أرينيس دي مونت",
                "label_fr": "Arenys de Munt"
            },
            {
                "value": 51972,
                "label": "Balsicas",
                "label_ar": "بالسيكاس",
                "label_fr": "Balsicas"
            },
            {
                "value": 54280,
                "label": "Monforte del Cid",
                "label_ar": "مونفورتي ديل سيد",
                "label_fr": "Monforte del Cid"
            },
            {
                "value": 53148,
                "label": "Tortola de Henares",
                "label_ar": "تورتولا دي إيناريس",
                "label_fr": "Tortola de Henares"
            },
            {
                "value": 53233,
                "label": "Cunit",
                "label_ar": "كونيت",
                "label_fr": "Cunit"
            },
            {
                "value": 51958,
                "label": "Arganda",
                "label_ar": "أرجاندا",
                "label_fr": "Arganda"
            },
            {
                "value": 53758,
                "label": "Babilafuente",
                "label_ar": "Babilafuente",
                "label_fr": "Babilafuente"
            },
            {
                "value": 53598,
                "label": "Fasnia",
                "label_ar": "فاسنيا",
                "label_fr": "Fasnia"
            },
            {
                "value": 54093,
                "label": "Cadreita",
                "label_ar": "كاديريتا",
                "label_fr": "Cadreita"
            },
            {
                "value": 53628,
                "label": "Encinasola",
                "label_ar": "إنسيناسولا",
                "label_fr": "Encinasola"
            },
            {
                "value": 53313,
                "label": "Villafranca",
                "label_ar": "فيلافرانكا",
                "label_fr": "Villafranca"
            },
            {
                "value": 53812,
                "label": "San Bartolomé",
                "label_ar": "سان بارتولومي",
                "label_fr": "San Bartolomé"
            },
            {
                "value": 53746,
                "label": "Alba de Tormes",
                "label_ar": "ألبا دي تورميس",
                "label_fr": "Alba de Tormes"
            },
            {
                "value": 52039,
                "label": "Vilanova del Camí",
                "label_ar": "فيلانوفا ديل كامي",
                "label_fr": "Vilanova del Camí"
            },
            {
                "value": 53829,
                "label": "San Blas",
                "label_ar": "سان بلاس",
                "label_fr": "San Blas"
            },
            {
                "value": 53630,
                "label": "Valverde del Fresno",
                "label_ar": "فالفيردي ديل فريسنو",
                "label_fr": "Valverde del Fresno"
            },
            {
                "value": 52830,
                "label": "Navarrete",
                "label_ar": "نافاريتي",
                "label_fr": "Navarrete"
            },
            {
                "value": 53374,
                "label": "Esplugues de Llobregat",
                "label_ar": "Esplugues de Llobregat",
                "label_fr": "Esplugues de Llobregat"
            },
            {
                "value": 52436,
                "label": "Catarroja",
                "label_ar": "كاتاروجا",
                "label_fr": "Catarroja"
            },
            {
                "value": 53674,
                "label": "Villaverde de los Cestos",
                "label_ar": "فيلافيردي دي لوس سيستوس",
                "label_fr": "Villaverde de los Cestos"
            },
            {
                "value": 53109,
                "label": "San Esteban del Valle",
                "label_ar": "سان إستيبان ديل فالي",
                "label_fr": "San Esteban del Valle"
            },
            {
                "value": 52029,
                "label": "Viator",
                "label_ar": "فاياتور",
                "label_fr": "Viator"
            },
            {
                "value": 52620,
                "label": "Arce",
                "label_ar": "Arce",
                "label_fr": "Arce"
            },
            {
                "value": 54365,
                "label": "Alhambra",
                "label_ar": "قصر الحمراء",
                "label_fr": "Alhambra"
            },
            {
                "value": 52985,
                "label": "La Llosa",
                "label_ar": "لا يوسا",
                "label_fr": "La Llosa"
            },
            {
                "value": 53104,
                "label": "Altafulla",
                "label_ar": "التفولا",
                "label_fr": "Altafulla"
            },
            {
                "value": 52765,
                "label": "Villasequilla de Yepes",
                "label_ar": "فيلاسيكيلا دي ييبس",
                "label_fr": "Villasequilla de Yepes"
            },
            {
                "value": 54251,
                "label": "Langa",
                "label_ar": "لانجا",
                "label_fr": "Langa"
            },
            {
                "value": 52016,
                "label": "Ermua",
                "label_ar": "ارموا",
                "label_fr": "Ermua"
            },
            {
                "value": 53471,
                "label": "Fitero",
                "label_ar": "فيتيرو",
                "label_fr": "Fitero"
            },
            {
                "value": 53725,
                "label": "Cazalegas",
                "label_ar": "كازاليجاس",
                "label_fr": "Cazalegas"
            },
            {
                "value": 53185,
                "label": "Villamediana de Iregua",
                "label_ar": "فيلامديانا دي إيريغوا",
                "label_fr": "Villamediana de Iregua"
            },
            {
                "value": 53030,
                "label": "Saldana",
                "label_ar": "سالدانا",
                "label_fr": "Saldana"
            },
            {
                "value": 52230,
                "label": "Sada",
                "label_ar": "صدى",
                "label_fr": "Sada"
            },
            {
                "value": 54156,
                "label": "Castellar de Santiago",
                "label_ar": "كاستيلار دي سانتياغو",
                "label_fr": "Castellar de Santiago"
            },
            {
                "value": 53869,
                "label": "Avinyonet del Penedes",
                "label_ar": "أفينيونيت ديل بينديس",
                "label_fr": "Avinyonet del Penedes"
            },
            {
                "value": 52824,
                "label": "Busturi-Axpe",
                "label_ar": "بوستوري اكسبي",
                "label_fr": "Busturi-Axpe"
            },
            {
                "value": 54110,
                "label": "Pedrosillo de Alba",
                "label_ar": "بدروسيلو دي ألبا",
                "label_fr": "Pedrosillo de Alba"
            },
            {
                "value": 53201,
                "label": "Pedrezuela",
                "label_ar": "بيدريزويلا",
                "label_fr": "Pedrezuela"
            },
            {
                "value": 53513,
                "label": "Plentzia",
                "label_ar": "بلنتزيا",
                "label_fr": "Plentzia"
            },
            {
                "value": 53697,
                "label": "Algezares",
                "label_ar": "الجزائر",
                "label_fr": "Algezares"
            },
            {
                "value": 53404,
                "label": "San Bartolomé de Tirajana",
                "label_ar": "سان بارتولومي دي تيراجانا",
                "label_fr": "San Bartolomé de Tirajana"
            },
            {
                "value": 52596,
                "label": "Curtis",
                "label_ar": "كورتيس",
                "label_fr": "Curtis"
            },
            {
                "value": 53530,
                "label": "Vilafant",
                "label_ar": "فيلافانت",
                "label_fr": "Vilafant"
            },
            {
                "value": 52228,
                "label": "Ferrol",
                "label_ar": "فيرول",
                "label_fr": "Ferrol"
            },
            {
                "value": 52324,
                "label": "Urda",
                "label_ar": "أوردا",
                "label_fr": "Urda"
            },
            {
                "value": 53332,
                "label": "Humanes",
                "label_ar": "البشر",
                "label_fr": "Humanes"
            },
            {
                "value": 52463,
                "label": "Bescano",
                "label_ar": "بيسكانو",
                "label_fr": "Bescano"
            },
            {
                "value": 52418,
                "label": "Palafrugell",
                "label_ar": "بالافروجيل",
                "label_fr": "Palafrugell"
            },
            {
                "value": 52348,
                "label": "San Juan de Moro",
                "label_ar": "سان خوان دي مورو",
                "label_fr": "San Juan de Moro"
            },
            {
                "value": 54243,
                "label": "Valmojado",
                "label_ar": "فالموجادو",
                "label_fr": "Valmojado"
            },
            {
                "value": 52155,
                "label": "Granada",
                "label_ar": "غرناطة",
                "label_fr": "Grenade"
            },
            {
                "value": 52525,
                "label": "Puig",
                "label_ar": "بويج",
                "label_fr": "Puig"
            },
            {
                "value": 53602,
                "label": "Juneda",
                "label_ar": "جونيدا",
                "label_fr": "Juneda"
            },
            {
                "value": 53557,
                "label": "Massamagrell",
                "label_ar": "ماساماجريل",
                "label_fr": "Massamagrell"
            },
            {
                "value": 54240,
                "label": "Los Llanos de Aridane",
                "label_ar": "لوس يانوس دي اريدان",
                "label_fr": "Los Llanos de Aridane"
            },
            {
                "value": 53043,
                "label": "Arama",
                "label_ar": "اراما",
                "label_fr": "Arama"
            },
            {
                "value": 52105,
                "label": "San Isidro",
                "label_ar": "سان ايسيدرو",
                "label_fr": "San Isidro"
            },
            {
                "value": 52925,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 51949,
                "label": "Pinto",
                "label_ar": "بينتو",
                "label_fr": "Pinto"
            },
            {
                "value": 52159,
                "label": "Vélez-Málaga",
                "label_ar": "فيليز مالقة",
                "label_fr": "Vélez-Málaga"
            },
            {
                "value": 52319,
                "label": "La Galera",
                "label_ar": "لا جاليرا",
                "label_fr": "La Galera"
            },
            {
                "value": 54327,
                "label": "Adrall",
                "label_ar": "أدرال",
                "label_fr": "Adrall"
            },
            {
                "value": 52904,
                "label": "Reocin",
                "label_ar": "ريوسين",
                "label_fr": "Reocin"
            },
            {
                "value": 51932,
                "label": "Arroyomolinos",
                "label_ar": "أرويومولينوس",
                "label_fr": "Arroyomolinos"
            },
            {
                "value": 54129,
                "label": "El Sotillo",
                "label_ar": "إل سوتيلو",
                "label_fr": "El Sotillo"
            },
            {
                "value": 53025,
                "label": "Beas",
                "label_ar": "البازلاء",
                "label_fr": "Beas"
            },
            {
                "value": 52736,
                "label": "Huarte-Araquil",
                "label_ar": "هوارتي أراكويل",
                "label_fr": "Huarte-Araquil"
            },
            {
                "value": 54171,
                "label": "La Calzada de Calatrava",
                "label_ar": "لا كالزادا دي كالاترافا",
                "label_fr": "La Calzada de Calatrava"
            },
            {
                "value": 52992,
                "label": "Valdepenas",
                "label_ar": "فالديبيناس",
                "label_fr": "Valdepenas"
            },
            {
                "value": 52811,
                "label": "Benicarló",
                "label_ar": "بينيكارلو",
                "label_fr": "Benicarló"
            },
            {
                "value": 53037,
                "label": "Sorbas",
                "label_ar": "سورباس",
                "label_fr": "Sorbas"
            },
            {
                "value": 53158,
                "label": "Traslavina",
                "label_ar": "تراسلافينا",
                "label_fr": "Traslavina"
            },
            {
                "value": 53499,
                "label": "Baina",
                "label_ar": "بينا",
                "label_fr": "Baina"
            },
            {
                "value": 53896,
                "label": "Periedo",
                "label_ar": "بيرييدو",
                "label_fr": "Periedo"
            },
            {
                "value": 52631,
                "label": "Idiazabal",
                "label_ar": "إديازابال",
                "label_fr": "Idiazabal"
            },
            {
                "value": 53936,
                "label": "La Arena",
                "label_ar": "لا ارينا",
                "label_fr": "La Arena"
            },
            {
                "value": 53188,
                "label": "Villafranca de los Barros",
                "label_ar": "فيلافرانكا دي لوس باروس",
                "label_fr": "Villafranca de los Barros"
            },
            {
                "value": 52003,
                "label": "Las Ventas de Retamosa",
                "label_ar": "لاس فينتاس دي ريتاموسا",
                "label_fr": "Las Ventas de Retamosa"
            },
            {
                "value": 52675,
                "label": "Casares",
                "label_ar": "كاساريس",
                "label_fr": "Casares"
            },
            {
                "value": 54061,
                "label": "Tamaraceite",
                "label_ar": "تاماراسيت",
                "label_fr": "Tamaraceite"
            },
            {
                "value": 52852,
                "label": "Els Monjos",
                "label_ar": "إلس مونجوس",
                "label_fr": "Els Monjos"
            },
            {
                "value": 52895,
                "label": "El Saler",
                "label_ar": "السالر",
                "label_fr": "El Saler"
            },
            {
                "value": 52672,
                "label": "Palamós",
                "label_ar": "بالاموس",
                "label_fr": "Palamós"
            },
            {
                "value": 53364,
                "label": "Castillo del Romeral",
                "label_ar": "كاستيلو ديل روميرال",
                "label_fr": "Castillo del Romeral"
            },
            {
                "value": 54278,
                "label": "Pasai San Pedro",
                "label_ar": "باساي سان بيدرو",
                "label_fr": "Pasai San Pedro"
            },
            {
                "value": 52019,
                "label": "Olula del Rio",
                "label_ar": "أولولا ديل ريو",
                "label_fr": "Olula del Rio"
            },
            {
                "value": 52501,
                "label": "Ibi",
                "label_ar": "إيبي",
                "label_fr": "Ibi"
            },
            {
                "value": 52080,
                "label": "La Orotava",
                "label_ar": "لا أوروتافا",
                "label_fr": "La Orotava"
            },
            {
                "value": 52451,
                "label": "Ribeira",
                "label_ar": "ريبيرا",
                "label_fr": "Ribeira"
            },
            {
                "value": 52471,
                "label": "Ribarroja",
                "label_ar": "ريباروجا",
                "label_fr": "Ribarroja"
            },
            {
                "value": 52104,
                "label": "La Victoria de Acentejo",
                "label_ar": "لا فيكتوريا دي اسينتيجو",
                "label_fr": "La Victoria de Acentejo"
            },
            {
                "value": 54330,
                "label": "Belvis de la Jara",
                "label_ar": "بيلفيس دي لا جارا",
                "label_fr": "Belvis de la Jara"
            },
            {
                "value": 52429,
                "label": "el Morche",
                "label_ar": "المورتش",
                "label_fr": "el Morche"
            },
            {
                "value": 52368,
                "label": "Torres de Berrellen",
                "label_ar": "توريس دي بيريلين",
                "label_fr": "Torres de Berrellen"
            },
            {
                "value": 51978,
                "label": "Villanueva de la Canada",
                "label_ar": "فيلانويفا دي لا كندا",
                "label_fr": "Villanueva de la Canada"
            },
            {
                "value": 54194,
                "label": "Campanario",
                "label_ar": "كامباناريو",
                "label_fr": "Campanario"
            },
            {
                "value": 52980,
                "label": "Tarancon",
                "label_ar": "تارانكون",
                "label_fr": "Tarancon"
            },
            {
                "value": 52806,
                "label": "Artajo",
                "label_ar": "ارتاجو",
                "label_fr": "Artajo"
            },
            {
                "value": 52816,
                "label": "Vilabertran",
                "label_ar": "فيلابيرتران",
                "label_fr": "Vilabertran"
            },
            {
                "value": 53580,
                "label": "El Raal",
                "label_ar": "الرال",
                "label_fr": "El Raal"
            },
            {
                "value": 53387,
                "label": "Urnieta",
                "label_ar": "أورنيتا",
                "label_fr": "Urnieta"
            },
            {
                "value": 52157,
                "label": "Amorebieta",
                "label_ar": "Amorebieta",
                "label_fr": "Amorebieta"
            },
            {
                "value": 53411,
                "label": "Hinojosa del Valle",
                "label_ar": "هينوخوسا ديل فالي",
                "label_fr": "Hinojosa del Valle"
            },
            {
                "value": 53489,
                "label": "Membrilla",
                "label_ar": "Membrilla",
                "label_fr": "Membrilla"
            },
            {
                "value": 53326,
                "label": "Castellbisbal",
                "label_ar": "كاستيلبيسبال",
                "label_fr": "Castellbisbal"
            },
            {
                "value": 53007,
                "label": "Jimena de la Frontera",
                "label_ar": "خيمينا دي لا فرونتيرا",
                "label_fr": "Jimena de la Frontera"
            },
            {
                "value": 53583,
                "label": "Santo Domingo de la Calzada",
                "label_ar": "سانتو دومينغو دي لا كالزادا",
                "label_fr": "Saint-Domingue de la Calzada"
            },
            {
                "value": 52706,
                "label": "Requena",
                "label_ar": "ريكينا",
                "label_fr": "Requena"
            },
            {
                "value": 54452,
                "label": "Miajadas",
                "label_ar": "مياجاداس",
                "label_fr": "Miajadas"
            },
            {
                "value": 54125,
                "label": "Las Hortichuelas",
                "label_ar": "لاس هورتيكويلاس",
                "label_fr": "Las Hortichuelas"
            },
            {
                "value": 52263,
                "label": "Plegamans",
                "label_ar": "بليغمانس",
                "label_fr": "Plegamans"
            },
            {
                "value": 53197,
                "label": "Torremenga",
                "label_ar": "توريمينجا",
                "label_fr": "Torremenga"
            },
            {
                "value": 52140,
                "label": "Cuevas de Vinroma",
                "label_ar": "كويفاس دي فينروما",
                "label_fr": "Cuevas de Vinroma"
            },
            {
                "value": 54207,
                "label": "Campuzano",
                "label_ar": "كامبوزانو",
                "label_fr": "Campuzano"
            },
            {
                "value": 54421,
                "label": "Hurchillo",
                "label_ar": "هورشيلو",
                "label_fr": "Hurchillo"
            },
            {
                "value": 52735,
                "label": "Mutilva Baja",
                "label_ar": "موتيلفا باجا",
                "label_fr": "Mutilva Baja"
            },
            {
                "value": 53965,
                "label": "Belvis de Jarama",
                "label_ar": "بيلفيس دي جاراما",
                "label_fr": "Belvis de Jarama"
            },
            {
                "value": 52234,
                "label": "Palencia",
                "label_ar": "بلنسية",
                "label_fr": "Palencia"
            },
            {
                "value": 52958,
                "label": "La Batlloria",
                "label_ar": "لا باتلوريا",
                "label_fr": "La Batlloria"
            },
            {
                "value": 52266,
                "label": "Sagunto",
                "label_ar": "ساجونتو",
                "label_fr": "Sagonte"
            },
            {
                "value": 53555,
                "label": "Albuixech",
                "label_ar": "البويكسيك",
                "label_fr": "Albuixech"
            },
            {
                "value": 53567,
                "label": "Zuazo de Vitoria",
                "label_ar": "زوازو دي فيتوريا",
                "label_fr": "Zuazo de Vitoria"
            },
            {
                "value": 51909,
                "label": "Begues",
                "label_ar": "يستجدي",
                "label_fr": "Begues"
            },
            {
                "value": 53875,
                "label": "Calamonte",
                "label_ar": "كالامونتي",
                "label_fr": "Calamonte"
            },
            {
                "value": 53790,
                "label": "Torres",
                "label_ar": "توريس",
                "label_fr": "Torres"
            },
            {
                "value": 54001,
                "label": "Villacuende",
                "label_ar": "فيلاكويندي",
                "label_fr": "Villacuende"
            },
            {
                "value": 53640,
                "label": "Llanes",
                "label_ar": "يانيس",
                "label_fr": "Llanes"
            },
            {
                "value": 53214,
                "label": "Ajalvir",
                "label_ar": "أجالفير",
                "label_fr": "Ajalvir"
            },
            {
                "value": 52936,
                "label": "Manises",
                "label_ar": "مانيسيس",
                "label_fr": "Manises"
            },
            {
                "value": 54436,
                "label": "San Vicente de Leon",
                "label_ar": "سان فيسنتي دي ليون",
                "label_fr": "San Vicente de Leon"
            },
            {
                "value": 52474,
                "label": "Santiago de Compostela",
                "label_ar": "سانتياغو دي كومبوستيلا",
                "label_fr": "Saint Jacques de Compostelle"
            },
            {
                "value": 54239,
                "label": "La Puebla de los Infantes",
                "label_ar": "لا بويبلا دي لوس إنفانتيس",
                "label_fr": "La Puebla de los Infantes"
            },
            {
                "value": 52291,
                "label": "Montmeló",
                "label_ar": "مونتميلو",
                "label_fr": "Montmeló"
            },
            {
                "value": 54179,
                "label": "La Torre",
                "label_ar": "لا توري",
                "label_fr": "La Torre"
            },
            {
                "value": 53926,
                "label": "Cornellana",
                "label_ar": "كورنيلانا",
                "label_fr": "Cornellana"
            },
            {
                "value": 53442,
                "label": "Pereiro de Aguiar",
                "label_ar": "بيريرو دي أغيار",
                "label_fr": "Pereiro de Aguiar"
            },
            {
                "value": 52982,
                "label": "Órgiva",
                "label_ar": "أرجيفا",
                "label_fr": "Órgiva"
            },
            {
                "value": 54107,
                "label": "Trevino",
                "label_ar": "تريفينو",
                "label_fr": "Trevino"
            },
            {
                "value": 54396,
                "label": "Bitem",
                "label_ar": "بيتيم",
                "label_fr": "Bitem"
            },
            {
                "value": 52217,
                "label": "Segovia",
                "label_ar": "سيغوفيا",
                "label_fr": "Ségovie"
            },
            {
                "value": 51965,
                "label": "Jesus",
                "label_ar": "يسوع",
                "label_fr": "Jésus"
            },
            {
                "value": 53238,
                "label": "Riudoms",
                "label_ar": "Riudoms",
                "label_fr": "Riudoms"
            },
            {
                "value": 53575,
                "label": "Moraleja de Enmedio",
                "label_ar": "موراليا دي إنميديو",
                "label_fr": "Moraleja de Enmedio"
            },
            {
                "value": 54420,
                "label": "Sobradiel",
                "label_ar": "سوبرادييل",
                "label_fr": "Sobradiel"
            },
            {
                "value": 54270,
                "label": "Villarluengo",
                "label_ar": "فيلارلوينجو",
                "label_fr": "Villarluengo"
            },
            {
                "value": 52459,
                "label": "Utrera",
                "label_ar": "أوتريرا",
                "label_fr": "Utrera"
            },
            {
                "value": 52717,
                "label": "Guardo",
                "label_ar": "جواردو",
                "label_fr": "Guardo"
            },
            {
                "value": 53806,
                "label": "Puentelarra",
                "label_ar": "Puentelarra",
                "label_fr": "Puentelarra"
            },
            {
                "value": 53101,
                "label": "La Caridad",
                "label_ar": "لا كاريداد",
                "label_fr": "La Caridad"
            },
            {
                "value": 52027,
                "label": "Los Gallardos",
                "label_ar": "لوس غالاردوس",
                "label_fr": "Los Gallardos"
            },
            {
                "value": 54336,
                "label": "Paredes de Nava",
                "label_ar": "باريديس دي نافا",
                "label_fr": "Paredes de Nava"
            },
            {
                "value": 53062,
                "label": "Morata de Jalon",
                "label_ar": "موراتا دي جالون",
                "label_fr": "Morata de Jalon"
            },
            {
                "value": 54043,
                "label": "Mequinensa / Mequinenza",
                "label_ar": "ميكينينسا / ميكينينزا",
                "label_fr": "Mequinensa / Mequinenza"
            },
            {
                "value": 54402,
                "label": "Noves",
                "label_ar": "مستجدات",
                "label_fr": "Noves"
            },
            {
                "value": 52240,
                "label": "la Garriga",
                "label_ar": "لا جاريجا",
                "label_fr": "la Garriga"
            },
            {
                "value": 52667,
                "label": "Pola de Siero",
                "label_ar": "بولا دي سيرو",
                "label_fr": "Pola de Siero"
            },
            {
                "value": 54448,
                "label": "Meira",
                "label_ar": "ميرا",
                "label_fr": "Meira"
            },
            {
                "value": 54262,
                "label": "Peralejo",
                "label_ar": "بيراليجو",
                "label_fr": "Peralejo"
            },
            {
                "value": 52573,
                "label": "Ledana",
                "label_ar": "ليدانا",
                "label_fr": "Ledana"
            },
            {
                "value": 53418,
                "label": "S'Arenal",
                "label_ar": "S'Arenal",
                "label_fr": "S'Arenal"
            },
            {
                "value": 52878,
                "label": "Villablino",
                "label_ar": "فيلابلينو",
                "label_fr": "Villablino"
            },
            {
                "value": 53727,
                "label": "Canillas de Albaida",
                "label_ar": "كانيلاس دي البيدا",
                "label_fr": "Canillas de Albaida"
            },
            {
                "value": 53236,
                "label": "Val de Santo Domingo",
                "label_ar": "فال دي سانتو دومينغو",
                "label_fr": "Val de Saint-Domingue"
            },
            {
                "value": 52211,
                "label": "Pontevedra",
                "label_ar": "بونتيفيدرا",
                "label_fr": "Pontevedra"
            },
            {
                "value": 53348,
                "label": "La Selva",
                "label_ar": "لا سيلفا",
                "label_fr": "La Selva"
            },
            {
                "value": 52326,
                "label": "Villar del Arzobispo",
                "label_ar": "فيلار ديل أرزوبيسبو",
                "label_fr": "Villar del Arzobispo"
            },
            {
                "value": 54473,
                "label": "Polan",
                "label_ar": "بولان",
                "label_fr": "Polan"
            },
            {
                "value": 52050,
                "label": "Igualada",
                "label_ar": "إغوالادا",
                "label_fr": "Igualada"
            },
            {
                "value": 51979,
                "label": "Grinon",
                "label_ar": "Grinon",
                "label_fr": "Grinon"
            },
            {
                "value": 53964,
                "label": "Malagon",
                "label_ar": "ملاغون",
                "label_fr": "Malagon"
            },
            {
                "value": 54303,
                "label": "Golmes",
                "label_ar": "جولمز",
                "label_fr": "Golmes"
            },
            {
                "value": 53493,
                "label": "Asteasu",
                "label_ar": "أستياسو",
                "label_fr": "Asteasu"
            },
            {
                "value": 53193,
                "label": "Santa Coloma de Farners",
                "label_ar": "سانتا كولوما دي فارينرز",
                "label_fr": "Santa Coloma de Farners"
            },
            {
                "value": 52095,
                "label": "Rupit",
                "label_ar": "روبيت",
                "label_fr": "Rupit"
            },
            {
                "value": 52480,
                "label": "Ampuero",
                "label_ar": "امبيرو",
                "label_fr": "Ampuero"
            },
            {
                "value": 53917,
                "label": "Esplus",
                "label_ar": "اسبلس",
                "label_fr": "Esplus"
            },
            {
                "value": 52261,
                "label": "Moia",
                "label_ar": "مويا",
                "label_fr": "Moia"
            },
            {
                "value": 53543,
                "label": "Villarrubia de los Ojos",
                "label_ar": "Villarrubia de los Ojos",
                "label_fr": "Villarrubia de los Ojos"
            },
            {
                "value": 52382,
                "label": "Rebordanes",
                "label_ar": "ريبوردانس",
                "label_fr": "Rebordanes"
            },
            {
                "value": 52168,
                "label": "Alsasua – Altsasu",
                "label_ar": "الساسوع - التساسو",
                "label_fr": "Alsasua - Altsasu"
            },
            {
                "value": 54431,
                "label": "Canar",
                "label_ar": "كنار",
                "label_fr": "Canar"
            },
            {
                "value": 52971,
                "label": "Gandesa",
                "label_ar": "غانديسا",
                "label_fr": "Gandesa"
            },
            {
                "value": 52564,
                "label": "Peñíscola",
                "label_ar": "بينيسكولا",
                "label_fr": "Peñíscola"
            },
            {
                "value": 52143,
                "label": "Segura",
                "label_ar": "سيجورا",
                "label_fr": "Segura"
            },
            {
                "value": 54390,
                "label": "La Torre",
                "label_ar": "لا توري",
                "label_fr": "La Torre"
            },
            {
                "value": 52571,
                "label": "Don Benito",
                "label_ar": "دون بينيتو",
                "label_fr": "Don Benito"
            },
            {
                "value": 52809,
                "label": "Vinaros",
                "label_ar": "فيناروس",
                "label_fr": "Vinaros"
            },
            {
                "value": 54113,
                "label": "la Pobla de Montornes",
                "label_ar": "لا بوبلا دي مونتورن",
                "label_fr": "la Pobla de Montornes"
            },
            {
                "value": 53287,
                "label": "Navàs",
                "label_ar": "نافاس",
                "label_fr": "Navàs"
            },
            {
                "value": 52890,
                "label": "Hernansancho",
                "label_ar": "هرنانسانشو",
                "label_fr": "Hernansancho"
            },
            {
                "value": 54116,
                "label": "Bajamar",
                "label_ar": "باجامار",
                "label_fr": "Bajamar"
            },
            {
                "value": 51940,
                "label": "Collado Villalba",
                "label_ar": "كولادو فيلالبا",
                "label_fr": "Collado Villalba"
            },
            {
                "value": 52592,
                "label": "O Barco de Valdeorras",
                "label_ar": "يا باركو دي فالديوراس",
                "label_fr": "O Barco de Valdeorras"
            },
            {
                "value": 53423,
                "label": "Talamanca de Jarama",
                "label_ar": "تالامانكا دي جاراما",
                "label_fr": "Talamanca de Jarama"
            },
            {
                "value": 52712,
                "label": "San Vicent del Raspeig",
                "label_ar": "سان فيسينت ديل راسبج",
                "label_fr": "San Vicent del Raspeig"
            },
            {
                "value": 53230,
                "label": "Illas",
                "label_ar": "إيلاس",
                "label_fr": "Illas"
            },
            {
                "value": 54162,
                "label": "Sancibrian",
                "label_ar": "سانسيبريان",
                "label_fr": "Sancibrian"
            },
            {
                "value": 54144,
                "label": "Guejar-Sierra",
                "label_ar": "جويخار سييرا",
                "label_fr": "Guejar-Sierra"
            },
            {
                "value": 51955,
                "label": "Toledo",
                "label_ar": "توليدو",
                "label_fr": "Toledo"
            },
            {
                "value": 51977,
                "label": "Portillo de Toledo",
                "label_ar": "بورتيلو دي توليدو",
                "label_fr": "Portillo de Toledo"
            },
            {
                "value": 53155,
                "label": "Coristanco",
                "label_ar": "كوريستانكو",
                "label_fr": "Coristanco"
            },
            {
                "value": 53626,
                "label": "Yebes",
                "label_ar": "يبس",
                "label_fr": "Yebes"
            },
            {
                "value": 52475,
                "label": "Vilanova d'Escornalbou",
                "label_ar": "فيلانوفا ديسكورنالبو",
                "label_fr": "Vilanova d'Escornalbou"
            },
            {
                "value": 52252,
                "label": "Las Torres de Cotillas",
                "label_ar": "لاس توريس دي كوتياس",
                "label_fr": "Las Torres de Cotillas"
            },
            {
                "value": 51953,
                "label": "Galapagar",
                "label_ar": "جالاباجار",
                "label_fr": "Galapagar"
            },
            {
                "value": 52513,
                "label": "Arrecife",
                "label_ar": "Arrecife",
                "label_fr": "Arrecife"
            },
            {
                "value": 52522,
                "label": "Puerto del Carmen",
                "label_ar": "بويرتو ديل كارمن",
                "label_fr": "Puerto del Carmen"
            },
            {
                "value": 53283,
                "label": "Sestao",
                "label_ar": "سيستاو",
                "label_fr": "Sestao"
            },
            {
                "value": 53122,
                "label": "Espejo",
                "label_ar": "إسبيجو",
                "label_fr": "Espejo"
            },
            {
                "value": 51954,
                "label": "Parla",
                "label_ar": "بارلا",
                "label_fr": "Parla"
            },
            {
                "value": 52338,
                "label": "Pucol",
                "label_ar": "بوكول",
                "label_fr": "Pucol"
            },
            {
                "value": 52773,
                "label": "Ventallo",
                "label_ar": "فينتالو",
                "label_fr": "Ventallo"
            },
            {
                "value": 53526,
                "label": "Cantimpalos",
                "label_ar": "كانتيمبالوس",
                "label_fr": "Cantimpalos"
            },
            {
                "value": 53070,
                "label": "Hervás",
                "label_ar": "هيرفاس",
                "label_fr": "Hervás"
            },
            {
                "value": 53232,
                "label": "els Pallaresos",
                "label_ar": "إلس بالاريسوس",
                "label_fr": "els Pallaresos"
            },
            {
                "value": 53256,
                "label": "Langa de Duero",
                "label_ar": "لانجا دي دويرو",
                "label_fr": "Langa de Duero"
            },
            {
                "value": 53244,
                "label": "La Pueblanueva",
                "label_ar": "لا بويبلانويفا",
                "label_fr": "La Pueblanueva"
            },
            {
                "value": 53722,
                "label": "Madronera",
                "label_ar": "مادرونيرا",
                "label_fr": "Madronera"
            },
            {
                "value": 54157,
                "label": "Almassera",
                "label_ar": "المسرة",
                "label_fr": "Almassera"
            },
            {
                "value": 53554,
                "label": "La Granada",
                "label_ar": "لا غرناطة",
                "label_fr": "La Grenade"
            },
            {
                "value": 54267,
                "label": "Lanjaron",
                "label_ar": "لانجارون",
                "label_fr": "Lanjaron"
            },
            {
                "value": 53935,
                "label": "La Canada de San Urbano",
                "label_ar": "لا كندا دي سان أوربانو",
                "label_fr": "La Canada de San Urbano"
            },
            {
                "value": 52430,
                "label": "Serracines",
                "label_ar": "سيراسين",
                "label_fr": "Serracines"
            },
            {
                "value": 52394,
                "label": "Llafranc",
                "label_ar": "للافرانك",
                "label_fr": "Llafranc"
            },
            {
                "value": 53581,
                "label": "Cenicero",
                "label_ar": "سينيسيرو",
                "label_fr": "Cenicero"
            },
            {
                "value": 52408,
                "label": "Lleida",
                "label_ar": "ليدا",
                "label_fr": "Lleida"
            },
            {
                "value": 52685,
                "label": "Medina del Campo",
                "label_ar": "مدينة ديل كامبو",
                "label_fr": "Médina del Campo"
            },
            {
                "value": 52393,
                "label": "Cabrera de Almanza",
                "label_ar": "كابريرا دي المنزا",
                "label_fr": "Cabrera de Almanza"
            },
            {
                "value": 52510,
                "label": "Paterna del Campo",
                "label_ar": "باتيرنا ديل كامبو",
                "label_fr": "Paterna del Campo"
            },
            {
                "value": 54197,
                "label": "Berrobi",
                "label_ar": "بيروبي",
                "label_fr": "Berrobi"
            },
            {
                "value": 53900,
                "label": "Cudillero",
                "label_ar": "كوديليرو",
                "label_fr": "Cudillero"
            },
            {
                "value": 53743,
                "label": "Caldes de Malavella",
                "label_ar": "كالديس دي مالافيلا",
                "label_fr": "Caldes de Malavella"
            },
            {
                "value": 52233,
                "label": "Verin",
                "label_ar": "فيرين",
                "label_fr": "Verin"
            },
            {
                "value": 52267,
                "label": "Paterna",
                "label_ar": "باتيرنا",
                "label_fr": "Paterna"
            },
            {
                "value": 52607,
                "label": "Campillos",
                "label_ar": "كامبيلوس",
                "label_fr": "Campillos"
            },
            {
                "value": 52178,
                "label": "Errenteria",
                "label_ar": "إرنتيريا",
                "label_fr": "Errenteria"
            },
            {
                "value": 52746,
                "label": "Saron",
                "label_ar": "سارون",
                "label_fr": "Saron"
            },
            {
                "value": 52929,
                "label": "Baeza",
                "label_ar": "بايزا",
                "label_fr": "Baeza"
            },
            {
                "value": 52576,
                "label": "Foz",
                "label_ar": "فوز",
                "label_fr": "Foz"
            },
            {
                "value": 53943,
                "label": "Matamorosa",
                "label_ar": "ماتاموروسا",
                "label_fr": "Matamorosa"
            },
            {
                "value": 53928,
                "label": "Quintanar de la Orden",
                "label_ar": "كوينتانار دي لا أوردين",
                "label_fr": "Quintanar de la Orden"
            },
            {
                "value": 53257,
                "label": "Ares",
                "label_ar": "آريس",
                "label_fr": "Ares"
            },
            {
                "value": 52248,
                "label": "Ibiza",
                "label_ar": "إيبيزا",
                "label_fr": "Ibiza"
            },
            {
                "value": 52940,
                "label": "Valencia",
                "label_ar": "فالنسيا",
                "label_fr": "Valence"
            },
            {
                "value": 52714,
                "label": "Valverde del Camino",
                "label_ar": "فالفيردي ديل كامينو",
                "label_fr": "Valverde del Camino"
            },
            {
                "value": 54428,
                "label": "Nuez de Ebro",
                "label_ar": "نويز دي إيبرو",
                "label_fr": "Nuez de Ebro"
            },
            {
                "value": 54017,
                "label": "La Canada",
                "label_ar": "لا كندا",
                "label_fr": "La Canada"
            },
            {
                "value": 53143,
                "label": "Bernuy",
                "label_ar": "بيرني",
                "label_fr": "Bernuy"
            },
            {
                "value": 53285,
                "label": "Alhama de Granada",
                "label_ar": "Alhama de Granada",
                "label_fr": "Alhama de Grenade"
            },
            {
                "value": 51916,
                "label": "Cornellàde Llobregat",
                "label_ar": "كورنيلاد لوبريغات",
                "label_fr": "Cornellàde Llobregat"
            },
            {
                "value": 53975,
                "label": "Tremp",
                "label_ar": "تريمب",
                "label_fr": "Tremp"
            },
            {
                "value": 53318,
                "label": "Luyando",
                "label_ar": "Luyando",
                "label_fr": "Luyando"
            },
            {
                "value": 52943,
                "label": "Agüimes",
                "label_ar": "اجويمس",
                "label_fr": "Agüimes"
            },
            {
                "value": 54195,
                "label": "Beniarbeig",
                "label_ar": "بنياربيج",
                "label_fr": "Beniarbeig"
            },
            {
                "value": 53584,
                "label": "Berriobeiti",
                "label_ar": "بريوبيتي",
                "label_fr": "Berriobeiti"
            },
            {
                "value": 53336,
                "label": "Teruel",
                "label_ar": "تيرويل",
                "label_fr": "Teruel"
            },
            {
                "value": 52883,
                "label": "Alcañiz",
                "label_ar": "الكانيز",
                "label_fr": "Alcañiz"
            },
            {
                "value": 53664,
                "label": "Santa Uxia de Ribeira",
                "label_ar": "سانتا يوكسيا دي ريبيرا",
                "label_fr": "Santa Uxia de Ribeira"
            },
            {
                "value": 53013,
                "label": "La Puebla de Hijar",
                "label_ar": "لا بويبلا دي حجار",
                "label_fr": "La Puebla de Hijar"
            },
            {
                "value": 52666,
                "label": "Altea",
                "label_ar": "ألتيا",
                "label_fr": "Altea"
            },
            {
                "value": 52803,
                "label": "Labajos",
                "label_ar": "لاباجوس",
                "label_fr": "Labajos"
            },
            {
                "value": 53139,
                "label": "Carrion de los Condes",
                "label_ar": "كاريون دي لوس كونديس",
                "label_fr": "Carrion de los Condes"
            },
            {
                "value": 54381,
                "label": "Campina",
                "label_ar": "كامبينا",
                "label_fr": "Campina"
            },
            {
                "value": 53321,
                "label": "Soto",
                "label_ar": "سوتو",
                "label_fr": "De manière à"
            },
            {
                "value": 53301,
                "label": "San Martin",
                "label_ar": "سان مارتن",
                "label_fr": "San Martin"
            },
            {
                "value": 52208,
                "label": "Real",
                "label_ar": "حقيقة",
                "label_fr": "Réel"
            },
            {
                "value": 54095,
                "label": "Mollo",
                "label_ar": "مولو",
                "label_fr": "Mollo"
            },
            {
                "value": 52855,
                "label": "Albaida",
                "label_ar": "البيضاء",
                "label_fr": "Albaida"
            },
            {
                "value": 53291,
                "label": "Luzaide",
                "label_ar": "لوزايد",
                "label_fr": "Luzaide"
            },
            {
                "value": 54150,
                "label": "Almazan",
                "label_ar": "المازان",
                "label_fr": "Almazan"
            },
            {
                "value": 54463,
                "label": "El Coronil",
                "label_ar": "إل كورونيل",
                "label_fr": "El Coronil"
            },
            {
                "value": 52369,
                "label": "Casar de Caceres",
                "label_ar": "كاسار دي كاسيريس",
                "label_fr": "Casar de Caceres"
            },
            {
                "value": 54132,
                "label": "Villanueva de Perales",
                "label_ar": "فيلانويفا دي بيراليس",
                "label_fr": "Villanueva de Perales"
            },
            {
                "value": 54099,
                "label": "San Martin de Unx",
                "label_ar": "سان مارتن دي اونكس",
                "label_fr": "San Martin de Unx"
            },
            {
                "value": 54417,
                "label": "Macael",
                "label_ar": "ماكيل",
                "label_fr": "Macael"
            },
            {
                "value": 53204,
                "label": "Sarria de Ter",
                "label_ar": "ساريا دي تير",
                "label_fr": "Sarria de Ter"
            },
            {
                "value": 53115,
                "label": "Dumbria",
                "label_ar": "دمبريا",
                "label_fr": "Dumbria"
            },
            {
                "value": 53057,
                "label": "Pescueza",
                "label_ar": "بيسكيوزا",
                "label_fr": "Pescueza"
            },
            {
                "value": 53878,
                "label": "Lajares",
                "label_ar": "لاجاريس",
                "label_fr": "Lajares"
            },
            {
                "value": 53337,
                "label": "Ezcaray",
                "label_ar": "إزكاراي",
                "label_fr": "Ezcaray"
            },
            {
                "value": 53884,
                "label": "Somahoz",
                "label_ar": "سوماحوز",
                "label_fr": "Somahoz"
            },
            {
                "value": 52748,
                "label": "Garriguella",
                "label_ar": "غاريغيلا",
                "label_fr": "Garriguella"
            },
            {
                "value": 52794,
                "label": "Olias del Rey",
                "label_ar": "أولياس ديل ري",
                "label_fr": "Olias del Rey"
            },
            {
                "value": 54076,
                "label": "Huetor Santillan",
                "label_ar": "هويتور سانتيلان",
                "label_fr": "Huetor Santillan"
            },
            {
                "value": 52244,
                "label": "Pineda de Mar",
                "label_ar": "بينيدا دي مار",
                "label_fr": "Pineda de Mar"
            },
            {
                "value": 52820,
                "label": "Arges",
                "label_ar": "أرجيس",
                "label_fr": "Arges"
            },
            {
                "value": 54163,
                "label": "Elechas",
                "label_ar": "اليكاس",
                "label_fr": "Elechas"
            },
            {
                "value": 54272,
                "label": "Iniesta",
                "label_ar": "انيستا",
                "label_fr": "Iniesta"
            },
            {
                "value": 54432,
                "label": "Neda",
                "label_ar": "ندى",
                "label_fr": "Neda"
            },
            {
                "value": 52196,
                "label": "Cartama",
                "label_ar": "كارتاما",
                "label_fr": "Cartama"
            },
            {
                "value": 52609,
                "label": "Rota",
                "label_ar": "محكمة إستئناف اكليركية",
                "label_fr": "Rota"
            },
            {
                "value": 53948,
                "label": "Bedmar",
                "label_ar": "بيدمار",
                "label_fr": "Bedmar"
            },
            {
                "value": 52009,
                "label": "Olivenza",
                "label_ar": "أوليفينزا",
                "label_fr": "Olivenza"
            },
            {
                "value": 53728,
                "label": "Son Servera",
                "label_ar": "ابن سيرفيرا",
                "label_fr": "Son Servera"
            },
            {
                "value": 52243,
                "label": "Palafolls",
                "label_ar": "بالافولس",
                "label_fr": "Palafolls"
            },
            {
                "value": 53127,
                "label": "Hontanares de Eresma",
                "label_ar": "هونتاناريس دي إريسما",
                "label_fr": "Hontanares de Eresma"
            },
            {
                "value": 53837,
                "label": "Meano",
                "label_ar": "مينو",
                "label_fr": "Meano"
            },
            {
                "value": 53880,
                "label": "Monachil",
                "label_ar": "موناتشيل",
                "label_fr": "Monachil"
            },
            {
                "value": 54233,
                "label": "Guillena",
                "label_ar": "غيلينا",
                "label_fr": "Guillena"
            },
            {
                "value": 53579,
                "label": "San Martino",
                "label_ar": "سان مارتينو",
                "label_fr": "San Martino"
            },
            {
                "value": 54173,
                "label": "Astrain",
                "label_ar": "سلالة",
                "label_fr": "Une souche"
            },
            {
                "value": 52846,
                "label": "Ugijar",
                "label_ar": "اوجيجار",
                "label_fr": "Ugijar"
            },
            {
                "value": 52922,
                "label": "Jerte",
                "label_ar": "جيرتي",
                "label_fr": "Jerte"
            },
            {
                "value": 53954,
                "label": "Sigueiro",
                "label_ar": "سيجويرو",
                "label_fr": "Sigueiro"
            },
            {
                "value": 52738,
                "label": "Incio",
                "label_ar": "إنسيو",
                "label_fr": "Incio"
            },
            {
                "value": 53373,
                "label": "Sedavi",
                "label_ar": "سيدافي",
                "label_fr": "Sedavi"
            },
            {
                "value": 53983,
                "label": "Luzmela",
                "label_ar": "لوزميلا",
                "label_fr": "Luzmela"
            },
            {
                "value": 53719,
                "label": "Gamiz",
                "label_ar": "غاميز",
                "label_fr": "Gamiz"
            },
            {
                "value": 52469,
                "label": "Salinas",
                "label_ar": "ساليناس",
                "label_fr": "Salinas"
            },
            {
                "value": 54078,
                "label": "Oleiros",
                "label_ar": "أوليروس",
                "label_fr": "Oleiros"
            },
            {
                "value": 52272,
                "label": "La Seca",
                "label_ar": "لا سيكا",
                "label_fr": "La Seca"
            },
            {
                "value": 52507,
                "label": "Valencina de la Concepcion",
                "label_ar": "فالنسينا دي لا كونسيبسيون",
                "label_fr": "Valencina de la Concepcion"
            },
            {
                "value": 53490,
                "label": "Almodovar del Campo",
                "label_ar": "المودوفار ديل كامبو",
                "label_fr": "Almodovar del Campo"
            },
            {
                "value": 52572,
                "label": "Aranda de Duero",
                "label_ar": "أراندا دي دويرو",
                "label_fr": "Aranda de Duero"
            },
            {
                "value": 54354,
                "label": "Panticosa",
                "label_ar": "بانتيكوسا",
                "label_fr": "Panticosa"
            },
            {
                "value": 52087,
                "label": "Antequera",
                "label_ar": "أنتقيرة",
                "label_fr": "Antequera"
            },
            {
                "value": 52616,
                "label": "Castellbell i el Vilar",
                "label_ar": "Castellbell i el Vilar",
                "label_fr": "Castellbell i el Vilar"
            },
            {
                "value": 52125,
                "label": "Mollet del Vallès",
                "label_ar": "موليت ديل فاليس",
                "label_fr": "Mollet del Vallès"
            },
            {
                "value": 53539,
                "label": "Terradillos",
                "label_ar": "تيراديلوس",
                "label_fr": "Terradillos"
            },
            {
                "value": 53121,
                "label": "Villarquemado",
                "label_ar": "فيلاركيمادو",
                "label_fr": "Villarquemado"
            },
            {
                "value": 52117,
                "label": "Malgrat de Mar",
                "label_ar": "مالجرات دي مار",
                "label_fr": "Malgrat de Mar"
            },
            {
                "value": 53289,
                "label": "Illora",
                "label_ar": "إلورا",
                "label_fr": "Illora"
            },
            {
                "value": 53160,
                "label": "Villamayor",
                "label_ar": "فيلامايور",
                "label_fr": "Villamayor"
            },
            {
                "value": 54414,
                "label": "Alcalá de los Gazules",
                "label_ar": "Alcalá de los Gazules",
                "label_fr": "Alcalá de los Gazules"
            },
            {
                "value": 54375,
                "label": "Montealegre del Castillo",
                "label_ar": "مونتيليغري ديل كاستيلو",
                "label_fr": "Montealegre del Castillo"
            },
            {
                "value": 53918,
                "label": "Arico",
                "label_ar": "أريكو",
                "label_fr": "Arico"
            },
            {
                "value": 53938,
                "label": "l'Escala",
                "label_ar": "اسكالا",
                "label_fr": "l'Escala"
            },
            {
                "value": 52417,
                "label": "Aspe",
                "label_ar": "أسبي",
                "label_fr": "Aspe"
            },
            {
                "value": 52162,
                "label": "Chiva",
                "label_ar": "تشيفا",
                "label_fr": "Chiva"
            },
            {
                "value": 52730,
                "label": "Andorra",
                "label_ar": "أندورا",
                "label_fr": "Andorre"
            },
            {
                "value": 53179,
                "label": "Los Narejos",
                "label_ar": "لوس نارجوس",
                "label_fr": "Los Narejos"
            },
            {
                "value": 52226,
                "label": "Burlata",
                "label_ar": "بورلاتا",
                "label_fr": "Burlata"
            },
            {
                "value": 54045,
                "label": "Solsona",
                "label_ar": "سولسونا",
                "label_fr": "Solsona"
            },
            {
                "value": 53568,
                "label": "Olaberria",
                "label_ar": "أولابريا",
                "label_fr": "Olaberria"
            },
            {
                "value": 53414,
                "label": "Otero",
                "label_ar": "أوتيرو",
                "label_fr": "Otero"
            },
            {
                "value": 53726,
                "label": "Garciaz",
                "label_ar": "جارسياز",
                "label_fr": "Garciaz"
            },
            {
                "value": 53877,
                "label": "Aldeanueva",
                "label_ar": "الدينويفا",
                "label_fr": "Aldeanueva"
            },
            {
                "value": 53822,
                "label": "Talarn",
                "label_ar": "تالارن",
                "label_fr": "Talarn"
            },
            {
                "value": 53475,
                "label": "Quintes",
                "label_ar": "كوينتس",
                "label_fr": "Quintes"
            },
            {
                "value": 52170,
                "label": "Santander",
                "label_ar": "سانتاندير",
                "label_fr": "Santander"
            },
            {
                "value": 52008,
                "label": "Mérida",
                "label_ar": "ميريدا",
                "label_fr": "Mérida"
            },
            {
                "value": 53609,
                "label": "Coles",
                "label_ar": "كولز",
                "label_fr": "Coles"
            },
            {
                "value": 52154,
                "label": "Alicante",
                "label_ar": "اليكانتي",
                "label_fr": "Alicante"
            },
            {
                "value": 52580,
                "label": "Oroso",
                "label_ar": "أوروسو",
                "label_fr": "Oroso"
            },
            {
                "value": 52788,
                "label": "Malpartida de Caceres",
                "label_ar": "مالبارتيدا دي كاسيريس",
                "label_fr": "Malpartida de Caceres"
            },
            {
                "value": 52064,
                "label": "Santiago",
                "label_ar": "سانتياغو",
                "label_fr": "Santiago"
            },
            {
                "value": 52797,
                "label": "Palomares del Rio",
                "label_ar": "بالوماريس ديل ريو",
                "label_fr": "Palomares del Rio"
            },
            {
                "value": 52349,
                "label": "Montellano",
                "label_ar": "مونتيلانو",
                "label_fr": "Montellano"
            },
            {
                "value": 52502,
                "label": "Vila-real",
                "label_ar": "فيلا ريال",
                "label_fr": "Vila-real"
            },
            {
                "value": 53605,
                "label": "Caldelas",
                "label_ar": "كالديلاس",
                "label_fr": "Caldelas"
            },
            {
                "value": 52796,
                "label": "Cistierna",
                "label_ar": "سيستيرنا",
                "label_fr": "Cistierna"
            },
            {
                "value": 52659,
                "label": "Costa",
                "label_ar": "كوستا",
                "label_fr": "Costa"
            },
            {
                "value": 53306,
                "label": "Santa Fe",
                "label_ar": "سانتا في",
                "label_fr": "Santa Fe"
            },
            {
                "value": 52818,
                "label": "Serra",
                "label_ar": "سيرا",
                "label_fr": "Serra"
            },
            {
                "value": 52017,
                "label": "Girona",
                "label_ar": "جيرونا",
                "label_fr": "Girona"
            },
            {
                "value": 54292,
                "label": "Ayegui",
                "label_ar": "Ayegui",
                "label_fr": "Ayegui"
            },
            {
                "value": 52308,
                "label": "Salamanca",
                "label_ar": "سالامانكا",
                "label_fr": "Salamanque"
            },
            {
                "value": 52036,
                "label": "Vicar",
                "label_ar": "النائب",
                "label_fr": "Vicaire"
            },
            {
                "value": 52391,
                "label": "Arbo",
                "label_ar": "اربو",
                "label_fr": "Arbo"
            },
            {
                "value": 54072,
                "label": "Sotillo de la Adrada",
                "label_ar": "سوتيلو دي لا أدرادا",
                "label_fr": "Sotillo de la Adrada"
            },
            {
                "value": 53960,
                "label": "Escurial",
                "label_ar": "إسكوريال",
                "label_fr": "Escurial"
            },
            {
                "value": 54127,
                "label": "Nueva",
                "label_ar": "نويفا",
                "label_fr": "Nueva"
            },
            {
                "value": 53773,
                "label": "Montagut",
                "label_ar": "مونتاجوت",
                "label_fr": "Montagut"
            },
            {
                "value": 53412,
                "label": "Guimar",
                "label_ar": "غيمار",
                "label_fr": "Guimar"
            },
            {
                "value": 53048,
                "label": "Alaró",
                "label_ar": "ألارو",
                "label_fr": "Alaró"
            },
            {
                "value": 54482,
                "label": "Blancos",
                "label_ar": "بلانكوس",
                "label_fr": "Blancos"
            },
            {
                "value": 52608,
                "label": "La Rambla",
                "label_ar": "لا رامبلا",
                "label_fr": "La Rambla"
            },
            {
                "value": 54285,
                "label": "Espinardo",
                "label_ar": "اسبيناردو",
                "label_fr": "Espinardo"
            },
            {
                "value": 54447,
                "label": "Nava de la Asuncion",
                "label_ar": "نافا دي لا أسونسيون",
                "label_fr": "Nava de la Asuncion"
            },
            {
                "value": 54360,
                "label": "Sant Juliàde Vilatorta",
                "label_ar": "سانت جولياد فيلاتورتا",
                "label_fr": "Sant Juliàde Vilatorta"
            },
            {
                "value": 54255,
                "label": "Las Navas de la Concepcion",
                "label_ar": "لاس نافاس دي لا كونسيبسيون",
                "label_fr": "Las Navas de la Concepcion"
            },
            {
                "value": 52884,
                "label": "Lledo",
                "label_ar": "Lledo",
                "label_fr": "Lledo"
            },
            {
                "value": 52742,
                "label": "Elizondo",
                "label_ar": "إليزوندو",
                "label_fr": "Elizondo"
            },
            {
                "value": 52084,
                "label": "Sabadell",
                "label_ar": "ساباديل",
                "label_fr": "Sabadell"
            },
            {
                "value": 53771,
                "label": "Genoves",
                "label_ar": "جينوفيس",
                "label_fr": "Genoves"
            },
            {
                "value": 53911,
                "label": "Andosilla",
                "label_ar": "أندوسيلا",
                "label_fr": "Andosilla"
            },
            {
                "value": 53388,
                "label": "Renedo",
                "label_ar": "رينيدو",
                "label_fr": "Renedo"
            },
            {
                "value": 53106,
                "label": "Pego",
                "label_ar": "بيغو",
                "label_fr": "Pego"
            },
            {
                "value": 54055,
                "label": "Pomar",
                "label_ar": "بومار",
                "label_fr": "Pomar"
            },
            {
                "value": 53107,
                "label": "l'Ametlla de Mar",
                "label_ar": "l'Ametlla de Mar",
                "label_fr": "l'Ametlla de Mar"
            },
            {
                "value": 53119,
                "label": "Sant Josep de sa Talaia",
                "label_ar": "سانت جوسيب دي سا تالايا",
                "label_fr": "Sant Josep de sa Talaia"
            },
            {
                "value": 53017,
                "label": "Axpe",
                "label_ar": "اكسبي",
                "label_fr": "Axpe"
            },
            {
                "value": 53852,
                "label": "Pelayos de la Presa",
                "label_ar": "بيلايوس دي لا بريسا",
                "label_fr": "Pelayos de la Presa"
            },
            {
                "value": 52015,
                "label": "Mondragón",
                "label_ar": "موندراغون",
                "label_fr": "Mondragón"
            },
            {
                "value": 54151,
                "label": "Guadalupe",
                "label_ar": "غوادالوبي",
                "label_fr": "Guadalupe"
            },
            {
                "value": 52489,
                "label": "Santa Cruz de Bezana",
                "label_ar": "سانتا كروز دي بيزانا",
                "label_fr": "Santa Cruz de Bezana"
            },
            {
                "value": 53749,
                "label": "Sagra",
                "label_ar": "ساجرا",
                "label_fr": "Sagra"
            },
            {
                "value": 53036,
                "label": "El Real de la Jara",
                "label_ar": "El Real de la Jara",
                "label_fr": "El Real de la Jara"
            },
            {
                "value": 52238,
                "label": "Toro",
                "label_ar": "تورو",
                "label_fr": "Toro"
            },
            {
                "value": 52118,
                "label": "Villena",
                "label_ar": "فيلينا",
                "label_fr": "Villena"
            },
            {
                "value": 52179,
                "label": "Zaragoza",
                "label_ar": "سرقسطة",
                "label_fr": "Saragosse"
            },
            {
                "value": 52214,
                "label": "Valdemorillo",
                "label_ar": "فالديموريلو",
                "label_fr": "Valdemorillo"
            },
            {
                "value": 52760,
                "label": "Covaleda",
                "label_ar": "كوفاليدا",
                "label_fr": "Covaleda"
            },
            {
                "value": 51936,
                "label": "Fuenlabrada",
                "label_ar": "فوينلابرادا",
                "label_fr": "Fuenlabrada"
            },
            {
                "value": 53063,
                "label": "Quintanar del Rey",
                "label_ar": "كوينتانار ديل ري",
                "label_fr": "Quintanar del Rey"
            },
            {
                "value": 51998,
                "label": "Becerril de la Sierra",
                "label_ar": "بيسيريل دي لا سييرا",
                "label_fr": "Becerril de la Sierra"
            },
            {
                "value": 54413,
                "label": "Rebolledo",
                "label_ar": "Rebolledo",
                "label_fr": "Rebolledo"
            },
            {
                "value": 52951,
                "label": "Villaluenga",
                "label_ar": "فيلالوينجا",
                "label_fr": "Villaluenga"
            },
            {
                "value": 53114,
                "label": "Archidona",
                "label_ar": "أرشيدونا",
                "label_fr": "Archidona"
            },
            {
                "value": 52207,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 52088,
                "label": "El Moral",
                "label_ar": "مورال",
                "label_fr": "El Moral"
            },
            {
                "value": 54487,
                "label": "La Luisiana",
                "label_ar": "لا لويزيانا",
                "label_fr": "La Luisiana"
            },
            {
                "value": 52555,
                "label": "Porrino",
                "label_ar": "بورينو",
                "label_fr": "Porrino"
            },
            {
                "value": 53315,
                "label": "Berango",
                "label_ar": "بيرانجو",
                "label_fr": "Berango"
            },
            {
                "value": 52654,
                "label": "Ciudad Real",
                "label_ar": "سيوداد ريال",
                "label_fr": "Ciudad Real"
            },
            {
                "value": 52727,
                "label": "Mora de Rubielos",
                "label_ar": "مورا دي روبييلوس",
                "label_fr": "Mora de Rubielos"
            },
            {
                "value": 54295,
                "label": "Salvatierra de Mino",
                "label_ar": "سالفاتيرا دي مينو",
                "label_fr": "Salvatierra de Mino"
            },
            {
                "value": 52470,
                "label": "Barakaldo",
                "label_ar": "باراكالدو",
                "label_fr": "Barakaldo"
            },
            {
                "value": 54142,
                "label": "Velilla",
                "label_ar": "فيليلا",
                "label_fr": "Velilla"
            },
            {
                "value": 53144,
                "label": "Falces",
                "label_ar": "الصواعق",
                "label_fr": "Falces"
            },
            {
                "value": 52643,
                "label": "El Vendrell",
                "label_ar": "El Vendrell",
                "label_fr": "El Vendrell"
            },
            {
                "value": 53187,
                "label": "Sotrondio",
                "label_ar": "سوترونديو",
                "label_fr": "Sotrondio"
            },
            {
                "value": 53620,
                "label": "San Feliz de Torio",
                "label_ar": "سان فيليز دي توريو",
                "label_fr": "San Feliz de Torio"
            },
            {
                "value": 53733,
                "label": "Ejea de los Caballeros",
                "label_ar": "إجيا دي لوس كاباليروس",
                "label_fr": "Ejea de los Caballeros"
            },
            {
                "value": 54361,
                "label": "Mota del Marques",
                "label_ar": "موتا ديل ماركيز",
                "label_fr": "Mota del Marques"
            },
            {
                "value": 52711,
                "label": "Cehegín",
                "label_ar": "سيهجين",
                "label_fr": "Cehegín"
            },
            {
                "value": 54228,
                "label": "Ordis",
                "label_ar": "أورديس",
                "label_fr": "Ordis"
            },
            {
                "value": 54030,
                "label": "Quart de Poblet",
                "label_ar": "كوارت دي بوبليت",
                "label_fr": "Quart de Poblet"
            },
            {
                "value": 53261,
                "label": "Sant Carles de la Rapita",
                "label_ar": "سانت كارليس دي لا رابيتا",
                "label_fr": "Sant Carles de la Rapita"
            },
            {
                "value": 54435,
                "label": "La Matanza de Acentejo",
                "label_ar": "لا ماتانزا دي اسينتيجو",
                "label_fr": "La Matanza de Acentejo"
            },
            {
                "value": 53791,
                "label": "El Abrigo",
                "label_ar": "El Abrigo",
                "label_fr": "El Abrigo"
            },
            {
                "value": 53042,
                "label": "Isla Cristina",
                "label_ar": "إيسلا كريستينا",
                "label_fr": "Isla Cristina"
            },
            {
                "value": 53472,
                "label": "Fortuna",
                "label_ar": "فورتونا",
                "label_fr": "Fortuna"
            },
            {
                "value": 53183,
                "label": "Derio",
                "label_ar": "ديريو",
                "label_fr": "Derio"
            },
            {
                "value": 53671,
                "label": "Trazo",
                "label_ar": "ترازو",
                "label_fr": "Trazo"
            },
            {
                "value": 52542,
                "label": "Lekeitio",
                "label_ar": "ليكيتو",
                "label_fr": "Lekeitio"
            },
            {
                "value": 53821,
                "label": "Begijar",
                "label_ar": "بيججار",
                "label_fr": "Bégijar"
            },
            {
                "value": 54475,
                "label": "Guevejar",
                "label_ar": "Guevejar",
                "label_fr": "Guevejar"
            },
            {
                "value": 53503,
                "label": "Alhama de Murcia",
                "label_ar": "ألاما دي مرسية",
                "label_fr": "Alhama de Murcie"
            },
            {
                "value": 53349,
                "label": "Verges",
                "label_ar": "الحواف",
                "label_fr": "Verges"
            },
            {
                "value": 54451,
                "label": "Villahermosa",
                "label_ar": "فيلاهيرموسا",
                "label_fr": "Villahermosa"
            },
            {
                "value": 52286,
                "label": "Torrelles de Llobregat",
                "label_ar": "توريليس دي يوبريغات",
                "label_fr": "Torrelles de Llobregat"
            },
            {
                "value": 53075,
                "label": "Penagos",
                "label_ar": "بيناغوس",
                "label_fr": "Penagos"
            },
            {
                "value": 52684,
                "label": "Martos",
                "label_ar": "مارتوس",
                "label_fr": "Martos"
            },
            {
                "value": 53052,
                "label": "Formentera de Segura",
                "label_ar": "فورمينتيرا دي سيجورا",
                "label_fr": "Formentera de Segura"
            },
            {
                "value": 51988,
                "label": "Cervello",
                "label_ar": "سيرفيلو",
                "label_fr": "Cervello"
            },
            {
                "value": 52680,
                "label": "Beniajan",
                "label_ar": "Beniajan",
                "label_fr": "Beniajan"
            },
            {
                "value": 52092,
                "label": "Las Navas del Marques",
                "label_ar": "لاس نافاس ديل ماركيز",
                "label_fr": "Las Navas del Marques"
            },
            {
                "value": 54176,
                "label": "Los Angeles",
                "label_ar": "لوس أنجلوس",
                "label_fr": "Los Angeles"
            },
            {
                "value": 52149,
                "label": "Morón de la Frontera",
                "label_ar": "مورون دي لا فرونتيرا",
                "label_fr": "Morón de la Frontera"
            },
            {
                "value": 53116,
                "label": "Valdesogo de Abajo",
                "label_ar": "فالديسوجو دي أباجو",
                "label_fr": "Valdesogo de Abajo"
            },
            {
                "value": 52072,
                "label": "San Sebastián de La Gomera",
                "label_ar": "سان سيباستيان دي لا غوميرا",
                "label_fr": "San Sebastián de La Gomera"
            },
            {
                "value": 54221,
                "label": "Peratallada",
                "label_ar": "بيراتالادا",
                "label_fr": "Peratallada"
            },
            {
                "value": 53142,
                "label": "Cobisa",
                "label_ar": "كوبيسا",
                "label_fr": "Cobisa"
            },
            {
                "value": 53937,
                "label": "Aznalcollar",
                "label_ar": "أزنالكولار",
                "label_fr": "Aznalcollar"
            },
            {
                "value": 52655,
                "label": "Muchamiel",
                "label_ar": "موشاميل",
                "label_fr": "Muchamiel"
            },
            {
                "value": 52691,
                "label": "Carmona",
                "label_ar": "كارمونا",
                "label_fr": "Carmona"
            },
            {
                "value": 52817,
                "label": "Joanetes",
                "label_ar": "جوانيتس",
                "label_fr": "Joanetes"
            },
            {
                "value": 53981,
                "label": "Sarria",
                "label_ar": "ساريا",
                "label_fr": "Sarria"
            },
            {
                "value": 53169,
                "label": "Fuente Vaqueros",
                "label_ar": "فوينتي فاكيروس",
                "label_fr": "Fuente Vaqueros"
            },
            {
                "value": 52815,
                "label": "Sant Pere de Torelló",
                "label_ar": "سانت بيري دي توريلو",
                "label_fr": "Sant Pere de Torelló"
            },
            {
                "value": 53907,
                "label": "Sant Pere de Riudebitlles",
                "label_ar": "Sant Pere de Riudebitlles",
                "label_fr": "Sant Pere de Riudebitlles"
            },
            {
                "value": 52481,
                "label": "Los Corrales de Buelna",
                "label_ar": "لوس كوراليس دي بويلنا",
                "label_fr": "Los Corrales de Buelna"
            },
            {
                "value": 52329,
                "label": "Albolote",
                "label_ar": "ألبولوت",
                "label_fr": "Albolote"
            },
            {
                "value": 52769,
                "label": "Bas",
                "label_ar": "باس",
                "label_fr": "Bas"
            },
            {
                "value": 54476,
                "label": "Caborana",
                "label_ar": "كابورانا",
                "label_fr": "Caborana"
            },
            {
                "value": 54191,
                "label": "Los Silos",
                "label_ar": "لوس سيلوس",
                "label_fr": "Los Silos"
            },
            {
                "value": 52561,
                "label": "Canals",
                "label_ar": "القنوات",
                "label_fr": "Canaux"
            },
            {
                "value": 53957,
                "label": "Masllorenc",
                "label_ar": "ماسلورينك",
                "label_fr": "Masllorenc"
            },
            {
                "value": 54007,
                "label": "Nino Perdido",
                "label_ar": "نينو بيرديدو",
                "label_fr": "Nino Perdido"
            },
            {
                "value": 53732,
                "label": "Artà",
                "label_ar": "أرتا",
                "label_fr": "Artà"
            },
            {
                "value": 54342,
                "label": "Isora",
                "label_ar": "إيسورا",
                "label_fr": "Isora"
            },
            {
                "value": 54355,
                "label": "Turis",
                "label_ar": "توريس",
                "label_fr": "Turis"
            },
            {
                "value": 53903,
                "label": "Pozo de la Serna",
                "label_ar": "بوزو دي لا سيرنا",
                "label_fr": "Pozo de la Serna"
            },
            {
                "value": 52875,
                "label": "La Mojonera",
                "label_ar": "لا موجونيرا",
                "label_fr": "La Mojonera"
            },
            {
                "value": 51944,
                "label": "Torrelodones",
                "label_ar": "توريلودونيس",
                "label_fr": "Torrelodones"
            },
            {
                "value": 53247,
                "label": "Perales del Puerto",
                "label_ar": "بيراليس ديل بويرتو",
                "label_fr": "Perales del Puerto"
            },
            {
                "value": 53254,
                "label": "Benavente",
                "label_ar": "بينافينتي",
                "label_fr": "Benavente"
            },
            {
                "value": 51986,
                "label": "Sevilla La Nueva",
                "label_ar": "اشبيلية لا نويفا",
                "label_fr": "Séville La Nueva"
            },
            {
                "value": 52651,
                "label": "Frailes",
                "label_ar": "فرايلز",
                "label_fr": "Frailes"
            },
            {
                "value": 53660,
                "label": "Marugan",
                "label_ar": "مارجان",
                "label_fr": "Marugan"
            },
            {
                "value": 53761,
                "label": "Madridanos",
                "label_ar": "مدريد",
                "label_fr": "Madridanos"
            },
            {
                "value": 52939,
                "label": "Villabanez",
                "label_ar": "فيلابانيز",
                "label_fr": "Villabanez"
            },
            {
                "value": 54312,
                "label": "Pinos Puente",
                "label_ar": "بينوس بوينتي",
                "label_fr": "Pinos Puente"
            },
            {
                "value": 54422,
                "label": "Villa de Don Fadrique",
                "label_ar": "فيلا دي دون فادريك",
                "label_fr": "Villa de Don Fadrique"
            },
            {
                "value": 53170,
                "label": "Grado",
                "label_ar": "جرادو",
                "label_fr": "Grado"
            },
            {
                "value": 53080,
                "label": "La Rapita",
                "label_ar": "لا رابيتا",
                "label_fr": "La Rapita"
            },
            {
                "value": 52577,
                "label": "Lalin",
                "label_ar": "لالين",
                "label_fr": "Lalin"
            },
            {
                "value": 53748,
                "label": "Pont de Molins",
                "label_ar": "بونت دي مولينز",
                "label_fr": "Pont de Molins"
            },
            {
                "value": 53650,
                "label": "Albalat dels Sorells",
                "label_ar": "البلات دل سوريلس",
                "label_fr": "Albalat dels Sorells"
            },
            {
                "value": 52763,
                "label": "Corralejo",
                "label_ar": "كوراليخو",
                "label_fr": "Corralejo"
            },
            {
                "value": 52342,
                "label": "Mondujar",
                "label_ar": "موندوجار",
                "label_fr": "Mondujar"
            },
            {
                "value": 52126,
                "label": "Algeciras",
                "label_ar": "الجزيرة الخضراء",
                "label_fr": "Algésiras"
            },
            {
                "value": 52399,
                "label": "La Puebla del Rio",
                "label_ar": "لا بويبلا ديل ريو",
                "label_fr": "La Puebla del Rio"
            },
            {
                "value": 52674,
                "label": "Cala del Moral",
                "label_ar": "كالا ديل مورال",
                "label_fr": "Cala del Moral"
            },
            {
                "value": 53695,
                "label": "Tarazona",
                "label_ar": "تارازونا",
                "label_fr": "Tarazona"
            },
            {
                "value": 52981,
                "label": "Carinena",
                "label_ar": "كارينينا",
                "label_fr": "Carinena"
            },
            {
                "value": 52250,
                "label": "Moratalla",
                "label_ar": "موراتالا",
                "label_fr": "Moratalla"
            },
            {
                "value": 52200,
                "label": "Cervera",
                "label_ar": "سيرفيرا",
                "label_fr": "Cervera"
            },
            {
                "value": 52351,
                "label": "Olvera",
                "label_ar": "أولفيرا",
                "label_fr": "Olvera"
            },
            {
                "value": 53251,
                "label": "El Garrobo",
                "label_ar": "الجروبو",
                "label_fr": "El Garrobo"
            },
            {
                "value": 52416,
                "label": "Burgos",
                "label_ar": "بورغوس",
                "label_fr": "Burgos"
            },
            {
                "value": 51959,
                "label": "Hoyo de Manzanares",
                "label_ar": "هويو دي مانزاناريس",
                "label_fr": "Hoyo de Manzanares"
            },
            {
                "value": 52781,
                "label": "La Algaba",
                "label_ar": "لا الجابا",
                "label_fr": "La Algaba"
            },
            {
                "value": 53011,
                "label": "Viveda",
                "label_ar": "فيفيدا",
                "label_fr": "Viveda"
            },
            {
                "value": 53616,
                "label": "Golmayo",
                "label_ar": "جولمايو",
                "label_fr": "Golmayo"
            },
            {
                "value": 54341,
                "label": "Poris de Abona",
                "label_ar": "بوريس دي أبونا",
                "label_fr": "Poris de Abona"
            },
            {
                "value": 53906,
                "label": "Villar",
                "label_ar": "فيلار",
                "label_fr": "Villar"
            },
            {
                "value": 52584,
                "label": "A Estrada",
                "label_ar": "استرادا",
                "label_fr": "A Estrada"
            },
            {
                "value": 52560,
                "label": "Daganzo de Arriba",
                "label_ar": "داجانزو دي أريبا",
                "label_fr": "Daganzo de Arriba"
            },
            {
                "value": 54256,
                "label": "Perafita",
                "label_ar": "بيرافيتا",
                "label_fr": "Perafita"
            },
            {
                "value": 52142,
                "label": "Cordova",
                "label_ar": "كوردوفا",
                "label_fr": "Cordoue"
            },
            {
                "value": 52921,
                "label": "Plasenzuela",
                "label_ar": "بلاسنزويلا",
                "label_fr": "Plasenzuela"
            },
            {
                "value": 53759,
                "label": "Aldeaseca de Armuna",
                "label_ar": "الديسيكا دي أرمونا",
                "label_fr": "Aldeaseca de Armuna"
            },
            {
                "value": 52519,
                "label": "Mengibar",
                "label_ar": "منجبار",
                "label_fr": "Mengibar"
            },
            {
                "value": 53102,
                "label": "Solares",
                "label_ar": "سولاريس",
                "label_fr": "Solares"
            },
            {
                "value": 52466,
                "label": "Cortiguera",
                "label_ar": "كورتيجويرا",
                "label_fr": "Cortiguera"
            },
            {
                "value": 53138,
                "label": "Sant Pere, Santa Caterina i La Ribera",
                "label_ar": "سانت بيري ، سانتا كاترينا إي لا ريبيرا",
                "label_fr": "Sant Pere, Santa Caterina et La Ribera"
            },
            {
                "value": 53851,
                "label": "Gaceta",
                "label_ar": "جاسيتا",
                "label_fr": "Gaceta"
            },
            {
                "value": 53474,
                "label": "El Toro",
                "label_ar": "إل تورو",
                "label_fr": "El Toro"
            },
            {
                "value": 52967,
                "label": "Sierra de Fuentes",
                "label_ar": "سييرا دي فوينتيس",
                "label_fr": "Sierra de Fuentes"
            },
            {
                "value": 54465,
                "label": "Valdetorres de Jarama",
                "label_ar": "فالديتوريس دي جاراما",
                "label_fr": "Valdetorres de Jarama"
            },
            {
                "value": 52023,
                "label": "Huercal de Almeria",
                "label_ar": "هويركال دي الميريا",
                "label_fr": "Huercal de Almeria"
            },
            {
                "value": 53859,
                "label": "La Penilla",
                "label_ar": "لا بينيلا",
                "label_fr": "La Penilla"
            },
            {
                "value": 54338,
                "label": "Hondon de los Frailes",
                "label_ar": "Hondon de los Frailes",
                "label_fr": "Hondon de los Frailes"
            },
            {
                "value": 53172,
                "label": "El Ballestero",
                "label_ar": "إل باليستيرو",
                "label_fr": "El Ballestero"
            },
            {
                "value": 52152,
                "label": "Águilas",
                "label_ar": "Águilas",
                "label_fr": "Águilas"
            },
            {
                "value": 52116,
                "label": "Tortosa",
                "label_ar": "طرطوشة",
                "label_fr": "Tortosa"
            },
            {
                "value": 52615,
                "label": "Baga",
                "label_ar": "باجا",
                "label_fr": "Baga"
            },
            {
                "value": 52961,
                "label": "Coiros",
                "label_ar": "كيروس",
                "label_fr": "Coiros"
            },
            {
                "value": 52947,
                "label": "Sojuela",
                "label_ar": "سوجويلا",
                "label_fr": "Sojuela"
            },
            {
                "value": 53167,
                "label": "Jumilla",
                "label_ar": "جوميلا",
                "label_fr": "Jumilla"
            },
            {
                "value": 52757,
                "label": "Meliana",
                "label_ar": "ميليانا",
                "label_fr": "Meliana"
            },
            {
                "value": 53658,
                "label": "Aldeanueva de Ebro",
                "label_ar": "الدينويفا دي إيبرو",
                "label_fr": "Aldeanueva de Ebro"
            },
            {
                "value": 52874,
                "label": "Guadiaro",
                "label_ar": "جواديارو",
                "label_fr": "Guadiaro"
            },
            {
                "value": 53779,
                "label": "Navas del Rey",
                "label_ar": "نافاس ديل ري",
                "label_fr": "Navas del Rey"
            },
            {
                "value": 51962,
                "label": "Torres de la Alameda",
                "label_ar": "توريس دي لا ألاميدا",
                "label_fr": "Torres de la Alameda"
            },
            {
                "value": 53784,
                "label": "Valsequillo de Gran Canaria",
                "label_ar": "فالسكييلو دي جران كناريا",
                "label_fr": "Valsequillo de Gran Canaria"
            },
            {
                "value": 52899,
                "label": "Vilamalla",
                "label_ar": "فيلامالا",
                "label_fr": "Vilamalla"
            },
            {
                "value": 53468,
                "label": "Ricla",
                "label_ar": "ريكلا",
                "label_fr": "Ricla"
            },
            {
                "value": 53061,
                "label": "Gallur",
                "label_ar": "غالور",
                "label_fr": "Gallur"
            },
            {
                "value": 52098,
                "label": "Granadilla de Abona",
                "label_ar": "غراناديلا دي أبونا",
                "label_fr": "Granadilla de Abona"
            },
            {
                "value": 53039,
                "label": "Barbastro",
                "label_ar": "بارباسترو",
                "label_fr": "Barbastro"
            },
            {
                "value": 52108,
                "label": "Tegueste",
                "label_ar": "Tegueste",
                "label_fr": "Tegueste"
            },
            {
                "value": 54297,
                "label": "Vilasantar",
                "label_ar": "فيلاسانتار",
                "label_fr": "Vilasantar"
            },
            {
                "value": 54161,
                "label": "Montejicar",
                "label_ar": "مونتيجيكار",
                "label_fr": "Montejicar"
            },
            {
                "value": 53226,
                "label": "Las Galletas",
                "label_ar": "لاس جاليتاس",
                "label_fr": "Las Galletas"
            },
            {
                "value": 52085,
                "label": "Marbella",
                "label_ar": "ماربيا",
                "label_fr": "Marbella"
            },
            {
                "value": 52377,
                "label": "Boiro",
                "label_ar": "بويرو",
                "label_fr": "Boiro"
            },
            {
                "value": 52031,
                "label": "Roquetas de Mar",
                "label_ar": "روكيتاس دي مار",
                "label_fr": "Roquetas de Mar"
            },
            {
                "value": 54304,
                "label": "Central",
                "label_ar": "وسط",
                "label_fr": "Central"
            },
            {
                "value": 53432,
                "label": "Olazagutia",
                "label_ar": "Olazagutia",
                "label_fr": "Olazagutia"
            },
            {
                "value": 53925,
                "label": "Bogajo",
                "label_ar": "بوجاجو",
                "label_fr": "Bogajo"
            },
            {
                "value": 52268,
                "label": "Torrent",
                "label_ar": "سيل",
                "label_fr": "Torrent"
            },
            {
                "value": 54286,
                "label": "Roses",
                "label_ar": "ورود",
                "label_fr": "Des roses"
            },
            {
                "value": 53084,
                "label": "El Grado",
                "label_ar": "الجرادو",
                "label_fr": "El Grado"
            },
            {
                "value": 52743,
                "label": "Urdiain",
                "label_ar": "Urdiain",
                "label_fr": "Urdiain"
            },
            {
                "value": 53268,
                "label": "San Felices",
                "label_ar": "سان فيليس",
                "label_fr": "San Felices"
            },
            {
                "value": 54016,
                "label": "Los Barrios",
                "label_ar": "لوس باريوس",
                "label_fr": "Los Barrios"
            },
            {
                "value": 51933,
                "label": "Colmenar Viejo",
                "label_ar": "كولمينار فيجو",
                "label_fr": "Colmenar Viejo"
            },
            {
                "value": 52275,
                "label": "La Unión",
                "label_ar": "لا أونيون",
                "label_fr": "La Unión"
            },
            {
                "value": 53413,
                "label": "Guarena",
                "label_ar": "Guarena",
                "label_fr": "Guarena"
            },
            {
                "value": 53858,
                "label": "Chinchilla de Monte Aragon",
                "label_ar": "شينشيلا دي مونتي أراغون",
                "label_fr": "Chinchilla de Monte Aragon"
            },
            {
                "value": 51925,
                "label": "Valdemoro",
                "label_ar": "فالديمورو",
                "label_fr": "Valdemoro"
            },
            {
                "value": 54423,
                "label": "El Alamo",
                "label_ar": "ألامو",
                "label_fr": "El Alamo"
            },
            {
                "value": 52634,
                "label": "Calpe",
                "label_ar": "كالبي",
                "label_fr": "Calpe"
            },
            {
                "value": 52970,
                "label": "Graus",
                "label_ar": "جراوس",
                "label_fr": "Graus"
            },
            {
                "value": 53521,
                "label": "Cifuentes",
                "label_ar": "سيفوينتس",
                "label_fr": "Cifuentes"
            },
            {
                "value": 54279,
                "label": "Benacazon",
                "label_ar": "بيناكازون",
                "label_fr": "Benacazon"
            },
            {
                "value": 54247,
                "label": "Puente Viesgo",
                "label_ar": "بوينتي فيسجو",
                "label_fr": "Puente Viesgo"
            },
            {
                "value": 52882,
                "label": "Paracuellos de Jarama",
                "label_ar": "باراكويلوس دي جاراما",
                "label_fr": "Paracuellos de Jarama"
            },
            {
                "value": 53407,
                "label": "la Bisbal del Penedes",
                "label_ar": "لا بيسبال ديل بينديس",
                "label_fr": "la Bisbal del Penedes"
            },
            {
                "value": 53518,
                "label": "Galdames Beitia",
                "label_ar": "جالدامس بيتيا",
                "label_fr": "Galdames Beitia"
            },
            {
                "value": 53662,
                "label": "Puerto Lumbreras",
                "label_ar": "بويرتو لومبريراس",
                "label_fr": "Puerto Lumbreras"
            },
            {
                "value": 52562,
                "label": "Cantillana",
                "label_ar": "كانتيلانا",
                "label_fr": "Cantillana"
            },
            {
                "value": 52528,
                "label": "Campillo",
                "label_ar": "كامبيلو",
                "label_fr": "Campillo"
            },
            {
                "value": 51910,
                "label": "Terrassa",
                "label_ar": "تيراسا",
                "label_fr": "Terrassa"
            },
            {
                "value": 54025,
                "label": "Ciudad Rodrigo",
                "label_ar": "سيوداد رودريجو",
                "label_fr": "Ciudad Rodrigo"
            },
            {
                "value": 52872,
                "label": "Fruiz",
                "label_ar": "فرويز",
                "label_fr": "Fruiz"
            },
            {
                "value": 53613,
                "label": "Maria de la Salut",
                "label_ar": "ماريا دي لا سالوت",
                "label_fr": "Maria de la Salut"
            },
            {
                "value": 52251,
                "label": "Totana",
                "label_ar": "توتانا",
                "label_fr": "Totana"
            },
            {
                "value": 52465,
                "label": "Baena",
                "label_ar": "باينا",
                "label_fr": "Baena"
            },
            {
                "value": 52812,
                "label": "Cala",
                "label_ar": "كالا",
                "label_fr": "Cala"
            },
            {
                "value": 53477,
                "label": "Corbera",
                "label_ar": "كوربيرا",
                "label_fr": "Corbera"
            },
            {
                "value": 53864,
                "label": "Zuera",
                "label_ar": "زويرا",
                "label_fr": "Zuera"
            },
            {
                "value": 53153,
                "label": "Polop",
                "label_ar": "بولوب",
                "label_fr": "Polop"
            },
            {
                "value": 54046,
                "label": "Padul",
                "label_ar": "بادول",
                "label_fr": "Padul"
            },
            {
                "value": 52134,
                "label": "Artes",
                "label_ar": "أرتيس",
                "label_fr": "Artes"
            },
            {
                "value": 52061,
                "label": "Úbeda",
                "label_ar": "أبدة",
                "label_fr": "Úbeda"
            },
            {
                "value": 52113,
                "label": "Cáceres",
                "label_ar": "كاسيريس",
                "label_fr": "Cáceres"
            },
            {
                "value": 52063,
                "label": "Alcala la Real",
                "label_ar": "الكالا لا ريال",
                "label_fr": "Alcala la Real"
            },
            {
                "value": 54399,
                "label": "Padilla del Ducado",
                "label_ar": "باديلا ديل دوكادو",
                "label_fr": "Padilla del Ducado"
            },
            {
                "value": 54260,
                "label": "Villanueva del Rio",
                "label_ar": "فيلانويفا ديل ريو",
                "label_fr": "Villanueva del Rio"
            },
            {
                "value": 52406,
                "label": "Fuensalida",
                "label_ar": "فوينساليدا",
                "label_fr": "Fuensalida"
            },
            {
                "value": 52972,
                "label": "Guadalix de la Sierra",
                "label_ar": "Guadalix de la Sierra",
                "label_fr": "Guadalix de la Sierra"
            },
            {
                "value": 51961,
                "label": "Los Molinos",
                "label_ar": "لوس مولينوس",
                "label_fr": "Los Molinos"
            },
            {
                "value": 52621,
                "label": "La Alberca",
                "label_ar": "لا ألبيركا",
                "label_fr": "La Alberca"
            },
            {
                "value": 52074,
                "label": "San Sebastian",
                "label_ar": "سان سيباستيان",
                "label_fr": "Saint-Sébastien"
            },
            {
                "value": 54056,
                "label": "Alfajarin",
                "label_ar": "الفجارين",
                "label_fr": "Alfajarin"
            },
            {
                "value": 53147,
                "label": "Torrejon del Rey",
                "label_ar": "توريجون ديل ري",
                "label_fr": "Torrejon del Rey"
            },
            {
                "value": 52556,
                "label": "Ogijares",
                "label_ar": "أوجيجاريس",
                "label_fr": "Ogijares"
            },
            {
                "value": 52503,
                "label": "Novelda",
                "label_ar": "نوفيلدا",
                "label_fr": "Novelda"
            },
            {
                "value": 52774,
                "label": "Ontigola",
                "label_ar": "أونتيجولا",
                "label_fr": "Ontigola"
            },
            {
                "value": 51975,
                "label": "Valdilecha",
                "label_ar": "فالديليتشا",
                "label_fr": "Valdilecha"
            },
            {
                "value": 51951,
                "label": "Ciempozuelos",
                "label_ar": "Ciempozuelos",
                "label_fr": "Ciempozuelos"
            },
            {
                "value": 54259,
                "label": "Gaucin",
                "label_ar": "جوسين",
                "label_fr": "Gaucin"
            },
            {
                "value": 53066,
                "label": "Valderrey",
                "label_ar": "فالديري",
                "label_fr": "Valderrey"
            },
            {
                "value": 52752,
                "label": "Socuellamos",
                "label_ar": "سوكويلاموس",
                "label_fr": "Socuellamos"
            },
            {
                "value": 54401,
                "label": "Buenache de Alarcon",
                "label_ar": "بويناش دي الاركون",
                "label_fr": "Buenache de Alarcon"
            },
            {
                "value": 53680,
                "label": "Villanueva de Gomez",
                "label_ar": "فيلانويفا دي جوميز",
                "label_fr": "Villanueva de Gomez"
            },
            {
                "value": 53177,
                "label": "Binefar",
                "label_ar": "بينيفار",
                "label_fr": "Binefar"
            },
            {
                "value": 52245,
                "label": "Santa Coloma de Cervelló",
                "label_ar": "سانتا كولوما دي سيرفيلو",
                "label_fr": "Santa Coloma de Cervelló"
            },
            {
                "value": 52741,
                "label": "Artana",
                "label_ar": "أرتانا",
                "label_fr": "Artana"
            },
            {
                "value": 52383,
                "label": "Castalla",
                "label_ar": "كاستلا",
                "label_fr": "Castalla"
            },
            {
                "value": 52515,
                "label": "La Roda de Andalucia",
                "label_ar": "لا رودا دي أندلسية",
                "label_fr": "La Roda de Andalucia"
            },
            {
                "value": 54329,
                "label": "Beniel",
                "label_ar": "بينيل",
                "label_fr": "Beniel"
            },
            {
                "value": 54378,
                "label": "Madrigueras",
                "label_ar": "مادريجوراس",
                "label_fr": "Madrigueras"
            },
            {
                "value": 53753,
                "label": "Los Rosales",
                "label_ar": "لوس روساليس",
                "label_fr": "Los Rosales"
            },
            {
                "value": 53718,
                "label": "L'Olleria",
                "label_ar": "لوليريا",
                "label_fr": "L'Olleria"
            },
            {
                "value": 53904,
                "label": "Campo de Criptana",
                "label_ar": "كامبو دي كريبتانا",
                "label_fr": "Campo de Criptana"
            },
            {
                "value": 51907,
                "label": "Barcelona",
                "label_ar": "برشلونة",
                "label_fr": "Barcelone"
            },
            {
                "value": 52713,
                "label": "Almonte",
                "label_ar": "ألمونتي",
                "label_fr": "Almonte"
            },
            {
                "value": 53856,
                "label": "Cabanaquinta",
                "label_ar": "كاباناكوينتا",
                "label_fr": "Cabanaquinta"
            },
            {
                "value": 54101,
                "label": "Eltzaburu",
                "label_ar": "Eltzaburu",
                "label_fr": "Eltzaburu"
            },
            {
                "value": 54418,
                "label": "Colonia de San Pedro",
                "label_ar": "كولونيا دي سان بيدرو",
                "label_fr": "Colonia de San Pedro"
            },
            {
                "value": 52188,
                "label": "Mairena del Aljarafe",
                "label_ar": "ميرينا ديل الجرافي",
                "label_fr": "Mairena del Aljarafe"
            },
            {
                "value": 52076,
                "label": "Burguillos del Cerro",
                "label_ar": "بورغيلوس ديل سيرو",
                "label_fr": "Burguillos del Cerro"
            },
            {
                "value": 52926,
                "label": "Valladolises",
                "label_ar": "فالادوليس",
                "label_fr": "Valladolises"
            },
            {
                "value": 53266,
                "label": "Villaviad",
                "label_ar": "فيلافياد",
                "label_fr": "Villaviad"
            },
            {
                "value": 53627,
                "label": "Grove, O",
                "label_ar": "جروف ، أو",
                "label_fr": "Grove, O"
            },
            {
                "value": 53494,
                "label": "Tobarra",
                "label_ar": "طوبارا",
                "label_fr": "Tobarra"
            },
            {
                "value": 52978,
                "label": "Cercedilla",
                "label_ar": "سيرسيديلا",
                "label_fr": "Cercedilla"
            },
            {
                "value": 54395,
                "label": "Pulpí",
                "label_ar": "Pulpí",
                "label_fr": "Pulpí"
            },
            {
                "value": 51990,
                "label": "Navalcarnero",
                "label_ar": "نافالكارنيرو",
                "label_fr": "Navalcarnero"
            },
            {
                "value": 52120,
                "label": "Algete",
                "label_ar": "Algete",
                "label_fr": "Algete"
            },
            {
                "value": 54300,
                "label": "Arroyal",
                "label_ar": "أرويال",
                "label_fr": "Arroyal"
            },
            {
                "value": 52732,
                "label": "Espartinas",
                "label_ar": "اسبارتيناس",
                "label_fr": "Espartinas"
            },
            {
                "value": 52934,
                "label": "Mediona",
                "label_ar": "ميديونا",
                "label_fr": "Mediona"
            },
            {
                "value": 54202,
                "label": "Daroca",
                "label_ar": "داروكا",
                "label_fr": "Daroca"
            },
            {
                "value": 53687,
                "label": "Tias",
                "label_ar": "تياس",
                "label_fr": "Tias"
            },
            {
                "value": 53912,
                "label": "Lezama",
                "label_ar": "ليزاما",
                "label_fr": "Lezama"
            },
            {
                "value": 53175,
                "label": "Corme-Porto",
                "label_ar": "كورمي بورتو",
                "label_fr": "Corme-Porto"
            },
            {
                "value": 52601,
                "label": "Muro",
                "label_ar": "مورو",
                "label_fr": "Muro"
            },
            {
                "value": 52594,
                "label": "Rairiz de Veiga",
                "label_ar": "Rairiz de Veiga",
                "label_fr": "Rairiz de Veiga"
            },
            {
                "value": 52516,
                "label": "Rioja",
                "label_ar": "ريوجا",
                "label_fr": "Rioja"
            },
            {
                "value": 52316,
                "label": "Capellades",
                "label_ar": "كابيلادس",
                "label_fr": "Capellades"
            },
            {
                "value": 53696,
                "label": "Anguciana",
                "label_ar": "أنجوسيانا",
                "label_fr": "Anguciana"
            },
            {
                "value": 52022,
                "label": "Vera",
                "label_ar": "فيرا",
                "label_fr": "Vera"
            },
            {
                "value": 53280,
                "label": "Torre del Mar",
                "label_ar": "توري ديل مار",
                "label_fr": "Torre del Mar"
            },
            {
                "value": 52698,
                "label": "Zaramillo",
                "label_ar": "زاراميلو",
                "label_fr": "Zaramillo"
            },
            {
                "value": 52359,
                "label": "El Pozo de los Frailes",
                "label_ar": "El Pozo de los Frailes",
                "label_fr": "El Pozo de los Frailes"
            },
            {
                "value": 52661,
                "label": "Talavera de la Reina",
                "label_ar": "تالافيرا دي لا رينا",
                "label_fr": "Talavera de la Reina"
            },
            {
                "value": 52919,
                "label": "Amurrio",
                "label_ar": "اموريو",
                "label_fr": "Amurrio"
            },
            {
                "value": 53813,
                "label": "Dalias",
                "label_ar": "دالياس",
                "label_fr": "Dalias"
            },
            {
                "value": 52352,
                "label": "La Roda",
                "label_ar": "لا رودا",
                "label_fr": "La Roda"
            },
            {
                "value": 52749,
                "label": "Godelleta",
                "label_ar": "جوديليتا",
                "label_fr": "Godelleta"
            },
            {
                "value": 54198,
                "label": "Horche",
                "label_ar": "حورتش",
                "label_fr": "Horche"
            },
            {
                "value": 53828,
                "label": "Castandiello",
                "label_ar": "كاستانديلو",
                "label_fr": "Castandiello"
            },
            {
                "value": 52165,
                "label": "Brenes",
                "label_ar": "برينز",
                "label_fr": "Brenes"
            },
            {
                "value": 52624,
                "label": "Moja",
                "label_ar": "موجا",
                "label_fr": "Moja"
            },
            {
                "value": 52425,
                "label": "Alcorisa",
                "label_ar": "الكوريسا",
                "label_fr": "Alcorisa"
            },
            {
                "value": 53051,
                "label": "Viana",
                "label_ar": "فيانا",
                "label_fr": "Viana"
            },
            {
                "value": 54363,
                "label": "Segorbe",
                "label_ar": "سيغوربي",
                "label_fr": "Segorbe"
            },
            {
                "value": 54137,
                "label": "San Nicolas del Puerto",
                "label_ar": "سان نيكولاس ديل بويرتو",
                "label_fr": "San Nicolas del Puerto"
            },
            {
                "value": 53815,
                "label": "Yuncos",
                "label_ar": "يونكوس",
                "label_fr": "Yuncos"
            },
            {
                "value": 52384,
                "label": "Castilleja de la Cuesta",
                "label_ar": "كاستيليا دي لا كويستا",
                "label_fr": "Castilleja de la Cuesta"
            },
            {
                "value": 53023,
                "label": "Arcade",
                "label_ar": "ممر",
                "label_fr": "Arcade"
            },
            {
                "value": 53154,
                "label": "San Juan de Alicante",
                "label_ar": "سان خوان دي اليكانتي",
                "label_fr": "San Juan de Alicante"
            },
            {
                "value": 53585,
                "label": "Marcilla",
                "label_ar": "مارسيلا",
                "label_fr": "Marcilla"
            },
            {
                "value": 54036,
                "label": "Loriguilla",
                "label_ar": "لوريجويلا",
                "label_fr": "Loriguilla"
            },
            {
                "value": 54211,
                "label": "Benifairo de les Valls",
                "label_ar": "Benifairo de les Valls",
                "label_fr": "Benifairo de les Valls"
            },
            {
                "value": 54217,
                "label": "Santa Cristina de Aro",
                "label_ar": "سانتا كريستينا دي أرو",
                "label_fr": "Santa Cristina de Aro"
            },
            {
                "value": 53549,
                "label": "San Luis de Sabinillas",
                "label_ar": "سان لويس دي سابينياس",
                "label_fr": "San Luis de Sabinillas"
            },
            {
                "value": 53945,
                "label": "Monda",
                "label_ar": "موندا",
                "label_fr": "Monda"
            },
            {
                "value": 53783,
                "label": "Fuente-Alamo",
                "label_ar": "فوينتي ألامو",
                "label_fr": "Fuente-Alamo"
            },
            {
                "value": 53396,
                "label": "Sancti Spiritus",
                "label_ar": "سانكتي سبيريتوس",
                "label_fr": "Sancti Spiritus"
            },
            {
                "value": 52504,
                "label": "l'Alcora",
                "label_ar": "ألكورا",
                "label_fr": "l'Alcora"
            },
            {
                "value": 52722,
                "label": "Montearagon",
                "label_ar": "مونتراجون",
                "label_fr": "Montearagon"
            },
            {
                "value": 53146,
                "label": "Portonovo",
                "label_ar": "بورتونوفو",
                "label_fr": "Portonovo"
            },
            {
                "value": 52718,
                "label": "Trobajo del Camino",
                "label_ar": "تروباجو ديل كامينو",
                "label_fr": "Trobajo del Camino"
            },
            {
                "value": 53795,
                "label": "Almansa",
                "label_ar": "المنسى",
                "label_fr": "Almansa"
            },
            {
                "value": 53747,
                "label": "Carrizo de la Ribera",
                "label_ar": "كاريزو دي لا ريبيرا",
                "label_fr": "Carrizo de la Ribera"
            },
            {
                "value": 53077,
                "label": "Villasana de Mena",
                "label_ar": "فيلاسانا دي مينا",
                "label_fr": "Villasana de Mena"
            },
            {
                "value": 52059,
                "label": "Hinojos",
                "label_ar": "هينوجوس",
                "label_fr": "Hinojos"
            },
            {
                "value": 52518,
                "label": "Benitachell",
                "label_ar": "بينيتاشيل",
                "label_fr": "Benitachell"
            },
            {
                "value": 52042,
                "label": "Vallgorguina",
                "label_ar": "Vallgorguina",
                "label_fr": "Vallgorguina"
            },
            {
                "value": 52568,
                "label": "Madrigalejo",
                "label_ar": "مادريجاليجو",
                "label_fr": "Madrigalejo"
            },
            {
                "value": 52448,
                "label": "Sant Joan les Fonts",
                "label_ar": "سانت جوان ليه فونتس",
                "label_fr": "Sant Joan les Fonts"
            },
            {
                "value": 52114,
                "label": "Santa Maria de Corcó",
                "label_ar": "سانتا ماريا دي كوركو",
                "label_fr": "Santa Maria de Corcó"
            },
            {
                "value": 53319,
                "label": "Torrefarrera",
                "label_ar": "توريفاريرا",
                "label_fr": "Torrefarrera"
            },
            {
                "value": 53272,
                "label": "Bellpuig",
                "label_ar": "بيلبويج",
                "label_fr": "Bellpuig"
            },
            {
                "value": 53797,
                "label": "Pulgar",
                "label_ar": "بولجار",
                "label_fr": "Pulgar"
            },
            {
                "value": 52955,
                "label": "Reinosa",
                "label_ar": "رينوزا",
                "label_fr": "Reinosa"
            },
            {
                "value": 54481,
                "label": "Triana",
                "label_ar": "تريانا",
                "label_fr": "Triana"
            },
            {
                "value": 53561,
                "label": "Villarreal de Alava",
                "label_ar": "فياريال دي ألافا",
                "label_fr": "Villarreal de Alava"
            },
            {
                "value": 52442,
                "label": "Villanueva del Rosario",
                "label_ar": "فيلانويفا ديل روزاريو",
                "label_fr": "Villanueva del Rosario"
            },
            {
                "value": 53673,
                "label": "El Perello",
                "label_ar": "إل بيريلو",
                "label_fr": "El Perello"
            },
            {
                "value": 52456,
                "label": "Los Villares",
                "label_ar": "لوس فيلاريس",
                "label_fr": "Los Villares"
            },
            {
                "value": 53004,
                "label": "El Sauzal",
                "label_ar": "السوزال",
                "label_fr": "El Sauzal"
            },
            {
                "value": 52703,
                "label": "Cala Millor",
                "label_ar": "كالا ميلور",
                "label_fr": "Cala Millor"
            },
            {
                "value": 52097,
                "label": "Tacoronte",
                "label_ar": "تاكورونتي",
                "label_fr": "Tacoronte"
            },
            {
                "value": 53519,
                "label": "Herrera del Duque",
                "label_ar": "هيريرا ديل دوكي",
                "label_fr": "Herrera del Duque"
            },
            {
                "value": 53366,
                "label": "Llinars del Valles",
                "label_ar": "لينارس ديل فاليس",
                "label_fr": "Llinars del Valles"
            },
            {
                "value": 52551,
                "label": "Rocafort",
                "label_ar": "روكافورت",
                "label_fr": "Rocafort"
            },
            {
                "value": 52371,
                "label": "Rute",
                "label_ar": "روت",
                "label_fr": "Rute"
            },
            {
                "value": 52723,
                "label": "Albarreal de Tajo",
                "label_ar": "الباريال دي تاجو",
                "label_fr": "Albarreal de Tajo"
            },
            {
                "value": 52635,
                "label": "Javea",
                "label_ar": "جافيا",
                "label_fr": "Javea"
            },
            {
                "value": 53249,
                "label": "La Orden",
                "label_ar": "لا أوردين",
                "label_fr": "La Orden"
            },
            {
                "value": 52378,
                "label": "Sobarzo",
                "label_ar": "سوبارزو",
                "label_fr": "Sobarzo"
            },
            {
                "value": 54459,
                "label": "Luceni",
                "label_ar": "Luceni",
                "label_fr": "Luceni"
            },
            {
                "value": 52861,
                "label": "Cardenosa",
                "label_ar": "كاردنوسا",
                "label_fr": "Cardenosa"
            },
            {
                "value": 54315,
                "label": "Fuenterrabia",
                "label_ar": "فوينترابيا",
                "label_fr": "Fuenterrabia"
            },
            {
                "value": 53033,
                "label": "Osuna",
                "label_ar": "أوسونا",
                "label_fr": "Osuna"
            },
            {
                "value": 51964,
                "label": "les Borges del Camp",
                "label_ar": "ليه بورخيس ديل كامب",
                "label_fr": "les Borges del Camp"
            },
            {
                "value": 52433,
                "label": "Villamiel de Toledo",
                "label_ar": "فيلاميل دي توليدو",
                "label_fr": "Villamiel de Tolède"
            },
            {
                "value": 52546,
                "label": "Huesca",
                "label_ar": "هويسكا",
                "label_fr": "Huesca"
            },
            {
                "value": 52260,
                "label": "Vilanova de Segriá",
                "label_ar": "فيلانوفا دي سيغريا",
                "label_fr": "Vilanova de Segriá"
            },
            {
                "value": 53508,
                "label": "Palmeira",
                "label_ar": "بالميرا",
                "label_fr": "Palmeira"
            },
            {
                "value": 54073,
                "label": "Cuevas de San Marcos",
                "label_ar": "كويفاس دي سان ماركوس",
                "label_fr": "Cuevas de San Marcos"
            },
            {
                "value": 53984,
                "label": "Riaza",
                "label_ar": "ريازة",
                "label_fr": "Riaza"
            },
            {
                "value": 52328,
                "label": "Mislata",
                "label_ar": "ميسلاتا",
                "label_fr": "Mislata"
            },
            {
                "value": 53990,
                "label": "Lodosa",
                "label_ar": "لودوسا",
                "label_fr": "Lodosa"
            },
            {
                "value": 53854,
                "label": "Gojar",
                "label_ar": "غوجار",
                "label_fr": "Gojar"
            },
            {
                "value": 53772,
                "label": "Nivar",
                "label_ar": "نيفار",
                "label_fr": "Nivar"
            },
            {
                "value": 52559,
                "label": "Vilagarcia de Arousa",
                "label_ar": "فيلاجارسيا دي أروسا",
                "label_fr": "Vilagarcia de Arousa"
            },
            {
                "value": 53905,
                "label": "Pedro Munoz",
                "label_ar": "بيدرو مونوز",
                "label_fr": "Pedro Munoz"
            },
            {
                "value": 54324,
                "label": "Pedrosillo el Ralo",
                "label_ar": "بيدروسيلو إل رالو",
                "label_fr": "Pedrosillo el Ralo"
            },
            {
                "value": 52107,
                "label": "Valverde",
                "label_ar": "فالفيردي",
                "label_fr": "Valverde"
            },
            {
                "value": 52372,
                "label": "Tomares",
                "label_ar": "توماريس",
                "label_fr": "Tomares"
            },
            {
                "value": 52645,
                "label": "Plasencia",
                "label_ar": "بلاسينسيا",
                "label_fr": "Plasencia"
            },
            {
                "value": 54085,
                "label": "Compostela",
                "label_ar": "كومبوستيلا",
                "label_fr": "Compostelle"
            },
            {
                "value": 52708,
                "label": "Alameda",
                "label_ar": "ألاميدا",
                "label_fr": "Alameda"
            },
            {
                "value": 52001,
                "label": "Colmenar del Arroyo",
                "label_ar": "كولمينار ديل أرويو",
                "label_fr": "Colmenar del Arroyo"
            },
            {
                "value": 54263,
                "label": "Ourense",
                "label_ar": "أورينس",
                "label_fr": "Ourense"
            },
            {
                "value": 53221,
                "label": "Pinoso",
                "label_ar": "بينوسو",
                "label_fr": "Pinoso"
            },
            {
                "value": 52822,
                "label": "Taboada",
                "label_ar": "تابوادا",
                "label_fr": "Taboada"
            },
            {
                "value": 53210,
                "label": "La Muela",
                "label_ar": "لا مويلا",
                "label_fr": "La Muela"
            },
            {
                "value": 54346,
                "label": "Valpalmas",
                "label_ar": "فالبالماس",
                "label_fr": "Valpalmas"
            },
            {
                "value": 52345,
                "label": "Yecla",
                "label_ar": "يكلا",
                "label_fr": "Yecla"
            },
            {
                "value": 54034,
                "label": "Ontur",
                "label_ar": "أونتور",
                "label_fr": "Ontur"
            },
            {
                "value": 53335,
                "label": "Carbajal de la Legua",
                "label_ar": "كارباجال دي لا ليغوا",
                "label_fr": "Carbajal de la Legua"
            },
            {
                "value": 54235,
                "label": "Fuentes de Jiloca",
                "label_ar": "فوينتيس دي جيلوكا",
                "label_fr": "Fuentes de Jiloca"
            },
            {
                "value": 53019,
                "label": "Carcar",
                "label_ar": "سيارة سيارة",
                "label_fr": "Voiture voiture"
            },
            {
                "value": 52527,
                "label": "Miramar",
                "label_ar": "ميرامار",
                "label_fr": "Miramar"
            },
            {
                "value": 52493,
                "label": "Soto de la Marina",
                "label_ar": "سوتو دي لا مارينا",
                "label_fr": "Soto de la Marina"
            },
            {
                "value": 53360,
                "label": "Mondonedo",
                "label_ar": "موندونيدو",
                "label_fr": "Mondonedo"
            },
            {
                "value": 54265,
                "label": "Pastrana",
                "label_ar": "باسترانا",
                "label_fr": "Pastrana"
            },
            {
                "value": 54269,
                "label": "Maria de Huerva",
                "label_ar": "ماريا دي هويرفا",
                "label_fr": "Maria de Huerva"
            },
            {
                "value": 53157,
                "label": "Hontoria del Pinar",
                "label_ar": "هونتوريا ديل بينار",
                "label_fr": "Hontoria del Pinar"
            },
            {
                "value": 54121,
                "label": "Tejina",
                "label_ar": "تيجينا",
                "label_fr": "Tejina"
            },
            {
                "value": 52847,
                "label": "Sant Joan de les Abadesses",
                "label_ar": "سانت جوان دي ليس أباديس",
                "label_fr": "Sant Joan de les Abadesses"
            },
            {
                "value": 53623,
                "label": "Guenes",
                "label_ar": "جين",
                "label_fr": "Guenes"
            },
            {
                "value": 53572,
                "label": "Berriz",
                "label_ar": "بريز",
                "label_fr": "Berriz"
            },
            {
                "value": 51999,
                "label": "Colmenarejo",
                "label_ar": "كولميناريجو",
                "label_fr": "Colmenarejo"
            },
            {
                "value": 52648,
                "label": "Gines",
                "label_ar": "جينس",
                "label_fr": "Gines"
            },
            {
                "value": 54053,
                "label": "Torralba de Oropesa",
                "label_ar": "Torralba de Oropesa",
                "label_fr": "Torralba de Oropesa"
            },
            {
                "value": 53782,
                "label": "Corvera",
                "label_ar": "كورفيرا",
                "label_fr": "Corvera"
            },
            {
                "value": 53764,
                "label": "Fabero",
                "label_ar": "فابيرو",
                "label_fr": "Fabero"
            },
            {
                "value": 54307,
                "label": "Villalpardo",
                "label_ar": "فيلالباردو",
                "label_fr": "Villalpardo"
            },
            {
                "value": 53993,
                "label": "Cizur Menor",
                "label_ar": "سيزور مينور",
                "label_fr": "Cizur Menor"
            },
            {
                "value": 53410,
                "label": "Monterroso",
                "label_ar": "مونتيروسو",
                "label_fr": "Monterroso"
            },
            {
                "value": 52253,
                "label": "Cieza",
                "label_ar": "سيزا",
                "label_fr": "Cieza"
            },
            {
                "value": 53401,
                "label": "Trillo",
                "label_ar": "تريلو",
                "label_fr": "Trillo"
            },
            {
                "value": 52728,
                "label": "La Fresneda",
                "label_ar": "لا فريسندا",
                "label_fr": "La Fresneda"
            },
            {
                "value": 54103,
                "label": "Valcabado",
                "label_ar": "فالكابادو",
                "label_fr": "Valcabado"
            },
            {
                "value": 54443,
                "label": "la Vall del Bac",
                "label_ar": "لا فال ديل باك",
                "label_fr": "la Vall del Bac"
            },
            {
                "value": 53685,
                "label": "Santovenia de Pisuerga",
                "label_ar": "سانتوفينيا دي بيسويرغا",
                "label_fr": "Santovenia de Pisuerga"
            },
            {
                "value": 52288,
                "label": "Castellet",
                "label_ar": "كاستيلت",
                "label_fr": "Castellet"
            },
            {
                "value": 53300,
                "label": "Manilva",
                "label_ar": "مانيلفا",
                "label_fr": "Manilva"
            },
            {
                "value": 53995,
                "label": "Mungia",
                "label_ar": "مونجيا",
                "label_fr": "Mungia"
            },
            {
                "value": 52294,
                "label": "Silla",
                "label_ar": "سيلا",
                "label_fr": "Silla"
            },
            {
                "value": 52570,
                "label": "Bocairent",
                "label_ar": "بوكيرنت",
                "label_fr": "Bocairent"
            },
            {
                "value": 53262,
                "label": "Beniarjo",
                "label_ar": "بنيارجو",
                "label_fr": "Beniarjo"
            },
            {
                "value": 53199,
                "label": "Buñol",
                "label_ar": "بونول",
                "label_fr": "Buñol"
            },
            {
                "value": 53909,
                "label": "Aranguren",
                "label_ar": "أرانجورين",
                "label_fr": "Aranguren"
            },
            {
                "value": 53942,
                "label": "Villanueva de la Reina",
                "label_ar": "فيلانويفا دي لا رينا",
                "label_fr": "Villanueva de la Reina"
            },
            {
                "value": 52997,
                "label": "Boecillo",
                "label_ar": "Boecillo",
                "label_fr": "Boecillo"
            },
            {
                "value": 53218,
                "label": "Consuegra",
                "label_ar": "كونسويغرا",
                "label_fr": "Consuegra"
            },
            {
                "value": 52499,
                "label": "ses Salines",
                "label_ar": "ses Salines",
                "label_fr": "ses Salines"
            },
            {
                "value": 52284,
                "label": "Molins de Rei",
                "label_ar": "مولين دي ري",
                "label_fr": "Molins de Rei"
            },
            {
                "value": 53355,
                "label": "Pinos del Valle",
                "label_ar": "بينوس ديل فالي",
                "label_fr": "Pinos del Valle"
            },
            {
                "value": 54230,
                "label": "Outes",
                "label_ar": "يفوت",
                "label_fr": "Outes"
            },
            {
                "value": 54419,
                "label": "Soneja",
                "label_ar": "سونيا",
                "label_fr": "Soneja"
            },
            {
                "value": 52916,
                "label": "Nambroca",
                "label_ar": "نامبروكا",
                "label_fr": "Nambroca"
            },
            {
                "value": 52566,
                "label": "Almodovar del Rio",
                "label_ar": "المودوفار ديل ريو",
                "label_fr": "Almodovar del Rio"
            },
            {
                "value": 54041,
                "label": "Orce",
                "label_ar": "أورس",
                "label_fr": "Orce"
            },
            {
                "value": 52802,
                "label": "Navalcan",
                "label_ar": "نافالكان",
                "label_fr": "Navalcan"
            },
            {
                "value": 52808,
                "label": "Montehermoso",
                "label_ar": "مونتيهيرموسو",
                "label_fr": "Montehermoso"
            },
            {
                "value": 53333,
                "label": "Alcazar de San Juan",
                "label_ar": "الكزار دي سان خوان",
                "label_fr": "Alcazar de San Juan"
            },
            {
                "value": 52256,
                "label": "Inca",
                "label_ar": "الإنكا",
                "label_fr": "Inca"
            },
            {
                "value": 52379,
                "label": "Zumaia",
                "label_ar": "زومايا",
                "label_fr": "Zumaia"
            },
            {
                "value": 52876,
                "label": "San Cristobal de Entrevinas",
                "label_ar": "سان كريستوبال دي إنتريفيناس",
                "label_fr": "San Cristobal de Entrevinas"
            },
            {
                "value": 52500,
                "label": "Muro",
                "label_ar": "مورو",
                "label_fr": "Muro"
            },
            {
                "value": 52014,
                "label": "Arona",
                "label_ar": "أرونا",
                "label_fr": "Arona"
            },
            {
                "value": 52849,
                "label": "Almenar",
                "label_ar": "المنار",
                "label_fr": "Almenar"
            },
            {
                "value": 52431,
                "label": "Navalperal de Pinares",
                "label_ar": "نافالبيرال دي بيناريس",
                "label_fr": "Navalperal de Pinares"
            },
            {
                "value": 52249,
                "label": "Santa Eulària des Riu",
                "label_ar": "سانتا إيولاريا دي ريو",
                "label_fr": "Santa Eulària des Riu"
            },
            {
                "value": 53384,
                "label": "Esteiro",
                "label_ar": "إستيرو",
                "label_fr": "Esteiro"
            },
            {
                "value": 54384,
                "label": "Negreira",
                "label_ar": "نيجريرا",
                "label_fr": "Negreira"
            },
            {
                "value": 53999,
                "label": "Ciriza",
                "label_ar": "سيريزا",
                "label_fr": "Ciriza"
            },
            {
                "value": 52779,
                "label": "Loja",
                "label_ar": "لوجا",
                "label_fr": "Loja"
            },
            {
                "value": 52364,
                "label": "Punta Umbría",
                "label_ar": "بونتا أومبريا",
                "label_fr": "Punta Umbría"
            },
            {
                "value": 54024,
                "label": "Bejar",
                "label_ar": "بيجار",
                "label_fr": "Bejar"
            },
            {
                "value": 53950,
                "label": "Santa Olalla",
                "label_ar": "سانتا أولالا",
                "label_fr": "Santa Olalla"
            },
            {
                "value": 52247,
                "label": "Castellar del Valles",
                "label_ar": "كاستيلار ديل فاليس",
                "label_fr": "Castellar del Valles"
            },
            {
                "value": 52858,
                "label": "Madrigal de la Vera",
                "label_ar": "مادريجال دي لا فيرا",
                "label_fr": "Madrigal de la Vera"
            },
            {
                "value": 52956,
                "label": "Yeles",
                "label_ar": "ييليس",
                "label_fr": "Yeles"
            },
            {
                "value": 53281,
                "label": "Sufli",
                "label_ar": "سوفلي",
                "label_fr": "Sufli"
            },
            {
                "value": 52533,
                "label": "Chucena",
                "label_ar": "تشوسينا",
                "label_fr": "Chucena"
            },
            {
                "value": 54232,
                "label": "El Palmar de Troya",
                "label_ar": "إلمار دي ترويا",
                "label_fr": "El Palmar de Troya"
            },
            {
                "value": 51993,
                "label": "El Escorial",
                "label_ar": "الاسكوريال",
                "label_fr": "El Escorial"
            },
            {
                "value": 52353,
                "label": "Almagro",
                "label_ar": "الماجرو",
                "label_fr": "Almagro"
            },
            {
                "value": 51960,
                "label": "Los Hueros",
                "label_ar": "لوس هويروس",
                "label_fr": "Los Hueros"
            },
            {
                "value": 52133,
                "label": "Tona",
                "label_ar": "تونا",
                "label_fr": "Tona"
            },
            {
                "value": 52237,
                "label": "Vivero",
                "label_ar": "فيفيرو",
                "label_fr": "Vivero"
            },
            {
                "value": 52046,
                "label": "Manresa",
                "label_ar": "مانريسا",
                "label_fr": "Manresa"
            },
            {
                "value": 52227,
                "label": "A Coruña",
                "label_ar": "لاكورونيا",
                "label_fr": "La Corogne"
            },
            {
                "value": 52296,
                "label": "La Llagosta",
                "label_ar": "لا لاغوستا",
                "label_fr": "La Llagosta"
            },
            {
                "value": 54282,
                "label": "Barbarroja",
                "label_ar": "بارباروجا",
                "label_fr": "Barbarroja"
            },
            {
                "value": 54348,
                "label": "Tahiche",
                "label_ar": "Tahiche",
                "label_fr": "Tahiche"
            },
            {
                "value": 52721,
                "label": "Miranda de Ebro",
                "label_ar": "ميراندا دي إيبرو",
                "label_fr": "Miranda de Ebro"
            },
            {
                "value": 53098,
                "label": "Mojados",
                "label_ar": "مجادوس",
                "label_fr": "Mojados"
            },
            {
                "value": 53615,
                "label": "Aldealsenor",
                "label_ar": "الديلسنور",
                "label_fr": "Aldealsenor"
            },
            {
                "value": 53915,
                "label": "Canete de las Torres",
                "label_ar": "كانيتي دي لاس توريس",
                "label_fr": "Canete de las Torres"
            },
            {
                "value": 52202,
                "label": "Ourense",
                "label_ar": "أورينس",
                "label_fr": "Ourense"
            },
            {
                "value": 52314,
                "label": "La Llacuna",
                "label_ar": "لا لاكونا",
                "label_fr": "La Llacuna"
            },
            {
                "value": 53060,
                "label": "Benahavis",
                "label_ar": "بيناهافيس",
                "label_fr": "Benahavis"
            },
            {
                "value": 54485,
                "label": "Gargallo",
                "label_ar": "جارجالو",
                "label_fr": "Gargallo"
            },
            {
                "value": 54261,
                "label": "San Ildefonso",
                "label_ar": "سان إلديفونسو",
                "label_fr": "San Ildefonso"
            },
            {
                "value": 53669,
                "label": "Henares",
                "label_ar": "هيناريس",
                "label_fr": "Henares"
            },
            {
                "value": 52497,
                "label": "Santa Maria del Cami",
                "label_ar": "سانتا ماريا ديل كامي",
                "label_fr": "Santa Maria del Cami"
            },
            {
                "value": 53682,
                "label": "Benavarri / Benabarre",
                "label_ar": "بينافاري / بيناباري",
                "label_fr": "Benavarri / Benabarre"
            },
            {
                "value": 53744,
                "label": "Agullana",
                "label_ar": "أغولانا",
                "label_fr": "Agullana"
            },
            {
                "value": 52210,
                "label": "Vigo",
                "label_ar": "فيجو",
                "label_fr": "Vigo"
            },
            {
                "value": 54333,
                "label": "Hernialde",
                "label_ar": "هيرنيالد",
                "label_fr": "Hernialde"
            },
            {
                "value": 52344,
                "label": "Coin",
                "label_ar": "عملة",
                "label_fr": "Pièce de monnaie"
            },
            {
                "value": 52488,
                "label": "Santiago de Cartes",
                "label_ar": "سانتياغو دي كارتيس",
                "label_fr": "Santiago de Cartes"
            },
            {
                "value": 53307,
                "label": "Santa Eulalia",
                "label_ar": "سانتا يولاليا",
                "label_fr": "Santa Eulalia"
            },
            {
                "value": 53738,
                "label": "Piedra",
                "label_ar": "بيدرا",
                "label_fr": "Piedra"
            },
            {
                "value": 52056,
                "label": "Mazagon",
                "label_ar": "مازاجون",
                "label_fr": "Mazagon"
            },
            {
                "value": 53314,
                "label": "la Nucia",
                "label_ar": "لا نوشيا",
                "label_fr": "la Nucia"
            },
            {
                "value": 52964,
                "label": "Aiguafreda",
                "label_ar": "إيغوافريدا",
                "label_fr": "Aiguafreda"
            },
            {
                "value": 53578,
                "label": "Lucena del Puerto",
                "label_ar": "لوسينا ديل بويرتو",
                "label_fr": "Lucena del Puerto"
            },
            {
                "value": 53709,
                "label": "Cassàde la Selva",
                "label_ar": "كاسادي لا سيلفا",
                "label_fr": "Cassàde la Selva"
            },
            {
                "value": 53704,
                "label": "Losar de la Vera",
                "label_ar": "لوسار دي لا فيرا",
                "label_fr": "Losar de la Vera"
            },
            {
                "value": 54391,
                "label": "Puebla de Don Fadrique",
                "label_ar": "بويبلا دي دون فادريك",
                "label_fr": "Puebla de Don Fadrique"
            },
            {
                "value": 52669,
                "label": "Utebo",
                "label_ar": "يوتيبو",
                "label_fr": "Utebo"
            },
            {
                "value": 52793,
                "label": "Arcicollar",
                "label_ar": "أركيكولار",
                "label_fr": "Arcicollar"
            },
            {
                "value": 52785,
                "label": "Vilanova",
                "label_ar": "فيلانوفا",
                "label_fr": "Vilanova"
            },
            {
                "value": 52626,
                "label": "Viladecans",
                "label_ar": "فيلاديكانس",
                "label_fr": "Viladecans"
            },
            {
                "value": 52420,
                "label": "Pozoblanco",
                "label_ar": "بوزوبلانكو",
                "label_fr": "Pozoblanco"
            },
            {
                "value": 51942,
                "label": "Alcobendas",
                "label_ar": "الكوبينداس",
                "label_fr": "Alcobendas"
            },
            {
                "value": 53425,
                "label": "Uceda",
                "label_ar": "أوسيدا",
                "label_fr": "Uceda"
            },
            {
                "value": 54275,
                "label": "La Raya",
                "label_ar": "لا رايا",
                "label_fr": "La Raya"
            },
            {
                "value": 54136,
                "label": "Rajadell",
                "label_ar": "راجادل",
                "label_fr": "Rajadell"
            },
            {
                "value": 53514,
                "label": "Moriscos",
                "label_ar": "موريسكوس",
                "label_fr": "Moriscos"
            },
            {
                "value": 52998,
                "label": "Huecas",
                "label_ar": "Huecas",
                "label_fr": "Huecas"
            },
            {
                "value": 54424,
                "label": "O Mazo",
                "label_ar": "يا مازو",
                "label_fr": "Ô Mazo"
            },
            {
                "value": 53916,
                "label": "Pueyo de Santa Cruz",
                "label_ar": "بويو دي سانتا كروز",
                "label_fr": "Pueyo de Santa Cruz"
            },
            {
                "value": 54237,
                "label": "El Provencio",
                "label_ar": "البروفينسيو",
                "label_fr": "El Provencio"
            },
            {
                "value": 52483,
                "label": "Maliano",
                "label_ar": "ماليانو",
                "label_fr": "Maliano"
            },
            {
                "value": 53577,
                "label": "San Jorge",
                "label_ar": "سان جورج",
                "label_fr": "San Jorge"
            },
            {
                "value": 54077,
                "label": "Dodro",
                "label_ar": "دودرو",
                "label_fr": "Dodro"
            },
            {
                "value": 54223,
                "label": "Fuente el Fresno",
                "label_ar": "فوينتي إل فريسنو",
                "label_fr": "Fuente el Fresno"
            },
            {
                "value": 53760,
                "label": "L'Albir",
                "label_ar": "البير",
                "label_fr": "L'Albir"
            },
            {
                "value": 52366,
                "label": "Silleda",
                "label_ar": "سيليدا",
                "label_fr": "Silleda"
            },
            {
                "value": 53110,
                "label": "Vinales",
                "label_ar": "فيناليس",
                "label_fr": "Vinales"
            },
            {
                "value": 51935,
                "label": "Majadahonda",
                "label_ar": "ماجاداهوندا",
                "label_fr": "Majadahonda"
            },
            {
                "value": 53684,
                "label": "Agoncillo",
                "label_ar": "Agoncillo",
                "label_fr": "Agoncillo"
            },
            {
                "value": 54306,
                "label": "El Pedernoso",
                "label_ar": "El Pedernoso",
                "label_fr": "El Pedernoso"
            },
            {
                "value": 54337,
                "label": "Calatorao",
                "label_ar": "كالاتوراو",
                "label_fr": "Calatorao"
            },
            {
                "value": 53741,
                "label": "Elgorriaga",
                "label_ar": "Elgorriaga",
                "label_fr": "Elgorriaga"
            },
            {
                "value": 53826,
                "label": "Rodonya",
                "label_ar": "رودونيا",
                "label_fr": "Rodonya"
            },
            {
                "value": 53478,
                "label": "Villarejo",
                "label_ar": "فيلاريجو",
                "label_fr": "Villarejo"
            },
            {
                "value": 54028,
                "label": "L'Alcudia de Crespins",
                "label_ar": "ألكوديا دي كريسبينز",
                "label_fr": "L'Alcudia de Crespins"
            },
            {
                "value": 52644,
                "label": "Bueu",
                "label_ar": "Bueu",
                "label_fr": "Bueu"
            },
            {
                "value": 54238,
                "label": "Constantina",
                "label_ar": "قسنطينة",
                "label_fr": "Constantina"
            },
            {
                "value": 52403,
                "label": "Adra",
                "label_ar": "عدرا",
                "label_fr": "Adra"
            },
            {
                "value": 52670,
                "label": "Calatayud",
                "label_ar": "قلعة أيوب",
                "label_fr": "Calatayud"
            },
            {
                "value": 54023,
                "label": "Balazote",
                "label_ar": "بالازوت",
                "label_fr": "Balazote"
            },
            {
                "value": 53046,
                "label": "Aravaca",
                "label_ar": "أرافاكا",
                "label_fr": "Aravaca"
            },
            {
                "value": 52996,
                "label": "Montemayor de Pililla",
                "label_ar": "مونتمايور دي بيلا",
                "label_fr": "Montemayor de Pililla"
            },
            {
                "value": 54340,
                "label": "Alfara del Patriarca",
                "label_ar": "ألفارا ديل باترياركا",
                "label_fr": "Alfara del Patriarca"
            },
            {
                "value": 54118,
                "label": "La Playa de Arguineguin",
                "label_ar": "لا بلايا دي أرغوينجوين",
                "label_fr": "La Playa de Arguineguin"
            },
            {
                "value": 52005,
                "label": "Rubí",
                "label_ar": "روبي",
                "label_fr": "Rubí"
            },
            {
                "value": 52235,
                "label": "Villamuriel de Cerrato",
                "label_ar": "فيلاموريل دي سيراتو",
                "label_fr": "Villamuriel de Cerrato"
            },
            {
                "value": 52426,
                "label": "Castilblanco de los Arroyos",
                "label_ar": "Castilblanco de los Arroyos",
                "label_fr": "Castilblanco de los Arroyos"
            },
            {
                "value": 53464,
                "label": "Olmedo",
                "label_ar": "أولميدو",
                "label_fr": "Olmedo"
            },
            {
                "value": 52299,
                "label": "Tiana",
                "label_ar": "تيانا",
                "label_fr": "Tiana"
            },
            {
                "value": 54383,
                "label": "Saamasas",
                "label_ar": "Saamasas",
                "label_fr": "Saamasas"
            },
            {
                "value": 53776,
                "label": "Olocau",
                "label_ar": "أولوكاو",
                "label_fr": "Olocau"
            },
            {
                "value": 53919,
                "label": "Anglès",
                "label_ar": "أنجليس",
                "label_fr": "Anglès"
            },
            {
                "value": 53163,
                "label": "Gelves",
                "label_ar": "جلفز",
                "label_fr": "Gelves"
            },
            {
                "value": 52453,
                "label": "Algemesi",
                "label_ar": "الجيميسي",
                "label_fr": "Algemesi"
            },
            {
                "value": 52356,
                "label": "Benicassim",
                "label_ar": "بن قاسم",
                "label_fr": "Benicassim"
            },
            {
                "value": 52204,
                "label": "Torre-Pacheco",
                "label_ar": "توري باتشيكو",
                "label_fr": "Torre-Pacheco"
            },
            {
                "value": 54356,
                "label": "La Cabrera",
                "label_ar": "لا كابريرا",
                "label_fr": "La Cabrera"
            },
            {
                "value": 54180,
                "label": "Castell de Ferro",
                "label_ar": "كاستل دي فيرو",
                "label_fr": "Castell de Ferro"
            },
            {
                "value": 51929,
                "label": "Aranjuez",
                "label_ar": "أرانجيز",
                "label_fr": "Aranjuez"
            },
            {
                "value": 52524,
                "label": "Soller",
                "label_ar": "سولير",
                "label_fr": "Soller"
            },
            {
                "value": 54123,
                "label": "La Rambla",
                "label_ar": "لا رامبلا",
                "label_fr": "La Rambla"
            },
            {
                "value": 53295,
                "label": "Alcover",
                "label_ar": "الكوفر",
                "label_fr": "Alcover"
            },
            {
                "value": 53594,
                "label": "Monzalbarba",
                "label_ar": "مونزالباربا",
                "label_fr": "Monzalbarba"
            },
            {
                "value": 52795,
                "label": "El Viso de San Juan",
                "label_ar": "El Viso de San Juan",
                "label_fr": "El Viso de San Juan"
            },
            {
                "value": 54467,
                "label": "Argelaguer",
                "label_ar": "Argelaguer",
                "label_fr": "Argelaguer"
            },
            {
                "value": 53382,
                "label": "Zaratamo",
                "label_ar": "زاراتامو",
                "label_fr": "Zaratamo"
            },
            {
                "value": 53831,
                "label": "La Espina",
                "label_ar": "لا اسبينا",
                "label_fr": "La Espina"
            },
            {
                "value": 52839,
                "label": "Cabana",
                "label_ar": "كابانا",
                "label_fr": "Cabane"
            },
            {
                "value": 52945,
                "label": "Collado Mediano",
                "label_ar": "كولادو ميديانو",
                "label_fr": "Collado Mediano"
            },
            {
                "value": 54020,
                "label": "Ontinyent",
                "label_ar": "أونتينينت",
                "label_fr": "Ontinyent"
            },
            {
                "value": 54281,
                "label": "Agost",
                "label_ar": "أغوست",
                "label_fr": "Agost"
            },
            {
                "value": 53930,
                "label": "Santa Cruz de Mudela",
                "label_ar": "سانتا كروز دي موديلا",
                "label_fr": "Santa Cruz de Mudela"
            },
            {
                "value": 52058,
                "label": "Aracena",
                "label_ar": "اراسينا",
                "label_fr": "Aracena"
            },
            {
                "value": 52444,
                "label": "El Palmar",
                "label_ar": "البالمار",
                "label_fr": "El Palmar"
            },
            {
                "value": 52697,
                "label": "Alella",
                "label_ar": "أليلا",
                "label_fr": "Alella"
            },
            {
                "value": 52486,
                "label": "Guarnizo",
                "label_ar": "جوارنيزو",
                "label_fr": "Guarnizo"
            },
            {
                "value": 52547,
                "label": "San Fernando",
                "label_ar": "سان فرناندو",
                "label_fr": "San Fernando"
            },
            {
                "value": 52949,
                "label": "La Torre",
                "label_ar": "لا توري",
                "label_fr": "La Torre"
            },
            {
                "value": 54109,
                "label": "Bordils",
                "label_ar": "بوردلس",
                "label_fr": "Bordils"
            },
            {
                "value": 51915,
                "label": "el Prat de Llobregat",
                "label_ar": "El Prat de Llobregat",
                "label_fr": "el Prat de Llobregat"
            },
            {
                "value": 52128,
                "label": "La Alameda de la Sagra",
                "label_ar": "لا ألاميدا دي لا ساجرا",
                "label_fr": "La Alameda de la Sagra"
            },
            {
                "value": 52043,
                "label": "Valles",
                "label_ar": "فاليس",
                "label_fr": "Valles"
            },
            {
                "value": 52960,
                "label": "Lantadilla",
                "label_ar": "لانتاديلا",
                "label_fr": "Lantadilla"
            },
            {
                "value": 52823,
                "label": "Trujillanos",
                "label_ar": "تروجيلانوس",
                "label_fr": "Trujillanos"
            },
            {
                "value": 52871,
                "label": "Pomar de Cinca",
                "label_ar": "بومار دي سينكا",
                "label_fr": "Pomar de Cinca"
            },
            {
                "value": 52973,
                "label": "Gerena",
                "label_ar": "جيرينا",
                "label_fr": "Gerena"
            },
            {
                "value": 52300,
                "label": "Sallent",
                "label_ar": "سايلنت",
                "label_fr": "Sallent"
            },
            {
                "value": 51939,
                "label": "Badalona",
                "label_ar": "بادالونا",
                "label_fr": "Badalona"
            },
            {
                "value": 52751,
                "label": "Medina-Sidonia",
                "label_ar": "المدينة المنورة-سيدونيا",
                "label_fr": "Médina-Sidonia"
            },
            {
                "value": 53038,
                "label": "Lopera",
                "label_ar": "لوبيرا",
                "label_fr": "Lopera"
            },
            {
                "value": 53861,
                "label": "Seva",
                "label_ar": "سيفا",
                "label_fr": "Seva"
            },
            {
                "value": 52137,
                "label": "San Juan",
                "label_ar": "سان خوان",
                "label_fr": "San Juan"
            },
            {
                "value": 52866,
                "label": "Navarres",
                "label_ar": "نافاريس",
                "label_fr": "Navarres"
            },
            {
                "value": 52276,
                "label": "Jabali Nuevo",
                "label_ar": "جبالي نويفو",
                "label_fr": "Jabali Nuevo"
            },
            {
                "value": 52873,
                "label": "Ubrique",
                "label_ar": "أوبريك",
                "label_fr": "Ubrique"
            },
            {
                "value": 54364,
                "label": "Montalban de Cordoba",
                "label_ar": "مونتلبان دي قرطبة",
                "label_fr": "Montalban de Cordoue"
            },
            {
                "value": 53016,
                "label": "Paracuellos",
                "label_ar": "باراكويلوس",
                "label_fr": "Paracuellos"
            },
            {
                "value": 52156,
                "label": "Lugo",
                "label_ar": "لوغو",
                "label_fr": "Lugo"
            },
            {
                "value": 53922,
                "label": "Almadén",
                "label_ar": "المادين",
                "label_fr": "Almadén"
            },
            {
                "value": 51983,
                "label": "Brunete",
                "label_ar": "برونيتي",
                "label_fr": "Brunete"
            },
            {
                "value": 52724,
                "label": "Anchuelo",
                "label_ar": "أنشويلو",
                "label_fr": "Anchuelo"
            },
            {
                "value": 52279,
                "label": "Alcoy",
                "label_ar": "الكوي",
                "label_fr": "Alcoy"
            },
            {
                "value": 52575,
                "label": "Oleiros",
                "label_ar": "أوليروس",
                "label_fr": "Oleiros"
            },
            {
                "value": 53123,
                "label": "Valdecabras",
                "label_ar": "فالديكابراس",
                "label_fr": "Valdecabras"
            },
            {
                "value": 52739,
                "label": "Tordesillas",
                "label_ar": "تورديسيلاس",
                "label_fr": "Tordesillas"
            },
            {
                "value": 53246,
                "label": "Cubas",
                "label_ar": "كوباس",
                "label_fr": "Cubas"
            },
            {
                "value": 53212,
                "label": "Quijorna",
                "label_ar": "كويجورنا",
                "label_fr": "Quijorna"
            },
            {
                "value": 52935,
                "label": "Conil de la Frontera",
                "label_ar": "كونيل دي لا فرونتيرا",
                "label_fr": "Conil de la Frontera"
            },
            {
                "value": 54483,
                "label": "Parada",
                "label_ar": "بارادا",
                "label_fr": "Parada"
            },
            {
                "value": 54175,
                "label": "Garraf",
                "label_ar": "الجراف",
                "label_fr": "Garraf"
            },
            {
                "value": 52578,
                "label": "As Pontes de Garcia Rodriguez",
                "label_ar": "مثل بونتيس دي جارسيا رودريغيز",
                "label_fr": "Comme Pontes de Garcia Rodriguez"
            },
            {
                "value": 52790,
                "label": "Egues-Uharte",
                "label_ar": "Egues-Uharte",
                "label_fr": "Egues-Uharte"
            },
            {
                "value": 53346,
                "label": "La Vinuela",
                "label_ar": "لا فينويلا",
                "label_fr": "La Vinuela"
            },
            {
                "value": 54037,
                "label": "Fuentealbilla",
                "label_ar": "فوينتالبيلا",
                "label_fr": "Fuentealbilla"
            },
            {
                "value": 53559,
                "label": "Torrent",
                "label_ar": "سيل",
                "label_fr": "Torrent"
            },
            {
                "value": 52199,
                "label": "Esquivias",
                "label_ar": "اسكويفياس",
                "label_fr": "Esquivias"
            },
            {
                "value": 53693,
                "label": "Pedralba",
                "label_ar": "بيدرالبا",
                "label_fr": "Pedralba"
            },
            {
                "value": 53162,
                "label": "Purchil",
                "label_ar": "بورشيل",
                "label_fr": "Purchil"
            },
            {
                "value": 52558,
                "label": "Albal",
                "label_ar": "البال",
                "label_fr": "Albal"
            },
            {
                "value": 53076,
                "label": "Sopela",
                "label_ar": "سوبيلا",
                "label_fr": "Sopela"
            },
            {
                "value": 54335,
                "label": "Comillas",
                "label_ar": "كومياس",
                "label_fr": "Comillas"
            },
            {
                "value": 53610,
                "label": "La Seu d'Urgell",
                "label_ar": "لا سيو دورجيل",
                "label_fr": "La Seu d'Urgell"
            },
            {
                "value": 53765,
                "label": "Catral",
                "label_ar": "كاترال",
                "label_fr": "Catral"
            },
            {
                "value": 53769,
                "label": "Ramales de la Victoria",
                "label_ar": "راماليس دي لا فيكتوريا",
                "label_fr": "Ramales de la Victoria"
            },
            {
                "value": 52911,
                "label": "Guía de Isora",
                "label_ar": "Guía de Isora",
                "label_fr": "Guía de Isora"
            },
            {
                "value": 54044,
                "label": "Salinas",
                "label_ar": "ساليناس",
                "label_fr": "Salinas"
            },
            {
                "value": 53427,
                "label": "La Arena",
                "label_ar": "لا ارينا",
                "label_fr": "La Arena"
            },
            {
                "value": 52136,
                "label": "Vic",
                "label_ar": "فيك",
                "label_fr": "Vic"
            },
            {
                "value": 52290,
                "label": "Terrassa",
                "label_ar": "تيراسا",
                "label_fr": "Terrassa"
            },
            {
                "value": 53053,
                "label": "Arjona",
                "label_ar": "أرجونا",
                "label_fr": "Arjona"
            },
            {
                "value": 53870,
                "label": "Les Fonts",
                "label_ar": "ليه الخطوط",
                "label_fr": "Les Fonts"
            },
            {
                "value": 53113,
                "label": "Casla",
                "label_ar": "كاسلا",
                "label_fr": "Casla"
            },
            {
                "value": 53112,
                "label": "Alfaro",
                "label_ar": "ألفارو",
                "label_fr": "Alfaro"
            },
            {
                "value": 53446,
                "label": "La Paz",
                "label_ar": "لاباز",
                "label_fr": "La Paz"
            },
            {
                "value": 53206,
                "label": "Guitiriz",
                "label_ar": "جيتيريز",
                "label_fr": "Guitiriz"
            },
            {
                "value": 52776,
                "label": "San Roque",
                "label_ar": "سان روكي",
                "label_fr": "San Roque"
            },
            {
                "value": 52075,
                "label": "Fuentes de Leon",
                "label_ar": "فوينتيس دي ليون",
                "label_fr": "Fuentes de Leon"
            },
            {
                "value": 52153,
                "label": "La Puebla de Cazalla",
                "label_ar": "لا بويبلا دي كازالا",
                "label_fr": "La Puebla de Cazalla"
            },
            {
                "value": 54018,
                "label": "Valverde",
                "label_ar": "فالفيردي",
                "label_fr": "Valverde"
            },
            {
                "value": 52898,
                "label": "Durcal",
                "label_ar": "دوركال",
                "label_fr": "Durcal"
            },
            {
                "value": 54462,
                "label": "El Cuervo",
                "label_ar": "إل كويرفو",
                "label_fr": "El Cuervo"
            },
            {
                "value": 53830,
                "label": "Matapozuelos",
                "label_ar": "ماتابوزويلوس",
                "label_fr": "Matapozuelos"
            },
            {
                "value": 53802,
                "label": "Rasines",
                "label_ar": "Rasines",
                "label_fr": "Rasines"
            },
            {
                "value": 53296,
                "label": "Deba",
                "label_ar": "ديبا",
                "label_fr": "Deba"
            },
            {
                "value": 52472,
                "label": "Vinalesa",
                "label_ar": "فيناليسا",
                "label_fr": "Vinalesa"
            },
            {
                "value": 53111,
                "label": "Vinaixa",
                "label_ar": "فينيكسا",
                "label_fr": "Vinaixa"
            },
            {
                "value": 54490,
                "label": "Fuensanta",
                "label_ar": "فوينسانتا",
                "label_fr": "Fuensanta"
            },
            {
                "value": 52801,
                "label": "Alhendin",
                "label_ar": "الهندين",
                "label_fr": "Alhendin"
            },
            {
                "value": 53690,
                "label": "Oliva de Merida",
                "label_ar": "أوليفا دي ميريدا",
                "label_fr": "Oliva de Merida"
            },
            {
                "value": 52215,
                "label": "San Fernando de Henares",
                "label_ar": "سان فرناندو دي إيناريس",
                "label_fr": "San Fernando de Henares"
            },
            {
                "value": 51966,
                "label": "Reus",
                "label_ar": "ريوس",
                "label_fr": "Reus"
            },
            {
                "value": 52737,
                "label": "Alguazas",
                "label_ar": "الجواز",
                "label_fr": "Alguazas"
            },
            {
                "value": 52783,
                "label": "Simancas",
                "label_ar": "سيمانكاس",
                "label_fr": "Simancas"
            },
            {
                "value": 53125,
                "label": "Alaejos",
                "label_ar": "الايجوس",
                "label_fr": "Alaejos"
            },
            {
                "value": 53648,
                "label": "Crevillent",
                "label_ar": "كريفيلنت",
                "label_fr": "Crevillent"
            },
            {
                "value": 54027,
                "label": "Montesa",
                "label_ar": "مونتيسا",
                "label_fr": "Montesa"
            },
            {
                "value": 52241,
                "label": "Cardedeu",
                "label_ar": "كارديو",
                "label_fr": "Cardedeu"
            },
            {
                "value": 51911,
                "label": "Sant Cugat del Vallès",
                "label_ar": "سانت كوجات ديل فاليس",
                "label_fr": "Sant Cugat del Vallès"
            },
            {
                "value": 52549,
                "label": "Moncada",
                "label_ar": "مونكادا",
                "label_fr": "Moncada"
            },
            {
                "value": 53095,
                "label": "Celanova",
                "label_ar": "سيلانوفا",
                "label_fr": "Celanova"
            },
            {
                "value": 53072,
                "label": "Aizarnazabal",
                "label_ar": "ايزارنازابال",
                "label_fr": "Aizarnazabal"
            },
            {
                "value": 53457,
                "label": "Bornos",
                "label_ar": "بورنوس",
                "label_fr": "Bornos"
            },
            {
                "value": 53892,
                "label": "Tijarafe",
                "label_ar": "Tijarafe",
                "label_fr": "Tijarafe"
            },
            {
                "value": 53902,
                "label": "Echarri-Aranaz",
                "label_ar": "اشاري ارناز",
                "label_fr": "Echarri-Aranaz"
            },
            {
                "value": 52232,
                "label": "Fene",
                "label_ar": "فين",
                "label_fr": "Fene"
            },
            {
                "value": 53563,
                "label": "Burjassot",
                "label_ar": "برجاسوت",
                "label_fr": "Burjassot"
            },
            {
                "value": 54003,
                "label": "Bustarviejo",
                "label_ar": "بوستارفيجو",
                "label_fr": "Bustarviejo"
            },
            {
                "value": 53145,
                "label": "Villamayor",
                "label_ar": "فيلامايور",
                "label_fr": "Villamayor"
            },
            {
                "value": 51996,
                "label": "Torrejón de Ardoz",
                "label_ar": "توريخون دي أردوز",
                "label_fr": "Torrejón de Ardoz"
            },
            {
                "value": 53288,
                "label": "Olite",
                "label_ar": "اولايت",
                "label_fr": "Olite"
            },
            {
                "value": 52526,
                "label": "Cocentaina",
                "label_ar": "Cocentaina",
                "label_fr": "Cocentaina"
            },
            {
                "value": 53445,
                "label": "Arnedillo",
                "label_ar": "أرنيديلو",
                "label_fr": "Arnedillo"
            },
            {
                "value": 52931,
                "label": "Eneriz",
                "label_ar": "انريز",
                "label_fr": "Eneriz"
            },
            {
                "value": 54444,
                "label": "Domingo Perez",
                "label_ar": "دومينغو بيريز",
                "label_fr": "Domingo Perez"
            },
            {
                "value": 53458,
                "label": "Los Santos de la Humosa",
                "label_ar": "لوس سانتوس دي لا هوموسا",
                "label_fr": "Los Santos de la Humosa"
            },
            {
                "value": 52305,
                "label": "Palmera",
                "label_ar": "بالميرا",
                "label_fr": "Palmera"
            },
            {
                "value": 52660,
                "label": "Santurtzi",
                "label_ar": "سانتورتزي",
                "label_fr": "Santurtzi"
            },
            {
                "value": 53558,
                "label": "La Plana",
                "label_ar": "لا بلانا",
                "label_fr": "La Plana"
            },
            {
                "value": 52750,
                "label": "Argamasilla de Alba",
                "label_ar": "أرغاماسيلا دي ألبا",
                "label_fr": "Argamasilla de Alba"
            },
            {
                "value": 54345,
                "label": "Cetina",
                "label_ar": "سيتينا",
                "label_fr": "Cetina"
            },
            {
                "value": 54170,
                "label": "Botarell",
                "label_ar": "بوتاريل",
                "label_fr": "Botarell"
            },
            {
                "value": 54339,
                "label": "Castuera",
                "label_ar": "كاستويرا",
                "label_fr": "Castuera"
            },
            {
                "value": 54166,
                "label": "Lanaja",
                "label_ar": "لاناجا",
                "label_fr": "Lanaja"
            },
            {
                "value": 52021,
                "label": "Almería",
                "label_ar": "المرية",
                "label_fr": "Almería"
            },
            {
                "value": 52219,
                "label": "La Guancha",
                "label_ar": "لا جوانشا",
                "label_fr": "La Guancha"
            },
            {
                "value": 53800,
                "label": "Sarinena",
                "label_ar": "سارينينا",
                "label_fr": "Sarinena"
            },
            {
                "value": 52304,
                "label": "Port de Sagunt",
                "label_ar": "بورت دي ساجونت",
                "label_fr": "Port de Sagunt"
            },
            {
                "value": 53014,
                "label": "Beniarres",
                "label_ar": "بينياريس",
                "label_fr": "Beniarres"
            },
            {
                "value": 52879,
                "label": "Basauri",
                "label_ar": "باساوري",
                "label_fr": "Basauri"
            },
            {
                "value": 52446,
                "label": "Gallarta",
                "label_ar": "جالارتا",
                "label_fr": "Gallarta"
            },
            {
                "value": 52334,
                "label": "Orihuela",
                "label_ar": "اوريويلا",
                "label_fr": "Orihuela"
            },
            {
                "value": 53243,
                "label": "Guadamur",
                "label_ar": "جوادامور",
                "label_fr": "Guadamur"
            },
            {
                "value": 53794,
                "label": "Bonmatí",
                "label_ar": "بونماتي",
                "label_fr": "Bonmatí"
            },
            {
                "value": 53258,
                "label": "Villares del Saz",
                "label_ar": "فيلاريس ديل ساز",
                "label_fr": "Villares del Saz"
            },
            {
                "value": 53910,
                "label": "Llofriu",
                "label_ar": "لوفريو",
                "label_fr": "Llofriu"
            },
            {
                "value": 51994,
                "label": "Campo Real",
                "label_ar": "كامبو ريال",
                "label_fr": "Campo Real"
            },
            {
                "value": 52676,
                "label": "Arroyo de la Miel",
                "label_ar": "أرويو دي لا ميل",
                "label_fr": "Arroyo de la Miel"
            },
            {
                "value": 52054,
                "label": "Fuengirola",
                "label_ar": "فوينخيرولا",
                "label_fr": "Fuengirola"
            },
            {
                "value": 52398,
                "label": "Mejorada del Campo",
                "label_ar": "ميجورادا ديل كامبو",
                "label_fr": "Mejorada del Campo"
            },
            {
                "value": 53278,
                "label": "Malpica",
                "label_ar": "مالبيكا",
                "label_fr": "Malpica"
            },
            {
                "value": 53803,
                "label": "Almudebar",
                "label_ar": "المودبر",
                "label_fr": "Almudebar"
            },
            {
                "value": 52941,
                "label": "Gilet",
                "label_ar": "جيليه",
                "label_fr": "Gilet"
            },
            {
                "value": 53008,
                "label": "Arcos de la Frontera",
                "label_ar": "اركوس دي لا فرونتيرا",
                "label_fr": "Arcos de la Frontera"
            },
            {
                "value": 53502,
                "label": "Astigarraga",
                "label_ar": "أستيجاراجا",
                "label_fr": "Astigarraga"
            },
            {
                "value": 54152,
                "label": "La Solana",
                "label_ar": "لا سولانا",
                "label_fr": "La Solana"
            },
            {
                "value": 54386,
                "label": "Medio",
                "label_ar": "ميديو",
                "label_fr": "Medio"
            },
            {
                "value": 53889,
                "label": "La Garganta",
                "label_ar": "لا جارجانتا",
                "label_fr": "La Garganta"
            },
            {
                "value": 52035,
                "label": "Tabernas",
                "label_ar": "تابيرناس",
                "label_fr": "Tabernas"
            },
            {
                "value": 52630,
                "label": "Estepona",
                "label_ar": "إستيبونا",
                "label_fr": "Estepona"
            },
            {
                "value": 51938,
                "label": "Las Palmas de Gran Canaria",
                "label_ar": "لاس بالماس دي جران كناريا",
                "label_fr": "Las Palmas de Gran Canaria"
            },
            {
                "value": 52509,
                "label": "Rafelguaraf",
                "label_ar": "رافل جراف",
                "label_fr": "Rafelguaraf"
            },
            {
                "value": 52123,
                "label": "Puebla de Alfinden",
                "label_ar": "بويبلا دي ألفيندين",
                "label_fr": "Puebla de Alfinden"
            },
            {
                "value": 54408,
                "label": "Algodonales",
                "label_ar": "الجودوناليس",
                "label_fr": "Algodonales"
            },
            {
                "value": 54091,
                "label": "Obanos",
                "label_ar": "أوبانوس",
                "label_fr": "Obanos"
            },
            {
                "value": 54019,
                "label": "San Roman",
                "label_ar": "سان رومان",
                "label_fr": "San Roman"
            },
            {
                "value": 53720,
                "label": "Avinyó",
                "label_ar": "أفينيو",
                "label_fr": "Avinyó"
            },
            {
                "value": 52704,
                "label": "Churriana",
                "label_ar": "Churriana",
                "label_fr": "Churriana"
            },
            {
                "value": 52677,
                "label": "Andujar",
                "label_ar": "أندوجار",
                "label_fr": "Andujar"
            },
            {
                "value": 52119,
                "label": "Vilassar de Mar",
                "label_ar": "فيلاسار دي مار",
                "label_fr": "Vilassar de Mar"
            },
            {
                "value": 53203,
                "label": "Camarenilla",
                "label_ar": "كامارينيلا",
                "label_fr": "Camarenilla"
            },
            {
                "value": 53224,
                "label": "Robledillo de Gata",
                "label_ar": "روبليديلو دي جاتا",
                "label_fr": "Robledillo de Gata"
            },
            {
                "value": 54075,
                "label": "La Herradura",
                "label_ar": "لا هيرادورا",
                "label_fr": "La Herradura"
            },
            {
                "value": 53069,
                "label": "Robledillo de la Vera",
                "label_ar": "روبليديلو دي لا فيرا",
                "label_fr": "Robledillo de la Vera"
            },
            {
                "value": 53844,
                "label": "Berantevilla",
                "label_ar": "بيرانتيفيلا",
                "label_fr": "Berantevilla"
            },
            {
                "value": 53807,
                "label": "Begis",
                "label_ar": "بيجيس",
                "label_fr": "Begis"
            },
            {
                "value": 52989,
                "label": "Montilla",
                "label_ar": "مونتيلا",
                "label_fr": "Montilla"
            },
            {
                "value": 53556,
                "label": "Museros",
                "label_ar": "موسيروس",
                "label_fr": "Museros"
            },
            {
                "value": 53681,
                "label": "Los Dolores",
                "label_ar": "لوس دولوريس",
                "label_fr": "Los Dolores"
            },
            {
                "value": 51952,
                "label": "San Sebastián de los Reyes",
                "label_ar": "سان سباستيان دي لوس رييس",
                "label_fr": "San Sebastián de los Reyes"
            },
            {
                "value": 53302,
                "label": "Yunquera de Henares",
                "label_ar": "Yunquera de Henares",
                "label_fr": "Yunquera de Henares"
            },
            {
                "value": 53882,
                "label": "Horcajo de las Torres",
                "label_ar": "هوركاجو دي لاس توريس",
                "label_fr": "Horcajo de las Torres"
            },
            {
                "value": 54000,
                "label": "Gorriti",
                "label_ar": "جوريتي",
                "label_fr": "Gorriti"
            },
            {
                "value": 52325,
                "label": "Tomelloso",
                "label_ar": "توميلوسو",
                "label_fr": "Tomelloso"
            },
            {
                "value": 53624,
                "label": "Tombrio de Arriba",
                "label_ar": "تومبريو دي أريبا",
                "label_fr": "Tombrio de Arriba"
            },
            {
                "value": 52969,
                "label": "Zubia",
                "label_ar": "الزوبيا",
                "label_fr": "Zubia"
            },
            {
                "value": 54089,
                "label": "Villaviciosa",
                "label_ar": "فيلافيسيوزا",
                "label_fr": "Villaviciosa"
            },
            {
                "value": 53537,
                "label": "Yaiza",
                "label_ar": "Yaiza",
                "label_fr": "Yaiza"
            },
            {
                "value": 53255,
                "label": "Huete",
                "label_ar": "هويت",
                "label_fr": "Huete"
            },
            {
                "value": 52485,
                "label": "Santona",
                "label_ar": "سانتونا",
                "label_fr": "Santona"
            },
            {
                "value": 53740,
                "label": "Encinas Reales",
                "label_ar": "إنسيناس رياليس",
                "label_fr": "Encinas Reales"
            },
            {
                "value": 52700,
                "label": "Mairena del Alcor",
                "label_ar": "ميرينا ديل الكور",
                "label_fr": "Mairena del Alcor"
            },
            {
                "value": 54067,
                "label": "Linyola",
                "label_ar": "لينيولا",
                "label_fr": "Linyola"
            },
            {
                "value": 52582,
                "label": "Carballo",
                "label_ar": "كاربالو",
                "label_fr": "Carballo"
            },
            {
                "value": 54009,
                "label": "Forua",
                "label_ar": "Forua",
                "label_fr": "Forua"
            },
            {
                "value": 53811,
                "label": "Ferreira",
                "label_ar": "فيريرا",
                "label_fr": "Ferreira"
            },
            {
                "value": 53462,
                "label": "Roales",
                "label_ar": "رواليس",
                "label_fr": "Roales"
            },
            {
                "value": 53899,
                "label": "Ortiguero",
                "label_ar": "أورتيجويرو",
                "label_fr": "Ortiguero"
            },
            {
                "value": 54185,
                "label": "Ponts",
                "label_ar": "بونتس",
                "label_fr": "Ponts"
            },
            {
                "value": 53087,
                "label": "Camarinas",
                "label_ar": "كاماريناس",
                "label_fr": "Camarinas"
            },
            {
                "value": 54250,
                "label": "Banugues",
                "label_ar": "بانوج",
                "label_fr": "Banugues"
            },
            {
                "value": 53117,
                "label": "l'Estartit",
                "label_ar": "l'Estartit",
                "label_fr": "l'Estartit"
            },
            {
                "value": 52837,
                "label": "Lepe",
                "label_ar": "ليبي",
                "label_fr": "Lepe"
            },
            {
                "value": 54374,
                "label": "Isla Becoa",
                "label_ar": "إيسلا بيكوا",
                "label_fr": "Isla Becoa"
            },
            {
                "value": 54368,
                "label": "Pozoamargo",
                "label_ar": "بوزوامارجو",
                "label_fr": "Pozoamargo"
            },
            {
                "value": 54159,
                "label": "Calonge",
                "label_ar": "كالونج",
                "label_fr": "Calonge"
            },
            {
                "value": 52343,
                "label": "Benalmádena",
                "label_ar": "Benalmádena",
                "label_fr": "Benalmádena"
            },
            {
                "value": 54114,
                "label": "Santa Fe de Mondujar",
                "label_ar": "سانتا في دي موندوجار",
                "label_fr": "Santa Fe de Mondujar"
            },
            {
                "value": 54370,
                "label": "Sardina",
                "label_ar": "سردينا",
                "label_fr": "Sardine"
            },
            {
                "value": 51970,
                "label": "Murcia",
                "label_ar": "مرسية",
                "label_fr": "Murcie"
            },
            {
                "value": 52517,
                "label": "Lloret de Mar",
                "label_ar": "يوريت دي مار",
                "label_fr": "Lloret de Mar"
            },
            {
                "value": 52018,
                "label": "Sant Bartomeu del Grau",
                "label_ar": "سانت بارتوميو ديل غراو",
                "label_fr": "Sant Bartomeu del Grau"
            },
            {
                "value": 53538,
                "label": "Ledrada",
                "label_ar": "ليدرادا",
                "label_fr": "Ledrada"
            },
            {
                "value": 53044,
                "label": "Arafo",
                "label_ar": "عرفة",
                "label_fr": "Arafo"
            },
            {
                "value": 53047,
                "label": "Alpedrete",
                "label_ar": "ألبيدريت",
                "label_fr": "Alpedrete"
            },
            {
                "value": 52047,
                "label": "Roda de Bara",
                "label_ar": "روضة دي بارا",
                "label_fr": "Roda de Bara"
            },
            {
                "value": 52531,
                "label": "Burriana",
                "label_ar": "بوريانا",
                "label_fr": "Burriana"
            },
            {
                "value": 52180,
                "label": "Tolosa",
                "label_ar": "تولوسا",
                "label_fr": "Tolosa"
            },
            {
                "value": 52605,
                "label": "Villafranca del Campo",
                "label_ar": "فيلافرانكا ديل كامبو",
                "label_fr": "Villafranca del Campo"
            },
            {
                "value": 51956,
                "label": "San Martin de la Vega",
                "label_ar": "سان مارتن دي لا فيجا",
                "label_fr": "San Martin de la Vega"
            },
            {
                "value": 54397,
                "label": "Hijar",
                "label_ar": "حجار",
                "label_fr": "Hijar"
            },
            {
                "value": 52161,
                "label": "Onda",
                "label_ar": "اوندا",
                "label_fr": "Onda"
            },
            {
                "value": 53438,
                "label": "Lucillos",
                "label_ar": "لوسيلوس",
                "label_fr": "Lucillos"
            },
            {
                "value": 53421,
                "label": "Valderrobres",
                "label_ar": "فالديروبرس",
                "label_fr": "Valderrobres"
            },
            {
                "value": 54193,
                "label": "Villabalter",
                "label_ar": "فيلابالتر",
                "label_fr": "Villabalter"
            },
            {
                "value": 53711,
                "label": "Valdeaveruelo",
                "label_ar": "فالديفريويلو",
                "label_fr": "Valdeaveruelo"
            },
            {
                "value": 52729,
                "label": "Ateca",
                "label_ar": "أتيكا",
                "label_fr": "Ateca"
            },
            {
                "value": 52269,
                "label": "Benisano",
                "label_ar": "بينيسانو",
                "label_fr": "Benisano"
            },
            {
                "value": 52295,
                "label": "Margarida",
                "label_ar": "مارجريدا",
                "label_fr": "Margarida"
            },
            {
                "value": 52988,
                "label": "Fernán Núñez",
                "label_ar": "فرنان نونيز",
                "label_fr": "Fernán Núñez"
            },
            {
                "value": 52636,
                "label": "Sant Feliu de Guíxols",
                "label_ar": "Sant Feliu de Guíxols",
                "label_fr": "Sant Feliu de Guíxols"
            },
            {
                "value": 54241,
                "label": "Altura",
                "label_ar": "ألتورا",
                "label_fr": "Altura"
            },
            {
                "value": 54433,
                "label": "Celeiro",
                "label_ar": "سيلييرو",
                "label_fr": "Celeiro"
            },
            {
                "value": 52868,
                "label": "Lasarte",
                "label_ar": "لاسارتي",
                "label_fr": "Lasarte"
            },
            {
                "value": 54456,
                "label": "Los Corrales",
                "label_ar": "لوس كوراليس",
                "label_fr": "Los Corrales"
            },
            {
                "value": 53156,
                "label": "Villarta de San Juan",
                "label_ar": "فيلارتا دي سان خوان",
                "label_fr": "Villarta de San Juan"
            },
            {
                "value": 52705,
                "label": "San Agustin de Guadalix",
                "label_ar": "سان أوجستين دي جواداليكس",
                "label_fr": "San Agustin de Guadalix"
            },
            {
                "value": 52012,
                "label": "Candelaria",
                "label_ar": "كانديلاريا",
                "label_fr": "Candelaria"
            },
            {
                "value": 53535,
                "label": "Cuntis",
                "label_ar": "كونتيس",
                "label_fr": "Chatte"
            },
            {
                "value": 52484,
                "label": "Colindres",
                "label_ar": "كوليندرز",
                "label_fr": "Colindres"
            },
            {
                "value": 52800,
                "label": "Aldeatejada",
                "label_ar": "الدياتيجادا",
                "label_fr": "Aldeatejada"
            },
            {
                "value": 52367,
                "label": "Almoster",
                "label_ar": "تقريبا",
                "label_fr": "Almoster"
            },
            {
                "value": 54131,
                "label": "Aceuchal",
                "label_ar": "آسيوتشال",
                "label_fr": "Aceuchal"
            },
            {
                "value": 53897,
                "label": "Oruna",
                "label_ar": "أورونا",
                "label_fr": "Oruna"
            },
            {
                "value": 51992,
                "label": "Robledo de Chavela",
                "label_ar": "روبليدو دي شافيلا",
                "label_fr": "Robledo de Chavela"
            },
            {
                "value": 52183,
                "label": "Moana",
                "label_ar": "موانا",
                "label_fr": "Moana"
            },
            {
                "value": 53029,
                "label": "Aguilar de Campoo",
                "label_ar": "أغيلار دي كامبو",
                "label_fr": "Aguilar de Campoo"
            },
            {
                "value": 53368,
                "label": "Huetor-Tajar",
                "label_ar": "هويتور تاجار",
                "label_fr": "Huetor-Tajar"
            },
            {
                "value": 53234,
                "label": "L' Espluga de Francoli",
                "label_ar": "L 'Espluga de Francoli",
                "label_fr": "L 'Espluga de Francoli"
            },
            {
                "value": 53810,
                "label": "Porto Cristo",
                "label_ar": "بورتو كريستو",
                "label_fr": "Porto Cristo"
            },
            {
                "value": 54174,
                "label": "Ullastrell",
                "label_ar": "أولستريل",
                "label_fr": "Ullastrell"
            },
            {
                "value": 53343,
                "label": "Cardona",
                "label_ar": "كاردونا",
                "label_fr": "Cardona"
            },
            {
                "value": 52318,
                "label": "Nerva",
                "label_ar": "نيرفا",
                "label_fr": "Nerva"
            },
            {
                "value": 52096,
                "label": "Canovelles",
                "label_ar": "كانوفيل",
                "label_fr": "Canovelles"
            },
            {
                "value": 53260,
                "label": "Alosno",
                "label_ar": "ألوسنو",
                "label_fr": "Alosno"
            },
            {
                "value": 53448,
                "label": "El Tablero",
                "label_ar": "El Tablero",
                "label_fr": "El Tablero"
            },
            {
                "value": 53657,
                "label": "Puigpelat",
                "label_ar": "بويغبلات",
                "label_fr": "Puigpelat"
            },
            {
                "value": 54382,
                "label": "Torroella de Fluvia",
                "label_ar": "Torroella de Fluvia",
                "label_fr": "Torroella de Fluvia"
            },
            {
                "value": 54229,
                "label": "Gordon",
                "label_ar": "جوردون",
                "label_fr": "Gordon"
            },
            {
                "value": 54189,
                "label": "Miono",
                "label_ar": "ميونو",
                "label_fr": "Miono"
            },
            {
                "value": 53606,
                "label": "Catoira",
                "label_ar": "كاتويرا",
                "label_fr": "Catoira"
            },
            {
                "value": 52457,
                "label": "Vilches",
                "label_ar": "فيلتشيس",
                "label_fr": "Vilches"
            },
            {
                "value": 53955,
                "label": "Carcabuey",
                "label_ar": "كاركابوي",
                "label_fr": "Carcabuey"
            },
            {
                "value": 53085,
                "label": "Porto",
                "label_ar": "بورتو",
                "label_fr": "Porto"
            },
            {
                "value": 52914,
                "label": "Ataun",
                "label_ar": "أتون",
                "label_fr": "Ataun"
            },
            {
                "value": 52836,
                "label": "Masquefa",
                "label_ar": "مسكيفة",
                "label_fr": "Masquefa"
            },
            {
                "value": 53322,
                "label": "Lebrija",
                "label_ar": "ليبريجا",
                "label_fr": "Lebrija"
            },
            {
                "value": 54434,
                "label": "Boceguillas",
                "label_ar": "Boceguillas",
                "label_fr": "Boceguillas"
            },
            {
                "value": 53192,
                "label": "Navalmoral de la Mata",
                "label_ar": "نافالمورال دي لا ماتا",
                "label_fr": "Navalmoral de la Mata"
            },
            {
                "value": 53951,
                "label": "Pezuela de las Torres",
                "label_ar": "بيزويلا دي لاس توريس",
                "label_fr": "Pezuela de las Torres"
            },
            {
                "value": 52138,
                "label": "Mojacar Pueblo",
                "label_ar": "موجاكار بويبلو",
                "label_fr": "Mojacar Pueblo"
            },
            {
                "value": 53282,
                "label": "Casas Viejas",
                "label_ar": "كاساس فيجاس",
                "label_fr": "Casas Viejas"
            },
            {
                "value": 52449,
                "label": "El Papiol",
                "label_ar": "El Papiol",
                "label_fr": "El Papiol"
            },
            {
                "value": 53184,
                "label": "La Oliva",
                "label_ar": "لا أوليفا",
                "label_fr": "La Oliva"
            },
            {
                "value": 52506,
                "label": "Chiclana de la Frontera",
                "label_ar": "شيكلانا دي لا فرونتيرا",
                "label_fr": "Chiclana de la Frontera"
            },
            {
                "value": 53012,
                "label": "Alava",
                "label_ar": "ألافا",
                "label_fr": "Alava"
            },
            {
                "value": 53173,
                "label": "Salobre",
                "label_ar": "سالوبري",
                "label_fr": "Salobre"
            },
            {
                "value": 53440,
                "label": "La Antilla",
                "label_ar": "لا أنتيلا",
                "label_fr": "La Antilla"
            },
            {
                "value": 53963,
                "label": "Torralba de Calatrava",
                "label_ar": "Torralba de Calatrava",
                "label_fr": "Torralba de Calatrava"
            },
            {
                "value": 54323,
                "label": "Santiponce",
                "label_ar": "سانتيبونسي",
                "label_fr": "Santiponce"
            },
            {
                "value": 53500,
                "label": "Monesterio",
                "label_ar": "مونيستيريو",
                "label_fr": "Monesterio"
            },
            {
                "value": 53028,
                "label": "Toral de los Vados",
                "label_ar": "تورال دي لوس فادوس",
                "label_fr": "Toral de los Vados"
            },
            {
                "value": 52053,
                "label": "Málaga",
                "label_ar": "مالقة",
                "label_fr": "Malaga"
            },
            {
                "value": 51995,
                "label": "Nuevo Baztan",
                "label_ar": "نويفو بازتان",
                "label_fr": "Nuevo Baztan"
            },
            {
                "value": 52089,
                "label": "Rincon de la Victoria",
                "label_ar": "رينكون دي لا فيكتوريا",
                "label_fr": "Rincon de la Victoria"
            },
            {
                "value": 52124,
                "label": "Loeches",
                "label_ar": "Loeches",
                "label_fr": "Loeches"
            },
            {
                "value": 52222,
                "label": "Atarrabia",
                "label_ar": "أتارابيا",
                "label_fr": "Atarrabia"
            },
            {
                "value": 54134,
                "label": "Venta de Banos",
                "label_ar": "فينتا دي بانوس",
                "label_fr": "Venta de Banos"
            },
            {
                "value": 53405,
                "label": "Castelseras",
                "label_ar": "كاستيلسيراس",
                "label_fr": "Castelseras"
            },
            {
                "value": 53465,
                "label": "Guadalcazar",
                "label_ar": "Guadalcazar",
                "label_fr": "Guadalcazar"
            },
            {
                "value": 54309,
                "label": "Rus",
                "label_ar": "روس",
                "label_fr": "Rus"
            },
            {
                "value": 52854,
                "label": "Agullent",
                "label_ar": "Agullent",
                "label_fr": "Agullent"
            },
            {
                "value": 53273,
                "label": "Villar de Gallimazo",
                "label_ar": "فيلار دي جاليمازو",
                "label_fr": "Villar de Gallimazo"
            },
            {
                "value": 53002,
                "label": "Huelves",
                "label_ar": "Huelves",
                "label_fr": "Huelves"
            },
            {
                "value": 53481,
                "label": "Arenas de San Pedro",
                "label_ar": "أريناس دي سان بيدرو",
                "label_fr": "Arènes de San Pedro"
            },
            {
                "value": 52894,
                "label": "Arriate",
                "label_ar": "Arriate",
                "label_fr": "Arriate"
            },
            {
                "value": 54147,
                "label": "El Torno",
                "label_ar": "إل تورنو",
                "label_fr": "El Torno"
            },
            {
                "value": 54008,
                "label": "Caserio El Campello",
                "label_ar": "كاسيريو إل كامبيلو",
                "label_fr": "Caserio El Campello"
            },
            {
                "value": 53371,
                "label": "Monteagudo",
                "label_ar": "مونتيجودو",
                "label_fr": "Monteagudo"
            },
            {
                "value": 52962,
                "label": "Torreaguera",
                "label_ar": "توريغويرا",
                "label_fr": "Torreaguera"
            },
            {
                "value": 52387,
                "label": "Villalar de los Comuneros",
                "label_ar": "Villalar de los Comuneros",
                "label_fr": "Villalar de los Comuneros"
            },
            {
                "value": 52376,
                "label": "Milagro",
                "label_ar": "ميلاجرو",
                "label_fr": "Milagro"
            },
            {
                "value": 52550,
                "label": "L'Eliana",
                "label_ar": "إليانا",
                "label_fr": "L'Eliana"
            },
            {
                "value": 54442,
                "label": "Olivares",
                "label_ar": "اوليفاريس",
                "label_fr": "Olivares"
            },
            {
                "value": 53040,
                "label": "Alcúdia",
                "label_ar": "الكودية",
                "label_fr": "Alcúdia"
            },
            {
                "value": 54097,
                "label": "Bellver de Cerdanya",
                "label_ar": "بيلفر دي سيردانيا",
                "label_fr": "Bellver de Cerdanya"
            },
            {
                "value": 52181,
                "label": "Valladolid",
                "label_ar": "بلد الوليد",
                "label_fr": "Valladolid"
            },
            {
                "value": 53161,
                "label": "Mecina Fondales",
                "label_ar": "ميسينا فونداليس",
                "label_fr": "Mecina Fondales"
            },
            {
                "value": 52144,
                "label": "Villanueva del Arzobispo",
                "label_ar": "فيلانويفا ديل أرزوبيسبو",
                "label_fr": "Villanueva del Arzobispo"
            },
            {
                "value": 53534,
                "label": "Ibarruri",
                "label_ar": "إباروري",
                "label_fr": "Ibarruri"
            },
            {
                "value": 53100,
                "label": "Chipiona",
                "label_ar": "شيبيونا",
                "label_fr": "Chipiona"
            },
            {
                "value": 53677,
                "label": "Ipazter",
                "label_ar": "ابازتر",
                "label_fr": "Ipazter"
            },
            {
                "value": 54184,
                "label": "Capsec",
                "label_ar": "كابسك",
                "label_fr": "Capsec"
            },
            {
                "value": 52441,
                "label": "Jaca",
                "label_ar": "جاكا",
                "label_fr": "Jaca"
            },
            {
                "value": 53186,
                "label": "Daya Vieja",
                "label_ar": "دايا فيجا",
                "label_fr": "Daya Vieja"
            },
            {
                "value": 52409,
                "label": "Maspalomas",
                "label_ar": "ماسبالوماس",
                "label_fr": "Maspalomas"
            },
            {
                "value": 53495,
                "label": "Useras",
                "label_ar": "مستخدمين",
                "label_fr": "Useras"
            },
            {
                "value": 53987,
                "label": "Illueca",
                "label_ar": "إيلويكا",
                "label_fr": "Illueca"
            },
            {
                "value": 52574,
                "label": "Fuente del Maestre",
                "label_ar": "فوينتي ديل مايستر",
                "label_fr": "Fuente del Maestre"
            },
            {
                "value": 51984,
                "label": "Cerceda",
                "label_ar": "سيرسيدا",
                "label_fr": "Cerceda"
            },
            {
                "value": 52397,
                "label": "Onil",
                "label_ar": "أونيل",
                "label_fr": "Onil"
            },
            {
                "value": 53311,
                "label": "Flix",
                "label_ar": "فليكس",
                "label_fr": "Flix"
            },
            {
                "value": 53504,
                "label": "Cerro Muriano",
                "label_ar": "سيرو موريانو",
                "label_fr": "Cerro Muriano"
            },
            {
                "value": 53237,
                "label": "Miraflores de la Sierra",
                "label_ar": "ميرافلوريس دي لا سييرا",
                "label_fr": "Miraflores de la Sierra"
            },
            {
                "value": 54347,
                "label": "Senija",
                "label_ar": "سينيجا",
                "label_fr": "Senija"
            },
            {
                "value": 53710,
                "label": "Chiloeches",
                "label_ar": "تشيلوتشيس",
                "label_fr": "Chiloeches"
            },
            {
                "value": 53424,
                "label": "Puerto del Rosario",
                "label_ar": "بويرتو ديل روساريو",
                "label_fr": "Puerto del Rosario"
            },
            {
                "value": 51941,
                "label": "Villamanta",
                "label_ar": "فيلامانتا",
                "label_fr": "Villamanta"
            },
            {
                "value": 52186,
                "label": "Zafra",
                "label_ar": "الظفرة",
                "label_fr": "Zafra"
            },
            {
                "value": 52413,
                "label": "Manacor",
                "label_ar": "ماناكور",
                "label_fr": "Manacor"
            },
            {
                "value": 53582,
                "label": "Fuenmayor",
                "label_ar": "فوينمايور",
                "label_fr": "Fuenmayor"
            },
            {
                "value": 54005,
                "label": "Millares",
                "label_ar": "ميلاريس",
                "label_fr": "Millares"
            },
            {
                "value": 54362,
                "label": "Fregenal de la Sierra",
                "label_ar": "فريجينال دي لا سييرا",
                "label_fr": "Fregenal de la Sierra"
            },
            {
                "value": 52390,
                "label": "Petrel",
                "label_ar": "بترل",
                "label_fr": "Pétrel"
            },
            {
                "value": 52201,
                "label": "La Pobla de Lillet",
                "label_ar": "لا بوبلا دي ليليت",
                "label_fr": "La Pobla de Lillet"
            },
            {
                "value": 52307,
                "label": "Bollullos de la Mitacion",
                "label_ar": "Bollullos de la Mitacion",
                "label_fr": "Bollullos de la Mitacion"
            },
            {
                "value": 53178,
                "label": "Abanto",
                "label_ar": "أبانتو",
                "label_fr": "Abanto"
            },
            {
                "value": 54331,
                "label": "Parres",
                "label_ar": "بارس",
                "label_fr": "Parres"
            },
            {
                "value": 53969,
                "label": "Xunqueira de Espadanedo",
                "label_ar": "Xunqueira دي إسبادانيدو",
                "label_fr": "Xunqueira de Espadanedo"
            },
            {
                "value": 52106,
                "label": "Tigaiga",
                "label_ar": "تيجايجا",
                "label_fr": "Tigaiga"
            },
            {
                "value": 54385,
                "label": "Navahermosa",
                "label_ar": "نافهيرموسا",
                "label_fr": "Navahermosa"
            },
            {
                "value": 52789,
                "label": "Arroyo",
                "label_ar": "أرويو",
                "label_fr": "Arroyo"
            },
            {
                "value": 52835,
                "label": "Navarcles",
                "label_ar": "نافاركليس",
                "label_fr": "Navarcles"
            },
            {
                "value": 54299,
                "label": "Camarassa",
                "label_ar": "كاماراسا",
                "label_fr": "Camarassa"
            },
            {
                "value": 53207,
                "label": "Arrigorriaga",
                "label_ar": "Arrigorriaga",
                "label_fr": "Arrigorriaga"
            },
            {
                "value": 52007,
                "label": "Badajoz",
                "label_ar": "بطليوس",
                "label_fr": "Badajoz"
            },
            {
                "value": 52045,
                "label": "Lucena del Cid",
                "label_ar": "لوسينا ديل سيد",
                "label_fr": "Lucena del Cid"
            },
            {
                "value": 53961,
                "label": "Cicero",
                "label_ar": "شيشرون",
                "label_fr": "Cicéron"
            },
            {
                "value": 52942,
                "label": "Cazorla",
                "label_ar": "كازورلا",
                "label_fr": "Cazorla"
            },
            {
                "value": 54344,
                "label": "Navas de Riofrio",
                "label_ar": "نافاس دي ريوفريو",
                "label_fr": "Navas de Riofrio"
            },
            {
                "value": 53982,
                "label": "Molinos de Duero",
                "label_ar": "مولينوس دي دويرو",
                "label_fr": "Molinos de Duero"
            },
            {
                "value": 52257,
                "label": "Pollença",
                "label_ar": "بولينكا",
                "label_fr": "Pollença"
            },
            {
                "value": 52629,
                "label": "Los Navalucillos",
                "label_ar": "لوس نافالوسيلوس",
                "label_fr": "Los Navalucillos"
            },
            {
                "value": 53661,
                "label": "Pantoja",
                "label_ar": "بانتوجا",
                "label_fr": "Pantoja"
            },
            {
                "value": 53818,
                "label": "Cubillos del Sil",
                "label_ar": "كوبيلوس ديل سيل",
                "label_fr": "Cubillos del Sil"
            },
            {
                "value": 53871,
                "label": "Alfara de Algimia",
                "label_ar": "ألفارا دي ألجيميا",
                "label_fr": "Alfara de Algimia"
            },
            {
                "value": 54480,
                "label": "La Gineta",
                "label_ar": "لا جينيتا",
                "label_fr": "La Gineta"
            },
            {
                "value": 54415,
                "label": "El Hoyo de Pinares",
                "label_ar": "El Hoyo de Pinares",
                "label_fr": "El Hoyo de Pinares"
            },
            {
                "value": 52071,
                "label": "Irun",
                "label_ar": "انا اركض",
                "label_fr": "Je cours"
            },
            {
                "value": 51934,
                "label": "Santa Cruz",
                "label_ar": "سانتا كروز",
                "label_fr": "Santa Cruz"
            },
            {
                "value": 53219,
                "label": "Ugena",
                "label_ar": "يوجينا",
                "label_fr": "Ugena"
            },
            {
                "value": 53593,
                "label": "Cabanas",
                "label_ar": "كاباناس",
                "label_fr": "Cabanas"
            },
            {
                "value": 53691,
                "label": "Sant Jordi",
                "label_ar": "سانت جوردي",
                "label_fr": "Sant Jordi"
            },
            {
                "value": 52617,
                "label": "Cubelles",
                "label_ar": "كوبيليس",
                "label_fr": "Cubelles"
            },
            {
                "value": 52339,
                "label": "Rafelbunyol",
                "label_ar": "رافلبونيول",
                "label_fr": "Rafelbunyol"
            },
            {
                "value": 53587,
                "label": "Fuentes de Ebro",
                "label_ar": "فوينتيس دي إيبرو",
                "label_fr": "Fuentes de Ebro"
            },
            {
                "value": 54394,
                "label": "Alhama de Aragon",
                "label_ar": "Alhama de Aragon",
                "label_fr": "Alhama de Aragon"
            },
            {
                "value": 53853,
                "label": "San Clemente",
                "label_ar": "سان كليمنتي",
                "label_fr": "San Clemente"
            },
            {
                "value": 53265,
                "label": "Avia",
                "label_ar": "أفيا",
                "label_fr": "Avia"
            },
            {
                "value": 54060,
                "label": "Benifato",
                "label_ar": "بنيفاتو",
                "label_fr": "Benifato"
            },
            {
                "value": 54086,
                "label": "Rendar",
                "label_ar": "رندار",
                "label_fr": "Rendar"
            },
            {
                "value": 52280,
                "label": "Villanueva de Castellon",
                "label_ar": "فيلانويفا دي كاستيلون",
                "label_fr": "Villanueva de Castellon"
            },
            {
                "value": 53453,
                "label": "Mollina",
                "label_ar": "مولينا",
                "label_fr": "Mollina"
            },
            {
                "value": 52541,
                "label": "Usurbil",
                "label_ar": "أوسوربيل",
                "label_fr": "Usurbil"
            },
            {
                "value": 52404,
                "label": "Somo",
                "label_ar": "سومو",
                "label_fr": "Somo"
            },
            {
                "value": 53330,
                "label": "Santa Brigida",
                "label_ar": "سانتا بريجيدا",
                "label_fr": "Santa Brigida"
            },
            {
                "value": 54165,
                "label": "Colinas de Trasmonte",
                "label_ar": "كوليناس دي تراسمونت",
                "label_fr": "Colinas de Trasmonte"
            },
            {
                "value": 53395,
                "label": "Rosell",
                "label_ar": "روسيل",
                "label_fr": "Rosell"
            },
            {
                "value": 52094,
                "label": "Taradell",
                "label_ar": "تاراديل",
                "label_fr": "Taradell"
            },
            {
                "value": 54276,
                "label": "Montroy",
                "label_ar": "مونتروي",
                "label_fr": "Montroy"
            },
            {
                "value": 53402,
                "label": "Villamayor",
                "label_ar": "فيلامايور",
                "label_fr": "Villamayor"
            },
            {
                "value": 53833,
                "label": "Broto",
                "label_ar": "بروتو",
                "label_fr": "Broto"
            },
            {
                "value": 52693,
                "label": "Figaro",
                "label_ar": "فيجارو",
                "label_fr": "Figaro"
            },
            {
                "value": 52637,
                "label": "Celra",
                "label_ar": "سيلرا",
                "label_fr": "Celra"
            },
            {
                "value": 52236,
                "label": "Viveiro",
                "label_ar": "فيفييرو",
                "label_fr": "Viveiro"
            },
            {
                "value": 53324,
                "label": "Los Yebenes",
                "label_ar": "لوس يبينيس",
                "label_fr": "Los Yebenes"
            },
            {
                "value": 53284,
                "label": "Galdakao",
                "label_ar": "جالداكاو",
                "label_fr": "Galdakao"
            },
            {
                "value": 52025,
                "label": "El Ejido",
                "label_ar": "El Ejido",
                "label_fr": "El Ejido"
            },
            {
                "value": 54192,
                "label": "Titulcia",
                "label_ar": "تيتولسيا",
                "label_fr": "Titulcia"
            },
            {
                "value": 54169,
                "label": "Moncofar",
                "label_ar": "Moncofar",
                "label_fr": "Moncofar"
            },
            {
                "value": 53277,
                "label": "Santa Marta de Tormes",
                "label_ar": "سانتا مارتا دي تورميس",
                "label_fr": "Santa Marta de Tormes"
            },
            {
                "value": 53417,
                "label": "Mezalocha",
                "label_ar": "ميزالوتشا",
                "label_fr": "Mezalocha"
            },
            {
                "value": 54349,
                "label": "San Fernando",
                "label_ar": "سان فرناندو",
                "label_fr": "San Fernando"
            },
            {
                "value": 54290,
                "label": "San Vicente",
                "label_ar": "سان فيسينتي",
                "label_fr": "San Vicente"
            },
            {
                "value": 52302,
                "label": "Santa Margarida",
                "label_ar": "سانتا مارجريدا",
                "label_fr": "Santa Margarida"
            },
            {
                "value": 52065,
                "label": "Linares",
                "label_ar": "ليناريس",
                "label_fr": "Linares"
            },
            {
                "value": 53929,
                "label": "Villamanrique",
                "label_ar": "فيلامانريك",
                "label_fr": "Villamanrique"
            },
            {
                "value": 52355,
                "label": "Villaverde del Rio",
                "label_ar": "فيلافيردي ديل ريو",
                "label_fr": "Villaverde del Rio"
            },
            {
                "value": 52030,
                "label": "Aguadulce",
                "label_ar": "اجوادولس",
                "label_fr": "Aguadulce"
            },
            {
                "value": 53279,
                "label": "La Calzada de Oropesa",
                "label_ar": "لا كالزادا دي أوروبيسا",
                "label_fr": "La Calzada de Oropesa"
            },
            {
                "value": 53529,
                "label": "Ouces",
                "label_ar": "أواسيس",
                "label_fr": "Ouces"
            },
            {
                "value": 52613,
                "label": "Tordera",
                "label_ar": "تورديرا",
                "label_fr": "Tordera"
            },
            {
                "value": 53242,
                "label": "Cabanas de la Sagra",
                "label_ar": "كاباناس دي لا ساجرا",
                "label_fr": "Cabanas de la Sagra"
            },
            {
                "value": 52070,
                "label": "Oria",
                "label_ar": "أوريا",
                "label_fr": "Oria"
            },
            {
                "value": 54135,
                "label": "Orusco",
                "label_ar": "أوروسكو",
                "label_fr": "Orusco"
            },
            {
                "value": 52791,
                "label": "Castejon",
                "label_ar": "كاستيجون",
                "label_fr": "Castejon"
            },
            {
                "value": 54126,
                "label": "El Puerto",
                "label_ar": "إل بويرتو",
                "label_fr": "El Puerto"
            },
            {
                "value": 53488,
                "label": "Albujon",
                "label_ar": "البوجون",
                "label_fr": "Albujon"
            },
            {
                "value": 52101,
                "label": "Santa Úrsula",
                "label_ar": "سانتا أورسولا",
                "label_fr": "Santa Úrsula"
            },
            {
                "value": 52784,
                "label": "Ajofrin",
                "label_ar": "اجوفرين",
                "label_fr": "Ajofrin"
            },
            {
                "value": 53010,
                "label": "La Abadilla",
                "label_ar": "لا اباديلا",
                "label_fr": "La Abadilla"
            },
            {
                "value": 53767,
                "label": "Fresno de la Vega",
                "label_ar": "فريسنو دي لا فيجا",
                "label_fr": "Fresno de la Vega"
            },
            {
                "value": 54096,
                "label": "Serrateix",
                "label_ar": "سيراتيكس",
                "label_fr": "Serrateix"
            },
            {
                "value": 54458,
                "label": "Epila",
                "label_ar": "ابيلا",
                "label_fr": "Epila"
            },
            {
                "value": 54430,
                "label": "Cardenal",
                "label_ar": "كاردنال",
                "label_fr": "Cardenal"
            },
            {
                "value": 52747,
                "label": "Mugardos",
                "label_ar": "موغاردوس",
                "label_fr": "Mugardos"
            },
            {
                "value": 54214,
                "label": "Santo Domingo",
                "label_ar": "سانتو دومينغو",
                "label_fr": "Saint-Domingue"
            },
            {
                "value": 53893,
                "label": "Amavida",
                "label_ar": "أمافيدا",
                "label_fr": "Amavida"
            },
            {
                "value": 53817,
                "label": "Villanueva de Cordoba",
                "label_ar": "فيلانويفا دي قرطبة",
                "label_fr": "Villanueva de Cordoba"
            },
            {
                "value": 54052,
                "label": "Pedrola",
                "label_ar": "بيدرولا",
                "label_fr": "Pedrola"
            },
            {
                "value": 54274,
                "label": "Redovan",
                "label_ar": "ريدوفان",
                "label_fr": "Redovan"
            },
            {
                "value": 54389,
                "label": "Caspe",
                "label_ar": "كاسبي",
                "label_fr": "Caspe"
            },
            {
                "value": 54488,
                "label": "Huevar",
                "label_ar": "هويفار",
                "label_fr": "Huevar"
            },
            {
                "value": 54409,
                "label": "Mejorada",
                "label_ar": "ميجورادا",
                "label_fr": "Mejorada"
            },
            {
                "value": 52218,
                "label": "Llosa de Ranes",
                "label_ar": "يوسا دي رانيس",
                "label_fr": "Llosa de Ranes"
            },
            {
                "value": 52312,
                "label": "Galvez",
                "label_ar": "جالفيز",
                "label_fr": "Galvez"
            },
            {
                "value": 52169,
                "label": "Orcoyen",
                "label_ar": "أوركوين",
                "label_fr": "Orcoyen"
            },
            {
                "value": 52799,
                "label": "Lomo de Arico",
                "label_ar": "لومو دي أريكو",
                "label_fr": "Lomo de Arico"
            },
            {
                "value": 52467,
                "label": "Cenes de la Vega",
                "label_ar": "سينيس دي لا فيجا",
                "label_fr": "Cenes de la Vega"
            },
            {
                "value": 53467,
                "label": "La Florida",
                "label_ar": "لا فلوريدا",
                "label_fr": "La Florida"
            },
            {
                "value": 53304,
                "label": "Amer",
                "label_ar": "عامر",
                "label_fr": "Amer"
            },
            {
                "value": 52512,
                "label": "Cambados",
                "label_ar": "كامبادوس",
                "label_fr": "Cambados"
            },
            {
                "value": 52539,
                "label": "Erandio",
                "label_ar": "إرانديو",
                "label_fr": "Erandio"
            },
            {
                "value": 52535,
                "label": "Olot",
                "label_ar": "أولوت",
                "label_fr": "Olot"
            },
            {
                "value": 52610,
                "label": "Alanis",
                "label_ar": "ألانيس",
                "label_fr": "Alanis"
            },
            {
                "value": 53823,
                "label": "Agramunt",
                "label_ar": "Agramunt",
                "label_fr": "Agramunt"
            },
            {
                "value": 53730,
                "label": "Moscas del Paramo",
                "label_ar": "موسكاس ديل بارامو",
                "label_fr": "Moscas del Paramo"
            },
            {
                "value": 52915,
                "label": "Alcala de Xivert",
                "label_ar": "الكالا دي Xivert",
                "label_fr": "Alcala de Xivert"
            },
            {
                "value": 53890,
                "label": "Bellcaire d'Emporda",
                "label_ar": "بيلكير ديمبوردا",
                "label_fr": "Bellcaire d'Emporda"
            },
            {
                "value": 52492,
                "label": "Termino",
                "label_ar": "تيرمينو",
                "label_fr": "Termino"
            },
            {
                "value": 53267,
                "label": "Cecenas",
                "label_ar": "سيسيناس",
                "label_fr": "Cecenas"
            },
            {
                "value": 54038,
                "label": "Benijofar",
                "label_ar": "بنيوفار",
                "label_fr": "Benijofar"
            },
            {
                "value": 52719,
                "label": "Armunia",
                "label_ar": "أرمونيا",
                "label_fr": "Armunia"
            },
            {
                "value": 53299,
                "label": "Candeleda",
                "label_ar": "كانديليدا",
                "label_fr": "Candeleda"
            },
            {
                "value": 52494,
                "label": "Renedo",
                "label_ar": "رينيدو",
                "label_fr": "Renedo"
            },
            {
                "value": 52386,
                "label": "Cambre",
                "label_ar": "كامبر",
                "label_fr": "Cambre"
            },
            {
                "value": 52109,
                "label": "Los Cristianos",
                "label_ar": "لوس كريستيانوس",
                "label_fr": "Los Cristianos"
            },
            {
                "value": 52242,
                "label": "Polinya",
                "label_ar": "بولينيا",
                "label_fr": "Polinya"
            },
            {
                "value": 52440,
                "label": "Pina de Ebro",
                "label_ar": "بينا دي إيبرو",
                "label_fr": "Pina de Ebro"
            },
            {
                "value": 54087,
                "label": "Orial",
                "label_ar": "شفوي",
                "label_fr": "Orial"
            },
            {
                "value": 52775,
                "label": "Alumbres",
                "label_ar": "ألومبريس",
                "label_fr": "Alumbres"
            },
            {
                "value": 53436,
                "label": "Villa",
                "label_ar": "فيلا",
                "label_fr": "Villa"
            },
            {
                "value": 53729,
                "label": "Torija",
                "label_ar": "توريجا",
                "label_fr": "Torija"
            },
            {
                "value": 53449,
                "label": "Ojen",
                "label_ar": "أوجين",
                "label_fr": "Ojen"
            },
            {
                "value": 53032,
                "label": "Capileira",
                "label_ar": "كابيليرا",
                "label_fr": "Capileira"
            },
            {
                "value": 51923,
                "label": "Villaviciosa de Odon",
                "label_ar": "فيلافيسيوزا دي أودون",
                "label_fr": "Villaviciosa de Odon"
            },
            {
                "value": 54388,
                "label": "Salobral",
                "label_ar": "سالوبرال",
                "label_fr": "Salobral"
            },
            {
                "value": 54271,
                "label": "Cebreros",
                "label_ar": "سيبريروس",
                "label_fr": "Cebreros"
            },
            {
                "value": 54287,
                "label": "Los Palacios",
                "label_ar": "لوس بالاسيوس",
                "label_fr": "Los Palacios"
            },
            {
                "value": 54257,
                "label": "la Granadella",
                "label_ar": "لا جراناديلا",
                "label_fr": "la Granadella"
            },
            {
                "value": 54224,
                "label": "Benamargosa",
                "label_ar": "بن امارجوسا",
                "label_fr": "Benamargosa"
            },
            {
                "value": 54359,
                "label": "Villanueva del Fresno",
                "label_ar": "فيلانويفا ديل فريسنو",
                "label_fr": "Villanueva del Fresno"
            },
            {
                "value": 52407,
                "label": "Falset",
                "label_ar": "فالسيت",
                "label_fr": "Falset"
            },
            {
                "value": 53757,
                "label": "Vallada",
                "label_ar": "فالادا",
                "label_fr": "Vallada"
            },
            {
                "value": 52004,
                "label": "Las Matas",
                "label_ar": "لاس ماتاس",
                "label_fr": "Las Matas"
            },
            {
                "value": 52593,
                "label": "Gondomar",
                "label_ar": "جوندومار",
                "label_fr": "Gondomar"
            },
            {
                "value": 54454,
                "label": "Caneja",
                "label_ar": "كانيجا",
                "label_fr": "Caneja"
            },
            {
                "value": 53379,
                "label": "Altet",
                "label_ar": "Altet",
                "label_fr": "Altet"
            },
            {
                "value": 54063,
                "label": "La Felguera",
                "label_ar": "لا فيلكويرا",
                "label_fr": "La Felguera"
            },
            {
                "value": 52505,
                "label": "Paradas",
                "label_ar": "بارادا",
                "label_fr": "Paradas"
            },
            {
                "value": 52464,
                "label": "Cabanes",
                "label_ar": "كابانيس",
                "label_fr": "Cabanes"
            },
            {
                "value": 52176,
                "label": "Jijona",
                "label_ar": "جيجونا",
                "label_fr": "Jijona"
            },
            {
                "value": 52380,
                "label": "Muriedas",
                "label_ar": "موريداس",
                "label_fr": "Muriedas"
            },
            {
                "value": 53619,
                "label": "Cidones",
                "label_ar": "سيدونيس",
                "label_fr": "Cidones"
            },
            {
                "value": 53292,
                "label": "Colmenar de Oreja",
                "label_ar": "كولمينار دي أوريجا",
                "label_fr": "Colmenar de Oreja"
            },
            {
                "value": 54186,
                "label": "La Pola de Gordon",
                "label_ar": "لا بولا دي جوردون",
                "label_fr": "La Pola de Gordon"
            },
            {
                "value": 53886,
                "label": "Alfarrasi",
                "label_ar": "الفراسي",
                "label_fr": "Alfarrasi"
            },
            {
                "value": 52856,
                "label": "Matadepera",
                "label_ar": "ماتاديبيرا",
                "label_fr": "Matadepera"
            },
            {
                "value": 53820,
                "label": "Carabana",
                "label_ar": "كارابانا",
                "label_fr": "Carabana"
            },
            {
                "value": 52163,
                "label": "Beniparrell",
                "label_ar": "بينيباريل",
                "label_fr": "Beniparrell"
            },
            {
                "value": 53400,
                "label": "Almendralejo",
                "label_ar": "المندراليخو",
                "label_fr": "Almendralejo"
            },
            {
                "value": 54234,
                "label": "Villanubla",
                "label_ar": "فيلانوبلا",
                "label_fr": "Villanubla"
            },
            {
                "value": 53108,
                "label": "El Espinar",
                "label_ar": "الاسبينار",
                "label_fr": "El Espinar"
            },
            {
                "value": 53065,
                "label": "Villarcayo",
                "label_ar": "فيلاركايو",
                "label_fr": "Villarcayo"
            },
            {
                "value": 52807,
                "label": "Arroyo de la Luz",
                "label_ar": "أرويو دي لا لوز",
                "label_fr": "Arroyo de la Luz"
            },
            {
                "value": 53341,
                "label": "Sotopalacios",
                "label_ar": "سوتوبالاسيوس",
                "label_fr": "Sotopalacios"
            },
            {
                "value": 53977,
                "label": "Boimorto",
                "label_ar": "Boimorto",
                "label_fr": "Boimorto"
            },
            {
                "value": 53944,
                "label": "Cadiar",
                "label_ar": "كاديار",
                "label_fr": "Cadiar"
            },
            {
                "value": 52132,
                "label": "Mahon",
                "label_ar": "ماهون",
                "label_fr": "Mahon"
            },
            {
                "value": 53419,
                "label": "Perillo",
                "label_ar": "بيريلو",
                "label_fr": "Perillo"
            },
            {
                "value": 54012,
                "label": "Villafranqueza",
                "label_ar": "فيلافرانكيزا",
                "label_fr": "Villafranqueza"
            },
            {
                "value": 52057,
                "label": "Los Marines",
                "label_ar": "لوس مارينز",
                "label_fr": "Los Marines"
            },
            {
                "value": 53021,
                "label": "Serranillos del Valle",
                "label_ar": "سيرانيلوس ديل فالي",
                "label_fr": "Serranillos del Valle"
            },
            {
                "value": 54130,
                "label": "Fuentes",
                "label_ar": "فوينتيس",
                "label_fr": "Fuentes"
            },
            {
                "value": 52838,
                "label": "Aljaraque",
                "label_ar": "الجارك",
                "label_fr": "Aljaraque"
            },
            {
                "value": 53406,
                "label": "La Palma del Condado",
                "label_ar": "لا بالما ديل كوندادو",
                "label_fr": "La Palma del Condado"
            },
            {
                "value": 53641,
                "label": "Carrascal de Barregas",
                "label_ar": "كاراسكال دي باريجاس",
                "label_fr": "Carrascal de Barregas"
            },
            {
                "value": 53492,
                "label": "Cruilles",
                "label_ar": "كرويلز",
                "label_fr": "Cruilles"
            },
            {
                "value": 53734,
                "label": "la Riera de Gaia",
                "label_ar": "لا رييرا دي جايا",
                "label_fr": "la Riera de Gaia"
            },
            {
                "value": 53263,
                "label": "Moya",
                "label_ar": "مويا",
                "label_fr": "Moya"
            },
            {
                "value": 52963,
                "label": "Alquerias",
                "label_ar": "Alquerias",
                "label_fr": "Alquerias"
            },
            {
                "value": 53055,
                "label": "Ciutadella",
                "label_ar": "سيوتاديلا",
                "label_fr": "Ciutadella"
            },
            {
                "value": 54220,
                "label": "Llers",
                "label_ar": "يليرس",
                "label_fr": "Llers"
            },
            {
                "value": 54308,
                "label": "Hondon de las Nieves",
                "label_ar": "هوندون دي لاس نيفيس",
                "label_fr": "Hondon de las Nieves"
            },
            {
                "value": 53466,
                "label": "Maside",
                "label_ar": "ماسايد",
                "label_fr": "Maside"
            },
            {
                "value": 53381,
                "label": "Herencia",
                "label_ar": "هيرينسيا",
                "label_fr": "Herencia"
            },
            {
                "value": 53035,
                "label": "Ribes de Freser",
                "label_ar": "ريبس دي فريزر",
                "label_fr": "Ribes de Freser"
            },
            {
                "value": 52073,
                "label": "Zumarraga",
                "label_ar": "زوماراجا",
                "label_fr": "Zumarraga"
            },
            {
                "value": 54319,
                "label": "El Grao",
                "label_ar": "إل جراو",
                "label_fr": "El Grao"
            },
            {
                "value": 53998,
                "label": "Usansolo",
                "label_ar": "يوسانسولو",
                "label_fr": "Usansolo"
            },
            {
                "value": 54216,
                "label": "Riumors",
                "label_ar": "ريومورس",
                "label_fr": "Riumors"
            },
            {
                "value": 52370,
                "label": "Aliseda",
                "label_ar": "أليسيا",
                "label_fr": "Aliseda"
            },
            {
                "value": 54289,
                "label": "Bellvei",
                "label_ar": "بيلفي",
                "label_fr": "Bellvei"
            },
            {
                "value": 53614,
                "label": "Coria del Río",
                "label_ar": "كوريا ديل ريو",
                "label_fr": "Coria del Río"
            },
            {
                "value": 53608,
                "label": "Allariz",
                "label_ar": "اللاريز",
                "label_fr": "Allariz"
            },
            {
                "value": 53573,
                "label": "La Puebla de Arganzon",
                "label_ar": "لا بويبلا دي أرغانزون",
                "label_fr": "La Puebla de Arganzon"
            },
            {
                "value": 52224,
                "label": "Baranain",
                "label_ar": "بارانين",
                "label_fr": "Baranain"
            },
            {
                "value": 52187,
                "label": "La Red de Valdetuejar",
                "label_ar": "لا ريد دي فالديتوجار",
                "label_fr": "La Red de Valdetuejar"
            },
            {
                "value": 53599,
                "label": "Segura de la Sierra",
                "label_ar": "سيجورا دي لا سييرا",
                "label_fr": "Segura de la Sierra"
            },
            {
                "value": 53731,
                "label": "Corrales",
                "label_ar": "كوراليس",
                "label_fr": "Corrales"
            },
            {
                "value": 52647,
                "label": "Santa Maria",
                "label_ar": "سانتا ماريا",
                "label_fr": "Sainte Marie"
            },
            {
                "value": 52604,
                "label": "Arino",
                "label_ar": "أرينو",
                "label_fr": "Arino"
            },
            {
                "value": 53024,
                "label": "el Poal",
                "label_ar": "البوال",
                "label_fr": "el Poal"
            },
            {
                "value": 54218,
                "label": "Camprodon",
                "label_ar": "كامبرودون",
                "label_fr": "Camprodon"
            },
            {
                "value": 52851,
                "label": "el Palau d'Anglesola",
                "label_ar": "El Palau d'Anglesola",
                "label_fr": "el Palau d'Anglesola"
            },
            {
                "value": 54206,
                "label": "Pozo de Guadalajara",
                "label_ar": "بوزو دي غوادالاخارا",
                "label_fr": "Pozo de Guadalajara"
            },
            {
                "value": 52870,
                "label": "Cea",
                "label_ar": "سي",
                "label_fr": "Céa"
            },
            {
                "value": 52321,
                "label": "Baza",
                "label_ar": "بازا",
                "label_fr": "Baza"
            },
            {
                "value": 52434,
                "label": "Ripollet",
                "label_ar": "ريبوليت",
                "label_fr": "Ripollet"
            },
            {
                "value": 53636,
                "label": "Checa",
                "label_ar": "شيكا",
                "label_fr": "Chéca"
            },
            {
                "value": 54160,
                "label": "Montillana",
                "label_ar": "مونتيلانا",
                "label_fr": "Montillana"
            },
            {
                "value": 54182,
                "label": "Cabeza del Buey",
                "label_ar": "كابيزا ديل بوي",
                "label_fr": "Cabeza del Buey"
            },
            {
                "value": 54062,
                "label": "San Cristobal",
                "label_ar": "سان كريستوبال",
                "label_fr": "San Cristobal"
            },
            {
                "value": 52679,
                "label": "Churra",
                "label_ar": "شورا",
                "label_fr": "Churra"
            },
            {
                "value": 53552,
                "label": "Villaralbo",
                "label_ar": "فيلارالبو",
                "label_fr": "Villaralbo"
            },
            {
                "value": 52002,
                "label": "Batres",
                "label_ar": "باتريس",
                "label_fr": "Batres"
            },
            {
                "value": 52358,
                "label": "Alhaurin de la Torre",
                "label_ar": "Alhaurin de la Torre",
                "label_fr": "Alhaurin de la Torre"
            },
            {
                "value": 52532,
                "label": "Salteras",
                "label_ar": "سالتيراس",
                "label_fr": "Salteras"
            },
            {
                "value": 52530,
                "label": "Herrera",
                "label_ar": "هيريرا",
                "label_fr": "Herrera"
            },
            {
                "value": 52487,
                "label": "Cabezon de la Sal",
                "label_ar": "كابيزون دي لا سال",
                "label_fr": "Cabezon de la Sal"
            },
            {
                "value": 51957,
                "label": "Coslada",
                "label_ar": "كوسلادا",
                "label_fr": "Coslada"
            },
            {
                "value": 51931,
                "label": "Las Rozas de Madrid",
                "label_ar": "لاس روزاس دي مدريد",
                "label_fr": "Las Rozas de Madrid"
            },
            {
                "value": 52129,
                "label": "Creixell",
                "label_ar": "كريكسيل",
                "label_fr": "Creixell"
            },
            {
                "value": 52611,
                "label": "Sanlúcar de Barrameda",
                "label_ar": "سانلوكار دي باراميدا",
                "label_fr": "Sanlúcar de Barrameda"
            },
            {
                "value": 52860,
                "label": "Ávila",
                "label_ar": "أفيلا",
                "label_fr": "Ávila"
            },
            {
                "value": 53901,
                "label": "Sangüesa",
                "label_ar": "Sangüesa",
                "label_fr": "Sangüesa"
            },
            {
                "value": 53949,
                "label": "Capmany",
                "label_ar": "كابماني",
                "label_fr": "Capmany"
            },
            {
                "value": 52415,
                "label": "Ribadavia",
                "label_ar": "ريبادافيا",
                "label_fr": "Ribadavia"
            },
            {
                "value": 53227,
                "label": "Salt",
                "label_ar": "ملح",
                "label_fr": "Sel"
            },
            {
                "value": 54248,
                "label": "Nembro",
                "label_ar": "نمبرو",
                "label_fr": "Nembro"
            },
            {
                "value": 53358,
                "label": "Espirdo",
                "label_ar": "اسبيردو",
                "label_fr": "Espirdo"
            },
            {
                "value": 54273,
                "label": "Dilar",
                "label_ar": "ديلار",
                "label_fr": "Dilar"
            },
            {
                "value": 52209,
                "label": "Acala del Rio",
                "label_ar": "أكالا ديل ريو",
                "label_fr": "Acala del Rio"
            },
            {
                "value": 52885,
                "label": "Toreno",
                "label_ar": "تورينو",
                "label_fr": "Toreno"
            },
            {
                "value": 52037,
                "label": "Sant Joan de Vilatorrada",
                "label_ar": "سانت جوان دي فيلاتورادا",
                "label_fr": "Sant Joan de Vilatorrada"
            },
            {
                "value": 52412,
                "label": "El Puerto de Santa María",
                "label_ar": "إل بويرتو دي سانتا ماريا",
                "label_fr": "El Puerto de Santa María"
            },
            {
                "value": 53435,
                "label": "Zarzalejo",
                "label_ar": "زارزاليخو",
                "label_fr": "Zarzalejo"
            },
            {
                "value": 53849,
                "label": "Rafelcofer",
                "label_ar": "رافلكوفر",
                "label_fr": "Rafelcofer"
            },
            {
                "value": 52164,
                "label": "Almenara",
                "label_ar": "المنارة",
                "label_fr": "Almenara"
            },
            {
                "value": 52498,
                "label": "Felanitx",
                "label_ar": "فيلانيتكس",
                "label_fr": "Felanitx"
            },
            {
                "value": 53865,
                "label": "Alagon",
                "label_ar": "ألاغون",
                "label_fr": "Alagon"
            },
            {
                "value": 54314,
                "label": "Astrabudua",
                "label_ar": "أسترابودوا",
                "label_fr": "Astrabudua"
            },
            {
                "value": 53459,
                "label": "Malpartida de Plasencia",
                "label_ar": "مالبارتيدا دي بلاسينسيا",
                "label_fr": "Malpartida de Plasencia"
            },
            {
                "value": 51982,
                "label": "Monte",
                "label_ar": "مونتي",
                "label_fr": "Monte"
            },
            {
                "value": 52529,
                "label": "Aznalcazar",
                "label_ar": "أزنالكازار",
                "label_fr": "Aznalcazar"
            },
            {
                "value": 54253,
                "label": "Curro",
                "label_ar": "كورو",
                "label_fr": "Curro"
            },
            {
                "value": 52205,
                "label": "San Pedro del Pinatar",
                "label_ar": "سان بيدرو ديل بيناتار",
                "label_fr": "San Pedro del Pinatar"
            },
            {
                "value": 53159,
                "label": "El Burgo de Osma",
                "label_ar": "El Burgo de Osma",
                "label_fr": "El Burgo de Osma"
            },
            {
                "value": 53832,
                "label": "Caso",
                "label_ar": "كاسو",
                "label_fr": "Caso"
            },
            {
                "value": 52623,
                "label": "Almassora",
                "label_ar": "المسورة",
                "label_fr": "Almassora"
            },
            {
                "value": 52754,
                "label": "Monserrat",
                "label_ar": "مونسيرات",
                "label_fr": "Monserrat"
            },
            {
                "value": 53763,
                "label": "Briviesca",
                "label_ar": "بريفيسكا",
                "label_fr": "Briviesca"
            },
            {
                "value": 52603,
                "label": "Garcia",
                "label_ar": "جارسيا",
                "label_fr": "Garcia"
            },
            {
                "value": 53390,
                "label": "Hornachuelos",
                "label_ar": "هورناتشويلوس",
                "label_fr": "Hornachuelos"
            },
            {
                "value": 54119,
                "label": "Salvatierra",
                "label_ar": "سالفاتيرا",
                "label_fr": "Salvatierra"
            },
            {
                "value": 53001,
                "label": "Hinojosa del Duque",
                "label_ar": "هينوخوسا ديل دوكي",
                "label_fr": "Hinojosa del Duque"
            },
            {
                "value": 54215,
                "label": "La Aljorra",
                "label_ar": "لا الجورا",
                "label_fr": "La Aljorra"
            },
            {
                "value": 52078,
                "label": "San Cristóbal de La Laguna",
                "label_ar": "سان كريستوبال دي لا لاغونا",
                "label_fr": "San Cristóbal de La Laguna"
            },
            {
                "value": 53133,
                "label": "Autol",
                "label_ar": "أوتول",
                "label_fr": "Autol"
            },
            {
                "value": 52768,
                "label": "Vilajuiga",
                "label_ar": "فيلاجويجا",
                "label_fr": "Vilajuiga"
            },
            {
                "value": 52320,
                "label": "Hellin",
                "label_ar": "هيلين",
                "label_fr": "Hellin"
            },
            {
                "value": 52048,
                "label": "Piera",
                "label_ar": "بيرا",
                "label_fr": "Piera"
            },
            {
                "value": 54400,
                "label": "Arca",
                "label_ar": "أركا",
                "label_fr": "Arca"
            },
            {
                "value": 54048,
                "label": "Sangonera la Verde",
                "label_ar": "سانغونيرا لا فيردي",
                "label_fr": "Sangonera la Verde"
            },
            {
                "value": 52347,
                "label": "Campos",
                "label_ar": "كامبوس",
                "label_fr": "Campos"
            },
            {
                "value": 53941,
                "label": "Cueto",
                "label_ar": "جديلة ل",
                "label_fr": "Cueto"
            },
            {
                "value": 52673,
                "label": "Armilla",
                "label_ar": "أرميلا",
                "label_fr": "Armilla"
            },
            {
                "value": 53607,
                "label": "Lillo",
                "label_ar": "ليلو",
                "label_fr": "Lillo"
            },
            {
                "value": 53128,
                "label": "Laguardia",
                "label_ar": "لاغوارديا",
                "label_fr": "Laguardia"
            },
            {
                "value": 53874,
                "label": "Castrogonzalo",
                "label_ar": "كاستروجونزالو",
                "label_fr": "Castrogonzalo"
            },
            {
                "value": 54047,
                "label": "Campo",
                "label_ar": "كامبو",
                "label_fr": "Campo"
            },
            {
                "value": 53683,
                "label": "Sixto",
                "label_ar": "سيكستو",
                "label_fr": "Sixto"
            },
            {
                "value": 53433,
                "label": "Yuncler",
                "label_ar": "يونكلر",
                "label_fr": "Yuncler"
            },
            {
                "value": 53542,
                "label": "Malpica",
                "label_ar": "مالبيكا",
                "label_fr": "Malpica"
            },
            {
                "value": 51937,
                "label": "Arucas",
                "label_ar": "أركاس",
                "label_fr": "Arucas"
            },
            {
                "value": 52049,
                "label": "Olesa de Montserrat",
                "label_ar": "أوليسا دي مونتسيرات",
                "label_fr": "Olesa de Montserrat"
            },
            {
                "value": 52766,
                "label": "La Puebla de Montalban",
                "label_ar": "لا بويبلا دي مونتالبان",
                "label_fr": "La Puebla de Montalban"
            },
            {
                "value": 51980,
                "label": "El Alamo",
                "label_ar": "ألامو",
                "label_fr": "El Alamo"
            },
            {
                "value": 53841,
                "label": "Mentrida",
                "label_ar": "منتريدا",
                "label_fr": "Mentrida"
            },
            {
                "value": 51969,
                "label": "Cartagena",
                "label_ar": "قرطاجنة",
                "label_fr": "Carthagène"
            },
            {
                "value": 52770,
                "label": "Cadaqués",
                "label_ar": "كاداكيس",
                "label_fr": "Cadaqués"
            },
            {
                "value": 52079,
                "label": "Santa Cruz de Tenerife",
                "label_ar": "سانتا كروز دي تينيريفي",
                "label_fr": "Santa Cruz de Ténérife"
            },
            {
                "value": 53952,
                "label": "Beranga",
                "label_ar": "بيرانجا",
                "label_fr": "Beranga"
            },
            {
                "value": 53522,
                "label": "Montijo",
                "label_ar": "مونتيجو",
                "label_fr": "Montijo"
            },
            {
                "value": 53778,
                "label": "Tineo",
                "label_ar": "تاينو",
                "label_fr": "Tineo"
            },
            {
                "value": 53933,
                "label": "Zorraquin",
                "label_ar": "زوراكوين",
                "label_fr": "Zorraquin"
            },
            {
                "value": 52147,
                "label": "San Julian de Muskiz",
                "label_ar": "سان جوليان دي موسكيز",
                "label_fr": "San Julian de Muskiz"
            },
            {
                "value": 53363,
                "label": "Villaquilambre",
                "label_ar": "فيلاكيلامبر",
                "label_fr": "Villaquilambre"
            },
            {
                "value": 53651,
                "label": "Sepulveda",
                "label_ar": "سيبولفيدا",
                "label_fr": "Sepulveda"
            },
            {
                "value": 52435,
                "label": "Meis",
                "label_ar": "ميس",
                "label_fr": "Moi, ce est"
            },
            {
                "value": 53202,
                "label": "Ingenio",
                "label_ar": "إنجينيو",
                "label_fr": "Ingenio"
            },
            {
                "value": 54022,
                "label": "Guadalupe",
                "label_ar": "غوادالوبي",
                "label_fr": "Guadalupe"
            },
            {
                "value": 52646,
                "label": "Paiporta",
                "label_ar": "بايبورتا",
                "label_fr": "Paiporta"
            },
            {
                "value": 52166,
                "label": "Jerez de la Frontera",
                "label_ar": "خيريز دي لا فرونتيرا",
                "label_fr": "Jerez de la Frontera"
            },
            {
                "value": 51908,
                "label": "Montornès del Vallès",
                "label_ar": "Montornès del Vallès",
                "label_fr": "Montornès del Vallès"
            },
            {
                "value": 54204,
                "label": "Vallmoll",
                "label_ar": "فالمول",
                "label_fr": "Vallmoll"
            },
            {
                "value": 53298,
                "label": "Aguilar",
                "label_ar": "أغيلار",
                "label_fr": "Aguilar"
            },
            {
                "value": 54035,
                "label": "Naquera",
                "label_ar": "ناكويرا",
                "label_fr": "Naquera"
            },
            {
                "value": 53248,
                "label": "Los Navalmorales",
                "label_ar": "لوس نافالموراليس",
                "label_fr": "Los Navalmorales"
            },
            {
                "value": 52421,
                "label": "Cruce de Arinaga",
                "label_ar": "Cruce de Arinaga",
                "label_fr": "Cruce de Arinaga"
            },
            {
                "value": 54164,
                "label": "Pontejos",
                "label_ar": "بونتيجوس",
                "label_fr": "Pontejos"
            },
            {
                "value": 53973,
                "label": "Torremocha del Campo",
                "label_ar": "توريموشا ديل كامبو",
                "label_fr": "Torremocha del Campo"
            },
            {
                "value": 52825,
                "label": "Barcarrota",
                "label_ar": "باركاروتا",
                "label_fr": "Barcarrota"
            },
            {
                "value": 54080,
                "label": "Placencia",
                "label_ar": "بلاسينسيا",
                "label_fr": "Placencia"
            },
            {
                "value": 53245,
                "label": "Petres",
                "label_ar": "بيترس",
                "label_fr": "Petres"
            },
            {
                "value": 53252,
                "label": "Consell",
                "label_ar": "كونسيل",
                "label_fr": "Consell"
            },
            {
                "value": 52694,
                "label": "Bargas",
                "label_ar": "بارجاس",
                "label_fr": "Bargas"
            },
            {
                "value": 53073,
                "label": "Nubledo",
                "label_ar": "نوبليدو",
                "label_fr": "Nubledo"
            },
            {
                "value": 53621,
                "label": "Medrano",
                "label_ar": "ميدرانو",
                "label_fr": "Medrano"
            },
            {
                "value": 54222,
                "label": "Vila-seca",
                "label_ar": "فيلا سيكا",
                "label_fr": "Vila-seca"
            },
            {
                "value": 53793,
                "label": "Cruces",
                "label_ar": "صلبان",
                "label_fr": "Cruces"
            },
            {
                "value": 52424,
                "label": "Las Vegas",
                "label_ar": "لاس فيجاس",
                "label_fr": "Las Vegas"
            },
            {
                "value": 52317,
                "label": "Iznate",
                "label_ar": "Iznate",
                "label_fr": "Iznate"
            },
            {
                "value": 53211,
                "label": "Mariola",
                "label_ar": "ماريولا",
                "label_fr": "Mariola"
            },
            {
                "value": 52583,
                "label": "Mino",
                "label_ar": "مينو",
                "label_fr": "Mino"
            },
            {
                "value": 54305,
                "label": "Ministerio",
                "label_ar": "وزير",
                "label_fr": "Ministerio"
            },
            {
                "value": 54187,
                "label": "Valdeavero",
                "label_ar": "فالديفيرو",
                "label_fr": "Valdeavero"
            },
            {
                "value": 54141,
                "label": "Hornachos",
                "label_ar": "هورناتشوس",
                "label_fr": "Hornachos"
            },
            {
                "value": 53576,
                "label": "Villacarriedo",
                "label_ar": "فيلاكاريدو",
                "label_fr": "Villacarriedo"
            },
            {
                "value": 53422,
                "label": "Sesena Nuevo",
                "label_ar": "سيسينا نويفو",
                "label_fr": "Sesena Nuevo"
            },
            {
                "value": 53271,
                "label": "Capella",
                "label_ar": "كابيلا",
                "label_fr": "Capella"
            },
            {
                "value": 53225,
                "label": "Garrovillas",
                "label_ar": "جاروفيلاس",
                "label_fr": "Garrovillas"
            },
            {
                "value": 53842,
                "label": "Mutriku",
                "label_ar": "موتريكو",
                "label_fr": "Mutriku"
            },
            {
                "value": 53780,
                "label": "Corvera",
                "label_ar": "كورفيرا",
                "label_fr": "Corvera"
            },
            {
                "value": 54148,
                "label": "Brazatortas",
                "label_ar": "برازاتورتاس",
                "label_fr": "Brazatortas"
            },
            {
                "value": 54283,
                "label": "Castilleja de Guzman",
                "label_ar": "كاستيليا دي جوزمان",
                "label_fr": "Castilleja de Guzman"
            },
            {
                "value": 53716,
                "label": "Cilleros",
                "label_ar": "سيلروس",
                "label_fr": "Cilleros"
            },
            {
                "value": 53487,
                "label": "La Fuente de San Esteban",
                "label_ar": "لا فوينتي دي سان إستيبان",
                "label_fr": "La Fuente de San Esteban"
            },
            {
                "value": 52158,
                "label": "Tortella",
                "label_ar": "تورتيلا",
                "label_fr": "Tortella"
            },
            {
                "value": 52913,
                "label": "Bailen",
                "label_ar": "بيلين",
                "label_fr": "Bailen"
            },
            {
                "value": 52928,
                "label": "Parbayon",
                "label_ar": "باربايون",
                "label_fr": "Parbayon"
            },
            {
                "value": 53347,
                "label": "Velilla de San Antonio",
                "label_ar": "فيليلا دي سان أنطونيو",
                "label_fr": "Velilla de San Antonio"
            },
            {
                "value": 54059,
                "label": "Espera",
                "label_ar": "إسبيرا",
                "label_fr": "Espera"
            },
            {
                "value": 53986,
                "label": "Trasona",
                "label_ar": "تراسونا",
                "label_fr": "Trasona"
            },
            {
                "value": 53081,
                "label": "Castellvell del Camp",
                "label_ar": "كاستلفيل ديل كامب",
                "label_fr": "Castellvell del Camp"
            },
            {
                "value": 53751,
                "label": "Casillas del Angel",
                "label_ar": "كاسياس ديل انجيل",
                "label_fr": "Casillas del Angel"
            },
            {
                "value": 52396,
                "label": "Elda",
                "label_ar": "إلدا",
                "label_fr": "Elda"
            },
            {
                "value": 52628,
                "label": "Algorta",
                "label_ar": "الجورتا",
                "label_fr": "Algorta"
            },
            {
                "value": 52340,
                "label": "Blanca",
                "label_ar": "بلانكا",
                "label_fr": "Blanca"
            },
            {
                "value": 54029,
                "label": "Manuel",
                "label_ar": "مانويل",
                "label_fr": "Manuel"
            },
            {
                "value": 54369,
                "label": "s'Arracó",
                "label_ar": "s'Arracó",
                "label_fr": "s'Arracó"
            },
            {
                "value": 53863,
                "label": "La Pedraja de Portillo",
                "label_ar": "لا بيدراجا دي بورتيو",
                "label_fr": "La Pedraja de Portillo"
            },
            {
                "value": 53149,
                "label": "Lugones",
                "label_ar": "لوجونز",
                "label_fr": "Lugones"
            },
            {
                "value": 53136,
                "label": "Tarazona de la Mancha",
                "label_ar": "تارازونا دي لا مانشا",
                "label_fr": "Tarazona de la Mancha"
            },
            {
                "value": 53816,
                "label": "Nalda",
                "label_ar": "نالدا",
                "label_fr": "Nalda"
            },
            {
                "value": 52427,
                "label": "Tomino",
                "label_ar": "تومينو",
                "label_fr": "Tomino"
            },
            {
                "value": 53531,
                "label": "Mura",
                "label_ar": "مورا",
                "label_fr": "Mura"
            },
            {
                "value": 52055,
                "label": "Huelva",
                "label_ar": "هويلفا",
                "label_fr": "Huelva"
            },
            {
                "value": 54155,
                "label": "Tous",
                "label_ar": "لنا",
                "label_fr": "À nous"
            },
            {
                "value": 53235,
                "label": "Pedro Abad",
                "label_ar": "بيدرو أباد",
                "label_fr": "Pedro Abad"
            },
            {
                "value": 54334,
                "label": "Poboleda",
                "label_ar": "بوبوليدا",
                "label_fr": "Poboleda"
            },
            {
                "value": 52066,
                "label": "Jodar",
                "label_ar": "جودار",
                "label_fr": "Jodar"
            },
            {
                "value": 52933,
                "label": "Algar",
                "label_ar": "الجار",
                "label_fr": "Algar"
            },
            {
                "value": 52455,
                "label": "La Guardia de Jaen",
                "label_ar": "لا غوارديا دي خاين",
                "label_fr": "La Guardia de Jaen"
            },
            {
                "value": 54236,
                "label": "Rincon de Soto",
                "label_ar": "رينكون دي سوتو",
                "label_fr": "Rincon de Soto"
            },
            {
                "value": 52414,
                "label": "Culleredo",
                "label_ar": "كوليريدو",
                "label_fr": "Culleredo"
            },
            {
                "value": 52682,
                "label": "Calahorra",
                "label_ar": "كالاهورا",
                "label_fr": "Calahorra"
            },
            {
                "value": 54212,
                "label": "Abegondo",
                "label_ar": "أبيغوندو",
                "label_fr": "Abegondo"
            },
            {
                "value": 53135,
                "label": "Bermeo",
                "label_ar": "برميو",
                "label_fr": "Bermeo"
            },
            {
                "value": 52160,
                "label": "Corchuela",
                "label_ar": "كوركويلا",
                "label_fr": "Corchuela"
            },
            {
                "value": 53383,
                "label": "Chauchina",
                "label_ar": "تشوشينا",
                "label_fr": "Chauchina"
            },
            {
                "value": 52633,
                "label": "el Campello",
                "label_ar": "ايل كامبيلو",
                "label_fr": "el Campello"
            },
            {
                "value": 52303,
                "label": "Montseny",
                "label_ar": "مونتسيني",
                "label_fr": "Montseny"
            },
            {
                "value": 52174,
                "label": "Villanueva de Villaescusa",
                "label_ar": "فيلانويفا دي فيلايسكوسا",
                "label_fr": "Villanueva de Villaescusa"
            },
            {
                "value": 53059,
                "label": "Palau",
                "label_ar": "بالاو",
                "label_fr": "Palau"
            },
            {
                "value": 54320,
                "label": "Carpesa",
                "label_ar": "كاربيسا",
                "label_fr": "Carpesa"
            },
            {
                "value": 54081,
                "label": "Legorreta",
                "label_ar": "ليغوريتا",
                "label_fr": "Legorreta"
            },
            {
                "value": 54153,
                "label": "Competa",
                "label_ar": "كومبيتا",
                "label_fr": "Competa"
            },
            {
                "value": 53176,
                "label": "Pozuelo del Rey",
                "label_ar": "بوزويلو ديل ري",
                "label_fr": "Pozuelo del Rey"
            },
            {
                "value": 52999,
                "label": "Palol",
                "label_ar": "بالول",
                "label_fr": "Palol"
            },
            {
                "value": 53088,
                "label": "Oliva de Plasencia",
                "label_ar": "أوليفا دي بلاسينسيا",
                "label_fr": "Oliva de Plasencia"
            },
            {
                "value": 53705,
                "label": "Traiguera",
                "label_ar": "ترايجويرا",
                "label_fr": "Traiguera"
            },
            {
                "value": 53501,
                "label": "Benagalbon",
                "label_ar": "بيناجالبون",
                "label_fr": "Benagalbon"
            },
            {
                "value": 51971,
                "label": "Lorca",
                "label_ar": "لوركا",
                "label_fr": "Lorca"
            },
            {
                "value": 52701,
                "label": "Santomera",
                "label_ar": "سانتوميرا",
                "label_fr": "Santomera"
            },
            {
                "value": 52264,
                "label": "Manlleu",
                "label_ar": "مانلو",
                "label_fr": "Manlleu"
            },
            {
                "value": 51950,
                "label": "Moralzarzal",
                "label_ar": "مورالزارزال",
                "label_fr": "Moralzarzal"
            },
            {
                "value": 52212,
                "label": "Cangas do Morrazo",
                "label_ar": "كانغاس دو مورازو",
                "label_fr": "Cangas do Morrazo"
            },
            {
                "value": 53862,
                "label": "Planes",
                "label_ar": "طائرات",
                "label_fr": "Avions"
            },
            {
                "value": 54042,
                "label": "Galera",
                "label_ar": "جاليرا",
                "label_fr": "Galera"
            },
            {
                "value": 52905,
                "label": "Nájera",
                "label_ar": "ناجيرا",
                "label_fr": "Nájera"
            },
            {
                "value": 53721,
                "label": "Arteixo",
                "label_ar": "أرتيكسو",
                "label_fr": "Arteixo"
            },
            {
                "value": 53527,
                "label": "La Secuita",
                "label_ar": "لا سيكويتا",
                "label_fr": "La Secuita"
            },
            {
                "value": 53591,
                "label": "Norena",
                "label_ar": "نورينا",
                "label_fr": "Norena"
            },
            {
                "value": 54011,
                "label": "Villanueva",
                "label_ar": "فيلانويفا",
                "label_fr": "Villanueva"
            },
            {
                "value": 52842,
                "label": "Bellvis",
                "label_ar": "بيلفيس",
                "label_fr": "Bellvis"
            },
            {
                "value": 52038,
                "label": "Sitges",
                "label_ar": "سيتجيس",
                "label_fr": "Sitges"
            },
            {
                "value": 52362,
                "label": "l' Argentera",
                "label_ar": "أرجنتيرا",
                "label_fr": "l 'Argentera"
            },
            {
                "value": 54064,
                "label": "La Baneza",
                "label_ar": "لابانيزا",
                "label_fr": "La Baneza"
            },
            {
                "value": 52602,
                "label": "Laracha",
                "label_ar": "لاراشا",
                "label_fr": "Laracha"
            },
            {
                "value": 52767,
                "label": "Navata",
                "label_ar": "نافاتا",
                "label_fr": "Navata"
            },
            {
                "value": 54057,
                "label": "Canet d'En Berenguer",
                "label_ar": "كانيه دين برنغير",
                "label_fr": "Canet d'En Berenguer"
            },
            {
                "value": 54149,
                "label": "Granja de Rocamora",
                "label_ar": "جرانجا دي روكامورا",
                "label_fr": "Granja de Rocamora"
            },
            {
                "value": 54201,
                "label": "Astudillo",
                "label_ar": "أستوديو",
                "label_fr": "Astudillo"
            },
            {
                "value": 53496,
                "label": "Salobreña",
                "label_ar": "سالوبرينيا",
                "label_fr": "Salobreña"
            },
            {
                "value": 53792,
                "label": "Villasevil",
                "label_ar": "فيلاسفيل",
                "label_fr": "Villasevil"
            },
            {
                "value": 52952,
                "label": "Iznajar",
                "label_ar": "إزناجار",
                "label_fr": "Iznajar"
            },
            {
                "value": 51948,
                "label": "Villalbilla",
                "label_ar": "فيلالبيلا",
                "label_fr": "Villalbilla"
            },
            {
                "value": 52683,
                "label": "Almuñécar",
                "label_ar": "المنكب",
                "label_fr": "Almuñécar"
            },
            {
                "value": 53814,
                "label": "Jabaga",
                "label_ar": "جاباجا",
                "label_fr": "Jabaga"
            },
            {
                "value": 52950,
                "label": "Escalona",
                "label_ar": "إسكالونا",
                "label_fr": "Escalona"
            },
            {
                "value": 53848,
                "label": "Alburquerque",
                "label_ar": "البوركيرك",
                "label_fr": "Alburquerque"
            },
            {
                "value": 52428,
                "label": "Biar",
                "label_ar": "بيار",
                "label_fr": "Biar"
            },
            {
                "value": 51914,
                "label": "Blanes",
                "label_ar": "بلانس",
                "label_fr": "Blanes"
            },
            {
                "value": 53074,
                "label": "Montgat",
                "label_ar": "مونتجات",
                "label_fr": "Montgat"
            },
            {
                "value": 52255,
                "label": "Capdepera",
                "label_ar": "كابديبيرا",
                "label_fr": "Capdepera"
            },
            {
                "value": 53834,
                "label": "Mata",
                "label_ar": "ماتا",
                "label_fr": "Mata"
            },
            {
                "value": 53872,
                "label": "Siguenza",
                "label_ar": "سيغوينزا",
                "label_fr": "Siguenza"
            },
            {
                "value": 54474,
                "label": "Los Rios",
                "label_ar": "لوس ريوس",
                "label_fr": "Los Rios"
            },
            {
                "value": 53067,
                "label": "Villafranca de los Caballeros",
                "label_ar": "فيلافرانكا دي لوس كاباليروس",
                "label_fr": "Villafranca de los Caballeros"
            },
            {
                "value": 54412,
                "label": "Velez-Rubio",
                "label_ar": "فيليز روبيو",
                "label_fr": "Velez-Rubio"
            },
            {
                "value": 53398,
                "label": "Bujaraloz",
                "label_ar": "بوجارالوز",
                "label_fr": "Bujaraloz"
            },
            {
                "value": 52798,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 53887,
                "label": "Sax",
                "label_ar": "ساكس",
                "label_fr": "Saxo"
            },
            {
                "value": 53787,
                "label": "Dolores",
                "label_ar": "دولوريس",
                "label_fr": "Dolores"
            },
            {
                "value": 52891,
                "label": "Navaconcejo",
                "label_ar": "نافاكونسيجو",
                "label_fr": "Navaconcejo"
            },
            {
                "value": 52889,
                "label": "Valdemaqueda",
                "label_ar": "فالديماكويدا",
                "label_fr": "Valdemaqueda"
            },
            {
                "value": 52778,
                "label": "Arenys de Mar",
                "label_ar": "Arenys de Mar",
                "label_fr": "Arenys de Mar"
            },
            {
                "value": 52740,
                "label": "Pedrajas",
                "label_ar": "بيدراجاس",
                "label_fr": "Pedrajas"
            },
            {
                "value": 52597,
                "label": "Friol",
                "label_ar": "فريول",
                "label_fr": "Friol"
            },
            {
                "value": 54266,
                "label": "El Casar",
                "label_ar": "الكزار",
                "label_fr": "El Casar"
            },
            {
                "value": 54371,
                "label": "Querol",
                "label_ar": "كويرول",
                "label_fr": "Querol"
            },
            {
                "value": 53625,
                "label": "Samalús",
                "label_ar": "Samalús",
                "label_fr": "Samalús"
            },
            {
                "value": 54301,
                "label": "Alza",
                "label_ar": "ألزا",
                "label_fr": "Alza"
            },
            {
                "value": 53216,
                "label": "Madridejos",
                "label_ar": "مادريدجوس",
                "label_fr": "Madridejos"
            },
            {
                "value": 53838,
                "label": "Borox",
                "label_ar": "بوروكس",
                "label_fr": "Borox"
            },
            {
                "value": 52734,
                "label": "Noain",
                "label_ar": "نوح",
                "label_fr": "Noain"
            },
            {
                "value": 52709,
                "label": "Palma del Rio",
                "label_ar": "بالما ديل ريو",
                "label_fr": "Palma del Rio"
            },
            {
                "value": 54050,
                "label": "Santa Maria",
                "label_ar": "سانتا ماريا",
                "label_fr": "Sainte Marie"
            },
            {
                "value": 54425,
                "label": "Quintana de la Serena",
                "label_ar": "كوينتانا دي لا سيرينا",
                "label_fr": "Quintana de la Serena"
            },
            {
                "value": 54393,
                "label": "Busot",
                "label_ar": "بوسوت",
                "label_fr": "Busot"
            },
            {
                "value": 53479,
                "label": "Molina de Aragon",
                "label_ar": "مولينا دي أراغون",
                "label_fr": "Molina de Aragon"
            },
            {
                "value": 52206,
                "label": "Torrevieja",
                "label_ar": "توريفايجا",
                "label_fr": "Torrevieja"
            },
            {
                "value": 54188,
                "label": "Sarandones",
                "label_ar": "ساراندونيس",
                "label_fr": "Sarandones"
            },
            {
                "value": 53049,
                "label": "Monistrol de Calders",
                "label_ar": "Monistrol دي كالدرز",
                "label_fr": "Monistrol de Calders"
            },
            {
                "value": 52585,
                "label": "Estrada",
                "label_ar": "استرادا",
                "label_fr": "Estrada"
            },
            {
                "value": 53524,
                "label": "Alcanadre",
                "label_ar": "الكندر",
                "label_fr": "Alcanadre"
            },
            {
                "value": 52588,
                "label": "Arzua",
                "label_ar": "أرزوا",
                "label_fr": "Arzua"
            },
            {
                "value": 53303,
                "label": "Ador",
                "label_ar": "أدور",
                "label_fr": "Ador"
            },
            {
                "value": 52833,
                "label": "Algaida",
                "label_ar": "الجيدا",
                "label_fr": "Algaida"
            },
            {
                "value": 53340,
                "label": "Torrox",
                "label_ar": "توروكس",
                "label_fr": "Torrox"
            },
            {
                "value": 52953,
                "label": "Teià",
                "label_ar": "تييا",
                "label_fr": "Teià"
            },
            {
                "value": 53560,
                "label": "Aldaia",
                "label_ar": "الضياء",
                "label_fr": "Aldaia"
            },
            {
                "value": 52579,
                "label": "Rianxo",
                "label_ar": "ريانكسو",
                "label_fr": "Rianxo"
            },
            {
                "value": 53367,
                "label": "Mora",
                "label_ar": "مورا",
                "label_fr": "Mora"
            },
            {
                "value": 52755,
                "label": "Huertas",
                "label_ar": "هويرتاس",
                "label_fr": "Huertas"
            },
            {
                "value": 53370,
                "label": "Figueras",
                "label_ar": "فيغيراس",
                "label_fr": "Figueras"
            },
            {
                "value": 51927,
                "label": "Cobena",
                "label_ar": "كوبينا",
                "label_fr": "Cobena"
            },
            {
                "value": 52828,
                "label": "Duenas",
                "label_ar": "دويناس",
                "label_fr": "Duenas"
            },
            {
                "value": 52591,
                "label": "O Carballino",
                "label_ar": "يا كاربالينو",
                "label_fr": "O Carballino"
            },
            {
                "value": 52445,
                "label": "Cobatillas",
                "label_ar": "كوباتيلاس",
                "label_fr": "Cobatillas"
            },
            {
                "value": 52841,
                "label": "Sils",
                "label_ar": "سيلس",
                "label_fr": "Sils"
            },
            {
                "value": 52664,
                "label": "Gurb",
                "label_ar": "جورب",
                "label_fr": "Gurb"
            },
            {
                "value": 52100,
                "label": "Santa Cruz de La Palma",
                "label_ar": "سانتا كروز دي لا بالما",
                "label_fr": "Santa Cruz de La Palma"
            },
            {
                "value": 53026,
                "label": "Pola de Laviana",
                "label_ar": "بولا دي لافيانا",
                "label_fr": "Pola de Laviana"
            },
            {
                "value": 52422,
                "label": "Monforte de Lemos",
                "label_ar": "مونفورتي دي ليموس",
                "label_fr": "Monforte de Lemos"
            },
            {
                "value": 53365,
                "label": "Sabucedo",
                "label_ar": "Sabucedo",
                "label_fr": "Sabucedo"
            },
            {
                "value": 52886,
                "label": "Villaconejos",
                "label_ar": "فيلاكونيوس",
                "label_fr": "Villaconejos"
            },
            {
                "value": 54040,
                "label": "el Camp de Mirra",
                "label_ar": "ايل كامب دي ميرا",
                "label_fr": "el Camp de Mirra"
            },
            {
                "value": 52622,
                "label": "Denia",
                "label_ar": "دينيا",
                "label_fr": "Denia"
            },
            {
                "value": 53515,
                "label": "Barbate",
                "label_ar": "أهلب قاسي الشعيرات",
                "label_fr": "Barbate"
            },
            {
                "value": 52410,
                "label": "Cuenca",
                "label_ar": "كوينكا",
                "label_fr": "Cuenca"
            },
            {
                "value": 52930,
                "label": "Oyon",
                "label_ar": "أويون",
                "label_fr": "Oyon"
            },
            {
                "value": 52020,
                "label": "Garrucha",
                "label_ar": "جاروتشا",
                "label_fr": "Garrucha"
            },
            {
                "value": 53867,
                "label": "Torredonjimeno",
                "label_ar": "Torredonjimeno",
                "label_fr": "Torredonjimeno"
            },
            {
                "value": 54477,
                "label": "Ujo",
                "label_ar": "يوجو",
                "label_fr": "Ujo"
            },
            {
                "value": 52965,
                "label": "Meco",
                "label_ar": "ميكو",
                "label_fr": "Meco"
            },
            {
                "value": 52924,
                "label": "Venta",
                "label_ar": "فينتا",
                "label_fr": "Venta"
            },
            {
                "value": 52979,
                "label": "Posadas",
                "label_ar": "بوساداس",
                "label_fr": "Posadas"
            },
            {
                "value": 53715,
                "label": "O Grove",
                "label_ar": "يا غروف",
                "label_fr": "O Grove"
            },
            {
                "value": 53714,
                "label": "Castillo de Locubin",
                "label_ar": "كاستيلو دي لوكوبين",
                "label_fr": "Castillo de Locubin"
            },
            {
                "value": 54372,
                "label": "La Lantejuela",
                "label_ar": "لا لانتيجويلا",
                "label_fr": "La Lantejuela"
            },
            {
                "value": 53971,
                "label": "Olula de Castro",
                "label_ar": "أولولا دي كاسترو",
                "label_fr": "Olula de Castro"
            },
            {
                "value": 53264,
                "label": "La Roca del Valles",
                "label_ar": "لا روكا ديل فاليس",
                "label_fr": "La Roca del Valles"
            },
            {
                "value": 53762,
                "label": "Aldeamayor de San Martin",
                "label_ar": "الديمايور دي سان مارتن",
                "label_fr": "Aldeamayor de San Martin"
            },
            {
                "value": 53994,
                "label": "Bara",
                "label_ar": "بارا",
                "label_fr": "Bara"
            },
            {
                "value": 53403,
                "label": "La Torre",
                "label_ar": "لا توري",
                "label_fr": "La Torre"
            },
            {
                "value": 52881,
                "label": "La Figuera",
                "label_ar": "لا فيغيرا",
                "label_fr": "La Figuera"
            },
            {
                "value": 53068,
                "label": "Parcent",
                "label_ar": "الوالد",
                "label_fr": "Parcent"
            },
            {
                "value": 53574,
                "label": "Gizaburuaga",
                "label_ar": "جيزابورواجا",
                "label_fr": "Gizaburuaga"
            },
            {
                "value": 52565,
                "label": "Mirador",
                "label_ar": "ميرادور",
                "label_fr": "Mirador"
            },
            {
                "value": 52614,
                "label": "Valls",
                "label_ar": "فالس",
                "label_fr": "Valls"
            },
            {
                "value": 52902,
                "label": "Haro",
                "label_ar": "هارو",
                "label_fr": "Haro"
            },
            {
                "value": 52984,
                "label": "La Robla",
                "label_ar": "لا روبلا",
                "label_fr": "La Robla"
            },
            {
                "value": 52792,
                "label": "Corella",
                "label_ar": "كوريلا",
                "label_fr": "Corella"
            },
            {
                "value": 52557,
                "label": "Motril",
                "label_ar": "موتريل",
                "label_fr": "Motril"
            },
            {
                "value": 53789,
                "label": "Magan",
                "label_ar": "ماجان",
                "label_fr": "Magan"
            },
            {
                "value": 53528,
                "label": "la Febro",
                "label_ar": "لا فيبرو",
                "label_fr": "la Febro"
            },
            {
                "value": 53305,
                "label": "Castejon del Puente",
                "label_ar": "كاستيجون ديل بوينتي",
                "label_fr": "Castejon del Puente"
            },
            {
                "value": 52011,
                "label": "Gáldar",
                "label_ar": "غالدار",
                "label_fr": "Gáldar"
            },
            {
                "value": 53239,
                "label": "el Morell",
                "label_ar": "الموريل",
                "label_fr": "el Morell"
            },
            {
                "value": 53426,
                "label": "Alfambra",
                "label_ar": "ألفامبرا",
                "label_fr": "Alfambra"
            },
            {
                "value": 53678,
                "label": "Alonsotegi",
                "label_ar": "ألونسوتيجي",
                "label_fr": "Alonsotegi"
            },
            {
                "value": 54416,
                "label": "Valle de Oro",
                "label_ar": "فالي دي أورو",
                "label_fr": "Valle de Oro"
            },
            {
                "value": 52552,
                "label": "Godella",
                "label_ar": "جوديلا",
                "label_fr": "Godella"
            },
            {
                "value": 52554,
                "label": "Cisterniga",
                "label_ar": "سيستيرنيجا",
                "label_fr": "Cisterniga"
            },
            {
                "value": 53385,
                "label": "Botorrita",
                "label_ar": "بوتوريتا",
                "label_fr": "Botorrita"
            },
            {
                "value": 52987,
                "label": "Begur",
                "label_ar": "بيجور",
                "label_fr": "Begur"
            },
            {
                "value": 53171,
                "label": "Beriain",
                "label_ar": "بريعين",
                "label_fr": "Beriain"
            },
            {
                "value": 52702,
                "label": "Gernika-Lumo",
                "label_ar": "جيرنيكا لومو",
                "label_fr": "Gernika-Lumo"
            },
            {
                "value": 53231,
                "label": "Riudellots de la Selva",
                "label_ar": "Riudellots de la Selva",
                "label_fr": "Riudellots de la Selva"
            },
            {
                "value": 52745,
                "label": "Noja",
                "label_ar": "نوخا",
                "label_fr": "Noja"
            },
            {
                "value": 54225,
                "label": "Lerma",
                "label_ar": "ليرما",
                "label_fr": "Lerma"
            },
            {
                "value": 53286,
                "label": "Villacarrillo",
                "label_ar": "فيلاكاريلو",
                "label_fr": "Villacarrillo"
            },
            {
                "value": 52880,
                "label": "Priego",
                "label_ar": "بريجو",
                "label_fr": "Priego"
            },
            {
                "value": 53666,
                "label": "Casillas de Coria",
                "label_ar": "كاسياس دي كوريا",
                "label_fr": "Casillas de Coria"
            },
            {
                "value": 52787,
                "label": "Chantada",
                "label_ar": "شانتادا",
                "label_fr": "Chantada"
            },
            {
                "value": 53050,
                "label": "La Virgen del Camino",
                "label_ar": "لا فيرجن ديل كامينو",
                "label_fr": "La Virgen del Camino"
            },
            {
                "value": 53857,
                "label": "Torre del Campo",
                "label_ar": "توري ديل كامبو",
                "label_fr": "Torre del Campo"
            },
            {
                "value": 52831,
                "label": "Quel",
                "label_ar": "كويل",
                "label_fr": "Quel"
            },
            {
                "value": 53632,
                "label": "Zahara de los Atunes",
                "label_ar": "زهرة دي لوس أتونيس",
                "label_fr": "Zahara de los Atunes"
            },
            {
                "value": 54441,
                "label": "Vilaplana",
                "label_ar": "فيلابلانا",
                "label_fr": "Vilaplana"
            },
            {
                "value": 52920,
                "label": "Villamalea",
                "label_ar": "فيلاماليا",
                "label_fr": "Villamalea"
            },
            {
                "value": 53839,
                "label": "Novallas",
                "label_ar": "نوفالاس",
                "label_fr": "Novallas"
            },
            {
                "value": 53431,
                "label": "Luarca",
                "label_ar": "لواركا",
                "label_fr": "Luarca"
            },
            {
                "value": 53768,
                "label": "Puentenansa",
                "label_ar": "Puentenansa",
                "label_fr": "Puentenansa"
            },
            {
                "value": 53670,
                "label": "San Juan del Puerto",
                "label_ar": "سان خوان ديل بويرتو",
                "label_fr": "San Juan del Puerto"
            },
            {
                "value": 52612,
                "label": "Ronda",
                "label_ar": "روندا",
                "label_fr": "Ronda"
            },
            {
                "value": 52983,
                "label": "Odena",
                "label_ar": "اودينا",
                "label_fr": "Odena"
            },
            {
                "value": 53389,
                "label": "Villacastin",
                "label_ar": "فيلاكاستين",
                "label_fr": "Villacastin"
            },
            {
                "value": 54200,
                "label": "Castropol",
                "label_ar": "كاستروبول",
                "label_fr": "Castropol"
            },
            {
                "value": 53546,
                "label": "Vejer de la Frontera",
                "label_ar": "Vejer de la Frontera",
                "label_fr": "Vejer de la Frontera"
            },
            {
                "value": 53354,
                "label": "La Codosera",
                "label_ar": "لا كودوسيرا",
                "label_fr": "La Codosera"
            },
            {
                "value": 53914,
                "label": "Albendin",
                "label_ar": "ألبيندين",
                "label_fr": "Albendin"
            },
            {
                "value": 53774,
                "label": "Portillo",
                "label_ar": "بورتيلو",
                "label_fr": "Portillo"
            },
            {
                "value": 53739,
                "label": "Pontos",
                "label_ar": "بونتوس",
                "label_fr": "Pontos"
            },
            {
                "value": 52026,
                "label": "Albox",
                "label_ar": "البوكس",
                "label_fr": "Albox"
            },
            {
                "value": 53819,
                "label": "Infantes",
                "label_ar": "الرضع",
                "label_fr": "Infantes"
            },
            {
                "value": 54231,
                "label": "Posadas",
                "label_ar": "بوساداس",
                "label_fr": "Posadas"
            },
            {
                "value": 53879,
                "label": "Torrelaguna",
                "label_ar": "توريلاغونا",
                "label_fr": "Torrelaguna"
            },
            {
                "value": 52888,
                "label": "Cintruénigo",
                "label_ar": "Cintruénigo",
                "label_fr": "Cintruénigo"
            },
            {
                "value": 52185,
                "label": "Vitoria-Gasteiz",
                "label_ar": "فيتوريا جاستيز",
                "label_fr": "Vitoria-Gasteiz"
            },
            {
                "value": 52733,
                "label": "Villares de la Reina",
                "label_ar": "فيلاريس دي لا رينا",
                "label_fr": "Villares de la Reina"
            },
            {
                "value": 53079,
                "label": "Olesa de Bonesvalls",
                "label_ar": "أوليسا دي بونزفالز",
                "label_fr": "Olesa de Bonesvalls"
            },
            {
                "value": 52081,
                "label": "La Higuerita",
                "label_ar": "لا هيغيريتا",
                "label_fr": "La Higuerita"
            },
            {
                "value": 53141,
                "label": "Torreblascopedro",
                "label_ar": "توريبلاسكوبيدرو",
                "label_fr": "Torreblascopedro"
            },
            {
                "value": 52354,
                "label": "Librilla",
                "label_ar": "ليبريللا",
                "label_fr": "Librilla"
            },
            {
                "value": 52908,
                "label": "Zalamea la Real",
                "label_ar": "Zalamea la Real",
                "label_fr": "Zalamea la Real"
            },
            {
                "value": 52863,
                "label": "La Flecha",
                "label_ar": "لا فليشا",
                "label_fr": "La Flecha"
            },
            {
                "value": 53447,
                "label": "La Carolina",
                "label_ar": "لا كارولينا",
                "label_fr": "La Carolina"
            },
            {
                "value": 52968,
                "label": "Balerma",
                "label_ar": "باليرما",
                "label_fr": "Balerma"
            },
            {
                "value": 52821,
                "label": "La Canonja",
                "label_ar": "لا كانونجا",
                "label_fr": "La Canonja"
            },
            {
                "value": 52306,
                "label": "Bormujos",
                "label_ar": "برموجوس",
                "label_fr": "Bormujos"
            },
            {
                "value": 53553,
                "label": "Navatejera",
                "label_ar": "نافاتيجيرا",
                "label_fr": "Navatejera"
            },
            {
                "value": 53736,
                "label": "Antigua",
                "label_ar": "أنتيغوا",
                "label_fr": "Antigua"
            },
            {
                "value": 52082,
                "label": "Playa",
                "label_ar": "بلايا",
                "label_fr": "Playa"
            },
            {
                "value": 52122,
                "label": "Lucena",
                "label_ar": "لوسينا",
                "label_fr": "Lucena"
            },
            {
                "value": 54429,
                "label": "Hospital",
                "label_ar": "مستشفى",
                "label_fr": "Hôpital"
            },
            {
                "value": 53511,
                "label": "Sierra",
                "label_ar": "سلسلة جبلية",
                "label_fr": "Sierra"
            },
            {
                "value": 52867,
                "label": "Collbato",
                "label_ar": "كولباتو",
                "label_fr": "Collbato"
            },
            {
                "value": 53804,
                "label": "Trespaderne",
                "label_ar": "تريسباديرن",
                "label_fr": "Trespaderne"
            },
            {
                "value": 52220,
                "label": "Pamplona",
                "label_ar": "بامبلونا",
                "label_fr": "Pampelune"
            },
            {
                "value": 53525,
                "label": "Pinseque",
                "label_ar": "بينسك",
                "label_fr": "Pinseque"
            },
            {
                "value": 53667,
                "label": "Urduna",
                "label_ar": "أردونا",
                "label_fr": "Urduna"
            },
            {
                "value": 53775,
                "label": "Ondara",
                "label_ar": "أوندارا",
                "label_fr": "Ondara"
            },
            {
                "value": 54014,
                "label": "Pedro",
                "label_ar": "بيدرو",
                "label_fr": "Pedro"
            },
            {
                "value": 54377,
                "label": "Tora de Riubregos",
                "label_ar": "تورا دي ريوبريغوس",
                "label_fr": "Tora de Riubregos"
            },
            {
                "value": 52336,
                "label": "Laredo",
                "label_ar": "لاريدو",
                "label_fr": "Laredo"
            },
            {
                "value": 52024,
                "label": "Nijar",
                "label_ar": "نيجار",
                "label_fr": "Nijar"
            },
            {
                "value": 53618,
                "label": "Torrelletas",
                "label_ar": "توريليتاس",
                "label_fr": "Torrelletas"
            },
            {
                "value": 52102,
                "label": "Puerto de la Cruz",
                "label_ar": "بويرتو دي لا كروز",
                "label_fr": "Puerto de la Cruz"
            },
            {
                "value": 52139,
                "label": "Castellón de la Plana",
                "label_ar": "كاستيون دي لا بلانا",
                "label_fr": "Castellón de la Plana"
            },
            {
                "value": 53702,
                "label": "Bicorp",
                "label_ar": "بيكورب",
                "label_fr": "Bicorp"
            },
            {
                "value": 52944,
                "label": "Numancia de la Sagra",
                "label_ar": "نومانسيا دي لا ساجرا",
                "label_fr": "Numancia de la Sagra"
            },
            {
                "value": 54268,
                "label": "Herrera",
                "label_ar": "هيريرا",
                "label_fr": "Herrera"
            },
            {
                "value": 52771,
                "label": "Móra d'Ebre",
                "label_ar": "مورا ديبر",
                "label_fr": "Móra d'Ebre"
            },
            {
                "value": 53622,
                "label": "Premiàde Dalt",
                "label_ar": "بريمياد دالت",
                "label_fr": "Premiàde Dalt"
            },
            {
                "value": 53703,
                "label": "Umbrete",
                "label_ar": "المظلة",
                "label_fr": "Umbrete"
            },
            {
                "value": 53737,
                "label": "Almargen",
                "label_ar": "المارجن",
                "label_fr": "Almargen"
            },
            {
                "value": 52903,
                "label": "Escatron",
                "label_ar": "إسكاترون",
                "label_fr": "Escatron"
            },
            {
                "value": 53469,
                "label": "Estellencs",
                "label_ar": "إستلينكس",
                "label_fr": "Estellencs"
            },
            {
                "value": 51918,
                "label": "Gava",
                "label_ar": "جافا",
                "label_fr": "Gava"
            },
            {
                "value": 52278,
                "label": "Los Garres",
                "label_ar": "لوس جاريس",
                "label_fr": "Los Garres"
            },
            {
                "value": 53470,
                "label": "Artajona",
                "label_ar": "ارتاجونا",
                "label_fr": "Artajona"
            },
            {
                "value": 52375,
                "label": "Rielves",
                "label_ar": "ريلفس",
                "label_fr": "Rielves"
            },
            {
                "value": 52365,
                "label": "Aizoain",
                "label_ar": "ايزوين",
                "label_fr": "Aizoain"
            },
            {
                "value": 54350,
                "label": "Artenara",
                "label_ar": "أرتينارا",
                "label_fr": "Artenara"
            },
            {
                "value": 53612,
                "label": "Campohermoso",
                "label_ar": "كامبوهيرموسو",
                "label_fr": "Campohermoso"
            },
            {
                "value": 51905,
                "label": "Valencia",
                "label_ar": "فالنسيا",
                "label_fr": "Valence"
            },
            {
                "value": 52957,
                "label": "Enguera",
                "label_ar": "إنجويرا",
                "label_fr": "Enguera"
            },
            {
                "value": 52443,
                "label": "Vergel",
                "label_ar": "فيرجيل",
                "label_fr": "Vergel"
            },
            {
                "value": 53353,
                "label": "Navaridas",
                "label_ar": "نافاريداس",
                "label_fr": "Navaridas"
            },
            {
                "value": 54376,
                "label": "Sudanell",
                "label_ar": "سودانيل",
                "label_fr": "Soudanell"
            },
            {
                "value": 54120,
                "label": "Marzagan",
                "label_ar": "مرزكان",
                "label_fr": "Marzagan"
            },
            {
                "value": 54074,
                "label": "Cajar",
                "label_ar": "الكاجار",
                "label_fr": "Cajar"
            },
            {
                "value": 52177,
                "label": "Elche",
                "label_ar": "إلتشي",
                "label_fr": "Elche"
            },
            {
                "value": 53588,
                "label": "Vilamaniscle",
                "label_ar": "فيلامانيكلي",
                "label_fr": "Vilamaniscle"
            },
            {
                "value": 53103,
                "label": "Torreperogil",
                "label_ar": "توريبيروجيل",
                "label_fr": "Torreperogil"
            },
            {
                "value": 52540,
                "label": "Portugalete",
                "label_ar": "برتغاليت",
                "label_fr": "Portugalete"
            },
            {
                "value": 53808,
                "label": "Morella",
                "label_ar": "موريلا",
                "label_fr": "Morella"
            },
            {
                "value": 53589,
                "label": "Navacerrada",
                "label_ar": "نافاسيرادا",
                "label_fr": "Navacerrada"
            },
            {
                "value": 54405,
                "label": "Sant Feliu de Llobregat",
                "label_ar": "سانت فيليو دي يوبريغات",
                "label_fr": "Sant Feliu de Llobregat"
            },
            {
                "value": 53054,
                "label": "Beas de Segura",
                "label_ar": "بيز دي سيجورا",
                "label_fr": "Beas de Segura"
            },
            {
                "value": 54070,
                "label": "Cebolla",
                "label_ar": "سيبولا",
                "label_fr": "Cebolla"
            },
            {
                "value": 53592,
                "label": "Brafim",
                "label_ar": "برافيم",
                "label_fr": "Brafim"
            },
            {
                "value": 52869,
                "label": "Valencia de Don Juan",
                "label_ar": "فالنسيا دي دون جوان",
                "label_fr": "Valence de Don Juan"
            },
            {
                "value": 52052,
                "label": "Mijas",
                "label_ar": "ميخاس",
                "label_fr": "Mijas"
            },
            {
                "value": 52893,
                "label": "Castrillon",
                "label_ar": "كاستريلون",
                "label_fr": "Castrillon"
            },
            {
                "value": 53316,
                "label": "Macanet de la Selva",
                "label_ar": "Macanet de la Selva",
                "label_fr": "Macanet de la Selva"
            },
            {
                "value": 53165,
                "label": "La Almunia de Dona Godina",
                "label_ar": "لا ألمونيا دي دونا جودينا",
                "label_fr": "La Almunia de Dona Godina"
            },
            {
                "value": 53777,
                "label": "Jorba",
                "label_ar": "جوربا",
                "label_fr": "Jorba"
            },
            {
                "value": 54296,
                "label": "Luou",
                "label_ar": "لوو",
                "label_fr": "Luou"
            },
            {
                "value": 53843,
                "label": "Grazalema",
                "label_ar": "جرازاليما",
                "label_fr": "Grazalema"
            },
            {
                "value": 53855,
                "label": "Sant Marti de Malda",
                "label_ar": "سانت مارتي دي مالدا",
                "label_fr": "Sant Marti de Malda"
            },
            {
                "value": 52681,
                "label": "Salou",
                "label_ar": "سالو",
                "label_fr": "Salou"
            },
            {
                "value": 54437,
                "label": "Seoane",
                "label_ar": "سيوان",
                "label_fr": "Seoane"
            },
            {
                "value": 53786,
                "label": "Pruvia",
                "label_ar": "بروفيا",
                "label_fr": "Pruvia"
            },
            {
                "value": 53194,
                "label": "Nanclares de la Oca",
                "label_ar": "نانكلاريس دي لا أوكا",
                "label_fr": "Nanclares de la Oca"
            },
            {
                "value": 52877,
                "label": "Castello",
                "label_ar": "كاستيلو",
                "label_fr": "Castello"
            },
            {
                "value": 52270,
                "label": "San Antonio",
                "label_ar": "سان أنطونيو",
                "label_fr": "San Antonio"
            },
            {
                "value": 52447,
                "label": "Langreo",
                "label_ar": "لانجريو",
                "label_fr": "Langreo"
            },
            {
                "value": 53174,
                "label": "Vedra",
                "label_ar": "فيدرا",
                "label_fr": "Vedra"
            },
            {
                "value": 52184,
                "label": "León",
                "label_ar": "ليون",
                "label_fr": "León"
            },
            {
                "value": 52044,
                "label": "Vall de Almonacid",
                "label_ar": "فال دي الموناسيد",
                "label_fr": "Vall de Almonacid"
            },
            {
                "value": 53752,
                "label": "Vilacolum",
                "label_ar": "فيلاكولوم",
                "label_fr": "Vilacolum"
            },
            {
                "value": 54353,
                "label": "Pulianas",
                "label_ar": "بوليانا",
                "label_fr": "Pulianas"
            },
            {
                "value": 52690,
                "label": "La Rinconada",
                "label_ar": "لا رينكونادا",
                "label_fr": "La Rinconada"
            },
            {
                "value": 51913,
                "label": "Vallirana",
                "label_ar": "فاليرانا",
                "label_fr": "Vallirana"
            },
            {
                "value": 53643,
                "label": "San Anton",
                "label_ar": "سان انطون",
                "label_fr": "San Anton"
            },
            {
                "value": 53550,
                "label": "Comares",
                "label_ar": "قماريس",
                "label_fr": "Comares"
            },
            {
                "value": 54082,
                "label": "Elgoibar",
                "label_ar": "الجوبار",
                "label_fr": "Elgoibar"
            },
            {
                "value": 52258,
                "label": "Marratxí",
                "label_ar": "ماراتشي",
                "label_fr": "Marratxí"
            },
            {
                "value": 53041,
                "label": "Cortegana",
                "label_ar": "كورتيجانا",
                "label_fr": "Cortegana"
            },
            {
                "value": 52150,
                "label": "Poboa de San Xulian",
                "label_ar": "بوبوا دي سان كسوليان",
                "label_fr": "Poboa de San Xulian"
            },
            {
                "value": 52598,
                "label": "Bergondo",
                "label_ar": "برجوندو",
                "label_fr": "Bergondo"
            },
            {
                "value": 52285,
                "label": "Cabrils",
                "label_ar": "كابريلز",
                "label_fr": "Cabrils"
            },
            {
                "value": 52040,
                "label": "Dosrius",
                "label_ar": "دوسريوس",
                "label_fr": "Dosrius"
            },
            {
                "value": 53352,
                "label": "Belgida",
                "label_ar": "بيلجيدا",
                "label_fr": "Belgida"
            },
            {
                "value": 52468,
                "label": "Carlet",
                "label_ar": "كارليت",
                "label_fr": "Carlet"
            },
            {
                "value": 53351,
                "label": "Llerena",
                "label_ar": "يرينا",
                "label_fr": "Llerena"
            },
            {
                "value": 53689,
                "label": "Fuente el Saz",
                "label_ar": "فوينتي الساز",
                "label_fr": "Fuente el Saz"
            },
            {
                "value": 53386,
                "label": "Chiclana de Segura",
                "label_ar": "شيكلانا دي سيجورا",
                "label_fr": "Chiclana de Segura"
            },
            {
                "value": 53652,
                "label": "La Virgen del Pilar",
                "label_ar": "لا فيرجن ديل بيلار",
                "label_fr": "La Virgen del Pilar"
            },
            {
                "value": 53491,
                "label": "Pola de Lena",
                "label_ar": "بولا دي لينا",
                "label_fr": "Pola de Lena"
            },
            {
                "value": 52083,
                "label": "Adeje",
                "label_ar": "أديجي",
                "label_fr": "Adeje"
            },
            {
                "value": 53205,
                "label": "Salas de los Infantes",
                "label_ar": "سالاس دي لوس إنفانتيس",
                "label_fr": "Salas de los Infantes"
            },
            {
                "value": 54199,
                "label": "Miranda de Arga",
                "label_ar": "ميراندا دي أرغا",
                "label_fr": "Miranda de Arga"
            },
            {
                "value": 53437,
                "label": "Monòver",
                "label_ar": "مونيفر",
                "label_fr": "Monòver"
            },
            {
                "value": 53799,
                "label": "Almazcara",
                "label_ar": "المازكارا",
                "label_fr": "Almazcara"
            },
            {
                "value": 53484,
                "label": "Higuera la Real",
                "label_ar": "هيغويرا لا ريال",
                "label_fr": "Higuera la Real"
            },
            {
                "value": 52780,
                "label": "Cijuela",
                "label_ar": "سيخويلا",
                "label_fr": "Cijuela"
            },
            {
                "value": 52523,
                "label": "Carrion de los Cespedes",
                "label_ar": "كاريون دي لوس سيسبيديس",
                "label_fr": "Carrion de los Cespedes"
            },
            {
                "value": 53064,
                "label": "Utiel",
                "label_ar": "يوتيل",
                "label_fr": "Utiel"
            },
            {
                "value": 53934,
                "label": "Zaratan",
                "label_ar": "زاراتان",
                "label_fr": "Zaratan"
            },
            {
                "value": 53027,
                "label": "Bolvir",
                "label_ar": "بولفير",
                "label_fr": "Bolvir"
            },
            {
                "value": 54410,
                "label": "Algarrobo",
                "label_ar": "الجاروبو",
                "label_fr": "Algarrobo"
            },
            {
                "value": 53724,
                "label": "Perdiguera",
                "label_ar": "Perdiguera",
                "label_fr": "Perdiguera"
            },
            {
                "value": 53708,
                "label": "Aielo de Malferit",
                "label_ar": "ايلو دي مالفريت",
                "label_fr": "Aielo de Malferit"
            },
            {
                "value": 52190,
                "label": "Trujillo",
                "label_ar": "تروخيو",
                "label_fr": "Trujillo"
            },
            {
                "value": 52548,
                "label": "Quart d'Onyar",
                "label_ar": "كوارت دونيار",
                "label_fr": "Quart d'Onyar"
            },
            {
                "value": 54438,
                "label": "Deustu",
                "label_ar": "ديوستو",
                "label_fr": "Deustu"
            },
            {
                "value": 53836,
                "label": "Carral",
                "label_ar": "كارال",
                "label_fr": "Carral"
            },
            {
                "value": 54460,
                "label": "Grisen",
                "label_ar": "جريسن",
                "label_fr": "Grisen"
            },
            {
                "value": 53454,
                "label": "Mazarrón",
                "label_ar": "مازارون",
                "label_fr": "Mazarrón"
            },
            {
                "value": 53923,
                "label": "Cox",
                "label_ar": "كوكس",
                "label_fr": "Barreur"
            },
            {
                "value": 54026,
                "label": "Astorga",
                "label_ar": "أستورجا",
                "label_fr": "Astorga"
            },
            {
                "value": 54242,
                "label": "Rubielos de Mora",
                "label_ar": "روبيلوس دي مورا",
                "label_fr": "Rubielos de Mora"
            },
            {
                "value": 53699,
                "label": "Castellar de la Frontera",
                "label_ar": "كاستيلار دي لا فرونتيرا",
                "label_fr": "Castellar de la Frontera"
            },
            {
                "value": 52986,
                "label": "Pals",
                "label_ar": "الزملاء",
                "label_fr": "Copains"
            },
            {
                "value": 52514,
                "label": "Écija",
                "label_ar": "Écija",
                "label_fr": "Écija"
            },
            {
                "value": 52482,
                "label": "Liencres",
                "label_ar": "لينكرس",
                "label_fr": "Liencres"
            },
            {
                "value": 52819,
                "label": "Turia",
                "label_ar": "توريا",
                "label_fr": "Turia"
            },
            {
                "value": 53638,
                "label": "Montfulla",
                "label_ar": "مونتفولا",
                "label_fr": "Montfulla"
            },
            {
                "value": 54031,
                "label": "Caudete",
                "label_ar": "كاوديت",
                "label_fr": "Caudete"
            },
            {
                "value": 52581,
                "label": "Teo",
                "label_ar": "تيو",
                "label_fr": "Teo"
            },
            {
                "value": 53827,
                "label": "Morata de Tajuna",
                "label_ar": "موراتا دي تاجونا",
                "label_fr": "Morata de Tajuna"
            },
            {
                "value": 51906,
                "label": "Riola",
                "label_ar": "ريولا",
                "label_fr": "Riola"
            },
            {
                "value": 52569,
                "label": "Estepa",
                "label_ar": "إستيبا",
                "label_fr": "Estepa"
            },
            {
                "value": 52586,
                "label": "Brion",
                "label_ar": "بريون",
                "label_fr": "Brion"
            },
            {
                "value": 53544,
                "label": "Sant Andreu de Llavaneres",
                "label_ar": "سانت أندرو دي لافانيريس",
                "label_fr": "Sant Andreu de Llavaneres"
            },
            {
                "value": 52086,
                "label": "Torremolinos",
                "label_ar": "توريمولينوس",
                "label_fr": "Torremolinos"
            },
            {
                "value": 54190,
                "label": "Hinojedo",
                "label_ar": "هينوجيدو",
                "label_fr": "Hinojedo"
            },
            {
                "value": 53166,
                "label": "Aguilar del Rio Alhama",
                "label_ar": "Aguilar del Rio Alhama",
                "label_fr": "Aguilar del Rio Alhama"
            },
            {
                "value": 53642,
                "label": "Martinet",
                "label_ar": "مارتينيت",
                "label_fr": "Martinet"
            },
            {
                "value": 51981,
                "label": "Torrejon de la Calzada",
                "label_ar": "توريخون دي لا كالزادا",
                "label_fr": "Torrejon de la Calzada"
            },
            {
                "value": 52937,
                "label": "El Barco de Avila",
                "label_ar": "إل باركو دي أفيلا",
                "label_fr": "El Barco de Avila"
            },
            {
                "value": 53920,
                "label": "Pinar",
                "label_ar": "بينار",
                "label_fr": "Pinar"
            },
            {
                "value": 53485,
                "label": "Villanueva de la Torre",
                "label_ar": "فيلانويفا دي لا توري",
                "label_fr": "Villanueva de la Torre"
            },
            {
                "value": 53480,
                "label": "Alegria",
                "label_ar": "اليجريا",
                "label_fr": "Alegria"
            },
            {
                "value": 53200,
                "label": "Portus",
                "label_ar": "بورتوس",
                "label_fr": "Portus"
            },
            {
                "value": 53845,
                "label": "Corral de Almaguer",
                "label_ar": "كورال دي الماغير",
                "label_fr": "Corral de Almaguer"
            },
            {
                "value": 53461,
                "label": "Palos de la Frontera",
                "label_ar": "بالوس دي لا فرونتيرا",
                "label_fr": "Palos de la Frontera"
            },
            {
                "value": 53429,
                "label": "Orpesa/Oropesa del Mar",
                "label_ar": "أوربيسا / أوروبيسا ديل مار",
                "label_fr": "Orpesa / Oropesa del Mar"
            },
            {
                "value": 52010,
                "label": "Telde",
                "label_ar": "تيلدي",
                "label_fr": "Telde"
            },
            {
                "value": 52993,
                "label": "El Medano",
                "label_ar": "الميدانو",
                "label_fr": "El Medano"
            },
            {
                "value": 52216,
                "label": "El Escorial",
                "label_ar": "الاسكوريال",
                "label_fr": "El Escorial"
            },
            {
                "value": 53967,
                "label": "Montefrio",
                "label_ar": "مونتيفريو",
                "label_fr": "Montefrio"
            },
            {
                "value": 54244,
                "label": "Can Picafort",
                "label_ar": "يمكن بيكافورت",
                "label_fr": "Can Picafort"
            },
            {
                "value": 52625,
                "label": "Sant Andreu de la Barca",
                "label_ar": "سانت أندرو دي لا باركا",
                "label_fr": "Sant Andreu de la Barca"
            },
            {
                "value": 53840,
                "label": "Laudio-Llodio",
                "label_ar": "لوديو لوديو",
                "label_fr": "Laudio-Llodio"
            },
            {
                "value": 52259,
                "label": "Premia de Mar",
                "label_ar": "بريميا دي مار",
                "label_fr": "Premia de Mar"
            },
            {
                "value": 53645,
                "label": "Selaya",
                "label_ar": "سيلايا",
                "label_fr": "Selaya"
            },
            {
                "value": 53562,
                "label": "Vilavella",
                "label_ar": "فيلافيلا",
                "label_fr": "Vilavella"
            },
            {
                "value": 53656,
                "label": "Valdezufre",
                "label_ar": "فالديزوفر",
                "label_fr": "Valdezufre"
            },
            {
                "value": 53096,
                "label": "Torrecaballeros",
                "label_ar": "Torrecaballeros",
                "label_fr": "Torrecaballeros"
            },
            {
                "value": 53270,
                "label": "Navia",
                "label_ar": "نافيا",
                "label_fr": "Navia"
            },
            {
                "value": 53571,
                "label": "Orio",
                "label_ar": "أوريو",
                "label_fr": "Orio"
            },
            {
                "value": 53972,
                "label": "Benahadux",
                "label_ar": "بيناهدوكس",
                "label_fr": "Benahadux"
            },
            {
                "value": 52511,
                "label": "Casabermeja",
                "label_ar": "كازابرميخا",
                "label_fr": "Casabermeja"
            },
            {
                "value": 52756,
                "label": "Firgas",
                "label_ar": "فيرغاس",
                "label_fr": "Firgas"
            },
            {
                "value": 53974,
                "label": "Albelda de Iregua",
                "label_ar": "ألبيلدا دي إيريغوا",
                "label_fr": "Albelda de Iregua"
            },
            {
                "value": 52687,
                "label": "Alcalá de Guadaira",
                "label_ar": "الكالا دي غواديرا",
                "label_fr": "Alcalá de Guadaira"
            },
            {
                "value": 54367,
                "label": "Miguel Esteban",
                "label_ar": "ميغيل إستيبان",
                "label_fr": "Miguel Esteban"
            },
            {
                "value": 52859,
                "label": "Mestanza",
                "label_ar": "ميستانزا",
                "label_fr": "Mestanza"
            },
            {
                "value": 53781,
                "label": "Puebla del Maestre",
                "label_ar": "بويبلا ديل مايستر",
                "label_fr": "Puebla del Maestre"
            },
            {
                "value": 52479,
                "label": "Limpias",
                "label_ar": "ليمبيا",
                "label_fr": "Limpias"
            },
            {
                "value": 52400,
                "label": "Albacete",
                "label_ar": "البسيط",
                "label_fr": "Albacete"
            },
            {
                "value": 54486,
                "label": "Borja",
                "label_ar": "بورجا",
                "label_fr": "Borja"
            },
            {
                "value": 54090,
                "label": "Ribadesella",
                "label_ar": "ريباديسيا",
                "label_fr": "Ribadesella"
            },
            {
                "value": 53565,
                "label": "La Mancha Blanca",
                "label_ar": "لا مانشا بلانكا",
                "label_fr": "La Mancha Blanca"
            },
            {
                "value": 53509,
                "label": "El Corrillo",
                "label_ar": "إل كوريللو",
                "label_fr": "El Corrillo"
            },
            {
                "value": 52826,
                "label": "Salvatierra de los Barros",
                "label_ar": "سالفاتيرا دي لوس باروس",
                "label_fr": "Salvatierra de los Barros"
            },
            {
                "value": 52327,
                "label": "Alzira",
                "label_ar": "الجزيرة",
                "label_fr": "Alzira"
            },
            {
                "value": 52110,
                "label": "Zamora",
                "label_ar": "زامورا",
                "label_fr": "Zamora"
            },
            {
                "value": 52461,
                "label": "San Juan de Aznalfarache",
                "label_ar": "سان خوان دي أزنالفاراتش",
                "label_fr": "San Juan de Aznalfarache"
            },
            {
                "value": 53707,
                "label": "Getaria",
                "label_ar": "جيتاريا",
                "label_fr": "Getaria"
            },
            {
                "value": 54469,
                "label": "Villalonga",
                "label_ar": "فيلالونجا",
                "label_fr": "Villalonga"
            },
            {
                "value": 52419,
                "label": "Villarrobledo",
                "label_ar": "فيلاروبليدو",
                "label_fr": "Villarrobledo"
            },
            {
                "value": 52927,
                "label": "La Cavada",
                "label_ar": "لا كافادا",
                "label_fr": "La Cavada"
            },
            {
                "value": 54098,
                "label": "Siete Iglesias de Trabancos",
                "label_ar": "Siete Iglesias de Trabancos",
                "label_fr": "Siete Iglesias de Trabancos"
            },
            {
                "value": 53486,
                "label": "Sabero",
                "label_ar": "سابيرو",
                "label_fr": "Sabero"
            },
            {
                "value": 53876,
                "label": "Fuensanta de Martos",
                "label_ar": "فوينسانتا دي مارتوس",
                "label_fr": "Fuensanta de Martos"
            },
            {
                "value": 53824,
                "label": "Villamartin",
                "label_ar": "فيلامارتين",
                "label_fr": "Villamartin"
            },
            {
                "value": 52000,
                "label": "San Martin de Valdeiglesias",
                "label_ar": "سان مارتن دي فالديجليسياس",
                "label_fr": "San Martin de Valdeiglesias"
            },
            {
                "value": 54392,
                "label": "La Canalosa",
                "label_ar": "لا كانالوسا",
                "label_fr": "La Canalosa"
            },
            {
                "value": 54288,
                "label": "Torrefeta",
                "label_ar": "توريفيتا",
                "label_fr": "Torrefeta"
            },
            {
                "value": 53361,
                "label": "Orellana la Vieja",
                "label_ar": "Orellana la Vieja",
                "label_fr": "Orellana la Vieja"
            },
            {
                "value": 53497,
                "label": "Algadefe",
                "label_ar": "الجادف",
                "label_fr": "Algadefe"
            },
            {
                "value": 52829,
                "label": "Reyes",
                "label_ar": "رييس",
                "label_fr": "Reyes"
            },
            {
                "value": 53091,
                "label": "Peligros",
                "label_ar": "بيليجروس",
                "label_fr": "Peligros"
            },
            {
                "value": 52439,
                "label": "Avilés",
                "label_ar": "أفيليس",
                "label_fr": "Avilés"
            },
            {
                "value": 54455,
                "label": "Bigastro",
                "label_ar": "بيجاسترو",
                "label_fr": "Bigastro"
            },
            {
                "value": 54219,
                "label": "Campdevànol",
                "label_ar": "كامبديفانول",
                "label_fr": "Campdevànol"
            },
            {
                "value": 54380,
                "label": "Soto de Cerrato",
                "label_ar": "سوتو دي سيراتو",
                "label_fr": "Soto de Cerrato"
            },
            {
                "value": 54298,
                "label": "A Pobra do Caraminal",
                "label_ar": "A Pobra do Caraminal",
                "label_fr": "Un Pobra do Caraminal"
            },
            {
                "value": 53532,
                "label": "Torrecilla de Alcaniz",
                "label_ar": "توريسيلا دي الكانيز",
                "label_fr": "Torrecilla de Alcaniz"
            },
            {
                "value": 53259,
                "label": "Ayamonte",
                "label_ar": "أيامونتي",
                "label_fr": "Ayamonte"
            },
            {
                "value": 52932,
                "label": "San Sebastian de los Ballesteros",
                "label_ar": "سان سيباستيان دي لوس باليستيروس",
                "label_fr": "San Sebastian de los Ballesteros"
            },
            {
                "value": 54264,
                "label": "Cartelle",
                "label_ar": "كارتيل",
                "label_fr": "Cartelle"
            },
            {
                "value": 52853,
                "label": "Beniganim",
                "label_ar": "بنيجانيم",
                "label_fr": "Beniganim"
            },
            {
                "value": 54252,
                "label": "Arrubal",
                "label_ar": "اروبال",
                "label_fr": "Arrubal"
            },
            {
                "value": 53932,
                "label": "Mosqueruela",
                "label_ar": "موسكيريلا",
                "label_fr": "Mosqueruela"
            },
            {
                "value": 53979,
                "label": "El Arahal",
                "label_ar": "الارحال",
                "label_fr": "El Arahal"
            },
            {
                "value": 53676,
                "label": "Almonacid de Zorita",
                "label_ar": "الموناسيد دي زوريتا",
                "label_fr": "Almonacid de Zorita"
            },
            {
                "value": 52389,
                "label": "Durango",
                "label_ar": "دورانجو",
                "label_fr": "Durango"
            },
            {
                "value": 52330,
                "label": "Atarfe",
                "label_ar": "أتارف",
                "label_fr": "Atarfe"
            },
            {
                "value": 53089,
                "label": "Villanueva de la Serena",
                "label_ar": "فيلانويفا دي لا سيرينا",
                "label_fr": "Villanueva de la Serena"
            },
            {
                "value": 54039,
                "label": "Daimiel",
                "label_ar": "ديميئيل",
                "label_fr": "Daimiel"
            },
            {
                "value": 53809,
                "label": "Argonos",
                "label_ar": "أرغونوس",
                "label_fr": "Argonos"
            },
            {
                "value": 53409,
                "label": "Mondariz",
                "label_ar": "مونداريز",
                "label_fr": "Mondariz"
            },
            {
                "value": 53164,
                "label": "Velez de Benaudalla",
                "label_ar": "فيليز دي بينودالا",
                "label_fr": "Velez de Benaudalla"
            },
            {
                "value": 53540,
                "label": "Camas",
                "label_ar": "كاماس",
                "label_fr": "Camas"
            },
            {
                "value": 52495,
                "label": "Calvià",
                "label_ar": "كالفيا",
                "label_fr": "Calvià"
            },
            {
                "value": 54470,
                "label": "Espolla",
                "label_ar": "اسبولا",
                "label_fr": "Espolla"
            },
            {
                "value": 52478,
                "label": "Torrelavega",
                "label_ar": "توريلافيجا",
                "label_fr": "Torrelavega"
            },
            {
                "value": 51917,
                "label": "Sant Just Desvern",
                "label_ar": "سانت جوست ديسفرن",
                "label_fr": "Sant Just Desvern"
            },
            {
                "value": 53058,
                "label": "Casas del Monte",
                "label_ar": "كاساس ديل مونتي",
                "label_fr": "Casas del Monte"
            },
            {
                "value": 53927,
                "label": "Villaseca de la Sagra",
                "label_ar": "فيلاسيكا دي لا ساجرا",
                "label_fr": "Villaseca de la Sagra"
            },
            {
                "value": 52618,
                "label": "Camargo",
                "label_ar": "كامارجو",
                "label_fr": "Camargo"
            },
            {
                "value": 51967,
                "label": "Santa Coloma de Queralt",
                "label_ar": "سانتا كولوما دي كيرالت",
                "label_fr": "Santa Coloma de Queralt"
            },
            {
                "value": 52715,
                "label": "Caravaca",
                "label_ar": "كارافاكا",
                "label_fr": "Caravaca"
            },
            {
                "value": 53603,
                "label": "Barranda",
                "label_ar": "باراندا",
                "label_fr": "Barranda"
            },
            {
                "value": 53523,
                "label": "Paradela",
                "label_ar": "باراديلا",
                "label_fr": "Paradela"
            },
            {
                "value": 53483,
                "label": "Mercadal",
                "label_ar": "ميركادال",
                "label_fr": "Mercadal"
            },
            {
                "value": 52619,
                "label": "Pradejon",
                "label_ar": "برايجون",
                "label_fr": "Pradejon"
            },
            {
                "value": 52864,
                "label": "Villalba de los Alcores",
                "label_ar": "فيلالبا دي لوس ألكوريس",
                "label_fr": "Villalba de los Alcores"
            },
            {
                "value": 54168,
                "label": "Calahonda",
                "label_ar": "كالاهوندا",
                "label_fr": "Calahonda"
            },
            {
                "value": 52135,
                "label": "Torelló",
                "label_ar": "توريلو",
                "label_fr": "Torelló"
            },
            {
                "value": 53240,
                "label": "La Adrada",
                "label_ar": "لا أدرادا",
                "label_fr": "La Adrada"
            },
            {
                "value": 54461,
                "label": "Aroche",
                "label_ar": "أروشي",
                "label_fr": "Aroche"
            },
            {
                "value": 54054,
                "label": "Venturada",
                "label_ar": "فينتورادا",
                "label_fr": "Venturada"
            },
            {
                "value": 53045,
                "label": "Rivabellosa",
                "label_ar": "ريفابيلوزا",
                "label_fr": "Rivabellosa"
            },
            {
                "value": 52332,
                "label": "Oasis (La Marina)",
                "label_ar": "الواحة (لا مارينا)",
                "label_fr": "Oasis (La Marina)"
            },
            {
                "value": 54104,
                "label": "Garrigas",
                "label_ar": "جاريجاس",
                "label_fr": "Garrigas"
            },
            {
                "value": 52900,
                "label": "Arnedo",
                "label_ar": "ارنيدو",
                "label_fr": "Arnedo"
            },
            {
                "value": 54088,
                "label": "Berron",
                "label_ar": "بيرون",
                "label_fr": "Berron"
            },
            {
                "value": 53455,
                "label": "Diezma",
                "label_ar": "ديزما",
                "label_fr": "Diezma"
            },
            {
                "value": 53191,
                "label": "Casas del Castanar",
                "label_ar": "كاساس ديل كاستانار",
                "label_fr": "Casas del Castanar"
            },
            {
                "value": 53342,
                "label": "La Pobla de Claramunt",
                "label_ar": "لا بوبلا دي كلارامونت",
                "label_fr": "La Pobla de Claramunt"
            },
            {
                "value": 53168,
                "label": "Herrera de Pisuerga",
                "label_ar": "هيريرا دي بيسويرغا",
                "label_fr": "Herrera de Pisuerga"
            },
            {
                "value": 52720,
                "label": "San Andres del Rabanedo",
                "label_ar": "سان أندريس ديل رابانيدو",
                "label_fr": "San Andres del Rabanedo"
            },
            {
                "value": 53924,
                "label": "Jacarilla",
                "label_ar": "جاكاريلا",
                "label_fr": "Jacarilla"
            },
            {
                "value": 53434,
                "label": "Igueste",
                "label_ar": "إيغويستي",
                "label_fr": "Igueste"
            },
            {
                "value": 52491,
                "label": "Entrambasaguas",
                "label_ar": "Entrambasaguas",
                "label_fr": "Entrambasaguas"
            },
            {
                "value": 53595,
                "label": "Rincon",
                "label_ar": "رينكون",
                "label_fr": "Rincon"
            },
            {
                "value": 52699,
                "label": "Moguer",
                "label_ar": "موغير",
                "label_fr": "Moguer"
            },
            {
                "value": 52401,
                "label": "Hoya-Gonzalo",
                "label_ar": "هويا جونزالو",
                "label_fr": "Hoya-Gonzalo"
            },
            {
                "value": 53988,
                "label": "Touro",
                "label_ar": "تورو",
                "label_fr": "Touro"
            },
            {
                "value": 51921,
                "label": "Madrid",
                "label_ar": "مدريد",
                "label_fr": "Madrid"
            },
            {
                "value": 53444,
                "label": "Cati",
                "label_ar": "كاتي",
                "label_fr": "Cati"
            },
            {
                "value": 53132,
                "label": "Tormantos",
                "label_ar": "تورمانتوس",
                "label_fr": "Tormantos"
            },
            {
                "value": 52274,
                "label": "Alcantarilla",
                "label_ar": "الكانتريلا",
                "label_fr": "Alcantarilla"
            },
            {
                "value": 53881,
                "label": "Vitigudino",
                "label_ar": "فيتيجودين",
                "label_fr": "Vitigudino"
            },
            {
                "value": 53005,
                "label": "Urroz",
                "label_ar": "أوروز",
                "label_fr": "Urroz"
            },
            {
                "value": 52844,
                "label": "Fraga",
                "label_ar": "فراجا",
                "label_fr": "Fraga"
            },
            {
                "value": 53213,
                "label": "Masdenverge",
                "label_ar": "مازنفيرج",
                "label_fr": "Masdenverge"
            },
            {
                "value": 53785,
                "label": "Camos",
                "label_ar": "كاموس",
                "label_fr": "Camos"
            },
            {
                "value": 53150,
                "label": "Candas",
                "label_ar": "كاندا",
                "label_fr": "Candas"
            },
            {
                "value": 53675,
                "label": "Calders",
                "label_ar": "كالدرز",
                "label_fr": "Calders"
            },
            {
                "value": 54177,
                "label": "Gautegiz Arteaga",
                "label_ar": "Gautegiz Arteaga",
                "label_fr": "Gautegiz Arteaga"
            },
            {
                "value": 53241,
                "label": "La Guardia",
                "label_ar": "لاغوارديا",
                "label_fr": "La Guardia"
            },
            {
                "value": 53846,
                "label": "Hostalric",
                "label_ar": "هوستالريك",
                "label_fr": "Hostalric"
            },
            {
                "value": 53092,
                "label": "Frigiliana",
                "label_ar": "فريجيليانا",
                "label_fr": "Frigiliana"
            },
            {
                "value": 52917,
                "label": "Mocejon",
                "label_ar": "Mocejon",
                "label_fr": "Mocejon"
            },
            {
                "value": 53415,
                "label": "Ribera del Fresno",
                "label_ar": "ريبيرا ديل فريسنو",
                "label_fr": "Ribera del Fresno"
            },
            {
                "value": 52544,
                "label": "Eibar",
                "label_ar": "إيبار",
                "label_fr": "Eibar"
            },
            {
                "value": 52918,
                "label": "Puerto de Gandia",
                "label_ar": "بويرتو دي جانديا",
                "label_fr": "Puerto de Gandia"
            },
            {
                "value": 52191,
                "label": "Los Alcazares",
                "label_ar": "لوس الكازاريس",
                "label_fr": "Los Alcazares"
            },
            {
                "value": 53451,
                "label": "Teror",
                "label_ar": "تيرور",
                "label_fr": "Teror"
            },
            {
                "value": 53953,
                "label": "Collado",
                "label_ar": "كولادو",
                "label_fr": "Collado"
            },
            {
                "value": 53327,
                "label": "Neda",
                "label_ar": "ندى",
                "label_fr": "Neda"
            },
            {
                "value": 52599,
                "label": "Trives",
                "label_ar": "تريفز",
                "label_fr": "Trives"
            },
            {
                "value": 52148,
                "label": "Betera",
                "label_ar": "Betera",
                "label_fr": "Betera"
            },
            {
                "value": 53463,
                "label": "Benatae",
                "label_ar": "بناتي",
                "label_fr": "Benatae"
            },
            {
                "value": 54471,
                "label": "Lousame",
                "label_ar": "Lousame",
                "label_fr": "Lousame"
            },
            {
                "value": 52653,
                "label": "Salceda",
                "label_ar": "سالسيدا",
                "label_fr": "Salceda"
            },
            {
                "value": 52246,
                "label": "Calella",
                "label_ar": "كاليلا",
                "label_fr": "Calella"
            },
            {
                "value": 53940,
                "label": "Burguillos",
                "label_ar": "بورغيلوس",
                "label_fr": "Burguillos"
            },
            {
                "value": 53516,
                "label": "Santa Cruz de la Zarza",
                "label_ar": "سانتا كروز دي لا زارزا",
                "label_fr": "Santa Cruz de la Zarza"
            },
            {
                "value": 53223,
                "label": "Guadiana del Caudillo",
                "label_ar": "Guadiana del Caudillo",
                "label_fr": "Guadiana del Caudillo"
            },
            {
                "value": 53344,
                "label": "Camponaraya",
                "label_ar": "كامبونارايا",
                "label_fr": "Camponaraya"
            },
            {
                "value": 53320,
                "label": "Casarrubios del Monte",
                "label_ar": "كاساروبيوس ديل مونتي",
                "label_fr": "Casarrubios del Monte"
            },
            {
                "value": 52231,
                "label": "Ames",
                "label_ar": "أميس",
                "label_fr": "Ames"
            },
            {
                "value": 52373,
                "label": "Legazpia",
                "label_ar": "ليغازبيا",
                "label_fr": "Legazpia"
            },
            {
                "value": 53482,
                "label": "Villamayor de Santiago",
                "label_ar": "فيلامايور دي سانتياغو",
                "label_fr": "Villamayor de Santiago"
            },
            {
                "value": 52062,
                "label": "Jaén",
                "label_ar": "جيان",
                "label_fr": "Jaén"
            },
            {
                "value": 52473,
                "label": "Churriana de la Vega",
                "label_ar": "تشوريانا دي لا فيجا",
                "label_fr": "Churriana de la Vega"
            },
            {
                "value": 54208,
                "label": "Ruente",
                "label_ar": "روينت",
                "label_fr": "Ruente"
            },
            {
                "value": 52995,
                "label": "Finestrat",
                "label_ar": "فينسترات",
                "label_fr": "Finestrat"
            },
            {
                "value": 53997,
                "label": "Abadino",
                "label_ar": "أبدينو",
                "label_fr": "Abadino"
            },
            {
                "value": 53959,
                "label": "La Frontera",
                "label_ar": "لا فرونتيرا",
                "label_fr": "La Frontera"
            },
            {
                "value": 53895,
                "label": "Bollullos par del Condado",
                "label_ar": "Bollullos par del Condado",
                "label_fr": "Bollullos par del Condado"
            },
            {
                "value": 53391,
                "label": "Mallen",
                "label_ar": "مالين",
                "label_fr": "Mallen"
            },
            {
                "value": 53754,
                "label": "Penaflor",
                "label_ar": "بينافلور",
                "label_fr": "Penaflor"
            },
            {
                "value": 53520,
                "label": "Santa Eugènia de Berga",
                "label_ar": "سانتا يوجينيا دي بيرجا",
                "label_fr": "Santa Eugènia de Berga"
            },
            {
                "value": 52710,
                "label": "Fuentes de Andalucia",
                "label_ar": "فوينتيس دي الأندلس",
                "label_fr": "Fuentes de Andalucia"
            },
            {
                "value": 53196,
                "label": "Manzanares el Real",
                "label_ar": "Manzanares el Real",
                "label_fr": "Manzanares el Real"
            },
            {
                "value": 53393,
                "label": "Quincoces de Yuso",
                "label_ar": "Quincoces de Yuso",
                "label_fr": "Quincoces de Yuso"
            },
            {
                "value": 54004,
                "label": "La Victoria",
                "label_ar": "لا فيكتوريا",
                "label_fr": "La Victoria"
            },
            {
                "value": 53006,
                "label": "El Vellon",
                "label_ar": "إل فيلون",
                "label_fr": "El Vellon"
            },
            {
                "value": 53476,
                "label": "Cangas del Narcea",
                "label_ar": "كانغاس ديل نارسيا",
                "label_fr": "Cangas del Narcea"
            },
            {
                "value": 52600,
                "label": "Caion",
                "label_ar": "كايون",
                "label_fr": "Caion"
            },
            {
                "value": 52145,
                "label": "Alginet",
                "label_ar": "الجينيت",
                "label_fr": "Alginet"
            },
            {
                "value": 52976,
                "label": "Yepes",
                "label_ar": "نعم",
                "label_fr": "Oui"
            },
            {
                "value": 53946,
                "label": "Bembibre",
                "label_ar": "بيمبيبري",
                "label_fr": "Bembibre"
            },
            {
                "value": 52707,
                "label": "Teulada",
                "label_ar": "تيولادا",
                "label_fr": "Teulada"
            },
            {
                "value": 52657,
                "label": "Huetor Vega",
                "label_ar": "هويتور فيجا",
                "label_fr": "Huetor Vega"
            },
            {
                "value": 52477,
                "label": "Vaciamadrid",
                "label_ar": "فاسيامادريد",
                "label_fr": "Vaciamadrid"
            },
            {
                "value": 53586,
                "label": "Aduna",
                "label_ar": "أدونا",
                "label_fr": "Aduna"
            },
            {
                "value": 52545,
                "label": "Ondarroa",
                "label_ar": "أونداروا",
                "label_fr": "Ondarroa"
            },
            {
                "value": 52761,
                "label": "Canet de Mar",
                "label_ar": "كانيه دي مار",
                "label_fr": "Canet de Mar"
            },
            {
                "value": 54332,
                "label": "Santa Eulàlia de Riuprimer",
                "label_ar": "سانتا يوليا دي ريوبريمير",
                "label_fr": "Santa Eulàlia de Riuprimer"
            },
            {
                "value": 51920,
                "label": "Castelldefels",
                "label_ar": "كاستيلديفيلس",
                "label_fr": "Castelldefels"
            },
            {
                "value": 54083,
                "label": "Landa",
                "label_ar": "لاندا",
                "label_fr": "Landa"
            },
            {
                "value": 52192,
                "label": "Pilar de la Horadada",
                "label_ar": "بيلار دي لا هورادادا",
                "label_fr": "Pilar de la Horadada"
            },
            {
                "value": 54357,
                "label": "Oza de los Rios",
                "label_ar": "اوزا دي لوس ريوس",
                "label_fr": "Oza de los Rios"
            },
            {
                "value": 54196,
                "label": "Calaf",
                "label_ar": "كالاف",
                "label_fr": "Calaf"
            },
            {
                "value": 53372,
                "label": "Sueca",
                "label_ar": "سويكا",
                "label_fr": "Sueca"
            },
            {
                "value": 52006,
                "label": "Gelida",
                "label_ar": "جيليدا",
                "label_fr": "Gelida"
            },
            {
                "value": 53253,
                "label": "Huelma",
                "label_ar": "هولما",
                "label_fr": "Huelma"
            },
            {
                "value": 53717,
                "label": "Lobios",
                "label_ar": "لوبيوس",
                "label_fr": "Lobios"
            },
            {
                "value": 53180,
                "label": "Alberite",
                "label_ar": "البيريت",
                "label_fr": "Alberite"
            },
            {
                "value": 53700,
                "label": "Mombeltran",
                "label_ar": "مومبلتران",
                "label_fr": "Mombeltran"
            },
            {
                "value": 52034,
                "label": "Arboleas",
                "label_ar": "أربوليس",
                "label_fr": "Arboleas"
            },
            {
                "value": 52909,
                "label": "Cartaya",
                "label_ar": "كارتايا",
                "label_fr": "Cartaya"
            },
            {
                "value": 52385,
                "label": "Santpedor",
                "label_ar": "سانتبيدور",
                "label_fr": "Santpedor"
            },
            {
                "value": 51912,
                "label": "l'Hospitalet de l'Infant",
                "label_ar": "l'Hospitalet de l'Infant",
                "label_fr": "l'Hospitalet de l'Infant"
            },
            {
                "value": 54245,
                "label": "San Juan",
                "label_ar": "سان خوان",
                "label_fr": "San Juan"
            },
            {
                "value": 51976,
                "label": "Villanueva del Pardillo",
                "label_ar": "فيلانويفا ديل بارديلو",
                "label_fr": "Villanueva del Pardillo"
            },
            {
                "value": 53082,
                "label": "Santa Barbara",
                "label_ar": "سانتا باربارا",
                "label_fr": "Santa Barbara"
            },
            {
                "value": 54128,
                "label": "Gibraleón",
                "label_ar": "جبريلاليون",
                "label_fr": "Gibraleón"
            },
            {
                "value": 54478,
                "label": "Ziga",
                "label_ar": "زيغا",
                "label_fr": "Ziga"
            },
            {
                "value": 53631,
                "label": "Martin",
                "label_ar": "مارتن",
                "label_fr": "Martin"
            },
            {
                "value": 52490,
                "label": "Liano",
                "label_ar": "ليانو",
                "label_fr": "Liano"
            },
            {
                "value": 52627,
                "label": "Santa Coloma de Gramenet",
                "label_ar": "سانتا كولوما دي جرامينت",
                "label_fr": "Santa Coloma de Gramenet"
            },
            {
                "value": 54387,
                "label": "Campins",
                "label_ar": "كامبينس",
                "label_fr": "Campins"
            },
            {
                "value": 53105,
                "label": "Santa Oliva",
                "label_ar": "سانتا أوليفا",
                "label_fr": "Santa Oliva"
            },
            {
                "value": 53654,
                "label": "Andeiro",
                "label_ar": "أنديرو",
                "label_fr": "Andeiro"
            },
            {
                "value": 52834,
                "label": "Riells i Viabrea",
                "label_ar": "ريلس أنا فيابريا",
                "label_fr": "Riells i Viabrea"
            },
            {
                "value": 52896,
                "label": "Santisteban del Puerto",
                "label_ar": "سانتيستيبان ديل بويرتو",
                "label_fr": "Santisteban del Puerto"
            },
            {
                "value": 53570,
                "label": "Anoeta",
                "label_ar": "أنويتا",
                "label_fr": "Anoeta"
            },
            {
                "value": 53003,
                "label": "Villar de Olalla",
                "label_ar": "فيلار دي أولالا",
                "label_fr": "Villar de Olalla"
            },
            {
                "value": 52923,
                "label": "Villafranca de Cordoba",
                "label_ar": "فيلافرانكا دي قرطبة",
                "label_fr": "Villafranca de Cordoba"
            },
            {
                "value": 54246,
                "label": "Galizano",
                "label_ar": "جاليزانو",
                "label_fr": "Galizano"
            },
            {
                "value": 52013,
                "label": "Alta",
                "label_ar": "ألتا",
                "label_fr": "Alta"
            },
            {
                "value": 52262,
                "label": "Ripoll",
                "label_ar": "ريبول",
                "label_fr": "Ripoll"
            },
            {
                "value": 54453,
                "label": "Teba",
                "label_ar": "طيبة",
                "label_fr": "Teba"
            },
            {
                "value": 52887,
                "label": "Ibarra",
                "label_ar": "ايبارا",
                "label_fr": "Ibarra"
            },
            {
                "value": 52897,
                "label": "Ibros",
                "label_ar": "إبروس",
                "label_fr": "Ibros"
            },
            {
                "value": 53137,
                "label": "Almensilla",
                "label_ar": "المينسيلا",
                "label_fr": "Almensilla"
            },
            {
                "value": 53545,
                "label": "Fornells de la Selva",
                "label_ar": "فورنيلس دي لا سيلفا",
                "label_fr": "Fornells de la Selva"
            },
            {
                "value": 54139,
                "label": "Pescador",
                "label_ar": "بيسكادور",
                "label_fr": "Pescador"
            },
            {
                "value": 52193,
                "label": "Almoradi",
                "label_ar": "المرادي",
                "label_fr": "Almoradi"
            },
            {
                "value": 51973,
                "label": "Villafranca",
                "label_ar": "فيلافرانكا",
                "label_fr": "Villafranca"
            },
            {
                "value": 52725,
                "label": "Alberic",
                "label_ar": "ألبيريك",
                "label_fr": "Albérique"
            },
            {
                "value": 52099,
                "label": "La Vera",
                "label_ar": "لا فيرا",
                "label_fr": "La Vera"
            },
            {
                "value": 52638,
                "label": "Pau",
                "label_ar": "باو",
                "label_fr": "Pau"
            },
            {
                "value": 54146,
                "label": "Valderrubio",
                "label_ar": "فالديروبيو",
                "label_fr": "Valderrubio"
            },
            {
                "value": 54291,
                "label": "Arnuero",
                "label_ar": "ارنويرو",
                "label_fr": "Arnuero"
            },
            {
                "value": 54033,
                "label": "Valdecilla",
                "label_ar": "فالديسيلا",
                "label_fr": "Valdecilla"
            },
            {
                "value": 54277,
                "label": "Para",
                "label_ar": "الفقرة",
                "label_fr": "Para"
            },
            {
                "value": 53018,
                "label": "Arbizu",
                "label_ar": "Arbizu",
                "label_fr": "Arbizu"
            },
            {
                "value": 53541,
                "label": "Muros",
                "label_ar": "موروس",
                "label_fr": "Muros"
            },
            {
                "value": 52361,
                "label": "Mogente",
                "label_ar": "موغينتي",
                "label_fr": "Mogente"
            },
            {
                "value": 54322,
                "label": "Celeiros",
                "label_ar": "سيليروس",
                "label_fr": "Celeiros"
            },
            {
                "value": 52341,
                "label": "Archena",
                "label_ar": "أرشينا",
                "label_fr": "Archena"
            },
            {
                "value": 52197,
                "label": "Cullera",
                "label_ar": "كوليرا",
                "label_fr": "Cullera"
            },
            {
                "value": 52283,
                "label": "Vilanova i la Geltrú",
                "label_ar": "فيلانوفا إي لا جيلترو",
                "label_fr": "Vilanova i la Geltrú"
            },
            {
                "value": 53338,
                "label": "Tafalla",
                "label_ar": "تافلا",
                "label_fr": "Tafalla"
            },
            {
                "value": 54325,
                "label": "Lijar",
                "label_ar": "ليجار",
                "label_fr": "Lijar"
            },
            {
                "value": 53022,
                "label": "El Molar",
                "label_ar": "المولار",
                "label_fr": "El Molar"
            },
            {
                "value": 54468,
                "label": "La Floresta Pearson",
                "label_ar": "لا فلوريستا بيرسون",
                "label_fr": "La Floresta Pearson"
            },
            {
                "value": 53590,
                "label": "Santa Maria del Paramo",
                "label_ar": "سانتا ماريا ديل بارامو",
                "label_fr": "Santa Maria del Paramo"
            },
            {
                "value": 52772,
                "label": "Alcanar",
                "label_ar": "الكنار",
                "label_fr": "Alcanar"
            },
            {
                "value": 53152,
                "label": "Galapagos",
                "label_ar": "غالاباغوس",
                "label_fr": "Galapagos"
            },
            {
                "value": 54366,
                "label": "Villanueva de la Jara",
                "label_ar": "فيلانويفا دي لا جارا",
                "label_fr": "Villanueva de la Jara"
            },
            {
                "value": 53430,
                "label": "Abaran",
                "label_ar": "أباران",
                "label_fr": "Abaran"
            },
            {
                "value": 52309,
                "label": "San Javier",
                "label_ar": "سان خافيير",
                "label_fr": "San Javier"
            },
            {
                "value": 52589,
                "label": "Betanzos",
                "label_ar": "بيتانزوس",
                "label_fr": "Betanzos"
            },
            {
                "value": 52658,
                "label": "Maracena",
                "label_ar": "ماراسينا",
                "label_fr": "Maracena"
            },
            {
                "value": 54021,
                "label": "La Nora",
                "label_ar": "لا نورا",
                "label_fr": "La Nora"
            },
            {
                "value": 53034,
                "label": "Villa del Prado",
                "label_ar": "فيلا ديل برادو",
                "label_fr": "Villa del Prado"
            },
            {
                "value": 53228,
                "label": "Luanco",
                "label_ar": "لوانكو",
                "label_fr": "Luanco"
            },
            {
                "value": 51922,
                "label": "Leganés",
                "label_ar": "ليجانيس",
                "label_fr": "Leganés"
            },
            {
                "value": 52534,
                "label": "Alovera",
                "label_ar": "الوفيرا",
                "label_fr": "Alovera"
            },
            {
                "value": 53377,
                "label": "La Riba",
                "label_ar": "لا ريبا",
                "label_fr": "La Riba"
            },
            {
                "value": 51974,
                "label": "Boadilla del Monte",
                "label_ar": "بوديلا دل مونتي",
                "label_fr": "Boadilla del Monte"
            },
            {
                "value": 53601,
                "label": "Anglesola",
                "label_ar": "أنجليسولا",
                "label_fr": "Anglesola"
            },
            {
                "value": 53996,
                "label": "Medina de Pomar",
                "label_ar": "مدينة دي بومار",
                "label_fr": "Médina de Pomar"
            },
            {
                "value": 52753,
                "label": "Guadarrama",
                "label_ar": "غواداراما",
                "label_fr": "Guadarrama"
            },
            {
                "value": 53962,
                "label": "Bolanos de Calatrava",
                "label_ar": "بولانوس دي كالاترافا",
                "label_fr": "Bolanos de Calatrava"
            },
            {
                "value": 54069,
                "label": "Cerdanyola del Vallès",
                "label_ar": "سيردانيولا ديل فاليس",
                "label_fr": "Cerdanyola del Vallès"
            },
            {
                "value": 54015,
                "label": "Palmones",
                "label_ar": "النخيل",
                "label_fr": "Palmones"
            },
            {
                "value": 52323,
                "label": "Monterrubio de la Serena",
                "label_ar": "مونتيروبيو دي لا سيرينا",
                "label_fr": "Monterrubio de la Serena"
            },
            {
                "value": 54032,
                "label": "Mortera",
                "label_ar": "Mortera",
                "label_fr": "Mortera"
            },
            {
                "value": 53600,
                "label": "Riba",
                "label_ar": "الربا",
                "label_fr": "Riba"
            },
            {
                "value": 53399,
                "label": "Belicena",
                "label_ar": "بليسينا",
                "label_fr": "Belicena"
            },
            {
                "value": 53663,
                "label": "Navamorcuende",
                "label_ar": "نافاموركويندي",
                "label_fr": "Navamorcuende"
            },
            {
                "value": 53885,
                "label": "San Miguel de las Duenas",
                "label_ar": "سان ميغيل دي لاس دويناس",
                "label_fr": "San Miguel de las Duenas"
            },
            {
                "value": 54010,
                "label": "Landa",
                "label_ar": "لاندا",
                "label_fr": "Landa"
            },
            {
                "value": 52090,
                "label": "Logroño",
                "label_ar": "لوغرونيو",
                "label_fr": "Logroño"
            },
            {
                "value": 53118,
                "label": "Torresandino",
                "label_ar": "توريساندينو",
                "label_fr": "Torresandino"
            },
            {
                "value": 51985,
                "label": "Chinchón",
                "label_ar": "شينشون",
                "label_fr": "Chinchón"
            },
            {
                "value": 52198,
                "label": "Guadalajara",
                "label_ar": "غوادالاخارا",
                "label_fr": "Guadalajara"
            },
            {
                "value": 52277,
                "label": "Ceuti",
                "label_ar": "سيوتي",
                "label_fr": "Ceuti"
            },
            {
                "value": 53140,
                "label": "Alforja",
                "label_ar": "الفرجة",
                "label_fr": "Alforja"
            },
            {
                "value": 52538,
                "label": "Villabona",
                "label_ar": "فيلابونا",
                "label_fr": "Villabona"
            },
            {
                "value": 54140,
                "label": "Santa Eulalia",
                "label_ar": "سانتا يولاليا",
                "label_fr": "Santa Eulalia"
            },
            {
                "value": 53512,
                "label": "Plan",
                "label_ar": "خطة",
                "label_fr": "Plan"
            },
            {
                "value": 54079,
                "label": "Sodupe",
                "label_ar": "سودوبي",
                "label_fr": "Sodupe"
            },
            {
                "value": 54100,
                "label": "Lazagurria",
                "label_ar": "لازاغوريا",
                "label_fr": "Lazagurria"
            },
            {
                "value": 51946,
                "label": "Sesena",
                "label_ar": "سيسينا",
                "label_fr": "Sesena"
            },
            {
                "value": 53124,
                "label": "Quintana de Raneros",
                "label_ar": "كوينتانا دي رانيروس",
                "label_fr": "Quintana de Raneros"
            },
            {
                "value": 52537,
                "label": "Subirats",
                "label_ar": "سوبيراتس",
                "label_fr": "Subirats"
            },
            {
                "value": 53564,
                "label": "Benimodo",
                "label_ar": "بينيمودو",
                "label_fr": "Benimodo"
            },
            {
                "value": 53913,
                "label": "Platja d'Aro",
                "label_ar": "بلاتجا دارو",
                "label_fr": "Platja d'Aro"
            },
            {
                "value": 52313,
                "label": "Muro del Alcoy",
                "label_ar": "مورو ديل كوي",
                "label_fr": "Muro del Alcoy"
            },
            {
                "value": 54210,
                "label": "Montmajor",
                "label_ar": "مونتميور",
                "label_fr": "Montmajor"
            },
            {
                "value": 53647,
                "label": "Azpeitia",
                "label_ar": "أزبيتيا",
                "label_fr": "Azpeitia"
            },
            {
                "value": 53688,
                "label": "Lago",
                "label_ar": "لاغو",
                "label_fr": "Lago"
            },
            {
                "value": 52827,
                "label": "Berja",
                "label_ar": "برجا",
                "label_fr": "Berja"
            },
            {
                "value": 53506,
                "label": "Gata",
                "label_ar": "جاتا",
                "label_fr": "Gata"
            },
            {
                "value": 54439,
                "label": "Arroyo del Ojanco",
                "label_ar": "أرويو ديل أوجانكو",
                "label_fr": "Arroyo del Ojanco"
            },
            {
                "value": 54479,
                "label": "Nora",
                "label_ar": "نورا",
                "label_fr": "Nora"
            },
            {
                "value": 52146,
                "label": "Santa Pola",
                "label_ar": "سانتا بولا",
                "label_fr": "Santa Pola"
            },
            {
                "value": 52652,
                "label": "Montesquiu",
                "label_ar": "مونتسكيو",
                "label_fr": "Montesquiu"
            },
            {
                "value": 52696,
                "label": "Santa Maria de Palautordera",
                "label_ar": "سانتا ماريا دي بالاوتورديرا",
                "label_fr": "Santa Maria de Palautordera"
            },
            {
                "value": 52759,
                "label": "Benejuzar",
                "label_ar": "بينجوزار",
                "label_fr": "Benejuzar"
            },
            {
                "value": 54108,
                "label": "L'Ampolla",
                "label_ar": "لامبولا",
                "label_fr": "L'Ampolla"
            },
            {
                "value": 52239,
                "label": "Parets del Vallès",
                "label_ar": "Parets del Vallès",
                "label_fr": "Parets del Vallès"
            },
            {
                "value": 53755,
                "label": "Callosa de Segura",
                "label_ar": "كالوسا دي سيجورا",
                "label_fr": "Callosa de Segura"
            },
            {
                "value": 53639,
                "label": "Torres de Segre",
                "label_ar": "توريس دي سيغري",
                "label_fr": "Torres de Segre"
            },
            {
                "value": 53947,
                "label": "Villanueva del Ariscal",
                "label_ar": "فيلانويفا ديل أريسكال",
                "label_fr": "Villanueva del Ariscal"
            },
            {
                "value": 53099,
                "label": "Penafiel",
                "label_ar": "بينافيل",
                "label_fr": "Penafiel"
            },
            {
                "value": 53510,
                "label": "Santa Marta de Ortigueira",
                "label_ar": "سانتا مارتا دي أورتيجويرا",
                "label_fr": "Santa Marta de Ortigueira"
            },
            {
                "value": 52273,
                "label": "Molina de Segura",
                "label_ar": "مولينا دي سيجورا",
                "label_fr": "Molina de Segura"
            },
            {
                "value": 52203,
                "label": "Roldan",
                "label_ar": "رولدان",
                "label_fr": "Roldan"
            },
            {
                "value": 51968,
                "label": "Cambrils",
                "label_ar": "كامبريلس",
                "label_fr": "Cambrils"
            },
            {
                "value": 54445,
                "label": "Santa Marina del Rey",
                "label_ar": "سانتا مارينا ديل ري",
                "label_fr": "Santa Marina del Rey"
            },
            {
                "value": 53533,
                "label": "Tuineje",
                "label_ar": "Tuineje",
                "label_fr": "Tuineje"
            },
            {
                "value": 53439,
                "label": "Villacondide",
                "label_ar": "فيلاكونديد",
                "label_fr": "Villacondide"
            },
            {
                "value": 53766,
                "label": "Vicolozano",
                "label_ar": "فيكولوزانو",
                "label_fr": "Vicolozano"
            },
            {
                "value": 53473,
                "label": "Tudela de Duero",
                "label_ar": "توديلا دي دويرو",
                "label_fr": "Tudela de Duero"
            },
            {
                "value": 53181,
                "label": "Elburgo",
                "label_ar": "إلبورغو",
                "label_fr": "Elburgo"
            },
            {
                "value": 52726,
                "label": "Priego de Cordoba",
                "label_ar": "بريجو دي كوردوبا",
                "label_fr": "Priego de Cordoba"
            },
            {
                "value": 52845,
                "label": "Teguise",
                "label_ar": "Teguise",
                "label_fr": "Teguise"
            },
            {
                "value": 52553,
                "label": "Laguna de Duero",
                "label_ar": "لاجونا دي دويرو",
                "label_fr": "Laguna de Duero"
            },
            {
                "value": 52671,
                "label": "Casetas",
                "label_ar": "كاسيتاس",
                "label_fr": "Casetas"
            },
            {
                "value": 53966,
                "label": "Puebla de la Calzada",
                "label_ar": "بويبلا دي لا كالزادا",
                "label_fr": "Puebla de la Calzada"
            },
            {
                "value": 52496,
                "label": "Llubí",
                "label_ar": "لوبي",
                "label_fr": "Llubí"
            },
            {
                "value": 52331,
                "label": "Dos Hermanas",
                "label_ar": "دوس هيرماناس",
                "label_fr": "Dos Hermanas"
            },
            {
                "value": 52632,
                "label": "Puertollano",
                "label_ar": "بويرتولانو",
                "label_fr": "Puertollano"
            },
            {
                "value": 52402,
                "label": "Villaricos",
                "label_ar": "فيلاريكوس",
                "label_fr": "Villaricos"
            },
            {
                "value": 53604,
                "label": "la Nou de Bergueda",
                "label_ar": "لا نو دي بيرجويدا",
                "label_fr": "la Nou de Bergueda"
            },
            {
                "value": 53883,
                "label": "Nestares",
                "label_ar": "نيستريس",
                "label_fr": "Nestares"
            },
            {
                "value": 52686,
                "label": "Nerja",
                "label_ar": "نيرجا",
                "label_fr": "Nerja"
            },
            {
                "value": 52862,
                "label": "Soria",
                "label_ar": "سوريا",
                "label_fr": "Soria"
            },
            {
                "value": 53978,
                "label": "Berguenda",
                "label_ar": "بيرجيندا",
                "label_fr": "Berguenda"
            },
            {
                "value": 53369,
                "label": "Albatera",
                "label_ar": "الباتيرا",
                "label_fr": "Albatera"
            },
            {
                "value": 52840,
                "label": "Cacheiras",
                "label_ar": "كاشيراس",
                "label_fr": "Cacheiras"
            },
            {
                "value": 52032,
                "label": "Tijola",
                "label_ar": "تيجولا",
                "label_fr": "Tijola"
            },
            {
                "value": 52688,
                "label": "Villajoyosa",
                "label_ar": "فيلاجويوسا",
                "label_fr": "Villajoyosa"
            },
            {
                "value": 54203,
                "label": "Agreda",
                "label_ar": "اجريدا",
                "label_fr": "Agreda"
            },
            {
                "value": 53505,
                "label": "Faura",
                "label_ar": "فورة",
                "label_fr": "Faura"
            },
            {
                "value": 53908,
                "label": "Viladrau",
                "label_ar": "فيلادرو",
                "label_fr": "Viladrau"
            },
            {
                "value": 52289,
                "label": "Sant Vicenc de Castellet",
                "label_ar": "سانت فيسينك دي كاستيليت",
                "label_fr": "Sant Vicenç de Castellet"
            },
            {
                "value": 52322,
                "label": "Sayalonga",
                "label_ar": "سايالونجا",
                "label_fr": "Sayalonga"
            },
            {
                "value": 54294,
                "label": "Chapela",
                "label_ar": "شابيلا",
                "label_fr": "Chapela"
            },
            {
                "value": 54094,
                "label": "Betelu",
                "label_ar": "بيتيلو",
                "label_fr": "Bétélu"
            },
            {
                "value": 53921,
                "label": "Villa del Rio",
                "label_ar": "فيلا ديل ريو",
                "label_fr": "Villa del Rio"
            },
            {
                "value": 53939,
                "label": "Benidoleig",
                "label_ar": "بينيدوليج",
                "label_fr": "Benidoleig"
            },
            {
                "value": 52357,
                "label": "Argentona",
                "label_ar": "أرجنتونا",
                "label_fr": "Argentona"
            },
            {
                "value": 52194,
                "label": "Hortichuela",
                "label_ar": "هورتشويلا",
                "label_fr": "Hortichuela"
            },
            {
                "value": 54411,
                "label": "La Mamola",
                "label_ar": "لا مامولا",
                "label_fr": "La Mamola"
            },
            {
                "value": 53093,
                "label": "Lerin",
                "label_ar": "ليرين",
                "label_fr": "Lerin"
            },
            {
                "value": 53498,
                "label": "Lliria",
                "label_ar": "ليريا",
                "label_fr": "Lliria"
            },
            {
                "value": 53217,
                "label": "Dosbarrios",
                "label_ar": "دوسباريوس",
                "label_fr": "Dosbarrios"
            },
            {
                "value": 53825,
                "label": "Sarroca de Lleida",
                "label_ar": "Sarroca de Lleida",
                "label_fr": "Sarroca de Lleida"
            },
            {
                "value": 53985,
                "label": "Pasaia",
                "label_ar": "باسايا",
                "label_fr": "Pasaia"
            },
            {
                "value": 52195,
                "label": "Puerto Real",
                "label_ar": "بويرتو ريال",
                "label_fr": "Puerto Real"
            },
            {
                "value": 53655,
                "label": "La Pedrera",
                "label_ar": "لا بيدريرا",
                "label_fr": "La Pedrera"
            },
            {
                "value": 52716,
                "label": "Las Islas",
                "label_ar": "لاس اسلاس",
                "label_fr": "Las Islas"
            },
            {
                "value": 51991,
                "label": "Pozuelo de Alarcón",
                "label_ar": "بوزويلو دي الاركون",
                "label_fr": "Pozuelo de Alarcón"
            },
            {
                "value": 53189,
                "label": "Anorbe",
                "label_ar": "أنوربي",
                "label_fr": "Anorbe"
            },
            {
                "value": 53788,
                "label": "Argamasilla de Calatrava",
                "label_ar": "Argamasilla de Calatrava",
                "label_fr": "Argamasilla de Calatrava"
            },
            {
                "value": 52093,
                "label": "Mataró",
                "label_ar": "ماتارو",
                "label_fr": "Mataró"
            },
            {
                "value": 54092,
                "label": "Peralta – Azkoien",
                "label_ar": "بيرالتا - أزكوين",
                "label_fr": "Peralta - Azkoien"
            },
            {
                "value": 52689,
                "label": "Zarautz",
                "label_ar": "Zarautz",
                "label_fr": "Zarautz"
            },
            {
                "value": 52333,
                "label": "Hernani",
                "label_ar": "هرناني",
                "label_fr": "Hernani"
            },
            {
                "value": 52656,
                "label": "Mancha Real",
                "label_ar": "مانشا ريال",
                "label_fr": "Mancha Real"
            },
            {
                "value": 54484,
                "label": "Lama",
                "label_ar": "لما",
                "label_fr": "Lama"
            },
            {
                "value": 54117,
                "label": "Alozaina",
                "label_ar": "الزينة",
                "label_fr": "Alozaina"
            },
            {
                "value": 52901,
                "label": "Quer",
                "label_ar": "Quer",
                "label_fr": "Quer"
            },
            {
                "value": 52374,
                "label": "Villacanas",
                "label_ar": "فيلاكاناس",
                "label_fr": "Villacanas"
            },
            {
                "value": 52360,
                "label": "Gijón",
                "label_ar": "خيخون",
                "label_fr": "Gijón"
            },
            {
                "value": 52346,
                "label": "Ferreries",
                "label_ar": "فيريريز",
                "label_fr": "Ferreries"
            },
            {
                "value": 52115,
                "label": "Valldoreix",
                "label_ar": "فالدوريكس",
                "label_fr": "Valldoreix"
            },
            {
                "value": 52663,
                "label": "Sant Sadurni d'Anoia",
                "label_ar": "سانت سادورني دانويا",
                "label_fr": "Sant Sadurni d'Anoia"
            },
            {
                "value": 53637,
                "label": "Rios",
                "label_ar": "ريوس",
                "label_fr": "Rios"
            },
            {
                "value": 53362,
                "label": "Olvega",
                "label_ar": "أولفيغا",
                "label_fr": "Olvega"
            },
            {
                "value": 54084,
                "label": "Azkoitia",
                "label_ar": "أزكويتا",
                "label_fr": "Azkoitia"
            },
            {
                "value": 53888,
                "label": "Berriatua",
                "label_ar": "برياتوا",
                "label_fr": "Berriatua"
            },
            {
                "value": 53308,
                "label": "Brea de Aragon",
                "label_ar": "بريا دي أراجون",
                "label_fr": "Brea de Aragon"
            },
            {
                "value": 54115,
                "label": "Tarifa",
                "label_ar": "طريفة",
                "label_fr": "Tarifa"
            },
            {
                "value": 53094,
                "label": "Tamames",
                "label_ar": "تامامس",
                "label_fr": "Tamames"
            },
            {
                "value": 53547,
                "label": "Agaete",
                "label_ar": "اجيتي",
                "label_fr": "Agaete"
            },
            {
                "value": 53350,
                "label": "Mira",
                "label_ar": "ميرا",
                "label_fr": "Mira"
            },
            {
                "value": 52051,
                "label": "Sant Pere de Ribes",
                "label_ar": "سانت بيري دي ريبس",
                "label_fr": "Sant Pere de Ribes"
            },
            {
                "value": 53031,
                "label": "Selva",
                "label_ar": "سيلفا",
                "label_fr": "Selva"
            },
            {
                "value": 53672,
                "label": "Villanueva de Gallego",
                "label_ar": "فيلانويفا دي جاليجو",
                "label_fr": "Villanueva de Gallego"
            },
            {
                "value": 52167,
                "label": "Los Barrios",
                "label_ar": "لوس باريوس",
                "label_fr": "Los Barrios"
            },
            {
                "value": 53798,
                "label": "Antas",
                "label_ar": "أنتاس",
                "label_fr": "Antas"
            },
            {
                "value": 52175,
                "label": "Ribadeo",
                "label_ar": "ريباديو",
                "label_fr": "Ribadeo"
            },
            {
                "value": 52476,
                "label": "Marchamalo",
                "label_ar": "مارشامالو",
                "label_fr": "Marchamalo"
            },
            {
                "value": 52762,
                "label": "Balenya",
                "label_ar": "بالينيا",
                "label_fr": "Balenya"
            },
            {
                "value": 54106,
                "label": "Robleda-Cervantes",
                "label_ar": "روبليدا سرفانتس",
                "label_fr": "Robleda-Cervantes"
            },
            {
                "value": 53801,
                "label": "Barros",
                "label_ar": "باروس",
                "label_fr": "Barros"
            },
            {
                "value": 52173,
                "label": "Santa Eulalia Bajera",
                "label_ar": "سانتا يولاليا باجيرا",
                "label_fr": "Santa Eulalia Bajera"
            },
            {
                "value": 53443,
                "label": "Muros de Nalon",
                "label_ar": "موروس دي نالون",
                "label_fr": "Muros de Nalon"
            },
            {
                "value": 52692,
                "label": "Montcada i Reixac",
                "label_ar": "مونتكادا أنا ريكساك",
                "label_fr": "Montcada i Reixac"
            },
            {
                "value": 53713,
                "label": "Perafort",
                "label_ar": "بيرافورت",
                "label_fr": "Perafort"
            },
            {
                "value": 51928,
                "label": "Getafe",
                "label_ar": "خيتافي",
                "label_fr": "Getafe"
            },
            {
                "value": 52298,
                "label": "el Masnou",
                "label_ar": "المصنو",
                "label_fr": "el Masnou"
            },
            {
                "value": 53416,
                "label": "Cruz",
                "label_ar": "كروز",
                "label_fr": "Cruz"
            },
            {
                "value": 54406,
                "label": "la Pobla de Mafumet",
                "label_ar": "لا بوبلا دي مافوميت",
                "label_fr": "la Pobla de Mafumet"
            },
            {
                "value": 53735,
                "label": "Pauels",
                "label_ar": "باولز",
                "label_fr": "Pauels"
            },
            {
                "value": 52225,
                "label": "Tudela",
                "label_ar": "توديلا",
                "label_fr": "Tudela"
            },
            {
                "value": 52271,
                "label": "Gandia",
                "label_ar": "غانديا",
                "label_fr": "Gandia"
            },
            {
                "value": 52462,
                "label": "Santa Pau",
                "label_ar": "سانتا باو",
                "label_fr": "Santa Pau"
            },
            {
                "value": 53507,
                "label": "Cuadros",
                "label_ar": "كوادروس",
                "label_fr": "Cuadros"
            },
            {
                "value": 53359,
                "label": "La Calzada",
                "label_ar": "لا كالزادا",
                "label_fr": "La Calzada"
            },
            {
                "value": 54112,
                "label": "Mataluenga",
                "label_ar": "ماتالوينجا",
                "label_fr": "Mataluenga"
            },
            {
                "value": 53868,
                "label": "Ron",
                "label_ar": "رون",
                "label_fr": "Ron"
            },
            {
                "value": 52112,
                "label": "Seville",
                "label_ar": "إشبيلية",
                "label_fr": "Séville"
            },
            {
                "value": 52293,
                "label": "els Hostalets de Pierola",
                "label_ar": "إل هوستالتس دي بييرولا",
                "label_fr": "els Hostalets de Pierola"
            },
            {
                "value": 52450,
                "label": "Couso de Salas",
                "label_ar": "كوسو دي سالاس",
                "label_fr": "Couso de Salas"
            },
            {
                "value": 53694,
                "label": "Benisuera",
                "label_ar": "Benisuera",
                "label_fr": "Benisuera"
            },
            {
                "value": 54284,
                "label": "Esparreguera",
                "label_ar": "اسبارغيرا",
                "label_fr": "Esparreguera"
            },
            {
                "value": 52959,
                "label": "Carranque",
                "label_ar": "كارانكي",
                "label_fr": "Carranque"
            },
            {
                "value": 53536,
                "label": "Cedillo del Condado",
                "label_ar": "سيديلو ديل كوندادو",
                "label_fr": "Cedillo del Condado"
            },
            {
                "value": 52649,
                "label": "la Vall",
                "label_ar": "لا فال",
                "label_fr": "la Vall"
            },
            {
                "value": 53898,
                "label": "Ajo",
                "label_ar": "آجو",
                "label_fr": "Ajo"
            },
            {
                "value": 54426,
                "label": "Ontinena",
                "label_ar": "أونتينينا",
                "label_fr": "Ontinena"
            },
            {
                "value": 54450,
                "label": "Trabada",
                "label_ar": "ترابادا",
                "label_fr": "Trabada"
            },
            {
                "value": 52141,
                "label": "Cadiz",
                "label_ar": "قادس",
                "label_fr": "Cadix"
            },
            {
                "value": 54111,
                "label": "Gozon de Ucieza",
                "label_ar": "Gozon de Ucieza",
                "label_fr": "Gozon de Ucieza"
            },
            {
                "value": 54006,
                "label": "Sollana",
                "label_ar": "سولانا",
                "label_fr": "Sollana"
            },
            {
                "value": 52301,
                "label": "Santa Perpetua de Mogoda",
                "label_ar": "سانتا بيربيتوا دي موجودا",
                "label_fr": "Santa Perpetua de Mogoda"
            },
            {
                "value": 53750,
                "label": "Benavides",
                "label_ar": "بينافيدس",
                "label_fr": "Benavides"
            },
            {
                "value": 52892,
                "label": "Galaroza",
                "label_ar": "جالاروزا",
                "label_fr": "Galaroza"
            },
            {
                "value": 53276,
                "label": "Miranda del Castanar",
                "label_ar": "ميراندا ديل كاستانار",
                "label_fr": "Miranda del Castanar"
            },
            {
                "value": 53290,
                "label": "Artesa de Segre",
                "label_ar": "أرتيسا دي سيغري",
                "label_fr": "Artesa de Segre"
            },
            {
                "value": 53866,
                "label": "Corgo",
                "label_ar": "كورجو",
                "label_fr": "Corgo"
            },
            {
                "value": 53375,
                "label": "Tarrega",
                "label_ar": "Tarrega",
                "label_fr": "Tarrega"
            },
            {
                "value": 53770,
                "label": "Camarma de Esteruelas",
                "label_ar": "كامارما دي إستيرولاس",
                "label_fr": "Camarma de Esteruelas"
            },
            {
                "value": 53698,
                "label": "Cervera del Rio Alhama",
                "label_ar": "سيرفيرا ديل ريو ألاما",
                "label_fr": "Cervera del Rio Alhama"
            },
            {
                "value": 53706,
                "label": "Chilches",
                "label_ar": "القشعريرة",
                "label_fr": "Chilches"
            },
            {
                "value": 52865,
                "label": "Puente-Genil",
                "label_ar": "بوينتي جينيل",
                "label_fr": "Puente-Genil"
            },
            {
                "value": 52452,
                "label": "L'Alcudia",
                "label_ar": "الكوديا",
                "label_fr": "L'Alcudia"
            },
            {
                "value": 54213,
                "label": "Maceira",
                "label_ar": "ماسيرا",
                "label_fr": "Maceira"
            },
            {
                "value": 53644,
                "label": "Suances",
                "label_ar": "سانسيس",
                "label_fr": "Suances"
            },
            {
                "value": 53015,
                "label": "Ahigal",
                "label_ar": "Ahigal",
                "label_fr": "Ahigal"
            },
            {
                "value": 53097,
                "label": "Carpio",
                "label_ar": "كاربيو",
                "label_fr": "Carpio"
            },
            {
                "value": 52395,
                "label": "Ponferrada",
                "label_ar": "بونفيرادا",
                "label_fr": "Ponferrada"
            },
            {
                "value": 53712,
                "label": "Verdu",
                "label_ar": "فيردو",
                "label_fr": "Verdu"
            },
            {
                "value": 52744,
                "label": "Mayor",
                "label_ar": "عمدة",
                "label_fr": "Maire"
            },
            {
                "value": 53392,
                "label": "Cogollos",
                "label_ar": "كوجولوس",
                "label_fr": "Cogollos"
            },
            {
                "value": 53397,
                "label": "Santiurde de Toranzo",
                "label_ar": "سانتيوردي دي تورانزو",
                "label_fr": "Santiurde de Toranzo"
            },
            {
                "value": 52337,
                "label": "Elexalde",
                "label_ar": "إليكسالد",
                "label_fr": "Elexalde"
            },
            {
                "value": 52590,
                "label": "Burela de Cabo",
                "label_ar": "بوريلا دي كابو",
                "label_fr": "Burela de Cabo"
            },
            {
                "value": 52662,
                "label": "Entrevias",
                "label_ar": "انتريفياس",
                "label_fr": "Entrevias"
            },
            {
                "value": 54446,
                "label": "Tiagua",
                "label_ar": "تياغوا",
                "label_fr": "Tiagua"
            },
            {
                "value": 53323,
                "label": "Valleseco",
                "label_ar": "فاليسيكو",
                "label_fr": "Valleseco"
            },
            {
                "value": 53294,
                "label": "Gata de Gorgos",
                "label_ar": "جاتا دي جورجوس",
                "label_fr": "Gata de Gorgos"
            },
            {
                "value": 53345,
                "label": "Cortes de la Frontera",
                "label_ar": "كورتيس دي لا فرونتيرا",
                "label_fr": "Cortes de la Frontera"
            },
            {
                "value": 54373,
                "label": "Villamanrique de la Condesa",
                "label_ar": "فيلامانريك دي لا كونديسا",
                "label_fr": "Villamanrique de la Condesa"
            },
            {
                "value": 53611,
                "label": "Azagra",
                "label_ar": "آزاغرا",
                "label_fr": "Azagra"
            },
            {
                "value": 52458,
                "label": "La Carlota",
                "label_ar": "لا كارلوتا",
                "label_fr": "La Carlota"
            },
            {
                "value": 52595,
                "label": "Redondela",
                "label_ar": "ريدونديلا",
                "label_fr": "Redondela"
            },
            {
                "value": 52460,
                "label": "Las Cabezas de San Juan",
                "label_ar": "لاس كابيزاس دي سان خوان",
                "label_fr": "Las Cabezas de San Juan"
            },
            {
                "value": 53275,
                "label": "Carcastillo",
                "label_ar": "كاراستيلو",
                "label_fr": "Carcastillo"
            },
            {
                "value": 53086,
                "label": "Cerdeda",
                "label_ar": "سيرديده",
                "label_fr": "Cerdeda"
            },
            {
                "value": 53548,
                "label": "Guamasa",
                "label_ar": "غوامسا",
                "label_fr": "Guamasa"
            },
            {
                "value": 53222,
                "label": "L'Arboc",
                "label_ar": "لاربوك",
                "label_fr": "L'Arboc"
            },
            {
                "value": 53723,
                "label": "Grijota",
                "label_ar": "جريجوتا",
                "label_fr": "Grijota"
            },
            {
                "value": 53970,
                "label": "Aguilafuente",
                "label_ar": "Aguilafuente",
                "label_fr": "Aguilafuente"
            },
            {
                "value": 53597,
                "label": "Garachico",
                "label_ar": "جاراتشيكو",
                "label_fr": "Garachico"
            },
            {
                "value": 54158,
                "label": "Villarrubia",
                "label_ar": "فيلاروبيا",
                "label_fr": "Villarrubia"
            },
            {
                "value": 53452,
                "label": "Marmolejo",
                "label_ar": "مارموليجو",
                "label_fr": "Marmolejo"
            },
            {
                "value": 53989,
                "label": "Vilanova de Arousa",
                "label_ar": "فيلانوفا دي أروسا",
                "label_fr": "Vilanova de Arousa"
            },
            {
                "value": 54002,
                "label": "Revellinos",
                "label_ar": "ريفيلينوس",
                "label_fr": "Revellinos"
            },
            {
                "value": 53976,
                "label": "Arevalo",
                "label_ar": "أريفالو",
                "label_fr": "Arevalo"
            },
            {
                "value": 53274,
                "label": "Valdepolo",
                "label_ar": "فالديبولو",
                "label_fr": "Valdepolo"
            },
            {
                "value": 53208,
                "label": "Zamudio",
                "label_ar": "زاموديو",
                "label_fr": "Zamudio"
            },
            {
                "value": 53456,
                "label": "Limones",
                "label_ar": "الليمون",
                "label_fr": "Limones"
            },
            {
                "value": 53847,
                "label": "Abanilla",
                "label_ar": "أبانيلا",
                "label_fr": "Abanilla"
            },
            {
                "value": 53569,
                "label": "Mendaro",
                "label_ar": "ميندارو",
                "label_fr": "Mendaro"
            },
            {
                "value": 54407,
                "label": "Puigcerdà",
                "label_ar": "بويجثيردا",
                "label_fr": "Puigcerdà"
            },
            {
                "value": 54205,
                "label": "El Burgo de Ebro",
                "label_ar": "El Burgo de Ebro",
                "label_fr": "El Burgo de Ebro"
            }
        ]
    },
    {
        "country": "Austria",
        "cities": [
            {
                "value": 55092,
                "label": "Bernstein",
                "label_ar": "برنشتاين",
                "label_fr": "Bernstein"
            },
            {
                "value": 55024,
                "label": "Gaschurn",
                "label_ar": "غاسشرن",
                "label_fr": "Gaschurn"
            },
            {
                "value": 55040,
                "label": "Pottenbrunn",
                "label_ar": "بوتنبرون",
                "label_fr": "Pottenbrunn"
            },
            {
                "value": 55173,
                "label": "Grobming",
                "label_ar": "يتلمس طريقه",
                "label_fr": "Grobming"
            },
            {
                "value": 55308,
                "label": "Schwanberg",
                "label_ar": "شوانبيرج",
                "label_fr": "Schwanberg"
            },
            {
                "value": 55286,
                "label": "Ach",
                "label_ar": "آتش",
                "label_fr": "Ach"
            },
            {
                "value": 55271,
                "label": "Zistersdorf",
                "label_ar": "زيسترسدورف",
                "label_fr": "Zistersdorf"
            },
            {
                "value": 54848,
                "label": "Ebbs",
                "label_ar": "إبس",
                "label_fr": "Ebbs"
            },
            {
                "value": 55825,
                "label": "Foederlach",
                "label_ar": "فيديرلاخ",
                "label_fr": "Foederlach"
            },
            {
                "value": 54999,
                "label": "Krottendorf bei Ligist",
                "label_ar": "كروتندورف باي ليجيست",
                "label_fr": "Krottendorf bei Ligist"
            },
            {
                "value": 55546,
                "label": "Aigen im Muehlkreis",
                "label_ar": "Aigen im Muehlkreis",
                "label_fr": "Aigen im Muehlkreis"
            },
            {
                "value": 54721,
                "label": "Ernst",
                "label_ar": "إرنست",
                "label_fr": "Ernst"
            },
            {
                "value": 55614,
                "label": "Offenhausen",
                "label_ar": "أوفنهاوزن",
                "label_fr": "Offenhausen"
            },
            {
                "value": 55754,
                "label": "Buch",
                "label_ar": "بوخ",
                "label_fr": "Buch"
            },
            {
                "value": 55309,
                "label": "Haller",
                "label_ar": "هالر",
                "label_fr": "Haller"
            },
            {
                "value": 54889,
                "label": "Reisenberg",
                "label_ar": "Reisenberg",
                "label_fr": "Reisenberg"
            },
            {
                "value": 54761,
                "label": "Altenhof am Hausruck",
                "label_ar": "Altenhof am Hausruck",
                "label_fr": "Altenhof am Hausruck"
            },
            {
                "value": 55526,
                "label": "Pinggau",
                "label_ar": "بينغاو",
                "label_fr": "Pinggau"
            },
            {
                "value": 54665,
                "label": "Hard",
                "label_ar": "الصعب",
                "label_fr": "Dur"
            },
            {
                "value": 55336,
                "label": "Mannersdorf an der Rabnitz",
                "label_ar": "Mannersdorf an der Rabnitz",
                "label_fr": "Mannersdorf an der Rabnitz"
            },
            {
                "value": 55111,
                "label": "Altenberg",
                "label_ar": "ألتنبرغ",
                "label_fr": "Altenberg"
            },
            {
                "value": 54547,
                "label": "Munchendorf",
                "label_ar": "مونشندورف",
                "label_fr": "Munchendorf"
            },
            {
                "value": 54791,
                "label": "Lermoos",
                "label_ar": "ليرموس",
                "label_fr": "Lermoos"
            },
            {
                "value": 54851,
                "label": "Poysdorf",
                "label_ar": "بويسدورف",
                "label_fr": "Poysdorf"
            },
            {
                "value": 55099,
                "label": "Marchtrenk",
                "label_ar": "مارشترينك",
                "label_fr": "Marchtrenk"
            },
            {
                "value": 55315,
                "label": "Stans",
                "label_ar": "ستانس",
                "label_fr": "Stans"
            },
            {
                "value": 54774,
                "label": "Unterjoch",
                "label_ar": "أونترجوخ",
                "label_fr": "Unterjoch"
            },
            {
                "value": 55593,
                "label": "Birgitz",
                "label_ar": "بيرجيتز",
                "label_fr": "Birgitz"
            },
            {
                "value": 55435,
                "label": "Launsdorf",
                "label_ar": "لاونسدورف",
                "label_fr": "Launsdorf"
            },
            {
                "value": 54867,
                "label": "Wiener Neudorf",
                "label_ar": "وينر نيودورف",
                "label_fr": "Wiener Neudorf"
            },
            {
                "value": 55035,
                "label": "Haid",
                "label_ar": "حيد",
                "label_fr": "Haid"
            },
            {
                "value": 54846,
                "label": "Reichendorf",
                "label_ar": "Reichendorf",
                "label_fr": "Reichendorf"
            },
            {
                "value": 55223,
                "label": "Traunkirchen",
                "label_ar": "تراونكيرشن",
                "label_fr": "Traunkirchen"
            },
            {
                "value": 55133,
                "label": "Elixhausen",
                "label_ar": "إليكسهاوزن",
                "label_fr": "Elixhausen"
            },
            {
                "value": 55636,
                "label": "Haibach ob der Donau",
                "label_ar": "Haibach ob der Donau",
                "label_fr": "Haibach ob der Donau"
            },
            {
                "value": 55567,
                "label": "Bruckneudorf",
                "label_ar": "بروكنودورف",
                "label_fr": "Bruckneudorf"
            },
            {
                "value": 55325,
                "label": "Hollersbach im Pinzgau",
                "label_ar": "هولرسباخ إم بينزغاو",
                "label_fr": "Hollersbach im Pinzgau"
            },
            {
                "value": 55127,
                "label": "Thalgau",
                "label_ar": "ثالجاو",
                "label_fr": "Thalgau"
            },
            {
                "value": 55027,
                "label": "Haag am Hausruck",
                "label_ar": "هاج ام هوسروك",
                "label_fr": "Haag am Hausruck"
            },
            {
                "value": 55888,
                "label": "Gross-Schweinbarth",
                "label_ar": "جروس شوينبارث",
                "label_fr": "Gross-Schweinbarth"
            },
            {
                "value": 55601,
                "label": "Marbach",
                "label_ar": "مارباخ",
                "label_fr": "Marbach"
            },
            {
                "value": 55696,
                "label": "Gutau",
                "label_ar": "جوتاو",
                "label_fr": "Gutau"
            },
            {
                "value": 54928,
                "label": "Saint Anton",
                "label_ar": "القديس انطون",
                "label_fr": "Saint Anton"
            },
            {
                "value": 55513,
                "label": "Schutzen am Gebirge",
                "label_ar": "Schutzen am Gebirge",
                "label_fr": "Schutzen am Gebirge"
            },
            {
                "value": 55464,
                "label": "Dalaas",
                "label_ar": "دلع",
                "label_fr": "Dalaas"
            },
            {
                "value": 54561,
                "label": "Wiener Neustadt",
                "label_ar": "وينر نويشتات",
                "label_fr": "Wiener Neustadt"
            },
            {
                "value": 55835,
                "label": "Meiningen",
                "label_ar": "مينينجين",
                "label_fr": "Meiningen"
            },
            {
                "value": 54604,
                "label": "Obdach",
                "label_ar": "Obdach",
                "label_fr": "Obdach"
            },
            {
                "value": 54614,
                "label": "Heimschuh",
                "label_ar": "Heimschuh",
                "label_fr": "Heimschuh"
            },
            {
                "value": 55291,
                "label": "Scharten",
                "label_ar": "شارتن",
                "label_fr": "Scharten"
            },
            {
                "value": 55284,
                "label": "Hintersdorf",
                "label_ar": "هينترسدورف",
                "label_fr": "Hintersdorf"
            },
            {
                "value": 55857,
                "label": "Virgen",
                "label_ar": "فيرجن",
                "label_fr": "Vierge"
            },
            {
                "value": 55743,
                "label": "Erpersdorf",
                "label_ar": "اربرسدورف",
                "label_fr": "Erpersdorf"
            },
            {
                "value": 54943,
                "label": "Uderns",
                "label_ar": "أودرنز",
                "label_fr": "Uderns"
            },
            {
                "value": 54813,
                "label": "Schoppernau",
                "label_ar": "شوبيرناو",
                "label_fr": "Schoppernau"
            },
            {
                "value": 54594,
                "label": "Feldkirch",
                "label_ar": "فيلدكيرش",
                "label_fr": "Feldkirch"
            },
            {
                "value": 54892,
                "label": "Donnerskirchen",
                "label_ar": "دونرسكيرشن",
                "label_fr": "Donnerskirchen"
            },
            {
                "value": 55491,
                "label": "Nassereith",
                "label_ar": "نصريث",
                "label_fr": "Nassereith"
            },
            {
                "value": 55792,
                "label": "Geinberg",
                "label_ar": "جينبيرج",
                "label_fr": "Geinberg"
            },
            {
                "value": 55449,
                "label": "Gunselsdorf",
                "label_ar": "Gunselsdorf",
                "label_fr": "Gunselsdorf"
            },
            {
                "value": 55275,
                "label": "Hellmonsodt",
                "label_ar": "هيلمونسودت",
                "label_fr": "Hellmonsodt"
            },
            {
                "value": 55095,
                "label": "Dechantskirchen",
                "label_ar": "ديشانتسكيرشن",
                "label_fr": "Dechantskirchen"
            },
            {
                "value": 55166,
                "label": "Klaus",
                "label_ar": "كلاوس",
                "label_fr": "Klaus"
            },
            {
                "value": 55532,
                "label": "Pinsdorf",
                "label_ar": "بينسدورف",
                "label_fr": "Pinsdorf"
            },
            {
                "value": 54571,
                "label": "Mauerbach",
                "label_ar": "ماورباخ",
                "label_fr": "Mauerbach"
            },
            {
                "value": 55407,
                "label": "Steindorf am Ossiacher See",
                "label_ar": "Steindorf am Ossiacher See",
                "label_fr": "Steindorf am Ossiacher See"
            },
            {
                "value": 55622,
                "label": "Zell am Moos",
                "label_ar": "زيل أم موس",
                "label_fr": "Zell am Moos"
            },
            {
                "value": 54578,
                "label": "Wels",
                "label_ar": "ويلس",
                "label_fr": "Wels"
            },
            {
                "value": 54622,
                "label": "Eferding",
                "label_ar": "إفيردينغ",
                "label_fr": "Eferding"
            },
            {
                "value": 55630,
                "label": "Ried im Traunkreis",
                "label_ar": "Ried im Traunkreis",
                "label_fr": "Ried im Traunkreis"
            },
            {
                "value": 55689,
                "label": "Haag",
                "label_ar": "هاج",
                "label_fr": "Haag"
            },
            {
                "value": 55352,
                "label": "Sachendorf",
                "label_ar": "ساشندورف",
                "label_fr": "Sachendorf"
            },
            {
                "value": 55842,
                "label": "Pachfurth",
                "label_ar": "باشفورث",
                "label_fr": "Pachfurth"
            },
            {
                "value": 55779,
                "label": "Langeck im Burgenland",
                "label_ar": "لانجيك ايم بورغنلاند",
                "label_fr": "Langeck im Burgenland"
            },
            {
                "value": 55034,
                "label": "Krenglbach",
                "label_ar": "كرينجلباخ",
                "label_fr": "Krenglbach"
            },
            {
                "value": 54961,
                "label": "Ostermiething",
                "label_ar": "أوسترميثينج",
                "label_fr": "Ostermiething"
            },
            {
                "value": 54680,
                "label": "Engerwitzdorf",
                "label_ar": "إنجيرويتزدورف",
                "label_fr": "Engerwitzdorf"
            },
            {
                "value": 55579,
                "label": "Enzersdorf an der Fischa",
                "label_ar": "Enzersdorf an der Fischa",
                "label_fr": "Enzersdorf an der Fischa"
            },
            {
                "value": 54601,
                "label": "Horn",
                "label_ar": "بوق",
                "label_fr": "klaxon"
            },
            {
                "value": 55883,
                "label": "Heiligenblut am Großglockner",
                "label_ar": "هيليغنبلوت آم جروسغلوكنر",
                "label_fr": "Heiligenblut am Großglockner"
            },
            {
                "value": 54731,
                "label": "Hohnhart",
                "label_ar": "هوهنهارت",
                "label_fr": "Hohnhart"
            },
            {
                "value": 54552,
                "label": "Frohnleiten",
                "label_ar": "Frohnleiten",
                "label_fr": "Frohnleiten"
            },
            {
                "value": 55531,
                "label": "Diepoltsham",
                "label_ar": "ديبولتشام",
                "label_fr": "Diepoltsham"
            },
            {
                "value": 55120,
                "label": "Tamsweg",
                "label_ar": "تامسويج",
                "label_fr": "Tamsweg"
            },
            {
                "value": 54797,
                "label": "Pettneu",
                "label_ar": "بيتنيو",
                "label_fr": "Pettneu"
            },
            {
                "value": 55734,
                "label": "Baumkirchen",
                "label_ar": "بومكيرشن",
                "label_fr": "Baumkirchen"
            },
            {
                "value": 55543,
                "label": "Kollmitzberg",
                "label_ar": "كولميتسبيرج",
                "label_fr": "Kollmitzberg"
            },
            {
                "value": 54842,
                "label": "Ennsdorf",
                "label_ar": "اينسدورف",
                "label_fr": "Ennsdorf"
            },
            {
                "value": 54583,
                "label": "Klagenfurt",
                "label_ar": "كلاغنفورت",
                "label_fr": "Klagenfurt"
            },
            {
                "value": 55870,
                "label": "Feuersbrunn",
                "label_ar": "فويرسبرون",
                "label_fr": "Feuersbrunn"
            },
            {
                "value": 55453,
                "label": "Weissbriach",
                "label_ar": "فايسبرياخ",
                "label_fr": "Weissbriach"
            },
            {
                "value": 54651,
                "label": "Wagna",
                "label_ar": "واغنا",
                "label_fr": "Wagna"
            },
            {
                "value": 55620,
                "label": "Taxenbach",
                "label_ar": "تاكسينباخ",
                "label_fr": "Taxenbach"
            },
            {
                "value": 55707,
                "label": "Matrei am Brenner",
                "label_ar": "ماتري آم برينر",
                "label_fr": "Matrei am Brenner"
            },
            {
                "value": 54573,
                "label": "Schwoich",
                "label_ar": "شويش",
                "label_fr": "Schwoich"
            },
            {
                "value": 54847,
                "label": "Miesenbach",
                "label_ar": "ميسينباخ",
                "label_fr": "Miesenbach"
            },
            {
                "value": 54500,
                "label": "Hinterstoder",
                "label_ar": "Hinterstoder",
                "label_fr": "Hinterstoder"
            },
            {
                "value": 55690,
                "label": "Naarn im Machlande",
                "label_ar": "Naarn im Machlande",
                "label_fr": "Naarn im Machlande"
            },
            {
                "value": 55418,
                "label": "Spital am Pyhrn",
                "label_ar": "سبيتال ام بيرن",
                "label_fr": "Spital am Pyhrn"
            },
            {
                "value": 55797,
                "label": "Lassee",
                "label_ar": "لاسي",
                "label_fr": "Lassee"
            },
            {
                "value": 55244,
                "label": "Miesenbach",
                "label_ar": "ميسينباخ",
                "label_fr": "Miesenbach"
            },
            {
                "value": 55576,
                "label": "Prinzersdorf",
                "label_ar": "برينزرسدورف",
                "label_fr": "Prinzersdorf"
            },
            {
                "value": 54756,
                "label": "Mayrhofen",
                "label_ar": "مايرهوفن",
                "label_fr": "Mayrhofen"
            },
            {
                "value": 54800,
                "label": "Pertisau",
                "label_ar": "بيرتيساو",
                "label_fr": "Pertisau"
            },
            {
                "value": 54976,
                "label": "Maildorf",
                "label_ar": "ميلدورف",
                "label_fr": "Maildorf"
            },
            {
                "value": 54498,
                "label": "Judendorf",
                "label_ar": "جوديندورف",
                "label_fr": "Judendorf"
            },
            {
                "value": 55702,
                "label": "Sachsenburg",
                "label_ar": "ساشسينبورغ",
                "label_fr": "Sachsenburg"
            },
            {
                "value": 55260,
                "label": "Stein an der Donau",
                "label_ar": "شتاين ان دير دوناو",
                "label_fr": "Stein an der Donau"
            },
            {
                "value": 55360,
                "label": "Roppen",
                "label_ar": "روبن",
                "label_fr": "Roppen"
            },
            {
                "value": 54659,
                "label": "Hatting",
                "label_ar": "هاتينج",
                "label_fr": "Hatting"
            },
            {
                "value": 54959,
                "label": "Seeboden",
                "label_ar": "سيبودن",
                "label_fr": "Seeboden"
            },
            {
                "value": 55772,
                "label": "Doblbach",
                "label_ar": "دوبلباخ",
                "label_fr": "Doblbach"
            },
            {
                "value": 55623,
                "label": "Huttschlag",
                "label_ar": "هوتشلاغ",
                "label_fr": "Huttschlag"
            },
            {
                "value": 54757,
                "label": "Stattegg",
                "label_ar": "ستاتيج",
                "label_fr": "Stattegg"
            },
            {
                "value": 54817,
                "label": "Bartholomaberg",
                "label_ar": "بارثولومابيرج",
                "label_fr": "Bartholomaberg"
            },
            {
                "value": 54980,
                "label": "Markt Neuhodis",
                "label_ar": "ماركت نوهوديس",
                "label_fr": "Markt Neuhodis"
            },
            {
                "value": 54812,
                "label": "Wald am Arlberg",
                "label_ar": "والد آم أرلبيرغ",
                "label_fr": "Wald am Arlberg"
            },
            {
                "value": 55246,
                "label": "Semmering",
                "label_ar": "سمرينغ",
                "label_fr": "Semmering"
            },
            {
                "value": 54582,
                "label": "Aschach an der Donau",
                "label_ar": "Aschach an der Donau",
                "label_fr": "Aschach an der Donau"
            },
            {
                "value": 54510,
                "label": "Brixen im Thale",
                "label_ar": "بريكسين إم ثالي",
                "label_fr": "Brixen im Thale"
            },
            {
                "value": 55487,
                "label": "Hausleiten",
                "label_ar": "Hausleiten",
                "label_fr": "Hausleiten"
            },
            {
                "value": 54794,
                "label": "Matrei in Osttirol",
                "label_ar": "ماتري في أوستيرول",
                "label_fr": "Matrei à Osttirol"
            },
            {
                "value": 55397,
                "label": "Gostling an der Ybbs",
                "label_ar": "Gostling an der Ybbs",
                "label_fr": "Gostling an der Ybbs"
            },
            {
                "value": 55266,
                "label": "Lieboch",
                "label_ar": "ليبوش",
                "label_fr": "Lieboch"
            },
            {
                "value": 54777,
                "label": "Bad Gastein",
                "label_ar": "باد جاستين",
                "label_fr": "Bad Gastein"
            },
            {
                "value": 55089,
                "label": "Maria Taferl",
                "label_ar": "ماريا تافرل",
                "label_fr": "Maria Taferl"
            },
            {
                "value": 55837,
                "label": "Thuringen",
                "label_ar": "تورينغن",
                "label_fr": "Thuringen"
            },
            {
                "value": 55134,
                "label": "Oberndorf bei Salzburg",
                "label_ar": "اوبرندورف باي سالزبورغ",
                "label_fr": "Oberndorf bei Salzbourg"
            },
            {
                "value": 55349,
                "label": "Sankt Kanzian",
                "label_ar": "سانكت كانزيان",
                "label_fr": "Sankt Kanzian"
            },
            {
                "value": 55445,
                "label": "Nenzing",
                "label_ar": "نينزينج",
                "label_fr": "Nenzing"
            },
            {
                "value": 55748,
                "label": "Burgschleinitz",
                "label_ar": "بورغشلينيتس",
                "label_fr": "Burgschleinitz"
            },
            {
                "value": 54587,
                "label": "Innsbruck",
                "label_ar": "إنسبروك",
                "label_fr": "Innsbruck"
            },
            {
                "value": 55678,
                "label": "Fischerndorf",
                "label_ar": "فيشرندورف",
                "label_fr": "Fischerndorf"
            },
            {
                "value": 55033,
                "label": "Pasching",
                "label_ar": "الفصح",
                "label_fr": "Pasching"
            },
            {
                "value": 54988,
                "label": "Karlstetten",
                "label_ar": "كارلستيتن",
                "label_fr": "Karlstetten"
            },
            {
                "value": 55228,
                "label": "Teufenbach",
                "label_ar": "تيوفينباخ",
                "label_fr": "Teufenbach"
            },
            {
                "value": 55405,
                "label": "Arbesthal",
                "label_ar": "اربيثال",
                "label_fr": "Arbesthal"
            },
            {
                "value": 54898,
                "label": "Vocklamarkt",
                "label_ar": "فوكلاماركت",
                "label_fr": "Vocklamarkt"
            },
            {
                "value": 55776,
                "label": "Halbturn",
                "label_ar": "هالبتورن",
                "label_fr": "Halbturn"
            },
            {
                "value": 55373,
                "label": "Sierning",
                "label_ar": "سييرنينغ",
                "label_fr": "Sierning"
            },
            {
                "value": 55815,
                "label": "Bruck am Ziller",
                "label_ar": "بروك آم زيلر",
                "label_fr": "Bruck am Ziller"
            },
            {
                "value": 54714,
                "label": "Perchtoldsdorf",
                "label_ar": "بيرشتولدسدورف",
                "label_fr": "Perchtoldsdorf"
            },
            {
                "value": 54580,
                "label": "Enzenkirchen",
                "label_ar": "إنزينكيرشن",
                "label_fr": "Enzenkirchen"
            },
            {
                "value": 54522,
                "label": "Korneuburg",
                "label_ar": "كورنوبورج",
                "label_fr": "Korneuburg"
            },
            {
                "value": 54907,
                "label": "Spielberg bei Knittelfeld",
                "label_ar": "سبيلبرغ باي كنيتلفيلد",
                "label_fr": "Spielberg bei Knittelfeld"
            },
            {
                "value": 55804,
                "label": "Zeillern",
                "label_ar": "زيليرن",
                "label_fr": "Zeillern"
            },
            {
                "value": 55306,
                "label": "Sernau",
                "label_ar": "سيرناو",
                "label_fr": "Sernau"
            },
            {
                "value": 55665,
                "label": "Pottenstein",
                "label_ar": "بوتنشتاين",
                "label_fr": "Pottenstein"
            },
            {
                "value": 54887,
                "label": "Oggau",
                "label_ar": "أوغاو",
                "label_fr": "Oggau"
            },
            {
                "value": 54558,
                "label": "Fehring",
                "label_ar": "فيرينغ",
                "label_fr": "Fehring"
            },
            {
                "value": 55290,
                "label": "Gaspoltshofen",
                "label_ar": "جاسبولتشوفن",
                "label_fr": "Gaspoltshofen"
            },
            {
                "value": 55851,
                "label": "Grafendorf bei Hartberg",
                "label_ar": "جرافيندورف باي هارتبرج",
                "label_fr": "Grafendorf bei Hartberg"
            },
            {
                "value": 54850,
                "label": "Hofen",
                "label_ar": "هوفن",
                "label_fr": "Hofen"
            },
            {
                "value": 54598,
                "label": "Wallsee",
                "label_ar": "Wallsee",
                "label_fr": "Wallsee"
            },
            {
                "value": 55174,
                "label": "Innermanzing",
                "label_ar": "إينرمانزينج",
                "label_fr": "Innermanzing"
            },
            {
                "value": 55761,
                "label": "Gortipohl",
                "label_ar": "جورتيبول",
                "label_fr": "Gortipohl"
            },
            {
                "value": 54832,
                "label": "Doebriach",
                "label_ar": "دويبرياخ",
                "label_fr": "Doebriach"
            },
            {
                "value": 55141,
                "label": "Sankt Veit im Pongau",
                "label_ar": "سانكت فيت إم بونجاو",
                "label_fr": "Sankt Veit im Pongau"
            },
            {
                "value": 55230,
                "label": "Kapfing",
                "label_ar": "كابفينغ",
                "label_fr": "Kapfing"
            },
            {
                "value": 55750,
                "label": "Stams",
                "label_ar": "ستامس",
                "label_fr": "Stams"
            },
            {
                "value": 55403,
                "label": "Ernstbrunn",
                "label_ar": "ارنستبرون",
                "label_fr": "Ernstbrunn"
            },
            {
                "value": 54793,
                "label": "Oberau",
                "label_ar": "أوبيراو",
                "label_fr": "Oberau"
            },
            {
                "value": 54579,
                "label": "Rubring",
                "label_ar": "روبرينج",
                "label_fr": "Rubring"
            },
            {
                "value": 55468,
                "label": "Grossrussbach",
                "label_ar": "غروسروسباخ",
                "label_fr": "Grossrussbach"
            },
            {
                "value": 55356,
                "label": "Hohenwarth",
                "label_ar": "هوهنوارث",
                "label_fr": "Hohenwarth"
            },
            {
                "value": 54519,
                "label": "Plangeross",
                "label_ar": "بلانجروس",
                "label_fr": "Plangeross"
            },
            {
                "value": 54493,
                "label": "Kapfenberg",
                "label_ar": "كابفنبيرج",
                "label_fr": "Kapfenberg"
            },
            {
                "value": 55537,
                "label": "Jennersdorf",
                "label_ar": "جينرسدورف",
                "label_fr": "Jennersdorf"
            },
            {
                "value": 54993,
                "label": "Grafenbach",
                "label_ar": "جرافينباخ",
                "label_fr": "Grafenbach"
            },
            {
                "value": 55081,
                "label": "Lenzing",
                "label_ar": "لينزنغ",
                "label_fr": "Lenzing"
            },
            {
                "value": 54786,
                "label": "Stubach",
                "label_ar": "ستوباتش",
                "label_fr": "Stubach"
            },
            {
                "value": 55578,
                "label": "Neufeld an der Leitha",
                "label_ar": "نيوفيلد آن دير ليثا",
                "label_fr": "Neufeld an der Leitha"
            },
            {
                "value": 54760,
                "label": "Gotzis",
                "label_ar": "جوتزيس",
                "label_fr": "Gotzis"
            },
            {
                "value": 55390,
                "label": "Thalling",
                "label_ar": "ثقل",
                "label_fr": "Décongélation"
            },
            {
                "value": 54953,
                "label": "Latschach",
                "label_ar": "لاتشاخ",
                "label_fr": "Latschach"
            },
            {
                "value": 55688,
                "label": "Maria Elend",
                "label_ar": "ماريا اليند",
                "label_fr": "Maria Elend"
            },
            {
                "value": 55670,
                "label": "Sankt Peter",
                "label_ar": "سانكت بيتر",
                "label_fr": "Sankt Peter"
            },
            {
                "value": 55846,
                "label": "Niederwaldkirchen",
                "label_ar": "Niederwaldkirchen",
                "label_fr": "Niederwaldkirchen"
            },
            {
                "value": 55161,
                "label": "Kennelbach",
                "label_ar": "كينيلباخ",
                "label_fr": "Kennelbach"
            },
            {
                "value": 54559,
                "label": "Neunkirchen",
                "label_ar": "نيونكيرشن",
                "label_fr": "Neunkirchen"
            },
            {
                "value": 54964,
                "label": "Filzmoos",
                "label_ar": "فيلزموس",
                "label_fr": "Filzmoos"
            },
            {
                "value": 54602,
                "label": "Leoben",
                "label_ar": "يوبين",
                "label_fr": "Leoben"
            },
            {
                "value": 55389,
                "label": "Waizenkirchen",
                "label_ar": "وايزنكيرشن",
                "label_fr": "Waizenkirchen"
            },
            {
                "value": 55840,
                "label": "Semriach",
                "label_ar": "Semriach",
                "label_fr": "Semriach"
            },
            {
                "value": 55368,
                "label": "Hausbrunn",
                "label_ar": "Hausbrunn",
                "label_fr": "Hausbrunn"
            },
            {
                "value": 55050,
                "label": "Turnitz",
                "label_ar": "تيرنيتز",
                "label_fr": "Turnitz"
            },
            {
                "value": 54647,
                "label": "Neudorf bei Staatz",
                "label_ar": "نيودورف باي ستاتز",
                "label_fr": "Neudorf bei Staatz"
            },
            {
                "value": 54520,
                "label": "Wilhelmsburg",
                "label_ar": "فيلهلمسبورج",
                "label_fr": "Wilhelmsburg"
            },
            {
                "value": 55015,
                "label": "Sankt Wolfgang",
                "label_ar": "سانكت ولفجانج",
                "label_fr": "Sankt Wolfgang"
            },
            {
                "value": 54526,
                "label": "Mondsee",
                "label_ar": "موندزي",
                "label_fr": "Mondsee"
            },
            {
                "value": 54863,
                "label": "Kirchberg am Wagram",
                "label_ar": "كيرشبرغ آم فاجرام",
                "label_fr": "Kirchberg am Wagram"
            },
            {
                "value": 54533,
                "label": "Schweiggers",
                "label_ar": "شفايجرز",
                "label_fr": "Schweiggers"
            },
            {
                "value": 55511,
                "label": "Frankenmarkt",
                "label_ar": "فرانكنماركت",
                "label_fr": "Frankenmarkt"
            },
            {
                "value": 54653,
                "label": "Lanzenkirchen",
                "label_ar": "لانزنكيرشن",
                "label_fr": "Lanzenkirchen"
            },
            {
                "value": 55195,
                "label": "Lofer",
                "label_ar": "لوفر",
                "label_fr": "Lofer"
            },
            {
                "value": 55877,
                "label": "Weng bei Admont",
                "label_ar": "ونغ باي أدمونت",
                "label_fr": "Weng bei Admont"
            },
            {
                "value": 55699,
                "label": "Altenmarkt an der Triesting",
                "label_ar": "Altenmarkt an der Triesting",
                "label_fr": "Altenmarkt an der Triesting"
            },
            {
                "value": 55364,
                "label": "Fischbach",
                "label_ar": "فيشباخ",
                "label_fr": "Fischbach"
            },
            {
                "value": 54511,
                "label": "Schwaz",
                "label_ar": "شواز",
                "label_fr": "Schwaz"
            },
            {
                "value": 55729,
                "label": "Utzenaich",
                "label_ar": "Utzenaich",
                "label_fr": "Utzenaich"
            },
            {
                "value": 55351,
                "label": "Kuehnsdorf",
                "label_ar": "كوهنسدورف",
                "label_fr": "Kuehnsdorf"
            },
            {
                "value": 54593,
                "label": "Luftenberg an der Donau",
                "label_ar": "لوفتنبرغ أن دير دوناو",
                "label_fr": "Luftenberg an der Donau"
            },
            {
                "value": 55600,
                "label": "Loosdorf",
                "label_ar": "لوسدورف",
                "label_fr": "Loosdorf"
            },
            {
                "value": 55530,
                "label": "Grossklein",
                "label_ar": "جروسكلين",
                "label_fr": "Grossklein"
            },
            {
                "value": 55175,
                "label": "Enzesfeld",
                "label_ar": "انزيسفيلد",
                "label_fr": "Enzesfeld"
            },
            {
                "value": 55561,
                "label": "Gugging",
                "label_ar": "تعانق",
                "label_fr": "Gugging"
            },
            {
                "value": 55611,
                "label": "Wies",
                "label_ar": "ويس",
                "label_fr": "Wies"
            },
            {
                "value": 55496,
                "label": "Rangersdorf",
                "label_ar": "رينجرسدورف",
                "label_fr": "Rangersdorf"
            },
            {
                "value": 55558,
                "label": "Kumberg",
                "label_ar": "كمبيرج",
                "label_fr": "Kumberg"
            },
            {
                "value": 54834,
                "label": "Millstatt",
                "label_ar": "ميلستات",
                "label_fr": "Millstatt"
            },
            {
                "value": 55495,
                "label": "Eberstein",
                "label_ar": "إبرشتاين",
                "label_fr": "Eberstein"
            },
            {
                "value": 55661,
                "label": "Strass",
                "label_ar": "ستراس",
                "label_fr": "Strass"
            },
            {
                "value": 54718,
                "label": "Horbranz",
                "label_ar": "هوربرانز",
                "label_fr": "Horbranz"
            },
            {
                "value": 55070,
                "label": "Gotzendorf an der Leitha",
                "label_ar": "Gotzendorf an der Leitha",
                "label_fr": "Gotzendorf an der Leitha"
            },
            {
                "value": 55584,
                "label": "Krumbach",
                "label_ar": "كرومباخ",
                "label_fr": "Krumbach"
            },
            {
                "value": 54548,
                "label": "Pillichsdorf",
                "label_ar": "بيلشسدورف",
                "label_fr": "Pillichsdorf"
            },
            {
                "value": 54742,
                "label": "Zillingdorf",
                "label_ar": "زلينجدورف",
                "label_fr": "Zillingdorf"
            },
            {
                "value": 55123,
                "label": "Seeham",
                "label_ar": "سيهام",
                "label_fr": "Seeham"
            },
            {
                "value": 54882,
                "label": "Mader",
                "label_ar": "مادير",
                "label_fr": "Mader"
            },
            {
                "value": 55358,
                "label": "Stegersbach",
                "label_ar": "ستيجرسباخ",
                "label_fr": "Stegersbach"
            },
            {
                "value": 54603,
                "label": "Weisskirchen in Steiermark",
                "label_ar": "Weisskirchen في Steiermark",
                "label_fr": "Weisskirchen à Steiermark"
            },
            {
                "value": 55490,
                "label": "Sistrans",
                "label_ar": "سيسترانس",
                "label_fr": "Sistrans"
            },
            {
                "value": 55599,
                "label": "Pischeldorf",
                "label_ar": "بيشيلدورف",
                "label_fr": "Pischeldorf"
            },
            {
                "value": 55172,
                "label": "Hofstatten an der Raab",
                "label_ar": "Hofstatten an der Raab",
                "label_fr": "Hofstatten an der Raab"
            },
            {
                "value": 54908,
                "label": "Tauplitz",
                "label_ar": "تاوبليتز",
                "label_fr": "Tauplitz"
            },
            {
                "value": 54948,
                "label": "Litschau",
                "label_ar": "ليتشاو",
                "label_fr": "Litschau"
            },
            {
                "value": 54896,
                "label": "Aichbach",
                "label_ar": "ايتشباخ",
                "label_fr": "Aichbach"
            },
            {
                "value": 54737,
                "label": "Ainet",
                "label_ar": "عينيت",
                "label_fr": "Ainet"
            },
            {
                "value": 54833,
                "label": "Maria Saal",
                "label_ar": "ماريا سال",
                "label_fr": "Maria Saal"
            },
            {
                "value": 55342,
                "label": "Kefermarkt",
                "label_ar": "Kefermarkt",
                "label_fr": "Kefermarkt"
            },
            {
                "value": 54864,
                "label": "Schwadorf",
                "label_ar": "شوادورف",
                "label_fr": "Schwadorf"
            },
            {
                "value": 55740,
                "label": "Kobersdorf",
                "label_ar": "كوبرسدورف",
                "label_fr": "Kobersdorf"
            },
            {
                "value": 55542,
                "label": "Riegersburg",
                "label_ar": "Riegersburg",
                "label_fr": "Riegersburg"
            },
            {
                "value": 55132,
                "label": "Tiefenbach",
                "label_ar": "تيفينباخ",
                "label_fr": "Tiefenbach"
            },
            {
                "value": 55117,
                "label": "Goldegg",
                "label_ar": "جولدج",
                "label_fr": "Goldegg"
            },
            {
                "value": 54991,
                "label": "Maiersdorf",
                "label_ar": "مايرسدورف",
                "label_fr": "Maiersdorf"
            },
            {
                "value": 54567,
                "label": "Ollern",
                "label_ar": "اوليرن",
                "label_fr": "Ollern"
            },
            {
                "value": 55853,
                "label": "Deutsch Goritz",
                "label_ar": "دويتش جوريتز",
                "label_fr": "Deutsch Goritz"
            },
            {
                "value": 55756,
                "label": "Sankt Stefan im Rosental",
                "label_ar": "سانكت ستيفان إم روزنتال",
                "label_fr": "Sankt Stefan im Rosental"
            },
            {
                "value": 55510,
                "label": "Neumarkt am Wallersee",
                "label_ar": "نيوماركت ام والرسي",
                "label_fr": "Neumarkt am Wallersee"
            },
            {
                "value": 55829,
                "label": "Gofis",
                "label_ar": "جوفيس",
                "label_fr": "Gofis"
            },
            {
                "value": 54630,
                "label": "Kirchschlag bei Linz",
                "label_ar": "Kirchschlag باي لينز",
                "label_fr": "Kirchschlag bei Linz"
            },
            {
                "value": 54736,
                "label": "Rannersdorf",
                "label_ar": "رانرسدورف",
                "label_fr": "Rannersdorf"
            },
            {
                "value": 55498,
                "label": "Eisentratten",
                "label_ar": "ايزنتراتن",
                "label_fr": "Eisentratten"
            },
            {
                "value": 55430,
                "label": "Gurtis",
                "label_ar": "جورتيس",
                "label_fr": "Gurtis"
            },
            {
                "value": 55436,
                "label": "Lavamund",
                "label_ar": "لافاموند",
                "label_fr": "Lavamund"
            },
            {
                "value": 54969,
                "label": "Grossarl",
                "label_ar": "غروسارل",
                "label_fr": "Grossarl"
            },
            {
                "value": 55264,
                "label": "Vordernberg",
                "label_ar": "فوردينبيرج",
                "label_fr": "Vordernberg"
            },
            {
                "value": 54949,
                "label": "Guntramsdorf",
                "label_ar": "جونترامسدورف",
                "label_fr": "Guntramsdorf"
            },
            {
                "value": 55863,
                "label": "Siebenhirten",
                "label_ar": "Siebenhirten",
                "label_fr": "Siebenhirten"
            },
            {
                "value": 55110,
                "label": "Kaprun",
                "label_ar": "كابرون",
                "label_fr": "Kaprun"
            },
            {
                "value": 55189,
                "label": "Wildon",
                "label_ar": "ويلدون",
                "label_fr": "Wildon"
            },
            {
                "value": 55345,
                "label": "Peuerbach",
                "label_ar": "بيورباخ",
                "label_fr": "Peuerbach"
            },
            {
                "value": 55273,
                "label": "Ort im Innkreis",
                "label_ar": "Ort im Innkreis",
                "label_fr": "Ort im Innkreis"
            },
            {
                "value": 54675,
                "label": "Grossweikersdorf",
                "label_ar": "Grossweikersdorf",
                "label_fr": "Grossweikersdorf"
            },
            {
                "value": 54641,
                "label": "Amstetten",
                "label_ar": "أمستيتن",
                "label_fr": "Amstetten"
            },
            {
                "value": 55784,
                "label": "Forst",
                "label_ar": "فورست",
                "label_fr": "Forst"
            },
            {
                "value": 55129,
                "label": "Koppl",
                "label_ar": "كوببل",
                "label_fr": "Koppl"
            },
            {
                "value": 54931,
                "label": "Kitzbühel",
                "label_ar": "كيتزبوهيل",
                "label_fr": "Kitzbühel"
            },
            {
                "value": 54619,
                "label": "Gunskirchen",
                "label_ar": "Gunskirchen",
                "label_fr": "Gunskirchen"
            },
            {
                "value": 54546,
                "label": "Markgrafneusiedl",
                "label_ar": "Markgrafneusiedl",
                "label_fr": "Markgrafneusiedl"
            },
            {
                "value": 55303,
                "label": "Steyregg",
                "label_ar": "ستيريج",
                "label_fr": "Steyregg"
            },
            {
                "value": 55425,
                "label": "Schwaigs",
                "label_ar": "شويغز",
                "label_fr": "Schwaigs"
            },
            {
                "value": 54895,
                "label": "Gols",
                "label_ar": "جولس",
                "label_fr": "Gols"
            },
            {
                "value": 54902,
                "label": "Mairhof",
                "label_ar": "مايرهوف",
                "label_fr": "Mairhof"
            },
            {
                "value": 54925,
                "label": "Panzendorf",
                "label_ar": "بانزيندورف",
                "label_fr": "Panzendorf"
            },
            {
                "value": 55006,
                "label": "Rotenbach",
                "label_ar": "روتنباخ",
                "label_fr": "Rotenbach"
            },
            {
                "value": 55856,
                "label": "Jenbach",
                "label_ar": "جينباخ",
                "label_fr": "Jenbach"
            },
            {
                "value": 55643,
                "label": "Langenrohr",
                "label_ar": "لانجينروهر",
                "label_fr": "Langenrohr"
            },
            {
                "value": 55334,
                "label": "Pottsching",
                "label_ar": "بوتسشينغ",
                "label_fr": "Pottsching"
            },
            {
                "value": 55562,
                "label": "Mannersdorf am Leithagebirge",
                "label_ar": "مانرسدورف ام لايثاجبيرج",
                "label_fr": "Mannersdorf am Leithagebirge"
            },
            {
                "value": 54563,
                "label": "Ternitz",
                "label_ar": "تيرنيتز",
                "label_fr": "Ternitz"
            },
            {
                "value": 55032,
                "label": "Asberg",
                "label_ar": "اسبيرج",
                "label_fr": "Asberg"
            },
            {
                "value": 54518,
                "label": "Jerzens",
                "label_ar": "جرزنس",
                "label_fr": "Jerzens"
            },
            {
                "value": 55833,
                "label": "Toesenssteinach",
                "label_ar": "Toesenssteinach",
                "label_fr": "Toesenssteinach"
            },
            {
                "value": 55812,
                "label": "Grafendorf",
                "label_ar": "جرافيندورف",
                "label_fr": "Grafendorf"
            },
            {
                "value": 54957,
                "label": "Ossiach",
                "label_ar": "Ossiach",
                "label_fr": "Ossiach"
            },
            {
                "value": 54856,
                "label": "Wurmbrand",
                "label_ar": "ورمبراند",
                "label_fr": "Wurmbrand"
            },
            {
                "value": 55056,
                "label": "Gramatneusiedl",
                "label_ar": "غراماتنيوزيدل",
                "label_fr": "Gramatneusiedl"
            },
            {
                "value": 54826,
                "label": "Sankt Polten",
                "label_ar": "سانكت بولتن",
                "label_fr": "Sankt Polten"
            },
            {
                "value": 55711,
                "label": "Oberwolbling",
                "label_ar": "Oberwolbling",
                "label_fr": "Oberwolbling"
            },
            {
                "value": 55378,
                "label": "Stoob",
                "label_ar": "ستوب",
                "label_fr": "Stoob"
            },
            {
                "value": 54716,
                "label": "Himmelreich",
                "label_ar": "هيملريتش",
                "label_fr": "Himmelreich"
            },
            {
                "value": 55114,
                "label": "Werfen",
                "label_ar": "ويرفن",
                "label_fr": "Werfen"
            },
            {
                "value": 55269,
                "label": "Nuziders",
                "label_ar": "نوزيدر",
                "label_fr": "Nuziders"
            },
            {
                "value": 54859,
                "label": "Steinach am Brenner",
                "label_ar": "Steinach am Brenner",
                "label_fr": "Steinach am Brenner"
            },
            {
                "value": 54692,
                "label": "Bad Gleichenberg",
                "label_ar": "باد جليتشنبرج",
                "label_fr": "Bad Gleichenberg"
            },
            {
                "value": 54810,
                "label": "Gschnitz",
                "label_ar": "غشنيتز",
                "label_fr": "Gschnitz"
            },
            {
                "value": 55328,
                "label": "Krispl",
                "label_ar": "كريسبل",
                "label_fr": "Krispl"
            },
            {
                "value": 55329,
                "label": "Tadten",
                "label_ar": "تادتن",
                "label_fr": "Tadten"
            },
            {
                "value": 54691,
                "label": "Seefeld in Tirol",
                "label_ar": "سيفيلد ان تيرول",
                "label_fr": "Seefeld au Tyrol"
            },
            {
                "value": 55136,
                "label": "Golling an der Salzach",
                "label_ar": "جولنج ان دير سالزاتش",
                "label_fr": "Golling an der Salzach"
            },
            {
                "value": 54492,
                "label": "Vienna",
                "label_ar": "فيينا",
                "label_fr": "Vienne"
            },
            {
                "value": 54553,
                "label": "Lannach",
                "label_ar": "لاناخ",
                "label_fr": "Lannach"
            },
            {
                "value": 54628,
                "label": "Attnang-Puchheim",
                "label_ar": "أتنانج بوتشيم",
                "label_fr": "Attnang-Puchheim"
            },
            {
                "value": 54884,
                "label": "Rohrau",
                "label_ar": "روهراو",
                "label_fr": "Rohrau"
            },
            {
                "value": 55163,
                "label": "Hausmannstatten",
                "label_ar": "Hausmannstatten",
                "label_fr": "Hausmannstatten"
            },
            {
                "value": 54784,
                "label": "Obertauern",
                "label_ar": "اوبرتاورن",
                "label_fr": "Obertauern"
            },
            {
                "value": 55557,
                "label": "Blumau",
                "label_ar": "بلوماو",
                "label_fr": "Blumau"
            },
            {
                "value": 55839,
                "label": "Inzersdorf im Kremstal",
                "label_ar": "Inzersdorf im Kremstal",
                "label_fr": "Inzersdorf im Kremstal"
            },
            {
                "value": 54965,
                "label": "Pichl",
                "label_ar": "بيشل",
                "label_fr": "Pichl"
            },
            {
                "value": 54920,
                "label": "Weissenbach am Lech",
                "label_ar": "Weissenbach am Lech",
                "label_fr": "Weissenbach am Lech"
            },
            {
                "value": 55656,
                "label": "Tieschen",
                "label_ar": "تيشين",
                "label_fr": "Tieschen"
            },
            {
                "value": 55234,
                "label": "Passthurn",
                "label_ar": "العبور",
                "label_fr": "Passthurn"
            },
            {
                "value": 54932,
                "label": "Hoehe",
                "label_ar": "هوهي",
                "label_fr": "Hoehe"
            },
            {
                "value": 55241,
                "label": "Augsdorf",
                "label_ar": "اوغسدورف",
                "label_fr": "Augsdorf"
            },
            {
                "value": 55298,
                "label": "Plescherken",
                "label_ar": "بليشركين",
                "label_fr": "Plescherken"
            },
            {
                "value": 55679,
                "label": "Unterberg",
                "label_ar": "أونتربيرج",
                "label_fr": "Unterberg"
            },
            {
                "value": 55698,
                "label": "Enzersfeld",
                "label_ar": "انزرسفيلد",
                "label_fr": "Enzersfeld"
            },
            {
                "value": 55817,
                "label": "Sooss",
                "label_ar": "سوس",
                "label_fr": "Sooss"
            },
            {
                "value": 54506,
                "label": "Fieberbrunn",
                "label_ar": "فيبربرون",
                "label_fr": "Fieberbrunn"
            },
            {
                "value": 54656,
                "label": "Wiesmath",
                "label_ar": "ويسماث",
                "label_fr": "Wiesmath"
            },
            {
                "value": 54871,
                "label": "Oberkappel",
                "label_ar": "Oberkappel",
                "label_fr": "Oberkappel"
            },
            {
                "value": 54946,
                "label": "Langenlois",
                "label_ar": "لانجينلوا",
                "label_fr": "Langenlois"
            },
            {
                "value": 54962,
                "label": "Rauris",
                "label_ar": "روريس",
                "label_fr": "Rauris"
            },
            {
                "value": 54683,
                "label": "Feldkirchen an der Donau",
                "label_ar": "Feldkirchen an der Donau",
                "label_fr": "Feldkirchen an der Donau"
            },
            {
                "value": 55588,
                "label": "Troepolach",
                "label_ar": "ترويبولاتش",
                "label_fr": "Troepolach"
            },
            {
                "value": 55379,
                "label": "Sankt Martin am Ybbsfelde",
                "label_ar": "سانكت مارتن آم يبسفيلدي",
                "label_fr": "Sankt Martin am Ybbsfelde"
            },
            {
                "value": 55649,
                "label": "Mooskirchen",
                "label_ar": "موسكيرشن",
                "label_fr": "Mooskirchen"
            },
            {
                "value": 55564,
                "label": "Obervellach",
                "label_ar": "أوبيرفيلاخ",
                "label_fr": "Obervellach"
            },
            {
                "value": 55736,
                "label": "Eggendorf",
                "label_ar": "إيجيندورف",
                "label_fr": "Eggendorf"
            },
            {
                "value": 55462,
                "label": "Gloggnitz",
                "label_ar": "جلوجنيتز",
                "label_fr": "Gloggnitz"
            },
            {
                "value": 55481,
                "label": "Klingfurth",
                "label_ar": "كلينجفورث",
                "label_fr": "Klingfurth"
            },
            {
                "value": 54650,
                "label": "Kalsdorf bei Graz",
                "label_ar": "كالسدورف باي غراتس",
                "label_fr": "Kalsdorf bei Graz"
            },
            {
                "value": 55875,
                "label": "Mauterndorf",
                "label_ar": "ماوترندورف",
                "label_fr": "Mauterndorf"
            },
            {
                "value": 55455,
                "label": "Peggau",
                "label_ar": "بيجاو",
                "label_fr": "Peggau"
            },
            {
                "value": 55301,
                "label": "Kleinbergl",
                "label_ar": "كلاينبيرجل",
                "label_fr": "Kleinbergl"
            },
            {
                "value": 55094,
                "label": "Zuers",
                "label_ar": "زويرس",
                "label_fr": "Zuers"
            },
            {
                "value": 55508,
                "label": "Schalchen",
                "label_ar": "شالتشن",
                "label_fr": "Schalchen"
            },
            {
                "value": 55642,
                "label": "Aichdorf",
                "label_ar": "ايتشدورف",
                "label_fr": "Aichdorf"
            },
            {
                "value": 55554,
                "label": "Erpfendorf",
                "label_ar": "اربفندورف",
                "label_fr": "Erpfendorf"
            },
            {
                "value": 54626,
                "label": "Niederneukirchen",
                "label_ar": "نيديرنيوكيرشن",
                "label_fr": "Niederneukirchen"
            },
            {
                "value": 54775,
                "label": "Holzhausen",
                "label_ar": "هولزهاوزن",
                "label_fr": "Holzhausen"
            },
            {
                "value": 55703,
                "label": "Lendorf",
                "label_ar": "ليندورف",
                "label_fr": "Lendorf"
            },
            {
                "value": 54799,
                "label": "Bruggen",
                "label_ar": "بروجن",
                "label_fr": "Bruggen"
            },
            {
                "value": 54534,
                "label": "Weitra",
                "label_ar": "ويترا",
                "label_fr": "Weitra"
            },
            {
                "value": 54942,
                "label": "Bichlbach",
                "label_ar": "بيشلباخ",
                "label_fr": "Bichlbach"
            },
            {
                "value": 55091,
                "label": "Gosing",
                "label_ar": "النشوة",
                "label_fr": "Gosing"
            },
            {
                "value": 55190,
                "label": "Spittal an der Drau",
                "label_ar": "Spittal an der Drau",
                "label_fr": "Spittal an der Drau"
            },
            {
                "value": 55060,
                "label": "Trumau",
                "label_ar": "تروماو",
                "label_fr": "Trumau"
            },
            {
                "value": 55255,
                "label": "Patsch",
                "label_ar": "باتش",
                "label_fr": "Patsch"
            },
            {
                "value": 55420,
                "label": "Waltersdorf in Oststeiermark",
                "label_ar": "Waltersdorf في Oststeiermark",
                "label_fr": "Waltersdorf à Oststeiermark"
            },
            {
                "value": 55384,
                "label": "Judenau",
                "label_ar": "يودناو",
                "label_fr": "Judenau"
            },
            {
                "value": 55125,
                "label": "Mattsee",
                "label_ar": "ماتسي",
                "label_fr": "Mattsee"
            },
            {
                "value": 54615,
                "label": "Wolfsberg",
                "label_ar": "ولفسبرج",
                "label_fr": "Wolfsberg"
            },
            {
                "value": 55247,
                "label": "Pirka",
                "label_ar": "بيركا",
                "label_fr": "Pirka"
            },
            {
                "value": 55834,
                "label": "Schlitters",
                "label_ar": "شليترز",
                "label_fr": "Schlitters"
            },
            {
                "value": 55632,
                "label": "Munzbach",
                "label_ar": "مونزباخ",
                "label_fr": "Munzbach"
            },
            {
                "value": 54837,
                "label": "Velden am Woerthersee",
                "label_ar": "فيلدين ام ورثرسي",
                "label_fr": "Velden am Woerthersee"
            },
            {
                "value": 55566,
                "label": "Guessing",
                "label_ar": "التخمين",
                "label_fr": "Devinant"
            },
            {
                "value": 55065,
                "label": "Hainfeld",
                "label_ar": "هينفيلد",
                "label_fr": "Hainfeld"
            },
            {
                "value": 54726,
                "label": "Schwechat",
                "label_ar": "شفيشات",
                "label_fr": "Schwechat"
            },
            {
                "value": 54755,
                "label": "Kramsach",
                "label_ar": "كرامساش",
                "label_fr": "Kramsach"
            },
            {
                "value": 54897,
                "label": "Lindenberg",
                "label_ar": "ليندنبرج",
                "label_fr": "Lindenberg"
            },
            {
                "value": 55289,
                "label": "Wilhering",
                "label_ar": "ويلهيرينغ",
                "label_fr": "Wilhering"
            },
            {
                "value": 55550,
                "label": "Wimpassing im Schwarzatale",
                "label_ar": "ويمباسينغ إم شوارزاتالي",
                "label_fr": "Wimpassing im Schwarzatale"
            },
            {
                "value": 54765,
                "label": "Schwanenstadt",
                "label_ar": "شواننشتات",
                "label_fr": "Schwanenstadt"
            },
            {
                "value": 55249,
                "label": "Steinerkirchen an der Traun",
                "label_ar": "Steinerkirchen an der Traun",
                "label_fr": "Steinerkirchen an der Traun"
            },
            {
                "value": 55287,
                "label": "Alberndorf in der Riedmark",
                "label_ar": "ألبيرندورف في دير ريدمارك",
                "label_fr": "Alberndorf in der Riedmark"
            },
            {
                "value": 55105,
                "label": "Hagenberg im Muhlkreis",
                "label_ar": "هاجنبرغ إم مولكريس",
                "label_fr": "Hagenberg im Muhlkreis"
            },
            {
                "value": 54808,
                "label": "Walchsee",
                "label_ar": "والشسي",
                "label_fr": "Walchsee"
            },
            {
                "value": 54667,
                "label": "Rankweil",
                "label_ar": "رانكويل",
                "label_fr": "Rankweil"
            },
            {
                "value": 55261,
                "label": "Glinzendorf",
                "label_ar": "جلينزيندورف",
                "label_fr": "Glinzendorf"
            },
            {
                "value": 54852,
                "label": "Untersiebenbrunn",
                "label_ar": "أونتيرسيبينبرون",
                "label_fr": "Untersiebenbrunn"
            },
            {
                "value": 54802,
                "label": "Finkenberg",
                "label_ar": "فينكينبيرج",
                "label_fr": "Finkenberg"
            },
            {
                "value": 54747,
                "label": "Gablitz",
                "label_ar": "جابليتز",
                "label_fr": "Gablitz"
            },
            {
                "value": 54788,
                "label": "Tristach",
                "label_ar": "تريستاش",
                "label_fr": "Tristach"
            },
            {
                "value": 54724,
                "label": "Ansfelden",
                "label_ar": "أنسفيلدن",
                "label_fr": "Ansfelden"
            },
            {
                "value": 55478,
                "label": "Inzing",
                "label_ar": "إنغينغ",
                "label_fr": "Inzing"
            },
            {
                "value": 55482,
                "label": "Potzneusiedl",
                "label_ar": "Potzneusiedl",
                "label_fr": "Potzneusiedl"
            },
            {
                "value": 55339,
                "label": "Weisskirchen an der Traun",
                "label_ar": "Weisskirchen an der Traun",
                "label_fr": "Weisskirchen an der Traun"
            },
            {
                "value": 55631,
                "label": "Obernberg",
                "label_ar": "اوبرنبرغ",
                "label_fr": "Obernberg"
            },
            {
                "value": 55827,
                "label": "Haag",
                "label_ar": "هاج",
                "label_fr": "Haag"
            },
            {
                "value": 54550,
                "label": "Zettling",
                "label_ar": "زيتلينج",
                "label_fr": "Zettling"
            },
            {
                "value": 55613,
                "label": "Altendorf",
                "label_ar": "ألتندورف",
                "label_fr": "Altendorf"
            },
            {
                "value": 55414,
                "label": "Madseit",
                "label_ar": "مادسيت",
                "label_fr": "Madseit"
            },
            {
                "value": 55149,
                "label": "Zams",
                "label_ar": "الزامز",
                "label_fr": "Zams"
            },
            {
                "value": 55157,
                "label": "Reichenau an der Rax",
                "label_ar": "Reichenau an der Rax",
                "label_fr": "Reichenau an der Rax"
            },
            {
                "value": 54781,
                "label": "Sankt Johann im Pongau",
                "label_ar": "سانكت جوهان إم بونجاو",
                "label_fr": "Sankt Johann im Pongau"
            },
            {
                "value": 54801,
                "label": "Kuehtai",
                "label_ar": "Kuehtai",
                "label_fr": "Kuehtai"
            },
            {
                "value": 55410,
                "label": "Niederland",
                "label_ar": "نيدرلاند",
                "label_fr": "Niederland"
            },
            {
                "value": 55619,
                "label": "Pattergassen",
                "label_ar": "باترغاسين",
                "label_fr": "Pattergassen"
            },
            {
                "value": 54870,
                "label": "Seewalchen",
                "label_ar": "Seewalchen",
                "label_fr": "Seewalchen"
            },
            {
                "value": 54792,
                "label": "Vent",
                "label_ar": "تنفيس",
                "label_fr": "Évent"
            },
            {
                "value": 55184,
                "label": "Kaltenbrunn",
                "label_ar": "كالتنبرون",
                "label_fr": "Kaltenbrunn"
            },
            {
                "value": 55369,
                "label": "Zwettl Stadt",
                "label_ar": "زويتل شتات",
                "label_fr": "Zwettl Stadt"
            },
            {
                "value": 55079,
                "label": "Mattersburg",
                "label_ar": "ماتيرسبيرغ",
                "label_fr": "Mattersburg"
            },
            {
                "value": 55666,
                "label": "Ampass",
                "label_ar": "أمباس",
                "label_fr": "Ampass"
            },
            {
                "value": 55387,
                "label": "Opponitz",
                "label_ar": "أوبونيتز",
                "label_fr": "Opponitz"
            },
            {
                "value": 54981,
                "label": "Liezen",
                "label_ar": "ليزين",
                "label_fr": "Liezen"
            },
            {
                "value": 55654,
                "label": "Biedermann",
                "label_ar": "بيدرمان",
                "label_fr": "Biedermann"
            },
            {
                "value": 54770,
                "label": "Leonding",
                "label_ar": "يوندنج",
                "label_fr": "Leonding"
            },
            {
                "value": 55732,
                "label": "Unterparschenbrunn",
                "label_ar": "Unterparschenbrunn",
                "label_fr": "Unterparschenbrunn"
            },
            {
                "value": 54644,
                "label": "Emmersdorf an der Donau",
                "label_ar": "Emmersdorf an der Donau",
                "label_fr": "Emmersdorf an der Donau"
            },
            {
                "value": 54539,
                "label": "Taufkirchen an der Trattnach",
                "label_ar": "Taufkirchen an der Trattnach",
                "label_fr": "Taufkirchen an der Trattnach"
            },
            {
                "value": 54795,
                "label": "Rinn",
                "label_ar": "رين",
                "label_fr": "Rinn"
            },
            {
                "value": 54830,
                "label": "Poertschach am Woerthersee",
                "label_ar": "Poertschach am Woerthersee",
                "label_fr": "Poertschach am Woerthersee"
            },
            {
                "value": 55421,
                "label": "Schonbuhel an der Donau",
                "label_ar": "Schonbuhel an der Donau",
                "label_fr": "Schonbuhel an der Donau"
            },
            {
                "value": 54689,
                "label": "Horsching",
                "label_ar": "فقس",
                "label_fr": "Chevaucher"
            },
            {
                "value": 54956,
                "label": "Sankt Martin am Techelsberg",
                "label_ar": "سانكت مارتن آم تيكيلسبيرغ",
                "label_fr": "Sankt Martin am Techelsberg"
            },
            {
                "value": 55086,
                "label": "Leogang",
                "label_ar": "ليوغانغ",
                "label_fr": "Leogang"
            },
            {
                "value": 55385,
                "label": "Konigstetten",
                "label_ar": "Konigstetten",
                "label_fr": "Konigstetten"
            },
            {
                "value": 55884,
                "label": "Hochsoelden",
                "label_ar": "Hochsoelden",
                "label_fr": "Hochsoelden"
            },
            {
                "value": 55521,
                "label": "Rosental",
                "label_ar": "روزنتال",
                "label_fr": "Rosental"
            },
            {
                "value": 55307,
                "label": "Niederwolz",
                "label_ar": "Niederwolz",
                "label_fr": "Niederwolz"
            },
            {
                "value": 54744,
                "label": "Absam",
                "label_ar": "أبسام",
                "label_fr": "Absam"
            },
            {
                "value": 55135,
                "label": "Kuchl",
                "label_ar": "كوشل",
                "label_fr": "Kuchl"
            },
            {
                "value": 55258,
                "label": "Oetz",
                "label_ar": "اويتز",
                "label_fr": "Oetz"
            },
            {
                "value": 55844,
                "label": "Fischamend Dorf",
                "label_ar": "فيشامند دورف",
                "label_fr": "Fischamend Dorf"
            },
            {
                "value": 55344,
                "label": "Ebreichsdorf",
                "label_ar": "ابريتشسدورف",
                "label_fr": "Ebreichsdorf"
            },
            {
                "value": 54855,
                "label": "Purgstall",
                "label_ar": "بورغستال",
                "label_fr": "Purgstall"
            },
            {
                "value": 55254,
                "label": "Gerlos",
                "label_ar": "جيرلوس",
                "label_fr": "Gerlos"
            },
            {
                "value": 54825,
                "label": "Eggenburg",
                "label_ar": "إغينبورغ",
                "label_fr": "Eggenburg"
            },
            {
                "value": 55823,
                "label": "Guttaring",
                "label_ar": "حلق",
                "label_fr": "Guttaring"
            },
            {
                "value": 55559,
                "label": "Neudau",
                "label_ar": "نويدو",
                "label_fr": "Neudau"
            },
            {
                "value": 55045,
                "label": "Pfaffstatten",
                "label_ar": "بفافشتاتن",
                "label_fr": "Pfaffstatten"
            },
            {
                "value": 54975,
                "label": "Mutters",
                "label_ar": "تمتم",
                "label_fr": "Marmonne"
            },
            {
                "value": 55516,
                "label": "Breitenbrunn",
                "label_ar": "بريتنبرون",
                "label_fr": "Breitenbrunn"
            },
            {
                "value": 55635,
                "label": "Gampern",
                "label_ar": "جامبيرن",
                "label_fr": "Gampern"
            },
            {
                "value": 54517,
                "label": "Tarrenz",
                "label_ar": "تارينز",
                "label_fr": "Tarrenz"
            },
            {
                "value": 54674,
                "label": "Trieben",
                "label_ar": "تريبين",
                "label_fr": "Trieben"
            },
            {
                "value": 54763,
                "label": "Bad Schallerbach",
                "label_ar": "باد شاليرباخ",
                "label_fr": "Bad Schallerbach"
            },
            {
                "value": 55586,
                "label": "Kanzelhoehe",
                "label_ar": "Kanzelhoehe",
                "label_fr": "Kanzelhoehe"
            },
            {
                "value": 55085,
                "label": "Eben im Pongau",
                "label_ar": "Eben im Pongau",
                "label_fr": "Eben im Pongau"
            },
            {
                "value": 55547,
                "label": "Nesselwangle",
                "label_ar": "نيسيلوانجل",
                "label_fr": "Nesselwangle"
            },
            {
                "value": 55370,
                "label": "Laaben",
                "label_ar": "لابين",
                "label_fr": "Laaben"
            },
            {
                "value": 55555,
                "label": "Gries",
                "label_ar": "جريس",
                "label_fr": "Gries"
            },
            {
                "value": 55818,
                "label": "Wolfpassing",
                "label_ar": "ولفبسينغ",
                "label_fr": "Wolfpassing"
            },
            {
                "value": 55824,
                "label": "Griffen",
                "label_ar": "جريفن",
                "label_fr": "Griffen"
            },
            {
                "value": 55568,
                "label": "Oberpullendorf",
                "label_ar": "أوبربوليندورف",
                "label_fr": "Oberpullendorf"
            },
            {
                "value": 55042,
                "label": "Gumpoldskirchen",
                "label_ar": "Gumpoldskirchen",
                "label_fr": "Gumpoldskirchen"
            },
            {
                "value": 54638,
                "label": "Baernbach",
                "label_ar": "بارنباخ",
                "label_fr": "Baernbach"
            },
            {
                "value": 54939,
                "label": "Hinterthiersee",
                "label_ar": "Hinterthiersee",
                "label_fr": "Hinterthiersee"
            },
            {
                "value": 55025,
                "label": "Rottenmann",
                "label_ar": "روتنمان",
                "label_fr": "Rottenmann"
            },
            {
                "value": 55083,
                "label": "Abtenau",
                "label_ar": "أبتيناو",
                "label_fr": "Abtenau"
            },
            {
                "value": 55304,
                "label": "Isper",
                "label_ar": "إسبر",
                "label_fr": "Isper"
            },
            {
                "value": 55782,
                "label": "Ober-Grafendorf",
                "label_ar": "أوبر جرافيندورف",
                "label_fr": "Ober-Grafendorf"
            },
            {
                "value": 55574,
                "label": "Lechaschau",
                "label_ar": "ليتشاو",
                "label_fr": "Lechaschau"
            },
            {
                "value": 55087,
                "label": "Gallbrunn",
                "label_ar": "جالبرون",
                "label_fr": "Gallbrunn"
            },
            {
                "value": 55608,
                "label": "Bruckl",
                "label_ar": "بروكل",
                "label_fr": "Bruckl"
            },
            {
                "value": 55788,
                "label": "Neuhofen an der Ybbs",
                "label_ar": "Neuhofen an der Ybbs",
                "label_fr": "Neuhofen an der Ybbs"
            },
            {
                "value": 55213,
                "label": "Bad Hall",
                "label_ar": "قاعة سيئة",
                "label_fr": "Bad Hall"
            },
            {
                "value": 55227,
                "label": "Murau",
                "label_ar": "موراو",
                "label_fr": "Murau"
            },
            {
                "value": 55098,
                "label": "Getzersdorf",
                "label_ar": "جيتزرسدورف",
                "label_fr": "Getzersdorf"
            },
            {
                "value": 54668,
                "label": "Lauterach",
                "label_ar": "لوتراش",
                "label_fr": "Lauterach"
            },
            {
                "value": 54779,
                "label": "Saalbach",
                "label_ar": "سالباخ",
                "label_fr": "Saalbach"
            },
            {
                "value": 55313,
                "label": "Leiten",
                "label_ar": "ليتن",
                "label_fr": "Leiten"
            },
            {
                "value": 55813,
                "label": "Zobing",
                "label_ar": "زوبينج",
                "label_fr": "Zobing"
            },
            {
                "value": 54841,
                "label": "Sipbachzell",
                "label_ar": "سيبباشزيل",
                "label_fr": "Sipbachzell"
            },
            {
                "value": 55317,
                "label": "Moos",
                "label_ar": "موس",
                "label_fr": "Moos"
            },
            {
                "value": 55150,
                "label": "Landeck",
                "label_ar": "لانديك",
                "label_fr": "Landeck"
            },
            {
                "value": 55634,
                "label": "Munzkirchen",
                "label_ar": "مونزكيرشن",
                "label_fr": "Munzkirchen"
            },
            {
                "value": 54899,
                "label": "Lichtenberg",
                "label_ar": "ليشتنبرغ",
                "label_fr": "Lichtenberg"
            },
            {
                "value": 54968,
                "label": "Karteis",
                "label_ar": "كرتييس",
                "label_fr": "Karteis"
            },
            {
                "value": 55074,
                "label": "Unterpullendorf",
                "label_ar": "أونتربوليندورف",
                "label_fr": "Unterpullendorf"
            },
            {
                "value": 55810,
                "label": "Oberort",
                "label_ar": "أوبيرورت",
                "label_fr": "Oberort"
            },
            {
                "value": 54699,
                "label": "Gleisdorf",
                "label_ar": "جليسدورف",
                "label_fr": "Gleisdorf"
            },
            {
                "value": 54933,
                "label": "Maurach",
                "label_ar": "موراخ",
                "label_fr": "Maurach"
            },
            {
                "value": 54640,
                "label": "Krems",
                "label_ar": "كريمس",
                "label_fr": "Krems"
            },
            {
                "value": 55447,
                "label": "Lichtenworth",
                "label_ar": "ليشتنورث",
                "label_fr": "Lichtenworth"
            },
            {
                "value": 54809,
                "label": "Ehrwald",
                "label_ar": "ايروالد",
                "label_fr": "Ehrwald"
            },
            {
                "value": 54649,
                "label": "Poelten",
                "label_ar": "Poelten",
                "label_fr": "Poelten"
            },
            {
                "value": 55577,
                "label": "Nickelsdorf",
                "label_ar": "نيكلسدورف",
                "label_fr": "Nickelsdorf"
            },
            {
                "value": 55014,
                "label": "Untergrub",
                "label_ar": "أونترجروب",
                "label_fr": "Untergrub"
            },
            {
                "value": 55109,
                "label": "Fusch an der Glocknerstrasse",
                "label_ar": "Fusch an der Glocknerstrasse",
                "label_fr": "Fusch an der Glocknerstrasse"
            },
            {
                "value": 55744,
                "label": "Bleiburg",
                "label_ar": "بليبيرج",
                "label_fr": "Bleiburg"
            },
            {
                "value": 54768,
                "label": "Baden bei Wien",
                "label_ar": "بادن باي فيينا",
                "label_fr": "Baden bei Wien"
            },
            {
                "value": 55145,
                "label": "Lamprechtshausen",
                "label_ar": "لامبريشتشاوزن",
                "label_fr": "Lamprechtshausen"
            },
            {
                "value": 55395,
                "label": "Purbach",
                "label_ar": "بورباخ",
                "label_fr": "Purbach"
            },
            {
                "value": 55010,
                "label": "Gattendorf",
                "label_ar": "جاتندورف",
                "label_fr": "Gattendorf"
            },
            {
                "value": 54816,
                "label": "Bludenz",
                "label_ar": "بلودنز",
                "label_fr": "Bludenz"
            },
            {
                "value": 55639,
                "label": "Krumegg",
                "label_ar": "كروميغ",
                "label_fr": "Krumegg"
            },
            {
                "value": 54886,
                "label": "Oed",
                "label_ar": "أويد",
                "label_fr": "Oed"
            },
            {
                "value": 55826,
                "label": "Koetschach",
                "label_ar": "Koetschach",
                "label_fr": "Koetschach"
            },
            {
                "value": 55140,
                "label": "Wagrain",
                "label_ar": "واغراين",
                "label_fr": "Wagrain"
            },
            {
                "value": 54947,
                "label": "Schongrabern",
                "label_ar": "شونغرابرن",
                "label_fr": "Schongrabern"
            },
            {
                "value": 55272,
                "label": "Guntersdorf",
                "label_ar": "Guntersdorf",
                "label_fr": "Guntersdorf"
            },
            {
                "value": 55003,
                "label": "Geras",
                "label_ar": "جراس",
                "label_fr": "Geras"
            },
            {
                "value": 55012,
                "label": "Asparn",
                "label_ar": "اسبارن",
                "label_fr": "Asparn"
            },
            {
                "value": 54725,
                "label": "Herzogenburg",
                "label_ar": "هرتسوجينبرج",
                "label_fr": "Herzogenburg"
            },
            {
                "value": 55138,
                "label": "Altenmarkt im Pongau",
                "label_ar": "التنماركت إم بونجاو",
                "label_fr": "Altenmarkt im Pongau"
            },
            {
                "value": 55431,
                "label": "Heiterwang",
                "label_ar": "هيتيروانغ",
                "label_fr": "Heiterwang"
            },
            {
                "value": 54979,
                "label": "Petersdorf",
                "label_ar": "بيترسدورف",
                "label_fr": "Petersdorf"
            },
            {
                "value": 55383,
                "label": "Gfohl",
                "label_ar": "جفوهل",
                "label_fr": "Gfohl"
            },
            {
                "value": 54858,
                "label": "Axams",
                "label_ar": "اكسامس",
                "label_fr": "Axams"
            },
            {
                "value": 55401,
                "label": "Redlham",
                "label_ar": "ريدلهام",
                "label_fr": "Redlham"
            },
            {
                "value": 54911,
                "label": "Passail",
                "label_ar": "باسيل",
                "label_fr": "Passail"
            },
            {
                "value": 55778,
                "label": "Grambach",
                "label_ar": "غرامباخ",
                "label_fr": "Grambach"
            },
            {
                "value": 55770,
                "label": "Pabneukirchen",
                "label_ar": "بابنيوكيرشن",
                "label_fr": "Pabneukirchen"
            },
            {
                "value": 55237,
                "label": "Hipping",
                "label_ar": "الوخز",
                "label_fr": "Hipping"
            },
            {
                "value": 55539,
                "label": "Satteins",
                "label_ar": "ساتين",
                "label_fr": "Satteins"
            },
            {
                "value": 55222,
                "label": "Bad Kreuzen",
                "label_ar": "سيء كروزن",
                "label_fr": "Bad Kreuzen"
            },
            {
                "value": 55365,
                "label": "Weissenkirchen in der Wachau",
                "label_ar": "Weissenkirchen in der Wachau",
                "label_fr": "Weissenkirchen in der Wachau"
            },
            {
                "value": 55684,
                "label": "Sankt Margarethen im Burgenland",
                "label_ar": "سانكت مارغريتن إم بورغنلاند",
                "label_fr": "Sankt Margarethen im Burgenland"
            },
            {
                "value": 55442,
                "label": "Feldkirchen bei Graz",
                "label_ar": "فيلدكيرشن باي غراتس",
                "label_fr": "Feldkirchen bei Graz"
            },
            {
                "value": 55279,
                "label": "Lembach im Muhlkreis",
                "label_ar": "Lembach im Muhlkreis",
                "label_fr": "Lembach im Muhlkreis"
            },
            {
                "value": 55312,
                "label": "Kleinstubing",
                "label_ar": "كلاينستوبينج",
                "label_fr": "Kleinstubing"
            },
            {
                "value": 54624,
                "label": "Gurten",
                "label_ar": "جورتن",
                "label_fr": "Gurten"
            },
            {
                "value": 55377,
                "label": "Kittsee",
                "label_ar": "كيتسي",
                "label_fr": "Kittsee"
            },
            {
                "value": 55747,
                "label": "Reifnitz",
                "label_ar": "Reifnitz",
                "label_fr": "Reifnitz"
            },
            {
                "value": 55242,
                "label": "Hirschegg",
                "label_ar": "هيرشيج",
                "label_fr": "Hirschegg"
            },
            {
                "value": 55486,
                "label": "Wurflach",
                "label_ar": "Wurflach",
                "label_fr": "Wurflach"
            },
            {
                "value": 54748,
                "label": "Breitenfurt bei Wien",
                "label_ar": "Breitenfurt bei Wien",
                "label_fr": "Breitenfurt bei Wien"
            },
            {
                "value": 55598,
                "label": "Bosendurnbach",
                "label_ar": "بوسندورنباخ",
                "label_fr": "Bosendurnbach"
            },
            {
                "value": 55603,
                "label": "Waidhofen an der Thaya",
                "label_ar": "Waidhofen an der Thaya",
                "label_fr": "Waidhofen an der Thaya"
            },
            {
                "value": 55061,
                "label": "Hofstetten",
                "label_ar": "هوفستيتن",
                "label_fr": "Hofstetten"
            },
            {
                "value": 54819,
                "label": "Mittelberg",
                "label_ar": "ميتيلبيرج",
                "label_fr": "Mittelberg"
            },
            {
                "value": 55071,
                "label": "Westendorf",
                "label_ar": "وستندورف",
                "label_fr": "Westendorf"
            },
            {
                "value": 54751,
                "label": "Ellmau",
                "label_ar": "المو",
                "label_fr": "Ellmau"
            },
            {
                "value": 54994,
                "label": "Neudorfl",
                "label_ar": "نيودورفل",
                "label_fr": "Neudorfl"
            },
            {
                "value": 54708,
                "label": "Feldkirchen in Karnten",
                "label_ar": "فيلدكيرشن في كارنتين",
                "label_fr": "Feldkirchen à Karnten"
            },
            {
                "value": 55730,
                "label": "Poetting",
                "label_ar": "شاعر",
                "label_fr": "Poetting"
            },
            {
                "value": 55276,
                "label": "Eberschwang",
                "label_ar": "إبيرشوانج",
                "label_fr": "Eberschwang"
            },
            {
                "value": 55327,
                "label": "Dorf",
                "label_ar": "دورف",
                "label_fr": "Dorf"
            },
            {
                "value": 54694,
                "label": "Serfaus",
                "label_ar": "سيرفاوس",
                "label_fr": "Serfaus"
            },
            {
                "value": 54698,
                "label": "Kaindorf",
                "label_ar": "كايندورف",
                "label_fr": "Kaindorf"
            },
            {
                "value": 55821,
                "label": "Lambrechten",
                "label_ar": "لامبريشتن",
                "label_fr": "Lambrechten"
            },
            {
                "value": 55522,
                "label": "Dobersberg",
                "label_ar": "دوبرسبرج",
                "label_fr": "Dobersberg"
            },
            {
                "value": 54935,
                "label": "Leutasch",
                "label_ar": "لوتاش",
                "label_fr": "Leutasch"
            },
            {
                "value": 54869,
                "label": "Kremsmunster",
                "label_ar": "كريمسمونستر",
                "label_fr": "Kremsmunster"
            },
            {
                "value": 54592,
                "label": "Ried im Innkreis",
                "label_ar": "ريد إم إنكريس",
                "label_fr": "Ried im Innkreis"
            },
            {
                "value": 54581,
                "label": "Lochen",
                "label_ar": "لوشن",
                "label_fr": "Lochen"
            },
            {
                "value": 55229,
                "label": "Apfelberg",
                "label_ar": "ابفيلبيرج",
                "label_fr": "Apfelberg"
            },
            {
                "value": 55240,
                "label": "Sankt Andrae",
                "label_ar": "سانكت أندريه",
                "label_fr": "Sankt Andrae"
            },
            {
                "value": 55865,
                "label": "Oberhofen im Inntal",
                "label_ar": "أوبرهوفن إم إنتال",
                "label_fr": "Oberhofen im Inntal"
            },
            {
                "value": 54542,
                "label": "Pottendorf",
                "label_ar": "بوتندورف",
                "label_fr": "Pottendorf"
            },
            {
                "value": 54872,
                "label": "Andorf",
                "label_ar": "أندورف",
                "label_fr": "Andorf"
            },
            {
                "value": 55156,
                "label": "Walbersdorf",
                "label_ar": "Walbersdorf",
                "label_fr": "Walbersdorf"
            },
            {
                "value": 54729,
                "label": "Gnas",
                "label_ar": "جناس",
                "label_fr": "Gnas"
            },
            {
                "value": 55483,
                "label": "Weppersdorf",
                "label_ar": "Weppersdorf",
                "label_fr": "Weppersdorf"
            },
            {
                "value": 54824,
                "label": "Bad Pirawarth",
                "label_ar": "باد بيراوارث",
                "label_fr": "Bad Pirawarth"
            },
            {
                "value": 55581,
                "label": "Toeschling",
                "label_ar": "توشلينج",
                "label_fr": "Toeschling"
            },
            {
                "value": 55382,
                "label": "Dross",
                "label_ar": "خبث",
                "label_fr": "Scories"
            },
            {
                "value": 54633,
                "label": "Pettenbach",
                "label_ar": "بيتنباخ",
                "label_fr": "Pettenbach"
            },
            {
                "value": 55029,
                "label": "Werndorf",
                "label_ar": "فيرندورف",
                "label_fr": "Werndorf"
            },
            {
                "value": 54927,
                "label": "Schoenau",
                "label_ar": "شويناو",
                "label_fr": "Schoenau"
            },
            {
                "value": 54496,
                "label": "Gmunden",
                "label_ar": "جموندين",
                "label_fr": "Gmunden"
            },
            {
                "value": 55459,
                "label": "Neumarkt in Steiermark",
                "label_ar": "Neumarkt في Steiermark",
                "label_fr": "Neumarkt à Steiermark"
            },
            {
                "value": 55067,
                "label": "Bad Radkersburg",
                "label_ar": "باد رادكرسبيرغ",
                "label_fr": "Bad Radkersburg"
            },
            {
                "value": 55408,
                "label": "Traunstein",
                "label_ar": "تراونستين",
                "label_fr": "Traunstein"
            },
            {
                "value": 55463,
                "label": "Furthof",
                "label_ar": "فورثوف",
                "label_fr": "Furthof"
            },
            {
                "value": 55343,
                "label": "Neuhofen an der Krems",
                "label_ar": "Neuhofen an der Krems",
                "label_fr": "Neuhofen an der Krems"
            },
            {
                "value": 55137,
                "label": "Lungotz",
                "label_ar": "لونجوتز",
                "label_fr": "Lungotz"
            },
            {
                "value": 54637,
                "label": "Ringelsdorf",
                "label_ar": "رينجلسدورف",
                "label_fr": "Ringelsdorf"
            },
            {
                "value": 54950,
                "label": "Afritz",
                "label_ar": "أفريتز",
                "label_fr": "Afritz"
            },
            {
                "value": 55525,
                "label": "Weiten",
                "label_ar": "ويتين",
                "label_fr": "Weiten"
            },
            {
                "value": 55036,
                "label": "Ottensheim",
                "label_ar": "Ottensheim",
                "label_fr": "Ottensheim"
            },
            {
                "value": 54590,
                "label": "Freistadt",
                "label_ar": "فريشتات",
                "label_fr": "Freistadt"
            },
            {
                "value": 55026,
                "label": "Guglwald",
                "label_ar": "غوغلوالد",
                "label_fr": "Guglwald"
            },
            {
                "value": 54544,
                "label": "Klosterneuburg",
                "label_ar": "كلوسترنوبرغ",
                "label_fr": "Klosterneuburg"
            },
            {
                "value": 54591,
                "label": "Walding",
                "label_ar": "والدينغ",
                "label_fr": "Walding"
            },
            {
                "value": 54958,
                "label": "Winkl",
                "label_ar": "وينكل",
                "label_fr": "Winkl"
            },
            {
                "value": 55438,
                "label": "Brandberg",
                "label_ar": "براندبرج",
                "label_fr": "Brandberg"
            },
            {
                "value": 55715,
                "label": "Obergruenburg",
                "label_ar": "أوبيرجروينبورج",
                "label_fr": "Obergruenburg"
            },
            {
                "value": 55469,
                "label": "Orth an der Donau",
                "label_ar": "Orth an der Donau",
                "label_fr": "Orth an der Donau"
            },
            {
                "value": 55335,
                "label": "Sankt Andra am Zicksee",
                "label_ar": "سانكت أندرا آم زيكسي",
                "label_fr": "Sankt Andra am Zicksee"
            },
            {
                "value": 54982,
                "label": "Mank",
                "label_ar": "مانك",
                "label_fr": "Mank"
            },
            {
                "value": 55816,
                "label": "Regelsbrunn",
                "label_ar": "Regelsbrunn",
                "label_fr": "Regelsbrunn"
            },
            {
                "value": 54798,
                "label": "OEtztal-Bahnhof",
                "label_ar": "OEtztal-Bahnhof",
                "label_fr": "OEtztal-Bahnhof"
            },
            {
                "value": 55704,
                "label": "Niederndorf",
                "label_ar": "نيدرندورف",
                "label_fr": "Niederndorf"
            },
            {
                "value": 55355,
                "label": "Payerbach",
                "label_ar": "بايرباخ",
                "label_fr": "Payerbach"
            },
            {
                "value": 54705,
                "label": "Lassnitzhohe",
                "label_ar": "لاسينتزهوهي",
                "label_fr": "Lassnitzhohe"
            },
            {
                "value": 55607,
                "label": "Rosegg",
                "label_ar": "روسيج",
                "label_fr": "Rosegg"
            },
            {
                "value": 55028,
                "label": "Burgkirchen",
                "label_ar": "بورجكيرشن",
                "label_fr": "Burgkirchen"
            },
            {
                "value": 54557,
                "label": "Kindberg",
                "label_ar": "كيندبرج",
                "label_fr": "Kindberg"
            },
            {
                "value": 54913,
                "label": "Knittelfeld",
                "label_ar": "كنيتلفيلد",
                "label_fr": "Knittelfeld"
            },
            {
                "value": 55375,
                "label": "Gramastetten",
                "label_ar": "جراماستيتين",
                "label_fr": "Gramastetten"
            },
            {
                "value": 55103,
                "label": "Mauthausen",
                "label_ar": "ماوتهاوزن",
                "label_fr": "Mauthausen"
            },
            {
                "value": 55647,
                "label": "Sinabelkirchen",
                "label_ar": "سينابيلكيرشن",
                "label_fr": "Sinabelkirchen"
            },
            {
                "value": 55517,
                "label": "Edelstal",
                "label_ar": "إديلستال",
                "label_fr": "Edelstal"
            },
            {
                "value": 55854,
                "label": "Kirchbach in Steiermark",
                "label_ar": "كيرشباخ في Steiermark",
                "label_fr": "Kirchbach à Steiermark"
            },
            {
                "value": 55441,
                "label": "Feldkirchen bei Mattighofen",
                "label_ar": "فيلدكيرشن باي ماتيغوفن",
                "label_fr": "Feldkirchen bei Mattighofen"
            },
            {
                "value": 55130,
                "label": "Eugendorf",
                "label_ar": "اوجيندورف",
                "label_fr": "Eugendorf"
            },
            {
                "value": 55659,
                "label": "Mandling",
                "label_ar": "ماندلينج",
                "label_fr": "Mandling"
            },
            {
                "value": 55115,
                "label": "Sulzau",
                "label_ar": "سولزو",
                "label_fr": "Sulzau"
            },
            {
                "value": 54822,
                "label": "Im Brand",
                "label_ar": "ايم براند",
                "label_fr": "Im marque"
            },
            {
                "value": 55677,
                "label": "Wolfau",
                "label_ar": "ولفاو",
                "label_fr": "Wolfau"
            },
            {
                "value": 54654,
                "label": "Bad Fischau",
                "label_ar": "سيئة فيشاو",
                "label_fr": "Bad Fischau"
            },
            {
                "value": 55452,
                "label": "Lebring",
                "label_ar": "ليبرينغ",
                "label_fr": "Lebring"
            },
            {
                "value": 54556,
                "label": "Pischelsdorf in Steiermark",
                "label_ar": "Pischelsdorf في Steiermark",
                "label_fr": "Pischelsdorf à Steiermark"
            },
            {
                "value": 54663,
                "label": "Strobl",
                "label_ar": "ستروبل",
                "label_fr": "Strobl"
            },
            {
                "value": 55739,
                "label": "Piringsdorf",
                "label_ar": "Piringsdorf",
                "label_fr": "Piringsdorf"
            },
            {
                "value": 54954,
                "label": "Aifersdorf",
                "label_ar": "أيفرسدورف",
                "label_fr": "Aifersdorf"
            },
            {
                "value": 55077,
                "label": "Purbach am Neusiedlersee",
                "label_ar": "بورباخ ام نيوسيدليرسي",
                "label_fr": "Purbach am Neusiedlersee"
            },
            {
                "value": 55760,
                "label": "Viktorsberg",
                "label_ar": "فيكتورسبرج",
                "label_fr": "Viktorsberg"
            },
            {
                "value": 55233,
                "label": "Hinterwaldberg",
                "label_ar": "هينتروالدبيرج",
                "label_fr": "Hinterwaldberg"
            },
            {
                "value": 54608,
                "label": "Stein an der Enns",
                "label_ar": "Stein an der Enns",
                "label_fr": "Stein an der Enns"
            },
            {
                "value": 54541,
                "label": "Kopfing im Innkreis",
                "label_ar": "Kopfing im Innkreis",
                "label_fr": "Kopfing im Innkreis"
            },
            {
                "value": 55199,
                "label": "Frankenburg",
                "label_ar": "فرانكنبورغ",
                "label_fr": "Frankenburg"
            },
            {
                "value": 55121,
                "label": "Margarethen am Moos",
                "label_ar": "مارغريتن آم موس",
                "label_fr": "Margarethen am Moos"
            },
            {
                "value": 54676,
                "label": "Laa an der Thaya",
                "label_ar": "لا ان دير ثايا",
                "label_fr": "Laa an der Thaya"
            },
            {
                "value": 55165,
                "label": "Koblach",
                "label_ar": "كوبلاخ",
                "label_fr": "Koblach"
            },
            {
                "value": 55169,
                "label": "Elsbethen",
                "label_ar": "إلسبيثين",
                "label_fr": "Elsbethen"
            },
            {
                "value": 55768,
                "label": "Gotzendorf",
                "label_ar": "جوتزيندورف",
                "label_fr": "Gotzendorf"
            },
            {
                "value": 55151,
                "label": "Neusiedl am See",
                "label_ar": "Neusiedl am See",
                "label_fr": "Neusiedl am See"
            },
            {
                "value": 55838,
                "label": "Sulzberg",
                "label_ar": "سولزبيرج",
                "label_fr": "Sulzberg"
            },
            {
                "value": 54530,
                "label": "Feldbach",
                "label_ar": "فيلدباخ",
                "label_fr": "Feldbach"
            },
            {
                "value": 55300,
                "label": "Gailitz",
                "label_ar": "جيليتز",
                "label_fr": "Gailitz"
            },
            {
                "value": 54569,
                "label": "Strasshof an der Nordbahn",
                "label_ar": "Strasshof an der Nordbahn",
                "label_fr": "Strasshof an der Nordbahn"
            },
            {
                "value": 55248,
                "label": "Bad Gams",
                "label_ar": "ألعاب سيئة",
                "label_fr": "Bad Gams"
            },
            {
                "value": 55004,
                "label": "Spitz",
                "label_ar": "سبيتز",
                "label_fr": "Loulou"
            },
            {
                "value": 54621,
                "label": "Zipf",
                "label_ar": "Zipf",
                "label_fr": "Zipf"
            },
            {
                "value": 55802,
                "label": "Sankt Kathrein am Hauenstein",
                "label_ar": "سانكت كاثرين ام هاونشتاين",
                "label_fr": "Sankt Kathrein am Hauenstein"
            },
            {
                "value": 55801,
                "label": "Hirm",
                "label_ar": "هيرم",
                "label_fr": "Hirm"
            },
            {
                "value": 55741,
                "label": "Klostermarienberg",
                "label_ar": "كلوسترمارينبيرج",
                "label_fr": "Klostermarienberg"
            },
            {
                "value": 55752,
                "label": "Maria Lankowitz",
                "label_ar": "ماريا لانكويتز",
                "label_fr": "Maria Lankowitz"
            },
            {
                "value": 55146,
                "label": "Sausenstein",
                "label_ar": "سوسنشتاين",
                "label_fr": "Sausenstein"
            },
            {
                "value": 54639,
                "label": "Koflach",
                "label_ar": "كوفلاتش",
                "label_fr": "Koflach"
            },
            {
                "value": 54827,
                "label": "Baumgarten im Burgenland",
                "label_ar": "بومغارتن ام بورغنلاند",
                "label_fr": "Baumgarten im Burgenland"
            },
            {
                "value": 54970,
                "label": "Reitdorf",
                "label_ar": "ريتدورف",
                "label_fr": "Reitdorf"
            },
            {
                "value": 54661,
                "label": "Telfs",
                "label_ar": "تيلفس",
                "label_fr": "Telfs"
            },
            {
                "value": 55159,
                "label": "Mollersdorf",
                "label_ar": "مولرسدورف",
                "label_fr": "Mollersdorf"
            },
            {
                "value": 55612,
                "label": "Polfing",
                "label_ar": "بولفينج",
                "label_fr": "Polfing"
            },
            {
                "value": 55893,
                "label": "Obersdorf",
                "label_ar": "أوبيرسدورف",
                "label_fr": "Obersdorf"
            },
            {
                "value": 55185,
                "label": "Gratwein",
                "label_ar": "جراتوين",
                "label_fr": "Gratwein"
            },
            {
                "value": 55020,
                "label": "Warth",
                "label_ar": "وارث",
                "label_fr": "Warth"
            },
            {
                "value": 55755,
                "label": "Puch",
                "label_ar": "بوتش",
                "label_fr": "Puch"
            },
            {
                "value": 55267,
                "label": "Krieglach",
                "label_ar": "كريجلاخ",
                "label_fr": "Krieglach"
            },
            {
                "value": 54555,
                "label": "Unterrohr",
                "label_ar": "Unterrohr",
                "label_fr": "Unterrohr"
            },
            {
                "value": 54978,
                "label": "Viktring",
                "label_ar": "فيكترينغ",
                "label_fr": "Viktring"
            },
            {
                "value": 55265,
                "label": "Kobenz",
                "label_ar": "كوبينز",
                "label_fr": "Kobenz"
            },
            {
                "value": 55819,
                "label": "Hautzendorf",
                "label_ar": "Hautzendorf",
                "label_fr": "Hautzendorf"
            },
            {
                "value": 55841,
                "label": "Gaming",
                "label_ar": "الألعاب",
                "label_fr": "Jeux"
            },
            {
                "value": 55422,
                "label": "Wienerherberg",
                "label_ar": "وينرهيربيرج",
                "label_fr": "Wienerherberg"
            },
            {
                "value": 55595,
                "label": "Gaweinstal",
                "label_ar": "جاوينستال",
                "label_fr": "Gaweinstal"
            },
            {
                "value": 55073,
                "label": "Weiden am See",
                "label_ar": "Weiden am See",
                "label_fr": "Weiden am See"
            },
            {
                "value": 55039,
                "label": "Unterweitersdorf",
                "label_ar": "Unterweitersdorf",
                "label_fr": "Unterweitersdorf"
            },
            {
                "value": 55285,
                "label": "Mettmach",
                "label_ar": "متماش",
                "label_fr": "Mettmach"
            },
            {
                "value": 55721,
                "label": "Sibratsgfall",
                "label_ar": "Sibratsgfall",
                "label_fr": "Sibratsgfall"
            },
            {
                "value": 54735,
                "label": "Brunn am Gebirge",
                "label_ar": "Brunn am Gebirge",
                "label_fr": "Brunn am Gebirge"
            },
            {
                "value": 55037,
                "label": "Mitterdorf im Muerztal",
                "label_ar": "ميتردورف ام مورتزتال",
                "label_fr": "Mitterdorf im Muerztal"
            },
            {
                "value": 55113,
                "label": "Maria Alm am Steinernen Meer",
                "label_ar": "ماريا ألم آم شتاينرنين مير",
                "label_fr": "Maria Alm am Steinernen Meer"
            },
            {
                "value": 55415,
                "label": "Motz",
                "label_ar": "موتز",
                "label_fr": "Motz"
            },
            {
                "value": 55196,
                "label": "Haidershofen",
                "label_ar": "هايدرشوفن",
                "label_fr": "Haidershofen"
            },
            {
                "value": 54866,
                "label": "Bisamberg",
                "label_ar": "بيسامبيرج",
                "label_fr": "Bisamberg"
            },
            {
                "value": 55572,
                "label": "Thoerl",
                "label_ar": "ثويرل",
                "label_fr": "Thoerl"
            },
            {
                "value": 55142,
                "label": "Oberalm",
                "label_ar": "أوبيرالم",
                "label_fr": "Oberalm"
            },
            {
                "value": 54562,
                "label": "Grunbach am Schneeberg",
                "label_ar": "جرونباخ ام شنيبرغ",
                "label_fr": "Grunbach am Schneeberg"
            },
            {
                "value": 54589,
                "label": "Fulpmes",
                "label_ar": "فولبمس",
                "label_fr": "Fulpmes"
            },
            {
                "value": 55041,
                "label": "Hohenberg",
                "label_ar": "هوهنبرج",
                "label_fr": "Hohenberg"
            },
            {
                "value": 55489,
                "label": "Fliess",
                "label_ar": "فليس",
                "label_fr": "Mouches"
            },
            {
                "value": 55448,
                "label": "Theresienfeld",
                "label_ar": "تيريزينفيلد",
                "label_fr": "Theresienfeld"
            },
            {
                "value": 55648,
                "label": "Rohrbach am Kulm",
                "label_ar": "روهرباخ ام كولم",
                "label_fr": "Rohrbach am Kulm"
            },
            {
                "value": 55663,
                "label": "Nestelbach bei Graz",
                "label_ar": "نستلباخ باي غراتس",
                "label_fr": "Nestelbach bei Graz"
            },
            {
                "value": 55197,
                "label": "Unterrohr",
                "label_ar": "Unterrohr",
                "label_fr": "Unterrohr"
            },
            {
                "value": 55591,
                "label": "Maria Buch",
                "label_ar": "ماريا بوخ",
                "label_fr": "Maria Buch"
            },
            {
                "value": 55444,
                "label": "Pottelsdorf",
                "label_ar": "بوتيلسدورف",
                "label_fr": "Pottelsdorf"
            },
            {
                "value": 55331,
                "label": "Riedlingsdorf",
                "label_ar": "ريدلينجزدورف",
                "label_fr": "Riedlingsdorf"
            },
            {
                "value": 55712,
                "label": "Hadersdorf am Kamp",
                "label_ar": "هادرسدورف ام كامب",
                "label_fr": "Hadersdorf am Kamp"
            },
            {
                "value": 54990,
                "label": "Erlauf",
                "label_ar": "ارلاوف",
                "label_fr": "Erlauf"
            },
            {
                "value": 55393,
                "label": "Wordern",
                "label_ar": "ردر",
                "label_fr": "Wordern"
            },
            {
                "value": 54704,
                "label": "Straden",
                "label_ar": "سترادين",
                "label_fr": "Straden"
            },
            {
                "value": 54658,
                "label": "Kufstein",
                "label_ar": "كوفشتاين",
                "label_fr": "Kufstein"
            },
            {
                "value": 55131,
                "label": "Ebenau",
                "label_ar": "ابيناو",
                "label_fr": "Ebenau"
            },
            {
                "value": 55502,
                "label": "Pinkafeld",
                "label_ar": "بينكافيلد",
                "label_fr": "Pinkafeld"
            },
            {
                "value": 55168,
                "label": "Muntlix",
                "label_ar": "مونتليكس",
                "label_fr": "Muntlix"
            },
            {
                "value": 55018,
                "label": "Wettmannstatten",
                "label_ar": "Wettmannstatten",
                "label_fr": "Wettmannstatten"
            },
            {
                "value": 54790,
                "label": "Waidring",
                "label_ar": "ويدرينغ",
                "label_fr": "Waidring"
            },
            {
                "value": 55604,
                "label": "Traisen",
                "label_ar": "ترايسن",
                "label_fr": "Traisen"
            },
            {
                "value": 55144,
                "label": "Fuschl am See",
                "label_ar": "فوشل ام سي",
                "label_fr": "Fuschl am See"
            },
            {
                "value": 54955,
                "label": "Bleiberg ob Villach",
                "label_ar": "بليبرغ أوب فيلاخ",
                "label_fr": "Bleiberg ob Villach"
            },
            {
                "value": 55556,
                "label": "Lanersbach",
                "label_ar": "لانرسباخ",
                "label_fr": "Lanersbach"
            },
            {
                "value": 55787,
                "label": "Preding",
                "label_ar": "المسبق",
                "label_fr": "Preding"
            },
            {
                "value": 55218,
                "label": "Biberbach",
                "label_ar": "بيبرباخ",
                "label_fr": "Biberbach"
            },
            {
                "value": 55263,
                "label": "Wieting",
                "label_ar": "فيتنج",
                "label_fr": "Wieting"
            },
            {
                "value": 54807,
                "label": "Alpbach",
                "label_ar": "ألباخ",
                "label_fr": "Alpbach"
            },
            {
                "value": 54596,
                "label": "Hochst",
                "label_ar": "هوشست",
                "label_fr": "Hochst"
            },
            {
                "value": 55589,
                "label": "Hallstatt",
                "label_ar": "هالستات",
                "label_fr": "Hallstatt"
            },
            {
                "value": 55650,
                "label": "Unzmarkt",
                "label_ar": "أونزماركت",
                "label_fr": "Unzmarkt"
            },
            {
                "value": 55847,
                "label": "Loretto",
                "label_ar": "لوريتو",
                "label_fr": "Loretto"
            },
            {
                "value": 54677,
                "label": "Gaflenz",
                "label_ar": "جافلينز",
                "label_fr": "Gaflenz"
            },
            {
                "value": 54986,
                "label": "Pyhra",
                "label_ar": "Pyhra",
                "label_fr": "Pyhra"
            },
            {
                "value": 54951,
                "label": "Friesach",
                "label_ar": "فريساش",
                "label_fr": "Friesach"
            },
            {
                "value": 55259,
                "label": "Jungholz",
                "label_ar": "Jungholz",
                "label_fr": "Jungholz"
            },
            {
                "value": 54966,
                "label": "Heissingfelding",
                "label_ar": "اللحام",
                "label_fr": "Heissingfelding"
            },
            {
                "value": 55270,
                "label": "Au",
                "label_ar": "Au",
                "label_fr": "Au"
            },
            {
                "value": 55536,
                "label": "Heissberg",
                "label_ar": "هيسبرغ",
                "label_fr": "Heissberg"
            },
            {
                "value": 54670,
                "label": "Zirl",
                "label_ar": "زيرل",
                "label_fr": "Zirl"
            },
            {
                "value": 54662,
                "label": "Bad Goisern",
                "label_ar": "سيئ Goisern",
                "label_fr": "Bad Goisern"
            },
            {
                "value": 54697,
                "label": "Harbach",
                "label_ar": "هارباخ",
                "label_fr": "Harbach"
            },
            {
                "value": 55310,
                "label": "Finsing",
                "label_ar": "تقطيع",
                "label_fr": "Finition"
            },
            {
                "value": 54666,
                "label": "Dornbirn",
                "label_ar": "دورنبيرن",
                "label_fr": "Dornbirn"
            },
            {
                "value": 54901,
                "label": "Grein",
                "label_ar": "غرين",
                "label_fr": "Grein"
            },
            {
                "value": 55457,
                "label": "Kapelln",
                "label_ar": "كابيلن",
                "label_fr": "Kapelln"
            },
            {
                "value": 55396,
                "label": "Grafenschlag",
                "label_ar": "جرافينشلاغ",
                "label_fr": "Grafenschlag"
            },
            {
                "value": 55017,
                "label": "Fuerstenfeld",
                "label_ar": "فويرستينفيلد",
                "label_fr": "Fuerstenfeld"
            },
            {
                "value": 55143,
                "label": "Henndorf am Wallersee",
                "label_ar": "Henndorf am Wallersee",
                "label_fr": "Henndorf am Wallersee"
            },
            {
                "value": 54687,
                "label": "Oberrohrbach",
                "label_ar": "أوبروهرباخ",
                "label_fr": "Oberrohrbach"
            },
            {
                "value": 54671,
                "label": "Admont",
                "label_ar": "أدمونت",
                "label_fr": "Admont"
            },
            {
                "value": 55440,
                "label": "Hofkirchen im Traunkreis",
                "label_ar": "Hofkirchen im Traunkreis",
                "label_fr": "Hofkirchen im Traunkreis"
            },
            {
                "value": 55102,
                "label": "Oberweis",
                "label_ar": "أوبيرفايس",
                "label_fr": "Oberweis"
            },
            {
                "value": 54564,
                "label": "Pitten",
                "label_ar": "مغرور",
                "label_fr": "Pitten"
            },
            {
                "value": 54758,
                "label": "Bad Voslau",
                "label_ar": "باد فوسلاو",
                "label_fr": "Bad Voslau"
            },
            {
                "value": 54611,
                "label": "Stainz bei Straden",
                "label_ar": "ستينز باي سترادين",
                "label_fr": "Stainz bei Straden"
            },
            {
                "value": 55055,
                "label": "Tullnerbach-Lawies",
                "label_ar": "Tullnerbach-Lawies",
                "label_fr": "Tullnerbach-Lawies"
            },
            {
                "value": 54568,
                "label": "Schonkirchen",
                "label_ar": "شونكيرشن",
                "label_fr": "Schonkirchen"
            },
            {
                "value": 55764,
                "label": "Marz",
                "label_ar": "مارز",
                "label_fr": "Marz"
            },
            {
                "value": 55361,
                "label": "Mosern",
                "label_ar": "موسرن",
                "label_fr": "Mosern"
            },
            {
                "value": 54713,
                "label": "Tiefgraben",
                "label_ar": "Tiefgraben",
                "label_fr": "Tiefgraben"
            },
            {
                "value": 55068,
                "label": "Waltersdorf an der March",
                "label_ar": "Waltersdorf an der March",
                "label_fr": "Waltersdorf an der March"
            },
            {
                "value": 55437,
                "label": "Vols",
                "label_ar": "المجلدات",
                "label_fr": "Vols"
            },
            {
                "value": 54963,
                "label": "Bramberg am Wildkogel",
                "label_ar": "برامبرغ آم ويلدكوغل",
                "label_fr": "Bramberg am Wildkogel"
            },
            {
                "value": 54710,
                "label": "Stein im Jauntal",
                "label_ar": "شتاين إم جونتال",
                "label_fr": "Stein im Jauntal"
            },
            {
                "value": 55330,
                "label": "Drassmarkt",
                "label_ar": "دراسماركت",
                "label_fr": "Drassmarkt"
            },
            {
                "value": 55052,
                "label": "Biedermannsdorf",
                "label_ar": "بيدرمانسدورف",
                "label_fr": "Biedermannsdorf"
            },
            {
                "value": 55753,
                "label": "Wartberg",
                "label_ar": "Wartberg",
                "label_fr": "Wartberg"
            },
            {
                "value": 55710,
                "label": "Hochburg",
                "label_ar": "هوشبورج",
                "label_fr": "Hochburg"
            },
            {
                "value": 54974,
                "label": "Strau",
                "label_ar": "ستراو",
                "label_fr": "Strau"
            },
            {
                "value": 55582,
                "label": "Durnstein",
                "label_ar": "دورنشتاين",
                "label_fr": "Dürnstein"
            },
            {
                "value": 55520,
                "label": "Pregarten",
                "label_ar": "روضة",
                "label_fr": "Pregarten"
            },
            {
                "value": 55088,
                "label": "Lilienfeld",
                "label_ar": "ليلينفيلد",
                "label_fr": "Lilienfeld"
            },
            {
                "value": 55571,
                "label": "Mitterndorf an der Fischa",
                "label_ar": "Mitterndorf an der Fischa",
                "label_fr": "Mitterndorf an der Fischa"
            },
            {
                "value": 54860,
                "label": "Nussdorf",
                "label_ar": "نوسدورف",
                "label_fr": "Nussdorf"
            },
            {
                "value": 55231,
                "label": "Barwies",
                "label_ar": "بارويز",
                "label_fr": "Barwies"
            },
            {
                "value": 55208,
                "label": "Hadersfeld",
                "label_ar": "هادرسفيلد",
                "label_fr": "Hadersfeld"
            },
            {
                "value": 55697,
                "label": "Pram",
                "label_ar": "عربة أطفال",
                "label_fr": "Landau"
            },
            {
                "value": 55609,
                "label": "Fernitz",
                "label_ar": "فيرنيتز",
                "label_fr": "Fernitz"
            },
            {
                "value": 55477,
                "label": "Gotzens",
                "label_ar": "العشرات",
                "label_fr": "Gotzens"
            },
            {
                "value": 55214,
                "label": "Wiesing",
                "label_ar": "ويسينج",
                "label_fr": "Wiesing"
            },
            {
                "value": 55728,
                "label": "Katsdorf",
                "label_ar": "كاتسدورف",
                "label_fr": "Katsdorf"
            },
            {
                "value": 54543,
                "label": "Weigelsdorf",
                "label_ar": "Weigelsdorf",
                "label_fr": "Weigelsdorf"
            },
            {
                "value": 54504,
                "label": "Salzburg",
                "label_ar": "سالزبورغ",
                "label_fr": "Salzbourg"
            },
            {
                "value": 55419,
                "label": "Eggendorf",
                "label_ar": "إيجيندورف",
                "label_fr": "Eggendorf"
            },
            {
                "value": 54995,
                "label": "Neckenmarkt",
                "label_ar": "نيكينماركت",
                "label_fr": "Neckenmarkt"
            },
            {
                "value": 55314,
                "label": "Obertrum am See",
                "label_ar": "أوبرتروم آم سي",
                "label_fr": "Obertrum am See"
            },
            {
                "value": 55637,
                "label": "Dorfla",
                "label_ar": "دورفلا",
                "label_fr": "Dorfla"
            },
            {
                "value": 54525,
                "label": "Mödling",
                "label_ar": "مودلينج",
                "label_fr": "Mödling"
            },
            {
                "value": 55084,
                "label": "Radstadt",
                "label_ar": "رادستادت",
                "label_fr": "Radstadt"
            },
            {
                "value": 55205,
                "label": "Garsten",
                "label_ar": "جارستن",
                "label_fr": "Garsten"
            },
            {
                "value": 55722,
                "label": "Bad Erlach",
                "label_ar": "باد إيرلاش",
                "label_fr": "Bad Erlach"
            },
            {
                "value": 55518,
                "label": "Mistelbach",
                "label_ar": "ميستلباخ",
                "label_fr": "Mistelbach"
            },
            {
                "value": 55139,
                "label": "Flachau",
                "label_ar": "Flachau",
                "label_fr": "Flachau"
            },
            {
                "value": 55465,
                "label": "Blindenmarkt",
                "label_ar": "Blindenmarkt",
                "label_fr": "Blindenmarkt"
            },
            {
                "value": 54874,
                "label": "Voelkermarkt",
                "label_ar": "Voelkermarkt",
                "label_fr": "Voelkermarkt"
            },
            {
                "value": 54805,
                "label": "Zell am Ziller",
                "label_ar": "زيل أم زيلر",
                "label_fr": "Zell am Ziller"
            },
            {
                "value": 55717,
                "label": "Engelhartszell",
                "label_ar": "إنجلهارتشيل",
                "label_fr": "Engelhartszell"
            },
            {
                "value": 55277,
                "label": "Altheim",
                "label_ar": "ألتهايم",
                "label_fr": "Altheim"
            },
            {
                "value": 54707,
                "label": "Jois",
                "label_ar": "جويس",
                "label_fr": "Jois"
            },
            {
                "value": 55147,
                "label": "Deutsch-Wagram",
                "label_ar": "دويتش فاغرام",
                "label_fr": "Deutsch-Wagram"
            },
            {
                "value": 54924,
                "label": "Haiming",
                "label_ar": "هايمنج",
                "label_fr": "Haiming"
            },
            {
                "value": 54655,
                "label": "Kilb",
                "label_ar": "كيلب",
                "label_fr": "Kilb"
            },
            {
                "value": 55250,
                "label": "Leonfelden",
                "label_ar": "ليونفيلدين",
                "label_fr": "Leonfelden"
            },
            {
                "value": 54497,
                "label": "Molln",
                "label_ar": "مولن",
                "label_fr": "Molln"
            },
            {
                "value": 55128,
                "label": "Hof bei Salzburg",
                "label_ar": "هوف باي سالزبورغ",
                "label_fr": "Hof bei Salzbourg"
            },
            {
                "value": 55713,
                "label": "Atzbach",
                "label_ar": "أتزباخ",
                "label_fr": "Atzbach"
            },
            {
                "value": 55179,
                "label": "Freienstein",
                "label_ar": "فرينشتاين",
                "label_fr": "Freienstein"
            },
            {
                "value": 54605,
                "label": "Muerzhofen",
                "label_ar": "Muerzhofen",
                "label_fr": "Muerzhofen"
            },
            {
                "value": 55803,
                "label": "Wang",
                "label_ar": "وانغ",
                "label_fr": "Wang"
            },
            {
                "value": 55116,
                "label": "Gaisberg",
                "label_ar": "جايسبيرج",
                "label_fr": "Gaisberg"
            },
            {
                "value": 54900,
                "label": "Micheldorf in Oberoesterreich",
                "label_ar": "ميتشلدورف في Oberoesterreich",
                "label_fr": "Micheldorf à Oberoesterreich"
            },
            {
                "value": 55597,
                "label": "Weikendorf",
                "label_ar": "ويكندورف",
                "label_fr": "Weikendorf"
            },
            {
                "value": 55416,
                "label": "Emberg",
                "label_ar": "إمبرج",
                "label_fr": "Emberg"
            },
            {
                "value": 54773,
                "label": "Perg",
                "label_ar": "بيرج",
                "label_fr": "Perg"
            },
            {
                "value": 54523,
                "label": "Giesshubl",
                "label_ar": "جيسهبل",
                "label_fr": "Giesshubl"
            },
            {
                "value": 54682,
                "label": "Ried in der Riedmark",
                "label_ar": "ريد في دير ريدمارك",
                "label_fr": "Ried in der Riedmark"
            },
            {
                "value": 55456,
                "label": "Zeiselmauer",
                "label_ar": "زايسلماور",
                "label_fr": "Zeiselmauer"
            },
            {
                "value": 54743,
                "label": "Hall",
                "label_ar": "صالة",
                "label_fr": "salle"
            },
            {
                "value": 54903,
                "label": "Gross-Enzersdorf",
                "label_ar": "جروس انزرسدورف",
                "label_fr": "Gross-Enzersdorf"
            },
            {
                "value": 54538,
                "label": "Wartberg an der Krems",
                "label_ar": "Wartberg an der Krems",
                "label_fr": "Wartberg an der Krems"
            },
            {
                "value": 55206,
                "label": "Kirchdorf an der Krems",
                "label_ar": "كيرشدورف ان دير كريمس",
                "label_fr": "Kirchdorf an der Krems"
            },
            {
                "value": 54740,
                "label": "Weikersdorf am Steinfelde",
                "label_ar": "Weikersdorf am Steinfelde",
                "label_fr": "Weikersdorf am Steinfelde"
            },
            {
                "value": 54857,
                "label": "Asperhofen",
                "label_ar": "أسبيرهوفن",
                "label_fr": "Asperhofen"
            },
            {
                "value": 54507,
                "label": "Graz",
                "label_ar": "غراتس",
                "label_fr": "Graz"
            },
            {
                "value": 54727,
                "label": "Hollabrunn",
                "label_ar": "هولابرون",
                "label_fr": "Hollabrunn"
            },
            {
                "value": 55253,
                "label": "Haimingerberg",
                "label_ar": "هيمنجربيرج",
                "label_fr": "Haimingerberg"
            },
            {
                "value": 55294,
                "label": "Meggenhofen",
                "label_ar": "ميجينهوفن",
                "label_fr": "Meggenhofen"
            },
            {
                "value": 55235,
                "label": "Sankt Lorenzen",
                "label_ar": "سانكت لورينزن",
                "label_fr": "Sankt Lorenzen"
            },
            {
                "value": 55657,
                "label": "Rieding",
                "label_ar": "ريدينغ",
                "label_fr": "Rieding"
            },
            {
                "value": 54878,
                "label": "Ebenthal",
                "label_ar": "ابينثال",
                "label_fr": "Ebenthal"
            },
            {
                "value": 55186,
                "label": "Eggersdorf bei Graz",
                "label_ar": "Eggersdorf باي غراتس",
                "label_fr": "Eggersdorf bei Graz"
            },
            {
                "value": 55044,
                "label": "Markt Piesting",
                "label_ar": "سوق بيستينج",
                "label_fr": "Markt Piesting"
            },
            {
                "value": 55293,
                "label": "Alkoven",
                "label_ar": "ألكوفن",
                "label_fr": "Alkoven"
            },
            {
                "value": 55615,
                "label": "Herzogsdorf",
                "label_ar": "هيرزوغسدورف",
                "label_fr": "Herzogsdorf"
            },
            {
                "value": 55757,
                "label": "Dobersdorf",
                "label_ar": "دوبرسدورف",
                "label_fr": "Dobersdorf"
            },
            {
                "value": 54766,
                "label": "Ohlsdorf",
                "label_ar": "اهلسدورف",
                "label_fr": "Ohlsdorf"
            },
            {
                "value": 54643,
                "label": "Amaliendorf",
                "label_ar": "أماليندورف",
                "label_fr": "Amaliendorf"
            },
            {
                "value": 54717,
                "label": "Gaaden",
                "label_ar": "Gaaden",
                "label_fr": "Gaaden"
            },
            {
                "value": 54818,
                "label": "Krumpendorf",
                "label_ar": "كرومبندورف",
                "label_fr": "Krumpendorf"
            },
            {
                "value": 55629,
                "label": "Steinhaus",
                "label_ar": "شتاينهاوس",
                "label_fr": "Steinhaus"
            },
            {
                "value": 55540,
                "label": "Ehrenhausen",
                "label_ar": "إهرنهاوزن",
                "label_fr": "Ehrenhausen"
            },
            {
                "value": 54599,
                "label": "Pochlarn",
                "label_ar": "بوشلارن",
                "label_fr": "Pochlarn"
            },
            {
                "value": 54820,
                "label": "Neustift",
                "label_ar": "نيوستيفت",
                "label_fr": "Neustift"
            },
            {
                "value": 55602,
                "label": "Allhartsberg",
                "label_ar": "ألهارتسبرغ",
                "label_fr": "Allhartsberg"
            },
            {
                "value": 55480,
                "label": "Ebenfurth",
                "label_ar": "إيبنفورث",
                "label_fr": "Ebenfurth"
            },
            {
                "value": 55519,
                "label": "Bad Blumau",
                "label_ar": "سيئة بلوماو",
                "label_fr": "Bad Blumau"
            },
            {
                "value": 55769,
                "label": "Pischelsdorf am Engelbach",
                "label_ar": "Pischelsdorf am Engelbach",
                "label_fr": "Pischelsdorf am Engelbach"
            },
            {
                "value": 54804,
                "label": "Ried im Zillertal",
                "label_ar": "ريد إم زيلرتال",
                "label_fr": "Ried im Zillertal"
            },
            {
                "value": 54853,
                "label": "Sitzenberg",
                "label_ar": "سيتزنبرج",
                "label_fr": "Sitzenberg"
            },
            {
                "value": 55850,
                "label": "Friedburg",
                "label_ar": "فريدبورغ",
                "label_fr": "Friedburg"
            },
            {
                "value": 55781,
                "label": "Zeltweg",
                "label_ar": "زيلتويج",
                "label_fr": "Zeltweg"
            },
            {
                "value": 55860,
                "label": "Ardagger Markt",
                "label_ar": "Ardagger Markt",
                "label_fr": "Ardagger Markt"
            },
            {
                "value": 54890,
                "label": "Steinhaus am Semmering",
                "label_ar": "Steinhaus am Semmering",
                "label_fr": "Steinhaus am Semmering"
            },
            {
                "value": 55295,
                "label": "Sankt Pankraz",
                "label_ar": "سانكت بانكراز",
                "label_fr": "Sankt Pankraz"
            },
            {
                "value": 55107,
                "label": "Bruck an der Grossglocknerstrasse",
                "label_ar": "Bruck an der Grossglocknerstrasse",
                "label_fr": "Bruck an der Grossglocknerstrasse"
            },
            {
                "value": 55392,
                "label": "Schwarzach",
                "label_ar": "شوارزاتش",
                "label_fr": "Schwarzach"
            },
            {
                "value": 54529,
                "label": "Weinburg am Sassbach",
                "label_ar": "Weinburg am Sassbach",
                "label_fr": "Weinburg am Sassbach"
            },
            {
                "value": 55879,
                "label": "Veitsch",
                "label_ar": "فيتش",
                "label_fr": "Veitsch"
            },
            {
                "value": 55882,
                "label": "Gasthof",
                "label_ar": "جاستهوف",
                "label_fr": "Gasthof"
            },
            {
                "value": 54764,
                "label": "Grieskirchen",
                "label_ar": "جريسكيرشن",
                "label_fr": "Grieskirchen"
            },
            {
                "value": 55859,
                "label": "Gross-Siegharts",
                "label_ar": "إجمالي Siegharts",
                "label_fr": "Gross-Siegharts"
            },
            {
                "value": 55305,
                "label": "Huetten",
                "label_ar": "Huetten",
                "label_fr": "Huetten"
            },
            {
                "value": 54749,
                "label": "Reith im Alpbachtal",
                "label_ar": "ريث إم ألباختال",
                "label_fr": "Reith im Alpbachtal"
            },
            {
                "value": 55374,
                "label": "Neukirchen an der Enknach",
                "label_ar": "Neukirchen an der Enknach",
                "label_fr": "Neukirchen an der Enknach"
            },
            {
                "value": 55043,
                "label": "Wollersdorf",
                "label_ar": "Wollersdorf",
                "label_fr": "Wollersdorf"
            },
            {
                "value": 55731,
                "label": "Niederleis",
                "label_ar": "Niederleis",
                "label_fr": "Niederleis"
            },
            {
                "value": 54753,
                "label": "Kirchberg in Tirol",
                "label_ar": "كيرشبرغ ان تيرول",
                "label_fr": "Kirchberg au Tyrol"
            },
            {
                "value": 55323,
                "label": "Stuhlfelden",
                "label_ar": "Stuhlfelden",
                "label_fr": "Stuhlfelden"
            },
            {
                "value": 54917,
                "label": "Asten",
                "label_ar": "أستين",
                "label_fr": "Asten"
            },
            {
                "value": 54745,
                "label": "Hall in Tirol",
                "label_ar": "هول إن تيرول",
                "label_fr": "Hall au Tyrol"
            },
            {
                "value": 54767,
                "label": "Pichl bei Wels",
                "label_ar": "بيشل باي ويلس",
                "label_fr": "Pichl bei Wels"
            },
            {
                "value": 55484,
                "label": "Tobaj",
                "label_ar": "طوباج",
                "label_fr": "Tobaj"
            },
            {
                "value": 55475,
                "label": "Attersee",
                "label_ar": "أتيرسي",
                "label_fr": "Attersee"
            },
            {
                "value": 54631,
                "label": "Steyr",
                "label_ar": "شتاير",
                "label_fr": "Steyr"
            },
            {
                "value": 54722,
                "label": "Grimmenstein",
                "label_ar": "جريمينشتاين",
                "label_fr": "Grimmenstein"
            },
            {
                "value": 55695,
                "label": "Neidling",
                "label_ar": "نيدلينج",
                "label_fr": "Neidling"
            },
            {
                "value": 55388,
                "label": "Freinberg",
                "label_ar": "فرينبرغ",
                "label_fr": "Freinberg"
            },
            {
                "value": 55062,
                "label": "Unterradlberg",
                "label_ar": "Unterradlberg",
                "label_fr": "Unterradlberg"
            },
            {
                "value": 55873,
                "label": "Jagerberg",
                "label_ar": "ياغيربيرج",
                "label_fr": "Jagerberg"
            },
            {
                "value": 55013,
                "label": "Sankt Wolfgang",
                "label_ar": "سانكت ولفجانج",
                "label_fr": "Sankt Wolfgang"
            },
            {
                "value": 55112,
                "label": "Piesendorf",
                "label_ar": "بيسندورف",
                "label_fr": "Piesendorf"
            },
            {
                "value": 54922,
                "label": "Graen",
                "label_ar": "غرين",
                "label_fr": "Graen"
            },
            {
                "value": 55528,
                "label": "Rudersdorf",
                "label_ar": "رودرسدورف",
                "label_fr": "Rudersdorf"
            },
            {
                "value": 55791,
                "label": "Goedersdorf",
                "label_ar": "Goedersdorf",
                "label_fr": "Goedersdorf"
            },
            {
                "value": 55096,
                "label": "Gnadenwald",
                "label_ar": "Gnadenwald",
                "label_fr": "Gnadenwald"
            },
            {
                "value": 55321,
                "label": "Rohrmoos",
                "label_ar": "روهرموس",
                "label_fr": "Rohrmoos"
            },
            {
                "value": 55162,
                "label": "Parschlug",
                "label_ar": "بارشلوغ",
                "label_fr": "Parschlug"
            },
            {
                "value": 54617,
                "label": "Buchkirchen",
                "label_ar": "بوخكيرشن",
                "label_fr": "Buchkirchen"
            },
            {
                "value": 55662,
                "label": "Aggsbach",
                "label_ar": "أغسباخ",
                "label_fr": "Aggsbach"
            },
            {
                "value": 55432,
                "label": "Siebenhirten",
                "label_ar": "Siebenhirten",
                "label_fr": "Siebenhirten"
            },
            {
                "value": 55380,
                "label": "Michelbach Markt",
                "label_ar": "ميشيلباخ ماركت",
                "label_fr": "Michelbach Markt"
            },
            {
                "value": 55404,
                "label": "Puchberg am Schneeberg",
                "label_ar": "Puchberg am Schneeberg",
                "label_fr": "Puchberg am Schneeberg"
            },
            {
                "value": 55238,
                "label": "Eberndorf",
                "label_ar": "إبرندورف",
                "label_fr": "Eberndorf"
            },
            {
                "value": 55653,
                "label": "Bockfliess",
                "label_ar": "بوكفليس",
                "label_fr": "Bockfliess"
            },
            {
                "value": 55843,
                "label": "Drassburg",
                "label_ar": "دراسبورغ",
                "label_fr": "Drassburg"
            },
            {
                "value": 54499,
                "label": "Ebersdorf",
                "label_ar": "إبيرسدورف",
                "label_fr": "Ebersdorf"
            },
            {
                "value": 54923,
                "label": "Mathon",
                "label_ar": "ماثون",
                "label_fr": "Mathon"
            },
            {
                "value": 54750,
                "label": "Worgl",
                "label_ar": "Worgl",
                "label_fr": "Worgl"
            },
            {
                "value": 54987,
                "label": "Randegg",
                "label_ar": "رانديج",
                "label_fr": "Randegg"
            },
            {
                "value": 55002,
                "label": "Falkenburg",
                "label_ar": "فالكنبورغ",
                "label_fr": "Falkenburg"
            },
            {
                "value": 54623,
                "label": "Wernstein am Inn",
                "label_ar": "Wernstein am Inn",
                "label_fr": "Wernstein am Inn"
            },
            {
                "value": 55400,
                "label": "Gmund",
                "label_ar": "جموند",
                "label_fr": "Gmund"
            },
            {
                "value": 55674,
                "label": "Schwendau",
                "label_ar": "شفينداو",
                "label_fr": "Schwendau"
            },
            {
                "value": 55628,
                "label": "Eisgarn",
                "label_ar": "إيسغارن",
                "label_fr": "Eisgarn"
            },
            {
                "value": 55219,
                "label": "Langenstein",
                "label_ar": "لانغنشتاين",
                "label_fr": "Langenstein"
            },
            {
                "value": 55836,
                "label": "Bludesch",
                "label_ar": "بلودش",
                "label_fr": "Bludesch"
            },
            {
                "value": 55239,
                "label": "Turrach",
                "label_ar": "توراش",
                "label_fr": "Turrach"
            },
            {
                "value": 55203,
                "label": "Edelschrott",
                "label_ar": "إيدلسكروت",
                "label_fr": "Edelschrott"
            },
            {
                "value": 54914,
                "label": "Bruck an der Mur",
                "label_ar": "بروك آن دير مور",
                "label_fr": "Bruck an der Mur"
            },
            {
                "value": 54880,
                "label": "Gosdorf",
                "label_ar": "جوسدورف",
                "label_fr": "Gosdorf"
            },
            {
                "value": 54741,
                "label": "Zillingtal",
                "label_ar": "زلينجتال",
                "label_fr": "Zillingtal"
            },
            {
                "value": 54821,
                "label": "Bizau",
                "label_ar": "بيزاو",
                "label_fr": "Bizau"
            },
            {
                "value": 55076,
                "label": "Winden am See",
                "label_ar": "Winden am See",
                "label_fr": "Winden am See"
            },
            {
                "value": 55148,
                "label": "Obergurgl",
                "label_ar": "Obergurgl",
                "label_fr": "Obergurgl"
            },
            {
                "value": 54636,
                "label": "Hochleithen",
                "label_ar": "Hochleithen",
                "label_fr": "Hochleithen"
            },
            {
                "value": 54502,
                "label": "Sankt Michael im Burgenland",
                "label_ar": "سانكت مايكل إم بورغنلاند",
                "label_fr": "Sankt Michael im Burgenland"
            },
            {
                "value": 54998,
                "label": "Katzelsdorf",
                "label_ar": "كاتزلسدورف",
                "label_fr": "Katzelsdorf"
            },
            {
                "value": 54711,
                "label": "Strasswalchen",
                "label_ar": "Strasswalchen",
                "label_fr": "Strasswalchen"
            },
            {
                "value": 55845,
                "label": "Kremsbruecke",
                "label_ar": "Kremsbruecke",
                "label_fr": "Kremsbruecke"
            },
            {
                "value": 54629,
                "label": "Schardenberg",
                "label_ar": "شاردينبيرج",
                "label_fr": "Schardenberg"
            },
            {
                "value": 54607,
                "label": "Dietersdorf am Gnasbach",
                "label_ar": "ديترسدورف ام جناسباخ",
                "label_fr": "Dietersdorf am Gnasbach"
            },
            {
                "value": 54701,
                "label": "Markt Allhau",
                "label_ar": "سوق اللهاو",
                "label_fr": "Markt Allhau"
            },
            {
                "value": 55718,
                "label": "Rohrbach in Oberosterreich",
                "label_ar": "Rohrbach في Oberosterreich",
                "label_fr": "Rohrbach à Oberosterreich"
            },
            {
                "value": 55106,
                "label": "Leibnitz, Styria",
                "label_ar": "ليبنيتز ، ستيريا",
                "label_fr": "Leibnitz, Styrie"
            },
            {
                "value": 55341,
                "label": "Sankt Georgen im Attergau",
                "label_ar": "Sankt Georgen im Attergau",
                "label_fr": "Sankt Georgen im Attergau"
            },
            {
                "value": 54839,
                "label": "Grafenstein",
                "label_ar": "جرافنشتاين",
                "label_fr": "Grafenstein"
            },
            {
                "value": 55022,
                "label": "Kreuz",
                "label_ar": "كروز",
                "label_fr": "Kreuz"
            },
            {
                "value": 54642,
                "label": "Weitersfeld",
                "label_ar": "ويتسفيلد",
                "label_fr": "Weitersfeld"
            },
            {
                "value": 55409,
                "label": "Gerersdorf",
                "label_ar": "جيرسدورف",
                "label_fr": "Gerersdorf"
            },
            {
                "value": 55104,
                "label": "Reichenau im Muhlkreis",
                "label_ar": "Reichenau im Muhlkreis",
                "label_fr": "Reichenau im Muhlkreis"
            },
            {
                "value": 55751,
                "label": "Radfeld",
                "label_ar": "رادفيلد",
                "label_fr": "Radfeld"
            },
            {
                "value": 55671,
                "label": "Absdorf",
                "label_ar": "أبسدورف",
                "label_fr": "Absdorf"
            },
            {
                "value": 55763,
                "label": "Hochneukirchen",
                "label_ar": "Hochneukirchen",
                "label_fr": "Hochneukirchen"
            },
            {
                "value": 55533,
                "label": "Schlierbach",
                "label_ar": "شليرباخ",
                "label_fr": "Schlierbach"
            },
            {
                "value": 55849,
                "label": "Horersdorf",
                "label_ar": "هوريسدورف",
                "label_fr": "Horersdorf"
            },
            {
                "value": 55633,
                "label": "Waldzell",
                "label_ar": "فالدزيل",
                "label_fr": "Waldzell"
            },
            {
                "value": 54681,
                "label": "Ulrichsberg",
                "label_ar": "أولريشسبيرج",
                "label_fr": "Ulrichsberg"
            },
            {
                "value": 55461,
                "label": "Enzenreith",
                "label_ar": "إنزينريث",
                "label_fr": "Enzenreith"
            },
            {
                "value": 54535,
                "label": "Wallern an der Trattnach",
                "label_ar": "Wallern an der Trattnach",
                "label_fr": "Wallern an der Trattnach"
            },
            {
                "value": 54840,
                "label": "Sankt Ulrich bei Steyr",
                "label_ar": "سانكت أولريش باي شتاير",
                "label_fr": "Sankt Ulrich bei Steyr"
            },
            {
                "value": 55427,
                "label": "Hintertux",
                "label_ar": "هينترتوكس",
                "label_fr": "Hintertux"
            },
            {
                "value": 55160,
                "label": "Hirtenberg",
                "label_ar": "هيرتنبرغ",
                "label_fr": "Hirtenberg"
            },
            {
                "value": 55319,
                "label": "Spratzern",
                "label_ar": "سبراتزرن",
                "label_fr": "Spratzern"
            },
            {
                "value": 55809,
                "label": "Watzendorf",
                "label_ar": "واتزندورف",
                "label_fr": "Watzendorf"
            },
            {
                "value": 54945,
                "label": "Altlengbach",
                "label_ar": "Altlengbach",
                "label_fr": "Altlengbach"
            },
            {
                "value": 55800,
                "label": "Warth",
                "label_ar": "وارث",
                "label_fr": "Warth"
            },
            {
                "value": 54690,
                "label": "Werfenweng",
                "label_ar": "ويرفينوينج",
                "label_fr": "Werfenweng"
            },
            {
                "value": 54570,
                "label": "Traiskirchen",
                "label_ar": "ترايسكيرشن",
                "label_fr": "Traiskirchen"
            },
            {
                "value": 54635,
                "label": "Neukirchen am Walde",
                "label_ar": "Neukirchen am Walde",
                "label_fr": "Neukirchen am Walde"
            },
            {
                "value": 55302,
                "label": "Gruner Baum",
                "label_ar": "جرونر باوم",
                "label_fr": "Gruner Baum"
            },
            {
                "value": 54811,
                "label": "Riezlern",
                "label_ar": "ريزلرن",
                "label_fr": "Riezlern"
            },
            {
                "value": 54944,
                "label": "Sierndorf",
                "label_ar": "سيرندورف",
                "label_fr": "Sierndorf"
            },
            {
                "value": 55434,
                "label": "Knappenberg",
                "label_ar": "كنابنبرغ",
                "label_fr": "Knappenberg"
            },
            {
                "value": 55366,
                "label": "Altwaidhofen",
                "label_ar": "التويدهوفن",
                "label_fr": "Altwaidhofen"
            },
            {
                "value": 54782,
                "label": "Bad Hofgastein",
                "label_ar": "باد هوفجاستين",
                "label_fr": "Bad Hofgastein"
            },
            {
                "value": 54700,
                "label": "Purkersdorf",
                "label_ar": "بيركيرسدورف",
                "label_fr": "Purkersdorf"
            },
            {
                "value": 54873,
                "label": "Schiefling am See",
                "label_ar": "Schiefling am See",
                "label_fr": "Schiefling am See"
            },
            {
                "value": 55874,
                "label": "Sinnersdorf",
                "label_ar": "سينرسدورف",
                "label_fr": "Sinnersdorf"
            },
            {
                "value": 54849,
                "label": "Wangle",
                "label_ar": "وانجل",
                "label_fr": "Combine"
            },
            {
                "value": 55450,
                "label": "Vorau",
                "label_ar": "فوراو",
                "label_fr": "Vorau"
            },
            {
                "value": 54828,
                "label": "Wundschuh",
                "label_ar": "Wundschuh",
                "label_fr": "Wundschuh"
            },
            {
                "value": 55046,
                "label": "Laxenburg",
                "label_ar": "لاكسنبورغ",
                "label_fr": "Laxenburg"
            },
            {
                "value": 54586,
                "label": "Kirchbichl",
                "label_ar": "كيرشبيشل",
                "label_fr": "Kirchbichl"
            },
            {
                "value": 55021,
                "label": "Sankt Gallenkirch",
                "label_ar": "سانكت غالنكيرش",
                "label_fr": "Sankt Gallenkirch"
            },
            {
                "value": 55714,
                "label": "Maria Schmolln",
                "label_ar": "ماريا شمولن",
                "label_fr": "Maria Schmolln"
            },
            {
                "value": 55501,
                "label": "Ardning",
                "label_ar": "أردنينغ",
                "label_fr": "Ardning"
            },
            {
                "value": 55673,
                "label": "Kirchdorf in Tirol",
                "label_ar": "كيرشدورف ان تيرول",
                "label_fr": "Kirchdorf au Tyrol"
            },
            {
                "value": 55322,
                "label": "Schladming",
                "label_ar": "شلادمينغ",
                "label_fr": "Schladming"
            },
            {
                "value": 55204,
                "label": "Mannsworth",
                "label_ar": "مانسورث",
                "label_fr": "Mannsworth"
            },
            {
                "value": 55078,
                "label": "Oslip",
                "label_ar": "أوسليب",
                "label_fr": "Oslip"
            },
            {
                "value": 54723,
                "label": "Bergheim",
                "label_ar": "بيرجهايم",
                "label_fr": "Bergheim"
            },
            {
                "value": 54514,
                "label": "Hartkirchen",
                "label_ar": "هارتكيرشن",
                "label_fr": "Hartkirchen"
            },
            {
                "value": 55048,
                "label": "Neudorf bei Landsee",
                "label_ar": "نيودورف باي لاندسي",
                "label_fr": "Neudorf bei Landsee"
            },
            {
                "value": 55783,
                "label": "Steg",
                "label_ar": "ستيج",
                "label_fr": "Steg"
            },
            {
                "value": 55346,
                "label": "Bruendl",
                "label_ar": "Bruendl",
                "label_fr": "Bruendl"
            },
            {
                "value": 55545,
                "label": "Pernegg an der Mur",
                "label_ar": "بيرنيج أن دير مور",
                "label_fr": "Pernegg an der Mur"
            },
            {
                "value": 55063,
                "label": "Muckendorf an der Donau",
                "label_ar": "Muckendorf an der Donau",
                "label_fr": "Muckendorf an der Donau"
            },
            {
                "value": 55417,
                "label": "Mellach",
                "label_ar": "ميلاخ",
                "label_fr": "Mellach"
            },
            {
                "value": 55758,
                "label": "Landegg",
                "label_ar": "لانديج",
                "label_fr": "Landegg"
            },
            {
                "value": 54513,
                "label": "Tulln",
                "label_ar": "تولن",
                "label_fr": "Tulln"
            },
            {
                "value": 55553,
                "label": "Bach",
                "label_ar": "باخ",
                "label_fr": "Bach"
            },
            {
                "value": 55672,
                "label": "Abtsdorf",
                "label_ar": "أبتسدورف",
                "label_fr": "Abtsdorf"
            },
            {
                "value": 55429,
                "label": "Weng im Innkreis",
                "label_ar": "Weng im Innkreis",
                "label_fr": "Weng im Innkreis"
            },
            {
                "value": 55514,
                "label": "Trausdorf an der Wulka",
                "label_ar": "Trausdorf an der Wulka",
                "label_fr": "Trausdorf an der Wulka"
            },
            {
                "value": 55243,
                "label": "Bezau",
                "label_ar": "بيزو",
                "label_fr": "Bezau"
            },
            {
                "value": 55795,
                "label": "Rutzenmoos",
                "label_ar": "روتزنموس",
                "label_fr": "Rutzenmoos"
            },
            {
                "value": 55201,
                "label": "Sankt Michael in Obersteiermark",
                "label_ar": "سانكت مايكل في Obersteiermark",
                "label_fr": "Sankt Michael à Obersteiermark"
            },
            {
                "value": 54875,
                "label": "Landskron",
                "label_ar": "لاندسكرون",
                "label_fr": "Landskron"
            },
            {
                "value": 54702,
                "label": "Deutschfeistritz",
                "label_ar": "دويتشفيستريتس",
                "label_fr": "Deutschfeistritz"
            },
            {
                "value": 55794,
                "label": "Rottenbach",
                "label_ar": "روتنباخ",
                "label_fr": "Rottenbach"
            },
            {
                "value": 54789,
                "label": "Sillian",
                "label_ar": "سيليان",
                "label_fr": "Sillian"
            },
            {
                "value": 54537,
                "label": "Sankt Florian",
                "label_ar": "سانكت فلوريان",
                "label_fr": "Sankt Florian"
            },
            {
                "value": 55005,
                "label": "Heidenreichstein",
                "label_ar": "Heidenreichstein",
                "label_fr": "Heidenreichstein"
            },
            {
                "value": 55340,
                "label": "Regau",
                "label_ar": "ريجاو",
                "label_fr": "Regau"
            },
            {
                "value": 54609,
                "label": "Fohnsdorf",
                "label_ar": "فوهنسدورف",
                "label_fr": "Fohnsdorf"
            },
            {
                "value": 55610,
                "label": "Fladnitz im Raabtal",
                "label_ar": "Fladnitz im Raabtal",
                "label_fr": "Fladnitz im Raabtal"
            },
            {
                "value": 55194,
                "label": "Voitsberg",
                "label_ar": "فويتسبرج",
                "label_fr": "Voitsberg"
            },
            {
                "value": 55799,
                "label": "Stettenhof",
                "label_ar": "ستيتنهوف",
                "label_fr": "Stettenhof"
            },
            {
                "value": 55864,
                "label": "Gobelsburg",
                "label_ar": "جوبلسبيرغ",
                "label_fr": "Gobelsburg"
            },
            {
                "value": 55527,
                "label": "Purgstall",
                "label_ar": "بورغستال",
                "label_fr": "Purgstall"
            },
            {
                "value": 55488,
                "label": "Herrnbaumgarten",
                "label_ar": "هيرنباومجارتن",
                "label_fr": "Herrnbaumgarten"
            },
            {
                "value": 55765,
                "label": "Seckau",
                "label_ar": "Seckau",
                "label_fr": "Seckau"
            },
            {
                "value": 55075,
                "label": "Am See",
                "label_ar": "أنا أرى",
                "label_fr": "Je vois"
            },
            {
                "value": 55122,
                "label": "Burmoos",
                "label_ar": "بورموس",
                "label_fr": "Burmoos"
            },
            {
                "value": 55548,
                "label": "Rotholz",
                "label_ar": "روثولز",
                "label_fr": "Rotholz"
            },
            {
                "value": 55773,
                "label": "Obritzberg",
                "label_ar": "Obritzberg",
                "label_fr": "Obritzberg"
            },
            {
                "value": 55625,
                "label": "Jagenbach",
                "label_ar": "جاجينباخ",
                "label_fr": "Jagenbach"
            },
            {
                "value": 54754,
                "label": "Rum",
                "label_ar": "رم",
                "label_fr": "Rhum"
            },
            {
                "value": 55292,
                "label": "Waxenberg",
                "label_ar": "واكسنبرغ",
                "label_fr": "Waxenberg"
            },
            {
                "value": 55890,
                "label": "Baumgartenberg",
                "label_ar": "بومغارتنبرغ",
                "label_fr": "Baumgartenberg"
            },
            {
                "value": 55552,
                "label": "Leopoldsdorf",
                "label_ar": "ليوبولدسدورف",
                "label_fr": "Leopoldsdorf"
            },
            {
                "value": 54918,
                "label": "Oftering",
                "label_ar": "Oftering",
                "label_fr": "Après"
            },
            {
                "value": 55069,
                "label": "Lutzmannsburg",
                "label_ar": "لوتزمانسبورج",
                "label_fr": "Lutzmannsburg"
            },
            {
                "value": 54572,
                "label": "Pettnau",
                "label_ar": "بيتناو",
                "label_fr": "Pettnau"
            },
            {
                "value": 55745,
                "label": "Ybbsitz",
                "label_ar": "يبسيتز",
                "label_fr": "Ybbsitz"
            },
            {
                "value": 54909,
                "label": "Ramsau im Zillertal",
                "label_ar": "رامسو إم زيلرتال",
                "label_fr": "Ramsau im Zillertal"
            },
            {
                "value": 54868,
                "label": "Oberneukirchen",
                "label_ar": "Oberneukirchen",
                "label_fr": "Oberneukirchen"
            },
            {
                "value": 55687,
                "label": "Kraig",
                "label_ar": "كريج",
                "label_fr": "Kraig"
            },
            {
                "value": 55072,
                "label": "Oberwart",
                "label_ar": "أوبيرفارت",
                "label_fr": "Oberwart"
            },
            {
                "value": 54715,
                "label": "Schwarzenau",
                "label_ar": "شوارزيناو",
                "label_fr": "Schwarzenau"
            },
            {
                "value": 55332,
                "label": "Wallern im Burgenland",
                "label_ar": "واليرن ايم بورغنلاند",
                "label_fr": "Wallern im Burgenland"
            },
            {
                "value": 55500,
                "label": "Gaal",
                "label_ar": "غال",
                "label_fr": "Gaal"
            },
            {
                "value": 55868,
                "label": "Baldramsdorf",
                "label_ar": "بالدرامسدورف",
                "label_fr": "Baldramsdorf"
            },
            {
                "value": 54709,
                "label": "Feistritz",
                "label_ar": "فيستريتز",
                "label_fr": "Feistritz"
            },
            {
                "value": 54815,
                "label": "Gargellen",
                "label_ar": "جارجلين",
                "label_fr": "Gargellen"
            },
            {
                "value": 54719,
                "label": "Hopfgarten im Brixental",
                "label_ar": "Hopfgarten إم بريكسينتال",
                "label_fr": "Hopfgarten im Brixental"
            },
            {
                "value": 55278,
                "label": "Labach",
                "label_ar": "لاباخ",
                "label_fr": "Labach"
            },
            {
                "value": 55324,
                "label": "Mittersill",
                "label_ar": "ميترسيل",
                "label_fr": "Mittersill"
            },
            {
                "value": 55738,
                "label": "Winzendorf",
                "label_ar": "Winzendorf",
                "label_fr": "Winzendorf"
            },
            {
                "value": 54597,
                "label": "Lustenau",
                "label_ar": "Lustenau",
                "label_fr": "Lustenau"
            },
            {
                "value": 54888,
                "label": "Hainburg an der Donau",
                "label_ar": "Hainburg an der Donau",
                "label_fr": "Hainburg an der Donau"
            },
            {
                "value": 54891,
                "label": "Steinabruckl",
                "label_ar": "شتاينبروكل",
                "label_fr": "Steinabruckl"
            },
            {
                "value": 55155,
                "label": "Gerasdorf am Steinfelde",
                "label_ar": "Gerasdorf am Steinfelde",
                "label_fr": "Gerasdorf am Steinfelde"
            },
            {
                "value": 55676,
                "label": "Lichtenegg",
                "label_ar": "Lichtenegg",
                "label_fr": "Lichtenegg"
            },
            {
                "value": 54632,
                "label": "Aistersheim",
                "label_ar": "أسترسيم",
                "label_fr": "Aistersheim"
            },
            {
                "value": 54771,
                "label": "Ampfelwang",
                "label_ar": "أمبفيلوانج",
                "label_fr": "Ampfelwang"
            },
            {
                "value": 55394,
                "label": "Frauenkirchen",
                "label_ar": "فراونكيرشن",
                "label_fr": "Frauenkirchen"
            },
            {
                "value": 55655,
                "label": "Altmunster",
                "label_ar": "ألتمونستر",
                "label_fr": "Altmunster"
            },
            {
                "value": 55216,
                "label": "Riedenthal",
                "label_ar": "ريدنتال",
                "label_fr": "Riedenthal"
            },
            {
                "value": 54823,
                "label": "Schrems",
                "label_ar": "شريمس",
                "label_fr": "Schrems"
            },
            {
                "value": 55871,
                "label": "Oberschutzen",
                "label_ar": "Oberschutzen",
                "label_fr": "Oberschutzen"
            },
            {
                "value": 55202,
                "label": "Petzenkirchen",
                "label_ar": "بيتزنكيرشن",
                "label_fr": "Petzenkirchen"
            },
            {
                "value": 54695,
                "label": "Muerzsteg",
                "label_ar": "Muerzsteg",
                "label_fr": "Muerzsteg"
            },
            {
                "value": 55592,
                "label": "Paldau",
                "label_ar": "بالداو",
                "label_fr": "Paldau"
            },
            {
                "value": 55777,
                "label": "Rosenau am Hengstpass",
                "label_ar": "Rosenau am Hengstpass",
                "label_fr": "Rosenau am Hengstpass"
            },
            {
                "value": 55016,
                "label": "Schwertberg",
                "label_ar": "شويرتبرج",
                "label_fr": "Schwertberg"
            },
            {
                "value": 55618,
                "label": "Feld am See",
                "label_ar": "فيلد أم سيي",
                "label_fr": "Feld am See"
            },
            {
                "value": 55822,
                "label": "Volders",
                "label_ar": "فولدرز",
                "label_fr": "Volders"
            },
            {
                "value": 54672,
                "label": "Hartberg",
                "label_ar": "هارتبرج",
                "label_fr": "Hartberg"
            },
            {
                "value": 55059,
                "label": "Hennersdorf",
                "label_ar": "هينرسدورف",
                "label_fr": "Hennersdorf"
            },
            {
                "value": 55746,
                "label": "Kaltenleutgeben",
                "label_ar": "كالتنلوتجيبين",
                "label_fr": "Kaltenleutgeben"
            },
            {
                "value": 55680,
                "label": "Aufhausen",
                "label_ar": "اوفهاوزن",
                "label_fr": "Aufhausen"
            },
            {
                "value": 55497,
                "label": "Moellbruecke",
                "label_ar": "Moellbruecke",
                "label_fr": "Moellbruecke"
            },
            {
                "value": 55009,
                "label": "Grieselstein",
                "label_ar": "جريسلستين",
                "label_fr": "Grieselstein"
            },
            {
                "value": 54904,
                "label": "Hinterbruehl",
                "label_ar": "هينتربرويل",
                "label_fr": "Hinterbruehl"
            },
            {
                "value": 54516,
                "label": "Imst",
                "label_ar": "إمست",
                "label_fr": "Imst"
            },
            {
                "value": 55297,
                "label": "Desselbrunn",
                "label_ar": "ديسيلبرون",
                "label_fr": "Desselbrunn"
            },
            {
                "value": 54728,
                "label": "Unterlamm",
                "label_ar": "أونترالام",
                "label_fr": "Unterlamm"
            },
            {
                "value": 55563,
                "label": "Wampersdorf",
                "label_ar": "وامبيرسدورف",
                "label_fr": "Wampersdorf"
            },
            {
                "value": 55470,
                "label": "Ulrichskirchen",
                "label_ar": "Ulrichskirchen",
                "label_fr": "Ulrichskirchen"
            },
            {
                "value": 55007,
                "label": "Petronell-Carnuntum",
                "label_ar": "بترونيل كارنونتوم",
                "label_fr": "Petronell-Carnuntum"
            },
            {
                "value": 54989,
                "label": "Lichtenau",
                "label_ar": "ليشتناو",
                "label_fr": "Lichtenau"
            },
            {
                "value": 55479,
                "label": "Ernsthofen",
                "label_ar": "إرنستهوفن",
                "label_fr": "Ernsthofen"
            },
            {
                "value": 55454,
                "label": "Eisbach",
                "label_ar": "ايسباخ",
                "label_fr": "Eisbach"
            },
            {
                "value": 54501,
                "label": "Sarleinsbach",
                "label_ar": "سارلينزباخ",
                "label_fr": "Sarleinsbach"
            },
            {
                "value": 55708,
                "label": "Hadres",
                "label_ar": "هادرس",
                "label_fr": "Hadres"
            },
            {
                "value": 55283,
                "label": "Gerasdorf bei Wien",
                "label_ar": "جيراسدورف باي فيينا",
                "label_fr": "Gerasdorf bei Wien"
            },
            {
                "value": 55372,
                "label": "Sankt Ruprecht an der Raab",
                "label_ar": "سانكت روبريخت ان دير راب",
                "label_fr": "Sankt Ruprecht an der Raab"
            },
            {
                "value": 55118,
                "label": "Lend",
                "label_ar": "إقراض",
                "label_fr": "Prêter"
            },
            {
                "value": 54843,
                "label": "Pucking",
                "label_ar": "عفريت",
                "label_fr": "Pucking"
            },
            {
                "value": 55727,
                "label": "Monchhof",
                "label_ar": "مونشوف",
                "label_fr": "Monchhof"
            },
            {
                "value": 55749,
                "label": "Traismauer",
                "label_ar": "ترايسماور",
                "label_fr": "Traismauer"
            },
            {
                "value": 55207,
                "label": "Sulz",
                "label_ar": "سولز",
                "label_fr": "Sulz"
            },
            {
                "value": 55641,
                "label": "Marchegg",
                "label_ar": "ماركيغي",
                "label_fr": "Marchegg"
            },
            {
                "value": 55251,
                "label": "Soll",
                "label_ar": "سول",
                "label_fr": "Soll"
            },
            {
                "value": 54906,
                "label": "Traboch",
                "label_ar": "طرابوك",
                "label_fr": "Traboch"
            },
            {
                "value": 55492,
                "label": "Telfes im Stubai",
                "label_ar": "Telfes im Stubai",
                "label_fr": "Telfes im Stubai"
            },
            {
                "value": 55245,
                "label": "Schwarzau am Steinfelde",
                "label_ar": "Schwarzau am Steinfelde",
                "label_fr": "Schwarzau am Steinfelde"
            },
            {
                "value": 55651,
                "label": "Bernhardsthal",
                "label_ar": "برنهاردستال",
                "label_fr": "Bernhardsthal"
            },
            {
                "value": 54606,
                "label": "Gross",
                "label_ar": "إجمالي",
                "label_fr": "Brut"
            },
            {
                "value": 54787,
                "label": "Lavant",
                "label_ar": "لافانت",
                "label_fr": "Lavant"
            },
            {
                "value": 55170,
                "label": "Mariazell",
                "label_ar": "ماريازيل",
                "label_fr": "Mariazell"
            },
            {
                "value": 54679,
                "label": "Altenberg bei Linz",
                "label_ar": "ألتنبرغ باي لينز",
                "label_fr": "Altenberg bei Linz"
            },
            {
                "value": 55411,
                "label": "Tweng",
                "label_ar": "توينج",
                "label_fr": "Tweng"
            },
            {
                "value": 55049,
                "label": "Leobendorf",
                "label_ar": "ليوبندورف",
                "label_fr": "Leobendorf"
            },
            {
                "value": 54618,
                "label": "Neumarkt an der Ybbs",
                "label_ar": "Neumarkt an der Ybbs",
                "label_fr": "Neumarkt an der Ybbs"
            },
            {
                "value": 55828,
                "label": "Japons",
                "label_ar": "اليابان",
                "label_fr": "Japons"
            },
            {
                "value": 55529,
                "label": "Stuhlsdorf",
                "label_ar": "ستولسدورف",
                "label_fr": "Stuhlsdorf"
            },
            {
                "value": 55212,
                "label": "Reith bei Seefeld",
                "label_ar": "ريث باي سيفيلد",
                "label_fr": "Reith bei Seefeld"
            },
            {
                "value": 54785,
                "label": "Zell am See",
                "label_ar": "زيلامسي",
                "label_fr": "Zell am See"
            },
            {
                "value": 55371,
                "label": "Liesfeld",
                "label_ar": "ليسفيلد",
                "label_fr": "Liesfeld"
            },
            {
                "value": 55154,
                "label": "Statzendorf",
                "label_ar": "ستاتزيندورف",
                "label_fr": "Statzendorf"
            },
            {
                "value": 54938,
                "label": "Niederachen",
                "label_ar": "نيدراتشن",
                "label_fr": "Niederachen"
            },
            {
                "value": 55183,
                "label": "Stiegl",
                "label_ar": "شتيجل",
                "label_fr": "Stiegl"
            },
            {
                "value": 55211,
                "label": "Leisach",
                "label_ar": "ليساش",
                "label_fr": "Leisach"
            },
            {
                "value": 54612,
                "label": "Gratkorn",
                "label_ar": "جراتكورن",
                "label_fr": "Gratkorn"
            },
            {
                "value": 54686,
                "label": "Oehling",
                "label_ar": "أوهلينج",
                "label_fr": "Oehling"
            },
            {
                "value": 55280,
                "label": "Sulz im Wienerwald",
                "label_ar": "Sulz im Wienerwald",
                "label_fr": "Sulz im Wienerwald"
            },
            {
                "value": 55766,
                "label": "Altlichtenwarth",
                "label_ar": "التليختنوارث",
                "label_fr": "Altlichtenwarth"
            },
            {
                "value": 54585,
                "label": "Obsteig",
                "label_ar": "أوبستيج",
                "label_fr": "Obsteig"
            },
            {
                "value": 55575,
                "label": "Bauer",
                "label_ar": "باور",
                "label_fr": "Bauer"
            },
            {
                "value": 55217,
                "label": "Hornstein",
                "label_ar": "هورنستين",
                "label_fr": "Hornstein"
            },
            {
                "value": 54769,
                "label": "Vocklabruck",
                "label_ar": "فوكلابروك",
                "label_fr": "Vocklabruck"
            },
            {
                "value": 54806,
                "label": "Nauders",
                "label_ar": "ناودرس",
                "label_fr": "Nauders"
            },
            {
                "value": 54495,
                "label": "Hohenzell",
                "label_ar": "هوهنزيل",
                "label_fr": "Hohenzell"
            },
            {
                "value": 54778,
                "label": "Wals",
                "label_ar": "وولز",
                "label_fr": "Wals"
            },
            {
                "value": 55153,
                "label": "Laab im Walde",
                "label_ar": "لاب ايم فالدي",
                "label_fr": "Laab im Walde"
            },
            {
                "value": 55000,
                "label": "Sankt Lorenzen im Muerztal",
                "label_ar": "سانكت لورينزن إم مويرتستال",
                "label_fr": "Sankt Lorenzen im Muerztal"
            },
            {
                "value": 55376,
                "label": "Edlitz",
                "label_ar": "إدليتس",
                "label_fr": "Edlitz"
            },
            {
                "value": 55268,
                "label": "Ludesch",
                "label_ar": "لوديش",
                "label_fr": "Ludesch"
            },
            {
                "value": 55544,
                "label": "Gresten",
                "label_ar": "جريستن",
                "label_fr": "Gresten"
            },
            {
                "value": 54532,
                "label": "Seitenstetten Markt",
                "label_ar": "سوق سيتنشتيتن",
                "label_fr": "Seitenstetten Markt"
            },
            {
                "value": 55683,
                "label": "Schwarzau im Gebirge",
                "label_ar": "Schwarzau im Gebirge",
                "label_fr": "Schwarzau im Gebirge"
            },
            {
                "value": 55152,
                "label": "Rohrbach am Steinfelde",
                "label_ar": "روهرباخ آم شتاينفيلدي",
                "label_fr": "Rohrbach am Steinfelde"
            },
            {
                "value": 55316,
                "label": "Strassen",
                "label_ar": "ستراسن",
                "label_fr": "Strassen"
            },
            {
                "value": 55466,
                "label": "Zellerndorf",
                "label_ar": "زيلرندورف",
                "label_fr": "Zellerndorf"
            },
            {
                "value": 54783,
                "label": "Hinterglemm",
                "label_ar": "هينتيرجليم",
                "label_fr": "Hinterglemm"
            },
            {
                "value": 54706,
                "label": "Unterwaltersdorf",
                "label_ar": "أونتيروالترزدورف",
                "label_fr": "Unterwaltersdorf"
            },
            {
                "value": 54627,
                "label": "Neuhofen",
                "label_ar": "نيوهوفن",
                "label_fr": "Neuhofen"
            },
            {
                "value": 55538,
                "label": "Berg",
                "label_ar": "بيرج",
                "label_fr": "Berg"
            },
            {
                "value": 55878,
                "label": "Haus",
                "label_ar": "هاوس",
                "label_fr": "Haus"
            },
            {
                "value": 55645,
                "label": "Jeging",
                "label_ar": "جيجينج",
                "label_fr": "Jeging"
            },
            {
                "value": 54879,
                "label": "Arriach",
                "label_ar": "Arriach",
                "label_fr": "Arriach"
            },
            {
                "value": 55176,
                "label": "Hohentauern",
                "label_ar": "هوهينتويرن",
                "label_fr": "Hohentauern"
            },
            {
                "value": 55209,
                "label": "Wolkersdorf im Weinviertel",
                "label_ar": "Wolkersdorf im Weinviertel",
                "label_fr": "Wolkersdorf im Weinviertel"
            },
            {
                "value": 55472,
                "label": "Schoerfling",
                "label_ar": "شويرفلينج",
                "label_fr": "Schoerfling"
            },
            {
                "value": 54915,
                "label": "Enns",
                "label_ar": "اينس",
                "label_fr": "Enns"
            },
            {
                "value": 55058,
                "label": "Zwolfaxing",
                "label_ar": "زولفاكسينج",
                "label_fr": "Zwolfaxing"
            },
            {
                "value": 55439,
                "label": "Braunau am Inn",
                "label_ar": "فندق Braunau am Inn",
                "label_fr": "Braunau am Inn"
            },
            {
                "value": 55869,
                "label": "Reith",
                "label_ar": "ريث",
                "label_fr": "Reith"
            },
            {
                "value": 55188,
                "label": "Ferndorf",
                "label_ar": "فيرندورف",
                "label_fr": "Ferndorf"
            },
            {
                "value": 54992,
                "label": "Ebergassing",
                "label_ar": "Ebergassing",
                "label_fr": "Ebergassant"
            },
            {
                "value": 54919,
                "label": "Laakirchen",
                "label_ar": "لاكيرشين",
                "label_fr": "Laakirchen"
            },
            {
                "value": 55701,
                "label": "Steinfeld",
                "label_ar": "شتاينفيلد",
                "label_fr": "Steinfeld"
            },
            {
                "value": 55590,
                "label": "Karl",
                "label_ar": "كارل",
                "label_fr": "Karl"
            },
            {
                "value": 55638,
                "label": "Heiligenkreuz am Waasen",
                "label_ar": "هيليغنكروز آم واسن",
                "label_fr": "Heiligenkreuz am Waasen"
            },
            {
                "value": 54595,
                "label": "Bregenz",
                "label_ar": "بريجينز",
                "label_fr": "Bregenz"
            },
            {
                "value": 54536,
                "label": "Neumarkt im Hausruckkreis",
                "label_ar": "Neumarkt im Hausruckkreis",
                "label_fr": "Neumarkt im Hausruckkreis"
            },
            {
                "value": 55038,
                "label": "Loipersbach",
                "label_ar": "لويبرسباخ",
                "label_fr": "Loipersbach"
            },
            {
                "value": 55224,
                "label": "Neukirchen",
                "label_ar": "نويكيرشن",
                "label_fr": "Neukirchen"
            },
            {
                "value": 55236,
                "label": "Aigen",
                "label_ar": "ايجن",
                "label_fr": "Aigen"
            },
            {
                "value": 54861,
                "label": "Gallneukirchen",
                "label_ar": "غالنيوكيرشن",
                "label_fr": "Gallneukirchen"
            },
            {
                "value": 54912,
                "label": "Hohenburg",
                "label_ar": "هوهنبورغ",
                "label_fr": "Hohenburg"
            },
            {
                "value": 54941,
                "label": "Kossen",
                "label_ar": "كوسين",
                "label_fr": "Kossen"
            },
            {
                "value": 55658,
                "label": "Grabelsdorf",
                "label_ar": "جرابلسدورف",
                "label_fr": "Grabelsdorf"
            },
            {
                "value": 55700,
                "label": "Rauchenwarth",
                "label_ar": "راوتشينوارث",
                "label_fr": "Rauchenwarth"
            },
            {
                "value": 55101,
                "label": "Feuchten",
                "label_ar": "Feuchten",
                "label_fr": "Feuchten"
            },
            {
                "value": 55476,
                "label": "Achensee",
                "label_ar": "أتشينسي",
                "label_fr": "Achensee"
            },
            {
                "value": 55296,
                "label": "Freilassing",
                "label_ar": "Freilassing",
                "label_fr": "Freilassing"
            },
            {
                "value": 55569,
                "label": "Zurndorf",
                "label_ar": "زورندورف",
                "label_fr": "Zurndorf"
            },
            {
                "value": 54652,
                "label": "Muerzzuschlag",
                "label_ar": "Muerzzuschlag",
                "label_fr": "Muerzzuschlag"
            },
            {
                "value": 54575,
                "label": "Aldrans",
                "label_ar": "الدرانس",
                "label_fr": "Aldrans"
            },
            {
                "value": 55082,
                "label": "Stadl-Paura",
                "label_ar": "ستادل بورا",
                "label_fr": "Stadl-Paura"
            },
            {
                "value": 55606,
                "label": "Feistritz an der Drau",
                "label_ar": "Feistritz an der Drau",
                "label_fr": "Feistritz an der Drau"
            },
            {
                "value": 55090,
                "label": "Ybbs an der Donau",
                "label_ar": "Ybbs an der Donau",
                "label_fr": "Ybbs an der Donau"
            },
            {
                "value": 55862,
                "label": "Haslau an der Donau",
                "label_ar": "Haslau an der Donau",
                "label_fr": "Haslau an der Donau"
            },
            {
                "value": 55692,
                "label": "Wilfleinsdorf",
                "label_ar": "ويلفلينسدورف",
                "label_fr": "Wilfleinsdorf"
            },
            {
                "value": 54877,
                "label": "Leutschach",
                "label_ar": "ليتشاخ",
                "label_fr": "Leutschach"
            },
            {
                "value": 55066,
                "label": "Trautmannsdorf an der Leitha",
                "label_ar": "Trautmannsdorf an der Leitha",
                "label_fr": "Trautmannsdorf an der Leitha"
            },
            {
                "value": 55594,
                "label": "Hoflein",
                "label_ar": "هوفلين",
                "label_fr": "Hoflein"
            },
            {
                "value": 55681,
                "label": "Eichgraben",
                "label_ar": "Eichgraben",
                "label_fr": "Eichgraben"
            },
            {
                "value": 55192,
                "label": "Weiler",
                "label_ar": "وايلر",
                "label_fr": "Weiler"
            },
            {
                "value": 55178,
                "label": "Rosenbach",
                "label_ar": "روزنباخ",
                "label_fr": "Rosenbach"
            },
            {
                "value": 55262,
                "label": "Liebenfels",
                "label_ar": "ليبنفيلس",
                "label_fr": "Liebenfels"
            },
            {
                "value": 55720,
                "label": "Doren",
                "label_ar": "دورين",
                "label_fr": "Doren"
            },
            {
                "value": 55885,
                "label": "Hatzendorf",
                "label_ar": "هاتزندورف",
                "label_fr": "Hatzendorf"
            },
            {
                "value": 54720,
                "label": "Tirol",
                "label_ar": "تيرول",
                "label_fr": "Tyrol"
            },
            {
                "value": 55119,
                "label": "Schwarzach im Pongau",
                "label_ar": "Schwarzach im Pongau",
                "label_fr": "Schwarzach im Pongau"
            },
            {
                "value": 55726,
                "label": "Neutal",
                "label_ar": "نيوتال",
                "label_fr": "Neutal"
            },
            {
                "value": 55320,
                "label": "Keutschach am See",
                "label_ar": "Keutschach am See",
                "label_fr": "Keutschach am See"
            },
            {
                "value": 54503,
                "label": "Bad Ischl",
                "label_ar": "سيئة ايشل",
                "label_fr": "Bad Ischl"
            },
            {
                "value": 55806,
                "label": "Adnet",
                "label_ar": "Adnet",
                "label_fr": "Adnet"
            },
            {
                "value": 54734,
                "label": "Fugen",
                "label_ar": "فوجين",
                "label_fr": "Fugen"
            },
            {
                "value": 55347,
                "label": "Ternberg",
                "label_ar": "تيرنبرغ",
                "label_fr": "Ternberg"
            },
            {
                "value": 54678,
                "label": "Dietach",
                "label_ar": "ديتاش",
                "label_fr": "Dietach"
            },
            {
                "value": 55171,
                "label": "Leobersdorf",
                "label_ar": "ليوبرسدورف",
                "label_fr": "Leobersdorf"
            },
            {
                "value": 55551,
                "label": "Niederalm",
                "label_ar": "نيدرالم",
                "label_fr": "Niederalm"
            },
            {
                "value": 54972,
                "label": "Kremsdorf",
                "label_ar": "كريمسدورف",
                "label_fr": "Kremsdorf"
            },
            {
                "value": 55512,
                "label": "Mallnitz",
                "label_ar": "مالنيتز",
                "label_fr": "Mallnitz"
            },
            {
                "value": 54554,
                "label": "Deutschlandsberg",
                "label_ar": "دويتشلاندسبيرج",
                "label_fr": "Deutschlandsberg"
            },
            {
                "value": 55424,
                "label": "Grafenweg",
                "label_ar": "جرافينويج",
                "label_fr": "Grafenweg"
            },
            {
                "value": 55735,
                "label": "Thaya",
                "label_ar": "ثايا",
                "label_fr": "Thaya"
            },
            {
                "value": 54952,
                "label": "Faak am See",
                "label_ar": "فاك ام سي",
                "label_fr": "Faak am See"
            },
            {
                "value": 55333,
                "label": "Siegendorf im Burgenland",
                "label_ar": "Siegendorf im Burgenland",
                "label_fr": "Siegendorf im Burgenland"
            },
            {
                "value": 55535,
                "label": "Trofaiach",
                "label_ar": "تروفياش",
                "label_fr": "Trofaiach"
            },
            {
                "value": 55790,
                "label": "Pernitz",
                "label_ar": "بيرنيتز",
                "label_fr": "Pernitz"
            },
            {
                "value": 54566,
                "label": "Parndorf",
                "label_ar": "بارندورف",
                "label_fr": "Parndorf"
            },
            {
                "value": 54780,
                "label": "Anthering",
                "label_ar": "أنثيرنج",
                "label_fr": "Anthering"
            },
            {
                "value": 55054,
                "label": "Rekawinkel",
                "label_ar": "Rekawinkel",
                "label_fr": "Rekawinkel"
            },
            {
                "value": 55011,
                "label": "Kottingbrunn",
                "label_ar": "كوتينغبرون",
                "label_fr": "Kottingbrunn"
            },
            {
                "value": 55724,
                "label": "Staatz",
                "label_ar": "ستاتز",
                "label_fr": "Staatz"
            },
            {
                "value": 55565,
                "label": "Zemendorf",
                "label_ar": "زيمندورف",
                "label_fr": "Zemendorf"
            },
            {
                "value": 55093,
                "label": "Rappoltenkirchen",
                "label_ar": "رابولتنكيرشن",
                "label_fr": "Rappoltenkirchen"
            },
            {
                "value": 55506,
                "label": "Flinsdorf",
                "label_ar": "فلينسدورف",
                "label_fr": "Flinsdorf"
            },
            {
                "value": 55886,
                "label": "Silz",
                "label_ar": "سيلز",
                "label_fr": "Silz"
            },
            {
                "value": 54977,
                "label": "Arnoldstein",
                "label_ar": "أرنولدشتاين",
                "label_fr": "Arnoldstein"
            },
            {
                "value": 54926,
                "label": "Scheffau am Wilden Kaiser",
                "label_ar": "Scheffau am Wilden Kaiser",
                "label_fr": "Scheffau am Wilden Kaiser"
            },
            {
                "value": 55789,
                "label": "Schachendorf",
                "label_ar": "شاشندورف",
                "label_fr": "Schachendorf"
            },
            {
                "value": 55509,
                "label": "Mauerkirchen",
                "label_ar": "ماوركيرشن",
                "label_fr": "Mauerkirchen"
            },
            {
                "value": 55793,
                "label": "Reichraming",
                "label_ar": "Reichraming",
                "label_fr": "Reichraming"
            },
            {
                "value": 55367,
                "label": "Kaumberg",
                "label_ar": "كامبرج",
                "label_fr": "Kaumberg"
            },
            {
                "value": 55001,
                "label": "Unterpurkla",
                "label_ar": "أونتيربوركلا",
                "label_fr": "Unterpurkla"
            },
            {
                "value": 55866,
                "label": "Ziersdorf",
                "label_ar": "زيرسدورف",
                "label_fr": "Ziersdorf"
            },
            {
                "value": 55406,
                "label": "Fuernitz",
                "label_ar": "فويرنيتز",
                "label_fr": "Fuernitz"
            },
            {
                "value": 55215,
                "label": "Kritzendorf",
                "label_ar": "كريتزندورف",
                "label_fr": "Kritzendorf"
            },
            {
                "value": 54565,
                "label": "Marktl",
                "label_ar": "ماركتل",
                "label_fr": "Marktl"
            },
            {
                "value": 54829,
                "label": "Glan",
                "label_ar": "غلان",
                "label_fr": "Glan"
            },
            {
                "value": 55288,
                "label": "Dellach",
                "label_ar": "ديلاخ",
                "label_fr": "Dellach"
            },
            {
                "value": 55852,
                "label": "Steinberg bei Ligist",
                "label_ar": "شتاينبرغ باي ليجيست",
                "label_fr": "Steinberg bei Ligist"
            },
            {
                "value": 55709,
                "label": "Maissau",
                "label_ar": "مايسو",
                "label_fr": "Maissau"
            },
            {
                "value": 54732,
                "label": "Aurolzmunster",
                "label_ar": "أورولزمونستر",
                "label_fr": "Aurolzmunster"
            },
            {
                "value": 54876,
                "label": "Purgg",
                "label_ar": "Purgg",
                "label_fr": "Purgg"
            },
            {
                "value": 55124,
                "label": "Ebensee",
                "label_ar": "إبينسي",
                "label_fr": "Ebensee"
            },
            {
                "value": 55471,
                "label": "Engersdorf",
                "label_ar": "انجرسدورف",
                "label_fr": "Engersdorf"
            },
            {
                "value": 55580,
                "label": "Tschoeran",
                "label_ar": "Tschoeran",
                "label_fr": "Tschoeran"
            },
            {
                "value": 55359,
                "label": "Waidmannsfeld",
                "label_ar": "ويدمانسفيلد",
                "label_fr": "Waidmannsfeld"
            },
            {
                "value": 55660,
                "label": "Ottenheim",
                "label_ar": "أوتينهايم",
                "label_fr": "Ottenheim"
            },
            {
                "value": 55413,
                "label": "Hinterburg",
                "label_ar": "هينتربرج",
                "label_fr": "Hinterburg"
            },
            {
                "value": 55398,
                "label": "Steinakirchen am Forst",
                "label_ar": "Steinakirchen am Forst",
                "label_fr": "Steinakirchen am Forst"
            },
            {
                "value": 54521,
                "label": "Melk",
                "label_ar": "ميلك",
                "label_fr": "Melk"
            },
            {
                "value": 55353,
                "label": "Eisenberg",
                "label_ar": "أيزنبرغ",
                "label_fr": "Eisenberg"
            },
            {
                "value": 54730,
                "label": "Weibern",
                "label_ar": "ويبرن",
                "label_fr": "Weibern"
            },
            {
                "value": 55669,
                "label": "Schneeberg",
                "label_ar": "شنيبرغ",
                "label_fr": "Schneeberg"
            },
            {
                "value": 54703,
                "label": "Sebersdorf",
                "label_ar": "سيبيرسدورف",
                "label_fr": "Sebersdorf"
            },
            {
                "value": 54996,
                "label": "Sollenau",
                "label_ar": "سوليناو",
                "label_fr": "Sollenau"
            },
            {
                "value": 55467,
                "label": "Grossebersdorf",
                "label_ar": "جروسسبيرسدورف",
                "label_fr": "Grossebersdorf"
            },
            {
                "value": 55705,
                "label": "Gries am Brenner",
                "label_ar": "جريس آم برينر",
                "label_fr": "Gries am Brenner"
            },
            {
                "value": 54696,
                "label": "Sieghartskirchen",
                "label_ar": "سيغارتسكيرشن",
                "label_fr": "Sieghartskirchen"
            },
            {
                "value": 55357,
                "label": "Bad Tatzmannsdorf",
                "label_ar": "باد تاتزمانسدورف",
                "label_fr": "Bad Tatzmannsdorf"
            },
            {
                "value": 55318,
                "label": "Sankt Valentin",
                "label_ar": "سانكت فالنتين",
                "label_fr": "Sankt Valentin"
            },
            {
                "value": 55682,
                "label": "Mollram",
                "label_ar": "مولرام",
                "label_fr": "Mollram"
            },
            {
                "value": 55892,
                "label": "Oberhausen",
                "label_ar": "أوبرهاوزن",
                "label_fr": "Oberhausen"
            },
            {
                "value": 55164,
                "label": "Wolfurt",
                "label_ar": "وولفورت",
                "label_fr": "Wolfurt"
            },
            {
                "value": 55337,
                "label": "Reutte",
                "label_ar": "ريوت",
                "label_fr": "Reutte"
            },
            {
                "value": 55541,
                "label": "Thal",
                "label_ar": "ثال",
                "label_fr": "Thal"
            },
            {
                "value": 55282,
                "label": "Schleinbach",
                "label_ar": "شلينباخ",
                "label_fr": "Schleinbach"
            },
            {
                "value": 55505,
                "label": "Buch bei Jenbach",
                "label_ar": "بوخ باي جينباخ",
                "label_fr": "Buch bei Jenbach"
            },
            {
                "value": 54910,
                "label": "Grosspesendorf",
                "label_ar": "جروسبيسندورف",
                "label_fr": "Grosspesendorf"
            },
            {
                "value": 55100,
                "label": "Fiss",
                "label_ar": "فيس",
                "label_fr": "Fiss"
            },
            {
                "value": 55503,
                "label": "Anger",
                "label_ar": "الغضب",
                "label_fr": "Colère"
            },
            {
                "value": 54634,
                "label": "Siezenheim",
                "label_ar": "سيزينهايم",
                "label_fr": "Siezenheim"
            },
            {
                "value": 54929,
                "label": "Hochgurgl",
                "label_ar": "Hochgurgl",
                "label_fr": "Hochgurgl"
            },
            {
                "value": 54930,
                "label": "Wenns",
                "label_ar": "وينز",
                "label_fr": "Wenns"
            },
            {
                "value": 55831,
                "label": "Gollersdorf",
                "label_ar": "جولرسدورف",
                "label_fr": "Gollersdorf"
            },
            {
                "value": 54937,
                "label": "Lienz",
                "label_ar": "لينز",
                "label_fr": "Lienz"
            },
            {
                "value": 55848,
                "label": "Trasdorf",
                "label_ar": "Trasdorf",
                "label_fr": "Trasdorf"
            },
            {
                "value": 54584,
                "label": "Woelfnitz",
                "label_ar": "ولفنيتز",
                "label_fr": "Woelfnitz"
            },
            {
                "value": 55694,
                "label": "Raabs an der Thaya",
                "label_ar": "Raabs an der Thaya",
                "label_fr": "Raabs an der Thaya"
            },
            {
                "value": 55583,
                "label": "Eibiswald",
                "label_ar": "إيبسوالد",
                "label_fr": "Eibiswald"
            },
            {
                "value": 55685,
                "label": "Riefensberg",
                "label_ar": "ريفينسبيرج",
                "label_fr": "Riefensberg"
            },
            {
                "value": 55232,
                "label": "Tumeltsham",
                "label_ar": "توملتشام",
                "label_fr": "Tumeltsham"
            },
            {
                "value": 55596,
                "label": "Loimersdorf",
                "label_ar": "Loimersdorf",
                "label_fr": "Loimersdorf"
            },
            {
                "value": 55706,
                "label": "Schmirn",
                "label_ar": "شميرن",
                "label_fr": "Schmirn"
            },
            {
                "value": 54576,
                "label": "Jochberg",
                "label_ar": "Jochberg",
                "label_fr": "Jochberg"
            },
            {
                "value": 55737,
                "label": "Ebendorf",
                "label_ar": "إيبندورف",
                "label_fr": "Ebendorf"
            },
            {
                "value": 55585,
                "label": "Hernstein",
                "label_ar": "هيرنشتاين",
                "label_fr": "Hernstein"
            },
            {
                "value": 54540,
                "label": "Zell an der Ybbs",
                "label_ar": "Zell an der Ybbs",
                "label_fr": "Zell an der Ybbs"
            },
            {
                "value": 55774,
                "label": "Gansbach",
                "label_ar": "غانسباخ",
                "label_fr": "Gansbach"
            },
            {
                "value": 55624,
                "label": "Vitis",
                "label_ar": "فيتيس",
                "label_fr": "Vitis"
            },
            {
                "value": 55807,
                "label": "Muhlbach am Manhartsberg",
                "label_ar": "Muhlbach am Manhartsberg",
                "label_fr": "Muhlbach am Manhartsberg"
            },
            {
                "value": 54838,
                "label": "Sirnitz",
                "label_ar": "سيرنيتز",
                "label_fr": "Sirnitz"
            },
            {
                "value": 55549,
                "label": "Pillberg",
                "label_ar": "بيلبرغ",
                "label_fr": "Pillberg"
            },
            {
                "value": 55494,
                "label": "Egg am Faaker See",
                "label_ar": "Egg am Faaker See",
                "label_fr": "Egg am Faaker See"
            },
            {
                "value": 55742,
                "label": "Poysbrunn",
                "label_ar": "بويسبرون",
                "label_fr": "Poysbrunn"
            },
            {
                "value": 55880,
                "label": "Sankt Marienkirchen am Hausruck",
                "label_ar": "سانكت مارينكيرشن أم هوسروك",
                "label_fr": "Sankt Marienkirchen am Hausruck"
            },
            {
                "value": 55644,
                "label": "Steinerberg",
                "label_ar": "شتاينربيرج",
                "label_fr": "Steinerberg"
            },
            {
                "value": 55198,
                "label": "Neukirchen an der Vockla",
                "label_ar": "Neukirchen an der Vockla",
                "label_fr": "Neukirchen an der Vockla"
            },
            {
                "value": 54905,
                "label": "Tattendorf",
                "label_ar": "تاتندورف",
                "label_fr": "Tattendorf"
            },
            {
                "value": 55053,
                "label": "Wolfsgraben",
                "label_ar": "ولفسجرابين",
                "label_fr": "Wolfsgraben"
            },
            {
                "value": 54997,
                "label": "Langenzersdorf",
                "label_ar": "لانغينزرسدورف",
                "label_fr": "Langenzersdorf"
            },
            {
                "value": 55187,
                "label": "Strassengel",
                "label_ar": "ستراسينجيل",
                "label_fr": "Strassengel"
            },
            {
                "value": 55362,
                "label": "Meisten",
                "label_ar": "ميستين",
                "label_fr": "Meisten"
            },
            {
                "value": 55716,
                "label": "Piberbach",
                "label_ar": "بيبرباخ",
                "label_fr": "Piberbach"
            },
            {
                "value": 55167,
                "label": "Fussach",
                "label_ar": "فساش",
                "label_fr": "Fussach"
            },
            {
                "value": 55399,
                "label": "Ulmerfeld",
                "label_ar": "أولميرفيلد",
                "label_fr": "Ulmerfeld"
            },
            {
                "value": 55433,
                "label": "Maria-Anzbach",
                "label_ar": "ماريا أنزباخ",
                "label_fr": "Maria-Anzbach"
            },
            {
                "value": 55560,
                "label": "Edt bei Lambach",
                "label_ar": "Edt bei Lambach",
                "label_fr": "Edt bei Lambach"
            },
            {
                "value": 55626,
                "label": "Altendorf",
                "label_ar": "ألتندورف",
                "label_fr": "Altendorf"
            },
            {
                "value": 54527,
                "label": "Saalfelden am Steinernen Meer",
                "label_ar": "Saalfelden am Steinernen Meer",
                "label_fr": "Saalfelden am Steinernen Meer"
            },
            {
                "value": 55402,
                "label": "Sankt Agatha",
                "label_ar": "سانكت أجاثا",
                "label_fr": "Sankt Agatha"
            },
            {
                "value": 54494,
                "label": "Kirchham",
                "label_ar": "كيرشام",
                "label_fr": "Kirchham"
            },
            {
                "value": 55225,
                "label": "Judenburg",
                "label_ar": "جودنبورغ",
                "label_fr": "Judenburg"
            },
            {
                "value": 55080,
                "label": "Kundl",
                "label_ar": "كوندل",
                "label_fr": "Kundl"
            },
            {
                "value": 54549,
                "label": "Pichling bei Stainz",
                "label_ar": "بيشلينج باي ستينز",
                "label_fr": "Pichling bei Stainz"
            },
            {
                "value": 54739,
                "label": "Hof am Leithaberge",
                "label_ar": "هوف آم ليثابيرج",
                "label_fr": "Hof am Leithaberge"
            },
            {
                "value": 54620,
                "label": "Neumarkt im Muhlkreis",
                "label_ar": "Neumarkt im Muhlkreis",
                "label_fr": "Neumarkt im Muhlkreis"
            },
            {
                "value": 55493,
                "label": "Pfons",
                "label_ar": "بفونس",
                "label_fr": "Pfons"
            },
            {
                "value": 55771,
                "label": "Rosental",
                "label_ar": "روزنتال",
                "label_fr": "Rosental"
            },
            {
                "value": 54752,
                "label": "Thaur",
                "label_ar": "ثار",
                "label_fr": "Thaur"
            },
            {
                "value": 54524,
                "label": "Stockerau",
                "label_ar": "ستوكيراو",
                "label_fr": "Stockerau"
            },
            {
                "value": 55485,
                "label": "Aspang Markt",
                "label_ar": "اسبانج ماركت",
                "label_fr": "Aspang Markt"
            },
            {
                "value": 54883,
                "label": "Schruns",
                "label_ar": "شرنز",
                "label_fr": "Schruns"
            },
            {
                "value": 54648,
                "label": "Waldenstein",
                "label_ar": "والدنشتاين",
                "label_fr": "Waldenstein"
            },
            {
                "value": 55762,
                "label": "Krumbach Markt",
                "label_ar": "كرومباخ ماركت",
                "label_fr": "Krumbach Markt"
            },
            {
                "value": 55460,
                "label": "Kroatisch Minihof",
                "label_ar": "Kroatisch Minihof",
                "label_fr": "Kroatisch Minihof"
            },
            {
                "value": 55570,
                "label": "Going",
                "label_ar": "ذاهب",
                "label_fr": "Aller"
            },
            {
                "value": 55057,
                "label": "Maria Lanzendorf",
                "label_ar": "ماريا لانزيندورف",
                "label_fr": "Maria Lanzendorf"
            },
            {
                "value": 54733,
                "label": "Puch bei Hallein",
                "label_ar": "بوش باي هاللين",
                "label_fr": "Puch bei Hallein"
            },
            {
                "value": 55814,
                "label": "Moosdorf",
                "label_ar": "موسدورف",
                "label_fr": "Moosdorf"
            },
            {
                "value": 55725,
                "label": "Altenburg",
                "label_ar": "ألتنبرغ",
                "label_fr": "Altenburg"
            },
            {
                "value": 54934,
                "label": "Solden",
                "label_ar": "سولدين",
                "label_fr": "Solden"
            },
            {
                "value": 54515,
                "label": "Hermagor",
                "label_ar": "هيرماجور",
                "label_fr": "Hermagor"
            },
            {
                "value": 55363,
                "label": "Schlins",
                "label_ar": "شلينز",
                "label_fr": "Schlins"
            },
            {
                "value": 54984,
                "label": "Oberndorf an der Melk",
                "label_ar": "اوبرندورف ان دير ميلك",
                "label_fr": "Oberndorf an der Melk"
            },
            {
                "value": 54893,
                "label": "Bruck an der Leitha",
                "label_ar": "Bruck an der Leitha",
                "label_fr": "Bruck an der Leitha"
            },
            {
                "value": 55350,
                "label": "Stadelbach",
                "label_ar": "ستادلباخ",
                "label_fr": "Stadelbach"
            },
            {
                "value": 55691,
                "label": "Maria Ellend",
                "label_ar": "ماريا إليند",
                "label_fr": "Maria Ellend"
            },
            {
                "value": 55719,
                "label": "Ottendorf bei Gleisdorf",
                "label_ar": "Ottendorf bei Gleisdorf",
                "label_fr": "Ottendorf bei Gleisdorf"
            },
            {
                "value": 54613,
                "label": "Villach",
                "label_ar": "فيلاخ",
                "label_fr": "Villach"
            },
            {
                "value": 54803,
                "label": "Ischgl",
                "label_ar": "ايشجل",
                "label_fr": "Ischgl"
            },
            {
                "value": 54836,
                "label": "Bodensdorf",
                "label_ar": "بودنسدورف",
                "label_fr": "Bodensdorf"
            },
            {
                "value": 54528,
                "label": "Weiz",
                "label_ar": "ويز",
                "label_fr": "Weiz"
            },
            {
                "value": 54512,
                "label": "Maria Enzersdorf",
                "label_ar": "ماريا انزرسدورف",
                "label_fr": "Maria Enzersdorf"
            },
            {
                "value": 54588,
                "label": "Schoenberg im Stubaital",
                "label_ar": "شوينبيرج إم ستوبيتال",
                "label_fr": "Schoenberg im Stubaital"
            },
            {
                "value": 54759,
                "label": "Altach",
                "label_ar": "ألتاش",
                "label_fr": "Altach"
            },
            {
                "value": 55616,
                "label": "Taufkirchen an der Pram",
                "label_ar": "Taufkirchen an der Pram",
                "label_fr": "Taufkirchen an der Pram"
            },
            {
                "value": 54983,
                "label": "Sankt Georgen am Ybbsfelde",
                "label_ar": "سانكت جورجين ام يبسفيلدي",
                "label_fr": "Sankt Georgen am Ybbsfelde"
            },
            {
                "value": 55796,
                "label": "Mitterkirchen im Machland",
                "label_ar": "ميتركيرشن إم ماشلاند",
                "label_fr": "Mitterkirchen im Machland"
            },
            {
                "value": 55808,
                "label": "Spillern",
                "label_ar": "Spillern",
                "label_fr": "Spillern"
            },
            {
                "value": 55180,
                "label": "Wattens",
                "label_ar": "واتينز",
                "label_fr": "Wattens"
            },
            {
                "value": 54610,
                "label": "Murfeld",
                "label_ar": "مورفيلد",
                "label_fr": "Murfeld"
            },
            {
                "value": 54660,
                "label": "Oberperfuss",
                "label_ar": "أوبربيرفوس",
                "label_fr": "Oberperfuss"
            },
            {
                "value": 55299,
                "label": "Flattach",
                "label_ar": "فلاتاتش",
                "label_fr": "Flattach"
            },
            {
                "value": 55507,
                "label": "Konigswiesen",
                "label_ar": "Konigswiesen",
                "label_fr": "Konigswiesen"
            },
            {
                "value": 54916,
                "label": "Ungenach",
                "label_ar": "أونجيناش",
                "label_fr": "Ungenach"
            },
            {
                "value": 54762,
                "label": "Gallspach",
                "label_ar": "غالسباخ",
                "label_fr": "Gallspach"
            },
            {
                "value": 54685,
                "label": "Scharnstein",
                "label_ar": "شارنشتاين",
                "label_fr": "Scharnstein"
            },
            {
                "value": 55867,
                "label": "Glaubendorf",
                "label_ar": "جلوبندورف",
                "label_fr": "Glaubendorf"
            },
            {
                "value": 54669,
                "label": "Klosterle",
                "label_ar": "كلوستيرل",
                "label_fr": "Klosterle"
            },
            {
                "value": 54862,
                "label": "Klein-Harras",
                "label_ar": "كلاين هراس",
                "label_fr": "Klein-Harras"
            },
            {
                "value": 54814,
                "label": "Galtur",
                "label_ar": "جالتور",
                "label_fr": "Galtur"
            },
            {
                "value": 55257,
                "label": "Kematen in Tirol",
                "label_ar": "Kematen in Tirol",
                "label_fr": "Kematen in Tirol"
            },
            {
                "value": 55019,
                "label": "Tschagguns",
                "label_ar": "Tschagguns",
                "label_fr": "Tschagguns"
            },
            {
                "value": 55064,
                "label": "Ganserndorf",
                "label_ar": "غانسيرندورف",
                "label_fr": "Ganserndorf"
            },
            {
                "value": 55030,
                "label": "Grossraming",
                "label_ar": "الإجمالي",
                "label_fr": "Grossraming"
            },
            {
                "value": 55443,
                "label": "Hollenthon",
                "label_ar": "هولينثون",
                "label_fr": "Hollenthon"
            },
            {
                "value": 55894,
                "label": "Alberschwende",
                "label_ar": "ألبيرشفينده",
                "label_fr": "Alberschwende"
            },
            {
                "value": 55798,
                "label": "Nochling",
                "label_ar": "نوتشلينج",
                "label_fr": "Nochling"
            },
            {
                "value": 55876,
                "label": "Eisenerz",
                "label_ar": "أيزنرز",
                "label_fr": "Eisenerz"
            },
            {
                "value": 55830,
                "label": "Tal",
                "label_ar": "تل",
                "label_fr": "Tal"
            },
            {
                "value": 54831,
                "label": "Oberaichwald",
                "label_ar": "أوبيرايشوالد",
                "label_fr": "Oberaichwald"
            },
            {
                "value": 55504,
                "label": "Schwarzenberg am Boehmerwald",
                "label_ar": "شوارزنبرج ام بوهمروالد",
                "label_fr": "Schwarzenberg am Boehmerwald"
            },
            {
                "value": 55256,
                "label": "Kappl",
                "label_ar": "كابل",
                "label_fr": "Kappl"
            },
            {
                "value": 55891,
                "label": "Dobldorf",
                "label_ar": "دوبولدورف",
                "label_fr": "Dobldorf"
            },
            {
                "value": 54577,
                "label": "Obernberg am Brenner",
                "label_ar": "اوبرنبرغ ام برينر",
                "label_fr": "Obernberg am Brenner"
            },
            {
                "value": 54545,
                "label": "Vosendorf",
                "label_ar": "فوسندورف",
                "label_fr": "Vosendorf"
            },
            {
                "value": 55326,
                "label": "Vigaun",
                "label_ar": "فيجاون",
                "label_fr": "Vigaun"
            },
            {
                "value": 54971,
                "label": "Grodig",
                "label_ar": "جروديج",
                "label_fr": "Grodig"
            },
            {
                "value": 54854,
                "label": "Obersiebenbrunn",
                "label_ar": "Obersiebenbrunn",
                "label_fr": "Obersiebenbrunn"
            },
            {
                "value": 55338,
                "label": "Kappern",
                "label_ar": "كابيرن",
                "label_fr": "Kappern"
            },
            {
                "value": 54625,
                "label": "Esternberg",
                "label_ar": "إسترنبرغ",
                "label_fr": "Esternberg"
            },
            {
                "value": 54693,
                "label": "Lech",
                "label_ar": "ليش",
                "label_fr": "Lech"
            },
            {
                "value": 54844,
                "label": "Neusiedl bei Guessing",
                "label_ar": "Neusiedl باي التخمين",
                "label_fr": "Neusiedl bei Guessing"
            },
            {
                "value": 54940,
                "label": "Achenkirch",
                "label_ar": "أشينكيرش",
                "label_fr": "Achenkirch"
            },
            {
                "value": 55446,
                "label": "Egg",
                "label_ar": "بيضة",
                "label_fr": "Oeuf"
            },
            {
                "value": 54865,
                "label": "Oberkirchbach",
                "label_ar": "أوبيركيرشباخ",
                "label_fr": "Oberkirchbach"
            },
            {
                "value": 54772,
                "label": "Scharding",
                "label_ar": "شاردينج",
                "label_fr": "Scharding"
            },
            {
                "value": 54776,
                "label": "Natternbach",
                "label_ar": "ناتيرنباخ",
                "label_fr": "Natternbach"
            },
            {
                "value": 54894,
                "label": "Wulkaprodersdorf",
                "label_ar": "Wulkaprodersdorf",
                "label_fr": "Wulkaprodersdorf"
            },
            {
                "value": 54712,
                "label": "Anif",
                "label_ar": "أنيف",
                "label_fr": "Anif"
            },
            {
                "value": 55426,
                "label": "Haldensee",
                "label_ar": "هالدينسي",
                "label_fr": "Haldensee"
            },
            {
                "value": 55226,
                "label": "Scheifling",
                "label_ar": "شيفلينج",
                "label_fr": "Scheifling"
            },
            {
                "value": 55391,
                "label": "Rothis",
                "label_ar": "روثيس",
                "label_fr": "Rothis"
            },
            {
                "value": 55108,
                "label": "Uttendorf",
                "label_ar": "أوتندورف",
                "label_fr": "Uttendorf"
            },
            {
                "value": 54796,
                "label": "Berwang",
                "label_ar": "بيروانغ",
                "label_fr": "Berwang"
            },
            {
                "value": 55872,
                "label": "Goesselsdorf",
                "label_ar": "Goesselsdorf",
                "label_fr": "Goesselsdorf"
            },
            {
                "value": 55423,
                "label": "Tannheim",
                "label_ar": "تانهايم",
                "label_fr": "Tannheim"
            },
            {
                "value": 55686,
                "label": "Windischgarsten",
                "label_ar": "Windischgarsten",
                "label_fr": "Windischgarsten"
            },
            {
                "value": 55664,
                "label": "Wimpassing an der Leitha",
                "label_ar": "Wimpassing an der Leitha",
                "label_fr": "Wimpassing an der Leitha"
            },
            {
                "value": 54531,
                "label": "Waidhofen an der Ybbs",
                "label_ar": "Waidhofen an der Ybbs",
                "label_fr": "Waidhofen an der Ybbs"
            },
            {
                "value": 55412,
                "label": "Dorfgastein",
                "label_ar": "دورفجاستين",
                "label_fr": "Dorfgastein"
            },
            {
                "value": 55627,
                "label": "Hoheneich",
                "label_ar": "Hoheneich",
                "label_fr": "Hoheneich"
            },
            {
                "value": 55451,
                "label": "Unterpremstatten",
                "label_ar": "Unterpremstatten",
                "label_fr": "Unterpremstatten"
            },
            {
                "value": 55177,
                "label": "Maria Worth",
                "label_ar": "ماريا وورث",
                "label_fr": "Maria Worth"
            },
            {
                "value": 55126,
                "label": "Seekirchen am Wallersee",
                "label_ar": "Seekirchen am Wallersee",
                "label_fr": "Seekirchen am Wallersee"
            },
            {
                "value": 55646,
                "label": "Alland",
                "label_ar": "ألاند",
                "label_fr": "Alland"
            },
            {
                "value": 55458,
                "label": "Frattingsdorf",
                "label_ar": "فراتينجسدورف",
                "label_fr": "Frattingsdorf"
            },
            {
                "value": 54936,
                "label": "Vorderlanersbach",
                "label_ar": "فورديرلانرسباخ",
                "label_fr": "Vorderlanersbach"
            },
            {
                "value": 55534,
                "label": "Eggendorf im Thale",
                "label_ar": "Eggendorf im Thale",
                "label_fr": "Eggendorf im Thale"
            },
            {
                "value": 55386,
                "label": "Lobenstein",
                "label_ar": "لوبينشتاين",
                "label_fr": "Lobenstein"
            },
            {
                "value": 54746,
                "label": "Tribuswinkel",
                "label_ar": "تريبوسوينكل",
                "label_fr": "Tribuswinkel"
            },
            {
                "value": 55723,
                "label": "Oberwaltersdorf",
                "label_ar": "Oberwaltersdorf",
                "label_fr": "Oberwaltersdorf"
            },
            {
                "value": 55861,
                "label": "Gars am Kamp",
                "label_ar": "غارس آم كامب",
                "label_fr": "Gars am Kamp"
            },
            {
                "value": 55023,
                "label": "Lochau",
                "label_ar": "لوكاو",
                "label_fr": "Lochau"
            },
            {
                "value": 54646,
                "label": "Kienberg",
                "label_ar": "كينبيرج",
                "label_fr": "Kienberg"
            },
            {
                "value": 55051,
                "label": "Pressbaum",
                "label_ar": "بريسباوم",
                "label_fr": "Pressbaum"
            },
            {
                "value": 55274,
                "label": "Weilbach",
                "label_ar": "ويلباخ",
                "label_fr": "Weilbach"
            },
            {
                "value": 55785,
                "label": "Studenzen",
                "label_ar": "ستودينزن",
                "label_fr": "Studenzen"
            },
            {
                "value": 55667,
                "label": "Biberwier",
                "label_ar": "بيبيروير",
                "label_fr": "Biberwier"
            },
            {
                "value": 55693,
                "label": "Bad Deutsch-Altenburg",
                "label_ar": "Bad Deutsch-Altenburg",
                "label_fr": "Bad Deutsch-Altenburg"
            },
            {
                "value": 55811,
                "label": "Grafendorf bei Stainz",
                "label_ar": "جرافيندورف باي ستينز",
                "label_fr": "Grafendorf bei Stainz"
            },
            {
                "value": 55221,
                "label": "Puchenau",
                "label_ar": "بوتشيناو",
                "label_fr": "Puchenau"
            },
            {
                "value": 55605,
                "label": "Ruden",
                "label_ar": "رودين",
                "label_fr": "Ruden"
            },
            {
                "value": 54845,
                "label": "Raab",
                "label_ar": "راب",
                "label_fr": "Raab"
            },
            {
                "value": 55200,
                "label": "Oberaich",
                "label_ar": "اوبرايش",
                "label_fr": "Oberaich"
            },
            {
                "value": 55515,
                "label": "Deutschkreutz",
                "label_ar": "دويتشكروتز",
                "label_fr": "Deutschkreutz"
            },
            {
                "value": 55252,
                "label": "Igls",
                "label_ar": "إيجلز",
                "label_fr": "Igls"
            },
            {
                "value": 55031,
                "label": "Thalheim bei Wels",
                "label_ar": "ثالهايم باي ويلس",
                "label_fr": "Thalheim bei Wels"
            },
            {
                "value": 55780,
                "label": "Waldkirchen am Wesen",
                "label_ar": "والدكيرشن ام ويسن",
                "label_fr": "Waldkirchen am Wesen"
            },
            {
                "value": 54551,
                "label": "Stainz",
                "label_ar": "ستينز",
                "label_fr": "Stainz"
            },
            {
                "value": 55191,
                "label": "Sankt Veit an der Glan",
                "label_ar": "سانكت فيت ان دير جلان",
                "label_fr": "Sankt Veit an der Glan"
            },
            {
                "value": 54616,
                "label": "Althofen",
                "label_ar": "آلثوفين",
                "label_fr": "Althofen"
            },
            {
                "value": 55220,
                "label": "Vorchdorf",
                "label_ar": "فورشدورف",
                "label_fr": "Vorchdorf"
            },
            {
                "value": 55587,
                "label": "Maglern",
                "label_ar": "ماجليرن",
                "label_fr": "Maglern"
            },
            {
                "value": 55767,
                "label": "Bergau",
                "label_ar": "بيرجاو",
                "label_fr": "Bergau"
            },
            {
                "value": 54600,
                "label": "Wieselburg",
                "label_ar": "ويزلبورغ",
                "label_fr": "Wieselburg"
            },
            {
                "value": 55181,
                "label": "Leitring",
                "label_ar": "ليترينج",
                "label_fr": "Leitring"
            },
            {
                "value": 55523,
                "label": "Neulengbach",
                "label_ar": "نيولينجباخ",
                "label_fr": "Neulengbach"
            },
            {
                "value": 54973,
                "label": "Moosburg",
                "label_ar": "موسبرغ",
                "label_fr": "Moosburg"
            },
            {
                "value": 55281,
                "label": "Zwentendorf",
                "label_ar": "زوينتندورف",
                "label_fr": "Zwentendorf"
            },
            {
                "value": 55524,
                "label": "Poggstall",
                "label_ar": "بوجستال",
                "label_fr": "Poggstall"
            },
            {
                "value": 55158,
                "label": "Felixdorf",
                "label_ar": "فيليكسدورف",
                "label_fr": "Felixdorf"
            },
            {
                "value": 55775,
                "label": "Schattendorf",
                "label_ar": "شاتندورف",
                "label_fr": "Schattendorf"
            },
            {
                "value": 55473,
                "label": "Neuzeug",
                "label_ar": "نويزيوغ",
                "label_fr": "Neuzeug"
            },
            {
                "value": 55640,
                "label": "Stubenberg",
                "label_ar": "ستوبنبرغ",
                "label_fr": "Stubenberg"
            },
            {
                "value": 55381,
                "label": "Watzelsdorf",
                "label_ar": "واتزلسدورف",
                "label_fr": "Watzelsdorf"
            },
            {
                "value": 54738,
                "label": "Traun",
                "label_ar": "تراون",
                "label_fr": "Traun"
            },
            {
                "value": 55786,
                "label": "Kottmannsdorf",
                "label_ar": "Kottmannsdorf",
                "label_fr": "Kottmannsdorf"
            },
            {
                "value": 55832,
                "label": "Niederkreuzstetten",
                "label_ar": "Niederkreuzstetten",
                "label_fr": "Niederkreuzstetten"
            },
            {
                "value": 54985,
                "label": "Boheimkirchen",
                "label_ar": "بوهيمكيرشن",
                "label_fr": "Boheimkirchen"
            },
            {
                "value": 55573,
                "label": "Breitenwang",
                "label_ar": "بريتينوانغ",
                "label_fr": "Breitenwang"
            },
            {
                "value": 55675,
                "label": "Stadtschlaining",
                "label_ar": "Stadtschlaining",
                "label_fr": "Stadtschlaining"
            },
            {
                "value": 54509,
                "label": "Hohenau",
                "label_ar": "هوهيناو",
                "label_fr": "Hohenau"
            },
            {
                "value": 54664,
                "label": "Hallein",
                "label_ar": "هاللين",
                "label_fr": "Hallein"
            },
            {
                "value": 54684,
                "label": "Mehrnbach",
                "label_ar": "مهرنباخ",
                "label_fr": "Mehrnbach"
            },
            {
                "value": 55008,
                "label": "Kirchschlag",
                "label_ar": "كيرششلاغ",
                "label_fr": "Kirchschlag"
            },
            {
                "value": 54505,
                "label": "Linz",
                "label_ar": "لينز",
                "label_fr": "Linz"
            },
            {
                "value": 55047,
                "label": "Himberg",
                "label_ar": "هيمبرج",
                "label_fr": "Himberg"
            },
            {
                "value": 55805,
                "label": "Gutenstein",
                "label_ar": "جوتنشتاين",
                "label_fr": "Gutenstein"
            },
            {
                "value": 54574,
                "label": "Aschau",
                "label_ar": "أشاو",
                "label_fr": "Aschau"
            },
            {
                "value": 54645,
                "label": "Grafenworth",
                "label_ar": "جرافينورث",
                "label_fr": "Grafenworth"
            },
            {
                "value": 55210,
                "label": "Berndorf",
                "label_ar": "بيرندورف",
                "label_fr": "Berndorf"
            },
            {
                "value": 54657,
                "label": "Eisenstadt",
                "label_ar": "أيزنشتات",
                "label_fr": "Eisenstadt"
            },
            {
                "value": 55881,
                "label": "Lohnsburg",
                "label_ar": "لونسبورغ",
                "label_fr": "Lohnsburg"
            },
            {
                "value": 55348,
                "label": "Innerkrems",
                "label_ar": "إينركريمس",
                "label_fr": "Innerkrems"
            },
            {
                "value": 55193,
                "label": "Raaba",
                "label_ar": "رابا",
                "label_fr": "Raaba"
            },
            {
                "value": 54508,
                "label": "Seiersberg",
                "label_ar": "سيرسبرغ",
                "label_fr": "Seiersberg"
            },
            {
                "value": 55182,
                "label": "Ligist",
                "label_ar": "ليجيست",
                "label_fr": "Ligiste"
            },
            {
                "value": 55311,
                "label": "Sankt Oswald",
                "label_ar": "سانكت أوزوالد",
                "label_fr": "Sankt Oswald"
            },
            {
                "value": 54560,
                "label": "Oberkohlstaetten",
                "label_ar": "Oberkohlstaetten",
                "label_fr": "Oberkohlstaetten"
            },
            {
                "value": 55820,
                "label": "Hart",
                "label_ar": "هارت",
                "label_fr": "Cerf"
            },
            {
                "value": 55652,
                "label": "Eckartsau",
                "label_ar": "إيكارتسو",
                "label_fr": "Eckartsau"
            },
            {
                "value": 54885,
                "label": "Kierling",
                "label_ar": "كيرلينج",
                "label_fr": "Kierling"
            },
            {
                "value": 55855,
                "label": "Kainach bei Voitsberg",
                "label_ar": "Kainach باي فويتسبرغ",
                "label_fr": "Kainach bei Voitsberg"
            },
            {
                "value": 55428,
                "label": "Oepping",
                "label_ar": "Oepping",
                "label_fr": "Oepping"
            },
            {
                "value": 55887,
                "label": "Gallzein",
                "label_ar": "جالزين",
                "label_fr": "Gallzein"
            },
            {
                "value": 54960,
                "label": "Annenheim",
                "label_ar": "أنينهايم",
                "label_fr": "Annenheim"
            },
            {
                "value": 55474,
                "label": "Perwang am Grabensee",
                "label_ar": "Perwang am Grabensee",
                "label_fr": "Perwang am Grabensee"
            },
            {
                "value": 55621,
                "label": "Nussdorf am Haunsberg",
                "label_ar": "نوسدورف ام هاونسبرج",
                "label_fr": "Nussdorf am Haunsberg"
            },
            {
                "value": 55759,
                "label": "Stumm",
                "label_ar": "ستوم",
                "label_fr": "Stumm"
            },
            {
                "value": 54835,
                "label": "Kleinkirchheim",
                "label_ar": "كلينكيرشهايم",
                "label_fr": "Kleinkirchheim"
            },
            {
                "value": 55617,
                "label": "Niederdorf",
                "label_ar": "نيدردورف",
                "label_fr": "Niederdorf"
            },
            {
                "value": 55499,
                "label": "Koestenberg",
                "label_ar": "كويستينبيرج",
                "label_fr": "Koestenberg"
            },
            {
                "value": 54881,
                "label": "Hohenems",
                "label_ar": "هوهنيمز",
                "label_fr": "Hohenems"
            },
            {
                "value": 55668,
                "label": "Gosau",
                "label_ar": "جوساو",
                "label_fr": "Gosau"
            },
            {
                "value": 55889,
                "label": "Klam",
                "label_ar": "كلام",
                "label_fr": "Klam"
            },
            {
                "value": 54688,
                "label": "Sommerein",
                "label_ar": "سومرين",
                "label_fr": "Sommerein"
            },
            {
                "value": 55097,
                "label": "Mieming",
                "label_ar": "ميمينغ",
                "label_fr": "Mieming"
            },
            {
                "value": 55354,
                "label": "Sauerbrunn",
                "label_ar": "ساويربرون",
                "label_fr": "Sauerbrunn"
            },
            {
                "value": 54673,
                "label": "Prabach",
                "label_ar": "براباخ",
                "label_fr": "Prabach"
            },
            {
                "value": 55733,
                "label": "Auersthal",
                "label_ar": "أويرستال",
                "label_fr": "Auersthal"
            },
            {
                "value": 54921,
                "label": "Pfunds",
                "label_ar": "صندوق",
                "label_fr": "Pfunds"
            },
            {
                "value": 54967,
                "label": "Plankenau",
                "label_ar": "بلانكينو",
                "label_fr": "Plankenau"
            },
            {
                "value": 55858,
                "label": "Stummerberg",
                "label_ar": "ستامربيرج",
                "label_fr": "Stummerberg"
            }
        ]
    },
    {
        "country": "United Kingdom",
        "cities": [
            {
                "value": 55892,
                "label": "Newquay",
                "label_ar": "نيوكواي",
                "label_fr": "Newquay"
            },
            {
                "value": 56336,
                "label": "Thatcham",
                "label_ar": "ثاتشام",
                "label_fr": "Thatcham"
            },
            {
                "value": 57055,
                "label": "East Molesey",
                "label_ar": "شرق موليسي",
                "label_fr": "East Molesey"
            },
            {
                "value": 56956,
                "label": "Witney",
                "label_ar": "ويتني",
                "label_fr": "Witney"
            },
            {
                "value": 56807,
                "label": "Currie",
                "label_ar": "كوري",
                "label_fr": "Currie"
            },
            {
                "value": 56563,
                "label": "Harleston",
                "label_ar": "هارلستون",
                "label_fr": "Harleston"
            },
            {
                "value": 58152,
                "label": "Minster Lovell",
                "label_ar": "مينستر لوفيل",
                "label_fr": "Minster Lovell"
            },
            {
                "value": 56879,
                "label": "Aberaeron",
                "label_ar": "ابيرايرون",
                "label_fr": "Aberaeron"
            },
            {
                "value": 57017,
                "label": "Armadale",
                "label_ar": "أرمادال",
                "label_fr": "Armadale"
            },
            {
                "value": 56029,
                "label": "Strathaven",
                "label_ar": "ستراتهافن",
                "label_fr": "Strathaven"
            },
            {
                "value": 57802,
                "label": "Westhoughton",
                "label_ar": "ويستهوفتون",
                "label_fr": "Westhoughton"
            },
            {
                "value": 55991,
                "label": "Farnham",
                "label_ar": "فارنهام",
                "label_fr": "Farnham"
            },
            {
                "value": 56285,
                "label": "Nantwich",
                "label_ar": "نانتويتش",
                "label_fr": "Nantwich"
            },
            {
                "value": 56493,
                "label": "Great Torrington",
                "label_ar": "تورينجتون العظيم",
                "label_fr": "Great Torrington"
            },
            {
                "value": 58385,
                "label": "Walgrave",
                "label_ar": "والجريف",
                "label_fr": "Walgrave"
            },
            {
                "value": 56747,
                "label": "Didcot",
                "label_ar": "ديدكوت",
                "label_fr": "Didcot"
            },
            {
                "value": 56726,
                "label": "Shotton",
                "label_ar": "شوتون",
                "label_fr": "Shotton"
            },
            {
                "value": 57308,
                "label": "Cottenham",
                "label_ar": "كوتنهام",
                "label_fr": "Cottenham"
            },
            {
                "value": 56693,
                "label": "Oakley",
                "label_ar": "اوكلي",
                "label_fr": "Oakley"
            },
            {
                "value": 56273,
                "label": "Sidmouth",
                "label_ar": "سيدماوث",
                "label_fr": "Sidmouth"
            },
            {
                "value": 57002,
                "label": "Skipton",
                "label_ar": "سكيبتون",
                "label_fr": "Skipton"
            },
            {
                "value": 58672,
                "label": "Worth",
                "label_ar": "يستحق",
                "label_fr": "Vaut"
            },
            {
                "value": 57570,
                "label": "Beckley",
                "label_ar": "بيكلي",
                "label_fr": "Beckley"
            },
            {
                "value": 55983,
                "label": "Aviemore",
                "label_ar": "أفيمور",
                "label_fr": "Aviemore"
            },
            {
                "value": 58587,
                "label": "Appleford",
                "label_ar": "أبلفورد",
                "label_fr": "Appleford"
            },
            {
                "value": 57129,
                "label": "Uppingham",
                "label_ar": "أوبينجهام",
                "label_fr": "Uppingham"
            },
            {
                "value": 56340,
                "label": "Wishaw",
                "label_ar": "يشو",
                "label_fr": "Wishaw"
            },
            {
                "value": 56940,
                "label": "Newtown",
                "label_ar": "بلدة جديدة",
                "label_fr": "Nouvelle ville"
            },
            {
                "value": 57118,
                "label": "Dungannon",
                "label_ar": "دونجانون",
                "label_fr": "Dungannon"
            },
            {
                "value": 57920,
                "label": "Bude",
                "label_ar": "بودي",
                "label_fr": "Bude"
            },
            {
                "value": 57924,
                "label": "Portree",
                "label_ar": "بورتري",
                "label_fr": "Portree"
            },
            {
                "value": 56017,
                "label": "Camberley",
                "label_ar": "كامبرلي",
                "label_fr": "Camberley"
            },
            {
                "value": 58078,
                "label": "East Peckham",
                "label_ar": "الشرق بيكهام",
                "label_fr": "East Peckham"
            },
            {
                "value": 57475,
                "label": "Chipping Campden",
                "label_ar": "تشيبينج كامبدين",
                "label_fr": "Chipping Campden"
            },
            {
                "value": 56712,
                "label": "Birchington",
                "label_ar": "بيرشينجتون",
                "label_fr": "Birchington"
            },
            {
                "value": 56202,
                "label": "Brigg",
                "label_ar": "بريج",
                "label_fr": "Brigg"
            },
            {
                "value": 58368,
                "label": "Pontycymer",
                "label_ar": "بونتيكمر",
                "label_fr": "Pontycymer"
            },
            {
                "value": 58221,
                "label": "Inverkip",
                "label_ar": "إنفركيب",
                "label_fr": "Inverkip"
            },
            {
                "value": 55981,
                "label": "Stirling",
                "label_ar": "ستيرلينغ",
                "label_fr": "Stirling"
            },
            {
                "value": 56409,
                "label": "Bebington",
                "label_ar": "بيبينجتون",
                "label_fr": "Bebington"
            },
            {
                "value": 56840,
                "label": "Tweedmouth",
                "label_ar": "تويدماوث",
                "label_fr": "Tweedmouth"
            },
            {
                "value": 56851,
                "label": "Richmond",
                "label_ar": "ريتشموند",
                "label_fr": "Richmond"
            },
            {
                "value": 58855,
                "label": "Thakeham",
                "label_ar": "Thakeham",
                "label_fr": "Thakeham"
            },
            {
                "value": 58103,
                "label": "Harrold",
                "label_ar": "هارولد",
                "label_fr": "Harrold"
            },
            {
                "value": 56638,
                "label": "Antrim",
                "label_ar": "أنتريم",
                "label_fr": "Antrim"
            },
            {
                "value": 58731,
                "label": "Ford",
                "label_ar": "معقل",
                "label_fr": "Gué"
            },
            {
                "value": 56428,
                "label": "Cheshunt",
                "label_ar": "تشيشونت",
                "label_fr": "Cheshunt"
            },
            {
                "value": 56245,
                "label": "Accrington",
                "label_ar": "أكرينجتون",
                "label_fr": "Accrington"
            },
            {
                "value": 57981,
                "label": "Trehafod",
                "label_ar": "تريهفود",
                "label_fr": "Trehafod"
            },
            {
                "value": 57583,
                "label": "Tadcaster",
                "label_ar": "تادكاستر",
                "label_fr": "Tadcaster"
            },
            {
                "value": 57352,
                "label": "Bromborough",
                "label_ar": "برومبورو",
                "label_fr": "Bromborough"
            },
            {
                "value": 58203,
                "label": "Guiseley",
                "label_ar": "جيزيلي",
                "label_fr": "Guiseley"
            },
            {
                "value": 58139,
                "label": "Kelmarsh",
                "label_ar": "كلمارش",
                "label_fr": "Kelmarsh"
            },
            {
                "value": 57065,
                "label": "Whitchurch",
                "label_ar": "ويتشرش",
                "label_fr": "Whitchurch"
            },
            {
                "value": 58714,
                "label": "Clare",
                "label_ar": "كلير",
                "label_fr": "Clare"
            },
            {
                "value": 57821,
                "label": "Thornaby",
                "label_ar": "ثورنابي",
                "label_fr": "Thornaby"
            },
            {
                "value": 57901,
                "label": "Hathersage",
                "label_ar": "هاثرسيج",
                "label_fr": "Hathersage"
            },
            {
                "value": 57614,
                "label": "South Milford",
                "label_ar": "جنوب ميلفورد",
                "label_fr": "South Milford"
            },
            {
                "value": 57408,
                "label": "Stutton",
                "label_ar": "ستاتون",
                "label_fr": "Stutton"
            },
            {
                "value": 57218,
                "label": "Winnersh",
                "label_ar": "وينرش",
                "label_fr": "Winnersh"
            },
            {
                "value": 58056,
                "label": "Saint Columb Major",
                "label_ar": "القديس كولومب الرائد",
                "label_fr": "Saint Columb Major"
            },
            {
                "value": 57539,
                "label": "Leysdown-on-Sea",
                "label_ar": "ليسداون أون سي",
                "label_fr": "Leysdown-on-Sea"
            },
            {
                "value": 56773,
                "label": "Berkeley",
                "label_ar": "بيركلي",
                "label_fr": "Berkeley"
            },
            {
                "value": 58608,
                "label": "Newhall",
                "label_ar": "نيوهول",
                "label_fr": "Newhall"
            },
            {
                "value": 57150,
                "label": "Back",
                "label_ar": "عودة",
                "label_fr": "Retour"
            },
            {
                "value": 58320,
                "label": "City of London",
                "label_ar": "مدينة لندن",
                "label_fr": "Ville de Londres"
            },
            {
                "value": 56467,
                "label": "Pinner",
                "label_ar": "بينر",
                "label_fr": "Pinner"
            },
            {
                "value": 56666,
                "label": "Carrickfergus",
                "label_ar": "كاريكفرجس",
                "label_fr": "Carrickfergus"
            },
            {
                "value": 57888,
                "label": "Canewdon",
                "label_ar": "كانيودون",
                "label_fr": "Canewdon"
            },
            {
                "value": 55861,
                "label": "Stroud",
                "label_ar": "ستراود",
                "label_fr": "Stroud"
            },
            {
                "value": 57547,
                "label": "Compton",
                "label_ar": "كومبتون",
                "label_fr": "Compton"
            },
            {
                "value": 56233,
                "label": "St Helens",
                "label_ar": "سانت هيلينز",
                "label_fr": "St Helens"
            },
            {
                "value": 58397,
                "label": "Gretna",
                "label_ar": "جريتنا",
                "label_fr": "Gretna"
            },
            {
                "value": 55928,
                "label": "Penarth",
                "label_ar": "بينارث",
                "label_fr": "Penarth"
            },
            {
                "value": 56084,
                "label": "Birstall",
                "label_ar": "بيرستال",
                "label_fr": "Birstall"
            },
            {
                "value": 56008,
                "label": "Faversham",
                "label_ar": "فافيرشام",
                "label_fr": "Faversham"
            },
            {
                "value": 57141,
                "label": "Golborne",
                "label_ar": "جولبورن",
                "label_fr": "Golborne"
            },
            {
                "value": 56268,
                "label": "Brentford",
                "label_ar": "برينتفورد",
                "label_fr": "Brentford"
            },
            {
                "value": 56778,
                "label": "Ross on Wye",
                "label_ar": "روس أون واي",
                "label_fr": "Ross on Wye"
            },
            {
                "value": 57435,
                "label": "Callander",
                "label_ar": "كالاندر",
                "label_fr": "Callander"
            },
            {
                "value": 57262,
                "label": "Steeton",
                "label_ar": "ستيتون",
                "label_fr": "Steeton"
            },
            {
                "value": 56402,
                "label": "Cranleigh",
                "label_ar": "كرانلي",
                "label_fr": "Cranleigh"
            },
            {
                "value": 56140,
                "label": "Biggleswade",
                "label_ar": "بيجلسويد",
                "label_fr": "Biggleswade"
            },
            {
                "value": 56767,
                "label": "Ormiston",
                "label_ar": "أورميستون",
                "label_fr": "Ormiston"
            },
            {
                "value": 56386,
                "label": "Southwell",
                "label_ar": "ساوثويل",
                "label_fr": "Southwell"
            },
            {
                "value": 57753,
                "label": "Stoke Poges",
                "label_ar": "ستوك بوجيس",
                "label_fr": "Stoke Poges"
            },
            {
                "value": 56367,
                "label": "Haslemere",
                "label_ar": "Haslemere",
                "label_fr": "Haslemere"
            },
            {
                "value": 56974,
                "label": "Weston",
                "label_ar": "ويستون",
                "label_fr": "Weston"
            },
            {
                "value": 56855,
                "label": "Thurso",
                "label_ar": "ثورسو",
                "label_fr": "Thurso"
            },
            {
                "value": 55887,
                "label": "Evesham",
                "label_ar": "إيفشام",
                "label_fr": "Evesham"
            },
            {
                "value": 56316,
                "label": "Brierley Hill",
                "label_ar": "بريرلي هيل",
                "label_fr": "Brierley Hill"
            },
            {
                "value": 56895,
                "label": "North Ferriby",
                "label_ar": "شمال فيريبي",
                "label_fr": "Nord Ferriby"
            },
            {
                "value": 57240,
                "label": "Swalwell",
                "label_ar": "سوالويل",
                "label_fr": "Swalwell"
            },
            {
                "value": 57713,
                "label": "Cheam",
                "label_ar": "تشيم",
                "label_fr": "Cheam"
            },
            {
                "value": 57998,
                "label": "Llanfairfechan",
                "label_ar": "Llanfairfechan",
                "label_fr": "Llanfairfechan"
            },
            {
                "value": 57587,
                "label": "North Moreton",
                "label_ar": "شمال موريتون",
                "label_fr": "North Moreton"
            },
            {
                "value": 56592,
                "label": "Lochgelly",
                "label_ar": "Lochgelly",
                "label_fr": "Lochgelly"
            },
            {
                "value": 56560,
                "label": "Prestatyn",
                "label_ar": "بريستاتين",
                "label_fr": "Prestatyn"
            },
            {
                "value": 56698,
                "label": "Londonderry",
                "label_ar": "لندنديري",
                "label_fr": "Londonderry"
            },
            {
                "value": 58197,
                "label": "Hambleton",
                "label_ar": "هامبلتون",
                "label_fr": "Hambleton"
            },
            {
                "value": 58304,
                "label": "Kennington",
                "label_ar": "كينينجتون",
                "label_fr": "Kennington"
            },
            {
                "value": 57574,
                "label": "Nordelph",
                "label_ar": "نوردلف",
                "label_fr": "Nordelph"
            },
            {
                "value": 57593,
                "label": "Mablethorpe",
                "label_ar": "مابليثورب",
                "label_fr": "Mablethorpe"
            },
            {
                "value": 58040,
                "label": "Fladbury",
                "label_ar": "فلادبيري",
                "label_fr": "Fladbury"
            },
            {
                "value": 58560,
                "label": "Clydach",
                "label_ar": "كلايدش",
                "label_fr": "Clydach"
            },
            {
                "value": 58465,
                "label": "Bignor",
                "label_ar": "بيجنور",
                "label_fr": "Bignor"
            },
            {
                "value": 56330,
                "label": "Richmond",
                "label_ar": "ريتشموند",
                "label_fr": "Richmond"
            },
            {
                "value": 56617,
                "label": "Thornaby",
                "label_ar": "ثورنابي",
                "label_fr": "Thornaby"
            },
            {
                "value": 57244,
                "label": "Crick",
                "label_ar": "كريك",
                "label_fr": "Crampe"
            },
            {
                "value": 56942,
                "label": "Rye",
                "label_ar": "الذرة",
                "label_fr": "Seigle"
            },
            {
                "value": 56230,
                "label": "Morecambe",
                "label_ar": "موركامب",
                "label_fr": "Morecambe"
            },
            {
                "value": 56184,
                "label": "Spennymoor",
                "label_ar": "سبينيمور",
                "label_fr": "Spennymoor"
            },
            {
                "value": 58261,
                "label": "Rothwell",
                "label_ar": "روثويل",
                "label_fr": "Rothwell"
            },
            {
                "value": 56839,
                "label": "Egremont",
                "label_ar": "إيغريمونت",
                "label_fr": "Egremont"
            },
            {
                "value": 58365,
                "label": "Milltimber",
                "label_ar": "ميلتيمبر",
                "label_fr": "Milltimber"
            },
            {
                "value": 57739,
                "label": "Whitchurch",
                "label_ar": "ويتشرش",
                "label_fr": "Whitchurch"
            },
            {
                "value": 58668,
                "label": "Wotton Underwood",
                "label_ar": "ووتون أندروود",
                "label_fr": "Wotton Underwood"
            },
            {
                "value": 56216,
                "label": "Holt",
                "label_ar": "هولت",
                "label_fr": "Holt"
            },
            {
                "value": 56569,
                "label": "Ascot",
                "label_ar": "أسكوت",
                "label_fr": "Ascot"
            },
            {
                "value": 57599,
                "label": "Inverurie",
                "label_ar": "إنفيريري",
                "label_fr": "Inverurie"
            },
            {
                "value": 58205,
                "label": "Frensham",
                "label_ar": "فرينشام",
                "label_fr": "Frensham"
            },
            {
                "value": 58108,
                "label": "Cranfield",
                "label_ar": "كرانفيلد",
                "label_fr": "Cranfield"
            },
            {
                "value": 57388,
                "label": "Sutton on Trent",
                "label_ar": "ساتون على ترينت",
                "label_fr": "Sutton on Trent"
            },
            {
                "value": 56987,
                "label": "Amlwch",
                "label_ar": "أملوش",
                "label_fr": "Amlwch"
            },
            {
                "value": 58632,
                "label": "Crosshouse",
                "label_ar": "كروس هاوس",
                "label_fr": "Crosshouse"
            },
            {
                "value": 58094,
                "label": "Bow",
                "label_ar": "ينحني",
                "label_fr": "Arc"
            },
            {
                "value": 57444,
                "label": "Whitwick",
                "label_ar": "ويتويك",
                "label_fr": "Whitwick"
            },
            {
                "value": 56553,
                "label": "Axminster",
                "label_ar": "أكسمينستر",
                "label_fr": "Axminster"
            },
            {
                "value": 56878,
                "label": "Fakenham",
                "label_ar": "فكينهام",
                "label_fr": "Fakenham"
            },
            {
                "value": 56002,
                "label": "Chichester",
                "label_ar": "شيشستر",
                "label_fr": "Chichester"
            },
            {
                "value": 56053,
                "label": "Sevenoaks",
                "label_ar": "سبعة السنديان",
                "label_fr": "Sevenoaks"
            },
            {
                "value": 56305,
                "label": "Wem",
                "label_ar": "ويم",
                "label_fr": "Wem"
            },
            {
                "value": 57822,
                "label": "Ash Vale",
                "label_ar": "الرماد فال",
                "label_fr": "Ash Vale"
            },
            {
                "value": 56853,
                "label": "Blairgowrie",
                "label_ar": "بليرجوري",
                "label_fr": "Blairgowrie"
            },
            {
                "value": 56526,
                "label": "West Wittering",
                "label_ar": "الغرب Wittering",
                "label_fr": "West Wittering"
            },
            {
                "value": 57510,
                "label": "Tollerton",
                "label_ar": "تولرتون",
                "label_fr": "Tollerton"
            },
            {
                "value": 58685,
                "label": "Newbridge",
                "label_ar": "جسر جديد",
                "label_fr": "Nouveau pont"
            },
            {
                "value": 56077,
                "label": "Keighley",
                "label_ar": "كيلي",
                "label_fr": "Keighley"
            },
            {
                "value": 58277,
                "label": "Llwynypia",
                "label_ar": "Llwynypia",
                "label_fr": "Llwynypia"
            },
            {
                "value": 57008,
                "label": "Wrangaton",
                "label_ar": "رانجاتون",
                "label_fr": "Wrangaton"
            },
            {
                "value": 56827,
                "label": "Frinton-on-Sea",
                "label_ar": "فرينتون أون سي",
                "label_fr": "Frinton-on-Sea"
            },
            {
                "value": 56959,
                "label": "Holt",
                "label_ar": "هولت",
                "label_fr": "Holt"
            },
            {
                "value": 56691,
                "label": "Newmarket",
                "label_ar": "سوق جديد",
                "label_fr": "Nouveau marché"
            },
            {
                "value": 56732,
                "label": "Heathfield",
                "label_ar": "هيثفيلد",
                "label_fr": "Heathfield"
            },
            {
                "value": 57106,
                "label": "Bridge",
                "label_ar": "جسر",
                "label_fr": "Pont"
            },
            {
                "value": 58235,
                "label": "Morriston",
                "label_ar": "موريستون",
                "label_fr": "Morriston"
            },
            {
                "value": 56876,
                "label": "Llandeilo",
                "label_ar": "لانديلو",
                "label_fr": "Llandeilo"
            },
            {
                "value": 57328,
                "label": "Elstree",
                "label_ar": "إلستري",
                "label_fr": "Elstree"
            },
            {
                "value": 57438,
                "label": "Great Chesterford",
                "label_ar": "جريت تشيسترفورد",
                "label_fr": "Great Chesterford"
            },
            {
                "value": 58268,
                "label": "Hever",
                "label_ar": "حيفر",
                "label_fr": "Hever"
            },
            {
                "value": 56777,
                "label": "Leominster",
                "label_ar": "ليومينستر",
                "label_fr": "Léominster"
            },
            {
                "value": 57932,
                "label": "Arrochar",
                "label_ar": "أروشار",
                "label_fr": "Arrochar"
            },
            {
                "value": 56247,
                "label": "Ormskirk",
                "label_ar": "Ormskirk",
                "label_fr": "Ormskirk"
            },
            {
                "value": 56239,
                "label": "Clitheroe",
                "label_ar": "كليثروي",
                "label_fr": "Clitheroe"
            },
            {
                "value": 56640,
                "label": "Tranent",
                "label_ar": "ترانينت",
                "label_fr": "Tranent"
            },
            {
                "value": 58894,
                "label": "Childwall",
                "label_ar": "تشايلدوال",
                "label_fr": "Childwall"
            },
            {
                "value": 56226,
                "label": "Cambridge",
                "label_ar": "كامبريدج",
                "label_fr": "Cambridge"
            },
            {
                "value": 56281,
                "label": "Telford",
                "label_ar": "تيلفورد",
                "label_fr": "Telford"
            },
            {
                "value": 57278,
                "label": "Ivybridge",
                "label_ar": "ايفيبريدج",
                "label_fr": "Ivybridge"
            },
            {
                "value": 57030,
                "label": "Ballyclare",
                "label_ar": "باليكلار",
                "label_fr": "Ballyclare"
            },
            {
                "value": 56703,
                "label": "Bishopstoke",
                "label_ar": "بيشوبستوك",
                "label_fr": "Bishopstoke"
            },
            {
                "value": 56964,
                "label": "Craven Arms",
                "label_ar": "كرافن الأسلحة",
                "label_fr": "Armes folles"
            },
            {
                "value": 57606,
                "label": "Stonehaven",
                "label_ar": "ستونهافن",
                "label_fr": "Stonehaven"
            },
            {
                "value": 56887,
                "label": "Treforest",
                "label_ar": "تريفورست",
                "label_fr": "Treforest"
            },
            {
                "value": 58790,
                "label": "Cadbury",
                "label_ar": "كادبوري",
                "label_fr": "Cadbury"
            },
            {
                "value": 56748,
                "label": "Westgate on Sea",
                "label_ar": "Westgate على البحر",
                "label_fr": "Westgate on Sea"
            },
            {
                "value": 58101,
                "label": "Akeley",
                "label_ar": "أكيلي",
                "label_fr": "Akeley"
            },
            {
                "value": 58642,
                "label": "Stisted",
                "label_ar": "ثابت",
                "label_fr": "Stisted"
            },
            {
                "value": 56681,
                "label": "Shipston on Stour",
                "label_ar": "شيبستون في ستور",
                "label_fr": "Shipston sur Stour"
            },
            {
                "value": 58449,
                "label": "Nantyglo",
                "label_ar": "نانتيجلو",
                "label_fr": "Nantyglo"
            },
            {
                "value": 56038,
                "label": "Hastings",
                "label_ar": "هاستنجز",
                "label_fr": "Hastings"
            },
            {
                "value": 56657,
                "label": "Prescot",
                "label_ar": "بريسكوت",
                "label_fr": "Prescot"
            },
            {
                "value": 58618,
                "label": "West Wickham",
                "label_ar": "ويست ويكهام",
                "label_fr": "West Wickham"
            },
            {
                "value": 58781,
                "label": "Plymstock",
                "label_ar": "بليمستوك",
                "label_fr": "Plymstock"
            },
            {
                "value": 58335,
                "label": "Bermondsey",
                "label_ar": "برموندسي",
                "label_fr": "Bermondsey"
            },
            {
                "value": 58241,
                "label": "Alveston",
                "label_ar": "الفستون",
                "label_fr": "Alveston"
            },
            {
                "value": 58635,
                "label": "Bovey Tracey",
                "label_ar": "بوفي تريسي",
                "label_fr": "Bovey Tracey"
            },
            {
                "value": 55985,
                "label": "Kinross",
                "label_ar": "كينروس",
                "label_fr": "Kinross"
            },
            {
                "value": 55918,
                "label": "Caerphilly",
                "label_ar": "كيرفيلي",
                "label_fr": "Caerphilly"
            },
            {
                "value": 58019,
                "label": "Stow",
                "label_ar": "خبأ",
                "label_fr": "Ranger"
            },
            {
                "value": 57339,
                "label": "Twyford",
                "label_ar": "تويفورد",
                "label_fr": "Twyford"
            },
            {
                "value": 58734,
                "label": "Ball",
                "label_ar": "الكرة",
                "label_fr": "Balle"
            },
            {
                "value": 56253,
                "label": "Alfreton",
                "label_ar": "ألفريتون",
                "label_fr": "Alfreton"
            },
            {
                "value": 56033,
                "label": "Belfast",
                "label_ar": "بلفاست",
                "label_fr": "Belfast"
            },
            {
                "value": 56234,
                "label": "Preston",
                "label_ar": "بريستون",
                "label_fr": "Preston"
            },
            {
                "value": 56101,
                "label": "Maldon",
                "label_ar": "مالدون",
                "label_fr": "Maldon"
            },
            {
                "value": 56842,
                "label": "Rothbury",
                "label_ar": "روثبيري",
                "label_fr": "Rothbury"
            },
            {
                "value": 57841,
                "label": "Greenisland",
                "label_ar": "جزيرة خضراء",
                "label_fr": "Île verte"
            },
            {
                "value": 58106,
                "label": "Loddington",
                "label_ar": "لودنجتون",
                "label_fr": "Loddington"
            },
            {
                "value": 58386,
                "label": "Berwick",
                "label_ar": "بيرويك",
                "label_fr": "Berwick"
            },
            {
                "value": 58700,
                "label": "North Newton",
                "label_ar": "شمال نيوتن",
                "label_fr": "North Newton"
            },
            {
                "value": 58002,
                "label": "Rock Ferry",
                "label_ar": "روك فيري",
                "label_fr": "Rock Ferry"
            },
            {
                "value": 56930,
                "label": "Much Wenlock",
                "label_ar": "الكثير وينلوك",
                "label_fr": "Beaucoup de Wenlock"
            },
            {
                "value": 55979,
                "label": "Troon",
                "label_ar": "ترون",
                "label_fr": "Troon"
            },
            {
                "value": 56531,
                "label": "Farnborough",
                "label_ar": "فارنبورو",
                "label_fr": "Farnborough"
            },
            {
                "value": 56544,
                "label": "Wednesbury",
                "label_ar": "وينزبيري",
                "label_fr": "Wednesdaybury"
            },
            {
                "value": 56908,
                "label": "Fauldhouse",
                "label_ar": "فولدهاوس",
                "label_fr": "Fauldhouse"
            },
            {
                "value": 56802,
                "label": "Molesey",
                "label_ar": "موليسي",
                "label_fr": "Molesey"
            },
            {
                "value": 56612,
                "label": "Bingley",
                "label_ar": "بينجلي",
                "label_fr": "Bingley"
            },
            {
                "value": 57096,
                "label": "Iwerne Courtney",
                "label_ar": "ايورن كورتني",
                "label_fr": "Iwerne Courtney"
            },
            {
                "value": 57465,
                "label": "Weston under Lizard",
                "label_ar": "ويستون تحت Lizard",
                "label_fr": "Weston sous le lézard"
            },
            {
                "value": 57694,
                "label": "Thornbury",
                "label_ar": "ثورنبيري",
                "label_fr": "Thornbury"
            },
            {
                "value": 56603,
                "label": "Ramsgate",
                "label_ar": "رامسجيت",
                "label_fr": "Ramsgate"
            },
            {
                "value": 57869,
                "label": "Dunkeld",
                "label_ar": "دنكيلد",
                "label_fr": "Dunkeld"
            },
            {
                "value": 58093,
                "label": "Mickleton",
                "label_ar": "ميكلتون",
                "label_fr": "Mickleton"
            },
            {
                "value": 58367,
                "label": "Oldbury",
                "label_ar": "أولدبيري",
                "label_fr": "Oldbury"
            },
            {
                "value": 57664,
                "label": "Ormesby",
                "label_ar": "أورمسبي",
                "label_fr": "Ormesby"
            },
            {
                "value": 57624,
                "label": "Meifod",
                "label_ar": "Meifod",
                "label_fr": "Meifod"
            },
            {
                "value": 56906,
                "label": "Settle",
                "label_ar": "تسوية",
                "label_fr": "Régler"
            },
            {
                "value": 58509,
                "label": "Woolacombe",
                "label_ar": "وولاكومب",
                "label_fr": "Woolacombe"
            },
            {
                "value": 58262,
                "label": "Builth Wells",
                "label_ar": "بويلث ويلز",
                "label_fr": "Builth Wells"
            },
            {
                "value": 55870,
                "label": "Bidford-on-Avon",
                "label_ar": "بيدفورد أون آفون",
                "label_fr": "Bidford-sur-Avon"
            },
            {
                "value": 58664,
                "label": "Jordanstown",
                "label_ar": "جوردانستاون",
                "label_fr": "Jordanstown"
            },
            {
                "value": 56883,
                "label": "Wigton",
                "label_ar": "ويغتون",
                "label_fr": "Wigton"
            },
            {
                "value": 55900,
                "label": "Redruth",
                "label_ar": "ريدروث",
                "label_fr": "Redruth"
            },
            {
                "value": 56483,
                "label": "Wallington",
                "label_ar": "والينغتون",
                "label_fr": "Wallington"
            },
            {
                "value": 57842,
                "label": "Carlton",
                "label_ar": "كارلتون",
                "label_fr": "Carlton"
            },
            {
                "value": 56687,
                "label": "Haslingden",
                "label_ar": "هاسلينجدن",
                "label_fr": "Haslingden"
            },
            {
                "value": 57097,
                "label": "Tollard Royal",
                "label_ar": "تولارد رويال",
                "label_fr": "Tollard Royal"
            },
            {
                "value": 56636,
                "label": "Walthamstow",
                "label_ar": "والثامستو",
                "label_fr": "Walthamstow"
            },
            {
                "value": 55859,
                "label": "Pershore",
                "label_ar": "بيرشور",
                "label_fr": "Pershore"
            },
            {
                "value": 56677,
                "label": "Cumnock",
                "label_ar": "كامنوك",
                "label_fr": "Cumnock"
            },
            {
                "value": 57411,
                "label": "Fishburn",
                "label_ar": "فيشبورن",
                "label_fr": "Fishburn"
            },
            {
                "value": 57863,
                "label": "Glenshee",
                "label_ar": "جلينشي",
                "label_fr": "Glenshee"
            },
            {
                "value": 56637,
                "label": "Stuartfield",
                "label_ar": "ستيوارتفيلد",
                "label_fr": "Stuartfield"
            },
            {
                "value": 56830,
                "label": "Nelson",
                "label_ar": "نيلسون",
                "label_fr": "Nelson"
            },
            {
                "value": 58561,
                "label": "Wheatley",
                "label_ar": "ويتلي",
                "label_fr": "Wheatley"
            },
            {
                "value": 57952,
                "label": "Whittlebury",
                "label_ar": "ويتلبري",
                "label_fr": "Whittlebury"
            },
            {
                "value": 58415,
                "label": "Draperstown",
                "label_ar": "درابرستاون",
                "label_fr": "Draperstown"
            },
            {
                "value": 58788,
                "label": "Otley",
                "label_ar": "أوتلي",
                "label_fr": "Otley"
            },
            {
                "value": 56552,
                "label": "Queensferry",
                "label_ar": "كوينزفيري",
                "label_fr": "Queensferry"
            },
            {
                "value": 55952,
                "label": "Atherstone",
                "label_ar": "أثيرستون",
                "label_fr": "Atherstone"
            },
            {
                "value": 57351,
                "label": "Newbiggin-by-the-Sea",
                "label_ar": "Newbiggin-by-the-Sea",
                "label_fr": "Newbiggin-by-the-Sea"
            },
            {
                "value": 58287,
                "label": "Englefield Green",
                "label_ar": "إنجلفيلد جرين",
                "label_fr": "Englefield Green"
            },
            {
                "value": 56495,
                "label": "Redhill",
                "label_ar": "التل الأحمر",
                "label_fr": "Colline rouge"
            },
            {
                "value": 57092,
                "label": "Wick",
                "label_ar": "ذبالة",
                "label_fr": "Mèche"
            },
            {
                "value": 56113,
                "label": "Croydon",
                "label_ar": "كرويدون",
                "label_fr": "Croydon"
            },
            {
                "value": 56587,
                "label": "Beaconsfield",
                "label_ar": "بيكونزفيلد",
                "label_fr": "Beaconsfield"
            },
            {
                "value": 57580,
                "label": "Oakamoor",
                "label_ar": "اوكامور",
                "label_fr": "Oakamoor"
            },
            {
                "value": 57591,
                "label": "Burstwick",
                "label_ar": "برستويك",
                "label_fr": "Burstwick"
            },
            {
                "value": 57463,
                "label": "Ventnor",
                "label_ar": "فينتنور",
                "label_fr": "Ventnor"
            },
            {
                "value": 57748,
                "label": "Horley",
                "label_ar": "هورلي",
                "label_fr": "Horley"
            },
            {
                "value": 57226,
                "label": "Aveley",
                "label_ar": "افيلي",
                "label_fr": "Aveley"
            },
            {
                "value": 57356,
                "label": "Windsor",
                "label_ar": "وندسور",
                "label_fr": "Windsor"
            },
            {
                "value": 56902,
                "label": "Carlton",
                "label_ar": "كارلتون",
                "label_fr": "Carlton"
            },
            {
                "value": 57266,
                "label": "Twyford",
                "label_ar": "تويفورد",
                "label_fr": "Twyford"
            },
            {
                "value": 57191,
                "label": "Wilden",
                "label_ar": "وايلدن",
                "label_fr": "Wilden"
            },
            {
                "value": 58190,
                "label": "Faulkland",
                "label_ar": "فولكلاند",
                "label_fr": "Faulkland"
            },
            {
                "value": 56472,
                "label": "Dagenham",
                "label_ar": "داجينهام",
                "label_fr": "Dagenham"
            },
            {
                "value": 57755,
                "label": "Eye",
                "label_ar": "عين",
                "label_fr": "Œil"
            },
            {
                "value": 55946,
                "label": "Heanor",
                "label_ar": "هينور",
                "label_fr": "Heanor"
            },
            {
                "value": 56622,
                "label": "Cwmbran",
                "label_ar": "كومبران",
                "label_fr": "Cwmbran"
            },
            {
                "value": 57060,
                "label": "Kilmacolm",
                "label_ar": "كيلماكولم",
                "label_fr": "Kilmacolm"
            },
            {
                "value": 56753,
                "label": "Grendon",
                "label_ar": "جريندون",
                "label_fr": "Grendon"
            },
            {
                "value": 56822,
                "label": "Syston",
                "label_ar": "سيستون",
                "label_fr": "Syston"
            },
            {
                "value": 58528,
                "label": "Lesmahagow",
                "label_ar": "ليسماهاغو",
                "label_fr": "Lesmahagow"
            },
            {
                "value": 56529,
                "label": "Drayton Saint Leonard",
                "label_ar": "درايتون سانت ليونارد",
                "label_fr": "Drayton Saint Léonard"
            },
            {
                "value": 57185,
                "label": "Clifton",
                "label_ar": "كليفتون",
                "label_fr": "Clifton"
            },
            {
                "value": 56311,
                "label": "Droitwich",
                "label_ar": "درويتويتش",
                "label_fr": "Droitwich"
            },
            {
                "value": 56555,
                "label": "Yeovil",
                "label_ar": "يوفيل",
                "label_fr": "Yeovil"
            },
            {
                "value": 55976,
                "label": "Clydebank",
                "label_ar": "كلايدبانك",
                "label_fr": "Clydebank"
            },
            {
                "value": 58604,
                "label": "Smalley",
                "label_ar": "سمالي",
                "label_fr": "Smalley"
            },
            {
                "value": 57145,
                "label": "Llantrisant",
                "label_ar": "لانتريسانت",
                "label_fr": "Llantrisant"
            },
            {
                "value": 56063,
                "label": "Abbots Langley",
                "label_ar": "أبوتس لانجلي",
                "label_fr": "Abbés Langley"
            },
            {
                "value": 58353,
                "label": "Preesall",
                "label_ar": "Preesall",
                "label_fr": "Preesall"
            },
            {
                "value": 56169,
                "label": "Darlington",
                "label_ar": "دارلينجتون",
                "label_fr": "Darlington"
            },
            {
                "value": 57119,
                "label": "Armagh",
                "label_ar": "ارماغ",
                "label_fr": "Armagh"
            },
            {
                "value": 55998,
                "label": "Bexhill",
                "label_ar": "بيكسهيل",
                "label_fr": "Bexhill"
            },
            {
                "value": 58732,
                "label": "Moss",
                "label_ar": "طحلب",
                "label_fr": "Mousse"
            },
            {
                "value": 58886,
                "label": "Westfield",
                "label_ar": "ويستفيلد",
                "label_fr": "Westfield"
            },
            {
                "value": 57910,
                "label": "Llanrwst",
                "label_ar": "لانروست",
                "label_fr": "Llanrwst"
            },
            {
                "value": 58341,
                "label": "Alvechurch",
                "label_ar": "ألفشرش",
                "label_fr": "Alvechurch"
            },
            {
                "value": 57180,
                "label": "Minchinhampton",
                "label_ar": "مينشينهامبتون",
                "label_fr": "Minchinhampton"
            },
            {
                "value": 56793,
                "label": "Bedworth",
                "label_ar": "بيدورث",
                "label_fr": "Bedworth"
            },
            {
                "value": 58178,
                "label": "Butterton",
                "label_ar": "باترتون",
                "label_fr": "Butterton"
            },
            {
                "value": 57292,
                "label": "Little Hulton",
                "label_ar": "ليتل هولتون",
                "label_fr": "Petit Hulton"
            },
            {
                "value": 56223,
                "label": "Lincoln",
                "label_ar": "لينكولن",
                "label_fr": "Lincoln"
            },
            {
                "value": 55915,
                "label": "Swansea",
                "label_ar": "سوانزي",
                "label_fr": "Swansea"
            },
            {
                "value": 56067,
                "label": "Hatfield",
                "label_ar": "هاتفيلد",
                "label_fr": "Hatfield"
            },
            {
                "value": 57149,
                "label": "Wigston",
                "label_ar": "ويجستون",
                "label_fr": "Wigston"
            },
            {
                "value": 57188,
                "label": "Watlington",
                "label_ar": "واتلينغتون",
                "label_fr": "Watlington"
            },
            {
                "value": 56809,
                "label": "Banbridge",
                "label_ar": "بانبريدج",
                "label_fr": "Banbridge"
            },
            {
                "value": 58735,
                "label": "Hartpury",
                "label_ar": "هارتبوري",
                "label_fr": "Hartpury"
            },
            {
                "value": 58135,
                "label": "Lustleigh",
                "label_ar": "لوستليغ",
                "label_fr": "Lustleigh"
            },
            {
                "value": 56520,
                "label": "Tavistock",
                "label_ar": "تافيستوك",
                "label_fr": "Tavistock"
            },
            {
                "value": 58195,
                "label": "Grasmere",
                "label_ar": "جراسمير",
                "label_fr": "Grasmere"
            },
            {
                "value": 58198,
                "label": "Friskney",
                "label_ar": "فريسكني",
                "label_fr": "Friskney"
            },
            {
                "value": 57420,
                "label": "Clackmannan",
                "label_ar": "كلاكمانان",
                "label_fr": "Clackmannan"
            },
            {
                "value": 56346,
                "label": "Forfar",
                "label_ar": "هذه الهدية نالت إعجاب الجميع",
                "label_fr": "Pour beaucoup"
            },
            {
                "value": 56629,
                "label": "Crowthorne",
                "label_ar": "كروثورن",
                "label_fr": "Crowthorne"
            },
            {
                "value": 57200,
                "label": "Caddington",
                "label_ar": "كادنجتون",
                "label_fr": "Caddington"
            },
            {
                "value": 58538,
                "label": "Rowde",
                "label_ar": "رودي",
                "label_fr": "Rowde"
            },
            {
                "value": 58550,
                "label": "Shilton",
                "label_ar": "شيلتون",
                "label_fr": "Shilton"
            },
            {
                "value": 58631,
                "label": "Rottingdean",
                "label_ar": "روتنجدين",
                "label_fr": "Rottingdean"
            },
            {
                "value": 56749,
                "label": "Blackwood",
                "label_ar": "خشب أسود",
                "label_fr": "Bois noir"
            },
            {
                "value": 57565,
                "label": "Insch",
                "label_ar": "إنش",
                "label_fr": "Insch"
            },
            {
                "value": 57785,
                "label": "Painswick",
                "label_ar": "بينسويك",
                "label_fr": "Painswick"
            },
            {
                "value": 57256,
                "label": "Corwen",
                "label_ar": "كوروين",
                "label_fr": "Corwen"
            },
            {
                "value": 57744,
                "label": "Chryston",
                "label_ar": "كريستون",
                "label_fr": "Chryston"
            },
            {
                "value": 56117,
                "label": "Sutton",
                "label_ar": "ساتون",
                "label_fr": "Sutton"
            },
            {
                "value": 57232,
                "label": "Sunninghill",
                "label_ar": "Sunninghill",
                "label_fr": "Sunninghill"
            },
            {
                "value": 56588,
                "label": "Windsor",
                "label_ar": "وندسور",
                "label_fr": "Windsor"
            },
            {
                "value": 57021,
                "label": "Otley",
                "label_ar": "أوتلي",
                "label_fr": "Otley"
            },
            {
                "value": 56780,
                "label": "Broadstairs",
                "label_ar": "برودستيرز",
                "label_fr": "Broadstairs"
            },
            {
                "value": 57548,
                "label": "Crumlin",
                "label_ar": "كروملين",
                "label_fr": "Crumlin"
            },
            {
                "value": 56970,
                "label": "Malton",
                "label_ar": "مالتون",
                "label_fr": "Malton"
            },
            {
                "value": 58637,
                "label": "Ryton",
                "label_ar": "رايتون",
                "label_fr": "Ryton"
            },
            {
                "value": 58804,
                "label": "Bettws",
                "label_ar": "بتوس",
                "label_fr": "Bettws"
            },
            {
                "value": 56021,
                "label": "Aberdeen",
                "label_ar": "أبردين",
                "label_fr": "Aberdeen"
            },
            {
                "value": 56244,
                "label": "Birkenhead",
                "label_ar": "بيركينهيد",
                "label_fr": "Birkenhead"
            },
            {
                "value": 57135,
                "label": "Ripley",
                "label_ar": "ريبلي",
                "label_fr": "Ripley"
            },
            {
                "value": 57164,
                "label": "Harefield",
                "label_ar": "هريفيلد",
                "label_fr": "Harefield"
            },
            {
                "value": 56413,
                "label": "Saltash",
                "label_ar": "سلتاش",
                "label_fr": "Saltash"
            },
            {
                "value": 56094,
                "label": "Ilford",
                "label_ar": "إلفورد",
                "label_fr": "Ilford"
            },
            {
                "value": 58035,
                "label": "Ruabon",
                "label_ar": "روابون",
                "label_fr": "Ruabon"
            },
            {
                "value": 57779,
                "label": "Pencaitland",
                "label_ar": "بينكايتلاند",
                "label_fr": "Pencaitland"
            },
            {
                "value": 56905,
                "label": "Thornton-in-Craven",
                "label_ar": "ثورنتون إن كرافن",
                "label_fr": "Thornton-en-Craven"
            },
            {
                "value": 58834,
                "label": "Bainton",
                "label_ar": "بينتون",
                "label_fr": "Bainton"
            },
            {
                "value": 58351,
                "label": "Llangennech",
                "label_ar": "لانجينيتش",
                "label_fr": "Llangennech"
            },
            {
                "value": 58793,
                "label": "Hilderstone",
                "label_ar": "هيلديرستون",
                "label_fr": "Hilderstone"
            },
            {
                "value": 57766,
                "label": "Wick",
                "label_ar": "ذبالة",
                "label_fr": "Mèche"
            },
            {
                "value": 58690,
                "label": "Mickleton",
                "label_ar": "ميكلتون",
                "label_fr": "Mickleton"
            },
            {
                "value": 57814,
                "label": "Sandiacre",
                "label_ar": "ساندياكري",
                "label_fr": "Sandiacre"
            },
            {
                "value": 55949,
                "label": "Bourne",
                "label_ar": "بورن",
                "label_fr": "Bourne"
            },
            {
                "value": 57573,
                "label": "Banff",
                "label_ar": "بانف",
                "label_fr": "Banff"
            },
            {
                "value": 56939,
                "label": "Pembroke Dock",
                "label_ar": "بيمبروك دوك",
                "label_fr": "Quai de Pembroke"
            },
            {
                "value": 58525,
                "label": "Langho",
                "label_ar": "لانغو",
                "label_fr": "Langho"
            },
            {
                "value": 56222,
                "label": "Wrawby",
                "label_ar": "راوبي",
                "label_fr": "Wrawby"
            },
            {
                "value": 55916,
                "label": "Cardiff",
                "label_ar": "كارديف",
                "label_fr": "Cardiff"
            },
            {
                "value": 56024,
                "label": "Alloa",
                "label_ar": "اللوا",
                "label_fr": "Alloa"
            },
            {
                "value": 57851,
                "label": "Newcastle",
                "label_ar": "نيوكاسل",
                "label_fr": "Newcastle"
            },
            {
                "value": 57167,
                "label": "Kilkeel",
                "label_ar": "كيلكيل",
                "label_fr": "Kilkeel"
            },
            {
                "value": 58402,
                "label": "Caversham",
                "label_ar": "كافيرشام",
                "label_fr": "Caversham"
            },
            {
                "value": 57736,
                "label": "Burnopfield",
                "label_ar": "بورنوبفيلد",
                "label_fr": "Burnopfield"
            },
            {
                "value": 57780,
                "label": "Lochmaddy",
                "label_ar": "Lochmaddy",
                "label_fr": "Lochmaddy"
            },
            {
                "value": 58114,
                "label": "Ballingry",
                "label_ar": "بالينجري",
                "label_fr": "Ballingry"
            },
            {
                "value": 57581,
                "label": "Beckingham",
                "label_ar": "بيكنغهام",
                "label_fr": "Beckingham"
            },
            {
                "value": 56872,
                "label": "Shepton Mallet",
                "label_ar": "شيبتون ماليت",
                "label_fr": "Maillet Shepton"
            },
            {
                "value": 56341,
                "label": "Alexandria",
                "label_ar": "الإسكندرية",
                "label_fr": "Alexandrie"
            },
            {
                "value": 57892,
                "label": "Norton",
                "label_ar": "نورتون",
                "label_fr": "Norton"
            },
            {
                "value": 57815,
                "label": "Marske",
                "label_ar": "مارسكي",
                "label_fr": "Marske"
            },
            {
                "value": 58536,
                "label": "Tempo",
                "label_ar": "سرعة",
                "label_fr": "Tempo"
            },
            {
                "value": 58490,
                "label": "Woore",
                "label_ar": "ووري",
                "label_fr": "Woore"
            },
            {
                "value": 58455,
                "label": "Maida Vale",
                "label_ar": "مايدا فالي",
                "label_fr": "Maida Vale"
            },
            {
                "value": 56720,
                "label": "Melton Mowbray",
                "label_ar": "ميلتون كوبرى",
                "label_fr": "Melton Mowbray"
            },
            {
                "value": 56104,
                "label": "Colchester",
                "label_ar": "كولشيستر",
                "label_fr": "Colchester"
            },
            {
                "value": 56429,
                "label": "Tring",
                "label_ar": "ترينج",
                "label_fr": "Tring"
            },
            {
                "value": 57323,
                "label": "Stornoway",
                "label_ar": "ستورنوواي",
                "label_fr": "Stornoway"
            },
            {
                "value": 56251,
                "label": "Wrexham",
                "label_ar": "ريكسهام",
                "label_fr": "Wrexham"
            },
            {
                "value": 56150,
                "label": "Chester",
                "label_ar": "تشيستر",
                "label_fr": "Chester"
            },
            {
                "value": 58890,
                "label": "Winton",
                "label_ar": "وينتون",
                "label_fr": "Winton"
            },
            {
                "value": 58471,
                "label": "St. Dogmaels",
                "label_ar": "سانت دوجمايلز",
                "label_fr": "Saint Dogmaels"
            },
            {
                "value": 56353,
                "label": "Aylesford",
                "label_ar": "ايلسفورد",
                "label_fr": "Aylesford"
            },
            {
                "value": 56631,
                "label": "Moreton",
                "label_ar": "موريتون",
                "label_fr": "Moreton"
            },
            {
                "value": 58598,
                "label": "Seacroft",
                "label_ar": "سيكروفت",
                "label_fr": "Seacroft"
            },
            {
                "value": 56815,
                "label": "Newport Pagnell",
                "label_ar": "نيوبورت باجنيل",
                "label_fr": "Newport Pagnell"
            },
            {
                "value": 57681,
                "label": "Droylsden",
                "label_ar": "درويلسدن",
                "label_fr": "Droylsden"
            },
            {
                "value": 56290,
                "label": "Broseley",
                "label_ar": "بروسلي",
                "label_fr": "Broseley"
            },
            {
                "value": 57496,
                "label": "Limekilns",
                "label_ar": "ليمكيلنس",
                "label_fr": "Limekilns"
            },
            {
                "value": 58863,
                "label": "Bransford",
                "label_ar": "برانسفورد",
                "label_fr": "Bransford"
            },
            {
                "value": 56702,
                "label": "Drayton Bassett",
                "label_ar": "درايتون باسيت",
                "label_fr": "Drayton Bassett"
            },
            {
                "value": 56115,
                "label": "Surbiton",
                "label_ar": "سوربيتون",
                "label_fr": "Surbiton"
            },
            {
                "value": 57467,
                "label": "Kinnerley",
                "label_ar": "كينيرلي",
                "label_fr": "Kinnerley"
            },
            {
                "value": 57985,
                "label": "Little Canfield",
                "label_ar": "ليتل كانفيلد",
                "label_fr": "Little Canfield"
            },
            {
                "value": 56856,
                "label": "Kinghorn",
                "label_ar": "كينغهورن",
                "label_fr": "Kinghorn"
            },
            {
                "value": 56147,
                "label": "Upton",
                "label_ar": "ابتون",
                "label_fr": "Upton"
            },
            {
                "value": 57028,
                "label": "Peebles",
                "label_ar": "بيبلز",
                "label_fr": "Peebles"
            },
            {
                "value": 58029,
                "label": "Amesbury",
                "label_ar": "أميسبوري",
                "label_fr": "Amesbury"
            },
            {
                "value": 56850,
                "label": "Gillingham",
                "label_ar": "جيلينجهام",
                "label_fr": "Gillingham"
            },
            {
                "value": 57103,
                "label": "Kelbrook",
                "label_ar": "كيلبروك",
                "label_fr": "Kelbrook"
            },
            {
                "value": 57569,
                "label": "Llanbedr",
                "label_ar": "يانبدر",
                "label_fr": "Llanbedr"
            },
            {
                "value": 57024,
                "label": "East Boldon",
                "label_ar": "شرق بولدون",
                "label_fr": "East Boldon"
            },
            {
                "value": 57685,
                "label": "Harrow Weald",
                "label_ar": "هارو ويلد",
                "label_fr": "Harrow Weald"
            },
            {
                "value": 56418,
                "label": "Par",
                "label_ar": "الاسمية",
                "label_fr": "Par"
            },
            {
                "value": 56713,
                "label": "Hamilton",
                "label_ar": "هاملتون",
                "label_fr": "Hamilton"
            },
            {
                "value": 57404,
                "label": "Whitchurch",
                "label_ar": "ويتشرش",
                "label_fr": "Whitchurch"
            },
            {
                "value": 57908,
                "label": "Wick",
                "label_ar": "ذبالة",
                "label_fr": "Mèche"
            },
            {
                "value": 56551,
                "label": "Carshalton",
                "label_ar": "كارسالتون",
                "label_fr": "Carshalton"
            },
            {
                "value": 57283,
                "label": "Tynemouth",
                "label_ar": "تاينماوث",
                "label_fr": "Tynemouth"
            },
            {
                "value": 56672,
                "label": "Swadlincote",
                "label_ar": "سوادلينكوت",
                "label_fr": "Swadlincote"
            },
            {
                "value": 56648,
                "label": "Newry",
                "label_ar": "نيوري",
                "label_fr": "Newry"
            },
            {
                "value": 56016,
                "label": "Billingshurst",
                "label_ar": "بيلينجشيرست",
                "label_fr": "Billingshurst"
            },
            {
                "value": 56885,
                "label": "Brecon",
                "label_ar": "بريكون",
                "label_fr": "Brecon"
            },
            {
                "value": 56207,
                "label": "Kings Lynn",
                "label_ar": "كينغز لين",
                "label_fr": "Kings Lynn"
            },
            {
                "value": 57796,
                "label": "Carnmoney",
                "label_ar": "كارنموني",
                "label_fr": "Carnmoney"
            },
            {
                "value": 58322,
                "label": "Charlwood",
                "label_ar": "تشارلوود",
                "label_fr": "Charlwood"
            },
            {
                "value": 56833,
                "label": "Cobham",
                "label_ar": "كوبهام",
                "label_fr": "Cobham"
            },
            {
                "value": 57880,
                "label": "Tain",
                "label_ar": "تاين",
                "label_fr": "Tain"
            },
            {
                "value": 55874,
                "label": "Stourbridge",
                "label_ar": "ستوربريدج",
                "label_fr": "Stourbridge"
            },
            {
                "value": 56118,
                "label": "Thornton Heath",
                "label_ar": "ثورنتون هيث",
                "label_fr": "Thornton Heath"
            },
            {
                "value": 58256,
                "label": "Godmersham",
                "label_ar": "غودمرشام",
                "label_fr": "Godmersham"
            },
            {
                "value": 58623,
                "label": "Sutton Bridge",
                "label_ar": "جسر ساتون",
                "label_fr": "Pont Sutton"
            },
            {
                "value": 58375,
                "label": "Little Lever",
                "label_ar": "ليتل ليفر",
                "label_fr": "Petit levier"
            },
            {
                "value": 56941,
                "label": "Ely",
                "label_ar": "ايلي",
                "label_fr": "Ely"
            },
            {
                "value": 56653,
                "label": "Saint Ives",
                "label_ar": "القديس ايفيس",
                "label_fr": "Saint Ives"
            },
            {
                "value": 55957,
                "label": "Prestwick",
                "label_ar": "بريستويك",
                "label_fr": "Prestwick"
            },
            {
                "value": 57481,
                "label": "Selston",
                "label_ar": "سيلستون",
                "label_fr": "Selston"
            },
            {
                "value": 57820,
                "label": "Cambuslang",
                "label_ar": "Cambuslang",
                "label_fr": "Cambuslang"
            },
            {
                "value": 58442,
                "label": "Bollington",
                "label_ar": "بولينجتون",
                "label_fr": "Bollington"
            },
            {
                "value": 58047,
                "label": "Wellington",
                "label_ar": "ويلينجتون",
                "label_fr": "Wellington"
            },
            {
                "value": 56962,
                "label": "Greenock",
                "label_ar": "غرينوك",
                "label_fr": "Greenock"
            },
            {
                "value": 58644,
                "label": "Appley Bridge",
                "label_ar": "جسر أبلي",
                "label_fr": "Pont Appley"
            },
            {
                "value": 56490,
                "label": "Wellington",
                "label_ar": "ويلينجتون",
                "label_fr": "Wellington"
            },
            {
                "value": 57480,
                "label": "Caston",
                "label_ar": "يلقي على",
                "label_fr": "Jeter sur"
            },
            {
                "value": 56725,
                "label": "Fulham",
                "label_ar": "فولهام",
                "label_fr": "Fulham"
            },
            {
                "value": 57586,
                "label": "Narberth",
                "label_ar": "ناربيرث",
                "label_fr": "Narberth"
            },
            {
                "value": 56649,
                "label": "Old Malton",
                "label_ar": "قديم مالتون",
                "label_fr": "Vieux Malton"
            },
            {
                "value": 58794,
                "label": "Nash",
                "label_ar": "ناش",
                "label_fr": "Nash"
            },
            {
                "value": 58675,
                "label": "Medmenham",
                "label_ar": "مدمنهام",
                "label_fr": "Medmenham"
            },
            {
                "value": 57665,
                "label": "Leyton",
                "label_ar": "ليتون",
                "label_fr": "Leyton"
            },
            {
                "value": 56031,
                "label": "Paisley",
                "label_ar": "بيزلي",
                "label_fr": "Paisley"
            },
            {
                "value": 56421,
                "label": "Westbury",
                "label_ar": "ويستبري",
                "label_fr": "Westbury"
            },
            {
                "value": 56420,
                "label": "Newton Abbot",
                "label_ar": "نيوتن أبوت",
                "label_fr": "Newton Abbot"
            },
            {
                "value": 56035,
                "label": "Chelmsford",
                "label_ar": "تشيلمسفورد",
                "label_fr": "Chelmsford"
            },
            {
                "value": 57239,
                "label": "Blackford",
                "label_ar": "بلاكفورد",
                "label_fr": "Blackford"
            },
            {
                "value": 56981,
                "label": "Chertsey",
                "label_ar": "تشيرتسي",
                "label_fr": "Chertsey"
            },
            {
                "value": 55967,
                "label": "St Andrews",
                "label_ar": "سانت أندروز",
                "label_fr": "Saint André"
            },
            {
                "value": 58888,
                "label": "Downend",
                "label_ar": "هبوط",
                "label_fr": "Downend"
            },
            {
                "value": 58431,
                "label": "Dungiven",
                "label_ar": "Dungiven",
                "label_fr": "Dungiven"
            },
            {
                "value": 58206,
                "label": "Avonmouth",
                "label_ar": "أفونماوث",
                "label_fr": "Avonmouth"
            },
            {
                "value": 56593,
                "label": "Ferndale",
                "label_ar": "فيرنديل",
                "label_fr": "Ferndale"
            },
            {
                "value": 56110,
                "label": "Tadworth",
                "label_ar": "تادوورث",
                "label_fr": "Tadworth"
            },
            {
                "value": 56312,
                "label": "Stourport On Severn",
                "label_ar": "ستوربورت أون سيفيرن",
                "label_fr": "Stourport sur Severn"
            },
            {
                "value": 58744,
                "label": "Havering atte Bower",
                "label_ar": "هافرينج أتى بور",
                "label_fr": "Havering atte Bower"
            },
            {
                "value": 58652,
                "label": "Lympsham",
                "label_ar": "ليمبشام",
                "label_fr": "Lympsham"
            },
            {
                "value": 56811,
                "label": "Mold",
                "label_ar": "قالب",
                "label_fr": "Moule"
            },
            {
                "value": 57545,
                "label": "Whitehaven",
                "label_ar": "وايتهيفن",
                "label_fr": "Whitehaven"
            },
            {
                "value": 56824,
                "label": "Haydock",
                "label_ar": "هايدوك",
                "label_fr": "Haydock"
            },
            {
                "value": 57839,
                "label": "Dovercourt",
                "label_ar": "دوفيركورت",
                "label_fr": "Dovercourt"
            },
            {
                "value": 56923,
                "label": "Dunmurry",
                "label_ar": "دنموري",
                "label_fr": "Dunmurry"
            },
            {
                "value": 56139,
                "label": "Wallingford",
                "label_ar": "والينجفورد",
                "label_fr": "Wallingford"
            },
            {
                "value": 56289,
                "label": "Lichfield",
                "label_ar": "ليتشفيلد",
                "label_fr": "Lichfield"
            },
            {
                "value": 57040,
                "label": "Hill",
                "label_ar": "تل",
                "label_fr": "Colline"
            },
            {
                "value": 56650,
                "label": "Conway",
                "label_ar": "كونواي",
                "label_fr": "Conway"
            },
            {
                "value": 57348,
                "label": "Pelsall",
                "label_ar": "بيلسال",
                "label_fr": "Pelsall"
            },
            {
                "value": 57949,
                "label": "Dolgelly",
                "label_ar": "دولجلي",
                "label_fr": "Dolgelly"
            },
            {
                "value": 57443,
                "label": "Brewood",
                "label_ar": "بروود",
                "label_fr": "Brasserie"
            },
            {
                "value": 58076,
                "label": "Widdrington",
                "label_ar": "فيدينغتون",
                "label_fr": "Widdrington"
            },
            {
                "value": 57904,
                "label": "Farmborough",
                "label_ar": "فارمبورو",
                "label_fr": "Farmborough"
            },
            {
                "value": 57365,
                "label": "Clarbeston Road",
                "label_ar": "طريق كلاربيستون",
                "label_fr": "Clarbeston Road"
            },
            {
                "value": 58458,
                "label": "Hawkwell",
                "label_ar": "هوكويل",
                "label_fr": "Hawkwell"
            },
            {
                "value": 56220,
                "label": "Spalding",
                "label_ar": "سبالدينج",
                "label_fr": "Spalding"
            },
            {
                "value": 57272,
                "label": "Hadleigh",
                "label_ar": "هادلي",
                "label_fr": "Hadleigh"
            },
            {
                "value": 57293,
                "label": "Halton",
                "label_ar": "وقف على",
                "label_fr": "Halton"
            },
            {
                "value": 56787,
                "label": "Esher",
                "label_ar": "إيشر",
                "label_fr": "Esher"
            },
            {
                "value": 58526,
                "label": "Burgh le Marsh",
                "label_ar": "برج لو مارش",
                "label_fr": "Burgh le Marsh"
            },
            {
                "value": 58466,
                "label": "Altham",
                "label_ar": "ألثام",
                "label_fr": "Altham"
            },
            {
                "value": 56764,
                "label": "Cumbernauld",
                "label_ar": "كمبرنولد",
                "label_fr": "Cumbernauld"
            },
            {
                "value": 56141,
                "label": "Ware",
                "label_ar": "وير",
                "label_fr": "Ware"
            },
            {
                "value": 58014,
                "label": "Helens Bay",
                "label_ar": "خليج هيلينز",
                "label_fr": "Helens Bay"
            },
            {
                "value": 57494,
                "label": "Pencarreg",
                "label_ar": "بنكارج",
                "label_fr": "Pencarreg"
            },
            {
                "value": 58393,
                "label": "Broughton",
                "label_ar": "جلبت على",
                "label_fr": "Introduit le"
            },
            {
                "value": 56615,
                "label": "Chesham",
                "label_ar": "تشيشام",
                "label_fr": "Chesham"
            },
            {
                "value": 55919,
                "label": "Blackwood",
                "label_ar": "خشب أسود",
                "label_fr": "Bois noir"
            },
            {
                "value": 57386,
                "label": "Clifton",
                "label_ar": "كليفتون",
                "label_fr": "Clifton"
            },
            {
                "value": 58539,
                "label": "Whitchurch",
                "label_ar": "ويتشرش",
                "label_fr": "Whitchurch"
            },
            {
                "value": 58619,
                "label": "South Brent",
                "label_ar": "جنوب برنت",
                "label_fr": "South Brent"
            },
            {
                "value": 57526,
                "label": "Weobley",
                "label_ar": "ويوبلي",
                "label_fr": "Weobley"
            },
            {
                "value": 56711,
                "label": "Sittingbourne",
                "label_ar": "سيتينجبورن",
                "label_fr": "Sittingbourne"
            },
            {
                "value": 57184,
                "label": "Chigwell",
                "label_ar": "تشيجويل",
                "label_fr": "Chigwell"
            },
            {
                "value": 57211,
                "label": "Long Buckby",
                "label_ar": "طويل باكبي",
                "label_fr": "Long Buckby"
            },
            {
                "value": 57213,
                "label": "Selsey",
                "label_ar": "سيلسي",
                "label_fr": "Selsey"
            },
            {
                "value": 56685,
                "label": "Girvan",
                "label_ar": "جيرفان",
                "label_fr": "Girvan"
            },
            {
                "value": 58710,
                "label": "Marr",
                "label_ar": "مار",
                "label_fr": "Marr"
            },
            {
                "value": 58523,
                "label": "Oulton",
                "label_ar": "أولتون",
                "label_fr": "Oulton"
            },
            {
                "value": 57051,
                "label": "Buntingford",
                "label_ar": "بونتنجفورد",
                "label_fr": "Buntingford"
            },
            {
                "value": 56864,
                "label": "Great Gransden",
                "label_ar": "غرانسدن العظيم",
                "label_fr": "Grand Gransden"
            },
            {
                "value": 56165,
                "label": "Flint",
                "label_ar": "فلينت",
                "label_fr": "Silex"
            },
            {
                "value": 57856,
                "label": "Dunblane",
                "label_ar": "دانبلين",
                "label_fr": "Dunblane"
            },
            {
                "value": 57826,
                "label": "East Cowes",
                "label_ar": "إيست كاوز",
                "label_fr": "Cowes Est"
            },
            {
                "value": 58792,
                "label": "Sparsholt",
                "label_ar": "سبارشولت",
                "label_fr": "Sparsholt"
            },
            {
                "value": 58379,
                "label": "Shenfield",
                "label_ar": "شينفيلد",
                "label_fr": "Shenfield"
            },
            {
                "value": 58004,
                "label": "Odiham",
                "label_ar": "أوديهام",
                "label_fr": "Odiham"
            },
            {
                "value": 56919,
                "label": "Holmewood",
                "label_ar": "هولموود",
                "label_fr": "Holmewood"
            },
            {
                "value": 56808,
                "label": "Limavady",
                "label_ar": "ليمافادي",
                "label_fr": "Limavady"
            },
            {
                "value": 57508,
                "label": "Middleton One Row",
                "label_ar": "ميدلتون صف واحد",
                "label_fr": "Middleton une rangée"
            },
            {
                "value": 58096,
                "label": "Sherfield upon Loddon",
                "label_ar": "شيرفيلد أبون لودون",
                "label_fr": "Sherfield sur Loddon"
            },
            {
                "value": 57632,
                "label": "South Molton",
                "label_ar": "جنوب مولتون",
                "label_fr": "South Molton"
            },
            {
                "value": 57102,
                "label": "Lerwick",
                "label_ar": "ليرويك",
                "label_fr": "Lerwick"
            },
            {
                "value": 56583,
                "label": "Bo'ness",
                "label_ar": "بوينس",
                "label_fr": "Bo'ness"
            },
            {
                "value": 56320,
                "label": "Littleborough",
                "label_ar": "ليتلبورو",
                "label_fr": "Littleborough"
            },
            {
                "value": 58462,
                "label": "Clapham",
                "label_ar": "كلافام",
                "label_fr": "Clapham"
            },
            {
                "value": 58128,
                "label": "Silverstone",
                "label_ar": "سيلفرستون",
                "label_fr": "Silverstone"
            },
            {
                "value": 57731,
                "label": "Chilbolton",
                "label_ar": "شيلبولتون",
                "label_fr": "Chilbolton"
            },
            {
                "value": 58105,
                "label": "Blewbury",
                "label_ar": "بلوبري",
                "label_fr": "Blewbury"
            },
            {
                "value": 58199,
                "label": "Stoke Climsland",
                "label_ar": "ستوك كليمسلاند",
                "label_fr": "Stoke Climsland"
            },
            {
                "value": 55890,
                "label": "Plymouth",
                "label_ar": "بليموث",
                "label_fr": "Plymouth"
            },
            {
                "value": 55913,
                "label": "Bideford",
                "label_ar": "بيدفورد",
                "label_fr": "Bideford"
            },
            {
                "value": 57095,
                "label": "Amersham",
                "label_ar": "أمرشام",
                "label_fr": "Amersham"
            },
            {
                "value": 58024,
                "label": "Claygate",
                "label_ar": "كلايجيت",
                "label_fr": "Claygate"
            },
            {
                "value": 56868,
                "label": "Gosberton",
                "label_ar": "جوسبيرتون",
                "label_fr": "Gosberton"
            },
            {
                "value": 56061,
                "label": "Wickford",
                "label_ar": "ويكفورد",
                "label_fr": "Wickford"
            },
            {
                "value": 57472,
                "label": "Talybont",
                "label_ar": "تاليبونت",
                "label_fr": "Talybont"
            },
            {
                "value": 57668,
                "label": "Goodmayes",
                "label_ar": "جودمايز",
                "label_fr": "Goodmayes"
            },
            {
                "value": 56182,
                "label": "Prudhoe",
                "label_ar": "برودو",
                "label_fr": "Prudhoe"
            },
            {
                "value": 57178,
                "label": "Star",
                "label_ar": "نجمة",
                "label_fr": "Étoile"
            },
            {
                "value": 57891,
                "label": "Mayfield",
                "label_ar": "مايفيلد",
                "label_fr": "Mayfield"
            },
            {
                "value": 57062,
                "label": "North Walsham",
                "label_ar": "شمال والشام",
                "label_fr": "North Walsham"
            },
            {
                "value": 57715,
                "label": "Chelsfield",
                "label_ar": "تشيلسفيلد",
                "label_fr": "Chelsfield"
            },
            {
                "value": 56068,
                "label": "Borehamwood",
                "label_ar": "بورهاموود",
                "label_fr": "Borehamwood"
            },
            {
                "value": 57555,
                "label": "Melrose",
                "label_ar": "ميلروز",
                "label_fr": "Melrose"
            },
            {
                "value": 58131,
                "label": "Monkswood",
                "label_ar": "مونكسوود",
                "label_fr": "Monkswood"
            },
            {
                "value": 58433,
                "label": "Handforth",
                "label_ar": "هاندفورث",
                "label_fr": "Handforth"
            },
            {
                "value": 57350,
                "label": "Gnosall",
                "label_ar": "غنوسال",
                "label_fr": "Gnosall"
            },
            {
                "value": 57819,
                "label": "Sheldon",
                "label_ar": "شيلدون",
                "label_fr": "Sheldon"
            },
            {
                "value": 56432,
                "label": "Tottenham",
                "label_ar": "توتنهام",
                "label_fr": "Tottenham"
            },
            {
                "value": 58491,
                "label": "Bosbury",
                "label_ar": "بوسبري",
                "label_fr": "Bosbury"
            },
            {
                "value": 57221,
                "label": "Formby",
                "label_ar": "فورمبي",
                "label_fr": "Formby"
            },
            {
                "value": 56308,
                "label": "Boxley",
                "label_ar": "بوكسلي",
                "label_fr": "Boxley"
            },
            {
                "value": 57045,
                "label": "Caythorpe",
                "label_ar": "كايثورب",
                "label_fr": "Caythorpe"
            },
            {
                "value": 58799,
                "label": "Sprowston",
                "label_ar": "سبروستون",
                "label_fr": "Sprowston"
            },
            {
                "value": 56314,
                "label": "Cirencester",
                "label_ar": "سيرينسيستر",
                "label_fr": "Cirencester"
            },
            {
                "value": 56760,
                "label": "Chatteris",
                "label_ar": "خاتريس",
                "label_fr": "Chatteris"
            },
            {
                "value": 58603,
                "label": "Birchanger",
                "label_ar": "بيرتشانجر",
                "label_fr": "Birchanger"
            },
            {
                "value": 58687,
                "label": "Bradfield St George",
                "label_ar": "برادفيلد سانت جورج",
                "label_fr": "Bradfield St George"
            },
            {
                "value": 57719,
                "label": "Honingham",
                "label_ar": "هونينغهام",
                "label_fr": "Honingham"
            },
            {
                "value": 56300,
                "label": "Congleton",
                "label_ar": "كونجليتون",
                "label_fr": "Congleton"
            },
            {
                "value": 56062,
                "label": "Royston",
                "label_ar": "رويستون",
                "label_fr": "Royston"
            },
            {
                "value": 56810,
                "label": "Hendon",
                "label_ar": "هندون",
                "label_fr": "Hendon"
            },
            {
                "value": 58121,
                "label": "Annesley",
                "label_ar": "أنيسلي",
                "label_fr": "Annesley"
            },
            {
                "value": 57871,
                "label": "Sutton Courtenay",
                "label_ar": "ساتون كورتيناي",
                "label_fr": "Sutton Courtenay"
            },
            {
                "value": 58575,
                "label": "Llanharan",
                "label_ar": "لانهاران",
                "label_fr": "Llanharan"
            },
            {
                "value": 55973,
                "label": "Leven",
                "label_ar": "ليفين",
                "label_fr": "Leven"
            },
            {
                "value": 58370,
                "label": "Dollis Hill",
                "label_ar": "دولس هيل",
                "label_fr": "Dollis Hill"
            },
            {
                "value": 58165,
                "label": "Camelford",
                "label_ar": "كاملفورد",
                "label_fr": "Camelford"
            },
            {
                "value": 58210,
                "label": "Stevenston",
                "label_ar": "ستيفنستون",
                "label_fr": "Stevenston"
            },
            {
                "value": 57345,
                "label": "Barton on Sea",
                "label_ar": "بارتون على البحر",
                "label_fr": "Barton sur mer"
            },
            {
                "value": 58146,
                "label": "Thorpe St Peter",
                "label_ar": "ثورب القديس بطرس",
                "label_fr": "Thorpe St Peter"
            },
            {
                "value": 58453,
                "label": "Raynes Park",
                "label_ar": "راينز بارك",
                "label_fr": "Parc Raynes"
            },
            {
                "value": 58264,
                "label": "Wanstead",
                "label_ar": "وانستيد",
                "label_fr": "Wanstead"
            },
            {
                "value": 56229,
                "label": "Southport",
                "label_ar": "ساوثبورت",
                "label_fr": "Southport"
            },
            {
                "value": 56888,
                "label": "River",
                "label_ar": "نهر",
                "label_fr": "rivière"
            },
            {
                "value": 58437,
                "label": "Weaverham",
                "label_ar": "ويفرهام",
                "label_fr": "Weaverham"
            },
            {
                "value": 56621,
                "label": "Wadebridge",
                "label_ar": "Wadebridge",
                "label_fr": "Wadebridge"
            },
            {
                "value": 58141,
                "label": "Stanton",
                "label_ar": "ستانتون",
                "label_fr": "Stanton"
            },
            {
                "value": 58796,
                "label": "Kingsland",
                "label_ar": "أرض الملك",
                "label_fr": "Kingsland"
            },
            {
                "value": 57505,
                "label": "Atworth",
                "label_ar": "أتوورث",
                "label_fr": "Atworth"
            },
            {
                "value": 56154,
                "label": "Colwyn Bay",
                "label_ar": "خليج كولوين",
                "label_fr": "Colwyn Bay"
            },
            {
                "value": 57236,
                "label": "East Horsley",
                "label_ar": "شرق هورسلي",
                "label_fr": "East Horsley"
            },
            {
                "value": 57064,
                "label": "Aughnacloy",
                "label_ar": "اوغناكلوي",
                "label_fr": "Aughnacloy"
            },
            {
                "value": 58881,
                "label": "Brayton",
                "label_ar": "برايتون",
                "label_fr": "Brayton"
            },
            {
                "value": 56218,
                "label": "Norwich",
                "label_ar": "نورويتش",
                "label_fr": "Norwich"
            },
            {
                "value": 55878,
                "label": "Stonehouse",
                "label_ar": "منزل حجري",
                "label_fr": "Maison en pierre"
            },
            {
                "value": 57772,
                "label": "Hilton",
                "label_ar": "هيلتون",
                "label_fr": "Hilton"
            },
            {
                "value": 57954,
                "label": "Nutley",
                "label_ar": "نوتلي",
                "label_fr": "Nutley"
            },
            {
                "value": 56028,
                "label": "Ballynahinch",
                "label_ar": "باليناهينش",
                "label_fr": "Ballynahinch"
            },
            {
                "value": 56488,
                "label": "High Wycombe",
                "label_ar": "ارتفاع ويكومب",
                "label_fr": "High Wycombe"
            },
            {
                "value": 57309,
                "label": "Earls Court",
                "label_ar": "إيرلز كورت",
                "label_fr": "Earls Court"
            },
            {
                "value": 57034,
                "label": "Okehampton",
                "label_ar": "أوكهامبتون",
                "label_fr": "Okehampton"
            },
            {
                "value": 57375,
                "label": "Worsley",
                "label_ar": "ورسلي",
                "label_fr": "Worsley"
            },
            {
                "value": 57361,
                "label": "Checkley",
                "label_ar": "تشيكلي",
                "label_fr": "Checkley"
            },
            {
                "value": 56573,
                "label": "Hedgerley",
                "label_ar": "هيدجيرلي",
                "label_fr": "Hedgerley"
            },
            {
                "value": 57261,
                "label": "Higham Ferrers",
                "label_ar": "هيغام فيريرز",
                "label_fr": "Higham Ferrers"
            },
            {
                "value": 56250,
                "label": "Rotherham",
                "label_ar": "روثرهام",
                "label_fr": "Rotherham"
            },
            {
                "value": 57171,
                "label": "London Borough of Wandsworth",
                "label_ar": "لندن بورو من واندسوورث",
                "label_fr": "Borough londonien de Wandsworth"
            },
            {
                "value": 58283,
                "label": "Renton",
                "label_ar": "رينتون",
                "label_fr": "Renton"
            },
            {
                "value": 58880,
                "label": "Minster",
                "label_ar": "وزير",
                "label_fr": "Cathédrale"
            },
            {
                "value": 58729,
                "label": "Eton",
                "label_ar": "إيتون",
                "label_fr": "Eton"
            },
            {
                "value": 56435,
                "label": "Pyle",
                "label_ar": "بايل",
                "label_fr": "Pyle"
            },
            {
                "value": 57047,
                "label": "Milnthorpe",
                "label_ar": "ميلنثورب",
                "label_fr": "Milnthorpe"
            },
            {
                "value": 58718,
                "label": "Crathorne",
                "label_ar": "كراثورن",
                "label_fr": "Crathorne"
            },
            {
                "value": 58422,
                "label": "Mobberley",
                "label_ar": "موبيرلي",
                "label_fr": "Mobberley"
            },
            {
                "value": 56278,
                "label": "Exmouth",
                "label_ar": "اكسماوث",
                "label_fr": "Exmouth"
            },
            {
                "value": 56415,
                "label": "Ringwood",
                "label_ar": "رينجوود",
                "label_fr": "Ringwood"
            },
            {
                "value": 56537,
                "label": "Hayes",
                "label_ar": "هايز",
                "label_fr": "Hayes"
            },
            {
                "value": 56071,
                "label": "Harrow",
                "label_ar": "مسلفة",
                "label_fr": "Herse"
            },
            {
                "value": 56518,
                "label": "Helston",
                "label_ar": "هيلستون",
                "label_fr": "Helston"
            },
            {
                "value": 57762,
                "label": "Steventon",
                "label_ar": "ستيفنتون",
                "label_fr": "Steventon"
            },
            {
                "value": 57074,
                "label": "Deptford",
                "label_ar": "ديبتفورد",
                "label_fr": "Deptford"
            },
            {
                "value": 56295,
                "label": "Hereford",
                "label_ar": "هيريفورد",
                "label_fr": "Hereford"
            },
            {
                "value": 56800,
                "label": "Lossiemouth",
                "label_ar": "لوزيماوث",
                "label_fr": "Lossiemouth"
            },
            {
                "value": 56989,
                "label": "Alness",
                "label_ar": "Alness",
                "label_fr": "Alness"
            },
            {
                "value": 57658,
                "label": "Chingford",
                "label_ar": "تشينجفورد",
                "label_fr": "Chingford"
            },
            {
                "value": 57799,
                "label": "Puckeridge",
                "label_ar": "بوكريدج",
                "label_fr": "Puckeridge"
            },
            {
                "value": 57193,
                "label": "North Weald",
                "label_ar": "شمال ويلد",
                "label_fr": "North Weald"
            },
            {
                "value": 57600,
                "label": "Connahs Quay",
                "label_ar": "كوناس كواي",
                "label_fr": "Connahs Quay"
            },
            {
                "value": 57716,
                "label": "Sedgley",
                "label_ar": "سيدجلي",
                "label_fr": "Sedgley"
            },
            {
                "value": 55902,
                "label": "Devizes",
                "label_ar": "ديفايسيس",
                "label_fr": "Conçoit"
            },
            {
                "value": 58001,
                "label": "Wyton",
                "label_ar": "ويتون",
                "label_fr": "Wyton"
            },
            {
                "value": 57390,
                "label": "Mansfield Woodhouse",
                "label_ar": "مانسفيلد وودهاوس",
                "label_fr": "Mansfield Woodhouse"
            },
            {
                "value": 57412,
                "label": "Corfe Mullen",
                "label_ar": "كورف مولين",
                "label_fr": "Corfe Mullen"
            },
            {
                "value": 56715,
                "label": "Nuneaton",
                "label_ar": "نونتون",
                "label_fr": "Nuneaton"
            },
            {
                "value": 58578,
                "label": "Chasetown",
                "label_ar": "تشاسيتاون",
                "label_fr": "Chasetown"
            },
            {
                "value": 58435,
                "label": "Hadlow Down",
                "label_ar": "هادلو داون",
                "label_fr": "Hadlow Down"
            },
            {
                "value": 55963,
                "label": "Falkirk",
                "label_ar": "فالكيرك",
                "label_fr": "Falkirk"
            },
            {
                "value": 58193,
                "label": "Cullen",
                "label_ar": "كولين",
                "label_fr": "Cullen"
            },
            {
                "value": 58476,
                "label": "Charlestown",
                "label_ar": "تشارلزتاون",
                "label_fr": "Charlestown"
            },
            {
                "value": 57926,
                "label": "Enstone",
                "label_ar": "إنستون",
                "label_fr": "Enstone"
            },
            {
                "value": 57957,
                "label": "Brede",
                "label_ar": "بريدي",
                "label_fr": "Brede"
            },
            {
                "value": 57528,
                "label": "Carlton le Moorland",
                "label_ar": "كارلتون لو مورلاند",
                "label_fr": "Carlton le Moorland"
            },
            {
                "value": 56022,
                "label": "Glasgow",
                "label_ar": "غلاسكو",
                "label_fr": "Glasgow"
            },
            {
                "value": 58294,
                "label": "Brimpton",
                "label_ar": "بريمبتون",
                "label_fr": "Brimpton"
            },
            {
                "value": 58381,
                "label": "Threlkeld",
                "label_ar": "ثريلكيلد",
                "label_fr": "Threlkeld"
            },
            {
                "value": 55885,
                "label": "Llandrindod Wells",
                "label_ar": "لاندريندود ويلز",
                "label_fr": "Puits de Llandrindod"
            },
            {
                "value": 56151,
                "label": "Liverpool",
                "label_ar": "ليفربول",
                "label_fr": "Liverpool"
            },
            {
                "value": 56671,
                "label": "Andover",
                "label_ar": "وأكثر",
                "label_fr": "Et plus"
            },
            {
                "value": 57529,
                "label": "Broom",
                "label_ar": "مكنسة",
                "label_fr": "Balai"
            },
            {
                "value": 58113,
                "label": "Southgate",
                "label_ar": "ساوثجيت",
                "label_fr": "Southgate"
            },
            {
                "value": 57596,
                "label": "Watton",
                "label_ar": "واتون",
                "label_fr": "Watton"
            },
            {
                "value": 56514,
                "label": "High Peak",
                "label_ar": "قمة عالية",
                "label_fr": "High Peak"
            },
            {
                "value": 58892,
                "label": "Wickersley",
                "label_ar": "ويكرسلي",
                "label_fr": "Wickersley"
            },
            {
                "value": 56866,
                "label": "Clovenfords",
                "label_ar": "كلوفنفوردز",
                "label_fr": "Clovenfords"
            },
            {
                "value": 57563,
                "label": "Burghclere",
                "label_ar": "بورغكلير",
                "label_fr": "Burghclere"
            },
            {
                "value": 58717,
                "label": "Herriard",
                "label_ar": "هيريارد",
                "label_fr": "Herriard"
            },
            {
                "value": 56517,
                "label": "Bodmin",
                "label_ar": "بودمين",
                "label_fr": "Bodmin"
            },
            {
                "value": 57809,
                "label": "Humberston",
                "label_ar": "هامبرستون",
                "label_fr": "Humberston"
            },
            {
                "value": 58666,
                "label": "Bishop Burton",
                "label_ar": "المطران بيرتون",
                "label_fr": "Mgr Burton"
            },
            {
                "value": 56362,
                "label": "Hexham",
                "label_ar": "هيكسهام",
                "label_fr": "Hexham"
            },
            {
                "value": 56380,
                "label": "Barnoldswick",
                "label_ar": "بارنولدزويك",
                "label_fr": "Barnoldswick"
            },
            {
                "value": 56900,
                "label": "Hursley",
                "label_ar": "هورسلي",
                "label_fr": "Hursley"
            },
            {
                "value": 57231,
                "label": "Filey",
                "label_ar": "ملفي",
                "label_fr": "Filey"
            },
            {
                "value": 56775,
                "label": "Drybrook",
                "label_ar": "دريبروك",
                "label_fr": "Drybrook"
            },
            {
                "value": 56728,
                "label": "Haltwhistle",
                "label_ar": "وقف الصافرة",
                "label_fr": "Haltwhistle"
            },
            {
                "value": 55970,
                "label": "Penicuik",
                "label_ar": "بينيكويك",
                "label_fr": "Penicuik"
            },
            {
                "value": 56283,
                "label": "Stoke-on-Trent",
                "label_ar": "ستوك أون ترينت",
                "label_fr": "Stoke-on-Trent"
            },
            {
                "value": 56798,
                "label": "Lewes",
                "label_ar": "لويس",
                "label_fr": "Lewes"
            },
            {
                "value": 57852,
                "label": "Monifieth",
                "label_ar": "Monifieth",
                "label_fr": "Monifieth"
            },
            {
                "value": 57929,
                "label": "Frant",
                "label_ar": "فرانت",
                "label_fr": "Frant"
            },
            {
                "value": 57011,
                "label": "Earley",
                "label_ar": "إيرلي",
                "label_fr": "Earley"
            },
            {
                "value": 58895,
                "label": "Hitcham",
                "label_ar": "هيتشام",
                "label_fr": "Hitcham"
            },
            {
                "value": 58752,
                "label": "Holywell",
                "label_ar": "هوليويل",
                "label_fr": "Holywell"
            },
            {
                "value": 58374,
                "label": "Portlethen",
                "label_ar": "بورتليثن",
                "label_fr": "Portlethen"
            },
            {
                "value": 56781,
                "label": "Oxted",
                "label_ar": "Oxted",
                "label_fr": "Oxted"
            },
            {
                "value": 56814,
                "label": "Coatbridge",
                "label_ar": "كواتبريدج",
                "label_fr": "Coatbridge"
            },
            {
                "value": 56376,
                "label": "Driffield",
                "label_ar": "دريفيلد",
                "label_fr": "Driffield"
            },
            {
                "value": 58356,
                "label": "Mountsorrel",
                "label_ar": "ماونتسوريل",
                "label_fr": "Montsorrel"
            },
            {
                "value": 56243,
                "label": "Hyde",
                "label_ar": "هايد",
                "label_fr": "Hyde"
            },
            {
                "value": 57677,
                "label": "Market Deeping",
                "label_ar": "تعميق السوق",
                "label_fr": "Approfondissement du marché"
            },
            {
                "value": 56507,
                "label": "Holywell",
                "label_ar": "هوليويل",
                "label_fr": "Holywell"
            },
            {
                "value": 56318,
                "label": "Farnworth",
                "label_ar": "فارنوث",
                "label_fr": "Farnworth"
            },
            {
                "value": 57212,
                "label": "Great Missenden",
                "label_ar": "Missenden العظيم",
                "label_fr": "Grand Missenden"
            },
            {
                "value": 56816,
                "label": "Chessington",
                "label_ar": "شيسينغتون",
                "label_fr": "Chessington"
            },
            {
                "value": 56662,
                "label": "Lowestoft",
                "label_ar": "Lowestoft",
                "label_fr": "Lowestoft"
            },
            {
                "value": 57767,
                "label": "Blidworth",
                "label_ar": "بليدورث",
                "label_fr": "Blidworth"
            },
            {
                "value": 56799,
                "label": "Newcastle-under-Lyme",
                "label_ar": "نيوكاسل أندر لايم",
                "label_fr": "Newcastle-under-Lyme"
            },
            {
                "value": 56144,
                "label": "Manchester",
                "label_ar": "مانشستر",
                "label_fr": "Manchester"
            },
            {
                "value": 57298,
                "label": "Streatley",
                "label_ar": "ستريتلي",
                "label_fr": "Streatley"
            },
            {
                "value": 56321,
                "label": "Heswall",
                "label_ar": "هيسوال",
                "label_fr": "Heswall"
            },
            {
                "value": 57708,
                "label": "Nailsea",
                "label_ar": "نيلسي",
                "label_fr": "Nailsea"
            },
            {
                "value": 58640,
                "label": "Kirk Ella",
                "label_ar": "كيرك ايلا",
                "label_fr": "Kirk Ella"
            },
            {
                "value": 56547,
                "label": "Ely",
                "label_ar": "ايلي",
                "label_fr": "Ely"
            },
            {
                "value": 56999,
                "label": "Walmer",
                "label_ar": "وولمر",
                "label_fr": "Walmer"
            },
            {
                "value": 57858,
                "label": "Itchen Abbas",
                "label_ar": "يتخن عباس",
                "label_fr": "Itchen Abbas"
            },
            {
                "value": 58354,
                "label": "Ewelme",
                "label_ar": "اويلمي",
                "label_fr": "Ewelme"
            },
            {
                "value": 57001,
                "label": "Milnathort",
                "label_ar": "ميلناثورت",
                "label_fr": "Milnathort"
            },
            {
                "value": 55944,
                "label": "Coventry",
                "label_ar": "كوفنتري",
                "label_fr": "Coventry"
            },
            {
                "value": 57572,
                "label": "Wymeswold",
                "label_ar": "Wymeswold",
                "label_fr": "Wymeswold"
            },
            {
                "value": 56572,
                "label": "Folkestone",
                "label_ar": "فولكستون",
                "label_fr": "Folkestone"
            },
            {
                "value": 56729,
                "label": "Hackney",
                "label_ar": "هاكني",
                "label_fr": "Hackney"
            },
            {
                "value": 57984,
                "label": "Warton",
                "label_ar": "وارتون",
                "label_fr": "Warton"
            },
            {
                "value": 56159,
                "label": "Runcorn",
                "label_ar": "رونكورن",
                "label_fr": "Runcorn"
            },
            {
                "value": 58514,
                "label": "Buckden",
                "label_ar": "باكدين",
                "label_fr": "Buckden"
            },
            {
                "value": 58636,
                "label": "Kingsteignton",
                "label_ar": "Kingsteignton",
                "label_fr": "Kingsteignton"
            },
            {
                "value": 57447,
                "label": "Lyminge",
                "label_ar": "ليمنج",
                "label_fr": "Lyminge"
            },
            {
                "value": 57132,
                "label": "Burythorpe",
                "label_ar": "بوريثورب",
                "label_fr": "Burythorpe"
            },
            {
                "value": 58410,
                "label": "Lurgan",
                "label_ar": "لورغان",
                "label_fr": "Lurgan"
            },
            {
                "value": 56844,
                "label": "Maryport",
                "label_ar": "ماريبورت",
                "label_fr": "Maryport"
            },
            {
                "value": 58448,
                "label": "Abergwynfi",
                "label_ar": "Abergwynfi",
                "label_fr": "Abergwynfi"
            },
            {
                "value": 58778,
                "label": "Monkton",
                "label_ar": "مونكتون",
                "label_fr": "Monkton"
            },
            {
                "value": 57527,
                "label": "Kirkwall",
                "label_ar": "كيركوال",
                "label_fr": "Kirkwall"
            },
            {
                "value": 57938,
                "label": "Bonar Bridge",
                "label_ar": "جسر بونار",
                "label_fr": "Pont de Bonar"
            },
            {
                "value": 57439,
                "label": "Lyme Regis",
                "label_ar": "ليم ريجيس",
                "label_fr": "Lyme Regis"
            },
            {
                "value": 57315,
                "label": "Biddulph",
                "label_ar": "بدولف",
                "label_fr": "Biddulph"
            },
            {
                "value": 58158,
                "label": "Rotherfield",
                "label_ar": "روثرفيلد",
                "label_fr": "Rotherfield"
            },
            {
                "value": 58392,
                "label": "Tatsfield",
                "label_ar": "تاتسفيلد",
                "label_fr": "Tatsfield"
            },
            {
                "value": 58738,
                "label": "Llanon",
                "label_ar": "يانون",
                "label_fr": "Llanon"
            },
            {
                "value": 57075,
                "label": "Denham",
                "label_ar": "دينهام",
                "label_fr": "Denham"
            },
            {
                "value": 58039,
                "label": "Kingswood",
                "label_ar": "كينجسوود",
                "label_fr": "Kingswood"
            },
            {
                "value": 57933,
                "label": "Winkleigh",
                "label_ar": "وينكلي",
                "label_fr": "Winkleigh"
            },
            {
                "value": 56512,
                "label": "Bangor-is-y-coed",
                "label_ar": "بانجور هو y مختلط",
                "label_fr": "Bangor-est-y-étudiante"
            },
            {
                "value": 58080,
                "label": "Benllech",
                "label_ar": "Benllech",
                "label_fr": "Benllech"
            },
            {
                "value": 58847,
                "label": "Barbaraville",
                "label_ar": "باربارافيل",
                "label_fr": "Barbaraville"
            },
            {
                "value": 58843,
                "label": "Ystradgynlais",
                "label_ar": "Ystradgynlais",
                "label_fr": "Ystradgynlais"
            },
            {
                "value": 57382,
                "label": "Houghton Regis",
                "label_ar": "هوتون ريجيس",
                "label_fr": "Houghton Regis"
            },
            {
                "value": 57724,
                "label": "Middleton",
                "label_ar": "ميدلتون",
                "label_fr": "Middleton"
            },
            {
                "value": 58639,
                "label": "Rayne",
                "label_ar": "راين",
                "label_fr": "Rayne"
            },
            {
                "value": 58593,
                "label": "Norton",
                "label_ar": "نورتون",
                "label_fr": "Norton"
            },
            {
                "value": 58873,
                "label": "Sundon Park",
                "label_ar": "سوندون بارك",
                "label_fr": "Parc Sundon"
            },
            {
                "value": 58401,
                "label": "Melton",
                "label_ar": "ميلتون",
                "label_fr": "Melton"
            },
            {
                "value": 58498,
                "label": "Middleton",
                "label_ar": "ميدلتون",
                "label_fr": "Middleton"
            },
            {
                "value": 57139,
                "label": "Aberford",
                "label_ar": "أبيرفورد",
                "label_fr": "Aberford"
            },
            {
                "value": 57353,
                "label": "Highbridge",
                "label_ar": "جسر عالية",
                "label_fr": "Highbridge"
            },
            {
                "value": 57561,
                "label": "Lockerbie",
                "label_ar": "لوكربي",
                "label_fr": "Lockerbie"
            },
            {
                "value": 58576,
                "label": "Ripponden",
                "label_ar": "ريبوندين",
                "label_fr": "Ripponden"
            },
            {
                "value": 58513,
                "label": "Borough Green",
                "label_ar": "بورو جرين",
                "label_fr": "Borough Green"
            },
            {
                "value": 58088,
                "label": "Pilham",
                "label_ar": "بيلهام",
                "label_fr": "Pilham"
            },
            {
                "value": 58428,
                "label": "Needingworth",
                "label_ar": "نيدنجورث",
                "label_fr": "Needingworth"
            },
            {
                "value": 57786,
                "label": "Donnington",
                "label_ar": "دونينجتون",
                "label_fr": "Donnington"
            },
            {
                "value": 58315,
                "label": "Spratton",
                "label_ar": "سبراتون",
                "label_fr": "Spratton"
            },
            {
                "value": 56049,
                "label": "Newhaven",
                "label_ar": "ملاذ جديد",
                "label_fr": "Newhaven"
            },
            {
                "value": 56294,
                "label": "Kingswinford",
                "label_ar": "كينغزوينفورد",
                "label_fr": "Kingswinford"
            },
            {
                "value": 56309,
                "label": "Wotton-under-Edge",
                "label_ar": "ووتون أندر إيدج",
                "label_fr": "Wotton-sous-bord"
            },
            {
                "value": 56977,
                "label": "Llanishen",
                "label_ar": "لانشين",
                "label_fr": "Llanishen"
            },
            {
                "value": 58390,
                "label": "Bentley",
                "label_ar": "بنتلي",
                "label_fr": "Bentley"
            },
            {
                "value": 57260,
                "label": "Larkhall",
                "label_ar": "لاركهول",
                "label_fr": "Larkhall"
            },
            {
                "value": 56288,
                "label": "Willenhall",
                "label_ar": "ويلينهال",
                "label_fr": "Willenhall"
            },
            {
                "value": 58246,
                "label": "Nassington",
                "label_ar": "ناسينجتون",
                "label_fr": "Nassington"
            },
            {
                "value": 55959,
                "label": "Dalkeith",
                "label_ar": "دالكيث",
                "label_fr": "Dalkeith"
            },
            {
                "value": 56522,
                "label": "Truro",
                "label_ar": "ترورو",
                "label_fr": "Truro"
            },
            {
                "value": 56469,
                "label": "Baldock",
                "label_ar": "بالدوك",
                "label_fr": "Baldock"
            },
            {
                "value": 58552,
                "label": "Walker",
                "label_ar": "ووكر",
                "label_fr": "Marcheur"
            },
            {
                "value": 56643,
                "label": "Abergavenny",
                "label_ar": "Abergavenny",
                "label_fr": "Abergavenny"
            },
            {
                "value": 58237,
                "label": "Wemyss Bay",
                "label_ar": "خليج ويميس",
                "label_fr": "Baie de Wemyss"
            },
            {
                "value": 58005,
                "label": "Calstock",
                "label_ar": "كالستوك",
                "label_fr": "Calstock"
            },
            {
                "value": 58032,
                "label": "Horndon on the Hill",
                "label_ar": "هورندون على التل",
                "label_fr": "Horndon sur la colline"
            },
            {
                "value": 56396,
                "label": "Margate",
                "label_ar": "مارجيت",
                "label_fr": "Margate"
            },
            {
                "value": 56473,
                "label": "Rainham",
                "label_ar": "رينهام",
                "label_fr": "Rainham"
            },
            {
                "value": 56209,
                "label": "Boston",
                "label_ar": "بوسطن",
                "label_fr": "Boston"
            },
            {
                "value": 58041,
                "label": "Callington",
                "label_ar": "كالينجتون",
                "label_fr": "Callington"
            },
            {
                "value": 58312,
                "label": "Kirkham",
                "label_ar": "كيركهام",
                "label_fr": "Kirkham"
            },
            {
                "value": 57567,
                "label": "Gargunnock",
                "label_ar": "Gargunnock",
                "label_fr": "Gargunnock"
            },
            {
                "value": 57775,
                "label": "Lubenham",
                "label_ar": "لوبينهام",
                "label_fr": "Lubenham"
            },
            {
                "value": 58227,
                "label": "North Waltham",
                "label_ar": "شمال والثام",
                "label_fr": "North Waltham"
            },
            {
                "value": 56598,
                "label": "Altrincham",
                "label_ar": "الترينشام",
                "label_fr": "Altrincham"
            },
            {
                "value": 56630,
                "label": "Menai Bridge",
                "label_ar": "جسر ميناي",
                "label_fr": "Pont de Menai"
            },
            {
                "value": 56731,
                "label": "Chipping Ongar",
                "label_ar": "تشيبينج أونجار",
                "label_fr": "Chipping Ongar"
            },
            {
                "value": 58027,
                "label": "Presteigne",
                "label_ar": "بريستين",
                "label_fr": "Presteigne"
            },
            {
                "value": 58515,
                "label": "Birtley",
                "label_ar": "بيرتلي",
                "label_fr": "Birtley"
            },
            {
                "value": 56568,
                "label": "Longton",
                "label_ar": "لونجتون",
                "label_fr": "Longton"
            },
            {
                "value": 57433,
                "label": "Alford",
                "label_ar": "ألفورد",
                "label_fr": "Alford"
            },
            {
                "value": 57993,
                "label": "Huntly",
                "label_ar": "بصراحة",
                "label_fr": "Huntly"
            },
            {
                "value": 57935,
                "label": "Beaworthy",
                "label_ar": "بيورثي",
                "label_fr": "Beaworthy"
            },
            {
                "value": 56099,
                "label": "Grays",
                "label_ar": "جرايز",
                "label_fr": "Gris"
            },
            {
                "value": 58337,
                "label": "Neilston",
                "label_ar": "نيلستون",
                "label_fr": "Neilston"
            },
            {
                "value": 55953,
                "label": "Derby",
                "label_ar": "دربي",
                "label_fr": "Derby"
            },
            {
                "value": 57233,
                "label": "Bickenhill",
                "label_ar": "بيكنهيل",
                "label_fr": "Bickenhill"
            },
            {
                "value": 56969,
                "label": "Lauder",
                "label_ar": "لودر",
                "label_fr": "Lauder"
            },
            {
                "value": 57940,
                "label": "Edgefield",
                "label_ar": "إيدجفيلد",
                "label_fr": "Edgefield"
            },
            {
                "value": 57791,
                "label": "Pembroke",
                "label_ar": "بيمبروك",
                "label_fr": "Pembroke"
            },
            {
                "value": 58156,
                "label": "Claverdon",
                "label_ar": "كلافردون",
                "label_fr": "Claverdon"
            },
            {
                "value": 56193,
                "label": "Billingham",
                "label_ar": "بيلينجهام",
                "label_fr": "Billingham"
            },
            {
                "value": 56055,
                "label": "Belvedere",
                "label_ar": "بلفيدير",
                "label_fr": "Belvédère"
            },
            {
                "value": 58495,
                "label": "Mere",
                "label_ar": "مجرد",
                "label_fr": "Simple"
            },
            {
                "value": 58742,
                "label": "Stow",
                "label_ar": "خبأ",
                "label_fr": "Ranger"
            },
            {
                "value": 56425,
                "label": "Banbury",
                "label_ar": "بانبري",
                "label_fr": "Banbury"
            },
            {
                "value": 56091,
                "label": "Rayleigh",
                "label_ar": "رايلي",
                "label_fr": "Rayleigh"
            },
            {
                "value": 58133,
                "label": "Mendlesham",
                "label_ar": "مندليشام",
                "label_fr": "Mendlesham"
            },
            {
                "value": 57089,
                "label": "Balerno",
                "label_ar": "باليرنو",
                "label_fr": "Balerno"
            },
            {
                "value": 56665,
                "label": "Rosyth",
                "label_ar": "روزيث",
                "label_fr": "Rosyth"
            },
            {
                "value": 56014,
                "label": "Ryde",
                "label_ar": "رايد",
                "label_fr": "Ryde"
            },
            {
                "value": 58532,
                "label": "Brandon",
                "label_ar": "براندون",
                "label_fr": "Brandon"
            },
            {
                "value": 58562,
                "label": "Islip",
                "label_ar": "انزلقت",
                "label_fr": "Je glisse"
            },
            {
                "value": 58821,
                "label": "Whitehouse",
                "label_ar": "البيت الابيض",
                "label_fr": "Maison Blanche"
            },
            {
                "value": 55954,
                "label": "Barrow upon Soar",
                "label_ar": "بارو أبون سور",
                "label_fr": "Barrow sur Soar"
            },
            {
                "value": 58840,
                "label": "Boston Spa",
                "label_ar": "بوسطن سبا",
                "label_fr": "Spa de Boston"
            },
            {
                "value": 57866,
                "label": "Leitholm",
                "label_ar": "ليثولم",
                "label_fr": "Leitholm"
            },
            {
                "value": 58167,
                "label": "Barmouth",
                "label_ar": "بارماوث",
                "label_fr": "Barmouth"
            },
            {
                "value": 55940,
                "label": "Leicester",
                "label_ar": "ليستر",
                "label_fr": "Leicester"
            },
            {
                "value": 57989,
                "label": "Nutfield",
                "label_ar": "نوتفيلد",
                "label_fr": "Nutfield"
            },
            {
                "value": 56606,
                "label": "Dereham",
                "label_ar": "ديرهام",
                "label_fr": "Dereham"
            },
            {
                "value": 56452,
                "label": "Corsham",
                "label_ar": "كورشام",
                "label_fr": "Corsham"
            },
            {
                "value": 58369,
                "label": "Great Baddow",
                "label_ar": "جريت بادو",
                "label_fr": "Grand Baddow"
            },
            {
                "value": 57003,
                "label": "Great Dunmow",
                "label_ar": "غريت دونمو",
                "label_fr": "Grand Dunmow"
            },
            {
                "value": 58016,
                "label": "Snettisham",
                "label_ar": "سنيتيشام",
                "label_fr": "Snettisham"
            },
            {
                "value": 57642,
                "label": "Ramsey",
                "label_ar": "رامسي",
                "label_fr": "Ramsey"
            },
            {
                "value": 58661,
                "label": "Capenhurst",
                "label_ar": "كابينهورست",
                "label_fr": "Capenhurst"
            },
            {
                "value": 57611,
                "label": "Barnwell",
                "label_ar": "بارنويل",
                "label_fr": "Barnwell"
            },
            {
                "value": 58307,
                "label": "Beach",
                "label_ar": "شاطئ بحر",
                "label_fr": "Plage"
            },
            {
                "value": 56168,
                "label": "Jarrow",
                "label_ar": "جارو",
                "label_fr": "Jarrow"
            },
            {
                "value": 58849,
                "label": "Bierton",
                "label_ar": "بيرتون",
                "label_fr": "Bierton"
            },
            {
                "value": 58878,
                "label": "Coppenhall",
                "label_ar": "كوبنهول",
                "label_fr": "Coppenhall"
            },
            {
                "value": 55958,
                "label": "Edinburgh",
                "label_ar": "ادنبره",
                "label_fr": "Edinbourg"
            },
            {
                "value": 57378,
                "label": "Westgate",
                "label_ar": "ويست جيت",
                "label_fr": "Westgate"
            },
            {
                "value": 56369,
                "label": "Newick",
                "label_ar": "نيويك",
                "label_fr": "Newick"
            },
            {
                "value": 56449,
                "label": "Rushden",
                "label_ar": "راشدين",
                "label_fr": "Rushden"
            },
            {
                "value": 57678,
                "label": "Rainworth",
                "label_ar": "رينورث",
                "label_fr": "Rainworth"
            },
            {
                "value": 57383,
                "label": "Hapton",
                "label_ar": "هابتون",
                "label_fr": "Hapton"
            },
            {
                "value": 58245,
                "label": "Bacton",
                "label_ar": "باكتون",
                "label_fr": "Bacton"
            },
            {
                "value": 56505,
                "label": "Newcastle",
                "label_ar": "نيوكاسل",
                "label_fr": "Newcastle"
            },
            {
                "value": 57409,
                "label": "Melton Constable",
                "label_ar": "ميلتون كونستابل",
                "label_fr": "Agent de Melton"
            },
            {
                "value": 57069,
                "label": "Sutton in Ashfield",
                "label_ar": "ساتون في أشفيلد",
                "label_fr": "Sutton à Ashfield"
            },
            {
                "value": 55927,
                "label": "Newport",
                "label_ar": "نيوبورت",
                "label_fr": "Newport"
            },
            {
                "value": 57712,
                "label": "Addington",
                "label_ar": "ادينغتون",
                "label_fr": "Addington"
            },
            {
                "value": 56355,
                "label": "Cramlington",
                "label_ar": "كراملينجتون",
                "label_fr": "Cramlington"
            },
            {
                "value": 57541,
                "label": "Banchory",
                "label_ar": "بانشوري",
                "label_fr": "Banchory"
            },
            {
                "value": 58068,
                "label": "Masham",
                "label_ar": "مشم",
                "label_fr": "Masham"
            },
            {
                "value": 56105,
                "label": "Billericay",
                "label_ar": "بيليريكاي",
                "label_fr": "Billericay"
            },
            {
                "value": 58177,
                "label": "Laxfield",
                "label_ar": "لاكسفيلد",
                "label_fr": "Laxfield"
            },
            {
                "value": 56410,
                "label": "Nelson",
                "label_ar": "نيلسون",
                "label_fr": "Nelson"
            },
            {
                "value": 56242,
                "label": "Blackburn",
                "label_ar": "حرق الأسود",
                "label_fr": "Blackburn"
            },
            {
                "value": 57321,
                "label": "Thrapston",
                "label_ar": "ثرابستون",
                "label_fr": "Thrapston"
            },
            {
                "value": 57805,
                "label": "Bulford",
                "label_ar": "بولفورد",
                "label_fr": "Bulford"
            },
            {
                "value": 58898,
                "label": "Handcross",
                "label_ar": "هاندكروس",
                "label_fr": "Croisé à la main"
            },
            {
                "value": 57831,
                "label": "Horsmonden",
                "label_ar": "هورموندين",
                "label_fr": "Horsmonden"
            },
            {
                "value": 57416,
                "label": "Fort William",
                "label_ar": "فورت ويليام",
                "label_fr": "Fort William"
            },
            {
                "value": 58396,
                "label": "Cleator",
                "label_ar": "Cleator",
                "label_fr": "Cleator"
            },
            {
                "value": 58660,
                "label": "Whitnash",
                "label_ar": "ويتناش",
                "label_fr": "Whitnash"
            },
            {
                "value": 56225,
                "label": "Clacton-on-Sea",
                "label_ar": "كلاكتون أون سي",
                "label_fr": "Clacton-on-Sea"
            },
            {
                "value": 56403,
                "label": "Canterbury",
                "label_ar": "كانتربري",
                "label_fr": "Cantorbéry"
            },
            {
                "value": 57874,
                "label": "Leigh",
                "label_ar": "لي",
                "label_fr": "Leigh"
            },
            {
                "value": 57778,
                "label": "Willington",
                "label_ar": "ويلينجتون",
                "label_fr": "Willington"
            },
            {
                "value": 57313,
                "label": "Broadstone",
                "label_ar": "برودستون",
                "label_fr": "Broadstone"
            },
            {
                "value": 57252,
                "label": "Hunstanton",
                "label_ar": "هونستانتون",
                "label_fr": "Hunstanton"
            },
            {
                "value": 56186,
                "label": "Peterlee",
                "label_ar": "بيترلي",
                "label_fr": "Peterlee"
            },
            {
                "value": 55978,
                "label": "Kilbirnie",
                "label_ar": "كيلبيرني",
                "label_fr": "Kilbirnie"
            },
            {
                "value": 58500,
                "label": "North Somercotes",
                "label_ar": "شمال سومركوتس",
                "label_fr": "North Somercotes"
            },
            {
                "value": 58138,
                "label": "Stockcross",
                "label_ar": "ستوككروس",
                "label_fr": "Stockcross"
            },
            {
                "value": 58208,
                "label": "East Challow",
                "label_ar": "الشرق الضحلة",
                "label_fr": "Challow Est"
            },
            {
                "value": 58860,
                "label": "Crossgates",
                "label_ar": "عبر البوابات",
                "label_fr": "Portes croisées"
            },
            {
                "value": 55964,
                "label": "Livingston",
                "label_ar": "ليفينغستون",
                "label_fr": "Livingston"
            },
            {
                "value": 56498,
                "label": "Tenbury Wells",
                "label_ar": "تينبري ويلز",
                "label_fr": "Tenbury Wells"
            },
            {
                "value": 58363,
                "label": "Donaghadee",
                "label_ar": "دوناجادي",
                "label_fr": "Donaghadee"
            },
            {
                "value": 58851,
                "label": "Ulcombe",
                "label_ar": "أولكومب",
                "label_fr": "Ulcombe"
            },
            {
                "value": 58783,
                "label": "Witley",
                "label_ar": "ويتلي",
                "label_fr": "Witley"
            },
            {
                "value": 56385,
                "label": "Mirfield",
                "label_ar": "ميرفيلد",
                "label_fr": "Mirfield"
            },
            {
                "value": 56365,
                "label": "Cockermouth",
                "label_ar": "كوكرماوث",
                "label_fr": "Cockermouth"
            },
            {
                "value": 56302,
                "label": "Uttoxeter",
                "label_ar": "أوتوكستر",
                "label_fr": "Uttoxeter"
            },
            {
                "value": 57332,
                "label": "Buckhurst Hill",
                "label_ar": "تل باكهورست",
                "label_fr": "Buckhurst Hill"
            },
            {
                "value": 56646,
                "label": "Pendlebury",
                "label_ar": "بندلبري",
                "label_fr": "Pendlebury"
            },
            {
                "value": 56098,
                "label": "South Ockendon",
                "label_ar": "جنوب اوكندون",
                "label_fr": "Ockendon sud"
            },
            {
                "value": 57336,
                "label": "Burley",
                "label_ar": "بيرلي",
                "label_fr": "Burley"
            },
            {
                "value": 57837,
                "label": "Rothesay",
                "label_ar": "روثسي",
                "label_fr": "Rothesay"
            },
            {
                "value": 56860,
                "label": "Somersham",
                "label_ar": "سومرشام",
                "label_fr": "Somersham"
            },
            {
                "value": 57902,
                "label": "Macduff",
                "label_ar": "ماكدوف",
                "label_fr": "Macduff"
            },
            {
                "value": 57944,
                "label": "Ramsbury",
                "label_ar": "رامزبري",
                "label_fr": "Ramsbury"
            },
            {
                "value": 56944,
                "label": "Coulsdon",
                "label_ar": "كولسدون",
                "label_fr": "Coulsdon"
            },
            {
                "value": 57535,
                "label": "Carbrooke",
                "label_ar": "كاربروك",
                "label_fr": "Carbrooke"
            },
            {
                "value": 57316,
                "label": "Bridport",
                "label_ar": "بريدبورت",
                "label_fr": "Bridport"
            },
            {
                "value": 57140,
                "label": "Melksham",
                "label_ar": "ملكشام",
                "label_fr": "Melksham"
            },
            {
                "value": 56539,
                "label": "Great Yarmouth",
                "label_ar": "يارموث العظيم",
                "label_fr": "Great Yarmouth"
            },
            {
                "value": 55881,
                "label": "Erdington",
                "label_ar": "اردينجتون",
                "label_fr": "Erdington"
            },
            {
                "value": 58052,
                "label": "Fowey",
                "label_ar": "فوي",
                "label_fr": "Fowey"
            },
            {
                "value": 58257,
                "label": "West Clandon",
                "label_ar": "ويست كلاندون",
                "label_fr": "West Clandon"
            },
            {
                "value": 55955,
                "label": "Loughborough",
                "label_ar": "لوبورو",
                "label_fr": "Loughborough"
            },
            {
                "value": 58181,
                "label": "Ashen",
                "label_ar": "أشين",
                "label_fr": "Cendré"
            },
            {
                "value": 57793,
                "label": "Bletchingley",
                "label_ar": "بلتشينجلي",
                "label_fr": "Bletchingley"
            },
            {
                "value": 56801,
                "label": "Invergordon",
                "label_ar": "إنفيرجوردون",
                "label_fr": "Invergordon"
            },
            {
                "value": 57434,
                "label": "Aberchirder",
                "label_ar": "أبيرشيردر",
                "label_fr": "Aberchirder"
            },
            {
                "value": 56381,
                "label": "Harrogate",
                "label_ar": "هاروغيت",
                "label_fr": "Harrogate"
            },
            {
                "value": 56304,
                "label": "Cradley",
                "label_ar": "كرادلي",
                "label_fr": "Cradley"
            },
            {
                "value": 57036,
                "label": "Yelverton",
                "label_ar": "يلفرتون",
                "label_fr": "Yelverton"
            },
            {
                "value": 56089,
                "label": "Leigh-on-Sea",
                "label_ar": "ليه على البحر",
                "label_fr": "Leigh-on-Sea"
            },
            {
                "value": 56142,
                "label": "Corby",
                "label_ar": "كوربي",
                "label_fr": "Corby"
            },
            {
                "value": 55904,
                "label": "St Austell",
                "label_ar": "سانت أوستل",
                "label_fr": "St Austell"
            },
            {
                "value": 58406,
                "label": "Cambusnethan",
                "label_ar": "كامبوسنتان",
                "label_fr": "Cambusnethan"
            },
            {
                "value": 56821,
                "label": "Kirkintilloch",
                "label_ar": "كيركنتيلوش",
                "label_fr": "Kirkintilloch"
            },
            {
                "value": 58319,
                "label": "Martin",
                "label_ar": "مارتن",
                "label_fr": "Martin"
            },
            {
                "value": 57395,
                "label": "Desborough",
                "label_ar": "ديسبورو",
                "label_fr": "Desborough"
            },
            {
                "value": 57521,
                "label": "Dyserth",
                "label_ar": "ديسيرث",
                "label_fr": "Dyserth"
            },
            {
                "value": 58036,
                "label": "Ramsey Saint Marys",
                "label_ar": "رامسي سانت ماريز",
                "label_fr": "Ramsey Saint Marys"
            },
            {
                "value": 58434,
                "label": "Caxton",
                "label_ar": "كاكستون",
                "label_fr": "Caxton"
            },
            {
                "value": 58425,
                "label": "Pontardawe",
                "label_ar": "بونتارداوي",
                "label_fr": "Pontardawe"
            },
            {
                "value": 58698,
                "label": "Llandrillo",
                "label_ar": "لاندريلو",
                "label_fr": "Llandrillo"
            },
            {
                "value": 58842,
                "label": "Criccieth",
                "label_ar": "كريسيث",
                "label_fr": "Criccieth"
            },
            {
                "value": 57706,
                "label": "Houston",
                "label_ar": "هيوستن",
                "label_fr": "Houston"
            },
            {
                "value": 56489,
                "label": "Ashford",
                "label_ar": "أشفورد",
                "label_fr": "Ashford"
            },
            {
                "value": 57470,
                "label": "Westbury-sub-Mendip",
                "label_ar": "ويستبري الفرعية مينديب",
                "label_fr": "Westbury-sous-Mendip"
            },
            {
                "value": 58634,
                "label": "Rogerstone",
                "label_ar": "روجرستون",
                "label_fr": "Rogerstone"
            },
            {
                "value": 58399,
                "label": "Ewhurst",
                "label_ar": "اهورست",
                "label_fr": "Ewhurst"
            },
            {
                "value": 58311,
                "label": "Clayton West",
                "label_ar": "كلايتون ويست",
                "label_fr": "Clayton West"
            },
            {
                "value": 58391,
                "label": "Kneeton",
                "label_ar": "كنيتون",
                "label_fr": "Kneeton"
            },
            {
                "value": 58473,
                "label": "Elmley Lovett",
                "label_ar": "إلملي لوفيت",
                "label_fr": "Elmley Lovett"
            },
            {
                "value": 56397,
                "label": "Winchester",
                "label_ar": "وينشستر",
                "label_fr": "Winchester"
            },
            {
                "value": 57647,
                "label": "Dumbleton",
                "label_ar": "دمبلتون",
                "label_fr": "Dumbleton"
            },
            {
                "value": 57722,
                "label": "Much Hoole",
                "label_ar": "الكثير هول",
                "label_fr": "Beaucoup Hoole"
            },
            {
                "value": 57860,
                "label": "Kirkby Stephen",
                "label_ar": "كيركبي ستيفن",
                "label_fr": "Kirkby Stephen"
            },
            {
                "value": 58232,
                "label": "Brockenhurst",
                "label_ar": "بروكنهورست",
                "label_fr": "Brockenhurst"
            },
            {
                "value": 58006,
                "label": "Benburb",
                "label_ar": "بنرب",
                "label_fr": "Benburb"
            },
            {
                "value": 57628,
                "label": "Auchtermuchty",
                "label_ar": "اوكترموشتي",
                "label_fr": "Auchtermuchty"
            },
            {
                "value": 57460,
                "label": "Saxmundham",
                "label_ar": "ساكسوندهام",
                "label_fr": "Saxmundham"
            },
            {
                "value": 57329,
                "label": "Slinfold",
                "label_ar": "سلينفولد",
                "label_fr": "Slinfold"
            },
            {
                "value": 58724,
                "label": "Hambleden",
                "label_ar": "هامبلدن",
                "label_fr": "Hambleden"
            },
            {
                "value": 55882,
                "label": "Halesowen",
                "label_ar": "هالسووين",
                "label_fr": "Halesowen"
            },
            {
                "value": 57479,
                "label": "Bedminster",
                "label_ar": "بيدمينستر",
                "label_fr": "Bedminster"
            },
            {
                "value": 58044,
                "label": "Wing",
                "label_ar": "جناح",
                "label_fr": "Aile"
            },
            {
                "value": 58828,
                "label": "Ash",
                "label_ar": "رماد",
                "label_fr": "Cendre"
            },
            {
                "value": 56090,
                "label": "Loughton",
                "label_ar": "لوتون",
                "label_fr": "Loughton"
            },
            {
                "value": 55872,
                "label": "Oldbury",
                "label_ar": "أولدبيري",
                "label_fr": "Oldbury"
            },
            {
                "value": 58891,
                "label": "Withington",
                "label_ar": "ويثينجتون",
                "label_fr": "Withington"
            },
            {
                "value": 56191,
                "label": "Chester-le-Street",
                "label_ar": "تشيستر لو ستريت",
                "label_fr": "Chester-le-Rue"
            },
            {
                "value": 56543,
                "label": "Washington",
                "label_ar": "واشنطن",
                "label_fr": "Washington"
            },
            {
                "value": 56387,
                "label": "Buckley",
                "label_ar": "باكلي",
                "label_fr": "Buckley"
            },
            {
                "value": 57154,
                "label": "Seale",
                "label_ar": "سيل",
                "label_fr": "Seale"
            },
            {
                "value": 56585,
                "label": "Linlithgow",
                "label_ar": "لينليثجو",
                "label_fr": "Linlithgow"
            },
            {
                "value": 57111,
                "label": "Letchworth",
                "label_ar": "ليتشوورث",
                "label_fr": "Letchworth"
            },
            {
                "value": 55994,
                "label": "Southsea",
                "label_ar": "البحر الجنوبي",
                "label_fr": "Mer du Sud"
            },
            {
                "value": 56170,
                "label": "Durham",
                "label_ar": "دورهام",
                "label_fr": "Durham"
            },
            {
                "value": 56975,
                "label": "Budleigh Salterton",
                "label_ar": "بودلي سالترتون",
                "label_fr": "Budleigh Salterton"
            },
            {
                "value": 58612,
                "label": "Plumstead",
                "label_ar": "بلومستيد",
                "label_fr": "Plumstead"
            },
            {
                "value": 57979,
                "label": "Mollington",
                "label_ar": "مولينجتون",
                "label_fr": "Mollington"
            },
            {
                "value": 57158,
                "label": "Bawtry",
                "label_ar": "بوترى",
                "label_fr": "Bawtry"
            },
            {
                "value": 58493,
                "label": "Brigstock",
                "label_ar": "بريجستوك",
                "label_fr": "Brigstock"
            },
            {
                "value": 57551,
                "label": "Pencader",
                "label_ar": "بينكادير",
                "label_fr": "Pencader"
            },
            {
                "value": 55867,
                "label": "Gloucester",
                "label_ar": "جلوستر",
                "label_fr": "Gloucester"
            },
            {
                "value": 56335,
                "label": "Seaton",
                "label_ar": "سيتون",
                "label_fr": "Seaton"
            },
            {
                "value": 57756,
                "label": "Thorrington",
                "label_ar": "ثورينجتون",
                "label_fr": "Thorrington"
            },
            {
                "value": 58204,
                "label": "Wroxall",
                "label_ar": "روكسال",
                "label_fr": "Wroxall"
            },
            {
                "value": 58677,
                "label": "Hooke",
                "label_ar": "هوك",
                "label_fr": "Hooke"
            },
            {
                "value": 56870,
                "label": "Roydon",
                "label_ar": "رويدون",
                "label_fr": "Roydon"
            },
            {
                "value": 56525,
                "label": "Kingston",
                "label_ar": "كينغستون",
                "label_fr": "Kingston"
            },
            {
                "value": 57670,
                "label": "Lowton",
                "label_ar": "لوتون",
                "label_fr": "Lowton"
            },
            {
                "value": 56194,
                "label": "Consett",
                "label_ar": "كونسيت",
                "label_fr": "Consett"
            },
            {
                "value": 58648,
                "label": "Bayble",
                "label_ar": "بايل",
                "label_fr": "Bayble"
            },
            {
                "value": 58081,
                "label": "Bramford",
                "label_ar": "برامفورد",
                "label_fr": "Bramford"
            },
            {
                "value": 57696,
                "label": "Norwood",
                "label_ar": "نوروود",
                "label_fr": "Norwood"
            },
            {
                "value": 56479,
                "label": "Godalming",
                "label_ar": "جودالمينج",
                "label_fr": "Godalming"
            },
            {
                "value": 55951,
                "label": "Ilkeston",
                "label_ar": "إلكستون",
                "label_fr": "Ilkeston"
            },
            {
                "value": 56023,
                "label": "Musselburgh",
                "label_ar": "موسيلبرج",
                "label_fr": "Musselburgh"
            },
            {
                "value": 58594,
                "label": "Appleton",
                "label_ar": "أبليتون",
                "label_fr": "Appleton"
            },
            {
                "value": 57489,
                "label": "Southwater",
                "label_ar": "ساوث ووتر",
                "label_fr": "Southwater"
            },
            {
                "value": 57663,
                "label": "Waterbeach",
                "label_ar": "Waterbeach",
                "label_fr": "Waterbeach"
            },
            {
                "value": 57466,
                "label": "Bishop's Castle",
                "label_ar": "قلعة المطران",
                "label_fr": "Château de l'évêque"
            },
            {
                "value": 57603,
                "label": "Leiston",
                "label_ar": "ليستون",
                "label_fr": "Leiston"
            },
            {
                "value": 57098,
                "label": "Dartmouth",
                "label_ar": "دارتموث",
                "label_fr": "Dartmouth"
            },
            {
                "value": 55972,
                "label": "Kennoway",
                "label_ar": "Kennoway",
                "label_fr": "Kennoway"
            },
            {
                "value": 56454,
                "label": "Swindon Village",
                "label_ar": "قرية سويندون",
                "label_fr": "Village de Swindon"
            },
            {
                "value": 58318,
                "label": "Brook",
                "label_ar": "بروك",
                "label_fr": "Ruisseau"
            },
            {
                "value": 57604,
                "label": "Gosfield",
                "label_ar": "جوسفيلد",
                "label_fr": "Gosfield"
            },
            {
                "value": 56116,
                "label": "Acton",
                "label_ar": "العمل على",
                "label_fr": "Agir sur"
            },
            {
                "value": 56786,
                "label": "Walton on the Hill",
                "label_ar": "والتون أون ذا هيل",
                "label_fr": "Walton sur la colline"
            },
            {
                "value": 57873,
                "label": "Yoxford",
                "label_ar": "يوكسفورد",
                "label_fr": "Yoxford"
            },
            {
                "value": 58505,
                "label": "Loppington",
                "label_ar": "لوبينغتون",
                "label_fr": "Loppington"
            },
            {
                "value": 57577,
                "label": "Greensted",
                "label_ar": "جرينستيد",
                "label_fr": "Greensted"
            },
            {
                "value": 56797,
                "label": "Sedgefield",
                "label_ar": "سيدجفيلد",
                "label_fr": "Sedgefield"
            },
            {
                "value": 58254,
                "label": "Bordesley",
                "label_ar": "بوردسلي",
                "label_fr": "Bordesley"
            },
            {
                "value": 57199,
                "label": "Waltham Cross",
                "label_ar": "والثام كروس",
                "label_fr": "Waltham Cross"
            },
            {
                "value": 56579,
                "label": "Caernarfon",
                "label_ar": "كارنارفون",
                "label_fr": "Caernarfon"
            },
            {
                "value": 57250,
                "label": "Moreton in Marsh",
                "label_ar": "موريتون في مارش",
                "label_fr": "Moreton dans Marsh"
            },
            {
                "value": 58686,
                "label": "Newham",
                "label_ar": "نيوهام",
                "label_fr": "Newham"
            },
            {
                "value": 57627,
                "label": "Kirkby",
                "label_ar": "كيركبي",
                "label_fr": "Kirkby"
            },
            {
                "value": 58857,
                "label": "Woburn Sands",
                "label_ar": "وبرن ساندز",
                "label_fr": "Woburn Sands"
            },
            {
                "value": 57621,
                "label": "Great Ryburgh",
                "label_ar": "ريبيرج الكبرى",
                "label_fr": "Grand Ryburgh"
            },
            {
                "value": 55864,
                "label": "Smethwick",
                "label_ar": "سميثويك",
                "label_fr": "Smethwick"
            },
            {
                "value": 57415,
                "label": "Radcliffe",
                "label_ar": "رادكليف",
                "label_fr": "Radcliffe"
            },
            {
                "value": 58811,
                "label": "Highway",
                "label_ar": "الطريق السريع",
                "label_fr": "Autoroute"
            },
            {
                "value": 56470,
                "label": "Shefford",
                "label_ar": "شيفورد",
                "label_fr": "Shefford"
            },
            {
                "value": 55925,
                "label": "Carmarthen",
                "label_ar": "كارمارثين",
                "label_fr": "Carmarthen"
            },
            {
                "value": 57659,
                "label": "Clipstone",
                "label_ar": "Clipstone",
                "label_fr": "Clipstone"
            },
            {
                "value": 56096,
                "label": "Romford",
                "label_ar": "رومفورد",
                "label_fr": "Romford"
            },
            {
                "value": 56527,
                "label": "Bracknell",
                "label_ar": "براكنيل",
                "label_fr": "Bracknell"
            },
            {
                "value": 57113,
                "label": "New Ferry",
                "label_ar": "نيو فيري",
                "label_fr": "Nouveau ferry"
            },
            {
                "value": 57263,
                "label": "Farnham Royal",
                "label_ar": "فارنام رويال",
                "label_fr": "Farnham Royal"
            },
            {
                "value": 56892,
                "label": "Meldreth",
                "label_ar": "ميلدريث",
                "label_fr": "Meldreth"
            },
            {
                "value": 57501,
                "label": "Little Kimble",
                "label_ar": "ليتل كيمبل",
                "label_fr": "Petit Kimble"
            },
            {
                "value": 56586,
                "label": "Kirkcaldy",
                "label_ar": "كيركالدي",
                "label_fr": "Kirkcaldy"
            },
            {
                "value": 57757,
                "label": "Burnham on Crouch",
                "label_ar": "بورنهام على كراوتش",
                "label_fr": "Burnham sur Crouch"
            },
            {
                "value": 57597,
                "label": "Bridgham",
                "label_ar": "بريدغام",
                "label_fr": "Bridgham"
            },
            {
                "value": 57483,
                "label": "Brixham",
                "label_ar": "بريكسهام",
                "label_fr": "Brixham"
            },
            {
                "value": 57646,
                "label": "Wilburton",
                "label_ar": "ويلبرتون",
                "label_fr": "Wilburton"
            },
            {
                "value": 56804,
                "label": "Johnstone",
                "label_ar": "جونستون",
                "label_fr": "Johnstone"
            },
            {
                "value": 56994,
                "label": "Portrush",
                "label_ar": "بورتراش",
                "label_fr": "Portrush"
            },
            {
                "value": 56684,
                "label": "West Bromwich",
                "label_ar": "وست بروميتش",
                "label_fr": "West Bromwich"
            },
            {
                "value": 57540,
                "label": "Oare",
                "label_ar": "أواري",
                "label_fr": "Oare"
            },
            {
                "value": 56180,
                "label": "Whitley Bay",
                "label_ar": "وايتلي باي",
                "label_fr": "Whitley Bay"
            },
            {
                "value": 56208,
                "label": "Long Melford",
                "label_ar": "لونغ ميلفورد",
                "label_fr": "Long Melford"
            },
            {
                "value": 57410,
                "label": "Jedburgh",
                "label_ar": "جيدبورغ",
                "label_fr": "Jedburgh"
            },
            {
                "value": 57208,
                "label": "Standlake",
                "label_ar": "Standlake",
                "label_fr": "Standlake"
            },
            {
                "value": 56903,
                "label": "Pickering",
                "label_ar": "بيكرينغ",
                "label_fr": "Pickering"
            },
            {
                "value": 57127,
                "label": "Croxley Green",
                "label_ar": "كروكسلي جرين",
                "label_fr": "Croxley vert"
            },
            {
                "value": 58349,
                "label": "Merthyr Mawr",
                "label_ar": "مرثير ماور",
                "label_fr": "Merthyr Mawr"
            },
            {
                "value": 58347,
                "label": "Ystalyfera",
                "label_ar": "يستلفيرا",
                "label_fr": "Ystalyfera"
            },
            {
                "value": 56163,
                "label": "Wallasey",
                "label_ar": "والاسي",
                "label_fr": "Wallasey"
            },
            {
                "value": 57082,
                "label": "High Peak Junction",
                "label_ar": "تقاطع الذروة العالية",
                "label_fr": "Jonction de pic élevé"
            },
            {
                "value": 56114,
                "label": "Worcester Park",
                "label_ar": "وورسيستر بارك",
                "label_fr": "Parc Worcester"
            },
            {
                "value": 58721,
                "label": "Chilwell",
                "label_ar": "شيلويل",
                "label_fr": "Chilwell"
            },
            {
                "value": 56647,
                "label": "Risca",
                "label_ar": "ريسكا",
                "label_fr": "Risca"
            },
            {
                "value": 57385,
                "label": "Rothwell",
                "label_ar": "روثويل",
                "label_fr": "Rothwell"
            },
            {
                "value": 57276,
                "label": "Steyning",
                "label_ar": "ستينينج",
                "label_fr": "Steyning"
            },
            {
                "value": 55876,
                "label": "Redditch",
                "label_ar": "ريديتش",
                "label_fr": "Redditch"
            },
            {
                "value": 58740,
                "label": "Humbie",
                "label_ar": "هومبي",
                "label_fr": "Humbie"
            },
            {
                "value": 56248,
                "label": "Killamarsh",
                "label_ar": "كيلامارش",
                "label_fr": "Killamarsh"
            },
            {
                "value": 55930,
                "label": "Rhondda",
                "label_ar": "روندا",
                "label_fr": "Rhondda"
            },
            {
                "value": 57124,
                "label": "Stansted",
                "label_ar": "ستانستيد",
                "label_fr": "Stansted"
            },
            {
                "value": 56310,
                "label": "Burntwood",
                "label_ar": "بيرنتوود",
                "label_fr": "Bois brûlé"
            },
            {
                "value": 56831,
                "label": "Royston",
                "label_ar": "رويستون",
                "label_fr": "Royston"
            },
            {
                "value": 56041,
                "label": "Uckfield",
                "label_ar": "أوكفيلد",
                "label_fr": "Uckfield"
            },
            {
                "value": 57634,
                "label": "Birnam",
                "label_ar": "بيرنام",
                "label_fr": "Birnam"
            },
            {
                "value": 57847,
                "label": "Welwyn",
                "label_ar": "ويلوين",
                "label_fr": "Welwyn"
            },
            {
                "value": 56393,
                "label": "Herne Bay",
                "label_ar": "هيرن باي",
                "label_fr": "Herne Bay"
            },
            {
                "value": 58629,
                "label": "Dalbeattie",
                "label_ar": "Dalbeattie",
                "label_fr": "Dalbeattie"
            },
            {
                "value": 58486,
                "label": "Willingale Doe",
                "label_ar": "Willingale Doe",
                "label_fr": "Willingale Doe"
            },
            {
                "value": 55886,
                "label": "Sutton Coldfield",
                "label_ar": "ساتون كولدفيلد",
                "label_fr": "Sutton Coldfield"
            },
            {
                "value": 57959,
                "label": "Portmadoc",
                "label_ar": "بورتمادوك",
                "label_fr": "Portmadoc"
            },
            {
                "value": 57255,
                "label": "Winnington",
                "label_ar": "وينينجتون",
                "label_fr": "Winnington"
            },
            {
                "value": 57449,
                "label": "Gunnislake",
                "label_ar": "Gunnislake",
                "label_fr": "Gunnislake"
            },
            {
                "value": 56825,
                "label": "Bridgemary",
                "label_ar": "بريدجماري",
                "label_fr": "Bridgemary"
            },
            {
                "value": 57224,
                "label": "Swanscombe",
                "label_ar": "سوانسكومب",
                "label_fr": "Swanscombe"
            },
            {
                "value": 56818,
                "label": "Abergele",
                "label_ar": "أبيرجيل",
                "label_fr": "Abergele"
            },
            {
                "value": 57972,
                "label": "Dornoch",
                "label_ar": "دورنوك",
                "label_fr": "Dornoch"
            },
            {
                "value": 57281,
                "label": "Tewin",
                "label_ar": "توين",
                "label_fr": "Tewin"
            },
            {
                "value": 57397,
                "label": "Fitzwilliam",
                "label_ar": "فيتزويليام",
                "label_fr": "Fitzwilliam"
            },
            {
                "value": 58590,
                "label": "Kingswood",
                "label_ar": "كينجسوود",
                "label_fr": "Kingswood"
            },
            {
                "value": 56575,
                "label": "Montrose",
                "label_ar": "مونتروز",
                "label_fr": "Montrose"
            },
            {
                "value": 58782,
                "label": "Stowe",
                "label_ar": "ستو",
                "label_fr": "Stowe"
            },
            {
                "value": 58706,
                "label": "Tendring",
                "label_ar": "تندرينج",
                "label_fr": "Tendring"
            },
            {
                "value": 58316,
                "label": "Bramhall",
                "label_ar": "برامهول",
                "label_fr": "Bramhall"
            },
            {
                "value": 58013,
                "label": "Castlederg",
                "label_ar": "كاسلدير",
                "label_fr": "Castlederg"
            },
            {
                "value": 57884,
                "label": "East Bergholt",
                "label_ar": "شرق بيرغولت",
                "label_fr": "East Bergholt"
            },
            {
                "value": 55863,
                "label": "Worcester",
                "label_ar": "ورسستر",
                "label_fr": "Worcester"
            },
            {
                "value": 56001,
                "label": "Reading",
                "label_ar": "قراءة",
                "label_fr": "En train de lire"
            },
            {
                "value": 58338,
                "label": "Whitwell",
                "label_ar": "ويتويل",
                "label_fr": "Whitwell"
            },
            {
                "value": 56861,
                "label": "Sherborne",
                "label_ar": "شيربورن",
                "label_fr": "Sherborne"
            },
            {
                "value": 57367,
                "label": "Pinxton",
                "label_ar": "بينكستون",
                "label_fr": "Pinxton"
            },
            {
                "value": 58520,
                "label": "Mundesley",
                "label_ar": "مونديسلي",
                "label_fr": "Mundesley"
            },
            {
                "value": 57921,
                "label": "Prestwich",
                "label_ar": "بريستويتش",
                "label_fr": "Prestwich"
            },
            {
                "value": 58657,
                "label": "West Row",
                "label_ar": "ويست رو",
                "label_fr": "West Row"
            },
            {
                "value": 57690,
                "label": "Crayford",
                "label_ar": "كرايفورد",
                "label_fr": "Crayford"
            },
            {
                "value": 56329,
                "label": "Ilminster",
                "label_ar": "إيلمينستر",
                "label_fr": "Ilminster"
            },
            {
                "value": 57907,
                "label": "Crosby",
                "label_ar": "كروسبي",
                "label_fr": "Crosby"
            },
            {
                "value": 58166,
                "label": "Newbridge",
                "label_ar": "جسر جديد",
                "label_fr": "Nouveau pont"
            },
            {
                "value": 58609,
                "label": "Stockton",
                "label_ar": "ستوكتون",
                "label_fr": "Stockton"
            },
            {
                "value": 56676,
                "label": "Keith",
                "label_ar": "كيث",
                "label_fr": "Keith"
            },
            {
                "value": 56701,
                "label": "Fraserburgh",
                "label_ar": "فرازربورج",
                "label_fr": "Fraserburgh"
            },
            {
                "value": 55995,
                "label": "Westerham",
                "label_ar": "ويسترهام",
                "label_fr": "Westerham"
            },
            {
                "value": 58366,
                "label": "Askern",
                "label_ar": "اسكيرن",
                "label_fr": "Askern"
            },
            {
                "value": 57326,
                "label": "Fillongley",
                "label_ar": "فيلونجلي",
                "label_fr": "Fillongley"
            },
            {
                "value": 57829,
                "label": "Blaenavon",
                "label_ar": "بلينافون",
                "label_fr": "Blaenavon"
            },
            {
                "value": 55862,
                "label": "Birmingham",
                "label_ar": "برمنغهام",
                "label_fr": "Birmingham"
            },
            {
                "value": 55860,
                "label": "Rowley Regis",
                "label_ar": "رولي ريجيس",
                "label_fr": "Rowley Regis"
            },
            {
                "value": 58172,
                "label": "Kingham",
                "label_ar": "كينغهام",
                "label_fr": "Kingham"
            },
            {
                "value": 57568,
                "label": "Port Erroll",
                "label_ar": "ميناء إرول",
                "label_fr": "Erroll de port"
            },
            {
                "value": 57359,
                "label": "Auchterarder",
                "label_ar": "أوشتراردير",
                "label_fr": "Auchterarder"
            },
            {
                "value": 58416,
                "label": "Otterburn",
                "label_ar": "أوتربيرن",
                "label_fr": "Otterburn"
            },
            {
                "value": 56565,
                "label": "Thetford",
                "label_ar": "ثيتفورد",
                "label_fr": "Thetford"
            },
            {
                "value": 58373,
                "label": "Colne",
                "label_ar": "كولن",
                "label_fr": "Colne"
            },
            {
                "value": 56922,
                "label": "Hillsborough",
                "label_ar": "هيلزبورو",
                "label_fr": "Hillsborough"
            },
            {
                "value": 57794,
                "label": "Balham",
                "label_ar": "بلهام",
                "label_fr": "Balham"
            },
            {
                "value": 58295,
                "label": "Risley",
                "label_ar": "ريسلي",
                "label_fr": "Risley"
            },
            {
                "value": 58816,
                "label": "Killin",
                "label_ar": "قتل",
                "label_fr": "Killin"
            },
            {
                "value": 58681,
                "label": "Ballybogy",
                "label_ar": "باليبوجي",
                "label_fr": "Ballybogy"
            },
            {
                "value": 58259,
                "label": "Linton",
                "label_ar": "لينتون",
                "label_fr": "Linton"
            },
            {
                "value": 57948,
                "label": "Lydbury North",
                "label_ar": "ليدبوري نورث",
                "label_fr": "Lydbury Nord"
            },
            {
                "value": 58414,
                "label": "Rawmarsh",
                "label_ar": "Rawmarsh",
                "label_fr": "Rawmarsh"
            },
            {
                "value": 57471,
                "label": "Dolwyddelan",
                "label_ar": "Dolwyddelan",
                "label_fr": "Dolwyddelan"
            },
            {
                "value": 56597,
                "label": "Cowdenbeath",
                "label_ar": "كاودنبيث",
                "label_fr": "Cowdenbeath"
            },
            {
                "value": 58874,
                "label": "Goudhurst",
                "label_ar": "جودهيرست",
                "label_fr": "Goudhurst"
            },
            {
                "value": 56641,
                "label": "Gosport",
                "label_ar": "اذهب للمارسة الرياضه",
                "label_fr": "Aller au sport"
            },
            {
                "value": 58568,
                "label": "Roche",
                "label_ar": "روش",
                "label_fr": "Roche"
            },
            {
                "value": 56052,
                "label": "Hassocks",
                "label_ar": "هاسوكس",
                "label_fr": "Hassocks"
            },
            {
                "value": 58606,
                "label": "Monyash",
                "label_ar": "مونياش",
                "label_fr": "Monyash"
            },
            {
                "value": 58727,
                "label": "Charlton on Otmoor",
                "label_ar": "تشارلتون في Otmoor",
                "label_fr": "Charlton sur Otmoor"
            },
            {
                "value": 58472,
                "label": "Higham on the Hill",
                "label_ar": "هيغام على التل",
                "label_fr": "Higham sur la colline"
            },
            {
                "value": 57553,
                "label": "Fressingfield",
                "label_ar": "فريسينجفيلد",
                "label_fr": "Fressingfield"
            },
            {
                "value": 58521,
                "label": "Sutton on Hull",
                "label_ar": "ساتون أون هال",
                "label_fr": "Sutton sur Hull"
            },
            {
                "value": 57450,
                "label": "Saint Agnes",
                "label_ar": "سانت أغنيس",
                "label_fr": "Sainte Agnès"
            },
            {
                "value": 56183,
                "label": "Rowlands Gill",
                "label_ar": "رولاندز جيل",
                "label_fr": "Rowlands Gill"
            },
            {
                "value": 56164,
                "label": "Mochdre",
                "label_ar": "Mochdre",
                "label_fr": "Mochdre"
            },
            {
                "value": 56045,
                "label": "Henfield",
                "label_ar": "هينفيلد",
                "label_fr": "Henfield"
            },
            {
                "value": 56431,
                "label": "Broxbourne",
                "label_ar": "بروكسبورن",
                "label_fr": "Broxbourne"
            },
            {
                "value": 57538,
                "label": "Cobham",
                "label_ar": "كوبهام",
                "label_fr": "Cobham"
            },
            {
                "value": 58667,
                "label": "West Drayton",
                "label_ar": "ويست درايتون",
                "label_fr": "West Drayton"
            },
            {
                "value": 57214,
                "label": "Hedon",
                "label_ar": "انه دون",
                "label_fr": "Hedon"
            },
            {
                "value": 57014,
                "label": "Stokesley",
                "label_ar": "ستوكسلي",
                "label_fr": "Stokesley"
            },
            {
                "value": 58187,
                "label": "Elsworth",
                "label_ar": "إلسورث",
                "label_fr": "Elsworth"
            },
            {
                "value": 58424,
                "label": "South Hetton",
                "label_ar": "جنوب هيتون",
                "label_fr": "South Hetton"
            },
            {
                "value": 56422,
                "label": "Penzance",
                "label_ar": "بينزانس",
                "label_fr": "Penzance"
            },
            {
                "value": 57189,
                "label": "Cheddar",
                "label_ar": "شيدر",
                "label_fr": "Cheddar"
            },
            {
                "value": 57674,
                "label": "Denton",
                "label_ar": "دنتون",
                "label_fr": "Denton"
            },
            {
                "value": 56607,
                "label": "Brough",
                "label_ar": "برو",
                "label_fr": "Brough"
            },
            {
                "value": 58037,
                "label": "Wirksworth",
                "label_ar": "ويركسورث",
                "label_fr": "Wirksworth"
            },
            {
                "value": 56756,
                "label": "Hull",
                "label_ar": "هال",
                "label_fr": "coque"
            },
            {
                "value": 55905,
                "label": "Trowbridge",
                "label_ar": "تروبريدج",
                "label_fr": "Trowbridge"
            },
            {
                "value": 57747,
                "label": "Waddesdon",
                "label_ar": "وادسدون",
                "label_fr": "Waddesdon"
            },
            {
                "value": 57136,
                "label": "Wareham",
                "label_ar": "ويرهام",
                "label_fr": "Wareham"
            },
            {
                "value": 56148,
                "label": "Oldham",
                "label_ar": "أولدهام",
                "label_fr": "Oldham"
            },
            {
                "value": 56382,
                "label": "Wath upon Dearne",
                "label_ar": "واث أبون ديرن",
                "label_fr": "Wath sur Dearne"
            },
            {
                "value": 56540,
                "label": "Liversedge",
                "label_ar": "ليفيرسيدج",
                "label_fr": "Liversedge"
            },
            {
                "value": 58085,
                "label": "Keresley",
                "label_ar": "كيريسلي",
                "label_fr": "Keresley"
            },
            {
                "value": 56924,
                "label": "Thirsk",
                "label_ar": "ثيرسك",
                "label_fr": "Thirsk"
            },
            {
                "value": 58020,
                "label": "Banham",
                "label_ar": "بنهام",
                "label_fr": "Banham"
            },
            {
                "value": 57004,
                "label": "Brechin",
                "label_ar": "بريشين",
                "label_fr": "Brechin"
            },
            {
                "value": 56828,
                "label": "Portchester",
                "label_ar": "بورتشيستر",
                "label_fr": "Portchester"
            },
            {
                "value": 58252,
                "label": "Aldridge",
                "label_ar": "الدريدج",
                "label_fr": "Aldridge"
            },
            {
                "value": 56450,
                "label": "Leighton Buzzard",
                "label_ar": "لايتون بازارد",
                "label_fr": "Leighton Buzzard"
            },
            {
                "value": 56359,
                "label": "Blyth",
                "label_ar": "بليث",
                "label_fr": "Blyth"
            },
            {
                "value": 58481,
                "label": "West Rudham",
                "label_ar": "ويست رودهام",
                "label_fr": "West Rudham"
            },
            {
                "value": 57455,
                "label": "Normandy",
                "label_ar": "نورماندي",
                "label_fr": "la Normandie"
            },
            {
                "value": 56263,
                "label": "Kingsbridge",
                "label_ar": "جسر الملوك",
                "label_fr": "Kingsbridge"
            },
            {
                "value": 57210,
                "label": "Takeley",
                "label_ar": "تاكيلي",
                "label_fr": "Takeley"
            },
            {
                "value": 56427,
                "label": "Royal Leamington Spa",
                "label_ar": "رويال ليمنجتون سبا",
                "label_fr": "Spa Royal Leamington"
            },
            {
                "value": 56331,
                "label": "Barnstaple",
                "label_ar": "بارنستابل",
                "label_fr": "Barnstaple"
            },
            {
                "value": 58808,
                "label": "Wednesfield",
                "label_ar": "الأربعاءفيلد",
                "label_fr": "Wednesdayfield"
            },
            {
                "value": 56642,
                "label": "Gorleston-on-Sea",
                "label_ar": "جورليستون أون سي",
                "label_fr": "Gorleston-on-Sea"
            },
            {
                "value": 55875,
                "label": "Cheltenham",
                "label_ar": "شلتنهام",
                "label_fr": "Cheltenham"
            },
            {
                "value": 56457,
                "label": "Burntisland",
                "label_ar": "بيرنتيسلاند",
                "label_fr": "Burntisland"
            },
            {
                "value": 56812,
                "label": "Oswaldtwistle",
                "label_ar": "أوزوالدتويستل",
                "label_fr": "Oswaldtwistle"
            },
            {
                "value": 58737,
                "label": "Bournville",
                "label_ar": "بورنفيل",
                "label_fr": "Bournville"
            },
            {
                "value": 56076,
                "label": "Wetherby",
                "label_ar": "ويثربى",
                "label_fr": "Wetherby"
            },
            {
                "value": 57454,
                "label": "West Kilbride",
                "label_ar": "ويست كيلبرايد",
                "label_fr": "West Kilbride"
            },
            {
                "value": 56360,
                "label": "Galashiels",
                "label_ar": "جالاشيلس",
                "label_fr": "Galashiels"
            },
            {
                "value": 57442,
                "label": "Unstone",
                "label_ar": "تفكك",
                "label_fr": "Démonter"
            },
            {
                "value": 57393,
                "label": "Shepshed",
                "label_ar": "شيبشد",
                "label_fr": "Shepshed"
            },
            {
                "value": 57451,
                "label": "Easington",
                "label_ar": "إيزينغتون",
                "label_fr": "Easington"
            },
            {
                "value": 58077,
                "label": "Lambeth",
                "label_ar": "لامبث",
                "label_fr": "Lambeth"
            },
            {
                "value": 58769,
                "label": "Scapa",
                "label_ar": "سكابا",
                "label_fr": "Scapa"
            },
            {
                "value": 56475,
                "label": "Harlow",
                "label_ar": "هارلو",
                "label_fr": "Harlow"
            },
            {
                "value": 57992,
                "label": "Westhay",
                "label_ar": "Westhay",
                "label_fr": "Westhay"
            },
            {
                "value": 57104,
                "label": "Knebworth",
                "label_ar": "نيبورث",
                "label_fr": "Knebworth"
            },
            {
                "value": 58764,
                "label": "Reepham",
                "label_ar": "ريبهام",
                "label_fr": "Reepham"
            },
            {
                "value": 58815,
                "label": "Kyle of Lochalsh",
                "label_ar": "كايل من لوتشالش",
                "label_fr": "Kyle de Lochalsh"
            },
            {
                "value": 56502,
                "label": "Stone",
                "label_ar": "حصاة",
                "label_fr": "Calcul"
            },
            {
                "value": 57206,
                "label": "Wales",
                "label_ar": "ويلز",
                "label_fr": "Pays de Galles"
            },
            {
                "value": 58429,
                "label": "Partington",
                "label_ar": "بارتينجتون",
                "label_fr": "Partington"
            },
            {
                "value": 56516,
                "label": "Hamble",
                "label_ar": "ذليل",
                "label_fr": "Hamble"
            },
            {
                "value": 57056,
                "label": "Sheringham",
                "label_ar": "شيرينغهام",
                "label_fr": "Sheringham"
            },
            {
                "value": 58049,
                "label": "Anstey",
                "label_ar": "انستي",
                "label_fr": "Anstey"
            },
            {
                "value": 58120,
                "label": "Eccleshall",
                "label_ar": "اكليسهول",
                "label_fr": "Eccleshall"
            },
            {
                "value": 58263,
                "label": "Cross in Hand",
                "label_ar": "عبور في متناول اليد",
                "label_fr": "Croix en main"
            },
            {
                "value": 56485,
                "label": "Northolt",
                "label_ar": "نورثولت",
                "label_fr": "Northolt"
            },
            {
                "value": 56487,
                "label": "Isleworth",
                "label_ar": "ايسلوورث",
                "label_fr": "Isleworth"
            },
            {
                "value": 56927,
                "label": "Newport",
                "label_ar": "نيوبورت",
                "label_fr": "Newport"
            },
            {
                "value": 58775,
                "label": "Rivington",
                "label_ar": "ريفينجتون",
                "label_fr": "Rivington"
            },
            {
                "value": 55961,
                "label": "Maybole",
                "label_ar": "مايبول",
                "label_fr": "Maybole"
            },
            {
                "value": 56210,
                "label": "Waltham",
                "label_ar": "والثام",
                "label_fr": "Waltham"
            },
            {
                "value": 56463,
                "label": "Potters Bar",
                "label_ar": "بار الخزافين",
                "label_fr": "Bar des potiers"
            },
            {
                "value": 57868,
                "label": "Bala",
                "label_ar": "بالا",
                "label_fr": "Bala"
            },
            {
                "value": 57300,
                "label": "Wilmington",
                "label_ar": "ويلمنجتون",
                "label_fr": "Wilmington"
            },
            {
                "value": 57305,
                "label": "Meggies",
                "label_ar": "ميجيس",
                "label_fr": "Meggies"
            },
            {
                "value": 56125,
                "label": "Hitchin",
                "label_ar": "هيتشن",
                "label_fr": "Hitchin"
            },
            {
                "value": 57879,
                "label": "Pampisford",
                "label_ar": "بامبيسفورد",
                "label_fr": "Pampisford"
            },
            {
                "value": 58200,
                "label": "Clifton Hampden",
                "label_ar": "كليفتون هامبدن",
                "label_fr": "Clifton Hampden"
            },
            {
                "value": 58153,
                "label": "Shireoaks",
                "label_ar": "Shireoaks",
                "label_fr": "Shireoaks"
            },
            {
                "value": 56177,
                "label": "Blaydon",
                "label_ar": "بلايدون",
                "label_fr": "Blaydon"
            },
            {
                "value": 57343,
                "label": "Alford",
                "label_ar": "ألفورد",
                "label_fr": "Alford"
            },
            {
                "value": 58801,
                "label": "Earlham",
                "label_ar": "إيرلهام",
                "label_fr": "Earlham"
            },
            {
                "value": 57982,
                "label": "Martley",
                "label_ar": "مارتلي",
                "label_fr": "Martley"
            },
            {
                "value": 56961,
                "label": "Gourock",
                "label_ar": "جوروك",
                "label_fr": "Gourock"
            },
            {
                "value": 56916,
                "label": "Magheralin",
                "label_ar": "ماغيرالين",
                "label_fr": "Magheralin"
            },
            {
                "value": 58302,
                "label": "Powfoot",
                "label_ar": "Powfoot",
                "label_fr": "Powfoot"
            },
            {
                "value": 57391,
                "label": "Frimley",
                "label_ar": "فريملي",
                "label_fr": "Frimley"
            },
            {
                "value": 57995,
                "label": "Bowness-on-Windermere",
                "label_ar": "باونيس أون ويندرمير",
                "label_fr": "Bowness-on-Windermere"
            },
            {
                "value": 55975,
                "label": "Larbert",
                "label_ar": "لاربرت",
                "label_fr": "Larbert"
            },
            {
                "value": 56913,
                "label": "Wick",
                "label_ar": "ذبالة",
                "label_fr": "Mèche"
            },
            {
                "value": 58573,
                "label": "Cublington",
                "label_ar": "كوبلينجتون",
                "label_fr": "Cublington"
            },
            {
                "value": 56755,
                "label": "Ilfracombe",
                "label_ar": "إلفراكومب",
                "label_fr": "Ilfracombe"
            },
            {
                "value": 56400,
                "label": "West Cowes",
                "label_ar": "ويست كاوز",
                "label_fr": "West Cowes"
            },
            {
                "value": 56744,
                "label": "Houghton on the Hill",
                "label_ar": "هوتون أون ذا هيل",
                "label_fr": "Houghton sur la colline"
            },
            {
                "value": 56605,
                "label": "Inverness",
                "label_ar": "إينفيرنيس",
                "label_fr": "Inverness"
            },
            {
                "value": 58186,
                "label": "Betws",
                "label_ar": "بين",
                "label_fr": "Entre"
            },
            {
                "value": 58772,
                "label": "Myddle",
                "label_ar": "Myddle",
                "label_fr": "Myddle"
            },
            {
                "value": 58468,
                "label": "Sandycroft",
                "label_ar": "سانديكروفت",
                "label_fr": "Sandycroft"
            },
            {
                "value": 56634,
                "label": "Scunthorpe",
                "label_ar": "سكونثورب",
                "label_fr": "Scunthorpe"
            },
            {
                "value": 55962,
                "label": "Dunbar",
                "label_ar": "دنبار",
                "label_fr": "Dunbar"
            },
            {
                "value": 56018,
                "label": "Fleet",
                "label_ar": "سريع",
                "label_fr": "Flotte"
            },
            {
                "value": 57655,
                "label": "Edwalton",
                "label_ar": "إدوالتون",
                "label_fr": "Edwalton"
            },
            {
                "value": 56050,
                "label": "Hailsham",
                "label_ar": "هيلشام",
                "label_fr": "Hailsham"
            },
            {
                "value": 57448,
                "label": "Broughton",
                "label_ar": "جلبت على",
                "label_fr": "Introduit le"
            },
            {
                "value": 56669,
                "label": "Stanford",
                "label_ar": "ستانفورد",
                "label_fr": "Stanford"
            },
            {
                "value": 58072,
                "label": "Reydon",
                "label_ar": "ريدون",
                "label_fr": "Reydon"
            },
            {
                "value": 58791,
                "label": "Alton",
                "label_ar": "ألتون",
                "label_fr": "Alton"
            },
            {
                "value": 57080,
                "label": "Barwell",
                "label_ar": "بارويل",
                "label_fr": "Barwell"
            },
            {
                "value": 56859,
                "label": "Oundle",
                "label_ar": "أوندل",
                "label_fr": "Oundle"
            },
            {
                "value": 57050,
                "label": "Swinton",
                "label_ar": "سوينتون",
                "label_fr": "Swinton"
            },
            {
                "value": 57823,
                "label": "Sonning",
                "label_ar": "سونينغ",
                "label_fr": "Sonning"
            },
            {
                "value": 58656,
                "label": "Mid Calder",
                "label_ar": "منتصف كالدر",
                "label_fr": "Mid Calder"
            },
            {
                "value": 58625,
                "label": "South Elmsall",
                "label_ar": "جنوب المسول",
                "label_fr": "South Elmsall"
            },
            {
                "value": 56280,
                "label": "Welshpool",
                "label_ar": "ويلشبول",
                "label_fr": "Welshpool"
            },
            {
                "value": 56092,
                "label": "Upminster",
                "label_ar": "أبمينستر",
                "label_fr": "Upminster"
            },
            {
                "value": 56931,
                "label": "Laugharne",
                "label_ar": "لوغارن",
                "label_fr": "Laugharne"
            },
            {
                "value": 58063,
                "label": "Shalford",
                "label_ar": "شالفورد",
                "label_fr": "Shalford"
            },
            {
                "value": 58378,
                "label": "Poplar",
                "label_ar": "حور",
                "label_fr": "Peuplier"
            },
            {
                "value": 57173,
                "label": "Marsham",
                "label_ar": "مارشام",
                "label_fr": "Marsham"
            },
            {
                "value": 56909,
                "label": "Sleaford",
                "label_ar": "سليفورد",
                "label_fr": "Sleaford"
            },
            {
                "value": 56499,
                "label": "Southam",
                "label_ar": "ساوثام",
                "label_fr": "Southam"
            },
            {
                "value": 58459,
                "label": "Menston",
                "label_ar": "مينستون",
                "label_fr": "Menston"
            },
            {
                "value": 57688,
                "label": "Theydon Bois",
                "label_ar": "ثيدون بوا",
                "label_fr": "Theydon Bois"
            },
            {
                "value": 57248,
                "label": "Buxted",
                "label_ar": "بكستد",
                "label_fr": "Buxted"
            },
            {
                "value": 57964,
                "label": "Yatton",
                "label_ar": "ياتون",
                "label_fr": "Yatton"
            },
            {
                "value": 58564,
                "label": "Stifford",
                "label_ar": "ستيفورد",
                "label_fr": "Stifford"
            },
            {
                "value": 57683,
                "label": "Keynsham",
                "label_ar": "كينشام",
                "label_fr": "Keynsham"
            },
            {
                "value": 56443,
                "label": "Fishguard",
                "label_ar": "Fishguard",
                "label_fr": "Fishguard"
            },
            {
                "value": 57774,
                "label": "Cowley",
                "label_ar": "كاولي",
                "label_fr": "Cowley"
            },
            {
                "value": 58109,
                "label": "Totteridge",
                "label_ar": "توتريدج",
                "label_fr": "Totteridge"
            },
            {
                "value": 58756,
                "label": "Weedon",
                "label_ar": "ويدون",
                "label_fr": "Weedon"
            },
            {
                "value": 57817,
                "label": "Tyldesley",
                "label_ar": "تيلديسلي",
                "label_fr": "Tyldesley"
            },
            {
                "value": 58722,
                "label": "Theale",
                "label_ar": "ثيل",
                "label_fr": "Theale"
            },
            {
                "value": 57776,
                "label": "Lambourn",
                "label_ar": "لامبورن",
                "label_fr": "Lambourn"
            },
            {
                "value": 55926,
                "label": "Bargoed",
                "label_ar": "مساومة",
                "label_fr": "Bargoed"
            },
            {
                "value": 57331,
                "label": "Linton",
                "label_ar": "لينتون",
                "label_fr": "Linton"
            },
            {
                "value": 57579,
                "label": "Bickleigh",
                "label_ar": "بيكلي",
                "label_fr": "Bickleigh"
            },
            {
                "value": 57703,
                "label": "Hanworth",
                "label_ar": "هانورث",
                "label_fr": "Hanworth"
            },
            {
                "value": 55896,
                "label": "Frome",
                "label_ar": "فروم",
                "label_fr": "Frome"
            },
            {
                "value": 56869,
                "label": "Biddenden",
                "label_ar": "بيدندن",
                "label_fr": "Biddenden"
            },
            {
                "value": 55971,
                "label": "Kilwinning",
                "label_ar": "كيلوينينغ",
                "label_fr": "Kilwinning"
            },
            {
                "value": 57243,
                "label": "Skelton",
                "label_ar": "هيكل عظمي",
                "label_fr": "Skelton"
            },
            {
                "value": 58893,
                "label": "Burford",
                "label_ar": "بورفورد",
                "label_fr": "Burford"
            },
            {
                "value": 57484,
                "label": "New Southgate",
                "label_ar": "ساوثجيت الجديدة",
                "label_fr": "New Southgate"
            },
            {
                "value": 58328,
                "label": "Walkden",
                "label_ar": "ووكدين",
                "label_fr": "Walkden"
            },
            {
                "value": 58292,
                "label": "Marnhull",
                "label_ar": "مارنهول",
                "label_fr": "Marnhull"
            },
            {
                "value": 58638,
                "label": "East Preston",
                "label_ar": "شرق بريستون",
                "label_fr": "East Preston"
            },
            {
                "value": 56404,
                "label": "Warden",
                "label_ar": "حارس",
                "label_fr": "Directeur"
            },
            {
                "value": 58309,
                "label": "Pennington",
                "label_ar": "بنينجتون",
                "label_fr": "Pennington"
            },
            {
                "value": 57894,
                "label": "Horam",
                "label_ar": "حورام",
                "label_fr": "Horam"
            },
            {
                "value": 57537,
                "label": "Giggleswick",
                "label_ar": "جيجلزويك",
                "label_fr": "Giggleswick"
            },
            {
                "value": 57227,
                "label": "Strood",
                "label_ar": "سترود",
                "label_fr": "Strood"
            },
            {
                "value": 58176,
                "label": "Willingham",
                "label_ar": "ويلينجهام",
                "label_fr": "Willingham"
            },
            {
                "value": 56820,
                "label": "Otford",
                "label_ar": "أوتفورد",
                "label_fr": "Otford"
            },
            {
                "value": 58626,
                "label": "Potterspury",
                "label_ar": "بوترزبوري",
                "label_fr": "Potterspury"
            },
            {
                "value": 58586,
                "label": "Heriot",
                "label_ar": "هيريوت",
                "label_fr": "Heriot"
            },
            {
                "value": 58673,
                "label": "Bekesbourne",
                "label_ar": "بيكسبورن",
                "label_fr": "Bekesbourne"
            },
            {
                "value": 56059,
                "label": "Rickmansworth",
                "label_ar": "ريكمانزورث",
                "label_fr": "Rickmansworth"
            },
            {
                "value": 58438,
                "label": "Bidston",
                "label_ar": "بيدستون",
                "label_fr": "Bidston"
            },
            {
                "value": 57225,
                "label": "Northfleet",
                "label_ar": "نورثفليت",
                "label_fr": "Northfleet"
            },
            {
                "value": 57219,
                "label": "Aintree",
                "label_ar": "انتري",
                "label_fr": "Aintree"
            },
            {
                "value": 58901,
                "label": "Clapham",
                "label_ar": "كلافام",
                "label_fr": "Clapham"
            },
            {
                "value": 56670,
                "label": "Bangor",
                "label_ar": "بانجور",
                "label_fr": "Bangor"
            },
            {
                "value": 56722,
                "label": "Irlam",
                "label_ar": "إيرلام",
                "label_fr": "Irlam"
            },
            {
                "value": 58339,
                "label": "Longridge",
                "label_ar": "لونجريدج",
                "label_fr": "Longridge"
            },
            {
                "value": 58511,
                "label": "Stanstead",
                "label_ar": "ستانستيد",
                "label_fr": "Stanstead"
            },
            {
                "value": 58789,
                "label": "East Down",
                "label_ar": "شرق داون",
                "label_fr": "East Down"
            },
            {
                "value": 58364,
                "label": "North Elmsall",
                "label_ar": "شمال المسول",
                "label_fr": "North Elmsall"
            },
            {
                "value": 56412,
                "label": "Wroughton",
                "label_ar": "روتون",
                "label_fr": "Wroughton"
            },
            {
                "value": 57100,
                "label": "Burscough",
                "label_ar": "بورسكوغ",
                "label_fr": "Burscough"
            },
            {
                "value": 57229,
                "label": "Albrighton",
                "label_ar": "أولبرايتون",
                "label_fr": "Albrighton"
            },
            {
                "value": 56727,
                "label": "Paignton",
                "label_ar": "بينتون",
                "label_fr": "Paignton"
            },
            {
                "value": 57217,
                "label": "Burnham",
                "label_ar": "بورنهام",
                "label_fr": "Burnham"
            },
            {
                "value": 57939,
                "label": "High Bentham",
                "label_ar": "هاي بينثام",
                "label_fr": "Haut Bentham"
            },
            {
                "value": 58010,
                "label": "Templepatrick",
                "label_ar": "تمبل باتريك",
                "label_fr": "Templepatrick"
            },
            {
                "value": 56709,
                "label": "Motherwell",
                "label_ar": "مذرويل",
                "label_fr": "Motherwell"
            },
            {
                "value": 56342,
                "label": "Woodford Green",
                "label_ar": "وودفورد جرين",
                "label_fr": "Vert Woodford"
            },
            {
                "value": 58607,
                "label": "Friern Barnet",
                "label_ar": "فرين بارنت",
                "label_fr": "Friern Barnet"
            },
            {
                "value": 58797,
                "label": "Meesden",
                "label_ar": "ميسدن",
                "label_fr": "Meesden"
            },
            {
                "value": 56954,
                "label": "Elland",
                "label_ar": "إيلاند",
                "label_fr": "Elland"
            },
            {
                "value": 57044,
                "label": "Frithville",
                "label_ar": "فريتفيل",
                "label_fr": "Frithville"
            },
            {
                "value": 56506,
                "label": "Louth",
                "label_ar": "لاوث",
                "label_fr": "Louth"
            },
            {
                "value": 57291,
                "label": "Alresford",
                "label_ar": "Alresford",
                "label_fr": "Alresford"
            },
            {
                "value": 56333,
                "label": "Taunton",
                "label_ar": "تونتون",
                "label_fr": "Taunton"
            },
            {
                "value": 56484,
                "label": "Tiverton",
                "label_ar": "تيفرتون",
                "label_fr": "Tiverton"
            },
            {
                "value": 56978,
                "label": "Battle",
                "label_ar": "معركة",
                "label_fr": "Bataille"
            },
            {
                "value": 56533,
                "label": "Shanklin",
                "label_ar": "شانكلين",
                "label_fr": "Shanklin"
            },
            {
                "value": 58760,
                "label": "Hazelwood",
                "label_ar": "بندق",
                "label_fr": "Noisetier"
            },
            {
                "value": 56546,
                "label": "Ashton-under-Lyne",
                "label_ar": "أشتون أندر لين",
                "label_fr": "Ashton-sous-Lyne"
            },
            {
                "value": 56132,
                "label": "Chacombe",
                "label_ar": "تشاكومب",
                "label_fr": "Chacombe"
            },
            {
                "value": 56152,
                "label": "Salford",
                "label_ar": "سالفورد",
                "label_fr": "Salford"
            },
            {
                "value": 58291,
                "label": "South Cave",
                "label_ar": "كهف الجنوب",
                "label_fr": "Grotte sud"
            },
            {
                "value": 57956,
                "label": "Harlech",
                "label_ar": "هارليك",
                "label_fr": "Harlech"
            },
            {
                "value": 57274,
                "label": "Brightlingsea",
                "label_ar": "برايتلينجسي",
                "label_fr": "Brightlingsea"
            },
            {
                "value": 58403,
                "label": "Wickham Market",
                "label_ar": "سوق ويكهام",
                "label_fr": "Marché de Wickham"
            },
            {
                "value": 57557,
                "label": "Whiteparish",
                "label_ar": "أبيض",
                "label_fr": "Whiteparish"
            },
            {
                "value": 57617,
                "label": "Montgomery",
                "label_ar": "مونتغمري",
                "label_fr": "Montgomery"
            },
            {
                "value": 56252,
                "label": "Doncaster",
                "label_ar": "دونكاستر",
                "label_fr": "Doncaster"
            },
            {
                "value": 57941,
                "label": "Gargrave",
                "label_ar": "جارجريف",
                "label_fr": "Gargrave"
            },
            {
                "value": 58194,
                "label": "Fringford",
                "label_ar": "فرينجفورد",
                "label_fr": "Fringford"
            },
            {
                "value": 56162,
                "label": "Northwich",
                "label_ar": "نورثويتش",
                "label_fr": "Northwich"
            },
            {
                "value": 57717,
                "label": "Edgbaston",
                "label_ar": "إدجباستون",
                "label_fr": "Edgbaston"
            },
            {
                "value": 55929,
                "label": "Neath",
                "label_ar": "نيث",
                "label_fr": "Neath"
            },
            {
                "value": 58174,
                "label": "Frizington",
                "label_ar": "فريزينجتون",
                "label_fr": "Frizington"
            },
            {
                "value": 58073,
                "label": "Darfield",
                "label_ar": "دارفيلد",
                "label_fr": "Darfield"
            },
            {
                "value": 56770,
                "label": "Northallerton",
                "label_ar": "نورثاليرتون",
                "label_fr": "Northallerton"
            },
            {
                "value": 57249,
                "label": "Duxford",
                "label_ar": "دوكسفورد",
                "label_fr": "Duxford"
            },
            {
                "value": 58142,
                "label": "Lostwithiel",
                "label_ar": "لوستويثيل",
                "label_fr": "Lostwithiel"
            },
            {
                "value": 56925,
                "label": "East Hagbourne",
                "label_ar": "إيست هاجبورن",
                "label_fr": "East Hagbourne"
            },
            {
                "value": 56774,
                "label": "Bishops Cleeve",
                "label_ar": "الأساقفة كليف",
                "label_fr": "Évêques Cleeve"
            },
            {
                "value": 58115,
                "label": "Newton on Trent",
                "label_ar": "نيوتن على ترينت",
                "label_fr": "Newton on Trent"
            },
            {
                "value": 56100,
                "label": "Maidstone",
                "label_ar": "ميدستون",
                "label_fr": "Maidstone"
            },
            {
                "value": 56338,
                "label": "Tadley",
                "label_ar": "تادلي",
                "label_fr": "Tadley"
            },
            {
                "value": 58344,
                "label": "Loughor",
                "label_ar": "Loughor",
                "label_fr": "Loughor"
            },
            {
                "value": 58484,
                "label": "Pleshey",
                "label_ar": "بلشي",
                "label_fr": "Pleshey"
            },
            {
                "value": 57838,
                "label": "Headington",
                "label_ar": "هيدينغتون",
                "label_fr": "Headington"
            },
            {
                "value": 56590,
                "label": "Lancing",
                "label_ar": "الطعن",
                "label_fr": "Lancing"
            },
            {
                "value": 58242,
                "label": "Garrison",
                "label_ar": "حامية",
                "label_fr": "Garnison"
            },
            {
                "value": 58419,
                "label": "Lee",
                "label_ar": "لي",
                "label_fr": "Lee"
            },
            {
                "value": 57384,
                "label": "Mount Bures",
                "label_ar": "جبل بوريس",
                "label_fr": "Mont Bures"
            },
            {
                "value": 57043,
                "label": "Appleby",
                "label_ar": "أبليبي",
                "label_fr": "Appleby"
            },
            {
                "value": 58163,
                "label": "Earnley",
                "label_ar": "إيرنلي",
                "label_fr": "Earnley"
            },
            {
                "value": 56548,
                "label": "Wisbech",
                "label_ar": "Wisbech",
                "label_fr": "Wisbech"
            },
            {
                "value": 56284,
                "label": "Tipton",
                "label_ar": "تيبتون",
                "label_fr": "Tipton"
            },
            {
                "value": 55989,
                "label": "York",
                "label_ar": "يورك",
                "label_fr": "York"
            },
            {
                "value": 56032,
                "label": "Lanark",
                "label_ar": "لانارك",
                "label_fr": "Lanark"
            },
            {
                "value": 57289,
                "label": "Ripe",
                "label_ar": "ناضج",
                "label_fr": "Mûr"
            },
            {
                "value": 56724,
                "label": "Coleraine",
                "label_ar": "كوليرين",
                "label_fr": "Coleraine"
            },
            {
                "value": 56005,
                "label": "Petersfield",
                "label_ar": "بيترسفيلد",
                "label_fr": "Petersfield"
            },
            {
                "value": 55997,
                "label": "Woking",
                "label_ar": "ووكينغ",
                "label_fr": "Woking"
            },
            {
                "value": 58584,
                "label": "Upper Beeding",
                "label_ar": "البيدج العلوي",
                "label_fr": "Alimentation supérieure"
            },
            {
                "value": 58286,
                "label": "Moulton St Michael",
                "label_ar": "مولتون سانت مايكل",
                "label_fr": "Moulton St Michael"
            },
            {
                "value": 56899,
                "label": "Hillside",
                "label_ar": "هيلسايد",
                "label_fr": "Flanc de coteau"
            },
            {
                "value": 56306,
                "label": "Ossett",
                "label_ar": "أوسيت",
                "label_fr": "Ossett"
            },
            {
                "value": 56743,
                "label": "Hayle",
                "label_ar": "هيل",
                "label_fr": "Hayle"
            },
            {
                "value": 57077,
                "label": "Burslem",
                "label_ar": "بورسللم",
                "label_fr": "Burslem"
            },
            {
                "value": 57890,
                "label": "Kirkconnel",
                "label_ar": "كيركونيل",
                "label_fr": "Kirkconnel"
            },
            {
                "value": 57983,
                "label": "Ellesmere",
                "label_ar": "إليسمير",
                "label_fr": "Ellesmere"
            },
            {
                "value": 57273,
                "label": "Bishops Waltham",
                "label_ar": "اساقفة والثام",
                "label_fr": "Évêques Waltham"
            },
            {
                "value": 57631,
                "label": "Polperro",
                "label_ar": "بولبيرو",
                "label_fr": "Polperro"
            },
            {
                "value": 56447,
                "label": "Woodstock",
                "label_ar": "وودستوك",
                "label_fr": "Woodstock"
            },
            {
                "value": 56582,
                "label": "Glenrothes",
                "label_ar": "جلينروثس",
                "label_fr": "Glenrothes"
            },
            {
                "value": 57029,
                "label": "Horwich",
                "label_ar": "هورويتش",
                "label_fr": "Horwich"
            },
            {
                "value": 58819,
                "label": "Headcorn",
                "label_ar": "ذرة الرأس",
                "label_fr": "Headcorn"
            },
            {
                "value": 56319,
                "label": "Rochdale",
                "label_ar": "روتشديل",
                "label_fr": "Rochdale"
            },
            {
                "value": 56580,
                "label": "West Calder",
                "label_ar": "غرب كالدر",
                "label_fr": "West Calder"
            },
            {
                "value": 56232,
                "label": "Ellesmere Port",
                "label_ar": "ميناء إليسمير",
                "label_fr": "Port d'Ellesmere"
            },
            {
                "value": 57238,
                "label": "Burton",
                "label_ar": "بيرتون",
                "label_fr": "Burton"
            },
            {
                "value": 57426,
                "label": "Buckland",
                "label_ar": "باكلاند",
                "label_fr": "Buckland"
            },
            {
                "value": 57357,
                "label": "Hopwood",
                "label_ar": "هوبوود",
                "label_fr": "Hopwood"
            },
            {
                "value": 56577,
                "label": "Denbigh",
                "label_ar": "دينبيغ",
                "label_fr": "Denbigh"
            },
            {
                "value": 56806,
                "label": "Gorebridge",
                "label_ar": "جوربريدج",
                "label_fr": "Gorebridge"
            },
            {
                "value": 57846,
                "label": "Pulloxhill",
                "label_ar": "بولوكسيل",
                "label_fr": "Pulloxhill"
            },
            {
                "value": 56571,
                "label": "East Stour",
                "label_ar": "إيست ستور",
                "label_fr": "East Stour"
            },
            {
                "value": 57205,
                "label": "Wythenshawe",
                "label_ar": "ويثينشو",
                "label_fr": "Wythenshawe"
            },
            {
                "value": 58599,
                "label": "Daresbury",
                "label_ar": "دارسبري",
                "label_fr": "Daresbury"
            },
            {
                "value": 58169,
                "label": "Llanfyllin",
                "label_ar": "لانفيلين",
                "label_fr": "Llanfyllin"
            },
            {
                "value": 58518,
                "label": "Hawarden",
                "label_ar": "هواردن",
                "label_fr": "Hawarden"
            },
            {
                "value": 58723,
                "label": "Belmont",
                "label_ar": "بلمونت",
                "label_fr": "Belmont"
            },
            {
                "value": 56267,
                "label": "Feltham",
                "label_ar": "فيلثام",
                "label_fr": "Feltham"
            },
            {
                "value": 56921,
                "label": "Ampthill",
                "label_ar": "أمبثيل",
                "label_fr": "Ampthill"
            },
            {
                "value": 57270,
                "label": "Horrabridge",
                "label_ar": "هورابريدج",
                "label_fr": "Horrabridge"
            },
            {
                "value": 57760,
                "label": "Rustington",
                "label_ar": "روستينجتون",
                "label_fr": "Rustington"
            },
            {
                "value": 58066,
                "label": "Froggatt",
                "label_ar": "فروجات",
                "label_fr": "Froggatt"
            },
            {
                "value": 55990,
                "label": "Middlesbrough",
                "label_ar": "ميدلزبره",
                "label_fr": "Middlesbrough"
            },
            {
                "value": 56893,
                "label": "Hessle",
                "label_ar": "هيسل",
                "label_fr": "Hessle"
            },
            {
                "value": 58596,
                "label": "Roffey",
                "label_ar": "روفي",
                "label_fr": "Roffey"
            },
            {
                "value": 55938,
                "label": "Ebbw Vale",
                "label_ar": "إيبو فالي",
                "label_fr": "Ebbw Vale"
            },
            {
                "value": 58534,
                "label": "Longhope",
                "label_ar": "Longhope",
                "label_fr": "Longhope"
            },
            {
                "value": 57143,
                "label": "Valley",
                "label_ar": "الوادي",
                "label_fr": "Vallée"
            },
            {
                "value": 58876,
                "label": "Compton Dundon",
                "label_ar": "كومبتون دوندون",
                "label_fr": "Compton Dundon"
            },
            {
                "value": 56494,
                "label": "Sturminster Newton",
                "label_ar": "ستورمينستر نيوتن",
                "label_fr": "Sturminster Newton"
            },
            {
                "value": 57922,
                "label": "Ashdon",
                "label_ar": "أشدون",
                "label_fr": "Ashdon"
            },
            {
                "value": 58758,
                "label": "Warmington",
                "label_ar": "وارمنجتون",
                "label_fr": "Warmington"
            },
            {
                "value": 56324,
                "label": "Hinckley",
                "label_ar": "هينكلي",
                "label_fr": "Hinckley"
            },
            {
                "value": 56433,
                "label": "Watford",
                "label_ar": "واتفورد",
                "label_fr": "Watford"
            },
            {
                "value": 56545,
                "label": "Dundee",
                "label_ar": "دندي",
                "label_fr": "Dundee"
            },
            {
                "value": 58670,
                "label": "Benson",
                "label_ar": "بنسون",
                "label_fr": "Benson"
            },
            {
                "value": 56453,
                "label": "Aylesbury",
                "label_ar": "ايلسبري",
                "label_fr": "Aylesbury"
            },
            {
                "value": 58092,
                "label": "Roxwell",
                "label_ar": "روكسويل",
                "label_fr": "Roxwell"
            },
            {
                "value": 57909,
                "label": "Glasdrumman",
                "label_ar": "غلاسدومان",
                "label_fr": "Glasdrumman"
            },
            {
                "value": 56835,
                "label": "Hillingdon",
                "label_ar": "هيلينجدون",
                "label_fr": "Hillingdon"
            },
            {
                "value": 58868,
                "label": "Augher",
                "label_ar": "اوجير",
                "label_fr": "Augher"
            },
            {
                "value": 56081,
                "label": "Halifax",
                "label_ar": "هاليفاكس",
                "label_fr": "Halifax"
            },
            {
                "value": 56074,
                "label": "Sheffield",
                "label_ar": "شيفيلد",
                "label_fr": "Sheffield"
            },
            {
                "value": 58084,
                "label": "Kimberley",
                "label_ar": "كيمبرلي",
                "label_fr": "Kimberley"
            },
            {
                "value": 55960,
                "label": "Kilsyth",
                "label_ar": "كيلسيث",
                "label_fr": "Kilsyth"
            },
            {
                "value": 56478,
                "label": "Epsom",
                "label_ar": "ابسوم",
                "label_fr": "Epsom"
            },
            {
                "value": 57052,
                "label": "Holbeach",
                "label_ar": "هولبيتش",
                "label_fr": "Holbeach"
            },
            {
                "value": 55899,
                "label": "Verwood",
                "label_ar": "فيروود",
                "label_fr": "Verwood"
            },
            {
                "value": 56658,
                "label": "Wrea Green",
                "label_ar": "اكليل جرين",
                "label_fr": "Wrea Green"
            },
            {
                "value": 56972,
                "label": "Whittlesey",
                "label_ar": "ويتليسي",
                "label_fr": "Whittlesey"
            },
            {
                "value": 57476,
                "label": "New Quay",
                "label_ar": "نيو كواي",
                "label_fr": "New Quay"
            },
            {
                "value": 57364,
                "label": "Minsterley",
                "label_ar": "مينسترلي",
                "label_fr": "Minsterley"
            },
            {
                "value": 56103,
                "label": "Basildon",
                "label_ar": "باسيلدون",
                "label_fr": "Basildon"
            },
            {
                "value": 56134,
                "label": "Market Harborough",
                "label_ar": "سوق هاربورو",
                "label_fr": "Marché Harborough"
            },
            {
                "value": 56379,
                "label": "Hebden Bridge",
                "label_ar": "جسر هيبدن",
                "label_fr": "Pont Hebden"
            },
            {
                "value": 57445,
                "label": "Somerton",
                "label_ar": "سوميرتون",
                "label_fr": "Somerton"
            },
            {
                "value": 57277,
                "label": "Southwick",
                "label_ar": "ساوثويك",
                "label_fr": "Southwick"
            },
            {
                "value": 57504,
                "label": "Loanhead",
                "label_ar": "معار",
                "label_fr": "Loanhead"
            },
            {
                "value": 57601,
                "label": "Dinas Powys",
                "label_ar": "ديناس بوويز",
                "label_fr": "Dinas Powys"
            },
            {
                "value": 57549,
                "label": "Whitbourne",
                "label_ar": "ويتبورن",
                "label_fr": "Whitbourne"
            },
            {
                "value": 58238,
                "label": "Murton",
                "label_ar": "مورتون",
                "label_fr": "Murton"
            },
            {
                "value": 56235,
                "label": "Darwen",
                "label_ar": "داروين",
                "label_fr": "Darwen"
            },
            {
                "value": 58421,
                "label": "Potton",
                "label_ar": "بوتون",
                "label_fr": "Potton"
            },
            {
                "value": 58838,
                "label": "Stoke Prior",
                "label_ar": "ستوك برايور",
                "label_fr": "Stoke Prior"
            },
            {
                "value": 57692,
                "label": "Balloch",
                "label_ar": "بالوش",
                "label_fr": "Balloch"
            },
            {
                "value": 56363,
                "label": "Dumfries",
                "label_ar": "دومفريز",
                "label_fr": "Dumfries"
            },
            {
                "value": 56608,
                "label": "East Ham",
                "label_ar": "إيست هام",
                "label_fr": "Jambon de l'est"
            },
            {
                "value": 56334,
                "label": "Dawlish",
                "label_ar": "داوليش",
                "label_fr": "Dawlish"
            },
            {
                "value": 58413,
                "label": "Manton",
                "label_ar": "مانتون",
                "label_fr": "Manton"
            },
            {
                "value": 58647,
                "label": "Paul",
                "label_ar": "بول",
                "label_fr": "Paul"
            },
            {
                "value": 57368,
                "label": "Alderley Edge",
                "label_ar": "ألدرلي إيدج",
                "label_fr": "Alderley Edge"
            },
            {
                "value": 57519,
                "label": "Church Stretton",
                "label_ar": "كنيسة ستريتون",
                "label_fr": "Église Stretton"
            },
            {
                "value": 58116,
                "label": "Lisnaskea",
                "label_ar": "ليسناسكيا",
                "label_fr": "Lisnaskea"
            },
            {
                "value": 57988,
                "label": "Dunkeswell",
                "label_ar": "دانكسويل",
                "label_fr": "Dunkeswell"
            },
            {
                "value": 57728,
                "label": "Milton on Stour",
                "label_ar": "ميلتون في ستور",
                "label_fr": "Milton on Stour"
            },
            {
                "value": 58130,
                "label": "Windlesham",
                "label_ar": "ويندلشام",
                "label_fr": "Windlesham"
            },
            {
                "value": 56474,
                "label": "St Albans",
                "label_ar": "ست ألبانز",
                "label_fr": "St Albans"
            },
            {
                "value": 58030,
                "label": "Drumahoe",
                "label_ar": "دروماهو",
                "label_fr": "Drumahoe"
            },
            {
                "value": 58693,
                "label": "Walford",
                "label_ar": "والفورد",
                "label_fr": "Walford"
            },
            {
                "value": 58143,
                "label": "Westleton",
                "label_ar": "ويستلتون",
                "label_fr": "Westleton"
            },
            {
                "value": 56492,
                "label": "Henstridge",
                "label_ar": "هينستريدج",
                "label_fr": "Henstridge"
            },
            {
                "value": 56618,
                "label": "Whyteleafe",
                "label_ar": "وايتليف",
                "label_fr": "Whyteleafe"
            },
            {
                "value": 56937,
                "label": "Stanford",
                "label_ar": "ستانفورد",
                "label_fr": "Stanford"
            },
            {
                "value": 58086,
                "label": "Barton under Needwood",
                "label_ar": "بارتون تحت نيدوود",
                "label_fr": "Barton sous Needwood"
            },
            {
                "value": 57279,
                "label": "Kirton in Lindsey",
                "label_ar": "كيرتون في ليندسي",
                "label_fr": "Kirton à Lindsey"
            },
            {
                "value": 57358,
                "label": "Woodham Ferrers",
                "label_ar": "وودهام فيريرز",
                "label_fr": "Woodham Ferrers"
            },
            {
                "value": 57669,
                "label": "Stanford",
                "label_ar": "ستانفورد",
                "label_fr": "Stanford"
            },
            {
                "value": 57667,
                "label": "Portslade-by-Sea",
                "label_ar": "بورتسليد عن طريق البحر",
                "label_fr": "Portslade-by-Sea"
            },
            {
                "value": 57330,
                "label": "Wombwell",
                "label_ar": "Wombwell",
                "label_fr": "Wombwell"
            },
            {
                "value": 57542,
                "label": "Hinton St George",
                "label_ar": "هينتون سانت جورج",
                "label_fr": "Hinton St George"
            },
            {
                "value": 57007,
                "label": "Papworth Everard",
                "label_ar": "بابوورث ايفرارد",
                "label_fr": "Papworth Everard"
            },
            {
                "value": 57660,
                "label": "Tansley",
                "label_ar": "تانسلي",
                "label_fr": "Tansley"
            },
            {
                "value": 56848,
                "label": "Chislehurst",
                "label_ar": "تشيسلهيرست",
                "label_fr": "Chislehurst"
            },
            {
                "value": 57333,
                "label": "Cosham",
                "label_ar": "كوشام",
                "label_fr": "Cosham"
            },
            {
                "value": 57347,
                "label": "Hindley",
                "label_ar": "هيندلي",
                "label_fr": "Hindley"
            },
            {
                "value": 58563,
                "label": "West Ashby",
                "label_ar": "غرب اشبي",
                "label_fr": "West Ashby"
            },
            {
                "value": 56619,
                "label": "South Benfleet",
                "label_ar": "جنوب بنفليت",
                "label_fr": "Sud Benfleet"
            },
            {
                "value": 57175,
                "label": "Sandwell",
                "label_ar": "ساندويل",
                "label_fr": "Sandwell"
            },
            {
                "value": 57638,
                "label": "Clavering",
                "label_ar": "Clavering",
                "label_fr": "Clavering"
            },
            {
                "value": 57620,
                "label": "Talgarth",
                "label_ar": "تالغارث",
                "label_fr": "Talgarth"
            },
            {
                "value": 56459,
                "label": "Bathgate",
                "label_ar": "باثجيت",
                "label_fr": "Bathgate"
            },
            {
                "value": 56442,
                "label": "Barry",
                "label_ar": "باري",
                "label_fr": "Barry"
            },
            {
                "value": 57192,
                "label": "Arlesey",
                "label_ar": "أرليسي",
                "label_fr": "Arlesey"
            },
            {
                "value": 56936,
                "label": "Howden",
                "label_ar": "هودين",
                "label_fr": "Howden"
            },
            {
                "value": 58417,
                "label": "Kilbride",
                "label_ar": "كيلبرايد",
                "label_fr": "Kilbride"
            },
            {
                "value": 58423,
                "label": "Swynnerton",
                "label_ar": "سوينرتون",
                "label_fr": "Swynnerton"
            },
            {
                "value": 58427,
                "label": "Weston in Gordano",
                "label_ar": "ويستون في جوردانو",
                "label_fr": "Weston à Gordano"
            },
            {
                "value": 56988,
                "label": "Hawick",
                "label_ar": "هوك",
                "label_fr": "Hawick"
            },
            {
                "value": 56167,
                "label": "Lymm",
                "label_ar": "ليم",
                "label_fr": "Lymm"
            },
            {
                "value": 56554,
                "label": "Crewkerne",
                "label_ar": "كروكيرن",
                "label_fr": "Crewkerne"
            },
            {
                "value": 58611,
                "label": "Adlingfleet",
                "label_ar": "Adlingfleet",
                "label_fr": "Adlingfleet"
            },
            {
                "value": 55936,
                "label": "Porthcawl",
                "label_ar": "بورثكاول",
                "label_fr": "Porthcawl"
            },
            {
                "value": 57511,
                "label": "Much Hadham",
                "label_ar": "كثير الهدام",
                "label_fr": "Beaucoup Hadham"
            },
            {
                "value": 57931,
                "label": "Lochwinnoch",
                "label_ar": "Lochwinnoch",
                "label_fr": "Lochwinnoch"
            },
            {
                "value": 57363,
                "label": "Knowsley",
                "label_ar": "نوسلي",
                "label_fr": "Knowsley"
            },
            {
                "value": 55910,
                "label": "Warminster",
                "label_ar": "ارمينستر",
                "label_fr": "Warminster"
            },
            {
                "value": 56837,
                "label": "Calverton",
                "label_ar": "كالفرتون",
                "label_fr": "Calverton"
            },
            {
                "value": 58345,
                "label": "Hampton Lucy",
                "label_ar": "هامبتون لوسي",
                "label_fr": "Hampton Lucy"
            },
            {
                "value": 55879,
                "label": "Bromsgrove",
                "label_ar": "برومسجروف",
                "label_fr": "Bromsgrove"
            },
            {
                "value": 57806,
                "label": "Godmanchester",
                "label_ar": "جودمانشستر",
                "label_fr": "Godmanchester"
            },
            {
                "value": 58171,
                "label": "Staindrop",
                "label_ar": "بقعة",
                "label_fr": "Tache"
            },
            {
                "value": 58809,
                "label": "Stanhope",
                "label_ar": "ستانهوب",
                "label_fr": "Stanhope"
            },
            {
                "value": 56635,
                "label": "Hornsea",
                "label_ar": "هورنسي",
                "label_fr": "Hornsea"
            },
            {
                "value": 58038,
                "label": "Peterculter",
                "label_ar": "بيتركلتر",
                "label_fr": "Peterculter"
            },
            {
                "value": 56198,
                "label": "Wymondham",
                "label_ar": "ويموندهام",
                "label_fr": "Wymondham"
            },
            {
                "value": 58003,
                "label": "Alderholt",
                "label_ar": "ألدرهولت",
                "label_fr": "Alderholt"
            },
            {
                "value": 55996,
                "label": "Uxbridge",
                "label_ar": "أوكسبريدج",
                "label_fr": "Uxbridge"
            },
            {
                "value": 57134,
                "label": "Studley",
                "label_ar": "ستودلي",
                "label_fr": "Studley"
            },
            {
                "value": 57797,
                "label": "East Dulwich",
                "label_ar": "شرق دولويتش",
                "label_fr": "East Dulwich"
            },
            {
                "value": 57131,
                "label": "Partridge Green",
                "label_ar": "الحجل الأخضر",
                "label_fr": "Vert perdrix"
            },
            {
                "value": 57867,
                "label": "Prees",
                "label_ar": "تفضيلات",
                "label_fr": "Prees"
            },
            {
                "value": 57114,
                "label": "Walton on Thames",
                "label_ar": "والتون على نهر التايمز",
                "label_fr": "Walton on Thames"
            },
            {
                "value": 58719,
                "label": "Dyce",
                "label_ar": "دايس",
                "label_fr": "Dyce"
            },
            {
                "value": 58712,
                "label": "Wyke",
                "label_ar": "ويك",
                "label_fr": "Wyke"
            },
            {
                "value": 56414,
                "label": "Penryn",
                "label_ar": "بنرين",
                "label_fr": "Penryn"
            },
            {
                "value": 58260,
                "label": "Edenfield",
                "label_ar": "إيدنفيلد",
                "label_fr": "Edenfield"
            },
            {
                "value": 57925,
                "label": "Aultbea",
                "label_ar": "أولتبيا",
                "label_fr": "Aultbea"
            },
            {
                "value": 57115,
                "label": "Levenshulme",
                "label_ar": "ليفينشولمي",
                "label_fr": "Levenshulme"
            },
            {
                "value": 58617,
                "label": "Rimington",
                "label_ar": "ريمنجتون",
                "label_fr": "Rimington"
            },
            {
                "value": 58557,
                "label": "Leuchars",
                "label_ar": "Leuchars",
                "label_fr": "Leuchars"
            },
            {
                "value": 58055,
                "label": "Hednesford",
                "label_ar": "هدنسفورد",
                "label_fr": "Hednesford"
            },
            {
                "value": 55923,
                "label": "Mountain Ash",
                "label_ar": "رماد الجبل",
                "label_fr": "Sorbier"
            },
            {
                "value": 57782,
                "label": "Willington",
                "label_ar": "ويلينجتون",
                "label_fr": "Willington"
            },
            {
                "value": 58046,
                "label": "Hermitage",
                "label_ar": "هيرميتاج",
                "label_fr": "Ermitage"
            },
            {
                "value": 56374,
                "label": "Catford",
                "label_ar": "كاتفورد",
                "label_fr": "Catford"
            },
            {
                "value": 57947,
                "label": "Lowdham",
                "label_ar": "لودهام",
                "label_fr": "Lowdham"
            },
            {
                "value": 57176,
                "label": "Shardlow",
                "label_ar": "شاردلو",
                "label_fr": "Shardlow"
            },
            {
                "value": 57859,
                "label": "West Raynham",
                "label_ar": "ويست راينهام",
                "label_fr": "West Raynham"
            },
            {
                "value": 57853,
                "label": "Duffus",
                "label_ar": "دافوس",
                "label_fr": "Duffus"
            },
            {
                "value": 58524,
                "label": "Methil",
                "label_ar": "ميثيل",
                "label_fr": "Methil"
            },
            {
                "value": 56491,
                "label": "Lyndhurst",
                "label_ar": "ليندهيرست",
                "label_fr": "Lyndhurst"
            },
            {
                "value": 58383,
                "label": "Stanton",
                "label_ar": "ستانتون",
                "label_fr": "Stanton"
            },
            {
                "value": 58288,
                "label": "Halstead",
                "label_ar": "هالستيد",
                "label_fr": "Halstead"
            },
            {
                "value": 56227,
                "label": "Saxtead",
                "label_ar": "ساكستيد",
                "label_fr": "Saxtead"
            },
            {
                "value": 57436,
                "label": "Aberlour",
                "label_ar": "أبرلور",
                "label_fr": "Aberlour"
            },
            {
                "value": 58253,
                "label": "Caister-on-Sea",
                "label_ar": "Caister-on-Sea",
                "label_fr": "Caister-on-Sea"
            },
            {
                "value": 57201,
                "label": "Reddish",
                "label_ar": "محمر",
                "label_fr": "Rougeâtre"
            },
            {
                "value": 57916,
                "label": "Selkirk",
                "label_ar": "سيلكيرك",
                "label_fr": "Selkirk"
            },
            {
                "value": 56070,
                "label": "Hertford",
                "label_ar": "هيرتفورد",
                "label_fr": "Hertford"
            },
            {
                "value": 56933,
                "label": "Churchill",
                "label_ar": "تشرشل",
                "label_fr": "Churchill"
            },
            {
                "value": 56166,
                "label": "Wigan",
                "label_ar": "ويغان",
                "label_fr": "Wigan"
            },
            {
                "value": 58444,
                "label": "Woodley",
                "label_ar": "وودلي",
                "label_fr": "Woodley"
            },
            {
                "value": 58225,
                "label": "Sileby",
                "label_ar": "سيليبي",
                "label_fr": "Sileby"
            },
            {
                "value": 57251,
                "label": "Three Legged Cross",
                "label_ar": "ثلاثة عبر أرجل",
                "label_fr": "Croix à trois pattes"
            },
            {
                "value": 56624,
                "label": "West Wickham",
                "label_ar": "ويست ويكهام",
                "label_fr": "West Wickham"
            },
            {
                "value": 58814,
                "label": "East Keal",
                "label_ar": "الشرق كيل",
                "label_fr": "East Keal"
            },
            {
                "value": 58620,
                "label": "Isleham",
                "label_ar": "Isleham",
                "label_fr": "Isleham"
            },
            {
                "value": 58580,
                "label": "Gomersal",
                "label_ar": "جومرسال",
                "label_fr": "Gomersal"
            },
            {
                "value": 57093,
                "label": "Leeds",
                "label_ar": "ليدز",
                "label_fr": "Leeds"
            },
            {
                "value": 57254,
                "label": "Broadway",
                "label_ar": "برودواي",
                "label_fr": "Broadway"
            },
            {
                "value": 56405,
                "label": "Carnforth",
                "label_ar": "كارنفورث",
                "label_fr": "Carnforth"
            },
            {
                "value": 56434,
                "label": "Harpenden",
                "label_ar": "هاربيندين",
                "label_fr": "Harpenden"
            },
            {
                "value": 57560,
                "label": "Pitlochry",
                "label_ar": "بيتلوكري",
                "label_fr": "Pitlochry"
            },
            {
                "value": 56441,
                "label": "Tredegar",
                "label_ar": "تريديغار",
                "label_fr": "Tredegar"
            },
            {
                "value": 58831,
                "label": "Moorside",
                "label_ar": "مورسايد",
                "label_fr": "Moorside"
            },
            {
                "value": 57684,
                "label": "Sidlesham",
                "label_ar": "Sidlesham",
                "label_fr": "Sidlesham"
            },
            {
                "value": 56880,
                "label": "Market Drayton",
                "label_ar": "سوق درايتون",
                "label_fr": "Marché Drayton"
            },
            {
                "value": 55884,
                "label": "Walsall",
                "label_ar": "والسال",
                "label_fr": "Walsall"
            },
            {
                "value": 56992,
                "label": "Cross",
                "label_ar": "تعبر",
                "label_fr": "Traverser"
            },
            {
                "value": 57376,
                "label": "Tilehurst",
                "label_ar": "تيلهيرست",
                "label_fr": "Tilehurst"
            },
            {
                "value": 57633,
                "label": "Alfold",
                "label_ar": "ألفولد",
                "label_fr": "Alfold"
            },
            {
                "value": 56128,
                "label": "Kidlington",
                "label_ar": "كيدلينجتون",
                "label_fr": "Kidlington"
            },
            {
                "value": 56391,
                "label": "Newark on Trent",
                "label_ar": "نيوارك على ترينت",
                "label_fr": "Newark sur Trent"
            },
            {
                "value": 57608,
                "label": "Muir of Ord",
                "label_ar": "موير من Ord",
                "label_fr": "Muir d'Ord"
            },
            {
                "value": 57419,
                "label": "Strathmiglo",
                "label_ar": "ستراثميغلو",
                "label_fr": "Strathmiglo"
            },
            {
                "value": 58581,
                "label": "Wendover",
                "label_ar": "ويندوفر",
                "label_fr": "Wendover"
            },
            {
                "value": 58240,
                "label": "Ticehurst",
                "label_ar": "تيشهرست",
                "label_fr": "Ticehurst"
            },
            {
                "value": 58064,
                "label": "Great Glemham",
                "label_ar": "جليمهام العظيم",
                "label_fr": "Grand Glemham"
            },
            {
                "value": 55934,
                "label": "Salisbury",
                "label_ar": "سالزبوري",
                "label_fr": "Salisbury"
            },
            {
                "value": 56236,
                "label": "Poulton le Fylde",
                "label_ar": "بولتون لو فيلدي",
                "label_fr": "Poulton le Fylde"
            },
            {
                "value": 58583,
                "label": "Weare",
                "label_ar": "نحن",
                "label_fr": "Nous sommes"
            },
            {
                "value": 58542,
                "label": "Moulton",
                "label_ar": "مولتون",
                "label_fr": "Moulton"
            },
            {
                "value": 56632,
                "label": "Hawley",
                "label_ar": "هاولي",
                "label_fr": "Hawley"
            },
            {
                "value": 58624,
                "label": "Plaistow",
                "label_ar": "بلايستو",
                "label_fr": "Plaistow"
            },
            {
                "value": 57084,
                "label": "Attleborough",
                "label_ar": "أتلبورو",
                "label_fr": "Attleborough"
            },
            {
                "value": 57246,
                "label": "Whisby",
                "label_ar": "ويسبي",
                "label_fr": "Whisby"
            },
            {
                "value": 56249,
                "label": "Worksop",
                "label_ar": "وركسوب",
                "label_fr": "Worksop"
            },
            {
                "value": 56206,
                "label": "Skegness",
                "label_ar": "سكيغنيس",
                "label_fr": "Skegness"
            },
            {
                "value": 56246,
                "label": "Dalton in Furness",
                "label_ar": "دالتون في فرنيس",
                "label_fr": "Dalton à Furness"
            },
            {
                "value": 57161,
                "label": "Overton",
                "label_ar": "أوفرتون",
                "label_fr": "Overton"
            },
            {
                "value": 57142,
                "label": "Patchway",
                "label_ar": "باتشواي",
                "label_fr": "Patchway"
            },
            {
                "value": 56847,
                "label": "Warlingham",
                "label_ar": "وارلينجهام",
                "label_fr": "Warlingham"
            },
            {
                "value": 57223,
                "label": "Silsden",
                "label_ar": "سيلسدن",
                "label_fr": "Silsden"
            },
            {
                "value": 56238,
                "label": "Lytham",
                "label_ar": "ليثام",
                "label_fr": "Lytham"
            },
            {
                "value": 58507,
                "label": "Diddington",
                "label_ar": "ديدنجتون",
                "label_fr": "Diddington"
            },
            {
                "value": 58691,
                "label": "Ovingdean",
                "label_ar": "Ovingdean",
                "label_fr": "Ovingdean"
            },
            {
                "value": 58615,
                "label": "Bishopstrow",
                "label_ar": "بيشوبسترو",
                "label_fr": "Bishopstrow"
            },
            {
                "value": 57207,
                "label": "Seaton",
                "label_ar": "سيتون",
                "label_fr": "Seaton"
            },
            {
                "value": 57945,
                "label": "Lyng",
                "label_ar": "لينج",
                "label_fr": "Lyng"
            },
            {
                "value": 58008,
                "label": "Hemsworth",
                "label_ar": "هيمسوورث",
                "label_fr": "Hemsworth"
            },
            {
                "value": 56007,
                "label": "South Hayling",
                "label_ar": "ساوث هايلينج",
                "label_fr": "South Hayling"
            },
            {
                "value": 57963,
                "label": "Flordon",
                "label_ar": "فلوردون",
                "label_fr": "Flordon"
            },
            {
                "value": 57063,
                "label": "Dromara",
                "label_ar": "درومارا",
                "label_fr": "Dromara"
            },
            {
                "value": 57258,
                "label": "Shoeburyness",
                "label_ar": "الحذاء",
                "label_fr": "Shoeburyness"
            },
            {
                "value": 57319,
                "label": "Huntspill",
                "label_ar": "هانتسبيل",
                "label_fr": "Huntspill"
            },
            {
                "value": 57899,
                "label": "East Tilbury",
                "label_ar": "شرق تيلبوري",
                "label_fr": "Tilbury est"
            },
            {
                "value": 58852,
                "label": "Oving",
                "label_ar": "أوفينج",
                "label_fr": "Oving"
            },
            {
                "value": 56394,
                "label": "Horley",
                "label_ar": "هورلي",
                "label_fr": "Horley"
            },
            {
                "value": 57000,
                "label": "Falmouth",
                "label_ar": "فالماوث",
                "label_fr": "Falmouth"
            },
            {
                "value": 56707,
                "label": "Millom",
                "label_ar": "ميلوم",
                "label_fr": "Millom"
            },
            {
                "value": 56407,
                "label": "Cleveleys",
                "label_ar": "كليفيليز",
                "label_fr": "Cleveleys"
            },
            {
                "value": 56377,
                "label": "Sowerby Bridge",
                "label_ar": "جسر سويربي",
                "label_fr": "Pont de Sowerby"
            },
            {
                "value": 57146,
                "label": "Woodsetts",
                "label_ar": "وودسيتس",
                "label_fr": "Woodsetts"
            },
            {
                "value": 57478,
                "label": "Petworth",
                "label_ar": "بيتورث",
                "label_fr": "Petworth"
            },
            {
                "value": 58446,
                "label": "Whaley Bridge",
                "label_ar": "جسر ويلي",
                "label_fr": "Pont de Whaley"
            },
            {
                "value": 58136,
                "label": "Talsarnau",
                "label_ar": "تالسارناو",
                "label_fr": "Talsarnau"
            },
            {
                "value": 57340,
                "label": "Midsomer Norton",
                "label_ar": "ميدسومر نورتون",
                "label_fr": "Midsomer Norton"
            },
            {
                "value": 58841,
                "label": "Fownhope",
                "label_ar": "فونهوب",
                "label_fr": "Fownhope"
            },
            {
                "value": 56354,
                "label": "Barking",
                "label_ar": "نباح",
                "label_fr": "Aboiement"
            },
            {
                "value": 57623,
                "label": "Sheering",
                "label_ar": "شيرنج",
                "label_fr": "Sheering"
            },
            {
                "value": 56997,
                "label": "Totternhoe",
                "label_ar": "Totternhoe",
                "label_fr": "Totternhoe"
            },
            {
                "value": 55939,
                "label": "Caldicot",
                "label_ar": "كالديكوت",
                "label_fr": "Caldicot"
            },
            {
                "value": 58616,
                "label": "Temple",
                "label_ar": "معبد",
                "label_fr": "Temple"
            },
            {
                "value": 57765,
                "label": "Worle",
                "label_ar": "وورل",
                "label_fr": "Worle"
            },
            {
                "value": 57714,
                "label": "Boldon Colliery",
                "label_ar": "منجم بولدون",
                "label_fr": "Mine de charbon Boldon"
            },
            {
                "value": 56042,
                "label": "Royal Tunbridge Wells",
                "label_ar": "رويال تونبريدج ويلز",
                "label_fr": "Royal Tunbridge Wells"
            },
            {
                "value": 56768,
                "label": "Longfield",
                "label_ar": "لونجفيلد",
                "label_fr": "Longfield"
            },
            {
                "value": 56985,
                "label": "Arundel",
                "label_ar": "أرونديل",
                "label_fr": "Arundel"
            },
            {
                "value": 56877,
                "label": "Tenterden",
                "label_ar": "تينتردين",
                "label_fr": "Tenterden"
            },
            {
                "value": 57656,
                "label": "Harlaxton",
                "label_ar": "هارلاكستون",
                "label_fr": "Harlaxton"
            },
            {
                "value": 58060,
                "label": "Great Milton",
                "label_ar": "جريت ميلتون",
                "label_fr": "Grand Milton"
            },
            {
                "value": 56344,
                "label": "Chatham",
                "label_ar": "تشاتام",
                "label_fr": "Chatham"
            },
            {
                "value": 56907,
                "label": "Manor",
                "label_ar": "مانور",
                "label_fr": "Manoir"
            },
            {
                "value": 56581,
                "label": "Grangemouth",
                "label_ar": "غرانجماوث",
                "label_fr": "Grangemouth"
            },
            {
                "value": 56980,
                "label": "Liphook",
                "label_ar": "ليبهوك",
                "label_fr": "Crochet à lèvres"
            },
            {
                "value": 58359,
                "label": "Willington Quay",
                "label_ar": "رصيف ويلينجتون",
                "label_fr": "Willington Quay"
            },
            {
                "value": 56838,
                "label": "Eastington",
                "label_ar": "ايستنجتون",
                "label_fr": "Eastington"
            },
            {
                "value": 56957,
                "label": "Padbury",
                "label_ar": "بادبيري",
                "label_fr": "Padbury"
            },
            {
                "value": 58226,
                "label": "Widmerpool",
                "label_ar": "ويدمربول",
                "label_fr": "Widmerpool"
            },
            {
                "value": 56093,
                "label": "Walton-on-the-Naze",
                "label_ar": "والتون أون ذا نيز",
                "label_fr": "Walton-sur-le-Naze"
            },
            {
                "value": 58118,
                "label": "Newport",
                "label_ar": "نيوبورت",
                "label_fr": "Newport"
            },
            {
                "value": 57468,
                "label": "Barkway",
                "label_ar": "باركواي",
                "label_fr": "Barkway"
            },
            {
                "value": 57120,
                "label": "Eastchurch",
                "label_ar": "إيستشيرش",
                "label_fr": "Eastchurch"
            },
            {
                "value": 56628,
                "label": "Wargrave",
                "label_ar": "ثعلب",
                "label_fr": "Wargrave"
            },
            {
                "value": 56680,
                "label": "Weedon Bec",
                "label_ar": "ويدون بيك",
                "label_fr": "Weedon Bec"
            },
            {
                "value": 58480,
                "label": "Hatton",
                "label_ar": "هاتون",
                "label_fr": "Hatton"
            },
            {
                "value": 56462,
                "label": "Bishop's Stortford",
                "label_ar": "بيشوب ستورتفورد",
                "label_fr": "Bishop's Stortford"
            },
            {
                "value": 58451,
                "label": "Astley",
                "label_ar": "آستلي",
                "label_fr": "Astley"
            },
            {
                "value": 56946,
                "label": "Newham",
                "label_ar": "نيوهام",
                "label_fr": "Newham"
            },
            {
                "value": 57153,
                "label": "Send",
                "label_ar": "إرسال",
                "label_fr": "Envoyer"
            },
            {
                "value": 58464,
                "label": "Horbury",
                "label_ar": "هوربوري",
                "label_fr": "Horbury"
            },
            {
                "value": 56102,
                "label": "Hornchurch",
                "label_ar": "هورنشيرش",
                "label_fr": "Hornchurch"
            },
            {
                "value": 57071,
                "label": "Braunstone",
                "label_ar": "براونستون",
                "label_fr": "Braunstone"
            },
            {
                "value": 58487,
                "label": "Coed-Talon",
                "label_ar": "Coed-Talon",
                "label_fr": "Coed-Talon"
            },
            {
                "value": 58395,
                "label": "Stalham",
                "label_ar": "ستالهام",
                "label_fr": "Stalham"
            },
            {
                "value": 58696,
                "label": "Charing Cross",
                "label_ar": "تشارنج الصليب",
                "label_fr": "Charing Cross"
            },
            {
                "value": 57177,
                "label": "Stoneleigh",
                "label_ar": "ستونيلي",
                "label_fr": "Stoneleigh"
            },
            {
                "value": 58548,
                "label": "Great Staughton",
                "label_ar": "جريت ستوتون",
                "label_fr": "Grand Staughton"
            },
            {
                "value": 57078,
                "label": "Broughton",
                "label_ar": "جلبت على",
                "label_fr": "Introduit le"
            },
            {
                "value": 57288,
                "label": "Box",
                "label_ar": "صندوق",
                "label_fr": "Boîte"
            },
            {
                "value": 56928,
                "label": "Abberton",
                "label_ar": "ابيرتون",
                "label_fr": "Abberton"
            },
            {
                "value": 57107,
                "label": "Toddington",
                "label_ar": "تودنجتون",
                "label_fr": "Toddington"
            },
            {
                "value": 56510,
                "label": "Tetbury",
                "label_ar": "Tetbury",
                "label_fr": "Tetbury"
            },
            {
                "value": 57057,
                "label": "Caistor",
                "label_ar": "قيستر",
                "label_fr": "Caistor"
            },
            {
                "value": 56739,
                "label": "Prestonpans",
                "label_ar": "بريستونبانز",
                "label_fr": "Prestonpans"
            },
            {
                "value": 58530,
                "label": "Parbold",
                "label_ar": "باربولد",
                "label_fr": "Parbold"
            },
            {
                "value": 56783,
                "label": "Woodbridge",
                "label_ar": "وودبريدج",
                "label_fr": "Woodbridge"
            },
            {
                "value": 57976,
                "label": "Grateley",
                "label_ar": "جراتلي",
                "label_fr": "Grateley"
            },
            {
                "value": 57148,
                "label": "Finchley",
                "label_ar": "فينشلي",
                "label_fr": "Finchley"
            },
            {
                "value": 56776,
                "label": "Highworth",
                "label_ar": "هايورث",
                "label_fr": "Highworth"
            },
            {
                "value": 58768,
                "label": "Broad Blunsdon",
                "label_ar": "برود بلونسدون",
                "label_fr": "Broad Blunsdon"
            },
            {
                "value": 58243,
                "label": "Skelmorlie",
                "label_ar": "سكيلمورلي",
                "label_fr": "Skelmorlie"
            },
            {
                "value": 56601,
                "label": "Coppull",
                "label_ar": "كوبول",
                "label_fr": "Coppull"
            },
            {
                "value": 56460,
                "label": "Ashby de la Zouch",
                "label_ar": "أشبي دي لا زوش",
                "label_fr": "Ashby de la Zouch"
            },
            {
                "value": 58009,
                "label": "St Ives",
                "label_ar": "سانت ايفيس",
                "label_fr": "St Ives"
            },
            {
                "value": 58676,
                "label": "Brookland",
                "label_ar": "بروكلاند",
                "label_fr": "Brookland"
            },
            {
                "value": 58258,
                "label": "Brownhills",
                "label_ar": "براونهيلز",
                "label_fr": "Brownhills"
            },
            {
                "value": 58885,
                "label": "Penrice",
                "label_ar": "بنريس",
                "label_fr": "Penrice"
            },
            {
                "value": 55977,
                "label": "Irvine",
                "label_ar": "ايرفين",
                "label_fr": "Irvine"
            },
            {
                "value": 57122,
                "label": "Woodford",
                "label_ar": "وودفورد",
                "label_fr": "Woodford"
            },
            {
                "value": 57507,
                "label": "Datchet",
                "label_ar": "داتشيت",
                "label_fr": "Datchet"
            },
            {
                "value": 57372,
                "label": "Downpatrick",
                "label_ar": "داونباتريك",
                "label_fr": "Downpatrick"
            },
            {
                "value": 58531,
                "label": "Long Stratton",
                "label_ar": "لونج ستراتون",
                "label_fr": "Long Stratton"
            },
            {
                "value": 57887,
                "label": "Oldmeldrum",
                "label_ar": "أولدميلدروم",
                "label_fr": "Oldmeldrum"
            },
            {
                "value": 56584,
                "label": "Berwick-Upon-Tweed",
                "label_ar": "بيرويك أبون تويد",
                "label_fr": "Berwick upon Tweed"
            },
            {
                "value": 58219,
                "label": "Lower Darwen",
                "label_ar": "داروين السفلى",
                "label_fr": "Darwen inférieur"
            },
            {
                "value": 57299,
                "label": "Hythe",
                "label_ar": "Hythe",
                "label_fr": "Hythe"
            },
            {
                "value": 56504,
                "label": "Armitage",
                "label_ar": "ارميتاج",
                "label_fr": "Armitage"
            },
            {
                "value": 58185,
                "label": "Llanwrda",
                "label_ar": "لانوردا",
                "label_fr": "Llanwrda"
            },
            {
                "value": 57961,
                "label": "Templecombe",
                "label_ar": "تمبلكومب",
                "label_fr": "Templecombe"
            },
            {
                "value": 56411,
                "label": "Glastonbury",
                "label_ar": "جلاستونبري",
                "label_fr": "Glastonbury"
            },
            {
                "value": 57169,
                "label": "Beaumont",
                "label_ar": "بومونت",
                "label_fr": "Beaumont"
            },
            {
                "value": 56763,
                "label": "Warfield",
                "label_ar": "ميدان الحرب",
                "label_fr": "Champ de guerre"
            },
            {
                "value": 57550,
                "label": "Fulmer",
                "label_ar": "فولمر",
                "label_fr": "Fulmer"
            },
            {
                "value": 57498,
                "label": "Great Gidding",
                "label_ar": "جيددينج العظيم",
                "label_fr": "Grand Gidding"
            },
            {
                "value": 56656,
                "label": "Calne",
                "label_ar": "كالني",
                "label_fr": "Calne"
            },
            {
                "value": 58820,
                "label": "Plaitford",
                "label_ar": "بليتفورد",
                "label_fr": "Plaitford"
            },
            {
                "value": 56004,
                "label": "Portsmouth",
                "label_ar": "بورتسموث",
                "label_fr": "Portsmouth"
            },
            {
                "value": 55922,
                "label": "Maesteg",
                "label_ar": "مايستيج",
                "label_fr": "Maesteg"
            },
            {
                "value": 58334,
                "label": "Crookham",
                "label_ar": "كروكهام",
                "label_fr": "Crookham"
            },
            {
                "value": 58021,
                "label": "Great Waldingfield",
                "label_ar": "جريت والدنجفيلد",
                "label_fr": "Great Waldingfield"
            },
            {
                "value": 58553,
                "label": "Cawston",
                "label_ar": "كاوستون",
                "label_fr": "Cawston"
            },
            {
                "value": 56203,
                "label": "Haverhill",
                "label_ar": "هافرهيل",
                "label_fr": "Haverhill"
            },
            {
                "value": 56477,
                "label": "Mitcham",
                "label_ar": "ميتشام",
                "label_fr": "Mitcham"
            },
            {
                "value": 56448,
                "label": "Stevenage",
                "label_ar": "ستيفنيج",
                "label_fr": "Stevenage"
            },
            {
                "value": 58333,
                "label": "Wilshamstead",
                "label_ar": "ويلشامستيد",
                "label_fr": "Wilshamstead"
            },
            {
                "value": 57999,
                "label": "New Buckenham",
                "label_ar": "نيو باكنهام",
                "label_fr": "New Buckenham"
            },
            {
                "value": 57958,
                "label": "Hilborough",
                "label_ar": "هيلبورو",
                "label_fr": "Hilborough"
            },
            {
                "value": 56083,
                "label": "Huddersfield",
                "label_ar": "هدرسفيلد",
                "label_fr": "Huddersfield"
            },
            {
                "value": 56313,
                "label": "Ledbury",
                "label_ar": "ليدبيري",
                "label_fr": "Ledbury"
            },
            {
                "value": 57110,
                "label": "Sutton on the Hill",
                "label_ar": "ساتون أون ذا هيل",
                "label_fr": "Sutton sur la colline"
            },
            {
                "value": 56200,
                "label": "Mildenhall",
                "label_ar": "ميلدنهال",
                "label_fr": "Mildenhall"
            },
            {
                "value": 57048,
                "label": "Kelso",
                "label_ar": "كيلسو",
                "label_fr": "Kelso"
            },
            {
                "value": 57302,
                "label": "Eastham",
                "label_ar": "إيستهام",
                "label_fr": "Eastham"
            },
            {
                "value": 57800,
                "label": "Wheathampstead",
                "label_ar": "ويثامبستيد",
                "label_fr": "Wheathampstead"
            },
            {
                "value": 58129,
                "label": "Long Ditton",
                "label_ar": "لونغ ديتون",
                "label_fr": "Long Ditton"
            },
            {
                "value": 58140,
                "label": "Gamlingay",
                "label_ar": "جاملينجاي",
                "label_fr": "Gamlingay"
            },
            {
                "value": 58697,
                "label": "City of Westminster",
                "label_ar": "مدينة وستمنستر",
                "label_fr": "Cité de Westminster"
            },
            {
                "value": 58398,
                "label": "Lenham",
                "label_ar": "لينهام",
                "label_fr": "Lenham"
            },
            {
                "value": 58870,
                "label": "Bloxwich",
                "label_ar": "بلوكسويتش",
                "label_fr": "Bloxwich"
            },
            {
                "value": 57699,
                "label": "Whickham",
                "label_ar": "ويكهام",
                "label_fr": "Whickham"
            },
            {
                "value": 56455,
                "label": "Thame",
                "label_ar": "ثايم",
                "label_fr": "Thame"
            },
            {
                "value": 56461,
                "label": "Saint Bees",
                "label_ar": "القديس بيز",
                "label_fr": "Saint Abeilles"
            },
            {
                "value": 57905,
                "label": "Gullane",
                "label_ar": "جولان",
                "label_fr": "Gullane"
            },
            {
                "value": 57209,
                "label": "Lower Brailes",
                "label_ar": "برايلز السفلى",
                "label_fr": "Brailes inférieurs"
            },
            {
                "value": 58212,
                "label": "Castlewellan",
                "label_ar": "Castlewellan",
                "label_fr": "Castlewellan"
            },
            {
                "value": 58469,
                "label": "Llantwit Fardre",
                "label_ar": "لانتويت فاردري",
                "label_fr": "Llantwit Fardre"
            },
            {
                "value": 56351,
                "label": "Brentwood",
                "label_ar": "برينتوود",
                "label_fr": "Brentwood"
            },
            {
                "value": 56602,
                "label": "Leek",
                "label_ar": "شخص عادي",
                "label_fr": "Poireau"
            },
            {
                "value": 58800,
                "label": "Moseley",
                "label_ar": "موزلي",
                "label_fr": "Moseley"
            },
            {
                "value": 57937,
                "label": "Stiffkey",
                "label_ar": "Stiffkey",
                "label_fr": "Stiffkey"
            },
            {
                "value": 58389,
                "label": "Ticknall",
                "label_ar": "Ticknall",
                "label_fr": "Ticknall"
            },
            {
                "value": 56195,
                "label": "Bognor Regis",
                "label_ar": "بوغنور ريجيس",
                "label_fr": "Bognor Regis"
            },
            {
                "value": 58180,
                "label": "Barton in Fabis",
                "label_ar": "بارتون في فابيس",
                "label_fr": "Barton dans Fabis"
            },
            {
                "value": 56416,
                "label": "Weston-super-Mare",
                "label_ar": "ويستون سوبر ماري",
                "label_fr": "Weston-super-Mare"
            },
            {
                "value": 56161,
                "label": "Sale",
                "label_ar": "تخفيض السعر",
                "label_fr": "Vente"
            },
            {
                "value": 58184,
                "label": "Weston",
                "label_ar": "ويستون",
                "label_fr": "Weston"
            },
            {
                "value": 57271,
                "label": "Portishead",
                "label_ar": "بورتيشيد",
                "label_fr": "Portishead"
            },
            {
                "value": 58516,
                "label": "Little Eaton",
                "label_ar": "ليتل إيتون",
                "label_fr": "Little Eaton"
            },
            {
                "value": 56513,
                "label": "Stalybridge",
                "label_ar": "ستاليبريدج",
                "label_fr": "Stalybridge"
            },
            {
                "value": 56282,
                "label": "Wolverhampton",
                "label_ar": "ولفرهامبتون",
                "label_fr": "Wolverhampton"
            },
            {
                "value": 56935,
                "label": "Godstone",
                "label_ar": "جودستون",
                "label_fr": "Godstone"
            },
            {
                "value": 57977,
                "label": "Pentraeth",
                "label_ar": "خماسي",
                "label_fr": "Pentraeth"
            },
            {
                "value": 58329,
                "label": "Barnes",
                "label_ar": "بارنز",
                "label_fr": "Barnes"
            },
            {
                "value": 58654,
                "label": "Midford",
                "label_ar": "ميدفورد",
                "label_fr": "Midford"
            },
            {
                "value": 57242,
                "label": "Bramley",
                "label_ar": "براملي",
                "label_fr": "Bramley"
            },
            {
                "value": 57781,
                "label": "Whitland",
                "label_ar": "ويتلاند",
                "label_fr": "Whitland"
            },
            {
                "value": 58452,
                "label": "Shirehampton",
                "label_ar": "شيرهامبتون",
                "label_fr": "Shirehampton"
            },
            {
                "value": 58754,
                "label": "Earls Colne",
                "label_ar": "إيرلز كولن",
                "label_fr": "Earls Colne"
            },
            {
                "value": 57710,
                "label": "Walton on the Hill",
                "label_ar": "والتون أون ذا هيل",
                "label_fr": "Walton sur la colline"
            },
            {
                "value": 57457,
                "label": "Milton",
                "label_ar": "ميلتون",
                "label_fr": "Milton"
            },
            {
                "value": 56846,
                "label": "Beccles",
                "label_ar": "بيكليس",
                "label_fr": "Beccles"
            },
            {
                "value": 57035,
                "label": "Stone Allerton",
                "label_ar": "ستون أليرتون",
                "label_fr": "Pierre Allerton"
            },
            {
                "value": 56792,
                "label": "Teynham",
                "label_ar": "تينهام",
                "label_fr": "Teynham"
            },
            {
                "value": 56343,
                "label": "Worthing",
                "label_ar": "ورذينج",
                "label_fr": "Worthing"
            },
            {
                "value": 58342,
                "label": "Eccles",
                "label_ar": "اكليس",
                "label_fr": "Eccles"
            },
            {
                "value": 56528,
                "label": "Weybridge",
                "label_ar": "ويبريدج",
                "label_fr": "Weybridge"
            },
            {
                "value": 57752,
                "label": "Chestfield",
                "label_ar": "تشيستفيلد",
                "label_fr": "Chestfield"
            },
            {
                "value": 56710,
                "label": "Saltcoats",
                "label_ar": "سالت كوتس",
                "label_fr": "Saltcoats"
            },
            {
                "value": 56399,
                "label": "Staines",
                "label_ar": "ستينز",
                "label_fr": "Staines"
            },
            {
                "value": 56401,
                "label": "Sandown",
                "label_ar": "سانداون",
                "label_fr": "Sandown"
            },
            {
                "value": 56530,
                "label": "Alton",
                "label_ar": "ألتون",
                "label_fr": "Alton"
            },
            {
                "value": 56350,
                "label": "Harwich",
                "label_ar": "هارويتش",
                "label_fr": "Harwich"
            },
            {
                "value": 57928,
                "label": "Grange",
                "label_ar": "جرانج",
                "label_fr": "Grange"
            },
            {
                "value": 56675,
                "label": "Hockley",
                "label_ar": "هوكلي",
                "label_fr": "Hockley"
            },
            {
                "value": 58755,
                "label": "Wealdstone",
                "label_ar": "ويلدستون",
                "label_fr": "Wealdstone"
            },
            {
                "value": 57827,
                "label": "Wootton",
                "label_ar": "ووتون",
                "label_fr": "Wootton"
            },
            {
                "value": 57310,
                "label": "Immingham",
                "label_ar": "إمينجهام",
                "label_fr": "Immingham"
            },
            {
                "value": 56730,
                "label": "Dronfield",
                "label_ar": "درونفيلد",
                "label_fr": "Dronfield"
            },
            {
                "value": 58862,
                "label": "West Grinstead",
                "label_ar": "ويست جرينستيد",
                "label_fr": "West Grinstead"
            },
            {
                "value": 58083,
                "label": "Byfield",
                "label_ar": "بيفيلد",
                "label_fr": "Byfield"
            },
            {
                "value": 58343,
                "label": "Ashmore",
                "label_ar": "أشمور",
                "label_fr": "Ashmore"
            },
            {
                "value": 58475,
                "label": "Earlston",
                "label_ar": "إيرلستون",
                "label_fr": "Earlston"
            },
            {
                "value": 58321,
                "label": "Bourton on the Water",
                "label_ar": "بورتون أون ذا ووتر",
                "label_fr": "Bourton sur l'eau"
            },
            {
                "value": 57335,
                "label": "Broughton",
                "label_ar": "جلبت على",
                "label_fr": "Introduit le"
            },
            {
                "value": 57380,
                "label": "Shirebrook",
                "label_ar": "شيربروك",
                "label_fr": "Shirebrook"
            },
            {
                "value": 57615,
                "label": "Bottesford",
                "label_ar": "بوتيسفورد",
                "label_fr": "Bottesford"
            },
            {
                "value": 56963,
                "label": "Stanwell",
                "label_ar": "ستانويل",
                "label_fr": "Stanwell"
            },
            {
                "value": 56347,
                "label": "Peterhead",
                "label_ar": "بيترهيد",
                "label_fr": "Peterhead"
            },
            {
                "value": 56054,
                "label": "Waterlooville",
                "label_ar": "واترلوفيل",
                "label_fr": "Waterlooville"
            },
            {
                "value": 55956,
                "label": "Gainsborough",
                "label_ar": "جينزبورو",
                "label_fr": "Gainsborough"
            },
            {
                "value": 57807,
                "label": "Henlow",
                "label_ar": "هينلو",
                "label_fr": "Henlow"
            },
            {
                "value": 58519,
                "label": "Barrowford",
                "label_ar": "باروفورد",
                "label_fr": "Barrowford"
            },
            {
                "value": 57296,
                "label": "Bishopton",
                "label_ar": "بيشوبتون",
                "label_fr": "Bishopton"
            },
            {
                "value": 57371,
                "label": "The Hyde",
                "label_ar": "الهايد",
                "label_fr": "Le Hyde"
            },
            {
                "value": 56708,
                "label": "Berkhamstead",
                "label_ar": "بيرخامستيد",
                "label_fr": "Berkhamstead"
            },
            {
                "value": 57245,
                "label": "Mayfair",
                "label_ar": "مايفير",
                "label_fr": "Mayfair"
            },
            {
                "value": 56388,
                "label": "Pontefract",
                "label_ar": "بونتفراكت",
                "label_fr": "Pontefract"
            },
            {
                "value": 57978,
                "label": "Llwyngwril",
                "label_ar": "Llwyngwril",
                "label_fr": "Llwyngwril"
            },
            {
                "value": 58861,
                "label": "Lower Beeding",
                "label_ar": "البيد السفلي",
                "label_fr": "Beeding inférieur"
            },
            {
                "value": 56215,
                "label": "Halstead",
                "label_ar": "هالستيد",
                "label_fr": "Halstead"
            },
            {
                "value": 55893,
                "label": "Wells",
                "label_ar": "آبار",
                "label_fr": "puits"
            },
            {
                "value": 56503,
                "label": "Bridgnorth",
                "label_ar": "بريدجنورث",
                "label_fr": "Bridgnorth"
            },
            {
                "value": 58007,
                "label": "Bangor",
                "label_ar": "بانجور",
                "label_fr": "Bangor"
            },
            {
                "value": 58858,
                "label": "Essendon",
                "label_ar": "اسيندون",
                "label_fr": "Essendon"
            },
            {
                "value": 58230,
                "label": "Harold Wood",
                "label_ar": "هارولد وود",
                "label_fr": "Harold Wood"
            },
            {
                "value": 58310,
                "label": "Littlebury",
                "label_ar": "ليتلبيري",
                "label_fr": "Littlebury"
            },
            {
                "value": 57013,
                "label": "Bromyard",
                "label_ar": "بروميارد",
                "label_fr": "Bromyard"
            },
            {
                "value": 56112,
                "label": "Beckenham",
                "label_ar": "بيكنهام",
                "label_fr": "Beckenham"
            },
            {
                "value": 56576,
                "label": "Heywood",
                "label_ar": "هيوود",
                "label_fr": "Heywood"
            },
            {
                "value": 56620,
                "label": "Cromer",
                "label_ar": "كرومر",
                "label_fr": "Cromer"
            },
            {
                "value": 58100,
                "label": "Naseby",
                "label_ar": "نصيبي",
                "label_fr": "Naseby"
            },
            {
                "value": 58478,
                "label": "Spondon",
                "label_ar": "سبوندون",
                "label_fr": "Spondon"
            },
            {
                "value": 57845,
                "label": "Brimscombe",
                "label_ar": "بريمسكومب",
                "label_fr": "Brimscombe"
            },
            {
                "value": 57602,
                "label": "Soham",
                "label_ar": "سهام",
                "label_fr": "Soham"
            },
            {
                "value": 58051,
                "label": "Inverkeithing",
                "label_ar": "Inverkeithing",
                "label_fr": "Inverkeithing"
            },
            {
                "value": 56664,
                "label": "Cullompton",
                "label_ar": "كولومبتون",
                "label_fr": "Cullompton"
            },
            {
                "value": 56897,
                "label": "Anlaby",
                "label_ar": "Anlaby",
                "label_fr": "Anlaby"
            },
            {
                "value": 57268,
                "label": "Wrangle",
                "label_ar": "رانجل",
                "label_fr": "Dispute"
            },
            {
                "value": 57182,
                "label": "Fairford",
                "label_ar": "فيرفورد",
                "label_fr": "Fairford"
            },
            {
                "value": 58273,
                "label": "Tisbury",
                "label_ar": "تيسبري",
                "label_fr": "Tisbury"
            },
            {
                "value": 57369,
                "label": "Gorton",
                "label_ar": "جورتون",
                "label_fr": "Gorton"
            },
            {
                "value": 58658,
                "label": "Hemswell",
                "label_ar": "هيمسويل",
                "label_fr": "Hemswell"
            },
            {
                "value": 58556,
                "label": "Sarratt",
                "label_ar": "سارات",
                "label_fr": "Sarratt"
            },
            {
                "value": 58678,
                "label": "Nuffield",
                "label_ar": "نافيلد",
                "label_fr": "Nuffield"
            },
            {
                "value": 56782,
                "label": "Iver",
                "label_ar": "إيفر",
                "label_fr": "Iver"
            },
            {
                "value": 58826,
                "label": "Ushaw Moor",
                "label_ar": "أوشو مور",
                "label_fr": "Ushaw Moor"
            },
            {
                "value": 57370,
                "label": "Padiham",
                "label_ar": "باديهام",
                "label_fr": "Padiham"
            },
            {
                "value": 58592,
                "label": "Gordon",
                "label_ar": "جوردون",
                "label_fr": "Gordon"
            },
            {
                "value": 57588,
                "label": "Hawes",
                "label_ar": "هاوز",
                "label_fr": "Hawes"
            },
            {
                "value": 56328,
                "label": "Twickenham",
                "label_ar": "تويكنهام",
                "label_fr": "Twickenham"
            },
            {
                "value": 58377,
                "label": "Felbridge",
                "label_ar": "فيلبريدج",
                "label_fr": "Felbridge"
            },
            {
                "value": 58535,
                "label": "Portaferry",
                "label_ar": "بورتافيري",
                "label_fr": "Portaferry"
            },
            {
                "value": 57509,
                "label": "Middleton",
                "label_ar": "ميدلتون",
                "label_fr": "Middleton"
            },
            {
                "value": 58234,
                "label": "Goring",
                "label_ar": "غورينغ",
                "label_fr": "Goring"
            },
            {
                "value": 57768,
                "label": "Stonesfield",
                "label_ar": "ستونزفيلد",
                "label_fr": "Stonesfield"
            },
            {
                "value": 57903,
                "label": "Dingwall",
                "label_ar": "دينجوال",
                "label_fr": "Dingwall"
            },
            {
                "value": 57196,
                "label": "Castlereagh",
                "label_ar": "كاسلريه",
                "label_fr": "Castlereagh"
            },
            {
                "value": 56153,
                "label": "Colne",
                "label_ar": "كولن",
                "label_fr": "Colne"
            },
            {
                "value": 57241,
                "label": "Broughton",
                "label_ar": "جلبت على",
                "label_fr": "Introduit le"
            },
            {
                "value": 57287,
                "label": "Hartley",
                "label_ar": "هارتلي",
                "label_fr": "Hartley"
            },
            {
                "value": 57512,
                "label": "More",
                "label_ar": "أكثر",
                "label_fr": "Plus"
            },
            {
                "value": 56758,
                "label": "Sidcup",
                "label_ar": "سيدكب",
                "label_fr": "Sidcup"
            },
            {
                "value": 57108,
                "label": "Hammersmith",
                "label_ar": "هامرسميث",
                "label_fr": "Hammersmith"
            },
            {
                "value": 56175,
                "label": "North Shields",
                "label_ar": "نورث شيلدز",
                "label_fr": "Boucliers du Nord"
            },
            {
                "value": 57524,
                "label": "Wincanton",
                "label_ar": "وينكانتون",
                "label_fr": "Wincanton"
            },
            {
                "value": 58160,
                "label": "Menheniot",
                "label_ar": "مينهينيوت",
                "label_fr": "Menheniot"
            },
            {
                "value": 58628,
                "label": "Stepps",
                "label_ar": "خطوات",
                "label_fr": "Stepps"
            },
            {
                "value": 57911,
                "label": "Hadleigh",
                "label_ar": "هادلي",
                "label_fr": "Hadleigh"
            },
            {
                "value": 57801,
                "label": "Briton Ferry",
                "label_ar": "بريتون فيري",
                "label_fr": "Briton Ferry"
            },
            {
                "value": 58830,
                "label": "Ystrad Mynach",
                "label_ar": "يستراد ميناك",
                "label_fr": "Ystrad Mynach"
            },
            {
                "value": 57914,
                "label": "Fivemiletown",
                "label_ar": "فايفميلتاون",
                "label_fr": "Fivemiletown"
            },
            {
                "value": 57610,
                "label": "Stretton on Fosse",
                "label_ar": "Stretton on Fosse",
                "label_fr": "Stretton sur Fosse"
            },
            {
                "value": 56274,
                "label": "Marlow",
                "label_ar": "مارلو",
                "label_fr": "Marlow"
            },
            {
                "value": 57923,
                "label": "Cogenhoe",
                "label_ar": "Cogenhoe",
                "label_fr": "Cogenhoe"
            },
            {
                "value": 57373,
                "label": "Cuffley",
                "label_ar": "كافلي",
                "label_fr": "Cuffley"
            },
            {
                "value": 58296,
                "label": "Wooburn",
                "label_ar": "ووبورن",
                "label_fr": "Wooburn"
            },
            {
                "value": 57711,
                "label": "Norbury",
                "label_ar": "نوربيري",
                "label_fr": "Norbury"
            },
            {
                "value": 57705,
                "label": "Purfleet",
                "label_ar": "بورفليت",
                "label_fr": "Purfleet"
            },
            {
                "value": 56705,
                "label": "Crossgar",
                "label_ar": "كروسجار",
                "label_fr": "Crossgar"
            },
            {
                "value": 57769,
                "label": "Laxton",
                "label_ar": "لاكستون",
                "label_fr": "Laxton"
            },
            {
                "value": 58371,
                "label": "Slawitt",
                "label_ar": "سلويت",
                "label_fr": "Slawitt"
            },
            {
                "value": 56293,
                "label": "Crewe",
                "label_ar": "كرو",
                "label_fr": "Crewe"
            },
            {
                "value": 57190,
                "label": "Woodhouse",
                "label_ar": "وودهاوس",
                "label_fr": "Maison de bois"
            },
            {
                "value": 56078,
                "label": "Barnsley",
                "label_ar": "بارنسلي",
                "label_fr": "Barnsley"
            },
            {
                "value": 58866,
                "label": "Aberfoyle",
                "label_ar": "أبيرفويل",
                "label_fr": "Aberfoyle"
            },
            {
                "value": 58770,
                "label": "Wheaton Aston",
                "label_ar": "ويتون أستون",
                "label_fr": "Wheaton Aston"
            },
            {
                "value": 58633,
                "label": "Cricklewood",
                "label_ar": "كريكلوود",
                "label_fr": "Cricklewood"
            },
            {
                "value": 58824,
                "label": "Quedgeley",
                "label_ar": "كويدجلي",
                "label_fr": "Quedgeley"
            },
            {
                "value": 56095,
                "label": "Gravesend",
                "label_ar": "جريفسيند",
                "label_fr": "Gravesend"
            },
            {
                "value": 55907,
                "label": "Bath",
                "label_ar": "حمام",
                "label_fr": "Une baignoire"
            },
            {
                "value": 56958,
                "label": "Radstock",
                "label_ar": "رادستوك",
                "label_fr": "Radstock"
            },
            {
                "value": 58537,
                "label": "Waringstown",
                "label_ar": "وارينجستاون",
                "label_fr": "Waringstown"
            },
            {
                "value": 57943,
                "label": "North Kilworth",
                "label_ar": "شمال كيلورث",
                "label_fr": "North Kilworth"
            },
            {
                "value": 56949,
                "label": "Molesey",
                "label_ar": "موليسي",
                "label_fr": "Molesey"
            },
            {
                "value": 55906,
                "label": "Christchurch",
                "label_ar": "كرايستشيرش",
                "label_fr": "Christchurch"
            },
            {
                "value": 58033,
                "label": "Barrow",
                "label_ar": "رابية",
                "label_fr": "Brouette"
            },
            {
                "value": 58597,
                "label": "Kingston",
                "label_ar": "كينغستون",
                "label_fr": "Kingston"
            },
            {
                "value": 56172,
                "label": "Hartlepool",
                "label_ar": "هارتلبول",
                "label_fr": "Hartlepool"
            },
            {
                "value": 58665,
                "label": "Southwark",
                "label_ar": "ساوثوارك",
                "label_fr": "Southwark"
            },
            {
                "value": 57076,
                "label": "Bootle",
                "label_ar": "بوتل",
                "label_fr": "Bootle"
            },
            {
                "value": 56660,
                "label": "Lingfield",
                "label_ar": "لينجفيلد",
                "label_fr": "Lingfield"
            },
            {
                "value": 58567,
                "label": "Tywardreath",
                "label_ar": "تيوردريث",
                "label_fr": "Tywardreath"
            },
            {
                "value": 56967,
                "label": "Treharris",
                "label_ar": "تريهاريس",
                "label_fr": "Treharris"
            },
            {
                "value": 56497,
                "label": "Quinton",
                "label_ar": "كوينتون",
                "label_fr": "Quinton"
            },
            {
                "value": 58280,
                "label": "Chilton",
                "label_ar": "شيلتون",
                "label_fr": "Chilton"
            },
            {
                "value": 58308,
                "label": "Brenchley",
                "label_ar": "برينشلي",
                "label_fr": "Brenchley"
            },
            {
                "value": 57144,
                "label": "Stoke upon Tern",
                "label_ar": "ستوك أبون تيرن",
                "label_fr": "Stoke upon Tern"
            },
            {
                "value": 58627,
                "label": "Newtonmore",
                "label_ar": "نيوتنمور",
                "label_fr": "Newtonmore"
            },
            {
                "value": 58621,
                "label": "Annan",
                "label_ar": "عنان",
                "label_fr": "Annan"
            },
            {
                "value": 56458,
                "label": "Perth",
                "label_ar": "بيرث",
                "label_fr": "Perth"
            },
            {
                "value": 58833,
                "label": "Beeston",
                "label_ar": "بيستون",
                "label_fr": "Beeston"
            },
            {
                "value": 56679,
                "label": "Cookham",
                "label_ar": "كوكهام",
                "label_fr": "Cookham"
            },
            {
                "value": 56803,
                "label": "Bourne End",
                "label_ar": "بورن إند",
                "label_fr": "Bourne End"
            },
            {
                "value": 58192,
                "label": "Scalby",
                "label_ar": "سكالبي",
                "label_fr": "Scalby"
            },
            {
                "value": 58582,
                "label": "Groombridge",
                "label_ar": "جرومبريدج",
                "label_fr": "Groombridge"
            },
            {
                "value": 57877,
                "label": "Looe",
                "label_ar": "لو",
                "label_fr": "Looe"
            },
            {
                "value": 58404,
                "label": "Penwortham",
                "label_ar": "بينورثام",
                "label_fr": "Penwortham"
            },
            {
                "value": 58430,
                "label": "Portstewart",
                "label_ar": "بورتستيوارت",
                "label_fr": "Portstewart"
            },
            {
                "value": 58773,
                "label": "Penkridge",
                "label_ar": "بنكريدج",
                "label_fr": "Penkridge"
            },
            {
                "value": 57832,
                "label": "Brixworth",
                "label_ar": "بريكسوورث",
                "label_fr": "Brixworth"
            },
            {
                "value": 57917,
                "label": "Ringmer",
                "label_ar": "رينمر",
                "label_fr": "Ringmer"
            },
            {
                "value": 58641,
                "label": "Sturmer",
                "label_ar": "ستورمر",
                "label_fr": "Sturmer"
            },
            {
                "value": 56682,
                "label": "Ashton in Makerfield",
                "label_ar": "أشتون في ماكرفيلد",
                "label_fr": "Ashton à Makerfield"
            },
            {
                "value": 58818,
                "label": "Dingestow",
                "label_ar": "Dingestow",
                "label_fr": "Dingestow"
            },
            {
                "value": 56057,
                "label": "Enfield",
                "label_ar": "انفيلد",
                "label_fr": "Enfield"
            },
            {
                "value": 57318,
                "label": "Thornton",
                "label_ar": "ثورنتون",
                "label_fr": "Thornton"
            },
            {
                "value": 57897,
                "label": "Turriff",
                "label_ar": "توريف",
                "label_fr": "Turriff"
            },
            {
                "value": 56996,
                "label": "Southwold",
                "label_ar": "ساوث وولد",
                "label_fr": "Southwold"
            },
            {
                "value": 57864,
                "label": "Kingussie",
                "label_ar": "Kingussie",
                "label_fr": "Kingussie"
            },
            {
                "value": 58806,
                "label": "Hampton",
                "label_ar": "هامبتون",
                "label_fr": "Hampton"
            },
            {
                "value": 58785,
                "label": "Hadlow",
                "label_ar": "هادلو",
                "label_fr": "Hadlow"
            },
            {
                "value": 58506,
                "label": "Powick",
                "label_ar": "بويك",
                "label_fr": "Powick"
            },
            {
                "value": 57738,
                "label": "Langstone",
                "label_ar": "لانجستون",
                "label_fr": "Langstone"
            },
            {
                "value": 58412,
                "label": "East Hoathly",
                "label_ar": "الشرق هوثلي",
                "label_fr": "East Hoathly"
            },
            {
                "value": 55865,
                "label": "Alcester",
                "label_ar": "السيستر",
                "label_fr": "Alcester"
            },
            {
                "value": 56408,
                "label": "Burnley",
                "label_ar": "بيرنلي",
                "label_fr": "Burnley"
            },
            {
                "value": 56955,
                "label": "Pocklington",
                "label_ar": "بوكلينجتون",
                "label_fr": "Pocklington"
            },
            {
                "value": 58282,
                "label": "Theale",
                "label_ar": "ثيل",
                "label_fr": "Theale"
            },
            {
                "value": 56706,
                "label": "Ballymena",
                "label_ar": "باليمينا",
                "label_fr": "Ballymena"
            },
            {
                "value": 57005,
                "label": "Birtley",
                "label_ar": "بيرتلي",
                "label_fr": "Birtley"
            },
            {
                "value": 57437,
                "label": "Cotgrave",
                "label_ar": "كوتجراف",
                "label_fr": "Cotgrave"
            },
            {
                "value": 57012,
                "label": "Bolsover",
                "label_ar": "بولسوفر",
                "label_fr": "Bolsover"
            },
            {
                "value": 57536,
                "label": "Kimbolton",
                "label_ar": "كيمبولتون",
                "label_fr": "Kimbolton"
            },
            {
                "value": 55894,
                "label": "Swanage",
                "label_ar": "سواناج",
                "label_fr": "Swanage"
            },
            {
                "value": 56423,
                "label": "Bushey",
                "label_ar": "بوشي",
                "label_fr": "Bushey"
            },
            {
                "value": 58251,
                "label": "Llansantffraid-ym-Mechain",
                "label_ar": "لانسانتتخوف- ym- ميشين",
                "label_fr": "Llansantffraid-ym-Mechain"
            },
            {
                "value": 58457,
                "label": "Wrington",
                "label_ar": "وينجتون",
                "label_fr": "Wrington"
            },
            {
                "value": 56476,
                "label": "Shipley",
                "label_ar": "شيبلي",
                "label_fr": "Shipley"
            },
            {
                "value": 57085,
                "label": "Perivale",
                "label_ar": "بريفال",
                "label_fr": "Perivale"
            },
            {
                "value": 56538,
                "label": "Felixstowe",
                "label_ar": "فيليكسستو",
                "label_fr": "Felixstowe"
            },
            {
                "value": 57306,
                "label": "Merton",
                "label_ar": "ميرتون",
                "label_fr": "Merton"
            },
            {
                "value": 58162,
                "label": "Blackwater",
                "label_ar": "مياه سوداء",
                "label_fr": "Eau noire"
            },
            {
                "value": 58384,
                "label": "Goostrey",
                "label_ar": "Goostrey",
                "label_fr": "Goostrey"
            },
            {
                "value": 58494,
                "label": "Clutton",
                "label_ar": "كلاتون",
                "label_fr": "Clutton"
            },
            {
                "value": 56214,
                "label": "Grimsby",
                "label_ar": "غريمسبي",
                "label_fr": "Grimsby"
            },
            {
                "value": 58875,
                "label": "Brooke",
                "label_ar": "بروك",
                "label_fr": "Brooke"
            },
            {
                "value": 58409,
                "label": "Bromham",
                "label_ar": "برومهام",
                "label_fr": "Bromham"
            },
            {
                "value": 55933,
                "label": "Bridgend",
                "label_ar": "بريدجند",
                "label_fr": "Bridgend"
            },
            {
                "value": 56633,
                "label": "Snodland",
                "label_ar": "سنودلاند",
                "label_fr": "Snodland"
            },
            {
                "value": 58650,
                "label": "Rock",
                "label_ar": "صخرة",
                "label_fr": "Roche"
            },
            {
                "value": 57761,
                "label": "Binsted",
                "label_ar": "بينستيد",
                "label_fr": "Binsted"
            },
            {
                "value": 57718,
                "label": "Freckleton",
                "label_ar": "فريكليتون",
                "label_fr": "Freckleton"
            },
            {
                "value": 58810,
                "label": "Abersychan",
                "label_ar": "ابرسيشان",
                "label_fr": "Abersychan"
            },
            {
                "value": 58844,
                "label": "Church Leigh",
                "label_ar": "كنيسة ليه",
                "label_fr": "Église Leigh"
            },
            {
                "value": 56765,
                "label": "Saltburn-by-the-Sea",
                "label_ar": "سالتبيرن باي ذا سي",
                "label_fr": "Saltburn-by-the-Sea"
            },
            {
                "value": 57730,
                "label": "Moulton St Michael",
                "label_ar": "مولتون سانت مايكل",
                "label_fr": "Moulton St Michael"
            },
            {
                "value": 56075,
                "label": "Leeds",
                "label_ar": "ليدز",
                "label_fr": "Leeds"
            },
            {
                "value": 58175,
                "label": "Silverdale",
                "label_ar": "سيلفرديل",
                "label_fr": "Silverdale"
            },
            {
                "value": 57788,
                "label": "Turvey",
                "label_ar": "تورفي",
                "label_fr": "Turvey"
            },
            {
                "value": 57311,
                "label": "Barrow upon Humber",
                "label_ar": "بارو على هامبر",
                "label_fr": "Barrow sur Humber"
            },
            {
                "value": 58209,
                "label": "Rhos-on-Sea",
                "label_ar": "روس أون سي",
                "label_fr": "Rhos-on-Sea"
            },
            {
                "value": 57264,
                "label": "Chandlers Ford",
                "label_ar": "تشاندلر فورد",
                "label_fr": "Chandlers Ford"
            },
            {
                "value": 57522,
                "label": "Arthog",
                "label_ar": "أرثوغ",
                "label_fr": "Arthog"
            },
            {
                "value": 58358,
                "label": "Hoylake",
                "label_ar": "هويلاك",
                "label_fr": "Hoylake"
            },
            {
                "value": 56389,
                "label": "Ashfield",
                "label_ar": "أشفيلد",
                "label_fr": "Ashfield"
            },
            {
                "value": 57317,
                "label": "Beer",
                "label_ar": "بيرة",
                "label_fr": "Bière"
            },
            {
                "value": 57520,
                "label": "Shifnal",
                "label_ar": "شيفنال",
                "label_fr": "Shifnal"
            },
            {
                "value": 58362,
                "label": "East Hanningfield",
                "label_ar": "شرق هانينجفيلد",
                "label_fr": "East Hanningfield"
            },
            {
                "value": 56960,
                "label": "Alva",
                "label_ar": "ألفا",
                "label_fr": "Alva"
            },
            {
                "value": 56196,
                "label": "Huntingdon",
                "label_ar": "هانتينجدون",
                "label_fr": "Huntingdon"
            },
            {
                "value": 57320,
                "label": "Isham",
                "label_ar": "Isham",
                "label_fr": "Isham"
            },
            {
                "value": 57128,
                "label": "Biggar",
                "label_ar": "بيغار",
                "label_fr": "Biggar"
            },
            {
                "value": 57918,
                "label": "Hawkhurst",
                "label_ar": "هوخورست",
                "label_fr": "Hawkhurst"
            },
            {
                "value": 56616,
                "label": "Newtownabbey",
                "label_ar": "نيوتاونبي",
                "label_fr": "Newtownabbey"
            },
            {
                "value": 56087,
                "label": "Knottingley",
                "label_ar": "نوتنجلي",
                "label_fr": "Knottingley"
            },
            {
                "value": 56481,
                "label": "East Grinstead",
                "label_ar": "الشرق جرينستيد",
                "label_fr": "East Grinstead"
            },
            {
                "value": 56966,
                "label": "Ferryside",
                "label_ar": "فيريسايد",
                "label_fr": "Ferryside"
            },
            {
                "value": 55931,
                "label": "Llanelli",
                "label_ar": "يانيلي",
                "label_fr": "Llanelli"
            },
            {
                "value": 58748,
                "label": "Salthouse",
                "label_ar": "سالتهاوس",
                "label_fr": "Salthouse"
            },
            {
                "value": 57377,
                "label": "Guisborough",
                "label_ar": "غيسبورو",
                "label_fr": "Guisborough"
            },
            {
                "value": 56072,
                "label": "Heckmondwike",
                "label_ar": "هيكموندويك",
                "label_fr": "Heckmondwike"
            },
            {
                "value": 58508,
                "label": "Saint Annes on the Sea",
                "label_ar": "سانت أنيه على البحر",
                "label_fr": "Saint Annes sur la mer"
            },
            {
                "value": 57366,
                "label": "Lee-on-the-Solent",
                "label_ar": "لي أون ذا سولنت",
                "label_fr": "Lee-sur-le-Solent"
            },
            {
                "value": 57883,
                "label": "Horsted Keynes",
                "label_ar": "هورستيد كينز",
                "label_fr": "Horsted Keynes"
            },
            {
                "value": 55897,
                "label": "Burnham-on-Sea",
                "label_ar": "بورنهام أون سي",
                "label_fr": "Burnham-on-Sea"
            },
            {
                "value": 56742,
                "label": "Atherton",
                "label_ar": "أثرتون",
                "label_fr": "Atherton"
            },
            {
                "value": 58372,
                "label": "Glenboig",
                "label_ar": "جلينبويج",
                "label_fr": "Glenboig"
            },
            {
                "value": 57725,
                "label": "East Linton",
                "label_ar": "شرق لينتون",
                "label_fr": "East Linton"
            },
            {
                "value": 57641,
                "label": "Longdown",
                "label_ar": "Longdown",
                "label_fr": "Longdown"
            },
            {
                "value": 57661,
                "label": "Princes Risborough",
                "label_ar": "الأمراء ريسبورو",
                "label_fr": "Princes Risborough"
            },
            {
                "value": 56436,
                "label": "Merthyr Tydfil",
                "label_ar": "مرثير تيدفيل",
                "label_fr": "Merthyr Tydfil"
            },
            {
                "value": 57073,
                "label": "Heath",
                "label_ar": "هيث",
                "label_fr": "Bruyère"
            },
            {
                "value": 56123,
                "label": "Milton Keynes",
                "label_ar": "ميلتون كينز",
                "label_fr": "Milton Keynes"
            },
            {
                "value": 56136,
                "label": "Kings Langley",
                "label_ar": "كينغز لانجلي",
                "label_fr": "Kings Langley"
            },
            {
                "value": 57855,
                "label": "Stewarton",
                "label_ar": "ستيوارتون",
                "label_fr": "Stewarton"
            },
            {
                "value": 56119,
                "label": "Orpington",
                "label_ar": "أوربينجتون",
                "label_fr": "Orpington"
            },
            {
                "value": 56138,
                "label": "Flitwick",
                "label_ar": "فليتويك",
                "label_fr": "Flitwick"
            },
            {
                "value": 56189,
                "label": "Corbridge",
                "label_ar": "كوربريدج",
                "label_fr": "Corbridge"
            },
            {
                "value": 56965,
                "label": "Bucknell",
                "label_ar": "باكنيل",
                "label_fr": "Bucknell"
            },
            {
                "value": 56696,
                "label": "Teddington",
                "label_ar": "تدينغتون",
                "label_fr": "Teddington"
            },
            {
                "value": 58460,
                "label": "Poynton",
                "label_ar": "بوينتون",
                "label_fr": "Poynton"
            },
            {
                "value": 57639,
                "label": "Gayton Thorpe",
                "label_ar": "جايتون ثورب",
                "label_fr": "Gayton Thorpe"
            },
            {
                "value": 57636,
                "label": "Cardigan",
                "label_ar": "كارديجان",
                "label_fr": "Cardigan"
            },
            {
                "value": 57275,
                "label": "Cuckfield",
                "label_ar": "كوكفيلد",
                "label_fr": "Cuckfield"
            },
            {
                "value": 57966,
                "label": "Slapton",
                "label_ar": "سلابتون",
                "label_fr": "Slapton"
            },
            {
                "value": 57895,
                "label": "Little Gaddesden",
                "label_ar": "ليتل جاديسدن",
                "label_fr": "Petit Gaddesden"
            },
            {
                "value": 56345,
                "label": "Greenhithe",
                "label_ar": "غرينهيث",
                "label_fr": "Greenhithe"
            },
            {
                "value": 56754,
                "label": "Teignmouth",
                "label_ar": "تيغنماوث",
                "label_fr": "Teignmouth"
            },
            {
                "value": 57259,
                "label": "Drumchapel",
                "label_ar": "طبل",
                "label_fr": "Drumchapel"
            },
            {
                "value": 58331,
                "label": "Eversholt",
                "label_ar": "إيفرسهولت",
                "label_fr": "Eversholt"
            },
            {
                "value": 58299,
                "label": "Whetstone",
                "label_ar": "مشحذ",
                "label_fr": "Pierre à aiguiser"
            },
            {
                "value": 57486,
                "label": "Totton",
                "label_ar": "توتون",
                "label_fr": "Totton"
            },
            {
                "value": 56471,
                "label": "Wickham Bishops",
                "label_ar": "ويكهام بيشوبس",
                "label_fr": "Évêques de Wickham"
            },
            {
                "value": 56723,
                "label": "Cleckheaton",
                "label_ar": "كليكهيتون",
                "label_fr": "Cleckheaton"
            },
            {
                "value": 57950,
                "label": "Blaenau-Ffestiniog",
                "label_ar": "Blaenau-Ffestiniog",
                "label_fr": "Blaenau-Ffestiniog"
            },
            {
                "value": 57125,
                "label": "Lymington",
                "label_ar": "ليمنجتون",
                "label_fr": "Lymington"
            },
            {
                "value": 56258,
                "label": "Llanfair-Dyffryn-Clwyd",
                "label_ar": "لانفير ديفرين كلويد",
                "label_fr": "Llanfair-Dyffryn-Clwyd"
            },
            {
                "value": 58239,
                "label": "Bodelwyddan",
                "label_ar": "بودلويدان",
                "label_fr": "Bodelwyddan"
            },
            {
                "value": 58432,
                "label": "Swaythling",
                "label_ar": "سويثينج",
                "label_fr": "Swaythling"
            },
            {
                "value": 55942,
                "label": "Coalville",
                "label_ar": "كولفيل",
                "label_fr": "Coalville"
            },
            {
                "value": 57314,
                "label": "Southbourne",
                "label_ar": "ساوثبورن",
                "label_fr": "Southbourne"
            },
            {
                "value": 57159,
                "label": "Turnberry",
                "label_ar": "تيرنبيري",
                "label_fr": "Turnberry"
            },
            {
                "value": 58071,
                "label": "Trusthorpe",
                "label_ar": "Trusthorpe",
                "label_fr": "Trusthorpe"
            },
            {
                "value": 58376,
                "label": "Bulwell",
                "label_ar": "بولويل",
                "label_fr": "Bulwell"
            },
            {
                "value": 58355,
                "label": "Whittlesford",
                "label_ar": "ويتلسفورد",
                "label_fr": "Whittlesford"
            },
            {
                "value": 56109,
                "label": "Banstead",
                "label_ar": "بانستيد",
                "label_fr": "Banstead"
            },
            {
                "value": 56934,
                "label": "Finchampstead",
                "label_ar": "فينشامبستيد",
                "label_fr": "Finchampstead"
            },
            {
                "value": 56690,
                "label": "Newton-le-Willows",
                "label_ar": "نيوتن لو ويلوز",
                "label_fr": "Newton-le-Willows"
            },
            {
                "value": 57492,
                "label": "Staplehurst",
                "label_ar": "ستابلهيرست",
                "label_fr": "Staplehurst"
            },
            {
                "value": 58807,
                "label": "Roade",
                "label_ar": "رود",
                "label_fr": "Roade"
            },
            {
                "value": 56275,
                "label": "Wokingham",
                "label_ar": "ووكينغهام",
                "label_fr": "Wokingham"
            },
            {
                "value": 56751,
                "label": "North Berwick",
                "label_ar": "نورث بيرويك",
                "label_fr": "North Berwick"
            },
            {
                "value": 56769,
                "label": "Yarm",
                "label_ar": "يارم",
                "label_fr": "Yarm"
            },
            {
                "value": 58265,
                "label": "Bembridge",
                "label_ar": "بيمبريدج",
                "label_fr": "Bembridge"
            },
            {
                "value": 56912,
                "label": "Coupar Angus",
                "label_ar": "كوبر انجوس",
                "label_fr": "Coupar Angus"
            },
            {
                "value": 57532,
                "label": "Offord Cluny",
                "label_ar": "أوفورد كلوني",
                "label_fr": "Offord Cluny"
            },
            {
                "value": 56829,
                "label": "Leigh",
                "label_ar": "لي",
                "label_fr": "Leigh"
            },
            {
                "value": 57215,
                "label": "Market Weighton",
                "label_ar": "وزن السوق",
                "label_fr": "Poids du marché"
            },
            {
                "value": 57531,
                "label": "Rogiet",
                "label_ar": "روجيت",
                "label_fr": "Rogiet"
            },
            {
                "value": 56107,
                "label": "Sheerness",
                "label_ar": "شيرنس",
                "label_fr": "Sheerness"
            },
            {
                "value": 58145,
                "label": "Belbroughton",
                "label_ar": "بيلبروتون",
                "label_fr": "Belbroughton"
            },
            {
                "value": 57564,
                "label": "Drayton",
                "label_ar": "درايتون",
                "label_fr": "Drayton"
            },
            {
                "value": 57046,
                "label": "Swaffham",
                "label_ar": "سوافهام",
                "label_fr": "Swaffham"
            },
            {
                "value": 57783,
                "label": "West Mersea",
                "label_ar": "غرب مرسى",
                "label_fr": "West Mersea"
            },
            {
                "value": 56536,
                "label": "Bury St Edmunds",
                "label_ar": "بيري سانت إدموندز",
                "label_fr": "Bury St Edmunds"
            },
            {
                "value": 56155,
                "label": "Leyland",
                "label_ar": "ليلاند",
                "label_fr": "Leyland"
            },
            {
                "value": 58713,
                "label": "Felsted",
                "label_ar": "فيلستيد",
                "label_fr": "Felsted"
            },
            {
                "value": 56082,
                "label": "Bridlington",
                "label_ar": "بريدلينغتون",
                "label_fr": "Bridlington"
            },
            {
                "value": 56667,
                "label": "Stanton Fitzwarren",
                "label_ar": "ستانتون فيتزوارين",
                "label_fr": "Stanton Fitzwarren"
            },
            {
                "value": 56181,
                "label": "Brandon",
                "label_ar": "براندون",
                "label_fr": "Brandon"
            },
            {
                "value": 58170,
                "label": "Cowden",
                "label_ar": "كاودن",
                "label_fr": "Cowden"
            },
            {
                "value": 58147,
                "label": "Broughton",
                "label_ar": "جلبت على",
                "label_fr": "Introduit le"
            },
            {
                "value": 57789,
                "label": "Haddenham",
                "label_ar": "هادنهام",
                "label_fr": "Haddenham"
            },
            {
                "value": 58228,
                "label": "Doagh",
                "label_ar": "دوغ",
                "label_fr": "Doagh"
            },
            {
                "value": 56556,
                "label": "Widnes",
                "label_ar": "ويدنس",
                "label_fr": "Widnes"
            },
            {
                "value": 56858,
                "label": "Crickhowell",
                "label_ar": "كريكهويل",
                "label_fr": "Crickhowell"
            },
            {
                "value": 56000,
                "label": "Aldershot",
                "label_ar": "ألدرشوت",
                "label_fr": "Aldershot"
            },
            {
                "value": 56689,
                "label": "Camborne",
                "label_ar": "كامبورن",
                "label_fr": "Camborne"
            },
            {
                "value": 57578,
                "label": "Dulverton",
                "label_ar": "دولفيرتون",
                "label_fr": "Dulverton"
            },
            {
                "value": 56674,
                "label": "Magherafelt",
                "label_ar": "ماغيرافيلت",
                "label_fr": "Magherafelt"
            },
            {
                "value": 56130,
                "label": "Chipping Norton",
                "label_ar": "تشيبينج نورتون",
                "label_fr": "Chipping Norton"
            },
            {
                "value": 57344,
                "label": "Low Ham",
                "label_ar": "لو هام",
                "label_fr": "Jambon bas"
            },
            {
                "value": 57165,
                "label": "Seven Kings",
                "label_ar": "سبعة ملوك",
                "label_fr": "Sept rois"
            },
            {
                "value": 56292,
                "label": "Winsford",
                "label_ar": "وينسفورد",
                "label_fr": "Winsford"
            },
            {
                "value": 57830,
                "label": "Dunchurch",
                "label_ar": "Dunchurch",
                "label_fr": "Dunchurch"
            },
            {
                "value": 56914,
                "label": "Chipstead",
                "label_ar": "تشيبستيد",
                "label_fr": "Chipstead"
            },
            {
                "value": 55950,
                "label": "Buxton",
                "label_ar": "بوكستون",
                "label_fr": "Buxton"
            },
            {
                "value": 57179,
                "label": "Bluntisham",
                "label_ar": "بلنتيشام",
                "label_fr": "Bluntisham"
            },
            {
                "value": 56036,
                "label": "Hove",
                "label_ar": "هوف",
                "label_fr": "Hove"
            },
            {
                "value": 56993,
                "label": "Ballycastle",
                "label_ar": "باليكاسل",
                "label_fr": "Ballycastle"
            },
            {
                "value": 58075,
                "label": "Elstead",
                "label_ar": "إلستيد",
                "label_fr": "Elstead"
            },
            {
                "value": 56121,
                "label": "Burgess Hill",
                "label_ar": "بيرجس هيل",
                "label_fr": "Burgess Hill"
            },
            {
                "value": 58201,
                "label": "Horbling",
                "label_ar": "هوربلنج",
                "label_fr": "Horbling"
            },
            {
                "value": 57168,
                "label": "Princethorpe",
                "label_ar": "برينسيثورب",
                "label_fr": "Princethorpe"
            },
            {
                "value": 58662,
                "label": "Itchen",
                "label_ar": "إيتشين",
                "label_fr": "Itchen"
            },
            {
                "value": 57392,
                "label": "Ravenstone",
                "label_ar": "رافينستون",
                "label_fr": "Ravenstone"
            },
            {
                "value": 57816,
                "label": "North Hykeham",
                "label_ar": "شمال هيكهام",
                "label_fr": "North Hykeham"
            },
            {
                "value": 57267,
                "label": "Farningham",
                "label_ar": "فارنينجهام",
                "label_fr": "Farningham"
            },
            {
                "value": 57934,
                "label": "Watchet",
                "label_ar": "واتشيت",
                "label_fr": "Watchet"
            },
            {
                "value": 58845,
                "label": "Drymen",
                "label_ar": "دريمين",
                "label_fr": "Drymen"
            },
            {
                "value": 57506,
                "label": "Brixton Hill",
                "label_ar": "بريكستون هيل",
                "label_fr": "Brixton Hill"
            },
            {
                "value": 58669,
                "label": "Dawley",
                "label_ar": "داولي",
                "label_fr": "Dawley"
            },
            {
                "value": 55903,
                "label": "Poole",
                "label_ar": "بول",
                "label_fr": "Poole"
            },
            {
                "value": 55948,
                "label": "Lutterworth",
                "label_ar": "Lutterworth",
                "label_fr": "Lutterworth"
            },
            {
                "value": 57576,
                "label": "Bayford",
                "label_ar": "بايفورد",
                "label_fr": "Bayford"
            },
            {
                "value": 56733,
                "label": "St Asaph",
                "label_ar": "سانت أساف",
                "label_fr": "St Asaph"
            },
            {
                "value": 57170,
                "label": "Hurworth",
                "label_ar": "هورورث",
                "label_fr": "Hurworth"
            },
            {
                "value": 56639,
                "label": "Stamford",
                "label_ar": "ستامفورد",
                "label_fr": "Stamford"
            },
            {
                "value": 57965,
                "label": "Milton",
                "label_ar": "ميلتون",
                "label_fr": "Milton"
            },
            {
                "value": 56373,
                "label": "Edenbridge",
                "label_ar": "إيدنبريدج",
                "label_fr": "Edenbridge"
            },
            {
                "value": 56254,
                "label": "East Retford",
                "label_ar": "إيست ريتفورد",
                "label_fr": "East Retford"
            },
            {
                "value": 56894,
                "label": "Beverley",
                "label_ar": "بيفرلي",
                "label_fr": "Beverley"
            },
            {
                "value": 56327,
                "label": "Belper",
                "label_ar": "بيلبر",
                "label_fr": "Belper"
            },
            {
                "value": 58763,
                "label": "Weald",
                "label_ar": "ويلد",
                "label_fr": "Weald"
            },
            {
                "value": 58248,
                "label": "Castle Cary",
                "label_ar": "قلعة كاري",
                "label_fr": "Château Cary"
            },
            {
                "value": 56224,
                "label": "Bury",
                "label_ar": "دفن",
                "label_fr": "Enterrer"
            },
            {
                "value": 57552,
                "label": "Shotley Gate",
                "label_ar": "بوابة شوتلي",
                "label_fr": "Porte Shotley"
            },
            {
                "value": 57015,
                "label": "Tillicoultry",
                "label_ar": "دواجن الحراثة",
                "label_fr": "Tillicoultry"
            },
            {
                "value": 57733,
                "label": "Bothwell",
                "label_ar": "على حد سواء بشكل جيد",
                "label_fr": "Bothwell"
            },
            {
                "value": 57067,
                "label": "Stone",
                "label_ar": "حصاة",
                "label_fr": "Calcul"
            },
            {
                "value": 56272,
                "label": "Martock",
                "label_ar": "مارتوك",
                "label_fr": "Martock"
            },
            {
                "value": 58034,
                "label": "Aghalee",
                "label_ar": "اغالي",
                "label_fr": "Aghalee"
            },
            {
                "value": 57253,
                "label": "Radlett",
                "label_ar": "رادليت",
                "label_fr": "Radlett"
            },
            {
                "value": 57464,
                "label": "Hindon",
                "label_ar": "هندون",
                "label_fr": "Hindon"
            },
            {
                "value": 55891,
                "label": "Fareham",
                "label_ar": "فارهام",
                "label_fr": "Fareham"
            },
            {
                "value": 58420,
                "label": "Leek Wootton",
                "label_ar": "ليك ووتون",
                "label_fr": "Poireau Wootton"
            },
            {
                "value": 58571,
                "label": "Treherbert",
                "label_ar": "تريهربيرت",
                "label_fr": "Treherbert"
            },
            {
                "value": 56201,
                "label": "Saint Neots",
                "label_ar": "سانت نيوتس",
                "label_fr": "Saint Neots"
            },
            {
                "value": 58897,
                "label": "West Langdon",
                "label_ar": "غرب لانغدون",
                "label_fr": "West Langdon"
            },
            {
                "value": 58900,
                "label": "Alrewas",
                "label_ar": "الرواس",
                "label_fr": "Alrewas"
            },
            {
                "value": 58649,
                "label": "Pitsford",
                "label_ar": "بيتسفورد",
                "label_fr": "Pitsford"
            },
            {
                "value": 57743,
                "label": "Rosslea",
                "label_ar": "روسليا",
                "label_fr": "Rosslea"
            },
            {
                "value": 56212,
                "label": "Peterborough",
                "label_ar": "بيتربورو",
                "label_fr": "Peterborough"
            },
            {
                "value": 56841,
                "label": "Ponteland",
                "label_ar": "بونتلاند",
                "label_fr": "Ponteland"
            },
            {
                "value": 55911,
                "label": "Southampton",
                "label_ar": "ساوثهامبتون",
                "label_fr": "Southampton"
            },
            {
                "value": 57228,
                "label": "Heston",
                "label_ar": "هيستون",
                "label_fr": "Heston"
            },
            {
                "value": 58207,
                "label": "Trefonen",
                "label_ar": "تريفونين",
                "label_fr": "Trefonen"
            },
            {
                "value": 57676,
                "label": "Lewisham",
                "label_ar": "لويشام",
                "label_fr": "Lewisham"
            },
            {
                "value": 56523,
                "label": "Bridgwater",
                "label_ar": "بريدجواتر",
                "label_fr": "Bridgwater"
            },
            {
                "value": 56519,
                "label": "Milford on Sea",
                "label_ar": "ميلفورد على البحر",
                "label_fr": "Milford on Sea"
            },
            {
                "value": 57094,
                "label": "Eltham",
                "label_ar": "إلثام",
                "label_fr": "Eltham"
            },
            {
                "value": 57403,
                "label": "Eaglesham",
                "label_ar": "ايجلشام",
                "label_fr": "Eaglesham"
            },
            {
                "value": 56991,
                "label": "Anstruther",
                "label_ar": "أنستروثر",
                "label_fr": "Anstruther"
            },
            {
                "value": 57407,
                "label": "South Petherton",
                "label_ar": "جنوب بيترتون",
                "label_fr": "South Petherton"
            },
            {
                "value": 56176,
                "label": "Redcar",
                "label_ar": "سيارة حمراء",
                "label_fr": "Voiture rouge"
            },
            {
                "value": 56508,
                "label": "Brook",
                "label_ar": "بروك",
                "label_fr": "Ruisseau"
            },
            {
                "value": 57523,
                "label": "Malmesbury",
                "label_ar": "مالميسبري",
                "label_fr": "Malmesbury"
            },
            {
                "value": 57399,
                "label": "Dulwich",
                "label_ar": "دولويتش",
                "label_fr": "Dulwich"
            },
            {
                "value": 56951,
                "label": "Pewsey",
                "label_ar": "بيوسي",
                "label_fr": "Pewsey"
            },
            {
                "value": 57059,
                "label": "Whitby",
                "label_ar": "ويتبي",
                "label_fr": "Whitby"
            },
            {
                "value": 57026,
                "label": "Llandovery",
                "label_ar": "لاندوفري",
                "label_fr": "Llandovery"
            },
            {
                "value": 58062,
                "label": "Taynuilt",
                "label_ar": "تاينويلت",
                "label_fr": "Taynuilt"
            },
            {
                "value": 58585,
                "label": "Coggeshall",
                "label_ar": "كوجيشال",
                "label_fr": "Coggeshall"
            },
            {
                "value": 56135,
                "label": "Oxford",
                "label_ar": "أكسفورد",
                "label_fr": "Oxford"
            },
            {
                "value": 58159,
                "label": "Wingate",
                "label_ar": "وينجيت",
                "label_fr": "Wingate"
            },
            {
                "value": 58045,
                "label": "Hingham",
                "label_ar": "هنغهام",
                "label_fr": "Hingham"
            },
            {
                "value": 58601,
                "label": "Hornsey",
                "label_ar": "هورنسي",
                "label_fr": "Hornsey"
            },
            {
                "value": 58231,
                "label": "Dorney",
                "label_ar": "دورني",
                "label_fr": "Dorney"
            },
            {
                "value": 57896,
                "label": "Stanbridge",
                "label_ar": "ستانبريدج",
                "label_fr": "Stanbridge"
            },
            {
                "value": 57025,
                "label": "Workington",
                "label_ar": "وركينجتون",
                "label_fr": "Workington"
            },
            {
                "value": 57727,
                "label": "Gipping",
                "label_ar": "الولع",
                "label_fr": "Gipping"
            },
            {
                "value": 56356,
                "label": "Morpeth",
                "label_ar": "موربيث",
                "label_fr": "Morpeth"
            },
            {
                "value": 56348,
                "label": "Glossop",
                "label_ar": "جلوسوب",
                "label_fr": "Glossop"
            },
            {
                "value": 56149,
                "label": "Stockport",
                "label_ar": "ستوكبورت",
                "label_fr": "Stockport"
            },
            {
                "value": 56549,
                "label": "Mauchline",
                "label_ar": "ماوشلين",
                "label_fr": "Mauchline"
            },
            {
                "value": 56651,
                "label": "Brandon",
                "label_ar": "براندون",
                "label_fr": "Brandon"
            },
            {
                "value": 56694,
                "label": "Goole",
                "label_ar": "جوول",
                "label_fr": "Goole"
            },
            {
                "value": 58326,
                "label": "Old Windsor",
                "label_ar": "وندسور القديمة",
                "label_fr": "Vieux Windsor"
            },
            {
                "value": 57732,
                "label": "Histon",
                "label_ar": "هيستون",
                "label_fr": "Histon"
            },
            {
                "value": 57337,
                "label": "Beauly",
                "label_ar": "بولي",
                "label_fr": "Beauly"
            },
            {
                "value": 58249,
                "label": "Capel",
                "label_ar": "كابيل",
                "label_fr": "Capel"
            },
            {
                "value": 58762,
                "label": "Hellesdon",
                "label_ar": "هيليسدون",
                "label_fr": "Hellesdon"
            },
            {
                "value": 56845,
                "label": "Barton upon Humber",
                "label_ar": "بارتون أبون هامبر",
                "label_fr": "Barton sur Humber"
            },
            {
                "value": 58780,
                "label": "Spittal",
                "label_ar": "البصاق",
                "label_fr": "Spittal"
            },
            {
                "value": 56451,
                "label": "Lidlington",
                "label_ar": "ليدلينجتون",
                "label_fr": "Lidlington"
            },
            {
                "value": 57088,
                "label": "Brierfield",
                "label_ar": "بريرفيلد",
                "label_fr": "Brierfield"
            },
            {
                "value": 57735,
                "label": "Square",
                "label_ar": "ميدان",
                "label_fr": "Carré"
            },
            {
                "value": 58899,
                "label": "Blackheath",
                "label_ar": "بلاكهيث",
                "label_fr": "Blackheath"
            },
            {
                "value": 58179,
                "label": "Blaenwaun",
                "label_ar": "Blaenwaun",
                "label_fr": "Blaenwaun"
            },
            {
                "value": 58832,
                "label": "Aldermaston",
                "label_ar": "ألدرماستون",
                "label_fr": "Aldermaston"
            },
            {
                "value": 56734,
                "label": "Rochford",
                "label_ar": "رشفورد",
                "label_fr": "Rochford"
            },
            {
                "value": 57022,
                "label": "Meanwood",
                "label_ar": "مينوود",
                "label_fr": "Meanwood"
            },
            {
                "value": 57338,
                "label": "Teddington",
                "label_ar": "تدينغتون",
                "label_fr": "Teddington"
            },
            {
                "value": 58777,
                "label": "Sidcot",
                "label_ar": "سيدكوت",
                "label_fr": "Sidcot"
            },
            {
                "value": 58031,
                "label": "Juniper Green",
                "label_ar": "جونيبر جرين",
                "label_fr": "Vert genévrier"
            },
            {
                "value": 58682,
                "label": "Annalong",
                "label_ar": "أنالونج",
                "label_fr": "Annalong"
            },
            {
                "value": 56378,
                "label": "Pudsey",
                "label_ar": "بودسي",
                "label_fr": "Pudsey"
            },
            {
                "value": 57341,
                "label": "Uddingston",
                "label_ar": "أودينجستون",
                "label_fr": "Uddingston"
            },
            {
                "value": 57487,
                "label": "Bletchley",
                "label_ar": "بلتشلي",
                "label_fr": "Bletchley"
            },
            {
                "value": 57787,
                "label": "Hatch",
                "label_ar": "فقس",
                "label_fr": "la trappe"
            },
            {
                "value": 56501,
                "label": "Middlewich",
                "label_ar": "Middlewich",
                "label_fr": "Middlewich"
            },
            {
                "value": 58382,
                "label": "Ullesthorpe",
                "label_ar": "أوليثورب",
                "label_fr": "Ullesthorpe"
            },
            {
                "value": 58496,
                "label": "Highcliffe",
                "label_ar": "هايكليف",
                "label_fr": "Highcliffe"
            },
            {
                "value": 56857,
                "label": "Ruthin",
                "label_ar": "روثين",
                "label_fr": "Ruthin"
            },
            {
                "value": 57946,
                "label": "Heckington",
                "label_ar": "هيكينجتون",
                "label_fr": "Heckington"
            },
            {
                "value": 58882,
                "label": "Snaresbrook",
                "label_ar": "سناريسبروك",
                "label_fr": "Snaresbrook"
            },
            {
                "value": 56889,
                "label": "Granby",
                "label_ar": "جرانبي",
                "label_fr": "Granby"
            },
            {
                "value": 57086,
                "label": "Tunstall",
                "label_ar": "Tunstall",
                "label_fr": "Tunstall"
            },
            {
                "value": 55935,
                "label": "Porth",
                "label_ar": "بورث",
                "label_fr": "Porth"
            },
            {
                "value": 56785,
                "label": "Fleetwood",
                "label_ar": "فليتوود",
                "label_fr": "Fleetwood"
            },
            {
                "value": 57740,
                "label": "Nettleton",
                "label_ar": "نيتلتون",
                "label_fr": "Nettleton"
            },
            {
                "value": 58290,
                "label": "Lapworth",
                "label_ar": "لابورث",
                "label_fr": "Lapworth"
            },
            {
                "value": 57742,
                "label": "Greenwich",
                "label_ar": "غرينتش",
                "label_fr": "Greenwich"
            },
            {
                "value": 58102,
                "label": "Nettlebed",
                "label_ar": "نيتلبيد",
                "label_fr": "Ortie"
            },
            {
                "value": 57544,
                "label": "Windermere",
                "label_ar": "ويندرمير",
                "label_fr": "Windermere"
            },
            {
                "value": 57997,
                "label": "Charmouth",
                "label_ar": "شارماوث",
                "label_fr": "Charmouth"
            },
            {
                "value": 57294,
                "label": "Dunoon",
                "label_ar": "دنون",
                "label_fr": "Dunoon"
            },
            {
                "value": 57833,
                "label": "Harwell",
                "label_ar": "هارويل",
                "label_fr": "Harwell"
            },
            {
                "value": 58643,
                "label": "Egton",
                "label_ar": "إيجتون",
                "label_fr": "Egton"
            },
            {
                "value": 56668,
                "label": "Downham Market",
                "label_ar": "سوق داونهام",
                "label_fr": "Marché de Downham"
            },
            {
                "value": 55898,
                "label": "Liskeard",
                "label_ar": "ليسكيرد",
                "label_fr": "Liskeard"
            },
            {
                "value": 57709,
                "label": "Polmont",
                "label_ar": "بولمونت",
                "label_fr": "Polmont"
            },
            {
                "value": 56654,
                "label": "Burry Port",
                "label_ar": "ميناء بري",
                "label_fr": "Burry Port"
            },
            {
                "value": 58701,
                "label": "Lupton",
                "label_ar": "لوبتون",
                "label_fr": "Lupton"
            },
            {
                "value": 57091,
                "label": "Knighton",
                "label_ar": "نايتون",
                "label_fr": "Knighton"
            },
            {
                "value": 58544,
                "label": "Metheringham",
                "label_ar": "ميثيرنغهام",
                "label_fr": "Metheringham"
            },
            {
                "value": 56979,
                "label": "Minehead",
                "label_ar": "ماينهيد",
                "label_fr": "Minehead"
            },
            {
                "value": 57493,
                "label": "Great Fransham",
                "label_ar": "جريت فرانشام",
                "label_fr": "Grand Fransham"
            },
            {
                "value": 58298,
                "label": "Crofton",
                "label_ar": "كروفتون",
                "label_fr": "Crofton"
            },
            {
                "value": 58418,
                "label": "Lavendon",
                "label_ar": "لافندون",
                "label_fr": "Lavendon"
            },
            {
                "value": 57612,
                "label": "Llangollen",
                "label_ar": "لانجولين",
                "label_fr": "Llangollen"
            },
            {
                "value": 57906,
                "label": "Bozeat",
                "label_ar": "بوزيت",
                "label_fr": "Bozeat"
            },
            {
                "value": 58053,
                "label": "Conon Bridge",
                "label_ar": "جسر كونون",
                "label_fr": "Pont de Conon"
            },
            {
                "value": 56366,
                "label": "Stockton-on-Tees",
                "label_ar": "ستوكتون أون تيز",
                "label_fr": "Stockton-on-Tees"
            },
            {
                "value": 56983,
                "label": "Brasted",
                "label_ar": "براستيد",
                "label_fr": "Brasted"
            },
            {
                "value": 56863,
                "label": "Brackley",
                "label_ar": "براكلي",
                "label_fr": "Brackley"
            },
            {
                "value": 56157,
                "label": "Bolton",
                "label_ar": "بولتون",
                "label_fr": "Boulonner"
            },
            {
                "value": 56146,
                "label": "Cheadle",
                "label_ar": "تشيدل",
                "label_fr": "Cheadle"
            },
            {
                "value": 58000,
                "label": "Tarbert",
                "label_ar": "تاربرت",
                "label_fr": "Tarbert"
            },
            {
                "value": 57893,
                "label": "Haydon Bridge",
                "label_ar": "جسر هايدون",
                "label_fr": "Pont de Haydon"
            },
            {
                "value": 57020,
                "label": "Sedbergh",
                "label_ar": "سيدبيرج",
                "label_fr": "Sedbergh"
            },
            {
                "value": 57418,
                "label": "Arbroath",
                "label_ar": "أربروث",
                "label_fr": "Arbroath"
            },
            {
                "value": 55943,
                "label": "Grantham",
                "label_ar": "جرانثام",
                "label_fr": "Grantham"
            },
            {
                "value": 56211,
                "label": "Oakham",
                "label_ar": "أوكهام",
                "label_fr": "Oakham"
            },
            {
                "value": 57499,
                "label": "Hemingstone",
                "label_ar": "همنجستون",
                "label_fr": "Hemingstone"
            },
            {
                "value": 57737,
                "label": "Aston Clinton",
                "label_ar": "أستون كلينتون",
                "label_fr": "Aston Clinton"
            },
            {
                "value": 58497,
                "label": "Burton Pidsea",
                "label_ar": "بيرتون بيدسي",
                "label_fr": "Burton Pidsea"
            },
            {
                "value": 57967,
                "label": "Kelty",
                "label_ar": "كيلتي",
                "label_fr": "Kelty"
            },
            {
                "value": 58703,
                "label": "Stoke Newington",
                "label_ar": "ستوك نيوينجتون",
                "label_fr": "Stoke Newington"
            },
            {
                "value": 58751,
                "label": "Milton",
                "label_ar": "ميلتون",
                "label_fr": "Milton"
            },
            {
                "value": 57458,
                "label": "Keswick",
                "label_ar": "كيسويك",
                "label_fr": "Keswick"
            },
            {
                "value": 56241,
                "label": "Rawtenstall",
                "label_ar": "Rawtenstall",
                "label_fr": "Rawtenstall"
            },
            {
                "value": 56796,
                "label": "Shrivenham",
                "label_ar": "شريفنهام",
                "label_fr": "Shrivenham"
            },
            {
                "value": 57875,
                "label": "Spetisbury",
                "label_ar": "سبيتسبري",
                "label_fr": "Spetisbury"
            },
            {
                "value": 57280,
                "label": "Treuddyn",
                "label_ar": "تريودين",
                "label_fr": "Treuddyn"
            },
            {
                "value": 58503,
                "label": "Fangfoss",
                "label_ar": "فانجفوس",
                "label_fr": "Fangfoss"
            },
            {
                "value": 58270,
                "label": "Pontesbury",
                "label_ar": "بونتيسبري",
                "label_fr": "Pontesbury"
            },
            {
                "value": 58549,
                "label": "Smarden",
                "label_ar": "سماردن",
                "label_fr": "Smarden"
            },
            {
                "value": 56357,
                "label": "Penrith",
                "label_ar": "بنريث",
                "label_fr": "Penrith"
            },
            {
                "value": 56417,
                "label": "Launceston",
                "label_ar": "لونسيستون",
                "label_fr": "Launceston"
            },
            {
                "value": 57751,
                "label": "Oban",
                "label_ar": "أوبان",
                "label_fr": "Oban"
            },
            {
                "value": 56255,
                "label": "Mansfield",
                "label_ar": "مانسفيلد",
                "label_fr": "Mansfield"
            },
            {
                "value": 58747,
                "label": "Waltham",
                "label_ar": "والثام",
                "label_fr": "Waltham"
            },
            {
                "value": 57804,
                "label": "Yeadon",
                "label_ar": "ييدون",
                "label_fr": "Yeadon"
            },
            {
                "value": 56542,
                "label": "Brighouse",
                "label_ar": "برايهاوس",
                "label_fr": "Brighouse"
            },
            {
                "value": 56836,
                "label": "Hanwell",
                "label_ar": "هانويل",
                "label_fr": "Hanwell"
            },
            {
                "value": 56445,
                "label": "Treorchy",
                "label_ar": "تريورشي",
                "label_fr": "Treorchy"
            },
            {
                "value": 57304,
                "label": "Little Paxton",
                "label_ar": "ليتل باكستون",
                "label_fr": "Petit Paxton"
            },
            {
                "value": 56048,
                "label": "Emsworth",
                "label_ar": "إمسوورث",
                "label_fr": "Emsworth"
            },
            {
                "value": 56559,
                "label": "Lydney",
                "label_ar": "ليدني",
                "label_fr": "Lydney"
            },
            {
                "value": 57429,
                "label": "Easingwold",
                "label_ar": "إيزينجولد",
                "label_fr": "Easingwold"
            },
            {
                "value": 58067,
                "label": "Brandesburton",
                "label_ar": "براندسبورتون",
                "label_fr": "Brandesburton"
            },
            {
                "value": 56867,
                "label": "Stromness",
                "label_ar": "عجب",
                "label_fr": "Stromness"
            },
            {
                "value": 57622,
                "label": "Wyverstone",
                "label_ar": "يفيرستون",
                "label_fr": "Wyverstone"
            },
            {
                "value": 58671,
                "label": "Upper Slaughter",
                "label_ar": "الذبح العلوي",
                "label_fr": "Abattage supérieur"
            },
            {
                "value": 58214,
                "label": "Rowlands Castle",
                "label_ar": "قلعة رولاندز",
                "label_fr": "Château de Rowlands"
            },
            {
                "value": 56444,
                "label": "Llangattock",
                "label_ar": "لانجاتوك",
                "label_fr": "Llangattock"
            },
            {
                "value": 58803,
                "label": "Mayfield",
                "label_ar": "مايفيلد",
                "label_fr": "Mayfield"
            },
            {
                "value": 58090,
                "label": "Helmsley",
                "label_ar": "هيلمسلي",
                "label_fr": "Helmsley"
            },
            {
                "value": 58042,
                "label": "Napton on the Hill",
                "label_ar": "نابتون على التل",
                "label_fr": "Napton sur la colline"
            },
            {
                "value": 56011,
                "label": "Kent",
                "label_ar": "كينت",
                "label_fr": "Kent"
            },
            {
                "value": 58267,
                "label": "Empingham",
                "label_ar": "امبينجهام",
                "label_fr": "Empingham"
            },
            {
                "value": 57675,
                "label": "Kempston",
                "label_ar": "كيمبستون",
                "label_fr": "Kempston"
            },
            {
                "value": 57027,
                "label": "Rutherglen",
                "label_ar": "روثرجلين",
                "label_fr": "Rutherglen"
            },
            {
                "value": 58784,
                "label": "Ratcliffe on Soar",
                "label_ar": "راتكليف على حلق",
                "label_fr": "Ratcliffe sur Soar"
            },
            {
                "value": 56129,
                "label": "Luton",
                "label_ar": "لوتون",
                "label_fr": "Luton"
            },
            {
                "value": 56419,
                "label": "Swindon",
                "label_ar": "سويندون",
                "label_fr": "Swindon"
            },
            {
                "value": 56120,
                "label": "Malden",
                "label_ar": "مالدن",
                "label_fr": "Malden"
            },
            {
                "value": 58012,
                "label": "Boscastle",
                "label_ar": "بوسكاسل",
                "label_fr": "Boscastle"
            },
            {
                "value": 57220,
                "label": "Crediton",
                "label_ar": "كريديتون",
                "label_fr": "Crediton"
            },
            {
                "value": 57151,
                "label": "Islington",
                "label_ar": "إيسلينجتون",
                "label_fr": "Islington"
            },
            {
                "value": 57650,
                "label": "Bruton",
                "label_ar": "بروتون",
                "label_fr": "Bruton"
            },
            {
                "value": 58196,
                "label": "Hightown",
                "label_ar": "هايت تاون",
                "label_fr": "Hightown"
            },
            {
                "value": 57613,
                "label": "Herstmonceux",
                "label_ar": "هيرستمونسيوكس",
                "label_fr": "Herstmonceux"
            },
            {
                "value": 55966,
                "label": "Largs",
                "label_ar": "كبير",
                "label_fr": "Largs"
            },
            {
                "value": 56704,
                "label": "Dumbarton",
                "label_ar": "دمبارتون",
                "label_fr": "Dumbarton"
            },
            {
                "value": 58502,
                "label": "Tideswell",
                "label_ar": "تايدزويل",
                "label_fr": "Tideswell"
            },
            {
                "value": 57582,
                "label": "Dymock",
                "label_ar": "ديموك",
                "label_fr": "Dymock"
            },
            {
                "value": 56326,
                "label": "Warwick",
                "label_ar": "وارويك",
                "label_fr": "Warwick"
            },
            {
                "value": 57058,
                "label": "Gretton",
                "label_ar": "جريتون",
                "label_fr": "Gretton"
            },
            {
                "value": 56298,
                "label": "Kidderminster",
                "label_ar": "كيدرمينستر",
                "label_fr": "Kidderminster"
            },
            {
                "value": 57473,
                "label": "Castleton",
                "label_ar": "كاسلتون",
                "label_fr": "Castleton"
            },
            {
                "value": 57121,
                "label": "Beckton",
                "label_ar": "بيكتون",
                "label_fr": "Beckton"
            },
            {
                "value": 58848,
                "label": "Oakhill",
                "label_ar": "اوكيل",
                "label_fr": "Oakhill"
            },
            {
                "value": 58817,
                "label": "Great Barton",
                "label_ar": "جريت بارتون",
                "label_fr": "Grand Barton"
            },
            {
                "value": 56795,
                "label": "Cam",
                "label_ar": "كام",
                "label_fr": "Came"
            },
            {
                "value": 58708,
                "label": "Cromwell",
                "label_ar": "كرومويل",
                "label_fr": "Cromwell"
            },
            {
                "value": 56446,
                "label": "Tenby",
                "label_ar": "تينبي",
                "label_fr": "Tenby"
            },
            {
                "value": 58188,
                "label": "Ufford",
                "label_ar": "أوفورد",
                "label_fr": "Ufford"
            },
            {
                "value": 58255,
                "label": "Shalford",
                "label_ar": "شالفورد",
                "label_fr": "Shalford"
            },
            {
                "value": 56746,
                "label": "Holywood",
                "label_ar": "هوليوود",
                "label_fr": "Holywood"
            },
            {
                "value": 56626,
                "label": "Colnbrook",
                "label_ar": "كولنبروك",
                "label_fr": "Colnbrook"
            },
            {
                "value": 58327,
                "label": "Laindon",
                "label_ar": "ليندون",
                "label_fr": "Laindon"
            },
            {
                "value": 57265,
                "label": "Ashley",
                "label_ar": "اشلي",
                "label_fr": "Ashley"
            },
            {
                "value": 56317,
                "label": "Dukinfield",
                "label_ar": "دوكينفيلد",
                "label_fr": "Dukinfield"
            },
            {
                "value": 56790,
                "label": "Chartham",
                "label_ar": "شارثام",
                "label_fr": "Chartham"
            },
            {
                "value": 57362,
                "label": "Heathrow",
                "label_ar": "هيثرو",
                "label_fr": "Heathrow"
            },
            {
                "value": 58436,
                "label": "Harrow on the Hill",
                "label_ar": "هارو أون ذا هيل",
                "label_fr": "Harrow sur la colline"
            },
            {
                "value": 58058,
                "label": "Aberkenfig",
                "label_ar": "أبيركنفيغ",
                "label_fr": "Aberkenfig"
            },
            {
                "value": 57546,
                "label": "Saundersfoot",
                "label_ar": "سوندرزفوت",
                "label_fr": "Saundersfoot"
            },
            {
                "value": 55969,
                "label": "Ayr",
                "label_ar": "عير",
                "label_fr": "Ayr"
            },
            {
                "value": 57312,
                "label": "Bingham",
                "label_ar": "بينغهام",
                "label_fr": "Bingham"
            },
            {
                "value": 57006,
                "label": "Bildeston",
                "label_ar": "بيلديستون",
                "label_fr": "Bildeston"
            },
            {
                "value": 56010,
                "label": "Leatherhead",
                "label_ar": "ليذرهيد",
                "label_fr": "Leatherhead"
            },
            {
                "value": 57592,
                "label": "Aldeburgh",
                "label_ar": "الدبرغ",
                "label_fr": "Aldeburgh"
            },
            {
                "value": 57849,
                "label": "Binfield",
                "label_ar": "بينفيلد",
                "label_fr": "Binfield"
            },
            {
                "value": 58112,
                "label": "Gresford",
                "label_ar": "جريسفورد",
                "label_fr": "Gresford"
            },
            {
                "value": 56085,
                "label": "Castleford",
                "label_ar": "كاسلفورد",
                "label_fr": "Castleford"
            },
            {
                "value": 58836,
                "label": "Shadoxhurst",
                "label_ar": "شادوكسهيرست",
                "label_fr": "Shadoxhurst"
            },
            {
                "value": 58189,
                "label": "Kirtlington",
                "label_ar": "كيرتلينجتون",
                "label_fr": "Kirtlington"
            },
            {
                "value": 56561,
                "label": "Langport",
                "label_ar": "لانغبورت",
                "label_fr": "Langport"
            },
            {
                "value": 56911,
                "label": "Monmouth",
                "label_ar": "مونماوث",
                "label_fr": "Monmouth"
            },
            {
                "value": 55880,
                "label": "Mitcheldean",
                "label_ar": "ميتشلدين",
                "label_fr": "Mitcheldean"
            },
            {
                "value": 58867,
                "label": "Douglas",
                "label_ar": "دوغلاس",
                "label_fr": "Douglas"
            },
            {
                "value": 56261,
                "label": "Sunbury-on-Thames",
                "label_ar": "صنبيري أون تيمز",
                "label_fr": "Sunbury-on-Thames"
            },
            {
                "value": 57695,
                "label": "Yate",
                "label_ar": "ياتي",
                "label_fr": "Yate"
            },
            {
                "value": 57500,
                "label": "Chaddesden",
                "label_ar": "تشادسددن",
                "label_fr": "Chaddesden"
            },
            {
                "value": 56534,
                "label": "Kendal",
                "label_ar": "كندال",
                "label_fr": "Kendal"
            },
            {
                "value": 58173,
                "label": "Cockerham",
                "label_ar": "كوكرهام",
                "label_fr": "Cockerham"
            },
            {
                "value": 58324,
                "label": "Southgate",
                "label_ar": "ساوثجيت",
                "label_fr": "Southgate"
            },
            {
                "value": 58272,
                "label": "Wolverley",
                "label_ar": "ولفرلي",
                "label_fr": "Wolverley"
            },
            {
                "value": 57477,
                "label": "Ottershaw",
                "label_ar": "أوترشو",
                "label_fr": "Ottershaw"
            },
            {
                "value": 56779,
                "label": "Faringdon",
                "label_ar": "فرينجدون",
                "label_fr": "Faringdon"
            },
            {
                "value": 56750,
                "label": "Chapel en le Frith",
                "label_ar": "تشابل إن لو فريث",
                "label_fr": "Chapelle en le Frith"
            },
            {
                "value": 56873,
                "label": "Helensburgh",
                "label_ar": "هيلينسبورغ",
                "label_fr": "Helensburgh"
            },
            {
                "value": 56591,
                "label": "Seaford",
                "label_ar": "سيفورد",
                "label_fr": "Seaford"
            },
            {
                "value": 55920,
                "label": "Aberdare",
                "label_ar": "أبيردار",
                "label_fr": "Aberdare"
            },
            {
                "value": 56270,
                "label": "Shaftesbury",
                "label_ar": "شافتسبري",
                "label_fr": "Shaftesbury"
            },
            {
                "value": 58767,
                "label": "Portglenone",
                "label_ar": "Portglenone",
                "label_fr": "Portglenone"
            },
            {
                "value": 57792,
                "label": "Chellaston",
                "label_ar": "شيلاستون",
                "label_fr": "Chellaston"
            },
            {
                "value": 58095,
                "label": "Henham",
                "label_ar": "هنهام",
                "label_fr": "Henham"
            },
            {
                "value": 57777,
                "label": "West Malling",
                "label_ar": "ويست مولينج",
                "label_fr": "West Malling"
            },
            {
                "value": 58527,
                "label": "Lakenheath",
                "label_ar": "لاكنهيث",
                "label_fr": "Lakenheath"
            },
            {
                "value": 57355,
                "label": "Aberystwyth",
                "label_ar": "ابيريستويث",
                "label_fr": "Aberystwyth"
            },
            {
                "value": 56058,
                "label": "Greenford",
                "label_ar": "جرينفورد",
                "label_fr": "Greenford"
            },
            {
                "value": 56740,
                "label": "Northwood",
                "label_ar": "نورثوود",
                "label_fr": "Northwood"
            },
            {
                "value": 58720,
                "label": "Kelly",
                "label_ar": "كيلي",
                "label_fr": "Kelly"
            },
            {
                "value": 57204,
                "label": "Timperley",
                "label_ar": "تيمبرلي",
                "label_fr": "Timperley"
            },
            {
                "value": 58222,
                "label": "Meopham",
                "label_ar": "ميفهام",
                "label_fr": "Meopham"
            },
            {
                "value": 58546,
                "label": "Aboyne",
                "label_ar": "أبوين",
                "label_fr": "Aboyne"
            },
            {
                "value": 56645,
                "label": "Welling",
                "label_ar": "يلينج",
                "label_fr": "Welling"
            },
            {
                "value": 56599,
                "label": "Hurstpierpoint",
                "label_ar": "هيرستبيربوينت",
                "label_fr": "Hurstpierpoint"
            },
            {
                "value": 58132,
                "label": "Sedgeberrow",
                "label_ar": "سيدجبيرو",
                "label_fr": "Sedgeberrow"
            },
            {
                "value": 56611,
                "label": "Kidsgrove",
                "label_ar": "كيدزجروف",
                "label_fr": "Kidsgrove"
            },
            {
                "value": 56361,
                "label": "Stanley",
                "label_ar": "ستانلي",
                "label_fr": "Stanley"
            },
            {
                "value": 57575,
                "label": "Hartfield",
                "label_ar": "هارتفيلد",
                "label_fr": "Hartfield"
            },
            {
                "value": 57307,
                "label": "Bookham",
                "label_ar": "بوكهام",
                "label_fr": "Bookham"
            },
            {
                "value": 56307,
                "label": "Biggin Hill",
                "label_ar": "بيججين هيل",
                "label_fr": "Biggin Hill"
            },
            {
                "value": 56574,
                "label": "Bisley",
                "label_ar": "بيسلي",
                "label_fr": "Bisley"
            },
            {
                "value": 56124,
                "label": "Dunstable",
                "label_ar": "دنستابل",
                "label_fr": "Dunstable"
            },
            {
                "value": 56265,
                "label": "Shepperton",
                "label_ar": "شيبرتون",
                "label_fr": "Shepperton"
            },
            {
                "value": 57629,
                "label": "Mark",
                "label_ar": "علامة",
                "label_fr": "marque"
            },
            {
                "value": 58730,
                "label": "Barley",
                "label_ar": "شعير",
                "label_fr": "Orge"
            },
            {
                "value": 57068,
                "label": "Dinnington",
                "label_ar": "دينينجتون",
                "label_fr": "Dinnington"
            },
            {
                "value": 56187,
                "label": "Bedlington",
                "label_ar": "بيدلينجتون",
                "label_fr": "Bedlington"
            },
            {
                "value": 57649,
                "label": "Easton",
                "label_ar": "ايستون",
                "label_fr": "Easton"
            },
            {
                "value": 57808,
                "label": "Coseley",
                "label_ar": "كوزلي",
                "label_fr": "Coseley"
            },
            {
                "value": 57630,
                "label": "Lifton",
                "label_ar": "رفع على",
                "label_fr": "Lifton"
            },
            {
                "value": 56271,
                "label": "Honiton",
                "label_ar": "هونيتون",
                "label_fr": "Honiton"
            },
            {
                "value": 58856,
                "label": "Staverton",
                "label_ar": "ستافرتون",
                "label_fr": "Staverton"
            },
            {
                "value": 58504,
                "label": "Porlock",
                "label_ar": "بورلوك",
                "label_fr": "Porlock"
            },
            {
                "value": 58440,
                "label": "Lawrencetown",
                "label_ar": "لورنس تاون",
                "label_fr": "Lawrencetown"
            },
            {
                "value": 57585,
                "label": "Gosforth",
                "label_ar": "جوسفورث",
                "label_fr": "Gosforth"
            },
            {
                "value": 58061,
                "label": "Matching",
                "label_ar": "مطابقة",
                "label_fr": "Correspondant à"
            },
            {
                "value": 58488,
                "label": "Beaumaris",
                "label_ar": "بوماريس",
                "label_fr": "Beaumaris"
            },
            {
                "value": 58771,
                "label": "Church",
                "label_ar": "كنيسة",
                "label_fr": "Église"
            },
            {
                "value": 56661,
                "label": "Old Colwyn",
                "label_ar": "كولوين القديمة",
                "label_fr": "Vieux Colwyn"
            },
            {
                "value": 58443,
                "label": "Pencoed",
                "label_ar": "بينكويد",
                "label_fr": "Crayonné"
            },
            {
                "value": 57054,
                "label": "Somerset",
                "label_ar": "سومرست",
                "label_fr": "Somerset"
            },
            {
                "value": 58522,
                "label": "Shawbury",
                "label_ar": "شوبري",
                "label_fr": "Shawbury"
            },
            {
                "value": 56984,
                "label": "Headley",
                "label_ar": "هيدلي",
                "label_fr": "Headley"
            },
            {
                "value": 57009,
                "label": "Fordingbridge",
                "label_ar": "فوردينجبريدج",
                "label_fr": "Fordingbridge"
            },
            {
                "value": 56826,
                "label": "Manningtree",
                "label_ar": "مانينغتري",
                "label_fr": "Manningtree"
            },
            {
                "value": 58541,
                "label": "Whalley",
                "label_ar": "والي",
                "label_fr": "Whalley"
            },
            {
                "value": 58798,
                "label": "Stratfield Mortimer",
                "label_ar": "ستراتفيلد مورتيمر",
                "label_fr": "Stratfield Mortimer"
            },
            {
                "value": 56240,
                "label": "Skelmersdale",
                "label_ar": "سكيلمرسديل",
                "label_fr": "Skelmersdale"
            },
            {
                "value": 55968,
                "label": "Kilmarnock",
                "label_ar": "كيلمارنوك",
                "label_fr": "Kilmarnock"
            },
            {
                "value": 55908,
                "label": "Torquay",
                "label_ar": "توركواي",
                "label_fr": "Torquay"
            },
            {
                "value": 58332,
                "label": "Horton Kirby",
                "label_ar": "هورتون كيربي",
                "label_fr": "Horton Kirby"
            },
            {
                "value": 57286,
                "label": "Henley",
                "label_ar": "هينلي",
                "label_fr": "Henley"
            },
            {
                "value": 58829,
                "label": "Old Basing",
                "label_ar": "القاعدة القديمة",
                "label_fr": "Ancienne base"
            },
            {
                "value": 56217,
                "label": "Sudbury",
                "label_ar": "سودبيري",
                "label_fr": "Sudbury"
            },
            {
                "value": 57183,
                "label": "Ickenham",
                "label_ar": "إيكينهام",
                "label_fr": "Ickenham"
            },
            {
                "value": 58600,
                "label": "Babraham",
                "label_ar": "بابراهام",
                "label_fr": "Babraham"
            },
            {
                "value": 58743,
                "label": "Anniesland",
                "label_ar": "أنيسلاند",
                "label_fr": "Anniesland"
            },
            {
                "value": 56920,
                "label": "Winslow",
                "label_ar": "وينسلو",
                "label_fr": "Winslow"
            },
            {
                "value": 57160,
                "label": "Morley",
                "label_ar": "مورلي",
                "label_fr": "Morley"
            },
            {
                "value": 58048,
                "label": "Littleport",
                "label_ar": "ليتلبورت",
                "label_fr": "Littleport"
            },
            {
                "value": 56480,
                "label": "Covent Garden",
                "label_ar": "كوفنت غاردن",
                "label_fr": "Covent Garden"
            },
            {
                "value": 57666,
                "label": "Bishopbriggs",
                "label_ar": "بيشوبريجس",
                "label_fr": "Bishopbriggs"
            },
            {
                "value": 56440,
                "label": "Kidwelly",
                "label_ar": "كيدويلي",
                "label_fr": "Kidwelly"
            },
            {
                "value": 58247,
                "label": "Wraysbury",
                "label_ar": "رايزبري",
                "label_fr": "Wraysbury"
            },
            {
                "value": 56296,
                "label": "Macclesfield",
                "label_ar": "ماكليسفيلد",
                "label_fr": "Macclesfield"
            },
            {
                "value": 58774,
                "label": "Woodhouse",
                "label_ar": "وودهاوس",
                "label_fr": "Maison de bois"
            },
            {
                "value": 57297,
                "label": "Trent",
                "label_ar": "ترينت",
                "label_fr": "Trent"
            },
            {
                "value": 58233,
                "label": "Fairlight",
                "label_ar": "Fairlight",
                "label_fr": "Fairlight"
            },
            {
                "value": 58746,
                "label": "Handsworth",
                "label_ar": "هاندزورث",
                "label_fr": "Handsworth"
            },
            {
                "value": 57885,
                "label": "Westcott",
                "label_ar": "ويستكوت",
                "label_fr": "Westcott"
            },
            {
                "value": 56406,
                "label": "Lancaster",
                "label_ar": "لانكستر",
                "label_fr": "Lancaster"
            },
            {
                "value": 56079,
                "label": "Ripon",
                "label_ar": "ريبون",
                "label_fr": "Ripon"
            },
            {
                "value": 56468,
                "label": "Witham",
                "label_ar": "ويثام",
                "label_fr": "Avec H"
            },
            {
                "value": 57147,
                "label": "Markfield",
                "label_ar": "ماركفيلد",
                "label_fr": "Markfield"
            },
            {
                "value": 58694,
                "label": "Askham",
                "label_ar": "اسكيم",
                "label_fr": "Askham"
            },
            {
                "value": 57682,
                "label": "Sawley",
                "label_ar": "ساولي",
                "label_fr": "Sawley"
            },
            {
                "value": 56160,
                "label": "Bury",
                "label_ar": "دفن",
                "label_fr": "Enterrer"
            },
            {
                "value": 58614,
                "label": "Eagle",
                "label_ar": "نسر",
                "label_fr": "Aigle"
            },
            {
                "value": 57530,
                "label": "Cinderford",
                "label_ar": "سندرفورد",
                "label_fr": "Cinderford"
            },
            {
                "value": 58157,
                "label": "Measham",
                "label_ar": "ميشام",
                "label_fr": "Measham"
            },
            {
                "value": 58865,
                "label": "Woodmancote",
                "label_ar": "وودمانكوت",
                "label_fr": "Woodmancote"
            },
            {
                "value": 56745,
                "label": "Llandudno",
                "label_ar": "لاندودنو",
                "label_fr": "Llandudno"
            },
            {
                "value": 58749,
                "label": "Inchinnan",
                "label_ar": "إنتشينان",
                "label_fr": "Inchinnan"
            },
            {
                "value": 55941,
                "label": "Nottingham",
                "label_ar": "نوتنغهام",
                "label_fr": "Nottingham"
            },
            {
                "value": 55987,
                "label": "South Shields",
                "label_ar": "ساوث شيلدز",
                "label_fr": "Boucliers sud"
            },
            {
                "value": 57431,
                "label": "Newburgh",
                "label_ar": "نيوبورج",
                "label_fr": "Newburgh"
            },
            {
                "value": 56998,
                "label": "Wantage",
                "label_ar": "وانتاج",
                "label_fr": "Wantage"
            },
            {
                "value": 57878,
                "label": "Upwell",
                "label_ar": "أبويل",
                "label_fr": "Upwell"
            },
            {
                "value": 58275,
                "label": "Paddock Wood",
                "label_ar": "خشب البادوك",
                "label_fr": "Bois de paddock"
            },
            {
                "value": 57942,
                "label": "Glentham",
                "label_ar": "جلينثام",
                "label_fr": "Glentham"
            },
            {
                "value": 57491,
                "label": "Llangefni",
                "label_ar": "لانجفني",
                "label_fr": "Llangefni"
            },
            {
                "value": 57968,
                "label": "Dollar",
                "label_ar": "دولار",
                "label_fr": "Dollar"
            },
            {
                "value": 56262,
                "label": "Redhill",
                "label_ar": "التل الأحمر",
                "label_fr": "Colline rouge"
            },
            {
                "value": 57126,
                "label": "Robertsbridge",
                "label_ar": "روبرتسبريدج",
                "label_fr": "Robertsbridge"
            },
            {
                "value": 57798,
                "label": "Irthlingborough",
                "label_ar": "ارثينغبورو",
                "label_fr": "Irthlingborough"
            },
            {
                "value": 56046,
                "label": "Littlehampton",
                "label_ar": "ليتلهامبتون",
                "label_fr": "Littlehampton"
            },
            {
                "value": 56323,
                "label": "Knutsford",
                "label_ar": "نوتسفورد",
                "label_fr": "Knutsford"
            },
            {
                "value": 56237,
                "label": "Chorley",
                "label_ar": "كورلي",
                "label_fr": "Chorley"
            },
            {
                "value": 56578,
                "label": "Bagillt",
                "label_ar": "باجيلت",
                "label_fr": "Bagillt"
            },
            {
                "value": 56219,
                "label": "Wattisfield",
                "label_ar": "واتيسفيلد",
                "label_fr": "Wattisfield"
            },
            {
                "value": 58883,
                "label": "Norham",
                "label_ar": "نورهام",
                "label_fr": "Norham"
            },
            {
                "value": 57235,
                "label": "Pembury",
                "label_ar": "Pembury",
                "label_fr": "Pembury"
            },
            {
                "value": 57072,
                "label": "Ibstock",
                "label_ar": "إبستوك",
                "label_fr": "Ibstock"
            },
            {
                "value": 57406,
                "label": "Blakeney",
                "label_ar": "بلاكيني",
                "label_fr": "Blakeney"
            },
            {
                "value": 58408,
                "label": "Gatwick",
                "label_ar": "جاتويك",
                "label_fr": "Gatwick"
            },
            {
                "value": 58082,
                "label": "Kibworth Harcourt",
                "label_ar": "كيبورث هاركورت",
                "label_fr": "Kibworth Harcourt"
            },
            {
                "value": 57991,
                "label": "Abergynolwyn",
                "label_ar": "أبيرجينولوين",
                "label_fr": "Abergynolwyn"
            },
            {
                "value": 56173,
                "label": "Ashington",
                "label_ar": "أشينغتون",
                "label_fr": "Ashington"
            },
            {
                "value": 56772,
                "label": "Clapham",
                "label_ar": "كلافام",
                "label_fr": "Clapham"
            },
            {
                "value": 57162,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 57605,
                "label": "Wootton",
                "label_ar": "ووتون",
                "label_fr": "Wootton"
            },
            {
                "value": 58902,
                "label": "Harby",
                "label_ar": "حربي",
                "label_fr": "Harby"
            },
            {
                "value": 57049,
                "label": "Shinfield",
                "label_ar": "شينفيلد",
                "label_fr": "Shinfield"
            },
            {
                "value": 58779,
                "label": "Wootton",
                "label_ar": "ووتون",
                "label_fr": "Wootton"
            },
            {
                "value": 56721,
                "label": "Gretna Green",
                "label_ar": "جريتنا جرين",
                "label_fr": "Gretna Green"
            },
            {
                "value": 57116,
                "label": "Choppington",
                "label_ar": "تشوبينجتون",
                "label_fr": "Choppington"
            },
            {
                "value": 57828,
                "label": "Durrington",
                "label_ar": "دورينجتون",
                "label_fr": "Durrington"
            },
            {
                "value": 57734,
                "label": "Barton-le-Clay",
                "label_ar": "بارتون لو كلاي",
                "label_fr": "Barton-le-Clay"
            },
            {
                "value": 57643,
                "label": "Piddington",
                "label_ar": "بيدنجتون",
                "label_fr": "Piddington"
            },
            {
                "value": 56699,
                "label": "Strabane",
                "label_ar": "سترابان",
                "label_fr": "Strabane"
            },
            {
                "value": 55937,
                "label": "Ammanford",
                "label_ar": "عمانفورد",
                "label_fr": "Ammanford"
            },
            {
                "value": 56047,
                "label": "Polegate",
                "label_ar": "بوليجيت",
                "label_fr": "Polegate"
            },
            {
                "value": 56832,
                "label": "Betchworth",
                "label_ar": "بيتشوورث",
                "label_fr": "Betchworth"
            },
            {
                "value": 56917,
                "label": "Alnwick",
                "label_ar": "النويك",
                "label_fr": "Alnwick"
            },
            {
                "value": 57861,
                "label": "Stillington",
                "label_ar": "ستيلنجتون",
                "label_fr": "Stillington"
            },
            {
                "value": 58300,
                "label": "Kensington",
                "label_ar": "كنسينغتون",
                "label_fr": "Kensington"
            },
            {
                "value": 58716,
                "label": "Thurston",
                "label_ar": "ثورستون",
                "label_fr": "Thurston"
            },
            {
                "value": 58766,
                "label": "Maryhill",
                "label_ar": "مارهيل",
                "label_fr": "Maryhill"
            },
            {
                "value": 58352,
                "label": "Epping Green",
                "label_ar": "إبينج جرين",
                "label_fr": "Epping vert"
            },
            {
                "value": 58591,
                "label": "Blaina",
                "label_ar": "بلاينا",
                "label_fr": "Blaina"
            },
            {
                "value": 56558,
                "label": "Perranporth",
                "label_ar": "بيرانبورث",
                "label_fr": "Perranporth"
            },
            {
                "value": 57389,
                "label": "West Byfleet",
                "label_ar": "غرب Byfleet",
                "label_fr": "West Byfleet"
            },
            {
                "value": 57960,
                "label": "Penshurst",
                "label_ar": "بنشرست",
                "label_fr": "Penshurst"
            },
            {
                "value": 57609,
                "label": "Codsall",
                "label_ar": "كودسال",
                "label_fr": "Codsall"
            },
            {
                "value": 57700,
                "label": "Salfords",
                "label_ar": "سلفوردز",
                "label_fr": "Salfords"
            },
            {
                "value": 55914,
                "label": "Pontypridd",
                "label_ar": "بونتيبريد",
                "label_fr": "Pontypridd"
            },
            {
                "value": 57969,
                "label": "Braunton",
                "label_ar": "براونتون",
                "label_fr": "Braunton"
            },
            {
                "value": 58485,
                "label": "Botesdale",
                "label_ar": "بوتسديل",
                "label_fr": "Botesdale"
            },
            {
                "value": 57163,
                "label": "Eastcote",
                "label_ar": "إيستكوت",
                "label_fr": "Eastcote"
            },
            {
                "value": 57081,
                "label": "Stocksbridge",
                "label_ar": "ستوكسبريدج",
                "label_fr": "Stocksbridge"
            },
            {
                "value": 58456,
                "label": "Chipping Sodbury",
                "label_ar": "شيبينج سودبيري",
                "label_fr": "Chipping Sodbury"
            },
            {
                "value": 57402,
                "label": "Llanidloes",
                "label_ar": "يانيدلوس",
                "label_fr": "Llanidloes"
            },
            {
                "value": 56875,
                "label": "Curdridge",
                "label_ar": "كوردريدج",
                "label_fr": "Curdridge"
            },
            {
                "value": 58859,
                "label": "Saint Clears",
                "label_ar": "سانت كليرز",
                "label_fr": "Saint Clears"
            },
            {
                "value": 57525,
                "label": "Silloth",
                "label_ar": "سيلوث",
                "label_fr": "Silloth"
            },
            {
                "value": 57290,
                "label": "Thurleigh",
                "label_ar": "ثورلي",
                "label_fr": "Thurleigh"
            },
            {
                "value": 56013,
                "label": "Gillingham",
                "label_ar": "جيلينجهام",
                "label_fr": "Gillingham"
            },
            {
                "value": 56190,
                "label": "Houghton-le-Spring",
                "label_ar": "هوتون لو سبرينغ",
                "label_fr": "Houghton-le-Spring"
            },
            {
                "value": 56843,
                "label": "Countess Wear",
                "label_ar": "ملابس الكونتيسة",
                "label_fr": "Usure de la comtesse"
            },
            {
                "value": 57679,
                "label": "Swavesey",
                "label_ar": "سوافيسي",
                "label_fr": "Swavesey"
            },
            {
                "value": 56019,
                "label": "Newport",
                "label_ar": "نيوبورت",
                "label_fr": "Newport"
            },
            {
                "value": 56791,
                "label": "Deal",
                "label_ar": "صفقة",
                "label_fr": "Traiter"
            },
            {
                "value": 56805,
                "label": "Well",
                "label_ar": "حسنا",
                "label_fr": "bien"
            },
            {
                "value": 56156,
                "label": "Bacup",
                "label_ar": "باكو",
                "label_fr": "Bacup"
            },
            {
                "value": 58483,
                "label": "Tillingham",
                "label_ar": "تلينجهام",
                "label_fr": "Tillingham"
            },
            {
                "value": 57810,
                "label": "Stotfold",
                "label_ar": "ستوتفولد",
                "label_fr": "Stotfold"
            },
            {
                "value": 55947,
                "label": "Northampton",
                "label_ar": "نورثهامبتون",
                "label_fr": "Northampton"
            },
            {
                "value": 56532,
                "label": "Hook",
                "label_ar": "صنارة صيد",
                "label_fr": "Crochet"
            },
            {
                "value": 57037,
                "label": "Blackford",
                "label_ar": "بلاكفورد",
                "label_fr": "Blackford"
            },
            {
                "value": 58089,
                "label": "Patrington",
                "label_ar": "باترينجتون",
                "label_fr": "Patrington"
            },
            {
                "value": 58380,
                "label": "Northenden",
                "label_ar": "نورثيندن",
                "label_fr": "Northenden"
            },
            {
                "value": 56692,
                "label": "Ardrossan",
                "label_ar": "أردروسان",
                "label_fr": "Ardrossan"
            },
            {
                "value": 58879,
                "label": "Garth",
                "label_ar": "جارث",
                "label_fr": "Garth"
            },
            {
                "value": 56145,
                "label": "Warrington",
                "label_ar": "وارينغتون",
                "label_fr": "Warrington"
            },
            {
                "value": 58787,
                "label": "Paston",
                "label_ar": "الماضي على",
                "label_fr": "Paston"
            },
            {
                "value": 57640,
                "label": "Malpas",
                "label_ar": "مالباس",
                "label_fr": "Malpas"
            },
            {
                "value": 57109,
                "label": "Crymych",
                "label_ar": "كريميش",
                "label_fr": "Crymych"
            },
            {
                "value": 56932,
                "label": "Clunderwen",
                "label_ar": "كلندروين",
                "label_fr": "Clunderwen"
            },
            {
                "value": 57970,
                "label": "South Cerney",
                "label_ar": "جنوب سيرني",
                "label_fr": "Sud Cerney"
            },
            {
                "value": 57482,
                "label": "Effingham",
                "label_ar": "إيفنغهام",
                "label_fr": "Effingham"
            },
            {
                "value": 57346,
                "label": "Wethersfield",
                "label_ar": "ويذرسفيلد",
                "label_fr": "Wethersfield"
            },
            {
                "value": 56604,
                "label": "Elgin",
                "label_ar": "إلجين",
                "label_fr": "Elgin"
            },
            {
                "value": 56291,
                "label": "Cannock",
                "label_ar": "كانوك",
                "label_fr": "Cannock"
            },
            {
                "value": 56613,
                "label": "Southend-on-Sea",
                "label_ar": "ساوثيند أون سي",
                "label_fr": "Southend-on-Sea"
            },
            {
                "value": 58306,
                "label": "Bredbury",
                "label_ar": "بريدبري",
                "label_fr": "Bredbury"
            },
            {
                "value": 58289,
                "label": "Cheadle",
                "label_ar": "تشيدل",
                "label_fr": "Cheadle"
            },
            {
                "value": 56904,
                "label": "Bedale",
                "label_ar": "بيدال",
                "label_fr": "Bedale"
            },
            {
                "value": 57033,
                "label": "Kings Sutton",
                "label_ar": "كينغز ساتون",
                "label_fr": "Kings Sutton"
            },
            {
                "value": 57401,
                "label": "Tile Hill",
                "label_ar": "هيل هيل",
                "label_fr": "Tile Hill"
            },
            {
                "value": 58018,
                "label": "Midhurst",
                "label_ar": "ميدهيرست",
                "label_fr": "Midhurst"
            },
            {
                "value": 57517,
                "label": "Nairn",
                "label_ar": "نايرن",
                "label_fr": "Nairn"
            },
            {
                "value": 58645,
                "label": "Hartford",
                "label_ar": "هارتفورد",
                "label_fr": "Hartford"
            },
            {
                "value": 56133,
                "label": "Abingdon",
                "label_ar": "أبينجدون",
                "label_fr": "Abingdon"
            },
            {
                "value": 57689,
                "label": "Burbage",
                "label_ar": "برباج",
                "label_fr": "Burbage"
            },
            {
                "value": 56521,
                "label": "Dorchester",
                "label_ar": "دورتشستر",
                "label_fr": "Dorchester"
            },
            {
                "value": 57087,
                "label": "Whitefield",
                "label_ar": "حقل أبيض",
                "label_fr": "Whitefield"
            },
            {
                "value": 55869,
                "label": "Tamworth",
                "label_ar": "تامورث",
                "label_fr": "Tamworth"
            },
            {
                "value": 57237,
                "label": "Upton",
                "label_ar": "ابتون",
                "label_fr": "Upton"
            },
            {
                "value": 57990,
                "label": "Dunning",
                "label_ar": "إنذار بالدفع",
                "label_fr": "Relance"
            },
            {
                "value": 56073,
                "label": "Bradford",
                "label_ar": "برادفورد",
                "label_fr": "Bradford"
            },
            {
                "value": 56097,
                "label": "Ipswich",
                "label_ar": "إبسويتش",
                "label_fr": "Ipswich"
            },
            {
                "value": 56383,
                "label": "Holmfirth",
                "label_ar": "هولمفيرث",
                "label_fr": "Holmfirth"
            },
            {
                "value": 58622,
                "label": "Upton",
                "label_ar": "ابتون",
                "label_fr": "Upton"
            },
            {
                "value": 56179,
                "label": "Newton Aycliffe",
                "label_ar": "نيوتن أيكليف",
                "label_fr": "Newton Aycliffe"
            },
            {
                "value": 56718,
                "label": "Waltham Abbey",
                "label_ar": "والثام أبي",
                "label_fr": "Abbaye de Waltham"
            },
            {
                "value": 57514,
                "label": "Ulceby",
                "label_ar": "أولسيبي",
                "label_fr": "Ulceby"
            },
            {
                "value": 57488,
                "label": "Winterbourne Dauntsey",
                "label_ar": "وينتربورن داونتسي",
                "label_fr": "Winterbourne Dauntsey"
            },
            {
                "value": 56127,
                "label": "Wootton",
                "label_ar": "ووتون",
                "label_fr": "Wootton"
            },
            {
                "value": 56301,
                "label": "Newport",
                "label_ar": "نيوبورت",
                "label_fr": "Newport"
            },
            {
                "value": 55984,
                "label": "Carluke",
                "label_ar": "كارلوك",
                "label_fr": "Carluke"
            },
            {
                "value": 58301,
                "label": "Ardmillan",
                "label_ar": "أردميلان",
                "label_fr": "Ardmillan"
            },
            {
                "value": 58015,
                "label": "Marhamchurch",
                "label_ar": "مارهامتشورش",
                "label_fr": "Marhamchurch"
            },
            {
                "value": 57518,
                "label": "Northrepps",
                "label_ar": "نورثريبس",
                "label_fr": "Northrepps"
            },
            {
                "value": 57423,
                "label": "Voe",
                "label_ar": "صوت",
                "label_fr": "Voe"
            },
            {
                "value": 58250,
                "label": "Four Marks",
                "label_ar": "أربع علامات",
                "label_fr": "Quatre marques"
            },
            {
                "value": 58725,
                "label": "Mossley",
                "label_ar": "موسلي",
                "label_fr": "Mossley"
            },
            {
                "value": 56185,
                "label": "Crook",
                "label_ar": "المحتال",
                "label_fr": "Escroc"
            },
            {
                "value": 56352,
                "label": "Epping",
                "label_ar": "إبينج",
                "label_fr": "Epping"
            },
            {
                "value": 56277,
                "label": "Ottery St Mary",
                "label_ar": "Ottery سانت ماري",
                "label_fr": "Ottery St Mary"
            },
            {
                "value": 56188,
                "label": "Shildon",
                "label_ar": "شيلدون",
                "label_fr": "Shildon"
            },
            {
                "value": 57379,
                "label": "Clifton",
                "label_ar": "كليفتون",
                "label_fr": "Clifton"
            },
            {
                "value": 57446,
                "label": "Stockbridge",
                "label_ar": "ستوكبريدج",
                "label_fr": "Stockbridge"
            },
            {
                "value": 57456,
                "label": "Rainham",
                "label_ar": "رينهام",
                "label_fr": "Rainham"
            },
            {
                "value": 57513,
                "label": "West Horsley",
                "label_ar": "ويست هورسلي",
                "label_fr": "West Horsley"
            },
            {
                "value": 57485,
                "label": "Ashwell",
                "label_ar": "أشويل",
                "label_fr": "Ashwell"
            },
            {
                "value": 56700,
                "label": "Bellshill",
                "label_ar": "بيلشيل",
                "label_fr": "Bellshill"
            },
            {
                "value": 57584,
                "label": "Wragby",
                "label_ar": "راغبي",
                "label_fr": "Wragby"
            },
            {
                "value": 56995,
                "label": "Woodhall Spa",
                "label_ar": "وودهال سبا",
                "label_fr": "Spa Woodhall"
            },
            {
                "value": 57469,
                "label": "Hurstbourne Tarrant",
                "label_ar": "هيرستبورن تارانت",
                "label_fr": "Hurstbourne Tarrant"
            },
            {
                "value": 56589,
                "label": "Maidenhead",
                "label_ar": "بكارة",
                "label_fr": "Virginité"
            },
            {
                "value": 56368,
                "label": "Haywards Heath",
                "label_ar": "هايواردس هيث",
                "label_fr": "Haywards Heath"
            },
            {
                "value": 56550,
                "label": "Hackbridge",
                "label_ar": "هاك بريدج",
                "label_fr": "Hackbridge"
            },
            {
                "value": 58357,
                "label": "Ripley",
                "label_ar": "ريبلي",
                "label_fr": "Ripley"
            },
            {
                "value": 58022,
                "label": "Norton",
                "label_ar": "نورتون",
                "label_fr": "Norton"
            },
            {
                "value": 58426,
                "label": "Hucknall under Huthwaite",
                "label_ar": "Hucknall تحت Huthwaite",
                "label_fr": "Hucknall sous Huthwaite"
            },
            {
                "value": 55986,
                "label": "Carlisle",
                "label_ar": "كارلايل",
                "label_fr": "Carlisle"
            },
            {
                "value": 56259,
                "label": "Slough",
                "label_ar": "سلو",
                "label_fr": "Bourbier"
            },
            {
                "value": 56609,
                "label": "Erith",
                "label_ar": "إيريث",
                "label_fr": "Erith"
            },
            {
                "value": 56131,
                "label": "Towcester",
                "label_ar": "تاوستر",
                "label_fr": "Towcester"
            },
            {
                "value": 56192,
                "label": "Bishop Auckland",
                "label_ar": "المطران أوكلاند",
                "label_fr": "Évêque Auckland"
            },
            {
                "value": 57016,
                "label": "Haddington",
                "label_ar": "هادينجتون",
                "label_fr": "Haddington"
            },
            {
                "value": 57962,
                "label": "Moira",
                "label_ar": "مويرا",
                "label_fr": "Moira"
            },
            {
                "value": 58461,
                "label": "Port Sunlight",
                "label_ar": "ميناء ضوء الشمس",
                "label_fr": "Lumière du soleil du port"
            },
            {
                "value": 56228,
                "label": "Graveley",
                "label_ar": "جرافيلي",
                "label_fr": "Graveley"
            },
            {
                "value": 56020,
                "label": "Dover",
                "label_ar": "دوفر",
                "label_fr": "Douvres"
            },
            {
                "value": 57857,
                "label": "South Killingholme",
                "label_ar": "جنوب كيلينغهولمي",
                "label_fr": "South Killingholme"
            },
            {
                "value": 56482,
                "label": "Lavant",
                "label_ar": "لافانت",
                "label_fr": "Lavant"
            },
            {
                "value": 56926,
                "label": "Rhayader",
                "label_ar": "رايادر",
                "label_fr": "Rhayader"
            },
            {
                "value": 56697,
                "label": "Port Talbot",
                "label_ar": "بورت تالبوت",
                "label_fr": "Port Talbot"
            },
            {
                "value": 57152,
                "label": "Ruddington",
                "label_ar": "رودنجتون",
                "label_fr": "Ruddington"
            },
            {
                "value": 57763,
                "label": "Heysham",
                "label_ar": "هيشام",
                "label_fr": "Heysham"
            },
            {
                "value": 58098,
                "label": "Duddington",
                "label_ar": "دودينجتون",
                "label_fr": "Duddington"
            },
            {
                "value": 56735,
                "label": "Dalry",
                "label_ar": "دالي",
                "label_fr": "Dalry"
            },
            {
                "value": 58605,
                "label": "Edwinstowe",
                "label_ar": "إدوينستو",
                "label_fr": "Edwinstowe"
            },
            {
                "value": 58069,
                "label": "Birdbrook",
                "label_ar": "بيردبروك",
                "label_fr": "Birdbrook"
            },
            {
                "value": 56030,
                "label": "Broxburn",
                "label_ar": "بروكسبرن",
                "label_fr": "Broxburn"
            },
            {
                "value": 56439,
                "label": "Milford Haven",
                "label_ar": "ميلفورد هافن",
                "label_fr": "Milford Haven"
            },
            {
                "value": 56256,
                "label": "Barrow in Furness",
                "label_ar": "بارو في فرنيس",
                "label_fr": "Barrow dans Furness"
            },
            {
                "value": 57913,
                "label": "Enniskillen",
                "label_ar": "إنيسكيلين",
                "label_fr": "Enniskillen"
            },
            {
                "value": 57865,
                "label": "Campbeltown",
                "label_ar": "كامبيلتاون",
                "label_fr": "Campbeltown"
            },
            {
                "value": 56871,
                "label": "Knaresborough",
                "label_ar": "كناريسبره",
                "label_fr": "Knaresborough"
            },
            {
                "value": 57813,
                "label": "Elderslie",
                "label_ar": "إلدرسلي",
                "label_fr": "Elderslie"
            },
            {
                "value": 56466,
                "label": "Palmers Green",
                "label_ar": "بالمرز جرين",
                "label_fr": "Vert palmiers"
            },
            {
                "value": 56012,
                "label": "Basingstoke",
                "label_ar": "باسينجستوك",
                "label_fr": "Basingstoke"
            },
            {
                "value": 57203,
                "label": "Stretford",
                "label_ar": "سترتفورد",
                "label_fr": "Stretford"
            },
            {
                "value": 58837,
                "label": "Hardham",
                "label_ar": "هاردهام",
                "label_fr": "Hardham"
            },
            {
                "value": 56231,
                "label": "Blackpool",
                "label_ar": "بلاكبول",
                "label_fr": "Blackpool"
            },
            {
                "value": 56358,
                "label": "Ferryhill",
                "label_ar": "فيريهيل",
                "label_fr": "Ferryhill"
            },
            {
                "value": 55945,
                "label": "Burton-on-Trent",
                "label_ar": "بيرتون أون ترينت",
                "label_fr": "Burton-on-Trent"
            },
            {
                "value": 57594,
                "label": "Beith",
                "label_ar": "بيث",
                "label_fr": "Beith"
            },
            {
                "value": 58266,
                "label": "High Legh",
                "label_ar": "ارتفاع ليغ",
                "label_fr": "High Legh"
            },
            {
                "value": 57295,
                "label": "Port Glasgow",
                "label_ar": "ميناء غلاسكو",
                "label_fr": "Port Glasgow"
            },
            {
                "value": 57840,
                "label": "Tarporley",
                "label_ar": "تاربورلي",
                "label_fr": "Tarporley"
            },
            {
                "value": 58043,
                "label": "Tayport",
                "label_ar": "تايبورت",
                "label_fr": "Tayport"
            },
            {
                "value": 57741,
                "label": "Waldringfield",
                "label_ar": "والدرينجفيلد",
                "label_fr": "Waldringfield"
            },
            {
                "value": 58889,
                "label": "Urchfont",
                "label_ar": "Urchfont",
                "label_fr": "Urchfont"
            },
            {
                "value": 58087,
                "label": "Clare",
                "label_ar": "كلير",
                "label_fr": "Clare"
            },
            {
                "value": 57726,
                "label": "Charfield",
                "label_ar": "شارفيلد",
                "label_fr": "Charfield"
            },
            {
                "value": 56051,
                "label": "Brighton",
                "label_ar": "برايتون",
                "label_fr": "Brighton"
            },
            {
                "value": 57133,
                "label": "Derwen",
                "label_ar": "ديروين",
                "label_fr": "Derwen"
            },
            {
                "value": 57019,
                "label": "Draycott",
                "label_ar": "درايكوت",
                "label_fr": "Draycott"
            },
            {
                "value": 58224,
                "label": "Broughty Ferry",
                "label_ar": "بريتي فيري",
                "label_fr": "Broughty Ferry"
            },
            {
                "value": 58877,
                "label": "Beal",
                "label_ar": "بيل",
                "label_fr": "Beal"
            },
            {
                "value": 57441,
                "label": "Shotts",
                "label_ar": "طلقات",
                "label_fr": "Shotts"
            },
            {
                "value": 57746,
                "label": "Chalfont Saint Peter",
                "label_ar": "تشالفونت سانت بيتر",
                "label_fr": "Chalfont Saint Pierre"
            },
            {
                "value": 58026,
                "label": "Mytholmroyd",
                "label_ar": "ميثولمرويد",
                "label_fr": "Mytholmroyd"
            },
            {
                "value": 57284,
                "label": "Spilsby",
                "label_ar": "سبيلسبي",
                "label_fr": "Spilsby"
            },
            {
                "value": 56614,
                "label": "Redbourn",
                "label_ar": "ريدبورن",
                "label_fr": "Redbourn"
            },
            {
                "value": 57936,
                "label": "Doune",
                "label_ar": "دون",
                "label_fr": "Doune"
            },
            {
                "value": 57795,
                "label": "Tonyrefail",
                "label_ar": "توني ريفايل",
                "label_fr": "Tonyrefail"
            },
            {
                "value": 58065,
                "label": "Northop",
                "label_ar": "نورثوب",
                "label_fr": "Northop"
            },
            {
                "value": 56260,
                "label": "Southall",
                "label_ar": "الجنوب",
                "label_fr": "Southall"
            },
            {
                "value": 58839,
                "label": "Shelford",
                "label_ar": "شيلفورد",
                "label_fr": "Shelford"
            },
            {
                "value": 57953,
                "label": "Llanwrtyd Wells",
                "label_ar": "لانويرتيد ويلز",
                "label_fr": "Llanwrtyd Wells"
            },
            {
                "value": 56952,
                "label": "Marlborough",
                "label_ar": "مارلبورو",
                "label_fr": "Marlborough"
            },
            {
                "value": 58182,
                "label": "Burbage",
                "label_ar": "برباج",
                "label_fr": "Burbage"
            },
            {
                "value": 56968,
                "label": "Lampeter",
                "label_ar": "لامبيتر",
                "label_fr": "Lampeter"
            },
            {
                "value": 57811,
                "label": "Horndean",
                "label_ar": "هورنديين",
                "label_fr": "Horndean"
            },
            {
                "value": 57637,
                "label": "Lechlade",
                "label_ar": "ليشليد",
                "label_fr": "Lechlade"
            },
            {
                "value": 57301,
                "label": "Wickham",
                "label_ar": "ويكهام",
                "label_fr": "Wickham"
            },
            {
                "value": 56174,
                "label": "Gateshead",
                "label_ar": "جيتسهيد",
                "label_fr": "Gateshead"
            },
            {
                "value": 56303,
                "label": "Shrewsbury",
                "label_ar": "شروزبري",
                "label_fr": "Shrewsbury"
            },
            {
                "value": 58474,
                "label": "Aberfeldy",
                "label_ar": "أبرفيلدي",
                "label_fr": "Aberfeldy"
            },
            {
                "value": 58025,
                "label": "Gorslas",
                "label_ar": "جورسلاس",
                "label_fr": "Gorslas"
            },
            {
                "value": 55883,
                "label": "Rugby",
                "label_ar": "كرة القدم الامريكية",
                "label_fr": "Le rugby"
            },
            {
                "value": 56297,
                "label": "Oswestry",
                "label_ar": "أوسويستري",
                "label_fr": "Oswestry"
            },
            {
                "value": 56339,
                "label": "Blandford Forum",
                "label_ar": "منتدى بلاندفورد",
                "label_fr": "Blandford Forum"
            },
            {
                "value": 57360,
                "label": "Chinnor",
                "label_ar": "تشينور",
                "label_fr": "Chinnor"
            },
            {
                "value": 56596,
                "label": "Portadown",
                "label_ar": "بورتاداون",
                "label_fr": "Portadown"
            },
            {
                "value": 57202,
                "label": "Bransgore",
                "label_ar": "برانسجور",
                "label_fr": "Bransgore"
            },
            {
                "value": 57430,
                "label": "Llanymynech",
                "label_ar": "Llanymynech",
                "label_fr": "Llanymynech"
            },
            {
                "value": 57381,
                "label": "Kirkby in Ashfield",
                "label_ar": "كيركبي في أشفيلد",
                "label_fr": "Kirkby à Ashfield"
            },
            {
                "value": 58168,
                "label": "Bledlow",
                "label_ar": "بليدلو",
                "label_fr": "Bledlow"
            },
            {
                "value": 58812,
                "label": "Cross Keys",
                "label_ar": "عبر المفاتيح",
                "label_fr": "Clés croisées"
            },
            {
                "value": 56337,
                "label": "Totnes",
                "label_ar": "توتنيس",
                "label_fr": "Totnes"
            },
            {
                "value": 56882,
                "label": "Clun",
                "label_ar": "كلون",
                "label_fr": "Clun"
            },
            {
                "value": 57758,
                "label": "Sanderstead",
                "label_ar": "ساندرستيد",
                "label_fr": "Sanderstead"
            },
            {
                "value": 58558,
                "label": "East Malling",
                "label_ar": "الشرق مالينغ",
                "label_fr": "East Malling"
            },
            {
                "value": 58887,
                "label": "Chilton Trinity",
                "label_ar": "شيلتون ترينيتي",
                "label_fr": "Chilton Trinity"
            },
            {
                "value": 57257,
                "label": "Cooksbridge",
                "label_ar": "كوكسبريدج",
                "label_fr": "Cooksbridge"
            },
            {
                "value": 56714,
                "label": "Edmonton",
                "label_ar": "ادمونتون",
                "label_fr": "Edmonton"
            },
            {
                "value": 58104,
                "label": "Colyford",
                "label_ar": "كوليفورد",
                "label_fr": "Colyford"
            },
            {
                "value": 57394,
                "label": "Old Buckenham",
                "label_ar": "قديم باكنهام",
                "label_fr": "Vieux Buckenham"
            },
            {
                "value": 58079,
                "label": "Taplow",
                "label_ar": "تابلو",
                "label_fr": "Taplow"
            },
            {
                "value": 58823,
                "label": "Boreham",
                "label_ar": "بورهام",
                "label_fr": "Boreham"
            },
            {
                "value": 56655,
                "label": "Addlestone",
                "label_ar": "آدلستون",
                "label_fr": "Addlestone"
            },
            {
                "value": 58589,
                "label": "Belleek",
                "label_ar": "بيليك",
                "label_fr": "Belleek"
            },
            {
                "value": 55980,
                "label": "Fochabers",
                "label_ar": "Fochabers",
                "label_fr": "Fochabers"
            },
            {
                "value": 55924,
                "label": "Usk",
                "label_ar": "Usk",
                "label_fr": "Usk"
            },
            {
                "value": 57653,
                "label": "Henley-on-Thames",
                "label_ar": "هينلي أون تيمز",
                "label_fr": "Henley-on-Thames"
            },
            {
                "value": 56627,
                "label": "Freshwater",
                "label_ar": "مياه عذبة",
                "label_fr": "Eau fraiche"
            },
            {
                "value": 56197,
                "label": "Kettering",
                "label_ar": "كيترينج",
                "label_fr": "Kettering"
            },
            {
                "value": 58711,
                "label": "Cranford",
                "label_ar": "كرانفورد",
                "label_fr": "Cranford"
            },
            {
                "value": 57066,
                "label": "Liss",
                "label_ar": "ليس",
                "label_fr": "Liss"
            },
            {
                "value": 57187,
                "label": "Laurencekirk",
                "label_ar": "لورنسكيرك",
                "label_fr": "Laurencekirk"
            },
            {
                "value": 58213,
                "label": "Maiden Newton",
                "label_ar": "نيوتن البكر",
                "label_fr": "Maiden Newton"
            },
            {
                "value": 56496,
                "label": "Bewdley",
                "label_ar": "بيودلي",
                "label_fr": "Bewdley"
            },
            {
                "value": 58285,
                "label": "Pittenweem",
                "label_ar": "بيتنويم",
                "label_fr": "Pittenweem"
            },
            {
                "value": 56069,
                "label": "Braintree",
                "label_ar": "برينتري",
                "label_fr": "Braintree"
            },
            {
                "value": 58757,
                "label": "Burwell",
                "label_ar": "بورويل",
                "label_fr": "Burwell"
            },
            {
                "value": 56039,
                "label": "New Cross",
                "label_ar": "الصليب الجديد",
                "label_fr": "Nouvelle croix"
            },
            {
                "value": 58555,
                "label": "Conisbrough",
                "label_ar": "كونيسبروه",
                "label_fr": "Conisbrough"
            },
            {
                "value": 58314,
                "label": "Kelvedon",
                "label_ar": "كلفيدون",
                "label_fr": "Kelvedon"
            },
            {
                "value": 57889,
                "label": "Winterborne Kingston",
                "label_ar": "وينتربورن كينغستون",
                "label_fr": "Winterborne Kingston"
            },
            {
                "value": 58217,
                "label": "Nafferton",
                "label_ar": "نافرتون",
                "label_fr": "Nafferton"
            },
            {
                "value": 57704,
                "label": "Comrie",
                "label_ar": "كومري",
                "label_fr": "Comrie"
            },
            {
                "value": 58313,
                "label": "Staple",
                "label_ar": "تدبيس",
                "label_fr": "Agrafe"
            },
            {
                "value": 56486,
                "label": "Reigate",
                "label_ar": "ريجيت",
                "label_fr": "Reigate"
            },
            {
                "value": 58463,
                "label": "Hanley",
                "label_ar": "هانلي",
                "label_fr": "Hanley"
            },
            {
                "value": 58501,
                "label": "Wold Newton",
                "label_ar": "وولد نيوتن",
                "label_fr": "Wold Newton"
            },
            {
                "value": 58728,
                "label": "Brockley Green",
                "label_ar": "بروكلي جرين",
                "label_fr": "Vert Brockley"
            },
            {
                "value": 56625,
                "label": "West Drayton",
                "label_ar": "ويست درايتون",
                "label_fr": "West Drayton"
            },
            {
                "value": 56813,
                "label": "Renfrew",
                "label_ar": "رينفرو",
                "label_fr": "Renfrew"
            },
            {
                "value": 57322,
                "label": "Queenborough",
                "label_ar": "كوينبوروه",
                "label_fr": "Queenborough"
            },
            {
                "value": 57222,
                "label": "Carnoustie",
                "label_ar": "كارنوستي",
                "label_fr": "Carnoustie"
            },
            {
                "value": 58683,
                "label": "Gifford",
                "label_ar": "جيفورد",
                "label_fr": "Gifford"
            },
            {
                "value": 58702,
                "label": "Egerton",
                "label_ar": "إجيرتون",
                "label_fr": "Egerton"
            },
            {
                "value": 58850,
                "label": "Droxford",
                "label_ar": "دروكسفورد",
                "label_fr": "Droxford"
            },
            {
                "value": 57503,
                "label": "Exminster",
                "label_ar": "إكسمينستر",
                "label_fr": "Exminster"
            },
            {
                "value": 58529,
                "label": "Midgham",
                "label_ar": "ميدغام",
                "label_fr": "Midgham"
            },
            {
                "value": 57973,
                "label": "Rowhedge",
                "label_ar": "روهيدج",
                "label_fr": "Rowhedge"
            },
            {
                "value": 56644,
                "label": "Morden",
                "label_ar": "حديث",
                "label_fr": "Morden"
            },
            {
                "value": 58802,
                "label": "Ashby de la Launde",
                "label_ar": "اشبي دي لا لوندي",
                "label_fr": "Ashby de la Launde"
            },
            {
                "value": 55974,
                "label": "Darvel",
                "label_ar": "دارفيل",
                "label_fr": "Darvel"
            },
            {
                "value": 57974,
                "label": "South Carlton",
                "label_ar": "ساوث كارلتون",
                "label_fr": "South Carlton"
            },
            {
                "value": 58786,
                "label": "Huish",
                "label_ar": "هويش",
                "label_fr": "Huish"
            },
            {
                "value": 57886,
                "label": "Stocksfield",
                "label_ar": "ستوكسفيلد",
                "label_fr": "Stocksfield"
            },
            {
                "value": 56759,
                "label": "Welwyn Garden City",
                "label_ar": "ويلوين جاردن سيتي",
                "label_fr": "Welwyn Garden City"
            },
            {
                "value": 58400,
                "label": "Sacriston",
                "label_ar": "ساكريستون",
                "label_fr": "Sacriston"
            },
            {
                "value": 58653,
                "label": "Medstead",
                "label_ar": "ميدستيد",
                "label_fr": "Medstead"
            },
            {
                "value": 58854,
                "label": "Llantrisant",
                "label_ar": "لانتريسانت",
                "label_fr": "Llantrisant"
            },
            {
                "value": 56026,
                "label": "East Kilbride",
                "label_ar": "شرق كيلبرايد",
                "label_fr": "East Kilbride"
            },
            {
                "value": 56849,
                "label": "Askam in Furness",
                "label_ar": "Askam في فورنيس",
                "label_fr": "Askam à Furness"
            },
            {
                "value": 56929,
                "label": "Sandwich",
                "label_ar": "ساندويتش",
                "label_fr": "Sandwich"
            },
            {
                "value": 56437,
                "label": "Pontypool",
                "label_ar": "بونتيبول",
                "label_fr": "Pontypool"
            },
            {
                "value": 57422,
                "label": "Plockton",
                "label_ar": "بلوكتون",
                "label_fr": "Plockton"
            },
            {
                "value": 57424,
                "label": "Inveraray",
                "label_ar": "إينفيراري",
                "label_fr": "Inveraray"
            },
            {
                "value": 56088,
                "label": "Dewsbury",
                "label_ar": "ديوسبيري",
                "label_fr": "Dewsbury"
            },
            {
                "value": 58579,
                "label": "Bilsthorpe",
                "label_ar": "بيلثورب",
                "label_fr": "Bilsthorpe"
            },
            {
                "value": 58477,
                "label": "Carrbridge",
                "label_ar": "كاربريدج",
                "label_fr": "Carrbridge"
            },
            {
                "value": 57927,
                "label": "Finedon",
                "label_ar": "فيندون",
                "label_fr": "Finedon"
            },
            {
                "value": 57835,
                "label": "Cleator Moor",
                "label_ar": "كليتور مور",
                "label_fr": "Cleator Moor"
            },
            {
                "value": 58028,
                "label": "Upton upon Severn",
                "label_ar": "أبتون أبون سيفيرن",
                "label_fr": "Upton sur Severn"
            },
            {
                "value": 56570,
                "label": "Buckfastleigh",
                "label_ar": "باكفاستلي",
                "label_fr": "Buckfastleigh"
            },
            {
                "value": 56683,
                "label": "Larne",
                "label_ar": "لارن",
                "label_fr": "Larne"
            },
            {
                "value": 57693,
                "label": "High Blantyre",
                "label_ar": "ارتفاع بلانتير",
                "label_fr": "Haut Blantyre"
            },
            {
                "value": 56623,
                "label": "Wimbledon",
                "label_ar": "ويمبلدون",
                "label_fr": "Wimbledon"
            },
            {
                "value": 58655,
                "label": "Comber",
                "label_ar": "كومبر",
                "label_fr": "Peigneuse"
            },
            {
                "value": 57919,
                "label": "Barnham",
                "label_ar": "بارنهام",
                "label_fr": "Barnham"
            },
            {
                "value": 57930,
                "label": "High Halden",
                "label_ar": "هاي هالدن",
                "label_fr": "Haut Halden"
            },
            {
                "value": 58325,
                "label": "Hennock",
                "label_ar": "هينوك",
                "label_fr": "Hennock"
            },
            {
                "value": 56424,
                "label": "Saffron Walden",
                "label_ar": "الزعفران والدن",
                "label_fr": "Safran Walden"
            },
            {
                "value": 58269,
                "label": "Arley",
                "label_ar": "أرلي",
                "label_fr": "Arley"
            },
            {
                "value": 56372,
                "label": "Havant",
                "label_ar": "هافانت",
                "label_fr": "Havant"
            },
            {
                "value": 55917,
                "label": "Abertillery",
                "label_ar": "أبيرتيليري",
                "label_fr": "Abertillerie"
            },
            {
                "value": 56264,
                "label": "London Borough of Hounslow",
                "label_ar": "منطقة هونسلو في لندن",
                "label_fr": "Borough londonien de Hounslow"
            },
            {
                "value": 57400,
                "label": "Pilton",
                "label_ar": "بيلتون",
                "label_fr": "Pilton"
            },
            {
                "value": 56884,
                "label": "Bowes",
                "label_ar": "باوز",
                "label_fr": "Bowes"
            },
            {
                "value": 56852,
                "label": "Halewood",
                "label_ar": "هالوود",
                "label_fr": "Halewood"
            },
            {
                "value": 58688,
                "label": "Lindal in Furness",
                "label_ar": "ليندال في فرنيس",
                "label_fr": "Lindal à Furness"
            },
            {
                "value": 57155,
                "label": "Grove",
                "label_ar": "بستان",
                "label_fr": "Bosquet"
            },
            {
                "value": 58137,
                "label": "Avoch",
                "label_ar": "أفوك",
                "label_fr": "Avoch"
            },
            {
                "value": 58489,
                "label": "Alston",
                "label_ar": "ألستون",
                "label_fr": "Alston"
            },
            {
                "value": 58630,
                "label": "Keston",
                "label_ar": "كيستون",
                "label_fr": "Keston"
            },
            {
                "value": 57648,
                "label": "Owslebury",
                "label_ar": "أوسلبري",
                "label_fr": "Owslebury"
            },
            {
                "value": 57657,
                "label": "South Normanton",
                "label_ar": "جنوب نورمانتون",
                "label_fr": "Sud Normanton"
            },
            {
                "value": 56789,
                "label": "Rhoose",
                "label_ar": "روز",
                "label_fr": "Rhoose"
            },
            {
                "value": 55895,
                "label": "Bournemouth",
                "label_ar": "بورنماوث",
                "label_fr": "Bournemouth"
            },
            {
                "value": 58750,
                "label": "Longport",
                "label_ar": "لونجبورت",
                "label_fr": "Longport"
            },
            {
                "value": 57198,
                "label": "Kirby Cross",
                "label_ar": "كيربي كروس",
                "label_fr": "Kirby Cross"
            },
            {
                "value": 56106,
                "label": "Tonbridge",
                "label_ar": "تونبريدج",
                "label_fr": "Tonbridge"
            },
            {
                "value": 58482,
                "label": "Whitton",
                "label_ar": "ويتون",
                "label_fr": "Whitton"
            },
            {
                "value": 57138,
                "label": "Thornton",
                "label_ar": "ثورنتون",
                "label_fr": "Thornton"
            },
            {
                "value": 57882,
                "label": "Penn",
                "label_ar": "بن",
                "label_fr": "Penn"
            },
            {
                "value": 56332,
                "label": "Colyton",
                "label_ar": "كوليتون",
                "label_fr": "Colyton"
            },
            {
                "value": 56673,
                "label": "Rugeley",
                "label_ar": "روجلي",
                "label_fr": "Rugeley"
            },
            {
                "value": 57686,
                "label": "Brockworth",
                "label_ar": "بروكورث",
                "label_fr": "Brockworth"
            },
            {
                "value": 58745,
                "label": "Greenhead",
                "label_ar": "جرينهيد",
                "label_fr": "Greenhead"
            },
            {
                "value": 57452,
                "label": "Garston",
                "label_ar": "جارستون",
                "label_fr": "Garston"
            },
            {
                "value": 56286,
                "label": "Stafford",
                "label_ar": "ستافورد",
                "label_fr": "Stafford"
            },
            {
                "value": 57462,
                "label": "Saint Brides Major",
                "label_ar": "القديسة عرائس الرائد",
                "label_fr": "Saint Brides Major"
            },
            {
                "value": 56137,
                "label": "Daventry",
                "label_ar": "دافينتري",
                "label_fr": "Daventry"
            },
            {
                "value": 58350,
                "label": "Milford",
                "label_ar": "ميلفورد",
                "label_fr": "Milford"
            },
            {
                "value": 58281,
                "label": "Loudwater",
                "label_ar": "بصوت عال",
                "label_fr": "Loudwater"
            },
            {
                "value": 58054,
                "label": "Marple",
                "label_ar": "ماربل",
                "label_fr": "Marple"
            },
            {
                "value": 56456,
                "label": "Kilbride",
                "label_ar": "كيلبرايد",
                "label_fr": "Kilbride"
            },
            {
                "value": 58739,
                "label": "Highbury",
                "label_ar": "هايبري",
                "label_fr": "Highbury"
            },
            {
                "value": 56695,
                "label": "Egham",
                "label_ar": "إيغام",
                "label_fr": "Egham"
            },
            {
                "value": 57396,
                "label": "Rainhill",
                "label_ar": "رينهيل",
                "label_fr": "Rainhill"
            },
            {
                "value": 56509,
                "label": "Clevedon",
                "label_ar": "كليفيدون",
                "label_fr": "Clevedon"
            },
            {
                "value": 56947,
                "label": "Cliffe",
                "label_ar": "كليف",
                "label_fr": "Cliffe"
            },
            {
                "value": 58613,
                "label": "Keele",
                "label_ar": "كيلي",
                "label_fr": "Keele"
            },
            {
                "value": 58117,
                "label": "Ballymoney",
                "label_ar": "باليموني",
                "label_fr": "Ballymoney"
            },
            {
                "value": 56126,
                "label": "Wellingborough",
                "label_ar": "ويلينجبورو",
                "label_fr": "Wellingborough"
            },
            {
                "value": 56834,
                "label": "Hungerford",
                "label_ar": "هانجرفورد",
                "label_fr": "Hungerford"
            },
            {
                "value": 57625,
                "label": "Brampton",
                "label_ar": "برامبتون",
                "label_fr": "Brampton"
            },
            {
                "value": 56066,
                "label": "Ruislip",
                "label_ar": "Ruislip",
                "label_fr": "Ruislip"
            },
            {
                "value": 56678,
                "label": "Chippenham",
                "label_ar": "تشبنهام",
                "label_fr": "Chippenham"
            },
            {
                "value": 58439,
                "label": "Abercynon",
                "label_ar": "أبيرسينون",
                "label_fr": "Abercynon"
            },
            {
                "value": 58161,
                "label": "Radcliffe on Trent",
                "label_ar": "رادكليف على ترينت",
                "label_fr": "Radcliffe sur Trent"
            },
            {
                "value": 56384,
                "label": "Selby",
                "label_ar": "سيلبي",
                "label_fr": "Selby"
            },
            {
                "value": 57234,
                "label": "Christleton",
                "label_ar": "كريستلتون",
                "label_fr": "Christleton"
            },
            {
                "value": 57038,
                "label": "Wedmore",
                "label_ar": "ويدمور",
                "label_fr": "Wedmore"
            },
            {
                "value": 56594,
                "label": "Gelligaer",
                "label_ar": "جيليجير",
                "label_fr": "Gelligaer"
            },
            {
                "value": 57671,
                "label": "Chalgrove",
                "label_ar": "تشالغروف",
                "label_fr": "Chalgrove"
            },
            {
                "value": 57784,
                "label": "Wentworth",
                "label_ar": "وينتورث",
                "label_fr": "Wentworth"
            },
            {
                "value": 58164,
                "label": "Kedington",
                "label_ar": "كيدنجتون",
                "label_fr": "Kedington"
            },
            {
                "value": 58297,
                "label": "Broughton",
                "label_ar": "جلبت على",
                "label_fr": "Introduit le"
            },
            {
                "value": 58441,
                "label": "Stranraer",
                "label_ar": "غريب",
                "label_fr": "Stranraer"
            },
            {
                "value": 57010,
                "label": "Bagshot",
                "label_ar": "باجشوت",
                "label_fr": "Bagshot"
            },
            {
                "value": 58846,
                "label": "Little Milton",
                "label_ar": "ليتل ميلتون",
                "label_fr": "Petit Milton"
            },
            {
                "value": 57453,
                "label": "Church Gresley",
                "label_ar": "الكنيسة جريسلي",
                "label_fr": "Église Gresley"
            },
            {
                "value": 57721,
                "label": "Holmes Chapel",
                "label_ar": "هولمز تشابل",
                "label_fr": "chapelle Holmes"
            },
            {
                "value": 56171,
                "label": "Sunderland",
                "label_ar": "سندرلاند",
                "label_fr": "Sunderland"
            },
            {
                "value": 56738,
                "label": "Carterton",
                "label_ar": "كارترتون",
                "label_fr": "Carterton"
            },
            {
                "value": 58099,
                "label": "Tetsworth",
                "label_ar": "تيتسورث",
                "label_fr": "Tetsworth"
            },
            {
                "value": 58271,
                "label": "Water Orton",
                "label_ar": "ماء أورتن",
                "label_fr": "Eau Orton"
            },
            {
                "value": 57951,
                "label": "Barmston",
                "label_ar": "بارمستون",
                "label_fr": "Barmston"
            },
            {
                "value": 58499,
                "label": "Withernwick",
                "label_ar": "ويذرنويك",
                "label_fr": "Withernwick"
            },
            {
                "value": 57701,
                "label": "Maghull",
                "label_ar": "Maghull",
                "label_fr": "Maghull"
            },
            {
                "value": 58216,
                "label": "Linton upon Ouse",
                "label_ar": "لينتون أبون أووز",
                "label_fr": "Linton sur Ouse"
            },
            {
                "value": 58336,
                "label": "Castle Douglas",
                "label_ar": "قلعة دوغلاس",
                "label_fr": "Château Douglas"
            },
            {
                "value": 56269,
                "label": "Chard",
                "label_ar": "شارد",
                "label_fr": "Bettes"
            },
            {
                "value": 56557,
                "label": "Diss",
                "label_ar": "ديس",
                "label_fr": "insulter"
            },
            {
                "value": 58559,
                "label": "Llanddulas",
                "label_ar": "لاندولاس",
                "label_fr": "Llanddulas"
            },
            {
                "value": 56717,
                "label": "Newtownards",
                "label_ar": "نيوتاوناردز",
                "label_fr": "Newtownards"
            },
            {
                "value": 57955,
                "label": "Cardross",
                "label_ar": "كاردروس",
                "label_fr": "Cardross"
            },
            {
                "value": 56976,
                "label": "Ashington",
                "label_ar": "أشينغتون",
                "label_fr": "Ashington"
            },
            {
                "value": 56364,
                "label": "Wallsend",
                "label_ar": "وولسيند",
                "label_fr": "Wallsend"
            },
            {
                "value": 57117,
                "label": "Normanton",
                "label_ar": "نورمانتون",
                "label_fr": "Normanton"
            },
            {
                "value": 57197,
                "label": "Ifield",
                "label_ar": "إيفيلد",
                "label_fr": "Ifield"
            },
            {
                "value": 58869,
                "label": "Hazel Grove",
                "label_ar": "هازل جروف",
                "label_fr": "Hazel Grove"
            },
            {
                "value": 57566,
                "label": "Inverness-shire",
                "label_ar": "إينفيرنيس شاير",
                "label_fr": "Inverness-shire"
            },
            {
                "value": 56757,
                "label": "Draycott in the Moors",
                "label_ar": "درايكوت في المور",
                "label_fr": "Draycott dans les Maures"
            },
            {
                "value": 58753,
                "label": "Beaufort",
                "label_ar": "بوفورت",
                "label_fr": "Beaufort"
            },
            {
                "value": 57042,
                "label": "Barnard Castle",
                "label_ar": "قلعة بارنارد",
                "label_fr": "Château Barnard"
            },
            {
                "value": 55871,
                "label": "Tewkesbury",
                "label_ar": "توكيسبيري",
                "label_fr": "Tewkesbury"
            },
            {
                "value": 58123,
                "label": "Randalstown",
                "label_ar": "راندالستاون",
                "label_fr": "Randalstown"
            },
            {
                "value": 58709,
                "label": "Bampton",
                "label_ar": "بامبتون",
                "label_fr": "Bampton"
            },
            {
                "value": 58595,
                "label": "Moorsholm",
                "label_ar": "مورشولم",
                "label_fr": "Moorsholm"
            },
            {
                "value": 56898,
                "label": "Elloughton",
                "label_ar": "إلوغتون",
                "label_fr": "Elloughton"
            },
            {
                "value": 57216,
                "label": "Withernsea",
                "label_ar": "ويذرنس",
                "label_fr": "Withernsea"
            },
            {
                "value": 58545,
                "label": "Duns",
                "label_ar": "دونز",
                "label_fr": "Duns"
            },
            {
                "value": 56025,
                "label": "Dunfermline",
                "label_ar": "دنفرملاين",
                "label_fr": "Dunfermline"
            },
            {
                "value": 57729,
                "label": "Greenfield",
                "label_ar": "جرينفيلد",
                "label_fr": "Greenfield"
            },
            {
                "value": 58218,
                "label": "Coalbrookdale",
                "label_ar": "كولبروكديل",
                "label_fr": "Coalbrookdale"
            },
            {
                "value": 56663,
                "label": "Sompting",
                "label_ar": "شيء",
                "label_fr": "Somptueux"
            },
            {
                "value": 57759,
                "label": "Stallingborough",
                "label_ar": "ستالينجبره",
                "label_fr": "Stallingborough"
            },
            {
                "value": 57282,
                "label": "Ramsbottom",
                "label_ar": "رامسبوتوم",
                "label_fr": "Ramsbottom"
            },
            {
                "value": 57157,
                "label": "Long Eaton",
                "label_ar": "لونغ إيتون",
                "label_fr": "Long Eaton"
            },
            {
                "value": 57032,
                "label": "Purley",
                "label_ar": "بورلي",
                "label_fr": "Purley"
            },
            {
                "value": 57083,
                "label": "Chadwell",
                "label_ar": "شادويل",
                "label_fr": "Chadwell"
            },
            {
                "value": 57405,
                "label": "Pwllheli",
                "label_ar": "Pwllheli",
                "label_fr": "Pwllheli"
            },
            {
                "value": 55873,
                "label": "Coleford",
                "label_ar": "كوليفورد",
                "label_fr": "Coleford"
            },
            {
                "value": 57174,
                "label": "Porthleven",
                "label_ar": "بورثليفن",
                "label_fr": "Porthleven"
            },
            {
                "value": 58111,
                "label": "Deighton",
                "label_ar": "ديتون",
                "label_fr": "Deighton"
            },
            {
                "value": 58023,
                "label": "Kilbarchan",
                "label_ar": "كيلبارشان",
                "label_fr": "Kilbarchan"
            },
            {
                "value": 58692,
                "label": "Stranmillis",
                "label_ar": "Stranmillis",
                "label_fr": "Stranmillis"
            },
            {
                "value": 57247,
                "label": "Willaston",
                "label_ar": "ويلستون",
                "label_fr": "Willaston"
            },
            {
                "value": 57413,
                "label": "Hartwell",
                "label_ar": "هارتويل",
                "label_fr": "Hartwell"
            },
            {
                "value": 57876,
                "label": "Kingskerswell",
                "label_ar": "Kingskerswell",
                "label_fr": "Kingskerswell"
            },
            {
                "value": 58445,
                "label": "Maerdy",
                "label_ar": "ميردي",
                "label_fr": "Maerdy"
            },
            {
                "value": 58134,
                "label": "Selborne",
                "label_ar": "سيلبورن",
                "label_fr": "Selborne"
            },
            {
                "value": 58278,
                "label": "Carrowdore",
                "label_ar": "كارودور",
                "label_fr": "Carrowdore"
            },
            {
                "value": 56918,
                "label": "Haverfordwest",
                "label_ar": "هافرفوردويست",
                "label_fr": "Haverfordwest"
            },
            {
                "value": 56315,
                "label": "Dursley",
                "label_ar": "دورسلي",
                "label_fr": "Dursley"
            },
            {
                "value": 56299,
                "label": "Bilston",
                "label_ar": "بيلستون",
                "label_fr": "Bilston"
            },
            {
                "value": 55868,
                "label": "Solihull",
                "label_ar": "سوليهول",
                "label_fr": "Solihull"
            },
            {
                "value": 58547,
                "label": "Heybridge",
                "label_ar": "هيبريدج",
                "label_fr": "Heybridge"
            },
            {
                "value": 56943,
                "label": "New Romney",
                "label_ar": "رومني الجديد",
                "label_fr": "New Romney"
            },
            {
                "value": 57912,
                "label": "Warrenpoint",
                "label_ar": "وارن بوينت",
                "label_fr": "Warrenpoint"
            },
            {
                "value": 58110,
                "label": "Maghera",
                "label_ar": "المغيرة",
                "label_fr": "Maghera"
            },
            {
                "value": 57432,
                "label": "Fintry",
                "label_ar": "فينتري",
                "label_fr": "Fintry"
            },
            {
                "value": 57079,
                "label": "Bulkington",
                "label_ar": "بولكنجتون",
                "label_fr": "Bulkington"
            },
            {
                "value": 57166,
                "label": "Newlands",
                "label_ar": "اراضي جديدة",
                "label_fr": "Nouveaux territoires"
            },
            {
                "value": 58150,
                "label": "Falkland",
                "label_ar": "فوكلاند",
                "label_fr": "Falkland"
            },
            {
                "value": 58569,
                "label": "Camberwell",
                "label_ar": "كامبرويل",
                "label_fr": "Camberwell"
            },
            {
                "value": 57194,
                "label": "Mill Hill",
                "label_ar": "ميل هيل",
                "label_fr": "Mill Hill"
            },
            {
                "value": 56771,
                "label": "Trimdon",
                "label_ar": "تريمدون",
                "label_fr": "Trimdon"
            },
            {
                "value": 57354,
                "label": "Ashbourne",
                "label_ar": "أشبورن",
                "label_fr": "Ashbourne"
            },
            {
                "value": 56199,
                "label": "Bungay",
                "label_ar": "بونغاي",
                "label_fr": "Bungay"
            },
            {
                "value": 56788,
                "label": "Ellon",
                "label_ar": "إيلون",
                "label_fr": "Ellon"
            },
            {
                "value": 58554,
                "label": "Farndon",
                "label_ar": "فارندون",
                "label_fr": "Farndon"
            },
            {
                "value": 56688,
                "label": "Llandudno Junction",
                "label_ar": "مفرق لاندودنو",
                "label_fr": "Jonction de Llandudno"
            },
            {
                "value": 56515,
                "label": "Chepstow",
                "label_ar": "تشيبستو",
                "label_fr": "Chepstow"
            },
            {
                "value": 57558,
                "label": "Zeals",
                "label_ar": "الحماسة",
                "label_fr": "Les zélés"
            },
            {
                "value": 58663,
                "label": "Brompton",
                "label_ar": "برومبتون",
                "label_fr": "Brompton"
            },
            {
                "value": 58154,
                "label": "Forres",
                "label_ar": "فوريس",
                "label_fr": "Forres"
            },
            {
                "value": 57515,
                "label": "Myton on Swale",
                "label_ar": "ميتون أون سويل",
                "label_fr": "Myton sur Swale"
            },
            {
                "value": 57881,
                "label": "Marden",
                "label_ar": "ماردن",
                "label_fr": "Marden"
            },
            {
                "value": 57844,
                "label": "Hagley",
                "label_ar": "هاجلي",
                "label_fr": "Hagley"
            },
            {
                "value": 58229,
                "label": "Saint Erme",
                "label_ar": "القديس إرمي",
                "label_fr": "Saint Erme"
            },
            {
                "value": 57554,
                "label": "Corfe Castle",
                "label_ar": "قلعة كورف",
                "label_fr": "Château de Corfe"
            },
            {
                "value": 56901,
                "label": "Newton Stewart",
                "label_ar": "نيوتن ستيوارت",
                "label_fr": "Newton Stewart"
            },
            {
                "value": 58330,
                "label": "Norton Lindsey",
                "label_ar": "نورتون ليندسي",
                "label_fr": "Norton Lindsey"
            },
            {
                "value": 55988,
                "label": "Newcastle upon Tyne",
                "label_ar": "نيوكاسل أبون تاين",
                "label_fr": "Newcastle upon Tyne"
            },
            {
                "value": 56108,
                "label": "Tilbury",
                "label_ar": "التلبرية مركبة خفيفة ذات عجلتين",
                "label_fr": "Tilbury"
            },
            {
                "value": 55909,
                "label": "New Milton",
                "label_ar": "نيو ميلتون",
                "label_fr": "Nouveau Milton"
            },
            {
                "value": 57571,
                "label": "Symington",
                "label_ar": "سيمينغتون",
                "label_fr": "Symington"
            },
            {
                "value": 57598,
                "label": "Drighlington",
                "label_ar": "دريلينغتون",
                "label_fr": "Drighlington"
            },
            {
                "value": 57862,
                "label": "Inverkeilor",
                "label_ar": "إنفيركيلور",
                "label_fr": "Inverkeilor"
            },
            {
                "value": 57750,
                "label": "Ben Rhydding",
                "label_ar": "بن ريدينغ",
                "label_fr": "Ben Rhydding"
            },
            {
                "value": 58293,
                "label": "Blackwater",
                "label_ar": "مياه سوداء",
                "label_fr": "Eau noire"
            },
            {
                "value": 58566,
                "label": "Padstow",
                "label_ar": "بادستو",
                "label_fr": "Padstow"
            },
            {
                "value": 58236,
                "label": "Alsager",
                "label_ar": "الصقر",
                "label_fr": "Alsager"
            },
            {
                "value": 57745,
                "label": "Skilgate",
                "label_ar": "سكيلجيت",
                "label_fr": "Skilgate"
            },
            {
                "value": 58361,
                "label": "Framlingham",
                "label_ar": "فراملينجهام",
                "label_fr": "Framlingham"
            },
            {
                "value": 57269,
                "label": "Forest Row",
                "label_ar": "فورست رو",
                "label_fr": "Rangée de la forêt"
            },
            {
                "value": 57720,
                "label": "Northfield",
                "label_ar": "نورثفيلد",
                "label_fr": "Northfield"
            },
            {
                "value": 57652,
                "label": "Angmering",
                "label_ar": "الغضب",
                "label_fr": "Angmering"
            },
            {
                "value": 58467,
                "label": "Oxenhope",
                "label_ar": "Oxenhope",
                "label_fr": "Oxenhope"
            },
            {
                "value": 57824,
                "label": "Willesden",
                "label_ar": "ويليسدن",
                "label_fr": "Willesden"
            },
            {
                "value": 56953,
                "label": "Croston",
                "label_ar": "كروستون",
                "label_fr": "Croston"
            },
            {
                "value": 58565,
                "label": "Ilchester",
                "label_ar": "إلتشيستر",
                "label_fr": "Ilchester"
            },
            {
                "value": 57843,
                "label": "Coalisland",
                "label_ar": "كواليسلاند",
                "label_fr": "Coalisland"
            },
            {
                "value": 57031,
                "label": "Buckingham",
                "label_ar": "باكنغهام",
                "label_fr": "Buckingham"
            },
            {
                "value": 56659,
                "label": "Caterham",
                "label_ar": "كاترهام",
                "label_fr": "Caterham"
            },
            {
                "value": 56761,
                "label": "Streatham",
                "label_ar": "ستريثام",
                "label_fr": "Streatham"
            },
            {
                "value": 58543,
                "label": "Mattishall",
                "label_ar": "ماتيشال",
                "label_fr": "Mattishall"
            },
            {
                "value": 58411,
                "label": "North Petherton",
                "label_ar": "شمال بيترتون",
                "label_fr": "North Petherton"
            },
            {
                "value": 56205,
                "label": "March",
                "label_ar": "مارس",
                "label_fr": "Mars"
            },
            {
                "value": 57619,
                "label": "Broadwas",
                "label_ar": "برودس",
                "label_fr": "Broadwas"
            },
            {
                "value": 57654,
                "label": "Cricklade",
                "label_ar": "كريكليد",
                "label_fr": "Cricklade"
            },
            {
                "value": 57672,
                "label": "Studham",
                "label_ar": "ستودهام",
                "label_fr": "Studham"
            },
            {
                "value": 56982,
                "label": "Ingatestone",
                "label_ar": "انجاتيسون",
                "label_fr": "Ingatestone"
            },
            {
                "value": 56886,
                "label": "Neston",
                "label_ar": "نيستون",
                "label_fr": "Neston"
            },
            {
                "value": 58853,
                "label": "Kinloch Rannoch",
                "label_ar": "كينلوش رانوش",
                "label_fr": "Kinloch Rannoch"
            },
            {
                "value": 56371,
                "label": "Saint Leonards-on-Sea",
                "label_ar": "سانت ليونارد أون سي",
                "label_fr": "Saint Léonards-sur-Mer"
            },
            {
                "value": 55877,
                "label": "Malvern",
                "label_ar": "مالفيرن",
                "label_fr": "Malvern"
            },
            {
                "value": 57607,
                "label": "Moston",
                "label_ar": "موستون",
                "label_fr": "Moston"
            },
            {
                "value": 58492,
                "label": "Tadmarton",
                "label_ar": "تدمارتون",
                "label_fr": "Tadmarton"
            },
            {
                "value": 58191,
                "label": "Hatherleigh",
                "label_ar": "Hatherleigh",
                "label_fr": "Hatherleigh"
            },
            {
                "value": 56370,
                "label": "Wadhurst",
                "label_ar": "وادهيرست",
                "label_fr": "Wadhurst"
            },
            {
                "value": 58680,
                "label": "Newbold",
                "label_ar": "نيوبولد",
                "label_fr": "Newbold"
            },
            {
                "value": 57440,
                "label": "Longstanton",
                "label_ar": "لونجستانتون",
                "label_fr": "Longstanton"
            },
            {
                "value": 56766,
                "label": "Bonnyrigg",
                "label_ar": "بونيريغ",
                "label_fr": "Bonnyrigg"
            },
            {
                "value": 56610,
                "label": "Sandbach",
                "label_ar": "ساندباخ",
                "label_fr": "Sandbach"
            },
            {
                "value": 57023,
                "label": "Market Rasen",
                "label_ar": "سوق راسين",
                "label_fr": "Marché Rasen"
            },
            {
                "value": 56009,
                "label": "Guildford",
                "label_ar": "جيلدفورد",
                "label_fr": "Guildford"
            },
            {
                "value": 56562,
                "label": "London Borough of Bromley",
                "label_ar": "بورو بروملي في لندن",
                "label_fr": "Borough londonien de Bromley"
            },
            {
                "value": 56056,
                "label": "Barnet",
                "label_ar": "بارنت",
                "label_fr": "Barnet"
            },
            {
                "value": 58602,
                "label": "Reed",
                "label_ar": "قصب",
                "label_fr": "Roseau"
            },
            {
                "value": 58323,
                "label": "Croughton",
                "label_ar": "كروتون",
                "label_fr": "Croughton"
            },
            {
                "value": 58220,
                "label": "Mells",
                "label_ar": "يذوب",
                "label_fr": "Mells"
            },
            {
                "value": 57900,
                "label": "Storrington",
                "label_ar": "ستورينجتون",
                "label_fr": "Storrington"
            },
            {
                "value": 56973,
                "label": "Llanfyrnach",
                "label_ar": "لانفيرناخ",
                "label_fr": "Llanfyrnach"
            },
            {
                "value": 57516,
                "label": "Lindfield",
                "label_ar": "ليندفيلد",
                "label_fr": "Lindfield"
            },
            {
                "value": 56143,
                "label": "Bedford",
                "label_ar": "بيدفورد",
                "label_fr": "Bedford"
            },
            {
                "value": 57749,
                "label": "Grantown on Spey",
                "label_ar": "Grantown على Spey",
                "label_fr": "Grantown sur Spey"
            },
            {
                "value": 57996,
                "label": "Chulmleigh",
                "label_ar": "شولملي",
                "label_fr": "Chulmleigh"
            },
            {
                "value": 56874,
                "label": "Bolney",
                "label_ar": "بولني",
                "label_fr": "Bolney"
            },
            {
                "value": 55889,
                "label": "Bristol",
                "label_ar": "بريستول",
                "label_fr": "Bristol"
            },
            {
                "value": 58284,
                "label": "Langley",
                "label_ar": "لانجلي",
                "label_fr": "Langley"
            },
            {
                "value": 55993,
                "label": "Sandhurst",
                "label_ar": "ساندهيرست",
                "label_fr": "Sandhurst"
            },
            {
                "value": 58017,
                "label": "Besthorpe",
                "label_ar": "بيستروب",
                "label_fr": "Besthorpe"
            },
            {
                "value": 58540,
                "label": "Watchfield",
                "label_ar": "واتشفيلد",
                "label_fr": "Watchfield"
            },
            {
                "value": 58447,
                "label": "Stony Stratford",
                "label_ar": "ستوني ستراتفورد",
                "label_fr": "Stony Stratford"
            },
            {
                "value": 57702,
                "label": "Neasden",
                "label_ar": "نيسدن",
                "label_fr": "Neasden"
            },
            {
                "value": 58148,
                "label": "Seaview",
                "label_ar": "اطلالة البحر",
                "label_fr": "Vue sur la mer"
            },
            {
                "value": 58651,
                "label": "Guthrie",
                "label_ar": "جوثري",
                "label_fr": "Guthrie"
            },
            {
                "value": 56003,
                "label": "Byfleet",
                "label_ar": "بايفليت",
                "label_fr": "Byfleet"
            },
            {
                "value": 56111,
                "label": "Dartford",
                "label_ar": "دارتفورد",
                "label_fr": "Dartford"
            },
            {
                "value": 56686,
                "label": "Denny",
                "label_ar": "ديني",
                "label_fr": "Denny"
            },
            {
                "value": 56080,
                "label": "Wakefield",
                "label_ar": "ويكفيلد",
                "label_fr": "Wakefield"
            },
            {
                "value": 58827,
                "label": "Worminghall",
                "label_ar": "وورمينغهول",
                "label_fr": "Worminghall"
            },
            {
                "value": 57854,
                "label": "Llantwit Major",
                "label_ar": "لانتويت الرائد",
                "label_fr": "Llantwit Major"
            },
            {
                "value": 56392,
                "label": "Matlock",
                "label_ar": "ماتلوك",
                "label_fr": "Matlock"
            },
            {
                "value": 56535,
                "label": "Craigavon",
                "label_ar": "كريجافون",
                "label_fr": "Craigavon"
            },
            {
                "value": 56915,
                "label": "West Kirby",
                "label_ar": "غرب كيربي",
                "label_fr": "West Kirby"
            },
            {
                "value": 55888,
                "label": "Stratford-upon-Avon",
                "label_ar": "ستراتفورد أبون آفون",
                "label_fr": "Stratford-upon-Avon"
            },
            {
                "value": 57186,
                "label": "Pulborough village hall",
                "label_ar": "قاعة قرية بولبورو",
                "label_fr": "Salle des fêtes de Pulborough"
            },
            {
                "value": 58059,
                "label": "North Tawton",
                "label_ar": "شمال تاوتن",
                "label_fr": "North Tawton"
            },
            {
                "value": 58470,
                "label": "New Mills",
                "label_ar": "نيو ميلز",
                "label_fr": "Nouveaux moulins"
            },
            {
                "value": 56784,
                "label": "Kirkliston",
                "label_ar": "كيركليستون",
                "label_fr": "Kirkliston"
            },
            {
                "value": 58610,
                "label": "Seghill",
                "label_ar": "سيغيل",
                "label_fr": "Seghill"
            },
            {
                "value": 58097,
                "label": "Kirkcudbright",
                "label_ar": "كيركودبرايت",
                "label_fr": "Kirkcudbright"
            },
            {
                "value": 56891,
                "label": "Arnold",
                "label_ar": "أرنولد",
                "label_fr": "Arnold"
            },
            {
                "value": 58574,
                "label": "Chilton",
                "label_ar": "شيلتون",
                "label_fr": "Chilton"
            },
            {
                "value": 57374,
                "label": "Wolstanton",
                "label_ar": "ولستانتون",
                "label_fr": "Wolstanton"
            },
            {
                "value": 58741,
                "label": "Cardonald",
                "label_ar": "كاردونالد",
                "label_fr": "Cardonald"
            },
            {
                "value": 57790,
                "label": "Millbrook",
                "label_ar": "ميلبروك",
                "label_fr": "Millbrook"
            },
            {
                "value": 57101,
                "label": "Aston",
                "label_ar": "أستون",
                "label_fr": "Aston"
            },
            {
                "value": 58126,
                "label": "Tandragee",
                "label_ar": "تاندراجي",
                "label_fr": "Tandragee"
            },
            {
                "value": 56043,
                "label": "Crowborough",
                "label_ar": "كروبورو",
                "label_fr": "Crowborough"
            },
            {
                "value": 57342,
                "label": "Ambleside",
                "label_ar": "آمبيلسايد",
                "label_fr": "Ambleside"
            },
            {
                "value": 58896,
                "label": "Westleigh",
                "label_ar": "ويستلي",
                "label_fr": "Westleigh"
            },
            {
                "value": 58070,
                "label": "Landrake",
                "label_ar": "لاندراك",
                "label_fr": "Landrake"
            },
            {
                "value": 58715,
                "label": "Stoke by Nayland",
                "label_ar": "ستوك باي نايلاند",
                "label_fr": "Stoke par Nayland"
            },
            {
                "value": 56204,
                "label": "Sandy",
                "label_ar": "ساندي",
                "label_fr": "Sablonneux"
            },
            {
                "value": 56719,
                "label": "Huyton",
                "label_ar": "هويتون",
                "label_fr": "Huyton"
            },
            {
                "value": 57327,
                "label": "Kirriemuir",
                "label_ar": "كيريموير",
                "label_fr": "Kirriemuir"
            },
            {
                "value": 57848,
                "label": "Kingston Seymour",
                "label_ar": "كينغستون سيمور",
                "label_fr": "Kingston Seymour"
            },
            {
                "value": 58149,
                "label": "East Barnet",
                "label_ar": "الشرق بارنت",
                "label_fr": "Barnet Est"
            },
            {
                "value": 56823,
                "label": "Frodsham",
                "label_ar": "فرودشام",
                "label_fr": "Frodsham"
            },
            {
                "value": 58407,
                "label": "Litherland",
                "label_ar": "ليثرلاند",
                "label_fr": "Litherland"
            },
            {
                "value": 56862,
                "label": "Caersws",
                "label_ar": "كيرسو",
                "label_fr": "Caersws"
            },
            {
                "value": 57644,
                "label": "Newport-On-Tay",
                "label_ar": "نيوبورت أون تاي",
                "label_fr": "Newport-On-Tay"
            },
            {
                "value": 58360,
                "label": "Pathhead",
                "label_ar": "باثهيد",
                "label_fr": "Pathhead"
            },
            {
                "value": 57018,
                "label": "Romsey",
                "label_ar": "رومسي",
                "label_fr": "Romsey"
            },
            {
                "value": 58305,
                "label": "Rainford",
                "label_ar": "رينفورد",
                "label_fr": "Rainford"
            },
            {
                "value": 57754,
                "label": "East Harling",
                "label_ar": "شرق هارلينج",
                "label_fr": "East Harling"
            },
            {
                "value": 57533,
                "label": "Pevensey",
                "label_ar": "بيفينسي",
                "label_fr": "Pevensey"
            },
            {
                "value": 58387,
                "label": "Halwell",
                "label_ar": "هالويل",
                "label_fr": "Halwell"
            },
            {
                "value": 58761,
                "label": "Wilnecote",
                "label_ar": "ويلنكوت",
                "label_fr": "Wilnecote"
            },
            {
                "value": 56948,
                "label": "Goring",
                "label_ar": "غورينغ",
                "label_fr": "Goring"
            },
            {
                "value": 55901,
                "label": "Weymouth",
                "label_ar": "ويموث",
                "label_fr": "Weymouth"
            },
            {
                "value": 56817,
                "label": "Eastwood",
                "label_ar": "ايستوود",
                "label_fr": "Eastwood"
            },
            {
                "value": 57053,
                "label": "Sunningdale",
                "label_ar": "سونينجديل",
                "label_fr": "Sunningdale"
            },
            {
                "value": 56065,
                "label": "Edgware",
                "label_ar": "إدجوير",
                "label_fr": "Edgware"
            },
            {
                "value": 57105,
                "label": "Sawbridgeworth",
                "label_ar": "سوبريدجورث",
                "label_fr": "Sawbridgeworth"
            },
            {
                "value": 58479,
                "label": "Shaw",
                "label_ar": "شو",
                "label_fr": "Shaw"
            },
            {
                "value": 56287,
                "label": "Newent",
                "label_ar": "نيوينت",
                "label_fr": "Newent"
            },
            {
                "value": 57070,
                "label": "Chinley",
                "label_ar": "تشينلي",
                "label_fr": "Chinley"
            },
            {
                "value": 58244,
                "label": "Roehampton",
                "label_ar": "روهامبتون",
                "label_fr": "Roehampton"
            },
            {
                "value": 57421,
                "label": "Beaulieu",
                "label_ar": "بوليو",
                "label_fr": "Beaulieu"
            },
            {
                "value": 57427,
                "label": "Purleigh",
                "label_ar": "بورلي",
                "label_fr": "Purleigh"
            },
            {
                "value": 58512,
                "label": "Ponders End",
                "label_ar": "نهاية بوندرز",
                "label_fr": "Se demande fin"
            },
            {
                "value": 58125,
                "label": "East Budleigh",
                "label_ar": "الشرق بودلي",
                "label_fr": "East Budleigh"
            },
            {
                "value": 57181,
                "label": "Chiswick",
                "label_ar": "تشيسويك",
                "label_fr": "Chiswick"
            },
            {
                "value": 56938,
                "label": "Tregarth",
                "label_ar": "تريغارث",
                "label_fr": "Tregarth"
            },
            {
                "value": 57771,
                "label": "Uphall",
                "label_ar": "Uphall",
                "label_fr": "Uphall"
            },
            {
                "value": 56040,
                "label": "Shoreham-by-Sea",
                "label_ar": "شورهام باي سي",
                "label_fr": "Shoreham-by-Sea"
            },
            {
                "value": 58279,
                "label": "Burwash",
                "label_ar": "برواش",
                "label_fr": "Burwash"
            },
            {
                "value": 57556,
                "label": "Innerleithen",
                "label_ar": "إينرليثين",
                "label_fr": "Innerleithen"
            },
            {
                "value": 56737,
                "label": "Rathfriland",
                "label_ar": "راثفريلاند",
                "label_fr": "Rathfriland"
            },
            {
                "value": 55999,
                "label": "Uxbridge",
                "label_ar": "أوكسبريدج",
                "label_fr": "Uxbridge"
            },
            {
                "value": 57543,
                "label": "Ashburton",
                "label_ar": "اشبورتون",
                "label_fr": "Ashburton"
            },
            {
                "value": 56027,
                "label": "West Linton",
                "label_ar": "ويست لينتون",
                "label_fr": "West Linton"
            },
            {
                "value": 55921,
                "label": "Hengoed",
                "label_ar": "هينجويد",
                "label_fr": "Hengoed"
            },
            {
                "value": 56564,
                "label": "Halesworth",
                "label_ar": "هالسوورث",
                "label_fr": "Halesworth"
            },
            {
                "value": 57773,
                "label": "Oadby",
                "label_ar": "Oadby",
                "label_fr": "Oadby"
            },
            {
                "value": 58707,
                "label": "Great Billing",
                "label_ar": "فواتير كبيرة",
                "label_fr": "Excellente facturation"
            },
            {
                "value": 56465,
                "label": "Dromore",
                "label_ar": "درومور",
                "label_fr": "Dromore"
            },
            {
                "value": 56037,
                "label": "Eastbourne",
                "label_ar": "ايستبورن",
                "label_fr": "Eastbourne"
            },
            {
                "value": 56015,
                "label": "Whitstable",
                "label_ar": "ايتستابل",
                "label_fr": "Whitstable"
            },
            {
                "value": 58183,
                "label": "Eynsham",
                "label_ar": "اينشام",
                "label_fr": "Eynsham"
            },
            {
                "value": 57987,
                "label": "Tackley",
                "label_ar": "تاكلي",
                "label_fr": "Tackley"
            },
            {
                "value": 56752,
                "label": "Whitburn",
                "label_ar": "ويتبورن",
                "label_fr": "Whitburn"
            },
            {
                "value": 56086,
                "label": "Scarborough",
                "label_ar": "سكاربورو",
                "label_fr": "Scarborough"
            },
            {
                "value": 56595,
                "label": "Lisburn",
                "label_ar": "ليزبيرن",
                "label_fr": "Lisburn"
            },
            {
                "value": 58276,
                "label": "Sully",
                "label_ar": "سولي",
                "label_fr": "Souiller"
            },
            {
                "value": 57039,
                "label": "Ashtead",
                "label_ar": "أشتيد",
                "label_fr": "Ashtead"
            },
            {
                "value": 57497,
                "label": "Leyburn",
                "label_ar": "ليبورن",
                "label_fr": "Leyburn"
            },
            {
                "value": 58202,
                "label": "Marcham",
                "label_ar": "مارشام",
                "label_fr": "Marcham"
            },
            {
                "value": 57616,
                "label": "Gaerwen",
                "label_ar": "Gaerwen",
                "label_fr": "Gaerwen"
            },
            {
                "value": 58223,
                "label": "Roath",
                "label_ar": "روث",
                "label_fr": "Roath"
            },
            {
                "value": 57589,
                "label": "New Barnet",
                "label_ar": "بارنت الجديد",
                "label_fr": "Nouveau Barnet"
            },
            {
                "value": 57349,
                "label": "Darlaston",
                "label_ar": "دارلاستون",
                "label_fr": "Darlaston"
            },
            {
                "value": 56741,
                "label": "Woolwich",
                "label_ar": "وولويتش",
                "label_fr": "Woolwich"
            },
            {
                "value": 57112,
                "label": "Redland",
                "label_ar": "الارض الحمراء",
                "label_fr": "Redland"
            },
            {
                "value": 56600,
                "label": "Mexborough",
                "label_ar": "ميكسبورو",
                "label_fr": "Mexborough"
            },
            {
                "value": 58107,
                "label": "Aylsham",
                "label_ar": "أيلشام",
                "label_fr": "Aylsham"
            },
            {
                "value": 57834,
                "label": "Bugbrooke",
                "label_ar": "بوجبروك",
                "label_fr": "Bugbrooke"
            },
            {
                "value": 58450,
                "label": "Dorridge",
                "label_ar": "دريدج",
                "label_fr": "Dorridge"
            },
            {
                "value": 56257,
                "label": "Chesterfield",
                "label_ar": "الحقل المصدر",
                "label_fr": "Chesterfield"
            },
            {
                "value": 56950,
                "label": "Hampton",
                "label_ar": "هامبتون",
                "label_fr": "Hampton"
            },
            {
                "value": 58733,
                "label": "Dean",
                "label_ar": "عميد",
                "label_fr": "doyen"
            },
            {
                "value": 57324,
                "label": "Postling",
                "label_ar": "Postling",
                "label_fr": "Postling"
            },
            {
                "value": 56971,
                "label": "Crowland",
                "label_ar": "كرولاند",
                "label_fr": "Crowland"
            },
            {
                "value": 58871,
                "label": "Linthwaite",
                "label_ar": "لينثويت",
                "label_fr": "Linthwaite"
            },
            {
                "value": 57334,
                "label": "Catherington",
                "label_ar": "كاثرينجتون",
                "label_fr": "Catherington"
            },
            {
                "value": 58684,
                "label": "Writtle",
                "label_ar": "مكتوب",
                "label_fr": "Writtle"
            },
            {
                "value": 56398,
                "label": "Eastleigh",
                "label_ar": "إيستلي",
                "label_fr": "Eastleigh"
            },
            {
                "value": 58570,
                "label": "Goodwick",
                "label_ar": "جودويك",
                "label_fr": "Goodwick"
            },
            {
                "value": 58736,
                "label": "Holborn",
                "label_ar": "هولبورن",
                "label_fr": "Holborn"
            },
            {
                "value": 56794,
                "label": "Catterick",
                "label_ar": "كاتريك",
                "label_fr": "Catterick"
            },
            {
                "value": 57428,
                "label": "Beaminster",
                "label_ar": "بيمينستر",
                "label_fr": "Beaminster"
            },
            {
                "value": 57172,
                "label": "Corringham",
                "label_ar": "كورينجهام",
                "label_fr": "Corringham"
            },
            {
                "value": 56325,
                "label": "Kenilworth",
                "label_ar": "كينيلورث",
                "label_fr": "Kenilworth"
            },
            {
                "value": 57803,
                "label": "Cheadle Hulme",
                "label_ar": "تشيدل هولم",
                "label_fr": "Cheadle Hulme"
            },
            {
                "value": 56910,
                "label": "Duffield",
                "label_ar": "دافيلد",
                "label_fr": "Duffield"
            },
            {
                "value": 57398,
                "label": "Garstang",
                "label_ar": "جارستانج",
                "label_fr": "Garstang"
            },
            {
                "value": 56524,
                "label": "Todmorden",
                "label_ar": "تودموردن",
                "label_fr": "Todmorden"
            },
            {
                "value": 58765,
                "label": "Eaglesfield",
                "label_ar": "ايجلزفيلد",
                "label_fr": "Eaglesfield"
            },
            {
                "value": 58695,
                "label": "Heythrop",
                "label_ar": "هيثروب",
                "label_fr": "Heythrop"
            },
            {
                "value": 56865,
                "label": "Airdrie",
                "label_ar": "ايردري",
                "label_fr": "Airdrie"
            },
            {
                "value": 57645,
                "label": "Blunham",
                "label_ar": "بلونهام",
                "label_fr": "Blunham"
            },
            {
                "value": 57673,
                "label": "Botley",
                "label_ar": "بوتلي",
                "label_fr": "Botley"
            },
            {
                "value": 57130,
                "label": "Hotham",
                "label_ar": "هوثام",
                "label_fr": "Hotham"
            },
            {
                "value": 57812,
                "label": "Tongham",
                "label_ar": "تونغهام",
                "label_fr": "Tongham"
            },
            {
                "value": 58405,
                "label": "Grimsargh",
                "label_ar": "غريمسارج",
                "label_fr": "Grimsargh"
            },
            {
                "value": 56819,
                "label": "Kenley",
                "label_ar": "كينلي",
                "label_fr": "Kenley"
            },
            {
                "value": 57698,
                "label": "Studley",
                "label_ar": "ستودلي",
                "label_fr": "Studley"
            },
            {
                "value": 58689,
                "label": "Ardingly",
                "label_ar": "أردينغلي",
                "label_fr": "Ardingly"
            },
            {
                "value": 57723,
                "label": "Bowdon",
                "label_ar": "بودون",
                "label_fr": "Bowdon"
            },
            {
                "value": 57590,
                "label": "Stratford",
                "label_ar": "ستراتفورد",
                "label_fr": "Stratford"
            },
            {
                "value": 57971,
                "label": "Horncastle",
                "label_ar": "هورنكاسل",
                "label_fr": "Horncastle"
            },
            {
                "value": 58388,
                "label": "Mallaig",
                "label_ar": "ملايج",
                "label_fr": "Mallaig"
            },
            {
                "value": 57414,
                "label": "Ketley",
                "label_ar": "كيتلي",
                "label_fr": "Ketley"
            },
            {
                "value": 57285,
                "label": "Denby",
                "label_ar": "دينبي",
                "label_fr": "Denby"
            },
            {
                "value": 56945,
                "label": "Dorking",
                "label_ar": "دوركينغ",
                "label_fr": "Dorking"
            },
            {
                "value": 57417,
                "label": "Galston",
                "label_ar": "جالستون",
                "label_fr": "Galston"
            },
            {
                "value": 58127,
                "label": "Stawell",
                "label_ar": "ستاويل",
                "label_fr": "Stawell"
            },
            {
                "value": 58533,
                "label": "Sanquhar",
                "label_ar": "سانكوهار",
                "label_fr": "Sanquhar"
            },
            {
                "value": 56881,
                "label": "Holyhead",
                "label_ar": "هوليهيد",
                "label_fr": "Holyhead"
            },
            {
                "value": 57707,
                "label": "Baildon",
                "label_ar": "بيلدون",
                "label_fr": "Baildon"
            },
            {
                "value": 57872,
                "label": "Wix",
                "label_ar": "Wix",
                "label_fr": "Wix"
            },
            {
                "value": 57425,
                "label": "Kinglassie",
                "label_ar": "Kinglassie",
                "label_fr": "Kinglassie"
            },
            {
                "value": 58155,
                "label": "Wombourne",
                "label_ar": "ومبورن",
                "label_fr": "Wombourne"
            },
            {
                "value": 58572,
                "label": "Radnage",
                "label_ar": "رادناج",
                "label_fr": "Radnage"
            },
            {
                "value": 58822,
                "label": "Great Amwell",
                "label_ar": "امويل العظيم",
                "label_fr": "Grand Amwell"
            },
            {
                "value": 57635,
                "label": "Strathyre",
                "label_ar": "ستراتير",
                "label_fr": "Strathyre"
            },
            {
                "value": 55866,
                "label": "Dudley",
                "label_ar": "دودلي",
                "label_fr": "Dudley"
            },
            {
                "value": 57230,
                "label": "Chapeltown",
                "label_ar": "تشابلتاون",
                "label_fr": "Chapeltown"
            },
            {
                "value": 58726,
                "label": "Woldingham",
                "label_ar": "وولدينجهام",
                "label_fr": "Woldingham"
            },
            {
                "value": 56896,
                "label": "Cottingham",
                "label_ar": "كوتنغهام",
                "label_fr": "Cottingham"
            },
            {
                "value": 55992,
                "label": "Crawley",
                "label_ar": "كرولي",
                "label_fr": "Crawley"
            },
            {
                "value": 58317,
                "label": "Bloomsbury",
                "label_ar": "بلومزبري",
                "label_fr": "Bloomsbury"
            },
            {
                "value": 58705,
                "label": "Repton",
                "label_ar": "ريبتون",
                "label_fr": "Repton"
            },
            {
                "value": 56395,
                "label": "Ashford",
                "label_ar": "أشفورد",
                "label_fr": "Ashford"
            },
            {
                "value": 58813,
                "label": "Cottered",
                "label_ar": "كوتر",
                "label_fr": "Clavette"
            },
            {
                "value": 57387,
                "label": "Maulden",
                "label_ar": "مولدن",
                "label_fr": "Maulden"
            },
            {
                "value": 57870,
                "label": "Longniddry",
                "label_ar": "لونجنيدري",
                "label_fr": "Longniddry"
            },
            {
                "value": 57850,
                "label": "Royal Wootton Bassett",
                "label_ar": "رويال ووتون باسيت",
                "label_fr": "Royal Wootton Bassett"
            },
            {
                "value": 58805,
                "label": "Lytchett Minster",
                "label_ar": "ليتشيت مينستر",
                "label_fr": "Lytchett Minster"
            },
            {
                "value": 56541,
                "label": "Batley",
                "label_ar": "باتلي",
                "label_fr": "Batley"
            },
            {
                "value": 58074,
                "label": "Whimple",
                "label_ar": "نشوة",
                "label_fr": "Whimple"
            },
            {
                "value": 56464,
                "label": "Hemel Hempstead",
                "label_ar": "هيميل هيمبستيد",
                "label_fr": "Hemel Hempstead"
            },
            {
                "value": 57626,
                "label": "Machynlleth",
                "label_ar": "ماتشينليث",
                "label_fr": "Machynlleth"
            },
            {
                "value": 55932,
                "label": "Tonypandy",
                "label_ar": "Tonypandy",
                "label_fr": "Tonypandy"
            },
            {
                "value": 57099,
                "label": "Cookstown",
                "label_ar": "كوكستاون",
                "label_fr": "Cookstown"
            },
            {
                "value": 56890,
                "label": "Melbourne",
                "label_ar": "ملبورن",
                "label_fr": "Melbourne"
            },
            {
                "value": 56716,
                "label": "Ulverston",
                "label_ar": "أولفرستون",
                "label_fr": "Ulverston"
            },
            {
                "value": 58119,
                "label": "Harworth",
                "label_ar": "هارورث",
                "label_fr": "Harworth"
            },
            {
                "value": 56567,
                "label": "Buckie",
                "label_ar": "بوكي",
                "label_fr": "Buckie"
            },
            {
                "value": 56044,
                "label": "Rochester",
                "label_ar": "روتشستر",
                "label_fr": "Rochester"
            },
            {
                "value": 58588,
                "label": "Belcoo",
                "label_ar": "بيلكو",
                "label_fr": "Belcoo"
            },
            {
                "value": 58124,
                "label": "Moneymore",
                "label_ar": "مزيد من المال",
                "label_fr": "Moneymore"
            },
            {
                "value": 57986,
                "label": "Bradwell on Sea",
                "label_ar": "برادويل أون سي",
                "label_fr": "Bradwell on Sea"
            },
            {
                "value": 56652,
                "label": "Wicken",
                "label_ar": "ويكين",
                "label_fr": "Wicken"
            },
            {
                "value": 56349,
                "label": "Southminster",
                "label_ar": "ساوثمينستر",
                "label_fr": "Southminster"
            },
            {
                "value": 58517,
                "label": "Dundonald",
                "label_ar": "دونالدونالد",
                "label_fr": "Dundonald"
            },
            {
                "value": 56276,
                "label": "Exeter",
                "label_ar": "إكستر",
                "label_fr": "Exeter"
            },
            {
                "value": 55982,
                "label": "Crieff",
                "label_ar": "كريف",
                "label_fr": "Crieff"
            },
            {
                "value": 58835,
                "label": "Rushden",
                "label_ar": "راشدين",
                "label_fr": "Rushden"
            },
            {
                "value": 57459,
                "label": "Etchingham",
                "label_ar": "إيتشنغهام",
                "label_fr": "Etchingham"
            },
            {
                "value": 56266,
                "label": "Hayes",
                "label_ar": "هايز",
                "label_fr": "Hayes"
            },
            {
                "value": 57697,
                "label": "Winlaton",
                "label_ar": "وينلاتون",
                "label_fr": "Winlaton"
            },
            {
                "value": 58659,
                "label": "Speke",
                "label_ar": "سبيك",
                "label_fr": "Speke"
            },
            {
                "value": 58551,
                "label": "Ridingmill",
                "label_ar": "طاحونة",
                "label_fr": "Équitation"
            },
            {
                "value": 58122,
                "label": "Oxshott",
                "label_ar": "Oxshott",
                "label_fr": "Oxshott"
            },
            {
                "value": 58211,
                "label": "Addington",
                "label_ar": "ادينغتون",
                "label_fr": "Addington"
            },
            {
                "value": 57770,
                "label": "Billesley",
                "label_ar": "بيليسلي",
                "label_fr": "Billesley"
            },
            {
                "value": 56990,
                "label": "Auchinleck",
                "label_ar": "اوتشينليك",
                "label_fr": "Auchinleck"
            },
            {
                "value": 57651,
                "label": "Aycliffe",
                "label_ar": "أيكليف",
                "label_fr": "Aycliffe"
            },
            {
                "value": 56390,
                "label": "Bakewell",
                "label_ar": "باكويل",
                "label_fr": "Bakewell"
            },
            {
                "value": 56221,
                "label": "Stowmarket",
                "label_ar": "ستوماركت",
                "label_fr": "Stowmarket"
            },
            {
                "value": 57461,
                "label": "Anstey",
                "label_ar": "انستي",
                "label_fr": "Anstey"
            },
            {
                "value": 58394,
                "label": "Millbrook",
                "label_ar": "ميلبروك",
                "label_fr": "Millbrook"
            },
            {
                "value": 58577,
                "label": "Gaydon",
                "label_ar": "جايدون",
                "label_fr": "Gaydon"
            },
            {
                "value": 58144,
                "label": "Corpach",
                "label_ar": "كورباتش",
                "label_fr": "Corpach"
            },
            {
                "value": 57687,
                "label": "Hebburn",
                "label_ar": "هيبورن",
                "label_fr": "Hebburn"
            },
            {
                "value": 58679,
                "label": "Failsworth",
                "label_ar": "فيليسورث",
                "label_fr": "Failsworth"
            },
            {
                "value": 56762,
                "label": "Tarleton",
                "label_ar": "تارلتون",
                "label_fr": "Tarleton"
            },
            {
                "value": 58776,
                "label": "Stanground",
                "label_ar": "ستانغراوند",
                "label_fr": "Stanground"
            },
            {
                "value": 58759,
                "label": "Surrey",
                "label_ar": "ساري",
                "label_fr": "Surrey"
            },
            {
                "value": 57662,
                "label": "Stock",
                "label_ar": "مخزون",
                "label_fr": "Stock"
            },
            {
                "value": 58303,
                "label": "Girton",
                "label_ar": "جيرتون",
                "label_fr": "Girton"
            },
            {
                "value": 57764,
                "label": "Torpoint",
                "label_ar": "توربوينت",
                "label_fr": "Torpoint"
            },
            {
                "value": 56006,
                "label": "Horsham",
                "label_ar": "هورشام",
                "label_fr": "Horsham"
            },
            {
                "value": 56034,
                "label": "London",
                "label_ar": "لندن",
                "label_fr": "Londres"
            },
            {
                "value": 56430,
                "label": "Hoddesdon",
                "label_ar": "هوديسدون",
                "label_fr": "Hoddesdon"
            },
            {
                "value": 56322,
                "label": "Rhyl",
                "label_ar": "رايل",
                "label_fr": "Rhyl"
            },
            {
                "value": 56986,
                "label": "Axbridge",
                "label_ar": "اكسبريدج",
                "label_fr": "Axbridge"
            },
            {
                "value": 57825,
                "label": "Bealings",
                "label_ar": "بيلاجس",
                "label_fr": "Bealings"
            },
            {
                "value": 58699,
                "label": "Gorseinon",
                "label_ar": "جورسينون",
                "label_fr": "Gorseinon"
            },
            {
                "value": 58050,
                "label": "Biddenham",
                "label_ar": "بيدينهام",
                "label_fr": "Biddenham"
            },
            {
                "value": 57137,
                "label": "South Brewham",
                "label_ar": "ساوث بروهام",
                "label_fr": "South Brewham"
            },
            {
                "value": 57980,
                "label": "Little Barningham",
                "label_ar": "ليتل بارنينجهام",
                "label_fr": "Petit Barningham"
            },
            {
                "value": 57559,
                "label": "Holsworthy",
                "label_ar": "هولسوورثي",
                "label_fr": "Holsworthy"
            },
            {
                "value": 56375,
                "label": "Ilkley",
                "label_ar": "إلكلي",
                "label_fr": "Ilkley"
            },
            {
                "value": 57041,
                "label": "Omagh",
                "label_ar": "أوماغ",
                "label_fr": "Omagh"
            },
            {
                "value": 56426,
                "label": "Bicester",
                "label_ar": "بيسستر",
                "label_fr": "Bicester"
            },
            {
                "value": 55965,
                "label": "Bonnybridge",
                "label_ar": "بونيبريدج",
                "label_fr": "Bonnybridge"
            },
            {
                "value": 58346,
                "label": "Tattershall",
                "label_ar": "تاترسهول",
                "label_fr": "Tattershall"
            },
            {
                "value": 58215,
                "label": "Boroughbridge",
                "label_ar": "بورو بريدج",
                "label_fr": "Boroughbridge"
            },
            {
                "value": 57818,
                "label": "Olney",
                "label_ar": "أولني",
                "label_fr": "Olney"
            },
            {
                "value": 57495,
                "label": "Benenden",
                "label_ar": "بنيندين",
                "label_fr": "Benenden"
            },
            {
                "value": 56736,
                "label": "Cowbridge",
                "label_ar": "كاوبريدج",
                "label_fr": "Cowbridge"
            },
            {
                "value": 56511,
                "label": "Wilmslow",
                "label_ar": "ويلمسلو",
                "label_fr": "Wilmslow"
            },
            {
                "value": 58057,
                "label": "Newcastle Emlyn",
                "label_ar": "نيوكاسل املين",
                "label_fr": "Newcastle Emlyn"
            },
            {
                "value": 57303,
                "label": "Thames Ditton",
                "label_ar": "التايمز ديتون",
                "label_fr": "Thames Ditton"
            },
            {
                "value": 56500,
                "label": "Ludlow",
                "label_ar": "لودلو",
                "label_fr": "Ludlow"
            },
            {
                "value": 56060,
                "label": "Stanmore",
                "label_ar": "ستانمور",
                "label_fr": "Stanmore"
            },
            {
                "value": 57490,
                "label": "Blakeney",
                "label_ar": "بلاكيني",
                "label_fr": "Blakeney"
            },
            {
                "value": 57502,
                "label": "Seascale",
                "label_ar": "مقياس البحر",
                "label_fr": "Seascale"
            },
            {
                "value": 57123,
                "label": "Rawreth",
                "label_ar": "Rawreth",
                "label_fr": "Rawreth"
            },
            {
                "value": 57994,
                "label": "Baslow",
                "label_ar": "باسلو",
                "label_fr": "Baslow"
            },
            {
                "value": 58151,
                "label": "Kelsall",
                "label_ar": "كيلسال",
                "label_fr": "Kelsall"
            },
            {
                "value": 56158,
                "label": "Urmston",
                "label_ar": "أورمستون",
                "label_fr": "Urmston"
            },
            {
                "value": 56213,
                "label": "Attleborough",
                "label_ar": "أتلبورو",
                "label_fr": "Attleborough"
            },
            {
                "value": 57915,
                "label": "Ellington",
                "label_ar": "إلينغتون",
                "label_fr": "Ellington"
            },
            {
                "value": 58674,
                "label": "Stoke",
                "label_ar": "بضاعة",
                "label_fr": "Alimenter"
            },
            {
                "value": 56854,
                "label": "Cupar",
                "label_ar": "كوبار",
                "label_fr": "Cupar"
            },
            {
                "value": 57595,
                "label": "Tiptree",
                "label_ar": "تيبتري",
                "label_fr": "Tiptree"
            },
            {
                "value": 58274,
                "label": "Harlesden",
                "label_ar": "هارليسدن",
                "label_fr": "Harlesden"
            },
            {
                "value": 56566,
                "label": "Wimborne Minster",
                "label_ar": "ويمبورن مينستر",
                "label_fr": "Minster de Wimborne"
            },
            {
                "value": 57474,
                "label": "Northleach",
                "label_ar": "نورثليتش",
                "label_fr": "Northleach"
            },
            {
                "value": 58646,
                "label": "Oakford",
                "label_ar": "أوكفورد",
                "label_fr": "Oakford"
            },
            {
                "value": 58864,
                "label": "Privett",
                "label_ar": "بريفيت",
                "label_fr": "Privett"
            },
            {
                "value": 58884,
                "label": "Mount Hamilton",
                "label_ar": "جبل هاميلتون",
                "label_fr": "Mont Hamilton"
            },
            {
                "value": 56279,
                "label": "Newbury",
                "label_ar": "نيوبري",
                "label_fr": "Newbury"
            },
            {
                "value": 57325,
                "label": "Cranbrook",
                "label_ar": "كرانبروك",
                "label_fr": "Cranbrook"
            },
            {
                "value": 57691,
                "label": "Hersham",
                "label_ar": "هيرشام",
                "label_fr": "Hersham"
            },
            {
                "value": 57195,
                "label": "Castle Donington",
                "label_ar": "قلعة دونينجتون",
                "label_fr": "Château Donington"
            },
            {
                "value": 57975,
                "label": "Grendon Underwood",
                "label_ar": "جريندون أندروود",
                "label_fr": "Grendon Underwood"
            },
            {
                "value": 58795,
                "label": "Burton",
                "label_ar": "بيرتون",
                "label_fr": "Burton"
            },
            {
                "value": 55912,
                "label": "Bradford-on-Avon",
                "label_ar": "برادفورد أون آفون",
                "label_fr": "Bradford-on-Avon"
            },
            {
                "value": 57836,
                "label": "Steeple Claydon",
                "label_ar": "برج الكنيسة كلايدون",
                "label_fr": "Clocher Claydon"
            },
            {
                "value": 58011,
                "label": "Higher Bebington",
                "label_ar": "أعلى Bebington",
                "label_fr": "Bebington supérieur"
            },
            {
                "value": 56438,
                "label": "New Tredegar",
                "label_ar": "جديد Tredegar",
                "label_fr": "Nouveau Tredegar"
            },
            {
                "value": 58454,
                "label": "Kings Norton",
                "label_ar": "كينغز نورتون",
                "label_fr": "Kings Norton"
            },
            {
                "value": 57562,
                "label": "Seend",
                "label_ar": "سيند",
                "label_fr": "Seend"
            },
            {
                "value": 57898,
                "label": "Lochgilphead",
                "label_ar": "Lochgilphead",
                "label_fr": "Lochgilphead"
            },
            {
                "value": 56122,
                "label": "Bexleyheath",
                "label_ar": "بيكسليهيث",
                "label_fr": "Bexleyheath"
            },
            {
                "value": 57534,
                "label": "Eyemouth",
                "label_ar": "ايماوث",
                "label_fr": "Bouche oculaire"
            },
            {
                "value": 58348,
                "label": "Kinmel",
                "label_ar": "كينمل",
                "label_fr": "Kinmel"
            },
            {
                "value": 57061,
                "label": "Stow on the Wold",
                "label_ar": "ستو على وولد",
                "label_fr": "Stow on the Wold"
            },
            {
                "value": 56064,
                "label": "Wembley",
                "label_ar": "ويمبلي",
                "label_fr": "Wembley"
            },
            {
                "value": 57090,
                "label": "Speldhurst",
                "label_ar": "سبيلدهيرست",
                "label_fr": "Speldhurst"
            },
            {
                "value": 58510,
                "label": "Chilcompton",
                "label_ar": "تشيلكومبتون",
                "label_fr": "Chilcompton"
            },
            {
                "value": 56178,
                "label": "Seaham",
                "label_ar": "سيهام",
                "label_fr": "Seaham"
            },
            {
                "value": 57156,
                "label": "Hindhead",
                "label_ar": "هيندهيد",
                "label_fr": "Hindhead"
            },
            {
                "value": 58704,
                "label": "North Leigh",
                "label_ar": "شمال لي",
                "label_fr": "North Leigh"
            },
            {
                "value": 58091,
                "label": "Leconfield",
                "label_ar": "ليكونفيلد",
                "label_fr": "Leconfield"
            },
            {
                "value": 58340,
                "label": "East Leake",
                "label_ar": "الشرق ليك",
                "label_fr": "East Leake"
            },
            {
                "value": 57618,
                "label": "Cynwyd",
                "label_ar": "سينويد",
                "label_fr": "Cynwyd"
            },
            {
                "value": 58825,
                "label": "Swanland",
                "label_ar": "سوانلاند",
                "label_fr": "Swanland"
            },
            {
                "value": 57680,
                "label": "Hucknall Torkard",
                "label_ar": "هوكنال توركارد",
                "label_fr": "Hucknall Torkard"
            },
            {
                "value": 58872,
                "label": "Silsoe",
                "label_ar": "سيلسو",
                "label_fr": "Silsoe"
            }
        ]
    },
    {
        "country": "Belgium",
        "cities": [
            {
                "value": 58916,
                "label": "Brasmenil",
                "label_ar": "براسمنيل",
                "label_fr": "Brasmenil"
            },
            {
                "value": 59220,
                "label": "Stabroek",
                "label_ar": "ستابروك",
                "label_fr": "Stabroek"
            },
            {
                "value": 59469,
                "label": "Anderlues",
                "label_ar": "أندرلوز",
                "label_fr": "Anderlues"
            },
            {
                "value": 59797,
                "label": "Hamme-Mille",
                "label_ar": "هام ميل",
                "label_fr": "Hamme-Mille"
            },
            {
                "value": 59730,
                "label": "Ghlin",
                "label_ar": "غلين",
                "label_fr": "Ghlin"
            },
            {
                "value": 59428,
                "label": "Burst",
                "label_ar": "انفجار",
                "label_fr": "Éclater"
            },
            {
                "value": 59663,
                "label": "Roeulx",
                "label_ar": "روولكس",
                "label_fr": "Roeulx"
            },
            {
                "value": 59330,
                "label": "Lint",
                "label_ar": "لينت",
                "label_fr": "Peluche"
            },
            {
                "value": 60046,
                "label": "Burdinne",
                "label_ar": "بوردن",
                "label_fr": "Burdinne"
            },
            {
                "value": 59567,
                "label": "Wolfsdonk",
                "label_ar": "ولفسدونك",
                "label_fr": "Wolfsdonk"
            },
            {
                "value": 59006,
                "label": "Buggenhout",
                "label_ar": "بوجنهاوت",
                "label_fr": "Buggenhout"
            },
            {
                "value": 59095,
                "label": "Gembloux",
                "label_ar": "جمبلوكس",
                "label_fr": "Gembloux"
            },
            {
                "value": 59956,
                "label": "Boezinge",
                "label_ar": "Boezinge",
                "label_fr": "Boezinge"
            },
            {
                "value": 59404,
                "label": "Faimes",
                "label_ar": "فايمز",
                "label_fr": "Faimes"
            },
            {
                "value": 59780,
                "label": "Plancenoit",
                "label_ar": "بلانكوينيت",
                "label_fr": "Plancenoit"
            },
            {
                "value": 59568,
                "label": "Assent",
                "label_ar": "موافقة",
                "label_fr": "Assentiment"
            },
            {
                "value": 59429,
                "label": "Couvin",
                "label_ar": "كوفين",
                "label_fr": "Couvin"
            },
            {
                "value": 59491,
                "label": "Kaulille",
                "label_ar": "كاول",
                "label_fr": "Kaulille"
            },
            {
                "value": 59721,
                "label": "Lessines",
                "label_ar": "ليسينس",
                "label_fr": "Lessines"
            },
            {
                "value": 59005,
                "label": "Hamme",
                "label_ar": "هام",
                "label_fr": "Hamme"
            },
            {
                "value": 60045,
                "label": "Banneux",
                "label_ar": "بانيوكس",
                "label_fr": "Banneux"
            },
            {
                "value": 60017,
                "label": "Biercee",
                "label_ar": "بيرسي",
                "label_fr": "Biercee"
            },
            {
                "value": 59671,
                "label": "Baisy-Thy",
                "label_ar": "بايسي خاصتك",
                "label_fr": "Baisy-Thy"
            },
            {
                "value": 60043,
                "label": "Oppem",
                "label_ar": "أوبيم",
                "label_fr": "Oppem"
            },
            {
                "value": 59521,
                "label": "Herselt",
                "label_ar": "هيرسلت",
                "label_fr": "Herselt"
            },
            {
                "value": 58982,
                "label": "Merelbeke",
                "label_ar": "ميريلبيك",
                "label_fr": "Merelbeke"
            },
            {
                "value": 59439,
                "label": "Nil Saint-Vincent",
                "label_ar": "نيل سانت فنسنت",
                "label_fr": "Nil Saint-Vincent"
            },
            {
                "value": 59397,
                "label": "Goor",
                "label_ar": "جوور",
                "label_fr": "Goor"
            },
            {
                "value": 59323,
                "label": "Waarloos",
                "label_ar": "وارلوس",
                "label_fr": "Waarloos"
            },
            {
                "value": 59226,
                "label": "Heist-op-den-Berg",
                "label_ar": "Heist-op-den-Berg",
                "label_fr": "Heist-op-den-Berg"
            },
            {
                "value": 59511,
                "label": "Hechtel",
                "label_ar": "هشتل",
                "label_fr": "Hechtel"
            },
            {
                "value": 58992,
                "label": "Grimminge",
                "label_ar": "جريمينج",
                "label_fr": "Grimminge"
            },
            {
                "value": 59894,
                "label": "Parike",
                "label_ar": "باريك",
                "label_fr": "Parike"
            },
            {
                "value": 59965,
                "label": "Ploegsteert",
                "label_ar": "بلويجستيرت",
                "label_fr": "Ploegsteert"
            },
            {
                "value": 59395,
                "label": "Celles",
                "label_ar": "الخلايا",
                "label_fr": "Celles"
            },
            {
                "value": 59022,
                "label": "Belsele",
                "label_ar": "بيلسيل",
                "label_fr": "Belsele"
            },
            {
                "value": 59747,
                "label": "Baelen",
                "label_ar": "بيلين",
                "label_fr": "Baelen"
            },
            {
                "value": 59019,
                "label": "Meldert",
                "label_ar": "ميلدرت",
                "label_fr": "Meldert"
            },
            {
                "value": 58990,
                "label": "Idegem",
                "label_ar": "الفكرة",
                "label_fr": "Idegem"
            },
            {
                "value": 60040,
                "label": "Chevron",
                "label_ar": "شيفرون",
                "label_fr": "Chevron"
            },
            {
                "value": 59830,
                "label": "Housse",
                "label_ar": "هوس",
                "label_fr": "Housse"
            },
            {
                "value": 59418,
                "label": "Schelle",
                "label_ar": "شيل",
                "label_fr": "Schelle"
            },
            {
                "value": 59020,
                "label": "Moerzeke",
                "label_ar": "Moerzeke",
                "label_fr": "Moerzeke"
            },
            {
                "value": 59416,
                "label": "Genval",
                "label_ar": "جينفال",
                "label_fr": "Genval"
            },
            {
                "value": 59084,
                "label": "Merendree",
                "label_ar": "ميريندري",
                "label_fr": "Merendree"
            },
            {
                "value": 59731,
                "label": "Limal",
                "label_ar": "ليمال",
                "label_fr": "Limal"
            },
            {
                "value": 59725,
                "label": "Plainevaux",
                "label_ar": "بلينفو",
                "label_fr": "Plainevaux"
            },
            {
                "value": 59386,
                "label": "Wanfercee-Baulet",
                "label_ar": "Wanfercee-Baulet",
                "label_fr": "Wanfercee-Baulet"
            },
            {
                "value": 59786,
                "label": "Sart-Dames-Avelines",
                "label_ar": "سارت دامس أفلين",
                "label_fr": "Sart-Dames-Avelines"
            },
            {
                "value": 59508,
                "label": "Lummen",
                "label_ar": "لومين",
                "label_fr": "Lummen"
            },
            {
                "value": 59637,
                "label": "Casteau",
                "label_ar": "كاستو",
                "label_fr": "Casteau"
            },
            {
                "value": 59695,
                "label": "Duisburg",
                "label_ar": "دويسبورغ",
                "label_fr": "Duisbourg"
            },
            {
                "value": 59837,
                "label": "Geer",
                "label_ar": "جير",
                "label_fr": "Geer"
            },
            {
                "value": 59706,
                "label": "Tilff",
                "label_ar": "تيلف",
                "label_fr": "Tilff"
            },
            {
                "value": 59690,
                "label": "Gelrode",
                "label_ar": "جيلرود",
                "label_fr": "Gelrode"
            },
            {
                "value": 60000,
                "label": "Grand-Reng",
                "label_ar": "جراند رينج",
                "label_fr": "Grand-Reng"
            },
            {
                "value": 59878,
                "label": "Rance",
                "label_ar": "رانس",
                "label_fr": "Rance"
            },
            {
                "value": 59085,
                "label": "Berchem",
                "label_ar": "بيركيم",
                "label_fr": "Berchem"
            },
            {
                "value": 58943,
                "label": "Grez-Doiceau",
                "label_ar": "جريز دويسو",
                "label_fr": "Grez-Doiceau"
            },
            {
                "value": 59028,
                "label": "Zelzate",
                "label_ar": "زلزاتي",
                "label_fr": "Zelzate"
            },
            {
                "value": 59217,
                "label": "Brasschaat",
                "label_ar": "براشات",
                "label_fr": "Brasschaat"
            },
            {
                "value": 59729,
                "label": "Neerharen",
                "label_ar": "نيرهارين",
                "label_fr": "Neerharen"
            },
            {
                "value": 59446,
                "label": "Wierde",
                "label_ar": "ويردي",
                "label_fr": "Wierde"
            },
            {
                "value": 59692,
                "label": "Eke",
                "label_ar": "إيكي",
                "label_fr": "Eke"
            },
            {
                "value": 59518,
                "label": "Langdorp",
                "label_ar": "لانغدورب",
                "label_fr": "Langdorp"
            },
            {
                "value": 59977,
                "label": "Ichtegem",
                "label_ar": "Ichtegem",
                "label_fr": "Ichtegem"
            },
            {
                "value": 59959,
                "label": "Alveringem",
                "label_ar": "ألفرينجيم",
                "label_fr": "Alveringem"
            },
            {
                "value": 59315,
                "label": "Galmaarden",
                "label_ar": "جالماردين",
                "label_fr": "Galmaarden"
            },
            {
                "value": 59781,
                "label": "Charneux",
                "label_ar": "شارنو",
                "label_fr": "Charneux"
            },
            {
                "value": 59482,
                "label": "Begijnendijk",
                "label_ar": "Begijnendijk",
                "label_fr": "Begijnendijk"
            },
            {
                "value": 59282,
                "label": "Reet",
                "label_ar": "ريت",
                "label_fr": "Reet"
            },
            {
                "value": 59291,
                "label": "Retie",
                "label_ar": "ريتي",
                "label_fr": "Retie"
            },
            {
                "value": 59972,
                "label": "Wielsbeke",
                "label_ar": "ويلسبكي",
                "label_fr": "Wielsbeke"
            },
            {
                "value": 59474,
                "label": "Hamont",
                "label_ar": "هامونت",
                "label_fr": "Hamont"
            },
            {
                "value": 59275,
                "label": "Weelde",
                "label_ar": "ويلدي",
                "label_fr": "Weelde"
            },
            {
                "value": 59147,
                "label": "Grimbergen",
                "label_ar": "جريمبيرجن",
                "label_fr": "Grimbergen"
            },
            {
                "value": 59483,
                "label": "Ham",
                "label_ar": "لحم خنزير",
                "label_fr": "jambon"
            },
            {
                "value": 58960,
                "label": "Duffel",
                "label_ar": "القماش الخشن",
                "label_fr": "Molleton"
            },
            {
                "value": 59961,
                "label": "Wervik",
                "label_ar": "ويرفيك",
                "label_fr": "Wervik"
            },
            {
                "value": 59784,
                "label": "Beernem",
                "label_ar": "بيرنيم",
                "label_fr": "Beernem"
            },
            {
                "value": 59317,
                "label": "Werchter",
                "label_ar": "ويرشتر",
                "label_fr": "Werchter"
            },
            {
                "value": 59197,
                "label": "Deurne",
                "label_ar": "ديورن",
                "label_fr": "Deurne"
            },
            {
                "value": 60003,
                "label": "Chimay",
                "label_ar": "شيماي",
                "label_fr": "Chimay"
            },
            {
                "value": 58920,
                "label": "Pepinster",
                "label_ar": "بيبينستر",
                "label_fr": "Pepinster"
            },
            {
                "value": 59789,
                "label": "Tohogne",
                "label_ar": "Tohogne",
                "label_fr": "Tohogne"
            },
            {
                "value": 59202,
                "label": "Geel",
                "label_ar": "جيل",
                "label_fr": "Geel"
            },
            {
                "value": 59801,
                "label": "Veltem-Beisem",
                "label_ar": "فيلتيم بيسم",
                "label_fr": "Veltem-Beisem"
            },
            {
                "value": 59103,
                "label": "Roux",
                "label_ar": "رو",
                "label_fr": "Roux"
            },
            {
                "value": 59280,
                "label": "Boechout",
                "label_ar": "Boechout",
                "label_fr": "Boechout"
            },
            {
                "value": 59683,
                "label": "Wevelgem",
                "label_ar": "Wevelgem",
                "label_fr": "Wevelgem"
            },
            {
                "value": 59214,
                "label": "Elewijt",
                "label_ar": "الويجت",
                "label_fr": "Elewijt"
            },
            {
                "value": 59333,
                "label": "Koningshooikt",
                "label_ar": "Koningshooikt",
                "label_fr": "Koningshooikt"
            },
            {
                "value": 59307,
                "label": "Tienen",
                "label_ar": "تينين",
                "label_fr": "Tirlemont"
            },
            {
                "value": 59796,
                "label": "Oostende",
                "label_ar": "أوستند",
                "label_fr": "Ostende"
            },
            {
                "value": 59810,
                "label": "Sint-Agatha-Rode",
                "label_ar": "سينت أجاثا رود",
                "label_fr": "Sint-Agatha-Rode"
            },
            {
                "value": 59242,
                "label": "Paal",
                "label_ar": "بال",
                "label_fr": "Paal"
            },
            {
                "value": 59608,
                "label": "Bassenge",
                "label_ar": "باسنجي",
                "label_fr": "Bassenge"
            },
            {
                "value": 59319,
                "label": "Olen",
                "label_ar": "أولين",
                "label_fr": "Olen"
            },
            {
                "value": 58958,
                "label": "Kraainem",
                "label_ar": "كراينم",
                "label_fr": "Kraainem"
            },
            {
                "value": 59000,
                "label": "Melsele",
                "label_ar": "ميلسيل",
                "label_fr": "Melsele"
            },
            {
                "value": 59208,
                "label": "Aartselaar",
                "label_ar": "أرتسيلار",
                "label_fr": "Aartselaar"
            },
            {
                "value": 59234,
                "label": "Meerle",
                "label_ar": "ميرل",
                "label_fr": "Meerle"
            },
            {
                "value": 59026,
                "label": "Wondelgem",
                "label_ar": "Wondelgem",
                "label_fr": "Wondelgem"
            },
            {
                "value": 59907,
                "label": "Astene",
                "label_ar": "أستين",
                "label_fr": "Astène"
            },
            {
                "value": 58942,
                "label": "Herstal",
                "label_ar": "هيرستال",
                "label_fr": "Herstal"
            },
            {
                "value": 59873,
                "label": "Mariekerke",
                "label_ar": "ماريكيركي",
                "label_fr": "Mariekerke"
            },
            {
                "value": 59644,
                "label": "Membach",
                "label_ar": "ممباتش",
                "label_fr": "Membach"
            },
            {
                "value": 59805,
                "label": "Neerijse",
                "label_ar": "نيريجس",
                "label_fr": "Neerijse"
            },
            {
                "value": 59042,
                "label": "Heusden",
                "label_ar": "هيوسدن",
                "label_fr": "Heusden"
            },
            {
                "value": 59235,
                "label": "Vlezenbeek",
                "label_ar": "فلزينبيك",
                "label_fr": "Vlezenbeek"
            },
            {
                "value": 59057,
                "label": "Denderhoutem",
                "label_ar": "Denderhoutem",
                "label_fr": "Denderhoutem"
            },
            {
                "value": 59115,
                "label": "Godarville",
                "label_ar": "جودارفيل",
                "label_fr": "Godarville"
            },
            {
                "value": 59366,
                "label": "Molenbeek-Saint-Jean",
                "label_ar": "مولينبيك سان جان",
                "label_fr": "Molenbeek-Saint-Jean"
            },
            {
                "value": 60018,
                "label": "Seneffe",
                "label_ar": "Seneffe",
                "label_fr": "Seneffe"
            },
            {
                "value": 59759,
                "label": "Bouillon",
                "label_ar": "بوالون",
                "label_fr": "Bouillon"
            },
            {
                "value": 58919,
                "label": "Seraing",
                "label_ar": "سيراينج",
                "label_fr": "Seraing"
            },
            {
                "value": 59301,
                "label": "Ternat",
                "label_ar": "تيرنات",
                "label_fr": "Ternat"
            },
            {
                "value": 59116,
                "label": "Forchies-la-Marche",
                "label_ar": "فورشيز لا مارش",
                "label_fr": "Forchies-la-Marche"
            },
            {
                "value": 59158,
                "label": "Loupoigne",
                "label_ar": "لوبوين",
                "label_fr": "Loupoigne"
            },
            {
                "value": 59892,
                "label": "Oedelem",
                "label_ar": "Oedelem",
                "label_fr": "Oedelem"
            },
            {
                "value": 58929,
                "label": "Lixhe",
                "label_ar": "ليكش",
                "label_fr": "Lixhe"
            },
            {
                "value": 59727,
                "label": "Gellik",
                "label_ar": "جيليك",
                "label_fr": "Gellik"
            },
            {
                "value": 59132,
                "label": "Lede",
                "label_ar": "ليدي",
                "label_fr": "Lede"
            },
            {
                "value": 59271,
                "label": "Westmalle",
                "label_ar": "ويستمال",
                "label_fr": "Westmalle"
            },
            {
                "value": 59604,
                "label": "Boncelles",
                "label_ar": "بونسيلس",
                "label_fr": "Boncelles"
            },
            {
                "value": 59866,
                "label": "Hensies",
                "label_ar": "هينسيس",
                "label_fr": "Hensies"
            },
            {
                "value": 59396,
                "label": "Vremde",
                "label_ar": "فريمدي",
                "label_fr": "Vremde"
            },
            {
                "value": 58936,
                "label": "Modave",
                "label_ar": "موداف",
                "label_fr": "Modave"
            },
            {
                "value": 59993,
                "label": "Outrijve",
                "label_ar": "Outrijve",
                "label_fr": "Outrijve"
            },
            {
                "value": 59844,
                "label": "Ecaussinnes-d'Enghien",
                "label_ar": "إيكوسينيس دي إنجين",
                "label_fr": "Ecaussinnes-d'Enghien"
            },
            {
                "value": 59666,
                "label": "Bilstain",
                "label_ar": "بيلستين",
                "label_fr": "Bilstain"
            },
            {
                "value": 59225,
                "label": "Booischot",
                "label_ar": "بويسكوت",
                "label_fr": "Booischot"
            },
            {
                "value": 59711,
                "label": "Nandrin",
                "label_ar": "ناندرين",
                "label_fr": "Nandrin"
            },
            {
                "value": 59861,
                "label": "Soignies",
                "label_ar": "سوينيس",
                "label_fr": "Soignies"
            },
            {
                "value": 59737,
                "label": "Leuze",
                "label_ar": "ليوز",
                "label_fr": "Leuze"
            },
            {
                "value": 59065,
                "label": "Evergem",
                "label_ar": "ايفرجيم",
                "label_fr": "Evergem"
            },
            {
                "value": 59863,
                "label": "Haren",
                "label_ar": "هارين",
                "label_fr": "Haren"
            },
            {
                "value": 59308,
                "label": "Hoegaarden",
                "label_ar": "هويجاردين",
                "label_fr": "Hoegaarden"
            },
            {
                "value": 59390,
                "label": "Niel",
                "label_ar": "نيل",
                "label_fr": "Niel"
            },
            {
                "value": 59259,
                "label": "Deerlijk",
                "label_ar": "ديرليجك",
                "label_fr": "Deerlijk"
            },
            {
                "value": 59336,
                "label": "Tielt",
                "label_ar": "تيلت",
                "label_fr": "Tielt"
            },
            {
                "value": 58973,
                "label": "Sint-Niklaas",
                "label_ar": "سينت نيكلاس",
                "label_fr": "Sint-Niklaas"
            },
            {
                "value": 59273,
                "label": "Hoboken",
                "label_ar": "هوبوكين",
                "label_fr": "Hoboken"
            },
            {
                "value": 59184,
                "label": "Manhay",
                "label_ar": "مانهاي",
                "label_fr": "Manhay"
            },
            {
                "value": 59774,
                "label": "Heusy",
                "label_ar": "هيوزي",
                "label_fr": "Heusy"
            },
            {
                "value": 59674,
                "label": "Battice",
                "label_ar": "باتيس",
                "label_fr": "Battice"
            },
            {
                "value": 59829,
                "label": "Heure-le-Romain",
                "label_ar": "هيور لو رومان",
                "label_fr": "Heure-le-Romain"
            },
            {
                "value": 59309,
                "label": "Woluwe-Saint-Lambert",
                "label_ar": "ولويه سانت لامبرت",
                "label_fr": "Woluwe-Saint-Lambert"
            },
            {
                "value": 59717,
                "label": "Villers-le-Bouillet",
                "label_ar": "فيلير لو بويليه",
                "label_fr": "Villers-le-Bouillet"
            },
            {
                "value": 59925,
                "label": "Varsenare",
                "label_ar": "فارسيناري",
                "label_fr": "Varsenare"
            },
            {
                "value": 59079,
                "label": "Teralfene",
                "label_ar": "تيرالفين",
                "label_fr": "Teralfène"
            },
            {
                "value": 59812,
                "label": "Elingen",
                "label_ar": "إلينجن",
                "label_fr": "Elingen"
            },
            {
                "value": 59121,
                "label": "Ransart",
                "label_ar": "رانسارت",
                "label_fr": "Ransart"
            },
            {
                "value": 59044,
                "label": "Erembodegem",
                "label_ar": "ارمبوديجيم",
                "label_fr": "Erembodegem"
            },
            {
                "value": 59654,
                "label": "Ampsin",
                "label_ar": "امبسين",
                "label_fr": "Ampsin"
            },
            {
                "value": 60001,
                "label": "Erquelinnes",
                "label_ar": "Erquelinnes",
                "label_fr": "Erquelinnes"
            },
            {
                "value": 59409,
                "label": "Fleron",
                "label_ar": "فليرون",
                "label_fr": "Fleron"
            },
            {
                "value": 59410,
                "label": "Massemen",
                "label_ar": "ماسيمين",
                "label_fr": "Massemen"
            },
            {
                "value": 59109,
                "label": "Bierges",
                "label_ar": "بيرجز",
                "label_fr": "Bierges"
            },
            {
                "value": 59838,
                "label": "Goe",
                "label_ar": "جو",
                "label_fr": "Goe"
            },
            {
                "value": 59310,
                "label": "Buizingen",
                "label_ar": "Buizingen",
                "label_fr": "Buizingen"
            },
            {
                "value": 59646,
                "label": "Lambermont",
                "label_ar": "لامبرمونت",
                "label_fr": "Lambermont"
            },
            {
                "value": 59346,
                "label": "Assenede",
                "label_ar": "أسيندي",
                "label_fr": "Assenede"
            },
            {
                "value": 59895,
                "label": "Zandbergen",
                "label_ar": "زاندبيرجين",
                "label_fr": "Zandbergen"
            },
            {
                "value": 59073,
                "label": "Hekelgem",
                "label_ar": "هيكلجم",
                "label_fr": "Hekelgem"
            },
            {
                "value": 59885,
                "label": "Houtaing",
                "label_ar": "هوتاينج",
                "label_fr": "Houtaing"
            },
            {
                "value": 59415,
                "label": "Bastogne",
                "label_ar": "باستوني",
                "label_fr": "Bastogne"
            },
            {
                "value": 59465,
                "label": "Trooz",
                "label_ar": "طروز",
                "label_fr": "Trooz"
            },
            {
                "value": 59640,
                "label": "Floreffe",
                "label_ar": "فلوريف",
                "label_fr": "Floreffe"
            },
            {
                "value": 59869,
                "label": "Sombreffe",
                "label_ar": "سومبريف",
                "label_fr": "Sombreffe"
            },
            {
                "value": 59341,
                "label": "Middelkerke",
                "label_ar": "ميدلكيركي",
                "label_fr": "Middelkerke"
            },
            {
                "value": 59195,
                "label": "Vorselaar",
                "label_ar": "فورسيلار",
                "label_fr": "Vorselaar"
            },
            {
                "value": 59467,
                "label": "Peer",
                "label_ar": "الأقران",
                "label_fr": "Pair"
            },
            {
                "value": 59093,
                "label": "Thuin",
                "label_ar": "ثوين",
                "label_fr": "Thuin"
            },
            {
                "value": 59551,
                "label": "Testelt",
                "label_ar": "تيستيلت",
                "label_fr": "Testelt"
            },
            {
                "value": 59475,
                "label": "Achel",
                "label_ar": "أخيل",
                "label_fr": "Achel"
            },
            {
                "value": 59190,
                "label": "Woluwe-Saint-Pierre - Sint-Pieters-Woluwe",
                "label_ar": "Woluwe-Saint-Pierre - Sint-Pieters-Woluwe",
                "label_fr": "Woluwe-Saint-Pierre - Woluwe-Saint-Pierre"
            },
            {
                "value": 59987,
                "label": "Tiegem",
                "label_ar": "تيجيم",
                "label_fr": "Tiegem"
            },
            {
                "value": 59038,
                "label": "Zele",
                "label_ar": "ض، ايلى",
                "label_fr": "Zele"
            },
            {
                "value": 59947,
                "label": "Ypres",
                "label_ar": "ابرس",
                "label_fr": "Ypres"
            },
            {
                "value": 60031,
                "label": "Lotenhulle",
                "label_ar": "Lotenhulle",
                "label_fr": "Lotenhulle"
            },
            {
                "value": 59932,
                "label": "Vlissegem",
                "label_ar": "فليسيجم",
                "label_fr": "Vlissegem"
            },
            {
                "value": 60059,
                "label": "Arbrefontaine",
                "label_ar": "أربريفونتين",
                "label_fr": "Arbrefontaine"
            },
            {
                "value": 59792,
                "label": "Chiny",
                "label_ar": "شيني",
                "label_fr": "Chiny"
            },
            {
                "value": 59296,
                "label": "Wolvertem",
                "label_ar": "ولفرتيم",
                "label_fr": "Wolvertem"
            },
            {
                "value": 59708,
                "label": "Lisogne",
                "label_ar": "ليسوجن",
                "label_fr": "Lisogne"
            },
            {
                "value": 59931,
                "label": "Sijsele",
                "label_ar": "Sijsele",
                "label_fr": "Sijsele"
            },
            {
                "value": 59537,
                "label": "Meeuwen",
                "label_ar": "Meeuwen",
                "label_fr": "Meeuwen"
            },
            {
                "value": 59150,
                "label": "Zellik",
                "label_ar": "زيليك",
                "label_fr": "Zellik"
            },
            {
                "value": 59854,
                "label": "Basecles",
                "label_ar": "Basecles",
                "label_fr": "Basecles"
            },
            {
                "value": 58999,
                "label": "Beveren",
                "label_ar": "بيفرين",
                "label_fr": "Beveren"
            },
            {
                "value": 59131,
                "label": "Ruiselede",
                "label_ar": "Ruiselede",
                "label_fr": "Ruiselede"
            },
            {
                "value": 59010,
                "label": "Essene",
                "label_ar": "إسين",
                "label_fr": "Essénien"
            },
            {
                "value": 59985,
                "label": "Voormezele",
                "label_ar": "فورمزيل",
                "label_fr": "Voormezele"
            },
            {
                "value": 59213,
                "label": "Keerbergen",
                "label_ar": "كيربيرجين",
                "label_fr": "Keerbergen"
            },
            {
                "value": 59297,
                "label": "Lennik",
                "label_ar": "لينيك",
                "label_fr": "Lennik"
            },
            {
                "value": 59089,
                "label": "Perwez",
                "label_ar": "بيرويز",
                "label_fr": "Perwez"
            },
            {
                "value": 59798,
                "label": "Pamel",
                "label_ar": "باميل",
                "label_fr": "Pamel"
            },
            {
                "value": 59613,
                "label": "Comblain-au-Pont",
                "label_ar": "كومبلين أو بونت",
                "label_fr": "Comblain-au-Pont"
            },
            {
                "value": 59007,
                "label": "Bornem",
                "label_ar": "بورنيم",
                "label_fr": "Bornem"
            },
            {
                "value": 59558,
                "label": "Meerlaar",
                "label_ar": "ميرلار",
                "label_fr": "Meerlaar"
            },
            {
                "value": 59105,
                "label": "Le Tilleul",
                "label_ar": "لو تيلول",
                "label_fr": "Le Tilleul"
            },
            {
                "value": 60051,
                "label": "Schaffen",
                "label_ar": "شافين",
                "label_fr": "Schaffen"
            },
            {
                "value": 59588,
                "label": "Berendrecht",
                "label_ar": "بيرندريخت",
                "label_fr": "Berendrecht"
            },
            {
                "value": 59806,
                "label": "Wijgmaal",
                "label_ar": "Wijgmaal",
                "label_fr": "Wijgmaal"
            },
            {
                "value": 59313,
                "label": "Schaarbeek",
                "label_ar": "شاربيك",
                "label_fr": "Schaarbeek"
            },
            {
                "value": 58989,
                "label": "Viane",
                "label_ar": "فيان",
                "label_fr": "Viane"
            },
            {
                "value": 58945,
                "label": "Turnhout",
                "label_ar": "إقبال",
                "label_fr": "Turnhout"
            },
            {
                "value": 59790,
                "label": "Bierbeek",
                "label_ar": "بيربيك",
                "label_fr": "Bierbeek"
            },
            {
                "value": 59517,
                "label": "Kersbeek-Miskom",
                "label_ar": "Kersbeek-Miskom",
                "label_fr": "Kersbeek-Miskom"
            },
            {
                "value": 60058,
                "label": "Quenast",
                "label_ar": "كويناست",
                "label_fr": "Quenast"
            },
            {
                "value": 58908,
                "label": "Wetteren",
                "label_ar": "ويترين",
                "label_fr": "Wetteren"
            },
            {
                "value": 59815,
                "label": "Oostmalle",
                "label_ar": "Oostmalle",
                "label_fr": "Oostmalle"
            },
            {
                "value": 59340,
                "label": "Veldegem",
                "label_ar": "فيلدجيم",
                "label_fr": "Veldegem"
            },
            {
                "value": 58917,
                "label": "Bruges",
                "label_ar": "بروج",
                "label_fr": "Bruges"
            },
            {
                "value": 59423,
                "label": "Heusden",
                "label_ar": "هيوسدن",
                "label_fr": "Heusden"
            },
            {
                "value": 59435,
                "label": "Lambermont",
                "label_ar": "لامبرمونت",
                "label_fr": "Lambermont"
            },
            {
                "value": 58978,
                "label": "Wachtebeke",
                "label_ar": "Wachtebeke",
                "label_fr": "Wachtebeke"
            },
            {
                "value": 58915,
                "label": "Sint-Michiels",
                "label_ar": "سينت ميشيلز",
                "label_fr": "Sint-Michiels"
            },
            {
                "value": 59884,
                "label": "Bertrix",
                "label_ar": "بيرتريكس",
                "label_fr": "Bertrix"
            },
            {
                "value": 59176,
                "label": "Sprimont",
                "label_ar": "سبريمونت",
                "label_fr": "Sprimont"
            },
            {
                "value": 59864,
                "label": "Saint-Sauveur",
                "label_ar": "سان سوفور",
                "label_fr": "Saint-Sauveur"
            },
            {
                "value": 59183,
                "label": "Loncin",
                "label_ar": "لونسين",
                "label_fr": "Loncin"
            },
            {
                "value": 58904,
                "label": "Wilrijk",
                "label_ar": "ويلريجك",
                "label_fr": "Wilrijk"
            },
            {
                "value": 59809,
                "label": "Korbeek-Dijle",
                "label_ar": "كوربيك ديجل",
                "label_fr": "Korbeek-Dijle"
            },
            {
                "value": 59813,
                "label": "Brussegem",
                "label_ar": "بروسيجم",
                "label_fr": "Brussegem"
            },
            {
                "value": 59899,
                "label": "Schorisse",
                "label_ar": "شوريس",
                "label_fr": "Schorisse"
            },
            {
                "value": 59550,
                "label": "Linden",
                "label_ar": "الزيزفون",
                "label_fr": "Tilleul"
            },
            {
                "value": 59247,
                "label": "Maasmechelen",
                "label_ar": "ماسمشيلين",
                "label_fr": "Maasmechelen"
            },
            {
                "value": 59355,
                "label": "Dadizele",
                "label_ar": "داديزل",
                "label_fr": "Dadizele"
            },
            {
                "value": 59194,
                "label": "Roeselare",
                "label_ar": "رويسيلاري",
                "label_fr": "Roulers"
            },
            {
                "value": 59973,
                "label": "Aarsele",
                "label_ar": "أارسيل",
                "label_fr": "Aarsele"
            },
            {
                "value": 59822,
                "label": "'s-Gravenwezel",
                "label_ar": "'s-Gravenwezel",
                "label_fr": "'s-Gravenwezel"
            },
            {
                "value": 59096,
                "label": "Wavre",
                "label_ar": "وافر",
                "label_fr": "Wavre"
            },
            {
                "value": 59058,
                "label": "Moorsel",
                "label_ar": "مورسيل",
                "label_fr": "Moorsel"
            },
            {
                "value": 59579,
                "label": "Oelegem",
                "label_ar": "اوليجيم",
                "label_fr": "Oelegem"
            },
            {
                "value": 59350,
                "label": "Kaprijke",
                "label_ar": "كابريجكي",
                "label_fr": "Kaprijke"
            },
            {
                "value": 59240,
                "label": "Gavere",
                "label_ar": "جافير",
                "label_fr": "Gavere"
            },
            {
                "value": 59519,
                "label": "Ramsel",
                "label_ar": "رامسل",
                "label_fr": "Ramsel"
            },
            {
                "value": 59845,
                "label": "Belgrade",
                "label_ar": "بلغراد",
                "label_fr": "Belgrade"
            },
            {
                "value": 59470,
                "label": "Tertre",
                "label_ar": "تيرتر",
                "label_fr": "Tertre"
            },
            {
                "value": 59062,
                "label": "Sint-Pauwels",
                "label_ar": "سينت باولس",
                "label_fr": "Sint-Pauwels"
            },
            {
                "value": 59565,
                "label": "Kermt",
                "label_ar": "كيرمت",
                "label_fr": "Kermt"
            },
            {
                "value": 59541,
                "label": "Haasrode",
                "label_ar": "هاسرود",
                "label_fr": "Haasrode"
            },
            {
                "value": 59609,
                "label": "Melen",
                "label_ar": "ميلين",
                "label_fr": "Melen"
            },
            {
                "value": 60002,
                "label": "Stree",
                "label_ar": "ستري",
                "label_fr": "Stree"
            },
            {
                "value": 59713,
                "label": "Jemappes",
                "label_ar": "Jemappes",
                "label_fr": "Jemappes"
            },
            {
                "value": 59843,
                "label": "Morialme",
                "label_ar": "Morialme",
                "label_fr": "Morialme"
            },
            {
                "value": 59098,
                "label": "Marcinelle",
                "label_ar": "مارسينيل",
                "label_fr": "Marcinelle"
            },
            {
                "value": 59746,
                "label": "Fallais",
                "label_ar": "فالي",
                "label_fr": "Fallais"
            },
            {
                "value": 59071,
                "label": "Schoonaarde",
                "label_ar": "شونارد",
                "label_fr": "Schoonaarde"
            },
            {
                "value": 59378,
                "label": "Tournai",
                "label_ar": "تورناي",
                "label_fr": "Tournai"
            },
            {
                "value": 59029,
                "label": "Stekene",
                "label_ar": "ستكين",
                "label_fr": "Stekene"
            },
            {
                "value": 59922,
                "label": "Couthuin",
                "label_ar": "كوثوين",
                "label_fr": "Couthuin"
            },
            {
                "value": 59719,
                "label": "Saint-Gilles",
                "label_ar": "سان جيل",
                "label_fr": "Saint-Gilles"
            },
            {
                "value": 58964,
                "label": "Franiere",
                "label_ar": "فرانيير",
                "label_fr": "Franière"
            },
            {
                "value": 59506,
                "label": "Leopoldsburg",
                "label_ar": "ليوبولدسبورغ",
                "label_fr": "Léopoldsburg"
            },
            {
                "value": 59060,
                "label": "Drongen",
                "label_ar": "Drongen",
                "label_fr": "Drongen"
            },
            {
                "value": 59179,
                "label": "Vielsalm",
                "label_ar": "فيلسالم",
                "label_fr": "Vielsalm"
            },
            {
                "value": 59911,
                "label": "Oeselgem",
                "label_ar": "أوسيلجم",
                "label_fr": "Oeselgem"
            },
            {
                "value": 59826,
                "label": "Waleffe Saint-Georges",
                "label_ar": "واليف سان جورج",
                "label_fr": "Waleffe Saint-Georges"
            },
            {
                "value": 59245,
                "label": "Zonhoven",
                "label_ar": "زونهوفن",
                "label_fr": "Zonhoven"
            },
            {
                "value": 59251,
                "label": "Bunsbeek",
                "label_ar": "بنسبيك",
                "label_fr": "Bunsbeek"
            },
            {
                "value": 59649,
                "label": "Hotton",
                "label_ar": "هوتون",
                "label_fr": "Hotton"
            },
            {
                "value": 59658,
                "label": "Remersdaal",
                "label_ar": "ريمرسداال",
                "label_fr": "Remersdaal"
            },
            {
                "value": 59472,
                "label": "Morlanwelz-Mariemont",
                "label_ar": "مورلانويلز ماريمون",
                "label_fr": "Morlanwelz-Mariemont"
            },
            {
                "value": 59575,
                "label": "Minderhout",
                "label_ar": "ميندرهوت",
                "label_fr": "Minderhout"
            },
            {
                "value": 59669,
                "label": "Ecaussinnes-Lalaing",
                "label_ar": "إيكاوسينيس لالينج",
                "label_fr": "Ecaussinnes-Lalaing"
            },
            {
                "value": 59623,
                "label": "Velaine-sur-Sambre",
                "label_ar": "فيلين سور سامبر",
                "label_fr": "Velaine-sur-Sambre"
            },
            {
                "value": 59139,
                "label": "Sint-Agatha-Berchem",
                "label_ar": "سينت أجاثا بيرشم",
                "label_fr": "Sint-Agatha-Berchem"
            },
            {
                "value": 59516,
                "label": "Budingen",
                "label_ar": "بودنغن",
                "label_fr": "Budingen"
            },
            {
                "value": 59930,
                "label": "Stene",
                "label_ar": "ستيني",
                "label_fr": "Stene"
            },
            {
                "value": 59493,
                "label": "Tielt",
                "label_ar": "تيلت",
                "label_fr": "Tielt"
            },
            {
                "value": 59034,
                "label": "Ressegem",
                "label_ar": "ريسيجيم",
                "label_fr": "Ressegem"
            },
            {
                "value": 59211,
                "label": "Sint-Katelijne-Waver",
                "label_ar": "سينت كاتلين ويفر",
                "label_fr": "Sint-Katelijne-Waver"
            },
            {
                "value": 59167,
                "label": "Beez",
                "label_ar": "بيز",
                "label_fr": "Beez"
            },
            {
                "value": 59916,
                "label": "Toernich",
                "label_ar": "تورنش",
                "label_fr": "Toernich"
            },
            {
                "value": 59773,
                "label": "Virton",
                "label_ar": "فيرتون",
                "label_fr": "Virton"
            },
            {
                "value": 59329,
                "label": "Muizen",
                "label_ar": "Muizen",
                "label_fr": "Muizen"
            },
            {
                "value": 59995,
                "label": "Gullegem",
                "label_ar": "جولجم",
                "label_fr": "Gullegem"
            },
            {
                "value": 59670,
                "label": "Braine-le-Comte",
                "label_ar": "براين لو كومت",
                "label_fr": "Braine-le-Comte"
            },
            {
                "value": 59414,
                "label": "Esneux",
                "label_ar": "اسنو",
                "label_fr": "Esneux"
            },
            {
                "value": 59068,
                "label": "Oppuurs",
                "label_ar": "مقابل",
                "label_fr": "Oppuurs"
            },
            {
                "value": 59710,
                "label": "Cerexhe-Heuseux",
                "label_ar": "Cerexhe-Heuseux",
                "label_fr": "Cerexhe-Heuseux"
            },
            {
                "value": 59392,
                "label": "Hofstade",
                "label_ar": "هوفستاد",
                "label_fr": "Hofstade"
            },
            {
                "value": 59486,
                "label": "Elen",
                "label_ar": "إلين",
                "label_fr": "Elen"
            },
            {
                "value": 59325,
                "label": "Rijmenam",
                "label_ar": "ريجمينام",
                "label_fr": "Rijmenam"
            },
            {
                "value": 59681,
                "label": "Bredene",
                "label_ar": "بريدين",
                "label_fr": "Bredene"
            },
            {
                "value": 59198,
                "label": "Berchem",
                "label_ar": "بيركيم",
                "label_fr": "Berchem"
            },
            {
                "value": 59849,
                "label": "Dinant",
                "label_ar": "دينانت",
                "label_fr": "Dinant"
            },
            {
                "value": 59244,
                "label": "Eksel",
                "label_ar": "اكسل",
                "label_fr": "Eksel"
            },
            {
                "value": 59545,
                "label": "Ulbeek",
                "label_ar": "أولبيك",
                "label_fr": "Ulbeek"
            },
            {
                "value": 59888,
                "label": "Havay",
                "label_ar": "هافاي",
                "label_fr": "Havay"
            },
            {
                "value": 59968,
                "label": "Ruddervoorde",
                "label_ar": "رودرفورد",
                "label_fr": "Ruddervoorde"
            },
            {
                "value": 59748,
                "label": "Othee",
                "label_ar": "Othee",
                "label_fr": "Othee"
            },
            {
                "value": 59645,
                "label": "Spa",
                "label_ar": "منتجع صحي",
                "label_fr": "Spa"
            },
            {
                "value": 58988,
                "label": "Gijzegem",
                "label_ar": "جيجزيجم",
                "label_fr": "Gijzegem"
            },
            {
                "value": 59933,
                "label": "Ursel",
                "label_ar": "Ursel",
                "label_fr": "Ursel"
            },
            {
                "value": 59419,
                "label": "Helchteren",
                "label_ar": "هلشتيرين",
                "label_fr": "Helchteren"
            },
            {
                "value": 59120,
                "label": "Fontaine-Valmont",
                "label_ar": "فونتين فالمونت",
                "label_fr": "Fontaine-Valmont"
            },
            {
                "value": 58930,
                "label": "Marche-en-Famenne",
                "label_ar": "ماركي إن فامين",
                "label_fr": "Marche-en-Famenne"
            },
            {
                "value": 59934,
                "label": "Zomergem",
                "label_ar": "زومرجيم",
                "label_fr": "Zomergem"
            },
            {
                "value": 59238,
                "label": "Leuven",
                "label_ar": "لوفين",
                "label_fr": "Louvain"
            },
            {
                "value": 59614,
                "label": "Thulin",
                "label_ar": "ثولين",
                "label_fr": "Thulin"
            },
            {
                "value": 59452,
                "label": "Bellem",
                "label_ar": "بلم",
                "label_fr": "Bellem"
            },
            {
                "value": 59204,
                "label": "Dessel",
                "label_ar": "ديسيل",
                "label_fr": "Dessel"
            },
            {
                "value": 59138,
                "label": "Groot-Bijgaarden",
                "label_ar": "جروت بيجاردن",
                "label_fr": "Groot-Bijgaarden"
            },
            {
                "value": 58911,
                "label": "Mechelen",
                "label_ar": "ميكلين",
                "label_fr": "Malines"
            },
            {
                "value": 59288,
                "label": "Putte",
                "label_ar": "بوت",
                "label_fr": "Putte"
            },
            {
                "value": 59978,
                "label": "Nieuwpoort",
                "label_ar": "نيوبورت",
                "label_fr": "Nieuport"
            },
            {
                "value": 59032,
                "label": "Sint-Gillis-Waas",
                "label_ar": "سينت جيليس فاس",
                "label_fr": "Sint-Gillis-Waas"
            },
            {
                "value": 59118,
                "label": "Mont-sur-Marchienne",
                "label_ar": "مونت سور مارشين",
                "label_fr": "Mont-sur-Marchienne"
            },
            {
                "value": 59689,
                "label": "Emptinne",
                "label_ar": "إمبتين",
                "label_fr": "Emptinne"
            },
            {
                "value": 59882,
                "label": "Knokke-Heist",
                "label_ar": "Knokke-Heist",
                "label_fr": "Knokke-Heist"
            },
            {
                "value": 60041,
                "label": "Saintes",
                "label_ar": "سينتس",
                "label_fr": "Saintes"
            },
            {
                "value": 58954,
                "label": "Eupen",
                "label_ar": "يوبين",
                "label_fr": "Eupen"
            },
            {
                "value": 59140,
                "label": "Uccle",
                "label_ar": "أوكل",
                "label_fr": "Uccle"
            },
            {
                "value": 59015,
                "label": "Baasrode",
                "label_ar": "Baasrode",
                "label_fr": "Baasrode"
            },
            {
                "value": 59269,
                "label": "Beerse",
                "label_ar": "بيرس",
                "label_fr": "Beerse"
            },
            {
                "value": 59440,
                "label": "Binche",
                "label_ar": "بينش",
                "label_fr": "Binche"
            },
            {
                "value": 59453,
                "label": "Ohey",
                "label_ar": "أوه",
                "label_fr": "Ohey"
            },
            {
                "value": 59761,
                "label": "De Panne",
                "label_ar": "دي باني",
                "label_fr": "De Panne"
            },
            {
                "value": 59606,
                "label": "Vottem",
                "label_ar": "فوتيم",
                "label_fr": "Vottem"
            },
            {
                "value": 58991,
                "label": "Geraardsbergen",
                "label_ar": "Geraardsbergen",
                "label_fr": "Geraardsbergen"
            },
            {
                "value": 59037,
                "label": "Berlare",
                "label_ar": "بيرلار",
                "label_fr": "Berlare"
            },
            {
                "value": 59066,
                "label": "Zwijnaarde",
                "label_ar": "زوينارد",
                "label_fr": "Zwijnaarde"
            },
            {
                "value": 59619,
                "label": "Viesville",
                "label_ar": "فيسفيل",
                "label_fr": "Viesville"
            },
            {
                "value": 59971,
                "label": "Sint-Baafs-Vijve",
                "label_ar": "سينت بافس فيجفي",
                "label_fr": "Sint-Baafs-Vijve"
            },
            {
                "value": 59088,
                "label": "Anderlecht",
                "label_ar": "أندرلخت",
                "label_fr": "Anderlecht"
            },
            {
                "value": 60014,
                "label": "Gentinnes",
                "label_ar": "جنتينس",
                "label_fr": "Gentinnes"
            },
            {
                "value": 59504,
                "label": "Munsterbilzen",
                "label_ar": "مونستربيلزين",
                "label_fr": "Munsterbilzen"
            },
            {
                "value": 59704,
                "label": "Montzen",
                "label_ar": "مونتزين",
                "label_fr": "Montzen"
            },
            {
                "value": 60039,
                "label": "La Calamine",
                "label_ar": "لا كالامين",
                "label_fr": "La Calamine"
            },
            {
                "value": 59136,
                "label": "Ohain",
                "label_ar": "أوهاين",
                "label_fr": "Ohain"
            },
            {
                "value": 59924,
                "label": "Anthisnes",
                "label_ar": "أنثيسنس",
                "label_fr": "Anthisnes"
            },
            {
                "value": 59206,
                "label": "Wijnegem",
                "label_ar": "Wijnegem",
                "label_fr": "Wijnegem"
            },
            {
                "value": 59953,
                "label": "Markegem",
                "label_ar": "ماركيجيم",
                "label_fr": "Markegem"
            },
            {
                "value": 59890,
                "label": "Saint-Mard",
                "label_ar": "سان مارد",
                "label_fr": "Saint-Mard"
            },
            {
                "value": 59468,
                "label": "Marais",
                "label_ar": "ماريه",
                "label_fr": "Marais"
            },
            {
                "value": 59944,
                "label": "Bavegem",
                "label_ar": "بافيجيم",
                "label_fr": "Bavegem"
            },
            {
                "value": 59286,
                "label": "Hulshout",
                "label_ar": "هلشوت",
                "label_fr": "Hulshout"
            },
            {
                "value": 59534,
                "label": "Diepenbeek",
                "label_ar": "ديبنبيك",
                "label_fr": "Diepenbeek"
            },
            {
                "value": 59638,
                "label": "Bernissart",
                "label_ar": "برنيسارت",
                "label_fr": "Bernissart"
            },
            {
                "value": 59958,
                "label": "Reninge",
                "label_ar": "رينينج",
                "label_fr": "Reninge"
            },
            {
                "value": 59860,
                "label": "Orp-le-Grand",
                "label_ar": "Orp-le-Grand",
                "label_fr": "Orp-le-Grand"
            },
            {
                "value": 59791,
                "label": "Sainte-Ode",
                "label_ar": "سانت أودي",
                "label_fr": "Sainte-Ode"
            },
            {
                "value": 59441,
                "label": "Pont-a-Celles",
                "label_ar": "Pont-a-Celles",
                "label_fr": "Pont-à-Celles"
            },
            {
                "value": 59607,
                "label": "Juprelle",
                "label_ar": "جوبريل",
                "label_fr": "Juprelle"
            },
            {
                "value": 59357,
                "label": "Maldegem",
                "label_ar": "مالديجم",
                "label_fr": "Maldegem"
            },
            {
                "value": 59014,
                "label": "Sint-Gillis-bij-Dendermonde",
                "label_ar": "سينت جيليس بيج ديندرموند",
                "label_fr": "Sint-Gillis-bij-Dendermonde"
            },
            {
                "value": 59045,
                "label": "Iddergem",
                "label_ar": "إديرجم",
                "label_fr": "Iddergem"
            },
            {
                "value": 59900,
                "label": "Etikhove",
                "label_ar": "Etikhove",
                "label_fr": "Etikhove"
            },
            {
                "value": 59080,
                "label": "Borsbeke",
                "label_ar": "بورسبيك",
                "label_fr": "Borsbeke"
            },
            {
                "value": 59344,
                "label": "Oostrozebeke",
                "label_ar": "Oostrozebeke",
                "label_fr": "Oostrozebeke"
            },
            {
                "value": 59398,
                "label": "Jupille-sur-Meuse",
                "label_ar": "Jupille-sur-Meuse",
                "label_fr": "Jupille-sur-Meuse"
            },
            {
                "value": 60033,
                "label": "Lanklaar",
                "label_ar": "لانكلار",
                "label_fr": "Lanklaar"
            },
            {
                "value": 59990,
                "label": "Ramskapelle",
                "label_ar": "رامسكابيل",
                "label_fr": "Ramskapelle"
            },
            {
                "value": 60023,
                "label": "Leffinge",
                "label_ar": "ليفينجي",
                "label_fr": "Leffinge"
            },
            {
                "value": 59937,
                "label": "Bassevelde",
                "label_ar": "باسفلدي",
                "label_fr": "Bassevelde"
            },
            {
                "value": 59454,
                "label": "Marchin",
                "label_ar": "مارشين",
                "label_fr": "Marchin"
            },
            {
                "value": 59668,
                "label": "Eghezee",
                "label_ar": "Eghezee",
                "label_fr": "Eghezee"
            },
            {
                "value": 59941,
                "label": "Werken",
                "label_ar": "ويركن",
                "label_fr": "Werken"
            },
            {
                "value": 59602,
                "label": "Flemalle-Haute",
                "label_ar": "فليمال-هوت",
                "label_fr": "Flemalle-Haute"
            },
            {
                "value": 59370,
                "label": "Manage",
                "label_ar": "يدير",
                "label_fr": "Gérer"
            },
            {
                "value": 59012,
                "label": "Opwijk",
                "label_ar": "أوبويك",
                "label_fr": "Opwijk"
            },
            {
                "value": 59108,
                "label": "Nalinnes",
                "label_ar": "نالينيس",
                "label_fr": "Nalinnes"
            },
            {
                "value": 60016,
                "label": "Grand-Leez",
                "label_ar": "جراند ليز",
                "label_fr": "Grand-Leez"
            },
            {
                "value": 60012,
                "label": "Veurne",
                "label_ar": "فيورن",
                "label_fr": "Furnes"
            },
            {
                "value": 59430,
                "label": "Sint-Stevens-Woluwe",
                "label_ar": "سينت ستيفنز ولوي",
                "label_fr": "Sint-Stevens-Woluwe"
            },
            {
                "value": 59463,
                "label": "Richelle",
                "label_ar": "ريشيل",
                "label_fr": "Richelle"
            },
            {
                "value": 59795,
                "label": "Dottignies",
                "label_ar": "Dottignies",
                "label_fr": "Dottignies"
            },
            {
                "value": 59547,
                "label": "Holsbeek",
                "label_ar": "هولزبيك",
                "label_fr": "Holsbeek"
            },
            {
                "value": 59631,
                "label": "Wagnelee",
                "label_ar": "واجنلي",
                "label_fr": "Wagnelee"
            },
            {
                "value": 59254,
                "label": "Diksmuide",
                "label_ar": "ديكسميود",
                "label_fr": "Dixmude"
            },
            {
                "value": 60006,
                "label": "Ghislenghien",
                "label_ar": "جيسلينجين",
                "label_fr": "Ghislenghien"
            },
            {
                "value": 59233,
                "label": "Poppel",
                "label_ar": "بوبل",
                "label_fr": "Poppel"
            },
            {
                "value": 59155,
                "label": "Melle",
                "label_ar": "ميلي",
                "label_fr": "Melle"
            },
            {
                "value": 59856,
                "label": "Lincent",
                "label_ar": "لينكسينت",
                "label_fr": "Lincent"
            },
            {
                "value": 59492,
                "label": "Baal",
                "label_ar": "بعل",
                "label_fr": "Baal"
            },
            {
                "value": 59295,
                "label": "Lembeek",
                "label_ar": "لمبيك",
                "label_fr": "Lembeek"
            },
            {
                "value": 59043,
                "label": "Laarne",
                "label_ar": "لارن",
                "label_fr": "Laarne"
            },
            {
                "value": 59106,
                "label": "Cour-sur-Heure",
                "label_ar": "Cour-sur-Heure",
                "label_fr": "Cour-sur-Heure"
            },
            {
                "value": 59165,
                "label": "Soumagne",
                "label_ar": "سومان",
                "label_fr": "Soumagne"
            },
            {
                "value": 58965,
                "label": "Chenee",
                "label_ar": "تشيني",
                "label_fr": "Chenee"
            },
            {
                "value": 59400,
                "label": "Rocourt",
                "label_ar": "روكورت",
                "label_fr": "Rocourt"
            },
            {
                "value": 59509,
                "label": "Heppen",
                "label_ar": "هيبن",
                "label_fr": "Heppen"
            },
            {
                "value": 60019,
                "label": "Thynes",
                "label_ar": "ثاين",
                "label_fr": "Thynes"
            },
            {
                "value": 59061,
                "label": "Kapellen",
                "label_ar": "كابيلين",
                "label_fr": "Kapellen"
            },
            {
                "value": 59945,
                "label": "Scheldewindeke",
                "label_ar": "شيلدوينديك",
                "label_fr": "Scheldewindeke"
            },
            {
                "value": 59702,
                "label": "Walhorn",
                "label_ar": "والورن",
                "label_fr": "Walhorn"
            },
            {
                "value": 59134,
                "label": "Tervuren",
                "label_ar": "ترفورين",
                "label_fr": "Tervuren"
            },
            {
                "value": 59816,
                "label": "Meerhout",
                "label_ar": "ميرهوت",
                "label_fr": "Meerhout"
            },
            {
                "value": 59740,
                "label": "Leernes",
                "label_ar": "ليرنس",
                "label_fr": "Leernes"
            },
            {
                "value": 59074,
                "label": "Wichelen",
                "label_ar": "Wichelen",
                "label_fr": "Wichelen"
            },
            {
                "value": 59388,
                "label": "Mont-Saint-Guibert",
                "label_ar": "مونت سان جيبرت",
                "label_fr": "Mont-Saint-Guibert"
            },
            {
                "value": 59992,
                "label": "Middelburg",
                "label_ar": "ميدلبورغ",
                "label_fr": "Middelburg"
            },
            {
                "value": 59277,
                "label": "Haacht",
                "label_ar": "هاخت",
                "label_fr": "Haacht"
            },
            {
                "value": 58971,
                "label": "Overijse",
                "label_ar": "أوفريجس",
                "label_fr": "Overijse"
            },
            {
                "value": 59221,
                "label": "Veerle",
                "label_ar": "فيرل",
                "label_fr": "Veerle"
            },
            {
                "value": 59820,
                "label": "Blaasveld",
                "label_ar": "بلاسفيلد",
                "label_fr": "Blaasveld"
            },
            {
                "value": 59825,
                "label": "Hermalle-sous-Argenteau",
                "label_ar": "Hermalle-sous-Argenteau",
                "label_fr": "Hermalle-sous-Argenteau"
            },
            {
                "value": 59047,
                "label": "Welle",
                "label_ar": "فيله",
                "label_fr": "Welle"
            },
            {
                "value": 59544,
                "label": "Velm",
                "label_ar": "فيلم",
                "label_fr": "Velm"
            },
            {
                "value": 59684,
                "label": "Marke",
                "label_ar": "مارك",
                "label_fr": "Marke"
            },
            {
                "value": 59975,
                "label": "Passendale",
                "label_ar": "باسندال",
                "label_fr": "Passendale"
            },
            {
                "value": 59698,
                "label": "Verlaine",
                "label_ar": "فيرلين",
                "label_fr": "Verlaine"
            },
            {
                "value": 59420,
                "label": "Strepy-Bracquegnies",
                "label_ar": "Strepy-Bracquegnies",
                "label_fr": "Strepy-Bracquegnies"
            },
            {
                "value": 59339,
                "label": "Dentergem",
                "label_ar": "Dentergem",
                "label_fr": "Dentergem"
            },
            {
                "value": 59901,
                "label": "Klemskerke",
                "label_ar": "كليمسكيرك",
                "label_fr": "Klemskerke"
            },
            {
                "value": 59069,
                "label": "Oudegem",
                "label_ar": "Oudegem",
                "label_fr": "Oudegem"
            },
            {
                "value": 60032,
                "label": "Haasdonk",
                "label_ar": "هاسدونك",
                "label_fr": "Haasdonk"
            },
            {
                "value": 58952,
                "label": "Sint-Genesius-Rode",
                "label_ar": "سينت جينيسيوس رودي",
                "label_fr": "Sint-Genesius-Rode"
            },
            {
                "value": 59963,
                "label": "Houthem",
                "label_ar": "حوثم",
                "label_fr": "Houthem"
            },
            {
                "value": 59879,
                "label": "Bois-d'Haine",
                "label_ar": "Bois-d'Haine",
                "label_fr": "Bois-d'Haine"
            },
            {
                "value": 59059,
                "label": "Mariakerke",
                "label_ar": "مارياكركي",
                "label_fr": "Mariakerke"
            },
            {
                "value": 59621,
                "label": "Couillet",
                "label_ar": "Couillet",
                "label_fr": "Couillet"
            },
            {
                "value": 59970,
                "label": "Wakken",
                "label_ar": "واكين",
                "label_fr": "Wakken"
            },
            {
                "value": 59563,
                "label": "Eigenbilzen",
                "label_ar": "ايجنبيلزين",
                "label_fr": "Eigenbilzen"
            },
            {
                "value": 58983,
                "label": "Appelterre-Eichem",
                "label_ar": "Appelterre-Eichem",
                "label_fr": "Appelterre-Eichem"
            },
            {
                "value": 59556,
                "label": "Koersel",
                "label_ar": "كورسل",
                "label_fr": "Koersel"
            },
            {
                "value": 59573,
                "label": "Hemiksem",
                "label_ar": "حميكسم",
                "label_fr": "Hemiksem"
            },
            {
                "value": 59723,
                "label": "Boussu",
                "label_ar": "بوسو",
                "label_fr": "Boussu"
            },
            {
                "value": 59542,
                "label": "Meensel-Kiezegem",
                "label_ar": "مينسل كيزيجيم",
                "label_fr": "Meensel-Kiezegem"
            },
            {
                "value": 59688,
                "label": "Vance",
                "label_ar": "فانس",
                "label_fr": "Vance"
            },
            {
                "value": 59133,
                "label": "Mouscron",
                "label_ar": "موسكرون",
                "label_fr": "Mouscron"
            },
            {
                "value": 59928,
                "label": "Zwartenbroek",
                "label_ar": "Zwartenbroek",
                "label_fr": "Zwartenbroek"
            },
            {
                "value": 59834,
                "label": "Monceau-sur-Sambre",
                "label_ar": "Monceau-sur-Sambre",
                "label_fr": "Monceau-sur-Sambre"
            },
            {
                "value": 59137,
                "label": "Waterloo",
                "label_ar": "واترلو",
                "label_fr": "Waterloo"
            },
            {
                "value": 59923,
                "label": "Seilles",
                "label_ar": "سيليس",
                "label_fr": "Seilles"
            },
            {
                "value": 58957,
                "label": "Strombeek-Bever",
                "label_ar": "سترومبيك بيفر",
                "label_fr": "Strombeek-Bever"
            },
            {
                "value": 59691,
                "label": "Borchtlombeek",
                "label_ar": "بورشتلومبيك",
                "label_fr": "Borchtlombeek"
            },
            {
                "value": 58939,
                "label": "Beyne-Heusay",
                "label_ar": "بين هيساي",
                "label_fr": "Beyne-Heusay"
            },
            {
                "value": 60011,
                "label": "Libramont",
                "label_ar": "ليبرامونت",
                "label_fr": "Libramont"
            },
            {
                "value": 59949,
                "label": "Langemark",
                "label_ar": "لانجمارك",
                "label_fr": "Langemark"
            },
            {
                "value": 59703,
                "label": "Malmedy",
                "label_ar": "مالميدي",
                "label_fr": "Malmedy"
            },
            {
                "value": 59494,
                "label": "Lubbeek",
                "label_ar": "لوبيك",
                "label_fr": "Lubbeek"
            },
            {
                "value": 59327,
                "label": "Leest",
                "label_ar": "ليست",
                "label_fr": "Leest"
            },
            {
                "value": 59950,
                "label": "Zwevegem",
                "label_ar": "Zwevegem",
                "label_fr": "Zwevegem"
            },
            {
                "value": 59520,
                "label": "Rillaar",
                "label_ar": "ريار",
                "label_fr": "Rillaar"
            },
            {
                "value": 58969,
                "label": "Rixensart",
                "label_ar": "ريكسينسارت",
                "label_fr": "Rixensart"
            },
            {
                "value": 59842,
                "label": "Florennes",
                "label_ar": "فلورين",
                "label_fr": "Florennes"
            },
            {
                "value": 59403,
                "label": "Saint-Leger",
                "label_ar": "سان ليجيه",
                "label_fr": "Saint-Léger"
            },
            {
                "value": 59590,
                "label": "Wespelaar",
                "label_ar": "ويسبيلار",
                "label_fr": "Wespelaar"
            },
            {
                "value": 59352,
                "label": "Rumbeke",
                "label_ar": "رومبيك",
                "label_fr": "Rumbeke"
            },
            {
                "value": 59999,
                "label": "Molenbeek",
                "label_ar": "مولينبيك",
                "label_fr": "Molenbeek"
            },
            {
                "value": 59356,
                "label": "Beveren",
                "label_ar": "بيفرين",
                "label_fr": "Beveren"
            },
            {
                "value": 58976,
                "label": "Destelbergen",
                "label_ar": "ديستلبيرجين",
                "label_fr": "Destelbergen"
            },
            {
                "value": 59915,
                "label": "Letterhoutem",
                "label_ar": "ليترهوتيم",
                "label_fr": "Letterhoutem"
            },
            {
                "value": 59581,
                "label": "Rijkevorsel",
                "label_ar": "ريجيفورسيل",
                "label_fr": "Rijkevorsel"
            },
            {
                "value": 59634,
                "label": "Lillois-Witterzee",
                "label_ar": "ليلويس ويتيرزي",
                "label_fr": "Lillois-Witterzee"
            },
            {
                "value": 60027,
                "label": "Lissewege",
                "label_ar": "ليسويج",
                "label_fr": "Lissewege"
            },
            {
                "value": 59627,
                "label": "Farciennes",
                "label_ar": "فارسين",
                "label_fr": "Farciennes"
            },
            {
                "value": 59201,
                "label": "Havre",
                "label_ar": "هافر",
                "label_fr": "Havre"
            },
            {
                "value": 60010,
                "label": "Grand",
                "label_ar": "كبير",
                "label_fr": "Grandiose"
            },
            {
                "value": 59324,
                "label": "Tongerlo",
                "label_ar": "تونجيرلو",
                "label_fr": "Tongerlo"
            },
            {
                "value": 59407,
                "label": "Assebroek",
                "label_ar": "Assebroek",
                "label_fr": "Assebroek"
            },
            {
                "value": 59149,
                "label": "Itterbeek",
                "label_ar": "إتيربيك",
                "label_fr": "Itterbeek"
            },
            {
                "value": 59902,
                "label": "Zuienkerke",
                "label_ar": "زوينكيركي",
                "label_fr": "Zuienkerke"
            },
            {
                "value": 59593,
                "label": "Buvrinnes",
                "label_ar": "بوفرينيس",
                "label_fr": "Buvrinnes"
            },
            {
                "value": 59421,
                "label": "Oud-Heverlee",
                "label_ar": "عود هيفرلي",
                "label_fr": "Oud-Heverlee"
            },
            {
                "value": 59957,
                "label": "Woesten",
                "label_ar": "Woesten",
                "label_fr": "Woesten"
            },
            {
                "value": 58913,
                "label": "Braine-le-Chateau",
                "label_ar": "براين لو شاتو",
                "label_fr": "Braine-le-Château"
            },
            {
                "value": 59094,
                "label": "Montignies-sur-Sambre",
                "label_ar": "مونتينيس سور سامبر",
                "label_fr": "Montignies-sur-Sambre"
            },
            {
                "value": 58995,
                "label": "Nederbrakel",
                "label_ar": "Nederbrakel",
                "label_fr": "Nederbrakel"
            },
            {
                "value": 59874,
                "label": "Maurage",
                "label_ar": "موراج",
                "label_fr": "Maurage"
            },
            {
                "value": 59239,
                "label": "Kampenhout",
                "label_ar": "كامبنهاوت",
                "label_fr": "Kampenhout"
            },
            {
                "value": 59411,
                "label": "Herseaux",
                "label_ar": "هيرسو",
                "label_fr": "Herseaux"
            },
            {
                "value": 59490,
                "label": "Tongeren",
                "label_ar": "تونجرين",
                "label_fr": "Tongres"
            },
            {
                "value": 59459,
                "label": "Moha",
                "label_ar": "موها",
                "label_fr": "Moha"
            },
            {
                "value": 59348,
                "label": "Oudenburg",
                "label_ar": "اودنبورغ",
                "label_fr": "Oudenburg"
            },
            {
                "value": 59507,
                "label": "Zolder",
                "label_ar": "زولدر",
                "label_fr": "Zolder"
            },
            {
                "value": 59510,
                "label": "Neerpelt",
                "label_ar": "نيربيلت",
                "label_fr": "Neerpelt"
            },
            {
                "value": 60052,
                "label": "Kerkhove",
                "label_ar": "كيركوف",
                "label_fr": "Kerkhove"
            },
            {
                "value": 59092,
                "label": "Fleurus",
                "label_ar": "فلوروس",
                "label_fr": "Fleurus"
            },
            {
                "value": 58905,
                "label": "Kontich",
                "label_ar": "كونتش",
                "label_fr": "Kontich"
            },
            {
                "value": 59694,
                "label": "Wolkrange",
                "label_ar": "وولكرانج",
                "label_fr": "Wolkrange"
            },
            {
                "value": 59722,
                "label": "Flenu",
                "label_ar": "فلينو",
                "label_fr": "Flenu"
            },
            {
                "value": 58937,
                "label": "Wanze",
                "label_ar": "وانز",
                "label_fr": "Wanze"
            },
            {
                "value": 58968,
                "label": "La Hulpe",
                "label_ar": "لا هولب",
                "label_fr": "La Hulpe"
            },
            {
                "value": 58931,
                "label": "Ougree",
                "label_ar": "اوجري",
                "label_fr": "Ougree"
            },
            {
                "value": 59951,
                "label": "Aalbeke",
                "label_ar": "آلبكة",
                "label_fr": "Aalbeke"
            },
            {
                "value": 59561,
                "label": "Oostham",
                "label_ar": "أوسثام",
                "label_fr": "Oostham"
            },
            {
                "value": 58997,
                "label": "Waasmunster",
                "label_ar": "واسمونستر",
                "label_fr": "Waasmunster"
            },
            {
                "value": 58938,
                "label": "Flobecq",
                "label_ar": "Flobecq",
                "label_fr": "Flobecq"
            },
            {
                "value": 59039,
                "label": "Burcht",
                "label_ar": "بيرشت",
                "label_fr": "Burcht"
            },
            {
                "value": 59498,
                "label": "Borgloon",
                "label_ar": "بورغلون",
                "label_fr": "Borgloon"
            },
            {
                "value": 60057,
                "label": "Kerksken",
                "label_ar": "كيركسكين",
                "label_fr": "Kerksken"
            },
            {
                "value": 59522,
                "label": "Diest",
                "label_ar": "ديست",
                "label_fr": "Diest"
            },
            {
                "value": 59555,
                "label": "Beverlo",
                "label_ar": "بيفرلو",
                "label_fr": "Beverlo"
            },
            {
                "value": 60004,
                "label": "Feluy",
                "label_ar": "فيلوي",
                "label_fr": "Feluy"
            },
            {
                "value": 59111,
                "label": "Chatelineau",
                "label_ar": "شاتلينو",
                "label_fr": "Châtelineau"
            },
            {
                "value": 58923,
                "label": "Argenteau",
                "label_ar": "أرجينتو",
                "label_fr": "Argenteau"
            },
            {
                "value": 59659,
                "label": "Anhée",
                "label_ar": "آنهي",
                "label_fr": "Anhée"
            },
            {
                "value": 59835,
                "label": "Wangenies",
                "label_ar": "صغار",
                "label_fr": "Wangenies"
            },
            {
                "value": 59487,
                "label": "Opgrimbie",
                "label_ar": "أوبجريمبي",
                "label_fr": "Opgrimbie"
            },
            {
                "value": 59616,
                "label": "Goutroux",
                "label_ar": "جوترو",
                "label_fr": "Goutroux"
            },
            {
                "value": 59279,
                "label": "Oud-Turnhout",
                "label_ar": "عود تورنهاوت",
                "label_fr": "Oud-Turnhout"
            },
            {
                "value": 59743,
                "label": "Xhendremael",
                "label_ar": "Xhendremael",
                "label_fr": "Xhendremael"
            },
            {
                "value": 59757,
                "label": "Warquignies",
                "label_ar": "Warquignies",
                "label_fr": "Warquignies"
            },
            {
                "value": 59523,
                "label": "Houthalen",
                "label_ar": "هوثالين",
                "label_fr": "Houthalen"
            },
            {
                "value": 60034,
                "label": "Schalkhoven",
                "label_ar": "شالخوفن",
                "label_fr": "Schalkhoven"
            },
            {
                "value": 59976,
                "label": "Geluwe",
                "label_ar": "جلوي",
                "label_fr": "Geluwe"
            },
            {
                "value": 58918,
                "label": "Lives-sur-Meuse",
                "label_ar": "يعيش سور ميوز",
                "label_fr": "Lives-sur-Meuse"
            },
            {
                "value": 59417,
                "label": "Rosieres",
                "label_ar": "روزيريس",
                "label_fr": "Rosières"
            },
            {
                "value": 59210,
                "label": "Zemst",
                "label_ar": "زمست",
                "label_fr": "Zemst"
            },
            {
                "value": 59591,
                "label": "Tildonk",
                "label_ar": "تيلدونك",
                "label_fr": "Tildonk"
            },
            {
                "value": 59946,
                "label": "Sint-Andries",
                "label_ar": "سينت أندريس",
                "label_fr": "Sint-Andries"
            },
            {
                "value": 59906,
                "label": "Kortemark",
                "label_ar": "كورتيمارك",
                "label_fr": "Kortemark"
            },
            {
                "value": 59750,
                "label": "Ressaix",
                "label_ar": "ريساي",
                "label_fr": "Ressaix"
            },
            {
                "value": 59385,
                "label": "Nethen",
                "label_ar": "نيثن",
                "label_fr": "Nethen"
            },
            {
                "value": 59316,
                "label": "Machelen",
                "label_ar": "ماتشيلن",
                "label_fr": "Machelen"
            },
            {
                "value": 59087,
                "label": "Drogenbos",
                "label_ar": "دروجنبوس",
                "label_fr": "Drogenbos"
            },
            {
                "value": 59263,
                "label": "Oosterzele",
                "label_ar": "اوسترزيل",
                "label_fr": "Oosterzele"
            },
            {
                "value": 59969,
                "label": "Meigem",
                "label_ar": "ميجيم",
                "label_fr": "Meigem"
            },
            {
                "value": 59679,
                "label": "Embourg",
                "label_ar": "امبورغ",
                "label_fr": "Embourg"
            },
            {
                "value": 59647,
                "label": "Aubange",
                "label_ar": "أوبانج",
                "label_fr": "Aubange"
            },
            {
                "value": 59620,
                "label": "Loverval",
                "label_ar": "لوفيرفال",
                "label_fr": "Loverval"
            },
            {
                "value": 59648,
                "label": "Mettet",
                "label_ar": "ميتت",
                "label_fr": "Mettet"
            },
            {
                "value": 59497,
                "label": "Gingelom",
                "label_ar": "جينجيلوم",
                "label_fr": "Gingelom"
            },
            {
                "value": 59332,
                "label": "Hove",
                "label_ar": "هوف",
                "label_fr": "Hove"
            },
            {
                "value": 59540,
                "label": "Bekkevoort",
                "label_ar": "بيكيفورت",
                "label_fr": "Bekkevoort"
            },
            {
                "value": 59832,
                "label": "Glimes",
                "label_ar": "الوهج",
                "label_fr": "Glimes"
            },
            {
                "value": 59338,
                "label": "Ingelmunster",
                "label_ar": "إنجلمونستر",
                "label_fr": "Ingelmunster"
            },
            {
                "value": 59917,
                "label": "Jamioulx",
                "label_ar": "جميولكس",
                "label_fr": "Jamioulx"
            },
            {
                "value": 59496,
                "label": "Kortrijk-Dutsel",
                "label_ar": "كورتريجك دوتسيل",
                "label_fr": "Courtrai-Dutsel"
            },
            {
                "value": 59311,
                "label": "Forest",
                "label_ar": "غابة",
                "label_fr": "Forêt"
            },
            {
                "value": 59002,
                "label": "Sint-Amandsberg",
                "label_ar": "سينت اماندسبرج",
                "label_fr": "Sint-Amandsberg"
            },
            {
                "value": 59076,
                "label": "Afsnee",
                "label_ar": "أفصني",
                "label_fr": "Afsnee"
            },
            {
                "value": 59101,
                "label": "Gilly",
                "label_ar": "جيلي",
                "label_fr": "Gilly"
            },
            {
                "value": 59196,
                "label": "Denderbelle",
                "label_ar": "دندربيل",
                "label_fr": "Denderbelle"
            },
            {
                "value": 59756,
                "label": "Waudrez",
                "label_ar": "Waudrez",
                "label_fr": "Waudrez"
            },
            {
                "value": 59376,
                "label": "Corroy-le-Grand",
                "label_ar": "Corroy-le-Grand",
                "label_fr": "Corroy-le-Grand"
            },
            {
                "value": 59909,
                "label": "Dudzele",
                "label_ar": "دودزيل",
                "label_fr": "Dudzele"
            },
            {
                "value": 60029,
                "label": "Sint-Kornelis-Horebeke",
                "label_ar": "سينت كورنيلس هوريبيكي",
                "label_fr": "Sint-Kornelis-Horebeke"
            },
            {
                "value": 59328,
                "label": "Boortmeerbeek",
                "label_ar": "بورتميربيك",
                "label_fr": "Boortmeerbeek"
            },
            {
                "value": 59611,
                "label": "Fexhe-le-Haut-Clocher",
                "label_ar": "Fexhe-le-Haut-Clocher",
                "label_fr": "Fexhe-le-Haut-Clocher"
            },
            {
                "value": 59186,
                "label": "Erpent",
                "label_ar": "Erpent",
                "label_fr": "Erpent"
            },
            {
                "value": 59322,
                "label": "Kasterlee",
                "label_ar": "كاسترلي",
                "label_fr": "Kasterlee"
            },
            {
                "value": 59052,
                "label": "Moerbeke",
                "label_ar": "موربيك",
                "label_fr": "Moerbeke"
            },
            {
                "value": 59193,
                "label": "Wezemaal",
                "label_ar": "ويزمال",
                "label_fr": "Wezemaal"
            },
            {
                "value": 59281,
                "label": "Rumst",
                "label_ar": "رمست",
                "label_fr": "Rumst"
            },
            {
                "value": 59720,
                "label": "Quaregnon",
                "label_ar": "كواريجنون",
                "label_fr": "Quaregnon"
            },
            {
                "value": 60020,
                "label": "Warchin",
                "label_ar": "وارشين",
                "label_fr": "Warchin"
            },
            {
                "value": 59003,
                "label": "Herzele",
                "label_ar": "هرتزل",
                "label_fr": "Herzele"
            },
            {
                "value": 59775,
                "label": "Nokere",
                "label_ar": "نوكيري",
                "label_fr": "Nokere"
            },
            {
                "value": 59762,
                "label": "Zulte",
                "label_ar": "زولت",
                "label_fr": "Zulte"
            },
            {
                "value": 59828,
                "label": "Thisnes",
                "label_ar": "ثينس",
                "label_fr": "Thisnes"
            },
            {
                "value": 59546,
                "label": "Kozen",
                "label_ar": "كوزين",
                "label_fr": "Kozen"
            },
            {
                "value": 59853,
                "label": "Bouge",
                "label_ar": "بوج",
                "label_fr": "Bouge"
            },
            {
                "value": 59172,
                "label": "Dave",
                "label_ar": "ديف",
                "label_fr": "Dave"
            },
            {
                "value": 59981,
                "label": "Kachtem",
                "label_ar": "كشتيم",
                "label_fr": "Kachtem"
            },
            {
                "value": 59141,
                "label": "Schepdaal",
                "label_ar": "شيبدال",
                "label_fr": "Schepdaal"
            },
            {
                "value": 59381,
                "label": "Wasmes",
                "label_ar": "واسميس",
                "label_fr": "Wasmes"
            },
            {
                "value": 59530,
                "label": "Molenstede",
                "label_ar": "مولينستيد",
                "label_fr": "Molenstede"
            },
            {
                "value": 59433,
                "label": "Auderghem",
                "label_ar": "أوديرغم",
                "label_fr": "Auderghem"
            },
            {
                "value": 59846,
                "label": "Ligny",
                "label_ar": "Ligny",
                "label_fr": "Ligny"
            },
            {
                "value": 59908,
                "label": "Heist",
                "label_ar": "سرقة",
                "label_fr": "Braquage"
            },
            {
                "value": 59451,
                "label": "Torhout",
                "label_ar": "تورهوت",
                "label_fr": "Torhout"
            },
            {
                "value": 59299,
                "label": "Humbeek",
                "label_ar": "همبيك",
                "label_fr": "Humbeek"
            },
            {
                "value": 58947,
                "label": "Waregem",
                "label_ar": "وارجيم",
                "label_fr": "Waregem"
            },
            {
                "value": 59682,
                "label": "Oostkamp",
                "label_ar": "أوستكامب",
                "label_fr": "Oostkamp"
            },
            {
                "value": 59734,
                "label": "Limelette",
                "label_ar": "لايمليت",
                "label_fr": "Limelette"
            },
            {
                "value": 58977,
                "label": "Lochristi",
                "label_ar": "لوكريستي",
                "label_fr": "Lochristi"
            },
            {
                "value": 59399,
                "label": "Waremme",
                "label_ar": "Waremme",
                "label_fr": "Waremme"
            },
            {
                "value": 58921,
                "label": "Beaufays",
                "label_ar": "Beaufays",
                "label_fr": "Beaufays"
            },
            {
                "value": 59955,
                "label": "Houthulst",
                "label_ar": "Houthulst",
                "label_fr": "Houthulst"
            },
            {
                "value": 59144,
                "label": "Beersel",
                "label_ar": "بيرسل",
                "label_fr": "Beersel"
            },
            {
                "value": 59292,
                "label": "Wechelderzande",
                "label_ar": "Wechelderzande",
                "label_fr": "Wechelderzande"
            },
            {
                "value": 59576,
                "label": "Merksplas",
                "label_ar": "Merksplas",
                "label_fr": "Merksplas"
            },
            {
                "value": 58963,
                "label": "Jemeppe-sur-Meuse",
                "label_ar": "جميب سور ميوز",
                "label_fr": "Jemeppe-sur-Meuse"
            },
            {
                "value": 59365,
                "label": "Yvoir",
                "label_ar": "إيفوار",
                "label_fr": "Yvoir"
            },
            {
                "value": 59833,
                "label": "Souvret",
                "label_ar": "سوفرت",
                "label_fr": "Souvret"
            },
            {
                "value": 60028,
                "label": "Serskamp",
                "label_ar": "سيرسكامب",
                "label_fr": "Serskamp"
            },
            {
                "value": 59572,
                "label": "Lichtaart",
                "label_ar": "ليشتارت",
                "label_fr": "Lichtaart"
            },
            {
                "value": 59091,
                "label": "Villers-la-Ville",
                "label_ar": "فيليرز لا فيل",
                "label_fr": "Villers-la-Ville"
            },
            {
                "value": 60053,
                "label": "Gits",
                "label_ar": "جيت",
                "label_fr": "Gits"
            },
            {
                "value": 59367,
                "label": "Anseroeul",
                "label_ar": "أنسيرويل",
                "label_fr": "Anseroeul"
            },
            {
                "value": 59361,
                "label": "Knesselare",
                "label_ar": "كنسلاري",
                "label_fr": "Knesselare"
            },
            {
                "value": 59582,
                "label": "Pulle",
                "label_ar": "بكرة",
                "label_fr": "Pulle"
            },
            {
                "value": 59685,
                "label": "Staden",
                "label_ar": "ستادن",
                "label_fr": "Staden"
            },
            {
                "value": 59732,
                "label": "Pipaix",
                "label_ar": "Pipaix",
                "label_fr": "Pipaix"
            },
            {
                "value": 59584,
                "label": "Eppegem",
                "label_ar": "Eppegem",
                "label_fr": "Eppegem"
            },
            {
                "value": 59589,
                "label": "Terhagen",
                "label_ar": "تيرهاجين",
                "label_fr": "Terhagen"
            },
            {
                "value": 60007,
                "label": "Post",
                "label_ar": "بريد",
                "label_fr": "Publier"
            },
            {
                "value": 59745,
                "label": "Warsage",
                "label_ar": "وارسج",
                "label_fr": "Warsage"
            },
            {
                "value": 59777,
                "label": "Vlierzele",
                "label_ar": "فلييرزيل",
                "label_fr": "Vlierzele"
            },
            {
                "value": 58949,
                "label": "Kortrijk",
                "label_ar": "كورتريجك",
                "label_fr": "Courtrai"
            },
            {
                "value": 58974,
                "label": "Zaventem",
                "label_ar": "زافينتيم",
                "label_fr": "Zaventem"
            },
            {
                "value": 58970,
                "label": "Evere",
                "label_ar": "إيفير",
                "label_fr": "Evere"
            },
            {
                "value": 59258,
                "label": "Gistel",
                "label_ar": "جيستل",
                "label_fr": "Gistel"
            },
            {
                "value": 59594,
                "label": "Bierset",
                "label_ar": "بيرسيت",
                "label_fr": "Bierset"
            },
            {
                "value": 58975,
                "label": "Denderleeuw",
                "label_ar": "دندرليو",
                "label_fr": "Denderleeuw"
            },
            {
                "value": 59185,
                "label": "Hamoir",
                "label_ar": "حمور",
                "label_fr": "Hamoir"
            },
            {
                "value": 59268,
                "label": "Vosselaar",
                "label_ar": "فوسيلار",
                "label_fr": "Vosselaar"
            },
            {
                "value": 59082,
                "label": "Zeveneken",
                "label_ar": "زيفينكين",
                "label_fr": "Zeveneken"
            },
            {
                "value": 59304,
                "label": "Etterbeek",
                "label_ar": "إتيربيك",
                "label_fr": "Etterbeek"
            },
            {
                "value": 60026,
                "label": "Adinkerke",
                "label_ar": "Adinkerke",
                "label_fr": "Adinkerke"
            },
            {
                "value": 59294,
                "label": "Halle",
                "label_ar": "هالي",
                "label_fr": "Halle"
            },
            {
                "value": 59562,
                "label": "Rekem",
                "label_ar": "ريكيم",
                "label_fr": "Rekem"
            },
            {
                "value": 59151,
                "label": "Alsemberg",
                "label_ar": "Alsemberg",
                "label_fr": "Alsemberg"
            },
            {
                "value": 59763,
                "label": "Wortegem",
                "label_ar": "Wortegem",
                "label_fr": "Wortegem"
            },
            {
                "value": 58924,
                "label": "Angleur",
                "label_ar": "أنجلور",
                "label_fr": "Angleur"
            },
            {
                "value": 59776,
                "label": "Vichte",
                "label_ar": "فيشت",
                "label_fr": "Vichte"
            },
            {
                "value": 59935,
                "label": "Hansbeke",
                "label_ar": "هانزبيك",
                "label_fr": "Hansbeke"
            },
            {
                "value": 59980,
                "label": "Zwevezele",
                "label_ar": "Zwevezele",
                "label_fr": "Zwevezele"
            },
            {
                "value": 59276,
                "label": "Kapelle-op-den-Bos",
                "label_ar": "Kapelle-op-den-Bos",
                "label_fr": "Kapelle-op-den-Bos"
            },
            {
                "value": 59867,
                "label": "Gobard",
                "label_ar": "جوبارد",
                "label_fr": "Gobard"
            },
            {
                "value": 59827,
                "label": "Enghien",
                "label_ar": "إنجين",
                "label_fr": "Enghien"
            },
            {
                "value": 59919,
                "label": "Ster",
                "label_ar": "ستير",
                "label_fr": "Ster"
            },
            {
                "value": 59461,
                "label": "Alleur",
                "label_ar": "ألور",
                "label_fr": "Alleur"
            },
            {
                "value": 59255,
                "label": "Kuurne",
                "label_ar": "كورني",
                "label_fr": "Kuurne"
            },
            {
                "value": 59672,
                "label": "Stembert",
                "label_ar": "ستمبرت",
                "label_fr": "Stembert"
            },
            {
                "value": 59595,
                "label": "Saive",
                "label_ar": "سيف",
                "label_fr": "Saive"
            },
            {
                "value": 59912,
                "label": "Harelbeke",
                "label_ar": "Harelbeke",
                "label_fr": "Harelbeke"
            },
            {
                "value": 59391,
                "label": "Boom",
                "label_ar": "فقاعة",
                "label_fr": "Boom"
            },
            {
                "value": 59938,
                "label": "Watervliet",
                "label_ar": "ووترفليت",
                "label_fr": "Watervliet"
            },
            {
                "value": 59653,
                "label": "Filot",
                "label_ar": "فيلوت",
                "label_fr": "Filot"
            },
            {
                "value": 59707,
                "label": "Astenet",
                "label_ar": "أستينيت",
                "label_fr": "Astenet"
            },
            {
                "value": 59456,
                "label": "Theux",
                "label_ar": "ثيو",
                "label_fr": "Theux"
            },
            {
                "value": 59596,
                "label": "Remicourt",
                "label_ar": "ريميكورت",
                "label_fr": "Remicourt"
            },
            {
                "value": 58979,
                "label": "Kalken",
                "label_ar": "كالكن",
                "label_fr": "Kalken"
            },
            {
                "value": 59363,
                "label": "Park",
                "label_ar": "منتزه",
                "label_fr": "parc"
            },
            {
                "value": 59680,
                "label": "Haneffe",
                "label_ar": "حنيف",
                "label_fr": "Haneffe"
            },
            {
                "value": 59705,
                "label": "Izel",
                "label_ar": "إيزيل",
                "label_fr": "Izel"
            },
            {
                "value": 59889,
                "label": "Attre",
                "label_ar": "أتري",
                "label_fr": "Attre"
            },
            {
                "value": 59984,
                "label": "Loker",
                "label_ar": "لوكر",
                "label_fr": "Loker"
            },
            {
                "value": 58980,
                "label": "Zaffelare",
                "label_ar": "زافيلاري",
                "label_fr": "Zaffelare"
            },
            {
                "value": 59413,
                "label": "Aartrijke",
                "label_ar": "Aartrijke",
                "label_fr": "Aartrijke"
            },
            {
                "value": 58932,
                "label": "Awans",
                "label_ar": "أوانز",
                "label_fr": "Awans"
            },
            {
                "value": 59615,
                "label": "Aubel",
                "label_ar": "أوبل",
                "label_fr": "Aubel"
            },
            {
                "value": 59054,
                "label": "Overmere",
                "label_ar": "أوفيرمير",
                "label_fr": "Overmere"
            },
            {
                "value": 59154,
                "label": "Arlon",
                "label_ar": "آرلون",
                "label_fr": "Arlon"
            },
            {
                "value": 58959,
                "label": "Wilsele",
                "label_ar": "ويلسيلي",
                "label_fr": "Wilsele"
            },
            {
                "value": 59628,
                "label": "Fontaine",
                "label_ar": "فونتين",
                "label_fr": "Fontaine"
            },
            {
                "value": 59481,
                "label": "Maaseik",
                "label_ar": "معسيك",
                "label_fr": "Maaseik"
            },
            {
                "value": 59836,
                "label": "Welkenraedt",
                "label_ar": "ويلكينريدت",
                "label_fr": "Welkenraedt"
            },
            {
                "value": 59597,
                "label": "Hodeige",
                "label_ar": "هوديج",
                "label_fr": "Hodeige"
            },
            {
                "value": 59427,
                "label": "Frameries",
                "label_ar": "فرامريز",
                "label_fr": "Frameries"
            },
            {
                "value": 59331,
                "label": "Edegem",
                "label_ar": "إديجم",
                "label_fr": "Edegem"
            },
            {
                "value": 59306,
                "label": "Vollezele",
                "label_ar": "فوليزيل",
                "label_fr": "Vollezele"
            },
            {
                "value": 59960,
                "label": "Lauwe",
                "label_ar": "اضحك",
                "label_fr": "Lauwe"
            },
            {
                "value": 59455,
                "label": "Villers-sur-Semois",
                "label_ar": "فيليرس سور سيموا",
                "label_fr": "Villers-sur-Semois"
            },
            {
                "value": 58956,
                "label": "Vilvoorde",
                "label_ar": "فيلفورد",
                "label_fr": "Vilvorde"
            },
            {
                "value": 59678,
                "label": "Limbourg",
                "label_ar": "ليمبورغ",
                "label_fr": "Limbourg"
            },
            {
                "value": 59354,
                "label": "Zonnebeke",
                "label_ar": "زونيبيك",
                "label_fr": "Zonnebeke"
            },
            {
                "value": 58985,
                "label": "Ninove",
                "label_ar": "نينوف",
                "label_fr": "Ninove"
            },
            {
                "value": 59554,
                "label": "Beringen",
                "label_ar": "بيرنغن",
                "label_fr": "Beringen"
            },
            {
                "value": 59665,
                "label": "Sclayn",
                "label_ar": "سكلن",
                "label_fr": "Sclayn"
            },
            {
                "value": 59450,
                "label": "Wanne",
                "label_ar": "وان",
                "label_fr": "Wanne"
            },
            {
                "value": 59543,
                "label": "Wellen",
                "label_ar": "ويلين",
                "label_fr": "Wellen"
            },
            {
                "value": 59532,
                "label": "Zichem",
                "label_ar": "زيكم",
                "label_fr": "Zichem"
            },
            {
                "value": 59394,
                "label": "Wepion",
                "label_ar": "Wepion",
                "label_fr": "Wepion"
            },
            {
                "value": 59178,
                "label": "Saint-Georges-sur-Meuse",
                "label_ar": "سان جورج سور ميوز",
                "label_fr": "Saint-Georges-sur-Meuse"
            },
            {
                "value": 58912,
                "label": "Puurs",
                "label_ar": "Puurs",
                "label_fr": "Puurs"
            },
            {
                "value": 59799,
                "label": "Peutie",
                "label_ar": "بيوتي",
                "label_fr": "Peutie"
            },
            {
                "value": 59850,
                "label": "Ham-sur-Sambre",
                "label_ar": "هام سور سامبر",
                "label_fr": "Ham-sur-Sambre"
            },
            {
                "value": 58909,
                "label": "Liège",
                "label_ar": "لييج",
                "label_fr": "Liege"
            },
            {
                "value": 59772,
                "label": "Barchon",
                "label_ar": "بارشون",
                "label_fr": "Barchon"
            },
            {
                "value": 59056,
                "label": "Pollare",
                "label_ar": "بولاري",
                "label_fr": "Pollare"
            },
            {
                "value": 59926,
                "label": "De Haan",
                "label_ar": "دي هان",
                "label_fr": "De Haan"
            },
            {
                "value": 59063,
                "label": "Kemzeke",
                "label_ar": "Kemzeke",
                "label_fr": "Kemzeke"
            },
            {
                "value": 59272,
                "label": "Aarschot",
                "label_ar": "أرشوت",
                "label_fr": "Aarschot"
            },
            {
                "value": 59600,
                "label": "Ramet",
                "label_ar": "راميت",
                "label_fr": "Ramet"
            },
            {
                "value": 59858,
                "label": "Dongelberg",
                "label_ar": "دونجلبرج",
                "label_fr": "Dongelberg"
            },
            {
                "value": 59009,
                "label": "Malderen",
                "label_ar": "مالديرين",
                "label_fr": "Malderen"
            },
            {
                "value": 59701,
                "label": "Kettenis",
                "label_ar": "كيتينيس",
                "label_fr": "Kettenis"
            },
            {
                "value": 59362,
                "label": "Waarschoot",
                "label_ar": "وارشوت",
                "label_fr": "Waarschoot"
            },
            {
                "value": 59189,
                "label": "Steenokkerzeel",
                "label_ar": "ستينوكيرزيل",
                "label_fr": "Steenokkerzeel"
            },
            {
                "value": 59099,
                "label": "Presles",
                "label_ar": "بريسليس",
                "label_fr": "Presles"
            },
            {
                "value": 60025,
                "label": "Leisele",
                "label_ar": "ليسيل",
                "label_fr": "Leisele"
            },
            {
                "value": 59585,
                "label": "Schriek",
                "label_ar": "شريك",
                "label_fr": "Schriek"
            },
            {
                "value": 59929,
                "label": "Petegem",
                "label_ar": "بيتيجم",
                "label_fr": "Petegem"
            },
            {
                "value": 59175,
                "label": "Jauche",
                "label_ar": "جوش",
                "label_fr": "Jauche"
            },
            {
                "value": 60021,
                "label": "Okegem",
                "label_ar": "Okegem",
                "label_fr": "Okegem"
            },
            {
                "value": 59881,
                "label": "Renlies",
                "label_ar": "رينليس",
                "label_fr": "Renlies"
            },
            {
                "value": 59794,
                "label": "Brugelette",
                "label_ar": "بروجليت",
                "label_fr": "Brugelette"
            },
            {
                "value": 59657,
                "label": "Houtain-le-Val",
                "label_ar": "هوتين لو فال",
                "label_fr": "Houtain-le-Val"
            },
            {
                "value": 58933,
                "label": "Mons",
                "label_ar": "مونس",
                "label_fr": "Mons"
            },
            {
                "value": 59449,
                "label": "Ferrieres",
                "label_ar": "العبارات",
                "label_fr": "Ferrières"
            },
            {
                "value": 59652,
                "label": "Xhoris",
                "label_ar": "خوريس",
                "label_fr": "Xhoris"
            },
            {
                "value": 60060,
                "label": "Forrieres",
                "label_ar": "فورييه",
                "label_fr": "Forrieres"
            },
            {
                "value": 59033,
                "label": "Schendelbeke",
                "label_ar": "شيندلبيك",
                "label_fr": "Schendelbeke"
            },
            {
                "value": 59632,
                "label": "Louvain-la-Neuve",
                "label_ar": "لوفان لا نوف",
                "label_fr": "Louvain-la-Neuve"
            },
            {
                "value": 59656,
                "label": "Nivelles",
                "label_ar": "نيفيل",
                "label_fr": "Nivelles"
            },
            {
                "value": 59484,
                "label": "Dilsen",
                "label_ar": "ديلسن",
                "label_fr": "Dilsen"
            },
            {
                "value": 59877,
                "label": "La Hestre",
                "label_ar": "لا هيستر",
                "label_fr": "La Hestre"
            },
            {
                "value": 59064,
                "label": "Eeklo",
                "label_ar": "إيكلو",
                "label_fr": "Eeklo"
            },
            {
                "value": 60008,
                "label": "Melsbroek",
                "label_ar": "ميلسبروك",
                "label_fr": "Melsbroek"
            },
            {
                "value": 58906,
                "label": "Vise",
                "label_ar": "ملزمة",
                "label_fr": "Vice"
            },
            {
                "value": 59072,
                "label": "Hingene",
                "label_ar": "هجين",
                "label_fr": "Hingène"
            },
            {
                "value": 58934,
                "label": "Huy",
                "label_ar": "هوي",
                "label_fr": "Huy"
            },
            {
                "value": 59651,
                "label": "Gourdinne",
                "label_ar": "جوردن",
                "label_fr": "Gourdinne"
            },
            {
                "value": 59709,
                "label": "Natoye",
                "label_ar": "ناتوي",
                "label_fr": "Natoye"
            },
            {
                "value": 59760,
                "label": "Avelgem",
                "label_ar": "أفيلجم",
                "label_fr": "Avelgem"
            },
            {
                "value": 59505,
                "label": "Halen",
                "label_ar": "هالين",
                "label_fr": "Halen"
            },
            {
                "value": 59379,
                "label": "Bonlez",
                "label_ar": "بونليز",
                "label_fr": "Bonlez"
            },
            {
                "value": 59918,
                "label": "Fosse des Loups",
                "label_ar": "Fosse des Loups",
                "label_fr": "Fosse des Loups"
            },
            {
                "value": 59278,
                "label": "Essen",
                "label_ar": "إيسن",
                "label_fr": "Essen"
            },
            {
                "value": 59548,
                "label": "Betekom",
                "label_ar": "بيتكوم",
                "label_fr": "Betekom"
            },
            {
                "value": 59818,
                "label": "Emblem",
                "label_ar": "شعار",
                "label_fr": "Emblème"
            },
            {
                "value": 59267,
                "label": "Borgerhout",
                "label_ar": "بورغيرهوت",
                "label_fr": "Borgerhout"
            },
            {
                "value": 59021,
                "label": "Lebbeke",
                "label_ar": "ليبيكي",
                "label_fr": "Lebbeke"
            },
            {
                "value": 59371,
                "label": "Arsimont",
                "label_ar": "أرسيمونت",
                "label_fr": "Arsimont"
            },
            {
                "value": 59241,
                "label": "Scherpenheuvel",
                "label_ar": "شيربنهوفيل",
                "label_fr": "Scherpenheuvel"
            },
            {
                "value": 59952,
                "label": "Heule",
                "label_ar": "هيول",
                "label_fr": "Heule"
            },
            {
                "value": 59650,
                "label": "Hampteau",
                "label_ar": "هامبتو",
                "label_fr": "Hampteau"
            },
            {
                "value": 59261,
                "label": "Hever",
                "label_ar": "حيفر",
                "label_fr": "Hever"
            },
            {
                "value": 59252,
                "label": "Zandhoven",
                "label_ar": "زاندهوفن",
                "label_fr": "Zandhoven"
            },
            {
                "value": 59171,
                "label": "Profondeville",
                "label_ar": "بروفوندفيل",
                "label_fr": "Profondeville"
            },
            {
                "value": 59886,
                "label": "Rebaix",
                "label_ar": "ربيكس",
                "label_fr": "Rebaix"
            },
            {
                "value": 60005,
                "label": "Sablon",
                "label_ar": "سابلون",
                "label_fr": "Sablon"
            },
            {
                "value": 59018,
                "label": "De Pinte",
                "label_ar": "دي بينتي",
                "label_fr": "De Pinte"
            },
            {
                "value": 59200,
                "label": "Wuustwezel",
                "label_ar": "Wuustwezel",
                "label_fr": "Wuustwezel"
            },
            {
                "value": 59921,
                "label": "Ellignies-Sainte-Anne",
                "label_ar": "إليجنيس سانت آن",
                "label_fr": "Ellignies-Sainte-Anne"
            },
            {
                "value": 59473,
                "label": "Bocholt",
                "label_ar": "Bocholt",
                "label_fr": "Bocholt"
            },
            {
                "value": 58961,
                "label": "Dworp",
                "label_ar": "Dworp",
                "label_fr": "Dworp"
            },
            {
                "value": 59893,
                "label": "Wieze",
                "label_ar": "ويز",
                "label_fr": "Wieze"
            },
            {
                "value": 59660,
                "label": "Messancy",
                "label_ar": "الفوضى",
                "label_fr": "Messancy"
            },
            {
                "value": 59212,
                "label": "Onze-Lieve-Vrouw-Waver",
                "label_ar": "Onze-Lieve-Vrouw-Waver",
                "label_fr": "Onze-Lieve-Vrouw-Waver"
            },
            {
                "value": 58987,
                "label": "Outer",
                "label_ar": "الخارجي",
                "label_fr": "Extérieur"
            },
            {
                "value": 59236,
                "label": "Kortenberg",
                "label_ar": "كورتنبرغ",
                "label_fr": "Kortenberg"
            },
            {
                "value": 59570,
                "label": "Baarle-Hertog",
                "label_ar": "بارل هيرتوج",
                "label_fr": "Baarle-Hertog"
            },
            {
                "value": 60038,
                "label": "Gouy-lez-Pieton",
                "label_ar": "جوي ليز بيتون",
                "label_fr": "Gouy-lez-Pieton"
            },
            {
                "value": 59939,
                "label": "Oosteeklo",
                "label_ar": "أوستيكلو",
                "label_fr": "Oosteeklo"
            },
            {
                "value": 59219,
                "label": "Kalmthout",
                "label_ar": "كالمثوت",
                "label_fr": "Kalmthout"
            },
            {
                "value": 59086,
                "label": "Grembergen",
                "label_ar": "جريمبيرجين",
                "label_fr": "Grembergen"
            },
            {
                "value": 59205,
                "label": "Balen",
                "label_ar": "بالين",
                "label_fr": "Balen"
            },
            {
                "value": 59160,
                "label": "Erbaut",
                "label_ar": "اربوت",
                "label_fr": "Erbaut"
            },
            {
                "value": 59997,
                "label": "Zarren",
                "label_ar": "زارين",
                "label_fr": "Zarren"
            },
            {
                "value": 59513,
                "label": "Zoutleeuw",
                "label_ar": "Zoutleeuw",
                "label_fr": "Zoutleeuw"
            },
            {
                "value": 59989,
                "label": "Heestert",
                "label_ar": "هيسترت",
                "label_fr": "Heestert"
            },
            {
                "value": 59715,
                "label": "Jodoigne",
                "label_ar": "جودوين",
                "label_fr": "Jodoigne"
            },
            {
                "value": 59013,
                "label": "Dendermonde",
                "label_ar": "ديندرموند",
                "label_fr": "Termonde"
            },
            {
                "value": 59726,
                "label": "Houffalize",
                "label_ar": "هوفاليز",
                "label_fr": "Houffalize"
            },
            {
                "value": 59318,
                "label": "Herent",
                "label_ar": "هيرنت",
                "label_fr": "Herent"
            },
            {
                "value": 59741,
                "label": "Sart Melin",
                "label_ar": "سارت ميلين",
                "label_fr": "Sart Melin"
            },
            {
                "value": 59583,
                "label": "Heindonk",
                "label_ar": "هيندونك",
                "label_fr": "Heindonk"
            },
            {
                "value": 59503,
                "label": "Rosmeer",
                "label_ar": "روزمير",
                "label_fr": "Rosmeer"
            },
            {
                "value": 59373,
                "label": "Sirault",
                "label_ar": "سيرولت",
                "label_fr": "Sirault"
            },
            {
                "value": 59312,
                "label": "Nossegem",
                "label_ar": "نوسيجيم",
                "label_fr": "Nossegem"
            },
            {
                "value": 59448,
                "label": "Erezee",
                "label_ar": "إيرزي",
                "label_fr": "Erezee"
            },
            {
                "value": 59232,
                "label": "Arendonk",
                "label_ar": "أرندونك",
                "label_fr": "Arendonk"
            },
            {
                "value": 58928,
                "label": "Fexhe-Slins",
                "label_ar": "فيكس سلينز",
                "label_fr": "Fexhe-Slins"
            },
            {
                "value": 59424,
                "label": "Bissegem",
                "label_ar": "بيسيجيم",
                "label_fr": "Bissegem"
            },
            {
                "value": 59017,
                "label": "Eksaarde",
                "label_ar": "اكساردي",
                "label_fr": "Eksaarde"
            },
            {
                "value": 59876,
                "label": "Mariembourg",
                "label_ar": "مارييمبورغ",
                "label_fr": "Mariembourg"
            },
            {
                "value": 59927,
                "label": "Ruien",
                "label_ar": "Ruien",
                "label_fr": "Ruien"
            },
            {
                "value": 59782,
                "label": "Walhain",
                "label_ar": "والهاين",
                "label_fr": "Walhain"
            },
            {
                "value": 59991,
                "label": "Sint-Maria-Latem",
                "label_ar": "سينت ماريا لاتيم",
                "label_fr": "Sint-Maria-Latem"
            },
            {
                "value": 59807,
                "label": "Saint-Jean-Geest",
                "label_ar": "سان جان جيست",
                "label_fr": "Saint-Jean-Geest"
            },
            {
                "value": 59078,
                "label": "Verrebroek",
                "label_ar": "فيريبروك",
                "label_fr": "Verrebroek"
            },
            {
                "value": 59578,
                "label": "Meersel",
                "label_ar": "ميرسيل",
                "label_fr": "Meersel"
            },
            {
                "value": 59824,
                "label": "Latinne",
                "label_ar": "لاتيني",
                "label_fr": "Latinne"
            },
            {
                "value": 59744,
                "label": "Huldenberg",
                "label_ar": "هولدنبرج",
                "label_fr": "Huldenberg"
            },
            {
                "value": 58993,
                "label": "Opbrakel",
                "label_ar": "أوبراكل",
                "label_fr": "Opbrakel"
            },
            {
                "value": 58972,
                "label": "Hoeilaart",
                "label_ar": "هويلارت",
                "label_fr": "Hoeilaart"
            },
            {
                "value": 59142,
                "label": "Sint-Pieters-Leeuw",
                "label_ar": "سينت بيترز ليو",
                "label_fr": "Sint-Pieters-Leeuw"
            },
            {
                "value": 59793,
                "label": "Dorinne",
                "label_ar": "دورين",
                "label_fr": "Dorinne"
            },
            {
                "value": 59891,
                "label": "Retinne",
                "label_ar": "ريتين",
                "label_fr": "Retinne"
            },
            {
                "value": 59697,
                "label": "Olne",
                "label_ar": "أولني",
                "label_fr": "Olne"
            },
            {
                "value": 59738,
                "label": "Hautrage",
                "label_ar": "أوتراج",
                "label_fr": "Hautrage"
            },
            {
                "value": 59870,
                "label": "Chaussee-Notre-Dame-Louvignies",
                "label_ar": "تشاوسي نوتردام لوفيني",
                "label_fr": "Chaussée-Notre-Dame-Louvignies"
            },
            {
                "value": 58984,
                "label": "Haaltert",
                "label_ar": "هالتر",
                "label_fr": "Haaltert"
            },
            {
                "value": 59457,
                "label": "Bavikhove",
                "label_ar": "بافيكوف",
                "label_fr": "Bavikhove"
            },
            {
                "value": 59502,
                "label": "Zutendaal",
                "label_ar": "Zutendaal",
                "label_fr": "Zutendaal"
            },
            {
                "value": 59964,
                "label": "Rekkem",
                "label_ar": "ريكيم",
                "label_fr": "Rekkem"
            },
            {
                "value": 59231,
                "label": "Zoersel",
                "label_ar": "زويرسل",
                "label_fr": "Zoersel"
            },
            {
                "value": 59283,
                "label": "Muizen",
                "label_ar": "Muizen",
                "label_fr": "Muizen"
            },
            {
                "value": 60015,
                "label": "Orbais",
                "label_ar": "أوربايس",
                "label_fr": "Orbais"
            },
            {
                "value": 59769,
                "label": "Damme",
                "label_ar": "دام",
                "label_fr": "Damme"
            },
            {
                "value": 59495,
                "label": "Nieuwrode",
                "label_ar": "نيورود",
                "label_fr": "Nieuwrode"
            },
            {
                "value": 59321,
                "label": "Gierle",
                "label_ar": "جيرل",
                "label_fr": "Gierle"
            },
            {
                "value": 59445,
                "label": "Butgenbach",
                "label_ar": "بوتجينباخ",
                "label_fr": "Butgenbach"
            },
            {
                "value": 59192,
                "label": "Liedekerke",
                "label_ar": "Liedekerke",
                "label_fr": "Liedekerke"
            },
            {
                "value": 59855,
                "label": "Stoumont",
                "label_ar": "ستومونت",
                "label_fr": "Stoumont"
            },
            {
                "value": 59910,
                "label": "Kanegem",
                "label_ar": "Kanegem",
                "label_fr": "Kanegem"
            },
            {
                "value": 59303,
                "label": "Merchtem",
                "label_ar": "Merchtem",
                "label_fr": "Merchtem"
            },
            {
                "value": 59524,
                "label": "Sint-Lambrechts-Herk",
                "label_ar": "سينت لامبرختس هيرك",
                "label_fr": "Sint-Lambrechts-Herk"
            },
            {
                "value": 58950,
                "label": "Zwijndrecht",
                "label_ar": "Zwijndrecht",
                "label_fr": "Zwijndrecht"
            },
            {
                "value": 59023,
                "label": "Sint-Martens-Latem",
                "label_ar": "سينت مارتنز لاتيم",
                "label_fr": "Sint-Martens-Latem"
            },
            {
                "value": 59124,
                "label": "Courcelles",
                "label_ar": "المحاكم",
                "label_fr": "Courcelles"
            },
            {
                "value": 59549,
                "label": "Pellenberg",
                "label_ar": "بيلينبرغ",
                "label_fr": "Pellenberg"
            },
            {
                "value": 59125,
                "label": "Jumet",
                "label_ar": "جوميت",
                "label_fr": "Jumet"
            },
            {
                "value": 59030,
                "label": "Bazel",
                "label_ar": "بازل",
                "label_fr": "Bazel"
            },
            {
                "value": 59913,
                "label": "Kortenaken",
                "label_ar": "كورتيناكن",
                "label_fr": "Kortenaken"
            },
            {
                "value": 59686,
                "label": "Knokke",
                "label_ar": "Knokke",
                "label_fr": "Knokke"
            },
            {
                "value": 59422,
                "label": "Hollain",
                "label_ar": "هولين",
                "label_fr": "Hollain"
            },
            {
                "value": 59841,
                "label": "Momalle",
                "label_ar": "مومال",
                "label_fr": "Momalle"
            },
            {
                "value": 59979,
                "label": "Nieuwpoort",
                "label_ar": "نيوبورت",
                "label_fr": "Nieuport"
            },
            {
                "value": 59724,
                "label": "Hermee",
                "label_ar": "هيرمي",
                "label_fr": "Hermee"
            },
            {
                "value": 58996,
                "label": "Everbeek",
                "label_ar": "ايفربيك",
                "label_fr": "Everbeek"
            },
            {
                "value": 59163,
                "label": "Ellezelles",
                "label_ar": "إليزيل",
                "label_fr": "Ellezelles"
            },
            {
                "value": 59840,
                "label": "Voroux-Goreux",
                "label_ar": "فوروكس جوروكس",
                "label_fr": "Voroux-Goreux"
            },
            {
                "value": 59471,
                "label": "Sankt Vith",
                "label_ar": "سانكت فيث",
                "label_fr": "Sankt Vith"
            },
            {
                "value": 59114,
                "label": "Chatelet",
                "label_ar": "شاتيليت",
                "label_fr": "Châtelet"
            },
            {
                "value": 59851,
                "label": "Carnieres",
                "label_ar": "كارنيريس",
                "label_fr": "Carnières"
            },
            {
                "value": 59405,
                "label": "Loppem",
                "label_ar": "لوبيم",
                "label_fr": "Loppem"
            },
            {
                "value": 59117,
                "label": "Falisolle",
                "label_ar": "فاليسول",
                "label_fr": "Falisolle"
            },
            {
                "value": 59868,
                "label": "Herinnes",
                "label_ar": "هيرينيس",
                "label_fr": "Herinnes"
            },
            {
                "value": 59298,
                "label": "Sterrebeek",
                "label_ar": "ستيريبيك",
                "label_fr": "Sterrebeek"
            },
            {
                "value": 59253,
                "label": "Aalter",
                "label_ar": "آلتر",
                "label_fr": "Aalter"
            },
            {
                "value": 58935,
                "label": "Ans",
                "label_ar": "الجواب",
                "label_fr": "Ans"
            },
            {
                "value": 59580,
                "label": "Kessel",
                "label_ar": "كيسيل",
                "label_fr": "Kessel"
            },
            {
                "value": 59766,
                "label": "Humbeek",
                "label_ar": "همبيك",
                "label_fr": "Humbeek"
            },
            {
                "value": 59967,
                "label": "Vladslo",
                "label_ar": "فلادسلو",
                "label_fr": "Vladslo"
            },
            {
                "value": 59230,
                "label": "Ekeren",
                "label_ar": "ايكيرين",
                "label_fr": "Ekeren"
            },
            {
                "value": 59443,
                "label": "Relegem",
                "label_ar": "ريليجيم",
                "label_fr": "Relegem"
            },
            {
                "value": 59788,
                "label": "Diegem",
                "label_ar": "ديجم",
                "label_fr": "Diegem"
            },
            {
                "value": 59437,
                "label": "Houdeng-Goegnies",
                "label_ar": "Houdeng-Goegnies",
                "label_fr": "Houdeng-Goegnies"
            },
            {
                "value": 59157,
                "label": "Meslin-l'Eveque",
                "label_ar": "Meslin-l'Eveque",
                "label_fr": "Meslin-l'Évêque"
            },
            {
                "value": 58966,
                "label": "Jette",
                "label_ar": "جيت",
                "label_fr": "Jette"
            },
            {
                "value": 59476,
                "label": "Genk",
                "label_ar": "جينك",
                "label_fr": "Genk"
            },
            {
                "value": 59347,
                "label": "Jabbeke",
                "label_ar": "جابيكي",
                "label_fr": "Jabbeke"
            },
            {
                "value": 59458,
                "label": "Tessenderlo",
                "label_ar": "تيسينديرلو",
                "label_fr": "Tessenderlo"
            },
            {
                "value": 59479,
                "label": "Opglabbeek",
                "label_ar": "أوبجلبيك",
                "label_fr": "Opglabbeek"
            },
            {
                "value": 59612,
                "label": "Amay",
                "label_ar": "أماي",
                "label_fr": "Amay"
            },
            {
                "value": 59364,
                "label": "Nameche",
                "label_ar": "ناميشي",
                "label_fr": "Nameche"
            },
            {
                "value": 59046,
                "label": "Sint-Lievens-Houtem",
                "label_ar": "سينت ليفينز حوتيم",
                "label_fr": "Sint-Lievens-Houtem"
            },
            {
                "value": 59237,
                "label": "Izegem",
                "label_ar": "Izegem",
                "label_fr": "Izegem"
            },
            {
                "value": 59557,
                "label": "Schulen",
                "label_ar": "شولين",
                "label_fr": "Schulen"
            },
            {
                "value": 58986,
                "label": "Nederhasselt",
                "label_ar": "نديرهاسلت",
                "label_fr": "Nederhasselt"
            },
            {
                "value": 59814,
                "label": "Korbeek-Lo",
                "label_ar": "كوربيك لو",
                "label_fr": "Korbeek-Lo"
            },
            {
                "value": 59848,
                "label": "Dalhem",
                "label_ar": "دلهيم",
                "label_fr": "Dalhem"
            },
            {
                "value": 58953,
                "label": "Hasselt",
                "label_ar": "هاسيلت",
                "label_fr": "Hasselt"
            },
            {
                "value": 59024,
                "label": "Kruibeke",
                "label_ar": "كروبيك",
                "label_fr": "Kruibeke"
            },
            {
                "value": 59246,
                "label": "Alken",
                "label_ar": "ألكين",
                "label_fr": "Alken"
            },
            {
                "value": 59872,
                "label": "Rode",
                "label_ar": "ركب",
                "label_fr": "Rode"
            },
            {
                "value": 59531,
                "label": "Averbode",
                "label_ar": "Averbode",
                "label_fr": "Averbode"
            },
            {
                "value": 59001,
                "label": "Zottegem",
                "label_ar": "زوتيجيم",
                "label_fr": "Zottegem"
            },
            {
                "value": 59387,
                "label": "Bousval",
                "label_ar": "بوسفال",
                "label_fr": "Bousval"
            },
            {
                "value": 59639,
                "label": "Quevaucamps",
                "label_ar": "Quevaucamps",
                "label_fr": "Quevaucamps"
            },
            {
                "value": 59728,
                "label": "Leerbeek",
                "label_ar": "لييربيك",
                "label_fr": "Leerbeek"
            },
            {
                "value": 59994,
                "label": "Lendelede",
                "label_ar": "لينديليد",
                "label_fr": "Lendelede"
            },
            {
                "value": 59107,
                "label": "Ham-sur-Heure",
                "label_ar": "هام سور هيور",
                "label_fr": "Ham-sur-Heure"
            },
            {
                "value": 59300,
                "label": "Beigem",
                "label_ar": "بيجيم",
                "label_fr": "Beigem"
            },
            {
                "value": 59169,
                "label": "Etalle",
                "label_ar": "إتال",
                "label_fr": "Etalle"
            },
            {
                "value": 59539,
                "label": "Melkwezer",
                "label_ar": "ملكفايزر",
                "label_fr": "Melkwezer"
            },
            {
                "value": 59514,
                "label": "Attenhoven",
                "label_ar": "أتنهوفن",
                "label_fr": "Attenhoven"
            },
            {
                "value": 59712,
                "label": "Belle Fontaine",
                "label_ar": "بيل فونتين",
                "label_fr": "Belle Fontaine"
            },
            {
                "value": 59821,
                "label": "Bevel",
                "label_ar": "شطبة",
                "label_fr": "Biseau"
            },
            {
                "value": 59630,
                "label": "Isnes",
                "label_ar": "إيسنس",
                "label_fr": "Isnes"
            },
            {
                "value": 59488,
                "label": "Hoeselt",
                "label_ar": "هوسيلت",
                "label_fr": "Hoeselt"
            },
            {
                "value": 60050,
                "label": "Lier",
                "label_ar": "لير",
                "label_fr": "Lier"
            },
            {
                "value": 59839,
                "label": "Assesse",
                "label_ar": "تقييم",
                "label_fr": "Assesse"
            },
            {
                "value": 59113,
                "label": "Jemelle",
                "label_ar": "جيميل",
                "label_fr": "Jemelle"
            },
            {
                "value": 59036,
                "label": "Schellebelle",
                "label_ar": "شيلبيل",
                "label_fr": "Schellebelle"
            },
            {
                "value": 59566,
                "label": "Vliermaal",
                "label_ar": "فليرمال",
                "label_fr": "Vliermaal"
            },
            {
                "value": 59188,
                "label": "Transinne",
                "label_ar": "ترانسين",
                "label_fr": "Transinne"
            },
            {
                "value": 59207,
                "label": "Bonheiden",
                "label_ar": "Bonheiden",
                "label_fr": "Bonheiden"
            },
            {
                "value": 59515,
                "label": "Landen",
                "label_ar": "لاندين",
                "label_fr": "Landen"
            },
            {
                "value": 59633,
                "label": "Braine-l'Alleud",
                "label_ar": "براين لالود",
                "label_fr": "Braine-l'Alleud"
            },
            {
                "value": 58910,
                "label": "Antwerp",
                "label_ar": "أنتويرب",
                "label_fr": "Anvers"
            },
            {
                "value": 59320,
                "label": "Lille",
                "label_ar": "ليل",
                "label_fr": "Lille"
            },
            {
                "value": 58962,
                "label": "Linkebeek",
                "label_ar": "لينكبيك",
                "label_fr": "Linkebeek"
            },
            {
                "value": 59389,
                "label": "Lommel",
                "label_ar": "لوميل",
                "label_fr": "Lommel"
            },
            {
                "value": 59599,
                "label": "Flemalle-Grande",
                "label_ar": "فليمال غراندي",
                "label_fr": "Flemalle-Grande"
            },
            {
                "value": 59804,
                "label": "Bertem",
                "label_ar": "بيرتيم",
                "label_fr": "Bertem"
            },
            {
                "value": 59293,
                "label": "Beerzel",
                "label_ar": "بيرزل",
                "label_fr": "Beerzel"
            },
            {
                "value": 59353,
                "label": "Moorslede",
                "label_ar": "مورسليد",
                "label_fr": "Moorslede"
            },
            {
                "value": 59128,
                "label": "Leval-Trahegnies",
                "label_ar": "ليفال تراينييس",
                "label_fr": "Leval-Trahegnies"
            },
            {
                "value": 59112,
                "label": "Rochefort",
                "label_ar": "روشيفورت",
                "label_fr": "Rochefort"
            },
            {
                "value": 59948,
                "label": "Elverdinge",
                "label_ar": "إلفردنج",
                "label_fr": "Elverdinge"
            },
            {
                "value": 59402,
                "label": "Grivegnee",
                "label_ar": "Grivegnee",
                "label_fr": "Grivegnee"
            },
            {
                "value": 60056,
                "label": "Poelkapelle",
                "label_ar": "Poelkapelle",
                "label_fr": "Poelkapelle"
            },
            {
                "value": 59431,
                "label": "Nazareth",
                "label_ar": "الناصرة",
                "label_fr": "Nazareth"
            },
            {
                "value": 59070,
                "label": "Appels",
                "label_ar": "أبلس",
                "label_fr": "Appels"
            },
            {
                "value": 59182,
                "label": "Wezembeek-Oppem",
                "label_ar": "Wezembeek-Oppem",
                "label_fr": "Wezembeek-Oppem"
            },
            {
                "value": 59528,
                "label": "Hoeleden",
                "label_ar": "Hoeleden",
                "label_fr": "Hoeleden"
            },
            {
                "value": 59266,
                "label": "Ravels",
                "label_ar": "رافلز",
                "label_fr": "Ravels"
            },
            {
                "value": 59852,
                "label": "Ophain",
                "label_ar": "أوفين",
                "label_fr": "Ophain"
            },
            {
                "value": 59577,
                "label": "Hoogstraten",
                "label_ar": "Hoogstraten",
                "label_fr": "Hoogstraten"
            },
            {
                "value": 59351,
                "label": "Lovendegem",
                "label_ar": "لوفينديجم",
                "label_fr": "Lovendegem"
            },
            {
                "value": 59489,
                "label": "Riemst",
                "label_ar": "ريمست",
                "label_fr": "Riemst"
            },
            {
                "value": 59817,
                "label": "Broechem",
                "label_ar": "بروشيم",
                "label_fr": "Broechem"
            },
            {
                "value": 59664,
                "label": "Aywaille",
                "label_ar": "أيويل",
                "label_fr": "Aywaille"
            },
            {
                "value": 59974,
                "label": "Oostvleteren",
                "label_ar": "أوستفليتيرين",
                "label_fr": "Oostvleteren"
            },
            {
                "value": 59779,
                "label": "Ways",
                "label_ar": "طرق",
                "label_fr": "Façons"
            },
            {
                "value": 59718,
                "label": "Coret",
                "label_ar": "كوريت",
                "label_fr": "Coret"
            },
            {
                "value": 59716,
                "label": "Baudour",
                "label_ar": "بودور",
                "label_fr": "Baudour"
            },
            {
                "value": 59153,
                "label": "Tubize",
                "label_ar": "توبيز",
                "label_fr": "Tubize"
            },
            {
                "value": 59622,
                "label": "Luttre",
                "label_ar": "لوتر",
                "label_fr": "Luttre"
            },
            {
                "value": 59676,
                "label": "Donceel",
                "label_ar": "دونسيل",
                "label_fr": "Donceel"
            },
            {
                "value": 59438,
                "label": "Cuesmes",
                "label_ar": "العظة",
                "label_fr": "Cuesmes"
            },
            {
                "value": 59122,
                "label": "Trazegnies",
                "label_ar": "Trazegnies",
                "label_fr": "Trazegnies"
            },
            {
                "value": 59248,
                "label": "Overpelt",
                "label_ar": "overpelt",
                "label_fr": "Overpelt"
            },
            {
                "value": 59871,
                "label": "Tongre-Notre-Dame",
                "label_ar": "تونجري نوتردام",
                "label_fr": "Tongre-Notre-Dame"
            },
            {
                "value": 59942,
                "label": "Bovekerke",
                "label_ar": "بوفكيركي",
                "label_fr": "Bovekerke"
            },
            {
                "value": 59605,
                "label": "Saint-Nicolas",
                "label_ar": "القديس نيكولاس",
                "label_fr": "Saint-Nicolas"
            },
            {
                "value": 59480,
                "label": "Kinrooi",
                "label_ar": "كينروي",
                "label_fr": "Kinrooi"
            },
            {
                "value": 59444,
                "label": "Andrimont",
                "label_ar": "أندريمونت",
                "label_fr": "Andrimont"
            },
            {
                "value": 59998,
                "label": "Overheide",
                "label_ar": "يفرط",
                "label_fr": "Overheide"
            },
            {
                "value": 59802,
                "label": "Everberg",
                "label_ar": "ايفربيرج",
                "label_fr": "Everberg"
            },
            {
                "value": 60049,
                "label": "Houtvenne",
                "label_ar": "هوتفين",
                "label_fr": "Houtvenne"
            },
            {
                "value": 59126,
                "label": "Haine-Saint-Paul",
                "label_ar": "هاين سانت بول",
                "label_fr": "Haine-Saint-Paul"
            },
            {
                "value": 59383,
                "label": "Court-Saint-Etienne",
                "label_ar": "محكمة سانت إتيان",
                "label_fr": "Court-Saint-Étienne"
            },
            {
                "value": 58951,
                "label": "Merksem",
                "label_ar": "Merksem",
                "label_fr": "Merksem"
            },
            {
                "value": 58925,
                "label": "Hollogne",
                "label_ar": "هولوجن",
                "label_fr": "Hollogne"
            },
            {
                "value": 59191,
                "label": "Brecht",
                "label_ar": "بريشت",
                "label_fr": "Brecht"
            },
            {
                "value": 58914,
                "label": "Namur",
                "label_ar": "نامور",
                "label_fr": "Namur"
            },
            {
                "value": 59643,
                "label": "Saint-Servais",
                "label_ar": "سانت سيرفيه",
                "label_fr": "Saint-Servais"
            },
            {
                "value": 59982,
                "label": "Pervijze",
                "label_ar": "برفيجز",
                "label_fr": "Pervijze"
            },
            {
                "value": 59174,
                "label": "Andenne",
                "label_ar": "أندين",
                "label_fr": "Andenne"
            },
            {
                "value": 59209,
                "label": "Nijlen",
                "label_ar": "نيجلن",
                "label_fr": "Nijlen"
            },
            {
                "value": 59224,
                "label": "Itegem",
                "label_ar": "Itegem",
                "label_fr": "Itegem"
            },
            {
                "value": 60048,
                "label": "Heffen",
                "label_ar": "هيفين",
                "label_fr": "Heffen"
            },
            {
                "value": 58927,
                "label": "Crisnee",
                "label_ar": "كريسني",
                "label_fr": "Crisnee"
            },
            {
                "value": 59349,
                "label": "Nevele",
                "label_ar": "نيفيل",
                "label_fr": "Nevele"
            },
            {
                "value": 59983,
                "label": "Desselgem",
                "label_ar": "ديسيلجم",
                "label_fr": "Desselgem"
            },
            {
                "value": 59067,
                "label": "Deurle",
                "label_ar": "ديورلي",
                "label_fr": "Deurle"
            },
            {
                "value": 59342,
                "label": "Westende",
                "label_ar": "Westende",
                "label_fr": "Westende"
            },
            {
                "value": 59442,
                "label": "Haut-Ittre",
                "label_ar": "أوت إتر",
                "label_fr": "Haut-Ittre"
            },
            {
                "value": 58994,
                "label": "Nieuwenhove",
                "label_ar": "Nieuwenhove",
                "label_fr": "Nieuwenhove"
            },
            {
                "value": 59966,
                "label": "Lichtervelde",
                "label_ar": "Lichtervelde",
                "label_fr": "Lichtervelde"
            },
            {
                "value": 59075,
                "label": "Sint-Denijs-Westrem",
                "label_ar": "سينت دنيج ويسترم",
                "label_fr": "Sint-Denijs-Westrem"
            },
            {
                "value": 59265,
                "label": "Pulderbos",
                "label_ar": "بولدربوس",
                "label_fr": "Pulderbos"
            },
            {
                "value": 59181,
                "label": "Romsee",
                "label_ar": "رومسي",
                "label_fr": "Romsee"
            },
            {
                "value": 59334,
                "label": "Anzegem",
                "label_ar": "أنزيجم",
                "label_fr": "Anzegem"
            },
            {
                "value": 59460,
                "label": "Henri-Chapelle",
                "label_ar": "هنري شابيل",
                "label_fr": "Henri-Chapelle"
            },
            {
                "value": 59610,
                "label": "Saint-Remy",
                "label_ar": "سانت ريمي",
                "label_fr": "Saint-Rémy"
            },
            {
                "value": 59305,
                "label": "Herne",
                "label_ar": "هيرن",
                "label_fr": "Herne"
            },
            {
                "value": 58946,
                "label": "Ghent",
                "label_ar": "غينت",
                "label_fr": "Gand"
            },
            {
                "value": 59284,
                "label": "Herenthout",
                "label_ar": "هيرنثوت",
                "label_fr": "Herenthout"
            },
            {
                "value": 59148,
                "label": "Ganshoren",
                "label_ar": "غانشورين",
                "label_fr": "Ganshoren"
            },
            {
                "value": 59662,
                "label": "Vedrin",
                "label_ar": "فيدرين",
                "label_fr": "Védrin"
            },
            {
                "value": 59954,
                "label": "Ooigem",
                "label_ar": "أويجيم",
                "label_fr": "Ooigem"
            },
            {
                "value": 59401,
                "label": "Montegnee",
                "label_ar": "مونتيني",
                "label_fr": "Montegnee"
            },
            {
                "value": 59677,
                "label": "Chaudfontaine",
                "label_ar": "شودفونتين",
                "label_fr": "Chaudfontaine"
            },
            {
                "value": 59462,
                "label": "Haccourt",
                "label_ar": "حاكورت",
                "label_fr": "Haccourt"
            },
            {
                "value": 59290,
                "label": "Morkhoven",
                "label_ar": "موركهوفن",
                "label_fr": "Morkhoven"
            },
            {
                "value": 59700,
                "label": "Florenville",
                "label_ar": "فلورينفيل",
                "label_fr": "Florenville"
            },
            {
                "value": 59380,
                "label": "Chercq",
                "label_ar": "شركق",
                "label_fr": "Chercq"
            },
            {
                "value": 59090,
                "label": "Landelies",
                "label_ar": "لانديليز",
                "label_fr": "Landelies"
            },
            {
                "value": 60037,
                "label": "Beloeil",
                "label_ar": "بيلويل",
                "label_fr": "Beloeil"
            },
            {
                "value": 59753,
                "label": "Mont-Sainte-Aldegonde",
                "label_ar": "مونت سانت ألديغوندي",
                "label_fr": "Mont-Sainte-Aldegonde"
            },
            {
                "value": 60022,
                "label": "Ardooie",
                "label_ar": "Ardooie",
                "label_fr": "Ardooie"
            },
            {
                "value": 60055,
                "label": "Dranouter",
                "label_ar": "Dranouter",
                "label_fr": "Dranouter"
            },
            {
                "value": 59883,
                "label": "Lippelo",
                "label_ar": "ليبلو",
                "label_fr": "Lippelo"
            },
            {
                "value": 59586,
                "label": "Hallaar",
                "label_ar": "هالار",
                "label_fr": "Hallaar"
            },
            {
                "value": 59161,
                "label": "Barry",
                "label_ar": "باري",
                "label_fr": "Barry"
            },
            {
                "value": 59962,
                "label": "Comines",
                "label_ar": "كومينز",
                "label_fr": "Comines"
            },
            {
                "value": 59529,
                "label": "Blanden",
                "label_ar": "بلاندن",
                "label_fr": "Blanden"
            },
            {
                "value": 59412,
                "label": "Landegem",
                "label_ar": "Landegem",
                "label_fr": "Landegem"
            },
            {
                "value": 59770,
                "label": "Ertvelde",
                "label_ar": "إرتفلدي",
                "label_fr": "Ertvelde"
            },
            {
                "value": 59847,
                "label": "Nouvelles",
                "label_ar": "نوفيل",
                "label_fr": "Nouvelles"
            },
            {
                "value": 60044,
                "label": "Arquennes",
                "label_ar": "Arquennes",
                "label_fr": "Arquennes"
            },
            {
                "value": 59865,
                "label": "Gages",
                "label_ar": "عربات",
                "label_fr": "Jauges"
            },
            {
                "value": 59129,
                "label": "Hyon",
                "label_ar": "هيون",
                "label_fr": "Hyon"
            },
            {
                "value": 59368,
                "label": "Ittre",
                "label_ar": "Ittre",
                "label_fr": "Ittre"
            },
            {
                "value": 59501,
                "label": "Heers",
                "label_ar": "هيرز",
                "label_fr": "Heers"
            },
            {
                "value": 60047,
                "label": "Marche-lez-Ecaussinnes",
                "label_ar": "Marche-lez-Ecaussinnes",
                "label_fr": "Marche-lez-Ecaussinnes"
            },
            {
                "value": 59408,
                "label": "Warneton",
                "label_ar": "وارنتون",
                "label_fr": "Warneton"
            },
            {
                "value": 59359,
                "label": "Wingene",
                "label_ar": "وينجين",
                "label_fr": "Wingene"
            },
            {
                "value": 59571,
                "label": "Poederlee",
                "label_ar": "بوديرلي",
                "label_fr": "Poederlee"
            },
            {
                "value": 59559,
                "label": "Kwaadmechelen",
                "label_ar": "كوادميشيلين",
                "label_fr": "Kwaadmechelen"
            },
            {
                "value": 59274,
                "label": "Tisselt",
                "label_ar": "تيسيلت",
                "label_fr": "Tisselt"
            },
            {
                "value": 59564,
                "label": "Vliermaalroot",
                "label_ar": "فليرمالروت",
                "label_fr": "Vliermaalroot"
            },
            {
                "value": 59083,
                "label": "Beervelde",
                "label_ar": "بيرفيلدي",
                "label_fr": "Beervelde"
            },
            {
                "value": 59800,
                "label": "Vossem",
                "label_ar": "فوسيم",
                "label_fr": "Vossem"
            },
            {
                "value": 59050,
                "label": "Asse",
                "label_ar": "آسي",
                "label_fr": "Asse"
            },
            {
                "value": 59560,
                "label": "Ham",
                "label_ar": "لحم خنزير",
                "label_fr": "jambon"
            },
            {
                "value": 59787,
                "label": "Ledeberg",
                "label_ar": "ليديبرغ",
                "label_fr": "Ledeberg"
            },
            {
                "value": 59785,
                "label": "Colfontaine",
                "label_ar": "كولفونتين",
                "label_fr": "Colfontaine"
            },
            {
                "value": 59625,
                "label": "Auvelais",
                "label_ar": "أوفيليا",
                "label_fr": "Auvelais"
            },
            {
                "value": 59943,
                "label": "Koekelare",
                "label_ar": "Koekelare",
                "label_fr": "Koekelare"
            },
            {
                "value": 59905,
                "label": "Poperinge",
                "label_ar": "بوبرينج",
                "label_fr": "Poperinge"
            },
            {
                "value": 59626,
                "label": "Pont-de-Loup",
                "label_ar": "بونت دي لوب",
                "label_fr": "Pont-de-Loup"
            },
            {
                "value": 59250,
                "label": "Bilzen",
                "label_ar": "بيلزن",
                "label_fr": "Bilzen"
            },
            {
                "value": 59257,
                "label": "Deinze",
                "label_ar": "دينز",
                "label_fr": "Deinze"
            },
            {
                "value": 59164,
                "label": "Dison",
                "label_ar": "ديسون",
                "label_fr": "Dison"
            },
            {
                "value": 59733,
                "label": "Ottignies",
                "label_ar": "العثمانيين",
                "label_fr": "Ottignies"
            },
            {
                "value": 59598,
                "label": "Braives",
                "label_ar": "الشجعان",
                "label_fr": "Braives"
            },
            {
                "value": 59996,
                "label": "Pittem",
                "label_ar": "بيتيم",
                "label_fr": "Pittem"
            },
            {
                "value": 59228,
                "label": "Schoten",
                "label_ar": "شوتن",
                "label_fr": "Schoten"
            },
            {
                "value": 59771,
                "label": "Agimont",
                "label_ar": "أجيمونت",
                "label_fr": "Agimont"
            },
            {
                "value": 58998,
                "label": "Temse",
                "label_ar": "تيمس",
                "label_fr": "Temse"
            },
            {
                "value": 59393,
                "label": "Ciney",
                "label_ar": "سيني",
                "label_fr": "Ciney"
            },
            {
                "value": 60009,
                "label": "Sint-Pieters op de Dijk",
                "label_ar": "Sint-Pieters op de Dijk",
                "label_fr": "Sint-Pieters op de Dijk"
            },
            {
                "value": 59406,
                "label": "Ronse",
                "label_ar": "رونس",
                "label_fr": "Renaix"
            },
            {
                "value": 59783,
                "label": "Hooglede",
                "label_ar": "Hooglede",
                "label_fr": "Hooglede"
            },
            {
                "value": 59641,
                "label": "Bovesse",
                "label_ar": "بوفيس",
                "label_fr": "Bovesse"
            },
            {
                "value": 59803,
                "label": "Leefdaal",
                "label_ar": "ليفدال",
                "label_fr": "Leefdaal"
            },
            {
                "value": 59335,
                "label": "Ingooigem",
                "label_ar": "Ingooigem",
                "label_fr": "Ingooigem"
            },
            {
                "value": 58967,
                "label": "Ixelles-Elsene",
                "label_ar": "Ixelles-Elsene",
                "label_fr": "Ixelles-Elsène"
            },
            {
                "value": 59764,
                "label": "Eernegem",
                "label_ar": "إيرنيجم",
                "label_fr": "Eernegem"
            },
            {
                "value": 59988,
                "label": "Otegem",
                "label_ar": "Otegem",
                "label_fr": "Otegem"
            },
            {
                "value": 60030,
                "label": "Impe",
                "label_ar": "إيمبي",
                "label_fr": "Impe"
            },
            {
                "value": 59587,
                "label": "Loenhout",
                "label_ar": "Loenhout",
                "label_fr": "Loenhout"
            },
            {
                "value": 59819,
                "label": "Ramsdonk",
                "label_ar": "رامسدونك",
                "label_fr": "Ramsdonk"
            },
            {
                "value": 59592,
                "label": "Blankenberge",
                "label_ar": "بلانكنبرج",
                "label_fr": "Blankenberge"
            },
            {
                "value": 59898,
                "label": "Zingem",
                "label_ar": "زينجيم",
                "label_fr": "Zingem"
            },
            {
                "value": 59262,
                "label": "Ranst",
                "label_ar": "رانست",
                "label_fr": "Ranst"
            },
            {
                "value": 59053,
                "label": "Vlekkem",
                "label_ar": "فليكيم",
                "label_fr": "Vlekkem"
            },
            {
                "value": 59466,
                "label": "Kessel-Lo",
                "label_ar": "كيسيل لو",
                "label_fr": "Kessel-Lo"
            },
            {
                "value": 59675,
                "label": "Blegny",
                "label_ar": "بليغني",
                "label_fr": "Blegny"
            },
            {
                "value": 59426,
                "label": "Incourt",
                "label_ar": "في المحكمة",
                "label_fr": "En cour"
            },
            {
                "value": 59525,
                "label": "Boorsem",
                "label_ar": "بورسم",
                "label_fr": "Boorsem"
            },
            {
                "value": 59903,
                "label": "Menen",
                "label_ar": "مينين",
                "label_fr": "Menin"
            },
            {
                "value": 59382,
                "label": "Chaumont-Gistoux",
                "label_ar": "شومون جيستوكس",
                "label_fr": "Chaumont-Gistoux"
            },
            {
                "value": 59436,
                "label": "Saint-Symphorien",
                "label_ar": "سانت سيمفوريان",
                "label_fr": "Saint-Symphorien"
            },
            {
                "value": 59100,
                "label": "Charleroi",
                "label_ar": "شارلروا",
                "label_fr": "Charleroi"
            },
            {
                "value": 59735,
                "label": "Hornu",
                "label_ar": "هورنو",
                "label_fr": "Hornu"
            },
            {
                "value": 59077,
                "label": "Erondegem",
                "label_ar": "ارونديجيم",
                "label_fr": "Erondegem"
            },
            {
                "value": 59527,
                "label": "Neerlinter",
                "label_ar": "نيرلينتر",
                "label_fr": "Neerlinter"
            },
            {
                "value": 59693,
                "label": "Koksijde",
                "label_ar": "كوكسيجدي",
                "label_fr": "Koksijde"
            },
            {
                "value": 59227,
                "label": "Wiekevorst",
                "label_ar": "ويكيفورست",
                "label_fr": "Wiekevorst"
            },
            {
                "value": 58922,
                "label": "Hannut",
                "label_ar": "حنوت",
                "label_fr": "Hannut"
            },
            {
                "value": 59831,
                "label": "Beauvechain",
                "label_ar": "Beauvechain",
                "label_fr": "Beauvechain"
            },
            {
                "value": 59714,
                "label": "Saint-Ghislain",
                "label_ar": "سان غيسلان",
                "label_fr": "Saint-Ghislain"
            },
            {
                "value": 59857,
                "label": "Ensival",
                "label_ar": "إنزيفال",
                "label_fr": "Ensival"
            },
            {
                "value": 59512,
                "label": "Geetbets",
                "label_ar": "Geetbets",
                "label_fr": "Geetbets"
            },
            {
                "value": 59218,
                "label": "Westerlo",
                "label_ar": "ويستيرلو",
                "label_fr": "Westerlo"
            },
            {
                "value": 59135,
                "label": "Dilbeek",
                "label_ar": "دلبيك",
                "label_fr": "Dilbeek"
            },
            {
                "value": 59110,
                "label": "Tamines",
                "label_ar": "تامينس",
                "label_fr": "Tamines"
            },
            {
                "value": 58940,
                "label": "Magnee",
                "label_ar": "ماجني",
                "label_fr": "Magnee"
            },
            {
                "value": 59051,
                "label": "Hofstade",
                "label_ar": "هوفستاد",
                "label_fr": "Hofstade"
            },
            {
                "value": 59739,
                "label": "Jurbise",
                "label_ar": "جربس",
                "label_fr": "Jurbise"
            },
            {
                "value": 59526,
                "label": "Gruitrode",
                "label_ar": "Gruitrode",
                "label_fr": "Gruitrode"
            },
            {
                "value": 59048,
                "label": "Nieuwerkerken",
                "label_ar": "Nieuwerkerken",
                "label_fr": "Nieuwerkerken"
            },
            {
                "value": 59896,
                "label": "Mere",
                "label_ar": "مجرد",
                "label_fr": "Simple"
            },
            {
                "value": 59031,
                "label": "Steendorp",
                "label_ar": "ستيندورب",
                "label_fr": "Steendorp"
            },
            {
                "value": 59008,
                "label": "Londerzeel",
                "label_ar": "لوندرزيل",
                "label_fr": "Londerzeel"
            },
            {
                "value": 60061,
                "label": "Freylange",
                "label_ar": "فريلانج",
                "label_fr": "Freylange"
            },
            {
                "value": 59447,
                "label": "Clavier",
                "label_ar": "كلافير",
                "label_fr": "Clavier"
            },
            {
                "value": 59755,
                "label": "Maisieres",
                "label_ar": "مايسيريس",
                "label_fr": "Maisières"
            },
            {
                "value": 59742,
                "label": "Watermael-Boitsfort",
                "label_ar": "Watermael-Boitsfort",
                "label_fr": "Watermael-Boitsfort"
            },
            {
                "value": 59767,
                "label": "Sart-lez-Spa",
                "label_ar": "سارت ليز سبا",
                "label_fr": "Sart-lez-Spa"
            },
            {
                "value": 59173,
                "label": "Verviers",
                "label_ar": "فيرفييرز",
                "label_fr": "Verviers"
            },
            {
                "value": 59642,
                "label": "Malonne",
                "label_ar": "مالون",
                "label_fr": "Malonne"
            },
            {
                "value": 59696,
                "label": "Vaux-sous-Chevremont",
                "label_ar": "فو سو شيفريمونت",
                "label_fr": "Vaux-sous-Chevremont"
            },
            {
                "value": 58944,
                "label": "Holland",
                "label_ar": "الهولندي",
                "label_fr": "Hollande"
            },
            {
                "value": 59384,
                "label": "Nodebais",
                "label_ar": "Nodebais",
                "label_fr": "Nodebais"
            },
            {
                "value": 59123,
                "label": "Marchienne-au-Pont",
                "label_ar": "مارشين أو بونت",
                "label_fr": "Marchienne-au-Pont"
            },
            {
                "value": 59104,
                "label": "Labuissiere",
                "label_ar": "لابويسير",
                "label_fr": "Labuissière"
            },
            {
                "value": 59289,
                "label": "Herentals",
                "label_ar": "Herentals",
                "label_fr": "Herentals"
            },
            {
                "value": 59629,
                "label": "Fontaine-l'Eveque",
                "label_ar": "فونتين لافيك",
                "label_fr": "Fontaine-l'Évêque"
            },
            {
                "value": 59920,
                "label": "Tilleur",
                "label_ar": "تيلور",
                "label_fr": "Tilleur"
            },
            {
                "value": 59603,
                "label": "Neuville-en-Condroz",
                "label_ar": "نيوفيل أون كوندروز",
                "label_fr": "Neuville-en-Condroz"
            },
            {
                "value": 59041,
                "label": "Sint-Amands",
                "label_ar": "سينت اماندز",
                "label_fr": "Sint-Amands"
            },
            {
                "value": 59358,
                "label": "Adegem",
                "label_ar": "Adegem",
                "label_fr": "Adegem"
            },
            {
                "value": 59374,
                "label": "Kain",
                "label_ar": "كين",
                "label_fr": "Kain"
            },
            {
                "value": 59601,
                "label": "Vivegnis",
                "label_ar": "فيفيجنيس",
                "label_fr": "Vivegnis"
            },
            {
                "value": 59635,
                "label": "Herve",
                "label_ar": "هيرفيه",
                "label_fr": "Hervé"
            },
            {
                "value": 59127,
                "label": "Peronnes",
                "label_ar": "بيرونيس",
                "label_fr": "Péronnes"
            },
            {
                "value": 59372,
                "label": "La Louvière",
                "label_ar": "لا لوفيير",
                "label_fr": "La Louvière"
            },
            {
                "value": 59102,
                "label": "Lodelinsart",
                "label_ar": "لوديلينسارت",
                "label_fr": "Lodelinsart"
            },
            {
                "value": 59552,
                "label": "Stevoort",
                "label_ar": "Stevoort",
                "label_fr": "Stevoort"
            },
            {
                "value": 59345,
                "label": "Ledegem",
                "label_ar": "ليدجيم",
                "label_fr": "Ledegem"
            },
            {
                "value": 59736,
                "label": "Dour",
                "label_ar": "عنيد",
                "label_fr": "Austère"
            },
            {
                "value": 58941,
                "label": "Oupeye",
                "label_ar": "أوباي",
                "label_fr": "Oupeye"
            },
            {
                "value": 59377,
                "label": "Jemeppe-sur-Sambre",
                "label_ar": "جميب سور سامبر",
                "label_fr": "Jemeppe-sur-Sambre"
            },
            {
                "value": 59159,
                "label": "Frasnes-lez-Buissenal",
                "label_ar": "فراسنس ليز بويسينال",
                "label_fr": "Frasnes-lez-Buissenal"
            },
            {
                "value": 58948,
                "label": "Heverlee",
                "label_ar": "هيفيرلي",
                "label_fr": "Heverlee"
            },
            {
                "value": 58926,
                "label": "Hognoul",
                "label_ar": "هونول",
                "label_fr": "Hognoul"
            },
            {
                "value": 59146,
                "label": "Meise",
                "label_ar": "ميز",
                "label_fr": "Meise"
            },
            {
                "value": 59343,
                "label": "Ostend",
                "label_ar": "اوستند",
                "label_fr": "Ostende"
            },
            {
                "value": 59130,
                "label": "Gosselies",
                "label_ar": "جوسيليس",
                "label_fr": "Gosselies"
            },
            {
                "value": 59553,
                "label": "Kortessem",
                "label_ar": "كورتسيم",
                "label_fr": "Kortessem"
            },
            {
                "value": 59434,
                "label": "Fayt-lez-Manage",
                "label_ar": "فايت ليز إدارة",
                "label_fr": "Fayt-lez-Manage"
            },
            {
                "value": 59143,
                "label": "Erps-Kwerps",
                "label_ar": "Erps-Kwerps",
                "label_fr": "Erps-Kwerps"
            },
            {
                "value": 59162,
                "label": "Montignies-lez-Lens",
                "label_ar": "مونتينيز ليز لينس",
                "label_fr": "Montignies-lez-Lens"
            },
            {
                "value": 59285,
                "label": "Berlaar",
                "label_ar": "بيرلار",
                "label_fr": "Berlaar"
            },
            {
                "value": 59500,
                "label": "Klein-Gelmen",
                "label_ar": "كلاين جيلمن",
                "label_fr": "Klein-Gelmen"
            },
            {
                "value": 59768,
                "label": "Ciplet",
                "label_ar": "سيبليت",
                "label_fr": "Ciplet"
            },
            {
                "value": 59499,
                "label": "Nieuwerkerken",
                "label_ar": "Nieuwerkerken",
                "label_fr": "Nieuwerkerken"
            },
            {
                "value": 60042,
                "label": "Neder-Over-Heembeek",
                "label_ar": "نيدر أوفر هيمبيك",
                "label_fr": "Neder-Over-Heembeek"
            },
            {
                "value": 59699,
                "label": "Flawinne",
                "label_ar": "Flawinne",
                "label_fr": "Flawinne"
            },
            {
                "value": 59859,
                "label": "Louveigne",
                "label_ar": "لوفين",
                "label_fr": "Louveigne"
            },
            {
                "value": 59027,
                "label": "Oostakker",
                "label_ar": "Oostakker",
                "label_fr": "Oostakker"
            },
            {
                "value": 59369,
                "label": "Chievres",
                "label_ar": "كييفريس",
                "label_fr": "Chièvres"
            },
            {
                "value": 59914,
                "label": "Rummen",
                "label_ar": "رومين",
                "label_fr": "Rummen"
            },
            {
                "value": 58955,
                "label": "Wemmel",
                "label_ar": "ويميل",
                "label_fr": "Wemmel"
            },
            {
                "value": 59432,
                "label": "Estaimpuis",
                "label_ar": "إستيمبويس",
                "label_fr": "Estaimpuis"
            },
            {
                "value": 59215,
                "label": "Willebroek",
                "label_ar": "ويلبروك",
                "label_fr": "Willebroek"
            },
            {
                "value": 59875,
                "label": "Sleidinge",
                "label_ar": "سليدينج",
                "label_fr": "Sleidinge"
            },
            {
                "value": 59661,
                "label": "Bressoux",
                "label_ar": "بريسوكس",
                "label_fr": "Bressoux"
            },
            {
                "value": 60036,
                "label": "Lobbes",
                "label_ar": "لوبز",
                "label_fr": "Lobbes"
            },
            {
                "value": 59811,
                "label": "Ruisbroek",
                "label_ar": "رويسبروك",
                "label_fr": "Ruisbroek"
            },
            {
                "value": 59936,
                "label": "Vinderhoute",
                "label_ar": "Vinderhoute",
                "label_fr": "Vinderhoute"
            },
            {
                "value": 59199,
                "label": "Borsbeek",
                "label_ar": "بورسبيك",
                "label_fr": "Borsbeek"
            },
            {
                "value": 59168,
                "label": "Jambes",
                "label_ar": "جامبيس",
                "label_fr": "Jambes"
            },
            {
                "value": 59624,
                "label": "Thimeon",
                "label_ar": "ثيمون",
                "label_fr": "Thimeon"
            },
            {
                "value": 58981,
                "label": "Gentbrugge",
                "label_ar": "جنتبروج",
                "label_fr": "Gentbrugge"
            },
            {
                "value": 58907,
                "label": "Brussels",
                "label_ar": "بروكسل",
                "label_fr": "Bruxelles"
            },
            {
                "value": 59011,
                "label": "Aalst",
                "label_ar": "آلست",
                "label_fr": "Alost"
            },
            {
                "value": 59823,
                "label": "Recht",
                "label_ar": "Recht",
                "label_fr": "Recht"
            },
            {
                "value": 59016,
                "label": "Lokeren",
                "label_ar": "لوكرين",
                "label_fr": "Lokeren"
            },
            {
                "value": 59618,
                "label": "Chastre",
                "label_ar": "تشاستر",
                "label_fr": "Chastre"
            },
            {
                "value": 59216,
                "label": "Tremelo",
                "label_ar": "تريميلو",
                "label_fr": "Tremelo"
            },
            {
                "value": 59180,
                "label": "Zeebrugge",
                "label_ar": "زيبروغ",
                "label_fr": "Zeebruges"
            },
            {
                "value": 59862,
                "label": "Saint-Josse-ten-Noode",
                "label_ar": "سانت جوس تين نود",
                "label_fr": "Saint-Josse-ten-Noode"
            },
            {
                "value": 59302,
                "label": "Lot",
                "label_ar": "كثيرا",
                "label_fr": "Lot"
            },
            {
                "value": 59177,
                "label": "Thimister",
                "label_ar": "ثيميستر",
                "label_fr": "Thimister"
            },
            {
                "value": 59081,
                "label": "Elversele",
                "label_ar": "إلفرسيل",
                "label_fr": "Elversele"
            },
            {
                "value": 59636,
                "label": "Ath",
                "label_ar": "آث",
                "label_fr": "Ath"
            },
            {
                "value": 59535,
                "label": "Opoeteren",
                "label_ar": "أوبتيرن",
                "label_fr": "Opoeteren"
            },
            {
                "value": 59326,
                "label": "Hombeek",
                "label_ar": "هومبيك",
                "label_fr": "Hombeek"
            },
            {
                "value": 60024,
                "label": "Avekapelle",
                "label_ar": "أفيكابيل",
                "label_fr": "Avekapelle"
            },
            {
                "value": 59808,
                "label": "Winksele",
                "label_ar": "Winksele",
                "label_fr": "Winksele"
            },
            {
                "value": 60054,
                "label": "Wenduine",
                "label_ar": "ويندويني",
                "label_fr": "Wenduine"
            },
            {
                "value": 59751,
                "label": "Estinnes-au-Val",
                "label_ar": "إستينيس أو فال",
                "label_fr": "Estinnes-au-Val"
            },
            {
                "value": 59119,
                "label": "Obaix",
                "label_ar": "أوبايكس",
                "label_fr": "Obaix"
            },
            {
                "value": 59758,
                "label": "Awirs",
                "label_ar": "أويرس",
                "label_fr": "Awirs"
            },
            {
                "value": 59170,
                "label": "Gesves",
                "label_ar": "جيسفيس",
                "label_fr": "Gesves"
            },
            {
                "value": 59025,
                "label": "De Klinge",
                "label_ar": "دي كلينج",
                "label_fr": "De Klinge"
            },
            {
                "value": 60013,
                "label": "Vaux",
                "label_ar": "فو",
                "label_fr": "Vaux"
            },
            {
                "value": 59754,
                "label": "Obourg",
                "label_ar": "أوبورج",
                "label_fr": "Obourg"
            },
            {
                "value": 59097,
                "label": "Chapelle-lez-Herlaimont",
                "label_ar": "شابيل ليز هيرليمونت",
                "label_fr": "Chapelle-lez-Herlaimont"
            },
            {
                "value": 59156,
                "label": "Quievrain",
                "label_ar": "كييفرين",
                "label_fr": "Quievrain"
            },
            {
                "value": 59765,
                "label": "Ruisbroek",
                "label_ar": "رويسبروك",
                "label_fr": "Ruisbroek"
            },
            {
                "value": 60035,
                "label": "Thy-le-Chateau",
                "label_ar": "Thy-le-Chateau",
                "label_fr": "Thy-le-Château"
            },
            {
                "value": 59249,
                "label": "Sint-Truiden",
                "label_ar": "سينت ترويدن",
                "label_fr": "Saint-Trond"
            },
            {
                "value": 59477,
                "label": "As",
                "label_ar": "مثل",
                "label_fr": "Comme"
            },
            {
                "value": 59145,
                "label": "Huizingen",
                "label_ar": "Huizingen",
                "label_fr": "Huizingen"
            },
            {
                "value": 59040,
                "label": "Mespelare",
                "label_ar": "ميسبيلاري",
                "label_fr": "Mespelare"
            },
            {
                "value": 59187,
                "label": "Gerpinnes",
                "label_ar": "جربينيس",
                "label_fr": "Gerpinnes"
            },
            {
                "value": 59478,
                "label": "Bree",
                "label_ar": "بري",
                "label_fr": "Bree"
            },
            {
                "value": 59778,
                "label": "Laken",
                "label_ar": "لاكن",
                "label_fr": "Laken"
            },
            {
                "value": 59375,
                "label": "Dampremy",
                "label_ar": "دامبيرمي",
                "label_fr": "Dampremy"
            },
            {
                "value": 59667,
                "label": "Grand-Rechain",
                "label_ar": "جراند ريشاين",
                "label_fr": "Grand-Rechain"
            },
            {
                "value": 58903,
                "label": "Mortsel",
                "label_ar": "مورتسل",
                "label_fr": "Mortsel"
            },
            {
                "value": 59464,
                "label": "Horion-Hozemont",
                "label_ar": "هوريون هوزمونت",
                "label_fr": "Horion-Hozémont"
            },
            {
                "value": 59004,
                "label": "Erpe",
                "label_ar": "إيرب",
                "label_fr": "Erpe"
            },
            {
                "value": 59569,
                "label": "Glabbeek",
                "label_ar": "جلابيك",
                "label_fr": "Glabbeek"
            },
            {
                "value": 59617,
                "label": "Herlaimont",
                "label_ar": "هيرليمونت",
                "label_fr": "Herlaimont"
            },
            {
                "value": 59673,
                "label": "Rhisnes",
                "label_ar": "ريسنس",
                "label_fr": "Rhisnes"
            },
            {
                "value": 59049,
                "label": "Herdersem",
                "label_ar": "هيررسيم",
                "label_fr": "Herdersem"
            },
            {
                "value": 59264,
                "label": "Grobbendonk",
                "label_ar": "جروبندونك",
                "label_fr": "Grobbendonk"
            },
            {
                "value": 59270,
                "label": "Bouwel",
                "label_ar": "بويل",
                "label_fr": "Bouwel"
            },
            {
                "value": 59152,
                "label": "Boutersem",
                "label_ar": "بوترسيم",
                "label_fr": "Boutersem"
            },
            {
                "value": 59536,
                "label": "Opitter",
                "label_ar": "Opitter",
                "label_fr": "Opitter"
            },
            {
                "value": 59222,
                "label": "Hoevenen",
                "label_ar": "Hoevenen",
                "label_fr": "Hoevenen"
            },
            {
                "value": 59223,
                "label": "Wommelgem",
                "label_ar": "Wommelgem",
                "label_fr": "Wommelgem"
            },
            {
                "value": 59314,
                "label": "Gooik",
                "label_ar": "جوويك",
                "label_fr": "Gooik"
            },
            {
                "value": 59687,
                "label": "Oordegem",
                "label_ar": "أورديجم",
                "label_fr": "Oordegem"
            },
            {
                "value": 59538,
                "label": "Herk-de-Stad",
                "label_ar": "هيرك دي ستاد",
                "label_fr": "Herk-de-Stad"
            },
            {
                "value": 59203,
                "label": "Mol",
                "label_ar": "مول",
                "label_fr": "Mol"
            },
            {
                "value": 59986,
                "label": "Moen",
                "label_ar": "موين",
                "label_fr": "Moen"
            },
            {
                "value": 59337,
                "label": "Meulebeke",
                "label_ar": "ميوليبيك",
                "label_fr": "Meulebeke"
            },
            {
                "value": 59360,
                "label": "Sint-Laureins",
                "label_ar": "سينت لورينز",
                "label_fr": "Sint-Laureins"
            },
            {
                "value": 59574,
                "label": "Sinaai",
                "label_ar": "سيناء",
                "label_fr": "Sinaai"
            },
            {
                "value": 59055,
                "label": "Meerbeke",
                "label_ar": "ميربيك",
                "label_fr": "Meerbeke"
            },
            {
                "value": 59035,
                "label": "Deftinge",
                "label_ar": "ديفتينج",
                "label_fr": "Deftinge"
            },
            {
                "value": 59166,
                "label": "Lontzen",
                "label_ar": "Lontzen",
                "label_fr": "Lontzen"
            },
            {
                "value": 59904,
                "label": "Westouter",
                "label_ar": "ويستوتر",
                "label_fr": "Westouter"
            },
            {
                "value": 59897,
                "label": "Kruishoutem",
                "label_ar": "كرويشوتيم",
                "label_fr": "Kruishoutem"
            },
            {
                "value": 59749,
                "label": "Stavelot",
                "label_ar": "ستافيلوت",
                "label_fr": "Stavelot"
            },
            {
                "value": 59256,
                "label": "Oudenaarde",
                "label_ar": "Oudenaarde",
                "label_fr": "Audenarde"
            },
            {
                "value": 59533,
                "label": "Kuringen",
                "label_ar": "كورينغن",
                "label_fr": "Kuringen"
            },
            {
                "value": 59752,
                "label": "Nimy",
                "label_ar": "نيمي",
                "label_fr": "Nimy"
            },
            {
                "value": 59260,
                "label": "Zedelgem",
                "label_ar": "زيدلجم",
                "label_fr": "Zedelgem"
            },
            {
                "value": 59880,
                "label": "Velaines",
                "label_ar": "فيلينيس",
                "label_fr": "Velaines"
            },
            {
                "value": 59940,
                "label": "Nukerke",
                "label_ar": "نوكركي",
                "label_fr": "Nukerke"
            },
            {
                "value": 59287,
                "label": "Rotselaar",
                "label_ar": "روتسيلار",
                "label_fr": "Rotselaar"
            },
            {
                "value": 59243,
                "label": "Lanaken",
                "label_ar": "لاناكين",
                "label_fr": "Lanaken"
            },
            {
                "value": 59425,
                "label": "Engis",
                "label_ar": "إنجيس",
                "label_fr": "Engis"
            },
            {
                "value": 59485,
                "label": "Stokkem",
                "label_ar": "Stokkem",
                "label_fr": "Stokkem"
            },
            {
                "value": 59229,
                "label": "Schilde",
                "label_ar": "شيلد",
                "label_fr": "Schilde"
            },
            {
                "value": 59887,
                "label": "Sart-en-Fagne",
                "label_ar": "سارت أون فاني",
                "label_fr": "Sart-en-Fagne"
            },
            {
                "value": 59655,
                "label": "Naast",
                "label_ar": "ناست",
                "label_fr": "Naast"
            }
        ]
    },
    {
        "country": "United Arab Emirates",
        "cities": [
            {
                "value": 60071,
                "label": "Al Bataeh",
                "label_ar": "البطائح",
                "label_fr": "Al Bataeh"
            },
            {
                "value": 60112,
                "label": "Lahbab",
                "label_ar": "لهباب",
                "label_fr": "Lahbab"
            },
            {
                "value": 60116,
                "label": "Masafi",
                "label_ar": "مسافي",
                "label_fr": "Masafi"
            },
            {
                "value": 60110,
                "label": "Khor Fakkan",
                "label_ar": "خورفكان",
                "label_fr": "Khor Fakkan"
            },
            {
                "value": 60082,
                "label": "Al Manama",
                "label_ar": "المنامة",
                "label_fr": "Al Manama"
            },
            {
                "value": 60064,
                "label": "Adhen",
                "label_ar": "Adhen",
                "label_fr": "Adhen"
            },
            {
                "value": 60124,
                "label": "Sharjah",
                "label_ar": "الشارقة",
                "label_fr": "Sharjah"
            },
            {
                "value": 60099,
                "label": "Falaj Al Mualla",
                "label_ar": "فلج المعلا",
                "label_fr": "Falaj Al Mualla"
            },
            {
                "value": 60069,
                "label": "Al Awir",
                "label_ar": "العوير",
                "label_fr": "Al Awir"
            },
            {
                "value": 60126,
                "label": "Sir Bani Yas",
                "label_ar": "صير بني ياس",
                "label_fr": "Sir Bani Yas"
            },
            {
                "value": 60109,
                "label": "Khatt",
                "label_ar": "خت",
                "label_fr": "Khatt"
            },
            {
                "value": 60080,
                "label": "Al Lisaili",
                "label_ar": "الليسيلي",
                "label_fr": "Al Lisaili"
            },
            {
                "value": 60066,
                "label": "Al Ain",
                "label_ar": "العين",
                "label_fr": "Al Ain"
            },
            {
                "value": 60108,
                "label": "Kalba",
                "label_ar": "كلباء",
                "label_fr": "Kalba"
            },
            {
                "value": 60104,
                "label": "Habshan",
                "label_ar": "حبشان",
                "label_fr": "Habshan"
            },
            {
                "value": 60097,
                "label": "Digdaga",
                "label_ar": "Digdaga",
                "label_fr": "Digdaga"
            },
            {
                "value": 60096,
                "label": "Dibba Al-Hisn",
                "label_ar": "دبا الحصن",
                "label_fr": "Dibba Al-Hisn"
            },
            {
                "value": 60127,
                "label": "Sweihan",
                "label_ar": "سويحان",
                "label_fr": "Sweihan"
            },
            {
                "value": 60072,
                "label": "Al Bithnah",
                "label_ar": "البثنة",
                "label_fr": "Al Bithnah"
            },
            {
                "value": 60106,
                "label": "Huwaylat",
                "label_ar": "حويلات",
                "label_fr": "Huwaylat"
            },
            {
                "value": 60092,
                "label": "Dadna",
                "label_ar": "دادنا",
                "label_fr": "Dadna"
            },
            {
                "value": 60105,
                "label": "Hatta",
                "label_ar": "حتا",
                "label_fr": "Hatta"
            },
            {
                "value": 60089,
                "label": "Al Yahar",
                "label_ar": "اليحر",
                "label_fr": "Al Yahar"
            },
            {
                "value": 60100,
                "label": "Fujairah",
                "label_ar": "الفجيرة",
                "label_fr": "Fujairah"
            },
            {
                "value": 60117,
                "label": "Masfut",
                "label_ar": "مصفوت",
                "label_fr": "Masfut"
            },
            {
                "value": 60119,
                "label": "Mleiha",
                "label_ar": "مليحة",
                "label_fr": "Mleiha"
            },
            {
                "value": 60084,
                "label": "Al Qor",
                "label_ar": "القرع",
                "label_fr": "Al Qor"
            },
            {
                "value": 60073,
                "label": "Al Faqa",
                "label_ar": "الفقا",
                "label_fr": "Al Faqa"
            },
            {
                "value": 60088,
                "label": "Al Shuwaib",
                "label_ar": "الشويب",
                "label_fr": "Al Shuwaib"
            },
            {
                "value": 60098,
                "label": "Dubai",
                "label_ar": "دبي",
                "label_fr": "Dubai"
            },
            {
                "value": 60090,
                "label": "Ar-Rams",
                "label_ar": "الرمس",
                "label_fr": "Ar-Rams"
            },
            {
                "value": 60076,
                "label": "Al Hamriyah",
                "label_ar": "الحمرية",
                "label_fr": "Al Hamriyah"
            },
            {
                "value": 60070,
                "label": "Al Badiyah",
                "label_ar": "البادية",
                "label_fr": "Al Badiyah"
            },
            {
                "value": 60121,
                "label": "RAK City",
                "label_ar": "مدينة رأس الخيمة",
                "label_fr": "RAK City"
            },
            {
                "value": 60128,
                "label": "Umm Al Quwain",
                "label_ar": "أم القيوين",
                "label_fr": "Umm Al Quwain"
            },
            {
                "value": 60062,
                "label": "Abu Dhabi",
                "label_ar": "أبو ظبي",
                "label_fr": "Abu Dhabi"
            },
            {
                "value": 60129,
                "label": "Wadi Shah",
                "label_ar": "وادي شاه",
                "label_fr": "Wadi Shah"
            },
            {
                "value": 60091,
                "label": "Asimah",
                "label_ar": "Asimah",
                "label_fr": "Asimah"
            },
            {
                "value": 60068,
                "label": "Al Aryam",
                "label_ar": "الأريام",
                "label_fr": "Al Aryam"
            },
            {
                "value": 60067,
                "label": "Al Ajban",
                "label_ar": "العجبان",
                "label_fr": "Al Ajban"
            },
            {
                "value": 60079,
                "label": "Al Khawaneej",
                "label_ar": "الخوانيج",
                "label_fr": "Al Khawaneej"
            },
            {
                "value": 60122,
                "label": "Ruwais",
                "label_ar": "الرويس",
                "label_fr": "Ruwais"
            },
            {
                "value": 60077,
                "label": "Al Jazirah Al Hamra",
                "label_ar": "الجزيرة الحمراء",
                "label_fr": "Al Jazirah Al Hamra"
            },
            {
                "value": 60087,
                "label": "Al Rashidya",
                "label_ar": "الراشدية",
                "label_fr": "Al Rashidya"
            },
            {
                "value": 60103,
                "label": "Ghub",
                "label_ar": "غب",
                "label_fr": "Ghub"
            },
            {
                "value": 60113,
                "label": "Liwa Oasis",
                "label_ar": "واحة ليوا",
                "label_fr": "Oasis de Liwa"
            },
            {
                "value": 60120,
                "label": "Nahil",
                "label_ar": "نهيل",
                "label_fr": "Nahil"
            },
            {
                "value": 60085,
                "label": "Al Qusaidat",
                "label_ar": "القصيدات",
                "label_fr": "Al Qusaidat"
            },
            {
                "value": 60115,
                "label": "Marawah",
                "label_ar": "مروح",
                "label_fr": "Marawah"
            },
            {
                "value": 60093,
                "label": "Dalma",
                "label_ar": "دلما",
                "label_fr": "Dalma"
            },
            {
                "value": 60065,
                "label": "Ajman",
                "label_ar": "عجمان",
                "label_fr": "Ajman"
            },
            {
                "value": 60074,
                "label": "Al Halah",
                "label_ar": "الحلا",
                "label_fr": "Al Halah"
            },
            {
                "value": 60118,
                "label": "Mirbah",
                "label_ar": "مربح",
                "label_fr": "Mirbah"
            },
            {
                "value": 60114,
                "label": "Madinat Zayed",
                "label_ar": "مدينة زايد",
                "label_fr": "Madinat Zayed"
            },
            {
                "value": 60095,
                "label": "Dibba Al-Fujairah",
                "label_ar": "دبا الفجيرة",
                "label_fr": "Dibba Al-Fujairah"
            },
            {
                "value": 60086,
                "label": "Al Rafaah",
                "label_ar": "الرفاعة",
                "label_fr": "Al Rafaah"
            },
            {
                "value": 60078,
                "label": "Al Jeer",
                "label_ar": "الجير",
                "label_fr": "Al Jeer"
            },
            {
                "value": 60075,
                "label": "Al Hamraniyah",
                "label_ar": "الحمرانية",
                "label_fr": "Al Hamraniyah"
            },
            {
                "value": 60125,
                "label": "Sila",
                "label_ar": "سيلا",
                "label_fr": "Sila"
            },
            {
                "value": 60130,
                "label": "Zubarah",
                "label_ar": "الزبارة",
                "label_fr": "Zubarah"
            },
            {
                "value": 60083,
                "label": "Al Mirfa",
                "label_ar": "المرفأ",
                "label_fr": "Al Mirfa"
            },
            {
                "value": 60101,
                "label": "Ghalilah",
                "label_ar": "غليلة",
                "label_fr": "Ghalilah"
            },
            {
                "value": 60107,
                "label": "Jebel Ali",
                "label_ar": "جبل علي",
                "label_fr": "Jebel Ali"
            },
            {
                "value": 60081,
                "label": "Al Madam",
                "label_ar": "المدام",
                "label_fr": "Madame Al"
            },
            {
                "value": 60094,
                "label": "Dhaid",
                "label_ar": "الذيد",
                "label_fr": "Dhaid"
            },
            {
                "value": 60102,
                "label": "Ghayathi",
                "label_ar": "غياثي",
                "label_fr": "Ghayathi"
            },
            {
                "value": 60111,
                "label": "Khor Khwair",
                "label_ar": "خور خوير",
                "label_fr": "Khor Khwair"
            },
            {
                "value": 60123,
                "label": "Sha'am",
                "label_ar": "شعم",
                "label_fr": "Sha'am"
            },
            {
                "value": 60063,
                "label": "Abu al Abyad",
                "label_ar": "أبو الأبيض",
                "label_fr": "Abu al Abyad"
            }
        ]
    },
    {
        "country": "Kazakhstan",
        "cities": [
            {
                "value": 60149,
                "label": "Ridder",
                "label_ar": "رايدر",
                "label_fr": "Ridder"
            },
            {
                "value": 60135,
                "label": "Karagandy",
                "label_ar": "كاراغاندي",
                "label_fr": "Karagandy"
            },
            {
                "value": 60140,
                "label": "Pavlodar",
                "label_ar": "بافلودار",
                "label_fr": "Pavlodar"
            },
            {
                "value": 60173,
                "label": "Kazakh",
                "label_ar": "الكازاخستانية",
                "label_fr": "Kazakh"
            },
            {
                "value": 60148,
                "label": "Serebryansk",
                "label_ar": "سيريبريانسك",
                "label_fr": "Serebryansk"
            },
            {
                "value": 60143,
                "label": "Kökshetaū",
                "label_ar": "Köksheta",
                "label_fr": "Kökshetaū"
            },
            {
                "value": 60168,
                "label": "Turgen'",
                "label_ar": "تورغن",
                "label_fr": "Turgen '"
            },
            {
                "value": 60153,
                "label": "Kyzylorda",
                "label_ar": "كيزيلوردا",
                "label_fr": "Kyzylorda"
            },
            {
                "value": 60163,
                "label": "Koktem",
                "label_ar": "Koktem",
                "label_fr": "Koktem"
            },
            {
                "value": 60159,
                "label": "Karagandy",
                "label_ar": "كاراغاندي",
                "label_fr": "Karagandy"
            },
            {
                "value": 60167,
                "label": "Rudnyy",
                "label_ar": "رودني",
                "label_fr": "Rudnyy"
            },
            {
                "value": 60132,
                "label": "Oral",
                "label_ar": "عن طريق الفم",
                "label_fr": "Oral"
            },
            {
                "value": 60174,
                "label": "Stepnogorsk",
                "label_ar": "ستيبنوغورسك",
                "label_fr": "Stepnogorsk"
            },
            {
                "value": 60166,
                "label": "Kentau",
                "label_ar": "كنتاو",
                "label_fr": "Kentau"
            },
            {
                "value": 60144,
                "label": "Aksay",
                "label_ar": "اكساي",
                "label_fr": "Aksay"
            },
            {
                "value": 60182,
                "label": "Turkestan",
                "label_ar": "تركستان",
                "label_fr": "Turkestan"
            },
            {
                "value": 60176,
                "label": "Līsakovsk",
                "label_ar": "Līsakovsk",
                "label_fr": "Līsakovsk"
            },
            {
                "value": 60131,
                "label": "Almaty",
                "label_ar": "ألماتي",
                "label_fr": "Almaty"
            },
            {
                "value": 60150,
                "label": "Shemonaīkha",
                "label_ar": "شمونيخا",
                "label_fr": "Shemonaīkha"
            },
            {
                "value": 60160,
                "label": "Dzhezkazgan",
                "label_ar": "جيزكازجان",
                "label_fr": "Dzhezkazgan"
            },
            {
                "value": 60139,
                "label": "Talghar",
                "label_ar": "تلغر",
                "label_fr": "Talghar"
            },
            {
                "value": 60152,
                "label": "Aqsū",
                "label_ar": "اقصو",
                "label_fr": "Aqsū"
            },
            {
                "value": 60181,
                "label": "Lomonosovka",
                "label_ar": "لومونوسوفكا",
                "label_fr": "Lomonosovka"
            },
            {
                "value": 60151,
                "label": "Semey",
                "label_ar": "سيمي",
                "label_fr": "Semey"
            },
            {
                "value": 60146,
                "label": "Petropavl",
                "label_ar": "بتروبافل",
                "label_fr": "Petropavl"
            },
            {
                "value": 60175,
                "label": "Ust-Kamenogorsk",
                "label_ar": "أوست كامينوجورسك",
                "label_fr": "Ust-Kamenogorsk"
            },
            {
                "value": 60147,
                "label": "Shymkent",
                "label_ar": "شيمكنت",
                "label_fr": "Shymkent"
            },
            {
                "value": 60142,
                "label": "Atyrau",
                "label_ar": "أتيراو",
                "label_fr": "Atyrau"
            },
            {
                "value": 60134,
                "label": "Qaskeleng",
                "label_ar": "قاسكيلينج",
                "label_fr": "Qaskeleng"
            },
            {
                "value": 60169,
                "label": "Esil",
                "label_ar": "Esil",
                "label_fr": "Esil"
            },
            {
                "value": 60170,
                "label": "Vostok",
                "label_ar": "فوستوك",
                "label_fr": "Vostok"
            },
            {
                "value": 60177,
                "label": "Aksoran",
                "label_ar": "أكسوران",
                "label_fr": "Aksoran"
            },
            {
                "value": 60155,
                "label": "Khromtaū",
                "label_ar": "خرمتاي",
                "label_fr": "Khromtaū"
            },
            {
                "value": 60172,
                "label": "Uritskiy",
                "label_ar": "يوريتسكي",
                "label_fr": "Uritskiy"
            },
            {
                "value": 60154,
                "label": "Aqtöbe",
                "label_ar": "أكتوبي",
                "label_fr": "Aqtöbe"
            },
            {
                "value": 60158,
                "label": "Zhangaözen",
                "label_ar": "Zhangaözen",
                "label_fr": "Zhangaözen"
            },
            {
                "value": 60145,
                "label": "Taraz",
                "label_ar": "تاراز",
                "label_fr": "Taraz"
            },
            {
                "value": 60138,
                "label": "Taldykorgan",
                "label_ar": "تالديكورجان",
                "label_fr": "Taldykorgan"
            },
            {
                "value": 60141,
                "label": "Ekibastuz",
                "label_ar": "إيكيباستوز",
                "label_fr": "Ekibastuz"
            },
            {
                "value": 60180,
                "label": "Börili",
                "label_ar": "بوريلي",
                "label_fr": "Börili"
            },
            {
                "value": 60136,
                "label": "Temirtau",
                "label_ar": "تيميرتاو",
                "label_fr": "Temirtau"
            },
            {
                "value": 60162,
                "label": "Kyzyl",
                "label_ar": "كيزيل",
                "label_fr": "Kyzyl"
            },
            {
                "value": 60133,
                "label": "Kostanay",
                "label_ar": "كوستاناي",
                "label_fr": "Kostanay"
            },
            {
                "value": 60161,
                "label": "Baikonur",
                "label_ar": "بايكونور",
                "label_fr": "Baïkonour"
            },
            {
                "value": 60179,
                "label": "Aqtas",
                "label_ar": "اقطاس",
                "label_fr": "Aqtas"
            },
            {
                "value": 60164,
                "label": "Dostyk",
                "label_ar": "دوستيك",
                "label_fr": "Dostyk"
            },
            {
                "value": 60178,
                "label": "Zyryanovsk",
                "label_ar": "زيريانوفسك",
                "label_fr": "Zyryanovsk"
            },
            {
                "value": 60137,
                "label": "Astana",
                "label_ar": "أستانا",
                "label_fr": "Astana"
            },
            {
                "value": 60156,
                "label": "Aqtau",
                "label_ar": "أكتاو",
                "label_fr": "Aqtau"
            },
            {
                "value": 60171,
                "label": "Qaraghandy",
                "label_ar": "قرهندي",
                "label_fr": "Qaraghandy"
            },
            {
                "value": 60157,
                "label": "Taldyk",
                "label_ar": "تالديك",
                "label_fr": "Taldyk"
            },
            {
                "value": 60165,
                "label": "Sonaly",
                "label_ar": "سونالي",
                "label_fr": "Sonaly"
            }
        ]
    },
    {
        "country": "Portugal",
        "cities": [
            {
                "value": 60468,
                "label": "Sintra",
                "label_ar": "سينترا",
                "label_fr": "Sintra"
            },
            {
                "value": 60414,
                "label": "Barcelos",
                "label_ar": "بارسيلوس",
                "label_fr": "Barcelos"
            },
            {
                "value": 60203,
                "label": "Viana do Castelo",
                "label_ar": "فيانا دو كاستيلو",
                "label_fr": "Viana do Castelo"
            },
            {
                "value": 60188,
                "label": "Braganca",
                "label_ar": "براغانكا",
                "label_fr": "Bragance"
            },
            {
                "value": 60626,
                "label": "Santa Barbara de Nexe",
                "label_ar": "سانتا باربرا دي نيكسي",
                "label_fr": "Santa Barbara de Nexe"
            },
            {
                "value": 60415,
                "label": "Bombarral",
                "label_ar": "بومبارال",
                "label_fr": "Bombarral"
            },
            {
                "value": 60204,
                "label": "Moncao",
                "label_ar": "مونكاو",
                "label_fr": "Moncao"
            },
            {
                "value": 60609,
                "label": "Queluz de Baixo",
                "label_ar": "كيلوز دي بايكسو",
                "label_fr": "Queluz de Baixo"
            },
            {
                "value": 60545,
                "label": "Alfornelos",
                "label_ar": "ألفورنيلوس",
                "label_fr": "Alfornelos"
            },
            {
                "value": 60420,
                "label": "Silveira",
                "label_ar": "سيلفيرا",
                "label_fr": "Silveira"
            },
            {
                "value": 60817,
                "label": "Margaride",
                "label_ar": "مارجريد",
                "label_fr": "Margaride"
            },
            {
                "value": 60566,
                "label": "Alpiarca",
                "label_ar": "ألبياركا",
                "label_fr": "Alpiarca"
            },
            {
                "value": 60570,
                "label": "Lago",
                "label_ar": "لاغو",
                "label_fr": "Lago"
            },
            {
                "value": 60606,
                "label": "Quinta do Anjo",
                "label_ar": "كوينتا دو أنجو",
                "label_fr": "Quinta do Anjo"
            },
            {
                "value": 60714,
                "label": "Alfena",
                "label_ar": "ألفينا",
                "label_fr": "Alfena"
            },
            {
                "value": 60376,
                "label": "Lavos",
                "label_ar": "لافوس",
                "label_fr": "Lavos"
            },
            {
                "value": 60383,
                "label": "Guia",
                "label_ar": "جويا",
                "label_fr": "Guia"
            },
            {
                "value": 60527,
                "label": "Torre da Marinha",
                "label_ar": "توري دا مارينيا",
                "label_fr": "Torre da Marinha"
            },
            {
                "value": 60877,
                "label": "Pereira",
                "label_ar": "بيريرا",
                "label_fr": "Pereira"
            },
            {
                "value": 60310,
                "label": "Almeirim",
                "label_ar": "الميرم",
                "label_fr": "Almeirim"
            },
            {
                "value": 60717,
                "label": "Godim",
                "label_ar": "جوديم",
                "label_fr": "Godim"
            },
            {
                "value": 60368,
                "label": "Carvalhal",
                "label_ar": "كارفالال",
                "label_fr": "Carvalhal"
            },
            {
                "value": 60332,
                "label": "Amiaes de Cima",
                "label_ar": "أمياس دي سيما",
                "label_fr": "Amiaes de Cima"
            },
            {
                "value": 60729,
                "label": "Vila Cha",
                "label_ar": "فيلا تشا",
                "label_fr": "Vila Cha"
            },
            {
                "value": 60519,
                "label": "Trancoso de Baixo",
                "label_ar": "ترانكوسو دي بايكسو",
                "label_fr": "Trancoso de Baixo"
            },
            {
                "value": 60599,
                "label": "Roque",
                "label_ar": "روكي",
                "label_fr": "Roque"
            },
            {
                "value": 60557,
                "label": "Zibreira",
                "label_ar": "زيبريرا",
                "label_fr": "Zibreira"
            },
            {
                "value": 60198,
                "label": "Tabuaco",
                "label_ar": "تابواكو",
                "label_fr": "Tabuaco"
            },
            {
                "value": 60821,
                "label": "Paraiso",
                "label_ar": "بارايسو",
                "label_fr": "Paraiso"
            },
            {
                "value": 60197,
                "label": "Lamego",
                "label_ar": "لاميجو",
                "label_fr": "Lamego"
            },
            {
                "value": 60541,
                "label": "Pontinha",
                "label_ar": "بونتينا",
                "label_fr": "Pontinha"
            },
            {
                "value": 60444,
                "label": "Odivelas",
                "label_ar": "Odivelas",
                "label_fr": "Odivelas"
            },
            {
                "value": 60500,
                "label": "Sobralinho",
                "label_ar": "سوبرالينيو",
                "label_fr": "Sobralinho"
            },
            {
                "value": 60734,
                "label": "Lousado",
                "label_ar": "لوسادو",
                "label_fr": "Lousado"
            },
            {
                "value": 60507,
                "label": "Santo Antao do Tojal",
                "label_ar": "سانتو أنتاو دو توجال",
                "label_fr": "Santo Antao do Tojal"
            },
            {
                "value": 60870,
                "label": "Caramulo",
                "label_ar": "كارامولو",
                "label_fr": "Caramulo"
            },
            {
                "value": 60700,
                "label": "Aljezur",
                "label_ar": "الجزيرة",
                "label_fr": "Aljezur"
            },
            {
                "value": 60424,
                "label": "Turcifal",
                "label_ar": "تورسيفال",
                "label_fr": "Turcifal"
            },
            {
                "value": 60423,
                "label": "Ferradosa",
                "label_ar": "فيرادوسا",
                "label_fr": "Ferradosa"
            },
            {
                "value": 60685,
                "label": "Bobadela",
                "label_ar": "بوباديلا",
                "label_fr": "Bobadela"
            },
            {
                "value": 60505,
                "label": "Portela",
                "label_ar": "بورتيلا",
                "label_fr": "Portela"
            },
            {
                "value": 60634,
                "label": "Pechao",
                "label_ar": "بيتشاو",
                "label_fr": "Pechao"
            },
            {
                "value": 60223,
                "label": "Guimarães",
                "label_ar": "غيماريش",
                "label_fr": "Guimarães"
            },
            {
                "value": 60391,
                "label": "Porto Alto",
                "label_ar": "بورتو ألتو",
                "label_fr": "Porto Alto"
            },
            {
                "value": 60192,
                "label": "Vilarinho de Arcos",
                "label_ar": "Vilarinho de Arcos",
                "label_fr": "Vilarinho de Arcos"
            },
            {
                "value": 60439,
                "label": "Amoreira",
                "label_ar": "أموريرا",
                "label_fr": "Amoreira"
            },
            {
                "value": 60277,
                "label": "Palhaca",
                "label_ar": "بالاكا",
                "label_fr": "Palhaca"
            },
            {
                "value": 60824,
                "label": "Basto",
                "label_ar": "باستو",
                "label_fr": "Basto"
            },
            {
                "value": 60641,
                "label": "Alvarinhos",
                "label_ar": "ألفارينهوس",
                "label_fr": "Alvarinhos"
            },
            {
                "value": 60365,
                "label": "Amor",
                "label_ar": "أمور",
                "label_fr": "Amor"
            },
            {
                "value": 60854,
                "label": "Cavaloes",
                "label_ar": "كافالوز",
                "label_fr": "Cavaloes"
            },
            {
                "value": 60558,
                "label": "Reguengo Grande",
                "label_ar": "ريجينجو غراندي",
                "label_fr": "Reguengo Grande"
            },
            {
                "value": 60881,
                "label": "Real",
                "label_ar": "حقيقة",
                "label_fr": "Réel"
            },
            {
                "value": 60712,
                "label": "Grijo",
                "label_ar": "جريجو",
                "label_fr": "Grijo"
            },
            {
                "value": 60469,
                "label": "Monte Estoril",
                "label_ar": "مونتي إستوريل",
                "label_fr": "Monte Estoril"
            },
            {
                "value": 60702,
                "label": "Canedo",
                "label_ar": "كانيدو",
                "label_fr": "Canedo"
            },
            {
                "value": 60425,
                "label": "Prime",
                "label_ar": "رئيس",
                "label_fr": "Premier"
            },
            {
                "value": 60396,
                "label": "Almada",
                "label_ar": "ألمادا",
                "label_fr": "Almada"
            },
            {
                "value": 60830,
                "label": "Jovim",
                "label_ar": "جوفيم",
                "label_fr": "Jovim"
            },
            {
                "value": 60673,
                "label": "Praia da Vitoria",
                "label_ar": "برايا دا فيتوريا",
                "label_fr": "Praia da Vitoria"
            },
            {
                "value": 60592,
                "label": "Pardilho",
                "label_ar": "بارديلهو",
                "label_fr": "Pardilho"
            },
            {
                "value": 60389,
                "label": "Riba de Ave",
                "label_ar": "ريبا دي افي",
                "label_fr": "Riba de Ave"
            },
            {
                "value": 60637,
                "label": "Cano",
                "label_ar": "كانو",
                "label_fr": "Cano"
            },
            {
                "value": 60597,
                "label": "Mozelos",
                "label_ar": "موزيلوس",
                "label_fr": "Mozelos"
            },
            {
                "value": 60811,
                "label": "Nine",
                "label_ar": "تسع",
                "label_fr": "Neuf"
            },
            {
                "value": 60303,
                "label": "Paul",
                "label_ar": "بول",
                "label_fr": "Paul"
            },
            {
                "value": 60517,
                "label": "Pinheiro",
                "label_ar": "بينهيرو",
                "label_fr": "Pinheiro"
            },
            {
                "value": 60767,
                "label": "Azoia",
                "label_ar": "أزويا",
                "label_fr": "Azoia"
            },
            {
                "value": 60771,
                "label": "Rio de Moinhos",
                "label_ar": "ريو دي موينهوس",
                "label_fr": "Rio de Moinhos"
            },
            {
                "value": 60608,
                "label": "Lordelo",
                "label_ar": "لوردلو",
                "label_fr": "Lordelo"
            },
            {
                "value": 60271,
                "label": "Arouca",
                "label_ar": "اروكا",
                "label_fr": "Arouca"
            },
            {
                "value": 60369,
                "label": "Murteira",
                "label_ar": "مورتيرا",
                "label_fr": "Murteira"
            },
            {
                "value": 60864,
                "label": "Arvore",
                "label_ar": "أرفور",
                "label_fr": "Arvore"
            },
            {
                "value": 60617,
                "label": "Vilar do Pinheiro",
                "label_ar": "فيلار دو بينيرو",
                "label_fr": "Vilar do Pinheiro"
            },
            {
                "value": 60820,
                "label": "Lourosa",
                "label_ar": "لوروسا",
                "label_fr": "Lourosa"
            },
            {
                "value": 60200,
                "label": "Dalvares",
                "label_ar": "دالفاريس",
                "label_fr": "Dalvares"
            },
            {
                "value": 60752,
                "label": "Infesta",
                "label_ar": "إنفستا",
                "label_fr": "Infesta"
            },
            {
                "value": 60819,
                "label": "Nespereira",
                "label_ar": "نيسبيريرا",
                "label_fr": "Nespereira"
            },
            {
                "value": 60324,
                "label": "Peniche",
                "label_ar": "بينيش",
                "label_fr": "Peniche"
            },
            {
                "value": 60482,
                "label": "Pevidem",
                "label_ar": "بيفيدم",
                "label_fr": "Pevidem"
            },
            {
                "value": 60487,
                "label": "Sao Domingos de Rana",
                "label_ar": "ساو دومينغوس دي رانا",
                "label_fr": "Sao Domingos de Rana"
            },
            {
                "value": 60818,
                "label": "Baião",
                "label_ar": "باياو",
                "label_fr": "Baião"
            },
            {
                "value": 60823,
                "label": "Prazins",
                "label_ar": "برازينز",
                "label_fr": "Prazins"
            },
            {
                "value": 60663,
                "label": "Vila Nova",
                "label_ar": "فيلا نوفا",
                "label_fr": "Vila Nova"
            },
            {
                "value": 60397,
                "label": "Massama",
                "label_ar": "مسامة",
                "label_fr": "Massama"
            },
            {
                "value": 60191,
                "label": "Carrazedo",
                "label_ar": "كارازيدو",
                "label_fr": "Carrazedo"
            },
            {
                "value": 60899,
                "label": "Caldas",
                "label_ar": "كالداس",
                "label_fr": "Caldas"
            },
            {
                "value": 60529,
                "label": "Elvas",
                "label_ar": "إلفاس",
                "label_fr": "Elvas"
            },
            {
                "value": 60682,
                "label": "Porto",
                "label_ar": "بورتو",
                "label_fr": "Porto"
            },
            {
                "value": 60384,
                "label": "Gondomar",
                "label_ar": "جوندومار",
                "label_fr": "Gondomar"
            },
            {
                "value": 60790,
                "label": "Vale de Acor",
                "label_ar": "فالي دي أكور",
                "label_fr": "Vale de Acor"
            },
            {
                "value": 60535,
                "label": "Charneca",
                "label_ar": "تشارنيكا",
                "label_fr": "Charneca"
            },
            {
                "value": 60666,
                "label": "Santiago",
                "label_ar": "سانتياغو",
                "label_fr": "Santiago"
            },
            {
                "value": 60728,
                "label": "Granja",
                "label_ar": "جرانجا",
                "label_fr": "Granja"
            },
            {
                "value": 60211,
                "label": "Arcos de Valdevez",
                "label_ar": "اركوس دي فالديفيز",
                "label_fr": "Arcos de Valdevez"
            },
            {
                "value": 60299,
                "label": "Castelo Branco",
                "label_ar": "كاستيلو برانكو",
                "label_fr": "Castelo Branco"
            },
            {
                "value": 60584,
                "label": "Santo Andre",
                "label_ar": "سانتو اندريه",
                "label_fr": "Saint André"
            },
            {
                "value": 60793,
                "label": "Encarnacao",
                "label_ar": "إنكارناكاو",
                "label_fr": "Encarnacao"
            },
            {
                "value": 60622,
                "label": "Castro Verde Municipality",
                "label_ar": "بلدية كاسترو فيردي",
                "label_fr": "Municipalité de Castro Verde"
            },
            {
                "value": 60778,
                "label": "Piedade",
                "label_ar": "بيدادي",
                "label_fr": "Piedade"
            },
            {
                "value": 60875,
                "label": "Ortiga",
                "label_ar": "أورتيجا",
                "label_fr": "Ortiga"
            },
            {
                "value": 60375,
                "label": "Aveiras de Cima",
                "label_ar": "أفيراس دي سيما",
                "label_fr": "Aveiras de Cima"
            },
            {
                "value": 60816,
                "label": "Ota",
                "label_ar": "اوتا",
                "label_fr": "Ota"
            },
            {
                "value": 60434,
                "label": "Beja",
                "label_ar": "البجا",
                "label_fr": "Beja"
            },
            {
                "value": 60852,
                "label": "Calvao",
                "label_ar": "كالفاو",
                "label_fr": "Calvao"
            },
            {
                "value": 60220,
                "label": "Sobrosa",
                "label_ar": "سوبروسا",
                "label_fr": "Sobrosa"
            },
            {
                "value": 60475,
                "label": "Montijo",
                "label_ar": "مونتيجو",
                "label_fr": "Montijo"
            },
            {
                "value": 60363,
                "label": "Batalha",
                "label_ar": "باتالها",
                "label_fr": "Batalha"
            },
            {
                "value": 60340,
                "label": "Sobreiro",
                "label_ar": "سوبريرو",
                "label_fr": "Sobreiro"
            },
            {
                "value": 60737,
                "label": "Massarelos",
                "label_ar": "ماساريلوس",
                "label_fr": "Massarelos"
            },
            {
                "value": 60381,
                "label": "Regueira de Pontes",
                "label_ar": "Regueira de Pontes",
                "label_fr": "Regueira de Pontes"
            },
            {
                "value": 60341,
                "label": "Sobral de Monte Agraco",
                "label_ar": "سوبرال دي مونتي اجراكو",
                "label_fr": "Sobral de Monte Agraco"
            },
            {
                "value": 60462,
                "label": "Roriz",
                "label_ar": "روريز",
                "label_fr": "Roriz"
            },
            {
                "value": 60885,
                "label": "Quinta",
                "label_ar": "كينتا",
                "label_fr": "Quinta"
            },
            {
                "value": 60450,
                "label": "Carnaxide",
                "label_ar": "كارناكسيد",
                "label_fr": "Carnaxide"
            },
            {
                "value": 60560,
                "label": "Madeira",
                "label_ar": "الماديرا",
                "label_fr": "Madère"
            },
            {
                "value": 60530,
                "label": "Dona Maria",
                "label_ar": "دونا ماريا",
                "label_fr": "Dona Maria"
            },
            {
                "value": 60896,
                "label": "Nogueira da Regedoura",
                "label_ar": "نوغيرا دا ريجدورا",
                "label_fr": "Nogueira da Regedoura"
            },
            {
                "value": 60302,
                "label": "Fundao",
                "label_ar": "Fundao",
                "label_fr": "Fundao"
            },
            {
                "value": 60201,
                "label": "Santa Marta de Penaguiao",
                "label_ar": "سانتا مارتا دي بيناجوياو",
                "label_fr": "Santa Marta de Penaguiao"
            },
            {
                "value": 60774,
                "label": "Proenca-a-Nova",
                "label_ar": "Proenca-a-Nova",
                "label_fr": "Proenca-a-Nova"
            },
            {
                "value": 60603,
                "label": "Portalegre",
                "label_ar": "بورتاليجري",
                "label_fr": "Portalegre"
            },
            {
                "value": 60805,
                "label": "Monsanto",
                "label_ar": "مونسانتو",
                "label_fr": "Monsanto"
            },
            {
                "value": 60326,
                "label": "Casal da Areia",
                "label_ar": "كاسال دا ارييا",
                "label_fr": "Casal da Areia"
            },
            {
                "value": 60488,
                "label": "Famoes",
                "label_ar": "فاموز",
                "label_fr": "Famoes"
            },
            {
                "value": 60829,
                "label": "Gemunde",
                "label_ar": "جيموند",
                "label_fr": "Gemunde"
            },
            {
                "value": 60238,
                "label": "Coimbra",
                "label_ar": "كويمبرا",
                "label_fr": "Coimbra"
            },
            {
                "value": 60791,
                "label": "Gradil",
                "label_ar": "جراديل",
                "label_fr": "Gradil"
            },
            {
                "value": 60835,
                "label": "Olivais",
                "label_ar": "أوليفايس",
                "label_fr": "Olivais"
            },
            {
                "value": 60372,
                "label": "Pontevel",
                "label_ar": "بونتيفيل",
                "label_fr": "Pontevel"
            },
            {
                "value": 60470,
                "label": "Galiza",
                "label_ar": "جليزة",
                "label_fr": "Galiza"
            },
            {
                "value": 60587,
                "label": "Arraiolos",
                "label_ar": "أرايولوس",
                "label_fr": "Arraiolos"
            },
            {
                "value": 60738,
                "label": "Sao Marcos",
                "label_ar": "ساو ماركوس",
                "label_fr": "Sao Marcos"
            },
            {
                "value": 60243,
                "label": "Viseu",
                "label_ar": "فيسيو",
                "label_fr": "Viseu"
            },
            {
                "value": 60196,
                "label": "Peso da Régua Municipality",
                "label_ar": "بلدية بيسو دا ريجوا",
                "label_fr": "Municipalité de Peso da Régua"
            },
            {
                "value": 60543,
                "label": "Alportel",
                "label_ar": "ألبورتل",
                "label_fr": "Alportel"
            },
            {
                "value": 60777,
                "label": "Santiago",
                "label_ar": "سانتياغو",
                "label_fr": "Santiago"
            },
            {
                "value": 60736,
                "label": "Vila Nova",
                "label_ar": "فيلا نوفا",
                "label_fr": "Vila Nova"
            },
            {
                "value": 60602,
                "label": "Silva",
                "label_ar": "سيلفا",
                "label_fr": "Silva"
            },
            {
                "value": 60836,
                "label": "Landim",
                "label_ar": "لانديم",
                "label_fr": "Landim"
            },
            {
                "value": 60800,
                "label": "Valenca",
                "label_ar": "فالينكا",
                "label_fr": "Valenca"
            },
            {
                "value": 60630,
                "label": "Campo Maior",
                "label_ar": "كامبو مايور",
                "label_fr": "Campo Maior"
            },
            {
                "value": 60848,
                "label": "Sandim",
                "label_ar": "سانديم",
                "label_fr": "Sandim"
            },
            {
                "value": 60766,
                "label": "Mira",
                "label_ar": "ميرا",
                "label_fr": "Mira"
            },
            {
                "value": 60611,
                "label": "Taveiro",
                "label_ar": "تافيرو",
                "label_fr": "Taveiro"
            },
            {
                "value": 60716,
                "label": "Creixomil",
                "label_ar": "كريكسوميل",
                "label_fr": "Creixomil"
            },
            {
                "value": 60601,
                "label": "Canedo",
                "label_ar": "كانيدو",
                "label_fr": "Canedo"
            },
            {
                "value": 60853,
                "label": "Campo",
                "label_ar": "كامبو",
                "label_fr": "Campo"
            },
            {
                "value": 60250,
                "label": "Satao",
                "label_ar": "ساتاو",
                "label_fr": "Satao"
            },
            {
                "value": 60787,
                "label": "Ferreira",
                "label_ar": "فيريرا",
                "label_fr": "Ferreira"
            },
            {
                "value": 60399,
                "label": "Felgueiras",
                "label_ar": "فيلجيراس",
                "label_fr": "Felgueiras"
            },
            {
                "value": 60694,
                "label": "Fogueteiro",
                "label_ar": "فوجيتيرو",
                "label_fr": "Fogueteiro"
            },
            {
                "value": 60349,
                "label": "Leiria",
                "label_ar": "ليريا",
                "label_fr": "Leiria"
            },
            {
                "value": 60499,
                "label": "Palhais",
                "label_ar": "Palhais",
                "label_fr": "Palhais"
            },
            {
                "value": 60323,
                "label": "Nazaré",
                "label_ar": "نازاري",
                "label_fr": "Nazaré"
            },
            {
                "value": 60222,
                "label": "Marco",
                "label_ar": "ماركو",
                "label_fr": "Marco"
            },
            {
                "value": 60757,
                "label": "Povoa de Santo Adriao",
                "label_ar": "بوفوا دي سانتو أدرياو",
                "label_fr": "Povoa de Santo Adriao"
            },
            {
                "value": 60343,
                "label": "Samora Correia",
                "label_ar": "سامورا كوريا",
                "label_fr": "Samora Correia"
            },
            {
                "value": 60213,
                "label": "Maia",
                "label_ar": "مايا",
                "label_fr": "Maia"
            },
            {
                "value": 60866,
                "label": "Samil",
                "label_ar": "سميل",
                "label_fr": "Samil"
            },
            {
                "value": 60337,
                "label": "Azueira",
                "label_ar": "أزويرا",
                "label_fr": "Azueira"
            },
            {
                "value": 60437,
                "label": "Povoa de Lanhoso",
                "label_ar": "بوفوا دي لانهوسو",
                "label_fr": "Povoa de Lanhoso"
            },
            {
                "value": 60604,
                "label": "Oliveira do Hospital",
                "label_ar": "مستشفى أوليفيرا",
                "label_fr": "Oliveira do Hospital"
            },
            {
                "value": 60417,
                "label": "Angra do Heroísmo",
                "label_ar": "Angra do Heroísmo",
                "label_fr": "Angra do Heroísmo"
            },
            {
                "value": 60542,
                "label": "Sao Bras de Alportel",
                "label_ar": "ساو براس دي ألبورتيل",
                "label_fr": "Sao Bras de Alportel"
            },
            {
                "value": 60472,
                "label": "Seixal",
                "label_ar": "Seixal",
                "label_fr": "Seixal"
            },
            {
                "value": 60411,
                "label": "Barreiro",
                "label_ar": "باريرو",
                "label_fr": "Barreiro"
            },
            {
                "value": 60711,
                "label": "Travanca",
                "label_ar": "ترافانكا",
                "label_fr": "Travanca"
            },
            {
                "value": 60320,
                "label": "Benedita",
                "label_ar": "بنديتا",
                "label_fr": "Benedita"
            },
            {
                "value": 60865,
                "label": "Dois Portos",
                "label_ar": "دويس بورتوس",
                "label_fr": "Dois Portos"
            },
            {
                "value": 60596,
                "label": "Argoncilhe",
                "label_ar": "أرغونسيلي",
                "label_fr": "Argoncilhe"
            },
            {
                "value": 60259,
                "label": "Oliveira do Bairro",
                "label_ar": "أوليفيرا دو بايرو",
                "label_fr": "Oliveira do Bairro"
            },
            {
                "value": 60477,
                "label": "Tavira Municipality",
                "label_ar": "بلدية تافيرا",
                "label_fr": "Municipalité de Tavira"
            },
            {
                "value": 60627,
                "label": "Pero Pinheiro",
                "label_ar": "بيرو بينيرو",
                "label_fr": "Pero Pinheiro"
            },
            {
                "value": 60573,
                "label": "Cabecudos",
                "label_ar": "كابيكودوس",
                "label_fr": "Cabecudos"
            },
            {
                "value": 60292,
                "label": "Seia",
                "label_ar": "سيا",
                "label_fr": "Seia"
            },
            {
                "value": 60230,
                "label": "Coimbra",
                "label_ar": "كويمبرا",
                "label_fr": "Coimbra"
            },
            {
                "value": 60600,
                "label": "Portel",
                "label_ar": "بورتل",
                "label_fr": "Portel"
            },
            {
                "value": 60370,
                "label": "Obidos",
                "label_ar": "أوبيدوس",
                "label_fr": "Obidos"
            },
            {
                "value": 60476,
                "label": "Loule",
                "label_ar": "لولي",
                "label_fr": "Loule"
            },
            {
                "value": 60511,
                "label": "Vila Nogueira de Azeitao",
                "label_ar": "Vila Nogueira de Azeitao",
                "label_fr": "Vila Nogueira de Azeitao"
            },
            {
                "value": 60564,
                "label": "Golega",
                "label_ar": "جوليجا",
                "label_fr": "Golega"
            },
            {
                "value": 60440,
                "label": "Bucelas",
                "label_ar": "بوكيلاس",
                "label_fr": "Bucelas"
            },
            {
                "value": 60555,
                "label": "Penha Garcia",
                "label_ar": "بينها جارسيا",
                "label_fr": "Penha Garcia"
            },
            {
                "value": 60242,
                "label": "Miranda do Corvo",
                "label_ar": "ميراندا دو كورفو",
                "label_fr": "Miranda do Corvo"
            },
            {
                "value": 60457,
                "label": "Leca da Palmeira",
                "label_ar": "ليكا دا بالميرا",
                "label_fr": "Leca da Palmeira"
            },
            {
                "value": 60193,
                "label": "Mirandela",
                "label_ar": "ميرانديلا",
                "label_fr": "Mirandela"
            },
            {
                "value": 60485,
                "label": "Parede",
                "label_ar": "باريد",
                "label_fr": "Parede"
            },
            {
                "value": 60305,
                "label": "Valverde",
                "label_ar": "فالفيردي",
                "label_fr": "Valverde"
            },
            {
                "value": 60844,
                "label": "Pinheiro da Bemposta",
                "label_ar": "بينيرو دا بيمبوستا",
                "label_fr": "Pinheiro da Bemposta"
            },
            {
                "value": 60549,
                "label": "Caldas de Vizela",
                "label_ar": "كالداس دي فيزيلا",
                "label_fr": "Caldas de Vizela"
            },
            {
                "value": 60855,
                "label": "Avidos",
                "label_ar": "أفيدوس",
                "label_fr": "Avidos"
            },
            {
                "value": 60422,
                "label": "Rio de Mouro",
                "label_ar": "ريو دي مورو",
                "label_fr": "Rio de Mouro"
            },
            {
                "value": 60897,
                "label": "Fiaes",
                "label_ar": "فيايس",
                "label_fr": "Fiaes"
            },
            {
                "value": 60380,
                "label": "Zambujal",
                "label_ar": "زامبوجال",
                "label_fr": "Zambujal"
            },
            {
                "value": 60272,
                "label": "Vale de Cambra",
                "label_ar": "فالي دي كامبرا",
                "label_fr": "Vale de Cambra"
            },
            {
                "value": 60270,
                "label": "Sao Joao da Madeira",
                "label_ar": "ساو جواو دا ماديرا",
                "label_fr": "Sao Joao da Madeira"
            },
            {
                "value": 60846,
                "label": "Soalheira",
                "label_ar": "صوالحيرة",
                "label_fr": "Soalheira"
            },
            {
                "value": 60576,
                "label": "Canical",
                "label_ar": "Canical",
                "label_fr": "Canical"
            },
            {
                "value": 60640,
                "label": "Altura",
                "label_ar": "ألتورا",
                "label_fr": "Altura"
            },
            {
                "value": 60464,
                "label": "Seixal",
                "label_ar": "Seixal",
                "label_fr": "Seixal"
            },
            {
                "value": 60812,
                "label": "Macas de Caminho",
                "label_ar": "Macas de Caminho",
                "label_fr": "Macas de Caminho"
            },
            {
                "value": 60572,
                "label": "Vila Nova",
                "label_ar": "فيلا نوفا",
                "label_fr": "Vila Nova"
            },
            {
                "value": 60632,
                "label": "Algoz",
                "label_ar": "الجوز",
                "label_fr": "Algoz"
            },
            {
                "value": 60405,
                "label": "Queluz",
                "label_ar": "كيلوز",
                "label_fr": "Queluz"
            },
            {
                "value": 60392,
                "label": "Sao Romao do Coronado",
                "label_ar": "ساو روماو دو كورونادو",
                "label_fr": "Sao Romao do Coronado"
            },
            {
                "value": 60228,
                "label": "Tarouca",
                "label_ar": "تاروكا",
                "label_fr": "Tarouca"
            },
            {
                "value": 60226,
                "label": "Ponte de Lima",
                "label_ar": "بونتي دي ليما",
                "label_fr": "Ponte de Lima"
            },
            {
                "value": 60421,
                "label": "Moscavide",
                "label_ar": "موسكافيد",
                "label_fr": "Moscavide"
            },
            {
                "value": 60650,
                "label": "Vila Alva",
                "label_ar": "فيلا الفا",
                "label_fr": "Vila Alva"
            },
            {
                "value": 60528,
                "label": "Ramada",
                "label_ar": "رمادا",
                "label_fr": "Ramada"
            },
            {
                "value": 60619,
                "label": "Estombar",
                "label_ar": "Estombar",
                "label_fr": "Estombar"
            },
            {
                "value": 60807,
                "label": "Amorim",
                "label_ar": "أموريم",
                "label_fr": "Amorim"
            },
            {
                "value": 60265,
                "label": "Oliveira de Azeméis",
                "label_ar": "أوليفيرا دي أزميس",
                "label_fr": "Oliveira de Azeméis"
            },
            {
                "value": 60364,
                "label": "Pataias",
                "label_ar": "باتايا",
                "label_fr": "Pataias"
            },
            {
                "value": 60373,
                "label": "Praia do Ribatejo",
                "label_ar": "برايا دو ريباتيجو",
                "label_fr": "Praia do Ribatejo"
            },
            {
                "value": 60449,
                "label": "Amares",
                "label_ar": "أماريس",
                "label_fr": "Amares"
            },
            {
                "value": 60445,
                "label": "Valongo",
                "label_ar": "فالونجو",
                "label_fr": "Valongo"
            },
            {
                "value": 60665,
                "label": "Grandola",
                "label_ar": "غراندولا",
                "label_fr": "Grandola"
            },
            {
                "value": 60832,
                "label": "Alvelos",
                "label_ar": "ألفيلوس",
                "label_fr": "Alvelos"
            },
            {
                "value": 60296,
                "label": "Lagarinhos",
                "label_ar": "لاغارينوس",
                "label_fr": "Lagarinhos"
            },
            {
                "value": 60313,
                "label": "Cartaxo",
                "label_ar": "كارتاكسو",
                "label_fr": "Cartaxo"
            },
            {
                "value": 60339,
                "label": "Ericeira",
                "label_ar": "إريسيرا",
                "label_fr": "Ericeira"
            },
            {
                "value": 60382,
                "label": "Benavente",
                "label_ar": "بينافينتي",
                "label_fr": "Benavente"
            },
            {
                "value": 60696,
                "label": "Escoural",
                "label_ar": "اسكورال",
                "label_fr": "Escoural"
            },
            {
                "value": 60762,
                "label": "Sao Joao da Talha",
                "label_ar": "ساو جواو دا طلحة",
                "label_fr": "Sao Joao da Talha"
            },
            {
                "value": 60218,
                "label": "Porto",
                "label_ar": "بورتو",
                "label_fr": "Porto"
            },
            {
                "value": 60427,
                "label": "Amadora",
                "label_ar": "أمادورا",
                "label_fr": "Amadora"
            },
            {
                "value": 60247,
                "label": "Águeda Municipality",
                "label_ar": "بلدية أجودا",
                "label_fr": "Municipalité d'Águeda"
            },
            {
                "value": 60307,
                "label": "Santarém",
                "label_ar": "سانتاريم",
                "label_fr": "Santarém"
            },
            {
                "value": 60879,
                "label": "Montes",
                "label_ar": "مونتيس",
                "label_fr": "Montes"
            },
            {
                "value": 60704,
                "label": "Trofa",
                "label_ar": "تروفا",
                "label_fr": "Trofa"
            },
            {
                "value": 60677,
                "label": "Montelavar",
                "label_ar": "مونتيلافار",
                "label_fr": "Montelavar"
            },
            {
                "value": 60548,
                "label": "Barcarena",
                "label_ar": "باركارينا",
                "label_fr": "Barcarena"
            },
            {
                "value": 60489,
                "label": "Costa de Caparica",
                "label_ar": "كوستا دي كاباريكا",
                "label_fr": "Costa de Caparica"
            },
            {
                "value": 60187,
                "label": "Boticas",
                "label_ar": "البوتيكاس",
                "label_fr": "Boticas"
            },
            {
                "value": 60291,
                "label": "Guarda",
                "label_ar": "غواردا",
                "label_fr": "Guarda"
            },
            {
                "value": 60217,
                "label": "Carvalhosa",
                "label_ar": "كارفالهوسا",
                "label_fr": "Carvalhosa"
            },
            {
                "value": 60534,
                "label": "Colares",
                "label_ar": "كولاريس",
                "label_fr": "Colares"
            },
            {
                "value": 60616,
                "label": "Lavradio",
                "label_ar": "لافراديو",
                "label_fr": "Lavradio"
            },
            {
                "value": 60431,
                "label": "Ponta Delgada",
                "label_ar": "بونتا ديلجادا",
                "label_fr": "Ponta Delgada"
            },
            {
                "value": 60845,
                "label": "Nogueira",
                "label_ar": "نوغيرا",
                "label_fr": "Nogueira"
            },
            {
                "value": 60860,
                "label": "Ponte",
                "label_ar": "بونتي",
                "label_fr": "Ponte"
            },
            {
                "value": 60336,
                "label": "Mafra",
                "label_ar": "مافرا",
                "label_fr": "Mafra"
            },
            {
                "value": 60847,
                "label": "Tocha",
                "label_ar": "توشا",
                "label_fr": "Tocha"
            },
            {
                "value": 60471,
                "label": "Lourinhã",
                "label_ar": "لورينها",
                "label_fr": "Lourinhã"
            },
            {
                "value": 60401,
                "label": "Rio Tinto",
                "label_ar": "ريو تينتو",
                "label_fr": "Rio Tinto"
            },
            {
                "value": 60255,
                "label": "Mamouros",
                "label_ar": "ماموروس",
                "label_fr": "Mamouros"
            },
            {
                "value": 60613,
                "label": "Damaia",
                "label_ar": "دامايا",
                "label_fr": "Damaia"
            },
            {
                "value": 60568,
                "label": "Ponte de Vagos",
                "label_ar": "بونتي دي فاجوس",
                "label_fr": "Ponte de Vagos"
            },
            {
                "value": 60607,
                "label": "Gulpilhares",
                "label_ar": "جولبيلهاريس",
                "label_fr": "Gulpilhares"
            },
            {
                "value": 60669,
                "label": "Viana do Alentejo",
                "label_ar": "فيانا دو ألينتيخو",
                "label_fr": "Viana do Alentejo"
            },
            {
                "value": 60284,
                "label": "Vale",
                "label_ar": "فالي",
                "label_fr": "Vallée"
            },
            {
                "value": 60190,
                "label": "Miranda do Douro",
                "label_ar": "ميراندا دو دورو",
                "label_fr": "Miranda do Douro"
            },
            {
                "value": 60308,
                "label": "Vale da Pinta",
                "label_ar": "فالي دا بينتا",
                "label_fr": "Vale da Pinta"
            },
            {
                "value": 60571,
                "label": "Câmara de Lobos",
                "label_ar": "كامارا دي لوبوس",
                "label_fr": "Câmara de Lobos"
            },
            {
                "value": 60623,
                "label": "Estoi",
                "label_ar": "إستوي",
                "label_fr": "Estoi"
            },
            {
                "value": 60772,
                "label": "Milhazes",
                "label_ar": "ملهزات",
                "label_fr": "Milhazes"
            },
            {
                "value": 60544,
                "label": "Almodovar",
                "label_ar": "المودوفار",
                "label_fr": "Almodovar"
            },
            {
                "value": 60241,
                "label": "Sao Joao do Campo",
                "label_ar": "ساو جواو دو كامبو",
                "label_fr": "Sao Joao do Campo"
            },
            {
                "value": 60510,
                "label": "Santo Antonio",
                "label_ar": "سانتو انطونيو",
                "label_fr": "Santo Antonio"
            },
            {
                "value": 60691,
                "label": "Outeiro",
                "label_ar": "أوتيرو",
                "label_fr": "Outeiro"
            },
            {
                "value": 60288,
                "label": "Vieira de Leiria",
                "label_ar": "فييرا دي ليريا",
                "label_fr": "Vieira de Leiria"
            },
            {
                "value": 60232,
                "label": "Cernache",
                "label_ar": "Cernache",
                "label_fr": "Cernache"
            },
            {
                "value": 60202,
                "label": "Torre de Moncorvo",
                "label_ar": "توري دي مونكورفو",
                "label_fr": "Torre de Moncorvo"
            },
            {
                "value": 60586,
                "label": "Sanguedo",
                "label_ar": "سانجيدو",
                "label_fr": "Sanguedo"
            },
            {
                "value": 60779,
                "label": "Vilar de Perdizes",
                "label_ar": "فيلار دي بيرديزيس",
                "label_fr": "Vilar de Perdizes"
            },
            {
                "value": 60759,
                "label": "Camarate",
                "label_ar": "كاماراتي",
                "label_fr": "Camarate"
            },
            {
                "value": 60843,
                "label": "Darque",
                "label_ar": "داركي",
                "label_fr": "Darque"
            },
            {
                "value": 60681,
                "label": "Vila Real de Santo António",
                "label_ar": "فيلا ريال دي سانتو أنطونيو",
                "label_fr": "Vila Real de Santo António"
            },
            {
                "value": 60825,
                "label": "Salto",
                "label_ar": "سالتو",
                "label_fr": "Salto"
            },
            {
                "value": 60565,
                "label": "Arruda dos Vinhos",
                "label_ar": "أرودا دوس فينوس",
                "label_fr": "Arruda dos Vinhos"
            },
            {
                "value": 60379,
                "label": "Graca",
                "label_ar": "جراسا",
                "label_fr": "Graca"
            },
            {
                "value": 60649,
                "label": "Cacilhas",
                "label_ar": "كاسيلاس",
                "label_fr": "Cacilhas"
            },
            {
                "value": 60516,
                "label": "Sarilhos Grandes",
                "label_ar": "Sarilhos Grandes",
                "label_fr": "Sarilhos Grandes"
            },
            {
                "value": 60428,
                "label": "Cacela",
                "label_ar": "كاسيلا",
                "label_fr": "Cacela"
            },
            {
                "value": 60699,
                "label": "Borba",
                "label_ar": "بوربا",
                "label_fr": "Borba"
            },
            {
                "value": 60279,
                "label": "Mourisca do Vouga",
                "label_ar": "موريسا دو فوجا",
                "label_fr": "Mourisca do Vouga"
            },
            {
                "value": 60578,
                "label": "Santa Cruz",
                "label_ar": "سانتا كروز",
                "label_fr": "Santa Cruz"
            },
            {
                "value": 60629,
                "label": "Bicesse",
                "label_ar": "بيسيس",
                "label_fr": "Bicesse"
            },
            {
                "value": 60615,
                "label": "Canecas",
                "label_ar": "كانيكاس",
                "label_fr": "Canecas"
            },
            {
                "value": 60262,
                "label": "Anadia",
                "label_ar": "أناديا",
                "label_fr": "Anadia"
            },
            {
                "value": 60743,
                "label": "Malveira da Serra",
                "label_ar": "مالفيرا دا سيرا",
                "label_fr": "Malveira da Serra"
            },
            {
                "value": 60371,
                "label": "Vila Nova da Barquinha",
                "label_ar": "فيلا نوفا دا باركوينها",
                "label_fr": "Vila Nova da Barquinha"
            },
            {
                "value": 60183,
                "label": "Amarante",
                "label_ar": "امارانتي",
                "label_fr": "Amarante"
            },
            {
                "value": 60575,
                "label": "Povoa do Forno",
                "label_ar": "بوفوا دو فورنو",
                "label_fr": "Povoa do Forno"
            },
            {
                "value": 60429,
                "label": "Palmela",
                "label_ar": "بالميلا",
                "label_fr": "Palmela"
            },
            {
                "value": 60580,
                "label": "Cortegaca",
                "label_ar": "كورتيجاكا",
                "label_fr": "Cortegaca"
            },
            {
                "value": 60455,
                "label": "Vila Nova",
                "label_ar": "فيلا نوفا",
                "label_fr": "Vila Nova"
            },
            {
                "value": 60605,
                "label": "Sabugal",
                "label_ar": "Sabugal",
                "label_fr": "Sabugal"
            },
            {
                "value": 60590,
                "label": "Sao Francisco",
                "label_ar": "ساو فرانسيسكو",
                "label_fr": "Sao Francisco"
            },
            {
                "value": 60792,
                "label": "Sao Pedro da Cadeira",
                "label_ar": "ساو بيدرو دا كاديرا",
                "label_fr": "Sao Pedro da Cadeira"
            },
            {
                "value": 60426,
                "label": "Vizela",
                "label_ar": "فيزيلا",
                "label_fr": "Vizela"
            },
            {
                "value": 60522,
                "label": "Montemor-o-Novo",
                "label_ar": "مونتيمور أو نوفو",
                "label_fr": "Montemor-o-Novo"
            },
            {
                "value": 60546,
                "label": "Rinchoa",
                "label_ar": "رينشوا",
                "label_fr": "Rinchoa"
            },
            {
                "value": 60647,
                "label": "Abobada",
                "label_ar": "أبوبادا",
                "label_fr": "Abobada"
            },
            {
                "value": 60210,
                "label": "Castelo",
                "label_ar": "كاستيلو",
                "label_fr": "Castelo"
            },
            {
                "value": 60348,
                "label": "Pombal",
                "label_ar": "بومبال",
                "label_fr": "Pombal"
            },
            {
                "value": 60366,
                "label": "Martinganca",
                "label_ar": "مارتينجانكا",
                "label_fr": "Martinganca"
            },
            {
                "value": 60639,
                "label": "Castro Marim",
                "label_ar": "كاسترو ماريم",
                "label_fr": "Castro Marim"
            },
            {
                "value": 60452,
                "label": "Fafe",
                "label_ar": "فافي",
                "label_fr": "Fafe"
            },
            {
                "value": 60438,
                "label": "Almancil",
                "label_ar": "المانسيل",
                "label_fr": "Almancil"
            },
            {
                "value": 60333,
                "label": "Constancia",
                "label_ar": "كونستانسيا",
                "label_fr": "Constancia"
            },
            {
                "value": 60562,
                "label": "Chao de Couce",
                "label_ar": "تشاو دي كوتش",
                "label_fr": "Chao de Couce"
            },
            {
                "value": 60463,
                "label": "Vialonga",
                "label_ar": "فيالونجا",
                "label_fr": "Vialonga"
            },
            {
                "value": 60676,
                "label": "Paderne",
                "label_ar": "بادرن",
                "label_fr": "Paderne"
            },
            {
                "value": 60206,
                "label": "Paredes de Coura",
                "label_ar": "باريديس دي كورا",
                "label_fr": "Paredes de Coura"
            },
            {
                "value": 60756,
                "label": "Penela",
                "label_ar": "بينيلا",
                "label_fr": "Penela"
            },
            {
                "value": 60237,
                "label": "Montemor-o-Velho",
                "label_ar": "مونتيمور-أو-فيلهو",
                "label_fr": "Montemor-o-Velho"
            },
            {
                "value": 60671,
                "label": "Penteado",
                "label_ar": "بنتيدو",
                "label_fr": "Penteado"
            },
            {
                "value": 60327,
                "label": "Tomar",
                "label_ar": "تومار",
                "label_fr": "Tomar"
            },
            {
                "value": 60842,
                "label": "Avanca",
                "label_ar": "أفانكا",
                "label_fr": "Avanca"
            },
            {
                "value": 60834,
                "label": "Santiago da Guarda",
                "label_ar": "سانتياغو دا غواردا",
                "label_fr": "Santiago da Guarda"
            },
            {
                "value": 60261,
                "label": "Poiares",
                "label_ar": "بواريس",
                "label_fr": "Poiares"
            },
            {
                "value": 60260,
                "label": "Mealhada",
                "label_ar": "ميلهادا",
                "label_fr": "Mealhada"
            },
            {
                "value": 60722,
                "label": "Porto",
                "label_ar": "بورتو",
                "label_fr": "Porto"
            },
            {
                "value": 60840,
                "label": "Marteleira",
                "label_ar": "مارتيليرا",
                "label_fr": "Marteleira"
            },
            {
                "value": 60514,
                "label": "Santo Antonio da Charneca",
                "label_ar": "سانتو أنطونيو دا تشارنيكا",
                "label_fr": "Santo Antonio da Charneca"
            },
            {
                "value": 60582,
                "label": "Corroios",
                "label_ar": "كورووس",
                "label_fr": "Corroios"
            },
            {
                "value": 60703,
                "label": "Vila Verde",
                "label_ar": "فيلا فيردي",
                "label_fr": "Vila Verde"
            },
            {
                "value": 60278,
                "label": "Estarreja",
                "label_ar": "Estarreja",
                "label_fr": "Estarreja"
            },
            {
                "value": 60697,
                "label": "Povoa da Galega",
                "label_ar": "بوفوا دا جاليجا",
                "label_fr": "Povoa da Galega"
            },
            {
                "value": 60330,
                "label": "Minde",
                "label_ar": "ميندي",
                "label_fr": "Minde"
            },
            {
                "value": 60357,
                "label": "Calvaria de Cima",
                "label_ar": "كالفاريا دي سيما",
                "label_fr": "Calvaria de Cima"
            },
            {
                "value": 60518,
                "label": "Sesimbra",
                "label_ar": "سيسيمبرا",
                "label_fr": "Sesimbra"
            },
            {
                "value": 60215,
                "label": "Santo Tirso",
                "label_ar": "سانتو تيرسو",
                "label_fr": "Santo Tirso"
            },
            {
                "value": 60667,
                "label": "Campanario",
                "label_ar": "كامباناريو",
                "label_fr": "Campanario"
            },
            {
                "value": 60751,
                "label": "Arcozelo",
                "label_ar": "أركوزيلو",
                "label_fr": "Arcozelo"
            },
            {
                "value": 60684,
                "label": "Souzel",
                "label_ar": "Souzel",
                "label_fr": "Souzel"
            },
            {
                "value": 60868,
                "label": "Beira",
                "label_ar": "بيرا",
                "label_fr": "Beira"
            },
            {
                "value": 60367,
                "label": "Serpins",
                "label_ar": "السربين",
                "label_fr": "Serpins"
            },
            {
                "value": 60540,
                "label": "Milharado",
                "label_ar": "ميلهاردو",
                "label_fr": "Milharado"
            },
            {
                "value": 60345,
                "label": "Sacavem",
                "label_ar": "ساكافيم",
                "label_fr": "Sacavem"
            },
            {
                "value": 60355,
                "label": "Marinha Grande",
                "label_ar": "مارينها غراندي",
                "label_fr": "Marinha Grande"
            },
            {
                "value": 60735,
                "label": "Forte",
                "label_ar": "فورتي",
                "label_fr": "Forte"
            },
            {
                "value": 60358,
                "label": "Maceira",
                "label_ar": "ماسيرا",
                "label_fr": "Maceira"
            },
            {
                "value": 60789,
                "label": "Alcanede",
                "label_ar": "الكانيد",
                "label_fr": "Alcanède"
            },
            {
                "value": 60794,
                "label": "Folgados",
                "label_ar": "فولجادوس",
                "label_fr": "Folgados"
            },
            {
                "value": 60593,
                "label": "Valado de Frades",
                "label_ar": "فالادو دي فراديس",
                "label_fr": "Valado de Frades"
            },
            {
                "value": 60293,
                "label": "Gouveia",
                "label_ar": "جوفيا",
                "label_fr": "Gouveia"
            },
            {
                "value": 60707,
                "label": "Santa Marta",
                "label_ar": "سانتا مارتا",
                "label_fr": "Santa Marta"
            },
            {
                "value": 60741,
                "label": "Bico",
                "label_ar": "بيكو",
                "label_fr": "Bico"
            },
            {
                "value": 60467,
                "label": "Alges",
                "label_ar": "الزوايا",
                "label_fr": "Alges"
            },
            {
                "value": 60239,
                "label": "Granja do Ulmeiro",
                "label_ar": "Granja do Ulmeiro",
                "label_fr": "Granja do Ulmeiro"
            },
            {
                "value": 60651,
                "label": "Tunes",
                "label_ar": "الألحان",
                "label_fr": "Mélodies"
            },
            {
                "value": 60782,
                "label": "Seroa",
                "label_ar": "سيروا",
                "label_fr": "Seroa"
            },
            {
                "value": 60525,
                "label": "Redondo",
                "label_ar": "ريدوندو",
                "label_fr": "Redondo"
            },
            {
                "value": 60374,
                "label": "Torres Novas",
                "label_ar": "توريس نوفاس",
                "label_fr": "Torres Novas"
            },
            {
                "value": 60252,
                "label": "Parada de Gonta",
                "label_ar": "بارادا دي جونتا",
                "label_fr": "Parada de Gonta"
            },
            {
                "value": 60430,
                "label": "Macieira da Lixa",
                "label_ar": "ماسييرا دا ليكسا",
                "label_fr": "Macieira da Lixa"
            },
            {
                "value": 60589,
                "label": "Vidigueira",
                "label_ar": "فيديجويرا",
                "label_fr": "Vidigueira"
            },
            {
                "value": 60753,
                "label": "Baguim",
                "label_ar": "باقيم",
                "label_fr": "Baguim"
            },
            {
                "value": 60234,
                "label": "Alfarelos",
                "label_ar": "الفاريلوس",
                "label_fr": "Alfarelos"
            },
            {
                "value": 60352,
                "label": "Costa de Lavos",
                "label_ar": "كوستا دي لافوس",
                "label_fr": "Costa de Lavos"
            },
            {
                "value": 60577,
                "label": "Canico",
                "label_ar": "كانيكو",
                "label_fr": "Canico"
            },
            {
                "value": 60538,
                "label": "Estremoz",
                "label_ar": "إستريموز",
                "label_fr": "Estremoz"
            },
            {
                "value": 60887,
                "label": "Ribeira Brava",
                "label_ar": "ريبيرا برافا",
                "label_fr": "Ribeira Brava"
            },
            {
                "value": 60294,
                "label": "Vilar Formoso",
                "label_ar": "فيلار فورموسو",
                "label_fr": "Vilar Formoso"
            },
            {
                "value": 60715,
                "label": "Azurem",
                "label_ar": "أزوريم",
                "label_fr": "Azurem"
            },
            {
                "value": 60300,
                "label": "Lisbon",
                "label_ar": "لشبونة",
                "label_fr": "Lisbonne"
            },
            {
                "value": 60655,
                "label": "Arronches",
                "label_ar": "أرونشس",
                "label_fr": "Arronches"
            },
            {
                "value": 60377,
                "label": "Figueiro dos Vinhos",
                "label_ar": "فيجيرو دوس فينوس",
                "label_fr": "Figueiro dos Vinhos"
            },
            {
                "value": 60850,
                "label": "Parada do Bispo",
                "label_ar": "بارادا دو بيسبو",
                "label_fr": "Parada do Bispo"
            },
            {
                "value": 60814,
                "label": "Modivas",
                "label_ar": "موديفاس",
                "label_fr": "Modivas"
            },
            {
                "value": 60478,
                "label": "Sao Bras",
                "label_ar": "ساو براس",
                "label_fr": "Sao Bras"
            },
            {
                "value": 60248,
                "label": "Campia",
                "label_ar": "كامبيا",
                "label_fr": "Campia"
            },
            {
                "value": 60413,
                "label": "Bouca",
                "label_ar": "بوكا",
                "label_fr": "Bouca"
            },
            {
                "value": 60195,
                "label": "Vinhais",
                "label_ar": "فينهايس",
                "label_fr": "Vinhais"
            },
            {
                "value": 60184,
                "label": "Sanfins do Douro",
                "label_ar": "سانفينز دو دورو",
                "label_fr": "Sanfins do Douro"
            },
            {
                "value": 60338,
                "label": "Torres Vedras",
                "label_ar": "توريس فيدراس",
                "label_fr": "Torres Vedras"
            },
            {
                "value": 60550,
                "label": "Aguiar da Beira Municipality",
                "label_ar": "بلدية اغيار دا بيرا",
                "label_fr": "Municipalité d'Aguiar da Beira"
            },
            {
                "value": 60321,
                "label": "Atouguia da Baleia",
                "label_ar": "أتوجية دا بالية",
                "label_fr": "Atouguia da Baleia"
            },
            {
                "value": 60648,
                "label": "Cabeco de Vide",
                "label_ar": "كابيكو دي فيدي",
                "label_fr": "Cabeco de Vide"
            },
            {
                "value": 60644,
                "label": "Tires",
                "label_ar": "الإطارات",
                "label_fr": "Pneus"
            },
            {
                "value": 60520,
                "label": "Lousa",
                "label_ar": "لوسا",
                "label_fr": "Lousa"
            },
            {
                "value": 60496,
                "label": "Quinta",
                "label_ar": "كينتا",
                "label_fr": "Quinta"
            },
            {
                "value": 60316,
                "label": "Penacova",
                "label_ar": "بيناكوفا",
                "label_fr": "Penacova"
            },
            {
                "value": 60537,
                "label": "Ourique",
                "label_ar": "أوريك",
                "label_fr": "Ourique"
            },
            {
                "value": 60880,
                "label": "Telhado",
                "label_ar": "تلحدو",
                "label_fr": "Telhado"
            },
            {
                "value": 60273,
                "label": "Santiago de Riba-Ul",
                "label_ar": "سانتياغو دي ريبا أول",
                "label_fr": "Santiago de Riba-Ul"
            },
            {
                "value": 60720,
                "label": "Guimaraes",
                "label_ar": "غيماريش",
                "label_fr": "Guimarães"
            },
            {
                "value": 60882,
                "label": "Apulia",
                "label_ar": "بوليا",
                "label_fr": "Pouilles"
            },
            {
                "value": 60240,
                "label": "Carapinheira",
                "label_ar": "كارابينيرا",
                "label_fr": "Carapinheira"
            },
            {
                "value": 60721,
                "label": "Brito",
                "label_ar": "بريتو",
                "label_fr": "Brito"
            },
            {
                "value": 60780,
                "label": "Montes",
                "label_ar": "مونتيس",
                "label_fr": "Montes"
            },
            {
                "value": 60869,
                "label": "Ramo",
                "label_ar": "رامو",
                "label_fr": "Ramo"
            },
            {
                "value": 60453,
                "label": "Povoa de Santa Iria",
                "label_ar": "بوفوا دي سانتا إيريا",
                "label_fr": "Povoa de Santa Iria"
            },
            {
                "value": 60625,
                "label": "Pegoes Velhos",
                "label_ar": "بيجوس فيلهوس",
                "label_fr": "Pegoes Velhos"
            },
            {
                "value": 60798,
                "label": "Figueira de Lorvao",
                "label_ar": "فيجويرا دي لورفاو",
                "label_fr": "Figueira de Lorvao"
            },
            {
                "value": 60465,
                "label": "Paco de Arcos",
                "label_ar": "باكو دي أركوس",
                "label_fr": "Paco de Arcos"
            },
            {
                "value": 60407,
                "label": "Amora",
                "label_ar": "أمورا",
                "label_fr": "Amora"
            },
            {
                "value": 60831,
                "label": "Prado",
                "label_ar": "برادو",
                "label_fr": "Prado"
            },
            {
                "value": 60189,
                "label": "Vila Real",
                "label_ar": "فيلا ريال",
                "label_fr": "Vila Real"
            },
            {
                "value": 60893,
                "label": "Tourais",
                "label_ar": "توريس",
                "label_fr": "Tourais"
            },
            {
                "value": 60595,
                "label": "Valega",
                "label_ar": "فاليجا",
                "label_fr": "Valega"
            },
            {
                "value": 60408,
                "label": "Moita",
                "label_ar": "مويتا",
                "label_fr": "Moita"
            },
            {
                "value": 60750,
                "label": "Sangalhos",
                "label_ar": "Sangalhos",
                "label_fr": "Sangalhos"
            },
            {
                "value": 60745,
                "label": "Bemfica",
                "label_ar": "بيمفيكا",
                "label_fr": "Bemfica"
            },
            {
                "value": 60486,
                "label": "Sao Pedro do Estoril",
                "label_ar": "ساو بيدرو دو إستوريل",
                "label_fr": "Sao Pedro do Estoril"
            },
            {
                "value": 60690,
                "label": "Moura",
                "label_ar": "مورا",
                "label_fr": "Moura"
            },
            {
                "value": 60581,
                "label": "Pragal",
                "label_ar": "براغال",
                "label_fr": "Pragal"
            },
            {
                "value": 60258,
                "label": "Mortagua",
                "label_ar": "مورتاجوا",
                "label_fr": "Mortagua"
            },
            {
                "value": 60706,
                "label": "Marco de Canaveses",
                "label_ar": "ماركو دي كانافيس",
                "label_fr": "Marco de Canaveses"
            },
            {
                "value": 60263,
                "label": "Vilarinho do Bairro",
                "label_ar": "فيلارينيو دو بايرو",
                "label_fr": "Vilarinho do Bairro"
            },
            {
                "value": 60890,
                "label": "Salreu",
                "label_ar": "سالرو",
                "label_fr": "Salreu"
            },
            {
                "value": 60378,
                "label": "Vermoil",
                "label_ar": "فيرمويل",
                "label_fr": "Vermoil"
            },
            {
                "value": 60709,
                "label": "Vermelha",
                "label_ar": "فيرميلها",
                "label_fr": "Vermelha"
            },
            {
                "value": 60484,
                "label": "Praia do Almoxarife",
                "label_ar": "برايا دو الموكساريف",
                "label_fr": "Praia do Almoxarife"
            },
            {
                "value": 60432,
                "label": "Funchal",
                "label_ar": "فونشال",
                "label_fr": "Funchal"
            },
            {
                "value": 60497,
                "label": "Marinhais",
                "label_ar": "مارينهايس",
                "label_fr": "Marinhais"
            },
            {
                "value": 60335,
                "label": "Salvaterra de Magos",
                "label_ar": "سلفاتيرا دي ماجوس",
                "label_fr": "Salvaterra de Magos"
            },
            {
                "value": 60654,
                "label": "Nisa",
                "label_ar": "نيسا",
                "label_fr": "Nisa"
            },
            {
                "value": 60827,
                "label": "Mesao Frio",
                "label_ar": "ميساو فريو",
                "label_fr": "Mesao Frio"
            },
            {
                "value": 60524,
                "label": "Alandroal",
                "label_ar": "الأندروال",
                "label_fr": "Alandroal"
            },
            {
                "value": 60385,
                "label": "Espinho",
                "label_ar": "إسبينيو",
                "label_fr": "Espinho"
            },
            {
                "value": 60346,
                "label": "Alenquer",
                "label_ar": "الينكير",
                "label_fr": "Alenquer"
            },
            {
                "value": 60282,
                "label": "Romariz",
                "label_ar": "روماريز",
                "label_fr": "Romariz"
            },
            {
                "value": 60436,
                "label": "Cascais",
                "label_ar": "كاسكايس",
                "label_fr": "Cascais"
            },
            {
                "value": 60287,
                "label": "Matosinhos Municipality",
                "label_ar": "بلدية ماتوسينهوس",
                "label_fr": "Municipalité de Matosinhos"
            },
            {
                "value": 60889,
                "label": "Catraia de Sao Paio",
                "label_ar": "كاترايا دي ساو بايو",
                "label_fr": "Catraia de Sao Paio"
            },
            {
                "value": 60446,
                "label": "Caparica",
                "label_ar": "كاباريكا",
                "label_fr": "Caparica"
            },
            {
                "value": 60801,
                "label": "Mocarria",
                "label_ar": "موكاريا",
                "label_fr": "Mocarria"
            },
            {
                "value": 60360,
                "label": "Souto da Carpalhosa",
                "label_ar": "Souto da Carpalhosa",
                "label_fr": "Souto da Carpalhosa"
            },
            {
                "value": 60501,
                "label": "Samouco",
                "label_ar": "ساموكو",
                "label_fr": "Samouco"
            },
            {
                "value": 60480,
                "label": "Tercena",
                "label_ar": "تيرسينا",
                "label_fr": "Tercena"
            },
            {
                "value": 60724,
                "label": "Fao",
                "label_ar": "الفاو",
                "label_fr": "Fao"
            },
            {
                "value": 60451,
                "label": "Colmeias",
                "label_ar": "كولمياس",
                "label_fr": "Colmeias"
            },
            {
                "value": 60344,
                "label": "Vila Franca de Xira",
                "label_ar": "فيلا فرانكا دي زيرا",
                "label_fr": "Vila Franca de Xira"
            },
            {
                "value": 60643,
                "label": "Porto Moniz",
                "label_ar": "بورتو مونيز",
                "label_fr": "Porto Moniz"
            },
            {
                "value": 60695,
                "label": "Odemira",
                "label_ar": "Odemira",
                "label_fr": "Odemira"
            },
            {
                "value": 60498,
                "label": "Boliqueime",
                "label_ar": "بوليكيمي",
                "label_fr": "Boliqueime"
            },
            {
                "value": 60675,
                "label": "Sao Joao das Lampas",
                "label_ar": "ساو جواو داس لامباس",
                "label_fr": "Sao Joao das Lampas"
            },
            {
                "value": 60495,
                "label": "Santa Iria da Azoia",
                "label_ar": "سانتا إيريا دا أزويا",
                "label_fr": "Santa Iria da Azoia"
            },
            {
                "value": 60689,
                "label": "Moura",
                "label_ar": "مورا",
                "label_fr": "Moura"
            },
            {
                "value": 60742,
                "label": "Trafaria",
                "label_ar": "ترافاريا",
                "label_fr": "Trafaria"
            },
            {
                "value": 60674,
                "label": "Reguengos de Monsaraz",
                "label_ar": "Reguengos de Monsaraz",
                "label_fr": "Reguengos de Monsaraz"
            },
            {
                "value": 60318,
                "label": "Caldas da Rainha",
                "label_ar": "كالداس دا راينها",
                "label_fr": "Caldas da Rainha"
            },
            {
                "value": 60481,
                "label": "Agualva",
                "label_ar": "اجوالفا",
                "label_fr": "Agualva"
            },
            {
                "value": 60808,
                "label": "Agucadoura",
                "label_ar": "أجوكادورا",
                "label_fr": "Agucadoura"
            },
            {
                "value": 60683,
                "label": "Lourel de Baixo",
                "label_ar": "لوريل دي بايكسو",
                "label_fr": "Lourel de Baixo"
            },
            {
                "value": 60513,
                "label": "Porto da Cruz",
                "label_ar": "بورتو دا كروز",
                "label_fr": "Porto da Cruz"
            },
            {
                "value": 60506,
                "label": "Alhandra",
                "label_ar": "الهندرة",
                "label_fr": "Alhandra"
            },
            {
                "value": 60574,
                "label": "Felgueiras",
                "label_ar": "فيلجيراس",
                "label_fr": "Felgueiras"
            },
            {
                "value": 60770,
                "label": "Valbom",
                "label_ar": "فالبوم",
                "label_fr": "Valbom"
            },
            {
                "value": 60861,
                "label": "Alijó Municipality",
                "label_ar": "بلدية أليخو",
                "label_fr": "Municipalité d'Alijó"
            },
            {
                "value": 60359,
                "label": "Juncal",
                "label_ar": "جنكال",
                "label_fr": "Juncal"
            },
            {
                "value": 60257,
                "label": "Cantanhede",
                "label_ar": "كانتانهيد",
                "label_fr": "Cantanhede"
            },
            {
                "value": 60552,
                "label": "Sao Mamede de Infesta",
                "label_ar": "ساو ماميدي دي إنفيستا",
                "label_fr": "Sao Mamede de Infesta"
            },
            {
                "value": 60224,
                "label": "Vila Nova de Gaia",
                "label_ar": "فيلا نوفا دي جايا",
                "label_fr": "Vila Nova de Gaia"
            },
            {
                "value": 60851,
                "label": "Sever do Vouga",
                "label_ar": "سيفر دو فوجا",
                "label_fr": "Sever do Vouga"
            },
            {
                "value": 60748,
                "label": "Sul",
                "label_ar": "سول",
                "label_fr": "Sul"
            },
            {
                "value": 60334,
                "label": "Alcanena",
                "label_ar": "الكانينا",
                "label_fr": "Alcanena"
            },
            {
                "value": 60283,
                "label": "Sao Joao",
                "label_ar": "ساو جواو",
                "label_fr": "Sao Joao"
            },
            {
                "value": 60776,
                "label": "Canidelo",
                "label_ar": "كانديلو",
                "label_fr": "Canidelo"
            },
            {
                "value": 60400,
                "label": "Vila do Conde",
                "label_ar": "فيلا دو كوندي",
                "label_fr": "Vila do Conde"
            },
            {
                "value": 60900,
                "label": "Treixedo",
                "label_ar": "Treixedo",
                "label_fr": "Treixedo"
            },
            {
                "value": 60329,
                "label": "Entroncamento",
                "label_ar": "Entroncamento",
                "label_fr": "Entroncamento"
            },
            {
                "value": 60857,
                "label": "Negrelos",
                "label_ar": "نيجريلوس",
                "label_fr": "Negrelos"
            },
            {
                "value": 60755,
                "label": "Aldeia dos Fernandes",
                "label_ar": "Aldeia dos Fernandes",
                "label_fr": "Aldeia dos Fernandes"
            },
            {
                "value": 60533,
                "label": "Lagoa",
                "label_ar": "لاجوا",
                "label_fr": "Lagoa"
            },
            {
                "value": 60398,
                "label": "Alcochete",
                "label_ar": "الكوشيه",
                "label_fr": "Alcochete"
            },
            {
                "value": 60670,
                "label": "Monsaraz",
                "label_ar": "مونسارز",
                "label_fr": "Monsaraz"
            },
            {
                "value": 60760,
                "label": "Talaide",
                "label_ar": "تالايد",
                "label_fr": "Talaide"
            },
            {
                "value": 60561,
                "label": "Alcains",
                "label_ar": "ألكينز",
                "label_fr": "Alcains"
            },
            {
                "value": 60858,
                "label": "Lagoa",
                "label_ar": "لاجوا",
                "label_fr": "Lagoa"
            },
            {
                "value": 60347,
                "label": "Cercal",
                "label_ar": "سيركال",
                "label_fr": "Cercal"
            },
            {
                "value": 60483,
                "label": "Rana",
                "label_ar": "رنا",
                "label_fr": "Rana"
            },
            {
                "value": 60828,
                "label": "Guardizela",
                "label_ar": "جوارديزيلا",
                "label_fr": "Guardizela"
            },
            {
                "value": 60731,
                "label": "Sendim",
                "label_ar": "ماسنجر",
                "label_fr": "Sendim"
            },
            {
                "value": 60863,
                "label": "Ponta",
                "label_ar": "بونتا",
                "label_fr": "Ponta"
            },
            {
                "value": 60216,
                "label": "Paredes",
                "label_ar": "باريديس",
                "label_fr": "Paredes"
            },
            {
                "value": 60809,
                "label": "Povoa",
                "label_ar": "بوفوا",
                "label_fr": "Povoa"
            },
            {
                "value": 60631,
                "label": "Praia do Carvoeiro",
                "label_ar": "برايا دو كارفويرو",
                "label_fr": "Praia do Carvoeiro"
            },
            {
                "value": 60769,
                "label": "Foz do Sousa",
                "label_ar": "فوز دو سوزا",
                "label_fr": "Foz do Sousa"
            },
            {
                "value": 60588,
                "label": "Vendas Novas",
                "label_ar": "فينداس نوفاس",
                "label_fr": "Vendas Novas"
            },
            {
                "value": 60458,
                "label": "Acores",
                "label_ar": "أكوريس",
                "label_fr": "Acores"
            },
            {
                "value": 60491,
                "label": "Lagoa",
                "label_ar": "لاجوا",
                "label_fr": "Lagoa"
            },
            {
                "value": 60678,
                "label": "Canhas",
                "label_ar": "كاناس",
                "label_fr": "Canhas"
            },
            {
                "value": 60583,
                "label": "Ferro",
                "label_ar": "فيرو",
                "label_fr": "Ferro"
            },
            {
                "value": 60740,
                "label": "Sardoal",
                "label_ar": "ساردوال",
                "label_fr": "Sardoal"
            },
            {
                "value": 60418,
                "label": "Pinhal Novo",
                "label_ar": "بينهال نوفو",
                "label_fr": "Pinhal Novo"
            },
            {
                "value": 60624,
                "label": "Poceirao",
                "label_ar": "بوسيراو",
                "label_fr": "Poceirao"
            },
            {
                "value": 60693,
                "label": "Vimieiro",
                "label_ar": "فيميرو",
                "label_fr": "Vimieiro"
            },
            {
                "value": 60229,
                "label": "Mesao Frio",
                "label_ar": "ميساو فريو",
                "label_fr": "Mesao Frio"
            },
            {
                "value": 60698,
                "label": "Buraca",
                "label_ar": "بوراكا",
                "label_fr": "Buraca"
            },
            {
                "value": 60883,
                "label": "Baltar",
                "label_ar": "بالتار",
                "label_fr": "Baltar"
            },
            {
                "value": 60245,
                "label": "Tondela",
                "label_ar": "تونديلا",
                "label_fr": "Tondela"
            },
            {
                "value": 60433,
                "label": "Estoril",
                "label_ar": "إستوريل",
                "label_fr": "Estoril"
            },
            {
                "value": 60826,
                "label": "Ronfe",
                "label_ar": "رونفي",
                "label_fr": "Ronfe"
            },
            {
                "value": 60443,
                "label": "Póvoa de Varzim",
                "label_ar": "بوفوا دي فارزيم",
                "label_fr": "Póvoa de Varzim"
            },
            {
                "value": 60297,
                "label": "Sao Vicente da Beira",
                "label_ar": "ساو فيسينتي دا بيرا",
                "label_fr": "Sao Vicente da Beira"
            },
            {
                "value": 60664,
                "label": "Cuba",
                "label_ar": "كوبا",
                "label_fr": "Cuba"
            },
            {
                "value": 60416,
                "label": "Celorico de Basto",
                "label_ar": "سيلوريكو دي باستو",
                "label_fr": "Celorico de Basto"
            },
            {
                "value": 60554,
                "label": "Runa",
                "label_ar": "رونا",
                "label_fr": "Runa"
            },
            {
                "value": 60841,
                "label": "Barrosas",
                "label_ar": "باروزاس",
                "label_fr": "Barrosas"
            },
            {
                "value": 60878,
                "label": "Cabanelas",
                "label_ar": "كابانيلاس",
                "label_fr": "Cabanelas"
            },
            {
                "value": 60859,
                "label": "Vilar do Paraiso",
                "label_ar": "فيلار دو بارايسو",
                "label_fr": "Vilar do Paraiso"
            },
            {
                "value": 60386,
                "label": "Senhora da Hora",
                "label_ar": "سنهورا دا هورا",
                "label_fr": "Senhora da Hora"
            },
            {
                "value": 60763,
                "label": "Entroncamento",
                "label_ar": "Entroncamento",
                "label_fr": "Entroncamento"
            },
            {
                "value": 60856,
                "label": "Quinchaes",
                "label_ar": "كوينتشيز",
                "label_fr": "Quinchaes"
            },
            {
                "value": 60205,
                "label": "Caminha",
                "label_ar": "كامينها",
                "label_fr": "Caminha"
            },
            {
                "value": 60388,
                "label": "Vila Nova de Famalicao",
                "label_ar": "فيلا نوفا دي فاماليكاو",
                "label_fr": "Vila Nova de Famalicao"
            },
            {
                "value": 60301,
                "label": "Covilha",
                "label_ar": "كوفيلا",
                "label_fr": "Covilha"
            },
            {
                "value": 60733,
                "label": "Moreira de Conegos",
                "label_ar": "موريرا دي كونيجوس",
                "label_fr": "Moreira de Conegos"
            },
            {
                "value": 60508,
                "label": "Carcavelos",
                "label_ar": "كاركافيلوس",
                "label_fr": "Carcavelos"
            },
            {
                "value": 60295,
                "label": "Trancoso",
                "label_ar": "ترانكوسو",
                "label_fr": "Trancoso"
            },
            {
                "value": 60758,
                "label": "Freamunde",
                "label_ar": "فريموند",
                "label_fr": "Freamunde"
            },
            {
                "value": 60275,
                "label": "Vagos",
                "label_ar": "الغموض",
                "label_fr": "Vagos"
            },
            {
                "value": 60532,
                "label": "Manique de Baixo",
                "label_ar": "مانيك دي بايكسو",
                "label_fr": "Manique de Baixo"
            },
            {
                "value": 60591,
                "label": "Carvoeiro",
                "label_ar": "كارفويرو",
                "label_fr": "Carvoeiro"
            },
            {
                "value": 60249,
                "label": "Nelas",
                "label_ar": "نيلاس",
                "label_fr": "Nelas"
            },
            {
                "value": 60653,
                "label": "Cabrela",
                "label_ar": "كابريلا",
                "label_fr": "Cabrela"
            },
            {
                "value": 60356,
                "label": "Porto de Mos",
                "label_ar": "بورتو دي موس",
                "label_fr": "Porto de Mos"
            },
            {
                "value": 60315,
                "label": "Lousã",
                "label_ar": "لوسا",
                "label_fr": "Lousã"
            },
            {
                "value": 60610,
                "label": "Malveira",
                "label_ar": "مالفيرا",
                "label_fr": "Malveira"
            },
            {
                "value": 60795,
                "label": "Linhaceira",
                "label_ar": "لينهاسيرا",
                "label_fr": "Linhaceira"
            },
            {
                "value": 60235,
                "label": "Soure Municipality",
                "label_ar": "بلدية سوري",
                "label_fr": "Municipalité de Soure"
            },
            {
                "value": 60837,
                "label": "Vila Nova de Paiva",
                "label_ar": "فيلا نوفا دي بايفا",
                "label_fr": "Vila Nova de Paiva"
            },
            {
                "value": 60236,
                "label": "Ceira",
                "label_ar": "سييرا",
                "label_fr": "Ceira"
            },
            {
                "value": 60494,
                "label": "Cavaleiros",
                "label_ar": "كافالييروس",
                "label_fr": "Cavaleiros"
            },
            {
                "value": 60319,
                "label": "Nadadouro",
                "label_ar": "نادادورو",
                "label_fr": "Nadadouro"
            },
            {
                "value": 60331,
                "label": "Ourem",
                "label_ar": "اورم",
                "label_fr": "Ourem"
            },
            {
                "value": 60799,
                "label": "Vila Nova de Cerveira",
                "label_ar": "فيلا نوفا دي سيرفيرا",
                "label_fr": "Vila Nova de Cerveira"
            },
            {
                "value": 60594,
                "label": "Assumar",
                "label_ar": "أسومار",
                "label_fr": "Assumar"
            },
            {
                "value": 60539,
                "label": "Sao Joao dos Montes",
                "label_ar": "ساو جواو دوس مونتيس",
                "label_fr": "Sao Joao dos Montes"
            },
            {
                "value": 60765,
                "label": "Fátima",
                "label_ar": "فاطمة",
                "label_fr": "Fátima"
            },
            {
                "value": 60254,
                "label": "Santa Comba Dao",
                "label_ar": "سانتا كومبا داو",
                "label_fr": "Santa Comba Dao"
            },
            {
                "value": 60504,
                "label": "Linda a Velha",
                "label_ar": "ليندا أ فيلها",
                "label_fr": "Linda a Velha"
            },
            {
                "value": 60891,
                "label": "Aldeia do Meio",
                "label_ar": "Aldeia do Meio",
                "label_fr": "Aldeia do Meio"
            },
            {
                "value": 60276,
                "label": "Aguada de Cima",
                "label_ar": "أغوادا دي سيما",
                "label_fr": "Aguada de Cima"
            },
            {
                "value": 60876,
                "label": "Sete Rios",
                "label_ar": "سيت ريوس",
                "label_fr": "Sete Rios"
            },
            {
                "value": 60680,
                "label": "Monforte",
                "label_ar": "مونفورتي",
                "label_fr": "Monforte"
            },
            {
                "value": 60351,
                "label": "Avelar",
                "label_ar": "أفيلار",
                "label_fr": "Avelar"
            },
            {
                "value": 60490,
                "label": "Lagos",
                "label_ar": "لاغوس",
                "label_fr": "Lagos"
            },
            {
                "value": 60274,
                "label": "Aveiro",
                "label_ar": "أفيرو",
                "label_fr": "Aveiro"
            },
            {
                "value": 60872,
                "label": "Alqueidao da Serra",
                "label_ar": "Alqueidao دا سيرا",
                "label_fr": "Alqueidao da Serra"
            },
            {
                "value": 60559,
                "label": "Ilhavo",
                "label_ar": "Ilhavo",
                "label_fr": "Ilhavo"
            },
            {
                "value": 60687,
                "label": "Algueirao",
                "label_ar": "Algueirao",
                "label_fr": "Algueirao"
            },
            {
                "value": 60353,
                "label": "Carrico",
                "label_ar": "كاريكو",
                "label_fr": "Carrico"
            },
            {
                "value": 60797,
                "label": "Santa Catarina da Serra",
                "label_ar": "سانتا كاتارينا دا سيرا",
                "label_fr": "Santa Catarina da Serra"
            },
            {
                "value": 60761,
                "label": "Polima",
                "label_ar": "بوليما",
                "label_fr": "Polima"
            },
            {
                "value": 60764,
                "label": "Riachos",
                "label_ar": "رياكوس",
                "label_fr": "Riachos"
            },
            {
                "value": 60725,
                "label": "Joane",
                "label_ar": "جوان",
                "label_fr": "Joane"
            },
            {
                "value": 60705,
                "label": "Canelas",
                "label_ar": "كانيلاس",
                "label_fr": "Canelas"
            },
            {
                "value": 60194,
                "label": "Macedo de Cavaleiros",
                "label_ar": "ماسيدو دي كافاليروس",
                "label_fr": "Macedo de Cavaleiros"
            },
            {
                "value": 60659,
                "label": "Santiago do Cacem",
                "label_ar": "سانتياغو دو قاسم",
                "label_fr": "Santiago do Cacem"
            },
            {
                "value": 60620,
                "label": "Monchique",
                "label_ar": "مونشيك",
                "label_fr": "Monchique"
            },
            {
                "value": 60785,
                "label": "Fradelos",
                "label_ar": "فرادلوس",
                "label_fr": "Fradelos"
            },
            {
                "value": 60884,
                "label": "Palmeira",
                "label_ar": "بالميرا",
                "label_fr": "Palmeira"
            },
            {
                "value": 60459,
                "label": "Varzea do Douro",
                "label_ar": "فارزيا دو دورو",
                "label_fr": "Varzea do Douro"
            },
            {
                "value": 60579,
                "label": "Oliveira",
                "label_ar": "أوليفيرا",
                "label_fr": "Oliveira"
            },
            {
                "value": 60806,
                "label": "Neiva",
                "label_ar": "نيفا",
                "label_fr": "Neiva"
            },
            {
                "value": 60361,
                "label": "Pedreiras",
                "label_ar": "بيدريراس",
                "label_fr": "Pedreiras"
            },
            {
                "value": 60730,
                "label": "Muro",
                "label_ar": "مورو",
                "label_fr": "Muro"
            },
            {
                "value": 60393,
                "label": "Portimao",
                "label_ar": "بورتيماو",
                "label_fr": "Portimao"
            },
            {
                "value": 60768,
                "label": "Alcoentre",
                "label_ar": "ألكوينتر",
                "label_fr": "Alcoentre"
            },
            {
                "value": 60410,
                "label": "Vizela",
                "label_ar": "فيزيلا",
                "label_fr": "Vizela"
            },
            {
                "value": 60214,
                "label": "Castelo de Paiva",
                "label_ar": "كاستيلو دي بايفا",
                "label_fr": "Castelo de Paiva"
            },
            {
                "value": 60744,
                "label": "Fanzeres",
                "label_ar": "مراوح",
                "label_fr": "Fanzeres"
            },
            {
                "value": 60726,
                "label": "Vilela",
                "label_ar": "فيليلا",
                "label_fr": "Vilela"
            },
            {
                "value": 60479,
                "label": "Quarteira",
                "label_ar": "كوارتيرا",
                "label_fr": "Quarteira"
            },
            {
                "value": 60618,
                "label": "Machico",
                "label_ar": "ماتشيكو",
                "label_fr": "Machico"
            },
            {
                "value": 60642,
                "label": "Ponta do Sol",
                "label_ar": "بونتا دو سول",
                "label_fr": "Ponta do Sol"
            },
            {
                "value": 60873,
                "label": "Alcacer do Sal",
                "label_ar": "ألكاسير دو سال",
                "label_fr": "Alcacer do Sal"
            },
            {
                "value": 60512,
                "label": "Castanheira do Ribatejo",
                "label_ar": "Castanheira do Ribatejo",
                "label_fr": "Castanheira do Ribatejo"
            },
            {
                "value": 60342,
                "label": "Carregado",
                "label_ar": "كاريجادو",
                "label_fr": "Carregado"
            },
            {
                "value": 60547,
                "label": "Aldeia de Paio Pires",
                "label_ar": "ألديا دي بايو بيريس",
                "label_fr": "Aldeia de Paio Pires"
            },
            {
                "value": 60317,
                "label": "Ferrel",
                "label_ar": "فيريل",
                "label_fr": "Ferrel"
            },
            {
                "value": 60447,
                "label": "Loures",
                "label_ar": "لوريس",
                "label_fr": "Loures"
            },
            {
                "value": 60838,
                "label": "Vieira do Minho",
                "label_ar": "فييرا دو مينهو",
                "label_fr": "Vieira do Minho"
            },
            {
                "value": 60898,
                "label": "Maceda",
                "label_ar": "ماسيدا",
                "label_fr": "Maceda"
            },
            {
                "value": 60199,
                "label": "Penafiel",
                "label_ar": "بينافيل",
                "label_fr": "Penafiel"
            },
            {
                "value": 60186,
                "label": "Chaves",
                "label_ar": "تشافيس",
                "label_fr": "Chaves"
            },
            {
                "value": 60672,
                "label": "Sobreda",
                "label_ar": "سوبريدا",
                "label_fr": "Sobreda"
            },
            {
                "value": 60207,
                "label": "Braga",
                "label_ar": "براغا",
                "label_fr": "Braga"
            },
            {
                "value": 60567,
                "label": "Gandara dos Olivais",
                "label_ar": "غاندارا دوس أوليفايس",
                "label_fr": "Gandara dos Olivais"
            },
            {
                "value": 60419,
                "label": "Oliveira do Douro",
                "label_ar": "أوليفيرا دو دورو",
                "label_fr": "Oliveira do Douro"
            },
            {
                "value": 60448,
                "label": "Gafanha da Nazare",
                "label_ar": "جافانها دا نازاري",
                "label_fr": "Gafanha da Nazare"
            },
            {
                "value": 60553,
                "label": "Lourical",
                "label_ar": "لوريال",
                "label_fr": "Lourical"
            },
            {
                "value": 60895,
                "label": "Penedono",
                "label_ar": "بينيدونو",
                "label_fr": "Penedono"
            },
            {
                "value": 60492,
                "label": "Parchal",
                "label_ar": "بارشال",
                "label_fr": "Parchal"
            },
            {
                "value": 60231,
                "label": "Mira",
                "label_ar": "ميرا",
                "label_fr": "Mira"
            },
            {
                "value": 60657,
                "label": "Ribeira Grande",
                "label_ar": "ريبيرا غراندي",
                "label_fr": "Ribeira Grande"
            },
            {
                "value": 60280,
                "label": "Arrifana",
                "label_ar": "أريفانا",
                "label_fr": "Arrifana"
            },
            {
                "value": 60503,
                "label": "Frielas",
                "label_ar": "فريلاس",
                "label_fr": "Frielas"
            },
            {
                "value": 60739,
                "label": "Manique do Intendente",
                "label_ar": "مانيك دو Intendente",
                "label_fr": "Manique do Intendente"
            },
            {
                "value": 60585,
                "label": "Baleia",
                "label_ar": "بالية",
                "label_fr": "Baleia"
            },
            {
                "value": 60354,
                "label": "Alvaiazere",
                "label_ar": "الفايازير",
                "label_fr": "Alvaiazere"
            },
            {
                "value": 60289,
                "label": "Porto",
                "label_ar": "بورتو",
                "label_fr": "Porto"
            },
            {
                "value": 60713,
                "label": "Lavra",
                "label_ar": "لافرا",
                "label_fr": "Lavra"
            },
            {
                "value": 60312,
                "label": "Vila Cha de Ourique",
                "label_ar": "فيلا شا دي أوريك",
                "label_fr": "Vila Cha de Ourique"
            },
            {
                "value": 60810,
                "label": "Povoa",
                "label_ar": "بوفوا",
                "label_fr": "Povoa"
            },
            {
                "value": 60621,
                "label": "Mexilhoeira Grande",
                "label_ar": "Mexilhoeira Grande",
                "label_fr": "Mexilhoeira Grande"
            },
            {
                "value": 60833,
                "label": "Tamel",
                "label_ar": "تاميل",
                "label_fr": "Tamel"
            },
            {
                "value": 60635,
                "label": "Santana",
                "label_ar": "سانتانا",
                "label_fr": "Santana"
            },
            {
                "value": 60493,
                "label": "Olhao",
                "label_ar": "Olhao",
                "label_fr": "Olhao"
            },
            {
                "value": 60894,
                "label": "Eixo",
                "label_ar": "إيكسو",
                "label_fr": "Eixo"
            },
            {
                "value": 60788,
                "label": "Cabeceiras de Basto",
                "label_ar": "كابيسيراس دي باستو",
                "label_fr": "Cabeceiras de Basto"
            },
            {
                "value": 60686,
                "label": "Cidade",
                "label_ar": "سيداد",
                "label_fr": "Cidade"
            },
            {
                "value": 60612,
                "label": "Linho",
                "label_ar": "لينهو",
                "label_fr": "Linho"
            },
            {
                "value": 60403,
                "label": "Oeiras",
                "label_ar": "أويراس",
                "label_fr": "Oeiras"
            },
            {
                "value": 60723,
                "label": "Esposende",
                "label_ar": "اسبوسيندي",
                "label_fr": "Esposende"
            },
            {
                "value": 60784,
                "label": "Touguinha",
                "label_ar": "تقنينها",
                "label_fr": "Touguinha"
            },
            {
                "value": 60803,
                "label": "Albergaria-a-Velha",
                "label_ar": "Albergaria-a-Velha",
                "label_fr": "Albergaria-a-Velha"
            },
            {
                "value": 60266,
                "label": "Esmoriz",
                "label_ar": "إسموريز",
                "label_fr": "Esmoriz"
            },
            {
                "value": 60598,
                "label": "Belmonte",
                "label_ar": "بلمونتي",
                "label_fr": "Belmonte"
            },
            {
                "value": 60531,
                "label": "Alter do Chao",
                "label_ar": "ألتر دو تشاو",
                "label_fr": "Alter do Chao"
            },
            {
                "value": 60526,
                "label": "Sao Juliao do Tojal",
                "label_ar": "ساو جولياو دو توجال",
                "label_fr": "Sao Juliao do Tojal"
            },
            {
                "value": 60862,
                "label": "Arazede",
                "label_ar": "أرازيد",
                "label_fr": "Arazede"
            },
            {
                "value": 60395,
                "label": "Setúbal",
                "label_ar": "سيتوبال",
                "label_fr": "Setúbal"
            },
            {
                "value": 60551,
                "label": "Anta",
                "label_ar": "أنتا",
                "label_fr": "Anta"
            },
            {
                "value": 60633,
                "label": "Benafim",
                "label_ar": "بينافيم",
                "label_fr": "Benafim"
            },
            {
                "value": 60466,
                "label": "Alcabideche",
                "label_ar": "الكابيدشي",
                "label_fr": "Alcabideche"
            },
            {
                "value": 60267,
                "label": "Sao Roque",
                "label_ar": "ساو روكي",
                "label_fr": "Sao Roque"
            },
            {
                "value": 60815,
                "label": "Rebordosa",
                "label_ar": "ريبوردوسا",
                "label_fr": "Rebordosa"
            },
            {
                "value": 60290,
                "label": "Baguim do Monte",
                "label_ar": "باجويم دو مونتي",
                "label_fr": "Baguim do Monte"
            },
            {
                "value": 60409,
                "label": "Pedroucos",
                "label_ar": "بيدروكوس",
                "label_fr": "Pedroucos"
            },
            {
                "value": 60515,
                "label": "Pinhal",
                "label_ar": "بينهال",
                "label_fr": "Pinhal"
            },
            {
                "value": 60614,
                "label": "Cruz Quebrada",
                "label_ar": "كروز كويبرادا",
                "label_fr": "Cruz Quebrada"
            },
            {
                "value": 60209,
                "label": "Vila Praia de Ancora",
                "label_ar": "فيلا برايا دي أنكورا",
                "label_fr": "Vila Praia de Ancora"
            },
            {
                "value": 60350,
                "label": "Figueira da Foz Municipality",
                "label_ar": "بلدية فيجويرا دا فوز",
                "label_fr": "Municipalité de Figueira da Foz"
            },
            {
                "value": 60802,
                "label": "Constance",
                "label_ar": "كونستانس",
                "label_fr": "Constance"
            },
            {
                "value": 60796,
                "label": "Igreja Nova",
                "label_ar": "إيغريجا نوفا",
                "label_fr": "Igreja Nova"
            },
            {
                "value": 60306,
                "label": "Serta",
                "label_ar": "سيرتا",
                "label_fr": "Serta"
            },
            {
                "value": 60387,
                "label": "Soito",
                "label_ar": "سويتو",
                "label_fr": "Soito"
            },
            {
                "value": 60874,
                "label": "Vila do Bispo",
                "label_ar": "فيلا دو بيسبو",
                "label_fr": "Vila do Bispo"
            },
            {
                "value": 60394,
                "label": "Cesar",
                "label_ar": "سيزار",
                "label_fr": "César"
            },
            {
                "value": 60221,
                "label": "Lousada",
                "label_ar": "لوسادا",
                "label_fr": "Lousada"
            },
            {
                "value": 60668,
                "label": "Vila de Porto Santo",
                "label_ar": "فيلا دي بورتو سانتو",
                "label_fr": "Vila de Porto Santo"
            },
            {
                "value": 60227,
                "label": "Paredes da Beira",
                "label_ar": "باريديس دا بيرا",
                "label_fr": "Paredes da Beira"
            },
            {
                "value": 60867,
                "label": "Vale",
                "label_ar": "فالي",
                "label_fr": "Vallée"
            },
            {
                "value": 60233,
                "label": "Condeixa-a-Nova",
                "label_ar": "Condeixa-a-Nova",
                "label_fr": "Condeixa-a-Nova"
            },
            {
                "value": 60638,
                "label": "Armacao de Pera",
                "label_ar": "أرماكاو دي بيرا",
                "label_fr": "Armacao de Pera"
            },
            {
                "value": 60563,
                "label": "Vila Franca do Rosario",
                "label_ar": "فيلا فرانكا دو روزاريو",
                "label_fr": "Vila Franca do Rosario"
            },
            {
                "value": 60749,
                "label": "Praia de Mira",
                "label_ar": "برايا دي ميرا",
                "label_fr": "Praia de Mira"
            },
            {
                "value": 60523,
                "label": "Evora",
                "label_ar": "إيفورا",
                "label_fr": "Evora"
            },
            {
                "value": 60473,
                "label": "Olivais",
                "label_ar": "أوليفايس",
                "label_fr": "Olivais"
            },
            {
                "value": 60646,
                "label": "Igreja Nova",
                "label_ar": "إيغريجا نوفا",
                "label_fr": "Igreja Nova"
            },
            {
                "value": 60256,
                "label": "Murtede",
                "label_ar": "مورتيد",
                "label_fr": "Murtede"
            },
            {
                "value": 60253,
                "label": "Mangualde",
                "label_ar": "مانجوالدي",
                "label_fr": "Mangualde"
            },
            {
                "value": 60718,
                "label": "Escapaes",
                "label_ar": "اسكابا",
                "label_fr": "Escapaes"
            },
            {
                "value": 60441,
                "label": "Albufeira",
                "label_ar": "البوفيرا",
                "label_fr": "Albufeira"
            },
            {
                "value": 60888,
                "label": "Castro Daire",
                "label_ar": "كاسترو داير",
                "label_fr": "Castro Daire"
            },
            {
                "value": 60710,
                "label": "Vidago",
                "label_ar": "فيداغو",
                "label_fr": "Vidago"
            },
            {
                "value": 60435,
                "label": "Recarei",
                "label_ar": "ريكاري",
                "label_fr": "Recarei"
            },
            {
                "value": 60886,
                "label": "Lagoas",
                "label_ar": "لاجواس",
                "label_fr": "Lagoas"
            },
            {
                "value": 60322,
                "label": "Alcobaca",
                "label_ar": "الكوباكا",
                "label_fr": "Alcobaça"
            },
            {
                "value": 60185,
                "label": "Vilarinho das Paranheiras",
                "label_ar": "Vilarinho das Paranheiras",
                "label_fr": "Vilarinho das Paranheiras"
            },
            {
                "value": 60658,
                "label": "Ermidas",
                "label_ar": "ارميداس",
                "label_fr": "Ermidas"
            },
            {
                "value": 60461,
                "label": "Moure",
                "label_ar": "موري",
                "label_fr": "Moure"
            },
            {
                "value": 60656,
                "label": "Olhos de Agua",
                "label_ar": "أولهوس دي أجوا",
                "label_fr": "Olhos de Agua"
            },
            {
                "value": 60502,
                "label": "Porto Salvo",
                "label_ar": "بورتو سالفو",
                "label_fr": "Porto Salvo"
            },
            {
                "value": 60701,
                "label": "Poco Barreto",
                "label_ar": "بوكو باريتو",
                "label_fr": "Poco Barreto"
            },
            {
                "value": 60813,
                "label": "Dossaos",
                "label_ar": "دوساوس",
                "label_fr": "Dossaos"
            },
            {
                "value": 60708,
                "label": "Felgueiras",
                "label_ar": "فيلجيراس",
                "label_fr": "Felgueiras"
            },
            {
                "value": 60285,
                "label": "Caldas de Sao Jorge",
                "label_ar": "كالداس دي ساو خورخي",
                "label_fr": "Caldas de Sao Jorge"
            },
            {
                "value": 60460,
                "label": "Vale",
                "label_ar": "فالي",
                "label_fr": "Vallée"
            },
            {
                "value": 60892,
                "label": "Ferreira do Alentejo",
                "label_ar": "فيريرا دو ألينتيخو",
                "label_fr": "Ferreira do Alentejo"
            },
            {
                "value": 60225,
                "label": "Olival",
                "label_ar": "أوليفال",
                "label_fr": "Olival"
            },
            {
                "value": 60404,
                "label": "Cacem",
                "label_ar": "قاسم",
                "label_fr": "Cacem"
            },
            {
                "value": 60652,
                "label": "Santa Clara-a-Velha",
                "label_ar": "سانتا كلارا فيلها",
                "label_fr": "Santa Clara-a-Velha"
            },
            {
                "value": 60556,
                "label": "Atalaia",
                "label_ar": "أتالايا",
                "label_fr": "Atalaia"
            },
            {
                "value": 60775,
                "label": "Teixoso",
                "label_ar": "تيكسوسو",
                "label_fr": "Teixoso"
            },
            {
                "value": 60732,
                "label": "Paredes",
                "label_ar": "باريديس",
                "label_fr": "Paredes"
            },
            {
                "value": 60406,
                "label": "Alfragide",
                "label_ar": "الفراجيد",
                "label_fr": "Alfragide"
            },
            {
                "value": 60325,
                "label": "Obidos",
                "label_ar": "أوبيدوس",
                "label_fr": "Obidos"
            },
            {
                "value": 60212,
                "label": "Paços de Ferreira",
                "label_ar": "باكوس دي فيريرا",
                "label_fr": "Paços de Ferreira"
            },
            {
                "value": 60660,
                "label": "Sines Municipality",
                "label_ar": "بلدية سينس",
                "label_fr": "Commune de Sines"
            },
            {
                "value": 60390,
                "label": "Marinha",
                "label_ar": "مارينها",
                "label_fr": "Marinha"
            },
            {
                "value": 60208,
                "label": "Ponte da Barca",
                "label_ar": "بونتي دا باركا",
                "label_fr": "Ponte da Barca"
            },
            {
                "value": 60286,
                "label": "Nogueira do Cravo",
                "label_ar": "نوغيرا دو كرافو",
                "label_fr": "Nogueira do Cravo"
            },
            {
                "value": 60456,
                "label": "Gandra",
                "label_ar": "غاندرا",
                "label_fr": "Gandra"
            },
            {
                "value": 60536,
                "label": "Venda do Pinheiro",
                "label_ar": "فيندا دو بينيرو",
                "label_fr": "Venda do Pinheiro"
            },
            {
                "value": 60298,
                "label": "Silvares",
                "label_ar": "سيلفاريس",
                "label_fr": "Silvares"
            },
            {
                "value": 60246,
                "label": "Castelejo",
                "label_ar": "كاستليجو",
                "label_fr": "Castelejo"
            },
            {
                "value": 60645,
                "label": "Lajes",
                "label_ar": "لاجيس",
                "label_fr": "Lajes"
            },
            {
                "value": 60662,
                "label": "Aljustrel",
                "label_ar": "الجوسترل",
                "label_fr": "Aljustrel"
            },
            {
                "value": 60822,
                "label": "Avintes",
                "label_ar": "أفينتيس",
                "label_fr": "Avintes"
            },
            {
                "value": 60636,
                "label": "Casal",
                "label_ar": "كاسال",
                "label_fr": "Casal"
            },
            {
                "value": 60309,
                "label": "Rio Maior",
                "label_ar": "ريو مايور",
                "label_fr": "Rio Maior"
            },
            {
                "value": 60244,
                "label": "Arganil",
                "label_ar": "أرغانيل",
                "label_fr": "Arganil"
            },
            {
                "value": 60688,
                "label": "Belas",
                "label_ar": "بيلاس",
                "label_fr": "Belas"
            },
            {
                "value": 60474,
                "label": "Alhos Vedros",
                "label_ar": "ألحوس فيدروس",
                "label_fr": "Alhos Vedros"
            },
            {
                "value": 60521,
                "label": "Alverca",
                "label_ar": "ألفيركا",
                "label_fr": "Alverca"
            },
            {
                "value": 60314,
                "label": "Pinheiro de Azere",
                "label_ar": "بينهيرو دي أزيري",
                "label_fr": "Pinheiro de Azere"
            },
            {
                "value": 60509,
                "label": "Silves",
                "label_ar": "سيلفيس",
                "label_fr": "Silves"
            },
            {
                "value": 60442,
                "label": "Quejas",
                "label_ar": "كويجاس",
                "label_fr": "Quejas"
            },
            {
                "value": 60781,
                "label": "Cinfaes",
                "label_ar": "سينفايس",
                "label_fr": "Cinfaes"
            },
            {
                "value": 60719,
                "label": "Leca do Bailio",
                "label_ar": "Leca do Bailio",
                "label_fr": "Leca do Bailio"
            },
            {
                "value": 60754,
                "label": "Caldas das Taipas",
                "label_ar": "كالداس داس تايباس",
                "label_fr": "Caldas das Taipas"
            },
            {
                "value": 60746,
                "label": "Sobrado",
                "label_ar": "سوبرادو",
                "label_fr": "Sobrado"
            },
            {
                "value": 60362,
                "label": "Garruchas",
                "label_ar": "Garruchas",
                "label_fr": "Garruchas"
            },
            {
                "value": 60839,
                "label": "Montalvo",
                "label_ar": "مونتالفو",
                "label_fr": "Montalvo"
            },
            {
                "value": 60727,
                "label": "Pedroso",
                "label_ar": "بيدروسو",
                "label_fr": "Pedroso"
            },
            {
                "value": 60251,
                "label": "Sao Vicente de Lafoes",
                "label_ar": "ساو فيسنتي دي لافوس",
                "label_fr": "Sao Vicente de Lafoes"
            },
            {
                "value": 60454,
                "label": "Caxias",
                "label_ar": "كاكسياس",
                "label_fr": "Caxias"
            },
            {
                "value": 60628,
                "label": "Almargem",
                "label_ar": "المرقم",
                "label_fr": "Almargem"
            },
            {
                "value": 60268,
                "label": "Cucujaes",
                "label_ar": "كوكوجيس",
                "label_fr": "Cucujaes"
            },
            {
                "value": 60269,
                "label": "Feira",
                "label_ar": "فييرا",
                "label_fr": "Feira"
            },
            {
                "value": 60311,
                "label": "Ponte de Sor",
                "label_ar": "بونتي دي سور",
                "label_fr": "Ponte de Sor"
            },
            {
                "value": 60747,
                "label": "Sao Felix da Marinha",
                "label_ar": "ساو فيليكس دا مارينيا",
                "label_fr": "Sao Felix da Marinha"
            },
            {
                "value": 60783,
                "label": "Vila Boa de Quires",
                "label_ar": "فيلا بوا دي كويرس",
                "label_fr": "Vila Boa de Quires"
            },
            {
                "value": 60328,
                "label": "Abrantes",
                "label_ar": "أبرانتيس",
                "label_fr": "Abrantes"
            },
            {
                "value": 60661,
                "label": "Serpa",
                "label_ar": "سيربا",
                "label_fr": "Serpa"
            },
            {
                "value": 60804,
                "label": "Oia",
                "label_ar": "أويا",
                "label_fr": "Oia"
            },
            {
                "value": 60773,
                "label": "Santa Catarina",
                "label_ar": "سانتا كاتارينا",
                "label_fr": "Santa Catarina"
            },
            {
                "value": 60219,
                "label": "Sobrado de Paiva",
                "label_ar": "سوبرادو دي بايفا",
                "label_fr": "Sobrado de Paiva"
            },
            {
                "value": 60412,
                "label": "Faro",
                "label_ar": "فارو",
                "label_fr": "Faro"
            },
            {
                "value": 60786,
                "label": "Ribeirao",
                "label_ar": "ريبيراو",
                "label_fr": "Ribeirao"
            },
            {
                "value": 60402,
                "label": "Mem Martins",
                "label_ar": "ميم مارتينز",
                "label_fr": "Mem Martins"
            },
            {
                "value": 60569,
                "label": "Boavista",
                "label_ar": "بوافيستا",
                "label_fr": "Boavista"
            },
            {
                "value": 60679,
                "label": "Arco da Calheta",
                "label_ar": "أركو دا كالهيتا",
                "label_fr": "Arco da Calheta"
            },
            {
                "value": 60281,
                "label": "Sao Joao de Ver",
                "label_ar": "ساو جواو دي فير",
                "label_fr": "Sao Joao de Ver"
            },
            {
                "value": 60692,
                "label": "Campinho",
                "label_ar": "كامبينيو",
                "label_fr": "Campinho"
            },
            {
                "value": 60849,
                "label": "Cadaval",
                "label_ar": "جادافال",
                "label_fr": "Cadaval"
            },
            {
                "value": 60304,
                "label": "Carvalho",
                "label_ar": "كارفالو",
                "label_fr": "Carvalho"
            },
            {
                "value": 60264,
                "label": "Ovar",
                "label_ar": "أوفار",
                "label_fr": "Ovar"
            },
            {
                "value": 60871,
                "label": "Terena",
                "label_ar": "تيرينا",
                "label_fr": "Terena"
            }
        ]
    },
    {
        "country": "Saudi Arabia",
        "cities": [
            {
                "value": 60903,
                "label": "Safwa",
                "label_ar": "صفوى",
                "label_fr": "Safwa"
            },
            {
                "value": 60927,
                "label": "Ras Tanura",
                "label_ar": "رأس تنورة",
                "label_fr": "Ras Tanura"
            },
            {
                "value": 60911,
                "label": "Ta'if",
                "label_ar": "الطائف",
                "label_fr": "Ta'if"
            },
            {
                "value": 60905,
                "label": "Dhahran",
                "label_ar": "الظهران",
                "label_fr": "Dhahran"
            },
            {
                "value": 60924,
                "label": "Al Yamamah",
                "label_ar": "اليمامة",
                "label_fr": "Al Yamamah"
            },
            {
                "value": 60935,
                "label": "Ra's al Khafji",
                "label_ar": "رأس الخفجي",
                "label_fr": "Ra's al Khafji"
            },
            {
                "value": 60919,
                "label": "Mina",
                "label_ar": "مينا",
                "label_fr": "Mina"
            },
            {
                "value": 60908,
                "label": "Jubail",
                "label_ar": "الجبيل",
                "label_fr": "Jubail"
            },
            {
                "value": 60928,
                "label": "At Tuwal",
                "label_ar": "في طوال",
                "label_fr": "À Tuwal"
            },
            {
                "value": 60918,
                "label": "Abqaiq",
                "label_ar": "ابقيق",
                "label_fr": "Abqaiq"
            },
            {
                "value": 60932,
                "label": "Hayil",
                "label_ar": "حائل",
                "label_fr": "Hayil"
            },
            {
                "value": 60901,
                "label": "Riyadh",
                "label_ar": "مدينة الرياض",
                "label_fr": "Riyad"
            },
            {
                "value": 60913,
                "label": "Al Hufuf",
                "label_ar": "الهفوف",
                "label_fr": "Al Hufuf"
            },
            {
                "value": 60917,
                "label": "Yanbu` al Bahr",
                "label_ar": "ينبع البحر",
                "label_fr": "Yanbu` al Bahr"
            },
            {
                "value": 60909,
                "label": "Sayhat",
                "label_ar": "سيهات",
                "label_fr": "Sayhat"
            },
            {
                "value": 60907,
                "label": "Khobar",
                "label_ar": "مدينه الخبر",
                "label_fr": "Khobar"
            },
            {
                "value": 60936,
                "label": "Najran",
                "label_ar": "نجران",
                "label_fr": "Najran"
            },
            {
                "value": 60940,
                "label": "Jazirah",
                "label_ar": "الجزيرة",
                "label_fr": "Jazirah"
            },
            {
                "value": 60912,
                "label": "Mecca",
                "label_ar": "مكة المكرمة",
                "label_fr": "Mecque"
            },
            {
                "value": 60941,
                "label": "Jazirah",
                "label_ar": "الجزيرة",
                "label_fr": "Jazirah"
            },
            {
                "value": 60931,
                "label": "Madinat Yanbu` as Sina`iyah",
                "label_ar": "مدينة ينبع الصناعية",
                "label_fr": "Madinat Yanbu` comme Sinaʻiyah"
            },
            {
                "value": 60916,
                "label": "Rabigh",
                "label_ar": "رابغ",
                "label_fr": "Rabigh"
            },
            {
                "value": 60929,
                "label": "Sabya",
                "label_ar": "صبيا",
                "label_fr": "Sabya"
            },
            {
                "value": 60933,
                "label": "Khulays",
                "label_ar": "خليص",
                "label_fr": "Khulays"
            },
            {
                "value": 60939,
                "label": "Rahman",
                "label_ar": "رحمن",
                "label_fr": "Rahman"
            },
            {
                "value": 60926,
                "label": "Sambah",
                "label_ar": "سامبح",
                "label_fr": "Sambah"
            },
            {
                "value": 60920,
                "label": "Ramdah",
                "label_ar": "رمضة",
                "label_fr": "Ramdah"
            },
            {
                "value": 60937,
                "label": "Sakaka",
                "label_ar": "سكاكا",
                "label_fr": "Sakaka"
            },
            {
                "value": 60902,
                "label": "Dammam",
                "label_ar": "الدمام",
                "label_fr": "Dammam"
            },
            {
                "value": 60923,
                "label": "Jizan",
                "label_ar": "جيزان",
                "label_fr": "Jizan"
            },
            {
                "value": 60921,
                "label": "Linah",
                "label_ar": "لينا",
                "label_fr": "Linah"
            },
            {
                "value": 60938,
                "label": "Al Bahah",
                "label_ar": "الباحة",
                "label_fr": "Al Bahah"
            },
            {
                "value": 60922,
                "label": "Abha",
                "label_ar": "أبها",
                "label_fr": "Abha"
            },
            {
                "value": 60914,
                "label": "Medina",
                "label_ar": "المدينة المنورة",
                "label_fr": "Médina"
            },
            {
                "value": 60934,
                "label": "Khamis Mushait",
                "label_ar": "خميس مشيط",
                "label_fr": "Khamis Mushait"
            },
            {
                "value": 60904,
                "label": "Al Qatif",
                "label_ar": "القطيف",
                "label_fr": "Al Qatif"
            },
            {
                "value": 60925,
                "label": "Tabuk",
                "label_ar": "تبوك",
                "label_fr": "Tabuk"
            },
            {
                "value": 60910,
                "label": "Jeddah",
                "label_ar": "جدة",
                "label_fr": "Djeddah"
            },
            {
                "value": 60915,
                "label": "Rahimah",
                "label_ar": "رحيمة",
                "label_fr": "Rahimah"
            },
            {
                "value": 60906,
                "label": "Al Faruq",
                "label_ar": "الفاروق",
                "label_fr": "Al Faruq"
            },
            {
                "value": 60930,
                "label": "Buraidah",
                "label_ar": "بريدة",
                "label_fr": "Buraidah"
            }
        ]
    },
    {
        "country": "Denmark",
        "cities": [
            {
                "value": 61266,
                "label": "Bredebro",
                "label_ar": "بريدبرو",
                "label_fr": "Bredebro"
            },
            {
                "value": 61395,
                "label": "Fjenneslev",
                "label_ar": "فينيسليف",
                "label_fr": "Fjenneslev"
            },
            {
                "value": 61431,
                "label": "Hvide Sande",
                "label_ar": "هفيدي ساندي",
                "label_fr": "Hvide Sande"
            },
            {
                "value": 61368,
                "label": "Agedrup",
                "label_ar": "Agedrup",
                "label_fr": "Agedrup"
            },
            {
                "value": 61050,
                "label": "Braedstrup",
                "label_ar": "برايدستروب",
                "label_fr": "Braedstrup"
            },
            {
                "value": 61379,
                "label": "Thorso",
                "label_ar": "ثورسو",
                "label_fr": "Thorso"
            },
            {
                "value": 60985,
                "label": "Jyllinge",
                "label_ar": "جيلينج",
                "label_fr": "Jyllinge"
            },
            {
                "value": 61061,
                "label": "Gedved",
                "label_ar": "جدفيد",
                "label_fr": "Gedved"
            },
            {
                "value": 61202,
                "label": "Ejby",
                "label_ar": "ايجبي",
                "label_fr": "Ejby"
            },
            {
                "value": 61391,
                "label": "Ostermarie",
                "label_ar": "أوسترماري",
                "label_fr": "Ostermarie"
            },
            {
                "value": 61360,
                "label": "Orsted",
                "label_ar": "أورستيد",
                "label_fr": "Orsted"
            },
            {
                "value": 60949,
                "label": "Elsinore",
                "label_ar": "Elsinore",
                "label_fr": "Elseneur"
            },
            {
                "value": 61074,
                "label": "Hellerup",
                "label_ar": "هيلروب",
                "label_fr": "Hellerup"
            },
            {
                "value": 61536,
                "label": "Hyllinge",
                "label_ar": "هايلينج",
                "label_fr": "Hyllinge"
            },
            {
                "value": 61034,
                "label": "Ejby",
                "label_ar": "ايجبي",
                "label_fr": "Ejby"
            },
            {
                "value": 61081,
                "label": "Ringsted",
                "label_ar": "رينجستيد",
                "label_fr": "Ringsted"
            },
            {
                "value": 61293,
                "label": "Gording",
                "label_ar": "جوردينج",
                "label_fr": "Gording"
            },
            {
                "value": 60994,
                "label": "Snekkersten",
                "label_ar": "Snekkersten",
                "label_fr": "Snekkersten"
            },
            {
                "value": 61010,
                "label": "Ballerup",
                "label_ar": "باليروب",
                "label_fr": "Ballerup"
            },
            {
                "value": 60980,
                "label": "Olstykke",
                "label_ar": "Olstykke",
                "label_fr": "Olstykke"
            },
            {
                "value": 61253,
                "label": "Orbaek",
                "label_ar": "أوربيك",
                "label_fr": "Orbaek"
            },
            {
                "value": 61291,
                "label": "Alsgarde",
                "label_ar": "الصقردي",
                "label_fr": "Alsgarde"
            },
            {
                "value": 61421,
                "label": "Norre Asmindrup",
                "label_ar": "نوري أسميندروب",
                "label_fr": "Norre Asmindrup"
            },
            {
                "value": 61073,
                "label": "Skive",
                "label_ar": "سكيف",
                "label_fr": "Skive"
            },
            {
                "value": 61533,
                "label": "Kvols",
                "label_ar": "كفولس",
                "label_fr": "Kvols"
            },
            {
                "value": 61500,
                "label": "Nimtofte",
                "label_ar": "نيمتوفت",
                "label_fr": "Nimtofte"
            },
            {
                "value": 61194,
                "label": "Aars",
                "label_ar": "آرس",
                "label_fr": "Aars"
            },
            {
                "value": 61288,
                "label": "Jerslev",
                "label_ar": "جيرسليف",
                "label_fr": "Jerslev"
            },
            {
                "value": 61177,
                "label": "Jelling",
                "label_ar": "الهلام",
                "label_fr": "Jelling"
            },
            {
                "value": 61439,
                "label": "Gjerlev",
                "label_ar": "جيرليف",
                "label_fr": "Gjerlev"
            },
            {
                "value": 61239,
                "label": "Oksbol",
                "label_ar": "أوكسبول",
                "label_fr": "Oksbol"
            },
            {
                "value": 60950,
                "label": "Roskilde",
                "label_ar": "روسكيلد",
                "label_fr": "Roskilde"
            },
            {
                "value": 61067,
                "label": "Hurup",
                "label_ar": "هوروب",
                "label_fr": "Hurup"
            },
            {
                "value": 60961,
                "label": "Hammel",
                "label_ar": "هاميل",
                "label_fr": "Hammel"
            },
            {
                "value": 61250,
                "label": "Nibe",
                "label_ar": "نيب",
                "label_fr": "Nibe"
            },
            {
                "value": 61355,
                "label": "Egernsund",
                "label_ar": "ايجرنسوند",
                "label_fr": "Egernsund"
            },
            {
                "value": 61173,
                "label": "Svanninge",
                "label_ar": "سفانينج",
                "label_fr": "Svanninge"
            },
            {
                "value": 61303,
                "label": "Sandager",
                "label_ar": "سانديجر",
                "label_fr": "Sandager"
            },
            {
                "value": 61249,
                "label": "Alestrup",
                "label_ar": "اليستروب",
                "label_fr": "Alestrup"
            },
            {
                "value": 61113,
                "label": "Liseleje",
                "label_ar": "ليسيليي",
                "label_fr": "Liseleje"
            },
            {
                "value": 61319,
                "label": "Gorlose",
                "label_ar": "غورلوز",
                "label_fr": "Gorlose"
            },
            {
                "value": 61126,
                "label": "Havndal",
                "label_ar": "هافندال",
                "label_fr": "Havndal"
            },
            {
                "value": 61418,
                "label": "Orum",
                "label_ar": "أوروم",
                "label_fr": "Orum"
            },
            {
                "value": 61267,
                "label": "Vandel",
                "label_ar": "فاندل",
                "label_fr": "Vandel"
            },
            {
                "value": 61376,
                "label": "Norre Nebel",
                "label_ar": "نوري نيبيل",
                "label_fr": "Norre Nebel"
            },
            {
                "value": 61488,
                "label": "Flemming",
                "label_ar": "فليمنغ",
                "label_fr": "Flemming"
            },
            {
                "value": 61167,
                "label": "Svaneke",
                "label_ar": "سفانيكي",
                "label_fr": "Svaneke"
            },
            {
                "value": 61025,
                "label": "Havdrup",
                "label_ar": "هافدروب",
                "label_fr": "Havdrup"
            },
            {
                "value": 61242,
                "label": "Hemmet",
                "label_ar": "هميت",
                "label_fr": "Hemmet"
            },
            {
                "value": 61095,
                "label": "Haslev",
                "label_ar": "هاسليف",
                "label_fr": "Haslev"
            },
            {
                "value": 61432,
                "label": "Rodkaersbro",
                "label_ar": "رودكيرسبرو",
                "label_fr": "Rodkaersbro"
            },
            {
                "value": 61220,
                "label": "Stokkemarke",
                "label_ar": "Stokkemarke",
                "label_fr": "Stokkemarke"
            },
            {
                "value": 60958,
                "label": "Uldum",
                "label_ar": "أولدوم",
                "label_fr": "Uldum"
            },
            {
                "value": 60945,
                "label": "Albertslund",
                "label_ar": "ألبيرتسلوند",
                "label_fr": "Albertslund"
            },
            {
                "value": 61283,
                "label": "Tars",
                "label_ar": "القطران",
                "label_fr": "Goudrons"
            },
            {
                "value": 60987,
                "label": "Naestved",
                "label_ar": "ناستفيد",
                "label_fr": "Naestved"
            },
            {
                "value": 61495,
                "label": "Vesterborg",
                "label_ar": "فيستيربورج",
                "label_fr": "Vesterborg"
            },
            {
                "value": 61300,
                "label": "Tranbjerg",
                "label_ar": "ترانبيرج",
                "label_fr": "Tranbjerg"
            },
            {
                "value": 61434,
                "label": "Lem",
                "label_ar": "ليم",
                "label_fr": "Lem"
            },
            {
                "value": 61409,
                "label": "Agerbaek",
                "label_ar": "أجربيك",
                "label_fr": "Agerbaek"
            },
            {
                "value": 61295,
                "label": "Lokken",
                "label_ar": "لوكين",
                "label_fr": "Lokken"
            },
            {
                "value": 60999,
                "label": "Hjortshoj",
                "label_ar": "هجورتشوج",
                "label_fr": "Hjortshoj"
            },
            {
                "value": 60954,
                "label": "Køge",
                "label_ar": "Køge",
                "label_fr": "Køge"
            },
            {
                "value": 60952,
                "label": "Vejle",
                "label_ar": "فيجل",
                "label_fr": "Vejle"
            },
            {
                "value": 61461,
                "label": "Jordrup",
                "label_ar": "جوردروب",
                "label_fr": "Jordrup"
            },
            {
                "value": 61140,
                "label": "Videbæk",
                "label_ar": "فيديبيك",
                "label_fr": "Videbæk"
            },
            {
                "value": 61423,
                "label": "Hoptrup",
                "label_ar": "هوبتروب",
                "label_fr": "Hoptrup"
            },
            {
                "value": 61102,
                "label": "Hjørring",
                "label_ar": "Hjørring",
                "label_fr": "Hjørring"
            },
            {
                "value": 61199,
                "label": "Brondby Strand",
                "label_ar": "بروندبي ستراند",
                "label_fr": "Brondby Strand"
            },
            {
                "value": 61254,
                "label": "Torring",
                "label_ar": "تورينج",
                "label_fr": "Torring"
            },
            {
                "value": 61155,
                "label": "Løgstør",
                "label_ar": "Løgstør",
                "label_fr": "Løgstør"
            },
            {
                "value": 61406,
                "label": "Vester-Skerninge",
                "label_ar": "فيستر سكيرنينج",
                "label_fr": "Vester-Skerninge"
            },
            {
                "value": 61229,
                "label": "Gudbjerg",
                "label_ar": "جودبيرج",
                "label_fr": "Gudbjerg"
            },
            {
                "value": 61497,
                "label": "Tjebberup",
                "label_ar": "تجيبيروب",
                "label_fr": "Tjebberup"
            },
            {
                "value": 61411,
                "label": "Herlufmagle",
                "label_ar": "هيرلوفماجلي",
                "label_fr": "Herlufmagle"
            },
            {
                "value": 61057,
                "label": "Bredballe",
                "label_ar": "بريدبال",
                "label_fr": "Bredballe"
            },
            {
                "value": 61072,
                "label": "Vinderup",
                "label_ar": "فيندراب",
                "label_fr": "Vinderup"
            },
            {
                "value": 61363,
                "label": "Klovborg",
                "label_ar": "كلوفبورغ",
                "label_fr": "Klovborg"
            },
            {
                "value": 61066,
                "label": "Nykobing Mors",
                "label_ar": "نيكوبينج مورس",
                "label_fr": "Nykobing Mors"
            },
            {
                "value": 61188,
                "label": "Skørping",
                "label_ar": "سكوربينج",
                "label_fr": "Skørping"
            },
            {
                "value": 61487,
                "label": "Holeby",
                "label_ar": "هوليبي",
                "label_fr": "Holeby"
            },
            {
                "value": 61085,
                "label": "Slangerup",
                "label_ar": "سلانجروب",
                "label_fr": "Slangerup"
            },
            {
                "value": 61156,
                "label": "Farsø",
                "label_ar": "فارسو",
                "label_fr": "Farsø"
            },
            {
                "value": 61507,
                "label": "Greve Strand",
                "label_ar": "جريف ستراند",
                "label_fr": "Greve Strand"
            },
            {
                "value": 61237,
                "label": "Krusa",
                "label_ar": "كروسا",
                "label_fr": "Krusa"
            },
            {
                "value": 61082,
                "label": "Gilleleje",
                "label_ar": "جيليلي",
                "label_fr": "Gilleleje"
            },
            {
                "value": 61028,
                "label": "Sonderso",
                "label_ar": "سوندرسو",
                "label_fr": "Sonderso"
            },
            {
                "value": 61530,
                "label": "Borum",
                "label_ar": "بوروم",
                "label_fr": "Borum"
            },
            {
                "value": 61268,
                "label": "Broager",
                "label_ar": "برويجر",
                "label_fr": "Courtier"
            },
            {
                "value": 61012,
                "label": "Humlebaek",
                "label_ar": "همليبيك",
                "label_fr": "Humlebaek"
            },
            {
                "value": 61546,
                "label": "Vederso",
                "label_ar": "فيديرسو",
                "label_fr": "Vederso"
            },
            {
                "value": 61465,
                "label": "Stouby",
                "label_ar": "ستوبي",
                "label_fr": "Stouby"
            },
            {
                "value": 61470,
                "label": "Trelde",
                "label_ar": "تريلدي",
                "label_fr": "Trelde"
            },
            {
                "value": 61301,
                "label": "Kirke Eskilstrup",
                "label_ar": "كيرك إسكيلستروب",
                "label_fr": "Kirke Eskilstrup"
            },
            {
                "value": 60960,
                "label": "Galten",
                "label_ar": "جالتن",
                "label_fr": "Galten"
            },
            {
                "value": 61056,
                "label": "Juelsminde",
                "label_ar": "Juelsminde",
                "label_fr": "Juelsminde"
            },
            {
                "value": 61307,
                "label": "Fjerritslev",
                "label_ar": "فيريتسليف",
                "label_fr": "Fjerritslev"
            },
            {
                "value": 60990,
                "label": "Karlstrup",
                "label_ar": "كارلستروب",
                "label_fr": "Karlstrup"
            },
            {
                "value": 61485,
                "label": "Astrup",
                "label_ar": "أستروب",
                "label_fr": "Astrup"
            },
            {
                "value": 61311,
                "label": "Ostervra",
                "label_ar": "أوسترفرا",
                "label_fr": "Ostervra"
            },
            {
                "value": 61292,
                "label": "Bredsten",
                "label_ar": "بريدستن",
                "label_fr": "Bredsten"
            },
            {
                "value": 61201,
                "label": "Skibby",
                "label_ar": "سكيبي",
                "label_fr": "Skibby"
            },
            {
                "value": 61299,
                "label": "Ferritslev",
                "label_ar": "فيريتسليف",
                "label_fr": "Ferritslev"
            },
            {
                "value": 61367,
                "label": "Sandved",
                "label_ar": "ساندفيد",
                "label_fr": "Sablé"
            },
            {
                "value": 61503,
                "label": "Klippinge",
                "label_ar": "كليبنج",
                "label_fr": "Klippinge"
            },
            {
                "value": 61529,
                "label": "Torring",
                "label_ar": "تورينج",
                "label_fr": "Torring"
            },
            {
                "value": 61491,
                "label": "Sanderum",
                "label_ar": "ساندروم",
                "label_fr": "Sanderum"
            },
            {
                "value": 61138,
                "label": "Ringe",
                "label_ar": "رينج",
                "label_fr": "Ringe"
            },
            {
                "value": 61213,
                "label": "Bjaeverskov",
                "label_ar": "بجيفيرسكوف",
                "label_fr": "Bjaeverskov"
            },
            {
                "value": 61419,
                "label": "Blommenslyst",
                "label_ar": "بلومنسليست",
                "label_fr": "Blommenslyst"
            },
            {
                "value": 61545,
                "label": "Hjerm",
                "label_ar": "هجرم",
                "label_fr": "Hjerm"
            },
            {
                "value": 61121,
                "label": "Follenslev",
                "label_ar": "فولينسليف",
                "label_fr": "Follenslev"
            },
            {
                "value": 60982,
                "label": "Holstebro",
                "label_ar": "هولستبرو",
                "label_fr": "Holstebro"
            },
            {
                "value": 61361,
                "label": "Farup",
                "label_ar": "حتى يصل",
                "label_fr": "Farup"
            },
            {
                "value": 61179,
                "label": "Gadstrup",
                "label_ar": "جادستروب",
                "label_fr": "Gadstrup"
            },
            {
                "value": 61132,
                "label": "Malling",
                "label_ar": "مولينج",
                "label_fr": "Malling"
            },
            {
                "value": 61540,
                "label": "Torrig",
                "label_ar": "توريج",
                "label_fr": "Torrig"
            },
            {
                "value": 61218,
                "label": "Grevinge",
                "label_ar": "جريفينجي",
                "label_fr": "Grevinge"
            },
            {
                "value": 61511,
                "label": "Tullebolle",
                "label_ar": "تولبول",
                "label_fr": "Tullebolle"
            },
            {
                "value": 60976,
                "label": "Ringkøbing",
                "label_ar": "رينغكبنغ",
                "label_fr": "Ringkøbing"
            },
            {
                "value": 61048,
                "label": "Vojens",
                "label_ar": "فوينس",
                "label_fr": "Vojens"
            },
            {
                "value": 61192,
                "label": "Them",
                "label_ar": "معهم",
                "label_fr": "Leur"
            },
            {
                "value": 61212,
                "label": "Store Heddinge",
                "label_ar": "متجر Heddinge",
                "label_fr": "Magasin Heddinge"
            },
            {
                "value": 61381,
                "label": "Moldrup",
                "label_ar": "مولدروب",
                "label_fr": "Moldrup"
            },
            {
                "value": 61265,
                "label": "Agerskov",
                "label_ar": "أجرسكوف",
                "label_fr": "Agerskov"
            },
            {
                "value": 60984,
                "label": "Jægerspris",
                "label_ar": "Jægerspris",
                "label_fr": "Jægerspris"
            },
            {
                "value": 61064,
                "label": "Ry",
                "label_ar": "راي",
                "label_fr": "Ry"
            },
            {
                "value": 61243,
                "label": "Aller",
                "label_ar": "ألير",
                "label_fr": "Aller"
            },
            {
                "value": 61003,
                "label": "Dragør",
                "label_ar": "دراغور",
                "label_fr": "Dragør"
            },
            {
                "value": 61079,
                "label": "Dalmose",
                "label_ar": "دالموس",
                "label_fr": "Dalmose"
            },
            {
                "value": 61402,
                "label": "Karise",
                "label_ar": "كاريز",
                "label_fr": "Karise"
            },
            {
                "value": 60996,
                "label": "Skaering",
                "label_ar": "سكرينج",
                "label_fr": "Skaering"
            },
            {
                "value": 61264,
                "label": "Padborg",
                "label_ar": "بادبورج",
                "label_fr": "Padborg"
            },
            {
                "value": 61459,
                "label": "Himmelev",
                "label_ar": "هيمليف",
                "label_fr": "Himmelev"
            },
            {
                "value": 61499,
                "label": "Nygard",
                "label_ar": "نيجارد",
                "label_fr": "Nygard"
            },
            {
                "value": 61216,
                "label": "Snertinge",
                "label_ar": "سنرتينجي",
                "label_fr": "Snertinge"
            },
            {
                "value": 61448,
                "label": "Rude",
                "label_ar": "قلة الادب",
                "label_fr": "Grossier"
            },
            {
                "value": 61514,
                "label": "Laeso",
                "label_ar": "لايسو",
                "label_fr": "Laeso"
            },
            {
                "value": 60979,
                "label": "Niva",
                "label_ar": "نيفا",
                "label_fr": "Niva"
            },
            {
                "value": 61433,
                "label": "Lemming",
                "label_ar": "ليمينغ",
                "label_fr": "Lemming"
            },
            {
                "value": 61393,
                "label": "Sulsted",
                "label_ar": "سولستيد",
                "label_fr": "Sulsted"
            },
            {
                "value": 61473,
                "label": "Odsted",
                "label_ar": "أودستيد",
                "label_fr": "Odsted"
            },
            {
                "value": 60955,
                "label": "Herlev",
                "label_ar": "هيرليف",
                "label_fr": "Herlev"
            },
            {
                "value": 61059,
                "label": "Hampen",
                "label_ar": "هامبن",
                "label_fr": "Hampen"
            },
            {
                "value": 61186,
                "label": "Rødby",
                "label_ar": "رودبي",
                "label_fr": "Rødby"
            },
            {
                "value": 61354,
                "label": "Gredstedbro",
                "label_ar": "Gredstedbro",
                "label_fr": "Gredstedbro"
            },
            {
                "value": 61115,
                "label": "Hornbaek",
                "label_ar": "هورنبيك",
                "label_fr": "Hornbaek"
            },
            {
                "value": 61332,
                "label": "Gelsted",
                "label_ar": "جيلستيد",
                "label_fr": "Gelsted"
            },
            {
                "value": 61535,
                "label": "No",
                "label_ar": "لا",
                "label_fr": "Non"
            },
            {
                "value": 61096,
                "label": "Borup",
                "label_ar": "بوروب",
                "label_fr": "Borup"
            },
            {
                "value": 61204,
                "label": "Esbjerg",
                "label_ar": "Esbjerg",
                "label_fr": "Esbjerg"
            },
            {
                "value": 61372,
                "label": "Vaeggerlose",
                "label_ar": "Vaeggerlose",
                "label_fr": "Vaeggerlose"
            },
            {
                "value": 61341,
                "label": "Klemensker",
                "label_ar": "كليمنسكر",
                "label_fr": "Klemensker"
            },
            {
                "value": 61049,
                "label": "Skanderborg",
                "label_ar": "سكاندربورج",
                "label_fr": "Skanderborg"
            },
            {
                "value": 61190,
                "label": "Lynge",
                "label_ar": "لينج",
                "label_fr": "Lynge"
            },
            {
                "value": 61456,
                "label": "Kolsen",
                "label_ar": "كولسن",
                "label_fr": "Kolsen"
            },
            {
                "value": 60956,
                "label": "Aarhus",
                "label_ar": "آرهوس",
                "label_fr": "Aarhus"
            },
            {
                "value": 61160,
                "label": "Hadsund",
                "label_ar": "هادسوند",
                "label_fr": "Hadsund"
            },
            {
                "value": 61427,
                "label": "Harboore",
                "label_ar": "هاربور",
                "label_fr": "Harboore"
            },
            {
                "value": 61255,
                "label": "Allingabro",
                "label_ar": "الينابرو",
                "label_fr": "Allingabro"
            },
            {
                "value": 61449,
                "label": "Langebæk",
                "label_ar": "لانجبيك",
                "label_fr": "Langebæk"
            },
            {
                "value": 61504,
                "label": "Tim",
                "label_ar": "تيم",
                "label_fr": "Tim"
            },
            {
                "value": 61385,
                "label": "Nodebo",
                "label_ar": "نوديبو",
                "label_fr": "Nodebo"
            },
            {
                "value": 61277,
                "label": "Almind",
                "label_ar": "ألميند",
                "label_fr": "Almind"
            },
            {
                "value": 61245,
                "label": "Gislev",
                "label_ar": "جيسليف",
                "label_fr": "Gislev"
            },
            {
                "value": 61382,
                "label": "Hosterkob",
                "label_ar": "هوستركوب",
                "label_fr": "Hosterkob"
            },
            {
                "value": 61481,
                "label": "Rynkeby",
                "label_ar": "رينكيبي",
                "label_fr": "Rynkeby"
            },
            {
                "value": 61365,
                "label": "Bryrup",
                "label_ar": "برايب",
                "label_fr": "Bryrup"
            },
            {
                "value": 61241,
                "label": "Billund",
                "label_ar": "بيلوند",
                "label_fr": "Billund"
            },
            {
                "value": 61551,
                "label": "Henne Strand",
                "label_ar": "هين ستراند",
                "label_fr": "Henne Strand"
            },
            {
                "value": 61416,
                "label": "Refsvindinge",
                "label_ar": "Refsvindinge",
                "label_fr": "Refsvindinge"
            },
            {
                "value": 61055,
                "label": "Stenderup",
                "label_ar": "ستندراب",
                "label_fr": "Stenderup"
            },
            {
                "value": 61335,
                "label": "Stensved",
                "label_ar": "ستينسفيد",
                "label_fr": "Stensved"
            },
            {
                "value": 60968,
                "label": "Ishøj",
                "label_ar": "إيشوج",
                "label_fr": "Ishøj"
            },
            {
                "value": 61223,
                "label": "Mern",
                "label_ar": "ميرن",
                "label_fr": "Mern"
            },
            {
                "value": 60986,
                "label": "Korsør",
                "label_ar": "كورسور",
                "label_fr": "Korsør"
            },
            {
                "value": 60977,
                "label": "Assens",
                "label_ar": "أسينز",
                "label_fr": "Assens"
            },
            {
                "value": 61054,
                "label": "Vamdrup",
                "label_ar": "فامدروب",
                "label_fr": "Vamdrup"
            },
            {
                "value": 61528,
                "label": "Bolderslev",
                "label_ar": "بولديرسليف",
                "label_fr": "Bolderslev"
            },
            {
                "value": 61174,
                "label": "Ærøskøbing",
                "label_ar": "Ærøskøbing",
                "label_fr": "Ærøskøbing"
            },
            {
                "value": 61107,
                "label": "Sindal",
                "label_ar": "سندال",
                "label_fr": "Sindal"
            },
            {
                "value": 61169,
                "label": "Herfolge",
                "label_ar": "هيرفولج",
                "label_fr": "Herfolge"
            },
            {
                "value": 60995,
                "label": "Allerod",
                "label_ar": "أليرود",
                "label_fr": "Allerod"
            },
            {
                "value": 61352,
                "label": "Kjellerup",
                "label_ar": "كجيلروب",
                "label_fr": "Kjellerup"
            },
            {
                "value": 61259,
                "label": "Holsted",
                "label_ar": "هولستيد",
                "label_fr": "Holsted"
            },
            {
                "value": 61228,
                "label": "Spentrup",
                "label_ar": "سبينتروب",
                "label_fr": "Spentrup"
            },
            {
                "value": 61387,
                "label": "Ebberup",
                "label_ar": "إيبيروب",
                "label_fr": "Ebberup"
            },
            {
                "value": 61286,
                "label": "Brenderup",
                "label_ar": "برندراب",
                "label_fr": "Brenderup"
            },
            {
                "value": 61148,
                "label": "Hals",
                "label_ar": "هالس",
                "label_fr": "Hals"
            },
            {
                "value": 61123,
                "label": "Præstø",
                "label_ar": "Præstø",
                "label_fr": "Præstø"
            },
            {
                "value": 61457,
                "label": "Bedsted",
                "label_ar": "سرير",
                "label_fr": "Lit"
            },
            {
                "value": 61557,
                "label": "Errindlev",
                "label_ar": "اريندليف",
                "label_fr": "Errindlev"
            },
            {
                "value": 61256,
                "label": "Tjaereborg",
                "label_ar": "تجايربورج",
                "label_fr": "Tjaereborg"
            },
            {
                "value": 61548,
                "label": "Kaldred",
                "label_ar": "كالدريد",
                "label_fr": "Kaldred"
            },
            {
                "value": 61462,
                "label": "Baekke",
                "label_ar": "بيكي",
                "label_fr": "Baekke"
            },
            {
                "value": 61195,
                "label": "Hedensted",
                "label_ar": "هيدينستيد",
                "label_fr": "Hedensted"
            },
            {
                "value": 61453,
                "label": "Hasle",
                "label_ar": "هاسل",
                "label_fr": "Hasle"
            },
            {
                "value": 61404,
                "label": "Hejnsvig",
                "label_ar": "Hejnsvig",
                "label_fr": "Hejnsvig"
            },
            {
                "value": 60966,
                "label": "Hedehusene",
                "label_ar": "Hedehusene",
                "label_fr": "Hedehusene"
            },
            {
                "value": 61349,
                "label": "Kolind",
                "label_ar": "كوليند",
                "label_fr": "Kolind"
            },
            {
                "value": 61276,
                "label": "Sjolund",
                "label_ar": "Sjolund",
                "label_fr": "Sjolund"
            },
            {
                "value": 60969,
                "label": "Frederikssund",
                "label_ar": "فريدريكسوند",
                "label_fr": "Frederikssund"
            },
            {
                "value": 61556,
                "label": "Naesby",
                "label_ar": "نايسبي",
                "label_fr": "Naesby"
            },
            {
                "value": 61269,
                "label": "Kjargaard",
                "label_ar": "كارجارد",
                "label_fr": "Kjargaard"
            },
            {
                "value": 61214,
                "label": "Stenlille",
                "label_ar": "ستينليل",
                "label_fr": "Stenlille"
            },
            {
                "value": 61075,
                "label": "Charlottenlund",
                "label_ar": "شارلوتينلوند",
                "label_fr": "Charlottenlund"
            },
            {
                "value": 61554,
                "label": "Bevtoft",
                "label_ar": "بيفتوفت",
                "label_fr": "Bevtoft"
            },
            {
                "value": 61017,
                "label": "Malov",
                "label_ar": "مالوف",
                "label_fr": "Malov"
            },
            {
                "value": 61041,
                "label": "Herning",
                "label_ar": "هيرنينغ",
                "label_fr": "Herning"
            },
            {
                "value": 61537,
                "label": "Kalvehave",
                "label_ar": "كالفيه",
                "label_fr": "Kalvehave"
            },
            {
                "value": 61029,
                "label": "Faaborg",
                "label_ar": "فابورج",
                "label_fr": "Faaborg"
            },
            {
                "value": 61274,
                "label": "Suldrup",
                "label_ar": "سولدروب",
                "label_fr": "Suldrup"
            },
            {
                "value": 61493,
                "label": "Toreby",
                "label_ar": "توريبي",
                "label_fr": "Toreby"
            },
            {
                "value": 61396,
                "label": "Harndrup",
                "label_ar": "هارندروب",
                "label_fr": "Harndrup"
            },
            {
                "value": 61392,
                "label": "Gråsten",
                "label_ar": "جرستن",
                "label_fr": "Gråsten"
            },
            {
                "value": 60993,
                "label": "Farum",
                "label_ar": "فاروم",
                "label_fr": "Farum"
            },
            {
                "value": 60944,
                "label": "Bronshoj",
                "label_ar": "برونشوج",
                "label_fr": "Bronshoj"
            },
            {
                "value": 61014,
                "label": "Nørre Alslev",
                "label_ar": "نور السليف",
                "label_fr": "Nørre Alslev"
            },
            {
                "value": 61328,
                "label": "Solbjerg",
                "label_ar": "Solbjerg",
                "label_fr": "Solbjerg"
            },
            {
                "value": 61248,
                "label": "Ranum",
                "label_ar": "رانوم",
                "label_fr": "Ranum"
            },
            {
                "value": 61278,
                "label": "Glumso",
                "label_ar": "جلومسو",
                "label_fr": "Glumso"
            },
            {
                "value": 61357,
                "label": "Ulfborg",
                "label_ar": "أولفورج",
                "label_fr": "Ulfborg"
            },
            {
                "value": 61544,
                "label": "Vestervig",
                "label_ar": "فيسترفج",
                "label_fr": "Vestervig"
            },
            {
                "value": 61027,
                "label": "Viby",
                "label_ar": "فيبي",
                "label_fr": "Viby"
            },
            {
                "value": 61226,
                "label": "Kettinge",
                "label_ar": "Kettinge",
                "label_fr": "Kettinge"
            },
            {
                "value": 61261,
                "label": "Tistrup",
                "label_ar": "تستروب",
                "label_fr": "Tistrup"
            },
            {
                "value": 61350,
                "label": "Engesvang",
                "label_ar": "Engesvang",
                "label_fr": "Engesvang"
            },
            {
                "value": 61451,
                "label": "Store Fuglede",
                "label_ar": "متجر Fuglede",
                "label_fr": "Magasin Fuglede"
            },
            {
                "value": 60998,
                "label": "Hornslet",
                "label_ar": "هورنسلت",
                "label_fr": "Corneille"
            },
            {
                "value": 60988,
                "label": "Holbaek",
                "label_ar": "هولبيك",
                "label_fr": "Holbaek"
            },
            {
                "value": 61348,
                "label": "Kejlstrup",
                "label_ar": "Kejlstrup",
                "label_fr": "Kejlstrup"
            },
            {
                "value": 61517,
                "label": "Hodsager",
                "label_ar": "هودساجر",
                "label_fr": "Hodsager"
            },
            {
                "value": 61407,
                "label": "Stenstrup",
                "label_ar": "ستينستروب",
                "label_fr": "Stenstrup"
            },
            {
                "value": 61326,
                "label": "Norre Aby",
                "label_ar": "نوري أبي",
                "label_fr": "Norre Aby"
            },
            {
                "value": 60963,
                "label": "Birkerød",
                "label_ar": "بيركيرود",
                "label_fr": "Birkerød"
            },
            {
                "value": 61362,
                "label": "Langa",
                "label_ar": "لانجا",
                "label_fr": "Langa"
            },
            {
                "value": 61306,
                "label": "Asa",
                "label_ar": "ك",
                "label_fr": "Comme un"
            },
            {
                "value": 61145,
                "label": "Morkov",
                "label_ar": "موركوف",
                "label_fr": "Morkov"
            },
            {
                "value": 61260,
                "label": "Gram",
                "label_ar": "غرام",
                "label_fr": "Gramme"
            },
            {
                "value": 61047,
                "label": "Augustenborg",
                "label_ar": "أوغستينبورغ",
                "label_fr": "Augustenborg"
            },
            {
                "value": 61088,
                "label": "Vig",
                "label_ar": "فيج",
                "label_fr": "Vig"
            },
            {
                "value": 61414,
                "label": "Dannemare",
                "label_ar": "دانيماري",
                "label_fr": "Dannemare"
            },
            {
                "value": 61035,
                "label": "Arslev",
                "label_ar": "أرسلليف",
                "label_fr": "Arslev"
            },
            {
                "value": 61052,
                "label": "Odder",
                "label_ar": "الغريب",
                "label_fr": "Odder"
            },
            {
                "value": 61437,
                "label": "Hou",
                "label_ar": "هوى",
                "label_fr": "Hou"
            },
            {
                "value": 61397,
                "label": "Borkop",
                "label_ar": "بوركوب",
                "label_fr": "Borkop"
            },
            {
                "value": 61415,
                "label": "Ansager",
                "label_ar": "أنساجر",
                "label_fr": "Ansager"
            },
            {
                "value": 61378,
                "label": "Sunds",
                "label_ar": "سندس",
                "label_fr": "Sunds"
            },
            {
                "value": 61193,
                "label": "Vejen",
                "label_ar": "فيجين",
                "label_fr": "Vejen"
            },
            {
                "value": 61279,
                "label": "Hovedgard",
                "label_ar": "هوفيدجارد",
                "label_fr": "Hovedgard"
            },
            {
                "value": 60970,
                "label": "Tarm",
                "label_ar": "تارم",
                "label_fr": "Tarm"
            },
            {
                "value": 61555,
                "label": "Martofte",
                "label_ar": "مارتوفتي",
                "label_fr": "Martofte"
            },
            {
                "value": 61164,
                "label": "Oster Assels",
                "label_ar": "أوستر أسيلز",
                "label_fr": "Oster Assels"
            },
            {
                "value": 61400,
                "label": "Gladsaxe",
                "label_ar": "جلادساكس",
                "label_fr": "Gladsaxe"
            },
            {
                "value": 61345,
                "label": "Trige",
                "label_ar": "تريج",
                "label_fr": "Trige"
            },
            {
                "value": 61314,
                "label": "Akirkeby",
                "label_ar": "أكيركيبي",
                "label_fr": "Akirkeby"
            },
            {
                "value": 61118,
                "label": "Gislinge",
                "label_ar": "جيزلينج",
                "label_fr": "Gislinge"
            },
            {
                "value": 61100,
                "label": "Olsted",
                "label_ar": "أولستيد",
                "label_fr": "Olsted"
            },
            {
                "value": 61069,
                "label": "Struer",
                "label_ar": "سترور",
                "label_fr": "Struer"
            },
            {
                "value": 61136,
                "label": "Aalborg",
                "label_ar": "البورك",
                "label_fr": "Aalborg"
            },
            {
                "value": 61494,
                "label": "Horbelev",
                "label_ar": "هوربيليف",
                "label_fr": "Horbelev"
            },
            {
                "value": 61045,
                "label": "Karlslunde",
                "label_ar": "كارلسلوند",
                "label_fr": "Karlslunde"
            },
            {
                "value": 61322,
                "label": "Harby",
                "label_ar": "حربي",
                "label_fr": "Harby"
            },
            {
                "value": 60957,
                "label": "Randers",
                "label_ar": "راندرز",
                "label_fr": "Randers"
            },
            {
                "value": 61547,
                "label": "Vils",
                "label_ar": "شجيرة",
                "label_fr": "Vils"
            },
            {
                "value": 61478,
                "label": "Osby",
                "label_ar": "أوسبي",
                "label_fr": "Osby"
            },
            {
                "value": 61053,
                "label": "Kolding",
                "label_ar": "كولدينج",
                "label_fr": "Kolding"
            },
            {
                "value": 61221,
                "label": "Sollested",
                "label_ar": "متقرح",
                "label_fr": "Sollested"
            },
            {
                "value": 61114,
                "label": "Virum",
                "label_ar": "فيروم",
                "label_fr": "Virum"
            },
            {
                "value": 61083,
                "label": "Helsinge",
                "label_ar": "هيلسينج",
                "label_fr": "Helsinge"
            },
            {
                "value": 61099,
                "label": "Ruds-Vedby",
                "label_ar": "رودس فيدبي",
                "label_fr": "Ruds-Vedby"
            },
            {
                "value": 61257,
                "label": "Henne",
                "label_ar": "هين",
                "label_fr": "Henne"
            },
            {
                "value": 61440,
                "label": "Balle",
                "label_ar": "بال",
                "label_fr": "Balle"
            },
            {
                "value": 61198,
                "label": "Frederiksberg",
                "label_ar": "فريدريكسبيرج",
                "label_fr": "Frederiksberg"
            },
            {
                "value": 61063,
                "label": "Ostbirk",
                "label_ar": "أوستبيرك",
                "label_fr": "Ostbirk"
            },
            {
                "value": 61033,
                "label": "Middelfart",
                "label_ar": "ميدلفارت",
                "label_fr": "Middelfart"
            },
            {
                "value": 61280,
                "label": "Ebeltoft",
                "label_ar": "إيبلتوفت",
                "label_fr": "Ebeltoft"
            },
            {
                "value": 61525,
                "label": "TRUE",
                "label_ar": "صحيح",
                "label_fr": "VRAI"
            },
            {
                "value": 61420,
                "label": "Slet",
                "label_ar": "سليت",
                "label_fr": "Slet"
            },
            {
                "value": 61408,
                "label": "Hoje Tastrup",
                "label_ar": "هوجي تاستروب",
                "label_fr": "Hoje Tastrup"
            },
            {
                "value": 61401,
                "label": "Horning",
                "label_ar": "هورننج",
                "label_fr": "Horning"
            },
            {
                "value": 61153,
                "label": "Rungsted",
                "label_ar": "رونجستيد",
                "label_fr": "Rungsted"
            },
            {
                "value": 61441,
                "label": "Kongerslev",
                "label_ar": "كونغرسليف",
                "label_fr": "Kongerslev"
            },
            {
                "value": 61337,
                "label": "Bovrup",
                "label_ar": "بوفروب",
                "label_fr": "Bovrup"
            },
            {
                "value": 61044,
                "label": "Christiansfeld",
                "label_ar": "كريستيانسفيلد",
                "label_fr": "Christiansfeld"
            },
            {
                "value": 61089,
                "label": "Hadsten",
                "label_ar": "هادستن",
                "label_fr": "Hadsten"
            },
            {
                "value": 61425,
                "label": "Bandholm",
                "label_ar": "باندهولم",
                "label_fr": "Bandholm"
            },
            {
                "value": 61508,
                "label": "Ramlose",
                "label_ar": "راملوز",
                "label_fr": "Ramlose"
            },
            {
                "value": 61046,
                "label": "Sønderborg",
                "label_ar": "Sønderborg",
                "label_fr": "Sønderborg"
            },
            {
                "value": 61294,
                "label": "Otterup",
                "label_ar": "Otterup",
                "label_fr": "Otterup"
            },
            {
                "value": 61541,
                "label": "Flauenskjold",
                "label_ar": "فلاوينسكجولد",
                "label_fr": "Flauenskjold"
            },
            {
                "value": 61258,
                "label": "Sherrebek",
                "label_ar": "شيريبك",
                "label_fr": "Sherrebek"
            },
            {
                "value": 61233,
                "label": "Løgumkloster",
                "label_ar": "Løgumkloster",
                "label_fr": "Løgumkloster"
            },
            {
                "value": 61112,
                "label": "Kastrup",
                "label_ar": "كاستروب",
                "label_fr": "Kastrup"
            },
            {
                "value": 61527,
                "label": "Vinkel",
                "label_ar": "فينكل",
                "label_fr": "Vinkel"
            },
            {
                "value": 61128,
                "label": "Morke",
                "label_ar": "مورك",
                "label_fr": "Morke"
            },
            {
                "value": 61370,
                "label": "Sabro",
                "label_ar": "سابرو",
                "label_fr": "Sabro"
            },
            {
                "value": 61159,
                "label": "Storvorde",
                "label_ar": "ستورفورد",
                "label_fr": "Storvorde"
            },
            {
                "value": 61428,
                "label": "Spottrup",
                "label_ar": "سبوتراب",
                "label_fr": "Spottrup"
            },
            {
                "value": 61482,
                "label": "Asperup",
                "label_ar": "Asperup",
                "label_fr": "Asperup"
            },
            {
                "value": 61163,
                "label": "Haderup",
                "label_ar": "هادروب",
                "label_fr": "Haderup"
            },
            {
                "value": 61272,
                "label": "Vestbjerg",
                "label_ar": "فيستبيرج",
                "label_fr": "Vestbjerg"
            },
            {
                "value": 61222,
                "label": "Guldborg",
                "label_ar": "جولدبرج",
                "label_fr": "Guldborg"
            },
            {
                "value": 61474,
                "label": "Veflinge",
                "label_ar": "فيفلينج",
                "label_fr": "Veflinge"
            },
            {
                "value": 61472,
                "label": "Nordby",
                "label_ar": "نوردبي",
                "label_fr": "Nordby"
            },
            {
                "value": 61004,
                "label": "Gentofte",
                "label_ar": "جينتوفتي",
                "label_fr": "Gentofte"
            },
            {
                "value": 61023,
                "label": "Lejre",
                "label_ar": "ليجر",
                "label_fr": "Lejre"
            },
            {
                "value": 61200,
                "label": "Tagerup",
                "label_ar": "تايجروب",
                "label_fr": "Tagerup"
            },
            {
                "value": 61133,
                "label": "Abyhoj",
                "label_ar": "ابيهوج",
                "label_fr": "Abyhoj"
            },
            {
                "value": 61206,
                "label": "Skaelskor",
                "label_ar": "Skaelskor",
                "label_fr": "Skaelskor"
            },
            {
                "value": 61338,
                "label": "Tornby",
                "label_ar": "تورنبي",
                "label_fr": "Tornby"
            },
            {
                "value": 61142,
                "label": "Idestrup",
                "label_ar": "Idestrup",
                "label_fr": "Idestrup"
            },
            {
                "value": 61422,
                "label": "Lambjerg",
                "label_ar": "لامبيرج",
                "label_fr": "Lambjerg"
            },
            {
                "value": 61168,
                "label": "Hojby",
                "label_ar": "هوجبي",
                "label_fr": "Hojby"
            },
            {
                "value": 61469,
                "label": "Bjaert",
                "label_ar": "بجيرت",
                "label_fr": "Bjaert"
            },
            {
                "value": 61117,
                "label": "Holte",
                "label_ar": "هولت",
                "label_fr": "Holte"
            },
            {
                "value": 61184,
                "label": "Stubbekøbing",
                "label_ar": "Stubbekøbing",
                "label_fr": "Stubbekøbing"
            },
            {
                "value": 61224,
                "label": "Karrebaeksminde",
                "label_ar": "Karrebaeksminde",
                "label_fr": "Karrebaeksminde"
            },
            {
                "value": 61021,
                "label": "Kirke-Hyllinge",
                "label_ar": "كيرك هيلينج",
                "label_fr": "Kirke-Hyllinge"
            },
            {
                "value": 61484,
                "label": "Skals",
                "label_ar": "سكالس",
                "label_fr": "Skals"
            },
            {
                "value": 61475,
                "label": "Brobyvaerk",
                "label_ar": "Brobyvaerk",
                "label_fr": "Brobyvaerk"
            },
            {
                "value": 61238,
                "label": "Nordborg",
                "label_ar": "نوردبورغ",
                "label_fr": "Nordborg"
            },
            {
                "value": 61377,
                "label": "Jerup",
                "label_ar": "جيروب",
                "label_fr": "Jerup"
            },
            {
                "value": 61284,
                "label": "Give",
                "label_ar": "يعطى",
                "label_fr": "Donner"
            },
            {
                "value": 61506,
                "label": "Nim",
                "label_ar": "نيم",
                "label_fr": "Nim"
            },
            {
                "value": 61327,
                "label": "Skævinge",
                "label_ar": "Skævinge",
                "label_fr": "Skævinge"
            },
            {
                "value": 61502,
                "label": "Boeslunde",
                "label_ar": "بوزلوند",
                "label_fr": "Boeslunde"
            },
            {
                "value": 61366,
                "label": "Nykobing Sjaelland",
                "label_ar": "نيكوبينغ سيالاند",
                "label_fr": "Nykobing Sjaelland"
            },
            {
                "value": 61108,
                "label": "Strandby",
                "label_ar": "ستراندبي",
                "label_fr": "Strandby"
            },
            {
                "value": 61134,
                "label": "Vaerlose",
                "label_ar": "فيرلوز",
                "label_fr": "Vaerlose"
            },
            {
                "value": 61463,
                "label": "Gesten",
                "label_ar": "جيستن",
                "label_fr": "Gesten"
            },
            {
                "value": 61000,
                "label": "Skodstrup",
                "label_ar": "سكودستروب",
                "label_fr": "Skodstrup"
            },
            {
                "value": 61185,
                "label": "Eskilstrup",
                "label_ar": "إسكيلستروب",
                "label_fr": "Eskilstrup"
            },
            {
                "value": 61106,
                "label": "Vra",
                "label_ar": "Vra",
                "label_fr": "Vra"
            },
            {
                "value": 61390,
                "label": "Arden",
                "label_ar": "أردن",
                "label_fr": "Arden"
            },
            {
                "value": 61538,
                "label": "Brøndby",
                "label_ar": "بروندبي",
                "label_fr": "Brøndby"
            },
            {
                "value": 61310,
                "label": "Dybvad",
                "label_ar": "ديبفاد",
                "label_fr": "Dybvad"
            },
            {
                "value": 61124,
                "label": "Lundby Stationsby",
                "label_ar": "محطات Lundby",
                "label_fr": "Stations de Lundby"
            },
            {
                "value": 61215,
                "label": "Gammel Svebolle",
                "label_ar": "Gammel Svebolle",
                "label_fr": "Gammel Svebolle"
            },
            {
                "value": 61103,
                "label": "Vadum",
                "label_ar": "فادوم",
                "label_fr": "Vadum"
            },
            {
                "value": 61340,
                "label": "Oure",
                "label_ar": "Oure",
                "label_fr": "Oure"
            },
            {
                "value": 60974,
                "label": "Vordingborg",
                "label_ar": "فوردينجبورج",
                "label_fr": "Vordingborg"
            },
            {
                "value": 61343,
                "label": "Bogense",
                "label_ar": "بوجينسي",
                "label_fr": "Bogense"
            },
            {
                "value": 61154,
                "label": "Naerum",
                "label_ar": "Naerum",
                "label_fr": "Naerum"
            },
            {
                "value": 61151,
                "label": "Bjerringbro",
                "label_ar": "بيرينغبرو",
                "label_fr": "Bjerringbro"
            },
            {
                "value": 61389,
                "label": "Beder",
                "label_ar": "بيدر",
                "label_fr": "Beder"
            },
            {
                "value": 61509,
                "label": "Ferslev",
                "label_ar": "فرسليف",
                "label_fr": "Ferslev"
            },
            {
                "value": 61020,
                "label": "Skovlunde",
                "label_ar": "سكوفلوند",
                "label_fr": "Skovlunde"
            },
            {
                "value": 61146,
                "label": "Svinninge",
                "label_ar": "سفينينج",
                "label_fr": "Svinninge"
            },
            {
                "value": 61077,
                "label": "Greve",
                "label_ar": "جريف",
                "label_fr": "Greve"
            },
            {
                "value": 61005,
                "label": "Ronde",
                "label_ar": "روند",
                "label_fr": "Ronde"
            },
            {
                "value": 61435,
                "label": "Bording Stationsby",
                "label_ar": "محطات بوردينج",
                "label_fr": "Bording Stationsby"
            },
            {
                "value": 61498,
                "label": "Eskebjerg",
                "label_ar": "إسكيبجيرج",
                "label_fr": "Eskebjerg"
            },
            {
                "value": 61217,
                "label": "Vemmelev",
                "label_ar": "فيميليف",
                "label_fr": "Vemmelev"
            },
            {
                "value": 60959,
                "label": "Egtved",
                "label_ar": "اغتفيد",
                "label_fr": "Egtved"
            },
            {
                "value": 61263,
                "label": "Nordenskov",
                "label_ar": "نوردنسكوف",
                "label_fr": "Nordenskov"
            },
            {
                "value": 61196,
                "label": "Harpelunde",
                "label_ar": "Harpelunde",
                "label_fr": "Harpelunde"
            },
            {
                "value": 61501,
                "label": "Melby",
                "label_ar": "ميلبي",
                "label_fr": "Melby"
            },
            {
                "value": 61187,
                "label": "Vemb",
                "label_ar": "فيمب",
                "label_fr": "Vemb"
            },
            {
                "value": 61446,
                "label": "Humble",
                "label_ar": "متواضع",
                "label_fr": "Humble"
            },
            {
                "value": 61330,
                "label": "Vipperod",
                "label_ar": "فيبيرود",
                "label_fr": "Vipperod"
            },
            {
                "value": 61011,
                "label": "Fredericia",
                "label_ar": "فريدريكيا",
                "label_fr": "Fredericia"
            },
            {
                "value": 61312,
                "label": "Skagen",
                "label_ar": "سكاجين",
                "label_fr": "Skagen"
            },
            {
                "value": 61302,
                "label": "Hundslund",
                "label_ar": "هوندسلوند",
                "label_fr": "Hundslund"
            },
            {
                "value": 61477,
                "label": "Verninge",
                "label_ar": "فيرنينج",
                "label_fr": "Verninge"
            },
            {
                "value": 61297,
                "label": "Fuglebjerg",
                "label_ar": "Fuglebjerg",
                "label_fr": "Fuglebjerg"
            },
            {
                "value": 61436,
                "label": "Vester Hassing",
                "label_ar": "فيستر هاسينج",
                "label_fr": "Vester Hassing"
            },
            {
                "value": 61386,
                "label": "Skarup",
                "label_ar": "سكاروب",
                "label_fr": "Skarup"
            },
            {
                "value": 61076,
                "label": "Klampenborg",
                "label_ar": "كلامبينبورغ",
                "label_fr": "Klampenborg"
            },
            {
                "value": 61317,
                "label": "Svenstrup",
                "label_ar": "سفينستروب",
                "label_fr": "Svenstrup"
            },
            {
                "value": 61230,
                "label": "Tønder",
                "label_ar": "توندر",
                "label_fr": "Tønder"
            },
            {
                "value": 61002,
                "label": "Hinnerup",
                "label_ar": "هينيروب",
                "label_fr": "Hinnerup"
            },
            {
                "value": 61464,
                "label": "Viuf",
                "label_ar": "فيوف",
                "label_fr": "Viuf"
            },
            {
                "value": 61460,
                "label": "Sundby",
                "label_ar": "سوندبي",
                "label_fr": "Sundby"
            },
            {
                "value": 60973,
                "label": "Rønne",
                "label_ar": "روني",
                "label_fr": "Rønne"
            },
            {
                "value": 61447,
                "label": "Marslev",
                "label_ar": "مارسليف",
                "label_fr": "Marslev"
            },
            {
                "value": 61152,
                "label": "Vedbaek",
                "label_ar": "فيدبيك",
                "label_fr": "Vedbaek"
            },
            {
                "value": 61022,
                "label": "Kirke Saby",
                "label_ar": "كيرك سابي",
                "label_fr": "Kirke Saby"
            },
            {
                "value": 61171,
                "label": "Rønnede",
                "label_ar": "رونيد",
                "label_fr": "Rønnede"
            },
            {
                "value": 61522,
                "label": "Glejbjerg",
                "label_ar": "جليبجيرج",
                "label_fr": "Glejbjerg"
            },
            {
                "value": 61549,
                "label": "Dong",
                "label_ar": "دونغ",
                "label_fr": "Dong"
            },
            {
                "value": 61251,
                "label": "Tranebjerg",
                "label_ar": "ترانيبيرج",
                "label_fr": "Tranebjerg"
            },
            {
                "value": 61309,
                "label": "Saeby",
                "label_ar": "صعيبي",
                "label_fr": "Saeby"
            },
            {
                "value": 61458,
                "label": "Skodsborg",
                "label_ar": "سكودسبورج",
                "label_fr": "Skodsborg"
            },
            {
                "value": 61483,
                "label": "Kvaerndrup",
                "label_ar": "كفارندروب",
                "label_fr": "Kvaerndrup"
            },
            {
                "value": 61219,
                "label": "Munke Bjergby",
                "label_ar": "مونكي بيرجبي",
                "label_fr": "Munke Bjergby"
            },
            {
                "value": 61429,
                "label": "Farvang",
                "label_ar": "فارفانج",
                "label_fr": "Farvang"
            },
            {
                "value": 61351,
                "label": "Hojslev",
                "label_ar": "هوجسليف",
                "label_fr": "Hojslev"
            },
            {
                "value": 61037,
                "label": "Svendborg",
                "label_ar": "سفينبورغ",
                "label_fr": "Svendborg"
            },
            {
                "value": 61296,
                "label": "Alslev",
                "label_ar": "ألسليف",
                "label_fr": "Alslev"
            },
            {
                "value": 61235,
                "label": "Ølgod",
                "label_ar": "Ølgod",
                "label_fr": "Ølgod"
            },
            {
                "value": 61165,
                "label": "Erslev",
                "label_ar": "إرسليف",
                "label_fr": "Erslev"
            },
            {
                "value": 61282,
                "label": "Dronninglund",
                "label_ar": "درونينجلوند",
                "label_fr": "Dronninglund"
            },
            {
                "value": 61496,
                "label": "Glyngore",
                "label_ar": "جلينجور",
                "label_fr": "Glyngore"
            },
            {
                "value": 61413,
                "label": "Laurbjerg",
                "label_ar": "لوربجيرج",
                "label_fr": "Laurbjerg"
            },
            {
                "value": 61364,
                "label": "Ale",
                "label_ar": "البيرة",
                "label_fr": "Ale"
            },
            {
                "value": 61101,
                "label": "Vejby",
                "label_ar": "فيجبي",
                "label_fr": "Vejby"
            },
            {
                "value": 61093,
                "label": "Ledoje",
                "label_ar": "ليدوجي",
                "label_fr": "Ledoje"
            },
            {
                "value": 61369,
                "label": "Vejstrup",
                "label_ar": "فيجستروب",
                "label_fr": "Vejstrup"
            },
            {
                "value": 61454,
                "label": "Blavand",
                "label_ar": "بلافاند",
                "label_fr": "Blavand"
            },
            {
                "value": 60978,
                "label": "Odense",
                "label_ar": "أودنس",
                "label_fr": "Odense"
            },
            {
                "value": 61070,
                "label": "Roslev",
                "label_ar": "روسليف",
                "label_fr": "Roslev"
            },
            {
                "value": 61183,
                "label": "Nysted",
                "label_ar": "متشنج",
                "label_fr": "Nysted"
            },
            {
                "value": 61273,
                "label": "Tylstrup",
                "label_ar": "تايلستروب",
                "label_fr": "Tylstrup"
            },
            {
                "value": 61109,
                "label": "Norre Bindslev",
                "label_ar": "نوري بيندسليف",
                "label_fr": "Norre Bindslev"
            },
            {
                "value": 61316,
                "label": "Jorlunde",
                "label_ar": "يورلوند",
                "label_fr": "Jorlunde"
            },
            {
                "value": 60997,
                "label": "Ega",
                "label_ar": "إيغا",
                "label_fr": "Ega"
            },
            {
                "value": 60964,
                "label": "Hvidovre",
                "label_ar": "هفيدوفر",
                "label_fr": "Hvidovre"
            },
            {
                "value": 61182,
                "label": "Maribo",
                "label_ar": "ماريبو",
                "label_fr": "Maribo"
            },
            {
                "value": 61065,
                "label": "Snedsted",
                "label_ar": "سنيدستيد",
                "label_fr": "Snedsted"
            },
            {
                "value": 61467,
                "label": "Barrit",
                "label_ar": "باريت",
                "label_fr": "Barrit"
            },
            {
                "value": 60946,
                "label": "Hillerød",
                "label_ar": "هيليرود",
                "label_fr": "Hillerød"
            },
            {
                "value": 61539,
                "label": "Horslunde",
                "label_ar": "هورسلوند",
                "label_fr": "Horslunde"
            },
            {
                "value": 61189,
                "label": "Norager",
                "label_ar": "نوراجر",
                "label_fr": "Norager"
            },
            {
                "value": 61097,
                "label": "Harlev",
                "label_ar": "هارليف",
                "label_fr": "Harlev"
            },
            {
                "value": 61380,
                "label": "Hellebaek",
                "label_ar": "هيلبيك",
                "label_fr": "Hellebaek"
            },
            {
                "value": 61521,
                "label": "Sommersted",
                "label_ar": "سومرستد",
                "label_fr": "Sommersted"
            },
            {
                "value": 61090,
                "label": "Viby",
                "label_ar": "فيبي",
                "label_fr": "Viby"
            },
            {
                "value": 61166,
                "label": "Frostrup",
                "label_ar": "فروستروب",
                "label_fr": "Frostrup"
            },
            {
                "value": 61040,
                "label": "Ans",
                "label_ar": "الجواب",
                "label_fr": "Ans"
            },
            {
                "value": 61543,
                "label": "Tejn",
                "label_ar": "تيجن",
                "label_fr": "Tejn"
            },
            {
                "value": 61553,
                "label": "Rebaek",
                "label_ar": "ربيك",
                "label_fr": "Rebaek"
            },
            {
                "value": 61262,
                "label": "Brorup",
                "label_ar": "Brorup",
                "label_fr": "Brorup"
            },
            {
                "value": 61405,
                "label": "Hesselager",
                "label_ar": "هيسيلاغر",
                "label_fr": "Hesselager"
            },
            {
                "value": 61285,
                "label": "Arre",
                "label_ar": "آري",
                "label_fr": "Arre"
            },
            {
                "value": 61130,
                "label": "Hasselager",
                "label_ar": "هاسلاجر",
                "label_fr": "Hasselager"
            },
            {
                "value": 61550,
                "label": "Holme",
                "label_ar": "هولمي",
                "label_fr": "Holme"
            },
            {
                "value": 61356,
                "label": "Blokhus",
                "label_ar": "بلوخوس",
                "label_fr": "Blokhus"
            },
            {
                "value": 61143,
                "label": "Brovst",
                "label_ar": "بروفست",
                "label_fr": "Brovst"
            },
            {
                "value": 61289,
                "label": "Tisvildeleje",
                "label_ar": "تيسفيلديليجي",
                "label_fr": "Tisvildeleje"
            },
            {
                "value": 61030,
                "label": "Marstal",
                "label_ar": "مارستال",
                "label_fr": "Marstal"
            },
            {
                "value": 61412,
                "label": "Koldby",
                "label_ar": "كولدبي",
                "label_fr": "Koldby"
            },
            {
                "value": 61424,
                "label": "Stakroge",
                "label_ar": "ستاكروجي",
                "label_fr": "Stakroge"
            },
            {
                "value": 60947,
                "label": "Frederiksværk",
                "label_ar": "فريدريكسفارك",
                "label_fr": "Frederiksværk"
            },
            {
                "value": 61197,
                "label": "Rodvig",
                "label_ar": "رودفيج",
                "label_fr": "Rodvig"
            },
            {
                "value": 61172,
                "label": "Vissenbjerg",
                "label_ar": "فيسنبيرج",
                "label_fr": "Vissenbjerg"
            },
            {
                "value": 61324,
                "label": "Klarup",
                "label_ar": "كلروب",
                "label_fr": "Klarup"
            },
            {
                "value": 61175,
                "label": "Nyborg",
                "label_ar": "نيبورج",
                "label_fr": "Nyborg"
            },
            {
                "value": 61513,
                "label": "Klokkerholm",
                "label_ar": "كلوكيرهولم",
                "label_fr": "Klokkerholm"
            },
            {
                "value": 61135,
                "label": "Stege",
                "label_ar": "ستيج",
                "label_fr": "Stege"
            },
            {
                "value": 61471,
                "label": "Millinge",
                "label_ar": "ميلينج",
                "label_fr": "Millinge"
            },
            {
                "value": 61552,
                "label": "Jystrup",
                "label_ar": "جستروب",
                "label_fr": "Jystrup"
            },
            {
                "value": 61137,
                "label": "Stovring",
                "label_ar": "ستوفرينغ",
                "label_fr": "Poêle"
            },
            {
                "value": 61388,
                "label": "Fovling",
                "label_ar": "الحشيش",
                "label_fr": "Fovling"
            },
            {
                "value": 61426,
                "label": "Bovlingbjerg",
                "label_ar": "بوفلينجبيرج",
                "label_fr": "Bovlingbjerg"
            },
            {
                "value": 61320,
                "label": "Harlev",
                "label_ar": "هارليف",
                "label_fr": "Harlev"
            },
            {
                "value": 61384,
                "label": "Lyngby",
                "label_ar": "لينجبي",
                "label_fr": "Lyngby"
            },
            {
                "value": 61430,
                "label": "Gjern",
                "label_ar": "جيرن",
                "label_fr": "Gjern"
            },
            {
                "value": 61158,
                "label": "Norresundby",
                "label_ar": "نوريسوندبي",
                "label_fr": "Norresundby"
            },
            {
                "value": 61013,
                "label": "Nakskov",
                "label_ar": "ناكسكوف",
                "label_fr": "Nakskov"
            },
            {
                "value": 61287,
                "label": "Auning",
                "label_ar": "عونينغ",
                "label_fr": "Auning"
            },
            {
                "value": 61111,
                "label": "Hirtshals",
                "label_ar": "هيرتشيلس",
                "label_fr": "Hirtshals"
            },
            {
                "value": 60953,
                "label": "Viborg",
                "label_ar": "فيبورغ",
                "label_fr": "Viborg"
            },
            {
                "value": 61347,
                "label": "Ryomgard",
                "label_ar": "ريومجارد",
                "label_fr": "Ryomgard"
            },
            {
                "value": 61358,
                "label": "Grindsted",
                "label_ar": "جريندستيد",
                "label_fr": "Grindsted"
            },
            {
                "value": 61531,
                "label": "Arslev",
                "label_ar": "أرسلليف",
                "label_fr": "Arslev"
            },
            {
                "value": 61486,
                "label": "Holme-Olstrup",
                "label_ar": "هولمي أولستروب",
                "label_fr": "Holme-Olstrup"
            },
            {
                "value": 61116,
                "label": "Espergaerde",
                "label_ar": "اسبيرجيردي",
                "label_fr": "Espergaerde"
            },
            {
                "value": 61131,
                "label": "Tilst",
                "label_ar": "Tilst",
                "label_fr": "Tilst"
            },
            {
                "value": 61234,
                "label": "Varde",
                "label_ar": "فاردي",
                "label_fr": "Varde"
            },
            {
                "value": 60989,
                "label": "Karup",
                "label_ar": "كاروب",
                "label_fr": "Karup"
            },
            {
                "value": 60948,
                "label": "Vasby",
                "label_ar": "فاسبي",
                "label_fr": "Vasby"
            },
            {
                "value": 61298,
                "label": "Store Merlose",
                "label_ar": "متجر Merlose",
                "label_fr": "Magasin Merlose"
            },
            {
                "value": 61534,
                "label": "Tikob",
                "label_ar": "تيكوب",
                "label_fr": "Tikob"
            },
            {
                "value": 61510,
                "label": "Soesmarke",
                "label_ar": "سوسمارك",
                "label_fr": "Soesmarke"
            },
            {
                "value": 60981,
                "label": "Lemvig",
                "label_ar": "ليمفيج",
                "label_fr": "Lemvig"
            },
            {
                "value": 61038,
                "label": "Tommerup",
                "label_ar": "توميروب",
                "label_fr": "Tommerup"
            },
            {
                "value": 61006,
                "label": "Vallensbæk",
                "label_ar": "فالينسبوك",
                "label_fr": "Vallensbæk"
            },
            {
                "value": 61058,
                "label": "Lunderskov",
                "label_ar": "لوندرسكوف",
                "label_fr": "Lunderskov"
            },
            {
                "value": 61122,
                "label": "Taastrup",
                "label_ar": "تاستروب",
                "label_fr": "Taastrup"
            },
            {
                "value": 61516,
                "label": "Sorvad",
                "label_ar": "سورفاد",
                "label_fr": "Sorvad"
            },
            {
                "value": 61127,
                "label": "Grenå",
                "label_ar": "غرينو",
                "label_fr": "Grenå"
            },
            {
                "value": 61442,
                "label": "Vebbestrup",
                "label_ar": "فيبستروب",
                "label_fr": "Vebbestrup"
            },
            {
                "value": 61398,
                "label": "Albaek",
                "label_ar": "البيك",
                "label_fr": "Albaek"
            },
            {
                "value": 61129,
                "label": "Virring",
                "label_ar": "فيررينغ",
                "label_fr": "Virring"
            },
            {
                "value": 61518,
                "label": "Dronningmolle",
                "label_ar": "Dronningmolle",
                "label_fr": "Dronningmolle"
            },
            {
                "value": 61505,
                "label": "Hammershoj",
                "label_ar": "هامرشوج",
                "label_fr": "Hammershoj"
            },
            {
                "value": 61225,
                "label": "Bogo By",
                "label_ar": "بوجو بي",
                "label_fr": "Bogo par"
            },
            {
                "value": 61334,
                "label": "Ryslinge",
                "label_ar": "ريسلينج",
                "label_fr": "Ryslinge"
            },
            {
                "value": 61094,
                "label": "Soro",
                "label_ar": "سورو",
                "label_fr": "Soro"
            },
            {
                "value": 61210,
                "label": "Høng",
                "label_ar": "هونج",
                "label_fr": "Høng"
            },
            {
                "value": 61375,
                "label": "Hornsyld",
                "label_ar": "هورنسيلد",
                "label_fr": "Hornsyld"
            },
            {
                "value": 61394,
                "label": "Sonder Omme",
                "label_ar": "سوندر اومي",
                "label_fr": "Sonder Omme"
            },
            {
                "value": 61371,
                "label": "Tarbaek",
                "label_ar": "طربيك",
                "label_fr": "Tarbaek"
            },
            {
                "value": 61227,
                "label": "Rødekro",
                "label_ar": "روديكرو",
                "label_fr": "Rødekro"
            },
            {
                "value": 61119,
                "label": "Ugerlose",
                "label_ar": "Ugerlose",
                "label_fr": "Ugerlose"
            },
            {
                "value": 61181,
                "label": "Tappernoje",
                "label_ar": "تابيرنوجي",
                "label_fr": "Tappernoje"
            },
            {
                "value": 61078,
                "label": "Slagelse",
                "label_ar": "سلاجيلس",
                "label_fr": "Slagelse"
            },
            {
                "value": 60965,
                "label": "Ganlose",
                "label_ar": "جانلوز",
                "label_fr": "Ganlose"
            },
            {
                "value": 61520,
                "label": "Norreballe",
                "label_ar": "نوريبال",
                "label_fr": "Norreballe"
            },
            {
                "value": 61180,
                "label": "Askeby",
                "label_ar": "اسكبي",
                "label_fr": "Askeby"
            },
            {
                "value": 61443,
                "label": "Nexo",
                "label_ar": "نيكسو",
                "label_fr": "Nexo"
            },
            {
                "value": 61031,
                "label": "Langeskov",
                "label_ar": "لانجسكوف",
                "label_fr": "Langeskov"
            },
            {
                "value": 61019,
                "label": "Stenlose",
                "label_ar": "ستينلوز",
                "label_fr": "Stenlose"
            },
            {
                "value": 61009,
                "label": "Kirke Hvalso",
                "label_ar": "كيرك هفالسو",
                "label_fr": "Kirke Hvalso"
            },
            {
                "value": 60962,
                "label": "Hørsholm",
                "label_ar": "هورشولم",
                "label_fr": "Hørsholm"
            },
            {
                "value": 61304,
                "label": "Saltum",
                "label_ar": "ملح",
                "label_fr": "Saltum"
            },
            {
                "value": 61232,
                "label": "Bramming",
                "label_ar": "برامينغ",
                "label_fr": "Bramming"
            },
            {
                "value": 61008,
                "label": "Kokkedal",
                "label_ar": "كوكيدال",
                "label_fr": "Kokkedal"
            },
            {
                "value": 61150,
                "label": "Skjern",
                "label_ar": "سكجيرن",
                "label_fr": "Skjern"
            },
            {
                "value": 61068,
                "label": "Baekmarksbro",
                "label_ar": "Baekmarksbro",
                "label_fr": "Baekmarksbro"
            },
            {
                "value": 61515,
                "label": "Spjald",
                "label_ar": "Spjald",
                "label_fr": "Spjald"
            },
            {
                "value": 61086,
                "label": "Tollose",
                "label_ar": "تولوز",
                "label_fr": "Tollose"
            },
            {
                "value": 61490,
                "label": "Stoholm",
                "label_ar": "ستوهولم",
                "label_fr": "Stoholm"
            },
            {
                "value": 61417,
                "label": "Sorring",
                "label_ar": "سورينج",
                "label_fr": "Affligeant"
            },
            {
                "value": 61211,
                "label": "Stroby",
                "label_ar": "ستروبي",
                "label_fr": "Stroby"
            },
            {
                "value": 61526,
                "label": "Tårnby",
                "label_ar": "تارنبي",
                "label_fr": "Tårnby"
            },
            {
                "value": 61141,
                "label": "Nykobing Falster",
                "label_ar": "نيكوبينج فالستر",
                "label_fr": "Nykobing Falster"
            },
            {
                "value": 61281,
                "label": "Hjallerup",
                "label_ar": "هجاليروب",
                "label_fr": "Hjallerup"
            },
            {
                "value": 61373,
                "label": "Kastrup",
                "label_ar": "كاستروب",
                "label_fr": "Kastrup"
            },
            {
                "value": 61524,
                "label": "Branderup",
                "label_ar": "برانديراب",
                "label_fr": "Branderup"
            },
            {
                "value": 61333,
                "label": "Glamsbjerg",
                "label_ar": "غلامسبيرغ",
                "label_fr": "Glamsbjerg"
            },
            {
                "value": 61489,
                "label": "Salten",
                "label_ar": "مملح",
                "label_fr": "Salten"
            },
            {
                "value": 61476,
                "label": "Baring",
                "label_ar": "تعرية",
                "label_fr": "Baring"
            },
            {
                "value": 61018,
                "label": "Fredensborg",
                "label_ar": "فريدنسبورج",
                "label_fr": "Fredensborg"
            },
            {
                "value": 61492,
                "label": "Vantinge",
                "label_ar": "فانتينجي",
                "label_fr": "Vantinge"
            },
            {
                "value": 61207,
                "label": "Horve",
                "label_ar": "هورفي",
                "label_fr": "Horve"
            },
            {
                "value": 61445,
                "label": "Soby",
                "label_ar": "سوبي",
                "label_fr": "Soby"
            },
            {
                "value": 61016,
                "label": "Ikast",
                "label_ar": "إيكاست",
                "label_fr": "Ikast"
            },
            {
                "value": 61346,
                "label": "Logstrup",
                "label_ar": "Logstrup",
                "label_fr": "Logstrup"
            },
            {
                "value": 61532,
                "label": "Bruunshab",
                "label_ar": "Bruunshab",
                "label_fr": "Bruunshab"
            },
            {
                "value": 61032,
                "label": "Kerteminde",
                "label_ar": "Kerteminde",
                "label_fr": "Kerteminde"
            },
            {
                "value": 61466,
                "label": "Sonder Stenderup",
                "label_ar": "Sonder Stenderup",
                "label_fr": "Sonder Stenderup"
            },
            {
                "value": 60991,
                "label": "Kongens Lyngby",
                "label_ar": "كونجينز لينجبي",
                "label_fr": "Kongens Lyngby"
            },
            {
                "value": 61170,
                "label": "Lille Skensved",
                "label_ar": "ليل سكينسفيد",
                "label_fr": "Lille Skensved"
            },
            {
                "value": 60942,
                "label": "Copenhagen",
                "label_ar": "كوبنهاغن",
                "label_fr": "Copenhague"
            },
            {
                "value": 61270,
                "label": "Daugard",
                "label_ar": "دوجارد",
                "label_fr": "Daugard"
            },
            {
                "value": 61331,
                "label": "Arup",
                "label_ar": "أروب",
                "label_fr": "Arup"
            },
            {
                "value": 61468,
                "label": "Hejls",
                "label_ar": "هجلس",
                "label_fr": "Hejls"
            },
            {
                "value": 61144,
                "label": "Aabybro",
                "label_ar": "ابيبرو",
                "label_fr": "Aabybro"
            },
            {
                "value": 61231,
                "label": "Randbol",
                "label_ar": "راندبول",
                "label_fr": "Randbol"
            },
            {
                "value": 61051,
                "label": "Horsens",
                "label_ar": "هورسنز",
                "label_fr": "Horsens"
            },
            {
                "value": 61323,
                "label": "Kibaek",
                "label_ar": "كيبيك",
                "label_fr": "Kibaek"
            },
            {
                "value": 61091,
                "label": "Marslet",
                "label_ar": "مارسليت",
                "label_fr": "Marslet"
            },
            {
                "value": 61374,
                "label": "Hanstholm",
                "label_ar": "هانستولم",
                "label_fr": "Hanstholm"
            },
            {
                "value": 61162,
                "label": "Terndrup",
                "label_ar": "تيرندروب",
                "label_fr": "Terndrup"
            },
            {
                "value": 60992,
                "label": "Hundested",
                "label_ar": "غزير",
                "label_fr": "Hundested"
            },
            {
                "value": 61452,
                "label": "Oddesund Syd",
                "label_ar": "أودسوند سيد",
                "label_fr": "Oddesund Syd"
            },
            {
                "value": 61236,
                "label": "Vorbasse",
                "label_ar": "فورباس",
                "label_fr": "Vorbasse"
            },
            {
                "value": 61098,
                "label": "Tureby",
                "label_ar": "توريبي",
                "label_fr": "Tureby"
            },
            {
                "value": 61203,
                "label": "Vivild",
                "label_ar": "فيفيلد",
                "label_fr": "Vivild"
            },
            {
                "value": 61318,
                "label": "Avlum",
                "label_ar": "أفلوم",
                "label_fr": "Avlum"
            },
            {
                "value": 61313,
                "label": "Pandrup",
                "label_ar": "باندروب",
                "label_fr": "Pandrup"
            },
            {
                "value": 61410,
                "label": "Alslev",
                "label_ar": "ألسليف",
                "label_fr": "Alslev"
            },
            {
                "value": 61036,
                "label": "Rudkøbing",
                "label_ar": "رودكبنغ",
                "label_fr": "Rudkøbing"
            },
            {
                "value": 61110,
                "label": "Gandrup",
                "label_ar": "جاندروب",
                "label_fr": "Gandrup"
            },
            {
                "value": 60943,
                "label": "Frederiksberg",
                "label_ar": "فريدريكسبيرج",
                "label_fr": "Frederiksberg"
            },
            {
                "value": 61438,
                "label": "Trustrup",
                "label_ar": "Trustrup",
                "label_fr": "Trustrup"
            },
            {
                "value": 61247,
                "label": "Baelum",
                "label_ar": "بايلوم",
                "label_fr": "Baelum"
            },
            {
                "value": 60951,
                "label": "Brande",
                "label_ar": "براندي",
                "label_fr": "Brande"
            },
            {
                "value": 60972,
                "label": "Soborg",
                "label_ar": "سوبورج",
                "label_fr": "Soborg"
            },
            {
                "value": 61305,
                "label": "Jerslev",
                "label_ar": "جيرسليف",
                "label_fr": "Jerslev"
            },
            {
                "value": 61479,
                "label": "Glesborg",
                "label_ar": "جليسبورج",
                "label_fr": "Glesborg"
            },
            {
                "value": 61191,
                "label": "Gadbjerg",
                "label_ar": "جادبيرج",
                "label_fr": "Gadbjerg"
            },
            {
                "value": 61455,
                "label": "Omme",
                "label_ar": "اوم",
                "label_fr": "Omme"
            },
            {
                "value": 61084,
                "label": "Graested",
                "label_ar": "متقدم",
                "label_fr": "Graested"
            },
            {
                "value": 61240,
                "label": "Rodding",
                "label_ar": "الرودينج",
                "label_fr": "Rodding"
            },
            {
                "value": 61157,
                "label": "Gistrup",
                "label_ar": "جيستروب",
                "label_fr": "Gistrup"
            },
            {
                "value": 61208,
                "label": "Regstrup",
                "label_ar": "ريجستروب",
                "label_fr": "Regstrup"
            },
            {
                "value": 61026,
                "label": "Solrod",
                "label_ar": "سولرود",
                "label_fr": "Solrod"
            },
            {
                "value": 61329,
                "label": "Vildbjerg",
                "label_ar": "فيلدبيرج",
                "label_fr": "Vildbjerg"
            },
            {
                "value": 61344,
                "label": "Frorup",
                "label_ar": "Frorup",
                "label_fr": "Frorup"
            },
            {
                "value": 61342,
                "label": "Gudhjem",
                "label_ar": "كودجيم",
                "label_fr": "Gudhjem"
            },
            {
                "value": 61042,
                "label": "Aabenraa",
                "label_ar": "أبينراء",
                "label_fr": "Aabenraa"
            },
            {
                "value": 60971,
                "label": "Haderslev",
                "label_ar": "هادرسليف",
                "label_fr": "Haderslev"
            },
            {
                "value": 61512,
                "label": "Tranekaer",
                "label_ar": "ترانكير",
                "label_fr": "Tranekaer"
            },
            {
                "value": 61139,
                "label": "Sakskøbing",
                "label_ar": "Sakskøbing",
                "label_fr": "Sakskøbing"
            },
            {
                "value": 61308,
                "label": "Gjol",
                "label_ar": "Gjol",
                "label_fr": "Gjol"
            },
            {
                "value": 61399,
                "label": "Ornhoj",
                "label_ar": "أورنهوج",
                "label_fr": "Ornhoj"
            },
            {
                "value": 61290,
                "label": "Kvistgard",
                "label_ar": "كفيستجارد",
                "label_fr": "Kvistgard"
            },
            {
                "value": 61275,
                "label": "Vodskov",
                "label_ar": "فودسكوف",
                "label_fr": "Vodskov"
            },
            {
                "value": 61353,
                "label": "Tinglev",
                "label_ar": "تينجليف",
                "label_fr": "Tinglev"
            },
            {
                "value": 61519,
                "label": "Bylderup-Bov",
                "label_ar": "بايلديروب بوف",
                "label_fr": "Bylderup-Bov"
            },
            {
                "value": 61104,
                "label": "Brønderslev",
                "label_ar": "بروندرسليف",
                "label_fr": "Brønderslev"
            },
            {
                "value": 61209,
                "label": "Jyderup",
                "label_ar": "جايدراب",
                "label_fr": "Jyderup"
            },
            {
                "value": 60983,
                "label": "Fensmark",
                "label_ar": "فينسمارك",
                "label_fr": "Fensmark"
            },
            {
                "value": 61060,
                "label": "Ejstrup",
                "label_ar": "إجستروب",
                "label_fr": "Ejstrup"
            },
            {
                "value": 61339,
                "label": "Morud",
                "label_ar": "مورود",
                "label_fr": "Morud"
            },
            {
                "value": 61336,
                "label": "Strib",
                "label_ar": "ستريب",
                "label_fr": "Strib"
            },
            {
                "value": 61039,
                "label": "Silkeborg",
                "label_ar": "سيلكبورج",
                "label_fr": "Silkeborg"
            },
            {
                "value": 61523,
                "label": "Janderup",
                "label_ar": "جانديروب",
                "label_fr": "Janderup"
            },
            {
                "value": 60975,
                "label": "Fakse",
                "label_ar": "وهمية",
                "label_fr": "Fakse"
            },
            {
                "value": 61480,
                "label": "Frederiksdal",
                "label_ar": "فريدريكسدال",
                "label_fr": "Frederiksdal"
            },
            {
                "value": 61246,
                "label": "Mariager",
                "label_ar": "ماريجر",
                "label_fr": "Mariager"
            },
            {
                "value": 61001,
                "label": "Lystrup",
                "label_ar": "شراب",
                "label_fr": "Lystrup"
            },
            {
                "value": 60967,
                "label": "Rødovre",
                "label_ar": "رودوفر",
                "label_fr": "Rødovre"
            },
            {
                "value": 61105,
                "label": "Frederikshavn",
                "label_ar": "فريدريكشافن",
                "label_fr": "Frederikshavn"
            },
            {
                "value": 61080,
                "label": "Bagsvaerd",
                "label_ar": "باجسفيرد",
                "label_fr": "Bagsvaerd"
            },
            {
                "value": 61149,
                "label": "Lasby",
                "label_ar": "لاسبي",
                "label_fr": "Lasby"
            },
            {
                "value": 61092,
                "label": "Hojbjerg",
                "label_ar": "هوجبيرج",
                "label_fr": "Hojbjerg"
            },
            {
                "value": 61315,
                "label": "Vekso",
                "label_ar": "فيكسو",
                "label_fr": "Vekso"
            },
            {
                "value": 61383,
                "label": "Skamby",
                "label_ar": "سكامبي",
                "label_fr": "Skamby"
            },
            {
                "value": 61176,
                "label": "Asnaes",
                "label_ar": "اسنايس",
                "label_fr": "Asnaes"
            },
            {
                "value": 61015,
                "label": "Glostrup",
                "label_ar": "جلوستروب",
                "label_fr": "Glostrup"
            },
            {
                "value": 61062,
                "label": "Brejning",
                "label_ar": "بريجنغ",
                "label_fr": "Brejning"
            },
            {
                "value": 61244,
                "label": "Ullerslev",
                "label_ar": "أولرسليف",
                "label_fr": "Ullerslev"
            },
            {
                "value": 61071,
                "label": "Thisted",
                "label_ar": "ثيستد",
                "label_fr": "Thisted"
            },
            {
                "value": 61178,
                "label": "Brabrand",
                "label_ar": "برابراند",
                "label_fr": "Brabrand"
            },
            {
                "value": 61359,
                "label": "Gørlev",
                "label_ar": "جورليف",
                "label_fr": "Gørlev"
            },
            {
                "value": 61024,
                "label": "Tune",
                "label_ar": "نغم",
                "label_fr": "Régler"
            },
            {
                "value": 61120,
                "label": "Farevejle",
                "label_ar": "فريفجل",
                "label_fr": "Farevejle"
            },
            {
                "value": 61542,
                "label": "Anholt",
                "label_ar": "أنهولت",
                "label_fr": "Anholt"
            },
            {
                "value": 61147,
                "label": "Dianalund",
                "label_ar": "ديانالوند",
                "label_fr": "Dianalund"
            },
            {
                "value": 61205,
                "label": "Norre Snede",
                "label_ar": "نوري سندي",
                "label_fr": "Norre Snede"
            },
            {
                "value": 61450,
                "label": "Borre",
                "label_ar": "بوري",
                "label_fr": "Borre"
            },
            {
                "value": 61271,
                "label": "Losning",
                "label_ar": "الخسارة",
                "label_fr": "Perdre"
            },
            {
                "value": 61007,
                "label": "Risskov",
                "label_ar": "ريسكوف",
                "label_fr": "Risskov"
            },
            {
                "value": 61321,
                "label": "Gudme",
                "label_ar": "جودمي",
                "label_fr": "Gudme"
            },
            {
                "value": 61325,
                "label": "Munkebo",
                "label_ar": "مونكبو",
                "label_fr": "Munkebo"
            },
            {
                "value": 61444,
                "label": "Allinge",
                "label_ar": "ألينج",
                "label_fr": "Allinge"
            },
            {
                "value": 61125,
                "label": "Knebel",
                "label_ar": "Knebel",
                "label_fr": "Knebel"
            },
            {
                "value": 61403,
                "label": "Toftlund",
                "label_ar": "توفتلوند",
                "label_fr": "Toftlund"
            },
            {
                "value": 61252,
                "label": "Ulstrup",
                "label_ar": "أولستروب",
                "label_fr": "Ulstrup"
            },
            {
                "value": 61087,
                "label": "Kalundborg",
                "label_ar": "كالوندبورغ",
                "label_fr": "Kalundborg"
            },
            {
                "value": 61161,
                "label": "Hobro",
                "label_ar": "هوبرو",
                "label_fr": "Hobro"
            },
            {
                "value": 61043,
                "label": "Ribe",
                "label_ar": "ريب",
                "label_fr": "Ribe"
            }
        ]
    },
    {
        "country": "Slovenia",
        "cities": [
            {
                "value": 61639,
                "label": "Slovenska Bistrica",
                "label_ar": "Slovenska Bistrica",
                "label_fr": "Slovenska Bistrica"
            },
            {
                "value": 61673,
                "label": "Grize",
                "label_ar": "أشيب",
                "label_fr": "Grize"
            },
            {
                "value": 61716,
                "label": "Starse",
                "label_ar": "نجم",
                "label_fr": "Starse"
            },
            {
                "value": 61569,
                "label": "Žirovnica",
                "label_ar": "سيروفنيكا",
                "label_fr": "Žirovnica"
            },
            {
                "value": 61567,
                "label": "Lesce",
                "label_ar": "ليسي",
                "label_fr": "Lesce"
            },
            {
                "value": 61649,
                "label": "Notranje Gorice",
                "label_ar": "Notranje Gorice",
                "label_fr": "Notranje Gorice"
            },
            {
                "value": 61698,
                "label": "Zgornja Polskava",
                "label_ar": "زجورنيا بولسكافا",
                "label_fr": "Zgornja Polskava"
            },
            {
                "value": 61576,
                "label": "Kočevje",
                "label_ar": "Kočevje",
                "label_fr": "Kočevje"
            },
            {
                "value": 61606,
                "label": "Koper",
                "label_ar": "كوبر",
                "label_fr": "Koper"
            },
            {
                "value": 61713,
                "label": "Preserje",
                "label_ar": "بريسيرجي",
                "label_fr": "Preserje"
            },
            {
                "value": 61682,
                "label": "Sentvid pri Sticni",
                "label_ar": "Sentvid pri Sticni",
                "label_fr": "Sentvid pri Sticni"
            },
            {
                "value": 61586,
                "label": "Gorisnica",
                "label_ar": "جوريسنيكا",
                "label_fr": "Gorisnica"
            },
            {
                "value": 61720,
                "label": "Cerklje ob Krki",
                "label_ar": "سيركلجي أوب كركي",
                "label_fr": "Cerklje ob Krki"
            },
            {
                "value": 61632,
                "label": "Dolenjske Toplice",
                "label_ar": "Dolenjske Toplice",
                "label_fr": "Dolenjske Toplice"
            },
            {
                "value": 61626,
                "label": "Hrusica",
                "label_ar": "هروسيكا",
                "label_fr": "Hrusica"
            },
            {
                "value": 61575,
                "label": "Mojstrana",
                "label_ar": "موجسترانا",
                "label_fr": "Mojstrana"
            },
            {
                "value": 61591,
                "label": "Naklo",
                "label_ar": "ناكلو",
                "label_fr": "Naklo"
            },
            {
                "value": 61564,
                "label": "Sencur",
                "label_ar": "سينكور",
                "label_fr": "Sencur"
            },
            {
                "value": 61628,
                "label": "Komenda",
                "label_ar": "كوميندا",
                "label_fr": "Komenda"
            },
            {
                "value": 61642,
                "label": "Polhov Gradec",
                "label_ar": "بولهوف جراديك",
                "label_fr": "Polhov Gradec"
            },
            {
                "value": 61704,
                "label": "Gmajnica",
                "label_ar": "جمانيكا",
                "label_fr": "Gmajnica"
            },
            {
                "value": 61707,
                "label": "Sempeter pri Gorici",
                "label_ar": "Sempeter pri Gorici",
                "label_fr": "Sempeter pri Gorici"
            },
            {
                "value": 61712,
                "label": "Spodnji Brnik",
                "label_ar": "سبودنجي برنيك",
                "label_fr": "Spodnji Brnik"
            },
            {
                "value": 61603,
                "label": "Stahovica",
                "label_ar": "ستاهوفيتسا",
                "label_fr": "Stahovica"
            },
            {
                "value": 61666,
                "label": "Podčetrtek",
                "label_ar": "بودسترتيك",
                "label_fr": "Podčetrtek"
            },
            {
                "value": 61645,
                "label": "Ajdovščina",
                "label_ar": "Ajdovščina",
                "label_fr": "Ajdovščina"
            },
            {
                "value": 61714,
                "label": "Trzic",
                "label_ar": "ترزيتش",
                "label_fr": "Trzic"
            },
            {
                "value": 61578,
                "label": "Sežana",
                "label_ar": "Sežana",
                "label_fr": "Sežana"
            },
            {
                "value": 61588,
                "label": "Lendava",
                "label_ar": "ليندافا",
                "label_fr": "Lendava"
            },
            {
                "value": 61677,
                "label": "Dekani",
                "label_ar": "ديكاني",
                "label_fr": "Dekani"
            },
            {
                "value": 61584,
                "label": "Gornja Radgona",
                "label_ar": "جورنجا رادجونا",
                "label_fr": "Gornja Radgona"
            },
            {
                "value": 61700,
                "label": "Rocinj",
                "label_ar": "روسينج",
                "label_fr": "Rocinj"
            },
            {
                "value": 61648,
                "label": "Litija",
                "label_ar": "Litija",
                "label_fr": "Litija"
            },
            {
                "value": 61662,
                "label": "Stara Cerkev",
                "label_ar": "ستارا سيركيف",
                "label_fr": "Stara Cerkev"
            },
            {
                "value": 61622,
                "label": "Globoko",
                "label_ar": "جلوبوكو",
                "label_fr": "Globoko"
            },
            {
                "value": 61631,
                "label": "Krško",
                "label_ar": "كرسكو",
                "label_fr": "Krško"
            },
            {
                "value": 61706,
                "label": "Sostanj",
                "label_ar": "سوستانج",
                "label_fr": "Sostanj"
            },
            {
                "value": 61676,
                "label": "Knezak",
                "label_ar": "كنيزاك",
                "label_fr": "Knezak"
            },
            {
                "value": 61592,
                "label": "Breginj",
                "label_ar": "برجينج",
                "label_fr": "Breginj"
            },
            {
                "value": 61629,
                "label": "Šentjernej",
                "label_ar": "Šentjernej",
                "label_fr": "Šentjernej"
            },
            {
                "value": 61710,
                "label": "Gorenja Vas",
                "label_ar": "جورنجا فاس",
                "label_fr": "Gorenja Vas"
            },
            {
                "value": 61610,
                "label": "Pernica",
                "label_ar": "بيرنيكا",
                "label_fr": "Pernica"
            },
            {
                "value": 61616,
                "label": "Trbovlje",
                "label_ar": "تربوفلي",
                "label_fr": "Trbovlje"
            },
            {
                "value": 61620,
                "label": "Prestranek",
                "label_ar": "بريسترانك",
                "label_fr": "Prestranek"
            },
            {
                "value": 61693,
                "label": "Smarje",
                "label_ar": "سمارجي",
                "label_fr": "Smarje"
            },
            {
                "value": 61621,
                "label": "Horjul",
                "label_ar": "هورجول",
                "label_fr": "Horjul"
            },
            {
                "value": 61719,
                "label": "Cersak",
                "label_ar": "سيرساك",
                "label_fr": "Cersak"
            },
            {
                "value": 61565,
                "label": "Bevke",
                "label_ar": "بيفك",
                "label_fr": "Bevke"
            },
            {
                "value": 61670,
                "label": "Sevnica",
                "label_ar": "سيفنيكا",
                "label_fr": "Sevnica"
            },
            {
                "value": 61612,
                "label": "Domžale",
                "label_ar": "Domžale",
                "label_fr": "Domžale"
            },
            {
                "value": 61605,
                "label": "Kranjska Gora",
                "label_ar": "كرانجسكا جورا",
                "label_fr": "Kranjska Gora"
            },
            {
                "value": 61722,
                "label": "Nova Cerkev",
                "label_ar": "نوفا سيركيف",
                "label_fr": "Nova Cerkev"
            },
            {
                "value": 61624,
                "label": "Izola",
                "label_ar": "إيزولا",
                "label_fr": "Izola"
            },
            {
                "value": 61686,
                "label": "Postojna",
                "label_ar": "بوستوينا",
                "label_fr": "Postojna"
            },
            {
                "value": 61672,
                "label": "Zelezniki",
                "label_ar": "زيليزنيكي",
                "label_fr": "Zelezniki"
            },
            {
                "value": 61656,
                "label": "Zgornje Skofije",
                "label_ar": "Zgornje Skofije",
                "label_fr": "Zgornje Skofije"
            },
            {
                "value": 61669,
                "label": "Trzin",
                "label_ar": "ترزين",
                "label_fr": "Trzin"
            },
            {
                "value": 61664,
                "label": "Ankaran",
                "label_ar": "أنكاران",
                "label_fr": "Ankaran"
            },
            {
                "value": 61574,
                "label": "Crensovci",
                "label_ar": "كرينزوفسي",
                "label_fr": "Crensovci"
            },
            {
                "value": 61659,
                "label": "Kobarid",
                "label_ar": "كوباريد",
                "label_fr": "Kobarid"
            },
            {
                "value": 61691,
                "label": "Lovrenc na Pohorju",
                "label_ar": "لوفرينك نا بوهورجو",
                "label_fr": "Lovrenc na Pohorju"
            },
            {
                "value": 61654,
                "label": "Ljubecna",
                "label_ar": "ليوبيكنا",
                "label_fr": "Ljubecna"
            },
            {
                "value": 61683,
                "label": "Borovnica",
                "label_ar": "بوروفنيتشا",
                "label_fr": "Borovnica"
            },
            {
                "value": 61708,
                "label": "Rakek",
                "label_ar": "رقيق",
                "label_fr": "Rakek"
            },
            {
                "value": 61690,
                "label": "Zagorje ob Savi",
                "label_ar": "Zagorje ob Savi",
                "label_fr": "Zagorje ob Savi"
            },
            {
                "value": 61587,
                "label": "Markovci",
                "label_ar": "ماركوفسي",
                "label_fr": "Markovci"
            },
            {
                "value": 61658,
                "label": "Divača",
                "label_ar": "ديفاتا",
                "label_fr": "Divača"
            },
            {
                "value": 61678,
                "label": "Moravce",
                "label_ar": "مورافسي",
                "label_fr": "Moravce"
            },
            {
                "value": 61721,
                "label": "Spodnje Hoce",
                "label_ar": "سبودني هوس",
                "label_fr": "Spodnje Hoce"
            },
            {
                "value": 61644,
                "label": "Pobegi",
                "label_ar": "بوبجي",
                "label_fr": "Pobegi"
            },
            {
                "value": 61627,
                "label": "Kidricevo",
                "label_ar": "Kidricevo",
                "label_fr": "Kidricevo"
            },
            {
                "value": 61692,
                "label": "Dravograd",
                "label_ar": "درافوغراد",
                "label_fr": "Dravograd"
            },
            {
                "value": 61590,
                "label": "Sredisce ob Dravi",
                "label_ar": "سريدس أوب درافي",
                "label_fr": "Sredisce ob Dravi"
            },
            {
                "value": 61653,
                "label": "Vojnik",
                "label_ar": "فوينيك",
                "label_fr": "Vojnik"
            },
            {
                "value": 61594,
                "label": "Novo Mesto",
                "label_ar": "نوفو ميستو",
                "label_fr": "Novo Mesto"
            },
            {
                "value": 61681,
                "label": "Brežice",
                "label_ar": "برايس",
                "label_fr": "Brežice"
            },
            {
                "value": 61611,
                "label": "Krajna",
                "label_ar": "كراجنا",
                "label_fr": "Krajna"
            },
            {
                "value": 61637,
                "label": "Miklavz na Dravskem Polju",
                "label_ar": "Miklavz na Dravskem Polju",
                "label_fr": "Miklavz na Dravskem Polju"
            },
            {
                "value": 61689,
                "label": "Hrastnik",
                "label_ar": "هراستنيك",
                "label_fr": "Hrastnik"
            },
            {
                "value": 61675,
                "label": "Mirna",
                "label_ar": "ميرنا",
                "label_fr": "Mirna"
            },
            {
                "value": 61619,
                "label": "Logatec",
                "label_ar": "لوجاتيك",
                "label_fr": "Logatec"
            },
            {
                "value": 61668,
                "label": "Ravne",
                "label_ar": "رافني",
                "label_fr": "Ravne"
            },
            {
                "value": 61633,
                "label": "Pesnica pri Mariboru",
                "label_ar": "Pesnica pri Mariboru",
                "label_fr": "Pesnica pri Mariboru"
            },
            {
                "value": 61602,
                "label": "Slovenj Gradec",
                "label_ar": "سلوفينج جراديك",
                "label_fr": "Slovenj Gradec"
            },
            {
                "value": 61660,
                "label": "Polzela",
                "label_ar": "بولزيلا",
                "label_fr": "Polzela"
            },
            {
                "value": 61585,
                "label": "Ptuj",
                "label_ar": "بتوج",
                "label_fr": "Ptuj"
            },
            {
                "value": 61718,
                "label": "Secovlje",
                "label_ar": "سيكوفلي",
                "label_fr": "Secovlje"
            },
            {
                "value": 61560,
                "label": "Ljubljana",
                "label_ar": "ليوبليانا",
                "label_fr": "Ljubljana"
            },
            {
                "value": 61593,
                "label": "Laško",
                "label_ar": "لاشكو",
                "label_fr": "Laško"
            },
            {
                "value": 61589,
                "label": "Beltinci",
                "label_ar": "بيلتينشي",
                "label_fr": "Beltinci"
            },
            {
                "value": 61609,
                "label": "Cirkovce",
                "label_ar": "سيركوفيتشي",
                "label_fr": "Cirkovce"
            },
            {
                "value": 61572,
                "label": "Jesenice",
                "label_ar": "جيسينيس",
                "label_fr": "Jesenice"
            },
            {
                "value": 61727,
                "label": "Miren",
                "label_ar": "ميرين",
                "label_fr": "Miren"
            },
            {
                "value": 61638,
                "label": "Spodnji Duplek",
                "label_ar": "سبودنجي دوبليك",
                "label_fr": "Spodnji Duplek"
            },
            {
                "value": 61599,
                "label": "Rogaška Slatina",
                "label_ar": "روغاسكا سلاتينا",
                "label_fr": "Rogaška Slatina"
            },
            {
                "value": 61650,
                "label": "Smlednik",
                "label_ar": "Smlednik",
                "label_fr": "Smlednik"
            },
            {
                "value": 61570,
                "label": "Prebold",
                "label_ar": "بريبولد",
                "label_fr": "Prebold"
            },
            {
                "value": 61697,
                "label": "Podbocje",
                "label_ar": "بودبوكجي",
                "label_fr": "Podbocje"
            },
            {
                "value": 61634,
                "label": "Poljcane",
                "label_ar": "Poljcane",
                "label_fr": "Poljcane"
            },
            {
                "value": 61674,
                "label": "Verzej",
                "label_ar": "Verzej",
                "label_fr": "Verzej"
            },
            {
                "value": 61580,
                "label": "Cerknica",
                "label_ar": "سيركنيكا",
                "label_fr": "Cerknica"
            },
            {
                "value": 61688,
                "label": "Slovenske Konjice",
                "label_ar": "Slovenske Konjice",
                "label_fr": "Slovenske Konjice"
            },
            {
                "value": 61630,
                "label": "Medvode",
                "label_ar": "Medvode",
                "label_fr": "Medvode"
            },
            {
                "value": 61699,
                "label": "Puconci",
                "label_ar": "بوكونشي",
                "label_fr": "Puconci"
            },
            {
                "value": 61715,
                "label": "Ziri",
                "label_ar": "زيري",
                "label_fr": "Ziri"
            },
            {
                "value": 61655,
                "label": "Škofja Loka",
                "label_ar": "شكوفجا لوكا",
                "label_fr": "Škofja Loka"
            },
            {
                "value": 61600,
                "label": "Mozirje",
                "label_ar": "موزيرجي",
                "label_fr": "Mozirje"
            },
            {
                "value": 61583,
                "label": "Kranj",
                "label_ar": "كرانج",
                "label_fr": "Kranj"
            },
            {
                "value": 61635,
                "label": "Ruše",
                "label_ar": "حيلة",
                "label_fr": "Ruse"
            },
            {
                "value": 61607,
                "label": "Breznica",
                "label_ar": "بريزنيكا",
                "label_fr": "Breznica"
            },
            {
                "value": 61657,
                "label": "Skofljica",
                "label_ar": "سكوفلجيكا",
                "label_fr": "Skofljica"
            },
            {
                "value": 61724,
                "label": "Zidani Most",
                "label_ar": "زيداني موست",
                "label_fr": "Zidani Most"
            },
            {
                "value": 61566,
                "label": "Kamnik",
                "label_ar": "كامنيك",
                "label_fr": "Kamnik"
            },
            {
                "value": 61623,
                "label": "Stari Trg pri Lozu",
                "label_ar": "ستاري ترغ بري لوزو",
                "label_fr": "Stari Trg pri Lozu"
            },
            {
                "value": 61679,
                "label": "Lenart v Slov. Goricah",
                "label_ar": "لينارت ضد سلوف. جوريكا",
                "label_fr": "Lenart contre Slov. Goricah"
            },
            {
                "value": 61703,
                "label": "Idrija",
                "label_ar": "إدريجا",
                "label_fr": "Idrija"
            },
            {
                "value": 61725,
                "label": "Petrovce",
                "label_ar": "بيتروفس",
                "label_fr": "Petrovce"
            },
            {
                "value": 61685,
                "label": "Smarje-Sap",
                "label_ar": "سمارج ساب",
                "label_fr": "Smarje-Sap"
            },
            {
                "value": 61636,
                "label": "Lovrenc na Dravskem Polju",
                "label_ar": "لوفرينك نا درافسكيم بوليجو",
                "label_fr": "Lovrenc na Dravskem Polju"
            },
            {
                "value": 61640,
                "label": "Žalec",
                "label_ar": "Žalec",
                "label_fr": "Žalec"
            },
            {
                "value": 61723,
                "label": "Sentjanz",
                "label_ar": "سينتجانز",
                "label_fr": "Sentjanz"
            },
            {
                "value": 61582,
                "label": "Zgornje Gorje",
                "label_ar": "زجورني جورجي",
                "label_fr": "Zgornje Gorje"
            },
            {
                "value": 61559,
                "label": "Velenje",
                "label_ar": "فيلينجي",
                "label_fr": "Velenje"
            },
            {
                "value": 61663,
                "label": "Kojsko",
                "label_ar": "كوجسكو",
                "label_fr": "Kojsko"
            },
            {
                "value": 61562,
                "label": "Radovljica",
                "label_ar": "رادوفليكا",
                "label_fr": "Radovljica"
            },
            {
                "value": 61573,
                "label": "Bohinjska Bela",
                "label_ar": "Bohinjska بيلا",
                "label_fr": "Bohinjska Bela"
            },
            {
                "value": 61696,
                "label": "Radlje ob Dravi",
                "label_ar": "Radlje ob Dravi",
                "label_fr": "Radlje ob Dravi"
            },
            {
                "value": 61595,
                "label": "Velika Loka",
                "label_ar": "فيليكا لوكا",
                "label_fr": "Velika Loka"
            },
            {
                "value": 61726,
                "label": "Dobrunje",
                "label_ar": "Dobrunje",
                "label_fr": "Dobrunje"
            },
            {
                "value": 61625,
                "label": "Spodnji Ivanjci",
                "label_ar": "سبودنجي إيفانجسي",
                "label_fr": "Spodnji Ivanjci"
            },
            {
                "value": 61651,
                "label": "Hotedrsica",
                "label_ar": "هوتدرسكا",
                "label_fr": "Hotedrsica"
            },
            {
                "value": 61702,
                "label": "Vodice",
                "label_ar": "الفوديس",
                "label_fr": "Vodice"
            },
            {
                "value": 61581,
                "label": "Ig",
                "label_ar": "إيغ",
                "label_fr": "Ig"
            },
            {
                "value": 61694,
                "label": "Selnica ob Dravi",
                "label_ar": "Selnica ob Dravi",
                "label_fr": "Selnica ob Dravi"
            },
            {
                "value": 61684,
                "label": "Besnica",
                "label_ar": "بيسنيكا",
                "label_fr": "Besnica"
            },
            {
                "value": 61701,
                "label": "Dobravlje",
                "label_ar": "دوبرافلي",
                "label_fr": "Dobravlje"
            },
            {
                "value": 61705,
                "label": "Ravne na Koroskem",
                "label_ar": "Ravne na Koroskem",
                "label_fr": "Ravne na Koroskem"
            },
            {
                "value": 61687,
                "label": "Smarje pri Jelsah",
                "label_ar": "Smarje pri Jelsah",
                "label_fr": "Smarje pri Jelsah"
            },
            {
                "value": 61646,
                "label": "Radomlje",
                "label_ar": "رادوملي",
                "label_fr": "Radomlje"
            },
            {
                "value": 61561,
                "label": "Smartno ob Paki",
                "label_ar": "سمارتنو أوب باكي",
                "label_fr": "Smartno ob Paki"
            },
            {
                "value": 61563,
                "label": "Begunje na Gorenjskem",
                "label_ar": "Begunje na Gorenjskem",
                "label_fr": "Begunje na Gorenjskem"
            },
            {
                "value": 61643,
                "label": "Videm",
                "label_ar": "فيديم",
                "label_fr": "Videm"
            },
            {
                "value": 61597,
                "label": "Prevalje",
                "label_ar": "بريفالي",
                "label_fr": "Prevalje"
            },
            {
                "value": 61680,
                "label": "Limbus",
                "label_ar": "ليمبوس",
                "label_fr": "Limbus"
            },
            {
                "value": 61568,
                "label": "Bled",
                "label_ar": "بليد",
                "label_fr": "Bled"
            },
            {
                "value": 61604,
                "label": "Murska Sobota",
                "label_ar": "مورسكا سوبوتا",
                "label_fr": "Murska Sobota"
            },
            {
                "value": 61601,
                "label": "Mezica",
                "label_ar": "ميزيكا",
                "label_fr": "Mezica"
            },
            {
                "value": 61661,
                "label": "Menges",
                "label_ar": "مينجز",
                "label_fr": "Menges"
            },
            {
                "value": 61613,
                "label": "Dob",
                "label_ar": "دوب",
                "label_fr": "Dob"
            },
            {
                "value": 61695,
                "label": "Bistrica pri Rusah",
                "label_ar": "بسترتشا بري روساه",
                "label_fr": "Bistrica pri Rusah"
            },
            {
                "value": 61614,
                "label": "Dobrova",
                "label_ar": "دوبروفا",
                "label_fr": "Dobrova"
            },
            {
                "value": 61617,
                "label": "Ilirska Bistrica",
                "label_ar": "إليرسكا بيستريتسا",
                "label_fr": "Ilirska Bistrica"
            },
            {
                "value": 61608,
                "label": "Maribor",
                "label_ar": "ماريبور",
                "label_fr": "Maribor"
            },
            {
                "value": 61577,
                "label": "Piran",
                "label_ar": "بيران",
                "label_fr": "Piran"
            },
            {
                "value": 61618,
                "label": "Vrhnika",
                "label_ar": "فرنيكا",
                "label_fr": "Vrhnika"
            },
            {
                "value": 61579,
                "label": "Nova Gorica",
                "label_ar": "نوفا جوريكا",
                "label_fr": "Nova Gorica"
            },
            {
                "value": 61711,
                "label": "Cemsenik",
                "label_ar": "سيمسينيك",
                "label_fr": "Cemsenik"
            },
            {
                "value": 61709,
                "label": "Portorož",
                "label_ar": "بورتوروز",
                "label_fr": "Portorož"
            },
            {
                "value": 61647,
                "label": "Vipava",
                "label_ar": "فيبافا",
                "label_fr": "Vipava"
            },
            {
                "value": 61671,
                "label": "Brestanica",
                "label_ar": "بريستانيكا",
                "label_fr": "Brestanica"
            },
            {
                "value": 61665,
                "label": "Tolmin",
                "label_ar": "تولمين",
                "label_fr": "Tolmin"
            },
            {
                "value": 61652,
                "label": "Dornava",
                "label_ar": "دورنافا",
                "label_fr": "Dornava"
            },
            {
                "value": 61641,
                "label": "Dol pri Ljubljani",
                "label_ar": "Dol pri Ljubljani",
                "label_fr": "Dol pri Ljubljani"
            },
            {
                "value": 61667,
                "label": "Log pri Brezovici",
                "label_ar": "سجل بري بريزوفيتشي",
                "label_fr": "Journal pri Brezovici"
            },
            {
                "value": 61598,
                "label": "Šentjur pri Celju",
                "label_ar": "Šentjur pri Celju",
                "label_fr": "Šentjur pri Celju"
            },
            {
                "value": 61717,
                "label": "Lokev",
                "label_ar": "لوكيف",
                "label_fr": "Lokev"
            },
            {
                "value": 61571,
                "label": "Tisina",
                "label_ar": "تيسينا",
                "label_fr": "Tisina"
            },
            {
                "value": 61615,
                "label": "Grosuplje",
                "label_ar": "جروسوبلج",
                "label_fr": "Grosuplje"
            },
            {
                "value": 61596,
                "label": "Zgornje Hoce",
                "label_ar": "زجورني هوس",
                "label_fr": "Zgornje Hoce"
            }
        ]
    },
    {
        "country": "Iran",
        "cities": [
            {
                "value": 61762,
                "label": "Baladeh",
                "label_ar": "Baladeh",
                "label_fr": "Baladeh"
            },
            {
                "value": 61950,
                "label": "Bukan",
                "label_ar": "بوكان",
                "label_fr": "Bukan"
            },
            {
                "value": 61796,
                "label": "Kashan",
                "label_ar": "كاشان",
                "label_fr": "Kashan"
            },
            {
                "value": 61801,
                "label": "Mehin",
                "label_ar": "مهين",
                "label_fr": "Mehin"
            },
            {
                "value": 61765,
                "label": "Behjan",
                "label_ar": "بهجان",
                "label_fr": "Behjan"
            },
            {
                "value": 61803,
                "label": "Golpayegan",
                "label_ar": "جولبايجان",
                "label_fr": "Golpayegan"
            },
            {
                "value": 61785,
                "label": "Peyk",
                "label_ar": "بيك",
                "label_fr": "Peyk"
            },
            {
                "value": 61905,
                "label": "Khowrasgan",
                "label_ar": "خوراسجان",
                "label_fr": "Khowrasgan"
            },
            {
                "value": 61744,
                "label": "Hamadan",
                "label_ar": "همدان",
                "label_fr": "Hamadan"
            },
            {
                "value": 61816,
                "label": "Behshahr",
                "label_ar": "بهشهر",
                "label_fr": "Behshahr"
            },
            {
                "value": 61959,
                "label": "Ravansar",
                "label_ar": "رافانسار",
                "label_fr": "Ravansar"
            },
            {
                "value": 61955,
                "label": "Astara",
                "label_ar": "أستارا",
                "label_fr": "Astara"
            },
            {
                "value": 61791,
                "label": "Borran-e Bala",
                "label_ar": "Borran-e Bala",
                "label_fr": "Borran-e Bala"
            },
            {
                "value": 61885,
                "label": "Fasa",
                "label_ar": "فسا",
                "label_fr": "Fasa"
            },
            {
                "value": 61836,
                "label": "Razan",
                "label_ar": "رزان",
                "label_fr": "Razan"
            },
            {
                "value": 61735,
                "label": "Birjand",
                "label_ar": "بيرجند",
                "label_fr": "Birjand"
            },
            {
                "value": 61833,
                "label": "Ravar",
                "label_ar": "رافار",
                "label_fr": "Ravar"
            },
            {
                "value": 61835,
                "label": "Damavand",
                "label_ar": "دماوند",
                "label_fr": "Damavand"
            },
            {
                "value": 61861,
                "label": "Chapar",
                "label_ar": "شابار",
                "label_fr": "Chapar"
            },
            {
                "value": 61862,
                "label": "Shahid Madani",
                "label_ar": "شهيد مدني",
                "label_fr": "Shahid Madani"
            },
            {
                "value": 61784,
                "label": "Dezful",
                "label_ar": "دزفول",
                "label_fr": "Dezful"
            },
            {
                "value": 61843,
                "label": "Fardis",
                "label_ar": "فارديس",
                "label_fr": "Fardis"
            },
            {
                "value": 61932,
                "label": "Rayen",
                "label_ar": "راين",
                "label_fr": "Rayen"
            },
            {
                "value": 61737,
                "label": "Farsi",
                "label_ar": "الفارسية",
                "label_fr": "Farsi"
            },
            {
                "value": 61888,
                "label": "Mahestan",
                "label_ar": "ماهستان",
                "label_fr": "Mahestan"
            },
            {
                "value": 61804,
                "label": "Bandar-e Mahshahr",
                "label_ar": "بندر ماهشهر",
                "label_fr": "Bandar-e Mahshahr"
            },
            {
                "value": 61738,
                "label": "Tiran",
                "label_ar": "تيران",
                "label_fr": "Tiran"
            },
            {
                "value": 61749,
                "label": "Zanjan",
                "label_ar": "زنجان",
                "label_fr": "Zanjan"
            },
            {
                "value": 61904,
                "label": "Lamerd",
                "label_ar": "لاميرد",
                "label_fr": "Lamerd"
            },
            {
                "value": 61802,
                "label": "Bakhtiaruyeh",
                "label_ar": "بختياروية",
                "label_fr": "Bakhtiaruyeh"
            },
            {
                "value": 61742,
                "label": "Yasuj",
                "label_ar": "ياسوج",
                "label_fr": "Yasuj"
            },
            {
                "value": 61900,
                "label": "Mehregan",
                "label_ar": "مهرجان",
                "label_fr": "Mehregan"
            },
            {
                "value": 61858,
                "label": "Sabzevar",
                "label_ar": "Sabzevar",
                "label_fr": "Sabzevar"
            },
            {
                "value": 61855,
                "label": "Hadishahr",
                "label_ar": "هاديشهر",
                "label_fr": "Hadishahr"
            },
            {
                "value": 61913,
                "label": "Evin",
                "label_ar": "إيفين",
                "label_fr": "Evin"
            },
            {
                "value": 61817,
                "label": "Esfarayen",
                "label_ar": "اسفراين",
                "label_fr": "Esfarayen"
            },
            {
                "value": 61960,
                "label": "Shahid Chamran",
                "label_ar": "شهيد جمران",
                "label_fr": "Shahid Chamran"
            },
            {
                "value": 61838,
                "label": "Homa",
                "label_ar": "حوما",
                "label_fr": "Homa"
            },
            {
                "value": 61828,
                "label": "Zangiabad",
                "label_ar": "Zangiabad",
                "label_fr": "Zangiabad"
            },
            {
                "value": 61761,
                "label": "Khong",
                "label_ar": "خونغ",
                "label_fr": "Khong"
            },
            {
                "value": 61790,
                "label": "Taha",
                "label_ar": "طه",
                "label_fr": "Taha"
            },
            {
                "value": 61954,
                "label": "`Aliabad-e Aq Hesar",
                "label_ar": "علي آباد آق حصار",
                "label_fr": "ʻAliabad-e Aq Hesar"
            },
            {
                "value": 61780,
                "label": "Payam",
                "label_ar": "بايام",
                "label_fr": "Payam"
            },
            {
                "value": 61751,
                "label": "Pasargad",
                "label_ar": "باسارجاد",
                "label_fr": "Pasargad"
            },
            {
                "value": 61753,
                "label": "Sabz",
                "label_ar": "سابز",
                "label_fr": "Sabz"
            },
            {
                "value": 61728,
                "label": "Karaj",
                "label_ar": "كرج",
                "label_fr": "Karaj"
            },
            {
                "value": 61771,
                "label": "Bakhtiari",
                "label_ar": "بختياري",
                "label_fr": "Bakhtiari"
            },
            {
                "value": 61770,
                "label": "Ilam",
                "label_ar": "إيلام",
                "label_fr": "Ilam"
            },
            {
                "value": 61851,
                "label": "Bardsir",
                "label_ar": "بردسير",
                "label_fr": "Bardsir"
            },
            {
                "value": 61860,
                "label": "Kordovan-e Sofla",
                "label_ar": "كوردوفان سوفلا",
                "label_fr": "Kordovan-e Sofla"
            },
            {
                "value": 61786,
                "label": "Abadan",
                "label_ar": "عبادان",
                "label_fr": "Abadan"
            },
            {
                "value": 61809,
                "label": "Gilas",
                "label_ar": "جيلاس",
                "label_fr": "Gilas"
            },
            {
                "value": 61870,
                "label": "Darmian",
                "label_ar": "دارميان",
                "label_fr": "Darmian"
            },
            {
                "value": 61935,
                "label": "Rabor",
                "label_ar": "رابر",
                "label_fr": "Rabor"
            },
            {
                "value": 61778,
                "label": "Ashna",
                "label_ar": "أشنا",
                "label_fr": "Ashna"
            },
            {
                "value": 61841,
                "label": "Arsanjan",
                "label_ar": "ارسنجان",
                "label_fr": "Arsanjan"
            },
            {
                "value": 61923,
                "label": "Zahra",
                "label_ar": "زهرة",
                "label_fr": "Zahra"
            },
            {
                "value": 61787,
                "label": "Samen",
                "label_ar": "سامين",
                "label_fr": "Samen"
            },
            {
                "value": 61865,
                "label": "Manjil",
                "label_ar": "مانجيل",
                "label_fr": "Manjil"
            },
            {
                "value": 61884,
                "label": "Shahrak-e Direh",
                "label_ar": "شهراك ديرة",
                "label_fr": "Shahrak-e Direh"
            },
            {
                "value": 61898,
                "label": "Naji",
                "label_ar": "ناجي",
                "label_fr": "Naji"
            },
            {
                "value": 61880,
                "label": "Ashan",
                "label_ar": "عشان",
                "label_fr": "Ashan"
            },
            {
                "value": 61949,
                "label": "Mahabad",
                "label_ar": "مهاباد",
                "label_fr": "Mahabad"
            },
            {
                "value": 61783,
                "label": "Tonekabon",
                "label_ar": "تونكابون",
                "label_fr": "Tonekabon"
            },
            {
                "value": 61927,
                "label": "Khomeyn",
                "label_ar": "خمين",
                "label_fr": "Khomeyn"
            },
            {
                "value": 61741,
                "label": "Sirjan",
                "label_ar": "سيرجان",
                "label_fr": "Sirjan"
            },
            {
                "value": 61768,
                "label": "Gostar",
                "label_ar": "جوستار",
                "label_fr": "Gostar"
            },
            {
                "value": 61752,
                "label": "Sena",
                "label_ar": "سينا",
                "label_fr": "Sena"
            },
            {
                "value": 61957,
                "label": "Asadabad",
                "label_ar": "أسد أباد",
                "label_fr": "Asadabad"
            },
            {
                "value": 61946,
                "label": "Mianej",
                "label_ar": "ميانيج",
                "label_fr": "Mianej"
            },
            {
                "value": 61777,
                "label": "`Oryan",
                "label_ar": "ʻ أوريان",
                "label_fr": "ʻOryan"
            },
            {
                "value": 61830,
                "label": "Rigan",
                "label_ar": "ريجان",
                "label_fr": "Rigan"
            },
            {
                "value": 61915,
                "label": "Shadman",
                "label_ar": "شادمان",
                "label_fr": "Shadman"
            },
            {
                "value": 61810,
                "label": "Urmia",
                "label_ar": "أورمية",
                "label_fr": "Urmia"
            },
            {
                "value": 61939,
                "label": "Estahban",
                "label_ar": "استهبان",
                "label_fr": "Estahban"
            },
            {
                "value": 61781,
                "label": "Baseri Hadi",
                "label_ar": "بصري هادي",
                "label_fr": "Baseri Hadi"
            },
            {
                "value": 61799,
                "label": "Vaj",
                "label_ar": "فاج",
                "label_fr": "Vaj"
            },
            {
                "value": 61911,
                "label": "Behdasht",
                "label_ar": "بهداشت",
                "label_fr": "Behdasht"
            },
            {
                "value": 61740,
                "label": "Kerman",
                "label_ar": "كرمان",
                "label_fr": "Kerman"
            },
            {
                "value": 61829,
                "label": "Kord Kandi",
                "label_ar": "كورد كاندي",
                "label_fr": "Kord Kandi"
            },
            {
                "value": 61823,
                "label": "Rasak",
                "label_ar": "رساك",
                "label_fr": "Rasak"
            },
            {
                "value": 61842,
                "label": "Karimkhan",
                "label_ar": "كريمخان",
                "label_fr": "Karimkhan"
            },
            {
                "value": 61815,
                "label": "Neka",
                "label_ar": "نيكا",
                "label_fr": "Neka"
            },
            {
                "value": 61729,
                "label": "Golestan",
                "label_ar": "جولستان",
                "label_fr": "Golestan"
            },
            {
                "value": 61837,
                "label": "Nahavand",
                "label_ar": "نهاوند",
                "label_fr": "Nahavand"
            },
            {
                "value": 61914,
                "label": "Nasir",
                "label_ar": "ناصر",
                "label_fr": "Nasir"
            },
            {
                "value": 61943,
                "label": "Marand",
                "label_ar": "ماراند",
                "label_fr": "Marand"
            },
            {
                "value": 61773,
                "label": "Gilan-e Gharb",
                "label_ar": "جيلان الغرب",
                "label_fr": "Gilan-e Gharb"
            },
            {
                "value": 61795,
                "label": "Hirad",
                "label_ar": "هيراد",
                "label_fr": "Hirad"
            },
            {
                "value": 61782,
                "label": "Mohr",
                "label_ar": "موهر",
                "label_fr": "Mohr"
            },
            {
                "value": 61958,
                "label": "Dorud",
                "label_ar": "درود",
                "label_fr": "Dorud"
            },
            {
                "value": 61877,
                "label": "Band",
                "label_ar": "فرقة",
                "label_fr": "Bande"
            },
            {
                "value": 61891,
                "label": "Kaman",
                "label_ar": "كامان",
                "label_fr": "Kaman"
            },
            {
                "value": 61876,
                "label": "Razi",
                "label_ar": "الرازي",
                "label_fr": "Razi"
            },
            {
                "value": 61916,
                "label": "Khvajeh",
                "label_ar": "خفاجة",
                "label_fr": "Khvajeh"
            },
            {
                "value": 61743,
                "label": "Kermanshah",
                "label_ar": "كرمانشاه",
                "label_fr": "Kermanshah"
            },
            {
                "value": 61902,
                "label": "Birizg",
                "label_ar": "بريز",
                "label_fr": "Birizg"
            },
            {
                "value": 61848,
                "label": "Hormozabad",
                "label_ar": "هرمز آباد",
                "label_fr": "Hormozabad"
            },
            {
                "value": 61822,
                "label": "Iranshahr",
                "label_ar": "إيرانشهر",
                "label_fr": "Iranshahr"
            },
            {
                "value": 61779,
                "label": "Shahrak",
                "label_ar": "شهراك",
                "label_fr": "Shahrak"
            },
            {
                "value": 61907,
                "label": "Novin",
                "label_ar": "نوفين",
                "label_fr": "Novin"
            },
            {
                "value": 61747,
                "label": "Azaran",
                "label_ar": "أزران",
                "label_fr": "Azaran"
            },
            {
                "value": 61849,
                "label": "Baft",
                "label_ar": "بافت",
                "label_fr": "Baft"
            },
            {
                "value": 61769,
                "label": "Yazd",
                "label_ar": "يزد",
                "label_fr": "Yazd"
            },
            {
                "value": 61760,
                "label": "Shiraz",
                "label_ar": "شيراز",
                "label_fr": "Shiraz"
            },
            {
                "value": 61831,
                "label": "Jiroft",
                "label_ar": "جيروفت",
                "label_fr": "Jiroft"
            },
            {
                "value": 61850,
                "label": "Onar",
                "label_ar": "أونار",
                "label_fr": "Onar"
            },
            {
                "value": 61863,
                "label": "Khameneh",
                "label_ar": "خامنه",
                "label_fr": "Khameneh"
            },
            {
                "value": 61936,
                "label": "`Aliabad",
                "label_ar": "علي اباد",
                "label_fr": "ʻAliabad"
            },
            {
                "value": 61776,
                "label": "Shahrud",
                "label_ar": "شهرود",
                "label_fr": "Shahrud"
            },
            {
                "value": 61793,
                "label": "Najafabad",
                "label_ar": "نجف اباد",
                "label_fr": "Najafabad"
            },
            {
                "value": 61881,
                "label": "Dadeh",
                "label_ar": "ديده",
                "label_fr": "Dadeh"
            },
            {
                "value": 61919,
                "label": "Eslamshahr",
                "label_ar": "اسلام شهر",
                "label_fr": "Eslamshahr"
            },
            {
                "value": 61895,
                "label": "Jadir",
                "label_ar": "جدير",
                "label_fr": "Jadir"
            },
            {
                "value": 61953,
                "label": "Germi",
                "label_ar": "جيرمي",
                "label_fr": "Germi"
            },
            {
                "value": 61745,
                "label": "Kordestan-e `Olya",
                "label_ar": "كردستان عوليا",
                "label_fr": "Kordestan-e ʻOlya"
            },
            {
                "value": 61856,
                "label": "Sahand",
                "label_ar": "سهند",
                "label_fr": "Sahand"
            },
            {
                "value": 61800,
                "label": "Piruz",
                "label_ar": "بيروز",
                "label_fr": "Piruz"
            },
            {
                "value": 61938,
                "label": "Neyriz",
                "label_ar": "نيريز",
                "label_fr": "Neyriz"
            },
            {
                "value": 61824,
                "label": "Khash",
                "label_ar": "خاش",
                "label_fr": "Khash"
            },
            {
                "value": 61894,
                "label": "Ravand",
                "label_ar": "رافاند",
                "label_fr": "Ravand"
            },
            {
                "value": 61731,
                "label": "Khorasan",
                "label_ar": "خراسان",
                "label_fr": "Khorasan"
            },
            {
                "value": 61756,
                "label": "Rasht",
                "label_ar": "رشت",
                "label_fr": "Rasht"
            },
            {
                "value": 61896,
                "label": "Jahrom",
                "label_ar": "جاهروم",
                "label_fr": "Jahrom"
            },
            {
                "value": 61806,
                "label": "Zahedan",
                "label_ar": "زاهدان",
                "label_fr": "Zahedan"
            },
            {
                "value": 61825,
                "label": "Falavarjan",
                "label_ar": "فلافارجان",
                "label_fr": "Falavarjan"
            },
            {
                "value": 61934,
                "label": "Ardakan",
                "label_ar": "أردكان",
                "label_fr": "Ardakan"
            },
            {
                "value": 61930,
                "label": "Khomeyni Shahr",
                "label_ar": "الخميني شهر",
                "label_fr": "Khomeyni Shahr"
            },
            {
                "value": 61864,
                "label": "Khalkhal",
                "label_ar": "خلخال",
                "label_fr": "Khalkhal"
            },
            {
                "value": 61920,
                "label": "Saveh",
                "label_ar": "سافه",
                "label_fr": "Saveh"
            },
            {
                "value": 61730,
                "label": "Mashhad",
                "label_ar": "مشهد",
                "label_fr": "Mashhad"
            },
            {
                "value": 61758,
                "label": "Isfahan",
                "label_ar": "اصفهان",
                "label_fr": "Ispahan"
            },
            {
                "value": 61918,
                "label": "Bostan",
                "label_ar": "البستان",
                "label_fr": "Bostan"
            },
            {
                "value": 61961,
                "label": "Kabir",
                "label_ar": "كبير",
                "label_fr": "Kabir"
            },
            {
                "value": 61764,
                "label": "Hasanabad-e Qadamgah",
                "label_ar": "حسن أباد القدامة",
                "label_fr": "Hasanabad-e Qadamgah"
            },
            {
                "value": 61811,
                "label": "Lahijan",
                "label_ar": "لاهيجان",
                "label_fr": "Lahijan"
            },
            {
                "value": 61908,
                "label": "Shahreza",
                "label_ar": "شهرزاه",
                "label_fr": "Shahreza"
            },
            {
                "value": 61879,
                "label": "Behbahan",
                "label_ar": "بهبهان",
                "label_fr": "Behbahan"
            },
            {
                "value": 61798,
                "label": "Torkan",
                "label_ar": "توركان",
                "label_fr": "Torkan"
            },
            {
                "value": 61921,
                "label": "Mahallat",
                "label_ar": "محلات",
                "label_fr": "Mahallat"
            },
            {
                "value": 61903,
                "label": "Gerash",
                "label_ar": "جرش",
                "label_fr": "Gerash"
            },
            {
                "value": 61956,
                "label": "Amlash",
                "label_ar": "أملاش",
                "label_fr": "Amlash"
            },
            {
                "value": 61736,
                "label": "Qazvin",
                "label_ar": "قزوين",
                "label_fr": "Qazvin"
            },
            {
                "value": 61873,
                "label": "Babol",
                "label_ar": "بابول",
                "label_fr": "Babol"
            },
            {
                "value": 61772,
                "label": "Gharbi",
                "label_ar": "الغربي",
                "label_fr": "Gharbi"
            },
            {
                "value": 61890,
                "label": "Mobarakeh",
                "label_ar": "مباركة",
                "label_fr": "Mobarakeh"
            },
            {
                "value": 61948,
                "label": "Khvoy",
                "label_ar": "خفوي",
                "label_fr": "Khvoy"
            },
            {
                "value": 61814,
                "label": "Kelarabad",
                "label_ar": "كيلاراباد",
                "label_fr": "Kelarabad"
            },
            {
                "value": 61925,
                "label": "Damghan",
                "label_ar": "دامغان",
                "label_fr": "Damghan"
            },
            {
                "value": 61868,
                "label": "Shirvan",
                "label_ar": "شيرفان",
                "label_fr": "Shirvan"
            },
            {
                "value": 61847,
                "label": "Vandar",
                "label_ar": "فاندار",
                "label_fr": "Vandar"
            },
            {
                "value": 61874,
                "label": "Mamasani",
                "label_ar": "ماماساني",
                "label_fr": "Mamasani"
            },
            {
                "value": 61910,
                "label": "Jahan Nama",
                "label_ar": "جهان ناما",
                "label_fr": "Jahan Nama"
            },
            {
                "value": 61819,
                "label": "Chabahar",
                "label_ar": "تشابهار",
                "label_fr": "Chabahar"
            },
            {
                "value": 61924,
                "label": "Garmsar",
                "label_ar": "جارمسار",
                "label_fr": "Garmsar"
            },
            {
                "value": 61893,
                "label": "Bidak",
                "label_ar": "بيدك",
                "label_fr": "Bidak"
            },
            {
                "value": 61857,
                "label": "Soltanabad",
                "label_ar": "سلطان اباد",
                "label_fr": "Soltanabad"
            },
            {
                "value": 61807,
                "label": "Arak",
                "label_ar": "اراك",
                "label_fr": "Arak"
            },
            {
                "value": 61788,
                "label": "Shahriar",
                "label_ar": "شهريار",
                "label_fr": "Shahriar"
            },
            {
                "value": 61882,
                "label": "Saman",
                "label_ar": "سمعان",
                "label_fr": "Saman"
            },
            {
                "value": 61942,
                "label": "Maragheh",
                "label_ar": "مراغة",
                "label_fr": "Maragheh"
            },
            {
                "value": 61922,
                "label": "Takestan",
                "label_ar": "تاكيستان",
                "label_fr": "Takestan"
            },
            {
                "value": 61859,
                "label": "Gorgan",
                "label_ar": "جرجان",
                "label_fr": "Gorgan"
            },
            {
                "value": 61767,
                "label": "Peshgoman",
                "label_ar": "بشغومان",
                "label_fr": "Peshgoman"
            },
            {
                "value": 61940,
                "label": "Fordu",
                "label_ar": "فوردو",
                "label_fr": "Fordu"
            },
            {
                "value": 61853,
                "label": "Siahkal",
                "label_ar": "سيحكال",
                "label_fr": "Siahkal"
            },
            {
                "value": 61763,
                "label": "Barbun",
                "label_ar": "باربون",
                "label_fr": "Barbun"
            },
            {
                "value": 61792,
                "label": "Arnan",
                "label_ar": "ارنان",
                "label_fr": "Arnan"
            },
            {
                "value": 61813,
                "label": "Parpis",
                "label_ar": "باربيس",
                "label_fr": "Parpis"
            },
            {
                "value": 61883,
                "label": "Sari",
                "label_ar": "ساري",
                "label_fr": "Sari"
            },
            {
                "value": 61812,
                "label": "Shahr",
                "label_ar": "شهر",
                "label_fr": "Shahr"
            },
            {
                "value": 61834,
                "label": "Varamin",
                "label_ar": "فارامين",
                "label_fr": "Varamin"
            },
            {
                "value": 61775,
                "label": "Sharif",
                "label_ar": "شريف",
                "label_fr": "Sharif"
            },
            {
                "value": 61820,
                "label": "Zabol",
                "label_ar": "زابول",
                "label_fr": "Zabol"
            },
            {
                "value": 61754,
                "label": "Baharan",
                "label_ar": "بحران",
                "label_fr": "Baharan"
            },
            {
                "value": 61951,
                "label": "Meshgin Shahr",
                "label_ar": "مشكين شهر",
                "label_fr": "Meshgin Shahr"
            },
            {
                "value": 61840,
                "label": "Amol",
                "label_ar": "امول",
                "label_fr": "Amol"
            },
            {
                "value": 61867,
                "label": "Salmas",
                "label_ar": "سلمس",
                "label_fr": "Salmas"
            },
            {
                "value": 61805,
                "label": "Sanandaj",
                "label_ar": "سنندج",
                "label_fr": "Sanandaj"
            },
            {
                "value": 61774,
                "label": "Gazan-e Bala",
                "label_ar": "غزة بالا",
                "label_fr": "Gazan-e Bala"
            },
            {
                "value": 61757,
                "label": "Rafsanjan",
                "label_ar": "رفسنجان",
                "label_fr": "Rafsanjan"
            },
            {
                "value": 61887,
                "label": "Qasr od Dasht",
                "label_ar": "قصر الدشت",
                "label_fr": "Qasr od Dasht"
            },
            {
                "value": 61912,
                "label": "Azadi",
                "label_ar": "آزادي",
                "label_fr": "Azadi"
            },
            {
                "value": 61808,
                "label": "Bastak",
                "label_ar": "البستك",
                "label_fr": "Bastak"
            },
            {
                "value": 61866,
                "label": "Hashtpar",
                "label_ar": "هاشتبر",
                "label_fr": "Hashtpar"
            },
            {
                "value": 61739,
                "label": "Bandar Būshehr",
                "label_ar": "بندر بوشهر",
                "label_fr": "Bandar Būshehr"
            },
            {
                "value": 61892,
                "label": "Kish",
                "label_ar": "كيش",
                "label_fr": "Kish"
            },
            {
                "value": 61937,
                "label": "Zarand",
                "label_ar": "زارند",
                "label_fr": "Zarand"
            },
            {
                "value": 61929,
                "label": "Semirom",
                "label_ar": "سميروم",
                "label_fr": "Semirom"
            },
            {
                "value": 61872,
                "label": "Quchan",
                "label_ar": "قوتشان",
                "label_fr": "Quchan"
            },
            {
                "value": 61748,
                "label": "Ardabil",
                "label_ar": "أردبيل",
                "label_fr": "Ardabil"
            },
            {
                "value": 61732,
                "label": "Razaviyeh",
                "label_ar": "رضوية",
                "label_fr": "Razaviyeh"
            },
            {
                "value": 61875,
                "label": "Malayer",
                "label_ar": "ملاير",
                "label_fr": "Malayer"
            },
            {
                "value": 61931,
                "label": "Valilu",
                "label_ar": "فاليلو",
                "label_fr": "Valilu"
            },
            {
                "value": 61750,
                "label": "Shahid",
                "label_ar": "شهيد",
                "label_fr": "Shahid"
            },
            {
                "value": 61759,
                "label": "Ahvaz",
                "label_ar": "الأهواز",
                "label_fr": "Ahvaz"
            },
            {
                "value": 61818,
                "label": "Abol",
                "label_ar": "أبول",
                "label_fr": "Abol"
            },
            {
                "value": 61755,
                "label": "Negar",
                "label_ar": "نيجار",
                "label_fr": "Negar"
            },
            {
                "value": 61901,
                "label": "Khowrshid",
                "label_ar": "خورشيد",
                "label_fr": "Khowrshid"
            },
            {
                "value": 61766,
                "label": "Shahrak-e Pars",
                "label_ar": "شهراك بارس",
                "label_fr": "Shahrak-e Pars"
            },
            {
                "value": 61869,
                "label": "Shahrak-e Milad",
                "label_ar": "شهراك ميلاد",
                "label_fr": "Shahrak-e Milad"
            },
            {
                "value": 61941,
                "label": "Servan",
                "label_ar": "سرفان",
                "label_fr": "Servan"
            },
            {
                "value": 61854,
                "label": "Miandoab",
                "label_ar": "مياندواب",
                "label_fr": "Miandoab"
            },
            {
                "value": 61928,
                "label": "Ashtian",
                "label_ar": "أشتيان",
                "label_fr": "Ashtien"
            },
            {
                "value": 61821,
                "label": "Saravan",
                "label_ar": "سارافان",
                "label_fr": "Saravan"
            },
            {
                "value": 61844,
                "label": "Parsian",
                "label_ar": "بارسيان",
                "label_fr": "Parsian"
            },
            {
                "value": 61734,
                "label": "Sistan-e `Olya",
                "label_ar": "سيستان عوليا",
                "label_fr": "Sistan-e ʻOlya"
            },
            {
                "value": 61794,
                "label": "Golriz",
                "label_ar": "جولريز",
                "label_fr": "Golriz"
            },
            {
                "value": 61889,
                "label": "Jahan",
                "label_ar": "جهان",
                "label_fr": "Jahan"
            },
            {
                "value": 61926,
                "label": "Shazand",
                "label_ar": "شازند",
                "label_fr": "Shazand"
            },
            {
                "value": 61746,
                "label": "Tabriz",
                "label_ar": "تبريز",
                "label_fr": "Tabriz"
            },
            {
                "value": 61845,
                "label": "Emam",
                "label_ar": "إمام",
                "label_fr": "Emam"
            },
            {
                "value": 61733,
                "label": "Semnan",
                "label_ar": "سمنان",
                "label_fr": "Semnan"
            },
            {
                "value": 61789,
                "label": "Azad",
                "label_ar": "آزاد",
                "label_fr": "Azad"
            },
            {
                "value": 61945,
                "label": "Malekan",
                "label_ar": "ملكان",
                "label_fr": "Malekan"
            },
            {
                "value": 61846,
                "label": "Neyshabur",
                "label_ar": "نيشابور",
                "label_fr": "Neyshabur"
            },
            {
                "value": 61852,
                "label": "Qeshm",
                "label_ar": "قشم",
                "label_fr": "Qeshm"
            },
            {
                "value": 61947,
                "label": "Julfa",
                "label_ar": "جلفا",
                "label_fr": "Julfa"
            },
            {
                "value": 61839,
                "label": "Hashtgerd",
                "label_ar": "Hashtgerd",
                "label_fr": "Hashtgerd"
            },
            {
                "value": 61827,
                "label": "Baghin",
                "label_ar": "باغين",
                "label_fr": "Baghin"
            },
            {
                "value": 61899,
                "label": "Hush",
                "label_ar": "صه",
                "label_fr": "Silence"
            },
            {
                "value": 61933,
                "label": "Meybod",
                "label_ar": "ميبود",
                "label_fr": "Meybod"
            },
            {
                "value": 61906,
                "label": "Kian",
                "label_ar": "كيان",
                "label_fr": "Kian"
            },
            {
                "value": 61944,
                "label": "Bonab",
                "label_ar": "بوناب",
                "label_fr": "Bonab"
            },
            {
                "value": 61797,
                "label": "Banak",
                "label_ar": "باناك",
                "label_fr": "Banak"
            },
            {
                "value": 61909,
                "label": "Idah",
                "label_ar": "ايدا",
                "label_fr": "Idah"
            },
            {
                "value": 61886,
                "label": "Wahna",
                "label_ar": "واهنا",
                "label_fr": "Wahna"
            },
            {
                "value": 61952,
                "label": "Moghan",
                "label_ar": "موغان",
                "label_fr": "Moghan"
            },
            {
                "value": 61871,
                "label": "Nehbandan",
                "label_ar": "نيهبندان",
                "label_fr": "Nehbandan"
            },
            {
                "value": 61832,
                "label": "Bandar-e Khamir",
                "label_ar": "بندر خمير",
                "label_fr": "Bandar-e Khamir"
            },
            {
                "value": 61897,
                "label": "Post",
                "label_ar": "بريد",
                "label_fr": "Publier"
            },
            {
                "value": 61917,
                "label": "Malek Talesh",
                "label_ar": "مالك طاليش",
                "label_fr": "Malek Talesh"
            },
            {
                "value": 61878,
                "label": "Badr",
                "label_ar": "بدر",
                "label_fr": "Badr"
            },
            {
                "value": 61826,
                "label": "Chah Deraz",
                "label_ar": "شاه دراز",
                "label_fr": "Chah Deraz"
            }
        ]
    },
    {
        "country": "Norway",
        "cities": [
            {
                "value": 61983,
                "label": "Hammerfest",
                "label_ar": "هامرفست",
                "label_fr": "Hammerfest"
            },
            {
                "value": 62065,
                "label": "Gran",
                "label_ar": "غران",
                "label_fr": "Gran"
            },
            {
                "value": 62781,
                "label": "Bleikvasslia",
                "label_ar": "بليكفاسليا",
                "label_fr": "Bleikvasslia"
            },
            {
                "value": 62672,
                "label": "Selva",
                "label_ar": "سيلفا",
                "label_fr": "Selva"
            },
            {
                "value": 62443,
                "label": "Onarheim",
                "label_ar": "أونارهايم",
                "label_fr": "Onarheim"
            },
            {
                "value": 62636,
                "label": "Skogn",
                "label_ar": "سكوجن",
                "label_fr": "Skogn"
            },
            {
                "value": 62327,
                "label": "Suldalsosen",
                "label_ar": "Suldalsosen",
                "label_fr": "Suldalsosen"
            },
            {
                "value": 62487,
                "label": "Vikersund",
                "label_ar": "فيكرسوند",
                "label_fr": "Vikersund"
            },
            {
                "value": 62717,
                "label": "Tynset",
                "label_ar": "تاينست",
                "label_fr": "Tynset"
            },
            {
                "value": 62486,
                "label": "Geithus",
                "label_ar": "جيثوس",
                "label_fr": "Geithus"
            },
            {
                "value": 61964,
                "label": "Moss",
                "label_ar": "طحلب",
                "label_fr": "Mousse"
            },
            {
                "value": 62063,
                "label": "Ottestad",
                "label_ar": "أوتيستاد",
                "label_fr": "Ottestad"
            },
            {
                "value": 62886,
                "label": "Fresvik",
                "label_ar": "فريسفيك",
                "label_fr": "Fresvik"
            },
            {
                "value": 62145,
                "label": "Tennevoll",
                "label_ar": "تينيفول",
                "label_fr": "Tennevoll"
            },
            {
                "value": 62696,
                "label": "Bekkjarvik",
                "label_ar": "بيكجارفيك",
                "label_fr": "Bekkjarvik"
            },
            {
                "value": 62659,
                "label": "Sorvagen",
                "label_ar": "سورفاجين",
                "label_fr": "Sorvagen"
            },
            {
                "value": 62371,
                "label": "Sandvoll",
                "label_ar": "ساندفول",
                "label_fr": "Sandvoll"
            },
            {
                "value": 62378,
                "label": "Saebovik",
                "label_ar": "سايبوفيك",
                "label_fr": "Saebovik"
            },
            {
                "value": 62172,
                "label": "Flekke",
                "label_ar": "فليك",
                "label_fr": "Flekke"
            },
            {
                "value": 62260,
                "label": "Kolnes",
                "label_ar": "كولنيس",
                "label_fr": "Kolnes"
            },
            {
                "value": 62163,
                "label": "As",
                "label_ar": "مثل",
                "label_fr": "Comme"
            },
            {
                "value": 62211,
                "label": "Valer",
                "label_ar": "فالير",
                "label_fr": "Valer"
            },
            {
                "value": 62109,
                "label": "Indre Arna",
                "label_ar": "إندري أرنا",
                "label_fr": "Indre Arna"
            },
            {
                "value": 62394,
                "label": "Bud",
                "label_ar": "برعم",
                "label_fr": "Bourgeon"
            },
            {
                "value": 62382,
                "label": "Hemsedal",
                "label_ar": "حمصيدال",
                "label_fr": "Hemsedal"
            },
            {
                "value": 62101,
                "label": "Gol",
                "label_ar": "جول",
                "label_fr": "Gol"
            },
            {
                "value": 62120,
                "label": "Stryn",
                "label_ar": "سترين",
                "label_fr": "Stryn"
            },
            {
                "value": 62136,
                "label": "Lyngseidet",
                "label_ar": "لينجسيديت",
                "label_fr": "Lyngseidet"
            },
            {
                "value": 62201,
                "label": "Kolbu",
                "label_ar": "كولبو",
                "label_fr": "Kolbu"
            },
            {
                "value": 62397,
                "label": "Sandtorg",
                "label_ar": "ساندتورج",
                "label_fr": "Sandtorg"
            },
            {
                "value": 62380,
                "label": "Hvittingfoss",
                "label_ar": "Hvittingfoss",
                "label_fr": "Hvittingfoss"
            },
            {
                "value": 62815,
                "label": "Bangsund",
                "label_ar": "بانجسوند",
                "label_fr": "Bangsund"
            },
            {
                "value": 62074,
                "label": "Sorum",
                "label_ar": "سوروم",
                "label_fr": "Sorum"
            },
            {
                "value": 62356,
                "label": "Odnes",
                "label_ar": "أودنيس",
                "label_fr": "Odnes"
            },
            {
                "value": 62552,
                "label": "Glesvaer",
                "label_ar": "جليسفاير",
                "label_fr": "Glesvaer"
            },
            {
                "value": 62451,
                "label": "Ana-Sira",
                "label_ar": "أنا سيرا",
                "label_fr": "Ana-Sira"
            },
            {
                "value": 62867,
                "label": "Hole",
                "label_ar": "الفجوة",
                "label_fr": "Trou"
            },
            {
                "value": 62806,
                "label": "Nygardsjoen",
                "label_ar": "Nygardsjoen",
                "label_fr": "Nygardsjoen"
            },
            {
                "value": 62436,
                "label": "Mehamn",
                "label_ar": "ميهامن",
                "label_fr": "Mehamn"
            },
            {
                "value": 62884,
                "label": "Hellandsjoen",
                "label_ar": "هيلاندسوين",
                "label_fr": "Hellandsjoen"
            },
            {
                "value": 62678,
                "label": "Ask",
                "label_ar": "يطلب",
                "label_fr": "Demander"
            },
            {
                "value": 62338,
                "label": "Søgne",
                "label_ar": "سوجني",
                "label_fr": "Søgne"
            },
            {
                "value": 62462,
                "label": "Stamsund",
                "label_ar": "ستامسوند",
                "label_fr": "Stamsund"
            },
            {
                "value": 62138,
                "label": "Gargan",
                "label_ar": "جارجان",
                "label_fr": "Gargan"
            },
            {
                "value": 62093,
                "label": "Raufoss",
                "label_ar": "روفوس",
                "label_fr": "Raufoss"
            },
            {
                "value": 62851,
                "label": "Valer",
                "label_ar": "فالير",
                "label_fr": "Valer"
            },
            {
                "value": 62460,
                "label": "Kabelvag",
                "label_ar": "كابيلفاج",
                "label_fr": "Kabelvag"
            },
            {
                "value": 62809,
                "label": "Brekke",
                "label_ar": "بريكي",
                "label_fr": "Brekke"
            },
            {
                "value": 62642,
                "label": "Sem",
                "label_ar": "سيم",
                "label_fr": "Sem"
            },
            {
                "value": 62757,
                "label": "Rauland",
                "label_ar": "راؤول",
                "label_fr": "Rauland"
            },
            {
                "value": 62082,
                "label": "Nesoddtangen",
                "label_ar": "نيسودتانجين",
                "label_fr": "Nesoddtangen"
            },
            {
                "value": 62567,
                "label": "Ramnes",
                "label_ar": "رامنز",
                "label_fr": "Ramnes"
            },
            {
                "value": 62843,
                "label": "Hebnes",
                "label_ar": "هيبنس",
                "label_fr": "Hebnes"
            },
            {
                "value": 62104,
                "label": "Levanger",
                "label_ar": "ليفانجر",
                "label_fr": "Levanger"
            },
            {
                "value": 62709,
                "label": "Re",
                "label_ar": "إعادة",
                "label_fr": "Ré"
            },
            {
                "value": 62653,
                "label": "Kleppstad",
                "label_ar": "كليبستاد",
                "label_fr": "Kleppstad"
            },
            {
                "value": 62444,
                "label": "Skanevik",
                "label_ar": "سكانيفيك",
                "label_fr": "Skanevik"
            },
            {
                "value": 62042,
                "label": "Baerums verk",
                "label_ar": "بيرومس فير",
                "label_fr": "Baerums verk"
            },
            {
                "value": 62384,
                "label": "Fjellstrand",
                "label_ar": "فيلستراند",
                "label_fr": "Fjellstrand"
            },
            {
                "value": 62670,
                "label": "Treungen",
                "label_ar": "Treungen",
                "label_fr": "Treungen"
            },
            {
                "value": 62317,
                "label": "Oveland",
                "label_ar": "أوفلاند",
                "label_fr": "Oveland"
            },
            {
                "value": 62586,
                "label": "Tafjord",
                "label_ar": "تافجورد",
                "label_fr": "Tafjord"
            },
            {
                "value": 62767,
                "label": "Gjerstad",
                "label_ar": "غيرشتاد",
                "label_fr": "Gjerstad"
            },
            {
                "value": 62531,
                "label": "Rodberg",
                "label_ar": "رودبرغ",
                "label_fr": "Rodberg"
            },
            {
                "value": 62635,
                "label": "Skogn",
                "label_ar": "سكوجن",
                "label_fr": "Skogn"
            },
            {
                "value": 62030,
                "label": "Moelv",
                "label_ar": "مولف",
                "label_fr": "Moelv"
            },
            {
                "value": 62557,
                "label": "Meraker",
                "label_ar": "ميراكر",
                "label_fr": "Meraker"
            },
            {
                "value": 62434,
                "label": "Langfjordbotn",
                "label_ar": "لانجفجوردبوتن",
                "label_fr": "Langfjordbotn"
            },
            {
                "value": 62600,
                "label": "Kolvereid",
                "label_ar": "كولفيريد",
                "label_fr": "Kolvereid"
            },
            {
                "value": 62477,
                "label": "Lunde",
                "label_ar": "لوند",
                "label_fr": "Lunde"
            },
            {
                "value": 61989,
                "label": "Raholt",
                "label_ar": "راهولت",
                "label_fr": "Raholt"
            },
            {
                "value": 62357,
                "label": "Minnesund",
                "label_ar": "مينيسوند",
                "label_fr": "Minnesund"
            },
            {
                "value": 62706,
                "label": "Storforshei",
                "label_ar": "ستورفورشي",
                "label_fr": "Storforshei"
            },
            {
                "value": 62802,
                "label": "Svartskog",
                "label_ar": "سفارتسكوج",
                "label_fr": "Svartskog"
            },
            {
                "value": 62804,
                "label": "Sand",
                "label_ar": "الرمل",
                "label_fr": "Le sable"
            },
            {
                "value": 62762,
                "label": "Eidsa",
                "label_ar": "العيدة",
                "label_fr": "Eidsa"
            },
            {
                "value": 62614,
                "label": "Oma",
                "label_ar": "اوما",
                "label_fr": "Oma"
            },
            {
                "value": 62845,
                "label": "Aker",
                "label_ar": "عكر",
                "label_fr": "Aker"
            },
            {
                "value": 62795,
                "label": "Helleland",
                "label_ar": "هيللاند",
                "label_fr": "Helleland"
            },
            {
                "value": 62873,
                "label": "Mestervik",
                "label_ar": "مسترفيك",
                "label_fr": "Mestervik"
            },
            {
                "value": 62673,
                "label": "Sandshamn",
                "label_ar": "ساندسهامن",
                "label_fr": "Sandshamn"
            },
            {
                "value": 61979,
                "label": "Nesbru",
                "label_ar": "نسبرو",
                "label_fr": "Nesbru"
            },
            {
                "value": 62805,
                "label": "Fonnes",
                "label_ar": "فونيس",
                "label_fr": "Fonnes"
            },
            {
                "value": 62637,
                "label": "Aure",
                "label_ar": "اوري",
                "label_fr": "Aure"
            },
            {
                "value": 62249,
                "label": "Eidsberg",
                "label_ar": "إيدسبيرغ",
                "label_fr": "Eidsberg"
            },
            {
                "value": 62648,
                "label": "Saeveland",
                "label_ar": "سيفيلاند",
                "label_fr": "Saeveland"
            },
            {
                "value": 62127,
                "label": "Deknepollen",
                "label_ar": "ديكنبولين",
                "label_fr": "Deknepollen"
            },
            {
                "value": 62375,
                "label": "Valestrandfossen",
                "label_ar": "فالستراندفوسن",
                "label_fr": "Valestrandfossen"
            },
            {
                "value": 62602,
                "label": "Namsskogan",
                "label_ar": "نامسكوجان",
                "label_fr": "Namsskogan"
            },
            {
                "value": 62119,
                "label": "Selje",
                "label_ar": "سيلجي",
                "label_fr": "Selje"
            },
            {
                "value": 62679,
                "label": "Sletta",
                "label_ar": "سليتا",
                "label_fr": "Sletta"
            },
            {
                "value": 62535,
                "label": "Sistranda",
                "label_ar": "سيستراندا",
                "label_fr": "Sistranda"
            },
            {
                "value": 62367,
                "label": "Grua",
                "label_ar": "غروا",
                "label_fr": "Grua"
            },
            {
                "value": 62827,
                "label": "Vale",
                "label_ar": "فالي",
                "label_fr": "Vallée"
            },
            {
                "value": 62869,
                "label": "Reitan",
                "label_ar": "ريتان",
                "label_fr": "Reitan"
            },
            {
                "value": 62713,
                "label": "Ringsaker",
                "label_ar": "رينغساكر",
                "label_fr": "Ringsaker"
            },
            {
                "value": 62429,
                "label": "Grendi",
                "label_ar": "Grendi",
                "label_fr": "Grendi"
            },
            {
                "value": 62610,
                "label": "Lofthus",
                "label_ar": "لوفثوس",
                "label_fr": "Lofthus"
            },
            {
                "value": 62739,
                "label": "Barstadvik",
                "label_ar": "بارستادفيك",
                "label_fr": "Barstadvik"
            },
            {
                "value": 62819,
                "label": "Rodoy",
                "label_ar": "رودوي",
                "label_fr": "Rodoy"
            },
            {
                "value": 62792,
                "label": "Miland",
                "label_ar": "ميلاند",
                "label_fr": "Miland"
            },
            {
                "value": 62181,
                "label": "Mandal",
                "label_ar": "ماندال",
                "label_fr": "Mandal"
            },
            {
                "value": 62849,
                "label": "Ringstad",
                "label_ar": "رينجستاد",
                "label_fr": "Ringstad"
            },
            {
                "value": 62430,
                "label": "Hagan",
                "label_ar": "هاجان",
                "label_fr": "Hagan"
            },
            {
                "value": 62593,
                "label": "Veggli",
                "label_ar": "نباتي",
                "label_fr": "Veggli"
            },
            {
                "value": 62269,
                "label": "Algard",
                "label_ar": "الجارد",
                "label_fr": "Algard"
            },
            {
                "value": 62499,
                "label": "Lokken Verk",
                "label_ar": "لوكين فيرك",
                "label_fr": "Lokken Verk"
            },
            {
                "value": 62875,
                "label": "Adland",
                "label_ar": "أدلاند",
                "label_fr": "Adland"
            },
            {
                "value": 62428,
                "label": "Vegarshei",
                "label_ar": "فيغارشي",
                "label_fr": "Vegarshei"
            },
            {
                "value": 62498,
                "label": "Torod",
                "label_ar": "تورود",
                "label_fr": "Torod"
            },
            {
                "value": 62546,
                "label": "Knarrevik",
                "label_ar": "كناريفيك",
                "label_fr": "Knarrevik"
            },
            {
                "value": 62441,
                "label": "Hovden",
                "label_ar": "هوفدن",
                "label_fr": "Hovden"
            },
            {
                "value": 62012,
                "label": "Fosnavag",
                "label_ar": "فوسنافاغ",
                "label_fr": "Fosnavag"
            },
            {
                "value": 62683,
                "label": "Bronnoysund",
                "label_ar": "برونويسوند",
                "label_fr": "Bronnoysund"
            },
            {
                "value": 62689,
                "label": "Eidsdal",
                "label_ar": "عيدسدال",
                "label_fr": "Eidsdal"
            },
            {
                "value": 62388,
                "label": "Vinstra",
                "label_ar": "فينسترا",
                "label_fr": "Vinstra"
            },
            {
                "value": 62601,
                "label": "Brattholmen",
                "label_ar": "Brattholmen",
                "label_fr": "Brattholmen"
            },
            {
                "value": 62002,
                "label": "Oppegard",
                "label_ar": "أوبيجارد",
                "label_fr": "Oppegard"
            },
            {
                "value": 62205,
                "label": "Gaupen",
                "label_ar": "جوبين",
                "label_fr": "Gaupen"
            },
            {
                "value": 62184,
                "label": "Movik",
                "label_ar": "موفيك",
                "label_fr": "Movik"
            },
            {
                "value": 62838,
                "label": "Lundamo",
                "label_ar": "لوندامو",
                "label_fr": "Lundamo"
            },
            {
                "value": 62268,
                "label": "Tananger",
                "label_ar": "تانانجر",
                "label_fr": "Tananger"
            },
            {
                "value": 62697,
                "label": "Austbo",
                "label_ar": "أوستبو",
                "label_fr": "Austbo"
            },
            {
                "value": 62687,
                "label": "Helgeland",
                "label_ar": "هيلجيلاند",
                "label_fr": "Helgeland"
            },
            {
                "value": 62005,
                "label": "Salhus",
                "label_ar": "سالهوس",
                "label_fr": "Salhus"
            },
            {
                "value": 62853,
                "label": "Borregard",
                "label_ar": "بوريجارد",
                "label_fr": "Borregard"
            },
            {
                "value": 62454,
                "label": "Flateby",
                "label_ar": "فلاتيبي",
                "label_fr": "Flateby"
            },
            {
                "value": 62577,
                "label": "Ulvik",
                "label_ar": "أولفيك",
                "label_fr": "Ulvik"
            },
            {
                "value": 62780,
                "label": "Konsvikosen",
                "label_ar": "كونسفيكوسين",
                "label_fr": "Konsvikosen"
            },
            {
                "value": 62091,
                "label": "Randaberg",
                "label_ar": "راندابيرج",
                "label_fr": "Randaberg"
            },
            {
                "value": 62453,
                "label": "Utsira",
                "label_ar": "أوتسيرا",
                "label_fr": "Utsira"
            },
            {
                "value": 62222,
                "label": "Svolvær",
                "label_ar": "سفولفير",
                "label_fr": "Svolvær"
            },
            {
                "value": 62730,
                "label": "Roldal",
                "label_ar": "رولدال",
                "label_fr": "Roldal"
            },
            {
                "value": 62385,
                "label": "Skabu",
                "label_ar": "سكابو",
                "label_fr": "Skabu"
            },
            {
                "value": 62118,
                "label": "Floro",
                "label_ar": "فلورو",
                "label_fr": "Floro"
            },
            {
                "value": 62440,
                "label": "Figgjo",
                "label_ar": "فيججو",
                "label_fr": "Figgjo"
            },
            {
                "value": 62309,
                "label": "Steinberg",
                "label_ar": "شتاينبرغ",
                "label_fr": "Steinberg"
            },
            {
                "value": 62438,
                "label": "Vormedal",
                "label_ar": "فورميدال",
                "label_fr": "Vormedal"
            },
            {
                "value": 62007,
                "label": "Gjerdrum",
                "label_ar": "جيردروم",
                "label_fr": "Gjerdrum"
            },
            {
                "value": 62651,
                "label": "Re",
                "label_ar": "إعادة",
                "label_fr": "Ré"
            },
            {
                "value": 62349,
                "label": "Brekstad",
                "label_ar": "بريكستاد",
                "label_fr": "Brekstad"
            },
            {
                "value": 62355,
                "label": "Amot",
                "label_ar": "أموت",
                "label_fr": "Amot"
            },
            {
                "value": 62858,
                "label": "Moen",
                "label_ar": "موين",
                "label_fr": "Moen"
            },
            {
                "value": 61987,
                "label": "Leirsund",
                "label_ar": "ليرسوند",
                "label_fr": "Leirsund"
            },
            {
                "value": 62846,
                "label": "Vraliosen",
                "label_ar": "فراليوسين",
                "label_fr": "Vraliosen"
            },
            {
                "value": 62023,
                "label": "Hjelset",
                "label_ar": "هلست",
                "label_fr": "Hjelset"
            },
            {
                "value": 62032,
                "label": "Brumunddal",
                "label_ar": "بروموندال",
                "label_fr": "Brumunddal"
            },
            {
                "value": 62175,
                "label": "Os",
                "label_ar": "نظام التشغيل",
                "label_fr": "Os"
            },
            {
                "value": 62099,
                "label": "Geilo",
                "label_ar": "جيلو",
                "label_fr": "Geilo"
            },
            {
                "value": 62861,
                "label": "Enebakkneset",
                "label_ar": "انباكنسيت",
                "label_fr": "Enebakkneset"
            },
            {
                "value": 62359,
                "label": "Setermoen",
                "label_ar": "Setermoen",
                "label_fr": "Setermoen"
            },
            {
                "value": 62248,
                "label": "Rakkestad",
                "label_ar": "راكستاد",
                "label_fr": "Rakkestad"
            },
            {
                "value": 62407,
                "label": "Maloy",
                "label_ar": "مالوي",
                "label_fr": "Maloy"
            },
            {
                "value": 62416,
                "label": "Fagerstrand",
                "label_ar": "فاجرستراند",
                "label_fr": "Fagerstrand"
            },
            {
                "value": 62096,
                "label": "Sellebakk",
                "label_ar": "صليبك",
                "label_fr": "Sellebakk"
            },
            {
                "value": 62112,
                "label": "Orkanger",
                "label_ar": "أوركانجر",
                "label_fr": "Orkanger"
            },
            {
                "value": 62447,
                "label": "Folderoy",
                "label_ar": "فولدروي",
                "label_fr": "Folderoy"
            },
            {
                "value": 62166,
                "label": "Gardermoen",
                "label_ar": "جارديرموين",
                "label_fr": "Gardermoen"
            },
            {
                "value": 62366,
                "label": "Sokna",
                "label_ar": "سوكنا",
                "label_fr": "Sokna"
            },
            {
                "value": 62833,
                "label": "Austevoll",
                "label_ar": "أوستفول",
                "label_fr": "Austevoll"
            },
            {
                "value": 62771,
                "label": "Turoy",
                "label_ar": "توروي",
                "label_fr": "Turoy"
            },
            {
                "value": 62126,
                "label": "Forde",
                "label_ar": "فوردي",
                "label_fr": "Forde"
            },
            {
                "value": 62747,
                "label": "Orre",
                "label_ar": "أور",
                "label_fr": "Orre"
            },
            {
                "value": 62129,
                "label": "Leikang",
                "label_ar": "Leikang",
                "label_fr": "Leikang"
            },
            {
                "value": 62811,
                "label": "Byrknes Nordre",
                "label_ar": "بيركنيس نوردري",
                "label_fr": "Byrknes Nordre"
            },
            {
                "value": 62831,
                "label": "Ebru",
                "label_ar": "إبرو",
                "label_fr": "Ebru"
            },
            {
                "value": 62878,
                "label": "Kolbjornsvik",
                "label_ar": "كولبجورنزفيك",
                "label_fr": "Kolbjornsvik"
            },
            {
                "value": 62171,
                "label": "Godvik",
                "label_ar": "جودفيك",
                "label_fr": "Godvik"
            },
            {
                "value": 62743,
                "label": "Vikran",
                "label_ar": "فيكران",
                "label_fr": "Vikran"
            },
            {
                "value": 62326,
                "label": "Drobak",
                "label_ar": "دروباك",
                "label_fr": "Drobak"
            },
            {
                "value": 62560,
                "label": "Reipa",
                "label_ar": "ريبا",
                "label_fr": "Reipa"
            },
            {
                "value": 62548,
                "label": "Slastad",
                "label_ar": "سلاستاد",
                "label_fr": "Slastad"
            },
            {
                "value": 62141,
                "label": "Kirkenes",
                "label_ar": "كيركينيس",
                "label_fr": "Kirkenes"
            },
            {
                "value": 62117,
                "label": "Sorbo",
                "label_ar": "سوربو",
                "label_fr": "Sorbo"
            },
            {
                "value": 62825,
                "label": "Arland",
                "label_ar": "أرلاند",
                "label_fr": "Arland"
            },
            {
                "value": 62305,
                "label": "Seljord",
                "label_ar": "سيلجورد",
                "label_fr": "Seljord"
            },
            {
                "value": 62469,
                "label": "Sylling",
                "label_ar": "التقطيع",
                "label_fr": "Sylling"
            },
            {
                "value": 62301,
                "label": "Bo",
                "label_ar": "بو",
                "label_fr": "Bo"
            },
            {
                "value": 62156,
                "label": "Kvinesdal",
                "label_ar": "كفينسدال",
                "label_fr": "Kvinesdal"
            },
            {
                "value": 62157,
                "label": "Nodeland",
                "label_ar": "نودلاند",
                "label_fr": "Nodeland"
            },
            {
                "value": 62387,
                "label": "Lom",
                "label_ar": "لوم",
                "label_fr": "Lom"
            },
            {
                "value": 62048,
                "label": "Krakeroy",
                "label_ar": "كراكروي",
                "label_fr": "Krakeroy"
            },
            {
                "value": 62472,
                "label": "Glomfjord",
                "label_ar": "جلومفيورد",
                "label_fr": "Glomfjord"
            },
            {
                "value": 62295,
                "label": "Lier",
                "label_ar": "لير",
                "label_fr": "Lier"
            },
            {
                "value": 62457,
                "label": "Rost",
                "label_ar": "روست",
                "label_fr": "Rost"
            },
            {
                "value": 62695,
                "label": "Leirfjord",
                "label_ar": "ليرفيورد",
                "label_fr": "Leirfjord"
            },
            {
                "value": 62034,
                "label": "Larvik",
                "label_ar": "Larvik",
                "label_fr": "Larvik"
            },
            {
                "value": 62734,
                "label": "Storsteinnes",
                "label_ar": "ستورستينيس",
                "label_fr": "Storsteinnes"
            },
            {
                "value": 62125,
                "label": "Kalvag",
                "label_ar": "كالفاج",
                "label_fr": "Kalvag"
            },
            {
                "value": 62599,
                "label": "Tyssedal",
                "label_ar": "تايسيدال",
                "label_fr": "Tyssedal"
            },
            {
                "value": 62832,
                "label": "Morgedal",
                "label_ar": "مورجدال",
                "label_fr": "Morgedal"
            },
            {
                "value": 62308,
                "label": "Hommersak",
                "label_ar": "هومرسك",
                "label_fr": "Hommersak"
            },
            {
                "value": 62722,
                "label": "Tysse",
                "label_ar": "تيسي",
                "label_fr": "Tysse"
            },
            {
                "value": 62556,
                "label": "Hersaeter",
                "label_ar": "هيرسيتر",
                "label_fr": "Hersaeter"
            },
            {
                "value": 62604,
                "label": "Snaase",
                "label_ar": "سنايس",
                "label_fr": "Snaase"
            },
            {
                "value": 62331,
                "label": "Egersund",
                "label_ar": "إيجرسوند",
                "label_fr": "Egersund"
            },
            {
                "value": 62753,
                "label": "Sundal",
                "label_ar": "سوندال",
                "label_fr": "Sundal"
            },
            {
                "value": 62072,
                "label": "Heimdal",
                "label_ar": "هيمدال",
                "label_fr": "Heimdal"
            },
            {
                "value": 62170,
                "label": "Hjellestad",
                "label_ar": "هيلستاد",
                "label_fr": "Hjellestad"
            },
            {
                "value": 62596,
                "label": "Hellesylt",
                "label_ar": "Hellesylt",
                "label_fr": "Hellesylt"
            },
            {
                "value": 62870,
                "label": "Holmen",
                "label_ar": "هولمن",
                "label_fr": "Holmen"
            },
            {
                "value": 62062,
                "label": "Hamar",
                "label_ar": "حمر",
                "label_fr": "Hamar"
            },
            {
                "value": 62592,
                "label": "Sorli",
                "label_ar": "سورلي",
                "label_fr": "Sorli"
            },
            {
                "value": 62880,
                "label": "Ulstein",
                "label_ar": "أولشتاين",
                "label_fr": "Ulstein"
            },
            {
                "value": 62837,
                "label": "Tretten",
                "label_ar": "تريتين",
                "label_fr": "Tretten"
            },
            {
                "value": 62066,
                "label": "Mo i Rana",
                "label_ar": "مو ط رنا",
                "label_fr": "Mo i Rana"
            },
            {
                "value": 62037,
                "label": "Halden",
                "label_ar": "هالدن",
                "label_fr": "Halden"
            },
            {
                "value": 62220,
                "label": "Bjornevatn",
                "label_ar": "بجورنفاتن",
                "label_fr": "Bjornevatn"
            },
            {
                "value": 62646,
                "label": "Siljan",
                "label_ar": "سيلجان",
                "label_fr": "Siljan"
            },
            {
                "value": 62223,
                "label": "Rorvik",
                "label_ar": "رورفيك",
                "label_fr": "Rorvik"
            },
            {
                "value": 62232,
                "label": "Nydalen",
                "label_ar": "نيدالين",
                "label_fr": "Nydalen"
            },
            {
                "value": 62008,
                "label": "Fetsund",
                "label_ar": "فيتسوند",
                "label_fr": "Fetsund"
            },
            {
                "value": 62589,
                "label": "Valldal",
                "label_ar": "فالدال",
                "label_fr": "Valldal"
            },
            {
                "value": 61997,
                "label": "Tomasjorda",
                "label_ar": "توماجوردا",
                "label_fr": "Tomasjorda"
            },
            {
                "value": 62189,
                "label": "Holen",
                "label_ar": "هولن",
                "label_fr": "Holen"
            },
            {
                "value": 62155,
                "label": "Grimstad",
                "label_ar": "جريمستاد",
                "label_fr": "Grimstad"
            },
            {
                "value": 62671,
                "label": "Opphaug",
                "label_ar": "أوبهوج",
                "label_fr": "Opphaug"
            },
            {
                "value": 62250,
                "label": "Solbergelva",
                "label_ar": "سولبيرجيلفا",
                "label_fr": "Solbergelva"
            },
            {
                "value": 62179,
                "label": "Kvinlog",
                "label_ar": "كفينلوغ",
                "label_fr": "Kvinlog"
            },
            {
                "value": 62458,
                "label": "Værøy",
                "label_ar": "Værøy",
                "label_fr": "Værøy"
            },
            {
                "value": 62779,
                "label": "Dalen",
                "label_ar": "دالين",
                "label_fr": "Dalen"
            },
            {
                "value": 62293,
                "label": "Tjelta",
                "label_ar": "تجيلتا",
                "label_fr": "Tjelta"
            },
            {
                "value": 62840,
                "label": "Langangen",
                "label_ar": "لانغانجين",
                "label_fr": "Langangen"
            },
            {
                "value": 62822,
                "label": "Misvaer",
                "label_ar": "المسفاير",
                "label_fr": "Misvaer"
            },
            {
                "value": 62748,
                "label": "Rindal",
                "label_ar": "رندال",
                "label_fr": "Rindal"
            },
            {
                "value": 62488,
                "label": "Eggedal",
                "label_ar": "إيجيدال",
                "label_fr": "Eggedal"
            },
            {
                "value": 62522,
                "label": "Nedre Frei",
                "label_ar": "ندر فري",
                "label_fr": "Nedre Frei"
            },
            {
                "value": 62071,
                "label": "Stathelle",
                "label_ar": "ستاثيل",
                "label_fr": "Stathelle"
            },
            {
                "value": 62624,
                "label": "Havøysund",
                "label_ar": "هافويسوند",
                "label_fr": "Havøysund"
            },
            {
                "value": 62022,
                "label": "Stordal",
                "label_ar": "ستوردال",
                "label_fr": "Stordal"
            },
            {
                "value": 62829,
                "label": "Holsen",
                "label_ar": "هولسن",
                "label_fr": "Holsen"
            },
            {
                "value": 62013,
                "label": "Brattvag",
                "label_ar": "براتفاغ",
                "label_fr": "Brattvag"
            },
            {
                "value": 62137,
                "label": "Moen",
                "label_ar": "موين",
                "label_fr": "Moen"
            },
            {
                "value": 62350,
                "label": "Lillesand",
                "label_ar": "ليلسان",
                "label_fr": "Lillesand"
            },
            {
                "value": 62197,
                "label": "Koppang",
                "label_ar": "كوبانج",
                "label_fr": "Koppang"
            },
            {
                "value": 61999,
                "label": "Tromsdalen",
                "label_ar": "ترومسدالين",
                "label_fr": "Tromsdalen"
            },
            {
                "value": 62583,
                "label": "Havik",
                "label_ar": "هافيك",
                "label_fr": "Havik"
            },
            {
                "value": 62325,
                "label": "Isdalsto",
                "label_ar": "إسدالستو",
                "label_fr": "Isdalsto"
            },
            {
                "value": 62081,
                "label": "Karlshus",
                "label_ar": "كارلشوس",
                "label_fr": "Karlshus"
            },
            {
                "value": 62134,
                "label": "Silsand",
                "label_ar": "سيلساند",
                "label_fr": "Silsand"
            },
            {
                "value": 62751,
                "label": "Groa",
                "label_ar": "جرو",
                "label_fr": "Groa"
            },
            {
                "value": 62286,
                "label": "Sandeid",
                "label_ar": "سانديد",
                "label_fr": "Sandeid"
            },
            {
                "value": 62046,
                "label": "Lillehammer",
                "label_ar": "ليلهامر",
                "label_fr": "Lillehammer"
            },
            {
                "value": 62437,
                "label": "Tysnes",
                "label_ar": "تايسنس",
                "label_fr": "Tysnes"
            },
            {
                "value": 62588,
                "label": "Eidsnes",
                "label_ar": "إيدسنيس",
                "label_fr": "Eidsnes"
            },
            {
                "value": 62149,
                "label": "Hasvik",
                "label_ar": "هاسفيك",
                "label_fr": "Hasvik"
            },
            {
                "value": 62265,
                "label": "Stol",
                "label_ar": "سرقت",
                "label_fr": "Stol"
            },
            {
                "value": 62537,
                "label": "Skjolden",
                "label_ar": "Skjolden",
                "label_fr": "Skjolden"
            },
            {
                "value": 62452,
                "label": "Sto",
                "label_ar": "ستو",
                "label_fr": "Sto"
            },
            {
                "value": 62539,
                "label": "Singsas",
                "label_ar": "سينغساس",
                "label_fr": "Singsas"
            },
            {
                "value": 62638,
                "label": "Tingvoll",
                "label_ar": "Tingvoll",
                "label_fr": "Tingvoll"
            },
            {
                "value": 62064,
                "label": "Arnes",
                "label_ar": "Arnes",
                "label_fr": "Arnes"
            },
            {
                "value": 62296,
                "label": "Klepp",
                "label_ar": "كليب",
                "label_fr": "Klepp"
            },
            {
                "value": 62485,
                "label": "Amot",
                "label_ar": "أموت",
                "label_fr": "Amot"
            },
            {
                "value": 62055,
                "label": "Haslum",
                "label_ar": "هاسلوم",
                "label_fr": "Haslum"
            },
            {
                "value": 62243,
                "label": "Risoyhamn",
                "label_ar": "ريسويهامن",
                "label_fr": "Risoyhamn"
            },
            {
                "value": 62563,
                "label": "Risør",
                "label_ar": "ريزور",
                "label_fr": "Risør"
            },
            {
                "value": 62052,
                "label": "Ranheim",
                "label_ar": "رانهايم",
                "label_fr": "Ranheim"
            },
            {
                "value": 62240,
                "label": "Dverberg",
                "label_ar": "دفربيرج",
                "label_fr": "Dverberg"
            },
            {
                "value": 62193,
                "label": "Fla",
                "label_ar": "فلوريدا",
                "label_fr": "Floride"
            },
            {
                "value": 62257,
                "label": "Stabbestad",
                "label_ar": "ستابستاد",
                "label_fr": "Stabbestad"
            },
            {
                "value": 62228,
                "label": "Flemma",
                "label_ar": "فليما",
                "label_fr": "Flemma"
            },
            {
                "value": 62412,
                "label": "Lidaladdi",
                "label_ar": "ليدالادي",
                "label_fr": "Lidaladdi"
            },
            {
                "value": 62736,
                "label": "Tornes",
                "label_ar": "تمزق",
                "label_fr": "Tornes"
            },
            {
                "value": 62216,
                "label": "Straumen",
                "label_ar": "سترومن",
                "label_fr": "Straumen"
            },
            {
                "value": 62618,
                "label": "Knappstad",
                "label_ar": "كنابستاد",
                "label_fr": "Knappstad"
            },
            {
                "value": 62685,
                "label": "Hordvik",
                "label_ar": "هوردفيك",
                "label_fr": "Hordvik"
            },
            {
                "value": 62403,
                "label": "Kolltveit",
                "label_ar": "كولتفيت",
                "label_fr": "Kolltveit"
            },
            {
                "value": 62538,
                "label": "Hamarvik",
                "label_ar": "هامارفيك",
                "label_fr": "Hamarvik"
            },
            {
                "value": 62723,
                "label": "Ervik",
                "label_ar": "ايرفيك",
                "label_fr": "Ervik"
            },
            {
                "value": 62368,
                "label": "Gan",
                "label_ar": "غان",
                "label_fr": "Gan"
            },
            {
                "value": 62365,
                "label": "Brandbu",
                "label_ar": "براندبو",
                "label_fr": "Brandbu"
            },
            {
                "value": 62544,
                "label": "Solvorn",
                "label_ar": "سولفورن",
                "label_fr": "Solvorn"
            },
            {
                "value": 62233,
                "label": "Hemnes",
                "label_ar": "هيمنز",
                "label_fr": "Hemnes"
            },
            {
                "value": 62712,
                "label": "Nybergsund",
                "label_ar": "Nybergsund",
                "label_fr": "Nybergsund"
            },
            {
                "value": 62212,
                "label": "Nordland",
                "label_ar": "نوردلاند",
                "label_fr": "Nordland"
            },
            {
                "value": 62445,
                "label": "Mirza Rafi Sauda",
                "label_ar": "ميرزا رافي سودة",
                "label_fr": "Mirza Rafi Sauda"
            },
            {
                "value": 62777,
                "label": "Trofors",
                "label_ar": "تروفورس",
                "label_fr": "Trofors"
            },
            {
                "value": 62224,
                "label": "Angvika",
                "label_ar": "أنجفيكا",
                "label_fr": "Angvika"
            },
            {
                "value": 62448,
                "label": "Sira",
                "label_ar": "سيرا",
                "label_fr": "Sira"
            },
            {
                "value": 62787,
                "label": "Horte",
                "label_ar": "هورت",
                "label_fr": "Horte"
            },
            {
                "value": 62797,
                "label": "Undeim",
                "label_ar": "Undeim",
                "label_fr": "Undeim"
            },
            {
                "value": 62251,
                "label": "Sande",
                "label_ar": "ساندي",
                "label_fr": "Sande"
            },
            {
                "value": 62666,
                "label": "Haram",
                "label_ar": "الهرم",
                "label_fr": "Haram"
            },
            {
                "value": 61968,
                "label": "Vestby",
                "label_ar": "فيستبي",
                "label_fr": "Vestby"
            },
            {
                "value": 62812,
                "label": "Ardal",
                "label_ar": "أردال",
                "label_fr": "Ardal"
            },
            {
                "value": 62727,
                "label": "Modalen",
                "label_ar": "مودالين",
                "label_fr": "Modalen"
            },
            {
                "value": 62144,
                "label": "Cavkkus",
                "label_ar": "كافكوس",
                "label_fr": "Cavkkus"
            },
            {
                "value": 61993,
                "label": "Kolsas",
                "label_ar": "كولساس",
                "label_fr": "Kolsas"
            },
            {
                "value": 62070,
                "label": "Narvik",
                "label_ar": "نارفيك",
                "label_fr": "Narvik"
            },
            {
                "value": 62520,
                "label": "Feda",
                "label_ar": "فدا",
                "label_fr": "Feda"
            },
            {
                "value": 62105,
                "label": "Tiller",
                "label_ar": "الحارث",
                "label_fr": "Taller"
            },
            {
                "value": 62068,
                "label": "Eidsvoll verk",
                "label_ar": "Eidsvoll verk",
                "label_fr": "Eidsvoll verk"
            },
            {
                "value": 62516,
                "label": "Hovin",
                "label_ar": "هوفين",
                "label_fr": "Hovin"
            },
            {
                "value": 62089,
                "label": "Honningsvåg",
                "label_ar": "Honningsvåg",
                "label_fr": "Honningsvåg"
            },
            {
                "value": 62142,
                "label": "Båtsfjord",
                "label_ar": "Båtsfjord",
                "label_fr": "Båtsfjord"
            },
            {
                "value": 62545,
                "label": "Tjeldsto",
                "label_ar": "تجيلدستو",
                "label_fr": "Tjeldsto"
            },
            {
                "value": 62393,
                "label": "Rognan",
                "label_ar": "روجنان",
                "label_fr": "Rognan"
            },
            {
                "value": 62772,
                "label": "Gasbakken",
                "label_ar": "غازباكن",
                "label_fr": "Gasbakken"
            },
            {
                "value": 61995,
                "label": "Skien",
                "label_ar": "سكاين",
                "label_fr": "Skien"
            },
            {
                "value": 62874,
                "label": "Skulsfjord",
                "label_ar": "Skulsfjord",
                "label_fr": "Skulsfjord"
            },
            {
                "value": 62776,
                "label": "Ramfjordbotn",
                "label_ar": "رامفيوردبوتن",
                "label_fr": "Ramfjordbotn"
            },
            {
                "value": 62526,
                "label": "Furnes",
                "label_ar": "فورنيس",
                "label_fr": "Furnes"
            },
            {
                "value": 62573,
                "label": "Flesberg",
                "label_ar": "فليسبيرغ",
                "label_fr": "Flesberg"
            },
            {
                "value": 62237,
                "label": "Trollfjorden",
                "label_ar": "ترولفيوردين",
                "label_fr": "Trollfjorden"
            },
            {
                "value": 62167,
                "label": "Garnes",
                "label_ar": "غارنيس",
                "label_fr": "Garnes"
            },
            {
                "value": 62044,
                "label": "Rjukan",
                "label_ar": "رجوكان",
                "label_fr": "Rjukan"
            },
            {
                "value": 62348,
                "label": "Hakadal",
                "label_ar": "هاكادال",
                "label_fr": "Hakadal"
            },
            {
                "value": 62823,
                "label": "Tangen",
                "label_ar": "Tangen",
                "label_fr": "Tangen"
            },
            {
                "value": 62773,
                "label": "Vikebygd",
                "label_ar": "فيكيبيجد",
                "label_fr": "Vikebygd"
            },
            {
                "value": 62415,
                "label": "Tomter",
                "label_ar": "تومتر",
                "label_fr": "Tomter"
            },
            {
                "value": 62872,
                "label": "Skarer",
                "label_ar": "سكارر",
                "label_fr": "Skarer"
            },
            {
                "value": 62323,
                "label": "Lærdal",
                "label_ar": "لاردال",
                "label_fr": "Lærdal"
            },
            {
                "value": 62474,
                "label": "Svarstad",
                "label_ar": "سفارستاد",
                "label_fr": "Svarstad"
            },
            {
                "value": 62716,
                "label": "Laukvik",
                "label_ar": "لوكفيك",
                "label_fr": "Laukvik"
            },
            {
                "value": 62615,
                "label": "Mjolkeraen",
                "label_ar": "مجولكرين",
                "label_fr": "Mjolkeraen"
            },
            {
                "value": 62139,
                "label": "Vadsø",
                "label_ar": "فادسو",
                "label_fr": "Vadsø"
            },
            {
                "value": 62450,
                "label": "Sandnes",
                "label_ar": "ساندنس",
                "label_fr": "Sandnes"
            },
            {
                "value": 62402,
                "label": "Vage",
                "label_ar": "فيج",
                "label_fr": "Vage"
            },
            {
                "value": 62333,
                "label": "Mathopen",
                "label_ar": "ماثوبين",
                "label_fr": "Mathopen"
            },
            {
                "value": 62408,
                "label": "Sogn",
                "label_ar": "سوغن",
                "label_fr": "Sogn"
            },
            {
                "value": 62028,
                "label": "Aukra",
                "label_ar": "اوكرا",
                "label_fr": "Aukra"
            },
            {
                "value": 62186,
                "label": "Fenstad",
                "label_ar": "فنستاد",
                "label_fr": "Fenstad"
            },
            {
                "value": 62742,
                "label": "Batsto",
                "label_ar": "باتستو",
                "label_fr": "Batsto"
            },
            {
                "value": 62244,
                "label": "Drag",
                "label_ar": "سحب",
                "label_fr": "Traîne"
            },
            {
                "value": 62225,
                "label": "Eide",
                "label_ar": "إيدي",
                "label_fr": "Eide"
            },
            {
                "value": 61981,
                "label": "Aseral",
                "label_ar": "Aseral",
                "label_fr": "Aseral"
            },
            {
                "value": 62569,
                "label": "Krakstad",
                "label_ar": "كراكستاد",
                "label_fr": "Krakstad"
            },
            {
                "value": 62855,
                "label": "Innvik",
                "label_ar": "إنفيك",
                "label_fr": "Innvik"
            },
            {
                "value": 62674,
                "label": "Tvedestrand",
                "label_ar": "تفيدستراند",
                "label_fr": "Tvedestrand"
            },
            {
                "value": 62684,
                "label": "Somna",
                "label_ar": "سومنا",
                "label_fr": "Somna"
            },
            {
                "value": 62088,
                "label": "Melsomvik",
                "label_ar": "ميلسومفيك",
                "label_fr": "Melsomvik"
            },
            {
                "value": 62791,
                "label": "Fall",
                "label_ar": "خريف",
                "label_fr": "Tomber"
            },
            {
                "value": 62410,
                "label": "Hagavik",
                "label_ar": "هاجافيك",
                "label_fr": "Hagavik"
            },
            {
                "value": 62505,
                "label": "Hommelvik",
                "label_ar": "هوملفيك",
                "label_fr": "Hommelvik"
            },
            {
                "value": 62493,
                "label": "Indre Ulvsvag",
                "label_ar": "إندري Ulvsvag",
                "label_fr": "Indre Ulvsvag"
            },
            {
                "value": 62079,
                "label": "Steinkjer",
                "label_ar": "Steinkjer",
                "label_fr": "Steinkjer"
            },
            {
                "value": 62502,
                "label": "Storas",
                "label_ar": "ستوراس",
                "label_fr": "Storas"
            },
            {
                "value": 62432,
                "label": "Ise",
                "label_ar": "ايس",
                "label_fr": "Ise"
            },
            {
                "value": 62389,
                "label": "Faberg",
                "label_ar": "فابيرج",
                "label_fr": "Faberg"
            },
            {
                "value": 62888,
                "label": "Vossestrand",
                "label_ar": "فوسيستراند",
                "label_fr": "Vossestrand"
            },
            {
                "value": 62339,
                "label": "Froland",
                "label_ar": "فرولاند",
                "label_fr": "Froland"
            },
            {
                "value": 62277,
                "label": "Oltedal",
                "label_ar": "أولتيدال",
                "label_fr": "Oltedal"
            },
            {
                "value": 62881,
                "label": "Holmenkollen",
                "label_ar": "هولمينكولين",
                "label_fr": "Holmenkollen"
            },
            {
                "value": 62073,
                "label": "Nøtterøy",
                "label_ar": "Nøtterøy",
                "label_fr": "Nøtterøy"
            },
            {
                "value": 62035,
                "label": "Andalsnes",
                "label_ar": "أندالسنيس",
                "label_fr": "Andalsnes"
            },
            {
                "value": 62396,
                "label": "Lonevag",
                "label_ar": "لونيفاج",
                "label_fr": "Lonevag"
            },
            {
                "value": 62203,
                "label": "Rogne",
                "label_ar": "روجن",
                "label_fr": "Rogne"
            },
            {
                "value": 62307,
                "label": "Dirdal",
                "label_ar": "ديردال",
                "label_fr": "Dirdal"
            },
            {
                "value": 62423,
                "label": "Skreia",
                "label_ar": "سكريا",
                "label_fr": "Skreia"
            },
            {
                "value": 62215,
                "label": "Nesna",
                "label_ar": "نسنا",
                "label_fr": "Nesna"
            },
            {
                "value": 62512,
                "label": "Lysoysundet",
                "label_ar": "ليسوسونديت",
                "label_fr": "Lysoysundet"
            },
            {
                "value": 62266,
                "label": "Naerbo",
                "label_ar": "نايربو",
                "label_fr": "Naerbo"
            },
            {
                "value": 62285,
                "label": "Lodingen",
                "label_ar": "لودينجين",
                "label_fr": "Lodingen"
            },
            {
                "value": 62278,
                "label": "Jorpeland",
                "label_ar": "جوربلاند",
                "label_fr": "Jorpeland"
            },
            {
                "value": 62003,
                "label": "Arendal",
                "label_ar": "أرندال",
                "label_fr": "Arendal"
            },
            {
                "value": 62054,
                "label": "Sandvika",
                "label_ar": "ساندفيكا",
                "label_fr": "Sandvika"
            },
            {
                "value": 62789,
                "label": "Moldjord",
                "label_ar": "مولدجورد",
                "label_fr": "Moldjord"
            },
            {
                "value": 62578,
                "label": "Sunde",
                "label_ar": "سوند",
                "label_fr": "Sunde"
            },
            {
                "value": 62852,
                "label": "Dolemo",
                "label_ar": "دوليمو",
                "label_fr": "Dolemo"
            },
            {
                "value": 62011,
                "label": "Skodje",
                "label_ar": "سكودي",
                "label_fr": "Skodje"
            },
            {
                "value": 62183,
                "label": "Hemnesberget",
                "label_ar": "همنسبيرجيت",
                "label_fr": "Hemnesberget"
            },
            {
                "value": 62745,
                "label": "Hustad",
                "label_ar": "هوستاد",
                "label_fr": "Hustad"
            },
            {
                "value": 62300,
                "label": "Berge",
                "label_ar": "بيرج",
                "label_fr": "Berge"
            },
            {
                "value": 62322,
                "label": "Flam",
                "label_ar": "Flam",
                "label_fr": "Flam"
            },
            {
                "value": 62027,
                "label": "Rovde",
                "label_ar": "روفدي",
                "label_fr": "Rovde"
            },
            {
                "value": 62655,
                "label": "Berlevåg",
                "label_ar": "بيرليفوج",
                "label_fr": "Berlevåg"
            },
            {
                "value": 62735,
                "label": "Midsund",
                "label_ar": "ميدسوند",
                "label_fr": "Midsund"
            },
            {
                "value": 62399,
                "label": "Stavern",
                "label_ar": "ستافرن",
                "label_fr": "Stavern"
            },
            {
                "value": 62411,
                "label": "Feiring",
                "label_ar": "المشاجرة",
                "label_fr": "Feiring"
            },
            {
                "value": 62645,
                "label": "Tistedal",
                "label_ar": "تيستدال",
                "label_fr": "Tistedal"
            },
            {
                "value": 62850,
                "label": "Borge",
                "label_ar": "بورج",
                "label_fr": "Borge"
            },
            {
                "value": 62750,
                "label": "Kvanne",
                "label_ar": "كفان",
                "label_fr": "Kvanne"
            },
            {
                "value": 62724,
                "label": "Klokkarvik",
                "label_ar": "كلوكارفيك",
                "label_fr": "Klokkarvik"
            },
            {
                "value": 62738,
                "label": "Tjorvag",
                "label_ar": "تجورفاج",
                "label_fr": "Tjorvag"
            },
            {
                "value": 62259,
                "label": "Etne",
                "label_ar": "إتني",
                "label_fr": "Etne"
            },
            {
                "value": 62414,
                "label": "Sorvik",
                "label_ar": "سورفيك",
                "label_fr": "Sorvik"
            },
            {
                "value": 62808,
                "label": "Bjordal",
                "label_ar": "بجوردال",
                "label_fr": "Bjordal"
            },
            {
                "value": 62828,
                "label": "Hyggen",
                "label_ar": "هيجن",
                "label_fr": "Hyggen"
            },
            {
                "value": 62841,
                "label": "Oyeren",
                "label_ar": "أويرين",
                "label_fr": "Oyeren"
            },
            {
                "value": 62628,
                "label": "Hokkasen",
                "label_ar": "هوكاسين",
                "label_fr": "Hokkasen"
            },
            {
                "value": 62311,
                "label": "Greaker",
                "label_ar": "جريكير",
                "label_fr": "Greaker"
            },
            {
                "value": 62017,
                "label": "Volda",
                "label_ar": "فولدا",
                "label_fr": "Volda"
            },
            {
                "value": 62347,
                "label": "Hvam",
                "label_ar": "هفام",
                "label_fr": "Hvam"
            },
            {
                "value": 62086,
                "label": "Royken",
                "label_ar": "رويكن",
                "label_fr": "Royken"
            },
            {
                "value": 62392,
                "label": "Nordfjordeid",
                "label_ar": "نوردفيورد",
                "label_fr": "Nordfjordeid"
            },
            {
                "value": 62515,
                "label": "Kval",
                "label_ar": "كفال",
                "label_fr": "Kval"
            },
            {
                "value": 62182,
                "label": "Sandnessjoen",
                "label_ar": "Sandnessjoen",
                "label_fr": "Sandnessjoen"
            },
            {
                "value": 62518,
                "label": "Haerland",
                "label_ar": "هيرلاند",
                "label_fr": "Haerland"
            },
            {
                "value": 62321,
                "label": "Skoppum",
                "label_ar": "Skoppum",
                "label_fr": "Skoppum"
            },
            {
                "value": 62041,
                "label": "Porsgrunn",
                "label_ar": "بورسغرون",
                "label_fr": "Porsgrunn"
            },
            {
                "value": 62061,
                "label": "Nevlunghamn",
                "label_ar": "نيفلونغهامن",
                "label_fr": "Nevlunghamn"
            },
            {
                "value": 62860,
                "label": "Follafoss",
                "label_ar": "فولافوس",
                "label_fr": "Follafoss"
            },
            {
                "value": 62877,
                "label": "Annstad",
                "label_ar": "أنستاد",
                "label_fr": "Annstad"
            },
            {
                "value": 62235,
                "label": "Bjorkelangen",
                "label_ar": "بجوركلانجين",
                "label_fr": "Bjorkelangen"
            },
            {
                "value": 62122,
                "label": "Sandane",
                "label_ar": "Sandane",
                "label_fr": "Sandane"
            },
            {
                "value": 62049,
                "label": "Rolvsoy",
                "label_ar": "Rolvsoy",
                "label_fr": "Rolvsoy"
            },
            {
                "value": 62294,
                "label": "Gullaug",
                "label_ar": "جولوج",
                "label_fr": "Gullaug"
            },
            {
                "value": 62334,
                "label": "Hylkje",
                "label_ar": "هيلكي",
                "label_fr": "Hylkje"
            },
            {
                "value": 62264,
                "label": "Skjoldastraumen",
                "label_ar": "Skjoldastraumen",
                "label_fr": "Skjoldastraumen"
            },
            {
                "value": 62001,
                "label": "Stabekk",
                "label_ar": "ستابيك",
                "label_fr": "Stabekk"
            },
            {
                "value": 62864,
                "label": "Amli",
                "label_ar": "أملي",
                "label_fr": "Amli"
            },
            {
                "value": 62078,
                "label": "Bones",
                "label_ar": "عظام",
                "label_fr": "Des os"
            },
            {
                "value": 62336,
                "label": "Florvag",
                "label_ar": "فلورفاج",
                "label_fr": "Florvag"
            },
            {
                "value": 62442,
                "label": "Bykle",
                "label_ar": "بيكل",
                "label_fr": "Bykle"
            },
            {
                "value": 62080,
                "label": "Engalsvik",
                "label_ar": "إنغالسفيك",
                "label_fr": "Engalsvik"
            },
            {
                "value": 62885,
                "label": "Marheim",
                "label_ar": "مارهايم",
                "label_fr": "Marheim"
            },
            {
                "value": 61973,
                "label": "Kongsvinger",
                "label_ar": "كونغسفينجر",
                "label_fr": "Kongsvinger"
            },
            {
                "value": 62169,
                "label": "Manger",
                "label_ar": "مدير",
                "label_fr": "Manger"
            },
            {
                "value": 62190,
                "label": "Hvalstad",
                "label_ar": "هفالستاد",
                "label_fr": "Hvalstad"
            },
            {
                "value": 62188,
                "label": "Oppdal",
                "label_ar": "اوبدال",
                "label_fr": "Oppdal"
            },
            {
                "value": 62025,
                "label": "Lyngstad",
                "label_ar": "لينجستاد",
                "label_fr": "Lyngstad"
            },
            {
                "value": 62793,
                "label": "Steinsdalen",
                "label_ar": "ستينسدالين",
                "label_fr": "Steinsdalen"
            },
            {
                "value": 62662,
                "label": "Hansnes",
                "label_ar": "هانسن",
                "label_fr": "Hansnes"
            },
            {
                "value": 62464,
                "label": "Ballstad",
                "label_ar": "بالستاد",
                "label_fr": "Ballstad"
            },
            {
                "value": 62059,
                "label": "Gressvik",
                "label_ar": "جريسفيك",
                "label_fr": "Gressvik"
            },
            {
                "value": 62242,
                "label": "Stronstad",
                "label_ar": "سترونستاد",
                "label_fr": "Stronstad"
            },
            {
                "value": 62229,
                "label": "Vagland",
                "label_ar": "فاجلاند",
                "label_fr": "Vagland"
            },
            {
                "value": 62290,
                "label": "Bomlo",
                "label_ar": "بوملو",
                "label_fr": "Bomlo"
            },
            {
                "value": 61990,
                "label": "Dilling",
                "label_ar": "الدلنج",
                "label_fr": "Aneth"
            },
            {
                "value": 62794,
                "label": "Brusand",
                "label_ar": "بروساند",
                "label_fr": "Brusand"
            },
            {
                "value": 62830,
                "label": "Hedal",
                "label_ar": "هيدال",
                "label_fr": "Hedal"
            },
            {
                "value": 62826,
                "label": "Sand",
                "label_ar": "الرمل",
                "label_fr": "Le sable"
            },
            {
                "value": 61976,
                "label": "Lillestrøm",
                "label_ar": "ليلستروم",
                "label_fr": "Lillestrøm"
            },
            {
                "value": 62489,
                "label": "Prestfoss",
                "label_ar": "Prestfoss",
                "label_fr": "Prestfoss"
            },
            {
                "value": 62337,
                "label": "Kleppesto",
                "label_ar": "كليبيستو",
                "label_fr": "Kleppesto"
            },
            {
                "value": 62641,
                "label": "Haddal",
                "label_ar": "هدل",
                "label_fr": "Haddal"
            },
            {
                "value": 62272,
                "label": "Farsund",
                "label_ar": "فارسوند",
                "label_fr": "Farsund"
            },
            {
                "value": 62373,
                "label": "Kjeller",
                "label_ar": "كجيلر",
                "label_fr": "Kjeller"
            },
            {
                "value": 62273,
                "label": "Vanse",
                "label_ar": "فانسي",
                "label_fr": "Vanse"
            },
            {
                "value": 62847,
                "label": "Trana",
                "label_ar": "ترانا",
                "label_fr": "Trana"
            },
            {
                "value": 62221,
                "label": "Burfjord",
                "label_ar": "برفجورد",
                "label_fr": "Burfjord"
            },
            {
                "value": 62261,
                "label": "Svortland",
                "label_ar": "سفورتلاند",
                "label_fr": "Svortland"
            },
            {
                "value": 62590,
                "label": "Grong",
                "label_ar": "جرونج",
                "label_fr": "Grong"
            },
            {
                "value": 62490,
                "label": "Romedal",
                "label_ar": "روميدال",
                "label_fr": "Romedal"
            },
            {
                "value": 62204,
                "label": "Land",
                "label_ar": "أرض",
                "label_fr": "Terre"
            },
            {
                "value": 62342,
                "label": "Loten",
                "label_ar": "لوتين",
                "label_fr": "Loten"
            },
            {
                "value": 62329,
                "label": "Inndyr",
                "label_ar": "إيندير",
                "label_fr": "Inndyr"
            },
            {
                "value": 62191,
                "label": "Husoy",
                "label_ar": "حسوي",
                "label_fr": "Husoy"
            },
            {
                "value": 62405,
                "label": "Moi",
                "label_ar": "موي",
                "label_fr": "Moi"
            },
            {
                "value": 62343,
                "label": "Oksvoll",
                "label_ar": "أوكسفول",
                "label_fr": "Oksvoll"
            },
            {
                "value": 62270,
                "label": "Byglandsfjord",
                "label_ar": "Byglandsfjord",
                "label_fr": "Byglandsfjord"
            },
            {
                "value": 62302,
                "label": "Bo",
                "label_ar": "بو",
                "label_fr": "Bo"
            },
            {
                "value": 62705,
                "label": "Bjerka",
                "label_ar": "بجيركا",
                "label_fr": "Bjerka"
            },
            {
                "value": 62313,
                "label": "Konsmo",
                "label_ar": "كونسمو",
                "label_fr": "Konsmo"
            },
            {
                "value": 62675,
                "label": "Svene",
                "label_ar": "سفين",
                "label_fr": "Svene"
            },
            {
                "value": 62580,
                "label": "Valen",
                "label_ar": "فالين",
                "label_fr": "Valen"
            },
            {
                "value": 62707,
                "label": "Haegeland",
                "label_ar": "هايجلاند",
                "label_fr": "Haegeland"
            },
            {
                "value": 62192,
                "label": "Skedsmokorset",
                "label_ar": "Skedsmokorset",
                "label_fr": "Skedsmokorset"
            },
            {
                "value": 62591,
                "label": "Skage",
                "label_ar": "سكيج",
                "label_fr": "Skage"
            },
            {
                "value": 62310,
                "label": "Svelvik",
                "label_ar": "سفيلفيك",
                "label_fr": "Svelvik"
            },
            {
                "value": 62729,
                "label": "Saevareid",
                "label_ar": "سيفريد",
                "label_fr": "Saevareid"
            },
            {
                "value": 62135,
                "label": "Samuelsberg",
                "label_ar": "Samuelsberg",
                "label_fr": "Samuelsberg"
            },
            {
                "value": 62644,
                "label": "Nordkisa",
                "label_ar": "نوردكيسا",
                "label_fr": "Nordkisa"
            },
            {
                "value": 62796,
                "label": "Espeland",
                "label_ar": "اسبلاند",
                "label_fr": "Espeland"
            },
            {
                "value": 62475,
                "label": "Dyrvika",
                "label_ar": "Dyrvika",
                "label_fr": "Dyrvika"
            },
            {
                "value": 62541,
                "label": "Knarrlaget",
                "label_ar": "كنارلاغيت",
                "label_fr": "Knarrlaget"
            },
            {
                "value": 62324,
                "label": "Voksa",
                "label_ar": "فوكسا",
                "label_fr": "Voksa"
            },
            {
                "value": 62159,
                "label": "Valle",
                "label_ar": "فالي",
                "label_fr": "Valle"
            },
            {
                "value": 62426,
                "label": "Skatoy",
                "label_ar": "سكاتوي",
                "label_fr": "Skatoy"
            },
            {
                "value": 62314,
                "label": "Finsand",
                "label_ar": "فينساند",
                "label_fr": "Finsand"
            },
            {
                "value": 62482,
                "label": "Eikelandsosen",
                "label_ar": "Eikelandsosen",
                "label_fr": "Eikelandsosen"
            },
            {
                "value": 62690,
                "label": "Otteroy",
                "label_ar": "Otteroy",
                "label_fr": "Otteroy"
            },
            {
                "value": 62484,
                "label": "Eikanger",
                "label_ar": "ايكنجر",
                "label_fr": "Eikanger"
            },
            {
                "value": 62351,
                "label": "Vestfossen",
                "label_ar": "فيستفوسن",
                "label_fr": "Vestfossen"
            },
            {
                "value": 62801,
                "label": "Snillfjord",
                "label_ar": "Snillfjord",
                "label_fr": "Snillfjord"
            },
            {
                "value": 62218,
                "label": "Karasjok",
                "label_ar": "Karasjok",
                "label_fr": "Karasjok"
            },
            {
                "value": 62245,
                "label": "Manndalen",
                "label_ar": "ماندالين",
                "label_fr": "Manndalen"
            },
            {
                "value": 62530,
                "label": "Uvdal",
                "label_ar": "يوفدال",
                "label_fr": "Uvdal"
            },
            {
                "value": 62620,
                "label": "Bo",
                "label_ar": "بو",
                "label_fr": "Bo"
            },
            {
                "value": 62627,
                "label": "Reinsvoll",
                "label_ar": "Reinsvoll",
                "label_fr": "Reinsvoll"
            },
            {
                "value": 62252,
                "label": "Mjondalen",
                "label_ar": "مجوندالين",
                "label_fr": "Mjondalen"
            },
            {
                "value": 62173,
                "label": "Granvin",
                "label_ar": "جرانفين",
                "label_fr": "Granvin"
            },
            {
                "value": 62267,
                "label": "Tau",
                "label_ar": "تاو",
                "label_fr": "Tau"
            },
            {
                "value": 62686,
                "label": "Fister",
                "label_ar": "شقيقة",
                "label_fr": "Fister"
            },
            {
                "value": 62669,
                "label": "Kviteseid",
                "label_ar": "كفيتسيد",
                "label_fr": "Kviteseid"
            },
            {
                "value": 62466,
                "label": "Havik",
                "label_ar": "هافيك",
                "label_fr": "Havik"
            },
            {
                "value": 62510,
                "label": "Sande",
                "label_ar": "ساندي",
                "label_fr": "Sande"
            },
            {
                "value": 62404,
                "label": "Voss",
                "label_ar": "فوس",
                "label_fr": "Voss"
            },
            {
                "value": 62721,
                "label": "Kamben",
                "label_ar": "كامبن",
                "label_fr": "Kamben"
            },
            {
                "value": 62060,
                "label": "Kragero",
                "label_ar": "كراجيرو",
                "label_fr": "Kragero"
            },
            {
                "value": 62140,
                "label": "Jakobselv",
                "label_ar": "جاكوبسيلف",
                "label_fr": "Jakobselv"
            },
            {
                "value": 62497,
                "label": "Skotbu",
                "label_ar": "سكوتبو",
                "label_fr": "Skotbu"
            },
            {
                "value": 62354,
                "label": "Almas",
                "label_ar": "الماس",
                "label_fr": "Almas"
            },
            {
                "value": 62654,
                "label": "Sommaroy",
                "label_ar": "سوماروي",
                "label_fr": "Sommaroy"
            },
            {
                "value": 62246,
                "label": "Fagernes",
                "label_ar": "فاجيرنز",
                "label_fr": "Fagernes"
            },
            {
                "value": 62279,
                "label": "Bryne",
                "label_ar": "برين",
                "label_fr": "Bryne"
            },
            {
                "value": 62711,
                "label": "Jomna",
                "label_ar": "جومنا",
                "label_fr": "Jomna"
            },
            {
                "value": 62667,
                "label": "Vinje",
                "label_ar": "فينج",
                "label_fr": "Vinje"
            },
            {
                "value": 62283,
                "label": "Borkenes",
                "label_ar": "بوركين",
                "label_fr": "Borkènes"
            },
            {
                "value": 62842,
                "label": "Reine",
                "label_ar": "رين",
                "label_fr": "Reine"
            },
            {
                "value": 62813,
                "label": "Blakset",
                "label_ar": "بلاكت",
                "label_fr": "Blakset"
            },
            {
                "value": 62213,
                "label": "Ballangen",
                "label_ar": "بالانجين",
                "label_fr": "Ballangen"
            },
            {
                "value": 62113,
                "label": "Åfjord",
                "label_ar": "Åfjord",
                "label_fr": "Åfjord"
            },
            {
                "value": 62196,
                "label": "Dokka",
                "label_ar": "الدقى",
                "label_fr": "Dokka"
            },
            {
                "value": 62358,
                "label": "Maura",
                "label_ar": "مورا",
                "label_fr": "Maura"
            },
            {
                "value": 62756,
                "label": "Bugøynes",
                "label_ar": "بوغوينيس",
                "label_fr": "Bugøynes"
            },
            {
                "value": 62582,
                "label": "Berge",
                "label_ar": "بيرج",
                "label_fr": "Berge"
            },
            {
                "value": 61985,
                "label": "Sortland",
                "label_ar": "سورتلاند",
                "label_fr": "Sortland"
            },
            {
                "value": 62110,
                "label": "Laksevag",
                "label_ar": "لاكسيفاج",
                "label_fr": "Laksevag"
            },
            {
                "value": 62731,
                "label": "Fedje",
                "label_ar": "Fedje",
                "label_fr": "Fedje"
            },
            {
                "value": 62508,
                "label": "Berger",
                "label_ar": "برجر",
                "label_fr": "Berger"
            },
            {
                "value": 62704,
                "label": "Hallingby",
                "label_ar": "هالينجبي",
                "label_fr": "Hallingby"
            },
            {
                "value": 62862,
                "label": "Boverfjorden",
                "label_ar": "بوفيرفيوردين",
                "label_fr": "Boverfjorden"
            },
            {
                "value": 62435,
                "label": "Kjollefjord",
                "label_ar": "Kjollefjord",
                "label_fr": "Kjollefjord"
            },
            {
                "value": 62576,
                "label": "Tveit",
                "label_ar": "تفيت",
                "label_fr": "Tveit"
            },
            {
                "value": 62123,
                "label": "Naustdal",
                "label_ar": "ناوستدال",
                "label_fr": "Naustdal"
            },
            {
                "value": 62289,
                "label": "Olen",
                "label_ar": "أولين",
                "label_fr": "Olen"
            },
            {
                "value": 62292,
                "label": "Sveio",
                "label_ar": "Sveio",
                "label_fr": "Sveio"
            },
            {
                "value": 62021,
                "label": "Stranda",
                "label_ar": "ستراندا",
                "label_fr": "Stranda"
            },
            {
                "value": 62607,
                "label": "Brevik",
                "label_ar": "بريفيك",
                "label_fr": "Brevik"
            },
            {
                "value": 62761,
                "label": "Bovagen",
                "label_ar": "بوفاجين",
                "label_fr": "Bovagen"
            },
            {
                "value": 62617,
                "label": "Skjeberg",
                "label_ar": "Skjeberg",
                "label_fr": "Skjeberg"
            },
            {
                "value": 61988,
                "label": "Dal",
                "label_ar": "دال",
                "label_fr": "Dal"
            },
            {
                "value": 62470,
                "label": "Bostad",
                "label_ar": "بوساد",
                "label_fr": "Bostad"
            },
            {
                "value": 62836,
                "label": "Nyborg",
                "label_ar": "نيبورج",
                "label_fr": "Nyborg"
            },
            {
                "value": 62467,
                "label": "Uggdal",
                "label_ar": "أوغدال",
                "label_fr": "Uggdal"
            },
            {
                "value": 62202,
                "label": "Vestre Gausdal",
                "label_ar": "فيستر جوسدال",
                "label_fr": "Vestre Gausdal"
            },
            {
                "value": 62185,
                "label": "Vennesla",
                "label_ar": "فينيسلا",
                "label_fr": "Vennesla"
            },
            {
                "value": 62353,
                "label": "Bjaland",
                "label_ar": "بجلاند",
                "label_fr": "Bjaland"
            },
            {
                "value": 62315,
                "label": "Mosby",
                "label_ar": "موسبي",
                "label_fr": "Mosby"
            },
            {
                "value": 62473,
                "label": "Kaupanger",
                "label_ar": "كاوبانجر",
                "label_fr": "Kaupanger"
            },
            {
                "value": 62401,
                "label": "Dovre",
                "label_ar": "دوفر",
                "label_fr": "Dovre"
            },
            {
                "value": 62370,
                "label": "Vaksdal",
                "label_ar": "فاكسدال",
                "label_fr": "Vaksdal"
            },
            {
                "value": 61986,
                "label": "Rygge",
                "label_ar": "Rygge",
                "label_fr": "Rygge"
            },
            {
                "value": 62352,
                "label": "Bjerkvik",
                "label_ar": "بيركفيك",
                "label_fr": "Bjerkvik"
            },
            {
                "value": 62665,
                "label": "Kvalsund",
                "label_ar": "كفالسوند",
                "label_fr": "Kvalsund"
            },
            {
                "value": 62174,
                "label": "Storebo",
                "label_ar": "ستوربو",
                "label_fr": "Storebo"
            },
            {
                "value": 62609,
                "label": "Na",
                "label_ar": "نا",
                "label_fr": "N / a"
            },
            {
                "value": 62788,
                "label": "Alen",
                "label_ar": "ألين",
                "label_fr": "Alen"
            },
            {
                "value": 62147,
                "label": "Olderdalen",
                "label_ar": "أولدردالين",
                "label_fr": "Olderdalen"
            },
            {
                "value": 62369,
                "label": "Sjovegan",
                "label_ar": "سيوفيجان",
                "label_fr": "Sjovegan"
            },
            {
                "value": 62084,
                "label": "Honefoss",
                "label_ar": "هونيفوس",
                "label_fr": "Honefoss"
            },
            {
                "value": 62737,
                "label": "Rollag",
                "label_ar": "رولاج",
                "label_fr": "Rollag"
            },
            {
                "value": 62733,
                "label": "Laksvatn",
                "label_ar": "لاكسفاتن",
                "label_fr": "Laksvatn"
            },
            {
                "value": 62083,
                "label": "Stange",
                "label_ar": "ستانج",
                "label_fr": "Stange"
            },
            {
                "value": 62449,
                "label": "Hauge",
                "label_ar": "المقياس",
                "label_fr": "Hauge"
            },
            {
                "value": 62318,
                "label": "Borhaug",
                "label_ar": "برهوغ",
                "label_fr": "Borhaug"
            },
            {
                "value": 62868,
                "label": "Rana",
                "label_ar": "رنا",
                "label_fr": "Rana"
            },
            {
                "value": 62857,
                "label": "Sondeled",
                "label_ar": "سوندليد",
                "label_fr": "Sondeled"
            },
            {
                "value": 62381,
                "label": "Tonstad",
                "label_ar": "تونستاد",
                "label_fr": "Tonstad"
            },
            {
                "value": 62461,
                "label": "Henningsvaer",
                "label_ar": "هينينجسفير",
                "label_fr": "Henningsvaer"
            },
            {
                "value": 62463,
                "label": "Leknes",
                "label_ar": "ليكنيس",
                "label_fr": "Leknes"
            },
            {
                "value": 62419,
                "label": "Kjopsvik",
                "label_ar": "كيوبسفيك",
                "label_fr": "Kjopsvik"
            },
            {
                "value": 62146,
                "label": "Birtavarre",
                "label_ar": "بيرتافاري",
                "label_fr": "Birtavarre"
            },
            {
                "value": 62754,
                "label": "Raudsand",
                "label_ar": "رودساند",
                "label_fr": "Raudsand"
            },
            {
                "value": 62468,
                "label": "Reistad",
                "label_ar": "Reistad",
                "label_fr": "Reistad"
            },
            {
                "value": 62051,
                "label": "Haukeland",
                "label_ar": "هوكلاند",
                "label_fr": "Haukeland"
            },
            {
                "value": 62230,
                "label": "Indre Klubben",
                "label_ar": "إندري كلوبن",
                "label_fr": "Indre Klubben"
            },
            {
                "value": 62377,
                "label": "Ranasfoss",
                "label_ar": "راناسفوس",
                "label_fr": "Ranasfoss"
            },
            {
                "value": 62688,
                "label": "Hundven",
                "label_ar": "Hundven",
                "label_fr": "Hundven"
            },
            {
                "value": 62386,
                "label": "Otta",
                "label_ar": "أوتا",
                "label_fr": "Otta"
            },
            {
                "value": 62379,
                "label": "Helgeroa",
                "label_ar": "هيلجيروا",
                "label_fr": "Helgeroa"
            },
            {
                "value": 62634,
                "label": "Salsbruket",
                "label_ar": "سالسبروكيت",
                "label_fr": "Salsbruket"
            },
            {
                "value": 62332,
                "label": "Erdal",
                "label_ar": "إردال",
                "label_fr": "Erdal"
            },
            {
                "value": 62376,
                "label": "Torpo",
                "label_ar": "توربو",
                "label_fr": "Torpo"
            },
            {
                "value": 62476,
                "label": "Hvitsten",
                "label_ar": "هفيتستين",
                "label_fr": "Hvitsten"
            },
            {
                "value": 62015,
                "label": "Syvik",
                "label_ar": "سيفيك",
                "label_fr": "Syvik"
            },
            {
                "value": 62010,
                "label": "Molde",
                "label_ar": "مولدي",
                "label_fr": "Molde"
            },
            {
                "value": 62527,
                "label": "Sander",
                "label_ar": "ساندر",
                "label_fr": "Ponceuse"
            },
            {
                "value": 62631,
                "label": "Spangereid",
                "label_ar": "سبانجيريد",
                "label_fr": "Spangéréide"
            },
            {
                "value": 62506,
                "label": "Tanem",
                "label_ar": "تانيم",
                "label_fr": "Tanem"
            },
            {
                "value": 62036,
                "label": "Ski",
                "label_ar": "تزلج",
                "label_fr": "Ski"
            },
            {
                "value": 62076,
                "label": "Saupstad",
                "label_ar": "سابستاد",
                "label_fr": "Saupstad"
            },
            {
                "value": 62817,
                "label": "Agskardet",
                "label_ar": "اجسكارديت",
                "label_fr": "Agskardet"
            },
            {
                "value": 62694,
                "label": "Tjotta",
                "label_ar": "تجوتا",
                "label_fr": "Tjotta"
            },
            {
                "value": 61966,
                "label": "Frogner",
                "label_ar": "فروجنر",
                "label_fr": "Frogner"
            },
            {
                "value": 62663,
                "label": "Hjelmas",
                "label_ar": "هلماس",
                "label_fr": "Hjelmas"
            },
            {
                "value": 62395,
                "label": "Rossland",
                "label_ar": "روسلاند",
                "label_fr": "Rossland"
            },
            {
                "value": 62503,
                "label": "Rennebu",
                "label_ar": "رينبو",
                "label_fr": "Rennebu"
            },
            {
                "value": 62418,
                "label": "Hellvik",
                "label_ar": "هيلفيك",
                "label_fr": "Hellvik"
            },
            {
                "value": 62253,
                "label": "Lunner",
                "label_ar": "لونر",
                "label_fr": "Lunner"
            },
            {
                "value": 62133,
                "label": "Skjervoy",
                "label_ar": "Skjervoy",
                "label_fr": "Skjervoy"
            },
            {
                "value": 62677,
                "label": "Eidsfoss",
                "label_ar": "إيدسفوس",
                "label_fr": "Eidsfoss"
            },
            {
                "value": 62422,
                "label": "Hof",
                "label_ar": "هوف",
                "label_fr": "Hof"
            },
            {
                "value": 62241,
                "label": "Andenes",
                "label_ar": "أندينس",
                "label_fr": "Andenes"
            },
            {
                "value": 62363,
                "label": "Kroderen",
                "label_ar": "كروديرين",
                "label_fr": "Kroderen"
            },
            {
                "value": 62150,
                "label": "Sorvaer",
                "label_ar": "سورفير",
                "label_fr": "Sorvaer"
            },
            {
                "value": 62045,
                "label": "Holmestrand",
                "label_ar": "هولميستراند",
                "label_fr": "Holmestrand"
            },
            {
                "value": 62562,
                "label": "Slattum",
                "label_ar": "Slattum",
                "label_fr": "Slattum"
            },
            {
                "value": 61982,
                "label": "Stokke",
                "label_ar": "ستوك",
                "label_fr": "Stokke"
            },
            {
                "value": 62504,
                "label": "Berkak",
                "label_ar": "بركاك",
                "label_fr": "Berkak"
            },
            {
                "value": 62288,
                "label": "Vedavagen",
                "label_ar": "فيدافاجين",
                "label_fr": "Vedavagen"
            },
            {
                "value": 61963,
                "label": "Karlshus",
                "label_ar": "كارلشوس",
                "label_fr": "Karlshus"
            },
            {
                "value": 62335,
                "label": "Mysen",
                "label_ar": "ميسن",
                "label_fr": "Mysen"
            },
            {
                "value": 62616,
                "label": "Torp",
                "label_ar": "تورب",
                "label_fr": "Torp"
            },
            {
                "value": 62798,
                "label": "Erfjord",
                "label_ar": "إرفجورد",
                "label_fr": "Erfjord"
            },
            {
                "value": 62024,
                "label": "Tennfjord",
                "label_ar": "تينفيورد",
                "label_fr": "Tennfjord"
            },
            {
                "value": 62543,
                "label": "Balestrand",
                "label_ar": "باليستراند",
                "label_fr": "Balestrand"
            },
            {
                "value": 62130,
                "label": "Sokndal",
                "label_ar": "سوكندال",
                "label_fr": "Sokndal"
            },
            {
                "value": 62274,
                "label": "Eiken",
                "label_ar": "أيكن",
                "label_fr": "Eiken"
            },
            {
                "value": 62726,
                "label": "Mo",
                "label_ar": "مو",
                "label_fr": "Mo"
            },
            {
                "value": 62176,
                "label": "Leirvik",
                "label_ar": "ليرفيك",
                "label_fr": "Leirvik"
            },
            {
                "value": 62649,
                "label": "Meldal",
                "label_ar": "ميلدال",
                "label_fr": "Meldal"
            },
            {
                "value": 62640,
                "label": "Sandstad",
                "label_ar": "ساندستاد",
                "label_fr": "Sandstad"
            },
            {
                "value": 62275,
                "label": "Varhaug",
                "label_ar": "فارهوغ",
                "label_fr": "Varhaug"
            },
            {
                "value": 62623,
                "label": "Bru",
                "label_ar": "برو",
                "label_fr": "Bru"
            },
            {
                "value": 62814,
                "label": "Olden",
                "label_ar": "أولدن",
                "label_fr": "Vieux"
            },
            {
                "value": 62775,
                "label": "Ankenes",
                "label_ar": "أنكينيس",
                "label_fr": "Ankenes"
            },
            {
                "value": 62661,
                "label": "Indreby",
                "label_ar": "إندربي",
                "label_fr": "Indreby"
            },
            {
                "value": 62759,
                "label": "Vevelstad",
                "label_ar": "فيفيلستاد",
                "label_fr": "Vevelstad"
            },
            {
                "value": 62782,
                "label": "Kyrkjebo",
                "label_ar": "كيركجبو",
                "label_fr": "Kyrkjebo"
            },
            {
                "value": 62629,
                "label": "Kleive",
                "label_ar": "كلايف",
                "label_fr": "Kleive"
            },
            {
                "value": 62364,
                "label": "Bruvik",
                "label_ar": "بروفيك",
                "label_fr": "Bruvik"
            },
            {
                "value": 62092,
                "label": "Krokstadelva",
                "label_ar": "كروكستاديلفا",
                "label_fr": "Krokstadelva"
            },
            {
                "value": 62534,
                "label": "Rypefjord",
                "label_ar": "Rypefjord",
                "label_fr": "Rypefjord"
            },
            {
                "value": 62131,
                "label": "Hoyanger",
                "label_ar": "هوانجر",
                "label_fr": "Hoyanger"
            },
            {
                "value": 62459,
                "label": "Gravdal",
                "label_ar": "جرافدال",
                "label_fr": "Gravdal"
            },
            {
                "value": 62816,
                "label": "Sulisjielmma",
                "label_ar": "سوليسجيلما",
                "label_fr": "Sulisjielmma"
            },
            {
                "value": 62210,
                "label": "Hov",
                "label_ar": "هوف",
                "label_fr": "Hov"
            },
            {
                "value": 62718,
                "label": "Myking",
                "label_ar": "ملكي",
                "label_fr": "Mon roi"
            },
            {
                "value": 62778,
                "label": "Lorenfallet",
                "label_ar": "Lorenfallet",
                "label_fr": "Lorenfallet"
            },
            {
                "value": 62016,
                "label": "Vestnes",
                "label_ar": "فيستنس",
                "label_fr": "Vestnes"
            },
            {
                "value": 62187,
                "label": "Naersnes",
                "label_ar": "Naersnes",
                "label_fr": "Naersnes"
            },
            {
                "value": 62095,
                "label": "Namsos",
                "label_ar": "نامسوس",
                "label_fr": "Namsos"
            },
            {
                "value": 62341,
                "label": "Straume",
                "label_ar": "ستروم",
                "label_fr": "Straume"
            },
            {
                "value": 62854,
                "label": "Lensvik",
                "label_ar": "لينسفيك",
                "label_fr": "Lensvik"
            },
            {
                "value": 62887,
                "label": "Soknedal",
                "label_ar": "سوكندال",
                "label_fr": "Soknedal"
            },
            {
                "value": 62555,
                "label": "Vevang",
                "label_ar": "فيفانغ",
                "label_fr": "Vevang"
            },
            {
                "value": 62528,
                "label": "Roverud",
                "label_ar": "روفيرود",
                "label_fr": "Roverud"
            },
            {
                "value": 62714,
                "label": "Os",
                "label_ar": "نظام التشغيل",
                "label_fr": "Os"
            },
            {
                "value": 62390,
                "label": "Fyrde",
                "label_ar": "فيرد",
                "label_fr": "Fyrde"
            },
            {
                "value": 61996,
                "label": "Finnsnes",
                "label_ar": "فينسنيس",
                "label_fr": "Finnsnes"
            },
            {
                "value": 62480,
                "label": "Birkeland",
                "label_ar": "بيركلاند",
                "label_fr": "Birkeland"
            },
            {
                "value": 62547,
                "label": "Saetre",
                "label_ar": "Saetre",
                "label_fr": "Saetre"
            },
            {
                "value": 62153,
                "label": "Varangerbotn",
                "label_ar": "فارانجيربوتن",
                "label_fr": "Varangerbotn"
            },
            {
                "value": 62256,
                "label": "Drangedal",
                "label_ar": "درانجيدال",
                "label_fr": "Drangedal"
            },
            {
                "value": 62835,
                "label": "Bjorke",
                "label_ar": "بيورك",
                "label_fr": "Bjorke"
            },
            {
                "value": 62681,
                "label": "Strusshamn",
                "label_ar": "ستروسهامن",
                "label_fr": "Strusshamn"
            },
            {
                "value": 62276,
                "label": "Royneberg",
                "label_ar": "روينبيرج",
                "label_fr": "Royneberg"
            },
            {
                "value": 62040,
                "label": "Tønsberg",
                "label_ar": "تونسبيرغ",
                "label_fr": "Tønsberg"
            },
            {
                "value": 62180,
                "label": "Amli",
                "label_ar": "أملي",
                "label_fr": "Amli"
            },
            {
                "value": 62625,
                "label": "Oresvika",
                "label_ar": "أوريسفيكا",
                "label_fr": "Oresvika"
            },
            {
                "value": 62698,
                "label": "Utskarpen",
                "label_ar": "أوتسكاربين",
                "label_fr": "Utskarpen"
            },
            {
                "value": 62702,
                "label": "Steigen",
                "label_ar": "شتايجن",
                "label_fr": "Steigen"
            },
            {
                "value": 62519,
                "label": "Sunndalsora",
                "label_ar": "سنندالسورا",
                "label_fr": "Sunndalsora"
            },
            {
                "value": 62746,
                "label": "Folkestad",
                "label_ar": "فولكستاد",
                "label_fr": "Folkestad"
            },
            {
                "value": 62632,
                "label": "Sparbu",
                "label_ar": "سباربو",
                "label_fr": "Sparbu"
            },
            {
                "value": 62612,
                "label": "Oystese",
                "label_ar": "محار",
                "label_fr": "Huître"
            },
            {
                "value": 62598,
                "label": "Brennasen",
                "label_ar": "بريناسن",
                "label_fr": "Brennasen"
            },
            {
                "value": 62053,
                "label": "Jessheim",
                "label_ar": "جيسهايم",
                "label_fr": "Jessheim"
            },
            {
                "value": 62494,
                "label": "Siggerud",
                "label_ar": "سيغيرود",
                "label_fr": "Siggerud"
            },
            {
                "value": 62774,
                "label": "Eivindvik",
                "label_ar": "إيفيندفيك",
                "label_fr": "Eivindvik"
            },
            {
                "value": 62549,
                "label": "Brandval",
                "label_ar": "براندفال",
                "label_fr": "Brandval"
            },
            {
                "value": 62362,
                "label": "Kirkenaer",
                "label_ar": "كيركينير",
                "label_fr": "Kirkenaer"
            },
            {
                "value": 62164,
                "label": "Kongsberg",
                "label_ar": "كونغسبيرغ",
                "label_fr": "Kongsberg"
            },
            {
                "value": 62626,
                "label": "Masfjorden",
                "label_ar": "ماسفيوردين",
                "label_fr": "Masfjorden"
            },
            {
                "value": 62298,
                "label": "Vikedal",
                "label_ar": "فيكيدال",
                "label_fr": "Vikedal"
            },
            {
                "value": 62238,
                "label": "Stokmarknes",
                "label_ar": "ستوكماركنس",
                "label_fr": "Stokmarknes"
            },
            {
                "value": 62521,
                "label": "Surnadalsora",
                "label_ar": "سورنادالسورا",
                "label_fr": "Surnadalsora"
            },
            {
                "value": 62574,
                "label": "Tyristrand",
                "label_ar": "تايستراند",
                "label_fr": "Tyristrand"
            },
            {
                "value": 62128,
                "label": "Raudeberg",
                "label_ar": "رودبرغ",
                "label_fr": "Raudeberg"
            },
            {
                "value": 61962,
                "label": "Jar",
                "label_ar": "إناء",
                "label_fr": "Pot"
            },
            {
                "value": 62000,
                "label": "Hovik verk",
                "label_ar": "Hovik verk",
                "label_fr": "Hovik verk"
            },
            {
                "value": 62198,
                "label": "Vingelen",
                "label_ar": "فينجلين",
                "label_fr": "Vingelen"
            },
            {
                "value": 62115,
                "label": "Nesttun",
                "label_ar": "نستون",
                "label_fr": "Nesttun"
            },
            {
                "value": 62383,
                "label": "Nordby Bruk",
                "label_ar": "نوردبي بروك",
                "label_fr": "Nordby Bruk"
            },
            {
                "value": 62320,
                "label": "Fiskum",
                "label_ar": "فيسكوم",
                "label_fr": "Fiskum"
            },
            {
                "value": 62100,
                "label": "Al",
                "label_ar": "ال",
                "label_fr": "Al"
            },
            {
                "value": 62254,
                "label": "Jaren",
                "label_ar": "جارين",
                "label_fr": "Jaren"
            },
            {
                "value": 62571,
                "label": "Foldereid",
                "label_ar": "فولديريد",
                "label_fr": "Foldereid"
            },
            {
                "value": 62330,
                "label": "Melhus",
                "label_ar": "ميلهوس",
                "label_fr": "Melhus"
            },
            {
                "value": 62455,
                "label": "Roa",
                "label_ar": "روا",
                "label_fr": "Roa"
            },
            {
                "value": 62165,
                "label": "Vatne",
                "label_ar": "فاتني",
                "label_fr": "Vatne"
            },
            {
                "value": 62606,
                "label": "Eidsvag",
                "label_ar": "إيدسفاغ",
                "label_fr": "Eidsvag"
            },
            {
                "value": 62660,
                "label": "Malm",
                "label_ar": "مالم",
                "label_fr": "Malm"
            },
            {
                "value": 62558,
                "label": "Neverdal",
                "label_ar": "نيفرديل",
                "label_fr": "Neverdal"
            },
            {
                "value": 61972,
                "label": "Sarpsborg",
                "label_ar": "ساربسبورج",
                "label_fr": "Sarpsborg"
            },
            {
                "value": 62085,
                "label": "Jevnaker",
                "label_ar": "جيفناكر",
                "label_fr": "Jevnaker"
            },
            {
                "value": 62239,
                "label": "Melbu",
                "label_ar": "ميلبو",
                "label_fr": "Melbu"
            },
            {
                "value": 61969,
                "label": "Aursmoen",
                "label_ar": "أورسمون",
                "label_fr": "Aursmoen"
            },
            {
                "value": 62764,
                "label": "Lepsoy",
                "label_ar": "Lepsoy",
                "label_fr": "Lepsoy"
            },
            {
                "value": 62340,
                "label": "Klofta",
                "label_ar": "كلوفتة",
                "label_fr": "Klofta"
            },
            {
                "value": 62524,
                "label": "Enga",
                "label_ar": "إنجا",
                "label_fr": "Enga"
            },
            {
                "value": 62575,
                "label": "Valle",
                "label_ar": "فالي",
                "label_fr": "Valle"
            },
            {
                "value": 62018,
                "label": "Ulsteinvik",
                "label_ar": "أولشتاينفيك",
                "label_fr": "Ulsteinvik"
            },
            {
                "value": 62312,
                "label": "Sirevag",
                "label_ar": "Sirevag",
                "label_fr": "Sirevag"
            },
            {
                "value": 62420,
                "label": "Skutvika",
                "label_ar": "سكوتفيكا",
                "label_fr": "Skutvika"
            },
            {
                "value": 62650,
                "label": "Adalsbruk",
                "label_ar": "Adalsbruk",
                "label_fr": "Adalsbruk"
            },
            {
                "value": 62121,
                "label": "Bygstad",
                "label_ar": "بيغستاد",
                "label_fr": "Bygstad"
            },
            {
                "value": 62863,
                "label": "Lenvik",
                "label_ar": "لينفيك",
                "label_fr": "Lenvik"
            },
            {
                "value": 62026,
                "label": "Kristiansund",
                "label_ar": "كريستيانسوند",
                "label_fr": "Kristiansund"
            },
            {
                "value": 62479,
                "label": "Faervik",
                "label_ar": "فيرفيك",
                "label_fr": "Faervik"
            },
            {
                "value": 62587,
                "label": "Godoy",
                "label_ar": "جودوي",
                "label_fr": "Godoy"
            },
            {
                "value": 61980,
                "label": "Skarnes",
                "label_ar": "سكارنس",
                "label_fr": "Skarnes"
            },
            {
                "value": 62844,
                "label": "From",
                "label_ar": "من عند",
                "label_fr": "De"
            },
            {
                "value": 62824,
                "label": "Larkollen",
                "label_ar": "لاركولين",
                "label_fr": "Larkollen"
            },
            {
                "value": 62728,
                "label": "Holmefjord",
                "label_ar": "هولمفيورد",
                "label_fr": "Holmefjord"
            },
            {
                "value": 62768,
                "label": "Roros",
                "label_ar": "روروس",
                "label_fr": "Roros"
            },
            {
                "value": 62043,
                "label": "Tertnes",
                "label_ar": "ترتنس",
                "label_fr": "Tertnes"
            },
            {
                "value": 62039,
                "label": "Fredrikstad",
                "label_ar": "فريدريكستاد",
                "label_fr": "Fredrikstad"
            },
            {
                "value": 62496,
                "label": "Evanger",
                "label_ar": "إيفانجر",
                "label_fr": "Evanger"
            },
            {
                "value": 62511,
                "label": "Austbygdi",
                "label_ar": "أوستبيغدي",
                "label_fr": "Austbygdi"
            },
            {
                "value": 62094,
                "label": "Heggedal",
                "label_ar": "هيجيدال",
                "label_fr": "Heggedal"
            },
            {
                "value": 62360,
                "label": "Auli",
                "label_ar": "اولي",
                "label_fr": "Auli"
            },
            {
                "value": 62839,
                "label": "Ask",
                "label_ar": "يطلب",
                "label_fr": "Demander"
            },
            {
                "value": 62597,
                "label": "Kjerstad",
                "label_ar": "كجيرستاد",
                "label_fr": "Kjerstad"
            },
            {
                "value": 62199,
                "label": "Heggenes",
                "label_ar": "هيجينز",
                "label_fr": "Heggenes"
            },
            {
                "value": 62800,
                "label": "Lauvsnes",
                "label_ar": "لوفسنيس",
                "label_fr": "Lauvsnes"
            },
            {
                "value": 62057,
                "label": "Langhus",
                "label_ar": "لانغوس",
                "label_fr": "Langhus"
            },
            {
                "value": 62291,
                "label": "Akrehamn",
                "label_ar": "أكريهامن",
                "label_fr": "Akrehamn"
            },
            {
                "value": 62446,
                "label": "Skjold",
                "label_ar": "Skjold",
                "label_fr": "Skjold"
            },
            {
                "value": 62143,
                "label": "Ivgobahta",
                "label_ar": "Ivgobahta",
                "label_fr": "Ivgobahta"
            },
            {
                "value": 62770,
                "label": "Vormsund",
                "label_ar": "فورمسوند",
                "label_fr": "Vormsund"
            },
            {
                "value": 62014,
                "label": "Langevag",
                "label_ar": "لانجفاج",
                "label_fr": "Langevag"
            },
            {
                "value": 62103,
                "label": "Sagvag",
                "label_ar": "ساجفاج",
                "label_fr": "Sagvag"
            },
            {
                "value": 62033,
                "label": "Harstad",
                "label_ar": "هارستاد",
                "label_fr": "Harstad"
            },
            {
                "value": 62417,
                "label": "Hyllestad",
                "label_ar": "هايليستاد",
                "label_fr": "Hyllestad"
            },
            {
                "value": 62031,
                "label": "Magnor",
                "label_ar": "ماغنور",
                "label_fr": "Magnor"
            },
            {
                "value": 62116,
                "label": "Langesund",
                "label_ar": "لانغيسوند",
                "label_fr": "Langesund"
            },
            {
                "value": 62755,
                "label": "Vardø",
                "label_ar": "فاردو",
                "label_fr": "Vardø"
            },
            {
                "value": 62570,
                "label": "Salsnes",
                "label_ar": "سالسنيس",
                "label_fr": "Salsnes"
            },
            {
                "value": 62038,
                "label": "Sandefjord",
                "label_ar": "ساندفيورد",
                "label_fr": "Sandefjord"
            },
            {
                "value": 62554,
                "label": "Tofte",
                "label_ar": "توفت",
                "label_fr": "Tofte"
            },
            {
                "value": 62595,
                "label": "Lindas",
                "label_ar": "لينداس",
                "label_fr": "Lindas"
            },
            {
                "value": 62701,
                "label": "Noresund",
                "label_ar": "نورسوند",
                "label_fr": "Noresund"
            },
            {
                "value": 62818,
                "label": "Ornes",
                "label_ar": "أورنس",
                "label_fr": "Ornes"
            },
            {
                "value": 62564,
                "label": "Bagn",
                "label_ar": "باجن",
                "label_fr": "Bagn"
            },
            {
                "value": 62741,
                "label": "Flesnes",
                "label_ar": "فليسنيس",
                "label_fr": "Flesnes"
            },
            {
                "value": 62316,
                "label": "Fossdalen",
                "label_ar": "فوسدالين",
                "label_fr": "Fossdalen"
            },
            {
                "value": 61992,
                "label": "Trondheim",
                "label_ar": "تروندهايم",
                "label_fr": "Trondheim"
            },
            {
                "value": 62682,
                "label": "Indre Billefjord",
                "label_ar": "إندري بيلفيورد",
                "label_fr": "Indre Billefjord"
            },
            {
                "value": 62481,
                "label": "Fevik",
                "label_ar": "فيفيك",
                "label_fr": "Fevik"
            },
            {
                "value": 62200,
                "label": "Ringebu",
                "label_ar": "Ringebu",
                "label_fr": "Ringebu"
            },
            {
                "value": 62507,
                "label": "Rud",
                "label_ar": "رود",
                "label_fr": "Rud"
            },
            {
                "value": 62262,
                "label": "Rubbestadneset",
                "label_ar": "Rubbestadneset",
                "label_fr": "Rubbestadneset"
            },
            {
                "value": 62579,
                "label": "Uskedalen",
                "label_ar": "أوسكيدالين",
                "label_fr": "Uskedalen"
            },
            {
                "value": 62783,
                "label": "Kvammen",
                "label_ar": "كفامن",
                "label_fr": "Kvammen"
            },
            {
                "value": 62807,
                "label": "Blaker",
                "label_ar": "بليكر",
                "label_fr": "Blaker"
            },
            {
                "value": 62876,
                "label": "Fiska",
                "label_ar": "فيسكا",
                "label_fr": "Fiska"
            },
            {
                "value": 62263,
                "label": "Vestra Mosterhamn",
                "label_ar": "فيسترا موسترهامن",
                "label_fr": "Vestra Mosterhamn"
            },
            {
                "value": 62691,
                "label": "Sperrebotn",
                "label_ar": "سبيربوتن",
                "label_fr": "Sperrebotn"
            },
            {
                "value": 62009,
                "label": "Ålesund",
                "label_ar": "أوليسوند",
                "label_fr": "Ålesund"
            },
            {
                "value": 62304,
                "label": "Hokksund",
                "label_ar": "هوكسوند",
                "label_fr": "Hokksund"
            },
            {
                "value": 62720,
                "label": "Hovet",
                "label_ar": "هوفيت",
                "label_fr": "Hovet"
            },
            {
                "value": 62284,
                "label": "Gratangen",
                "label_ar": "جراتانجين",
                "label_fr": "Gratangen"
            },
            {
                "value": 62514,
                "label": "Hestvika",
                "label_ar": "هيستفيكا",
                "label_fr": "Hestvika"
            },
            {
                "value": 62760,
                "label": "Loen",
                "label_ar": "لوين",
                "label_fr": "Loen"
            },
            {
                "value": 62790,
                "label": "Forde",
                "label_ar": "فوردي",
                "label_fr": "Forde"
            },
            {
                "value": 62769,
                "label": "Malvik",
                "label_ar": "مالفيك",
                "label_fr": "Malvik"
            },
            {
                "value": 61977,
                "label": "Horten",
                "label_ar": "هورتن",
                "label_fr": "Horten"
            },
            {
                "value": 61978,
                "label": "Lysaker",
                "label_ar": "ليسكر",
                "label_fr": "Lysaker"
            },
            {
                "value": 62492,
                "label": "Innbygda",
                "label_ar": "إنبيجدا",
                "label_fr": "Innbygda"
            },
            {
                "value": 61974,
                "label": "Asgardstrand",
                "label_ar": "Asgardstrand",
                "label_fr": "Asgardstrand"
            },
            {
                "value": 62345,
                "label": "Vollen",
                "label_ar": "فولن",
                "label_fr": "Vollen"
            },
            {
                "value": 62346,
                "label": "Ikornnes",
                "label_ar": "إيكورنيس",
                "label_fr": "Ikornnes"
            },
            {
                "value": 62478,
                "label": "Nedenes",
                "label_ar": "ندينيس",
                "label_fr": "Nedenes"
            },
            {
                "value": 62613,
                "label": "Herand",
                "label_ar": "هيراند",
                "label_fr": "Elle et"
            },
            {
                "value": 62425,
                "label": "Hornnes",
                "label_ar": "هورنز",
                "label_fr": "Hornnes"
            },
            {
                "value": 62160,
                "label": "Strommen",
                "label_ar": "سترومن",
                "label_fr": "Strommen"
            },
            {
                "value": 62132,
                "label": "Storslett",
                "label_ar": "ستورسليت",
                "label_fr": "Storslett"
            },
            {
                "value": 61971,
                "label": "Bergen",
                "label_ar": "بيرغن",
                "label_fr": "Bergen"
            },
            {
                "value": 62471,
                "label": "Gvarv",
                "label_ar": "جفارف",
                "label_fr": "Gvarv"
            },
            {
                "value": 62106,
                "label": "Hell",
                "label_ar": "الجحيم",
                "label_fr": "Enfer"
            },
            {
                "value": 62483,
                "label": "Rong",
                "label_ar": "رونغ",
                "label_fr": "Rong"
            },
            {
                "value": 62871,
                "label": "Kornsjo",
                "label_ar": "كورنسجو",
                "label_fr": "Kornsjo"
            },
            {
                "value": 62161,
                "label": "Kopervik",
                "label_ar": "كوبيرفيك",
                "label_fr": "Kopervik"
            },
            {
                "value": 62439,
                "label": "Fitjar",
                "label_ar": "فيتجار",
                "label_fr": "Fitjar"
            },
            {
                "value": 62400,
                "label": "Lalm",
                "label_ar": "لالم",
                "label_fr": "Lalm"
            },
            {
                "value": 62879,
                "label": "Hjorungavag",
                "label_ar": "هجورونجافاج",
                "label_fr": "Hjorungavag"
            },
            {
                "value": 62306,
                "label": "Ulefoss",
                "label_ar": "أوليفوس",
                "label_fr": "Ulefoss"
            },
            {
                "value": 62303,
                "label": "Notodden",
                "label_ar": "نوتودين",
                "label_fr": "Notodden"
            },
            {
                "value": 62865,
                "label": "Hildre",
                "label_ar": "هيلدر",
                "label_fr": "Hildre"
            },
            {
                "value": 62553,
                "label": "Hitra",
                "label_ar": "هيترا",
                "label_fr": "Hitra"
            },
            {
                "value": 62647,
                "label": "Herre",
                "label_ar": "هيري",
                "label_fr": "Herre"
            },
            {
                "value": 62177,
                "label": "Mosjoen",
                "label_ar": "Mosjoen",
                "label_fr": "Mosjoen"
            },
            {
                "value": 61994,
                "label": "Fornebu",
                "label_ar": "فورنبو",
                "label_fr": "Fornebu"
            },
            {
                "value": 62715,
                "label": "Tolga",
                "label_ar": "تولجا",
                "label_fr": "Tolga"
            },
            {
                "value": 62500,
                "label": "Skaun",
                "label_ar": "سكون",
                "label_fr": "Skaun"
            },
            {
                "value": 62047,
                "label": "Bodø",
                "label_ar": "بودو",
                "label_fr": "Bodø"
            },
            {
                "value": 62152,
                "label": "Talvik",
                "label_ar": "تالفيك",
                "label_fr": "Talvik"
            },
            {
                "value": 62413,
                "label": "Myre",
                "label_ar": "بلدي إعادة",
                "label_fr": "Myre"
            },
            {
                "value": 62680,
                "label": "Aros",
                "label_ar": "عروس",
                "label_fr": "Aros"
            },
            {
                "value": 62124,
                "label": "Vassenden",
                "label_ar": "فاسيندين",
                "label_fr": "Vassenden"
            },
            {
                "value": 62740,
                "label": "Lauvstad",
                "label_ar": "لوفستاد",
                "label_fr": "Lauvstad"
            },
            {
                "value": 62361,
                "label": "Austmarka",
                "label_ar": "أوستماركا",
                "label_fr": "Austmarka"
            },
            {
                "value": 62859,
                "label": "Kaldfarnes",
                "label_ar": "كالدفارنيس",
                "label_fr": "Kaldfarnes"
            },
            {
                "value": 62710,
                "label": "Leines",
                "label_ar": "لين",
                "label_fr": "Leines"
            },
            {
                "value": 61975,
                "label": "Kolbotn",
                "label_ar": "كولبوتن",
                "label_fr": "Kolbotn"
            },
            {
                "value": 62236,
                "label": "Fosser",
                "label_ar": "فوسر",
                "label_fr": "Fosser"
            },
            {
                "value": 62633,
                "label": "Asen",
                "label_ar": "آسين",
                "label_fr": "Asen"
            },
            {
                "value": 62540,
                "label": "Follese",
                "label_ar": "فولي",
                "label_fr": "Follese"
            },
            {
                "value": 62621,
                "label": "Vikesa",
                "label_ar": "فيكيسا",
                "label_fr": "Vikesa"
            },
            {
                "value": 62374,
                "label": "Kvelde",
                "label_ar": "كفيلدي",
                "label_fr": "Kvelde"
            },
            {
                "value": 62856,
                "label": "Selasvatn",
                "label_ar": "سيلاسفاتن",
                "label_fr": "Selasvatn"
            },
            {
                "value": 62004,
                "label": "Gjøvik",
                "label_ar": "Gjøvik",
                "label_fr": "Gjøvik"
            },
            {
                "value": 62766,
                "label": "Ytre Alvik",
                "label_ar": "Ytre Alvik",
                "label_fr": "Ytre Alvik"
            },
            {
                "value": 62019,
                "label": "Eide",
                "label_ar": "إيدي",
                "label_fr": "Eide"
            },
            {
                "value": 62533,
                "label": "Ardalstangen",
                "label_ar": "أردالستانجين",
                "label_fr": "Ardalstangen"
            },
            {
                "value": 62344,
                "label": "Agotnes",
                "label_ar": "أغوتنيس",
                "label_fr": "Agotnes"
            },
            {
                "value": 62605,
                "label": "Hemnskjela",
                "label_ar": "هيمنسجيلا",
                "label_fr": "Hemnskjela"
            },
            {
                "value": 62114,
                "label": "Son",
                "label_ar": "ابن",
                "label_fr": "Fils"
            },
            {
                "value": 62532,
                "label": "Fardal",
                "label_ar": "فاردال",
                "label_fr": "Fardal"
            },
            {
                "value": 62561,
                "label": "Vadheim",
                "label_ar": "فادهايم",
                "label_fr": "Vadheim"
            },
            {
                "value": 62639,
                "label": "Oksfjord",
                "label_ar": "أوكسفجورد",
                "label_fr": "Oksfjord"
            },
            {
                "value": 62227,
                "label": "Kvisvik",
                "label_ar": "Kvisvik",
                "label_fr": "Kvisvik"
            },
            {
                "value": 62255,
                "label": "Felle",
                "label_ar": "فيلي",
                "label_fr": "Felle"
            },
            {
                "value": 61998,
                "label": "Tromsø",
                "label_ar": "ترومسو",
                "label_fr": "Tromsø"
            },
            {
                "value": 62803,
                "label": "Disena",
                "label_ar": "ديسينا",
                "label_fr": "Disena"
            },
            {
                "value": 62398,
                "label": "Tovik",
                "label_ar": "توفيك",
                "label_fr": "Tovik"
            },
            {
                "value": 62433,
                "label": "Kjellmyra",
                "label_ar": "كجيلميرا",
                "label_fr": "Kjellmyra"
            },
            {
                "value": 62431,
                "label": "Venabygd",
                "label_ar": "فينابيجد",
                "label_fr": "Venabygd"
            },
            {
                "value": 62107,
                "label": "Hegra",
                "label_ar": "هجر",
                "label_fr": "Hegra"
            },
            {
                "value": 62719,
                "label": "Askøy",
                "label_ar": "اسأل",
                "label_fr": "Askøy"
            },
            {
                "value": 62219,
                "label": "Lakselv",
                "label_ar": "لاكسلف",
                "label_fr": "Lakselv"
            },
            {
                "value": 61991,
                "label": "Elverum",
                "label_ar": "Elverum",
                "label_fr": "Elverum"
            },
            {
                "value": 61970,
                "label": "Tranby",
                "label_ar": "ترانبي",
                "label_fr": "Tranby"
            },
            {
                "value": 62603,
                "label": "Trones",
                "label_ar": "ترون",
                "label_fr": "Trones"
            },
            {
                "value": 62069,
                "label": "Kristiansand",
                "label_ar": "كريستيانساند",
                "label_fr": "Kristiansand"
            },
            {
                "value": 62652,
                "label": "Digermulen",
                "label_ar": "Digermulen",
                "label_fr": "Digermulen"
            },
            {
                "value": 62087,
                "label": "Hunndalen",
                "label_ar": "هوندالين",
                "label_fr": "Hunndalen"
            },
            {
                "value": 62391,
                "label": "Davik",
                "label_ar": "دافيك",
                "label_fr": "Davik"
            },
            {
                "value": 62098,
                "label": "Kyrksaeterora",
                "label_ar": "Kyrksaeterora",
                "label_fr": "Kyrksaeterora"
            },
            {
                "value": 62158,
                "label": "Lyngdal",
                "label_ar": "لينجدال",
                "label_fr": "Lyngdal"
            },
            {
                "value": 62247,
                "label": "Leira",
                "label_ar": "ليرا",
                "label_fr": "Leira"
            },
            {
                "value": 62006,
                "label": "Hol",
                "label_ar": "هول",
                "label_fr": "Hol"
            },
            {
                "value": 62692,
                "label": "Haltdalen",
                "label_ar": "هلتدلين",
                "label_fr": "Haltdalen"
            },
            {
                "value": 62217,
                "label": "Alta",
                "label_ar": "ألتا",
                "label_fr": "Alta"
            },
            {
                "value": 62883,
                "label": "Buvika",
                "label_ar": "بوفيكا",
                "label_fr": "Buvika"
            },
            {
                "value": 62608,
                "label": "Eidfjord",
                "label_ar": "إيدفيورد",
                "label_fr": "Eidfjord"
            },
            {
                "value": 62234,
                "label": "Loken",
                "label_ar": "لوكن",
                "label_fr": "Loken"
            },
            {
                "value": 62889,
                "label": "Skeie",
                "label_ar": "سكي",
                "label_fr": "Skeie"
            },
            {
                "value": 62226,
                "label": "Batnfjordsora",
                "label_ar": "باتنفجوردسورا",
                "label_fr": "Batnfjordsora"
            },
            {
                "value": 62565,
                "label": "Aurdal",
                "label_ar": "أوردال",
                "label_fr": "Aurdal"
            },
            {
                "value": 62542,
                "label": "Vangsvika",
                "label_ar": "فانجسفيكا",
                "label_fr": "Vangsvika"
            },
            {
                "value": 62297,
                "label": "Kleppe",
                "label_ar": "كليبي",
                "label_fr": "Kleppe"
            },
            {
                "value": 62848,
                "label": "Nyhus",
                "label_ar": "نيوس",
                "label_fr": "Nyhus"
            },
            {
                "value": 62020,
                "label": "Hareid",
                "label_ar": "حريد",
                "label_fr": "Hareid"
            },
            {
                "value": 61967,
                "label": "Drammen",
                "label_ar": "درامن",
                "label_fr": "Drammen"
            },
            {
                "value": 61965,
                "label": "Oslo",
                "label_ar": "أوسلو",
                "label_fr": "Oslo"
            },
            {
                "value": 62657,
                "label": "Sund",
                "label_ar": "سوند",
                "label_fr": "Sund"
            },
            {
                "value": 62282,
                "label": "Evenskjer",
                "label_ar": "Evenskjer",
                "label_fr": "Evenskjer"
            },
            {
                "value": 62299,
                "label": "Odda",
                "label_ar": "أودا",
                "label_fr": "Odda"
            },
            {
                "value": 62619,
                "label": "Borgen",
                "label_ar": "بورجن",
                "label_fr": "Borgen"
            },
            {
                "value": 62328,
                "label": "Myre",
                "label_ar": "بلدي إعادة",
                "label_fr": "Myre"
            },
            {
                "value": 62700,
                "label": "Buskerud",
                "label_ar": "بوسكيرود",
                "label_fr": "Buskerud"
            },
            {
                "value": 62630,
                "label": "Lovund",
                "label_ar": "لوفوند",
                "label_fr": "Lovund"
            },
            {
                "value": 62056,
                "label": "Asker",
                "label_ar": "السائل",
                "label_fr": "Demandeur"
            },
            {
                "value": 62090,
                "label": "Slemmestad",
                "label_ar": "سليمستاد",
                "label_fr": "Slemmestad"
            },
            {
                "value": 62744,
                "label": "Stamnes",
                "label_ar": "ستامنيس",
                "label_fr": "Stamnes"
            },
            {
                "value": 62075,
                "label": "Sorumsand",
                "label_ar": "سورومساند",
                "label_fr": "Sorumsand"
            },
            {
                "value": 62231,
                "label": "Sandnes",
                "label_ar": "ساندنس",
                "label_fr": "Sandnes"
            },
            {
                "value": 62194,
                "label": "Lena",
                "label_ar": "لينا",
                "label_fr": "Lena"
            },
            {
                "value": 62067,
                "label": "Frekhaug",
                "label_ar": "Frekhaug",
                "label_fr": "Frekhaug"
            },
            {
                "value": 62529,
                "label": "Knapper",
                "label_ar": "كنابر",
                "label_fr": "Knapper"
            },
            {
                "value": 62866,
                "label": "Fotlandsvag",
                "label_ar": "فوتلاندسفاغ",
                "label_fr": "Fotlandsvag"
            },
            {
                "value": 62281,
                "label": "Kongsvika",
                "label_ar": "كونغسفيكا",
                "label_fr": "Kongsvika"
            },
            {
                "value": 62495,
                "label": "Bjoneroa",
                "label_ar": "بيونيروا",
                "label_fr": "Bjoneroa"
            },
            {
                "value": 62271,
                "label": "Flekkefjord",
                "label_ar": "فليكيفجورد",
                "label_fr": "Flekkefjord"
            },
            {
                "value": 62287,
                "label": "Skudeneshavn",
                "label_ar": "Skudeneshavn",
                "label_fr": "Skudeneshavn"
            },
            {
                "value": 62465,
                "label": "Soreidgrenda",
                "label_ar": "سوريجريندا",
                "label_fr": "Soreidgrenda"
            },
            {
                "value": 62834,
                "label": "Spillum",
                "label_ar": "Spillum",
                "label_fr": "Spillum"
            },
            {
                "value": 62676,
                "label": "Hetlevik",
                "label_ar": "Hetlevik",
                "label_fr": "Hetlevik"
            },
            {
                "value": 62513,
                "label": "Eidsvag",
                "label_ar": "إيدسفاغ",
                "label_fr": "Eidsvag"
            },
            {
                "value": 62208,
                "label": "Eina",
                "label_ar": "اينا",
                "label_fr": "Eina"
            },
            {
                "value": 62319,
                "label": "Ovrebo",
                "label_ar": "أوفريبو",
                "label_fr": "Ovrebo"
            },
            {
                "value": 62406,
                "label": "Kvam",
                "label_ar": "كفام",
                "label_fr": "Kvam"
            },
            {
                "value": 62206,
                "label": "Sor-Fron",
                "label_ar": "سور فرون",
                "label_fr": "Sor-Fron"
            },
            {
                "value": 62749,
                "label": "Valsoyfjord",
                "label_ar": "فالسويفجورد",
                "label_fr": "Valsoyfjord"
            },
            {
                "value": 62585,
                "label": "Dimmelsvik",
                "label_ar": "ديملسفيك",
                "label_fr": "Dimmelsvik"
            },
            {
                "value": 62178,
                "label": "Loddefjord",
                "label_ar": "لودديفجورد",
                "label_fr": "Loddefjord"
            },
            {
                "value": 62162,
                "label": "Orje",
                "label_ar": "أورجي",
                "label_fr": "Orje"
            },
            {
                "value": 62664,
                "label": "Valestrand",
                "label_ar": "فالستراند",
                "label_fr": "Valestrand"
            },
            {
                "value": 62550,
                "label": "Arneberg",
                "label_ar": "أرنيبرغ",
                "label_fr": "Arneberg"
            },
            {
                "value": 62517,
                "label": "Gjolme",
                "label_ar": "غولمي",
                "label_fr": "Gjolme"
            },
            {
                "value": 62427,
                "label": "Staubo",
                "label_ar": "ستوبو",
                "label_fr": "Staubo"
            },
            {
                "value": 62424,
                "label": "Trengereid",
                "label_ar": "ترينجريد",
                "label_fr": "Trengereid"
            },
            {
                "value": 62111,
                "label": "Spydeberg",
                "label_ar": "سبايدبرغ",
                "label_fr": "Spydeberg"
            },
            {
                "value": 62668,
                "label": "Fyresdal",
                "label_ar": "فيريسدال",
                "label_fr": "Fyresdal"
            },
            {
                "value": 62029,
                "label": "Skotterud",
                "label_ar": "سكوترود",
                "label_fr": "Skotterud"
            },
            {
                "value": 62658,
                "label": "Ulvagen",
                "label_ar": "أولفاجين",
                "label_fr": "Ulvagen"
            },
            {
                "value": 62725,
                "label": "Nordstrono",
                "label_ar": "نوردسترونو",
                "label_fr": "Nordstrono"
            },
            {
                "value": 62491,
                "label": "Alvdal",
                "label_ar": "الفضال",
                "label_fr": "Alvdal"
            },
            {
                "value": 62551,
                "label": "Misje",
                "label_ar": "ميسجي",
                "label_fr": "Misje"
            },
            {
                "value": 62501,
                "label": "Eggkleiva",
                "label_ar": "Eggkleiva",
                "label_fr": "Eggkleiva"
            },
            {
                "value": 62195,
                "label": "Eidsvoll",
                "label_ar": "إيدسفول",
                "label_fr": "Eidsvoll"
            },
            {
                "value": 62643,
                "label": "Longva",
                "label_ar": "لونجفا",
                "label_fr": "Longva"
            },
            {
                "value": 62763,
                "label": "Rokland",
                "label_ar": "روكلاند",
                "label_fr": "Rokland"
            },
            {
                "value": 62509,
                "label": "Barkaker",
                "label_ar": "بارككر",
                "label_fr": "Barkaker"
            },
            {
                "value": 62409,
                "label": "Heroysund",
                "label_ar": "هيرويسوند",
                "label_fr": "Heroysund"
            },
            {
                "value": 62568,
                "label": "Beitostolen",
                "label_ar": "بيتوستولين",
                "label_fr": "Beitostolen"
            },
            {
                "value": 62622,
                "label": "Vigrestad",
                "label_ar": "فيجريستاد",
                "label_fr": "Vigrestad"
            },
            {
                "value": 62810,
                "label": "Dale",
                "label_ar": "دايل",
                "label_fr": "Vallée"
            },
            {
                "value": 62821,
                "label": "Tjong",
                "label_ar": "Tjong",
                "label_fr": "Tjong"
            },
            {
                "value": 62703,
                "label": "Kongshamn",
                "label_ar": "كونغسهامن",
                "label_fr": "Kongshamn"
            },
            {
                "value": 62148,
                "label": "Kautokeino",
                "label_ar": "كاوتوكينو",
                "label_fr": "Kautokeino"
            },
            {
                "value": 62799,
                "label": "Hovag",
                "label_ar": "هوفاج",
                "label_fr": "Hovag"
            },
            {
                "value": 62765,
                "label": "Beisfjord",
                "label_ar": "بيسفيورد",
                "label_fr": "Beisfjord"
            },
            {
                "value": 61984,
                "label": "Askim",
                "label_ar": "اسكيم",
                "label_fr": "Askim"
            },
            {
                "value": 62456,
                "label": "Finnoy",
                "label_ar": "فينوي",
                "label_fr": "Finnoy"
            },
            {
                "value": 62258,
                "label": "Sannidal",
                "label_ar": "سنيدال",
                "label_fr": "Sannidal"
            },
            {
                "value": 62209,
                "label": "Follebu",
                "label_ar": "فوليبو",
                "label_fr": "Follebu"
            },
            {
                "value": 62536,
                "label": "Ovre Ardal",
                "label_ar": "أوفر أردال",
                "label_fr": "Ovre Ardal"
            },
            {
                "value": 62732,
                "label": "Vannvag",
                "label_ar": "فانفاج",
                "label_fr": "Vannvag"
            },
            {
                "value": 62572,
                "label": "Abelvaer",
                "label_ar": "أبيلفاير",
                "label_fr": "Abelvaer"
            },
            {
                "value": 62693,
                "label": "Korsvegen",
                "label_ar": "كورسفين",
                "label_fr": "Korsvegen"
            },
            {
                "value": 62102,
                "label": "Nesbyen",
                "label_ar": "نسبين",
                "label_fr": "Nesbyen"
            },
            {
                "value": 62280,
                "label": "Sola",
                "label_ar": "سولا",
                "label_fr": "Sola"
            },
            {
                "value": 62584,
                "label": "Hatlestranda",
                "label_ar": "هاتليستراندا",
                "label_fr": "Hatlestranda"
            },
            {
                "value": 62752,
                "label": "Meisingset",
                "label_ar": "Meisingset",
                "label_fr": "Meisingset"
            },
            {
                "value": 62421,
                "label": "Nykirke",
                "label_ar": "نيكيرك",
                "label_fr": "Nykirke"
            },
            {
                "value": 62594,
                "label": "Rykene",
                "label_ar": "ريكيني",
                "label_fr": "Rykene"
            },
            {
                "value": 62151,
                "label": "Akkarfjord",
                "label_ar": "عكار فيورد",
                "label_fr": "Akkarfjord"
            },
            {
                "value": 62786,
                "label": "Nesgrenda",
                "label_ar": "نسجريندا",
                "label_fr": "Nesgrenda"
            },
            {
                "value": 62372,
                "label": "Holter",
                "label_ar": "هولتر",
                "label_fr": "Holter"
            },
            {
                "value": 62882,
                "label": "Slemdal",
                "label_ar": "سليمدال",
                "label_fr": "Slemdal"
            },
            {
                "value": 62207,
                "label": "Bruflat",
                "label_ar": "Bruflat",
                "label_fr": "Bruflat"
            },
            {
                "value": 62581,
                "label": "Rosendal",
                "label_ar": "روزندال",
                "label_fr": "Rosendal"
            },
            {
                "value": 62820,
                "label": "Skjerstad",
                "label_ar": "Skjerstad",
                "label_fr": "Skjerstad"
            },
            {
                "value": 62785,
                "label": "Korgen",
                "label_ar": "كورجن",
                "label_fr": "Korgen"
            },
            {
                "value": 62154,
                "label": "Evje",
                "label_ar": "إيفجي",
                "label_fr": "Evje"
            },
            {
                "value": 62058,
                "label": "Stavern",
                "label_ar": "ستافرن",
                "label_fr": "Stavern"
            },
            {
                "value": 62566,
                "label": "Tjøme",
                "label_ar": "تجومي",
                "label_fr": "Tjøme"
            },
            {
                "value": 62559,
                "label": "Selvaer",
                "label_ar": "سيلفاير",
                "label_fr": "Selvaer"
            },
            {
                "value": 62214,
                "label": "Fauske",
                "label_ar": "فوسكي",
                "label_fr": "Fauske"
            },
            {
                "value": 62699,
                "label": "Eydehavn",
                "label_ar": "إيدهافن",
                "label_fr": "Eydehavn"
            },
            {
                "value": 62050,
                "label": "Saltnes",
                "label_ar": "سالتنس",
                "label_fr": "Saltnes"
            },
            {
                "value": 62784,
                "label": "Gransherad",
                "label_ar": "غرانشراد",
                "label_fr": "Gransherad"
            },
            {
                "value": 62758,
                "label": "Svelgen",
                "label_ar": "سفيلجن",
                "label_fr": "Svelgen"
            },
            {
                "value": 62611,
                "label": "Norheimsund",
                "label_ar": "نورهايمسوند",
                "label_fr": "Norheimsund"
            },
            {
                "value": 62108,
                "label": "Stavanger",
                "label_ar": "ستافنجر",
                "label_fr": "Stavanger"
            },
            {
                "value": 62523,
                "label": "Halsa",
                "label_ar": "هلسا",
                "label_fr": "Halsa"
            },
            {
                "value": 62168,
                "label": "Saebo",
                "label_ar": "سايبو",
                "label_fr": "Saebo"
            },
            {
                "value": 62097,
                "label": "Haugesund",
                "label_ar": "هاوجيسوند",
                "label_fr": "Haugesund"
            },
            {
                "value": 62656,
                "label": "Ramberg",
                "label_ar": "رامبرج",
                "label_fr": "Ramberg"
            },
            {
                "value": 62525,
                "label": "Verdal",
                "label_ar": "فيردال",
                "label_fr": "Verdal"
            },
            {
                "value": 62077,
                "label": "Ørsta",
                "label_ar": "Ørsta",
                "label_fr": "Ørsta"
            },
            {
                "value": 62708,
                "label": "Rena",
                "label_ar": "رينا",
                "label_fr": "Rena"
            }
        ]
    },
    {
        "country": "Mexico",
        "cities": [
            {
                "value": 63176,
                "label": "Rio Blanco",
                "label_ar": "ريو بلانكو",
                "label_fr": "Rio Blanco"
            },
            {
                "value": 62907,
                "label": "Baja California",
                "label_ar": "باجا كاليفورنيا",
                "label_fr": "Basse Californie"
            },
            {
                "value": 63492,
                "label": "San Pedro Piedra Gorda",
                "label_ar": "سان بيدرو بيدرا جوردا",
                "label_fr": "San Pedro Piedra Gorda"
            },
            {
                "value": 63161,
                "label": "Coyoacán",
                "label_ar": "كويواكان",
                "label_fr": "Coyoacán"
            },
            {
                "value": 62964,
                "label": "Garcia",
                "label_ar": "جارسيا",
                "label_fr": "Garcia"
            },
            {
                "value": 63378,
                "label": "Cuajimalpa",
                "label_ar": "كواجيمالبا",
                "label_fr": "Cuajimalpa"
            },
            {
                "value": 63401,
                "label": "Ciudad Sahagun",
                "label_ar": "سيوداد ساهاغون",
                "label_fr": "Ciudad Sahagun"
            },
            {
                "value": 62926,
                "label": "Cuernavaca",
                "label_ar": "كويرنافاكا",
                "label_fr": "Cuernavaca"
            },
            {
                "value": 63141,
                "label": "Oxkutzkab",
                "label_ar": "Oxkutzkab",
                "label_fr": "Oxkutzkab"
            },
            {
                "value": 63300,
                "label": "Pueblo Hidalgo",
                "label_ar": "بويبلو هيدالجو",
                "label_fr": "Pueblo Hidalgo"
            },
            {
                "value": 63127,
                "label": "El Ocotito",
                "label_ar": "إل أوكوتيتو",
                "label_fr": "El Ocotito"
            },
            {
                "value": 63015,
                "label": "Paseos de Tecamac",
                "label_ar": "باسيوس دي تيكاماك",
                "label_fr": "Paseos de Tecamac"
            },
            {
                "value": 63213,
                "label": "Santiago Ixcuintla",
                "label_ar": "سانتياغو إككوينتلا",
                "label_fr": "Santiago Ixcuintla"
            },
            {
                "value": 63612,
                "label": "Apan",
                "label_ar": "مقلاة",
                "label_fr": "Une casserole"
            },
            {
                "value": 63474,
                "label": "Guaymas",
                "label_ar": "Guaymas",
                "label_fr": "Guaymas"
            },
            {
                "value": 63417,
                "label": "Los Garzas",
                "label_ar": "لوس جارزاس",
                "label_fr": "Los Garzas"
            },
            {
                "value": 63625,
                "label": "Compostela",
                "label_ar": "كومبوستيلا",
                "label_fr": "Compostelle"
            },
            {
                "value": 63289,
                "label": "El Torreon",
                "label_ar": "إل توريون",
                "label_fr": "El Torreon"
            },
            {
                "value": 63046,
                "label": "Barranca de Santa Clara",
                "label_ar": "بارانكا دي سانتا كلارا",
                "label_fr": "Barranca de Santa Clara"
            },
            {
                "value": 63129,
                "label": "Mixquiahuala de Juarez",
                "label_ar": "ميكسكياهوالا دي خواريز",
                "label_fr": "Mixquiahuala de Juarez"
            },
            {
                "value": 63294,
                "label": "Cadereyta de Montes",
                "label_ar": "كاديريتا دي مونتيس",
                "label_fr": "Cadereyta de Montes"
            },
            {
                "value": 63653,
                "label": "San Vicente",
                "label_ar": "سان فيسينتي",
                "label_fr": "San Vicente"
            },
            {
                "value": 63596,
                "label": "Santa Catalina",
                "label_ar": "سانتا كاتالينا",
                "label_fr": "Santa Catalina"
            },
            {
                "value": 63570,
                "label": "Canatlan",
                "label_ar": "كاناتلان",
                "label_fr": "Canatlan"
            },
            {
                "value": 63532,
                "label": "Agua Amarga",
                "label_ar": "أغوا أمارجا",
                "label_fr": "Agua Amarga"
            },
            {
                "value": 63001,
                "label": "Mextepec",
                "label_ar": "مكستيبيك",
                "label_fr": "Mextepec"
            },
            {
                "value": 63466,
                "label": "Ciudad Constitucion",
                "label_ar": "Ciudad Constitucion",
                "label_fr": "Constitucion de Ciudad"
            },
            {
                "value": 63566,
                "label": "Satelite",
                "label_ar": "القمر الصناعي",
                "label_fr": "Satelite"
            },
            {
                "value": 63108,
                "label": "Ciudad de Villa de Alvarez",
                "label_ar": "Ciudad de Villa de Alvarez",
                "label_fr": "Ciudad de Villa de Alvarez"
            },
            {
                "value": 63622,
                "label": "Texcoco",
                "label_ar": "تيكسكوكو",
                "label_fr": "Texcoco"
            },
            {
                "value": 63041,
                "label": "Yurecuaro",
                "label_ar": "يوريكوارو",
                "label_fr": "Yurecuaro"
            },
            {
                "value": 63498,
                "label": "Mineral del Chico",
                "label_ar": "مينرال ديل شيكو",
                "label_fr": "Mineral del Chico"
            },
            {
                "value": 63475,
                "label": "Sonora",
                "label_ar": "سونورا",
                "label_fr": "Sonora"
            },
            {
                "value": 63064,
                "label": "El Leon",
                "label_ar": "إل ليون",
                "label_fr": "El Léon"
            },
            {
                "value": 63105,
                "label": "Tepatitlán de Morelos",
                "label_ar": "تيباتيتلان دي موريلوس",
                "label_fr": "Tepatitlán de Morelos"
            },
            {
                "value": 63365,
                "label": "La Habana",
                "label_ar": "لا هابانا",
                "label_fr": "La Habana"
            },
            {
                "value": 63147,
                "label": "Nogales",
                "label_ar": "نوجاليس",
                "label_fr": "Nogales"
            },
            {
                "value": 63460,
                "label": "Tirindaro",
                "label_ar": "تيريندارو",
                "label_fr": "Tirindaro"
            },
            {
                "value": 63648,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 63507,
                "label": "San Francisco Javier",
                "label_ar": "سان فرانسيسكو جافير",
                "label_fr": "San Francisco Javier"
            },
            {
                "value": 63556,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 63453,
                "label": "Juan",
                "label_ar": "خوان",
                "label_fr": "Juan"
            },
            {
                "value": 63416,
                "label": "Alamo",
                "label_ar": "ألامو",
                "label_fr": "Alamo"
            },
            {
                "value": 63069,
                "label": "Tequila",
                "label_ar": "تكيلا",
                "label_fr": "Tequila"
            },
            {
                "value": 63242,
                "label": "La Victoria",
                "label_ar": "لا فيكتوريا",
                "label_fr": "La Victoria"
            },
            {
                "value": 63328,
                "label": "Acatic",
                "label_ar": "أكاتيك",
                "label_fr": "Acatique"
            },
            {
                "value": 63380,
                "label": "Atlatlahucan",
                "label_ar": "أتلاتلاهوكان",
                "label_fr": "Atlatlahucan"
            },
            {
                "value": 63386,
                "label": "Oaxtepec",
                "label_ar": "Oaxtepec",
                "label_fr": "Oaxtepec"
            },
            {
                "value": 63487,
                "label": "Queseria",
                "label_ar": "القيصرية",
                "label_fr": "Queseria"
            },
            {
                "value": 62963,
                "label": "Naucalpan",
                "label_ar": "ناوكالبان",
                "label_fr": "Naucalpan"
            },
            {
                "value": 63165,
                "label": "Panotla",
                "label_ar": "بانوتلا",
                "label_fr": "Panotla"
            },
            {
                "value": 63592,
                "label": "Mier",
                "label_ar": "مير",
                "label_fr": "Mier"
            },
            {
                "value": 63333,
                "label": "Ciudad Valles",
                "label_ar": "سيوداد فاليس",
                "label_fr": "Ciudad Valles"
            },
            {
                "value": 63563,
                "label": "Jimenez",
                "label_ar": "خيمينيز",
                "label_fr": "Jimenez"
            },
            {
                "value": 63418,
                "label": "Jimenez",
                "label_ar": "خيمينيز",
                "label_fr": "Jimenez"
            },
            {
                "value": 63195,
                "label": "Cuautla",
                "label_ar": "كواوتلا",
                "label_fr": "Cuautla"
            },
            {
                "value": 63092,
                "label": "Corregidora",
                "label_ar": "كوريجيدورا",
                "label_fr": "Corregidora"
            },
            {
                "value": 63260,
                "label": "San Jose del Valle",
                "label_ar": "سان خوسيه ديل فالي",
                "label_fr": "San Jose del Valle"
            },
            {
                "value": 63238,
                "label": "Ocoyoacac",
                "label_ar": "أوكويواكاك",
                "label_fr": "Ocoyoacac"
            },
            {
                "value": 63597,
                "label": "Jalostotitlan",
                "label_ar": "جالوستوتيتلان",
                "label_fr": "Jalostotitlan"
            },
            {
                "value": 63536,
                "label": "Taxco",
                "label_ar": "تاكسكو",
                "label_fr": "Taxco"
            },
            {
                "value": 63654,
                "label": "Arandas",
                "label_ar": "أرانداس",
                "label_fr": "Arandas"
            },
            {
                "value": 63249,
                "label": "Tehuacán",
                "label_ar": "تهواكان",
                "label_fr": "Tehuacán"
            },
            {
                "value": 62928,
                "label": "Tuxtla Gutiérrez",
                "label_ar": "توكستلا جوتيريز",
                "label_fr": "Tuxtla Gutiérrez"
            },
            {
                "value": 63201,
                "label": "Ocotlan",
                "label_ar": "أوكوتلان",
                "label_fr": "Ocotlan"
            },
            {
                "value": 63042,
                "label": "Tocumbo",
                "label_ar": "توكومبو",
                "label_fr": "Tocumbo"
            },
            {
                "value": 63107,
                "label": "El Tecuan",
                "label_ar": "El Tecuan",
                "label_fr": "El Tecuan"
            },
            {
                "value": 63514,
                "label": "Rancho Xalnene",
                "label_ar": "رانشو زالنين",
                "label_fr": "Rancho Xalnene"
            },
            {
                "value": 63389,
                "label": "Yecapixtla",
                "label_ar": "Yecapixtla",
                "label_fr": "Yecapixtla"
            },
            {
                "value": 63607,
                "label": "Teotihuacan de Arista",
                "label_ar": "تيوتيهواكان دي أريستا",
                "label_fr": "Teotihuacan de Arista"
            },
            {
                "value": 63633,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 63326,
                "label": "Pericos",
                "label_ar": "بريكوس",
                "label_fr": "Pericos"
            },
            {
                "value": 62988,
                "label": "Chalco de Diaz Covarrubias",
                "label_ar": "تشالكو دي دياز كوفاروبياس",
                "label_fr": "Chalco de Diaz Covarrubias"
            },
            {
                "value": 63055,
                "label": "San Martin Texmelucan de Labastida",
                "label_ar": "سان مارتن تيكسميلوكان دي لابستيدا",
                "label_fr": "San Martin Texmelucan de Labastida"
            },
            {
                "value": 63437,
                "label": "Moroleon",
                "label_ar": "موروليون",
                "label_fr": "Moroléon"
            },
            {
                "value": 63286,
                "label": "Santa Apolonia",
                "label_ar": "سانتا أبولونيا",
                "label_fr": "Santa Apolonia"
            },
            {
                "value": 63480,
                "label": "El Rio",
                "label_ar": "El Rio",
                "label_fr": "El Rio"
            },
            {
                "value": 63576,
                "label": "Tierra Colorada",
                "label_ar": "تييرا كولورادا",
                "label_fr": "Tierra Colorada"
            },
            {
                "value": 63003,
                "label": "Netzahualcoyotl",
                "label_ar": "Netzahualcoyotl",
                "label_fr": "Netzahualcoyotl"
            },
            {
                "value": 63444,
                "label": "Tepalcatepec",
                "label_ar": "تيبالكاتيبيك",
                "label_fr": "Tepalcatepec"
            },
            {
                "value": 63193,
                "label": "Minatitlán",
                "label_ar": "ميناتيتلان",
                "label_fr": "Minatitlán"
            },
            {
                "value": 62918,
                "label": "Mérida",
                "label_ar": "ميريدا",
                "label_fr": "Mérida"
            },
            {
                "value": 63521,
                "label": "Benito Juarez",
                "label_ar": "شخص اسمه \"بينيتو خواريز",
                "label_fr": "Benito Juarez"
            },
            {
                "value": 63059,
                "label": "San Pedro de Nolasco",
                "label_ar": "سان بيدرو دي نولاسكو",
                "label_fr": "San Pedro de Nolasco"
            },
            {
                "value": 63115,
                "label": "El Alto Culiacan",
                "label_ar": "إل ألتو كولياكان",
                "label_fr": "El Alto Culiacan"
            },
            {
                "value": 62925,
                "label": "Zacatecas",
                "label_ar": "زاكاتيكاس",
                "label_fr": "Zacatecas"
            },
            {
                "value": 63445,
                "label": "San Miguel el Alto",
                "label_ar": "سان ميغيل ألتو",
                "label_fr": "San Miguel el Alto"
            },
            {
                "value": 63299,
                "label": "La Pista de Tizayuca",
                "label_ar": "لا بيستا دي تيزايوكا",
                "label_fr": "La Pista de Tizayuca"
            },
            {
                "value": 63210,
                "label": "Villa Cuauhtemoc Otzolotepec",
                "label_ar": "فيلا كواوتيموك Otzolotepec",
                "label_fr": "Villa Cuauhtemoc Otzolotepec"
            },
            {
                "value": 62956,
                "label": "León",
                "label_ar": "ليون",
                "label_fr": "León"
            },
            {
                "value": 62958,
                "label": "Guadalupe",
                "label_ar": "غوادالوبي",
                "label_fr": "Guadalupe"
            },
            {
                "value": 62904,
                "label": "Rosarito",
                "label_ar": "روساريتو",
                "label_fr": "Rosarito"
            },
            {
                "value": 63178,
                "label": "San Nicolas",
                "label_ar": "سان نيكولاس",
                "label_fr": "San Nicolas"
            },
            {
                "value": 63364,
                "label": "Tonala",
                "label_ar": "تونالا",
                "label_fr": "Tonala"
            },
            {
                "value": 63382,
                "label": "Tlaquiltenango",
                "label_ar": "Tlaquiltenango",
                "label_fr": "Tlaquiltenango"
            },
            {
                "value": 63093,
                "label": "San Miguel de Allende",
                "label_ar": "سان ميغيل دي الليندي",
                "label_fr": "San Miguel de Allende"
            },
            {
                "value": 63144,
                "label": "Bahia de Kino",
                "label_ar": "باهيا دي كينو",
                "label_fr": "Bahia de Kino"
            },
            {
                "value": 63057,
                "label": "Rayon",
                "label_ar": "رايون",
                "label_fr": "Rayonne"
            },
            {
                "value": 62899,
                "label": "Acapulco",
                "label_ar": "أكابولكو",
                "label_fr": "Acapulco"
            },
            {
                "value": 63419,
                "label": "Ojinaga",
                "label_ar": "أوجيناجا",
                "label_fr": "Ojinaga"
            },
            {
                "value": 63267,
                "label": "San Francisco del Rincón",
                "label_ar": "سان فرانسيسكو ديل رينكون",
                "label_fr": "San Francisco del Rincón"
            },
            {
                "value": 63293,
                "label": "Quiroga",
                "label_ar": "كيروجا",
                "label_fr": "Quiroga"
            },
            {
                "value": 63510,
                "label": "Puerto Peñasco",
                "label_ar": "بويرتو بينياسكو",
                "label_fr": "Puerto Peñasco"
            },
            {
                "value": 62930,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 62905,
                "label": "Chihuahua",
                "label_ar": "شيواوا",
                "label_fr": "Chihuahua"
            },
            {
                "value": 63273,
                "label": "Venustiano Carranza",
                "label_ar": "فينوستيانو كارانزا",
                "label_fr": "Venustiano Carranza"
            },
            {
                "value": 62991,
                "label": "Venustiano Carranza",
                "label_ar": "فينوستيانو كارانزا",
                "label_fr": "Venustiano Carranza"
            },
            {
                "value": 63376,
                "label": "Juarez",
                "label_ar": "خواريز",
                "label_fr": "Juarez"
            },
            {
                "value": 62976,
                "label": "Poncitlan",
                "label_ar": "بونسيتلان",
                "label_fr": "Poncitlan"
            },
            {
                "value": 63495,
                "label": "Salina Cruz",
                "label_ar": "سالينا كروز",
                "label_fr": "Salina Cruz"
            },
            {
                "value": 63185,
                "label": "Tamazula de Gordiano",
                "label_ar": "تامازولا دي جورديانو",
                "label_fr": "Tamazula de Gordiano"
            },
            {
                "value": 63209,
                "label": "Ciudad Lopez Mateos",
                "label_ar": "سيوداد لوبيز ماتيوس",
                "label_fr": "Ciudad Lopez Mateos"
            },
            {
                "value": 63369,
                "label": "Balancan de Dominguez",
                "label_ar": "بالانكان دي دومينغيز",
                "label_fr": "Balancan de Dominguez"
            },
            {
                "value": 63008,
                "label": "Durango",
                "label_ar": "دورانجو",
                "label_fr": "Durango"
            },
            {
                "value": 62943,
                "label": "Motozintla de Mendoza",
                "label_ar": "موتوزينتلا دي ميندوزا",
                "label_fr": "Motozintla de Mendoza"
            },
            {
                "value": 63355,
                "label": "Cuyoaco",
                "label_ar": "كويواكو",
                "label_fr": "Cuyoaco"
            },
            {
                "value": 63150,
                "label": "Cuautepec de Hinojosa",
                "label_ar": "Cuautepec de Hinojosa",
                "label_fr": "Cuautepec de Hinojosa"
            },
            {
                "value": 63537,
                "label": "San Pablo Hidalgo",
                "label_ar": "سان بابلو هيدالجو",
                "label_fr": "San Pablo Hidalgo"
            },
            {
                "value": 63075,
                "label": "Ciudad Guzmán",
                "label_ar": "سيوداد جوزمان",
                "label_fr": "Ciudad Guzmán"
            },
            {
                "value": 63228,
                "label": "Lerma",
                "label_ar": "ليرما",
                "label_fr": "Lerma"
            },
            {
                "value": 63248,
                "label": "La Ciudad",
                "label_ar": "لا سيوداد",
                "label_fr": "La Ciudad"
            },
            {
                "value": 63459,
                "label": "Chicago",
                "label_ar": "شيكاغو",
                "label_fr": "Chicago"
            },
            {
                "value": 63564,
                "label": "Ixtlahuaca de Villada",
                "label_ar": "اكستلاهواكا دي فيلادا",
                "label_fr": "Ixtlahuaca de Villada"
            },
            {
                "value": 63095,
                "label": "La Florida",
                "label_ar": "لا فلوريدا",
                "label_fr": "La Florida"
            },
            {
                "value": 62934,
                "label": "Hermosillo",
                "label_ar": "هيرموسيلو",
                "label_fr": "Hermosillo"
            },
            {
                "value": 63476,
                "label": "Empalme",
                "label_ar": "إمبالمي",
                "label_fr": "Empalme"
            },
            {
                "value": 63265,
                "label": "Tepotzotlan",
                "label_ar": "تيبوتزوتلان",
                "label_fr": "Tepotzotlan"
            },
            {
                "value": 63065,
                "label": "Juarez",
                "label_ar": "خواريز",
                "label_fr": "Juarez"
            },
            {
                "value": 63258,
                "label": "Zapotitlan de Vadillo",
                "label_ar": "زابوتيتلان دي فاديلو",
                "label_fr": "Zapotitlan de Vadillo"
            },
            {
                "value": 63304,
                "label": "Tlahuelilpan",
                "label_ar": "تلاهويللبان",
                "label_fr": "Tlahuelilpan"
            },
            {
                "value": 63661,
                "label": "Colonia",
                "label_ar": "كولونيا",
                "label_fr": "Colonia"
            },
            {
                "value": 63642,
                "label": "San Rafael",
                "label_ar": "سان رافائيل",
                "label_fr": "San Rafael"
            },
            {
                "value": 62985,
                "label": "San Andres Tuxtla",
                "label_ar": "سان أندريس توكستلا",
                "label_fr": "San Andres Tuxtla"
            },
            {
                "value": 63407,
                "label": "San Sebastian Xhala",
                "label_ar": "سان سيباستيان شالا",
                "label_fr": "Saint-Sébastien Xhala"
            },
            {
                "value": 63509,
                "label": "Ixtapa",
                "label_ar": "اكستابا",
                "label_fr": "Ixtapa"
            },
            {
                "value": 63271,
                "label": "Silao",
                "label_ar": "سيلاو",
                "label_fr": "Silao"
            },
            {
                "value": 62979,
                "label": "Cuautitlan",
                "label_ar": "كواوتيتلان",
                "label_fr": "Cuautitlan"
            },
            {
                "value": 63247,
                "label": "Ex-Hacienda Pantitlan",
                "label_ar": "السابق Hacienda Pantitlan",
                "label_fr": "Ex-Hacienda Pantitlan"
            },
            {
                "value": 62971,
                "label": "Cuauhtemoc",
                "label_ar": "كواوتيموك",
                "label_fr": "Cuauhtémoc"
            },
            {
                "value": 63550,
                "label": "Tacotalpa",
                "label_ar": "تاكوتالبا",
                "label_fr": "Tacotalpa"
            },
            {
                "value": 63104,
                "label": "Reyes de Vallarta",
                "label_ar": "رييس دي فالارتا",
                "label_fr": "Reyes de Vallarta"
            },
            {
                "value": 63619,
                "label": "Jerez de Garcia Salinas",
                "label_ar": "جيريز دي جارسيا ساليناس",
                "label_fr": "Jerez de Garcia Salinas"
            },
            {
                "value": 63062,
                "label": "Guadalupe",
                "label_ar": "غوادالوبي",
                "label_fr": "Guadalupe"
            },
            {
                "value": 63583,
                "label": "Alamos",
                "label_ar": "الاموس",
                "label_fr": "Alamos"
            },
            {
                "value": 62903,
                "label": "Playa del Carmen",
                "label_ar": "بلايا ديل كارمن",
                "label_fr": "Playa del Carmen"
            },
            {
                "value": 63539,
                "label": "Tilzapotla",
                "label_ar": "تيلزابوتلا",
                "label_fr": "Tilzapotla"
            },
            {
                "value": 62990,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 63031,
                "label": "Chihuahua",
                "label_ar": "شيواوا",
                "label_fr": "Chihuahua"
            },
            {
                "value": 63153,
                "label": "San Nicolas",
                "label_ar": "سان نيكولاس",
                "label_fr": "San Nicolas"
            },
            {
                "value": 63126,
                "label": "Ixtapa-Zihuatanejo",
                "label_ar": "اكستابا-زيهواتانيجو",
                "label_fr": "Ixtapa-Zihuatanejo"
            },
            {
                "value": 63122,
                "label": "Candelaria",
                "label_ar": "كانديلاريا",
                "label_fr": "Candelaria"
            },
            {
                "value": 63569,
                "label": "Monclova",
                "label_ar": "مونكلوفا",
                "label_fr": "Monclova"
            },
            {
                "value": 63454,
                "label": "Villa Garcia",
                "label_ar": "فيلا جارسيا",
                "label_fr": "Villa Garcia"
            },
            {
                "value": 63103,
                "label": "Bucerias",
                "label_ar": "بوسيرياس",
                "label_fr": "Bucerias"
            },
            {
                "value": 62955,
                "label": "Tala",
                "label_ar": "تالا",
                "label_fr": "Tala"
            },
            {
                "value": 63429,
                "label": "Puente Grande",
                "label_ar": "بوينتي غراندي",
                "label_fr": "Puente Grande"
            },
            {
                "value": 63435,
                "label": "Ignacio Allende",
                "label_ar": "اجناسيو الليندي",
                "label_fr": "Ignacio Allende"
            },
            {
                "value": 63643,
                "label": "Leyva Solano",
                "label_ar": "ليفا سولانو",
                "label_fr": "Leyva Solano"
            },
            {
                "value": 62970,
                "label": "Torreón",
                "label_ar": "توريون",
                "label_fr": "Torreón"
            },
            {
                "value": 63457,
                "label": "Jacona de Plancarte",
                "label_ar": "جاكونا دي بلانكارت",
                "label_fr": "Jacona de Plancarte"
            },
            {
                "value": 62894,
                "label": "Santiago de Queretaro",
                "label_ar": "سانتياغو دي كويريتارو",
                "label_fr": "Santiago de Queretaro"
            },
            {
                "value": 63557,
                "label": "Frontera",
                "label_ar": "فرونتيرا",
                "label_fr": "Frontera"
            },
            {
                "value": 63038,
                "label": "Patzcuaro",
                "label_ar": "باتزكوارو",
                "label_fr": "Patzcuaro"
            },
            {
                "value": 62913,
                "label": "Aguascalientes",
                "label_ar": "أغواسكالينتس",
                "label_fr": "Aguascalientes"
            },
            {
                "value": 63264,
                "label": "Libramiento Leon-Salamanca",
                "label_ar": "ليبرامينتو ليون سالامانكا",
                "label_fr": "Libramiento Leon-Salamanque"
            },
            {
                "value": 63627,
                "label": "San Felipe",
                "label_ar": "سان فيليبي",
                "label_fr": "San Felipe"
            },
            {
                "value": 62929,
                "label": "Reynosa",
                "label_ar": "رينوسا",
                "label_fr": "Reynosa"
            },
            {
                "value": 63259,
                "label": "El Grullo",
                "label_ar": "إل جرولو",
                "label_fr": "El Grullo"
            },
            {
                "value": 63632,
                "label": "Arcelia",
                "label_ar": "أرسيليا",
                "label_fr": "Arcelia"
            },
            {
                "value": 63406,
                "label": "Acolman",
                "label_ar": "أكولمان",
                "label_fr": "Acolman"
            },
            {
                "value": 63028,
                "label": "Sinaloa de Leyva",
                "label_ar": "سينالوا دي ليفا",
                "label_fr": "Sinaloa de Leyva"
            },
            {
                "value": 63250,
                "label": "San Pedro Cholula",
                "label_ar": "سان بيدرو تشولولا",
                "label_fr": "San Pedro Cholula"
            },
            {
                "value": 63529,
                "label": "Guadalupe",
                "label_ar": "غوادالوبي",
                "label_fr": "Guadalupe"
            },
            {
                "value": 63591,
                "label": "Tepeaca",
                "label_ar": "تيبياكا",
                "label_fr": "Tepeaca"
            },
            {
                "value": 63234,
                "label": "Ixtapan de la Sal",
                "label_ar": "اكستابان دي لا سال",
                "label_fr": "Ixtapan de la Sal"
            },
            {
                "value": 63664,
                "label": "San Miguel Balderas",
                "label_ar": "سان ميغيل بالديراس",
                "label_fr": "San Miguel Balderas"
            },
            {
                "value": 63575,
                "label": "Ursulo Galvan",
                "label_ar": "أورسولو جالفان",
                "label_fr": "Ursulo Galvan"
            },
            {
                "value": 62922,
                "label": "Salamanca",
                "label_ar": "سالامانكا",
                "label_fr": "Salamanque"
            },
            {
                "value": 63071,
                "label": "El Salto",
                "label_ar": "السالتو",
                "label_fr": "El Salto"
            },
            {
                "value": 63395,
                "label": "Santiago Tianguistenco",
                "label_ar": "سانتياغو تيانغيستينكو",
                "label_fr": "Santiago Tianguistenco"
            },
            {
                "value": 63203,
                "label": "Las Delicias",
                "label_ar": "لاس ديليسياس",
                "label_fr": "Las Delicias"
            },
            {
                "value": 63094,
                "label": "Yuriria",
                "label_ar": "يوريريا",
                "label_fr": "Yuriria"
            },
            {
                "value": 63214,
                "label": "Colonia Hidalgo",
                "label_ar": "كولونيا هيدالجو",
                "label_fr": "Colonia Hidalgo"
            },
            {
                "value": 63358,
                "label": "Jesus Carranza",
                "label_ar": "يسوع كارانزا",
                "label_fr": "Jésus Carranza"
            },
            {
                "value": 63620,
                "label": "San Salvador Atenco",
                "label_ar": "سان سلفادور أتينكو",
                "label_fr": "San Salvador Atenco"
            },
            {
                "value": 63589,
                "label": "Ciudad Sabinas",
                "label_ar": "سيوداد سابيناس",
                "label_fr": "Ciudad Sabinas"
            },
            {
                "value": 63656,
                "label": "Tamaulipas",
                "label_ar": "تاماوليباس",
                "label_fr": "Tamaulipas"
            },
            {
                "value": 63277,
                "label": "Chiconcuac",
                "label_ar": "شيكونكواك",
                "label_fr": "Chiconcuac"
            },
            {
                "value": 63471,
                "label": "Escuinapa de Hidalgo",
                "label_ar": "اسكوينابا دي هيدالغو",
                "label_fr": "Escuinapa de Hidalgo"
            },
            {
                "value": 63646,
                "label": "Barrio de Mexico",
                "label_ar": "باريو دي المكسيك",
                "label_fr": "Barrio de Mexico"
            },
            {
                "value": 63222,
                "label": "Heroica Zitacuaro",
                "label_ar": "هيرويكا زيتاكوارو",
                "label_fr": "Heroica Zitacuaro"
            },
            {
                "value": 63297,
                "label": "Acatlan de Perez Figueroa",
                "label_ar": "أكاتلان دي بيريز فيغيروا",
                "label_fr": "Acatlan de Perez Figueroa"
            },
            {
                "value": 63545,
                "label": "La Moncada",
                "label_ar": "لا مونكادا",
                "label_fr": "La Moncada"
            },
            {
                "value": 63381,
                "label": "Tepoztlan",
                "label_ar": "تيبوزتلان",
                "label_fr": "Tepoztlan"
            },
            {
                "value": 63651,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 62912,
                "label": "Campeche",
                "label_ar": "كامبيتشي",
                "label_fr": "Campeche"
            },
            {
                "value": 63023,
                "label": "Santa Maria Chimalhuacan",
                "label_ar": "سانتا ماريا تشيمالهواكان",
                "label_fr": "Santa Maria Chimalhuacan"
            },
            {
                "value": 63572,
                "label": "San Miguel de Papasquiaro",
                "label_ar": "سان ميغيل دي باباسكيارو",
                "label_fr": "San Miguel de Papasquiaro"
            },
            {
                "value": 62952,
                "label": "Xalapa",
                "label_ar": "خالابا",
                "label_fr": "Xalapa"
            },
            {
                "value": 63455,
                "label": "Jalpa",
                "label_ar": "جالبا",
                "label_fr": "Jalpa"
            },
            {
                "value": 63559,
                "label": "Ojo de Agua",
                "label_ar": "أوجو دي أجوا",
                "label_fr": "Ojo de Agua"
            },
            {
                "value": 63388,
                "label": "Tezoyuca",
                "label_ar": "تيزويوكا",
                "label_fr": "Tezoyuca"
            },
            {
                "value": 63174,
                "label": "Cuitlahuac",
                "label_ar": "كويتلاواك",
                "label_fr": "Cuitlahuac"
            },
            {
                "value": 63434,
                "label": "Dolores Hidalgo Cuna de la Independencia Nacional",
                "label_ar": "دولوريس هيدالغو كونا دي لا إندبيندينسيا ناسيونال",
                "label_fr": "Dolores Hidalgo Cuna de la Independencia Nacional"
            },
            {
                "value": 63034,
                "label": "Jocotepec",
                "label_ar": "جوكوتبيك",
                "label_fr": "Jocotepec"
            },
            {
                "value": 63613,
                "label": "Tezontepec de Aldama",
                "label_ar": "Tezontepec de Aldama",
                "label_fr": "Tezontepec de Aldama"
            },
            {
                "value": 63578,
                "label": "San Fernando",
                "label_ar": "سان فرناندو",
                "label_fr": "San Fernando"
            },
            {
                "value": 63140,
                "label": "Uman",
                "label_ar": "أومان",
                "label_fr": "Uman"
            },
            {
                "value": 63515,
                "label": "Tuxpan de Rodriguez Cano",
                "label_ar": "توكسبان دي رودريغيز كانو",
                "label_fr": "Tuxpan de Rodriguez Cano"
            },
            {
                "value": 63535,
                "label": "Colonia de Venado",
                "label_ar": "كولونيا دي فينادو",
                "label_fr": "Colonia de Venado"
            },
            {
                "value": 63074,
                "label": "Tecalitlan",
                "label_ar": "تيكاليتلان",
                "label_fr": "Tecalitlan"
            },
            {
                "value": 63135,
                "label": "Juarez",
                "label_ar": "خواريز",
                "label_fr": "Juarez"
            },
            {
                "value": 63306,
                "label": "Huixtla",
                "label_ar": "هويكستلا",
                "label_fr": "Huixtla"
            },
            {
                "value": 63316,
                "label": "Amecameca de Juarez",
                "label_ar": "أميكاميكا دي خواريز",
                "label_fr": "Amecameca de Juarez"
            },
            {
                "value": 63181,
                "label": "Villa Hidalgo",
                "label_ar": "فيلا هيدالجو",
                "label_fr": "Villa Hidalgo"
            },
            {
                "value": 63131,
                "label": "Tolcayuca",
                "label_ar": "تولكايوكا",
                "label_fr": "Tolcayuca"
            },
            {
                "value": 63468,
                "label": "Todos Santos",
                "label_ar": "تودوس سانتوس",
                "label_fr": "Todos Santos"
            },
            {
                "value": 63494,
                "label": "Heroica Coscomatepec de Bravo",
                "label_ar": "Heroica Coscomatepec de Bravo",
                "label_fr": "Heroica Coscomatepec de Bravo"
            },
            {
                "value": 63420,
                "label": "Cuauhtemoc",
                "label_ar": "كواوتيموك",
                "label_fr": "Cuauhtémoc"
            },
            {
                "value": 63433,
                "label": "Monte Leon",
                "label_ar": "مونتي ليون",
                "label_fr": "Monte Léon"
            },
            {
                "value": 63324,
                "label": "Vistahermosa de Negrete",
                "label_ar": "Vistahermosa de Negrete",
                "label_fr": "Vistahermosa de Negrete"
            },
            {
                "value": 63609,
                "label": "La Herradura",
                "label_ar": "لا هيرادورا",
                "label_fr": "La Herradura"
            },
            {
                "value": 63385,
                "label": "Jojutla",
                "label_ar": "جوجوتلا",
                "label_fr": "Jojutla"
            },
            {
                "value": 63483,
                "label": "San Miguel el Alto (El Puerto)",
                "label_ar": "سان ميغيل إل ألتو (إل بويرتو)",
                "label_fr": "San Miguel el Alto (El Puerto)"
            },
            {
                "value": 62961,
                "label": "Mazatlán",
                "label_ar": "مازاتلان",
                "label_fr": "Mazatlán"
            },
            {
                "value": 63162,
                "label": "Tlalmanalco de Velazquez",
                "label_ar": "Tlalmanalco de Velazquez",
                "label_fr": "Tlalmanalco de Velazquez"
            },
            {
                "value": 63099,
                "label": "Tuxpan",
                "label_ar": "توكسبان",
                "label_fr": "Tuxpan"
            },
            {
                "value": 63088,
                "label": "San Juan del Río",
                "label_ar": "سان خوان ديل ريو",
                "label_fr": "San Juan del Río"
            },
            {
                "value": 63101,
                "label": "Banderas del Aguila",
                "label_ar": "بانديراس ديل أغيلا",
                "label_fr": "Banderas del Aguila"
            },
            {
                "value": 62916,
                "label": "Colonia Madero (Madero)",
                "label_ar": "كولونيا ماديرو (ماديرو)",
                "label_fr": "Colonia Madero (Madero)"
            },
            {
                "value": 63571,
                "label": "Nuevo Ideal",
                "label_ar": "نويفو ايديال",
                "label_fr": "Idéal Nuevo"
            },
            {
                "value": 63525,
                "label": "Zapotlan de Juarez",
                "label_ar": "زابوتلان دي خواريز",
                "label_fr": "Zapotlan de Juarez"
            },
            {
                "value": 63246,
                "label": "Petatlan",
                "label_ar": "بيتاتلان",
                "label_fr": "Petatlan"
            },
            {
                "value": 63427,
                "label": "Camargo",
                "label_ar": "كامارجو",
                "label_fr": "Camargo"
            },
            {
                "value": 63482,
                "label": "El Mirador",
                "label_ar": "الميرادور",
                "label_fr": "El Mirador"
            },
            {
                "value": 63308,
                "label": "Comitán",
                "label_ar": "كوميتان",
                "label_fr": "Comitán"
            },
            {
                "value": 63637,
                "label": "San Antonio Virreyes",
                "label_ar": "سان انطونيو فيريس",
                "label_fr": "San Antonio Virreyes"
            },
            {
                "value": 63305,
                "label": "Brisas Barra de Suchiate",
                "label_ar": "Brisas Barra de Suchiate",
                "label_fr": "Brisas Barra de Suchiate"
            },
            {
                "value": 63270,
                "label": "Rincon de Tamayo",
                "label_ar": "رينكون دي تامايو",
                "label_fr": "Rincon de Tamayo"
            },
            {
                "value": 62892,
                "label": "Cancún",
                "label_ar": "كانكون",
                "label_fr": "Cancun"
            },
            {
                "value": 62967,
                "label": "Tlalnepantla",
                "label_ar": "تلانيبانتلا",
                "label_fr": "Tlalnepantla"
            },
            {
                "value": 63177,
                "label": "Cadereyta Jimenez",
                "label_ar": "كاديريتا خيمينيز",
                "label_fr": "Cadereyta Jimenez"
            },
            {
                "value": 63013,
                "label": "Teoloyucan",
                "label_ar": "تيولويوكان",
                "label_fr": "Teoloyucan"
            },
            {
                "value": 63002,
                "label": "Ciudad Nezahualcoyotl",
                "label_ar": "Ciudad Nezahualcoyotl",
                "label_fr": "Ciudad Nezahualcoyotl"
            },
            {
                "value": 62896,
                "label": "Puebla",
                "label_ar": "بويبلا",
                "label_fr": "Puebla"
            },
            {
                "value": 63590,
                "label": "Catemaco",
                "label_ar": "كاتيماكو",
                "label_fr": "Catemaco"
            },
            {
                "value": 63368,
                "label": "Pichucalco",
                "label_ar": "بيتشوكالكو",
                "label_fr": "Pichucalco"
            },
            {
                "value": 63518,
                "label": "Juchitán de Zaragoza",
                "label_ar": "يوتشيتان دي سرقسطة",
                "label_fr": "Juchitán de Zaragoza"
            },
            {
                "value": 63463,
                "label": "Ahualulco de Mercado",
                "label_ar": "أهواللكو دي ميركادو",
                "label_fr": "Ahualulco de Mercado"
            },
            {
                "value": 63142,
                "label": "Cananea",
                "label_ar": "كانانيا",
                "label_fr": "Cananea"
            },
            {
                "value": 63508,
                "label": "Pantanal",
                "label_ar": "بانتانال",
                "label_fr": "Pantanal"
            },
            {
                "value": 62980,
                "label": "Zamora",
                "label_ar": "زامورا",
                "label_fr": "Zamora"
            },
            {
                "value": 63501,
                "label": "Tlapacoyan",
                "label_ar": "تلاباكويان",
                "label_fr": "Tlapacoyan"
            },
            {
                "value": 63634,
                "label": "Mazatepec",
                "label_ar": "مازاتيبيك",
                "label_fr": "Mazatepec"
            },
            {
                "value": 62890,
                "label": "Monterrey",
                "label_ar": "مونتيري",
                "label_fr": "Monterrey"
            },
            {
                "value": 63119,
                "label": "Guasave",
                "label_ar": "جواساف",
                "label_fr": "Guasave"
            },
            {
                "value": 63295,
                "label": "Asuncion Ixtaltepec",
                "label_ar": "أسونسيون اكستالتيبيك",
                "label_fr": "Asuncion Ixtaltepec"
            },
            {
                "value": 62939,
                "label": "Coatzacoalcos",
                "label_ar": "كواتزاكوالكوس",
                "label_fr": "Coatzacoalcos"
            },
            {
                "value": 63638,
                "label": "Cerritos",
                "label_ar": "سيريتوس",
                "label_fr": "Cerritos"
            },
            {
                "value": 63019,
                "label": "Ensenada",
                "label_ar": "إنسينادا",
                "label_fr": "Ensenada"
            },
            {
                "value": 62954,
                "label": "Garza Garcia",
                "label_ar": "جارزا جارسيا",
                "label_fr": "Garza Garcia"
            },
            {
                "value": 63617,
                "label": "Magiscatzin",
                "label_ar": "ماجيسكاتزين",
                "label_fr": "Magiscatzin"
            },
            {
                "value": 63164,
                "label": "Benito Juarez",
                "label_ar": "شخص اسمه \"بينيتو خواريز",
                "label_fr": "Benito Juarez"
            },
            {
                "value": 62983,
                "label": "Ameca",
                "label_ar": "أميكا",
                "label_fr": "Ameca"
            },
            {
                "value": 63363,
                "label": "Huimanguillo",
                "label_ar": "Huimanguillo",
                "label_fr": "Huimanguillo"
            },
            {
                "value": 63175,
                "label": "Tierra Blanca",
                "label_ar": "تييرا بلانكا",
                "label_fr": "Tierra Blanca"
            },
            {
                "value": 63011,
                "label": "Salvatierra",
                "label_ar": "سالفاتيرا",
                "label_fr": "Salvatierra"
            },
            {
                "value": 62973,
                "label": "Salinas de Hidalgo",
                "label_ar": "ساليناس دي هيدالجو",
                "label_fr": "Salinas de Hidalgo"
            },
            {
                "value": 63117,
                "label": "Guamuchil",
                "label_ar": "غواموتشيل",
                "label_fr": "Guamuchil"
            },
            {
                "value": 63155,
                "label": "General Escobedo",
                "label_ar": "الجنرال اسكوبيدو",
                "label_fr": "Général Escobedo"
            },
            {
                "value": 62902,
                "label": "Tijuana",
                "label_ar": "تيخوانا",
                "label_fr": "Tijuana"
            },
            {
                "value": 63116,
                "label": "Navolato",
                "label_ar": "نافولاتو",
                "label_fr": "Navolato"
            },
            {
                "value": 63257,
                "label": "Colimas",
                "label_ar": "كوليماس",
                "label_fr": "Colimas"
            },
            {
                "value": 63605,
                "label": "Tequixquiac",
                "label_ar": "تيكيكيكياك",
                "label_fr": "Tequixquiac"
            },
            {
                "value": 62895,
                "label": "Guadalajara",
                "label_ar": "غوادالاخارا",
                "label_fr": "Guadalajara"
            },
            {
                "value": 62931,
                "label": "Heroica Matamoros",
                "label_ar": "هيرويكا ماتاموروس",
                "label_fr": "Heroica Matamoros"
            },
            {
                "value": 63301,
                "label": "Iglesia Vieja",
                "label_ar": "Iglesia Vieja",
                "label_fr": "Iglesia Vieja"
            },
            {
                "value": 63477,
                "label": "Cabos",
                "label_ar": "كابوس",
                "label_fr": "Cabos"
            },
            {
                "value": 63512,
                "label": "Agua Prieta",
                "label_ar": "أغوا برييتا",
                "label_fr": "Agua Prieta"
            },
            {
                "value": 62937,
                "label": "Villahermosa",
                "label_ar": "فيلاهيرموسا",
                "label_fr": "Villahermosa"
            },
            {
                "value": 63362,
                "label": "Rayon",
                "label_ar": "رايون",
                "label_fr": "Rayonne"
            },
            {
                "value": 63658,
                "label": "Villa de Almoloya de Juarez",
                "label_ar": "فيلا دي ألمولويا دي خواريز",
                "label_fr": "Villa de Almoloya de Juarez"
            },
            {
                "value": 63152,
                "label": "Monterrey",
                "label_ar": "مونتيري",
                "label_fr": "Monterrey"
            },
            {
                "value": 63132,
                "label": "Ixtapaluca",
                "label_ar": "اكستابالوكا",
                "label_fr": "Ixtapaluca"
            },
            {
                "value": 62965,
                "label": "Apodaca",
                "label_ar": "أبوداكا",
                "label_fr": "Apodaca"
            },
            {
                "value": 62953,
                "label": "Ciudad Victoria",
                "label_ar": "سيوداد فيكتوريا",
                "label_fr": "Ciudad Victoria"
            },
            {
                "value": 63667,
                "label": "Tepetlacolco",
                "label_ar": "تيبيتلاكولكو",
                "label_fr": "Tepetlacolco"
            },
            {
                "value": 63032,
                "label": "Colonia Benito Juarez",
                "label_ar": "كولونيا بينيتو خواريز",
                "label_fr": "Colonia Benito Juarez"
            },
            {
                "value": 63594,
                "label": "Colonia la Presa (Ejido de Chapultepec)",
                "label_ar": "Colonia la Presa (Ejido de Chapultepec)",
                "label_fr": "Colonia la Presa (Ejido de Chapultepec)"
            },
            {
                "value": 63553,
                "label": "La Tapachula",
                "label_ar": "لا تاباتشولا",
                "label_fr": "La Tapachula"
            },
            {
                "value": 63599,
                "label": "Primero de Mayo",
                "label_ar": "بريميرو دي مايو",
                "label_fr": "Primero de Mayo"
            },
            {
                "value": 62981,
                "label": "Los Reyes",
                "label_ar": "لوس رييس",
                "label_fr": "Los Reyes"
            },
            {
                "value": 63166,
                "label": "Fortin de las Flores",
                "label_ar": "فورتين دي لاس فلوريس",
                "label_fr": "Fortin de las Flores"
            },
            {
                "value": 63312,
                "label": "Magdalena Petlacalco",
                "label_ar": "ماجدالينا بيتلاكالكو",
                "label_fr": "Magdalena Petlacalco"
            },
            {
                "value": 62999,
                "label": "Valle de Santiago",
                "label_ar": "فالي دي سانتياغو",
                "label_fr": "Valle de Santiago"
            },
            {
                "value": 63323,
                "label": "Sahuayo de Morelos",
                "label_ar": "ساهوايو دي موريلوس",
                "label_fr": "Sahuayo de Morelos"
            },
            {
                "value": 63279,
                "label": "Acambay",
                "label_ar": "أكامباي",
                "label_fr": "Acambay"
            },
            {
                "value": 63470,
                "label": "Teloloapan",
                "label_ar": "تيلولوابان",
                "label_fr": "Teloloapan"
            },
            {
                "value": 63021,
                "label": "Tablon Grande (Bombas de Apaxco)",
                "label_ar": "تابلون غراندي (بومباس دي أباكسكو)",
                "label_fr": "Tablon Grande (Bombas de Apaxco)"
            },
            {
                "value": 63171,
                "label": "Yanga",
                "label_ar": "يانجا",
                "label_fr": "Yanga"
            },
            {
                "value": 62915,
                "label": "Tlaquepaque",
                "label_ar": "Tlaquepaque",
                "label_fr": "Tlaquepaque"
            },
            {
                "value": 62908,
                "label": "Quintana Roo",
                "label_ar": "كوينتانا رو",
                "label_fr": "Quintana Roo"
            },
            {
                "value": 63159,
                "label": "Estado de Mexico",
                "label_ar": "إستادو دي المكسيك",
                "label_fr": "Estado de Mexico"
            },
            {
                "value": 63462,
                "label": "San Martin Hidalgo",
                "label_ar": "سان مارتن هيدالجو",
                "label_fr": "San Martin Hidalgo"
            },
            {
                "value": 63037,
                "label": "Xalisco",
                "label_ar": "خاليسكو",
                "label_fr": "Xalisco"
            },
            {
                "value": 63551,
                "label": "Suchiapa",
                "label_ar": "سوتشيابا",
                "label_fr": "Suchiapa"
            },
            {
                "value": 63191,
                "label": "Cosoleacaque",
                "label_ar": "Cosoleacaque",
                "label_fr": "Cosoleacaque"
            },
            {
                "value": 63555,
                "label": "Arizpe",
                "label_ar": "أريزبي",
                "label_fr": "Arizpe"
            },
            {
                "value": 63663,
                "label": "San Miguel de Atotonilco",
                "label_ar": "سان ميغيل دي أتوتونيلكو",
                "label_fr": "San Miguel de Atotonilco"
            },
            {
                "value": 63506,
                "label": "Ejido de Otumba (Loma Bonita)",
                "label_ar": "إجيدو دي أوتومبا (لوما بونيتا)",
                "label_fr": "Ejido de Otumba (Loma Bonita)"
            },
            {
                "value": 63602,
                "label": "Jalisco",
                "label_ar": "خاليسكو",
                "label_fr": "Jalisco"
            },
            {
                "value": 63035,
                "label": "San Antonio",
                "label_ar": "سان أنطونيو",
                "label_fr": "San Antonio"
            },
            {
                "value": 63458,
                "label": "Francisco Castellanos",
                "label_ar": "فرانسيسكو كاستيلانوس",
                "label_fr": "Francisco Castellanos"
            },
            {
                "value": 63608,
                "label": "San Luis",
                "label_ar": "سان لويس",
                "label_fr": "San Luis"
            },
            {
                "value": 63052,
                "label": "San Juan",
                "label_ar": "سان خوان",
                "label_fr": "San Juan"
            },
            {
                "value": 63128,
                "label": "Zitlala",
                "label_ar": "زيتلالا",
                "label_fr": "Zitlala"
            },
            {
                "value": 63588,
                "label": "Huichapan",
                "label_ar": "هويتشابان",
                "label_fr": "Huichapan"
            },
            {
                "value": 63187,
                "label": "Tabasco",
                "label_ar": "تاباسكو",
                "label_fr": "Tabasco"
            },
            {
                "value": 63194,
                "label": "San Juan del Rio",
                "label_ar": "سان خوان ديل ريو",
                "label_fr": "San Juan del Rio"
            },
            {
                "value": 62901,
                "label": "Cabo San Lucas",
                "label_ar": "كابو سان لوكاس",
                "label_fr": "Cabo San Lucas"
            },
            {
                "value": 63136,
                "label": "Tulum",
                "label_ar": "تولوم",
                "label_fr": "Tulum"
            },
            {
                "value": 63050,
                "label": "San Andres Cholula",
                "label_ar": "سان أندريس تشولولا",
                "label_fr": "San Andres Cholula"
            },
            {
                "value": 63662,
                "label": "Piedras Negras",
                "label_ar": "بيدراس نجراس",
                "label_fr": "Piedras Negras"
            },
            {
                "value": 63601,
                "label": "Paso Real",
                "label_ar": "باسو ريال",
                "label_fr": "Paso Real"
            },
            {
                "value": 63287,
                "label": "Valle Hermoso",
                "label_ar": "فالي هيرموسو",
                "label_fr": "Valle Hermoso"
            },
            {
                "value": 63276,
                "label": "Gustavo A. Madero Borough",
                "label_ar": "جوستافو إيه ماديرو بورو",
                "label_fr": "Arrondissement Gustavo A. Madero"
            },
            {
                "value": 63280,
                "label": "Tlaltizapan",
                "label_ar": "Tlaltizapan",
                "label_fr": "Tlaltizapan"
            },
            {
                "value": 62966,
                "label": "Tulancingo",
                "label_ar": "تولانسينجو",
                "label_fr": "Tulancingo"
            },
            {
                "value": 63527,
                "label": "Jaltipan de Morelos",
                "label_ar": "جالتيبان دي موريلوس",
                "label_fr": "Jaltipan de Morelos"
            },
            {
                "value": 63216,
                "label": "Zacualpan",
                "label_ar": "زاكولبان",
                "label_fr": "Zacualpan"
            },
            {
                "value": 63366,
                "label": "Teapa",
                "label_ar": "تيبا",
                "label_fr": "Teapa"
            },
            {
                "value": 63432,
                "label": "Tizapan el Alto",
                "label_ar": "Tizapan el Alto",
                "label_fr": "Tizapan el Alto"
            },
            {
                "value": 63327,
                "label": "Cajititlan",
                "label_ar": "كاجيتتلان",
                "label_fr": "Cajititlan"
            },
            {
                "value": 63504,
                "label": "Melchor",
                "label_ar": "ميلكور",
                "label_fr": "Melchor"
            },
            {
                "value": 63080,
                "label": "Fresnillo",
                "label_ar": "فريسنيلو",
                "label_fr": "Fresnillo"
            },
            {
                "value": 63502,
                "label": "La Isla",
                "label_ar": "لا إيسلا",
                "label_fr": "La Isla"
            },
            {
                "value": 63090,
                "label": "Acambaro",
                "label_ar": "أكامبارو",
                "label_fr": "Acambaro"
            },
            {
                "value": 63543,
                "label": "Huanimaro",
                "label_ar": "هوانيمارو",
                "label_fr": "Huanimaro"
            },
            {
                "value": 63202,
                "label": "Poza Rica Chacas",
                "label_ar": "Poza Rica Chacas",
                "label_fr": "Poza Rica Chacas"
            },
            {
                "value": 63098,
                "label": "Iztacalco",
                "label_ar": "Iztacalco",
                "label_fr": "Iztacalco"
            },
            {
                "value": 63151,
                "label": "Huauchinango",
                "label_ar": "Huauchinango",
                "label_fr": "Huauchinango"
            },
            {
                "value": 63310,
                "label": "Zacapoaxtla",
                "label_ar": "زاكابوكستلا",
                "label_fr": "Zacapoaxtla"
            },
            {
                "value": 63231,
                "label": "San Miguel Zinacantepec",
                "label_ar": "سان ميغيل زيناكانتيبيك",
                "label_fr": "San Miguel Zinacantepec"
            },
            {
                "value": 63100,
                "label": "Lázaro Cárdenas",
                "label_ar": "لازارو كارديناس",
                "label_fr": "Lázaro Cárdenas"
            },
            {
                "value": 63212,
                "label": "Los Sabinos",
                "label_ar": "لوس سابينوس",
                "label_fr": "Los Sabinos"
            },
            {
                "value": 63522,
                "label": "Gomez Palacio",
                "label_ar": "جوميز بالاسيو",
                "label_fr": "Gomez Palacio"
            },
            {
                "value": 63025,
                "label": "Tula de Allende",
                "label_ar": "تولا دي الليندي",
                "label_fr": "Tula de Allende"
            },
            {
                "value": 63311,
                "label": "Altotonga",
                "label_ar": "ألتوتونجا",
                "label_fr": "Altotonga"
            },
            {
                "value": 63005,
                "label": "Granjas",
                "label_ar": "جرانجاس",
                "label_fr": "Granjas"
            },
            {
                "value": 63650,
                "label": "Pedregal de Jerico",
                "label_ar": "بيدريغال دي جيريكو",
                "label_fr": "Pedregal de Jerico"
            },
            {
                "value": 63660,
                "label": "Sierra Madre",
                "label_ar": "سييرا مادري",
                "label_fr": "Sierra Madre"
            },
            {
                "value": 63196,
                "label": "Tecate",
                "label_ar": "تيكاتي",
                "label_fr": "Tecate"
            },
            {
                "value": 63007,
                "label": "Tlajomulco de Zuniga",
                "label_ar": "Tlajomulco de Zuniga",
                "label_fr": "Tlajomulco de Zuniga"
            },
            {
                "value": 63189,
                "label": "Comalcalco",
                "label_ar": "كومالكالكو",
                "label_fr": "Comalcalco"
            },
            {
                "value": 63668,
                "label": "Benito Juarez (Santa Elena)",
                "label_ar": "بينيتو خواريز (سانتا إيلينا)",
                "label_fr": "Benito Juarez (Santa Elena)"
            },
            {
                "value": 63391,
                "label": "Almoloya",
                "label_ar": "الملوية",
                "label_fr": "Almoloya"
            },
            {
                "value": 63442,
                "label": "Villagran",
                "label_ar": "فيلاجران",
                "label_fr": "Villagran"
            },
            {
                "value": 63579,
                "label": "Roma",
                "label_ar": "روما",
                "label_fr": "Roma"
            },
            {
                "value": 63438,
                "label": "La Union",
                "label_ar": "لا يونيون",
                "label_fr": "La Union"
            },
            {
                "value": 63066,
                "label": "Benito Juarez",
                "label_ar": "شخص اسمه \"بينيتو خواريز",
                "label_fr": "Benito Juarez"
            },
            {
                "value": 63070,
                "label": "Tonala",
                "label_ar": "تونالا",
                "label_fr": "Tonala"
            },
            {
                "value": 63292,
                "label": "San Jose Iturbide",
                "label_ar": "سان خوسيه إيتوربيدي",
                "label_fr": "San Jose Iturbide"
            },
            {
                "value": 63307,
                "label": "Vicente Guerrero",
                "label_ar": "فيسنتي غيريرو",
                "label_fr": "Vicente Guerrero"
            },
            {
                "value": 63125,
                "label": "Mazatlan",
                "label_ar": "مازاتلان",
                "label_fr": "Mazatlan"
            },
            {
                "value": 63614,
                "label": "Mineral del Monte",
                "label_ar": "مينرال ديل مونتي",
                "label_fr": "Mineral del Monte"
            },
            {
                "value": 63224,
                "label": "Campestre Tarimbaro",
                "label_ar": "كامبيستر تاريمبارو",
                "label_fr": "Campestre Tarimbaro"
            },
            {
                "value": 63123,
                "label": "Tlapa de Comonfort",
                "label_ar": "تلابا دي كومونفورت",
                "label_fr": "Tlapa de Comonfort"
            },
            {
                "value": 63133,
                "label": "Isla Holbox",
                "label_ar": "ايسلا هولبوكس",
                "label_fr": "Isla Holbox"
            },
            {
                "value": 63390,
                "label": "Tepetlapa",
                "label_ar": "Tepetlapa",
                "label_fr": "Tepetlapa"
            },
            {
                "value": 63139,
                "label": "Muna",
                "label_ar": "منى",
                "label_fr": "Muna"
            },
            {
                "value": 62997,
                "label": "Jacobo",
                "label_ar": "جاكوبو",
                "label_fr": "Jacobo"
            },
            {
                "value": 63436,
                "label": "Apaseo el Grande",
                "label_ar": "Apaseo el Grande",
                "label_fr": "Apaseo el Grande"
            },
            {
                "value": 62996,
                "label": "Ciudad Obregón",
                "label_ar": "سيوداد أوبريغون",
                "label_fr": "Ciudad Obregón"
            },
            {
                "value": 63251,
                "label": "Vicente Guerrero",
                "label_ar": "فيسنتي غيريرو",
                "label_fr": "Vicente Guerrero"
            },
            {
                "value": 63486,
                "label": "Guadalupe Victoria",
                "label_ar": "غوادالوبي فيكتوريا",
                "label_fr": "Guadalupe Victoria"
            },
            {
                "value": 62968,
                "label": "La Reforma",
                "label_ar": "لا ريفورما",
                "label_fr": "La Reforma"
            },
            {
                "value": 63000,
                "label": "Montemorelos",
                "label_ar": "مونتيموريلوس",
                "label_fr": "Montemorelos"
            },
            {
                "value": 63236,
                "label": "San Mateo Mexicaltzingo",
                "label_ar": "سان ماتيو مكسيكالتزينجو",
                "label_fr": "San Mateo Mexicaltzingo"
            },
            {
                "value": 63489,
                "label": "Anahuac",
                "label_ar": "أناهواك",
                "label_fr": "Anahuac"
            },
            {
                "value": 63520,
                "label": "Kanasin",
                "label_ar": "كاناسين",
                "label_fr": "Kanasin"
            },
            {
                "value": 63635,
                "label": "San Martin Duraznos",
                "label_ar": "سان مارتن دورازنوس",
                "label_fr": "San Martin Duraznos"
            },
            {
                "value": 63229,
                "label": "San Mateo",
                "label_ar": "سان ماتيو",
                "label_fr": "San Mateo"
            },
            {
                "value": 63439,
                "label": "Apaseo el Alto",
                "label_ar": "أباسيو ألتو",
                "label_fr": "Apaseo el Alto"
            },
            {
                "value": 63367,
                "label": "Reforma (Madrigal 2da. Seccion)",
                "label_ar": "ريفورما (Madrigal 2da. Seccion)",
                "label_fr": "Reforma (Madrigal 2da. Seccion)"
            },
            {
                "value": 63182,
                "label": "La Barca",
                "label_ar": "لا باركا",
                "label_fr": "La Barca"
            },
            {
                "value": 63354,
                "label": "Atlzayanca",
                "label_ar": "أتلزايانكا",
                "label_fr": "Atlzayanca"
            },
            {
                "value": 63315,
                "label": "Alvaro Obregon Borough",
                "label_ar": "ألفارو أوبريغون بورو",
                "label_fr": "Arrondissement d'Alvaro Obregon"
            },
            {
                "value": 63179,
                "label": "Allende",
                "label_ar": "الليندي",
                "label_fr": "Allende"
            },
            {
                "value": 63325,
                "label": "Chiapa de Corzo",
                "label_ar": "تشيابا دي كورزو",
                "label_fr": "Chiapa de Corzo"
            },
            {
                "value": 63296,
                "label": "Ciudad de Huajuapam de Leon",
                "label_ar": "Ciudad de Huajuapam de Leon",
                "label_fr": "Ciudad de Huajuapam de Leon"
            },
            {
                "value": 62959,
                "label": "Nuevo Leon",
                "label_ar": "نويفو ليون",
                "label_fr": "Nuevo Leon"
            },
            {
                "value": 62898,
                "label": "Mexicali",
                "label_ar": "مكسيكالي",
                "label_fr": "Mexicali"
            },
            {
                "value": 63262,
                "label": "Tecuala",
                "label_ar": "تيكوالا",
                "label_fr": "Tecuala"
            },
            {
                "value": 63263,
                "label": "Cuarta Brigada",
                "label_ar": "كوارتا بريجادا",
                "label_fr": "Cuarta Brigada"
            },
            {
                "value": 62962,
                "label": "Irapuato",
                "label_ar": "إرابواتو",
                "label_fr": "Irapuato"
            },
            {
                "value": 63337,
                "label": "Iguala de la Independencia",
                "label_ar": "إغوالا دي لا إندبيندينسيا",
                "label_fr": "Iguala de la Independencia"
            },
            {
                "value": 63344,
                "label": "Autopista Puebla Orizaba 185 (La Condusa)",
                "label_ar": "Autopista Puebla Orizaba 185 (لا كوندوزا)",
                "label_fr": "Autopista Puebla Orizaba 185 (La Condusa)"
            },
            {
                "value": 63490,
                "label": "Ojuelos de Jalisco",
                "label_ar": "Ojuelos de Jalisco",
                "label_fr": "Ojuelos de Jalisco"
            },
            {
                "value": 63058,
                "label": "Graciano Sanchez",
                "label_ar": "جراسيانو سانشيز",
                "label_fr": "Graciano Sanchez"
            },
            {
                "value": 63440,
                "label": "San Luis de la Paz",
                "label_ar": "سان لويس دي لاباز",
                "label_fr": "San Luis de la Paz"
            },
            {
                "value": 63349,
                "label": "Medellin de Bravo",
                "label_ar": "ميديلين دي برافو",
                "label_fr": "Medellin de Bravo"
            },
            {
                "value": 63408,
                "label": "Zumpango",
                "label_ar": "زومبانغو",
                "label_fr": "Zumpango"
            },
            {
                "value": 63221,
                "label": "Villadiego",
                "label_ar": "فيلاديجو",
                "label_fr": "Villadiego"
            },
            {
                "value": 63565,
                "label": "Tlatlaya",
                "label_ar": "تلاتلايا",
                "label_fr": "Tlatlaya"
            },
            {
                "value": 63158,
                "label": "Cuautlancingo",
                "label_ar": "Cuautlancingo",
                "label_fr": "Cuautlancingo"
            },
            {
                "value": 62938,
                "label": "Jalapa",
                "label_ar": "جالابا",
                "label_fr": "Jalapa"
            },
            {
                "value": 63226,
                "label": "Tejupilco de Hidalgo",
                "label_ar": "Tejupilco de Hidalgo",
                "label_fr": "Tejupilco de Hidalgo"
            },
            {
                "value": 63341,
                "label": "Ciudad Madero",
                "label_ar": "سيوداد ماديرو",
                "label_fr": "Ciudad Madero"
            },
            {
                "value": 63631,
                "label": "Jonacatepec de Leandro Valle",
                "label_ar": "جوناكاتيبيك دي لياندرو فالي",
                "label_fr": "Jonacatepec de Leandro Valle"
            },
            {
                "value": 63274,
                "label": "Tlalnepantla",
                "label_ar": "تلانيبانتلا",
                "label_fr": "Tlalnepantla"
            },
            {
                "value": 63396,
                "label": "Xonacatlan",
                "label_ar": "زوناكاتلان",
                "label_fr": "Xonacatlan"
            },
            {
                "value": 63610,
                "label": "Cardonal",
                "label_ar": "كاردونال",
                "label_fr": "Cardonal"
            },
            {
                "value": 63156,
                "label": "Refractarios Guadalajara",
                "label_ar": "Refractarios غوادالاخارا",
                "label_fr": "Refractarios Guadalajara"
            },
            {
                "value": 62932,
                "label": "Guanajuato",
                "label_ar": "غواناخواتو",
                "label_fr": "Guanajuato"
            },
            {
                "value": 63547,
                "label": "Las Cabanas (Colinas de los Angeles)",
                "label_ar": "لاس كاباناس (كوليناس دي لوس أنجلوس)",
                "label_fr": "Las Cabanas (Colinas de los Angeles)"
            },
            {
                "value": 63208,
                "label": "Cunduacan",
                "label_ar": "كونداكان",
                "label_fr": "Cunduacan"
            },
            {
                "value": 63096,
                "label": "Morelos",
                "label_ar": "موريلوس",
                "label_fr": "Morelos"
            },
            {
                "value": 63655,
                "label": "Uriangato",
                "label_ar": "يوريانجاتو",
                "label_fr": "Uriangato"
            },
            {
                "value": 63160,
                "label": "Iztapalapa",
                "label_ar": "إزتابالابا",
                "label_fr": "Iztapalapa"
            },
            {
                "value": 63623,
                "label": "Azteca",
                "label_ar": "أزتيكا",
                "label_fr": "Azteca"
            },
            {
                "value": 62947,
                "label": "Tampico",
                "label_ar": "تامبيكو",
                "label_fr": "Tampico"
            },
            {
                "value": 63033,
                "label": "Nuevo Casas Grandes",
                "label_ar": "نويفو كاساس غرانديز",
                "label_fr": "Nuevo Casas Grandes"
            },
            {
                "value": 63051,
                "label": "Atlixco",
                "label_ar": "أتليكسكو",
                "label_fr": "Atlixco"
            },
            {
                "value": 63146,
                "label": "Nacozari Viejo",
                "label_ar": "ناكوزاري فيجو",
                "label_fr": "Nacozari Viejo"
            },
            {
                "value": 63531,
                "label": "Estados Unidos",
                "label_ar": "Estados Unidos",
                "label_fr": "Estados Unidos"
            },
            {
                "value": 63542,
                "label": "Parral",
                "label_ar": "بارال",
                "label_fr": "Parral"
            },
            {
                "value": 63040,
                "label": "Los Reyes",
                "label_ar": "لوس رييس",
                "label_fr": "Los Reyes"
            },
            {
                "value": 63533,
                "label": "El Carmen",
                "label_ar": "الكارمن",
                "label_fr": "El Carmen"
            },
            {
                "value": 63154,
                "label": "Escobedo",
                "label_ar": "اسكوبيدو",
                "label_fr": "Escobedo"
            },
            {
                "value": 63413,
                "label": "Quintana Roo",
                "label_ar": "كوينتانا رو",
                "label_fr": "Quintana Roo"
            },
            {
                "value": 63423,
                "label": "Mariano Matamoros",
                "label_ar": "ماريانو ماتاموروس",
                "label_fr": "Mariano Matamoros"
            },
            {
                "value": 63379,
                "label": "Jilotepec",
                "label_ar": "جيلوتيبيك",
                "label_fr": "Jilotepec"
            },
            {
                "value": 63198,
                "label": "Emiliano Zapata",
                "label_ar": "إميليانو زاباتا",
                "label_fr": "Emiliano Zapata"
            },
            {
                "value": 63649,
                "label": "San Angel Inn",
                "label_ar": "سان انجل ان",
                "label_fr": "Auberge San Angel"
            },
            {
                "value": 63317,
                "label": "Juarez",
                "label_ar": "خواريز",
                "label_fr": "Juarez"
            },
            {
                "value": 62944,
                "label": "Arriaga",
                "label_ar": "أرياغا",
                "label_fr": "Arriaga"
            },
            {
                "value": 63220,
                "label": "Zacapu",
                "label_ar": "زاكابو",
                "label_fr": "Zacapu"
            },
            {
                "value": 63398,
                "label": "Atlacomulco",
                "label_ar": "اتلاكومولكو",
                "label_fr": "Atlacomulco"
            },
            {
                "value": 63313,
                "label": "Selene",
                "label_ar": "سيلين",
                "label_fr": "Selene"
            },
            {
                "value": 63446,
                "label": "Zapotlanejo",
                "label_ar": "زابوتلانيجو",
                "label_fr": "Zapotlanejo"
            },
            {
                "value": 63424,
                "label": "Soto la Marina",
                "label_ar": "سوتو لا مارينا",
                "label_fr": "Soto la Marina"
            },
            {
                "value": 63410,
                "label": "Asuncion Nochixtlan",
                "label_ar": "أسونسيون نوتشيكستلان",
                "label_fr": "Asuncion Nochixtlan"
            },
            {
                "value": 63478,
                "label": "Huatabampo",
                "label_ar": "هواتابامبو",
                "label_fr": "Huatabampo"
            },
            {
                "value": 63431,
                "label": "Atequiza",
                "label_ar": "أتيكيزا",
                "label_fr": "Atequiza"
            },
            {
                "value": 63621,
                "label": "El Parque",
                "label_ar": "إل بارك",
                "label_fr": "El Parque"
            },
            {
                "value": 63351,
                "label": "Chiautempan",
                "label_ar": "شياوتيمبان",
                "label_fr": "Chiautempan"
            },
            {
                "value": 63083,
                "label": "Jesus Maria",
                "label_ar": "يسوع ماريا",
                "label_fr": "Jésus Maria"
            },
            {
                "value": 63227,
                "label": "Tenancingo de Degollado",
                "label_ar": "Tenancingo دي ديغولادو",
                "label_fr": "Tenancingo de Degollado"
            },
            {
                "value": 63012,
                "label": "Mineral de la Reforma",
                "label_ar": "مينرال دي لا ريفورما",
                "label_fr": "Minéral de la Reforma"
            },
            {
                "value": 63449,
                "label": "San Luis Soyatlan",
                "label_ar": "سان لويس سوياتلان",
                "label_fr": "San Luis Soyatlan"
            },
            {
                "value": 63548,
                "label": "General Teran",
                "label_ar": "الجنرال تيران",
                "label_fr": "Général Teran"
            },
            {
                "value": 63618,
                "label": "Xochimilco",
                "label_ar": "زوتشيميلكو",
                "label_fr": "Xochimilco"
            },
            {
                "value": 63114,
                "label": "Los Mochis",
                "label_ar": "لوس موتشيس",
                "label_fr": "Los Mochis"
            },
            {
                "value": 62945,
                "label": "Jiutepec",
                "label_ar": "جيوتبيك",
                "label_fr": "Jiutepec"
            },
            {
                "value": 63611,
                "label": "Zacualtipan",
                "label_ar": "زاكوالتيبان",
                "label_fr": "Zacualtipan"
            },
            {
                "value": 63282,
                "label": "Temascaltepec de Gonzalez",
                "label_ar": "Temascaltepec de Gonzalez",
                "label_fr": "Temascaltepec de Gonzalez"
            },
            {
                "value": 63360,
                "label": "Las Choapas",
                "label_ar": "لاس تشواباس",
                "label_fr": "Las Choapas"
            },
            {
                "value": 63469,
                "label": "Las Canoras (Altiva)",
                "label_ar": "لاس كانوراس (ألتيفا)",
                "label_fr": "Las Canoras (Altiva)"
            },
            {
                "value": 63022,
                "label": "Pueblo Juarez (La Magdalena)",
                "label_ar": "بويبلو خواريز (لا ماجدالينا)",
                "label_fr": "Pueblo Juarez (La Magdalena)"
            },
            {
                "value": 63043,
                "label": "Los Reyes de Salgado",
                "label_ar": "لوس رييس دي سالغادو",
                "label_fr": "Los Reyes de Salgado"
            },
            {
                "value": 63290,
                "label": "Madero",
                "label_ar": "ماديرو",
                "label_fr": "Madero"
            },
            {
                "value": 63076,
                "label": "San Miguel de Cozumel",
                "label_ar": "سان ميغيل دي كوزوميل",
                "label_fr": "San Miguel de Cozumel"
            },
            {
                "value": 63464,
                "label": "Tenamaxtlan",
                "label_ar": "تيناماكستلان",
                "label_fr": "Tenamaxtlan"
            },
            {
                "value": 63350,
                "label": "San Juan Bautista Tuxtla",
                "label_ar": "سان خوان باوتيستا توكستلا",
                "label_fr": "San Juan Bautista Tuxtla"
            },
            {
                "value": 63006,
                "label": "Temascalcingo",
                "label_ar": "Temascalcingo",
                "label_fr": "Temascalcingo"
            },
            {
                "value": 63268,
                "label": "Purisima de Bustos",
                "label_ar": "بوريسيما دي بوستوس",
                "label_fr": "Purisima de Bustos"
            },
            {
                "value": 63374,
                "label": "Cuautepec",
                "label_ar": "كواوتبيك",
                "label_fr": "Cuautepec"
            },
            {
                "value": 63516,
                "label": "Ures",
                "label_ar": "يورس",
                "label_fr": "Ures"
            },
            {
                "value": 63261,
                "label": "Ixtapa",
                "label_ar": "اكستابا",
                "label_fr": "Ixtapa"
            },
            {
                "value": 62951,
                "label": "Ciudad del Carmen",
                "label_ar": "سيوداد ديل كارمن",
                "label_fr": "Ciudad del Carmen"
            },
            {
                "value": 63137,
                "label": "Chetumal",
                "label_ar": "شيتومال",
                "label_fr": "Chetumal"
            },
            {
                "value": 63149,
                "label": "Magdalena de Kino",
                "label_ar": "ماجدالينا دي كينو",
                "label_fr": "Magdalena de Kino"
            },
            {
                "value": 63199,
                "label": "Valles",
                "label_ar": "فاليس",
                "label_fr": "Valles"
            },
            {
                "value": 62897,
                "label": "Pachuca",
                "label_ar": "باتشوكا",
                "label_fr": "Pachuca"
            },
            {
                "value": 63318,
                "label": "Banderilla",
                "label_ar": "Banderilla",
                "label_fr": "Banderilla"
            },
            {
                "value": 63335,
                "label": "San Martin de Camargo",
                "label_ar": "سان مارتن دي كامارجو",
                "label_fr": "San Martin de Camargo"
            },
            {
                "value": 63120,
                "label": "Ahome",
                "label_ar": "بيت",
                "label_fr": "Une maison"
            },
            {
                "value": 63384,
                "label": "Temixco",
                "label_ar": "تيميكسكو",
                "label_fr": "Temixco"
            },
            {
                "value": 63636,
                "label": "Pachuca de Soto",
                "label_ar": "باتشوكا دي سوتو",
                "label_fr": "Pachuca de Soto"
            },
            {
                "value": 62893,
                "label": "San Nicolás de los Garza",
                "label_ar": "سان نيكولاس دي لوس غارزا",
                "label_fr": "San Nicolás de los Garza"
            },
            {
                "value": 63387,
                "label": "Xochitepec",
                "label_ar": "Xochitepec",
                "label_fr": "Xochitepec"
            },
            {
                "value": 63169,
                "label": "Mendoza",
                "label_ar": "مندوزا",
                "label_fr": "Mendoza"
            },
            {
                "value": 63479,
                "label": "Ezequiel Montes",
                "label_ar": "إزيكويل مونتيس",
                "label_fr": "Ezequiel Montes"
            },
            {
                "value": 63644,
                "label": "Chihuahua",
                "label_ar": "شيواوا",
                "label_fr": "Chihuahua"
            },
            {
                "value": 63183,
                "label": "Tacambaro de Codallos",
                "label_ar": "تاكامبارو دي كودالوس",
                "label_fr": "Tacambaro de Codallos"
            },
            {
                "value": 63204,
                "label": "Seccion Primera Santiago Tetla",
                "label_ar": "Seccion Primera Santiago Tetla",
                "label_fr": "Seccion Primera Santiago Tetla"
            },
            {
                "value": 62992,
                "label": "Santa Catarina",
                "label_ar": "سانتا كاتارينا",
                "label_fr": "Santa Catarina"
            },
            {
                "value": 62891,
                "label": "Mexico City",
                "label_ar": "مكسيكو سيتي",
                "label_fr": "Mexico"
            },
            {
                "value": 62917,
                "label": "Colonia Mexico",
                "label_ar": "كولونيا المكسيك",
                "label_fr": "Colonia Mexique"
            },
            {
                "value": 63560,
                "label": "Puerto Mexico",
                "label_ar": "بويرتو المكسيك",
                "label_fr": "Puerto Mexique"
            },
            {
                "value": 63603,
                "label": "Jesus Maria",
                "label_ar": "يسوع ماريا",
                "label_fr": "Jésus Maria"
            },
            {
                "value": 62927,
                "label": "Tlaxcala",
                "label_ar": "تلاكسكالا",
                "label_fr": "Tlaxcala"
            },
            {
                "value": 63526,
                "label": "Progreso",
                "label_ar": "بروغريسو",
                "label_fr": "Progreso"
            },
            {
                "value": 62972,
                "label": "Boca del Rio",
                "label_ar": "بوكا ديل ريو",
                "label_fr": "Boca del Rio"
            },
            {
                "value": 63426,
                "label": "Colonia Miguel Aleman Valdez",
                "label_ar": "كولونيا ميغيل أليمان فالديز",
                "label_fr": "Colonia Miguel Aleman Valdez"
            },
            {
                "value": 63233,
                "label": "Tonatico",
                "label_ar": "توناتيكو",
                "label_fr": "Tonatico"
            },
            {
                "value": 63538,
                "label": "Coyuca de Catalan",
                "label_ar": "كويوكا دي كتالان",
                "label_fr": "Coyuca de Catalan"
            },
            {
                "value": 63606,
                "label": "Apaxco de Ocampo",
                "label_ar": "أباكسكو دي أوكامبو",
                "label_fr": "Apaxco de Ocampo"
            },
            {
                "value": 63091,
                "label": "Pedro Escobedo",
                "label_ar": "بيدرو اسكوبيدو",
                "label_fr": "Pedro Escobedo"
            },
            {
                "value": 63452,
                "label": "Guadalupito",
                "label_ar": "غوادالوبيتو",
                "label_fr": "Guadalupito"
            },
            {
                "value": 63411,
                "label": "Santa Catarina Juquila",
                "label_ar": "سانتا كاتارينا جوكويلا",
                "label_fr": "Santa Catarina Juquila"
            },
            {
                "value": 63451,
                "label": "Huejucar",
                "label_ar": "هوجوكار",
                "label_fr": "Huejucar"
            },
            {
                "value": 63029,
                "label": "Cocula",
                "label_ar": "كوكولا",
                "label_fr": "Cocula"
            },
            {
                "value": 63587,
                "label": "San Pedro Pochutla",
                "label_ar": "سان بيدرو بوكوتلا",
                "label_fr": "San Pedro Pochutla"
            },
            {
                "value": 63375,
                "label": "Colonia Lopez Nelio (Las Palmas)",
                "label_ar": "كولونيا لوبيز نيليو (لاس بالماس)",
                "label_fr": "Colonia Lopez Nelio (Las Palmas)"
            },
            {
                "value": 63138,
                "label": "Tecax",
                "label_ar": "تيكاكس",
                "label_fr": "Tecax"
            },
            {
                "value": 63604,
                "label": "Jocotitlan",
                "label_ar": "جوكوتيتلان",
                "label_fr": "Jocotitlan"
            },
            {
                "value": 63561,
                "label": "Chiautla",
                "label_ar": "شياوتلا",
                "label_fr": "Chiautla"
            },
            {
                "value": 63044,
                "label": "Tangancicuaro de Arista",
                "label_ar": "Tangancicuaro de Arista",
                "label_fr": "Tangancicuaro de Arista"
            },
            {
                "value": 63283,
                "label": "San Agustin",
                "label_ar": "سان اجستين",
                "label_fr": "San Agustin"
            },
            {
                "value": 63232,
                "label": "Valle de Bravo",
                "label_ar": "فالي دي برافو",
                "label_fr": "Valle de Bravo"
            },
            {
                "value": 63409,
                "label": "Puerto Escondido",
                "label_ar": "بويرتو إسكونديدو",
                "label_fr": "Puerto Escondido"
            },
            {
                "value": 63639,
                "label": "San Luis Potosí",
                "label_ar": "سان لويس بوتوسي",
                "label_fr": "San Luis Potosí"
            },
            {
                "value": 63481,
                "label": "Manuel Avila Camacho",
                "label_ar": "مانويل أفيلا كاماتشو",
                "label_fr": "Manuel Avila Camacho"
            },
            {
                "value": 63190,
                "label": "Cardenas",
                "label_ar": "كارديناس",
                "label_fr": "Cardenas"
            },
            {
                "value": 63110,
                "label": "Tizimín",
                "label_ar": "تيزمين",
                "label_fr": "Tizimín"
            },
            {
                "value": 63045,
                "label": "La Piedad",
                "label_ar": "لا بيداد",
                "label_fr": "La Piedad"
            },
            {
                "value": 63060,
                "label": "Linares",
                "label_ar": "ليناريس",
                "label_fr": "Linares"
            },
            {
                "value": 63540,
                "label": "Axochiapan",
                "label_ar": "أكسوتشيابان",
                "label_fr": "Axochiapan"
            },
            {
                "value": 63235,
                "label": "Luvianos",
                "label_ar": "لوفيانوس",
                "label_fr": "Luvianos"
            },
            {
                "value": 63404,
                "label": "Huehuetoca",
                "label_ar": "هويهويتوكا",
                "label_fr": "Huehuetoca"
            },
            {
                "value": 62984,
                "label": "Adolfo Ruiz Cortines",
                "label_ar": "أدولفو رويز كورتينيس",
                "label_fr": "Adolfo Ruiz Cortines"
            },
            {
                "value": 63298,
                "label": "Miguel Hidalgo",
                "label_ar": "ميغيل هيدالجو",
                "label_fr": "Miguel Hidalgo"
            },
            {
                "value": 63394,
                "label": "San Simon de Guerrero",
                "label_ar": "سان سيمون دي غيريرو",
                "label_fr": "San Simon de Guerrero"
            },
            {
                "value": 62933,
                "label": "La Paz",
                "label_ar": "لاباز",
                "label_fr": "La Paz"
            },
            {
                "value": 63443,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 63580,
                "label": "Coahuila",
                "label_ar": "كواهويلا",
                "label_fr": "Coahuila"
            },
            {
                "value": 63010,
                "label": "Insurgentes",
                "label_ar": "المتمردون",
                "label_fr": "Insurgentes"
            },
            {
                "value": 63519,
                "label": "San Luis Río Colorado",
                "label_ar": "سان لويس ريو كولورادو",
                "label_fr": "San Luis Río Colorado"
            },
            {
                "value": 63511,
                "label": "Bamoa",
                "label_ar": "باموا",
                "label_fr": "Bamoa"
            },
            {
                "value": 63192,
                "label": "Villa Nanchital",
                "label_ar": "فيلا نانتشيتال",
                "label_fr": "Villa Nanchital"
            },
            {
                "value": 63087,
                "label": "Ocozocoautla de Espinosa",
                "label_ar": "Ocozocoautla de Espinosa",
                "label_fr": "Ocozocoautla de Espinosa"
            },
            {
                "value": 63145,
                "label": "La Paletena",
                "label_ar": "لا باليتينا",
                "label_fr": "La Paletena"
            },
            {
                "value": 63004,
                "label": "San Patricio",
                "label_ar": "سان باتريسيو",
                "label_fr": "San Patricio"
            },
            {
                "value": 63205,
                "label": "San Salvador Tzompantepec",
                "label_ar": "سان سلفادور تزومبانتيبيك",
                "label_fr": "San Salvador Tzompantepec"
            },
            {
                "value": 62977,
                "label": "Ramos Arizpe",
                "label_ar": "راموس أريزبي",
                "label_fr": "Ramos Arizpe"
            },
            {
                "value": 62920,
                "label": "Saltillo",
                "label_ar": "سالتيلو",
                "label_fr": "Saltillo"
            },
            {
                "value": 63068,
                "label": "El Colli",
                "label_ar": "الكولي",
                "label_fr": "El Colli"
            },
            {
                "value": 63253,
                "label": "Colonia Cuauhtemoc",
                "label_ar": "كولونيا كواوتيموك",
                "label_fr": "Colonia Cuauhtemoc"
            },
            {
                "value": 63243,
                "label": "Jaumave",
                "label_ar": "جاومافي",
                "label_fr": "Jaumave"
            },
            {
                "value": 62936,
                "label": "Abasolo",
                "label_ar": "أباسولو",
                "label_fr": "Abasolo"
            },
            {
                "value": 63027,
                "label": "Chiapas",
                "label_ar": "تشياباس",
                "label_fr": "Chiapas"
            },
            {
                "value": 63528,
                "label": "San Juan de Aragon",
                "label_ar": "سان خوان دي أراغون",
                "label_fr": "San Juan de Aragon"
            },
            {
                "value": 63425,
                "label": "Altamira",
                "label_ar": "التاميرا",
                "label_fr": "Altamira"
            },
            {
                "value": 63499,
                "label": "Martinez de la Torre",
                "label_ar": "مارتينيز دي لا توري",
                "label_fr": "Martinez de la Torre"
            },
            {
                "value": 62919,
                "label": "Ciudad Juárez",
                "label_ar": "سيوداد خواريز",
                "label_fr": "Ciudad Juárez"
            },
            {
                "value": 63047,
                "label": "Izucar de Matamoros",
                "label_ar": "إيزوكار دي ماتاموروس",
                "label_fr": "Izucar de Matamoros"
            },
            {
                "value": 63285,
                "label": "Zacualpan",
                "label_ar": "زاكولبان",
                "label_fr": "Zacualpan"
            },
            {
                "value": 63118,
                "label": "El Fuerte de Montes Claros",
                "label_ar": "El Fuerte de Montes Claros",
                "label_fr": "El Fuerte de Montes Claros"
            },
            {
                "value": 63230,
                "label": "Zacatepec",
                "label_ar": "زاكاتيبيك",
                "label_fr": "Zacatepec"
            },
            {
                "value": 63053,
                "label": "Libres",
                "label_ar": "ليبريس",
                "label_fr": "Libres"
            },
            {
                "value": 63269,
                "label": "Nicolas Romero",
                "label_ar": "نيكولاس روميرو",
                "label_fr": "Nicolas Romero"
            },
            {
                "value": 63084,
                "label": "Tapachula",
                "label_ar": "تاباتشولا",
                "label_fr": "Tapachula"
            },
            {
                "value": 63168,
                "label": "Miguel Aleman",
                "label_ar": "ميغيل أليمان",
                "label_fr": "Miguel Aleman"
            },
            {
                "value": 63356,
                "label": "San Sebastian Tutla",
                "label_ar": "سان سيباستيان توتلا",
                "label_fr": "Saint-Sébastien Tutla"
            },
            {
                "value": 63450,
                "label": "Autlan de Navarro",
                "label_ar": "أوتلان دي نافارو",
                "label_fr": "Autlan de Navarro"
            },
            {
                "value": 63113,
                "label": "Yucatan",
                "label_ar": "يوكاتان",
                "label_fr": "Yucatan"
            },
            {
                "value": 63665,
                "label": "San Francisco",
                "label_ar": "سان فرانسيسكو",
                "label_fr": "San Francisco"
            },
            {
                "value": 63357,
                "label": "Acatzingo de Hidalgo",
                "label_ar": "أكاتسينغو دي هيدالجو",
                "label_fr": "Acatzingo de Hidalgo"
            },
            {
                "value": 63347,
                "label": "Heroica Alvarado",
                "label_ar": "هيرويكا ألفارادو",
                "label_fr": "Heroica Alvarado"
            },
            {
                "value": 63329,
                "label": "San Sebastian el Grande",
                "label_ar": "سان سيباستيان إل غراندي",
                "label_fr": "Saint-Sébastien el Grande"
            },
            {
                "value": 63641,
                "label": "Visitacion",
                "label_ar": "فيزاسيون",
                "label_fr": "Visitacion"
            },
            {
                "value": 63143,
                "label": "Heroica Caborca",
                "label_ar": "هيرويكا كابوركا",
                "label_fr": "Heroica Caborca"
            },
            {
                "value": 63353,
                "label": "Chignahuapan",
                "label_ar": "Chignahuapan",
                "label_fr": "Chignahuapan"
            },
            {
                "value": 63393,
                "label": "Ejido Santa Maria Tianguistenco (Ejido el Rosario)",
                "label_ar": "إجيدو سانتا ماريا تيانغويستينكو (إجيدو إل روزاريو)",
                "label_fr": "Ejido Santa Maria Tianguistenco (Ejido el Rosario)"
            },
            {
                "value": 63361,
                "label": "San Cristobal",
                "label_ar": "سان كريستوبال",
                "label_fr": "San Cristobal"
            },
            {
                "value": 63488,
                "label": "Villa de Costa Rica",
                "label_ar": "فيلا دي كوستاريكا",
                "label_fr": "Villa de Costa Rica"
            },
            {
                "value": 62909,
                "label": "Ecatepec de Morelos",
                "label_ar": "إيكاتيبيك دي موريلوس",
                "label_fr": "Ecatepec de Morelos"
            },
            {
                "value": 63562,
                "label": "Gutierrez Zamora",
                "label_ar": "جوتيريز زامورا",
                "label_fr": "Gutierrez Zamora"
            },
            {
                "value": 63573,
                "label": "Tlatlauquitepec",
                "label_ar": "تلاتلاوكويتيبيك",
                "label_fr": "Tlatlauquitepec"
            },
            {
                "value": 63343,
                "label": "San Salvador Huixcolotla",
                "label_ar": "سان سلفادور هويسكولوتلا",
                "label_fr": "San Salvador Huixcolotla"
            },
            {
                "value": 63067,
                "label": "Santiago",
                "label_ar": "سانتياغو",
                "label_fr": "Santiago"
            },
            {
                "value": 63400,
                "label": "Tezontepec",
                "label_ar": "تيزونتيبيك",
                "label_fr": "Tezontepec"
            },
            {
                "value": 63472,
                "label": "Badiraguato",
                "label_ar": "Badiraguato",
                "label_fr": "Badiraguato"
            },
            {
                "value": 63447,
                "label": "Ixtlahuacan del Rio",
                "label_ar": "اكستلاهواكان ديل ريو",
                "label_fr": "Ixtlahuacan del Rio"
            },
            {
                "value": 63541,
                "label": "Almada",
                "label_ar": "ألمادا",
                "label_fr": "Almada"
            },
            {
                "value": 63352,
                "label": "Zacatelco",
                "label_ar": "زاكاتلكو",
                "label_fr": "Zacatelco"
            },
            {
                "value": 63485,
                "label": "San Gabriel",
                "label_ar": "سان جبرائيل",
                "label_fr": "San Gabriel"
            },
            {
                "value": 63574,
                "label": "Cosamaloapan de Carpio",
                "label_ar": "كوسامالوابان دي كاربيو",
                "label_fr": "Cosamaloapan de Carpio"
            },
            {
                "value": 63412,
                "label": "Morelos",
                "label_ar": "موريلوس",
                "label_fr": "Morelos"
            },
            {
                "value": 63072,
                "label": "El Salto",
                "label_ar": "السالتو",
                "label_fr": "El Salto"
            },
            {
                "value": 62949,
                "label": "Tuxpan",
                "label_ar": "توكسبان",
                "label_fr": "Tuxpan"
            },
            {
                "value": 63039,
                "label": "Pastor Ortiz",
                "label_ar": "القس أورتيز",
                "label_fr": "Pasteur Ortiz"
            },
            {
                "value": 62960,
                "label": "Nuevo Laredo",
                "label_ar": "نويفو لاريدو",
                "label_fr": "Nuevo Laredo"
            },
            {
                "value": 63237,
                "label": "Malinalco",
                "label_ar": "مالينالكو",
                "label_fr": "Malinalco"
            },
            {
                "value": 63377,
                "label": "Jesus del Monte",
                "label_ar": "جيسوس ديل مونتي",
                "label_fr": "Jésus del Monte"
            },
            {
                "value": 63441,
                "label": "San Felipe Torres Mochas [Ganaderia]",
                "label_ar": "سان فيليبي توريس موكا [غاناديريا]",
                "label_fr": "San Felipe Torres Mochas [Ganaderia]"
            },
            {
                "value": 63461,
                "label": "Erongaricuaro",
                "label_ar": "ارونجاريكوارو",
                "label_fr": "Erongaricuaro"
            },
            {
                "value": 63628,
                "label": "Cutzamala de Pinzon",
                "label_ar": "Cutzamala دي بينزون",
                "label_fr": "Cutzamala de Pinzon"
            },
            {
                "value": 63026,
                "label": "Tampico Alto",
                "label_ar": "تامبيكو ألتو",
                "label_fr": "Tampico Alto"
            },
            {
                "value": 63302,
                "label": "Tepeji de Ocampo",
                "label_ar": "تيبيجي دي أوكامبو",
                "label_fr": "Tepeji de Ocampo"
            },
            {
                "value": 62935,
                "label": "Celaya",
                "label_ar": "سيلايا",
                "label_fr": "Celaya"
            },
            {
                "value": 63348,
                "label": "Tlacotalpan",
                "label_ar": "تلاكوتالبان",
                "label_fr": "Tlacotalpan"
            },
            {
                "value": 63546,
                "label": "Huimilpan",
                "label_ar": "هويميلبان",
                "label_fr": "Huimilpan"
            },
            {
                "value": 63085,
                "label": "San Cristóbal de las Casas",
                "label_ar": "سان كريستوبال دي لاس كاساس",
                "label_fr": "San Cristóbal de las Casas"
            },
            {
                "value": 63254,
                "label": "Mazatecochco",
                "label_ar": "مازاتيكوتشكو",
                "label_fr": "Mazatecochco"
            },
            {
                "value": 63266,
                "label": "Purisima del Zapote",
                "label_ar": "بوريسيما ديل زابوتي",
                "label_fr": "Purisima del Zapote"
            },
            {
                "value": 63585,
                "label": "San Fernando",
                "label_ar": "سان فرناندو",
                "label_fr": "San Fernando"
            },
            {
                "value": 63121,
                "label": "La Cruz",
                "label_ar": "لا كروز",
                "label_fr": "La Cruz"
            },
            {
                "value": 63024,
                "label": "Chilpancingo",
                "label_ar": "تشيلبانسينجو",
                "label_fr": "Chilpancingo"
            },
            {
                "value": 63616,
                "label": "San Pedro de Las Colonias",
                "label_ar": "سان بيدرو دي لاس كولونياس",
                "label_fr": "San Pedro de Las Colonias"
            },
            {
                "value": 63332,
                "label": "Cazones de Herrera",
                "label_ar": "كازونيس دي هيريرا",
                "label_fr": "Cazones de Herrera"
            },
            {
                "value": 63275,
                "label": "Atizapan",
                "label_ar": "أتيزابان",
                "label_fr": "Atizapan"
            },
            {
                "value": 63207,
                "label": "Epazoyucan",
                "label_ar": "إبازويوكان",
                "label_fr": "Epazoyucan"
            },
            {
                "value": 63173,
                "label": "Ciudad Mendoza",
                "label_ar": "سيوداد مندوزا",
                "label_fr": "Ciudad Mendoza"
            },
            {
                "value": 63319,
                "label": "Emiliano Zapata",
                "label_ar": "إميليانو زاباتا",
                "label_fr": "Emiliano Zapata"
            },
            {
                "value": 63630,
                "label": "Puente de Ixtla",
                "label_ar": "بوينتي دي اكستلا",
                "label_fr": "Puente de Ixtla"
            },
            {
                "value": 63281,
                "label": "San Mateo Atenco",
                "label_ar": "سان ماتيو أتينكو",
                "label_fr": "San Mateo Atenco"
            },
            {
                "value": 63218,
                "label": "Manzanillo",
                "label_ar": "مانزانيلو",
                "label_fr": "Manzanillo"
            },
            {
                "value": 63223,
                "label": "Huandacareo",
                "label_ar": "هوانداكارو",
                "label_fr": "Huandacareo"
            },
            {
                "value": 63284,
                "label": "Fraccionamiento Iberica Calimaya",
                "label_ar": "Fraccionamiento Iberica Calimaya",
                "label_fr": "Fraccionamiento Iberica Calimaya"
            },
            {
                "value": 63206,
                "label": "Guadalajara",
                "label_ar": "غوادالاخارا",
                "label_fr": "Guadalajara"
            },
            {
                "value": 63586,
                "label": "San Luis",
                "label_ar": "سان لويس",
                "label_fr": "San Luis"
            },
            {
                "value": 63320,
                "label": "Angamacutiro de la Union",
                "label_ar": "أنغاماكوتيرو دي لا يونيون",
                "label_fr": "Angamacutiro de la Union"
            },
            {
                "value": 63624,
                "label": "Barra de Navidad",
                "label_ar": "بارا دي نافيداد",
                "label_fr": "Barra de Navidad"
            },
            {
                "value": 63081,
                "label": "Sombrerete",
                "label_ar": "سومبريريت",
                "label_fr": "Sombrerete"
            },
            {
                "value": 63659,
                "label": "Florida",
                "label_ar": "فلوريدا",
                "label_fr": "Floride"
            },
            {
                "value": 63089,
                "label": "Cortazar",
                "label_ar": "كورتازار",
                "label_fr": "Cortazar"
            },
            {
                "value": 63186,
                "label": "Ciudad Ayala",
                "label_ar": "سيوداد أيالا",
                "label_fr": "Ciudad Ayala"
            },
            {
                "value": 63554,
                "label": "Aldama",
                "label_ar": "الداما",
                "label_fr": "Aldama"
            },
            {
                "value": 63078,
                "label": "San Agustin (Salvador Alvarado)",
                "label_ar": "سان أوجستين (سلفادور ألفارادو)",
                "label_fr": "San Agustin (Salvador Alvarado)"
            },
            {
                "value": 63014,
                "label": "Tultitlan",
                "label_ar": "تولتيتلان",
                "label_fr": "Tultitlan"
            },
            {
                "value": 63079,
                "label": "Morelos",
                "label_ar": "موريلوس",
                "label_fr": "Morelos"
            },
            {
                "value": 63252,
                "label": "Huejotzingo",
                "label_ar": "هوجوتزينغو",
                "label_fr": "Huejotzingo"
            },
            {
                "value": 63272,
                "label": "San Francisco",
                "label_ar": "سان فرانسيسكو",
                "label_fr": "San Francisco"
            },
            {
                "value": 63219,
                "label": "Tecoman",
                "label_ar": "تيكومان",
                "label_fr": "Tecoman"
            },
            {
                "value": 63217,
                "label": "Ixtlan del Rio",
                "label_ar": "اكستلان ديل ريو",
                "label_fr": "Ixtlan del Rio"
            },
            {
                "value": 63340,
                "label": "Las Delicias",
                "label_ar": "لاس ديليسياس",
                "label_fr": "Las Delicias"
            },
            {
                "value": 63383,
                "label": "Cocoyoc",
                "label_ar": "كوكويوك",
                "label_fr": "Cocoyoc"
            },
            {
                "value": 63170,
                "label": "Tezonapa",
                "label_ar": "تيزونابا",
                "label_fr": "Tezonapa"
            },
            {
                "value": 63430,
                "label": "Mineral",
                "label_ar": "المعدنية",
                "label_fr": "Minéral"
            },
            {
                "value": 63200,
                "label": "San Vicente Chicoloapan",
                "label_ar": "سان فيسنتي تشيكولوابان",
                "label_fr": "San Vicente Chicoloapan"
            },
            {
                "value": 62923,
                "label": "Toluca",
                "label_ar": "تولوكا",
                "label_fr": "Toluca"
            },
            {
                "value": 63465,
                "label": "Chapala",
                "label_ar": "تشابالا",
                "label_fr": "Chapala"
            },
            {
                "value": 63172,
                "label": "Paraje Nuevo",
                "label_ar": "باراي نويفو",
                "label_fr": "Paraje Nuevo"
            },
            {
                "value": 63600,
                "label": "La Joya",
                "label_ar": "لا جويا",
                "label_fr": "La Joya"
            },
            {
                "value": 63581,
                "label": "Coyoacan",
                "label_ar": "كويواكان",
                "label_fr": "Coyoacan"
            },
            {
                "value": 63402,
                "label": "Tlanalapa",
                "label_ar": "تلانالابا",
                "label_fr": "Tlanalapa"
            },
            {
                "value": 63493,
                "label": "Tlacolula de Matamoros",
                "label_ar": "تلاكولولا دي ماتاموروس",
                "label_fr": "Tlacolula de Matamoros"
            },
            {
                "value": 63370,
                "label": "Paraiso",
                "label_ar": "بارايسو",
                "label_fr": "Paraiso"
            },
            {
                "value": 62950,
                "label": "Zapopan",
                "label_ar": "زابوبان",
                "label_fr": "Zapopan"
            },
            {
                "value": 62994,
                "label": "San Bernardo",
                "label_ar": "سان برناردو",
                "label_fr": "San Bernardo"
            },
            {
                "value": 63134,
                "label": "Puerto Morelos",
                "label_ar": "بويرتو موريلوس",
                "label_fr": "Puerto Morelos"
            },
            {
                "value": 63645,
                "label": "Prolongacion Avenida Mexico Norte",
                "label_ar": "برولونجاسيون أفينيدا المكسيك نورتي",
                "label_fr": "Prolongacion Avenida Mexico Norte"
            },
            {
                "value": 63629,
                "label": "San Agustin Altamirano",
                "label_ar": "سان أوجستين ألتاميرانو",
                "label_fr": "San Agustin Altamirano"
            },
            {
                "value": 63415,
                "label": "Colonia Veracruz",
                "label_ar": "كولونيا فيراكروز",
                "label_fr": "Colonia Veracruz"
            },
            {
                "value": 63339,
                "label": "San Agustin Tlaxiaca",
                "label_ar": "سان أوجستين تلاكسياكا",
                "label_fr": "San Agustin Tlaxiaca"
            },
            {
                "value": 63241,
                "label": "Santa Anita",
                "label_ar": "سانتا أنيتا",
                "label_fr": "Santa Anita"
            },
            {
                "value": 62946,
                "label": "San Nicolas",
                "label_ar": "سان نيكولاس",
                "label_fr": "San Nicolas"
            },
            {
                "value": 63256,
                "label": "Apatzingan",
                "label_ar": "أباتزينجان",
                "label_fr": "Apatzingan"
            },
            {
                "value": 63211,
                "label": "Melchor Muzquiz",
                "label_ar": "ميلكور موزكيز",
                "label_fr": "Melchor Muzquiz"
            },
            {
                "value": 63245,
                "label": "Colonia Guerrero (Los Guajes)",
                "label_ar": "Colonia Guerrero (Los Guajes)",
                "label_fr": "Colonia Guerrero (Los Guajes)"
            },
            {
                "value": 63244,
                "label": "Atoyac de Alvarez",
                "label_ar": "أتوياك دي ألفاريز",
                "label_fr": "Atoyac de Alvarez"
            },
            {
                "value": 63188,
                "label": "Macuspana",
                "label_ar": "ماكوسبانا",
                "label_fr": "Macuspana"
            },
            {
                "value": 62940,
                "label": "Cuauhtemoc",
                "label_ar": "كواوتيموك",
                "label_fr": "Cuauhtémoc"
            },
            {
                "value": 63314,
                "label": "Xochimilco",
                "label_ar": "زوتشيميلكو",
                "label_fr": "Xochimilco"
            },
            {
                "value": 63061,
                "label": "Escobedo",
                "label_ar": "اسكوبيدو",
                "label_fr": "Escobedo"
            },
            {
                "value": 63009,
                "label": "Tlalpan",
                "label_ar": "تلالبان",
                "label_fr": "Tlalpan"
            },
            {
                "value": 63036,
                "label": "Union de Tula",
                "label_ar": "يونيون دي تولا",
                "label_fr": "Union de Tula"
            },
            {
                "value": 63577,
                "label": "Pueblo Yaqui",
                "label_ar": "بويبلو ياكي",
                "label_fr": "Pueblo Yaqui"
            },
            {
                "value": 62969,
                "label": "Huixquilucan",
                "label_ar": "هويكسكيلوكان",
                "label_fr": "Huixquilucan"
            },
            {
                "value": 62900,
                "label": "Puerto Vallarta",
                "label_ar": "بويرتو فالارتا",
                "label_fr": "Puerto Vallarta"
            },
            {
                "value": 63428,
                "label": "Atlacholoaya",
                "label_ar": "Atlacholoaya",
                "label_fr": "Atlacholoaya"
            },
            {
                "value": 63336,
                "label": "Marquelia",
                "label_ar": "ماركيليا",
                "label_fr": "Marquelia"
            },
            {
                "value": 63640,
                "label": "Loma de los Cavazos (Maria de la Luz)",
                "label_ar": "لوما دي لوس كافازوس (ماريا دي لا لوز)",
                "label_fr": "Loma de los Cavazos (Maria de la Luz)"
            },
            {
                "value": 63558,
                "label": "Monclova",
                "label_ar": "مونكلوفا",
                "label_fr": "Monclova"
            },
            {
                "value": 63112,
                "label": "Hunucma",
                "label_ar": "هونوكما",
                "label_fr": "Hunucma"
            },
            {
                "value": 63552,
                "label": "Tenosique de Pino Suarez",
                "label_ar": "تينوسيك دي بينو سواريز",
                "label_fr": "Tenosique de Pino Suarez"
            },
            {
                "value": 63584,
                "label": "San Cristobal",
                "label_ar": "سان كريستوبال",
                "label_fr": "San Cristobal"
            },
            {
                "value": 63240,
                "label": "Gueramaro",
                "label_ar": "جويرامارو",
                "label_fr": "Gueramaro"
            },
            {
                "value": 63422,
                "label": "Francisco I. Madero",
                "label_ar": "فرانسيسكو آي ماديرو",
                "label_fr": "Francisco I. Madero"
            },
            {
                "value": 63048,
                "label": "Apizaco",
                "label_ar": "أبيزاكو",
                "label_fr": "Apizaco"
            },
            {
                "value": 63392,
                "label": "Jiquipilco",
                "label_ar": "جيكويبيلكو",
                "label_fr": "Jiquipilco"
            },
            {
                "value": 63309,
                "label": "Perote",
                "label_ar": "بيروت",
                "label_fr": "Perote"
            },
            {
                "value": 63524,
                "label": "Poza Rica de Hidalgo",
                "label_ar": "بوزا ريكا دي هيدالجو",
                "label_fr": "Poza Rica de Hidalgo"
            },
            {
                "value": 63097,
                "label": "Escondida de lo Verde",
                "label_ar": "إسكونديدا دي لو فيردي",
                "label_fr": "Escondida de lo Verde"
            },
            {
                "value": 63615,
                "label": "Tezoyuca",
                "label_ar": "تيزويوكا",
                "label_fr": "Tezoyuca"
            },
            {
                "value": 63534,
                "label": "Colon",
                "label_ar": "القولون",
                "label_fr": "Côlon"
            },
            {
                "value": 63184,
                "label": "Apatzingán",
                "label_ar": "أباتسينجان",
                "label_fr": "Apatzingán"
            },
            {
                "value": 62993,
                "label": "Lerdo",
                "label_ar": "ليردو",
                "label_fr": "Lerdo"
            },
            {
                "value": 63322,
                "label": "Jiquilpan de Juarez",
                "label_ar": "جيكويلبان دي خواريز",
                "label_fr": "Jiquilpan de Juarez"
            },
            {
                "value": 63197,
                "label": "Yautepec",
                "label_ar": "Yautepec",
                "label_fr": "Yautepec"
            },
            {
                "value": 63163,
                "label": "Tlahuac",
                "label_ar": "تلهواك",
                "label_fr": "Tlahuac"
            },
            {
                "value": 63568,
                "label": "San Nicolas Totolapan",
                "label_ar": "سان نيكولا توتولابان",
                "label_fr": "San Nicolas Totolapan"
            },
            {
                "value": 62941,
                "label": "San Luis Potosí",
                "label_ar": "سان لويس بوتوسي",
                "label_fr": "San Luis Potosí"
            },
            {
                "value": 62987,
                "label": "Santa Isabel Tlanepantla",
                "label_ar": "سانتا إيزابيل تلانيبانتلا",
                "label_fr": "Santa Isabel Tlanepantla"
            },
            {
                "value": 63567,
                "label": "Pueblo Viejo",
                "label_ar": "بويبلو فيجو",
                "label_fr": "Pueblo Viejo"
            },
            {
                "value": 62975,
                "label": "Jalupa",
                "label_ar": "جالوبا",
                "label_fr": "Jalupa"
            },
            {
                "value": 63102,
                "label": "Ajijic",
                "label_ar": "أجيجيتش",
                "label_fr": "Ajijic"
            },
            {
                "value": 63157,
                "label": "El Sabinal (San Pedro)",
                "label_ar": "El Sabinal (San Pedro)",
                "label_fr": "El Sabinal (San Pedro)"
            },
            {
                "value": 63500,
                "label": "Coatepec",
                "label_ar": "كوتيبيك",
                "label_fr": "Coatepec"
            },
            {
                "value": 63331,
                "label": "Coatzintla",
                "label_ar": "كواتزينتلا",
                "label_fr": "Coatzintla"
            },
            {
                "value": 63082,
                "label": "Guadalupe",
                "label_ar": "غوادالوبي",
                "label_fr": "Guadalupe"
            },
            {
                "value": 63346,
                "label": "Jose Cardel",
                "label_ar": "خوسيه كارديل",
                "label_fr": "José Cardel"
            },
            {
                "value": 62974,
                "label": "Tepic",
                "label_ar": "تيبيك",
                "label_fr": "Tepic"
            },
            {
                "value": 62924,
                "label": "Morelia",
                "label_ar": "موريليا",
                "label_fr": "Morelia"
            },
            {
                "value": 63148,
                "label": "Leon",
                "label_ar": "ليون",
                "label_fr": "Léon"
            },
            {
                "value": 63467,
                "label": "Loreto",
                "label_ar": "لوريتو",
                "label_fr": "Loreto"
            },
            {
                "value": 62921,
                "label": "Colima",
                "label_ar": "كوليما",
                "label_fr": "Colima"
            },
            {
                "value": 63666,
                "label": "Moctezuma",
                "label_ar": "موكتيزوما",
                "label_fr": "Moctezuma"
            },
            {
                "value": 63626,
                "label": "Santa Fe",
                "label_ar": "سانتا في",
                "label_fr": "Santa Fe"
            },
            {
                "value": 62948,
                "label": "Topolobampo",
                "label_ar": "توبولوبامبو",
                "label_fr": "Topolobampo"
            },
            {
                "value": 63288,
                "label": "Rio Bravo",
                "label_ar": "ريو برافو",
                "label_fr": "Rio Bravo"
            },
            {
                "value": 63497,
                "label": "Zacatlan",
                "label_ar": "زاكاتلان",
                "label_fr": "Zacatlan"
            },
            {
                "value": 63124,
                "label": "Petaquillas",
                "label_ar": "بيتاكويلاس",
                "label_fr": "Petaquillas"
            },
            {
                "value": 63503,
                "label": "Teziutlán",
                "label_ar": "تيزيوتلان",
                "label_fr": "Teziutlán"
            },
            {
                "value": 63086,
                "label": "Tuxtla",
                "label_ar": "توكستلا",
                "label_fr": "Tuxtla"
            },
            {
                "value": 63496,
                "label": "Tocatlan",
                "label_ar": "توكاتلان",
                "label_fr": "Tocatlan"
            },
            {
                "value": 63523,
                "label": "Tekoh",
                "label_ar": "تيكوه",
                "label_fr": "Tekoh"
            },
            {
                "value": 63359,
                "label": "Agua Dulce",
                "label_ar": "أغوا دولسي",
                "label_fr": "Agua Dulce"
            },
            {
                "value": 62906,
                "label": "Veracruz",
                "label_ar": "فيراكروز",
                "label_fr": "Veracruz"
            },
            {
                "value": 62914,
                "label": "Cholula",
                "label_ar": "شولولا",
                "label_fr": "Cholula"
            },
            {
                "value": 62982,
                "label": "Centro",
                "label_ar": "سنترو",
                "label_fr": "Centro"
            },
            {
                "value": 63291,
                "label": "La Canada",
                "label_ar": "لا كندا",
                "label_fr": "La Canada"
            },
            {
                "value": 63530,
                "label": "Pesqueria",
                "label_ar": "Pesqueria",
                "label_fr": "Pesqueria"
            },
            {
                "value": 63049,
                "label": "Tecamachalco",
                "label_ar": "تيكاماتشالكو",
                "label_fr": "Tecamachalco"
            },
            {
                "value": 63180,
                "label": "San Juan",
                "label_ar": "سان خوان",
                "label_fr": "San Juan"
            },
            {
                "value": 63073,
                "label": "Tapalpa",
                "label_ar": "تابالبا",
                "label_fr": "Tapalpa"
            },
            {
                "value": 62978,
                "label": "Orizaba",
                "label_ar": "أوريزابا",
                "label_fr": "Orizaba"
            },
            {
                "value": 63056,
                "label": "Berriozabal",
                "label_ar": "Berriozabal",
                "label_fr": "Berriozabal"
            },
            {
                "value": 63399,
                "label": "Actopan",
                "label_ar": "أكتوبان",
                "label_fr": "Actopan"
            },
            {
                "value": 63018,
                "label": "Azcapotzalco",
                "label_ar": "أزكابوتزالكو",
                "label_fr": "Azcapotzalco"
            },
            {
                "value": 63278,
                "label": "Colonia Emiliano Zapata (Ejido Santiago Temoaya)",
                "label_ar": "Colonia Emiliano Zapata (Ejido Santiago Temoaya)",
                "label_fr": "Colonia Emiliano Zapata (Ejido Santiago Temoaya)"
            },
            {
                "value": 63372,
                "label": "Mina Mexico",
                "label_ar": "مينا المكسيك",
                "label_fr": "Mina Mexique"
            },
            {
                "value": 63544,
                "label": "Escuintla",
                "label_ar": "اسكوينتلا",
                "label_fr": "Escuintla"
            },
            {
                "value": 63593,
                "label": "Anahuac",
                "label_ar": "أناهواك",
                "label_fr": "Anahuac"
            },
            {
                "value": 63330,
                "label": "El Sauz",
                "label_ar": "السوز",
                "label_fr": "El Sauz"
            },
            {
                "value": 63517,
                "label": "Amado Nervo",
                "label_ar": "أمادو نيرفو",
                "label_fr": "Amado Nervo"
            },
            {
                "value": 63403,
                "label": "Tepeapulco",
                "label_ar": "تيببولكو",
                "label_fr": "Tepeapulco"
            },
            {
                "value": 63598,
                "label": "San Jeronimo de Juarez",
                "label_ar": "سان جيرونيمو دي خواريز",
                "label_fr": "San Jeronimo de Juarez"
            },
            {
                "value": 63448,
                "label": "Zapotlanejo",
                "label_ar": "زابوتلانيجو",
                "label_fr": "Zapotlanejo"
            },
            {
                "value": 63215,
                "label": "Jala",
                "label_ar": "جالا",
                "label_fr": "Jala"
            },
            {
                "value": 63397,
                "label": "San Antonio la Isla",
                "label_ar": "سان أنطونيو لا إيسلا",
                "label_fr": "San Antonio la Isla"
            },
            {
                "value": 63106,
                "label": "Atotonilco el Alto",
                "label_ar": "أتوتونيلكو ألتو",
                "label_fr": "Atotonilco el Alto"
            },
            {
                "value": 63020,
                "label": "Cajeme",
                "label_ar": "كاجيم",
                "label_fr": "Cajeme"
            },
            {
                "value": 62911,
                "label": "Ciudad Hidalgo",
                "label_ar": "سيوداد هيدالجو",
                "label_fr": "Ciudad Hidalgo"
            },
            {
                "value": 63473,
                "label": "San José del Cabo",
                "label_ar": "سان خوسيه ديل كابو",
                "label_fr": "San José del Cabo"
            },
            {
                "value": 63017,
                "label": "Piedras Negras",
                "label_ar": "بيدراس نجراس",
                "label_fr": "Piedras Negras"
            },
            {
                "value": 63077,
                "label": "Córdoba",
                "label_ar": "قرطبة",
                "label_fr": "Cordoue"
            },
            {
                "value": 63255,
                "label": "Nueva Italia de Ruiz",
                "label_ar": "نويفا إيطاليا دي رويز",
                "label_fr": "Nueva Italia de Ruiz"
            },
            {
                "value": 63421,
                "label": "Nuevo Coahuila",
                "label_ar": "نويفو كواهويلا",
                "label_fr": "Nuevo Coahuila"
            },
            {
                "value": 63016,
                "label": "Tultepec",
                "label_ar": "تلتيبيك",
                "label_fr": "Tultepec"
            },
            {
                "value": 63225,
                "label": "Puruandiro",
                "label_ar": "بوروانديرو",
                "label_fr": "Puruandiro"
            },
            {
                "value": 63345,
                "label": "Tlaxcalancingo",
                "label_ar": "Tlaxcalancingo",
                "label_fr": "Tlaxcalancingo"
            },
            {
                "value": 63456,
                "label": "Loreto",
                "label_ar": "لوريتو",
                "label_fr": "Loreto"
            },
            {
                "value": 63130,
                "label": "Coacalco",
                "label_ar": "كواكالكو",
                "label_fr": "Coacalco"
            },
            {
                "value": 63491,
                "label": "Pabellon de Arteaga",
                "label_ar": "بابلون دي أرتيغا",
                "label_fr": "Pabellon de Arteaga"
            },
            {
                "value": 63030,
                "label": "Navojoa",
                "label_ar": "نافوجوا",
                "label_fr": "Navojoa"
            },
            {
                "value": 63373,
                "label": "Polanco",
                "label_ar": "بولانكو",
                "label_fr": "Polanco"
            },
            {
                "value": 62942,
                "label": "Culiacán",
                "label_ar": "كولياكان",
                "label_fr": "Culiacán"
            },
            {
                "value": 63371,
                "label": "Centro",
                "label_ar": "سنترو",
                "label_fr": "Centro"
            },
            {
                "value": 62957,
                "label": "Kilometro 32 Texcoco Lecheria",
                "label_ar": "Kilometro 32 Texcoco Lecheria",
                "label_fr": "Kilometro 32 Texcoco Lecheria"
            },
            {
                "value": 63342,
                "label": "San Martin Notario",
                "label_ar": "سان مارتن نوتاريو",
                "label_fr": "San Martin Notario"
            },
            {
                "value": 63338,
                "label": "Lagos de Moreno",
                "label_ar": "لاغوس دي مورينو",
                "label_fr": "Lagos de Moreno"
            },
            {
                "value": 63054,
                "label": "Agua Azul",
                "label_ar": "أغوا أزول",
                "label_fr": "Agua Azul"
            },
            {
                "value": 63513,
                "label": "El Potrero",
                "label_ar": "إل بوتريرو",
                "label_fr": "El Potrero"
            },
            {
                "value": 63505,
                "label": "Tequisistlan",
                "label_ar": "تيكيسيستلان",
                "label_fr": "Tequisistlan"
            },
            {
                "value": 63109,
                "label": "Izamal",
                "label_ar": "ازمال",
                "label_fr": "Izamal"
            },
            {
                "value": 62910,
                "label": "Oaxaca",
                "label_ar": "أواكساكا",
                "label_fr": "Oaxaca"
            },
            {
                "value": 63321,
                "label": "Zinapecuaro",
                "label_ar": "زينابيكوارو",
                "label_fr": "Zinapecuaro"
            },
            {
                "value": 63111,
                "label": "Valladolid",
                "label_ar": "بلد الوليد",
                "label_fr": "Valladolid"
            },
            {
                "value": 63167,
                "label": "Zongolica",
                "label_ar": "زونغوليكا",
                "label_fr": "Zongolica"
            },
            {
                "value": 62995,
                "label": "Uruapan",
                "label_ar": "أوروابان",
                "label_fr": "Uruapan"
            },
            {
                "value": 62986,
                "label": "Zaragoza",
                "label_ar": "سرقسطة",
                "label_fr": "Saragosse"
            },
            {
                "value": 63334,
                "label": "Pedro Meoqui",
                "label_ar": "بيدرو ميوكي",
                "label_fr": "Pedro Meoqui"
            },
            {
                "value": 63063,
                "label": "Cadereyta",
                "label_ar": "كاديريتا",
                "label_fr": "Cadereyta"
            },
            {
                "value": 62998,
                "label": "Cuautitlán Izcalli",
                "label_ar": "كواوتيتلان إيزكالي",
                "label_fr": "Cuautitlán Izcalli"
            },
            {
                "value": 62989,
                "label": "Ejido Distrito Federal",
                "label_ar": "Ejido Distrito Federal",
                "label_fr": "Ejido Distrito Federal"
            },
            {
                "value": 63414,
                "label": "Isla Mujeres",
                "label_ar": "جزيرة موخيريس",
                "label_fr": "Isla Mujeres"
            },
            {
                "value": 63582,
                "label": "Pabellon",
                "label_ar": "بابلون",
                "label_fr": "Pabellon"
            },
            {
                "value": 63239,
                "label": "Tequisquiapan",
                "label_ar": "تيكيسكيابان",
                "label_fr": "Tequisquiapan"
            },
            {
                "value": 63657,
                "label": "Laredo",
                "label_ar": "لاريدو",
                "label_fr": "Laredo"
            },
            {
                "value": 63647,
                "label": "Santa Lucia",
                "label_ar": "سانتا لوسيا",
                "label_fr": "Santa Lucia"
            },
            {
                "value": 63484,
                "label": "Nextlalpan",
                "label_ar": "نيكستلالبان",
                "label_fr": "Nextlalpan"
            },
            {
                "value": 63549,
                "label": "Acuna",
                "label_ar": "اكونا",
                "label_fr": "Acuna"
            },
            {
                "value": 63405,
                "label": "Noxtepec de Zaragoza (El Aguacate)",
                "label_ar": "Noxtepec de Zaragoza (El Aguacate)",
                "label_fr": "Noxtepec de Zaragoza (El Aguacate)"
            },
            {
                "value": 63595,
                "label": "Las Americas",
                "label_ar": "لاس الأمريكتين",
                "label_fr": "Las Americas"
            },
            {
                "value": 63652,
                "label": "Adolfo Lopez Mateos",
                "label_ar": "أدولفو لوبيز ماتيوس",
                "label_fr": "Adolfo Lopez Mateos"
            },
            {
                "value": 63303,
                "label": "Ixmiquilpan",
                "label_ar": "إكسميكويلبان",
                "label_fr": "Ixmiquilpan"
            }
        ]
    },
    {
        "country": "Canada",
        "cities": [
            {
                "value": 64760,
                "label": "South River",
                "label_ar": "نهر الجنوب",
                "label_fr": "South River"
            },
            {
                "value": 64951,
                "label": "Fonthill",
                "label_ar": "فونثيل",
                "label_fr": "Fonthill"
            },
            {
                "value": 65756,
                "label": "Kisbey",
                "label_ar": "كيسبي",
                "label_fr": "Kisbey"
            },
            {
                "value": 64741,
                "label": "Prince",
                "label_ar": "أمير",
                "label_fr": "Prince"
            },
            {
                "value": 64884,
                "label": "Leader",
                "label_ar": "زعيم",
                "label_fr": "Chef"
            },
            {
                "value": 64289,
                "label": "Gibsons",
                "label_ar": "جيبسونز",
                "label_fr": "Gibsons"
            },
            {
                "value": 64026,
                "label": "Acton",
                "label_ar": "العمل على",
                "label_fr": "Agir sur"
            },
            {
                "value": 65797,
                "label": "Cranberry",
                "label_ar": "كرز",
                "label_fr": "Canneberge"
            },
            {
                "value": 64130,
                "label": "Bedford",
                "label_ar": "بيدفورد",
                "label_fr": "Bedford"
            },
            {
                "value": 65620,
                "label": "Riverton",
                "label_ar": "ريفرتون",
                "label_fr": "Riverton"
            },
            {
                "value": 64098,
                "label": "Sydney",
                "label_ar": "سيدني",
                "label_fr": "Sydney"
            },
            {
                "value": 65000,
                "label": "Drayton",
                "label_ar": "درايتون",
                "label_fr": "Drayton"
            },
            {
                "value": 65117,
                "label": "Charny",
                "label_ar": "تشارني",
                "label_fr": "Charny"
            },
            {
                "value": 65237,
                "label": "Gold Bridge",
                "label_ar": "جسر الذهب",
                "label_fr": "Pont d'or"
            },
            {
                "value": 65795,
                "label": "Hartley Bay",
                "label_ar": "خليج هارتلي",
                "label_fr": "Hartley Bay"
            },
            {
                "value": 64386,
                "label": "Pont-Rouge",
                "label_ar": "بونت روج",
                "label_fr": "Pont-Rouge"
            },
            {
                "value": 63730,
                "label": "Rougemont",
                "label_ar": "روجيمونت",
                "label_fr": "Rougemont"
            },
            {
                "value": 64014,
                "label": "Sault Ste. Marie",
                "label_ar": "سولت سانت. ماري",
                "label_fr": "Sault Ste. Marie"
            },
            {
                "value": 63946,
                "label": "Warman",
                "label_ar": "وارمان",
                "label_fr": "Warman"
            },
            {
                "value": 64078,
                "label": "Desbiens",
                "label_ar": "ديسبيينز",
                "label_fr": "Desbiens"
            },
            {
                "value": 64907,
                "label": "Nanton",
                "label_ar": "نانتون",
                "label_fr": "Nanton"
            },
            {
                "value": 65780,
                "label": "Penzance",
                "label_ar": "بينزانس",
                "label_fr": "Penzance"
            },
            {
                "value": 64411,
                "label": "Brandon",
                "label_ar": "براندون",
                "label_fr": "Brandon"
            },
            {
                "value": 64613,
                "label": "Cat Lake",
                "label_ar": "بحيرة القط",
                "label_fr": "Cat Lake"
            },
            {
                "value": 64920,
                "label": "Saint-Pierre-les-Becquets",
                "label_ar": "سان بيير ليه بيكيه",
                "label_fr": "Saint-Pierre-les-Becquets"
            },
            {
                "value": 64391,
                "label": "Kirkland Lake",
                "label_ar": "بحيرة كيركلاند",
                "label_fr": "Kirkland Lake"
            },
            {
                "value": 65151,
                "label": "Chelsea",
                "label_ar": "تشيلسي",
                "label_fr": "Chelsea"
            },
            {
                "value": 65578,
                "label": "Millarville",
                "label_ar": "ميلارفيل",
                "label_fr": "Millarville"
            },
            {
                "value": 65202,
                "label": "Bedford",
                "label_ar": "بيدفورد",
                "label_fr": "Bedford"
            },
            {
                "value": 64113,
                "label": "Oxford",
                "label_ar": "أكسفورد",
                "label_fr": "Oxford"
            },
            {
                "value": 64944,
                "label": "Calixa-Lavallee",
                "label_ar": "كاليكسا لافالي",
                "label_fr": "Calixa-Lavallee"
            },
            {
                "value": 63671,
                "label": "Vancouver",
                "label_ar": "فانكوفر",
                "label_fr": "Vancouver"
            },
            {
                "value": 64179,
                "label": "Walkerton",
                "label_ar": "والكرتون",
                "label_fr": "Walkerton"
            },
            {
                "value": 64761,
                "label": "Markstay",
                "label_ar": "ماركستاي",
                "label_fr": "Markstay"
            },
            {
                "value": 64842,
                "label": "Canoe Narrows",
                "label_ar": "مضيق الزورق",
                "label_fr": "Canoë Narrows"
            },
            {
                "value": 64882,
                "label": "Downsview",
                "label_ar": "داونسفيو",
                "label_fr": "Downsview"
            },
            {
                "value": 65140,
                "label": "Killarney",
                "label_ar": "كيلارني",
                "label_fr": "Killarney"
            },
            {
                "value": 65616,
                "label": "Empress",
                "label_ar": "الإمبراطورة",
                "label_fr": "Impératrice"
            },
            {
                "value": 64154,
                "label": "Elliot Lake",
                "label_ar": "بحيرة إليوت",
                "label_fr": "Elliot Lake"
            },
            {
                "value": 63689,
                "label": "Kamloops",
                "label_ar": "كاملوبس",
                "label_fr": "Kamloops"
            },
            {
                "value": 64241,
                "label": "Ange-Gardien",
                "label_ar": "أنجي جاردين",
                "label_fr": "Ange-Gardien"
            },
            {
                "value": 65439,
                "label": "Authier",
                "label_ar": "أوثير",
                "label_fr": "Authier"
            },
            {
                "value": 64301,
                "label": "Temiscaming",
                "label_ar": "Temiscaming",
                "label_fr": "Témiscaming"
            },
            {
                "value": 64846,
                "label": "Langenburg",
                "label_ar": "لانغنبورغ",
                "label_fr": "Langenburg"
            },
            {
                "value": 63914,
                "label": "Canyon",
                "label_ar": "كانيون",
                "label_fr": "Canyon"
            },
            {
                "value": 63724,
                "label": "Welland",
                "label_ar": "حسنا و",
                "label_fr": "Bien et"
            },
            {
                "value": 63819,
                "label": "Greenfield Park",
                "label_ar": "جرينفيلد بارك",
                "label_fr": "Greenfield Park"
            },
            {
                "value": 63675,
                "label": "Camrose",
                "label_ar": "كامروز",
                "label_fr": "Camrose"
            },
            {
                "value": 64947,
                "label": "Puvirnituq",
                "label_ar": "بوفيرنيتوق",
                "label_fr": "Puvirnituq"
            },
            {
                "value": 65348,
                "label": "Raleigh",
                "label_ar": "رالي",
                "label_fr": "Raleigh"
            },
            {
                "value": 63773,
                "label": "Levis",
                "label_ar": "ليفيس",
                "label_fr": "Lévi's"
            },
            {
                "value": 65321,
                "label": "Danville",
                "label_ar": "دانفيل",
                "label_fr": "Danville"
            },
            {
                "value": 65385,
                "label": "Star City",
                "label_ar": "مدينة النجوم",
                "label_fr": "Star City"
            },
            {
                "value": 65020,
                "label": "Qualicum Beach",
                "label_ar": "شاطئ كواليكوم",
                "label_fr": "Plage de Qualicum"
            },
            {
                "value": 65700,
                "label": "Muirkirk",
                "label_ar": "مويركيرك",
                "label_fr": "Muirkirk"
            },
            {
                "value": 63787,
                "label": "Kirkland",
                "label_ar": "كيركلاند",
                "label_fr": "Kirkland"
            },
            {
                "value": 64729,
                "label": "Trois Pistoles",
                "label_ar": "تروا بيستولس",
                "label_fr": "Trois Pistoles"
            },
            {
                "value": 64460,
                "label": "Alberton",
                "label_ar": "ألبرتون",
                "label_fr": "Alberton"
            },
            {
                "value": 65629,
                "label": "West Hill",
                "label_ar": "التلة الغربية",
                "label_fr": "West Hill"
            },
            {
                "value": 64891,
                "label": "Wishart",
                "label_ar": "ويشارت",
                "label_fr": "Wishart"
            },
            {
                "value": 64819,
                "label": "L'Orignal",
                "label_ar": "لوريجنال",
                "label_fr": "L'Orignal"
            },
            {
                "value": 65828,
                "label": "Takla Landing",
                "label_ar": "تكلا للهبوط",
                "label_fr": "Takla Landing"
            },
            {
                "value": 65204,
                "label": "Carp",
                "label_ar": "الكارب",
                "label_fr": "Carpe"
            },
            {
                "value": 65777,
                "label": "Swastika",
                "label_ar": "الصليب المعقوف",
                "label_fr": "Svastika"
            },
            {
                "value": 65642,
                "label": "Manitou",
                "label_ar": "مانيتو",
                "label_fr": "Manitou"
            },
            {
                "value": 63881,
                "label": "Newmarket",
                "label_ar": "سوق جديد",
                "label_fr": "Nouveau marché"
            },
            {
                "value": 64180,
                "label": "Chesterville",
                "label_ar": "تشيسترفيل",
                "label_fr": "Chesterville"
            },
            {
                "value": 64137,
                "label": "Windsor",
                "label_ar": "وندسور",
                "label_fr": "Windsor"
            },
            {
                "value": 64811,
                "label": "South Lancaster",
                "label_ar": "جنوب لانكستر",
                "label_fr": "South Lancaster"
            },
            {
                "value": 63915,
                "label": "Golden",
                "label_ar": "ذهبي",
                "label_fr": "d'or"
            },
            {
                "value": 64115,
                "label": "Fogo",
                "label_ar": "فوغو",
                "label_fr": "Fogo"
            },
            {
                "value": 64808,
                "label": "Weedon",
                "label_ar": "ويدون",
                "label_fr": "Weedon"
            },
            {
                "value": 65640,
                "label": "Morris",
                "label_ar": "موريس",
                "label_fr": "Morris"
            },
            {
                "value": 65215,
                "label": "Parham",
                "label_ar": "بارهام",
                "label_fr": "Parham"
            },
            {
                "value": 64715,
                "label": "Saint-Philippe",
                "label_ar": "سان فيليب",
                "label_fr": "Saint-Philippe"
            },
            {
                "value": 63697,
                "label": "Lethbridge",
                "label_ar": "ليثبريدج",
                "label_fr": "Lethbridge"
            },
            {
                "value": 65695,
                "label": "Yahk",
                "label_ar": "ياهك",
                "label_fr": "Yahk"
            },
            {
                "value": 65840,
                "label": "Oak Lake",
                "label_ar": "أوك ليك",
                "label_fr": "Oak Lake"
            },
            {
                "value": 64362,
                "label": "Daniel's Harbour",
                "label_ar": "ميناء دانيال",
                "label_fr": "Daniel's Harbour"
            },
            {
                "value": 65030,
                "label": "Nipigon",
                "label_ar": "نيبيجون",
                "label_fr": "Nipigon"
            },
            {
                "value": 65342,
                "label": "Tatamagouche",
                "label_ar": "Tatamagouche",
                "label_fr": "Tatamagouche"
            },
            {
                "value": 63758,
                "label": "Woodstock",
                "label_ar": "وودستوك",
                "label_fr": "Woodstock"
            },
            {
                "value": 65801,
                "label": "Tompkins",
                "label_ar": "تومبكينز",
                "label_fr": "Tompkins"
            },
            {
                "value": 64469,
                "label": "Pitt Meadows",
                "label_ar": "بيت ميدوز",
                "label_fr": "Pitt Meadows"
            },
            {
                "value": 65314,
                "label": "Sainte-Famille",
                "label_ar": "سانت فاميل",
                "label_fr": "Sainte-Famille"
            },
            {
                "value": 64358,
                "label": "Margaretsville",
                "label_ar": "مارغريتسفيل",
                "label_fr": "Margaretsville"
            },
            {
                "value": 65606,
                "label": "Barons",
                "label_ar": "البارونات",
                "label_fr": "Barons"
            },
            {
                "value": 65008,
                "label": "Ascot Corner",
                "label_ar": "ركن أسكوت",
                "label_fr": "Coin d'Ascot"
            },
            {
                "value": 65190,
                "label": "Bonaventure",
                "label_ar": "بونافنتورا",
                "label_fr": "Bonaventure"
            },
            {
                "value": 64942,
                "label": "Shannon",
                "label_ar": "شانون",
                "label_fr": "Shannon"
            },
            {
                "value": 65152,
                "label": "Clarke City",
                "label_ar": "كلارك سيتي",
                "label_fr": "Clarke City"
            },
            {
                "value": 64665,
                "label": "Carleton Place",
                "label_ar": "مكان كارلتون",
                "label_fr": "Place Carleton"
            },
            {
                "value": 63891,
                "label": "Didsbury",
                "label_ar": "ديدسبري",
                "label_fr": "Didsbury"
            },
            {
                "value": 64370,
                "label": "Granville Ferry",
                "label_ar": "جرانفيل فيري",
                "label_fr": "Granville Ferry"
            },
            {
                "value": 64591,
                "label": "Westport",
                "label_ar": "ويستبورت",
                "label_fr": "Westport"
            },
            {
                "value": 65608,
                "label": "Saint-Augustin-de-Desmaures",
                "label_ar": "سان أوغسطين دي ديسموريس",
                "label_fr": "Saint-Augustin-de-Desmaures"
            },
            {
                "value": 65495,
                "label": "Legal",
                "label_ar": "قانوني",
                "label_fr": "Légal"
            },
            {
                "value": 65169,
                "label": "Paspebiac",
                "label_ar": "باسبيبياك",
                "label_fr": "Paspébiac"
            },
            {
                "value": 63701,
                "label": "Fort Saskatchewan",
                "label_ar": "حصن ساسكاتشوان",
                "label_fr": "Fort Saskatchewan"
            },
            {
                "value": 65565,
                "label": "Iroquois",
                "label_ar": "إيروكوا",
                "label_fr": "Iroquois"
            },
            {
                "value": 63703,
                "label": "Morinville",
                "label_ar": "مورينفيل",
                "label_fr": "Morinville"
            },
            {
                "value": 64146,
                "label": "Tottenham",
                "label_ar": "توتنهام",
                "label_fr": "Tottenham"
            },
            {
                "value": 65848,
                "label": "Morewood",
                "label_ar": "موريوود",
                "label_fr": "Morewood"
            },
            {
                "value": 63777,
                "label": "Saint-Michel-des-Saints",
                "label_ar": "سان ميشيل دي سانت",
                "label_fr": "Saint-Michel-des-Saints"
            },
            {
                "value": 65686,
                "label": "Ashville",
                "label_ar": "أشفيل",
                "label_fr": "Ashville"
            },
            {
                "value": 64521,
                "label": "Ridgeway",
                "label_ar": "ريدجواي",
                "label_fr": "Ridgeway"
            },
            {
                "value": 64804,
                "label": "Saint-Come",
                "label_ar": "سان تعال",
                "label_fr": "Saint-Come"
            },
            {
                "value": 64332,
                "label": "Hant's Harbour",
                "label_ar": "ميناء هانت",
                "label_fr": "Port de Hant"
            },
            {
                "value": 64165,
                "label": "Hanover",
                "label_ar": "هانوفر",
                "label_fr": "Hanovre"
            },
            {
                "value": 65674,
                "label": "Norval",
                "label_ar": "نورفال",
                "label_fr": "Norval"
            },
            {
                "value": 63893,
                "label": "Blairmore",
                "label_ar": "بليرمور",
                "label_fr": "Blairmore"
            },
            {
                "value": 63740,
                "label": "Beauport",
                "label_ar": "بيوبورت",
                "label_fr": "Beauport"
            },
            {
                "value": 65327,
                "label": "Debert",
                "label_ar": "ديبرت",
                "label_fr": "Debert"
            },
            {
                "value": 65310,
                "label": "Chalk River",
                "label_ar": "نهر الطباشير",
                "label_fr": "Chalk River"
            },
            {
                "value": 63674,
                "label": "Leamington",
                "label_ar": "ليمنجتون",
                "label_fr": "Leamington"
            },
            {
                "value": 65601,
                "label": "Trout Creek",
                "label_ar": "تراوت كريك",
                "label_fr": "Trout Creek"
            },
            {
                "value": 65121,
                "label": "Saint-Fabien-de-Panet",
                "label_ar": "سان فابيان دي بانيه",
                "label_fr": "Saint-Fabien-de-Panet"
            },
            {
                "value": 65096,
                "label": "Ashmont",
                "label_ar": "أشمونت",
                "label_fr": "Ashmont"
            },
            {
                "value": 64700,
                "label": "Mannville",
                "label_ar": "مانفيل",
                "label_fr": "Mannville"
            },
            {
                "value": 65411,
                "label": "Chipman",
                "label_ar": "تشيبمان",
                "label_fr": "Chipman"
            },
            {
                "value": 63986,
                "label": "Dartmouth",
                "label_ar": "دارتموث",
                "label_fr": "Dartmouth"
            },
            {
                "value": 63841,
                "label": "Sainte-Agathe-des-Monts",
                "label_ar": "Sainte-Agathe-des-Monts",
                "label_fr": "Sainte-Agathe-des-Monts"
            },
            {
                "value": 64375,
                "label": "Bromont",
                "label_ar": "برومونت",
                "label_fr": "Bromont"
            },
            {
                "value": 64104,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 64686,
                "label": "Havelock",
                "label_ar": "هافلوك",
                "label_fr": "Havelock"
            },
            {
                "value": 65380,
                "label": "Arcola",
                "label_ar": "اركولا",
                "label_fr": "Arcola"
            },
            {
                "value": 64506,
                "label": "Wallaceburg",
                "label_ar": "والاسبرغ",
                "label_fr": "Wallaceburg"
            },
            {
                "value": 64112,
                "label": "Annapolis Royal",
                "label_ar": "أنابوليس رويال",
                "label_fr": "Annapolis Royal"
            },
            {
                "value": 65226,
                "label": "Brooklyn",
                "label_ar": "بروكلين",
                "label_fr": "Brooklyn"
            },
            {
                "value": 64466,
                "label": "Sicamous",
                "label_ar": "سيكاموس",
                "label_fr": "Sicamous"
            },
            {
                "value": 64897,
                "label": "Brechin",
                "label_ar": "بريشين",
                "label_fr": "Brechin"
            },
            {
                "value": 64914,
                "label": "Milverton",
                "label_ar": "ميلفرتون",
                "label_fr": "Milverton"
            },
            {
                "value": 65036,
                "label": "Lillooet",
                "label_ar": "ليلويت",
                "label_fr": "Lillooet"
            },
            {
                "value": 63998,
                "label": "Hagersville",
                "label_ar": "هاجرسفيل",
                "label_fr": "Hagersville"
            },
            {
                "value": 64087,
                "label": "Fenelon Falls",
                "label_ar": "شلالات فينيلون",
                "label_fr": "Chutes de Fénelon"
            },
            {
                "value": 65445,
                "label": "Blacks Harbour",
                "label_ar": "ميناء بلاكس",
                "label_fr": "Blacks Harbour"
            },
            {
                "value": 65745,
                "label": "Field",
                "label_ar": "حقل",
                "label_fr": "Champ"
            },
            {
                "value": 64553,
                "label": "Blenheim",
                "label_ar": "بلينهايم",
                "label_fr": "Blenheim"
            },
            {
                "value": 65236,
                "label": "Sussex",
                "label_ar": "ساسكس",
                "label_fr": "Sussex"
            },
            {
                "value": 64036,
                "label": "Millbrook",
                "label_ar": "ميلبروك",
                "label_fr": "Millbrook"
            },
            {
                "value": 63843,
                "label": "Sainte-Anne-des-Monts",
                "label_ar": "سانت آن دي مونت",
                "label_fr": "Sainte-Anne-des-Monts"
            },
            {
                "value": 64523,
                "label": "Paris",
                "label_ar": "باريس",
                "label_fr": "Paris"
            },
            {
                "value": 65781,
                "label": "Delisle",
                "label_ar": "ديلايل",
                "label_fr": "Delisle"
            },
            {
                "value": 63954,
                "label": "Maidstone",
                "label_ar": "ميدستون",
                "label_fr": "Maidstone"
            },
            {
                "value": 64549,
                "label": "Newburgh",
                "label_ar": "نيوبورج",
                "label_fr": "Newburgh"
            },
            {
                "value": 64827,
                "label": "Big River",
                "label_ar": "نهر كبير",
                "label_fr": "Grosse rivière"
            },
            {
                "value": 64483,
                "label": "Lumby",
                "label_ar": "لومبي",
                "label_fr": "Lumby"
            },
            {
                "value": 64573,
                "label": "Dundurn",
                "label_ar": "دوندورن",
                "label_fr": "Dundurn"
            },
            {
                "value": 64641,
                "label": "Cambridge Bay",
                "label_ar": "كامبردج باي",
                "label_fr": "Cambridge Bay"
            },
            {
                "value": 64284,
                "label": "Dudswell",
                "label_ar": "دودسويل",
                "label_fr": "Dudswell"
            },
            {
                "value": 65759,
                "label": "Conquest",
                "label_ar": "غزو",
                "label_fr": "Conquête"
            },
            {
                "value": 65339,
                "label": "Mount Stewart",
                "label_ar": "جبل ستيوارت",
                "label_fr": "Mount Stewart"
            },
            {
                "value": 64457,
                "label": "Carters Cove",
                "label_ar": "كارترز كوف",
                "label_fr": "Carters Cove"
            },
            {
                "value": 64369,
                "label": "Gambo",
                "label_ar": "جامبو",
                "label_fr": "Gambo"
            },
            {
                "value": 63774,
                "label": "Sorel",
                "label_ar": "سوريل",
                "label_fr": "Sorel"
            },
            {
                "value": 64125,
                "label": "Cornwall",
                "label_ar": "كورنوال",
                "label_fr": "Cornouailles"
            },
            {
                "value": 64156,
                "label": "Casimir",
                "label_ar": "كازيمير",
                "label_fr": "Casimir"
            },
            {
                "value": 63876,
                "label": "Windsor",
                "label_ar": "وندسور",
                "label_fr": "Windsor"
            },
            {
                "value": 64399,
                "label": "Prescott",
                "label_ar": "بريسكوت",
                "label_fr": "Prescott"
            },
            {
                "value": 64565,
                "label": "Wynyard",
                "label_ar": "وينيارد",
                "label_fr": "Wynyard"
            },
            {
                "value": 65244,
                "label": "Gem",
                "label_ar": "جوهرة",
                "label_fr": "Gemme"
            },
            {
                "value": 64529,
                "label": "Weir",
                "label_ar": "وير",
                "label_fr": "seuil"
            },
            {
                "value": 65718,
                "label": "Tompkins",
                "label_ar": "تومبكينز",
                "label_fr": "Tompkins"
            },
            {
                "value": 64656,
                "label": "Melbourne Ridge",
                "label_ar": "ملبورن ريدج",
                "label_fr": "Melbourne Ridge"
            },
            {
                "value": 63956,
                "label": "Yorkton",
                "label_ar": "يوركتون",
                "label_fr": "Yorkton"
            },
            {
                "value": 64453,
                "label": "Haileybury",
                "label_ar": "هيليبيري",
                "label_fr": "Haileybury"
            },
            {
                "value": 64561,
                "label": "Moose Jaw",
                "label_ar": "موس جو",
                "label_fr": "Moose Jaw"
            },
            {
                "value": 64139,
                "label": "King City",
                "label_ar": "مدينة الملك",
                "label_fr": "King City"
            },
            {
                "value": 65035,
                "label": "Rossland",
                "label_ar": "روسلاند",
                "label_fr": "Rossland"
            },
            {
                "value": 63822,
                "label": "Amos",
                "label_ar": "عاموس",
                "label_fr": "Amos"
            },
            {
                "value": 65084,
                "label": "Fraser Lake",
                "label_ar": "بحيرة فريزر",
                "label_fr": "Lac Fraser"
            },
            {
                "value": 63970,
                "label": "Portage la Prairie",
                "label_ar": "Portage la Prairie",
                "label_fr": "Portage la Prairie"
            },
            {
                "value": 64157,
                "label": "Massey",
                "label_ar": "ماسي",
                "label_fr": "Massey"
            },
            {
                "value": 65056,
                "label": "Enfield",
                "label_ar": "انفيلد",
                "label_fr": "Enfield"
            },
            {
                "value": 65573,
                "label": "Quyon",
                "label_ar": "كويون",
                "label_fr": "Quyon"
            },
            {
                "value": 65292,
                "label": "Fredericton Junction",
                "label_ar": "فريدريكتون جانكشن",
                "label_fr": "Fredericton Junction"
            },
            {
                "value": 64747,
                "label": "Hensall",
                "label_ar": "هنسال",
                "label_fr": "Hensall"
            },
            {
                "value": 65628,
                "label": "Saint-Raphael",
                "label_ar": "سانت رافائيل",
                "label_fr": "Saint-Raphaël"
            },
            {
                "value": 64446,
                "label": "Hantsport",
                "label_ar": "هانتسبورت",
                "label_fr": "Hantsport"
            },
            {
                "value": 65474,
                "label": "Taylor",
                "label_ar": "تايلور",
                "label_fr": "Taylor"
            },
            {
                "value": 64233,
                "label": "Sainte-Sophie-d'Halifax",
                "label_ar": "سانت صوفي دي هاليفاكس",
                "label_fr": "Sainte-Sophie-d'Halifax"
            },
            {
                "value": 64258,
                "label": "Hampton",
                "label_ar": "هامبتون",
                "label_fr": "Hampton"
            },
            {
                "value": 65675,
                "label": "Chatsworth",
                "label_ar": "تشاتسوورث",
                "label_fr": "Chatsworth"
            },
            {
                "value": 64546,
                "label": "Devon",
                "label_ar": "ديفون",
                "label_fr": "Devon"
            },
            {
                "value": 65203,
                "label": "Pont-Viau",
                "label_ar": "بونت فياو",
                "label_fr": "Pont-Viau"
            },
            {
                "value": 65061,
                "label": "Lawrencetown",
                "label_ar": "لورنس تاون",
                "label_fr": "Lawrencetown"
            },
            {
                "value": 64833,
                "label": "Gravelbourg",
                "label_ar": "جرافيلبورج",
                "label_fr": "Gravelbourg"
            },
            {
                "value": 65413,
                "label": "Harvey",
                "label_ar": "هارفي",
                "label_fr": "Harvey"
            },
            {
                "value": 64551,
                "label": "Sydenham",
                "label_ar": "سيدنهام",
                "label_fr": "Sydenham"
            },
            {
                "value": 64876,
                "label": "Papineauville",
                "label_ar": "بابينوفيل",
                "label_fr": "Papineauville"
            },
            {
                "value": 64497,
                "label": "Campbell River",
                "label_ar": "نهر كامبل",
                "label_fr": "Campbell River"
            },
            {
                "value": 64649,
                "label": "Lachenaie",
                "label_ar": "لاتشيني",
                "label_fr": "Lachenaie"
            },
            {
                "value": 65178,
                "label": "Saint-Philemon",
                "label_ar": "سانت فيليمون",
                "label_fr": "Saint-Philémon"
            },
            {
                "value": 63834,
                "label": "Roberval",
                "label_ar": "روبرفال",
                "label_fr": "Roberval"
            },
            {
                "value": 64796,
                "label": "Tweed",
                "label_ar": "تويد",
                "label_fr": "Tweed"
            },
            {
                "value": 64101,
                "label": "Yarmouth",
                "label_ar": "يارموث",
                "label_fr": "Yarmouth"
            },
            {
                "value": 63884,
                "label": "Wheatley",
                "label_ar": "ويتلي",
                "label_fr": "Wheatley"
            },
            {
                "value": 65468,
                "label": "Boissevain",
                "label_ar": "Boissevain",
                "label_fr": "Boissevain"
            },
            {
                "value": 64755,
                "label": "Saint-Adalbert",
                "label_ar": "سان أدالبرت",
                "label_fr": "Saint-Adalbert"
            },
            {
                "value": 65461,
                "label": "Alert Bay",
                "label_ar": "أليرت باي",
                "label_fr": "Alert Bay"
            },
            {
                "value": 65153,
                "label": "Val-Brillant",
                "label_ar": "فال بريانت",
                "label_fr": "Val-Brillant"
            },
            {
                "value": 65609,
                "label": "Fort McPherson",
                "label_ar": "فورت ماكفرسون",
                "label_fr": "Fort McPherson"
            },
            {
                "value": 64809,
                "label": "Port Stanley",
                "label_ar": "بورت ستانلي",
                "label_fr": "Port Stanley"
            },
            {
                "value": 64697,
                "label": "Czar",
                "label_ar": "قيصر",
                "label_fr": "Tsar"
            },
            {
                "value": 63916,
                "label": "Invermere",
                "label_ar": "إنفيرمير",
                "label_fr": "Invermere"
            },
            {
                "value": 64988,
                "label": "Saint-Joachim",
                "label_ar": "القديس يواكيم",
                "label_fr": "Saint-Joachim"
            },
            {
                "value": 64960,
                "label": "Frankford",
                "label_ar": "فرانكفورد",
                "label_fr": "Frankford"
            },
            {
                "value": 65852,
                "label": "Alameda",
                "label_ar": "ألاميدا",
                "label_fr": "Alameda"
            },
            {
                "value": 64037,
                "label": "Arthur",
                "label_ar": "آرثر",
                "label_fr": "Arthur"
            },
            {
                "value": 65639,
                "label": "Piney",
                "label_ar": "صنوري",
                "label_fr": "Piney"
            },
            {
                "value": 64511,
                "label": "Collingwood",
                "label_ar": "كولينجوود",
                "label_fr": "Collingwood"
            },
            {
                "value": 64509,
                "label": "Rockland",
                "label_ar": "روكلاند",
                "label_fr": "Rockland"
            },
            {
                "value": 64783,
                "label": "Lake Louise",
                "label_ar": "بحيرة، لويز",
                "label_fr": "Lake Louise"
            },
            {
                "value": 65106,
                "label": "Turtleford",
                "label_ar": "تيرتلفورد",
                "label_fr": "Turtleford"
            },
            {
                "value": 65386,
                "label": "Gronlid",
                "label_ar": "جرونليد",
                "label_fr": "Gronlid"
            },
            {
                "value": 63725,
                "label": "Vieux-Saint-Laurent",
                "label_ar": "Vieux-Saint-Laurent",
                "label_fr": "Vieux-Saint-Laurent"
            },
            {
                "value": 64818,
                "label": "Brochet",
                "label_ar": "بروشيه",
                "label_fr": "Brochet"
            },
            {
                "value": 64863,
                "label": "Lumsden",
                "label_ar": "لومسدن",
                "label_fr": "Lumsden"
            },
            {
                "value": 65307,
                "label": "Pelly",
                "label_ar": "بيلي",
                "label_fr": "Pelly"
            },
            {
                "value": 64034,
                "label": "Tillsonburg",
                "label_ar": "تيلسونبورغ",
                "label_fr": "Tillsonburg"
            },
            {
                "value": 64094,
                "label": "Charlottetown",
                "label_ar": "شارلوت تاون",
                "label_fr": "Charlottetown"
            },
            {
                "value": 64024,
                "label": "Puslinch",
                "label_ar": "بوسلينش",
                "label_fr": "Puslinch"
            },
            {
                "value": 64699,
                "label": "Athabasca",
                "label_ar": "أثاباسكا",
                "label_fr": "Athabasca"
            },
            {
                "value": 64769,
                "label": "Kagawong",
                "label_ar": "كاجاونج",
                "label_fr": "Kagawong"
            },
            {
                "value": 65393,
                "label": "Florenceville-Bristol",
                "label_ar": "فلورينسفيل بريستول",
                "label_fr": "Florenceville-Bristol"
            },
            {
                "value": 65498,
                "label": "Delburne",
                "label_ar": "دلبورن",
                "label_fr": "Delburne"
            },
            {
                "value": 65586,
                "label": "Rapid City",
                "label_ar": "رابيد سيتي",
                "label_fr": "Ville rapide"
            },
            {
                "value": 64401,
                "label": "Lakefield",
                "label_ar": "ليكفيلد",
                "label_fr": "Lakefield"
            },
            {
                "value": 64998,
                "label": "Geraldton",
                "label_ar": "جيرالدتون",
                "label_fr": "Geraldton"
            },
            {
                "value": 63721,
                "label": "Scarborough",
                "label_ar": "سكاربورو",
                "label_fr": "Scarborough"
            },
            {
                "value": 63812,
                "label": "Candiac",
                "label_ar": "كاندياك",
                "label_fr": "Candiac"
            },
            {
                "value": 65659,
                "label": "West Bay",
                "label_ar": "الخليج الغربي",
                "label_fr": "la baie ouest"
            },
            {
                "value": 63824,
                "label": "Barraute",
                "label_ar": "باروت",
                "label_fr": "Barraute"
            },
            {
                "value": 64625,
                "label": "Mortlach",
                "label_ar": "مورتلاخ",
                "label_fr": "Mortlach"
            },
            {
                "value": 63948,
                "label": "Meadow Lake",
                "label_ar": "ميدو ليك",
                "label_fr": "Lac Meadow"
            },
            {
                "value": 64441,
                "label": "Saint Fintan's",
                "label_ar": "سانت فينتان",
                "label_fr": "Saint Fintan"
            },
            {
                "value": 64326,
                "label": "Barrington",
                "label_ar": "بارينجتون",
                "label_fr": "Barrington"
            },
            {
                "value": 64954,
                "label": "Roblin",
                "label_ar": "روبلين",
                "label_fr": "Roblin"
            },
            {
                "value": 65070,
                "label": "Lucky Lake",
                "label_ar": "لاكي ليك",
                "label_fr": "Lucky Lake"
            },
            {
                "value": 64748,
                "label": "Lucan",
                "label_ar": "لوكان",
                "label_fr": "Lucan"
            },
            {
                "value": 64825,
                "label": "Blue River",
                "label_ar": "النهر الأزرق",
                "label_fr": "Rivière bleue"
            },
            {
                "value": 63867,
                "label": "Mont-Tremblant",
                "label_ar": "مونت تريمبلانت",
                "label_fr": "Mont-Tremblant"
            },
            {
                "value": 64692,
                "label": "Port Burwell",
                "label_ar": "بورت بورويل",
                "label_fr": "Port Burwell"
            },
            {
                "value": 64544,
                "label": "Drumbo",
                "label_ar": "درامبو",
                "label_fr": "Drumbo"
            },
            {
                "value": 64268,
                "label": "Tadoussac",
                "label_ar": "تادوساك",
                "label_fr": "Tadoussac"
            },
            {
                "value": 65282,
                "label": "Komoka",
                "label_ar": "كوموكا",
                "label_fr": "Komoka"
            },
            {
                "value": 65481,
                "label": "Saguenay",
                "label_ar": "ساجويني",
                "label_fr": "Saguenay"
            },
            {
                "value": 63691,
                "label": "Abbotsford",
                "label_ar": "أبوتسفورد",
                "label_fr": "Abbotsford"
            },
            {
                "value": 64899,
                "label": "Minden",
                "label_ar": "ميندن",
                "label_fr": "Minden"
            },
            {
                "value": 64812,
                "label": "Lancaster",
                "label_ar": "لانكستر",
                "label_fr": "Lancaster"
            },
            {
                "value": 64830,
                "label": "Francis",
                "label_ar": "فرانسيس",
                "label_fr": "Francis"
            },
            {
                "value": 65417,
                "label": "East Margaree",
                "label_ar": "شرق مارجاري",
                "label_fr": "Margaree Est"
            },
            {
                "value": 64801,
                "label": "Hespeler",
                "label_ar": "هيسبيلر",
                "label_fr": "Hespeler"
            },
            {
                "value": 64147,
                "label": "Caledon",
                "label_ar": "كاليدون",
                "label_fr": "Caledon"
            },
            {
                "value": 64662,
                "label": "Limoges",
                "label_ar": "ليموج",
                "label_fr": "Limoges"
            },
            {
                "value": 64174,
                "label": "Simcoe",
                "label_ar": "سيمكو",
                "label_fr": "Simcoe"
            },
            {
                "value": 63992,
                "label": "Sackville",
                "label_ar": "ساكفيل",
                "label_fr": "Sackville"
            },
            {
                "value": 64264,
                "label": "McMasterville",
                "label_ar": "ماكماسترفيل",
                "label_fr": "McMasterville"
            },
            {
                "value": 65752,
                "label": "Wiseton",
                "label_ar": "ويستون",
                "label_fr": "Wiseton"
            },
            {
                "value": 64822,
                "label": "Dominion City",
                "label_ar": "دومينيون سيتي",
                "label_fr": "Dominion City"
            },
            {
                "value": 64519,
                "label": "Glen Robertson",
                "label_ar": "جلين روبرتسون",
                "label_fr": "Glen Robertson"
            },
            {
                "value": 65325,
                "label": "Nisku",
                "label_ar": "نيسكو",
                "label_fr": "Nisku"
            },
            {
                "value": 65119,
                "label": "Sainte-Justine",
                "label_ar": "سانت جوستين",
                "label_fr": "Sainte-Justine"
            },
            {
                "value": 65361,
                "label": "New Harbour",
                "label_ar": "نيو هاربور",
                "label_fr": "Nouveau port"
            },
            {
                "value": 65625,
                "label": "Saint-Michel",
                "label_ar": "سان ميشيل",
                "label_fr": "Saint-Michel"
            },
            {
                "value": 64407,
                "label": "Terrace",
                "label_ar": "مصطبة",
                "label_fr": "Terrasse"
            },
            {
                "value": 64976,
                "label": "Alton",
                "label_ar": "ألتون",
                "label_fr": "Alton"
            },
            {
                "value": 65174,
                "label": "Deschambault",
                "label_ar": "ديشامبولت",
                "label_fr": "Deschambault"
            },
            {
                "value": 64181,
                "label": "Cornwall",
                "label_ar": "كورنوال",
                "label_fr": "Cornouailles"
            },
            {
                "value": 65579,
                "label": "Weston",
                "label_ar": "ويستون",
                "label_fr": "Weston"
            },
            {
                "value": 65155,
                "label": "Girouxville",
                "label_ar": "جيروكسفيل",
                "label_fr": "Girouxville"
            },
            {
                "value": 64210,
                "label": "Parry Sound",
                "label_ar": "باري ساوند",
                "label_fr": "Parry Sound"
            },
            {
                "value": 64176,
                "label": "Campbellford",
                "label_ar": "كامبلفورد",
                "label_fr": "Campbellford"
            },
            {
                "value": 65768,
                "label": "Marchand",
                "label_ar": "مارشاند",
                "label_fr": "Marchand"
            },
            {
                "value": 63978,
                "label": "Lampman",
                "label_ar": "لامبمان",
                "label_fr": "Lampman"
            },
            {
                "value": 64537,
                "label": "Hearst",
                "label_ar": "هيرست",
                "label_fr": "Hearst"
            },
            {
                "value": 65453,
                "label": "Sayabec",
                "label_ar": "سايابيك",
                "label_fr": "Sayabec"
            },
            {
                "value": 64262,
                "label": "Bruno",
                "label_ar": "برونو",
                "label_fr": "Bruno"
            },
            {
                "value": 64472,
                "label": "St. Albert",
                "label_ar": "سانت ألبرت",
                "label_fr": "Saint-Albert"
            },
            {
                "value": 65860,
                "label": "Saint-Francois-de-l'Ile-d'Orleans",
                "label_ar": "سان فرانسوا دي ليل دورليانز",
                "label_fr": "Saint-François-de-l'Ile-d'Orléans"
            },
            {
                "value": 65398,
                "label": "Apohaqui",
                "label_ar": "أبوهاكي",
                "label_fr": "Apohaqui"
            },
            {
                "value": 63850,
                "label": "Richmond Hill",
                "label_ar": "ريتشموند هيل",
                "label_fr": "Richmond Hill"
            },
            {
                "value": 64536,
                "label": "Cochrane",
                "label_ar": "كوكرين",
                "label_fr": "Cochrane"
            },
            {
                "value": 65357,
                "label": "Gimli",
                "label_ar": "جيملي",
                "label_fr": "Gimli"
            },
            {
                "value": 63844,
                "label": "Salaberry-de-Valleyfield",
                "label_ar": "سالبيري دي فاليفيلد",
                "label_fr": "Salaberry-de-Valleyfield"
            },
            {
                "value": 63991,
                "label": "Halifax",
                "label_ar": "هاليفاكس",
                "label_fr": "Halifax"
            },
            {
                "value": 64639,
                "label": "Ayr",
                "label_ar": "عير",
                "label_fr": "Ayr"
            },
            {
                "value": 63792,
                "label": "Saint-Lambert",
                "label_ar": "سان لامبرت",
                "label_fr": "Saint-Lambert"
            },
            {
                "value": 64182,
                "label": "Kapuskasing",
                "label_ar": "كابوسكاسينج",
                "label_fr": "Kapuskasing"
            },
            {
                "value": 64515,
                "label": "Thurso",
                "label_ar": "ثورسو",
                "label_fr": "Thurso"
            },
            {
                "value": 65522,
                "label": "Lethbridge",
                "label_ar": "ليثبريدج",
                "label_fr": "Lethbridge"
            },
            {
                "value": 64797,
                "label": "Saint-Ferdinand",
                "label_ar": "سان فرديناند",
                "label_fr": "Saint-Ferdinand"
            },
            {
                "value": 65309,
                "label": "L'Etang-du-Nord",
                "label_ar": "لتانغ دو نورد",
                "label_fr": "L'Etang-du-Nord"
            },
            {
                "value": 63816,
                "label": "Les Escoumins",
                "label_ar": "ليه اسكومينز",
                "label_fr": "Les Escoumins"
            },
            {
                "value": 65316,
                "label": "Saint-Ferreol-les-Neiges",
                "label_ar": "سان فيريول ليه نيج",
                "label_fr": "Saint-Ferréol-les-Neiges"
            },
            {
                "value": 65242,
                "label": "Tilley",
                "label_ar": "تيلي",
                "label_fr": "Tilley"
            },
            {
                "value": 64973,
                "label": "Lefroy",
                "label_ar": "يفروي",
                "label_fr": "Lefroy"
            },
            {
                "value": 65122,
                "label": "Baie-du-Febvre",
                "label_ar": "باي دو فبر",
                "label_fr": "Baie-du-Febvre"
            },
            {
                "value": 64353,
                "label": "Gander",
                "label_ar": "المغفل",
                "label_fr": "Jars"
            },
            {
                "value": 64773,
                "label": "Dashwood",
                "label_ar": "داشوود",
                "label_fr": "Dashwood"
            },
            {
                "value": 64886,
                "label": "Waldheim",
                "label_ar": "فالدهايم",
                "label_fr": "Waldheim"
            },
            {
                "value": 64688,
                "label": "Clermont",
                "label_ar": "كليرمونت",
                "label_fr": "Clermont"
            },
            {
                "value": 64048,
                "label": "Saint-Maurice",
                "label_ar": "سان موريس",
                "label_fr": "Saint-Maurice"
            },
            {
                "value": 65793,
                "label": "Bic",
                "label_ar": "بيك",
                "label_fr": "Bic"
            },
            {
                "value": 64327,
                "label": "Crapaud",
                "label_ar": "كرابود",
                "label_fr": "Crapaud"
            },
            {
                "value": 64236,
                "label": "Otterburn Park",
                "label_ar": "اوتربورن بارك",
                "label_fr": "Otterburn Park"
            },
            {
                "value": 64306,
                "label": "Bearn",
                "label_ar": "بيرن",
                "label_fr": "Bearn"
            },
            {
                "value": 65787,
                "label": "Marbleton",
                "label_ar": "ماربلتون",
                "label_fr": "Marbleton"
            },
            {
                "value": 64481,
                "label": "Vanderhoof",
                "label_ar": "فاندرهوف",
                "label_fr": "Vanderhoof"
            },
            {
                "value": 64402,
                "label": "Cobourg",
                "label_ar": "كوبورغ",
                "label_fr": "Cobourg"
            },
            {
                "value": 65739,
                "label": "Watson Lake",
                "label_ar": "بحيرة واتسون",
                "label_fr": "Watson Lake"
            },
            {
                "value": 64202,
                "label": "Middleton",
                "label_ar": "ميدلتون",
                "label_fr": "Middleton"
            },
            {
                "value": 64022,
                "label": "Shelburne",
                "label_ar": "شيلبورن",
                "label_fr": "Shelburne"
            },
            {
                "value": 65600,
                "label": "Odessa",
                "label_ar": "أوديسا",
                "label_fr": "Odessa"
            },
            {
                "value": 65175,
                "label": "Cap-Sante",
                "label_ar": "كاب سانتي",
                "label_fr": "Cap-Sante"
            },
            {
                "value": 64216,
                "label": "Napierville",
                "label_ar": "نابيرفيل",
                "label_fr": "Napierville"
            },
            {
                "value": 65644,
                "label": "Arborg",
                "label_ar": "أربورغ",
                "label_fr": "Arborg"
            },
            {
                "value": 63793,
                "label": "Mascouche",
                "label_ar": "ماسكوش",
                "label_fr": "Mascouche"
            },
            {
                "value": 64631,
                "label": "Vankleek Hill",
                "label_ar": "فانكليك هيل",
                "label_fr": "Vankleek Hill"
            },
            {
                "value": 65587,
                "label": "Dufresne",
                "label_ar": "دوفرسن",
                "label_fr": "Dufresne"
            },
            {
                "value": 65148,
                "label": "Eatonville",
                "label_ar": "إيتونفيل",
                "label_fr": "Eatonville"
            },
            {
                "value": 64028,
                "label": "Ajax",
                "label_ar": "اياكس",
                "label_fr": "Ajax"
            },
            {
                "value": 64138,
                "label": "Barrie",
                "label_ar": "باري",
                "label_fr": "Barrie"
            },
            {
                "value": 65534,
                "label": "Howley",
                "label_ar": "هاولي",
                "label_fr": "Howley"
            },
            {
                "value": 64571,
                "label": "Redvers",
                "label_ar": "ريدفيرز",
                "label_fr": "Redvers"
            },
            {
                "value": 63807,
                "label": "Boucherville",
                "label_ar": "بوشرفيل",
                "label_fr": "Boucherville"
            },
            {
                "value": 65293,
                "label": "Ripples",
                "label_ar": "تموجات",
                "label_fr": "Ondulations"
            },
            {
                "value": 65016,
                "label": "Penhold",
                "label_ar": "بنهولد",
                "label_fr": "Penhold"
            },
            {
                "value": 64092,
                "label": "Vaughan",
                "label_ar": "فوغان",
                "label_fr": "Vaughan"
            },
            {
                "value": 64316,
                "label": "Pictou",
                "label_ar": "بيكتو",
                "label_fr": "Pictou"
            },
            {
                "value": 63763,
                "label": "Grimsby",
                "label_ar": "غريمسبي",
                "label_fr": "Grimsby"
            },
            {
                "value": 64635,
                "label": "Mount Brydges",
                "label_ar": "جبل بريدج",
                "label_fr": "Mont Brydges"
            },
            {
                "value": 65277,
                "label": "Witless Bay",
                "label_ar": "ويتليس باي",
                "label_fr": "Witless Bay"
            },
            {
                "value": 64086,
                "label": "Oliver",
                "label_ar": "أوليفر",
                "label_fr": "Oliver"
            },
            {
                "value": 64962,
                "label": "Saint-Isidore",
                "label_ar": "سانت إيزيدور",
                "label_fr": "Saint-Isidore"
            },
            {
                "value": 64318,
                "label": "Troy",
                "label_ar": "طروادة",
                "label_fr": "Troie"
            },
            {
                "value": 63875,
                "label": "Saint-Mathieu",
                "label_ar": "سانت ماتيو",
                "label_fr": "Saint-Mathieu"
            },
            {
                "value": 64323,
                "label": "Grono Road",
                "label_ar": "طريق جرونو",
                "label_fr": "Chemin Grono"
            },
            {
                "value": 65266,
                "label": "Willowdale",
                "label_ar": "ويلوديل",
                "label_fr": "Willowdale"
            },
            {
                "value": 65427,
                "label": "Saint-Aime-du-Lac-des-Iles",
                "label_ar": "Saint-Aime-du-Lac-des-Iles",
                "label_fr": "Saint-Aime-du-Lac-des-Iles"
            },
            {
                "value": 64478,
                "label": "Trail",
                "label_ar": "ممر المشاة",
                "label_fr": "Piste"
            },
            {
                "value": 65783,
                "label": "Arelee",
                "label_ar": "أريلي",
                "label_fr": "Arelee"
            },
            {
                "value": 63820,
                "label": "Deux-Montagnes",
                "label_ar": "دوكس مونتانيس",
                "label_fr": "Deux-Montagnes"
            },
            {
                "value": 63959,
                "label": "Moosomin",
                "label_ar": "موسومين",
                "label_fr": "Moosomin"
            },
            {
                "value": 65423,
                "label": "Emerald Junction",
                "label_ar": "إميرالد جانكشن",
                "label_fr": "Jonction d'émeraude"
            },
            {
                "value": 64070,
                "label": "Roxton Falls",
                "label_ar": "شلالات روكستون",
                "label_fr": "Roxton Falls"
            },
            {
                "value": 65179,
                "label": "Saint-Pamphile",
                "label_ar": "سانت بامفيل",
                "label_fr": "Saint-Pamphile"
            },
            {
                "value": 65150,
                "label": "Saint-Stanislas",
                "label_ar": "سانت ستانيسلاس",
                "label_fr": "Saint-Stanislas"
            },
            {
                "value": 63765,
                "label": "Beamsville",
                "label_ar": "بيمزفيل",
                "label_fr": "Beamsville"
            },
            {
                "value": 65705,
                "label": "Cross Lake",
                "label_ar": "كروس ليك",
                "label_fr": "Cross Lake"
            },
            {
                "value": 65382,
                "label": "White City",
                "label_ar": "المدينة البيضاء",
                "label_fr": "Ville blanche"
            },
            {
                "value": 65128,
                "label": "Saint-Vallier",
                "label_ar": "سانت فالييه",
                "label_fr": "Saint-Vallier"
            },
            {
                "value": 65742,
                "label": "Gray Creek",
                "label_ar": "غراي كريك",
                "label_fr": "Gray Creek"
            },
            {
                "value": 63801,
                "label": "Sainte-Therese",
                "label_ar": "سانت تيريز",
                "label_fr": "Sainte-Thérèse"
            },
            {
                "value": 64373,
                "label": "Bay de Verde",
                "label_ar": "باي دي فيردي",
                "label_fr": "Baie de Verde"
            },
            {
                "value": 64585,
                "label": "Kitchener",
                "label_ar": "كيتشنر",
                "label_fr": "Kitchener"
            },
            {
                "value": 64287,
                "label": "Callander",
                "label_ar": "كالاندر",
                "label_fr": "Callander"
            },
            {
                "value": 65839,
                "label": "Inglis",
                "label_ar": "إنجليس",
                "label_fr": "Inglis"
            },
            {
                "value": 64677,
                "label": "Wiarton",
                "label_ar": "فيارتون",
                "label_fr": "Wiarton"
            },
            {
                "value": 64986,
                "label": "Biencourt",
                "label_ar": "بينكور",
                "label_fr": "Biencourt"
            },
            {
                "value": 65130,
                "label": "Coaticook",
                "label_ar": "Coaticook",
                "label_fr": "Coaticook"
            },
            {
                "value": 65503,
                "label": "Salmon River",
                "label_ar": "نهر السلمون",
                "label_fr": "Rivière Salmon"
            },
            {
                "value": 64314,
                "label": "Westville",
                "label_ar": "ويستفيل",
                "label_fr": "Westville"
            },
            {
                "value": 64427,
                "label": "Coniston",
                "label_ar": "كونيستون",
                "label_fr": "Coniston"
            },
            {
                "value": 64646,
                "label": "Deline",
                "label_ar": "دي لاين",
                "label_fr": "Déline"
            },
            {
                "value": 65823,
                "label": "Onanole",
                "label_ar": "أونانول",
                "label_fr": "Onanole"
            },
            {
                "value": 65584,
                "label": "Saint-Paul-d'Abbotsford",
                "label_ar": "سان بول دابوتسفورد",
                "label_fr": "Saint-Paul-d'Abbotsford"
            },
            {
                "value": 64119,
                "label": "Antigonish",
                "label_ar": "أنتيجونيش",
                "label_fr": "Antigonish"
            },
            {
                "value": 65275,
                "label": "St. Marys",
                "label_ar": "سانت ماريز",
                "label_fr": "St. Marys"
            },
            {
                "value": 63810,
                "label": "Saint-Lambert",
                "label_ar": "سان لامبرت",
                "label_fr": "Saint-Lambert"
            },
            {
                "value": 64658,
                "label": "Finch",
                "label_ar": "خفق",
                "label_fr": "Bouvreuil"
            },
            {
                "value": 65125,
                "label": "Roxton Pond",
                "label_ar": "روكستون بوند",
                "label_fr": "Étang Roxton"
            },
            {
                "value": 64912,
                "label": "Rainbow Lake",
                "label_ar": "بحيرة قوس قزح",
                "label_fr": "Rainbow Lake"
            },
            {
                "value": 65837,
                "label": "Colville Lake",
                "label_ar": "بحيرة كولفيل",
                "label_fr": "Lac Colville"
            },
            {
                "value": 64620,
                "label": "Swan River",
                "label_ar": "نهر البجع",
                "label_fr": "Swan River"
            },
            {
                "value": 64304,
                "label": "Saint-Hugues",
                "label_ar": "سانت هوغز",
                "label_fr": "Saint-Hugues"
            },
            {
                "value": 64366,
                "label": "Chester",
                "label_ar": "تشيستر",
                "label_fr": "Chester"
            },
            {
                "value": 65132,
                "label": "Sainte-Claire",
                "label_ar": "سانت كلير",
                "label_fr": "Sainte-Claire"
            },
            {
                "value": 65575,
                "label": "Kazabazua",
                "label_ar": "كازابازوا",
                "label_fr": "Kazabazua"
            },
            {
                "value": 63988,
                "label": "Sydney",
                "label_ar": "سيدني",
                "label_fr": "Sydney"
            },
            {
                "value": 64667,
                "label": "Coldwater",
                "label_ar": "ماء بارد",
                "label_fr": "Eau froide"
            },
            {
                "value": 65063,
                "label": "Maryfield",
                "label_ar": "ماريفيلد",
                "label_fr": "Maryfield"
            },
            {
                "value": 65533,
                "label": "Lakeburn",
                "label_ar": "لايبورن",
                "label_fr": "Lakeburn"
            },
            {
                "value": 63973,
                "label": "Sechelt",
                "label_ar": "سيتشيلت",
                "label_fr": "Sechelt"
            },
            {
                "value": 64701,
                "label": "Paradise Valley",
                "label_ar": "وادي الجنة",
                "label_fr": "Paradise Valley"
            },
            {
                "value": 64758,
                "label": "Cap-Saint-Ignace",
                "label_ar": "كاب سان اجناس",
                "label_fr": "Cap-Saint-Ignace"
            },
            {
                "value": 64650,
                "label": "Atlin",
                "label_ar": "أتلين",
                "label_fr": "Atlin"
            },
            {
                "value": 65638,
                "label": "Quill Lake",
                "label_ar": "بحيرة كويل",
                "label_fr": "Lac Quill"
            },
            {
                "value": 65773,
                "label": "Acadia Valley",
                "label_ar": "وادي أكاديا",
                "label_fr": "Vallée de l'Acadie"
            },
            {
                "value": 63849,
                "label": "Aurora",
                "label_ar": "أورورا",
                "label_fr": "Aurore"
            },
            {
                "value": 64889,
                "label": "Pilot Butte",
                "label_ar": "بايلوت بوت",
                "label_fr": "Pilote Butte"
            },
            {
                "value": 64813,
                "label": "Brighton",
                "label_ar": "برايتون",
                "label_fr": "Brighton"
            },
            {
                "value": 64277,
                "label": "Sutton",
                "label_ar": "ساتون",
                "label_fr": "Sutton"
            },
            {
                "value": 63687,
                "label": "New Westminster",
                "label_ar": "نيو وستمنستر",
                "label_fr": "New Westminster"
            },
            {
                "value": 64881,
                "label": "Laurentia Beach",
                "label_ar": "شاطئ لورينتيا",
                "label_fr": "Plage Laurentia"
            },
            {
                "value": 65245,
                "label": "Union Bay",
                "label_ar": "يونيون باي",
                "label_fr": "Union Bay"
            },
            {
                "value": 64135,
                "label": "Sydney Mines",
                "label_ar": "مناجم سيدني",
                "label_fr": "Sydney Mines"
            },
            {
                "value": 63709,
                "label": "High Level",
                "label_ar": "مستوى عال",
                "label_fr": "Haut niveau"
            },
            {
                "value": 65457,
                "label": "Glenwood",
                "label_ar": "جلينوود",
                "label_fr": "Glenwood"
            },
            {
                "value": 63936,
                "label": "Williams Lake",
                "label_ar": "بحيرة ويليامز",
                "label_fr": "Williams Lake"
            },
            {
                "value": 63706,
                "label": "Okotoks",
                "label_ar": "Okotoks",
                "label_fr": "Okotoks"
            },
            {
                "value": 63782,
                "label": "Saint-Eustache",
                "label_ar": "سانت يوستاش",
                "label_fr": "Saint-Eustache"
            },
            {
                "value": 63873,
                "label": "Notre-Dame-du-Mont-Carmel",
                "label_ar": "نوتردام دو مونت كارمل",
                "label_fr": "Notre-Dame-du-Mont-Carmel"
            },
            {
                "value": 65599,
                "label": "Spencerville",
                "label_ar": "سبنسرفيل",
                "label_fr": "Spencerville"
            },
            {
                "value": 65256,
                "label": "Upton",
                "label_ar": "ابتون",
                "label_fr": "Upton"
            },
            {
                "value": 65241,
                "label": "Duchess",
                "label_ar": "دوقة",
                "label_fr": "Duchesse"
            },
            {
                "value": 64562,
                "label": "Wadena",
                "label_ar": "وادينا",
                "label_fr": "Wadena"
            },
            {
                "value": 64672,
                "label": "Baie-Sainte-Catherine",
                "label_ar": "باي سانت كاترين",
                "label_fr": "Baie-Sainte-Catherine"
            },
            {
                "value": 64559,
                "label": "Lang",
                "label_ar": "لانج",
                "label_fr": "Lang"
            },
            {
                "value": 65426,
                "label": "Chibougamau",
                "label_ar": "شيبوغاماو",
                "label_fr": "Chibougamau"
            },
            {
                "value": 64910,
                "label": "Nobleford",
                "label_ar": "نوبلفورد",
                "label_fr": "Nobleford"
            },
            {
                "value": 65332,
                "label": "Milton",
                "label_ar": "ميلتون",
                "label_fr": "Milton"
            },
            {
                "value": 63857,
                "label": "Saint-Gabriel-de-Valcartier",
                "label_ar": "سان جابرييل دي فالكارتير",
                "label_fr": "Saint-Gabriel-de-Valcartier"
            },
            {
                "value": 63677,
                "label": "Markham",
                "label_ar": "ماركهام",
                "label_fr": "Markham"
            },
            {
                "value": 64690,
                "label": "Wyoming",
                "label_ar": "وايومنغ",
                "label_fr": "Wyoming"
            },
            {
                "value": 64009,
                "label": "Lake Cowichan",
                "label_ar": "بحيرة كويشان",
                "label_fr": "Lac Cowichan"
            },
            {
                "value": 64740,
                "label": "Landrienne",
                "label_ar": "لاندريان",
                "label_fr": "Landrienne"
            },
            {
                "value": 64307,
                "label": "La Tuque",
                "label_ar": "لا توك",
                "label_fr": "La Tuque"
            },
            {
                "value": 64377,
                "label": "Acton Vale",
                "label_ar": "أكتون فالي",
                "label_fr": "Acton Vale"
            },
            {
                "value": 65648,
                "label": "Comber",
                "label_ar": "كومبر",
                "label_fr": "Peigneuse"
            },
            {
                "value": 63861,
                "label": "Centreville",
                "label_ar": "سنترفيل",
                "label_fr": "Centreville"
            },
            {
                "value": 65097,
                "label": "Myrnam",
                "label_ar": "ميرنام",
                "label_fr": "Myrnam"
            },
            {
                "value": 64524,
                "label": "Harriston",
                "label_ar": "هارستون",
                "label_fr": "Harriston"
            },
            {
                "value": 64404,
                "label": "Port Hope",
                "label_ar": "ميناء الأمل",
                "label_fr": "Port Hope"
            },
            {
                "value": 65099,
                "label": "Thorhild",
                "label_ar": "ثورهيلد",
                "label_fr": "Thorhild"
            },
            {
                "value": 65317,
                "label": "Aylmer",
                "label_ar": "ايلمر",
                "label_fr": "Aylmer"
            },
            {
                "value": 65546,
                "label": "Les Coteaux",
                "label_ar": "لي كوتو",
                "label_fr": "Les Coteaux"
            },
            {
                "value": 63835,
                "label": "Asbestos",
                "label_ar": "الاسبستوس",
                "label_fr": "Amiante"
            },
            {
                "value": 65722,
                "label": "Port Alice",
                "label_ar": "ميناء أليس",
                "label_fr": "Port Alice"
            },
            {
                "value": 65631,
                "label": "Binscarth",
                "label_ar": "بنسكارث",
                "label_fr": "Binscarth"
            },
            {
                "value": 64623,
                "label": "Eston",
                "label_ar": "إستون",
                "label_fr": "Eston"
            },
            {
                "value": 64949,
                "label": "Oxford House",
                "label_ar": "أكسفورد هاوس",
                "label_fr": "Maison d'Oxford"
            },
            {
                "value": 65792,
                "label": "Moberly Lake",
                "label_ar": "بحيرة موبرلي",
                "label_fr": "Lac Moberly"
            },
            {
                "value": 64676,
                "label": "Markdale",
                "label_ar": "ماركديل",
                "label_fr": "Markdale"
            },
            {
                "value": 64716,
                "label": "Kamouraska",
                "label_ar": "كاموراسكا",
                "label_fr": "Kamouraska"
            },
            {
                "value": 65243,
                "label": "Rosemary",
                "label_ar": "إكليل الجبل",
                "label_fr": "Romarin"
            },
            {
                "value": 65230,
                "label": "Entwistle",
                "label_ar": "شراك",
                "label_fr": "Entwistle"
            },
            {
                "value": 65331,
                "label": "North Rustico",
                "label_ar": "شمال روستيكو",
                "label_fr": "North Rustico"
            },
            {
                "value": 63815,
                "label": "Stratford",
                "label_ar": "ستراتفورد",
                "label_fr": "Stratford"
            },
            {
                "value": 64145,
                "label": "Schomberg",
                "label_ar": "شومبرج",
                "label_fr": "Schomberg"
            },
            {
                "value": 64406,
                "label": "Kitimat",
                "label_ar": "كيتيمات",
                "label_fr": "Kitimat"
            },
            {
                "value": 64567,
                "label": "Swift Current",
                "label_ar": "التيار السريع",
                "label_fr": "Courant Swift"
            },
            {
                "value": 65539,
                "label": "Big Valley",
                "label_ar": "الوادي الكبير",
                "label_fr": "Grande vallée"
            },
            {
                "value": 65721,
                "label": "Kerwood",
                "label_ar": "كيروود",
                "label_fr": "Kerwood"
            },
            {
                "value": 65519,
                "label": "Willowvale",
                "label_ar": "ويلوفيل",
                "label_fr": "Willowvale"
            },
            {
                "value": 65271,
                "label": "Hillsburgh",
                "label_ar": "هيلسبورج",
                "label_fr": "Hillsburgh"
            },
            {
                "value": 64779,
                "label": "Viking",
                "label_ar": "فايكنغ",
                "label_fr": "Viking"
            },
            {
                "value": 63854,
                "label": "Whitby",
                "label_ar": "ويتبي",
                "label_fr": "Whitby"
            },
            {
                "value": 64333,
                "label": "Carbonear",
                "label_ar": "كاربونير",
                "label_fr": "Carbonear"
            },
            {
                "value": 64293,
                "label": "Fort Nelson",
                "label_ar": "فورت نيلسون",
                "label_fr": "Fort Nelson"
            },
            {
                "value": 63739,
                "label": "Quebec",
                "label_ar": "كيبيك",
                "label_fr": "Québec"
            },
            {
                "value": 63719,
                "label": "Ottawa",
                "label_ar": "أوتاوا",
                "label_fr": "Ottawa"
            },
            {
                "value": 65656,
                "label": "St. Stephen",
                "label_ar": "سانت ستيفن",
                "label_fr": "St. Stephen"
            },
            {
                "value": 63679,
                "label": "Coquitlam",
                "label_ar": "كوكتلام",
                "label_fr": "Coquitlam"
            },
            {
                "value": 64685,
                "label": "Apsley",
                "label_ar": "أبسلي",
                "label_fr": "Apsley"
            },
            {
                "value": 65532,
                "label": "Perth",
                "label_ar": "بيرث",
                "label_fr": "Perth"
            },
            {
                "value": 63930,
                "label": "Cumberland",
                "label_ar": "كمبرلاند",
                "label_fr": "Cumberland"
            },
            {
                "value": 65460,
                "label": "Lytton",
                "label_ar": "ليتون",
                "label_fr": "Lytton"
            },
            {
                "value": 64730,
                "label": "Saint-Raymond",
                "label_ar": "سانت ريمون",
                "label_fr": "Saint-Raymond"
            },
            {
                "value": 64726,
                "label": "Kangiqsujuaq",
                "label_ar": "كانجيكسوجواك",
                "label_fr": "Kangiqsujuaq"
            },
            {
                "value": 64379,
                "label": "Metabetchouan-Lac-a-la-Croix",
                "label_ar": "ميتابتشوان لاك لا كروا",
                "label_fr": "Métabetchouan-Lac-à-la-Croix"
            },
            {
                "value": 64911,
                "label": "Stirling",
                "label_ar": "ستيرلينغ",
                "label_fr": "Stirling"
            },
            {
                "value": 64793,
                "label": "Saint-Eusebe",
                "label_ar": "سان أوزيبي",
                "label_fr": "Saint-Eusebe"
            },
            {
                "value": 64211,
                "label": "Maple",
                "label_ar": "خشب القيقب",
                "label_fr": "Érable"
            },
            {
                "value": 65607,
                "label": "Cottam",
                "label_ar": "كوتام",
                "label_fr": "Cottam"
            },
            {
                "value": 64079,
                "label": "Hebertville",
                "label_ar": "هيبيرتفيل",
                "label_fr": "Hebertville"
            },
            {
                "value": 65833,
                "label": "Fox Creek",
                "label_ar": "فوكس كريك",
                "label_fr": "Fox Creek"
            },
            {
                "value": 64082,
                "label": "Ayer's Cliff",
                "label_ar": "آير كليف",
                "label_fr": "Ayer's Cliff"
            },
            {
                "value": 64317,
                "label": "Marystown",
                "label_ar": "ماريستاون",
                "label_fr": "Marystown"
            },
            {
                "value": 64938,
                "label": "Sainte-Anne-de-Bellevue",
                "label_ar": "سانت آن دي بلفيو",
                "label_fr": "Sainte-Anne-de-Bellevue"
            },
            {
                "value": 64030,
                "label": "Glencoe",
                "label_ar": "جلينكو",
                "label_fr": "Glencoe"
            },
            {
                "value": 64381,
                "label": "Warwick",
                "label_ar": "وارويك",
                "label_fr": "Warwick"
            },
            {
                "value": 65162,
                "label": "Altona",
                "label_ar": "التونا",
                "label_fr": "Altona"
            },
            {
                "value": 63864,
                "label": "Jerseyville",
                "label_ar": "جيرسيفيل",
                "label_fr": "Jerseyville"
            },
            {
                "value": 65549,
                "label": "Wellington",
                "label_ar": "ويلينجتون",
                "label_fr": "Wellington"
            },
            {
                "value": 64438,
                "label": "Dover",
                "label_ar": "دوفر",
                "label_fr": "Douvres"
            },
            {
                "value": 64397,
                "label": "Exeter",
                "label_ar": "إكستر",
                "label_fr": "Exeter"
            },
            {
                "value": 65147,
                "label": "Blandford",
                "label_ar": "بلاندفورد",
                "label_fr": "Blandford"
            },
            {
                "value": 65697,
                "label": "Nouvelle",
                "label_ar": "نوفيل",
                "label_fr": "Nouvelle"
            },
            {
                "value": 64437,
                "label": "Pointe-Verte",
                "label_ar": "بوانت فيرت",
                "label_fr": "Pointe-Verte"
            },
            {
                "value": 65142,
                "label": "Fort Albany",
                "label_ar": "حصن ألباني",
                "label_fr": "Fort Albany"
            },
            {
                "value": 65392,
                "label": "Saint Andrews",
                "label_ar": "سانت اندروز",
                "label_fr": "Saint Andrews"
            },
            {
                "value": 65446,
                "label": "Caroline",
                "label_ar": "كارولين",
                "label_fr": "Caroline"
            },
            {
                "value": 65001,
                "label": "Saint-Léonard",
                "label_ar": "سان ليونارد",
                "label_fr": "Saint-Léonard"
            },
            {
                "value": 64792,
                "label": "L'Ascension",
                "label_ar": "لا أسنسيون",
                "label_fr": "L'Ascension"
            },
            {
                "value": 63700,
                "label": "Fort McMurray",
                "label_ar": "فورت ماكموري",
                "label_fr": "Fort McMurray"
            },
            {
                "value": 64883,
                "label": "Enterprise",
                "label_ar": "مشروع - مغامرة",
                "label_fr": "Entreprise"
            },
            {
                "value": 65685,
                "label": "Russell",
                "label_ar": "راسل",
                "label_fr": "Russell"
            },
            {
                "value": 65432,
                "label": "Otter Lake",
                "label_ar": "بحيرة أوتر",
                "label_fr": "Otter Lake"
            },
            {
                "value": 65680,
                "label": "Cape Dorset",
                "label_ar": "كيب دورست",
                "label_fr": "Cape Dorset"
            },
            {
                "value": 64351,
                "label": "Victoria Cove",
                "label_ar": "فيكتوريا كوف",
                "label_fr": "Victoria Cove"
            },
            {
                "value": 63972,
                "label": "White Rock",
                "label_ar": "صخرة بيضاء",
                "label_fr": "White Rock"
            },
            {
                "value": 63862,
                "label": "Cambridge",
                "label_ar": "كامبريدج",
                "label_fr": "Cambridge"
            },
            {
                "value": 65861,
                "label": "Vauxhall",
                "label_ar": "فوكسهول",
                "label_fr": "Vauxhall"
            },
            {
                "value": 64310,
                "label": "St. Thomas",
                "label_ar": "سانت توماس",
                "label_fr": "Saint Thomas"
            },
            {
                "value": 64084,
                "label": "Tumbler Ridge",
                "label_ar": "بهلوان ريدج",
                "label_fr": "Tumbler Ridge"
            },
            {
                "value": 63837,
                "label": "Saint-Anaclet-de-Lessard",
                "label_ar": "سان أناكليت دي ليسار",
                "label_fr": "Saint-Anaclet-de-Lessard"
            },
            {
                "value": 64348,
                "label": "Liverpool",
                "label_ar": "ليفربول",
                "label_fr": "Liverpool"
            },
            {
                "value": 64543,
                "label": "Niagara",
                "label_ar": "نياجرا",
                "label_fr": "Niagara"
            },
            {
                "value": 65677,
                "label": "Rossport",
                "label_ar": "روسبورت",
                "label_fr": "Rossport"
            },
            {
                "value": 64133,
                "label": "Meteghan River",
                "label_ar": "نهر ميتيغان",
                "label_fr": "Rivière Meteghan"
            },
            {
                "value": 63770,
                "label": "Granby",
                "label_ar": "جرانبي",
                "label_fr": "Granby"
            },
            {
                "value": 63805,
                "label": "Verdun",
                "label_ar": "فردان",
                "label_fr": "Verdun"
            },
            {
                "value": 65212,
                "label": "Pakenham",
                "label_ar": "باكينهام",
                "label_fr": "Pakenham"
            },
            {
                "value": 65205,
                "label": "Pass Lake",
                "label_ar": "باس ليك",
                "label_fr": "Pass Lake"
            },
            {
                "value": 63897,
                "label": "Taber",
                "label_ar": "تابر",
                "label_fr": "Taber"
            },
            {
                "value": 64031,
                "label": "Caledon",
                "label_ar": "كاليدون",
                "label_fr": "Caledon"
            },
            {
                "value": 64840,
                "label": "Ile-a-la-Crosse",
                "label_ar": "إيل-لا-كروس",
                "label_fr": "Ile-à-la-Crosse"
            },
            {
                "value": 64013,
                "label": "Van Anda",
                "label_ar": "فان أندا",
                "label_fr": "Van Anda"
            },
            {
                "value": 65110,
                "label": "Buckhorn",
                "label_ar": "بقرن",
                "label_fr": "Buckhorn"
            },
            {
                "value": 64845,
                "label": "Cumberland House",
                "label_ar": "كمبرلاند هاوس",
                "label_fr": "Maison Cumberland"
            },
            {
                "value": 64045,
                "label": "Niagara-on-the-Lake",
                "label_ar": "نياجرا أون ذا ليك",
                "label_fr": "Niagara-on-the-Lake"
            },
            {
                "value": 65484,
                "label": "Dorion-Gardens",
                "label_ar": "دوريون جاردنز",
                "label_fr": "Dorion-Jardins"
            },
            {
                "value": 64109,
                "label": "Heart's Delight-Islington",
                "label_ar": "فرحة القلب - إيسلينجتون",
                "label_fr": "Heart's Delight-Islington"
            },
            {
                "value": 65375,
                "label": "Wabana",
                "label_ar": "وابانا",
                "label_fr": "Wabana"
            },
            {
                "value": 64877,
                "label": "Ripon",
                "label_ar": "ريبون",
                "label_fr": "Ripon"
            },
            {
                "value": 65137,
                "label": "Cromer",
                "label_ar": "كرومر",
                "label_fr": "Cromer"
            },
            {
                "value": 64966,
                "label": "McGregor",
                "label_ar": "ماكجريجور",
                "label_fr": "McGregor"
            },
            {
                "value": 64626,
                "label": "Cabri",
                "label_ar": "كابري",
                "label_fr": "Cabri"
            },
            {
                "value": 63749,
                "label": "Queen Charlotte",
                "label_ar": "الملكة شارلوت",
                "label_fr": "Reine Charlotte"
            },
            {
                "value": 65454,
                "label": "Causapscal",
                "label_ar": "السببية",
                "label_fr": "Causapscal"
            },
            {
                "value": 64420,
                "label": "Tracadie–Sheila",
                "label_ar": "تراكادي - شيلا",
                "label_fr": "Tracadie – Sheila"
            },
            {
                "value": 64385,
                "label": "L'Anse-Saint-Jean",
                "label_ar": "لانس سان جان",
                "label_fr": "L'Anse-Saint-Jean"
            },
            {
                "value": 64029,
                "label": "Orangeville",
                "label_ar": "أورانجفيل",
                "label_fr": "Orangeville"
            },
            {
                "value": 64525,
                "label": "Alexandria",
                "label_ar": "الإسكندرية",
                "label_fr": "Alexandrie"
            },
            {
                "value": 63732,
                "label": "London",
                "label_ar": "لندن",
                "label_fr": "Londres"
            },
            {
                "value": 63887,
                "label": "Stettler",
                "label_ar": "ستيتلر",
                "label_fr": "Stettler"
            },
            {
                "value": 65665,
                "label": "Port Edward",
                "label_ar": "بورت إدوارد",
                "label_fr": "Port Edward"
            },
            {
                "value": 65059,
                "label": "Upper Island Cove",
                "label_ar": "أبر آيلاند كوف",
                "label_fr": "Upper Island Cove"
            },
            {
                "value": 63795,
                "label": "Chambly",
                "label_ar": "شامبلي",
                "label_fr": "Chambly"
            },
            {
                "value": 64186,
                "label": "Durham",
                "label_ar": "دورهام",
                "label_fr": "Durham"
            },
            {
                "value": 64064,
                "label": "Saint-Ulric",
                "label_ar": "سان أولريك",
                "label_fr": "Saint-Ulric"
            },
            {
                "value": 63869,
                "label": "Plessisville",
                "label_ar": "بليسيسفيل",
                "label_fr": "Plessisville"
            },
            {
                "value": 63929,
                "label": "Parksville",
                "label_ar": "باركسفيل",
                "label_fr": "Parksville"
            },
            {
                "value": 65574,
                "label": "Maberly",
                "label_ar": "مابرلي",
                "label_fr": "Maberly"
            },
            {
                "value": 64981,
                "label": "Penetanguishene",
                "label_ar": "Penetanguishene",
                "label_fr": "Penetanguishene"
            },
            {
                "value": 64680,
                "label": "Holman",
                "label_ar": "هولمان",
                "label_fr": "Holman"
            },
            {
                "value": 64647,
                "label": "Orton",
                "label_ar": "أورتن",
                "label_fr": "Orton"
            },
            {
                "value": 64576,
                "label": "Broadview",
                "label_ar": "نظرة واسعة",
                "label_fr": "Large vue"
            },
            {
                "value": 65289,
                "label": "Riverview",
                "label_ar": "اطلالة النهر",
                "label_fr": "Vue de la rivière"
            },
            {
                "value": 64151,
                "label": "New Liskeard",
                "label_ar": "جديد ليسكيرد",
                "label_fr": "New Liskeard"
            },
            {
                "value": 63669,
                "label": "Toronto",
                "label_ar": "تورنتو",
                "label_fr": "Toronto"
            },
            {
                "value": 65257,
                "label": "Bonsecours",
                "label_ar": "Bonsecours",
                "label_fr": "Bonsecours"
            },
            {
                "value": 64666,
                "label": "Brockville",
                "label_ar": "بروكفيل",
                "label_fr": "Brockville"
            },
            {
                "value": 63753,
                "label": "Hawkesbury",
                "label_ar": "هوكسبيري",
                "label_fr": "Hawkesbury"
            },
            {
                "value": 65294,
                "label": "Orono",
                "label_ar": "أورونو",
                "label_fr": "Orono"
            },
            {
                "value": 64346,
                "label": "Murray Harbour",
                "label_ar": "ميناء موراي",
                "label_fr": "Murray Harbour"
            },
            {
                "value": 64759,
                "label": "Little Current",
                "label_ar": "القليل الحالي",
                "label_fr": "Petit courant"
            },
            {
                "value": 64713,
                "label": "Saint-Roch-des-Aulnaies",
                "label_ar": "Saint-Roch-des-Aulnaies",
                "label_fr": "Saint-Roch-des-Aulnaies"
            },
            {
                "value": 65429,
                "label": "Beaux-Rivages--Lac-des-Ecorces--Val-Barrette",
                "label_ar": "Beaux-Rivages - Lac-des-Ecorces - Val-Barrette",
                "label_fr": "Beaux-Rivages - Lac-des-Ecorces - Val-Barrette"
            },
            {
                "value": 64214,
                "label": "Saint-Camille",
                "label_ar": "سان كميل",
                "label_fr": "Saint-Camille"
            },
            {
                "value": 64675,
                "label": "Thorndale",
                "label_ar": "ثورنديل",
                "label_fr": "Thorndale"
            },
            {
                "value": 64403,
                "label": "Baltimore",
                "label_ar": "بالتيمور",
                "label_fr": "Baltimore"
            },
            {
                "value": 64224,
                "label": "Corbyville",
                "label_ar": "كوربيفيل",
                "label_fr": "Corbyville"
            },
            {
                "value": 64367,
                "label": "Baie Verte",
                "label_ar": "باي فيرت",
                "label_fr": "Baie Verte"
            },
            {
                "value": 65763,
                "label": "Wrentham",
                "label_ar": "رينثام",
                "label_fr": "Wrentham"
            },
            {
                "value": 65545,
                "label": "Bloomfield",
                "label_ar": "بلومفيلد",
                "label_fr": "Bloomfield"
            },
            {
                "value": 64934,
                "label": "Burk's Falls",
                "label_ar": "شلالات بورك",
                "label_fr": "Burk's Falls"
            },
            {
                "value": 65206,
                "label": "Theresa",
                "label_ar": "هناك",
                "label_fr": "Il y a un"
            },
            {
                "value": 64091,
                "label": "Potter",
                "label_ar": "بوتر",
                "label_fr": "Potier"
            },
            {
                "value": 64605,
                "label": "Tofino",
                "label_ar": "توفينو",
                "label_fr": "Tofino"
            },
            {
                "value": 64195,
                "label": "Cold Lake",
                "label_ar": "كولد ليك",
                "label_fr": "Cold Lake"
            },
            {
                "value": 65692,
                "label": "Agincourt",
                "label_ar": "أجينكور",
                "label_fr": "Agincourt"
            },
            {
                "value": 63952,
                "label": "North Battleford",
                "label_ar": "شمال باتلفورد",
                "label_fr": "North Battleford"
            },
            {
                "value": 65451,
                "label": "Borden-Carleton",
                "label_ar": "بوردن كارلتون",
                "label_fr": "Borden-Carleton"
            },
            {
                "value": 65570,
                "label": "Avonmore",
                "label_ar": "أفونمور",
                "label_fr": "Avonmore"
            },
            {
                "value": 65354,
                "label": "Sperling",
                "label_ar": "سبيرلينج",
                "label_fr": "Sperling"
            },
            {
                "value": 65280,
                "label": "Delaware West",
                "label_ar": "ديلاوير ويست",
                "label_fr": "Delaware Ouest"
            },
            {
                "value": 64648,
                "label": "Eaglesham",
                "label_ar": "ايجلشام",
                "label_fr": "Eaglesham"
            },
            {
                "value": 64534,
                "label": "Bancroft",
                "label_ar": "بانكروفت",
                "label_fr": "Bancroft"
            },
            {
                "value": 65862,
                "label": "Tennion",
                "label_ar": "تينيون",
                "label_fr": "Tennion"
            },
            {
                "value": 64622,
                "label": "Onion Lake",
                "label_ar": "بحيرة البصل",
                "label_fr": "Lac Onion"
            },
            {
                "value": 63984,
                "label": "Shelburne",
                "label_ar": "شيلبورن",
                "label_fr": "Shelburne"
            },
            {
                "value": 65209,
                "label": "Saint-Clement",
                "label_ar": "سانت كليمان",
                "label_fr": "Saint-Clément"
            },
            {
                "value": 65653,
                "label": "Churchville",
                "label_ar": "تشيرشفيل",
                "label_fr": "Churchville"
            },
            {
                "value": 65754,
                "label": "Bethune",
                "label_ar": "بيثون",
                "label_fr": "Bethune"
            },
            {
                "value": 64933,
                "label": "Mabou",
                "label_ar": "مابو",
                "label_fr": "Mabou"
            },
            {
                "value": 64148,
                "label": "Greater Sudbury",
                "label_ar": "أكبر Sudbury",
                "label_fr": "Grand Sudbury"
            },
            {
                "value": 65838,
                "label": "Hamiota",
                "label_ar": "حميوتا",
                "label_fr": "Hamiota"
            },
            {
                "value": 64698,
                "label": "Cherry Grove",
                "label_ar": "شيري جروف",
                "label_fr": "Cherry Grove"
            },
            {
                "value": 64205,
                "label": "Saint-Frederic",
                "label_ar": "سانت فريدريك",
                "label_fr": "Saint-Frédéric"
            },
            {
                "value": 65033,
                "label": "Red Lake",
                "label_ar": "البحيرة الحمراء",
                "label_fr": "Lac Rouge"
            },
            {
                "value": 63934,
                "label": "Armstrong",
                "label_ar": "ارمسترونغ",
                "label_fr": "Armstrong"
            },
            {
                "value": 64642,
                "label": "Kugaaruk",
                "label_ar": "كوجاروك",
                "label_fr": "Kugaaruk"
            },
            {
                "value": 64417,
                "label": "Fredericton",
                "label_ar": "فريدريكتون",
                "label_fr": "Fredericton"
            },
            {
                "value": 64919,
                "label": "Saint-Alexis-des-Monts",
                "label_ar": "سان الكسيس دي مونت",
                "label_fr": "Saint-Alexis-des-Monts"
            },
            {
                "value": 64275,
                "label": "Saint-Venant-de-Paquette",
                "label_ar": "سان فينان دي باكيت",
                "label_fr": "Saint-Venant-de-Paquette"
            },
            {
                "value": 64628,
                "label": "Saint-Andre",
                "label_ar": "سانت اندريه",
                "label_fr": "Saint-André"
            },
            {
                "value": 65302,
                "label": "Strasbourg",
                "label_ar": "ستراسبورغ",
                "label_fr": "Strasbourg"
            },
            {
                "value": 64746,
                "label": "Bluewater",
                "label_ar": "ماء ازرق",
                "label_fr": "L'eau bleue"
            },
            {
                "value": 63878,
                "label": "Tecumseh",
                "label_ar": "تيكومسيه",
                "label_fr": "Tecumseh"
            },
            {
                "value": 65050,
                "label": "Middle Arm",
                "label_ar": "الذراع الأوسط",
                "label_fr": "Bras moyen"
            },
            {
                "value": 64018,
                "label": "Atikokan",
                "label_ar": "أتيكوكان",
                "label_fr": "Atikokan"
            },
            {
                "value": 65434,
                "label": "Matagami",
                "label_ar": "ماتاجامي",
                "label_fr": "Matagami"
            },
            {
                "value": 64766,
                "label": "Chestermere",
                "label_ar": "تشيسترمير",
                "label_fr": "Chestermere"
            },
            {
                "value": 64153,
                "label": "Copper Cliff",
                "label_ar": "كليف كليف",
                "label_fr": "Falaise de cuivre"
            },
            {
                "value": 63933,
                "label": "Salmon Arm",
                "label_ar": "ذراع السلمون",
                "label_fr": "Bras de saumon"
            },
            {
                "value": 65704,
                "label": "Kuujjuarapik",
                "label_ar": "كوجوارابيك",
                "label_fr": "Kuujjuarapik"
            },
            {
                "value": 64982,
                "label": "Wainfleet",
                "label_ar": "وينفليت",
                "label_fr": "Wainfleet"
            },
            {
                "value": 64221,
                "label": "Sainte-Marie",
                "label_ar": "سانت ماري",
                "label_fr": "Sainte-Marie"
            },
            {
                "value": 64188,
                "label": "Gore Bay",
                "label_ar": "جور باي",
                "label_fr": "Gore Bay"
            },
            {
                "value": 64296,
                "label": "Halfmoon Bay",
                "label_ar": "نصف القمر، جزء رئيسي من مبنى",
                "label_fr": "Halfmoon Bay"
            },
            {
                "value": 64387,
                "label": "Baie-Saint-Paul",
                "label_ar": "باي سانت بول",
                "label_fr": "Baie-Saint-Paul"
            },
            {
                "value": 65772,
                "label": "Smooth Rock Falls",
                "label_ar": "سموث روك فولز",
                "label_fr": "Smooth Rock Falls"
            },
            {
                "value": 64595,
                "label": "Port Royal",
                "label_ar": "ميناء ملكي",
                "label_fr": "Port Royal"
            },
            {
                "value": 64909,
                "label": "Langdon",
                "label_ar": "لانغدون",
                "label_fr": "Langdon"
            },
            {
                "value": 64083,
                "label": "Windsor",
                "label_ar": "وندسور",
                "label_fr": "Windsor"
            },
            {
                "value": 64555,
                "label": "Merlin",
                "label_ar": "ميرلين",
                "label_fr": "Merlin"
            },
            {
                "value": 63860,
                "label": "Kentville",
                "label_ar": "كنتفيل",
                "label_fr": "Kentville"
            },
            {
                "value": 64858,
                "label": "Watrous",
                "label_ar": "وطي",
                "label_fr": "Watrous"
            },
            {
                "value": 65308,
                "label": "Sturgis",
                "label_ar": "ستورجيس",
                "label_fr": "Sturgis"
            },
            {
                "value": 65296,
                "label": "Kitscoty",
                "label_ar": "كيتسكوتي",
                "label_fr": "Kitscoty"
            },
            {
                "value": 64634,
                "label": "Thorold",
                "label_ar": "ثورولد",
                "label_fr": "Thorold"
            },
            {
                "value": 65719,
                "label": "Penobsquis",
                "label_ar": "بينوبسكيس",
                "label_fr": "Penobsquis"
            },
            {
                "value": 63906,
                "label": "Winnipeg",
                "label_ar": "وينيبيغ",
                "label_fr": "Winnipeg"
            },
            {
                "value": 64251,
                "label": "Beaupre",
                "label_ar": "بيوبر",
                "label_fr": "Beaupré"
            },
            {
                "value": 64440,
                "label": "Port Saunders",
                "label_ar": "بورت سوندرز",
                "label_fr": "Port Saunders"
            },
            {
                "value": 65816,
                "label": "Grandora",
                "label_ar": "غراندورا",
                "label_fr": "Grandora"
            },
            {
                "value": 64343,
                "label": "Clementsvale",
                "label_ar": "كليمينتسفيل",
                "label_fr": "Clementsvale"
            },
            {
                "value": 64590,
                "label": "Revelstoke",
                "label_ar": "ريفيلستوك",
                "label_fr": "Revelstoke"
            },
            {
                "value": 64500,
                "label": "Whitecourt",
                "label_ar": "وايتكورت",
                "label_fr": "Whitecourt"
            },
            {
                "value": 64063,
                "label": "Matane",
                "label_ar": "ماتاني",
                "label_fr": "Matane"
            },
            {
                "value": 63780,
                "label": "Sainte-Marthe",
                "label_ar": "سانت مارتي",
                "label_fr": "Sainte-Marthe"
            },
            {
                "value": 64733,
                "label": "Houston",
                "label_ar": "هيوستن",
                "label_fr": "Houston"
            },
            {
                "value": 65769,
                "label": "Mountain View",
                "label_ar": "ماونتن فيو",
                "label_fr": "vue sur la montagne"
            },
            {
                "value": 64581,
                "label": "Coleville",
                "label_ar": "كوليفيل",
                "label_fr": "Coleville"
            },
            {
                "value": 64787,
                "label": "Alvinston",
                "label_ar": "ألفينستون",
                "label_fr": "Alvinston"
            },
            {
                "value": 65663,
                "label": "Lantzville",
                "label_ar": "لانتزفيل",
                "label_fr": "Lantzville"
            },
            {
                "value": 65390,
                "label": "Richibucto",
                "label_ar": "ريتشيبوكتو",
                "label_fr": "Richibucto"
            },
            {
                "value": 64499,
                "label": "Redcliff",
                "label_ar": "ريدكليف",
                "label_fr": "Redcliff"
            },
            {
                "value": 64627,
                "label": "Dryden",
                "label_ar": "درايدن",
                "label_fr": "Dryden"
            },
            {
                "value": 64279,
                "label": "Lac-Megantic",
                "label_ar": "لاك ميجانتيك",
                "label_fr": "Lac-Mégantic"
            },
            {
                "value": 64055,
                "label": "Yamachiche",
                "label_ar": "ياماتشي",
                "label_fr": "Yamachiche"
            },
            {
                "value": 65475,
                "label": "Petite-Riviere-Saint-Francois",
                "label_ar": "بيتيت ريفيير سان فرانسوا",
                "label_fr": "Petite-Rivière-Saint-François"
            },
            {
                "value": 65622,
                "label": "Scott",
                "label_ar": "سكوت",
                "label_fr": "Scott"
            },
            {
                "value": 63826,
                "label": "Rouyn-Noranda",
                "label_ar": "روين نوراندا",
                "label_fr": "Rouyn-Noranda"
            },
            {
                "value": 65133,
                "label": "Melocheville",
                "label_ar": "ميلوشفيل",
                "label_fr": "Melocheville"
            },
            {
                "value": 65458,
                "label": "Plamondon",
                "label_ar": "بلاموندون",
                "label_fr": "Plamondon"
            },
            {
                "value": 65260,
                "label": "Cheticamp",
                "label_ar": "Cheticamp",
                "label_fr": "Chéticamp"
            },
            {
                "value": 63910,
                "label": "Kimberley",
                "label_ar": "كيمبرلي",
                "label_fr": "Kimberley"
            },
            {
                "value": 65191,
                "label": "Westree",
                "label_ar": "Westree",
                "label_fr": "Westree"
            },
            {
                "value": 63764,
                "label": "Vineland Station",
                "label_ar": "محطة فينلاند",
                "label_fr": "Gare de Vineland"
            },
            {
                "value": 65641,
                "label": "Plum Coulee",
                "label_ar": "بلوم كولي",
                "label_fr": "Coulée de prune"
            },
            {
                "value": 65220,
                "label": "Saint-Polycarpe",
                "label_ar": "سان بولي كارب",
                "label_fr": "Saint-Polycarpe"
            },
            {
                "value": 65065,
                "label": "Luseland",
                "label_ar": "لوسلاند",
                "label_fr": "Luseland"
            },
            {
                "value": 64282,
                "label": "Ormstown",
                "label_ar": "أورمستاون",
                "label_fr": "Ormstown"
            },
            {
                "value": 64044,
                "label": "Bourget",
                "label_ar": "بورجيه",
                "label_fr": "Bourget"
            },
            {
                "value": 65683,
                "label": "Sillery",
                "label_ar": "سخافة",
                "label_fr": "Sillery"
            },
            {
                "value": 64732,
                "label": "Hay Lakes",
                "label_ar": "هاي ليكس",
                "label_fr": "Hay Lakes"
            },
            {
                "value": 64020,
                "label": "Schreiber",
                "label_ar": "شرايبر",
                "label_fr": "Schreiber"
            },
            {
                "value": 65744,
                "label": "Klemtu",
                "label_ar": "كليمتو",
                "label_fr": "Klemtu"
            },
            {
                "value": 64242,
                "label": "Saint Romuald",
                "label_ar": "القديس روموالد",
                "label_fr": "Saint Romuald"
            },
            {
                "value": 64632,
                "label": "Haliburton",
                "label_ar": "هاليبورتون",
                "label_fr": "Haliburton"
            },
            {
                "value": 65322,
                "label": "Barriere",
                "label_ar": "باريير",
                "label_fr": "Barrière"
            },
            {
                "value": 63855,
                "label": "Pickering",
                "label_ar": "بيكرينغ",
                "label_fr": "Pickering"
            },
            {
                "value": 65550,
                "label": "Claremont",
                "label_ar": "كليرمونت",
                "label_fr": "Claremont"
            },
            {
                "value": 64743,
                "label": "Beauceville",
                "label_ar": "بوسفيل",
                "label_fr": "Beauceville"
            },
            {
                "value": 64869,
                "label": "Lancaster Park",
                "label_ar": "لانكستر بارك",
                "label_fr": "Parc Lancaster"
            },
            {
                "value": 64905,
                "label": "Niverville",
                "label_ar": "نيفرفيل",
                "label_fr": "Niverville"
            },
            {
                "value": 64578,
                "label": "Wilkie",
                "label_ar": "ويلكي",
                "label_fr": "Wilkie"
            },
            {
                "value": 63896,
                "label": "Cowley",
                "label_ar": "كاولي",
                "label_fr": "Cowley"
            },
            {
                "value": 65183,
                "label": "Boswell",
                "label_ar": "بوسويل",
                "label_fr": "Boswell"
            },
            {
                "value": 64580,
                "label": "Macklin",
                "label_ar": "ماكلين",
                "label_fr": "Macklin"
            },
            {
                "value": 64371,
                "label": "Western Bay",
                "label_ar": "ويسترن باي",
                "label_fr": "Baie occidentale"
            },
            {
                "value": 64286,
                "label": "St. Catharines",
                "label_ar": "سانت كاثرينز",
                "label_fr": "St. Catharines"
            },
            {
                "value": 65101,
                "label": "Tofield",
                "label_ar": "إلى الحقل",
                "label_fr": "Tofield"
            },
            {
                "value": 64247,
                "label": "La Prairie",
                "label_ar": "لا برايري",
                "label_fr": "La Prairie"
            },
            {
                "value": 65525,
                "label": "Tlell",
                "label_ar": "تلل",
                "label_fr": "Tlell"
            },
            {
                "value": 64219,
                "label": "Compton",
                "label_ar": "كومبتون",
                "label_fr": "Compton"
            },
            {
                "value": 64416,
                "label": "Noyan",
                "label_ar": "نويان",
                "label_fr": "Noyan"
            },
            {
                "value": 63888,
                "label": "Rocky Mountain House",
                "label_ar": "روكي ماونتن هاوس",
                "label_fr": "Rocky Mountain House"
            },
            {
                "value": 63771,
                "label": "Cowansville",
                "label_ar": "كوانسفيل",
                "label_fr": "Cowansville"
            },
            {
                "value": 64408,
                "label": "Smithers",
                "label_ar": "سميثرز",
                "label_fr": "Smithers"
            },
            {
                "value": 64836,
                "label": "Allan",
                "label_ar": "ألان",
                "label_fr": "Allan"
            },
            {
                "value": 65011,
                "label": "Montebello",
                "label_ar": "مونتيبيلو",
                "label_fr": "Montebello"
            },
            {
                "value": 64855,
                "label": "Saint-Stanislas-de-Kostka",
                "label_ar": "سان ستانيسلاس دي كوستكا",
                "label_fr": "Saint-Stanislas-de-Kostka"
            },
            {
                "value": 65040,
                "label": "Saint-Charles-de-Bellechasse",
                "label_ar": "سان شارل دي بيليكاس",
                "label_fr": "Saint-Charles-de-Bellechasse"
            },
            {
                "value": 64770,
                "label": "Inverness",
                "label_ar": "إينفيرنيس",
                "label_fr": "Inverness"
            },
            {
                "value": 64032,
                "label": "Oakville",
                "label_ar": "أوكفيل",
                "label_fr": "Oakville"
            },
            {
                "value": 64596,
                "label": "Slave Lake",
                "label_ar": "بحيرة الرقيق",
                "label_fr": "Lac des Esclaves"
            },
            {
                "value": 64436,
                "label": "Nackawic",
                "label_ar": "نكاويك",
                "label_fr": "Nackawic"
            },
            {
                "value": 65442,
                "label": "East Coulee",
                "label_ar": "الشرق كولي",
                "label_fr": "Coulée Est"
            },
            {
                "value": 64923,
                "label": "Saint-Paulin",
                "label_ar": "سانت بولين",
                "label_fr": "Saint-Paulin"
            },
            {
                "value": 65034,
                "label": "100 Mile House",
                "label_ar": "100 مايل هاوس",
                "label_fr": "Maison de 100 milles"
            },
            {
                "value": 65672,
                "label": "Gladstone",
                "label_ar": "جلادستون",
                "label_fr": "Gladstone"
            },
            {
                "value": 64790,
                "label": "Hemmingford",
                "label_ar": "همنغفورد",
                "label_fr": "Hemmingford"
            },
            {
                "value": 64579,
                "label": "Kerrobert",
                "label_ar": "كيربرت",
                "label_fr": "Kerrobert"
            },
            {
                "value": 64383,
                "label": "Saint-Damase",
                "label_ar": "سانت داماس",
                "label_fr": "Saint-Damase"
            },
            {
                "value": 63927,
                "label": "Courtenay",
                "label_ar": "كورتيناي",
                "label_fr": "Courtenay"
            },
            {
                "value": 64480,
                "label": "Hope",
                "label_ar": "أمل",
                "label_fr": "Espérer"
            },
            {
                "value": 64459,
                "label": "Sable River",
                "label_ar": "نهر السمور",
                "label_fr": "Rivière Sable"
            },
            {
                "value": 64495,
                "label": "Sylvan Lake",
                "label_ar": "بحيرة سيلفان",
                "label_fr": "Sylvan Lake"
            },
            {
                "value": 65233,
                "label": "Dawson Settlement",
                "label_ar": "تسوية داوسون",
                "label_fr": "Règlement Dawson"
            },
            {
                "value": 65091,
                "label": "Grande-Anse",
                "label_ar": "غراندي آنس",
                "label_fr": "Grande-Anse"
            },
            {
                "value": 65400,
                "label": "Havre Boucher",
                "label_ar": "هافر باوتشر",
                "label_fr": "Havre Boucher"
            },
            {
                "value": 65523,
                "label": "Gander Bay",
                "label_ar": "غاندر باي",
                "label_fr": "Gander Bay"
            },
            {
                "value": 65219,
                "label": "Saint-Flavien",
                "label_ar": "سانت فلافيان",
                "label_fr": "Saint-Flavien"
            },
            {
                "value": 64617,
                "label": "Fermont",
                "label_ar": "فيرمونت",
                "label_fr": "Fermont"
            },
            {
                "value": 64993,
                "label": "Labelle",
                "label_ar": "لابيل",
                "label_fr": "Labelle"
            },
            {
                "value": 64621,
                "label": "Lashburn",
                "label_ar": "لاشبورن",
                "label_fr": "Lashburn"
            },
            {
                "value": 65784,
                "label": "Baie-Des-Sables",
                "label_ar": "باي دي سابلز",
                "label_fr": "Baie-Des-Sables"
            },
            {
                "value": 64372,
                "label": "Old Perlican",
                "label_ar": "بيرليكان القديمة",
                "label_fr": "Vieux Perlican"
            },
            {
                "value": 64080,
                "label": "Carmel",
                "label_ar": "الكرمل",
                "label_fr": "Carmel"
            },
            {
                "value": 65459,
                "label": "Wittenburg",
                "label_ar": "فيتنبرغ",
                "label_fr": "Wittenburg"
            },
            {
                "value": 65370,
                "label": "Clarenville-Shoal Harbour",
                "label_ar": "ميناء كلارينفيل شول",
                "label_fr": "Port de Clarenville-Shoal"
            },
            {
                "value": 64274,
                "label": "Farnham",
                "label_ar": "فارنهام",
                "label_fr": "Farnham"
            },
            {
                "value": 63847,
                "label": "Champlain",
                "label_ar": "شامبلين",
                "label_fr": "Champlain"
            },
            {
                "value": 64194,
                "label": "Wetaskiwin",
                "label_ar": "ويتاسكيوين",
                "label_fr": "Wetaskiwin"
            },
            {
                "value": 64118,
                "label": "Digby",
                "label_ar": "ديجبي",
                "label_fr": "Digby"
            },
            {
                "value": 65283,
                "label": "Jacksons",
                "label_ar": "Jacksons",
                "label_fr": "Jacksons"
            },
            {
                "value": 65633,
                "label": "Otterburne",
                "label_ar": "أوتربورن",
                "label_fr": "Otterburne"
            },
            {
                "value": 64409,
                "label": "Prince Rupert",
                "label_ar": "الأمير روبرت",
                "label_fr": "Prince Rupert"
            },
            {
                "value": 64953,
                "label": "Lanark",
                "label_ar": "لانارك",
                "label_fr": "Lanark"
            },
            {
                "value": 64001,
                "label": "Sidney",
                "label_ar": "سيدني",
                "label_fr": "Sidney"
            },
            {
                "value": 65305,
                "label": "Borden",
                "label_ar": "بوردن",
                "label_fr": "Borden"
            },
            {
                "value": 65239,
                "label": "Canim Lake",
                "label_ar": "بحيرة كانيم",
                "label_fr": "Lac Canim"
            },
            {
                "value": 64557,
                "label": "Dauphin",
                "label_ar": "دوفين",
                "label_fr": "Dauphin"
            },
            {
                "value": 65164,
                "label": "Armagh",
                "label_ar": "ارماغ",
                "label_fr": "Armagh"
            },
            {
                "value": 65107,
                "label": "Woodridge",
                "label_ar": "وودريدج",
                "label_fr": "Woodridge"
            },
            {
                "value": 65808,
                "label": "Windham Centre",
                "label_ar": "مركز ويندهام",
                "label_fr": "Centre de Windham"
            },
            {
                "value": 64929,
                "label": "Manseau",
                "label_ar": "مانسو",
                "label_fr": "Manseau"
            },
            {
                "value": 63796,
                "label": "Varennes",
                "label_ar": "فارينيس",
                "label_fr": "Varennes"
            },
            {
                "value": 63756,
                "label": "Burgessville",
                "label_ar": "برجسفيل",
                "label_fr": "Burgessville"
            },
            {
                "value": 64839,
                "label": "Glaslyn",
                "label_ar": "جلاسلين",
                "label_fr": "Glaslyn"
            },
            {
                "value": 63985,
                "label": "Mahone Bay",
                "label_ar": "خليج ماهون",
                "label_fr": "Mahone Bay"
            },
            {
                "value": 64706,
                "label": "Balcarres",
                "label_ar": "بالكاريس",
                "label_fr": "Balcarres"
            },
            {
                "value": 65761,
                "label": "Sainte-Clotilde-de-Horton",
                "label_ar": "سانت كلوتيلد دي هورتون",
                "label_fr": "Sainte-Clotilde-de-Horton"
            },
            {
                "value": 64868,
                "label": "Cochrane",
                "label_ar": "كوكرين",
                "label_fr": "Cochrane"
            },
            {
                "value": 65353,
                "label": "The Pas",
                "label_ar": "باس",
                "label_fr": "Le Pas"
            },
            {
                "value": 64116,
                "label": "Greenspond",
                "label_ar": "جرينسبوند",
                "label_fr": "Greenspond"
            },
            {
                "value": 64149,
                "label": "Lively",
                "label_ar": "حية",
                "label_fr": "Animé"
            },
            {
                "value": 63783,
                "label": "Sainte-Catherine",
                "label_ar": "سانت كاترين",
                "label_fr": "Sainte-Catherine"
            },
            {
                "value": 65786,
                "label": "Tahsis",
                "label_ar": "تحسس",
                "label_fr": "Tahsis"
            },
            {
                "value": 64479,
                "label": "Castlegar",
                "label_ar": "Castlegar",
                "label_fr": "Castlegar"
            },
            {
                "value": 65074,
                "label": "Domain",
                "label_ar": "نطاق",
                "label_fr": "Domaine"
            },
            {
                "value": 63905,
                "label": "Vermilion",
                "label_ar": "قرمزي",
                "label_fr": "Vermillon"
            },
            {
                "value": 65017,
                "label": "Coaldale",
                "label_ar": "كوالديل",
                "label_fr": "Coaldale"
            },
            {
                "value": 64586,
                "label": "Dawson Creek",
                "label_ar": "داوسون كريك",
                "label_fr": "Dawson Creek"
            },
            {
                "value": 63781,
                "label": "Nominingue",
                "label_ar": "الترشيح",
                "label_fr": "Nominingue"
            },
            {
                "value": 64798,
                "label": "Deep River",
                "label_ar": "نهر عميق",
                "label_fr": "Rivière profonde"
            },
            {
                "value": 64959,
                "label": "Nakina",
                "label_ar": "ناكينا",
                "label_fr": "Nakina"
            },
            {
                "value": 64062,
                "label": "Yamaska",
                "label_ar": "ياماسكا",
                "label_fr": "Yamaska"
            },
            {
                "value": 64948,
                "label": "Kangirsuk",
                "label_ar": "كانجيرسوك",
                "label_fr": "Kangirsuk"
            },
            {
                "value": 64143,
                "label": "Uxbridge",
                "label_ar": "أوكسبريدج",
                "label_fr": "Uxbridge"
            },
            {
                "value": 64782,
                "label": "Vulcan",
                "label_ar": "فولكان",
                "label_fr": "Vulcain"
            },
            {
                "value": 64489,
                "label": "Rycroft",
                "label_ar": "ريكروفت",
                "label_fr": "Rycroft"
            },
            {
                "value": 65272,
                "label": "Embrun",
                "label_ar": "إمبرون",
                "label_fr": "Embrun"
            },
            {
                "value": 65724,
                "label": "Saint-Telesphore",
                "label_ar": "سان تيليسفور",
                "label_fr": "Saint-Telesphore"
            },
            {
                "value": 65851,
                "label": "Emerald",
                "label_ar": "زمرد",
                "label_fr": "émeraude"
            },
            {
                "value": 64117,
                "label": "Lumsden",
                "label_ar": "لومسدن",
                "label_fr": "Lumsden"
            },
            {
                "value": 64422,
                "label": "Woodstock",
                "label_ar": "وودستوك",
                "label_fr": "Woodstock"
            },
            {
                "value": 65005,
                "label": "Pointe-Fortune",
                "label_ar": "بوانت فورتشن",
                "label_fr": "Pointe-Fortune"
            },
            {
                "value": 64887,
                "label": "Radville",
                "label_ar": "رادفيل",
                "label_fr": "Radville"
            },
            {
                "value": 65051,
                "label": "Shubenacadie",
                "label_ar": "Shubenacadie",
                "label_fr": "Shubenacadie"
            },
            {
                "value": 65537,
                "label": "Saint-Louis de Kent",
                "label_ar": "سانت لويس دي كينت",
                "label_fr": "Saint-Louis de Kent"
            },
            {
                "value": 65029,
                "label": "Red Rock",
                "label_ar": "ريد روك",
                "label_fr": "Red Rock"
            },
            {
                "value": 65568,
                "label": "Petherton",
                "label_ar": "بيتيرتون",
                "label_fr": "Petherton"
            },
            {
                "value": 63714,
                "label": "Tavistock",
                "label_ar": "تافيستوك",
                "label_fr": "Tavistock"
            },
            {
                "value": 63925,
                "label": "Port Alberni",
                "label_ar": "بورت ألبيرني",
                "label_fr": "Port Alberni"
            },
            {
                "value": 64445,
                "label": "Conception Bay South",
                "label_ar": "كونسيبشن باي جنوب",
                "label_fr": "Conception Bay South"
            },
            {
                "value": 64299,
                "label": "Notre-Dame-du-Nord",
                "label_ar": "نوتردام دو نورد",
                "label_fr": "Notre-Dame-du-Nord"
            },
            {
                "value": 64199,
                "label": "Aylesford",
                "label_ar": "ايلسفورد",
                "label_fr": "Aylesford"
            },
            {
                "value": 64000,
                "label": "Cayuga",
                "label_ar": "كايوجا",
                "label_fr": "Cayuga"
            },
            {
                "value": 65696,
                "label": "Sunderland",
                "label_ar": "سندرلاند",
                "label_fr": "Sunderland"
            },
            {
                "value": 65798,
                "label": "Dorset",
                "label_ar": "دورست",
                "label_fr": "Dorset"
            },
            {
                "value": 65822,
                "label": "Pierson",
                "label_ar": "بيرسون",
                "label_fr": "Pierson"
            },
            {
                "value": 64321,
                "label": "Hebron",
                "label_ar": "الخليل",
                "label_fr": "Hébron"
            },
            {
                "value": 63957,
                "label": "Melville",
                "label_ar": "ملفيل",
                "label_fr": "Melville"
            },
            {
                "value": 64683,
                "label": "Kars",
                "label_ar": "كارس",
                "label_fr": "Kars"
            },
            {
                "value": 64354,
                "label": "Twillingate",
                "label_ar": "Twillingate",
                "label_fr": "Twillingate"
            },
            {
                "value": 64784,
                "label": "Vegreville",
                "label_ar": "فيجريفيل",
                "label_fr": "Vegreville"
            },
            {
                "value": 64245,
                "label": "Sainte-Julie",
                "label_ar": "سانت جولي",
                "label_fr": "Sainte-Julie"
            },
            {
                "value": 65182,
                "label": "Windermere",
                "label_ar": "ويندرمير",
                "label_fr": "Windermere"
            },
            {
                "value": 63900,
                "label": "High River",
                "label_ar": "هاي ريفر",
                "label_fr": "High River"
            },
            {
                "value": 64684,
                "label": "Ardrossan",
                "label_ar": "أردروسان",
                "label_fr": "Ardrossan"
            },
            {
                "value": 63797,
                "label": "Mirabel",
                "label_ar": "ميرابل",
                "label_fr": "Mirabel"
            },
            {
                "value": 65497,
                "label": "Cayley",
                "label_ar": "كايلي",
                "label_fr": "Cayley"
            },
            {
                "value": 63775,
                "label": "Blainville",
                "label_ar": "بلانفيل",
                "label_fr": "Blainville"
            },
            {
                "value": 63964,
                "label": "Selkirk",
                "label_ar": "سيلكيرك",
                "label_fr": "Selkirk"
            },
            {
                "value": 64810,
                "label": "Chertsey",
                "label_ar": "تشيرتسي",
                "label_fr": "Chertsey"
            },
            {
                "value": 64566,
                "label": "Kelvington",
                "label_ar": "كيلفينغتون",
                "label_fr": "Kelvington"
            },
            {
                "value": 63963,
                "label": "Stonewall",
                "label_ar": "حائط حجارة",
                "label_fr": "Mur de pierre"
            },
            {
                "value": 65712,
                "label": "Deroche",
                "label_ar": "ديروش",
                "label_fr": "Deroche"
            },
            {
                "value": 64768,
                "label": "Emsdale",
                "label_ar": "إمسدال",
                "label_fr": "Emsdale"
            },
            {
                "value": 64902,
                "label": "Sainte-Gertrude",
                "label_ar": "سانت جيرترود",
                "label_fr": "Sainte-Gertrude"
            },
            {
                "value": 65221,
                "label": "Saint-Placide",
                "label_ar": "سان بلاسيد",
                "label_fr": "Saint-Placide"
            },
            {
                "value": 64239,
                "label": "Pointe-Calumet",
                "label_ar": "بوانت كالوميت",
                "label_fr": "Pointe-Calumet"
            },
            {
                "value": 64295,
                "label": "Roberts Creek",
                "label_ar": "روبرتس كريك",
                "label_fr": "Roberts Creek"
            },
            {
                "value": 63727,
                "label": "Longueuil",
                "label_ar": "لونغوي",
                "label_fr": "Longueuil"
            },
            {
                "value": 63924,
                "label": "Coldstream",
                "label_ar": "تيار بارد",
                "label_fr": "Le courant froid"
            },
            {
                "value": 64598,
                "label": "Morden",
                "label_ar": "حديث",
                "label_fr": "Morden"
            },
            {
                "value": 65267,
                "label": "Thornbury",
                "label_ar": "ثورنبيري",
                "label_fr": "Thornbury"
            },
            {
                "value": 64003,
                "label": "Saanichton",
                "label_ar": "سانيشتون",
                "label_fr": "Saanichton"
            },
            {
                "value": 63786,
                "label": "Westmount",
                "label_ar": "ويستماونت",
                "label_fr": "Westmount"
            },
            {
                "value": 64556,
                "label": "Garibaldi Highlands",
                "label_ar": "مرتفعات غاريبالدي",
                "label_fr": "Hautes terres de Garibaldi"
            },
            {
                "value": 65286,
                "label": "Newcastle Creek",
                "label_ar": "نيوكاسل كريك",
                "label_fr": "Newcastle Creek"
            },
            {
                "value": 65103,
                "label": "Mundare",
                "label_ar": "موندير",
                "label_fr": "Mundare"
            },
            {
                "value": 64611,
                "label": "Gogama",
                "label_ar": "جوجاما",
                "label_fr": "Gogama"
            },
            {
                "value": 64019,
                "label": "Terrace Bay",
                "label_ar": "تيراس باي",
                "label_fr": "Terrace Bay"
            },
            {
                "value": 64614,
                "label": "Ivujivik",
                "label_ar": "إيفوجيفيك",
                "label_fr": "Ivujivik"
            },
            {
                "value": 65231,
                "label": "Newtown",
                "label_ar": "بلدة جديدة",
                "label_fr": "Nouvelle ville"
            },
            {
                "value": 63713,
                "label": "Chatham",
                "label_ar": "تشاتام",
                "label_fr": "Chatham"
            },
            {
                "value": 64674,
                "label": "Almonte",
                "label_ar": "ألمونتي",
                "label_fr": "Almonte"
            },
            {
                "value": 65699,
                "label": "Buckingham",
                "label_ar": "باكنغهام",
                "label_fr": "Buckingham"
            },
            {
                "value": 64142,
                "label": "Bradford",
                "label_ar": "برادفورد",
                "label_fr": "Bradford"
            },
            {
                "value": 64347,
                "label": "Seldom-Little Seldom",
                "label_ar": "نادرا قليلا",
                "label_fr": "Rarement-peu Rarement"
            },
            {
                "value": 64788,
                "label": "Arkona",
                "label_ar": "أركونا",
                "label_fr": "Arkona"
            },
            {
                "value": 65299,
                "label": "South Augusta",
                "label_ar": "جنوب أوغوستا",
                "label_fr": "Sud Augusta"
            },
            {
                "value": 63827,
                "label": "Saint-Prosper",
                "label_ar": "سان بروسبر",
                "label_fr": "Saint-Prosper"
            },
            {
                "value": 64721,
                "label": "Bois-des-Filion",
                "label_ar": "Bois-des-Filion",
                "label_fr": "Bois-des-Filion"
            },
            {
                "value": 65846,
                "label": "Sharbot Lake",
                "label_ar": "بحيرة شاربوت",
                "label_fr": "Lac Sharbot"
            },
            {
                "value": 64956,
                "label": "Saint-Paul-de-la-Croix",
                "label_ar": "سان بول دي لا كروا",
                "label_fr": "Saint-Paul-de-la-Croix"
            },
            {
                "value": 63688,
                "label": "Langley",
                "label_ar": "لانجلي",
                "label_fr": "Langley"
            },
            {
                "value": 64967,
                "label": "Foymount",
                "label_ar": "فويماونت",
                "label_fr": "Foymount"
            },
            {
                "value": 63938,
                "label": "Greenwood",
                "label_ar": "غرينوود",
                "label_fr": "Greenwood"
            },
            {
                "value": 65053,
                "label": "Stanley Bridge, Hope River, Bayview, Cavendish and North Rustico",
                "label_ar": "كوبري ستانلي ونهر هوب وبايفيو وكافنديش وشمال روستيكو",
                "label_fr": "Pont Stanley, rivière Hope, Bayview, Cavendish et North Rustico"
            },
            {
                "value": 64448,
                "label": "Dieppe",
                "label_ar": "دييب",
                "label_fr": "Dieppe"
            },
            {
                "value": 65278,
                "label": "Scotland",
                "label_ar": "اسكتلندا",
                "label_fr": "Écosse"
            },
            {
                "value": 65710,
                "label": "Dog Creek",
                "label_ar": "دوج كريك",
                "label_fr": "Dog Creek"
            },
            {
                "value": 65668,
                "label": "Munster",
                "label_ar": "مونستر",
                "label_fr": "Munster"
            },
            {
                "value": 64786,
                "label": "Baden",
                "label_ar": "بادن",
                "label_fr": "Baden"
            },
            {
                "value": 63947,
                "label": "Melfort",
                "label_ar": "ملفورت",
                "label_fr": "Melfort"
            },
            {
                "value": 65693,
                "label": "Omerville",
                "label_ar": "أومرفيل",
                "label_fr": "Omerville"
            },
            {
                "value": 65841,
                "label": "Souris",
                "label_ar": "سوريس",
                "label_fr": "Souris"
            },
            {
                "value": 64389,
                "label": "Paisley",
                "label_ar": "بيزلي",
                "label_fr": "Paisley"
            },
            {
                "value": 65422,
                "label": "Flat River",
                "label_ar": "نهر فلات",
                "label_fr": "Flat River"
            },
            {
                "value": 63951,
                "label": "Kamsack",
                "label_ar": "كامساك",
                "label_fr": "Kamsack"
            },
            {
                "value": 64490,
                "label": "Irricana",
                "label_ar": "إيريكانا",
                "label_fr": "Irricana"
            },
            {
                "value": 65143,
                "label": "Attawapiskat",
                "label_ar": "الاتوابيسكات",
                "label_fr": "Attawapiskat"
            },
            {
                "value": 64507,
                "label": "Mooretown",
                "label_ar": "موريتاون",
                "label_fr": "Mooretown"
            },
            {
                "value": 65414,
                "label": "La Tabatiere",
                "label_ar": "لا تاباتير",
                "label_fr": "La Tabatière"
            },
            {
                "value": 64050,
                "label": "Batiscan",
                "label_ar": "باتيسكان",
                "label_fr": "Batiscan"
            },
            {
                "value": 65177,
                "label": "Saint-Antoine-de-Tilly",
                "label_ar": "سان أنطوان دي تيلي",
                "label_fr": "Saint-Antoine-de-Tilly"
            },
            {
                "value": 64764,
                "label": "Midhurst",
                "label_ar": "ميدهيرست",
                "label_fr": "Midhurst"
            },
            {
                "value": 65796,
                "label": "North Star",
                "label_ar": "نجم شمال",
                "label_fr": "étoile polaire"
            },
            {
                "value": 65771,
                "label": "Standard",
                "label_ar": "اساسي",
                "label_fr": "la norme"
            },
            {
                "value": 63966,
                "label": "Pine Falls",
                "label_ar": "شلالات الصنوبر",
                "label_fr": "Pine Falls"
            },
            {
                "value": 65330,
                "label": "Hunter River",
                "label_ar": "نهر هنتر",
                "label_fr": "Hunter River"
            },
            {
                "value": 64703,
                "label": "Lac La Biche",
                "label_ar": "لاك لا بيشي",
                "label_fr": "Lac La Biche"
            },
            {
                "value": 64152,
                "label": "Timmins",
                "label_ar": "تيمينز",
                "label_fr": "Timmins"
            },
            {
                "value": 65115,
                "label": "New Dundee",
                "label_ar": "جديد دندي",
                "label_fr": "Nouveau Dundee"
            },
            {
                "value": 63693,
                "label": "Whistler Village",
                "label_ar": "قرية ويسلر",
                "label_fr": "Village de Whistler"
            },
            {
                "value": 65210,
                "label": "Circonscription electorale de Jacques-Cartier",
                "label_ar": "Circonscription Electorale de Jacques-Cartier",
                "label_fr": "Circonscription électorale de Jacques-Cartier"
            },
            {
                "value": 65490,
                "label": "Sainte-Marguerite",
                "label_ar": "سانت مارغريت",
                "label_fr": "Sainte-Marguerite"
            },
            {
                "value": 64757,
                "label": "Saint-Apollinaire",
                "label_ar": "سان أبولينير",
                "label_fr": "Saint-Apollinaire"
            },
            {
                "value": 64753,
                "label": "Ailsa Craig",
                "label_ar": "ايلسا كريج",
                "label_fr": "Ailsa Craig"
            },
            {
                "value": 64913,
                "label": "Foremost",
                "label_ar": "قبل كل شيء",
                "label_fr": "Avant toute chose"
            },
            {
                "value": 64932,
                "label": "River John",
                "label_ar": "نهر جون",
                "label_fr": "Rivière John"
            },
            {
                "value": 64980,
                "label": "Severn Bridge",
                "label_ar": "جسر سيفيرن",
                "label_fr": "Pont de Severn"
            },
            {
                "value": 64872,
                "label": "Stony Plain",
                "label_ar": "عادي حجري",
                "label_fr": "Plaine pierreuse"
            },
            {
                "value": 65430,
                "label": "Sainte-Anne-du-Lac",
                "label_ar": "سانت آن دو لاك",
                "label_fr": "Sainte-Anne-du-Lac"
            },
            {
                "value": 64011,
                "label": "Ladysmith",
                "label_ar": "ليديسميث",
                "label_fr": "Ladysmith"
            },
            {
                "value": 65746,
                "label": "Tisdale",
                "label_ar": "تيسدال",
                "label_fr": "Tisdale"
            },
            {
                "value": 65590,
                "label": "Eriksdale",
                "label_ar": "إريكسدال",
                "label_fr": "Eriksdale"
            },
            {
                "value": 64463,
                "label": "Portugal Cove",
                "label_ar": "البرتغال كوف",
                "label_fr": "Portugal Cove"
            },
            {
                "value": 64563,
                "label": "Foam Lake",
                "label_ar": "بحيرة الرغوة",
                "label_fr": "Lac Foam"
            },
            {
                "value": 64687,
                "label": "Lion's Head",
                "label_ar": "رأس الأسد",
                "label_fr": "Tête de lion"
            },
            {
                "value": 64916,
                "label": "Saint-Ludger",
                "label_ar": "سان لودجر",
                "label_fr": "Saint-Ludger"
            },
            {
                "value": 65409,
                "label": "Port Elgin",
                "label_ar": "بورت إلجين",
                "label_fr": "Port Elgin"
            },
            {
                "value": 64423,
                "label": "Edmundston",
                "label_ar": "إدموندستون",
                "label_fr": "Edmundston"
            },
            {
                "value": 64604,
                "label": "Ucluelet",
                "label_ar": "أوكلويليت",
                "label_fr": "Ucluelet"
            },
            {
                "value": 63950,
                "label": "Esterhazy",
                "label_ar": "استرهازي",
                "label_fr": "Esterhazy"
            },
            {
                "value": 65062,
                "label": "Fort Qu'Appelle",
                "label_ar": "فورت كوابل",
                "label_fr": "Fort Qu'Appelle"
            },
            {
                "value": 65224,
                "label": "Hull",
                "label_ar": "هال",
                "label_fr": "coque"
            },
            {
                "value": 64126,
                "label": "Georgetown",
                "label_ar": "جورج تاون",
                "label_fr": "Georgetown"
            },
            {
                "value": 64624,
                "label": "Outlook",
                "label_ar": "الآفاق",
                "label_fr": "Perspective"
            },
            {
                "value": 64661,
                "label": "Stayner",
                "label_ar": "ستنر",
                "label_fr": "Stayner"
            },
            {
                "value": 65026,
                "label": "Agassiz",
                "label_ar": "اجاسيز",
                "label_fr": "Agassiz"
            },
            {
                "value": 64253,
                "label": "Clarence-Rockland",
                "label_ar": "كلارنس روكلاند",
                "label_fr": "Clarence-Rockland"
            },
            {
                "value": 63945,
                "label": "Humboldt",
                "label_ar": "همبولت",
                "label_fr": "Humboldt"
            },
            {
                "value": 64319,
                "label": "La Conception",
                "label_ar": "لا كونسيبشن",
                "label_fr": "La Conception"
            },
            {
                "value": 65273,
                "label": "Sutton",
                "label_ar": "ساتون",
                "label_fr": "Sutton"
            },
            {
                "value": 64861,
                "label": "White Fox",
                "label_ar": "الثعلب الأبيض",
                "label_fr": "Renard blanc"
            },
            {
                "value": 63830,
                "label": "Saint-Victor",
                "label_ar": "سانت فيكتور",
                "label_fr": "Saint-Victor"
            },
            {
                "value": 64363,
                "label": "Tusket",
                "label_ar": "توسكيت",
                "label_fr": "Tusket"
            },
            {
                "value": 64300,
                "label": "Ville-Marie",
                "label_ar": "فيل ماري",
                "label_fr": "Ville-Marie"
            },
            {
                "value": 64774,
                "label": "Lucknow",
                "label_ar": "لكناو",
                "label_fr": "Lucknow"
            },
            {
                "value": 64862,
                "label": "Nokomis",
                "label_ar": "نوكوميس",
                "label_fr": "Nokomis"
            },
            {
                "value": 64127,
                "label": "New Hamburg",
                "label_ar": "نيو هامبورغ",
                "label_fr": "Nouveau Hambourg"
            },
            {
                "value": 64941,
                "label": "Pincourt",
                "label_ar": "بينكور",
                "label_fr": "Pincourt"
            },
            {
                "value": 65222,
                "label": "Princeville",
                "label_ar": "برينسفيل",
                "label_fr": "Princeville"
            },
            {
                "value": 65829,
                "label": "Louisbourg",
                "label_ar": "لويسبورغ",
                "label_fr": "Louisbourg"
            },
            {
                "value": 64608,
                "label": "Heron Bay",
                "label_ar": "هيرون باي",
                "label_fr": "Heron Bay"
            },
            {
                "value": 65593,
                "label": "Bonshaw",
                "label_ar": "بونشو",
                "label_fr": "Bonshaw"
            },
            {
                "value": 64244,
                "label": "Dollard-Des Ormeaux",
                "label_ar": "دولارد ديس أورمو",
                "label_fr": "Dollard-Des Ormeaux"
            },
            {
                "value": 64248,
                "label": "Baie-D'Urfe",
                "label_ar": "باي دورف",
                "label_fr": "Baie-D'Urfe"
            },
            {
                "value": 65032,
                "label": "Marathon",
                "label_ar": "ماراثون",
                "label_fr": "Marathon"
            },
            {
                "value": 65362,
                "label": "Chapel Arm",
                "label_ar": "ذراع المصلى",
                "label_fr": "Bras de la chapelle"
            },
            {
                "value": 65269,
                "label": "Conestogo",
                "label_ar": "كونستوجو",
                "label_fr": "Conestogo"
            },
            {
                "value": 64456,
                "label": "Elmsdale",
                "label_ar": "المسديل",
                "label_fr": "Elmsdale"
            },
            {
                "value": 65225,
                "label": "Hubbards",
                "label_ar": "هوباردز",
                "label_fr": "Hubbards"
            },
            {
                "value": 64208,
                "label": "Bracebridge",
                "label_ar": "براسبريدج",
                "label_fr": "Bracebridge"
            },
            {
                "value": 65544,
                "label": "Grindrod",
                "label_ar": "جريندرود",
                "label_fr": "Grindrod"
            },
            {
                "value": 64989,
                "label": "Sainte-Marcelline-de-Kildare",
                "label_ar": "سانت مارسيلين دي كيلدير",
                "label_fr": "Sainte-Marcelline-de-Kildare"
            },
            {
                "value": 65621,
                "label": "Sherrington",
                "label_ar": "شيرينجتون",
                "label_fr": "Sherrington"
            },
            {
                "value": 64852,
                "label": "Hanley",
                "label_ar": "هانلي",
                "label_fr": "Hanley"
            },
            {
                "value": 64187,
                "label": "Britt",
                "label_ar": "بريت",
                "label_fr": "Britt"
            },
            {
                "value": 64849,
                "label": "Elrose",
                "label_ar": "إلروز",
                "label_fr": "Elrose"
            },
            {
                "value": 65066,
                "label": "Waldeck",
                "label_ar": "فالديك",
                "label_fr": "Waldeck"
            },
            {
                "value": 65463,
                "label": "Carberry",
                "label_ar": "كاربيري",
                "label_fr": "Carberry"
            },
            {
                "value": 64777,
                "label": "Kirkton",
                "label_ar": "كيركتون",
                "label_fr": "Kirkton"
            },
            {
                "value": 64908,
                "label": "Rimbey",
                "label_ar": "Rimbey",
                "label_fr": "Rimbey"
            },
            {
                "value": 63885,
                "label": "Tecumseh",
                "label_ar": "تيكومسيه",
                "label_fr": "Tecumseh"
            },
            {
                "value": 65198,
                "label": "Elliston",
                "label_ar": "إليستون",
                "label_fr": "Elliston"
            },
            {
                "value": 65623,
                "label": "Mar",
                "label_ar": "مارس",
                "label_fr": "Mar"
            },
            {
                "value": 63788,
                "label": "Delson",
                "label_ar": "دلسون",
                "label_fr": "Delson"
            },
            {
                "value": 64695,
                "label": "Saint-Fabien",
                "label_ar": "سان فابيان",
                "label_fr": "Saint-Fabien"
            },
            {
                "value": 64249,
                "label": "Oka",
                "label_ar": "اوكا",
                "label_fr": "Oka"
            },
            {
                "value": 65246,
                "label": "Black Creek",
                "label_ar": "بلاك كريك",
                "label_fr": "Black Creek"
            },
            {
                "value": 63798,
                "label": "Rivière-du-Loup",
                "label_ar": "ريفيير دو لوب",
                "label_fr": "Rivière-du-Loup"
            },
            {
                "value": 63708,
                "label": "Airdrie",
                "label_ar": "ايردري",
                "label_fr": "Airdrie"
            },
            {
                "value": 64313,
                "label": "Valley",
                "label_ar": "الوادي",
                "label_fr": "Vallée"
            },
            {
                "value": 65428,
                "label": "Chapais",
                "label_ar": "شابايس",
                "label_fr": "Chapais"
            },
            {
                "value": 65627,
                "label": "Newdale",
                "label_ar": "نيودال",
                "label_fr": "Newdale"
            },
            {
                "value": 64926,
                "label": "Saint-Barnabe-Sud",
                "label_ar": "سان برنابي سود",
                "label_fr": "Saint-Barnabe-Sud"
            },
            {
                "value": 63919,
                "label": "Summerland",
                "label_ar": "سمرلاند",
                "label_fr": "Summerland"
            },
            {
                "value": 65381,
                "label": "Preeceville",
                "label_ar": "بريسفيل",
                "label_fr": "Preeceville"
            },
            {
                "value": 65159,
                "label": "Glendon",
                "label_ar": "جليندون",
                "label_fr": "Glendon"
            },
            {
                "value": 64597,
                "label": "Valleyview",
                "label_ar": "فاليفيو",
                "label_fr": "Valleyview"
            },
            {
                "value": 64097,
                "label": "Stellarton",
                "label_ar": "ستيلارتون",
                "label_fr": "Stellarton"
            },
            {
                "value": 64518,
                "label": "Petrolia",
                "label_ar": "بتروليا",
                "label_fr": "Petrolia"
            },
            {
                "value": 65384,
                "label": "Wymark",
                "label_ar": "ويمارك",
                "label_fr": "Wymark"
            },
            {
                "value": 65079,
                "label": "Scotstown",
                "label_ar": "سكوتستاون",
                "label_fr": "Scotstown"
            },
            {
                "value": 65274,
                "label": "Wellesley",
                "label_ar": "ويليسلي",
                "label_fr": "Wellesley"
            },
            {
                "value": 63911,
                "label": "Cranbrook",
                "label_ar": "كرانبروك",
                "label_fr": "Cranbrook"
            },
            {
                "value": 64053,
                "label": "Nicolet",
                "label_ar": "نيكوليت",
                "label_fr": "Nicolet"
            },
            {
                "value": 65387,
                "label": "Garrick",
                "label_ar": "جاريك",
                "label_fr": "Garrick"
            },
            {
                "value": 64122,
                "label": "Port Hawkesbury",
                "label_ar": "بورت هوكسبيري",
                "label_fr": "Port Hawkesbury"
            },
            {
                "value": 65611,
                "label": "Aklavik",
                "label_ar": "اكلافيك",
                "label_fr": "Aklavik"
            },
            {
                "value": 64718,
                "label": "Les Cedres",
                "label_ar": "ليه سيدرس",
                "label_fr": "Les Cedres"
            },
            {
                "value": 64522,
                "label": "Keswick Beach",
                "label_ar": "شاطئ كيسويك",
                "label_fr": "Plage de Keswick"
            },
            {
                "value": 63918,
                "label": "Penticton",
                "label_ar": "بينتيكتون",
                "label_fr": "Penticton"
            },
            {
                "value": 64131,
                "label": "Church Point",
                "label_ar": "تشيرش بوينت",
                "label_fr": "Point de l'église"
            },
            {
                "value": 65765,
                "label": "Delaware",
                "label_ar": "ديلاوير",
                "label_fr": "Delaware"
            },
            {
                "value": 65255,
                "label": "Port au Choix",
                "label_ar": "بورت أو تشويكس",
                "label_fr": "Port au Choix"
            },
            {
                "value": 64964,
                "label": "Saint-Denis",
                "label_ar": "سان دوني",
                "label_fr": "Saint-Denis"
            },
            {
                "value": 64931,
                "label": "Cleveland",
                "label_ar": "كليفلاند",
                "label_fr": "Cleveland"
            },
            {
                "value": 64961,
                "label": "Dorchester",
                "label_ar": "دورتشستر",
                "label_fr": "Dorchester"
            },
            {
                "value": 64039,
                "label": "Roxboro",
                "label_ar": "روكسبورو",
                "label_fr": "Roxboro"
            },
            {
                "value": 64898,
                "label": "Chicoutimi",
                "label_ar": "شيكوتيمي",
                "label_fr": "Chicoutimi"
            },
            {
                "value": 64061,
                "label": "Val-David",
                "label_ar": "فال ديفيد",
                "label_fr": "Val-David"
            },
            {
                "value": 65483,
                "label": "Mont-Saint-Gregoire",
                "label_ar": "مونت سانت جريجوار",
                "label_fr": "Mont-Saint-Grégoire"
            },
            {
                "value": 64192,
                "label": "Hanna",
                "label_ar": "حنا",
                "label_fr": "Hanna"
            },
            {
                "value": 64223,
                "label": "Trenton",
                "label_ar": "ترينتون",
                "label_fr": "Trenton"
            },
            {
                "value": 65058,
                "label": "Colinet",
                "label_ar": "كولينيت",
                "label_fr": "Colinet"
            },
            {
                "value": 65064,
                "label": "Bredenbury",
                "label_ar": "بريدنبري",
                "label_fr": "Bredenbury"
            },
            {
                "value": 64943,
                "label": "Saint-Bruno-de-Montarville",
                "label_ar": "سان برونو دي مونتارفيل",
                "label_fr": "Saint-Bruno-de-Montarville"
            },
            {
                "value": 64915,
                "label": "Atwood",
                "label_ar": "أتوود",
                "label_fr": "Atwood"
            },
            {
                "value": 65813,
                "label": "Blackie",
                "label_ar": "بلاكي",
                "label_fr": "Blackie"
            },
            {
                "value": 65396,
                "label": "Riverside-Albert",
                "label_ar": "ريفرسايد ألبرت",
                "label_fr": "Riverside-Albert"
            },
            {
                "value": 65592,
                "label": "Cranberry Portage",
                "label_ar": "التوت البري بورتاج",
                "label_fr": "Portage aux canneberges"
            },
            {
                "value": 64432,
                "label": "Rogersville",
                "label_ar": "روجرسفيل",
                "label_fr": "Rogersville"
            },
            {
                "value": 65485,
                "label": "Saint-Andre-Avellin",
                "label_ar": "سانت أندريه أفلين",
                "label_fr": "Saint-André-Avellin"
            },
            {
                "value": 65116,
                "label": "Saint-Francois-du-Lac",
                "label_ar": "سان فرانسوا دو لاك",
                "label_fr": "Saint-François-du-Lac"
            },
            {
                "value": 64963,
                "label": "Nobleton",
                "label_ar": "نوبلتون",
                "label_fr": "Nobleton"
            },
            {
                "value": 64505,
                "label": "Donnelly",
                "label_ar": "دونيلي",
                "label_fr": "Donnelly"
            },
            {
                "value": 65240,
                "label": "Cobden",
                "label_ar": "كوبدن",
                "label_fr": "Cobden"
            },
            {
                "value": 64890,
                "label": "Frobisher",
                "label_ar": "Frobisher",
                "label_fr": "Frobisher"
            },
            {
                "value": 65726,
                "label": "Saint-Jude",
                "label_ar": "سان جود",
                "label_fr": "Saint-Jude"
            },
            {
                "value": 64939,
                "label": "Saint-Roch-de-Richelieu",
                "label_ar": "سان روش دي ريشيليو",
                "label_fr": "Saint-Roch-de-Richelieu"
            },
            {
                "value": 64261,
                "label": "Hudson",
                "label_ar": "هدسون",
                "label_fr": "Hudson"
            },
            {
                "value": 63909,
                "label": "Westlock",
                "label_ar": "ويستلوك",
                "label_fr": "Westlock"
            },
            {
                "value": 63856,
                "label": "Vercheres",
                "label_ar": "فيشيرس",
                "label_fr": "Vercheres"
            },
            {
                "value": 65766,
                "label": "Snow Lake",
                "label_ar": "بحيرة الثلج",
                "label_fr": "Snow Lake"
            },
            {
                "value": 65340,
                "label": "Cardigan",
                "label_ar": "كارديجان",
                "label_fr": "Cardigan"
            },
            {
                "value": 65794,
                "label": "Ashcroft",
                "label_ar": "اشكروفت",
                "label_fr": "Ashcroft"
            },
            {
                "value": 64671,
                "label": "La Malbaie",
                "label_ar": "لا مالباي",
                "label_fr": "La Malbaie"
            },
            {
                "value": 64337,
                "label": "Trochu",
                "label_ar": "تروشو",
                "label_fr": "Trochu"
            },
            {
                "value": 63995,
                "label": "Port Dover",
                "label_ar": "ميناء دوفر",
                "label_fr": "Port Douvres"
            },
            {
                "value": 64128,
                "label": "St. John's",
                "label_ar": "سانت جون",
                "label_fr": "St. John's"
            },
            {
                "value": 64237,
                "label": "Circonscription electorale d'Anjou",
                "label_ar": "الدائرة الانتخابية Electorale d'Anjou",
                "label_fr": "Circonscription électorale d'Anjou"
            },
            {
                "value": 64997,
                "label": "Saint-Roch",
                "label_ar": "سان روش",
                "label_fr": "Saint-Roch"
            },
            {
                "value": 65037,
                "label": "Charlemagne",
                "label_ar": "شارلمان",
                "label_fr": "Charlemagne"
            },
            {
                "value": 64843,
                "label": "Arborfield",
                "label_ar": "أربورفيلد",
                "label_fr": "Arborfield"
            },
            {
                "value": 65166,
                "label": "Saint-Georges-de-Windsor",
                "label_ar": "سان جورج دو وندسور",
                "label_fr": "Saint-Georges-de-Windsor"
            },
            {
                "value": 64750,
                "label": "Dublin",
                "label_ar": "دبلن",
                "label_fr": "Dublin"
            },
            {
                "value": 65602,
                "label": "Powassan",
                "label_ar": "بواسان",
                "label_fr": "Powassan"
            },
            {
                "value": 64705,
                "label": "Montmartre",
                "label_ar": "مونمارتر",
                "label_fr": "Montmartre"
            },
            {
                "value": 63961,
                "label": "Saint-Hyacinthe",
                "label_ar": "القديس هياسنت",
                "label_fr": "Saint-Hyacinthe"
            },
            {
                "value": 64474,
                "label": "Aldergrove",
                "label_ar": "الدرجروف",
                "label_fr": "Aldergrove"
            },
            {
                "value": 65157,
                "label": "Smoky Lake",
                "label_ar": "سموكي ليك",
                "label_fr": "Lac Smoky"
            },
            {
                "value": 64278,
                "label": "Saint-Zacharie",
                "label_ar": "القديس زكريا",
                "label_fr": "Saint-Zacharie"
            },
            {
                "value": 64535,
                "label": "Belwood",
                "label_ar": "بيلوود",
                "label_fr": "Belwood"
            },
            {
                "value": 63776,
                "label": "Mont-Royal",
                "label_ar": "مونت رويال",
                "label_fr": "Mont-Royal"
            },
            {
                "value": 64854,
                "label": "Wabigoon",
                "label_ar": "وابيجون",
                "label_fr": "Wabigoon"
            },
            {
                "value": 65713,
                "label": "Parson",
                "label_ar": "بارسون",
                "label_fr": "Pasteur"
            },
            {
                "value": 65440,
                "label": "Forestville",
                "label_ar": "فورستفيل",
                "label_fr": "Forestville"
            },
            {
                "value": 65185,
                "label": "Salmo",
                "label_ar": "سالمو",
                "label_fr": "Salmo"
            },
            {
                "value": 65757,
                "label": "Ituna",
                "label_ar": "إيتونا",
                "label_fr": "Ituna"
            },
            {
                "value": 64178,
                "label": "Winchester",
                "label_ar": "وينشستر",
                "label_fr": "Winchester"
            },
            {
                "value": 63944,
                "label": "La Ronge",
                "label_ar": "لا رونج",
                "label_fr": "La Ronge"
            },
            {
                "value": 64462,
                "label": "New Waterford",
                "label_ar": "نيو وترفورد",
                "label_fr": "New Waterford"
            },
            {
                "value": 65300,
                "label": "DeBolt",
                "label_ar": "ديبولت",
                "label_fr": "DeBolt"
            },
            {
                "value": 65779,
                "label": "Aylsham",
                "label_ar": "أيلشام",
                "label_fr": "Aylsham"
            },
            {
                "value": 65167,
                "label": "Saint-Adelphe",
                "label_ar": "سانت أدلفي",
                "label_fr": "Saint-Adelphe"
            },
            {
                "value": 64991,
                "label": "Wasaga Beach",
                "label_ar": "شاطئ واساجا",
                "label_fr": "Wasaga Beach"
            },
            {
                "value": 64405,
                "label": "Port Perry",
                "label_ar": "بورت بيري",
                "label_fr": "Port Perry"
            },
            {
                "value": 65109,
                "label": "Hastings",
                "label_ar": "هاستنجز",
                "label_fr": "Hastings"
            },
            {
                "value": 65054,
                "label": "Arcadia",
                "label_ar": "أركاديا",
                "label_fr": "Arcadie"
            },
            {
                "value": 64232,
                "label": "Chateauguay",
                "label_ar": "شاتوجواي",
                "label_fr": "Châteauguay"
            },
            {
                "value": 65810,
                "label": "Kent Bridge",
                "label_ar": "جسر كينت",
                "label_fr": "Pont de Kent"
            },
            {
                "value": 63935,
                "label": "Sorrento",
                "label_ar": "سورينتو",
                "label_fr": "Sorrento"
            },
            {
                "value": 64657,
                "label": "Saint-Gabriel",
                "label_ar": "سان جبرائيل",
                "label_fr": "Saint-Gabriel"
            },
            {
                "value": 65187,
                "label": "Notre-Dame-de-Lourdes",
                "label_ar": "نوتردام دي لورديس",
                "label_fr": "Notre-Dame-de-Lourdes"
            },
            {
                "value": 63997,
                "label": "Dunnville",
                "label_ar": "دنفيل",
                "label_fr": "Dunnville"
            },
            {
                "value": 64503,
                "label": "Raymond",
                "label_ar": "ريمون",
                "label_fr": "Raymond"
            },
            {
                "value": 64015,
                "label": "Manitouwadge",
                "label_ar": "Manitouwadge",
                "label_fr": "Manitouwadge"
            },
            {
                "value": 64447,
                "label": "Bay Bulls",
                "label_ar": "خليج بولز",
                "label_fr": "Taureaux de la baie"
            },
            {
                "value": 64108,
                "label": "Bay Roberts",
                "label_ar": "خليج روبرتس",
                "label_fr": "Bay Roberts"
            },
            {
                "value": 65635,
                "label": "Yellow Grass",
                "label_ar": "العشب الأصفر",
                "label_fr": "Herbe jaune"
            },
            {
                "value": 64969,
                "label": "West Osgoode",
                "label_ar": "ويست أوسجود",
                "label_fr": "West Osgoode"
            },
            {
                "value": 64785,
                "label": "Saint-Cesaire",
                "label_ar": "سان سيزير",
                "label_fr": "Saint-Césaire"
            },
            {
                "value": 64572,
                "label": "Wakaw",
                "label_ar": "واكاو",
                "label_fr": "Wakaw"
            },
            {
                "value": 64496,
                "label": "Ponoka",
                "label_ar": "بونوكا",
                "label_fr": "Ponoka"
            },
            {
                "value": 63868,
                "label": "Saint-Faustin--Lac-Carre",
                "label_ar": "سان فاوستين - لاك كاري",
                "label_fr": "Saint-Faustin - Lac-Carré"
            },
            {
                "value": 64123,
                "label": "D'Escousse",
                "label_ar": "D'Escousse",
                "label_fr": "D'Escousse"
            },
            {
                "value": 65727,
                "label": "Baldonnel",
                "label_ar": "بالدونيل",
                "label_fr": "Baldonnel"
            },
            {
                "value": 64038,
                "label": "North Hatley",
                "label_ar": "شمال هاتلي",
                "label_fr": "North Hatley"
            },
            {
                "value": 64257,
                "label": "Lavaltrie",
                "label_ar": "لافالتري",
                "label_fr": "Lavaltrie"
            },
            {
                "value": 65647,
                "label": "Winnipegosis",
                "label_ar": "وينيبيغوسيس",
                "label_fr": "Winnipegose"
            },
            {
                "value": 64752,
                "label": "Norwich",
                "label_ar": "نورويتش",
                "label_fr": "Norwich"
            },
            {
                "value": 64434,
                "label": "Lavillette",
                "label_ar": "لافليت",
                "label_fr": "Lavillette"
            },
            {
                "value": 64144,
                "label": "Angus",
                "label_ar": "انجوس",
                "label_fr": "Angus"
            },
            {
                "value": 65508,
                "label": "Miami",
                "label_ar": "ميامي",
                "label_fr": "Miami"
            },
            {
                "value": 64921,
                "label": "La Visitation",
                "label_ar": "لا الزيارة",
                "label_fr": "La Visitation"
            },
            {
                "value": 64950,
                "label": "Saint-Jean-de-Dieu",
                "label_ar": "سان جان دي ديو",
                "label_fr": "Saint-Jean-de-Dieu"
            },
            {
                "value": 64832,
                "label": "Assiniboia",
                "label_ar": "أسينيبويا",
                "label_fr": "Assiniboia"
            },
            {
                "value": 65208,
                "label": "Utterson",
                "label_ar": "أوتيرسون",
                "label_fr": "Utterson"
            },
            {
                "value": 64820,
                "label": "Verner",
                "label_ar": "فيرنر",
                "label_fr": "Verner"
            },
            {
                "value": 65075,
                "label": "Lac-Drolet",
                "label_ar": "لاك دروليت",
                "label_fr": "Lac-Drolet"
            },
            {
                "value": 64060,
                "label": "Chandler",
                "label_ar": "تشاندلر",
                "label_fr": "Chandler"
            },
            {
                "value": 64398,
                "label": "Bayfield",
                "label_ar": "بايفيلد",
                "label_fr": "Bayfield"
            },
            {
                "value": 64455,
                "label": "Timberlea",
                "label_ar": "تيمبرليا",
                "label_fr": "Timberlea"
            },
            {
                "value": 64767,
                "label": "Moosehorn",
                "label_ar": "موسهورن",
                "label_fr": "Moosehorn"
            },
            {
                "value": 64329,
                "label": "Clarenville",
                "label_ar": "كلارينفيل",
                "label_fr": "Clarenville"
            },
            {
                "value": 64696,
                "label": "Sainte-Croix",
                "label_ar": "سانت كروا",
                "label_fr": "Sainte-Croix"
            },
            {
                "value": 63678,
                "label": "Creston",
                "label_ar": "كريستون",
                "label_fr": "Creston"
            },
            {
                "value": 65682,
                "label": "Rosedale",
                "label_ar": "روزديل",
                "label_fr": "Rosedale"
            },
            {
                "value": 63879,
                "label": "Kingsville",
                "label_ar": "كينجسفيل",
                "label_fr": "Kingsville"
            },
            {
                "value": 63817,
                "label": "Saint-Jacques",
                "label_ar": "سان جاك",
                "label_fr": "Saint-Jacques"
            },
            {
                "value": 64312,
                "label": "Stewiacke",
                "label_ar": "ستيواكي",
                "label_fr": "Stewiacke"
            },
            {
                "value": 64771,
                "label": "Roland",
                "label_ar": "رولاند",
                "label_fr": "Roland"
            },
            {
                "value": 65365,
                "label": "St. Anthony",
                "label_ar": "القديس أنتوني",
                "label_fr": "Saint Antoine"
            },
            {
                "value": 65014,
                "label": "Bowden",
                "label_ar": "بودين",
                "label_fr": "Bowden"
            },
            {
                "value": 65734,
                "label": "Plumas",
                "label_ar": "بلوماس",
                "label_fr": "Plumas"
            },
            {
                "value": 63715,
                "label": "Perth",
                "label_ar": "بيرث",
                "label_fr": "Perth"
            },
            {
                "value": 65088,
                "label": "Cumberland",
                "label_ar": "كمبرلاند",
                "label_fr": "Cumberland"
            },
            {
                "value": 65630,
                "label": "Granum",
                "label_ar": "جرانوم",
                "label_fr": "Granum"
            },
            {
                "value": 65201,
                "label": "Chapleau",
                "label_ar": "مصلى",
                "label_fr": "Chapleau"
            },
            {
                "value": 64841,
                "label": "La Loche",
                "label_ar": "لا لوك",
                "label_fr": "La Loche"
            },
            {
                "value": 64243,
                "label": "Saint-Honore-de-Temiscouata",
                "label_ar": "سان أونوريه دي تيميسكواتا",
                "label_fr": "Saint-Honoré-de-Témiscouata"
            },
            {
                "value": 65535,
                "label": "Harbour Main",
                "label_ar": "ميناء مين",
                "label_fr": "Harbour Main"
            },
            {
                "value": 65596,
                "label": "Sardis",
                "label_ar": "ساردس",
                "label_fr": "Sardes"
            },
            {
                "value": 64111,
                "label": "Coleman",
                "label_ar": "كولمان",
                "label_fr": "Coleman"
            },
            {
                "value": 65002,
                "label": "Bala",
                "label_ar": "بالا",
                "label_fr": "Bala"
            },
            {
                "value": 64218,
                "label": "Martinville",
                "label_ar": "مارتينفيل",
                "label_fr": "Martinville"
            },
            {
                "value": 64952,
                "label": "L'Ancienne-Lorette",
                "label_ar": "لانسيين لوريت",
                "label_fr": "L'Ancienne-Lorette"
            },
            {
                "value": 64514,
                "label": "Sainte-Emelie-de-l'Energie",
                "label_ar": "سانت إيميلي دي لانرجي",
                "label_fr": "Sainte-Emelie-de-l'Energie"
            },
            {
                "value": 65136,
                "label": "Virden",
                "label_ar": "فيردن",
                "label_fr": "Virden"
            },
            {
                "value": 65853,
                "label": "Manor",
                "label_ar": "مانور",
                "label_fr": "Manoir"
            },
            {
                "value": 64340,
                "label": "Fortune",
                "label_ar": "ثروة",
                "label_fr": "Fortune"
            },
            {
                "value": 64271,
                "label": "Beaconsfield",
                "label_ar": "بيكونزفيلد",
                "label_fr": "Beaconsfield"
            },
            {
                "value": 64090,
                "label": "Dunsford",
                "label_ar": "دانسفورد",
                "label_fr": "Dunsford"
            },
            {
                "value": 64638,
                "label": "Deseronto",
                "label_ar": "ديسيرونتو",
                "label_fr": "Deseronto"
            },
            {
                "value": 63993,
                "label": "Mount Uniacke",
                "label_ar": "جبل أونياكي",
                "label_fr": "Mont Uniacke"
            },
            {
                "value": 65615,
                "label": "Consort",
                "label_ar": "القرين",
                "label_fr": "Épouse"
            },
            {
                "value": 63712,
                "label": "Grande Prairie",
                "label_ar": "غراند براري",
                "label_fr": "Grande Prairie"
            },
            {
                "value": 63735,
                "label": "Sherbrooke",
                "label_ar": "شيربروك",
                "label_fr": "Sherbrooke"
            },
            {
                "value": 65466,
                "label": "Moose Creek",
                "label_ar": "موس كريك",
                "label_fr": "Moose Creek"
            },
            {
                "value": 64356,
                "label": "St. Alban's",
                "label_ar": "ست ألبانز",
                "label_fr": "Saint Alban"
            },
            {
                "value": 65527,
                "label": "Hornepayne",
                "label_ar": "هورنيباين",
                "label_fr": "Hornepayne"
            },
            {
                "value": 64227,
                "label": "Grenville",
                "label_ar": "جرينفيل",
                "label_fr": "Grenville"
            },
            {
                "value": 64735,
                "label": "Pemberton",
                "label_ar": "بيمبرتون",
                "label_fr": "Pemberton"
            },
            {
                "value": 64640,
                "label": "Yellowknife",
                "label_ar": "يلونايف",
                "label_fr": "Yellowknife"
            },
            {
                "value": 65013,
                "label": "Waubaushene",
                "label_ar": "Waubaushene",
                "label_fr": "Waubaushene"
            },
            {
                "value": 64309,
                "label": "Richmond",
                "label_ar": "ريتشموند",
                "label_fr": "Richmond"
            },
            {
                "value": 64859,
                "label": "Watson",
                "label_ar": "واتسون",
                "label_fr": "Watson"
            },
            {
                "value": 64424,
                "label": "Campbellton",
                "label_ar": "كامبلتون",
                "label_fr": "Campbellton"
            },
            {
                "value": 65287,
                "label": "Oromocto",
                "label_ar": "أوروموكتو",
                "label_fr": "Oromocto"
            },
            {
                "value": 65669,
                "label": "Kilworthy",
                "label_ar": "كيلورثي",
                "label_fr": "Kilworthy"
            },
            {
                "value": 64880,
                "label": "Portage-du-Fort",
                "label_ar": "بورتاج دو فورت",
                "label_fr": "Portage-du-Fort"
            },
            {
                "value": 64803,
                "label": "Stirling",
                "label_ar": "ستيرلينغ",
                "label_fr": "Stirling"
            },
            {
                "value": 64075,
                "label": "Louiseville",
                "label_ar": "لويزفيل",
                "label_fr": "Louiseville"
            },
            {
                "value": 64415,
                "label": "Port Colborne",
                "label_ar": "بورت كولبورن",
                "label_fr": "Port Colborne"
            },
            {
                "value": 64010,
                "label": "Cobble Hill",
                "label_ar": "كوبل هيل",
                "label_fr": "Cobble Hill"
            },
            {
                "value": 63845,
                "label": "Saint-Germain-de-Grantham",
                "label_ar": "سان جيرمان دي جرانثام",
                "label_fr": "Saint-Germain-de-Grantham"
            },
            {
                "value": 64987,
                "label": "Cap-Chat",
                "label_ar": "كاب شات",
                "label_fr": "Cap-Chat"
            },
            {
                "value": 65542,
                "label": "Ganges",
                "label_ar": "الغانج",
                "label_fr": "Ganges"
            },
            {
                "value": 65634,
                "label": "Beauval",
                "label_ar": "بوفال",
                "label_fr": "Beauval"
            },
            {
                "value": 65834,
                "label": "Fort Smith",
                "label_ar": "فورت سميث",
                "label_fr": "Fort Smith"
            },
            {
                "value": 64593,
                "label": "Yarker",
                "label_ar": "ياركر",
                "label_fr": "Yarker"
            },
            {
                "value": 65146,
                "label": "Ham-Nord",
                "label_ar": "هام نورد",
                "label_fr": "Ham-Nord"
            },
            {
                "value": 65530,
                "label": "Mansonville",
                "label_ar": "مانسونفيل",
                "label_fr": "Mansonville"
            },
            {
                "value": 65086,
                "label": "Shamattawa",
                "label_ar": "شماتاوه",
                "label_fr": "Shamattawa"
            },
            {
                "value": 65515,
                "label": "Notre-Dame-du-Bon-Conseil",
                "label_ar": "نوتردام دو بون كونسيل",
                "label_fr": "Notre-Dame-du-Bon-Conseil"
            },
            {
                "value": 64395,
                "label": "Goderich",
                "label_ar": "جوديريتش",
                "label_fr": "Goderich"
            },
            {
                "value": 65080,
                "label": "Racine",
                "label_ar": "راسين",
                "label_fr": "Racine"
            },
            {
                "value": 63825,
                "label": "Val-d'Or",
                "label_ar": "فال دور",
                "label_fr": "Val-d'Or"
            },
            {
                "value": 64664,
                "label": "Sainte-Melanie",
                "label_ar": "سانت ميلاني",
                "label_fr": "Sainte-Mélanie"
            },
            {
                "value": 64308,
                "label": "Laverlochere",
                "label_ar": "لافيرلوشير",
                "label_fr": "Laverlochère"
            },
            {
                "value": 65350,
                "label": "Spaniard's Bay",
                "label_ar": "خليج الاسباني",
                "label_fr": "Baie des Espagnols"
            },
            {
                "value": 65161,
                "label": "Stony Mountain",
                "label_ar": "ستوني ماونتن",
                "label_fr": "Montagne pierreuse"
            },
            {
                "value": 64095,
                "label": "New Glasgow",
                "label_ar": "نيو جلاسكو",
                "label_fr": "New Glasgow"
            },
            {
                "value": 65444,
                "label": "Hazelton",
                "label_ar": "هازلتون",
                "label_fr": "Hazelton"
            },
            {
                "value": 63895,
                "label": "Coleman",
                "label_ar": "كولمان",
                "label_fr": "Coleman"
            },
            {
                "value": 64100,
                "label": "Villa Marie",
                "label_ar": "فيلا ماري",
                "label_fr": "Villa Marie"
            },
            {
                "value": 65855,
                "label": "Naicam",
                "label_ar": "نايكام",
                "label_fr": "Naicam"
            },
            {
                "value": 63965,
                "label": "Teulon",
                "label_ar": "تيولون",
                "label_fr": "Teulon"
            },
            {
                "value": 64977,
                "label": "Port Credit",
                "label_ar": "ائتمان الميناء",
                "label_fr": "Crédit portuaire"
            },
            {
                "value": 65566,
                "label": "Priceville",
                "label_ar": "بريسفيل",
                "label_fr": "Priceville"
            },
            {
                "value": 65538,
                "label": "Pennfield",
                "label_ar": "بينفيلد",
                "label_fr": "Pennfield"
            },
            {
                "value": 63698,
                "label": "Edmonton",
                "label_ar": "ادمونتون",
                "label_fr": "Edmonton"
            },
            {
                "value": 63789,
                "label": "Saint-Sauveur",
                "label_ar": "سان سوفور",
                "label_fr": "Saint-Sauveur"
            },
            {
                "value": 63802,
                "label": "Dorval",
                "label_ar": "دورفال",
                "label_fr": "Dorval"
            },
            {
                "value": 65060,
                "label": "West Chezzetcook",
                "label_ar": "الغرب Chezzetcook",
                "label_fr": "West Chezzetcook"
            },
            {
                "value": 64297,
                "label": "La Sarre",
                "label_ar": "لا سار",
                "label_fr": "La Sarre"
            },
            {
                "value": 65189,
                "label": "New Carlisle",
                "label_ar": "كارلايل الجديدة",
                "label_fr": "New Carlisle"
            },
            {
                "value": 65144,
                "label": "Amqui",
                "label_ar": "Amqui",
                "label_fr": "Amqui"
            },
            {
                "value": 65087,
                "label": "Sheho",
                "label_ar": "شيهو",
                "label_fr": "Sheho"
            },
            {
                "value": 64058,
                "label": "Saint-Pierre",
                "label_ar": "سان بيير",
                "label_fr": "Saint-Pierre"
            },
            {
                "value": 64352,
                "label": "Birchy Bay",
                "label_ar": "بيرشي باي",
                "label_fr": "Birchy Bay"
            },
            {
                "value": 65418,
                "label": "Whites Lake",
                "label_ar": "وايت ليك",
                "label_fr": "Lac des Blancs"
            },
            {
                "value": 65193,
                "label": "Fort Good Hope",
                "label_ar": "فورت هوب جيد",
                "label_fr": "Fort Good Hope"
            },
            {
                "value": 63833,
                "label": "Drummondville",
                "label_ar": "دروموندفيل",
                "label_fr": "Drummondville"
            },
            {
                "value": 65452,
                "label": "Saint-Leon-de-Standon",
                "label_ar": "سان ليون دي ستاندون",
                "label_fr": "Saint-Léon-de-Standon"
            },
            {
                "value": 63829,
                "label": "Saint-Simon-de-Rimouski",
                "label_ar": "سان سيمون دي ريموسكي",
                "label_fr": "Saint-Simon-de-Rimouski"
            },
            {
                "value": 63882,
                "label": "Essex",
                "label_ar": "إسكس",
                "label_fr": "Essex"
            },
            {
                "value": 64288,
                "label": "Renfrew",
                "label_ar": "رينفرو",
                "label_fr": "Renfrew"
            },
            {
                "value": 63670,
                "label": "Montreal",
                "label_ar": "مونتريال",
                "label_fr": "Montréal"
            },
            {
                "value": 65154,
                "label": "Fort Kent",
                "label_ar": "فورت كينت",
                "label_fr": "Fort Kent"
            },
            {
                "value": 64252,
                "label": "Marieville",
                "label_ar": "مارييفيل",
                "label_fr": "Marieville"
            },
            {
                "value": 65703,
                "label": "Ogoki",
                "label_ar": "أوجوكي",
                "label_fr": "Ogoki"
            },
            {
                "value": 64311,
                "label": "Nepean",
                "label_ar": "نيبين",
                "label_fr": "Nepean"
            },
            {
                "value": 65082,
                "label": "Courtright",
                "label_ar": "محكمة",
                "label_fr": "Courtright"
            },
            {
                "value": 64325,
                "label": "Barrington Passage",
                "label_ar": "ممر بارينجتون",
                "label_fr": "Passage de Barrington"
            },
            {
                "value": 65346,
                "label": "Stephenville Crossing",
                "label_ar": "ستيفنفيل كروسينج",
                "label_fr": "Stephenville Crossing"
            },
            {
                "value": 64935,
                "label": "Forest",
                "label_ar": "غابة",
                "label_fr": "Forêt"
            },
            {
                "value": 63717,
                "label": "Laval",
                "label_ar": "لافال",
                "label_fr": "Laval"
            },
            {
                "value": 63980,
                "label": "Halifax",
                "label_ar": "هاليفاكس",
                "label_fr": "Halifax"
            },
            {
                "value": 64888,
                "label": "Herbert",
                "label_ar": "هربرت",
                "label_fr": "Herbert"
            },
            {
                "value": 64008,
                "label": "Chemainus",
                "label_ar": "شيمانوس",
                "label_fr": "Chemainus"
            },
            {
                "value": 64795,
                "label": "Saint-Donat",
                "label_ar": "سان دونات",
                "label_fr": "Saint-Donat"
            },
            {
                "value": 64269,
                "label": "Chute-aux-Outardes",
                "label_ar": "شلال aux-Outardes",
                "label_fr": "Chute-aux-Outardes"
            },
            {
                "value": 65334,
                "label": "Great Village",
                "label_ar": "قرية كبيرة",
                "label_fr": "Grand village"
            },
            {
                "value": 64220,
                "label": "Sawyerville",
                "label_ar": "سويرفيل",
                "label_fr": "Sawyerville"
            },
            {
                "value": 65290,
                "label": "Salisbury",
                "label_ar": "سالزبوري",
                "label_fr": "Salisbury"
            },
            {
                "value": 64850,
                "label": "Elbow",
                "label_ar": "مرفق",
                "label_fr": "Coude"
            },
            {
                "value": 65462,
                "label": "Ardmore",
                "label_ar": "أردمور",
                "label_fr": "Ardmore"
            },
            {
                "value": 65127,
                "label": "Cabano",
                "label_ar": "كابانو",
                "label_fr": "Cabano"
            },
            {
                "value": 63710,
                "label": "Fairview, Alberta",
                "label_ar": "فيرفيو ، ألبرتا",
                "label_fr": "Fairview, Alberta"
            },
            {
                "value": 65649,
                "label": "Campbellville",
                "label_ar": "كامبلفيل",
                "label_fr": "Campbellville"
            },
            {
                "value": 64996,
                "label": "Echo Bay",
                "label_ar": "إيكو باي",
                "label_fr": "Echo Bay"
            },
            {
                "value": 65416,
                "label": "Miscouche",
                "label_ar": "ميسكوش",
                "label_fr": "Miscouche"
            },
            {
                "value": 64200,
                "label": "Berwick",
                "label_ar": "بيرويك",
                "label_fr": "Berwick"
            },
            {
                "value": 64132,
                "label": "Placentia",
                "label_ar": "المشيمة",
                "label_fr": "Placentia"
            },
            {
                "value": 63979,
                "label": "Saskatoon",
                "label_ar": "ساسكاتون",
                "label_fr": "Saskatoon"
            },
            {
                "value": 64542,
                "label": "Gloucester",
                "label_ar": "جلوستر",
                "label_fr": "Gloucester"
            },
            {
                "value": 65388,
                "label": "Bienfait",
                "label_ar": "بينفايت",
                "label_fr": "Bienfait"
            },
            {
                "value": 65778,
                "label": "Hampden",
                "label_ar": "هامبدن",
                "label_fr": "Hampden"
            },
            {
                "value": 65613,
                "label": "Morrin",
                "label_ar": "موريين",
                "label_fr": "Morrin"
            },
            {
                "value": 64824,
                "label": "Watford",
                "label_ar": "واتفورد",
                "label_fr": "Watford"
            },
            {
                "value": 63865,
                "label": "North Russell",
                "label_ar": "نورث راسل",
                "label_fr": "North Russell"
            },
            {
                "value": 64388,
                "label": "Mitchell",
                "label_ar": "ميتشل",
                "label_fr": "Mitchell"
            },
            {
                "value": 65455,
                "label": "Slocan",
                "label_ar": "سلوكان",
                "label_fr": "Slocan"
            },
            {
                "value": 65809,
                "label": "Parkhill",
                "label_ar": "باركهيل",
                "label_fr": "Parkhill"
            },
            {
                "value": 64270,
                "label": "Pointe-Lebel",
                "label_ar": "بوانت ليبل",
                "label_fr": "Pointe-Lebel"
            },
            {
                "value": 64772,
                "label": "Milk River",
                "label_ar": "نهر الحليب",
                "label_fr": "Rivière Milk"
            },
            {
                "value": 64212,
                "label": "Saint-Joseph-de-Beauce",
                "label_ar": "سان جوزيف دي بوس",
                "label_fr": "Saint-Joseph-de-Beauce"
            },
            {
                "value": 65252,
                "label": "Lacolle",
                "label_ar": "لاكول",
                "label_fr": "Lacolle"
            },
            {
                "value": 65820,
                "label": "Birtle",
                "label_ar": "بيرتل",
                "label_fr": "Birtle"
            },
            {
                "value": 64968,
                "label": "Cheapside",
                "label_ar": "الرخيص",
                "label_fr": "Cheapside"
            },
            {
                "value": 64530,
                "label": "L'Ile Perrot",
                "label_ar": "ليل بيرو",
                "label_fr": "L'Ile Perrot"
            },
            {
                "value": 65560,
                "label": "Economy",
                "label_ar": "الاقتصاد",
                "label_fr": "Économie"
            },
            {
                "value": 64835,
                "label": "Viscount",
                "label_ar": "فيكونت",
                "label_fr": "Vicomte"
            },
            {
                "value": 63696,
                "label": "Calgary",
                "label_ar": "كالجاري",
                "label_fr": "Calgary"
            },
            {
                "value": 63969,
                "label": "St. Paul",
                "label_ar": "القديس بول",
                "label_fr": "St.Paul"
            },
            {
                "value": 65319,
                "label": "Duhamel",
                "label_ar": "دوهاميل",
                "label_fr": "Duhamel"
            },
            {
                "value": 64791,
                "label": "Emo",
                "label_ar": "ايمو",
                "label_fr": "Emo"
            },
            {
                "value": 65441,
                "label": "Taschereau",
                "label_ar": "تاشيرو",
                "label_fr": "Taschereau"
            },
            {
                "value": 64734,
                "label": "Fruitvale",
                "label_ar": "فروتفيل",
                "label_fr": "Fruitvale"
            },
            {
                "value": 65844,
                "label": "Domremy",
                "label_ar": "دومريمي",
                "label_fr": "Domrémy"
            },
            {
                "value": 64799,
                "label": "Ingleside",
                "label_ar": "إنجلسايد",
                "label_fr": "Ingleside"
            },
            {
                "value": 65785,
                "label": "Schefferville",
                "label_ar": "شيفرفيل",
                "label_fr": "Schefferville"
            },
            {
                "value": 64693,
                "label": "Mont-Joli",
                "label_ar": "مونت جولي",
                "label_fr": "Mont-Joli"
            },
            {
                "value": 64196,
                "label": "Calmar",
                "label_ar": "الهدوء",
                "label_fr": "Calmar"
            },
            {
                "value": 64533,
                "label": "Milford Station",
                "label_ar": "محطة ميلفورد",
                "label_fr": "Gare de Milford"
            },
            {
                "value": 65694,
                "label": "Bramalea",
                "label_ar": "براماليا",
                "label_fr": "Bramalea"
            },
            {
                "value": 65748,
                "label": "Bromhead",
                "label_ar": "برومهيد",
                "label_fr": "Bromhead"
            },
            {
                "value": 64971,
                "label": "Port Hardy",
                "label_ar": "بورت هاردي",
                "label_fr": "Port Hardy"
            },
            {
                "value": 64025,
                "label": "York",
                "label_ar": "يورك",
                "label_fr": "York"
            },
            {
                "value": 64995,
                "label": "Dundalk",
                "label_ar": "دوندالك",
                "label_fr": "Dundalk"
            },
            {
                "value": 63955,
                "label": "Estevan",
                "label_ar": "استيفان",
                "label_fr": "Estevan"
            },
            {
                "value": 64206,
                "label": "Rigaud",
                "label_ar": "ريغو",
                "label_fr": "Rigaud"
            },
            {
                "value": 64930,
                "label": "Saint-Sylvere",
                "label_ar": "سان سيلفر",
                "label_fr": "Saint-Sylvere"
            },
            {
                "value": 63728,
                "label": "Oshawa",
                "label_ar": "أوشاوا",
                "label_fr": "Oshawa"
            },
            {
                "value": 64794,
                "label": "Saint-Zenon",
                "label_ar": "سانت زينون",
                "label_fr": "Saint-Zenon"
            },
            {
                "value": 64826,
                "label": "Sainte-Brigitte-des-Saults",
                "label_ar": "سانت بريجيت ديس سولت",
                "label_fr": "Sainte-Brigitte-des-Saults"
            },
            {
                "value": 65338,
                "label": "Porters Lake",
                "label_ar": "بحيرة بورترز",
                "label_fr": "Lac Porters"
            },
            {
                "value": 64633,
                "label": "Espanola",
                "label_ar": "اسبانولا",
                "label_fr": "Espanola"
            },
            {
                "value": 63853,
                "label": "Guelph",
                "label_ar": "جيلف",
                "label_fr": "Guelph"
            },
            {
                "value": 64570,
                "label": "North Portal",
                "label_ar": "بوابة الشمال",
                "label_fr": "Portail nord"
            },
            {
                "value": 64360,
                "label": "Corner Brook",
                "label_ar": "ركن بروك",
                "label_fr": "Corner Brook"
            },
            {
                "value": 63899,
                "label": "Drumheller",
                "label_ar": "الطبل",
                "label_fr": "Drumheller"
            },
            {
                "value": 64526,
                "label": "Cardinal",
                "label_ar": "أساسي",
                "label_fr": "Cardinal"
            },
            {
                "value": 65730,
                "label": "Edgewood",
                "label_ar": "إدجوود",
                "label_fr": "Edgewood"
            },
            {
                "value": 64644,
                "label": "Rankin Inlet",
                "label_ar": "رانكين إنليت",
                "label_fr": "Rankin Inlet"
            },
            {
                "value": 65729,
                "label": "Malakwa",
                "label_ar": "ملكوا",
                "label_fr": "Malakwa"
            },
            {
                "value": 65562,
                "label": "Sainte-Foy",
                "label_ar": "سانت فوي",
                "label_fr": "Sainte-Foy"
            },
            {
                "value": 63707,
                "label": "Strathmore",
                "label_ar": "ستراثمور",
                "label_fr": "Strathmore"
            },
            {
                "value": 64531,
                "label": "La Salle",
                "label_ar": "لا سال",
                "label_fr": "la salle"
            },
            {
                "value": 65102,
                "label": "Holden",
                "label_ar": "هولدن",
                "label_fr": "Holden"
            },
            {
                "value": 64267,
                "label": "Saint-Antonin",
                "label_ar": "سانت أنتونين",
                "label_fr": "Saint-Antonin"
            },
            {
                "value": 64412,
                "label": "Stittsville",
                "label_ar": "ستيتسفيل",
                "label_fr": "Stittsville"
            },
            {
                "value": 64281,
                "label": "Sainte-Cecile-de-Whitton",
                "label_ar": "سانت سيسيل دي ويتون",
                "label_fr": "Sainte-Cécile-de-Whitton"
            },
            {
                "value": 65800,
                "label": "Warner",
                "label_ar": "وارنر",
                "label_fr": "Warner"
            },
            {
                "value": 65581,
                "label": "Portage",
                "label_ar": "حمل",
                "label_fr": "Portage"
            },
            {
                "value": 64418,
                "label": "Moncton",
                "label_ar": "مونكتون",
                "label_fr": "Moncton"
            },
            {
                "value": 64778,
                "label": "Daysland",
                "label_ar": "دايزلاند",
                "label_fr": "Daysland"
            },
            {
                "value": 65636,
                "label": "Craik",
                "label_ar": "كريك",
                "label_fr": "Craik"
            },
            {
                "value": 64487,
                "label": "Grimshaw",
                "label_ar": "جريمشو",
                "label_fr": "Grimshaw"
            },
            {
                "value": 64235,
                "label": "Mont-Saint-Hilaire",
                "label_ar": "مونت سانت هيلير",
                "label_fr": "Mont-Saint-Hilaire"
            },
            {
                "value": 63747,
                "label": "Albanel",
                "label_ar": "البانيل",
                "label_fr": "Albanel"
            },
            {
                "value": 64365,
                "label": "Rockville",
                "label_ar": "روكفيل",
                "label_fr": "Rockville"
            },
            {
                "value": 64866,
                "label": "Stevensville",
                "label_ar": "ستيفنسفيل",
                "label_fr": "Stevensville"
            },
            {
                "value": 65227,
                "label": "Mill Village",
                "label_ar": "قرية الطاحونة",
                "label_fr": "Village du moulin"
            },
            {
                "value": 64775,
                "label": "Kippen",
                "label_ar": "كيبن",
                "label_fr": "Kippen"
            },
            {
                "value": 65424,
                "label": "Iona",
                "label_ar": "إيونا",
                "label_fr": "Iona"
            },
            {
                "value": 65344,
                "label": "Pynns",
                "label_ar": "بينز",
                "label_fr": "Pynns"
            },
            {
                "value": 65559,
                "label": "La Corey",
                "label_ar": "لا كوري",
                "label_fr": "La Corey"
            },
            {
                "value": 64452,
                "label": "South Porcupine",
                "label_ar": "جنوب النيص",
                "label_fr": "South Porcupine"
            },
            {
                "value": 63999,
                "label": "Waterford",
                "label_ar": "وترفورد",
                "label_fr": "Waterford"
            },
            {
                "value": 64425,
                "label": "Bathurst",
                "label_ar": "باثورست",
                "label_fr": "Bathurst"
            },
            {
                "value": 64837,
                "label": "Gull Lake",
                "label_ar": "بحيرة نورس",
                "label_fr": "Lac Gull"
            },
            {
                "value": 64473,
                "label": "Oyama",
                "label_ar": "وياما",
                "label_fr": "Oyama"
            },
            {
                "value": 65301,
                "label": "St. Brieux",
                "label_ar": "سانت بريوكس",
                "label_fr": "Saint Brieux"
            },
            {
                "value": 64021,
                "label": "Woodbridge",
                "label_ar": "وودبريدج",
                "label_fr": "Woodbridge"
            },
            {
                "value": 64789,
                "label": "Beaverton",
                "label_ar": "بيفيرتون",
                "label_fr": "Beaverton"
            },
            {
                "value": 64315,
                "label": "Eureka",
                "label_ar": "يوريكا",
                "label_fr": "Eurêka"
            },
            {
                "value": 64504,
                "label": "Bellevue",
                "label_ar": "بلفيو",
                "label_fr": "Bellevue"
            },
            {
                "value": 65114,
                "label": "Sombra",
                "label_ar": "سومبرا",
                "label_fr": "Sombra"
            },
            {
                "value": 65738,
                "label": "Carmacks",
                "label_ar": "كارماكس",
                "label_fr": "Carmacks"
            },
            {
                "value": 64433,
                "label": "Doaktown",
                "label_ar": "Doaktown",
                "label_fr": "Doaktown"
            },
            {
                "value": 65123,
                "label": "Dunham",
                "label_ar": "دنهام",
                "label_fr": "Dunham"
            },
            {
                "value": 65850,
                "label": "Allenford",
                "label_ar": "ألينفورد",
                "label_fr": "Allenford"
            },
            {
                "value": 65129,
                "label": "Riviere-Bleue",
                "label_ar": "ريفيير بلو",
                "label_fr": "Rivière-Bleue"
            },
            {
                "value": 64065,
                "label": "Saint-Leonard-d'Aston",
                "label_ar": "سانت ليونارد دستون",
                "label_fr": "Saint-Léonard-d'Aston"
            },
            {
                "value": 65755,
                "label": "Qu'Appelle",
                "label_ar": "Qu'Appelle",
                "label_fr": "Qu'Appelle"
            },
            {
                "value": 65733,
                "label": "Neuville",
                "label_ar": "نوفيل",
                "label_fr": "Neuville"
            },
            {
                "value": 64177,
                "label": "Napanee",
                "label_ar": "ناباني",
                "label_fr": "Napanee"
            },
            {
                "value": 65120,
                "label": "Fortierville",
                "label_ar": "فورتيرفيل",
                "label_fr": "Fortierville"
            },
            {
                "value": 65077,
                "label": "Dixville",
                "label_ar": "ديكسفيل",
                "label_fr": "Dixville"
            },
            {
                "value": 64603,
                "label": "Port McNeill",
                "label_ar": "بورت ماكنيل",
                "label_fr": "Port McNeill"
            },
            {
                "value": 64594,
                "label": "Saint-Jean-de-Matha",
                "label_ar": "سان جان دي ماثا",
                "label_fr": "Saint-Jean-de-Matha"
            },
            {
                "value": 65576,
                "label": "Warsaw",
                "label_ar": "وارسو",
                "label_fr": "Varsovie"
            },
            {
                "value": 63736,
                "label": "Kanata",
                "label_ar": "كاناتا",
                "label_fr": "Kanata"
            },
            {
                "value": 63757,
                "label": "Brantford",
                "label_ar": "برانتفورد",
                "label_fr": "Brantford"
            },
            {
                "value": 64689,
                "label": "Eden",
                "label_ar": "عدن",
                "label_fr": "Eden"
            },
            {
                "value": 65048,
                "label": "Stephenville",
                "label_ar": "ستيفنفيل",
                "label_fr": "Stephenville"
            },
            {
                "value": 63766,
                "label": "Niagara Falls",
                "label_ar": "شلالات نياجرا",
                "label_fr": "chutes du Niagara"
            },
            {
                "value": 63683,
                "label": "Burnaby",
                "label_ar": "برنابي",
                "label_fr": "Burnaby"
            },
            {
                "value": 64494,
                "label": "Blackfalds",
                "label_ar": "بلاكفالدس",
                "label_fr": "Blackfalds"
            },
            {
                "value": 63923,
                "label": "Nelson",
                "label_ar": "نيلسون",
                "label_fr": "Nelson"
            },
            {
                "value": 64516,
                "label": "Sainte-Cecile-de-Masham",
                "label_ar": "سانت سيسيل دي ماشام",
                "label_fr": "Sainte-Cécile-de-Masham"
            },
            {
                "value": 64468,
                "label": "Pritchard",
                "label_ar": "بريتشارد",
                "label_fr": "Pritchard"
            },
            {
                "value": 65180,
                "label": "Gadsby",
                "label_ar": "جادسبي",
                "label_fr": "Gadsby"
            },
            {
                "value": 63808,
                "label": "Jonquiere",
                "label_ar": "جونكيير",
                "label_fr": "Jonquière"
            },
            {
                "value": 64217,
                "label": "Austin",
                "label_ar": "أوستين",
                "label_fr": "Austin"
            },
            {
                "value": 64660,
                "label": "Saint-Pie",
                "label_ar": "سانت باي",
                "label_fr": "Saint-Pie"
            },
            {
                "value": 64512,
                "label": "Thamesville",
                "label_ar": "تاميسفيل",
                "label_fr": "Thamesville"
            },
            {
                "value": 65563,
                "label": "Wilcox",
                "label_ar": "ويلكوكس",
                "label_fr": "Wilcox"
            },
            {
                "value": 65732,
                "label": "Les Mechins",
                "label_ar": "ليه ميتشينز",
                "label_fr": "Les Mechins"
            },
            {
                "value": 64272,
                "label": "Saint-Marc-sur-Richelieu",
                "label_ar": "سان مارك سور ريشيليو",
                "label_fr": "Saint-Marc-sur-Richelieu"
            },
            {
                "value": 65676,
                "label": "Unionville",
                "label_ar": "يونيونفيل",
                "label_fr": "Unionville"
            },
            {
                "value": 64228,
                "label": "Elora",
                "label_ar": "إلورا",
                "label_fr": "Elora"
            },
            {
                "value": 65394,
                "label": "Baddeck",
                "label_ar": "Baddeck",
                "label_fr": "Baddeck"
            },
            {
                "value": 65673,
                "label": "Riviere-Rouge",
                "label_ar": "ريفيير روج",
                "label_fr": "Rivière-Rouge"
            },
            {
                "value": 65662,
                "label": "South Slocan",
                "label_ar": "جنوب سلوكان",
                "label_fr": "South Slocan"
            },
            {
                "value": 65708,
                "label": "Oldcastle",
                "label_ar": "القلعة القديمة",
                "label_fr": "Vieux château"
            },
            {
                "value": 65135,
                "label": "Saint-Anselme",
                "label_ar": "سانت أنسيلمي",
                "label_fr": "Saint-Anselme"
            },
            {
                "value": 64345,
                "label": "Springdale",
                "label_ar": "سبرينجديل",
                "label_fr": "Springdale"
            },
            {
                "value": 65478,
                "label": "Saint-Paul",
                "label_ar": "القديس بول",
                "label_fr": "Saint Paul"
            },
            {
                "value": 65650,
                "label": "Havre-Aubert",
                "label_ar": "هافر أوبيرت",
                "label_fr": "Havre-Aubert"
            },
            {
                "value": 63699,
                "label": "Lacombe",
                "label_ar": "لاكومب",
                "label_fr": "Lacombe"
            },
            {
                "value": 64668,
                "label": "Everett",
                "label_ar": "إيفريت",
                "label_fr": "Everett"
            },
            {
                "value": 64283,
                "label": "Stanbridge East",
                "label_ar": "ستانبريدج إيست",
                "label_fr": "Stanbridge Est"
            },
            {
                "value": 63848,
                "label": "North York",
                "label_ar": "شمال يورك",
                "label_fr": "North York"
            },
            {
                "value": 64780,
                "label": "Rosalind",
                "label_ar": "روزاليند",
                "label_fr": "Rosalind"
            },
            {
                "value": 64896,
                "label": "Caronport",
                "label_ar": "مرآب",
                "label_fr": "Caronport"
            },
            {
                "value": 65238,
                "label": "Seton Portage",
                "label_ar": "سيتون بورتاج",
                "label_fr": "Seton Portage"
            },
            {
                "value": 64077,
                "label": "East Broughton",
                "label_ar": "شرق بروتون",
                "label_fr": "East Broughton"
            },
            {
                "value": 64928,
                "label": "Coleraine",
                "label_ar": "كوليرين",
                "label_fr": "Coleraine"
            },
            {
                "value": 64879,
                "label": "Shawville",
                "label_ar": "شوفيل",
                "label_fr": "Shawville"
            },
            {
                "value": 65731,
                "label": "Sayward",
                "label_ar": "سايوارد",
                "label_fr": "Sayward"
            },
            {
                "value": 65223,
                "label": "St-Lazare",
                "label_ar": "سانت لازار",
                "label_fr": "St-Lazare"
            },
            {
                "value": 65055,
                "label": "New-Wes-Valley",
                "label_ar": "نيو ويس فالي",
                "label_fr": "Nouvelle-Wes-Valley"
            },
            {
                "value": 65604,
                "label": "Kispiox",
                "label_ar": "كيسبيوكس",
                "label_fr": "Kispiox"
            },
            {
                "value": 64106,
                "label": "Harbour Grace",
                "label_ar": "هاربور جريس",
                "label_fr": "Harbour Grace"
            },
            {
                "value": 63813,
                "label": "Kincardine",
                "label_ar": "كينكاردين",
                "label_fr": "Kincardine"
            },
            {
                "value": 63760,
                "label": "Stoney Creek",
                "label_ar": "ستوني كريك",
                "label_fr": "Stoney Creek"
            },
            {
                "value": 63778,
                "label": "Saint-Hubert-de-Riviere-du-Loup",
                "label_ar": "سان هوبرت دي ريفيير دو لوب",
                "label_fr": "Saint-Hubert-de-Rivière-du-Loup"
            },
            {
                "value": 65349,
                "label": "Barnaby River",
                "label_ar": "نهر بارنابي",
                "label_fr": "Rivière Barnaby"
            },
            {
                "value": 63989,
                "label": "Truro",
                "label_ar": "ترورو",
                "label_fr": "Truro"
            },
            {
                "value": 63821,
                "label": "Waterville",
                "label_ar": "ووترفيل",
                "label_fr": "Waterville"
            },
            {
                "value": 65108,
                "label": "Berwyn",
                "label_ar": "بيروين",
                "label_fr": "Berwyn"
            },
            {
                "value": 64972,
                "label": "Sointula",
                "label_ar": "سوينتولا",
                "label_fr": "Sointula"
            },
            {
                "value": 65652,
                "label": "North Delta",
                "label_ar": "شمال الدلتا",
                "label_fr": "Delta Nord"
            },
            {
                "value": 65788,
                "label": "Coal Harbour",
                "label_ar": "ميناء الفحم",
                "label_fr": "Coal Harbour"
            },
            {
                "value": 65471,
                "label": "Beiseker",
                "label_ar": "بيسكر",
                "label_fr": "Beiseker"
            },
            {
                "value": 64655,
                "label": "Bowmanville",
                "label_ar": "بومانفيل",
                "label_fr": "Bowmanville"
            },
            {
                "value": 65262,
                "label": "Castle Bay",
                "label_ar": "كاسل باي",
                "label_fr": "Castle Bay"
            },
            {
                "value": 64853,
                "label": "Oxdrift",
                "label_ar": "أوكسدريفت",
                "label_fr": "Oxdrift"
            },
            {
                "value": 64378,
                "label": "Valcourt",
                "label_ar": "فالكورت",
                "label_fr": "Valcourt"
            },
            {
                "value": 64163,
                "label": "Garson",
                "label_ar": "جارسون",
                "label_fr": "Garson"
            },
            {
                "value": 65857,
                "label": "Frelighsburg",
                "label_ar": "فريليجسبورج",
                "label_fr": "Frelighsburg"
            },
            {
                "value": 65540,
                "label": "High Prairie",
                "label_ar": "هاي براري",
                "label_fr": "High Prairie"
            },
            {
                "value": 63718,
                "label": "Hamilton",
                "label_ar": "هاملتون",
                "label_fr": "Hamilton"
            },
            {
                "value": 65172,
                "label": "Maria",
                "label_ar": "ماريا",
                "label_fr": "Maria"
            },
            {
                "value": 65228,
                "label": "Lower Island Cove",
                "label_ar": "جزيرة لوار كوف",
                "label_fr": "Lower Island Cove"
            },
            {
                "value": 64894,
                "label": "Silton",
                "label_ar": "سيلتون",
                "label_fr": "Silton"
            },
            {
                "value": 64166,
                "label": "Listowel",
                "label_ar": "الاستماع",
                "label_fr": "Listowel"
            },
            {
                "value": 64615,
                "label": "Pikangikum",
                "label_ar": "Pikangikum",
                "label_fr": "Pikangikum"
            },
            {
                "value": 65027,
                "label": "Crossfield",
                "label_ar": "كروسفيلد",
                "label_fr": "Crossfield"
            },
            {
                "value": 65819,
                "label": "Cut Knife",
                "label_ar": "سكين القطع",
                "label_fr": "Couper le couteau"
            },
            {
                "value": 64435,
                "label": "Hartland",
                "label_ar": "هارتلاند",
                "label_fr": "Hartland"
            },
            {
                "value": 65046,
                "label": "Colliers",
                "label_ar": "كوليرز",
                "label_fr": "Colliers"
            },
            {
                "value": 65835,
                "label": "Arctic Bay",
                "label_ar": "أركتيك باي",
                "label_fr": "Arctic Bay"
            },
            {
                "value": 64226,
                "label": "Pembroke",
                "label_ar": "بيمبروك",
                "label_fr": "Pembroke"
            },
            {
                "value": 64720,
                "label": "Lorraine",
                "label_ar": "لورين",
                "label_fr": "Lorraine"
            },
            {
                "value": 64643,
                "label": "Sanikiluaq",
                "label_ar": "سانيكيلواك",
                "label_fr": "Sanikiluaq"
            },
            {
                "value": 64990,
                "label": "Lanoraie",
                "label_ar": "لانوراي",
                "label_fr": "Lanoraie"
            },
            {
                "value": 65251,
                "label": "Cudworth",
                "label_ar": "كودوورث",
                "label_fr": "Cudworth"
            },
            {
                "value": 64513,
                "label": "Mandeville",
                "label_ar": "ماندفيل",
                "label_fr": "Mandeville"
            },
            {
                "value": 63711,
                "label": "Leduc",
                "label_ar": "ليدوك",
                "label_fr": "Leduc"
            },
            {
                "value": 64708,
                "label": "Glentworth",
                "label_ar": "جلينتوورث",
                "label_fr": "Glentworth"
            },
            {
                "value": 64349,
                "label": "Port Medway",
                "label_ar": "ميناء ميدواي",
                "label_fr": "Port Medway"
            },
            {
                "value": 64344,
                "label": "Bear River",
                "label_ar": "نهر بير",
                "label_fr": "Bear River"
            },
            {
                "value": 65814,
                "label": "Seaforth",
                "label_ar": "سيفورث",
                "label_fr": "Seaforth"
            },
            {
                "value": 65711,
                "label": "New Denver",
                "label_ar": "دنفر الجديدة",
                "label_fr": "New Denver"
            },
            {
                "value": 63695,
                "label": "Port Moody",
                "label_ar": "بورت مودي",
                "label_fr": "Port Moody"
            },
            {
                "value": 64711,
                "label": "Saint-Pacome",
                "label_ar": "سانت باكوم",
                "label_fr": "Saint-Pacôme"
            },
            {
                "value": 64978,
                "label": "Val-des-Monts",
                "label_ar": "فال دي مونت",
                "label_fr": "Val-des-Monts"
            },
            {
                "value": 63681,
                "label": "Port Coquitlam",
                "label_ar": "ميناء كوكتلام",
                "label_fr": "Port Coquitlam"
            },
            {
                "value": 63743,
                "label": "Saint-Augustin",
                "label_ar": "سانت أوغسطين",
                "label_fr": "Saint-Augustin"
            },
            {
                "value": 64102,
                "label": "Digby",
                "label_ar": "ديجبي",
                "label_fr": "Digby"
            },
            {
                "value": 64619,
                "label": "Griswold",
                "label_ar": "جريسوولد",
                "label_fr": "Griswold"
            },
            {
                "value": 64339,
                "label": "Bonne Bay",
                "label_ar": "خليج بون",
                "label_fr": "Bonne Baie"
            },
            {
                "value": 65253,
                "label": "Saint-Urbain-Premier",
                "label_ar": "سانت أوربان رئيس الوزراء",
                "label_fr": "Saint-Urbain-Premier"
            },
            {
                "value": 64136,
                "label": "Stratford",
                "label_ar": "ستراتفورد",
                "label_fr": "Stratford"
            },
            {
                "value": 64047,
                "label": "Maskinonge",
                "label_ar": "ماسكينونج",
                "label_fr": "Maskinonge"
            },
            {
                "value": 64560,
                "label": "Rouleau",
                "label_ar": "رولو",
                "label_fr": "Rouleau"
            },
            {
                "value": 64612,
                "label": "Sandy Lake",
                "label_ar": "بحيرة ساندي",
                "label_fr": "Sandy Lake"
            },
            {
                "value": 65472,
                "label": "Millet",
                "label_ar": "الدخن",
                "label_fr": "Millet"
            },
            {
                "value": 65605,
                "label": "Cedar Valley",
                "label_ar": "وادي الأرز",
                "label_fr": "Cedar Valley"
            },
            {
                "value": 64867,
                "label": "Fort Erie",
                "label_ar": "فورت إيري",
                "label_fr": "Fort Erie"
            },
            {
                "value": 64510,
                "label": "Innisfil",
                "label_ar": "إنيسفيل",
                "label_fr": "Innisfil"
            },
            {
                "value": 65812,
                "label": "Garden River",
                "label_ar": "نهر الحديقة",
                "label_fr": "Garden River"
            },
            {
                "value": 64805,
                "label": "Ilderton",
                "label_ar": "إيلديرتون",
                "label_fr": "Ilderton"
            },
            {
                "value": 64169,
                "label": "Wingham",
                "label_ar": "وينغهام",
                "label_fr": "Wingham"
            },
            {
                "value": 64763,
                "label": "Hawkestone Beach",
                "label_ar": "شاطئ هوكستون",
                "label_fr": "Plage de Hawkestone"
            },
            {
                "value": 64374,
                "label": "Circonscription electorale d'Outremont",
                "label_ar": "Circonscription electorale d'Outremont",
                "label_fr": "Circonscription électorale d'Outremont"
            },
            {
                "value": 65691,
                "label": "Ferintosh",
                "label_ar": "فيرينتوش",
                "label_fr": "Ferintosh"
            },
            {
                "value": 64583,
                "label": "Valemount",
                "label_ar": "فاليمونت",
                "label_fr": "Valemount"
            },
            {
                "value": 64088,
                "label": "Lindsay",
                "label_ar": "ليندسي",
                "label_fr": "Lindsay"
            },
            {
                "value": 64461,
                "label": "Glace Bay",
                "label_ar": "خليج جلاس",
                "label_fr": "Glace Bay"
            },
            {
                "value": 63690,
                "label": "Maple Ridge",
                "label_ar": "القيقب ريدج",
                "label_fr": "Maple Ridge"
            },
            {
                "value": 63832,
                "label": "Alma",
                "label_ar": "ألما",
                "label_fr": "Alma"
            },
            {
                "value": 65383,
                "label": "Shellbrook",
                "label_ar": "شيلبروك",
                "label_fr": "Shellbrook"
            },
            {
                "value": 65826,
                "label": "Bryson",
                "label_ar": "بريسون",
                "label_fr": "Bryson"
            },
            {
                "value": 65149,
                "label": "Saint-Tite",
                "label_ar": "سان تيتي",
                "label_fr": "Saint-Tite"
            },
            {
                "value": 65356,
                "label": "Carman",
                "label_ar": "كارمان",
                "label_fr": "Carman"
            },
            {
                "value": 63692,
                "label": "Chilliwack",
                "label_ar": "تشيليواك",
                "label_fr": "Chilliwack"
            },
            {
                "value": 63839,
                "label": "Magog",
                "label_ar": "ماجوج",
                "label_fr": "Magog"
            },
            {
                "value": 64069,
                "label": "Saint-Cyrille-de-Wendover",
                "label_ar": "سان سيريل دي ويندوفر",
                "label_fr": "Saint-Cyrille-de-Wendover"
            },
            {
                "value": 65817,
                "label": "St. Louis",
                "label_ar": "سانت لويس",
                "label_fr": "Saint Louis"
            },
            {
                "value": 65098,
                "label": "Two Hills",
                "label_ar": "تلالان",
                "label_fr": "Deux collines"
            },
            {
                "value": 65195,
                "label": "Whatì",
                "label_ar": "ما انا",
                "label_fr": "Ce que je"
            },
            {
                "value": 64679,
                "label": "Kohler",
                "label_ar": "كوهلر",
                "label_fr": "Kohler"
            },
            {
                "value": 65655,
                "label": "Munson",
                "label_ar": "مونسون",
                "label_fr": "Munson"
            },
            {
                "value": 65389,
                "label": "Radisson",
                "label_ar": "راديسون",
                "label_fr": "Radisson"
            },
            {
                "value": 65395,
                "label": "Canso",
                "label_ar": "كانسو",
                "label_fr": "Canso"
            },
            {
                "value": 63785,
                "label": "Saint-Leonard",
                "label_ar": "سانت ليونارد",
                "label_fr": "Saint-Léonard"
            },
            {
                "value": 65291,
                "label": "Shediac",
                "label_ar": "شدياق",
                "label_fr": "Shediac"
            },
            {
                "value": 63790,
                "label": "Saint-Jean-sur-Richelieu",
                "label_ar": "سان جان سور ريشيليو",
                "label_fr": "Saint-Jean-sur-Richelieu"
            },
            {
                "value": 64207,
                "label": "Huntsville",
                "label_ar": "هانتسفيل",
                "label_fr": "Huntsville"
            },
            {
                "value": 65750,
                "label": "Abbey",
                "label_ar": "دير",
                "label_fr": "une abbaye"
            },
            {
                "value": 64670,
                "label": "Ignace",
                "label_ar": "إشعال",
                "label_fr": "Ignace"
            },
            {
                "value": 65449,
                "label": "Saint-Marc-des-Carrieres",
                "label_ar": "سان مارك دي كاريير",
                "label_fr": "Saint-Marc-des-Carrières"
            },
            {
                "value": 65090,
                "label": "Bishop's Falls",
                "label_ar": "شلالات الأسقف",
                "label_fr": "Bishop's Falls"
            },
            {
                "value": 65520,
                "label": "Wakefield",
                "label_ar": "ويكفيلد",
                "label_fr": "Wakefield"
            },
            {
                "value": 65304,
                "label": "Granton",
                "label_ar": "جرانتون",
                "label_fr": "Granton"
            },
            {
                "value": 65433,
                "label": "Mont-Saint-Michel",
                "label_ar": "مونت سانت ميشيل",
                "label_fr": "Mont-Saint-Michel"
            },
            {
                "value": 64167,
                "label": "Mildmay",
                "label_ar": "ميلدماي",
                "label_fr": "Mildmay"
            },
            {
                "value": 64393,
                "label": "Omemee",
                "label_ar": "أوميمي",
                "label_fr": "Omemee"
            },
            {
                "value": 64807,
                "label": "Alfred",
                "label_ar": "ألفريد",
                "label_fr": "Alfred"
            },
            {
                "value": 65111,
                "label": "MacTier",
                "label_ar": "ماكتير",
                "label_fr": "MacTier"
            },
            {
                "value": 64574,
                "label": "Prince Albert",
                "label_ar": "الأمير ألبرت",
                "label_fr": "Prince Albert"
            },
            {
                "value": 65407,
                "label": "Bouctouche",
                "label_ar": "بوكتوش",
                "label_fr": "Bouctouche"
            },
            {
                "value": 64046,
                "label": "Concord",
                "label_ar": "كونكورد",
                "label_fr": "Concorde"
            },
            {
                "value": 64592,
                "label": "Elgin",
                "label_ar": "إلجين",
                "label_fr": "Elgin"
            },
            {
                "value": 65501,
                "label": "Winfield",
                "label_ar": "وينفيلد",
                "label_fr": "Winfield"
            },
            {
                "value": 63746,
                "label": "Dolbeau-Mistassini",
                "label_ar": "دولبو ميستاسيني",
                "label_fr": "Dolbeau-Mistassini"
            },
            {
                "value": 64263,
                "label": "Saint-Joachim-de-Shefford",
                "label_ar": "سان يواكيم دي شيفورد",
                "label_fr": "Saint-Joachim-de-Shefford"
            },
            {
                "value": 65690,
                "label": "Clair",
                "label_ar": "كلير",
                "label_fr": "Clair"
            },
            {
                "value": 65571,
                "label": "Apple Hill",
                "label_ar": "هيل هيل",
                "label_fr": "Apple Hill"
            },
            {
                "value": 65854,
                "label": "Ceylon",
                "label_ar": "سيلان",
                "label_fr": "Ceylan"
            },
            {
                "value": 65359,
                "label": "Trout River",
                "label_ar": "نهر التراوت",
                "label_fr": "Rivière Trout"
            },
            {
                "value": 65363,
                "label": "Eastport",
                "label_ar": "ايستبورت",
                "label_fr": "Eastport"
            },
            {
                "value": 65831,
                "label": "Malagawatch",
                "label_ar": "مالاجواتش",
                "label_fr": "Malagawatch"
            },
            {
                "value": 65131,
                "label": "Cheneville",
                "label_ar": "تشينفيل",
                "label_fr": "Cheneville"
            },
            {
                "value": 65651,
                "label": "Bamfield",
                "label_ar": "بامفيلد",
                "label_fr": "Bamfield"
            },
            {
                "value": 63852,
                "label": "Etobicoke",
                "label_ar": "إيتوبيكوك",
                "label_fr": "Etobicoke"
            },
            {
                "value": 64749,
                "label": "Grand Bend",
                "label_ar": "جراند بيند",
                "label_fr": "Grand Bend"
            },
            {
                "value": 64052,
                "label": "Becancour",
                "label_ar": "بيكانكور",
                "label_fr": "Bécancour"
            },
            {
                "value": 65715,
                "label": "Saint-Casimir",
                "label_ar": "سان كازيمير",
                "label_fr": "Saint-Casimir"
            },
            {
                "value": 65667,
                "label": "Riviere-Ouelle",
                "label_ar": "ريفيير أويل",
                "label_fr": "Rivière-Ouelle"
            },
            {
                "value": 64059,
                "label": "Sainte-Angele-de-Premont",
                "label_ar": "سانت انجيل دي بريمونت",
                "label_fr": "Sainte-Angele-de-Premont"
            },
            {
                "value": 65105,
                "label": "Boyle",
                "label_ar": "بويل",
                "label_fr": "Boyle"
            },
            {
                "value": 64600,
                "label": "Forestburg",
                "label_ar": "فورستبرج",
                "label_fr": "Forestburg"
            },
            {
                "value": 65022,
                "label": "Bowser",
                "label_ar": "العربة",
                "label_fr": "Bowser"
            },
            {
                "value": 65367,
                "label": "O'Regan's",
                "label_ar": "أوريجان",
                "label_fr": "O'Regan's"
            },
            {
                "value": 63722,
                "label": "Cambridge",
                "label_ar": "كامبريدج",
                "label_fr": "Cambridge"
            },
            {
                "value": 63981,
                "label": "Bridgewater",
                "label_ar": "بريدجووتر",
                "label_fr": "Bridgewater"
            },
            {
                "value": 64361,
                "label": "Wainwright",
                "label_ar": "وينرايت",
                "label_fr": "Wainwright"
            },
            {
                "value": 64829,
                "label": "Spiritwood",
                "label_ar": "سبيريتوود",
                "label_fr": "Spiritwood"
            },
            {
                "value": 64673,
                "label": "Casselman",
                "label_ar": "كاسيلمان",
                "label_fr": "Casselman"
            },
            {
                "value": 64291,
                "label": "Princeton",
                "label_ar": "برينستون",
                "label_fr": "Princeton"
            },
            {
                "value": 64728,
                "label": "Fort Severn",
                "label_ar": "حصن سيفيرن",
                "label_fr": "Fort Severn"
            },
            {
                "value": 65847,
                "label": "Beachburg",
                "label_ar": "بيتشبرج",
                "label_fr": "Beachburg"
            },
            {
                "value": 63818,
                "label": "L'Assomption",
                "label_ar": "لا أسومبشن",
                "label_fr": "L'Assomption"
            },
            {
                "value": 64183,
                "label": "Picton",
                "label_ar": "بيكتون",
                "label_fr": "Picton"
            },
            {
                "value": 64577,
                "label": "Wawota",
                "label_ar": "واوتا",
                "label_fr": "Wawota"
            },
            {
                "value": 65728,
                "label": "Hagensborg",
                "label_ar": "هاجنسبورج",
                "label_fr": "Hagensborg"
            },
            {
                "value": 65044,
                "label": "Saint-Hilaire",
                "label_ar": "سانت هيلير",
                "label_fr": "Saint-Hilaire"
            },
            {
                "value": 64520,
                "label": "Long Sault",
                "label_ar": "لونغ سولت",
                "label_fr": "Long Sault"
            },
            {
                "value": 65165,
                "label": "Saint-Alban",
                "label_ar": "سان ألبان",
                "label_fr": "Saint-Alban"
            },
            {
                "value": 65555,
                "label": "Flin Flon",
                "label_ar": "فلين فلون",
                "label_fr": "Flin Flon"
            },
            {
                "value": 65078,
                "label": "Saint-Sylvestre",
                "label_ar": "سانت سيلفستر",
                "label_fr": "Saint-Sylvestre"
            },
            {
                "value": 64856,
                "label": "Clifford",
                "label_ar": "كليفورد",
                "label_fr": "Clifford"
            },
            {
                "value": 64538,
                "label": "Moosonee",
                "label_ar": "Moosonee",
                "label_fr": "Moosonee"
            },
            {
                "value": 63726,
                "label": "Kitchener",
                "label_ar": "كيتشنر",
                "label_fr": "Kitchener"
            },
            {
                "value": 64958,
                "label": "Madoc",
                "label_ar": "مادوك",
                "label_fr": "Madoc"
            },
            {
                "value": 65743,
                "label": "Bridge Lake",
                "label_ar": "بحيرة الجسر",
                "label_fr": "Bridge Lake"
            },
            {
                "value": 64335,
                "label": "Cornwallis",
                "label_ar": "كورنواليس",
                "label_fr": "Cornwallis"
            },
            {
                "value": 64847,
                "label": "Rocanville",
                "label_ar": "روكانفيل",
                "label_fr": "Rocanville"
            },
            {
                "value": 64439,
                "label": "Lewisporte",
                "label_ar": "لويسبورتي",
                "label_fr": "Lewisporte"
            },
            {
                "value": 65406,
                "label": "Dalhousie",
                "label_ar": "دالهوزي",
                "label_fr": "Dalhousie"
            },
            {
                "value": 65288,
                "label": "Millville",
                "label_ar": "ميلفيل",
                "label_fr": "Millville"
            },
            {
                "value": 64892,
                "label": "Dalmeny",
                "label_ar": "دالميني",
                "label_fr": "Dalmeny"
            },
            {
                "value": 65678,
                "label": "Wemindji",
                "label_ar": "ويميندجي",
                "label_fr": "Wemindji"
            },
            {
                "value": 65408,
                "label": "Wabush",
                "label_ar": "وابش",
                "label_fr": "Wabush"
            },
            {
                "value": 65818,
                "label": "Lake Lenore",
                "label_ar": "بحيرة لينور",
                "label_fr": "Lac Lenore"
            },
            {
                "value": 65378,
                "label": "Vonda",
                "label_ar": "فوندا",
                "label_fr": "Vonda"
            },
            {
                "value": 63913,
                "label": "Fernie",
                "label_ar": "فيرني",
                "label_fr": "Fernie"
            },
            {
                "value": 63932,
                "label": "Enderby",
                "label_ar": "إندربي",
                "label_fr": "Enderby"
            },
            {
                "value": 64482,
                "label": "Fort St. James",
                "label_ar": "فورت سانت جيمس",
                "label_fr": "Fort Saint-James"
            },
            {
                "value": 64256,
                "label": "Saint-Lin",
                "label_ar": "سانت لين",
                "label_fr": "Saint-Lin"
            },
            {
                "value": 65341,
                "label": "Vernon Bridge",
                "label_ar": "جسر فيرنون",
                "label_fr": "Pont de Vernon"
            },
            {
                "value": 63769,
                "label": "Peterborough",
                "label_ar": "بيتربورو",
                "label_fr": "Peterborough"
            },
            {
                "value": 63723,
                "label": "Waterloo",
                "label_ar": "واترلو",
                "label_fr": "Waterloo"
            },
            {
                "value": 64006,
                "label": "Shawnigan Lake",
                "label_ar": "بحيرة شونيغان",
                "label_fr": "Lac Shawnigan"
            },
            {
                "value": 65531,
                "label": "Nauwigewauk",
                "label_ar": "Nauwigewauk",
                "label_fr": "Nauwigewauk"
            },
            {
                "value": 65443,
                "label": "Rumsey",
                "label_ar": "رمزي",
                "label_fr": "Rumsey"
            },
            {
                "value": 65827,
                "label": "Springfield",
                "label_ar": "سبرينجفيلد",
                "label_fr": "Springfield"
            },
            {
                "value": 65391,
                "label": "Shanklin",
                "label_ar": "شانكلين",
                "label_fr": "Shanklin"
            },
            {
                "value": 64754,
                "label": "Thedford",
                "label_ar": "ثيدفورد",
                "label_fr": "Thedford"
            },
            {
                "value": 64017,
                "label": "Thunder Bay",
                "label_ar": "الرعد خليج",
                "label_fr": "Thunder Bay"
            },
            {
                "value": 64449,
                "label": "Berry Mills",
                "label_ar": "بيري ميلز",
                "label_fr": "Berry Mills"
            },
            {
                "value": 64298,
                "label": "Dupuy",
                "label_ar": "دوبوي",
                "label_fr": "Dupuy"
            },
            {
                "value": 63685,
                "label": "Delta",
                "label_ar": "دلتا",
                "label_fr": "Delta"
            },
            {
                "value": 64155,
                "label": "Blind River",
                "label_ar": "نهر بليند",
                "label_fr": "Blind River"
            },
            {
                "value": 63987,
                "label": "Eastern Passage",
                "label_ar": "الممر الشرقي",
                "label_fr": "Passage oriental"
            },
            {
                "value": 65556,
                "label": "Elgin",
                "label_ar": "إلجين",
                "label_fr": "Elgin"
            },
            {
                "value": 64464,
                "label": "Grand Falls",
                "label_ar": "جراند فولز",
                "label_fr": "Grand-Sault"
            },
            {
                "value": 64925,
                "label": "Parisville",
                "label_ar": "باريسفيل",
                "label_fr": "Parisville"
            },
            {
                "value": 63684,
                "label": "Surrey",
                "label_ar": "ساري",
                "label_fr": "Surrey"
            },
            {
                "value": 64714,
                "label": "Saint-Jean-Port-Joli",
                "label_ar": "سان جان بورت جولي",
                "label_fr": "Saint-Jean-Port-Joli"
            },
            {
                "value": 63794,
                "label": "Saint-Jerome",
                "label_ar": "سان جيروم",
                "label_fr": "Saint-Jérôme"
            },
            {
                "value": 64723,
                "label": "Inukjuak",
                "label_ar": "إينوكجواك",
                "label_fr": "Inukjuak"
            },
            {
                "value": 65019,
                "label": "Gabriola",
                "label_ar": "غابريولا",
                "label_fr": "Gabriola"
            },
            {
                "value": 65168,
                "label": "Murdochville",
                "label_ar": "مردوخفيل",
                "label_fr": "Murdochville"
            },
            {
                "value": 64390,
                "label": "Iroquois Falls",
                "label_ar": "شلالات إيروكوا",
                "label_fr": "Chutes d'Iroquois"
            },
            {
                "value": 65803,
                "label": "Saint-Irenee",
                "label_ar": "سانت إيريني",
                "label_fr": "Saint-Irénée"
            },
            {
                "value": 64994,
                "label": "Spanish",
                "label_ar": "الأسبانية",
                "label_fr": "Espagnol"
            },
            {
                "value": 65505,
                "label": "Ramea",
                "label_ar": "راميا",
                "label_fr": "Ramea"
            },
            {
                "value": 65372,
                "label": "Rencontre East",
                "label_ar": "رينكونتر الشرق",
                "label_fr": "Rencontre Est"
            },
            {
                "value": 65089,
                "label": "Foxtrap",
                "label_ar": "فوكستراب",
                "label_fr": "Foxtrap"
            },
            {
                "value": 65551,
                "label": "Saint-Ambroise",
                "label_ar": "سان امبرواز",
                "label_fr": "Saint-Ambroise"
            },
            {
                "value": 65010,
                "label": "Begin",
                "label_ar": "ابدأ",
                "label_fr": "Commencer"
            },
            {
                "value": 63779,
                "label": "Rosemere",
                "label_ar": "روزمير",
                "label_fr": "Rosemère"
            },
            {
                "value": 65184,
                "label": "Kaslo",
                "label_ar": "كاسلو",
                "label_fr": "Kaslo"
            },
            {
                "value": 65707,
                "label": "South Indian Lake",
                "label_ar": "بحيرة جنوب الهند",
                "label_fr": "South Indian Lake"
            },
            {
                "value": 65482,
                "label": "Arthabaska",
                "label_ar": "أرثاباسكا",
                "label_fr": "Arthabaska"
            },
            {
                "value": 64396,
                "label": "Clinton",
                "label_ar": "كلينتون",
                "label_fr": "Clinton"
            },
            {
                "value": 64606,
                "label": "Merville",
                "label_ar": "ميرفيل",
                "label_fr": "Merville"
            },
            {
                "value": 65351,
                "label": "Tyndall",
                "label_ar": "تيندال",
                "label_fr": "Tyndall"
            },
            {
                "value": 64652,
                "label": "Saint-Guillaume",
                "label_ar": "سان غيوم",
                "label_fr": "Saint-Guillaume"
            },
            {
                "value": 65612,
                "label": "Breslau",
                "label_ar": "بريسلاو",
                "label_fr": "Breslau"
            },
            {
                "value": 65858,
                "label": "Lyster",
                "label_ar": "ليستر",
                "label_fr": "Lyster"
            },
            {
                "value": 63901,
                "label": "Drayton Valley",
                "label_ar": "وادي درايتون",
                "label_fr": "Vallée de Drayton"
            },
            {
                "value": 64193,
                "label": "Lougheed",
                "label_ar": "لوغيد",
                "label_fr": "Lougheed"
            },
            {
                "value": 64341,
                "label": "Morell",
                "label_ar": "موريل",
                "label_fr": "Morell"
            },
            {
                "value": 64023,
                "label": "Bolton",
                "label_ar": "بولتون",
                "label_fr": "Boulonner"
            },
            {
                "value": 64120,
                "label": "Kensington",
                "label_ar": "كنسينغتون",
                "label_fr": "Kensington"
            },
            {
                "value": 64159,
                "label": "Belleville",
                "label_ar": "بيلفيل",
                "label_fr": "Belleville"
            },
            {
                "value": 65318,
                "label": "Price",
                "label_ar": "السعر",
                "label_fr": "Prix"
            },
            {
                "value": 64033,
                "label": "Norfolk County",
                "label_ar": "مقاطعة نورفولك",
                "label_fr": "Comté de Norfolk"
            },
            {
                "value": 64051,
                "label": "Pierreville",
                "label_ar": "بييرفيل",
                "label_fr": "Pierreville"
            },
            {
                "value": 64229,
                "label": "Vaudreuil-Dorion",
                "label_ar": "فودريل دوريون",
                "label_fr": "Vaudreuil-Dorion"
            },
            {
                "value": 64873,
                "label": "Redwater",
                "label_ar": "ماء أحمر",
                "label_fr": "Eau Rouge"
            },
            {
                "value": 65326,
                "label": "Montague",
                "label_ar": "مونتاج",
                "label_fr": "Montague"
            },
            {
                "value": 64328,
                "label": "Brigus",
                "label_ar": "بريجس",
                "label_fr": "Brigus"
            },
            {
                "value": 65751,
                "label": "Blaine Lake",
                "label_ar": "بحيرة بلين",
                "label_fr": "Lac Blaine"
            },
            {
                "value": 64844,
                "label": "Carrot River",
                "label_ar": "نهر الجزر",
                "label_fr": "Rivière Carrot"
            },
            {
                "value": 64400,
                "label": "Berthierville",
                "label_ar": "بيرثيرفيل",
                "label_fr": "Berthierville"
            },
            {
                "value": 64955,
                "label": "Ridgetown",
                "label_ar": "ريدجتاون",
                "label_fr": "Ridgetown"
            },
            {
                "value": 64651,
                "label": "Shaunavon",
                "label_ar": "شونافون",
                "label_fr": "Shaunavon"
            },
            {
                "value": 65235,
                "label": "Keswick",
                "label_ar": "كيسويك",
                "label_fr": "Keswick"
            },
            {
                "value": 65735,
                "label": "Kedgwick",
                "label_ar": "كيدجويك",
                "label_fr": "Kedgwick"
            },
            {
                "value": 63886,
                "label": "Memphremagog",
                "label_ar": "ممفريماجوج",
                "label_fr": "Memphrémagog"
            },
            {
                "value": 64414,
                "label": "Winkler",
                "label_ar": "وينكلر",
                "label_fr": "Winkler"
            },
            {
                "value": 65500,
                "label": "Wembley",
                "label_ar": "ويمبلي",
                "label_fr": "Wembley"
            },
            {
                "value": 64041,
                "label": "Huntingdon",
                "label_ar": "هانتينجدون",
                "label_fr": "Huntingdon"
            },
            {
                "value": 65270,
                "label": "Churchill",
                "label_ar": "تشرشل",
                "label_fr": "Churchill"
            },
            {
                "value": 65366,
                "label": "Garnish",
                "label_ar": "مقبلات",
                "label_fr": "Garnir"
            },
            {
                "value": 65493,
                "label": "Provost",
                "label_ar": "العميد",
                "label_fr": "Prévôt"
            },
            {
                "value": 65456,
                "label": "Audet",
                "label_ar": "أوديت",
                "label_fr": "Audet"
            },
            {
                "value": 63976,
                "label": "Midale",
                "label_ar": "ميدال",
                "label_fr": "Midale"
            },
            {
                "value": 64552,
                "label": "Moraviantown",
                "label_ar": "مورافيانتاون",
                "label_fr": "Moraviantown"
            },
            {
                "value": 65336,
                "label": "Meteghan",
                "label_ar": "ميتيغان",
                "label_fr": "Meteghan"
            },
            {
                "value": 64331,
                "label": "Scotsburn",
                "label_ar": "سكوتسبورن",
                "label_fr": "Scotsburn"
            },
            {
                "value": 63866,
                "label": "Cote-Saint-Luc",
                "label_ar": "كوت سانت لوك",
                "label_fr": "Côte-Saint-Luc"
            },
            {
                "value": 64458,
                "label": "Mira Gut",
                "label_ar": "ميرا غوت",
                "label_fr": "Mira Gut"
            },
            {
                "value": 65112,
                "label": "Saint Etienne-De-Lauzon",
                "label_ar": "سانت إتيان دي لوزون",
                "label_fr": "Saint Etienne-De-Lauzon"
            },
            {
                "value": 65368,
                "label": "Cow Head",
                "label_ar": "رأس البقر",
                "label_fr": "Tête de vache"
            },
            {
                "value": 64421,
                "label": "Miramichi",
                "label_ar": "ميراميتشي",
                "label_fr": "Miramichi"
            },
            {
                "value": 64330,
                "label": "Blackville",
                "label_ar": "بلاكفيل",
                "label_fr": "Blackville"
            },
            {
                "value": 64564,
                "label": "Theodore",
                "label_ar": "ثيودور",
                "label_fr": "Théodore"
            },
            {
                "value": 65250,
                "label": "Davidson",
                "label_ar": "ديفيدسون",
                "label_fr": "Davidson"
            },
            {
                "value": 64350,
                "label": "Paradise Cove",
                "label_ar": "كهف الجنة",
                "label_fr": "Paradise Cove"
            },
            {
                "value": 65830,
                "label": "Port Morien",
                "label_ar": "بورت موريان",
                "label_fr": "Port Morien"
            },
            {
                "value": 64002,
                "label": "Crofton",
                "label_ar": "كروفتون",
                "label_fr": "Crofton"
            },
            {
                "value": 65343,
                "label": "Minto",
                "label_ar": "مينتو",
                "label_fr": "Minto"
            },
            {
                "value": 65492,
                "label": "Edgerton",
                "label_ar": "إدجيرتون",
                "label_fr": "Edgerton"
            },
            {
                "value": 63823,
                "label": "La Morandiere",
                "label_ar": "لا مورانديير",
                "label_fr": "La Morandière"
            },
            {
                "value": 64486,
                "label": "Peace River",
                "label_ar": "نهر السلام",
                "label_fr": "Rivière de la Paix"
            },
            {
                "value": 64451,
                "label": "Quispamsis",
                "label_ar": "كويسبامسيس",
                "label_fr": "Quispamsis"
            },
            {
                "value": 65405,
                "label": "Sherbrooke",
                "label_ar": "شيربروك",
                "label_fr": "Sherbrooke"
            },
            {
                "value": 64828,
                "label": "Canwood",
                "label_ar": "كانوود",
                "label_fr": "Canwood"
            },
            {
                "value": 65160,
                "label": "Woodlands",
                "label_ar": "وودلاندز",
                "label_fr": "Woodlands"
            },
            {
                "value": 64185,
                "label": "Owen Sound",
                "label_ar": "صوت أوين",
                "label_fr": "Owen Sound"
            },
            {
                "value": 65702,
                "label": "Pukatawagan",
                "label_ar": "بوكاتاواغان",
                "label_fr": "Pukatawagan"
            },
            {
                "value": 65760,
                "label": "Saint-Felix-de-Kingsey",
                "label_ar": "سان فيليكس دي كينغسي",
                "label_fr": "Saint-Félix-de-Kingsey"
            },
            {
                "value": 64575,
                "label": "Indian Head",
                "label_ar": "رئيس هندي",
                "label_fr": "Tête indienne"
            },
            {
                "value": 65736,
                "label": "Val-Morin",
                "label_ar": "فال مورين",
                "label_fr": "Val-Morin"
            },
            {
                "value": 65295,
                "label": "Denfield",
                "label_ar": "دينفيلد",
                "label_fr": "Denfield"
            },
            {
                "value": 65369,
                "label": "Parson's Pond",
                "label_ar": "بركة بارسون",
                "label_fr": "Étang Parson"
            },
            {
                "value": 65506,
                "label": "Lac du Bonnet",
                "label_ar": "لاك دو بونيه",
                "label_fr": "Lac du Bonnet"
            },
            {
                "value": 64476,
                "label": "Garibaldi",
                "label_ar": "غاريبالدي",
                "label_fr": "Garibaldi"
            },
            {
                "value": 63846,
                "label": "Thetford-Mines",
                "label_ar": "ثيتفورد ماينز",
                "label_fr": "Thetford-Mines"
            },
            {
                "value": 65758,
                "label": "Mistatim",
                "label_ar": "مستاتيم",
                "label_fr": "Mistatim"
            },
            {
                "value": 63754,
                "label": "Shannonville",
                "label_ar": "شانونفيل",
                "label_fr": "Shannonville"
            },
            {
                "value": 64054,
                "label": "Saint-Etienne-des-Gres",
                "label_ar": "سانت إتيان دي جريس",
                "label_fr": "Saint-Étienne-des-Gres"
            },
            {
                "value": 65217,
                "label": "Notre-Dame-du-Portage",
                "label_ar": "نوتردام دو بورتاج",
                "label_fr": "Notre-Dame-du-Portage"
            },
            {
                "value": 64528,
                "label": "Pinawa",
                "label_ar": "بينوا",
                "label_fr": "Pinawa"
            },
            {
                "value": 65057,
                "label": "Burgeo",
                "label_ar": "برجيو",
                "label_fr": "Burgeo"
            },
            {
                "value": 65467,
                "label": "Golden Lake",
                "label_ar": "جولدن ليك",
                "label_fr": "Golden Lake"
            },
            {
                "value": 65569,
                "label": "Merrickville",
                "label_ar": "ميريكفيل",
                "label_fr": "Merrickville"
            },
            {
                "value": 64527,
                "label": "Norwood",
                "label_ar": "نوروود",
                "label_fr": "Norwood"
            },
            {
                "value": 65085,
                "label": "Alexander",
                "label_ar": "الكسندر",
                "label_fr": "Alexandre"
            },
            {
                "value": 65842,
                "label": "Sandy Bay",
                "label_ar": "ساندي باي",
                "label_fr": "Sandy Bay"
            },
            {
                "value": 64532,
                "label": "Milford",
                "label_ar": "ميلفورد",
                "label_fr": "Milford"
            },
            {
                "value": 65775,
                "label": "Englehart",
                "label_ar": "إنجلهارت",
                "label_fr": "Englehart"
            },
            {
                "value": 64240,
                "label": "La Pocatiere",
                "label_ar": "لا بوكاتير",
                "label_fr": "La Pocatière"
            },
            {
                "value": 65671,
                "label": "MacGregor",
                "label_ar": "ماكجريجور",
                "label_fr": "MacGregor"
            },
            {
                "value": 64502,
                "label": "Claresholm",
                "label_ar": "كلاريشولم",
                "label_fr": "Claresholm"
            },
            {
                "value": 64158,
                "label": "Rockwood",
                "label_ar": "روكوود",
                "label_fr": "Rockwood"
            },
            {
                "value": 63741,
                "label": "Donnacona",
                "label_ar": "دوناكونا",
                "label_fr": "Donnacona"
            },
            {
                "value": 65740,
                "label": "Paulatuk",
                "label_ar": "بولاتوك",
                "label_fr": "Paulatuk"
            },
            {
                "value": 65824,
                "label": "Baldur",
                "label_ar": "بلدور",
                "label_fr": "Baldur"
            },
            {
                "value": 65753,
                "label": "Mankota",
                "label_ar": "مانكوتا",
                "label_fr": "Mankota"
            },
            {
                "value": 65415,
                "label": "Betsiamites",
                "label_ar": "بيتسياميتس",
                "label_fr": "Betsiamites"
            },
            {
                "value": 65804,
                "label": "Saint-Arsene",
                "label_ar": "سان ارسين",
                "label_fr": "Saint-Arsène"
            },
            {
                "value": 63811,
                "label": "Brossard",
                "label_ar": "بروسارد",
                "label_fr": "Brossard"
            },
            {
                "value": 63800,
                "label": "Repentigny",
                "label_ar": "التوبة",
                "label_fr": "Repentigny"
            },
            {
                "value": 64965,
                "label": "Riviere-Beaudette",
                "label_ar": "ريفيير بوديت",
                "label_fr": "Rivière-Beaudette"
            },
            {
                "value": 64285,
                "label": "Saint-Elzear",
                "label_ar": "سانت إلزير",
                "label_fr": "Saint-Elzéar"
            },
            {
                "value": 63939,
                "label": "Peachland",
                "label_ar": "بيتش لاند",
                "label_fr": "Peachland"
            },
            {
                "value": 64072,
                "label": "Disraeli",
                "label_ar": "دزرائيلي",
                "label_fr": "Disraeli"
            },
            {
                "value": 64450,
                "label": "Rothesay",
                "label_ar": "روثسي",
                "label_fr": "Rothesay"
            },
            {
                "value": 65856,
                "label": "Wright-Gracefield-Northfield",
                "label_ar": "رايت جريسفيلد نورثفيلد",
                "label_fr": "Wright-Gracefield-Northfield"
            },
            {
                "value": 65821,
                "label": "Hartney",
                "label_ar": "هارتني",
                "label_fr": "Hartney"
            },
            {
                "value": 64491,
                "label": "Turner Valley",
                "label_ar": "وادي تيرنر",
                "label_fr": "Turner Valley"
            },
            {
                "value": 64870,
                "label": "Bentley",
                "label_ar": "بنتلي",
                "label_fr": "Bentley"
            },
            {
                "value": 64071,
                "label": "Richmond",
                "label_ar": "ريتشموند",
                "label_fr": "Richmond"
            },
            {
                "value": 63990,
                "label": "Springhill",
                "label_ar": "تل الربيع",
                "label_fr": "Springhill"
            },
            {
                "value": 64076,
                "label": "Saint-Dominique",
                "label_ar": "سانت دومينيك",
                "label_fr": "Saint-Dominique"
            },
            {
                "value": 65211,
                "label": "Cartier",
                "label_ar": "كارتييه",
                "label_fr": "Cartier"
            },
            {
                "value": 65313,
                "label": "Chateau-Richer",
                "label_ar": "شاتو ريشر",
                "label_fr": "Château-Richer"
            },
            {
                "value": 65716,
                "label": "Sainte-Thecle",
                "label_ar": "سانت تيكلي",
                "label_fr": "Sainte-Thecle"
            },
            {
                "value": 64419,
                "label": "Perth-Andover",
                "label_ar": "بيرث أندوفر",
                "label_fr": "Perth-Andover"
            },
            {
                "value": 65543,
                "label": "Canal Flats",
                "label_ar": "شقق القناة",
                "label_fr": "Appartements du canal"
            },
            {
                "value": 65073,
                "label": "Lipton",
                "label_ar": "ليبتون",
                "label_fr": "Lipton"
            },
            {
                "value": 64431,
                "label": "Canterbury",
                "label_ar": "كانتربري",
                "label_fr": "Cantorbéry"
            },
            {
                "value": 64885,
                "label": "Rosthern",
                "label_ar": "روستيرن",
                "label_fr": "Rosthern"
            },
            {
                "value": 64607,
                "label": "Nanaimo",
                "label_ar": "نانايمو",
                "label_fr": "Nanaimo"
            },
            {
                "value": 63971,
                "label": "Tsawwassen",
                "label_ar": "تساواسن",
                "label_fr": "Tsawwassen"
            },
            {
                "value": 65689,
                "label": "Streetsville",
                "label_ar": "ستريتسفيل",
                "label_fr": "Streetsville"
            },
            {
                "value": 63809,
                "label": "Chicoutimi",
                "label_ar": "شيكوتيمي",
                "label_fr": "Chicoutimi"
            },
            {
                "value": 64074,
                "label": "Saint-Gedeon",
                "label_ar": "سان جيديون",
                "label_fr": "Saint-Gédéon"
            },
            {
                "value": 64234,
                "label": "Pointe-aux-Trembles",
                "label_ar": "Pointe-aux-Trembles",
                "label_fr": "Pointe-aux-Trembles"
            },
            {
                "value": 65469,
                "label": "Sainte-Helene-de-Breakeyville",
                "label_ar": "سانت هيلين دي بريكيفيل",
                "label_fr": "Sainte-Hélène-de-Breakeyville"
            },
            {
                "value": 65480,
                "label": "Saint-Pascal",
                "label_ar": "سان باسكال",
                "label_fr": "Saint-Pascal"
            },
            {
                "value": 65258,
                "label": "Labrador City",
                "label_ar": "مدينة لابرادور",
                "label_fr": "Labrador City"
            },
            {
                "value": 64712,
                "label": "Saint-Philippe-de-Neri",
                "label_ar": "سان فيليب دي نيري",
                "label_fr": "Saint-Philippe-de-Neri"
            },
            {
                "value": 64170,
                "label": "Mount Forest",
                "label_ar": "جبل فورست",
                "label_fr": "Mount Forest"
            },
            {
                "value": 65720,
                "label": "Whitney",
                "label_ar": "ويتني",
                "label_fr": "Whitney"
            },
            {
                "value": 64927,
                "label": "Saint-Wenceslas",
                "label_ar": "سانت وينسيسلاس",
                "label_fr": "Saint-Venceslas"
            },
            {
                "value": 64704,
                "label": "Wolseley",
                "label_ar": "ولسيلي",
                "label_fr": "Wolseley"
            },
            {
                "value": 64005,
                "label": "Mayne",
                "label_ar": "ماين",
                "label_fr": "Mayne"
            },
            {
                "value": 65619,
                "label": "Eckville",
                "label_ar": "إيكفيل",
                "label_fr": "Eckville"
            },
            {
                "value": 64498,
                "label": "Medicine Hat",
                "label_ar": "ميديسن هات",
                "label_fr": "Medicine Hat"
            },
            {
                "value": 65323,
                "label": "Charlie Lake",
                "label_ar": "تشارلي ليك",
                "label_fr": "Charlie Lake"
            },
            {
                "value": 63921,
                "label": "Westbank",
                "label_ar": "الضفة الغربية",
                "label_fr": "Banque de l'Ouest"
            },
            {
                "value": 64302,
                "label": "Macamic",
                "label_ar": "ماكاميك",
                "label_fr": "Macamic"
            },
            {
                "value": 65045,
                "label": "Beauharnois",
                "label_ar": "بوهارنويس",
                "label_fr": "Beauharnois"
            },
            {
                "value": 64717,
                "label": "Saint-Aubert",
                "label_ar": "سان أوبيرت",
                "label_fr": "Saint-Aubert"
            },
            {
                "value": 64201,
                "label": "Greenwood",
                "label_ar": "غرينوود",
                "label_fr": "Greenwood"
            },
            {
                "value": 64394,
                "label": "Pefferlaw",
                "label_ar": "بيفيرلاو",
                "label_fr": "Pefferlaw"
            },
            {
                "value": 65447,
                "label": "Saint-Gilles",
                "label_ar": "سان جيل",
                "label_fr": "Saint-Gilles"
            },
            {
                "value": 64815,
                "label": "Carrying Place",
                "label_ar": "مكان الحمل",
                "label_fr": "Lieu de transport"
            },
            {
                "value": 65465,
                "label": "Acme",
                "label_ar": "ذروة",
                "label_fr": "Acmé"
            },
            {
                "value": 64140,
                "label": "Beeton",
                "label_ar": "بيتون",
                "label_fr": "Beeton"
            },
            {
                "value": 63863,
                "label": "Ancaster",
                "label_ar": "أنكاستر",
                "label_fr": "Ancaster"
            },
            {
                "value": 65404,
                "label": "Guysborough",
                "label_ar": "Guysborough",
                "label_fr": "Guysborough"
            },
            {
                "value": 63784,
                "label": "Terrebonne",
                "label_ar": "تريبون",
                "label_fr": "Terrebonne"
            },
            {
                "value": 64426,
                "label": "Souris",
                "label_ar": "سوريس",
                "label_fr": "Souris"
            },
            {
                "value": 63799,
                "label": "Brossard",
                "label_ar": "بروسارد",
                "label_fr": "Brossard"
            },
            {
                "value": 64875,
                "label": "Namur",
                "label_ar": "نامور",
                "label_fr": "Namur"
            },
            {
                "value": 65594,
                "label": "Colonsay",
                "label_ar": "كولونساي",
                "label_fr": "Colonsay"
            },
            {
                "value": 64465,
                "label": "Pouch Cove",
                "label_ar": "الحقيبة كوف",
                "label_fr": "Pouch Cove"
            },
            {
                "value": 65589,
                "label": "Prospect",
                "label_ar": "احتمال",
                "label_fr": "Perspective"
            },
            {
                "value": 63755,
                "label": "Elk Point",
                "label_ar": "إلك بوينت",
                "label_fr": "Elk Point"
            },
            {
                "value": 65216,
                "label": "Keene",
                "label_ar": "كين",
                "label_fr": "Keene"
            },
            {
                "value": 65021,
                "label": "Nanoose Bay",
                "label_ar": "خليج النانوس",
                "label_fr": "Nanoose Bay"
            },
            {
                "value": 64107,
                "label": "Bellevue",
                "label_ar": "بلفيو",
                "label_fr": "Bellevue"
            },
            {
                "value": 65156,
                "label": "Mallaig",
                "label_ar": "ملايج",
                "label_fr": "Mallaig"
            },
            {
                "value": 65509,
                "label": "Kinistino",
                "label_ar": "كينيستينو",
                "label_fr": "Kinistino"
            },
            {
                "value": 63750,
                "label": "Masset",
                "label_ar": "ماسيت",
                "label_fr": "Masset"
            },
            {
                "value": 64382,
                "label": "La Presentation",
                "label_ar": "عرض تقديمي",
                "label_fr": "La Présentation"
            },
            {
                "value": 64280,
                "label": "Brome",
                "label_ar": "بروم",
                "label_fr": "Brome"
            },
            {
                "value": 64292,
                "label": "Merritt",
                "label_ar": "ميريت",
                "label_fr": "Merritt"
            },
            {
                "value": 65402,
                "label": "Little Narrows",
                "label_ar": "يضيق قليلا",
                "label_fr": "Little Narrows"
            },
            {
                "value": 63892,
                "label": "Carstairs",
                "label_ar": "كارستيرز",
                "label_fr": "Carstairs"
            },
            {
                "value": 64225,
                "label": "Petawawa",
                "label_ar": "بيتاوا",
                "label_fr": "Petawawa"
            },
            {
                "value": 65373,
                "label": "Caraquet",
                "label_ar": "كاراكيه",
                "label_fr": "Caraquet"
            },
            {
                "value": 65028,
                "label": "Bow Island",
                "label_ar": "جزيرة بو",
                "label_fr": "Île Bow"
            },
            {
                "value": 65004,
                "label": "Saint-Anicet",
                "label_ar": "سانت أنيسيت",
                "label_fr": "Saint-Anicet"
            },
            {
                "value": 64739,
                "label": "Savona",
                "label_ar": "سافونا",
                "label_fr": "Savona"
            },
            {
                "value": 65554,
                "label": "Londonderry",
                "label_ar": "لندنديري",
                "label_fr": "Londonderry"
            },
            {
                "value": 64874,
                "label": "Grand-Remous",
                "label_ar": "غراند رموس",
                "label_fr": "Grand-Remous"
            },
            {
                "value": 63772,
                "label": "Boisbriand",
                "label_ar": "Boisbriand",
                "label_fr": "Boisbriand"
            },
            {
                "value": 65126,
                "label": "Berthier-Sur-Mer",
                "label_ar": "Berthier-Sur-Mer",
                "label_fr": "Berthier-sur-Mer"
            },
            {
                "value": 63962,
                "label": "Steinbach",
                "label_ar": "شتاينباخ",
                "label_fr": "Steinbach"
            },
            {
                "value": 65163,
                "label": "Sainte-Helene-de-Bagot",
                "label_ar": "سانت هيلين دي باجوت",
                "label_fr": "Sainte-Hélène-de-Bagot"
            },
            {
                "value": 64937,
                "label": "Courtland",
                "label_ar": "كورتلاند",
                "label_fr": "Courtland"
            },
            {
                "value": 65737,
                "label": "Dawson City",
                "label_ar": "داوسون سيتي",
                "label_fr": "Dawson City"
            },
            {
                "value": 65371,
                "label": "St. Joseph's",
                "label_ar": "سانت جوزيف",
                "label_fr": "Saint-Joseph"
            },
            {
                "value": 65714,
                "label": "Spillimacheen",
                "label_ar": "Spillimacheen",
                "label_fr": "Spillimacheen"
            },
            {
                "value": 65709,
                "label": "Pouce Coupe",
                "label_ar": "بوس كوبيه",
                "label_fr": "Pouce Coupe"
            },
            {
                "value": 64582,
                "label": "Bella Bella",
                "label_ar": "بيلا بيلا",
                "label_fr": "Bella Bella"
            },
            {
                "value": 63673,
                "label": "Mississauga",
                "label_ar": "ميسيسوجا",
                "label_fr": "Mississauga"
            },
            {
                "value": 63704,
                "label": "Spruce Grove",
                "label_ar": "سبروس غروف",
                "label_fr": "Spruce Grove"
            },
            {
                "value": 64164,
                "label": "Port Elgin",
                "label_ar": "بورت إلجين",
                "label_fr": "Port Elgin"
            },
            {
                "value": 65776,
                "label": "Moonbeam",
                "label_ar": "شعاع القمر",
                "label_fr": "Rayon de lune"
            },
            {
                "value": 64096,
                "label": "Trenton",
                "label_ar": "ترينتون",
                "label_fr": "Trenton"
            },
            {
                "value": 65567,
                "label": "South Mountain",
                "label_ar": "جنوب الجبل",
                "label_fr": "South Mountain"
            },
            {
                "value": 64040,
                "label": "Crabtree",
                "label_ar": "كرابتري",
                "label_fr": "Crabtree"
            },
            {
                "value": 65626,
                "label": "Tracadie",
                "label_ar": "تراكادي",
                "label_fr": "Tracadie"
            },
            {
                "value": 65352,
                "label": "Neepawa",
                "label_ar": "نيباوا",
                "label_fr": "Neepawa"
            },
            {
                "value": 65791,
                "label": "Barkerville",
                "label_ar": "باركرفيل",
                "label_fr": "Barkerville"
            },
            {
                "value": 65799,
                "label": "Field",
                "label_ar": "حقل",
                "label_fr": "Champ"
            },
            {
                "value": 64893,
                "label": "Langham",
                "label_ar": "لانغام",
                "label_fr": "Langham"
            },
            {
                "value": 64691,
                "label": "Morrisburg",
                "label_ar": "موريسبرج",
                "label_fr": "Morrisburg"
            },
            {
                "value": 64659,
                "label": "Arnprior",
                "label_ar": "ارنبريور",
                "label_fr": "Arnprior"
            },
            {
                "value": 63748,
                "label": "Port Clements",
                "label_ar": "ميناء كليمنتس",
                "label_fr": "Port Clements"
            },
            {
                "value": 64737,
                "label": "Cache Creek",
                "label_ar": "كاش كريك",
                "label_fr": "Cache Creek"
            },
            {
                "value": 64616,
                "label": "Bearskin Lake",
                "label_ar": "بحيرة بيرسكين",
                "label_fr": "Lac Bearskin"
            },
            {
                "value": 65664,
                "label": "New Aiyansh",
                "label_ar": "آيانش الجديدة",
                "label_fr": "Nouveau Aiyansh"
            },
            {
                "value": 65052,
                "label": "Plympton",
                "label_ar": "بليمبتون",
                "label_fr": "Plympton"
            },
            {
                "value": 65207,
                "label": "Victoria Harbour",
                "label_ar": "ميناء فيكتوريا",
                "label_fr": "Victoria Harbour"
            },
            {
                "value": 65516,
                "label": "Shigawake",
                "label_ar": "شيغاواكي",
                "label_fr": "Shigawake"
            },
            {
                "value": 64999,
                "label": "South Gower",
                "label_ar": "جنوب جاور",
                "label_fr": "South Gower"
            },
            {
                "value": 65397,
                "label": "Scoudouc",
                "label_ar": "سكودوك",
                "label_fr": "Scoudouc"
            },
            {
                "value": 63682,
                "label": "North Vancouver",
                "label_ar": "شمال فانكوفر",
                "label_fr": "North Vancouver"
            },
            {
                "value": 63803,
                "label": "Leonard",
                "label_ar": "ليونارد",
                "label_fr": "Léonard"
            },
            {
                "value": 65200,
                "label": "New Ross",
                "label_ar": "نيو روس",
                "label_fr": "Nouveau Ross"
            },
            {
                "value": 63983,
                "label": "Lunenburg",
                "label_ar": "لونينبورج",
                "label_fr": "Lunenburg"
            },
            {
                "value": 64568,
                "label": "Grenfell",
                "label_ar": "جرينفيل",
                "label_fr": "Grenfell"
            },
            {
                "value": 64324,
                "label": "Jordan Falls",
                "label_ar": "شلالات الأردن",
                "label_fr": "Jordan Falls"
            },
            {
                "value": 63917,
                "label": "Fairmont Hot Springs",
                "label_ar": "فيرمونت هوت سبرينجز",
                "label_fr": "Sources chaudes Fairmont"
            },
            {
                "value": 65025,
                "label": "Cowichan Bay",
                "label_ar": "خليج Cowichan",
                "label_fr": "Cowichan Bay"
            },
            {
                "value": 63958,
                "label": "Maple Creek",
                "label_ar": "مابل كريك",
                "label_fr": "Maple Creek"
            },
            {
                "value": 64057,
                "label": "Baie-Comeau",
                "label_ar": "باي كومو",
                "label_fr": "Baie-Comeau"
            },
            {
                "value": 65666,
                "label": "Pinantan Lake",
                "label_ar": "بحيرة بينانتان",
                "label_fr": "Lac Pinantan"
            },
            {
                "value": 64618,
                "label": "Ohsweken",
                "label_ar": "أوسويكن",
                "label_fr": "Ohsweken"
            },
            {
                "value": 64161,
                "label": "North Bay",
                "label_ar": "نورث باي",
                "label_fr": "North Bay"
            },
            {
                "value": 64238,
                "label": "Howick",
                "label_ar": "هوويك",
                "label_fr": "Howick"
            },
            {
                "value": 64878,
                "label": "Messines",
                "label_ar": "ميسينز",
                "label_fr": "Messines"
            },
            {
                "value": 65281,
                "label": "Sparta",
                "label_ar": "سبارتا",
                "label_fr": "Sparte"
            },
            {
                "value": 63931,
                "label": "Winfield",
                "label_ar": "وينفيلد",
                "label_fr": "Winfield"
            },
            {
                "value": 63982,
                "label": "Italy Cross",
                "label_ar": "ايطاليا كروس",
                "label_fr": "Croix d'Italie"
            },
            {
                "value": 63751,
                "label": "Ingersoll",
                "label_ar": "انجرسول",
                "label_fr": "Ingersoll"
            },
            {
                "value": 64380,
                "label": "Wickham",
                "label_ar": "ويكهام",
                "label_fr": "Wickham"
            },
            {
                "value": 64121,
                "label": "Arichat",
                "label_ar": "أريشات",
                "label_fr": "Arichat"
            },
            {
                "value": 64707,
                "label": "Wood Mountain",
                "label_ar": "وود ماونتن",
                "label_fr": "Wood Mountain"
            },
            {
                "value": 65031,
                "label": "White River",
                "label_ar": "النهر الأبيض",
                "label_fr": "White River"
            },
            {
                "value": 65192,
                "label": "Hay River",
                "label_ar": "نهر هاي",
                "label_fr": "Hay River"
            },
            {
                "value": 65679,
                "label": "Arviat",
                "label_ar": "ارفيات",
                "label_fr": "Arviat"
            },
            {
                "value": 64290,
                "label": "Prince George",
                "label_ar": "الأمير جورج",
                "label_fr": "Prince George"
            },
            {
                "value": 65658,
                "label": "Malton",
                "label_ar": "مالتون",
                "label_fr": "Malton"
            },
            {
                "value": 63941,
                "label": "Rosetown",
                "label_ar": "روزتاون",
                "label_fr": "Rosetown"
            },
            {
                "value": 63977,
                "label": "Nipawin",
                "label_ar": "نيبوين",
                "label_fr": "Nipawin"
            },
            {
                "value": 64682,
                "label": "Neudorf",
                "label_ar": "نيودورف",
                "label_fr": "Neudorf"
            },
            {
                "value": 65042,
                "label": "Lery",
                "label_ar": "ليري",
                "label_fr": "Lery"
            },
            {
                "value": 65043,
                "label": "Gore",
                "label_ar": "جور",
                "label_fr": "Sang"
            },
            {
                "value": 63733,
                "label": "Milton",
                "label_ar": "ميلتون",
                "label_fr": "Milton"
            },
            {
                "value": 65083,
                "label": "Point Edward",
                "label_ar": "نقطة إدوارد",
                "label_fr": "Point Edward"
            },
            {
                "value": 65134,
                "label": "Saint-Henri",
                "label_ar": "سان هنري",
                "label_fr": "Saint-Henri"
            },
            {
                "value": 63768,
                "label": "Jordan",
                "label_ar": "الأردن",
                "label_fr": "Jordan"
            },
            {
                "value": 65249,
                "label": "Oxbow",
                "label_ar": "Oxbow",
                "label_fr": "Oxbow"
            },
            {
                "value": 64541,
                "label": "Iqaluit",
                "label_ar": "إيكالويت",
                "label_fr": "Iqaluit"
            },
            {
                "value": 65617,
                "label": "Jenner",
                "label_ar": "جينر",
                "label_fr": "Jenner"
            },
            {
                "value": 65832,
                "label": "Whycocomagh",
                "label_ar": "Whycocomagh",
                "label_fr": "Whycocomagh"
            },
            {
                "value": 65646,
                "label": "Gillam",
                "label_ar": "جيلام",
                "label_fr": "Gillam"
            },
            {
                "value": 63967,
                "label": "Beausejour",
                "label_ar": "بوسيجور",
                "label_fr": "Beauséjour"
            },
            {
                "value": 64027,
                "label": "Stouffville",
                "label_ar": "ستوفيل",
                "label_fr": "Stouffville"
            },
            {
                "value": 65790,
                "label": "Grasmere",
                "label_ar": "جراسمير",
                "label_fr": "Grasmere"
            },
            {
                "value": 65849,
                "label": "Spring Hill",
                "label_ar": "تل الربيع",
                "label_fr": "Spring Hill"
            },
            {
                "value": 64895,
                "label": "Neilburg",
                "label_ar": "نيلبورغ",
                "label_fr": "Neilburg"
            },
            {
                "value": 63870,
                "label": "Port-Cartier",
                "label_ar": "بورت كارتييه",
                "label_fr": "Port-Cartier"
            },
            {
                "value": 65802,
                "label": "Kettleby",
                "label_ar": "Kettleby",
                "label_fr": "Kettleby"
            },
            {
                "value": 65489,
                "label": "Saint-Ubalde",
                "label_ar": "سان اوبالدي",
                "label_fr": "Saint-Ubalde"
            },
            {
                "value": 64175,
                "label": "Marmora",
                "label_ar": "مرمورا",
                "label_fr": "Marmora"
            },
            {
                "value": 65345,
                "label": "Glenwood",
                "label_ar": "جلينوود",
                "label_fr": "Glenwood"
            },
            {
                "value": 63877,
                "label": "Belle River",
                "label_ar": "نهر بيل",
                "label_fr": "Belle River"
            },
            {
                "value": 64392,
                "label": "Levack",
                "label_ar": "ليفاك",
                "label_fr": "Levack"
            },
            {
                "value": 64093,
                "label": "Murray River",
                "label_ar": "نهر موراي",
                "label_fr": "Murray River"
            },
            {
                "value": 63940,
                "label": "Regina",
                "label_ar": "ريجينا",
                "label_fr": "Regina"
            },
            {
                "value": 63716,
                "label": "Midland",
                "label_ar": "ميدلاند",
                "label_fr": "Midland"
            },
            {
                "value": 65018,
                "label": "Clive",
                "label_ar": "كلايف",
                "label_fr": "Clive"
            },
            {
                "value": 63791,
                "label": "Sabrevois",
                "label_ar": "سابريفوا",
                "label_fr": "Sabrevois"
            },
            {
                "value": 64222,
                "label": "Saint-Louis-de-Gonzague",
                "label_ar": "سانت لويس دي جونزاغ",
                "label_fr": "Saint-Louis-de-Gonzague"
            },
            {
                "value": 63942,
                "label": "Canora",
                "label_ar": "كانورا",
                "label_fr": "Canora"
            },
            {
                "value": 65684,
                "label": "Cartwright",
                "label_ar": "كارترايت",
                "label_fr": "Cartwright"
            },
            {
                "value": 65770,
                "label": "Worsley",
                "label_ar": "ورسلي",
                "label_fr": "Worsley"
            },
            {
                "value": 65541,
                "label": "Wildwood",
                "label_ar": "خشب البرية",
                "label_fr": "Forêt sauvage"
            },
            {
                "value": 64745,
                "label": "Daveluyville",
                "label_ar": "دافيلويفيل",
                "label_fr": "Daveluyville"
            },
            {
                "value": 64654,
                "label": "Montreal East",
                "label_ar": "مونتريال الشرقية",
                "label_fr": "Montréal Est"
            },
            {
                "value": 63744,
                "label": "Joliette",
                "label_ar": "جولييت",
                "label_fr": "Joliette"
            },
            {
                "value": 64254,
                "label": "Contrecoeur",
                "label_ar": "Contrecoeur",
                "label_fr": "Contrecœur"
            },
            {
                "value": 65473,
                "label": "Fort Vermilion",
                "label_ar": "فورت فيرمليون",
                "label_fr": "Fort Vermilion"
            },
            {
                "value": 64129,
                "label": "Mount Pearl",
                "label_ar": "جبل بيرل",
                "label_fr": "Mount Pearl"
            },
            {
                "value": 64636,
                "label": "Grand Valley",
                "label_ar": "جراند فالي",
                "label_fr": "Grand Valley"
            },
            {
                "value": 65598,
                "label": "Hafford",
                "label_ar": "هافورد",
                "label_fr": "Hafford"
            },
            {
                "value": 64901,
                "label": "Saint-Marc-de-Figuery",
                "label_ar": "سان مارك دي فيجيري",
                "label_fr": "Saint-Marc-de-Figuery"
            },
            {
                "value": 65067,
                "label": "Duck Lake",
                "label_ar": "بحيرة البطة",
                "label_fr": "Lac aux canards"
            },
            {
                "value": 65265,
                "label": "Havre-Saint-Pierre",
                "label_ar": "هافر سان بيير",
                "label_fr": "Havre-Saint-Pierre"
            },
            {
                "value": 63702,
                "label": "Red Deer",
                "label_ar": "غزال أحمر",
                "label_fr": "Red Deer"
            },
            {
                "value": 63851,
                "label": "Orleans",
                "label_ar": "اورليانز",
                "label_fr": "Orléans"
            },
            {
                "value": 65303,
                "label": "Raymore",
                "label_ar": "رايمور",
                "label_fr": "Raymore"
            },
            {
                "value": 64230,
                "label": "Lachute",
                "label_ar": "لاشوت",
                "label_fr": "Lachute"
            },
            {
                "value": 65657,
                "label": "Arva",
                "label_ar": "ارفا",
                "label_fr": "Arva"
            },
            {
                "value": 64105,
                "label": "Clarke's Beach",
                "label_ar": "شاطئ كلارك",
                "label_fr": "Plage de Clarke"
            },
            {
                "value": 65100,
                "label": "Lamont",
                "label_ar": "لامونت",
                "label_fr": "Lamont"
            },
            {
                "value": 64781,
                "label": "Willingdon",
                "label_ar": "ويلينجدون",
                "label_fr": "Willingdon"
            },
            {
                "value": 65499,
                "label": "Hobbema",
                "label_ar": "هوبيما",
                "label_fr": "Hobbema"
            },
            {
                "value": 65825,
                "label": "Melita",
                "label_ar": "ميليتا",
                "label_fr": "Melita"
            },
            {
                "value": 65009,
                "label": "Tring-Jonction",
                "label_ar": "ترينج جونكشن",
                "label_fr": "Tring-Jonction"
            },
            {
                "value": 64246,
                "label": "L'Epiphanie",
                "label_ar": "L'Epiphanie",
                "label_fr": "L'Épiphanie"
            },
            {
                "value": 64681,
                "label": "Galahad",
                "label_ar": "جلاهاد",
                "label_fr": "Galahad"
            },
            {
                "value": 64368,
                "label": "Happy Valley-Goose Bay",
                "label_ar": "هابي فالي-غوس باي",
                "label_fr": "Happy Valley-Goose Bay"
            },
            {
                "value": 63937,
                "label": "Grand Forks",
                "label_ar": "جراند فوركس",
                "label_fr": "Grand Forks"
            },
            {
                "value": 65529,
                "label": "Henryville",
                "label_ar": "Henryville",
                "label_fr": "Henryville"
            },
            {
                "value": 64587,
                "label": "Port Renfrew",
                "label_ar": "ميناء رينفرو",
                "label_fr": "Port Renfrew"
            },
            {
                "value": 64821,
                "label": "Ayton",
                "label_ar": "ايتون",
                "label_fr": "Ayton"
            },
            {
                "value": 65199,
                "label": "King's Point",
                "label_ar": "نقطة الملك",
                "label_fr": "King's Point"
            },
            {
                "value": 64694,
                "label": "Metis-sur-Mer",
                "label_ar": "متيس سور مير",
                "label_fr": "Métis-sur-Mer"
            },
            {
                "value": 65807,
                "label": "Ladysmith",
                "label_ar": "ليديسميث",
                "label_fr": "Ladysmith"
            },
            {
                "value": 63874,
                "label": "Shawinigan-Sud",
                "label_ar": "شاوينيجان سود",
                "label_fr": "Shawinigan-Sud"
            },
            {
                "value": 65003,
                "label": "Elmvale",
                "label_ar": "Elmvale",
                "label_fr": "Elmvale"
            },
            {
                "value": 65464,
                "label": "Torrington",
                "label_ar": "تورينجتون",
                "label_fr": "Torrington"
            },
            {
                "value": 65364,
                "label": "Fermeuse",
                "label_ar": "فيرميوز",
                "label_fr": "Fermeuse"
            },
            {
                "value": 65561,
                "label": "Cannington",
                "label_ar": "كانينجتون",
                "label_fr": "Cannington"
            },
            {
                "value": 63734,
                "label": "Pointe-Claire",
                "label_ar": "بوانت كلير",
                "label_fr": "Pointe-Claire"
            },
            {
                "value": 65624,
                "label": "Grande Cache",
                "label_ar": "غراندي كاش",
                "label_fr": "Grande Cache"
            },
            {
                "value": 63831,
                "label": "La Guadeloupe",
                "label_ar": "لا جوادلوب",
                "label_fr": "La Guadeloupe"
            },
            {
                "value": 65448,
                "label": "Lac-aux-Sables",
                "label_ar": "لاك أوكس سابلز",
                "label_fr": "Lac-aux-Sables"
            },
            {
                "value": 64141,
                "label": "Alliston",
                "label_ar": "أليستون",
                "label_fr": "Alliston"
            },
            {
                "value": 64073,
                "label": "Normandin",
                "label_ar": "نورماندين",
                "label_fr": "Normandin"
            },
            {
                "value": 64067,
                "label": "Aston-Jonction",
                "label_ar": "أستون جونكشن",
                "label_fr": "Aston-Jonction"
            },
            {
                "value": 65486,
                "label": "Quathiaski Cove",
                "label_ar": "Quathiaski كوف",
                "label_fr": "Quathiaski Cove"
            },
            {
                "value": 63686,
                "label": "Vernon",
                "label_ar": "فيرنون",
                "label_fr": "Vernon"
            },
            {
                "value": 65039,
                "label": "Saint-Felix-de-Valois",
                "label_ar": "سان فيليكس دي فالوا",
                "label_fr": "Saint-Félix-de-Valois"
            },
            {
                "value": 64816,
                "label": "Lyn",
                "label_ar": "لين",
                "label_fr": "Lyn"
            },
            {
                "value": 64738,
                "label": "Logan Lake",
                "label_ar": "بحيرة لوجان",
                "label_fr": "Lac Logan"
            },
            {
                "value": 64089,
                "label": "Bobcaygeon",
                "label_ar": "بوبكيجون",
                "label_fr": "Bobcaygeon"
            },
            {
                "value": 64985,
                "label": "Coboconk",
                "label_ar": "كوبوكونك",
                "label_fr": "Coboconk"
            },
            {
                "value": 65145,
                "label": "Kingsey Falls",
                "label_ar": "شلالات كينجسي",
                "label_fr": "Kingsey Falls"
            },
            {
                "value": 65431,
                "label": "Ferme-Neuve",
                "label_ar": "فيرمي نوف",
                "label_fr": "Ferme-Neuve"
            },
            {
                "value": 65232,
                "label": "Petitcodiac",
                "label_ar": "بيتيتكودياك",
                "label_fr": "Petitcodiac"
            },
            {
                "value": 63920,
                "label": "Okanagan Centre",
                "label_ar": "مركز أوكاناجان",
                "label_fr": "Centre de l'Okanagan"
            },
            {
                "value": 64983,
                "label": "Maidstone",
                "label_ar": "ميدستون",
                "label_fr": "Maidstone"
            },
            {
                "value": 64492,
                "label": "Bassano",
                "label_ar": "باسانو",
                "label_fr": "Bassano"
            },
            {
                "value": 63994,
                "label": "Beaver Bank",
                "label_ar": "بيفر بنك",
                "label_fr": "Beaver Bank"
            },
            {
                "value": 63828,
                "label": "Saint-Georges",
                "label_ar": "سان جورج",
                "label_fr": "Saint-Georges"
            },
            {
                "value": 64081,
                "label": "Sainte-Anne-de-la-Perade",
                "label_ar": "سانت آن دي لا بيراد",
                "label_fr": "Sainte-Anne-de-la-Pérade"
            },
            {
                "value": 64355,
                "label": "Burin",
                "label_ar": "بورين",
                "label_fr": "Burin"
            },
            {
                "value": 63680,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 64114,
                "label": "Amherst",
                "label_ar": "امهيرست",
                "label_fr": "Amherst"
            },
            {
                "value": 64838,
                "label": "St. Walburg",
                "label_ar": "سانت والبورج",
                "label_fr": "Saint Walburg"
            },
            {
                "value": 65806,
                "label": "Pointe-a-la-Croix",
                "label_ar": "بوانت لا كروا",
                "label_fr": "Pointe-à-la-Croix"
            },
            {
                "value": 65528,
                "label": "Sainte-Brigide-d'Iberville",
                "label_ar": "سانت بريجيد ديبرفيل",
                "label_fr": "Sainte-Brigide-d'Iberville"
            },
            {
                "value": 63996,
                "label": "Canfield",
                "label_ar": "كانفيلد",
                "label_fr": "Canfield"
            },
            {
                "value": 65376,
                "label": "Stoughton",
                "label_ar": "ستوتون",
                "label_fr": "Stoughton"
            },
            {
                "value": 65358,
                "label": "Heart's Content",
                "label_ar": "محتوى القلب",
                "label_fr": "Le contenu de coeur"
            },
            {
                "value": 65558,
                "label": "Pain Court",
                "label_ar": "محكمة الألم",
                "label_fr": "Cour de la douleur"
            },
            {
                "value": 64168,
                "label": "Palmerston",
                "label_ar": "بالمرستون",
                "label_fr": "Palmerston"
            },
            {
                "value": 65610,
                "label": "Tuktoyaktuk",
                "label_ar": "توكتوياكتوك",
                "label_fr": "Tuktoyaktuk"
            },
            {
                "value": 65749,
                "label": "Debden",
                "label_ar": "ديبدن",
                "label_fr": "Debden"
            },
            {
                "value": 65688,
                "label": "Gretna",
                "label_ar": "جريتنا",
                "label_fr": "Gretna"
            },
            {
                "value": 64742,
                "label": "Saint-Elie",
                "label_ar": "سانت إيلي",
                "label_fr": "Saint-Elie"
            },
            {
                "value": 65637,
                "label": "Norquay",
                "label_ar": "نوركواي",
                "label_fr": "Norquay"
            },
            {
                "value": 64637,
                "label": "Camlachie",
                "label_ar": "Camlachie",
                "label_fr": "Camlachie"
            },
            {
                "value": 64376,
                "label": "Victoriaville",
                "label_ar": "فيكتوريافيل",
                "label_fr": "Victoriaville"
            },
            {
                "value": 65118,
                "label": "Saint-Odilon-de-Cranbourne",
                "label_ar": "سان أوديلون دي كرانبورن",
                "label_fr": "Saint-Odilon-de-Cranbourne"
            },
            {
                "value": 65511,
                "label": "Saint-Antoine-sur-Richelieu",
                "label_ar": "سان أنطوان سور ريشيليو",
                "label_fr": "Saint-Antoine-sur-Richelieu"
            },
            {
                "value": 65547,
                "label": "Dresden",
                "label_ar": "دريسدن",
                "label_fr": "Dresde"
            },
            {
                "value": 63752,
                "label": "Alma",
                "label_ar": "ألما",
                "label_fr": "Alma"
            },
            {
                "value": 63767,
                "label": "Smithville",
                "label_ar": "سميثفيل",
                "label_fr": "Smithville"
            },
            {
                "value": 64007,
                "label": "Duncan",
                "label_ar": "دنكان",
                "label_fr": "Duncan"
            },
            {
                "value": 63960,
                "label": "Saint-Pierre-de-Broughton",
                "label_ar": "سان بيير دي بروتون",
                "label_fr": "Saint-Pierre-de-Broughton"
            },
            {
                "value": 64589,
                "label": "Skidegate",
                "label_ar": "سكيديجيت",
                "label_fr": "Skidegate"
            },
            {
                "value": 64814,
                "label": "Colborne",
                "label_ar": "كولبورن",
                "label_fr": "Colborne"
            },
            {
                "value": 63953,
                "label": "Weyburn",
                "label_ar": "ويبرن",
                "label_fr": "Weyburn"
            },
            {
                "value": 64303,
                "label": "Massueville",
                "label_ar": "ماسوفيل",
                "label_fr": "Massueville"
            },
            {
                "value": 64979,
                "label": "Newcastle Village",
                "label_ar": "قرية نيوكاسل",
                "label_fr": "Village de Newcastle"
            },
            {
                "value": 65603,
                "label": "Sorel-Tracy",
                "label_ar": "سوريل تريسي",
                "label_fr": "Sorel-Tracy"
            },
            {
                "value": 65588,
                "label": "Fabreville",
                "label_ar": "فابريفيل",
                "label_fr": "Fabreville"
            },
            {
                "value": 65333,
                "label": "LaHave",
                "label_ar": "لاهاف",
                "label_fr": "LaHave"
            },
            {
                "value": 65488,
                "label": "La Durantaye",
                "label_ar": "لا دورانتاي",
                "label_fr": "La Durantaye"
            },
            {
                "value": 64150,
                "label": "Chelmsford",
                "label_ar": "تشيلمسفورد",
                "label_fr": "Chelmsford"
            },
            {
                "value": 65670,
                "label": "Elma",
                "label_ar": "إلما",
                "label_fr": "Elma"
            },
            {
                "value": 63902,
                "label": "Barrhead",
                "label_ar": "بارهيد",
                "label_fr": "Barrhead"
            },
            {
                "value": 64260,
                "label": "Luskville",
                "label_ar": "لوسكفيل",
                "label_fr": "Luskville"
            },
            {
                "value": 64731,
                "label": "St. Andrews",
                "label_ar": "سانت أندروز",
                "label_fr": "Saint André"
            },
            {
                "value": 65158,
                "label": "Andrew",
                "label_ar": "أندرو",
                "label_fr": "Andrew"
            },
            {
                "value": 64629,
                "label": "Saint-Sebastien",
                "label_ar": "سان سيباستيان",
                "label_fr": "Saint-Sébastien"
            },
            {
                "value": 65597,
                "label": "Pascal",
                "label_ar": "باسكال",
                "label_fr": "Pascal"
            },
            {
                "value": 65047,
                "label": "Port au Port",
                "label_ar": "بورت أو بورت",
                "label_fr": "Port au Port"
            },
            {
                "value": 63912,
                "label": "Marysville",
                "label_ar": "ماريسفيل",
                "label_fr": "Marysville"
            },
            {
                "value": 64992,
                "label": "Tilbury",
                "label_ar": "التلبرية مركبة خفيفة ذات عجلتين",
                "label_fr": "Tilbury"
            },
            {
                "value": 63694,
                "label": "Kelowna",
                "label_ar": "كيلونا",
                "label_fr": "Kelowna"
            },
            {
                "value": 65580,
                "label": "Burnside",
                "label_ar": "بيرنسايد",
                "label_fr": "Burnside"
            },
            {
                "value": 64550,
                "label": "Stella",
                "label_ar": "ستيلا",
                "label_fr": "Stella"
            },
            {
                "value": 65789,
                "label": "McBride",
                "label_ar": "ماكبرايد",
                "label_fr": "McBride"
            },
            {
                "value": 65276,
                "label": "Torbay",
                "label_ar": "تورباي",
                "label_fr": "Torbay"
            },
            {
                "value": 64173,
                "label": "Napanee",
                "label_ar": "ناباني",
                "label_fr": "Napanee"
            },
            {
                "value": 65723,
                "label": "Stoneham",
                "label_ar": "ستونهام",
                "label_fr": "Stoneham"
            },
            {
                "value": 64922,
                "label": "Saint-Liboire",
                "label_ar": "سان ليبوار",
                "label_fr": "Saint-Liboire"
            },
            {
                "value": 65311,
                "label": "Saint-Luc",
                "label_ar": "سانت لوك",
                "label_fr": "Saint-Luc"
            },
            {
                "value": 65517,
                "label": "Port-Daniel",
                "label_ar": "بورت دانيال",
                "label_fr": "Port-Daniel"
            },
            {
                "value": 64806,
                "label": "Arundel",
                "label_ar": "أرونديل",
                "label_fr": "Arundel"
            },
            {
                "value": 65081,
                "label": "Cardston",
                "label_ar": "كاردستون",
                "label_fr": "Cardston"
            },
            {
                "value": 64724,
                "label": "Poplar Hill",
                "label_ar": "تل الحور",
                "label_fr": "Poplar Hill"
            },
            {
                "value": 63928,
                "label": "Comox",
                "label_ar": "كوموكس",
                "label_fr": "Comox"
            },
            {
                "value": 63814,
                "label": "North Perth",
                "label_ar": "شمال بيرث",
                "label_fr": "North Perth"
            },
            {
                "value": 64134,
                "label": "East Chezzetcook",
                "label_ar": "الشرق Chezzetcook",
                "label_fr": "East Chezzetcook"
            },
            {
                "value": 63889,
                "label": "Banff",
                "label_ar": "بانف",
                "label_fr": "Banff"
            },
            {
                "value": 64860,
                "label": "Southend Reindeer",
                "label_ar": "ساوثيند ريندير",
                "label_fr": "Renne de Southend"
            },
            {
                "value": 63926,
                "label": "Sooke",
                "label_ar": "سوك",
                "label_fr": "Sooke"
            },
            {
                "value": 65285,
                "label": "Meaford",
                "label_ar": "ميفورد",
                "label_fr": "Meaford"
            },
            {
                "value": 65139,
                "label": "McCreary",
                "label_ar": "ماكريري",
                "label_fr": "McCreary"
            },
            {
                "value": 65614,
                "label": "Youngstown",
                "label_ar": "يونجستاون",
                "label_fr": "Youngstown"
            },
            {
                "value": 64359,
                "label": "Hampton",
                "label_ar": "هامبتون",
                "label_fr": "Hampton"
            },
            {
                "value": 64428,
                "label": "Saint-Quentin",
                "label_ar": "سانت كوينتين",
                "label_fr": "Saint-Quentin"
            },
            {
                "value": 64190,
                "label": "Waterdown",
                "label_ar": "نزول المطر",
                "label_fr": "Waterdown"
            },
            {
                "value": 65591,
                "label": "Minnedosa",
                "label_ar": "مينيدوسا",
                "label_fr": "Minnedosa"
            },
            {
                "value": 65815,
                "label": "Roblin",
                "label_ar": "روبلين",
                "label_fr": "Roblin"
            },
            {
                "value": 64012,
                "label": "Powell River",
                "label_ar": "نهر باول",
                "label_fr": "Powell River"
            },
            {
                "value": 64475,
                "label": "Squamish",
                "label_ar": "سكواميش",
                "label_fr": "Squamish"
            },
            {
                "value": 65259,
                "label": "Wallace",
                "label_ar": "والاس",
                "label_fr": "Wallace"
            },
            {
                "value": 64702,
                "label": "Nampa",
                "label_ar": "نامبا",
                "label_fr": "Nampa"
            },
            {
                "value": 65552,
                "label": "Senneville",
                "label_ar": "سينفيل",
                "label_fr": "Senneville"
            },
            {
                "value": 65071,
                "label": "Dinsmore",
                "label_ar": "دينسمور",
                "label_fr": "Dinsmore"
            },
            {
                "value": 64357,
                "label": "West Lake",
                "label_ar": "البحيرة الغربية",
                "label_fr": "lac de l'Ouest"
            },
            {
                "value": 64588,
                "label": "Nakusp",
                "label_ar": "ناكوسب",
                "label_fr": "Nakusp"
            },
            {
                "value": 64719,
                "label": "L'Islet",
                "label_ar": "ليسليت",
                "label_fr": "L'Islet"
            },
            {
                "value": 64322,
                "label": "Cavendish",
                "label_ar": "كافنديش",
                "label_fr": "Cavendish"
            },
            {
                "value": 65104,
                "label": "Irma",
                "label_ar": "إيرما",
                "label_fr": "Irma"
            },
            {
                "value": 63883,
                "label": "Harrow",
                "label_ar": "مسلفة",
                "label_fr": "Herse"
            },
            {
                "value": 65069,
                "label": "Hudson Bay",
                "label_ar": "خليج هدسون",
                "label_fr": "Baie d'Hudson"
            },
            {
                "value": 65421,
                "label": "Renous",
                "label_ar": "رينو",
                "label_fr": "Renous"
            },
            {
                "value": 64250,
                "label": "Saint-Jean-de-l'Ile-d'Orleans",
                "label_ar": "سان جان دي ليل دورلين",
                "label_fr": "Saint-Jean-de-l'Ile-d'Orléans"
            },
            {
                "value": 64904,
                "label": "Keewatin",
                "label_ar": "كيواتين",
                "label_fr": "Keewatin"
            },
            {
                "value": 65023,
                "label": "Honeymoon Bay",
                "label_ar": "خليج شهر العسل",
                "label_fr": "Honeymoon Bay"
            },
            {
                "value": 64049,
                "label": "Saint-Barthelemy",
                "label_ar": "سانت بارتيليمي",
                "label_fr": "Saint-Barthélemy"
            },
            {
                "value": 65502,
                "label": "Breton",
                "label_ar": "بريتون",
                "label_fr": "Breton"
            },
            {
                "value": 64068,
                "label": "Sainte-Perpetue",
                "label_ar": "سانت بربتو",
                "label_fr": "Sainte-Perpétue"
            },
            {
                "value": 64103,
                "label": "Brookfield",
                "label_ar": "بروكفيلد",
                "label_fr": "Brookfield"
            },
            {
                "value": 64957,
                "label": "Riviere-Saint-Paul",
                "label_ar": "ريفيير سانت بول",
                "label_fr": "Rivière-Saint-Paul"
            },
            {
                "value": 64946,
                "label": "Quaqtaq",
                "label_ar": "ققاطاق",
                "label_fr": "Quaqtaq"
            },
            {
                "value": 65762,
                "label": "Saint-Malachie",
                "label_ar": "سان مالاتشي",
                "label_fr": "Saint-Malachie"
            },
            {
                "value": 65717,
                "label": "Lemberg",
                "label_ar": "ليمبيرج",
                "label_fr": "Lemberg"
            },
            {
                "value": 65007,
                "label": "Clarkson",
                "label_ar": "كلاركسون",
                "label_fr": "Clarkson"
            },
            {
                "value": 63742,
                "label": "Charlesbourg",
                "label_ar": "شارلسبورج",
                "label_fr": "Charlesbourg"
            },
            {
                "value": 65049,
                "label": "Musquodoboit Harbour",
                "label_ar": "ميناء Musquodoboit",
                "label_fr": "Port de Musquodoboit"
            },
            {
                "value": 64066,
                "label": "Deschaillons-sur-Saint-Laurent",
                "label_ar": "ديشايلون سور سان لوران",
                "label_fr": "Deschaillons-sur-Saint-Laurent"
            },
            {
                "value": 64545,
                "label": "Delhi",
                "label_ar": "دلهي",
                "label_fr": "Delhi"
            },
            {
                "value": 64800,
                "label": "Don Mills",
                "label_ar": "دون ميلز",
                "label_fr": "Don Mills"
            },
            {
                "value": 65399,
                "label": "Oak Point",
                "label_ar": "أوك بوينت",
                "label_fr": "Oak Point"
            },
            {
                "value": 64984,
                "label": "Washago",
                "label_ar": "Washago",
                "label_fr": "Washago"
            },
            {
                "value": 63745,
                "label": "Saint-Felicien",
                "label_ar": "سانت فيليسيان",
                "label_fr": "Saint-Félicien"
            },
            {
                "value": 65782,
                "label": "Rhein",
                "label_ar": "راين",
                "label_fr": "Rhein"
            },
            {
                "value": 65706,
                "label": "Grand Rapids",
                "label_ar": "المنحدرات الكبرى",
                "label_fr": "Grand Rapids"
            },
            {
                "value": 64725,
                "label": "Fort Hope",
                "label_ar": "فورت هوب",
                "label_fr": "Fort Hope"
            },
            {
                "value": 64276,
                "label": "Vallee-Jonction",
                "label_ar": "فالي جونكشن",
                "label_fr": "Vallée-Jonction"
            },
            {
                "value": 63738,
                "label": "Lasalle",
                "label_ar": "لا سال",
                "label_fr": "La salle"
            },
            {
                "value": 63858,
                "label": "Canning",
                "label_ar": "تعليب",
                "label_fr": "Mise en conserve"
            },
            {
                "value": 64669,
                "label": "Fort Frances",
                "label_ar": "حصن فرانسيس",
                "label_fr": "Fort Frances"
            },
            {
                "value": 65194,
                "label": "Fort Simpson",
                "label_ar": "فورت سيمبسون",
                "label_fr": "Fort Simpson"
            },
            {
                "value": 64918,
                "label": "Saint-Severe",
                "label_ar": "سان سيفير",
                "label_fr": "Saint-Severe"
            },
            {
                "value": 65229,
                "label": "Roddickton",
                "label_ar": "روديكتون",
                "label_fr": "Roddickton"
            },
            {
                "value": 63737,
                "label": "Gatineau",
                "label_ar": "جاتينو",
                "label_fr": "Gatineau"
            },
            {
                "value": 64197,
                "label": "Clairmont",
                "label_ar": "كليرمونت",
                "label_fr": "Clairmont"
            },
            {
                "value": 65859,
                "label": "Saint-Benjamin",
                "label_ar": "سانت بنيامين",
                "label_fr": "Saint-Benjamin"
            },
            {
                "value": 65654,
                "label": "Kinuso",
                "label_ar": "كينوسو",
                "label_fr": "Kinuso"
            },
            {
                "value": 64970,
                "label": "Williamsburg",
                "label_ar": "وليامزبيرج",
                "label_fr": "Williamsburg"
            },
            {
                "value": 65524,
                "label": "St. Peters Bay",
                "label_ar": "سانت بيترز باي",
                "label_fr": "Baie de St. Peters"
            },
            {
                "value": 64184,
                "label": "Fort-Coulonge",
                "label_ar": "فورت كولونج",
                "label_fr": "Fort-Coulonge"
            },
            {
                "value": 64364,
                "label": "Saulnierville",
                "label_ar": "Saulnierville",
                "label_fr": "Saulnierville"
            },
            {
                "value": 64756,
                "label": "Lotbiniere",
                "label_ar": "Lotbiniere",
                "label_fr": "Lotbinière"
            },
            {
                "value": 65006,
                "label": "Manotick Station",
                "label_ar": "محطة مانوتيك",
                "label_fr": "Gare de Manotick"
            },
            {
                "value": 65329,
                "label": "Central Bedeque",
                "label_ar": "سنترال بيدكي",
                "label_fr": "Bedeque central"
            },
            {
                "value": 64484,
                "label": "Sexsmith",
                "label_ar": "صائغ",
                "label_fr": "Sexsmith"
            },
            {
                "value": 63836,
                "label": "Rimouski",
                "label_ar": "ريموسكي",
                "label_fr": "Rimouski"
            },
            {
                "value": 64645,
                "label": "Kugluktuk",
                "label_ar": "Kugluktuk",
                "label_fr": "Kugluktuk"
            },
            {
                "value": 64485,
                "label": "McLennan",
                "label_ar": "ماكلينان",
                "label_fr": "McLennan"
            },
            {
                "value": 64035,
                "label": "Orillia",
                "label_ar": "أوريليا",
                "label_fr": "Orillia"
            },
            {
                "value": 64540,
                "label": "Earlton",
                "label_ar": "إيرلتون",
                "label_fr": "Earlton"
            },
            {
                "value": 64467,
                "label": "Tappen",
                "label_ar": "تابين",
                "label_fr": "Tappen"
            },
            {
                "value": 65764,
                "label": "Carseland",
                "label_ar": "كارسيلاند",
                "label_fr": "Carseland"
            },
            {
                "value": 65507,
                "label": "Whitemouth",
                "label_ar": "ويتماوث",
                "label_fr": "Whitemouth"
            },
            {
                "value": 65687,
                "label": "Treherne",
                "label_ar": "تريهيرن",
                "label_fr": "Treherne"
            },
            {
                "value": 65510,
                "label": "Birch Hills",
                "label_ar": "تلال بيرش",
                "label_fr": "Birch Hills"
            },
            {
                "value": 63894,
                "label": "Pincher Creek",
                "label_ar": "بينشر كريك",
                "label_fr": "Pincher Creek"
            },
            {
                "value": 64865,
                "label": "Cupar",
                "label_ar": "كوبار",
                "label_fr": "Cupar"
            },
            {
                "value": 65470,
                "label": "Gleichen",
                "label_ar": "جليشن",
                "label_fr": "Gleichen"
            },
            {
                "value": 65213,
                "label": "Warkworth",
                "label_ar": "واركورث",
                "label_fr": "Warkworth"
            },
            {
                "value": 65450,
                "label": "Saint-Come--Liniere",
                "label_ar": "سان تعال - لينير",
                "label_fr": "Saint-Come - Linière"
            },
            {
                "value": 65595,
                "label": "Coburg",
                "label_ar": "كوبورغ",
                "label_fr": "Cobourg"
            },
            {
                "value": 65518,
                "label": "Saint-Camille",
                "label_ar": "سان كميل",
                "label_fr": "Saint-Camille"
            },
            {
                "value": 64547,
                "label": "Gananoque",
                "label_ar": "جانانوك",
                "label_fr": "Gananoque"
            },
            {
                "value": 65514,
                "label": "Glovertown",
                "label_ar": "جلوفيرتاون",
                "label_fr": "Glovertown"
            },
            {
                "value": 65196,
                "label": "Whitehorse",
                "label_ar": "حصان أبيض",
                "label_fr": "Cheval Blanc"
            },
            {
                "value": 65582,
                "label": "Waterloo",
                "label_ar": "واترلو",
                "label_fr": "Waterloo"
            },
            {
                "value": 65218,
                "label": "Carignan",
                "label_ar": "كارينيان",
                "label_fr": "Carignan"
            },
            {
                "value": 64043,
                "label": "Rawdon",
                "label_ar": "راودون",
                "label_fr": "Rawdon"
            },
            {
                "value": 64501,
                "label": "Magrath",
                "label_ar": "ماجراث",
                "label_fr": "Magrath"
            },
            {
                "value": 64848,
                "label": "Milden",
                "label_ar": "ميلدن",
                "label_fr": "Milden"
            },
            {
                "value": 63859,
                "label": "Wolfville",
                "label_ar": "ولففيل",
                "label_fr": "Wolfville"
            },
            {
                "value": 63842,
                "label": "Sainte-Adele",
                "label_ar": "سانت أديل",
                "label_fr": "Sainte-Adele"
            },
            {
                "value": 64273,
                "label": "Portneuf",
                "label_ar": "بورتنوف",
                "label_fr": "Portneuf"
            },
            {
                "value": 64945,
                "label": "Beaver Crossing",
                "label_ar": "معبر بيفر",
                "label_fr": "Traversée du castor"
            },
            {
                "value": 65645,
                "label": "Moose Lake",
                "label_ar": "بحيرة موس",
                "label_fr": "Lac Moose"
            },
            {
                "value": 65420,
                "label": "Margaree Valley",
                "label_ar": "وادي مارجاري",
                "label_fr": "Vallée de Margaree"
            },
            {
                "value": 65643,
                "label": "Churchill",
                "label_ar": "تشرشل",
                "label_fr": "Churchill"
            },
            {
                "value": 63840,
                "label": "Shawinigan",
                "label_ar": "شاوينيجان",
                "label_fr": "Shawinigan"
            },
            {
                "value": 65526,
                "label": "Saint-Nazaire",
                "label_ar": "سان نازير",
                "label_fr": "Saint-Nazaire"
            },
            {
                "value": 64203,
                "label": "Kingston",
                "label_ar": "كينغستون",
                "label_fr": "Kingston"
            },
            {
                "value": 65337,
                "label": "Caledonia",
                "label_ar": "كاليدونيا",
                "label_fr": "Calédonie"
            },
            {
                "value": 65476,
                "label": "Eganville",
                "label_ar": "إغانفيل",
                "label_fr": "Eganville"
            },
            {
                "value": 65701,
                "label": "Coutts",
                "label_ar": "كوتس",
                "label_fr": "Coutts"
            },
            {
                "value": 65843,
                "label": "Young",
                "label_ar": "شاب",
                "label_fr": "Jeune"
            },
            {
                "value": 64599,
                "label": "Atikameg",
                "label_ar": "أتيكاميج",
                "label_fr": "Atikameg"
            },
            {
                "value": 63871,
                "label": "Sept-Iles",
                "label_ar": "سبتمبر ايليس",
                "label_fr": "Sept-Iles"
            },
            {
                "value": 64762,
                "label": "Fort Macleod",
                "label_ar": "فورت ماكلويد",
                "label_fr": "Fort Macleod"
            },
            {
                "value": 63908,
                "label": "Jasper",
                "label_ar": "يشب",
                "label_fr": "Jaspe"
            },
            {
                "value": 64336,
                "label": "Black Diamond",
                "label_ar": "الماس الأسود",
                "label_fr": "Diamant noir"
            },
            {
                "value": 64342,
                "label": "Bridgetown",
                "label_ar": "بريدجتاون",
                "label_fr": "Bridgetown"
            },
            {
                "value": 65234,
                "label": "Waweig",
                "label_ar": "Waweig",
                "label_fr": "Waweig"
            },
            {
                "value": 64231,
                "label": "Saint-Basile-le-Grand",
                "label_ar": "سان باسيل لو جراند",
                "label_fr": "Saint-Basile-le-Grand"
            },
            {
                "value": 65041,
                "label": "Sainte-Martine",
                "label_ar": "سانت مارتين",
                "label_fr": "Sainte-Martine"
            },
            {
                "value": 63761,
                "label": "Caledonia",
                "label_ar": "كاليدونيا",
                "label_fr": "Calédonie"
            },
            {
                "value": 65012,
                "label": "Plantagenet Station",
                "label_ar": "محطة بلانتاجنيت",
                "label_fr": "Station Plantagenet"
            },
            {
                "value": 64508,
                "label": "Sarnia",
                "label_ar": "سارنيا",
                "label_fr": "Sarnia"
            },
            {
                "value": 65557,
                "label": "Rossburn",
                "label_ar": "روسبيرن",
                "label_fr": "Rossburn"
            },
            {
                "value": 65583,
                "label": "Saint-Paul",
                "label_ar": "القديس بول",
                "label_fr": "Saint Paul"
            },
            {
                "value": 65438,
                "label": "Cap-aux-Meules",
                "label_ar": "كاب-أوكس-ميولز",
                "label_fr": "Cap-aux-Meules"
            },
            {
                "value": 65585,
                "label": "Brigham",
                "label_ar": "بريغهام",
                "label_fr": "Brigham"
            },
            {
                "value": 65015,
                "label": "Olds",
                "label_ar": "قديم",
                "label_fr": "Olds"
            },
            {
                "value": 65306,
                "label": "Brentwood Bay",
                "label_ar": "خليج برينتوود",
                "label_fr": "Brentwood Bay"
            },
            {
                "value": 65811,
                "label": "Searchmont",
                "label_ar": "البحث",
                "label_fr": "Searchmont"
            },
            {
                "value": 64765,
                "label": "Hythe",
                "label_ar": "Hythe",
                "label_fr": "Hythe"
            },
            {
                "value": 63872,
                "label": "Montmagny",
                "label_ar": "مونماجني",
                "label_fr": "Montmagny"
            },
            {
                "value": 64305,
                "label": "Malartic",
                "label_ar": "مالارتيك",
                "label_fr": "Malartic"
            },
            {
                "value": 65197,
                "label": "Inuvik",
                "label_ar": "إينوفيك",
                "label_fr": "Inuvik"
            },
            {
                "value": 65298,
                "label": "St. Jacobs",
                "label_ar": "سانت جاكوبس",
                "label_fr": "Saint-Jacobs"
            },
            {
                "value": 64085,
                "label": "Osoyoos",
                "label_ar": "أوسويوس",
                "label_fr": "Osoyoos"
            },
            {
                "value": 64903,
                "label": "Kenora",
                "label_ar": "كينورا",
                "label_fr": "Kenora"
            },
            {
                "value": 63705,
                "label": "Sherwood Park",
                "label_ar": "شيروود بارك",
                "label_fr": "Sherwood Park"
            },
            {
                "value": 64857,
                "label": "Teeswater",
                "label_ar": "تيزووتر",
                "label_fr": "Teeswater"
            },
            {
                "value": 63898,
                "label": "Brooks",
                "label_ar": "بروكس",
                "label_fr": "Brooks"
            },
            {
                "value": 64975,
                "label": "Sundridge",
                "label_ar": "سندريدج",
                "label_fr": "Sundridge"
            },
            {
                "value": 64558,
                "label": "Gilbert Plains",
                "label_ar": "جيلبرت بلينز",
                "label_fr": "Gilbert Plains"
            },
            {
                "value": 64722,
                "label": "Deer Lake",
                "label_ar": "بحيرة الغزلان",
                "label_fr": "Deer Lake"
            },
            {
                "value": 64204,
                "label": "Three Mile Plains",
                "label_ar": "ثري مايل بلينز",
                "label_fr": "Three Mile Plains"
            },
            {
                "value": 63907,
                "label": "Hinton",
                "label_ar": "هينتون",
                "label_fr": "Hinton"
            },
            {
                "value": 64802,
                "label": "Erin",
                "label_ar": "ايرين",
                "label_fr": "Erin"
            },
            {
                "value": 64215,
                "label": "Eastman",
                "label_ar": "ايستمان",
                "label_fr": "Eastman"
            },
            {
                "value": 64554,
                "label": "Oil Springs",
                "label_ar": "الينابيع الزيتية",
                "label_fr": "Ressorts à huile"
            },
            {
                "value": 64488,
                "label": "Beaverlodge",
                "label_ar": "بيفرلودج",
                "label_fr": "Beaverlodge"
            },
            {
                "value": 64630,
                "label": "Nantes",
                "label_ar": "نانت",
                "label_fr": "Nantes"
            },
            {
                "value": 64834,
                "label": "Lafleche",
                "label_ar": "لافليتشي",
                "label_fr": "Laflèche"
            },
            {
                "value": 63880,
                "label": "Amherstburg",
                "label_ar": "امهيرستبرج",
                "label_fr": "Amherstburg"
            },
            {
                "value": 64110,
                "label": "Summerside",
                "label_ar": "سامرسايد",
                "label_fr": "Summerside"
            },
            {
                "value": 65315,
                "label": "Sainte-Anne-de-Beaupre",
                "label_ar": "سانت آن دي بوبر",
                "label_fr": "Sainte-Anne-de-Beaupré"
            },
            {
                "value": 63890,
                "label": "Canmore",
                "label_ar": "كانمور",
                "label_fr": "Canmore"
            },
            {
                "value": 64817,
                "label": "Athens",
                "label_ar": "أثينا",
                "label_fr": "Athènes"
            },
            {
                "value": 64539,
                "label": "Matheson",
                "label_ar": "ماثيسون",
                "label_fr": "Matheson"
            },
            {
                "value": 64209,
                "label": "Gravenhurst",
                "label_ar": "جرافنهيرست",
                "label_fr": "Gravenhurst"
            },
            {
                "value": 64016,
                "label": "Wawa",
                "label_ar": "واوا",
                "label_fr": "Wawa"
            },
            {
                "value": 64410,
                "label": "Roseneath",
                "label_ar": "روزينث",
                "label_fr": "Roseneath"
            },
            {
                "value": 63762,
                "label": "Burlington",
                "label_ar": "برلنغتون",
                "label_fr": "Burlington"
            },
            {
                "value": 64906,
                "label": "Shoal Lake",
                "label_ar": "بحيرة شول",
                "label_fr": "Lac Shoal"
            },
            {
                "value": 64042,
                "label": "Beloeil",
                "label_ar": "بيلويل",
                "label_fr": "Beloeil"
            },
            {
                "value": 64320,
                "label": "Middle Musquodoboit",
                "label_ar": "موسكودوبويت الأوسط",
                "label_fr": "Milieu Musquodoboit"
            },
            {
                "value": 65747,
                "label": "Spy Hill",
                "label_ar": "جاسوس هيل",
                "label_fr": "Spy Hill"
            },
            {
                "value": 65632,
                "label": "Moffat",
                "label_ar": "موفات",
                "label_fr": "Moffat"
            },
            {
                "value": 64653,
                "label": "Masson-Angers",
                "label_ar": "ماسون انجيه",
                "label_fr": "Masson-Angers"
            },
            {
                "value": 64609,
                "label": "Sioux Lookout",
                "label_ar": "مرصد سيوكس",
                "label_fr": "Sioux Lookout"
            },
            {
                "value": 65264,
                "label": "Laurier-Station",
                "label_ar": "محطة لوريير",
                "label_fr": "Laurier-Station"
            },
            {
                "value": 65093,
                "label": "Elmira",
                "label_ar": "الميرا",
                "label_fr": "Elmira"
            },
            {
                "value": 65425,
                "label": "Mont-Laurier",
                "label_ar": "مونت لورييه",
                "label_fr": "Mont-Laurier"
            },
            {
                "value": 64172,
                "label": "Kingston",
                "label_ar": "كينغستون",
                "label_fr": "Kingston"
            },
            {
                "value": 63968,
                "label": "Thompson",
                "label_ar": "طومسون",
                "label_fr": "Thompson"
            },
            {
                "value": 64429,
                "label": "Plaster Rock",
                "label_ar": "صخرة الجص",
                "label_fr": "Roche de plâtre"
            },
            {
                "value": 64259,
                "label": "Saint John",
                "label_ar": "القديس يوحنا",
                "label_fr": "Saint Jean"
            },
            {
                "value": 64191,
                "label": "Carlisle",
                "label_ar": "كارلايل",
                "label_fr": "Carlisle"
            },
            {
                "value": 64709,
                "label": "Saint-Colomban",
                "label_ar": "سان كولومبان",
                "label_fr": "Saint-Colomban"
            },
            {
                "value": 64751,
                "label": "Huron East",
                "label_ar": "هورون إيست",
                "label_fr": "Huron Est"
            },
            {
                "value": 65247,
                "label": "Cumberland Bay",
                "label_ar": "خليج كمبرلاند",
                "label_fr": "Baie de Cumberland"
            },
            {
                "value": 64601,
                "label": "Bashaw",
                "label_ar": "باشاو",
                "label_fr": "Bashaw"
            },
            {
                "value": 65171,
                "label": "Grande-Riviere",
                "label_ar": "غراندي ريفيير",
                "label_fr": "Grande-Rivière"
            },
            {
                "value": 64940,
                "label": "Saint-Charles-sur-Richelieu",
                "label_ar": "سان شارل سور ريشيليو",
                "label_fr": "Saint-Charles-sur-Richelieu"
            },
            {
                "value": 65681,
                "label": "Saint-Bruno",
                "label_ar": "سان برونو",
                "label_fr": "Saint-Bruno"
            },
            {
                "value": 64471,
                "label": "Onoway",
                "label_ar": "أونواي",
                "label_fr": "Onoway"
            },
            {
                "value": 63729,
                "label": "Brampton",
                "label_ar": "برامبتون",
                "label_fr": "Brampton"
            },
            {
                "value": 65138,
                "label": "Saint-Godefroi",
                "label_ar": "سان جودفروي",
                "label_fr": "Saint-Godefroi"
            },
            {
                "value": 64189,
                "label": "Dundas",
                "label_ar": "دونداس",
                "label_fr": "Dundas"
            },
            {
                "value": 65377,
                "label": "Fillmore",
                "label_ar": "فيلمور",
                "label_fr": "Fillmore"
            },
            {
                "value": 65347,
                "label": "Pasadena",
                "label_ar": "باسادينا",
                "label_fr": "Pasadena"
            },
            {
                "value": 65496,
                "label": "Hardisty",
                "label_ar": "هارديستي",
                "label_fr": "Hardisty"
            },
            {
                "value": 65845,
                "label": "St-Timothee",
                "label_ar": "سانت تيموثي",
                "label_fr": "St-Timothée"
            },
            {
                "value": 64974,
                "label": "Smiths Falls",
                "label_ar": "سميث فولز",
                "label_fr": "Smiths Falls"
            },
            {
                "value": 64384,
                "label": "La Baie",
                "label_ar": "لا باي",
                "label_fr": "La Baie"
            },
            {
                "value": 64710,
                "label": "Cookshire-Eaton",
                "label_ar": "كوكشاير إيتون",
                "label_fr": "Cookshire-Eaton"
            },
            {
                "value": 65379,
                "label": "Battleford",
                "label_ar": "باتلفورد",
                "label_fr": "Battleford"
            },
            {
                "value": 65487,
                "label": "Denman Island",
                "label_ar": "جزيرة دينمان",
                "label_fr": "Île Denman"
            },
            {
                "value": 64470,
                "label": "Fort St. John",
                "label_ar": "فورت سانت جون",
                "label_fr": "Fort St. John"
            },
            {
                "value": 65437,
                "label": "Palmarolle",
                "label_ar": "بالمارول",
                "label_fr": "Palmarolle"
            },
            {
                "value": 63943,
                "label": "Kindersley",
                "label_ar": "كيندرسلي",
                "label_fr": "Kindersley"
            },
            {
                "value": 63676,
                "label": "Richmond",
                "label_ar": "ريتشموند",
                "label_fr": "Richmond"
            },
            {
                "value": 64162,
                "label": "Aylmer",
                "label_ar": "ايلمر",
                "label_fr": "Aylmer"
            },
            {
                "value": 64171,
                "label": "Chesley",
                "label_ar": "تشيسلي",
                "label_fr": "Chesley"
            },
            {
                "value": 64936,
                "label": "Mount Elgin",
                "label_ar": "جبل الجين",
                "label_fr": "Mont Elgin"
            },
            {
                "value": 65660,
                "label": "Pontypool",
                "label_ar": "بونتيبول",
                "label_fr": "Pontypool"
            },
            {
                "value": 65805,
                "label": "Saint-Urbain",
                "label_ar": "سان اوربان",
                "label_fr": "Saint-Urbain"
            },
            {
                "value": 64213,
                "label": "La Patrie",
                "label_ar": "لا باتري",
                "label_fr": "La Patrie"
            },
            {
                "value": 64678,
                "label": "Saint-Zotique",
                "label_ar": "سانت زوتيك",
                "label_fr": "Saint-Zotique"
            },
            {
                "value": 64727,
                "label": "Tasiujaq",
                "label_ar": "تاسيوجق",
                "label_fr": "Tasiujaq"
            },
            {
                "value": 65261,
                "label": "Pugwash",
                "label_ar": "بوجواش",
                "label_fr": "Pugwash"
            },
            {
                "value": 64776,
                "label": "Ripley",
                "label_ar": "ريبلي",
                "label_fr": "Ripley"
            },
            {
                "value": 65360,
                "label": "Bell Island",
                "label_ar": "جزيرة بيل",
                "label_fr": "Bell Island"
            },
            {
                "value": 65076,
                "label": "Knowlton",
                "label_ar": "نولتون",
                "label_fr": "Knowlton"
            },
            {
                "value": 65572,
                "label": "North Lunenburg",
                "label_ar": "شمال ونينبورج",
                "label_fr": "North Lunenburg"
            },
            {
                "value": 65553,
                "label": "Saint-Denis",
                "label_ar": "سان دوني",
                "label_fr": "Saint-Denis"
            },
            {
                "value": 63672,
                "label": "Lachine",
                "label_ar": "لاتشين",
                "label_fr": "Lachine"
            },
            {
                "value": 63720,
                "label": "Fergus",
                "label_ar": "فيرغوس",
                "label_fr": "Fergus"
            },
            {
                "value": 64477,
                "label": "Brackendale",
                "label_ar": "براكنديل",
                "label_fr": "Brackendale"
            },
            {
                "value": 64056,
                "label": "Gaspé",
                "label_ar": "جاسبي",
                "label_fr": "Gaspé"
            },
            {
                "value": 65284,
                "label": "Port McNicoll",
                "label_ar": "بورت مكنيكول",
                "label_fr": "Port McNicoll"
            },
            {
                "value": 64334,
                "label": "Holyrood",
                "label_ar": "هوليرود",
                "label_fr": "Holyrood"
            },
            {
                "value": 65661,
                "label": "Clarence",
                "label_ar": "كلارنس",
                "label_fr": "Clarence"
            },
            {
                "value": 65491,
                "label": "Inglewood",
                "label_ar": "إنجلوود",
                "label_fr": "Inglewood"
            },
            {
                "value": 64610,
                "label": "Kuujjuaq",
                "label_ar": "Kuujjuaq",
                "label_fr": "Kuujjuaq"
            },
            {
                "value": 63975,
                "label": "Biggar",
                "label_ar": "بيغار",
                "label_fr": "Biggar"
            },
            {
                "value": 65188,
                "label": "Esprit-Saint",
                "label_ar": "إسبريت سانت",
                "label_fr": "Esprit-Saint"
            },
            {
                "value": 64493,
                "label": "Innisfail",
                "label_ar": "إينيسيفيل",
                "label_fr": "Innisfail"
            },
            {
                "value": 65436,
                "label": "Angliers",
                "label_ar": "الزوايا",
                "label_fr": "Angliers"
            },
            {
                "value": 65374,
                "label": "Mobile",
                "label_ar": "التليفون المحمول",
                "label_fr": "Mobile"
            },
            {
                "value": 65521,
                "label": "Lourdes",
                "label_ar": "لورد",
                "label_fr": "Lourdes"
            },
            {
                "value": 65512,
                "label": "Clyde River",
                "label_ar": "نهر كلايد",
                "label_fr": "Clyde River"
            },
            {
                "value": 63903,
                "label": "Edson",
                "label_ar": "إدسون",
                "label_fr": "Edson"
            },
            {
                "value": 65355,
                "label": "Pilot Mound",
                "label_ar": "تل الطيار",
                "label_fr": "Pilote Mound"
            },
            {
                "value": 64265,
                "label": "Mercier",
                "label_ar": "ميرسير",
                "label_fr": "Mercier"
            },
            {
                "value": 65170,
                "label": "Newport",
                "label_ar": "نيوبورت",
                "label_fr": "Newport"
            },
            {
                "value": 65297,
                "label": "Flesherton",
                "label_ar": "فليشيرتون",
                "label_fr": "Flesherton"
            },
            {
                "value": 65412,
                "label": "Weldon",
                "label_ar": "ويلدون",
                "label_fr": "Weldon"
            },
            {
                "value": 64444,
                "label": "Grand Falls-Windsor",
                "label_ar": "جراند فولز وندسور",
                "label_fr": "Grand Falls-Windsor"
            },
            {
                "value": 65279,
                "label": "Maxville",
                "label_ar": "ماكسفيل",
                "label_fr": "Maxville"
            },
            {
                "value": 65564,
                "label": "Sainte-Marthe-sur-le-Lac",
                "label_ar": "Sainte-Marthe-sur-le-Lac",
                "label_fr": "Sainte-Marthe-sur-le-Lac"
            },
            {
                "value": 65092,
                "label": "Strathroy",
                "label_ar": "ستراثروي",
                "label_fr": "Strathroy"
            },
            {
                "value": 64736,
                "label": "Clearwater",
                "label_ar": "ماء نقي",
                "label_fr": "Eau claire"
            },
            {
                "value": 64871,
                "label": "Beaumont",
                "label_ar": "بومونت",
                "label_fr": "Beaumont"
            },
            {
                "value": 64548,
                "label": "Bath",
                "label_ar": "حمام",
                "label_fr": "Une baignoire"
            },
            {
                "value": 64454,
                "label": "Grand Bay–Westfield",
                "label_ar": "جراند باي - ويستفيلد",
                "label_fr": "Grand Baie – Westfield"
            },
            {
                "value": 65403,
                "label": "Kingsville",
                "label_ar": "كينجسفيل",
                "label_fr": "Kingsville"
            },
            {
                "value": 64569,
                "label": "Carlyle",
                "label_ar": "كارلايل",
                "label_fr": "Carlyle"
            },
            {
                "value": 65536,
                "label": "Allardville",
                "label_ar": "ألاردفيل",
                "label_fr": "Allardville"
            },
            {
                "value": 65095,
                "label": "Memramcook",
                "label_ar": "Memramcook",
                "label_fr": "Memramcook"
            },
            {
                "value": 63806,
                "label": "Saint-Agapit",
                "label_ar": "سان أغابيت",
                "label_fr": "Saint-Agapit"
            },
            {
                "value": 64124,
                "label": "Petit-de-Grat",
                "label_ar": "بيتي دي جرات",
                "label_fr": "Petit-de-Grat"
            },
            {
                "value": 63922,
                "label": "Chase",
                "label_ar": "مطاردة",
                "label_fr": "Chasse"
            },
            {
                "value": 64517,
                "label": "Kemptville",
                "label_ar": "كمبتفيل",
                "label_fr": "Kemptville"
            },
            {
                "value": 65312,
                "label": "Boischatel",
                "label_ar": "Boischatel",
                "label_fr": "Boischatel"
            },
            {
                "value": 65577,
                "label": "Islington",
                "label_ar": "إيسلينجتون",
                "label_fr": "Islington"
            },
            {
                "value": 64851,
                "label": "Buffalo Narrows",
                "label_ar": "بافلو ناروز",
                "label_fr": "Buffalo Narrows"
            },
            {
                "value": 64430,
                "label": "Neguac",
                "label_ar": "نيجواك",
                "label_fr": "Neguac"
            },
            {
                "value": 65214,
                "label": "Saint-Cyprien",
                "label_ar": "سان سيبريان",
                "label_fr": "Saint-Cyprien"
            },
            {
                "value": 65124,
                "label": "East Angus",
                "label_ar": "شرق انجوس",
                "label_fr": "East Angus"
            },
            {
                "value": 65767,
                "label": "Dugald",
                "label_ar": "دوجالد",
                "label_fr": "Dugald"
            },
            {
                "value": 64255,
                "label": "Maniwaki",
                "label_ar": "مانيواكي",
                "label_fr": "Maniwaki"
            },
            {
                "value": 64004,
                "label": "North Saanich",
                "label_ar": "شمال سانيش",
                "label_fr": "North Saanich"
            },
            {
                "value": 64294,
                "label": "Mackenzie",
                "label_ar": "ماكنزي",
                "label_fr": "Mackenzie"
            },
            {
                "value": 65324,
                "label": "Burns Lake",
                "label_ar": "بحيرة بيرنز",
                "label_fr": "Burns Lake"
            },
            {
                "value": 65248,
                "label": "Lanigan",
                "label_ar": "لانيجان",
                "label_fr": "Lanigan"
            },
            {
                "value": 64864,
                "label": "Southey",
                "label_ar": "سوثي",
                "label_fr": "Southey"
            },
            {
                "value": 64160,
                "label": "Sturgeon Falls",
                "label_ar": "شلالات الحفش",
                "label_fr": "Sturgeon Falls"
            },
            {
                "value": 65320,
                "label": "St-Hubert",
                "label_ar": "سانت هوبرت",
                "label_fr": "St-Hubert"
            },
            {
                "value": 65419,
                "label": "Ingonish",
                "label_ar": "إنغونيش",
                "label_fr": "Ingonish"
            },
            {
                "value": 65254,
                "label": "Newton",
                "label_ar": "نيوتن",
                "label_fr": "Newton"
            },
            {
                "value": 65186,
                "label": "Leslieville",
                "label_ar": "ليسليفيل",
                "label_fr": "Leslieville"
            },
            {
                "value": 64442,
                "label": "L'Anse-au-Loup",
                "label_ar": "L'Anse-au-Loup",
                "label_fr": "L'Anse-au-Loup"
            },
            {
                "value": 64602,
                "label": "Quesnel",
                "label_ar": "كويسنيل",
                "label_fr": "Quesnel"
            },
            {
                "value": 65479,
                "label": "Saint-Thomas",
                "label_ar": "سانت توماس",
                "label_fr": "Saint-Thomas"
            },
            {
                "value": 64663,
                "label": "Greely",
                "label_ar": "بشجاعة",
                "label_fr": "Greely"
            },
            {
                "value": 64443,
                "label": "Deer Lake",
                "label_ar": "بحيرة الغزلان",
                "label_fr": "Deer Lake"
            },
            {
                "value": 65113,
                "label": "Creemore",
                "label_ar": "كريمور",
                "label_fr": "Creemore"
            },
            {
                "value": 65725,
                "label": "Obedjiwan",
                "label_ar": "عبيدجوان",
                "label_fr": "Obedjiwan"
            },
            {
                "value": 63949,
                "label": "Unity",
                "label_ar": "وحدة",
                "label_fr": "Unité"
            },
            {
                "value": 65513,
                "label": "Hare Bay",
                "label_ar": "هير باي",
                "label_fr": "Baie de Hare"
            },
            {
                "value": 64900,
                "label": "Senneterre",
                "label_ar": "سينيتر",
                "label_fr": "Senneterre"
            },
            {
                "value": 65072,
                "label": "Carnduff",
                "label_ar": "كارندوف",
                "label_fr": "Carnduff"
            },
            {
                "value": 65741,
                "label": "Norman Wells",
                "label_ar": "نورمان ويلز",
                "label_fr": "Norman Wells"
            },
            {
                "value": 65774,
                "label": "Spirit River",
                "label_ar": "نهر الروح",
                "label_fr": "Spirit River"
            },
            {
                "value": 64413,
                "label": "Norway House",
                "label_ar": "بيت النرويج",
                "label_fr": "Norway House"
            },
            {
                "value": 65094,
                "label": "Thamesford",
                "label_ar": "ثاميسفورد",
                "label_fr": "Thamesford"
            },
            {
                "value": 65618,
                "label": "Clinton",
                "label_ar": "كلينتون",
                "label_fr": "Clinton"
            },
            {
                "value": 65698,
                "label": "Edam",
                "label_ar": "ايدام",
                "label_fr": "Edam"
            },
            {
                "value": 64924,
                "label": "Grand-Saint-Esprit",
                "label_ar": "جراند سان إسبريت",
                "label_fr": "Grand-Saint-Esprit"
            },
            {
                "value": 63804,
                "label": "Saint-Lin-Laurentides",
                "label_ar": "سان لين لورنتيدس",
                "label_fr": "Saint-Lin-Laurentides"
            },
            {
                "value": 64266,
                "label": "Sainte-Julienne",
                "label_ar": "سانت جوليان",
                "label_fr": "Sainte-Julienne"
            },
            {
                "value": 64744,
                "label": "Sainte-Monique",
                "label_ar": "سانت مونيك",
                "label_fr": "Sainte-Monique"
            },
            {
                "value": 64917,
                "label": "Lambton",
                "label_ar": "لامبتون",
                "label_fr": "Lambton"
            },
            {
                "value": 64823,
                "label": "Inwood",
                "label_ar": "في الخشب",
                "label_fr": "Inwood"
            },
            {
                "value": 65401,
                "label": "Mulgrave",
                "label_ar": "مولجراف",
                "label_fr": "Mulgrave"
            },
            {
                "value": 65141,
                "label": "Rivers",
                "label_ar": "الأنهار",
                "label_fr": "Rivières"
            },
            {
                "value": 64338,
                "label": "Three Hills",
                "label_ar": "ثلاثة تلال",
                "label_fr": "Trois collines"
            },
            {
                "value": 65477,
                "label": "Carleton-sur-Mer",
                "label_ar": "كارلتون سور مير",
                "label_fr": "Carleton-sur-Mer"
            },
            {
                "value": 63974,
                "label": "Mission",
                "label_ar": "مهمة",
                "label_fr": "Mission"
            },
            {
                "value": 64198,
                "label": "Bonnyville",
                "label_ar": "بونيفيل",
                "label_fr": "Bonnyville"
            },
            {
                "value": 65024,
                "label": "Youbou",
                "label_ar": "Youbou",
                "label_fr": "Youbou"
            },
            {
                "value": 63904,
                "label": "Lloydminster",
                "label_ar": "لويدمينستر",
                "label_fr": "Lloydminster"
            },
            {
                "value": 65068,
                "label": "Calder",
                "label_ar": "كالدر",
                "label_fr": "Calder"
            },
            {
                "value": 65504,
                "label": "Wellington",
                "label_ar": "ويلينجتون",
                "label_fr": "Wellington"
            },
            {
                "value": 65548,
                "label": "L'Isle-Verte",
                "label_ar": "ليل فيرت",
                "label_fr": "L'Isle-Verte"
            },
            {
                "value": 65038,
                "label": "Saint-Lazare-de-Bellechasse",
                "label_ar": "سان لازار دي بيليكاس",
                "label_fr": "Saint-Lazare-de-Bellechasse"
            },
            {
                "value": 65836,
                "label": "Kimmirut",
                "label_ar": "كيميروت",
                "label_fr": "Kimmirut"
            },
            {
                "value": 64099,
                "label": "North Sydney",
                "label_ar": "شمال سيدني",
                "label_fr": "North Sydney"
            },
            {
                "value": 65181,
                "label": "New-Richmond",
                "label_ar": "نيو ريتشموند",
                "label_fr": "New-Richmond"
            },
            {
                "value": 63838,
                "label": "Trois-Rivières",
                "label_ar": "تروا ريفيير",
                "label_fr": "Trois-Rivières"
            },
            {
                "value": 63731,
                "label": "Saint-Remi",
                "label_ar": "سانت ريمي",
                "label_fr": "Saint-Rémi"
            },
            {
                "value": 65494,
                "label": "Marwayne",
                "label_ar": "ماروين",
                "label_fr": "Marwayne"
            },
            {
                "value": 64831,
                "label": "Willow Bunch",
                "label_ar": "صفصاف بانش",
                "label_fr": "Bouquet de saules"
            },
            {
                "value": 64584,
                "label": "Kitwanga",
                "label_ar": "كيتوانجا",
                "label_fr": "Kitwanga"
            },
            {
                "value": 65328,
                "label": "Port Lorne",
                "label_ar": "بورت لورن",
                "label_fr": "Port Lorne"
            },
            {
                "value": 65263,
                "label": "Terre Noire",
                "label_ar": "تيري نوار",
                "label_fr": "Terre Noire"
            },
            {
                "value": 63759,
                "label": "Thornhill",
                "label_ar": "ثورنهيل",
                "label_fr": "Thornhill"
            },
            {
                "value": 65335,
                "label": "Head of Jeddore",
                "label_ar": "رئيس جدور",
                "label_fr": "Chef de Jeddore"
            },
            {
                "value": 65410,
                "label": "River Hebert",
                "label_ar": "نهر هيبرت",
                "label_fr": "Rivière Hebert"
            },
            {
                "value": 65268,
                "label": "Rexdale",
                "label_ar": "ريكسديل",
                "label_fr": "Rexdale"
            },
            {
                "value": 65435,
                "label": "Guigues",
                "label_ar": "Guigues",
                "label_fr": "Guigues"
            },
            {
                "value": 65176,
                "label": "Saint-Basile",
                "label_ar": "سان باسيلي",
                "label_fr": "Saint-Basile"
            },
            {
                "value": 65173,
                "label": "Saint-Michel-de-Bellechasse",
                "label_ar": "سان ميشيل دي بيلتشاس",
                "label_fr": "Saint-Michel-de-Bellechasse"
            }
        ]
    },
    {
        "country": "Syria",
        "cities": [
            {
                "value": 65865,
                "label": "Aleppo",
                "label_ar": "حلب",
                "label_fr": "Alep"
            },
            {
                "value": 65866,
                "label": "Ad Darah",
                "label_ar": "الدارة",
                "label_fr": "Ad Darah"
            },
            {
                "value": 65864,
                "label": "`Ara",
                "label_ar": "آرا",
                "label_fr": "ʻAra"
            },
            {
                "value": 65863,
                "label": "Damascus",
                "label_ar": "دمشق",
                "label_fr": "Damas"
            }
        ]
    },
    {
        "country": "Ukraine",
        "cities": [
            {
                "value": 66120,
                "label": "Tyachiv",
                "label_ar": "تاياتشيف",
                "label_fr": "Tyachiv"
            },
            {
                "value": 66045,
                "label": "Sokoliv",
                "label_ar": "سوكوليف",
                "label_fr": "Sokoliv"
            },
            {
                "value": 65966,
                "label": "Lohvynove",
                "label_ar": "Lohvynove",
                "label_fr": "Lohvynove"
            },
            {
                "value": 65940,
                "label": "Feodosiya",
                "label_ar": "فيودوسيا",
                "label_fr": "Feodosiya"
            },
            {
                "value": 65882,
                "label": "Zhytomyr",
                "label_ar": "زيتومير",
                "label_fr": "Zhytomyr"
            },
            {
                "value": 66087,
                "label": "Mostys'ka",
                "label_ar": "موستيسكا",
                "label_fr": "Mostys'ka"
            },
            {
                "value": 65971,
                "label": "Rivne",
                "label_ar": "ريفني",
                "label_fr": "Rivne"
            },
            {
                "value": 65991,
                "label": "Vasylets'",
                "label_ar": "فاسيليتس",
                "label_fr": "Vasylets '"
            },
            {
                "value": 65978,
                "label": "Chervona Hirka",
                "label_ar": "تشيرفونا هيركا",
                "label_fr": "Chervona Hirka"
            },
            {
                "value": 66012,
                "label": "Boryspil Raion",
                "label_ar": "بوريسبيل رايون",
                "label_fr": "Raion Boryspil"
            },
            {
                "value": 66099,
                "label": "Barashivka",
                "label_ar": "باراشيفكا",
                "label_fr": "Barashivka"
            },
            {
                "value": 66013,
                "label": "Debal'tseve",
                "label_ar": "دبالتسيف",
                "label_fr": "Debal'tseve"
            },
            {
                "value": 66009,
                "label": "Zhmerynka",
                "label_ar": "زميرينكا",
                "label_fr": "Zhmerynka"
            },
            {
                "value": 65932,
                "label": "Drohobych",
                "label_ar": "دروبيتش",
                "label_fr": "Drohobych"
            },
            {
                "value": 66027,
                "label": "Alushta",
                "label_ar": "الوشتا",
                "label_fr": "Alushta"
            },
            {
                "value": 65970,
                "label": "Stat",
                "label_ar": "ستات",
                "label_fr": "Stat"
            },
            {
                "value": 66108,
                "label": "Sencha",
                "label_ar": "سينشا",
                "label_fr": "Sencha"
            },
            {
                "value": 66010,
                "label": "Yasinovataya",
                "label_ar": "ياسينوفاتايا",
                "label_fr": "Yasinovataya"
            },
            {
                "value": 66040,
                "label": "Maksim",
                "label_ar": "مكسيم",
                "label_fr": "Maksim"
            },
            {
                "value": 66103,
                "label": "Osypenko",
                "label_ar": "أوسيبينكو",
                "label_fr": "Osypenko"
            },
            {
                "value": 65894,
                "label": "Petrenky",
                "label_ar": "بيترينكي",
                "label_fr": "Petrenky"
            },
            {
                "value": 65998,
                "label": "Poplavy",
                "label_ar": "بوبلافي",
                "label_fr": "Poplavy"
            },
            {
                "value": 66100,
                "label": "Stryi",
                "label_ar": "ستري",
                "label_fr": "Stryi"
            },
            {
                "value": 65899,
                "label": "Krasnoarmiys'k",
                "label_ar": "كراسنوارميسك",
                "label_fr": "Krasnoarmiys'k"
            },
            {
                "value": 65869,
                "label": "Ternopil",
                "label_ar": "ترنوبل",
                "label_fr": "Ternopil"
            },
            {
                "value": 65880,
                "label": "Novomoskovsk",
                "label_ar": "نوفوموسكوفسك",
                "label_fr": "Novomoskovsk"
            },
            {
                "value": 66063,
                "label": "Knyazhychi",
                "label_ar": "كنيازيتشي",
                "label_fr": "Knyazhychi"
            },
            {
                "value": 66008,
                "label": "Chernev",
                "label_ar": "تشيرنيف",
                "label_fr": "Chernev"
            },
            {
                "value": 65917,
                "label": "Lutsk",
                "label_ar": "لوتسك",
                "label_fr": "Loutsk"
            },
            {
                "value": 66095,
                "label": "Bozhenka",
                "label_ar": "بوزينكا",
                "label_fr": "Bozhenka"
            },
            {
                "value": 65981,
                "label": "Vasylkiv",
                "label_ar": "فاسيلكيف",
                "label_fr": "Vasylkiv"
            },
            {
                "value": 66015,
                "label": "Lisichansk",
                "label_ar": "ليسيتشانسك",
                "label_fr": "Lisichansk"
            },
            {
                "value": 66026,
                "label": "Bilohirs'k",
                "label_ar": "بيلوهيرسك",
                "label_fr": "Bilohirs'k"
            },
            {
                "value": 66014,
                "label": "Ternovka",
                "label_ar": "تيرنوفكا",
                "label_fr": "Ternovka"
            },
            {
                "value": 65962,
                "label": "Netishyn",
                "label_ar": "نيتشين",
                "label_fr": "Netishyn"
            },
            {
                "value": 66050,
                "label": "Hostomel'",
                "label_ar": "هوستوميل",
                "label_fr": "Hostomel '"
            },
            {
                "value": 65904,
                "label": "Krasnodon",
                "label_ar": "كراسنودون",
                "label_fr": "Krasnodon"
            },
            {
                "value": 66046,
                "label": "Korostyshiv",
                "label_ar": "كوروستيشيف",
                "label_fr": "Korostyshiv"
            },
            {
                "value": 66028,
                "label": "Kozyatyn",
                "label_ar": "كوزياتين",
                "label_fr": "Kozyatyn"
            },
            {
                "value": 65948,
                "label": "Kalush",
                "label_ar": "كلوش",
                "label_fr": "Kalush"
            },
            {
                "value": 66132,
                "label": "Uspens'ke",
                "label_ar": "Uspens'ke",
                "label_fr": "Uspens'ke"
            },
            {
                "value": 65870,
                "label": "Brody",
                "label_ar": "برودي",
                "label_fr": "Brody"
            },
            {
                "value": 66112,
                "label": "Dubno",
                "label_ar": "دوبنو",
                "label_fr": "Dubno"
            },
            {
                "value": 65901,
                "label": "Bila Tserkva",
                "label_ar": "بيلا تسيركفا",
                "label_fr": "Bila Tserkva"
            },
            {
                "value": 66005,
                "label": "Makariv",
                "label_ar": "مكاريف",
                "label_fr": "Makariv"
            },
            {
                "value": 65969,
                "label": "Rayhorodka",
                "label_ar": "رايهورودكا",
                "label_fr": "Rayhorodka"
            },
            {
                "value": 65985,
                "label": "Zolochiv",
                "label_ar": "زولوتشيف",
                "label_fr": "Zolochiv"
            },
            {
                "value": 65963,
                "label": "Spas",
                "label_ar": "منتجعات صحية",
                "label_fr": "Les thermes"
            },
            {
                "value": 66054,
                "label": "Zlazne",
                "label_ar": "زلازن",
                "label_fr": "Zlazne"
            },
            {
                "value": 65885,
                "label": "Boryspil",
                "label_ar": "بوريسبيل",
                "label_fr": "Boryspil"
            },
            {
                "value": 65936,
                "label": "Volochys'k",
                "label_ar": "فولوتشيسك",
                "label_fr": "Volochys'k"
            },
            {
                "value": 66062,
                "label": "Mylove",
                "label_ar": "حبيبتى",
                "label_fr": "Mon amour"
            },
            {
                "value": 66122,
                "label": "Khust",
                "label_ar": "خوست",
                "label_fr": "Khust"
            },
            {
                "value": 65921,
                "label": "Shpola",
                "label_ar": "شبولا",
                "label_fr": "Shpola"
            },
            {
                "value": 65895,
                "label": "Kovel",
                "label_ar": "كوفيل",
                "label_fr": "Kovel"
            },
            {
                "value": 66004,
                "label": "Lutugino",
                "label_ar": "لوتوجينو",
                "label_fr": "Lutugino"
            },
            {
                "value": 66071,
                "label": "Ivankov",
                "label_ar": "ايفانكوف",
                "label_fr": "Ivankov"
            },
            {
                "value": 66030,
                "label": "Alupka",
                "label_ar": "ألوبكا",
                "label_fr": "Alupka"
            },
            {
                "value": 65871,
                "label": "Donetsk",
                "label_ar": "دونيتسك",
                "label_fr": "Donetsk"
            },
            {
                "value": 66055,
                "label": "Obukhov",
                "label_ar": "أوبوكوف",
                "label_fr": "Obukhov"
            },
            {
                "value": 65902,
                "label": "Pavlograd",
                "label_ar": "بافلوجراد",
                "label_fr": "Pavlograd"
            },
            {
                "value": 65924,
                "label": "Dimitrov",
                "label_ar": "ديميتروف",
                "label_fr": "Dimitrov"
            },
            {
                "value": 66117,
                "label": "Haysyn",
                "label_ar": "هايسين",
                "label_fr": "Haysyn"
            },
            {
                "value": 65997,
                "label": "Kurylivka",
                "label_ar": "كوريليفكا",
                "label_fr": "Kurylivka"
            },
            {
                "value": 66053,
                "label": "Kvasyliv",
                "label_ar": "كفاسيليف",
                "label_fr": "Kvasyliv"
            },
            {
                "value": 65977,
                "label": "Bolekhiv",
                "label_ar": "بوليخيف",
                "label_fr": "Bolekhiv"
            },
            {
                "value": 65920,
                "label": "Tsyurupinsk",
                "label_ar": "تسيوروبينسك",
                "label_fr": "Tsyurupinsk"
            },
            {
                "value": 65986,
                "label": "Tkachenka",
                "label_ar": "تكاتشينكا",
                "label_fr": "Tkachenka"
            },
            {
                "value": 66097,
                "label": "Merefa",
                "label_ar": "ميريفا",
                "label_fr": "Merefa"
            },
            {
                "value": 65900,
                "label": "Syeverodonets'k",
                "label_ar": "سيفيرودونتسك",
                "label_fr": "Syeverodonets'k"
            },
            {
                "value": 66039,
                "label": "Berdychiv",
                "label_ar": "بيرديتشيف",
                "label_fr": "Berdychiv"
            },
            {
                "value": 66096,
                "label": "Zmiyiv",
                "label_ar": "زميف",
                "label_fr": "Zmiyiv"
            },
            {
                "value": 65876,
                "label": "Kherson",
                "label_ar": "خيرسون",
                "label_fr": "Kherson"
            },
            {
                "value": 65886,
                "label": "Kharkiv",
                "label_ar": "خاركيف",
                "label_fr": "Kharkiv"
            },
            {
                "value": 65987,
                "label": "Rubizhne",
                "label_ar": "روبيزني",
                "label_fr": "Rubizhne"
            },
            {
                "value": 66048,
                "label": "Zabolotiv",
                "label_ar": "زابولوتيف",
                "label_fr": "Zabolotiv"
            },
            {
                "value": 65955,
                "label": "Krasnoperekops'k",
                "label_ar": "Krasnoperekops'k",
                "label_fr": "Krasnoperekops'k"
            },
            {
                "value": 66082,
                "label": "Volkov",
                "label_ar": "فولكوف",
                "label_fr": "Volkov"
            },
            {
                "value": 66007,
                "label": "Bryanka",
                "label_ar": "بريانكا",
                "label_fr": "Bryanka"
            },
            {
                "value": 65883,
                "label": "Odesa",
                "label_ar": "أوديسا",
                "label_fr": "Odessa"
            },
            {
                "value": 65926,
                "label": "Poltava",
                "label_ar": "بولتافا",
                "label_fr": "Poltava"
            },
            {
                "value": 66102,
                "label": "Dmitrovichi",
                "label_ar": "دميتروفيتشي",
                "label_fr": "Dmitrovichi"
            },
            {
                "value": 66032,
                "label": "Volnovakha",
                "label_ar": "فولنوفاكيا",
                "label_fr": "Volnovakha"
            },
            {
                "value": 66107,
                "label": "Serhiyi",
                "label_ar": "سرحي",
                "label_fr": "Serhiyi"
            },
            {
                "value": 66019,
                "label": "Berehove",
                "label_ar": "بيرهوف",
                "label_fr": "Berehove"
            },
            {
                "value": 65879,
                "label": "Berdyans'k",
                "label_ar": "بيرديانسك",
                "label_fr": "Berdyans'k"
            },
            {
                "value": 65922,
                "label": "Chernivtsi",
                "label_ar": "تشيرنيفتسي",
                "label_fr": "Tchernivtsi"
            },
            {
                "value": 66125,
                "label": "Petranka",
                "label_ar": "بيترانكا",
                "label_fr": "Petranka"
            },
            {
                "value": 65973,
                "label": "Turka",
                "label_ar": "توركا",
                "label_fr": "Turka"
            },
            {
                "value": 65875,
                "label": "Yenakiyeve",
                "label_ar": "Yenakiyeve",
                "label_fr": "Yenakiyeve"
            },
            {
                "value": 65958,
                "label": "Belaya",
                "label_ar": "بيلايا",
                "label_fr": "Belaya"
            },
            {
                "value": 66084,
                "label": "Stepan'",
                "label_ar": "ستيبان",
                "label_fr": "Stépan '"
            },
            {
                "value": 66118,
                "label": "Sokol",
                "label_ar": "سوكول",
                "label_fr": "Sokol"
            },
            {
                "value": 65980,
                "label": "Kotovs'k",
                "label_ar": "كوتوفسك",
                "label_fr": "Kotovs'k"
            },
            {
                "value": 66092,
                "label": "Boryslav",
                "label_ar": "بوريسلاف",
                "label_fr": "Boryslav"
            },
            {
                "value": 65928,
                "label": "Sevastopol",
                "label_ar": "سيفاستوبول",
                "label_fr": "Sébastopol"
            },
            {
                "value": 65954,
                "label": "Ivanov",
                "label_ar": "إيفانوف",
                "label_fr": "Ivanov"
            },
            {
                "value": 65953,
                "label": "Nizhyn",
                "label_ar": "نيزين",
                "label_fr": "Nizhyn"
            },
            {
                "value": 66134,
                "label": "Khyzha",
                "label_ar": "خيزة",
                "label_fr": "Khyzha"
            },
            {
                "value": 65943,
                "label": "Brovary",
                "label_ar": "بروفاري",
                "label_fr": "Brovary"
            },
            {
                "value": 65949,
                "label": "Melitopol",
                "label_ar": "ميليتوبول",
                "label_fr": "Mélitopol"
            },
            {
                "value": 65906,
                "label": "Sumy",
                "label_ar": "سومي",
                "label_fr": "Sumy"
            },
            {
                "value": 65945,
                "label": "Aleksandriya",
                "label_ar": "الكسندريا",
                "label_fr": "Aleksandriya"
            },
            {
                "value": 65913,
                "label": "Volodymyr-Volyns'kyy",
                "label_ar": "فولوديمير فولينسكي",
                "label_fr": "Volodymyr-Volyns'kyy"
            },
            {
                "value": 65967,
                "label": "Podol",
                "label_ar": "بودول",
                "label_fr": "Podol"
            },
            {
                "value": 65898,
                "label": "Desna",
                "label_ar": "ديسنا",
                "label_fr": "Desna"
            },
            {
                "value": 65877,
                "label": "Zaporizhia",
                "label_ar": "زابوريزهيا",
                "label_fr": "Zaporijia"
            },
            {
                "value": 65925,
                "label": "Mala Tokmachka",
                "label_ar": "مالا توكماتشكا",
                "label_fr": "Mala Tokmachka"
            },
            {
                "value": 65914,
                "label": "Sverdlovs'k",
                "label_ar": "سفيردلوفسك",
                "label_fr": "Sverdlovs'k"
            },
            {
                "value": 66115,
                "label": "Mukacheve",
                "label_ar": "موكاشيف",
                "label_fr": "Mukacheve"
            },
            {
                "value": 65975,
                "label": "Irpin",
                "label_ar": "ايربين",
                "label_fr": "Irpin"
            },
            {
                "value": 66114,
                "label": "Kreminna",
                "label_ar": "كريمينا",
                "label_fr": "Kreminna"
            },
            {
                "value": 65999,
                "label": "Zdolbuniv",
                "label_ar": "زدولبونيف",
                "label_fr": "Zdolbuniv"
            },
            {
                "value": 65947,
                "label": "Marhanets'",
                "label_ar": "مارانيتس",
                "label_fr": "Marhanets"
            },
            {
                "value": 66038,
                "label": "Mykolayiv",
                "label_ar": "ميكولايف",
                "label_fr": "Mykolayiv"
            },
            {
                "value": 66080,
                "label": "Davydov",
                "label_ar": "دافيدوف",
                "label_fr": "Davydov"
            },
            {
                "value": 65968,
                "label": "Boyarka",
                "label_ar": "بوياركا",
                "label_fr": "Boyarka"
            },
            {
                "value": 66052,
                "label": "Dykan'ka",
                "label_ar": "ديكانكا",
                "label_fr": "Dykan'ka"
            },
            {
                "value": 65923,
                "label": "Buchach",
                "label_ar": "بوكاش",
                "label_fr": "Buchach"
            },
            {
                "value": 65893,
                "label": "Kirovsk",
                "label_ar": "كيروفسك",
                "label_fr": "Kirovsk"
            },
            {
                "value": 66029,
                "label": "Tul'chyn",
                "label_ar": "تولشين",
                "label_fr": "Tul'chyn"
            },
            {
                "value": 66111,
                "label": "Markova",
                "label_ar": "ماركوفا",
                "label_fr": "Markova"
            },
            {
                "value": 66056,
                "label": "Trypillia",
                "label_ar": "طريبيلا",
                "label_fr": "Trypillia"
            },
            {
                "value": 65915,
                "label": "Illintsi",
                "label_ar": "إلينسي",
                "label_fr": "Illintsi"
            },
            {
                "value": 65912,
                "label": "Lviv",
                "label_ar": "لفيف",
                "label_fr": "Lviv"
            },
            {
                "value": 65996,
                "label": "Aleksandrovka",
                "label_ar": "أليكساندروفكا",
                "label_fr": "Aleksandrovka"
            },
            {
                "value": 66021,
                "label": "Rovenki",
                "label_ar": "روفينكي",
                "label_fr": "Rovenki"
            },
            {
                "value": 65878,
                "label": "Dnipropetrovsk",
                "label_ar": "دنيبروبتروفسك",
                "label_fr": "Dnipropetrovsk"
            },
            {
                "value": 66061,
                "label": "Novovolyns'k",
                "label_ar": "نوفوفولينسك",
                "label_fr": "Novovolyns'k"
            },
            {
                "value": 66131,
                "label": "Tokmak",
                "label_ar": "توكماك",
                "label_fr": "Tokmak"
            },
            {
                "value": 66001,
                "label": "Komsomolsk",
                "label_ar": "كومسومولسك",
                "label_fr": "Komsomolsk"
            },
            {
                "value": 66031,
                "label": "Svessa",
                "label_ar": "سفيسا",
                "label_fr": "Svessa"
            },
            {
                "value": 65961,
                "label": "Anan'yiv",
                "label_ar": "أنانيف",
                "label_fr": "Anan'yiv"
            },
            {
                "value": 65930,
                "label": "Khmel'nyts'kyy",
                "label_ar": "Khmel'nyts'kyy",
                "label_fr": "Khmel'nyts'kyy"
            },
            {
                "value": 66017,
                "label": "Ugledar",
                "label_ar": "أوجليدار",
                "label_fr": "Ugledar"
            },
            {
                "value": 66000,
                "label": "Kyseliv",
                "label_ar": "كيسليف",
                "label_fr": "Kyseliv"
            },
            {
                "value": 66020,
                "label": "Kremenets",
                "label_ar": "كريمينتس",
                "label_fr": "Kremenets"
            },
            {
                "value": 66121,
                "label": "Druzhkovka",
                "label_ar": "دروزكوفكا",
                "label_fr": "Druzhkovka"
            },
            {
                "value": 65959,
                "label": "Shostka",
                "label_ar": "شوستكا",
                "label_fr": "Shostka"
            },
            {
                "value": 66018,
                "label": "Sudak",
                "label_ar": "سوداك",
                "label_fr": "Sudak"
            },
            {
                "value": 66068,
                "label": "Nadezhda",
                "label_ar": "ناديجدا",
                "label_fr": "Nadezhda"
            },
            {
                "value": 65941,
                "label": "Yalta",
                "label_ar": "يالطا",
                "label_fr": "Yalta"
            },
            {
                "value": 66074,
                "label": "Babin",
                "label_ar": "بابين",
                "label_fr": "Babin"
            },
            {
                "value": 65942,
                "label": "Stakhanov",
                "label_ar": "ستاخانوف",
                "label_fr": "Stakhanov"
            },
            {
                "value": 66137,
                "label": "Vyshneve",
                "label_ar": "فيشنيف",
                "label_fr": "Vyshneve"
            },
            {
                "value": 65872,
                "label": "Mariupol",
                "label_ar": "ماريوبول",
                "label_fr": "Marioupol"
            },
            {
                "value": 65960,
                "label": "Storozhynets'",
                "label_ar": "ستوروزينيتس",
                "label_fr": "Storozhynets '"
            },
            {
                "value": 65988,
                "label": "Malyn",
                "label_ar": "مالين",
                "label_fr": "Malyn"
            },
            {
                "value": 66126,
                "label": "Selidovo",
                "label_ar": "سليدوفو",
                "label_fr": "Selidovo"
            },
            {
                "value": 66105,
                "label": "Izmail",
                "label_ar": "إسماعيل",
                "label_fr": "Izmail"
            },
            {
                "value": 65888,
                "label": "Cherkasy",
                "label_ar": "تشيركاسي",
                "label_fr": "Tcherkassy"
            },
            {
                "value": 66077,
                "label": "Slynkivshchyna",
                "label_ar": "سلينكيفشتشينا",
                "label_fr": "Slynkivshchyna"
            },
            {
                "value": 66129,
                "label": "Monastyryshche",
                "label_ar": "Monastyryshche",
                "label_fr": "Monastyryshche"
            },
            {
                "value": 65938,
                "label": "Pavlohrad",
                "label_ar": "بافلوهراد",
                "label_fr": "Pavlohrad"
            },
            {
                "value": 66098,
                "label": "Bushtyno",
                "label_ar": "بوشتينو",
                "label_fr": "Bushtyno"
            },
            {
                "value": 66060,
                "label": "Sorotskoye",
                "label_ar": "سوروتسكوي",
                "label_fr": "Sorotskoye"
            },
            {
                "value": 65881,
                "label": "Chervonohrad",
                "label_ar": "شرفونوهراد",
                "label_fr": "Chervonohrad"
            },
            {
                "value": 65889,
                "label": "Kerch",
                "label_ar": "كيرتش",
                "label_fr": "Kertch"
            },
            {
                "value": 66116,
                "label": "Khotin'",
                "label_ar": "خوتين",
                "label_fr": "Khotin '"
            },
            {
                "value": 65909,
                "label": "Kuna",
                "label_ar": "كونا",
                "label_fr": "Kuna"
            },
            {
                "value": 66123,
                "label": "Pyrohiv",
                "label_ar": "بيروهيف",
                "label_fr": "Pyrohiv"
            },
            {
                "value": 65891,
                "label": "Teplodar",
                "label_ar": "تبلودار",
                "label_fr": "Teplodar"
            },
            {
                "value": 65903,
                "label": "Makiyivka",
                "label_ar": "ماكييفكا",
                "label_fr": "Makiyivka"
            },
            {
                "value": 65965,
                "label": "Bucha",
                "label_ar": "بوتشا",
                "label_fr": "Bucha"
            },
            {
                "value": 66135,
                "label": "Nikopol'",
                "label_ar": "نيكوبول",
                "label_fr": "Nikopol '"
            },
            {
                "value": 66066,
                "label": "Zastava",
                "label_ar": "زاستافا",
                "label_fr": "Zastava"
            },
            {
                "value": 65867,
                "label": "Kiev",
                "label_ar": "كييف",
                "label_fr": "Kiev"
            },
            {
                "value": 65919,
                "label": "Svalyava",
                "label_ar": "سفاليافا",
                "label_fr": "Svalyava"
            },
            {
                "value": 65944,
                "label": "Kremenchuk",
                "label_ar": "كريمنشوك",
                "label_fr": "Kremenchuk"
            },
            {
                "value": 66051,
                "label": "Voronovo",
                "label_ar": "فورونوفو",
                "label_fr": "Voronovo"
            },
            {
                "value": 66070,
                "label": "Khmil'nyk",
                "label_ar": "خميلنيك",
                "label_fr": "Khmil'nyk"
            },
            {
                "value": 65939,
                "label": "Ternopol'skiy Rayon",
                "label_ar": "Ternopol'skiy رايون",
                "label_fr": "Ternopol'skiy Rayon"
            },
            {
                "value": 66064,
                "label": "Nosivka",
                "label_ar": "نوسيفكا",
                "label_fr": "Nosivka"
            },
            {
                "value": 66072,
                "label": "Darakhov",
                "label_ar": "داراخوف",
                "label_fr": "Darakhov"
            },
            {
                "value": 66057,
                "label": "Kaniv",
                "label_ar": "كانيف",
                "label_fr": "Kaniv"
            },
            {
                "value": 65927,
                "label": "Korosten",
                "label_ar": "كوروستين",
                "label_fr": "Korosten"
            },
            {
                "value": 66006,
                "label": "Horlivka",
                "label_ar": "هورليفكا",
                "label_fr": "Horlivka"
            },
            {
                "value": 65992,
                "label": "Teremky",
                "label_ar": "Teremky",
                "label_fr": "Teremky"
            },
            {
                "value": 66088,
                "label": "Lityn",
                "label_ar": "ليتين",
                "label_fr": "Lityn"
            },
            {
                "value": 65984,
                "label": "Soroki",
                "label_ar": "سوروكي",
                "label_fr": "Soroki"
            },
            {
                "value": 65931,
                "label": "Kryvyy Rih",
                "label_ar": "كريفي ريه",
                "label_fr": "Kryvyy Rih"
            },
            {
                "value": 65929,
                "label": "Smila",
                "label_ar": "سميلا",
                "label_fr": "Smila"
            },
            {
                "value": 66049,
                "label": "Horodenka",
                "label_ar": "هورودينكا",
                "label_fr": "Horodenka"
            },
            {
                "value": 66127,
                "label": "Lenina",
                "label_ar": "لينينا",
                "label_fr": "Lenina"
            },
            {
                "value": 66104,
                "label": "Osnova",
                "label_ar": "أوسنوفا",
                "label_fr": "Osnova"
            },
            {
                "value": 66041,
                "label": "Shepetivka",
                "label_ar": "شيبيتيفكا",
                "label_fr": "Shepetivka"
            },
            {
                "value": 66069,
                "label": "Stanislav",
                "label_ar": "ستانيسلاف",
                "label_fr": "Stanislav"
            },
            {
                "value": 65896,
                "label": "Uzhhorod",
                "label_ar": "أوزهورود",
                "label_fr": "Oujhorod"
            },
            {
                "value": 66002,
                "label": "Romny",
                "label_ar": "رومني",
                "label_fr": "Romny"
            },
            {
                "value": 66024,
                "label": "Kuznetsovsk",
                "label_ar": "كوزنتسوفسك",
                "label_fr": "Kuznetsovsk"
            },
            {
                "value": 66042,
                "label": "Dzhankoy",
                "label_ar": "دزهانكوي",
                "label_fr": "Dzhankoy"
            },
            {
                "value": 66037,
                "label": "Novyy Buh",
                "label_ar": "نوفي بوه",
                "label_fr": "Novyy Buh"
            },
            {
                "value": 65935,
                "label": "Pervomays'k",
                "label_ar": "بيرفومايسك",
                "label_fr": "Pervomays'k"
            },
            {
                "value": 66086,
                "label": "Kurakhovo",
                "label_ar": "كوراخوفو",
                "label_fr": "Kurakhovo"
            },
            {
                "value": 65933,
                "label": "Rivne",
                "label_ar": "ريفني",
                "label_fr": "Rivne"
            },
            {
                "value": 65964,
                "label": "Vinogradar'",
                "label_ar": "فينوجرادار",
                "label_fr": "Vinogradar '"
            },
            {
                "value": 65892,
                "label": "Alchevs'k",
                "label_ar": "الشيفسك",
                "label_fr": "Alchevs'k"
            },
            {
                "value": 65976,
                "label": "Voznesenka",
                "label_ar": "فوزنيسينكا",
                "label_fr": "Voznesenka"
            },
            {
                "value": 65907,
                "label": "Oleh",
                "label_ar": "اوليه",
                "label_fr": "Oleh"
            },
            {
                "value": 65979,
                "label": "Energodar",
                "label_ar": "إنرجودار",
                "label_fr": "Energodar"
            },
            {
                "value": 66101,
                "label": "Soroka",
                "label_ar": "سوروكا",
                "label_fr": "Soroka"
            },
            {
                "value": 66089,
                "label": "Hoshiv",
                "label_ar": "هوشيف",
                "label_fr": "Hoshiv"
            },
            {
                "value": 65873,
                "label": "Rodinskoye",
                "label_ar": "رودنسكوي",
                "label_fr": "Rodinskoïe"
            },
            {
                "value": 66034,
                "label": "Zhdanovka",
                "label_ar": "Zhdanovka",
                "label_fr": "Zhdanovka"
            },
            {
                "value": 66073,
                "label": "Semen",
                "label_ar": "المني",
                "label_fr": "Sperme"
            },
            {
                "value": 66035,
                "label": "Ukrayinka",
                "label_ar": "أوكراينكا",
                "label_fr": "Ukrayinka"
            },
            {
                "value": 66081,
                "label": "Tsvetkov",
                "label_ar": "تسفيتكوف",
                "label_fr": "Tsvetkov"
            },
            {
                "value": 65905,
                "label": "Simferopol",
                "label_ar": "سيمفيروبول",
                "label_fr": "Simféropol"
            },
            {
                "value": 65989,
                "label": "Zhashkiv",
                "label_ar": "زهاشكيف",
                "label_fr": "Zhashkiv"
            },
            {
                "value": 65957,
                "label": "Kolomyia",
                "label_ar": "كولوميا",
                "label_fr": "Kolomyia"
            },
            {
                "value": 65868,
                "label": "Vyshhorod",
                "label_ar": "فيشورد",
                "label_fr": "Vyshhorod"
            },
            {
                "value": 66059,
                "label": "Krasnohrad",
                "label_ar": "كراسنوهراد",
                "label_fr": "Krasnohrad"
            },
            {
                "value": 66044,
                "label": "Kakhovka",
                "label_ar": "كاخوفكا",
                "label_fr": "Kakhovka"
            },
            {
                "value": 65890,
                "label": "Mykolayiv",
                "label_ar": "ميكولايف",
                "label_fr": "Mykolayiv"
            },
            {
                "value": 66128,
                "label": "Goncharov",
                "label_ar": "غونشاروف",
                "label_fr": "Gontcharov"
            },
            {
                "value": 65982,
                "label": "Alekseyevo-Druzhkovka",
                "label_ar": "أليكسييفو دروزكوفكا",
                "label_fr": "Alekseyevo-Druzhkovka"
            },
            {
                "value": 66033,
                "label": "Bila Hora",
                "label_ar": "بيلا هورا",
                "label_fr": "Bila Hora"
            },
            {
                "value": 66043,
                "label": "Bobrynets'",
                "label_ar": "بوبرينيتس",
                "label_fr": "Bobrynets '"
            },
            {
                "value": 66093,
                "label": "Lubny",
                "label_ar": "لوبني",
                "label_fr": "Lubny"
            },
            {
                "value": 65916,
                "label": "Ivano-Frankivs'k",
                "label_ar": "ايفانو فرانكيفسك",
                "label_fr": "Ivano-Frankivs'k"
            },
            {
                "value": 65884,
                "label": "Chernihiv",
                "label_ar": "تشيرنيهيف",
                "label_fr": "Tchernihiv"
            },
            {
                "value": 65951,
                "label": "Kirovohrad",
                "label_ar": "كيروفوهراد",
                "label_fr": "Kirovohrad"
            },
            {
                "value": 66119,
                "label": "Kyyiv",
                "label_ar": "كييف",
                "label_fr": "Kyyiv"
            },
            {
                "value": 65993,
                "label": "Lugansk",
                "label_ar": "لوغانسك",
                "label_fr": "Lougansk"
            },
            {
                "value": 66065,
                "label": "Bortnychi",
                "label_ar": "بورتنيتشي",
                "label_fr": "Bortnychi"
            },
            {
                "value": 66023,
                "label": "Konotop",
                "label_ar": "كونوتوب",
                "label_fr": "Konotop"
            },
            {
                "value": 66113,
                "label": "Luganskoye",
                "label_ar": "لوغانسكوي",
                "label_fr": "Louganskoïe"
            },
            {
                "value": 65897,
                "label": "Vinnytsya",
                "label_ar": "فينيتسا",
                "label_fr": "Vinnytsya"
            },
            {
                "value": 65946,
                "label": "Zaporozhe",
                "label_ar": "زابوروجي",
                "label_fr": "Zaporozhe"
            },
            {
                "value": 66090,
                "label": "Sokil'nyky",
                "label_ar": "سوكيلنيكي",
                "label_fr": "Sokil'nyky"
            },
            {
                "value": 65887,
                "label": "Luhansk",
                "label_ar": "لوهانسك",
                "label_fr": "Louhansk"
            },
            {
                "value": 65908,
                "label": "Obolon'",
                "label_ar": "Obolon '",
                "label_fr": "Obolon '"
            },
            {
                "value": 66085,
                "label": "Frunze",
                "label_ar": "فرونزي",
                "label_fr": "Frunze"
            },
            {
                "value": 66016,
                "label": "Gorlovka",
                "label_ar": "جورلوفكا",
                "label_fr": "Gorlovka"
            },
            {
                "value": 66094,
                "label": "Borodyanka",
                "label_ar": "بورودينكا",
                "label_fr": "Borodyanka"
            },
            {
                "value": 65950,
                "label": "Vorovskiy",
                "label_ar": "فوروفسكي",
                "label_fr": "Vorovskiy"
            },
            {
                "value": 66109,
                "label": "Baryshivka",
                "label_ar": "باريشيفكا",
                "label_fr": "Baryshivka"
            },
            {
                "value": 65934,
                "label": "Dniprodzerzhyns'k",
                "label_ar": "Dniprodzerzhyns'k",
                "label_fr": "Dniprodzerzhyns'k"
            },
            {
                "value": 65956,
                "label": "Zolotonosha",
                "label_ar": "زولوتونوشا",
                "label_fr": "Zolotonosha"
            },
            {
                "value": 66124,
                "label": "Torez",
                "label_ar": "توريز",
                "label_fr": "Torez"
            },
            {
                "value": 65983,
                "label": "Kalynivka",
                "label_ar": "كالينيفكا",
                "label_fr": "Kalynivka"
            },
            {
                "value": 66036,
                "label": "Kirovs'ke",
                "label_ar": "كيروفسكي",
                "label_fr": "Kirovs'ke"
            },
            {
                "value": 66110,
                "label": "Sverdlovs'ke",
                "label_ar": "سفيردلوفسكي",
                "label_fr": "Sverdlovs'ke"
            },
            {
                "value": 65974,
                "label": "Chop",
                "label_ar": "يقطع",
                "label_fr": "Hacher"
            },
            {
                "value": 66130,
                "label": "Fontanka",
                "label_ar": "فونتانكا",
                "label_fr": "Fontanka"
            },
            {
                "value": 66076,
                "label": "German",
                "label_ar": "ألمانية",
                "label_fr": "allemand"
            },
            {
                "value": 66003,
                "label": "Artemivs'k",
                "label_ar": "أرتيميفسك",
                "label_fr": "Artemivs'k"
            },
            {
                "value": 65911,
                "label": "Kalynivka",
                "label_ar": "كالينيفكا",
                "label_fr": "Kalynivka"
            },
            {
                "value": 65874,
                "label": "Sloviansk",
                "label_ar": "Sloviansk",
                "label_fr": "Sloviansk"
            },
            {
                "value": 66025,
                "label": "Shevchenka",
                "label_ar": "شيفتشينكا",
                "label_fr": "Shevchenka"
            },
            {
                "value": 65952,
                "label": "Dolyns'ka",
                "label_ar": "Dolyns'ka",
                "label_fr": "Dolyns'ka"
            },
            {
                "value": 66011,
                "label": "Fastiv",
                "label_ar": "فاستيف",
                "label_fr": "Fastiv"
            },
            {
                "value": 65972,
                "label": "Kramatorsk",
                "label_ar": "كراماتورسك",
                "label_fr": "Kramatorsk"
            },
            {
                "value": 66133,
                "label": "Letychiv",
                "label_ar": "ليتشيف",
                "label_fr": "Letychiv"
            },
            {
                "value": 66047,
                "label": "Lanovka",
                "label_ar": "لانوفكا",
                "label_fr": "Lanovka"
            },
            {
                "value": 65995,
                "label": "Shevchenko",
                "label_ar": "شيفتشينكو",
                "label_fr": "Shevchenko"
            },
            {
                "value": 66075,
                "label": "Romaniv",
                "label_ar": "رومانيف",
                "label_fr": "Romaniv"
            },
            {
                "value": 65990,
                "label": "Zavod",
                "label_ar": "زافود",
                "label_fr": "Zavod"
            },
            {
                "value": 66083,
                "label": "Pobeda",
                "label_ar": "بوبيدا",
                "label_fr": "Pobeda"
            },
            {
                "value": 65937,
                "label": "Slavuta",
                "label_ar": "سلافوتا",
                "label_fr": "Slavuta"
            },
            {
                "value": 66067,
                "label": "Uman'",
                "label_ar": "أومان",
                "label_fr": "Uman '"
            },
            {
                "value": 66058,
                "label": "Saky",
                "label_ar": "ساكي",
                "label_fr": "Saky"
            },
            {
                "value": 66106,
                "label": "Hlukhiv",
                "label_ar": "هلوخيف",
                "label_fr": "Hlukhiv"
            },
            {
                "value": 66091,
                "label": "Grebenyuk",
                "label_ar": "غريبنيوك",
                "label_fr": "Grebenyuk"
            },
            {
                "value": 66022,
                "label": "Armyans'k",
                "label_ar": "ارميانسك",
                "label_fr": "Armyans'k"
            },
            {
                "value": 66079,
                "label": "Bukova",
                "label_ar": "بوكوفا",
                "label_fr": "Bukova"
            },
            {
                "value": 65994,
                "label": "Mega",
                "label_ar": "ميجا",
                "label_fr": "Méga"
            },
            {
                "value": 66078,
                "label": "Toporov",
                "label_ar": "توبوروف",
                "label_fr": "Toporov"
            },
            {
                "value": 65918,
                "label": "Novi Sanzhary",
                "label_ar": "نوفي سانزاري",
                "label_fr": "Novi Sanzhary"
            },
            {
                "value": 65910,
                "label": "Volodars'k-Volyns'kyy",
                "label_ar": "فولودارسك فولينسكي",
                "label_fr": "Volodars'k-Volyns'kyy"
            },
            {
                "value": 66136,
                "label": "Oreanda",
                "label_ar": "اوريندا",
                "label_fr": "Oreanda"
            }
        ]
    },
    {
        "country": "Cyprus",
        "cities": [
            {
                "value": 66150,
                "label": "Strovolos",
                "label_ar": "ستروفولوس",
                "label_fr": "Strovolos"
            },
            {
                "value": 66160,
                "label": "Deryneia",
                "label_ar": "Deryneia",
                "label_fr": "Deryneia"
            },
            {
                "value": 66146,
                "label": "Chlorakas",
                "label_ar": "كلوراكاس",
                "label_fr": "Chlorakas"
            },
            {
                "value": 66147,
                "label": "Paralimni",
                "label_ar": "باراليمني",
                "label_fr": "Paralimni"
            },
            {
                "value": 66154,
                "label": "Mouttagiaka",
                "label_ar": "موتاجياكا",
                "label_fr": "Mouttagiaka"
            },
            {
                "value": 66161,
                "label": "Sotira",
                "label_ar": "سوتيرا",
                "label_fr": "Sotira"
            },
            {
                "value": 66149,
                "label": "Morfou",
                "label_ar": "مورفو",
                "label_fr": "Morfou"
            },
            {
                "value": 66145,
                "label": "Kato Polemidia",
                "label_ar": "كاتو بوليميديا",
                "label_fr": "Kato Polemidia"
            },
            {
                "value": 66157,
                "label": "Agia Anna",
                "label_ar": "أجيا آنا",
                "label_fr": "Agia Anna"
            },
            {
                "value": 66139,
                "label": "Nicosia",
                "label_ar": "نيقوسيا",
                "label_fr": "Nicosie"
            },
            {
                "value": 66141,
                "label": "Paphos",
                "label_ar": "بافوس",
                "label_fr": "Paphos"
            },
            {
                "value": 66159,
                "label": "Famagusta",
                "label_ar": "فاماغوستا",
                "label_fr": "Famagouste"
            },
            {
                "value": 66148,
                "label": "Parekklisha",
                "label_ar": "باريكليشا",
                "label_fr": "Parekklisha"
            },
            {
                "value": 66142,
                "label": "Geroskipou",
                "label_ar": "جيروسكيبو",
                "label_fr": "Geroskipou"
            },
            {
                "value": 66138,
                "label": "Limassol",
                "label_ar": "ليماسول",
                "label_fr": "Limassol"
            },
            {
                "value": 66151,
                "label": "Kyrenia",
                "label_ar": "كيرينيا",
                "label_fr": "Kyrenia"
            },
            {
                "value": 66140,
                "label": "Larnaca",
                "label_ar": "لارنكا",
                "label_fr": "Larnaca"
            },
            {
                "value": 66158,
                "label": "Kato Lakatamia",
                "label_ar": "كاتو لاكاتاميا",
                "label_fr": "Kato Lakatamia"
            },
            {
                "value": 66144,
                "label": "Livadia",
                "label_ar": "ليفاديا",
                "label_fr": "Livadia"
            },
            {
                "value": 66153,
                "label": "Aradippou",
                "label_ar": "ارديبو",
                "label_fr": "Aradippou"
            },
            {
                "value": 66162,
                "label": "Tera",
                "label_ar": "تيرا",
                "label_fr": "Tera"
            },
            {
                "value": 66152,
                "label": "Laxia",
                "label_ar": "لاكسيا",
                "label_fr": "Laxia"
            },
            {
                "value": 66143,
                "label": "Ayia Marina",
                "label_ar": "ايا مارينا",
                "label_fr": "Ayia Marina"
            },
            {
                "value": 66155,
                "label": "Kiti",
                "label_ar": "كيتي",
                "label_fr": "Kiti"
            },
            {
                "value": 66156,
                "label": "Prodromi",
                "label_ar": "برودرومي",
                "label_fr": "Prodromi"
            }
        ]
    },
    {
        "country": "Czech Republic",
        "cities": [
            {
                "value": 67012,
                "label": "Uzice",
                "label_ar": "أوزيتسه",
                "label_fr": "Uzice"
            },
            {
                "value": 66757,
                "label": "Mechenice",
                "label_ar": "ميكينيسي",
                "label_fr": "Mechenice"
            },
            {
                "value": 66553,
                "label": "Slavonice",
                "label_ar": "سلافونيس",
                "label_fr": "Slavonice"
            },
            {
                "value": 67104,
                "label": "Vochov",
                "label_ar": "فوتشوف",
                "label_fr": "Vochov"
            },
            {
                "value": 66514,
                "label": "Kretin",
                "label_ar": "كريتين",
                "label_fr": "Kretin"
            },
            {
                "value": 66610,
                "label": "Raspenava",
                "label_ar": "راسبينافا",
                "label_fr": "Raspenava"
            },
            {
                "value": 66743,
                "label": "Veltrusy",
                "label_ar": "فيلتروسي",
                "label_fr": "Veltrusy"
            },
            {
                "value": 66429,
                "label": "Jevisovice",
                "label_ar": "جيفيسوفيتسي",
                "label_fr": "Jevisovice"
            },
            {
                "value": 67035,
                "label": "Kotencice",
                "label_ar": "كوتنشيتسي",
                "label_fr": "Kotencice"
            },
            {
                "value": 66908,
                "label": "Mirosovice",
                "label_ar": "ميروسوفيتسي",
                "label_fr": "Mirosovice"
            },
            {
                "value": 66434,
                "label": "Trinec",
                "label_ar": "ترينيك",
                "label_fr": "Trinec"
            },
            {
                "value": 66449,
                "label": "Vizovice",
                "label_ar": "فيزوفيتسي",
                "label_fr": "Vizovice"
            },
            {
                "value": 66291,
                "label": "Kralupy nad Vltavou",
                "label_ar": "كرالوبي ناد فلتافو",
                "label_fr": "Kralupy nad Vltavou"
            },
            {
                "value": 66989,
                "label": "Seibersdorf",
                "label_ar": "سيبرسدورف",
                "label_fr": "Seibersdorf"
            },
            {
                "value": 66924,
                "label": "Kostelec nad Cernymi Lesy",
                "label_ar": "Kostelec nad Cernymi Lesy",
                "label_fr": "Kostelec nad Cernymi Lesy"
            },
            {
                "value": 66561,
                "label": "Harrachov",
                "label_ar": "هاراشوف",
                "label_fr": "Harrachov"
            },
            {
                "value": 66340,
                "label": "Znojmo",
                "label_ar": "زنويمو",
                "label_fr": "Znojmo"
            },
            {
                "value": 66251,
                "label": "Ceska Ves",
                "label_ar": "سيسكا فيس",
                "label_fr": "Ceska Ves"
            },
            {
                "value": 67112,
                "label": "Sanov",
                "label_ar": "سانوف",
                "label_fr": "Sanov"
            },
            {
                "value": 67099,
                "label": "Zizkov",
                "label_ar": "زيزكوف",
                "label_fr": "Zizkov"
            },
            {
                "value": 66839,
                "label": "Rozsochatec",
                "label_ar": "Rozsochatec",
                "label_fr": "Rozsochatec"
            },
            {
                "value": 66516,
                "label": "Novosedly",
                "label_ar": "مستجد",
                "label_fr": "Novosiblement"
            },
            {
                "value": 66349,
                "label": "Dolni Nemci",
                "label_ar": "دولني نمشي",
                "label_fr": "Dolni Nemci"
            },
            {
                "value": 66466,
                "label": "Rousinov",
                "label_ar": "روسينوف",
                "label_fr": "Rousinov"
            },
            {
                "value": 66648,
                "label": "Jinocany",
                "label_ar": "جينوكاني",
                "label_fr": "Jinocany"
            },
            {
                "value": 66753,
                "label": "Dolni Becva",
                "label_ar": "دولني بيكفا",
                "label_fr": "Dolni Becva"
            },
            {
                "value": 66455,
                "label": "Mikulov",
                "label_ar": "ميكولوف",
                "label_fr": "Mikulov"
            },
            {
                "value": 66790,
                "label": "Velvary",
                "label_ar": "فلفاري",
                "label_fr": "Velvary"
            },
            {
                "value": 66448,
                "label": "Velke Heraltice",
                "label_ar": "فيلك هيرالتيس",
                "label_fr": "Velke Heraltice"
            },
            {
                "value": 66276,
                "label": "Susice",
                "label_ar": "سوسيسي",
                "label_fr": "Susice"
            },
            {
                "value": 66473,
                "label": "Marianska",
                "label_ar": "ماريانسكا",
                "label_fr": "Marianska"
            },
            {
                "value": 66954,
                "label": "Hostin",
                "label_ar": "هوستن",
                "label_fr": "Hostin"
            },
            {
                "value": 66397,
                "label": "Mladá Boleslav",
                "label_ar": "ملادا بوليسلاف",
                "label_fr": "Mladá Boleslav"
            },
            {
                "value": 66366,
                "label": "Bilovice",
                "label_ar": "بيلوفيتسي",
                "label_fr": "Bilovice"
            },
            {
                "value": 66645,
                "label": "Slapanice",
                "label_ar": "سلابانيس",
                "label_fr": "Slapanice"
            },
            {
                "value": 66334,
                "label": "Lutin",
                "label_ar": "لوتين",
                "label_fr": "Lutin"
            },
            {
                "value": 66574,
                "label": "Lampertice",
                "label_ar": "لامبيرتيس",
                "label_fr": "Lampertice"
            },
            {
                "value": 66626,
                "label": "Veverska Bityska",
                "label_ar": "فيفيرسكا بييتسكا",
                "label_fr": "Veverska Bityska"
            },
            {
                "value": 66967,
                "label": "Sviadnov",
                "label_ar": "سفيادنوف",
                "label_fr": "Sviadnov"
            },
            {
                "value": 66195,
                "label": "Jaroslav",
                "label_ar": "ياروسلاف",
                "label_fr": "Jaroslav"
            },
            {
                "value": 66707,
                "label": "Loukov",
                "label_ar": "لوكوف",
                "label_fr": "Loukov"
            },
            {
                "value": 66279,
                "label": "Velke Hydcice",
                "label_ar": "فيلك هيدسيس",
                "label_fr": "Velke Hydcice"
            },
            {
                "value": 66763,
                "label": "Petrovice u Karvine",
                "label_ar": "بيتروفيتش يو كارفين",
                "label_fr": "Petrovice u Karvine"
            },
            {
                "value": 66393,
                "label": "Modrice",
                "label_ar": "مودريس",
                "label_fr": "Modrice"
            },
            {
                "value": 66310,
                "label": "Podivin",
                "label_ar": "بوديفين",
                "label_fr": "Podivin"
            },
            {
                "value": 66859,
                "label": "Frantiskovy Lazne",
                "label_ar": "فرانتيشكوفي لازن",
                "label_fr": "Frantiskovy Lazne"
            },
            {
                "value": 66484,
                "label": "Jirny",
                "label_ar": "جيرني",
                "label_fr": "Jirny"
            },
            {
                "value": 66821,
                "label": "Hronov",
                "label_ar": "هرونوف",
                "label_fr": "Hronov"
            },
            {
                "value": 66882,
                "label": "Vsechovice",
                "label_ar": "فيشوفيتسي",
                "label_fr": "Vsechovice"
            },
            {
                "value": 66655,
                "label": "Svojkov",
                "label_ar": "سفويكوف",
                "label_fr": "Svojkov"
            },
            {
                "value": 66673,
                "label": "Nechanice",
                "label_ar": "نشانيك",
                "label_fr": "Nechanice"
            },
            {
                "value": 66230,
                "label": "Zeliv",
                "label_ar": "زيليف",
                "label_fr": "Zeliv"
            },
            {
                "value": 66826,
                "label": "Kvetnice",
                "label_ar": "كفيتنيتسي",
                "label_fr": "Kvetnice"
            },
            {
                "value": 66977,
                "label": "Repy",
                "label_ar": "رد",
                "label_fr": "Repy"
            },
            {
                "value": 66508,
                "label": "Prestice",
                "label_ar": "Prestice",
                "label_fr": "Prestice"
            },
            {
                "value": 66635,
                "label": "Habartov",
                "label_ar": "هابارتوف",
                "label_fr": "Habartov"
            },
            {
                "value": 66478,
                "label": "Vysker",
                "label_ar": "فيسكر",
                "label_fr": "Vysker"
            },
            {
                "value": 66390,
                "label": "Mnisek pod Brdy",
                "label_ar": "منيسك بود بردي",
                "label_fr": "Mnisek pod Brdy"
            },
            {
                "value": 66544,
                "label": "Rudna",
                "label_ar": "رودنا",
                "label_fr": "Rudna"
            },
            {
                "value": 66714,
                "label": "Jedovnice",
                "label_ar": "جيدوفنيتسي",
                "label_fr": "Jedovnice"
            },
            {
                "value": 66559,
                "label": "Sumice",
                "label_ar": "سوميس",
                "label_fr": "Sumice"
            },
            {
                "value": 67147,
                "label": "Rataje",
                "label_ar": "راتاجي",
                "label_fr": "Rataje"
            },
            {
                "value": 66287,
                "label": "Krivoklat",
                "label_ar": "كريفوكلات",
                "label_fr": "Krivoklat"
            },
            {
                "value": 66921,
                "label": "Veverske Kninice",
                "label_ar": "فيفيرسكي كنينيس",
                "label_fr": "Veverske Kninice"
            },
            {
                "value": 66219,
                "label": "Svetla nad Sazavou",
                "label_ar": "سفيتلا ناد سازافو",
                "label_fr": "Svetla nad Sazavou"
            },
            {
                "value": 66625,
                "label": "Lhotka",
                "label_ar": "Lhotka",
                "label_fr": "Lhotka"
            },
            {
                "value": 66887,
                "label": "Kunstat",
                "label_ar": "كونستات",
                "label_fr": "Kunstat"
            },
            {
                "value": 66597,
                "label": "Lovosice",
                "label_ar": "لوفوسيتسي",
                "label_fr": "Lovosice"
            },
            {
                "value": 66770,
                "label": "Otrokovice",
                "label_ar": "أوتروكوفيتسي",
                "label_fr": "Otrokovice"
            },
            {
                "value": 67013,
                "label": "Hvozd",
                "label_ar": "هفوزد",
                "label_fr": "Hvozd"
            },
            {
                "value": 66915,
                "label": "Obristvi",
                "label_ar": "Obristvi",
                "label_fr": "Obristvi"
            },
            {
                "value": 66206,
                "label": "Strazek",
                "label_ar": "سترازيك",
                "label_fr": "Strazek"
            },
            {
                "value": 66969,
                "label": "Hudlice",
                "label_ar": "هودليس",
                "label_fr": "Hudlice"
            },
            {
                "value": 66180,
                "label": "Lanskroun",
                "label_ar": "Lanskroun",
                "label_fr": "Lanskroun"
            },
            {
                "value": 66706,
                "label": "Libocany",
                "label_ar": "ليبوكاني",
                "label_fr": "Libocany"
            },
            {
                "value": 66517,
                "label": "Hostomice",
                "label_ar": "هوستوميس",
                "label_fr": "Hostomice"
            },
            {
                "value": 66346,
                "label": "Ricany",
                "label_ar": "ريكاني",
                "label_fr": "Ricany"
            },
            {
                "value": 66588,
                "label": "Luby",
                "label_ar": "لوبي",
                "label_fr": "Luby"
            },
            {
                "value": 67139,
                "label": "Koci",
                "label_ar": "كوجي",
                "label_fr": "Koci"
            },
            {
                "value": 66550,
                "label": "Dvorce",
                "label_ar": "دفور",
                "label_fr": "Dvorce"
            },
            {
                "value": 66624,
                "label": "Kacice",
                "label_ar": "كاسيسي",
                "label_fr": "Kacice"
            },
            {
                "value": 67143,
                "label": "Podborany",
                "label_ar": "بودبوراني",
                "label_fr": "Podborany"
            },
            {
                "value": 66941,
                "label": "Nucice",
                "label_ar": "نويس",
                "label_fr": "Nucice"
            },
            {
                "value": 66235,
                "label": "Jablunka",
                "label_ar": "جابلونكا",
                "label_fr": "Jablunka"
            },
            {
                "value": 67126,
                "label": "Kyjov",
                "label_ar": "كيجوف",
                "label_fr": "Kyjov"
            },
            {
                "value": 67095,
                "label": "Krenovice",
                "label_ar": "كرينوفيتسي",
                "label_fr": "Krenovice"
            },
            {
                "value": 66234,
                "label": "Zlín",
                "label_ar": "زلين",
                "label_fr": "Zlín"
            },
            {
                "value": 66601,
                "label": "Libos",
                "label_ar": "ليبوس",
                "label_fr": "Libos"
            },
            {
                "value": 67137,
                "label": "Cerna za Bory",
                "label_ar": "سيرنا زا بوري",
                "label_fr": "Cerna za Bory"
            },
            {
                "value": 66415,
                "label": "Kosmonosy",
                "label_ar": "كوسمونوسي",
                "label_fr": "Kosmonosy"
            },
            {
                "value": 66491,
                "label": "Pchery",
                "label_ar": "Pchery",
                "label_fr": "Pchery"
            },
            {
                "value": 66332,
                "label": "Dobre",
                "label_ar": "دوبري",
                "label_fr": "Dobre"
            },
            {
                "value": 67156,
                "label": "Cejc",
                "label_ar": "سيجك",
                "label_fr": "Cejc"
            },
            {
                "value": 67116,
                "label": "Ritka",
                "label_ar": "ريتكا",
                "label_fr": "Ritka"
            },
            {
                "value": 66294,
                "label": "Drnovice",
                "label_ar": "درنوفيتسي",
                "label_fr": "Drnovice"
            },
            {
                "value": 66716,
                "label": "Zabreh",
                "label_ar": "زابرة",
                "label_fr": "Zabreh"
            },
            {
                "value": 66975,
                "label": "Strasice",
                "label_ar": "ستراسيس",
                "label_fr": "Strasice"
            },
            {
                "value": 66535,
                "label": "Velke Losiny",
                "label_ar": "فيلك لوسيني",
                "label_fr": "Velke Losiny"
            },
            {
                "value": 66494,
                "label": "Mnichovo Hradiště",
                "label_ar": "Mnichovo Hradiště",
                "label_fr": "Mnichovo Hradiště"
            },
            {
                "value": 66387,
                "label": "Žatec",
                "label_ar": "Žatec",
                "label_fr": "Žatec"
            },
            {
                "value": 66353,
                "label": "Dobriv",
                "label_ar": "دوبريف",
                "label_fr": "Dobriv"
            },
            {
                "value": 66351,
                "label": "Kostelec nad Orlici",
                "label_ar": "Kostelec ناد أورليتشي",
                "label_fr": "Kostelec nad Orlici"
            },
            {
                "value": 66446,
                "label": "Polna",
                "label_ar": "بولنا",
                "label_fr": "Polna"
            },
            {
                "value": 66815,
                "label": "Hlubocepy",
                "label_ar": "هلوبوسيبي",
                "label_fr": "Hlubocépie"
            },
            {
                "value": 66572,
                "label": "Dily",
                "label_ar": "ديلي",
                "label_fr": "Dily"
            },
            {
                "value": 66268,
                "label": "Opava",
                "label_ar": "أوبافا",
                "label_fr": "Opava"
            },
            {
                "value": 66710,
                "label": "Nepomysl",
                "label_ar": "نيبوميسل",
                "label_fr": "Népomysl"
            },
            {
                "value": 66427,
                "label": "Ctidruzice",
                "label_ar": "Ctidruzice",
                "label_fr": "Ctidruzice"
            },
            {
                "value": 67001,
                "label": "Svihov",
                "label_ar": "سفيهوف",
                "label_fr": "Svihov"
            },
            {
                "value": 66193,
                "label": "Milotice",
                "label_ar": "ميلوتيس",
                "label_fr": "Milotice"
            },
            {
                "value": 66522,
                "label": "Cerveny Kostelec",
                "label_ar": "سيرفيني كوستيليك",
                "label_fr": "Cerveny Kostelec"
            },
            {
                "value": 67115,
                "label": "Milonice",
                "label_ar": "ميلونيس",
                "label_fr": "Milonice"
            },
            {
                "value": 66360,
                "label": "Mratin",
                "label_ar": "ميراتين",
                "label_fr": "Mratin"
            },
            {
                "value": 66247,
                "label": "Teplice",
                "label_ar": "تبليتسه",
                "label_fr": "Teplice"
            },
            {
                "value": 67017,
                "label": "Hrusovany u Brna",
                "label_ar": "هروسوفاني أو برنا",
                "label_fr": "Hrusovany u Brna"
            },
            {
                "value": 66169,
                "label": "Chrudim",
                "label_ar": "شروديم",
                "label_fr": "Chrudim"
            },
            {
                "value": 67029,
                "label": "Moravsky Pisek",
                "label_ar": "مورافسكي بيسيك",
                "label_fr": "Moravsky Pisek"
            },
            {
                "value": 66246,
                "label": "Ostrov",
                "label_ar": "أوستروف",
                "label_fr": "Ostrov"
            },
            {
                "value": 66749,
                "label": "Arnoltice",
                "label_ar": "ارنولتيس",
                "label_fr": "Arnoltice"
            },
            {
                "value": 66199,
                "label": "Hodonín",
                "label_ar": "Hodonín",
                "label_fr": "Hodonín"
            },
            {
                "value": 67094,
                "label": "Klicany",
                "label_ar": "كليكانى",
                "label_fr": "Klicany"
            },
            {
                "value": 66693,
                "label": "Vidce",
                "label_ar": "فيدس",
                "label_fr": "Vidce"
            },
            {
                "value": 66843,
                "label": "Ricany",
                "label_ar": "ريكاني",
                "label_fr": "Ricany"
            },
            {
                "value": 66580,
                "label": "Planany",
                "label_ar": "بلاناني",
                "label_fr": "Planany"
            },
            {
                "value": 66357,
                "label": "Merklin",
                "label_ar": "ميركلن",
                "label_fr": "Merklin"
            },
            {
                "value": 66643,
                "label": "Zatcany",
                "label_ar": "زتكاني",
                "label_fr": "Zatcany"
            },
            {
                "value": 67151,
                "label": "Sterboholy",
                "label_ar": "ستيربوهولي",
                "label_fr": "Sterboholy"
            },
            {
                "value": 66798,
                "label": "Kostelec na Hane",
                "label_ar": "Kostelec na Hane",
                "label_fr": "Kostelec na Hane"
            },
            {
                "value": 66918,
                "label": "Opatovice nad Labem",
                "label_ar": "أوباتوفيس ناد لابيم",
                "label_fr": "Opatovice nad Labem"
            },
            {
                "value": 66778,
                "label": "Nove Dvory",
                "label_ar": "نوفي دفوري",
                "label_fr": "Nove Dvory"
            },
            {
                "value": 67130,
                "label": "Dolany",
                "label_ar": "دولاني",
                "label_fr": "Dolany"
            },
            {
                "value": 66841,
                "label": "Blovice",
                "label_ar": "بلوفيس",
                "label_fr": "Blovice"
            },
            {
                "value": 67136,
                "label": "Rosice",
                "label_ar": "روزيس",
                "label_fr": "Rosice"
            },
            {
                "value": 66999,
                "label": "Jesenik",
                "label_ar": "جيسينيك",
                "label_fr": "Jesenik"
            },
            {
                "value": 66952,
                "label": "Vratkov",
                "label_ar": "فراتكوف",
                "label_fr": "Vratkov"
            },
            {
                "value": 67124,
                "label": "Kopidlno",
                "label_ar": "كوبيدلنو",
                "label_fr": "Kopidlno"
            },
            {
                "value": 66886,
                "label": "Zdice",
                "label_ar": "زديس",
                "label_fr": "Zdice"
            },
            {
                "value": 66764,
                "label": "Polepy",
                "label_ar": "بوليبي",
                "label_fr": "Polepy"
            },
            {
                "value": 67155,
                "label": "Pocenice",
                "label_ar": "بوسينيس",
                "label_fr": "Pocenice"
            },
            {
                "value": 66592,
                "label": "Cernotin",
                "label_ar": "سيرنوتين",
                "label_fr": "Cernotine"
            },
            {
                "value": 67082,
                "label": "Blucina",
                "label_ar": "بلوسينا",
                "label_fr": "Blucina"
            },
            {
                "value": 66442,
                "label": "Lhota",
                "label_ar": "لهوتا",
                "label_fr": "Lhota"
            },
            {
                "value": 66362,
                "label": "Ejpovice",
                "label_ar": "إيبوفيتسي",
                "label_fr": "Ejpovice"
            },
            {
                "value": 66266,
                "label": "Hosteradice",
                "label_ar": "Hosteradice",
                "label_fr": "Hosteradice"
            },
            {
                "value": 67028,
                "label": "Kouty",
                "label_ar": "قوتي",
                "label_fr": "Kouty"
            },
            {
                "value": 67148,
                "label": "Francova Lhota",
                "label_ar": "فرانكوفا لهوتا",
                "label_fr": "Francova Lhota"
            },
            {
                "value": 66992,
                "label": "Plouznice",
                "label_ar": "بلوزنيتسي",
                "label_fr": "Plouznice"
            },
            {
                "value": 66960,
                "label": "Vrane nad Vltavou",
                "label_ar": "فران ناد فلتافو",
                "label_fr": "Vrane nad Vltavou"
            },
            {
                "value": 66727,
                "label": "Radnice",
                "label_ar": "رادنيتسي",
                "label_fr": "Radnice"
            },
            {
                "value": 66265,
                "label": "Brezi",
                "label_ar": "بريزي",
                "label_fr": "Brezi"
            },
            {
                "value": 66228,
                "label": "Děčín",
                "label_ar": "دين",
                "label_fr": "Děčín"
            },
            {
                "value": 66719,
                "label": "Sedliste",
                "label_ar": "سيدليست",
                "label_fr": "Sedliste"
            },
            {
                "value": 66595,
                "label": "Malenovice",
                "label_ar": "مالينوفيتسي",
                "label_fr": "Malenovice"
            },
            {
                "value": 66834,
                "label": "Stezery",
                "label_ar": "ستيزري",
                "label_fr": "Stezery"
            },
            {
                "value": 66569,
                "label": "Osecna",
                "label_ar": "أوسيكا",
                "label_fr": "Osecna"
            },
            {
                "value": 66452,
                "label": "Bavory",
                "label_ar": "شهي",
                "label_fr": "Bavory"
            },
            {
                "value": 66799,
                "label": "Krmelin",
                "label_ar": "كرميلين",
                "label_fr": "Krmelin"
            },
            {
                "value": 66785,
                "label": "Mlazovice",
                "label_ar": "ملازوفيتسي",
                "label_fr": "Mlazovice"
            },
            {
                "value": 66326,
                "label": "Zdiby",
                "label_ar": "زديبي",
                "label_fr": "Zdiby"
            },
            {
                "value": 66192,
                "label": "Kyjov",
                "label_ar": "كيجوف",
                "label_fr": "Kyjov"
            },
            {
                "value": 66656,
                "label": "Kozmice",
                "label_ar": "كوزميتسي",
                "label_fr": "Kozmice"
            },
            {
                "value": 66692,
                "label": "Rajec-Jestrebi",
                "label_ar": "راجيك جيستريبي",
                "label_fr": "Rajec-Jestrebi"
            },
            {
                "value": 66348,
                "label": "Uhersky Brod",
                "label_ar": "اهيرسكي برود",
                "label_fr": "Uhersky Brod"
            },
            {
                "value": 66472,
                "label": "Dvur Kralove nad Labem",
                "label_ar": "دفور كرالوف ناد لابيم",
                "label_fr": "Dvur Kralove nad Labem"
            },
            {
                "value": 66758,
                "label": "Ohrobec",
                "label_ar": "أوهروبك",
                "label_fr": "Ohrobec"
            },
            {
                "value": 67107,
                "label": "Rosice",
                "label_ar": "روزيس",
                "label_fr": "Rosice"
            },
            {
                "value": 66737,
                "label": "Velke Opatovice",
                "label_ar": "فيلك أوباتوفيتسي",
                "label_fr": "Velke Opatovice"
            },
            {
                "value": 66880,
                "label": "Doudleby nad Orlici",
                "label_ar": "دودلبي ناد أورليتشي",
                "label_fr": "Doudleby nad Orlici"
            },
            {
                "value": 66585,
                "label": "Sternberk",
                "label_ar": "ستيرنبيرك",
                "label_fr": "Sternberk"
            },
            {
                "value": 67123,
                "label": "Bradlec",
                "label_ar": "برادليك",
                "label_fr": "Bradlec"
            },
            {
                "value": 66677,
                "label": "Kostice",
                "label_ar": "كوستيس",
                "label_fr": "Kostice"
            },
            {
                "value": 66267,
                "label": "Jistebnik",
                "label_ar": "جيستبنيك",
                "label_fr": "Jistebnik"
            },
            {
                "value": 66196,
                "label": "Uhersky Ostroh",
                "label_ar": "أوهيرسكي أوستروه",
                "label_fr": "Uhersky Ostroh"
            },
            {
                "value": 67117,
                "label": "Kuncina",
                "label_ar": "كونسينا",
                "label_fr": "Kuncina"
            },
            {
                "value": 67089,
                "label": "Stara Voda",
                "label_ar": "ستارا فودا",
                "label_fr": "Stara Voda"
            },
            {
                "value": 66614,
                "label": "Velke Popovice",
                "label_ar": "فيلك بوبوفيتسي",
                "label_fr": "Velke Popovice"
            },
            {
                "value": 66911,
                "label": "Mseno",
                "label_ar": "ميسنو",
                "label_fr": "Mseno"
            },
            {
                "value": 67030,
                "label": "Vysoka",
                "label_ar": "فيسوكا",
                "label_fr": "Vysoka"
            },
            {
                "value": 66207,
                "label": "Kutná Hora",
                "label_ar": "كوتنا هورا",
                "label_fr": "Kutná Hora"
            },
            {
                "value": 66651,
                "label": "Dalovice",
                "label_ar": "دالوفيتسي",
                "label_fr": "Dalovice"
            },
            {
                "value": 66515,
                "label": "Slavkov u Brna",
                "label_ar": "سلافكوف و برنا",
                "label_fr": "Slavkov u Brna"
            },
            {
                "value": 66604,
                "label": "Dolni Sytova",
                "label_ar": "دولني سيتوفا",
                "label_fr": "Dolni Sytova"
            },
            {
                "value": 66765,
                "label": "Cerekvice nad Loucnou",
                "label_ar": "سيريكفيس ناد لوكنو",
                "label_fr": "Cerekvice nad Loucnou"
            },
            {
                "value": 66724,
                "label": "Vyprachtice",
                "label_ar": "Vyprachtice",
                "label_fr": "Vyprachtice"
            },
            {
                "value": 66337,
                "label": "Turnov",
                "label_ar": "تورنوف",
                "label_fr": "Turnov"
            },
            {
                "value": 66402,
                "label": "Rychvald",
                "label_ar": "ريتشفالد",
                "label_fr": "Rychvald"
            },
            {
                "value": 66306,
                "label": "Plana",
                "label_ar": "بلانا",
                "label_fr": "Plana"
            },
            {
                "value": 66919,
                "label": "Kdyne",
                "label_ar": "كدين",
                "label_fr": "Kdyne"
            },
            {
                "value": 66825,
                "label": "Zbysov",
                "label_ar": "زبيسوف",
                "label_fr": "Zbysov"
            },
            {
                "value": 66275,
                "label": "Milovice",
                "label_ar": "ميلوفيتسي",
                "label_fr": "Milovice"
            },
            {
                "value": 66426,
                "label": "Rovna",
                "label_ar": "روفنا",
                "label_fr": "Rovna"
            },
            {
                "value": 66861,
                "label": "Horni Lutyne",
                "label_ar": "هورني لوتين",
                "label_fr": "Horni Lutyne"
            },
            {
                "value": 66406,
                "label": "Brandys nad Labem-Stara Boleslav",
                "label_ar": "برانديز ناد لابيم ستارا بوليسلاف",
                "label_fr": "Brandys nad Labem-Stara Boleslav"
            },
            {
                "value": 66810,
                "label": "Horice",
                "label_ar": "هوريس",
                "label_fr": "Horice"
            },
            {
                "value": 66581,
                "label": "Dymokury",
                "label_ar": "ديموكري",
                "label_fr": "Dymokury"
            },
            {
                "value": 66813,
                "label": "Haje",
                "label_ar": "الحاج",
                "label_fr": "Haje"
            },
            {
                "value": 66352,
                "label": "Lysa nad Labem",
                "label_ar": "ليزا ناد لابم",
                "label_fr": "Lysa nad Labem"
            },
            {
                "value": 66412,
                "label": "Jindrichuv Hradec",
                "label_ar": "جيندريشوف هراديك",
                "label_fr": "Jindrichuv Hradec"
            },
            {
                "value": 66548,
                "label": "Okrisky",
                "label_ar": "أوكريسكي",
                "label_fr": "Okrisky"
            },
            {
                "value": 66931,
                "label": "Detmarovice",
                "label_ar": "ديتمروفيس",
                "label_fr": "Detmarovice"
            },
            {
                "value": 66437,
                "label": "Lodenice",
                "label_ar": "لودينيس",
                "label_fr": "Lodenice"
            },
            {
                "value": 66372,
                "label": "Hustopece",
                "label_ar": "Hustopece",
                "label_fr": "Hustopece"
            },
            {
                "value": 66221,
                "label": "Bojanov",
                "label_ar": "بويانوف",
                "label_fr": "Bojanov"
            },
            {
                "value": 66647,
                "label": "Hlasna Treban",
                "label_ar": "هلسنا تريبان",
                "label_fr": "Hlasna Treban"
            },
            {
                "value": 66974,
                "label": "Loucen",
                "label_ar": "Loucen",
                "label_fr": "Loucen"
            },
            {
                "value": 66611,
                "label": "Dolni Kounice",
                "label_ar": "دولني كونيس",
                "label_fr": "Dolni Kounice"
            },
            {
                "value": 66866,
                "label": "Lomnice nad Popelkou",
                "label_ar": "لومنيتسي ناد بوبلكو",
                "label_fr": "Lomnice nad Popelkou"
            },
            {
                "value": 66773,
                "label": "Vcelna",
                "label_ar": "فسيلنا",
                "label_fr": "Vcelna"
            },
            {
                "value": 67152,
                "label": "Tman",
                "label_ar": "تمان",
                "label_fr": "Tman"
            },
            {
                "value": 66801,
                "label": "Bilina",
                "label_ar": "بيلينا",
                "label_fr": "Bilina"
            },
            {
                "value": 66563,
                "label": "Letovice",
                "label_ar": "ليتوفيتسي",
                "label_fr": "Letovice"
            },
            {
                "value": 67114,
                "label": "Sucha Loz",
                "label_ar": "سوتشا لوز",
                "label_fr": "Sucha Loz"
            },
            {
                "value": 67140,
                "label": "Mestec",
                "label_ar": "ميستيك",
                "label_fr": "Mestec"
            },
            {
                "value": 66578,
                "label": "Trutnov",
                "label_ar": "تروتنوف",
                "label_fr": "Trutnov"
            },
            {
                "value": 66331,
                "label": "Kratonohy",
                "label_ar": "كراتونوهى",
                "label_fr": "Kratonohy"
            },
            {
                "value": 66168,
                "label": "Vlcovice",
                "label_ar": "فلكوفيتسي",
                "label_fr": "Vlcovice"
            },
            {
                "value": 66760,
                "label": "Cervene Pecky",
                "label_ar": "سيرفين بيكي",
                "label_fr": "Cervène Pecky"
            },
            {
                "value": 66874,
                "label": "Krtiny",
                "label_ar": "كرتيني",
                "label_fr": "Krtiny"
            },
            {
                "value": 66386,
                "label": "Most",
                "label_ar": "معظم",
                "label_fr": "Plus"
            },
            {
                "value": 66262,
                "label": "Hodice",
                "label_ar": "هوديس",
                "label_fr": "Hodice"
            },
            {
                "value": 66734,
                "label": "Kamenny Privoz",
                "label_ar": "كاميني بريفوز",
                "label_fr": "Kamenny Privoz"
            },
            {
                "value": 66653,
                "label": "Dolni Benesov",
                "label_ar": "دولني بينيسوف",
                "label_fr": "Dolni Benesov"
            },
            {
                "value": 66336,
                "label": "Zabcice",
                "label_ar": "زابتشي",
                "label_fr": "Zabcice"
            },
            {
                "value": 66897,
                "label": "Stankov",
                "label_ar": "ستانكوف",
                "label_fr": "Stankov"
            },
            {
                "value": 66986,
                "label": "Kylesovice",
                "label_ar": "كيليسوفيتسي",
                "label_fr": "Kylesovice"
            },
            {
                "value": 66784,
                "label": "Stoky",
                "label_ar": "ستوك",
                "label_fr": "Stoky"
            },
            {
                "value": 66320,
                "label": "Hradec Králové",
                "label_ar": "هراديك كرالوف",
                "label_fr": "Hradec Králové"
            },
            {
                "value": 66421,
                "label": "Skutec",
                "label_ar": "سكوتيك",
                "label_fr": "Skutec"
            },
            {
                "value": 66430,
                "label": "Batelov",
                "label_ar": "باتيلوف",
                "label_fr": "Batelov"
            },
            {
                "value": 66779,
                "label": "Prikazy",
                "label_ar": "بريكازي",
                "label_fr": "Prikazy"
            },
            {
                "value": 66701,
                "label": "Prachatice",
                "label_ar": "براشاتيس",
                "label_fr": "Prachatice"
            },
            {
                "value": 66666,
                "label": "Jesenice",
                "label_ar": "جيسينيس",
                "label_fr": "Jesenice"
            },
            {
                "value": 66520,
                "label": "Stochov",
                "label_ar": "ستوتشوف",
                "label_fr": "Stochov"
            },
            {
                "value": 66270,
                "label": "Jaromer",
                "label_ar": "يارومير",
                "label_fr": "Jaromer"
            },
            {
                "value": 67073,
                "label": "Novy Oldrichov",
                "label_ar": "نوفي أولدريتشوف",
                "label_fr": "Novy Oldrichov"
            },
            {
                "value": 66894,
                "label": "Prazmo",
                "label_ar": "برازمو",
                "label_fr": "Prazmo"
            },
            {
                "value": 66504,
                "label": "Sedlcany",
                "label_ar": "Sedlcany",
                "label_fr": "Sedlcany"
            },
            {
                "value": 66800,
                "label": "Borovany",
                "label_ar": "بوروفاني",
                "label_fr": "Borovany"
            },
            {
                "value": 66733,
                "label": "Pecky",
                "label_ar": "بيكي",
                "label_fr": "Pecky"
            },
            {
                "value": 66244,
                "label": "Krnov",
                "label_ar": "كرنوف",
                "label_fr": "Krnov"
            },
            {
                "value": 66672,
                "label": "Kraliky",
                "label_ar": "كراليكي",
                "label_fr": "Kraliky"
            },
            {
                "value": 66639,
                "label": "Varnsdorf",
                "label_ar": "فارنسدورف",
                "label_fr": "Varnsdorf"
            },
            {
                "value": 66637,
                "label": "Vsetaty",
                "label_ar": "فستاتي",
                "label_fr": "Vsetaty"
            },
            {
                "value": 66659,
                "label": "Vrchlabi",
                "label_ar": "فرتشلابي",
                "label_fr": "Vrchlabi"
            },
            {
                "value": 66869,
                "label": "Ostromer",
                "label_ar": "أوسترومير",
                "label_fr": "Ostromer"
            },
            {
                "value": 66175,
                "label": "Valasske Klobouky",
                "label_ar": "فالاسكي كلوبوكي",
                "label_fr": "Valasske Klobouky"
            },
            {
                "value": 66536,
                "label": "Lhota pod Libcany",
                "label_ar": "Lhota pod Libcany",
                "label_fr": "Lhota pod Libcany"
            },
            {
                "value": 66586,
                "label": "Telnice",
                "label_ar": "Telnice",
                "label_fr": "Telnice"
            },
            {
                "value": 66871,
                "label": "Horni Briza",
                "label_ar": "هورني بريزا",
                "label_fr": "Horni Briza"
            },
            {
                "value": 67053,
                "label": "Český Krumlov",
                "label_ar": "تشيسكي كروملوف",
                "label_fr": "Český Krumlov"
            },
            {
                "value": 66832,
                "label": "Kralice na Hane",
                "label_ar": "كراليس نا هان",
                "label_fr": "Kralice na Hane"
            },
            {
                "value": 66493,
                "label": "Tremosna",
                "label_ar": "تريموزنا",
                "label_fr": "Tremosna"
            },
            {
                "value": 67067,
                "label": "Oparany",
                "label_ar": "أوباراني",
                "label_fr": "Oparany"
            },
            {
                "value": 66359,
                "label": "Kbely",
                "label_ar": "كبيلي",
                "label_fr": "Kbely"
            },
            {
                "value": 66524,
                "label": "Drisy",
                "label_ar": "دريسي",
                "label_fr": "Drisy"
            },
            {
                "value": 66883,
                "label": "Lazne Libverda",
                "label_ar": "Lazne Libverda",
                "label_fr": "Lazne Libverda"
            },
            {
                "value": 66190,
                "label": "Pilsen",
                "label_ar": "بيلسن",
                "label_fr": "Pilsen"
            },
            {
                "value": 66573,
                "label": "Sobeslav",
                "label_ar": "سوبيسلاف",
                "label_fr": "Sobeslav"
            },
            {
                "value": 66962,
                "label": "Tuchlovice",
                "label_ar": "توشلوفيتسي",
                "label_fr": "Tuchlovice"
            },
            {
                "value": 66370,
                "label": "Zidlochovice",
                "label_ar": "زيدلوكوفيتسي",
                "label_fr": "Zidlochovice"
            },
            {
                "value": 66469,
                "label": "Krasna Lipa",
                "label_ar": "كراسنا ليبا",
                "label_fr": "Krasna Lipa"
            },
            {
                "value": 66991,
                "label": "Straz nad Nisou",
                "label_ar": "ستراز ناد نيسو",
                "label_fr": "Straz nad Nisou"
            },
            {
                "value": 67092,
                "label": "Libis",
                "label_ar": "ليبيس",
                "label_fr": "Libis"
            },
            {
                "value": 66840,
                "label": "Nova Ves",
                "label_ar": "نوفا فيس",
                "label_fr": "Nova Ves"
            },
            {
                "value": 66739,
                "label": "Krizanov",
                "label_ar": "كريزانوف",
                "label_fr": "Krizanov"
            },
            {
                "value": 66545,
                "label": "Cvikov",
                "label_ar": "سفيكوف",
                "label_fr": "Cvikov"
            },
            {
                "value": 67036,
                "label": "Vojkovice",
                "label_ar": "فويكوفيتسي",
                "label_fr": "Vojkovice"
            },
            {
                "value": 66953,
                "label": "Ratiskovice",
                "label_ar": "راتيسكوفيتسي",
                "label_fr": "Ratiskovice"
            },
            {
                "value": 66830,
                "label": "Luze",
                "label_ar": "لوز",
                "label_fr": "Luze"
            },
            {
                "value": 66934,
                "label": "Vracov",
                "label_ar": "فراكوف",
                "label_fr": "Vracov"
            },
            {
                "value": 66829,
                "label": "Pacov",
                "label_ar": "باكوف",
                "label_fr": "Pacov"
            },
            {
                "value": 66681,
                "label": "Ujezd nad Lesy",
                "label_ar": "أوجزد ناد ليسي",
                "label_fr": "Ujezd nad Lesy"
            },
            {
                "value": 66914,
                "label": "Provodov",
                "label_ar": "بروفودوف",
                "label_fr": "Provodov"
            },
            {
                "value": 66891,
                "label": "Olovi",
                "label_ar": "أولوفي",
                "label_fr": "Olovi"
            },
            {
                "value": 66752,
                "label": "Zehun",
                "label_ar": "زيهون",
                "label_fr": "Zehun"
            },
            {
                "value": 66188,
                "label": "Hovezi",
                "label_ar": "هوفيزي",
                "label_fr": "Hovezi"
            },
            {
                "value": 66768,
                "label": "Bratrikov",
                "label_ar": "براتريكوف",
                "label_fr": "Bratrikov"
            },
            {
                "value": 66988,
                "label": "Ivanovice na Hane",
                "label_ar": "إيفانوفيتشي نا هان",
                "label_fr": "Ivanovice na Hane"
            },
            {
                "value": 66408,
                "label": "Velky Borek",
                "label_ar": "فيلكي بوريك",
                "label_fr": "Velky Borek"
            },
            {
                "value": 66225,
                "label": "Sudkov",
                "label_ar": "سودكوف",
                "label_fr": "Sudkov"
            },
            {
                "value": 66419,
                "label": "Luzna",
                "label_ar": "لوزنا",
                "label_fr": "Luzna"
            },
            {
                "value": 66447,
                "label": "Grucovice",
                "label_ar": "جروكوفيتسي",
                "label_fr": "Grucovice"
            },
            {
                "value": 66492,
                "label": "Bustehrad",
                "label_ar": "بوستيهراد",
                "label_fr": "Bustehrad"
            },
            {
                "value": 66949,
                "label": "Lipnik nad Becvou",
                "label_ar": "ليبنيك ناد بيكفو",
                "label_fr": "Lipnik nad Becvou"
            },
            {
                "value": 66913,
                "label": "Moravsky Beroun",
                "label_ar": "مورافسكي بيرون",
                "label_fr": "Moravsky Beroun"
            },
            {
                "value": 66674,
                "label": "Kaplice",
                "label_ar": "كابليس",
                "label_fr": "Kaplice"
            },
            {
                "value": 66696,
                "label": "Sobotka",
                "label_ar": "سوبوتكا",
                "label_fr": "Sobotka"
            },
            {
                "value": 67025,
                "label": "Studenka",
                "label_ar": "ستودينكا",
                "label_fr": "Studenka"
            },
            {
                "value": 66983,
                "label": "Navsi u Jablunkova",
                "label_ar": "Navsi u Jablunkova",
                "label_fr": "Navsi u Jablunkova"
            },
            {
                "value": 66593,
                "label": "Drahotuse",
                "label_ar": "دراهوتوز",
                "label_fr": "Drahotuse"
            },
            {
                "value": 66948,
                "label": "Mezibori",
                "label_ar": "ميزيبوري",
                "label_fr": "Mezibori"
            },
            {
                "value": 66910,
                "label": "Seberov",
                "label_ar": "سيبيروف",
                "label_fr": "Seberov"
            },
            {
                "value": 66623,
                "label": "Velky Osek",
                "label_ar": "فيلكي أوسيك",
                "label_fr": "Velky Osek"
            },
            {
                "value": 67090,
                "label": "Zandov",
                "label_ar": "زاندوف",
                "label_fr": "Zandov"
            },
            {
                "value": 66191,
                "label": "Naklo",
                "label_ar": "ناكلو",
                "label_fr": "Naklo"
            },
            {
                "value": 66378,
                "label": "Uhrice",
                "label_ar": "أوريس",
                "label_fr": "Uhrice"
            },
            {
                "value": 66863,
                "label": "Trmice",
                "label_ar": "ترميس",
                "label_fr": "Trmice"
            },
            {
                "value": 66482,
                "label": "Chlumec nad Cidlinou",
                "label_ar": "كلوميك ناد سيدلينو",
                "label_fr": "Chlumec nad Cidlinou"
            },
            {
                "value": 66806,
                "label": "Bilina",
                "label_ar": "بيلينا",
                "label_fr": "Bilina"
            },
            {
                "value": 66612,
                "label": "Nove Veseli",
                "label_ar": "نوف فيسيلي",
                "label_fr": "Nove Veseli"
            },
            {
                "value": 66361,
                "label": "Zajeci",
                "label_ar": "زاجيتشي",
                "label_fr": "Zajeci"
            },
            {
                "value": 66602,
                "label": "Jilove u Prahy",
                "label_ar": "جيلوف براهي",
                "label_fr": "Jilove u Prahy"
            },
            {
                "value": 66662,
                "label": "Dubi",
                "label_ar": "دوبي",
                "label_fr": "Dubi"
            },
            {
                "value": 66233,
                "label": "Lichkov",
                "label_ar": "ليشكوف",
                "label_fr": "Lichkov"
            },
            {
                "value": 66385,
                "label": "Lazne Bohdanec",
                "label_ar": "لازن بوهدانيك",
                "label_fr": "Lazne Bohdanec"
            },
            {
                "value": 66657,
                "label": "Trhovy Stepanov",
                "label_ar": "ترهوفي ستيبانوف",
                "label_fr": "Trhovy Stepanov"
            },
            {
                "value": 66761,
                "label": "Bilovice nad Svitavou",
                "label_ar": "بيلوفيس ناد سفيتافو",
                "label_fr": "Bilovice nad Svitavou"
            },
            {
                "value": 66487,
                "label": "Bystrice",
                "label_ar": "بيستريس",
                "label_fr": "Bystrice"
            },
            {
                "value": 66972,
                "label": "Bludov",
                "label_ar": "بلودوف",
                "label_fr": "Bludov"
            },
            {
                "value": 66433,
                "label": "Zeletava",
                "label_ar": "زيليتافا",
                "label_fr": "Zeletava"
            },
            {
                "value": 66428,
                "label": "Pocatky",
                "label_ar": "بوكاتكي",
                "label_fr": "Pocatky"
            },
            {
                "value": 66870,
                "label": "Velke Svatonovice",
                "label_ar": "فيلك سفاتونوفيتسي",
                "label_fr": "Velke Svatonovice"
            },
            {
                "value": 66615,
                "label": "Hostomice",
                "label_ar": "هوستوميس",
                "label_fr": "Hostomice"
            },
            {
                "value": 66540,
                "label": "Vinarice",
                "label_ar": "فيناريس",
                "label_fr": "Vinarice"
            },
            {
                "value": 66409,
                "label": "Rokycany",
                "label_ar": "روكيكاني",
                "label_fr": "Rokycany"
            },
            {
                "value": 66831,
                "label": "Vernovice",
                "label_ar": "فيرنوفيتسي",
                "label_fr": "Vernovice"
            },
            {
                "value": 66650,
                "label": "Lounovice pod Blanikem",
                "label_ar": "Lounovice جراب Blanikem",
                "label_fr": "Pod Lounovice Blanikem"
            },
            {
                "value": 66242,
                "label": "Bruntal",
                "label_ar": "برونتال",
                "label_fr": "Bruntal"
            },
            {
                "value": 66205,
                "label": "Valasske Mezirici",
                "label_ar": "فالاسكي ميزيريسي",
                "label_fr": "Valasske Mezirici"
            },
            {
                "value": 66292,
                "label": "Mělník",
                "label_ar": "مولنيك",
                "label_fr": "Mělník"
            },
            {
                "value": 66479,
                "label": "Velka Polom",
                "label_ar": "فيلكا بولوم",
                "label_fr": "Velka Polom"
            },
            {
                "value": 66922,
                "label": "Kostelni Hlavno",
                "label_ar": "كوستيلني هلافنو",
                "label_fr": "Kostelni Hlavno"
            },
            {
                "value": 66981,
                "label": "Drzkov",
                "label_ar": "درزكوف",
                "label_fr": "Drzkov"
            },
            {
                "value": 66417,
                "label": "Bela pod Bezdezem",
                "label_ar": "بيلا بود Bezdezem",
                "label_fr": "Bela pod Bezdezem"
            },
            {
                "value": 66961,
                "label": "Markvartovice",
                "label_ar": "ماركفارتوفيتسي",
                "label_fr": "Markvartovice"
            },
            {
                "value": 66323,
                "label": "Moran",
                "label_ar": "موران",
                "label_fr": "Moran"
            },
            {
                "value": 66755,
                "label": "Smrzovka",
                "label_ar": "سمرزوفكا",
                "label_fr": "Smrzovka"
            },
            {
                "value": 67020,
                "label": "Rudoltice",
                "label_ar": "رودولتيس",
                "label_fr": "Rudoltice"
            },
            {
                "value": 66702,
                "label": "Menany",
                "label_ar": "ميناني",
                "label_fr": "Menany"
            },
            {
                "value": 66973,
                "label": "Mcely",
                "label_ar": "ماكلي",
                "label_fr": "Mcely"
            },
            {
                "value": 66903,
                "label": "Borohradek",
                "label_ar": "بوروهراديك",
                "label_fr": "Borohradek"
            },
            {
                "value": 66879,
                "label": "Vinicne Sumice",
                "label_ar": "فينيكن سوميس",
                "label_fr": "Vinicne Sumice"
            },
            {
                "value": 66183,
                "label": "Jablonec nad Nisou",
                "label_ar": "جابلونك ناد نيسو",
                "label_fr": "Jablonec nad Nisou"
            },
            {
                "value": 66201,
                "label": "Raj",
                "label_ar": "راج",
                "label_fr": "Raj"
            },
            {
                "value": 67075,
                "label": "Preckov",
                "label_ar": "بريكوف",
                "label_fr": "Preckov"
            },
            {
                "value": 66754,
                "label": "Stare Sedliste",
                "label_ar": "التحديق سيدليست",
                "label_fr": "Regarder Sedliste"
            },
            {
                "value": 66367,
                "label": "Spytihnev",
                "label_ar": "سبيتينيف",
                "label_fr": "Spytihnev"
            },
            {
                "value": 66736,
                "label": "Stribrec",
                "label_ar": "ستريبريك",
                "label_fr": "Stribrec"
            },
            {
                "value": 67106,
                "label": "Dubicko",
                "label_ar": "دوبيكو",
                "label_fr": "Dubicko"
            },
            {
                "value": 66465,
                "label": "Janovice nad Uhlavou",
                "label_ar": "جانوفيس ناد أولافو",
                "label_fr": "Janovice nad Uhlavou"
            },
            {
                "value": 66721,
                "label": "Cesky Dub",
                "label_ar": "تشيسكي دوب",
                "label_fr": "Cesky Dub"
            },
            {
                "value": 66380,
                "label": "Svatava",
                "label_ar": "سفاتافا",
                "label_fr": "Svatava"
            },
            {
                "value": 66698,
                "label": "Msec",
                "label_ar": "msec",
                "label_fr": "Msec"
            },
            {
                "value": 66443,
                "label": "Nova Lhota",
                "label_ar": "نوفا لهوتا",
                "label_fr": "Nova Lhota"
            },
            {
                "value": 66876,
                "label": "Hamr",
                "label_ar": "هامر",
                "label_fr": "Hamr"
            },
            {
                "value": 67040,
                "label": "Vratimov",
                "label_ar": "فراتيموف",
                "label_fr": "Vratimov"
            },
            {
                "value": 66293,
                "label": "Vyškov",
                "label_ar": "فيشكوف",
                "label_fr": "Vyškov"
            },
            {
                "value": 66445,
                "label": "Zubri",
                "label_ar": "الزبري",
                "label_fr": "Zubri"
            },
            {
                "value": 66407,
                "label": "Skalsko",
                "label_ar": "سكالسكو",
                "label_fr": "Skalsko"
            },
            {
                "value": 66532,
                "label": "Prezletice",
                "label_ar": "بريزليتيس",
                "label_fr": "Prezletice"
            },
            {
                "value": 66634,
                "label": "Chribska",
                "label_ar": "شريبسكا",
                "label_fr": "Chribska"
            },
            {
                "value": 66214,
                "label": "Rychnov nad Kneznou",
                "label_ar": "ريتشنوف ناد كنيزنو",
                "label_fr": "Rychnov nad Kneznou"
            },
            {
                "value": 66811,
                "label": "Zelezny Brod",
                "label_ar": "زيليزني برود",
                "label_fr": "Zelezny Brod"
            },
            {
                "value": 66867,
                "label": "Perstejn",
                "label_ar": "بيرستين",
                "label_fr": "Perstejn"
            },
            {
                "value": 66868,
                "label": "Krouna",
                "label_ar": "كرونا",
                "label_fr": "Krouna"
            },
            {
                "value": 66729,
                "label": "Lachov",
                "label_ar": "لاتشوف",
                "label_fr": "Lachov"
            },
            {
                "value": 66335,
                "label": "Vsenory",
                "label_ar": "فسينوري",
                "label_fr": "Vsenory"
            },
            {
                "value": 66979,
                "label": "Hradcovice",
                "label_ar": "هرادكوفيتسي",
                "label_fr": "Hradcovice"
            },
            {
                "value": 66211,
                "label": "Caslav",
                "label_ar": "كاسلاف",
                "label_fr": "Caslav"
            },
            {
                "value": 66303,
                "label": "Tachov",
                "label_ar": "تاتشوف",
                "label_fr": "Tachov"
            },
            {
                "value": 66534,
                "label": "Hradec nad Moravici",
                "label_ar": "هراديك ناد مورافيشي",
                "label_fr": "Hradec nad Moravici"
            },
            {
                "value": 67096,
                "label": "Janovice",
                "label_ar": "جانوفيس",
                "label_fr": "Janovice"
            },
            {
                "value": 66600,
                "label": "Stribrna Skalice",
                "label_ar": "Stribrna Skalice",
                "label_fr": "Stribrna Skalice"
            },
            {
                "value": 66709,
                "label": "Rajecko",
                "label_ar": "راجيكو",
                "label_fr": "Rajecko"
            },
            {
                "value": 66963,
                "label": "Ostrozska Lhota",
                "label_ar": "أوستروزكا لهوتا",
                "label_fr": "Ostrozska Lhota"
            },
            {
                "value": 66301,
                "label": "Cesky Brod",
                "label_ar": "سيسكي برود",
                "label_fr": "Cesky Brod"
            },
            {
                "value": 66312,
                "label": "Dehylov",
                "label_ar": "ديهيلوف",
                "label_fr": "Dehylov"
            },
            {
                "value": 66481,
                "label": "Strunkovice nad Blanici",
                "label_ar": "Strunkovice nad Blanici",
                "label_fr": "Strunkovice nad Blanici"
            },
            {
                "value": 66422,
                "label": "Roudnicek",
                "label_ar": "Roudnicek",
                "label_fr": "Roudnicek"
            },
            {
                "value": 66462,
                "label": "Chocen",
                "label_ar": "شوسين",
                "label_fr": "Chocen"
            },
            {
                "value": 66562,
                "label": "Snezne",
                "label_ar": "سنيزن",
                "label_fr": "Snezne"
            },
            {
                "value": 66675,
                "label": "Bechyne",
                "label_ar": "بيتشين",
                "label_fr": "Bechyne"
            },
            {
                "value": 67091,
                "label": "Pistovice",
                "label_ar": "بيستوفيس",
                "label_fr": "Pistovice"
            },
            {
                "value": 67081,
                "label": "Statenice",
                "label_ar": "ستاتينس",
                "label_fr": "Statenice"
            },
            {
                "value": 66324,
                "label": "Petrvald",
                "label_ar": "بيترفالد",
                "label_fr": "Petrvald"
            },
            {
                "value": 66237,
                "label": "Votice",
                "label_ar": "تصويت",
                "label_fr": "Vote"
            },
            {
                "value": 66847,
                "label": "Dolni Berkovice",
                "label_ar": "دولني بيركوفيتسي",
                "label_fr": "Dolni Berkovice"
            },
            {
                "value": 66476,
                "label": "Predklasteri",
                "label_ar": "بريدكلاستي",
                "label_fr": "Predklasteri"
            },
            {
                "value": 66486,
                "label": "Doubravy",
                "label_ar": "دوبرافي",
                "label_fr": "Doubravy"
            },
            {
                "value": 66598,
                "label": "Novy Knin",
                "label_ar": "نوفي كنين",
                "label_fr": "Novy Knin"
            },
            {
                "value": 66688,
                "label": "Brod",
                "label_ar": "برود",
                "label_fr": "Brod"
            },
            {
                "value": 66174,
                "label": "Horovice",
                "label_ar": "هوروفيس",
                "label_fr": "Horovice"
            },
            {
                "value": 66652,
                "label": "Bolebor",
                "label_ar": "بوليبور",
                "label_fr": "Bolebor"
            },
            {
                "value": 66742,
                "label": "Branov",
                "label_ar": "برانوف",
                "label_fr": "Branov"
            },
            {
                "value": 66877,
                "label": "Merklin",
                "label_ar": "ميركلن",
                "label_fr": "Merklin"
            },
            {
                "value": 66374,
                "label": "Jičín",
                "label_ar": "Jičín",
                "label_fr": "Jičín"
            },
            {
                "value": 66944,
                "label": "Rudolfov",
                "label_ar": "رودولفوف",
                "label_fr": "Rudolfov"
            },
            {
                "value": 66722,
                "label": "Loket",
                "label_ar": "لوكيت",
                "label_fr": "Loket"
            },
            {
                "value": 66993,
                "label": "Benesovice",
                "label_ar": "بينيسوفيتسي",
                "label_fr": "Benesovice"
            },
            {
                "value": 67038,
                "label": "Lasenice",
                "label_ar": "لاسينيس",
                "label_fr": "Lasenice"
            },
            {
                "value": 66231,
                "label": "Karlovy Vary",
                "label_ar": "كارلوفي فاري",
                "label_fr": "Karlovy Vary"
            },
            {
                "value": 66774,
                "label": "Chvalec",
                "label_ar": "شفاليك",
                "label_fr": "Chvalec"
            },
            {
                "value": 66285,
                "label": "Chomutov",
                "label_ar": "تشوموتوف",
                "label_fr": "Chomutov"
            },
            {
                "value": 66232,
                "label": "Zampach",
                "label_ar": "زامباخ",
                "label_fr": "Zampach"
            },
            {
                "value": 66776,
                "label": "Protivin",
                "label_ar": "بروتيفين",
                "label_fr": "Protivin"
            },
            {
                "value": 66347,
                "label": "Stare Mesto",
                "label_ar": "ستاري ميستو",
                "label_fr": "Regarder Mesto"
            },
            {
                "value": 66354,
                "label": "Velke Mezirici",
                "label_ar": "فيلك ميزيريسي",
                "label_fr": "Velke Mezirici"
            },
            {
                "value": 66541,
                "label": "Kamenice",
                "label_ar": "كامينيس",
                "label_fr": "Kamenice"
            },
            {
                "value": 66745,
                "label": "Tynec nad Sazavou",
                "label_ar": "تينيك ناد سازافو",
                "label_fr": "Tynec nad Sazavou"
            },
            {
                "value": 66551,
                "label": "Chotesov",
                "label_ar": "تشوتيسوف",
                "label_fr": "Chotesov"
            },
            {
                "value": 66537,
                "label": "Hradek",
                "label_ar": "Hradek",
                "label_fr": "Hradek"
            },
            {
                "value": 66311,
                "label": "Bludovice",
                "label_ar": "بلودوفيتسي",
                "label_fr": "Bludovice"
            },
            {
                "value": 66708,
                "label": "Hospozin",
                "label_ar": "هوسبوزين",
                "label_fr": "Hospozine"
            },
            {
                "value": 66899,
                "label": "Lustenice",
                "label_ar": "لوستينيس",
                "label_fr": "Lustenice"
            },
            {
                "value": 66912,
                "label": "Stara Paka",
                "label_ar": "ستارا باكا",
                "label_fr": "Stara Paka"
            },
            {
                "value": 66828,
                "label": "Brezolupy",
                "label_ar": "بريزولوبي",
                "label_fr": "Brezolupy"
            },
            {
                "value": 66966,
                "label": "Dobra",
                "label_ar": "دوبرا",
                "label_fr": "Dobra"
            },
            {
                "value": 66333,
                "label": "Karvina",
                "label_ar": "كارفينا",
                "label_fr": "Karvina"
            },
            {
                "value": 66571,
                "label": "Mesto Touskov",
                "label_ar": "ميستو توسكوف",
                "label_fr": "Mesto Touskov"
            },
            {
                "value": 67079,
                "label": "Ustin",
                "label_ar": "أوستين",
                "label_fr": "Ustin"
            },
            {
                "value": 66690,
                "label": "Kamenice",
                "label_ar": "كامينيس",
                "label_fr": "Kamenice"
            },
            {
                "value": 66936,
                "label": "Jilemnice",
                "label_ar": "جيليمنيتسي",
                "label_fr": "Jilemnice"
            },
            {
                "value": 66667,
                "label": "Mutenice",
                "label_ar": "موتينيس",
                "label_fr": "Mutenice"
            },
            {
                "value": 66603,
                "label": "Chodova Plana",
                "label_ar": "تشودوفا بلانا",
                "label_fr": "Chodova Plana"
            },
            {
                "value": 66554,
                "label": "Choustnik",
                "label_ar": "تشوستنيك",
                "label_fr": "Choustnik"
            },
            {
                "value": 67016,
                "label": "Miroslav",
                "label_ar": "ميروسلاف",
                "label_fr": "Miroslav"
            },
            {
                "value": 66756,
                "label": "Strancice",
                "label_ar": "غريب",
                "label_fr": "Strancice"
            },
            {
                "value": 67062,
                "label": "Olomucany",
                "label_ar": "أولوموكاني",
                "label_fr": "Olomucany"
            },
            {
                "value": 67097,
                "label": "Horni Redice",
                "label_ar": "هورني ريدس",
                "label_fr": "Horni Redice"
            },
            {
                "value": 66794,
                "label": "Prlov",
                "label_ar": "برلوف",
                "label_fr": "Prlov"
            },
            {
                "value": 66401,
                "label": "Lhota",
                "label_ar": "لهوتا",
                "label_fr": "Lhota"
            },
            {
                "value": 66916,
                "label": "Lety",
                "label_ar": "ليتي",
                "label_fr": "Lety"
            },
            {
                "value": 67011,
                "label": "Libesice",
                "label_ar": "ليبسيتسي",
                "label_fr": "Libesice"
            },
            {
                "value": 66286,
                "label": "Litvinov",
                "label_ar": "ليتفينوف",
                "label_fr": "Litvinov"
            },
            {
                "value": 66280,
                "label": "Tvrdonice",
                "label_ar": "تفردونيس",
                "label_fr": "Tvrdonice"
            },
            {
                "value": 66298,
                "label": "Boskovice",
                "label_ar": "بوسكوفيتسي",
                "label_fr": "Boskovice"
            },
            {
                "value": 66460,
                "label": "Jilovec",
                "label_ar": "جيلوفيتش",
                "label_fr": "Jilovec"
            },
            {
                "value": 66358,
                "label": "Domazlice",
                "label_ar": "دومازليتسي",
                "label_fr": "Domazlice"
            },
            {
                "value": 66964,
                "label": "Zeravice",
                "label_ar": "زرافيس",
                "label_fr": "Zeravice"
            },
            {
                "value": 66555,
                "label": "Hrdejovice",
                "label_ar": "هرديوفيتسي",
                "label_fr": "Hrdejovice"
            },
            {
                "value": 67000,
                "label": "Rokytnice",
                "label_ar": "روكيتنيتسي",
                "label_fr": "Rokytnice"
            },
            {
                "value": 66685,
                "label": "Nezamyslice",
                "label_ar": "Nezamyslice",
                "label_fr": "Nezamyslice"
            },
            {
                "value": 67018,
                "label": "Oslavany",
                "label_ar": "أوسلافاني",
                "label_fr": "Oslavany"
            },
            {
                "value": 66890,
                "label": "Strelice",
                "label_ar": "ستريليس",
                "label_fr": "Strelice"
            },
            {
                "value": 67121,
                "label": "Dobrichovice",
                "label_ar": "دوبريتشوفيتسي",
                "label_fr": "Dobrichovice"
            },
            {
                "value": 66987,
                "label": "Hostice",
                "label_ar": "مضيفة",
                "label_fr": "Hostice"
            },
            {
                "value": 66315,
                "label": "Letohrad",
                "label_ar": "ليتوهراد",
                "label_fr": "Letohrad"
            },
            {
                "value": 66845,
                "label": "Line",
                "label_ar": "خط",
                "label_fr": "Ligne"
            },
            {
                "value": 66660,
                "label": "Kamyk",
                "label_ar": "كاميك",
                "label_fr": "Kamyk"
            },
            {
                "value": 67146,
                "label": "Lukov",
                "label_ar": "لوكوف",
                "label_fr": "Lukov"
            },
            {
                "value": 66751,
                "label": "Citov",
                "label_ar": "سيتوف",
                "label_fr": "Citov"
            },
            {
                "value": 66946,
                "label": "Kadan",
                "label_ar": "كادان",
                "label_fr": "Kadan"
            },
            {
                "value": 66394,
                "label": "Přerov",
                "label_ar": "بيروف",
                "label_fr": "Přerov"
            },
            {
                "value": 66240,
                "label": "Lomnice",
                "label_ar": "لومنيتسي",
                "label_fr": "Lomnice"
            },
            {
                "value": 66314,
                "label": "Stritez nad Ludinou",
                "label_ar": "سترايتز ناد لودينو",
                "label_fr": "Stritez nad Ludinou"
            },
            {
                "value": 66322,
                "label": "Vitkov",
                "label_ar": "فيتكوف",
                "label_fr": "Vitkov"
            },
            {
                "value": 66762,
                "label": "Starovice",
                "label_ar": "ستاروفيتسي",
                "label_fr": "Starovice"
            },
            {
                "value": 66791,
                "label": "Citov",
                "label_ar": "سيتوف",
                "label_fr": "Citov"
            },
            {
                "value": 66203,
                "label": "Darkovicky",
                "label_ar": "داركوفيكي",
                "label_fr": "Darkovicky"
            },
            {
                "value": 66629,
                "label": "Tursko",
                "label_ar": "تورسكو",
                "label_fr": "Tursko"
            },
            {
                "value": 66965,
                "label": "Hradiste",
                "label_ar": "هراديستي",
                "label_fr": "Hradiste"
            },
            {
                "value": 66560,
                "label": "Lubenec",
                "label_ar": "لوبينيك",
                "label_fr": "Lubenec"
            },
            {
                "value": 66838,
                "label": "Malenice",
                "label_ar": "مالينيس",
                "label_fr": "Malenice"
            },
            {
                "value": 67007,
                "label": "Dolni Zandov",
                "label_ar": "دولني زاندوف",
                "label_fr": "Dolni Zandov"
            },
            {
                "value": 66570,
                "label": "Bystrice",
                "label_ar": "بيستريس",
                "label_fr": "Bystrice"
            },
            {
                "value": 66194,
                "label": "Rtyne",
                "label_ar": "رتين",
                "label_fr": "Rtyne"
            },
            {
                "value": 66691,
                "label": "Syrovice",
                "label_ar": "سيروفيتسي",
                "label_fr": "Syrovice"
            },
            {
                "value": 66257,
                "label": "Třebíč",
                "label_ar": "تريبيش",
                "label_fr": "Třebíč"
            },
            {
                "value": 66274,
                "label": "Krupka",
                "label_ar": "كروبكا",
                "label_fr": "Krupka"
            },
            {
                "value": 67054,
                "label": "Lomnice nad Luznici",
                "label_ar": "Lomnice ناد Luznici",
                "label_fr": "Lomnice nad Luznici"
            },
            {
                "value": 66375,
                "label": "Chodov",
                "label_ar": "تشودوف",
                "label_fr": "Chodov"
            },
            {
                "value": 66895,
                "label": "Senohraby",
                "label_ar": "سنهرابي",
                "label_fr": "Senohraby"
            },
            {
                "value": 66858,
                "label": "Nupaky",
                "label_ar": "نوباكي",
                "label_fr": "Nupaky"
            },
            {
                "value": 66985,
                "label": "Predmesti",
                "label_ar": "بريدميستي",
                "label_fr": "Predmesti"
            },
            {
                "value": 67110,
                "label": "Velesovice",
                "label_ar": "فيليسوفيتسي",
                "label_fr": "Velesovice"
            },
            {
                "value": 66809,
                "label": "Mlade Buky",
                "label_ar": "ملاد بوكي",
                "label_fr": "Mlade Buky"
            },
            {
                "value": 67088,
                "label": "Obrany",
                "label_ar": "أوبراني",
                "label_fr": "Obrany"
            },
            {
                "value": 67111,
                "label": "Krasna Hora nad Vltavou",
                "label_ar": "كراسنا هورا ناد فلتافو",
                "label_fr": "Krasna Hora nad Vltavou"
            },
            {
                "value": 66909,
                "label": "Starec",
                "label_ar": "ستاريك",
                "label_fr": "Starec"
            },
            {
                "value": 66835,
                "label": "Nivnice",
                "label_ar": "نيفنيتسي",
                "label_fr": "Nivnice"
            },
            {
                "value": 66996,
                "label": "Prackovice nad Labem",
                "label_ar": "براكوفيتسي ناد لابم",
                "label_fr": "Prackovice nad Labem"
            },
            {
                "value": 66783,
                "label": "Zipotin",
                "label_ar": "زيبوتين",
                "label_fr": "Zipotin"
            },
            {
                "value": 66862,
                "label": "Blizevedly",
                "label_ar": "بصراحة",
                "label_fr": "Blizevedly"
            },
            {
                "value": 67037,
                "label": "Rokytnice nad Jizerou",
                "label_ar": "Rokytnice ناد جيزيرو",
                "label_fr": "Rokytnice nad Jizerou"
            },
            {
                "value": 66808,
                "label": "Dukovany",
                "label_ar": "دوكوفاني",
                "label_fr": "Dukovany"
            },
            {
                "value": 66321,
                "label": "Bernartice nad Odrou",
                "label_ar": "برنارتيس ناد أودرو",
                "label_fr": "Bernartice nad Odrou"
            },
            {
                "value": 67023,
                "label": "Dolni Dunajovice",
                "label_ar": "دولني دوناجوفيتسي",
                "label_fr": "Dolni Dunajovice"
            },
            {
                "value": 66904,
                "label": "Zelesice",
                "label_ar": "زيليسيتسي",
                "label_fr": "Zelesice"
            },
            {
                "value": 66817,
                "label": "Stod",
                "label_ar": "ستود",
                "label_fr": "Stod"
            },
            {
                "value": 66269,
                "label": "Sezemice",
                "label_ar": "سيزيميتسي",
                "label_fr": "Sezemice"
            },
            {
                "value": 66471,
                "label": "Mokrany",
                "label_ar": "مقراني",
                "label_fr": "Mokrany"
            },
            {
                "value": 66884,
                "label": "Cercany",
                "label_ar": "سركاني",
                "label_fr": "Cercany"
            },
            {
                "value": 66477,
                "label": "Pisecna",
                "label_ar": "بيسكنا",
                "label_fr": "Pisecna"
            },
            {
                "value": 66720,
                "label": "Hodslavice",
                "label_ar": "هودسلافيتسي",
                "label_fr": "Hodslavice"
            },
            {
                "value": 66549,
                "label": "Ceska Skalice",
                "label_ar": "سيسكا سكاليس",
                "label_fr": "Ceska Skalice"
            },
            {
                "value": 66661,
                "label": "Dobroslavice",
                "label_ar": "دوبروسلافيتسي",
                "label_fr": "Dobroslavice"
            },
            {
                "value": 66893,
                "label": "Cerncice",
                "label_ar": "سيرنكتسي",
                "label_fr": "Cerncice"
            },
            {
                "value": 66812,
                "label": "Dejvice",
                "label_ar": "ديجفيس",
                "label_fr": "Dejvice"
            },
            {
                "value": 67077,
                "label": "Hostinne",
                "label_ar": "هوستين",
                "label_fr": "Hostinne"
            },
            {
                "value": 66501,
                "label": "Stribro",
                "label_ar": "ستريبرو",
                "label_fr": "Stribro"
            },
            {
                "value": 66212,
                "label": "Ronov nad Doubravou",
                "label_ar": "رونوف ناد دوبرافو",
                "label_fr": "Ronov nad Doubravou"
            },
            {
                "value": 66512,
                "label": "Frystak",
                "label_ar": "Frystak",
                "label_fr": "Frystak"
            },
            {
                "value": 67125,
                "label": "Chotetov",
                "label_ar": "تشوتيتوف",
                "label_fr": "Chotetov"
            },
            {
                "value": 66888,
                "label": "Predmerice nad Jizerou",
                "label_ar": "بريدميريس ناد جيزيرو",
                "label_fr": "Prédmerice nad Jizerou"
            },
            {
                "value": 66642,
                "label": "Moravska Nova Ves",
                "label_ar": "مورافسكا نوفا فيس",
                "label_fr": "Moravska Nova Ves"
            },
            {
                "value": 66503,
                "label": "Vrbno pod Pradedem",
                "label_ar": "فربنو بود براديديم",
                "label_fr": "Vrbno pod Pradedem"
            },
            {
                "value": 66905,
                "label": "Humpolec",
                "label_ar": "هامبوليك",
                "label_fr": "Humpolec"
            },
            {
                "value": 66984,
                "label": "Zlicin",
                "label_ar": "زليسين",
                "label_fr": "Zlicin"
            },
            {
                "value": 66664,
                "label": "Celakovice",
                "label_ar": "سيلاكوفيتسي",
                "label_fr": "Celakovice"
            },
            {
                "value": 66678,
                "label": "Krumvir",
                "label_ar": "كرومفير",
                "label_fr": "Krumvir"
            },
            {
                "value": 66164,
                "label": "Chodov",
                "label_ar": "تشودوف",
                "label_fr": "Chodov"
            },
            {
                "value": 66646,
                "label": "Bohumin",
                "label_ar": "بوهومين",
                "label_fr": "Bohumin"
            },
            {
                "value": 67015,
                "label": "Chlupice",
                "label_ar": "كلوبيس",
                "label_fr": "Chlupice"
            },
            {
                "value": 66942,
                "label": "Rudikov",
                "label_ar": "روديكوف",
                "label_fr": "Rudikov"
            },
            {
                "value": 67006,
                "label": "Hovorcovice",
                "label_ar": "هوفوركوفيتسي",
                "label_fr": "Hovorcovice"
            },
            {
                "value": 66596,
                "label": "Hostivice",
                "label_ar": "هوستيفيس",
                "label_fr": "Hostivice"
            },
            {
                "value": 66250,
                "label": "Jevicko",
                "label_ar": "جيفيكو",
                "label_fr": "Jevicko"
            },
            {
                "value": 66583,
                "label": "Drzovice",
                "label_ar": "درزوفيتسي",
                "label_fr": "Drzovice"
            },
            {
                "value": 66892,
                "label": "Mostek",
                "label_ar": "موستيك",
                "label_fr": "Mostek"
            },
            {
                "value": 67043,
                "label": "Korycany",
                "label_ar": "كوريكاني",
                "label_fr": "Korycany"
            },
            {
                "value": 66857,
                "label": "Kochovice",
                "label_ar": "كوتشوفيتسي",
                "label_fr": "Kochovice"
            },
            {
                "value": 66309,
                "label": "Mesice",
                "label_ar": "ميسيس",
                "label_fr": "Mesice"
            },
            {
                "value": 67154,
                "label": "Domazelice",
                "label_ar": "دومازيلتسي",
                "label_fr": "Domazelice"
            },
            {
                "value": 66587,
                "label": "Mukarov",
                "label_ar": "موكاروف",
                "label_fr": "Mukarov"
            },
            {
                "value": 66178,
                "label": "Poritsch",
                "label_ar": "الشرفة",
                "label_fr": "Poritsch"
            },
            {
                "value": 67058,
                "label": "Kourim",
                "label_ar": "كوريم",
                "label_fr": "Kourim"
            },
            {
                "value": 66513,
                "label": "Svitavy",
                "label_ar": "سفيتافي",
                "label_fr": "Svitavy"
            },
            {
                "value": 66284,
                "label": "Hluboka nad Vltavou",
                "label_ar": "هلوبوكا ناد فلتافو",
                "label_fr": "Hluboka nad Vltavou"
            },
            {
                "value": 66896,
                "label": "Stity",
                "label_ar": "ستيتي",
                "label_fr": "Stity"
            },
            {
                "value": 67070,
                "label": "Hlubocky",
                "label_ar": "هلوبوكي",
                "label_fr": "Hlubocky"
            },
            {
                "value": 66682,
                "label": "Zadni Treban",
                "label_ar": "زادني تريبان",
                "label_fr": "Zadni Treban"
            },
            {
                "value": 67101,
                "label": "Velky",
                "label_ar": "فيلكي",
                "label_fr": "Velky"
            },
            {
                "value": 67022,
                "label": "Polesovice",
                "label_ar": "بوليسوفيتسي",
                "label_fr": "Polésovice"
            },
            {
                "value": 67065,
                "label": "Ruda nad Moravou",
                "label_ar": "روضة ناد مورافو",
                "label_fr": "Ruda nad Moravou"
            },
            {
                "value": 66384,
                "label": "Vysoka nad Labem",
                "label_ar": "فيسوكا ناد لابيم",
                "label_fr": "Vysoka nad Labem"
            },
            {
                "value": 66725,
                "label": "Leskovec",
                "label_ar": "ليسكوفيتش",
                "label_fr": "Leskovec"
            },
            {
                "value": 66198,
                "label": "Horni Bojanovice",
                "label_ar": "هورني بويانوفيتسي",
                "label_fr": "Horni Bojanovice"
            },
            {
                "value": 66609,
                "label": "Bernartice",
                "label_ar": "برنارتيس",
                "label_fr": "Bernartice"
            },
            {
                "value": 66438,
                "label": "Svojek",
                "label_ar": "Svojek",
                "label_fr": "Svojek"
            },
            {
                "value": 66796,
                "label": "Ujezd",
                "label_ar": "اوجزد",
                "label_fr": "Ujezd"
            },
            {
                "value": 67122,
                "label": "Bolatice",
                "label_ar": "بولاتيس",
                "label_fr": "Bolatice"
            },
            {
                "value": 66732,
                "label": "Osek",
                "label_ar": "أوسيك",
                "label_fr": "Osek"
            },
            {
                "value": 67085,
                "label": "Mezilesi",
                "label_ar": "ميزيليسي",
                "label_fr": "Mezilesi"
            },
            {
                "value": 66998,
                "label": "Rapotin",
                "label_ar": "رابوتين",
                "label_fr": "Rapotin"
            },
            {
                "value": 67105,
                "label": "Moravicany",
                "label_ar": "مورافيكاني",
                "label_fr": "Moravicany"
            },
            {
                "value": 66392,
                "label": "Tachlovice",
                "label_ar": "تاخلوفيتسي",
                "label_fr": "Tachlovice"
            },
            {
                "value": 66671,
                "label": "Dlouha Trebova",
                "label_ar": "دلوها تريبوفا",
                "label_fr": "Dlouha Trebova"
            },
            {
                "value": 66364,
                "label": "Banov",
                "label_ar": "بانوف",
                "label_fr": "Banov"
            },
            {
                "value": 67100,
                "label": "Lednice",
                "label_ar": "ليدنيس",
                "label_fr": "Lednice"
            },
            {
                "value": 66878,
                "label": "Jirickov",
                "label_ar": "جيريكوف",
                "label_fr": "Jirickov"
            },
            {
                "value": 66591,
                "label": "Mikulovice",
                "label_ar": "ميكولوفيتسي",
                "label_fr": "Mikulovice"
            },
            {
                "value": 66341,
                "label": "Horni Becva",
                "label_ar": "هورني بيكفا",
                "label_fr": "Horni Becva"
            },
            {
                "value": 66475,
                "label": "Ktis",
                "label_ar": "كتيس",
                "label_fr": "Ktis"
            },
            {
                "value": 66676,
                "label": "Milevsko",
                "label_ar": "ميليفسكو",
                "label_fr": "Milevsko"
            },
            {
                "value": 66584,
                "label": "Pisecna",
                "label_ar": "بيسكنا",
                "label_fr": "Pisecna"
            },
            {
                "value": 66435,
                "label": "Vendryne",
                "label_ar": "فيندرين",
                "label_fr": "Vendryne"
            },
            {
                "value": 67093,
                "label": "Jince",
                "label_ar": "جينس",
                "label_fr": "Jince"
            },
            {
                "value": 66227,
                "label": "Cestice",
                "label_ar": "سيستيس",
                "label_fr": "Cestice"
            },
            {
                "value": 66423,
                "label": "Roudnice nad Labem",
                "label_ar": "رودنيس ناد لابم",
                "label_fr": "Roudnice nad Labem"
            },
            {
                "value": 66453,
                "label": "Moravsky Zizkov",
                "label_ar": "مورافسكي زيزكوف",
                "label_fr": "Moravsky Zizkov"
            },
            {
                "value": 66605,
                "label": "Zasova",
                "label_ar": "زاسوفا",
                "label_fr": "Zasova"
            },
            {
                "value": 66788,
                "label": "Liten",
                "label_ar": "ليتين",
                "label_fr": "Liten"
            },
            {
                "value": 66937,
                "label": "Sloup",
                "label_ar": "سلوب",
                "label_fr": "Sloup"
            },
            {
                "value": 66510,
                "label": "Holice",
                "label_ar": "هوليس",
                "label_fr": "Holice"
            },
            {
                "value": 67034,
                "label": "Rovina",
                "label_ar": "روفينا",
                "label_fr": "Rovina"
            },
            {
                "value": 66906,
                "label": "Rikovice",
                "label_ar": "ريكوفيتسي",
                "label_fr": "Rikovice"
            },
            {
                "value": 66631,
                "label": "Kolodeje",
                "label_ar": "كولوديي",
                "label_fr": "Kolodeje"
            },
            {
                "value": 66255,
                "label": "Radostin",
                "label_ar": "رادوستين",
                "label_fr": "Radostin"
            },
            {
                "value": 66917,
                "label": "Baska",
                "label_ar": "باسكا",
                "label_fr": "Baska"
            },
            {
                "value": 66746,
                "label": "Mirosov",
                "label_ar": "ميروسوف",
                "label_fr": "Mirosov"
            },
            {
                "value": 66669,
                "label": "Hradek nad Nisou",
                "label_ar": "هرادك ناد نيسو",
                "label_fr": "Hradek nad Nisou"
            },
            {
                "value": 66345,
                "label": "Bystrice pod Hostynem",
                "label_ar": "بيستريس بود هوستينيم",
                "label_fr": "Bystrice pod Hostynem"
            },
            {
                "value": 66210,
                "label": "Brno",
                "label_ar": "برنو",
                "label_fr": "Brno"
            },
            {
                "value": 66289,
                "label": "Louny",
                "label_ar": "لوني",
                "label_fr": "Louny"
            },
            {
                "value": 66404,
                "label": "Klatovy",
                "label_ar": "كلاتوفي",
                "label_fr": "Klatovy"
            },
            {
                "value": 67024,
                "label": "Petrovice",
                "label_ar": "بيتروفيتسي",
                "label_fr": "Petrovice"
            },
            {
                "value": 67044,
                "label": "Hovorany",
                "label_ar": "هوفوراني",
                "label_fr": "Hovorany"
            },
            {
                "value": 67063,
                "label": "Sebrov",
                "label_ar": "سيبروف",
                "label_fr": "Sebrov"
            },
            {
                "value": 67069,
                "label": "Stare Mesto",
                "label_ar": "ستاري ميستو",
                "label_fr": "Regarder Mesto"
            },
            {
                "value": 66299,
                "label": "Blansko",
                "label_ar": "بلانسكو",
                "label_fr": "Blansko"
            },
            {
                "value": 66399,
                "label": "Strakonice",
                "label_ar": "ستراكونيس",
                "label_fr": "Strakonice"
            },
            {
                "value": 67120,
                "label": "Stary Bydzov",
                "label_ar": "ستاري بيدزوف",
                "label_fr": "Stary Bydzov"
            },
            {
                "value": 66440,
                "label": "Veseli nad Moravou",
                "label_ar": "فيسيلي ناد مورافو",
                "label_fr": "Veseli nad Moravou"
            },
            {
                "value": 66619,
                "label": "Chocerady",
                "label_ar": "تشوسيرادي",
                "label_fr": "Chocerady"
            },
            {
                "value": 66236,
                "label": "Nymburk",
                "label_ar": "نيمبورك",
                "label_fr": "Nymburk"
            },
            {
                "value": 66329,
                "label": "Zamberk",
                "label_ar": "زامبيرك",
                "label_fr": "Zamberk"
            },
            {
                "value": 66431,
                "label": "Lesonice",
                "label_ar": "ليسونيس",
                "label_fr": "Lesonice"
            },
            {
                "value": 67014,
                "label": "Zelenec",
                "label_ar": "زيلينك",
                "label_fr": "Zelenec"
            },
            {
                "value": 66252,
                "label": "Sumperk",
                "label_ar": "سومبيرك",
                "label_fr": "Sumperk"
            },
            {
                "value": 66665,
                "label": "Dubec",
                "label_ar": "دوبك",
                "label_fr": "Dubec"
            },
            {
                "value": 66307,
                "label": "Siluvky",
                "label_ar": "سيلوفكي",
                "label_fr": "Soyeux"
            },
            {
                "value": 67050,
                "label": "Radim",
                "label_ar": "راديم",
                "label_fr": "Radim"
            },
            {
                "value": 67033,
                "label": "Milikov",
                "label_ar": "ميليكوف",
                "label_fr": "Milikov"
            },
            {
                "value": 66283,
                "label": "Lanzhot",
                "label_ar": "لانزوت",
                "label_fr": "Lanzhot"
            },
            {
                "value": 66704,
                "label": "Bochov",
                "label_ar": "بوشوف",
                "label_fr": "Bochov"
            },
            {
                "value": 67138,
                "label": "Nasavrky",
                "label_ar": "Nasavrky",
                "label_fr": "Nasavrky"
            },
            {
                "value": 67113,
                "label": "Jezov",
                "label_ar": "جيزوف",
                "label_fr": "Jezov"
            },
            {
                "value": 66295,
                "label": "Bucovice",
                "label_ar": "بوكوفيتسي",
                "label_fr": "Bucovice"
            },
            {
                "value": 66679,
                "label": "Fulnek",
                "label_ar": "فولنيك",
                "label_fr": "Fulnek"
            },
            {
                "value": 66802,
                "label": "Příbram",
                "label_ar": "بيبرام",
                "label_fr": "Příbram"
            },
            {
                "value": 66456,
                "label": "Starovicky",
                "label_ar": "ستاروفيكي",
                "label_fr": "Starovicky"
            },
            {
                "value": 66325,
                "label": "Jirkov",
                "label_ar": "جيركوف",
                "label_fr": "Jirkov"
            },
            {
                "value": 66807,
                "label": "Smirice",
                "label_ar": "Smirice",
                "label_fr": "Sourire narquois"
            },
            {
                "value": 66717,
                "label": "Jaroslavice",
                "label_ar": "ياروسلافيتسي",
                "label_fr": "Jaroslavice"
            },
            {
                "value": 66222,
                "label": "Hermanuv Mestec",
                "label_ar": "هيرمانوف ميستيك",
                "label_fr": "Hermanuv Mestec"
            },
            {
                "value": 67135,
                "label": "Hrabetice",
                "label_ar": "هرابيتيس",
                "label_fr": "Hrabetice"
            },
            {
                "value": 66777,
                "label": "Domasov",
                "label_ar": "دوماسوف",
                "label_fr": "Domasov"
            },
            {
                "value": 66907,
                "label": "Michle",
                "label_ar": "ميشيل",
                "label_fr": "Michle"
            },
            {
                "value": 67026,
                "label": "Velke Albrechtice",
                "label_ar": "فيلك ألبريشتيس",
                "label_fr": "Velke Albrechtice"
            },
            {
                "value": 66391,
                "label": "Kraluv Dvur",
                "label_ar": "كرالوف دفور",
                "label_fr": "Kraluv Dvur"
            },
            {
                "value": 66490,
                "label": "Velka Dobra",
                "label_ar": "فيلكا دوبرا",
                "label_fr": "Velka Dobra"
            },
            {
                "value": 66613,
                "label": "Osik",
                "label_ar": "أوسيك",
                "label_fr": "Osik"
            },
            {
                "value": 67080,
                "label": "Dlouhe",
                "label_ar": "دلوهي",
                "label_fr": "Dlouhe"
            },
            {
                "value": 66413,
                "label": "Jalubi",
                "label_ar": "الجلوبى",
                "label_fr": "Jalubi"
            },
            {
                "value": 66994,
                "label": "Cista",
                "label_ar": "سيستا",
                "label_fr": "Cista"
            },
            {
                "value": 66539,
                "label": "Hrusovany nad Jevisovkou",
                "label_ar": "هروسوفاني ناد جيفيسوفكو",
                "label_fr": "Hrusovany nad Jevisovkou"
            },
            {
                "value": 66997,
                "label": "Kresice",
                "label_ar": "كريسيسي",
                "label_fr": "Kresice"
            },
            {
                "value": 66900,
                "label": "Zlate Hory",
                "label_ar": "زلات هوري",
                "label_fr": "Zlate Hory"
            },
            {
                "value": 66822,
                "label": "Broumov",
                "label_ar": "بروموف",
                "label_fr": "Broumov"
            },
            {
                "value": 66855,
                "label": "Zdirec",
                "label_ar": "زديريك",
                "label_fr": "Zdirec"
            },
            {
                "value": 66576,
                "label": "Hradec",
                "label_ar": "هراديك",
                "label_fr": "Hradec"
            },
            {
                "value": 66245,
                "label": "Písek",
                "label_ar": "بيسك",
                "label_fr": "Písek"
            },
            {
                "value": 66902,
                "label": "Brodce",
                "label_ar": "برودس",
                "label_fr": "Brodce"
            },
            {
                "value": 66547,
                "label": "Skripov",
                "label_ar": "سكريبوف",
                "label_fr": "Skripov"
            },
            {
                "value": 67131,
                "label": "Tvarozna",
                "label_ar": "تفاروزنا",
                "label_fr": "Tvarozna"
            },
            {
                "value": 66848,
                "label": "Bezdekov",
                "label_ar": "بيزديكوف",
                "label_fr": "Bezdekov"
            },
            {
                "value": 66686,
                "label": "Boretice",
                "label_ar": "بوريتيس",
                "label_fr": "Boretice"
            },
            {
                "value": 66711,
                "label": "Stitina",
                "label_ar": "ستيتينا",
                "label_fr": "Stitina"
            },
            {
                "value": 66689,
                "label": "Kralovice",
                "label_ar": "كرالوفيتسي",
                "label_fr": "Kralovice"
            },
            {
                "value": 66187,
                "label": "Roznov pod Radhostem",
                "label_ar": "Roznov pod Radhostem",
                "label_fr": "Roznov pod Radhostem"
            },
            {
                "value": 66441,
                "label": "České Budějovice",
                "label_ar": "České Budějovice",
                "label_fr": "České Budějovice"
            },
            {
                "value": 66342,
                "label": "Vladislav",
                "label_ar": "فلاديسلاف",
                "label_fr": "Vladislav"
            },
            {
                "value": 66259,
                "label": "Jablonne v Podjestedi",
                "label_ar": "جابلون ضد بودجيستي",
                "label_fr": "Jablonne contre Podjestedi"
            },
            {
                "value": 66184,
                "label": "Zd'ar nad Sazavou",
                "label_ar": "زدار ناد سازافو",
                "label_fr": "Zd'ar nad Sazavou"
            },
            {
                "value": 66713,
                "label": "Brodek",
                "label_ar": "بروديك",
                "label_fr": "Brodek"
            },
            {
                "value": 66654,
                "label": "Doksy",
                "label_ar": "دوكسى",
                "label_fr": "Doksy"
            },
            {
                "value": 66856,
                "label": "Dobronin",
                "label_ar": "دوبرونين",
                "label_fr": "Dobronine"
            },
            {
                "value": 66608,
                "label": "Trebon",
                "label_ar": "تريبون",
                "label_fr": "Trebon"
            },
            {
                "value": 66319,
                "label": "Libeznice",
                "label_ar": "ليبيزنيتسي",
                "label_fr": "Libeznice"
            },
            {
                "value": 66261,
                "label": "Pardubice",
                "label_ar": "باردوبيتسه",
                "label_fr": "Pardubice"
            },
            {
                "value": 66186,
                "label": "Semily",
                "label_ar": "شبه",
                "label_fr": "Semily"
            },
            {
                "value": 66368,
                "label": "Dubne",
                "label_ar": "دوبني",
                "label_fr": "Dubne"
            },
            {
                "value": 66792,
                "label": "Strachotice",
                "label_ar": "ستراخوتيس",
                "label_fr": "Strachotice"
            },
            {
                "value": 66932,
                "label": "Nedakonice",
                "label_ar": "نيداكونيتسي",
                "label_fr": "Nedakonice"
            },
            {
                "value": 67145,
                "label": "Slusovice",
                "label_ar": "سلوسوفيتسي",
                "label_fr": "Slusovice"
            },
            {
                "value": 66957,
                "label": "Cebin",
                "label_ar": "سيبين",
                "label_fr": "Cebin"
            },
            {
                "value": 66396,
                "label": "Babice",
                "label_ar": "بابيس",
                "label_fr": "Babice"
            },
            {
                "value": 66620,
                "label": "Doubravnik",
                "label_ar": "دوبرافنيك",
                "label_fr": "Doubravnik"
            },
            {
                "value": 66166,
                "label": "Jablunkov",
                "label_ar": "جابلنكوف",
                "label_fr": "Jablunkov"
            },
            {
                "value": 66507,
                "label": "Usti nad Orlici",
                "label_ar": "أوستي ناد أورليتشي",
                "label_fr": "Usti nad Orlici"
            },
            {
                "value": 66177,
                "label": "Slavicin",
                "label_ar": "سلافيسين",
                "label_fr": "Slavicine"
            },
            {
                "value": 66350,
                "label": "Ceska Trebova",
                "label_ar": "سيسكا تريبوفا",
                "label_fr": "Ceska Trebova"
            },
            {
                "value": 66594,
                "label": "Senice na Hane",
                "label_ar": "سينيس نا هان",
                "label_fr": "Senice na Hane"
            },
            {
                "value": 66226,
                "label": "Olomouc",
                "label_ar": "أولوموك",
                "label_fr": "Olomouc"
            },
            {
                "value": 67074,
                "label": "Straz pod Ralskem",
                "label_ar": "جراب Straz Ralskem",
                "label_fr": "Straz pod Ralskem"
            },
            {
                "value": 66864,
                "label": "Lisov",
                "label_ar": "ليسوف",
                "label_fr": "Lisov"
            },
            {
                "value": 66668,
                "label": "Tyniste nad Orlici",
                "label_ar": "تينيست ناد أورليتشي",
                "label_fr": "Tyniste nad Orlici"
            },
            {
                "value": 66976,
                "label": "Rozmital pod Tremsinem",
                "label_ar": "Rozmital pod Tremsinem",
                "label_fr": "Pod Rozmital Tremsinem"
            },
            {
                "value": 66425,
                "label": "Steti",
                "label_ar": "ستيتي",
                "label_fr": "Steti"
            },
            {
                "value": 66928,
                "label": "Rapotice",
                "label_ar": "رابوتيس",
                "label_fr": "Rapotice"
            },
            {
                "value": 66568,
                "label": "Cechtice",
                "label_ar": "Cechtice",
                "label_fr": "Cechtice"
            },
            {
                "value": 66723,
                "label": "Desna",
                "label_ar": "ديسنا",
                "label_fr": "Desna"
            },
            {
                "value": 66658,
                "label": "Senov",
                "label_ar": "سينوف",
                "label_fr": "Senov"
            },
            {
                "value": 67066,
                "label": "Vodnany",
                "label_ar": "فودناني",
                "label_fr": "Vodnany"
            },
            {
                "value": 66873,
                "label": "Dolni Jircany",
                "label_ar": "دولني جيركاني",
                "label_fr": "Dolni Jircany"
            },
            {
                "value": 66759,
                "label": "Trebestovice",
                "label_ar": "تريبستوفيتسي",
                "label_fr": "Trebestovice"
            },
            {
                "value": 67119,
                "label": "Vsestary",
                "label_ar": "فسستاري",
                "label_fr": "Vsestary"
            },
            {
                "value": 66694,
                "label": "Knezmost",
                "label_ar": "كنيزوست",
                "label_fr": "Knezmost"
            },
            {
                "value": 66700,
                "label": "Zdeslav",
                "label_ar": "زديسلاف",
                "label_fr": "Zdeslav"
            },
            {
                "value": 66797,
                "label": "Hukvaldy",
                "label_ar": "هوكفالدي",
                "label_fr": "Hukvaldy"
            },
            {
                "value": 66795,
                "label": "Klecany",
                "label_ar": "كليكاني",
                "label_fr": "Klecany"
            },
            {
                "value": 66775,
                "label": "Zbuch",
                "label_ar": "زبوش",
                "label_fr": "Zbuch"
            },
            {
                "value": 66579,
                "label": "Dolni Radechova",
                "label_ar": "دولني راديشوفا",
                "label_fr": "Dolni Radechova"
            },
            {
                "value": 66530,
                "label": "Krhova",
                "label_ar": "كرهوفا",
                "label_fr": "Krhova"
            },
            {
                "value": 66418,
                "label": "Rakovnik",
                "label_ar": "راكوفنيك",
                "label_fr": "Rakovnik"
            },
            {
                "value": 67061,
                "label": "Borotin",
                "label_ar": "بوروتين",
                "label_fr": "Borotine"
            },
            {
                "value": 66403,
                "label": "Tábor",
                "label_ar": "تابور",
                "label_fr": "Tambourin"
            },
            {
                "value": 67086,
                "label": "Pavlice",
                "label_ar": "بافليس",
                "label_fr": "Pavlice"
            },
            {
                "value": 66525,
                "label": "Benešov",
                "label_ar": "بينيسوف",
                "label_fr": "Benešov"
            },
            {
                "value": 66388,
                "label": "Klasterec nad Ohri",
                "label_ar": "كلاستريك ناد اوري",
                "label_fr": "Klasterec nad Ohri"
            },
            {
                "value": 66220,
                "label": "Golcuv Jenikov",
                "label_ar": "جولكوف جينيكوف",
                "label_fr": "Golcuv Jenikov"
            },
            {
                "value": 67071,
                "label": "Tesetice",
                "label_ar": "تيسيتيس",
                "label_fr": "Tesetice"
            },
            {
                "value": 66852,
                "label": "Kunratice",
                "label_ar": "كونراتيس",
                "label_fr": "Kunratice"
            },
            {
                "value": 66458,
                "label": "Vlachovice",
                "label_ar": "فلاشوفيتسي",
                "label_fr": "Vlachovice"
            },
            {
                "value": 66850,
                "label": "Rebesovice",
                "label_ar": "ريبيسوفيتسي",
                "label_fr": "Rebesovice"
            },
            {
                "value": 66789,
                "label": "Brezno",
                "label_ar": "بريزنو",
                "label_fr": "Brezno"
            },
            {
                "value": 66302,
                "label": "Hulin",
                "label_ar": "هولين",
                "label_fr": "Hulin"
            },
            {
                "value": 66781,
                "label": "Psary",
                "label_ar": "Psary",
                "label_fr": "Psary"
            },
            {
                "value": 66836,
                "label": "Chotoviny",
                "label_ar": "شوتوفيني",
                "label_fr": "Chotoviny"
            },
            {
                "value": 66531,
                "label": "Sadska",
                "label_ar": "سادسكا",
                "label_fr": "Sadska"
            },
            {
                "value": 66506,
                "label": "Cejkovice",
                "label_ar": "سيكوفيتسي",
                "label_fr": "Cejkovice"
            },
            {
                "value": 66627,
                "label": "Strachotin",
                "label_ar": "ستراخوتين",
                "label_fr": "Strachotine"
            },
            {
                "value": 66744,
                "label": "Dolni Brezany",
                "label_ar": "دولني بريزاني",
                "label_fr": "Dolni Brezany"
            },
            {
                "value": 66814,
                "label": "Visnove",
                "label_ar": "فيسنوف",
                "label_fr": "Visnove"
            },
            {
                "value": 66224,
                "label": "Ostrava",
                "label_ar": "اوسترافا",
                "label_fr": "Ostrava"
            },
            {
                "value": 66875,
                "label": "Nova Cerekev",
                "label_ar": "نوفا سيريكيف",
                "label_fr": "Nova Cerekev"
            },
            {
                "value": 66288,
                "label": "Horesedly",
                "label_ar": "بتردد",
                "label_fr": "Horesedly"
            },
            {
                "value": 66383,
                "label": "Cheb",
                "label_ar": "الشاب",
                "label_fr": "Cheb"
            },
            {
                "value": 67049,
                "label": "Konice",
                "label_ar": "كونيس",
                "label_fr": "Konice"
            },
            {
                "value": 66980,
                "label": "Bojkovice",
                "label_ar": "بوجكوفيتسي",
                "label_fr": "Bojkovice"
            },
            {
                "value": 66818,
                "label": "Velehrad",
                "label_ar": "فيليهراد",
                "label_fr": "Velehrad"
            },
            {
                "value": 67076,
                "label": "Nenkovice",
                "label_ar": "نينكوفيتسي",
                "label_fr": "Nenkovice"
            },
            {
                "value": 67039,
                "label": "Kacerov",
                "label_ar": "كاسيروف",
                "label_fr": "Kacerov"
            },
            {
                "value": 66982,
                "label": "Chroustovice",
                "label_ar": "Chroustovice",
                "label_fr": "Chroustovice"
            },
            {
                "value": 66699,
                "label": "Pustejov",
                "label_ar": "بوستيجوف",
                "label_fr": "Pustejov"
            },
            {
                "value": 66451,
                "label": "Celadna",
                "label_ar": "سيلادنا",
                "label_fr": "Celadna"
            },
            {
                "value": 66925,
                "label": "Nesovice",
                "label_ar": "نيسوفيتسي",
                "label_fr": "Nesovice"
            },
            {
                "value": 66500,
                "label": "Hysly",
                "label_ar": "هيسلي",
                "label_fr": "Hysly"
            },
            {
                "value": 66218,
                "label": "Ledec nad Sazavou",
                "label_ar": "ليديك ناد سازافو",
                "label_fr": "Ledec nad Sazavou"
            },
            {
                "value": 66938,
                "label": "Unicov",
                "label_ar": "يونيكوف",
                "label_fr": "Unicov"
            },
            {
                "value": 67052,
                "label": "Prerov nad Labem",
                "label_ar": "بريروف ناد لابيم",
                "label_fr": "Prerov nad Labem"
            },
            {
                "value": 66277,
                "label": "Nyrsko",
                "label_ar": "نيرسكو",
                "label_fr": "Nyrsko"
            },
            {
                "value": 67060,
                "label": "Nikolcice",
                "label_ar": "نيكولتسي",
                "label_fr": "Nikolcice"
            },
            {
                "value": 66304,
                "label": "Bor",
                "label_ar": "بور",
                "label_fr": "Bor"
            },
            {
                "value": 66480,
                "label": "Vimperk",
                "label_ar": "فيمبيرك",
                "label_fr": "Vimperk"
            },
            {
                "value": 66926,
                "label": "Zborovice",
                "label_ar": "زبوروفيتسي",
                "label_fr": "Zborovice"
            },
            {
                "value": 66649,
                "label": "Horousanky",
                "label_ar": "هوروسانكي",
                "label_fr": "Horousanky"
            },
            {
                "value": 66927,
                "label": "Tisice",
                "label_ar": "تيسيسي",
                "label_fr": "Tisice"
            },
            {
                "value": 66930,
                "label": "Choustnikovo Hradiste",
                "label_ar": "تشوستنيكوفو هراديستي",
                "label_fr": "Choustnikovo Hradiste"
            },
            {
                "value": 66787,
                "label": "Radun",
                "label_ar": "رادون",
                "label_fr": "Radun"
            },
            {
                "value": 66951,
                "label": "Mala Skala",
                "label_ar": "مالا سكالا",
                "label_fr": "Mala Skala"
            },
            {
                "value": 66519,
                "label": "Ostrozska Nova Ves",
                "label_ar": "أوستروزكا نوفا فيس",
                "label_fr": "Ostrozska Nova Ves"
            },
            {
                "value": 66499,
                "label": "Dubnany",
                "label_ar": "دوبناني",
                "label_fr": "Dubnany"
            },
            {
                "value": 66189,
                "label": "Nove Straseci",
                "label_ar": "نوف ستراسيسي",
                "label_fr": "Nove Straseci"
            },
            {
                "value": 66414,
                "label": "Nove Mesto na Morave",
                "label_ar": "نوفي ميستو نا مورافي",
                "label_fr": "Nove Mesto na Morave"
            },
            {
                "value": 66644,
                "label": "Tucapy",
                "label_ar": "توسابي",
                "label_fr": "Tucapy"
            },
            {
                "value": 66389,
                "label": "Dobris",
                "label_ar": "دوبريس",
                "label_fr": "Dobris"
            },
            {
                "value": 67048,
                "label": "Kaznejov",
                "label_ar": "كازنيوف",
                "label_fr": "Kaznejov"
            },
            {
                "value": 67084,
                "label": "Dobrany",
                "label_ar": "دبراني",
                "label_fr": "Dobrany"
            },
            {
                "value": 66565,
                "label": "Lysice",
                "label_ar": "ليسيس",
                "label_fr": "Lysice"
            },
            {
                "value": 66527,
                "label": "Vsechovice",
                "label_ar": "فيشوفيتسي",
                "label_fr": "Vsechovice"
            },
            {
                "value": 66929,
                "label": "Bosin",
                "label_ar": "بوسين",
                "label_fr": "Bosin"
            },
            {
                "value": 66552,
                "label": "Novy Bor",
                "label_ar": "نوفي بور",
                "label_fr": "Novy Bor"
            },
            {
                "value": 66204,
                "label": "Vsetin",
                "label_ar": "فستين",
                "label_fr": "Vsetin"
            },
            {
                "value": 66271,
                "label": "Česká Lípa",
                "label_ar": "Česká Lípa",
                "label_fr": "Česká Lípa"
            },
            {
                "value": 66248,
                "label": "Liberec",
                "label_ar": "Liberec",
                "label_fr": "Liberec"
            },
            {
                "value": 66853,
                "label": "Dusejov",
                "label_ar": "دوسجوف",
                "label_fr": "Dusejov"
            },
            {
                "value": 67009,
                "label": "Slapy",
                "label_ar": "صفعة",
                "label_fr": "Slapy"
            },
            {
                "value": 66971,
                "label": "Sestajovice",
                "label_ar": "سيستاجوفيتسي",
                "label_fr": "Sestajovice"
            },
            {
                "value": 67128,
                "label": "Dobruska",
                "label_ar": "دوبروسكا",
                "label_fr": "Dobruska"
            },
            {
                "value": 66502,
                "label": "Stenovice",
                "label_ar": "ستينوفيتسي",
                "label_fr": "Stenovice"
            },
            {
                "value": 66313,
                "label": "Velka Bites",
                "label_ar": "فيلكا بايتس",
                "label_fr": "Bouchées Velka"
            },
            {
                "value": 66297,
                "label": "Havlíčkův Brod",
                "label_ar": "Havlíčkův Brod",
                "label_fr": "Havlíčkův Brod"
            },
            {
                "value": 66582,
                "label": "Chyne",
                "label_ar": "شين",
                "label_fr": "Chyne"
            },
            {
                "value": 66680,
                "label": "Malcice",
                "label_ar": "ماليسيس",
                "label_fr": "Malveillance"
            },
            {
                "value": 67144,
                "label": "Ujezd",
                "label_ar": "اوجزد",
                "label_fr": "Ujezd"
            },
            {
                "value": 66509,
                "label": "Holysov",
                "label_ar": "هوليسوف",
                "label_fr": "Holysov"
            },
            {
                "value": 66411,
                "label": "Orlova",
                "label_ar": "أورلوفا",
                "label_fr": "Orlova"
            },
            {
                "value": 66705,
                "label": "Mladkov",
                "label_ar": "ملاديكوف",
                "label_fr": "Mladkov"
            },
            {
                "value": 66400,
                "label": "Havířov",
                "label_ar": "هافيكوف",
                "label_fr": "Havířov"
            },
            {
                "value": 66636,
                "label": "Velke Brezno",
                "label_ar": "فيلك بريزنو",
                "label_fr": "Velke Brezno"
            },
            {
                "value": 66995,
                "label": "Adamov",
                "label_ar": "اداموف",
                "label_fr": "Adamov"
            },
            {
                "value": 66300,
                "label": "Tisnov",
                "label_ar": "تيسنوف",
                "label_fr": "Tisnov"
            },
            {
                "value": 66542,
                "label": "Nehvizdy",
                "label_ar": "نهفيزدي",
                "label_fr": "Nehvizdy"
            },
            {
                "value": 66543,
                "label": "Hradiste",
                "label_ar": "هراديستي",
                "label_fr": "Hradiste"
            },
            {
                "value": 66566,
                "label": "Svitavka",
                "label_ar": "سفيتافكا",
                "label_fr": "Svitavka"
            },
            {
                "value": 66215,
                "label": "Policka",
                "label_ar": "بوليكا",
                "label_fr": "Policka"
            },
            {
                "value": 66181,
                "label": "Chlumcany",
                "label_ar": "كلومكاني",
                "label_fr": "Chlumcany"
            },
            {
                "value": 66467,
                "label": "Kraslice",
                "label_ar": "كراسليتشي",
                "label_fr": "Kraslice"
            },
            {
                "value": 67003,
                "label": "Davle",
                "label_ar": "دافل",
                "label_fr": "Davle"
            },
            {
                "value": 66556,
                "label": "Roztoky",
                "label_ar": "روزتوكي",
                "label_fr": "Roztoky"
            },
            {
                "value": 66771,
                "label": "Haj ve Slezsku",
                "label_ar": "الحاج و Slezsku",
                "label_fr": "Haj ve Slezsku"
            },
            {
                "value": 66432,
                "label": "Satov",
                "label_ar": "ساتوف",
                "label_fr": "Satov"
            },
            {
                "value": 66489,
                "label": "Kamenne Zehrovice",
                "label_ar": "كامين زيروفيتسي",
                "label_fr": "Kamenne Zehrovice"
            },
            {
                "value": 67008,
                "label": "Dubenec",
                "label_ar": "دوبينيك",
                "label_fr": "Dubenec"
            },
            {
                "value": 66872,
                "label": "Ckyne",
                "label_ar": "Ckyne",
                "label_fr": "Ckyne"
            },
            {
                "value": 66273,
                "label": "Pribor",
                "label_ar": "بريبور",
                "label_fr": "Pribor"
            },
            {
                "value": 66819,
                "label": "Radostice",
                "label_ar": "رادوستيس",
                "label_fr": "Radostice"
            },
            {
                "value": 66844,
                "label": "Zeleznice",
                "label_ar": "زيليزنيتسي",
                "label_fr": "Zeleznice"
            },
            {
                "value": 66606,
                "label": "Velke Prilepy",
                "label_ar": "فيلك بريليبي",
                "label_fr": "Velke Prilepy"
            },
            {
                "value": 66959,
                "label": "Holubice",
                "label_ar": "هولوبيس",
                "label_fr": "Holubice"
            },
            {
                "value": 66695,
                "label": "Zvole",
                "label_ar": "زفول",
                "label_fr": "Zvole"
            },
            {
                "value": 66712,
                "label": "Lipova",
                "label_ar": "ليبوفا",
                "label_fr": "Lipova"
            },
            {
                "value": 66803,
                "label": "Skalice",
                "label_ar": "Skalice",
                "label_fr": "Skalice"
            },
            {
                "value": 66577,
                "label": "Velka Jesenice",
                "label_ar": "فيلكا جيسينيس",
                "label_fr": "Velka Jesenice"
            },
            {
                "value": 66241,
                "label": "Nedvedice",
                "label_ar": "نيدفيديس",
                "label_fr": "Nedvedice"
            },
            {
                "value": 67002,
                "label": "Milin",
                "label_ar": "ميلين",
                "label_fr": "Milin"
            },
            {
                "value": 66318,
                "label": "Nachod",
                "label_ar": "ناشود",
                "label_fr": "Nachod"
            },
            {
                "value": 66376,
                "label": "Napajedla",
                "label_ar": "ناباجيدلا",
                "label_fr": "Napajedla"
            },
            {
                "value": 66955,
                "label": "Dobra Voda",
                "label_ar": "دوبرا فودا",
                "label_fr": "Dobra Voda"
            },
            {
                "value": 67055,
                "label": "Svaty Jan nad Malsi",
                "label_ar": "سفاتي جان ناد مالسي",
                "label_fr": "Svaty Jan nad Malsi"
            },
            {
                "value": 66575,
                "label": "Rychnov",
                "label_ar": "ريتشنوف",
                "label_fr": "Rychnov"
            },
            {
                "value": 67027,
                "label": "Krnsko",
                "label_ar": "كرنسكو",
                "label_fr": "Krnsko"
            },
            {
                "value": 67102,
                "label": "Primda",
                "label_ar": "بريمدا",
                "label_fr": "Primda"
            },
            {
                "value": 67021,
                "label": "Tupesy",
                "label_ar": "توبيسي",
                "label_fr": "Tupesy"
            },
            {
                "value": 66617,
                "label": "Zulova",
                "label_ar": "زولوفا",
                "label_fr": "Zulova"
            },
            {
                "value": 66538,
                "label": "Sivice",
                "label_ar": "سيفيتسي",
                "label_fr": "Sivice"
            },
            {
                "value": 66424,
                "label": "Nedomice",
                "label_ar": "نيدوميس",
                "label_fr": "Nedomice"
            },
            {
                "value": 66282,
                "label": "Prusanky",
                "label_ar": "Prusanky",
                "label_fr": "Prusanky"
            },
            {
                "value": 66793,
                "label": "Cheznovice",
                "label_ar": "تشيزنوفيتسي",
                "label_fr": "Cheznovice"
            },
            {
                "value": 67068,
                "label": "Zliv",
                "label_ar": "زليف",
                "label_fr": "Zliv"
            },
            {
                "value": 67133,
                "label": "Zilina",
                "label_ar": "زيلينا",
                "label_fr": "Zilina"
            },
            {
                "value": 67047,
                "label": "Loucany",
                "label_ar": "لوكاني",
                "label_fr": "Loucany"
            },
            {
                "value": 66356,
                "label": "Nyrany",
                "label_ar": "نيراني",
                "label_fr": "Nyrany"
            },
            {
                "value": 66521,
                "label": "Moravske Budejovice",
                "label_ar": "مورافسكي بوديوفيتش",
                "label_fr": "Moravske Budejovice"
            },
            {
                "value": 66355,
                "label": "Hranice",
                "label_ar": "هرنيس",
                "label_fr": "Hranice"
            },
            {
                "value": 66505,
                "label": "Vrbice",
                "label_ar": "فربيس",
                "label_fr": "Vrbice"
            },
            {
                "value": 67019,
                "label": "Otnice",
                "label_ar": "Otnice",
                "label_fr": "Otnice"
            },
            {
                "value": 66260,
                "label": "Trstenice",
                "label_ar": "ترستينيس",
                "label_fr": "Trstenice"
            },
            {
                "value": 66564,
                "label": "Tremesna",
                "label_ar": "تريمسنا",
                "label_fr": "Tremesna"
            },
            {
                "value": 66567,
                "label": "Temice",
                "label_ar": "تيميس",
                "label_fr": "Temice"
            },
            {
                "value": 66529,
                "label": "Benatky nad Jizerou",
                "label_ar": "بيناتكي ناد جيزيرو",
                "label_fr": "Benatky nad Jizerou"
            },
            {
                "value": 67041,
                "label": "Palkovice",
                "label_ar": "بالكوفيتسي",
                "label_fr": "Palkovice"
            },
            {
                "value": 66381,
                "label": "Frenstat pod Radhostem",
                "label_ar": "Frenstat pod Radhostem",
                "label_fr": "Frenstat pod Radhostem"
            },
            {
                "value": 66923,
                "label": "Litava",
                "label_ar": "ليتافا",
                "label_fr": "Litava"
            },
            {
                "value": 66523,
                "label": "Filipova",
                "label_ar": "فيليبوفا",
                "label_fr": "Filipova"
            },
            {
                "value": 66296,
                "label": "Prostějov",
                "label_ar": "بروستوجوف",
                "label_fr": "Prostějov"
            },
            {
                "value": 66715,
                "label": "Potstejn",
                "label_ar": "Potstejn",
                "label_fr": "Potstejn"
            },
            {
                "value": 67098,
                "label": "Karlin",
                "label_ar": "كارلين",
                "label_fr": "Karlin"
            },
            {
                "value": 67132,
                "label": "Libice nad Cidlinou",
                "label_ar": "ليبيس ناد سيدلينو",
                "label_fr": "Libice nad Cidlinou"
            },
            {
                "value": 66865,
                "label": "Komorni Lhotka",
                "label_ar": "كومورني لهوتكا",
                "label_fr": "Komorni Lhotka"
            },
            {
                "value": 66820,
                "label": "Ivancice",
                "label_ar": "إيفانشيسي",
                "label_fr": "Ivancice"
            },
            {
                "value": 66557,
                "label": "Horni Podluzi",
                "label_ar": "هورني بودلوزي",
                "label_fr": "Horni Podluzi"
            },
            {
                "value": 66208,
                "label": "Troja",
                "label_ar": "طروادة",
                "label_fr": "Troja"
            },
            {
                "value": 66483,
                "label": "Uvaly",
                "label_ar": "يوفالي",
                "label_fr": "Uvaly"
            },
            {
                "value": 66163,
                "label": "Prague",
                "label_ar": "براغ",
                "label_fr": "Prague"
            },
            {
                "value": 66254,
                "label": "Trest'",
                "label_ar": "تريست",
                "label_fr": "Trest '"
            },
            {
                "value": 66223,
                "label": "Nove Mesto nad Metuji",
                "label_ar": "نوفي ميستو ناد ميتوجي",
                "label_fr": "Nove Mesto nad Metuji"
            },
            {
                "value": 66328,
                "label": "Chrast",
                "label_ar": "شرست",
                "label_fr": "Chrast"
            },
            {
                "value": 66827,
                "label": "Sedlec",
                "label_ar": "Sedlec",
                "label_fr": "Sedlec"
            },
            {
                "value": 66258,
                "label": "Novy Bydzov",
                "label_ar": "نوفي بيدزوف",
                "label_fr": "Novy Bydzov"
            },
            {
                "value": 66881,
                "label": "Bitovany",
                "label_ar": "بيتوفاني",
                "label_fr": "Bitovany"
            },
            {
                "value": 66860,
                "label": "Nelahozeves",
                "label_ar": "نيلاهوزيفيس",
                "label_fr": "Nelahozeves"
            },
            {
                "value": 66846,
                "label": "Bystrice",
                "label_ar": "بيستريس",
                "label_fr": "Bystrice"
            },
            {
                "value": 66618,
                "label": "Stalkov",
                "label_ar": "ستالكوف",
                "label_fr": "Stalkov"
            },
            {
                "value": 66217,
                "label": "Prelouc",
                "label_ar": "بريلوك",
                "label_fr": "Prelouc"
            },
            {
                "value": 66369,
                "label": "Telc",
                "label_ar": "Telc",
                "label_fr": "Telc"
            },
            {
                "value": 66769,
                "label": "Nova Paka",
                "label_ar": "نوفا باكا",
                "label_fr": "Nova Paka"
            },
            {
                "value": 66978,
                "label": "Luka",
                "label_ar": "لوكا",
                "label_fr": "Luka"
            },
            {
                "value": 66804,
                "label": "Rozdrojovice",
                "label_ar": "روزدروجوفيتسي",
                "label_fr": "Rozdrojovice"
            },
            {
                "value": 66747,
                "label": "Jirice",
                "label_ar": "جيريس",
                "label_fr": "Jirice"
            },
            {
                "value": 66786,
                "label": "Postrelmov",
                "label_ar": "بوستريلموف",
                "label_fr": "Postrelmov"
            },
            {
                "value": 66589,
                "label": "Hranice",
                "label_ar": "هرنيس",
                "label_fr": "Hranice"
            },
            {
                "value": 66461,
                "label": "Moravska Trebova",
                "label_ar": "مورافسكا تريبوفا",
                "label_fr": "Moravska Trebova"
            },
            {
                "value": 67083,
                "label": "Stepanov",
                "label_ar": "ستيبانوف",
                "label_fr": "Stepanov"
            },
            {
                "value": 66628,
                "label": "Veseli nad Luznici",
                "label_ar": "فيسيلي ناد لوزنيزي",
                "label_fr": "Veseli nad Luznici"
            },
            {
                "value": 66488,
                "label": "Hlinsko",
                "label_ar": "هلنسكو",
                "label_fr": "Hlinsko"
            },
            {
                "value": 66740,
                "label": "Lichnov (o. Novy Jicin)",
                "label_ar": "ليشنوف (o. نوفي جيسين)",
                "label_fr": "Lichnov (o. Novy Jicin)"
            },
            {
                "value": 66735,
                "label": "Krepenice",
                "label_ar": "كريبينس",
                "label_fr": "Krepenice"
            },
            {
                "value": 66398,
                "label": "Hlucin",
                "label_ar": "هلوسين",
                "label_fr": "Hlucin"
            },
            {
                "value": 66463,
                "label": "Ladvi",
                "label_ar": "Ladvi",
                "label_fr": "Ladvi"
            },
            {
                "value": 66837,
                "label": "Nejdek",
                "label_ar": "نيجديك",
                "label_fr": "Nejdek"
            },
            {
                "value": 67142,
                "label": "Cakovice",
                "label_ar": "كاكوفيتسي",
                "label_fr": "Cakovice"
            },
            {
                "value": 66633,
                "label": "Brusperk",
                "label_ar": "بروسبيرك",
                "label_fr": "Brusperk"
            },
            {
                "value": 66330,
                "label": "Jablonne nad Orlici",
                "label_ar": "جابلون ناد أورليتشي",
                "label_fr": "Jablonne nad Orlici"
            },
            {
                "value": 66410,
                "label": "Kroměříž",
                "label_ar": "كروميتش",
                "label_fr": "Kroměříž"
            },
            {
                "value": 66278,
                "label": "Blatna",
                "label_ar": "Blatna",
                "label_fr": "Blatna"
            },
            {
                "value": 66363,
                "label": "Opocno",
                "label_ar": "أوبوكنو",
                "label_fr": "Opocno"
            },
            {
                "value": 66518,
                "label": "Ostopovice",
                "label_ar": "أوستوبوفيتسي",
                "label_fr": "Ostopovice"
            },
            {
                "value": 66947,
                "label": "Komarice",
                "label_ar": "كوماريس",
                "label_fr": "Komarice"
            },
            {
                "value": 66243,
                "label": "Dolni Sucha",
                "label_ar": "دولني سوتشا",
                "label_fr": "Dolni Sucha"
            },
            {
                "value": 66849,
                "label": "Kamenicky Senov",
                "label_ar": "كامينيكي سينوف",
                "label_fr": "Kamenicky Senov"
            },
            {
                "value": 66607,
                "label": "Brezova",
                "label_ar": "بريزوفا",
                "label_fr": "Brezova"
            },
            {
                "value": 66229,
                "label": "Citoliby",
                "label_ar": "Citoliby",
                "label_fr": "Citoliby"
            },
            {
                "value": 66209,
                "label": "Pysely",
                "label_ar": "بيسلي",
                "label_fr": "Pysely"
            },
            {
                "value": 67109,
                "label": "Pruhonice",
                "label_ar": "بروهونيس",
                "label_fr": "Pruhonice"
            },
            {
                "value": 66939,
                "label": "Vresina",
                "label_ar": "فريسينا",
                "label_fr": "Vresina"
            },
            {
                "value": 66379,
                "label": "Vacenovice",
                "label_ar": "فاكينوفيتسي",
                "label_fr": "Vacenovice"
            },
            {
                "value": 66670,
                "label": "Olsany",
                "label_ar": "أولساني",
                "label_fr": "Olsany"
            },
            {
                "value": 66216,
                "label": "Prosetin",
                "label_ar": "بروسيتين",
                "label_fr": "Prosétine"
            },
            {
                "value": 66621,
                "label": "Pelhrimov",
                "label_ar": "بيلريموف",
                "label_fr": "Pelhrimov"
            },
            {
                "value": 66898,
                "label": "Cerhenice",
                "label_ar": "Cerhenice",
                "label_fr": "Cerhenice"
            },
            {
                "value": 66920,
                "label": "Horni Nemci",
                "label_ar": "هورني نمسي",
                "label_fr": "Horni Nemci"
            },
            {
                "value": 67129,
                "label": "Klobouky",
                "label_ar": "كلوبوكي",
                "label_fr": "Klobouky"
            },
            {
                "value": 66197,
                "label": "Ceska",
                "label_ar": "سيسكا",
                "label_fr": "Ceska"
            },
            {
                "value": 66816,
                "label": "Krchleby",
                "label_ar": "كرشليبي",
                "label_fr": "Krchleby"
            },
            {
                "value": 66728,
                "label": "Benesov",
                "label_ar": "بينيسوف",
                "label_fr": "Benesov"
            },
            {
                "value": 66264,
                "label": "Kladno",
                "label_ar": "كلادنو",
                "label_fr": "Kladno"
            },
            {
                "value": 66343,
                "label": "Unetice",
                "label_ar": "أحادي",
                "label_fr": "Unetice"
            },
            {
                "value": 66249,
                "label": "Vysoke Myto",
                "label_ar": "فيسوكي ميتو",
                "label_fr": "Vysoke Myto"
            },
            {
                "value": 66464,
                "label": "Luhacovice",
                "label_ar": "لوهاتشوفيتسي",
                "label_fr": "Luhacovice"
            },
            {
                "value": 66823,
                "label": "Marov u Upice",
                "label_ar": "Marov u Upice",
                "label_fr": "Marov u Upice"
            },
            {
                "value": 66805,
                "label": "Duchcov",
                "label_ar": "دوتشكوف",
                "label_fr": "Duchcov"
            },
            {
                "value": 66943,
                "label": "Belotin",
                "label_ar": "بيلوتين",
                "label_fr": "Belotin"
            },
            {
                "value": 66851,
                "label": "Dolni Terlicko",
                "label_ar": "دولني تيرليكو",
                "label_fr": "Dolni Terlicko"
            },
            {
                "value": 66990,
                "label": "Vtelno",
                "label_ar": "فتيلنو",
                "label_fr": "Vtelno"
            },
            {
                "value": 67004,
                "label": "Hajnice",
                "label_ar": "هاجنيتسي",
                "label_fr": "Hajnice"
            },
            {
                "value": 66748,
                "label": "Skuhrov nad Belou",
                "label_ar": "سكوروف ناد بيلو",
                "label_fr": "Skuhrov nad Belou"
            },
            {
                "value": 66638,
                "label": "Sluknov",
                "label_ar": "سلوكنوف",
                "label_fr": "Sluknov"
            },
            {
                "value": 66316,
                "label": "Frydlant nad Ostravici",
                "label_ar": "فريدلانت ناد أوسترافيتشي",
                "label_fr": "Frydlant nad Ostravici"
            },
            {
                "value": 66281,
                "label": "Dolni Bojanovice",
                "label_ar": "دولني بويانوفيتسي",
                "label_fr": "Dolni Bojanovice"
            },
            {
                "value": 67153,
                "label": "Tlumacov",
                "label_ar": "تلوماكوف",
                "label_fr": "Tlumacov"
            },
            {
                "value": 66741,
                "label": "Libcany",
                "label_ar": "ليبكاني",
                "label_fr": "Libcany"
            },
            {
                "value": 66171,
                "label": "Tehov",
                "label_ar": "تيهوف",
                "label_fr": "Tehov"
            },
            {
                "value": 66511,
                "label": "Pariz",
                "label_ar": "باريز",
                "label_fr": "Pariz"
            },
            {
                "value": 66945,
                "label": "Mimon",
                "label_ar": "ميمون",
                "label_fr": "Mimon"
            },
            {
                "value": 67127,
                "label": "Lipence",
                "label_ar": "ليبنس",
                "label_fr": "Lipence"
            },
            {
                "value": 66239,
                "label": "Břeclav",
                "label_ar": "بيكلاف",
                "label_fr": "Břeclav"
            },
            {
                "value": 66185,
                "label": "Bystrice nad Pernstejnem",
                "label_ar": "بيستريس ناد بيرنستجنيم",
                "label_fr": "Bystrice nad Pernstejnem"
            },
            {
                "value": 66179,
                "label": "As",
                "label_ar": "مثل",
                "label_fr": "Comme"
            },
            {
                "value": 67134,
                "label": "Horin",
                "label_ar": "هورين",
                "label_fr": "Horin"
            },
            {
                "value": 67056,
                "label": "Vysoke Veseli",
                "label_ar": "فيسوكي فيسيلي",
                "label_fr": "Vysoke Veseli"
            },
            {
                "value": 67064,
                "label": "Chlumec",
                "label_ar": "كلوميك",
                "label_fr": "Chlumec"
            },
            {
                "value": 67087,
                "label": "Klimkovice",
                "label_ar": "كليمكوفيتسي",
                "label_fr": "Klimkovice"
            },
            {
                "value": 66238,
                "label": "Podebrady",
                "label_ar": "بوديبرادي",
                "label_fr": "Podebrady"
            },
            {
                "value": 66616,
                "label": "Mokra Horakov",
                "label_ar": "موكرا هوراكوف",
                "label_fr": "Mokra Horakov"
            },
            {
                "value": 66373,
                "label": "Holoubkov",
                "label_ar": "هولوبكوف",
                "label_fr": "Holoubkov"
            },
            {
                "value": 67046,
                "label": "Bohunovice",
                "label_ar": "بوهونوفيتسي",
                "label_fr": "Bohunovice"
            },
            {
                "value": 67057,
                "label": "Zelenka",
                "label_ar": "زيلينكا",
                "label_fr": "Zelenka"
            },
            {
                "value": 66485,
                "label": "Sibrina",
                "label_ar": "سيبرينا",
                "label_fr": "Sibrina"
            },
            {
                "value": 67103,
                "label": "Cerna Hora",
                "label_ar": "سيرنا هورا",
                "label_fr": "Cerna Hora"
            },
            {
                "value": 66950,
                "label": "Velesin",
                "label_ar": "فيليسين",
                "label_fr": "Velesin"
            },
            {
                "value": 66738,
                "label": "Chrast",
                "label_ar": "شرست",
                "label_fr": "Chrast"
            },
            {
                "value": 66395,
                "label": "Mnichovice",
                "label_ar": "منيتشوفيتسي",
                "label_fr": "Mnichovice"
            },
            {
                "value": 66317,
                "label": "Frydek-Mistek",
                "label_ar": "فريدك ميستيك",
                "label_fr": "Frydek-Mistek"
            },
            {
                "value": 66416,
                "label": "Rovensko pod Troskami",
                "label_ar": "روفنسكو بود تروسكامي",
                "label_fr": "Pod Rovensko Troskami"
            },
            {
                "value": 66167,
                "label": "Český Těšín",
                "label_ar": "Český Těšín",
                "label_fr": "Český Těšín"
            },
            {
                "value": 66630,
                "label": "Kravare",
                "label_ar": "كرافاري",
                "label_fr": "Kravare"
            },
            {
                "value": 67005,
                "label": "Libochovicky",
                "label_ar": "ليبوتشوفيكي",
                "label_fr": "Libochovicky"
            },
            {
                "value": 66889,
                "label": "Budyne nad Ohri",
                "label_ar": "بودين ناد أوري",
                "label_fr": "Budyne nad Ohri"
            },
            {
                "value": 67078,
                "label": "Holasice",
                "label_ar": "Holasice",
                "label_fr": "Holasice"
            },
            {
                "value": 66684,
                "label": "Ochoz",
                "label_ar": "أوشوز",
                "label_fr": "Ochoz"
            },
            {
                "value": 66474,
                "label": "Borsice",
                "label_ar": "بورسيس",
                "label_fr": "Borsice"
            },
            {
                "value": 66290,
                "label": "Postoloprty",
                "label_ar": "Postoloprty",
                "label_fr": "Postoloprty"
            },
            {
                "value": 67059,
                "label": "Ceske Mezirici",
                "label_ar": "سيسكي ميزيريسي",
                "label_fr": "Ceske Mezirici"
            },
            {
                "value": 67118,
                "label": "Litomyšl",
                "label_ar": "Litomyšl",
                "label_fr": "Litomyšl"
            },
            {
                "value": 66703,
                "label": "Vselibice",
                "label_ar": "Vselibice",
                "label_fr": "Vselibice"
            },
            {
                "value": 66622,
                "label": "Pecin",
                "label_ar": "بيسين",
                "label_fr": "Pécin"
            },
            {
                "value": 67149,
                "label": "Rybna",
                "label_ar": "ريبنا",
                "label_fr": "Rybna"
            },
            {
                "value": 66726,
                "label": "Jiloviste",
                "label_ar": "جيلوفيست",
                "label_fr": "Jiloviste"
            },
            {
                "value": 66272,
                "label": "Vamberk",
                "label_ar": "فامبيرك",
                "label_fr": "Vamberk"
            },
            {
                "value": 66730,
                "label": "Nova Vcelnice",
                "label_ar": "نوفا فسيلنيتسي",
                "label_fr": "Nova Vcelnice"
            },
            {
                "value": 66176,
                "label": "Bylnice",
                "label_ar": "بيلنيس",
                "label_fr": "Bylnice"
            },
            {
                "value": 66958,
                "label": "Odolena Voda",
                "label_ar": "أودولينا فودا",
                "label_fr": "Odolena Voda"
            },
            {
                "value": 66468,
                "label": "Ondrejov",
                "label_ar": "أوندريجوف",
                "label_fr": "Ondrejov"
            },
            {
                "value": 66956,
                "label": "Holesov",
                "label_ar": "هوليسوف",
                "label_fr": "Holesov"
            },
            {
                "value": 66640,
                "label": "Novy Hrozenkov",
                "label_ar": "نوفي هروزينكوف",
                "label_fr": "Novy Hrozenkov"
            },
            {
                "value": 67010,
                "label": "Moravsky Krumlov",
                "label_ar": "مورافسكي كروملوف",
                "label_fr": "Moravsky Krumlov"
            },
            {
                "value": 66780,
                "label": "Neratovice",
                "label_ar": "نيراتوفيتسي",
                "label_fr": "Neratovice"
            },
            {
                "value": 66933,
                "label": "Havrice",
                "label_ar": "هافريس",
                "label_fr": "Havrice"
            },
            {
                "value": 66172,
                "label": "Koprivnice",
                "label_ar": "كوبريفنيتسي",
                "label_fr": "Koprivnice"
            },
            {
                "value": 66436,
                "label": "Slany",
                "label_ar": "سلاني",
                "label_fr": "Slany"
            },
            {
                "value": 66165,
                "label": "Ústí nad Labem",
                "label_ar": "أوستي ناد لابم",
                "label_fr": "Ústí nad Labem"
            },
            {
                "value": 66365,
                "label": "Uherské Hradiště",
                "label_ar": "Uherské Hradiště",
                "label_fr": "Uherské Hradiště"
            },
            {
                "value": 66590,
                "label": "Horni Slavkov",
                "label_ar": "هورني سلافكوف",
                "label_fr": "Horni Slavkov"
            },
            {
                "value": 66772,
                "label": "Mosty u Jablunkova",
                "label_ar": "Mosty u Jablunkova",
                "label_fr": "Mosty u Jablunkova"
            },
            {
                "value": 66377,
                "label": "Kunovice",
                "label_ar": "كونوفيتسي",
                "label_fr": "Kunovice"
            },
            {
                "value": 66327,
                "label": "Cepi",
                "label_ar": "سيبي",
                "label_fr": "Cepi"
            },
            {
                "value": 66641,
                "label": "Halenkov",
                "label_ar": "هالنكوف",
                "label_fr": "Halenkov"
            },
            {
                "value": 66833,
                "label": "Senec",
                "label_ar": "سينيك",
                "label_fr": "Senec"
            },
            {
                "value": 66687,
                "label": "Mlada Vozice",
                "label_ar": "ملادا فوزيتسي",
                "label_fr": "Mlada Vozice"
            },
            {
                "value": 67108,
                "label": "Pribyslav",
                "label_ar": "بريبيسلاف",
                "label_fr": "Pribyslav"
            },
            {
                "value": 66444,
                "label": "Ratibor",
                "label_ar": "راتيبور",
                "label_fr": "Ratibor"
            },
            {
                "value": 66495,
                "label": "Uhonice",
                "label_ar": "اوهونيس",
                "label_fr": "Uhonice"
            },
            {
                "value": 66497,
                "label": "Rymarov",
                "label_ar": "ريماروف",
                "label_fr": "Rymarov"
            },
            {
                "value": 66253,
                "label": "Jihlava",
                "label_ar": "جيهلافا",
                "label_fr": "Jihlava"
            },
            {
                "value": 66718,
                "label": "Damnice",
                "label_ar": "دمنيس",
                "label_fr": "Damnice"
            },
            {
                "value": 67032,
                "label": "Frydlant",
                "label_ar": "فرايدلانت",
                "label_fr": "Frydlant"
            },
            {
                "value": 66263,
                "label": "Chrastna",
                "label_ar": "شراستنا",
                "label_fr": "Chrastna"
            },
            {
                "value": 67031,
                "label": "Divisov",
                "label_ar": "ديفيسوف",
                "label_fr": "Divisov"
            },
            {
                "value": 66854,
                "label": "Lhota",
                "label_ar": "لهوتا",
                "label_fr": "Lhota"
            },
            {
                "value": 66405,
                "label": "Liban",
                "label_ar": "لبنان",
                "label_fr": "Liban"
            },
            {
                "value": 66308,
                "label": "Kurim",
                "label_ar": "كوريم",
                "label_fr": "Kurim"
            },
            {
                "value": 66213,
                "label": "Chotebor",
                "label_ar": "تشوتيبور",
                "label_fr": "Chotebor"
            },
            {
                "value": 66632,
                "label": "Prepere",
                "label_ar": "الإعداد",
                "label_fr": "Prepere"
            },
            {
                "value": 66824,
                "label": "Police nad Metuji",
                "label_ar": "الشرطة ناد متوجي",
                "label_fr": "Police nad Metuji"
            },
            {
                "value": 66968,
                "label": "Tosovice",
                "label_ar": "توسوفيتسي",
                "label_fr": "Tosovice"
            },
            {
                "value": 67141,
                "label": "Brtnice",
                "label_ar": "Brtnice",
                "label_fr": "Brtnice"
            },
            {
                "value": 66935,
                "label": "Zdounky",
                "label_ar": "زدونكي",
                "label_fr": "Zdounky"
            },
            {
                "value": 66182,
                "label": "Cernosice",
                "label_ar": "سيرنوسيتسي",
                "label_fr": "Cernosice"
            },
            {
                "value": 67150,
                "label": "Vlastislav",
                "label_ar": "فلاستيسلاف",
                "label_fr": "Vlastislav"
            },
            {
                "value": 66533,
                "label": "Deblin",
                "label_ar": "دبلين",
                "label_fr": "Deblin"
            },
            {
                "value": 66782,
                "label": "Horomerice",
                "label_ar": "هوروميريس",
                "label_fr": "Horomerice"
            },
            {
                "value": 66683,
                "label": "Trebechovice pod Orebem",
                "label_ar": "Trebechovice pod Orebem",
                "label_fr": "Pod de Trebechovice Orebem"
            },
            {
                "value": 66202,
                "label": "Silherovice",
                "label_ar": "سيلهيروفيتسي",
                "label_fr": "Silherovice"
            },
            {
                "value": 66457,
                "label": "Zlata",
                "label_ar": "زلاتا",
                "label_fr": "Zlata"
            },
            {
                "value": 66470,
                "label": "Jenec",
                "label_ar": "جينيك",
                "label_fr": "Jenec"
            },
            {
                "value": 66450,
                "label": "Nydek",
                "label_ar": "نيدك",
                "label_fr": "Nydek"
            },
            {
                "value": 66170,
                "label": "Kolín",
                "label_ar": "كولين",
                "label_fr": "Kolín"
            },
            {
                "value": 66338,
                "label": "Novy Jicin",
                "label_ar": "نوفي جيسين",
                "label_fr": "Novy Jicin"
            },
            {
                "value": 66901,
                "label": "Mrlinek",
                "label_ar": "مرلينك",
                "label_fr": "Mrlinek"
            },
            {
                "value": 66697,
                "label": "Poricany",
                "label_ar": "بوريكاني",
                "label_fr": "Poricany"
            },
            {
                "value": 66459,
                "label": "Litovel",
                "label_ar": "ليتوفيل",
                "label_fr": "Litovel"
            },
            {
                "value": 66420,
                "label": "Vlasim",
                "label_ar": "فلاسيم",
                "label_fr": "Vlasim"
            },
            {
                "value": 66526,
                "label": "Bukovec",
                "label_ar": "بوكوفيتش",
                "label_fr": "Bukovec"
            },
            {
                "value": 66885,
                "label": "Predmerice nad Labem",
                "label_ar": "بريدميريس ناد لابيم",
                "label_fr": "Predmerice nad Labem"
            },
            {
                "value": 66842,
                "label": "Odry",
                "label_ar": "أودري",
                "label_fr": "Odry"
            },
            {
                "value": 67051,
                "label": "Nezvestice",
                "label_ar": "نيزفيستسي",
                "label_fr": "Nezvestice"
            },
            {
                "value": 66970,
                "label": "Snedovice",
                "label_ar": "سنيدوفيتسي",
                "label_fr": "Snedovice"
            },
            {
                "value": 66339,
                "label": "Mohelnice",
                "label_ar": "موهيلنيتسي",
                "label_fr": "Mohelnice"
            },
            {
                "value": 66750,
                "label": "Tetin",
                "label_ar": "تيتين",
                "label_fr": "Tétine"
            },
            {
                "value": 66173,
                "label": "Beroun",
                "label_ar": "بيرون",
                "label_fr": "Beroun"
            },
            {
                "value": 66498,
                "label": "Malesov",
                "label_ar": "ماليسوف",
                "label_fr": "Malesov"
            },
            {
                "value": 66599,
                "label": "Maly Ujezd",
                "label_ar": "مالي أوجزد",
                "label_fr": "Maly Ujezd"
            },
            {
                "value": 66371,
                "label": "Albrechtice",
                "label_ar": "ألبريشتيس",
                "label_fr": "Albrechtice"
            },
            {
                "value": 66546,
                "label": "Ostrov u Macochy",
                "label_ar": "أوستروف ش ماكوشي",
                "label_fr": "Ostrov u Macochy"
            },
            {
                "value": 67072,
                "label": "Kuncice",
                "label_ar": "كونكيسي",
                "label_fr": "Kuncice"
            },
            {
                "value": 66439,
                "label": "Volyne",
                "label_ar": "فولين",
                "label_fr": "Volyne"
            },
            {
                "value": 66528,
                "label": "Horni Pocernice",
                "label_ar": "هورني بوسرنيس",
                "label_fr": "Horni Pocernice"
            },
            {
                "value": 66766,
                "label": "Horsovsky Tyn",
                "label_ar": "هورسوفسكي تاين",
                "label_fr": "Horsovsky Tyn"
            },
            {
                "value": 66767,
                "label": "Branky",
                "label_ar": "برانكي",
                "label_fr": "Branky"
            },
            {
                "value": 67045,
                "label": "Horni Benesov",
                "label_ar": "هورني بينيسوف",
                "label_fr": "Horni Benesov"
            },
            {
                "value": 67042,
                "label": "Zdanice",
                "label_ar": "زدانيس",
                "label_fr": "Zdanice"
            },
            {
                "value": 66496,
                "label": "Sany",
                "label_ar": "ساني",
                "label_fr": "Sany"
            },
            {
                "value": 66731,
                "label": "Tuchomerice",
                "label_ar": "توتشومريس",
                "label_fr": "Tuchomerice"
            },
            {
                "value": 66200,
                "label": "Straznice",
                "label_ar": "سترازنيتسي",
                "label_fr": "Straznice"
            },
            {
                "value": 66454,
                "label": "Valtice",
                "label_ar": "فالتيس",
                "label_fr": "Valtice"
            },
            {
                "value": 66663,
                "label": "Kostelec nad Labem",
                "label_ar": "Kostelec ناد لابم",
                "label_fr": "Kostelec nad Labem"
            },
            {
                "value": 66256,
                "label": "Litoměřice",
                "label_ar": "ليتوميتسي",
                "label_fr": "Litoměřice"
            },
            {
                "value": 66382,
                "label": "Sokolov",
                "label_ar": "سوكولوف",
                "label_fr": "Sokolov"
            },
            {
                "value": 66305,
                "label": "Mariánské Lázně",
                "label_ar": "ماريانسكي لازني",
                "label_fr": "Mariánské Lázně"
            },
            {
                "value": 66940,
                "label": "Velka Bystrice",
                "label_ar": "فيلكا بيستريس",
                "label_fr": "Velka Bystrice"
            },
            {
                "value": 66558,
                "label": "Dolni Poustevna",
                "label_ar": "دولني بوستيفنا",
                "label_fr": "Dolni Poustevna"
            },
            {
                "value": 66344,
                "label": "Spalene Porici",
                "label_ar": "سبالين بوريسي",
                "label_fr": "Spalene Porici"
            }
        ]
    },
    {
        "country": "Switzerland",
        "cities": [
            {
                "value": 67759,
                "label": "Conthey",
                "label_ar": "كونتي",
                "label_fr": "Conthey"
            },
            {
                "value": 68408,
                "label": "Cugnasco",
                "label_ar": "كوجناسكو",
                "label_fr": "Cugnasco"
            },
            {
                "value": 68479,
                "label": "Wengen",
                "label_ar": "ون قن",
                "label_fr": "Wengen"
            },
            {
                "value": 67235,
                "label": "Riddes",
                "label_ar": "ريدز",
                "label_fr": "Riddes"
            },
            {
                "value": 68461,
                "label": "Winterberg",
                "label_ar": "وينتربرغ",
                "label_fr": "Winterberg"
            },
            {
                "value": 67596,
                "label": "Morcote",
                "label_ar": "موركوت",
                "label_fr": "Morcote"
            },
            {
                "value": 67526,
                "label": "Cadempino",
                "label_ar": "كاديمبينو",
                "label_fr": "Cadempino"
            },
            {
                "value": 67335,
                "label": "Frutigen",
                "label_ar": "فروتيغن",
                "label_fr": "Frutigen"
            },
            {
                "value": 67308,
                "label": "Hergiswil",
                "label_ar": "هيرجيسويل",
                "label_fr": "Hergiswil"
            },
            {
                "value": 67385,
                "label": "Kuesnacht",
                "label_ar": "Kuesnacht",
                "label_fr": "Kuesnacht"
            },
            {
                "value": 68281,
                "label": "Hausen am Albis / Hausen (Dorf)",
                "label_ar": "Hausen am Albis / Hausen (Dorf)",
                "label_fr": "Hausen am Albis / Hausen (Dorf)"
            },
            {
                "value": 67639,
                "label": "Schenkon",
                "label_ar": "شينكون",
                "label_fr": "Schenkon"
            },
            {
                "value": 68405,
                "label": "Gerlikon",
                "label_ar": "جيرليكون",
                "label_fr": "Gerlikon"
            },
            {
                "value": 67821,
                "label": "Ennetbürgen",
                "label_ar": "Ennetbürgen",
                "label_fr": "Ennetbürgen"
            },
            {
                "value": 67209,
                "label": "Seedorf",
                "label_ar": "سيدورف",
                "label_fr": "Seedorf"
            },
            {
                "value": 67733,
                "label": "Rothrist",
                "label_ar": "روثريست",
                "label_fr": "Rothrist"
            },
            {
                "value": 67474,
                "label": "Bellinzona",
                "label_ar": "بيلينزونا",
                "label_fr": "Bellinzone"
            },
            {
                "value": 67520,
                "label": "Küttigen",
                "label_ar": "Küttigen",
                "label_fr": "Küttigen"
            },
            {
                "value": 67359,
                "label": "Munchenstein",
                "label_ar": "مونشنشتاين",
                "label_fr": "Munchenstein"
            },
            {
                "value": 67885,
                "label": "Siselen",
                "label_ar": "سيسلين",
                "label_fr": "Siselen"
            },
            {
                "value": 68202,
                "label": "Wil",
                "label_ar": "ويل",
                "label_fr": "Wil"
            },
            {
                "value": 67355,
                "label": "Marin",
                "label_ar": "مارين",
                "label_fr": "Marin"
            },
            {
                "value": 67867,
                "label": "Busswil",
                "label_ar": "بوسويل",
                "label_fr": "Busswil"
            },
            {
                "value": 67642,
                "label": "Weggis",
                "label_ar": "ويغيس",
                "label_fr": "Weggis"
            },
            {
                "value": 68437,
                "label": "Herdern",
                "label_ar": "هيرديرن",
                "label_fr": "Herdern"
            },
            {
                "value": 67521,
                "label": "Troistorrents",
                "label_ar": "ترواستورانتس",
                "label_fr": "Troistorrents"
            },
            {
                "value": 67252,
                "label": "Ebnat-Kappel",
                "label_ar": "ابنات كابل",
                "label_fr": "Ebnat-Kappel"
            },
            {
                "value": 68159,
                "label": "Gordevio",
                "label_ar": "جورديفيو",
                "label_fr": "Gordevio"
            },
            {
                "value": 68338,
                "label": "Sonvilier",
                "label_ar": "سونفيلييه",
                "label_fr": "Sonvilier"
            },
            {
                "value": 67827,
                "label": "Cremines",
                "label_ar": "كريمين",
                "label_fr": "Crémines"
            },
            {
                "value": 68084,
                "label": "Begnins",
                "label_ar": "بيجنينز",
                "label_fr": "Begnins"
            },
            {
                "value": 67634,
                "label": "Ebikon",
                "label_ar": "إيبيكون",
                "label_fr": "Ebikon"
            },
            {
                "value": 67283,
                "label": "Lugano",
                "label_ar": "لوغانو",
                "label_fr": "Lugano"
            },
            {
                "value": 67237,
                "label": "Onex",
                "label_ar": "أونيكس",
                "label_fr": "Onex"
            },
            {
                "value": 68323,
                "label": "Gais",
                "label_ar": "جايس",
                "label_fr": "Gais"
            },
            {
                "value": 68350,
                "label": "Gettnau",
                "label_ar": "جيتناو",
                "label_fr": "Gettnau"
            },
            {
                "value": 67793,
                "label": "Obfelden",
                "label_ar": "أوبفيلدن",
                "label_fr": "Obfelden"
            },
            {
                "value": 67720,
                "label": "Waldenburg",
                "label_ar": "والدنبورغ",
                "label_fr": "Waldenburg"
            },
            {
                "value": 68312,
                "label": "Arzier",
                "label_ar": "أرزير",
                "label_fr": "Arzier"
            },
            {
                "value": 67916,
                "label": "Netstal",
                "label_ar": "نيتستال",
                "label_fr": "Netstal"
            },
            {
                "value": 67510,
                "label": "Zug",
                "label_ar": "زوغ",
                "label_fr": "Zoug"
            },
            {
                "value": 67421,
                "label": "Aarau",
                "label_ar": "أراو",
                "label_fr": "Aarau"
            },
            {
                "value": 67672,
                "label": "Ayent",
                "label_ar": "أيينت",
                "label_fr": "Ayent"
            },
            {
                "value": 68319,
                "label": "Niederscherli",
                "label_ar": "نيدرشيرلي",
                "label_fr": "Niederscherli"
            },
            {
                "value": 67508,
                "label": "Vesenaz",
                "label_ar": "فيسيناز",
                "label_fr": "Vesenaz"
            },
            {
                "value": 68393,
                "label": "Chesieres",
                "label_ar": "Chesieres",
                "label_fr": "Chesières"
            },
            {
                "value": 67487,
                "label": "Sursee",
                "label_ar": "سورسي",
                "label_fr": "Sursee"
            },
            {
                "value": 68428,
                "label": "Raperswilen",
                "label_ar": "Raperswilen",
                "label_fr": "Raperswilen"
            },
            {
                "value": 67935,
                "label": "Vordemwald",
                "label_ar": "فورديموالد",
                "label_fr": "Vordemwald"
            },
            {
                "value": 67792,
                "label": "Stansstad",
                "label_ar": "ستانسستاد",
                "label_fr": "Stansstad"
            },
            {
                "value": 68041,
                "label": "Gampelen",
                "label_ar": "جامبيلين",
                "label_fr": "Gampelen"
            },
            {
                "value": 67612,
                "label": "Cortaillod",
                "label_ar": "كورتايلود",
                "label_fr": "Cortaillod"
            },
            {
                "value": 68414,
                "label": "Rapperswil",
                "label_ar": "رابرزويل",
                "label_fr": "Rapperswil"
            },
            {
                "value": 68086,
                "label": "La Plaine",
                "label_ar": "لا بلين",
                "label_fr": "La Plaine"
            },
            {
                "value": 67175,
                "label": "Brig",
                "label_ar": "العميد",
                "label_fr": "Brick"
            },
            {
                "value": 67489,
                "label": "Avenches",
                "label_ar": "أفخم",
                "label_fr": "Avenches"
            },
            {
                "value": 67543,
                "label": "Morigen",
                "label_ar": "موريجان",
                "label_fr": "Morigen"
            },
            {
                "value": 68357,
                "label": "Vuiteboeuf",
                "label_ar": "Vuiteboeuf",
                "label_fr": "Vuiteboeuf"
            },
            {
                "value": 68494,
                "label": "Mühlehorn",
                "label_ar": "مولهورن",
                "label_fr": "Mühlehorn"
            },
            {
                "value": 68188,
                "label": "Emmetten",
                "label_ar": "إيميتين",
                "label_fr": "Emmetten"
            },
            {
                "value": 67694,
                "label": "Rekingen",
                "label_ar": "Rekingen",
                "label_fr": "Rekingen"
            },
            {
                "value": 67348,
                "label": "Goldach",
                "label_ar": "غولداش",
                "label_fr": "Goldach"
            },
            {
                "value": 67234,
                "label": "Monthey",
                "label_ar": "مونثي",
                "label_fr": "Monthey"
            },
            {
                "value": 68402,
                "label": "Genestrerio",
                "label_ar": "جينستريريو",
                "label_fr": "Genestrerio"
            },
            {
                "value": 67898,
                "label": "Nafels",
                "label_ar": "نافيلس",
                "label_fr": "Nafels"
            },
            {
                "value": 67743,
                "label": "Uetendorf",
                "label_ar": "أويتيندورف",
                "label_fr": "Uetendorf"
            },
            {
                "value": 67881,
                "label": "Freienbach",
                "label_ar": "فراينباخ",
                "label_fr": "Freienbach"
            },
            {
                "value": 67656,
                "label": "Altstatten",
                "label_ar": "التشتاتين",
                "label_fr": "Altstatten"
            },
            {
                "value": 67702,
                "label": "Ormalingen",
                "label_ar": "Ormalingen",
                "label_fr": "Ormalingen"
            },
            {
                "value": 68214,
                "label": "Diegten",
                "label_ar": "ديجتن",
                "label_fr": "Diegten"
            },
            {
                "value": 68305,
                "label": "Les Hauts-Geneveys",
                "label_ar": "ليه أوت جينيفيس",
                "label_fr": "Les Hauts-Geneveys"
            },
            {
                "value": 68209,
                "label": "Agra",
                "label_ar": "اجرا",
                "label_fr": "Agra"
            },
            {
                "value": 68339,
                "label": "Fleurier",
                "label_ar": "فلورييه",
                "label_fr": "Fleurier"
            },
            {
                "value": 68105,
                "label": "Sargans",
                "label_ar": "سارجانز",
                "label_fr": "Sargans"
            },
            {
                "value": 67829,
                "label": "Bavois",
                "label_ar": "بافوا",
                "label_fr": "Bavois"
            },
            {
                "value": 67763,
                "label": "Grone",
                "label_ar": "غرون",
                "label_fr": "Grone"
            },
            {
                "value": 67168,
                "label": "Roggwil",
                "label_ar": "روجويل",
                "label_fr": "Roggwil"
            },
            {
                "value": 67347,
                "label": "Kronbuhl",
                "label_ar": "كرونبول",
                "label_fr": "Kronbuhl"
            },
            {
                "value": 68034,
                "label": "Munchwilen",
                "label_ar": "مونشويلن",
                "label_fr": "Munchwilen"
            },
            {
                "value": 68130,
                "label": "Unterkulm",
                "label_ar": "Unterkulm",
                "label_fr": "Unterkulm"
            },
            {
                "value": 67957,
                "label": "Stein",
                "label_ar": "شتاين",
                "label_fr": "Stein"
            },
            {
                "value": 68397,
                "label": "Hasle",
                "label_ar": "هاسل",
                "label_fr": "Hasle"
            },
            {
                "value": 68193,
                "label": "Igis",
                "label_ar": "إيجيس",
                "label_fr": "Igis"
            },
            {
                "value": 67874,
                "label": "Schubelbach",
                "label_ar": "شوبيلباخ",
                "label_fr": "Schubelbach"
            },
            {
                "value": 67312,
                "label": "Turbenthal",
                "label_ar": "توربينثال",
                "label_fr": "Turbenthal"
            },
            {
                "value": 67364,
                "label": "Dottikon",
                "label_ar": "دوتيكون",
                "label_fr": "Dottikon"
            },
            {
                "value": 67866,
                "label": "Worben",
                "label_ar": "وربن",
                "label_fr": "Worben"
            },
            {
                "value": 68380,
                "label": "Melchnau",
                "label_ar": "ميلشناو",
                "label_fr": "Melchnau"
            },
            {
                "value": 68089,
                "label": "Rossens",
                "label_ar": "روسينز",
                "label_fr": "Rossens"
            },
            {
                "value": 68150,
                "label": "Montagnola",
                "label_ar": "مونتاجنولا",
                "label_fr": "Montagnola"
            },
            {
                "value": 68204,
                "label": "Hausen",
                "label_ar": "Hausen",
                "label_fr": "Hausen"
            },
            {
                "value": 67529,
                "label": "Berlikon",
                "label_ar": "بيرليكون",
                "label_fr": "Berlikon"
            },
            {
                "value": 67201,
                "label": "Ersigen",
                "label_ar": "إرسيجن",
                "label_fr": "Ersigen"
            },
            {
                "value": 67210,
                "label": "Herzogenbuchsee",
                "label_ar": "هرتسوجينبوشسي",
                "label_fr": "Herzogenbuchsee"
            },
            {
                "value": 67823,
                "label": "Thierrens",
                "label_ar": "تيرينز",
                "label_fr": "Thierrens"
            },
            {
                "value": 67344,
                "label": "Wurenlos",
                "label_ar": "Wurenlos",
                "label_fr": "Wurenlos"
            },
            {
                "value": 68166,
                "label": "Obersaxen",
                "label_ar": "أوبرساكسين",
                "label_fr": "Obersaxen"
            },
            {
                "value": 68211,
                "label": "Eiken",
                "label_ar": "أيكن",
                "label_fr": "Eiken"
            },
            {
                "value": 68000,
                "label": "Weesen",
                "label_ar": "ويسين",
                "label_fr": "Weesen"
            },
            {
                "value": 67571,
                "label": "Rudolfstetten",
                "label_ar": "رودولفستيتن",
                "label_fr": "Rudolfstetten"
            },
            {
                "value": 67437,
                "label": "Romanel",
                "label_ar": "رومانيل",
                "label_fr": "Romanel"
            },
            {
                "value": 67730,
                "label": "Cottens",
                "label_ar": "قطن",
                "label_fr": "Cottens"
            },
            {
                "value": 67224,
                "label": "Reinach",
                "label_ar": "ريناخ",
                "label_fr": "Reinach"
            },
            {
                "value": 67664,
                "label": "Gerlafingen",
                "label_ar": "جيرلافينغن",
                "label_fr": "Gerlafingen"
            },
            {
                "value": 68071,
                "label": "Cernier",
                "label_ar": "سيرنييه",
                "label_fr": "Cernier"
            },
            {
                "value": 68344,
                "label": "Cadenazzo",
                "label_ar": "كاديناتسو",
                "label_fr": "Cadenazzo"
            },
            {
                "value": 68500,
                "label": "Brusino Arsizio",
                "label_ar": "بروسينو أرسيزيو",
                "label_fr": "Brusino Arsizio"
            },
            {
                "value": 67629,
                "label": "Hergiswil",
                "label_ar": "هيرجيسويل",
                "label_fr": "Hergiswil"
            },
            {
                "value": 67422,
                "label": "Volketswil",
                "label_ar": "فولكيتسويل",
                "label_fr": "Volketswil"
            },
            {
                "value": 67645,
                "label": "Roschenz",
                "label_ar": "روشينز",
                "label_fr": "Roschenz"
            },
            {
                "value": 67972,
                "label": "Novazzano",
                "label_ar": "نوفازانو",
                "label_fr": "Novazzano"
            },
            {
                "value": 68025,
                "label": "Chapelle",
                "label_ar": "شابيل",
                "label_fr": "Chapelle"
            },
            {
                "value": 67266,
                "label": "Vevey",
                "label_ar": "فيفي",
                "label_fr": "Vevey"
            },
            {
                "value": 67960,
                "label": "Canobbio",
                "label_ar": "كانوبيو",
                "label_fr": "Canobbio"
            },
            {
                "value": 67932,
                "label": "Chavannes",
                "label_ar": "شافانيس",
                "label_fr": "Chavannes"
            },
            {
                "value": 67589,
                "label": "Seuzach Dorf",
                "label_ar": "سيوزاك دورف",
                "label_fr": "Seuzach Dorf"
            },
            {
                "value": 67772,
                "label": "Chippis",
                "label_ar": "شيبس",
                "label_fr": "Chippis"
            },
            {
                "value": 67535,
                "label": "Illnau",
                "label_ar": "إلناو",
                "label_fr": "Illnau"
            },
            {
                "value": 67213,
                "label": "Therwil",
                "label_ar": "ثيرويل",
                "label_fr": "Therwil"
            },
            {
                "value": 67802,
                "label": "Neuenkirch",
                "label_ar": "نوينكيرش",
                "label_fr": "Neuenkirch"
            },
            {
                "value": 67250,
                "label": "Chur",
                "label_ar": "شور",
                "label_fr": "Coire"
            },
            {
                "value": 67244,
                "label": "Reinach",
                "label_ar": "ريناخ",
                "label_fr": "Reinach"
            },
            {
                "value": 68395,
                "label": "Seedorf",
                "label_ar": "سيدورف",
                "label_fr": "Seedorf"
            },
            {
                "value": 67560,
                "label": "Romanshorn",
                "label_ar": "رومانشورن",
                "label_fr": "Romanshorn"
            },
            {
                "value": 67284,
                "label": "Rotkreuz",
                "label_ar": "روتكروز",
                "label_fr": "Rotkreuz"
            },
            {
                "value": 67297,
                "label": "Sarnen",
                "label_ar": "سارنين",
                "label_fr": "Sarnen"
            },
            {
                "value": 67595,
                "label": "Morbio Inferiore",
                "label_ar": "موربيو إنفيريوري",
                "label_fr": "Morbio Inferiore"
            },
            {
                "value": 67820,
                "label": "Hauterive",
                "label_ar": "Hauterive",
                "label_fr": "Hauterive"
            },
            {
                "value": 68291,
                "label": "Orpund",
                "label_ar": "Orpund",
                "label_fr": "Orpund"
            },
            {
                "value": 67449,
                "label": "Gebenstorf",
                "label_ar": "جيبينستورف",
                "label_fr": "Gebenstorf"
            },
            {
                "value": 67499,
                "label": "Cornaux",
                "label_ar": "كورنو",
                "label_fr": "Cornaux"
            },
            {
                "value": 67662,
                "label": "Riedholz",
                "label_ar": "ريدهولز",
                "label_fr": "Riedholz"
            },
            {
                "value": 67416,
                "label": "Pregassona",
                "label_ar": "بريجاسونا",
                "label_fr": "Pregassona"
            },
            {
                "value": 67556,
                "label": "Chexbres",
                "label_ar": "Chexbres",
                "label_fr": "Chexbres"
            },
            {
                "value": 67959,
                "label": "Zuzgen",
                "label_ar": "زوزجن",
                "label_fr": "Zuzgen"
            },
            {
                "value": 67713,
                "label": "Unterlunkhofen",
                "label_ar": "Unterlunkhofen",
                "label_fr": "Unterlunkhofen"
            },
            {
                "value": 67227,
                "label": "Wallisellen",
                "label_ar": "واليسيلين",
                "label_fr": "Wallisellen"
            },
            {
                "value": 67891,
                "label": "Unteriberg",
                "label_ar": "أونتيريبيرج",
                "label_fr": "Unteriberg"
            },
            {
                "value": 67668,
                "label": "Saint-Maurice",
                "label_ar": "سان موريس",
                "label_fr": "Saint-Maurice"
            },
            {
                "value": 67279,
                "label": "Bulach",
                "label_ar": "بولاش",
                "label_fr": "Bulach"
            },
            {
                "value": 67345,
                "label": "Oberentfelden",
                "label_ar": "Oberentfelden",
                "label_fr": "Oberentfelden"
            },
            {
                "value": 67473,
                "label": "Buchs / Buchs (Dorf)",
                "label_ar": "بوخس / بوخس (دورف)",
                "label_fr": "Buchs / Buchs (Dorf)"
            },
            {
                "value": 68132,
                "label": "Reiden",
                "label_ar": "رايدن",
                "label_fr": "Reiden"
            },
            {
                "value": 68075,
                "label": "Morschach",
                "label_ar": "مورشاخ",
                "label_fr": "Morschach"
            },
            {
                "value": 68247,
                "label": "Weissbad",
                "label_ar": "ويسباد",
                "label_fr": "Weissbad"
            },
            {
                "value": 68240,
                "label": "Pampigny",
                "label_ar": "بامبيني",
                "label_fr": "Pampigny"
            },
            {
                "value": 67396,
                "label": "Bex",
                "label_ar": "بيكس",
                "label_fr": "Bex"
            },
            {
                "value": 68491,
                "label": "Ferden",
                "label_ar": "فيردن",
                "label_fr": "Ferden"
            },
            {
                "value": 67519,
                "label": "Grueningen",
                "label_ar": "Grueningen",
                "label_fr": "Grueningen"
            },
            {
                "value": 68379,
                "label": "Glion",
                "label_ar": "غليون",
                "label_fr": "Glion"
            },
            {
                "value": 67893,
                "label": "Bettingen",
                "label_ar": "بيتينغن",
                "label_fr": "Bettingen"
            },
            {
                "value": 68206,
                "label": "Ballwil",
                "label_ar": "بالويل",
                "label_fr": "Ballwil"
            },
            {
                "value": 68262,
                "label": "Guttingen",
                "label_ar": "جوتنجن",
                "label_fr": "Guttingen"
            },
            {
                "value": 67604,
                "label": "Bach",
                "label_ar": "باخ",
                "label_fr": "Bach"
            },
            {
                "value": 67652,
                "label": "Uerikon",
                "label_ar": "Uerikon",
                "label_fr": "Uerikon"
            },
            {
                "value": 67469,
                "label": "Rapperswil",
                "label_ar": "رابرزويل",
                "label_fr": "Rapperswil"
            },
            {
                "value": 67461,
                "label": "Untervaz",
                "label_ar": "Untervaz",
                "label_fr": "Untervaz"
            },
            {
                "value": 68436,
                "label": "Le Sentier",
                "label_ar": "لو سينتير",
                "label_fr": "Le Sentier"
            },
            {
                "value": 67509,
                "label": "Rickenbach",
                "label_ar": "ريكنباخ",
                "label_fr": "Rickenbach"
            },
            {
                "value": 67301,
                "label": "Hochdorf",
                "label_ar": "هوشدورف",
                "label_fr": "Hochdorf"
            },
            {
                "value": 67290,
                "label": "Neuenhof",
                "label_ar": "نوينهوف",
                "label_fr": "Neuenhof"
            },
            {
                "value": 67615,
                "label": "Fontainemelon",
                "label_ar": "Fontainemelon",
                "label_fr": "Fontainemelon"
            },
            {
                "value": 68213,
                "label": "Scuol",
                "label_ar": "سكول",
                "label_fr": "Scuol"
            },
            {
                "value": 68361,
                "label": "Ardon",
                "label_ar": "أردون",
                "label_fr": "Ardon"
            },
            {
                "value": 68372,
                "label": "Burchen",
                "label_ar": "بيرشين",
                "label_fr": "Burchen"
            },
            {
                "value": 68373,
                "label": "Merishausen",
                "label_ar": "ميريسهاوزن",
                "label_fr": "Merishausen"
            },
            {
                "value": 68133,
                "label": "Dagmersellen",
                "label_ar": "داجمرسلين",
                "label_fr": "Dagmersellen"
            },
            {
                "value": 68053,
                "label": "Oberrieden",
                "label_ar": "أوبيرريدن",
                "label_fr": "Oberrieden"
            },
            {
                "value": 67427,
                "label": "Versoix",
                "label_ar": "فيرسوا",
                "label_fr": "Versoix"
            },
            {
                "value": 67314,
                "label": "Chiasso",
                "label_ar": "شياسو",
                "label_fr": "Chiasso"
            },
            {
                "value": 67869,
                "label": "Liebistorf",
                "label_ar": "ليبيستورف",
                "label_fr": "Liebistorf"
            },
            {
                "value": 68390,
                "label": "Zeihen",
                "label_ar": "زيهين",
                "label_fr": "Zeihen"
            },
            {
                "value": 67399,
                "label": "Wittenbach",
                "label_ar": "ويتنباخ",
                "label_fr": "Wittenbach"
            },
            {
                "value": 67452,
                "label": "Seewis im Pratigau",
                "label_ar": "Seewis im Pratigau",
                "label_fr": "Seewis im Pratigau"
            },
            {
                "value": 68056,
                "label": "Veltheim",
                "label_ar": "فيلتهايم",
                "label_fr": "Veltheim"
            },
            {
                "value": 68083,
                "label": "Rue",
                "label_ar": "شارع",
                "label_fr": "Rue"
            },
            {
                "value": 67582,
                "label": "Broc",
                "label_ar": "بروك",
                "label_fr": "Broc"
            },
            {
                "value": 68385,
                "label": "Bitsch",
                "label_ar": "بيتش",
                "label_fr": "Bitsch"
            },
            {
                "value": 67386,
                "label": "Thun",
                "label_ar": "ثون",
                "label_fr": "Thoune"
            },
            {
                "value": 67835,
                "label": "Lurtigen",
                "label_ar": "لورتيغن",
                "label_fr": "Lurtigen"
            },
            {
                "value": 67884,
                "label": "Ins",
                "label_ar": "الإضافية",
                "label_fr": "Ins"
            },
            {
                "value": 67212,
                "label": "Rheinfelden",
                "label_ar": "راينفيلدن",
                "label_fr": "Rheinfelden"
            },
            {
                "value": 68462,
                "label": "Lindau",
                "label_ar": "لينداو",
                "label_fr": "Lindau"
            },
            {
                "value": 68266,
                "label": "Sattel",
                "label_ar": "ساتيل",
                "label_fr": "Sattel"
            },
            {
                "value": 67817,
                "label": "Egg",
                "label_ar": "بيضة",
                "label_fr": "Oeuf"
            },
            {
                "value": 68440,
                "label": "Stoos",
                "label_ar": "ستوس",
                "label_fr": "Stoos"
            },
            {
                "value": 68165,
                "label": "Campfer",
                "label_ar": "كامفير",
                "label_fr": "Campfer"
            },
            {
                "value": 68303,
                "label": "Sarn",
                "label_ar": "سارن",
                "label_fr": "Sarn"
            },
            {
                "value": 68318,
                "label": "Gerzensee",
                "label_ar": "Gerzensee",
                "label_fr": "Gerzensee"
            },
            {
                "value": 67691,
                "label": "Koblenz",
                "label_ar": "كوبلنز",
                "label_fr": "Coblence"
            },
            {
                "value": 68110,
                "label": "Thielle",
                "label_ar": "ثيل",
                "label_fr": "Thielle"
            },
            {
                "value": 67178,
                "label": "Saas-Fee",
                "label_ar": "Saas-Fee",
                "label_fr": "Saas-Fee"
            },
            {
                "value": 67281,
                "label": "Au",
                "label_ar": "Au",
                "label_fr": "Au"
            },
            {
                "value": 67170,
                "label": "Bannwil",
                "label_ar": "بانويل",
                "label_fr": "Bannwil"
            },
            {
                "value": 67365,
                "label": "Windisch",
                "label_ar": "وينديش",
                "label_fr": "Windisch"
            },
            {
                "value": 67744,
                "label": "Brenzikofen",
                "label_ar": "برينزيكوفين",
                "label_fr": "Brenzikofen"
            },
            {
                "value": 67354,
                "label": "Allschwil",
                "label_ar": "أولشويل",
                "label_fr": "Allschwil"
            },
            {
                "value": 67740,
                "label": "Flaach",
                "label_ar": "فلاخ",
                "label_fr": "Flaach"
            },
            {
                "value": 67274,
                "label": "Diessenhofen",
                "label_ar": "ديسنهوفن",
                "label_fr": "Diessenhofen"
            },
            {
                "value": 67537,
                "label": "Elsau-Raeterschen",
                "label_ar": "Elsau-Raeterschen",
                "label_fr": "Elsau-Raeterschen"
            },
            {
                "value": 68435,
                "label": "Les Acacias",
                "label_ar": "ليه أكاسياس",
                "label_fr": "Les Acacias"
            },
            {
                "value": 68450,
                "label": "Meiringen",
                "label_ar": "ميرينغن",
                "label_fr": "Meiringen"
            },
            {
                "value": 67229,
                "label": "Collombey",
                "label_ar": "كولومبي",
                "label_fr": "Collombey"
            },
            {
                "value": 68229,
                "label": "Wimmis",
                "label_ar": "ويميس",
                "label_fr": "Wimmis"
            },
            {
                "value": 67245,
                "label": "Biel/Bienne",
                "label_ar": "بيل / بين",
                "label_fr": "Bienne / Bienne"
            },
            {
                "value": 67680,
                "label": "Ayer",
                "label_ar": "آير",
                "label_fr": "Ayer"
            },
            {
                "value": 67837,
                "label": "Saint-Livres",
                "label_ar": "سانت ليفرس",
                "label_fr": "Saint-Livres"
            },
            {
                "value": 67597,
                "label": "Littau",
                "label_ar": "ليتاو",
                "label_fr": "Littau"
            },
            {
                "value": 67883,
                "label": "Kerzers",
                "label_ar": "كيرزر",
                "label_fr": "Kerzers"
            },
            {
                "value": 67598,
                "label": "Schachen",
                "label_ar": "شاشين",
                "label_fr": "Schachen"
            },
            {
                "value": 68342,
                "label": "Langenbruck",
                "label_ar": "لانغنبروك",
                "label_fr": "Langenbruck"
            },
            {
                "value": 67358,
                "label": "Saint-Sulpice",
                "label_ar": "سان سولبيس",
                "label_fr": "Saint-Sulpice"
            },
            {
                "value": 67735,
                "label": "Wald",
                "label_ar": "والد",
                "label_fr": "Wald"
            },
            {
                "value": 68032,
                "label": "Alterswil",
                "label_ar": "الترسويل",
                "label_fr": "Alterswil"
            },
            {
                "value": 68407,
                "label": "Schinznach Bad",
                "label_ar": "Schinznach باد",
                "label_fr": "Schinznach Bad"
            },
            {
                "value": 67572,
                "label": "Oberbuchsiten",
                "label_ar": "Oberbuchsiten",
                "label_fr": "Oberbuchsiten"
            },
            {
                "value": 67289,
                "label": "Wil",
                "label_ar": "ويل",
                "label_fr": "Wil"
            },
            {
                "value": 67877,
                "label": "Rickenbach",
                "label_ar": "ريكنباخ",
                "label_fr": "Rickenbach"
            },
            {
                "value": 67913,
                "label": "Staufen",
                "label_ar": "شتاوفن",
                "label_fr": "Staufen"
            },
            {
                "value": 68163,
                "label": "Hittnau",
                "label_ar": "هيتناو",
                "label_fr": "Hittnau"
            },
            {
                "value": 67708,
                "label": "Cheseaux-sur-Lausanne",
                "label_ar": "تشيزو سور لوزان",
                "label_fr": "Cheseaux-sur-Lausanne"
            },
            {
                "value": 67640,
                "label": "Eich",
                "label_ar": "ايش",
                "label_fr": "Eich"
            },
            {
                "value": 68164,
                "label": "Boll",
                "label_ar": "بول",
                "label_fr": "Boll"
            },
            {
                "value": 67189,
                "label": "Welschenrohr",
                "label_ar": "ويلشينروهر",
                "label_fr": "Welschenrohr"
            },
            {
                "value": 67205,
                "label": "Munsingen",
                "label_ar": "مونسينجين",
                "label_fr": "Munsingen"
            },
            {
                "value": 67920,
                "label": "La Chiesaz",
                "label_ar": "لا كيزاز",
                "label_fr": "La Chiesaz"
            },
            {
                "value": 68225,
                "label": "Chez-le-Bart",
                "label_ar": "شيز لو بارت",
                "label_fr": "Chez-le-Bart"
            },
            {
                "value": 67790,
                "label": "Munster",
                "label_ar": "مونستر",
                "label_fr": "Munster"
            },
            {
                "value": 67630,
                "label": "Kastanienbaum",
                "label_ar": "كاستانينباوم",
                "label_fr": "Kastanienbaum"
            },
            {
                "value": 67569,
                "label": "Glattfelden",
                "label_ar": "جلاتفيلدن",
                "label_fr": "Glattfelden"
            },
            {
                "value": 67181,
                "label": "Egerkingen",
                "label_ar": "إيجركينجين",
                "label_fr": "Egerkingen"
            },
            {
                "value": 68070,
                "label": "Eschenbach",
                "label_ar": "إشنباخ",
                "label_fr": "Eschenbach"
            },
            {
                "value": 68265,
                "label": "Vitznau",
                "label_ar": "فيتزناو",
                "label_fr": "Vitznau"
            },
            {
                "value": 68279,
                "label": "Meinier",
                "label_ar": "مينير",
                "label_fr": "Meinier"
            },
            {
                "value": 67805,
                "label": "Walchwil",
                "label_ar": "والشويل",
                "label_fr": "Walchwil"
            },
            {
                "value": 67907,
                "label": "Rothenthurm",
                "label_ar": "روثينثورم",
                "label_fr": "Rothenthurm"
            },
            {
                "value": 68304,
                "label": "Tasch",
                "label_ar": "تاش",
                "label_fr": "Tâche"
            },
            {
                "value": 68337,
                "label": "Chene-Paquier",
                "label_ar": "تشيني باكيير",
                "label_fr": "Chene-Paquier"
            },
            {
                "value": 68311,
                "label": "Amden",
                "label_ar": "امدن",
                "label_fr": "Amden"
            },
            {
                "value": 67324,
                "label": "Rorschach",
                "label_ar": "رورشاخ",
                "label_fr": "Rorschach"
            },
            {
                "value": 68108,
                "label": "Sennwald",
                "label_ar": "سينوالد",
                "label_fr": "Sennwald"
            },
            {
                "value": 68203,
                "label": "Bronschhofen",
                "label_ar": "برونشهوفن",
                "label_fr": "Bronschhofen"
            },
            {
                "value": 67925,
                "label": "Kolliken",
                "label_ar": "كوليكن",
                "label_fr": "Kolliken"
            },
            {
                "value": 68195,
                "label": "Weiningen",
                "label_ar": "وينينجين",
                "label_fr": "Weiningen"
            },
            {
                "value": 68452,
                "label": "Ufhusen",
                "label_ar": "أوفهوسن",
                "label_fr": "Ufhusen"
            },
            {
                "value": 67578,
                "label": "Grandvillard",
                "label_ar": "غراندفيلارد",
                "label_fr": "Grandvillard"
            },
            {
                "value": 68367,
                "label": "Manno",
                "label_ar": "مانو",
                "label_fr": "Manno"
            },
            {
                "value": 67172,
                "label": "Leukerbad",
                "label_ar": "لوكيرباد",
                "label_fr": "Leukerbad"
            },
            {
                "value": 67986,
                "label": "Schinznach Dorf",
                "label_ar": "شينزناش دورف",
                "label_fr": "Schinznach Dorf"
            },
            {
                "value": 68138,
                "label": "Vinzel",
                "label_ar": "فينزل",
                "label_fr": "Vinzel"
            },
            {
                "value": 67394,
                "label": "Siebnen",
                "label_ar": "سيبنين",
                "label_fr": "Siebnen"
            },
            {
                "value": 67610,
                "label": "Reconvilier",
                "label_ar": "Reconvilier",
                "label_fr": "Reconvilier"
            },
            {
                "value": 68066,
                "label": "Tauffelen",
                "label_ar": "توفلين",
                "label_fr": "Tauffelen"
            },
            {
                "value": 67777,
                "label": "Russin",
                "label_ar": "روسين",
                "label_fr": "Russin"
            },
            {
                "value": 67963,
                "label": "Dallenwil",
                "label_ar": "دالينويل",
                "label_fr": "Dallenwil"
            },
            {
                "value": 68018,
                "label": "Zuckenriet",
                "label_ar": "زوكينريت",
                "label_fr": "Zuckenriet"
            },
            {
                "value": 67327,
                "label": "Oberlunkhofen",
                "label_ar": "اوبرلونكوفن",
                "label_fr": "Oberlunkhofen"
            },
            {
                "value": 67243,
                "label": "Uhwiesen",
                "label_ar": "اوهويسين",
                "label_fr": "Uhwiesen"
            },
            {
                "value": 68131,
                "label": "Gontenschwil",
                "label_ar": "جونتينشويل",
                "label_fr": "Gontenschwil"
            },
            {
                "value": 67687,
                "label": "Orvin",
                "label_ar": "أورفين",
                "label_fr": "Orvin"
            },
            {
                "value": 68396,
                "label": "Erstfeld",
                "label_ar": "ارستفيلد",
                "label_fr": "Erstfeld"
            },
            {
                "value": 68040,
                "label": "Soyhieres",
                "label_ar": "الصويا",
                "label_fr": "Soyhieres"
            },
            {
                "value": 68253,
                "label": "Bursins",
                "label_ar": "بورسينس",
                "label_fr": "Bursins"
            },
            {
                "value": 67880,
                "label": "Erlach",
                "label_ar": "إرلاتش",
                "label_fr": "Erlach"
            },
            {
                "value": 68377,
                "label": "Iserables",
                "label_ar": "Iserables",
                "label_fr": "Isérables"
            },
            {
                "value": 68002,
                "label": "Flamatt",
                "label_ar": "فلامات",
                "label_fr": "Flamatt"
            },
            {
                "value": 67779,
                "label": "Fulenbach",
                "label_ar": "فولنباخ",
                "label_fr": "Fulenbach"
            },
            {
                "value": 67174,
                "label": "Visp",
                "label_ar": "فيسب",
                "label_fr": "Visp"
            },
            {
                "value": 67475,
                "label": "Belmont-sur-Lausanne",
                "label_ar": "بلمونت سور لوزان",
                "label_fr": "Belmont-sur-Lausanne"
            },
            {
                "value": 68182,
                "label": "Miecourt",
                "label_ar": "ميكورت",
                "label_fr": "Miecourt"
            },
            {
                "value": 67553,
                "label": "Köniz",
                "label_ar": "كونيز",
                "label_fr": "Köniz"
            },
            {
                "value": 67576,
                "label": "Neyruz",
                "label_ar": "نيروز",
                "label_fr": "Neyruz"
            },
            {
                "value": 67704,
                "label": "Hagglingen",
                "label_ar": "هاغلينغن",
                "label_fr": "Hagglingen"
            },
            {
                "value": 67669,
                "label": "Val d'Illiez",
                "label_ar": "فال ديليز",
                "label_fr": "Val d'Illiez"
            },
            {
                "value": 67471,
                "label": "Roche",
                "label_ar": "روش",
                "label_fr": "Roche"
            },
            {
                "value": 67361,
                "label": "Veyrier",
                "label_ar": "فيرير",
                "label_fr": "Veyrier"
            },
            {
                "value": 68048,
                "label": "Felben",
                "label_ar": "فيلبن",
                "label_fr": "Felben"
            },
            {
                "value": 68016,
                "label": "Schattdorf",
                "label_ar": "شاتدورف",
                "label_fr": "Schattdorf"
            },
            {
                "value": 68118,
                "label": "Gipf-Oberfrick",
                "label_ar": "جيبف أوبيرفريك",
                "label_fr": "Gipf-Oberfrick"
            },
            {
                "value": 67697,
                "label": "Wangen",
                "label_ar": "فانجين",
                "label_fr": "Wangen"
            },
            {
                "value": 67966,
                "label": "Buren nid dem Bach",
                "label_ar": "Buren nid dem Bach",
                "label_fr": "Buren nid dem Bach"
            },
            {
                "value": 68234,
                "label": "Eggersriet",
                "label_ar": "إيجرسريت",
                "label_fr": "Eggersriet"
            },
            {
                "value": 67897,
                "label": "Fullinsdorf",
                "label_ar": "فولينسدورف",
                "label_fr": "Fullinsdorf"
            },
            {
                "value": 67256,
                "label": "Laufen",
                "label_ar": "لوفين",
                "label_fr": "Laufen"
            },
            {
                "value": 67644,
                "label": "Wiesendangen",
                "label_ar": "Wiesendangen",
                "label_fr": "Wiesendangen"
            },
            {
                "value": 68179,
                "label": "Ligerz",
                "label_ar": "Ligerz",
                "label_fr": "Ligerz"
            },
            {
                "value": 68168,
                "label": "Sottens",
                "label_ar": "سوتينز",
                "label_fr": "Sottens"
            },
            {
                "value": 68045,
                "label": "Chamoson",
                "label_ar": "شاموسون",
                "label_fr": "Chamoson"
            },
            {
                "value": 68425,
                "label": "Zullwil",
                "label_ar": "زولويل",
                "label_fr": "Zullwil"
            },
            {
                "value": 68369,
                "label": "Unterseen",
                "label_ar": "أونترسين",
                "label_fr": "Unterseen"
            },
            {
                "value": 67755,
                "label": "Schoetz",
                "label_ar": "شويتز",
                "label_fr": "Schoetz"
            },
            {
                "value": 67894,
                "label": "Villmergen",
                "label_ar": "فيلميرجن",
                "label_fr": "Villmergen"
            },
            {
                "value": 67478,
                "label": "Altdorf",
                "label_ar": "التدورف",
                "label_fr": "Altdorf"
            },
            {
                "value": 67476,
                "label": "Zollikon",
                "label_ar": "زوليكون",
                "label_fr": "Zollikon"
            },
            {
                "value": 67760,
                "label": "Arbaz",
                "label_ar": "ارباز",
                "label_fr": "Arbaz"
            },
            {
                "value": 67451,
                "label": "Brugg",
                "label_ar": "بروغ",
                "label_fr": "Brugg"
            },
            {
                "value": 67816,
                "label": "Zumikon",
                "label_ar": "زوميكون",
                "label_fr": "Zumikon"
            },
            {
                "value": 67712,
                "label": "Frenkendorf",
                "label_ar": "فرينكيندورف",
                "label_fr": "Frenkendorf"
            },
            {
                "value": 67566,
                "label": "Andwil",
                "label_ar": "أندويل",
                "label_fr": "Andwil"
            },
            {
                "value": 67485,
                "label": "Mettmenstetten",
                "label_ar": "ميتمنستيتن",
                "label_fr": "Mettmenstetten"
            },
            {
                "value": 67270,
                "label": "Trimbach",
                "label_ar": "تريمباتش",
                "label_fr": "Trimbach"
            },
            {
                "value": 67381,
                "label": "Wollerau",
                "label_ar": "Wollerau",
                "label_fr": "Wollerau"
            },
            {
                "value": 67255,
                "label": "Giubiasco",
                "label_ar": "جيوبياسكو",
                "label_fr": "Giubiasco"
            },
            {
                "value": 68217,
                "label": "Orsonnens",
                "label_ar": "Orsonnens",
                "label_fr": "Orsonnens"
            },
            {
                "value": 67812,
                "label": "Siviriez",
                "label_ar": "Siviriez",
                "label_fr": "Siviriez"
            },
            {
                "value": 67956,
                "label": "Caslano",
                "label_ar": "كاسلانو",
                "label_fr": "Caslano"
            },
            {
                "value": 67581,
                "label": "Faoug",
                "label_ar": "فوغ",
                "label_fr": "Faoug"
            },
            {
                "value": 68484,
                "label": "Hermance",
                "label_ar": "هيرمانس",
                "label_fr": "Hermance"
            },
            {
                "value": 68026,
                "label": "Villaz-Saint-Pierre",
                "label_ar": "فيلاز سان بيير",
                "label_fr": "Villaz-Saint-Pierre"
            },
            {
                "value": 67888,
                "label": "Ziegelbrucke",
                "label_ar": "زيجلبروك",
                "label_fr": "Ziegelbrucke"
            },
            {
                "value": 67715,
                "label": "Les Breuleux",
                "label_ar": "ليه بريول",
                "label_fr": "Les Breuleux"
            },
            {
                "value": 67732,
                "label": "Brutten",
                "label_ar": "بروتين",
                "label_fr": "Brutten"
            },
            {
                "value": 68352,
                "label": "Altnau",
                "label_ar": "ألتناو",
                "label_fr": "Altnau"
            },
            {
                "value": 68269,
                "label": "Buonas",
                "label_ar": "بوناس",
                "label_fr": "Buonas"
            },
            {
                "value": 67413,
                "label": "Heimberg",
                "label_ar": "هايمبرج",
                "label_fr": "Heimberg"
            },
            {
                "value": 68173,
                "label": "Sezegnin",
                "label_ar": "سيزجنين",
                "label_fr": "Sezegnin"
            },
            {
                "value": 68335,
                "label": "Krauchthal",
                "label_ar": "كراوتشثال",
                "label_fr": "Krauchthal"
            },
            {
                "value": 67981,
                "label": "Adligenswil",
                "label_ar": "Adligenswil",
                "label_fr": "Adligenswil"
            },
            {
                "value": 67259,
                "label": "Pfaeffikon",
                "label_ar": "بفافيكون",
                "label_fr": "Pfaeffikon"
            },
            {
                "value": 67434,
                "label": "Fehraltorf",
                "label_ar": "فيرالتورف",
                "label_fr": "Fehraltorf"
            },
            {
                "value": 68315,
                "label": "Schleitheim",
                "label_ar": "شلايتهايم",
                "label_fr": "Schleitheim"
            },
            {
                "value": 68376,
                "label": "Menzingen",
                "label_ar": "Menzingen",
                "label_fr": "Menzingen"
            },
            {
                "value": 68426,
                "label": "Sils-Segl Maria",
                "label_ar": "سيلس سيجل ماريا",
                "label_fr": "Sils-Segl Maria"
            },
            {
                "value": 68037,
                "label": "Sins",
                "label_ar": "الذنوب",
                "label_fr": "péchés capitaux"
            },
            {
                "value": 67900,
                "label": "Hundwil",
                "label_ar": "هوندويل",
                "label_fr": "Hundwil"
            },
            {
                "value": 68449,
                "label": "Bonigen",
                "label_ar": "بونيجن",
                "label_fr": "Bonigen"
            },
            {
                "value": 68231,
                "label": "Gommiswald",
                "label_ar": "جوميسوالد",
                "label_fr": "Gommiswald"
            },
            {
                "value": 67954,
                "label": "Buchs",
                "label_ar": "بوخس",
                "label_fr": "Buchs"
            },
            {
                "value": 67456,
                "label": "Egliswil",
                "label_ar": "اغليسويل",
                "label_fr": "Egliswil"
            },
            {
                "value": 68340,
                "label": "Lenz",
                "label_ar": "لينز",
                "label_fr": "Lenz"
            },
            {
                "value": 68148,
                "label": "Corsier",
                "label_ar": "كورسير",
                "label_fr": "Corsier"
            },
            {
                "value": 68470,
                "label": "Savognin",
                "label_ar": "سافوجنين",
                "label_fr": "Savognin"
            },
            {
                "value": 67429,
                "label": "Samstagern",
                "label_ar": "سامستاغيرن",
                "label_fr": "Samstagern"
            },
            {
                "value": 67673,
                "label": "Erde",
                "label_ar": "إردي",
                "label_fr": "Erde"
            },
            {
                "value": 67742,
                "label": "Niederurnen",
                "label_ar": "نيدرورن",
                "label_fr": "Niederurnen"
            },
            {
                "value": 68469,
                "label": "Wolfhalden",
                "label_ar": "ولفلدن",
                "label_fr": "Wolfhalden"
            },
            {
                "value": 68441,
                "label": "Zunzgen",
                "label_ar": "زنزجن",
                "label_fr": "Zunzgen"
            },
            {
                "value": 67353,
                "label": "Peseux",
                "label_ar": "بيسيو",
                "label_fr": "Peseux"
            },
            {
                "value": 67383,
                "label": "Merenschwand",
                "label_ar": "Merenschwand",
                "label_fr": "Merenschwand"
            },
            {
                "value": 67703,
                "label": "Mellingen",
                "label_ar": "ميلينجن",
                "label_fr": "Mellingen"
            },
            {
                "value": 67832,
                "label": "Grandval",
                "label_ar": "غراندفال",
                "label_fr": "Grandval"
            },
            {
                "value": 67373,
                "label": "Derendingen",
                "label_ar": "ديرينجين",
                "label_fr": "Derendingen"
            },
            {
                "value": 68090,
                "label": "Busserach",
                "label_ar": "بوسيراخ",
                "label_fr": "Busserach"
            },
            {
                "value": 68429,
                "label": "Feusisberg",
                "label_ar": "Feusisberg",
                "label_fr": "Feusisberg"
            },
            {
                "value": 67839,
                "label": "Lenzerheide",
                "label_ar": "لينتسرهيد",
                "label_fr": "Lenzerheide"
            },
            {
                "value": 67495,
                "label": "Tagerwilen",
                "label_ar": "تايجرويلن",
                "label_fr": "Tagerwilen"
            },
            {
                "value": 67257,
                "label": "Frauenfeld",
                "label_ar": "فراونفيلد",
                "label_fr": "Frauenfeld"
            },
            {
                "value": 68051,
                "label": "Territet",
                "label_ar": "إقليم",
                "label_fr": "Territet"
            },
            {
                "value": 67762,
                "label": "Evionnaz",
                "label_ar": "Evionnaz",
                "label_fr": "Evionnaz"
            },
            {
                "value": 67360,
                "label": "Emmenbruecke",
                "label_ar": "Emmenbruecke",
                "label_fr": "Emmenbruecke"
            },
            {
                "value": 67774,
                "label": "Fully",
                "label_ar": "تماما",
                "label_fr": "Pleinement"
            },
            {
                "value": 67340,
                "label": "Tenero",
                "label_ar": "تينيرو",
                "label_fr": "Tenero"
            },
            {
                "value": 67831,
                "label": "Gimel",
                "label_ar": "جيميل",
                "label_fr": "Gimel"
            },
            {
                "value": 68387,
                "label": "Pfyn",
                "label_ar": "بفين",
                "label_fr": "Pfyn"
            },
            {
                "value": 68239,
                "label": "Camorino",
                "label_ar": "كامورينو",
                "label_fr": "Camorino"
            },
            {
                "value": 67300,
                "label": "Erlenbach",
                "label_ar": "إرلينباخ",
                "label_fr": "Erlenbach"
            },
            {
                "value": 68394,
                "label": "Schiers",
                "label_ar": "شيرز",
                "label_fr": "Schiers"
            },
            {
                "value": 68092,
                "label": "Basadingen",
                "label_ar": "باسادينجين",
                "label_fr": "Basadingen"
            },
            {
                "value": 67222,
                "label": "Wegenstetten",
                "label_ar": "Wegenstetten",
                "label_fr": "Wegenstetten"
            },
            {
                "value": 68207,
                "label": "Goldau",
                "label_ar": "غولداو",
                "label_fr": "Goldau"
            },
            {
                "value": 68416,
                "label": "Perroy",
                "label_ar": "Perroy",
                "label_fr": "Perroy"
            },
            {
                "value": 68060,
                "label": "Les Verrieres",
                "label_ar": "ليه فيرييه",
                "label_fr": "Les Verrières"
            },
            {
                "value": 67263,
                "label": "Wetzikon",
                "label_ar": "ويتزيكون",
                "label_fr": "Wetzikon"
            },
            {
                "value": 67889,
                "label": "Bilten",
                "label_ar": "بيلتين",
                "label_fr": "Bilten"
            },
            {
                "value": 68062,
                "label": "Rhazuns",
                "label_ar": "رازون",
                "label_fr": "Rhazuns"
            },
            {
                "value": 67216,
                "label": "Binningen",
                "label_ar": "Binningen",
                "label_fr": "Binningen"
            },
            {
                "value": 67751,
                "label": "Rafz",
                "label_ar": "رافز",
                "label_fr": "Rafz"
            },
            {
                "value": 67941,
                "label": "Erschwil",
                "label_ar": "ارشويل",
                "label_fr": "Erschwil"
            },
            {
                "value": 68006,
                "label": "Bonnefontaine",
                "label_ar": "بونيفونتين",
                "label_fr": "Bonnefontaine"
            },
            {
                "value": 67464,
                "label": "Wangs",
                "label_ar": "وانجس",
                "label_fr": "Wangs"
            },
            {
                "value": 67625,
                "label": "Steffisburg",
                "label_ar": "شتيفيسبرج",
                "label_fr": "Steffisburg"
            },
            {
                "value": 67547,
                "label": "Moutier",
                "label_ar": "موتير",
                "label_fr": "Moutier"
            },
            {
                "value": 68347,
                "label": "Thalheim",
                "label_ar": "ثالهايم",
                "label_fr": "Thalheim"
            },
            {
                "value": 67979,
                "label": "Gunzgen",
                "label_ar": "Gunzgen",
                "label_fr": "Gunzgen"
            },
            {
                "value": 67968,
                "label": "Zuzwil",
                "label_ar": "زوزويل",
                "label_fr": "Zuzwil"
            },
            {
                "value": 67392,
                "label": "Baden",
                "label_ar": "بادن",
                "label_fr": "Baden"
            },
            {
                "value": 67220,
                "label": "Oberdorf",
                "label_ar": "اوبردورف",
                "label_fr": "Oberdorf"
            },
            {
                "value": 67193,
                "label": "Gumligen",
                "label_ar": "جومليجن",
                "label_fr": "Gumligen"
            },
            {
                "value": 68244,
                "label": "Kleinlutzel",
                "label_ar": "كلاينلوتزل",
                "label_fr": "Kleinlutzel"
            },
            {
                "value": 67813,
                "label": "Chavannes-les-Forts",
                "label_ar": "شافان ليه فورت",
                "label_fr": "Chavannes-les-Forts"
            },
            {
                "value": 67899,
                "label": "Mollis",
                "label_ar": "موليس",
                "label_fr": "Mollis"
            },
            {
                "value": 67261,
                "label": "Yverdon-les-Bains",
                "label_ar": "يفردون ليه با",
                "label_fr": "Yverdon-les-Bains"
            },
            {
                "value": 67655,
                "label": "Villars-sur-Ollon",
                "label_ar": "فيلار سور اولون",
                "label_fr": "Villars-sur-Ollon"
            },
            {
                "value": 68422,
                "label": "Greppen",
                "label_ar": "جريبن",
                "label_fr": "Greppen"
            },
            {
                "value": 67787,
                "label": "Winkel",
                "label_ar": "وينكل",
                "label_fr": "Winkel"
            },
            {
                "value": 67599,
                "label": "Dattwil",
                "label_ar": "داتويل",
                "label_fr": "Dattwil"
            },
            {
                "value": 67406,
                "label": "Neuchatel",
                "label_ar": "نوشاتيل",
                "label_fr": "Neuchâtel"
            },
            {
                "value": 68220,
                "label": "Ettingen",
                "label_ar": "إيتنغن",
                "label_fr": "Ettingen"
            },
            {
                "value": 67538,
                "label": "Hinterkappelen",
                "label_ar": "Hinterkappelen",
                "label_fr": "Hinterkappelen"
            },
            {
                "value": 68223,
                "label": "Hilterfingen",
                "label_ar": "هيلترفينغن",
                "label_fr": "Hilterfingen"
            },
            {
                "value": 67190,
                "label": "Mohlin",
                "label_ar": "مولين",
                "label_fr": "Mohlin"
            },
            {
                "value": 67310,
                "label": "Forel",
                "label_ar": "فوريل",
                "label_fr": "Forel"
            },
            {
                "value": 67699,
                "label": "Bremgarten",
                "label_ar": "بريمغارتن",
                "label_fr": "Bremgarten"
            },
            {
                "value": 68177,
                "label": "Corminboeuf",
                "label_ar": "كورمينبوف",
                "label_fr": "Corminboeuf"
            },
            {
                "value": 68033,
                "label": "Niederbuchsiten",
                "label_ar": "Niederbuchsiten",
                "label_fr": "Niederbuchsiten"
            },
            {
                "value": 68302,
                "label": "Grono",
                "label_ar": "جرونو",
                "label_fr": "Grono"
            },
            {
                "value": 67868,
                "label": "Bosingen",
                "label_ar": "بوسينغين",
                "label_fr": "Bosingen"
            },
            {
                "value": 67807,
                "label": "Vuadens",
                "label_ar": "فوادنز",
                "label_fr": "Vuadens"
            },
            {
                "value": 68371,
                "label": "Arzo",
                "label_ar": "أرزو",
                "label_fr": "Arzo"
            },
            {
                "value": 67226,
                "label": "Kaiseraugst",
                "label_ar": "كايزراوجست",
                "label_fr": "Kaiseraugst"
            },
            {
                "value": 67890,
                "label": "Kallnach",
                "label_ar": "كالناتش",
                "label_fr": "Kallnach"
            },
            {
                "value": 68134,
                "label": "Ibach",
                "label_ar": "يباتش",
                "label_fr": "Ibach"
            },
            {
                "value": 67417,
                "label": "Wikon",
                "label_ar": "ويكون",
                "label_fr": "Wikon"
            },
            {
                "value": 67320,
                "label": "Solothurn",
                "label_ar": "سولوتورن",
                "label_fr": "Soleure"
            }
        ]
    },
    {
        "country": "Iraq",
        "cities": [
            {
                "value": 68502,
                "label": "Baghdad",
                "label_ar": "بغداد",
                "label_fr": "Bagdad"
            },
            {
                "value": 68507,
                "label": "Basra",
                "label_ar": "البصرة",
                "label_fr": "Bassora"
            },
            {
                "value": 68508,
                "label": "Bahr",
                "label_ar": "بحر",
                "label_fr": "Bahr"
            },
            {
                "value": 68505,
                "label": "Kirkuk",
                "label_ar": "كركوك",
                "label_fr": "Kirkouk"
            }
        ]
    },
    {
        "country": "Turkey",
        "cities": [
            {
                "value": 68819,
                "label": "Hurriyet",
                "label_ar": "حريت",
                "label_fr": "Hurriyet"
            },
            {
                "value": 68546,
                "label": "Balcova",
                "label_ar": "بالكوفا",
                "label_fr": "Balcova"
            },
            {
                "value": 68790,
                "label": "Ferizli",
                "label_ar": "فيريزلي",
                "label_fr": "Ferizli"
            },
            {
                "value": 68682,
                "label": "Elâzığ",
                "label_ar": "العزيغ",
                "label_fr": "Elâzığ"
            },
            {
                "value": 68755,
                "label": "Esenkent",
                "label_ar": "Esenkent",
                "label_fr": "Esenkent"
            },
            {
                "value": 68640,
                "label": "Karabük Province",
                "label_ar": "مقاطعة كارابوك",
                "label_fr": "Province de Karabük"
            },
            {
                "value": 68826,
                "label": "İbişkōy",
                "label_ar": "İbişkōy",
                "label_fr": "İbişkōy"
            },
            {
                "value": 68545,
                "label": "Tire",
                "label_ar": "إطار العجلة",
                "label_fr": "Pneu"
            },
            {
                "value": 68619,
                "label": "Aydin",
                "label_ar": "أيدين",
                "label_fr": "Aydin"
            },
            {
                "value": 68542,
                "label": "Sisli",
                "label_ar": "شيشلي",
                "label_fr": "Sisli"
            },
            {
                "value": 68601,
                "label": "Dogus",
                "label_ar": "دوجوس",
                "label_fr": "Dogus"
            },
            {
                "value": 68539,
                "label": "Adapazarı",
                "label_ar": "Adapazarı",
                "label_fr": "Adapazarı"
            },
            {
                "value": 68524,
                "label": "Samsun",
                "label_ar": "سامسون",
                "label_fr": "Samsun"
            },
            {
                "value": 68597,
                "label": "Çanakkale",
                "label_ar": "جناق قلعة",
                "label_fr": "Çanakkale"
            },
            {
                "value": 68595,
                "label": "Erzincan",
                "label_ar": "ارزينجان",
                "label_fr": "Erzincan"
            },
            {
                "value": 68771,
                "label": "Doruklu",
                "label_ar": "دوروكلو",
                "label_fr": "Doruklu"
            },
            {
                "value": 68580,
                "label": "Mardin",
                "label_ar": "ماردين",
                "label_fr": "Mardin"
            },
            {
                "value": 68611,
                "label": "Kecioeren",
                "label_ar": "كيسيورين",
                "label_fr": "Kecioeren"
            },
            {
                "value": 68554,
                "label": "Muratpasa",
                "label_ar": "مراد باشا",
                "label_fr": "Muratpasa"
            },
            {
                "value": 68802,
                "label": "Kemal",
                "label_ar": "كمال",
                "label_fr": "Kemal"
            },
            {
                "value": 68528,
                "label": "Orhangazi",
                "label_ar": "Orhangazi",
                "label_fr": "Orhangazi"
            },
            {
                "value": 68610,
                "label": "Kastamonu",
                "label_ar": "كاستامونو",
                "label_fr": "Kastamonu"
            },
            {
                "value": 68825,
                "label": "Susurluk",
                "label_ar": "سوسرلوك",
                "label_fr": "Susurluk"
            },
            {
                "value": 68687,
                "label": "Karatekin",
                "label_ar": "كاراتيكين",
                "label_fr": "Karatekin"
            },
            {
                "value": 68736,
                "label": "Dalaman",
                "label_ar": "دالامان",
                "label_fr": "Dalaman"
            },
            {
                "value": 68556,
                "label": "Kirikkale",
                "label_ar": "كيريكالي",
                "label_fr": "Kirikkale"
            },
            {
                "value": 68744,
                "label": "Yakakoy",
                "label_ar": "ياكاكوي",
                "label_fr": "Yakakoy"
            },
            {
                "value": 68748,
                "label": "Beykent",
                "label_ar": "بيكنت",
                "label_fr": "Beykent"
            },
            {
                "value": 68693,
                "label": "Sinop",
                "label_ar": "سينوب",
                "label_fr": "Sinop"
            },
            {
                "value": 68681,
                "label": "Karaman",
                "label_ar": "كرمان",
                "label_fr": "Karaman"
            },
            {
                "value": 68715,
                "label": "Bozuyuk",
                "label_ar": "بوزويوك",
                "label_fr": "Bozuyuk"
            },
            {
                "value": 68708,
                "label": "Germencik",
                "label_ar": "جيرمنسيك",
                "label_fr": "Germencik"
            },
            {
                "value": 68713,
                "label": "Ayazaga",
                "label_ar": "أيازاجا",
                "label_fr": "Ayazaga"
            },
            {
                "value": 68669,
                "label": "Esenyurt",
                "label_ar": "اسنيورت",
                "label_fr": "Esenyurt"
            },
            {
                "value": 68561,
                "label": "Kadikoy",
                "label_ar": "كاديكوي",
                "label_fr": "Kadikoy"
            },
            {
                "value": 68806,
                "label": "Gazi",
                "label_ar": "غازي",
                "label_fr": "Gazi"
            },
            {
                "value": 68584,
                "label": "Kırşehir",
                "label_ar": "كيرشهير",
                "label_fr": "Kırşehir"
            },
            {
                "value": 68637,
                "label": "Ercis",
                "label_ar": "إرسيس",
                "label_fr": "Ercis"
            },
            {
                "value": 68667,
                "label": "Tesvikiye",
                "label_ar": "Tesvikiye",
                "label_fr": "Tesvikiye"
            },
            {
                "value": 68623,
                "label": "Seyhan",
                "label_ar": "سيحان",
                "label_fr": "Seyhan"
            },
            {
                "value": 68543,
                "label": "Magnesia ad Sipylum",
                "label_ar": "Magnesia ad Sipylum",
                "label_fr": "Magnésie et Sipylum"
            },
            {
                "value": 68801,
                "label": "Baskent",
                "label_ar": "باسكنت",
                "label_fr": "Baskent"
            },
            {
                "value": 68534,
                "label": "Diyarbakır",
                "label_ar": "ديار بكر",
                "label_fr": "Diyarbakır"
            },
            {
                "value": 68629,
                "label": "Iskilip",
                "label_ar": "إسكيليب",
                "label_fr": "Iskilip"
            },
            {
                "value": 68589,
                "label": "Merzifon",
                "label_ar": "Merzifon",
                "label_fr": "Merzifon"
            },
            {
                "value": 68763,
                "label": "Alanya",
                "label_ar": "الانيا",
                "label_fr": "Alanya"
            },
            {
                "value": 68518,
                "label": "Izmir",
                "label_ar": "إزمير",
                "label_fr": "Izmir"
            },
            {
                "value": 68798,
                "label": "Sabanci",
                "label_ar": "سابانجي",
                "label_fr": "Sabanci"
            },
            {
                "value": 68812,
                "label": "Iğdır",
                "label_ar": "Iğdır",
                "label_fr": "Iğdır"
            },
            {
                "value": 68670,
                "label": "Silifke",
                "label_ar": "سيليفكي",
                "label_fr": "Silifke"
            },
            {
                "value": 68706,
                "label": "Bor",
                "label_ar": "بور",
                "label_fr": "Bor"
            },
            {
                "value": 68723,
                "label": "Baskil",
                "label_ar": "باسكل",
                "label_fr": "Baskil"
            },
            {
                "value": 68570,
                "label": "Mugla",
                "label_ar": "موغلا",
                "label_fr": "Mugla"
            },
            {
                "value": 68576,
                "label": "Bilgi",
                "label_ar": "بيلجي",
                "label_fr": "Bilgi"
            },
            {
                "value": 68653,
                "label": "Pendik",
                "label_ar": "بنديك",
                "label_fr": "Pendik"
            },
            {
                "value": 68632,
                "label": "Yozgat",
                "label_ar": "يوزغات",
                "label_fr": "Yozgat"
            },
            {
                "value": 68674,
                "label": "Zeytinburnu",
                "label_ar": "زيتين بورنو",
                "label_fr": "Zeytinburnu"
            },
            {
                "value": 68759,
                "label": "Erenkoy",
                "label_ar": "إرينكوي",
                "label_fr": "Erenkoy"
            },
            {
                "value": 68775,
                "label": "Elek",
                "label_ar": "إليك",
                "label_fr": "Elek"
            },
            {
                "value": 68559,
                "label": "Bodrum",
                "label_ar": "بودروم",
                "label_fr": "Bodrum"
            },
            {
                "value": 68586,
                "label": "Yalova",
                "label_ar": "يالوفا",
                "label_fr": "Yalova"
            },
            {
                "value": 68562,
                "label": "Kayseri",
                "label_ar": "قيصري",
                "label_fr": "Kayseri"
            },
            {
                "value": 68747,
                "label": "Unieh",
                "label_ar": "أونيه",
                "label_fr": "Unieh"
            },
            {
                "value": 68658,
                "label": "Banaz",
                "label_ar": "باناز",
                "label_fr": "Banaz"
            },
            {
                "value": 68617,
                "label": "Giresun",
                "label_ar": "جيرسون",
                "label_fr": "Giresun"
            },
            {
                "value": 68615,
                "label": "Bayburt",
                "label_ar": "بايبورت",
                "label_fr": "Bayburt"
            },
            {
                "value": 68532,
                "label": "Ordu",
                "label_ar": "أوردو",
                "label_fr": "Ordu"
            },
            {
                "value": 68645,
                "label": "Artvin",
                "label_ar": "أرتفين",
                "label_fr": "Artvin"
            },
            {
                "value": 68652,
                "label": "UEskuedar",
                "label_ar": "UEskuedar",
                "label_fr": "UEskuedar"
            },
            {
                "value": 68646,
                "label": "Niksar",
                "label_ar": "نيكسار",
                "label_fr": "Niksar"
            },
            {
                "value": 68557,
                "label": "Karamursel",
                "label_ar": "Karamursel",
                "label_fr": "Karamursel"
            },
            {
                "value": 68804,
                "label": "Gediz",
                "label_ar": "جيدز",
                "label_fr": "Gediz"
            },
            {
                "value": 68590,
                "label": "Tekirdağ",
                "label_ar": "تيكيرداغ",
                "label_fr": "Tekirdağ"
            },
            {
                "value": 68784,
                "label": "Bugdayli",
                "label_ar": "بوجديلي",
                "label_fr": "Bugdayli"
            },
            {
                "value": 68604,
                "label": "Bolu",
                "label_ar": "بولو",
                "label_fr": "Bolu"
            },
            {
                "value": 68523,
                "label": "Bitlis",
                "label_ar": "بيتليس",
                "label_fr": "Bitlis"
            },
            {
                "value": 68614,
                "label": "Gümüşhane",
                "label_ar": "جوموشانه",
                "label_fr": "Gümüşhane"
            },
            {
                "value": 68769,
                "label": "Ataturk",
                "label_ar": "أتاتورك",
                "label_fr": "Atatürk"
            },
            {
                "value": 68582,
                "label": "Batman",
                "label_ar": "الرجل الوطواط",
                "label_fr": "Homme chauve-souris"
            },
            {
                "value": 68607,
                "label": "Siirt",
                "label_ar": "سيرت",
                "label_fr": "Siirt"
            },
            {
                "value": 68635,
                "label": "Van",
                "label_ar": "سيارة نقل",
                "label_fr": "Van"
            },
            {
                "value": 68660,
                "label": "Nazilli",
                "label_ar": "نازيلي",
                "label_fr": "Nazilli"
            },
            {
                "value": 68519,
                "label": "Ankara",
                "label_ar": "أنقرة",
                "label_fr": "Ankara"
            },
            {
                "value": 68594,
                "label": "Maltepe",
                "label_ar": "مالتيب",
                "label_fr": "Maltepe"
            },
            {
                "value": 68730,
                "label": "Turgutlu",
                "label_ar": "تورغوتلو",
                "label_fr": "Turgutlu"
            },
            {
                "value": 68761,
                "label": "Belek",
                "label_ar": "بيليك",
                "label_fr": "Belek"
            },
            {
                "value": 68544,
                "label": "Bergama",
                "label_ar": "بيرجاما",
                "label_fr": "Bergama"
            },
            {
                "value": 68778,
                "label": "Muradiye",
                "label_ar": "مرادية",
                "label_fr": "Muradiye"
            },
            {
                "value": 68776,
                "label": "Dokuz",
                "label_ar": "دوكوز",
                "label_fr": "Dokuz"
            },
            {
                "value": 68789,
                "label": "Beysehir",
                "label_ar": "بيشهير",
                "label_fr": "Beysehir"
            },
            {
                "value": 68650,
                "label": "Niluefer",
                "label_ar": "نيلوفر",
                "label_fr": "Niluefer"
            },
            {
                "value": 68602,
                "label": "Burgaz",
                "label_ar": "بورغاز",
                "label_fr": "Burgaz"
            },
            {
                "value": 68587,
                "label": "Balıkesir",
                "label_ar": "باليكسير",
                "label_fr": "Balıkesir"
            },
            {
                "value": 68710,
                "label": "Ikitelli",
                "label_ar": "إيكيتيلي",
                "label_fr": "Ikitelli"
            },
            {
                "value": 68569,
                "label": "Kars",
                "label_ar": "كارس",
                "label_fr": "Kars"
            },
            {
                "value": 68703,
                "label": "Safranbolu",
                "label_ar": "سافرانبولو",
                "label_fr": "Safranbolu"
            },
            {
                "value": 68626,
                "label": "Manavgat",
                "label_ar": "مانافجات",
                "label_fr": "Manavgat"
            }
        ]
    },
    {
        "country": "Romania",
        "cities": [
            {
                "value": 69099,
                "label": "Poiana Ilvei",
                "label_ar": "بويانا إيلفي",
                "label_fr": "Poiana Ilvei"
            },
            {
                "value": 69089,
                "label": "Tartasesti",
                "label_ar": "تارتاسستي",
                "label_fr": "Tartasesti"
            },
            {
                "value": 68922,
                "label": "Bârlad",
                "label_ar": "برلاد",
                "label_fr": "Bârlad"
            },
            {
                "value": 69057,
                "label": "Bujor",
                "label_ar": "بوجور",
                "label_fr": "Bujor"
            },
            {
                "value": 68906,
                "label": "Salatrucel",
                "label_ar": "Salatrucel",
                "label_fr": "Salatrucel"
            },
            {
                "value": 68856,
                "label": "Targu Gangulesti",
                "label_ar": "تارجو جانجوليستي",
                "label_fr": "Targu Gangulesti"
            },
            {
                "value": 69068,
                "label": "Deta",
                "label_ar": "ديتا",
                "label_fr": "Deta"
            },
            {
                "value": 69018,
                "label": "Valea Seaca",
                "label_ar": "فاليا سيكا",
                "label_fr": "Valea Seaca"
            },
            {
                "value": 69203,
                "label": "Odobesti",
                "label_ar": "أودوبيستي",
                "label_fr": "Odobesti"
            },
            {
                "value": 68947,
                "label": "Cugir",
                "label_ar": "كوجير",
                "label_fr": "Cugir"
            },
            {
                "value": 69043,
                "label": "Valenii de Munte",
                "label_ar": "فاليني دي مونتي",
                "label_fr": "Valenii de Munte"
            },
            {
                "value": 69084,
                "label": "Rohia",
                "label_ar": "روهيا",
                "label_fr": "Rohia"
            },
            {
                "value": 69172,
                "label": "Ciumani",
                "label_ar": "Ciumani",
                "label_fr": "Ciumani"
            },
            {
                "value": 69092,
                "label": "Tunari",
                "label_ar": "توناري",
                "label_fr": "Tunari"
            },
            {
                "value": 69119,
                "label": "Corlateni",
                "label_ar": "كورلاتيني",
                "label_fr": "Corlateni"
            },
            {
                "value": 69035,
                "label": "Tulnici",
                "label_ar": "تولنيسي",
                "label_fr": "Tulnici"
            },
            {
                "value": 69148,
                "label": "Susenii Bargaului",
                "label_ar": "سوسيني بارجولوي",
                "label_fr": "Susenii Bargaului"
            },
            {
                "value": 68849,
                "label": "Buzău",
                "label_ar": "بوزاو",
                "label_fr": "Buzău"
            },
            {
                "value": 68951,
                "label": "Madaras",
                "label_ar": "ماداراس",
                "label_fr": "Madaras"
            },
            {
                "value": 69019,
                "label": "Alunu",
                "label_ar": "ألونو",
                "label_fr": "Alunu"
            },
            {
                "value": 69189,
                "label": "Panduri",
                "label_ar": "باندوري",
                "label_fr": "Panduri"
            },
            {
                "value": 68977,
                "label": "Berceni",
                "label_ar": "بيرسيني",
                "label_fr": "Berceni"
            },
            {
                "value": 69135,
                "label": "Dridu",
                "label_ar": "دريدو",
                "label_fr": "Dridu"
            },
            {
                "value": 68870,
                "label": "Miercurea-Ciuc",
                "label_ar": "ميركوريا سيوك",
                "label_fr": "Miercurea-Ciuc"
            },
            {
                "value": 68903,
                "label": "Jibou",
                "label_ar": "جيبو",
                "label_fr": "Jibou"
            },
            {
                "value": 68975,
                "label": "Marginea",
                "label_ar": "الهامش",
                "label_fr": "Marginea"
            },
            {
                "value": 69159,
                "label": "Sebes",
                "label_ar": "سيبس",
                "label_fr": "Sebes"
            },
            {
                "value": 69079,
                "label": "Soimus",
                "label_ar": "Soimus",
                "label_fr": "Soimus"
            },
            {
                "value": 68960,
                "label": "Otelu Rosu",
                "label_ar": "أوتيلو روسو",
                "label_fr": "Otelu Rosu"
            },
            {
                "value": 69197,
                "label": "Humulesti",
                "label_ar": "Humulesti",
                "label_fr": "Humulesti"
            },
            {
                "value": 69183,
                "label": "Mandruloc",
                "label_ar": "Mandruloc",
                "label_fr": "Mandruloc"
            },
            {
                "value": 69025,
                "label": "Crangasi",
                "label_ar": "كرانجاسي",
                "label_fr": "Crangasi"
            },
            {
                "value": 69014,
                "label": "Cara",
                "label_ar": "كارا",
                "label_fr": "Cara"
            },
            {
                "value": 68978,
                "label": "Lacu",
                "label_ar": "لاكو",
                "label_fr": "Lacu"
            },
            {
                "value": 68844,
                "label": "Alba Iulia",
                "label_ar": "ألبا يوليا",
                "label_fr": "Alba Iulia"
            },
            {
                "value": 68834,
                "label": "Galați",
                "label_ar": "جالاتي",
                "label_fr": "Galați"
            },
            {
                "value": 68828,
                "label": "Piteşti",
                "label_ar": "بيتيشت",
                "label_fr": "Piteşti"
            },
            {
                "value": 69120,
                "label": "Gura Humorului",
                "label_ar": "جورا هومورولوي",
                "label_fr": "Gura Humorului"
            },
            {
                "value": 69066,
                "label": "Voiteg",
                "label_ar": "فويتج",
                "label_fr": "Voiteg"
            },
            {
                "value": 68902,
                "label": "Lehliu-Gara",
                "label_ar": "ليهليو غارا",
                "label_fr": "Lehliu-Gara"
            },
            {
                "value": 68952,
                "label": "Decebal",
                "label_ar": "ديسيبال",
                "label_fr": "Decebal"
            },
            {
                "value": 68869,
                "label": "Miercurea Sibiului",
                "label_ar": "ميركوريا سيبيولوي",
                "label_fr": "Miercurea Sibiului"
            },
            {
                "value": 69174,
                "label": "Stefanestii de Jos",
                "label_ar": "ستيفانيستي دي جوس",
                "label_fr": "Stefanestii de Jos"
            },
            {
                "value": 69103,
                "label": "Costesti",
                "label_ar": "كوستستي",
                "label_fr": "Costesti"
            },
            {
                "value": 68983,
                "label": "Moinesti",
                "label_ar": "موينيستي",
                "label_fr": "Moinesti"
            },
            {
                "value": 68841,
                "label": "Târgu Mureş",
                "label_ar": "تارغو موريس",
                "label_fr": "Târgu Mureş"
            },
            {
                "value": 68875,
                "label": "Bucu",
                "label_ar": "بوكو",
                "label_fr": "Bucu"
            },
            {
                "value": 69157,
                "label": "Giroc",
                "label_ar": "جيروك",
                "label_fr": "Giroc"
            },
            {
                "value": 68939,
                "label": "Orsova",
                "label_ar": "أورسوفا",
                "label_fr": "Orsova"
            },
            {
                "value": 69204,
                "label": "Hangulesti",
                "label_ar": "هانغوليستي",
                "label_fr": "Hangulesti"
            },
            {
                "value": 69071,
                "label": "Dej",
                "label_ar": "Dej",
                "label_fr": "Dej"
            },
            {
                "value": 68905,
                "label": "Turnu Ruieni",
                "label_ar": "تورنو روييني",
                "label_fr": "Turnu Ruieni"
            },
            {
                "value": 68981,
                "label": "Zimnicea",
                "label_ar": "زيمنسيا",
                "label_fr": "Zimnicea"
            },
            {
                "value": 68861,
                "label": "Giurgiu",
                "label_ar": "جورجيو",
                "label_fr": "Giurgiu"
            },
            {
                "value": 68933,
                "label": "Cisnadie",
                "label_ar": "سيسنادي",
                "label_fr": "Cisnadie"
            },
            {
                "value": 68927,
                "label": "Arad",
                "label_ar": "اراد",
                "label_fr": "Arad"
            },
            {
                "value": 68854,
                "label": "Braşov",
                "label_ar": "براشوف",
                "label_fr": "Braşov"
            },
            {
                "value": 69049,
                "label": "Tatarani",
                "label_ar": "تتاراني",
                "label_fr": "Tatarani"
            },
            {
                "value": 69177,
                "label": "Stanilesti",
                "label_ar": "ستانيلستي",
                "label_fr": "Stanilesti"
            },
            {
                "value": 69121,
                "label": "Salcea",
                "label_ar": "سالسيا",
                "label_fr": "Salcée"
            },
            {
                "value": 69109,
                "label": "Sancraieni",
                "label_ar": "سانكرايني",
                "label_fr": "Sancraieni"
            },
            {
                "value": 68996,
                "label": "Vidin",
                "label_ar": "فيدين",
                "label_fr": "Vidin"
            },
            {
                "value": 68930,
                "label": "Fogarasch",
                "label_ar": "فوجراش",
                "label_fr": "Fogarasch"
            },
            {
                "value": 68838,
                "label": "Navodari",
                "label_ar": "نافوداري",
                "label_fr": "Navodari"
            },
            {
                "value": 69030,
                "label": "Iorga",
                "label_ar": "إيورجا",
                "label_fr": "Iorga"
            },
            {
                "value": 68887,
                "label": "Turda",
                "label_ar": "توردا",
                "label_fr": "Turda"
            },
            {
                "value": 69105,
                "label": "Stroesti",
                "label_ar": "ستروستي",
                "label_fr": "Stroesti"
            },
            {
                "value": 68888,
                "label": "Odorheiu Secuiesc",
                "label_ar": "Odorheiu Secuiesc",
                "label_fr": "Odorheiu Secuiesc"
            },
            {
                "value": 69160,
                "label": "Lancram",
                "label_ar": "لانكرام",
                "label_fr": "Lancram"
            },
            {
                "value": 68979,
                "label": "Domnesti",
                "label_ar": "دومنيستي",
                "label_fr": "Domnesti"
            },
            {
                "value": 68929,
                "label": "Simeria",
                "label_ar": "سيميريا",
                "label_fr": "Simeria"
            },
            {
                "value": 69027,
                "label": "Sangeorge",
                "label_ar": "سانجورج",
                "label_fr": "Sangeorge"
            },
            {
                "value": 69153,
                "label": "Râşnov",
                "label_ar": "راشنوف",
                "label_fr": "Râşnov"
            },
            {
                "value": 68970,
                "label": "Busteni",
                "label_ar": "بوستيني",
                "label_fr": "Busteni"
            },
            {
                "value": 69101,
                "label": "Fundulea",
                "label_ar": "قاع العين",
                "label_fr": "Fundulea"
            },
            {
                "value": 69041,
                "label": "Oltenita",
                "label_ar": "أولتينيتا",
                "label_fr": "Oltenita"
            },
            {
                "value": 69093,
                "label": "Tandarei",
                "label_ar": "تنداري",
                "label_fr": "Tandarei"
            },
            {
                "value": 69146,
                "label": "Prod",
                "label_ar": "همز",
                "label_fr": "Prod"
            },
            {
                "value": 69072,
                "label": "Gilau",
                "label_ar": "جيلاو",
                "label_fr": "Gilau"
            },
            {
                "value": 69070,
                "label": "Gataia",
                "label_ar": "قطيع",
                "label_fr": "Gataia"
            },
            {
                "value": 68847,
                "label": "Botoşani",
                "label_ar": "بوتشاني",
                "label_fr": "Botoşani"
            },
            {
                "value": 69060,
                "label": "Bucsani",
                "label_ar": "بوكساني",
                "label_fr": "Bucsani"
            },
            {
                "value": 68914,
                "label": "Campia Turzii",
                "label_ar": "كامبيا تورزي",
                "label_fr": "Campia Turzii"
            },
            {
                "value": 69184,
                "label": "Moroda",
                "label_ar": "مورودا",
                "label_fr": "Moroda"
            },
            {
                "value": 68846,
                "label": "Petresti",
                "label_ar": "بيتريستي",
                "label_fr": "Petresti"
            },
            {
                "value": 68936,
                "label": "Curtea de Argeş",
                "label_ar": "كورتيا دي أرجيس",
                "label_fr": "Curtea de Argeş"
            },
            {
                "value": 69052,
                "label": "Vedea",
                "label_ar": "فيديا",
                "label_fr": "Vedea"
            }
        ]
    },
    {
        "country": "Lebanon",
        "cities": [
            {
                "value": 69215,
                "label": "Bsalim",
                "label_ar": "بصاليم",
                "label_fr": "Bsalim"
            },
            {
                "value": 69226,
                "label": "Dbaiye",
                "label_ar": "دبى",
                "label_fr": "Dbaiye"
            },
            {
                "value": 69219,
                "label": "Dik el Mehdi",
                "label_ar": "ديك المهدي",
                "label_fr": "Dik el Mehdi"
            },
            {
                "value": 69221,
                "label": "Tripoli",
                "label_ar": "طرابلس",
                "label_fr": "Tripoli"
            },
            {
                "value": 69229,
                "label": "Sarba",
                "label_ar": "صربا",
                "label_fr": "Sarba"
            },
            {
                "value": 69222,
                "label": "Baabda",
                "label_ar": "بعبدا",
                "label_fr": "Baabda"
            }
        ]
    },
    {
        "country": "Hungary",
        "cities": [
            {
                "value": 69890,
                "label": "Mandok",
                "label_ar": "ماندوك",
                "label_fr": "Mandok"
            },
            {
                "value": 69733,
                "label": "Dunavarsany",
                "label_ar": "دونافارساني",
                "label_fr": "Dunavarsany"
            },
            {
                "value": 69978,
                "label": "Sarszentlorinc",
                "label_ar": "سارسزينتلورينك",
                "label_fr": "Sarszentlorinc"
            },
            {
                "value": 69853,
                "label": "Varbo",
                "label_ar": "فاربو",
                "label_fr": "Varbo"
            },
            {
                "value": 69897,
                "label": "Kunsziget",
                "label_ar": "كونزيجيت",
                "label_fr": "Kunsziget"
            },
            {
                "value": 69378,
                "label": "Tura",
                "label_ar": "طرة",
                "label_fr": "Tura"
            },
            {
                "value": 69362,
                "label": "Kadarkut",
                "label_ar": "كادركوت",
                "label_fr": "Kadarkut"
            },
            {
                "value": 69570,
                "label": "Korosladany",
                "label_ar": "كوروسلاداني",
                "label_fr": "Korosladany"
            },
            {
                "value": 69544,
                "label": "Pilisszentkereszt",
                "label_ar": "Pilisszentkereszt",
                "label_fr": "Pilisszentkereszt"
            },
            {
                "value": 69462,
                "label": "Ercsi",
                "label_ar": "إركسي",
                "label_fr": "Ercsi"
            },
            {
                "value": 69317,
                "label": "Győr",
                "label_ar": "جيور",
                "label_fr": "Győr"
            },
            {
                "value": 69916,
                "label": "Makad",
                "label_ar": "مكاد",
                "label_fr": "Makad"
            },
            {
                "value": 69454,
                "label": "Galgamacsa",
                "label_ar": "Galgamacsa",
                "label_fr": "Galgamacsa"
            },
            {
                "value": 69612,
                "label": "Komlod",
                "label_ar": "كوملود",
                "label_fr": "Komlod"
            },
            {
                "value": 69714,
                "label": "Szigethalom",
                "label_ar": "سيغيثالوم",
                "label_fr": "Szigethalom"
            },
            {
                "value": 69907,
                "label": "Vacduka",
                "label_ar": "فادوكا",
                "label_fr": "Vacduka"
            },
            {
                "value": 69585,
                "label": "Pakozd",
                "label_ar": "باكوزد",
                "label_fr": "Pakozd"
            },
            {
                "value": 69743,
                "label": "Koka",
                "label_ar": "كوكا",
                "label_fr": "Koka"
            },
            {
                "value": 69557,
                "label": "Toekoel",
                "label_ar": "تويكويل",
                "label_fr": "Toekoel"
            },
            {
                "value": 69503,
                "label": "Magyaregres",
                "label_ar": "Magyaregres",
                "label_fr": "Magyaregres"
            },
            {
                "value": 69955,
                "label": "Balaton",
                "label_ar": "بالاتون",
                "label_fr": "Balaton"
            },
            {
                "value": 69856,
                "label": "Dedestapolcsany",
                "label_ar": "ديديستابولكساني",
                "label_fr": "Dedestapolcsany"
            },
            {
                "value": 69729,
                "label": "Tarcal",
                "label_ar": "تاركال",
                "label_fr": "Tarcal"
            },
            {
                "value": 69443,
                "label": "Taktaszada",
                "label_ar": "Taktaszada",
                "label_fr": "Taktaszada"
            },
            {
                "value": 69475,
                "label": "Kalocsa",
                "label_ar": "كالوكسا",
                "label_fr": "Kalocsa"
            },
            {
                "value": 69456,
                "label": "Nagydobsza",
                "label_ar": "ناجيدوبسزا",
                "label_fr": "Nagydobsza"
            },
            {
                "value": 69749,
                "label": "Monorierdo",
                "label_ar": "مونورييردو",
                "label_fr": "Monorierdo"
            },
            {
                "value": 69469,
                "label": "Budaors",
                "label_ar": "بوداورس",
                "label_fr": "Budaors"
            },
            {
                "value": 69693,
                "label": "Szarvas",
                "label_ar": "سارفاس",
                "label_fr": "Szarvas"
            },
            {
                "value": 69871,
                "label": "Golle",
                "label_ar": "غول",
                "label_fr": "Golle"
            },
            {
                "value": 69670,
                "label": "Barcs",
                "label_ar": "باركس",
                "label_fr": "Barcs"
            },
            {
                "value": 69288,
                "label": "Nogradsipek",
                "label_ar": "نوجرادسيبك",
                "label_fr": "Nogradsipek"
            },
            {
                "value": 69866,
                "label": "Tiszavalk",
                "label_ar": "تيزافالك",
                "label_fr": "Tiszavalk"
            },
            {
                "value": 69373,
                "label": "Tatabánya",
                "label_ar": "تاتابانيا",
                "label_fr": "Tatabánya"
            },
            {
                "value": 69953,
                "label": "Kolontar",
                "label_ar": "كولونتار",
                "label_fr": "Kolontar"
            },
            {
                "value": 69408,
                "label": "Bukkaranyos",
                "label_ar": "بوكارانيوس",
                "label_fr": "Bukkaranyos"
            },
            {
                "value": 69465,
                "label": "Hajduboszormeny",
                "label_ar": "هايدوبوسورميني",
                "label_fr": "Hajduboszormeny"
            },
            {
                "value": 69621,
                "label": "Hajduhadhaz",
                "label_ar": "هاجدحاز",
                "label_fr": "Hajduhadhaz"
            },
            {
                "value": 69634,
                "label": "Fegyvernek",
                "label_ar": "فيجيفيرنيك",
                "label_fr": "Fegyvernek"
            },
            {
                "value": 69678,
                "label": "Felsopakony",
                "label_ar": "فيلسوباكوني",
                "label_fr": "Felsopakony"
            },
            {
                "value": 69664,
                "label": "Polgar",
                "label_ar": "بولجار",
                "label_fr": "Polgar"
            },
            {
                "value": 69382,
                "label": "Csepa",
                "label_ar": "سيبا",
                "label_fr": "Csepa"
            },
            {
                "value": 69610,
                "label": "Petohaza",
                "label_ar": "بتوحزة",
                "label_fr": "Petohaza"
            },
            {
                "value": 69981,
                "label": "Gyorzamoly",
                "label_ar": "جيورزامولي",
                "label_fr": "Gyorzamoly"
            },
            {
                "value": 69467,
                "label": "Nyirbator",
                "label_ar": "نيرباتور",
                "label_fr": "Nyirbator"
            },
            {
                "value": 69368,
                "label": "Lajoskomarom",
                "label_ar": "لاجوسكوماروم",
                "label_fr": "Lajoskomarom"
            },
            {
                "value": 69704,
                "label": "Tabajd",
                "label_ar": "طبجد",
                "label_fr": "Tabajd"
            },
            {
                "value": 69912,
                "label": "Kotelek",
                "label_ar": "Kotelek",
                "label_fr": "Kotelek"
            },
            {
                "value": 69601,
                "label": "Nezsa",
                "label_ar": "نزسة",
                "label_fr": "Nezsa"
            },
            {
                "value": 69809,
                "label": "Csanytelek",
                "label_ar": "Csanytelek",
                "label_fr": "Csanytelek"
            },
            {
                "value": 69350,
                "label": "Nemetker",
                "label_ar": "نيميتكر",
                "label_fr": "Nemetker"
            },
            {
                "value": 69345,
                "label": "Paks",
                "label_ar": "باكس",
                "label_fr": "Paks"
            },
            {
                "value": 69291,
                "label": "Zalaegerszeg",
                "label_ar": "Zalaegerszeg",
                "label_fr": "Zalaegerszeg"
            },
            {
                "value": 69453,
                "label": "Szodliget",
                "label_ar": "Szodliget",
                "label_fr": "Szodliget"
            },
            {
                "value": 69473,
                "label": "Tab",
                "label_ar": "التبويب",
                "label_fr": "Languette"
            },
            {
                "value": 69254,
                "label": "Ujszilvas",
                "label_ar": "Ujszilvas",
                "label_fr": "Ujszilvas"
            },
            {
                "value": 69261,
                "label": "Apatfalva",
                "label_ar": "أباتفالفا",
                "label_fr": "Apatfalva"
            },
            {
                "value": 69540,
                "label": "Verseg",
                "label_ar": "فيرسيج",
                "label_fr": "Verseg"
            },
            {
                "value": 69799,
                "label": "Morahalom",
                "label_ar": "مرحلوم",
                "label_fr": "Morahalom"
            },
            {
                "value": 69493,
                "label": "Toeroekbalint",
                "label_ar": "Toeroekbalint",
                "label_fr": "Toeroekbalint"
            },
            {
                "value": 69825,
                "label": "Mezobereny",
                "label_ar": "Mezobereny",
                "label_fr": "Mezobereny"
            },
            {
                "value": 69322,
                "label": "Ajka",
                "label_ar": "اكا",
                "label_fr": "Ajka"
            },
            {
                "value": 69470,
                "label": "Teglas",
                "label_ar": "تجلاس",
                "label_fr": "Teglas"
            },
            {
                "value": 69393,
                "label": "Boncodfolde",
                "label_ar": "بونكودفولد",
                "label_fr": "Boncodfolde"
            },
            {
                "value": 69241,
                "label": "Balatonfured",
                "label_ar": "بالاتونفوريد",
                "label_fr": "Balatonfured"
            },
            {
                "value": 69817,
                "label": "Dunavecse",
                "label_ar": "دونافيس",
                "label_fr": "Dunavecse"
            },
            {
                "value": 69639,
                "label": "Zebegeny",
                "label_ar": "زيبجيني",
                "label_fr": "Zebegeny"
            },
            {
                "value": 69742,
                "label": "Hercegkut",
                "label_ar": "هرسكوت",
                "label_fr": "Hercegkut"
            },
            {
                "value": 69883,
                "label": "Dunaszeg",
                "label_ar": "دونازيج",
                "label_fr": "Dunaszeg"
            },
            {
                "value": 69292,
                "label": "Szentkatalin",
                "label_ar": "زينتكاتالين",
                "label_fr": "Szentkatalin"
            },
            {
                "value": 69587,
                "label": "Oroszlany",
                "label_ar": "أوروسزلاني",
                "label_fr": "Oroszlany"
            },
            {
                "value": 69746,
                "label": "Inarcs",
                "label_ar": "إيناركس",
                "label_fr": "Inarcs"
            },
            {
                "value": 69893,
                "label": "Ujkenez",
                "label_ar": "أوجكينيز",
                "label_fr": "Ujkenez"
            },
            {
                "value": 69591,
                "label": "Kornye",
                "label_ar": "كورني",
                "label_fr": "Kornye"
            },
            {
                "value": 69711,
                "label": "Halasztelek",
                "label_ar": "Halasztelek",
                "label_fr": "Halasztelek"
            },
            {
                "value": 69328,
                "label": "Komlo",
                "label_ar": "كوملو",
                "label_fr": "Komlo"
            },
            {
                "value": 69682,
                "label": "Szendehely",
                "label_ar": "Szendehely",
                "label_fr": "Szendehely"
            },
            {
                "value": 69562,
                "label": "Repcelak",
                "label_ar": "ريبشيلاك",
                "label_fr": "Repcelak"
            },
            {
                "value": 69549,
                "label": "Varosfoeld",
                "label_ar": "فاروسفولد",
                "label_fr": "Varosfoeld"
            },
            {
                "value": 69674,
                "label": "Kiskoros",
                "label_ar": "كيسكوروس",
                "label_fr": "Kiskoros"
            },
            {
                "value": 69270,
                "label": "Miskolc",
                "label_ar": "ميسكولك",
                "label_fr": "Miskolc"
            },
            {
                "value": 69841,
                "label": "Ciko",
                "label_ar": "سيكو",
                "label_fr": "Ciko"
            },
            {
                "value": 69593,
                "label": "Selyeb",
                "label_ar": "صليب",
                "label_fr": "Selyeb"
            },
            {
                "value": 69264,
                "label": "Posfa",
                "label_ar": "Posfa",
                "label_fr": "Posfa"
            },
            {
                "value": 69250,
                "label": "Sarszentmihaly",
                "label_ar": "سارسينتميهالي",
                "label_fr": "Sarszentmihaly"
            },
            {
                "value": 69793,
                "label": "Nagyvazsony",
                "label_ar": "ناجيفازوني",
                "label_fr": "Nagyvazsony"
            },
            {
                "value": 69785,
                "label": "Mezofalva",
                "label_ar": "ميزوفالفا",
                "label_fr": "Mezofalva"
            },
            {
                "value": 69892,
                "label": "Gemzse",
                "label_ar": "Gemzse",
                "label_fr": "Gemzse"
            },
            {
                "value": 69359,
                "label": "Balatonszabadi",
                "label_ar": "Balatonszabadi",
                "label_fr": "Balatonszabadi"
            },
            {
                "value": 69265,
                "label": "Tiszaujvaros",
                "label_ar": "Tiszaujvaros",
                "label_fr": "Tiszaujvaros"
            },
            {
                "value": 69371,
                "label": "Csogle",
                "label_ar": "Csogle",
                "label_fr": "Csogle"
            },
            {
                "value": 69699,
                "label": "Pazmand",
                "label_ar": "بازماند",
                "label_fr": "Pazmand"
            },
            {
                "value": 69681,
                "label": "Pecel",
                "label_ar": "بيسيل",
                "label_fr": "Pecel"
            },
            {
                "value": 69299,
                "label": "Szazhalombatta",
                "label_ar": "Szazhalombatta",
                "label_fr": "Szazhalombatta"
            },
            {
                "value": 69831,
                "label": "Bogyiszlo",
                "label_ar": "بوجيسزلو",
                "label_fr": "Bogyiszlo"
            },
            {
                "value": 69375,
                "label": "Tompa",
                "label_ar": "تومبا",
                "label_fr": "Tompa"
            },
            {
                "value": 69402,
                "label": "Nemesbod",
                "label_ar": "نيمسبود",
                "label_fr": "Nemesbod"
            },
            {
                "value": 69319,
                "label": "Szemely",
                "label_ar": "Szemely",
                "label_fr": "Szemely"
            },
            {
                "value": 69246,
                "label": "Érd",
                "label_ar": "إرد",
                "label_fr": "Érd"
            },
            {
                "value": 69471,
                "label": "Vamosszabadi",
                "label_ar": "فاموسسابادي",
                "label_fr": "Vamosszabadi"
            },
            {
                "value": 69464,
                "label": "Biharkeresztes",
                "label_ar": "Biharkeresztes",
                "label_fr": "Biharkeresztes"
            },
            {
                "value": 69951,
                "label": "Center",
                "label_ar": "مركز",
                "label_fr": "Centre"
            },
            {
                "value": 69895,
                "label": "Dudar",
                "label_ar": "دودار",
                "label_fr": "Dudar"
            },
            {
                "value": 69314,
                "label": "Veresegyhaz",
                "label_ar": "فيريزيجهاز",
                "label_fr": "Veresegyhaz"
            },
            {
                "value": 69872,
                "label": "Vasarosdombo",
                "label_ar": "فاساروسدومبو",
                "label_fr": "Vasarosdombo"
            },
            {
                "value": 69967,
                "label": "Perenye",
                "label_ar": "Perenye",
                "label_fr": "Perenye"
            },
            {
                "value": 69519,
                "label": "Janossomorja",
                "label_ar": "يانوسومورجا",
                "label_fr": "Janossomorja"
            },
            {
                "value": 69271,
                "label": "Kunszentmarton",
                "label_ar": "كونسينتمارتون",
                "label_fr": "Kunszentmarton"
            },
            {
                "value": 69752,
                "label": "Tapioszecso",
                "label_ar": "Tapioszecso",
                "label_fr": "Tapioszecso"
            },
            {
                "value": 69635,
                "label": "Kisdorog",
                "label_ar": "كيسدوروغ",
                "label_fr": "Kisdorog"
            },
            {
                "value": 69901,
                "label": "Balatonfokajar",
                "label_ar": "بالاتونفوكاجار",
                "label_fr": "Balatonfokajar"
            },
            {
                "value": 69910,
                "label": "Belapatfalva",
                "label_ar": "بيلاباتفالفا",
                "label_fr": "Belapatfalva"
            },
            {
                "value": 69538,
                "label": "Danszentmiklos",
                "label_ar": "Danszentmiklos",
                "label_fr": "Danszentmiklos"
            },
            {
                "value": 69748,
                "label": "Szentmartonkata",
                "label_ar": "Szentmartonkata",
                "label_fr": "Szentmartonkata"
            },
            {
                "value": 69913,
                "label": "Cserepfalu",
                "label_ar": "سيريبفالو",
                "label_fr": "Cserepfalu"
            },
            {
                "value": 69690,
                "label": "Csoeroeg",
                "label_ar": "كسوروج",
                "label_fr": "Csoeroeg"
            },
            {
                "value": 69808,
                "label": "Sarosd",
                "label_ar": "ساروسد",
                "label_fr": "Sarosd"
            },
            {
                "value": 69755,
                "label": "Koszarhegy",
                "label_ar": "Koszarhegy",
                "label_fr": "Koszarhegy"
            },
            {
                "value": 69724,
                "label": "Szuhakallo",
                "label_ar": "Szuhakallo",
                "label_fr": "Szuhakallo"
            },
            {
                "value": 69927,
                "label": "Petofibanya",
                "label_ar": "بيتوفيبانيا",
                "label_fr": "Petofibanya"
            },
            {
                "value": 69623,
                "label": "Maglod",
                "label_ar": "ماجلود",
                "label_fr": "Maglod"
            },
            {
                "value": 69658,
                "label": "Balassagyarmat",
                "label_ar": "بالاساجيارمات",
                "label_fr": "Balassagyarmat"
            },
            {
                "value": 69759,
                "label": "Vajszlo",
                "label_ar": "فاجسلو",
                "label_fr": "Vajszlo"
            },
            {
                "value": 69695,
                "label": "Bekesszentandras",
                "label_ar": "Bekesszentandras",
                "label_fr": "Bekesszentandras"
            },
            {
                "value": 69661,
                "label": "Tapiogyorgye",
                "label_ar": "تابيوجيورجي",
                "label_fr": "Tapiogyorgye"
            },
            {
                "value": 69530,
                "label": "Hajdunanas",
                "label_ar": "هاجدوناناس",
                "label_fr": "Hajdunanas"
            },
            {
                "value": 69389,
                "label": "Sandorfalva",
                "label_ar": "ساندورفالفا",
                "label_fr": "Sandorfalva"
            },
            {
                "value": 69929,
                "label": "Orbottyan",
                "label_ar": "أوربوتيان",
                "label_fr": "Orbottyan"
            },
            {
                "value": 69459,
                "label": "Kony",
                "label_ar": "كوني",
                "label_fr": "Kony"
            },
            {
                "value": 69313,
                "label": "Kismaros",
                "label_ar": "كيسماروس",
                "label_fr": "Kismaros"
            },
            {
                "value": 69722,
                "label": "Emod",
                "label_ar": "إيمود",
                "label_fr": "Emod"
            },
            {
                "value": 69608,
                "label": "Mariapocs",
                "label_ar": "ماريابوكس",
                "label_fr": "Mariapocs"
            },
            {
                "value": 69606,
                "label": "Pilis",
                "label_ar": "بيليس",
                "label_fr": "Pilis"
            },
            {
                "value": 69535,
                "label": "Marcali",
                "label_ar": "ماركالي",
                "label_fr": "Marcali"
            },
            {
                "value": 69945,
                "label": "Tiszapalkonya",
                "label_ar": "Tiszapalkonya",
                "label_fr": "Tiszapalkonya"
            },
            {
                "value": 69839,
                "label": "Decs",
                "label_ar": "ديسمبر",
                "label_fr": "Déc"
            },
            {
                "value": 69592,
                "label": "Malyi",
                "label_ar": "مالي",
                "label_fr": "Malyi"
            },
            {
                "value": 69702,
                "label": "Nemesvid",
                "label_ar": "Nemesvid",
                "label_fr": "Nemesvid"
            },
            {
                "value": 69479,
                "label": "Nyul",
                "label_ar": "نيول",
                "label_fr": "Nyul"
            },
            {
                "value": 69835,
                "label": "Vardomb",
                "label_ar": "فاردومب",
                "label_fr": "Vardomb"
            },
            {
                "value": 69648,
                "label": "Ecser",
                "label_ar": "ايسر",
                "label_fr": "Ecser"
            },
            {
                "value": 69500,
                "label": "Szigetszentmiklos",
                "label_ar": "Szigetszentmiklos",
                "label_fr": "Szigetszentmiklos"
            },
            {
                "value": 69560,
                "label": "Papa",
                "label_ar": "بابا",
                "label_fr": "Papa"
            },
            {
                "value": 69772,
                "label": "Hajmasker",
                "label_ar": "الحاج",
                "label_fr": "Hajmasker"
            },
            {
                "value": 69660,
                "label": "Heves",
                "label_ar": "هيفيز",
                "label_fr": "Heves"
            },
            {
                "value": 69584,
                "label": "Tarjan",
                "label_ar": "ترجان",
                "label_fr": "Tarjan"
            },
            {
                "value": 69447,
                "label": "Hatvan",
                "label_ar": "هاتفان",
                "label_fr": "Hatvan"
            },
            {
                "value": 69865,
                "label": "Baracs",
                "label_ar": "باراكس",
                "label_fr": "Baracs"
            },
            {
                "value": 69433,
                "label": "Asotthalom",
                "label_ar": "أسوتالوم",
                "label_fr": "Asotthalom"
            },
            {
                "value": 69394,
                "label": "Szigliget",
                "label_ar": "سيجليجيت",
                "label_fr": "Szigliget"
            },
            {
                "value": 69616,
                "label": "Berkesd",
                "label_ar": "بيركسد",
                "label_fr": "Berkesd"
            },
            {
                "value": 69578,
                "label": "Paka",
                "label_ar": "باكا",
                "label_fr": "Paka"
            },
            {
                "value": 69691,
                "label": "Veroce",
                "label_ar": "فيروسي",
                "label_fr": "Veroce"
            },
            {
                "value": 69542,
                "label": "Halaszi",
                "label_ar": "هلسي",
                "label_fr": "Halaszi"
            },
            {
                "value": 69669,
                "label": "Szentlorinc",
                "label_ar": "سينتلورينك",
                "label_fr": "Szentlorinc"
            },
            {
                "value": 69891,
                "label": "Patroha",
                "label_ar": "باتروها",
                "label_fr": "Patroha"
            },
            {
                "value": 69478,
                "label": "Deg",
                "label_ar": "درجة",
                "label_fr": "Degré"
            },
            {
                "value": 69386,
                "label": "Somogyudvarhely",
                "label_ar": "Somogyudvarhely",
                "label_fr": "Somogyudvarhely"
            },
            {
                "value": 69790,
                "label": "Babolna",
                "label_ar": "بابولنا",
                "label_fr": "Babolna"
            },
            {
                "value": 69740,
                "label": "Bazsi",
                "label_ar": "بازي",
                "label_fr": "Bazsi"
            },
            {
                "value": 69505,
                "label": "Nagyberki",
                "label_ar": "ناجيبيركي",
                "label_fr": "Nagyberki"
            },
            {
                "value": 69377,
                "label": "Veszprém",
                "label_ar": "فيزبرم",
                "label_fr": "Veszprém"
            },
            {
                "value": 69284,
                "label": "Szajol",
                "label_ar": "سجاجول",
                "label_fr": "Szajol"
            },
            {
                "value": 69295,
                "label": "Erdokertes",
                "label_ar": "إردوكرتس",
                "label_fr": "Erdokertes"
            },
            {
                "value": 69984,
                "label": "Vezseny",
                "label_ar": "فيزسيني",
                "label_fr": "Vezseny"
            },
            {
                "value": 69656,
                "label": "Mezokovesd",
                "label_ar": "ميزوكوفيسد",
                "label_fr": "Mezokovesd"
            },
            {
                "value": 69463,
                "label": "Visznek",
                "label_ar": "فيزنيك",
                "label_fr": "Visznek"
            },
            {
                "value": 69255,
                "label": "Kecskemét",
                "label_ar": "كيسكميت",
                "label_fr": "Kecskemét"
            },
            {
                "value": 69812,
                "label": "Szar",
                "label_ar": "سزار",
                "label_fr": "Szar"
            },
            {
                "value": 69351,
                "label": "Kurd",
                "label_ar": "كردي",
                "label_fr": "Kurde"
            },
            {
                "value": 69941,
                "label": "Perbal",
                "label_ar": "بيربال",
                "label_fr": "Perbal"
            },
            {
                "value": 69579,
                "label": "Gyar",
                "label_ar": "جيار",
                "label_fr": "Gyar"
            },
            {
                "value": 69830,
                "label": "Ocseny",
                "label_ar": "Ocseny",
                "label_fr": "Ocseny"
            },
            {
                "value": 69990,
                "label": "Gasztony",
                "label_ar": "جازتوني",
                "label_fr": "Gasztony"
            },
            {
                "value": 69718,
                "label": "Nyekladhaza",
                "label_ar": "نيكلاهازا",
                "label_fr": "Nyekladhaza"
            },
            {
                "value": 69908,
                "label": "Ocsod",
                "label_ar": "Ocsod",
                "label_fr": "Ocsod"
            },
            {
                "value": 69349,
                "label": "Tevel",
                "label_ar": "تيفيل",
                "label_fr": "Tevel"
            },
            {
                "value": 69404,
                "label": "Sarvar",
                "label_ar": "سارفار",
                "label_fr": "Sarvar"
            },
            {
                "value": 69979,
                "label": "Uraiujfalu",
                "label_ar": "Uraiujfalu",
                "label_fr": "Uraiujfalu"
            },
            {
                "value": 69399,
                "label": "Nagycserkesz",
                "label_ar": "Nagycserkesz",
                "label_fr": "Nagycserkesz"
            },
            {
                "value": 69457,
                "label": "Rabapatona",
                "label_ar": "راباباتونا",
                "label_fr": "Rabapatona"
            },
            {
                "value": 69388,
                "label": "Nagyatad",
                "label_ar": "نجياتاد",
                "label_fr": "Nagyatad"
            },
            {
                "value": 69645,
                "label": "Ujlengyel",
                "label_ar": "أوجلنجيل",
                "label_fr": "Ujlengyel"
            }
        ]
    },
    {
        "country": "Georgia",
        "cities": [
            {
                "value": 69998,
                "label": "Qazbegi",
                "label_ar": "قزبيجي",
                "label_fr": "Qazbegi"
            },
            {
                "value": 69995,
                "label": "Shek'vetili",
                "label_ar": "شكفيتيلي",
                "label_fr": "Shek'vetili"
            },
            {
                "value": 70001,
                "label": "Samtredia",
                "label_ar": "Samtredia",
                "label_fr": "Samtredia"
            },
            {
                "value": 69994,
                "label": "Ts'khinvali",
                "label_ar": "تسخينفالي",
                "label_fr": "Ts'khinvali"
            },
            {
                "value": 70002,
                "label": "Zugdidi",
                "label_ar": "زوغديدي",
                "label_fr": "Zougdidi"
            }
        ]
    },
    {
        "country": "Brazil",
        "cities": [
            {
                "value": 70110,
                "label": "Nova Palma",
                "label_ar": "نوفا بالما",
                "label_fr": "Nova Palma"
            },
            {
                "value": 71555,
                "label": "Nunes Freire",
                "label_ar": "نونيس فريري",
                "label_fr": "Nunes Freire"
            },
            {
                "value": 71158,
                "label": "Bebedouro",
                "label_ar": "بيبيدورو",
                "label_fr": "Bebedouro"
            },
            {
                "value": 71728,
                "label": "Aldeia",
                "label_ar": "الديا",
                "label_fr": "Aldeia"
            },
            {
                "value": 71575,
                "label": "Correntina",
                "label_ar": "كورينتينا",
                "label_fr": "Correntina"
            },
            {
                "value": 71811,
                "label": "Manhuacu",
                "label_ar": "مانهواكو",
                "label_fr": "Manhuacu"
            },
            {
                "value": 70091,
                "label": "Canela",
                "label_ar": "كانيلا",
                "label_fr": "Canela"
            },
            {
                "value": 71261,
                "label": "Joao Lisboa",
                "label_ar": "جواو لشبونة",
                "label_fr": "Joao Lisboa"
            },
            {
                "value": 70932,
                "label": "Jaguaribe",
                "label_ar": "جاكواربي",
                "label_fr": "Jaguaribe"
            },
            {
                "value": 70177,
                "label": "Caico",
                "label_ar": "كايكو",
                "label_fr": "Caico"
            },
            {
                "value": 70043,
                "label": "Palmas",
                "label_ar": "بالماس",
                "label_fr": "Palmas"
            },
            {
                "value": 71199,
                "label": "Vitoria de Santo Antao",
                "label_ar": "فيتوريا دي سانتو أنتاو",
                "label_fr": "Vitoria de Santo Antao"
            },
            {
                "value": 71458,
                "label": "Pedra Grande",
                "label_ar": "بيدرا غراندي",
                "label_fr": "Pedra Grande"
            },
            {
                "value": 71583,
                "label": "Esperanca",
                "label_ar": "اسبيرانجا",
                "label_fr": "Esperanca"
            },
            {
                "value": 71582,
                "label": "Capao Alto",
                "label_ar": "كاباو ألتو",
                "label_fr": "Capao Alto"
            },
            {
                "value": 70797,
                "label": "Restinga Seca",
                "label_ar": "ريستينجا سيكا",
                "label_fr": "Restinga Seca"
            },
            {
                "value": 71768,
                "label": "Engenheiro Beltrao",
                "label_ar": "إنجينهيرو بيلتراو",
                "label_fr": "Engenheiro Beltrao"
            },
            {
                "value": 71540,
                "label": "Timon",
                "label_ar": "تيمون",
                "label_fr": "Timon"
            },
            {
                "value": 70756,
                "label": "Presidente Kennedy",
                "label_ar": "الرئيس كينيدي",
                "label_fr": "Presidente Kennedy"
            },
            {
                "value": 71851,
                "label": "Feira Nova",
                "label_ar": "فييرا نوفا",
                "label_fr": "Feira Nova"
            },
            {
                "value": 70521,
                "label": "Almirante Tamandare",
                "label_ar": "ألميرانتي تامانداري",
                "label_fr": "Almirante Tamandare"
            },
            {
                "value": 71722,
                "label": "Rio Manso",
                "label_ar": "ريو مانسو",
                "label_fr": "Rio Manso"
            },
            {
                "value": 70027,
                "label": "Terenos",
                "label_ar": "تيرينوس",
                "label_fr": "Terenos"
            },
            {
                "value": 71518,
                "label": "Ubata",
                "label_ar": "أوباتا",
                "label_fr": "Ubata"
            },
            {
                "value": 70890,
                "label": "Irati",
                "label_ar": "إيراتي",
                "label_fr": "Irati"
            },
            {
                "value": 71701,
                "label": "Serra Azul",
                "label_ar": "سيرا ازول",
                "label_fr": "Serra Azul"
            },
            {
                "value": 70119,
                "label": "Canoas",
                "label_ar": "زوارق",
                "label_fr": "Canoës"
            },
            {
                "value": 71191,
                "label": "Timoteo",
                "label_ar": "تيموتيو",
                "label_fr": "Timoteo"
            },
            {
                "value": 71174,
                "label": "Varzea",
                "label_ar": "فارزيا",
                "label_fr": "Varzea"
            },
            {
                "value": 71667,
                "label": "Schroeder",
                "label_ar": "شرودر",
                "label_fr": "Schroeder"
            },
            {
                "value": 71043,
                "label": "Joanopolis",
                "label_ar": "جوانوبوليس",
                "label_fr": "Joanopolis"
            },
            {
                "value": 70187,
                "label": "Catanduva",
                "label_ar": "كاتاندوفا",
                "label_fr": "Catanduva"
            },
            {
                "value": 71875,
                "label": "Marcelino Vieira",
                "label_ar": "مارسيلينو فييرا",
                "label_fr": "Marcelino Vieira"
            },
            {
                "value": 71978,
                "label": "Ipaucu",
                "label_ar": "إباوكو",
                "label_fr": "Ipaucu"
            },
            {
                "value": 70420,
                "label": "Sacramento",
                "label_ar": "سكرامنتو",
                "label_fr": "Sacramento"
            },
            {
                "value": 70251,
                "label": "Palmeira",
                "label_ar": "بالميرا",
                "label_fr": "Palmeira"
            },
            {
                "value": 70295,
                "label": "Jaiba",
                "label_ar": "جايبه",
                "label_fr": "Jaiba"
            },
            {
                "value": 71120,
                "label": "Ipu",
                "label_ar": "أنا بو",
                "label_fr": "Ipu"
            },
            {
                "value": 70126,
                "label": "Luziania",
                "label_ar": "لوزيانيا",
                "label_fr": "Luziania"
            },
            {
                "value": 70628,
                "label": "Simplicio Mendes",
                "label_ar": "سيمبليسيو مينديز",
                "label_fr": "Simplicio Mendes"
            },
            {
                "value": 71498,
                "label": "Palmeira",
                "label_ar": "بالميرا",
                "label_fr": "Palmeira"
            },
            {
                "value": 71352,
                "label": "Ubarana",
                "label_ar": "أوبارانا",
                "label_fr": "Ubarana"
            },
            {
                "value": 71326,
                "label": "Sao Manuel",
                "label_ar": "ساو مانويل",
                "label_fr": "Sao Manuel"
            },
            {
                "value": 72049,
                "label": "Sao Felix",
                "label_ar": "ساو فيليكس",
                "label_fr": "Sao Felix"
            },
            {
                "value": 71994,
                "label": "Pontes e Lacerda",
                "label_ar": "بونتيس ولاسيردا",
                "label_fr": "Pontes e Lacerda"
            },
            {
                "value": 70709,
                "label": "Navirai",
                "label_ar": "نافيراي",
                "label_fr": "Navirai"
            },
            {
                "value": 71457,
                "label": "Porto",
                "label_ar": "بورتو",
                "label_fr": "Porto"
            },
            {
                "value": 71795,
                "label": "Sangao",
                "label_ar": "سانجاو",
                "label_fr": "Sangao"
            },
            {
                "value": 72036,
                "label": "Taquarana",
                "label_ar": "تاكوارانا",
                "label_fr": "Taquarana"
            },
            {
                "value": 71639,
                "label": "Sao Felipe",
                "label_ar": "ساو فيليبي",
                "label_fr": "Sao Felipe"
            },
            {
                "value": 70884,
                "label": "Cerro Branco",
                "label_ar": "سيرو برانكو",
                "label_fr": "Cerro Branco"
            },
            {
                "value": 71967,
                "label": "Sao Bento do Sapucai",
                "label_ar": "ساو بينتو دو سابوكاي",
                "label_fr": "Sao Bento do Sapucai"
            },
            {
                "value": 70421,
                "label": "São Carlos",
                "label_ar": "ساو كارلوس",
                "label_fr": "São Carlos"
            },
            {
                "value": 70995,
                "label": "Almeirim",
                "label_ar": "الميرم",
                "label_fr": "Almeirim"
            },
            {
                "value": 71079,
                "label": "Boa Vista",
                "label_ar": "بوا فيستا",
                "label_fr": "Boa Vista"
            },
            {
                "value": 71081,
                "label": "Sarzedo",
                "label_ar": "سارزيدو",
                "label_fr": "Sarzedo"
            },
            {
                "value": 71477,
                "label": "Mineiros",
                "label_ar": "مينيروس",
                "label_fr": "Mineiros"
            },
            {
                "value": 71910,
                "label": "Canavieiras",
                "label_ar": "كانافيراس",
                "label_fr": "Canavieiras"
            },
            {
                "value": 71987,
                "label": "Entre Rios",
                "label_ar": "انتري ريوس",
                "label_fr": "Entre Rios"
            },
            {
                "value": 70757,
                "label": "Brejetuba",
                "label_ar": "بريجيتوبا",
                "label_fr": "Brejetuba"
            },
            {
                "value": 71405,
                "label": "Lagoa da Canoa",
                "label_ar": "لاغوا دا كانوا",
                "label_fr": "Lagoa da Canoa"
            },
            {
                "value": 71312,
                "label": "Bom Jesus do Amparo",
                "label_ar": "بوم جيسوس يفعل أمبارو",
                "label_fr": "Bom Jesus do Amparo"
            },
            {
                "value": 71345,
                "label": "Nova Alianca",
                "label_ar": "نوفا أليانكا",
                "label_fr": "Nova Alianca"
            },
            {
                "value": 71418,
                "label": "Arroio do Tigre",
                "label_ar": "Arroio do Tigre",
                "label_fr": "Arroio do Tigre"
            },
            {
                "value": 71416,
                "label": "Boa Vista",
                "label_ar": "بوا فيستا",
                "label_fr": "Boa Vista"
            },
            {
                "value": 71488,
                "label": "Porangatu",
                "label_ar": "بورانجاتو",
                "label_fr": "Porangatu"
            },
            {
                "value": 71692,
                "label": "Resende Costa",
                "label_ar": "ريسيندي كوستا",
                "label_fr": "Resende Costa"
            },
            {
                "value": 70101,
                "label": "Cambe",
                "label_ar": "كامبي",
                "label_fr": "Cambe"
            },
            {
                "value": 71511,
                "label": "Canoinhas",
                "label_ar": "كانوينهاس",
                "label_fr": "Canoinhas"
            },
            {
                "value": 71126,
                "label": "Rio Branco",
                "label_ar": "ريو برانكو",
                "label_fr": "Rio Branco"
            },
            {
                "value": 71391,
                "label": "Guaxupe",
                "label_ar": "Guaxupe",
                "label_fr": "Guaxupe"
            },
            {
                "value": 70632,
                "label": "Aguas Lindas",
                "label_ar": "اجواس لينداس",
                "label_fr": "Aguas Lindas"
            },
            {
                "value": 72066,
                "label": "Clevelandia",
                "label_ar": "كليفلانديا",
                "label_fr": "Clevelandia"
            },
            {
                "value": 70530,
                "label": "Conde",
                "label_ar": "كوندي",
                "label_fr": "Condé"
            },
            {
                "value": 70681,
                "label": "Tobias Barreto",
                "label_ar": "توبياس باريتو",
                "label_fr": "Tobias Barreto"
            },
            {
                "value": 71839,
                "label": "Presidente Epitacio",
                "label_ar": "بريزيدنتي إبيتاسيو",
                "label_fr": "Presidente Epitacio"
            },
            {
                "value": 71178,
                "label": "Sao Jose do Goiabal",
                "label_ar": "ساو جوزيه دو جويابال",
                "label_fr": "Sao Jose do Goiabal"
            },
            {
                "value": 71209,
                "label": "Guaraci",
                "label_ar": "Guaraci",
                "label_fr": "Guaraci"
            },
            {
                "value": 71599,
                "label": "Urania",
                "label_ar": "يورانيا",
                "label_fr": "Uranie"
            },
            {
                "value": 71132,
                "label": "Bela Vista do Paraiso",
                "label_ar": "بيلا فيستا دو بارايسو",
                "label_fr": "Bela Vista do Paraiso"
            },
            {
                "value": 71576,
                "label": "Santa Maria da Vitoria",
                "label_ar": "سانتا ماريا دا فيتوريا",
                "label_fr": "Santa Maria da Vitoria"
            },
            {
                "value": 71268,
                "label": "Carapebus",
                "label_ar": "Carapebus",
                "label_fr": "Carapebus"
            },
            {
                "value": 70466,
                "label": "Pau dos Ferros",
                "label_ar": "باو دوس فيروس",
                "label_fr": "Pau dos Ferros"
            },
            {
                "value": 71133,
                "label": "Montividiu",
                "label_ar": "مونتيفيديو",
                "label_fr": "Montividiu"
            },
            {
                "value": 71486,
                "label": "Nucleo Bandeirante",
                "label_ar": "نوكليو بانديرانت",
                "label_fr": "Nucleo Bandeirante"
            },
            {
                "value": 71310,
                "label": "Aquiraz",
                "label_ar": "أكويراز",
                "label_fr": "Aquiraz"
            },
            {
                "value": 70776,
                "label": "Turmalina",
                "label_ar": "تورمالينا",
                "label_fr": "Turmalina"
            },
            {
                "value": 70088,
                "label": "Dom Feliciano",
                "label_ar": "دوم فيليسيانو",
                "label_fr": "Dom Feliciano"
            },
            {
                "value": 71449,
                "label": "Santana do Cariri",
                "label_ar": "سانتانا دو كاريري",
                "label_fr": "Santana do Cariri"
            },
            {
                "value": 70843,
                "label": "Timbauba",
                "label_ar": "تمباوبا",
                "label_fr": "Timbauba"
            },
            {
                "value": 70670,
                "label": "Boituva",
                "label_ar": "بويتوفا",
                "label_fr": "Boituva"
            },
            {
                "value": 71662,
                "label": "Andre da Rocha",
                "label_ar": "أندريه دا روشا",
                "label_fr": "André da Rocha"
            },
            {
                "value": 71931,
                "label": "Cunha Pora",
                "label_ar": "كونها بورا",
                "label_fr": "Cunha Pora"
            },
            {
                "value": 70015,
                "label": "Itaquaquecetuba",
                "label_ar": "إتاكواكويسيتوبا",
                "label_fr": "Itaquaquecetuba"
            },
            {
                "value": 71147,
                "label": "Venda Nova",
                "label_ar": "فيندا نوفا",
                "label_fr": "Venda Nova"
            },
            {
                "value": 71656,
                "label": "Velha",
                "label_ar": "فيلها",
                "label_fr": "Velha"
            },
            {
                "value": 70279,
                "label": "Passos",
                "label_ar": "باسوس",
                "label_fr": "Passos"
            },
            {
                "value": 71170,
                "label": "Cacapava",
                "label_ar": "كاكابافا",
                "label_fr": "Cacapava"
            },
            {
                "value": 71452,
                "label": "Dix-sept Rosado",
                "label_ar": "ديكس سبت روسادو",
                "label_fr": "Dix-sept Rosado"
            },
            {
                "value": 71269,
                "label": "Casimiro de Abreu",
                "label_ar": "كاسيميرو دي أبرو",
                "label_fr": "Casimiro de Abreu"
            },
            {
                "value": 71960,
                "label": "Pitanga",
                "label_ar": "بيتانغا",
                "label_fr": "Pitanga"
            },
            {
                "value": 71320,
                "label": "Miracema",
                "label_ar": "ميراسيما",
                "label_fr": "Miracema"
            },
            {
                "value": 71917,
                "label": "Ibirarema",
                "label_ar": "إبيراريما",
                "label_fr": "Ibirarema"
            },
            {
                "value": 70148,
                "label": "Andradina",
                "label_ar": "أندرادينا",
                "label_fr": "Andradina"
            },
            {
                "value": 71256,
                "label": "Mineiros do Tiete",
                "label_ar": "مينيروس دو تيتي",
                "label_fr": "Mineiros do Tiete"
            },
            {
                "value": 70340,
                "label": "Ibirite",
                "label_ar": "إبيريتي",
                "label_fr": "Ibirite"
            },
            {
                "value": 71841,
                "label": "Faxinal do Soturno",
                "label_ar": "فاكسينال دو سوتورنو",
                "label_fr": "Faxinal do Soturno"
            },
            {
                "value": 71705,
                "label": "Itajobi",
                "label_ar": "إتاجوبي",
                "label_fr": "Itajobi"
            },
            {
                "value": 72082,
                "label": "Santo Amaro",
                "label_ar": "سانتو أمارو",
                "label_fr": "Santo Amaro"
            },
            {
                "value": 70552,
                "label": "Borda da Mata",
                "label_ar": "بوردا دا ماتا",
                "label_fr": "Borda da Mata"
            },
            {
                "value": 70018,
                "label": "São José dos Campos",
                "label_ar": "ساو خوسيه دوس كامبوس",
                "label_fr": "São José dos Campos"
            },
            {
                "value": 70270,
                "label": "Penha",
                "label_ar": "بنها",
                "label_fr": "Penha"
            },
            {
                "value": 70056,
                "label": "Sombrio",
                "label_ar": "سومبريو",
                "label_fr": "Sombrio"
            },
            {
                "value": 72084,
                "label": "Angatuba",
                "label_ar": "أنغاتوبا",
                "label_fr": "Angatuba"
            },
            {
                "value": 70903,
                "label": "Brodosqui",
                "label_ar": "برودوسكي",
                "label_fr": "Brodosqui"
            },
            {
                "value": 70688,
                "label": "Barra Mansa",
                "label_ar": "بارا مانسا",
                "label_fr": "Barra Mansa"
            },
            {
                "value": 71073,
                "label": "Rosario",
                "label_ar": "روزاريو",
                "label_fr": "Rosario"
            },
            {
                "value": 70868,
                "label": "Promissao",
                "label_ar": "بروميساو",
                "label_fr": "Promissao"
            },
            {
                "value": 70987,
                "label": "Sapiranga",
                "label_ar": "سابيرانجا",
                "label_fr": "Sapiranga"
            },
            {
                "value": 71323,
                "label": "Boa Vista",
                "label_ar": "بوا فيستا",
                "label_fr": "Boa Vista"
            },
            {
                "value": 71512,
                "label": "Tres Barras",
                "label_ar": "تريس باراس",
                "label_fr": "Tres Barras"
            },
            {
                "value": 70940,
                "label": "Catende",
                "label_ar": "كاتيندي",
                "label_fr": "Catende"
            },
            {
                "value": 70763,
                "label": "Alagoa Grande",
                "label_ar": "ألاغوا غراندي",
                "label_fr": "Alagoa Grande"
            },
            {
                "value": 71047,
                "label": "Boa Vista",
                "label_ar": "بوا فيستا",
                "label_fr": "Boa Vista"
            },
            {
                "value": 71095,
                "label": "Cristais",
                "label_ar": "كريستيس",
                "label_fr": "Cristais"
            },
            {
                "value": 71627,
                "label": "Grajau",
                "label_ar": "جراجاو",
                "label_fr": "Grajau"
            },
            {
                "value": 71670,
                "label": "Virginopolis",
                "label_ar": "فيرجينوبوليس",
                "label_fr": "Virginopolis"
            },
            {
                "value": 71651,
                "label": "Pirangi",
                "label_ar": "بيرانجي",
                "label_fr": "Pirangi"
            },
            {
                "value": 71763,
                "label": "Nova Londrina",
                "label_ar": "نوفا لوندرينا",
                "label_fr": "Nova Londrina"
            },
            {
                "value": 70836,
                "label": "Sao Goncalo dos Campos",
                "label_ar": "ساو جونكالو دوس كامبوس",
                "label_fr": "Sao Goncalo dos Campos"
            },
            {
                "value": 72058,
                "label": "Jabora",
                "label_ar": "جابورا",
                "label_fr": "Jabora"
            },
            {
                "value": 70292,
                "label": "Feliz",
                "label_ar": "فيليز",
                "label_fr": "Feliz"
            },
            {
                "value": 71419,
                "label": "Progresso",
                "label_ar": "بروجرسو",
                "label_fr": "Progresso"
            },
            {
                "value": 70391,
                "label": "Lavras da Mangabeira",
                "label_ar": "لافراس دا مانجابيرا",
                "label_fr": "Lavras da Mangabeira"
            },
            {
                "value": 70798,
                "label": "Ipora",
                "label_ar": "إيبورا",
                "label_fr": "Ipora"
            },
            {
                "value": 70779,
                "label": "Querencia do Norte",
                "label_ar": "كويرينسيا دو نورتي",
                "label_fr": "Querencia do Norte"
            },
            {
                "value": 71640,
                "label": "Ubaira",
                "label_ar": "ابيرا",
                "label_fr": "Ubaira"
            },
            {
                "value": 72007,
                "label": "Barracao",
                "label_ar": "باراكاو",
                "label_fr": "Barracao"
            },
            {
                "value": 71006,
                "label": "Santo Aleixo",
                "label_ar": "سانتو اليكسو",
                "label_fr": "Santo Aleixo"
            },
            {
                "value": 71482,
                "label": "Massaranduba",
                "label_ar": "ماساراندوبا",
                "label_fr": "Massaranduba"
            },
            {
                "value": 71565,
                "label": "Barao de Grajau",
                "label_ar": "باراو دي جراجاو",
                "label_fr": "Barao de Grajau"
            },
            {
                "value": 71619,
                "label": "Dionisio Cerqueira",
                "label_ar": "ديونيسيو سيركويرا",
                "label_fr": "Dionisio Cerqueira"
            },
            {
                "value": 71260,
                "label": "Andradas",
                "label_ar": "أندراداس",
                "label_fr": "Andradas"
            },
            {
                "value": 70951,
                "label": "Pontalina",
                "label_ar": "بونتالينا",
                "label_fr": "Pontalina"
            },
            {
                "value": 70201,
                "label": "Louveira",
                "label_ar": "لوفيرا",
                "label_fr": "Louveira"
            },
            {
                "value": 70617,
                "label": "Sao Goncalo do Amarante",
                "label_ar": "ساو جونكالو دو امارانتي",
                "label_fr": "Sao Goncalo do Amarante"
            },
            {
                "value": 70535,
                "label": "Caetite",
                "label_ar": "كايتيت",
                "label_fr": "Caetite"
            },
            {
                "value": 71165,
                "label": "Julio de Castilhos",
                "label_ar": "خوليو دي كاستيلوس",
                "label_fr": "Julio de Castilhos"
            },
            {
                "value": 71257,
                "label": "Carmo do Rio Claro",
                "label_ar": "كارمو دو ريو كلارو",
                "label_fr": "Carmo do Rio Claro"
            },
            {
                "value": 70532,
                "label": "Lavras",
                "label_ar": "لافراس",
                "label_fr": "Lavras"
            },
            {
                "value": 70289,
                "label": "Poco Verde",
                "label_ar": "بوكو فيردي",
                "label_fr": "Poco Verde"
            },
            {
                "value": 72020,
                "label": "Nova Timboteua",
                "label_ar": "نوفا تمبوتوا",
                "label_fr": "Nova Timboteua"
            },
            {
                "value": 71535,
                "label": "Iracema",
                "label_ar": "إيراسيما",
                "label_fr": "Iracema"
            },
            {
                "value": 70942,
                "label": "Jaguariaiva",
                "label_ar": "جاغواريايفا",
                "label_fr": "Jaguariaiva"
            },
            {
                "value": 71324,
                "label": "Campo Grande",
                "label_ar": "كامبو غراندي",
                "label_fr": "Campo Grande"
            },
            {
                "value": 71840,
                "label": "Iguai",
                "label_ar": "إجواي",
                "label_fr": "Iguaï"
            },
            {
                "value": 71056,
                "label": "Videira",
                "label_ar": "فيديرا",
                "label_fr": "Videira"
            },
            {
                "value": 71658,
                "label": "Morrinhos",
                "label_ar": "مورينيوس",
                "label_fr": "Morrinhos"
            },
            {
                "value": 70873,
                "label": "Vera Cruz",
                "label_ar": "فيرا كروز",
                "label_fr": "Vera Cruz"
            },
            {
                "value": 70092,
                "label": "Capao do Leao",
                "label_ar": "كاباو دو لياو",
                "label_fr": "Capao do Leao"
            },
            {
                "value": 71397,
                "label": "Aroeiras",
                "label_ar": "ارويراس",
                "label_fr": "Aroeiras"
            },
            {
                "value": 72123,
                "label": "Catanduvas",
                "label_ar": "كاتاندوفا",
                "label_fr": "Catanduvas"
            },
            {
                "value": 70304,
                "label": "Paulo Jacinto",
                "label_ar": "باولو جاسينتو",
                "label_fr": "Paulo Jacinto"
            },
            {
                "value": 71790,
                "label": "Gloria",
                "label_ar": "جلوريا",
                "label_fr": "Gloria"
            },
            {
                "value": 71848,
                "label": "Remigio",
                "label_ar": "ريميجيو",
                "label_fr": "Remigio"
            },
            {
                "value": 70424,
                "label": "Indaiatuba",
                "label_ar": "إنداياتوبا",
                "label_fr": "Indaiatuba"
            },
            {
                "value": 71663,
                "label": "Caraa",
                "label_ar": "كارا",
                "label_fr": "Caraa"
            },
            {
                "value": 70430,
                "label": "Sao Joaquim do Monte",
                "label_ar": "ساو جواكيم دو مونتي",
                "label_fr": "Sao Joaquim do Monte"
            },
            {
                "value": 71249,
                "label": "Planalto",
                "label_ar": "بلانالتو",
                "label_fr": "Planalto"
            },
            {
                "value": 70786,
                "label": "Biritinga",
                "label_ar": "بريتينجا",
                "label_fr": "Biritinga"
            },
            {
                "value": 71865,
                "label": "Morro Reuter",
                "label_ar": "مورو رويتر",
                "label_fr": "Morro Reuter"
            },
            {
                "value": 70595,
                "label": "Piraju",
                "label_ar": "بيراجو",
                "label_fr": "Piraju"
            },
            {
                "value": 71088,
                "label": "Pirai",
                "label_ar": "بيراي",
                "label_fr": "Pirai"
            },
            {
                "value": 70175,
                "label": "São Paulo",
                "label_ar": "ساو باولو",
                "label_fr": "São Paulo"
            },
            {
                "value": 71674,
                "label": "Carmopolis",
                "label_ar": "كارموبوليس",
                "label_fr": "Carmopolis"
            },
            {
                "value": 70185,
                "label": "Barrinha",
                "label_ar": "بارينها",
                "label_fr": "Barrinha"
            },
            {
                "value": 70849,
                "label": "Capanema",
                "label_ar": "كابانيما",
                "label_fr": "Capanema"
            },
            {
                "value": 70958,
                "label": "Itapiranga",
                "label_ar": "إيتابيرانجا",
                "label_fr": "Itapiranga"
            },
            {
                "value": 72026,
                "label": "Forquilha",
                "label_ar": "Forquilha",
                "label_fr": "Forquilha"
            },
            {
                "value": 71956,
                "label": "Parana",
                "label_ar": "بارانا",
                "label_fr": "Parana"
            },
            {
                "value": 71929,
                "label": "Juquia",
                "label_ar": "Juquia",
                "label_fr": "Juquia"
            },
            {
                "value": 71784,
                "label": "Julio Mesquita",
                "label_ar": "جوليو ميسكيتا",
                "label_fr": "Julio Mesquita"
            },
            {
                "value": 70943,
                "label": "Mariluz",
                "label_ar": "ماريلوز",
                "label_fr": "Mariluz"
            },
            {
                "value": 70138,
                "label": "Castro",
                "label_ar": "كاسترو",
                "label_fr": "Castro"
            },
            {
                "value": 71649,
                "label": "Garca",
                "label_ar": "غاركا",
                "label_fr": "Garca"
            },
            {
                "value": 70219,
                "label": "Cabreuva",
                "label_ar": "كابروفا",
                "label_fr": "Cabreuva"
            },
            {
                "value": 71426,
                "label": "Presidente Getulio",
                "label_ar": "بريزيدنتي جيتوليو",
                "label_fr": "Présidente Getulio"
            },
            {
                "value": 71884,
                "label": "Sao Joao de Pirabas",
                "label_ar": "ساو جواو دي بيراباس",
                "label_fr": "Sao Joao de Pirabas"
            },
            {
                "value": 70549,
                "label": "Rio Bonito",
                "label_ar": "ريو بونيتو",
                "label_fr": "Rio Bonito"
            },
            {
                "value": 70889,
                "label": "Cruzeiro do Sul",
                "label_ar": "كروزيرو دو سول",
                "label_fr": "Cruzeiro do Sul"
            },
            {
                "value": 70360,
                "label": "Barretos",
                "label_ar": "باريتوس",
                "label_fr": "Barretos"
            },
            {
                "value": 70457,
                "label": "Alto Santo",
                "label_ar": "ألتو سانتو",
                "label_fr": "Alto Santo"
            },
            {
                "value": 70370,
                "label": "Alto Piquiri",
                "label_ar": "ألتو بيكيري",
                "label_fr": "Alto Piquiri"
            },
            {
                "value": 70079,
                "label": "Porto Velho",
                "label_ar": "بورتو فيلهو",
                "label_fr": "Porto Velho"
            },
            {
                "value": 71628,
                "label": "Capela",
                "label_ar": "كابيلا",
                "label_fr": "Capela"
            },
            {
                "value": 71125,
                "label": "Gramado",
                "label_ar": "جرامادو",
                "label_fr": "Gramado"
            },
            {
                "value": 70460,
                "label": "Gomes",
                "label_ar": "جوميز",
                "label_fr": "Gomes"
            },
            {
                "value": 71945,
                "label": "Faxinal",
                "label_ar": "فاكسينال",
                "label_fr": "Faxinal"
            },
            {
                "value": 70833,
                "label": "Catu",
                "label_ar": "كاتو",
                "label_fr": "Catu"
            },
            {
                "value": 71337,
                "label": "Tamboril",
                "label_ar": "تامبوريل",
                "label_fr": "Tamboril"
            },
            {
                "value": 72121,
                "label": "Bonito",
                "label_ar": "بونيتو",
                "label_fr": "Bonito"
            },
            {
                "value": 71078,
                "label": "Boa Vista",
                "label_ar": "بوا فيستا",
                "label_fr": "Boa Vista"
            },
            {
                "value": 70011,
                "label": "Brasília",
                "label_ar": "برازيليا",
                "label_fr": "Brasilia"
            },
            {
                "value": 71239,
                "label": "Forquilha",
                "label_ar": "Forquilha",
                "label_fr": "Forquilha"
            },
            {
                "value": 72051,
                "label": "Conde",
                "label_ar": "كوندي",
                "label_fr": "Condé"
            },
            {
                "value": 71990,
                "label": "Terra Nova",
                "label_ar": "تيرا نوفا",
                "label_fr": "Terra Nova"
            },
            {
                "value": 71333,
                "label": "Parnaiba",
                "label_ar": "بارنيبا",
                "label_fr": "Parnaiba"
            },
            {
                "value": 71353,
                "label": "Capelinha",
                "label_ar": "Capelinha",
                "label_fr": "Capelinha"
            },
            {
                "value": 70834,
                "label": "Sapeacu",
                "label_ar": "سابياكو",
                "label_fr": "Sapeacu"
            },
            {
                "value": 70518,
                "label": "Campos",
                "label_ar": "كامبوس",
                "label_fr": "Campos"
            },
            {
                "value": 71703,
                "label": "Aguas de Lindoia",
                "label_ar": "أغواس دي ليندويا",
                "label_fr": "Aguas de Lindoia"
            },
            {
                "value": 70240,
                "label": "Osasco",
                "label_ar": "أوساسكو",
                "label_fr": "Osasco"
            },
            {
                "value": 70330,
                "label": "Xavantina",
                "label_ar": "زافانتينا",
                "label_fr": "Xavantina"
            },
            {
                "value": 70041,
                "label": "Fenix",
                "label_ar": "فينيكس",
                "label_fr": "Fenix"
            },
            {
                "value": 71757,
                "label": "Lambari",
                "label_ar": "لامباري",
                "label_fr": "Lambari"
            },
            {
                "value": 71109,
                "label": "Abadiania",
                "label_ar": "العبادية",
                "label_fr": "Abadiania"
            },
            {
                "value": 71015,
                "label": "Cruz das Almas",
                "label_ar": "كروز داس الماس",
                "label_fr": "Cruz das Almas"
            },
            {
                "value": 70380,
                "label": "Itajuba",
                "label_ar": "إيتاجوبا",
                "label_fr": "Itajuba"
            },
            {
                "value": 70266,
                "label": "Itatiaia",
                "label_ar": "إيتاتيايا",
                "label_fr": "Itatiaia"
            },
            {
                "value": 70483,
                "label": "Guanambi",
                "label_ar": "غوانامبي",
                "label_fr": "Guanambi"
            },
            {
                "value": 70385,
                "label": "Garanhuns",
                "label_ar": "جارانهونس",
                "label_fr": "Garanhuns"
            },
            {
                "value": 70673,
                "label": "Nova Odessa",
                "label_ar": "نوفا أوديسا",
                "label_fr": "Nova Odessa"
            },
            {
                "value": 70113,
                "label": "Rondonópolis",
                "label_ar": "روندونوبوليس",
                "label_fr": "Rondonópolis"
            },
            {
                "value": 71696,
                "label": "Igarata",
                "label_ar": "إيغاراتا",
                "label_fr": "Igarata"
            },
            {
                "value": 71462,
                "label": "Mandaguari",
                "label_ar": "Mandaguari",
                "label_fr": "Mandaguari"
            },
            {
                "value": 70991,
                "label": "Cocalinho",
                "label_ar": "كوكالينهو",
                "label_fr": "Cocalinho"
            },
            {
                "value": 71940,
                "label": "Barra do Corda",
                "label_ar": "بارا دو كوردا",
                "label_fr": "Barra do Corda"
            },
            {
                "value": 71924,
                "label": "Sorriso",
                "label_ar": "سوريسو",
                "label_fr": "Sorriso"
            },
            {
                "value": 70994,
                "label": "Abidos",
                "label_ar": "أبيدوس",
                "label_fr": "Abidos"
            },
            {
                "value": 70395,
                "label": "Ipatinga",
                "label_ar": "إباتينجا",
                "label_fr": "Ipatinga"
            },
            {
                "value": 72039,
                "label": "Santo Antonio de Posse",
                "label_ar": "سانتو أنطونيو دي بوسي",
                "label_fr": "Santo Antonio de Posse"
            },
            {
                "value": 70587,
                "label": "Boa Esperanca",
                "label_ar": "بوا اسبرانكا",
                "label_fr": "Boa Esperanca"
            },
            {
                "value": 71022,
                "label": "Caraguatatuba",
                "label_ar": "كاراجواتاتوبا",
                "label_fr": "Caraguatatuba"
            },
            {
                "value": 71996,
                "label": "Porto Sao Jose Velho",
                "label_ar": "بورتو ساو جوزيه فيلهو",
                "label_fr": "Porto Sao Jose Velho"
            },
            {
                "value": 71139,
                "label": "Nobres",
                "label_ar": "نوبريس",
                "label_fr": "Nobres"
            },
            {
                "value": 70934,
                "label": "Maria da Fe",
                "label_ar": "ماريا دا في",
                "label_fr": "Maria da Fe"
            },
            {
                "value": 71429,
                "label": "Sebastianopolis do Sul",
                "label_ar": "سيباستيانوبوليس دو سول",
                "label_fr": "Sebastianopolis do Sul"
            },
            {
                "value": 71479,
                "label": "Luzerna",
                "label_ar": "لوزيرنا",
                "label_fr": "Luzerna"
            },
            {
                "value": 70023,
                "label": "Rio Branco",
                "label_ar": "ريو برانكو",
                "label_fr": "Rio Branco"
            },
            {
                "value": 71618,
                "label": "Rio Bananal",
                "label_ar": "ريو بانانال",
                "label_fr": "Rio Bananal"
            },
            {
                "value": 71638,
                "label": "Presidente Dutra",
                "label_ar": "بريزيدنتي دوترا",
                "label_fr": "Presidente Dutra"
            },
            {
                "value": 71588,
                "label": "Jundiai do Sul",
                "label_ar": "جوندياي دو سول",
                "label_fr": "Jundiai do Sul"
            },
            {
                "value": 72104,
                "label": "Santo Antonio da Platina",
                "label_ar": "سانتو أنطونيو دا بلاتينا",
                "label_fr": "Santo Antonio da Platina"
            },
            {
                "value": 70490,
                "label": "Vargem Alta",
                "label_ar": "فارجيم ألتا",
                "label_fr": "Vargem Alta"
            },
            {
                "value": 70039,
                "label": "Palmas",
                "label_ar": "بالماس",
                "label_fr": "Palmas"
            },
            {
                "value": 70829,
                "label": "Sao Roque",
                "label_ar": "ساو روكي",
                "label_fr": "Sao Roque"
            },
            {
                "value": 71706,
                "label": "Santa Adelia",
                "label_ar": "سانتا أديليا",
                "label_fr": "Santa Adelia"
            },
            {
                "value": 71446,
                "label": "Barra de Sao Miguel",
                "label_ar": "بارا دي ساو ميغيل",
                "label_fr": "Barra de Sao Miguel"
            },
            {
                "value": 71808,
                "label": "Balsa Nova",
                "label_ar": "بلسا نوفا",
                "label_fr": "Balsa Nova"
            },
            {
                "value": 70778,
                "label": "Santa Isabel do Ivai",
                "label_ar": "سانتا إيزابيل دو إيفاي",
                "label_fr": "Santa Isabel do Ivai"
            },
            {
                "value": 71383,
                "label": "Natercia",
                "label_ar": "ناتيرسيا",
                "label_fr": "Natercia"
            },
            {
                "value": 70022,
                "label": "João Pessoa",
                "label_ar": "جواو بيسوا",
                "label_fr": "João Pessoa"
            },
            {
                "value": 70901,
                "label": "Sao Joaquim da Barra",
                "label_ar": "ساو جواكيم دا بارا",
                "label_fr": "Sao Joaquim da Barra"
            },
            {
                "value": 70222,
                "label": "Sao Vicente",
                "label_ar": "ساو فيسينتي",
                "label_fr": "Sao Vicente"
            },
            {
                "value": 70997,
                "label": "Presidente Bernardes",
                "label_ar": "الرئيس برنارديس",
                "label_fr": "Présidente Bernardes"
            },
            {
                "value": 71296,
                "label": "Cerro Grande",
                "label_ar": "سيرو غراندي",
                "label_fr": "Cerro Grande"
            },
            {
                "value": 70298,
                "label": "Itaporanga",
                "label_ar": "إيتابورانجا",
                "label_fr": "Itaporanga"
            },
            {
                "value": 70698,
                "label": "Itabaianinha",
                "label_ar": "إيتابيانينها",
                "label_fr": "Itabaianinha"
            },
            {
                "value": 70221,
                "label": "São Bernardo do Campo",
                "label_ar": "ساو برناردو دو كامبو",
                "label_fr": "São Bernardo do Campo"
            },
            {
                "value": 70353,
                "label": "Itabaiana",
                "label_ar": "إيتابيانا",
                "label_fr": "Itabaiana"
            },
            {
                "value": 70543,
                "label": "Jerico",
                "label_ar": "جيريكو",
                "label_fr": "Jerico"
            },
            {
                "value": 70696,
                "label": "Guarei",
                "label_ar": "Guarei",
                "label_fr": "Guarei"
            },
            {
                "value": 71920,
                "label": "Nazare Paulista",
                "label_ar": "نازاري باوليستا",
                "label_fr": "Nazare Paulista"
            },
            {
                "value": 70343,
                "label": "Caucaia",
                "label_ar": "القوقا",
                "label_fr": "Caucaia"
            },
            {
                "value": 70252,
                "label": "Sao Luiz Gonzaga",
                "label_ar": "ساو لويز جونزاغا",
                "label_fr": "Sao Luiz Gonzaga"
            },
            {
                "value": 71541,
                "label": "Sao Goncalo do Para",
                "label_ar": "ساو جونكالو دو بارا",
                "label_fr": "Sao Goncalo do Para"
            },
            {
                "value": 70819,
                "label": "Lages",
                "label_ar": "لاجز",
                "label_fr": "Lages"
            },
            {
                "value": 71544,
                "label": "Palmital",
                "label_ar": "بالميتال",
                "label_fr": "Palmital"
            },
            {
                "value": 71690,
                "label": "Wenceslau Braz",
                "label_ar": "Wenceslau Braz",
                "label_fr": "Wenceslau Braz"
            },
            {
                "value": 71058,
                "label": "Cicero Dantas",
                "label_ar": "شيشرون دانتاس",
                "label_fr": "Cicero Dantas"
            },
            {
                "value": 70012,
                "label": "Lagoa",
                "label_ar": "لاجوا",
                "label_fr": "Lagoa"
            },
            {
                "value": 71435,
                "label": "Dias",
                "label_ar": "دياس",
                "label_fr": "Dias"
            },
            {
                "value": 71844,
                "label": "Cupira",
                "label_ar": "كوبيرا",
                "label_fr": "Cupira"
            },
            {
                "value": 70053,
                "label": "Joinville",
                "label_ar": "انضمام فيل",
                "label_fr": "Rejoindre la ville"
            },
            {
                "value": 70846,
                "label": "Ituporanga",
                "label_ar": "إيتوبورانجا",
                "label_fr": "Ituporanga"
            },
            {
                "value": 70852,
                "label": "Poco das Antas",
                "label_ar": "بوكو داس أنتاس",
                "label_fr": "Poco das Antas"
            },
            {
                "value": 70923,
                "label": "Conceicao do Mato Dentro",
                "label_ar": "كونسيكاو دو ماتو دينترو",
                "label_fr": "Conceicao do Mato Dentro"
            },
            {
                "value": 72027,
                "label": "Jussari",
                "label_ar": "جوساري",
                "label_fr": "Jussari"
            },
            {
                "value": 72068,
                "label": "Inhacora",
                "label_ar": "إينهاكورا",
                "label_fr": "Inhacora"
            },
            {
                "value": 70680,
                "label": "Umbauba",
                "label_ar": "أومباوبا",
                "label_fr": "Umbauba"
            },
            {
                "value": 72098,
                "label": "Itinga da Serra",
                "label_ar": "إيتينجا دا سيرا",
                "label_fr": "Itinga da Serra"
            },
            {
                "value": 70562,
                "label": "Sao Jose do Ouro",
                "label_ar": "ساو جوزيه دو أورو",
                "label_fr": "Sao Jose do Ouro"
            },
            {
                "value": 70302,
                "label": "Santa Isabel do Para",
                "label_ar": "سانتا إيزابيل دو بارا",
                "label_fr": "Santa Isabel do Para"
            },
            {
                "value": 70716,
                "label": "Sertania",
                "label_ar": "سيرتانيا",
                "label_fr": "Sertania"
            },
            {
                "value": 70265,
                "label": "Volta Redonda",
                "label_ar": "فولتا ريدوندا",
                "label_fr": "Volta Redonda"
            },
            {
                "value": 71417,
                "label": "Lagoa Vermelha",
                "label_ar": "لاجوا فيرميلها",
                "label_fr": "Lagoa Vermelha"
            },
            {
                "value": 71633,
                "label": "Monte Carmelo",
                "label_ar": "مونتي كارميلو",
                "label_fr": "Monte Carmelo"
            },
            {
                "value": 72125,
                "label": "Estiva",
                "label_ar": "إستيفا",
                "label_fr": "Estiva"
            },
            {
                "value": 71587,
                "label": "Itaqui",
                "label_ar": "إيتاكي",
                "label_fr": "Itaqui"
            },
            {
                "value": 71014,
                "label": "Salvador",
                "label_ar": "سلفادور",
                "label_fr": "Salvador"
            },
            {
                "value": 70925,
                "label": "Bom Jardim",
                "label_ar": "بوم جارديم",
                "label_fr": "Bom Jardim"
            },
            {
                "value": 71194,
                "label": "Frei Paulo",
                "label_ar": "فراي باولو",
                "label_fr": "Frei Paulo"
            },
            {
                "value": 71631,
                "label": "Benevides",
                "label_ar": "بينيفيدس",
                "label_fr": "Benevides"
            },
            {
                "value": 70501,
                "label": "Formiga",
                "label_ar": "فورميغا",
                "label_fr": "Formiga"
            },
            {
                "value": 70241,
                "label": "Jandira",
                "label_ar": "جانديرا",
                "label_fr": "Jandira"
            },
            {
                "value": 71552,
                "label": "Triunfo",
                "label_ar": "تريونفو",
                "label_fr": "Triunfo"
            },
            {
                "value": 72063,
                "label": "Iguaba Grande",
                "label_ar": "إجوابا غراندي",
                "label_fr": "Iguaba Grande"
            },
            {
                "value": 71675,
                "label": "Buritizeiro",
                "label_ar": "بوريتيزيرو",
                "label_fr": "Buritizeiro"
            },
            {
                "value": 70894,
                "label": "Brasil",
                "label_ar": "البرازيل",
                "label_fr": "Brésil"
            },
            {
                "value": 72055,
                "label": "São Borja",
                "label_ar": "ساو بورجا",
                "label_fr": "São Borja"
            },
            {
                "value": 71546,
                "label": "Baia da Traicao",
                "label_ar": "بايا دا ترايكاو",
                "label_fr": "Baia da Traicao"
            },
            {
                "value": 70059,
                "label": "Palhoca",
                "label_ar": "بالوكا",
                "label_fr": "Palhoca"
            },
            {
                "value": 71188,
                "label": "Eusebio",
                "label_ar": "أوزيبيو",
                "label_fr": "Eusebio"
            },
            {
                "value": 71491,
                "label": "Jacuizinho",
                "label_ar": "جاكوزينيو",
                "label_fr": "Jacuizinho"
            },
            {
                "value": 70274,
                "label": "Ceres",
                "label_ar": "سيريس",
                "label_fr": "Cérès"
            },
            {
                "value": 70057,
                "label": "Imbituba",
                "label_ar": "إمبيتوبا",
                "label_fr": "Imbituba"
            },
            {
                "value": 70614,
                "label": "Tiete",
                "label_ar": "تيتي",
                "label_fr": "Tiete"
            },
            {
                "value": 70229,
                "label": "Tremembe",
                "label_ar": "تريميمبي",
                "label_fr": "Tremembe"
            },
            {
                "value": 71062,
                "label": "Progresso",
                "label_ar": "بروجرسو",
                "label_fr": "Progresso"
            },
            {
                "value": 71886,
                "label": "Bonito",
                "label_ar": "بونيتو",
                "label_fr": "Bonito"
            },
            {
                "value": 70006,
                "label": "Salvador",
                "label_ar": "سلفادور",
                "label_fr": "Salvador"
            },
            {
                "value": 70164,
                "label": "Lambari",
                "label_ar": "لامباري",
                "label_fr": "Lambari"
            },
            {
                "value": 70699,
                "label": "Tomar do Geru",
                "label_ar": "تومار دو جيرو",
                "label_fr": "Tomar do Geru"
            },
            {
                "value": 71342,
                "label": "Baturite",
                "label_ar": "باتوريت",
                "label_fr": "Baturite"
            },
            {
                "value": 72101,
                "label": "Barra",
                "label_ar": "بارا",
                "label_fr": "Barra"
            },
            {
                "value": 70069,
                "label": "Mutum",
                "label_ar": "موتوم",
                "label_fr": "Mutum"
            },
            {
                "value": 70828,
                "label": "Guarapari",
                "label_ar": "غواراباري",
                "label_fr": "Guarapari"
            },
            {
                "value": 71981,
                "label": "Aguas da Prata",
                "label_ar": "أغواس دا براتا",
                "label_fr": "Aguas da Prata"
            },
            {
                "value": 70978,
                "label": "Candeias",
                "label_ar": "كاندياس",
                "label_fr": "Candeias"
            },
            {
                "value": 70567,
                "label": "Japira",
                "label_ar": "جابيرا",
                "label_fr": "Japira"
            },
            {
                "value": 71012,
                "label": "Arujá",
                "label_ar": "أروجا",
                "label_fr": "Arujá"
            },
            {
                "value": 70944,
                "label": "Ji Parana",
                "label_ar": "جي بارانا",
                "label_fr": "Ji Parana"
            },
            {
                "value": 70157,
                "label": "Realeza",
                "label_ar": "ريليزا",
                "label_fr": "Realeza"
            },
            {
                "value": 70609,
                "label": "Joao Monlevade",
                "label_ar": "جواو مونليفادي",
                "label_fr": "Joao Monlevade"
            },
            {
                "value": 71991,
                "label": "Altos",
                "label_ar": "ألتوس",
                "label_fr": "Altos"
            },
            {
                "value": 71117,
                "label": "Sao Jose",
                "label_ar": "ساو جوزيه",
                "label_fr": "Sao José"
            },
            {
                "value": 70450,
                "label": "Juruaia",
                "label_ar": "Juruaia",
                "label_fr": "Juruaia"
            },
            {
                "value": 71526,
                "label": "Itabaiana",
                "label_ar": "إيتابيانا",
                "label_fr": "Itabaiana"
            },
            {
                "value": 71845,
                "label": "Salgueiro",
                "label_ar": "سالجيرو",
                "label_fr": "Salgueiro"
            },
            {
                "value": 70754,
                "label": "Sanharo",
                "label_ar": "سانهارو",
                "label_fr": "Sanharo"
            },
            {
                "value": 70408,
                "label": "Minas",
                "label_ar": "ميناس",
                "label_fr": "Minas"
            },
            {
                "value": 71195,
                "label": "Altinho",
                "label_ar": "ألتينو",
                "label_fr": "Altinho"
            },
            {
                "value": 70728,
                "label": "Orobo",
                "label_ar": "أوروبو",
                "label_fr": "Orobo"
            },
            {
                "value": 70515,
                "label": "Camaragibe",
                "label_ar": "كاماراجيبى",
                "label_fr": "Camaragibe"
            },
            {
                "value": 70404,
                "label": "Madre de Deus",
                "label_ar": "مادري دي ديوس",
                "label_fr": "Madre de Deus"
            },
            {
                "value": 70463,
                "label": "Piquet Carneiro",
                "label_ar": "بيكيه كارنيرو",
                "label_fr": "Piquet Carneiro"
            },
            {
                "value": 71431,
                "label": "Juatuba",
                "label_ar": "جواتوبا",
                "label_fr": "Juatuba"
            },
            {
                "value": 70656,
                "label": "Campos do Jordao",
                "label_ar": "كامبوس دو جورداو",
                "label_fr": "Campos do Jordao"
            },
            {
                "value": 70357,
                "label": "Guapore",
                "label_ar": "غوابور",
                "label_fr": "Guaporé"
            },
            {
                "value": 70456,
                "label": "Mar de Espanha",
                "label_ar": "مار دي اسبانيها",
                "label_fr": "Mar de Espanha"
            },
            {
                "value": 72025,
                "label": "Teodoro Sampaio",
                "label_ar": "تيودورو سامبايو",
                "label_fr": "Teodoro Sampaio"
            },
            {
                "value": 70761,
                "label": "Mage",
                "label_ar": "بركه",
                "label_fr": "mage"
            },
            {
                "value": 70845,
                "label": "Pancas",
                "label_ar": "بانكاس",
                "label_fr": "Pancas"
            },
            {
                "value": 71489,
                "label": "Seara",
                "label_ar": "سيرا",
                "label_fr": "Seara"
            },
            {
                "value": 70730,
                "label": "Abelardo Luz",
                "label_ar": "أبيلاردو لوز",
                "label_fr": "Abelardo Luz"
            },
            {
                "value": 70427,
                "label": "Lagoa dos Gatos",
                "label_ar": "لاجوا دوس جاتوس",
                "label_fr": "Lagoa dos Gatos"
            },
            {
                "value": 71007,
                "label": "Caçador",
                "label_ar": "Caçador",
                "label_fr": "Caçador"
            },
            {
                "value": 71105,
                "label": "Mongagua",
                "label_ar": "مونجاجوا",
                "label_fr": "Mongagua"
            },
            {
                "value": 70678,
                "label": "Carazinho",
                "label_ar": "كارازينيو",
                "label_fr": "Carazinho"
            },
            {
                "value": 71373,
                "label": "Palestina",
                "label_ar": "فلسطين",
                "label_fr": "Palestina"
            },
            {
                "value": 70046,
                "label": "Aguas de Chapeco",
                "label_ar": "أغواس دي تشابيكو",
                "label_fr": "Aguas de Chapeco"
            },
            {
                "value": 71300,
                "label": "Cha Grande",
                "label_ar": "تشا غراندي",
                "label_fr": "Cha Grande"
            },
            {
                "value": 70971,
                "label": "Sao Salvador",
                "label_ar": "ساو سلفادور",
                "label_fr": "Sao Salvador"
            },
            {
                "value": 71609,
                "label": "Ibiuna",
                "label_ar": "إبيونا",
                "label_fr": "Ibiuna"
            },
            {
                "value": 71533,
                "label": "Pereiro",
                "label_ar": "بيريرو",
                "label_fr": "Pereiro"
            },
            {
                "value": 71737,
                "label": "Belem do Brejo do Cruz",
                "label_ar": "بيليم دو بريجو دو كروز",
                "label_fr": "Belem do Brejo do Cruz"
            },
            {
                "value": 70616,
                "label": "Parnamirim",
                "label_ar": "بارنامريم",
                "label_fr": "Parnamirim"
            },
            {
                "value": 71370,
                "label": "Valenca",
                "label_ar": "فالينكا",
                "label_fr": "Valenca"
            },
            {
                "value": 70646,
                "label": "Formosa",
                "label_ar": "فورموزا",
                "label_fr": "Formose"
            },
            {
                "value": 70801,
                "label": "Parelhas",
                "label_ar": "باريلهاس",
                "label_fr": "Parelhas"
            },
            {
                "value": 71252,
                "label": "Espera Feliz",
                "label_ar": "إسبيرا فيليز",
                "label_fr": "Espera Feliz"
            },
            {
                "value": 71388,
                "label": "Nossa Senhora da Gloria",
                "label_ar": "نوسا سينهورا دا جلوريا",
                "label_fr": "Nossa Senhora da Gloria"
            },
            {
                "value": 71102,
                "label": "Itapolis",
                "label_ar": "إيتابوليس",
                "label_fr": "Itapolis"
            },
            {
                "value": 71551,
                "label": "Bom Jesus",
                "label_ar": "بوم يسوع",
                "label_fr": "Bom Jesus"
            },
            {
                "value": 71786,
                "label": "Pariquera Acu",
                "label_ar": "Pariquera Acu",
                "label_fr": "Pariquera Acu"
            },
            {
                "value": 71597,
                "label": "Sao Lourenco dOeste",
                "label_ar": "ساو لورينكو دي أويستي",
                "label_fr": "Sao Lourenço d'Oeste"
            },
            {
                "value": 72031,
                "label": "Antas",
                "label_ar": "أنتاس",
                "label_fr": "Antas"
            },
            {
                "value": 71817,
                "label": "Nova Ponte",
                "label_ar": "نوفا بونتي",
                "label_fr": "Nova Ponte"
            },
            {
                "value": 70032,
                "label": "Rio Verde de Mato Grosso",
                "label_ar": "ريو فيردي دي ماتو جروسو",
                "label_fr": "Rio Verde de Mato Grosso"
            },
            {
                "value": 71151,
                "label": "Guaraciaba",
                "label_ar": "Guaraciaba",
                "label_fr": "Guaraciaba"
            },
            {
                "value": 71958,
                "label": "Nova Esperanca",
                "label_ar": "نوفا اسبيرانجا",
                "label_fr": "Nova Esperanca"
            },
            {
                "value": 70180,
                "label": "Amparo",
                "label_ar": "أمبارو",
                "label_fr": "Amparo"
            },
            {
                "value": 71585,
                "label": "Urai",
                "label_ar": "أوراي",
                "label_fr": "Urai"
            },
            {
                "value": 70454,
                "label": "Cataguases",
                "label_ar": "كاتاجواسيس",
                "label_fr": "Cataguases"
            },
            {
                "value": 71287,
                "label": "Capela",
                "label_ar": "كابيلا",
                "label_fr": "Capela"
            },
            {
                "value": 72118,
                "label": "Barra",
                "label_ar": "بارا",
                "label_fr": "Barra"
            },
            {
                "value": 70475,
                "label": "Boa Esperanca",
                "label_ar": "بوا اسبرانكا",
                "label_fr": "Boa Esperanca"
            },
            {
                "value": 71184,
                "label": "Vicente",
                "label_ar": "فيسنتي",
                "label_fr": "Vicente"
            },
            {
                "value": 71474,
                "label": "Bernardo",
                "label_ar": "برناردو",
                "label_fr": "Bernardo"
            },
            {
                "value": 72064,
                "label": "Barra Velha",
                "label_ar": "بارا فيلها",
                "label_fr": "Barra Velha"
            },
            {
                "value": 70675,
                "label": "Morrinhos",
                "label_ar": "مورينيوس",
                "label_fr": "Morrinhos"
            },
            {
                "value": 70823,
                "label": "Campina Grande do Sul",
                "label_ar": "كامبينا غراندي دو سول",
                "label_fr": "Campina Grande do Sul"
            },
            {
                "value": 71051,
                "label": "Campo Verde",
                "label_ar": "كامبو فيردي",
                "label_fr": "Campo Verde"
            },
            {
                "value": 71021,
                "label": "Itapicuru",
                "label_ar": "ايتابيكورو",
                "label_fr": "Itapicuru"
            },
            {
                "value": 70078,
                "label": "Sobradinho",
                "label_ar": "سوبرادينيو",
                "label_fr": "Sobradinho"
            },
            {
                "value": 70285,
                "label": "Santa Rita do Passa Quatro",
                "label_ar": "سانتا ريتا دو باسا كواترو",
                "label_fr": "Santa Rita do Passa Quatro"
            },
            {
                "value": 72092,
                "label": "Arraial",
                "label_ar": "Arraial",
                "label_fr": "Arraial"
            },
            {
                "value": 70507,
                "label": "Sao Pedro da Aldeia",
                "label_ar": "ساو بيدرو دا الديا",
                "label_fr": "Sao Pedro da Aldeia"
            },
            {
                "value": 70578,
                "label": "Sao Cristovao",
                "label_ar": "ساو كريستوفاو",
                "label_fr": "Sao Cristovao"
            },
            {
                "value": 72008,
                "label": "Turvo",
                "label_ar": "تورفو",
                "label_fr": "Turvo"
            },
            {
                "value": 71536,
                "label": "Carius",
                "label_ar": "كاريوس",
                "label_fr": "Carius"
            },
            {
                "value": 71735,
                "label": "Joao Neiva",
                "label_ar": "جواو نيفا",
                "label_fr": "Joao Neiva"
            },
            {
                "value": 71832,
                "label": "Taiobeiras",
                "label_ar": "تايوبيراس",
                "label_fr": "Taiobeiras"
            },
            {
                "value": 71110,
                "label": "Terra Nova",
                "label_ar": "تيرا نوفا",
                "label_fr": "Terra Nova"
            },
            {
                "value": 70311,
                "label": "Londrina",
                "label_ar": "لوندرينا",
                "label_fr": "Londrina"
            },
            {
                "value": 70916,
                "label": "Catalao",
                "label_ar": "كاتالاو",
                "label_fr": "Catalao"
            },
            {
                "value": 70644,
                "label": "Itapaci",
                "label_ar": "إيتاباشي",
                "label_fr": "Itapaci"
            },
            {
                "value": 70665,
                "label": "Dumont",
                "label_ar": "دومون",
                "label_fr": "Dumont"
            },
            {
                "value": 71197,
                "label": "Lucena",
                "label_ar": "لوسينا",
                "label_fr": "Lucena"
            },
            {
                "value": 70477,
                "label": "Muriaé",
                "label_ar": "مورياي",
                "label_fr": "Muriaé"
            },
            {
                "value": 71616,
                "label": "Bueno Brandao",
                "label_ar": "بوينو برانداو",
                "label_fr": "Bueno Brandao"
            },
            {
                "value": 71691,
                "label": "Itarare",
                "label_ar": "ايتاراري",
                "label_fr": "Itarare"
            },
            {
                "value": 70514,
                "label": "Colatina",
                "label_ar": "كولاتينا",
                "label_fr": "Colatine"
            },
            {
                "value": 70034,
                "label": "Ponta Pora",
                "label_ar": "بونتا بورا",
                "label_fr": "Ponta Pora"
            },
            {
                "value": 70398,
                "label": "Muniz Ferreira",
                "label_ar": "مونيز فيريرا",
                "label_fr": "Muniz Ferreira"
            },
            {
                "value": 70610,
                "label": "Bom Jesus dos Perdoes",
                "label_ar": "بوم جيسوس دوس بيردوس",
                "label_fr": "Bom Jesus dos Perdoes"
            },
            {
                "value": 70586,
                "label": "Marilandia",
                "label_ar": "ماريلانديا",
                "label_fr": "Marilandia"
            },
            {
                "value": 70782,
                "label": "Primavera",
                "label_ar": "بريمافيرا",
                "label_fr": "Primavera"
            },
            {
                "value": 71046,
                "label": "Areia Branca",
                "label_ar": "أريا برانكا",
                "label_fr": "Areia Branca"
            },
            {
                "value": 71948,
                "label": "Chavantes",
                "label_ar": "شافانتس",
                "label_fr": "Chavantes"
            },
            {
                "value": 71549,
                "label": "Cajati",
                "label_ar": "كاجاتي",
                "label_fr": "Cajati"
            },
            {
                "value": 70247,
                "label": "Medianeira",
                "label_ar": "ميديانيرا",
                "label_fr": "Medianeira"
            },
            {
                "value": 70451,
                "label": "Campos Novos",
                "label_ar": "كامبوس نوفوس",
                "label_fr": "Campos Novos"
            },
            {
                "value": 71358,
                "label": "Jardim de Piranhas",
                "label_ar": "جارديم دي بيرانهاس",
                "label_fr": "Jardim de Piranhas"
            },
            {
                "value": 70856,
                "label": "Itatiba",
                "label_ar": "إيتاتيبا",
                "label_fr": "Itatiba"
            },
            {
                "value": 70555,
                "label": "Urucania",
                "label_ar": "أوروكانيا",
                "label_fr": "Urucanie"
            },
            {
                "value": 72103,
                "label": "Ouricuri",
                "label_ar": "أوريكوري",
                "label_fr": "Ouricuri"
            },
            {
                "value": 71068,
                "label": "Recanto",
                "label_ar": "ريكانتو",
                "label_fr": "Recanto"
            },
            {
                "value": 71816,
                "label": "Matutina",
                "label_ar": "ماتوتينا",
                "label_fr": "Matutina"
            },
            {
                "value": 72004,
                "label": "Carambei",
                "label_ar": "كرامبي",
                "label_fr": "Carambei"
            },
            {
                "value": 71288,
                "label": "Sertao",
                "label_ar": "سيرتاو",
                "label_fr": "Sertao"
            },
            {
                "value": 71183,
                "label": "Fortaleza",
                "label_ar": "فورتاليزا",
                "label_fr": "Fortaleza"
            },
            {
                "value": 71234,
                "label": "Itabuna",
                "label_ar": "إيتابونا",
                "label_fr": "Itabuna"
            },
            {
                "value": 71723,
                "label": "Piracema",
                "label_ar": "بيراسيما",
                "label_fr": "Piracema"
            },
            {
                "value": 70572,
                "label": "Cordeiropolis",
                "label_ar": "كورديروبوليس",
                "label_fr": "Cordeiropolis"
            },
            {
                "value": 72116,
                "label": "Bom Jesus",
                "label_ar": "بوم يسوع",
                "label_fr": "Bom Jesus"
            },
            {
                "value": 71777,
                "label": "Vila Maria",
                "label_ar": "فيلا ماريا",
                "label_fr": "Vila Maria"
            },
            {
                "value": 72002,
                "label": "Estancia Nova",
                "label_ar": "إستانسيا نوفا",
                "label_fr": "Estancia Nova"
            },
            {
                "value": 71336,
                "label": "Paracuru",
                "label_ar": "باراكورو",
                "label_fr": "Paracuru"
            },
            {
                "value": 70178,
                "label": "Jucurutu",
                "label_ar": "جوكوروتو",
                "label_fr": "Jucurutu"
            },
            {
                "value": 71360,
                "label": "Ipumirim",
                "label_ar": "إيبوميريم",
                "label_fr": "Ipumirim"
            },
            {
                "value": 70612,
                "label": "Sao Domingos",
                "label_ar": "ساو دومينغوس",
                "label_fr": "Sao Domingos"
            },
            {
                "value": 71242,
                "label": "Caputira",
                "label_ar": "كابوتيرا",
                "label_fr": "Caputira"
            },
            {
                "value": 70383,
                "label": "Canhotinho",
                "label_ar": "كانهوتينيو",
                "label_fr": "Canhotinho"
            },
            {
                "value": 71636,
                "label": "Paraibano",
                "label_ar": "بارايبانو",
                "label_fr": "Paraibano"
            },
            {
                "value": 70815,
                "label": "Sao Jose do Rio Pardo",
                "label_ar": "ساو جوزيه دو ريو باردو",
                "label_fr": "Sao Jose do Rio Pardo"
            },
            {
                "value": 72080,
                "label": "Otacilio Costa",
                "label_ar": "أوتاسيليو كوستا",
                "label_fr": "Otacilio Costa"
            },
            {
                "value": 71171,
                "label": "Cravinhos",
                "label_ar": "كرافينهوس",
                "label_fr": "Cravinhos"
            },
            {
                "value": 70593,
                "label": "Nova Esperanca",
                "label_ar": "نوفا اسبيرانجا",
                "label_fr": "Nova Esperanca"
            },
            {
                "value": 71306,
                "label": "Seberi",
                "label_ar": "سيبيري",
                "label_fr": "Seberi"
            },
            {
                "value": 71885,
                "label": "Inhangapi",
                "label_ar": "Inhangapi",
                "label_fr": "Inhangapi"
            },
            {
                "value": 70631,
                "label": "Morro da Fumaca",
                "label_ar": "مورو دا فوماكا",
                "label_fr": "Morro da Fumaca"
            },
            {
                "value": 71092,
                "label": "Sao Jose do Egito",
                "label_ar": "ساو جوزيه دو إيجيتو",
                "label_fr": "Sao Jose do Egito"
            },
            {
                "value": 70103,
                "label": "Imbituva",
                "label_ar": "إمبيتوفا",
                "label_fr": "Imbituva"
            },
            {
                "value": 71439,
                "label": "Rio Casca",
                "label_ar": "ريو كاسكا",
                "label_fr": "Rio Casca"
            },
            {
                "value": 71838,
                "label": "America de Cima",
                "label_ar": "أمريكا دي سيما",
                "label_fr": "America de Cima"
            },
            {
                "value": 71782,
                "label": "Jaguaras",
                "label_ar": "جاكواراس",
                "label_fr": "Jaguaras"
            },
            {
                "value": 71189,
                "label": "Riachao do Jacuipe",
                "label_ar": "Riachao do Jacuipe",
                "label_fr": "Riachao do Jacuipe"
            },
            {
                "value": 71343,
                "label": "Visconde do Rio Branco",
                "label_ar": "فيسكوندي دو ريو برانكو",
                "label_fr": "Visconde do Rio Branco"
            },
            {
                "value": 71594,
                "label": "Batista",
                "label_ar": "باتيستا",
                "label_fr": "Batista"
            },
            {
                "value": 70727,
                "label": "Umbuzeiro",
                "label_ar": "أمبوزيرو",
                "label_fr": "Umbuzeiro"
            },
            {
                "value": 70105,
                "label": "Camboriu",
                "label_ar": "كامبوريو",
                "label_fr": "Camboriu"
            },
            {
                "value": 70268,
                "label": "Seropedica",
                "label_ar": "سيروبيديكا",
                "label_fr": "Seropedica"
            },
            {
                "value": 70262,
                "label": "Sao Joao de Meriti",
                "label_ar": "ساو جواو دي ميريتي",
                "label_fr": "Sao Joao de Meriti"
            },
            {
                "value": 71175,
                "label": "Garimpo Novo",
                "label_ar": "جاريمبو نوفو",
                "label_fr": "Garimpo Novo"
            },
            {
                "value": 71852,
                "label": "Porto Esperidiao",
                "label_ar": "بورتو اسبريدياو",
                "label_fr": "Porto Esperidiao"
            },
            {
                "value": 70880,
                "label": "Leopoldina",
                "label_ar": "ليوبولدينا",
                "label_fr": "Léopoldina"
            },
            {
                "value": 70282,
                "label": "Marataizes",
                "label_ar": "ماراتيزيس",
                "label_fr": "Marataizes"
            },
            {
                "value": 72001,
                "label": "Picada",
                "label_ar": "بيكادا",
                "label_fr": "Picada"
            },
            {
                "value": 70702,
                "label": "Osorio",
                "label_ar": "أوسوريو",
                "label_fr": "Osorio"
            },
            {
                "value": 70831,
                "label": "Feira de Santana",
                "label_ar": "فييرا دي سانتانا",
                "label_fr": "Feira de Santana"
            },
            {
                "value": 71155,
                "label": "Taquaritinga",
                "label_ar": "تاكواريتينجا",
                "label_fr": "Taquaritinga"
            },
            {
                "value": 71830,
                "label": "Oliveira",
                "label_ar": "أوليفيرا",
                "label_fr": "Oliveira"
            },
            {
                "value": 70777,
                "label": "Curitibanos",
                "label_ar": "كوريتيبانوس",
                "label_fr": "Curitibanos"
            },
            {
                "value": 71683,
                "label": "Uibai",
                "label_ar": "Uibai",
                "label_fr": "Uibai"
            },
            {
                "value": 71270,
                "label": "Ribeirao",
                "label_ar": "ريبيراو",
                "label_fr": "Ribeirao"
            },
            {
                "value": 71720,
                "label": "Areado",
                "label_ar": "Areado",
                "label_fr": "Areado"
            },
            {
                "value": 70037,
                "label": "Cuiabá",
                "label_ar": "كويابا",
                "label_fr": "Cuiabá"
            },
            {
                "value": 71603,
                "label": "Bananal",
                "label_ar": "الموز",
                "label_fr": "Bananal"
            },
            {
                "value": 70908,
                "label": "Miguelopolis",
                "label_ar": "ميغيلوبوليس",
                "label_fr": "Miguelopolis"
            },
            {
                "value": 71387,
                "label": "Alagoa Nova",
                "label_ar": "ألاغوا نوفا",
                "label_fr": "Alagoa Nova"
            },
            {
                "value": 70694,
                "label": "Sao Paulo do Potengi",
                "label_ar": "ساو باولو دو بوتنجي",
                "label_fr": "Sao Paulo do Potengi"
            },
            {
                "value": 70667,
                "label": "Aracoiaba da Serra",
                "label_ar": "Aracoiaba دا سيرا",
                "label_fr": "Aracoiaba da Serra"
            },
            {
                "value": 71412,
                "label": "Mucuri",
                "label_ar": "موكوري",
                "label_fr": "Mucuri"
            },
            {
                "value": 70839,
                "label": "Nova Lima",
                "label_ar": "نوفا ليما",
                "label_fr": "Nova Lima"
            },
            {
                "value": 71215,
                "label": "Tupanatinga",
                "label_ar": "توباناتينجا",
                "label_fr": "Tupanatinga"
            },
            {
                "value": 71746,
                "label": "Boqueirao",
                "label_ar": "بوكيراو",
                "label_fr": "Boqueirao"
            },
            {
                "value": 70051,
                "label": "Forquilhinha",
                "label_ar": "Forquilhinha",
                "label_fr": "Forquilhinha"
            },
            {
                "value": 72073,
                "label": "Palmeira dos Indios",
                "label_ar": "بالميرا دوس إنديوس",
                "label_fr": "Palmeira dos Indios"
            },
            {
                "value": 71866,
                "label": "Aparecida do Rio Negro",
                "label_ar": "أباريسيدا دو ريو نيغرو",
                "label_fr": "Aparecida do Rio Negro"
            },
            {
                "value": 71355,
                "label": "Arroio do Meio",
                "label_ar": "أرويو دو ميو",
                "label_fr": "Arroio do Meio"
            },
            {
                "value": 71328,
                "label": "Sao Luis de Montes Belos",
                "label_ar": "ساو لويس دي مونتيس بيلوس",
                "label_fr": "Sao Luis de Montes Belos"
            },
            {
                "value": 71159,
                "label": "Guaicara",
                "label_ar": "Guaicara",
                "label_fr": "Guaicara"
            },
            {
                "value": 71581,
                "label": "Alto Paraiso de Goias",
                "label_ar": "ألتو بارايسو دي جوياس",
                "label_fr": "Alto Paraiso de Goias"
            },
            {
                "value": 72017,
                "label": "Sao Sebastiao",
                "label_ar": "ساو سيباستياو",
                "label_fr": "Sao Sebastiao"
            },
            {
                "value": 71087,
                "label": "Tabatinga",
                "label_ar": "تاباتينجا",
                "label_fr": "Tabatinga"
            },
            {
                "value": 71377,
                "label": "Mamonas",
                "label_ar": "ماموناس",
                "label_fr": "Mamonas"
            },
            {
                "value": 70546,
                "label": "São Luís",
                "label_ar": "ساو لويس",
                "label_fr": "São Luís"
            },
            {
                "value": 71281,
                "label": "Neopolis",
                "label_ar": "نيوبوليس",
                "label_fr": "Neopolis"
            },
            {
                "value": 70969,
                "label": "Jaboticatubas",
                "label_ar": "جابوتيكاتوباس",
                "label_fr": "Jaboticatubas"
            },
            {
                "value": 71338,
                "label": "Floriano",
                "label_ar": "فلوريانو",
                "label_fr": "Floriano"
            },
            {
                "value": 70096,
                "label": "Viamão",
                "label_ar": "فياماو",
                "label_fr": "Viamão"
            },
            {
                "value": 71091,
                "label": "Juru",
                "label_ar": "جورو",
                "label_fr": "Juru"
            },
            {
                "value": 71083,
                "label": "Barra do Piraí",
                "label_ar": "بارا دو بيري",
                "label_fr": "Barra do Piraí"
            },
            {
                "value": 71725,
                "label": "Bonfim",
                "label_ar": "بونفيم",
                "label_fr": "Bonfim"
            },
            {
                "value": 71410,
                "label": "Nanuque",
                "label_ar": "نانوك",
                "label_fr": "Nanuque"
            },
            {
                "value": 71497,
                "label": "Fartura",
                "label_ar": "فرطورة",
                "label_fr": "Fartura"
            },
            {
                "value": 71340,
                "label": "Marcolandia",
                "label_ar": "ماركولانديا",
                "label_fr": "Marcolandia"
            },
            {
                "value": 71303,
                "label": "Pedro Avelino",
                "label_ar": "بيدرو أفلينو",
                "label_fr": "Pedro Avelino"
            },
            {
                "value": 71824,
                "label": "Parnamirim",
                "label_ar": "بارنامريم",
                "label_fr": "Parnamirim"
            },
            {
                "value": 70950,
                "label": "Afonso Claudio",
                "label_ar": "أفونسو كلاوديو",
                "label_fr": "Afonso Claudio"
            },
            {
                "value": 70604,
                "label": "Aracati",
                "label_ar": "أراكاتي",
                "label_fr": "Aracati"
            },
            {
                "value": 70913,
                "label": "Capinopolis",
                "label_ar": "كابينوبوليس",
                "label_fr": "Capinopolis"
            },
            {
                "value": 71448,
                "label": "Santa Maria de Itabira",
                "label_ar": "سانتا ماريا دي إيتابيرا",
                "label_fr": "Santa Maria de Itabira"
            },
            {
                "value": 70732,
                "label": "Sao Miguel d'Oeste",
                "label_ar": "ساو ميغيل دي أويستي",
                "label_fr": "Sao Miguel d'Oeste"
            },
            {
                "value": 70981,
                "label": "Aracai",
                "label_ar": "أراكاي",
                "label_fr": "Aracai"
            },
            {
                "value": 71630,
                "label": "Riacho Fundo",
                "label_ar": "رياتشو فوندو",
                "label_fr": "Riacho Fundo"
            },
            {
                "value": 70608,
                "label": "Ribeirão das Neves",
                "label_ar": "ريبيراو داس نيفيس",
                "label_fr": "Ribeirão das Neves"
            },
            {
                "value": 70417,
                "label": "Itapetininga",
                "label_ar": "إيتابيتينين أ",
                "label_fr": "Itapetininga"
            },
            {
                "value": 71813,
                "label": "Muniz Freire",
                "label_ar": "مونيز فريري",
                "label_fr": "Muniz Freire"
            },
            {
                "value": 70553,
                "label": "Ponte Nova",
                "label_ar": "بونتي نوفا",
                "label_fr": "Ponte Nova"
            },
            {
                "value": 70683,
                "label": "Prudentopolis",
                "label_ar": "برودينتوبوليس",
                "label_fr": "Prudentopolis"
            },
            {
                "value": 72093,
                "label": "Bangu",
                "label_ar": "بانغو",
                "label_fr": "Bangu"
            },
            {
                "value": 71128,
                "label": "Ivoti",
                "label_ar": "إيفوتي",
                "label_fr": "Ivoti"
            },
            {
                "value": 70390,
                "label": "Itanhomi",
                "label_ar": "إيتانهوم",
                "label_fr": "Itanhomi"
            },
            {
                "value": 71390,
                "label": "Gurupi",
                "label_ar": "جوربي",
                "label_fr": "Gurupi"
            },
            {
                "value": 70249,
                "label": "Extrema",
                "label_ar": "إكستريما",
                "label_fr": "Extrema"
            },
            {
                "value": 70359,
                "label": "Uchoa",
                "label_ar": "أوتشوا",
                "label_fr": "Uchoa"
            },
            {
                "value": 71665,
                "label": "Vespasiano Correa",
                "label_ar": "فيسباسيانو كوريا",
                "label_fr": "Vespasiano Correa"
            },
            {
                "value": 71389,
                "label": "Ribeira do Pombal",
                "label_ar": "ريبيرا دو بومبال",
                "label_fr": "Ribeira do Pombal"
            },
            {
                "value": 70954,
                "label": "Aloandia",
                "label_ar": "ألونديا",
                "label_fr": "Aloandia"
            },
            {
                "value": 71843,
                "label": "Itapissuma",
                "label_ar": "إيتابيسوما",
                "label_fr": "Itapissuma"
            },
            {
                "value": 71743,
                "label": "Serra Preta",
                "label_ar": "سيرا بريتا",
                "label_fr": "Serra Preta"
            },
            {
                "value": 71734,
                "label": "Tuparendi",
                "label_ar": "توباريندي",
                "label_fr": "Tuparendi"
            },
            {
                "value": 70214,
                "label": "Guarulhos",
                "label_ar": "جوارولوس",
                "label_fr": "Guarulhos"
            },
            {
                "value": 71635,
                "label": "Itaituba",
                "label_ar": "إيتايتوبا",
                "label_fr": "Itaituba"
            },
            {
                "value": 70711,
                "label": "Maxaranguape",
                "label_ar": "ماكسارانغوابي",
                "label_fr": "Maxaranguape"
            },
            {
                "value": 70897,
                "label": "Ribeirao Corrente",
                "label_ar": "ريبيراو كورينتي",
                "label_fr": "Ribeirao Corrente"
            },
            {
                "value": 70905,
                "label": "Sales Oliveira",
                "label_ar": "مبيعات أوليفيرا",
                "label_fr": "Ventes Oliveira"
            },
            {
                "value": 72087,
                "label": "Breves",
                "label_ar": "بريفيس",
                "label_fr": "Breves"
            },
            {
                "value": 71348,
                "label": "Urupes",
                "label_ar": "أوروبس",
                "label_fr": "Urupes"
            },
            {
                "value": 71752,
                "label": "Muzambinho",
                "label_ar": "موزامبينيو",
                "label_fr": "Muzambinho"
            },
            {
                "value": 71220,
                "label": "Itapipoca",
                "label_ar": "إيتابيبوكا",
                "label_fr": "Itapipoca"
            },
            {
                "value": 71695,
                "label": "Nova Independencia",
                "label_ar": "نوفا إندبيندينسيا",
                "label_fr": "Nova Independencia"
            },
            {
                "value": 71335,
                "label": "Machados",
                "label_ar": "ماتشادوس",
                "label_fr": "Machados"
            },
            {
                "value": 71100,
                "label": "Penapolis",
                "label_ar": "بينابوليس",
                "label_fr": "Penapolis"
            },
            {
                "value": 71974,
                "label": "Ceu Azul",
                "label_ar": "سيو ازول",
                "label_fr": "Ceu Azul"
            },
            {
                "value": 71936,
                "label": "Monte Siao",
                "label_ar": "مونتي سياو",
                "label_fr": "Monte Siao"
            },
            {
                "value": 71207,
                "label": "Viana",
                "label_ar": "فيانا",
                "label_fr": "Viana"
            },
            {
                "value": 70392,
                "label": "Monteiro",
                "label_ar": "مونتيرو",
                "label_fr": "Monteiro"
            },
            {
                "value": 70502,
                "label": "Palotina",
                "label_ar": "بالوتينا",
                "label_fr": "Palotina"
            },
            {
                "value": 71710,
                "label": "Jacobina",
                "label_ar": "جاكوبينا",
                "label_fr": "Jacobina"
            },
            {
                "value": 70334,
                "label": "Estancia Velha",
                "label_ar": "إستانسيا فيلها",
                "label_fr": "Estancia Velha"
            },
            {
                "value": 70308,
                "label": "Balsas",
                "label_ar": "بلساس",
                "label_fr": "Balsas"
            },
            {
                "value": 70074,
                "label": "Eldorado",
                "label_ar": "إلدورادو",
                "label_fr": "Eldorado"
            },
            {
                "value": 70671,
                "label": "Santa Luzia",
                "label_ar": "سانتا لوزيا",
                "label_fr": "Santa Luzia"
            },
            {
                "value": 71859,
                "label": "Lauro",
                "label_ar": "لاورو",
                "label_fr": "Lauro"
            },
            {
                "value": 71266,
                "label": "Irece",
                "label_ar": "ايريس",
                "label_fr": "Irece"
            },
            {
                "value": 71200,
                "label": "Novo Mundo",
                "label_ar": "نوفو موندو",
                "label_fr": "Novo Mundo"
            },
            {
                "value": 70936,
                "label": "Piraquara",
                "label_ar": "بيراكوارا",
                "label_fr": "Piraquara"
            },
            {
                "value": 71880,
                "label": "Marituba",
                "label_ar": "ماريتوبا",
                "label_fr": "Marituba"
            },
            {
                "value": 71891,
                "label": "Itatira",
                "label_ar": "إيتاتيرا",
                "label_fr": "Itatira"
            },
            {
                "value": 70004,
                "label": "Sorocaba",
                "label_ar": "سوروكابا",
                "label_fr": "Sorocaba"
            },
            {
                "value": 70033,
                "label": "Cacoal",
                "label_ar": "كاكوال",
                "label_fr": "Cacoal"
            },
            {
                "value": 70386,
                "label": "Corguinho",
                "label_ar": "كورغينو",
                "label_fr": "Corguinho"
            },
            {
                "value": 71569,
                "label": "Mirinzal",
                "label_ar": "ميرينزال",
                "label_fr": "Mirinzal"
            },
            {
                "value": 71379,
                "label": "Pedra Bonita",
                "label_ar": "بيدرا بونيتا",
                "label_fr": "Pedra Bonita"
            },
            {
                "value": 70930,
                "label": "Ipojuca",
                "label_ar": "إيبوجوكا",
                "label_fr": "Ipojuca"
            },
            {
                "value": 71516,
                "label": "Dois Vizinhos",
                "label_ar": "دويس فيزينهوس",
                "label_fr": "Dois Vizinhos"
            },
            {
                "value": 70947,
                "label": "Rio do Pires",
                "label_ar": "ريو دو بيريس",
                "label_fr": "Rio do Pires"
            },
            {
                "value": 72085,
                "label": "Palmelo",
                "label_ar": "بالميلو",
                "label_fr": "Palmelo"
            },
            {
                "value": 70538,
                "label": "Tabuleiro do Norte",
                "label_ar": "تابوليرو دو نورتي",
                "label_fr": "Tabuleiro do Norte"
            },
            {
                "value": 71573,
                "label": "Pinhalzinho",
                "label_ar": "بينهالتزينيو",
                "label_fr": "Pinhalzinho"
            },
            {
                "value": 71052,
                "label": "Coroados",
                "label_ar": "كوراودوس",
                "label_fr": "Coroados"
            },
            {
                "value": 70373,
                "label": "Ipiranga",
                "label_ar": "إيبيرانجا",
                "label_fr": "Ipiranga"
            },
            {
                "value": 70368,
                "label": "Itapua",
                "label_ar": "إيتابوا",
                "label_fr": "Itapua"
            },
            {
                "value": 70326,
                "label": "Tijuca",
                "label_ar": "تيجوكا",
                "label_fr": "Tijuca"
            },
            {
                "value": 70108,
                "label": "Santo",
                "label_ar": "سانتو",
                "label_fr": "Santo"
            },
            {
                "value": 71005,
                "label": "Alegre",
                "label_ar": "أليغري",
                "label_fr": "Alegre"
            },
            {
                "value": 71182,
                "label": "Amazonas",
                "label_ar": "أمازوناس",
                "label_fr": "Amazonas"
            },
            {
                "value": 71179,
                "label": "Lagoa do Carro",
                "label_ar": "لاجوا دو كارو",
                "label_fr": "Lagoa do Carro"
            },
            {
                "value": 71070,
                "label": "Cabo Frio",
                "label_ar": "كابو فريو",
                "label_fr": "Cabo Frio"
            },
            {
                "value": 71119,
                "label": "Arceburgo",
                "label_ar": "Arceburgo",
                "label_fr": "Arceburgo"
            },
            {
                "value": 70264,
                "label": "Caxambu",
                "label_ar": "كاكسامبو",
                "label_fr": "Caxambu"
            },
            {
                "value": 71699,
                "label": "Dourado",
                "label_ar": "دورادو",
                "label_fr": "Dourado"
            },
            {
                "value": 71799,
                "label": "Mandirituba",
                "label_ar": "مانديريتوبا",
                "label_fr": "Mandirituba"
            }
        ]
    },
    {
        "country": "Poland",
        "cities": [
            {
                "value": 73447,
                "label": "Wrocław",
                "label_ar": "فروتسواف",
                "label_fr": "Wrocław"
            },
            {
                "value": 73448,
                "label": "Warsaw",
                "label_ar": "وارسو",
                "label_fr": "Varsovie"
            },
            {
                "value": 73449,
                "label": "Elzbieta",
                "label_ar": "الزبيطة",
                "label_fr": "Elzbieta"
            },
            {
                "value": 73450,
                "label": "Debica",
                "label_ar": "ديبيكا",
                "label_fr": "Debica"
            },
            {
                "value": 73451,
                "label": "Sedziszow Malopolski",
                "label_ar": "Sedziszow Malopolski",
                "label_fr": "Sedziszow Malopolski"
            },
            {
                "value": 73452,
                "label": "Dabki",
                "label_ar": "الدبكي",
                "label_fr": "Dabki"
            },
            {
                "value": 73453,
                "label": "Sławno",
                "label_ar": "Sławno",
                "label_fr": "Sławno"
            },
            {
                "value": 73454,
                "label": "Gorlice",
                "label_ar": "غورليس",
                "label_fr": "Gorlice"
            },
            {
                "value": 73455,
                "label": "Wola Rebkowska",
                "label_ar": "وولا ريبكوسكا",
                "label_fr": "Wola Rebkowska"
            },
            {
                "value": 73456,
                "label": "Cierpice",
                "label_ar": "سيربيس",
                "label_fr": "Cierpice"
            },
            {
                "value": 73457,
                "label": "Mińsk Mazowiecki",
                "label_ar": "Mińsk Mazowiecki",
                "label_fr": "Mińsk Mazowiecki"
            },
            {
                "value": 73458,
                "label": "Zabia Wola",
                "label_ar": "زابيا وولا",
                "label_fr": "Zabia Wola"
            },
            {
                "value": 73459,
                "label": "Kampinos",
                "label_ar": "كامبينوس",
                "label_fr": "Kampinos"
            },
            {
                "value": 73460,
                "label": "Krakow",
                "label_ar": "كراكوف",
                "label_fr": "Cracovie"
            },
            {
                "value": 73461,
                "label": "Olesnica",
                "label_ar": "Olesnica",
                "label_fr": "Olesnica"
            },
            {
                "value": 73462,
                "label": "Pruszków",
                "label_ar": "Pruszków",
                "label_fr": "Pruszków"
            },
            {
                "value": 73463,
                "label": "Marek",
                "label_ar": "ماريك",
                "label_fr": "Marek"
            },
            {
                "value": 73464,
                "label": "Grupa",
                "label_ar": "جروبا",
                "label_fr": "Grupa"
            },
            {
                "value": 73465,
                "label": "Szczecin",
                "label_ar": "شتشيتسين",
                "label_fr": "Szczecin"
            },
            {
                "value": 73466,
                "label": "Kielce",
                "label_ar": "كيلسي",
                "label_fr": "Kielce"
            },
            {
                "value": 73467,
                "label": "Warzachewka Polska",
                "label_ar": "Warzachewka بولسكا",
                "label_fr": "Warzachewka Polska"
            },
            {
                "value": 73468,
                "label": "Olsztyn",
                "label_ar": "أولشتين",
                "label_fr": "Olsztyn"
            },
            {
                "value": 73469,
                "label": "Ełk",
                "label_ar": "Ek",
                "label_fr": "Wapiti"
            },
            {
                "value": 73470,
                "label": "Golasowice",
                "label_ar": "جولاسوويتشي",
                "label_fr": "Golasowice"
            },
            {
                "value": 73471,
                "label": "Katowice",
                "label_ar": "كاتوفيتشي",
                "label_fr": "Katowice"
            },
            {
                "value": 73472,
                "label": "Mysłowice",
                "label_ar": "ميسوفيتسي",
                "label_fr": "Mysłowice"
            },
            {
                "value": 73473,
                "label": "Zawiercie",
                "label_ar": "Zawiercie",
                "label_fr": "Zawiercie"
            },
            {
                "value": 73474,
                "label": "Chorzów",
                "label_ar": "شورزو",
                "label_fr": "Chorzów"
            },
            {
                "value": 73475,
                "label": "Gracze",
                "label_ar": "جراتز",
                "label_fr": "Gracze"
            },
            {
                "value": 73476,
                "label": "Częstochowa",
                "label_ar": "شيستوشوا",
                "label_fr": "Częstochowa"
            },
            {
                "value": 73477,
                "label": "Wadowice",
                "label_ar": "فادوفيتسي",
                "label_fr": "Wadowice"
            },
            {
                "value": 73478,
                "label": "Radom",
                "label_ar": "رادوم",
                "label_fr": "Radom"
            },
            {
                "value": 73479,
                "label": "Raszyn",
                "label_ar": "رازين",
                "label_fr": "Raszyn"
            },
            {
                "value": 73480,
                "label": "Tarnogrod",
                "label_ar": "تارنوجرود",
                "label_fr": "Tarnogrod"
            },
            {
                "value": 73481,
                "label": "Rzewnie",
                "label_ar": "Rzewnie",
                "label_fr": "Rzewnie"
            },
            {
                "value": 73482,
                "label": "Białystok",
                "label_ar": "بياليستوك",
                "label_fr": "Białystok"
            },
            {
                "value": 73483,
                "label": "Otwock",
                "label_ar": "اوتووك",
                "label_fr": "Otwock"
            },
            {
                "value": 73484,
                "label": "Garwolin",
                "label_ar": "جارولين",
                "label_fr": "Garwolin"
            },
            {
                "value": 73485,
                "label": "Borowiec",
                "label_ar": "بوروويك",
                "label_fr": "Borowiec"
            },
            {
                "value": 73486,
                "label": "Gliwice",
                "label_ar": "جليفيس",
                "label_fr": "Gliwice"
            },
            {
                "value": 73487,
                "label": "Gdańsk",
                "label_ar": "غدانسك",
                "label_fr": "Gdańsk"
            },
            {
                "value": 73488,
                "label": "Wielki Klincz",
                "label_ar": "ويلكي كلينكز",
                "label_fr": "Wielki Klincz"
            },
            {
                "value": 73489,
                "label": "Debina",
                "label_ar": "ديبينا",
                "label_fr": "Debina"
            },
            {
                "value": 73490,
                "label": "Sedziszow",
                "label_ar": "Sedziszow",
                "label_fr": "Sedziszow"
            },
            {
                "value": 73491,
                "label": "Gozdowo",
                "label_ar": "Gozdowo",
                "label_fr": "Gozdowo"
            },
            {
                "value": 73492,
                "label": "Lublin",
                "label_ar": "لوبلين",
                "label_fr": "Lublin"
            },
            {
                "value": 73493,
                "label": "Radoslaw",
                "label_ar": "رادوسلاف",
                "label_fr": "Radoslaw"
            },
            {
                "value": 73494,
                "label": "Czersk",
                "label_ar": "تشيرسك",
                "label_fr": "Czersk"
            },
            {
                "value": 73495,
                "label": "Skoczow",
                "label_ar": "سكوتشوف",
                "label_fr": "Skoczow"
            },
            {
                "value": 73496,
                "label": "Jozefow",
                "label_ar": "جوزيفو",
                "label_fr": "Jozefow"
            },
            {
                "value": 73497,
                "label": "Sopot",
                "label_ar": "سوبوت",
                "label_fr": "Sopot"
            },
            {
                "value": 73498,
                "label": "Nowe Lignowy",
                "label_ar": "Nowe Lignowy",
                "label_fr": "Nowe Lignowy"
            },
            {
                "value": 73499,
                "label": "Poznan",
                "label_ar": "بوزنان",
                "label_fr": "Poznan"
            },
            {
                "value": 73500,
                "label": "Gdynia",
                "label_ar": "جدينيا",
                "label_fr": "Gdynia"
            },
            {
                "value": 73501,
                "label": "Limanowa",
                "label_ar": "ليمانوا",
                "label_fr": "Limanowa"
            },
            {
                "value": 73502,
                "label": "Chociwel",
                "label_ar": "تشوسيويل",
                "label_fr": "Chociwel"
            },
            {
                "value": 73503,
                "label": "Grzmucin",
                "label_ar": "جرزموسين",
                "label_fr": "Grzmucin"
            },
            {
                "value": 73504,
                "label": "Lubicz",
                "label_ar": "لوبيتش",
                "label_fr": "Lubicz"
            },
            {
                "value": 73505,
                "label": "Celestynów",
                "label_ar": "سيليستينوف",
                "label_fr": "Celestynów"
            },
            {
                "value": 73506,
                "label": "Siemianowice Śląskie",
                "label_ar": "سيميانوفيتسي سلاسكي",
                "label_fr": "Siemianowice Śląskie"
            },
            {
                "value": 73507,
                "label": "Lidzbark",
                "label_ar": "ليدزبارك",
                "label_fr": "Lidzbark"
            },
            {
                "value": 73508,
                "label": "Sulejowek",
                "label_ar": "سوليجويك",
                "label_fr": "Sulejowek"
            },
            {
                "value": 73509,
                "label": "Biedrusko",
                "label_ar": "بيدروسكو",
                "label_fr": "Biedrusko"
            },
            {
                "value": 73510,
                "label": "Malbork",
                "label_ar": "مالبورك",
                "label_fr": "Malbork"
            },
            {
                "value": 73511,
                "label": "Chelm",
                "label_ar": "شيلم",
                "label_fr": "Chelm"
            },
            {
                "value": 73512,
                "label": "Chełm",
                "label_ar": "Chełm",
                "label_fr": "Chełm"
            },
            {
                "value": 73513,
                "label": "Kwidzyn",
                "label_ar": "كويدزين",
                "label_fr": "Kwidzyn"
            },
            {
                "value": 73514,
                "label": "Mielec",
                "label_ar": "ميليك",
                "label_fr": "Mielec"
            },
            {
                "value": 73515,
                "label": "Chorzelow",
                "label_ar": "كورزيلو",
                "label_fr": "Chorzelow"
            },
            {
                "value": 73516,
                "label": "Rzeszów",
                "label_ar": "رزيسزو",
                "label_fr": "Rzeszów"
            },
            {
                "value": 73517,
                "label": "Świdnica",
                "label_ar": "Świdnica",
                "label_fr": "Świdnica"
            },
            {
                "value": 73518,
                "label": "Przeclaw",
                "label_ar": "برزكلاو",
                "label_fr": "Przeclaw"
            },
            {
                "value": 73519,
                "label": "Suwałki",
                "label_ar": "Suwałki",
                "label_fr": "Suwałki"
            },
            {
                "value": 73520,
                "label": "Wejherowo",
                "label_ar": "Wejherowo",
                "label_fr": "Wejherowo"
            },
            {
                "value": 73521,
                "label": "Puck",
                "label_ar": "عفريت",
                "label_fr": "Palet"
            },
            {
                "value": 73522,
                "label": "Rumia",
                "label_ar": "روميا",
                "label_fr": "Rumia"
            },
            {
                "value": 73523,
                "label": "Tomaszow",
                "label_ar": "تومازو",
                "label_fr": "Tomaszow"
            },
            {
                "value": 73524,
                "label": "Golub-Dobrzyn",
                "label_ar": "جولوب دوبرزين",
                "label_fr": "Golub-Dobrzyn"
            },
            {
                "value": 73525,
                "label": "Bielsko-Biala",
                "label_ar": "بيلسكو بيالا",
                "label_fr": "Bielsko-Biala"
            },
            {
                "value": 73526,
                "label": "Bydgoszcz",
                "label_ar": "بيدغوشتش",
                "label_fr": "Bydgoszcz"
            },
            {
                "value": 73527,
                "label": "Bielawa",
                "label_ar": "بييلاوا",
                "label_fr": "Bielawa"
            },
            {
                "value": 73528,
                "label": "Polanica-Zdrój",
                "label_ar": "بولانيكا زدروي",
                "label_fr": "Polanica-Zdrój"
            },
            {
                "value": 73529,
                "label": "Lebork",
                "label_ar": "ليبورك",
                "label_fr": "Lebork"
            },
            {
                "value": 73530,
                "label": "Stegna",
                "label_ar": "ستيجنا",
                "label_fr": "Stegna"
            },
            {
                "value": 73531,
                "label": "Nakło nad Notecią",
                "label_ar": "Nakło nad Notecią",
                "label_fr": "Nakło nad Notecią"
            },
            {
                "value": 73532,
                "label": "Koscierzyna",
                "label_ar": "كوسيرزينا",
                "label_fr": "Koscierzyna"
            },
            {
                "value": 73533,
                "label": "Jelenia Góra",
                "label_ar": "جيلينيا جورا",
                "label_fr": "Jelenia Góra"
            },
            {
                "value": 73534,
                "label": "Ostrołęka",
                "label_ar": "أوستروكا",
                "label_fr": "Ostrołęka"
            },
            {
                "value": 73535,
                "label": "Nowa Ruda",
                "label_ar": "نووا رودا",
                "label_fr": "Nowa Ruda"
            },
            {
                "value": 73536,
                "label": "Libiaz",
                "label_ar": "ليبياز",
                "label_fr": "Libiaz"
            },
            {
                "value": 73537,
                "label": "Młynary",
                "label_ar": "ميناري",
                "label_fr": "Młynary"
            },
            {
                "value": 73538,
                "label": "Oświęcim",
                "label_ar": "Oświcim",
                "label_fr": "Oświęcim"
            },
            {
                "value": 73539,
                "label": "Jarosław",
                "label_ar": "ياروسلاف",
                "label_fr": "Jarosław"
            },
            {
                "value": 73540,
                "label": "Koszalin",
                "label_ar": "كوسزالين",
                "label_fr": "Koszalin"
            },
            {
                "value": 73541,
                "label": "Zambrów",
                "label_ar": "زامبرو",
                "label_fr": "Zambrów"
            },
            {
                "value": 73542,
                "label": "Lukow",
                "label_ar": "لوكوف",
                "label_fr": "Lukow"
            },
            {
                "value": 73543,
                "label": "Wisniew",
                "label_ar": "ويسنيو",
                "label_fr": "Wisniew"
            },
            {
                "value": 73544,
                "label": "Wieluń",
                "label_ar": "فييلو",
                "label_fr": "Wieluń"
            },
            {
                "value": 73545,
                "label": "Łódź",
                "label_ar": "وودج",
                "label_fr": "Łódź"
            },
            {
                "value": 73546,
                "label": "Gorzów Wielkopolski",
                "label_ar": "غورزو فييلكوبولسكي",
                "label_fr": "Gorzów Wielkopolski"
            },
            {
                "value": 73547,
                "label": "Teofilow",
                "label_ar": "توفيلو",
                "label_fr": "Teofilow"
            },
            {
                "value": 73548,
                "label": "Koniecpol",
                "label_ar": "كونيكبول",
                "label_fr": "Koniecpol"
            },
            {
                "value": 73549,
                "label": "Klomnice",
                "label_ar": "كلومنيتسي",
                "label_fr": "Klomnice"
            },
            {
                "value": 73550,
                "label": "Kruszyna",
                "label_ar": "كروسزينا",
                "label_fr": "Kruszyna"
            },
            {
                "value": 73551,
                "label": "Zawada",
                "label_ar": "زوادة",
                "label_fr": "Zawada"
            },
            {
                "value": 73552,
                "label": "Huta Stara",
                "label_ar": "هوتا ستارا",
                "label_fr": "Huta Stara"
            },
            {
                "value": 73553,
                "label": "Praszka",
                "label_ar": "براسزكا",
                "label_fr": "Praszka"
            },
            {
                "value": 73554,
                "label": "Czeladz",
                "label_ar": "تشيلادز",
                "label_fr": "Czeladz"
            },
            {
                "value": 73555,
                "label": "Stalowa Wola",
                "label_ar": "ستالوا وولا",
                "label_fr": "Stalowa Wola"
            },
            {
                "value": 73556,
                "label": "Suchowola",
                "label_ar": "سوتشولا",
                "label_fr": "Suchowola"
            },
            {
                "value": 73557,
                "label": "Zabrze",
                "label_ar": "زبرزي",
                "label_fr": "Zabrze"
            },
            {
                "value": 73558,
                "label": "Opole",
                "label_ar": "أوبول",
                "label_fr": "Opole"
            },
            {
                "value": 73559,
                "label": "Wałbrzych",
                "label_ar": "ويبرزيش",
                "label_fr": "Wałbrzych"
            },
            {
                "value": 73560,
                "label": "Linowko",
                "label_ar": "لينوكو",
                "label_fr": "Linowko"
            },
            {
                "value": 73561,
                "label": "Witkowo",
                "label_ar": "Witkowo",
                "label_fr": "Witkowo"
            },
            {
                "value": 73562,
                "label": "Ranizow",
                "label_ar": "رانيزو",
                "label_fr": "Ranizow"
            },
            {
                "value": 73563,
                "label": "Nowy Tomysl",
                "label_ar": "Nowy Tomysl",
                "label_fr": "Nowy Tomysl"
            },
            {
                "value": 73564,
                "label": "Kornik",
                "label_ar": "كورنيك",
                "label_fr": "Kornik"
            },
            {
                "value": 73565,
                "label": "Dolice",
                "label_ar": "دولتشي",
                "label_fr": "Dolice"
            },
            {
                "value": 73566,
                "label": "Zawady",
                "label_ar": "زوادي",
                "label_fr": "Zawady"
            },
            {
                "value": 73567,
                "label": "Miłomłyn",
                "label_ar": "ميومين",
                "label_fr": "Miłomłyn"
            },
            {
                "value": 73568,
                "label": "Barciany",
                "label_ar": "Barciany",
                "label_fr": "Barciany"
            },
            {
                "value": 73569,
                "label": "Biskupiec",
                "label_ar": "بيسكوبيك",
                "label_fr": "Biskupiec"
            },
            {
                "value": 73570,
                "label": "Bytom",
                "label_ar": "بايتوم",
                "label_fr": "Bytom"
            },
            {
                "value": 73571,
                "label": "Radostowice",
                "label_ar": "رادوستوفيتسي",
                "label_fr": "Radostowice"
            },
            {
                "value": 73572,
                "label": "Przeworsk",
                "label_ar": "برزيورسك",
                "label_fr": "Przeworsk"
            },
            {
                "value": 73573,
                "label": "Szowsko",
                "label_ar": "Szowsko",
                "label_fr": "Szowsko"
            },
            {
                "value": 73574,
                "label": "Radymno",
                "label_ar": "راديمنو",
                "label_fr": "Radymno"
            },
            {
                "value": 73575,
                "label": "Trzebownisko",
                "label_ar": "Trzebownisko",
                "label_fr": "Trzebownisko"
            },
            {
                "value": 73576,
                "label": "Strazow",
                "label_ar": "سترازوف",
                "label_fr": "Strazow"
            },
            {
                "value": 73577,
                "label": "Sokolow Malopolski",
                "label_ar": "سوكولوف مالوبولسكي",
                "label_fr": "Sokolow Malopolski"
            },
            {
                "value": 73578,
                "label": "Wysoka Strzyzowska",
                "label_ar": "Wysoka Strzyzowska",
                "label_fr": "Wysoka Strzyzowska"
            },
            {
                "value": 73579,
                "label": "Ogrody",
                "label_ar": "اوغرودي",
                "label_fr": "Ogrody"
            },
            {
                "value": 73580,
                "label": "Siedlce",
                "label_ar": "سيدلس",
                "label_fr": "Siedlce"
            },
            {
                "value": 73581,
                "label": "Kotuń",
                "label_ar": "Kotuń",
                "label_fr": "Kotuń"
            },
            {
                "value": 73582,
                "label": "Świętochłowice",
                "label_ar": "Świętochłowice",
                "label_fr": "Świętochłowice"
            },
            {
                "value": 73583,
                "label": "Cisiec",
                "label_ar": "سيسيك",
                "label_fr": "Cisiec"
            },
            {
                "value": 73584,
                "label": "Wegierska Gorka",
                "label_ar": "ويجيرسكا جوركا",
                "label_fr": "Wegierska Gorka"
            },
            {
                "value": 73585,
                "label": "Trawniki",
                "label_ar": "تراونيكي",
                "label_fr": "Trawniki"
            },
            {
                "value": 73586,
                "label": "Swidnik",
                "label_ar": "سويدنيك",
                "label_fr": "Swidnik"
            },
            {
                "value": 73587,
                "label": "Giżycko",
                "label_ar": "Giżycko",
                "label_fr": "Giżycko"
            },
            {
                "value": 73588,
                "label": "Mszczonów",
                "label_ar": "Mszczonów",
                "label_fr": "Mszczonów"
            },
            {
                "value": 73589,
                "label": "Kłodawa",
                "label_ar": "كوداوا",
                "label_fr": "Kłodawa"
            },
            {
                "value": 73590,
                "label": "Ząbki",
                "label_ar": "الزوبكي",
                "label_fr": "Ząbki"
            },
            {
                "value": 73591,
                "label": "Radzyń Chełmiński",
                "label_ar": "Radzyń Chełmiński",
                "label_fr": "Radzyń Chełmiński"
            },
            {
                "value": 73592,
                "label": "Bierun",
                "label_ar": "بيرون",
                "label_fr": "Bierun"
            },
            {
                "value": 73593,
                "label": "Sosnowiec",
                "label_ar": "سوسنويك",
                "label_fr": "Sosnowiec"
            },
            {
                "value": 73594,
                "label": "Gorno",
                "label_ar": "جورنو",
                "label_fr": "Gorno"
            },
            {
                "value": 73595,
                "label": "Pabianice",
                "label_ar": "بابيانيس",
                "label_fr": "Pabianice"
            },
            {
                "value": 73596,
                "label": "Rybnik",
                "label_ar": "ريبنيك",
                "label_fr": "Rybnik"
            },
            {
                "value": 73597,
                "label": "Zakopane",
                "label_ar": "زاكوباني",
                "label_fr": "Zakopane"
            },
            {
                "value": 73598,
                "label": "Lubin",
                "label_ar": "لوبين",
                "label_fr": "Lubin"
            },
            {
                "value": 73599,
                "label": "Sulechow",
                "label_ar": "سوليشو",
                "label_fr": "Sulechow"
            },
            {
                "value": 73600,
                "label": "Kargowa",
                "label_ar": "كارجوا",
                "label_fr": "Kargowa"
            },
            {
                "value": 73601,
                "label": "Zielona Góra",
                "label_ar": "زييلونا جورا",
                "label_fr": "Zielona Góra"
            },
            {
                "value": 73602,
                "label": "Szprotawa",
                "label_ar": "سزبروتاوا",
                "label_fr": "Szprotawa"
            },
            {
                "value": 73603,
                "label": "Przylep",
                "label_ar": "برزيلب",
                "label_fr": "Przylep"
            },
            {
                "value": 73604,
                "label": "Czerwiensk",
                "label_ar": "سيزيروينسك",
                "label_fr": "Czerwiensk"
            },
            {
                "value": 73605,
                "label": "Przemysl",
                "label_ar": "برزيميسل",
                "label_fr": "Przemysl"
            },
            {
                "value": 73606,
                "label": "Piotrków Trybunalski",
                "label_ar": "Piotrków تريبونالسكي",
                "label_fr": "Piotrków Trybunalski"
            },
            {
                "value": 73607,
                "label": "Kutno",
                "label_ar": "كوتنو",
                "label_fr": "Kutno"
            },
            {
                "value": 73608,
                "label": "Rabien",
                "label_ar": "ربيع",
                "label_fr": "Rabien"
            },
            {
                "value": 73609,
                "label": "Gadka Stara",
                "label_ar": "جادكا ستارا",
                "label_fr": "Gadka Stara"
            },
            {
                "value": 73610,
                "label": "Tomaszów Mazowiecki",
                "label_ar": "توماشوف مازويكي",
                "label_fr": "Tomaszów Mazowiecki"
            },
            {
                "value": 73611,
                "label": "Sieradz",
                "label_ar": "سيرادز",
                "label_fr": "Sieradz"
            },
            {
                "value": 73612,
                "label": "Bełchatów",
                "label_ar": "Bełchatów",
                "label_fr": "Bełchatów"
            },
            {
                "value": 73613,
                "label": "Koło",
                "label_ar": "كوو",
                "label_fr": "Koło"
            },
            {
                "value": 73614,
                "label": "Poswietne",
                "label_ar": "بوسويتني",
                "label_fr": "Poswietne"
            },
            {
                "value": 73615,
                "label": "Chalupki",
                "label_ar": "تشالوبكي",
                "label_fr": "Chalupki"
            },
            {
                "value": 73616,
                "label": "Janin",
                "label_ar": "جانين",
                "label_fr": "Janin"
            },
            {
                "value": 73617,
                "label": "Braniewo",
                "label_ar": "برانيو",
                "label_fr": "Braniewo"
            },
            {
                "value": 73618,
                "label": "Biała Podlaska",
                "label_ar": "بياوا بودلاسكا",
                "label_fr": "Biała Podlaska"
            },
            {
                "value": 73619,
                "label": "Kamienna Gora",
                "label_ar": "كامينا جورا",
                "label_fr": "Kamienna Gora"
            },
            {
                "value": 73620,
                "label": "Goleniów",
                "label_ar": "Goleniów",
                "label_fr": "Goleniów"
            },
            {
                "value": 73621,
                "label": "Ostrowiec Świętokrzyski",
                "label_ar": "Ostrowiec Świętokrzyski",
                "label_fr": "Ostrowiec Świętokrzyski"
            },
            {
                "value": 73622,
                "label": "Szypliszki",
                "label_ar": "Szypliszki",
                "label_fr": "Szypliszki"
            },
            {
                "value": 73623,
                "label": "Wołomin",
                "label_ar": "Wołomin",
                "label_fr": "Wołomin"
            },
            {
                "value": 73624,
                "label": "Ruda Śląska",
                "label_ar": "رودا Śląska",
                "label_fr": "Ruda Śląska"
            },
            {
                "value": 73625,
                "label": "Słupsk",
                "label_ar": "سوبسك",
                "label_fr": "Słupsk"
            },
            {
                "value": 73626,
                "label": "Budziska",
                "label_ar": "بودزيسكا",
                "label_fr": "Budziska"
            },
            {
                "value": 73627,
                "label": "Tarnowskie Gory",
                "label_ar": "Tarnowskie جوري",
                "label_fr": "Tarnowskie Gory"
            },
            {
                "value": 73628,
                "label": "Police",
                "label_ar": "شرطة",
                "label_fr": "Police"
            },
            {
                "value": 73629,
                "label": "Słubice",
                "label_ar": "سوبيتس",
                "label_fr": "Słubice"
            },
            {
                "value": 73630,
                "label": "Nowy Dwor Mazowiecki",
                "label_ar": "Nowy Dwor Mazowiecki",
                "label_fr": "Nowy Dwor Mazowiecki"
            },
            {
                "value": 73631,
                "label": "Kalisz",
                "label_ar": "كاليش",
                "label_fr": "Kalisz"
            },
            {
                "value": 73632,
                "label": "Międzyrzecz",
                "label_ar": "Międzyrzecz",
                "label_fr": "Międzyrzecz"
            },
            {
                "value": 73633,
                "label": "Wilczyce",
                "label_ar": "ويلكزيز",
                "label_fr": "Wilczyce"
            },
            {
                "value": 73634,
                "label": "Debno",
                "label_ar": "ديبنو",
                "label_fr": "Debno"
            },
            {
                "value": 73635,
                "label": "Puławy",
                "label_ar": "Puławy",
                "label_fr": "Puławy"
            },
            {
                "value": 73636,
                "label": "Tyczyn",
                "label_ar": "تيكزين",
                "label_fr": "Tyczyn"
            },
            {
                "value": 73637,
                "label": "Poreba",
                "label_ar": "بوريبا",
                "label_fr": "Poreba"
            },
            {
                "value": 73638,
                "label": "Piekary",
                "label_ar": "بيكاري",
                "label_fr": "Piekary"
            },
            {
                "value": 73639,
                "label": "Tarnowiec",
                "label_ar": "تارنوفيك",
                "label_fr": "Tarnowiec"
            },
            {
                "value": 73640,
                "label": "Radgoszcz",
                "label_ar": "رادغوز",
                "label_fr": "Radgoszcz"
            },
            {
                "value": 73641,
                "label": "Dąbrowa Górnicza",
                "label_ar": "Dąbrowa Górnicza",
                "label_fr": "Dąbrowa Górnicza"
            },
            {
                "value": 73642,
                "label": "Mikołów",
                "label_ar": "ميكووف",
                "label_fr": "Mikołów"
            },
            {
                "value": 73643,
                "label": "Wieliczka",
                "label_ar": "Wieliczka",
                "label_fr": "Wieliczka"
            },
            {
                "value": 73644,
                "label": "Tychy",
                "label_ar": "تيشي",
                "label_fr": "Tychy"
            },
            {
                "value": 73645,
                "label": "Gorz",
                "label_ar": "جورز",
                "label_fr": "Gorz"
            },
            {
                "value": 73646,
                "label": "Raszowa",
                "label_ar": "Raszowa",
                "label_fr": "Raszowa"
            },
            {
                "value": 73647,
                "label": "Nowy Sącz",
                "label_ar": "Nowy Sącz",
                "label_fr": "Nowy Sącz"
            },
            {
                "value": 73648,
                "label": "Wilkowice",
                "label_ar": "ويلكوفيتسي",
                "label_fr": "Wilkowice"
            },
            {
                "value": 73649,
                "label": "Korytow",
                "label_ar": "كوريتو",
                "label_fr": "Korytow"
            },
            {
                "value": 73650,
                "label": "Fabianki",
                "label_ar": "فابيانكي",
                "label_fr": "Fabianki"
            },
            {
                "value": 73651,
                "label": "Marki",
                "label_ar": "ماركي",
                "label_fr": "Marki"
            },
            {
                "value": 73652,
                "label": "Znin",
                "label_ar": "زنين",
                "label_fr": "Znin"
            },
            {
                "value": 73653,
                "label": "Swarzedz",
                "label_ar": "Swarzedz",
                "label_fr": "Swarzedz"
            },
            {
                "value": 73654,
                "label": "Płock",
                "label_ar": "بوك",
                "label_fr": "Płock"
            },
            {
                "value": 73655,
                "label": "Grudziądz",
                "label_ar": "Grudzidz",
                "label_fr": "Grudziądz"
            },
            {
                "value": 73656,
                "label": "Dzierzazno",
                "label_ar": "Dzierzazno",
                "label_fr": "Dzierzazno"
            },
            {
                "value": 73657,
                "label": "Polkowice",
                "label_ar": "بولكوفيتسي",
                "label_fr": "Polkowice"
            },
            {
                "value": 73658,
                "label": "Modlnica",
                "label_ar": "مودلنيكا",
                "label_fr": "Modlnica"
            },
            {
                "value": 73659,
                "label": "Teresin",
                "label_ar": "تيريسين",
                "label_fr": "Teresin"
            },
            {
                "value": 73660,
                "label": "Sochaczew",
                "label_ar": "سوشاشيو",
                "label_fr": "Sochaczew"
            },
            {
                "value": 73661,
                "label": "Skwierzyna",
                "label_ar": "Skwierzyna",
                "label_fr": "Skwierzyna"
            },
            {
                "value": 73662,
                "label": "Pielice",
                "label_ar": "بيليس",
                "label_fr": "Pielice"
            },
            {
                "value": 73663,
                "label": "Deszczno",
                "label_ar": "Deszczno",
                "label_fr": "Deszczno"
            },
            {
                "value": 73664,
                "label": "Wojciech",
                "label_ar": "فويتشخ",
                "label_fr": "Wojciech"
            },
            {
                "value": 73665,
                "label": "Tarnów",
                "label_ar": "تارنوف",
                "label_fr": "Tarnów"
            },
            {
                "value": 73666,
                "label": "Wyszków",
                "label_ar": "Wyszków",
                "label_fr": "Wyszków"
            },
            {
                "value": 73667,
                "label": "Strzegom",
                "label_ar": "سترزيغوم",
                "label_fr": "Strzegom"
            },
            {
                "value": 73668,
                "label": "Swiebodzice",
                "label_ar": "Swiebodzice",
                "label_fr": "Swiebodzice"
            },
            {
                "value": 73669,
                "label": "Oborniki Śląskie",
                "label_ar": "Oborniki Śląskie",
                "label_fr": "Oborniki Śląskie"
            },
            {
                "value": 73670,
                "label": "Biłgoraj",
                "label_ar": "Biłgoraj",
                "label_fr": "Biłgoraj"
            },
            {
                "value": 73671,
                "label": "Gmina Widawa",
                "label_ar": "جمينا ويداوا",
                "label_fr": "Gmina Widawa"
            },
            {
                "value": 73672,
                "label": "Inowrocław",
                "label_ar": "Inowrocław",
                "label_fr": "Inowrocław"
            },
            {
                "value": 73673,
                "label": "Anin",
                "label_ar": "أنين",
                "label_fr": "Anin"
            },
            {
                "value": 73674,
                "label": "Glowno",
                "label_ar": "جلونو",
                "label_fr": "Glowno"
            },
            {
                "value": 73675,
                "label": "Tłuszcz",
                "label_ar": "Tuszcz",
                "label_fr": "Tłuszcz"
            },
            {
                "value": 73676,
                "label": "Nowe Skalmierzyce",
                "label_ar": "Nowe Skalmierzyce",
                "label_fr": "Nowe Skalmierzyce"
            },
            {
                "value": 73677,
                "label": "Konstancin-Jeziorna",
                "label_ar": "كونستانسين جيزورنا",
                "label_fr": "Konstancin-Jeziorna"
            },
            {
                "value": 73678,
                "label": "Kowalowa",
                "label_ar": "كوالوا",
                "label_fr": "Kowalowa"
            },
            {
                "value": 73679,
                "label": "Proszowice",
                "label_ar": "بروزوفيتسي",
                "label_fr": "Proszowice"
            },
            {
                "value": 73680,
                "label": "Gorzow Slaski",
                "label_ar": "جورزو سلاسكي",
                "label_fr": "Gorzow Slaski"
            },
            {
                "value": 73681,
                "label": "Mysliborz",
                "label_ar": "ميسليبورز",
                "label_fr": "Mysliborz"
            },
            {
                "value": 73682,
                "label": "Walcz",
                "label_ar": "الفالس",
                "label_fr": "Walcz"
            },
            {
                "value": 73683,
                "label": "Bolszewo",
                "label_ar": "Bolszewo",
                "label_fr": "Bolszewo"
            },
            {
                "value": 73684,
                "label": "Ozarow Mazowiecki",
                "label_ar": "أوزارو مازويكي",
                "label_fr": "Ozarow Mazowiecki"
            },
            {
                "value": 73685,
                "label": "Zdunska Wola",
                "label_ar": "Zdunska Wola",
                "label_fr": "Zdunska Wola"
            },
            {
                "value": 73686,
                "label": "Starachowice",
                "label_ar": "Starachowice",
                "label_fr": "Starachowice"
            },
            {
                "value": 73687,
                "label": "Kobylka",
                "label_ar": "كوبيلكا",
                "label_fr": "Kobylka"
            },
            {
                "value": 73688,
                "label": "Sadlinki",
                "label_ar": "Sadlinki",
                "label_fr": "Sadlinki"
            },
            {
                "value": 73689,
                "label": "Dabrowa Chotomowska",
                "label_ar": "دابروا تشوتوموفسكا",
                "label_fr": "Dabrowa Chotomowska"
            },
            {
                "value": 73690,
                "label": "Piekary Slaskie",
                "label_ar": "بيكاري سلاسكي",
                "label_fr": "Piekary Slaskie"
            },
            {
                "value": 73691,
                "label": "Piastow",
                "label_ar": "بياستو",
                "label_fr": "Piastow"
            },
            {
                "value": 73692,
                "label": "Głogów",
                "label_ar": "Głogów",
                "label_fr": "Głogów"
            },
            {
                "value": 73693,
                "label": "Liszki",
                "label_ar": "ليزكي",
                "label_fr": "Liszki"
            },
            {
                "value": 73694,
                "label": "Grodzisk Mazowiecki",
                "label_ar": "جرودزيسك مازويكي",
                "label_fr": "Grodzisk Mazowiecki"
            },
            {
                "value": 73695,
                "label": "Krasnystaw",
                "label_ar": "كراسنيستاو",
                "label_fr": "Krasnystaw"
            },
            {
                "value": 73696,
                "label": "Lowicz",
                "label_ar": "Lowicz",
                "label_fr": "Lowicz"
            },
            {
                "value": 73697,
                "label": "Dabrowka Wielka",
                "label_ar": "دابروكا ويلكا",
                "label_fr": "Dabrowka Wielka"
            },
            {
                "value": 73698,
                "label": "Czerwionka-Leszczyny",
                "label_ar": "Czerwionka-Leszczyny",
                "label_fr": "Czerwionka-Leszczyny"
            },
            {
                "value": 73699,
                "label": "Ornontowice",
                "label_ar": "Ornontowice",
                "label_fr": "Ornontowice"
            },
            {
                "value": 73700,
                "label": "Przyszowice",
                "label_ar": "Przyszowice",
                "label_fr": "Przyszowice"
            },
            {
                "value": 73701,
                "label": "Sanok",
                "label_ar": "سانوك",
                "label_fr": "Sanok"
            },
            {
                "value": 73702,
                "label": "Zgorzelec",
                "label_ar": "Zgorzelec",
                "label_fr": "Zgorzelec"
            },
            {
                "value": 73703,
                "label": "Piensk",
                "label_ar": "بينسك",
                "label_fr": "Piensk"
            },
            {
                "value": 73704,
                "label": "Zbaszyn",
                "label_ar": "زبازين",
                "label_fr": "Zbaszyn"
            },
            {
                "value": 73705,
                "label": "Zbaszynek",
                "label_ar": "Zbaszynek",
                "label_fr": "Zbaszynek"
            },
            {
                "value": 73706,
                "label": "Jaslo",
                "label_ar": "جاسلو",
                "label_fr": "Jaslo"
            },
            {
                "value": 73707,
                "label": "Konin",
                "label_ar": "كونين",
                "label_fr": "Konin"
            },
            {
                "value": 73708,
                "label": "Żywiec",
                "label_ar": "Żywiec",
                "label_fr": "Żywiec"
            },
            {
                "value": 73709,
                "label": "Lipsko",
                "label_ar": "ليبسكو",
                "label_fr": "Lipsko"
            },
            {
                "value": 73710,
                "label": "Reda",
                "label_ar": "رضا",
                "label_fr": "Reda"
            },
            {
                "value": 73711,
                "label": "Lubartow",
                "label_ar": "لوبارتو",
                "label_fr": "Lubartow"
            },
            {
                "value": 73712,
                "label": "Plochocin",
                "label_ar": "بلوكوسين",
                "label_fr": "Plochocine"
            },
            {
                "value": 73713,
                "label": "Knurow",
                "label_ar": "نورو",
                "label_fr": "Connaître"
            },
            {
                "value": 73714,
                "label": "Brochocin",
                "label_ar": "بروشوسين",
                "label_fr": "Brochocine"
            },
            {
                "value": 73715,
                "label": "Barcin",
                "label_ar": "باركين",
                "label_fr": "Barcin"
            },
            {
                "value": 73716,
                "label": "Włocławek",
                "label_ar": "Włocławek",
                "label_fr": "Włocławek"
            },
            {
                "value": 73717,
                "label": "Jablonica",
                "label_ar": "جابلونيكا",
                "label_fr": "Jablonica"
            },
            {
                "value": 73718,
                "label": "Chełmno",
                "label_ar": "Chełmno",
                "label_fr": "Chełmno"
            },
            {
                "value": 73719,
                "label": "Miedzyborow",
                "label_ar": "ميدزبورو",
                "label_fr": "Miedzyborow"
            },
            {
                "value": 73720,
                "label": "Serock",
                "label_ar": "سيروك",
                "label_fr": "Serock"
            },
            {
                "value": 73721,
                "label": "Zielonka",
                "label_ar": "زيلونكا",
                "label_fr": "Zielonka"
            },
            {
                "value": 73722,
                "label": "Sroda Wielkopolska",
                "label_ar": "سرودا فيلكوبولسكا",
                "label_fr": "Sroda Wielkopolska"
            },
            {
                "value": 73723,
                "label": "Belsk Duzy",
                "label_ar": "بيلسك دوزي",
                "label_fr": "Belsk Duzy"
            },
            {
                "value": 73724,
                "label": "Radzymin",
                "label_ar": "رادزيمين",
                "label_fr": "Radzymin"
            },
            {
                "value": 73725,
                "label": "Gmina Morawica",
                "label_ar": "جمينا موراويكا",
                "label_fr": "Gmina Morawica"
            },
            {
                "value": 73726,
                "label": "Gmina Brwinów",
                "label_ar": "غمينا بروينوف",
                "label_fr": "Gmina Brwinów"
            },
            {
                "value": 73727,
                "label": "Gmina Końskie",
                "label_ar": "جمينا كوسكي",
                "label_fr": "Gmina Końskie"
            },
            {
                "value": 73728,
                "label": "Zielonki",
                "label_ar": "زيلونكي",
                "label_fr": "Zielonki"
            },
            {
                "value": 73729,
                "label": "Lask",
                "label_ar": "لاسك",
                "label_fr": "Lask"
            },
            {
                "value": 73730,
                "label": "Jerzmanowice",
                "label_ar": "جيرزمانوفيتسي",
                "label_fr": "Jerzmanowice"
            },
            {
                "value": 73731,
                "label": "Krzeszowice",
                "label_ar": "Krzeszowice",
                "label_fr": "Krzeszowice"
            },
            {
                "value": 73732,
                "label": "Tuszyn",
                "label_ar": "توسين",
                "label_fr": "Tuszyn"
            },
            {
                "value": 73733,
                "label": "Mechelinki",
                "label_ar": "ميكلينكي",
                "label_fr": "Mechelinki"
            },
            {
                "value": 73734,
                "label": "Kościan",
                "label_ar": "Kościan",
                "label_fr": "Kościan"
            },
            {
                "value": 73735,
                "label": "Szemud",
                "label_ar": "سمود",
                "label_fr": "Szemud"
            },
            {
                "value": 73736,
                "label": "Lomianki",
                "label_ar": "لوميانكي",
                "label_fr": "Lomianki"
            },
            {
                "value": 73737,
                "label": "Komorow",
                "label_ar": "كومورو",
                "label_fr": "Komorow"
            },
            {
                "value": 73738,
                "label": "Parczew",
                "label_ar": "باركزيو",
                "label_fr": "Parczew"
            },
            {
                "value": 73739,
                "label": "Żyrardów",
                "label_ar": "Żyrardów",
                "label_fr": "Żyrardów"
            },
            {
                "value": 73740,
                "label": "Szczawno-Zdroj",
                "label_ar": "Szczawno-Zdroj",
                "label_fr": "Szczawno-Zdroj"
            },
            {
                "value": 73741,
                "label": "Lubliniec",
                "label_ar": "لوبلينيك",
                "label_fr": "Lubliniec"
            },
            {
                "value": 73742,
                "label": "Zlocieniec",
                "label_ar": "Zlocieniec",
                "label_fr": "Zlocieniec"
            },
            {
                "value": 73743,
                "label": "Strzelce Opolskie",
                "label_ar": "Strzelce Opolskie",
                "label_fr": "Strzelce Opolskie"
            },
            {
                "value": 73744,
                "label": "Warka",
                "label_ar": "الوركاء",
                "label_fr": "Warka"
            },
            {
                "value": 73745,
                "label": "Toruń",
                "label_ar": "يهرب",
                "label_fr": "Courir"
            },
            {
                "value": 73746,
                "label": "Mragowo",
                "label_ar": "مراجوو",
                "label_fr": "Mragowo"
            },
            {
                "value": 73747,
                "label": "Lodygowice",
                "label_ar": "Lodygowice",
                "label_fr": "Lodygowice"
            },
            {
                "value": 73748,
                "label": "Wabrzezno",
                "label_ar": "Wabrzezno",
                "label_fr": "Wabrzezno"
            },
            {
                "value": 73749,
                "label": "Annopol",
                "label_ar": "أنوبول",
                "label_fr": "Annopol"
            },
            {
                "value": 73750,
                "label": "Ladek",
                "label_ar": "Ladek",
                "label_fr": "Ladek"
            },
            {
                "value": 73751,
                "label": "Chrzanów",
                "label_ar": "Chrzanów",
                "label_fr": "Chrzanów"
            },
            {
                "value": 73752,
                "label": "Gmina Strzelin",
                "label_ar": "جمينا سترزيلين",
                "label_fr": "Gmina Strzelin"
            },
            {
                "value": 73753,
                "label": "Wola Zaradzynska",
                "label_ar": "وولا زارادزينسكا",
                "label_fr": "Wola Zaradzynska"
            },
            {
                "value": 73754,
                "label": "Mikoszewo",
                "label_ar": "ميكوسزيو",
                "label_fr": "Mikoszewo"
            },
            {
                "value": 73755,
                "label": "Rostarzewo",
                "label_ar": "روستارزيو",
                "label_fr": "Rostarzewo"
            },
            {
                "value": 73756,
                "label": "Ryman",
                "label_ar": "رايمان",
                "label_fr": "Ryman"
            },
            {
                "value": 73757,
                "label": "Swietoniowa",
                "label_ar": "سويتونيوا",
                "label_fr": "Swietoniowa"
            },
            {
                "value": 73758,
                "label": "Strzyzow",
                "label_ar": "Strzyzow",
                "label_fr": "Strzyzow"
            },
            {
                "value": 73759,
                "label": "Płońsk",
                "label_ar": "Płońsk",
                "label_fr": "Płońsk"
            },
            {
                "value": 73760,
                "label": "Raciaz",
                "label_ar": "راتشياز",
                "label_fr": "Raciaz"
            },
            {
                "value": 73761,
                "label": "Solec Kujawski",
                "label_ar": "سوليك كوجاوسكي",
                "label_fr": "Solec Kujawski"
            },
            {
                "value": 73762,
                "label": "Bielsk",
                "label_ar": "بيلسك",
                "label_fr": "Bielsk"
            },
            {
                "value": 73763,
                "label": "Zamość",
                "label_ar": "زامو",
                "label_fr": "Zamość"
            },
            {
                "value": 73764,
                "label": "Lack",
                "label_ar": "نقص",
                "label_fr": "Manquer de"
            },
            {
                "value": 73765,
                "label": "Ostróda",
                "label_ar": "أوسترودا",
                "label_fr": "Ostróda"
            },
            {
                "value": 73766,
                "label": "Korsze",
                "label_ar": "كورس",
                "label_fr": "Korsze"
            },
            {
                "value": 73767,
                "label": "Starogard Gdański",
                "label_ar": "ستاروغارد غدانسكي",
                "label_fr": "Starogard Gdański"
            },
            {
                "value": 73768,
                "label": "Mońki",
                "label_ar": "Mońki",
                "label_fr": "Mońki"
            },
            {
                "value": 73769,
                "label": "Sokołów Podlaski",
                "label_ar": "Sokołów Podlaski",
                "label_fr": "Sokołów Podlaski"
            },
            {
                "value": 73770,
                "label": "Suchy Las",
                "label_ar": "سوشي لاس",
                "label_fr": "Suchy Las"
            },
            {
                "value": 73771,
                "label": "Wysogotowo",
                "label_ar": "Wysogotowo",
                "label_fr": "Wysogotowo"
            },
            {
                "value": 73772,
                "label": "Lulin",
                "label_ar": "لولين",
                "label_fr": "Lulin"
            },
            {
                "value": 73773,
                "label": "Murowana Goslina",
                "label_ar": "موروانا جوسلينا",
                "label_fr": "Murowana Goslina"
            },
            {
                "value": 73774,
                "label": "Kosakowo",
                "label_ar": "Kosakowo",
                "label_fr": "Kosakowo"
            },
            {
                "value": 73775,
                "label": "Pierwoszyno",
                "label_ar": "بيرفوسزينو",
                "label_fr": "Pierwoszyno"
            },
            {
                "value": 73776,
                "label": "Pogwizdow",
                "label_ar": "بوجويزدو",
                "label_fr": "Pogwizdow"
            },
            {
                "value": 73777,
                "label": "Leszno",
                "label_ar": "ليسزنو",
                "label_fr": "Leszno"
            },
            {
                "value": 73778,
                "label": "Pobiedziska",
                "label_ar": "بوبييدزيسكا",
                "label_fr": "Pobiedziska"
            },
            {
                "value": 73779,
                "label": "Tarnowo Podgorne",
                "label_ar": "تارنوو بودجورن",
                "label_fr": "Tarnowo Podgorne"
            },
            {
                "value": 73780,
                "label": "Puszczykowo",
                "label_ar": "Puszczykowo",
                "label_fr": "Puszczykowo"
            },
            {
                "value": 73781,
                "label": "Gniezno",
                "label_ar": "جنيزنو",
                "label_fr": "Gniezno"
            },
            {
                "value": 73782,
                "label": "Stawiany",
                "label_ar": "ستاوياني",
                "label_fr": "Stawiany"
            },
            {
                "value": 73783,
                "label": "Garby",
                "label_ar": "غاربي",
                "label_fr": "Garby"
            },
            {
                "value": 73784,
                "label": "Szamotuły",
                "label_ar": "Szamotuły",
                "label_fr": "Szamotuły"
            },
            {
                "value": 73785,
                "label": "Strzalkowo",
                "label_ar": "Strzalkowo",
                "label_fr": "Strzalkowo"
            },
            {
                "value": 73786,
                "label": "Buk",
                "label_ar": "بوك",
                "label_fr": "Buk"
            },
            {
                "value": 73787,
                "label": "Pleszew",
                "label_ar": "بليزيو",
                "label_fr": "Pleszew"
            },
            {
                "value": 73788,
                "label": "Wronki",
                "label_ar": "ورونكي",
                "label_fr": "Wronki"
            },
            {
                "value": 73789,
                "label": "Czarze",
                "label_ar": "قيصر",
                "label_fr": "Czarze"
            },
            {
                "value": 73790,
                "label": "Maksymilianowo",
                "label_ar": "ماكسيميليانووو",
                "label_fr": "Maksymilianowo"
            },
            {
                "value": 73791,
                "label": "Gruczno",
                "label_ar": "غروسنو",
                "label_fr": "Gruczno"
            },
            {
                "value": 73792,
                "label": "Gzin",
                "label_ar": "جزين",
                "label_fr": "Gzin"
            },
            {
                "value": 73793,
                "label": "Adama",
                "label_ar": "أداما",
                "label_fr": "Adama"
            },
            {
                "value": 73794,
                "label": "Wrzesnia",
                "label_ar": "Wrzesnia",
                "label_fr": "Wrzesnia"
            },
            {
                "value": 73795,
                "label": "Borzykowo",
                "label_ar": "بورزيكوفو",
                "label_fr": "Borzykowo"
            },
            {
                "value": 73796,
                "label": "Oblaczkowo",
                "label_ar": "Oblaczkowo",
                "label_fr": "Oblaczkowo"
            },
            {
                "value": 73797,
                "label": "Tulce",
                "label_ar": "تولس",
                "label_fr": "Tulce"
            },
            {
                "value": 73798,
                "label": "Obrzycko",
                "label_ar": "Obrzycko",
                "label_fr": "Obrzycko"
            },
            {
                "value": 73799,
                "label": "Plewiska",
                "label_ar": "بليويسكا",
                "label_fr": "Plewiska"
            },
            {
                "value": 73800,
                "label": "Gmina Śrem",
                "label_ar": "غمينا شريم",
                "label_fr": "Gmina Śrem"
            },
            {
                "value": 73801,
                "label": "Mogilno",
                "label_ar": "موغيلنو",
                "label_fr": "Mogilno"
            },
            {
                "value": 73802,
                "label": "Zabierzow Bochenski",
                "label_ar": "زابيرزو بوشينسكي",
                "label_fr": "Zabierzow Bochenski"
            },
            {
                "value": 73803,
                "label": "Świnoujście",
                "label_ar": "أووينوجيسي",
                "label_fr": "Świnoujście"
            },
            {
                "value": 73804,
                "label": "Maria",
                "label_ar": "ماريا",
                "label_fr": "Maria"
            },
            {
                "value": 73805,
                "label": "Cigacice",
                "label_ar": "سيجاسيتسي",
                "label_fr": "Cigacice"
            },
            {
                "value": 73806,
                "label": "Chotel",
                "label_ar": "تشوتل",
                "label_fr": "Chotel"
            },
            {
                "value": 73807,
                "label": "Nowa Sol",
                "label_ar": "نوا سول",
                "label_fr": "Nowa Sol"
            },
            {
                "value": 73808,
                "label": "Legnica",
                "label_ar": "ليجنيكا",
                "label_fr": "Legnica"
            },
            {
                "value": 73809,
                "label": "Lubsko",
                "label_ar": "لوبسكو",
                "label_fr": "Lubsko"
            },
            {
                "value": 73810,
                "label": "Grebocice",
                "label_ar": "غريبوسيس",
                "label_fr": "Grebocice"
            },
            {
                "value": 73811,
                "label": "Gorzyce",
                "label_ar": "جورزيز",
                "label_fr": "Gorzyce"
            },
            {
                "value": 73812,
                "label": "Lubaszowa",
                "label_ar": "Lubaszowa",
                "label_fr": "Lubaszowa"
            },
            {
                "value": 73813,
                "label": "Wieszowa",
                "label_ar": "فيزوفا",
                "label_fr": "Wieszowa"
            },
            {
                "value": 73814,
                "label": "Tyczyn",
                "label_ar": "تيكزين",
                "label_fr": "Tyczyn"
            },
            {
                "value": 73815,
                "label": "Chmielnik",
                "label_ar": "شمييلنيك",
                "label_fr": "Chmielnik"
            },
            {
                "value": 73816,
                "label": "Blazowa",
                "label_ar": "بلازوا",
                "label_fr": "Blazowa"
            },
            {
                "value": 73817,
                "label": "Dynow",
                "label_ar": "داينو",
                "label_fr": "Dynow"
            },
            {
                "value": 73818,
                "label": "Lubenia",
                "label_ar": "لوبينيا",
                "label_fr": "Lubenia"
            },
            {
                "value": 73819,
                "label": "Ząbkowice Śląskie",
                "label_ar": "Ząbkowice Śląskie",
                "label_fr": "Ząbkowice Śląskie"
            },
            {
                "value": 73820,
                "label": "Gorzow",
                "label_ar": "جورزو",
                "label_fr": "Gorzow"
            },
            {
                "value": 73821,
                "label": "Zarow",
                "label_ar": "زارو",
                "label_fr": "Zarow"
            },
            {
                "value": 73822,
                "label": "Jaworzyna Slaska",
                "label_ar": "Jaworzyna سلاسكا",
                "label_fr": "Jaworzyna Slaska"
            },
            {
                "value": 73823,
                "label": "Tomaszow",
                "label_ar": "تومازو",
                "label_fr": "Tomaszow"
            },
            {
                "value": 73824,
                "label": "Szczytno",
                "label_ar": "Szczytno",
                "label_fr": "Szczytno"
            },
            {
                "value": 73825,
                "label": "Ciechocinek",
                "label_ar": "Ciechocinek",
                "label_fr": "Ciechocinek"
            },
            {
                "value": 73826,
                "label": "Rypin",
                "label_ar": "ريبين",
                "label_fr": "Rypin"
            },
            {
                "value": 73827,
                "label": "Kozieglowy",
                "label_ar": "Kozieglowy",
                "label_fr": "Kozieglowy"
            },
            {
                "value": 73828,
                "label": "Grzebien",
                "label_ar": "Grzebien",
                "label_fr": "Grzebien"
            },
            {
                "value": 73829,
                "label": "Gora",
                "label_ar": "جورا",
                "label_fr": "Gora"
            },
            {
                "value": 73830,
                "label": "Balice",
                "label_ar": "باليس",
                "label_fr": "Balice"
            },
            {
                "value": 73831,
                "label": "Studzieniec",
                "label_ar": "Studzieniec",
                "label_fr": "Studzieniec"
            },
            {
                "value": 73832,
                "label": "Czarnkow",
                "label_ar": "تشيرنكوف",
                "label_fr": "Czarnkow"
            },
            {
                "value": 73833,
                "label": "Zlotow",
                "label_ar": "زلوتو",
                "label_fr": "Zlotow"
            },
            {
                "value": 73834,
                "label": "Chodzież",
                "label_ar": "تشودزيتش",
                "label_fr": "Chodzież"
            },
            {
                "value": 73835,
                "label": "Pila",
                "label_ar": "بيلا",
                "label_fr": "Pila"
            },
            {
                "value": 73836,
                "label": "Miroslawiec",
                "label_ar": "ميروسلاويك",
                "label_fr": "Miroslawiec"
            },
            {
                "value": 73837,
                "label": "Ujscie",
                "label_ar": "أوجشي",
                "label_fr": "Ujscie"
            },
            {
                "value": 73838,
                "label": "Wyrzysk",
                "label_ar": "ويرزيسك",
                "label_fr": "Wyrzysk"
            },
            {
                "value": 73839,
                "label": "Slupca",
                "label_ar": "سلوبكا",
                "label_fr": "Slupca"
            },
            {
                "value": 73840,
                "label": "Międzychód",
                "label_ar": "Mizdzychód",
                "label_fr": "Międzychód"
            },
            {
                "value": 73841,
                "label": "Grodzisk Wielkopolski",
                "label_ar": "جرودزيسك فيلكوبولسكي",
                "label_fr": "Grodzisk Wielkopolski"
            },
            {
                "value": 73842,
                "label": "Gostyn",
                "label_ar": "جوستين",
                "label_fr": "Gostyn"
            },
            {
                "value": 73843,
                "label": "Owinska",
                "label_ar": "أوينسكا",
                "label_fr": "Owinska"
            },
            {
                "value": 73844,
                "label": "Trzcianka",
                "label_ar": "ترزسيانكا",
                "label_fr": "Trzcianka"
            },
            {
                "value": 73845,
                "label": "Osno",
                "label_ar": "أوسنو",
                "label_fr": "Osno"
            },
            {
                "value": 73846,
                "label": "Wijewo",
                "label_ar": "ويجيو",
                "label_fr": "Wijewo"
            },
            {
                "value": 73847,
                "label": "Bochnia",
                "label_ar": "بوكنيا",
                "label_fr": "Bochnia"
            },
            {
                "value": 73848,
                "label": "Dziewin",
                "label_ar": "دزيفين",
                "label_fr": "Dziewin"
            },
            {
                "value": 73849,
                "label": "Jastrzębie Zdrój",
                "label_ar": "Jastrzębie Zdrój",
                "label_fr": "Jastrzębie Zdrój"
            },
            {
                "value": 73850,
                "label": "Nysa",
                "label_ar": "نيسا",
                "label_fr": "Nysa"
            },
            {
                "value": 73851,
                "label": "Nowe Miasto Lubawskie",
                "label_ar": "Nowe Miasto Lubawskie",
                "label_fr": "Nowe Miasto Lubawskie"
            },
            {
                "value": 73852,
                "label": "Wojkowice",
                "label_ar": "Wojkowice",
                "label_fr": "Wojkowice"
            },
            {
                "value": 73853,
                "label": "Zofia",
                "label_ar": "زوفيا",
                "label_fr": "Zofia"
            },
            {
                "value": 73854,
                "label": "Ostrów Wielkopolski",
                "label_ar": "أوسترو فيلكوبولسكي",
                "label_fr": "Ostrów Wielkopolski"
            },
            {
                "value": 73855,
                "label": "Tarchaly Wielkie",
                "label_ar": "Tarchaly Wielkie",
                "label_fr": "Tarchaly Wielkie"
            },
            {
                "value": 73856,
                "label": "Krotoszyn",
                "label_ar": "كروتوسزين",
                "label_fr": "Krotoszyn"
            },
            {
                "value": 73857,
                "label": "Jarocin",
                "label_ar": "جاروسين",
                "label_fr": "Jarocin"
            },
            {
                "value": 73858,
                "label": "Lubraniec",
                "label_ar": "لوبرانيك",
                "label_fr": "Lubraniec"
            },
            {
                "value": 73859,
                "label": "Kluczbork",
                "label_ar": "كلوكزبورك",
                "label_fr": "Kluczbork"
            },
            {
                "value": 73860,
                "label": "Osięciny",
                "label_ar": "Osięciny",
                "label_fr": "Osięciny"
            },
            {
                "value": 73861,
                "label": "Władysławowo",
                "label_ar": "Władysławowo",
                "label_fr": "Władysławowo"
            },
            {
                "value": 73862,
                "label": "Cekcyn",
                "label_ar": "Cekcyn",
                "label_fr": "Cekcyn"
            },
            {
                "value": 73863,
                "label": "Tarnobrzeg",
                "label_ar": "Tarnobrzeg",
                "label_fr": "Tarnobrzeg"
            },
            {
                "value": 73864,
                "label": "Olkusz",
                "label_ar": "أولكوز",
                "label_fr": "Olkusz"
            },
            {
                "value": 73865,
                "label": "Serock",
                "label_ar": "سيروك",
                "label_fr": "Serock"
            },
            {
                "value": 73866,
                "label": "Odolin",
                "label_ar": "أودولين",
                "label_fr": "Odolin"
            },
            {
                "value": 73867,
                "label": "Czechowice-Dziedzice",
                "label_ar": "تشيكوفيتسه دزيدزيسي",
                "label_fr": "Czechowice-Dziedzice"
            },
            {
                "value": 73868,
                "label": "Meszna",
                "label_ar": "Meszna",
                "label_fr": "Meszna"
            },
            {
                "value": 73869,
                "label": "Legionowo",
                "label_ar": "Legionowo",
                "label_fr": "Legionowo"
            },
            {
                "value": 73870,
                "label": "Piekoszow",
                "label_ar": "بيكوسزو",
                "label_fr": "Piekoszow"
            },
            {
                "value": 73871,
                "label": "Swoboda",
                "label_ar": "سوبودا",
                "label_fr": "Swoboda"
            },
            {
                "value": 73872,
                "label": "Wilkowisko",
                "label_ar": "ويلكويسكو",
                "label_fr": "Wilkowisko"
            },
            {
                "value": 73873,
                "label": "Kolbudy",
                "label_ar": "كولبودي",
                "label_fr": "Kolbudy"
            },
            {
                "value": 73874,
                "label": "Bytów",
                "label_ar": "بايتو",
                "label_fr": "Bytów"
            },
            {
                "value": 73875,
                "label": "Aleksandrów Kujawski",
                "label_ar": "ألكسندروف كوجاوسكي",
                "label_fr": "Aleksandrów Kujawski"
            },
            {
                "value": 73876,
                "label": "Sadlno",
                "label_ar": "سادلنو",
                "label_fr": "Sadlno"
            },
            {
                "value": 73877,
                "label": "Chełmża",
                "label_ar": "Chełmża",
                "label_fr": "Chełmża"
            },
            {
                "value": 73878,
                "label": "Karczew",
                "label_ar": "كارسيف",
                "label_fr": "Karczew"
            },
            {
                "value": 73879,
                "label": "Błonie",
                "label_ar": "بيوني",
                "label_fr": "Błonie"
            },
            {
                "value": 73880,
                "label": "Jozefoslaw",
                "label_ar": "جوزيفوسلاف",
                "label_fr": "Jozefoslaw"
            },
            {
                "value": 73881,
                "label": "Zalubice Stare",
                "label_ar": "Zalubice Stare",
                "label_fr": "Zalubice Stare"
            },
            {
                "value": 73882,
                "label": "Kołbiel",
                "label_ar": "Kołbiel",
                "label_fr": "Kołbiel"
            },
            {
                "value": 73883,
                "label": "Jutrosin",
                "label_ar": "جوتروسين",
                "label_fr": "Jutrosin"
            },
            {
                "value": 73884,
                "label": "Ustron",
                "label_ar": "أوسترون",
                "label_fr": "Ustron"
            },
            {
                "value": 73885,
                "label": "Wodzisław Śląski",
                "label_ar": "Wodzisław Śląski",
                "label_fr": "Wodzisław Śląski"
            },
            {
                "value": 73886,
                "label": "Strumien",
                "label_ar": "سترومين",
                "label_fr": "Strumien"
            },
            {
                "value": 73887,
                "label": "Lubon",
                "label_ar": "لوبون",
                "label_fr": "Lubon"
            },
            {
                "value": 73888,
                "label": "Łapy",
                "label_ar": "Łapy",
                "label_fr": "Łapy"
            },
            {
                "value": 73889,
                "label": "Blachownia",
                "label_ar": "Blachownia",
                "label_fr": "Blachownia"
            },
            {
                "value": 73890,
                "label": "Ziebice",
                "label_ar": "زيبتسي",
                "label_fr": "Ziebice"
            },
            {
                "value": 73891,
                "label": "Jaworzno",
                "label_ar": "جاورزنو",
                "label_fr": "Jaworzno"
            },
            {
                "value": 73892,
                "label": "Pewel Mala",
                "label_ar": "بيويل مالا",
                "label_fr": "Pewel Mala"
            },
            {
                "value": 73893,
                "label": "Naklo",
                "label_ar": "ناكلو",
                "label_fr": "Naklo"
            },
            {
                "value": 73894,
                "label": "Będzin",
                "label_ar": "بودزين",
                "label_fr": "Będzin"
            },
            {
                "value": 73895,
                "label": "Miasteczko Slaskie",
                "label_ar": "Miasteczko سلاسكي",
                "label_fr": "Miasteczko Slaskie"
            },
            {
                "value": 73896,
                "label": "Weglowice",
                "label_ar": "ويجلويتشي",
                "label_fr": "Weglowice"
            },
            {
                "value": 73897,
                "label": "Koszecin",
                "label_ar": "كوسزيسين",
                "label_fr": "Koszecin"
            },
            {
                "value": 73898,
                "label": "MaryLka",
                "label_ar": "ماريلكا",
                "label_fr": "MaryLka"
            },
            {
                "value": 73899,
                "label": "Zgierz",
                "label_ar": "زجيرز",
                "label_fr": "Zgierz"
            },
            {
                "value": 73900,
                "label": "Skawina",
                "label_ar": "سكوينا",
                "label_fr": "Skawina"
            },
            {
                "value": 73901,
                "label": "Pruszcz Gdanski",
                "label_ar": "Pruszcz Gdanski",
                "label_fr": "Pruszcz Gdanski"
            },
            {
                "value": 73902,
                "label": "Tluczan",
                "label_ar": "تلوكزان",
                "label_fr": "Tluczan"
            },
            {
                "value": 73903,
                "label": "Podegrodzie",
                "label_ar": "Podegrodzie",
                "label_fr": "Podegrodzie"
            },
            {
                "value": 73904,
                "label": "Wegrzce Wielkie",
                "label_ar": "Wegrzce Wielkie",
                "label_fr": "Wegrzce Wielkie"
            },
            {
                "value": 73905,
                "label": "Stary Sacz",
                "label_ar": "ستاري ساكز",
                "label_fr": "Stary Sacz"
            },
            {
                "value": 73906,
                "label": "Olawa",
                "label_ar": "أولاوا",
                "label_fr": "Olawa"
            },
            {
                "value": 73907,
                "label": "Naleczow",
                "label_ar": "ناليتشوف",
                "label_fr": "Naleczow"
            },
            {
                "value": 73908,
                "label": "Piaseczno",
                "label_ar": "بياسيكزنو",
                "label_fr": "Piaseczno"
            },
            {
                "value": 73909,
                "label": "Kozy",
                "label_ar": "كوزي",
                "label_fr": "Kozy"
            },
            {
                "value": 73910,
                "label": "Nowa",
                "label_ar": "نوا",
                "label_fr": "Nowa"
            },
            {
                "value": 73911,
                "label": "Żagań",
                "label_ar": "Żagań",
                "label_fr": "Żagań"
            },
            {
                "value": 73912,
                "label": "Gubin",
                "label_ar": "جوبين",
                "label_fr": "Gubin"
            },
            {
                "value": 73913,
                "label": "Luban",
                "label_ar": "لوبان",
                "label_fr": "Luban"
            },
            {
                "value": 73914,
                "label": "Kowary",
                "label_ar": "كواري",
                "label_fr": "Kowary"
            },
            {
                "value": 73915,
                "label": "Piechowice",
                "label_ar": "بييتشوفيتسي",
                "label_fr": "Piechowice"
            },
            {
                "value": 73916,
                "label": "Bolesławiec",
                "label_ar": "بوليسوافيتش",
                "label_fr": "Bolesławiec"
            },
            {
                "value": 73917,
                "label": "Jawor",
                "label_ar": "جوور",
                "label_fr": "Jawor"
            },
            {
                "value": 73918,
                "label": "Pieszkow",
                "label_ar": "بيسزكو",
                "label_fr": "Pieszkow"
            },
            {
                "value": 73919,
                "label": "Bystrzyca Klodzka",
                "label_ar": "بيسترزيكا كلودزكا",
                "label_fr": "Bystrzyca Klodzka"
            },
            {
                "value": 73920,
                "label": "Elblag",
                "label_ar": "إلبلاغ",
                "label_fr": "Elblag"
            },
            {
                "value": 73921,
                "label": "Swiebodzin",
                "label_ar": "Swiebodzin",
                "label_fr": "Swiebodzin"
            },
            {
                "value": 73922,
                "label": "Borek Strzelinski",
                "label_ar": "بوريك سترزيلينسكي",
                "label_fr": "Borek Strzelinski"
            },
            {
                "value": 73923,
                "label": "Cieszyn",
                "label_ar": "سيسزين",
                "label_fr": "Cieszyn"
            },
            {
                "value": 73924,
                "label": "Aleksandrów Łódzki",
                "label_ar": "ألكساندرو لودزكي",
                "label_fr": "Aleksandrów Łódzki"
            },
            {
                "value": 73925,
                "label": "Rzeszotary",
                "label_ar": "رزيسزوتاري",
                "label_fr": "Rzeszotary"
            },
            {
                "value": 73926,
                "label": "Ozorkow",
                "label_ar": "أوزوركوف",
                "label_fr": "Ozorkow"
            },
            {
                "value": 73927,
                "label": "Prochowice",
                "label_ar": "بروشوفيتسي",
                "label_fr": "Prochowice"
            },
            {
                "value": 73928,
                "label": "Kłodzko",
                "label_ar": "كودزكو",
                "label_fr": "Kłodzko"
            },
            {
                "value": 73929,
                "label": "Szymanow",
                "label_ar": "زيمانوف",
                "label_fr": "Szymanow"
            },
            {
                "value": 73930,
                "label": "Kunice",
                "label_ar": "كونيس",
                "label_fr": "Kunice"
            },
            {
                "value": 73931,
                "label": "Zary",
                "label_ar": "زاري",
                "label_fr": "Zary"
            },
            {
                "value": 73932,
                "label": "Sieniawa Zarska",
                "label_ar": "سينياوا زارسكا",
                "label_fr": "Sieniawa Zarska"
            },
            {
                "value": 73933,
                "label": "Lipinki Luzyckie",
                "label_ar": "ليبينكي لوزيكي",
                "label_fr": "Lipinki Luzyckie"
            },
            {
                "value": 73934,
                "label": "Niwica",
                "label_ar": "نيويكا",
                "label_fr": "Niwica"
            },
            {
                "value": 73935,
                "label": "Sobotka",
                "label_ar": "سوبوتكا",
                "label_fr": "Sobotka"
            },
            {
                "value": 73936,
                "label": "Pietrzykowice",
                "label_ar": "بيتريزكوفيتسه",
                "label_fr": "Pietrzykowice"
            },
            {
                "value": 73937,
                "label": "Środa Śląska",
                "label_ar": "Środa Śląska",
                "label_fr": "Środa Śląska"
            },
            {
                "value": 73938,
                "label": "Mary",
                "label_ar": "ماري",
                "label_fr": "Marie"
            },
            {
                "value": 73939,
                "label": "Jugow",
                "label_ar": "جوغو",
                "label_fr": "Jugow"
            },
            {
                "value": 73940,
                "label": "Bogatynia",
                "label_ar": "بوغاتينيا",
                "label_fr": "Bogatynia"
            },
            {
                "value": 73941,
                "label": "Miedzylesie",
                "label_ar": "ميدزيلسي",
                "label_fr": "Miedzylesie"
            },
            {
                "value": 73942,
                "label": "Zalesie Górne",
                "label_ar": "زالسي جورن",
                "label_fr": "Zalesie Górne"
            },
            {
                "value": 73943,
                "label": "Pisz",
                "label_ar": "بيز",
                "label_fr": "Pisz"
            },
            {
                "value": 73944,
                "label": "Hrubieszów",
                "label_ar": "Hrubieszów",
                "label_fr": "Hrubieszów"
            },
            {
                "value": 73945,
                "label": "Haczow",
                "label_ar": "Haczow",
                "label_fr": "Haczow"
            },
            {
                "value": 73946,
                "label": "Nadarzyn",
                "label_ar": "نادرزين",
                "label_fr": "Nadarzyn"
            },
            {
                "value": 73947,
                "label": "Chojnice",
                "label_ar": "شوجنيتسي",
                "label_fr": "Chojnice"
            },
            {
                "value": 73948,
                "label": "Ostrog",
                "label_ar": "اوستروج",
                "label_fr": "Ostrog"
            },
            {
                "value": 73949,
                "label": "Racibórz",
                "label_ar": "راسيبورز",
                "label_fr": "Racibórz"
            },
            {
                "value": 73950,
                "label": "Wasilkow",
                "label_ar": "Wasilkow",
                "label_fr": "Wasilkow"
            },
            {
                "value": 73951,
                "label": "Węgrów",
                "label_ar": "نحن نكبر",
                "label_fr": "Nous grandissons"
            },
            {
                "value": 73952,
                "label": "Zmigrod",
                "label_ar": "زميجرود",
                "label_fr": "Zmigrod"
            },
            {
                "value": 73953,
                "label": "Tczew",
                "label_ar": "Tczew",
                "label_fr": "Tczew"
            },
            {
                "value": 73954,
                "label": "Sokółka",
                "label_ar": "سوكوتشكا",
                "label_fr": "Sokółka"
            },
            {
                "value": 73955,
                "label": "Siemiatycze",
                "label_ar": "سيمياتيتش",
                "label_fr": "Siemiatycze"
            },
            {
                "value": 73956,
                "label": "Zabłudów",
                "label_ar": "زابودوف",
                "label_fr": "Zabłudów"
            },
            {
                "value": 73957,
                "label": "Mazancowice",
                "label_ar": "Mazancowice",
                "label_fr": "Mazancowice"
            },
            {
                "value": 73958,
                "label": "Sierpc",
                "label_ar": "سيربك",
                "label_fr": "Sierpc"
            },
            {
                "value": 73959,
                "label": "Łomża",
                "label_ar": "żomża",
                "label_fr": "Łomża"
            },
            {
                "value": 73960,
                "label": "Morąg",
                "label_ar": "Morąg",
                "label_fr": "Morąg"
            },
            {
                "value": 73961,
                "label": "Wagrowiec",
                "label_ar": "واغرويتش",
                "label_fr": "Wagrowiec"
            },
            {
                "value": 73962,
                "label": "Dzierzoniow",
                "label_ar": "دزيرزونيو",
                "label_fr": "Dzierzoniow"
            },
            {
                "value": 73963,
                "label": "Nowogard",
                "label_ar": "Nowogard",
                "label_fr": "Nowogard"
            },
            {
                "value": 73964,
                "label": "Choszczno",
                "label_ar": "تشوزكزنو",
                "label_fr": "Choszczno"
            },
            {
                "value": 73965,
                "label": "Gmina Jędrzejów",
                "label_ar": "غمينا Jędrzejów",
                "label_fr": "Gmina Jędrzejów"
            },
            {
                "value": 73966,
                "label": "Ustka",
                "label_ar": "أوستكا",
                "label_fr": "Ustka"
            },
            {
                "value": 73967,
                "label": "Gmina Świecie",
                "label_ar": "غمينا Świecie",
                "label_fr": "Gmina Świecie"
            },
            {
                "value": 73968,
                "label": "Łęczyca",
                "label_ar": "Łęczyca",
                "label_fr": "Łęczyca"
            },
            {
                "value": 73969,
                "label": "Chojnów",
                "label_ar": "Chojnów",
                "label_fr": "Chojnów"
            },
            {
                "value": 73970,
                "label": "Frysztak",
                "label_ar": "فريشتاك",
                "label_fr": "Frysztak"
            },
            {
                "value": 73971,
                "label": "Twardawa",
                "label_ar": "توارداوا",
                "label_fr": "Twardawa"
            },
            {
                "value": 73972,
                "label": "Lubomia",
                "label_ar": "لوبوميا",
                "label_fr": "Lubomia"
            },
            {
                "value": 73973,
                "label": "Smigiel",
                "label_ar": "سميجيل",
                "label_fr": "Smigiel"
            },
            {
                "value": 73974,
                "label": "Andrespol",
                "label_ar": "أندريسبول",
                "label_fr": "Andrespol"
            },
            {
                "value": 73975,
                "label": "Oksywie",
                "label_ar": "أوكسيوي",
                "label_fr": "Oksywie"
            },
            {
                "value": 73976,
                "label": "Siechnice",
                "label_ar": "سيشنيتسي",
                "label_fr": "Siechnice"
            },
            {
                "value": 73977,
                "label": "Lutynia",
                "label_ar": "لوتينيا",
                "label_fr": "Lutynia"
            },
            {
                "value": 73978,
                "label": "Rzuchowa",
                "label_ar": "رزوتشوا",
                "label_fr": "Rzuchowa"
            },
            {
                "value": 73979,
                "label": "Radzanowo",
                "label_ar": "رادزانوو",
                "label_fr": "Radzanowo"
            },
            {
                "value": 73980,
                "label": "Pyrzyce",
                "label_ar": "بيرزيز",
                "label_fr": "Pyrzyce"
            },
            {
                "value": 73981,
                "label": "Sompolno",
                "label_ar": "سومبولنو",
                "label_fr": "Sompolno"
            },
            {
                "value": 73982,
                "label": "Żory",
                "label_ar": "نعم",
                "label_fr": "Żory"
            },
            {
                "value": 73983,
                "label": "Białogard",
                "label_ar": "بياوغارد",
                "label_fr": "Białogard"
            },
            {
                "value": 73984,
                "label": "Swidwin",
                "label_ar": "سويدوين",
                "label_fr": "Swidwin"
            },
            {
                "value": 73985,
                "label": "Karlino",
                "label_ar": "كارلينو",
                "label_fr": "Karlino"
            },
            {
                "value": 73986,
                "label": "Zaczernie",
                "label_ar": "زاكزرني",
                "label_fr": "Zaczernie"
            },
            {
                "value": 73987,
                "label": "Korczyna",
                "label_ar": "كورتزينا",
                "label_fr": "Korczyna"
            },
            {
                "value": 73988,
                "label": "Strykow",
                "label_ar": "ستريكوف",
                "label_fr": "Strykow"
            },
            {
                "value": 73989,
                "label": "Katy",
                "label_ar": "كاتي",
                "label_fr": "Katy"
            },
            {
                "value": 73990,
                "label": "Przemysl",
                "label_ar": "برزيميسل",
                "label_fr": "Przemysl"
            },
            {
                "value": 73991,
                "label": "Makow Mazowiecki",
                "label_ar": "ماكو مازويكي",
                "label_fr": "Makow Mazowiecki"
            },
            {
                "value": 73992,
                "label": "Wielen Zaobrzanski",
                "label_ar": "فيلين زوبرزانسكي",
                "label_fr": "Wielen Zaobrzanski"
            },
            {
                "value": 73993,
                "label": "Skierniewice",
                "label_ar": "سكييرنيفيتسي",
                "label_fr": "Skierniewice"
            },
            {
                "value": 73994,
                "label": "Chmielow",
                "label_ar": "شميلو",
                "label_fr": "Chmielow"
            },
            {
                "value": 73995,
                "label": "Polajewo",
                "label_ar": "بولاجيو",
                "label_fr": "Polajewo"
            },
            {
                "value": 73996,
                "label": "Bukowice",
                "label_ar": "بوكوفيتشي",
                "label_fr": "Bukowice"
            },
            {
                "value": 73997,
                "label": "Zaniemysl",
                "label_ar": "زانييميسل",
                "label_fr": "Zaniemysl"
            },
            {
                "value": 73998,
                "label": "Gryfice",
                "label_ar": "جريفيس",
                "label_fr": "Gryfice"
            },
            {
                "value": 73999,
                "label": "Skorzewo",
                "label_ar": "سكورزيو",
                "label_fr": "Skorzewo"
            },
            {
                "value": 74000,
                "label": "Sosnie",
                "label_ar": "سوسني",
                "label_fr": "Sosnie"
            },
            {
                "value": 74001,
                "label": "Kuznica Czarnkowska",
                "label_ar": "كوزنيكا كزارنكوسكا",
                "label_fr": "Kuznica Czarnkowska"
            },
            {
                "value": 74002,
                "label": "Mikstat",
                "label_ar": "ميكستات",
                "label_fr": "Mikstat"
            },
            {
                "value": 74003,
                "label": "Trzciel",
                "label_ar": "Trzciel",
                "label_fr": "Trzciel"
            },
            {
                "value": 74004,
                "label": "Przezmierowo",
                "label_ar": "Przezmierowo",
                "label_fr": "Przezmierowo"
            },
            {
                "value": 74005,
                "label": "Szczaniec",
                "label_ar": "Szczaniec",
                "label_fr": "Szczaniec"
            },
            {
                "value": 74006,
                "label": "Wolsztyn",
                "label_ar": "ولشتين",
                "label_fr": "Wolsztyn"
            },
            {
                "value": 74007,
                "label": "Gmina Lipno",
                "label_ar": "جمينا ليبنو",
                "label_fr": "Gmina Lipno"
            },
            {
                "value": 74008,
                "label": "Pogorzela",
                "label_ar": "بوجورزيلا",
                "label_fr": "Pogorzela"
            },
            {
                "value": 74009,
                "label": "Zalakowo",
                "label_ar": "زالاكوفو",
                "label_fr": "Zalakowo"
            },
            {
                "value": 74010,
                "label": "Służewo",
                "label_ar": "Służewo",
                "label_fr": "Służewo"
            },
            {
                "value": 74011,
                "label": "Stargard Szczeciński",
                "label_ar": "Stargard Szczeciński",
                "label_fr": "Stargard Szczeciński"
            },
            {
                "value": 74012,
                "label": "Trzebiatów",
                "label_ar": "Trzebiatów",
                "label_fr": "Trzebiatów"
            },
            {
                "value": 74013,
                "label": "Wolin",
                "label_ar": "ولين",
                "label_fr": "Wolin"
            },
            {
                "value": 74014,
                "label": "Mrowino",
                "label_ar": "مروينو",
                "label_fr": "Mrowino"
            },
            {
                "value": 74015,
                "label": "Papowo",
                "label_ar": "بابوو",
                "label_fr": "Papowo"
            },
            {
                "value": 74016,
                "label": "Mielno",
                "label_ar": "ميلنو",
                "label_fr": "Mielno"
            },
            {
                "value": 74017,
                "label": "Kostrzyn",
                "label_ar": "Kostrzyn",
                "label_fr": "Kostrzyn"
            },
            {
                "value": 74018,
                "label": "Steszew",
                "label_ar": "ستيسزو",
                "label_fr": "Steszew"
            },
            {
                "value": 74019,
                "label": "Gozdnica",
                "label_ar": "Gozdnica",
                "label_fr": "Gozdnica"
            },
            {
                "value": 74020,
                "label": "Gnuszyn",
                "label_ar": "Gnuszyn",
                "label_fr": "Gnuszyn"
            },
            {
                "value": 74021,
                "label": "Babimost",
                "label_ar": "بابيموست",
                "label_fr": "Babimost"
            },
            {
                "value": 74022,
                "label": "Czerwonak",
                "label_ar": "سيزيروناك",
                "label_fr": "Czerwonak"
            },
            {
                "value": 74023,
                "label": "Kamien Pomorski",
                "label_ar": "كامين بومورسكي",
                "label_fr": "Kamien Pomorski"
            },
            {
                "value": 74024,
                "label": "Biesiekierz",
                "label_ar": "بيسيكيرز",
                "label_fr": "Biesiekierz"
            },
            {
                "value": 74025,
                "label": "Swietno",
                "label_ar": "Swietno",
                "label_fr": "Swietno"
            },
            {
                "value": 74026,
                "label": "Rosnowko",
                "label_ar": "روسنوكو",
                "label_fr": "Rosnowko"
            },
            {
                "value": 74027,
                "label": "Klenica",
                "label_ar": "كلينيكا",
                "label_fr": "Klenica"
            },
            {
                "value": 74028,
                "label": "Zerkow",
                "label_ar": "زيركو",
                "label_fr": "Zerkow"
            },
            {
                "value": 74029,
                "label": "Budzyn",
                "label_ar": "بودزين",
                "label_fr": "Budzyn"
            },
            {
                "value": 74030,
                "label": "Wilkanowo",
                "label_ar": "ويلكانوفو",
                "label_fr": "Wilkanowo"
            },
            {
                "value": 74031,
                "label": "Iwiczna",
                "label_ar": "Iwiczna",
                "label_fr": "Iwiczna"
            },
            {
                "value": 74032,
                "label": "Zosin",
                "label_ar": "زوسين",
                "label_fr": "Zosin"
            },
            {
                "value": 74033,
                "label": "Ostrowek",
                "label_ar": "أوسترويك",
                "label_fr": "Ostrowek"
            },
            {
                "value": 74034,
                "label": "Cmielow",
                "label_ar": "Cmielow",
                "label_fr": "Cmielow"
            },
            {
                "value": 74035,
                "label": "Sandomierz",
                "label_ar": "ساندوميرز",
                "label_fr": "Sandomierz"
            },
            {
                "value": 74036,
                "label": "Rakow",
                "label_ar": "راكو",
                "label_fr": "Rakow"
            },
            {
                "value": 74037,
                "label": "Wachock",
                "label_ar": "واشوك",
                "label_fr": "Wachock"
            },
            {
                "value": 74038,
                "label": "Nowa Deba",
                "label_ar": "نووا ديبا",
                "label_fr": "Nowa Deba"
            },
            {
                "value": 74039,
                "label": "Sidra",
                "label_ar": "السدرة",
                "label_fr": "Sidra"
            },
            {
                "value": 74040,
                "label": "Maslow",
                "label_ar": "ماسلو",
                "label_fr": "Maslow"
            },
            {
                "value": 74041,
                "label": "Busko-Zdrój",
                "label_ar": "Busko-Zdrój",
                "label_fr": "Busko-Zdrój"
            },
            {
                "value": 74042,
                "label": "Michałowo",
                "label_ar": "ميشالوو",
                "label_fr": "Michałowo"
            },
            {
                "value": 74043,
                "label": "Tykocin",
                "label_ar": "تيكوسين",
                "label_fr": "Tykocin"
            },
            {
                "value": 74044,
                "label": "Bodzentyn",
                "label_ar": "بودزينتين",
                "label_fr": "Bodzentyn"
            },
            {
                "value": 74045,
                "label": "Iwaniska",
                "label_ar": "إيوانيسكا",
                "label_fr": "Iwaniska"
            },
            {
                "value": 74046,
                "label": "Kazimierza Wielka",
                "label_ar": "كازيميرزا ويلكا",
                "label_fr": "Kazimierza Wielka"
            },
            {
                "value": 74047,
                "label": "Bejsce",
                "label_ar": "Bejsce",
                "label_fr": "Bejsce"
            },
            {
                "value": 74048,
                "label": "Nowa Slupia",
                "label_ar": "نوا سلوبيا",
                "label_fr": "Nowa Slupia"
            },
            {
                "value": 74049,
                "label": "Deblin",
                "label_ar": "دبلين",
                "label_fr": "Deblin"
            },
            {
                "value": 74050,
                "label": "Ulez",
                "label_ar": "أوليز",
                "label_fr": "Ulez"
            },
            {
                "value": 74051,
                "label": "Suchedniow",
                "label_ar": "سوشيدنيو",
                "label_fr": "Suchedniow"
            },
            {
                "value": 74052,
                "label": "Mniow",
                "label_ar": "منيو",
                "label_fr": "Mniow"
            },
            {
                "value": 74053,
                "label": "Lipnik",
                "label_ar": "ليبنيك",
                "label_fr": "Lipnik"
            },
            {
                "value": 74054,
                "label": "Brańsk",
                "label_ar": "براسسك",
                "label_fr": "Brańsk"
            },
            {
                "value": 74055,
                "label": "Chmielnik",
                "label_ar": "شمييلنيك",
                "label_fr": "Chmielnik"
            },
            {
                "value": 74056,
                "label": "Gmina Gnojno",
                "label_ar": "جمينا كنوجنو",
                "label_fr": "Gmina Gnojno"
            },
            {
                "value": 74057,
                "label": "Gmina Sadowie",
                "label_ar": "غمينا سادوي",
                "label_fr": "Gmina Sadowie"
            },
            {
                "value": 74058,
                "label": "Jastkow",
                "label_ar": "جاستكو",
                "label_fr": "Jastkow"
            },
            {
                "value": 74059,
                "label": "Terespol",
                "label_ar": "تيريسبول",
                "label_fr": "Terespol"
            },
            {
                "value": 74060,
                "label": "Stopnica",
                "label_ar": "ستوبنيكا",
                "label_fr": "Stopnica"
            },
            {
                "value": 74061,
                "label": "Staszow",
                "label_ar": "ستاسزو",
                "label_fr": "Staszow"
            },
            {
                "value": 74062,
                "label": "Baranow",
                "label_ar": "بارانو",
                "label_fr": "Baranow"
            },
            {
                "value": 74063,
                "label": "Krosno",
                "label_ar": "كروسنو",
                "label_fr": "Krosno"
            },
            {
                "value": 74064,
                "label": "Narew",
                "label_ar": "نارو",
                "label_fr": "Narew"
            },
            {
                "value": 74065,
                "label": "Slawno B",
                "label_ar": "سلونو ب",
                "label_fr": "Slawno B"
            },
            {
                "value": 74066,
                "label": "Boćki",
                "label_ar": "Boćki",
                "label_fr": "Boćki"
            },
            {
                "value": 74067,
                "label": "Gmina Wyszki",
                "label_ar": "جمينا ويزكي",
                "label_fr": "Gmina Wyszki"
            },
            {
                "value": 74068,
                "label": "Zagnansk",
                "label_ar": "زاجنانسك",
                "label_fr": "Zagnansk"
            },
            {
                "value": 74069,
                "label": "Olsztynek",
                "label_ar": "أولشتينك",
                "label_fr": "Olsztynek"
            },
            {
                "value": 74070,
                "label": "Gmina Połaniec",
                "label_ar": "جمينا بويانيك",
                "label_fr": "Gmina Połaniec"
            },
            {
                "value": 74071,
                "label": "Humniska",
                "label_ar": "هامنسكا",
                "label_fr": "Humniska"
            },
            {
                "value": 74072,
                "label": "Choroszcz",
                "label_ar": "تشوروشز",
                "label_fr": "Choroszcz"
            },
            {
                "value": 74073,
                "label": "Kamien",
                "label_ar": "كامين",
                "label_fr": "Kamien"
            },
            {
                "value": 74074,
                "label": "Wola Kopcowa",
                "label_ar": "وولا كوبكاوا",
                "label_fr": "Wola Kopcowa"
            },
            {
                "value": 74075,
                "label": "Margonin",
                "label_ar": "مارجونين",
                "label_fr": "Margonin"
            },
            {
                "value": 74076,
                "label": "Barwice",
                "label_ar": "باروايس",
                "label_fr": "Barwice"
            },
            {
                "value": 74077,
                "label": "Wolow",
                "label_ar": "وولو",
                "label_fr": "Wolow"
            },
            {
                "value": 74078,
                "label": "Lubomierz",
                "label_ar": "لوبوميرز",
                "label_fr": "Lubomierz"
            },
            {
                "value": 74079,
                "label": "Winsko",
                "label_ar": "وينسكو",
                "label_fr": "Winsko"
            },
            {
                "value": 74080,
                "label": "Kamionki",
                "label_ar": "كاميونكي",
                "label_fr": "Kamionki"
            },
            {
                "value": 74081,
                "label": "Stare Kurowo",
                "label_ar": "ستاري كوروو",
                "label_fr": "Regarder Kurowo"
            },
            {
                "value": 74082,
                "label": "Osielsko",
                "label_ar": "أوسيلسكو",
                "label_fr": "Osielsko"
            },
            {
                "value": 74083,
                "label": "Gizalki",
                "label_ar": "جيزالكي",
                "label_fr": "Gizalki"
            },
            {
                "value": 74084,
                "label": "Gmina Dąbie",
                "label_ar": "جمينا ديبي",
                "label_fr": "Gmina Dąbie"
            },
            {
                "value": 74085,
                "label": "Dzikowiec",
                "label_ar": "دزيكوفيتش",
                "label_fr": "Dzikowiec"
            },
            {
                "value": 74086,
                "label": "Stara Kamienica",
                "label_ar": "ستارا كامينيكا",
                "label_fr": "Stara Kamienica"
            },
            {
                "value": 74087,
                "label": "Miszkowice",
                "label_ar": "ميسكوفيتسي",
                "label_fr": "Miszkowice"
            },
            {
                "value": 74088,
                "label": "Czarny Bor",
                "label_ar": "كزارني بور",
                "label_fr": "Czarny Bor"
            },
            {
                "value": 74089,
                "label": "Bogdaszowice",
                "label_ar": "بوغداشوفيتسي",
                "label_fr": "Bogdaszowice"
            },
            {
                "value": 74090,
                "label": "Domaslaw",
                "label_ar": "دوماسلو",
                "label_fr": "Domaslaw"
            },
            {
                "value": 74091,
                "label": "Strzeszow",
                "label_ar": "Strzeszow",
                "label_fr": "Strzeszow"
            },
            {
                "value": 74092,
                "label": "Zloty Stok",
                "label_ar": "زلوتي ستوك",
                "label_fr": "Zloty Stok"
            },
            {
                "value": 74093,
                "label": "Milicz",
                "label_ar": "ميليتش",
                "label_fr": "Milicz"
            },
            {
                "value": 74094,
                "label": "Drawsko Pomorskie",
                "label_ar": "دروسكو بومورسكي",
                "label_fr": "Drawsko Pomorskie"
            },
            {
                "value": 74095,
                "label": "Msciwojow",
                "label_ar": "مشيووجو",
                "label_fr": "Msciwojow"
            },
            {
                "value": 74096,
                "label": "Sepolno Krajenskie",
                "label_ar": "سيبولنو كراجينسكي",
                "label_fr": "Sepolno Krajenskie"
            },
            {
                "value": 74097,
                "label": "Gmina Bobrowo",
                "label_ar": "جمينا بوبروو",
                "label_fr": "Gmina Bobrowo"
            },
            {
                "value": 74098,
                "label": "Brzeg",
                "label_ar": "برزيج",
                "label_fr": "Brzeg"
            },
            {
                "value": 74099,
                "label": "Kudowa-Zdrój",
                "label_ar": "Kudowa-Zdrój",
                "label_fr": "Kudowa-Zdrój"
            },
            {
                "value": 74100,
                "label": "Gmina Bądkowo",
                "label_ar": "غمينا بدكوو",
                "label_fr": "Gmina Bądkowo"
            },
            {
                "value": 74101,
                "label": "Przysiek",
                "label_ar": "برزيسيك",
                "label_fr": "Przysiek"
            },
            {
                "value": 74102,
                "label": "Wystep",
                "label_ar": "ويستب",
                "label_fr": "Wystep"
            },
            {
                "value": 74103,
                "label": "Bochowo",
                "label_ar": "Bochowo",
                "label_fr": "Bochowo"
            },
            {
                "value": 74104,
                "label": "Gniewkowo",
                "label_ar": "Gniewkowo",
                "label_fr": "Gniewkowo"
            },
            {
                "value": 74105,
                "label": "Osiek",
                "label_ar": "Osiek",
                "label_fr": "Osiek"
            },
            {
                "value": 74106,
                "label": "Dabrowa Chelminska",
                "label_ar": "دابروا شلمينسكا",
                "label_fr": "Dabrowa Chelminska"
            },
            {
                "value": 74107,
                "label": "Waganiec",
                "label_ar": "واغانيك",
                "label_fr": "Waganiec"
            },
            {
                "value": 74108,
                "label": "Swiekatowo",
                "label_ar": "Swiekatowo",
                "label_fr": "Swiekatowo"
            },
            {
                "value": 74109,
                "label": "Ciechocin",
                "label_ar": "سيتشوسين",
                "label_fr": "Ciechocin"
            },
            {
                "value": 74110,
                "label": "Kołobrzeg",
                "label_ar": "كولوبرزيغ",
                "label_fr": "Kołobrzeg"
            },
            {
                "value": 74111,
                "label": "Kobierzyce",
                "label_ar": "Kobierzyce",
                "label_fr": "Kobierzyce"
            },
            {
                "value": 74112,
                "label": "Pilawa",
                "label_ar": "بيلاوا",
                "label_fr": "Pilawa"
            },
            {
                "value": 74113,
                "label": "Żuromin",
                "label_ar": "أورومين",
                "label_fr": "Żuromin"
            },
            {
                "value": 74114,
                "label": "Latowicz",
                "label_ar": "لاتوفيتش",
                "label_fr": "Latowicz"
            },
            {
                "value": 74115,
                "label": "Beblo",
                "label_ar": "بيبلو",
                "label_fr": "Beblo"
            },
            {
                "value": 74116,
                "label": "Gmina Chmielno",
                "label_ar": "جمينا شميلنو",
                "label_fr": "Gmina Chmielno"
            },
            {
                "value": 74117,
                "label": "Zblewo",
                "label_ar": "Zblewo",
                "label_fr": "Zblewo"
            },
            {
                "value": 74118,
                "label": "Mława",
                "label_ar": "موا",
                "label_fr": "Mława"
            },
            {
                "value": 74119,
                "label": "Racula",
                "label_ar": "راكولا",
                "label_fr": "Racula"
            },
            {
                "value": 74120,
                "label": "Smolnik",
                "label_ar": "سمولنيك",
                "label_fr": "Smolnik"
            },
            {
                "value": 74121,
                "label": "Laska",
                "label_ar": "لاسكا",
                "label_fr": "Laska"
            },
            {
                "value": 74122,
                "label": "Pinczow",
                "label_ar": "بينتشوف",
                "label_fr": "Pinczow"
            },
            {
                "value": 74123,
                "label": "Tolkmicko",
                "label_ar": "تولكميكو",
                "label_fr": "Tolkmicko"
            },
            {
                "value": 74124,
                "label": "Lacko",
                "label_ar": "لاكو",
                "label_fr": "Manque de"
            },
            {
                "value": 74125,
                "label": "Szydlowo",
                "label_ar": "Szydlowo",
                "label_fr": "Szydlowo"
            },
            {
                "value": 74126,
                "label": "Przechlewo",
                "label_ar": "برزيشلو",
                "label_fr": "Przechlewo"
            },
            {
                "value": 74127,
                "label": "Supraśl",
                "label_ar": "سوبرايل",
                "label_fr": "Supraśl"
            },
            {
                "value": 74128,
                "label": "Kielczow",
                "label_ar": "كيلشوف",
                "label_fr": "Kielczow"
            },
            {
                "value": 74129,
                "label": "Balice",
                "label_ar": "باليس",
                "label_fr": "Balice"
            },
            {
                "value": 74130,
                "label": "Makow",
                "label_ar": "ماكو",
                "label_fr": "Makow"
            },
            {
                "value": 74131,
                "label": "Lotyn",
                "label_ar": "لوتين",
                "label_fr": "Lotyn"
            },
            {
                "value": 74132,
                "label": "Kotlin",
                "label_ar": "كوتلن",
                "label_fr": "Kotlin"
            },
            {
                "value": 74133,
                "label": "Stolno",
                "label_ar": "ستولنو",
                "label_fr": "Stolno"
            },
            {
                "value": 74134,
                "label": "Sucha Beskidzka",
                "label_ar": "سوكا بيسكيدزكا",
                "label_fr": "Sucha Beskidzka"
            },
            {
                "value": 74135,
                "label": "Hucisko Jawornickie",
                "label_ar": "Hucisko Jawornickie",
                "label_fr": "Hucisko Jawornickie"
            },
            {
                "value": 74136,
                "label": "Kobior",
                "label_ar": "كوبيور",
                "label_fr": "Kobior"
            },
            {
                "value": 74137,
                "label": "Ledziny",
                "label_ar": "ليدزينى",
                "label_fr": "Ledziny"
            },
            {
                "value": 74138,
                "label": "Rybno",
                "label_ar": "ريبنو",
                "label_fr": "Rybno"
            },
            {
                "value": 74139,
                "label": "Milanówek",
                "label_ar": "ميلانويك",
                "label_fr": "Milanówek"
            },
            {
                "value": 74140,
                "label": "Mogilany",
                "label_ar": "موجيلاني",
                "label_fr": "Mogilany"
            },
            {
                "value": 74141,
                "label": "Rozprza",
                "label_ar": "روزبرزا",
                "label_fr": "Rozprza"
            },
            {
                "value": 74142,
                "label": "Dziekanow Lesny",
                "label_ar": "دزيكانو ليسني",
                "label_fr": "Dziekanow Lesny"
            },
            {
                "value": 74143,
                "label": "Wolka",
                "label_ar": "وولكا",
                "label_fr": "Wolka"
            },
            {
                "value": 74144,
                "label": "Raciazek",
                "label_ar": "راسيازيك",
                "label_fr": "Raciazek"
            },
            {
                "value": 74145,
                "label": "Dobra",
                "label_ar": "دوبرا",
                "label_fr": "Dobra"
            },
            {
                "value": 74146,
                "label": "Staniatki",
                "label_ar": "ستانياتكي",
                "label_fr": "Staniatki"
            },
            {
                "value": 74147,
                "label": "Zagorow",
                "label_ar": "زاغورو",
                "label_fr": "Zagorow"
            },
            {
                "value": 74148,
                "label": "Pegow",
                "label_ar": "بيجو",
                "label_fr": "Pegow"
            },
            {
                "value": 74149,
                "label": "Trzebnica",
                "label_ar": "ترزبنيكا",
                "label_fr": "Trzebnica"
            },
            {
                "value": 74150,
                "label": "Zwolen",
                "label_ar": "زولين",
                "label_fr": "Zwolen"
            },
            {
                "value": 74151,
                "label": "Myslenice",
                "label_ar": "ميسلينيس",
                "label_fr": "Myslenice"
            },
            {
                "value": 74152,
                "label": "Rzgow Pierwszy",
                "label_ar": "Rzgow Pierwszy",
                "label_fr": "Rzgow Pierwszy"
            },
            {
                "value": 74153,
                "label": "Rawa Mazowiecka",
                "label_ar": "راوا مازوييكا",
                "label_fr": "Rawa Mazowiecka"
            },
            {
                "value": 74154,
                "label": "Regimin",
                "label_ar": "نظام",
                "label_fr": "Regimin"
            },
            {
                "value": 74155,
                "label": "Tarczyn",
                "label_ar": "Tarczyn",
                "label_fr": "Tarczyn"
            },
            {
                "value": 74156,
                "label": "Pułtusk",
                "label_ar": "Puustusk",
                "label_fr": "Pułtusk"
            },
            {
                "value": 74157,
                "label": "Ksieginice",
                "label_ar": "كسيجينس",
                "label_fr": "Ksieginice"
            },
            {
                "value": 74158,
                "label": "Golczewo",
                "label_ar": "غولكزيو",
                "label_fr": "Golczewo"
            },
            {
                "value": 74159,
                "label": "Swieta Katarzyna",
                "label_ar": "سوييتا كاتارزينا",
                "label_fr": "Swieta Katarzyna"
            },
            {
                "value": 74160,
                "label": "Santok",
                "label_ar": "سانتوك",
                "label_fr": "Santok"
            },
            {
                "value": 74161,
                "label": "Wloszczowa",
                "label_ar": "Wloszczowa",
                "label_fr": "Wloszczowa"
            },
            {
                "value": 74162,
                "label": "Chylice",
                "label_ar": "كيليس",
                "label_fr": "Chylice"
            },
            {
                "value": 74163,
                "label": "Bralin",
                "label_ar": "برالين",
                "label_fr": "Bralin"
            },
            {
                "value": 74164,
                "label": "Gmina Książ Wielkopolski",
                "label_ar": "جمينا كسيتش ويلكوبولسكي",
                "label_fr": "Gmina Książ Wielkopolski"
            },
            {
                "value": 74165,
                "label": "Targanice",
                "label_ar": "Targanice",
                "label_fr": "Targanice"
            },
            {
                "value": 74166,
                "label": "Sztum",
                "label_ar": "شتوم",
                "label_fr": "Sztum"
            },
            {
                "value": 74167,
                "label": "Kostrzyn nad Odra",
                "label_ar": "Kostrzyn ناد أودرا",
                "label_fr": "Kostrzyn nad Odra"
            },
            {
                "value": 74168,
                "label": "Czluchow",
                "label_ar": "كلوشوف",
                "label_fr": "Czluchow"
            },
            {
                "value": 74169,
                "label": "Radzionkow",
                "label_ar": "رادزيونكو",
                "label_fr": "Radzionkow"
            },
            {
                "value": 74170,
                "label": "Wola Krzysztoporska",
                "label_ar": "Wola Krzysztoporska",
                "label_fr": "Wola Krzysztoporska"
            },
            {
                "value": 74171,
                "label": "Pionki",
                "label_ar": "بيونكي",
                "label_fr": "Pionki"
            },
            {
                "value": 74172,
                "label": "Brzostowka",
                "label_ar": "Brzostowka",
                "label_fr": "Brzostowka"
            },
            {
                "value": 74173,
                "label": "Oborniki",
                "label_ar": "أوبورنيكي",
                "label_fr": "Oborniki"
            },
            {
                "value": 74174,
                "label": "Gmina Stąporków",
                "label_ar": "Gmina Stąporków",
                "label_fr": "Gmina Stąporków"
            },
            {
                "value": 74175,
                "label": "Turobin",
                "label_ar": "توروبين",
                "label_fr": "Turobin"
            },
            {
                "value": 74176,
                "label": "Cwiklice",
                "label_ar": "كويكليس",
                "label_fr": "Cwiklice"
            },
            {
                "value": 74177,
                "label": "Końskowola",
                "label_ar": "Końskowola",
                "label_fr": "Końskowola"
            },
            {
                "value": 74178,
                "label": "Jemielnica",
                "label_ar": "جيميلنيكا",
                "label_fr": "Jemielnica"
            },
            {
                "value": 74179,
                "label": "Dobrodzien",
                "label_ar": "دوبرودزين",
                "label_fr": "Dobrodzien"
            },
            {
                "value": 74180,
                "label": "Lidzbark Warmiński",
                "label_ar": "Lidzbark Warmiski",
                "label_fr": "Lidzbark Warmiński"
            },
            {
                "value": 74181,
                "label": "Mszana Dolna",
                "label_ar": "مسزانا دولنا",
                "label_fr": "Mszana Dolna"
            },
            {
                "value": 74182,
                "label": "Andrychow",
                "label_ar": "أندريشو",
                "label_fr": "Andrychow"
            },
            {
                "value": 74183,
                "label": "Ruda",
                "label_ar": "روضة",
                "label_fr": "Ruda"
            },
            {
                "value": 74184,
                "label": "Nieporęt",
                "label_ar": "نيبورت",
                "label_fr": "Nieporęt"
            },
            {
                "value": 74185,
                "label": "Gizyce",
                "label_ar": "جيزيس",
                "label_fr": "Gizyce"
            },
            {
                "value": 74186,
                "label": "Tyszowce",
                "label_ar": "تايسزو",
                "label_fr": "Tyszowce"
            },
            {
                "value": 74187,
                "label": "Krzyszkowice",
                "label_ar": "Krzyszkowice",
                "label_fr": "Krzyszkowice"
            },
            {
                "value": 74188,
                "label": "Grojec",
                "label_ar": "جروجيك",
                "label_fr": "Grojec"
            },
            {
                "value": 74189,
                "label": "Scinawa Nyska",
                "label_ar": "سينوا نيسكا",
                "label_fr": "Scinawa Nyska"
            },
            {
                "value": 74190,
                "label": "Kamieniec",
                "label_ar": "كامينيك",
                "label_fr": "Kamieniec"
            },
            {
                "value": 74191,
                "label": "Otoki",
                "label_ar": "أوتوكي",
                "label_fr": "Otoki"
            },
            {
                "value": 74192,
                "label": "Ilowo",
                "label_ar": "Ilowo",
                "label_fr": "Ilowo"
            },
            {
                "value": 74193,
                "label": "Kowal",
                "label_ar": "كوال",
                "label_fr": "Kowal"
            },
            {
                "value": 74194,
                "label": "Bielsk Podlaski",
                "label_ar": "بيلسك بودلاسكي",
                "label_fr": "Bielsk Podlaski"
            },
            {
                "value": 74195,
                "label": "Wschowa",
                "label_ar": "وشوا",
                "label_fr": "Wschowa"
            },
            {
                "value": 74196,
                "label": "Krosno Odrzanskie",
                "label_ar": "كروسنو أودرزانسكي",
                "label_fr": "Krosno Odrzanskie"
            },
            {
                "value": 74197,
                "label": "Dukla",
                "label_ar": "دوكلا",
                "label_fr": "Dukla"
            },
            {
                "value": 74198,
                "label": "Bazanowka",
                "label_ar": "بازانوكا",
                "label_fr": "Bazanowka"
            },
            {
                "value": 74199,
                "label": "Gogolin",
                "label_ar": "جوجولين",
                "label_fr": "Gogolin"
            },
            {
                "value": 74200,
                "label": "Złotoryja",
                "label_ar": "Złotoryja",
                "label_fr": "Złotoryja"
            },
            {
                "value": 74201,
                "label": "Bielkowo",
                "label_ar": "بيلكوو",
                "label_fr": "Bielkowo"
            },
            {
                "value": 74202,
                "label": "Gołdap",
                "label_ar": "Gołdap",
                "label_fr": "Gołdap"
            },
            {
                "value": 74203,
                "label": "Tuchola",
                "label_ar": "توتشولا",
                "label_fr": "Tuchola"
            },
            {
                "value": 74204,
                "label": "Brzeg Dolny",
                "label_ar": "برزيج دولني",
                "label_fr": "Brzeg Dolny"
            },
            {
                "value": 74205,
                "label": "Opoczno",
                "label_ar": "أوبوتشنو",
                "label_fr": "Opoczno"
            },
            {
                "value": 74206,
                "label": "Kowiesy",
                "label_ar": "كويزي",
                "label_fr": "Kowiesy"
            },
            {
                "value": 74207,
                "label": "Jedlicze",
                "label_ar": "جيدليش",
                "label_fr": "Jedlicze"
            },
            {
                "value": 74208,
                "label": "Karpacz",
                "label_ar": "كارباتش",
                "label_fr": "Karpacz"
            },
            {
                "value": 74209,
                "label": "Gronowo Elblaskie",
                "label_ar": "Gronowo Elblaskie",
                "label_fr": "Gronowo Elblaskie"
            },
            {
                "value": 74210,
                "label": "Zrecin",
                "label_ar": "زرسين",
                "label_fr": "Zrecin"
            },
            {
                "value": 74211,
                "label": "Kętrzyn",
                "label_ar": "كورتزين",
                "label_fr": "Kętrzyn"
            },
            {
                "value": 74212,
                "label": "Niemcz",
                "label_ar": "نيمتش",
                "label_fr": "Niemcz"
            },
            {
                "value": 74213,
                "label": "Wylatowo",
                "label_ar": "Wylatowo",
                "label_fr": "Wylatowo"
            },
            {
                "value": 74214,
                "label": "Krzepice",
                "label_ar": "كرزيبيتسي",
                "label_fr": "Krzepice"
            },
            {
                "value": 74215,
                "label": "Żychlin",
                "label_ar": "شيكلن",
                "label_fr": "Żychlin"
            },
            {
                "value": 74216,
                "label": "Zdzieszowice",
                "label_ar": "Zdzieszowice",
                "label_fr": "Zdzieszowice"
            },
            {
                "value": 74217,
                "label": "Szczucin",
                "label_ar": "شتشوسين",
                "label_fr": "Szczucin"
            },
            {
                "value": 74218,
                "label": "Debno",
                "label_ar": "ديبنو",
                "label_fr": "Debno"
            },
            {
                "value": 74219,
                "label": "Wisla",
                "label_ar": "ويسلا",
                "label_fr": "Wisla"
            },
            {
                "value": 74220,
                "label": "Kazimierz Biskupi",
                "label_ar": "كازيميرز بيسكوبي",
                "label_fr": "Kazimierz Biskupi"
            },
            {
                "value": 74221,
                "label": "Kolbuszowa",
                "label_ar": "كولبوسوفا",
                "label_fr": "Kolbuszowa"
            },
            {
                "value": 74222,
                "label": "Skarszewy",
                "label_ar": "Skarszewy",
                "label_fr": "Skarszewy"
            },
            {
                "value": 74223,
                "label": "Wlodawa",
                "label_ar": "ولوداوا",
                "label_fr": "Wlodawa"
            },
            {
                "value": 74224,
                "label": "Dygowo",
                "label_ar": "ديغوو",
                "label_fr": "Dygowo"
            },
            {
                "value": 74225,
                "label": "Lublewo",
                "label_ar": "لوبليو",
                "label_fr": "Lublewo"
            },
            {
                "value": 74226,
                "label": "Rogow",
                "label_ar": "روجو",
                "label_fr": "Rogow"
            },
            {
                "value": 74227,
                "label": "Tymowa",
                "label_ar": "تيموا",
                "label_fr": "Tymowa"
            },
            {
                "value": 74228,
                "label": "Ostrow",
                "label_ar": "اوسترو",
                "label_fr": "Ostrow"
            },
            {
                "value": 74229,
                "label": "Dziwnow",
                "label_ar": "Dziwnow",
                "label_fr": "Dziwnow"
            },
            {
                "value": 74230,
                "label": "Chelmsko Slaskie",
                "label_ar": "تشيلمسكو سلاسكي",
                "label_fr": "Chelmsko Slaskie"
            },
            {
                "value": 74231,
                "label": "Bolewice",
                "label_ar": "بوليويس",
                "label_fr": "Bolewice"
            },
            {
                "value": 74232,
                "label": "Sycow",
                "label_ar": "Sycow",
                "label_fr": "Sycow"
            },
            {
                "value": 74233,
                "label": "Miroslaw",
                "label_ar": "ميروسلاف",
                "label_fr": "Miroslaw"
            },
            {
                "value": 74234,
                "label": "Witaszyce",
                "label_ar": "ويتاسيسي",
                "label_fr": "Witaszyce"
            },
            {
                "value": 74235,
                "label": "Ropczyce",
                "label_ar": "Ropczyce",
                "label_fr": "Ropczyce"
            },
            {
                "value": 74236,
                "label": "Lubanie",
                "label_ar": "لوباني",
                "label_fr": "Lubanie"
            },
            {
                "value": 74237,
                "label": "Przeclaw",
                "label_ar": "برزكلاو",
                "label_fr": "Przeclaw"
            },
            {
                "value": 74238,
                "label": "Rewa",
                "label_ar": "ريوا",
                "label_fr": "Rewa"
            },
            {
                "value": 74239,
                "label": "Nidzica",
                "label_ar": "Nidzica",
                "label_fr": "Nidzica"
            },
            {
                "value": 74240,
                "label": "Orneta",
                "label_ar": "أورنيتا",
                "label_fr": "Orneta"
            },
            {
                "value": 74241,
                "label": "Rzepin",
                "label_ar": "رزيبين",
                "label_fr": "Rzepin"
            },
            {
                "value": 74242,
                "label": "Barlinek",
                "label_ar": "بارلينك",
                "label_fr": "Barlinek"
            },
            {
                "value": 74243,
                "label": "Pszczyna",
                "label_ar": "Pszczyna",
                "label_fr": "Pszczyna"
            },
            {
                "value": 74244,
                "label": "Stara",
                "label_ar": "ستارا",
                "label_fr": "Stara"
            },
            {
                "value": 74245,
                "label": "Ksiazenice",
                "label_ar": "كسيازينيتسي",
                "label_fr": "Ksiazenice"
            },
            {
                "value": 74246,
                "label": "Kocmyrzow",
                "label_ar": "Kocmyrzow",
                "label_fr": "Kocmyrzow"
            },
            {
                "value": 74247,
                "label": "Stryszow",
                "label_ar": "ستريسزو",
                "label_fr": "Stryszow"
            },
            {
                "value": 74248,
                "label": "Augustów",
                "label_ar": "Augustów",
                "label_fr": "Augustów"
            },
            {
                "value": 74249,
                "label": "Mosty",
                "label_ar": "موستي",
                "label_fr": "Mosty"
            },
            {
                "value": 74250,
                "label": "Labiszyn",
                "label_ar": "لابيزين",
                "label_fr": "Labiszyn"
            },
            {
                "value": 74251,
                "label": "Gmina Moszczenica",
                "label_ar": "غمينا موسززينيكا",
                "label_fr": "Gmina Moszczenica"
            },
            {
                "value": 74252,
                "label": "Krokowa",
                "label_ar": "كروكووا",
                "label_fr": "Krokowa"
            },
            {
                "value": 74253,
                "label": "Zegrze Pomorskie",
                "label_ar": "زجرزي بومورسكي",
                "label_fr": "Zegrze Pomorskie"
            },
            {
                "value": 74254,
                "label": "Prudnik",
                "label_ar": "برودنيك",
                "label_fr": "Prudnik"
            },
            {
                "value": 74255,
                "label": "Konarzyce",
                "label_ar": "Konarzyce",
                "label_fr": "Konarzyce"
            },
            {
                "value": 74256,
                "label": "Popowice",
                "label_ar": "Popowice",
                "label_fr": "Popowice"
            },
            {
                "value": 74257,
                "label": "Nowy Dwor",
                "label_ar": "نوي دور",
                "label_fr": "Nowy Dwor"
            },
            {
                "value": 74258,
                "label": "Krzyki-Partynice",
                "label_ar": "Krzyki-Partynice",
                "label_fr": "Krzyki-Partynice"
            },
            {
                "value": 74259,
                "label": "Lobez",
                "label_ar": "لوبيز",
                "label_fr": "Lobez"
            },
            {
                "value": 74260,
                "label": "Kowalew",
                "label_ar": "كوالو",
                "label_fr": "Kowalew"
            },
            {
                "value": 74261,
                "label": "Działdowo",
                "label_ar": "Działdowo",
                "label_fr": "Działdowo"
            },
            {
                "value": 74262,
                "label": "Piaski",
                "label_ar": "بياسكي",
                "label_fr": "Piaski"
            },
            {
                "value": 74263,
                "label": "Grodki",
                "label_ar": "غرودكي",
                "label_fr": "Grodki"
            },
            {
                "value": 74264,
                "label": "Rokietnica",
                "label_ar": "روكيتنيكا",
                "label_fr": "Rokietnica"
            },
            {
                "value": 74265,
                "label": "Lubnice",
                "label_ar": "لوبنيتسي",
                "label_fr": "Lubnice"
            },
            {
                "value": 74266,
                "label": "Mialy",
                "label_ar": "ميالي",
                "label_fr": "Mialy"
            },
            {
                "value": 74267,
                "label": "Chelmek",
                "label_ar": "شيلميك",
                "label_fr": "Chelmek"
            },
            {
                "value": 74268,
                "label": "Kliniska",
                "label_ar": "كلينيسكا",
                "label_fr": "Kliniska"
            },
            {
                "value": 74269,
                "label": "Brodnica",
                "label_ar": "برودنيكا",
                "label_fr": "Brodnica"
            },
            {
                "value": 74270,
                "label": "Krepa Kaszubska",
                "label_ar": "كريبا كاشوبسكا",
                "label_fr": "Krepa Kaszubska"
            },
            {
                "value": 74271,
                "label": "Wicko",
                "label_ar": "ويكو",
                "label_fr": "Wicko"
            },
            {
                "value": 74272,
                "label": "Konczyce Male",
                "label_ar": "ذكر Konczyce",
                "label_fr": "Konczyce Male"
            },
            {
                "value": 74273,
                "label": "Kozmin Wielkopolski",
                "label_ar": "كوزمين ويلكوبولسكي",
                "label_fr": "Kozmin Wielkopolski"
            },
            {
                "value": 74274,
                "label": "Zebrzydowice",
                "label_ar": "Zebrzydowice",
                "label_fr": "Zebrzydowice"
            },
            {
                "value": 74275,
                "label": "Jelesnia",
                "label_ar": "جيلسنيا",
                "label_fr": "Jelesnia"
            },
            {
                "value": 74276,
                "label": "Susz",
                "label_ar": "سوز",
                "label_fr": "Susz"
            },
            {
                "value": 74277,
                "label": "Cybinka",
                "label_ar": "سايبينكا",
                "label_fr": "Cybinka"
            },
            {
                "value": 74278,
                "label": "Gluszyca",
                "label_ar": "جلوسزيكا",
                "label_fr": "Gluszyca"
            },
            {
                "value": 74279,
                "label": "Borkowice",
                "label_ar": "بوركوفيتسي",
                "label_fr": "Borkowice"
            },
            {
                "value": 74280,
                "label": "Iwla",
                "label_ar": "إيولا",
                "label_fr": "Iwla"
            },
            {
                "value": 74281,
                "label": "Kozy",
                "label_ar": "كوزي",
                "label_fr": "Kozy"
            },
            {
                "value": 74282,
                "label": "Gora Pulawska",
                "label_ar": "جورا بولاوسكا",
                "label_fr": "Gora Pulawska"
            },
            {
                "value": 74283,
                "label": "Stary Garbow",
                "label_ar": "ستاري جاربو",
                "label_fr": "Guirlande stary"
            },
            {
                "value": 74284,
                "label": "Jablonna",
                "label_ar": "جابلوننا",
                "label_fr": "Jablonna"
            },
            {
                "value": 74285,
                "label": "Pobiedna",
                "label_ar": "بوبييدنا",
                "label_fr": "Pobiedna"
            },
            {
                "value": 74286,
                "label": "Chocianow",
                "label_ar": "شوشيانو",
                "label_fr": "Chocianow"
            },
            {
                "value": 74287,
                "label": "Pajeczno",
                "label_ar": "باجيسنو",
                "label_fr": "Pajeczno"
            },
            {
                "value": 74288,
                "label": "Rożental",
                "label_ar": "روزنتال",
                "label_fr": "Rożental"
            },
            {
                "value": 74289,
                "label": "Nowy Targ",
                "label_ar": "Nowy Targ",
                "label_fr": "Nowy Targ"
            },
            {
                "value": 74290,
                "label": "Dobra",
                "label_ar": "دوبرا",
                "label_fr": "Dobra"
            },
            {
                "value": 74291,
                "label": "Olesno",
                "label_ar": "Olesno",
                "label_fr": "Olesno"
            },
            {
                "value": 74292,
                "label": "Sulejow",
                "label_ar": "سوليجو",
                "label_fr": "Sulejow"
            },
            {
                "value": 74293,
                "label": "Lubawa",
                "label_ar": "لوباوا",
                "label_fr": "Lubawa"
            },
            {
                "value": 74294,
                "label": "Dzwierzuty",
                "label_ar": "دزويرزوتي",
                "label_fr": "Dzwierzuty"
            },
            {
                "value": 74295,
                "label": "Blizne",
                "label_ar": "بليزن",
                "label_fr": "Blizne"
            },
            {
                "value": 74296,
                "label": "Kopki",
                "label_ar": "كوبكي",
                "label_fr": "Kopki"
            },
            {
                "value": 74297,
                "label": "Rawicz",
                "label_ar": "راويتش",
                "label_fr": "Rawicz"
            },
            {
                "value": 74298,
                "label": "Bystra",
                "label_ar": "بيسترا",
                "label_fr": "Bystra"
            },
            {
                "value": 74299,
                "label": "Katy",
                "label_ar": "كاتي",
                "label_fr": "Katy"
            },
            {
                "value": 74300,
                "label": "Jordanow",
                "label_ar": "جوردانوف",
                "label_fr": "Jordanow"
            },
            {
                "value": 74301,
                "label": "Czernichow",
                "label_ar": "تشيرنيشو",
                "label_fr": "Czernichow"
            },
            {
                "value": 74302,
                "label": "Pruchna",
                "label_ar": "بروشنا",
                "label_fr": "Pruchna"
            },
            {
                "value": 74303,
                "label": "Jawiszowice",
                "label_ar": "Jawiszowice",
                "label_fr": "Jawiszowice"
            },
            {
                "value": 74304,
                "label": "Stadla",
                "label_ar": "ستادلا",
                "label_fr": "Stadla"
            },
            {
                "value": 74305,
                "label": "Walim",
                "label_ar": "وليام",
                "label_fr": "Walim"
            },
            {
                "value": 74306,
                "label": "Gmina Miękinia",
                "label_ar": "غمينا ميكينيا",
                "label_fr": "Gmina Miękinia"
            },
            {
                "value": 74307,
                "label": "Rydułtowy",
                "label_ar": "Rydułtowy",
                "label_fr": "Rydułtowy"
            },
            {
                "value": 74308,
                "label": "Milkowice",
                "label_ar": "Milkowice",
                "label_fr": "Milkowice"
            },
            {
                "value": 74309,
                "label": "Poczesna",
                "label_ar": "بوكزيسنا",
                "label_fr": "Poczesna"
            },
            {
                "value": 74310,
                "label": "Mierzecice",
                "label_ar": "ميرزيسيتسي",
                "label_fr": "Mierzecice"
            },
            {
                "value": 74311,
                "label": "Klobuck",
                "label_ar": "كلوبوك",
                "label_fr": "Klobuck"
            },
            {
                "value": 74312,
                "label": "Raszowka",
                "label_ar": "Raszowka",
                "label_fr": "Raszowka"
            },
            {
                "value": 74313,
                "label": "Wieloglowy",
                "label_ar": "Wieloglowy",
                "label_fr": "Wieloglowy"
            },
            {
                "value": 74314,
                "label": "Koniusza",
                "label_ar": "كونيوزا",
                "label_fr": "Koniusza"
            },
            {
                "value": 74315,
                "label": "Wambierzyce",
                "label_ar": "Wambierzyce",
                "label_fr": "Wambierzyce"
            },
            {
                "value": 74316,
                "label": "Sokolowsko",
                "label_ar": "سوكولوسكو",
                "label_fr": "Sokolowsko"
            },
            {
                "value": 74317,
                "label": "Ostroszowice",
                "label_ar": "أوستروسوفيتسه",
                "label_fr": "Ostroszowice"
            },
            {
                "value": 74318,
                "label": "Gmina Borów",
                "label_ar": "غمينا بورو",
                "label_fr": "Gmina Borów"
            },
            {
                "value": 74319,
                "label": "Pila Koscielecka",
                "label_ar": "بيلا كوسيليكا",
                "label_fr": "Pila Koscielecka"
            },
            {
                "value": 74320,
                "label": "Piasek",
                "label_ar": "بياسيك",
                "label_fr": "Piasek"
            },
            {
                "value": 74321,
                "label": "Paniowki",
                "label_ar": "بانيوفكي",
                "label_fr": "Paniowki"
            },
            {
                "value": 74322,
                "label": "Nowogrodziec",
                "label_ar": "Nowogrodziec",
                "label_fr": "Nowogrodziec"
            },
            {
                "value": 74323,
                "label": "Boguszów-Gorce",
                "label_ar": "Boguszów-Gorce",
                "label_fr": "Boguszów-Gorce"
            },
            {
                "value": 74324,
                "label": "Bobrowniki",
                "label_ar": "بوبروونيكي",
                "label_fr": "Bobrowniki"
            },
            {
                "value": 74325,
                "label": "Trabki",
                "label_ar": "طرابكي",
                "label_fr": "Trabki"
            },
            {
                "value": 74326,
                "label": "Wilczyn",
                "label_ar": "ويلتشين",
                "label_fr": "Wilczyn"
            },
            {
                "value": 74327,
                "label": "Toszek",
                "label_ar": "توسيك",
                "label_fr": "Toszek"
            },
            {
                "value": 74328,
                "label": "Kedzierzyn-Kozle",
                "label_ar": "كيدزيرزين كوزلي",
                "label_fr": "Kedzierzyn-Kozle"
            },
            {
                "value": 74329,
                "label": "Luborzyca",
                "label_ar": "لوبورزيكا",
                "label_fr": "Luborzyca"
            },
            {
                "value": 74330,
                "label": "Klikuszowa",
                "label_ar": "كليكوسوفا",
                "label_fr": "Klikuszowa"
            },
            {
                "value": 74331,
                "label": "Kalwaria Zebrzydowska",
                "label_ar": "كالواريا زيبيرزيدوسكا",
                "label_fr": "Kalwaria Zebrzydowska"
            },
            {
                "value": 74332,
                "label": "Boleslaw",
                "label_ar": "بوليسلو",
                "label_fr": "Boleslaw"
            },
            {
                "value": 74333,
                "label": "Wisniowa",
                "label_ar": "ويسنيوا",
                "label_fr": "Wisniowa"
            },
            {
                "value": 74334,
                "label": "Orzesze",
                "label_ar": "أورزيزيه",
                "label_fr": "Orzesze"
            },
            {
                "value": 74335,
                "label": "Karniowice",
                "label_ar": "كارنيوفيتسي",
                "label_fr": "Karniowice"
            },
            {
                "value": 74336,
                "label": "Suloszowa",
                "label_ar": "سولوسوفا",
                "label_fr": "Suloszowa"
            },
            {
                "value": 74337,
                "label": "Niepolomice",
                "label_ar": "نيبولوميس",
                "label_fr": "Niepolomice"
            },
            {
                "value": 74338,
                "label": "Malczyce",
                "label_ar": "مالشيسي",
                "label_fr": "Malczyce"
            },
            {
                "value": 74339,
                "label": "Jankowice",
                "label_ar": "جانكويس",
                "label_fr": "Jankowice"
            },
            {
                "value": 74340,
                "label": "Wrzosowa",
                "label_ar": "Wrzosowa",
                "label_fr": "Wrzosowa"
            },
            {
                "value": 74341,
                "label": "Krośniewice",
                "label_ar": "Krośniewice",
                "label_fr": "Krośniewice"
            },
            {
                "value": 74342,
                "label": "Zelow",
                "label_ar": "زيلو",
                "label_fr": "Zelow"
            },
            {
                "value": 74343,
                "label": "Pyskowice",
                "label_ar": "بيسكوفيتسي",
                "label_fr": "Pyskowice"
            },
            {
                "value": 74344,
                "label": "Swierklany",
                "label_ar": "Swierklany",
                "label_fr": "Swierklany"
            },
            {
                "value": 74345,
                "label": "Slemien",
                "label_ar": "سليميان",
                "label_fr": "Slemien"
            },
            {
                "value": 74346,
                "label": "Koniakow",
                "label_ar": "كونياكو",
                "label_fr": "Koniakow"
            },
            {
                "value": 74347,
                "label": "Krzyzanowice",
                "label_ar": "Krzyzanowice",
                "label_fr": "Krzyzanowice"
            },
            {
                "value": 74348,
                "label": "Myszkow",
                "label_ar": "ميسكو",
                "label_fr": "Myszkow"
            },
            {
                "value": 74349,
                "label": "Jaroszow",
                "label_ar": "جاروزوف",
                "label_fr": "Jaroszow"
            },
            {
                "value": 74350,
                "label": "Niemcza",
                "label_ar": "نيمتشا",
                "label_fr": "Niemcza"
            },
            {
                "value": 74351,
                "label": "Bestwinka",
                "label_ar": "بيستوينكا",
                "label_fr": "Bestwinka"
            },
            {
                "value": 74352,
                "label": "Glogowek",
                "label_ar": "جلوجويك",
                "label_fr": "Glogowek"
            },
            {
                "value": 74353,
                "label": "Glucholazy",
                "label_ar": "جلوتشولازي",
                "label_fr": "Glucholazy"
            },
            {
                "value": 74354,
                "label": "Rogow",
                "label_ar": "روجو",
                "label_fr": "Rogow"
            },
            {
                "value": 74355,
                "label": "Stare Bogaczowice",
                "label_ar": "ستاري بوغاتشوفيتسه",
                "label_fr": "Regarder Bogaczowice"
            },
            {
                "value": 74356,
                "label": "Sieniawka",
                "label_ar": "سينياوكا",
                "label_fr": "Sieniawka"
            },
            {
                "value": 74357,
                "label": "Pilawa Gorna",
                "label_ar": "بيلاوا جورنا",
                "label_fr": "Pilawa Gorna"
            },
            {
                "value": 74358,
                "label": "Jastrzebie",
                "label_ar": "جاسترزيبي",
                "label_fr": "Jastrzebie"
            },
            {
                "value": 74359,
                "label": "Bestwina",
                "label_ar": "بيستوينا",
                "label_fr": "Bestwina"
            },
            {
                "value": 74360,
                "label": "Lipowa",
                "label_ar": "ليبوا",
                "label_fr": "Lipowa"
            },
            {
                "value": 74361,
                "label": "Koscielisko",
                "label_ar": "كوسيليسكو",
                "label_fr": "Koscielisko"
            },
            {
                "value": 74362,
                "label": "Biala",
                "label_ar": "بيالا",
                "label_fr": "Biala"
            },
            {
                "value": 74363,
                "label": "Kamienica Polska",
                "label_ar": "كامينيكا بولسكا",
                "label_fr": "Kamienica Polska"
            },
            {
                "value": 74364,
                "label": "Koluszki",
                "label_ar": "Koluszki",
                "label_fr": "Koluszki"
            },
            {
                "value": 74365,
                "label": "Dobron",
                "label_ar": "دوبرون",
                "label_fr": "Dobron"
            },
            {
                "value": 74366,
                "label": "Trzebinia",
                "label_ar": "Trzebinia",
                "label_fr": "Trzebinia"
            },
            {
                "value": 74367,
                "label": "Ogrodzieniec",
                "label_ar": "Ogrodzieniec",
                "label_fr": "Ogrodzieniec"
            },
            {
                "value": 74368,
                "label": "Czaniec",
                "label_ar": "كزانيك",
                "label_fr": "Czaniec"
            },
            {
                "value": 74369,
                "label": "Jaroszowiec",
                "label_ar": "جاروزوفيتش",
                "label_fr": "Jaroszowiec"
            },
            {
                "value": 74370,
                "label": "Wola Filipowska",
                "label_ar": "وولا فيليبوسكا",
                "label_fr": "Wola Filipowska"
            },
            {
                "value": 74371,
                "label": "Belk",
                "label_ar": "بيلك",
                "label_fr": "Belk"
            },
            {
                "value": 74372,
                "label": "Katy Wroclawskie",
                "label_ar": "كاتي فروكلافسكي",
                "label_fr": "Katy Wroclawskie"
            },
            {
                "value": 74373,
                "label": "Korfantow",
                "label_ar": "كورفانتو",
                "label_fr": "Korfantow"
            },
            {
                "value": 74374,
                "label": "Gdow",
                "label_ar": "جدو",
                "label_fr": "Gdow"
            },
            {
                "value": 74375,
                "label": "Stryszawa",
                "label_ar": "ستريسزاوا",
                "label_fr": "Stryszawa"
            },
            {
                "value": 74376,
                "label": "Rudy",
                "label_ar": "رودي",
                "label_fr": "Rudy"
            },
            {
                "value": 74377,
                "label": "Skala",
                "label_ar": "سكالا",
                "label_fr": "Skala"
            },
            {
                "value": 74378,
                "label": "Polanka Wielka",
                "label_ar": "بولانكا ويلكا",
                "label_fr": "Polanka Wielka"
            },
            {
                "value": 74379,
                "label": "Radlin",
                "label_ar": "رادلين",
                "label_fr": "Radlin"
            },
            {
                "value": 74380,
                "label": "Chrzesne",
                "label_ar": "كرزيسن",
                "label_fr": "Chrzesne"
            },
            {
                "value": 74381,
                "label": "Grodzisk",
                "label_ar": "جروديسك",
                "label_fr": "Grodzisk"
            },
            {
                "value": 74382,
                "label": "Chotomow",
                "label_ar": "تشوتومو",
                "label_fr": "Chotomow"
            },
            {
                "value": 74383,
                "label": "Nowe Miasto nad Pilica",
                "label_ar": "Nowe Miasto ناد Pilica",
                "label_fr": "Nowe Miasto nad Pilica"
            },
            {
                "value": 74384,
                "label": "Laskarzew",
                "label_ar": "لاسكارزيو",
                "label_fr": "Laskarzew"
            },
            {
                "value": 74385,
                "label": "Łosice",
                "label_ar": "Łosice",
                "label_fr": "Łosice"
            },
            {
                "value": 74386,
                "label": "Przasnysz",
                "label_ar": "برزاسنيز",
                "label_fr": "Przasnysz"
            },
            {
                "value": 74387,
                "label": "Lancut",
                "label_ar": "لانكوت",
                "label_fr": "Lancut"
            },
            {
                "value": 74388,
                "label": "Trzciana",
                "label_ar": "ترزشيانا",
                "label_fr": "Trzciana"
            },
            {
                "value": 74389,
                "label": "Kurylowka",
                "label_ar": "كوريلوكا",
                "label_fr": "Kurylowka"
            },
            {
                "value": 74390,
                "label": "Czudec",
                "label_ar": "كزوديك",
                "label_fr": "Czudec"
            },
            {
                "value": 74391,
                "label": "Kanczuga",
                "label_ar": "كانزوجا",
                "label_fr": "Kanczuga"
            },
            {
                "value": 74392,
                "label": "Rudnik nad Sanem",
                "label_ar": "رودنيك ناد سانم",
                "label_fr": "Rudnik nad Sanem"
            },
            {
                "value": 74393,
                "label": "Zurawica",
                "label_ar": "زوراويكا",
                "label_fr": "Zurawica"
            },
            {
                "value": 74394,
                "label": "Rakszawa",
                "label_ar": "راكسزاوا",
                "label_fr": "Rakszawa"
            },
            {
                "value": 74395,
                "label": "Majdan Krolewski",
                "label_ar": "مجدان كروليوسكي",
                "label_fr": "Majdan Krolewski"
            },
            {
                "value": 74396,
                "label": "Iława",
                "label_ar": "إياوا",
                "label_fr": "Iława"
            },
            {
                "value": 74397,
                "label": "Kuligow",
                "label_ar": "كوليجو",
                "label_fr": "Kuligow"
            },
            {
                "value": 74398,
                "label": "Nacpolsk",
                "label_ar": "ناكبولسك",
                "label_fr": "Nacpolsk"
            },
            {
                "value": 74399,
                "label": "Stare Babice",
                "label_ar": "التحديق بابيس",
                "label_fr": "Regarder Babice"
            },
            {
                "value": 74400,
                "label": "Wyszogrod",
                "label_ar": "Wyszogrod",
                "label_fr": "Wyszogrod"
            },
            {
                "value": 74401,
                "label": "Lesnica",
                "label_ar": "ليسنيكا",
                "label_fr": "Lesnica"
            },
            {
                "value": 74402,
                "label": "Szadek",
                "label_ar": "صادق",
                "label_fr": "Szadek"
            },
            {
                "value": 74403,
                "label": "Gmina Lutomiersk",
                "label_ar": "جمينا لوتوميرسك",
                "label_fr": "Gmina Lutomiersk"
            },
            {
                "value": 74404,
                "label": "Kolonowskie",
                "label_ar": "كولونوفسكي",
                "label_fr": "Kolonowskie"
            },
            {
                "value": 74405,
                "label": "Barglowka",
                "label_ar": "Barglowka",
                "label_fr": "Barglowka"
            },
            {
                "value": 74406,
                "label": "Bojszow",
                "label_ar": "بوجسو",
                "label_fr": "Bojszow"
            },
            {
                "value": 74407,
                "label": "Nowy Wisnicz",
                "label_ar": "Nowy Wisnicz",
                "label_fr": "Nowy Wisnicz"
            },
            {
                "value": 74408,
                "label": "Rybna",
                "label_ar": "ريبنا",
                "label_fr": "Rybna"
            },
            {
                "value": 74409,
                "label": "Czarny Dunajec",
                "label_ar": "كزارني دوناجيتش",
                "label_fr": "Czarny Dunajec"
            },
            {
                "value": 74410,
                "label": "Brzeszcze",
                "label_ar": "Brzeszcze",
                "label_fr": "Brzeszcze"
            },
            {
                "value": 74411,
                "label": "Gromiec",
                "label_ar": "جروميك",
                "label_fr": "Gromiec"
            },
            {
                "value": 74412,
                "label": "Barcice",
                "label_ar": "باركيس",
                "label_fr": "Barcice"
            },
            {
                "value": 74413,
                "label": "Slomniki",
                "label_ar": "سلومنيكي",
                "label_fr": "Slomniki"
            },
            {
                "value": 74414,
                "label": "Piekielnik",
                "label_ar": "بيكيلنيك",
                "label_fr": "Piekielnik"
            },
            {
                "value": 74415,
                "label": "Brzezinka",
                "label_ar": "Brzezinka",
                "label_fr": "Brzezinka"
            },
            {
                "value": 74416,
                "label": "Chrzan",
                "label_ar": "كرزان",
                "label_fr": "Chrzan"
            },
            {
                "value": 74417,
                "label": "Tegoborze",
                "label_ar": "تيغوبورز",
                "label_fr": "Tegoborze"
            },
            {
                "value": 74418,
                "label": "Kukow",
                "label_ar": "كوكو",
                "label_fr": "Kukow"
            },
            {
                "value": 74419,
                "label": "Zalesie",
                "label_ar": "زالسي",
                "label_fr": "Zalesie"
            },
            {
                "value": 74420,
                "label": "Siemkowice",
                "label_ar": "سيمكوفيتسي",
                "label_fr": "Siemkowice"
            },
            {
                "value": 74421,
                "label": "Sidzina",
                "label_ar": "سيدزينا",
                "label_fr": "Sidzina"
            },
            {
                "value": 74422,
                "label": "Bialy Dunajec",
                "label_ar": "بيالي دوناجيتش",
                "label_fr": "Bialy Dunajec"
            },
            {
                "value": 74423,
                "label": "Kojszowka",
                "label_ar": "كوجسوفكا",
                "label_fr": "Kojszowka"
            },
            {
                "value": 74424,
                "label": "Gorzyce",
                "label_ar": "جورزيز",
                "label_fr": "Gorzyce"
            },
            {
                "value": 74425,
                "label": "Wymiarki",
                "label_ar": "Wymiarki",
                "label_fr": "Wymiarki"
            },
            {
                "value": 74426,
                "label": "Prabuty",
                "label_ar": "برابوتي",
                "label_fr": "Prabuty"
            },
            {
                "value": 74427,
                "label": "Szczawno",
                "label_ar": "Szczawno",
                "label_fr": "Szczawno"
            },
            {
                "value": 74428,
                "label": "Swidnica",
                "label_ar": "Swidnica",
                "label_fr": "Swidnica"
            },
            {
                "value": 74429,
                "label": "Dabrowa Biskupia",
                "label_ar": "دابروا بسكوبيا",
                "label_fr": "Dabrowa Biskupia"
            },
            {
                "value": 74430,
                "label": "Lysomice",
                "label_ar": "ليسوميس",
                "label_fr": "Lysomice"
            },
            {
                "value": 74431,
                "label": "Kcynia",
                "label_ar": "كسينيا",
                "label_fr": "Kcynia"
            },
            {
                "value": 74432,
                "label": "Rogowo",
                "label_ar": "روجوو",
                "label_fr": "Rogowo"
            },
            {
                "value": 74433,
                "label": "Komorsk",
                "label_ar": "كومورسك",
                "label_fr": "Komorsk"
            },
            {
                "value": 74434,
                "label": "Tuczno",
                "label_ar": "توكزنو",
                "label_fr": "Tuczno"
            },
            {
                "value": 74435,
                "label": "Miedzyzdroje",
                "label_ar": "ميدزيزدروي",
                "label_fr": "Miedzyzdroje"
            },
            {
                "value": 74436,
                "label": "Bojano",
                "label_ar": "بوجانو",
                "label_fr": "Bojano"
            },
            {
                "value": 74437,
                "label": "Straszyn",
                "label_ar": "سترازين",
                "label_fr": "Straszyn"
            },
            {
                "value": 74438,
                "label": "Kolodziejewo",
                "label_ar": "Kolodziejewo",
                "label_fr": "Kolodziejewo"
            },
            {
                "value": 74439,
                "label": "Gmina Sierakowice",
                "label_ar": "غمينا سيراكوفيتسه",
                "label_fr": "Gmina Sierakowice"
            },
            {
                "value": 74440,
                "label": "Ilowa",
                "label_ar": "إيلوا",
                "label_fr": "Ilowa"
            },
            {
                "value": 74441,
                "label": "Dargoslaw",
                "label_ar": "دارغوسلاف",
                "label_fr": "Dargoslaw"
            },
            {
                "value": 74442,
                "label": "Sarbinowo",
                "label_ar": "ساربينو",
                "label_fr": "Sarbinowo"
            },
            {
                "value": 74443,
                "label": "Jeziora Wielkie",
                "label_ar": "Jeziora Wielkie",
                "label_fr": "Jeziora Wielkie"
            },
            {
                "value": 74444,
                "label": "Zbiczno",
                "label_ar": "Zbiczno",
                "label_fr": "Zbiczno"
            },
            {
                "value": 74445,
                "label": "Baruchowo",
                "label_ar": "باروخوو",
                "label_fr": "Baruchowo"
            },
            {
                "value": 74446,
                "label": "Pieszyce",
                "label_ar": "بيسيز",
                "label_fr": "Pieszyce"
            },
            {
                "value": 74447,
                "label": "Gmina Bolków",
                "label_ar": "غمينا بولكو",
                "label_fr": "Gmina Bolków"
            },
            {
                "value": 74448,
                "label": "Koluszki",
                "label_ar": "Koluszki",
                "label_fr": "Koluszki"
            },
            {
                "value": 74449,
                "label": "Dobron",
                "label_ar": "دوبرون",
                "label_fr": "Dobron"
            },
            {
                "value": 74450,
                "label": "Trzebinia",
                "label_ar": "Trzebinia",
                "label_fr": "Trzebinia"
            },
            {
                "value": 74451,
                "label": "Ogrodzieniec",
                "label_ar": "Ogrodzieniec",
                "label_fr": "Ogrodzieniec"
            },
            {
                "value": 74452,
                "label": "Czaniec",
                "label_ar": "كزانيك",
                "label_fr": "Czaniec"
            },
            {
                "value": 74453,
                "label": "Jaroszowiec",
                "label_ar": "جاروزوفيتش",
                "label_fr": "Jaroszowiec"
            },
            {
                "value": 74454,
                "label": "Wola Filipowska",
                "label_ar": "وولا فيليبوسكا",
                "label_fr": "Wola Filipowska"
            },
            {
                "value": 74455,
                "label": "Belk",
                "label_ar": "بيلك",
                "label_fr": "Belk"
            },
            {
                "value": 74456,
                "label": "Katy Wroclawskie",
                "label_ar": "كاتي فروكلافسكي",
                "label_fr": "Katy Wroclawskie"
            },
            {
                "value": 74457,
                "label": "Korfantow",
                "label_ar": "كورفانتو",
                "label_fr": "Korfantow"
            },
            {
                "value": 74458,
                "label": "Gdow",
                "label_ar": "جدو",
                "label_fr": "Gdow"
            },
            {
                "value": 74459,
                "label": "Stryszawa",
                "label_ar": "ستريسزاوا",
                "label_fr": "Stryszawa"
            },
            {
                "value": 74460,
                "label": "Rudy",
                "label_ar": "رودي",
                "label_fr": "Rudy"
            },
            {
                "value": 74461,
                "label": "Skala",
                "label_ar": "سكالا",
                "label_fr": "Skala"
            },
            {
                "value": 74462,
                "label": "Polanka Wielka",
                "label_ar": "بولانكا ويلكا",
                "label_fr": "Polanka Wielka"
            },
            {
                "value": 74463,
                "label": "Radlin",
                "label_ar": "رادلين",
                "label_fr": "Radlin"
            },
            {
                "value": 74464,
                "label": "Chrzesne",
                "label_ar": "كرزيسن",
                "label_fr": "Chrzesne"
            },
            {
                "value": 74465,
                "label": "Grodzisk",
                "label_ar": "جروديسك",
                "label_fr": "Grodzisk"
            },
            {
                "value": 74466,
                "label": "Chotomow",
                "label_ar": "تشوتومو",
                "label_fr": "Chotomow"
            },
            {
                "value": 74467,
                "label": "Nowe Miasto nad Pilica",
                "label_ar": "Nowe Miasto ناد Pilica",
                "label_fr": "Nowe Miasto nad Pilica"
            },
            {
                "value": 74468,
                "label": "Laskarzew",
                "label_ar": "لاسكارزيو",
                "label_fr": "Laskarzew"
            },
            {
                "value": 74469,
                "label": "Łosice",
                "label_ar": "Łosice",
                "label_fr": "Łosice"
            },
            {
                "value": 74470,
                "label": "Przasnysz",
                "label_ar": "برزاسنيز",
                "label_fr": "Przasnysz"
            },
            {
                "value": 74471,
                "label": "Lancut",
                "label_ar": "لانكوت",
                "label_fr": "Lancut"
            },
            {
                "value": 74472,
                "label": "Trzciana",
                "label_ar": "ترزشيانا",
                "label_fr": "Trzciana"
            },
            {
                "value": 74473,
                "label": "Kurylowka",
                "label_ar": "كوريلوكا",
                "label_fr": "Kurylowka"
            },
            {
                "value": 74474,
                "label": "Czudec",
                "label_ar": "كزوديك",
                "label_fr": "Czudec"
            },
            {
                "value": 74475,
                "label": "Kanczuga",
                "label_ar": "كانزوجا",
                "label_fr": "Kanczuga"
            },
            {
                "value": 74476,
                "label": "Rudnik nad Sanem",
                "label_ar": "رودنيك ناد سانم",
                "label_fr": "Rudnik nad Sanem"
            },
            {
                "value": 74477,
                "label": "Zurawica",
                "label_ar": "زوراويكا",
                "label_fr": "Zurawica"
            },
            {
                "value": 74478,
                "label": "Rakszawa",
                "label_ar": "راكسزاوا",
                "label_fr": "Rakszawa"
            },
            {
                "value": 74479,
                "label": "Majdan Krolewski",
                "label_ar": "مجدان كروليوسكي",
                "label_fr": "Majdan Krolewski"
            },
            {
                "value": 74480,
                "label": "Iława",
                "label_ar": "إياوا",
                "label_fr": "Iława"
            },
            {
                "value": 74481,
                "label": "Kuligow",
                "label_ar": "كوليجو",
                "label_fr": "Kuligow"
            },
            {
                "value": 74482,
                "label": "Nacpolsk",
                "label_ar": "ناكبولسك",
                "label_fr": "Nacpolsk"
            },
            {
                "value": 74483,
                "label": "Stare Babice",
                "label_ar": "التحديق بابيس",
                "label_fr": "Regarder Babice"
            },
            {
                "value": 74484,
                "label": "Wyszogrod",
                "label_ar": "Wyszogrod",
                "label_fr": "Wyszogrod"
            },
            {
                "value": 74485,
                "label": "Lesnica",
                "label_ar": "ليسنيكا",
                "label_fr": "Lesnica"
            },
            {
                "value": 74486,
                "label": "Szadek",
                "label_ar": "صادق",
                "label_fr": "Szadek"
            },
            {
                "value": 74487,
                "label": "Gmina Lutomiersk",
                "label_ar": "جمينا لوتوميرسك",
                "label_fr": "Gmina Lutomiersk"
            },
            {
                "value": 74488,
                "label": "Kolonowskie",
                "label_ar": "كولونوفسكي",
                "label_fr": "Kolonowskie"
            },
            {
                "value": 74489,
                "label": "Barglowka",
                "label_ar": "Barglowka",
                "label_fr": "Barglowka"
            },
            {
                "value": 74490,
                "label": "Bojszow",
                "label_ar": "بوجسو",
                "label_fr": "Bojszow"
            },
            {
                "value": 74491,
                "label": "Nowy Wisnicz",
                "label_ar": "Nowy Wisnicz",
                "label_fr": "Nowy Wisnicz"
            },
            {
                "value": 74492,
                "label": "Rybna",
                "label_ar": "ريبنا",
                "label_fr": "Rybna"
            },
            {
                "value": 74493,
                "label": "Czarny Dunajec",
                "label_ar": "كزارني دوناجيتش",
                "label_fr": "Czarny Dunajec"
            },
            {
                "value": 74494,
                "label": "Brzeszcze",
                "label_ar": "Brzeszcze",
                "label_fr": "Brzeszcze"
            },
            {
                "value": 74495,
                "label": "Gromiec",
                "label_ar": "جروميك",
                "label_fr": "Gromiec"
            },
            {
                "value": 74496,
                "label": "Barcice",
                "label_ar": "باركيس",
                "label_fr": "Barcice"
            },
            {
                "value": 74497,
                "label": "Slomniki",
                "label_ar": "سلومنيكي",
                "label_fr": "Slomniki"
            },
            {
                "value": 74498,
                "label": "Piekielnik",
                "label_ar": "بيكيلنيك",
                "label_fr": "Piekielnik"
            },
            {
                "value": 74499,
                "label": "Brzezinka",
                "label_ar": "Brzezinka",
                "label_fr": "Brzezinka"
            },
            {
                "value": 74500,
                "label": "Chrzan",
                "label_ar": "كرزان",
                "label_fr": "Chrzan"
            },
            {
                "value": 74501,
                "label": "Tegoborze",
                "label_ar": "تيغوبورز",
                "label_fr": "Tegoborze"
            },
            {
                "value": 74502,
                "label": "Kukow",
                "label_ar": "كوكو",
                "label_fr": "Kukow"
            },
            {
                "value": 74503,
                "label": "Zalesie",
                "label_ar": "زالسي",
                "label_fr": "Zalesie"
            },
            {
                "value": 74504,
                "label": "Siemkowice",
                "label_ar": "سيمكوفيتسي",
                "label_fr": "Siemkowice"
            },
            {
                "value": 74505,
                "label": "Sidzina",
                "label_ar": "سيدزينا",
                "label_fr": "Sidzina"
            },
            {
                "value": 74506,
                "label": "Bialy Dunajec",
                "label_ar": "بيالي دوناجيتش",
                "label_fr": "Bialy Dunajec"
            },
            {
                "value": 74507,
                "label": "Kojszowka",
                "label_ar": "كوجسوفكا",
                "label_fr": "Kojszowka"
            },
            {
                "value": 74508,
                "label": "Gorzyce",
                "label_ar": "جورزيز",
                "label_fr": "Gorzyce"
            },
            {
                "value": 74509,
                "label": "Wymiarki",
                "label_ar": "Wymiarki",
                "label_fr": "Wymiarki"
            },
            {
                "value": 74510,
                "label": "Prabuty",
                "label_ar": "برابوتي",
                "label_fr": "Prabuty"
            },
            {
                "value": 74511,
                "label": "Szczawno",
                "label_ar": "Szczawno",
                "label_fr": "Szczawno"
            },
            {
                "value": 74512,
                "label": "Swidnica",
                "label_ar": "Swidnica",
                "label_fr": "Swidnica"
            },
            {
                "value": 74513,
                "label": "Dabrowa Biskupia",
                "label_ar": "دابروا بسكوبيا",
                "label_fr": "Dabrowa Biskupia"
            },
            {
                "value": 74514,
                "label": "Lysomice",
                "label_ar": "ليسوميس",
                "label_fr": "Lysomice"
            },
            {
                "value": 74515,
                "label": "Kcynia",
                "label_ar": "كسينيا",
                "label_fr": "Kcynia"
            },
            {
                "value": 74516,
                "label": "Rogowo",
                "label_ar": "روجوو",
                "label_fr": "Rogowo"
            },
            {
                "value": 74517,
                "label": "Komorsk",
                "label_ar": "كومورسك",
                "label_fr": "Komorsk"
            },
            {
                "value": 74518,
                "label": "Tuczno",
                "label_ar": "توكزنو",
                "label_fr": "Tuczno"
            },
            {
                "value": 74519,
                "label": "Miedzyzdroje",
                "label_ar": "ميدزيزدروي",
                "label_fr": "Miedzyzdroje"
            },
            {
                "value": 74520,
                "label": "Bojano",
                "label_ar": "بوجانو",
                "label_fr": "Bojano"
            },
            {
                "value": 74521,
                "label": "Straszyn",
                "label_ar": "سترازين",
                "label_fr": "Straszyn"
            },
            {
                "value": 74522,
                "label": "Kolodziejewo",
                "label_ar": "Kolodziejewo",
                "label_fr": "Kolodziejewo"
            },
            {
                "value": 74523,
                "label": "Gmina Sierakowice",
                "label_ar": "غمينا سيراكوفيتسه",
                "label_fr": "Gmina Sierakowice"
            },
            {
                "value": 74524,
                "label": "Ilowa",
                "label_ar": "إيلوا",
                "label_fr": "Ilowa"
            },
            {
                "value": 74525,
                "label": "Dargoslaw",
                "label_ar": "دارغوسلاف",
                "label_fr": "Dargoslaw"
            },
            {
                "value": 74526,
                "label": "Sarbinowo",
                "label_ar": "ساربينو",
                "label_fr": "Sarbinowo"
            },
            {
                "value": 74527,
                "label": "Jeziora Wielkie",
                "label_ar": "Jeziora Wielkie",
                "label_fr": "Jeziora Wielkie"
            },
            {
                "value": 74528,
                "label": "Zbiczno",
                "label_ar": "Zbiczno",
                "label_fr": "Zbiczno"
            },
            {
                "value": 74529,
                "label": "Baruchowo",
                "label_ar": "باروخوو",
                "label_fr": "Baruchowo"
            },
            {
                "value": 74530,
                "label": "Pieszyce",
                "label_ar": "بيسيز",
                "label_fr": "Pieszyce"
            },
            {
                "value": 74531,
                "label": "Gmina Bolków",
                "label_ar": "غمينا بولكو",
                "label_fr": "Gmina Bolków"
            },
            {
                "value": 74532,
                "label": "Marcinowice",
                "label_ar": "مارسينويس",
                "label_fr": "Marcinowice"
            },
            {
                "value": 74533,
                "label": "Krasnik",
                "label_ar": "كراسنيك",
                "label_fr": "Krasnik"
            },
            {
                "value": 74534,
                "label": "Grajewo",
                "label_ar": "Grajewo",
                "label_fr": "Grajewo"
            },
            {
                "value": 74535,
                "label": "Checiny",
                "label_ar": "Checiny",
                "label_fr": "Checiny"
            },
            {
                "value": 74536,
                "label": "Ryn",
                "label_ar": "رين",
                "label_fr": "Ryn"
            },
            {
                "value": 74537,
                "label": "Szyldak",
                "label_ar": "سزيلداك",
                "label_fr": "Szyldak"
            },
            {
                "value": 74538,
                "label": "Bisztynek",
                "label_ar": "Bisztynek",
                "label_fr": "Bisztynek"
            },
            {
                "value": 74539,
                "label": "Dabrowno",
                "label_ar": "دابروونو",
                "label_fr": "Dabrowno"
            },
            {
                "value": 74540,
                "label": "Dywity",
                "label_ar": "ديويتي",
                "label_fr": "Dywity"
            },
            {
                "value": 74541,
                "label": "Dylewo",
                "label_ar": "ديليو",
                "label_fr": "Dylewo"
            },
            {
                "value": 74542,
                "label": "Kurów",
                "label_ar": "كورو",
                "label_fr": "Kurów"
            },
            {
                "value": 74543,
                "label": "Grunwald",
                "label_ar": "جرونوالد",
                "label_fr": "Grunwald"
            },
            {
                "value": 74544,
                "label": "Bartoszyce",
                "label_ar": "بارتوزيتشي",
                "label_fr": "Bartoszyce"
            },
            {
                "value": 74545,
                "label": "Zabrowo",
                "label_ar": "زابروو",
                "label_fr": "Zabrowo"
            },
            {
                "value": 74546,
                "label": "Stawiguda",
                "label_ar": "ستاويجودا",
                "label_fr": "Stawiguda"
            },
            {
                "value": 74547,
                "label": "Kozienice",
                "label_ar": "كوزينس",
                "label_fr": "Kozienice"
            },
            {
                "value": 74548,
                "label": "Drozdowo",
                "label_ar": "دروزدوو",
                "label_fr": "Drozdowo"
            },
            {
                "value": 74549,
                "label": "Trzesniow",
                "label_ar": "ترزيسنيو",
                "label_fr": "Trzesniow"
            },
            {
                "value": 74550,
                "label": "Swiatki",
                "label_ar": "Swiatki",
                "label_fr": "Swiatki"
            },
            {
                "value": 74551,
                "label": "Samin",
                "label_ar": "سامين",
                "label_fr": "Samin"
            },
            {
                "value": 74552,
                "label": "Dobre Miasto",
                "label_ar": "دوبري مياستو",
                "label_fr": "Dobre Miasto"
            },
            {
                "value": 74553,
                "label": "Stare Juchy",
                "label_ar": "التحديق جوشي",
                "label_fr": "Regarder Juchy"
            },
            {
                "value": 74554,
                "label": "Zalewo",
                "label_ar": "Zalewo",
                "label_fr": "Zalewo"
            },
            {
                "value": 74555,
                "label": "Trzydnik Duzy",
                "label_ar": "ترزيدنيك دوزي",
                "label_fr": "Trzydnik Duzy"
            },
            {
                "value": 74556,
                "label": "Prawiedniki",
                "label_ar": "براويدنيكي",
                "label_fr": "Prawiedniki"
            },
            {
                "value": 74557,
                "label": "Garbow",
                "label_ar": "جاربو",
                "label_fr": "Garbow"
            },
            {
                "value": 74558,
                "label": "Milowka",
                "label_ar": "ميلوكا",
                "label_fr": "Milowka"
            },
            {
                "value": 74559,
                "label": "Zablocie",
                "label_ar": "زابلوسي",
                "label_fr": "Zablocie"
            },
            {
                "value": 74560,
                "label": "Dobroszyce",
                "label_ar": "Dobroszyce",
                "label_fr": "Dobroszyce"
            },
            {
                "value": 74561,
                "label": "Gmina Puszcza Mariańska",
                "label_ar": "Gmina Puszcza Mariańska",
                "label_fr": "Gmina Puszcza Mariańska"
            },
            {
                "value": 74562,
                "label": "Falkow",
                "label_ar": "فولكو",
                "label_fr": "Falkow"
            },
            {
                "value": 74563,
                "label": "Bedkow",
                "label_ar": "بيدكو",
                "label_fr": "Bedkow"
            },
            {
                "value": 74564,
                "label": "Radomsko",
                "label_ar": "رادومسكو",
                "label_fr": "Radomsko"
            },
            {
                "value": 74565,
                "label": "Kamien Slaski",
                "label_ar": "كامين سلاسكي",
                "label_fr": "Kamien Slaski"
            },
            {
                "value": 74566,
                "label": "Nieborowice",
                "label_ar": "نيبورووفيس",
                "label_fr": "Nieborowice"
            },
            {
                "value": 74567,
                "label": "Slawkow",
                "label_ar": "Slawkow",
                "label_fr": "Slawkow"
            },
            {
                "value": 74568,
                "label": "Wilkow",
                "label_ar": "ويلكو",
                "label_fr": "Wilkow"
            },
            {
                "value": 74569,
                "label": "Roztoka",
                "label_ar": "روزتوكا",
                "label_fr": "Roztoka"
            },
            {
                "value": 74570,
                "label": "Zembrzyce",
                "label_ar": "زيمبرزيز",
                "label_fr": "Zembrzyce"
            },
            {
                "value": 74571,
                "label": "Ciezkowice",
                "label_ar": "سيزكوفيتسي",
                "label_fr": "Ciezkowice"
            },
            {
                "value": 74572,
                "label": "Hecznarowice",
                "label_ar": "هيكزناروفيتسي",
                "label_fr": "Hecznarowice"
            },
            {
                "value": 74573,
                "label": "Szklarska Poręba",
                "label_ar": "سكلارسكا بوريبا",
                "label_fr": "Szklarska Poreba"
            },
            {
                "value": 74574,
                "label": "Zagrodno",
                "label_ar": "زاغرودنو",
                "label_fr": "Zagrodno"
            },
            {
                "value": 74575,
                "label": "Krosnowice",
                "label_ar": "كروسنوفيس",
                "label_fr": "Krosnowice"
            },
            {
                "value": 74576,
                "label": "Krasiejow",
                "label_ar": "كراسيجو",
                "label_fr": "Krasiejow"
            },
            {
                "value": 74577,
                "label": "Lubawka",
                "label_ar": "لوباوكا",
                "label_fr": "Lubawka"
            },
            {
                "value": 74578,
                "label": "Twardogora",
                "label_ar": "تواردوغورا",
                "label_fr": "Twardogora"
            },
            {
                "value": 74579,
                "label": "Zorawina",
                "label_ar": "زوراوينا",
                "label_fr": "Zorawina"
            },
            {
                "value": 74580,
                "label": "Prusice",
                "label_ar": "Prusice",
                "label_fr": "Prusice"
            },
            {
                "value": 74581,
                "label": "Smardzew",
                "label_ar": "Smardzew",
                "label_fr": "Smardzew"
            },
            {
                "value": 74582,
                "label": "Domaniewice",
                "label_ar": "دومنيفيتسي",
                "label_fr": "Domaniewice"
            },
            {
                "value": 74583,
                "label": "Brudzice",
                "label_ar": "برودزيس",
                "label_fr": "Brudzice"
            },
            {
                "value": 74584,
                "label": "Zduny",
                "label_ar": "زدوني",
                "label_fr": "Zduny"
            },
            {
                "value": 74585,
                "label": "Tworog",
                "label_ar": "Tworog",
                "label_fr": "Tworog"
            },
            {
                "value": 74586,
                "label": "Stroze",
                "label_ar": "ستروزي",
                "label_fr": "Stroze"
            },
            {
                "value": 74587,
                "label": "Klucze",
                "label_ar": "كلوتشز",
                "label_fr": "Klucze"
            },
            {
                "value": 74588,
                "label": "Alwernia",
                "label_ar": "الورنية",
                "label_fr": "Alwernia"
            },
            {
                "value": 74589,
                "label": "Suchy Dab",
                "label_ar": "سوشي داب",
                "label_fr": "Suchy Dab"
            },
            {
                "value": 74590,
                "label": "Rokitki",
                "label_ar": "روكيتكي",
                "label_fr": "Rokitki"
            },
            {
                "value": 74591,
                "label": "Sztutowo",
                "label_ar": "Sztutowo",
                "label_fr": "Sztutowo"
            },
            {
                "value": 74592,
                "label": "Ostaszewo",
                "label_ar": "أوستاسزيو",
                "label_fr": "Ostaszewo"
            },
            {
                "value": 74593,
                "label": "Drewnica",
                "label_ar": "درونيكا",
                "label_fr": "Drewnica"
            },
            {
                "value": 74594,
                "label": "Godziszewo",
                "label_ar": "Godziszewo",
                "label_fr": "Godziszewo"
            },
            {
                "value": 74595,
                "label": "Pelplin",
                "label_ar": "بيلبلن",
                "label_fr": "Pelplin"
            },
            {
                "value": 74596,
                "label": "Drezdenko",
                "label_ar": "دريزدينكو",
                "label_fr": "Drezdenko"
            },
            {
                "value": 74597,
                "label": "Dobrzany",
                "label_ar": "دوبرزاني",
                "label_fr": "Dobrzany"
            },
            {
                "value": 74598,
                "label": "Trzcinsko",
                "label_ar": "ترزينسكو",
                "label_fr": "Trzcinsko"
            },
            {
                "value": 74599,
                "label": "Klecko",
                "label_ar": "كليكو",
                "label_fr": "Klecko"
            },
            {
                "value": 74600,
                "label": "Ostrowite",
                "label_ar": "أوسترويت",
                "label_fr": "Ostrowite"
            },
            {
                "value": 74601,
                "label": "Pszczew",
                "label_ar": "Pszczew",
                "label_fr": "Pszczew"
            },
            {
                "value": 74602,
                "label": "Kicin",
                "label_ar": "كيسين",
                "label_fr": "Kicin"
            },
            {
                "value": 74603,
                "label": "Legowo",
                "label_ar": "ليغووو",
                "label_fr": "Legowo"
            },
            {
                "value": 74604,
                "label": "Barczewo",
                "label_ar": "باركزيو",
                "label_fr": "Barczewo"
            },
            {
                "value": 74605,
                "label": "Swarzewo",
                "label_ar": "سوارزيو",
                "label_fr": "Swarzewo"
            },
            {
                "value": 74606,
                "label": "Leba",
                "label_ar": "ليبا",
                "label_fr": "Leba"
            },
            {
                "value": 74607,
                "label": "Pasłęk",
                "label_ar": "باسوك",
                "label_fr": "Pasłęk"
            },
            {
                "value": 74608,
                "label": "Komorniki",
                "label_ar": "كومورنيكي",
                "label_fr": "Komorniki"
            },
            {
                "value": 74609,
                "label": "Kartuzy",
                "label_ar": "كارتوزي",
                "label_fr": "Kartuzy"
            },
            {
                "value": 74610,
                "label": "Raczki",
                "label_ar": "رازكي",
                "label_fr": "Raczki"
            },
            {
                "value": 74611,
                "label": "Rokitnica",
                "label_ar": "روكيتنيكا",
                "label_fr": "Rokitnica"
            },
            {
                "value": 74612,
                "label": "Bukowina Tatrzanska",
                "label_ar": "بوكوينا تاترزانسكا",
                "label_fr": "Bukowina Tatrzanska"
            },
            {
                "value": 74613,
                "label": "Myslachowice",
                "label_ar": "ميسلاشوفيتسي",
                "label_fr": "Myslachowice"
            },
            {
                "value": 74614,
                "label": "Siennica Nadolna",
                "label_ar": "سيينيكا نادولنا",
                "label_fr": "Siennica Nadolna"
            },
            {
                "value": 74615,
                "label": "Radzyn Podlaski",
                "label_ar": "رادزين بودلاسكي",
                "label_fr": "Radzyn Podlaski"
            },
            {
                "value": 74616,
                "label": "Gorki Wielkie",
                "label_ar": "جوركي ويلكي",
                "label_fr": "Gorki Wielkie"
            },
            {
                "value": 74617,
                "label": "Brenna",
                "label_ar": "برينا",
                "label_fr": "Brenna"
            },
            {
                "value": 74618,
                "label": "Sianow",
                "label_ar": "سيانو",
                "label_fr": "Sianow"
            },
            {
                "value": 74619,
                "label": "Smardzowice",
                "label_ar": "سماردزوفيتسي",
                "label_fr": "Smardzowice"
            },
            {
                "value": 74620,
                "label": "Turek",
                "label_ar": "توريك",
                "label_fr": "Turek"
            },
            {
                "value": 74621,
                "label": "Gmina Przywidz",
                "label_ar": "جمينا برزيويدز",
                "label_fr": "Gmina Przywidz"
            },
            {
                "value": 74622,
                "label": "Zukowo",
                "label_ar": "زوكوفو",
                "label_fr": "Zukowo"
            },
            {
                "value": 74623,
                "label": "Opatow",
                "label_ar": "أوباتو",
                "label_fr": "Opatow"
            },
            {
                "value": 74624,
                "label": "Kunow",
                "label_ar": "كونو",
                "label_fr": "Kunow"
            },
            {
                "value": 74625,
                "label": "Nowy Dwor Gdanski",
                "label_ar": "Nowy Dwor Gdanski",
                "label_fr": "Nowy Dwor Gdanski"
            },
            {
                "value": 74626,
                "label": "Milcza",
                "label_ar": "ميلكزا",
                "label_fr": "Milcza"
            },
            {
                "value": 74627,
                "label": "Krzywin",
                "label_ar": "كرزيوين",
                "label_fr": "Krzywin"
            },
            {
                "value": 74628,
                "label": "Kazmierz",
                "label_ar": "Kazmierz",
                "label_fr": "Kazmierz"
            },
            {
                "value": 74629,
                "label": "Skopanie",
                "label_ar": "سكوباني",
                "label_fr": "Skopanie"
            },
            {
                "value": 74630,
                "label": "Kwaczala",
                "label_ar": "كوازالا",
                "label_fr": "Kwaczala"
            },
            {
                "value": 74631,
                "label": "Labowa",
                "label_ar": "لابوا",
                "label_fr": "Labowa"
            },
            {
                "value": 74632,
                "label": "Orzysz",
                "label_ar": "Orzysz",
                "label_fr": "Orzysz"
            },
            {
                "value": 74633,
                "label": "Lubochnia",
                "label_ar": "لوبشنيا",
                "label_fr": "Lubochnia"
            },
            {
                "value": 74634,
                "label": "Frombork",
                "label_ar": "فرومبورك",
                "label_fr": "Frombork"
            },
            {
                "value": 74635,
                "label": "Gmina Kolno",
                "label_ar": "جمينا كولنو",
                "label_fr": "Gmina Kolno"
            },
            {
                "value": 74636,
                "label": "Kowalewo",
                "label_ar": "كوالوو",
                "label_fr": "Kowalewo"
            },
            {
                "value": 74637,
                "label": "Rejowiec",
                "label_ar": "Rejowiec",
                "label_fr": "Rejowiec"
            },
            {
                "value": 74638,
                "label": "Brzesko",
                "label_ar": "برزيسكو",
                "label_fr": "Brzesko"
            },
            {
                "value": 74639,
                "label": "Dabrowa",
                "label_ar": "دبروا",
                "label_fr": "Dabrowa"
            },
            {
                "value": 74640,
                "label": "Sieroszowice",
                "label_ar": "سيروزوفيتسي",
                "label_fr": "Sieroszowice"
            },
            {
                "value": 74641,
                "label": "Zawonia",
                "label_ar": "زاونيا",
                "label_fr": "Zawonia"
            },
            {
                "value": 74642,
                "label": "Wojkowice Koscielne",
                "label_ar": "Wojkowice Koscielne",
                "label_fr": "Wojkowice Koscielne"
            },
            {
                "value": 74643,
                "label": "Gowarzewo",
                "label_ar": "Gowarzewo",
                "label_fr": "Gowarzewo"
            },
            {
                "value": 74644,
                "label": "Wreczyca Wielka",
                "label_ar": "Wreczyca Wielka",
                "label_fr": "Wreczyca Wielka"
            },
            {
                "value": 74645,
                "label": "Gmina Gołuchów",
                "label_ar": "غمينا Gołuchów",
                "label_fr": "Gmina Gołuchów"
            },
            {
                "value": 74646,
                "label": "Siekierczyn",
                "label_ar": "Siekierczyn",
                "label_fr": "Siekierczyn"
            },
            {
                "value": 74647,
                "label": "Dziegielow",
                "label_ar": "دزيغيلو",
                "label_fr": "Dziegielow"
            },
            {
                "value": 74648,
                "label": "Wrzosowka",
                "label_ar": "Wrzosowka",
                "label_fr": "Wrzosowka"
            },
            {
                "value": 74649,
                "label": "Oleszno",
                "label_ar": "Oleszno",
                "label_fr": "Oleszno"
            },
            {
                "value": 74650,
                "label": "Swarorzyn",
                "label_ar": "سوارورزين",
                "label_fr": "Swarorzyn"
            },
            {
                "value": 74651,
                "label": "Falkowo",
                "label_ar": "فالكوو",
                "label_fr": "Falkowo"
            },
            {
                "value": 74652,
                "label": "Niechanowo",
                "label_ar": "Niechanowo",
                "label_fr": "Niechanowo"
            },
            {
                "value": 74653,
                "label": "Kikół",
                "label_ar": "كيكوف",
                "label_fr": "Kikół"
            },
            {
                "value": 74654,
                "label": "Wielgie",
                "label_ar": "Wielgie",
                "label_fr": "Wielgie"
            },
            {
                "value": 74655,
                "label": "Radomin",
                "label_ar": "رادومين",
                "label_fr": "Radomin"
            },
            {
                "value": 74656,
                "label": "Otyn",
                "label_ar": "أوتين",
                "label_fr": "Otyn"
            },
            {
                "value": 74657,
                "label": "Kaczkowo",
                "label_ar": "كاتشكوو",
                "label_fr": "Kaczkowo"
            },
            {
                "value": 74658,
                "label": "Osno Lubuskie",
                "label_ar": "أوسنو لوبوسكي",
                "label_fr": "Osno Lubuskie"
            },
            {
                "value": 74659,
                "label": "Swiniary",
                "label_ar": "Swiniary",
                "label_fr": "Swiniary"
            },
            {
                "value": 74660,
                "label": "Krzyz Wielkopolski",
                "label_ar": "كرزيز فيلكوبولسكي",
                "label_fr": "Krzyz Wielkopolski"
            },
            {
                "value": 74661,
                "label": "Rydzyna",
                "label_ar": "ريدزينا",
                "label_fr": "Rydzyna"
            },
            {
                "value": 74662,
                "label": "Pepowo",
                "label_ar": "بيبوو",
                "label_fr": "Pepowo"
            },
            {
                "value": 74663,
                "label": "Baranowko",
                "label_ar": "بارانوفكو",
                "label_fr": "Baranowko"
            },
            {
                "value": 74664,
                "label": "Czapury",
                "label_ar": "كزابوري",
                "label_fr": "Czapury"
            },
            {
                "value": 74665,
                "label": "Klodawa",
                "label_ar": "كلوداوا",
                "label_fr": "Klodawa"
            },
            {
                "value": 74666,
                "label": "Czempin",
                "label_ar": "تشيمبين",
                "label_fr": "Czempin"
            },
            {
                "value": 74667,
                "label": "Piaski",
                "label_ar": "بياسكي",
                "label_fr": "Piaski"
            },
            {
                "value": 74668,
                "label": "Gmina Nowogród Bobrzański",
                "label_ar": "غمينا نووجرود بوبرزانسكي",
                "label_fr": "Gmina Nowogród Bobrzański"
            },
            {
                "value": 74669,
                "label": "Wielichowo",
                "label_ar": "Wielichowo",
                "label_fr": "Wielichowo"
            },
            {
                "value": 74670,
                "label": "Gmina Babiak",
                "label_ar": "جمينا بابياق",
                "label_fr": "Gmina Babiak"
            },
            {
                "value": 74671,
                "label": "Kwilcz",
                "label_ar": "كويلكز",
                "label_fr": "Kwilcz"
            },
            {
                "value": 74672,
                "label": "Szczytniki",
                "label_ar": "شتشيتنيكي",
                "label_fr": "Szczytniki"
            },
            {
                "value": 74673,
                "label": "Gmina Szubin",
                "label_ar": "جمينة زوبين",
                "label_fr": "Gmina Szubin"
            },
            {
                "value": 74674,
                "label": "Lisewo",
                "label_ar": "ليسيو",
                "label_fr": "Lisewo"
            },
            {
                "value": 74675,
                "label": "Gruszczyn",
                "label_ar": "Gruszczyn",
                "label_fr": "Gruszczyn"
            },
            {
                "value": 74676,
                "label": "Karnice",
                "label_ar": "كارنيس",
                "label_fr": "Karnice"
            },
            {
                "value": 74677,
                "label": "Darnowo",
                "label_ar": "دارنوو",
                "label_fr": "Darnowo"
            },
            {
                "value": 74678,
                "label": "Skrzetusz",
                "label_ar": "Skrzetusz",
                "label_fr": "Skrzetusz"
            },
            {
                "value": 74679,
                "label": "Duszniki-Zdroj",
                "label_ar": "Duszniki-Zdroj",
                "label_fr": "Duszniki-Zdroj"
            },
            {
                "value": 74680,
                "label": "Gmina Kostomłoty",
                "label_ar": "غمينا كوستموتي",
                "label_fr": "Gmina Kostomłoty"
            },
            {
                "value": 74681,
                "label": "Kielcz",
                "label_ar": "كيلش",
                "label_fr": "Kielcz"
            },
            {
                "value": 74682,
                "label": "Pruszcz Pomorski",
                "label_ar": "Pruszcz بومورسكي",
                "label_fr": "Pruszcz Pomorski"
            },
            {
                "value": 74683,
                "label": "Manowo",
                "label_ar": "مانوو",
                "label_fr": "Manowo"
            },
            {
                "value": 74684,
                "label": "Siemirowice",
                "label_ar": "سيميروويتشي",
                "label_fr": "Siemirowice"
            },
            {
                "value": 74685,
                "label": "Damnica",
                "label_ar": "دامنيكا",
                "label_fr": "Damnica"
            },
            {
                "value": 74686,
                "label": "Czernica",
                "label_ar": "تشيرنيكا",
                "label_fr": "Czernica"
            },
            {
                "value": 74687,
                "label": "Tanowo",
                "label_ar": "تانوو",
                "label_fr": "Tanowo"
            },
            {
                "value": 74688,
                "label": "Stare Czarnowo",
                "label_ar": "ستاري كزارنوو",
                "label_fr": "Regarder Czarnowo"
            },
            {
                "value": 74689,
                "label": "Rewal",
                "label_ar": "المكافأة",
                "label_fr": "Rewal"
            },
            {
                "value": 74690,
                "label": "Stanowice",
                "label_ar": "ستانوفيتسي",
                "label_fr": "Stanowice"
            },
            {
                "value": 74691,
                "label": "Miedzyborz",
                "label_ar": "ميدزبورز",
                "label_fr": "Miedzyborz"
            },
            {
                "value": 74692,
                "label": "Lomnica",
                "label_ar": "لومنيكا",
                "label_fr": "Lomnica"
            },
            {
                "value": 74693,
                "label": "Laka",
                "label_ar": "لاكا",
                "label_fr": "Laka"
            },
            {
                "value": 74694,
                "label": "Konopiska",
                "label_ar": "كونوبيسكا",
                "label_fr": "Konopiska"
            },
            {
                "value": 74695,
                "label": "Poraj",
                "label_ar": "بوراج",
                "label_fr": "Poraj"
            },
            {
                "value": 74696,
                "label": "Glubczyce",
                "label_ar": "غلوبتشي",
                "label_fr": "Glubczyce"
            },
            {
                "value": 74697,
                "label": "Rzeczyce",
                "label_ar": "رزيتشي",
                "label_fr": "Rzeczyce"
            },
            {
                "value": 74698,
                "label": "Ozimek",
                "label_ar": "اوزيميك",
                "label_fr": "Ozimek"
            },
            {
                "value": 74699,
                "label": "Jasienica",
                "label_ar": "جاسينيكا",
                "label_fr": "Jasienica"
            },
            {
                "value": 74700,
                "label": "Lekawica",
                "label_ar": "ليكويكا",
                "label_fr": "Lekawica"
            },
            {
                "value": 74701,
                "label": "Huta Dlutowska",
                "label_ar": "هوتا دلوتوسكا",
                "label_fr": "Huta Dlutowska"
            },
            {
                "value": 74702,
                "label": "Dobieszowice",
                "label_ar": "Dobieszowice",
                "label_fr": "Dobieszowice"
            },
            {
                "value": 74703,
                "label": "Ligota",
                "label_ar": "ليغوتا",
                "label_fr": "Ligota"
            },
            {
                "value": 74704,
                "label": "Niemodlin",
                "label_ar": "نيمودلين",
                "label_fr": "Niemodlin"
            },
            {
                "value": 74705,
                "label": "Kraszew",
                "label_ar": "كرازيو",
                "label_fr": "Kraszew"
            },
            {
                "value": 74706,
                "label": "Czekanow",
                "label_ar": "Czekanow",
                "label_fr": "Czekanow"
            },
            {
                "value": 74707,
                "label": "Brudzowice",
                "label_ar": "برودزوفيتسي",
                "label_fr": "Brudzowice"
            },
            {
                "value": 74708,
                "label": "Kocierzew Poludniowy",
                "label_ar": "Kocierzew Poludniowy",
                "label_fr": "Kocierzew Poludniowy"
            },
            {
                "value": 74709,
                "label": "Karchowice",
                "label_ar": "كارشوفيتسي",
                "label_fr": "Karchowice"
            },
            {
                "value": 74710,
                "label": "Wierzchowisko",
                "label_ar": "Wierzchowisko",
                "label_fr": "Wierzchowisko"
            },
            {
                "value": 74711,
                "label": "Torzym",
                "label_ar": "تورزيم",
                "label_fr": "Torzym"
            },
            {
                "value": 74712,
                "label": "Chełmce",
                "label_ar": "تشيمس",
                "label_fr": "Chełmce"
            },
            {
                "value": 74713,
                "label": "Lipno",
                "label_ar": "ليبنو",
                "label_fr": "Lipno"
            },
            {
                "value": 74714,
                "label": "Kleszczewo",
                "label_ar": "Kleszczewo",
                "label_fr": "Kleszczewo"
            },
            {
                "value": 74715,
                "label": "Gmina Kiszkowo",
                "label_ar": "جمينا كيشكوو",
                "label_fr": "Gmina Kiszkowo"
            },
            {
                "value": 74716,
                "label": "Slone",
                "label_ar": "سلون",
                "label_fr": "Slone"
            },
            {
                "value": 74717,
                "label": "Golanice",
                "label_ar": "جولانيس",
                "label_fr": "Golanice"
            },
            {
                "value": 74718,
                "label": "Kowalewo Pomorskie",
                "label_ar": "كواليو بومورسكي",
                "label_fr": "Kowalewo Pomorskie"
            },
            {
                "value": 74719,
                "label": "Witnica",
                "label_ar": "ويتنيكا",
                "label_fr": "Witnica"
            },
            {
                "value": 74720,
                "label": "Niegoslawice",
                "label_ar": "نيغوسلاوس",
                "label_fr": "Niegoslawice"
            },
            {
                "value": 74721,
                "label": "Szadlowice",
                "label_ar": "زادوفيتسي",
                "label_fr": "Szadlowice"
            },
            {
                "value": 74722,
                "label": "Pniewy",
                "label_ar": "Pniewy",
                "label_fr": "Pniewy"
            },
            {
                "value": 74723,
                "label": "Zaparcin",
                "label_ar": "زابارسين",
                "label_fr": "Zaparcin"
            },
            {
                "value": 74724,
                "label": "Paliszewo",
                "label_ar": "باليسزو",
                "label_fr": "Paliszewo"
            },
            {
                "value": 74725,
                "label": "Melno",
                "label_ar": "ميلنو",
                "label_fr": "Melno"
            },
            {
                "value": 74726,
                "label": "Juchnowiec Koscielny",
                "label_ar": "جوتشنويك كوسيلني",
                "label_fr": "Juchnowiec Koscielny"
            },
            {
                "value": 74727,
                "label": "Gmina Ciechanowiec",
                "label_ar": "غمينا سيشانوفيتش",
                "label_fr": "Gmina Ciechanowiec"
            },
            {
                "value": 74728,
                "label": "Grabowka",
                "label_ar": "جرابووكا",
                "label_fr": "Grabowka"
            },
            {
                "value": 74729,
                "label": "Krypno",
                "label_ar": "كريبنو",
                "label_fr": "Krypno"
            },
            {
                "value": 74730,
                "label": "Gmina Jedlińsk",
                "label_ar": "جمينا Jedlińsk",
                "label_fr": "Gmina Jedlińsk"
            },
            {
                "value": 74731,
                "label": "Knyszyn",
                "label_ar": "كنيسزين",
                "label_fr": "Knyszyn"
            },
            {
                "value": 74732,
                "label": "Osieck",
                "label_ar": "أوسيك",
                "label_fr": "Osieck"
            },
            {
                "value": 74733,
                "label": "Ciechanów",
                "label_ar": "Ciechanów",
                "label_fr": "Ciechanów"
            },
            {
                "value": 74734,
                "label": "Niedrzwica Duza",
                "label_ar": "Niedrzwica Duza",
                "label_fr": "Niedrzwica Duza"
            },
            {
                "value": 74735,
                "label": "Bochotnica",
                "label_ar": "Bochotnica",
                "label_fr": "Bochotnica"
            },
            {
                "value": 74736,
                "label": "Moszczanka",
                "label_ar": "Moszczanka",
                "label_fr": "Moszczanka"
            },
            {
                "value": 74737,
                "label": "Wawolnica",
                "label_ar": "Wawolnica",
                "label_fr": "Wawolnica"
            },
            {
                "value": 74738,
                "label": "Lipiny",
                "label_ar": "ليبيني",
                "label_fr": "Lipiny"
            },
            {
                "value": 74739,
                "label": "Klikawa",
                "label_ar": "كليكاوا",
                "label_fr": "Klikawa"
            },
            {
                "value": 74740,
                "label": "Wola Baranowska",
                "label_ar": "وولا بارانوسكا",
                "label_fr": "Wola Baranowska"
            },
            {
                "value": 74741,
                "label": "Wadowice Gorne",
                "label_ar": "فادوفيتسي جورن",
                "label_fr": "Wadowice Gorne"
            },
            {
                "value": 74742,
                "label": "Wielbark",
                "label_ar": "ويلبارك",
                "label_fr": "Wielbark"
            },
            {
                "value": 74743,
                "label": "Gorzkow",
                "label_ar": "جورزكو",
                "label_fr": "Gorzkow"
            },
            {
                "value": 74744,
                "label": "Bychawa",
                "label_ar": "بيشاوا",
                "label_fr": "Bychawa"
            },
            {
                "value": 74745,
                "label": "Jeziorany",
                "label_ar": "Jeziorany",
                "label_fr": "Jeziorany"
            },
            {
                "value": 74746,
                "label": "Siennica",
                "label_ar": "سيينيكا",
                "label_fr": "Siennica"
            },
            {
                "value": 74747,
                "label": "Pilawa",
                "label_ar": "بيلاوا",
                "label_fr": "Pilawa"
            },
            {
                "value": 74748,
                "label": "Ojrzen",
                "label_ar": "اوجرزين",
                "label_fr": "Ojrzen"
            },
            {
                "value": 74749,
                "label": "Duczki",
                "label_ar": "دوزكي",
                "label_fr": "Duczki"
            },
            {
                "value": 74750,
                "label": "Gąsocin",
                "label_ar": "جوسوسين",
                "label_fr": "Gąsocin"
            },
            {
                "value": 74751,
                "label": "Dzierzgon",
                "label_ar": "دزيرزغون",
                "label_fr": "Dzierzgon"
            },
            {
                "value": 74752,
                "label": "Miloradz",
                "label_ar": "ميلورادز",
                "label_fr": "Miloradz"
            },
            {
                "value": 74753,
                "label": "Koleczkowo",
                "label_ar": "كوليزكوو",
                "label_fr": "Koleczkowo"
            },
            {
                "value": 74754,
                "label": "Milobadz",
                "label_ar": "ميلوبادز",
                "label_fr": "Milobadz"
            },
            {
                "value": 74755,
                "label": "Liniewo",
                "label_ar": "لينيو",
                "label_fr": "Liniewo"
            },
            {
                "value": 74756,
                "label": "Stary Dzierzgoń",
                "label_ar": "ستاري دزيرزغو",
                "label_fr": "Stary Dzierzgoń"
            },
            {
                "value": 74757,
                "label": "Gmina Poddębice",
                "label_ar": "جمينا بودوبيس",
                "label_fr": "Gmina Poddębice"
            },
            {
                "value": 74758,
                "label": "Gmina Sulęcin",
                "label_ar": "جمينا سوليسين",
                "label_fr": "Gmina Sulęcin"
            },
            {
                "value": 74759,
                "label": "Nowe Miasto nad Warta",
                "label_ar": "Nowe Miasto ناد وارتا",
                "label_fr": "Nowe Miasto nad Warta"
            },
            {
                "value": 74760,
                "label": "Gołubie",
                "label_ar": "Gołubie",
                "label_fr": "Gołubie"
            },
            {
                "value": 74761,
                "label": "Sulmin",
                "label_ar": "سولمين",
                "label_fr": "Sulmin"
            },
            {
                "value": 74762,
                "label": "Slawoszyno",
                "label_ar": "Slawoszyno",
                "label_fr": "Slawoszyno"
            },
            {
                "value": 74763,
                "label": "Mierzeszyn",
                "label_ar": "ميرززين",
                "label_fr": "Mierzeszyn"
            },
            {
                "value": 74764,
                "label": "Otomin",
                "label_ar": "أوتومين",
                "label_fr": "Otomin"
            },
            {
                "value": 74765,
                "label": "Choczewo",
                "label_ar": "تشوتشيو",
                "label_fr": "Choczewo"
            },
            {
                "value": 74766,
                "label": "Kobylnica",
                "label_ar": "كوبيلنيكا",
                "label_fr": "Kobylnica"
            },
            {
                "value": 74767,
                "label": "Szlachta",
                "label_ar": "سزلاشتا",
                "label_fr": "Szlachta"
            },
            {
                "value": 74768,
                "label": "Rakowiec",
                "label_ar": "راكويك",
                "label_fr": "Rakowiec"
            },
            {
                "value": 74769,
                "label": "Stankowo",
                "label_ar": "ستانكوو",
                "label_fr": "Stankowo"
            },
            {
                "value": 74770,
                "label": "Majewo",
                "label_ar": "Majewo",
                "label_fr": "Majewo"
            },
            {
                "value": 74771,
                "label": "Borkowo",
                "label_ar": "بوركوو",
                "label_fr": "Borkowo"
            },
            {
                "value": 74772,
                "label": "Bojanowo",
                "label_ar": "Bojanowo",
                "label_fr": "Bojanowo"
            },
            {
                "value": 74773,
                "label": "Lipusz",
                "label_ar": "ليبوس",
                "label_fr": "Lipusz"
            },
            {
                "value": 74774,
                "label": "Sobowidz",
                "label_ar": "سوبويدز",
                "label_fr": "Sobowidz"
            },
            {
                "value": 74775,
                "label": "Pniewy",
                "label_ar": "Pniewy",
                "label_fr": "Pniewy"
            },
            {
                "value": 74776,
                "label": "Lubichowo",
                "label_ar": "Lubichowo",
                "label_fr": "Lubichowo"
            },
            {
                "value": 74777,
                "label": "Tenczyn",
                "label_ar": "تنزين",
                "label_fr": "Tenczyn"
            },
            {
                "value": 74778,
                "label": "Nowa Biala",
                "label_ar": "نوا بيالا",
                "label_fr": "Nowa Biala"
            },
            {
                "value": 74779,
                "label": "Szyce",
                "label_ar": "Szyce",
                "label_fr": "Szyce"
            },
            {
                "value": 74780,
                "label": "Michalowice",
                "label_ar": "ميكالوفيتسي",
                "label_fr": "Michalowice"
            },
            {
                "value": 74781,
                "label": "Gaworzyce",
                "label_ar": "جاورزيز",
                "label_fr": "Gaworzyce"
            },
            {
                "value": 74782,
                "label": "Iwanowice",
                "label_ar": "إيوانوفيتسي",
                "label_fr": "Iwanowice"
            },
            {
                "value": 74783,
                "label": "Konstantynow",
                "label_ar": "كونستانتينو",
                "label_fr": "Konstantynow"
            },
            {
                "value": 74784,
                "label": "Konstantynow Lodzki",
                "label_ar": "كونستانتينو لودزكي",
                "label_fr": "Konstantynow Lodzki"
            },
            {
                "value": 74785,
                "label": "Olszyna",
                "label_ar": "أولزينا",
                "label_fr": "Olszyna"
            },
            {
                "value": 74786,
                "label": "Lesna",
                "label_ar": "ليسنا",
                "label_fr": "Lesna"
            },
            {
                "value": 74787,
                "label": "Lądek Zdrój",
                "label_ar": "ليدك زدروي",
                "label_fr": "Lądek Zdrój"
            },
            {
                "value": 74788,
                "label": "Rabka-Zdroj",
                "label_ar": "ربكه زدروج",
                "label_fr": "Rabka-Zdroj"
            },
            {
                "value": 74789,
                "label": "Krapkowice",
                "label_ar": "كرابكوفيتسي",
                "label_fr": "Krapkowice"
            },
            {
                "value": 74790,
                "label": "Mucharz",
                "label_ar": "موخارز",
                "label_fr": "Mucharz"
            },
            {
                "value": 74791,
                "label": "Szewce",
                "label_ar": "سزيوس",
                "label_fr": "Szewce"
            },
            {
                "value": 74792,
                "label": "Wozniki",
                "label_ar": "وزنيكي",
                "label_fr": "Wozniki"
            },
            {
                "value": 74793,
                "label": "Dlugopole-Zdroj",
                "label_ar": "دلوجوبول زدروج",
                "label_fr": "Dlugopole-Zdroj"
            },
            {
                "value": 74794,
                "label": "Kruszyn",
                "label_ar": "كروزين",
                "label_fr": "Kruszyn"
            },
            {
                "value": 74795,
                "label": "Tymbark",
                "label_ar": "تيمبارك",
                "label_fr": "Tymbark"
            },
            {
                "value": 74796,
                "label": "Skarzysko-Kamienna",
                "label_ar": "سكارزيسكو كامينا",
                "label_fr": "Skarzysko-Kamienna"
            },
            {
                "value": 74797,
                "label": "Peczniew",
                "label_ar": "بيتشنيو",
                "label_fr": "Peczniew"
            },
            {
                "value": 74798,
                "label": "Gluchow",
                "label_ar": "جلوشو",
                "label_fr": "Gluchow"
            },
            {
                "value": 74799,
                "label": "Gryfow Slaski",
                "label_ar": "جريفوف سلاسكي",
                "label_fr": "Gryfow Slaski"
            },
            {
                "value": 74800,
                "label": "Gmina Zakliczyn",
                "label_ar": "غمينا زاكليزين",
                "label_fr": "Gmina Zakliczyn"
            },
            {
                "value": 74801,
                "label": "Bibice",
                "label_ar": "بيبيس",
                "label_fr": "Bibice"
            },
            {
                "value": 74802,
                "label": "Gorzyczki",
                "label_ar": "جورزيتشكي",
                "label_fr": "Gorzyczki"
            },
            {
                "value": 74803,
                "label": "Kalety",
                "label_ar": "كاليتي",
                "label_fr": "Kalety"
            },
            {
                "value": 74804,
                "label": "Biskupice",
                "label_ar": "بيسكوبيتسي",
                "label_fr": "Biskupice"
            },
            {
                "value": 74805,
                "label": "Mirkow",
                "label_ar": "ميركو",
                "label_fr": "Mirkow"
            },
            {
                "value": 74806,
                "label": "Szczyrk",
                "label_ar": "Szczyrk",
                "label_fr": "Szczyrk"
            },
            {
                "value": 74807,
                "label": "Jarkowice",
                "label_ar": "جاركوفيتسي",
                "label_fr": "Jarkowice"
            },
            {
                "value": 74808,
                "label": "Wegliniec",
                "label_ar": "Wegliniec",
                "label_fr": "Wegliniec"
            },
            {
                "value": 74809,
                "label": "Mokrsko",
                "label_ar": "موكرسكو",
                "label_fr": "Mokrsko"
            },
            {
                "value": 74810,
                "label": "Zawadzkie",
                "label_ar": "Zawadzkie",
                "label_fr": "Zawadzkie"
            },
            {
                "value": 74811,
                "label": "Ciasna",
                "label_ar": "Ciasna",
                "label_fr": "Ciasna"
            },
            {
                "value": 74812,
                "label": "Uciechow",
                "label_ar": "Uciechow",
                "label_fr": "Uciechow"
            },
            {
                "value": 74813,
                "label": "Pielgrzymka",
                "label_ar": "بيلجرزيمكا",
                "label_fr": "Pielgrzymka"
            },
            {
                "value": 74814,
                "label": "Szczercow",
                "label_ar": "شتشيركو",
                "label_fr": "Szczercow"
            },
            {
                "value": 74815,
                "label": "Garki",
                "label_ar": "جاركي",
                "label_fr": "Garki"
            },
            {
                "value": 74816,
                "label": "Klecza Dolna",
                "label_ar": "كليكزا دولنا",
                "label_fr": "Klecza Dolna"
            },
            {
                "value": 74817,
                "label": "Nisko",
                "label_ar": "نيسكو",
                "label_fr": "Nisko"
            },
            {
                "value": 74818,
                "label": "Kroscienko Wyzne",
                "label_ar": "كروزينكو ويزني",
                "label_fr": "Kroscienko Wyzne"
            },
            {
                "value": 74819,
                "label": "Lezajsk",
                "label_ar": "ليزايسك",
                "label_fr": "Lezajsk"
            },
            {
                "value": 74820,
                "label": "Gmina Księżpol",
                "label_ar": "جمينا كسيبول",
                "label_fr": "Gmina Księżpol"
            },
            {
                "value": 74821,
                "label": "Nielisz",
                "label_ar": "Nielisz",
                "label_fr": "Nielisz"
            },
            {
                "value": 74822,
                "label": "Ostrow Lubelski",
                "label_ar": "أوسترو لوبليسكي",
                "label_fr": "Ostrow Lubelski"
            },
            {
                "value": 74823,
                "label": "Grodzisko Dolne",
                "label_ar": "جرودزيسكو دولني",
                "label_fr": "Grodzisko Dolne"
            },
            {
                "value": 74824,
                "label": "Milanow",
                "label_ar": "ميلانوف",
                "label_fr": "Milanow"
            },
            {
                "value": 74825,
                "label": "Nowo-Aleksandrowo",
                "label_ar": "نوو أليكساندرو",
                "label_fr": "Nowo-Aleksandrowo"
            },
            {
                "value": 74826,
                "label": "Jezowe",
                "label_ar": "جيزوي",
                "label_fr": "Jezowe"
            },
            {
                "value": 74827,
                "label": "Pysznica",
                "label_ar": "بيزنيكا",
                "label_fr": "Pysznica"
            },
            {
                "value": 74828,
                "label": "Susiec",
                "label_ar": "سوسيك",
                "label_fr": "Susiec"
            },
            {
                "value": 74829,
                "label": "Lubycza Krolewska",
                "label_ar": "Lubycza Krolewska",
                "label_fr": "Lubycza Krolewska"
            },
            {
                "value": 74830,
                "label": "Szczuczyn",
                "label_ar": "شتشوتشين",
                "label_fr": "Szczuczyn"
            },
            {
                "value": 74831,
                "label": "Otrebusy",
                "label_ar": "أوتريبوسي",
                "label_fr": "Otrebusy"
            },
            {
                "value": 74832,
                "label": "Slupno",
                "label_ar": "سلوبنو",
                "label_fr": "Slupno"
            },
            {
                "value": 74833,
                "label": "Frydrychowice",
                "label_ar": "فرايدريتشوفيتسي",
                "label_fr": "Frydrychowice"
            },
            {
                "value": 74834,
                "label": "Kuznia",
                "label_ar": "كوزنيا",
                "label_fr": "Kuznia"
            },
            {
                "value": 74835,
                "label": "Dankowice",
                "label_ar": "دانكويس",
                "label_fr": "Dankowice"
            },
            {
                "value": 74836,
                "label": "Dzialoszyn",
                "label_ar": "دزيالوسزين",
                "label_fr": "Dzialoszyn"
            },
            {
                "value": 74837,
                "label": "Marklowice",
                "label_ar": "ماركلوفيتسي",
                "label_fr": "Marklowice"
            },
            {
                "value": 74838,
                "label": "Rogoznik",
                "label_ar": "روجوزنيك",
                "label_fr": "Rogoznik"
            },
            {
                "value": 74839,
                "label": "Bielany",
                "label_ar": "بيلاني",
                "label_fr": "Bielany"
            },
            {
                "value": 74840,
                "label": "Bolechowice",
                "label_ar": "Bolechowice",
                "label_fr": "Bolechowice"
            },
            {
                "value": 74841,
                "label": "Minoga",
                "label_ar": "مينوجا",
                "label_fr": "Minoga"
            },
            {
                "value": 74842,
                "label": "Chybie",
                "label_ar": "تشيبي",
                "label_fr": "Chybie"
            },
            {
                "value": 74843,
                "label": "Popow",
                "label_ar": "بوبو",
                "label_fr": "Popow"
            },
            {
                "value": 74844,
                "label": "Jablonka",
                "label_ar": "جابلونكا",
                "label_fr": "Jablonka"
            },
            {
                "value": 74845,
                "label": "Zawoja",
                "label_ar": "Zawoja",
                "label_fr": "Zawoja"
            },
            {
                "value": 74846,
                "label": "Biecz",
                "label_ar": "بيكز",
                "label_fr": "Biecz"
            },
            {
                "value": 74847,
                "label": "Byczyna",
                "label_ar": "بيكزينا",
                "label_fr": "Byczyna"
            },
            {
                "value": 74848,
                "label": "Gorzkowice",
                "label_ar": "جورزكوفيتسه",
                "label_fr": "Gorzkowice"
            },
            {
                "value": 74849,
                "label": "Druzbice",
                "label_ar": "دروزبيس",
                "label_fr": "Druzbice"
            },
            {
                "value": 74850,
                "label": "Trzebunia",
                "label_ar": "ترزبونيا",
                "label_fr": "Trzebunia"
            },
            {
                "value": 74851,
                "label": "Gora Siewierska",
                "label_ar": "غورا سيويرسكا",
                "label_fr": "Gora Siewierska"
            },
            {
                "value": 74852,
                "label": "Skrbensko",
                "label_ar": "سكربنسكو",
                "label_fr": "Skrbensko"
            },
            {
                "value": 74853,
                "label": "Wisla Wielka",
                "label_ar": "ويسلا ويلكا",
                "label_fr": "Wisla Wielka"
            },
            {
                "value": 74854,
                "label": "Smolec",
                "label_ar": "سموليك",
                "label_fr": "Smolec"
            },
            {
                "value": 74855,
                "label": "Konradowka",
                "label_ar": "كونرادوفكا",
                "label_fr": "Konradowka"
            },
            {
                "value": 74856,
                "label": "Bielany Wroclawskie",
                "label_ar": "بيلاني فروكلافسكي",
                "label_fr": "Bielany Wroclawskie"
            },
            {
                "value": 74857,
                "label": "Drzewica",
                "label_ar": "Drzewica",
                "label_fr": "Drzewica"
            },
            {
                "value": 74858,
                "label": "Konstantyn",
                "label_ar": "كونستانتين",
                "label_fr": "Konstantyn"
            },
            {
                "value": 74859,
                "label": "Namyslow",
                "label_ar": "ناميسلو",
                "label_fr": "Namyslow"
            },
            {
                "value": 74860,
                "label": "Janow",
                "label_ar": "جانو",
                "label_fr": "Janow"
            },
            {
                "value": 74861,
                "label": "Szczejkowice",
                "label_ar": "Szczejkowice",
                "label_fr": "Szczejkowice"
            },
            {
                "value": 74862,
                "label": "Sanniki",
                "label_ar": "سانيكي",
                "label_fr": "Sanniki"
            },
            {
                "value": 74863,
                "label": "Rajszew",
                "label_ar": "راجزيو",
                "label_fr": "Rajszew"
            },
            {
                "value": 74864,
                "label": "Jasieniec",
                "label_ar": "جاسينيك",
                "label_fr": "Jasieniec"
            },
            {
                "value": 74865,
                "label": "Ustanow",
                "label_ar": "Ustanow",
                "label_fr": "Ustanow"
            },
            {
                "value": 74866,
                "label": "Pomiechowek",
                "label_ar": "بوميتشويك",
                "label_fr": "Pomiechowek"
            },
            {
                "value": 74867,
                "label": "Kazimierz Dolny",
                "label_ar": "كازيميرز دولني",
                "label_fr": "Kazimierz Dolny"
            },
            {
                "value": 74868,
                "label": "Czarna",
                "label_ar": "قصرنا",
                "label_fr": "Czarna"
            },
            {
                "value": 74869,
                "label": "Nasielsk",
                "label_ar": "ناسيلسك",
                "label_fr": "Nasielsk"
            },
            {
                "value": 74870,
                "label": "Ryki",
                "label_ar": "ريكي",
                "label_fr": "Ryki"
            },
            {
                "value": 74871,
                "label": "Staroźreby",
                "label_ar": "Starobyreby",
                "label_fr": "Staroźreby"
            },
            {
                "value": 74872,
                "label": "Bukowsko",
                "label_ar": "بوكوفسكو",
                "label_fr": "Bukowsko"
            },
            {
                "value": 74873,
                "label": "Magnuszew",
                "label_ar": "ماغنوسزو",
                "label_fr": "Magnuszew"
            },
            {
                "value": 74874,
                "label": "Strzyze",
                "label_ar": "Strzyze",
                "label_fr": "Strzyze"
            },
            {
                "value": 74875,
                "label": "Dunaj",
                "label_ar": "دوناج",
                "label_fr": "Dunaj"
            },
            {
                "value": 74876,
                "label": "Trzeszczyn",
                "label_ar": "Trzeszczyn",
                "label_fr": "Trzeszczyn"
            },
            {
                "value": 74877,
                "label": "Brzeznica",
                "label_ar": "Brzeznica",
                "label_fr": "Brzeznica"
            },
            {
                "value": 74878,
                "label": "Belsznica",
                "label_ar": "بيلزنيكا",
                "label_fr": "Belsznica"
            },
            {
                "value": 74879,
                "label": "Kasinka",
                "label_ar": "كاسينكا",
                "label_fr": "Kasinka"
            },
            {
                "value": 74880,
                "label": "Szczecinek",
                "label_ar": "شتشينيك",
                "label_fr": "Szczecinek"
            },
            {
                "value": 74881,
                "label": "Baran",
                "label_ar": "باران",
                "label_fr": "Baran"
            },
            {
                "value": 74882,
                "label": "Zielone",
                "label_ar": "زيلون",
                "label_fr": "Zielone"
            },
            {
                "value": 74883,
                "label": "Makowiec",
                "label_ar": "ماكوفيتش",
                "label_fr": "Makowiec"
            },
            {
                "value": 74884,
                "label": "Praca",
                "label_ar": "براكا",
                "label_fr": "Praca"
            },
            {
                "value": 74885,
                "label": "Kielno",
                "label_ar": "كيلنو",
                "label_fr": "Kielno"
            },
            {
                "value": 74886,
                "label": "Rogozno",
                "label_ar": "روجوزنو",
                "label_fr": "Rogozno"
            },
            {
                "value": 74887,
                "label": "Szreniawa",
                "label_ar": "Szreniawa",
                "label_fr": "Szreniawa"
            },
            {
                "value": 74888,
                "label": "Gmina Władysławów",
                "label_ar": "Gmina Wadysławów",
                "label_fr": "Gmina Władysławów"
            },
            {
                "value": 74889,
                "label": "Gniewino",
                "label_ar": "جنيوينو",
                "label_fr": "Gniewino"
            },
            {
                "value": 74890,
                "label": "Luzino",
                "label_ar": "لوزينو",
                "label_fr": "Luzino"
            },
            {
                "value": 74891,
                "label": "Gościcino",
                "label_ar": "Gościcino",
                "label_fr": "Gościcino"
            },
            {
                "value": 74892,
                "label": "Karlikowo",
                "label_ar": "كارليكوفو",
                "label_fr": "Karlikowo"
            },
            {
                "value": 74893,
                "label": "Dobrzyca",
                "label_ar": "دوبرزيكا",
                "label_fr": "Dobrzyca"
            },
            {
                "value": 74894,
                "label": "Stara Lubianka",
                "label_ar": "ستارا لوبيانكا",
                "label_fr": "Stara Lubianka"
            },
            {
                "value": 74895,
                "label": "Pila",
                "label_ar": "بيلا",
                "label_fr": "Pila"
            },
            {
                "value": 74896,
                "label": "Dolaszewo",
                "label_ar": "Dolaszewo",
                "label_fr": "Dolaszewo"
            },
            {
                "value": 74897,
                "label": "Turowiec",
                "label_ar": "Turowiec",
                "label_fr": "Turowiec"
            },
            {
                "value": 74898,
                "label": "Grzegorz",
                "label_ar": "جرزيغورز",
                "label_fr": "Grzegorz"
            },
            {
                "value": 74899,
                "label": "Suszec",
                "label_ar": "سوزيك",
                "label_fr": "Suszec"
            },
            {
                "value": 74900,
                "label": "Szczurowa",
                "label_ar": "شتشوروا",
                "label_fr": "Szczurowa"
            },
            {
                "value": 74901,
                "label": "Dulcza Wielka",
                "label_ar": "دولتشا ويلكا",
                "label_fr": "Dulcza Wielka"
            },
            {
                "value": 74902,
                "label": "Liw",
                "label_ar": "ليو",
                "label_fr": "Liw"
            },
            {
                "value": 74903,
                "label": "Ostrow Mazowiecka",
                "label_ar": "أوسترو مازوييكا",
                "label_fr": "Ostrow Mazowiecka"
            },
            {
                "value": 74904,
                "label": "Szczepanow",
                "label_ar": "شتشيبانو",
                "label_fr": "Szczepanow"
            },
            {
                "value": 74905,
                "label": "Borzecin",
                "label_ar": "بورزيسين",
                "label_fr": "Borzecin"
            },
            {
                "value": 74906,
                "label": "Wysokie Mazowieckie",
                "label_ar": "Wysokie Mazowieckie",
                "label_fr": "Wysokie Mazowieckie"
            },
            {
                "value": 74907,
                "label": "Szepietowo",
                "label_ar": "Szepietowo",
                "label_fr": "Szepietowo"
            },
            {
                "value": 74908,
                "label": "Śniadowo",
                "label_ar": "Śniadowo",
                "label_fr": "Śniadowo"
            },
            {
                "value": 74909,
                "label": "Rudzica",
                "label_ar": "رودزيكا",
                "label_fr": "Rudzica"
            },
            {
                "value": 74910,
                "label": "Kokoszkowy",
                "label_ar": "Kokoszkowy",
                "label_fr": "Kokoszkowy"
            },
            {
                "value": 74911,
                "label": "Rychwal",
                "label_ar": "ريتشل",
                "label_fr": "Rychwal"
            },
            {
                "value": 74912,
                "label": "Ostrzeszow",
                "label_ar": "Ostrzeszow",
                "label_fr": "Ostrzeszow"
            },
            {
                "value": 74913,
                "label": "Golkowice",
                "label_ar": "جولكويس",
                "label_fr": "Golkowice"
            },
            {
                "value": 74914,
                "label": "Zelazkow",
                "label_ar": "زيلازكو",
                "label_fr": "Zelazkow"
            },
            {
                "value": 74915,
                "label": "Kępno",
                "label_ar": "كوبنو",
                "label_fr": "Kępno"
            },
            {
                "value": 74916,
                "label": "Brok",
                "label_ar": "بروك",
                "label_fr": "Brok"
            },
            {
                "value": 74917,
                "label": "Chwaszczyno",
                "label_ar": "Chwaszczyno",
                "label_fr": "Chwaszczyno"
            },
            {
                "value": 74918,
                "label": "Bierun Nowy",
                "label_ar": "بيرون نوي",
                "label_fr": "Bierun Nowy"
            },
            {
                "value": 74919,
                "label": "Odolanow",
                "label_ar": "أودولانو",
                "label_fr": "Odolanow"
            },
            {
                "value": 74920,
                "label": "Topola Mala",
                "label_ar": "توبولا مالا",
                "label_fr": "Topola Mala"
            },
            {
                "value": 74921,
                "label": "Rebkow",
                "label_ar": "ريبكو",
                "label_fr": "Rebkow"
            },
            {
                "value": 74922,
                "label": "Cieszkow",
                "label_ar": "سيسكوف",
                "label_fr": "Cieszkow"
            },
            {
                "value": 74923,
                "label": "Zduny",
                "label_ar": "زدوني",
                "label_fr": "Zduny"
            },
            {
                "value": 74924,
                "label": "Strzelce Krajenskie",
                "label_ar": "Strzelce Krajenskie",
                "label_fr": "Strzelce Krajenskie"
            },
            {
                "value": 74925,
                "label": "Polomia",
                "label_ar": "بولوميا",
                "label_fr": "Polomia"
            },
            {
                "value": 74926,
                "label": "Miejska Gorka",
                "label_ar": "مييسكا جوركا",
                "label_fr": "Miejska Gorka"
            },
            {
                "value": 74927,
                "label": "Strzelno",
                "label_ar": "سترزيلنو",
                "label_fr": "Strzelno"
            },
            {
                "value": 74928,
                "label": "Goleszow",
                "label_ar": "جولزوف",
                "label_fr": "Goleszow"
            },
            {
                "value": 74929,
                "label": "Rozanka",
                "label_ar": "روزانكا",
                "label_fr": "Rozanka"
            },
            {
                "value": 74930,
                "label": "Lochow",
                "label_ar": "Lochow",
                "label_fr": "Lochow"
            },
            {
                "value": 74931,
                "label": "Henrykow",
                "label_ar": "هنريكوف",
                "label_fr": "Henrykow"
            },
            {
                "value": 74932,
                "label": "Pietrowice Wielkie",
                "label_ar": "بيترويتس ويلكي",
                "label_fr": "Pietrowice Wielkie"
            },
            {
                "value": 74933,
                "label": "Gorzyce",
                "label_ar": "جورزيز",
                "label_fr": "Gorzyce"
            },
            {
                "value": 74934,
                "label": "Glowienka",
                "label_ar": "جلوينكا",
                "label_fr": "Glowienka"
            },
            {
                "value": 74935,
                "label": "Reszel",
                "label_ar": "ريسل",
                "label_fr": "Reszel"
            },
            {
                "value": 74936,
                "label": "Olecko",
                "label_ar": "اوليكو",
                "label_fr": "Olecko"
            },
            {
                "value": 74937,
                "label": "Dabrowskie",
                "label_ar": "دابروفسكي",
                "label_fr": "Dabrowskie"
            },
            {
                "value": 74938,
                "label": "Zabor",
                "label_ar": "زابور",
                "label_fr": "Zabor"
            },
            {
                "value": 74939,
                "label": "Kozuchow",
                "label_ar": "كوزوتشوف",
                "label_fr": "Kozuchow"
            },
            {
                "value": 74940,
                "label": "Czosnow",
                "label_ar": "كزوسنوو",
                "label_fr": "Czosnow"
            },
            {
                "value": 74941,
                "label": "Zielona",
                "label_ar": "زيلونا",
                "label_fr": "Zielona"
            },
            {
                "value": 74942,
                "label": "Wilga",
                "label_ar": "ويلجا",
                "label_fr": "Wilga"
            },
            {
                "value": 74943,
                "label": "Zloczew",
                "label_ar": "زلوتشيف",
                "label_fr": "Zloczew"
            },
            {
                "value": 74944,
                "label": "Zagosciniec",
                "label_ar": "زاغوسينيك",
                "label_fr": "Zagosciniec"
            },
            {
                "value": 74945,
                "label": "Podlasie",
                "label_ar": "بودلاسي",
                "label_fr": "Podlasie"
            },
            {
                "value": 74946,
                "label": "Zerniki",
                "label_ar": "زرنيكي",
                "label_fr": "Zerniki"
            },
            {
                "value": 74947,
                "label": "Sulistrowice",
                "label_ar": "سوليسترويتس",
                "label_fr": "Sulistrowice"
            },
            {
                "value": 74948,
                "label": "Chyliczki",
                "label_ar": "تشيليتشكي",
                "label_fr": "Chyliczki"
            },
            {
                "value": 74949,
                "label": "Kleczany",
                "label_ar": "كليكزاني",
                "label_fr": "Kleczany"
            },
            {
                "value": 74950,
                "label": "Międzyrzec Podlaski",
                "label_ar": "Międzyrzec Podlaski",
                "label_fr": "Międzyrzec Podlaski"
            },
            {
                "value": 74951,
                "label": "Bratkowice",
                "label_ar": "براتكوفيتسي",
                "label_fr": "Bratkowice"
            },
            {
                "value": 74952,
                "label": "Wolbrom",
                "label_ar": "وولبروم",
                "label_fr": "Wolbrom"
            },
            {
                "value": 74953,
                "label": "Radlow",
                "label_ar": "رادلو",
                "label_fr": "Radlow"
            },
            {
                "value": 74954,
                "label": "Wawel",
                "label_ar": "فافل",
                "label_fr": "Wawel"
            },
            {
                "value": 74955,
                "label": "Rozgarty",
                "label_ar": "روزجارتى",
                "label_fr": "Rozgarty"
            },
            {
                "value": 74956,
                "label": "Osiek",
                "label_ar": "Osiek",
                "label_fr": "Osiek"
            },
            {
                "value": 74957,
                "label": "Pawlowice",
                "label_ar": "باولويتشي",
                "label_fr": "Pawlowice"
            },
            {
                "value": 74958,
                "label": "Pszow",
                "label_ar": "Pszow",
                "label_fr": "Pszow"
            },
            {
                "value": 74959,
                "label": "Zagorzyce",
                "label_ar": "زاغورسيس",
                "label_fr": "Zagorzyce"
            },
            {
                "value": 74960,
                "label": "Iwierzyce",
                "label_ar": "إيويرزي",
                "label_fr": "Iwierzyce"
            },
            {
                "value": 74961,
                "label": "Nawodna",
                "label_ar": "نوودنا",
                "label_fr": "Nawodna"
            },
            {
                "value": 74962,
                "label": "Warzymice",
                "label_ar": "Warzymice",
                "label_fr": "Warzymice"
            },
            {
                "value": 74963,
                "label": "Darłowo",
                "label_ar": "Darłowo",
                "label_fr": "Darłowo"
            },
            {
                "value": 74964,
                "label": "Ostrowy",
                "label_ar": "أوستروي",
                "label_fr": "Ostrowy"
            },
            {
                "value": 74965,
                "label": "Miłki",
                "label_ar": "ميكي",
                "label_fr": "Miłki"
            },
            {
                "value": 74966,
                "label": "Węgorzewo",
                "label_ar": "Węgorzewo",
                "label_fr": "Węgorzewo"
            },
            {
                "value": 74967,
                "label": "Prabuty",
                "label_ar": "برابوتي",
                "label_fr": "Prabuty"
            },
            {
                "value": 74968,
                "label": "Stare Miasto",
                "label_ar": "ستاري مياستو",
                "label_fr": "Regarder Miasto"
            },
            {
                "value": 74969,
                "label": "Brzozow",
                "label_ar": "برزوزوف",
                "label_fr": "Brzozow"
            },
            {
                "value": 74970,
                "label": "Kamien",
                "label_ar": "كامين",
                "label_fr": "Kamien"
            },
            {
                "value": 74971,
                "label": "Stoczek",
                "label_ar": "Stoczek",
                "label_fr": "Stoczek"
            },
            {
                "value": 74972,
                "label": "Hajnówka",
                "label_ar": "Hajnówka",
                "label_fr": "Hajnówka"
            },
            {
                "value": 74973,
                "label": "Leczna",
                "label_ar": "ليكزنا",
                "label_fr": "Leczna"
            },
            {
                "value": 74974,
                "label": "Leszkowice",
                "label_ar": "ليسكوفيتسه",
                "label_fr": "Leszkowice"
            },
            {
                "value": 74975,
                "label": "Strzyzow",
                "label_ar": "Strzyzow",
                "label_fr": "Strzyzow"
            },
            {
                "value": 74976,
                "label": "Sulmierzyce",
                "label_ar": "سولميرزيز",
                "label_fr": "Sulmierzyce"
            },
            {
                "value": 74977,
                "label": "Karsko",
                "label_ar": "كارسكو",
                "label_fr": "Karsko"
            },
            {
                "value": 74978,
                "label": "Pustkow",
                "label_ar": "Pustkow",
                "label_fr": "Pustkow"
            },
            {
                "value": 74979,
                "label": "Zyrakow",
                "label_ar": "زيراكو",
                "label_fr": "Zyrakow"
            },
            {
                "value": 74980,
                "label": "Brzostek",
                "label_ar": "Brzostek",
                "label_fr": "Brzostek"
            },
            {
                "value": 74981,
                "label": "Radomysl Wielki",
                "label_ar": "رادوميسل ويلكي",
                "label_fr": "Radomysl Wielki"
            },
            {
                "value": 74982,
                "label": "Brzeznica",
                "label_ar": "Brzeznica",
                "label_fr": "Brzeznica"
            },
            {
                "value": 74983,
                "label": "Zarowka",
                "label_ar": "زاروفكا",
                "label_fr": "Zarowka"
            },
            {
                "value": 74984,
                "label": "Rzemien",
                "label_ar": "رزيميان",
                "label_fr": "Rzemien"
            },
            {
                "value": 74985,
                "label": "Plesna",
                "label_ar": "بليسنا",
                "label_fr": "Plesna"
            },
            {
                "value": 74986,
                "label": "Niedomice",
                "label_ar": "نيدوميتسي",
                "label_fr": "Niedomice"
            },
            {
                "value": 74987,
                "label": "Bogumilowice",
                "label_ar": "بوغوميلوفيتسي",
                "label_fr": "Bogumilowice"
            },
            {
                "value": 74988,
                "label": "Borne Sulinowo",
                "label_ar": "بورن سولينوو",
                "label_fr": "Borne Sulinowo"
            },
            {
                "value": 74989,
                "label": "Polczyn-Zdroj",
                "label_ar": "Polczyn-Zdroj",
                "label_fr": "Polczyn-Zdroj"
            },
            {
                "value": 74990,
                "label": "Czaplinek",
                "label_ar": "تشابلينيك",
                "label_fr": "Czaplinek"
            },
            {
                "value": 74991,
                "label": "Szostka",
                "label_ar": "زوستكا",
                "label_fr": "Szostka"
            },
            {
                "value": 74992,
                "label": "Kuzniki",
                "label_ar": "كوزنيكي",
                "label_fr": "Kuzniki"
            },
            {
                "value": 74993,
                "label": "Cianowice Duze",
                "label_ar": "سيانوفيتسي دوز",
                "label_fr": "Cianowice Duze"
            },
            {
                "value": 74994,
                "label": "Zbroslawice",
                "label_ar": "Zbroslawice",
                "label_fr": "Zbroslawice"
            },
            {
                "value": 74995,
                "label": "Zernica",
                "label_ar": "زرنيكا",
                "label_fr": "Zernica"
            },
            {
                "value": 74996,
                "label": "Biezanow-Prokocim",
                "label_ar": "بيزانو بروكوسيم",
                "label_fr": "Biezanow-Prokocim"
            },
            {
                "value": 74997,
                "label": "Prokocim",
                "label_ar": "بروكوسيم",
                "label_fr": "Prokocim"
            },
            {
                "value": 74998,
                "label": "Suwaki",
                "label_ar": "سواكي",
                "label_fr": "Suwaki"
            },
            {
                "value": 74999,
                "label": "Jabłonowo Pomorskie",
                "label_ar": "Jabłonowo بومورسكي",
                "label_fr": "Jabłonowo Pomorskie"
            },
            {
                "value": 75000,
                "label": "Krzywcza",
                "label_ar": "Krzywcza",
                "label_fr": "Krzywcza"
            },
            {
                "value": 75001,
                "label": "Zabrzeg",
                "label_ar": "زبرزيق",
                "label_fr": "Zabrzeg"
            },
            {
                "value": 75002,
                "label": "Zglobien",
                "label_ar": "زجلوبين",
                "label_fr": "Zglobien"
            },
            {
                "value": 75003,
                "label": "Krzeszow",
                "label_ar": "كرزيسزو",
                "label_fr": "Krzeszow"
            },
            {
                "value": 75004,
                "label": "Mscice",
                "label_ar": "مسكيس",
                "label_fr": "Mscice"
            },
            {
                "value": 75005,
                "label": "Rudna Mala",
                "label_ar": "رودنا مالا",
                "label_fr": "Rudna Mala"
            },
            {
                "value": 75006,
                "label": "Zielonki-Wies",
                "label_ar": "زيلونكي فيز",
                "label_fr": "Zielonki-Wies"
            },
            {
                "value": 75007,
                "label": "Bramki",
                "label_ar": "برامكي",
                "label_fr": "Bramki"
            },
            {
                "value": 75008,
                "label": "Makow Podhalanski",
                "label_ar": "ماكو بودالانسكي",
                "label_fr": "Makow Podhalanski"
            },
            {
                "value": 75009,
                "label": "Czernica",
                "label_ar": "تشيرنيكا",
                "label_fr": "Czernica"
            },
            {
                "value": 75010,
                "label": "Nowa Wies Elcka",
                "label_ar": "نوا ويس إلكا",
                "label_fr": "Nowa Wies Elcka"
            },
            {
                "value": 75011,
                "label": "Gmina Osjaków",
                "label_ar": "غمينا أوسجاكوف",
                "label_fr": "Gmina Osjaków"
            },
            {
                "value": 75012,
                "label": "Mieczewo",
                "label_ar": "ميتشيو",
                "label_fr": "Mieczewo"
            },
            {
                "value": 75013,
                "label": "Kety",
                "label_ar": "كيتي",
                "label_fr": "Kety"
            },
            {
                "value": 75014,
                "label": "Klaj",
                "label_ar": "كلاج",
                "label_fr": "Klaj"
            },
            {
                "value": 75015,
                "label": "Czerniewice",
                "label_ar": "تشيرنيفيتسي",
                "label_fr": "Czerniewice"
            },
            {
                "value": 75016,
                "label": "Raczka",
                "label_ar": "راشكا",
                "label_fr": "Raczka"
            },
            {
                "value": 75017,
                "label": "Serwis",
                "label_ar": "سيرويس",
                "label_fr": "Serwis"
            },
            {
                "value": 75018,
                "label": "Gomunice",
                "label_ar": "جومونيس",
                "label_fr": "Gomunice"
            },
            {
                "value": 75019,
                "label": "Rusiec",
                "label_ar": "روسيك",
                "label_fr": "Rusiec"
            },
            {
                "value": 75020,
                "label": "Marta",
                "label_ar": "مارتا",
                "label_fr": "Marta"
            },
            {
                "value": 75021,
                "label": "Debe Wielkie",
                "label_ar": "ديبي ويلكي",
                "label_fr": "Debe Wielkie"
            },
            {
                "value": 75022,
                "label": "Olszowice",
                "label_ar": "أولزوفيتسي",
                "label_fr": "Olszowice"
            },
            {
                "value": 75023,
                "label": "Kowalkow",
                "label_ar": "كووالكو",
                "label_fr": "Kowalkow"
            },
            {
                "value": 75024,
                "label": "Banino",
                "label_ar": "بانينو",
                "label_fr": "Banino"
            },
            {
                "value": 75025,
                "label": "Skrzyszow",
                "label_ar": "Skrzyszow",
                "label_fr": "Skrzyszow"
            },
            {
                "value": 75026,
                "label": "Lachowice",
                "label_ar": "لاشوفيتسي",
                "label_fr": "Lachowice"
            },
            {
                "value": 75027,
                "label": "Rumianek",
                "label_ar": "رومانيك",
                "label_fr": "Rumianek"
            },
            {
                "value": 75028,
                "label": "Kobylanka",
                "label_ar": "كوبيلانكا",
                "label_fr": "Kobylanka"
            },
            {
                "value": 75029,
                "label": "Radziechowy",
                "label_ar": "رادزيتشوي",
                "label_fr": "Radziechowy"
            },
            {
                "value": 75030,
                "label": "Giby",
                "label_ar": "جيبى",
                "label_fr": "Giby"
            },
            {
                "value": 75031,
                "label": "Bukowno",
                "label_ar": "بوكينو",
                "label_fr": "Bukowno"
            },
            {
                "value": 75032,
                "label": "Modrze",
                "label_ar": "مودرز",
                "label_fr": "Modrze"
            },
            {
                "value": 75033,
                "label": "Mosina",
                "label_ar": "موسينا",
                "label_fr": "Mosina"
            },
            {
                "value": 75034,
                "label": "Miastko",
                "label_ar": "مياستكو",
                "label_fr": "Miastko"
            },
            {
                "value": 75035,
                "label": "Keblowo",
                "label_ar": "كيبلوو",
                "label_fr": "Keblowo"
            },
            {
                "value": 75036,
                "label": "Wielowies",
                "label_ar": "Wielowies",
                "label_fr": "Wielowies"
            },
            {
                "value": 75037,
                "label": "Opatowek",
                "label_ar": "أوباتوفيك",
                "label_fr": "Opatowek"
            },
            {
                "value": 75038,
                "label": "Strzebin",
                "label_ar": "سترزبين",
                "label_fr": "Strzebin"
            },
            {
                "value": 75039,
                "label": "Stary Zamosc",
                "label_ar": "ستاري زاموسك",
                "label_fr": "Stary Zamosc"
            },
            {
                "value": 75040,
                "label": "Labunie",
                "label_ar": "لبوني",
                "label_fr": "Labunie"
            },
            {
                "value": 75041,
                "label": "Skomlin",
                "label_ar": "سكوملين",
                "label_fr": "Skomlin"
            },
            {
                "value": 75042,
                "label": "Warzno",
                "label_ar": "وارزنو",
                "label_fr": "Warzno"
            },
            {
                "value": 75043,
                "label": "Klimontow",
                "label_ar": "كليمونتوف",
                "label_fr": "Klimontow"
            },
            {
                "value": 75044,
                "label": "Lochowo",
                "label_ar": "Lochowo",
                "label_fr": "Lochowo"
            },
            {
                "value": 75045,
                "label": "Debrzno Wies",
                "label_ar": "ديبرزنو ويس",
                "label_fr": "Debrzno Wies"
            },
            {
                "value": 75046,
                "label": "Rudziniec",
                "label_ar": "رودزينيك",
                "label_fr": "Rudziniec"
            },
            {
                "value": 75047,
                "label": "Lany",
                "label_ar": "حبل",
                "label_fr": "Lany"
            },
            {
                "value": 75048,
                "label": "Ujazd",
                "label_ar": "اوجازد",
                "label_fr": "Ujazd"
            },
            {
                "value": 75049,
                "label": "Trzyciaz",
                "label_ar": "ترزيسياز",
                "label_fr": "Trzyciaz"
            },
            {
                "value": 75050,
                "label": "Pilica",
                "label_ar": "بيليكا",
                "label_fr": "Pilica"
            },
            {
                "value": 75051,
                "label": "Bydlin",
                "label_ar": "بيدلين",
                "label_fr": "Bydlin"
            },
            {
                "value": 75052,
                "label": "Nebrowo Wielkie",
                "label_ar": "نيبروو ويلكي",
                "label_fr": "Nebrowo Wielkie"
            },
            {
                "value": 75053,
                "label": "Naprawa",
                "label_ar": "نبراوة",
                "label_fr": "Naprawa"
            },
            {
                "value": 75054,
                "label": "Szydlowiec",
                "label_ar": "Szydlowiec",
                "label_fr": "Szydlowiec"
            },
            {
                "value": 75055,
                "label": "Bielcza",
                "label_ar": "بيلتشا",
                "label_fr": "Bielcza"
            },
            {
                "value": 75056,
                "label": "Arkadia",
                "label_ar": "أركاديا",
                "label_fr": "Arkadia"
            },
            {
                "value": 75057,
                "label": "Lubsza",
                "label_ar": "Lubsza",
                "label_fr": "Lubsza"
            },
            {
                "value": 75058,
                "label": "Babienica",
                "label_ar": "بابينيكا",
                "label_fr": "Babienica"
            },
            {
                "value": 75059,
                "label": "Miedzybrodzie Zywieckie",
                "label_ar": "Miedzybrodzie Zywieckie",
                "label_fr": "Miedzybrodzie Zywieckie"
            },
            {
                "value": 75060,
                "label": "Ryglice",
                "label_ar": "ريجليس",
                "label_fr": "Ryglice"
            },
            {
                "value": 75061,
                "label": "Sieniawa",
                "label_ar": "سينياوا",
                "label_fr": "Sieniawa"
            },
            {
                "value": 75062,
                "label": "Przystajn",
                "label_ar": "برزيستاين",
                "label_fr": "Przystajn"
            },
            {
                "value": 75063,
                "label": "Przemyslaw",
                "label_ar": "برزيميسلاف",
                "label_fr": "Przemyslaw"
            },
            {
                "value": 75064,
                "label": "Bukowiec Opoczynski",
                "label_ar": "Bukowiec Opoczynski",
                "label_fr": "Bukowiec Opoczynski"
            },
            {
                "value": 75065,
                "label": "Maszewo",
                "label_ar": "مازيو",
                "label_fr": "Maszewo"
            },
            {
                "value": 75066,
                "label": "Orzel",
                "label_ar": "أورزيل",
                "label_fr": "Orzel"
            },
            {
                "value": 75067,
                "label": "Dabrowa Bialostocka",
                "label_ar": "دابروا بيالوستوكا",
                "label_fr": "Dabrowa Bialostocka"
            },
            {
                "value": 75068,
                "label": "Jozefatow",
                "label_ar": "جوزيفاتوف",
                "label_fr": "Jozefatow"
            },
            {
                "value": 75069,
                "label": "Bolimów",
                "label_ar": "بوليمو",
                "label_fr": "Bolimów"
            },
            {
                "value": 75070,
                "label": "Rzasnia",
                "label_ar": "رزاسنيا",
                "label_fr": "Rzasnia"
            },
            {
                "value": 75071,
                "label": "Rudka",
                "label_ar": "رودكا",
                "label_fr": "Rudka"
            },
            {
                "value": 75072,
                "label": "Buczkowice",
                "label_ar": "بوكزكوفيتسه",
                "label_fr": "Buczkowice"
            },
            {
                "value": 75073,
                "label": "Pomorskie",
                "label_ar": "بومورسكي",
                "label_fr": "Pomorskie"
            },
            {
                "value": 75074,
                "label": "Gieraltowice",
                "label_ar": "جيرالتوفيتسي",
                "label_fr": "Gieraltowice"
            },
            {
                "value": 75075,
                "label": "Chocznia",
                "label_ar": "شوزنيا",
                "label_fr": "Chocznia"
            },
            {
                "value": 75076,
                "label": "Obsza",
                "label_ar": "Obsza",
                "label_fr": "Obsza"
            },
            {
                "value": 75077,
                "label": "Sobienie Jeziory",
                "label_ar": "سوبييني جيزيوري",
                "label_fr": "Sobienie Jeziory"
            },
            {
                "value": 75078,
                "label": "Dobiesz",
                "label_ar": "دوبيز",
                "label_fr": "Dobiesz"
            },
            {
                "value": 75079,
                "label": "Czersk",
                "label_ar": "تشيرسك",
                "label_fr": "Czersk"
            },
            {
                "value": 75080,
                "label": "Gora Kalwaria",
                "label_ar": "غورا كالواريا",
                "label_fr": "Gora Kalwaria"
            },
            {
                "value": 75081,
                "label": "Bojadla",
                "label_ar": "بوجدلا",
                "label_fr": "Bojadla"
            },
            {
                "value": 75082,
                "label": "Grzmiaca",
                "label_ar": "جرزمياكا",
                "label_fr": "Grzmiaca"
            },
            {
                "value": 75083,
                "label": "Ilza",
                "label_ar": "إيلزا",
                "label_fr": "Ilza"
            },
            {
                "value": 75084,
                "label": "Siwek",
                "label_ar": "سيويك",
                "label_fr": "Siwek"
            },
            {
                "value": 75085,
                "label": "Strzeleczki",
                "label_ar": "Strzeleczki",
                "label_fr": "Strzeleczki"
            },
            {
                "value": 75086,
                "label": "Gorazdze",
                "label_ar": "غورازدزه",
                "label_fr": "Gorazdze"
            },
            {
                "value": 75087,
                "label": "Osieczna",
                "label_ar": "أوسيتشنا",
                "label_fr": "Osieczna"
            },
            {
                "value": 75088,
                "label": "Harasiuki",
                "label_ar": "Harasiuki",
                "label_fr": "Harasiuki"
            },
            {
                "value": 75089,
                "label": "Liskow",
                "label_ar": "ليسكو",
                "label_fr": "Liskow"
            },
            {
                "value": 75090,
                "label": "Strzebielino",
                "label_ar": "سترزيبيلينو",
                "label_fr": "Strzebielino"
            },
            {
                "value": 75091,
                "label": "Gryfino",
                "label_ar": "جريفينو",
                "label_fr": "Gryfino"
            },
            {
                "value": 75092,
                "label": "Skorcz",
                "label_ar": "سكوركز",
                "label_fr": "Skorcz"
            },
            {
                "value": 75093,
                "label": "Grebocin",
                "label_ar": "غريبوسين",
                "label_fr": "Grebocin"
            },
            {
                "value": 75094,
                "label": "Chorzele",
                "label_ar": "كورزيل",
                "label_fr": "Chorzele"
            },
            {
                "value": 75095,
                "label": "Kobiernice",
                "label_ar": "كوبييرنيتسي",
                "label_fr": "Kobiernice"
            },
            {
                "value": 75096,
                "label": "Korzenna",
                "label_ar": "كورزينا",
                "label_fr": "Korzenna"
            },
            {
                "value": 75097,
                "label": "Koronowo",
                "label_ar": "كورونوو",
                "label_fr": "Koronowo"
            },
            {
                "value": 75098,
                "label": "Wielopole Skrzynskie",
                "label_ar": "Wielopole Skrzynskie",
                "label_fr": "Wielopole Skrzynskie"
            },
            {
                "value": 75099,
                "label": "Przygodzice",
                "label_ar": "برزيغودزيسي",
                "label_fr": "Przygodzice"
            },
            {
                "value": 75100,
                "label": "Sulmierzyce",
                "label_ar": "سولميرزيز",
                "label_fr": "Sulmierzyce"
            },
            {
                "value": 75101,
                "label": "Opalenica",
                "label_ar": "أوبالينكا",
                "label_fr": "Opalenica"
            },
            {
                "value": 75102,
                "label": "Maslice",
                "label_ar": "ماسليس",
                "label_fr": "Maslice"
            },
            {
                "value": 75103,
                "label": "Bobrza",
                "label_ar": "بوبريزا",
                "label_fr": "Bobrza"
            },
            {
                "value": 75104,
                "label": "Dzielna",
                "label_ar": "دزيلنا",
                "label_fr": "Dzielna"
            },
            {
                "value": 75105,
                "label": "Zarszyn",
                "label_ar": "زارسين",
                "label_fr": "Zarszyn"
            },
            {
                "value": 75106,
                "label": "Huta Dabrowa",
                "label_ar": "حوطة دبروا",
                "label_fr": "Huta Dabrowa"
            },
            {
                "value": 75107,
                "label": "Cegłów",
                "label_ar": "Cegów",
                "label_fr": "Cegłów"
            },
            {
                "value": 75108,
                "label": "Borowa",
                "label_ar": "بورووا",
                "label_fr": "Borowa"
            },
            {
                "value": 75109,
                "label": "Biała Rawska",
                "label_ar": "بياوا روسكا",
                "label_fr": "Biała Rawska"
            },
            {
                "value": 75110,
                "label": "Kolonia Zawada",
                "label_ar": "Kolonia Zawada",
                "label_fr": "Kolonia Zawada"
            },
            {
                "value": 75111,
                "label": "Sochocin",
                "label_ar": "سوتشوسين",
                "label_fr": "Sochocin"
            },
            {
                "value": 75112,
                "label": "Staniszow",
                "label_ar": "ستانيسزو",
                "label_fr": "Staniszow"
            },
            {
                "value": 75113,
                "label": "Bialobrzegi",
                "label_ar": "بيالوبرزيغي",
                "label_fr": "Bialobrzegi"
            },
            {
                "value": 75114,
                "label": "Byslaw",
                "label_ar": "بيسلاو",
                "label_fr": "Byslaw"
            },
            {
                "value": 75115,
                "label": "Pogodki",
                "label_ar": "بوجودكي",
                "label_fr": "Pogodki"
            },
            {
                "value": 75116,
                "label": "Brynica",
                "label_ar": "برينيكا",
                "label_fr": "Brynica"
            },
            {
                "value": 75117,
                "label": "Daleszyce",
                "label_ar": "ديلزيسي",
                "label_fr": "Daleszyce"
            },
            {
                "value": 75118,
                "label": "Gloskow",
                "label_ar": "جلوسكو",
                "label_fr": "Gloskow"
            },
            {
                "value": 75119,
                "label": "Strachówka",
                "label_ar": "Strachówka",
                "label_fr": "Strachówka"
            },
            {
                "value": 75120,
                "label": "Wola Radlowska",
                "label_ar": "وولا رادلوسكا",
                "label_fr": "Wola Radlowska"
            },
            {
                "value": 75121,
                "label": "Golotczyzna",
                "label_ar": "جولوتكزنا",
                "label_fr": "Golotczyzna"
            },
            {
                "value": 75122,
                "label": "Osiek Jasielski",
                "label_ar": "Osiek Jasielski",
                "label_fr": "Osiek Jasielski"
            },
            {
                "value": 75123,
                "label": "Baniocha",
                "label_ar": "بانيوتشا",
                "label_fr": "Baniocha"
            },
            {
                "value": 75124,
                "label": "Czarny Las",
                "label_ar": "كزارني لاس",
                "label_fr": "Czarny Las"
            },
            {
                "value": 75125,
                "label": "Radziejowice",
                "label_ar": "رادزيوفيتسي",
                "label_fr": "Radziejowice"
            },
            {
                "value": 75126,
                "label": "Wola Rasztowska",
                "label_ar": "Wola Rasztowska",
                "label_fr": "Wola Rasztowska"
            },
            {
                "value": 75127,
                "label": "Rebowo",
                "label_ar": "ريبوو",
                "label_fr": "Rebowo"
            },
            {
                "value": 75128,
                "label": "Zglobice",
                "label_ar": "Zglobice",
                "label_fr": "Zglobice"
            },
            {
                "value": 75129,
                "label": "Borki",
                "label_ar": "بوركي",
                "label_fr": "Borki"
            },
            {
                "value": 75130,
                "label": "Kielpin",
                "label_ar": "كيلبين",
                "label_fr": "Kielpin"
            },
            {
                "value": 75131,
                "label": "Nowy Staw",
                "label_ar": "نوي ستاو",
                "label_fr": "Nowy Staw"
            },
            {
                "value": 75132,
                "label": "Bielawy",
                "label_ar": "بيلاوي",
                "label_fr": "Bielawy"
            },
            {
                "value": 75133,
                "label": "Janow Lubelski",
                "label_ar": "جانو لوبليسكي",
                "label_fr": "Janow Lubelski"
            },
            {
                "value": 75134,
                "label": "Leszno",
                "label_ar": "ليسزنو",
                "label_fr": "Leszno"
            },
            {
                "value": 75135,
                "label": "Koteze",
                "label_ar": "كوتيز",
                "label_fr": "Koteze"
            },
            {
                "value": 75136,
                "label": "Kurdwanow",
                "label_ar": "كوردوانوف",
                "label_fr": "Kurdwanow"
            },
            {
                "value": 75137,
                "label": "Nowa Huta",
                "label_ar": "نوا هوتا",
                "label_fr": "Nowa Huta"
            },
            {
                "value": 75138,
                "label": "Czyzyny",
                "label_ar": "Czyzyny",
                "label_fr": "Czyzyny"
            },
            {
                "value": 75139,
                "label": "Podgorze",
                "label_ar": "بودجورزي",
                "label_fr": "Podgorze"
            },
            {
                "value": 75140,
                "label": "Goszczyn",
                "label_ar": "Goszczyn",
                "label_fr": "Goszczyn"
            },
            {
                "value": 75141,
                "label": "Wisznice",
                "label_ar": "Wisznice",
                "label_fr": "Wisznice"
            },
            {
                "value": 75142,
                "label": "Koszyce",
                "label_ar": "كوسيتش",
                "label_fr": "Koszyce"
            },
            {
                "value": 75143,
                "label": "Koszyce",
                "label_ar": "كوسيتش",
                "label_fr": "Koszyce"
            },
            {
                "value": 75144,
                "label": "Wieliszew",
                "label_ar": "Wieliszew",
                "label_fr": "Wieliszew"
            },
            {
                "value": 75145,
                "label": "Miedzna",
                "label_ar": "ميدزنا",
                "label_fr": "Miedzna"
            },
            {
                "value": 75146,
                "label": "Czarne",
                "label_ar": "كزارني",
                "label_fr": "Czarne"
            },
            {
                "value": 75147,
                "label": "Dopiewiec",
                "label_ar": "Dopiewiec",
                "label_fr": "Dopiewiec"
            },
            {
                "value": 75148,
                "label": "Lusowko",
                "label_ar": "Lusowko",
                "label_fr": "Lusowko"
            },
            {
                "value": 75149,
                "label": "Sady",
                "label_ar": "سادي",
                "label_fr": "Sady"
            },
            {
                "value": 75150,
                "label": "Paledzie",
                "label_ar": "Paledzie",
                "label_fr": "Paledzie"
            },
            {
                "value": 75151,
                "label": "Biskupice",
                "label_ar": "بيسكوبيتسي",
                "label_fr": "Biskupice"
            },
            {
                "value": 75152,
                "label": "Przemet",
                "label_ar": "برزيميت",
                "label_fr": "Przemet"
            },
            {
                "value": 75153,
                "label": "Rogalinek",
                "label_ar": "روجالينك",
                "label_fr": "Rogalinek"
            },
            {
                "value": 75154,
                "label": "Gostynin",
                "label_ar": "جوستينين",
                "label_fr": "Gostynin"
            },
            {
                "value": 75155,
                "label": "Skorzec",
                "label_ar": "سكورزيك",
                "label_fr": "Skorzec"
            },
            {
                "value": 75156,
                "label": "Kusnierz",
                "label_ar": "Kusnierz",
                "label_fr": "Kusnierz"
            },
            {
                "value": 75157,
                "label": "Gąbin",
                "label_ar": "جوبين",
                "label_fr": "Gąbin"
            },
            {
                "value": 75158,
                "label": "Kurzetnik",
                "label_ar": "كورزيتنيك",
                "label_fr": "Kurzetnik"
            },
            {
                "value": 75159,
                "label": "Zabno",
                "label_ar": "زابنو",
                "label_fr": "Zabno"
            },
            {
                "value": 75160,
                "label": "Dobroszyce",
                "label_ar": "Dobroszyce",
                "label_fr": "Dobroszyce"
            },
            {
                "value": 75161,
                "label": "Olejnica",
                "label_ar": "Olejnica",
                "label_fr": "Olejnica"
            },
            {
                "value": 75162,
                "label": "Czastary",
                "label_ar": "القيصر",
                "label_fr": "Czastary"
            },
            {
                "value": 75163,
                "label": "Bierutow",
                "label_ar": "بيروتو",
                "label_fr": "Bierutow"
            },
            {
                "value": 75164,
                "label": "Zawada",
                "label_ar": "زوادة",
                "label_fr": "Zawada"
            },
            {
                "value": 75165,
                "label": "Wiazow",
                "label_ar": "Wiazow",
                "label_fr": "Wiazow"
            },
            {
                "value": 75166,
                "label": "Kurek",
                "label_ar": "كورك",
                "label_fr": "Kurek"
            },
            {
                "value": 75167,
                "label": "Bojszowy",
                "label_ar": "بوجسوي",
                "label_fr": "Bojszowy"
            },
            {
                "value": 75168,
                "label": "Subkowy",
                "label_ar": "Subkowy",
                "label_fr": "Subkowy"
            },
            {
                "value": 75169,
                "label": "Myszyniec",
                "label_ar": "ميسزينيك",
                "label_fr": "Myszyniec"
            },
            {
                "value": 75170,
                "label": "Juszkowo",
                "label_ar": "Juszkowo",
                "label_fr": "Juszkowo"
            },
            {
                "value": 75171,
                "label": "Olszany",
                "label_ar": "أولزاني",
                "label_fr": "Olszany"
            },
            {
                "value": 75172,
                "label": "Puszczew",
                "label_ar": "Puszczew",
                "label_fr": "Puszczew"
            },
            {
                "value": 75173,
                "label": "Zorawia",
                "label_ar": "الزاوية",
                "label_fr": "Zorawia"
            },
            {
                "value": 75174,
                "label": "Katarzyna",
                "label_ar": "كاتارزينا",
                "label_fr": "Katarzyna"
            },
            {
                "value": 75175,
                "label": "Braszewice",
                "label_ar": "Braszewice",
                "label_fr": "Braszewice"
            },
            {
                "value": 75176,
                "label": "Tomaszow Lubelski",
                "label_ar": "توماسو لوبليسكي",
                "label_fr": "Tomaszow Lubelski"
            },
            {
                "value": 75177,
                "label": "Wieckowice",
                "label_ar": "Wieckowice",
                "label_fr": "Wieckowice"
            },
            {
                "value": 75178,
                "label": "Staw",
                "label_ar": "ستاو",
                "label_fr": "Staw"
            },
            {
                "value": 75179,
                "label": "Pisarzowice",
                "label_ar": "بيسارزوفيتسي",
                "label_fr": "Pisarzowice"
            },
            {
                "value": 75180,
                "label": "Trojanow",
                "label_ar": "تروجانوف",
                "label_fr": "Cheval de Troie"
            },
            {
                "value": 75181,
                "label": "Baborow",
                "label_ar": "بابورو",
                "label_fr": "Baborow"
            },
            {
                "value": 75182,
                "label": "Lobzenica",
                "label_ar": "لوبزينيكا",
                "label_fr": "Lobzenica"
            },
            {
                "value": 75183,
                "label": "Grabiec",
                "label_ar": "جرابيك",
                "label_fr": "Grabiec"
            },
            {
                "value": 75184,
                "label": "Jasienica Dolna",
                "label_ar": "جاسينيكا دولنا",
                "label_fr": "Jasienica Dolna"
            },
            {
                "value": 75185,
                "label": "Stary Broniszew",
                "label_ar": "ستاري برونيسزو",
                "label_fr": "Stary Broniszew"
            },
            {
                "value": 75186,
                "label": "Przyrow",
                "label_ar": "برزيرو",
                "label_fr": "Przyrow"
            },
            {
                "value": 75187,
                "label": "Charzykowy",
                "label_ar": "تشارزيكوي",
                "label_fr": "Charzykowy"
            },
            {
                "value": 75188,
                "label": "Pastuchow",
                "label_ar": "Pastuchow",
                "label_fr": "Pastuchow"
            },
            {
                "value": 75189,
                "label": "Mackowice",
                "label_ar": "ماكوفيتسي",
                "label_fr": "Mackowice"
            },
            {
                "value": 75190,
                "label": "Krynica-Zdroj",
                "label_ar": "كرينيكا زدروي",
                "label_fr": "Krynica-Zdroj"
            },
            {
                "value": 75191,
                "label": "Tylicz",
                "label_ar": "تيليش",
                "label_fr": "Tylicz"
            },
            {
                "value": 75192,
                "label": "Morawica",
                "label_ar": "مورافيكا",
                "label_fr": "Morawica"
            },
            {
                "value": 75193,
                "label": "Nowy Swietow",
                "label_ar": "Nowy Swietow",
                "label_fr": "Nowy Swietow"
            },
            {
                "value": 75194,
                "label": "Chrzowice",
                "label_ar": "خرزوفيتسي",
                "label_fr": "Chrzowice"
            },
            {
                "value": 75195,
                "label": "Lelow",
                "label_ar": "أدنى",
                "label_fr": "Lelow"
            },
            {
                "value": 75196,
                "label": "Tuchow",
                "label_ar": "توتشو",
                "label_fr": "Tuchow"
            },
            {
                "value": 75197,
                "label": "Lipnica Murowana",
                "label_ar": "ليبنيكا مورووانا",
                "label_fr": "Lipnica Murowana"
            },
            {
                "value": 75198,
                "label": "Wysoka",
                "label_ar": "ويسوكا",
                "label_fr": "Wysoka"
            },
            {
                "value": 75199,
                "label": "Pawlowice",
                "label_ar": "باولويتشي",
                "label_fr": "Pawlowice"
            },
            {
                "value": 75200,
                "label": "Czarna Białostocka",
                "label_ar": "كزارنا بياوستوكا",
                "label_fr": "Czarna Białostocka"
            },
            {
                "value": 75201,
                "label": "Radziejów",
                "label_ar": "Radziejów",
                "label_fr": "Radziejów"
            },
            {
                "value": 75202,
                "label": "Scinawa Mala",
                "label_ar": "سيناوا مالا",
                "label_fr": "Scinawa Mala"
            },
            {
                "value": 75203,
                "label": "Jastrzebie",
                "label_ar": "جاسترزيبي",
                "label_fr": "Jastrzebie"
            },
            {
                "value": 75204,
                "label": "Czarnozyly",
                "label_ar": "Czarnozyly",
                "label_fr": "Czarnozyly"
            },
            {
                "value": 75205,
                "label": "Baboszewo",
                "label_ar": "بابوسزيو",
                "label_fr": "Baboszewo"
            },
            {
                "value": 75206,
                "label": "Drohiczyn",
                "label_ar": "دروهيتشين",
                "label_fr": "Drohiczyn"
            },
            {
                "value": 75207,
                "label": "Iwonicz-Zdroj",
                "label_ar": "إيونيكز زدروي",
                "label_fr": "Iwonicz-Zdroj"
            },
            {
                "value": 75208,
                "label": "Kosina",
                "label_ar": "كوسينا",
                "label_fr": "Kosina"
            },
            {
                "value": 75209,
                "label": "Brzyska Wola",
                "label_ar": "برزيسكا وولا",
                "label_fr": "Brzyska Wola"
            },
            {
                "value": 75210,
                "label": "Bircza",
                "label_ar": "بيركزا",
                "label_fr": "Bircza"
            },
            {
                "value": 75211,
                "label": "Podleze",
                "label_ar": "بودليز",
                "label_fr": "Podleze"
            },
            {
                "value": 75212,
                "label": "Zator",
                "label_ar": "زاتور",
                "label_fr": "Zator"
            },
            {
                "value": 75213,
                "label": "Tomaszowice",
                "label_ar": "توماشوفيتسي",
                "label_fr": "Tomaszowice"
            },
            {
                "value": 75214,
                "label": "Wilczeta",
                "label_ar": "ويلكزيتا",
                "label_fr": "Wilczeta"
            },
            {
                "value": 75215,
                "label": "Swiecie nad Osa",
                "label_ar": "Swiecie nad Osa",
                "label_fr": "Swiecie nad Osa"
            },
            {
                "value": 75216,
                "label": "Wapielsk",
                "label_ar": "وابيلسك",
                "label_fr": "Wapielsk"
            },
            {
                "value": 75217,
                "label": "Bledzew",
                "label_ar": "بليدزو",
                "label_fr": "Bledzew"
            },
            {
                "value": 75218,
                "label": "Trzemeszno Lubuskie",
                "label_ar": "Trzemeszno Lubuskie",
                "label_fr": "Trzemeszno Lubuskie"
            },
            {
                "value": 75219,
                "label": "Rymanow",
                "label_ar": "Rymanow",
                "label_fr": "Rymanow"
            },
            {
                "value": 75220,
                "label": "Odrzykon",
                "label_ar": "أودرزكون",
                "label_fr": "Odrzykon"
            },
            {
                "value": 75221,
                "label": "Roczyny",
                "label_ar": "روتشيني",
                "label_fr": "Roczyny"
            },
            {
                "value": 75222,
                "label": "Kolno",
                "label_ar": "كولنو",
                "label_fr": "Kolno"
            },
            {
                "value": 75223,
                "label": "Grabow nad Prosna",
                "label_ar": "غرابو ناد بروسنا",
                "label_fr": "Grabow nad Prosna"
            },
            {
                "value": 75224,
                "label": "Zaleszany",
                "label_ar": "زاليسزاني",
                "label_fr": "Zaleszany"
            },
            {
                "value": 75225,
                "label": "Turbia",
                "label_ar": "توربيا",
                "label_fr": "Turbia"
            },
            {
                "value": 75226,
                "label": "Milejow",
                "label_ar": "ميلجو",
                "label_fr": "Milejow"
            },
            {
                "value": 75227,
                "label": "Gmina Ludwin",
                "label_ar": "جمينا لودوين",
                "label_fr": "Gmina Ludwin"
            },
            {
                "value": 75228,
                "label": "Kaczor",
                "label_ar": "كاسور",
                "label_fr": "Kaczor"
            },
            {
                "value": 75229,
                "label": "Kochcice",
                "label_ar": "كوتشيتسي",
                "label_fr": "Kochcice"
            },
            {
                "value": 75230,
                "label": "Brzeziny",
                "label_ar": "بريزيني",
                "label_fr": "Brzeziny"
            },
            {
                "value": 75231,
                "label": "Frank",
                "label_ar": "صريح",
                "label_fr": "Franc"
            },
            {
                "value": 75232,
                "label": "Imielin",
                "label_ar": "إيميلين",
                "label_fr": "Imielin"
            },
            {
                "value": 75233,
                "label": "Cmolas",
                "label_ar": "كامولاس",
                "label_fr": "Cmolas"
            },
            {
                "value": 75234,
                "label": "Wolborz",
                "label_ar": "ولبورز",
                "label_fr": "Wolborz"
            },
            {
                "value": 75235,
                "label": "Zawidow",
                "label_ar": "زويدو",
                "label_fr": "Zawidow"
            },
            {
                "value": 75236,
                "label": "Gieraltowice",
                "label_ar": "جيرالتوفيتسي",
                "label_fr": "Gieraltowice"
            },
            {
                "value": 75237,
                "label": "Stronie Slaskie",
                "label_ar": "ستروني سلاسكي",
                "label_fr": "Stronie Slaskie"
            },
            {
                "value": 75238,
                "label": "Lewin Brzeski",
                "label_ar": "لوين برزيسكي",
                "label_fr": "Lewin Brzeski"
            },
            {
                "value": 75239,
                "label": "Podgorne",
                "label_ar": "بودجورن",
                "label_fr": "Podgorne"
            },
            {
                "value": 75240,
                "label": "Gniew",
                "label_ar": "جنيو",
                "label_fr": "Gniew"
            },
            {
                "value": 75241,
                "label": "Laziska Gorne",
                "label_ar": "لازيسكا جورن",
                "label_fr": "Laziska Gorne"
            },
            {
                "value": 75242,
                "label": "Nowe Chechlo",
                "label_ar": "Nowe Chechlo",
                "label_fr": "Nowe Chechlo"
            },
            {
                "value": 75243,
                "label": "Soczewka",
                "label_ar": "Soczewka",
                "label_fr": "Soczewka"
            },
            {
                "value": 75244,
                "label": "Tarnow Opolski",
                "label_ar": "تارنوف أوبولسكي",
                "label_fr": "Tarnow Opolski"
            },
            {
                "value": 75245,
                "label": "Gmina Konarzyny",
                "label_ar": "جمينا كونرزيني",
                "label_fr": "Gmina Konarzyny"
            },
            {
                "value": 75246,
                "label": "Swierk",
                "label_ar": "سويرك",
                "label_fr": "Swierk"
            },
            {
                "value": 75247,
                "label": "Reczno",
                "label_ar": "ريكزنو",
                "label_fr": "Reczno"
            },
            {
                "value": 75248,
                "label": "Kamien Krajenski",
                "label_ar": "كامين كراجينسكي",
                "label_fr": "Kamien Krajenski"
            },
            {
                "value": 75249,
                "label": "Zgorsko",
                "label_ar": "زجورسكو",
                "label_fr": "Zgorsko"
            },
            {
                "value": 75250,
                "label": "Polskie",
                "label_ar": "بولسكي",
                "label_fr": "Polskie"
            },
            {
                "value": 75251,
                "label": "Boguslaw",
                "label_ar": "بوغسلاف",
                "label_fr": "Boguslaw"
            },
            {
                "value": 75252,
                "label": "Ustrzyki Dolne",
                "label_ar": "أوسترزيكي دولن",
                "label_fr": "Ustrzyki Dolne"
            },
            {
                "value": 75253,
                "label": "Chojna",
                "label_ar": "شوجنا",
                "label_fr": "Chojna"
            },
            {
                "value": 75254,
                "label": "Towarzystwo",
                "label_ar": "تورزيستو",
                "label_fr": "Towarzystwo"
            },
            {
                "value": 75255,
                "label": "Borowe",
                "label_ar": "بورو",
                "label_fr": "Borowe"
            },
            {
                "value": 75256,
                "label": "Sobotka",
                "label_ar": "سوبوتكا",
                "label_fr": "Sobotka"
            },
            {
                "value": 75257,
                "label": "Lajsk",
                "label_ar": "لاجسك",
                "label_fr": "Lajsk"
            },
            {
                "value": 75258,
                "label": "Pokrzywnica",
                "label_ar": "Pokrzywnica",
                "label_fr": "Pokrzywnica"
            },
            {
                "value": 75259,
                "label": "Prosna",
                "label_ar": "بروسنا",
                "label_fr": "Prosna"
            },
            {
                "value": 75260,
                "label": "Supienie",
                "label_ar": "Supienie",
                "label_fr": "Supienie"
            },
            {
                "value": 75261,
                "label": "Pilchowice",
                "label_ar": "بيلشوفيتسي",
                "label_fr": "Pilchowice"
            },
            {
                "value": 75262,
                "label": "Unieszewo",
                "label_ar": "Unieszewo",
                "label_fr": "Unieszewo"
            },
            {
                "value": 75263,
                "label": "Mszana",
                "label_ar": "مسزانا",
                "label_fr": "Mszana"
            },
            {
                "value": 75264,
                "label": "Bory",
                "label_ar": "بوري",
                "label_fr": "Bory"
            },
            {
                "value": 75265,
                "label": "Gaszowice",
                "label_ar": "جاسوفيتسي",
                "label_fr": "Gaszowice"
            },
            {
                "value": 75266,
                "label": "Syrynia",
                "label_ar": "سيرينيا",
                "label_fr": "Syrynia"
            },
            {
                "value": 75267,
                "label": "Bogunice",
                "label_ar": "بوغونيس",
                "label_fr": "Bogunice"
            },
            {
                "value": 75268,
                "label": "Żelechów",
                "label_ar": "Żelechów",
                "label_fr": "Żelechów"
            },
            {
                "value": 75269,
                "label": "Siewierz",
                "label_ar": "سيويرز",
                "label_fr": "Siewierz"
            },
            {
                "value": 75270,
                "label": "Skierdy",
                "label_ar": "سكييردي",
                "label_fr": "Skierdy"
            },
            {
                "value": 75271,
                "label": "Modlniczka",
                "label_ar": "Modlniczka",
                "label_fr": "Modlniczka"
            },
            {
                "value": 75272,
                "label": "Zgorzala",
                "label_ar": "زغورزالا",
                "label_fr": "Zgorzala"
            },
            {
                "value": 75273,
                "label": "Burzenin",
                "label_ar": "بورزينين",
                "label_fr": "Burzenin"
            },
            {
                "value": 75274,
                "label": "Wasniow",
                "label_ar": "واسنيو",
                "label_fr": "Wasniow"
            },
            {
                "value": 75275,
                "label": "Radoszyce",
                "label_ar": "رادوزيتشي",
                "label_fr": "Radoszyce"
            },
            {
                "value": 75276,
                "label": "Nowy Korczyn",
                "label_ar": "Nowy Korczyn",
                "label_fr": "Nowy Korczyn"
            },
            {
                "value": 75277,
                "label": "Pacanow",
                "label_ar": "باكانوف",
                "label_fr": "Pacanow"
            },
            {
                "value": 75278,
                "label": "Grabki Duze",
                "label_ar": "جرابكي دوز",
                "label_fr": "Grabki Duze"
            },
            {
                "value": 75279,
                "label": "Gmina Szydłów",
                "label_ar": "غمينا Szydłów",
                "label_fr": "Gmina Szydłów"
            },
            {
                "value": 75280,
                "label": "Strawczyn",
                "label_ar": "ستراوتشين",
                "label_fr": "Strawczyn"
            },
            {
                "value": 75281,
                "label": "Kedzierzyn",
                "label_ar": "كيدزيرزين",
                "label_fr": "Kedzierzyn"
            },
            {
                "value": 75282,
                "label": "Bolkow",
                "label_ar": "بولكو",
                "label_fr": "Bolkow"
            },
            {
                "value": 75283,
                "label": "Karpiska",
                "label_ar": "كاربيسكا",
                "label_fr": "Karpiska"
            },
            {
                "value": 75284,
                "label": "Wieruszow",
                "label_ar": "فيروزوف",
                "label_fr": "Wieruszow"
            },
            {
                "value": 75285,
                "label": "Laziska",
                "label_ar": "لازيسكا",
                "label_fr": "Laziska"
            },
            {
                "value": 75286,
                "label": "Tecza",
                "label_ar": "تيكزا",
                "label_fr": "Tecza"
            },
            {
                "value": 75287,
                "label": "Biskupice Oloboczne",
                "label_ar": "Biskupice Oloboczne",
                "label_fr": "Biskupice Oloboczne"
            },
            {
                "value": 75288,
                "label": "Biskupin",
                "label_ar": "بيسكوبين",
                "label_fr": "Biskupin"
            },
            {
                "value": 75289,
                "label": "Hornowek",
                "label_ar": "هورنويك",
                "label_fr": "Hornowek"
            },
            {
                "value": 75290,
                "label": "Bohdan",
                "label_ar": "بوهدان",
                "label_fr": "Bohdan"
            },
            {
                "value": 75291,
                "label": "Debowiec",
                "label_ar": "ديبويك",
                "label_fr": "Debowiec"
            },
            {
                "value": 75292,
                "label": "Medyka",
                "label_ar": "ميديكا",
                "label_fr": "Medyka"
            },
            {
                "value": 75293,
                "label": "Pcim",
                "label_ar": "بسيم",
                "label_fr": "Pcim"
            },
            {
                "value": 75294,
                "label": "Lubien",
                "label_ar": "لوبين",
                "label_fr": "Lubien"
            },
            {
                "value": 75295,
                "label": "Lomianki Dolne",
                "label_ar": "لوميانكي دولن",
                "label_fr": "Lomianki Dolne"
            },
            {
                "value": 75296,
                "label": "Sycewice",
                "label_ar": "Sycewice",
                "label_fr": "Sycewice"
            },
            {
                "value": 75297,
                "label": "Bielawa",
                "label_ar": "بييلاوا",
                "label_fr": "Bielawa"
            },
            {
                "value": 75298,
                "label": "Niewierz",
                "label_ar": "نويرز",
                "label_fr": "Niewierz"
            },
            {
                "value": 75299,
                "label": "Glowna",
                "label_ar": "جلونا",
                "label_fr": "Glowna"
            },
            {
                "value": 75300,
                "label": "Winnica",
                "label_ar": "وينيكا",
                "label_fr": "Winnica"
            },
            {
                "value": 75301,
                "label": "Krasocin",
                "label_ar": "كراسوسين",
                "label_fr": "Krasocin"
            },
            {
                "value": 75302,
                "label": "Blizyn",
                "label_ar": "بليزين",
                "label_fr": "Blizyn"
            },
            {
                "value": 75303,
                "label": "Bieliny",
                "label_ar": "بيليني",
                "label_fr": "Bieliny"
            },
            {
                "value": 75304,
                "label": "Jedlnia-Letnisko",
                "label_ar": "جيدلنيا ليتنسكو",
                "label_fr": "Jedlnia-Letnisko"
            },
            {
                "value": 75305,
                "label": "Olimpia",
                "label_ar": "أوليمبيا",
                "label_fr": "Olimpia"
            },
            {
                "value": 75306,
                "label": "Brześć Kujawski",
                "label_ar": "برزيك كوجاوسكي",
                "label_fr": "Brześć Kujawski"
            },
            {
                "value": 75307,
                "label": "Lipnica Wielka",
                "label_ar": "ليبنيكا ويلكا",
                "label_fr": "Lipnica Wielka"
            },
            {
                "value": 75308,
                "label": "Mieroszow",
                "label_ar": "ميروزوف",
                "label_fr": "Mieroszow"
            },
            {
                "value": 75309,
                "label": "Jastrowie",
                "label_ar": "جاستروي",
                "label_fr": "Jastrowie"
            },
            {
                "value": 75310,
                "label": "Lubaczow",
                "label_ar": "Lubaczow",
                "label_fr": "Lubaczow"
            },
            {
                "value": 75311,
                "label": "Krynica",
                "label_ar": "كرينيكا",
                "label_fr": "Krynica"
            },
            {
                "value": 75312,
                "label": "Gmina Opole Lubelskie",
                "label_ar": "جمينا أوبول لوبليسكي",
                "label_fr": "Gmina Opole Lubelskie"
            },
            {
                "value": 75313,
                "label": "Siedlec",
                "label_ar": "سيدليك",
                "label_fr": "Siedlec"
            },
            {
                "value": 75314,
                "label": "Ślesin",
                "label_ar": "أوليسين",
                "label_fr": "Ślesin"
            },
            {
                "value": 75315,
                "label": "Kozminek",
                "label_ar": "كوزمينك",
                "label_fr": "Kozminek"
            },
            {
                "value": 75316,
                "label": "Zaluski",
                "label_ar": "زالوسكي",
                "label_fr": "Zaluski"
            },
            {
                "value": 75317,
                "label": "Paulina",
                "label_ar": "بولينا",
                "label_fr": "Paulina"
            },
            {
                "value": 75318,
                "label": "Kleszczow",
                "label_ar": "Kleszczow",
                "label_fr": "Kleszczow"
            },
            {
                "value": 75319,
                "label": "Miechow",
                "label_ar": "ميتشو",
                "label_fr": "Miechow"
            },
            {
                "value": 75320,
                "label": "Wlodzimierz",
                "label_ar": "Wlodzimierz",
                "label_fr": "Wlodzimierz"
            },
            {
                "value": 75321,
                "label": "Zebowice",
                "label_ar": "زيبوفيتسي",
                "label_fr": "Zebowice"
            },
            {
                "value": 75322,
                "label": "Chrzastowka",
                "label_ar": "Chrzastowka",
                "label_fr": "Chrzastowka"
            },
            {
                "value": 75323,
                "label": "Szebnie",
                "label_ar": "Szebnie",
                "label_fr": "Szebnie"
            },
            {
                "value": 75324,
                "label": "Zalubice Nowe",
                "label_ar": "زالوبيس نو",
                "label_fr": "Zalubice Nowe"
            },
            {
                "value": 75325,
                "label": "Pedziwiatry",
                "label_ar": "بيدزيواتري",
                "label_fr": "Pédziwiatry"
            },
            {
                "value": 75326,
                "label": "Medynia Glogowska",
                "label_ar": "ميدينيا جلوجوسكا",
                "label_fr": "Medynia Glogowska"
            },
            {
                "value": 75327,
                "label": "Nienadowka",
                "label_ar": "نينادوفكا",
                "label_fr": "Nienadowka"
            },
            {
                "value": 75328,
                "label": "Dziechciniec",
                "label_ar": "Dziechciniec",
                "label_fr": "Dziechciniec"
            },
            {
                "value": 75329,
                "label": "Glogow Malopolski",
                "label_ar": "جلوجو مالوبولسكي",
                "label_fr": "Glogow Malopolski"
            },
            {
                "value": 75330,
                "label": "Krasne",
                "label_ar": "كراسن",
                "label_fr": "Krasne"
            },
            {
                "value": 75331,
                "label": "Kozieglowy",
                "label_ar": "Kozieglowy",
                "label_fr": "Kozieglowy"
            },
            {
                "value": 75332,
                "label": "Izabelin",
                "label_ar": "إيزابلين",
                "label_fr": "Izabelin"
            },
            {
                "value": 75333,
                "label": "Keblowo",
                "label_ar": "كيبلوو",
                "label_fr": "Keblowo"
            },
            {
                "value": 75334,
                "label": "Gora",
                "label_ar": "جورا",
                "label_fr": "Gora"
            },
            {
                "value": 75335,
                "label": "Gowino",
                "label_ar": "جوينو",
                "label_fr": "Gowino"
            },
            {
                "value": 75336,
                "label": "Moryn",
                "label_ar": "مورين",
                "label_fr": "Moryn"
            },
            {
                "value": 75337,
                "label": "Kijewo Krolewskie",
                "label_ar": "Kijewo Krolewskie",
                "label_fr": "Kijewo Krolewskie"
            },
            {
                "value": 75338,
                "label": "Lesko",
                "label_ar": "ليسكو",
                "label_fr": "Lesko"
            },
            {
                "value": 75339,
                "label": "Domaradz",
                "label_ar": "دومارادز",
                "label_fr": "Domaradz"
            },
            {
                "value": 75340,
                "label": "Suraż",
                "label_ar": "سورة",
                "label_fr": "Sourateż"
            },
            {
                "value": 75341,
                "label": "Radzanow",
                "label_ar": "رادزانو",
                "label_fr": "Radzanow"
            },
            {
                "value": 75342,
                "label": "Malogoszcz",
                "label_ar": "Malogoszcz",
                "label_fr": "Malogoszcz"
            },
            {
                "value": 75343,
                "label": "Mikolajki",
                "label_ar": "ميكولاجكي",
                "label_fr": "Mikolajki"
            },
            {
                "value": 75344,
                "label": "Mirsk",
                "label_ar": "ميرسك",
                "label_fr": "Mirsk"
            },
            {
                "value": 75345,
                "label": "Trzemeszno",
                "label_ar": "ترزيميسزنو",
                "label_fr": "Trzemeszno"
            },
            {
                "value": 75346,
                "label": "Pakość",
                "label_ar": "باكو",
                "label_fr": "Pakość"
            },
            {
                "value": 75347,
                "label": "Orchowo",
                "label_ar": "Orchowo",
                "label_fr": "Orchowo"
            },
            {
                "value": 75348,
                "label": "Gębice",
                "label_ar": "Gębice",
                "label_fr": "Gębice"
            },
            {
                "value": 75349,
                "label": "Izabela",
                "label_ar": "إيزابيلا",
                "label_fr": "Izabela"
            },
            {
                "value": 75350,
                "label": "Dobromierz",
                "label_ar": "دوبروميرز",
                "label_fr": "Dobromierz"
            },
            {
                "value": 75351,
                "label": "Wiorek",
                "label_ar": "ويورك",
                "label_fr": "Wiorek"
            },
            {
                "value": 75352,
                "label": "Brzoza Krolewska",
                "label_ar": "برزوزا كرولوسكا",
                "label_fr": "Brzoza Krolewska"
            },
            {
                "value": 75353,
                "label": "Narzym",
                "label_ar": "نارزيم",
                "label_fr": "Narzym"
            },
            {
                "value": 75354,
                "label": "Gmina Potęgowo",
                "label_ar": "غمينا بوتوجوو",
                "label_fr": "Gmina Potęgowo"
            },
            {
                "value": 75355,
                "label": "Smolnica",
                "label_ar": "سمولنيكا",
                "label_fr": "Smolnica"
            },
            {
                "value": 75356,
                "label": "Grodkow",
                "label_ar": "غرودكو",
                "label_fr": "Grodkow"
            },
            {
                "value": 75357,
                "label": "Konarzewo",
                "label_ar": "كونارزيو",
                "label_fr": "Konarzewo"
            },
            {
                "value": 75358,
                "label": "Muszyna",
                "label_ar": "Muszyna",
                "label_fr": "Muszyna"
            },
            {
                "value": 75359,
                "label": "Cekow",
                "label_ar": "سيكو",
                "label_fr": "Cekow"
            },
            {
                "value": 75360,
                "label": "Trzemesnia",
                "label_ar": "ترزيميسنيا",
                "label_fr": "Trzemesnia"
            },
            {
                "value": 75361,
                "label": "Mrocza",
                "label_ar": "مروكزا",
                "label_fr": "Mrocza"
            },
            {
                "value": 75362,
                "label": "Grybow",
                "label_ar": "جريبو",
                "label_fr": "Grybow"
            },
            {
                "value": 75363,
                "label": "Oltarzew",
                "label_ar": "أولترزيو",
                "label_fr": "Oltarzew"
            },
            {
                "value": 75364,
                "label": "Drawno",
                "label_ar": "درونو",
                "label_fr": "Drawno"
            },
            {
                "value": 75365,
                "label": "Tylmanowa",
                "label_ar": "تايلمانوا",
                "label_fr": "Tylmanowa"
            },
            {
                "value": 75366,
                "label": "Zalasewo",
                "label_ar": "Zalasewo",
                "label_fr": "Zalasewo"
            },
            {
                "value": 75367,
                "label": "Dobrzen Wielki",
                "label_ar": "Dobrzen Wielki",
                "label_fr": "Dobrzen Wielki"
            }
        ]
    },
    {
        "country": "Ireland",
        "cities": [
            {
                "value": 75384,
                "label": "Carlow",
                "label_ar": "كارلو",
                "label_fr": "Carlow"
            },
            {
                "value": 75472,
                "label": "Donabate",
                "label_ar": "تبرع",
                "label_fr": "Donabate"
            },
            {
                "value": 75401,
                "label": "Clonsilla",
                "label_ar": "كلونسيلا",
                "label_fr": "Clonsilla"
            },
            {
                "value": 75564,
                "label": "Oranmore",
                "label_ar": "أورانمور",
                "label_fr": "Oranmore"
            },
            {
                "value": 75635,
                "label": "Carraroe",
                "label_ar": "كارارو",
                "label_fr": "Carraroe"
            },
            {
                "value": 75557,
                "label": "Passage West",
                "label_ar": "ممر غرب",
                "label_fr": "Passage Ouest"
            },
            {
                "value": 75644,
                "label": "Naul",
                "label_ar": "ناول",
                "label_fr": "Naul"
            },
            {
                "value": 75434,
                "label": "Nenagh",
                "label_ar": "نيناغ",
                "label_fr": "Nenagh"
            },
            {
                "value": 75390,
                "label": "Swords",
                "label_ar": "السيوف",
                "label_fr": "Épées"
            },
            {
                "value": 75551,
                "label": "Geevagh",
                "label_ar": "جيفاغ",
                "label_fr": "Geevagh"
            },
            {
                "value": 75371,
                "label": "Mountrath",
                "label_ar": "ماونتراث",
                "label_fr": "Mountrath"
            },
            {
                "value": 75421,
                "label": "Naas",
                "label_ar": "ناس",
                "label_fr": "Naas"
            },
            {
                "value": 75579,
                "label": "Clarecastle",
                "label_ar": "كلاريكاسل",
                "label_fr": "Clarecastle"
            },
            {
                "value": 75593,
                "label": "Foynes",
                "label_ar": "فوينز",
                "label_fr": "Foynes"
            },
            {
                "value": 75621,
                "label": "Headford",
                "label_ar": "هيدفورد",
                "label_fr": "Headford"
            },
            {
                "value": 75424,
                "label": "Leixlip",
                "label_ar": "ليكسليب",
                "label_fr": "Leixlip"
            },
            {
                "value": 75483,
                "label": "Tralee",
                "label_ar": "ترالي",
                "label_fr": "Tralee"
            },
            {
                "value": 75661,
                "label": "Newmarket",
                "label_ar": "سوق جديد",
                "label_fr": "Nouveau marché"
            },
            {
                "value": 75538,
                "label": "Ballyhooly",
                "label_ar": "Ballyhooly",
                "label_fr": "Ballyhooly"
            },
            {
                "value": 75433,
                "label": "Glen",
                "label_ar": "جلين",
                "label_fr": "Glen"
            },
            {
                "value": 75389,
                "label": "Templeogue",
                "label_ar": "تمبلوغ",
                "label_fr": "Templeogue"
            },
            {
                "value": 75383,
                "label": "Tipperary",
                "label_ar": "تيبيراري",
                "label_fr": "Tipperary"
            },
            {
                "value": 75413,
                "label": "Clonmel",
                "label_ar": "كلونميل",
                "label_fr": "Clonmel"
            },
            {
                "value": 75535,
                "label": "Ballybrack",
                "label_ar": "باليبراك",
                "label_fr": "Ballybrack"
            },
            {
                "value": 75468,
                "label": "Stepaside",
                "label_ar": "ستيبسايد",
                "label_fr": "Écartez vous"
            },
            {
                "value": 75581,
                "label": "Ballon",
                "label_ar": "بالون",
                "label_fr": "Ballon"
            },
            {
                "value": 75566,
                "label": "Callan",
                "label_ar": "كالان",
                "label_fr": "Callan"
            },
            {
                "value": 75634,
                "label": "Ballyragget",
                "label_ar": "باليراجيت",
                "label_fr": "Ballyragget"
            },
            {
                "value": 75372,
                "label": "Dublin",
                "label_ar": "دبلن",
                "label_fr": "Dublin"
            },
            {
                "value": 75494,
                "label": "Crumlin",
                "label_ar": "كروملين",
                "label_fr": "Crumlin"
            },
            {
                "value": 75603,
                "label": "Doughiska",
                "label_ar": "دوجيسكا",
                "label_fr": "Doughiska"
            },
            {
                "value": 75638,
                "label": "Glanmire",
                "label_ar": "جلانمير",
                "label_fr": "Glanmire"
            },
            {
                "value": 75511,
                "label": "Mallow",
                "label_ar": "ملوخية",
                "label_fr": "Mauve"
            },
            {
                "value": 75583,
                "label": "Coachford",
                "label_ar": "كوتشفورد",
                "label_fr": "Coachford"
            },
            {
                "value": 75393,
                "label": "Clondalkin",
                "label_ar": "كلوندالكين",
                "label_fr": "Clondalkin"
            },
            {
                "value": 75416,
                "label": "Athlone",
                "label_ar": "أثلون",
                "label_fr": "Athlone"
            },
            {
                "value": 75439,
                "label": "Athboy",
                "label_ar": "أثبوي",
                "label_fr": "Athboy"
            },
            {
                "value": 75438,
                "label": "Sligo",
                "label_ar": "سليجو",
                "label_fr": "Sligo"
            },
            {
                "value": 75600,
                "label": "Feakle",
                "label_ar": "ضعيف",
                "label_fr": "Feakle"
            },
            {
                "value": 75529,
                "label": "Kildalkey",
                "label_ar": "كيلدالكي",
                "label_fr": "Kildalkey"
            },
            {
                "value": 75647,
                "label": "Ballycullen",
                "label_ar": "باليكولين",
                "label_fr": "Ballycullen"
            },
            {
                "value": 75654,
                "label": "Rathmines",
                "label_ar": "راثماينز",
                "label_fr": "Rathmines"
            },
            {
                "value": 75648,
                "label": "Sallins",
                "label_ar": "سالينز",
                "label_fr": "Sallins"
            },
            {
                "value": 75518,
                "label": "Clonakilty",
                "label_ar": "كلوناكيلتي",
                "label_fr": "Clonakilty"
            },
            {
                "value": 75580,
                "label": "Tullamore",
                "label_ar": "تولامور",
                "label_fr": "Tullamore"
            },
            {
                "value": 75643,
                "label": "Mornington",
                "label_ar": "مورنينغتون",
                "label_fr": "Mornington"
            },
            {
                "value": 75423,
                "label": "Waterford",
                "label_ar": "وترفورد",
                "label_fr": "Waterford"
            },
            {
                "value": 75547,
                "label": "Portarlington",
                "label_ar": "بورتارلينجتون",
                "label_fr": "Portarlington"
            },
            {
                "value": 75385,
                "label": "Abbeyleix",
                "label_ar": "أبيليكس",
                "label_fr": "Abbeyleix"
            },
            {
                "value": 75629,
                "label": "Portumna",
                "label_ar": "بورتومنا",
                "label_fr": "Portumna"
            },
            {
                "value": 75646,
                "label": "Portmarnock",
                "label_ar": "بورتمارنوك",
                "label_fr": "Portmarnock"
            },
            {
                "value": 75415,
                "label": "Ashbourne",
                "label_ar": "أشبورن",
                "label_fr": "Ashbourne"
            },
            {
                "value": 75402,
                "label": "Kilkenny",
                "label_ar": "كيلكيني",
                "label_fr": "Kilkenny"
            },
            {
                "value": 75403,
                "label": "Droichead Nua",
                "label_ar": "درويشيد نوا",
                "label_fr": "Droichead Nua"
            },
            {
                "value": 75397,
                "label": "Limerick",
                "label_ar": "ليمريك",
                "label_fr": "Limerick"
            },
            {
                "value": 75611,
                "label": "Doolin",
                "label_ar": "دولن",
                "label_fr": "Doolin"
            },
            {
                "value": 75651,
                "label": "Jamestown",
                "label_ar": "جيمستاون",
                "label_fr": "Jamestown"
            },
            {
                "value": 75657,
                "label": "Sallynoggin",
                "label_ar": "ساللينوجين",
                "label_fr": "Sallynoggin"
            },
            {
                "value": 75448,
                "label": "Abbeyfeale",
                "label_ar": "أبييفيل",
                "label_fr": "Abbeyfeale"
            },
            {
                "value": 75541,
                "label": "Straffan",
                "label_ar": "سترافان",
                "label_fr": "Straffan"
            },
            {
                "value": 75530,
                "label": "Castlerea",
                "label_ar": "كاستليريا",
                "label_fr": "Castlerea"
            },
            {
                "value": 75637,
                "label": "Crusheen",
                "label_ar": "Crusheen",
                "label_fr": "Crusheen"
            },
            {
                "value": 75436,
                "label": "Kilbride Cross Roads",
                "label_ar": "كيلبرايد كروس رودز",
                "label_fr": "Kilbride Cross Roads"
            },
            {
                "value": 75457,
                "label": "Wexford",
                "label_ar": "ويكسفورد",
                "label_fr": "Wexford"
            },
            {
                "value": 75578,
                "label": "Birr",
                "label_ar": "بير",
                "label_fr": "Birr"
            },
            {
                "value": 75627,
                "label": "Bantry",
                "label_ar": "بانتري",
                "label_fr": "Bantry"
            },
            {
                "value": 75480,
                "label": "Hospital",
                "label_ar": "مستشفى",
                "label_fr": "Hôpital"
            },
            {
                "value": 75625,
                "label": "Ballinasloe",
                "label_ar": "بالينسلو",
                "label_fr": "Ballinasloe"
            },
            {
                "value": 75370,
                "label": "Roscrea",
                "label_ar": "روسكريا",
                "label_fr": "Roscrea"
            },
            {
                "value": 75519,
                "label": "Ballyphilip",
                "label_ar": "بالفيليب",
                "label_fr": "Ballyphilip"
            },
            {
                "value": 75548,
                "label": "Buncrana",
                "label_ar": "Buncrana",
                "label_fr": "Buncrana"
            },
            {
                "value": 75447,
                "label": "Killiney",
                "label_ar": "كيليني",
                "label_fr": "Killiney"
            },
            {
                "value": 75490,
                "label": "Mitchelstown",
                "label_ar": "ميتشلستاون",
                "label_fr": "Mitchelstown"
            },
            {
                "value": 75487,
                "label": "Duleek",
                "label_ar": "دوليك",
                "label_fr": "Duleek"
            },
            {
                "value": 75619,
                "label": "Ballinrobe",
                "label_ar": "بالينروب",
                "label_fr": "Ballinrobe"
            },
            {
                "value": 75592,
                "label": "Swinford",
                "label_ar": "سوينفورد",
                "label_fr": "Swinford"
            },
            {
                "value": 75497,
                "label": "Kinsale",
                "label_ar": "كينسالي",
                "label_fr": "Kinsale"
            },
            {
                "value": 75476,
                "label": "Kilcock",
                "label_ar": "كيلكوك",
                "label_fr": "Kilcock"
            },
            {
                "value": 75418,
                "label": "Dalkey",
                "label_ar": "دالكى",
                "label_fr": "Dalkey"
            },
            {
                "value": 75568,
                "label": "Carnew",
                "label_ar": "كارنو",
                "label_fr": "Carnew"
            },
            {
                "value": 75605,
                "label": "Raheny",
                "label_ar": "راهيني",
                "label_fr": "Raheny"
            },
            {
                "value": 75391,
                "label": "Bray",
                "label_ar": "نهيق",
                "label_fr": "Braire"
            },
            {
                "value": 75626,
                "label": "Cahersiveen",
                "label_ar": "كاهيرسيفين",
                "label_fr": "Cahersiveen"
            },
            {
                "value": 75620,
                "label": "Tuam",
                "label_ar": "توام",
                "label_fr": "Tuam"
            },
            {
                "value": 75445,
                "label": "Foxrock",
                "label_ar": "فوكسروك",
                "label_fr": "Foxrock"
            },
            {
                "value": 75561,
                "label": "Virginia",
                "label_ar": "فرجينيا",
                "label_fr": "Virginie"
            },
            {
                "value": 75652,
                "label": "Bodyke",
                "label_ar": "Bodyke",
                "label_fr": "Bodyke"
            },
            {
                "value": 75475,
                "label": "Thurles",
                "label_ar": "Thurles",
                "label_fr": "Thurles"
            },
            {
                "value": 75470,
                "label": "Firhouse",
                "label_ar": "فيرهاوس",
                "label_fr": "Firhouse"
            },
            {
                "value": 75544,
                "label": "Courtown",
                "label_ar": "كورتاون",
                "label_fr": "Courtown"
            },
            {
                "value": 75550,
                "label": "Carrickmacross",
                "label_ar": "كاريكماكروس",
                "label_fr": "Carrickmacross"
            },
            {
                "value": 75516,
                "label": "Glengarriff",
                "label_ar": "جلينجاريف",
                "label_fr": "Glengarriff"
            },
            {
                "value": 75451,
                "label": "Ballincollig",
                "label_ar": "بالينكوليج",
                "label_fr": "Ballincollig"
            },
            {
                "value": 75659,
                "label": "Cross",
                "label_ar": "تعبر",
                "label_fr": "Traverser"
            },
            {
                "value": 75639,
                "label": "Arklow",
                "label_ar": "أركلو",
                "label_fr": "Arklow"
            },
            {
                "value": 75485,
                "label": "Cobh",
                "label_ar": "كوبه",
                "label_fr": "Cobh"
            },
            {
                "value": 75453,
                "label": "Ballyroe",
                "label_ar": "باليرو",
                "label_fr": "Ballyroe"
            },
            {
                "value": 75456,
                "label": "Macroom",
                "label_ar": "ماكروم",
                "label_fr": "Macroom"
            },
            {
                "value": 75622,
                "label": "Spiddal",
                "label_ar": "سبيدال",
                "label_fr": "Spiddal"
            },
            {
                "value": 75559,
                "label": "Oysterhaven",
                "label_ar": "أويسترهافن",
                "label_fr": "Oysterhaven"
            },
            {
                "value": 75410,
                "label": "Rathgar",
                "label_ar": "راثغار",
                "label_fr": "Rathgar"
            },
            {
                "value": 75394,
                "label": "Ballyfermot",
                "label_ar": "باليفرموت",
                "label_fr": "Ballyfermot"
            },
            {
                "value": 75459,
                "label": "Milltown",
                "label_ar": "ميلتاون",
                "label_fr": "Ville de moulin"
            },
            {
                "value": 75510,
                "label": "Moycullen",
                "label_ar": "مويكولين",
                "label_fr": "Moycullen"
            },
            {
                "value": 75537,
                "label": "Leopardstown",
                "label_ar": "ليوباردستاون",
                "label_fr": "Leopardstown"
            },
            {
                "value": 75618,
                "label": "Oughterard",
                "label_ar": "اوتيرارد",
                "label_fr": "Oughterard"
            },
            {
                "value": 75496,
                "label": "Kingswood",
                "label_ar": "كينجسوود",
                "label_fr": "Kingswood"
            },
            {
                "value": 75527,
                "label": "Enfield",
                "label_ar": "انفيلد",
                "label_fr": "Enfield"
            },
            {
                "value": 75595,
                "label": "Newcastle West",
                "label_ar": "نيوكاسل ويست",
                "label_fr": "Newcastle West"
            },
            {
                "value": 75460,
                "label": "Dundalk",
                "label_ar": "دوندالك",
                "label_fr": "Dundalk"
            },
            {
                "value": 75660,
                "label": "Castlemaine",
                "label_ar": "كاسلمين",
                "label_fr": "Castlemaine"
            },
            {
                "value": 75417,
                "label": "Marino",
                "label_ar": "مارينو",
                "label_fr": "Marino"
            },
            {
                "value": 75435,
                "label": "Shannon",
                "label_ar": "شانون",
                "label_fr": "Shannon"
            },
            {
                "value": 75477,
                "label": "Sutton",
                "label_ar": "ساتون",
                "label_fr": "Sutton"
            },
            {
                "value": 75656,
                "label": "Kenmare",
                "label_ar": "كينمير",
                "label_fr": "Kenmare"
            },
            {
                "value": 75467,
                "label": "Island",
                "label_ar": "جزيرة",
                "label_fr": "Île"
            },
            {
                "value": 75640,
                "label": "Kilcoole",
                "label_ar": "كيلكول",
                "label_fr": "Kilcoole"
            },
            {
                "value": 75373,
                "label": "Porterstown",
                "label_ar": "بورترستاون",
                "label_fr": "Porterstown"
            },
            {
                "value": 75570,
                "label": "Castlebridge",
                "label_ar": "كاسلبريدج",
                "label_fr": "Castlebridge"
            },
            {
                "value": 75400,
                "label": "Castleknock",
                "label_ar": "كاسلنوك",
                "label_fr": "Castleknock"
            },
            {
                "value": 75409,
                "label": "Wicklow",
                "label_ar": "ويكلو",
                "label_fr": "Wicklow"
            },
            {
                "value": 75602,
                "label": "Windy Arbour",
                "label_ar": "Windy Arbor",
                "label_fr": "Windy Arbor"
            },
            {
                "value": 75616,
                "label": "Ballinadee",
                "label_ar": "بالينادي",
                "label_fr": "Ballinadee"
            },
            {
                "value": 75604,
                "label": "Thomastown",
                "label_ar": "توماستاون",
                "label_fr": "Thomastown"
            },
            {
                "value": 75653,
                "label": "Rathcoole",
                "label_ar": "راثكول",
                "label_fr": "Rathcoole"
            },
            {
                "value": 75608,
                "label": "Patrickswell",
                "label_ar": "باتريكسويل",
                "label_fr": "Patrickswell"
            },
            {
                "value": 75506,
                "label": "Bundoran",
                "label_ar": "بوندوران",
                "label_fr": "Bundoran"
            },
            {
                "value": 75517,
                "label": "Ballygarvan",
                "label_ar": "باليجارفان",
                "label_fr": "Ballygarvan"
            },
            {
                "value": 75381,
                "label": "Carrick",
                "label_ar": "كاريك",
                "label_fr": "Carrick"
            },
            {
                "value": 75388,
                "label": "Tallaght",
                "label_ar": "تلاغت",
                "label_fr": "Tallaght"
            },
            {
                "value": 75454,
                "label": "Sandymount",
                "label_ar": "ساندي ماونت",
                "label_fr": "Sandymount"
            },
            {
                "value": 75586,
                "label": "County Wexford",
                "label_ar": "مقاطعة ويكسفورد",
                "label_fr": "Comté de Wexford"
            },
            {
                "value": 75504,
                "label": "Tyrrellspass",
                "label_ar": "تيريلسباس",
                "label_fr": "Tyrrellspass"
            },
            {
                "value": 75432,
                "label": "Mount Merrion",
                "label_ar": "جبل ميريون",
                "label_fr": "Mont Merrion"
            },
            {
                "value": 75412,
                "label": "Mullingar",
                "label_ar": "مولينجار",
                "label_fr": "Mullingar"
            },
            {
                "value": 75503,
                "label": "Ballineen",
                "label_ar": "بالينين",
                "label_fr": "Ballineen"
            },
            {
                "value": 75536,
                "label": "Dún Laoghaire",
                "label_ar": "دن لاوجير",
                "label_fr": "Dún Laoghaire"
            },
            {
                "value": 75555,
                "label": "Carrickmines",
                "label_ar": "كاريكماينز",
                "label_fr": "Carrickmines"
            },
            {
                "value": 75489,
                "label": "Lusk",
                "label_ar": "لاسك",
                "label_fr": "Lusk"
            },
            {
                "value": 75474,
                "label": "Rathfarnham",
                "label_ar": "راثفارنهام",
                "label_fr": "Rathfarnham"
            },
            {
                "value": 75597,
                "label": "Dunleer",
                "label_ar": "دنلير",
                "label_fr": "Dunleer"
            },
            {
                "value": 75455,
                "label": "Slieve",
                "label_ar": "سليف",
                "label_fr": "Slieve"
            },
            {
                "value": 75481,
                "label": "Bettystown",
                "label_ar": "بيتيستاون",
                "label_fr": "Bettystown"
            },
            {
                "value": 75458,
                "label": "Blanchardstown",
                "label_ar": "بلانشاردستاون",
                "label_fr": "Blanchardstown"
            },
            {
                "value": 75641,
                "label": "Kilcullen",
                "label_ar": "كيلكولين",
                "label_fr": "Kilcullen"
            },
            {
                "value": 75513,
                "label": "Ratoath",
                "label_ar": "راتوات",
                "label_fr": "Ratoath"
            },
            {
                "value": 75582,
                "label": "Kingscourt",
                "label_ar": "محكمة الملك",
                "label_fr": "Kingscourt"
            },
            {
                "value": 75528,
                "label": "Edenderry",
                "label_ar": "إدندرري",
                "label_fr": "Edenderry"
            },
            {
                "value": 75567,
                "label": "Tullow",
                "label_ar": "تالو",
                "label_fr": "Tullow"
            },
            {
                "value": 75599,
                "label": "Killaloe",
                "label_ar": "كيلالو",
                "label_fr": "Killaloe"
            },
            {
                "value": 75614,
                "label": "Ringaskiddy",
                "label_ar": "رينغاسكيدي",
                "label_fr": "Ringaskiddy"
            },
            {
                "value": 75466,
                "label": "Ballymahon",
                "label_ar": "باليماهون",
                "label_fr": "Ballymahon"
            },
            {
                "value": 75545,
                "label": "Loughrea",
                "label_ar": "لوغريا",
                "label_fr": "Loughrea"
            },
            {
                "value": 75426,
                "label": "Mayo",
                "label_ar": "مايو",
                "label_fr": "Mayo"
            },
            {
                "value": 75498,
                "label": "Dunboyne",
                "label_ar": "دانبوين",
                "label_fr": "Dunboyne"
            },
            {
                "value": 75617,
                "label": "Kinvarra",
                "label_ar": "كينفارا",
                "label_fr": "Kinvarra"
            },
            {
                "value": 75495,
                "label": "Clontarf",
                "label_ar": "كلونتارف",
                "label_fr": "Clontarf"
            },
            {
                "value": 75523,
                "label": "Carbury",
                "label_ar": "كاربوري",
                "label_fr": "Carbury"
            },
            {
                "value": 75574,
                "label": "Longford",
                "label_ar": "لونجفورد",
                "label_fr": "Longford"
            },
            {
                "value": 75560,
                "label": "Cavan",
                "label_ar": "كافان",
                "label_fr": "Cavan"
            },
            {
                "value": 75491,
                "label": "Kanturk",
                "label_ar": "كانتورك",
                "label_fr": "Kanturk"
            },
            {
                "value": 75628,
                "label": "Crookhaven",
                "label_ar": "كروكهافن",
                "label_fr": "Crookhaven"
            },
            {
                "value": 75521,
                "label": "Kilfinane",
                "label_ar": "كيلفينان",
                "label_fr": "Kilfinane"
            },
            {
                "value": 75534,
                "label": "Santry",
                "label_ar": "سانتري",
                "label_fr": "Santry"
            },
            {
                "value": 75598,
                "label": "Longwood",
                "label_ar": "لونغوود",
                "label_fr": "Longwood"
            },
            {
                "value": 75610,
                "label": "Ballyvaghan",
                "label_ar": "باليافاغان",
                "label_fr": "Ballyvaghan"
            },
            {
                "value": 75505,
                "label": "Donegal",
                "label_ar": "دونيجال",
                "label_fr": "Donegal"
            },
            {
                "value": 75414,
                "label": "Sandyford",
                "label_ar": "سانديفورد",
                "label_fr": "Sandyford"
            },
            {
                "value": 75553,
                "label": "Carrick on Shannon",
                "label_ar": "كاريك على شانون",
                "label_fr": "Carrick sur Shannon"
            },
            {
                "value": 75499,
                "label": "Caher",
                "label_ar": "قاهر",
                "label_fr": "Caher"
            },
            {
                "value": 75539,
                "label": "Claregalway",
                "label_ar": "كلارجالواي",
                "label_fr": "Claregalway"
            },
            {
                "value": 75446,
                "label": "Shankill",
                "label_ar": "شانكيل",
                "label_fr": "Shankill"
            },
            {
                "value": 75514,
                "label": "Bandon",
                "label_ar": "باندون",
                "label_fr": "Bandon"
            },
            {
                "value": 75376,
                "label": "Ballina",
                "label_ar": "بالينا",
                "label_fr": "Ballina"
            },
            {
                "value": 75449,
                "label": "Dunshaughlin",
                "label_ar": "دونشولين",
                "label_fr": "Dunshaughlin"
            },
            {
                "value": 75450,
                "label": "Navan",
                "label_ar": "نافان",
                "label_fr": "Navan"
            },
            {
                "value": 75558,
                "label": "Ennis",
                "label_ar": "اينيس",
                "label_fr": "Ennis"
            },
            {
                "value": 75427,
                "label": "Terenure",
                "label_ar": "Terenure",
                "label_fr": "Terenure"
            },
            {
                "value": 75437,
                "label": "Killybegs",
                "label_ar": "كيليبيغ",
                "label_fr": "Killybegs"
            },
            {
                "value": 75420,
                "label": "Glasnevin",
                "label_ar": "جلاسنيفين",
                "label_fr": "Glasnevin"
            },
            {
                "value": 75486,
                "label": "Enniskerry",
                "label_ar": "إنسكيري",
                "label_fr": "Enniskerry"
            },
            {
                "value": 75464,
                "label": "Gorey",
                "label_ar": "جوري",
                "label_fr": "Gorey"
            },
            {
                "value": 75473,
                "label": "Coolock",
                "label_ar": "كولوك",
                "label_fr": "Coolock"
            },
            {
                "value": 75623,
                "label": "Gort",
                "label_ar": "جورت",
                "label_fr": "Gort"
            },
            {
                "value": 75590,
                "label": "Westport",
                "label_ar": "ويستبورت",
                "label_fr": "Westport"
            },
            {
                "value": 75428,
                "label": "Clane",
                "label_ar": "عشيرة",
                "label_fr": "Clane"
            },
            {
                "value": 75632,
                "label": "Leamlara",
                "label_ar": "ليملارا",
                "label_fr": "Leamlara"
            },
            {
                "value": 75442,
                "label": "Youghal",
                "label_ar": "يوجال",
                "label_fr": "Youghal"
            },
            {
                "value": 75642,
                "label": "Ferbane",
                "label_ar": "فيربان",
                "label_fr": "Ferbane"
            },
            {
                "value": 75374,
                "label": "Carrigaline",
                "label_ar": "كاريجالين",
                "label_fr": "Carrigaline"
            },
            {
                "value": 75563,
                "label": "Bailieborough",
                "label_ar": "بايليبورو",
                "label_fr": "Bailieborough"
            },
            {
                "value": 75601,
                "label": "Baldoyle",
                "label_ar": "بالدويل",
                "label_fr": "Baldoyle"
            },
            {
                "value": 75395,
                "label": "Artane",
                "label_ar": "أرتان",
                "label_fr": "Artane"
            },
            {
                "value": 75573,
                "label": "Ballymote",
                "label_ar": "باليموت",
                "label_fr": "Ballymote"
            },
            {
                "value": 75380,
                "label": "Lisselton",
                "label_ar": "ليسلتون",
                "label_fr": "Lisselton"
            },
            {
                "value": 75500,
                "label": "Broadford",
                "label_ar": "برودفورد",
                "label_fr": "Broadford"
            },
            {
                "value": 75482,
                "label": "River",
                "label_ar": "نهر",
                "label_fr": "rivière"
            },
            {
                "value": 75615,
                "label": "Skibbereen",
                "label_ar": "سكيبيرين",
                "label_fr": "Skibbereen"
            },
            {
                "value": 75532,
                "label": "Skerries",
                "label_ar": "سكيريس",
                "label_fr": "Skerries"
            },
            {
                "value": 75392,
                "label": "Cabinteely",
                "label_ar": "كابينتيلي",
                "label_fr": "Cabinteely"
            },
            {
                "value": 75562,
                "label": "Clones",
                "label_ar": "الحيوانات المستنسخة",
                "label_fr": "Clones"
            },
            {
                "value": 75577,
                "label": "Castleblayney",
                "label_ar": "كاسل بلايني",
                "label_fr": "Castleblayney"
            },
            {
                "value": 75533,
                "label": "Garristown",
                "label_ar": "جاريستاون",
                "label_fr": "Garristown"
            },
            {
                "value": 75540,
                "label": "Dunlavin",
                "label_ar": "دنلافين",
                "label_fr": "Dunlavin"
            },
            {
                "value": 75554,
                "label": "Saint Mullins",
                "label_ar": "سانت مولينز",
                "label_fr": "Saint Mullins"
            },
            {
                "value": 75575,
                "label": "Ballivor",
                "label_ar": "باليفور",
                "label_fr": "Ballivor"
            },
            {
                "value": 75425,
                "label": "Walkinstown",
                "label_ar": "ووكينستاون",
                "label_fr": "Walkinstown"
            },
            {
                "value": 75443,
                "label": "Greystones",
                "label_ar": "غرايستون",
                "label_fr": "Pierres grises"
            },
            {
                "value": 75606,
                "label": "Dundrum",
                "label_ar": "حور",
                "label_fr": "Dundrum"
            },
            {
                "value": 75405,
                "label": "Celbridge",
                "label_ar": "سيلبريدج",
                "label_fr": "Celbridge"
            },
            {
                "value": 75591,
                "label": "Claremorris",
                "label_ar": "كلاريموريس",
                "label_fr": "Claremorris"
            },
            {
                "value": 75411,
                "label": "Stillorgan",
                "label_ar": "ستيلورجان",
                "label_fr": "Stillorgan"
            },
            {
                "value": 75594,
                "label": "Sixmilebridge",
                "label_ar": "سيكسمايلبريدج",
                "label_fr": "Sixmilebridge"
            },
            {
                "value": 75556,
                "label": "Killorglin",
                "label_ar": "كيلورجلين",
                "label_fr": "Killorglin"
            },
            {
                "value": 75612,
                "label": "Killurin",
                "label_ar": "كيلورين",
                "label_fr": "Killurin"
            },
            {
                "value": 75406,
                "label": "Lucan",
                "label_ar": "لوكان",
                "label_fr": "Lucan"
            },
            {
                "value": 75609,
                "label": "Delgany",
                "label_ar": "ديلجاني",
                "label_fr": "Delgany"
            },
            {
                "value": 75382,
                "label": "Galway",
                "label_ar": "غالواي",
                "label_fr": "Galway"
            },
            {
                "value": 75479,
                "label": "Monaghan",
                "label_ar": "موناغان",
                "label_fr": "Monaghan"
            },
            {
                "value": 75613,
                "label": "Midleton",
                "label_ar": "ميدلتون",
                "label_fr": "Midleton"
            },
            {
                "value": 75509,
                "label": "Athenry",
                "label_ar": "أثينري",
                "label_fr": "Athenry"
            },
            {
                "value": 75512,
                "label": "Castlebar",
                "label_ar": "كاسلبار",
                "label_fr": "Castlebar"
            },
            {
                "value": 75587,
                "label": "Balgriffin",
                "label_ar": "بالغريفين",
                "label_fr": "Balgriffin"
            },
            {
                "value": 75552,
                "label": "Killala",
                "label_ar": "كيلالا",
                "label_fr": "Killala"
            },
            {
                "value": 75492,
                "label": "Balbriggan",
                "label_ar": "بالبريغان",
                "label_fr": "Balbriggan"
            },
            {
                "value": 75422,
                "label": "Blessington",
                "label_ar": "بليسينجتون",
                "label_fr": "Blessington"
            },
            {
                "value": 75636,
                "label": "Killinick",
                "label_ar": "كيلينيك",
                "label_fr": "Killinick"
            },
            {
                "value": 75524,
                "label": "Monasterevin",
                "label_ar": "Monasterevin",
                "label_fr": "Monasterevin"
            },
            {
                "value": 75584,
                "label": "Clonee",
                "label_ar": "استنساخ",
                "label_fr": "Clonee"
            },
            {
                "value": 75649,
                "label": "Irishtown",
                "label_ar": "آيرشتاون",
                "label_fr": "Irishtown"
            },
            {
                "value": 75484,
                "label": "Ballybrit",
                "label_ar": "باليبريت",
                "label_fr": "Ballybrit"
            },
            {
                "value": 75399,
                "label": "Prosperous",
                "label_ar": "مزدهر",
                "label_fr": "Prospère"
            },
            {
                "value": 75462,
                "label": "Kilrush",
                "label_ar": "كيلراش",
                "label_fr": "Kilrush"
            },
            {
                "value": 75589,
                "label": "Foxford",
                "label_ar": "فوكسفورد",
                "label_fr": "Foxford"
            },
            {
                "value": 75502,
                "label": "Berrings",
                "label_ar": "بيرينجز",
                "label_fr": "Berrings"
            },
            {
                "value": 75377,
                "label": "Manorhamilton",
                "label_ar": "مانورهاميلتون",
                "label_fr": "Manorhamilton"
            },
            {
                "value": 75465,
                "label": "Letterkenny",
                "label_ar": "ليتيركيني",
                "label_fr": "Letterkenny"
            },
            {
                "value": 75650,
                "label": "Ballymun",
                "label_ar": "باليمون",
                "label_fr": "Ballymun"
            },
            {
                "value": 75469,
                "label": "Ballintober",
                "label_ar": "بالينتوبر",
                "label_fr": "Ballintober"
            },
            {
                "value": 75571,
                "label": "Glenealy",
                "label_ar": "جلينيلي",
                "label_fr": "Glenealy"
            },
            {
                "value": 75569,
                "label": "New Ross",
                "label_ar": "نيو روس",
                "label_fr": "Nouveau Ross"
            },
            {
                "value": 75645,
                "label": "Donnybrook",
                "label_ar": "دونيبروك",
                "label_fr": "Donnybrook"
            },
            {
                "value": 75526,
                "label": "Clogherhead",
                "label_ar": "كلوجيرهيد",
                "label_fr": "Clogherhead"
            },
            {
                "value": 75408,
                "label": "Dundrum",
                "label_ar": "حور",
                "label_fr": "Dundrum"
            },
            {
                "value": 75488,
                "label": "Rush",
                "label_ar": "سرعه",
                "label_fr": "se ruer"
            },
            {
                "value": 75588,
                "label": "Kiltamagh",
                "label_ar": "Kiltamagh",
                "label_fr": "Kiltamagh"
            },
            {
                "value": 75452,
                "label": "Ardee",
                "label_ar": "أردي",
                "label_fr": "Ardee"
            },
            {
                "value": 75501,
                "label": "Kilmichael",
                "label_ar": "كيلمايكل",
                "label_fr": "Kilmichael"
            },
            {
                "value": 75507,
                "label": "Glenties",
                "label_ar": "جلينتيس",
                "label_fr": "Glenties"
            },
            {
                "value": 75520,
                "label": "Boherbue",
                "label_ar": "بوهيربيو",
                "label_fr": "Boherbue"
            },
            {
                "value": 75386,
                "label": "Mullinavat",
                "label_ar": "مولينافات",
                "label_fr": "Mullinavat"
            },
            {
                "value": 75565,
                "label": "Shrule",
                "label_ar": "شرول",
                "label_fr": "Shrule"
            },
            {
                "value": 75387,
                "label": "Maynooth",
                "label_ar": "ماينوث",
                "label_fr": "Maynooth"
            },
            {
                "value": 75429,
                "label": "Killarney",
                "label_ar": "كيلارني",
                "label_fr": "Killarney"
            },
            {
                "value": 75631,
                "label": "Kilmore",
                "label_ar": "كيلمور",
                "label_fr": "Kilmore"
            },
            {
                "value": 75508,
                "label": "Killygordon",
                "label_ar": "كيليجوردون",
                "label_fr": "Killygordon"
            },
            {
                "value": 75542,
                "label": "Summerhill",
                "label_ar": "سمرهيل",
                "label_fr": "Summerhill"
            },
            {
                "value": 75463,
                "label": "Dungarvan",
                "label_ar": "دونجارفان",
                "label_fr": "Dungarvan"
            },
            {
                "value": 75515,
                "label": "Douglas",
                "label_ar": "دوغلاس",
                "label_fr": "Douglas"
            },
            {
                "value": 75633,
                "label": "Bagenalstown",
                "label_ar": "باغنالستاون",
                "label_fr": "Bagenalstown"
            },
            {
                "value": 75630,
                "label": "County Galway",
                "label_ar": "مقاطعة غالواي",
                "label_fr": "Comté de Galway"
            },
            {
                "value": 75407,
                "label": "Blackrock",
                "label_ar": "حجر أسود",
                "label_fr": "Roche noire"
            },
            {
                "value": 75525,
                "label": "Monkstown",
                "label_ar": "مونكستاون",
                "label_fr": "Monkstown"
            },
            {
                "value": 75430,
                "label": "Trim",
                "label_ar": "تقليم",
                "label_fr": "Réduire"
            },
            {
                "value": 75441,
                "label": "Drogheda",
                "label_ar": "دروغيدا",
                "label_fr": "Drogheda"
            },
            {
                "value": 75531,
                "label": "Stradbally",
                "label_ar": "سترادبالي",
                "label_fr": "Stradbally"
            },
            {
                "value": 75378,
                "label": "Kells",
                "label_ar": "كيلز",
                "label_fr": "Kells"
            },
            {
                "value": 75543,
                "label": "Ballymount",
                "label_ar": "باليمونت",
                "label_fr": "Ballymount"
            },
            {
                "value": 75522,
                "label": "Fermoy",
                "label_ar": "فيرمي",
                "label_fr": "Fermoy"
            },
            {
                "value": 75396,
                "label": "Malahide",
                "label_ar": "مالاهايد",
                "label_fr": "Malahide"
            },
            {
                "value": 75375,
                "label": "Cork",
                "label_ar": "كورك",
                "label_fr": "Liège"
            },
            {
                "value": 75572,
                "label": "Carrick-on-Suir",
                "label_ar": "كاريك أون سوير",
                "label_fr": "Carrick-on-Suir"
            },
            {
                "value": 75444,
                "label": "Ballsbridge",
                "label_ar": "بولسبريدج",
                "label_fr": "Ballsbridge"
            },
            {
                "value": 75478,
                "label": "Newport",
                "label_ar": "نيوبورت",
                "label_fr": "Newport"
            },
            {
                "value": 75369,
                "label": "Roscommon",
                "label_ar": "روسكومون",
                "label_fr": "Roscommon"
            },
            {
                "value": 75546,
                "label": "Enniscorthy",
                "label_ar": "إينيسكورثي",
                "label_fr": "Enniscorthy"
            },
            {
                "value": 75471,
                "label": "Cabra",
                "label_ar": "كابرا",
                "label_fr": "Cabra"
            },
            {
                "value": 75624,
                "label": "Williamstown",
                "label_ar": "وليامزتاون",
                "label_fr": "Williamstown"
            },
            {
                "value": 75431,
                "label": "Seafield",
                "label_ar": "البحر",
                "label_fr": "Seafield"
            },
            {
                "value": 75440,
                "label": "Crosshaven",
                "label_ar": "كروسهافن",
                "label_fr": "Crosshaven"
            },
            {
                "value": 75493,
                "label": "Rathmolyon",
                "label_ar": "راثموليون",
                "label_fr": "Rathmolyon"
            },
            {
                "value": 75576,
                "label": "Louth",
                "label_ar": "لاوث",
                "label_fr": "Louth"
            },
            {
                "value": 75585,
                "label": "Kilmainham",
                "label_ar": "كيلمينهام",
                "label_fr": "Kilmainham"
            },
            {
                "value": 75461,
                "label": "Athy",
                "label_ar": "آثي",
                "label_fr": "Athy"
            },
            {
                "value": 75419,
                "label": "Finglas",
                "label_ar": "الأصابع",
                "label_fr": "Finglas"
            },
            {
                "value": 75596,
                "label": "Grange",
                "label_ar": "جرانج",
                "label_fr": "Grange"
            },
            {
                "value": 75398,
                "label": "Saggart",
                "label_ar": "ساغارت",
                "label_fr": "Saggart"
            },
            {
                "value": 75549,
                "label": "Cashel",
                "label_ar": "كاشيل",
                "label_fr": "Cashel"
            },
            {
                "value": 75607,
                "label": "Rathowen",
                "label_ar": "راثوين",
                "label_fr": "Rathowen"
            },
            {
                "value": 75379,
                "label": "Listowel",
                "label_ar": "الاستماع",
                "label_fr": "Listowel"
            },
            {
                "value": 75655,
                "label": "Inchicore",
                "label_ar": "انشيكور",
                "label_fr": "Inchicore"
            },
            {
                "value": 75404,
                "label": "Kildare",
                "label_ar": "كيلدير",
                "label_fr": "Kildare"
            },
            {
                "value": 75658,
                "label": "Carrigtohill",
                "label_ar": "كاريجتوهيل",
                "label_fr": "Carrigtohill"
            }
        ]
    },
    {
        "country": "Croatia",
        "cities": [
            {
                "value": 75776,
                "label": "Pregrada",
                "label_ar": "بريجرادا",
                "label_fr": "Pregrada"
            },
            {
                "value": 75743,
                "label": "Kriz",
                "label_ar": "كريز",
                "label_fr": "Kriz"
            },
            {
                "value": 75674,
                "label": "Gornja Lomnica",
                "label_ar": "جورنجا لومنيكا",
                "label_fr": "Gornja Lomnica"
            },
            {
                "value": 75745,
                "label": "Kastel Gomilica",
                "label_ar": "كاستل جوميليكا",
                "label_fr": "Kastel Gomilica"
            },
            {
                "value": 75715,
                "label": "Bestovje",
                "label_ar": "Bestovje",
                "label_fr": "Bestovje"
            },
            {
                "value": 75684,
                "label": "Lupoglav",
                "label_ar": "لوبوغلاف",
                "label_fr": "Lupoglav"
            },
            {
                "value": 75675,
                "label": "Šibenik",
                "label_ar": "شيبينيك",
                "label_fr": "Šibenik"
            },
            {
                "value": 75785,
                "label": "Požega",
                "label_ar": "Požega",
                "label_fr": "Požega"
            },
            {
                "value": 75771,
                "label": "Varazdinske Toplice",
                "label_ar": "فارازدينسكي توبليس",
                "label_fr": "Varazdinske Toplice"
            },
            {
                "value": 75778,
                "label": "Donji Kraljevec",
                "label_ar": "دوني كرالجيفيك",
                "label_fr": "Donji Kraljevec"
            },
            {
                "value": 75664,
                "label": "Ivanec",
                "label_ar": "إيفانيك",
                "label_fr": "Ivanec"
            },
            {
                "value": 75693,
                "label": "Krk",
                "label_ar": "كرك",
                "label_fr": "Krk"
            },
            {
                "value": 75772,
                "label": "Mursko Sredisce",
                "label_ar": "Mursko Sredisce",
                "label_fr": "Mursko Sredisce"
            },
            {
                "value": 75740,
                "label": "Vardarac",
                "label_ar": "فارداراك",
                "label_fr": "Vardarac"
            },
            {
                "value": 75667,
                "label": "Varaždin",
                "label_ar": "فرائدين",
                "label_fr": "Varaždin"
            },
            {
                "value": 75688,
                "label": "Vukovar",
                "label_ar": "فوكوفار",
                "label_fr": "Vukovar"
            },
            {
                "value": 75673,
                "label": "Rijeka",
                "label_ar": "رييكا",
                "label_fr": "Rijeka"
            },
            {
                "value": 75719,
                "label": "Mlini",
                "label_ar": "مليني",
                "label_fr": "Mlini"
            },
            {
                "value": 75763,
                "label": "Novo Cice",
                "label_ar": "نوفو سيس",
                "label_fr": "Novo Cice"
            },
            {
                "value": 75770,
                "label": "Koprivnica",
                "label_ar": "كوبريفنيكا",
                "label_fr": "Koprivnica"
            },
            {
                "value": 75733,
                "label": "Lukavec",
                "label_ar": "لوكافيك",
                "label_fr": "Lukavec"
            },
            {
                "value": 75749,
                "label": "Krsan",
                "label_ar": "كرسان",
                "label_fr": "Krsan"
            },
            {
                "value": 75696,
                "label": "Trogir",
                "label_ar": "تروجير",
                "label_fr": "Trogir"
            },
            {
                "value": 75774,
                "label": "Desinic",
                "label_ar": "ديسينيك",
                "label_fr": "Desinic"
            },
            {
                "value": 75671,
                "label": "Sinj",
                "label_ar": "سنج",
                "label_fr": "Sinj"
            },
            {
                "value": 75722,
                "label": "Drniš",
                "label_ar": "درنش",
                "label_fr": "Drniš"
            },
            {
                "value": 75711,
                "label": "Krapina",
                "label_ar": "كرابينا",
                "label_fr": "Krapina"
            },
            {
                "value": 75751,
                "label": "Medulin",
                "label_ar": "ميدولين",
                "label_fr": "Medulin"
            },
            {
                "value": 75726,
                "label": "Opatija",
                "label_ar": "أوباتيا",
                "label_fr": "Opatija"
            },
            {
                "value": 75704,
                "label": "Makarska",
                "label_ar": "ماكارسكا",
                "label_fr": "Makarska"
            },
            {
                "value": 75718,
                "label": "Metković",
                "label_ar": "ميتكوفيتش",
                "label_fr": "Metković"
            },
            {
                "value": 75795,
                "label": "Bracevci",
                "label_ar": "براسيفتشي",
                "label_fr": "Bracevci"
            },
            {
                "value": 75758,
                "label": "Cabuna",
                "label_ar": "كابونا",
                "label_fr": "Cabuna"
            },
            {
                "value": 75724,
                "label": "Darda",
                "label_ar": "الدرداء",
                "label_fr": "Darda"
            },
            {
                "value": 75814,
                "label": "Donja Zelina",
                "label_ar": "دونجا زيلينا",
                "label_fr": "Donja Zelina"
            },
            {
                "value": 75737,
                "label": "Antunovac",
                "label_ar": "أنتونوفاك",
                "label_fr": "Antunovac"
            },
            {
                "value": 75734,
                "label": "Pula",
                "label_ar": "بولا",
                "label_fr": "Pula"
            },
            {
                "value": 75798,
                "label": "Slavonski Brod",
                "label_ar": "سلافونسكي برود",
                "label_fr": "Slavonski Brod"
            },
            {
                "value": 75687,
                "label": "Piskorevci",
                "label_ar": "بيسكورفيشي",
                "label_fr": "Piskorevci"
            },
            {
                "value": 75755,
                "label": "Karojba",
                "label_ar": "كاروجبا",
                "label_fr": "Karojba"
            },
            {
                "value": 75712,
                "label": "Vrbovec",
                "label_ar": "فربوفيك",
                "label_fr": "Vrbovec"
            },
            {
                "value": 75686,
                "label": "Đakovo",
                "label_ar": "شكوفو",
                "label_fr": "Đakovo"
            },
            {
                "value": 75727,
                "label": "Bibinje",
                "label_ar": "بيبيني",
                "label_fr": "Bibinje"
            },
            {
                "value": 75793,
                "label": "Sokolovac",
                "label_ar": "سوكولوفاك",
                "label_fr": "Sokolovac"
            },
            {
                "value": 75797,
                "label": "Orahovica",
                "label_ar": "أوراهوفيتسا",
                "label_fr": "Orahovica"
            },
            {
                "value": 75681,
                "label": "Simuni",
                "label_ar": "سيموني",
                "label_fr": "Simuni"
            },
            {
                "value": 75690,
                "label": "Nustar",
                "label_ar": "نوستار",
                "label_fr": "Nustar"
            },
            {
                "value": 75702,
                "label": "Matulji",
                "label_ar": "ماتولجي",
                "label_fr": "Matulji"
            },
            {
                "value": 75783,
                "label": "Strmec",
                "label_ar": "سترميك",
                "label_fr": "Strmec"
            },
            {
                "value": 75720,
                "label": "Postira",
                "label_ar": "بوستيرا",
                "label_fr": "Postira"
            },
            {
                "value": 75685,
                "label": "Županja",
                "label_ar": "زوبانيا",
                "label_fr": "Županja"
            },
            {
                "value": 75680,
                "label": "Zadar",
                "label_ar": "زادار",
                "label_fr": "Zadar"
            },
            {
                "value": 75700,
                "label": "Zapresic",
                "label_ar": "زابريسيك",
                "label_fr": "Zapresic"
            },
            {
                "value": 75767,
                "label": "Donja Pusca",
                "label_ar": "دونجا بوسكا",
                "label_fr": "Donja Pusca"
            },
            {
                "value": 75670,
                "label": "Vinkovci",
                "label_ar": "فينكوفسي",
                "label_fr": "Vinkovci"
            },
            {
                "value": 75672,
                "label": "Karlovac",
                "label_ar": "كارلوفاك",
                "label_fr": "Karlovac"
            },
            {
                "value": 75723,
                "label": "Privlaka",
                "label_ar": "بريفلاكا",
                "label_fr": "Privlaka"
            },
            {
                "value": 75735,
                "label": "Viskovci",
                "label_ar": "فيسكوفسي",
                "label_fr": "Viskovci"
            },
            {
                "value": 75802,
                "label": "Slatina",
                "label_ar": "سلاتينا",
                "label_fr": "Slatina"
            },
            {
                "value": 75781,
                "label": "Brdovec",
                "label_ar": "بردوفيتش",
                "label_fr": "Brdovec"
            },
            {
                "value": 75747,
                "label": "Crikvenica",
                "label_ar": "كريكفينيكا",
                "label_fr": "Crikvenica"
            },
            {
                "value": 75757,
                "label": "Fazana",
                "label_ar": "فزانا",
                "label_fr": "Fazana"
            },
            {
                "value": 75768,
                "label": "Gornji Stupnik",
                "label_ar": "جورني ستوبنيك",
                "label_fr": "Gornji Stupnik"
            },
            {
                "value": 75806,
                "label": "Vrata",
                "label_ar": "فراتا",
                "label_fr": "Vrata"
            },
            {
                "value": 75748,
                "label": "Pazin",
                "label_ar": "بازين",
                "label_fr": "Pazin"
            },
            {
                "value": 75744,
                "label": "Lupoglav",
                "label_ar": "لوبوغلاف",
                "label_fr": "Lupoglav"
            },
            {
                "value": 75701,
                "label": "Oroslavje",
                "label_ar": "أوروسلافجي",
                "label_fr": "Oroslavje"
            },
            {
                "value": 75713,
                "label": "Sesvete",
                "label_ar": "سيسفيت",
                "label_fr": "Sesvete"
            },
            {
                "value": 75695,
                "label": "Petrcane",
                "label_ar": "بيتركاني",
                "label_fr": "Petrcane"
            },
            {
                "value": 75760,
                "label": "Bizovac",
                "label_ar": "بيزوفاك",
                "label_fr": "Bizovac"
            },
            {
                "value": 75709,
                "label": "Petrinja",
                "label_ar": "بيترينجا",
                "label_fr": "Petrinja"
            },
            {
                "value": 75750,
                "label": "Umag",
                "label_ar": "اوماج",
                "label_fr": "Umag"
            },
            {
                "value": 75669,
                "label": "Glina",
                "label_ar": "جلينا",
                "label_fr": "Glina"
            },
            {
                "value": 75730,
                "label": "Fuzine",
                "label_ar": "فوزين",
                "label_fr": "Fuzine"
            },
            {
                "value": 75721,
                "label": "Primosten",
                "label_ar": "بريموستين",
                "label_fr": "Primosten"
            },
            {
                "value": 75694,
                "label": "Lovran",
                "label_ar": "لوفران",
                "label_fr": "Lovran"
            },
            {
                "value": 75699,
                "label": "Sisak",
                "label_ar": "سيساك",
                "label_fr": "Sisak"
            },
            {
                "value": 75717,
                "label": "Rijeka",
                "label_ar": "رييكا",
                "label_fr": "Rijeka"
            },
            {
                "value": 75811,
                "label": "Splitska",
                "label_ar": "سبليتسكا",
                "label_fr": "Splitska"
            },
            {
                "value": 75677,
                "label": "Novi Vinodolski",
                "label_ar": "نوفي فينودولسكي",
                "label_fr": "Novi Vinodolski"
            },
            {
                "value": 75800,
                "label": "Sice",
                "label_ar": "سايس",
                "label_fr": "Sice"
            },
            {
                "value": 75714,
                "label": "Popovaca",
                "label_ar": "بوبوفاكا",
                "label_fr": "Popovaca"
            },
            {
                "value": 75813,
                "label": "Lapad",
                "label_ar": "لاباد",
                "label_fr": "Lapad"
            },
            {
                "value": 75725,
                "label": "Samobor",
                "label_ar": "ساموبور",
                "label_fr": "Samobor"
            },
            {
                "value": 75775,
                "label": "Prelog",
                "label_ar": "مقدمة",
                "label_fr": "Prelog"
            },
            {
                "value": 75706,
                "label": "Baska Voda",
                "label_ar": "باسكا فودا",
                "label_fr": "Baska Voda"
            },
            {
                "value": 75698,
                "label": "Dugo Selo",
                "label_ar": "دوجو سيلو",
                "label_fr": "Dugo Selo"
            },
            {
                "value": 75759,
                "label": "Pakrac",
                "label_ar": "باكراك",
                "label_fr": "Pakrac"
            },
            {
                "value": 75773,
                "label": "Vinica",
                "label_ar": "فينيكا",
                "label_fr": "Vinica"
            },
            {
                "value": 75810,
                "label": "Virje",
                "label_ar": "فيرجي",
                "label_fr": "Virje"
            },
            {
                "value": 75707,
                "label": "Lekenik",
                "label_ar": "ليكينك",
                "label_fr": "Lekenik"
            },
            {
                "value": 75746,
                "label": "Kastel Luksic",
                "label_ar": "كاستل لوكسيتش",
                "label_fr": "Kastel Luksic"
            },
            {
                "value": 75807,
                "label": "Hreljin",
                "label_ar": "هيرلين",
                "label_fr": "Hreljin"
            },
            {
                "value": 75790,
                "label": "Vratisinec",
                "label_ar": "فراتيسينك",
                "label_fr": "Vratisinec"
            },
            {
                "value": 75782,
                "label": "Zabok",
                "label_ar": "زابوق",
                "label_fr": "Zabok"
            },
            {
                "value": 75803,
                "label": "Dalj",
                "label_ar": "دالج",
                "label_fr": "Dalj"
            },
            {
                "value": 75787,
                "label": "Belišće",
                "label_ar": "Beliše",
                "label_fr": "Belišće"
            },
            {
                "value": 75728,
                "label": "Podastrana",
                "label_ar": "بوداسترانا",
                "label_fr": "Podastrana"
            },
            {
                "value": 75710,
                "label": "Galgovo",
                "label_ar": "جالجوفو",
                "label_fr": "Galgovo"
            },
            {
                "value": 75784,
                "label": "Kraljevec na Sutli",
                "label_ar": "كرالجيفيك نا سوتلي",
                "label_fr": "Kraljevec na Sutli"
            },
            {
                "value": 75691,
                "label": "Delnice",
                "label_ar": "ديلنيتسي",
                "label_fr": "Delnice"
            },
            {
                "value": 75762,
                "label": "Ivanic-Grad",
                "label_ar": "إيفانيك جراد",
                "label_fr": "Ivanic-Grad"
            },
            {
                "value": 75705,
                "label": "Brela",
                "label_ar": "بريلا",
                "label_fr": "Brela"
            },
            {
                "value": 75808,
                "label": "Rakitje",
                "label_ar": "راكيتجي",
                "label_fr": "Rakitje"
            },
            {
                "value": 75796,
                "label": "Virovitica",
                "label_ar": "فيروفيتيكا",
                "label_fr": "Virovitica"
            },
            {
                "value": 75769,
                "label": "Bedekovcina",
                "label_ar": "Bedekovcina",
                "label_fr": "Bedekovcina"
            },
            {
                "value": 75780,
                "label": "Senkovec",
                "label_ar": "سينكوفيتش",
                "label_fr": "Senkovec"
            },
            {
                "value": 75732,
                "label": "Rovinj",
                "label_ar": "روفينج",
                "label_fr": "Rovinj"
            },
            {
                "value": 75765,
                "label": "Orle",
                "label_ar": "أورلي",
                "label_fr": "Orle"
            },
            {
                "value": 75742,
                "label": "Čakovec",
                "label_ar": "شاكوفيتش",
                "label_fr": "Čakovec"
            },
            {
                "value": 75799,
                "label": "Kutjevo",
                "label_ar": "Kutjevo",
                "label_fr": "Kutjevo"
            },
            {
                "value": 75663,
                "label": "Zagreb",
                "label_ar": "زغرب",
                "label_fr": "Zagreb"
            },
            {
                "value": 75703,
                "label": "Poreč",
                "label_ar": "بوريتش",
                "label_fr": "Poreč"
            },
            {
                "value": 75666,
                "label": "Labin",
                "label_ar": "لابين",
                "label_fr": "Labin"
            },
            {
                "value": 75761,
                "label": "Vojnic",
                "label_ar": "فوينيك",
                "label_fr": "Vojnic"
            },
            {
                "value": 75731,
                "label": "Orebic",
                "label_ar": "أوربيك",
                "label_fr": "Orebic"
            },
            {
                "value": 75786,
                "label": "Nova Gradiska",
                "label_ar": "نوفا غراديسكا",
                "label_fr": "Nova Gradiska"
            },
            {
                "value": 75678,
                "label": "Dubrovnik",
                "label_ar": "دوبروفنيك",
                "label_fr": "Dubrovnik"
            },
            {
                "value": 75679,
                "label": "Cavtat",
                "label_ar": "كافتات",
                "label_fr": "Cavtat"
            },
            {
                "value": 75779,
                "label": "Novi Marof",
                "label_ar": "نوفي معروف",
                "label_fr": "Novi Marof"
            },
            {
                "value": 75708,
                "label": "Strmec Samoborski",
                "label_ar": "سترميك ساموبورسكي",
                "label_fr": "Strmec Samoborski"
            },
            {
                "value": 75805,
                "label": "Sesvetski Kraljevec",
                "label_ar": "سيسفيتسكي كرالجيفيتش",
                "label_fr": "Sesvetski Kraljevec"
            },
            {
                "value": 75789,
                "label": "Cestica",
                "label_ar": "سيستيكا",
                "label_fr": "Cestica"
            },
            {
                "value": 75791,
                "label": "Lepoglava",
                "label_ar": "ليبوغلافا",
                "label_fr": "Lepoglava"
            },
            {
                "value": 75801,
                "label": "Vetovo",
                "label_ar": "فيتوفو",
                "label_fr": "Vetovo"
            },
            {
                "value": 75697,
                "label": "Betina",
                "label_ar": "بيتينا",
                "label_fr": "Betina"
            },
            {
                "value": 75753,
                "label": "Buje",
                "label_ar": "بوج",
                "label_fr": "Buje"
            },
            {
                "value": 75739,
                "label": "Silas",
                "label_ar": "سيلاس",
                "label_fr": "Silas"
            },
            {
                "value": 75766,
                "label": "Bregana",
                "label_ar": "برجانا",
                "label_fr": "Bregana"
            },
            {
                "value": 75754,
                "label": "Sapjane",
                "label_ar": "سابجان",
                "label_fr": "Sapjane"
            },
            {
                "value": 75792,
                "label": "Tuzno",
                "label_ar": "توزنو",
                "label_fr": "Tuzno"
            },
            {
                "value": 75662,
                "label": "Velika Gorica",
                "label_ar": "فيليكا جوريكا",
                "label_fr": "Velika Gorica"
            },
            {
                "value": 75788,
                "label": "Okrug Gornji",
                "label_ar": "أوكروج جورني",
                "label_fr": "Okrug Gornji"
            },
            {
                "value": 75736,
                "label": "Podgajci Posavski",
                "label_ar": "Podgajci Posavski",
                "label_fr": "Podgajci Posavski"
            },
            {
                "value": 75752,
                "label": "Icici",
                "label_ar": "إيسيسي",
                "label_fr": "Icici"
            },
            {
                "value": 75689,
                "label": "Brsadin",
                "label_ar": "برسادين",
                "label_fr": "Brsadin"
            },
            {
                "value": 75764,
                "label": "Sveti Ivan Zelina",
                "label_ar": "سفيتي إيفان زيلينا",
                "label_fr": "Sveti Ivan Zelina"
            },
            {
                "value": 75692,
                "label": "Vrbnik",
                "label_ar": "فربنيك",
                "label_fr": "Vrbnik"
            },
            {
                "value": 75665,
                "label": "Core",
                "label_ar": "النواة",
                "label_fr": "Coeur"
            },
            {
                "value": 75809,
                "label": "Gornji Kriz",
                "label_ar": "جورني كريز",
                "label_fr": "Gornji Kriz"
            },
            {
                "value": 75741,
                "label": "Solin",
                "label_ar": "سولين",
                "label_fr": "Solin"
            },
            {
                "value": 75812,
                "label": "Baska",
                "label_ar": "باسكا",
                "label_fr": "Baska"
            },
            {
                "value": 75804,
                "label": "Bjelovar",
                "label_ar": "بيلوفار",
                "label_fr": "Bjelovar"
            },
            {
                "value": 75716,
                "label": "Nedelisce",
                "label_ar": "Nedelisce",
                "label_fr": "Nedelisce"
            },
            {
                "value": 75683,
                "label": "Rab",
                "label_ar": "راب",
                "label_fr": "Rab"
            },
            {
                "value": 75729,
                "label": "Osijek",
                "label_ar": "أوسيجيك",
                "label_fr": "Osijek"
            },
            {
                "value": 75668,
                "label": "Split",
                "label_ar": "انشق، مزق",
                "label_fr": "Divisé"
            },
            {
                "value": 75676,
                "label": "Zlatar",
                "label_ar": "زلاتار",
                "label_fr": "Zlatar"
            },
            {
                "value": 75738,
                "label": "Cepin",
                "label_ar": "سيبين",
                "label_fr": "Cepin"
            },
            {
                "value": 75794,
                "label": "Kastel Sucurac",
                "label_ar": "كاستل سوكوراك",
                "label_fr": "Kastel Sucurac"
            },
            {
                "value": 75756,
                "label": "Kostrena",
                "label_ar": "كوسترينا",
                "label_fr": "Kostrena"
            },
            {
                "value": 75777,
                "label": "Torcec",
                "label_ar": "تورسيك",
                "label_fr": "Torcec"
            },
            {
                "value": 75682,
                "label": "Tar",
                "label_ar": "قطران",
                "label_fr": "Le goudron"
            }
        ]
    },
    {
        "country": "Bosnia and Herzegovina",
        "cities": [
            {
                "value": 75817,
                "label": "Mostar",
                "label_ar": "موستار",
                "label_fr": "Mostar"
            },
            {
                "value": 75826,
                "label": "Doboj",
                "label_ar": "دوبوج",
                "label_fr": "Doboj"
            },
            {
                "value": 75823,
                "label": "Bihać",
                "label_ar": "بيهاتش",
                "label_fr": "Bihać"
            },
            {
                "value": 75852,
                "label": "Novi Travnik",
                "label_ar": "نوفي ترافنيك",
                "label_fr": "Novi Travnik"
            },
            {
                "value": 75838,
                "label": "Zivinice",
                "label_ar": "زيفينيس",
                "label_fr": "Zivinice"
            },
            {
                "value": 75829,
                "label": "Srpska",
                "label_ar": "سربسكا",
                "label_fr": "Srpska"
            },
            {
                "value": 75854,
                "label": "Kiseljak",
                "label_ar": "كيسيلجاك",
                "label_fr": "Kiseljak"
            },
            {
                "value": 75849,
                "label": "Bugojno",
                "label_ar": "بوجوينو",
                "label_fr": "Bugojno"
            },
            {
                "value": 75855,
                "label": "Siroki Brijeg",
                "label_ar": "سيروكي برييج",
                "label_fr": "Siroki Brijeg"
            },
            {
                "value": 75824,
                "label": "Vogosca",
                "label_ar": "فوغوسكا",
                "label_fr": "Vogosca"
            },
            {
                "value": 75853,
                "label": "Vitez",
                "label_ar": "فيتيز",
                "label_fr": "Vitez"
            },
            {
                "value": 75834,
                "label": "Stup",
                "label_ar": "توقف",
                "label_fr": "Stupide"
            },
            {
                "value": 75818,
                "label": "Livno",
                "label_ar": "ليفنو",
                "label_fr": "Livno"
            },
            {
                "value": 75856,
                "label": "Ilidza",
                "label_ar": "إليدزا",
                "label_fr": "Ilidza"
            },
            {
                "value": 75820,
                "label": "Lukavac",
                "label_ar": "لوكافاك",
                "label_fr": "Lukavac"
            },
            {
                "value": 75816,
                "label": "Zenica",
                "label_ar": "زينيكا",
                "label_fr": "Zenica"
            },
            {
                "value": 75842,
                "label": "Bosanska Krupa",
                "label_ar": "بوسانسكا كروبا",
                "label_fr": "Bosanska Krupa"
            },
            {
                "value": 75843,
                "label": "Sanski Most",
                "label_ar": "سانسكي موست",
                "label_fr": "Sanski Most"
            },
            {
                "value": 75837,
                "label": "Bosanska Dubica",
                "label_ar": "بوسانسكا دوبيكا",
                "label_fr": "Bosanska Dubica"
            },
            {
                "value": 75836,
                "label": "Prijedor",
                "label_ar": "بريدور",
                "label_fr": "Prijedor"
            },
            {
                "value": 75815,
                "label": "Sarajevo",
                "label_ar": "سراييفو",
                "label_fr": "Sarajevo"
            },
            {
                "value": 75847,
                "label": "Zepce",
                "label_ar": "زيبسي",
                "label_fr": "Zepce"
            },
            {
                "value": 75841,
                "label": "Brcko",
                "label_ar": "برتشكو",
                "label_fr": "Brcko"
            },
            {
                "value": 75830,
                "label": "Banja Luka",
                "label_ar": "بانيا لوكا",
                "label_fr": "Banja Luka"
            },
            {
                "value": 75822,
                "label": "Jelah",
                "label_ar": "جيله",
                "label_fr": "Jelah"
            },
            {
                "value": 75833,
                "label": "Visoko",
                "label_ar": "فيسوكو",
                "label_fr": "Visoko"
            },
            {
                "value": 75828,
                "label": "Donja Mahala",
                "label_ar": "دونجا ماهالا",
                "label_fr": "Donja Mahala"
            },
            {
                "value": 75819,
                "label": "Ljubuski",
                "label_ar": "ليوبوسكي",
                "label_fr": "Ljubuski"
            },
            {
                "value": 75835,
                "label": "Lopare",
                "label_ar": "لوباري",
                "label_fr": "Lopare"
            },
            {
                "value": 75827,
                "label": "Banja",
                "label_ar": "بانيا",
                "label_fr": "Banja"
            },
            {
                "value": 75845,
                "label": "Odzak",
                "label_ar": "أودزاك",
                "label_fr": "Odzak"
            },
            {
                "value": 75839,
                "label": "Gracanica",
                "label_ar": "جراكنيكا",
                "label_fr": "Gracanica"
            },
            {
                "value": 75825,
                "label": "Bijeljina",
                "label_ar": "بيجلجينا",
                "label_fr": "Bijeljina"
            },
            {
                "value": 75831,
                "label": "Tesanj",
                "label_ar": "تيسانج",
                "label_fr": "Tesanj"
            },
            {
                "value": 75846,
                "label": "Modrica",
                "label_ar": "مودريكا",
                "label_fr": "Modrica"
            },
            {
                "value": 75840,
                "label": "Core",
                "label_ar": "النواة",
                "label_fr": "Coeur"
            },
            {
                "value": 75851,
                "label": "Travnik",
                "label_ar": "ترافنيك",
                "label_fr": "Travnik"
            },
            {
                "value": 75821,
                "label": "Tuzla",
                "label_ar": "توزلا",
                "label_fr": "Tuzla"
            },
            {
                "value": 75848,
                "label": "Goražde",
                "label_ar": "جورايد",
                "label_fr": "Goražde"
            },
            {
                "value": 75850,
                "label": "Jajce",
                "label_ar": "جاجس",
                "label_fr": "Jajce"
            },
            {
                "value": 75844,
                "label": "Cazin",
                "label_ar": "كازين",
                "label_fr": "Cazin"
            },
            {
                "value": 75832,
                "label": "Breza",
                "label_ar": "بريزا",
                "label_fr": "Breza"
            }
        ]
    },
    {
        "country": "Estonia",
        "cities": [
            {
                "value": 75892,
                "label": "Jüri",
                "label_ar": "يوري",
                "label_fr": "Jüri"
            },
            {
                "value": 75924,
                "label": "Tapa",
                "label_ar": "تابا",
                "label_fr": "Tapa"
            },
            {
                "value": 75893,
                "label": "Emmaste",
                "label_ar": "إيميست",
                "label_fr": "Emmaste"
            },
            {
                "value": 75937,
                "label": "Paikuse",
                "label_ar": "بايكوز",
                "label_fr": "Paikuse"
            },
            {
                "value": 75916,
                "label": "Tabivere",
                "label_ar": "تابيفير",
                "label_fr": "Tabivère"
            },
            {
                "value": 75935,
                "label": "Kilingi-Nõmme",
                "label_ar": "Kilingi-Nõmme",
                "label_fr": "Kilingi-Nõmme"
            },
            {
                "value": 75904,
                "label": "Ardu",
                "label_ar": "أردو",
                "label_fr": "Ardu"
            },
            {
                "value": 75875,
                "label": "Harku",
                "label_ar": "هاركو",
                "label_fr": "Harku"
            },
            {
                "value": 75932,
                "label": "Rakke",
                "label_ar": "Rakke",
                "label_fr": "Rakke"
            },
            {
                "value": 75909,
                "label": "Aaviku",
                "label_ar": "افيكو",
                "label_fr": "Aaviku"
            },
            {
                "value": 75872,
                "label": "Pärnu",
                "label_ar": "بارنو",
                "label_fr": "Pärnu"
            },
            {
                "value": 75912,
                "label": "Kuusalu",
                "label_ar": "كوسالو",
                "label_fr": "Kuusalu"
            },
            {
                "value": 75868,
                "label": "Kohtla-Järve",
                "label_ar": "كوتلا يارف",
                "label_fr": "Kohtla-Järve"
            },
            {
                "value": 75901,
                "label": "Venevere",
                "label_ar": "فينيفير",
                "label_fr": "Venevere"
            },
            {
                "value": 75877,
                "label": "Vara",
                "label_ar": "فارا",
                "label_fr": "Vara"
            },
            {
                "value": 75865,
                "label": "Keila",
                "label_ar": "كيلا",
                "label_fr": "Keila"
            },
            {
                "value": 75928,
                "label": "Ambla",
                "label_ar": "أمبلا",
                "label_fr": "Ambla"
            },
            {
                "value": 75936,
                "label": "Kärdla",
                "label_ar": "كاردلا",
                "label_fr": "Kärdla"
            },
            {
                "value": 75862,
                "label": "Võru",
                "label_ar": "Võru",
                "label_fr": "Võru"
            },
            {
                "value": 75869,
                "label": "Tartu",
                "label_ar": "تارتو",
                "label_fr": "Tartu"
            },
            {
                "value": 75876,
                "label": "Saku",
                "label_ar": "ساكو",
                "label_fr": "Saku"
            },
            {
                "value": 75914,
                "label": "Elva",
                "label_ar": "إيلفا",
                "label_fr": "Elva"
            },
            {
                "value": 75918,
                "label": "Rapla",
                "label_ar": "رابلا",
                "label_fr": "Rapla"
            },
            {
                "value": 75925,
                "label": "Kose",
                "label_ar": "كوز",
                "label_fr": "Kose"
            },
            {
                "value": 75858,
                "label": "Kuressaare",
                "label_ar": "كوريسار",
                "label_fr": "Kuressaare"
            },
            {
                "value": 75861,
                "label": "Põlva",
                "label_ar": "بيلفا",
                "label_fr": "Põlva"
            },
            {
                "value": 75874,
                "label": "Türi",
                "label_ar": "توري",
                "label_fr": "Türi"
            },
            {
                "value": 75920,
                "label": "Kiili",
                "label_ar": "كيلي",
                "label_fr": "Kiili"
            },
            {
                "value": 75888,
                "label": "Kaarma",
                "label_ar": "الكارما",
                "label_fr": "Kaarma"
            },
            {
                "value": 75880,
                "label": "Kohtla",
                "label_ar": "كوتلا",
                "label_fr": "Kohtla"
            },
            {
                "value": 75907,
                "label": "Antsla",
                "label_ar": "انتسلا",
                "label_fr": "Antsla"
            },
            {
                "value": 75857,
                "label": "Maardu",
                "label_ar": "ماردو",
                "label_fr": "Maardu"
            },
            {
                "value": 75864,
                "label": "Tabasalu",
                "label_ar": "تاباسالو",
                "label_fr": "Tabasalu"
            },
            {
                "value": 75900,
                "label": "Lubja",
                "label_ar": "لوبجا",
                "label_fr": "Lubja"
            },
            {
                "value": 75911,
                "label": "Kehra",
                "label_ar": "كهرا",
                "label_fr": "Kehra"
            },
            {
                "value": 75879,
                "label": "Kulna",
                "label_ar": "كولنا",
                "label_fr": "Kulna"
            },
            {
                "value": 75889,
                "label": "Paldiski",
                "label_ar": "بالديسكي",
                "label_fr": "Paldiski"
            },
            {
                "value": 75933,
                "label": "Avinurme",
                "label_ar": "أفينورم",
                "label_fr": "Avinurme"
            },
            {
                "value": 75896,
                "label": "Jõgeva",
                "label_ar": "Jõgeva",
                "label_fr": "Jõgeva"
            },
            {
                "value": 75884,
                "label": "Kiviõli",
                "label_ar": "كيفيتشلي",
                "label_fr": "Kiviõli"
            },
            {
                "value": 75881,
                "label": "Narva",
                "label_ar": "نارفا",
                "label_fr": "Narva"
            },
            {
                "value": 75903,
                "label": "Vaida",
                "label_ar": "فيدا",
                "label_fr": "Vaida"
            },
            {
                "value": 75878,
                "label": "Alliku",
                "label_ar": "اليكو",
                "label_fr": "Alliku"
            },
            {
                "value": 75866,
                "label": "Rakvere",
                "label_ar": "راكفير",
                "label_fr": "Rakvere"
            },
            {
                "value": 75930,
                "label": "Syare",
                "label_ar": "سياري",
                "label_fr": "Syare"
            },
            {
                "value": 75895,
                "label": "Peetri",
                "label_ar": "بيتري",
                "label_fr": "Peetri"
            },
            {
                "value": 75887,
                "label": "Voka",
                "label_ar": "فوكا",
                "label_fr": "Voka"
            },
            {
                "value": 75886,
                "label": "Toila",
                "label_ar": "تويلا",
                "label_fr": "Toila"
            },
            {
                "value": 75871,
                "label": "Viljandi",
                "label_ar": "فيلجاندي",
                "label_fr": "Viljandi"
            },
            {
                "value": 75863,
                "label": "Viimsi",
                "label_ar": "فيمسي",
                "label_fr": "Viimsi"
            },
            {
                "value": 75923,
                "label": "Somerpalu",
                "label_ar": "سومربالو",
                "label_fr": "Somerpalu"
            },
            {
                "value": 75873,
                "label": "Paide",
                "label_ar": "بايدي",
                "label_fr": "Paide"
            },
            {
                "value": 75926,
                "label": "Haage",
                "label_ar": "هاج",
                "label_fr": "Haage"
            },
            {
                "value": 75860,
                "label": "Laagri",
                "label_ar": "لاغري",
                "label_fr": "Laagri"
            },
            {
                "value": 75906,
                "label": "Otepaeae",
                "label_ar": "Otepaeae",
                "label_fr": "Otepaeae"
            },
            {
                "value": 75899,
                "label": "Haabneeme",
                "label_ar": "هابنيم",
                "label_fr": "Haabneeme"
            },
            {
                "value": 75891,
                "label": "Sindi",
                "label_ar": "سندي",
                "label_fr": "Sindi"
            },
            {
                "value": 75859,
                "label": "Tallinn",
                "label_ar": "تالين",
                "label_fr": "Tallinn"
            },
            {
                "value": 75905,
                "label": "Puhja",
                "label_ar": "Puhja",
                "label_fr": "Puhja"
            },
            {
                "value": 75882,
                "label": "Tamsalu",
                "label_ar": "تامسالو",
                "label_fr": "Tamsalu"
            },
            {
                "value": 75913,
                "label": "Loksa",
                "label_ar": "لوكسا",
                "label_fr": "Loksa"
            },
            {
                "value": 75921,
                "label": "Lahtse",
                "label_ar": "لاهس",
                "label_fr": "Lahtse"
            },
            {
                "value": 75929,
                "label": "Kadrina",
                "label_ar": "كادرينا",
                "label_fr": "Kadrina"
            },
            {
                "value": 75883,
                "label": "Aasmae",
                "label_ar": "اسماء",
                "label_fr": "Aasmae"
            },
            {
                "value": 75870,
                "label": "Jõhvi",
                "label_ar": "Jhvi",
                "label_fr": "Jõhvi"
            },
            {
                "value": 75885,
                "label": "Ahtma",
                "label_ar": "احتما",
                "label_fr": "Ahtma"
            },
            {
                "value": 75897,
                "label": "Haapsalu",
                "label_ar": "هابسالو",
                "label_fr": "Haapsalu"
            },
            {
                "value": 75867,
                "label": "Sillamäe",
                "label_ar": "سيلاما",
                "label_fr": "Sillamäe"
            },
            {
                "value": 75931,
                "label": "Harju",
                "label_ar": "هارجو",
                "label_fr": "Harju"
            },
            {
                "value": 75934,
                "label": "Halinga",
                "label_ar": "هالينجا",
                "label_fr": "Halinga"
            },
            {
                "value": 75910,
                "label": "Lepna",
                "label_ar": "لبنا",
                "label_fr": "Lepna"
            },
            {
                "value": 75898,
                "label": "Lagedi",
                "label_ar": "لاجيدي",
                "label_fr": "Lagedi"
            },
            {
                "value": 75908,
                "label": "Palivere",
                "label_ar": "باليفير",
                "label_fr": "Palivère"
            },
            {
                "value": 75917,
                "label": "Saue",
                "label_ar": "Saue",
                "label_fr": "Saue"
            },
            {
                "value": 75890,
                "label": "Loo",
                "label_ar": "مرحاض",
                "label_fr": "Toilette"
            },
            {
                "value": 75894,
                "label": "Aespa",
                "label_ar": "ايسبا",
                "label_fr": "Aespa"
            },
            {
                "value": 75902,
                "label": "Põltsamaa",
                "label_ar": "Põltsamaa",
                "label_fr": "Põltsamaa"
            },
            {
                "value": 75919,
                "label": "Kohila",
                "label_ar": "كوهيلا",
                "label_fr": "Kohila"
            },
            {
                "value": 75922,
                "label": "Muraste",
                "label_ar": "موراستي",
                "label_fr": "Muraste"
            },
            {
                "value": 75915,
                "label": "Valga",
                "label_ar": "فالجا",
                "label_fr": "Valga"
            },
            {
                "value": 75927,
                "label": "Jõelähtme Parish",
                "label_ar": "Jõelähtme أبرشية",
                "label_fr": "Paroisse de Jõelähtme"
            }
        ]
    },
    {
        "country": "Latvia",
        "cities": [
            {
                "value": 75980,
                "label": "Kandava",
                "label_ar": "كاندافا",
                "label_fr": "Kandava"
            },
            {
                "value": 76004,
                "label": "Durbe",
                "label_ar": "دوربي",
                "label_fr": "Durbe"
            },
            {
                "value": 75986,
                "label": "Talsi",
                "label_ar": "تالسي",
                "label_fr": "Talsi"
            },
            {
                "value": 76002,
                "label": "Malpils",
                "label_ar": "مالبيلس",
                "label_fr": "Malpils"
            },
            {
                "value": 75946,
                "label": "Jūrmala",
                "label_ar": "جيرمالا",
                "label_fr": "Jūrmala"
            },
            {
                "value": 75951,
                "label": "Ikšķile",
                "label_ar": "Ikšķile",
                "label_fr": "Ikšķile"
            },
            {
                "value": 75961,
                "label": "Pilsrundale",
                "label_ar": "بيلسروندال",
                "label_fr": "Pilsrundale"
            },
            {
                "value": 75975,
                "label": "Līvāni",
                "label_ar": "ليفاني",
                "label_fr": "Līvāni"
            },
            {
                "value": 75947,
                "label": "Preiļi",
                "label_ar": "بريتي",
                "label_fr": "Preiļi"
            },
            {
                "value": 75977,
                "label": "Stalgene",
                "label_ar": "ستالجين",
                "label_fr": "Stalgène"
            },
            {
                "value": 75938,
                "label": "Riga",
                "label_ar": "ريغا",
                "label_fr": "Riga"
            },
            {
                "value": 75995,
                "label": "Ludza",
                "label_ar": "لودزا",
                "label_fr": "Ludza"
            },
            {
                "value": 75987,
                "label": "Lapmezciems",
                "label_ar": "لابميزس",
                "label_fr": "Lapmezciems"
            },
            {
                "value": 75970,
                "label": "Marupe",
                "label_ar": "ماروبي",
                "label_fr": "Marupe"
            },
            {
                "value": 75994,
                "label": "Adazi",
                "label_ar": "أدازي",
                "label_fr": "Adazi"
            },
            {
                "value": 75990,
                "label": "Mazsalaca",
                "label_ar": "Mazsalaca",
                "label_fr": "Mazsalaca"
            },
            {
                "value": 75948,
                "label": "Aizkraukle",
                "label_ar": "Aizkraukle",
                "label_fr": "Aizkraukle"
            },
            {
                "value": 75945,
                "label": "Liepāja",
                "label_ar": "ليباجا",
                "label_fr": "Liepāja"
            },
            {
                "value": 75939,
                "label": "Centrs",
                "label_ar": "المراكز",
                "label_fr": "Centrs"
            },
            {
                "value": 75971,
                "label": "Valka",
                "label_ar": "فالكا",
                "label_fr": "Valka"
            },
            {
                "value": 75983,
                "label": "Malta",
                "label_ar": "مالطا",
                "label_fr": "Malte"
            },
            {
                "value": 75963,
                "label": "Ogre",
                "label_ar": "غول",
                "label_fr": "Ogre"
            },
            {
                "value": 75991,
                "label": "Kolka",
                "label_ar": "كولكا",
                "label_fr": "Kolka"
            },
            {
                "value": 75952,
                "label": "Daugavpils",
                "label_ar": "دوجافبيلز",
                "label_fr": "Daugavpils"
            },
            {
                "value": 75967,
                "label": "Saldus",
                "label_ar": "سالدوس",
                "label_fr": "Saldus"
            },
            {
                "value": 75944,
                "label": "Ventspils",
                "label_ar": "فنتسبيلز",
                "label_fr": "Ventspils"
            },
            {
                "value": 75954,
                "label": "Rēzekne",
                "label_ar": "روزكن",
                "label_fr": "Rēzekne"
            },
            {
                "value": 76000,
                "label": "Sunākste",
                "label_ar": "Sunākste",
                "label_fr": "Sunākste"
            },
            {
                "value": 75958,
                "label": "Liepa",
                "label_ar": "ليبا",
                "label_fr": "Liepa"
            },
            {
                "value": 75982,
                "label": "Carnikava",
                "label_ar": "كارنيكافا",
                "label_fr": "Carnikava"
            },
            {
                "value": 75997,
                "label": "Baldone",
                "label_ar": "بالدوني",
                "label_fr": "Baldone"
            },
            {
                "value": 75976,
                "label": "Zasa",
                "label_ar": "زاسا",
                "label_fr": "Zasa"
            },
            {
                "value": 75969,
                "label": "Jēkabpils",
                "label_ar": "Jēkabpils",
                "label_fr": "Jēkabpils"
            },
            {
                "value": 75966,
                "label": "Ilguciems",
                "label_ar": "Ilguciems",
                "label_fr": "Ilguciems"
            },
            {
                "value": 75942,
                "label": "Ozolnieki",
                "label_ar": "أوزولنيكي",
                "label_fr": "Ozolnieki"
            },
            {
                "value": 75956,
                "label": "Dobele",
                "label_ar": "دوبيل",
                "label_fr": "Dobele"
            },
            {
                "value": 75998,
                "label": "Limbaži",
                "label_ar": "Limbai",
                "label_fr": "Limbaži"
            },
            {
                "value": 75985,
                "label": "Varakļāni",
                "label_ar": "Varakļāni",
                "label_fr": "Varakļāni"
            },
            {
                "value": 75988,
                "label": "Smiltene",
                "label_ar": "سميلتين",
                "label_fr": "Smiltene"
            },
            {
                "value": 75999,
                "label": "Gulbene",
                "label_ar": "غولبين",
                "label_fr": "Gulbène"
            },
            {
                "value": 75957,
                "label": "Izvalta",
                "label_ar": "Izvalta",
                "label_fr": "Izvalta"
            },
            {
                "value": 75955,
                "label": "Cēsis",
                "label_ar": "سيسيس",
                "label_fr": "Cēsis"
            },
            {
                "value": 75974,
                "label": "Madona",
                "label_ar": "مادونا",
                "label_fr": "Madona"
            },
            {
                "value": 75989,
                "label": "Sigulda",
                "label_ar": "سيغولدا",
                "label_fr": "Sigulda"
            },
            {
                "value": 75992,
                "label": "Varsava",
                "label_ar": "فارسافا",
                "label_fr": "Varsava"
            },
            {
                "value": 75962,
                "label": "Bauska",
                "label_ar": "بوسكا",
                "label_fr": "Bauska"
            },
            {
                "value": 75996,
                "label": "Olaine",
                "label_ar": "اولين",
                "label_fr": "Olaine"
            },
            {
                "value": 75953,
                "label": "Agenskalns",
                "label_ar": "أجينسكالنس",
                "label_fr": "Agenskalns"
            },
            {
                "value": 75984,
                "label": "Zilupe",
                "label_ar": "زيلوبي",
                "label_fr": "Zilupe"
            },
            {
                "value": 75964,
                "label": "Lēdmane",
                "label_ar": "ليدمان",
                "label_fr": "Lēdmane"
            },
            {
                "value": 75973,
                "label": "Strenči",
                "label_ar": "ستريني",
                "label_fr": "Strenči"
            },
            {
                "value": 75943,
                "label": "Priedkalne",
                "label_ar": "بريدكالن",
                "label_fr": "Priedkalne"
            },
            {
                "value": 75978,
                "label": "Pure",
                "label_ar": "نقي",
                "label_fr": "Pur"
            },
            {
                "value": 75968,
                "label": "Kuldīga",
                "label_ar": "كولديجا",
                "label_fr": "Kuldīga"
            },
            {
                "value": 75993,
                "label": "Jaunolaine",
                "label_ar": "Jaunolaine",
                "label_fr": "Jaunolaine"
            },
            {
                "value": 75940,
                "label": "Salaspils",
                "label_ar": "سالاسبلس",
                "label_fr": "Salaspils"
            },
            {
                "value": 75960,
                "label": "Alūksne",
                "label_ar": "أليكسن",
                "label_fr": "Alūksne"
            },
            {
                "value": 75981,
                "label": "Ķekava",
                "label_ar": "Ķekava",
                "label_fr": "Ķekava"
            },
            {
                "value": 75959,
                "label": "Valmiera",
                "label_ar": "فالميرا",
                "label_fr": "Valmiera"
            },
            {
                "value": 76003,
                "label": "Brankas",
                "label_ar": "برانكاس",
                "label_fr": "Brankas"
            },
            {
                "value": 75965,
                "label": "Ķegums",
                "label_ar": "Ķegums",
                "label_fr": "Ķegums"
            },
            {
                "value": 75949,
                "label": "Ropaži",
                "label_ar": "روباتي",
                "label_fr": "Ropaži"
            },
            {
                "value": 75979,
                "label": "Aizpute",
                "label_ar": "ايزبوت",
                "label_fr": "Aizpute"
            },
            {
                "value": 75972,
                "label": "Balvi",
                "label_ar": "بالفي",
                "label_fr": "Balvi"
            },
            {
                "value": 75950,
                "label": "Tukums",
                "label_ar": "توكومس",
                "label_fr": "Tukums"
            },
            {
                "value": 76001,
                "label": "Viesīte",
                "label_ar": "فييسيت",
                "label_fr": "Viesīte"
            },
            {
                "value": 75941,
                "label": "Jelgava",
                "label_ar": "جيلجافا",
                "label_fr": "Jelgava"
            }
        ]
    },
    {
        "country": "Hashemite Kingdom of Jordan",
        "cities": [
            {
                "value": 76008,
                "label": "Sahab",
                "label_ar": "سحاب",
                "label_fr": "Sahab"
            },
            {
                "value": 76011,
                "label": "Ar Ramtha",
                "label_ar": "الرمثا",
                "label_fr": "Ar Ramtha"
            },
            {
                "value": 76010,
                "label": "Salt",
                "label_ar": "ملح",
                "label_fr": "Sel"
            },
            {
                "value": 76009,
                "label": "Madaba",
                "label_ar": "مادبا",
                "label_fr": "Madaba"
            },
            {
                "value": 76006,
                "label": "Irbid",
                "label_ar": "اربد",
                "label_fr": "Irbid"
            },
            {
                "value": 76005,
                "label": "Amman",
                "label_ar": "عمان",
                "label_fr": "Amman"
            },
            {
                "value": 76007,
                "label": "Farah",
                "label_ar": "فرح",
                "label_fr": "Farah"
            }
        ]
    },
    {
        "country": "Kyrgyzstan",
        "cities": [
            {
                "value": 76012,
                "label": "Bishkek",
                "label_ar": "بيشكيك",
                "label_fr": "Bichkek"
            }
        ]
    },
    {
        "country": "Réunion",
        "cities": [
            {
                "value": 76029,
                "label": "Les Avirons",
                "label_ar": "ليه أفيرون",
                "label_fr": "Les Avirons"
            },
            {
                "value": 76030,
                "label": "La Saline",
                "label_ar": "لا سالين",
                "label_fr": "La Saline"
            },
            {
                "value": 76014,
                "label": "Le Tampon",
                "label_ar": "لو تامبون",
                "label_fr": "Le Tampon"
            },
            {
                "value": 76031,
                "label": "Etang-Sale les Bains",
                "label_ar": "Etang-Sale les Bains",
                "label_fr": "Etang-Sale les Bains"
            },
            {
                "value": 76035,
                "label": "Piton",
                "label_ar": "بيتون",
                "label_fr": "Piton"
            },
            {
                "value": 76034,
                "label": "Entre-Deux",
                "label_ar": "انتري دوكس",
                "label_fr": "Entre-Deux"
            },
            {
                "value": 76013,
                "label": "Saint-Joseph",
                "label_ar": "القديس يوسف",
                "label_fr": "Saint Joseph"
            },
            {
                "value": 76036,
                "label": "Saint-Benoit",
                "label_ar": "سان بينوا",
                "label_fr": "Saint-Benoît"
            },
            {
                "value": 76015,
                "label": "Saint-Andre",
                "label_ar": "سانت اندريه",
                "label_fr": "Saint-André"
            },
            {
                "value": 76027,
                "label": "Petite-Ile",
                "label_ar": "صغيرتي إيل",
                "label_fr": "Petite-Ile"
            },
            {
                "value": 76023,
                "label": "Saint-Gilles-les Bains",
                "label_ar": "سان جيل ليه با",
                "label_fr": "Saint-Gilles-les Bains"
            },
            {
                "value": 76033,
                "label": "La Chaloupe Saint-Leu",
                "label_ar": "لا تشالوب سان لو",
                "label_fr": "La Chaloupe Saint-Leu"
            },
            {
                "value": 76022,
                "label": "Ravine des Cabris",
                "label_ar": "رافين دي كابريس",
                "label_fr": "Ravin des Cabris"
            },
            {
                "value": 76021,
                "label": "Sainte-Suzanne",
                "label_ar": "سانت سوزان",
                "label_fr": "Sainte-Suzanne"
            },
            {
                "value": 76017,
                "label": "Saint-Paul",
                "label_ar": "القديس بول",
                "label_fr": "Saint Paul"
            },
            {
                "value": 76018,
                "label": "La Possession",
                "label_ar": "لا بوسيشن",
                "label_fr": "La Possession"
            },
            {
                "value": 76019,
                "label": "Le Port",
                "label_ar": "لو بورت",
                "label_fr": "Le Port"
            },
            {
                "value": 76020,
                "label": "Le Petit Tampon",
                "label_ar": "لو بيتيت تامبون",
                "label_fr": "Le Petit Tampon"
            },
            {
                "value": 76028,
                "label": "La Riviere",
                "label_ar": "لا ريفيير",
                "label_fr": "La Rivière"
            },
            {
                "value": 76026,
                "label": "Sainte-Marie",
                "label_ar": "سانت ماري",
                "label_fr": "Sainte-Marie"
            },
            {
                "value": 76016,
                "label": "Saint-Piorre",
                "label_ar": "سان بيوري",
                "label_fr": "Saint-Piorre"
            },
            {
                "value": 76032,
                "label": "Saint-Louis",
                "label_ar": "سانت لويس",
                "label_fr": "Saint Louis"
            },
            {
                "value": 76025,
                "label": "Saint-Denis",
                "label_ar": "سان دوني",
                "label_fr": "Saint-Denis"
            },
            {
                "value": 76024,
                "label": "Sainte-Clotilde",
                "label_ar": "سانت كلوتيلد",
                "label_fr": "Sainte-Clotilde"
            }
        ]
    },
    {
        "country": "Isle of Man",
        "cities": [
            {
                "value": 76045,
                "label": "Foxdale",
                "label_ar": "فوكسديل",
                "label_fr": "Foxdale"
            },
            {
                "value": 76039,
                "label": "Ballasalla",
                "label_ar": "بلاصلة",
                "label_fr": "Ballasalla"
            },
            {
                "value": 76042,
                "label": "Ramsey",
                "label_ar": "رامسي",
                "label_fr": "Ramsey"
            },
            {
                "value": 76048,
                "label": "Crosby",
                "label_ar": "كروسبي",
                "label_fr": "Crosby"
            },
            {
                "value": 76044,
                "label": "Dalby",
                "label_ar": "دالبي",
                "label_fr": "Dalby"
            },
            {
                "value": 76041,
                "label": "Port Erin",
                "label_ar": "بورت ايرين",
                "label_fr": "Port Erin"
            },
            {
                "value": 76047,
                "label": "Port Saint Mary",
                "label_ar": "ميناء سانت ماري",
                "label_fr": "Port Saint Mary"
            },
            {
                "value": 76043,
                "label": "Laxey",
                "label_ar": "لاكسي",
                "label_fr": "Laxey"
            },
            {
                "value": 76037,
                "label": "Douglas",
                "label_ar": "دوغلاس",
                "label_fr": "Douglas"
            },
            {
                "value": 76049,
                "label": "Saint Johns",
                "label_ar": "سانت جونز",
                "label_fr": "Saint Johns"
            },
            {
                "value": 76040,
                "label": "Castletown",
                "label_ar": "كاسلتاون",
                "label_fr": "Castletown"
            },
            {
                "value": 76046,
                "label": "Peel",
                "label_ar": "قشر",
                "label_fr": "Peler"
            },
            {
                "value": 76038,
                "label": "Onchan",
                "label_ar": "اونشان",
                "label_fr": "Onchan"
            }
        ]
    },
    {
        "country": "Libya",
        "cities": [
            {
                "value": 76054,
                "label": "Sabha",
                "label_ar": "سبها",
                "label_fr": "Sabha"
            },
            {
                "value": 76051,
                "label": "Benghazi",
                "label_ar": "بنغازي",
                "label_fr": "Benghazi"
            },
            {
                "value": 76053,
                "label": "Zliten",
                "label_ar": "زليتن",
                "label_fr": "Zliten"
            },
            {
                "value": 76050,
                "label": "Tripoli",
                "label_ar": "طرابلس",
                "label_fr": "Tripoli"
            },
            {
                "value": 76052,
                "label": "Misratah",
                "label_ar": "مصراتة",
                "label_fr": "Misratah"
            }
        ]
    },
    {
        "country": "Luxembourg",
        "cities": [
            {
                "value": 76133,
                "label": "Gostingen",
                "label_ar": "غوستينغن",
                "label_fr": "Gostingen"
            },
            {
                "value": 76067,
                "label": "Sandweiler",
                "label_ar": "ساندويلر",
                "label_fr": "Sandweiler"
            },
            {
                "value": 76083,
                "label": "Hesperange",
                "label_ar": "هيسبيرانج",
                "label_fr": "Hesperange"
            },
            {
                "value": 76091,
                "label": "Gonderange",
                "label_ar": "جوندرانج",
                "label_fr": "Gonderange"
            },
            {
                "value": 76153,
                "label": "Schoos",
                "label_ar": "المدارس",
                "label_fr": "Schoos"
            },
            {
                "value": 76152,
                "label": "Mondorf-les-Bains",
                "label_ar": "موندورف ليه با",
                "label_fr": "Mondorf-les-Bains"
            },
            {
                "value": 76125,
                "label": "Mertert",
                "label_ar": "Mertert",
                "label_fr": "Mertert"
            },
            {
                "value": 76101,
                "label": "Dippach",
                "label_ar": "ديباخ",
                "label_fr": "Dippach"
            },
            {
                "value": 76103,
                "label": "Oberanven",
                "label_ar": "أوبيرانفين",
                "label_fr": "Oberanven"
            },
            {
                "value": 76120,
                "label": "Olm",
                "label_ar": "أولم",
                "label_fr": "Olm"
            },
            {
                "value": 76099,
                "label": "Bofferdange",
                "label_ar": "بوفيردانجي",
                "label_fr": "Bofferdange"
            },
            {
                "value": 76106,
                "label": "Differdange",
                "label_ar": "ديفردانجي",
                "label_fr": "Differdange"
            },
            {
                "value": 76151,
                "label": "Mamer",
                "label_ar": "مامر",
                "label_fr": "Mamer"
            },
            {
                "value": 76118,
                "label": "Moutfort",
                "label_ar": "موتفورت",
                "label_fr": "Moutfort"
            },
            {
                "value": 76124,
                "label": "Niedercorn",
                "label_ar": "نيدركورن",
                "label_fr": "Niedercorn"
            },
            {
                "value": 76078,
                "label": "Luxembourg-Kirchberg",
                "label_ar": "لوكسمبورغ كيرشبرغ",
                "label_fr": "Luxembourg-Kirchberg"
            },
            {
                "value": 76129,
                "label": "Wasserbillig",
                "label_ar": "Wasserbillig",
                "label_fr": "Wasserbillig"
            },
            {
                "value": 76098,
                "label": "Berchem",
                "label_ar": "بيركيم",
                "label_fr": "Berchem"
            },
            {
                "value": 76090,
                "label": "Merl",
                "label_ar": "ميرل",
                "label_fr": "Merl"
            },
            {
                "value": 76126,
                "label": "Erpeldange-lez-Bous",
                "label_ar": "Erpeldange-lez-Bous",
                "label_fr": "Erpeldange-lez-Bous"
            },
            {
                "value": 76080,
                "label": "Esch-sur-Alzette",
                "label_ar": "ايش سور الزيت",
                "label_fr": "Esch-sur-Alzette"
            },
            {
                "value": 76107,
                "label": "Grevenknapp",
                "label_ar": "جريفينناب",
                "label_fr": "Grevenknapp"
            },
            {
                "value": 76137,
                "label": "Schouweiler",
                "label_ar": "شويلر",
                "label_fr": "Schouweiler"
            },
            {
                "value": 76089,
                "label": "Grosbous",
                "label_ar": "جروسبوس",
                "label_fr": "Grosbous"
            },
            {
                "value": 76146,
                "label": "Limpertsberg",
                "label_ar": "ليمبيرتسبيرج",
                "label_fr": "Limpertsberg"
            },
            {
                "value": 76077,
                "label": "Ernster",
                "label_ar": "ارنستر",
                "label_fr": "Ernster"
            },
            {
                "value": 76085,
                "label": "Goetzingen",
                "label_ar": "جويتزينجن",
                "label_fr": "Goetzingen"
            },
            {
                "value": 76074,
                "label": "Alzingen",
                "label_ar": "Alzingen",
                "label_fr": "Alzingen"
            },
            {
                "value": 76057,
                "label": "Itzig",
                "label_ar": "ايتزيغ",
                "label_fr": "Itzig"
            },
            {
                "value": 76081,
                "label": "Lamadelaine",
                "label_ar": "لاماديلين",
                "label_fr": "Lamadelaine"
            },
            {
                "value": 76164,
                "label": "Frisange",
                "label_ar": "فريسانج",
                "label_fr": "Frisange"
            },
            {
                "value": 76070,
                "label": "Strassen",
                "label_ar": "ستراسن",
                "label_fr": "Strassen"
            },
            {
                "value": 76150,
                "label": "Kehlen",
                "label_ar": "كيهلين",
                "label_fr": "Kehlen"
            },
            {
                "value": 76145,
                "label": "Colmar",
                "label_ar": "كولمار",
                "label_fr": "Colmar"
            },
            {
                "value": 76117,
                "label": "Hersberg",
                "label_ar": "هيرسبيرج",
                "label_fr": "Hersberg"
            },
            {
                "value": 76111,
                "label": "Crauthem",
                "label_ar": "كراوثيم",
                "label_fr": "Crauthem"
            },
            {
                "value": 76112,
                "label": "Beaufort",
                "label_ar": "بوفورت",
                "label_fr": "Beaufort"
            },
            {
                "value": 76154,
                "label": "Beidweiler",
                "label_ar": "بيدويلر",
                "label_fr": "Beidweiler"
            },
            {
                "value": 76116,
                "label": "Lintgen",
                "label_ar": "لينتجن",
                "label_fr": "Lintgen"
            },
            {
                "value": 76147,
                "label": "Larochette",
                "label_ar": "لاروشيت",
                "label_fr": "Larochette"
            },
            {
                "value": 76128,
                "label": "Canach",
                "label_ar": "كاناش",
                "label_fr": "Canach"
            },
            {
                "value": 76171,
                "label": "Remich",
                "label_ar": "ريميش",
                "label_fr": "Remich"
            },
            {
                "value": 76064,
                "label": "Hunsdorf",
                "label_ar": "Hunsdorf",
                "label_fr": "Hunsdorf"
            },
            {
                "value": 76142,
                "label": "Foetz",
                "label_ar": "فوتز",
                "label_fr": "Foetz"
            },
            {
                "value": 76131,
                "label": "Remerschen",
                "label_ar": "Remerschen",
                "label_fr": "Remerschen"
            },
            {
                "value": 76121,
                "label": "Tuntange",
                "label_ar": "تونتانج",
                "label_fr": "Tuntange"
            },
            {
                "value": 76123,
                "label": "Koetschette",
                "label_ar": "Koetschette",
                "label_fr": "Koetschette"
            },
            {
                "value": 76114,
                "label": "Heisdorf-sur-Alzette",
                "label_ar": "Heisdorf-sur-Alzette",
                "label_fr": "Heisdorf-sur-Alzette"
            },
            {
                "value": 76063,
                "label": "Luxembourg",
                "label_ar": "لوكسمبورغ",
                "label_fr": "Luxembourg"
            },
            {
                "value": 76165,
                "label": "Noerdange",
                "label_ar": "نوردانج",
                "label_fr": "Noerdange"
            },
            {
                "value": 76140,
                "label": "Kleinbettingen",
                "label_ar": "كلاينبيتينجن",
                "label_fr": "Kleinbettingen"
            },
            {
                "value": 76061,
                "label": "Belvaux",
                "label_ar": "بيلفو",
                "label_fr": "Belvaux"
            },
            {
                "value": 76068,
                "label": "Beggen",
                "label_ar": "بيجن",
                "label_fr": "Beggen"
            },
            {
                "value": 76148,
                "label": "Hollerich",
                "label_ar": "هوليريش",
                "label_fr": "Hollerich"
            },
            {
                "value": 76073,
                "label": "Bereldange",
                "label_ar": "بيرلدانج",
                "label_fr": "Bereldange"
            },
            {
                "value": 76062,
                "label": "Bettembourg",
                "label_ar": "بيتمبورغ",
                "label_fr": "Bettembourg"
            },
            {
                "value": 76108,
                "label": "Schieren",
                "label_ar": "شيرين",
                "label_fr": "Schieren"
            },
            {
                "value": 76161,
                "label": "Roeser",
                "label_ar": "مسبحة",
                "label_fr": "Roeser"
            },
            {
                "value": 76100,
                "label": "Mondercange",
                "label_ar": "مونديركانج",
                "label_fr": "Mondercange"
            },
            {
                "value": 76168,
                "label": "Esch-sur-Sure",
                "label_ar": "ايش سور بالتأكيد",
                "label_fr": "Esch-sur-Sûr"
            },
            {
                "value": 76113,
                "label": "Syren",
                "label_ar": "سيرين",
                "label_fr": "Syren"
            },
            {
                "value": 76141,
                "label": "Bonnevoie",
                "label_ar": "Bonnevoie",
                "label_fr": "Bonnevoie"
            },
            {
                "value": 76105,
                "label": "Grevenmacher",
                "label_ar": "جريفينماتشر",
                "label_fr": "Grevenmacher"
            },
            {
                "value": 76139,
                "label": "Ahn",
                "label_ar": "اهن",
                "label_fr": "Ahn"
            },
            {
                "value": 76056,
                "label": "Bascharage",
                "label_ar": "بشراج",
                "label_fr": "Bascharage"
            },
            {
                "value": 76072,
                "label": "Mersch",
                "label_ar": "ميرش",
                "label_fr": "Mersch"
            },
            {
                "value": 76149,
                "label": "Hagen",
                "label_ar": "هاغن",
                "label_fr": "Hagen"
            },
            {
                "value": 76094,
                "label": "Bridel",
                "label_ar": "برايدل",
                "label_fr": "Bridel"
            },
            {
                "value": 76097,
                "label": "Kayl",
                "label_ar": "كيل",
                "label_fr": "Kayl"
            },
            {
                "value": 76087,
                "label": "Gosseldange",
                "label_ar": "جوسيلدانج",
                "label_fr": "Gosseldange"
            },
            {
                "value": 76092,
                "label": "Ettelbruck",
                "label_ar": "إتيلبروك",
                "label_fr": "Ettelbruck"
            },
            {
                "value": 76159,
                "label": "Bivange",
                "label_ar": "بيفانج",
                "label_fr": "Bivange"
            },
            {
                "value": 76071,
                "label": "Contern",
                "label_ar": "كونترن",
                "label_fr": "Contern"
            },
            {
                "value": 76095,
                "label": "Rodange",
                "label_ar": "رودانج",
                "label_fr": "Rodange"
            },
            {
                "value": 76144,
                "label": "Steinfort",
                "label_ar": "شتاينفورت",
                "label_fr": "Steinfort"
            },
            {
                "value": 76122,
                "label": "Bissen",
                "label_ar": "بيسن",
                "label_fr": "Bissen"
            },
            {
                "value": 76127,
                "label": "Ehnen",
                "label_ar": "اهنين",
                "label_fr": "Ehnen"
            },
            {
                "value": 76058,
                "label": "Dudelange",
                "label_ar": "دوديلانج",
                "label_fr": "Dudelange"
            },
            {
                "value": 76076,
                "label": "Leudelange",
                "label_ar": "ليودلانج",
                "label_fr": "Leudelange"
            },
            {
                "value": 76082,
                "label": "Rumelange",
                "label_ar": "روميلانج",
                "label_fr": "Rumelange"
            },
            {
                "value": 76134,
                "label": "Bourglinster",
                "label_ar": "بورجلينستر",
                "label_fr": "Bourglinster"
            },
            {
                "value": 76135,
                "label": "Bergem",
                "label_ar": "برجيم",
                "label_fr": "Bergem"
            },
            {
                "value": 76138,
                "label": "Capellen",
                "label_ar": "كابلين",
                "label_fr": "Capellen"
            },
            {
                "value": 76093,
                "label": "Huncherange",
                "label_ar": "هانشرانج",
                "label_fr": "Huncherange"
            },
            {
                "value": 76096,
                "label": "Hautcharage",
                "label_ar": "Hautcharage",
                "label_fr": "Hautcharage"
            },
            {
                "value": 76109,
                "label": "Pontpierre",
                "label_ar": "بونتبيير",
                "label_fr": "Pontpierre"
            },
            {
                "value": 76104,
                "label": "Pétange",
                "label_ar": "بيتانج",
                "label_fr": "Pétange"
            },
            {
                "value": 76160,
                "label": "Godbrange",
                "label_ar": "جودبرانج",
                "label_fr": "Godbrange"
            },
            {
                "value": 76155,
                "label": "Oetrange",
                "label_ar": "أوترانج",
                "label_fr": "Oetrange"
            },
            {
                "value": 76162,
                "label": "Cap",
                "label_ar": "قبعة",
                "label_fr": "Casquette"
            },
            {
                "value": 76084,
                "label": "Medernach",
                "label_ar": "ميدرناخ",
                "label_fr": "Medernach"
            },
            {
                "value": 76075,
                "label": "Holzem",
                "label_ar": "هولزيم",
                "label_fr": "Holzem"
            },
            {
                "value": 76166,
                "label": "Beckerich",
                "label_ar": "بيكريش",
                "label_fr": "Beckerich"
            },
            {
                "value": 76119,
                "label": "Uebersyren",
                "label_ar": "أوبيرسيرين",
                "label_fr": "Uebersyren"
            },
            {
                "value": 76110,
                "label": "Sanem",
                "label_ar": "سانيم",
                "label_fr": "Sanem"
            },
            {
                "value": 76086,
                "label": "Nospelt",
                "label_ar": "Nospelt",
                "label_fr": "Nospelt"
            },
            {
                "value": 76156,
                "label": "Lorentzweiler",
                "label_ar": "لورنتزويلر",
                "label_fr": "Lorentzweiler"
            },
            {
                "value": 76143,
                "label": "Walferdange",
                "label_ar": "Walferdange",
                "label_fr": "Walferdange"
            },
            {
                "value": 76158,
                "label": "Dalheim",
                "label_ar": "دالهايم",
                "label_fr": "Dalheim"
            },
            {
                "value": 76169,
                "label": "Betzdorf",
                "label_ar": "بيتزدورف",
                "label_fr": "Betzdorf"
            },
            {
                "value": 76163,
                "label": "Roodt-sur-Syre",
                "label_ar": "رودت سور سير",
                "label_fr": "Roodt-sur-Syre"
            },
            {
                "value": 76170,
                "label": "Schuttrange",
                "label_ar": "شوترانج",
                "label_fr": "Schuttrange"
            },
            {
                "value": 76130,
                "label": "Wormeldange",
                "label_ar": "ورميلدانج",
                "label_fr": "Wormeldange"
            },
            {
                "value": 76065,
                "label": "Junglinster",
                "label_ar": "جنغلينستر",
                "label_fr": "Junglinster"
            },
            {
                "value": 76059,
                "label": "Helmsange",
                "label_ar": "هيلمسانج",
                "label_fr": "Helmsange"
            },
            {
                "value": 76157,
                "label": "Wolpert",
                "label_ar": "وولبرت",
                "label_fr": "Wolpert"
            },
            {
                "value": 76088,
                "label": "Schifflange",
                "label_ar": "شيفلانج",
                "label_fr": "Schifflange"
            },
            {
                "value": 76069,
                "label": "Heffingen",
                "label_ar": "هيفينغن",
                "label_fr": "Heffingen"
            },
            {
                "value": 76167,
                "label": "Echternach",
                "label_ar": "اشترناخ",
                "label_fr": "Echternach"
            },
            {
                "value": 76115,
                "label": "Helmdange",
                "label_ar": "هيلمدانج",
                "label_fr": "Helmdange"
            },
            {
                "value": 76132,
                "label": "Bigonville",
                "label_ar": "بيجونفيل",
                "label_fr": "Bigonville"
            },
            {
                "value": 76102,
                "label": "Burmerange",
                "label_ar": "بورميرانج",
                "label_fr": "Burmerange"
            },
            {
                "value": 76066,
                "label": "Bertrange",
                "label_ar": "بيرترانج",
                "label_fr": "Bertrange"
            },
            {
                "value": 76136,
                "label": "Hobscheid",
                "label_ar": "هوبشايد",
                "label_fr": "Hobscheid"
            },
            {
                "value": 76055,
                "label": "Steinsel",
                "label_ar": "شتاينسل",
                "label_fr": "Steinsel"
            },
            {
                "value": 76079,
                "label": "Tetange",
                "label_ar": "تيتانج",
                "label_fr": "Tetange"
            },
            {
                "value": 76060,
                "label": "Soleuvre",
                "label_ar": "سولوفر",
                "label_fr": "Soleuvre"
            }
        ]
    },
    {
        "country": "Armenia",
        "cities": [
            {
                "value": 76172,
                "label": "Aparan",
                "label_ar": "اباران",
                "label_fr": "Aparan"
            },
            {
                "value": 76175,
                "label": "Abovyan",
                "label_ar": "أبوفيان",
                "label_fr": "Abovyan"
            },
            {
                "value": 76185,
                "label": "Tsaghkadzor",
                "label_ar": "تساجكادزور",
                "label_fr": "Tsaghkadzor"
            },
            {
                "value": 76184,
                "label": "Ijevan",
                "label_ar": "إجيفان",
                "label_fr": "Ijevan"
            },
            {
                "value": 76173,
                "label": "Yerevan",
                "label_ar": "يريفان",
                "label_fr": "Erevan"
            },
            {
                "value": 76176,
                "label": "Vanadzor",
                "label_ar": "فانادزور",
                "label_fr": "Vanadzor"
            },
            {
                "value": 76174,
                "label": "Jermuk",
                "label_ar": "جيرموك",
                "label_fr": "Jermuk"
            },
            {
                "value": 76178,
                "label": "Ashtarak",
                "label_ar": "Ashtarak",
                "label_fr": "Ashtarak"
            },
            {
                "value": 76179,
                "label": "Arabkir",
                "label_ar": "عربكير",
                "label_fr": "Arabkir"
            },
            {
                "value": 76181,
                "label": "Kapan",
                "label_ar": "كابان",
                "label_fr": "Kapan"
            },
            {
                "value": 76182,
                "label": "Erebuni Fortress",
                "label_ar": "قلعة إيريبوني",
                "label_fr": "Forteresse Erebuni"
            },
            {
                "value": 76183,
                "label": "Hrazdan",
                "label_ar": "هرازدان",
                "label_fr": "Hrazdan"
            },
            {
                "value": 76180,
                "label": "Agarak",
                "label_ar": "اجاراك",
                "label_fr": "Agarak"
            },
            {
                "value": 76177,
                "label": "Apaga",
                "label_ar": "أباجا",
                "label_fr": "Apaga"
            }
        ]
    },
    {
        "country": "British Virgin Islands",
        "cities": [
            {
                "value": 76187,
                "label": "Tortola",
                "label_ar": "تورتولا",
                "label_fr": "Tortola"
            },
            {
                "value": 76186,
                "label": "Road Town",
                "label_ar": "رود تاون",
                "label_fr": "Road Town"
            }
        ]
    },
    {
        "country": "Yemen",
        "cities": [
            {
                "value": 76189,
                "label": "Sanaa",
                "label_ar": "صنعاء",
                "label_fr": "Sanaa"
            },
            {
                "value": 76190,
                "label": "Aden",
                "label_ar": "عدن",
                "label_fr": "Aden"
            },
            {
                "value": 76188,
                "label": "Sana'",
                "label_ar": "صنعاء'",
                "label_fr": "Sana '"
            }
        ]
    },
    {
        "country": "Belarus",
        "cities": [
            {
                "value": 76198,
                "label": "Lida",
                "label_ar": "ليدا",
                "label_fr": "Lida"
            },
            {
                "value": 76212,
                "label": "Shklow",
                "label_ar": "شكلاو",
                "label_fr": "Shklow"
            },
            {
                "value": 76213,
                "label": "Mozyr'",
                "label_ar": "موزير",
                "label_fr": "Mozyr '"
            },
            {
                "value": 76197,
                "label": "Navapolatsk",
                "label_ar": "نافابولاتسك",
                "label_fr": "Navapolatsk"
            },
            {
                "value": 76201,
                "label": "Lyakhovichi",
                "label_ar": "لياكوفيتشي",
                "label_fr": "Lyakhovichi"
            },
            {
                "value": 76208,
                "label": "Lyaskavichy",
                "label_ar": "Lyaskavichy",
                "label_fr": "Lyaskavichy"
            },
            {
                "value": 76193,
                "label": "Brest",
                "label_ar": "بريست",
                "label_fr": "Brest"
            },
            {
                "value": 76214,
                "label": "Dzyarzhynsk",
                "label_ar": "دزيارجينسك",
                "label_fr": "Dzyarzhynsk"
            },
            {
                "value": 76205,
                "label": "Valer'yanovo",
                "label_ar": "فاليريانوفو",
                "label_fr": "Valer'yanovo"
            },
            {
                "value": 76204,
                "label": "Orsha",
                "label_ar": "أورشا",
                "label_fr": "Orsha"
            },
            {
                "value": 76203,
                "label": "Polatsk",
                "label_ar": "بولاتسك",
                "label_fr": "Polatsk"
            },
            {
                "value": 76211,
                "label": "Lahoysk",
                "label_ar": "لاهويسك",
                "label_fr": "Lahoysk"
            },
            {
                "value": 76191,
                "label": "Minsk",
                "label_ar": "مينسك",
                "label_fr": "Minsk"
            },
            {
                "value": 76194,
                "label": "Vitebsk",
                "label_ar": "فيتيبسك",
                "label_fr": "Vitebsk"
            },
            {
                "value": 76209,
                "label": "Zhodzina",
                "label_ar": "جودزينا",
                "label_fr": "Zhodzina"
            },
            {
                "value": 76206,
                "label": "Baranovichi",
                "label_ar": "بارانوفيتشي",
                "label_fr": "Baranovichi"
            },
            {
                "value": 76199,
                "label": "Horki",
                "label_ar": "هوركي",
                "label_fr": "Horki"
            },
            {
                "value": 76196,
                "label": "Hrodna",
                "label_ar": "هرودنا",
                "label_fr": "Hrodna"
            },
            {
                "value": 76202,
                "label": "Radoshkovichi",
                "label_ar": "رادوشكوفيتشي",
                "label_fr": "Radoshkovichi"
            },
            {
                "value": 76195,
                "label": "Dvor-Gomel'",
                "label_ar": "دفور غوميل",
                "label_fr": "Dvor-Gomel '"
            },
            {
                "value": 76192,
                "label": "Mogilev",
                "label_ar": "موغيليف",
                "label_fr": "Moguilev"
            },
            {
                "value": 76200,
                "label": "Borisov",
                "label_ar": "بوريسوف",
                "label_fr": "Borisov"
            },
            {
                "value": 76207,
                "label": "Mazyr",
                "label_ar": "مزير",
                "label_fr": "Mazyr"
            },
            {
                "value": 76210,
                "label": "Zaslawye",
                "label_ar": "زاسلاوي",
                "label_fr": "Zaslawye"
            },
            {
                "value": 76215,
                "label": "Pinsk",
                "label_ar": "بينسك",
                "label_fr": "Pinsk"
            }
        ]
    },
    {
        "country": "Gibraltar",
        "cities": [
            {
                "value": 76216,
                "label": "Gibraltar",
                "label_ar": "جبل طارق",
                "label_fr": "Gibraltar"
            }
        ]
    },
    {
        "country": "Kenya",
        "cities": [
            {
                "value": 76228,
                "label": "Kaiboi",
                "label_ar": "كايبوي",
                "label_fr": "Kaiboi"
            },
            {
                "value": 76235,
                "label": "Maseno",
                "label_ar": "ماسينو",
                "label_fr": "Maseno"
            },
            {
                "value": 76238,
                "label": "Sawa Sawa",
                "label_ar": "سوا سوا",
                "label_fr": "Sawa Sawa"
            },
            {
                "value": 76231,
                "label": "Bondo",
                "label_ar": "بوندو",
                "label_fr": "Bondo"
            },
            {
                "value": 76226,
                "label": "Kiambu",
                "label_ar": "كيامبو",
                "label_fr": "Kiambu"
            },
            {
                "value": 76224,
                "label": "Kabete",
                "label_ar": "كابيتي",
                "label_fr": "Kabete"
            },
            {
                "value": 76218,
                "label": "Mombasa",
                "label_ar": "مومباسا",
                "label_fr": "Mombasa"
            },
            {
                "value": 76227,
                "label": "Nakuru",
                "label_ar": "ناكورو",
                "label_fr": "Nakuru"
            },
            {
                "value": 76225,
                "label": "Wote",
                "label_ar": "حذر",
                "label_fr": "Wote"
            },
            {
                "value": 76237,
                "label": "Mbita",
                "label_ar": "مبيتا",
                "label_fr": "Mbita"
            },
            {
                "value": 76221,
                "label": "Thika",
                "label_ar": "ثيكا",
                "label_fr": "Thika"
            },
            {
                "value": 76223,
                "label": "Kitui",
                "label_ar": "كيتوي",
                "label_fr": "Kitui"
            },
            {
                "value": 76220,
                "label": "Machakos",
                "label_ar": "مشاكوس",
                "label_fr": "Machakos"
            },
            {
                "value": 76230,
                "label": "Kisii",
                "label_ar": "كيسي",
                "label_fr": "Kisii"
            },
            {
                "value": 76236,
                "label": "Kikuyu",
                "label_ar": "كيكويو",
                "label_fr": "Kikuyu"
            },
            {
                "value": 76233,
                "label": "Chuka",
                "label_ar": "تشوكا",
                "label_fr": "Chuka"
            },
            {
                "value": 76234,
                "label": "Karatina",
                "label_ar": "كاراتينا",
                "label_fr": "Karatina"
            },
            {
                "value": 76217,
                "label": "Nairobi",
                "label_ar": "نيروبي",
                "label_fr": "Nairobi"
            },
            {
                "value": 76229,
                "label": "Kitale",
                "label_ar": "كيتالي",
                "label_fr": "Kitale"
            },
            {
                "value": 76239,
                "label": "Turbo",
                "label_ar": "توربو",
                "label_fr": "Turbo"
            },
            {
                "value": 76222,
                "label": "Eldoret",
                "label_ar": "إلدوريت",
                "label_fr": "Eldoret"
            },
            {
                "value": 76232,
                "label": "Siaya",
                "label_ar": "سيايا",
                "label_fr": "Siaya"
            },
            {
                "value": 76219,
                "label": "Kisumu",
                "label_ar": "كيسومو",
                "label_fr": "Kisumu"
            }
        ]
    },
    {
        "country": "Chile",
        "cities": [
            {
                "value": 76347,
                "label": "Vitacura",
                "label_ar": "فيتاكورا",
                "label_fr": "Vitacura"
            },
            {
                "value": 76277,
                "label": "Talcahuano",
                "label_ar": "تالكاهوانو",
                "label_fr": "Talcahuano"
            },
            {
                "value": 76342,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 76253,
                "label": "Valdivia",
                "label_ar": "فالديفيا",
                "label_fr": "Valdivia"
            },
            {
                "value": 76317,
                "label": "Renca",
                "label_ar": "رينكا",
                "label_fr": "Renca"
            },
            {
                "value": 76245,
                "label": "Iquique",
                "label_ar": "ايكيكي",
                "label_fr": "Iquique"
            },
            {
                "value": 76356,
                "label": "Chimbarongo",
                "label_ar": "شيمبارونجو",
                "label_fr": "Chimbarongo"
            },
            {
                "value": 76266,
                "label": "La Union",
                "label_ar": "لا يونيون",
                "label_fr": "La Union"
            },
            {
                "value": 76308,
                "label": "Quilicura",
                "label_ar": "Quilicura",
                "label_fr": "Quilicura"
            },
            {
                "value": 76315,
                "label": "Renaca",
                "label_ar": "ريناكا",
                "label_fr": "Renaca"
            },
            {
                "value": 76360,
                "label": "Lautaro",
                "label_ar": "لوتارو",
                "label_fr": "Lautaro"
            },
            {
                "value": 76267,
                "label": "Quilpué",
                "label_ar": "كويلبوي",
                "label_fr": "Quilpué"
            },
            {
                "value": 76279,
                "label": "San Fernando",
                "label_ar": "سان فرناندو",
                "label_fr": "San Fernando"
            },
            {
                "value": 76318,
                "label": "Recoleta",
                "label_ar": "ريكوليتا",
                "label_fr": "Recoleta"
            },
            {
                "value": 76290,
                "label": "Talagante",
                "label_ar": "تالاغانتي",
                "label_fr": "Talagante"
            },
            {
                "value": 76264,
                "label": "La Laja",
                "label_ar": "لا لاجا",
                "label_fr": "La Laja"
            },
            {
                "value": 76260,
                "label": "Quillota",
                "label_ar": "كويلوتا",
                "label_fr": "Quillota"
            },
            {
                "value": 76297,
                "label": "La Granja",
                "label_ar": "لا جرانجا",
                "label_fr": "La Granja"
            },
            {
                "value": 76324,
                "label": "San Felipe",
                "label_ar": "سان فيليبي",
                "label_fr": "San Felipe"
            },
            {
                "value": 76271,
                "label": "Rancagua",
                "label_ar": "رانكاغوا",
                "label_fr": "Rancagua"
            },
            {
                "value": 76349,
                "label": "Tarapaca",
                "label_ar": "Tarapaca",
                "label_fr": "Tarapaca"
            },
            {
                "value": 76240,
                "label": "Las Condes",
                "label_ar": "لاس كونديس",
                "label_fr": "Las Condes"
            },
            {
                "value": 76269,
                "label": "Macul",
                "label_ar": "ماكول",
                "label_fr": "Macul"
            },
            {
                "value": 76291,
                "label": "Nunoa",
                "label_ar": "نونوا",
                "label_fr": "Nunoa"
            },
            {
                "value": 76340,
                "label": "Santa Barbara",
                "label_ar": "سانتا باربارا",
                "label_fr": "Santa Barbara"
            },
            {
                "value": 76319,
                "label": "Apoquindo",
                "label_ar": "أبوكيندو",
                "label_fr": "Apoquindo"
            },
            {
                "value": 76272,
                "label": "Curicó",
                "label_ar": "كوريكو",
                "label_fr": "Curicó"
            },
            {
                "value": 76300,
                "label": "Arauco",
                "label_ar": "أراوكو",
                "label_fr": "Arauco"
            },
            {
                "value": 76252,
                "label": "Maquina Atacama",
                "label_ar": "ماكوينا أتاكاما",
                "label_fr": "Maquina Atacama"
            },
            {
                "value": 76313,
                "label": "Calama",
                "label_ar": "كالاما",
                "label_fr": "Calama"
            },
            {
                "value": 76302,
                "label": "Curacavi",
                "label_ar": "كوراكافي",
                "label_fr": "Curacavi"
            },
            {
                "value": 76310,
                "label": "Villa Alemana",
                "label_ar": "فيلا اليمانا",
                "label_fr": "Villa Alemana"
            },
            {
                "value": 76299,
                "label": "Monte Aguila",
                "label_ar": "مونتي أغيلا",
                "label_fr": "Monte Aguila"
            },
            {
                "value": 76328,
                "label": "Puente Alto",
                "label_ar": "بوينتي ألتو",
                "label_fr": "Puente Alto"
            },
            {
                "value": 76351,
                "label": "Lo Barnechea",
                "label_ar": "لو بارنيشيا",
                "label_fr": "Lo Barnechea"
            },
            {
                "value": 76355,
                "label": "Nogales",
                "label_ar": "نوجاليس",
                "label_fr": "Nogales"
            },
            {
                "value": 76337,
                "label": "Cerrillos de Tamaya",
                "label_ar": "سيريلوس دي تامايا",
                "label_fr": "Cerrillos de Tamaya"
            },
            {
                "value": 76275,
                "label": "Coquimbo",
                "label_ar": "كوكيمبو",
                "label_fr": "Coquimbo"
            },
            {
                "value": 76354,
                "label": "La Ligua",
                "label_ar": "لا ليغوا",
                "label_fr": "La Ligua"
            },
            {
                "value": 76336,
                "label": "El Arco",
                "label_ar": "إل أركو",
                "label_fr": "El Arco"
            },
            {
                "value": 76344,
                "label": "Angol",
                "label_ar": "أنجول",
                "label_fr": "Angol"
            },
            {
                "value": 76258,
                "label": "Molina",
                "label_ar": "مولينا",
                "label_fr": "Molina"
            },
            {
                "value": 76296,
                "label": "Melipilla",
                "label_ar": "مليبيلا",
                "label_fr": "Melipilla"
            },
            {
                "value": 76261,
                "label": "Punta Arenas",
                "label_ar": "بونتا اريناس",
                "label_fr": "Punta Arenas"
            },
            {
                "value": 76323,
                "label": "Maipu",
                "label_ar": "ميبو",
                "label_fr": "Maipu"
            },
            {
                "value": 76307,
                "label": "Castro",
                "label_ar": "كاسترو",
                "label_fr": "Castro"
            },
            {
                "value": 76250,
                "label": "Providencia",
                "label_ar": "بروفيدنسيا",
                "label_fr": "Providencia"
            },
            {
                "value": 76301,
                "label": "Chiguayante",
                "label_ar": "شيغوايانتي",
                "label_fr": "Chiguayante"
            },
            {
                "value": 76306,
                "label": "Puerto Varas",
                "label_ar": "بويرتو فاراس",
                "label_fr": "Puerto Varas"
            },
            {
                "value": 76265,
                "label": "Temuco",
                "label_ar": "تيموكو",
                "label_fr": "Temuco"
            },
            {
                "value": 76294,
                "label": "Caldera",
                "label_ar": "فوه بركانيه",
                "label_fr": "Caldera"
            },
            {
                "value": 76338,
                "label": "El Bosque",
                "label_ar": "El Bosque",
                "label_fr": "El Bosque"
            },
            {
                "value": 76329,
                "label": "Olmue",
                "label_ar": "أولمو",
                "label_fr": "Olmue"
            },
            {
                "value": 76304,
                "label": "Osorno",
                "label_ar": "أوسورنو",
                "label_fr": "Osorno"
            },
            {
                "value": 76332,
                "label": "El Salvador",
                "label_ar": "السلفادور",
                "label_fr": "Le Salvador"
            },
            {
                "value": 76364,
                "label": "Catemu",
                "label_ar": "كاتيمو",
                "label_fr": "Catemu"
            },
            {
                "value": 76322,
                "label": "Penablanca",
                "label_ar": "بينابلانكا",
                "label_fr": "Penablanca"
            },
            {
                "value": 76282,
                "label": "Constitucion",
                "label_ar": "كونستيتوسيون",
                "label_fr": "Constitucion"
            },
            {
                "value": 76335,
                "label": "Rinconada",
                "label_ar": "رينكونادا",
                "label_fr": "Rinconada"
            },
            {
                "value": 76305,
                "label": "Centro",
                "label_ar": "سنترو",
                "label_fr": "Centro"
            },
            {
                "value": 76288,
                "label": "Nacimiento",
                "label_ar": "ناسيمينتو",
                "label_fr": "Nacimiento"
            },
            {
                "value": 76273,
                "label": "Talca",
                "label_ar": "تالكا",
                "label_fr": "Talca"
            },
            {
                "value": 76281,
                "label": "Pucon",
                "label_ar": "بوكون",
                "label_fr": "Pucon"
            },
            {
                "value": 76352,
                "label": "Puerto Natales",
                "label_ar": "بويرتو ناتالس",
                "label_fr": "Puerto Natales"
            },
            {
                "value": 76285,
                "label": "Bulnes",
                "label_ar": "بولنس",
                "label_fr": "Bulnes"
            },
            {
                "value": 76353,
                "label": "La Calera",
                "label_ar": "لا كاليرا",
                "label_fr": "La Calera"
            },
            {
                "value": 76314,
                "label": "San Antonio",
                "label_ar": "سان أنطونيو",
                "label_fr": "San Antonio"
            },
            {
                "value": 76287,
                "label": "Graneros",
                "label_ar": "جرانيروس",
                "label_fr": "Graneros"
            },
            {
                "value": 76274,
                "label": "Vallenar",
                "label_ar": "فالينار",
                "label_fr": "Vallenar"
            },
            {
                "value": 76330,
                "label": "Purranque",
                "label_ar": "Purranque",
                "label_fr": "Purranque"
            },
            {
                "value": 76286,
                "label": "Rengo",
                "label_ar": "رينجو",
                "label_fr": "Rengo"
            },
            {
                "value": 76243,
                "label": "Hanga Roa",
                "label_ar": "هانجا روا",
                "label_fr": "Hanga Roa"
            },
            {
                "value": 76298,
                "label": "Coronel",
                "label_ar": "كورونيل",
                "label_fr": "Coronel"
            },
            {
                "value": 76251,
                "label": "Copiapó",
                "label_ar": "كوبيابو",
                "label_fr": "Copiapó"
            },
            {
                "value": 76242,
                "label": "Viña del Mar",
                "label_ar": "فينيا ديل مار",
                "label_fr": "Viña del Mar"
            },
            {
                "value": 76257,
                "label": "Collipulli",
                "label_ar": "كوليبولي",
                "label_fr": "Collipulli"
            },
            {
                "value": 76341,
                "label": "Los Andes",
                "label_ar": "لوس أنديس",
                "label_fr": "Los Andes"
            },
            {
                "value": 76278,
                "label": "San Javier",
                "label_ar": "سان خافيير",
                "label_fr": "San Javier"
            },
            {
                "value": 76350,
                "label": "Maule",
                "label_ar": "مولي",
                "label_fr": "Maule"
            },
            {
                "value": 76343,
                "label": "Cunco",
                "label_ar": "كونكو",
                "label_fr": "Cunco"
            },
            {
                "value": 76241,
                "label": "Santiago",
                "label_ar": "سانتياغو",
                "label_fr": "Santiago"
            },
            {
                "value": 76268,
                "label": "Puchuncavi",
                "label_ar": "بوشنكافي",
                "label_fr": "Puchuncavi"
            },
            {
                "value": 76311,
                "label": "Buin",
                "label_ar": "بوين",
                "label_fr": "Buin"
            },
            {
                "value": 76254,
                "label": "Coihaique",
                "label_ar": "Coihaique",
                "label_fr": "Coihaique"
            },
            {
                "value": 76270,
                "label": "Lampa",
                "label_ar": "لامبا",
                "label_fr": "Lampa"
            },
            {
                "value": 76325,
                "label": "Villarrica",
                "label_ar": "فيلاريكا",
                "label_fr": "Villarrica"
            },
            {
                "value": 76283,
                "label": "San Carlos",
                "label_ar": "سان كارلوس",
                "label_fr": "San Carlos"
            },
            {
                "value": 76363,
                "label": "Chonchi",
                "label_ar": "تشونتشي",
                "label_fr": "Chonchi"
            },
            {
                "value": 76321,
                "label": "Puerto Aisen",
                "label_ar": "بويرتو أيسن",
                "label_fr": "Puerto Aisen"
            },
            {
                "value": 76320,
                "label": "Lolol",
                "label_ar": "لولول",
                "label_fr": "Lolol"
            },
            {
                "value": 76249,
                "label": "Chuquicamata",
                "label_ar": "تشوكيكاماتا",
                "label_fr": "Chuquicamata"
            },
            {
                "value": 76244,
                "label": "Antofagasta",
                "label_ar": "أنتوفاجاستا",
                "label_fr": "Antofagasta"
            },
            {
                "value": 76289,
                "label": "San Bernardo",
                "label_ar": "سان برناردو",
                "label_fr": "San Bernardo"
            },
            {
                "value": 76248,
                "label": "Ovalle",
                "label_ar": "البيضاوي",
                "label_fr": "Ovalle"
            },
            {
                "value": 76358,
                "label": "Algarrobo",
                "label_ar": "الجاروبو",
                "label_fr": "Algarrobo"
            },
            {
                "value": 76262,
                "label": "Tome",
                "label_ar": "إلي",
                "label_fr": "tome"
            },
            {
                "value": 76326,
                "label": "Paillaco",
                "label_ar": "بايلاكو",
                "label_fr": "Paillaco"
            },
            {
                "value": 76292,
                "label": "Colina",
                "label_ar": "كولينا",
                "label_fr": "Colina"
            },
            {
                "value": 76345,
                "label": "Puren",
                "label_ar": "بورن",
                "label_fr": "Puren"
            },
            {
                "value": 76259,
                "label": "Los Angeles",
                "label_ar": "لوس أنجلوس",
                "label_fr": "Los Angeles"
            },
            {
                "value": 76331,
                "label": "Chicureo Abajo",
                "label_ar": "شيكوريو أباجو",
                "label_fr": "Chicureo Abajo"
            },
            {
                "value": 76362,
                "label": "Quellon",
                "label_ar": "كويلون",
                "label_fr": "Quellon"
            },
            {
                "value": 76316,
                "label": "Concon",
                "label_ar": "كونكون",
                "label_fr": "Concon"
            },
            {
                "value": 76276,
                "label": "Mejillones",
                "label_ar": "Mejillones",
                "label_fr": "Mejillones"
            },
            {
                "value": 76303,
                "label": "La Reina",
                "label_ar": "لا رينا",
                "label_fr": "La Reina"
            },
            {
                "value": 76333,
                "label": "Miraflores",
                "label_ar": "ميرافلوريس",
                "label_fr": "Miraflores"
            },
            {
                "value": 76263,
                "label": "Chillan",
                "label_ar": "شيلان",
                "label_fr": "Chillan"
            },
            {
                "value": 76359,
                "label": "Dalcahue",
                "label_ar": "دالكاهو",
                "label_fr": "Dalcahue"
            },
            {
                "value": 76339,
                "label": "El Valle",
                "label_ar": "ايل فالي",
                "label_fr": "El Valle"
            },
            {
                "value": 76284,
                "label": "Chillan Viejo",
                "label_ar": "شيلان فيجو",
                "label_fr": "Chillan Viejo"
            },
            {
                "value": 76346,
                "label": "San Martin",
                "label_ar": "سان مارتن",
                "label_fr": "San Martin"
            },
            {
                "value": 76357,
                "label": "Llanquihue",
                "label_ar": "لانكيهيو",
                "label_fr": "Llanquihue"
            },
            {
                "value": 76348,
                "label": "Fresia",
                "label_ar": "فريزيا",
                "label_fr": "Fresia"
            },
            {
                "value": 76280,
                "label": "La Cisterna",
                "label_ar": "لا سيستيرنا",
                "label_fr": "La Cisterna"
            },
            {
                "value": 76247,
                "label": "Valparaíso",
                "label_ar": "فالبارايسو",
                "label_fr": "Valparaíso"
            },
            {
                "value": 76255,
                "label": "Galvarino",
                "label_ar": "جالفارينو",
                "label_fr": "Galvarino"
            },
            {
                "value": 76246,
                "label": "Concepción",
                "label_ar": "كونسيبسيون",
                "label_fr": "Concepción"
            },
            {
                "value": 76293,
                "label": "Machali",
                "label_ar": "مشالي",
                "label_fr": "Machali"
            },
            {
                "value": 76256,
                "label": "Port Montt",
                "label_ar": "بورت مونت",
                "label_fr": "Port Montt"
            },
            {
                "value": 76312,
                "label": "Arica",
                "label_ar": "أريكا",
                "label_fr": "Arica"
            },
            {
                "value": 76361,
                "label": "Quemchi",
                "label_ar": "الكيمتشي",
                "label_fr": "Quemchi"
            },
            {
                "value": 76366,
                "label": "El Quisco",
                "label_ar": "El Quisco",
                "label_fr": "El Quisco"
            },
            {
                "value": 76309,
                "label": "La Serena",
                "label_ar": "لاسيرينا",
                "label_fr": "La Serena"
            },
            {
                "value": 76365,
                "label": "Penaflor",
                "label_ar": "بينافلور",
                "label_fr": "Penaflor"
            },
            {
                "value": 76295,
                "label": "El Monte",
                "label_ar": "المونتي",
                "label_fr": "El Monte"
            },
            {
                "value": 76334,
                "label": "El Salto",
                "label_ar": "السالتو",
                "label_fr": "El Salto"
            },
            {
                "value": 76327,
                "label": "Rio Bueno",
                "label_ar": "ريو بوينو",
                "label_fr": "Rio Bueno"
            }
        ]
    },
    {
        "country": "Qatar",
        "cities": [
            {
                "value": 76369,
                "label": "Ar Rayyan",
                "label_ar": "الريان",
                "label_fr": "Ar Rayyan"
            },
            {
                "value": 76367,
                "label": "Doha",
                "label_ar": "الدوحة",
                "label_fr": "Doha"
            },
            {
                "value": 76368,
                "label": "Al Wakrah",
                "label_ar": "الوكرة",
                "label_fr": "Al Wakrah"
            }
        ]
    },
    {
        "country": "Kuwait",
        "cities": [
            {
                "value": 76373,
                "label": "Hawalli",
                "label_ar": "حولي",
                "label_fr": "Hawalli"
            },
            {
                "value": 76374,
                "label": "Ar Rawdah",
                "label_ar": "الروضة",
                "label_fr": "Ar Rawdah"
            },
            {
                "value": 76382,
                "label": "Janub as Surrah",
                "label_ar": "الجنوب كسرة",
                "label_fr": "Janub comme Surrah"
            },
            {
                "value": 76378,
                "label": "Kayfan",
                "label_ar": "كيفان",
                "label_fr": "Kayfan"
            },
            {
                "value": 76371,
                "label": "Bayan",
                "label_ar": "بيان",
                "label_fr": "Bayan"
            },
            {
                "value": 76380,
                "label": "Abraq Khaytan",
                "label_ar": "ابرق خيطان",
                "label_fr": "Abraq Khaytan"
            },
            {
                "value": 76375,
                "label": "As Salimiyah",
                "label_ar": "السالمية",
                "label_fr": "Comme Salimiyah"
            },
            {
                "value": 76379,
                "label": "Al Ahmadi",
                "label_ar": "الأحمدي",
                "label_fr": "Al Ahmadi"
            },
            {
                "value": 76384,
                "label": "Ad Dawhah",
                "label_ar": "Ad Dawhah",
                "label_fr": "Ad Dawhah"
            },
            {
                "value": 76372,
                "label": "Al Farwaniyah",
                "label_ar": "الفروانية",
                "label_fr": "Al Farwaniyah"
            },
            {
                "value": 76370,
                "label": "Kuwait City",
                "label_ar": "مدينة الكويت",
                "label_fr": "Koweït"
            },
            {
                "value": 76385,
                "label": "Ash Shu`aybah",
                "label_ar": "الشعيبة",
                "label_fr": "Ash Shuʻaybah"
            },
            {
                "value": 76376,
                "label": "Salwa",
                "label_ar": "سلوى",
                "label_fr": "Salwa"
            },
            {
                "value": 76377,
                "label": "Al Shamiya",
                "label_ar": "الشامية",
                "label_fr": "Al Shamiya"
            },
            {
                "value": 76383,
                "label": "Ad Dasmah",
                "label_ar": "الدسمة",
                "label_fr": "Ad Dasmah"
            },
            {
                "value": 76381,
                "label": "Ash Shuwaykh",
                "label_ar": "الشويخ",
                "label_fr": "Ash Shuwaykh"
            }
        ]
    },
    {
        "country": "Guadeloupe",
        "cities": [
            {
                "value": 76386,
                "label": "Les Abymes",
                "label_ar": "ليه أبيميس",
                "label_fr": "Les Abymes"
            },
            {
                "value": 76405,
                "label": "Anse-Bertrand",
                "label_ar": "آنس برتراند",
                "label_fr": "Anse-Bertrand"
            },
            {
                "value": 76413,
                "label": "Pointe-Noire",
                "label_ar": "بوانت نوار",
                "label_fr": "Pointe-Noire"
            },
            {
                "value": 76401,
                "label": "Le Gosier",
                "label_ar": "لو جوسير",
                "label_fr": "Le Gosier"
            },
            {
                "value": 76395,
                "label": "Petit-Bourg",
                "label_ar": "بيتي بورغ",
                "label_fr": "Petit-Bourg"
            },
            {
                "value": 76396,
                "label": "Le Moule",
                "label_ar": "لو مول",
                "label_fr": "Le Moule"
            },
            {
                "value": 76407,
                "label": "Baillif",
                "label_ar": "بيليف",
                "label_fr": "Baillif"
            },
            {
                "value": 76411,
                "label": "Terre-de-Haut",
                "label_ar": "Terre-de-Haut",
                "label_fr": "Terre-de-Haut"
            },
            {
                "value": 76412,
                "label": "Trois-Rivieres",
                "label_ar": "تروا ريفيير",
                "label_fr": "Trois-Rivières"
            },
            {
                "value": 76387,
                "label": "Basse-Terre",
                "label_ar": "باس تير",
                "label_fr": "Basse-Terre"
            },
            {
                "value": 76389,
                "label": "Sainte-Anne",
                "label_ar": "سانت آن",
                "label_fr": "Sainte-Anne"
            },
            {
                "value": 76398,
                "label": "Port-Louis",
                "label_ar": "بورت لويس",
                "label_fr": "Port Louis"
            },
            {
                "value": 76409,
                "label": "Capesterre-de-Marie-Galante",
                "label_ar": "Capesterre-de-Marie-Galante",
                "label_fr": "Capesterre-de-Marie-Galante"
            },
            {
                "value": 76399,
                "label": "Capesterre-Belle-Eau",
                "label_ar": "Capesterre-Belle-Eau",
                "label_fr": "Capesterre-Belle-Eau"
            },
            {
                "value": 76402,
                "label": "Sainte-Rose",
                "label_ar": "سانت روز",
                "label_fr": "Sainte-Rose"
            },
            {
                "value": 76406,
                "label": "Petit-Canal",
                "label_ar": "قناة بيتيت",
                "label_fr": "Petit-Canal"
            },
            {
                "value": 76408,
                "label": "Goyave",
                "label_ar": "جوييف",
                "label_fr": "Goyave"
            },
            {
                "value": 76400,
                "label": "Baie Mahault",
                "label_ar": "باي ماهولت",
                "label_fr": "Baie Mahault"
            },
            {
                "value": 76390,
                "label": "Morne-a-l'Eau",
                "label_ar": "Morne-a-l'Eau",
                "label_fr": "Morne-à-l'Eau"
            },
            {
                "value": 76393,
                "label": "Saint-Claude",
                "label_ar": "سان كلود",
                "label_fr": "Saint-Claude"
            },
            {
                "value": 76397,
                "label": "Gourbeyre",
                "label_ar": "جوربير",
                "label_fr": "Gourbeyre"
            },
            {
                "value": 76415,
                "label": "Village",
                "label_ar": "قرية",
                "label_fr": "Village"
            },
            {
                "value": 76404,
                "label": "Grand-Bourg",
                "label_ar": "جراند بور",
                "label_fr": "Grand-Bourg"
            },
            {
                "value": 76394,
                "label": "Baie-Mahault",
                "label_ar": "باي ماهولت",
                "label_fr": "Baie-Mahault"
            },
            {
                "value": 76410,
                "label": "Deshaies",
                "label_ar": "ديشايس",
                "label_fr": "Deshaies"
            },
            {
                "value": 76403,
                "label": "Terre-de-Bas",
                "label_ar": "تير دي باس",
                "label_fr": "Terre-de-Bas"
            },
            {
                "value": 76391,
                "label": "Saint-Francois",
                "label_ar": "سان فرانسوا",
                "label_fr": "Saint-François"
            },
            {
                "value": 76392,
                "label": "Lamentin",
                "label_ar": "لامينتين",
                "label_fr": "Lamentin"
            },
            {
                "value": 76414,
                "label": "Saint-Louis",
                "label_ar": "سانت لويس",
                "label_fr": "Saint Louis"
            },
            {
                "value": 76388,
                "label": "Pointe-à-Pitre",
                "label_ar": "بوانت à-Pitre",
                "label_fr": "Pointe-à-Pitre"
            }
        ]
    },
    {
        "country": "Martinique",
        "cities": [
            {
                "value": 76431,
                "label": "Le Diamant",
                "label_ar": "لو ديامانت",
                "label_fr": "Le Diamant"
            },
            {
                "value": 76421,
                "label": "Le Lamentin",
                "label_ar": "لو لامينتين",
                "label_fr": "Le Lamentin"
            },
            {
                "value": 76428,
                "label": "Les Anses-d'Arlet",
                "label_ar": "ليه أنسيس دارليت",
                "label_fr": "Les Anses-d'Arlet"
            },
            {
                "value": 76434,
                "label": "Saint-Esprit",
                "label_ar": "سان إسبريت",
                "label_fr": "Saint-Esprit"
            },
            {
                "value": 76417,
                "label": "Fort-de-France",
                "label_ar": "فور دو فرانس",
                "label_fr": "Fort-de-France"
            },
            {
                "value": 76418,
                "label": "Le Gros-Morne",
                "label_ar": "لو جروس مورن",
                "label_fr": "Le Gros-Morne"
            },
            {
                "value": 76426,
                "label": "Sainte-Luce",
                "label_ar": "سانت لوس",
                "label_fr": "Sainte-Luce"
            },
            {
                "value": 76423,
                "label": "Schoelcher",
                "label_ar": "شولشر",
                "label_fr": "Schoelcher"
            },
            {
                "value": 76425,
                "label": "Le Carbet",
                "label_ar": "لو كاربيت",
                "label_fr": "Le Carbet"
            },
            {
                "value": 76419,
                "label": "Saint-Joseph",
                "label_ar": "القديس يوسف",
                "label_fr": "Saint Joseph"
            },
            {
                "value": 76432,
                "label": "Le Morne-Rouge",
                "label_ar": "لو مورن روج",
                "label_fr": "Le Morne-Rouge"
            },
            {
                "value": 76433,
                "label": "Sainte-Marie",
                "label_ar": "سانت ماري",
                "label_fr": "Sainte-Marie"
            },
            {
                "value": 76427,
                "label": "Les Trois-Ilets",
                "label_ar": "ليه تروا ايليتس",
                "label_fr": "Les Trois-Ilets"
            },
            {
                "value": 76416,
                "label": "Riviere-Salee",
                "label_ar": "ريفيير سالي",
                "label_fr": "Rivière-Salee"
            },
            {
                "value": 76429,
                "label": "Le Francois",
                "label_ar": "لو فرانسوا",
                "label_fr": "Le François"
            },
            {
                "value": 76430,
                "label": "Sainte-Anne",
                "label_ar": "سانت آن",
                "label_fr": "Sainte-Anne"
            },
            {
                "value": 76424,
                "label": "Le Robert",
                "label_ar": "لو روبرت",
                "label_fr": "Le Robert"
            },
            {
                "value": 76420,
                "label": "Ducos",
                "label_ar": "دوكوس",
                "label_fr": "Ducos"
            },
            {
                "value": 76422,
                "label": "Case-Pilote",
                "label_ar": "حالة بيلوت",
                "label_fr": "Cas-Pilote"
            }
        ]
    },
    {
        "country": "French Guiana",
        "cities": [
            {
                "value": 76437,
                "label": "Remire-Montjoly",
                "label_ar": "Remire-Montjoly",
                "label_fr": "Remire-Montjoly"
            },
            {
                "value": 76438,
                "label": "Matoury",
                "label_ar": "ماتوري",
                "label_fr": "Matoury"
            },
            {
                "value": 76435,
                "label": "Petit Cayenne",
                "label_ar": "بيتي كايين",
                "label_fr": "Petit Cayenne"
            },
            {
                "value": 76436,
                "label": "Kourou",
                "label_ar": "كورو",
                "label_fr": "Kourou"
            }
        ]
    },
    {
        "country": "Dominican Republic",
        "cities": [
            {
                "value": 76457,
                "label": "Salvaleón de Higüey",
                "label_ar": "سالفاليون دي هيجوي",
                "label_fr": "Salvaleón de Higüey"
            },
            {
                "value": 76439,
                "label": "Santo Domingo",
                "label_ar": "سانتو دومينغو",
                "label_fr": "Saint-Domingue"
            },
            {
                "value": 76458,
                "label": "Hato Mayor del Rey",
                "label_ar": "هاتو مايور ديل ري",
                "label_fr": "Hato Mayor del Rey"
            },
            {
                "value": 76448,
                "label": "La Romana",
                "label_ar": "لا رومانا",
                "label_fr": "La Romana"
            },
            {
                "value": 76464,
                "label": "Sabaneta",
                "label_ar": "سابانيتا",
                "label_fr": "Sabaneta"
            },
            {
                "value": 76445,
                "label": "Boca Chica",
                "label_ar": "بوكا تشيكا",
                "label_fr": "Boca Chica"
            },
            {
                "value": 76441,
                "label": "San Cristobal",
                "label_ar": "سان كريستوبال",
                "label_fr": "San Cristobal"
            },
            {
                "value": 76449,
                "label": "Puerto Plata",
                "label_ar": "بويرتو بلاتا",
                "label_fr": "Puerto Plata"
            },
            {
                "value": 76466,
                "label": "Los Prados",
                "label_ar": "لوس برادوس",
                "label_fr": "Los Prados"
            },
            {
                "value": 76452,
                "label": "Jimani",
                "label_ar": "جيماني",
                "label_fr": "Jimani"
            },
            {
                "value": 76454,
                "label": "Los Alcarrizos",
                "label_ar": "لوس الكاريزوس",
                "label_fr": "Los Alcarrizos"
            },
            {
                "value": 76446,
                "label": "Punta Cana",
                "label_ar": "بونتا كانا",
                "label_fr": "Punta Cana"
            },
            {
                "value": 76459,
                "label": "Arenazo",
                "label_ar": "Arenazo",
                "label_fr": "Arenazo"
            },
            {
                "value": 76451,
                "label": "San Pedro de Macorís",
                "label_ar": "سان بيدرو دي ماكوريس",
                "label_fr": "San Pedro de Macorís"
            },
            {
                "value": 76461,
                "label": "Cotui",
                "label_ar": "كوتوي",
                "label_fr": "Cotui"
            },
            {
                "value": 76463,
                "label": "Concepción de la Vega",
                "label_ar": "كونسيبسيون دي لا فيغا",
                "label_fr": "Concepción de la Vega"
            },
            {
                "value": 76450,
                "label": "San Francisco de Macorís",
                "label_ar": "سان فرانسيسكو دي ماكوريس",
                "label_fr": "San Francisco de Macorís"
            },
            {
                "value": 76443,
                "label": "Moca",
                "label_ar": "موكا",
                "label_fr": "Moca"
            },
            {
                "value": 76455,
                "label": "Santa Cruz de Barahona",
                "label_ar": "سانتا كروز دي باراهونا",
                "label_fr": "Santa Cruz de Barahona"
            },
            {
                "value": 76440,
                "label": "Santiago de los Caballeros",
                "label_ar": "سانتياغو دي لوس كاباليروس",
                "label_fr": "Santiago de los Caballeros"
            },
            {
                "value": 76447,
                "label": "San Jose de Ocoa",
                "label_ar": "سان خوسيه دي أوكوا",
                "label_fr": "San José de Ocoa"
            },
            {
                "value": 76460,
                "label": "Pedernales",
                "label_ar": "بدرناليس",
                "label_fr": "Pedernales"
            },
            {
                "value": 76456,
                "label": "Baní",
                "label_ar": "باني",
                "label_fr": "Baní"
            },
            {
                "value": 76442,
                "label": "San Fernando de Monte Cristi",
                "label_ar": "سان فرناندو دي مونتي كريستي",
                "label_fr": "San Fernando de Monte Cristi"
            },
            {
                "value": 76465,
                "label": "Bavaro",
                "label_ar": "بافارو",
                "label_fr": "Bavaro"
            },
            {
                "value": 76444,
                "label": "Dominica",
                "label_ar": "دومينيكا",
                "label_fr": "Dominique"
            },
            {
                "value": 76462,
                "label": "Cabarete",
                "label_ar": "كباريت",
                "label_fr": "Cabarete"
            },
            {
                "value": 76453,
                "label": "Guaricano",
                "label_ar": "جوريكانو",
                "label_fr": "Guaricano"
            }
        ]
    },
    {
        "country": "Guam",
        "cities": [
            {
                "value": 76469,
                "label": "Barrigada Village",
                "label_ar": "قرية بارجادا",
                "label_fr": "Village de Barrigada"
            },
            {
                "value": 76471,
                "label": "Yigo Village",
                "label_ar": "قرية ييجو",
                "label_fr": "Village de Yigo"
            },
            {
                "value": 76468,
                "label": "Hagåtña",
                "label_ar": "هاغاتنا",
                "label_fr": "Hagåtña"
            },
            {
                "value": 76472,
                "label": "Inarajan Village",
                "label_ar": "قرية انراجان",
                "label_fr": "Village d'Inarajan"
            },
            {
                "value": 76473,
                "label": "Dededo Village",
                "label_ar": "قرية ديدو",
                "label_fr": "Village de Dededo"
            },
            {
                "value": 76467,
                "label": "Santa Rita",
                "label_ar": "سانتا ريتا",
                "label_fr": "Santa Rita"
            },
            {
                "value": 76470,
                "label": "Tamuning-Tumon-Harmon Village",
                "label_ar": "قرية تامونينج-تومون-هارمون",
                "label_fr": "Village de Tamuning-Tumon-Harmon"
            }
        ]
    },
    {
        "country": "U.S. Virgin Islands",
        "cities": [
            {
                "value": 76478,
                "label": "Kingshill",
                "label_ar": "Kingshill",
                "label_fr": "Kingshill"
            },
            {
                "value": 76475,
                "label": "Charlotte Amalie",
                "label_ar": "شارلوت أمالي",
                "label_fr": "Charlotte Amalie"
            },
            {
                "value": 76477,
                "label": "St John Island",
                "label_ar": "جزيرة سانت جون",
                "label_fr": "Île Saint-Jean"
            },
            {
                "value": 76476,
                "label": "Christiansted",
                "label_ar": "كريستيانستيد",
                "label_fr": "Christiansted"
            },
            {
                "value": 76474,
                "label": "Frederiksted",
                "label_ar": "فريدريكستيد",
                "label_fr": "Frederiksted"
            }
        ]
    },
    {
        "country": "Puerto Rico",
        "cities": [
            {
                "value": 76553,
                "label": "Lajas",
                "label_ar": "لاجاس",
                "label_fr": "Lajas"
            },
            {
                "value": 76541,
                "label": "Juana Diaz",
                "label_ar": "خوانا دياز",
                "label_fr": "Juana Diaz"
            },
            {
                "value": 76555,
                "label": "Residencial Puerto Real",
                "label_ar": "ريزيدينشيال بويرتو ريال",
                "label_fr": "Residencial Puerto Real"
            },
            {
                "value": 76549,
                "label": "Yauco",
                "label_ar": "Yauco",
                "label_fr": "Yauco"
            },
            {
                "value": 76523,
                "label": "Mayaguez",
                "label_ar": "ماياجيز",
                "label_fr": "Mayaguez"
            },
            {
                "value": 76496,
                "label": "Luquillo",
                "label_ar": "لوكيلو",
                "label_fr": "Luquillo"
            },
            {
                "value": 76506,
                "label": "Saint Just",
                "label_ar": "القديس فقط",
                "label_fr": "Saint Just"
            },
            {
                "value": 76491,
                "label": "Toa Baja",
                "label_ar": "توا باجا",
                "label_fr": "Toa Baja"
            },
            {
                "value": 76483,
                "label": "Guaynabo",
                "label_ar": "Guaynabo",
                "label_fr": "Guaynabo"
            },
            {
                "value": 76551,
                "label": "Florida",
                "label_ar": "فلوريدا",
                "label_fr": "Floride"
            },
            {
                "value": 76560,
                "label": "Sabana Seca",
                "label_ar": "سابانا سيكا",
                "label_fr": "Sabana Seca"
            },
            {
                "value": 76564,
                "label": "Culebra",
                "label_ar": "كوليبرا",
                "label_fr": "Culebra"
            },
            {
                "value": 76529,
                "label": "Sabana Grande",
                "label_ar": "سابانا غراندي",
                "label_fr": "Sabana Grande"
            },
            {
                "value": 76534,
                "label": "Loiza",
                "label_ar": "لويزا",
                "label_fr": "Loiza"
            },
            {
                "value": 76563,
                "label": "Jayuya",
                "label_ar": "جايويا",
                "label_fr": "Jayuya"
            },
            {
                "value": 76554,
                "label": "San German",
                "label_ar": "سان جيرمان",
                "label_fr": "San allemand"
            },
            {
                "value": 76522,
                "label": "Moca",
                "label_ar": "موكا",
                "label_fr": "Moca"
            },
            {
                "value": 76498,
                "label": "Ciales",
                "label_ar": "سياليس",
                "label_fr": "Ciales"
            },
            {
                "value": 76486,
                "label": "Salinas",
                "label_ar": "ساليناس",
                "label_fr": "Salinas"
            },
            {
                "value": 76499,
                "label": "Humacao",
                "label_ar": "هيوماكاو",
                "label_fr": "Humacao"
            },
            {
                "value": 76494,
                "label": "Aguas Buenas",
                "label_ar": "أغواس بويناس",
                "label_fr": "Aguas Buenas"
            },
            {
                "value": 76535,
                "label": "Canovanas",
                "label_ar": "كانوفاناس",
                "label_fr": "Canovanas"
            },
            {
                "value": 76508,
                "label": "Lares",
                "label_ar": "لاريس",
                "label_fr": "Lares"
            },
            {
                "value": 76528,
                "label": "Ensenada",
                "label_ar": "إنسينادا",
                "label_fr": "Ensenada"
            },
            {
                "value": 76533,
                "label": "Cidra",
                "label_ar": "سيدرا",
                "label_fr": "Cidra"
            },
            {
                "value": 76495,
                "label": "Punta Santiago",
                "label_ar": "بونتا سانتياغو",
                "label_fr": "Punta Santiago"
            },
            {
                "value": 76511,
                "label": "Bajadero",
                "label_ar": "باجاديرو",
                "label_fr": "Bajadero"
            },
            {
                "value": 76559,
                "label": "Adjuntas",
                "label_ar": "Adjuntas",
                "label_fr": "Adjuntas"
            },
            {
                "value": 76514,
                "label": "Aibonito",
                "label_ar": "ايبونيتو",
                "label_fr": "Aibonito"
            },
            {
                "value": 76484,
                "label": "San Juan",
                "label_ar": "سان خوان",
                "label_fr": "San Juan"
            },
            {
                "value": 76485,
                "label": "Caguas",
                "label_ar": "كاجواس",
                "label_fr": "Caguas"
            },
            {
                "value": 76540,
                "label": "Aguada",
                "label_ar": "أغوادا",
                "label_fr": "Aguada"
            },
            {
                "value": 76552,
                "label": "Penuelas",
                "label_ar": "بينويلاس",
                "label_fr": "Penuelas"
            },
            {
                "value": 76507,
                "label": "Trujillo Alto",
                "label_ar": "تروخيو ألتو",
                "label_fr": "Trujillo Alto"
            },
            {
                "value": 76505,
                "label": "Naguabo",
                "label_ar": "ناجابو",
                "label_fr": "Naguabo"
            },
            {
                "value": 76515,
                "label": "Cayey",
                "label_ar": "كايي",
                "label_fr": "Cayey"
            },
            {
                "value": 76489,
                "label": "Arecibo",
                "label_ar": "أريسيبو",
                "label_fr": "Arecibo"
            },
            {
                "value": 76544,
                "label": "Guanica",
                "label_ar": "جوانيكا",
                "label_fr": "Guanica"
            },
            {
                "value": 76512,
                "label": "Gurabo",
                "label_ar": "غورابو",
                "label_fr": "Gurabo"
            },
            {
                "value": 76565,
                "label": "Coto Laurel",
                "label_ar": "كوتو لوريل",
                "label_fr": "Coto Laurel"
            },
            {
                "value": 76556,
                "label": "Orocovis",
                "label_ar": "أوروكوفيس",
                "label_fr": "Orocovis"
            },
            {
                "value": 76562,
                "label": "San Antonio",
                "label_ar": "سان أنطونيو",
                "label_fr": "San Antonio"
            },
            {
                "value": 76481,
                "label": "Carolina",
                "label_ar": "كارولينا",
                "label_fr": "Caroline"
            },
            {
                "value": 76510,
                "label": "Camuy",
                "label_ar": "كاموي",
                "label_fr": "Camuy"
            },
            {
                "value": 76517,
                "label": "Ponce",
                "label_ar": "بونس",
                "label_fr": "Maquereau"
            },
            {
                "value": 76526,
                "label": "Maunabo",
                "label_ar": "ماونابو",
                "label_fr": "Maunabo"
            },
            {
                "value": 76545,
                "label": "Boqueron",
                "label_ar": "بوكيرون",
                "label_fr": "Boqueron"
            },
            {
                "value": 76538,
                "label": "Coamo",
                "label_ar": "كوامو",
                "label_fr": "Coamo"
            },
            {
                "value": 76504,
                "label": "Vega Alta",
                "label_ar": "فيجا ألتا",
                "label_fr": "Vega Alta"
            },
            {
                "value": 76502,
                "label": "Hatillo",
                "label_ar": "هاتيلو",
                "label_fr": "Hatillo"
            },
            {
                "value": 76503,
                "label": "Manati",
                "label_ar": "ماناتي",
                "label_fr": "Manati"
            },
            {
                "value": 76542,
                "label": "Rincon",
                "label_ar": "رينكون",
                "label_fr": "Rincon"
            },
            {
                "value": 76509,
                "label": "San Sebastian",
                "label_ar": "سان سيباستيان",
                "label_fr": "Saint-Sébastien"
            },
            {
                "value": 76497,
                "label": "Fajardo",
                "label_ar": "فاجاردو",
                "label_fr": "Fajardo"
            },
            {
                "value": 76536,
                "label": "Yabucoa",
                "label_ar": "يابوكوا",
                "label_fr": "Yabucoa"
            },
            {
                "value": 76557,
                "label": "Maricao",
                "label_ar": "ماريكاو",
                "label_fr": "Maricao"
            },
            {
                "value": 76519,
                "label": "Villalba",
                "label_ar": "فيلالبا",
                "label_fr": "Villalba"
            },
            {
                "value": 76546,
                "label": "Quebradillas",
                "label_ar": "كيبراديلاس",
                "label_fr": "Quebradillas"
            },
            {
                "value": 76513,
                "label": "San Lorenzo",
                "label_ar": "سان لورينزو",
                "label_fr": "San Lorenzo"
            },
            {
                "value": 76561,
                "label": "Vieques",
                "label_ar": "فييكس",
                "label_fr": "Vieques"
            },
            {
                "value": 76500,
                "label": "Juncos",
                "label_ar": "جونكوس",
                "label_fr": "Juncos"
            },
            {
                "value": 76487,
                "label": "Vega Baja",
                "label_ar": "فيجا باجا",
                "label_fr": "Vega Baja"
            },
            {
                "value": 76479,
                "label": "Bayamón",
                "label_ar": "بايامون",
                "label_fr": "Bayamón"
            },
            {
                "value": 76566,
                "label": "Mercedita",
                "label_ar": "ميرسيديتا",
                "label_fr": "Mercedita"
            },
            {
                "value": 76521,
                "label": "Isabela",
                "label_ar": "إيزابيلا",
                "label_fr": "Isabela"
            },
            {
                "value": 76493,
                "label": "Corozal",
                "label_ar": "كوروزال",
                "label_fr": "Corozal"
            },
            {
                "value": 76501,
                "label": "Barceloneta",
                "label_ar": "برشلونيتا",
                "label_fr": "Barceloneta"
            },
            {
                "value": 76518,
                "label": "Guayanilla",
                "label_ar": "غوايانيلا",
                "label_fr": "Guayanilla"
            },
            {
                "value": 76527,
                "label": "Patillas",
                "label_ar": "باتيلاس",
                "label_fr": "Patillas"
            },
            {
                "value": 76548,
                "label": "Ceiba",
                "label_ar": "سيبا",
                "label_fr": "Ceiba"
            },
            {
                "value": 76530,
                "label": "Barranquitas",
                "label_ar": "بارانكويتاس",
                "label_fr": "Barranquitas"
            },
            {
                "value": 76520,
                "label": "Aguadilla",
                "label_ar": "اجواديلا",
                "label_fr": "Aguadilla"
            },
            {
                "value": 76532,
                "label": "Garrochales",
                "label_ar": "Garrochales",
                "label_fr": "Garrochales"
            },
            {
                "value": 76539,
                "label": "Cabo Rojo",
                "label_ar": "كابو روجو",
                "label_fr": "Cabo Rojo"
            },
            {
                "value": 76525,
                "label": "Guayama",
                "label_ar": "غواياما",
                "label_fr": "Guayama"
            },
            {
                "value": 76558,
                "label": "Naranjito",
                "label_ar": "نارانجيتو",
                "label_fr": "Naranjito"
            },
            {
                "value": 76516,
                "label": "Santa Isabel",
                "label_ar": "سانتا إيزابيل",
                "label_fr": "Santa Isabel"
            },
            {
                "value": 76543,
                "label": "Anasco",
                "label_ar": "أناسكو",
                "label_fr": "Anasco"
            },
            {
                "value": 76547,
                "label": "Toa Alta",
                "label_ar": "توا ألتا",
                "label_fr": "Toa Alta"
            },
            {
                "value": 76550,
                "label": "Palmer",
                "label_ar": "بالمر",
                "label_fr": "Palmer"
            },
            {
                "value": 76537,
                "label": "Arroyo",
                "label_ar": "أرويو",
                "label_fr": "Arroyo"
            },
            {
                "value": 76531,
                "label": "Utuado",
                "label_ar": "أوتوادو",
                "label_fr": "Utuado"
            },
            {
                "value": 76482,
                "label": "Rio Grande",
                "label_ar": "ريو غراندي",
                "label_fr": "Rio Grande"
            },
            {
                "value": 76524,
                "label": "Hormigueros",
                "label_ar": "هورميجيروس",
                "label_fr": "Hormigueros"
            },
            {
                "value": 76480,
                "label": "Dorado",
                "label_ar": "دورادو",
                "label_fr": "Dorado"
            },
            {
                "value": 76488,
                "label": "Morovis",
                "label_ar": "موروفيس",
                "label_fr": "Morovis"
            },
            {
                "value": 76492,
                "label": "Las Piedras",
                "label_ar": "لاس بيدراس",
                "label_fr": "Las Piedras"
            },
            {
                "value": 76490,
                "label": "Catano",
                "label_ar": "كاتانو",
                "label_fr": "Catano"
            }
        ]
    },
    {
        "country": "Mongolia",
        "cities": [
            {
                "value": 76567,
                "label": "Ulan Bator",
                "label_ar": "أولان باتور",
                "label_fr": "Ulan Bator"
            },
            {
                "value": 76589,
                "label": "Uliastay",
                "label_ar": "يولياستاي",
                "label_fr": "Uliastay"
            },
            {
                "value": 76584,
                "label": "Dalandzadgad",
                "label_ar": "دالاندزادجاد",
                "label_fr": "Dalandzadgad"
            },
            {
                "value": 76580,
                "label": "Undurkhaan",
                "label_ar": "Undurkhaan",
                "label_fr": "Undurkhaan"
            },
            {
                "value": 76581,
                "label": "Ovoot",
                "label_ar": "أوفوت",
                "label_fr": "Ovoot"
            },
            {
                "value": 76573,
                "label": "Sühbaatar",
                "label_ar": "سوهباتار",
                "label_fr": "Sühbaatar"
            },
            {
                "value": 76569,
                "label": "Altai",
                "label_ar": "التاي",
                "label_fr": "Altaï"
            },
            {
                "value": 76587,
                "label": "Saynshand",
                "label_ar": "ساينشاند",
                "label_fr": "Saynshand"
            },
            {
                "value": 76576,
                "label": "Han-Uul",
                "label_ar": "هان يول",
                "label_fr": "Han-Uul"
            },
            {
                "value": 76578,
                "label": "Javhlant",
                "label_ar": "جافلانت",
                "label_fr": "Javhlant"
            },
            {
                "value": 76586,
                "label": "Cecerleg",
                "label_ar": "سيسيرليج",
                "label_fr": "Cecerleg"
            },
            {
                "value": 76568,
                "label": "Chihertey",
                "label_ar": "تشيهيرتي",
                "label_fr": "Chihertey"
            },
            {
                "value": 76585,
                "label": "Toyrim",
                "label_ar": "تويريم",
                "label_fr": "Toyrim"
            },
            {
                "value": 76571,
                "label": "Mörön",
                "label_ar": "أحمق",
                "label_fr": "Idiot"
            },
            {
                "value": 76570,
                "label": "Bayanhongor",
                "label_ar": "بيانهونغور",
                "label_fr": "Bayanhongor"
            },
            {
                "value": 76572,
                "label": "Baruun-Urt",
                "label_ar": "بارون أورت",
                "label_fr": "Baruun-Urt"
            },
            {
                "value": 76583,
                "label": "Mandalgovi",
                "label_ar": "ماندالغوفي",
                "label_fr": "Mandalgovi"
            },
            {
                "value": 76574,
                "label": "Bayangol",
                "label_ar": "بايانجول",
                "label_fr": "Bayangol"
            },
            {
                "value": 76577,
                "label": "Arvayheer",
                "label_ar": "ارفاهير",
                "label_fr": "Arvayheer"
            },
            {
                "value": 76575,
                "label": "Darhan",
                "label_ar": "ضرحان",
                "label_fr": "Darhan"
            },
            {
                "value": 76579,
                "label": "Choyr",
                "label_ar": "تشوير",
                "label_fr": "Choyr"
            },
            {
                "value": 76582,
                "label": "Ulaan-Uul",
                "label_ar": "أولان أول",
                "label_fr": "Ulaan-Uul"
            },
            {
                "value": 76588,
                "label": "Khovd",
                "label_ar": "خوفد",
                "label_fr": "Khovd"
            }
        ]
    },
    {
        "country": "New Zealand",
        "cities": [
            {
                "value": 76639,
                "label": "Parnell",
                "label_ar": "بارنيل",
                "label_fr": "Parnell"
            },
            {
                "value": 76790,
                "label": "Kohukohu",
                "label_ar": "كوهوكوهو",
                "label_fr": "Kohukohu"
            },
            {
                "value": 76806,
                "label": "Blackburn",
                "label_ar": "حرق الأسود",
                "label_fr": "Blackburn"
            },
            {
                "value": 76621,
                "label": "Lower Hutt",
                "label_ar": "لوير هت",
                "label_fr": "Lower Hutt"
            },
            {
                "value": 76595,
                "label": "Christchurch",
                "label_ar": "كرايستشيرش",
                "label_fr": "Christchurch"
            },
            {
                "value": 76746,
                "label": "Hokitika",
                "label_ar": "هوكيتيكا",
                "label_fr": "Hokitika"
            },
            {
                "value": 76759,
                "label": "Winchester",
                "label_ar": "وينشستر",
                "label_fr": "Winchester"
            },
            {
                "value": 76695,
                "label": "Otorohanga",
                "label_ar": "Otorohanga",
                "label_fr": "Otorohanga"
            },
            {
                "value": 76864,
                "label": "Waikuku",
                "label_ar": "وايكوكو",
                "label_fr": "Waikuku"
            },
            {
                "value": 76768,
                "label": "Owaka",
                "label_ar": "أواكا",
                "label_fr": "Owaka"
            },
            {
                "value": 76682,
                "label": "Mangaroa",
                "label_ar": "مانجاروا",
                "label_fr": "Mangaroa"
            },
            {
                "value": 76629,
                "label": "Rotorua",
                "label_ar": "روتوروا",
                "label_fr": "Rotorua"
            },
            {
                "value": 76618,
                "label": "Red Beach",
                "label_ar": "الشاطئ الأحمر",
                "label_fr": "Plage rouge"
            },
            {
                "value": 76646,
                "label": "Queenstown",
                "label_ar": "كوينزتاون",
                "label_fr": "Queenstown"
            },
            {
                "value": 76672,
                "label": "Renwick",
                "label_ar": "رينويك",
                "label_fr": "Renwick"
            },
            {
                "value": 76775,
                "label": "Springs Junction",
                "label_ar": "تقاطع الينابيع",
                "label_fr": "Jonction de ressorts"
            },
            {
                "value": 76632,
                "label": "Waihi Beach",
                "label_ar": "شاطئ Waihi",
                "label_fr": "Plage de Waihi"
            },
            {
                "value": 76670,
                "label": "Tinwald",
                "label_ar": "تينوالد",
                "label_fr": "Tinwald"
            },
            {
                "value": 76726,
                "label": "Kaikoura",
                "label_ar": "كايكورا",
                "label_fr": "Kaikoura"
            },
            {
                "value": 76785,
                "label": "Kimbolton",
                "label_ar": "كيمبولتون",
                "label_fr": "Kimbolton"
            },
            {
                "value": 76667,
                "label": "Katikati",
                "label_ar": "كاتيكاتي",
                "label_fr": "Katikati"
            },
            {
                "value": 76676,
                "label": "Invercargill",
                "label_ar": "إنفركارجيل",
                "label_fr": "Invercargill"
            },
            {
                "value": 76827,
                "label": "Kamo",
                "label_ar": "كامو",
                "label_fr": "Kamo"
            },
            {
                "value": 76715,
                "label": "Eltham",
                "label_ar": "إلثام",
                "label_fr": "Eltham"
            },
            {
                "value": 76802,
                "label": "Fairlie",
                "label_ar": "فيرلي",
                "label_fr": "Fairlie"
            },
            {
                "value": 76796,
                "label": "Mosgiel",
                "label_ar": "موسجيل",
                "label_fr": "Mosgiel"
            },
            {
                "value": 76846,
                "label": "Rotherham",
                "label_ar": "روثرهام",
                "label_fr": "Rotherham"
            },
            {
                "value": 76781,
                "label": "Piopio",
                "label_ar": "بيوبيو",
                "label_fr": "Piopio"
            },
            {
                "value": 76599,
                "label": "Saint Heliers",
                "label_ar": "سانت هيليرز",
                "label_fr": "Saint Heliers"
            },
            {
                "value": 76641,
                "label": "Nelson",
                "label_ar": "نيلسون",
                "label_fr": "Nelson"
            },
            {
                "value": 76603,
                "label": "Tuakau",
                "label_ar": "Tuakau",
                "label_fr": "Tuakau"
            },
            {
                "value": 76635,
                "label": "Matakana",
                "label_ar": "ماتاكانا",
                "label_fr": "Matakana"
            },
            {
                "value": 76748,
                "label": "Te Anau",
                "label_ar": "تي أناو",
                "label_fr": "Te Anau"
            },
            {
                "value": 76679,
                "label": "Rakaia",
                "label_ar": "رقية",
                "label_fr": "Rakaia"
            },
            {
                "value": 76709,
                "label": "Waikuku Beach",
                "label_ar": "شاطئ وايكوكو",
                "label_fr": "Plage de Waikuku"
            },
            {
                "value": 76799,
                "label": "Mossburn",
                "label_ar": "موسبيرن",
                "label_fr": "Mossburn"
            },
            {
                "value": 76691,
                "label": "Riverton",
                "label_ar": "ريفرتون",
                "label_fr": "Riverton"
            },
            {
                "value": 76769,
                "label": "Waitati",
                "label_ar": "وايتاتي",
                "label_fr": "Waitati"
            },
            {
                "value": 76631,
                "label": "Dunedin",
                "label_ar": "دنيدن",
                "label_fr": "Dunedin"
            },
            {
                "value": 76608,
                "label": "Takapuna",
                "label_ar": "تاكابونا",
                "label_fr": "Takapuna"
            },
            {
                "value": 76680,
                "label": "Halswell",
                "label_ar": "هالسويل",
                "label_fr": "Halswell"
            },
            {
                "value": 76761,
                "label": "Sawyers Bay",
                "label_ar": "سويرز باي",
                "label_fr": "Sawyers Bay"
            },
            {
                "value": 76787,
                "label": "Bulls",
                "label_ar": "الثيران",
                "label_fr": "Taureaux"
            },
            {
                "value": 76622,
                "label": "Greenhithe",
                "label_ar": "غرينهيث",
                "label_fr": "Greenhithe"
            },
            {
                "value": 76671,
                "label": "Glenbrook",
                "label_ar": "جلينبروك",
                "label_fr": "Glenbrook"
            },
            {
                "value": 76712,
                "label": "Levin",
                "label_ar": "ليفين",
                "label_fr": "Levin"
            },
            {
                "value": 76844,
                "label": "Oamaru",
                "label_ar": "أومارو",
                "label_fr": "Oamaru"
            },
            {
                "value": 76774,
                "label": "Franz Josef",
                "label_ar": "فرانز جوزيف",
                "label_fr": "Franz Josef"
            },
            {
                "value": 76650,
                "label": "Whakatane",
                "label_ar": "Whakatane",
                "label_fr": "Whakatane"
            },
            {
                "value": 76653,
                "label": "Hastings",
                "label_ar": "هاستنجز",
                "label_fr": "Hastings"
            },
            {
                "value": 76693,
                "label": "Ohura",
                "label_ar": "أوهورا",
                "label_fr": "Ohura"
            },
            {
                "value": 76857,
                "label": "Darfield",
                "label_ar": "دارفيلد",
                "label_fr": "Darfield"
            },
            {
                "value": 76755,
                "label": "Mangonui",
                "label_ar": "مانجونوي",
                "label_fr": "Mangonui"
            },
            {
                "value": 76612,
                "label": "Paihia",
                "label_ar": "بايهيا",
                "label_fr": "Paihia"
            },
            {
                "value": 76600,
                "label": "Torbay",
                "label_ar": "تورباي",
                "label_fr": "Torbay"
            },
            {
                "value": 76642,
                "label": "New Plymouth",
                "label_ar": "نيو بليموث",
                "label_fr": "New Plymouth"
            },
            {
                "value": 76597,
                "label": "Levels",
                "label_ar": "المستويات",
                "label_fr": "Les niveaux"
            },
            {
                "value": 76818,
                "label": "Kirwee",
                "label_ar": "كيروي",
                "label_fr": "Kirwee"
            },
            {
                "value": 76708,
                "label": "Drury",
                "label_ar": "دروري",
                "label_fr": "Drury"
            },
            {
                "value": 76652,
                "label": "Northcote Point",
                "label_ar": "نورثكوت بوينت",
                "label_fr": "Northcote Point"
            },
            {
                "value": 76721,
                "label": "Takanini",
                "label_ar": "تاكانيني",
                "label_fr": "Takanini"
            },
            {
                "value": 76737,
                "label": "Cashmere Hills",
                "label_ar": "تلال الكشمير",
                "label_fr": "Cachemire Hills"
            },
            {
                "value": 76858,
                "label": "Duvauchelle",
                "label_ar": "Duvauchelle",
                "label_fr": "Duvauchelle"
            },
            {
                "value": 76805,
                "label": "Campbells Bay",
                "label_ar": "كامبلز باي",
                "label_fr": "Campbells Bay"
            },
            {
                "value": 76749,
                "label": "Oban",
                "label_ar": "أوبان",
                "label_fr": "Oban"
            },
            {
                "value": 76616,
                "label": "Wellsford",
                "label_ar": "ويلسفورد",
                "label_fr": "Wellsford"
            },
            {
                "value": 76764,
                "label": "Taupiri",
                "label_ar": "توبيري",
                "label_fr": "Taupiri"
            },
            {
                "value": 76701,
                "label": "Rolleston",
                "label_ar": "روليستون",
                "label_fr": "Rolleston"
            },
            {
                "value": 76728,
                "label": "Woolston",
                "label_ar": "وولستون",
                "label_fr": "Woolston"
            },
            {
                "value": 76832,
                "label": "Otane",
                "label_ar": "أوتان",
                "label_fr": "Otane"
            },
            {
                "value": 76638,
                "label": "Waiau Pa",
                "label_ar": "Waiau Pa",
                "label_fr": "Waiau Pa"
            },
            {
                "value": 76751,
                "label": "Weston",
                "label_ar": "ويستون",
                "label_fr": "Weston"
            },
            {
                "value": 76808,
                "label": "Portland",
                "label_ar": "بورتلاند",
                "label_fr": "Portland"
            },
            {
                "value": 76742,
                "label": "Porangahau",
                "label_ar": "بورانغاو",
                "label_fr": "Porangahau"
            },
            {
                "value": 76664,
                "label": "Te Kauwhata",
                "label_ar": "تي كواهاتا",
                "label_fr": "Te Kauwhata"
            },
            {
                "value": 76777,
                "label": "Ashhurst",
                "label_ar": "أشهورست",
                "label_fr": "Ashhurst"
            },
            {
                "value": 76795,
                "label": "Clinton",
                "label_ar": "كلينتون",
                "label_fr": "Clinton"
            },
            {
                "value": 76821,
                "label": "Waimana",
                "label_ar": "ويمانا",
                "label_fr": "Waimana"
            },
            {
                "value": 76791,
                "label": "Okaihau",
                "label_ar": "Okaihau",
                "label_fr": "Okaihau"
            },
            {
                "value": 76658,
                "label": "Wendon Valley",
                "label_ar": "وادي ويندون",
                "label_fr": "Vallée de Wendon"
            },
            {
                "value": 76852,
                "label": "Bombay",
                "label_ar": "بومباي",
                "label_fr": "Bombay"
            },
            {
                "value": 76829,
                "label": "Myross Bush",
                "label_ar": "ميرس بوش",
                "label_fr": "Myross Bush"
            },
            {
                "value": 76744,
                "label": "Herne Bay",
                "label_ar": "هيرن باي",
                "label_fr": "Herne Bay"
            },
            {
                "value": 76835,
                "label": "Wyndham",
                "label_ar": "ويندهام",
                "label_fr": "Wyndham"
            },
            {
                "value": 76688,
                "label": "Riversdale",
                "label_ar": "ريفرسدال",
                "label_fr": "Riversdale"
            },
            {
                "value": 76733,
                "label": "Mangawhai Heads",
                "label_ar": "رؤساء Mangawhai",
                "label_fr": "Têtes de Mangawhai"
            },
            {
                "value": 76830,
                "label": "Waverley",
                "label_ar": "ويفرلي",
                "label_fr": "Waverley"
            },
            {
                "value": 76690,
                "label": "Tuatapere",
                "label_ar": "تواتابيري",
                "label_fr": "Tuatapere"
            },
            {
                "value": 76788,
                "label": "Orakei",
                "label_ar": "أوراكي",
                "label_fr": "Orakei"
            },
            {
                "value": 76628,
                "label": "Waimauku",
                "label_ar": "Waimauku",
                "label_fr": "Waimauku"
            },
            {
                "value": 76617,
                "label": "Hamilton",
                "label_ar": "هاملتون",
                "label_fr": "Hamilton"
            },
            {
                "value": 76660,
                "label": "Greymouth",
                "label_ar": "جريموث",
                "label_fr": "Greymouth"
            },
            {
                "value": 76735,
                "label": "Orewa",
                "label_ar": "أوريوا",
                "label_fr": "Orewa"
            },
            {
                "value": 76593,
                "label": "Windermere",
                "label_ar": "ويندرمير",
                "label_fr": "Windermere"
            },
            {
                "value": 76760,
                "label": "Opunake",
                "label_ar": "أوبونيك",
                "label_fr": "Opunake"
            },
            {
                "value": 76828,
                "label": "Onga Onga",
                "label_ar": "اونجا اونجا",
                "label_fr": "Onga Onga"
            },
            {
                "value": 76739,
                "label": "Templeton",
                "label_ar": "تمبلتون",
                "label_fr": "Templeton"
            },
            {
                "value": 76627,
                "label": "Titirangi North",
                "label_ar": "شمال تيتيرانجي",
                "label_fr": "Titirangi Nord"
            },
            {
                "value": 76804,
                "label": "Puhoi",
                "label_ar": "بوهوي",
                "label_fr": "Puhoi"
            },
            {
                "value": 76662,
                "label": "Punakaiki",
                "label_ar": "بوناكايكي",
                "label_fr": "Punakaiki"
            },
            {
                "value": 76614,
                "label": "Amberley",
                "label_ar": "امبرلي",
                "label_fr": "Amberley"
            },
            {
                "value": 76645,
                "label": "Marua",
                "label_ar": "ماروا",
                "label_fr": "Marua"
            },
            {
                "value": 76809,
                "label": "Kerikeri",
                "label_ar": "كيريكيري",
                "label_fr": "Kerikeri"
            },
            {
                "value": 76623,
                "label": "Wanganui",
                "label_ar": "وانجانوي",
                "label_fr": "Wanganui"
            },
            {
                "value": 76825,
                "label": "Maungaturoto",
                "label_ar": "ماونغاتوروتو",
                "label_fr": "Maungaturoto"
            },
            {
                "value": 76678,
                "label": "Oxford",
                "label_ar": "أكسفورد",
                "label_fr": "Oxford"
            },
            {
                "value": 76786,
                "label": "Otaki Beach",
                "label_ar": "شاطئ أوتاكي",
                "label_fr": "Plage d'Otaki"
            },
            {
                "value": 76696,
                "label": "Tirau",
                "label_ar": "تيراو",
                "label_fr": "Tirau"
            },
            {
                "value": 76687,
                "label": "Takaka",
                "label_ar": "تاكاكا",
                "label_fr": "Takaka"
            },
            {
                "value": 76720,
                "label": "Warkworth",
                "label_ar": "واركورث",
                "label_fr": "Warkworth"
            },
            {
                "value": 76763,
                "label": "Kawhia",
                "label_ar": "كوحية",
                "label_fr": "Kawhia"
            },
            {
                "value": 76655,
                "label": "Lincoln",
                "label_ar": "لينكولن",
                "label_fr": "Lincoln"
            },
            {
                "value": 76725,
                "label": "Waipara",
                "label_ar": "Waipara",
                "label_fr": "Waipara"
            },
            {
                "value": 76841,
                "label": "Greerton",
                "label_ar": "جريرتون",
                "label_fr": "Greerton"
            },
            {
                "value": 76648,
                "label": "Cave",
                "label_ar": "كهف",
                "label_fr": "la grotte"
            },
            {
                "value": 76717,
                "label": "Paraparaumu",
                "label_ar": "باراباراومو",
                "label_fr": "Paraparaumu"
            },
            {
                "value": 76771,
                "label": "Karamea",
                "label_ar": "كرامية",
                "label_fr": "Karamea"
            },
            {
                "value": 76845,
                "label": "Paradise",
                "label_ar": "الجنة",
                "label_fr": "paradis"
            },
            {
                "value": 76668,
                "label": "Waihi",
                "label_ar": "Waihi",
                "label_fr": "Waihi"
            },
            {
                "value": 76590,
                "label": "Auckland",
                "label_ar": "أوكلاند",
                "label_fr": "Auckland"
            },
            {
                "value": 76711,
                "label": "Tahoraiti",
                "label_ar": "طاهوريتي",
                "label_fr": "Tahoraiti"
            },
            {
                "value": 76610,
                "label": "Taradale",
                "label_ar": "Taradale",
                "label_fr": "Taradale"
            },
            {
                "value": 76611,
                "label": "Whangarei",
                "label_ar": "وانجاري",
                "label_fr": "Whangarei"
            },
            {
                "value": 76727,
                "label": "Thames",
                "label_ar": "التايمز",
                "label_fr": "Tamise"
            },
            {
                "value": 76813,
                "label": "Favona",
                "label_ar": "فافونا",
                "label_fr": "Favona"
            },
            {
                "value": 76707,
                "label": "Opaheke",
                "label_ar": "أوباهيكي",
                "label_fr": "Opaheke"
            },
            {
                "value": 76683,
                "label": "Khandallah",
                "label_ar": "خندالله",
                "label_fr": "Khandallah"
            },
            {
                "value": 76820,
                "label": "Tamahere",
                "label_ar": "تامهير",
                "label_fr": "Tamahere"
            },
            {
                "value": 76824,
                "label": "Okato",
                "label_ar": "اوكاتو",
                "label_fr": "Okato"
            },
            {
                "value": 76730,
                "label": "Kaitangata",
                "label_ar": "كايتانغاتا",
                "label_fr": "Kaitangata"
            },
            {
                "value": 76713,
                "label": "Tokoroa",
                "label_ar": "توكوروا",
                "label_fr": "Tokoroa"
            },
            {
                "value": 76801,
                "label": "West Melton",
                "label_ar": "ويست ميلتون",
                "label_fr": "West Melton"
            },
            {
                "value": 76647,
                "label": "Gisborne",
                "label_ar": "جيسبورن",
                "label_fr": "Gisborne"
            },
            {
                "value": 76615,
                "label": "Belmont",
                "label_ar": "بلمونت",
                "label_fr": "Belmont"
            },
            {
                "value": 76779,
                "label": "Foxton Beach",
                "label_ar": "فوكستون بيتش",
                "label_fr": "Plage de Foxton"
            },
            {
                "value": 76822,
                "label": "Bethlehem",
                "label_ar": "بيت لحم",
                "label_fr": "Bethléem"
            },
            {
                "value": 76757,
                "label": "Waipawa",
                "label_ar": "وايباوا",
                "label_fr": "Waipawa"
            },
            {
                "value": 76669,
                "label": "Paeroa",
                "label_ar": "بايروا",
                "label_fr": "Paeroa"
            },
            {
                "value": 76661,
                "label": "Westport",
                "label_ar": "ويستبورت",
                "label_fr": "Westport"
            },
            {
                "value": 76765,
                "label": "Colville",
                "label_ar": "كولفيل",
                "label_fr": "Colville"
            },
            {
                "value": 76699,
                "label": "Tokomaru",
                "label_ar": "توكومارو",
                "label_fr": "Tokomaru"
            },
            {
                "value": 76722,
                "label": "Kumeu",
                "label_ar": "كوميو",
                "label_fr": "Kumeu"
            },
            {
                "value": 76863,
                "label": "Doyleston",
                "label_ar": "دويلستون",
                "label_fr": "Doyleston"
            },
            {
                "value": 76758,
                "label": "Takapau",
                "label_ar": "تاكاباو",
                "label_fr": "Takapau"
            },
            {
                "value": 76723,
                "label": "Waikanae",
                "label_ar": "وايكانا",
                "label_fr": "Waikanae"
            },
            {
                "value": 76837,
                "label": "Swanson",
                "label_ar": "سوانسون",
                "label_fr": "Swanson"
            },
            {
                "value": 76640,
                "label": "Napier City",
                "label_ar": "مدينة نابير",
                "label_fr": "Napier City"
            },
            {
                "value": 76619,
                "label": "Avondale",
                "label_ar": "أفونديل",
                "label_fr": "Avondale"
            },
            {
                "value": 76657,
                "label": "Timaru",
                "label_ar": "تيمارو",
                "label_fr": "Timaru"
            },
            {
                "value": 76624,
                "label": "Masterton",
                "label_ar": "ماسترتون",
                "label_fr": "Masterton"
            },
            {
                "value": 76793,
                "label": "Mission Bay",
                "label_ar": "ميشن باي",
                "label_fr": "Mission Bay"
            },
            {
                "value": 76719,
                "label": "Riverhead",
                "label_ar": "ريفرهيد",
                "label_fr": "Riverhead"
            },
            {
                "value": 76674,
                "label": "Ngaio",
                "label_ar": "نجيو",
                "label_fr": "Ngaio"
            },
            {
                "value": 76831,
                "label": "Beachlands",
                "label_ar": "بيتش لاندز",
                "label_fr": "Beachlands"
            },
            {
                "value": 76675,
                "label": "Clyde",
                "label_ar": "كلايد",
                "label_fr": "Clyde"
            },
            {
                "value": 76861,
                "label": "Browns Bay",
                "label_ar": "خليج براونز",
                "label_fr": "Browns Bay"
            },
            {
                "value": 76698,
                "label": "North Shore",
                "label_ar": "الشاطئ الشمالي",
                "label_fr": "la côte Nord"
            },
            {
                "value": 76651,
                "label": "Opotiki",
                "label_ar": "Opotiki",
                "label_fr": "Opotiki"
            },
            {
                "value": 76819,
                "label": "Hunterville",
                "label_ar": "هانترفيل",
                "label_fr": "Hunterville"
            },
            {
                "value": 76634,
                "label": "Taupo",
                "label_ar": "تاوبو",
                "label_fr": "Taupo"
            },
            {
                "value": 76741,
                "label": "Urenui",
                "label_ar": "أورينوي",
                "label_fr": "Urenui"
            },
            {
                "value": 76689,
                "label": "Otautau",
                "label_ar": "أوتاوتاو",
                "label_fr": "Otautau"
            },
            {
                "value": 76842,
                "label": "Port Chalmers",
                "label_ar": "بورت تشالمرز",
                "label_fr": "Port Chalmers"
            },
            {
                "value": 76850,
                "label": "Penrose",
                "label_ar": "بنروز",
                "label_fr": "Penrose"
            },
            {
                "value": 76848,
                "label": "Clive",
                "label_ar": "كلايف",
                "label_fr": "Clive"
            },
            {
                "value": 76776,
                "label": "Dargaville",
                "label_ar": "دارجافيل",
                "label_fr": "Dargaville"
            },
            {
                "value": 76606,
                "label": "Papakura",
                "label_ar": "باباكورا",
                "label_fr": "Papakura"
            },
            {
                "value": 76644,
                "label": "Douglas",
                "label_ar": "دوغلاس",
                "label_fr": "Douglas"
            },
            {
                "value": 76654,
                "label": "Petone",
                "label_ar": "بيتون",
                "label_fr": "Petone"
            },
            {
                "value": 76750,
                "label": "Balfour",
                "label_ar": "بلفور",
                "label_fr": "Balfour"
            },
            {
                "value": 76697,
                "label": "Leamington",
                "label_ar": "ليمنجتون",
                "label_fr": "Leamington"
            },
            {
                "value": 76798,
                "label": "Menzies Ferry",
                "label_ar": "مينزيس فيري",
                "label_fr": "Ferry Menzies"
            },
            {
                "value": 76756,
                "label": "Havelock North",
                "label_ar": "هافلوك الشمالية",
                "label_fr": "Havelock Nord"
            },
            {
                "value": 76855,
                "label": "Coalgate",
                "label_ar": "كولجيت",
                "label_fr": "Coalgate"
            },
            {
                "value": 76604,
                "label": "Manukau",
                "label_ar": "مانوكاو",
                "label_fr": "Manukau"
            },
            {
                "value": 76718,
                "label": "Kaiwharawhara",
                "label_ar": "Kaiwharawhara",
                "label_fr": "Kaiwharawhara"
            },
            {
                "value": 76636,
                "label": "Pakuranga",
                "label_ar": "باكورانجا",
                "label_fr": "Pakuranga"
            },
            {
                "value": 76700,
                "label": "Hawera",
                "label_ar": "هويرة",
                "label_fr": "Hawera"
            },
            {
                "value": 76834,
                "label": "Weymouth",
                "label_ar": "ويموث",
                "label_fr": "Weymouth"
            },
            {
                "value": 76747,
                "label": "Glen Eden",
                "label_ar": "جلين إيدن",
                "label_fr": "Glen Eden"
            },
            {
                "value": 76685,
                "label": "Ngatea",
                "label_ar": "نجاتيا",
                "label_fr": "Ngatea"
            },
            {
                "value": 76677,
                "label": "Pokeno",
                "label_ar": "بوكنو",
                "label_fr": "Pokeno"
            },
            {
                "value": 76847,
                "label": "Longburn",
                "label_ar": "لونغبورن",
                "label_fr": "Longburn"
            },
            {
                "value": 76767,
                "label": "Hikuai",
                "label_ar": "هيكواي",
                "label_fr": "Hikuai"
            },
            {
                "value": 76607,
                "label": "Johnsonville",
                "label_ar": "جونسونفيل",
                "label_fr": "Johnsonville"
            },
            {
                "value": 76732,
                "label": "Murupara",
                "label_ar": "Murupara",
                "label_fr": "Murupara"
            },
            {
                "value": 76794,
                "label": "Ranfurly",
                "label_ar": "رانفورلي",
                "label_fr": "Ranfurly"
            },
            {
                "value": 76778,
                "label": "Foxton",
                "label_ar": "فوكستون",
                "label_fr": "Foxton"
            },
            {
                "value": 76843,
                "label": "Oturehua",
                "label_ar": "أوتتهوا",
                "label_fr": "Oturehua"
            },
            {
                "value": 76833,
                "label": "Clarks",
                "label_ar": "كلاركس",
                "label_fr": "Clarks"
            },
            {
                "value": 76783,
                "label": "Pukeatua",
                "label_ar": "بوكياتوا",
                "label_fr": "Pukeatua"
            },
            {
                "value": 76643,
                "label": "Winton",
                "label_ar": "وينتون",
                "label_fr": "Winton"
            },
            {
                "value": 76782,
                "label": "Manunui",
                "label_ar": "مانونوي",
                "label_fr": "Manunui"
            },
            {
                "value": 76859,
                "label": "Sefton",
                "label_ar": "سيفتون",
                "label_fr": "Sefton"
            },
            {
                "value": 76865,
                "label": "Kawakawa",
                "label_ar": "كاواكاوا",
                "label_fr": "Kawakawa"
            },
            {
                "value": 76851,
                "label": "Collingwood",
                "label_ar": "كولينجوود",
                "label_fr": "Collingwood"
            },
            {
                "value": 76816,
                "label": "Kaeo",
                "label_ar": "كايو",
                "label_fr": "Kaeo"
            },
            {
                "value": 76860,
                "label": "Edendale",
                "label_ar": "Edendale",
                "label_fr": "Edendale"
            },
            {
                "value": 76868,
                "label": "Coatesville",
                "label_ar": "كوتسفيل",
                "label_fr": "Coatesville"
            },
            {
                "value": 76862,
                "label": "Methven",
                "label_ar": "ميثفين",
                "label_fr": "Methven"
            },
            {
                "value": 76734,
                "label": "Kaiwaka",
                "label_ar": "كايواكا",
                "label_fr": "Kaiwaka"
            },
            {
                "value": 76706,
                "label": "Porirua",
                "label_ar": "بوريرو",
                "label_fr": "Porirua"
            },
            {
                "value": 76659,
                "label": "Wanaka",
                "label_ar": "واناكا",
                "label_fr": "Wanaka"
            },
            {
                "value": 76633,
                "label": "Kawerau",
                "label_ar": "Kawerau",
                "label_fr": "Kawerau"
            },
            {
                "value": 76714,
                "label": "Turangi",
                "label_ar": "تورانجي",
                "label_fr": "Turangi"
            },
            {
                "value": 76743,
                "label": "Waiouru",
                "label_ar": "وايورو",
                "label_fr": "Waiouru"
            },
            {
                "value": 76594,
                "label": "Tauranga",
                "label_ar": "تاورانجا",
                "label_fr": "Tauranga"
            },
            {
                "value": 76736,
                "label": "Walton",
                "label_ar": "والتون",
                "label_fr": "Walton"
            },
            {
                "value": 76752,
                "label": "Lake Tekapo",
                "label_ar": "بحيرة تيكابو",
                "label_fr": "Lac Tekapo"
            },
            {
                "value": 76849,
                "label": "Otematata",
                "label_ar": "أوتيماتاتا",
                "label_fr": "Otematata"
            },
            {
                "value": 76810,
                "label": "Moerewa",
                "label_ar": "مويريوا",
                "label_fr": "Moerewa"
            },
            {
                "value": 76591,
                "label": "Palmerston North",
                "label_ar": "بالمرستون نورث",
                "label_fr": "Palmerston North"
            },
            {
                "value": 76626,
                "label": "Mangere",
                "label_ar": "مانجير",
                "label_fr": "Mangere"
            },
            {
                "value": 76836,
                "label": "Oratia",
                "label_ar": "اوراتيا",
                "label_fr": "Oratia"
            },
            {
                "value": 76803,
                "label": "Wairoa",
                "label_ar": "وويروا",
                "label_fr": "Wairoa"
            },
            {
                "value": 76770,
                "label": "Hawarden",
                "label_ar": "هواردن",
                "label_fr": "Hawarden"
            },
            {
                "value": 76716,
                "label": "Waitoa",
                "label_ar": "ويتوا",
                "label_fr": "Waitoa"
            },
            {
                "value": 76762,
                "label": "Whitianga",
                "label_ar": "ويتيانجا",
                "label_fr": "Whitianga"
            },
            {
                "value": 76772,
                "label": "Milton",
                "label_ar": "ميلتون",
                "label_fr": "Milton"
            },
            {
                "value": 76753,
                "label": "Kurow",
                "label_ar": "كورو",
                "label_fr": "Kurow"
            },
            {
                "value": 76869,
                "label": "Reporoa",
                "label_ar": "ريبوروا",
                "label_fr": "Reporoa"
            },
            {
                "value": 76807,
                "label": "Waipu",
                "label_ar": "ويبو",
                "label_fr": "Waipu"
            },
            {
                "value": 76817,
                "label": "Te Roti",
                "label_ar": "تي روتي",
                "label_fr": "Te Roti"
            },
            {
                "value": 76784,
                "label": "Okaiawa",
                "label_ar": "أوكاياوا",
                "label_fr": "Okaiawa"
            },
            {
                "value": 76620,
                "label": "Yaldhurst",
                "label_ar": "يلدهيرست",
                "label_fr": "Yaldhurst"
            },
            {
                "value": 76724,
                "label": "Northland",
                "label_ar": "نورثلاند",
                "label_fr": "Northland"
            },
            {
                "value": 76800,
                "label": "Rakaia Gorge",
                "label_ar": "راقية جورج",
                "label_fr": "Gorges de Rakaia"
            },
            {
                "value": 76596,
                "label": "Cheviot",
                "label_ar": "الشفيوت غنم كثيف الصوف",
                "label_fr": "Cheviot"
            },
            {
                "value": 76773,
                "label": "Kaiata",
                "label_ar": "كاياتا",
                "label_fr": "Kaiata"
            },
            {
                "value": 76704,
                "label": "Raglan",
                "label_ar": "الرغلا ن معطف",
                "label_fr": "Raglan"
            },
            {
                "value": 76613,
                "label": "Waiau",
                "label_ar": "واياو",
                "label_fr": "Waiau"
            },
            {
                "value": 76592,
                "label": "Wellington",
                "label_ar": "ويلينجتون",
                "label_fr": "Wellington"
            },
            {
                "value": 76602,
                "label": "Silverdale",
                "label_ar": "سيلفرديل",
                "label_fr": "Silverdale"
            },
            {
                "value": 76812,
                "label": "Tasman",
                "label_ar": "تاسمان",
                "label_fr": "Tasman"
            },
            {
                "value": 76766,
                "label": "Upper Moutere",
                "label_ar": "العليا Moutere",
                "label_fr": "Upper Moutere"
            },
            {
                "value": 76625,
                "label": "Stanmore Bay",
                "label_ar": "خليج ستانمور",
                "label_fr": "Stanmore Bay"
            },
            {
                "value": 76694,
                "label": "Te Atatu",
                "label_ar": "تي أتاتو",
                "label_fr": "Te Atatu"
            },
            {
                "value": 76823,
                "label": "Wakefield",
                "label_ar": "ويكفيلد",
                "label_fr": "Wakefield"
            },
            {
                "value": 76729,
                "label": "Wainuiomata",
                "label_ar": "وينويوماتا",
                "label_fr": "Wainuiomata"
            },
            {
                "value": 76686,
                "label": "Balclutha",
                "label_ar": "بالكلوثا",
                "label_fr": "Balclutha"
            },
            {
                "value": 76797,
                "label": "Outram",
                "label_ar": "أوترام",
                "label_fr": "Outram"
            },
            {
                "value": 76792,
                "label": "Ruawai",
                "label_ar": "Ruawai",
                "label_fr": "Ruawai"
            },
            {
                "value": 76840,
                "label": "Pleasant Point",
                "label_ar": "بليزانت بوينت",
                "label_fr": "Point agréable"
            },
            {
                "value": 76867,
                "label": "Paraparaumu Beach",
                "label_ar": "شاطئ باراباراومو",
                "label_fr": "Plage de Paraparaumu"
            },
            {
                "value": 76738,
                "label": "Wairoa",
                "label_ar": "وويروا",
                "label_fr": "Wairoa"
            },
            {
                "value": 76839,
                "label": "Paekakariki",
                "label_ar": "بيكاكاريكي",
                "label_fr": "Paekakariki"
            },
            {
                "value": 76731,
                "label": "Lumsden",
                "label_ar": "لومسدن",
                "label_fr": "Lumsden"
            },
            {
                "value": 76754,
                "label": "Ahaura",
                "label_ar": "اهاورا",
                "label_fr": "Ahaura"
            },
            {
                "value": 76789,
                "label": "Awanui",
                "label_ar": "اوانوي",
                "label_fr": "Awanui"
            },
            {
                "value": 76605,
                "label": "Manukau",
                "label_ar": "مانوكاو",
                "label_fr": "Manukau"
            },
            {
                "value": 76703,
                "label": "Whangamata",
                "label_ar": "وانجاماتا",
                "label_fr": "Whangamata"
            },
            {
                "value": 76710,
                "label": "Waiuku",
                "label_ar": "وايوكو",
                "label_fr": "Waiuku"
            },
            {
                "value": 76702,
                "label": "Mount Maunganui",
                "label_ar": "جبل Maunganui",
                "label_fr": "Mont Maunganui"
            },
            {
                "value": 76663,
                "label": "Leeston",
                "label_ar": "ليستون",
                "label_fr": "Leeston"
            },
            {
                "value": 76745,
                "label": "Howick",
                "label_ar": "هوويك",
                "label_fr": "Howick"
            },
            {
                "value": 76666,
                "label": "Haast",
                "label_ar": "هاست",
                "label_fr": "Haast"
            },
            {
                "value": 76740,
                "label": "Waitara",
                "label_ar": "ويتارا",
                "label_fr": "Waitara"
            },
            {
                "value": 76630,
                "label": "Ponsonby",
                "label_ar": "بونسونبي",
                "label_fr": "Ponsonby"
            },
            {
                "value": 76637,
                "label": "Albany",
                "label_ar": "ألباني",
                "label_fr": "Albany"
            },
            {
                "value": 76665,
                "label": "Ohaupo",
                "label_ar": "أوهاوبو",
                "label_fr": "Ohaupo"
            },
            {
                "value": 76705,
                "label": "Whangaparaoa",
                "label_ar": "وانجاباراوا",
                "label_fr": "Whangaparaoa"
            },
            {
                "value": 76838,
                "label": "Paremata",
                "label_ar": "باريماتا",
                "label_fr": "Paremata"
            },
            {
                "value": 76854,
                "label": "Waikari",
                "label_ar": "ويكاري",
                "label_fr": "Waikari"
            },
            {
                "value": 76684,
                "label": "Woodend",
                "label_ar": "وودند",
                "label_fr": "Woodend"
            },
            {
                "value": 76814,
                "label": "Coromandel",
                "label_ar": "كورومانديل",
                "label_fr": "Coromandel"
            },
            {
                "value": 76673,
                "label": "Waikawa",
                "label_ar": "وايكاوا",
                "label_fr": "Waikawa"
            },
            {
                "value": 76826,
                "label": "Maungatapere",
                "label_ar": "ماونجاتابير",
                "label_fr": "Maungatapere"
            },
            {
                "value": 76815,
                "label": "Russell",
                "label_ar": "راسل",
                "label_fr": "Russell"
            },
            {
                "value": 76866,
                "label": "Brightwater",
                "label_ar": "المياه مشرق",
                "label_fr": "Eau vive"
            },
            {
                "value": 76811,
                "label": "East Tamaki",
                "label_ar": "شرق تاماكي",
                "label_fr": "East Tamaki"
            },
            {
                "value": 76853,
                "label": "Clevedon",
                "label_ar": "كليفيدون",
                "label_fr": "Clevedon"
            },
            {
                "value": 76681,
                "label": "Linwood",
                "label_ar": "لينوود",
                "label_fr": "Linwood"
            },
            {
                "value": 76692,
                "label": "Pirongia",
                "label_ar": "بيرونجيا",
                "label_fr": "Pirongia"
            },
            {
                "value": 76656,
                "label": "Otaki",
                "label_ar": "أوتاكي",
                "label_fr": "Otaki"
            },
            {
                "value": 76649,
                "label": "Papamoa",
                "label_ar": "باباموا",
                "label_fr": "Papamoa"
            },
            {
                "value": 76601,
                "label": "Waitakere City",
                "label_ar": "مدينة وايتاكيري",
                "label_fr": "Waitakere City"
            },
            {
                "value": 76598,
                "label": "Panmure",
                "label_ar": "بانمور",
                "label_fr": "Panmure"
            },
            {
                "value": 76609,
                "label": "Waitangi",
                "label_ar": "وايتانجي",
                "label_fr": "Waitangi"
            },
            {
                "value": 76780,
                "label": "Eastbourne",
                "label_ar": "ايستبورن",
                "label_fr": "Eastbourne"
            },
            {
                "value": 76856,
                "label": "Oakura",
                "label_ar": "اوكورا",
                "label_fr": "Oakura"
            }
        ]
    },
    {
        "country": "Singapore",
        "cities": [
            {
                "value": 76903,
                "label": "Tanjong Pagar",
                "label_ar": "تانجونج باجار",
                "label_fr": "Tanjong Pagar"
            },
            {
                "value": 76885,
                "label": "Jurong Town",
                "label_ar": "مدينة جورونج",
                "label_fr": "Ville de Jurong"
            },
            {
                "value": 76875,
                "label": "Tampines New Town",
                "label_ar": "مدينة تامبينيس الجديدة",
                "label_fr": "Nouvelle ville de Tampines"
            },
            {
                "value": 76870,
                "label": "Singapore",
                "label_ar": "سنغافورة",
                "label_fr": "Singapour"
            },
            {
                "value": 76890,
                "label": "Bukit Batok New Town",
                "label_ar": "بوكيت باتوك المدينة الجديدة",
                "label_fr": "Bukit Batok Nouvelle ville"
            },
            {
                "value": 76880,
                "label": "Choa Chu Kang New Town",
                "label_ar": "مدينة تشوا تشو كانغ الجديدة",
                "label_fr": "Nouvelle ville de Choa Chu Kang"
            },
            {
                "value": 76895,
                "label": "Bukit Timah",
                "label_ar": "بوكيت تيماه",
                "label_fr": "Bukit Timah"
            },
            {
                "value": 76873,
                "label": "Ayer Raja New Town",
                "label_ar": "بلدة عير رجا الجديدة",
                "label_fr": "Ayer Raja Nouvelle ville"
            },
            {
                "value": 76881,
                "label": "Punggol",
                "label_ar": "بونغول",
                "label_fr": "Punggol"
            },
            {
                "value": 76892,
                "label": "Holland Village",
                "label_ar": "القرية الهولندية",
                "label_fr": "Holland Village"
            },
            {
                "value": 76893,
                "label": "Tai Seng",
                "label_ar": "تاي سينغ",
                "label_fr": "Tai Seng"
            },
            {
                "value": 76872,
                "label": "Bedok New Town",
                "label_ar": "مدينة بيدوك الجديدة",
                "label_fr": "Bedok Nouvelle ville"
            },
            {
                "value": 76899,
                "label": "Boon Lay",
                "label_ar": "بون لاي",
                "label_fr": "Boon Lay"
            },
            {
                "value": 76894,
                "label": "Toa Payoh New Town",
                "label_ar": "مدينة توا بايوه الجديدة",
                "label_fr": "Toa Payoh Nouvelle ville"
            },
            {
                "value": 76901,
                "label": "Pandan Valley",
                "label_ar": "وادي باندان",
                "label_fr": "Vallée de Pandan"
            },
            {
                "value": 76876,
                "label": "Ang Mo Kio New Town",
                "label_ar": "أنج مو كيو نيو تاون",
                "label_fr": "Ang Mo Kio Nouvelle ville"
            },
            {
                "value": 76898,
                "label": "Queenstown Estate",
                "label_ar": "كوينزتاون العقارية",
                "label_fr": "Domaine de Queenstown"
            },
            {
                "value": 76891,
                "label": "Pasir Panjang",
                "label_ar": "باسير بانجانج",
                "label_fr": "Pasir Panjang"
            },
            {
                "value": 76877,
                "label": "Kampong Pasir Ris",
                "label_ar": "كامبونج باسير ريس",
                "label_fr": "Kampong Pasir Ris"
            },
            {
                "value": 76887,
                "label": "Woodlands New Town",
                "label_ar": "وودلاندز نيو تاون",
                "label_fr": "Nouvelle ville de Woodlands"
            },
            {
                "value": 76900,
                "label": "Simei New Town",
                "label_ar": "بلدة سيمي الجديدة",
                "label_fr": "Nouvelle ville de Simei"
            },
            {
                "value": 76874,
                "label": "Kalang",
                "label_ar": "كالانج",
                "label_fr": "Kalang"
            },
            {
                "value": 76902,
                "label": "Clementi New Town",
                "label_ar": "مدينة كليمنتي الجديدة",
                "label_fr": "Clementi Nouvelle ville"
            },
            {
                "value": 76886,
                "label": "Tanglin Halt",
                "label_ar": "تانغلين هالت",
                "label_fr": "Halte Tanglin"
            },
            {
                "value": 76879,
                "label": "Yew Tee",
                "label_ar": "الطقسوس المحملة",
                "label_fr": "Yew Tee"
            },
            {
                "value": 76889,
                "label": "Bukit Panjang New Town",
                "label_ar": "بوكيت بانجانج نيو تاون",
                "label_fr": "Bukit Panjang Nouvelle ville"
            },
            {
                "value": 76884,
                "label": "Serangoon",
                "label_ar": "سيرانجون",
                "label_fr": "Serangoon"
            },
            {
                "value": 76882,
                "label": "Changi Village",
                "label_ar": "قرية شانغي",
                "label_fr": "Village de Changi"
            },
            {
                "value": 76883,
                "label": "Bukit Timah Estate",
                "label_ar": "عقارات بوكيت تيماه",
                "label_fr": "Domaine de Bukit Timah"
            },
            {
                "value": 76878,
                "label": "Hougang",
                "label_ar": "هوجانج",
                "label_fr": "Hougang"
            },
            {
                "value": 76871,
                "label": "Yishun New Town",
                "label_ar": "مدينة ييشون الجديدة",
                "label_fr": "Nouvelle ville de Yishun"
            },
            {
                "value": 76896,
                "label": "Jurong West New Town",
                "label_ar": "جورونج ويست نيو تاون",
                "label_fr": "Jurong West Nouvelle ville"
            },
            {
                "value": 76897,
                "label": "Kembangan",
                "label_ar": "كيمبانغان",
                "label_fr": "Kembangan"
            },
            {
                "value": 76888,
                "label": "Jurong East New Town",
                "label_ar": "جورونج إيست نيو تاون",
                "label_fr": "Nouvelle ville de Jurong East"
            }
        ]
    },
    {
        "country": "Indonesia",
        "cities": [
            {
                "value": 77150,
                "label": "Stabat",
                "label_ar": "ستابات",
                "label_fr": "Stabat"
            },
            {
                "value": 77006,
                "label": "Baturaja",
                "label_ar": "باتوراجا",
                "label_fr": "Baturaja"
            },
            {
                "value": 77099,
                "label": "Gedung",
                "label_ar": "جيدونج",
                "label_fr": "Gedung"
            },
            {
                "value": 77148,
                "label": "Soe",
                "label_ar": "سو",
                "label_fr": "Soe"
            },
            {
                "value": 77108,
                "label": "Tuban",
                "label_ar": "توبان",
                "label_fr": "Tuban"
            },
            {
                "value": 76935,
                "label": "Pekalongan",
                "label_ar": "بيكالونجان",
                "label_fr": "Pékalongan"
            },
            {
                "value": 77143,
                "label": "Palaihari",
                "label_ar": "باليهاري",
                "label_fr": "Palaihari"
            },
            {
                "value": 76915,
                "label": "Sungailiat",
                "label_ar": "سنجيلات",
                "label_fr": "Sungailiat"
            },
            {
                "value": 76936,
                "label": "Kudus",
                "label_ar": "كودوس",
                "label_fr": "Kudus"
            },
            {
                "value": 76942,
                "label": "Yogyakarta",
                "label_ar": "يوجياكارتا",
                "label_fr": "Yogyakarta"
            },
            {
                "value": 76947,
                "label": "Surakarta",
                "label_ar": "سوراكارتا",
                "label_fr": "Surakarta"
            },
            {
                "value": 76967,
                "label": "Madiun",
                "label_ar": "Madiun",
                "label_fr": "Madiun"
            },
            {
                "value": 77094,
                "label": "Tanjungpinang",
                "label_ar": "تانجونج بينانج",
                "label_fr": "Tanjungpinang"
            },
            {
                "value": 76996,
                "label": "Makassar",
                "label_ar": "ماكاسار",
                "label_fr": "Makassar"
            },
            {
                "value": 77167,
                "label": "Sejahtera",
                "label_ar": "Sejahtera",
                "label_fr": "Sejahtera"
            },
            {
                "value": 77013,
                "label": "Kepanjen",
                "label_ar": "كيبانجين",
                "label_fr": "Kepanjen"
            },
            {
                "value": 77001,
                "label": "Banda Aceh",
                "label_ar": "باندا اتشيه",
                "label_fr": "Banda Aceh"
            },
            {
                "value": 77026,
                "label": "Kerinci",
                "label_ar": "كيرينسي",
                "label_fr": "Kerinci"
            },
            {
                "value": 76976,
                "label": "Lamongan",
                "label_ar": "لامونجان",
                "label_fr": "Lamongan"
            },
            {
                "value": 76933,
                "label": "Sukoharjo",
                "label_ar": "سوكوهارجو",
                "label_fr": "Sukoharjo"
            },
            {
                "value": 77059,
                "label": "Tengah",
                "label_ar": "تنجا",
                "label_fr": "Tengah"
            },
            {
                "value": 77132,
                "label": "Pematangsiantar",
                "label_ar": "بيماتانجسيانتار",
                "label_fr": "Pematangsiantar"
            },
            {
                "value": 77074,
                "label": "Negeribesar",
                "label_ar": "نجريبصر",
                "label_fr": "Negeribesar"
            },
            {
                "value": 77031,
                "label": "Purworejo",
                "label_ar": "بوروريجو",
                "label_fr": "Purworejo"
            },
            {
                "value": 77083,
                "label": "Polerejo",
                "label_ar": "بوليريجو",
                "label_fr": "Polerejo"
            },
            {
                "value": 77101,
                "label": "Kuta",
                "label_ar": "كوتا",
                "label_fr": "Kuta"
            },
            {
                "value": 76932,
                "label": "Sumedang",
                "label_ar": "سوميدانج",
                "label_fr": "Sumedang"
            },
            {
                "value": 76943,
                "label": "Sleman",
                "label_ar": "سليمان",
                "label_fr": "Sleman"
            },
            {
                "value": 76980,
                "label": "Banjarmasin",
                "label_ar": "بانجارماسين",
                "label_fr": "Banjarmasin"
            },
            {
                "value": 76949,
                "label": "Temanggung",
                "label_ar": "تيمانجونج",
                "label_fr": "Temanggung"
            },
            {
                "value": 77160,
                "label": "Tomohon",
                "label_ar": "توموهون",
                "label_fr": "Tomohon"
            },
            {
                "value": 77123,
                "label": "Tembagapura",
                "label_ar": "تمباجابورا",
                "label_fr": "Tembagapura"
            },
            {
                "value": 77135,
                "label": "Tigarasa",
                "label_ar": "تيغاراسا",
                "label_fr": "Tigarasa"
            },
            {
                "value": 76995,
                "label": "Adiantorop",
                "label_ar": "أديانتوروب",
                "label_fr": "Adiantorop"
            },
            {
                "value": 77067,
                "label": "Serang",
                "label_ar": "سيرانج",
                "label_fr": "Serang"
            },
            {
                "value": 77081,
                "label": "Pondok",
                "label_ar": "بوندوك",
                "label_fr": "Pondok"
            },
            {
                "value": 77050,
                "label": "Duren",
                "label_ar": "دورين",
                "label_fr": "Duren"
            },
            {
                "value": 77120,
                "label": "Lawang",
                "label_ar": "لاوانج",
                "label_fr": "Lawang"
            },
            {
                "value": 77043,
                "label": "Dumai",
                "label_ar": "دوماي",
                "label_fr": "Dumai"
            },
            {
                "value": 76991,
                "label": "Tondano",
                "label_ar": "توندانو",
                "label_fr": "Tondano"
            },
            {
                "value": 77104,
                "label": "Area",
                "label_ar": "منطقة",
                "label_fr": "Zone"
            },
            {
                "value": 77096,
                "label": "Nusa",
                "label_ar": "نوسا",
                "label_fr": "Nusa"
            },
            {
                "value": 77140,
                "label": "Ungaran",
                "label_ar": "أنغاران",
                "label_fr": "Ungaran"
            },
            {
                "value": 77076,
                "label": "Lestari",
                "label_ar": "ليستاري",
                "label_fr": "Lestari"
            },
            {
                "value": 77175,
                "label": "Kemang",
                "label_ar": "كيمانج",
                "label_fr": "Kemang"
            },
            {
                "value": 77119,
                "label": "Parakan",
                "label_ar": "باراكان",
                "label_fr": "Parakan"
            },
            {
                "value": 77161,
                "label": "Citeureup",
                "label_ar": "Citeureup",
                "label_fr": "Citeureup"
            },
            {
                "value": 77121,
                "label": "Pare",
                "label_ar": "باري",
                "label_fr": "Rogner"
            },
            {
                "value": 76997,
                "label": "Sekupang",
                "label_ar": "سيكوبانج",
                "label_fr": "Sekupang"
            },
            {
                "value": 76926,
                "label": "Subang",
                "label_ar": "سوبانج",
                "label_fr": "Subang"
            },
            {
                "value": 77103,
                "label": "Mega",
                "label_ar": "ميجا",
                "label_fr": "Méga"
            },
            {
                "value": 76985,
                "label": "Denpasar",
                "label_ar": "دينباسار",
                "label_fr": "Denpasar"
            },
            {
                "value": 76924,
                "label": "Sukabumi",
                "label_ar": "سوكابومي",
                "label_fr": "Sukabumi"
            },
            {
                "value": 77165,
                "label": "Abadi",
                "label_ar": "العبادي",
                "label_fr": "Abadi"
            },
            {
                "value": 77159,
                "label": "Blora",
                "label_ar": "بلورا",
                "label_fr": "Blora"
            },
            {
                "value": 76959,
                "label": "Surabayan",
                "label_ar": "سورابايان",
                "label_fr": "Surabayan"
            },
            {
                "value": 76939,
                "label": "Demak",
                "label_ar": "ديماك",
                "label_fr": "Demak"
            },
            {
                "value": 77037,
                "label": "Darussalam",
                "label_ar": "دار السلام",
                "label_fr": "Darussalam"
            },
            {
                "value": 76912,
                "label": "Medan",
                "label_ar": "ميدان",
                "label_fr": "Medan"
            },
            {
                "value": 76952,
                "label": "Purbalingga",
                "label_ar": "بوربالينجا",
                "label_fr": "Purbalingga"
            },
            {
                "value": 77095,
                "label": "Lubuk Pakam",
                "label_ar": "لوبوك باكام",
                "label_fr": "Lubuk Pakam"
            },
            {
                "value": 77093,
                "label": "Kendal",
                "label_ar": "كندال",
                "label_fr": "Kendal"
            },
            {
                "value": 77089,
                "label": "Serang",
                "label_ar": "سيرانج",
                "label_fr": "Serang"
            },
            {
                "value": 77126,
                "label": "Cimanggis",
                "label_ar": "سيمانجيس",
                "label_fr": "Cimanggis"
            },
            {
                "value": 77060,
                "label": "Tenggara",
                "label_ar": "تنجارا",
                "label_fr": "Tenggara"
            },
            {
                "value": 76922,
                "label": "Bekasi",
                "label_ar": "بيكاسي",
                "label_fr": "Bekasi"
            },
            {
                "value": 77086,
                "label": "Nusantara",
                "label_ar": "نوسانتارا",
                "label_fr": "Nusantara"
            },
            {
                "value": 77149,
                "label": "Nusa Dua",
                "label_ar": "نوسا دوا",
                "label_fr": "Nusa Dua"
            },
            {
                "value": 77023,
                "label": "Bengkulu",
                "label_ar": "بنجكولو",
                "label_fr": "Bengkulu"
            },
            {
                "value": 77048,
                "label": "Cihampelas",
                "label_ar": "تشهامبيلاس",
                "label_fr": "Cihampelas"
            },
            {
                "value": 77088,
                "label": "Kalimantan",
                "label_ar": "كاليمانتان",
                "label_fr": "Kalimantan"
            },
            {
                "value": 77130,
                "label": "Sigli",
                "label_ar": "سيجلي",
                "label_fr": "Sigli"
            },
            {
                "value": 77014,
                "label": "Ponorogo",
                "label_ar": "بونوروجو",
                "label_fr": "Ponorogo"
            },
            {
                "value": 77008,
                "label": "Cimahi",
                "label_ar": "Cimahi",
                "label_fr": "Cimahi"
            },
            {
                "value": 76951,
                "label": "Purwokerto",
                "label_ar": "بوروكيرتو",
                "label_fr": "Purwokerto"
            },
            {
                "value": 77066,
                "label": "Tanjung Balai",
                "label_ar": "تانجونج بالاي",
                "label_fr": "Tanjung Balai"
            },
            {
                "value": 77000,
                "label": "Binjai",
                "label_ar": "Binjai",
                "label_fr": "Binjai"
            },
            {
                "value": 77002,
                "label": "Lhokseumawe",
                "label_ar": "Lhokseumawe",
                "label_fr": "Lhokseumawe"
            },
            {
                "value": 77065,
                "label": "Tenggarong",
                "label_ar": "تنجارونج",
                "label_fr": "Tenggarong"
            },
            {
                "value": 76913,
                "label": "Pangaturan",
                "label_ar": "بانغاتوران",
                "label_fr": "Pangaturan"
            },
            {
                "value": 76948,
                "label": "Bantul",
                "label_ar": "بانتول",
                "label_fr": "Bantul"
            },
            {
                "value": 77047,
                "label": "Parman",
                "label_ar": "بارمان",
                "label_fr": "Parman"
            },
            {
                "value": 77091,
                "label": "Cikini",
                "label_ar": "جيكيني",
                "label_fr": "Cikini"
            },
            {
                "value": 76937,
                "label": "Klaten",
                "label_ar": "كلاتن",
                "label_fr": "Klaten"
            },
            {
                "value": 77176,
                "label": "Tabanan",
                "label_ar": "تابانان",
                "label_fr": "Tabanan"
            },
            {
                "value": 76914,
                "label": "Tebingtinggi",
                "label_ar": "Tebingtinggi",
                "label_fr": "Tebingtinggi"
            },
            {
                "value": 76955,
                "label": "Bangkalan",
                "label_ar": "بانغالان",
                "label_fr": "Bangkalan"
            },
            {
                "value": 77034,
                "label": "Cilegon",
                "label_ar": "سيليجون",
                "label_fr": "Cilegon"
            },
            {
                "value": 77027,
                "label": "Porsea",
                "label_ar": "بورسي",
                "label_fr": "Porsée"
            },
            {
                "value": 77062,
                "label": "Simpang",
                "label_ar": "سيمبانج",
                "label_fr": "Simpang"
            },
            {
                "value": 77106,
                "label": "Soho",
                "label_ar": "سوهو",
                "label_fr": "Soho"
            },
            {
                "value": 77114,
                "label": "Amlapura",
                "label_ar": "أملابورا",
                "label_fr": "Amlapura"
            },
            {
                "value": 76940,
                "label": "Salatiga",
                "label_ar": "Salatiga",
                "label_fr": "Salatiga"
            },
            {
                "value": 76962,
                "label": "Lumajang",
                "label_ar": "Lumajang",
                "label_fr": "Lumajang"
            },
            {
                "value": 76965,
                "label": "Kediri",
                "label_ar": "كديري",
                "label_fr": "Kediri"
            },
            {
                "value": 77017,
                "label": "Sengkang",
                "label_ar": "سينغكانغ",
                "label_fr": "Sengkang"
            },
            {
                "value": 76957,
                "label": "Mojokerto",
                "label_ar": "موجوكيرتو",
                "label_fr": "Mojokerto"
            },
            {
                "value": 77018,
                "label": "Gorontalo",
                "label_ar": "جورونتالو",
                "label_fr": "Gorontalo"
            },
            {
                "value": 77111,
                "label": "Sijunjung",
                "label_ar": "سيونجونج",
                "label_fr": "Sijunjung"
            },
            {
                "value": 76917,
                "label": "Bengkalis",
                "label_ar": "بنجكاليس",
                "label_fr": "Bengkalis"
            },
            {
                "value": 77038,
                "label": "Kupang",
                "label_ar": "كوبانغ",
                "label_fr": "Kupang"
            },
            {
                "value": 77024,
                "label": "Guntung",
                "label_ar": "جونتونج",
                "label_fr": "Guntung"
            },
            {
                "value": 77042,
                "label": "Darmaga",
                "label_ar": "دارماجا",
                "label_fr": "Darmaga"
            },
            {
                "value": 76977,
                "label": "Pamekasan",
                "label_ar": "بامكاسان",
                "label_fr": "Pamekasan"
            },
            {
                "value": 77079,
                "label": "Kartasura",
                "label_ar": "كارتاسورا",
                "label_fr": "Kartasura"
            },
            {
                "value": 77040,
                "label": "Pati",
                "label_ar": "باتي",
                "label_fr": "Pati"
            },
            {
                "value": 76971,
                "label": "Banyuwangi",
                "label_ar": "بانيوانجي",
                "label_fr": "Banyuwangi"
            },
            {
                "value": 76970,
                "label": "Bojonegoro",
                "label_ar": "بوجونيغورو",
                "label_fr": "Bojonegoro"
            },
            {
                "value": 77057,
                "label": "Utara",
                "label_ar": "أوتارا",
                "label_fr": "Utara"
            },
            {
                "value": 77164,
                "label": "Badung",
                "label_ar": "بادونج",
                "label_fr": "Badung"
            },
            {
                "value": 77077,
                "label": "Kandangan",
                "label_ar": "كاندانغان",
                "label_fr": "Kandangan"
            },
            {
                "value": 77145,
                "label": "Sampit",
                "label_ar": "سامبيت",
                "label_fr": "Sampit"
            },
            {
                "value": 77133,
                "label": "Payakumbuh",
                "label_ar": "باياكومبوه",
                "label_fr": "Payakumbuh"
            },
            {
                "value": 76956,
                "label": "Pasuruan",
                "label_ar": "باسوروان",
                "label_fr": "Pasuruan"
            },
            {
                "value": 77105,
                "label": "Wilayah",
                "label_ar": "ولاية",
                "label_fr": "Wilayah"
            },
            {
                "value": 77128,
                "label": "Lapan",
                "label_ar": "لابان",
                "label_fr": "Lapan"
            },
            {
                "value": 76963,
                "label": "Tulungagung",
                "label_ar": "تولونغاغونغ",
                "label_fr": "Tulungagung"
            },
            {
                "value": 77075,
                "label": "Cempaka",
                "label_ar": "سيمباكا",
                "label_fr": "Cempaka"
            },
            {
                "value": 77092,
                "label": "Purwodadi Grobogan",
                "label_ar": "بورودادي جروبوجان",
                "label_fr": "Purwodadi Grobogan"
            },
            {
                "value": 77051,
                "label": "Angkasa",
                "label_ar": "أنجكاسا",
                "label_fr": "Angkasa"
            },
            {
                "value": 77046,
                "label": "Bukit Tinggi",
                "label_ar": "بوكيت تينجي",
                "label_fr": "Bukit Tinggi"
            },
            {
                "value": 76969,
                "label": "Nganjuk",
                "label_ar": "نجانجوك",
                "label_fr": "Nganjuk"
            },
            {
                "value": 77127,
                "label": "Kebayoran Baru",
                "label_ar": "كيبايوران بارو",
                "label_fr": "Kebayoran Baru"
            },
            {
                "value": 76921,
                "label": "Sangereng",
                "label_ar": "سانجرينج",
                "label_fr": "Sangereng"
            },
            {
                "value": 76982,
                "label": "Bontang",
                "label_ar": "بونتانغ",
                "label_fr": "Bontang"
            },
            {
                "value": 77053,
                "label": "Menara",
                "label_ar": "منارة",
                "label_fr": "Ménara"
            },
            {
                "value": 77084,
                "label": "Muntilan",
                "label_ar": "مونتيلان",
                "label_fr": "Muntilan"
            },
            {
                "value": 77039,
                "label": "Bandar Lampung",
                "label_ar": "بندر لامبونج",
                "label_fr": "Bandar Lampung"
            },
            {
                "value": 77019,
                "label": "Gianyar",
                "label_ar": "جيانيار",
                "label_fr": "Gianyar"
            },
            {
                "value": 76981,
                "label": "Buntok",
                "label_ar": "بونتوك",
                "label_fr": "Buntok"
            },
            {
                "value": 77063,
                "label": "Gunungsitoli",
                "label_ar": "جونونجسيتولي",
                "label_fr": "Gunungsitoli"
            },
            {
                "value": 76954,
                "label": "Surabaya",
                "label_ar": "سورابايا",
                "label_fr": "Surabaya"
            },
            {
                "value": 76904,
                "label": "Bandung",
                "label_ar": "باندونغ",
                "label_fr": "Bandung"
            },
            {
                "value": 76979,
                "label": "Singkawang",
                "label_ar": "سينجكاوانج",
                "label_fr": "Singkawang"
            },
            {
                "value": 76920,
                "label": "Bogor",
                "label_ar": "بوجور",
                "label_fr": "Bogor"
            },
            {
                "value": 76905,
                "label": "Jakarta",
                "label_ar": "جاكرتا",
                "label_fr": "Jakarta"
            },
            {
                "value": 77138,
                "label": "Wonogiri",
                "label_ar": "ونوجيري",
                "label_fr": "Wonogiri"
            },
            {
                "value": 76931,
                "label": "Majalengka",
                "label_ar": "Majalengka",
                "label_fr": "Majalengka"
            },
            {
                "value": 77030,
                "label": "Tirtagangga",
                "label_ar": "Tirtagangga",
                "label_fr": "Tirtagangga"
            },
            {
                "value": 76953,
                "label": "Kulon",
                "label_ar": "كولون",
                "label_fr": "Kulon"
            },
            {
                "value": 76988,
                "label": "Mataram",
                "label_ar": "ماتارام",
                "label_fr": "Mataram"
            },
            {
                "value": 77004,
                "label": "Balige",
                "label_ar": "باليج",
                "label_fr": "Balige"
            },
            {
                "value": 77153,
                "label": "Holis",
                "label_ar": "هوليس",
                "label_fr": "Holis"
            },
            {
                "value": 76923,
                "label": "Karawang",
                "label_ar": "كاراوانج",
                "label_fr": "Karawang"
            },
            {
                "value": 76975,
                "label": "Gresik",
                "label_ar": "جريسيك",
                "label_fr": "Gresik"
            },
            {
                "value": 76973,
                "label": "Situbondo",
                "label_ar": "سيتوبوندو",
                "label_fr": "Situbondo"
            },
            {
                "value": 76993,
                "label": "Bima",
                "label_ar": "بيما",
                "label_fr": "Bima"
            },
            {
                "value": 77124,
                "label": "Banjarbaru",
                "label_ar": "بانجاربارو",
                "label_fr": "Banjarbaru"
            },
            {
                "value": 77052,
                "label": "Jimbaran",
                "label_ar": "جيمباران",
                "label_fr": "Jimbaran"
            },
            {
                "value": 77033,
                "label": "Tigaraksa",
                "label_ar": "تيغاراكسا",
                "label_fr": "Tigaraksa"
            },
            {
                "value": 77169,
                "label": "Indo",
                "label_ar": "إندو",
                "label_fr": "Indo"
            },
            {
                "value": 77072,
                "label": "Poso",
                "label_ar": "بوسو",
                "label_fr": "Poso"
            },
            {
                "value": 77020,
                "label": "Jayapura",
                "label_ar": "جايابورا",
                "label_fr": "Jayapura"
            },
            {
                "value": 76911,
                "label": "Malang",
                "label_ar": "مالانج",
                "label_fr": "Malang"
            },
            {
                "value": 77025,
                "label": "Langsa",
                "label_ar": "لانجسا",
                "label_fr": "Langsa"
            },
            {
                "value": 76916,
                "label": "Palembang",
                "label_ar": "باليمبانج",
                "label_fr": "Palembang"
            },
            {
                "value": 76930,
                "label": "Kuningan",
                "label_ar": "كونينغان",
                "label_fr": "Kuningan"
            },
            {
                "value": 76908,
                "label": "Balikpapan",
                "label_ar": "باليكبابان",
                "label_fr": "Balikpapan"
            },
            {
                "value": 77041,
                "label": "Panasuan",
                "label_ar": "باناسوان",
                "label_fr": "Panasuan"
            },
            {
                "value": 77070,
                "label": "Bondowoso",
                "label_ar": "Bondowoso",
                "label_fr": "Bondowoso"
            },
            {
                "value": 77115,
                "label": "Baubau",
                "label_ar": "باوباو",
                "label_fr": "Baubau"
            },
            {
                "value": 77144,
                "label": "Tanjung",
                "label_ar": "تانجونج",
                "label_fr": "Tanjung"
            },
            {
                "value": 77112,
                "label": "Kerobokan",
                "label_ar": "كيروبوكان",
                "label_fr": "Kerobokan"
            },
            {
                "value": 76919,
                "label": "Depok",
                "label_ar": "ديبوك",
                "label_fr": "Depok"
            },
            {
                "value": 77158,
                "label": "Bantaeng",
                "label_ar": "بانتينج",
                "label_fr": "Bantaeng"
            },
            {
                "value": 77168,
                "label": "Cakrawala",
                "label_ar": "كاكراوالا",
                "label_fr": "Cakrawala"
            },
            {
                "value": 77109,
                "label": "Cilincing",
                "label_ar": "سيلينسينج",
                "label_fr": "Cilincing"
            },
            {
                "value": 77157,
                "label": "Duri",
                "label_ar": "دوري",
                "label_fr": "Duri"
            },
            {
                "value": 77085,
                "label": "Boyolali",
                "label_ar": "بويولالي",
                "label_fr": "Boyolali"
            },
            {
                "value": 76927,
                "label": "Ciamis",
                "label_ar": "سياميس",
                "label_fr": "Ciamis"
            },
            {
                "value": 77113,
                "label": "Negara",
                "label_ar": "نيجارا",
                "label_fr": "Negara"
            },
            {
                "value": 77137,
                "label": "Cibubur",
                "label_ar": "سيبوبور",
                "label_fr": "Cibubur"
            },
            {
                "value": 76961,
                "label": "Blitar",
                "label_ar": "بليتار",
                "label_fr": "Blitar"
            },
            {
                "value": 77064,
                "label": "Pemalang",
                "label_ar": "بيمالانج",
                "label_fr": "Pemalang"
            },
            {
                "value": 77139,
                "label": "Sragen",
                "label_ar": "سراجين",
                "label_fr": "Sragen"
            },
            {
                "value": 76906,
                "label": "Samarinda",
                "label_ar": "ساماريندا",
                "label_fr": "Samarinda"
            },
            {
                "value": 76934,
                "label": "Semarang",
                "label_ar": "سيمارانج",
                "label_fr": "Semarang"
            },
            {
                "value": 77036,
                "label": "Sanur",
                "label_ar": "سانور",
                "label_fr": "Sanur"
            },
            {
                "value": 76966,
                "label": "Trenggalek",
                "label_ar": "ترينجالك",
                "label_fr": "Trenggalek"
            },
            {
                "value": 77032,
                "label": "Pandeglang",
                "label_ar": "بانديجلانج",
                "label_fr": "Pandeglang"
            },
            {
                "value": 77087,
                "label": "Cipinanglatihan",
                "label_ar": "Cipinanglatihan",
                "label_fr": "Cipinanglatihan"
            },
            {
                "value": 77173,
                "label": "Udayana",
                "label_ar": "اديانا",
                "label_fr": "Udayana"
            },
            {
                "value": 77056,
                "label": "Baratjaya",
                "label_ar": "باراتجايا",
                "label_fr": "Baratjaya"
            },
            {
                "value": 77003,
                "label": "Serdang",
                "label_ar": "سيردانغ",
                "label_fr": "Serdang"
            },
            {
                "value": 76984,
                "label": "Tarakan",
                "label_ar": "تاراكان",
                "label_fr": "Tarakan"
            },
            {
                "value": 77005,
                "label": "Lampeong",
                "label_ar": "لامبونغ",
                "label_fr": "Lampeong"
            },
            {
                "value": 77156,
                "label": "Kopeng",
                "label_ar": "كوبينج",
                "label_fr": "Kopeng"
            },
            {
                "value": 77107,
                "label": "Menteng",
                "label_ar": "مينتينج",
                "label_fr": "Menteng"
            },
            {
                "value": 77154,
                "label": "Banjarnegara",
                "label_ar": "بانجارنيجارا",
                "label_fr": "Banjarnegara"
            },
            {
                "value": 77011,
                "label": "Jombang",
                "label_ar": "جومبانج",
                "label_fr": "Jombang"
            },
            {
                "value": 77069,
                "label": "Cibitung",
                "label_ar": "سيبيتونج",
                "label_fr": "Cibitung"
            },
            {
                "value": 76986,
                "label": "Badung",
                "label_ar": "بادونج",
                "label_fr": "Badung"
            },
            {
                "value": 77073,
                "label": "Ambon City",
                "label_ar": "امبون سيتي",
                "label_fr": "Ville d'Ambon"
            },
            {
                "value": 77097,
                "label": "Kelapa Dua",
                "label_ar": "كيلابا دوا",
                "label_fr": "Kelapa Dua"
            },
            {
                "value": 76950,
                "label": "Kebumen",
                "label_ar": "كيبومين",
                "label_fr": "Kebumen"
            },
            {
                "value": 76946,
                "label": "Wonosobo",
                "label_ar": "ونوسوبو",
                "label_fr": "Wonosobo"
            },
            {
                "value": 77134,
                "label": "Kebayoran Lama Selatan",
                "label_ar": "Kebayoran Lama Selatan",
                "label_fr": "Kebayoran Lama Selatan"
            },
            {
                "value": 77007,
                "label": "Bandar",
                "label_ar": "بندر",
                "label_fr": "Bandar"
            },
            {
                "value": 77012,
                "label": "Mojoagung",
                "label_ar": "موجواجونج",
                "label_fr": "Mojoagung"
            },
            {
                "value": 77016,
                "label": "Palu",
                "label_ar": "بالو",
                "label_fr": "Palu"
            },
            {
                "value": 77136,
                "label": "Purwakarta",
                "label_ar": "بورواكارتا",
                "label_fr": "Purwakarta"
            },
            {
                "value": 76987,
                "label": "Ubud",
                "label_ar": "أوبود",
                "label_fr": "Ubud"
            },
            {
                "value": 77155,
                "label": "Banjar",
                "label_ar": "بنجر",
                "label_fr": "Banjar"
            },
            {
                "value": 77151,
                "label": "Maros",
                "label_ar": "ماروس",
                "label_fr": "Maros"
            },
            {
                "value": 76994,
                "label": "Sungguminasa",
                "label_ar": "سونغجوميناسا",
                "label_fr": "Sungguminasa"
            },
            {
                "value": 77015,
                "label": "Pacitan",
                "label_ar": "باسيتان",
                "label_fr": "Pacitan"
            },
            {
                "value": 76925,
                "label": "Tasikmalaya",
                "label_ar": "تاسيكمالايا",
                "label_fr": "Tasikmalaya"
            },
            {
                "value": 77142,
                "label": "Ambarawa",
                "label_ar": "أمباراوا",
                "label_fr": "Ambarawa"
            },
            {
                "value": 77110,
                "label": "Sunggal",
                "label_ar": "سنغال",
                "label_fr": "Sunggal"
            },
            {
                "value": 76989,
                "label": "Selong",
                "label_ar": "سيلونج",
                "label_fr": "Selong"
            },
            {
                "value": 76978,
                "label": "Pontianak",
                "label_ar": "بونتياناك",
                "label_fr": "Pontianak"
            },
            {
                "value": 77010,
                "label": "Banyumas",
                "label_ar": "بانيوماس",
                "label_fr": "Banyumas"
            },
            {
                "value": 77044,
                "label": "Timur",
                "label_ar": "تيمور",
                "label_fr": "Timur"
            },
            {
                "value": 77082,
                "label": "Solo",
                "label_ar": "منفرد",
                "label_fr": "Solo"
            },
            {
                "value": 77035,
                "label": "Cilegon",
                "label_ar": "سيليجون",
                "label_fr": "Cilegon"
            },
            {
                "value": 77117,
                "label": "Sampang",
                "label_ar": "سامبانج",
                "label_fr": "Sampang"
            },
            {
                "value": 77028,
                "label": "Bali",
                "label_ar": "بالي",
                "label_fr": "Bali"
            },
            {
                "value": 77022,
                "label": "Wonosari",
                "label_ar": "ونوساري",
                "label_fr": "Wonosari"
            },
            {
                "value": 77068,
                "label": "Cikarang",
                "label_ar": "سيكارانج",
                "label_fr": "Cikarang"
            },
            {
                "value": 77098,
                "label": "Gandul",
                "label_ar": "غاندول",
                "label_fr": "Gandul"
            },
            {
                "value": 77058,
                "label": "Veteran",
                "label_ar": "مخضرم",
                "label_fr": "Vétéran"
            },
            {
                "value": 77122,
                "label": "Airmadidi",
                "label_ar": "ايرماديدي",
                "label_fr": "Airmadidi"
            },
            {
                "value": 77166,
                "label": "Anggrek",
                "label_ar": "أنجريك",
                "label_fr": "Anggrek"
            },
            {
                "value": 77131,
                "label": "Kabanjahe",
                "label_ar": "كابانجه",
                "label_fr": "Kabanjahe"
            },
            {
                "value": 77090,
                "label": "Serpong",
                "label_ar": "سيربونج",
                "label_fr": "Serpong"
            },
            {
                "value": 76998,
                "label": "Kota",
                "label_ar": "كوتا",
                "label_fr": "Kota"
            },
            {
                "value": 77029,
                "label": "Cianjur",
                "label_ar": "سيانجور",
                "label_fr": "Cianjur"
            },
            {
                "value": 77021,
                "label": "Soasio",
                "label_ar": "سواسيو",
                "label_fr": "Soasio"
            },
            {
                "value": 76964,
                "label": "Magetan",
                "label_ar": "ماجيتان",
                "label_fr": "Magétan"
            },
            {
                "value": 76992,
                "label": "Bitung",
                "label_ar": "بيتونج",
                "label_fr": "Bitung"
            },
            {
                "value": 76941,
                "label": "Tegal",
                "label_ar": "تيجال",
                "label_fr": "Tegal"
            },
            {
                "value": 77125,
                "label": "Palangka",
                "label_ar": "بالانجكا",
                "label_fr": "Palangka"
            },
            {
                "value": 77152,
                "label": "Tipar Timur",
                "label_ar": "تيبار تيمور",
                "label_fr": "Tipar Timur"
            },
            {
                "value": 76907,
                "label": "Pekanbaru",
                "label_ar": "بيكانبارو",
                "label_fr": "Pékanbaru"
            },
            {
                "value": 77116,
                "label": "Karanganyar",
                "label_ar": "كارانجانيار",
                "label_fr": "Karanganyar"
            },
            {
                "value": 77141,
                "label": "Batang",
                "label_ar": "باتانج",
                "label_fr": "Batang"
            },
            {
                "value": 77071,
                "label": "Singaraja",
                "label_ar": "سينجاراجا",
                "label_fr": "Singaraja"
            },
            {
                "value": 77102,
                "label": "Kalideres",
                "label_ar": "كاليديريس",
                "label_fr": "Kalideres"
            },
            {
                "value": 77129,
                "label": "Pusat",
                "label_ar": "بوسات",
                "label_fr": "Pusat"
            },
            {
                "value": 77009,
                "label": "Indramayu",
                "label_ar": "إندرامايو",
                "label_fr": "Indramayu"
            },
            {
                "value": 77055,
                "label": "Bantan",
                "label_ar": "بانتان",
                "label_fr": "Bantan"
            },
            {
                "value": 77054,
                "label": "Pamulang",
                "label_ar": "بامولانج",
                "label_fr": "Pamulang"
            },
            {
                "value": 77100,
                "label": "Tanjung",
                "label_ar": "تانجونج",
                "label_fr": "Tanjung"
            },
            {
                "value": 77162,
                "label": "Pekan",
                "label_ar": "بيكان",
                "label_fr": "Pékan"
            },
            {
                "value": 77049,
                "label": "Tangsel",
                "label_ar": "تانجسيل",
                "label_fr": "Tangsel"
            },
            {
                "value": 76990,
                "label": "Manado",
                "label_ar": "مانادو",
                "label_fr": "Manado"
            },
            {
                "value": 76968,
                "label": "Ngawi",
                "label_ar": "نقاوي",
                "label_fr": "Ngawi"
            },
            {
                "value": 76944,
                "label": "Cilacap",
                "label_ar": "سيلاكاب",
                "label_fr": "Cilacap"
            },
            {
                "value": 77170,
                "label": "Sentul",
                "label_ar": "سينتول",
                "label_fr": "Sentul"
            },
            {
                "value": 77172,
                "label": "Mail",
                "label_ar": "بريد",
                "label_fr": "Courrier"
            },
            {
                "value": 77163,
                "label": "Mamuju",
                "label_ar": "ماموجو",
                "label_fr": "Mamuju"
            },
            {
                "value": 76909,
                "label": "Padang",
                "label_ar": "بادانج",
                "label_fr": "Padang"
            },
            {
                "value": 76945,
                "label": "Magelang",
                "label_ar": "ماجيلانج",
                "label_fr": "Magelang"
            },
            {
                "value": 76972,
                "label": "Jember",
                "label_ar": "جمبر",
                "label_fr": "Jember"
            },
            {
                "value": 76918,
                "label": "Jambi City",
                "label_ar": "مدينة جامبي",
                "label_fr": "Ville de Jambi"
            },
            {
                "value": 77174,
                "label": "Cengkareng",
                "label_ar": "Cengkareng",
                "label_fr": "Cengkareng"
            },
            {
                "value": 77147,
                "label": "Bangli",
                "label_ar": "البنغالية",
                "label_fr": "Bangli"
            },
            {
                "value": 76910,
                "label": "Patam",
                "label_ar": "باتام",
                "label_fr": "Patam"
            },
            {
                "value": 76983,
                "label": "Palangkaraya",
                "label_ar": "بالانجكارايا",
                "label_fr": "Palangkaraya"
            },
            {
                "value": 77080,
                "label": "Jagakarsa",
                "label_ar": "جاغاكارسا",
                "label_fr": "Jagakarsa"
            },
            {
                "value": 76974,
                "label": "Probolinggo",
                "label_ar": "بروبولينجو",
                "label_fr": "Probolinggo"
            },
            {
                "value": 77045,
                "label": "Riau",
                "label_ar": "رياو",
                "label_fr": "Riau"
            },
            {
                "value": 77078,
                "label": "Ciputat",
                "label_ar": "سيبوتات",
                "label_fr": "Ciputat"
            },
            {
                "value": 76938,
                "label": "Jepara",
                "label_ar": "جيبارا",
                "label_fr": "Jepara"
            },
            {
                "value": 76958,
                "label": "Sidoarjo",
                "label_ar": "سيدوارجو",
                "label_fr": "Sidoarjo"
            },
            {
                "value": 77061,
                "label": "Selatan",
                "label_ar": "سلاتان",
                "label_fr": "Selatan"
            },
            {
                "value": 77146,
                "label": "Bulukumba",
                "label_ar": "بولوكومبا",
                "label_fr": "Bulukumba"
            },
            {
                "value": 76960,
                "label": "Batu",
                "label_ar": "باتو",
                "label_fr": "Batu"
            },
            {
                "value": 76999,
                "label": "Bangkinang",
                "label_ar": "بانجكينانج",
                "label_fr": "Bangkinang"
            },
            {
                "value": 76929,
                "label": "Garut",
                "label_ar": "جاروت",
                "label_fr": "Garut"
            },
            {
                "value": 77171,
                "label": "Utama",
                "label_ar": "أوتاما",
                "label_fr": "Utama"
            },
            {
                "value": 77118,
                "label": "Depok Jaya",
                "label_ar": "ديبوك جايا",
                "label_fr": "Depok Jaya"
            },
            {
                "value": 76928,
                "label": "Cirebon",
                "label_ar": "سيريبون",
                "label_fr": "Cirebon"
            }
        ]
    },
    {
        "country": "Nepal",
        "cities": [
            {
                "value": 77179,
                "label": "Kathmandu",
                "label_ar": "كاتماندو",
                "label_fr": "Katmandou"
            },
            {
                "value": 77180,
                "label": "Bharatpur",
                "label_ar": "بهاراتبور",
                "label_fr": "Bharatpur"
            },
            {
                "value": 77178,
                "label": "Jawlakhel",
                "label_ar": "جواخل",
                "label_fr": "Jawlakhel"
            },
            {
                "value": 77182,
                "label": "Lumbini",
                "label_ar": "لومبيني",
                "label_fr": "Lumbini"
            },
            {
                "value": 77181,
                "label": "Palpa",
                "label_ar": "بالبا",
                "label_fr": "Palpa"
            },
            {
                "value": 77177,
                "label": "Patan",
                "label_ar": "باتان",
                "label_fr": "Patan"
            }
        ]
    },
    {
        "country": "Papua New Guinea",
        "cities": [
            {
                "value": 77187,
                "label": "Arawa",
                "label_ar": "أراوا",
                "label_fr": "Arawa"
            },
            {
                "value": 77190,
                "label": "Goroka",
                "label_ar": "جوروكا",
                "label_fr": "Goroka"
            },
            {
                "value": 77195,
                "label": "Kerema",
                "label_ar": "كيريما",
                "label_fr": "Kerema"
            },
            {
                "value": 77183,
                "label": "Waigani",
                "label_ar": "وايغاني",
                "label_fr": "Waigani"
            },
            {
                "value": 77194,
                "label": "Kokopo",
                "label_ar": "كوكوبو",
                "label_fr": "Kokopo"
            },
            {
                "value": 77206,
                "label": "Mendi",
                "label_ar": "مندي",
                "label_fr": "Mendi"
            },
            {
                "value": 77198,
                "label": "Wabag",
                "label_ar": "واباج",
                "label_fr": "Wabag"
            },
            {
                "value": 77185,
                "label": "Port Moresby",
                "label_ar": "مرفئ مويسبي",
                "label_fr": "Port Moresby"
            },
            {
                "value": 77203,
                "label": "Aitape",
                "label_ar": "ايتاب",
                "label_fr": "Aitape"
            },
            {
                "value": 77208,
                "label": "Kavieng",
                "label_ar": "كافينج",
                "label_fr": "Kavieng"
            },
            {
                "value": 77192,
                "label": "Kimbe",
                "label_ar": "كيمبي",
                "label_fr": "Kimbe"
            },
            {
                "value": 77196,
                "label": "Lorengau",
                "label_ar": "لورينغاو",
                "label_fr": "Lorengau"
            },
            {
                "value": 77204,
                "label": "Kiunga",
                "label_ar": "كيونجا",
                "label_fr": "Kiunga"
            },
            {
                "value": 77193,
                "label": "Daru",
                "label_ar": "دارو",
                "label_fr": "Daru"
            },
            {
                "value": 77201,
                "label": "Nambaga",
                "label_ar": "نامباجا",
                "label_fr": "Nambaga"
            },
            {
                "value": 77197,
                "label": "Popondetta",
                "label_ar": "بوبونديتا",
                "label_fr": "Popondetta"
            },
            {
                "value": 77209,
                "label": "Kupano",
                "label_ar": "كوبانو",
                "label_fr": "Kupano"
            },
            {
                "value": 77189,
                "label": "Lae",
                "label_ar": "لاي",
                "label_fr": "Lae"
            },
            {
                "value": 77191,
                "label": "Wewak",
                "label_ar": "ويواك",
                "label_fr": "Wewak"
            },
            {
                "value": 77186,
                "label": "Namatanai",
                "label_ar": "ناماتاني",
                "label_fr": "Namatanai"
            },
            {
                "value": 77184,
                "label": "Madang",
                "label_ar": "مادانغ",
                "label_fr": "Madang"
            },
            {
                "value": 77188,
                "label": "Pongani",
                "label_ar": "بونجاني",
                "label_fr": "Pongani"
            },
            {
                "value": 77205,
                "label": "Vanimo",
                "label_ar": "فانيمو",
                "label_fr": "Vanimo"
            },
            {
                "value": 77207,
                "label": "Paivara",
                "label_ar": "بايفارا",
                "label_fr": "Paivara"
            },
            {
                "value": 77202,
                "label": "Kikori",
                "label_ar": "كيكوري",
                "label_fr": "Kikori"
            },
            {
                "value": 77200,
                "label": "Mount Hagen",
                "label_ar": "جبل هاجن",
                "label_fr": "Mont Hagen"
            },
            {
                "value": 77199,
                "label": "Kundiawa",
                "label_ar": "كوندياوا",
                "label_fr": "Kundiawa"
            }
        ]
    },
    {
        "country": "Pakistan",
        "cities": [
            {
                "value": 77270,
                "label": "Chārsadda",
                "label_ar": "شارسادا",
                "label_fr": "Chārsadda"
            },
            {
                "value": 77256,
                "label": "New Mirpur",
                "label_ar": "ميربور الجديدة",
                "label_fr": "Nouveau Mirpur"
            },
            {
                "value": 77259,
                "label": "Gulberg",
                "label_ar": "جولبرج",
                "label_fr": "Gulberg"
            },
            {
                "value": 77258,
                "label": "Saddar",
                "label_ar": "سدار",
                "label_fr": "Saddar"
            },
            {
                "value": 77262,
                "label": "Sarai Sidhu",
                "label_ar": "ساراي سيدو",
                "label_fr": "Sarai Sidhu"
            },
            {
                "value": 77252,
                "label": "Kabirwala",
                "label_ar": "كابيروالا",
                "label_fr": "Kabirwala"
            },
            {
                "value": 77219,
                "label": "Swābi",
                "label_ar": "السوابي",
                "label_fr": "Swābi"
            },
            {
                "value": 77269,
                "label": "Usman",
                "label_ar": "عثمان",
                "label_fr": "Usman"
            },
            {
                "value": 77249,
                "label": "Multan",
                "label_ar": "ملتان",
                "label_fr": "Multan"
            },
            {
                "value": 77239,
                "label": "Abbottabad",
                "label_ar": "أبوت آباد",
                "label_fr": "Abbottabad"
            },
            {
                "value": 77282,
                "label": "Pindi",
                "label_ar": "بيندي",
                "label_fr": "Pindi"
            },
            {
                "value": 77273,
                "label": "Mian Channu",
                "label_ar": "ميان تشانو",
                "label_fr": "Mian Channu"
            },
            {
                "value": 77237,
                "label": "Bahāwalpur",
                "label_ar": "باهوالبور",
                "label_fr": "Bahāwalpur"
            },
            {
                "value": 77217,
                "label": "Khanpur",
                "label_ar": "خانبور",
                "label_fr": "Khanpur"
            },
            {
                "value": 77213,
                "label": "Ziauddin",
                "label_ar": "ضياء الدين",
                "label_fr": "Ziauddin"
            },
            {
                "value": 77276,
                "label": "Jhang City",
                "label_ar": "مدينة جانغ",
                "label_fr": "Jhang City"
            },
            {
                "value": 77228,
                "label": "Sheikhupura",
                "label_ar": "شيخوبورا",
                "label_fr": "Sheikhupura"
            },
            {
                "value": 77247,
                "label": "Peshawar",
                "label_ar": "بيشاور",
                "label_fr": "Peshawar"
            },
            {
                "value": 77216,
                "label": "Rawalpindi",
                "label_ar": "روالبندي",
                "label_fr": "Rawalpindi"
            },
            {
                "value": 77221,
                "label": "Faisalabad",
                "label_ar": "فيصل أباد",
                "label_fr": "Faisalabad"
            },
            {
                "value": 77242,
                "label": "Haripur",
                "label_ar": "هاريبور",
                "label_fr": "Haripur"
            },
            {
                "value": 77233,
                "label": "Narowal",
                "label_ar": "ضيق",
                "label_fr": "Narowal"
            },
            {
                "value": 77263,
                "label": "Dera Ghazi Khan",
                "label_ar": "ديرا غازي خان",
                "label_fr": "Dera Ghazi Khan"
            },
            {
                "value": 77244,
                "label": "Plot",
                "label_ar": "قطعة",
                "label_fr": "Terrain"
            },
            {
                "value": 77265,
                "label": "Chakwal",
                "label_ar": "شاكوال",
                "label_fr": "Chakwal"
            },
            {
                "value": 77241,
                "label": "Havelian",
                "label_ar": "هافيليان",
                "label_fr": "Havelian"
            },
            {
                "value": 77245,
                "label": "Hyderabad",
                "label_ar": "حيدر أباد",
                "label_fr": "Hyderabad"
            },
            {
                "value": 77253,
                "label": "Fazal",
                "label_ar": "فضل",
                "label_fr": "Fazal"
            },
            {
                "value": 77250,
                "label": "Quetta",
                "label_ar": "كويتا",
                "label_fr": "Quetta"
            },
            {
                "value": 77278,
                "label": "Jhumra",
                "label_ar": "جمرة",
                "label_fr": "Jhumra"
            },
            {
                "value": 77268,
                "label": "Mandi",
                "label_ar": "مندي",
                "label_fr": "Mandi"
            },
            {
                "value": 77261,
                "label": "Muzaffarabad",
                "label_ar": "مظفر اباد",
                "label_fr": "Muzaffarabad"
            },
            {
                "value": 77211,
                "label": "Habib Baihk",
                "label_ar": "حبيب بايهك",
                "label_fr": "Habib Baihk"
            },
            {
                "value": 77280,
                "label": "Kohat",
                "label_ar": "كوهات",
                "label_fr": "Kohat"
            },
            {
                "value": 77227,
                "label": "Nangar",
                "label_ar": "نانجار",
                "label_fr": "Nangar"
            },
            {
                "value": 77277,
                "label": "Toba Tek Singh",
                "label_ar": "توبا تيك سينغ",
                "label_fr": "Toba Tek Singh"
            },
            {
                "value": 77229,
                "label": "Sialkot",
                "label_ar": "سيالكوت",
                "label_fr": "Sialkot"
            },
            {
                "value": 77234,
                "label": "Sargodha",
                "label_ar": "سرغودا",
                "label_fr": "Sargodha"
            },
            {
                "value": 77218,
                "label": "Jhelum",
                "label_ar": "جيلوم",
                "label_fr": "Jhelum"
            },
            {
                "value": 77222,
                "label": "Cantt",
                "label_ar": "كانت",
                "label_fr": "Cantt"
            },
            {
                "value": 77232,
                "label": "Wazirabad",
                "label_ar": "وزيراباد",
                "label_fr": "Wazirabad"
            },
            {
                "value": 77283,
                "label": "Rawlakot",
                "label_ar": "Rawlakot",
                "label_fr": "Rawlakot"
            },
            {
                "value": 77215,
                "label": "Islamabad",
                "label_ar": "اسلام آباد",
                "label_fr": "Islamabad"
            },
            {
                "value": 77274,
                "label": "Khanewal",
                "label_ar": "خانيوال",
                "label_fr": "Khanewal"
            },
            {
                "value": 77226,
                "label": "Kasur",
                "label_ar": "كاسور",
                "label_fr": "Kasur"
            },
            {
                "value": 77230,
                "label": "Mandi Bahauddin",
                "label_ar": "مندي بهاء الدين",
                "label_fr": "Mandi Bahauddin"
            },
            {
                "value": 77271,
                "label": "Nowshera",
                "label_ar": "ناوشيرا",
                "label_fr": "Nowshera"
            },
            {
                "value": 77248,
                "label": "Gujranwala",
                "label_ar": "جوجرانوالا",
                "label_fr": "Gujranwala"
            },
            {
                "value": 77236,
                "label": "Daud Khel",
                "label_ar": "داود خيل",
                "label_fr": "Daud Khel"
            },
            {
                "value": 77240,
                "label": "Batgram",
                "label_ar": "باتغرام",
                "label_fr": "Batgram"
            },
            {
                "value": 77220,
                "label": "Lahore",
                "label_ar": "لاهور",
                "label_fr": "Lahore"
            },
            {
                "value": 77264,
                "label": "Sahiwal",
                "label_ar": "ساهوال",
                "label_fr": "Sahiwal"
            },
            {
                "value": 77260,
                "label": "Gilgit",
                "label_ar": "جيلجيت",
                "label_fr": "Gilgit"
            },
            {
                "value": 77225,
                "label": "Goth Abad Magsi",
                "label_ar": "القوطي أباد ماجسي",
                "label_fr": "Goth Abad Magsi"
            },
            {
                "value": 77257,
                "label": "Fātima",
                "label_ar": "فاطمة",
                "label_fr": "Fātima"
            },
            {
                "value": 77267,
                "label": "Sukkur",
                "label_ar": "شكور",
                "label_fr": "Soukkur"
            },
            {
                "value": 77266,
                "label": "Bhimbar",
                "label_ar": "بهيمبار",
                "label_fr": "Bhimbar"
            },
            {
                "value": 77272,
                "label": "Mardan",
                "label_ar": "ماردان",
                "label_fr": "Mardan"
            },
            {
                "value": 77238,
                "label": "Burewala",
                "label_ar": "بوريوالا",
                "label_fr": "Burewala"
            },
            {
                "value": 77212,
                "label": "Karachi",
                "label_ar": "كراتشي",
                "label_fr": "Karachi"
            },
            {
                "value": 77275,
                "label": "Jhang Sadr",
                "label_ar": "جهانج الصدر",
                "label_fr": "Jhang Sadr"
            },
            {
                "value": 77254,
                "label": "Clifton",
                "label_ar": "كليفتون",
                "label_fr": "Clifton"
            },
            {
                "value": 77251,
                "label": "Khan",
                "label_ar": "خان",
                "label_fr": "Khan"
            },
            {
                "value": 77281,
                "label": "Nankana Sahib",
                "label_ar": "نانكانا صاحب",
                "label_fr": "Nankana Sahib"
            },
            {
                "value": 77231,
                "label": "Gujrat",
                "label_ar": "جوجرات",
                "label_fr": "Gujrat"
            },
            {
                "value": 77279,
                "label": "Daska",
                "label_ar": "داسكا",
                "label_fr": "Daska"
            },
            {
                "value": 77223,
                "label": "Gujar Khan",
                "label_ar": "غوجار خان",
                "label_fr": "Gujar Khan"
            },
            {
                "value": 77246,
                "label": "Miran Shah",
                "label_ar": "ميران شاه",
                "label_fr": "Miran Shah"
            },
            {
                "value": 77243,
                "label": "Mansehra",
                "label_ar": "مانسيهرا",
                "label_fr": "Mansehra"
            },
            {
                "value": 77235,
                "label": "Mianwali",
                "label_ar": "ميانوالي",
                "label_fr": "Mianwali"
            },
            {
                "value": 77224,
                "label": "Attock",
                "label_ar": "أتوك",
                "label_fr": "Attock"
            },
            {
                "value": 77210,
                "label": "Gulshan-e-Iqbal",
                "label_ar": "جولشان إقبال",
                "label_fr": "Gulshan-e-Iqbal"
            },
            {
                "value": 77255,
                "label": "Sarwar",
                "label_ar": "سروار",
                "label_fr": "Sarwar"
            },
            {
                "value": 77214,
                "label": "Okara",
                "label_ar": "اوكارا",
                "label_fr": "Okara"
            }
        ]
    },
    {
        "country": "Panama",
        "cities": [
            {
                "value": 77291,
                "label": "Plaza",
                "label_ar": "بلازا",
                "label_fr": "Place"
            },
            {
                "value": 77289,
                "label": "Chiriqui",
                "label_ar": "تشيريكي",
                "label_fr": "Chiriqui"
            },
            {
                "value": 77359,
                "label": "Playón Chico",
                "label_ar": "بلايون شيكو",
                "label_fr": "Playón Chico"
            },
            {
                "value": 77325,
                "label": "Llano Tugri",
                "label_ar": "لانو توجري",
                "label_fr": "Llano Tugri"
            },
            {
                "value": 77347,
                "label": "Campo Alegre",
                "label_ar": "كامبو أليجري",
                "label_fr": "Campo Alegre"
            },
            {
                "value": 77360,
                "label": "Curundame",
                "label_ar": "كوروندام",
                "label_fr": "Curundame"
            },
            {
                "value": 77298,
                "label": "Portobelo",
                "label_ar": "بورتوبيلو",
                "label_fr": "Portobelo"
            },
            {
                "value": 77331,
                "label": "San Felipe",
                "label_ar": "سان فيليبي",
                "label_fr": "San Felipe"
            },
            {
                "value": 77324,
                "label": "Parque Lefevre",
                "label_ar": "باركي لوفيفر",
                "label_fr": "Parque Lefevre"
            },
            {
                "value": 77332,
                "label": "Santa Isabel",
                "label_ar": "سانتا إيزابيل",
                "label_fr": "Santa Isabel"
            },
            {
                "value": 77317,
                "label": "Mulatupo",
                "label_ar": "مولاتوبو",
                "label_fr": "Mulatupo"
            },
            {
                "value": 77342,
                "label": "Curundu",
                "label_ar": "كوروندو",
                "label_fr": "Curundu"
            },
            {
                "value": 77348,
                "label": "Vista Hermosa",
                "label_ar": "فيستا هيرموسا",
                "label_fr": "Vista Hermosa"
            },
            {
                "value": 77319,
                "label": "Entre Rios",
                "label_ar": "انتري ريوس",
                "label_fr": "Entre Rios"
            },
            {
                "value": 77354,
                "label": "Cristobal",
                "label_ar": "كريستوبال",
                "label_fr": "Cristobal"
            },
            {
                "value": 77339,
                "label": "Calidonia",
                "label_ar": "كاليدونيا",
                "label_fr": "Calidonie"
            },
            {
                "value": 77334,
                "label": "Quebrada de Camilo",
                "label_ar": "كويبرادا دي كاميلو",
                "label_fr": "Quebrada de Camilo"
            },
            {
                "value": 77335,
                "label": "Arosemena",
                "label_ar": "أروسيمينا",
                "label_fr": "Arosemena"
            },
            {
                "value": 77341,
                "label": "Santa Ana",
                "label_ar": "سانتا آنا",
                "label_fr": "Santa Ana"
            },
            {
                "value": 77343,
                "label": "Elena",
                "label_ar": "ايلينا",
                "label_fr": "Elena"
            },
            {
                "value": 77305,
                "label": "Santa Clara",
                "label_ar": "سانتا كلارا",
                "label_fr": "Santa Clara"
            },
            {
                "value": 77302,
                "label": "Sacramento",
                "label_ar": "سكرامنتو",
                "label_fr": "Sacramento"
            },
            {
                "value": 77318,
                "label": "Torres Bluff",
                "label_ar": "توريس بلاف",
                "label_fr": "Torres Bluff"
            },
            {
                "value": 77313,
                "label": "Juan Franco",
                "label_ar": "خوان فرانكو",
                "label_fr": "Juan Franco"
            },
            {
                "value": 77349,
                "label": "Muerto",
                "label_ar": "Muerto",
                "label_fr": "Muerto"
            },
            {
                "value": 77316,
                "label": "Juan Diaz",
                "label_ar": "خوان دياز",
                "label_fr": "Juan Diaz"
            },
            {
                "value": 77345,
                "label": "Jimenez",
                "label_ar": "خيمينيز",
                "label_fr": "Jimenez"
            },
            {
                "value": 77326,
                "label": "Bugaba",
                "label_ar": "بوغابا",
                "label_fr": "Bugaba"
            },
            {
                "value": 77285,
                "label": "Santa Catalina",
                "label_ar": "سانتا كاتالينا",
                "label_fr": "Santa Catalina"
            },
            {
                "value": 77310,
                "label": "Santiago",
                "label_ar": "سانتياغو",
                "label_fr": "Santiago"
            },
            {
                "value": 77296,
                "label": "David",
                "label_ar": "ديفيد",
                "label_fr": "David"
            },
            {
                "value": 77346,
                "label": "El Lago",
                "label_ar": "إل لاجو",
                "label_fr": "El Lago"
            },
            {
                "value": 77358,
                "label": "Sabalo",
                "label_ar": "سابالو",
                "label_fr": "Sabalo"
            },
            {
                "value": 77357,
                "label": "Peru",
                "label_ar": "بيرو",
                "label_fr": "Pérou"
            },
            {
                "value": 77293,
                "label": "Guadalupe",
                "label_ar": "غوادالوبي",
                "label_fr": "Guadalupe"
            },
            {
                "value": 77330,
                "label": "Cerro Viento",
                "label_ar": "سيرو فينتو",
                "label_fr": "Cerro Viento"
            },
            {
                "value": 77300,
                "label": "Pueblo Nuevo",
                "label_ar": "بويبلو نويفو",
                "label_fr": "Pueblo Nuevo"
            },
            {
                "value": 77297,
                "label": "Boquete",
                "label_ar": "بوكيت",
                "label_fr": "Boquete"
            },
            {
                "value": 77309,
                "label": "Chitré",
                "label_ar": "شيتريه",
                "label_fr": "Chitré"
            },
            {
                "value": 77307,
                "label": "Torre",
                "label_ar": "توري",
                "label_fr": "Torre"
            },
            {
                "value": 77327,
                "label": "Santo Domingo",
                "label_ar": "سانتو دومينغو",
                "label_fr": "Saint-Domingue"
            },
            {
                "value": 77303,
                "label": "Arraijan",
                "label_ar": "Arraijan",
                "label_fr": "Arraijan"
            },
            {
                "value": 77333,
                "label": "San Cristobal",
                "label_ar": "سان كريستوبال",
                "label_fr": "San Cristobal"
            },
            {
                "value": 77337,
                "label": "Las Sabanas",
                "label_ar": "لاس ساباناس",
                "label_fr": "Las Sabanas"
            },
            {
                "value": 77294,
                "label": "Penonomé",
                "label_ar": "Penonomé",
                "label_fr": "Penonomé"
            },
            {
                "value": 77356,
                "label": "Aguadulce",
                "label_ar": "اجوادولس",
                "label_fr": "Aguadulce"
            },
            {
                "value": 77312,
                "label": "La Chorrera",
                "label_ar": "لا كوريرا",
                "label_fr": "La Chorrera"
            },
            {
                "value": 77284,
                "label": "Panama City",
                "label_ar": "مدينة باناما",
                "label_fr": "Ville de Panama"
            },
            {
                "value": 77323,
                "label": "San Francisco",
                "label_ar": "سان فرانسيسكو",
                "label_fr": "San Francisco"
            },
            {
                "value": 77288,
                "label": "Cocle",
                "label_ar": "كوكل",
                "label_fr": "Cocle"
            },
            {
                "value": 77315,
                "label": "Vista Alegre",
                "label_ar": "فيستا أليغري",
                "label_fr": "Vista Alegre"
            },
            {
                "value": 77351,
                "label": "La Loma",
                "label_ar": "لا لوما",
                "label_fr": "La Loma"
            },
            {
                "value": 77287,
                "label": "El Arado",
                "label_ar": "العرادو",
                "label_fr": "El Arado"
            },
            {
                "value": 77301,
                "label": "Veraguas",
                "label_ar": "فيراغواس",
                "label_fr": "Veraguas"
            },
            {
                "value": 77340,
                "label": "Corozal",
                "label_ar": "كوروزال",
                "label_fr": "Corozal"
            },
            {
                "value": 77320,
                "label": "Las Mercedes",
                "label_ar": "لاس مرسيدس",
                "label_fr": "Las Mercedes"
            },
            {
                "value": 77321,
                "label": "Rio Abajo",
                "label_ar": "ريو أباجو",
                "label_fr": "Rio Abajo"
            },
            {
                "value": 77299,
                "label": "Bella Vista",
                "label_ar": "بيلا فيستا",
                "label_fr": "Bella Vista"
            },
            {
                "value": 77355,
                "label": "Fuerte Amador",
                "label_ar": "فويرتي أمادور",
                "label_fr": "Fuerte Amador"
            },
            {
                "value": 77306,
                "label": "Balboa",
                "label_ar": "بالبوا",
                "label_fr": "Balboa"
            },
            {
                "value": 77314,
                "label": "Juan Diaz",
                "label_ar": "خوان دياز",
                "label_fr": "Juan Diaz"
            },
            {
                "value": 77286,
                "label": "Playa Blanca",
                "label_ar": "بلايا بلانكا",
                "label_fr": "Playa Blanca"
            },
            {
                "value": 77304,
                "label": "Paitilla",
                "label_ar": "بايتيلا",
                "label_fr": "Paitilla"
            },
            {
                "value": 77329,
                "label": "Las Tablas",
                "label_ar": "لاس تابلاس",
                "label_fr": "Las Tablas"
            },
            {
                "value": 77350,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 77338,
                "label": "Los Angeles",
                "label_ar": "لوس أنجلوس",
                "label_fr": "Los Angeles"
            },
            {
                "value": 77308,
                "label": "Kuba",
                "label_ar": "كوبا",
                "label_fr": "Kuba"
            },
            {
                "value": 77292,
                "label": "Bocas del Toro",
                "label_ar": "بوكاس ديل تورو",
                "label_fr": "Bocas del Toro"
            },
            {
                "value": 77322,
                "label": "El Cangrejo",
                "label_ar": "El Cangrejo",
                "label_fr": "El Cangrejo"
            },
            {
                "value": 77344,
                "label": "Albrook",
                "label_ar": "ألبروك",
                "label_fr": "Albrook"
            },
            {
                "value": 77352,
                "label": "Finca Blanco Numero Uno",
                "label_ar": "فينكا بلانكو نوميرو أونو",
                "label_fr": "Finca Blanco Numero Uno"
            },
            {
                "value": 77295,
                "label": "Chigore",
                "label_ar": "شيغور",
                "label_fr": "Chigore"
            },
            {
                "value": 77328,
                "label": "Ancon",
                "label_ar": "أنكون",
                "label_fr": "Ancon"
            },
            {
                "value": 77353,
                "label": "La Exposicion",
                "label_ar": "لا اكسبوزيكيون",
                "label_fr": "La Exposicion"
            },
            {
                "value": 77311,
                "label": "San Miguelito",
                "label_ar": "سان ميغيليتو",
                "label_fr": "San Miguelito"
            },
            {
                "value": 77336,
                "label": "Tocumen",
                "label_ar": "توكومين",
                "label_fr": "Tocumen"
            },
            {
                "value": 77290,
                "label": "Colón",
                "label_ar": "كولون",
                "label_fr": "Côlon"
            }
        ]
    },
    {
        "country": "Costa Rica",
        "cities": [
            {
                "value": 77380,
                "label": "Escazu",
                "label_ar": "اسكازو",
                "label_fr": "Escazu"
            },
            {
                "value": 77381,
                "label": "Santa Cecilia",
                "label_ar": "سانتا سيسيليا",
                "label_fr": "Santa Cecilia"
            },
            {
                "value": 77379,
                "label": "Cartago",
                "label_ar": "كارتاجو",
                "label_fr": "Cartago"
            },
            {
                "value": 77393,
                "label": "Perez",
                "label_ar": "بيريز",
                "label_fr": "Perez"
            },
            {
                "value": 77373,
                "label": "Corazon de Jesus",
                "label_ar": "كورازون دي جيسوس",
                "label_fr": "Corazon de Jésus"
            },
            {
                "value": 77420,
                "label": "Turrucares",
                "label_ar": "توروكاريس",
                "label_fr": "Turrucares"
            },
            {
                "value": 77396,
                "label": "San Ramon",
                "label_ar": "سان رامون",
                "label_fr": "San Ramon"
            },
            {
                "value": 77417,
                "label": "Sabanilla",
                "label_ar": "سابانيلا",
                "label_fr": "Sabanilla"
            },
            {
                "value": 77363,
                "label": "Puerto Jimenez",
                "label_ar": "بويرتو خيمينيز",
                "label_fr": "Puerto Jimenez"
            },
            {
                "value": 77394,
                "label": "Aserri",
                "label_ar": "عسيري",
                "label_fr": "Aserri"
            },
            {
                "value": 77385,
                "label": "Paraiso",
                "label_ar": "بارايسو",
                "label_fr": "Paraiso"
            },
            {
                "value": 77366,
                "label": "Tres Rios",
                "label_ar": "تريس ريوس",
                "label_fr": "Tres Rios"
            },
            {
                "value": 77367,
                "label": "Santa Cruz",
                "label_ar": "سانتا كروز",
                "label_fr": "Santa Cruz"
            },
            {
                "value": 77413,
                "label": "Santo Domingo",
                "label_ar": "سانتو دومينغو",
                "label_fr": "Saint-Domingue"
            },
            {
                "value": 77427,
                "label": "Quepos",
                "label_ar": "Quepos",
                "label_fr": "Quepos"
            },
            {
                "value": 77362,
                "label": "Alajuela",
                "label_ar": "ألاخويلا",
                "label_fr": "Alajuela"
            },
            {
                "value": 77370,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 77378,
                "label": "Puntarenas",
                "label_ar": "بونتاريناس",
                "label_fr": "Puntarenas"
            },
            {
                "value": 77398,
                "label": "Guapiles",
                "label_ar": "جوابيلز",
                "label_fr": "Guapiles"
            },
            {
                "value": 77371,
                "label": "Guadalupe",
                "label_ar": "غوادالوبي",
                "label_fr": "Guadalupe"
            },
            {
                "value": 77376,
                "label": "San Isidro",
                "label_ar": "سان ايسيدرو",
                "label_fr": "San Isidro"
            },
            {
                "value": 77414,
                "label": "San Joaquin",
                "label_ar": "سان جواكين",
                "label_fr": "San Joaquin"
            },
            {
                "value": 77428,
                "label": "Siquirres",
                "label_ar": "سيكيريس",
                "label_fr": "Siquirres"
            },
            {
                "value": 77369,
                "label": "Alfaro",
                "label_ar": "ألفارو",
                "label_fr": "Alfaro"
            },
            {
                "value": 77426,
                "label": "Sarchi",
                "label_ar": "سارشي",
                "label_fr": "Sarchi"
            },
            {
                "value": 77368,
                "label": "San Rafael",
                "label_ar": "سان رافائيل",
                "label_fr": "San Rafael"
            },
            {
                "value": 77390,
                "label": "San Pablo",
                "label_ar": "سان بابلو",
                "label_fr": "San Pablo"
            },
            {
                "value": 77372,
                "label": "Barva",
                "label_ar": "بارفا",
                "label_fr": "Barva"
            },
            {
                "value": 77387,
                "label": "Pavas",
                "label_ar": "بافاس",
                "label_fr": "Pavas"
            },
            {
                "value": 77399,
                "label": "San Francisco",
                "label_ar": "سان فرانسيسكو",
                "label_fr": "San Francisco"
            },
            {
                "value": 77403,
                "label": "Turrialba",
                "label_ar": "توريالبا",
                "label_fr": "Turrialba"
            },
            {
                "value": 77419,
                "label": "San Juan",
                "label_ar": "سان خوان",
                "label_fr": "San Juan"
            },
            {
                "value": 77391,
                "label": "San Rafael",
                "label_ar": "سان رافائيل",
                "label_fr": "San Rafael"
            },
            {
                "value": 77374,
                "label": "Moravia",
                "label_ar": "مورافيا",
                "label_fr": "Moravie"
            },
            {
                "value": 77397,
                "label": "Guachipelin",
                "label_ar": "جواشيبيلين",
                "label_fr": "Guachipelin"
            },
            {
                "value": 77406,
                "label": "San Francisco",
                "label_ar": "سان فرانسيسكو",
                "label_fr": "San Francisco"
            },
            {
                "value": 77405,
                "label": "Coronado",
                "label_ar": "كورونادو",
                "label_fr": "Coronado"
            },
            {
                "value": 77425,
                "label": "Poas",
                "label_ar": "بواس",
                "label_fr": "Poas"
            },
            {
                "value": 77429,
                "label": "Esparza",
                "label_ar": "اسبارزا",
                "label_fr": "Esparza"
            },
            {
                "value": 77400,
                "label": "Grecia",
                "label_ar": "اليونان",
                "label_fr": "Grecia"
            },
            {
                "value": 77410,
                "label": "Quesada",
                "label_ar": "كيسادة",
                "label_fr": "Quesada"
            },
            {
                "value": 77364,
                "label": "Santa Ana",
                "label_ar": "سانتا آنا",
                "label_fr": "Santa Ana"
            },
            {
                "value": 77383,
                "label": "Coyol",
                "label_ar": "كويول",
                "label_fr": "Coyol"
            },
            {
                "value": 77404,
                "label": "San Marcos",
                "label_ar": "سان ماركوس",
                "label_fr": "San Marcos"
            },
            {
                "value": 77418,
                "label": "San Jose de la Montana",
                "label_ar": "سان خوسيه دي لا مونتانا",
                "label_fr": "San José de la Montana"
            },
            {
                "value": 77382,
                "label": "Liberia",
                "label_ar": "ليبيريا",
                "label_fr": "Libéria"
            },
            {
                "value": 77361,
                "label": "San José",
                "label_ar": "سان خوسيه",
                "label_fr": "San José"
            },
            {
                "value": 77411,
                "label": "Zarcero",
                "label_ar": "زارسيرو",
                "label_fr": "Zarcero"
            },
            {
                "value": 77415,
                "label": "Llorente",
                "label_ar": "يورينتي",
                "label_fr": "Llorente"
            },
            {
                "value": 77407,
                "label": "Palmares",
                "label_ar": "بالماريس",
                "label_fr": "palmarès"
            },
            {
                "value": 77365,
                "label": "Heredia",
                "label_ar": "هيريديا",
                "label_fr": "Heredia"
            },
            {
                "value": 77424,
                "label": "Jaco",
                "label_ar": "جاكو",
                "label_fr": "Jaco"
            },
            {
                "value": 77408,
                "label": "Zapote",
                "label_ar": "زابوتي",
                "label_fr": "Zapote"
            },
            {
                "value": 77421,
                "label": "Miramar",
                "label_ar": "ميرامار",
                "label_fr": "Miramar"
            },
            {
                "value": 77395,
                "label": "Guacima",
                "label_ar": "جواسيما",
                "label_fr": "Guacima"
            },
            {
                "value": 77409,
                "label": "San Diego",
                "label_ar": "سان دييغو",
                "label_fr": "San Diego"
            },
            {
                "value": 77416,
                "label": "Mercedes",
                "label_ar": "مرسيدس",
                "label_fr": "Mercedes"
            },
            {
                "value": 77422,
                "label": "Filadelfia",
                "label_ar": "Filadelfia",
                "label_fr": "Filadelfia"
            },
            {
                "value": 77384,
                "label": "Curridabat",
                "label_ar": "كوريدابات",
                "label_fr": "Curridabat"
            },
            {
                "value": 77392,
                "label": "Colon",
                "label_ar": "القولون",
                "label_fr": "Côlon"
            },
            {
                "value": 77388,
                "label": "Desamparados",
                "label_ar": "ديسامبارادوس",
                "label_fr": "Desamparados"
            },
            {
                "value": 77412,
                "label": "Mexico",
                "label_ar": "المكسيك",
                "label_fr": "Mexique"
            },
            {
                "value": 77423,
                "label": "Atenas",
                "label_ar": "أتيناس",
                "label_fr": "Atenas"
            },
            {
                "value": 77401,
                "label": "Isla de Tibas",
                "label_ar": "جزيرة تيباس",
                "label_fr": "Isla de Tibas"
            },
            {
                "value": 77389,
                "label": "Limón",
                "label_ar": "ليمون",
                "label_fr": "Limón"
            },
            {
                "value": 77402,
                "label": "San Josecito",
                "label_ar": "سان جوزيتو",
                "label_fr": "San Josecito"
            },
            {
                "value": 77375,
                "label": "Alajuelita",
                "label_ar": "الاجوليتا",
                "label_fr": "Alajuelita"
            },
            {
                "value": 77386,
                "label": "Llorente",
                "label_ar": "يورينتي",
                "label_fr": "Llorente"
            },
            {
                "value": 77377,
                "label": "La Garita",
                "label_ar": "لا جاريتا",
                "label_fr": "La Garita"
            }
        ]
    },
    {
        "country": "Peru",
        "cities": [
            {
                "value": 77493,
                "label": "Talara",
                "label_ar": "تالارا",
                "label_fr": "Talara"
            },
            {
                "value": 77490,
                "label": "Ventanilla",
                "label_ar": "فينتانيلا",
                "label_fr": "Ventanilla"
            },
            {
                "value": 77500,
                "label": "Loreto",
                "label_ar": "لوريتو",
                "label_fr": "Loreto"
            },
            {
                "value": 77480,
                "label": "Santiago De Surco",
                "label_ar": "سانتياغو دي سوركو",
                "label_fr": "Santiago De Surco"
            },
            {
                "value": 77483,
                "label": "Huaytara",
                "label_ar": "هوايتارا",
                "label_fr": "Huaytara"
            },
            {
                "value": 77463,
                "label": "Pucallpa",
                "label_ar": "بوكالبا",
                "label_fr": "Pucallpa"
            },
            {
                "value": 77459,
                "label": "Jose Olaya",
                "label_ar": "جوزيه العليا",
                "label_fr": "José Olaya"
            },
            {
                "value": 77460,
                "label": "Jesus Maria",
                "label_ar": "يسوع ماريا",
                "label_fr": "Jésus Maria"
            },
            {
                "value": 77478,
                "label": "Ate",
                "label_ar": "أكل",
                "label_fr": "A mangé"
            },
            {
                "value": 77495,
                "label": "San Martin",
                "label_ar": "سان مارتن",
                "label_fr": "San Martin"
            },
            {
                "value": 77513,
                "label": "Rimac",
                "label_ar": "ريماك",
                "label_fr": "Rimac"
            },
            {
                "value": 77497,
                "label": "Maldonado",
                "label_ar": "مالدونادو",
                "label_fr": "Maldonado"
            },
            {
                "value": 77462,
                "label": "Huacho",
                "label_ar": "هواتشو",
                "label_fr": "Huacho"
            },
            {
                "value": 77455,
                "label": "Bellavista",
                "label_ar": "بيلافيستا",
                "label_fr": "Bellavista"
            },
            {
                "value": 77445,
                "label": "Huancayo",
                "label_ar": "هوانكايو",
                "label_fr": "Huancayo"
            },
            {
                "value": 77431,
                "label": "Cerro de Pasco",
                "label_ar": "سيرو دي باسكو",
                "label_fr": "Cerro de Pasco"
            },
            {
                "value": 77505,
                "label": "Tingo Maria",
                "label_ar": "تينغو ماريا",
                "label_fr": "Tingo Maria"
            },
            {
                "value": 77491,
                "label": "Huánuco",
                "label_ar": "هوانوكو",
                "label_fr": "Huánuco"
            },
            {
                "value": 77509,
                "label": "Puerto Inca",
                "label_ar": "بويرتو إنكا",
                "label_fr": "Puerto Inca"
            },
            {
                "value": 77468,
                "label": "Lince",
                "label_ar": "لينس",
                "label_fr": "Lince"
            },
            {
                "value": 77450,
                "label": "San Juan Bautista",
                "label_ar": "سان خوان باوتيستا",
                "label_fr": "San Juan Bautista"
            },
            {
                "value": 77464,
                "label": "Tacna",
                "label_ar": "تاكنا",
                "label_fr": "Tacna"
            },
            {
                "value": 77494,
                "label": "Chachapoyas",
                "label_ar": "تشاتشابوياس",
                "label_fr": "Chachapoyas"
            },
            {
                "value": 77499,
                "label": "Caras",
                "label_ar": "كاراس",
                "label_fr": "Caras"
            },
            {
                "value": 77439,
                "label": "Chimbote",
                "label_ar": "شيمبوت",
                "label_fr": "Chimbote"
            },
            {
                "value": 77482,
                "label": "Cajamarca",
                "label_ar": "كاخاماركا",
                "label_fr": "Cajamarca"
            },
            {
                "value": 77433,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 77443,
                "label": "Tarapoto",
                "label_ar": "تارابوتو",
                "label_fr": "Tarapoto"
            },
            {
                "value": 77508,
                "label": "Miraflores",
                "label_ar": "ميرافلوريس",
                "label_fr": "Miraflores"
            },
            {
                "value": 77476,
                "label": "La Molina",
                "label_ar": "لا مولينا",
                "label_fr": "La Molina"
            },
            {
                "value": 77503,
                "label": "Pisco",
                "label_ar": "بيسكو",
                "label_fr": "Pisco"
            },
            {
                "value": 77498,
                "label": "Bagua",
                "label_ar": "باغوا",
                "label_fr": "Bagua"
            },
            {
                "value": 77430,
                "label": "Lima",
                "label_ar": "ليما",
                "label_fr": "Lima"
            },
            {
                "value": 77440,
                "label": "Machu Picchu",
                "label_ar": "ماتشو بيتشو",
                "label_fr": "Machu Picchu"
            },
            {
                "value": 77449,
                "label": "Abancay",
                "label_ar": "أبانكاي",
                "label_fr": "Abancay"
            },
            {
                "value": 77437,
                "label": "Callao",
                "label_ar": "كالاو",
                "label_fr": "Callao"
            },
            {
                "value": 77444,
                "label": "Ica",
                "label_ar": "إيكا",
                "label_fr": "I CA"
            },
            {
                "value": 77492,
                "label": "Moquegua",
                "label_ar": "موكيجوا",
                "label_fr": "Moquegua"
            },
            {
                "value": 77485,
                "label": "Jaen",
                "label_ar": "خاين",
                "label_fr": "Jaen"
            },
            {
                "value": 77479,
                "label": "El Agustino",
                "label_ar": "ايل اجوستينو",
                "label_fr": "El Agustino"
            },
            {
                "value": 77471,
                "label": "La Perla",
                "label_ar": "لا بيرلا",
                "label_fr": "La Perla"
            },
            {
                "value": 77434,
                "label": "Cusco",
                "label_ar": "كوسكو",
                "label_fr": "Cusco"
            },
            {
                "value": 77453,
                "label": "Chiclayo",
                "label_ar": "تشيكلايو",
                "label_fr": "Chiclayo"
            },
            {
                "value": 77488,
                "label": "Cuzco",
                "label_ar": "كوزكو",
                "label_fr": "Cuzco"
            },
            {
                "value": 77504,
                "label": "Imperial",
                "label_ar": "إمبراطوري",
                "label_fr": "Impérial"
            },
            {
                "value": 77457,
                "label": "Chincha",
                "label_ar": "تشينشا",
                "label_fr": "Chincha"
            },
            {
                "value": 77496,
                "label": "Minas de Marcona",
                "label_ar": "ميناس دي ماركونا",
                "label_fr": "Minas de Marcona"
            },
            {
                "value": 77475,
                "label": "Barranco",
                "label_ar": "بارانكو",
                "label_fr": "Barranco"
            },
            {
                "value": 77469,
                "label": "La Punta",
                "label_ar": "لا بونتا",
                "label_fr": "La Punta"
            },
            {
                "value": 77481,
                "label": "Barranca",
                "label_ar": "بارانكا",
                "label_fr": "Barranca"
            },
            {
                "value": 77441,
                "label": "Juliaca",
                "label_ar": "جولياكا",
                "label_fr": "Juliaca"
            },
            {
                "value": 77458,
                "label": "Huaraz",
                "label_ar": "هواراز",
                "label_fr": "Huaraz"
            },
            {
                "value": 77448,
                "label": "Piura",
                "label_ar": "بيورا",
                "label_fr": "Piura"
            },
            {
                "value": 77511,
                "label": "Sullana",
                "label_ar": "سولانا",
                "label_fr": "Sullana"
            },
            {
                "value": 77461,
                "label": "Puerto Maldonado",
                "label_ar": "بويرتو مالدونادو",
                "label_fr": "Puerto Maldonado"
            },
            {
                "value": 77447,
                "label": "Ayacucho",
                "label_ar": "أياكوتشو",
                "label_fr": "Ayacucho"
            },
            {
                "value": 77465,
                "label": "Ilo",
                "label_ar": "ايلو",
                "label_fr": "Ilo"
            },
            {
                "value": 77506,
                "label": "Tumbes",
                "label_ar": "تومبيس",
                "label_fr": "Tumbes"
            },
            {
                "value": 77515,
                "label": "San Isidro",
                "label_ar": "سان ايسيدرو",
                "label_fr": "San Isidro"
            },
            {
                "value": 77467,
                "label": "Paucarpata",
                "label_ar": "باوكارباتا",
                "label_fr": "Paucarpata"
            },
            {
                "value": 77451,
                "label": "Trujillo",
                "label_ar": "تروخيو",
                "label_fr": "Trujillo"
            },
            {
                "value": 77442,
                "label": "San Borja",
                "label_ar": "سان بورجا",
                "label_fr": "San Borja"
            },
            {
                "value": 77446,
                "label": "Puno",
                "label_ar": "بونو",
                "label_fr": "Puno"
            },
            {
                "value": 77436,
                "label": "Iquitos",
                "label_ar": "إكيتوس",
                "label_fr": "Iquitos"
            },
            {
                "value": 77514,
                "label": "Santa",
                "label_ar": "سانتا",
                "label_fr": "Père Noël"
            },
            {
                "value": 77486,
                "label": "Junin",
                "label_ar": "جونين",
                "label_fr": "Junin"
            },
            {
                "value": 77438,
                "label": "San Isidro",
                "label_ar": "سان ايسيدرو",
                "label_fr": "San Isidro"
            },
            {
                "value": 77470,
                "label": "Bolivar",
                "label_ar": "بوليفار",
                "label_fr": "Bolivar"
            },
            {
                "value": 77474,
                "label": "Lurigancho",
                "label_ar": "لوريجانشو",
                "label_fr": "Lurigancho"
            },
            {
                "value": 77466,
                "label": "Lambayeque",
                "label_ar": "لامباييك",
                "label_fr": "Lambayeque"
            },
            {
                "value": 77472,
                "label": "Surquillo",
                "label_ar": "سوركويلو",
                "label_fr": "Surquillo"
            },
            {
                "value": 77510,
                "label": "Pasco",
                "label_ar": "باسكو",
                "label_fr": "Pasco"
            },
            {
                "value": 77489,
                "label": "Calle",
                "label_ar": "كالي",
                "label_fr": "Calle"
            },
            {
                "value": 77432,
                "label": "Santiago",
                "label_ar": "سانتياغو",
                "label_fr": "Santiago"
            },
            {
                "value": 77484,
                "label": "Huaral",
                "label_ar": "حورال",
                "label_fr": "Huaral"
            },
            {
                "value": 77452,
                "label": "Pimentel",
                "label_ar": "بيمينتيل",
                "label_fr": "Pimentel"
            },
            {
                "value": 77454,
                "label": "Huancavelica",
                "label_ar": "هوانكافيليكا",
                "label_fr": "Huancavelica"
            },
            {
                "value": 77507,
                "label": "Moyobamba",
                "label_ar": "مويوبامبا",
                "label_fr": "Moyobamba"
            },
            {
                "value": 77487,
                "label": "Surco",
                "label_ar": "سوركو",
                "label_fr": "Surco"
            },
            {
                "value": 77477,
                "label": "Rimac",
                "label_ar": "ريماك",
                "label_fr": "Rimac"
            },
            {
                "value": 77456,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 77473,
                "label": "San Martin",
                "label_ar": "سان مارتن",
                "label_fr": "San Martin"
            },
            {
                "value": 77501,
                "label": "Hacienda La Palma",
                "label_ar": "هاسيندا لا بالما",
                "label_fr": "Hacienda La Palma"
            },
            {
                "value": 77512,
                "label": "Progreso",
                "label_ar": "بروغريسو",
                "label_fr": "Progreso"
            },
            {
                "value": 77435,
                "label": "Arequipa",
                "label_ar": "أريكويبا",
                "label_fr": "Arequipa"
            },
            {
                "value": 77502,
                "label": "Rioja",
                "label_ar": "ريوجا",
                "label_fr": "Rioja"
            }
        ]
    },
    {
        "country": "Belize",
        "cities": [
            {
                "value": 77519,
                "label": "San Ignacio",
                "label_ar": "سان اجناسيو",
                "label_fr": "San Ignacio"
            },
            {
                "value": 77516,
                "label": "Belize City",
                "label_ar": "مدينة بليز",
                "label_fr": "Belize City"
            },
            {
                "value": 77522,
                "label": "San Pedro Town",
                "label_ar": "مدينة سان بيدرو",
                "label_fr": "Ville de San Pedro"
            },
            {
                "value": 77520,
                "label": "Ladyville",
                "label_ar": "ليديفيل",
                "label_fr": "Ladyville"
            },
            {
                "value": 77517,
                "label": "Benque Viejo del Carmen",
                "label_ar": "Benque Viejo del Carmen",
                "label_fr": "Benque Viejo del Carmen"
            },
            {
                "value": 77518,
                "label": "Belmopan",
                "label_ar": "بلموبان",
                "label_fr": "Belmopan"
            },
            {
                "value": 77521,
                "label": "Freetown Sibun",
                "label_ar": "فريتاون سيبون",
                "label_fr": "Freetown Sibun"
            }
        ]
    },
    {
        "country": "Nigeria",
        "cities": [
            {
                "value": 77594,
                "label": "Funtua",
                "label_ar": "فونتوا",
                "label_fr": "Funtua"
            },
            {
                "value": 77577,
                "label": "Abraka",
                "label_ar": "أبركة",
                "label_fr": "Abraka"
            },
            {
                "value": 77576,
                "label": "Anambra",
                "label_ar": "أنامبرا",
                "label_fr": "Anambra"
            },
            {
                "value": 77566,
                "label": "Magodo",
                "label_ar": "ماجودو",
                "label_fr": "Magodo"
            },
            {
                "value": 77582,
                "label": "Jalingo",
                "label_ar": "جالينجو",
                "label_fr": "Jalingo"
            },
            {
                "value": 77606,
                "label": "Ajegunle",
                "label_ar": "Ajegunle",
                "label_fr": "Ajegunle"
            },
            {
                "value": 77540,
                "label": "Port Harcourt",
                "label_ar": "بورت هاركورت",
                "label_fr": "Port Harcourt"
            },
            {
                "value": 77589,
                "label": "Abeokuta",
                "label_ar": "أبيوكوتا",
                "label_fr": "Abeokuta"
            },
            {
                "value": 77578,
                "label": "Abraka",
                "label_ar": "أبركة",
                "label_fr": "Abraka"
            },
            {
                "value": 77569,
                "label": "Ughelli",
                "label_ar": "أوغيلي",
                "label_fr": "Ughelli"
            },
            {
                "value": 77598,
                "label": "Adodo",
                "label_ar": "أدودو",
                "label_fr": "Adodo"
            },
            {
                "value": 77601,
                "label": "Igueben",
                "label_ar": "إيجبين",
                "label_fr": "Igueben"
            },
            {
                "value": 77573,
                "label": "Ilorin",
                "label_ar": "إيلورين",
                "label_fr": "Ilorin"
            },
            {
                "value": 77581,
                "label": "Ado-Ekiti",
                "label_ar": "Ado-Ekiti",
                "label_fr": "Ado-Ekiti"
            },
            {
                "value": 77529,
                "label": "Shomolu",
                "label_ar": "شومولو",
                "label_fr": "Shomolu"
            },
            {
                "value": 77557,
                "label": "Calabar",
                "label_ar": "كالابار",
                "label_fr": "Calabar"
            },
            {
                "value": 77547,
                "label": "Apapa",
                "label_ar": "أبابا",
                "label_fr": "Apapa"
            },
            {
                "value": 77526,
                "label": "Isolo",
                "label_ar": "ايسولو",
                "label_fr": "Isolo"
            },
            {
                "value": 77556,
                "label": "Warri",
                "label_ar": "واري",
                "label_fr": "Warri"
            },
            {
                "value": 77527,
                "label": "Yaba",
                "label_ar": "يابا",
                "label_fr": "Yaba"
            },
            {
                "value": 77574,
                "label": "Jos",
                "label_ar": "جوس",
                "label_fr": "Jos"
            },
            {
                "value": 77607,
                "label": "Chafe",
                "label_ar": "تشاف",
                "label_fr": "Irriter"
            },
            {
                "value": 77600,
                "label": "Ungwan Madaki",
                "label_ar": "أونغوان ماداكي",
                "label_fr": "Ungwan Madaki"
            },
            {
                "value": 77564,
                "label": "Yola",
                "label_ar": "يولا",
                "label_fr": "Yola"
            },
            {
                "value": 77523,
                "label": "Ikeja",
                "label_ar": "إيكيجا",
                "label_fr": "Ikeja"
            },
            {
                "value": 77565,
                "label": "Egbeda",
                "label_ar": "إجبيدا",
                "label_fr": "Egbeda"
            },
            {
                "value": 77551,
                "label": "Ikorodu",
                "label_ar": "إيكورودو",
                "label_fr": "Ikorodu"
            },
            {
                "value": 77579,
                "label": "Abuja",
                "label_ar": "أبوجا",
                "label_fr": "Abuja"
            },
            {
                "value": 77549,
                "label": "Suru-Lere",
                "label_ar": "سورو لير",
                "label_fr": "Suru-Lere"
            },
            {
                "value": 77595,
                "label": "Obafemi",
                "label_ar": "أوبافيمي",
                "label_fr": "Obafemi"
            },
            {
                "value": 77592,
                "label": "Akure",
                "label_ar": "أكوري",
                "label_fr": "Akure"
            },
            {
                "value": 77597,
                "label": "Nsukka",
                "label_ar": "نسوكا",
                "label_fr": "Nsukka"
            },
            {
                "value": 77560,
                "label": "Osogbo",
                "label_ar": "أوسوغبو",
                "label_fr": "Osogbo"
            },
            {
                "value": 77538,
                "label": "Uyo",
                "label_ar": "أويو",
                "label_fr": "Uyo"
            },
            {
                "value": 77587,
                "label": "Minna",
                "label_ar": "مينا",
                "label_fr": "Minna"
            },
            {
                "value": 77580,
                "label": "Abakaliki",
                "label_ar": "أباكاليكي",
                "label_fr": "Abakaliki"
            },
            {
                "value": 77524,
                "label": "Lagos",
                "label_ar": "لاغوس",
                "label_fr": "Lagos"
            },
            {
                "value": 77603,
                "label": "Igando",
                "label_ar": "إيجاندو",
                "label_fr": "Igando"
            },
            {
                "value": 77575,
                "label": "Warri",
                "label_ar": "واري",
                "label_fr": "Warri"
            },
            {
                "value": 77531,
                "label": "Lekki",
                "label_ar": "ليكي",
                "label_fr": "Lekki"
            },
            {
                "value": 77562,
                "label": "Odau",
                "label_ar": "أوداو",
                "label_fr": "Odau"
            },
            {
                "value": 77539,
                "label": "Mushin",
                "label_ar": "موشين",
                "label_fr": "Mushin"
            },
            {
                "value": 77537,
                "label": "Bwari",
                "label_ar": "بواري",
                "label_fr": "Bwari"
            },
            {
                "value": 77536,
                "label": "Aganga",
                "label_ar": "أجانجا",
                "label_fr": "Aganga"
            },
            {
                "value": 77555,
                "label": "Zaria",
                "label_ar": "زاريا",
                "label_fr": "Zaria"
            },
            {
                "value": 77588,
                "label": "Obudu",
                "label_ar": "أوبودو",
                "label_fr": "Obudu"
            },
            {
                "value": 77530,
                "label": "Ojo",
                "label_ar": "اوجو",
                "label_fr": "Ojo"
            },
            {
                "value": 77567,
                "label": "Ilesa",
                "label_ar": "إليسا",
                "label_fr": "Ilesa"
            },
            {
                "value": 77532,
                "label": "Ifako",
                "label_ar": "Ifako",
                "label_fr": "Ifako"
            },
            {
                "value": 77563,
                "label": "Damaturu",
                "label_ar": "داماتورو",
                "label_fr": "Damaturu"
            },
            {
                "value": 77605,
                "label": "Ikotun",
                "label_ar": "إيكوتن",
                "label_fr": "Ikotun"
            },
            {
                "value": 77591,
                "label": "Alimosho",
                "label_ar": "عليموشو",
                "label_fr": "Alimosho"
            },
            {
                "value": 77609,
                "label": "Kebbi",
                "label_ar": "كيبي",
                "label_fr": "Kebbi"
            },
            {
                "value": 77570,
                "label": "Kaduna",
                "label_ar": "كادونا",
                "label_fr": "Kaduna"
            },
            {
                "value": 77552,
                "label": "Bonny",
                "label_ar": "بوني",
                "label_fr": "Beau"
            },
            {
                "value": 77586,
                "label": "Ido",
                "label_ar": "أنا افعل",
                "label_fr": "Je fais"
            },
            {
                "value": 77602,
                "label": "Oshodi",
                "label_ar": "أوشودي",
                "label_fr": "Oshodi"
            },
            {
                "value": 77545,
                "label": "Benin City",
                "label_ar": "مدينة بنين",
                "label_fr": "Benin City"
            },
            {
                "value": 77533,
                "label": "Ayobo",
                "label_ar": "أيوبو",
                "label_fr": "Ayobo"
            },
            {
                "value": 77568,
                "label": "Onigbongbo",
                "label_ar": "أونيغبونجبو",
                "label_fr": "Onigbongbo"
            },
            {
                "value": 77543,
                "label": "Ibadan",
                "label_ar": "إبادان",
                "label_fr": "Ibadan"
            },
            {
                "value": 77599,
                "label": "Ondo",
                "label_ar": "أوندو",
                "label_fr": "Ondo"
            },
            {
                "value": 77528,
                "label": "Iseri-Oke",
                "label_ar": "إيسيري أوك",
                "label_fr": "Iseri-Oke"
            },
            {
                "value": 77541,
                "label": "Aba",
                "label_ar": "أبا",
                "label_fr": "Aba"
            },
            {
                "value": 77542,
                "label": "Forum",
                "label_ar": "المنتدى",
                "label_fr": "Forum"
            },
            {
                "value": 77535,
                "label": "Ipaja",
                "label_ar": "إيباجا",
                "label_fr": "Ipaja"
            },
            {
                "value": 77550,
                "label": "Ajuwon",
                "label_ar": "اجوون",
                "label_fr": "Ajuwon"
            },
            {
                "value": 77546,
                "label": "Mowe",
                "label_ar": "مو",
                "label_fr": "Mowe"
            },
            {
                "value": 77572,
                "label": "Enugu",
                "label_ar": "إينوجو",
                "label_fr": "Enugu"
            },
            {
                "value": 77544,
                "label": "Kano",
                "label_ar": "كانو",
                "label_fr": "Kano"
            },
            {
                "value": 77559,
                "label": "Ota",
                "label_ar": "اوتا",
                "label_fr": "Ota"
            },
            {
                "value": 77604,
                "label": "Agidingbi",
                "label_ar": "Agidingbi",
                "label_fr": "Agidingbi"
            },
            {
                "value": 77561,
                "label": "Ekpoma",
                "label_ar": "اكبوما",
                "label_fr": "Ekpoma"
            },
            {
                "value": 77558,
                "label": "Orile Oshodi",
                "label_ar": "أوريل أوشودي",
                "label_fr": "Orile Oshodi"
            },
            {
                "value": 77548,
                "label": "Ojota",
                "label_ar": "أوجوتا",
                "label_fr": "Ojota"
            },
            {
                "value": 77534,
                "label": "Suleja",
                "label_ar": "سوليجا",
                "label_fr": "Suleja"
            },
            {
                "value": 77608,
                "label": "Mogho",
                "label_ar": "موغو",
                "label_fr": "Mogho"
            },
            {
                "value": 77571,
                "label": "Owerri",
                "label_ar": "أويري",
                "label_fr": "Owerri"
            },
            {
                "value": 77593,
                "label": "Makurdi",
                "label_ar": "ماكوردي",
                "label_fr": "Makurdi"
            },
            {
                "value": 77590,
                "label": "Oworonsoki",
                "label_ar": "أوورونسوكي",
                "label_fr": "Oworonsoki"
            },
            {
                "value": 77553,
                "label": "Agege",
                "label_ar": "Agege",
                "label_fr": "Agege"
            },
            {
                "value": 77525,
                "label": "Maryland",
                "label_ar": "ماريلاند",
                "label_fr": "Maryland"
            },
            {
                "value": 77584,
                "label": "Burutu",
                "label_ar": "بوروتو",
                "label_fr": "Burutu"
            },
            {
                "value": 77554,
                "label": "Birnin Kebbi",
                "label_ar": "بيرنين كيبي",
                "label_fr": "Birnin Kebbi"
            },
            {
                "value": 77585,
                "label": "Lokoja",
                "label_ar": "لوكوجا",
                "label_fr": "Lokoja"
            },
            {
                "value": 77596,
                "label": "Tara",
                "label_ar": "تارا",
                "label_fr": "Tara"
            },
            {
                "value": 77583,
                "label": "Ile-Ife",
                "label_ar": "إيل إيف",
                "label_fr": "Ile-Ife"
            }
        ]
    },
    {
        "country": "Venezuela",
        "cities": [
            {
                "value": 77653,
                "label": "Porlamar",
                "label_ar": "بورلامار",
                "label_fr": "Porlamar"
            },
            {
                "value": 77691,
                "label": "Los Chorros",
                "label_ar": "لوس تشوروس",
                "label_fr": "Los Chorros"
            },
            {
                "value": 77616,
                "label": "Torre",
                "label_ar": "توري",
                "label_fr": "Torre"
            },
            {
                "value": 77636,
                "label": "San Antonio de Los Altos",
                "label_ar": "سان أنطونيو دي لوس ألتوس",
                "label_fr": "San Antonio de Los Altos"
            },
            {
                "value": 77669,
                "label": "San Cristóbal",
                "label_ar": "سان كريستوبال",
                "label_fr": "San Cristóbal"
            },
            {
                "value": 77619,
                "label": "Mérida",
                "label_ar": "ميريدا",
                "label_fr": "Mérida"
            },
            {
                "value": 77674,
                "label": "Pampatar",
                "label_ar": "بامباتار",
                "label_fr": "Pampatar"
            },
            {
                "value": 77655,
                "label": "Calabozo",
                "label_ar": "كالابوزو",
                "label_fr": "Calabozo"
            },
            {
                "value": 77633,
                "label": "Barquisimeto",
                "label_ar": "باركيسيميتو",
                "label_fr": "Barquisimeto"
            },
            {
                "value": 77635,
                "label": "Carrizal",
                "label_ar": "كاريزال",
                "label_fr": "Carrizal"
            },
            {
                "value": 77645,
                "label": "Catia La Mar",
                "label_ar": "كاتيا لا مار",
                "label_fr": "Catia La Mar"
            },
            {
                "value": 77640,
                "label": "Araure",
                "label_ar": "أراور",
                "label_fr": "Araure"
            },
            {
                "value": 77610,
                "label": "Puerto La Cruz",
                "label_ar": "بويرتو لا كروز",
                "label_fr": "Puerto La Cruz"
            },
            {
                "value": 77664,
                "label": "Turmero",
                "label_ar": "تورميرو",
                "label_fr": "Turmero"
            },
            {
                "value": 77650,
                "label": "Valle",
                "label_ar": "فالي",
                "label_fr": "Valle"
            },
            {
                "value": 77632,
                "label": "Punto Fijo",
                "label_ar": "بونتو فيجو",
                "label_fr": "Punto Fijo"
            },
            {
                "value": 77699,
                "label": "San Francisco",
                "label_ar": "سان فرانسيسكو",
                "label_fr": "San Francisco"
            },
            {
                "value": 77673,
                "label": "San Antonio",
                "label_ar": "سان أنطونيو",
                "label_fr": "San Antonio"
            },
            {
                "value": 77679,
                "label": "Coro",
                "label_ar": "كورو",
                "label_fr": "Coro"
            },
            {
                "value": 77662,
                "label": "La Villa del Rosario",
                "label_ar": "لا فيلا ديل روزاريو",
                "label_fr": "La Villa del Rosario"
            },
            {
                "value": 77615,
                "label": "Santa Fe",
                "label_ar": "سانتا في",
                "label_fr": "Santa Fe"
            },
            {
                "value": 77643,
                "label": "La Guaira",
                "label_ar": "لا جويرا",
                "label_fr": "La Guaira"
            },
            {
                "value": 77624,
                "label": "Maracay",
                "label_ar": "ماراكاي",
                "label_fr": "Maracay"
            },
            {
                "value": 77686,
                "label": "El Chorro",
                "label_ar": "إل تشورو",
                "label_fr": "El Chorro"
            },
            {
                "value": 77658,
                "label": "La Victoria",
                "label_ar": "لا فيكتوريا",
                "label_fr": "La Victoria"
            },
            {
                "value": 77647,
                "label": "Santa Cruz del Zulia",
                "label_ar": "سانتا كروز ديل زوليا",
                "label_fr": "Santa Cruz del Zulia"
            },
            {
                "value": 77642,
                "label": "Guatire",
                "label_ar": "غواتير",
                "label_fr": "Guatire"
            },
            {
                "value": 77611,
                "label": "Caracas",
                "label_ar": "كاراكاس",
                "label_fr": "Caracas"
            },
            {
                "value": 77659,
                "label": "Santa Teresa",
                "label_ar": "سانتا تيريزا",
                "label_fr": "Santa Teresa"
            },
            {
                "value": 77620,
                "label": "Puerto Ordaz and San Felix",
                "label_ar": "بويرتو أورداز وسان فيليكس",
                "label_fr": "Puerto Ordaz et San Felix"
            },
            {
                "value": 77665,
                "label": "Cagua",
                "label_ar": "كاجوا",
                "label_fr": "Cagua"
            },
            {
                "value": 77648,
                "label": "Bachaquero",
                "label_ar": "باشاكيرو",
                "label_fr": "Bachaquero"
            },
            {
                "value": 77663,
                "label": "Ciudad Ojeda",
                "label_ar": "سيوداد أوجيدا",
                "label_fr": "Ciudad Ojeda"
            },
            {
                "value": 77693,
                "label": "Curazaito",
                "label_ar": "كورازايتو",
                "label_fr": "Curazaito"
            },
            {
                "value": 77666,
                "label": "San Felipe",
                "label_ar": "سان فيليبي",
                "label_fr": "San Felipe"
            },
            {
                "value": 77627,
                "label": "San Jose de Guanipa",
                "label_ar": "سان خوسيه دي جوانيبا",
                "label_fr": "San José de Guanipa"
            },
            {
                "value": 77661,
                "label": "Vargas",
                "label_ar": "فارغاس",
                "label_fr": "Vargas"
            },
            {
                "value": 77644,
                "label": "Caraballeda",
                "label_ar": "كاراباليدا",
                "label_fr": "Caraballeda"
            },
            {
                "value": 77701,
                "label": "Colonia Tovar",
                "label_ar": "كولونيا توفار",
                "label_fr": "Colonia Tovar"
            },
            {
                "value": 77685,
                "label": "Monte Cristo",
                "label_ar": "مونتي كريستو",
                "label_fr": "Monte Cristo"
            },
            {
                "value": 77646,
                "label": "Maturín",
                "label_ar": "ماتورين",
                "label_fr": "Maturín"
            },
            {
                "value": 77694,
                "label": "Guanare",
                "label_ar": "Guanare",
                "label_fr": "Guanare"
            },
            {
                "value": 77641,
                "label": "Valera",
                "label_ar": "فاليرا",
                "label_fr": "Valera"
            },
            {
                "value": 77667,
                "label": "Trujillo",
                "label_ar": "تروخيو",
                "label_fr": "Trujillo"
            },
            {
                "value": 77657,
                "label": "Charallave",
                "label_ar": "شارالاف",
                "label_fr": "Charallave"
            },
            {
                "value": 77656,
                "label": "Campo de Carabobo",
                "label_ar": "كامبو دي كارابوبو",
                "label_fr": "Campo de Carabobo"
            },
            {
                "value": 77654,
                "label": "Tucupita",
                "label_ar": "توكوبيتا",
                "label_fr": "Tucupita"
            },
            {
                "value": 77621,
                "label": "Guayana",
                "label_ar": "غوايانا",
                "label_fr": "Guayana"
            },
            {
                "value": 77668,
                "label": "Anaco",
                "label_ar": "أناكو",
                "label_fr": "Anaco"
            },
            {
                "value": 77629,
                "label": "Mariguitar",
                "label_ar": "القطيفة",
                "label_fr": "Mariguitar"
            },
            {
                "value": 77680,
                "label": "Caripe",
                "label_ar": "كاريب",
                "label_fr": "Caripe"
            },
            {
                "value": 77681,
                "label": "Bejuma",
                "label_ar": "بيجوما",
                "label_fr": "Bejuma"
            },
            {
                "value": 77623,
                "label": "San Felix",
                "label_ar": "سان فيليكس",
                "label_fr": "San Felix"
            },
            {
                "value": 77625,
                "label": "Barcelona",
                "label_ar": "برشلونة",
                "label_fr": "Barcelone"
            },
            {
                "value": 77638,
                "label": "Cabudare",
                "label_ar": "كابودار",
                "label_fr": "Cabudare"
            },
            {
                "value": 77672,
                "label": "Miranda",
                "label_ar": "ميراندا",
                "label_fr": "Miranda"
            },
            {
                "value": 77688,
                "label": "Maria",
                "label_ar": "ماريا",
                "label_fr": "Maria"
            },
            {
                "value": 77676,
                "label": "Ciudad Piar",
                "label_ar": "سيوداد بيار",
                "label_fr": "Ciudad Piar"
            },
            {
                "value": 77683,
                "label": "El Rosal",
                "label_ar": "الروسال",
                "label_fr": "El Rosal"
            },
            {
                "value": 77634,
                "label": "San Diego",
                "label_ar": "سان دييغو",
                "label_fr": "San Diego"
            },
            {
                "value": 77628,
                "label": "Guarenas",
                "label_ar": "غوارينا",
                "label_fr": "Guarenas"
            },
            {
                "value": 77703,
                "label": "El Vigia",
                "label_ar": "إل فيجيا",
                "label_fr": "El Vigia"
            },
            {
                "value": 77700,
                "label": "Cua",
                "label_ar": "كوا",
                "label_fr": "Cua"
            },
            {
                "value": 77639,
                "label": "Acarigua",
                "label_ar": "أكاريجوا",
                "label_fr": "Acarigua"
            },
            {
                "value": 77698,
                "label": "Puerto Cabello",
                "label_ar": "بويرتو كابيلو",
                "label_fr": "Puerto Cabello"
            },
            {
                "value": 77622,
                "label": "Ciudad Guayana",
                "label_ar": "سيوداد غوايانا",
                "label_fr": "Ciudad Guayana"
            },
            {
                "value": 77631,
                "label": "Ciudad Bolívar",
                "label_ar": "سيوداد بوليفار",
                "label_fr": "Ciudad Bolívar"
            },
            {
                "value": 77618,
                "label": "Valencia",
                "label_ar": "فالنسيا",
                "label_fr": "Valence"
            },
            {
                "value": 77684,
                "label": "La Verdad",
                "label_ar": "لا فيرداد",
                "label_fr": "La Verdad"
            },
            {
                "value": 77651,
                "label": "Naguanagua",
                "label_ar": "ناغواناغوا",
                "label_fr": "Naguanagua"
            },
            {
                "value": 77613,
                "label": "El Roque",
                "label_ar": "إل روك",
                "label_fr": "El Roque"
            },
            {
                "value": 77675,
                "label": "El Recreo",
                "label_ar": "ال ريكريو",
                "label_fr": "El Recreo"
            },
            {
                "value": 77612,
                "label": "Ocumare",
                "label_ar": "أوكوماري",
                "label_fr": "Ocumare"
            },
            {
                "value": 77660,
                "label": "Gueiria",
                "label_ar": "جويريا",
                "label_fr": "Gueiria"
            },
            {
                "value": 77652,
                "label": "La Cruz",
                "label_ar": "لا كروز",
                "label_fr": "La Cruz"
            },
            {
                "value": 77649,
                "label": "Maiquetia",
                "label_ar": "مايكويتيا",
                "label_fr": "Maiquetia"
            },
            {
                "value": 77617,
                "label": "Maracaibo",
                "label_ar": "ماراكايبو",
                "label_fr": "Maracaibo"
            },
            {
                "value": 77677,
                "label": "Chivacoa",
                "label_ar": "شيفاكوا",
                "label_fr": "Chivacoa"
            },
            {
                "value": 77692,
                "label": "La Castellana",
                "label_ar": "لا كاستيلانا",
                "label_fr": "La Castellana"
            },
            {
                "value": 77630,
                "label": "Macuto",
                "label_ar": "ماكوتو",
                "label_fr": "Macuto"
            },
            {
                "value": 77695,
                "label": "Caja de Agua",
                "label_ar": "كاجا دي أجوا",
                "label_fr": "Caja de Agua"
            },
            {
                "value": 77704,
                "label": "Sabaneta",
                "label_ar": "سابانيتا",
                "label_fr": "Sabaneta"
            },
            {
                "value": 77626,
                "label": "Cabimas",
                "label_ar": "كابيماس",
                "label_fr": "Cabimas"
            },
            {
                "value": 77687,
                "label": "Solano",
                "label_ar": "سولانو",
                "label_fr": "Solano"
            },
            {
                "value": 77637,
                "label": "Los Teques",
                "label_ar": "لوس تيكيس",
                "label_fr": "Los Teques"
            },
            {
                "value": 77702,
                "label": "Tariba",
                "label_ar": "طريبا",
                "label_fr": "Tariba"
            },
            {
                "value": 77678,
                "label": "Londres",
                "label_ar": "لوندر",
                "label_fr": "Londres"
            },
            {
                "value": 77690,
                "label": "La Urbina",
                "label_ar": "لا أوربينا",
                "label_fr": "La Urbina"
            },
            {
                "value": 77671,
                "label": "Cumaná",
                "label_ar": "كومانا",
                "label_fr": "Cumaná"
            },
            {
                "value": 77682,
                "label": "Matanzas",
                "label_ar": "ماتانزاس",
                "label_fr": "Matanzas"
            },
            {
                "value": 77670,
                "label": "El Tigre",
                "label_ar": "El Tigre",
                "label_fr": "El Tigre"
            },
            {
                "value": 77697,
                "label": "Bolivar",
                "label_ar": "بوليفار",
                "label_fr": "Bolivar"
            },
            {
                "value": 77689,
                "label": "San Joaquin",
                "label_ar": "سان جواكين",
                "label_fr": "San Joaquin"
            },
            {
                "value": 77696,
                "label": "Barinas",
                "label_ar": "باريناس",
                "label_fr": "Barinas"
            },
            {
                "value": 77614,
                "label": "Guachara",
                "label_ar": "جواشارا",
                "label_fr": "Guachara"
            }
        ]
    },
    {
        "country": "Bahamas",
        "cities": [
            {
                "value": 77708,
                "label": "Spanish Wells",
                "label_ar": "سبانش ويلز",
                "label_fr": "Spanish Wells"
            },
            {
                "value": 77705,
                "label": "Nassau",
                "label_ar": "ناسو",
                "label_fr": "Nassau"
            },
            {
                "value": 77706,
                "label": "Freeport",
                "label_ar": "ميناء حر",
                "label_fr": "Freeport"
            },
            {
                "value": 77710,
                "label": "Dunmore Town",
                "label_ar": "مدينة دنمور",
                "label_fr": "Ville de Dunmore"
            },
            {
                "value": 77707,
                "label": "Marsh Harbour",
                "label_ar": "مارش هاربر",
                "label_fr": "Marsh Harbour"
            },
            {
                "value": 77709,
                "label": "Andros Town",
                "label_ar": "أندروس تاون",
                "label_fr": "Ville d'Andros"
            },
            {
                "value": 77711,
                "label": "Palmetto Point",
                "label_ar": "بالميتو بوينت",
                "label_fr": "Pointe Palmetto"
            }
        ]
    },
    {
        "country": "Morocco",
        "cities": [
            {
                "value": 77756,
                "label": "Afourer",
                "label_ar": "أفورر",
                "label_fr": "Afourer"
            },
            {
                "value": 77716,
                "label": "Skhirat",
                "label_ar": "الصخيرات",
                "label_fr": "Skhirat"
            },
            {
                "value": 77719,
                "label": "Kenitra",
                "label_ar": "القنيطرة",
                "label_fr": "Kenitra"
            },
            {
                "value": 77744,
                "label": "El Hajeb",
                "label_ar": "الحاجب",
                "label_fr": "El Hajeb"
            },
            {
                "value": 77735,
                "label": "Mohammedia",
                "label_ar": "المحمدية",
                "label_fr": "Mohammedia"
            },
            {
                "value": 77736,
                "label": "El Gara",
                "label_ar": "الجارا",
                "label_fr": "El Gara"
            },
            {
                "value": 77715,
                "label": "Temara",
                "label_ar": "تمارة",
                "label_fr": "Témara"
            },
            {
                "value": 77738,
                "label": "Safi",
                "label_ar": "آسفي",
                "label_fr": "Safi"
            },
            {
                "value": 77751,
                "label": "Settat",
                "label_ar": "سطات",
                "label_fr": "Settat"
            },
            {
                "value": 77721,
                "label": "Fes",
                "label_ar": "فاس",
                "label_fr": "Fès"
            },
            {
                "value": 77722,
                "label": "Tarfaya",
                "label_ar": "طرفاية",
                "label_fr": "Tarfaya"
            },
            {
                "value": 77761,
                "label": "Assa",
                "label_ar": "آسا",
                "label_fr": "Assa"
            },
            {
                "value": 77747,
                "label": "M'dik",
                "label_ar": "مديك",
                "label_fr": "M'dik"
            },
            {
                "value": 77763,
                "label": "Taza",
                "label_ar": "تازة",
                "label_fr": "Taza"
            },
            {
                "value": 77742,
                "label": "Sefrou",
                "label_ar": "صفرو",
                "label_fr": "Sefrou"
            },
            {
                "value": 77760,
                "label": "Deroua",
                "label_ar": "دروة",
                "label_fr": "Deroua"
            },
            {
                "value": 77724,
                "label": "Agadir",
                "label_ar": "أغادير",
                "label_fr": "Agadir"
            },
            {
                "value": 77739,
                "label": "Benguerir",
                "label_ar": "بن جرير",
                "label_fr": "Benguerir"
            },
            {
                "value": 77723,
                "label": "Meknes",
                "label_ar": "مكناس",
                "label_fr": "Meknès"
            },
            {
                "value": 77740,
                "label": "Tiznit",
                "label_ar": "تزنيت",
                "label_fr": "Tiznit"
            },
            {
                "value": 77729,
                "label": "Oujda",
                "label_ar": "وجدة",
                "label_fr": "Oujda"
            },
            {
                "value": 77741,
                "label": "Sidi Slimane",
                "label_ar": "سيدي سليمان",
                "label_fr": "Sidi Slimane"
            },
            {
                "value": 77726,
                "label": "Salé",
                "label_ar": "تخفيض السعر",
                "label_fr": "Vente"
            },
            {
                "value": 77725,
                "label": "Khouribga",
                "label_ar": "خريبكة",
                "label_fr": "Khouribga"
            },
            {
                "value": 77759,
                "label": "Oued Zem",
                "label_ar": "واد زم",
                "label_fr": "Oued Zem"
            },
            {
                "value": 77718,
                "label": "Rabat",
                "label_ar": "الرباط",
                "label_fr": "Rabat"
            },
            {
                "value": 77750,
                "label": "Ouazzane",
                "label_ar": "وزان",
                "label_fr": "Ouazzane"
            },
            {
                "value": 77717,
                "label": "Casablanca",
                "label_ar": "الدار البيضاء",
                "label_fr": "Casablanca"
            },
            {
                "value": 77728,
                "label": "Touissite",
                "label_ar": "تويسيت",
                "label_fr": "Touissite"
            },
            {
                "value": 77749,
                "label": "Al Hoceima",
                "label_ar": "الحسيمة",
                "label_fr": "Al Hoceima"
            },
            {
                "value": 77757,
                "label": "Beni Mellal",
                "label_ar": "بني ملال",
                "label_fr": "Beni Mellal"
            },
            {
                "value": 77748,
                "label": "Tan-Tan",
                "label_ar": "طانطان",
                "label_fr": "Tan-Tan"
            },
            {
                "value": 77714,
                "label": "Tangier",
                "label_ar": "طنجة",
                "label_fr": "Tanger"
            },
            {
                "value": 77734,
                "label": "Oulad Teima",
                "label_ar": "أولاد تايمة",
                "label_fr": "Oulad Teima"
            },
            {
                "value": 77733,
                "label": "Khemisset",
                "label_ar": "الخميسات",
                "label_fr": "Khemisset"
            },
            {
                "value": 77753,
                "label": "Tahala",
                "label_ar": "تحالة",
                "label_fr": "Tahala"
            },
            {
                "value": 77752,
                "label": "Tiflet",
                "label_ar": "تيفلت",
                "label_fr": "Tiflet"
            },
            {
                "value": 77727,
                "label": "Tétouan",
                "label_ar": "تطوان",
                "label_fr": "Tétouan"
            },
            {
                "value": 77720,
                "label": "Sidi Yahia az Za'er",
                "label_ar": "سيدي يحيى الزائر",
                "label_fr": "Sidi Yahia az Za'er"
            },
            {
                "value": 77758,
                "label": "Fkih Ben Salah",
                "label_ar": "فقيه بن صلاح",
                "label_fr": "Fkih Ben Salah"
            },
            {
                "value": 77754,
                "label": "El Jadida",
                "label_ar": "الجديدة",
                "label_fr": "El Jadida"
            },
            {
                "value": 77764,
                "label": "Mansour",
                "label_ar": "منصور",
                "label_fr": "Mansour"
            },
            {
                "value": 77730,
                "label": "Nador",
                "label_ar": "الناظور",
                "label_fr": "Nador"
            },
            {
                "value": 77713,
                "label": "Marrakesh",
                "label_ar": "مراكش",
                "label_fr": "Marrakech"
            },
            {
                "value": 77755,
                "label": "Sidi Bennour",
                "label_ar": "سيدي بنور",
                "label_fr": "Sidi Bennour"
            },
            {
                "value": 77732,
                "label": "Mehediyah",
                "label_ar": "المهدية",
                "label_fr": "Mehediyah"
            },
            {
                "value": 77737,
                "label": "Berrechid",
                "label_ar": "برشيد",
                "label_fr": "Berrechid"
            },
            {
                "value": 77745,
                "label": "Larache",
                "label_ar": "العرائش",
                "label_fr": "Larache"
            },
            {
                "value": 77746,
                "label": "Ksar el Kebir",
                "label_ar": "القصر الكبير",
                "label_fr": "Ksar el Kebir"
            },
            {
                "value": 77743,
                "label": "Ifrane de l'Anti-Atlas",
                "label_ar": "إفران دي أنتي أطلس",
                "label_fr": "Ifrane de l'Anti-Atlas"
            },
            {
                "value": 77762,
                "label": "Ait Melloul",
                "label_ar": "أيت ملول",
                "label_fr": "Ait Melloul"
            },
            {
                "value": 77731,
                "label": "Erfoud",
                "label_ar": "أرفود",
                "label_fr": "Erfoud"
            }
        ]
    },
    {
        "country": "Colombia",
        "cities": [
            {
                "value": 77793,
                "label": "Pereira",
                "label_ar": "بيريرا",
                "label_fr": "Pereira"
            },
            {
                "value": 77849,
                "label": "Ginebra",
                "label_ar": "جينبرا",
                "label_fr": "Ginebra"
            },
            {
                "value": 77871,
                "label": "Pitalito",
                "label_ar": "بيتاليتو",
                "label_fr": "Pitalito"
            },
            {
                "value": 77859,
                "label": "Chiquinquira",
                "label_ar": "تشكينكويرا",
                "label_fr": "Chiquinquira"
            },
            {
                "value": 77804,
                "label": "Yopal",
                "label_ar": "يوبال",
                "label_fr": "Yopal"
            },
            {
                "value": 77829,
                "label": "Tenjo",
                "label_ar": "تينجو",
                "label_fr": "Tenjo"
            },
            {
                "value": 77771,
                "label": "Magdalena",
                "label_ar": "ماغدالينا",
                "label_fr": "Magdalena"
            },
            {
                "value": 77837,
                "label": "Montenegro",
                "label_ar": "الجبل الأسود",
                "label_fr": "Monténégro"
            },
            {
                "value": 77838,
                "label": "Sogamoso",
                "label_ar": "سوغاموسو",
                "label_fr": "Sogamoso"
            },
            {
                "value": 77807,
                "label": "Rionegro",
                "label_ar": "ريونجرو",
                "label_fr": "Rionegro"
            },
            {
                "value": 77798,
                "label": "Santa Marta",
                "label_ar": "سانتا مارتا",
                "label_fr": "Santa Marta"
            },
            {
                "value": 77826,
                "label": "Villamaria",
                "label_ar": "فيلاماريا",
                "label_fr": "Villamaria"
            },
            {
                "value": 77777,
                "label": "Bucaramanga",
                "label_ar": "بوكارامانغا",
                "label_fr": "Bucaramanga"
            },
            {
                "value": 77839,
                "label": "Espinal",
                "label_ar": "اسبينال",
                "label_fr": "Espinal"
            },
            {
                "value": 77773,
                "label": "Villavicencio",
                "label_ar": "فيلافيسينسيو",
                "label_fr": "Villavicencio"
            },
            {
                "value": 77855,
                "label": "San Gil",
                "label_ar": "سان جيل",
                "label_fr": "San Gil"
            },
            {
                "value": 77876,
                "label": "Guarne",
                "label_ar": "جوارني",
                "label_fr": "Guarne"
            },
            {
                "value": 77821,
                "label": "Urumita",
                "label_ar": "أوروميتا",
                "label_fr": "Urumita"
            },
            {
                "value": 77846,
                "label": "Ipiales",
                "label_ar": "ابياليس",
                "label_fr": "Ipiales"
            },
            {
                "value": 77785,
                "label": "Valledupar",
                "label_ar": "فالدوبار",
                "label_fr": "Valledupar"
            },
            {
                "value": 77857,
                "label": "Purificacion",
                "label_ar": "بوريفيكاسيون",
                "label_fr": "Purificacion"
            },
            {
                "value": 77868,
                "label": "Mitu",
                "label_ar": "ميتو",
                "label_fr": "Mitu"
            },
            {
                "value": 77878,
                "label": "Yumbo",
                "label_ar": "يومبو",
                "label_fr": "Yumbo"
            },
            {
                "value": 77834,
                "label": "Silvania",
                "label_ar": "سيلفانيا",
                "label_fr": "Silvania"
            },
            {
                "value": 77766,
                "label": "Bogotá",
                "label_ar": "بوغوتا",
                "label_fr": "Bogotá"
            },
            {
                "value": 77854,
                "label": "Corozal",
                "label_ar": "كوروزال",
                "label_fr": "Corozal"
            },
            {
                "value": 77881,
                "label": "Mosquera",
                "label_ar": "موسكيرا",
                "label_fr": "Mosquera"
            },
            {
                "value": 77860,
                "label": "Caldas",
                "label_ar": "كالداس",
                "label_fr": "Caldas"
            },
            {
                "value": 77873,
                "label": "Sopo",
                "label_ar": "سوبو",
                "label_fr": "Sopo"
            },
            {
                "value": 77844,
                "label": "Puerto Carreño",
                "label_ar": "بويرتو كارينو",
                "label_fr": "Puerto Carreño"
            },
            {
                "value": 77853,
                "label": "Sachica",
                "label_ar": "ساتشيكا",
                "label_fr": "Sachica"
            },
            {
                "value": 77765,
                "label": "Medellín",
                "label_ar": "ميديلين",
                "label_fr": "Medellín"
            },
            {
                "value": 77847,
                "label": "Galapa",
                "label_ar": "جالابا",
                "label_fr": "Galapa"
            },
            {
                "value": 77808,
                "label": "Popayan",
                "label_ar": "بوبايان",
                "label_fr": "Popayan"
            },
            {
                "value": 77790,
                "label": "Fusagasuga",
                "label_ar": "فوساغاسوجا",
                "label_fr": "Fusagasuga"
            },
            {
                "value": 77783,
                "label": "Cúcuta",
                "label_ar": "كوكوتا",
                "label_fr": "Cúcuta"
            },
            {
                "value": 77805,
                "label": "Acacias",
                "label_ar": "أكاسيا",
                "label_fr": "Acacias"
            },
            {
                "value": 77809,
                "label": "Chia",
                "label_ar": "شيا",
                "label_fr": "Chia"
            },
            {
                "value": 77824,
                "label": "Soacha",
                "label_ar": "سواتشا",
                "label_fr": "Soacha"
            },
            {
                "value": 77801,
                "label": "Pasto",
                "label_ar": "باستو",
                "label_fr": "Pasto"
            },
            {
                "value": 77772,
                "label": "Palmira",
                "label_ar": "بالميرا",
                "label_fr": "Palmira"
            },
            {
                "value": 77781,
                "label": "Ibague",
                "label_ar": "إيباجي",
                "label_fr": "Ibague"
            },
            {
                "value": 77799,
                "label": "Riohacha",
                "label_ar": "ريوهاتشا",
                "label_fr": "Riohacha"
            },
            {
                "value": 77874,
                "label": "Cartago",
                "label_ar": "كارتاجو",
                "label_fr": "Cartago"
            },
            {
                "value": 77884,
                "label": "Cota",
                "label_ar": "كوتا",
                "label_fr": "Cota"
            },
            {
                "value": 77787,
                "label": "Engativa",
                "label_ar": "إنجاتيفا",
                "label_fr": "Engativa"
            },
            {
                "value": 77802,
                "label": "Tuluá",
                "label_ar": "تولوا",
                "label_fr": "Tuluá"
            },
            {
                "value": 77831,
                "label": "Buga",
                "label_ar": "بوجا",
                "label_fr": "Buga"
            },
            {
                "value": 77840,
                "label": "Sabaneta",
                "label_ar": "سابانيتا",
                "label_fr": "Sabaneta"
            },
            {
                "value": 77822,
                "label": "Dosquebradas",
                "label_ar": "دوسكويبراداس",
                "label_fr": "Dosquebradas"
            },
            {
                "value": 77886,
                "label": "Risaralda",
                "label_ar": "ريسارالدا",
                "label_fr": "Risaralda"
            },
            {
                "value": 77800,
                "label": "Sincelejo",
                "label_ar": "سينسيليجو",
                "label_fr": "Sincelejo"
            },
            {
                "value": 77784,
                "label": "Chocho",
                "label_ar": "هنود الحمر",
                "label_fr": "Chocho"
            },
            {
                "value": 77825,
                "label": "Turbaco",
                "label_ar": "تورباكو",
                "label_fr": "Turbaco"
            },
            {
                "value": 77815,
                "label": "San Clemente",
                "label_ar": "سان كليمنتي",
                "label_fr": "San Clemente"
            },
            {
                "value": 77833,
                "label": "Villa del Rosario",
                "label_ar": "فيلا ديل روزاريو",
                "label_fr": "Villa del Rosario"
            },
            {
                "value": 77883,
                "label": "Puerto Tejada",
                "label_ar": "بويرتو تيجادا",
                "label_fr": "Puerto Tejada"
            },
            {
                "value": 77864,
                "label": "Los Patios",
                "label_ar": "لوس باتيوس",
                "label_fr": "Los Patios"
            },
            {
                "value": 77820,
                "label": "San Martin",
                "label_ar": "سان مارتن",
                "label_fr": "San Martin"
            },
            {
                "value": 77812,
                "label": "Calarcá",
                "label_ar": "كالاركا",
                "label_fr": "Calarcá"
            },
            {
                "value": 77832,
                "label": "Montería",
                "label_ar": "مونتيريا",
                "label_fr": "Montería"
            },
            {
                "value": 77835,
                "label": "Florencia",
                "label_ar": "فلورنسيا",
                "label_fr": "Florencia"
            },
            {
                "value": 77789,
                "label": "Duitama",
                "label_ar": "دويتاما",
                "label_fr": "Duitama"
            },
            {
                "value": 77786,
                "label": "Tarapaca",
                "label_ar": "Tarapaca",
                "label_fr": "Tarapaca"
            },
            {
                "value": 77779,
                "label": "Neiva",
                "label_ar": "نيفا",
                "label_fr": "Neiva"
            },
            {
                "value": 77817,
                "label": "Tauramena",
                "label_ar": "تورامينا",
                "label_fr": "Tauramena"
            },
            {
                "value": 77872,
                "label": "San José del Guaviare",
                "label_ar": "سان خوسيه ديل غوافياري",
                "label_fr": "San José del Guaviare"
            },
            {
                "value": 77858,
                "label": "Boyaca",
                "label_ar": "بوياكا",
                "label_fr": "Boyaca"
            },
            {
                "value": 77797,
                "label": "Floridablanca",
                "label_ar": "فلوريدابلانكا",
                "label_fr": "Floridablanca"
            },
            {
                "value": 77814,
                "label": "San Diego",
                "label_ar": "سان دييغو",
                "label_fr": "San Diego"
            },
            {
                "value": 77852,
                "label": "Zipaquirá",
                "label_ar": "Zipaquirá",
                "label_fr": "Zipaquirá"
            },
            {
                "value": 77882,
                "label": "Cumaribo",
                "label_ar": "كوماريبو",
                "label_fr": "Cumaribo"
            },
            {
                "value": 77862,
                "label": "Arauca",
                "label_ar": "أراوكا",
                "label_fr": "Arauca"
            },
            {
                "value": 77806,
                "label": "Bello",
                "label_ar": "بيلو",
                "label_fr": "Bello"
            },
            {
                "value": 77796,
                "label": "Santa Rosa de Cabal",
                "label_ar": "سانتا روزا دي كابال",
                "label_fr": "Santa Rosa de Cabal"
            },
            {
                "value": 77774,
                "label": "Bermudez",
                "label_ar": "بيرموديز",
                "label_fr": "Bermudez"
            },
            {
                "value": 77842,
                "label": "Facatativá",
                "label_ar": "فاكاتاتيفا",
                "label_fr": "Facatativá"
            },
            {
                "value": 77879,
                "label": "Columbia",
                "label_ar": "كولومبيا",
                "label_fr": "Columbia"
            },
            {
                "value": 77830,
                "label": "Cajica",
                "label_ar": "كاجيكا",
                "label_fr": "Cajica"
            },
            {
                "value": 77880,
                "label": "Atlantico",
                "label_ar": "اتلانتيكو",
                "label_fr": "Atlantico"
            },
            {
                "value": 77866,
                "label": "Aguachica",
                "label_ar": "اجواتشيكا",
                "label_fr": "Aguachica"
            },
            {
                "value": 77768,
                "label": "Miami",
                "label_ar": "ميامي",
                "label_fr": "Miami"
            },
            {
                "value": 77870,
                "label": "Velez",
                "label_ar": "فيليز",
                "label_fr": "Velez"
            },
            {
                "value": 77828,
                "label": "Tocancipa",
                "label_ar": "توكانسيبا",
                "label_fr": "Tocancipa"
            },
            {
                "value": 77867,
                "label": "Santa Rosa del Sur",
                "label_ar": "سانتا روزا ديل سور",
                "label_fr": "Santa Rosa del Sur"
            },
            {
                "value": 77861,
                "label": "Ubaque",
                "label_ar": "أوباك",
                "label_fr": "Ubaque"
            },
            {
                "value": 77877,
                "label": "La Ceja",
                "label_ar": "لا سيجا",
                "label_fr": "La Ceja"
            },
            {
                "value": 77794,
                "label": "Armenia",
                "label_ar": "أرمينيا",
                "label_fr": "Arménie"
            },
            {
                "value": 77845,
                "label": "El Colegio",
                "label_ar": "إل كوليجيو",
                "label_fr": "El Colegio"
            },
            {
                "value": 77850,
                "label": "Yotoco",
                "label_ar": "يوتوكو",
                "label_fr": "Yotoco"
            },
            {
                "value": 77863,
                "label": "Sabana de Torres",
                "label_ar": "سابانا دي توريس",
                "label_fr": "Sabana de Torres"
            },
            {
                "value": 77778,
                "label": "San Andrés",
                "label_ar": "سان أندرياس",
                "label_fr": "San Andres"
            },
            {
                "value": 77795,
                "label": "Manizales",
                "label_ar": "مانيزاليس",
                "label_fr": "Manizales"
            },
            {
                "value": 77775,
                "label": "Acevedo",
                "label_ar": "أسيفيدو",
                "label_fr": "Acevedo"
            },
            {
                "value": 77769,
                "label": "Cundinamarca",
                "label_ar": "كونديناماركا",
                "label_fr": "Cundinamarca"
            },
            {
                "value": 77843,
                "label": "Madrid",
                "label_ar": "مدريد",
                "label_fr": "Madrid"
            },
            {
                "value": 77767,
                "label": "Barranquilla",
                "label_ar": "بارانكويلا",
                "label_fr": "Barranquilla"
            },
            {
                "value": 77803,
                "label": "Tunja",
                "label_ar": "تونجا",
                "label_fr": "Tunja"
            },
            {
                "value": 77782,
                "label": "Buenaventura",
                "label_ar": "بوينافينتورا",
                "label_fr": "Buenaventura"
            },
            {
                "value": 77810,
                "label": "Municipio de Copacabana",
                "label_ar": "MUNICIPIO دي كوباكابانا",
                "label_fr": "Municipio de Copacabana"
            },
            {
                "value": 77865,
                "label": "Cauca",
                "label_ar": "كاوكا",
                "label_fr": "Cauca"
            },
            {
                "value": 77856,
                "label": "Pamplona",
                "label_ar": "بامبلونا",
                "label_fr": "Pampelune"
            },
            {
                "value": 77776,
                "label": "Cartagena",
                "label_ar": "قرطاجنة",
                "label_fr": "Carthagène"
            },
            {
                "value": 77792,
                "label": "Itagui",
                "label_ar": "إتاجوي",
                "label_fr": "Itagui"
            },
            {
                "value": 77875,
                "label": "La Union",
                "label_ar": "لا يونيون",
                "label_fr": "La Union"
            },
            {
                "value": 77848,
                "label": "La Mesa",
                "label_ar": "لا ميسا",
                "label_fr": "La Mesa"
            },
            {
                "value": 77770,
                "label": "Antioquia",
                "label_ar": "أنتيوكيا",
                "label_fr": "Antioquia"
            },
            {
                "value": 77851,
                "label": "Obando",
                "label_ar": "أوباندو",
                "label_fr": "Obando"
            },
            {
                "value": 77836,
                "label": "Candelaria",
                "label_ar": "كانديلاريا",
                "label_fr": "Candelaria"
            },
            {
                "value": 77819,
                "label": "Calamar",
                "label_ar": "كالامار",
                "label_fr": "Calamar"
            },
            {
                "value": 77816,
                "label": "Piedecuesta",
                "label_ar": "بيديكويستا",
                "label_fr": "Piedecuesta"
            },
            {
                "value": 77827,
                "label": "Soledad",
                "label_ar": "سوليداد",
                "label_fr": "Soledad"
            },
            {
                "value": 77823,
                "label": "Fundacion",
                "label_ar": "مؤسسة",
                "label_fr": "Fundacion"
            },
            {
                "value": 77885,
                "label": "Puerta Roja",
                "label_ar": "بويرتا روجا",
                "label_fr": "Puerta Roja"
            },
            {
                "value": 77780,
                "label": "Santiago de Cali",
                "label_ar": "سانتياغو دي كالي",
                "label_fr": "Santiago de Cali"
            },
            {
                "value": 77813,
                "label": "Barrancabermeja",
                "label_ar": "بارانكابيرميخا",
                "label_fr": "Barrancabermeja"
            },
            {
                "value": 77788,
                "label": "Inirida",
                "label_ar": "انريدا",
                "label_fr": "Inirida"
            },
            {
                "value": 77818,
                "label": "Giron",
                "label_ar": "جيرون",
                "label_fr": "Giron"
            },
            {
                "value": 77841,
                "label": "Planadas",
                "label_ar": "بلاناداس",
                "label_fr": "Planadas"
            },
            {
                "value": 77811,
                "label": "La Estrella",
                "label_ar": "لا استريلا",
                "label_fr": "La Estrella"
            },
            {
                "value": 77869,
                "label": "Fonseca",
                "label_ar": "فونسيكا",
                "label_fr": "Fonseca"
            },
            {
                "value": 77791,
                "label": "Envigado",
                "label_ar": "إنفيجادو",
                "label_fr": "Envigado"
            }
        ]
    },
    {
        "country": "Seychelles",
        "cities": [
            {
                "value": 77887,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 77888,
                "label": "Takamaka",
                "label_ar": "تاكاماكا",
                "label_fr": "Takamaka"
            }
        ]
    },
    {
        "country": "Barbados",
        "cities": [
            {
                "value": 77907,
                "label": "Clapham",
                "label_ar": "كلافام",
                "label_fr": "Clapham"
            },
            {
                "value": 77902,
                "label": "Warners",
                "label_ar": "نذير",
                "label_fr": "Warners"
            },
            {
                "value": 77896,
                "label": "Bloomsbury",
                "label_ar": "بلومزبري",
                "label_fr": "Bloomsbury"
            },
            {
                "value": 77890,
                "label": "Bridgetown",
                "label_ar": "بريدجتاون",
                "label_fr": "Bridgetown"
            },
            {
                "value": 77900,
                "label": "Holetown",
                "label_ar": "هولتاون",
                "label_fr": "Holetown"
            },
            {
                "value": 77893,
                "label": "Hastings",
                "label_ar": "هاستنجز",
                "label_fr": "Hastings"
            },
            {
                "value": 77906,
                "label": "Cave Hill",
                "label_ar": "كيف هيل",
                "label_fr": "Cave Hill"
            },
            {
                "value": 77905,
                "label": "Worthing",
                "label_ar": "ورذينج",
                "label_fr": "Worthing"
            },
            {
                "value": 77911,
                "label": "Jackmans",
                "label_ar": "جاكمان",
                "label_fr": "Jackmans"
            },
            {
                "value": 77903,
                "label": "Bruce Vale",
                "label_ar": "بروس فالي",
                "label_fr": "Bruce Vale"
            },
            {
                "value": 77904,
                "label": "Bagatelle",
                "label_ar": "باجاتيل",
                "label_fr": "Bagatelle"
            },
            {
                "value": 77891,
                "label": "Pine Housing Estate",
                "label_ar": "عقارات الصنوبر السكنية",
                "label_fr": "Lotissement Pine"
            },
            {
                "value": 77901,
                "label": "Wanstead",
                "label_ar": "وانستيد",
                "label_fr": "Wanstead"
            },
            {
                "value": 77909,
                "label": "Porters",
                "label_ar": "الحمالين",
                "label_fr": "Porteurs"
            },
            {
                "value": 77895,
                "label": "Rendezvous",
                "label_ar": "موعد",
                "label_fr": "Rendez-vous"
            },
            {
                "value": 77899,
                "label": "Rockley",
                "label_ar": "روكلي",
                "label_fr": "Rockley"
            },
            {
                "value": 77897,
                "label": "The Garden",
                "label_ar": "الحديقة",
                "label_fr": "Le jardin"
            },
            {
                "value": 77910,
                "label": "Husbands",
                "label_ar": "الأزواج",
                "label_fr": "Maris"
            },
            {
                "value": 77894,
                "label": "Wildey",
                "label_ar": "وايلد",
                "label_fr": "Wildey"
            },
            {
                "value": 77898,
                "label": "Oistins",
                "label_ar": "أويستين",
                "label_fr": "Oistins"
            },
            {
                "value": 77892,
                "label": "Atlantic Shores",
                "label_ar": "شواطئ المحيط الأطلسي",
                "label_fr": "Atlantique"
            },
            {
                "value": 77908,
                "label": "White Hill",
                "label_ar": "وايت هيل",
                "label_fr": "White Hill"
            },
            {
                "value": 77889,
                "label": "Warrens",
                "label_ar": "وارينز",
                "label_fr": "Warrens"
            }
        ]
    },
    {
        "country": "Egypt",
        "cities": [
            {
                "value": 77938,
                "label": "Banha",
                "label_ar": "بنها",
                "label_fr": "Banha"
            },
            {
                "value": 77917,
                "label": "Shubra",
                "label_ar": "شبرا",
                "label_fr": "Shubra"
            },
            {
                "value": 77918,
                "label": "Damietta",
                "label_ar": "دمياط",
                "label_fr": "Damiette"
            },
            {
                "value": 77935,
                "label": "Faraskur",
                "label_ar": "فارسكور",
                "label_fr": "Faraskur"
            },
            {
                "value": 77921,
                "label": "Al Mansurah",
                "label_ar": "المنصورة",
                "label_fr": "Al Mansurah"
            },
            {
                "value": 77916,
                "label": "Helwan",
                "label_ar": "حلوان",
                "label_fr": "Helwan"
            },
            {
                "value": 77929,
                "label": "Heliopolis",
                "label_ar": "مصر الجديدة",
                "label_fr": "Héliopolis"
            },
            {
                "value": 77943,
                "label": "Almazah",
                "label_ar": "الماظة",
                "label_fr": "Almazah"
            },
            {
                "value": 77928,
                "label": "Al Mahallah al Kubra",
                "label_ar": "المحلة الكبرى",
                "label_fr": "Al Mahallah al Kubra"
            },
            {
                "value": 77915,
                "label": "Giza",
                "label_ar": "الجيزة",
                "label_fr": "Gizeh"
            },
            {
                "value": 77920,
                "label": "Madinat an Nasr",
                "label_ar": "مدينة نصر",
                "label_fr": "Madinat an Nasr"
            },
            {
                "value": 77912,
                "label": "Cairo",
                "label_ar": "القاهرة",
                "label_fr": "Caire"
            },
            {
                "value": 77946,
                "label": "Abu Hammad",
                "label_ar": "ابو حماد",
                "label_fr": "Abu Hammad"
            },
            {
                "value": 77939,
                "label": "Port Said",
                "label_ar": "بورسعيد",
                "label_fr": "Port-Saïd"
            },
            {
                "value": 77931,
                "label": "Az Zamalik",
                "label_ar": "الزمالك",
                "label_fr": "Az Zamalik"
            },
            {
                "value": 77940,
                "label": "Luxor",
                "label_ar": "الأقصر",
                "label_fr": "Louxor"
            },
            {
                "value": 77930,
                "label": "Hurghada",
                "label_ar": "الغردقة",
                "label_fr": "Hurghada"
            },
            {
                "value": 77919,
                "label": "Suez",
                "label_ar": "السويس",
                "label_fr": "Suez"
            },
            {
                "value": 77925,
                "label": "Zagazig",
                "label_ar": "الزقازيق",
                "label_fr": "Zagazig"
            },
            {
                "value": 77926,
                "label": "Ramsis",
                "label_ar": "رمسيس",
                "label_fr": "Ramsis"
            },
            {
                "value": 77937,
                "label": "Toukh",
                "label_ar": "طوخ",
                "label_fr": "Toukh"
            },
            {
                "value": 77933,
                "label": "Madinat as Sadis min Uktubar",
                "label_ar": "مدينة مثل ساديس من أوكتوبار",
                "label_fr": "Madinat comme Sadis min Uktubar"
            },
            {
                "value": 77949,
                "label": "Kafr ash Shaykh",
                "label_ar": "كفر الشيخ",
                "label_fr": "Kafr ash Shaykh"
            },
            {
                "value": 77924,
                "label": "Tanta",
                "label_ar": "طنطا",
                "label_fr": "Tanta"
            },
            {
                "value": 77944,
                "label": "Assiut",
                "label_ar": "أسيوط",
                "label_fr": "Assiut"
            },
            {
                "value": 77950,
                "label": "Badr",
                "label_ar": "بدر",
                "label_fr": "Badr"
            },
            {
                "value": 77942,
                "label": "Sadat",
                "label_ar": "السادات",
                "label_fr": "Sadate"
            },
            {
                "value": 77923,
                "label": "Sohag",
                "label_ar": "سوهاج",
                "label_fr": "Sohag"
            },
            {
                "value": 77941,
                "label": "Minya",
                "label_ar": "المنيا",
                "label_fr": "Minya"
            },
            {
                "value": 77914,
                "label": "Alexandria",
                "label_ar": "الإسكندرية",
                "label_fr": "Alexandrie"
            },
            {
                "value": 77922,
                "label": "Bani Suwayf",
                "label_ar": "بني سويف",
                "label_fr": "Bani Suwayf"
            },
            {
                "value": 77927,
                "label": "Ar Rawdah",
                "label_ar": "الروضة",
                "label_fr": "Ar Rawdah"
            },
            {
                "value": 77934,
                "label": "Ismailia",
                "label_ar": "الإسماعيلية",
                "label_fr": "Ismailia"
            },
            {
                "value": 77948,
                "label": "Flaminj",
                "label_ar": "فلامينج",
                "label_fr": "Flaminj"
            },
            {
                "value": 77945,
                "label": "Al Marj",
                "label_ar": "المرج",
                "label_fr": "Al Marj"
            },
            {
                "value": 77947,
                "label": "Nasr",
                "label_ar": "نصر",
                "label_fr": "Nasr"
            },
            {
                "value": 77936,
                "label": "Shirbin",
                "label_ar": "شربين",
                "label_fr": "Shirbin"
            },
            {
                "value": 77932,
                "label": "Rafah",
                "label_ar": "رفح",
                "label_fr": "Rafah"
            },
            {
                "value": 77913,
                "label": "New Cairo",
                "label_ar": "القاهرة الجديدة",
                "label_fr": "Nouveau Caire"
            }
        ]
    },
    {
        "country": "Argentina",
        "cities": [
            {
                "value": 77965,
                "label": "Gualeguay",
                "label_ar": "جواليجواي",
                "label_fr": "Gualeguay"
            },
            {
                "value": 78359,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 78075,
                "label": "Grand Bourg",
                "label_ar": "جراند بور",
                "label_fr": "Grand Bourg"
            },
            {
                "value": 78100,
                "label": "Lomas de Zamora",
                "label_ar": "لوماس دي زامورا",
                "label_fr": "Lomas de Zamora"
            },
            {
                "value": 78104,
                "label": "La Union",
                "label_ar": "لا يونيون",
                "label_fr": "La Union"
            },
            {
                "value": 78189,
                "label": "Tablada",
                "label_ar": "تبلدة",
                "label_fr": "Tablada"
            },
            {
                "value": 78458,
                "label": "Acevedo",
                "label_ar": "أسيفيدو",
                "label_fr": "Acevedo"
            },
            {
                "value": 78392,
                "label": "Colon",
                "label_ar": "القولون",
                "label_fr": "Côlon"
            },
            {
                "value": 77966,
                "label": "Leones",
                "label_ar": "ليونز",
                "label_fr": "Leones"
            },
            {
                "value": 77956,
                "label": "Olivos",
                "label_ar": "أوليفوس",
                "label_fr": "Olivos"
            },
            {
                "value": 78112,
                "label": "Balcarce",
                "label_ar": "بالكارسي",
                "label_fr": "Balcarce"
            },
            {
                "value": 78097,
                "label": "Rafael Calzada",
                "label_ar": "رافائيل كالزادا",
                "label_fr": "Rafael Calzada"
            },
            {
                "value": 78190,
                "label": "Villa Luzuriaga",
                "label_ar": "فيلا لوزورياجا",
                "label_fr": "Villa Luzuriaga"
            },
            {
                "value": 78110,
                "label": "Don Bosco",
                "label_ar": "دون بوسكو",
                "label_fr": "Don Bosco"
            },
            {
                "value": 78072,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 78396,
                "label": "Bernasconi",
                "label_ar": "برناسكوني",
                "label_fr": "Bernasconi"
            },
            {
                "value": 78578,
                "label": "Del Campillo",
                "label_ar": "ديل كامبيلو",
                "label_fr": "Del Campillo"
            },
            {
                "value": 78282,
                "label": "Valle Hermoso",
                "label_ar": "فالي هيرموسو",
                "label_fr": "Valle Hermoso"
            },
            {
                "value": 78027,
                "label": "General San Martin",
                "label_ar": "الجنرال سان مارتن",
                "label_fr": "Général San Martin"
            },
            {
                "value": 78412,
                "label": "Plaza Huincul",
                "label_ar": "بلازا هوينكول",
                "label_fr": "Plaza Huincul"
            },
            {
                "value": 78002,
                "label": "Casilda",
                "label_ar": "كاسيلدا",
                "label_fr": "Casilda"
            },
            {
                "value": 78168,
                "label": "San Clemente",
                "label_ar": "سان كليمنتي",
                "label_fr": "San Clemente"
            },
            {
                "value": 78153,
                "label": "Boulogne",
                "label_ar": "بولوني",
                "label_fr": "Boulogne"
            },
            {
                "value": 78265,
                "label": "Glew",
                "label_ar": "جلو",
                "label_fr": "Glew"
            },
            {
                "value": 78165,
                "label": "San Bernardo",
                "label_ar": "سان برناردو",
                "label_fr": "San Bernardo"
            },
            {
                "value": 78352,
                "label": "Roldan",
                "label_ar": "رولدان",
                "label_fr": "Roldan"
            },
            {
                "value": 78253,
                "label": "Santa Lucia",
                "label_ar": "سانتا لوسيا",
                "label_fr": "Santa Lucia"
            },
            {
                "value": 78447,
                "label": "Veronica",
                "label_ar": "فيرونيكا",
                "label_fr": "Véronique"
            },
            {
                "value": 78485,
                "label": "Sierra de la Ventana",
                "label_ar": "سييرا دي لا فينتانا",
                "label_fr": "Sierra de la Ventana"
            },
            {
                "value": 78391,
                "label": "Choele Choel",
                "label_ar": "شويل شويل",
                "label_fr": "Choele Choel"
            },
            {
                "value": 78022,
                "label": "San Miguel de Tucumán",
                "label_ar": "سان ميغيل دي توكومان",
                "label_fr": "San Miguel de Tucumán"
            },
            {
                "value": 78374,
                "label": "Arroyito",
                "label_ar": "اروييتو",
                "label_fr": "Arroyito"
            },
            {
                "value": 78115,
                "label": "Batan",
                "label_ar": "باتان",
                "label_fr": "Batan"
            },
            {
                "value": 78405,
                "label": "Realico",
                "label_ar": "ريليكو",
                "label_fr": "Realico"
            },
            {
                "value": 78394,
                "label": "Jauregui",
                "label_ar": "جوريجوي",
                "label_fr": "Jauregui"
            },
            {
                "value": 78491,
                "label": "Diaz",
                "label_ar": "دياز",
                "label_fr": "Diaz"
            },
            {
                "value": 78255,
                "label": "Maipu",
                "label_ar": "ميبو",
                "label_fr": "Maipu"
            },
            {
                "value": 78213,
                "label": "Viedma",
                "label_ar": "فيدما",
                "label_fr": "Viedma"
            },
            {
                "value": 77958,
                "label": "Pilar",
                "label_ar": "بيلار",
                "label_fr": "Pilar"
            },
            {
                "value": 78245,
                "label": "Isidro Casanova",
                "label_ar": "إيسيدرو كازانوفا",
                "label_fr": "Isidro Casanova"
            },
            {
                "value": 78030,
                "label": "Zárate",
                "label_ar": "زارات",
                "label_fr": "Zárate"
            },
            {
                "value": 78256,
                "label": "Tunuyan",
                "label_ar": "تونويان",
                "label_fr": "Tunuyan"
            },
            {
                "value": 78479,
                "label": "Charata",
                "label_ar": "شاراتا",
                "label_fr": "Charata"
            },
            {
                "value": 78481,
                "label": "Saenz Pena",
                "label_ar": "ساينز بينا",
                "label_fr": "Saenz Pena"
            },
            {
                "value": 78148,
                "label": "Caleta Olivia",
                "label_ar": "كاليتا أوليفيا",
                "label_fr": "Caleta Olivia"
            },
            {
                "value": 78032,
                "label": "Jose Leon Suarez",
                "label_ar": "خوسيه ليون سواريز",
                "label_fr": "José Leon Suarez"
            },
            {
                "value": 78018,
                "label": "Alvear",
                "label_ar": "ألفير",
                "label_fr": "Alvear"
            },
            {
                "value": 78486,
                "label": "General Lagos",
                "label_ar": "عام لاغوس",
                "label_fr": "Général Lagos"
            },
            {
                "value": 78159,
                "label": "San Salvador",
                "label_ar": "سان سلفادور",
                "label_fr": "San Salvador"
            },
            {
                "value": 77972,
                "label": "Colon",
                "label_ar": "القولون",
                "label_fr": "Côlon"
            },
            {
                "value": 78183,
                "label": "Tostado",
                "label_ar": "توستادو",
                "label_fr": "Tostado"
            },
            {
                "value": 78079,
                "label": "Margarita",
                "label_ar": "مارجريتا",
                "label_fr": "Margarita"
            },
            {
                "value": 78413,
                "label": "Sarmiento",
                "label_ar": "سارمينتو",
                "label_fr": "Sarmiento"
            },
            {
                "value": 78522,
                "label": "Arroyito Challaco",
                "label_ar": "اروييتو تشالاكو",
                "label_fr": "Arroyito Challaco"
            },
            {
                "value": 78105,
                "label": "Dock Sud",
                "label_ar": "دوك سود",
                "label_fr": "Dock Sud"
            },
            {
                "value": 78127,
                "label": "Oncativo",
                "label_ar": "اونكاتيفو",
                "label_fr": "Oncativo"
            },
            {
                "value": 78316,
                "label": "Turdera",
                "label_ar": "تورديرا",
                "label_fr": "Turdera"
            },
            {
                "value": 78254,
                "label": "Villa Mercedes",
                "label_ar": "فيلا مرسيدس",
                "label_fr": "Villa Mercedes"
            },
            {
                "value": 78008,
                "label": "Martinez",
                "label_ar": "مارتينيز",
                "label_fr": "Martinez"
            },
            {
                "value": 78069,
                "label": "Rocamora",
                "label_ar": "روكامورا",
                "label_fr": "Rocamora"
            },
            {
                "value": 78012,
                "label": "Villa Regina",
                "label_ar": "فيلا ريجينا",
                "label_fr": "Villa Regina"
            },
            {
                "value": 78219,
                "label": "Coy Aike",
                "label_ar": "كوي آيك",
                "label_fr": "Coy Aike"
            },
            {
                "value": 78300,
                "label": "Ceres",
                "label_ar": "سيريس",
                "label_fr": "Cérès"
            },
            {
                "value": 78186,
                "label": "General Cabrera",
                "label_ar": "الجنرال كابريرا",
                "label_fr": "Général Cabrera"
            },
            {
                "value": 78051,
                "label": "Thames",
                "label_ar": "التايمز",
                "label_fr": "Tamise"
            },
            {
                "value": 78401,
                "label": "Catrilo",
                "label_ar": "كاتريلو",
                "label_fr": "Catrilo"
            },
            {
                "value": 78494,
                "label": "Rivadavia",
                "label_ar": "ريفادافيا",
                "label_fr": "Rivadavia"
            },
            {
                "value": 78516,
                "label": "San Carlos de Bolivar",
                "label_ar": "سان كارلوس دي بوليفار",
                "label_fr": "San Carlos de Bolivar"
            },
            {
                "value": 78062,
                "label": "Pueyrredon",
                "label_ar": "بويريدون",
                "label_fr": "Pueyrredon"
            },
            {
                "value": 78574,
                "label": "Macachin",
                "label_ar": "ماكاشين",
                "label_fr": "Macachin"
            },
            {
                "value": 78152,
                "label": "Berisso",
                "label_ar": "بيريسو",
                "label_fr": "Berisso"
            },
            {
                "value": 78336,
                "label": "Granadero Baigorria",
                "label_ar": "جراناديرو بايغوريا",
                "label_fr": "Granadero Baigorria"
            },
            {
                "value": 77951,
                "label": "Ingeniero Beaugey",
                "label_ar": "إنجينيرو بوجي",
                "label_fr": "Ingeniero Beaugey"
            },
            {
                "value": 78009,
                "label": "Bragado",
                "label_ar": "براغادو",
                "label_fr": "Bragado"
            },
            {
                "value": 77962,
                "label": "San Luis",
                "label_ar": "سان لويس",
                "label_fr": "San Luis"
            },
            {
                "value": 78196,
                "label": "Montserrat",
                "label_ar": "مونتسيرات",
                "label_fr": "Montserrat"
            },
            {
                "value": 78311,
                "label": "Cinco Saltos",
                "label_ar": "سينكو سالتوس",
                "label_fr": "Cinco Saltos"
            },
            {
                "value": 78296,
                "label": "Adelia Maria",
                "label_ar": "أديليا ماريا",
                "label_fr": "Adelia Maria"
            },
            {
                "value": 78113,
                "label": "Moreno",
                "label_ar": "مورينو",
                "label_fr": "Moreno"
            },
            {
                "value": 78388,
                "label": "Henderson",
                "label_ar": "هندرسون",
                "label_fr": "Henderson"
            },
            {
                "value": 78427,
                "label": "Eldorado",
                "label_ar": "إلدورادو",
                "label_fr": "Eldorado"
            },
            {
                "value": 78284,
                "label": "Canada de Gomez",
                "label_ar": "كندا دي جوميز",
                "label_fr": "Canada de Gomez"
            },
            {
                "value": 78057,
                "label": "Munro",
                "label_ar": "مونرو",
                "label_fr": "Munro"
            },
            {
                "value": 78064,
                "label": "San Salvador de Jujuy",
                "label_ar": "سان سلفادور دي خوخوي",
                "label_fr": "San Salvador de Jujuy"
            },
            {
                "value": 78214,
                "label": "Presidente Derqui",
                "label_ar": "الرئيس Derqui",
                "label_fr": "Presidente Derqui"
            },
            {
                "value": 78329,
                "label": "Ranchos",
                "label_ar": "رانشوس",
                "label_fr": "Ranchos"
            },
            {
                "value": 78435,
                "label": "Dolores",
                "label_ar": "دولوريس",
                "label_fr": "Dolores"
            },
            {
                "value": 77961,
                "label": "Federal",
                "label_ar": "الفيدرالية",
                "label_fr": "Fédéral"
            },
            {
                "value": 78054,
                "label": "Esperanza",
                "label_ar": "اسبيرانزا",
                "label_fr": "Esperanza"
            },
            {
                "value": 78052,
                "label": "Chorroarin",
                "label_ar": "شورارين",
                "label_fr": "Chorroarine"
            },
            {
                "value": 77986,
                "label": "Quilmes",
                "label_ar": "كويلمز",
                "label_fr": "Quilmes"
            },
            {
                "value": 78418,
                "label": "Fray Luis A. Beltran",
                "label_ar": "فراي لويس أ. بلتران",
                "label_fr": "Fray Luis A. Beltran"
            },
            {
                "value": 78039,
                "label": "Villa Urquiza",
                "label_ar": "فيلا Urquiza",
                "label_fr": "Villa Urquiza"
            },
            {
                "value": 78510,
                "label": "Bovril",
                "label_ar": "بوفريل",
                "label_fr": "Bovril"
            },
            {
                "value": 78517,
                "label": "Salto Grande",
                "label_ar": "سالتو غراندي",
                "label_fr": "Salto Grande"
            },
            {
                "value": 78035,
                "label": "General Juan Madariaga",
                "label_ar": "الجنرال خوان مادارياغا",
                "label_fr": "Général Juan Madariaga"
            },
            {
                "value": 78332,
                "label": "Huanguelen",
                "label_ar": "Huanguelen",
                "label_fr": "Huanguelen"
            },
            {
                "value": 78140,
                "label": "Intendente Alvear",
                "label_ar": "Intendente Alvear",
                "label_fr": "Intendente Alvear"
            },
            {
                "value": 78136,
                "label": "San Benito",
                "label_ar": "سان بينيتو",
                "label_fr": "San Benito"
            },
            {
                "value": 78014,
                "label": "El Talar",
                "label_ar": "التلار",
                "label_fr": "El Talar"
            },
            {
                "value": 78046,
                "label": "Garin",
                "label_ar": "جارين",
                "label_fr": "Garin"
            },
            {
                "value": 78129,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 78147,
                "label": "Tandil",
                "label_ar": "تانديل",
                "label_fr": "Tandil"
            },
            {
                "value": 78477,
                "label": "La Violeta",
                "label_ar": "لا فيوليتا",
                "label_fr": "La Violeta"
            },
            {
                "value": 78579,
                "label": "Villa Angela",
                "label_ar": "فيلا أنجيلا",
                "label_fr": "Villa Angela"
            },
            {
                "value": 77977,
                "label": "Villaguay",
                "label_ar": "فيلاجواي",
                "label_fr": "Villaguay"
            },
            {
                "value": 78320,
                "label": "Villa La Angostura",
                "label_ar": "فيلا لا أنجوستورا",
                "label_fr": "Villa La Angostura"
            },
            {
                "value": 78480,
                "label": "Bella Vista",
                "label_ar": "بيلا فيستا",
                "label_fr": "Bella Vista"
            },
            {
                "value": 77994,
                "label": "Temperley",
                "label_ar": "تمبرلي",
                "label_fr": "Temperley"
            },
            {
                "value": 78287,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 78120,
                "label": "General Pacheco",
                "label_ar": "الجنرال باتشيكو",
                "label_fr": "Général Pacheco"
            },
            {
                "value": 78229,
                "label": "Brandsen",
                "label_ar": "براندسن",
                "label_fr": "Brandsen"
            },
            {
                "value": 78240,
                "label": "Gonzalez Catan",
                "label_ar": "جونزاليس كاتان",
                "label_fr": "Gonzalez Catan"
            },
            {
                "value": 78192,
                "label": "Villa Dominico",
                "label_ar": "فيلا دومينيكو",
                "label_fr": "Villa Dominico"
            },
            {
                "value": 78067,
                "label": "Cervantes",
                "label_ar": "سرفانتس",
                "label_fr": "Cervantes"
            },
            {
                "value": 78197,
                "label": "Aldo Bonzi",
                "label_ar": "ألدو بونزي",
                "label_fr": "Aldo Bonzi"
            },
            {
                "value": 78184,
                "label": "Huinca Renanco",
                "label_ar": "هوينكا رينانكو",
                "label_fr": "Huinca Renanco"
            },
            {
                "value": 78083,
                "label": "Huanchillas",
                "label_ar": "هوانشيلاس",
                "label_fr": "Huanchillas"
            },
            {
                "value": 78044,
                "label": "General Pico",
                "label_ar": "عام بيكو",
                "label_fr": "Général Pico"
            },
            {
                "value": 78118,
                "label": "Benavidez",
                "label_ar": "بينافيدز",
                "label_fr": "Benavidez"
            },
            {
                "value": 78121,
                "label": "Mariano J. Haedo",
                "label_ar": "ماريانو جيه هايدو",
                "label_fr": "Mariano J. Haedo"
            },
            {
                "value": 78301,
                "label": "Baradero",
                "label_ar": "باراديرو",
                "label_fr": "Baradero"
            },
            {
                "value": 78175,
                "label": "El Calafate",
                "label_ar": "الخلافة",
                "label_fr": "El Calafate"
            },
            {
                "value": 78349,
                "label": "Venado Tuerto",
                "label_ar": "فينادو تورتو",
                "label_fr": "Venado Tuerto"
            },
            {
                "value": 78016,
                "label": "Lanus",
                "label_ar": "لانوس",
                "label_fr": "Lanus"
            },
            {
                "value": 78289,
                "label": "Oliveros",
                "label_ar": "أوليفيروس",
                "label_fr": "Oliveros"
            },
            {
                "value": 78333,
                "label": "La Calera",
                "label_ar": "لا كاليرا",
                "label_fr": "La Calera"
            },
            {
                "value": 78302,
                "label": "Villa Trinidad",
                "label_ar": "فيلا ترينيداد",
                "label_fr": "Villa Trinidad"
            },
            {
                "value": 78567,
                "label": "Melincue",
                "label_ar": "ميلينكو",
                "label_fr": "Mélincue"
            },
            {
                "value": 78475,
                "label": "Merlo",
                "label_ar": "ميرلو",
                "label_fr": "Merlo"
            },
            {
                "value": 78344,
                "label": "La Paz",
                "label_ar": "لاباز",
                "label_fr": "La Paz"
            },
            {
                "value": 78462,
                "label": "Independencia",
                "label_ar": "الاستقلال",
                "label_fr": "Independencia"
            },
            {
                "value": 78337,
                "label": "Sunchales",
                "label_ar": "مشمس",
                "label_fr": "Sunchales"
            },
            {
                "value": 78250,
                "label": "Tapiales",
                "label_ar": "تابياليس",
                "label_fr": "Tapiales"
            },
            {
                "value": 78499,
                "label": "Rivadavia",
                "label_ar": "ريفادافيا",
                "label_fr": "Rivadavia"
            },
            {
                "value": 78423,
                "label": "San Isidro",
                "label_ar": "سان ايسيدرو",
                "label_fr": "San Isidro"
            },
            {
                "value": 78492,
                "label": "San Jeronimo Norte",
                "label_ar": "سان جيرونيمو نورتي",
                "label_fr": "San Jeronimo Norte"
            },
            {
                "value": 78324,
                "label": "Barrio Fisherton",
                "label_ar": "باريو فيشرتون",
                "label_fr": "Barrio Fisherton"
            },
            {
                "value": 78536,
                "label": "Agua de Oro",
                "label_ar": "أغوا دي أورو",
                "label_fr": "Agua de Oro"
            },
            {
                "value": 78545,
                "label": "Los Antiguos",
                "label_ar": "لوس أنتيجوس",
                "label_fr": "Los Antiguos"
            },
            {
                "value": 78511,
                "label": "Anatuya",
                "label_ar": "أناتويا",
                "label_fr": "Anatuya"
            },
            {
                "value": 78003,
                "label": "San Rafael",
                "label_ar": "سان رافائيل",
                "label_fr": "San Rafael"
            },
            {
                "value": 77995,
                "label": "Banfield",
                "label_ar": "بانفيلد",
                "label_fr": "Banfield"
            },
            {
                "value": 78101,
                "label": "Tristan Suarez",
                "label_ar": "تريستان سواريز",
                "label_fr": "Tristan Suarez"
            },
            {
                "value": 78305,
                "label": "La Leonesa",
                "label_ar": "لا ليونيزا",
                "label_fr": "La Leonesa"
            },
            {
                "value": 77952,
                "label": "San Isidro",
                "label_ar": "سان ايسيدرو",
                "label_fr": "San Isidro"
            },
            {
                "value": 77980,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 78177,
                "label": "Mercedes",
                "label_ar": "مرسيدس",
                "label_fr": "Mercedes"
            },
            {
                "value": 78217,
                "label": "El Hoyo",
                "label_ar": "إل هويو",
                "label_fr": "El Hoyo"
            },
            {
                "value": 78234,
                "label": "San Manuel",
                "label_ar": "سان مانويل",
                "label_fr": "San Manuel"
            },
            {
                "value": 78581,
                "label": "San Antonio de Areco",
                "label_ar": "سان أنطونيو دي أريكو",
                "label_fr": "San Antonio de Areco"
            },
            {
                "value": 78508,
                "label": "Diego de Alvear",
                "label_ar": "دييغو دي ألفير",
                "label_fr": "Diego de Alvear"
            },
            {
                "value": 78440,
                "label": "Concepcion",
                "label_ar": "كونسيبسيون",
                "label_fr": "Concepcion"
            },
            {
                "value": 78098,
                "label": "Claypole",
                "label_ar": "كلايبول",
                "label_fr": "Claypole"
            },
            {
                "value": 78257,
                "label": "Corralitos",
                "label_ar": "كوراليتوس",
                "label_fr": "Corralitos"
            },
            {
                "value": 78304,
                "label": "Canada del Ucle",
                "label_ar": "كندا ديل أوكل",
                "label_fr": "Canada del Ucle"
            },
            {
                "value": 78078,
                "label": "Villa Huidobro",
                "label_ar": "فيلا هويدوبرو",
                "label_fr": "Villa Huidobro"
            },
            {
                "value": 78379,
                "label": "Villa Maria Grande",
                "label_ar": "فيلا ماريا جراندي",
                "label_fr": "Villa Maria Grande"
            },
            {
                "value": 77991,
                "label": "Santos Lugares",
                "label_ar": "سانتوس لوغاريس",
                "label_fr": "Santos Lugares"
            },
            {
                "value": 78261,
                "label": "San Antonio Oeste",
                "label_ar": "سان انطونيو اويستي",
                "label_fr": "San Antonio Oeste"
            },
            {
                "value": 78005,
                "label": "Luis Guillon",
                "label_ar": "لويس جويلون",
                "label_fr": "Luis Guillon"
            },
            {
                "value": 77998,
                "label": "Llavallol",
                "label_ar": "Llavallol",
                "label_fr": "Llavallol"
            },
            {
                "value": 78264,
                "label": "Guernica",
                "label_ar": "غيرنيكا",
                "label_fr": "Guernica"
            },
            {
                "value": 78015,
                "label": "Fatima",
                "label_ar": "فاطمة",
                "label_fr": "Fatima"
            },
            {
                "value": 78512,
                "label": "Pozo del Molle",
                "label_ar": "بوزو ديل مولي",
                "label_fr": "Pozo del Molle"
            },
            {
                "value": 78291,
                "label": "Bandera",
                "label_ar": "بانديرا",
                "label_fr": "Bandera"
            },
            {
                "value": 78155,
                "label": "Plottier",
                "label_ar": "متآمر",
                "label_fr": "Plottier"
            },
            {
                "value": 78525,
                "label": "Las Perdices",
                "label_ar": "لاس بيرديس",
                "label_fr": "Las Perdices"
            },
            {
                "value": 77953,
                "label": "Buenos Aires",
                "label_ar": "بوينس ايرس",
                "label_fr": "Buenos Aires"
            },
            {
                "value": 78019,
                "label": "Mariano Acosta",
                "label_ar": "ماريانو أكوستا",
                "label_fr": "Mariano Acosta"
            },
            {
                "value": 78562,
                "label": "Mayor Buratovich",
                "label_ar": "عمدة بوراتوفيتش",
                "label_fr": "Maire Buratovich"
            },
            {
                "value": 78497,
                "label": "Almafuerte",
                "label_ar": "المافويرتي",
                "label_fr": "Almafuerte"
            },
            {
                "value": 78439,
                "label": "San Cristobal",
                "label_ar": "سان كريستوبال",
                "label_fr": "San Cristobal"
            },
            {
                "value": 78202,
                "label": "Navarro",
                "label_ar": "نافارو",
                "label_fr": "Navarro"
            },
            {
                "value": 78150,
                "label": "Wilde",
                "label_ar": "وايلد",
                "label_fr": "Wilde"
            },
            {
                "value": 78107,
                "label": "Castelar",
                "label_ar": "كاستيلار",
                "label_fr": "Castelar"
            },
            {
                "value": 78204,
                "label": "Río Turbio",
                "label_ar": "ريو توربيو",
                "label_fr": "Río Turbio"
            },
            {
                "value": 78164,
                "label": "Villa Gesell",
                "label_ar": "فيلا جيزيل",
                "label_fr": "Villa Gesell"
            },
            {
                "value": 78335,
                "label": "Villa Lugano",
                "label_ar": "فيلا لوغانو",
                "label_fr": "Villa Lugano"
            },
            {
                "value": 78239,
                "label": "Partido de Jose C. Paz",
                "label_ar": "Partido de Jose C. Paz",
                "label_fr": "Partido de Jose C. Paz"
            },
            {
                "value": 78246,
                "label": "Laferrere",
                "label_ar": "لافرير",
                "label_fr": "Laferrère"
            },
            {
                "value": 78141,
                "label": "Ingeniero Luiggi",
                "label_ar": "إنجينيرو لويجي",
                "label_fr": "Ingeniero Luiggi"
            },
            {
                "value": 78290,
                "label": "Despenaderos",
                "label_ar": "ديسبينادروس",
                "label_fr": "Despenaderos"
            },
            {
                "value": 78224,
                "label": "28 de Noviembre",
                "label_ar": "28 دي نوفيمبري",
                "label_fr": "28 novembre"
            },
            {
                "value": 78366,
                "label": "General Roca",
                "label_ar": "جنرال روكا",
                "label_fr": "Général Roca"
            },
            {
                "value": 78341,
                "label": "Hersilia",
                "label_ar": "هيرسيليا",
                "label_fr": "Hersilia"
            },
            {
                "value": 78321,
                "label": "Rufino",
                "label_ar": "روفينو",
                "label_fr": "Rufino"
            },
            {
                "value": 78334,
                "label": "Santa Teresita",
                "label_ar": "سانتا تيريسيتا",
                "label_fr": "Santa Teresita"
            },
            {
                "value": 78343,
                "label": "Villa Mercedes",
                "label_ar": "فيلا مرسيدس",
                "label_fr": "Villa Mercedes"
            },
            {
                "value": 78455,
                "label": "Ascension",
                "label_ar": "الصعود",
                "label_fr": "Ascension"
            },
            {
                "value": 78361,
                "label": "Pavon",
                "label_ar": "بافون",
                "label_fr": "Pavon"
            },
            {
                "value": 78348,
                "label": "Belgrano",
                "label_ar": "بلغرانو",
                "label_fr": "Belgrano"
            },
            {
                "value": 78450,
                "label": "Arequito",
                "label_ar": "أريكيتو",
                "label_fr": "Arequito"
            },
            {
                "value": 78532,
                "label": "Retiro",
                "label_ar": "ريتيرو",
                "label_fr": "Retiro"
            },
            {
                "value": 78506,
                "label": "Villa Las Rosas",
                "label_ar": "فيلا لاس روساس",
                "label_fr": "Villa Las Rosas"
            },
            {
                "value": 78570,
                "label": "Veinticinco de Mayo",
                "label_ar": "Veinticinco de Mayo",
                "label_fr": "Veinticinco de Mayo"
            },
            {
                "value": 78496,
                "label": "La Paz",
                "label_ar": "لاباز",
                "label_fr": "La Paz"
            },
            {
                "value": 78509,
                "label": "San Antonio de Arredondo",
                "label_ar": "سان أنطونيو دي Arredondo",
                "label_fr": "San Antonio de Arredondo"
            },
            {
                "value": 78473,
                "label": "Arroyo Seco",
                "label_ar": "أرويو سيكو",
                "label_fr": "Arroyo Seco"
            },
            {
                "value": 78414,
                "label": "Luque",
                "label_ar": "لوك",
                "label_fr": "Luque"
            },
            {
                "value": 77968,
                "label": "Mendoza",
                "label_ar": "مندوزا",
                "label_fr": "Mendoza"
            },
            {
                "value": 78166,
                "label": "Tres Arroyos",
                "label_ar": "تريس أرويوس",
                "label_fr": "Tres Arroyos"
            },
            {
                "value": 78033,
                "label": "Río Cuarto",
                "label_ar": "ريو كوارتو",
                "label_fr": "Río Cuarto"
            },
            {
                "value": 78137,
                "label": "Puerto Iguazú",
                "label_ar": "بويرتو إجوازو",
                "label_fr": "Puerto Iguazú"
            },
            {
                "value": 78049,
                "label": "Salta",
                "label_ar": "سالتا",
                "label_fr": "Salta"
            },
            {
                "value": 78060,
                "label": "San Jose",
                "label_ar": "سان خوسيه",
                "label_fr": "San José"
            },
            {
                "value": 78055,
                "label": "Posadas",
                "label_ar": "بوساداس",
                "label_fr": "Posadas"
            },
            {
                "value": 78384,
                "label": "Chajari",
                "label_ar": "شاجاري",
                "label_fr": "Chajari"
            },
            {
                "value": 77984,
                "label": "Del Viso",
                "label_ar": "ديل فيسو",
                "label_fr": "Del Viso"
            },
            {
                "value": 78173,
                "label": "Laborde",
                "label_ar": "لابورد",
                "label_fr": "Laborde"
            },
            {
                "value": 78026,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 77979,
                "label": "Tortuguitas",
                "label_ar": "تورتوجيتاس",
                "label_fr": "Tortuguitas"
            },
            {
                "value": 78271,
                "label": "Ranelagh",
                "label_ar": "رانيلا",
                "label_fr": "Ranelagh"
            },
            {
                "value": 78043,
                "label": "San Fernando",
                "label_ar": "سان فرناندو",
                "label_fr": "San Fernando"
            },
            {
                "value": 78269,
                "label": "San Francisco Solano",
                "label_ar": "سان فرانسيسكو سولانو",
                "label_fr": "San Francisco Solano"
            },
            {
                "value": 78376,
                "label": "El Trebol",
                "label_ar": "التريبول",
                "label_fr": "El Trebol"
            },
            {
                "value": 78201,
                "label": "Perito Moreno",
                "label_ar": "بيريتو مورينو",
                "label_fr": "Perito Moreno"
            },
            {
                "value": 78298,
                "label": "Coronel Dorrego",
                "label_ar": "كورونيل دوريجو",
                "label_fr": "Coronel Dorrego"
            },
            {
                "value": 78363,
                "label": "Azara",
                "label_ar": "أزارا",
                "label_fr": "Azara"
            },
            {
                "value": 78262,
                "label": "Alejandro Korn",
                "label_ar": "أليخاندرو كورن",
                "label_fr": "Alejandro Korn"
            },
            {
                "value": 78111,
                "label": "Bernal",
                "label_ar": "برنال",
                "label_fr": "Bernal"
            },
            {
                "value": 78535,
                "label": "Brinkmann",
                "label_ar": "برينكمان",
                "label_fr": "Brinkmann"
            },
            {
                "value": 78548,
                "label": "Ucacha",
                "label_ar": "أوكاتشا",
                "label_fr": "Ucacha"
            },
            {
                "value": 78422,
                "label": "Lavalle",
                "label_ar": "لافال",
                "label_fr": "Lavalle"
            },
            {
                "value": 78493,
                "label": "Jeppener",
                "label_ar": "جيبينر",
                "label_fr": "Jeppener"
            },
            {
                "value": 78449,
                "label": "Rio Colorado",
                "label_ar": "ريو كولورادو",
                "label_fr": "Rio Colorado"
            },
            {
                "value": 78489,
                "label": "Ayacucho",
                "label_ar": "أياكوتشو",
                "label_fr": "Ayacucho"
            },
            {
                "value": 78490,
                "label": "Bosch",
                "label_ar": "بوش",
                "label_fr": "Bosch"
            },
            {
                "value": 78576,
                "label": "Puerto Rico",
                "label_ar": "بورتوريكو",
                "label_fr": "Porto Rico"
            },
            {
                "value": 78502,
                "label": "Maggiolo",
                "label_ar": "ماجيولو",
                "label_fr": "Maggiolo"
            },
            {
                "value": 77970,
                "label": "Santa Fe",
                "label_ar": "سانتا في",
                "label_fr": "Santa Fe"
            },
            {
                "value": 78370,
                "label": "Trenque Lauquen",
                "label_ar": "ترينك لاوكوين",
                "label_fr": "Trenque Lauquen"
            },
            {
                "value": 78383,
                "label": "Coronel Pringles",
                "label_ar": "كورونيل برينجلز",
                "label_fr": "Coronel Pringles"
            },
            {
                "value": 77990,
                "label": "Villa Elisa",
                "label_ar": "فيلا إليسا",
                "label_fr": "Villa Elisa"
            },
            {
                "value": 78347,
                "label": "Pedernales",
                "label_ar": "بدرناليس",
                "label_fr": "Pedernales"
            },
            {
                "value": 78144,
                "label": "Rancul",
                "label_ar": "رانكول",
                "label_fr": "Rancul"
            },
            {
                "value": 77981,
                "label": "Pilar",
                "label_ar": "بيلار",
                "label_fr": "Pilar"
            },
            {
                "value": 78373,
                "label": "Santa Clara de Saguier",
                "label_ar": "سانتا كلارا دي ساجوير",
                "label_fr": "Santa Clara de Saguier"
            },
            {
                "value": 78109,
                "label": "Hurlingham",
                "label_ar": "هيرلينجهام",
                "label_fr": "Hurlingham"
            },
            {
                "value": 78154,
                "label": "Belgrano",
                "label_ar": "بلغرانو",
                "label_fr": "Belgrano"
            },
            {
                "value": 78357,
                "label": "Salsipuedes",
                "label_ar": "سالسيبوديس",
                "label_fr": "Salsipuedes"
            },
            {
                "value": 78006,
                "label": "Burzaco",
                "label_ar": "بورزاكو",
                "label_fr": "Burzaco"
            },
            {
                "value": 78198,
                "label": "La Tablada",
                "label_ar": "لا تابلادا",
                "label_fr": "La Tablada"
            },
            {
                "value": 78375,
                "label": "Arroyito",
                "label_ar": "اروييتو",
                "label_fr": "Arroyito"
            },
            {
                "value": 78124,
                "label": "Florida",
                "label_ar": "فلوريدا",
                "label_fr": "Floride"
            },
            {
                "value": 78172,
                "label": "Paraná",
                "label_ar": "بارانا",
                "label_fr": "Paraná"
            },
            {
                "value": 78411,
                "label": "Franck",
                "label_ar": "فرانك",
                "label_fr": "Franck"
            },
            {
                "value": 78393,
                "label": "Cutral-Co",
                "label_ar": "كترال كو",
                "label_fr": "Cutral-Co"
            },
            {
                "value": 78421,
                "label": "Villa Giardino",
                "label_ar": "فيلا جياردينو",
                "label_fr": "Villa Giardino"
            },
            {
                "value": 78544,
                "label": "Piquete Cabado",
                "label_ar": "بيكيتي كابادو",
                "label_fr": "Piquete Cabado"
            },
            {
                "value": 78408,
                "label": "Centenario",
                "label_ar": "سينتيناريو",
                "label_fr": "Centenario"
            },
            {
                "value": 78445,
                "label": "Nogoya",
                "label_ar": "نوغويا",
                "label_fr": "Nogoya"
            },
            {
                "value": 78369,
                "label": "Saladillo",
                "label_ar": "سالاديلو",
                "label_fr": "Saladillo"
            },
            {
                "value": 78503,
                "label": "Villa Dolores",
                "label_ar": "فيلا دولوريس",
                "label_fr": "Villa Dolores"
            },
            {
                "value": 78563,
                "label": "Germania",
                "label_ar": "جرمانيا",
                "label_fr": "Germanie"
            },
            {
                "value": 78566,
                "label": "Carlos Tejedor",
                "label_ar": "كارلوس تيجيدور",
                "label_fr": "Carlos Tejedor"
            },
            {
                "value": 78125,
                "label": "Humahuaca",
                "label_ar": "هوماهواكا",
                "label_fr": "Humahuaca"
            },
            {
                "value": 78547,
                "label": "Sinsacate",
                "label_ar": "سينساكيت",
                "label_fr": "Sinsacate"
            },
            {
                "value": 78460,
                "label": "Albardon",
                "label_ar": "البردون",
                "label_fr": "Albardon"
            },
            {
                "value": 78424,
                "label": "San Isidro de Lules",
                "label_ar": "سان إيسيدرو دي لوليس",
                "label_fr": "San Isidro de Lules"
            },
            {
                "value": 78553,
                "label": "Pinamar",
                "label_ar": "بينامار",
                "label_fr": "Pinamar"
            },
            {
                "value": 78461,
                "label": "Marcos Juarez",
                "label_ar": "ماركوس خواريز",
                "label_fr": "Marcos Juarez"
            },
            {
                "value": 78319,
                "label": "Canada Rosquin",
                "label_ar": "كندا روسكوين",
                "label_fr": "Canada Rosquin"
            },
            {
                "value": 78484,
                "label": "Garupa",
                "label_ar": "جاروبا",
                "label_fr": "Garupa"
            },
            {
                "value": 78557,
                "label": "Las Catitas",
                "label_ar": "لاس كاتيتاس",
                "label_fr": "Las Catitas"
            },
            {
                "value": 78378,
                "label": "Nunez",
                "label_ar": "نونيز",
                "label_fr": "Nunez"
            },
            {
                "value": 78179,
                "label": "Bosques",
                "label_ar": "بوسك",
                "label_fr": "Bosques"
            },
            {
                "value": 78082,
                "label": "La Para",
                "label_ar": "لا بارا",
                "label_fr": "La Para"
            },
            {
                "value": 78031,
                "label": "Belen de Escobar",
                "label_ar": "بيلين دي اسكوبار",
                "label_fr": "Belen de Escobar"
            },
            {
                "value": 78036,
                "label": "San Andres",
                "label_ar": "سان أندرياس",
                "label_fr": "San Andres"
            },
            {
                "value": 77955,
                "label": "Córdoba",
                "label_ar": "قرطبة",
                "label_fr": "Cordoue"
            },
            {
                "value": 78038,
                "label": "Nordelta",
                "label_ar": "نوردلتا",
                "label_fr": "Nordelta"
            },
            {
                "value": 78034,
                "label": "La Banda",
                "label_ar": "لا باندا",
                "label_fr": "La Banda"
            },
            {
                "value": 78084,
                "label": "Cosquin",
                "label_ar": "كوسكوين",
                "label_fr": "Cosquin"
            },
            {
                "value": 77992,
                "label": "Los Polvorines",
                "label_ar": "لوس بولفورينز",
                "label_fr": "Los Polvorines"
            },
            {
                "value": 78048,
                "label": "San Nicolás de los Arroyos",
                "label_ar": "سان نيكولاس دي لوس أرويوس",
                "label_fr": "San Nicolás de los Arroyos"
            },
            {
                "value": 78028,
                "label": "Yerba Buena",
                "label_ar": "يربا بوينا",
                "label_fr": "Yerba Buena"
            },
            {
                "value": 78158,
                "label": "Goya",
                "label_ar": "جويا",
                "label_fr": "Goya"
            },
            {
                "value": 78085,
                "label": "Villa de Maria",
                "label_ar": "فيلا دي ماريا",
                "label_fr": "Villa de Maria"
            },
            {
                "value": 78225,
                "label": "Abasto",
                "label_ar": "أباستو",
                "label_fr": "Abasto"
            },
            {
                "value": 78252,
                "label": "Platanos",
                "label_ar": "بلاتانوس",
                "label_fr": "Platanos"
            },
            {
                "value": 78294,
                "label": "Maquinista Savio",
                "label_ar": "ماكوينستا سافيو",
                "label_fr": "Maquinista Savio"
            },
            {
                "value": 78339,
                "label": "Bombal",
                "label_ar": "قنبلة",
                "label_fr": "Bombal"
            },
            {
                "value": 78191,
                "label": "Chacarita",
                "label_ar": "تشاكاريتا",
                "label_fr": "Chacarita"
            },
            {
                "value": 78358,
                "label": "Pilar",
                "label_ar": "بيلار",
                "label_fr": "Pilar"
            },
            {
                "value": 78346,
                "label": "Antonio Carboni",
                "label_ar": "انطونيو كاربوني",
                "label_fr": "Antonio Carboni"
            },
            {
                "value": 78188,
                "label": "Ciudadela",
                "label_ar": "سيوداديلا",
                "label_fr": "Ciudadela"
            },
            {
                "value": 78571,
                "label": "Warnes",
                "label_ar": "وارنز",
                "label_fr": "Warnes"
            },
            {
                "value": 78047,
                "label": "Bell Ville",
                "label_ar": "بيل فيل",
                "label_fr": "Bell Ville"
            },
            {
                "value": 78095,
                "label": "Merlo",
                "label_ar": "ميرلو",
                "label_fr": "Merlo"
            },
            {
                "value": 78325,
                "label": "Rosas",
                "label_ar": "روساس",
                "label_fr": "Rosas"
            },
            {
                "value": 78167,
                "label": "Darregueira",
                "label_ar": "دارجويرا",
                "label_fr": "Darregueira"
            },
            {
                "value": 78185,
                "label": "General Deheza",
                "label_ar": "الجنرال دهيزا",
                "label_fr": "Général Deheza"
            },
            {
                "value": 78247,
                "label": "Rafael Castillo",
                "label_ar": "رافائيل كاستيلو",
                "label_fr": "Rafael Castillo"
            },
            {
                "value": 78353,
                "label": "Mar del Tuyu",
                "label_ar": "مار ديل تويو",
                "label_fr": "Mar del Tuyu"
            },
            {
                "value": 78399,
                "label": "Rafael Obligado",
                "label_ar": "رافائيل أوبليجادو",
                "label_fr": "Rafael Obligado"
            },
            {
                "value": 78543,
                "label": "Hilario",
                "label_ar": "هيلاريو",
                "label_fr": "Hilario"
            },
            {
                "value": 78451,
                "label": "Los Molinos",
                "label_ar": "لوس مولينوس",
                "label_fr": "Los Molinos"
            },
            {
                "value": 78527,
                "label": "Asamblea",
                "label_ar": "Asamblea",
                "label_fr": "Asamblea"
            },
            {
                "value": 78426,
                "label": "Armstrong",
                "label_ar": "ارمسترونغ",
                "label_fr": "Armstrong"
            },
            {
                "value": 78561,
                "label": "Wheelwright",
                "label_ar": "صانع العجلات",
                "label_fr": "Charron"
            },
            {
                "value": 78554,
                "label": "Villa General Belgrano",
                "label_ar": "فيلا جنرال بيلجرانو",
                "label_fr": "Villa General Belgrano"
            },
            {
                "value": 78122,
                "label": "Palermo",
                "label_ar": "باليرمو",
                "label_fr": "Palerme"
            },
            {
                "value": 77973,
                "label": "Cipolletti",
                "label_ar": "سيبوليتي",
                "label_fr": "Cipolletti"
            },
            {
                "value": 78286,
                "label": "Villa Bosch",
                "label_ar": "فيلا بوش",
                "label_fr": "Villa Bosch"
            },
            {
                "value": 78194,
                "label": "Flores",
                "label_ar": "فلوريس",
                "label_fr": "Flores"
            },
            {
                "value": 78459,
                "label": "Vuelta de Obligado",
                "label_ar": "Vuelta de Obligado",
                "label_fr": "Vuelta de Obligado"
            },
            {
                "value": 78209,
                "label": "Pigue",
                "label_ar": "بيجيو",
                "label_fr": "Pigue"
            },
            {
                "value": 78385,
                "label": "Freyre",
                "label_ar": "فري",
                "label_fr": "Freyre"
            },
            {
                "value": 78377,
                "label": "Empalme Lobos",
                "label_ar": "إمبالمي لوبوس",
                "label_fr": "Empalme Lobos"
            },
            {
                "value": 78397,
                "label": "Pasco",
                "label_ar": "باسكو",
                "label_fr": "Pasco"
            },
            {
                "value": 77954,
                "label": "Paternal",
                "label_ar": "الأب",
                "label_fr": "Paternel"
            },
            {
                "value": 78108,
                "label": "Moron",
                "label_ar": "أحمق",
                "label_fr": "Idiot"
            },
            {
                "value": 78236,
                "label": "Suipacha",
                "label_ar": "Suipacha",
                "label_fr": "Suipacha"
            },
            {
                "value": 78146,
                "label": "Reconquista",
                "label_ar": "Reconquista",
                "label_fr": "Reconquista"
            },
            {
                "value": 78328,
                "label": "Alicia",
                "label_ar": "أليسيا",
                "label_fr": "Alicia"
            },
            {
                "value": 78187,
                "label": "Villa Alsina",
                "label_ar": "فيلا السينا",
                "label_fr": "Villa Alsina"
            },
            {
                "value": 78143,
                "label": "Winifreda",
                "label_ar": "وينيفريدا",
                "label_fr": "Winifreda"
            },
            {
                "value": 78470,
                "label": "Manantial",
                "label_ar": "مانانتيال",
                "label_fr": "Manantial"
            },
            {
                "value": 78266,
                "label": "San Vicente",
                "label_ar": "سان فيسينتي",
                "label_fr": "San Vicente"
            },
            {
                "value": 78081,
                "label": "Capilla del Monte",
                "label_ar": "كابيلا ديل مونتي",
                "label_fr": "Capilla del Monte"
            },
            {
                "value": 78235,
                "label": "Bordenave",
                "label_ar": "بوردناف",
                "label_fr": "Bordenave"
            },
            {
                "value": 78345,
                "label": "Alberti",
                "label_ar": "البرتي",
                "label_fr": "Alberti"
            },
            {
                "value": 78216,
                "label": "Alberdi",
                "label_ar": "البردي",
                "label_fr": "Alberdi"
            },
            {
                "value": 78050,
                "label": "De Mayo",
                "label_ar": "دي مايو",
                "label_fr": "De Mayo"
            },
            {
                "value": 78242,
                "label": "Capilla del Senor",
                "label_ar": "كابيلا ديل سينور",
                "label_fr": "Capilla del Senor"
            },
            {
                "value": 78306,
                "label": "Tupungato",
                "label_ar": "Tupungato",
                "label_fr": "Tupungato"
            },
            {
                "value": 78232,
                "label": "General San Martin",
                "label_ar": "الجنرال سان مارتن",
                "label_fr": "Général San Martin"
            },
            {
                "value": 78001,
                "label": "Pico Truncado",
                "label_ar": "بيكو ترونكادو",
                "label_fr": "Pico Truncado"
            },
            {
                "value": 78565,
                "label": "Sarmiento",
                "label_ar": "سارمينتو",
                "label_fr": "Sarmiento"
            },
            {
                "value": 78059,
                "label": "Santa Rita",
                "label_ar": "سانتا ريتا",
                "label_fr": "Santa Rita"
            },
            {
                "value": 78402,
                "label": "Caleufu",
                "label_ar": "كالوفو",
                "label_fr": "Caleufu"
            },
            {
                "value": 78398,
                "label": "James Craik",
                "label_ar": "جيمس كريك",
                "label_fr": "James Craik"
            },
            {
                "value": 78364,
                "label": "Chilecito",
                "label_ar": "تشيليسيتو",
                "label_fr": "Chilecito"
            },
            {
                "value": 78206,
                "label": "Rawson",
                "label_ar": "روسون",
                "label_fr": "Rawson"
            },
            {
                "value": 78281,
                "label": "Villa Allende",
                "label_ar": "فيلا الليندي",
                "label_fr": "Villa Allende"
            },
            {
                "value": 78448,
                "label": "Liniers",
                "label_ar": "لينيرز",
                "label_fr": "Liniers"
            },
            {
                "value": 78457,
                "label": "Constituyentes",
                "label_ar": "الناخبون",
                "label_fr": "Constituyentes"
            },
            {
                "value": 78442,
                "label": "Segui",
                "label_ar": "سيغي",
                "label_fr": "Segui"
            },
            {
                "value": 78307,
                "label": "Palmira",
                "label_ar": "بالميرا",
                "label_fr": "Palmira"
            },
            {
                "value": 78419,
                "label": "Hipatia",
                "label_ar": "هيباتيا",
                "label_fr": "Hipatia"
            },
            {
                "value": 78071,
                "label": "Beccar",
                "label_ar": "بيكار",
                "label_fr": "Beccar"
            },
            {
                "value": 78142,
                "label": "Bernardo Larroude",
                "label_ar": "برناردو لارود",
                "label_fr": "Bernardo Larroude"
            },
            {
                "value": 78400,
                "label": "La Madrid",
                "label_ar": "لا مدريد",
                "label_fr": "La Madrid"
            },
            {
                "value": 78073,
                "label": "Mercedes",
                "label_ar": "مرسيدس",
                "label_fr": "Mercedes"
            },
            {
                "value": 78161,
                "label": "Las Rosas",
                "label_ar": "لاس روساس",
                "label_fr": "Las Rosas"
            },
            {
                "value": 78456,
                "label": "Sampacho",
                "label_ar": "سامباتشو",
                "label_fr": "Sampacho"
            },
            {
                "value": 78331,
                "label": "Pueblo San Jose",
                "label_ar": "بويبلو سان خوسيه",
                "label_fr": "Pueblo San Jose"
            },
            {
                "value": 78386,
                "label": "Tapalque",
                "label_ar": "تابالك",
                "label_fr": "Tapalque"
            },
            {
                "value": 78551,
                "label": "Los Cardales",
                "label_ar": "لوس كارداليس",
                "label_fr": "Los Cardales"
            },
            {
                "value": 78080,
                "label": "Viamonte",
                "label_ar": "فيامونتي",
                "label_fr": "Viamonte"
            },
            {
                "value": 78199,
                "label": "Lynch",
                "label_ar": "لينش",
                "label_fr": "Lyncher"
            },
            {
                "value": 78465,
                "label": "Rodriguez Pena",
                "label_ar": "رودريغيز بينا",
                "label_fr": "Rodriguez Pena"
            },
            {
                "value": 78029,
                "label": "Tafi Viejo",
                "label_ar": "تافي فيجو",
                "label_fr": "Tafi Viejo"
            },
            {
                "value": 78277,
                "label": "Villa Adelina",
                "label_ar": "فيلا أديلينا",
                "label_fr": "Villa Adelina"
            },
            {
                "value": 78117,
                "label": "Villa Madero",
                "label_ar": "فيلا ماديرو",
                "label_fr": "Villa Madero"
            },
            {
                "value": 78303,
                "label": "San Guillermo",
                "label_ar": "سان غييرمو",
                "label_fr": "San Guillermo"
            },
            {
                "value": 78351,
                "label": "Justiniano Posse",
                "label_ar": "جستنيانو بوس",
                "label_fr": "Justiniano Posse"
            },
            {
                "value": 78181,
                "label": "San Antonio de Padua",
                "label_ar": "سان أنطونيو دي بادوا",
                "label_fr": "San Antonio de Padoue"
            },
            {
                "value": 78463,
                "label": "Ibarlucea",
                "label_ar": "ايبارلوسيا",
                "label_fr": "Ibarlucea"
            },
            {
                "value": 78488,
                "label": "Rio Piedras",
                "label_ar": "ريو بيدراس",
                "label_fr": "Rio Piedras"
            },
            {
                "value": 78004,
                "label": "San Andres de Giles",
                "label_ar": "سان أندريس دي جايلز",
                "label_fr": "San Andres de Giles"
            },
            {
                "value": 78436,
                "label": "Trevelin",
                "label_ar": "تريفلين",
                "label_fr": "Trevelin"
            },
            {
                "value": 78178,
                "label": "Minacar",
                "label_ar": "ميناكار",
                "label_fr": "Minacar"
            },
            {
                "value": 78501,
                "label": "Concepcion",
                "label_ar": "كونسيبسيون",
                "label_fr": "Concepcion"
            },
            {
                "value": 78090,
                "label": "Godoy Cruz",
                "label_ar": "جودوي كروز",
                "label_fr": "Godoy Cruz"
            },
            {
                "value": 78315,
                "label": "Villa del Parque",
                "label_ar": "فيلا ديل بارك",
                "label_fr": "Villa del Parque"
            },
            {
                "value": 78425,
                "label": "San Martin de las Escobas",
                "label_ar": "سان مارتن دي لاس إسكوباس",
                "label_fr": "San Martin de las Escobas"
            },
            {
                "value": 77975,
                "label": "San Jorge",
                "label_ar": "سان جورج",
                "label_fr": "San Jorge"
            },
            {
                "value": 78523,
                "label": "Villa Paranacito",
                "label_ar": "فيلا باراناسيتو",
                "label_fr": "Villa Paranacito"
            },
            {
                "value": 78504,
                "label": "El Dorado",
                "label_ar": "الدورادو",
                "label_fr": "El Dorado"
            },
            {
                "value": 78395,
                "label": "Muniz",
                "label_ar": "مونيز",
                "label_fr": "Muniz"
            },
            {
                "value": 78577,
                "label": "Acebal",
                "label_ar": "اسيبال",
                "label_fr": "Acebal"
            },
            {
                "value": 78096,
                "label": "Las Heras",
                "label_ar": "لاس هيراس",
                "label_fr": "Las Heras"
            },
            {
                "value": 78312,
                "label": "El Palomar",
                "label_ar": "إل بالومار",
                "label_fr": "El Palomar"
            },
            {
                "value": 78160,
                "label": "Palpala",
                "label_ar": "بالبالا",
                "label_fr": "Palpala"
            },
            {
                "value": 78226,
                "label": "Olavarría",
                "label_ar": "أولافاريا",
                "label_fr": "Olavarría"
            },
            {
                "value": 78362,
                "label": "La Cumbre",
                "label_ar": "لا كومبر",
                "label_fr": "La Cumbre"
            },
            {
                "value": 78011,
                "label": "Bahía Blanca",
                "label_ar": "باهيا بلانكا",
                "label_fr": "Bahía Blanca"
            },
            {
                "value": 78156,
                "label": "Neuquén",
                "label_ar": "نيوكوين",
                "label_fr": "Neuquén"
            },
            {
                "value": 78157,
                "label": "Guatrache",
                "label_ar": "Guatrache",
                "label_fr": "Guatrache"
            },
            {
                "value": 77969,
                "label": "Rivadavia",
                "label_ar": "ريفادافيا",
                "label_fr": "Rivadavia"
            },
            {
                "value": 78546,
                "label": "Laboulaye",
                "label_ar": "لابولاي",
                "label_fr": "Laboulaye"
            },
            {
                "value": 77989,
                "label": "San Juan",
                "label_ar": "سان خوان",
                "label_fr": "San Juan"
            },
            {
                "value": 78126,
                "label": "Mendiolaza",
                "label_ar": "منديولازا",
                "label_fr": "Mendiolaza"
            },
            {
                "value": 78524,
                "label": "Morteros",
                "label_ar": "Morteros",
                "label_fr": "Morteros"
            },
            {
                "value": 78086,
                "label": "San Jose de la Esquina",
                "label_ar": "سان خوسيه دي لا إسكينا",
                "label_fr": "San José de la Esquina"
            },
            {
                "value": 77983,
                "label": "San Telmo",
                "label_ar": "سان تيلمو",
                "label_fr": "San Telmo"
            },
            {
                "value": 78387,
                "label": "Totoras",
                "label_ar": "توتوراس",
                "label_fr": "Totoras"
            },
            {
                "value": 78007,
                "label": "Eduardo Castex",
                "label_ar": "إدواردو كاستكس",
                "label_fr": "Eduardo Castex"
            },
            {
                "value": 78500,
                "label": "Sanchez",
                "label_ar": "سانشيز",
                "label_fr": "Sanchez"
            },
            {
                "value": 78193,
                "label": "General Las Heras",
                "label_ar": "الجنرال لاس هيراس",
                "label_fr": "Général Las Heras"
            },
            {
                "value": 78040,
                "label": "Campana",
                "label_ar": "كامبانا",
                "label_fr": "Campana"
            },
            {
                "value": 78119,
                "label": "San Justo",
                "label_ar": "سان جوستو",
                "label_fr": "San Justo"
            },
            {
                "value": 78170,
                "label": "Comodoro Rivadavia",
                "label_ar": "كومودورو ريفادافيا",
                "label_fr": "Comodoro Rivadavia"
            },
            {
                "value": 78210,
                "label": "Villa Rosa",
                "label_ar": "فيلا روزا",
                "label_fr": "Villa Rosa"
            },
            {
                "value": 78453,
                "label": "Canals",
                "label_ar": "القنوات",
                "label_fr": "Canaux"
            },
            {
                "value": 78564,
                "label": "Saladillo",
                "label_ar": "سالاديلو",
                "label_fr": "Saladillo"
            },
            {
                "value": 78074,
                "label": "El Palomar",
                "label_ar": "إل بالومار",
                "label_fr": "El Palomar"
            },
            {
                "value": 78162,
                "label": "Carlos Pellegrini",
                "label_ar": "كارلوس بيليجريني",
                "label_fr": "Carlos Pellegrini"
            },
            {
                "value": 78280,
                "label": "San Salvador",
                "label_ar": "سان سلفادور",
                "label_fr": "San Salvador"
            },
            {
                "value": 77985,
                "label": "La Plata",
                "label_ar": "لا بلاتا",
                "label_fr": "La Plata"
            },
            {
                "value": 77957,
                "label": "Florencio Varela",
                "label_ar": "فلورنسيو فاريلا",
                "label_fr": "Florencio Varela"
            },
            {
                "value": 78327,
                "label": "Villa Media Agua",
                "label_ar": "فيلا ميديا اجوا",
                "label_fr": "Villa Media Agua"
            },
            {
                "value": 77974,
                "label": "Libertad",
                "label_ar": "ليبرتاد",
                "label_fr": "Libertad"
            },
            {
                "value": 78149,
                "label": "Caballito",
                "label_ar": "كاباليتو",
                "label_fr": "Caballito"
            },
            {
                "value": 78538,
                "label": "Famailla",
                "label_ar": "فاميلا",
                "label_fr": "Famailla"
            },
            {
                "value": 78417,
                "label": "Ordonez",
                "label_ar": "أوردونيز",
                "label_fr": "Ordonez"
            },
            {
                "value": 78569,
                "label": "Pehuajó",
                "label_ar": "بهواجو",
                "label_fr": "Pehuajó"
            },
            {
                "value": 78452,
                "label": "Arteaga",
                "label_ar": "أرتيغا",
                "label_fr": "Arteaga"
            },
            {
                "value": 78410,
                "label": "Alvarez",
                "label_ar": "ألفاريز",
                "label_fr": "Alvarez"
            },
            {
                "value": 78066,
                "label": "Bariloche",
                "label_ar": "باريلوش",
                "label_fr": "Bariloche"
            },
            {
                "value": 78058,
                "label": "Uriburu",
                "label_ar": "أوريبورو",
                "label_fr": "Uriburu"
            },
            {
                "value": 78549,
                "label": "General Martin Miguel de Guemes",
                "label_ar": "الجنرال مارتن ميغيل دي جيميس",
                "label_fr": "Général Martin Miguel de Guemes"
            },
            {
                "value": 78444,
                "label": "Villa Canas",
                "label_ar": "فيلا كاناس",
                "label_fr": "Villa Canas"
            },
            {
                "value": 78513,
                "label": "Magdalena",
                "label_ar": "ماغدالينا",
                "label_fr": "Magdalena"
            },
            {
                "value": 78180,
                "label": "Piamonte",
                "label_ar": "بيامونتي",
                "label_fr": "Piamonte"
            },
            {
                "value": 78145,
                "label": "Capitan Bermudez",
                "label_ar": "كابيتان بيرموديز",
                "label_fr": "Capitan Bermudez"
            },
            {
                "value": 78420,
                "label": "Zenon Pereyra",
                "label_ar": "زينون بيريرا",
                "label_fr": "Zenon Pereyra"
            },
            {
                "value": 78182,
                "label": "Marcos Paz",
                "label_ar": "ماركوس باز",
                "label_fr": "Marcos Paz"
            },
            {
                "value": 78061,
                "label": "La Rioja",
                "label_ar": "لاريوخا",
                "label_fr": "La Rioja"
            },
            {
                "value": 78077,
                "label": "Villa María",
                "label_ar": "فيلا ماريا",
                "label_fr": "Villa María"
            },
            {
                "value": 78163,
                "label": "Rafaela",
                "label_ar": "رافاييلا",
                "label_fr": "Rafaela"
            },
            {
                "value": 78355,
                "label": "Pergamino",
                "label_ar": "بيرغامينو",
                "label_fr": "Pergamino"
            },
            {
                "value": 78094,
                "label": "Mar del Plata",
                "label_ar": "مار ديل بلاتا",
                "label_fr": "Mar del Plata"
            },
            {
                "value": 78013,
                "label": "Gobernador Galvez",
                "label_ar": "جوبيرنادور جالفيز",
                "label_fr": "Gobernador Galvez"
            },
            {
                "value": 78505,
                "label": "Perico",
                "label_ar": "بريكو",
                "label_fr": "Perico"
            },
            {
                "value": 78042,
                "label": "San Lorenzo",
                "label_ar": "سان لورينزو",
                "label_fr": "San Lorenzo"
            },
            {
                "value": 78195,
                "label": "Sarandi",
                "label_ar": "ساراندي",
                "label_fr": "Sarandi"
            },
            {
                "value": 78065,
                "label": "Concordia",
                "label_ar": "كونكورديا",
                "label_fr": "Concordia"
            },
            {
                "value": 77964,
                "label": "Gualeguaychú",
                "label_ar": "Gualeguaychú",
                "label_fr": "Gualeguaychú"
            },
            {
                "value": 78403,
                "label": "Alta Italia",
                "label_ar": "ألتا إيطاليا",
                "label_fr": "Alta Italia"
            },
            {
                "value": 77959,
                "label": "Vicente Lopez",
                "label_ar": "فيسنتي لوبيز",
                "label_fr": "Vicente Lopez"
            },
            {
                "value": 78176,
                "label": "Curuzu Cuatia",
                "label_ar": "كوروزو كواتيا",
                "label_fr": "Curuzu Cuatia"
            },
            {
                "value": 78278,
                "label": "Las Talitas",
                "label_ar": "لاس تاليتاس",
                "label_fr": "Las Talitas"
            },
            {
                "value": 78207,
                "label": "Vera",
                "label_ar": "فيرا",
                "label_fr": "Vera"
            },
            {
                "value": 78208,
                "label": "Coronel Martinez de Hoz",
                "label_ar": "كورونيل مارتينيز دي هوز",
                "label_fr": "Coronel Martinez de Hoz"
            },
            {
                "value": 78433,
                "label": "General Lavalle",
                "label_ar": "الجنرال لافال",
                "label_fr": "Général Lavalle"
            },
            {
                "value": 78259,
                "label": "Magdalena",
                "label_ar": "ماغدالينا",
                "label_fr": "Magdalena"
            },
            {
                "value": 78295,
                "label": "Necochea",
                "label_ar": "نيكوتشيا",
                "label_fr": "Nécochée"
            },
            {
                "value": 78233,
                "label": "Salto",
                "label_ar": "سالتو",
                "label_fr": "Salto"
            },
            {
                "value": 78260,
                "label": "Ensenada",
                "label_ar": "إنسينادا",
                "label_fr": "Ensenada"
            },
            {
                "value": 78238,
                "label": "Fernandez",
                "label_ar": "فرنانديز",
                "label_fr": "Fernandez"
            },
            {
                "value": 78539,
                "label": "Villa Aberastain",
                "label_ar": "فيلا ابراستين",
                "label_fr": "Villa Aberastain"
            },
            {
                "value": 78534,
                "label": "Galvez",
                "label_ar": "جالفيز",
                "label_fr": "Galvez"
            },
            {
                "value": 77978,
                "label": "Santiago del Estero",
                "label_ar": "سانتياغو ديل استيرو",
                "label_fr": "Santiago del Estero"
            },
            {
                "value": 77999,
                "label": "Esteban Echeverria",
                "label_ar": "إستيبان إتشيفيريا",
                "label_fr": "Esteban Echeverria"
            },
            {
                "value": 78200,
                "label": "Oliva",
                "label_ar": "أوليفا",
                "label_fr": "Oliva"
            },
            {
                "value": 78288,
                "label": "Chateaubriand",
                "label_ar": "شاتوبريان",
                "label_fr": "Chateaubriand"
            },
            {
                "value": 78464,
                "label": "Perez",
                "label_ar": "بيريز",
                "label_fr": "Perez"
            },
            {
                "value": 78573,
                "label": "Villa Robles",
                "label_ar": "فيلا روبلز",
                "label_fr": "Villa Robles"
            },
            {
                "value": 78230,
                "label": "Villa Ocampo",
                "label_ar": "فيلا أوكامبو",
                "label_fr": "Villa Ocampo"
            },
            {
                "value": 78218,
                "label": "General Acha",
                "label_ar": "عام آشا",
                "label_fr": "Général Acha"
            },
            {
                "value": 78274,
                "label": "Pico de Salamanca",
                "label_ar": "بيكو دي سالامانكا",
                "label_fr": "Pico de Salamanque"
            },
            {
                "value": 78518,
                "label": "General Guido",
                "label_ar": "الجنرال جويدو",
                "label_fr": "Général Guido"
            },
            {
                "value": 78135,
                "label": "Resistencia",
                "label_ar": "ريزيستنسيا",
                "label_fr": "Resistencia"
            },
            {
                "value": 78498,
                "label": "Tacuari",
                "label_ar": "تاكوارى",
                "label_fr": "Tacuari"
            },
            {
                "value": 78409,
                "label": "Canuelas",
                "label_ar": "كانويلاس",
                "label_fr": "Canuelas"
            },
            {
                "value": 78279,
                "label": "Formosa",
                "label_ar": "فورموزا",
                "label_fr": "Formose"
            },
            {
                "value": 78227,
                "label": "General Mansilla",
                "label_ar": "الجنرال مانسيلا",
                "label_fr": "Général Mansilla"
            },
            {
                "value": 78446,
                "label": "Larroque",
                "label_ar": "لاروك",
                "label_fr": "Larroque"
            },
            {
                "value": 78068,
                "label": "San Fernando del Valle de Catamarca",
                "label_ar": "سان فرناندو ديل فالي دي كاتاماركا",
                "label_fr": "San Fernando del Valle de Catamarca"
            },
            {
                "value": 78220,
                "label": "San Cayetano",
                "label_ar": "سان كايتانو",
                "label_fr": "San Cayetano"
            },
            {
                "value": 78317,
                "label": "Funes",
                "label_ar": "فونيس",
                "label_fr": "Funes"
            },
            {
                "value": 78416,
                "label": "Jesus Maria",
                "label_ar": "يسوع ماريا",
                "label_fr": "Jésus Maria"
            },
            {
                "value": 78529,
                "label": "Gobernador Crespo",
                "label_ar": "جوبيرنادور كريسبو",
                "label_fr": "Gobernador Crespo"
            },
            {
                "value": 78429,
                "label": "Vicuna Mackenna",
                "label_ar": "فيكونا ماكينا",
                "label_fr": "Vicuna Mackenna"
            },
            {
                "value": 78354,
                "label": "General Viamonte",
                "label_ar": "الجنرال فيامونتي",
                "label_fr": "Général Viamonte"
            },
            {
                "value": 78514,
                "label": "Las Flores",
                "label_ar": "لاس فلوريس",
                "label_fr": "Las Flores"
            },
            {
                "value": 78441,
                "label": "San Javier",
                "label_ar": "سان خافيير",
                "label_fr": "San Javier"
            },
            {
                "value": 78292,
                "label": "Chacabuco",
                "label_ar": "تشاكابوكو",
                "label_fr": "Chacabuco"
            },
            {
                "value": 78350,
                "label": "Rio Tercero",
                "label_ar": "ريو تيرسيرو",
                "label_fr": "Rio Tercero"
            },
            {
                "value": 78428,
                "label": "Bustinza",
                "label_ar": "بوستينزا",
                "label_fr": "Bustinza"
            },
            {
                "value": 78583,
                "label": "Calderon",
                "label_ar": "كالديرون",
                "label_fr": "Calderon"
            },
            {
                "value": 78241,
                "label": "Pontevedra",
                "label_ar": "بونتيفيدرا",
                "label_fr": "Pontevedra"
            },
            {
                "value": 78467,
                "label": "Villa Constitucion",
                "label_ar": "فيلا كونستيتيوسيون",
                "label_fr": "Villa Constitucion"
            },
            {
                "value": 78215,
                "label": "Villa Nueva",
                "label_ar": "فيلا نويفا",
                "label_fr": "Villa Nueva"
            },
            {
                "value": 78093,
                "label": "City Bell",
                "label_ar": "مدينة بيل",
                "label_fr": "Cloche de la ville"
            },
            {
                "value": 78258,
                "label": "Lujan de Cuyo",
                "label_ar": "لوجان دي كويو",
                "label_fr": "Lujan de Cuyo"
            },
            {
                "value": 78023,
                "label": "Villa Ballester",
                "label_ar": "فيلا باليستر",
                "label_fr": "Villa Ballester"
            },
            {
                "value": 78406,
                "label": "Miramar",
                "label_ar": "ميرامار",
                "label_fr": "Miramar"
            },
            {
                "value": 78249,
                "label": "Ciudad General Belgrano",
                "label_ar": "Ciudad General Belgrano",
                "label_fr": "Ciudad General Belgrano"
            },
            {
                "value": 78309,
                "label": "Villa Insuperable",
                "label_ar": "فيلا لا تقهر",
                "label_fr": "Villa Insuperable"
            },
            {
                "value": 78381,
                "label": "Arrecifes",
                "label_ar": "مقولات",
                "label_fr": "Arrecifes"
            },
            {
                "value": 77967,
                "label": "Corrientes",
                "label_ar": "كورينتس",
                "label_fr": "Corrientes"
            },
            {
                "value": 78474,
                "label": "Firmat",
                "label_ar": "فيرمات",
                "label_fr": "Firmat"
            },
            {
                "value": 78340,
                "label": "Villa Amelia",
                "label_ar": "فيلا اميليا",
                "label_fr": "Villa Amelia"
            },
            {
                "value": 78330,
                "label": "Puan",
                "label_ar": "بوان",
                "label_fr": "Puan"
            },
            {
                "value": 77971,
                "label": "La Pampa",
                "label_ar": "لا بامبا",
                "label_fr": "La Pampa"
            },
            {
                "value": 78367,
                "label": "Recoleta",
                "label_ar": "ريكوليتا",
                "label_fr": "Recoleta"
            },
            {
                "value": 78528,
                "label": "Albarellos",
                "label_ar": "الباريلوس",
                "label_fr": "Albarellos"
            },
            {
                "value": 78263,
                "label": "Roque Perez",
                "label_ar": "روكي بيريز",
                "label_fr": "Roque Perez"
            },
            {
                "value": 78476,
                "label": "Acassuso",
                "label_ar": "أكاسوسو",
                "label_fr": "Acassuso"
            },
            {
                "value": 78243,
                "label": "Chivilcoy",
                "label_ar": "تشيفيلكوي",
                "label_fr": "Chivilcoy"
            },
            {
                "value": 78575,
                "label": "Laguna Alsina",
                "label_ar": "لاجونا السينا",
                "label_fr": "Laguna Alsina"
            },
            {
                "value": 78507,
                "label": "Embalse",
                "label_ar": "زائف",
                "label_fr": "Embalse"
            },
            {
                "value": 78521,
                "label": "Las Flores",
                "label_ar": "لاس فلوريس",
                "label_fr": "Las Flores"
            },
            {
                "value": 78323,
                "label": "Rojas",
                "label_ar": "روخاس",
                "label_fr": "Rojas"
            },
            {
                "value": 78268,
                "label": "Lobos",
                "label_ar": "لوبوس",
                "label_fr": "Lobos"
            },
            {
                "value": 78365,
                "label": "Alta Gracia",
                "label_ar": "ألتا جراسيا",
                "label_fr": "Alta Gracia"
            },
            {
                "value": 78087,
                "label": "Sacanta",
                "label_ar": "ساكنتا",
                "label_fr": "Sacanta"
            },
            {
                "value": 78056,
                "label": "Frias",
                "label_ar": "فرياس",
                "label_fr": "Frias"
            },
            {
                "value": 78273,
                "label": "Devoto",
                "label_ar": "ديفوتو",
                "label_fr": "Devoto"
            },
            {
                "value": 78313,
                "label": "General Rodriguez",
                "label_ar": "الجنرال رودريغيز",
                "label_fr": "Général Rodriguez"
            },
            {
                "value": 78000,
                "label": "Gonzalez Moreno",
                "label_ar": "جونزاليس مورينو",
                "label_fr": "Gonzalez Moreno"
            },
            {
                "value": 78088,
                "label": "Las Varillas",
                "label_ar": "لاس فارياس",
                "label_fr": "Las Varillas"
            },
            {
                "value": 78223,
                "label": "General Alvear",
                "label_ar": "الجنرال ألفير",
                "label_fr": "Général Alvear"
            },
            {
                "value": 78024,
                "label": "Don Torcuato",
                "label_ar": "دون توركواتو",
                "label_fr": "Don Torcuato"
            },
            {
                "value": 78389,
                "label": "Carhue",
                "label_ar": "كارهو",
                "label_fr": "Carhue"
            },
            {
                "value": 78114,
                "label": "Miramar",
                "label_ar": "ميرامار",
                "label_fr": "Miramar"
            },
            {
                "value": 78356,
                "label": "San Francisco",
                "label_ar": "سان فرانسيسكو",
                "label_fr": "San Francisco"
            },
            {
                "value": 78138,
                "label": "Santa Rosa",
                "label_ar": "سانتا روزا",
                "label_fr": "Santa Rosa"
            },
            {
                "value": 78437,
                "label": "Luzuriaga",
                "label_ar": "لوزورياجا",
                "label_fr": "Luzuriaga"
            },
            {
                "value": 78037,
                "label": "Tigre",
                "label_ar": "تيغري",
                "label_fr": "Tigre"
            },
            {
                "value": 77996,
                "label": "Ezeiza",
                "label_ar": "عزيزة",
                "label_fr": "Ezeiza"
            },
            {
                "value": 78469,
                "label": "Maipu",
                "label_ar": "ميبو",
                "label_fr": "Maipu"
            },
            {
                "value": 78454,
                "label": "Godoy",
                "label_ar": "جودوي",
                "label_fr": "Godoy"
            },
            {
                "value": 78552,
                "label": "Doblas",
                "label_ar": "دوبلاس",
                "label_fr": "Doblas"
            },
            {
                "value": 78382,
                "label": "Todd",
                "label_ar": "تود",
                "label_fr": "Todd"
            },
            {
                "value": 78342,
                "label": "Maria Juana",
                "label_ar": "ماريا خوانا",
                "label_fr": "Maria Juana"
            },
            {
                "value": 78407,
                "label": "Balnearia",
                "label_ar": "بالنيريا",
                "label_fr": "Balnéaire"
            },
            {
                "value": 78556,
                "label": "La Punta",
                "label_ar": "لا بونتا",
                "label_fr": "La Punta"
            },
            {
                "value": 78482,
                "label": "Monteros",
                "label_ar": "مونتيروس",
                "label_fr": "Monteros"
            },
            {
                "value": 78487,
                "label": "Juan Pujol",
                "label_ar": "خوان بوجول",
                "label_fr": "Juan Pujol"
            },
            {
                "value": 78020,
                "label": "Villa Carlos Paz",
                "label_ar": "فيلا كارلوس باز",
                "label_fr": "Villa Carlos Paz"
            },
            {
                "value": 77988,
                "label": "Ituzaingo",
                "label_ar": "إيتوزينغو",
                "label_fr": "Ituzaingo"
            },
            {
                "value": 78091,
                "label": "Ushuaia",
                "label_ar": "أوشوايا",
                "label_fr": "Ushuaia"
            },
            {
                "value": 78025,
                "label": "Matheu",
                "label_ar": "ماتيو",
                "label_fr": "Matheu"
            },
            {
                "value": 78520,
                "label": "Vedia",
                "label_ar": "فيديا",
                "label_fr": "Vedia"
            },
            {
                "value": 78275,
                "label": "Azul",
                "label_ar": "أزول",
                "label_fr": "Azul"
            },
            {
                "value": 78133,
                "label": "Berazategui",
                "label_ar": "بيرازاتيجوي",
                "label_fr": "Berazategui"
            },
            {
                "value": 78272,
                "label": "San Francisco de Santa Fe",
                "label_ar": "سان فرانسيسكو دي سانتا في",
                "label_fr": "San Francisco de Santa Fe"
            },
            {
                "value": 78134,
                "label": "Obera",
                "label_ar": "أوبرا",
                "label_fr": "Obera"
            },
            {
                "value": 78533,
                "label": "Hernando",
                "label_ar": "هيرناندو",
                "label_fr": "Hernando"
            },
            {
                "value": 78438,
                "label": "Ibicuy",
                "label_ar": "إيبيكوي",
                "label_fr": "Ibicuy"
            },
            {
                "value": 78010,
                "label": "Mar de Ajo",
                "label_ar": "مار دي آجو",
                "label_fr": "Mar de Ajo"
            },
            {
                "value": 78478,
                "label": "San Pedro",
                "label_ar": "حي سان بيدرو",
                "label_fr": "San Pedro"
            },
            {
                "value": 78560,
                "label": "Carlos Casares",
                "label_ar": "كارلوس كاساريس",
                "label_fr": "Carlos Casares"
            },
            {
                "value": 78244,
                "label": "Luján",
                "label_ar": "لوجان",
                "label_fr": "Luján"
            },
            {
                "value": 78537,
                "label": "Rada Tilly",
                "label_ar": "رادا تيلي",
                "label_fr": "Rada Tilly"
            },
            {
                "value": 78572,
                "label": "Maria Ignacia",
                "label_ar": "ماريا اجناسيا",
                "label_fr": "Maria Ignacia"
            },
            {
                "value": 78053,
                "label": "San Martin",
                "label_ar": "سان مارتن",
                "label_fr": "San Martin"
            },
            {
                "value": 78174,
                "label": "Junín",
                "label_ar": "جونين",
                "label_fr": "Junín"
            },
            {
                "value": 78212,
                "label": "San Gregorio",
                "label_ar": "سان جريجوريو",
                "label_fr": "San Gregorio"
            },
            {
                "value": 78211,
                "label": "Catriel",
                "label_ar": "كاتريل",
                "label_fr": "Catriel"
            },
            {
                "value": 78526,
                "label": "Corral de Bustos",
                "label_ar": "كورال دي بوستوس",
                "label_fr": "Corral de Bustos"
            },
            {
                "value": 78171,
                "label": "Río Gallegos",
                "label_ar": "ريو جاليجوس",
                "label_fr": "Río Gallegos"
            },
            {
                "value": 78415,
                "label": "Colonia Caroya",
                "label_ar": "كولونيا كارويا",
                "label_fr": "Colonia Caroya"
            },
            {
                "value": 78283,
                "label": "Concepción del Uruguay",
                "label_ar": "كونسيبسيون ديل أوروغواي",
                "label_fr": "Concepción del Uruguay"
            },
            {
                "value": 78123,
                "label": "Almagro",
                "label_ar": "الماجرو",
                "label_fr": "Almagro"
            },
            {
                "value": 78251,
                "label": "Ezpeleta",
                "label_ar": "إزبيليتا",
                "label_fr": "Ezpeleta"
            },
            {
                "value": 78550,
                "label": "San Nicolas",
                "label_ar": "سان نيكولاس",
                "label_fr": "San Nicolas"
            },
            {
                "value": 78540,
                "label": "Hilario Ascasubi",
                "label_ar": "هيلاريو أسكاسوبي",
                "label_fr": "Hilario Ascasubi"
            },
            {
                "value": 78558,
                "label": "Florentino Ameghino",
                "label_ar": "فلورنتينو أميجينو",
                "label_fr": "Florentino Ameghino"
            },
            {
                "value": 78092,
                "label": "Rio Grande",
                "label_ar": "ريو غراندي",
                "label_fr": "Rio Grande"
            },
            {
                "value": 78131,
                "label": "Francisco Alvarez",
                "label_ar": "فرانسيسكو ألفاريز",
                "label_fr": "Francisco Alvarez"
            },
            {
                "value": 78063,
                "label": "Castillo",
                "label_ar": "كاستيلو",
                "label_fr": "Castillo"
            },
            {
                "value": 78432,
                "label": "Rawson",
                "label_ar": "روسون",
                "label_fr": "Rawson"
            },
            {
                "value": 78314,
                "label": "La Lucila",
                "label_ar": "لا لوسيلا",
                "label_fr": "La Lucila"
            },
            {
                "value": 77982,
                "label": "Paso del Rey",
                "label_ar": "باسو ديل ري",
                "label_fr": "Paso del Rey"
            },
            {
                "value": 77963,
                "label": "Puerto Madryn",
                "label_ar": "بويرتو مادرين",
                "label_fr": "Puerto Madryn"
            },
            {
                "value": 78568,
                "label": "Clorinda",
                "label_ar": "كلوريندا",
                "label_fr": "Clorinda"
            },
            {
                "value": 78472,
                "label": "General Villegas",
                "label_ar": "عام فيليجاس",
                "label_fr": "Général Villegas"
            },
            {
                "value": 78041,
                "label": "Villa de Mayo",
                "label_ar": "فيلا دي مايو",
                "label_fr": "Villa de Mayo"
            },
            {
                "value": 77987,
                "label": "Hipolito Yrigoyen",
                "label_ar": "هيبوليتو يريغوين",
                "label_fr": "Hipolito Yrigoyen"
            },
            {
                "value": 78099,
                "label": "Avellaneda",
                "label_ar": "أفيلانيدا",
                "label_fr": "Avellaneda"
            },
            {
                "value": 78515,
                "label": "Pellegrini",
                "label_ar": "بيليجريني",
                "label_fr": "Pellegrini"
            },
            {
                "value": 78021,
                "label": "Mariano Moreno",
                "label_ar": "ماريانو مورينو",
                "label_fr": "Mariano Moreno"
            },
            {
                "value": 78169,
                "label": "Castelli",
                "label_ar": "كاستيلي",
                "label_fr": "Castelli"
            },
            {
                "value": 78222,
                "label": "Leandro N. Alem",
                "label_ar": "لياندرو ن",
                "label_fr": "Leandro N. Alem"
            },
            {
                "value": 78045,
                "label": "Caseros",
                "label_ar": "كاسيروس",
                "label_fr": "Caseros"
            },
            {
                "value": 77993,
                "label": "Jose Marmol",
                "label_ar": "خوسيه مارمول",
                "label_fr": "José Marmol"
            },
            {
                "value": 78130,
                "label": "Rio Ceballos",
                "label_ar": "ريو سيبايوس",
                "label_fr": "Rio Ceballos"
            },
            {
                "value": 77960,
                "label": "Rosario",
                "label_ar": "روزاريو",
                "label_fr": "Rosario"
            },
            {
                "value": 78368,
                "label": "La Falda",
                "label_ar": "لا فالدا",
                "label_fr": "La Falda"
            },
            {
                "value": 78070,
                "label": "Apostoles",
                "label_ar": "الرسل",
                "label_fr": "Apostoles"
            },
            {
                "value": 78228,
                "label": "Monte Hermoso",
                "label_ar": "مونتي هيرموسو",
                "label_fr": "Monte Hermoso"
            },
            {
                "value": 78542,
                "label": "Drabble",
                "label_ar": "درابل",
                "label_fr": "Drabble"
            },
            {
                "value": 78580,
                "label": "Ingeniero Maschwitz",
                "label_ar": "إنجينيرو ماشويتز",
                "label_fr": "Ingeniero Maschwitz"
            },
            {
                "value": 78468,
                "label": "Bigand",
                "label_ar": "كبيرة و",
                "label_fr": "Bigand"
            },
            {
                "value": 78519,
                "label": "Teodelina",
                "label_ar": "تيوديلينا",
                "label_fr": "Teodelina"
            },
            {
                "value": 78203,
                "label": "Tornquist",
                "label_ar": "تورنكويست",
                "label_fr": "Tornquist"
            },
            {
                "value": 78116,
                "label": "Lomas del Mirador",
                "label_ar": "لوماس ديل ميرادور",
                "label_fr": "Lomas del Mirador"
            },
            {
                "value": 78404,
                "label": "Colonia Baron",
                "label_ar": "كولونيا بارون",
                "label_fr": "Colonia Baron"
            },
            {
                "value": 78326,
                "label": "General Paz",
                "label_ar": "الجنرال باز",
                "label_fr": "Général Paz"
            },
            {
                "value": 78308,
                "label": "Allen",
                "label_ar": "ألين",
                "label_fr": "Allen"
            },
            {
                "value": 78310,
                "label": "Open Door",
                "label_ar": "باب مفتوح",
                "label_fr": "Porte ouverte"
            },
            {
                "value": 78430,
                "label": "Lima",
                "label_ar": "ليما",
                "label_fr": "Lima"
            },
            {
                "value": 78267,
                "label": "Navarro",
                "label_ar": "نافارو",
                "label_fr": "Navarro"
            },
            {
                "value": 78471,
                "label": "San Genaro",
                "label_ar": "سان جينارو",
                "label_fr": "San Genaro"
            },
            {
                "value": 78248,
                "label": "Bella Vista",
                "label_ar": "بيلا فيستا",
                "label_fr": "Bella Vista"
            },
            {
                "value": 78103,
                "label": "Longchamps",
                "label_ar": "لونج تشامبس",
                "label_fr": "Longchamps"
            },
            {
                "value": 78297,
                "label": "Monje",
                "label_ar": "مونجي",
                "label_fr": "Monje"
            },
            {
                "value": 78555,
                "label": "San Martín de los Andes",
                "label_ar": "سان مارتين دي لوس أنديز",
                "label_fr": "San Martín de los Andes"
            },
            {
                "value": 78559,
                "label": "Portena",
                "label_ar": "بورتينا",
                "label_fr": "Portena"
            },
            {
                "value": 78151,
                "label": "General Roca",
                "label_ar": "جنرال روكا",
                "label_fr": "Général Roca"
            },
            {
                "value": 78139,
                "label": "Ramallo",
                "label_ar": "رامالو",
                "label_fr": "Ramallo"
            },
            {
                "value": 78582,
                "label": "Cramer",
                "label_ar": "كريمر",
                "label_fr": "Cramer"
            },
            {
                "value": 78390,
                "label": "Colonia San Miguel Arcangel",
                "label_ar": "كولونيا سان ميغيل أركانجيل",
                "label_fr": "Colonia San Miguel Arcangel"
            },
            {
                "value": 78128,
                "label": "Rio Segundo",
                "label_ar": "ريو سيجوندو",
                "label_fr": "Rio Segundo"
            },
            {
                "value": 78076,
                "label": "Cruz del Eje",
                "label_ar": "كروز ديل إيجي",
                "label_fr": "Cruz del Eje"
            },
            {
                "value": 78106,
                "label": "Punta Alta",
                "label_ar": "بونتا ألتا",
                "label_fr": "Punta Alta"
            },
            {
                "value": 78431,
                "label": "Floresta",
                "label_ar": "فلوريستا",
                "label_fr": "Floresta"
            },
            {
                "value": 78270,
                "label": "Almirante Brown",
                "label_ar": "ألميرانتي براون",
                "label_fr": "Marron Almirante"
            },
            {
                "value": 78231,
                "label": "Manuel J. Cobo",
                "label_ar": "مانويل جيه كوبو",
                "label_fr": "Manuel J. Cobo"
            },
            {
                "value": 78338,
                "label": "Angelica",
                "label_ar": "انجليكا",
                "label_fr": "Angélique"
            },
            {
                "value": 78434,
                "label": "Gaiman",
                "label_ar": "جايمان",
                "label_fr": "Gaïman"
            },
            {
                "value": 78237,
                "label": "San Antonio de Obligado",
                "label_ar": "سان أنطونيو دي أوبليغادو",
                "label_fr": "San Antonio de Obligado"
            },
            {
                "value": 78531,
                "label": "Camilo Aldao",
                "label_ar": "كاميلو الداو",
                "label_fr": "Camilo Aldao"
            },
            {
                "value": 78530,
                "label": "Villa Nueva",
                "label_ar": "فيلا نويفا",
                "label_fr": "Villa Nueva"
            },
            {
                "value": 78102,
                "label": "Remedios de Escalada",
                "label_ar": "ريميديوس دي إسكالادا",
                "label_fr": "Remedios de Escalada"
            },
            {
                "value": 78495,
                "label": "Tio Pujio",
                "label_ar": "تيو بوجيو",
                "label_fr": "Tio Pujio"
            },
            {
                "value": 78360,
                "label": "Cavanagh",
                "label_ar": "كافانا",
                "label_fr": "Cavanagh"
            },
            {
                "value": 77997,
                "label": "Canning",
                "label_ar": "تعليب",
                "label_fr": "Mise en conserve"
            },
            {
                "value": 78318,
                "label": "Avellaneda",
                "label_ar": "أفيلانيدا",
                "label_fr": "Avellaneda"
            },
            {
                "value": 78089,
                "label": "Ramos Mejia",
                "label_ar": "راموس ميخيا",
                "label_fr": "Ramos Mejia"
            },
            {
                "value": 78276,
                "label": "Pedro Luro",
                "label_ar": "بيدرو لورو",
                "label_fr": "Pedro Luro"
            },
            {
                "value": 78299,
                "label": "Crespo",
                "label_ar": "كريسبو",
                "label_fr": "Crespo"
            },
            {
                "value": 78293,
                "label": "Viale",
                "label_ar": "فيالي",
                "label_fr": "Viale"
            },
            {
                "value": 78221,
                "label": "San Miguel del Monte",
                "label_ar": "سان ميغيل ديل مونتي",
                "label_fr": "San Miguel del Monte"
            },
            {
                "value": 78017,
                "label": "Malargüe",
                "label_ar": "مالارجوي",
                "label_fr": "Malargüe"
            },
            {
                "value": 78466,
                "label": "Santa Lucia",
                "label_ar": "سانتا لوسيا",
                "label_fr": "Santa Lucia"
            },
            {
                "value": 77976,
                "label": "Trelew",
                "label_ar": "تريليو",
                "label_fr": "Trelew"
            },
            {
                "value": 78132,
                "label": "La Boca",
                "label_ar": "لا بوكا",
                "label_fr": "La Boca"
            },
            {
                "value": 78322,
                "label": "Salguero",
                "label_ar": "سالجويرو",
                "label_fr": "Salguero"
            },
            {
                "value": 78443,
                "label": "Veinticinco de Mayo",
                "label_ar": "Veinticinco de Mayo",
                "label_fr": "Veinticinco de Mayo"
            },
            {
                "value": 78372,
                "label": "Sierra de los Padres",
                "label_ar": "سييرا دي لوس بادريس",
                "label_fr": "Sierra de los Padres"
            },
            {
                "value": 78483,
                "label": "Santo Tome",
                "label_ar": "سانتو تومي",
                "label_fr": "Santo Tome"
            },
            {
                "value": 78371,
                "label": "Esquel",
                "label_ar": "Esquel",
                "label_fr": "Esquel"
            },
            {
                "value": 78285,
                "label": "Banda del Rio Sali",
                "label_ar": "باندا ديل ريو سالي",
                "label_fr": "Banda del Rio Sali"
            },
            {
                "value": 78541,
                "label": "Manfredi",
                "label_ar": "مانفريدي",
                "label_fr": "Manfredi"
            },
            {
                "value": 78205,
                "label": "El Bolsón",
                "label_ar": "El Bolsón",
                "label_fr": "El Bolsón"
            },
            {
                "value": 78380,
                "label": "Santa Elena",
                "label_ar": "سانتا ايلينا",
                "label_fr": "Santa Elena"
            }
        ]
    },
    {
        "country": "Brunei",
        "cities": [
            {
                "value": 78588,
                "label": "Kampong Jerudong",
                "label_ar": "كامبونج جيرودونج",
                "label_fr": "Kampong Jerudong"
            },
            {
                "value": 78591,
                "label": "Bangar",
                "label_ar": "البنغار",
                "label_fr": "Bangar"
            },
            {
                "value": 78584,
                "label": "Bandar Seri Begawan",
                "label_ar": "بندر سيري بيغاوان",
                "label_fr": "Bandar Seri Begawan"
            },
            {
                "value": 78585,
                "label": "Seria",
                "label_ar": "سيريا",
                "label_fr": "Seria"
            },
            {
                "value": 78587,
                "label": "Kuala Belait",
                "label_ar": "كوالا بيليت",
                "label_fr": "Kuala Belait"
            },
            {
                "value": 78589,
                "label": "Kuala Balai",
                "label_ar": "كوالا بالاي",
                "label_fr": "Kuala Balai"
            },
            {
                "value": 78590,
                "label": "Panaga",
                "label_ar": "باناجا",
                "label_fr": "Panaga"
            },
            {
                "value": 78586,
                "label": "Tutong",
                "label_ar": "توتونج",
                "label_fr": "Tutong"
            }
        ]
    },
    {
        "country": "Bahrain",
        "cities": [
            {
                "value": 78608,
                "label": "Sanabis",
                "label_ar": "السنابس",
                "label_fr": "Sanabis"
            },
            {
                "value": 78594,
                "label": "Al Muharraq",
                "label_ar": "المحرق",
                "label_fr": "Al Muharraq"
            },
            {
                "value": 78606,
                "label": "Jurdab",
                "label_ar": "جرداب",
                "label_fr": "Jurdab"
            },
            {
                "value": 78599,
                "label": "Bani Jamrah",
                "label_ar": "بني جمرة",
                "label_fr": "Bani Jamrah"
            },
            {
                "value": 78597,
                "label": "Al Markh",
                "label_ar": "المرخ",
                "label_fr": "Al Markh"
            },
            {
                "value": 78595,
                "label": "Madinat Hamad",
                "label_ar": "مدينة حمد",
                "label_fr": "Madinat Hamad"
            },
            {
                "value": 78603,
                "label": "Al Hamalah",
                "label_ar": "الهملة",
                "label_fr": "Al Hamalah"
            },
            {
                "value": 78601,
                "label": "Madinat `Isa",
                "label_ar": "مدينة عيسى",
                "label_fr": "Madinat ʻIsa"
            },
            {
                "value": 78592,
                "label": "Manama",
                "label_ar": "المنامة",
                "label_fr": "Manama"
            },
            {
                "value": 78604,
                "label": "Sitrah",
                "label_ar": "سترة",
                "label_fr": "Sitrah"
            },
            {
                "value": 78600,
                "label": "Sanad",
                "label_ar": "سند",
                "label_fr": "Sanad"
            },
            {
                "value": 78596,
                "label": "Al Janabiyah",
                "label_ar": "الجنبية",
                "label_fr": "Al Janabiyah"
            },
            {
                "value": 78607,
                "label": "Oil City",
                "label_ar": "مدينة النفط",
                "label_fr": "Ville pétrolière"
            },
            {
                "value": 78593,
                "label": "Barbar",
                "label_ar": "باربار",
                "label_fr": "Barbar"
            },
            {
                "value": 78605,
                "label": "Tubli",
                "label_ar": "توبلي",
                "label_fr": "Tubli"
            },
            {
                "value": 78598,
                "label": "Al Budayyi`",
                "label_ar": "البديع",
                "label_fr": "Al Budayyi`"
            },
            {
                "value": 78602,
                "label": "Al Hadd",
                "label_ar": "الحد",
                "label_fr": "Al Hadd"
            }
        ]
    },
    {
        "country": "Aruba",
        "cities": [
            {
                "value": 78612,
                "label": "Tanki Lender",
                "label_ar": "مقرض تانكي",
                "label_fr": "Prêteur Tanki"
            },
            {
                "value": 78609,
                "label": "Palm Beach",
                "label_ar": "شاطئ النخيل",
                "label_fr": "plage de palmiers"
            },
            {
                "value": 78616,
                "label": "Sabaneta",
                "label_ar": "سابانيتا",
                "label_fr": "Sabaneta"
            },
            {
                "value": 78617,
                "label": "San Barbola",
                "label_ar": "سان باربولا",
                "label_fr": "San Barbola"
            },
            {
                "value": 78615,
                "label": "Paradera",
                "label_ar": "باراديرا",
                "label_fr": "Paradera"
            },
            {
                "value": 78611,
                "label": "Sero Blanco",
                "label_ar": "سيرو بلانكو",
                "label_fr": "Sero Blanco"
            },
            {
                "value": 78610,
                "label": "Oranjestad",
                "label_ar": "أورانجيستاد",
                "label_fr": "Oranjestad"
            },
            {
                "value": 78614,
                "label": "Noord",
                "label_ar": "نورد",
                "label_fr": "Noord"
            },
            {
                "value": 78613,
                "label": "Santa Cruz",
                "label_ar": "سانتا كروز",
                "label_fr": "Santa Cruz"
            },
            {
                "value": 78618,
                "label": "Sint Nicolaas",
                "label_ar": "سينت نيكولاس",
                "label_fr": "Sint Nicolaas"
            },
            {
                "value": 78619,
                "label": "Ponton",
                "label_ar": "بونتون",
                "label_fr": "Ponton"
            }
        ]
    },
    {
        "country": "Saint Lucia",
        "cities": [
            {
                "value": 78623,
                "label": "Vieux Fort",
                "label_ar": "فيو فورت",
                "label_fr": "Vieux Fort"
            },
            {
                "value": 78626,
                "label": "Choiseul",
                "label_ar": "شويزول",
                "label_fr": "Choiseul"
            },
            {
                "value": 78624,
                "label": "Anse La Raye",
                "label_ar": "أنس لا راي",
                "label_fr": "Anse La Raye"
            },
            {
                "value": 78621,
                "label": "Castries",
                "label_ar": "كاستريس",
                "label_fr": "Castries"
            },
            {
                "value": 78622,
                "label": "Dauphin",
                "label_ar": "دوفين",
                "label_fr": "Dauphin"
            },
            {
                "value": 78625,
                "label": "Gros Islet",
                "label_ar": "جزيرة جروس",
                "label_fr": "Gros Islet"
            }
        ]
    },
    {
        "country": "Bangladesh",
        "cities": [
            {
                "value": 78643,
                "label": "Uttara",
                "label_ar": "أوتارا",
                "label_fr": "Uttara"
            },
            {
                "value": 78642,
                "label": "Jessore",
                "label_ar": "جيسور",
                "label_fr": "Jessore"
            },
            {
                "value": 78636,
                "label": "Hossain",
                "label_ar": "حسين",
                "label_fr": "Hossain"
            },
            {
                "value": 78637,
                "label": "Kazipur",
                "label_ar": "كازيبور",
                "label_fr": "Kazipur"
            },
            {
                "value": 78627,
                "label": "Dhaka",
                "label_ar": "دكا",
                "label_fr": "Dhaka"
            },
            {
                "value": 78633,
                "label": "Barisal",
                "label_ar": "باريسال",
                "label_fr": "Barisal"
            },
            {
                "value": 78631,
                "label": "Bangla",
                "label_ar": "البنغالية",
                "label_fr": "Bangla"
            },
            {
                "value": 78634,
                "label": "Kabi",
                "label_ar": "كابي",
                "label_fr": "Kabi"
            },
            {
                "value": 78639,
                "label": "Bazar",
                "label_ar": "بازار",
                "label_fr": "Bazar"
            },
            {
                "value": 78630,
                "label": "Agrabad",
                "label_ar": "أغراباد",
                "label_fr": "Agrabad"
            },
            {
                "value": 78638,
                "label": "Rajshahi",
                "label_ar": "راجشاهي",
                "label_fr": "Rajshahi"
            },
            {
                "value": 78628,
                "label": "Tejgaon",
                "label_ar": "تيجون",
                "label_fr": "Tejgaon"
            },
            {
                "value": 78640,
                "label": "Mymensingh",
                "label_ar": "ميمينسينغ",
                "label_fr": "Mymensingh"
            },
            {
                "value": 78635,
                "label": "Comilla",
                "label_ar": "كوميلا",
                "label_fr": "Comilla"
            },
            {
                "value": 78629,
                "label": "Chittagong",
                "label_ar": "شيتاغونغ",
                "label_fr": "Chittagong"
            },
            {
                "value": 78641,
                "label": "Narayanganj",
                "label_ar": "نارايانجانج",
                "label_fr": "Narayanganj"
            },
            {
                "value": 78632,
                "label": "Delta",
                "label_ar": "دلتا",
                "label_fr": "Delta"
            }
        ]
    },
    {
        "country": "Tokelau",
        "cities": [
            {
                "value": 78644,
                "label": "Atafu Village",
                "label_ar": "قرية عطافو",
                "label_fr": "Village d'Atafu"
            }
        ]
    },
    {
        "country": "Cambodia",
        "cities": [
            {
                "value": 78656,
                "label": "Phumi Thmei",
                "label_ar": "فومي ثمي",
                "label_fr": "Phumi Thmei"
            },
            {
                "value": 78648,
                "label": "Phumi Vott Phnum",
                "label_ar": "فومي فوت بنوم",
                "label_fr": "Phumi Vott Phnum"
            },
            {
                "value": 78658,
                "label": "Phumi Chhuk",
                "label_ar": "فومي تشوك",
                "label_fr": "Phumi Chhuk"
            },
            {
                "value": 78657,
                "label": "Phumi Prei",
                "label_ar": "فومي بري",
                "label_fr": "Phumi Prei"
            },
            {
                "value": 78654,
                "label": "Phumi Prek Mrinh",
                "label_ar": "فومي بريك مرينه",
                "label_fr": "Phumi Prek Mrinh"
            },
            {
                "value": 78651,
                "label": "Tuol Kok",
                "label_ar": "تول كوك",
                "label_fr": "Tuol Kok"
            },
            {
                "value": 78653,
                "label": "Moung Roessei",
                "label_ar": "مونج رويسي",
                "label_fr": "Moung Roessei"
            },
            {
                "value": 78647,
                "label": "Phumi Siem Reab",
                "label_ar": "فومي سييم ريب",
                "label_fr": "Phumi Siem Reab"
            },
            {
                "value": 78645,
                "label": "Phnom Penh",
                "label_ar": "بنوم بنه",
                "label_fr": "Phnom Penh"
            },
            {
                "value": 78649,
                "label": "Sihanoukville",
                "label_ar": "سيهانوكفيل",
                "label_fr": "Sihanoukville"
            },
            {
                "value": 78646,
                "label": "Phumi Preah Haoh",
                "label_ar": "فومي برياه هاوه",
                "label_fr": "Phumi Preah Haoh"
            },
            {
                "value": 78650,
                "label": "Phumi Boeng (1)",
                "label_ar": "فومي بوينج (1)",
                "label_fr": "Phumi Boeng (1)"
            },
            {
                "value": 78652,
                "label": "Phumi Thnal",
                "label_ar": "فومي ثنال",
                "label_fr": "Phumi Thnal"
            },
            {
                "value": 78655,
                "label": "Vott Kampong Svay",
                "label_ar": "فوت كامبونغ سفاي",
                "label_fr": "Vott Kampong Svay"
            }
        ]
    },
    {
        "country": "Macao",
        "cities": [
            {
                "value": 78659,
                "label": "Macao",
                "label_ar": "ماكاو",
                "label_fr": "Macao"
            },
            {
                "value": 78660,
                "label": "Taipa",
                "label_ar": "تايبا",
                "label_fr": "Taipa"
            }
        ]
    },
    {
        "country": "Maldives",
        "cities": [
            {
                "value": 78661,
                "label": "Malé",
                "label_ar": "الذكر",
                "label_fr": "Masculin"
            },
            {
                "value": 78662,
                "label": "Naifaru",
                "label_ar": "نايفارو",
                "label_fr": "Naifaru"
            }
        ]
    },
    {
        "country": "Afghanistan",
        "cities": [
            {
                "value": 78668,
                "label": "Molah",
                "label_ar": "ملاح",
                "label_fr": "Molah"
            },
            {
                "value": 78669,
                "label": "Rana",
                "label_ar": "رنا",
                "label_fr": "Rana"
            },
            {
                "value": 78665,
                "label": "Kandahar",
                "label_ar": "قندهار",
                "label_fr": "Kandahar"
            },
            {
                "value": 78670,
                "label": "Herat",
                "label_ar": "هيرات",
                "label_fr": "Herat"
            },
            {
                "value": 78664,
                "label": "Wazir Akbar Khan",
                "label_ar": "وزير أكبر خان",
                "label_fr": "Wazir Akbar Khan"
            },
            {
                "value": 78667,
                "label": "Shar",
                "label_ar": "شار",
                "label_fr": "Shar"
            },
            {
                "value": 78666,
                "label": "Sharif",
                "label_ar": "شريف",
                "label_fr": "Sharif"
            },
            {
                "value": 78663,
                "label": "Kabul",
                "label_ar": "كابول",
                "label_fr": "Kaboul"
            }
        ]
    },
    {
        "country": "New Caledonia",
        "cities": [
            {
                "value": 78673,
                "label": "Mont-Dore",
                "label_ar": "مونت دور",
                "label_fr": "Mont-Dore"
            },
            {
                "value": 78674,
                "label": "Dumbea",
                "label_ar": "دمبيا",
                "label_fr": "Dumbea"
            },
            {
                "value": 78671,
                "label": "Nouville",
                "label_ar": "نوفيل",
                "label_fr": "Nouville"
            },
            {
                "value": 78672,
                "label": "Noumea",
                "label_ar": "نوميا",
                "label_fr": "Nouméa"
            },
            {
                "value": 78675,
                "label": "Paita",
                "label_ar": "بيتا",
                "label_fr": "Paita"
            }
        ]
    },
    {
        "country": "Fiji",
        "cities": [
            {
                "value": 78677,
                "label": "Lautoka",
                "label_ar": "لاوتوكا",
                "label_fr": "Lautoka"
            },
            {
                "value": 78679,
                "label": "Tamavua Heights",
                "label_ar": "مرتفعات تامافوا",
                "label_fr": "Tamavua Heights"
            },
            {
                "value": 78678,
                "label": "Nadi",
                "label_ar": "نادي",
                "label_fr": "Nadi"
            },
            {
                "value": 78676,
                "label": "Suva",
                "label_ar": "سوفا",
                "label_fr": "Suva"
            }
        ]
    },
    {
        "country": "Wallis and Futuna",
        "cities": [
            {
                "value": 78680,
                "label": "Vailala",
                "label_ar": "فايلالا",
                "label_fr": "Vailala"
            },
            {
                "value": 78681,
                "label": "Mata-Utu",
                "label_ar": "ماتا أوتو",
                "label_fr": "Mata-Utu"
            }
        ]
    },
    {
        "country": "Albania",
        "cities": [
            {
                "value": 78687,
                "label": "Elbasan",
                "label_ar": "الباسان",
                "label_fr": "Elbasan"
            },
            {
                "value": 78684,
                "label": "Petran",
                "label_ar": "بيتران",
                "label_fr": "Petran"
            },
            {
                "value": 78688,
                "label": "Durrës",
                "label_ar": "دوريس",
                "label_fr": "Durrës"
            },
            {
                "value": 78682,
                "label": "Tirana",
                "label_ar": "تيرانا",
                "label_fr": "Tirana"
            },
            {
                "value": 78689,
                "label": "Vlorë",
                "label_ar": "فلورو",
                "label_fr": "Vlorë"
            },
            {
                "value": 78685,
                "label": "Ura Vajgurore",
                "label_ar": "أورا فاجورور",
                "label_fr": "Ura Vajgurore"
            },
            {
                "value": 78686,
                "label": "Shkoder",
                "label_ar": "شكودر",
                "label_fr": "Shkoder"
            },
            {
                "value": 78683,
                "label": "Pogradec",
                "label_ar": "بوغراديك",
                "label_fr": "Pogradec"
            }
        ]
    },
    {
        "country": "Uzbekistan",
        "cities": [
            {
                "value": 78699,
                "label": "Chartak",
                "label_ar": "تشارتاك",
                "label_fr": "Chartak"
            },
            {
                "value": 78700,
                "label": "Chimboy",
                "label_ar": "شيمبوي",
                "label_fr": "Chimboy"
            },
            {
                "value": 78755,
                "label": "Yangiobod",
                "label_ar": "يانجوبود",
                "label_fr": "Yangiobod"
            },
            {
                "value": 78748,
                "label": "Uchquduq",
                "label_ar": "أوشقدوق",
                "label_fr": "Uchquduq"
            },
            {
                "value": 78706,
                "label": "G'uzor",
                "label_ar": "جزور",
                "label_fr": "G'uzor"
            },
            {
                "value": 78744,
                "label": "Tomdibuloq",
                "label_ar": "تومديبولوق",
                "label_fr": "Tomdibuloq"
            },
            {
                "value": 78727,
                "label": "Piskent",
                "label_ar": "بيسكنت",
                "label_fr": "Piskent"
            },
            {
                "value": 78754,
                "label": "Xo'jayli",
                "label_ar": "Xo'jayli",
                "label_fr": "Xo'jayli"
            },
            {
                "value": 78722,
                "label": "Nukus",
                "label_ar": "نوكوس",
                "label_fr": "Nukus"
            },
            {
                "value": 78737,
                "label": "Shakhrihon",
                "label_ar": "شخريهون",
                "label_fr": "Shakhrihon"
            },
            {
                "value": 78701,
                "label": "Chirchiq",
                "label_ar": "تشيرشيك",
                "label_fr": "Chirchiq"
            },
            {
                "value": 78718,
                "label": "Margilan",
                "label_ar": "مارجيلان",
                "label_fr": "Margilan"
            },
            {
                "value": 78728,
                "label": "Qarshi",
                "label_ar": "قرشي",
                "label_fr": "Qarshi"
            },
            {
                "value": 78746,
                "label": "Turtkul",
                "label_ar": "ترتكل",
                "label_fr": "Turtkul"
            },
            {
                "value": 78756,
                "label": "Yangiyer",
                "label_ar": "يانجيير",
                "label_fr": "Yangiyer"
            },
            {
                "value": 78743,
                "label": "Termez",
                "label_ar": "ترميز",
                "label_fr": "Termez"
            },
            {
                "value": 78721,
                "label": "Navoiy",
                "label_ar": "Navoiy",
                "label_fr": "Navoiy"
            },
            {
                "value": 78751,
                "label": "Vobkent",
                "label_ar": "فوبكنت",
                "label_fr": "Vobkent"
            },
            {
                "value": 78710,
                "label": "Kattaqo'rg'on",
                "label_ar": "كاتقو أرجون",
                "label_fr": "Kattaqo'rg'on"
            },
            {
                "value": 78691,
                "label": "Akkula",
                "label_ar": "اكولا",
                "label_fr": "Akkula"
            },
            {
                "value": 78696,
                "label": "Bekobod",
                "label_ar": "بيكوبود",
                "label_fr": "Bekobod"
            },
            {
                "value": 78716,
                "label": "Kosonsoy",
                "label_ar": "كوسونسي",
                "label_fr": "Kosonsoy"
            },
            {
                "value": 78753,
                "label": "Xonobod",
                "label_ar": "Xonobod",
                "label_fr": "Xonobod"
            },
            {
                "value": 78745,
                "label": "To'ytepa",
                "label_ar": "To'ytepa",
                "label_fr": "To'ytepa"
            },
            {
                "value": 78695,
                "label": "Baxt",
                "label_ar": "باكست",
                "label_fr": "Baxt"
            },
            {
                "value": 78750,
                "label": "Urgut",
                "label_ar": "أورغوت",
                "label_fr": "Urgut"
            },
            {
                "value": 78747,
                "label": "Uchqo'rg'on",
                "label_ar": "Uchqo'rg'on",
                "label_fr": "Uchqo'rg'on"
            },
            {
                "value": 78704,
                "label": "Fergana",
                "label_ar": "فرغانة",
                "label_fr": "Fergana"
            },
            {
                "value": 78752,
                "label": "Xalqobod",
                "label_ar": "خالقوبود",
                "label_fr": "Xalqobod"
            },
            {
                "value": 78725,
                "label": "Olmaliq",
                "label_ar": "أولماليق",
                "label_fr": "Olmaliq"
            },
            {
                "value": 78733,
                "label": "Quvasoy",
                "label_ar": "كفاسوي",
                "label_fr": "Quvasoy"
            },
            {
                "value": 78729,
                "label": "Qorako'l",
                "label_ar": "قوراكول",
                "label_fr": "Qorako'l"
            },
            {
                "value": 78742,
                "label": "Taxiatosh",
                "label_ar": "تاكسياتوش",
                "label_fr": "Taxiatosh"
            },
            {
                "value": 78715,
                "label": "Kokand",
                "label_ar": "قوقند",
                "label_fr": "Kokand"
            },
            {
                "value": 78720,
                "label": "Namangan",
                "label_ar": "نامانجان",
                "label_fr": "Namangan"
            },
            {
                "value": 78724,
                "label": "Okhangaron",
                "label_ar": "Okhangaron",
                "label_fr": "Okhangaron"
            },
            {
                "value": 78758,
                "label": "Zarafshon",
                "label_ar": "زرافشون",
                "label_fr": "Zarafshon"
            },
            {
                "value": 78717,
                "label": "Kungrad",
                "label_ar": "كونغراد",
                "label_fr": "Kungrad"
            },
            {
                "value": 78734,
                "label": "Rishdan",
                "label_ar": "رشدان",
                "label_fr": "Rishdan"
            },
            {
                "value": 78714,
                "label": "Kogon",
                "label_ar": "كوجون",
                "label_fr": "Kogon"
            },
            {
                "value": 78738,
                "label": "Shirabad",
                "label_ar": "شراباد",
                "label_fr": "Shirabad"
            },
            {
                "value": 78708,
                "label": "Jizzax",
                "label_ar": "جيزاكس",
                "label_fr": "Jizzax"
            },
            {
                "value": 78719,
                "label": "Mo'ynoq",
                "label_ar": "معينوق",
                "label_fr": "Mo'ynoq"
            },
            {
                "value": 78723,
                "label": "Nurota",
                "label_ar": "نوروتا",
                "label_fr": "Nurota"
            },
            {
                "value": 78735,
                "label": "Samarqand",
                "label_ar": "سمرقند",
                "label_fr": "Samarqand"
            },
            {
                "value": 78749,
                "label": "Urganch",
                "label_ar": "أورغانش",
                "label_fr": "Urganch"
            },
            {
                "value": 78693,
                "label": "Angren",
                "label_ar": "أنجرين",
                "label_fr": "Angren"
            },
            {
                "value": 78702,
                "label": "Dashtobod",
                "label_ar": "داشتوبود",
                "label_fr": "Dashtobod"
            },
            {
                "value": 78711,
                "label": "Kegeyli",
                "label_ar": "كيجيلي",
                "label_fr": "Kegeyli"
            },
            {
                "value": 78692,
                "label": "Andijon",
                "label_ar": "أنديجون",
                "label_fr": "Andijon"
            },
            {
                "value": 78741,
                "label": "Tashkent",
                "label_ar": "طشقند",
                "label_fr": "Tachkent"
            },
            {
                "value": 78731,
                "label": "Qozoonketkan",
                "label_ar": "Qozoonketkan",
                "label_fr": "Qozoonketkan"
            },
            {
                "value": 78707,
                "label": "Guliston",
                "label_ar": "جوليستون",
                "label_fr": "Guliston"
            },
            {
                "value": 78698,
                "label": "Bukhara",
                "label_ar": "بخارى",
                "label_fr": "Boukhara"
            },
            {
                "value": 78694,
                "label": "Asaka",
                "label_ar": "أساكا",
                "label_fr": "Asaka"
            },
            {
                "value": 78709,
                "label": "Juma",
                "label_ar": "جمعة",
                "label_fr": "Juma"
            },
            {
                "value": 78757,
                "label": "Yangiyo'l",
                "label_ar": "Yangiyo'l",
                "label_fr": "Yangiyo'l"
            },
            {
                "value": 78732,
                "label": "Quva",
                "label_ar": "كوفا",
                "label_fr": "Quva"
            },
            {
                "value": 78736,
                "label": "Shahrisabz",
                "label_ar": "شهريسابز",
                "label_fr": "Shahrisabz"
            },
            {
                "value": 78697,
                "label": "Beruniy",
                "label_ar": "بيروني",
                "label_fr": "Beruniy"
            },
            {
                "value": 78703,
                "label": "Denov",
                "label_ar": "دينوف",
                "label_fr": "Denov"
            },
            {
                "value": 78730,
                "label": "Qorasuv",
                "label_ar": "قوراصوف",
                "label_fr": "Qorasuv"
            },
            {
                "value": 78713,
                "label": "Khiva",
                "label_ar": "خيوة",
                "label_fr": "Khiva"
            },
            {
                "value": 78712,
                "label": "Khakkulabad",
                "label_ar": "خاكول أباد",
                "label_fr": "Khakkulabad"
            },
            {
                "value": 78739,
                "label": "Shirin",
                "label_ar": "شيرين",
                "label_fr": "Shirin"
            },
            {
                "value": 78690,
                "label": "Afrasiab",
                "label_ar": "أفراسياب",
                "label_fr": "Afrasiab"
            },
            {
                "value": 78726,
                "label": "Oqtosh",
                "label_ar": "اقطوش",
                "label_fr": "Oqtosh"
            },
            {
                "value": 78705,
                "label": "G'ijduvon",
                "label_ar": "جيجدوفون",
                "label_fr": "G'ijduvon"
            },
            {
                "value": 78740,
                "label": "Sirdaryo",
                "label_ar": "سيرداريو",
                "label_fr": "Sirdaryo"
            }
        ]
    },
    {
        "country": "Montenegro",
        "cities": [
            {
                "value": 78762,
                "label": "Kotor",
                "label_ar": "كوتور",
                "label_fr": "Kotor"
            },
            {
                "value": 78759,
                "label": "Podgorica",
                "label_ar": "بودغوريتشا",
                "label_fr": "Podgorica"
            },
            {
                "value": 78764,
                "label": "Budva",
                "label_ar": "بودفا",
                "label_fr": "Budva"
            },
            {
                "value": 78763,
                "label": "Crna Gora",
                "label_ar": "كرنا غورا",
                "label_fr": "Crna Gora"
            },
            {
                "value": 78761,
                "label": "Igalo",
                "label_ar": "إيغالو",
                "label_fr": "Igalo"
            },
            {
                "value": 78760,
                "label": "Pljevlja",
                "label_ar": "بلييفليا",
                "label_fr": "Pljevlja"
            },
            {
                "value": 78767,
                "label": "Ulcinj",
                "label_ar": "أولسيني",
                "label_fr": "Ulcinj"
            },
            {
                "value": 78765,
                "label": "Stari Bar",
                "label_ar": "ستاري بار",
                "label_fr": "Bar Stari"
            },
            {
                "value": 78768,
                "label": "Bečići",
                "label_ar": "Bečići",
                "label_fr": "Bečići"
            },
            {
                "value": 78766,
                "label": "Herceg Novi",
                "label_ar": "هرسك نوفي",
                "label_fr": "Herceg Novi"
            },
            {
                "value": 78769,
                "label": "Niksic",
                "label_ar": "نيكسيك",
                "label_fr": "Niksic"
            }
        ]
    },
    {
        "country": "North Korea",
        "cities": [
            {
                "value": 78770,
                "label": "Pyongyang",
                "label_ar": "بيونغ يانغ",
                "label_fr": "Pyongyang"
            }
        ]
    },
    {
        "country": "Antarctica",
        "cities": [
            {
                "value": 78773,
                "label": "Antarctica",
                "label_ar": "القارة القطبية الجنوبية",
                "label_fr": "Antarctique"
            }
        ]
    },
    {
        "country": "Bermuda",
        "cities": [
            {
                "value": 78775,
                "label": "Saint George",
                "label_ar": "القديس جورج",
                "label_fr": "Saint George"
            },
            {
                "value": 78776,
                "label": "Somerset",
                "label_ar": "سومرست",
                "label_fr": "Somerset"
            },
            {
                "value": 78774,
                "label": "Hamilton",
                "label_ar": "هاملتون",
                "label_fr": "Hamilton"
            }
        ]
    },
    {
        "country": "Curaçao",
        "cities": [
            {
                "value": 78778,
                "label": "Vredeberg",
                "label_ar": "فريدبيرج",
                "label_fr": "Vredeberg"
            },
            {
                "value": 78779,
                "label": "Emmastad",
                "label_ar": "امستاد",
                "label_fr": "Emmastad"
            },
            {
                "value": 78777,
                "label": "Willemstad",
                "label_ar": "ويلمستاد",
                "label_fr": "Willemstad"
            }
        ]
    },
    {
        "country": "Ecuador",
        "cities": [
            {
                "value": 78843,
                "label": "Quevedo",
                "label_ar": "كيفيدو",
                "label_fr": "Quevedo"
            },
            {
                "value": 78787,
                "label": "Otavalo",
                "label_ar": "أوتافالو",
                "label_fr": "Otavalo"
            },
            {
                "value": 78848,
                "label": "Hacienda Bolivia",
                "label_ar": "هاسيندا بوليفيا",
                "label_fr": "Hacienda Bolivie"
            },
            {
                "value": 78784,
                "label": "Ibarra",
                "label_ar": "ايبارا",
                "label_fr": "Ibarra"
            },
            {
                "value": 78825,
                "label": "Atacames",
                "label_ar": "أتاكاميس",
                "label_fr": "Atacames"
            },
            {
                "value": 78785,
                "label": "Riobamba",
                "label_ar": "ريوبامبا",
                "label_fr": "Riobamba"
            },
            {
                "value": 78796,
                "label": "Cuenca",
                "label_ar": "كوينكا",
                "label_fr": "Cuenca"
            },
            {
                "value": 78817,
                "label": "General Leonidas Plaza Gutierrez",
                "label_ar": "الجنرال ليونيداس بلازا جوتيريز",
                "label_fr": "Général Leonidas Plaza Gutierrez"
            },
            {
                "value": 78820,
                "label": "Jipijapa",
                "label_ar": "Jipijapa",
                "label_fr": "Jipijapa"
            },
            {
                "value": 78831,
                "label": "Tulcán",
                "label_ar": "تولكان",
                "label_fr": "Tulcán"
            },
            {
                "value": 78833,
                "label": "Daule",
                "label_ar": "دول",
                "label_fr": "Daule"
            },
            {
                "value": 78840,
                "label": "Hacienda Santa Catalina",
                "label_ar": "هاسيندا سانتا كاتالينا",
                "label_fr": "Hacienda Santa Catalina"
            },
            {
                "value": 78841,
                "label": "Zamora",
                "label_ar": "زامورا",
                "label_fr": "Zamora"
            },
            {
                "value": 78815,
                "label": "Zumba",
                "label_ar": "زومبا",
                "label_fr": "Zumba"
            },
            {
                "value": 78804,
                "label": "Durán",
                "label_ar": "دوران",
                "label_fr": "Duran"
            },
            {
                "value": 78802,
                "label": "Huaquillas",
                "label_ar": "هواكيلاس",
                "label_fr": "Huaquillas"
            },
            {
                "value": 78808,
                "label": "Manta",
                "label_ar": "مانتا",
                "label_fr": "Manta"
            },
            {
                "value": 78819,
                "label": "Sucre",
                "label_ar": "سوكريه",
                "label_fr": "Sucre"
            },
            {
                "value": 78835,
                "label": "Playas",
                "label_ar": "بلاياس",
                "label_fr": "Playas"
            },
            {
                "value": 78791,
                "label": "Cayambe",
                "label_ar": "كايامبي",
                "label_fr": "Cayambe"
            },
            {
                "value": 78806,
                "label": "Machala",
                "label_ar": "ماتشالا",
                "label_fr": "Machala"
            },
            {
                "value": 78849,
                "label": "El Naranjal",
                "label_ar": "النارنجال",
                "label_fr": "El Naranjal"
            },
            {
                "value": 78821,
                "label": "Macara",
                "label_ar": "ماكارا",
                "label_fr": "Macara"
            },
            {
                "value": 78792,
                "label": "Tena",
                "label_ar": "تينا",
                "label_fr": "Tena"
            },
            {
                "value": 78828,
                "label": "Guabo",
                "label_ar": "Guabo",
                "label_fr": "Guabo"
            },
            {
                "value": 78834,
                "label": "Pasaje",
                "label_ar": "باساجي",
                "label_fr": "Pasaje"
            },
            {
                "value": 78829,
                "label": "Santa Cruz",
                "label_ar": "سانتا كروز",
                "label_fr": "Santa Cruz"
            },
            {
                "value": 78780,
                "label": "Quito",
                "label_ar": "كيتو",
                "label_fr": "Quito"
            },
            {
                "value": 78805,
                "label": "Florida",
                "label_ar": "فلوريدا",
                "label_fr": "Floride"
            },
            {
                "value": 78812,
                "label": "Macas",
                "label_ar": "ماكاس",
                "label_fr": "Macas"
            },
            {
                "value": 78847,
                "label": "Hacienda Ibarra",
                "label_ar": "هاسيندا ايبارا",
                "label_fr": "Hacienda Ibarra"
            },
            {
                "value": 78807,
                "label": "Loja",
                "label_ar": "لوجا",
                "label_fr": "Loja"
            },
            {
                "value": 78798,
                "label": "Paccha",
                "label_ar": "باتشا",
                "label_fr": "Paccha"
            },
            {
                "value": 78842,
                "label": "Vinces",
                "label_ar": "الخمور",
                "label_fr": "Vinces"
            },
            {
                "value": 78810,
                "label": "Pelileo",
                "label_ar": "بيليليو",
                "label_fr": "Pelileo"
            },
            {
                "value": 78827,
                "label": "San Rafael",
                "label_ar": "سان رافائيل",
                "label_fr": "San Rafael"
            },
            {
                "value": 78836,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 78794,
                "label": "Latacunga",
                "label_ar": "لاتاكونجا",
                "label_fr": "Latacunga"
            },
            {
                "value": 78783,
                "label": "Guayaquil",
                "label_ar": "غواياكيل",
                "label_fr": "Guayaquil"
            },
            {
                "value": 78797,
                "label": "Esmeraldas",
                "label_ar": "إسميرالداس",
                "label_fr": "Esmeraldas"
            },
            {
                "value": 78795,
                "label": "Santo Domingo de los Colorados",
                "label_ar": "سانتو دومينغو دي لوس كولورادوس",
                "label_fr": "Santo Domingo de los Colorados"
            },
            {
                "value": 78793,
                "label": "Tarapoa",
                "label_ar": "Tarapoa",
                "label_fr": "Tarapoa"
            },
            {
                "value": 78789,
                "label": "Tababela",
                "label_ar": "تابابيلا",
                "label_fr": "Tababela"
            },
            {
                "value": 78782,
                "label": "Guayas",
                "label_ar": "غواياس",
                "label_fr": "Guayas"
            },
            {
                "value": 78818,
                "label": "Calderon",
                "label_ar": "كالديرون",
                "label_fr": "Calderon"
            },
            {
                "value": 78801,
                "label": "La Libertad",
                "label_ar": "لا ليبرتاد",
                "label_fr": "La Libertad"
            },
            {
                "value": 78814,
                "label": "Puyo",
                "label_ar": "بويو",
                "label_fr": "Puyo"
            },
            {
                "value": 78800,
                "label": "El Carmen",
                "label_ar": "الكارمن",
                "label_fr": "El Carmen"
            },
            {
                "value": 78790,
                "label": "Guayllabamba",
                "label_ar": "جويلابامبا",
                "label_fr": "Guayllabamba"
            },
            {
                "value": 78823,
                "label": "La Esperanza",
                "label_ar": "لا اسبيرانزا",
                "label_fr": "La Esperanza"
            },
            {
                "value": 78799,
                "label": "Zaruma",
                "label_ar": "زاروما",
                "label_fr": "Zaruma"
            },
            {
                "value": 78803,
                "label": "Olmedo",
                "label_ar": "أولميدو",
                "label_fr": "Olmedo"
            },
            {
                "value": 78838,
                "label": "Loreto",
                "label_ar": "لوريتو",
                "label_fr": "Loreto"
            },
            {
                "value": 78809,
                "label": "Nabon",
                "label_ar": "نابون",
                "label_fr": "Nabon"
            },
            {
                "value": 78832,
                "label": "Atuntaqui",
                "label_ar": "أتونتاكي",
                "label_fr": "Atuntaqui"
            },
            {
                "value": 78850,
                "label": "Banos",
                "label_ar": "بانوس",
                "label_fr": "Banos"
            },
            {
                "value": 78786,
                "label": "Ambato",
                "label_ar": "أمباتو",
                "label_fr": "Ambato"
            },
            {
                "value": 78822,
                "label": "Santa Elena",
                "label_ar": "سانتا ايلينا",
                "label_fr": "Santa Elena"
            },
            {
                "value": 78811,
                "label": "Gualaceo",
                "label_ar": "جوالاسيو",
                "label_fr": "Gualaceo"
            },
            {
                "value": 78844,
                "label": "Hacienda Duran",
                "label_ar": "هاسيندا دوران",
                "label_fr": "Hacienda Duran"
            },
            {
                "value": 78845,
                "label": "Bahia de Caraquez",
                "label_ar": "باهيا دي كاراكيز",
                "label_fr": "Bahia de Caraquez"
            },
            {
                "value": 78826,
                "label": "La Troncal",
                "label_ar": "لا ترونكال",
                "label_fr": "La Troncal"
            },
            {
                "value": 78830,
                "label": "Milagro",
                "label_ar": "ميلاجرو",
                "label_fr": "Milagro"
            },
            {
                "value": 78846,
                "label": "Salinas",
                "label_ar": "ساليناس",
                "label_fr": "Salinas"
            },
            {
                "value": 78837,
                "label": "Puerto Francisco de Orellana",
                "label_ar": "بويرتو فرانسيسكو دي أوريانا",
                "label_fr": "Puerto Francisco de Orellana"
            },
            {
                "value": 78781,
                "label": "Pichincha",
                "label_ar": "بيشينشا",
                "label_fr": "Pichincha"
            },
            {
                "value": 78839,
                "label": "Montecristi",
                "label_ar": "مونتكريستي",
                "label_fr": "Montecristi"
            },
            {
                "value": 78824,
                "label": "Machachi",
                "label_ar": "مشاتشي",
                "label_fr": "Machachi"
            },
            {
                "value": 78788,
                "label": "Guamote",
                "label_ar": "غواموت",
                "label_fr": "Guamote"
            },
            {
                "value": 78813,
                "label": "Samborondon",
                "label_ar": "سامبوروندون",
                "label_fr": "Samborondon"
            },
            {
                "value": 78816,
                "label": "Portoviejo",
                "label_ar": "بورتوفيجو",
                "label_fr": "Portoviejo"
            }
        ]
    },
    {
        "country": "South Africa",
        "cities": [
            {
                "value": 79067,
                "label": "Claremont",
                "label_ar": "كليرمونت",
                "label_fr": "Claremont"
            },
            {
                "value": 78981,
                "label": "Rooigrond",
                "label_ar": "رويجروند",
                "label_fr": "Rooigrond"
            },
            {
                "value": 79057,
                "label": "Vredenburg",
                "label_ar": "فريدينبورغ",
                "label_fr": "Vredenburg"
            },
            {
                "value": 78969,
                "label": "Kingsmead",
                "label_ar": "Kingsmead",
                "label_fr": "Kingsmead"
            },
            {
                "value": 79087,
                "label": "Mmabatho",
                "label_ar": "ماباثو",
                "label_fr": "Mmabatho"
            },
            {
                "value": 79112,
                "label": "Belhar",
                "label_ar": "بلهار",
                "label_fr": "Belhar"
            },
            {
                "value": 78967,
                "label": "Potchefstroom",
                "label_ar": "بوتشيفستروم",
                "label_fr": "Potchefstroom"
            },
            {
                "value": 79086,
                "label": "Westville",
                "label_ar": "ويستفيل",
                "label_fr": "Westville"
            },
            {
                "value": 78956,
                "label": "Elarduspark",
                "label_ar": "Elarduspark",
                "label_fr": "Elarduspark"
            },
            {
                "value": 78942,
                "label": "Upington",
                "label_ar": "أبينجتون",
                "label_fr": "Upington"
            },
            {
                "value": 78889,
                "label": "Krugersdorp",
                "label_ar": "كروغرسدورب",
                "label_fr": "Krugersdorp"
            },
            {
                "value": 79085,
                "label": "Bergvliet",
                "label_ar": "بيرجفليت",
                "label_fr": "Bergvliet"
            },
            {
                "value": 79080,
                "label": "Zeerust",
                "label_ar": "زيروست",
                "label_fr": "Zeerust"
            },
            {
                "value": 79061,
                "label": "Three Rivers",
                "label_ar": "ثلاث أنهار",
                "label_fr": "Trois fleuves"
            },
            {
                "value": 79116,
                "label": "Alice",
                "label_ar": "أليس",
                "label_fr": "Alice"
            },
            {
                "value": 79133,
                "label": "Waverley",
                "label_ar": "ويفرلي",
                "label_fr": "Waverley"
            },
            {
                "value": 79156,
                "label": "Primrose",
                "label_ar": "روزماري",
                "label_fr": "Primevère"
            },
            {
                "value": 79077,
                "label": "Henley on Klip",
                "label_ar": "Henley on Klip",
                "label_fr": "Henley sur Klip"
            },
            {
                "value": 79017,
                "label": "Lutzville",
                "label_ar": "لوتزفيل",
                "label_fr": "Lutzville"
            },
            {
                "value": 79149,
                "label": "Clanwilliam",
                "label_ar": "كلانويليام",
                "label_fr": "Clanwilliam"
            },
            {
                "value": 79171,
                "label": "Eersterivier",
                "label_ar": "إيرستريفير",
                "label_fr": "Eersterivier"
            },
            {
                "value": 79011,
                "label": "Welkom",
                "label_ar": "ويلكوم",
                "label_fr": "Welkom"
            },
            {
                "value": 79054,
                "label": "City of Cape Town",
                "label_ar": "مدينة كيب تاون",
                "label_fr": "Ville du Cap"
            },
            {
                "value": 78913,
                "label": "Florida Glen",
                "label_ar": "فلوريدا جلين",
                "label_fr": "Florida Glen"
            },
            {
                "value": 79006,
                "label": "Worcester",
                "label_ar": "ورسستر",
                "label_fr": "Worcester"
            },
            {
                "value": 79016,
                "label": "Riebeek-Kasteel",
                "label_ar": "ريبيك كاستيل",
                "label_fr": "Riebeek-Kasteel"
            },
            {
                "value": 78859,
                "label": "Port Elizabeth",
                "label_ar": "ميناء اليزابيث",
                "label_fr": "Port Elizabeth"
            },
            {
                "value": 78851,
                "label": "Johannesburg",
                "label_ar": "جوهانسبرج",
                "label_fr": "Johannesburg"
            },
            {
                "value": 78852,
                "label": "Sandton",
                "label_ar": "ساندتون",
                "label_fr": "Sandton"
            },
            {
                "value": 78865,
                "label": "Newcastle",
                "label_ar": "نيوكاسل",
                "label_fr": "Newcastle"
            },
            {
                "value": 78992,
                "label": "Newlands",
                "label_ar": "اراضي جديدة",
                "label_fr": "Nouveaux territoires"
            },
            {
                "value": 78930,
                "label": "Fochville",
                "label_ar": "فوشفيل",
                "label_fr": "Fochville"
            },
            {
                "value": 78855,
                "label": "Malmesbury",
                "label_ar": "مالميسبري",
                "label_fr": "Malmesbury"
            },
            {
                "value": 78978,
                "label": "Rawsonville",
                "label_ar": "روسونفيل",
                "label_fr": "Rawsonville"
            },
            {
                "value": 78943,
                "label": "Dube Location",
                "label_ar": "موقع دوبي",
                "label_fr": "Emplacement du Dube"
            },
            {
                "value": 78929,
                "label": "Tokai",
                "label_ar": "توكاي",
                "label_fr": "Tokai"
            },
            {
                "value": 78951,
                "label": "Brackenfell",
                "label_ar": "براكنفيل",
                "label_fr": "Brackenfell"
            },
            {
                "value": 78882,
                "label": "Port Alfred",
                "label_ar": "بورت ألفريد",
                "label_fr": "Port Alfred"
            },
            {
                "value": 79103,
                "label": "Wynberg",
                "label_ar": "وينبرغ",
                "label_fr": "Wynberg"
            },
            {
                "value": 78962,
                "label": "Bedfordview",
                "label_ar": "بيدفوردفيو",
                "label_fr": "Bedfordview"
            },
            {
                "value": 79005,
                "label": "KwaDukuza",
                "label_ar": "كوادوكوزا",
                "label_fr": "KwaDukuza"
            },
            {
                "value": 79094,
                "label": "Grabouw",
                "label_ar": "جرابو",
                "label_fr": "Grabouw"
            },
            {
                "value": 79022,
                "label": "Bredasdorp",
                "label_ar": "بريداسدورب",
                "label_fr": "Bredasdorp"
            },
            {
                "value": 79107,
                "label": "Roggebaai",
                "label_ar": "Roggebaai",
                "label_fr": "Roggebaai"
            },
            {
                "value": 79166,
                "label": "Gillitts",
                "label_ar": "جيليتس",
                "label_fr": "Gillitts"
            },
            {
                "value": 79076,
                "label": "Prieska",
                "label_ar": "بريسكا",
                "label_fr": "Prieska"
            },
            {
                "value": 78988,
                "label": "Beaufort West",
                "label_ar": "بوفورت ويست",
                "label_fr": "Beaufort Ouest"
            },
            {
                "value": 78923,
                "label": "Amanzimtoti",
                "label_ar": "أمانزيمتوتي",
                "label_fr": "Amanzimtoti"
            },
            {
                "value": 79090,
                "label": "Wilderness",
                "label_ar": "البرية",
                "label_fr": "Région sauvage"
            },
            {
                "value": 79043,
                "label": "Onverwacht",
                "label_ar": "أونفيرواخت",
                "label_fr": "Onverwacht"
            },
            {
                "value": 78949,
                "label": "Middelburg",
                "label_ar": "ميدلبورغ",
                "label_fr": "Middelburg"
            },
            {
                "value": 79055,
                "label": "Buffalo",
                "label_ar": "الجاموس",
                "label_fr": "Buffle"
            },
            {
                "value": 78887,
                "label": "Kloof",
                "label_ar": "كلوف",
                "label_fr": "Kloof"
            },
            {
                "value": 79140,
                "label": "Vosloorus",
                "label_ar": "فوسلوروس",
                "label_fr": "Vosloorus"
            },
            {
                "value": 79163,
                "label": "Fordsburg",
                "label_ar": "فوردسبورج",
                "label_fr": "Fordsburg"
            },
            {
                "value": 79098,
                "label": "Grahamstown",
                "label_ar": "جراهامستاون",
                "label_fr": "Grahamstown"
            },
            {
                "value": 79160,
                "label": "Gingindlovu",
                "label_ar": "جينيندلوفو",
                "label_fr": "Gingindlovu"
            },
            {
                "value": 79136,
                "label": "Capital Park",
                "label_ar": "كابيتال بارك",
                "label_fr": "Parc de la capitale"
            },
            {
                "value": 79178,
                "label": "Carolina",
                "label_ar": "كارولينا",
                "label_fr": "Caroline"
            },
            {
                "value": 78987,
                "label": "Waverley",
                "label_ar": "ويفرلي",
                "label_fr": "Waverley"
            },
            {
                "value": 78946,
                "label": "Kingsley",
                "label_ar": "كينجسلي",
                "label_fr": "Kingsley"
            },
            {
                "value": 78995,
                "label": "Lynnwood",
                "label_ar": "لينوود",
                "label_fr": "Lynnwood"
            },
            {
                "value": 79024,
                "label": "New Germany",
                "label_ar": "ألمانيا الجديدة",
                "label_fr": "Nouvelle Allemagne"
            },
            {
                "value": 79073,
                "label": "Kinross",
                "label_ar": "كينروس",
                "label_fr": "Kinross"
            },
            {
                "value": 78974,
                "label": "Hluhluwe",
                "label_ar": "هلوهلوي",
                "label_fr": "Hluhluwe"
            },
            {
                "value": 78941,
                "label": "Louis Trichardt",
                "label_ar": "لويس تريتشاردت",
                "label_fr": "Louis Trichardt"
            },
            {
                "value": 79137,
                "label": "Parktown",
                "label_ar": "باركتاون",
                "label_fr": "Parktown"
            },
            {
                "value": 79062,
                "label": "Hawston",
                "label_ar": "هاوستون",
                "label_fr": "Hawston"
            },
            {
                "value": 79058,
                "label": "Evander",
                "label_ar": "إيفاندر",
                "label_fr": "Evander"
            },
            {
                "value": 79161,
                "label": "Malvern",
                "label_ar": "مالفيرن",
                "label_fr": "Malvern"
            },
            {
                "value": 79142,
                "label": "The Rest",
                "label_ar": "البقية",
                "label_fr": "Le reste"
            },
            {
                "value": 79083,
                "label": "King William's Town",
                "label_ar": "مدينة الملك وليام",
                "label_fr": "La ville du roi Guillaume"
            },
            {
                "value": 78938,
                "label": "Kleinmond",
                "label_ar": "كلاينموند",
                "label_fr": "Kleinmond"
            },
            {
                "value": 79162,
                "label": "Elsburg",
                "label_ar": "إلسبرج",
                "label_fr": "Elsburg"
            },
            {
                "value": 79147,
                "label": "Kalkbaai",
                "label_ar": "كلكباي",
                "label_fr": "Kalkbaai"
            },
            {
                "value": 78925,
                "label": "Witbank",
                "label_ar": "ويتبانك",
                "label_fr": "Witbank"
            },
            {
                "value": 79091,
                "label": "Phokeng",
                "label_ar": "فوكينج",
                "label_fr": "Phokeng"
            },
            {
                "value": 79013,
                "label": "Knysna",
                "label_ar": "كنيسنا",
                "label_fr": "Knysna"
            },
            {
                "value": 79124,
                "label": "Butterworth",
                "label_ar": "بتروورث",
                "label_fr": "Butterworth"
            },
            {
                "value": 79139,
                "label": "Bathurst",
                "label_ar": "باثورست",
                "label_fr": "Bathurst"
            },
            {
                "value": 79066,
                "label": "Gordon's Bay",
                "label_ar": "خليج جوردون",
                "label_fr": "Gordon's Bay"
            },
            {
                "value": 79070,
                "label": "Overport",
                "label_ar": "أوفبورت",
                "label_fr": "Overport"
            },
            {
                "value": 78998,
                "label": "Hilton",
                "label_ar": "هيلتون",
                "label_fr": "Hilton"
            },
            {
                "value": 79040,
                "label": "Umkomaas",
                "label_ar": "Umkomaas",
                "label_fr": "Umkomaas"
            },
            {
                "value": 79159,
                "label": "Edenburg",
                "label_ar": "ايدنبورغ",
                "label_fr": "Edenburg"
            },
            {
                "value": 79110,
                "label": "Erasmia",
                "label_ar": "ايراسميا",
                "label_fr": "Erasmia"
            },
            {
                "value": 79097,
                "label": "Rhodes",
                "label_ar": "رودس",
                "label_fr": "Rhodes"
            },
            {
                "value": 79105,
                "label": "Rosslyn",
                "label_ar": "روسلين",
                "label_fr": "Rosslyn"
            },
            {
                "value": 79176,
                "label": "Umbogintwini",
                "label_ar": "أومبوجينتويني",
                "label_fr": "Umbogintwini"
            },
            {
                "value": 78939,
                "label": "Mooi River",
                "label_ar": "نهر موي",
                "label_fr": "Rivière Mooi"
            },
            {
                "value": 79134,
                "label": "Mobeni",
                "label_ar": "موبيني",
                "label_fr": "Mobeni"
            },
            {
                "value": 79082,
                "label": "Harrismith",
                "label_ar": "هاريسميث",
                "label_fr": "Harrismith"
            },
            {
                "value": 79034,
                "label": "Dullstroom",
                "label_ar": "دولستروم",
                "label_fr": "Dullstroom"
            },
            {
                "value": 79167,
                "label": "Messina",
                "label_ar": "ميسينا",
                "label_fr": "Messine"
            },
            {
                "value": 79071,
                "label": "Milnerton",
                "label_ar": "ميلنرتون",
                "label_fr": "Milnerton"
            },
            {
                "value": 79014,
                "label": "Ixopo",
                "label_ar": "إكسوبو",
                "label_fr": "Ixopo"
            },
            {
                "value": 78936,
                "label": "Uitenhage",
                "label_ar": "Uitenhage",
                "label_fr": "Uitenhage"
            },
            {
                "value": 78866,
                "label": "Cape Town",
                "label_ar": "كيب تاون",
                "label_fr": "Le Cap"
            },
            {
                "value": 79030,
                "label": "Maidstone",
                "label_ar": "ميدستون",
                "label_fr": "Maidstone"
            },
            {
                "value": 78915,
                "label": "Olifantsfontein",
                "label_ar": "أوليفانتسفونتين",
                "label_fr": "Olifantsfontein"
            },
            {
                "value": 79153,
                "label": "Kokstad",
                "label_ar": "كوكستاد",
                "label_fr": "Kokstad"
            },
            {
                "value": 78903,
                "label": "Brits",
                "label_ar": "بريتس",
                "label_fr": "Britanniques"
            },
            {
                "value": 79068,
                "label": "Hennenman",
                "label_ar": "هينينمان",
                "label_fr": "Hennenman"
            },
            {
                "value": 78899,
                "label": "Roodepoort",
                "label_ar": "رودبورت",
                "label_fr": "Roodepoort"
            },
            {
                "value": 78856,
                "label": "Kempton Park",
                "label_ar": "كمبتون بارك",
                "label_fr": "Parc Kempton"
            },
            {
                "value": 79037,
                "label": "Senekal",
                "label_ar": "سنكال",
                "label_fr": "Senekal"
            },
            {
                "value": 78907,
                "label": "Vanderbijlpark",
                "label_ar": "فاندربيجلبارك",
                "label_fr": "Vanderbijlpark"
            },
            {
                "value": 79148,
                "label": "Craighall",
                "label_ar": "كريغال",
                "label_fr": "Craighall"
            },
            {
                "value": 78973,
                "label": "Bethlehem",
                "label_ar": "بيت لحم",
                "label_fr": "Bethléem"
            },
            {
                "value": 78874,
                "label": "Wellington",
                "label_ar": "ويلينجتون",
                "label_fr": "Wellington"
            },
            {
                "value": 78991,
                "label": "Duiwelskloof",
                "label_ar": "دويويلسكلوف",
                "label_fr": "Duiwelskloof"
            },
            {
                "value": 79165,
                "label": "Saint Helena Bay",
                "label_ar": "خليج سانت هيلانة",
                "label_fr": "Baie de Sainte-Hélène"
            },
            {
                "value": 79122,
                "label": "Maraisburg",
                "label_ar": "ماريسبورغ",
                "label_fr": "Maraisburg"
            },
            {
                "value": 79072,
                "label": "Deneysville",
                "label_ar": "دينيسفيل",
                "label_fr": "Deneysville"
            },
            {
                "value": 79031,
                "label": "Hillcrest",
                "label_ar": "هيلكريست",
                "label_fr": "Hillcrest"
            },
            {
                "value": 79021,
                "label": "Odendaalsrus",
                "label_ar": "Odendaalsrus",
                "label_fr": "Odendaalsrus"
            },
            {
                "value": 79092,
                "label": "Haenertsburg",
                "label_ar": "هاينرتسبورج",
                "label_fr": "Haenertsburg"
            },
            {
                "value": 79027,
                "label": "Constantia",
                "label_ar": "كونستانتيا",
                "label_fr": "Constantia"
            },
            {
                "value": 78914,
                "label": "Alberton",
                "label_ar": "ألبرتون",
                "label_fr": "Alberton"
            },
            {
                "value": 79047,
                "label": "Empangeni",
                "label_ar": "إمبانجيني",
                "label_fr": "Empangeni"
            },
            {
                "value": 79046,
                "label": "Meadowridge",
                "label_ar": "ميدوريدج",
                "label_fr": "Meadowridge"
            },
            {
                "value": 79121,
                "label": "Elandsfontein",
                "label_ar": "ايلاندفونتين",
                "label_fr": "Elandsfontein"
            },
            {
                "value": 79032,
                "label": "Montagu",
                "label_ar": "مونتاجو",
                "label_fr": "Montagu"
            },
            {
                "value": 79064,
                "label": "Houghton Estate",
                "label_ar": "هوتون العقارية",
                "label_fr": "Domaine Houghton"
            },
            {
                "value": 78984,
                "label": "Bryanston",
                "label_ar": "بريانستون",
                "label_fr": "Bryanston"
            },
            {
                "value": 78896,
                "label": "Lephalale",
                "label_ar": "ليفالالي",
                "label_fr": "Lephalale"
            },
            {
                "value": 79050,
                "label": "Phoenix",
                "label_ar": "فينيكس",
                "label_fr": "Phénix"
            },
            {
                "value": 78955,
                "label": "Oranjezicht",
                "label_ar": "Oranjezicht",
                "label_fr": "Oranjezicht"
            },
            {
                "value": 78881,
                "label": "Kraaifontein",
                "label_ar": "كرايفونتين",
                "label_fr": "Kraaifontein"
            },
            {
                "value": 79095,
                "label": "Graaff Reinet",
                "label_ar": "غراف رينيت",
                "label_fr": "Graaff Reinet"
            },
            {
                "value": 79088,
                "label": "Goodwood",
                "label_ar": "خشب جيد",
                "label_fr": "Bon bois"
            },
            {
                "value": 79019,
                "label": "Richmond",
                "label_ar": "ريتشموند",
                "label_fr": "Richmond"
            },
            {
                "value": 79130,
                "label": "Peninsula",
                "label_ar": "شبه جزيرة",
                "label_fr": "Péninsule"
            },
            {
                "value": 79044,
                "label": "Kriel",
                "label_ar": "كرييل",
                "label_fr": "Kriel"
            },
            {
                "value": 78986,
                "label": "Coega",
                "label_ar": "كويغا",
                "label_fr": "Coega"
            },
            {
                "value": 78863,
                "label": "Benoni",
                "label_ar": "بينوني",
                "label_fr": "Benoni"
            },
            {
                "value": 78947,
                "label": "Durbanville",
                "label_ar": "دوربانفيل",
                "label_fr": "Durbanville"
            },
            {
                "value": 78886,
                "label": "Margate",
                "label_ar": "مارجيت",
                "label_fr": "Margate"
            },
            {
                "value": 78875,
                "label": "Winterton",
                "label_ar": "وينترتون",
                "label_fr": "Winterton"
            },
            {
                "value": 78853,
                "label": "Midrand",
                "label_ar": "ميدراند",
                "label_fr": "Midrand"
            },
            {
                "value": 78880,
                "label": "Sun Valley",
                "label_ar": "شمس، واد",
                "label_fr": "Sun Valley"
            },
            {
                "value": 79056,
                "label": "Merrivale",
                "label_ar": "ميريفال",
                "label_fr": "Merrivale"
            },
            {
                "value": 78983,
                "label": "Hartswater",
                "label_ar": "هارتسووتر",
                "label_fr": "Hartswater"
            },
            {
                "value": 79119,
                "label": "Monument Park",
                "label_ar": "حديقة النصب",
                "label_fr": "Monument Park"
            },
            {
                "value": 79145,
                "label": "Mokopane",
                "label_ar": "موكوبان",
                "label_fr": "Mokopane"
            },
            {
                "value": 79096,
                "label": "Mount Edgecombe",
                "label_ar": "جبل Edgecombe",
                "label_fr": "Mont Edgecombe"
            },
            {
                "value": 79109,
                "label": "Ashton",
                "label_ar": "أشتون",
                "label_fr": "Ashton"
            },
            {
                "value": 79108,
                "label": "Ganspan",
                "label_ar": "جانسبان",
                "label_fr": "Ganspan"
            },
            {
                "value": 78999,
                "label": "Hout Bay",
                "label_ar": "هوت باي",
                "label_fr": "Hout Bay"
            },
            {
                "value": 79018,
                "label": "Mafikeng",
                "label_ar": "مافيكينج",
                "label_fr": "Mafikeng"
            },
            {
                "value": 79079,
                "label": "Wartburg",
                "label_ar": "وارتبرج",
                "label_fr": "Wartburg"
            },
            {
                "value": 78982,
                "label": "Stilfontein",
                "label_ar": "Stilfontein",
                "label_fr": "Stilfontein"
            },
            {
                "value": 79126,
                "label": "Medunsa",
                "label_ar": "ميدونسا",
                "label_fr": "Medunsa"
            },
            {
                "value": 78910,
                "label": "Sasolburg",
                "label_ar": "ساسولبورغ",
                "label_fr": "Sasolburg"
            },
            {
                "value": 79173,
                "label": "Laudium",
                "label_ar": "لوديوم",
                "label_fr": "Laudium"
            },
            {
                "value": 79168,
                "label": "Weltevreden",
                "label_ar": "ويلتفريدن",
                "label_fr": "Weltevreden"
            },
            {
                "value": 78997,
                "label": "Northcliff",
                "label_ar": "نورثكليف",
                "label_fr": "Northcliff"
            },
            {
                "value": 78924,
                "label": "Lyttelton",
                "label_ar": "ليتيلتون",
                "label_fr": "Lyttelton"
            },
            {
                "value": 78905,
                "label": "Springs",
                "label_ar": "الينابيع",
                "label_fr": "Ressorts"
            },
            {
                "value": 78945,
                "label": "Walmer Heights",
                "label_ar": "وولمر هايتس",
                "label_fr": "Walmer Heights"
            },
            {
                "value": 79172,
                "label": "Sabie",
                "label_ar": "سابي",
                "label_fr": "Sabie"
            },
            {
                "value": 79026,
                "label": "Ladybrand",
                "label_ar": "ليديبراند",
                "label_fr": "Ladybrand"
            },
            {
                "value": 79029,
                "label": "Lichtenburg",
                "label_ar": "ليشتنبرغ",
                "label_fr": "Lichtenburg"
            },
            {
                "value": 78950,
                "label": "Vrededorp",
                "label_ar": "فريدورب",
                "label_fr": "Vrededorp"
            },
            {
                "value": 79007,
                "label": "Shelly Beach",
                "label_ar": "شاطئ شيلي",
                "label_fr": "Plage de Shelly"
            },
            {
                "value": 78979,
                "label": "Pringle Bay",
                "label_ar": "برينجل باي",
                "label_fr": "Pringle Bay"
            },
            {
                "value": 79132,
                "label": "Doornfontein",
                "label_ar": "دورنفونتين",
                "label_fr": "Doornfontein"
            },
            {
                "value": 78965,
                "label": "Ogies",
                "label_ar": "أوجيس",
                "label_fr": "Ogies"
            },
            {
                "value": 79129,
                "label": "Queenstown",
                "label_ar": "كوينزتاون",
                "label_fr": "Queenstown"
            },
            {
                "value": 78993,
                "label": "Alrode",
                "label_ar": "الرود",
                "label_fr": "Alrode"
            },
            {
                "value": 78977,
                "label": "Oudtshoorn",
                "label_ar": "أوتشورن",
                "label_fr": "Oudtshoorn"
            },
            {
                "value": 79048,
                "label": "Humansdorp",
                "label_ar": "هيومنسدورب",
                "label_fr": "Humansdorp"
            },
            {
                "value": 78927,
                "label": "Nigel",
                "label_ar": "نايجل",
                "label_fr": "Nigel"
            },
            {
                "value": 78864,
                "label": "Centurion",
                "label_ar": "سنتوريون",
                "label_fr": "Centurion"
            },
            {
                "value": 79179,
                "label": "Swartkops",
                "label_ar": "سوارتكوبس",
                "label_fr": "Swartkops"
            },
            {
                "value": 78937,
                "label": "Hermanus",
                "label_ar": "هيرمانوس",
                "label_fr": "Hermanus"
            },
            {
                "value": 79078,
                "label": "Coligny",
                "label_ar": "كوليجني",
                "label_fr": "Coligny"
            },
            {
                "value": 78879,
                "label": "Hartenbos",
                "label_ar": "هارتينبوس",
                "label_fr": "Hartenbos"
            },
            {
                "value": 78884,
                "label": "London",
                "label_ar": "لندن",
                "label_fr": "Londres"
            },
            {
                "value": 79120,
                "label": "Emerald Hill",
                "label_ar": "إميرالد هيل",
                "label_fr": "Emerald Hill"
            },
            {
                "value": 79081,
                "label": "Thabazimbi",
                "label_ar": "ثابازيمبي",
                "label_fr": "Thabazimbi"
            },
            {
                "value": 79089,
                "label": "La Lucia",
                "label_ar": "لا لوسيا",
                "label_fr": "La Lucie"
            },
            {
                "value": 79012,
                "label": "De Aar",
                "label_ar": "دي آر",
                "label_fr": "De Aar"
            },
            {
                "value": 79001,
                "label": "Botshabelo",
                "label_ar": "بوتشابيلو",
                "label_fr": "Botshabelo"
            },
            {
                "value": 78901,
                "label": "Edenvale",
                "label_ar": "Edenvale",
                "label_fr": "Edenvale"
            },
            {
                "value": 78919,
                "label": "Lombardy East",
                "label_ar": "شرق لومباردي",
                "label_fr": "Lombardie Est"
            },
            {
                "value": 78891,
                "label": "Pongola",
                "label_ar": "بونجولا",
                "label_fr": "Pongola"
            },
            {
                "value": 78918,
                "label": "Rustenburg",
                "label_ar": "روستنبرج",
                "label_fr": "Rustenburg"
            },
            {
                "value": 79075,
                "label": "Temba",
                "label_ar": "تمبا",
                "label_fr": "Temba"
            },
            {
                "value": 78872,
                "label": "Panorama",
                "label_ar": "بانوراما",
                "label_fr": "Panorama"
            },
            {
                "value": 78867,
                "label": "Stellenbosch",
                "label_ar": "ستيلينبوش",
                "label_fr": "Stellenbosch"
            },
            {
                "value": 78953,
                "label": "Kranskop",
                "label_ar": "كرانسكوب",
                "label_fr": "Kranskop"
            },
            {
                "value": 78897,
                "label": "Groblersdal",
                "label_ar": "Groblersdal",
                "label_fr": "Groblersdal"
            },
            {
                "value": 78902,
                "label": "Carletonville",
                "label_ar": "كارلتونفيل",
                "label_fr": "Carletonville"
            },
            {
                "value": 78961,
                "label": "Cullinan",
                "label_ar": "كولينان",
                "label_fr": "Cullinan"
            },
            {
                "value": 78900,
                "label": "Kimberley",
                "label_ar": "كيمبرلي",
                "label_fr": "Kimberley"
            },
            {
                "value": 79177,
                "label": "Oranjeville",
                "label_ar": "أورانجفيل",
                "label_fr": "Oranjeville"
            },
            {
                "value": 78970,
                "label": "Himeville",
                "label_ar": "هيمفيل",
                "label_fr": "Himeville"
            },
            {
                "value": 78944,
                "label": "Saint James",
                "label_ar": "جيمس قديس",
                "label_fr": "Saint James"
            },
            {
                "value": 78920,
                "label": "Sebokeng",
                "label_ar": "سيبوكينج",
                "label_fr": "Sebokeng"
            },
            {
                "value": 79157,
                "label": "Plumstead",
                "label_ar": "بلومستيد",
                "label_fr": "Plumstead"
            },
            {
                "value": 78869,
                "label": "Gansbaai",
                "label_ar": "غانسباي",
                "label_fr": "Gansbaai"
            },
            {
                "value": 78888,
                "label": "Greytown",
                "label_ar": "Greytown",
                "label_fr": "Greytown"
            },
            {
                "value": 78921,
                "label": "Morningside Manor",
                "label_ar": "مورنينجسايد مانور",
                "label_fr": "Manoir Morningside"
            },
            {
                "value": 79114,
                "label": "Braamfontein",
                "label_ar": "برامفونتين",
                "label_fr": "Braamfontein"
            },
            {
                "value": 79100,
                "label": "Saldanha",
                "label_ar": "سالدانها",
                "label_fr": "Saldanha"
            },
            {
                "value": 78933,
                "label": "Klerksdorp",
                "label_ar": "كليركسدورب",
                "label_fr": "Klerksdorp"
            },
            {
                "value": 79152,
                "label": "Irene",
                "label_ar": "ايرين",
                "label_fr": "Irène"
            },
            {
                "value": 79003,
                "label": "Warner Beach",
                "label_ar": "وارنر بيتش",
                "label_fr": "Plage Warner"
            },
            {
                "value": 79104,
                "label": "Middelburg",
                "label_ar": "ميدلبورغ",
                "label_fr": "Middelburg"
            },
            {
                "value": 79151,
                "label": "Wonderboom",
                "label_ar": "Wonderboom",
                "label_fr": "Wonderboom"
            },
            {
                "value": 78948,
                "label": "Pinetown",
                "label_ar": "بينتاون",
                "label_fr": "Pinetown"
            },
            {
                "value": 79093,
                "label": "Balfour",
                "label_ar": "بلفور",
                "label_fr": "Balfour"
            },
            {
                "value": 79117,
                "label": "Elsenburg",
                "label_ar": "إلسينبورغ",
                "label_fr": "Elsenburg"
            },
            {
                "value": 78871,
                "label": "Paarl",
                "label_ar": "بارل",
                "label_fr": "Paarl"
            },
            {
                "value": 78876,
                "label": "Strand",
                "label_ar": "ساحل",
                "label_fr": "Brin"
            },
            {
                "value": 79009,
                "label": "Rosettenville",
                "label_ar": "روزيتينفيل",
                "label_fr": "Rosettenville"
            },
            {
                "value": 79146,
                "label": "Hyde Park",
                "label_ar": "هايد بارك",
                "label_fr": "Hyde Park"
            },
            {
                "value": 78932,
                "label": "Polokwane",
                "label_ar": "بولوكواني",
                "label_fr": "Polokwane"
            },
            {
                "value": 79164,
                "label": "Three Anchor Bay",
                "label_ar": "ثري أنكور باي",
                "label_fr": "Trois Anchor Bay"
            },
            {
                "value": 78966,
                "label": "Hekpoort",
                "label_ar": "هيكبورت",
                "label_fr": "Hekpoort"
            },
            {
                "value": 79025,
                "label": "Ottosdal",
                "label_ar": "أوتوسدال",
                "label_fr": "Ottosdal"
            },
            {
                "value": 78877,
                "label": "East London",
                "label_ar": "لندن الشرقية",
                "label_fr": "Londres est"
            },
            {
                "value": 79111,
                "label": "Dundee",
                "label_ar": "دندي",
                "label_fr": "Dundee"
            },
            {
                "value": 79004,
                "label": "Stanford",
                "label_ar": "ستانفورد",
                "label_fr": "Stanford"
            },
            {
                "value": 78898,
                "label": "Randburg",
                "label_ar": "راندبورغ",
                "label_fr": "Randburg"
            },
            {
                "value": 79038,
                "label": "Verulam",
                "label_ar": "فيرولام",
                "label_fr": "Verulam"
            },
            {
                "value": 78971,
                "label": "Vryheid",
                "label_ar": "فريهيد",
                "label_fr": "Vryheid"
            },
            {
                "value": 79115,
                "label": "Hogsback",
                "label_ar": "هوجسباك",
                "label_fr": "Hogsback"
            },
            {
                "value": 79051,
                "label": "Atlantis",
                "label_ar": "اتلانتس",
                "label_fr": "Atlantis"
            },
            {
                "value": 78904,
                "label": "Rivonia",
                "label_ar": "ريفونيا",
                "label_fr": "Rivonia"
            },
            {
                "value": 79074,
                "label": "Lydenburg",
                "label_ar": "ليدنبورغ",
                "label_fr": "Lydenburg"
            },
            {
                "value": 78954,
                "label": "Richards Bay",
                "label_ar": "ريتشاردز باي",
                "label_fr": "Richards Bay"
            },
            {
                "value": 79045,
                "label": "Napier",
                "label_ar": "نابير",
                "label_fr": "Napier"
            },
            {
                "value": 79042,
                "label": "Tzaneen",
                "label_ar": "تزانين",
                "label_fr": "Tzaneen"
            },
            {
                "value": 78968,
                "label": "Hoedspruit",
                "label_ar": "هويدسبروت",
                "label_fr": "Hoedspruit"
            },
            {
                "value": 79135,
                "label": "Hatfield",
                "label_ar": "هاتفيلد",
                "label_fr": "Hatfield"
            },
            {
                "value": 78893,
                "label": "Scottburgh",
                "label_ar": "سكوتبرج",
                "label_fr": "Scottburgh"
            },
            {
                "value": 79035,
                "label": "Phalaborwa",
                "label_ar": "فالابوروا",
                "label_fr": "Phalaborwa"
            },
            {
                "value": 79002,
                "label": "Salt Rock",
                "label_ar": "سولت روك",
                "label_fr": "Salt Rock"
            },
            {
                "value": 78890,
                "label": "Westonaria",
                "label_ar": "ويستوناريا",
                "label_fr": "Westonaria"
            },
            {
                "value": 79036,
                "label": "Welkom",
                "label_ar": "ويلكوم",
                "label_fr": "Welkom"
            },
            {
                "value": 78959,
                "label": "Tongaat",
                "label_ar": "تونجات",
                "label_fr": "Tongaat"
            },
            {
                "value": 79169,
                "label": "Vaalbank",
                "label_ar": "فالبانك",
                "label_fr": "Vaalbank"
            },
            {
                "value": 78964,
                "label": "Modderfontein",
                "label_ar": "مودرفونتين",
                "label_fr": "Modderfontein"
            },
            {
                "value": 78862,
                "label": "Delmas",
                "label_ar": "دلماس",
                "label_fr": "Delmas"
            },
            {
                "value": 78996,
                "label": "Tembisa",
                "label_ar": "تمبيسا",
                "label_fr": "Tembisa"
            },
            {
                "value": 79010,
                "label": "Umhlanga",
                "label_ar": "أوملانجا",
                "label_fr": "Umhlanga"
            },
            {
                "value": 78926,
                "label": "Sinoville",
                "label_ar": "سينوفيل",
                "label_fr": "Sinoville"
            },
            {
                "value": 79123,
                "label": "Fort Cox",
                "label_ar": "فورت كوكس",
                "label_fr": "Fort Cox"
            },
            {
                "value": 78922,
                "label": "Bethal",
                "label_ar": "بيتال",
                "label_fr": "Bethal"
            },
            {
                "value": 78860,
                "label": "Meyerton",
                "label_ar": "ميرتون",
                "label_fr": "Meyerton"
            },
            {
                "value": 79170,
                "label": "Machadodorp",
                "label_ar": "ماتشادورب",
                "label_fr": "Machadodorp"
            },
            {
                "value": 78963,
                "label": "Weltevreedenpark",
                "label_ar": "ويلتيفريدينبارك",
                "label_fr": "Weltevreedenpark"
            },
            {
                "value": 78894,
                "label": "Port Shepstone",
                "label_ar": "ميناء شيبستون",
                "label_fr": "Port Shepstone"
            },
            {
                "value": 79052,
                "label": "Mossel Bay",
                "label_ar": "موسيل باي",
                "label_fr": "Mossel Bay"
            },
            {
                "value": 79150,
                "label": "Brooklyn",
                "label_ar": "بروكلين",
                "label_fr": "Brooklyn"
            },
            {
                "value": 78916,
                "label": "Walkerville",
                "label_ar": "والكرفيل",
                "label_fr": "Walkerville"
            },
            {
                "value": 78934,
                "label": "Nelspruit",
                "label_ar": "نيلسبروت",
                "label_fr": "Nelspruit"
            },
            {
                "value": 79102,
                "label": "Rooihuiskraal",
                "label_ar": "Rooihuiskraal",
                "label_fr": "Rooihuiskraal"
            },
            {
                "value": 79000,
                "label": "Queensburgh",
                "label_ar": "كوينزبورغ",
                "label_fr": "Queensburgh"
            },
            {
                "value": 78858,
                "label": "Durban",
                "label_ar": "ديربان",
                "label_fr": "Durban"
            },
            {
                "value": 78912,
                "label": "Isando",
                "label_ar": "ايساندو",
                "label_fr": "Isando"
            },
            {
                "value": 79144,
                "label": "Queenswood",
                "label_ar": "كوينزوود",
                "label_fr": "Queenswood"
            },
            {
                "value": 79033,
                "label": "Howick",
                "label_ar": "هوويك",
                "label_fr": "Howick"
            },
            {
                "value": 78911,
                "label": "Brakpan",
                "label_ar": "براكبان",
                "label_fr": "Brakpan"
            },
            {
                "value": 78917,
                "label": "Franschhoek",
                "label_ar": "فرانشهوك",
                "label_fr": "Franschhoek"
            },
            {
                "value": 79106,
                "label": "Silverton",
                "label_ar": "سيلفرتون",
                "label_fr": "Silverton"
            },
            {
                "value": 78854,
                "label": "Pretoria",
                "label_ar": "بريتوريا",
                "label_fr": "Pretoria"
            },
            {
                "value": 79020,
                "label": "Virginia",
                "label_ar": "فرجينيا",
                "label_fr": "Virginie"
            },
            {
                "value": 79008,
                "label": "Melkbosstrand",
                "label_ar": "Melkbosstrand",
                "label_fr": "Melkbosstrand"
            },
            {
                "value": 79158,
                "label": "Lansdowne",
                "label_ar": "لانسداون",
                "label_fr": "Lansdowne"
            },
            {
                "value": 79084,
                "label": "Kingsborough",
                "label_ar": "كينجسبورو",
                "label_fr": "Kingsborough"
            },
            {
                "value": 79143,
                "label": "Majuba",
                "label_ar": "ماجوبا",
                "label_fr": "Majuba"
            },
            {
                "value": 78909,
                "label": "Vereeniging",
                "label_ar": "فيرينجينج",
                "label_fr": "Vereeniging"
            },
            {
                "value": 79060,
                "label": "Vaalpark",
                "label_ar": "فالبارك",
                "label_fr": "Vaalpark"
            },
            {
                "value": 78928,
                "label": "Hartbeespoort",
                "label_ar": "هارتبيسبورت",
                "label_fr": "Hartbeespoort"
            },
            {
                "value": 78861,
                "label": "Bloemfontein",
                "label_ar": "بلومفونتين",
                "label_fr": "Bloemfontein"
            },
            {
                "value": 79028,
                "label": "Hazyview",
                "label_ar": "هازيفيو",
                "label_fr": "Hazyview"
            },
            {
                "value": 78940,
                "label": "Mookgophong",
                "label_ar": "موكجوفونج",
                "label_fr": "Mookgophong"
            },
            {
                "value": 78885,
                "label": "Pietermaritzburg",
                "label_ar": "بيترماريتسبورج",
                "label_fr": "Pietermaritzburg"
            },
            {
                "value": 79015,
                "label": "Lyndhurst",
                "label_ar": "ليندهيرست",
                "label_fr": "Lyndhurst"
            },
            {
                "value": 79069,
                "label": "Kuils River",
                "label_ar": "نهر كويلز",
                "label_fr": "Rivière Kuils"
            },
            {
                "value": 78972,
                "label": "Emnambithi-Ladysmith",
                "label_ar": "Emnambithi-Ladysmith",
                "label_fr": "Emnambithi-Ladysmith"
            },
            {
                "value": 79141,
                "label": "Bothasig",
                "label_ar": "بوثسيج",
                "label_fr": "Bothasig"
            },
            {
                "value": 79059,
                "label": "Umtentweni",
                "label_ar": "Umtentweni",
                "label_fr": "Umtentweni"
            },
            {
                "value": 79099,
                "label": "La Motte",
                "label_ar": "لا موت",
                "label_fr": "La Motte"
            },
            {
                "value": 79065,
                "label": "Muizenberg",
                "label_ar": "Muizenberg",
                "label_fr": "Muizenberg"
            },
            {
                "value": 79127,
                "label": "Schweizer-Reneke",
                "label_ar": "شفايتزر رينيك",
                "label_fr": "Schweizer-Reneke"
            },
            {
                "value": 78990,
                "label": "Secunda",
                "label_ar": "سيكوندا",
                "label_fr": "Secunda"
            },
            {
                "value": 79138,
                "label": "Sandown",
                "label_ar": "سانداون",
                "label_fr": "Sandown"
            },
            {
                "value": 78931,
                "label": "Witpoortjie",
                "label_ar": "Witpoortjie",
                "label_fr": "Witpoortjie"
            },
            {
                "value": 78857,
                "label": "Despatch",
                "label_ar": "إيفاد",
                "label_fr": "Envoi"
            },
            {
                "value": 78878,
                "label": "Somerset West",
                "label_ar": "سومرست ويست",
                "label_fr": "Somerset West"
            },
            {
                "value": 78994,
                "label": "Umdloti",
                "label_ar": "أمدلوتي",
                "label_fr": "Umdloti"
            },
            {
                "value": 78957,
                "label": "Bronkhorstspruit",
                "label_ar": "برونخورستسبرويت",
                "label_fr": "Bronkhorstspruit"
            },
            {
                "value": 78870,
                "label": "Bellville",
                "label_ar": "بيلفيل",
                "label_fr": "Bellville"
            },
            {
                "value": 78976,
                "label": "Philipstown",
                "label_ar": "فيليبستاون",
                "label_fr": "Philipstown"
            },
            {
                "value": 78868,
                "label": "Mthatha",
                "label_ar": "مثاثها",
                "label_fr": "Mthatha"
            },
            {
                "value": 79125,
                "label": "Thohoyandou",
                "label_ar": "ثوهوياندو",
                "label_fr": "Thohoyandou"
            },
            {
                "value": 79174,
                "label": "Gezina",
                "label_ar": "جزينا",
                "label_fr": "Gezina"
            },
            {
                "value": 79118,
                "label": "Onderstepoort",
                "label_ar": "أونديرستيبورت",
                "label_fr": "Onderstepoort"
            },
            {
                "value": 79155,
                "label": "Philadelphia",
                "label_ar": "فيلادلفيا",
                "label_fr": "crême Philadelphia"
            },
            {
                "value": 79039,
                "label": "Standerton",
                "label_ar": "ستاندرتون",
                "label_fr": "Standerton"
            },
            {
                "value": 78960,
                "label": "Katlehong",
                "label_ar": "كاتلهونج",
                "label_fr": "Katlehong"
            },
            {
                "value": 78895,
                "label": "Germiston",
                "label_ar": "جيرميستون",
                "label_fr": "Germiston"
            },
            {
                "value": 79063,
                "label": "Parow",
                "label_ar": "بارو",
                "label_fr": "Parow"
            },
            {
                "value": 78906,
                "label": "Heidelberg",
                "label_ar": "هايدلبرغ",
                "label_fr": "Heidelberg"
            },
            {
                "value": 78873,
                "label": "George",
                "label_ar": "جورج",
                "label_fr": "George"
            },
            {
                "value": 78883,
                "label": "Sedgefield",
                "label_ar": "سيدجفيلد",
                "label_fr": "Sedgefield"
            },
            {
                "value": 79128,
                "label": "Moorreesburg",
                "label_ar": "موريسبرج",
                "label_fr": "Moorreesburg"
            },
            {
                "value": 79053,
                "label": "Welgemoed",
                "label_ar": "ويلجمود",
                "label_fr": "Welgemoed"
            },
            {
                "value": 79113,
                "label": "Marshalltown",
                "label_ar": "مارشالتاون",
                "label_fr": "Marshalltown"
            },
            {
                "value": 78958,
                "label": "Soshanguve",
                "label_ar": "سوشانجوف",
                "label_fr": "Soshanguve"
            },
            {
                "value": 79049,
                "label": "Soweto",
                "label_ar": "سويتو",
                "label_fr": "Soweto"
            },
            {
                "value": 78985,
                "label": "Eloff",
                "label_ar": "Eloff",
                "label_fr": "Eloff"
            },
            {
                "value": 78980,
                "label": "Villiersdorp",
                "label_ar": "فيليرسدورب",
                "label_fr": "Villiersdorp"
            },
            {
                "value": 79041,
                "label": "Eshowe",
                "label_ar": "إيشو",
                "label_fr": "Eshowe"
            },
            {
                "value": 78975,
                "label": "Kroonstad",
                "label_ar": "كرونستاد",
                "label_fr": "Kroonstad"
            },
            {
                "value": 79023,
                "label": "Plettenberg Bay",
                "label_ar": "خليج بليتينبيرج",
                "label_fr": "Plettenberg Bay"
            },
            {
                "value": 79101,
                "label": "Parkview",
                "label_ar": "باركفيو",
                "label_fr": "Parkview"
            },
            {
                "value": 78908,
                "label": "Randfontein",
                "label_ar": "راندفونتين",
                "label_fr": "Randfontein"
            },
            {
                "value": 78952,
                "label": "Ermelo",
                "label_ar": "إرميلو",
                "label_fr": "Ermelo"
            },
            {
                "value": 78989,
                "label": "White River",
                "label_ar": "النهر الأبيض",
                "label_fr": "White River"
            },
            {
                "value": 78892,
                "label": "Boksburg",
                "label_ar": "بوكسبورغ",
                "label_fr": "Boksburg"
            },
            {
                "value": 79154,
                "label": "Welgelegen",
                "label_ar": "ويلجليجن",
                "label_fr": "Welgelegen"
            },
            {
                "value": 78935,
                "label": "Pinelands",
                "label_ar": "بينيلاندس",
                "label_fr": "Pinelands"
            },
            {
                "value": 79175,
                "label": "Glen Austin",
                "label_ar": "جلين أوستن",
                "label_fr": "Glen Austin"
            },
            {
                "value": 79131,
                "label": "Faure",
                "label_ar": "فور",
                "label_fr": "Faure"
            },
            {
                "value": 79180,
                "label": "Rosebank",
                "label_ar": "روزبانك",
                "label_fr": "Rosebank"
            }
        ]
    },
    {
        "country": "Saint Kitts and Nevis",
        "cities": [
            {
                "value": 79183,
                "label": "Sandy Point Town",
                "label_ar": "ساندي بوينت تاون",
                "label_fr": "Ville de Sandy Point"
            },
            {
                "value": 79182,
                "label": "Basseterre",
                "label_ar": "باستير",
                "label_fr": "Basseterre"
            },
            {
                "value": 79181,
                "label": "Charlestown",
                "label_ar": "تشارلزتاون",
                "label_fr": "Charlestown"
            }
        ]
    },
    {
        "country": "Samoa",
        "cities": [
            {
                "value": 79184,
                "label": "Apia",
                "label_ar": "أبيا",
                "label_fr": "Apia"
            },
            {
                "value": 79185,
                "label": "Safotulafai",
                "label_ar": "سافوتولافاي",
                "label_fr": "Safotulafai"
            }
        ]
    },
    {
        "country": "Bolivia",
        "cities": [
            {
                "value": 79198,
                "label": "Bermejo",
                "label_ar": "برميجو",
                "label_fr": "Bermejo"
            },
            {
                "value": 79189,
                "label": "Sucre",
                "label_ar": "سوكريه",
                "label_fr": "Sucre"
            },
            {
                "value": 79199,
                "label": "Cruz",
                "label_ar": "كروز",
                "label_fr": "Cruz"
            },
            {
                "value": 79202,
                "label": "Aroma",
                "label_ar": "رائحة",
                "label_fr": "Arôme"
            },
            {
                "value": 79191,
                "label": "Santa Cruz",
                "label_ar": "سانتا كروز",
                "label_fr": "Santa Cruz"
            },
            {
                "value": 79203,
                "label": "Cotoca",
                "label_ar": "كوتوكا",
                "label_fr": "Cotoca"
            },
            {
                "value": 79188,
                "label": "Santa Cruz",
                "label_ar": "سانتا كروز",
                "label_fr": "Santa Cruz"
            },
            {
                "value": 79196,
                "label": "Riberalta",
                "label_ar": "ريبرالتا",
                "label_fr": "Riberalta"
            },
            {
                "value": 79201,
                "label": "Cobija",
                "label_ar": "كوبيجا",
                "label_fr": "Cobija"
            },
            {
                "value": 79193,
                "label": "Oruro",
                "label_ar": "أورورو",
                "label_fr": "Oruro"
            },
            {
                "value": 79200,
                "label": "Caracasa",
                "label_ar": "كاراكاسا",
                "label_fr": "Caracasa"
            },
            {
                "value": 79195,
                "label": "Tarija",
                "label_ar": "تاريخا",
                "label_fr": "Tarija"
            },
            {
                "value": 79204,
                "label": "Anillo",
                "label_ar": "أنيلو",
                "label_fr": "Anillo"
            },
            {
                "value": 79187,
                "label": "La Paz",
                "label_ar": "لاباز",
                "label_fr": "La Paz"
            },
            {
                "value": 79192,
                "label": "Potosí",
                "label_ar": "بوتوسي",
                "label_fr": "Potosí"
            },
            {
                "value": 79190,
                "label": "Cochabamba",
                "label_ar": "كوتشابامبا",
                "label_fr": "Cochabamba"
            },
            {
                "value": 79194,
                "label": "Guayaramerin",
                "label_ar": "Guayaramerin",
                "label_fr": "Guayaramerin"
            },
            {
                "value": 79197,
                "label": "Trinidad",
                "label_ar": "ترينيداد",
                "label_fr": "Trinidad"
            }
        ]
    },
    {
        "country": "Guernsey",
        "cities": [
            {
                "value": 79207,
                "label": "St. Saviour",
                "label_ar": "القديس المخلص",
                "label_fr": "Saint Sauveur"
            },
            {
                "value": 79205,
                "label": "St Peter Port",
                "label_ar": "ميناء سانت بيتر",
                "label_fr": "Port Saint-Pierre"
            },
            {
                "value": 79206,
                "label": "Torteval",
                "label_ar": "تورتيفال",
                "label_fr": "Torteval"
            }
        ]
    },
    {
        "country": "Malta",
        "cities": [
            {
                "value": 79250,
                "label": "Mqabba",
                "label_ar": "مقابة",
                "label_fr": "Mqabba"
            },
            {
                "value": 79227,
                "label": "Imsida",
                "label_ar": "إمسيدا",
                "label_fr": "Imsida"
            },
            {
                "value": 79246,
                "label": "Fontana",
                "label_ar": "فونتانا",
                "label_fr": "Fontana"
            },
            {
                "value": 79221,
                "label": "Munxar",
                "label_ar": "مونكسار",
                "label_fr": "Munxar"
            },
            {
                "value": 79267,
                "label": "Bugibba",
                "label_ar": "بوجيبا",
                "label_fr": "Bugibba"
            },
            {
                "value": 79248,
                "label": "Saint Venera",
                "label_ar": "القديسة فينيرا",
                "label_fr": "Saint Venera"
            },
            {
                "value": 79220,
                "label": "Mosta",
                "label_ar": "موستا",
                "label_fr": "Mosta"
            },
            {
                "value": 79208,
                "label": "Mellieha",
                "label_ar": "مليحة",
                "label_fr": "Mellieha"
            },
            {
                "value": 79240,
                "label": "Mdina",
                "label_ar": "مدينا",
                "label_fr": "Mdina"
            },
            {
                "value": 79213,
                "label": "Paola",
                "label_ar": "باولا",
                "label_fr": "Paola"
            },
            {
                "value": 79274,
                "label": "Manikata",
                "label_ar": "مانيكاتا",
                "label_fr": "Manikata"
            },
            {
                "value": 79263,
                "label": "Gudja",
                "label_ar": "جودجا",
                "label_fr": "Gudja"
            },
            {
                "value": 79223,
                "label": "Attard",
                "label_ar": "عطار",
                "label_fr": "Attard"
            },
            {
                "value": 79231,
                "label": "Zabbar",
                "label_ar": "زبار",
                "label_fr": "Zabbar"
            },
            {
                "value": 79244,
                "label": "Marsaskala",
                "label_ar": "مارساسكالا",
                "label_fr": "Marsaskala"
            },
            {
                "value": 79216,
                "label": "Marsa",
                "label_ar": "مرسى",
                "label_fr": "Marsa"
            },
            {
                "value": 79235,
                "label": "Valletta",
                "label_ar": "فاليتا",
                "label_fr": "La Valette"
            },
            {
                "value": 79239,
                "label": "Qormi",
                "label_ar": "قرمي",
                "label_fr": "Qormi"
            },
            {
                "value": 79249,
                "label": "Pembroke",
                "label_ar": "بيمبروك",
                "label_fr": "Pembroke"
            },
            {
                "value": 79272,
                "label": "Ghaxaq",
                "label_ar": "غكساك",
                "label_fr": "Ghaxaq"
            },
            {
                "value": 79243,
                "label": "Luqa",
                "label_ar": "لوقا",
                "label_fr": "Luqa"
            },
            {
                "value": 79215,
                "label": "Fgura",
                "label_ar": "فجورة",
                "label_fr": "Fgura"
            },
            {
                "value": 79209,
                "label": "Hamrun",
                "label_ar": "هامرون",
                "label_fr": "Hamrun"
            },
            {
                "value": 79252,
                "label": "Gzira",
                "label_ar": "الجزيرة",
                "label_fr": "Gzira"
            },
            {
                "value": 79258,
                "label": "Il-Madliena",
                "label_ar": "ايل مادلينا",
                "label_fr": "Il-Madliena"
            },
            {
                "value": 79222,
                "label": "Rabat",
                "label_ar": "الرباط",
                "label_fr": "Rabat"
            },
            {
                "value": 79253,
                "label": "St. Julian's",
                "label_ar": "سانت جوليان",
                "label_fr": "Saint-Julien"
            },
            {
                "value": 79257,
                "label": "Saint Lucia",
                "label_ar": "القديسة لوسيا",
                "label_fr": "Sainte-Lucie"
            },
            {
                "value": 79212,
                "label": "Zurrieq",
                "label_ar": "زوريك",
                "label_fr": "Zurrieq"
            },
            {
                "value": 79273,
                "label": "L-Iskorvit",
                "label_ar": "إل إسكورفيت",
                "label_fr": "L-Iskorvit"
            },
            {
                "value": 79259,
                "label": "Senglea",
                "label_ar": "سينجليا",
                "label_fr": "Senglea"
            },
            {
                "value": 79261,
                "label": "Dingli",
                "label_ar": "دينجلي",
                "label_fr": "Dingli"
            },
            {
                "value": 79233,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 79230,
                "label": "Safi",
                "label_ar": "آسفي",
                "label_fr": "Safi"
            },
            {
                "value": 79256,
                "label": "Hal Gharghur",
                "label_ar": "هل قرغور",
                "label_fr": "Hal Gharghur"
            },
            {
                "value": 79265,
                "label": "Ghajnsielem",
                "label_ar": "غجنسيليم",
                "label_fr": "Ghajnsielem"
            },
            {
                "value": 79276,
                "label": "Ta' Xbiex",
                "label_ar": "Ta 'Xbiex",
                "label_fr": "Ta 'Xbiex"
            },
            {
                "value": 79228,
                "label": "Birkirkara",
                "label_ar": "بيركيركارا",
                "label_fr": "Birkirkara"
            },
            {
                "value": 79210,
                "label": "Siggiewi",
                "label_ar": "Siggiewi",
                "label_fr": "Siggiewi"
            },
            {
                "value": 79218,
                "label": "Birżebbuġa",
                "label_ar": "بيرشبويت",
                "label_fr": "Birżebbuġa"
            },
            {
                "value": 79236,
                "label": "Cospicua",
                "label_ar": "كوسبيكوا",
                "label_fr": "Cospicua"
            },
            {
                "value": 79234,
                "label": "Sannat",
                "label_ar": "سانات",
                "label_fr": "Sannat"
            },
            {
                "value": 79260,
                "label": "Bingemma",
                "label_ar": "بينجيما",
                "label_fr": "Bingemma"
            },
            {
                "value": 79225,
                "label": "Naxxar",
                "label_ar": "Naxxar",
                "label_fr": "Naxxar"
            },
            {
                "value": 79266,
                "label": "Xewkija",
                "label_ar": "Xewkija",
                "label_fr": "Xewkija"
            },
            {
                "value": 79229,
                "label": "Zejtun",
                "label_ar": "زيتون",
                "label_fr": "Zejtun"
            },
            {
                "value": 79251,
                "label": "Marsalforn",
                "label_ar": "مارسالفورن",
                "label_fr": "Marsalforn"
            },
            {
                "value": 79264,
                "label": "Saint Lawrence",
                "label_ar": "سانت لورانس",
                "label_fr": "Saint-Laurent"
            },
            {
                "value": 79245,
                "label": "Pietà",
                "label_ar": "بيتا",
                "label_fr": "Pietà"
            },
            {
                "value": 79254,
                "label": "Imtarfa",
                "label_ar": "إمترفة",
                "label_fr": "Imtarfa"
            },
            {
                "value": 79226,
                "label": "Lija",
                "label_ar": "ليجا",
                "label_fr": "Lija"
            },
            {
                "value": 79238,
                "label": "Sliema",
                "label_ar": "سليمة",
                "label_fr": "Sliema"
            },
            {
                "value": 79277,
                "label": "Swieqi",
                "label_ar": "سويقي",
                "label_fr": "Swieqi"
            },
            {
                "value": 79237,
                "label": "San Pawl il-Bahar",
                "label_ar": "سان باول البحر",
                "label_fr": "San Pawl il-Bahar"
            },
            {
                "value": 79217,
                "label": "Kirkop",
                "label_ar": "كيركوب",
                "label_fr": "Kirkop"
            },
            {
                "value": 79214,
                "label": "Tarxien",
                "label_ar": "Tarxien",
                "label_fr": "Tarxien"
            },
            {
                "value": 79241,
                "label": "Marsaxlokk",
                "label_ar": "مارساشلوك",
                "label_fr": "Marsaxlokk"
            },
            {
                "value": 79242,
                "label": "Floriana",
                "label_ar": "فلوريانا",
                "label_fr": "Floriana"
            },
            {
                "value": 79271,
                "label": "Qrendi",
                "label_ar": "قرندي",
                "label_fr": "Qrendi"
            },
            {
                "value": 79269,
                "label": "Balzan",
                "label_ar": "بلزان",
                "label_fr": "Balzan"
            },
            {
                "value": 79255,
                "label": "Ghasri",
                "label_ar": "غصري",
                "label_fr": "Ghasri"
            },
            {
                "value": 79275,
                "label": "Iz-Zebbiegh",
                "label_ar": "عز زبيغ",
                "label_fr": "Iz-Zebbiegh"
            },
            {
                "value": 79247,
                "label": "Il-Pergla",
                "label_ar": "Il-Pergla",
                "label_fr": "Il-Pergla"
            },
            {
                "value": 79262,
                "label": "L-Iklin",
                "label_ar": "L- إكلين",
                "label_fr": "L-Iklin"
            },
            {
                "value": 79211,
                "label": "Saint John",
                "label_ar": "القديس يوحنا",
                "label_fr": "Saint Jean"
            },
            {
                "value": 79268,
                "label": "Nadur",
                "label_ar": "نادور",
                "label_fr": "Nadur"
            },
            {
                "value": 79224,
                "label": "Xaghra",
                "label_ar": "زاغرا",
                "label_fr": "Xaghra"
            },
            {
                "value": 79219,
                "label": "Gharb",
                "label_ar": "الغرب",
                "label_fr": "Gharb"
            },
            {
                "value": 79232,
                "label": "Haz-Zebbug",
                "label_ar": "Haz-Zebbug",
                "label_fr": "Haz-Zebbug"
            },
            {
                "value": 79270,
                "label": "Birgu",
                "label_ar": "بيرغو",
                "label_fr": "Birgu"
            }
        ]
    },
    {
        "country": "Tajikistan",
        "cities": [
            {
                "value": 79279,
                "label": "Khorugh",
                "label_ar": "خروج",
                "label_fr": "Khorugh"
            },
            {
                "value": 79278,
                "label": "Dushanbe",
                "label_ar": "دوشانبي",
                "label_fr": "Douchanbé"
            }
        ]
    },
    {
        "country": "Zimbabwe",
        "cities": [
            {
                "value": 79284,
                "label": "Chinhoyi",
                "label_ar": "تشينهويي",
                "label_fr": "Chinhoyi"
            },
            {
                "value": 79285,
                "label": "Kwekwe",
                "label_ar": "كويكوي",
                "label_fr": "Kwekwe"
            },
            {
                "value": 79287,
                "label": "Gwanda",
                "label_ar": "جواندا",
                "label_fr": "Gwanda"
            },
            {
                "value": 79281,
                "label": "Greendale",
                "label_ar": "جريندال",
                "label_fr": "Greendale"
            },
            {
                "value": 79288,
                "label": "Mufakose",
                "label_ar": "Mufakose",
                "label_fr": "Mufakose"
            },
            {
                "value": 79280,
                "label": "Harare",
                "label_ar": "هراري",
                "label_fr": "Harare"
            },
            {
                "value": 79282,
                "label": "Bulawayo",
                "label_ar": "بولاوايو",
                "label_fr": "Bulawayo"
            },
            {
                "value": 79283,
                "label": "Mutare",
                "label_ar": "موتاري",
                "label_fr": "Mutare"
            },
            {
                "value": 79286,
                "label": "Victoria Falls",
                "label_ar": "شلالات فيكتوريا",
                "label_fr": "Les chutes Victoria"
            }
        ]
    },
    {
        "country": "Liberia",
        "cities": [
            {
                "value": 79291,
                "label": "Paynesville",
                "label_ar": "باينسفيل",
                "label_fr": "Paynesville"
            },
            {
                "value": 79289,
                "label": "Monrovia",
                "label_ar": "مونروفيا",
                "label_fr": "Monrovia"
            },
            {
                "value": 79290,
                "label": "Fish Town",
                "label_ar": "مدينة الأسماك",
                "label_fr": "Ville de poisson"
            }
        ]
    },
    {
        "country": "Ghana",
        "cities": [
            {
                "value": 79300,
                "label": "Legon",
                "label_ar": "ليجون",
                "label_fr": "Legon"
            },
            {
                "value": 79302,
                "label": "Kumasi",
                "label_ar": "كوماسي",
                "label_fr": "Kumasi"
            },
            {
                "value": 79295,
                "label": "Sunyani",
                "label_ar": "سنياني",
                "label_fr": "Sunyani"
            },
            {
                "value": 79293,
                "label": "Accra",
                "label_ar": "أكرا",
                "label_fr": "Accra"
            },
            {
                "value": 79296,
                "label": "Bolgatanga",
                "label_ar": "بولجاتانجا",
                "label_fr": "Bolgatanga"
            },
            {
                "value": 79294,
                "label": "Koforidua",
                "label_ar": "كوفوريدوا",
                "label_fr": "Koforidua"
            },
            {
                "value": 79303,
                "label": "Takoradi",
                "label_ar": "تاكورادي",
                "label_fr": "Takoradi"
            },
            {
                "value": 79307,
                "label": "Berekum",
                "label_ar": "بيريكوم",
                "label_fr": "Berekum"
            },
            {
                "value": 79297,
                "label": "Winneba",
                "label_ar": "وينيبا",
                "label_fr": "Winneba"
            },
            {
                "value": 79304,
                "label": "Mampong",
                "label_ar": "مامبونج",
                "label_fr": "Mampong"
            },
            {
                "value": 79305,
                "label": "Cape Coast",
                "label_ar": "كيب كوست",
                "label_fr": "Cape Coast"
            },
            {
                "value": 79301,
                "label": "Wa",
                "label_ar": "وا",
                "label_fr": "Washington"
            },
            {
                "value": 79298,
                "label": "Home",
                "label_ar": "الصفحة الرئيسية",
                "label_fr": "Accueil"
            },
            {
                "value": 79306,
                "label": "Bawku",
                "label_ar": "باوكو",
                "label_fr": "Bawku"
            },
            {
                "value": 79292,
                "label": "Tema",
                "label_ar": "تيما",
                "label_fr": "Tema"
            },
            {
                "value": 79299,
                "label": "Navrongo",
                "label_ar": "نافرونجو",
                "label_fr": "Navrongo"
            }
        ]
    },
    {
        "country": "Tanzania",
        "cities": [
            {
                "value": 79308,
                "label": "Dar es Salaam",
                "label_ar": "دار السلام",
                "label_fr": "Dar es Salaam"
            },
            {
                "value": 79314,
                "label": "Tanga",
                "label_ar": "تانجا",
                "label_fr": "Tanga"
            },
            {
                "value": 79317,
                "label": "Njombe",
                "label_ar": "نجومبي",
                "label_fr": "Njombe"
            },
            {
                "value": 79316,
                "label": "Bukoba",
                "label_ar": "بوكوبا",
                "label_fr": "Bukoba"
            },
            {
                "value": 79309,
                "label": "Arusha",
                "label_ar": "أروشا",
                "label_fr": "Arusha"
            },
            {
                "value": 79313,
                "label": "Morogoro",
                "label_ar": "موروجورو",
                "label_fr": "Morogoro"
            },
            {
                "value": 79312,
                "label": "Dodoma",
                "label_ar": "دودوما",
                "label_fr": "Dodoma"
            },
            {
                "value": 79315,
                "label": "Raha",
                "label_ar": "راحة",
                "label_fr": "Raha"
            },
            {
                "value": 79311,
                "label": "Zanzibar",
                "label_ar": "زنجبار",
                "label_fr": "Zanzibar"
            },
            {
                "value": 79310,
                "label": "Mwanza",
                "label_ar": "موانزا",
                "label_fr": "Mwanza"
            }
        ]
    },
    {
        "country": "Zambia",
        "cities": [
            {
                "value": 79325,
                "label": "Siavonga",
                "label_ar": "سيافونجا",
                "label_fr": "Siavonga"
            },
            {
                "value": 79318,
                "label": "Lusaka",
                "label_ar": "لوساكا",
                "label_fr": "Lusaka"
            },
            {
                "value": 79319,
                "label": "Mumbwa",
                "label_ar": "مومبوا",
                "label_fr": "Mumbwa"
            },
            {
                "value": 79323,
                "label": "Kalomo",
                "label_ar": "كالومو",
                "label_fr": "Kalomo"
            },
            {
                "value": 79322,
                "label": "Macha",
                "label_ar": "ماشا",
                "label_fr": "Macha"
            },
            {
                "value": 79324,
                "label": "Solwezi",
                "label_ar": "سولويزي",
                "label_fr": "Solwezi"
            },
            {
                "value": 79326,
                "label": "Livingstone",
                "label_ar": "ليفينغستون",
                "label_fr": "Livingstone"
            },
            {
                "value": 79320,
                "label": "Kitwe",
                "label_ar": "كيتوي",
                "label_fr": "Kitwe"
            },
            {
                "value": 79321,
                "label": "Ndola",
                "label_ar": "ندولا",
                "label_fr": "Ndola"
            }
        ]
    },
    {
        "country": "Madagascar",
        "cities": [
            {
                "value": 79330,
                "label": "Ambanja",
                "label_ar": "أمبانجا",
                "label_fr": "Ambanja"
            },
            {
                "value": 79328,
                "label": "Antananarivo",
                "label_ar": "أنتاناناريفو",
                "label_fr": "Antananarivo"
            },
            {
                "value": 79329,
                "label": "Antsirabe",
                "label_ar": "أنتسيرابي",
                "label_fr": "Antsirabe"
            },
            {
                "value": 79332,
                "label": "Fianarantsoa",
                "label_ar": "فيانارانتسوا",
                "label_fr": "Fianarantsoa"
            },
            {
                "value": 79331,
                "label": "Toliara",
                "label_ar": "توليارا",
                "label_fr": "Toliara"
            },
            {
                "value": 79333,
                "label": "Antsiranana",
                "label_ar": "أنتسيرانانا",
                "label_fr": "Antsiranana"
            },
            {
                "value": 79327,
                "label": "Toamasina",
                "label_ar": "تواماسينا",
                "label_fr": "Toamasina"
            }
        ]
    },
    {
        "country": "Angola",
        "cities": [
            {
                "value": 79334,
                "label": "Kuito",
                "label_ar": "كويتو",
                "label_fr": "Kuito"
            },
            {
                "value": 79343,
                "label": "Camabatela",
                "label_ar": "كاماباتيلا",
                "label_fr": "Camabatela"
            },
            {
                "value": 79351,
                "label": "Saurimo",
                "label_ar": "سوريمو",
                "label_fr": "Saurimo"
            },
            {
                "value": 79335,
                "label": "Uíge",
                "label_ar": "Uíge",
                "label_fr": "Uíge"
            },
            {
                "value": 79348,
                "label": "Ondjiva",
                "label_ar": "أونديفا",
                "label_fr": "Ondjiva"
            },
            {
                "value": 79347,
                "label": "Talatona",
                "label_ar": "تالاتونا",
                "label_fr": "Talatona"
            },
            {
                "value": 79350,
                "label": "Cacole",
                "label_ar": "كاكول",
                "label_fr": "Cacole"
            },
            {
                "value": 79336,
                "label": "Luanda",
                "label_ar": "لواندا",
                "label_fr": "Luanda"
            },
            {
                "value": 79349,
                "label": "Cazeta",
                "label_ar": "كازيتا",
                "label_fr": "Cazeta"
            },
            {
                "value": 79341,
                "label": "Piri",
                "label_ar": "بيري",
                "label_fr": "Piri"
            },
            {
                "value": 79337,
                "label": "Benguela",
                "label_ar": "بنغيلا",
                "label_fr": "Benguela"
            },
            {
                "value": 79340,
                "label": "Malanje",
                "label_ar": "مالانج",
                "label_fr": "Malanje"
            },
            {
                "value": 79356,
                "label": "Lubango",
                "label_ar": "لوبانغو",
                "label_fr": "Lubango"
            },
            {
                "value": 79338,
                "label": "Lobito",
                "label_ar": "لوبيتو",
                "label_fr": "Lobito"
            },
            {
                "value": 79339,
                "label": "Cabinda",
                "label_ar": "كابيندا",
                "label_fr": "Cabinda"
            },
            {
                "value": 79346,
                "label": "Menongue",
                "label_ar": "مينونج",
                "label_fr": "Menongue"
            },
            {
                "value": 79345,
                "label": "N'dalatando",
                "label_ar": "N'dalatando",
                "label_fr": "N'dalatando"
            },
            {
                "value": 79355,
                "label": "Huambo",
                "label_ar": "هوامبو",
                "label_fr": "Huambo"
            },
            {
                "value": 79352,
                "label": "Ambriz",
                "label_ar": "أمبريز",
                "label_fr": "Ambriz"
            },
            {
                "value": 79354,
                "label": "Lumeje",
                "label_ar": "لوميج",
                "label_fr": "Lumeje"
            },
            {
                "value": 79342,
                "label": "Namibe",
                "label_ar": "ناميبي",
                "label_fr": "Namibe"
            },
            {
                "value": 79344,
                "label": "Lucapa",
                "label_ar": "لوكابا",
                "label_fr": "Lucapa"
            },
            {
                "value": 79353,
                "label": "Muxaluando",
                "label_ar": "موكسلواندو",
                "label_fr": "Muxaluando"
            }
        ]
    },
    {
        "country": "Namibia",
        "cities": [
            {
                "value": 79368,
                "label": "Grootfontein",
                "label_ar": "جروتفونتين",
                "label_fr": "Grootfontein"
            },
            {
                "value": 79358,
                "label": "Katima Mulilo",
                "label_ar": "كاتيما موليلو",
                "label_fr": "Katima Mulilo"
            },
            {
                "value": 79357,
                "label": "Windhoek",
                "label_ar": "ويندهوك",
                "label_fr": "Windhoek"
            },
            {
                "value": 79370,
                "label": "Oranjemund",
                "label_ar": "أورانجيموند",
                "label_fr": "Oranjemund"
            },
            {
                "value": 79371,
                "label": "Mpapuka",
                "label_ar": "مبابوكا",
                "label_fr": "Mpapuka"
            },
            {
                "value": 79372,
                "label": "Etunda",
                "label_ar": "إتوندا",
                "label_fr": "Etunda"
            },
            {
                "value": 79369,
                "label": "Keetmanshoop",
                "label_ar": "كيتمانشوب",
                "label_fr": "Keetmanshoop"
            },
            {
                "value": 79364,
                "label": "Oshakati",
                "label_ar": "Oshakati",
                "label_fr": "Oshakati"
            },
            {
                "value": 79367,
                "label": "Tsumeb",
                "label_ar": "تسوميب",
                "label_fr": "Tsumeb"
            },
            {
                "value": 79362,
                "label": "Swakopmund",
                "label_ar": "سواكوبموند",
                "label_fr": "Swakopmund"
            },
            {
                "value": 79363,
                "label": "Walvis Bay",
                "label_ar": "والفيس باي",
                "label_fr": "Walvis Bay"
            },
            {
                "value": 79366,
                "label": "Ongwediva",
                "label_ar": "أونجويديفا",
                "label_fr": "Ongwediva"
            },
            {
                "value": 79359,
                "label": "Olympia",
                "label_ar": "أولمبيا",
                "label_fr": "Olympie"
            },
            {
                "value": 79361,
                "label": "Otjiwarongo",
                "label_ar": "أوتجيوارونجو",
                "label_fr": "Otjiwarongo"
            },
            {
                "value": 79365,
                "label": "Ondangwa",
                "label_ar": "أوندانغوا",
                "label_fr": "Ondangwa"
            },
            {
                "value": 79360,
                "label": "Outapi",
                "label_ar": "Outapi",
                "label_fr": "Outapi"
            }
        ]
    },
    {
        "country": "Ivory Coast",
        "cities": [
            {
                "value": 79373,
                "label": "Abidjan",
                "label_ar": "أبيدجان",
                "label_fr": "Abidjan"
            }
        ]
    },
    {
        "country": "Sudan",
        "cities": [
            {
                "value": 79374,
                "label": "Kassala",
                "label_ar": "كسلا",
                "label_fr": "Kassala"
            },
            {
                "value": 79377,
                "label": "Shendi",
                "label_ar": "شندي",
                "label_fr": "Shendi"
            },
            {
                "value": 79375,
                "label": "Khartoum",
                "label_ar": "الخرطوم",
                "label_fr": "Khartoum"
            },
            {
                "value": 79376,
                "label": "Nyala",
                "label_ar": "نيالا",
                "label_fr": "Nyala"
            },
            {
                "value": 79379,
                "label": "Umm Durman",
                "label_ar": "أم درمان",
                "label_fr": "Umm Durman"
            },
            {
                "value": 79378,
                "label": "Thabit",
                "label_ar": "ثابت",
                "label_fr": "Thabit"
            }
        ]
    },
    {
        "country": "Uganda",
        "cities": [
            {
                "value": 79380,
                "label": "Kampala",
                "label_ar": "كمبالا",
                "label_fr": "Kampala"
            }
        ]
    },
    {
        "country": "Cameroon",
        "cities": [
            {
                "value": 79386,
                "label": "Ngaoundéré",
                "label_ar": "نغاونديري",
                "label_fr": "Ngaoundéré"
            },
            {
                "value": 79385,
                "label": "Bamenda",
                "label_ar": "بامندا",
                "label_fr": "Bamenda"
            },
            {
                "value": 79381,
                "label": "Douala",
                "label_ar": "دوالا",
                "label_fr": "Douala"
            },
            {
                "value": 79390,
                "label": "Bafia",
                "label_ar": "بافيا",
                "label_fr": "Bafia"
            },
            {
                "value": 79382,
                "label": "Yaoundé",
                "label_ar": "ياوندي",
                "label_fr": "Yaoundé"
            },
            {
                "value": 79391,
                "label": "Kribi",
                "label_ar": "كريبي",
                "label_fr": "Kribi"
            },
            {
                "value": 79389,
                "label": "Buea",
                "label_ar": "بويا",
                "label_fr": "Buea"
            },
            {
                "value": 79387,
                "label": "Tibati",
                "label_ar": "تباتي",
                "label_fr": "Tibati"
            },
            {
                "value": 79384,
                "label": "Ringo",
                "label_ar": "رينغو",
                "label_fr": "Ringo"
            },
            {
                "value": 79388,
                "label": "Kumba",
                "label_ar": "كومبا",
                "label_fr": "Kumba"
            },
            {
                "value": 79383,
                "label": "Bafoussam",
                "label_ar": "بافوسام",
                "label_fr": "Bafoussam"
            }
        ]
    },
    {
        "country": "Malawi",
        "cities": [
            {
                "value": 79395,
                "label": "Lilongwe",
                "label_ar": "ليلونغوي",
                "label_fr": "Lilongwe"
            },
            {
                "value": 79393,
                "label": "Mzuzu",
                "label_ar": "مزوزو",
                "label_fr": "Mzuzu"
            },
            {
                "value": 79394,
                "label": "Blantyre",
                "label_ar": "بلانتير",
                "label_fr": "Blantyre"
            },
            {
                "value": 79392,
                "label": "Made",
                "label_ar": "مصنوع",
                "label_fr": "Fait"
            }
        ]
    },
    {
        "country": "Gabon",
        "cities": [
            {
                "value": 79397,
                "label": "Moanda",
                "label_ar": "مواندا",
                "label_fr": "Moanda"
            },
            {
                "value": 79399,
                "label": "Lambaréné",
                "label_ar": "لامباريني",
                "label_fr": "Lambaréné"
            },
            {
                "value": 79401,
                "label": "Port-Gentil",
                "label_ar": "بورت جنتيل",
                "label_fr": "Port-Gentil"
            },
            {
                "value": 79400,
                "label": "Gamba",
                "label_ar": "جامبا",
                "label_fr": "Gamba"
            },
            {
                "value": 79396,
                "label": "Libreville",
                "label_ar": "ليبرفيل",
                "label_fr": "Libreville"
            },
            {
                "value": 79398,
                "label": "Mamagnia",
                "label_ar": "ماماجنيا",
                "label_fr": "Mamagnia"
            }
        ]
    },
    {
        "country": "Mali",
        "cities": [
            {
                "value": 79402,
                "label": "Bamako",
                "label_ar": "باماكو",
                "label_fr": "Bamako"
            }
        ]
    },
    {
        "country": "Benin",
        "cities": [
            {
                "value": 79405,
                "label": "Abomey-Calavi",
                "label_ar": "أبومي كالافي",
                "label_fr": "Abomey-Calavi"
            },
            {
                "value": 79404,
                "label": "Cotonou",
                "label_ar": "كوتونو",
                "label_fr": "Cotonou"
            }
        ]
    },
    {
        "country": "Chad",
        "cities": [
            {
                "value": 79406,
                "label": "Massaguet",
                "label_ar": "مساجيت",
                "label_fr": "Massaguet"
            },
            {
                "value": 79407,
                "label": "Abéché",
                "label_ar": "أبيشي",
                "label_fr": "Abéché"
            },
            {
                "value": 79408,
                "label": "N'Djamena",
                "label_ar": "نجامينا",
                "label_fr": "N'Djaména"
            },
            {
                "value": 79409,
                "label": "Moundou",
                "label_ar": "موندو",
                "label_fr": "Moundou"
            }
        ]
    },
    {
        "country": "Botswana",
        "cities": [
            {
                "value": 79413,
                "label": "Serowe",
                "label_ar": "سيروي",
                "label_fr": "Serowe"
            },
            {
                "value": 79414,
                "label": "Village",
                "label_ar": "قرية",
                "label_fr": "Village"
            },
            {
                "value": 79410,
                "label": "Francistown",
                "label_ar": "فرانسيستاون",
                "label_fr": "Francistown"
            },
            {
                "value": 79412,
                "label": "Orapa",
                "label_ar": "أورابا",
                "label_fr": "Orapa"
            },
            {
                "value": 79411,
                "label": "Gaborone",
                "label_ar": "غابورون",
                "label_fr": "Gaborone"
            }
        ]
    },
    {
        "country": "Cape Verde",
        "cities": [
            {
                "value": 79415,
                "label": "Praia",
                "label_ar": "برايا",
                "label_fr": "Praia"
            }
        ]
    },
    {
        "country": "Rwanda",
        "cities": [
            {
                "value": 79417,
                "label": "Nyarugunga",
                "label_ar": "نياروجونجا",
                "label_fr": "Nyarugunga"
            },
            {
                "value": 79416,
                "label": "Kigali",
                "label_ar": "كيغالي",
                "label_fr": "Kigali"
            }
        ]
    },
    {
        "country": "Republic of the Congo",
        "cities": [
            {
                "value": 79419,
                "label": "Pointe Noire",
                "label_ar": "بوانت نوار",
                "label_fr": "Pointe Noire"
            },
            {
                "value": 79418,
                "label": "Brazzaville",
                "label_ar": "برازافيل",
                "label_fr": "Brazzaville"
            }
        ]
    },
    {
        "country": "Mozambique",
        "cities": [
            {
                "value": 79428,
                "label": "Tete",
                "label_ar": "تيتي",
                "label_fr": "Tete"
            },
            {
                "value": 79427,
                "label": "Pemba",
                "label_ar": "بيمبا",
                "label_fr": "Pemba"
            },
            {
                "value": 79421,
                "label": "Maputo",
                "label_ar": "مابوتو",
                "label_fr": "Maputo"
            },
            {
                "value": 79422,
                "label": "Mozambique",
                "label_ar": "موزمبيق",
                "label_fr": "Mozambique"
            },
            {
                "value": 79423,
                "label": "Matola",
                "label_ar": "ماتولا",
                "label_fr": "Matola"
            },
            {
                "value": 79424,
                "label": "Nampula",
                "label_ar": "نامبولا",
                "label_fr": "Nampula"
            },
            {
                "value": 79425,
                "label": "Quelimane",
                "label_ar": "كيليماني",
                "label_fr": "Quelimane"
            },
            {
                "value": 79426,
                "label": "Beira",
                "label_ar": "بيرا",
                "label_fr": "Beira"
            }
        ]
    },
    {
        "country": "Gambia",
        "cities": [
            {
                "value": 79429,
                "label": "Banjul",
                "label_ar": "بانجول",
                "label_fr": "Banjul"
            }
        ]
    },
    {
        "country": "Lesotho",
        "cities": [
            {
                "value": 79431,
                "label": "Roma",
                "label_ar": "روما",
                "label_fr": "Roma"
            },
            {
                "value": 79430,
                "label": "Maseru",
                "label_ar": "ماسيرو",
                "label_fr": "Maseru"
            }
        ]
    },
    {
        "country": "Mauritius",
        "cities": [
            {
                "value": 79437,
                "label": "Rose Hill",
                "label_ar": "روز هيل",
                "label_fr": "Rose Hill"
            },
            {
                "value": 79442,
                "label": "Le Reduit",
                "label_ar": "لو ريديويت",
                "label_fr": "Le Reduit"
            },
            {
                "value": 79436,
                "label": "Tamarin",
                "label_ar": "تامارين",
                "label_fr": "Tamarin"
            },
            {
                "value": 79444,
                "label": "Saint Jean",
                "label_ar": "القديس جان",
                "label_fr": "Saint Jean"
            },
            {
                "value": 79432,
                "label": "Port Louis",
                "label_ar": "بورت لويس",
                "label_fr": "Port Louis"
            },
            {
                "value": 79435,
                "label": "Goodlands",
                "label_ar": "جودلاندز",
                "label_fr": "Goodlands"
            },
            {
                "value": 79443,
                "label": "Vacoas",
                "label_ar": "الفراغات",
                "label_fr": "Vacoas"
            },
            {
                "value": 79440,
                "label": "Chemin Grenier",
                "label_ar": "شيمن جرينير",
                "label_fr": "Chemin Grenier"
            },
            {
                "value": 79433,
                "label": "Beau Bassin",
                "label_ar": "بو باسين",
                "label_fr": "Beau Bassin"
            },
            {
                "value": 79438,
                "label": "Floreal",
                "label_ar": "فلوريال",
                "label_fr": "Floréal"
            },
            {
                "value": 79439,
                "label": "Port Mathurin",
                "label_ar": "ميناء ماثورين",
                "label_fr": "Port Mathurin"
            },
            {
                "value": 79434,
                "label": "Quatre Bornes",
                "label_ar": "كواتر بورنيس",
                "label_fr": "Quatre Bornes"
            },
            {
                "value": 79441,
                "label": "Ebene CyberCity",
                "label_ar": "Ebene CyberCity",
                "label_fr": "CyberCity d'Ebene"
            }
        ]
    },
    {
        "country": "Algeria",
        "cities": [
            {
                "value": 79473,
                "label": "Bougara",
                "label_ar": "بوقرة",
                "label_fr": "Bougara"
            },
            {
                "value": 79458,
                "label": "Tizi",
                "label_ar": "تيزي",
                "label_fr": "Tizi"
            },
            {
                "value": 79478,
                "label": "Djelfa",
                "label_ar": "الجلفة",
                "label_fr": "Djelfa"
            },
            {
                "value": 79469,
                "label": "Laghouat",
                "label_ar": "الأغواط",
                "label_fr": "Laghouat"
            },
            {
                "value": 79466,
                "label": "El Tarf",
                "label_ar": "الطارف",
                "label_fr": "El Tarf"
            },
            {
                "value": 79449,
                "label": "Sétif",
                "label_ar": "سطيف",
                "label_fr": "Sétif"
            },
            {
                "value": 79452,
                "label": "Bordj",
                "label_ar": "برج",
                "label_fr": "Bordj"
            },
            {
                "value": 79455,
                "label": "Saida",
                "label_ar": "صيدا",
                "label_fr": "Saida"
            },
            {
                "value": 79460,
                "label": "Azazga",
                "label_ar": "عزازقة",
                "label_fr": "Azazga"
            },
            {
                "value": 79474,
                "label": "Chlef",
                "label_ar": "الشلف",
                "label_fr": "Chlef"
            },
            {
                "value": 79470,
                "label": "Kouba",
                "label_ar": "القبة",
                "label_fr": "Kouba"
            },
            {
                "value": 79471,
                "label": "Jijel",
                "label_ar": "جيجل",
                "label_fr": "Jijel"
            },
            {
                "value": 79451,
                "label": "Bordj Bou Arreridj",
                "label_ar": "برج بوعريريج",
                "label_fr": "Bordj Bou Arreridj"
            },
            {
                "value": 79461,
                "label": "Blida",
                "label_ar": "البليدة",
                "label_fr": "Blida"
            },
            {
                "value": 79476,
                "label": "Ouargla",
                "label_ar": "ورقلة",
                "label_fr": "Ouargla"
            },
            {
                "value": 79479,
                "label": "Béchar",
                "label_ar": "بشار",
                "label_fr": "Béchar"
            },
            {
                "value": 79456,
                "label": "Tlemcen",
                "label_ar": "تلمسان",
                "label_fr": "Tlemcen"
            },
            {
                "value": 79445,
                "label": "Algiers",
                "label_ar": "الجزائر العاصمة",
                "label_fr": "Alger"
            },
            {
                "value": 79446,
                "label": "Souk Ahras",
                "label_ar": "سوق أهراس",
                "label_fr": "Souk Ahras"
            },
            {
                "value": 79447,
                "label": "Annaba",
                "label_ar": "عنابة",
                "label_fr": "Annaba"
            },
            {
                "value": 79464,
                "label": "Draria",
                "label_ar": "دراريا",
                "label_fr": "Draria"
            },
            {
                "value": 79468,
                "label": "Illizi",
                "label_ar": "إليزي",
                "label_fr": "Illizi"
            },
            {
                "value": 79472,
                "label": "Ghardaïa",
                "label_ar": "غرداية",
                "label_fr": "Ghardaïa"
            },
            {
                "value": 79475,
                "label": "Cheraga",
                "label_ar": "الشراقة",
                "label_fr": "Cheraga"
            },
            {
                "value": 79463,
                "label": "Hussein Dey",
                "label_ar": "حسين داي",
                "label_fr": "Hussein Dey"
            },
            {
                "value": 79457,
                "label": "Oued Smar",
                "label_ar": "واد السمار",
                "label_fr": "Oued Smar"
            },
            {
                "value": 79459,
                "label": "Tizi Ouzou",
                "label_ar": "تيزي وزو",
                "label_fr": "Tizi Ouzou"
            },
            {
                "value": 79462,
                "label": "Rouiba",
                "label_ar": "الرويبة",
                "label_fr": "Rouiba"
            },
            {
                "value": 79467,
                "label": "Constantine",
                "label_ar": "قسنطينة",
                "label_fr": "Constantine"
            },
            {
                "value": 79453,
                "label": "Tiaret",
                "label_ar": "تيارت",
                "label_fr": "Diadème"
            },
            {
                "value": 79480,
                "label": "Tamanghasset",
                "label_ar": "تمنغست",
                "label_fr": "Tamanghasset"
            },
            {
                "value": 79465,
                "label": "Tissemsilt",
                "label_ar": "تيسمسيلت",
                "label_fr": "Tissemsilt"
            },
            {
                "value": 79450,
                "label": "Béjaïa",
                "label_ar": "بجاية",
                "label_fr": "Béjaïa"
            },
            {
                "value": 79477,
                "label": "Relizane",
                "label_ar": "غليزان",
                "label_fr": "Relizane"
            },
            {
                "value": 79454,
                "label": "Oran",
                "label_ar": "وهران",
                "label_fr": "Oran"
            },
            {
                "value": 79448,
                "label": "Batna City",
                "label_ar": "مدينة باتنة",
                "label_fr": "Ville de Batna"
            }
        ]
    },
    {
        "country": "Guinea",
        "cities": [
            {
                "value": 79484,
                "label": "Labé",
                "label_ar": "لابيه",
                "label_fr": "Labé"
            },
            {
                "value": 79489,
                "label": "Dabola",
                "label_ar": "دابولا",
                "label_fr": "Dabola"
            },
            {
                "value": 79482,
                "label": "Forécariah",
                "label_ar": "فوريكاريا",
                "label_fr": "Forécariah"
            },
            {
                "value": 79481,
                "label": "Conakry",
                "label_ar": "كوناكري",
                "label_fr": "Conakry"
            },
            {
                "value": 79486,
                "label": "Port Kamsar",
                "label_ar": "ميناء كامسار",
                "label_fr": "Port Kamsar"
            },
            {
                "value": 79492,
                "label": "Kalia",
                "label_ar": "كاليا",
                "label_fr": "Kalia"
            },
            {
                "value": 79488,
                "label": "Mamou",
                "label_ar": "مامو",
                "label_fr": "Mamou"
            },
            {
                "value": 79483,
                "label": "Nzérékoré",
                "label_ar": "Nzérékoré",
                "label_fr": "Nzérékoré"
            },
            {
                "value": 79490,
                "label": "Lola",
                "label_ar": "لولا",
                "label_fr": "Lola"
            },
            {
                "value": 79487,
                "label": "Boké",
                "label_ar": "بوكيه",
                "label_fr": "Boké"
            },
            {
                "value": 79485,
                "label": "Kankan",
                "label_ar": "كانكان",
                "label_fr": "Kankan"
            },
            {
                "value": 79491,
                "label": "Sangaredi",
                "label_ar": "سانغاردي",
                "label_fr": "Sangaredi"
            }
        ]
    },
    {
        "country": "Congo",
        "cities": [
            {
                "value": 79495,
                "label": "Goma",
                "label_ar": "غوما",
                "label_fr": "Goma"
            },
            {
                "value": 79493,
                "label": "Kinshasa",
                "label_ar": "كينشاسا",
                "label_fr": "Kinshasa"
            },
            {
                "value": 79496,
                "label": "Banana",
                "label_ar": "موز",
                "label_fr": "banane"
            },
            {
                "value": 79494,
                "label": "Lubumbashi",
                "label_ar": "لوبومباشي",
                "label_fr": "Lubumbashi"
            },
            {
                "value": 79497,
                "label": "Likasi",
                "label_ar": "ليكاسي",
                "label_fr": "Likasi"
            }
        ]
    },
    {
        "country": "Swaziland",
        "cities": [
            {
                "value": 79499,
                "label": "Piggs Peak",
                "label_ar": "بيجز بيك",
                "label_fr": "Pic Piggs"
            },
            {
                "value": 79501,
                "label": "Kwaluseni",
                "label_ar": "كوالوسيني",
                "label_fr": "Kwaluseni"
            },
            {
                "value": 79498,
                "label": "Mbabane",
                "label_ar": "مبابان",
                "label_fr": "Mbabane"
            },
            {
                "value": 79500,
                "label": "Lobamba",
                "label_ar": "لوبامبا",
                "label_fr": "Lobamba"
            },
            {
                "value": 79502,
                "label": "Manzini",
                "label_ar": "مانزيني",
                "label_fr": "Manzini"
            }
        ]
    },
    {
        "country": "Burkina Faso",
        "cities": [
            {
                "value": 79504,
                "label": "Bobo-Dioulasso",
                "label_ar": "بوبو ديولاسو",
                "label_fr": "Bobo-Dioulasso"
            },
            {
                "value": 79503,
                "label": "Ouagadougou",
                "label_ar": "واغادوغو",
                "label_fr": "Ouagadougou"
            },
            {
                "value": 79505,
                "label": "Tenkodogo",
                "label_ar": "تينكودوجو",
                "label_fr": "Tenkodogo"
            }
        ]
    },
    {
        "country": "Sierra Leone",
        "cities": [
            {
                "value": 79506,
                "label": "Freetown",
                "label_ar": "فريتاون",
                "label_fr": "Freetown"
            }
        ]
    },
    {
        "country": "Somalia",
        "cities": [
            {
                "value": 79507,
                "label": "Hargeysa",
                "label_ar": "هرجيسا",
                "label_fr": "Hargeysa"
            },
            {
                "value": 79508,
                "label": "Mogadishu",
                "label_ar": "مقديشو",
                "label_fr": "Mogadiscio"
            }
        ]
    },
    {
        "country": "Niger",
        "cities": [
            {
                "value": 79509,
                "label": "Niamey",
                "label_ar": "نيامي",
                "label_fr": "Niamey"
            }
        ]
    },
    {
        "country": "Central African Republic",
        "cities": [
            {
                "value": 79510,
                "label": "Bangui",
                "label_ar": "بانغي",
                "label_fr": "Bangui"
            }
        ]
    },
    {
        "country": "Togo",
        "cities": [
            {
                "value": 79512,
                "label": "Sansanne-Mango",
                "label_ar": "سانسان مانجو",
                "label_fr": "Sansanne-Mangue"
            },
            {
                "value": 79511,
                "label": "Lomé",
                "label_ar": "لومي",
                "label_fr": "Lomé"
            }
        ]
    },
    {
        "country": "Burundi",
        "cities": [
            {
                "value": 79513,
                "label": "Bujumbura",
                "label_ar": "بوجومبورا",
                "label_fr": "Bujumbura"
            }
        ]
    },
    {
        "country": "Equatorial Guinea",
        "cities": [
            {
                "value": 79515,
                "label": "Malabo",
                "label_ar": "مالابو",
                "label_fr": "Malabo"
            }
        ]
    },
    {
        "country": "South Sudan",
        "cities": [
            {
                "value": 79516,
                "label": "Juba",
                "label_ar": "جوبا",
                "label_fr": "Juba"
            }
        ]
    },
    {
        "country": "Senegal",
        "cities": [
            {
                "value": 79527,
                "label": "Kedougou",
                "label_ar": "Kedougou",
                "label_fr": "Kédougou"
            },
            {
                "value": 79518,
                "label": "Dakar",
                "label_ar": "داكار",
                "label_fr": "Dakar"
            },
            {
                "value": 79524,
                "label": "Tanaf",
                "label_ar": "تناف",
                "label_fr": "Tanaf"
            },
            {
                "value": 79521,
                "label": "Kaolack",
                "label_ar": "كاولاك",
                "label_fr": "Kaolack"
            },
            {
                "value": 79525,
                "label": "Saint-Louis",
                "label_ar": "سانت لويس",
                "label_fr": "Saint Louis"
            },
            {
                "value": 79517,
                "label": "Sama",
                "label_ar": "مؤسسة النقد العربي السعودي",
                "label_fr": "Sama"
            },
            {
                "value": 79523,
                "label": "Boussinki",
                "label_ar": "بوسنكي",
                "label_fr": "Boussinki"
            },
            {
                "value": 79526,
                "label": "Camberene",
                "label_ar": "كامبيرين",
                "label_fr": "Camberène"
            },
            {
                "value": 79528,
                "label": "Madina Kokoun",
                "label_ar": "مدينة كوكون",
                "label_fr": "Madina Kokoun"
            },
            {
                "value": 79522,
                "label": "Dodji",
                "label_ar": "دودجي",
                "label_fr": "Dodji"
            },
            {
                "value": 79519,
                "label": "Guediawaye",
                "label_ar": "Guediawaye",
                "label_fr": "Guediawaye"
            },
            {
                "value": 79520,
                "label": "Louga",
                "label_ar": "لوجا",
                "label_fr": "Louga"
            }
        ]
    },
    {
        "country": "Mauritania",
        "cities": [
            {
                "value": 79529,
                "label": "Nouakchott",
                "label_ar": "نواكشوط",
                "label_fr": "Nouakchott"
            }
        ]
    },
    {
        "country": "Djibouti",
        "cities": [
            {
                "value": 79530,
                "label": "Djibouti",
                "label_ar": "جيبوتي",
                "label_fr": "Djibouti"
            }
        ]
    },
    {
        "country": "Comoros",
        "cities": [
            {
                "value": 79532,
                "label": "Moroni",
                "label_ar": "موروني",
                "label_fr": "Moroni"
            },
            {
                "value": 79531,
                "label": "Moutsamoudou",
                "label_ar": "موتسامودو",
                "label_fr": "Moutsamoudou"
            }
        ]
    },
    {
        "country": "Tunisia",
        "cities": [
            {
                "value": 79542,
                "label": "Rades",
                "label_ar": "رادس",
                "label_fr": "Radès"
            },
            {
                "value": 79533,
                "label": "Tunis",
                "label_ar": "تونس",
                "label_fr": "Tunis"
            },
            {
                "value": 79537,
                "label": "Monastir",
                "label_ar": "المنستير",
                "label_fr": "Monastir"
            },
            {
                "value": 79535,
                "label": "Sousse",
                "label_ar": "سوسة",
                "label_fr": "Sousse"
            },
            {
                "value": 79538,
                "label": "Hammamet",
                "label_ar": "الحمامات",
                "label_fr": "Hammamet"
            },
            {
                "value": 79534,
                "label": "Le Bardo",
                "label_ar": "لو باردو",
                "label_fr": "Le Bardo"
            },
            {
                "value": 79541,
                "label": "Beja",
                "label_ar": "البجا",
                "label_fr": "Beja"
            },
            {
                "value": 79540,
                "label": "Manouba",
                "label_ar": "منوبة",
                "label_fr": "Manouba"
            },
            {
                "value": 79536,
                "label": "Gafsa",
                "label_ar": "قفصة",
                "label_fr": "Gafsa"
            },
            {
                "value": 79544,
                "label": "Sfax",
                "label_ar": "صفاقس",
                "label_fr": "Sfax"
            },
            {
                "value": 79539,
                "label": "Sidi Bouzid",
                "label_ar": "سيدي بوزيد",
                "label_fr": "Sidi Bouzid"
            },
            {
                "value": 79543,
                "label": "Ariana",
                "label_ar": "أريانة",
                "label_fr": "Ariana"
            }
        ]
    },
    {
        "country": "Mayotte",
        "cities": [
            {
                "value": 79548,
                "label": "Pamandzi",
                "label_ar": "باماندزي",
                "label_fr": "Pamandzi"
            },
            {
                "value": 79551,
                "label": "Ouangani",
                "label_ar": "وانجاني",
                "label_fr": "Ouangani"
            },
            {
                "value": 79547,
                "label": "Tsoundzou 1",
                "label_ar": "تسوندزو 1",
                "label_fr": "Tsoundzou 1"
            },
            {
                "value": 79549,
                "label": "Sada",
                "label_ar": "صدى",
                "label_fr": "Sada"
            },
            {
                "value": 79552,
                "label": "Tsingoni",
                "label_ar": "تسينغوني",
                "label_fr": "Tsingoni"
            },
            {
                "value": 79553,
                "label": "Bandaboa",
                "label_ar": "باندابوا",
                "label_fr": "Bandaboa"
            },
            {
                "value": 79545,
                "label": "Koungou",
                "label_ar": "كونغو",
                "label_fr": "Koungou"
            },
            {
                "value": 79550,
                "label": "Chiconi",
                "label_ar": "تشيكوني",
                "label_fr": "Chiconi"
            },
            {
                "value": 79556,
                "label": "Dzaoudzi",
                "label_ar": "دزاودزي",
                "label_fr": "Dzaoudzi"
            },
            {
                "value": 79554,
                "label": "Dzoumonye",
                "label_ar": "Dzoumonye",
                "label_fr": "Dzoumonye"
            },
            {
                "value": 79546,
                "label": "Mamoudzou",
                "label_ar": "مامودزو",
                "label_fr": "Mamoudzou"
            },
            {
                "value": 79555,
                "label": "Combani",
                "label_ar": "كومباني",
                "label_fr": "Combani"
            }
        ]
    },
    {
        "country": "Bhutan",
        "cities": [
            {
                "value": 79557,
                "label": "Thimphu",
                "label_ar": "تيمفو",
                "label_fr": "Thimphu"
            },
            {
                "value": 79559,
                "label": "Phuntsholing",
                "label_ar": "Phuntsholing",
                "label_fr": "Phuntsholing"
            },
            {
                "value": 79558,
                "label": "Paro",
                "label_ar": "بارو",
                "label_fr": "Paro"
            },
            {
                "value": 79560,
                "label": "Mongar",
                "label_ar": "مونغار",
                "label_fr": "Mongar"
            }
        ]
    },
    {
        "country": "Greenland",
        "cities": [
            {
                "value": 79563,
                "label": "Nuuk",
                "label_ar": "نوك",
                "label_fr": "Nuuk"
            },
            {
                "value": 79570,
                "label": "Paamiut",
                "label_ar": "باموت",
                "label_fr": "Paamiut"
            },
            {
                "value": 79561,
                "label": "Nuussuaq",
                "label_ar": "نوسوق",
                "label_fr": "Nuussuaq"
            },
            {
                "value": 79564,
                "label": "Maniitsoq",
                "label_ar": "مانيتسوك",
                "label_fr": "Maniitsoq"
            },
            {
                "value": 79562,
                "label": "Kapisillit",
                "label_ar": "كابيسيليت",
                "label_fr": "Kapisillit"
            },
            {
                "value": 79573,
                "label": "Tasiilaq",
                "label_ar": "تصيلق",
                "label_fr": "Tasiilaq"
            },
            {
                "value": 79572,
                "label": "Upernavik",
                "label_ar": "أوبيرنافيك",
                "label_fr": "Upernavik"
            },
            {
                "value": 79569,
                "label": "Qasigiannguit",
                "label_ar": "Qasigiannguit",
                "label_fr": "Qasigiannguit"
            },
            {
                "value": 79567,
                "label": "Ilulissat",
                "label_ar": "إيلوليسات",
                "label_fr": "Ilulissat"
            },
            {
                "value": 79565,
                "label": "Qeqertarsuaq",
                "label_ar": "ققرترسواق",
                "label_fr": "Qeqertarsuaq"
            },
            {
                "value": 79571,
                "label": "Narsaq",
                "label_ar": "نارساك",
                "label_fr": "Narsaq"
            },
            {
                "value": 79568,
                "label": "Uummannaq Kommune",
                "label_ar": "Uummannaq Kommune",
                "label_fr": "Uummannaq Kommune"
            },
            {
                "value": 79574,
                "label": "Sisimiut",
                "label_ar": "سيسيميوت",
                "label_fr": "Sisimiut"
            },
            {
                "value": 79577,
                "label": "Narsarsuaq",
                "label_ar": "نارسارسواك",
                "label_fr": "Narsarsuaq"
            },
            {
                "value": 79576,
                "label": "Qeqertat",
                "label_ar": "ققرتات",
                "label_fr": "Qeqertat"
            },
            {
                "value": 79575,
                "label": "Aasiaat",
                "label_ar": "اسيات",
                "label_fr": "Aasiaat"
            },
            {
                "value": 79566,
                "label": "Qaqortoq",
                "label_ar": "ققرطوق",
                "label_fr": "Qaqortoq"
            }
        ]
    },
    {
        "country": "Kosovo",
        "cities": [
            {
                "value": 79580,
                "label": "Mitrovica",
                "label_ar": "ميتروفيتشا",
                "label_fr": "Mitrovica"
            },
            {
                "value": 79581,
                "label": "Dunavo",
                "label_ar": "دونافو",
                "label_fr": "Dunavo"
            },
            {
                "value": 79579,
                "label": "Ferizaj",
                "label_ar": "فيريزاج",
                "label_fr": "Ferizaj"
            },
            {
                "value": 79578,
                "label": "Pristina",
                "label_ar": "بريشتينا",
                "label_fr": "Pristina"
            },
            {
                "value": 79582,
                "label": "Prizren",
                "label_ar": "بريزرين",
                "label_fr": "Prizren"
            }
        ]
    },
    {
        "country": "Cayman Islands",
        "cities": [
            {
                "value": 79584,
                "label": "George Town",
                "label_ar": "جورج تاون",
                "label_fr": "George Town"
            },
            {
                "value": 79585,
                "label": "West Bay",
                "label_ar": "الخليج الغربي",
                "label_fr": "la baie ouest"
            },
            {
                "value": 79587,
                "label": "Spot Bay",
                "label_ar": "سبوت باي",
                "label_fr": "Spot Bay"
            },
            {
                "value": 79589,
                "label": "Newlands",
                "label_ar": "اراضي جديدة",
                "label_fr": "Nouveaux territoires"
            },
            {
                "value": 79583,
                "label": "Coral Gables",
                "label_ar": "حائط مرجان",
                "label_fr": "Coral Gables"
            },
            {
                "value": 79586,
                "label": "Savannah",
                "label_ar": "سافانا",
                "label_fr": "Savane"
            },
            {
                "value": 79588,
                "label": "Bodden Town",
                "label_ar": "مدينة بودن",
                "label_fr": "Ville de Bodden"
            }
        ]
    },
    {
        "country": "Jamaica",
        "cities": [
            {
                "value": 79598,
                "label": "May Pen",
                "label_ar": "قد القلم",
                "label_fr": "Stylo de mai"
            },
            {
                "value": 79596,
                "label": "Gordon Town",
                "label_ar": "مدينة جوردون",
                "label_fr": "Gordon Town"
            },
            {
                "value": 79595,
                "label": "Gregory Park",
                "label_ar": "جريجوري بارك",
                "label_fr": "Gregory Park"
            },
            {
                "value": 79602,
                "label": "Negril",
                "label_ar": "نيجريل",
                "label_fr": "Negril"
            },
            {
                "value": 79590,
                "label": "Runaway Bay",
                "label_ar": "هارب باي",
                "label_fr": "Runaway Bay"
            },
            {
                "value": 79603,
                "label": "Port Maria",
                "label_ar": "ميناء ماريا",
                "label_fr": "Port Maria"
            },
            {
                "value": 79599,
                "label": "Ocho Rios",
                "label_ar": "اوتشو ريوس",
                "label_fr": "Ocho Rios"
            },
            {
                "value": 79591,
                "label": "Kingston",
                "label_ar": "كينغستون",
                "label_fr": "Kingston"
            },
            {
                "value": 79593,
                "label": "Montego Bay",
                "label_ar": "مونتيغو باي",
                "label_fr": "Montego Bay"
            },
            {
                "value": 79604,
                "label": "Browns Town",
                "label_ar": "بلدة براونز",
                "label_fr": "Browns Town"
            },
            {
                "value": 79601,
                "label": "Old Harbour",
                "label_ar": "الميناء القديم",
                "label_fr": "Vieux port"
            },
            {
                "value": 79594,
                "label": "Portmore",
                "label_ar": "بورتمور",
                "label_fr": "Portmore"
            },
            {
                "value": 79606,
                "label": "Black River",
                "label_ar": "النهر الاسود",
                "label_fr": "Rivière Noire"
            },
            {
                "value": 79592,
                "label": "Spanish Town",
                "label_ar": "المدينة الاسبانية",
                "label_fr": "Ville espagnole"
            },
            {
                "value": 79597,
                "label": "Mandeville",
                "label_ar": "ماندفيل",
                "label_fr": "Mandeville"
            },
            {
                "value": 79600,
                "label": "Portland Cottage",
                "label_ar": "كوخ بورتلاند",
                "label_fr": "Cottage Portland"
            },
            {
                "value": 79605,
                "label": "Moneague",
                "label_ar": "مونيج",
                "label_fr": "Moneague"
            }
        ]
    },
    {
        "country": "Guatemala",
        "cities": [
            {
                "value": 79629,
                "label": "Coban",
                "label_ar": "كوبان",
                "label_fr": "Coban"
            },
            {
                "value": 79625,
                "label": "Petapa",
                "label_ar": "بيتابا",
                "label_fr": "Petapa"
            },
            {
                "value": 79607,
                "label": "Huehuetenango",
                "label_ar": "Huehuetenango",
                "label_fr": "Huehuetenango"
            },
            {
                "value": 79638,
                "label": "Jocotenango",
                "label_ar": "جوكوتينانجو",
                "label_fr": "Jocotenango"
            },
            {
                "value": 79610,
                "label": "El Salvador",
                "label_ar": "السلفادور",
                "label_fr": "Le Salvador"
            },
            {
                "value": 79624,
                "label": "Ciudad Vieja",
                "label_ar": "سيوداد فيجا",
                "label_fr": "Ciudad Vieja"
            },
            {
                "value": 79635,
                "label": "La Reforma",
                "label_ar": "لا ريفورما",
                "label_fr": "La Reforma"
            },
            {
                "value": 79623,
                "label": "Zacapa",
                "label_ar": "زاكابا",
                "label_fr": "Zacapa"
            },
            {
                "value": 79634,
                "label": "San Marcos",
                "label_ar": "سان ماركوس",
                "label_fr": "San Marcos"
            },
            {
                "value": 79648,
                "label": "San Jose Pinula",
                "label_ar": "سان خوسيه بينولا",
                "label_fr": "San José Pinula"
            },
            {
                "value": 79627,
                "label": "Palin",
                "label_ar": "بالين",
                "label_fr": "Palin"
            },
            {
                "value": 79643,
                "label": "Chiquimula",
                "label_ar": "شيكيمولا",
                "label_fr": "Chiquimula"
            },
            {
                "value": 79620,
                "label": "Quesada",
                "label_ar": "كيسادة",
                "label_fr": "Quesada"
            },
            {
                "value": 79642,
                "label": "Totonicapán",
                "label_ar": "توتونيكابان",
                "label_fr": "Totonicapán"
            },
            {
                "value": 79611,
                "label": "Catarina",
                "label_ar": "كاتارينا",
                "label_fr": "Catarina"
            },
            {
                "value": 79641,
                "label": "Solola",
                "label_ar": "سولولا",
                "label_fr": "Solola"
            },
            {
                "value": 79651,
                "label": "Cambote",
                "label_ar": "كامبوتي",
                "label_fr": "Cambote"
            },
            {
                "value": 79619,
                "label": "Quetzaltenango",
                "label_ar": "كويتزالتنانغو",
                "label_fr": "Quetzaltenango"
            },
            {
                "value": 79614,
                "label": "Melchor de Mencos",
                "label_ar": "ميلكور دي مينكوس",
                "label_fr": "Melchor de Mencos"
            },
            {
                "value": 79615,
                "label": "Chimaltenango",
                "label_ar": "تشيمالتينانجو",
                "label_fr": "Chimaltenango"
            },
            {
                "value": 79646,
                "label": "Flores",
                "label_ar": "فلوريس",
                "label_fr": "Flores"
            },
            {
                "value": 79639,
                "label": "Santa Cruz La Laguna",
                "label_ar": "سانتا كروز لا لاغونا",
                "label_fr": "Santa Cruz La Laguna"
            },
            {
                "value": 79636,
                "label": "San Juan",
                "label_ar": "سان خوان",
                "label_fr": "San Juan"
            },
            {
                "value": 79613,
                "label": "Mixco",
                "label_ar": "ميكسكو",
                "label_fr": "Mixco"
            },
            {
                "value": 79616,
                "label": "San Pedro Sacatepequez",
                "label_ar": "سان بيدرو ساكاتيبيكيز",
                "label_fr": "San Pedro Sacatepequez"
            },
            {
                "value": 79618,
                "label": "Santa Catarina Pinula",
                "label_ar": "سانتا كاتارينا بينولا",
                "label_fr": "Santa Catarina Pinula"
            },
            {
                "value": 79609,
                "label": "Vista Hermosa",
                "label_ar": "فيستا هيرموسا",
                "label_fr": "Vista Hermosa"
            },
            {
                "value": 79612,
                "label": "Amatitlán",
                "label_ar": "أماتيتلان",
                "label_fr": "Amatitlán"
            },
            {
                "value": 79647,
                "label": "Santa Rosa",
                "label_ar": "سانتا روزا",
                "label_fr": "Santa Rosa"
            },
            {
                "value": 79649,
                "label": "El Naranjo",
                "label_ar": "النارانجو",
                "label_fr": "El Naranjo"
            },
            {
                "value": 79608,
                "label": "Guatemala City",
                "label_ar": "مدينة غواتيمالا",
                "label_fr": "Guatemala City"
            },
            {
                "value": 79637,
                "label": "Panajachel",
                "label_ar": "باناجاشيل",
                "label_fr": "Panajachel"
            },
            {
                "value": 79631,
                "label": "Santa Lucia Cotzumalguapa",
                "label_ar": "سانتا لوسيا كوتزومالغوابا",
                "label_fr": "Santa Lucia Cotzumalguapa"
            },
            {
                "value": 79621,
                "label": "Santa Cruz Naranjo",
                "label_ar": "سانتا كروز نارانجو",
                "label_fr": "Santa Cruz Naranjo"
            },
            {
                "value": 79617,
                "label": "Antigua Guatemala",
                "label_ar": "أنتيغوا غواتيمالا",
                "label_fr": "Antigua Guatemala"
            },
            {
                "value": 79630,
                "label": "Mazatenango",
                "label_ar": "مازاتينانغو",
                "label_fr": "Mazatenango"
            },
            {
                "value": 79622,
                "label": "Villa Nueva",
                "label_ar": "فيلا نويفا",
                "label_fr": "Villa Nueva"
            },
            {
                "value": 79650,
                "label": "El Limon",
                "label_ar": "ليمون",
                "label_fr": "El Limon"
            },
            {
                "value": 79645,
                "label": "Central",
                "label_ar": "وسط",
                "label_fr": "Central"
            },
            {
                "value": 79628,
                "label": "La Reforma",
                "label_ar": "لا ريفورما",
                "label_fr": "La Reforma"
            },
            {
                "value": 79632,
                "label": "Retalhuleu",
                "label_ar": "Retalhuleu",
                "label_fr": "Retalhuleu"
            },
            {
                "value": 79640,
                "label": "Esquipulas",
                "label_ar": "إسكويبولاس",
                "label_fr": "Esquipulas"
            },
            {
                "value": 79633,
                "label": "Escuintla",
                "label_ar": "اسكوينتلا",
                "label_fr": "Escuintla"
            },
            {
                "value": 79644,
                "label": "Puerto Barrios",
                "label_ar": "بويرتو باريوس",
                "label_fr": "Puerto Barrios"
            },
            {
                "value": 79626,
                "label": "San Antonio Miramar",
                "label_ar": "سان انطونيو ميرامار",
                "label_fr": "San Antonio Miramar"
            }
        ]
    },
    {
        "country": "Marshall Islands",
        "cities": [
            {
                "value": 79652,
                "label": "Majuro",
                "label_ar": "ماجورو",
                "label_fr": "Majuro"
            }
        ]
    },
    {
        "country": "Monaco",
        "cities": [
            {
                "value": 79655,
                "label": "Monte Carlo",
                "label_ar": "مونتي كارلو",
                "label_fr": "monte Carlo"
            },
            {
                "value": 79654,
                "label": "Monaco",
                "label_ar": "موناكو",
                "label_fr": "Monaco"
            }
        ]
    },
    {
        "country": "Anguilla",
        "cities": [
            {
                "value": 79657,
                "label": "George Hill",
                "label_ar": "جورج هيل",
                "label_fr": "George Hill"
            },
            {
                "value": 79656,
                "label": "The Valley",
                "label_ar": "الوادي",
                "label_fr": "La vallée"
            }
        ]
    },
    {
        "country": "Grenada",
        "cities": [
            {
                "value": 79660,
                "label": "Gouyave",
                "label_ar": "جوياف",
                "label_fr": "Gouyave"
            },
            {
                "value": 79659,
                "label": "St. George's",
                "label_ar": "سانت جورج",
                "label_fr": "St. George's"
            },
            {
                "value": 79658,
                "label": "Sauteurs",
                "label_ar": "سوتور",
                "label_fr": "Sauteurs"
            }
        ]
    },
    {
        "country": "Paraguay",
        "cities": [
            {
                "value": 79676,
                "label": "Luque",
                "label_ar": "لوك",
                "label_fr": "Luque"
            },
            {
                "value": 79681,
                "label": "Colonia Mariano Roque Alonso",
                "label_ar": "كولونيا ماريانو روك ألونسو",
                "label_fr": "Colonia Mariano Roque Alonso"
            },
            {
                "value": 79684,
                "label": "La Paz",
                "label_ar": "لاباز",
                "label_fr": "La Paz"
            },
            {
                "value": 79688,
                "label": "Loma Plata",
                "label_ar": "لوما بلاتا",
                "label_fr": "Loma Plata"
            },
            {
                "value": 79685,
                "label": "Fuerte Olimpo",
                "label_ar": "فويرتي أوليمبو",
                "label_fr": "Fuerte Olimpo"
            },
            {
                "value": 79669,
                "label": "Santa Rosa",
                "label_ar": "سانتا روزا",
                "label_fr": "Santa Rosa"
            },
            {
                "value": 79675,
                "label": "San Lorenzo",
                "label_ar": "سان لورينزو",
                "label_fr": "San Lorenzo"
            },
            {
                "value": 79664,
                "label": "Boqueron",
                "label_ar": "بوكيرون",
                "label_fr": "Boqueron"
            },
            {
                "value": 79673,
                "label": "Salto del Guaira",
                "label_ar": "سالتو ديل جويرا",
                "label_fr": "Salto del Guaira"
            },
            {
                "value": 79689,
                "label": "Limpio",
                "label_ar": "ليمبيو",
                "label_fr": "Limpio"
            },
            {
                "value": 79674,
                "label": "San Alberto",
                "label_ar": "سان البرتو",
                "label_fr": "San Alberto"
            },
            {
                "value": 79677,
                "label": "Capiatá",
                "label_ar": "كابياتا",
                "label_fr": "Capiatá"
            },
            {
                "value": 79687,
                "label": "Presidente Franco",
                "label_ar": "بريزيدنتي فرانكو",
                "label_fr": "Presidente Franco"
            },
            {
                "value": 79666,
                "label": "Ayolas",
                "label_ar": "أيولاس",
                "label_fr": "Ayolas"
            },
            {
                "value": 79661,
                "label": "Coronel Oviedo",
                "label_ar": "كورونيل أوفييدو",
                "label_fr": "Coronel Oviedo"
            },
            {
                "value": 79686,
                "label": "Santa Rita",
                "label_ar": "سانتا ريتا",
                "label_fr": "Santa Rita"
            },
            {
                "value": 79667,
                "label": "Fernando de la Mora",
                "label_ar": "فرناندو دي لا مورا",
                "label_fr": "Fernando de la Mora"
            },
            {
                "value": 79662,
                "label": "Yataity",
                "label_ar": "ياتيتي",
                "label_fr": "Yataity"
            },
            {
                "value": 79680,
                "label": "Villa Hayes",
                "label_ar": "فيلا هايز",
                "label_fr": "Villa Hayes"
            },
            {
                "value": 79672,
                "label": "Ciudad del Este",
                "label_ar": "Ciudad del Este",
                "label_fr": "Ciudad del Este"
            },
            {
                "value": 79690,
                "label": "Hohenau",
                "label_ar": "هوهيناو",
                "label_fr": "Hohenau"
            },
            {
                "value": 79691,
                "label": "Independencia",
                "label_ar": "الاستقلال",
                "label_fr": "Independencia"
            },
            {
                "value": 79671,
                "label": "Hernandarias",
                "label_ar": "هيرنانداريا",
                "label_fr": "Hernandarias"
            },
            {
                "value": 79679,
                "label": "Villa Elisa",
                "label_ar": "فيلا إليسا",
                "label_fr": "Villa Elisa"
            },
            {
                "value": 79665,
                "label": "San Juan Bautista",
                "label_ar": "سان خوان باوتيستا",
                "label_fr": "San Juan Bautista"
            },
            {
                "value": 79678,
                "label": "Villarrica",
                "label_ar": "فيلاريكا",
                "label_fr": "Villarrica"
            },
            {
                "value": 79682,
                "label": "Encarnación",
                "label_ar": "إنكارناسيون",
                "label_fr": "Encarnación"
            },
            {
                "value": 79683,
                "label": "Chore",
                "label_ar": "عمل روتيني",
                "label_fr": "Corvée"
            },
            {
                "value": 79668,
                "label": "Nemby",
                "label_ar": "نمبي",
                "label_fr": "Nemby"
            },
            {
                "value": 79663,
                "label": "Asunción",
                "label_ar": "أسونسيون",
                "label_fr": "Asunción"
            },
            {
                "value": 79670,
                "label": "Lambaré",
                "label_ar": "لامبارى",
                "label_fr": "Lambaré"
            }
        ]
    },
    {
        "country": "Montserrat",
        "cities": [
            {
                "value": 79693,
                "label": "Salem",
                "label_ar": "سالم",
                "label_fr": "Salem"
            },
            {
                "value": 79692,
                "label": "Davy Hill",
                "label_ar": "ديفي هيل",
                "label_fr": "Davy Hill"
            }
        ]
    },
    {
        "country": "Turks and Caicos Islands",
        "cities": [
            {
                "value": 79696,
                "label": "Balfour Town",
                "label_ar": "بلدة بلفور",
                "label_fr": "Ville de Balfour"
            },
            {
                "value": 79694,
                "label": "Cockburn Harbour",
                "label_ar": "ميناء كوكبيرن",
                "label_fr": "Cockburn Harbour"
            },
            {
                "value": 79695,
                "label": "Over Back The Damp",
                "label_ar": "على ظهر الرطوبة",
                "label_fr": "Sur le dos de l'humidité"
            }
        ]
    },
    {
        "country": "Antigua and Barbuda",
        "cities": [
            {
                "value": 79703,
                "label": "Parham",
                "label_ar": "بارهام",
                "label_fr": "Parham"
            },
            {
                "value": 79700,
                "label": "Woods",
                "label_ar": "الغابة",
                "label_fr": "les bois"
            },
            {
                "value": 79697,
                "label": "St. John's",
                "label_ar": "سانت جون",
                "label_fr": "St. John's"
            },
            {
                "value": 79701,
                "label": "Cassada Gardens",
                "label_ar": "حدائق كاسادا",
                "label_fr": "Jardins de Cassada"
            },
            {
                "value": 79699,
                "label": "All Saints",
                "label_ar": "جميع القديسين",
                "label_fr": "Tous les saints"
            },
            {
                "value": 79698,
                "label": "Old Road",
                "label_ar": "الطريق القديم",
                "label_fr": "Ancienne route"
            },
            {
                "value": 79702,
                "label": "Codrington",
                "label_ar": "كودرينجتون",
                "label_fr": "Codrington"
            }
        ]
    },
    {
        "country": "Tuvalu",
        "cities": [
            {
                "value": 79704,
                "label": "Funafuti",
                "label_ar": "فونافوتي",
                "label_fr": "Funafuti"
            }
        ]
    },
    {
        "country": "French Polynesia",
        "cities": [
            {
                "value": 79707,
                "label": "Faaa",
                "label_ar": "الفا",
                "label_fr": "Faaa"
            },
            {
                "value": 79706,
                "label": "Mahina",
                "label_ar": "ماهينا",
                "label_fr": "Mahina"
            },
            {
                "value": 79711,
                "label": "Paeau",
                "label_ar": "باو",
                "label_fr": "Paeau"
            },
            {
                "value": 79709,
                "label": "Punaauia",
                "label_ar": "بوناويا",
                "label_fr": "Punaauia"
            },
            {
                "value": 79710,
                "label": "Arue",
                "label_ar": "أرو",
                "label_fr": "Arue"
            },
            {
                "value": 79705,
                "label": "Papeete",
                "label_ar": "بابيتي",
                "label_fr": "Papeete"
            },
            {
                "value": 79708,
                "label": "Pirae",
                "label_ar": "بيراي",
                "label_fr": "Pirae"
            }
        ]
    },
    {
        "country": "Solomon Islands",
        "cities": [
            {
                "value": 79712,
                "label": "Honiara",
                "label_ar": "هونيارا",
                "label_fr": "Honiara"
            }
        ]
    },
    {
        "country": "Vanuatu",
        "cities": [
            {
                "value": 79716,
                "label": "Loltong",
                "label_ar": "لولتونج",
                "label_fr": "Loltong"
            },
            {
                "value": 79715,
                "label": "Level",
                "label_ar": "مستوى",
                "label_fr": "Niveau"
            },
            {
                "value": 79714,
                "label": "Port Vila",
                "label_ar": "بورت فيلا",
                "label_fr": "Port Vila"
            }
        ]
    },
    {
        "country": "Suriname",
        "cities": [
            {
                "value": 79724,
                "label": "Groningen",
                "label_ar": "جرونينجن",
                "label_fr": "Groningen"
            },
            {
                "value": 79719,
                "label": "Onverwacht",
                "label_ar": "أونفيرواخت",
                "label_fr": "Onverwacht"
            },
            {
                "value": 79720,
                "label": "Totness",
                "label_ar": "توتّر",
                "label_fr": "Totness"
            },
            {
                "value": 79722,
                "label": "Moengo",
                "label_ar": "موينغو",
                "label_fr": "Moengo"
            },
            {
                "value": 79717,
                "label": "Friendship",
                "label_ar": "صداقة",
                "label_fr": "Relation amicale"
            },
            {
                "value": 79721,
                "label": "Nieuw Amsterdam",
                "label_ar": "نيو أمستردام",
                "label_fr": "Nieuw Amsterdam"
            },
            {
                "value": 79725,
                "label": "Brownsweg",
                "label_ar": "Brownsweg",
                "label_fr": "Brownsweg"
            },
            {
                "value": 79718,
                "label": "Paramaribo",
                "label_ar": "باراماريبو",
                "label_fr": "Paramaribo"
            },
            {
                "value": 79723,
                "label": "Botopasi",
                "label_ar": "بوتوباسي",
                "label_fr": "Botopasi"
            }
        ]
    },
    {
        "country": "Cook Islands",
        "cities": [
            {
                "value": 79726,
                "label": "Avarua",
                "label_ar": "أفاروا",
                "label_fr": "Avarua"
            },
            {
                "value": 79727,
                "label": "Titikaveka",
                "label_ar": "تيتيكافيكا",
                "label_fr": "Titikaveka"
            }
        ]
    },
    {
        "country": "Kiribati",
        "cities": [
            {
                "value": 79728,
                "label": "Umwa Village",
                "label_ar": "قرية اموة",
                "label_fr": "Village d'Umwa"
            }
        ]
    },
    {
        "country": "Niue",
        "cities": [
            {
                "value": 79730,
                "label": "Alofi",
                "label_ar": "ألوفي",
                "label_fr": "Alofi"
            }
        ]
    },
    {
        "country": "Tonga",
        "cities": [
            {
                "value": 79732,
                "label": "Neiafu",
                "label_ar": "نيافو",
                "label_fr": "Neiafu"
            },
            {
                "value": 79733,
                "label": "Kanokupolu",
                "label_ar": "كانوكوبولو",
                "label_fr": "Kanokupolu"
            }
        ]
    },
    {
        "country": "French Southern Territories",
        "cities": [
            {
                "value": 79734,
                "label": "Port-aux-Francais",
                "label_ar": "بورت أو فرانسيه",
                "label_fr": "Port-aux-Francais"
            }
        ]
    },
    {
        "country": "Norfolk Island",
        "cities": [
            {
                "value": 79735,
                "label": "Kingston",
                "label_ar": "كينغستون",
                "label_fr": "Kingston"
            }
        ]
    },
    {
        "country": "Turkmenistan",
        "cities": [
            {
                "value": 79737,
                "label": "Darganata",
                "label_ar": "دارجاناتا",
                "label_fr": "Darganata"
            },
            {
                "value": 79738,
                "label": "Ashgabat",
                "label_ar": "عشق أباد",
                "label_fr": "Achgabat"
            }
        ]
    },
    {
        "country": "Pitcairn Islands",
        "cities": [
            {
                "value": 79739,
                "label": "Adamstown",
                "label_ar": "ادمستاون",
                "label_fr": "Adamstown"
            }
        ]
    },
    {
        "country": "San Marino",
        "cities": [
            {
                "value": 79743,
                "label": "Serravalle",
                "label_ar": "سيرافالي",
                "label_fr": "Serravalle"
            },
            {
                "value": 79744,
                "label": "Acquaviva",
                "label_ar": "أكوافيفا",
                "label_fr": "Acquaviva"
            },
            {
                "value": 79740,
                "label": "Falciano",
                "label_ar": "فالشيانو",
                "label_fr": "Falciano"
            },
            {
                "value": 79741,
                "label": "Fiorentino",
                "label_ar": "فيورنتينو",
                "label_fr": "Fiorentino"
            },
            {
                "value": 79742,
                "label": "San Marino",
                "label_ar": "سان مارينو",
                "label_fr": "Saint Marin"
            }
        ]
    },
    {
        "country": "Åland",
        "cities": [
            {
                "value": 79746,
                "label": "Eckerö",
                "label_ar": "إيكيرو",
                "label_fr": "Eckerö"
            },
            {
                "value": 79753,
                "label": "Hammarland",
                "label_ar": "هامرلاند",
                "label_fr": "Hammarland"
            },
            {
                "value": 79752,
                "label": "Saltvik",
                "label_ar": "سالتفيك",
                "label_fr": "Saltvik"
            },
            {
                "value": 79747,
                "label": "Lemland",
                "label_ar": "ليملاند",
                "label_fr": "Lemland"
            },
            {
                "value": 79745,
                "label": "Mariehamn",
                "label_ar": "ماريهامن",
                "label_fr": "Mariehamn"
            },
            {
                "value": 79749,
                "label": "Godby",
                "label_ar": "جودبي",
                "label_fr": "Godby"
            },
            {
                "value": 79750,
                "label": "Gottby",
                "label_ar": "جوتبي",
                "label_fr": "Gottby"
            },
            {
                "value": 79751,
                "label": "Jurmo",
                "label_ar": "جورمو",
                "label_fr": "Jurmo"
            },
            {
                "value": 79748,
                "label": "OEdkarby",
                "label_ar": "OEdkarby",
                "label_fr": "OEdkarby"
            }
        ]
    },
    {
        "country": "Faroe Islands",
        "cities": [
            {
                "value": 79756,
                "label": "Leirvik",
                "label_ar": "ليرفيك",
                "label_fr": "Leirvik"
            },
            {
                "value": 79761,
                "label": "Klaksvík",
                "label_ar": "كلاكسفيك",
                "label_fr": "Klaksvík"
            },
            {
                "value": 79763,
                "label": "Signabour",
                "label_ar": "Signabour",
                "label_fr": "Signabour"
            },
            {
                "value": 79757,
                "label": "Saltangara",
                "label_ar": "سالتانجارا",
                "label_fr": "Saltangara"
            },
            {
                "value": 79755,
                "label": "Tórshavn",
                "label_ar": "تورشفان",
                "label_fr": "Tórshavn"
            },
            {
                "value": 79764,
                "label": "Glyvrar",
                "label_ar": "جليفرار",
                "label_fr": "Glyvrar"
            },
            {
                "value": 79762,
                "label": "Innan Glyvur",
                "label_ar": "إنان جليفور",
                "label_fr": "Innan Glyvur"
            },
            {
                "value": 79758,
                "label": "Hoyvík",
                "label_ar": "هويفيك",
                "label_fr": "Hoyvík"
            },
            {
                "value": 79760,
                "label": "Hvalba",
                "label_ar": "هفالبا",
                "label_fr": "Hvalba"
            },
            {
                "value": 79754,
                "label": "Strendur",
                "label_ar": "ستريندور",
                "label_fr": "Strendur"
            },
            {
                "value": 79759,
                "label": "Argir",
                "label_ar": "ارجير",
                "label_fr": "Argir"
            }
        ]
    },
    {
        "country": "Svalbard and Jan Mayen",
        "cities": [
            {
                "value": 79765,
                "label": "Longyearbyen",
                "label_ar": "لونجييربين",
                "label_fr": "Longyearbyen"
            }
        ]
    },
    {
        "country": "Cocos [Keeling] Islands",
        "cities": [
            {
                "value": 79766,
                "label": "West Island",
                "label_ar": "ويست آيلاند",
                "label_fr": "West Island"
            }
        ]
    },
    {
        "country": "Nauru",
        "cities": [
            {
                "value": 79767,
                "label": "Anabar",
                "label_ar": "أنابار",
                "label_fr": "Anabar"
            }
        ]
    },
    {
        "country": "South Georgia and the South Sandwich Islands",
        "cities": [
            {
                "value": 79768,
                "label": "Grytviken",
                "label_ar": "جريتفيكن",
                "label_fr": "Grytviken"
            }
        ]
    },
    {
        "country": "Sint Maarten",
        "cities": [
            {
                "value": 79770,
                "label": "Philipsburg",
                "label_ar": "فيليبسبورج",
                "label_fr": "Philipsburg"
            }
        ]
    },
    {
        "country": "Guinea-Bissau",
        "cities": [
            {
                "value": 79773,
                "label": "Bissau",
                "label_ar": "بيساو",
                "label_fr": "Bissau"
            },
            {
                "value": 79772,
                "label": "Cacheu",
                "label_ar": "كاشيو",
                "label_fr": "Cacheu"
            }
        ]
    },
    {
        "country": "Saint Martin",
        "cities": [
            {
                "value": 79775,
                "label": "Marigot",
                "label_ar": "ماريجوت",
                "label_fr": "Marigot"
            },
            {
                "value": 79776,
                "label": "Anse Marcel",
                "label_ar": "آنس مارسيل",
                "label_fr": "Anse Marcel"
            }
        ]
    },
    {
        "country": "Saint Vincent and the Grenadines",
        "cities": [
            {
                "value": 79777,
                "label": "Kingstown",
                "label_ar": "كينغستاون",
                "label_fr": "Kingstown"
            },
            {
                "value": 79779,
                "label": "Union",
                "label_ar": "اتحاد",
                "label_fr": "syndicat"
            },
            {
                "value": 79778,
                "label": "Georgetown",
                "label_ar": "جورج تاون",
                "label_fr": "Georgetown"
            }
        ]
    },
    {
        "country": "Saint-Barthélemy",
        "cities": [
            {
                "value": 79782,
                "label": "Grand Cul-de-Sac",
                "label_ar": "جراند كول دي ساك",
                "label_fr": "Grand Cul-de-Sac"
            },
            {
                "value": 79783,
                "label": "Gustavia",
                "label_ar": "غوستافيا",
                "label_fr": "Gustavia"
            }
        ]
    },
    {
        "country": "Dominica",
        "cities": [
            {
                "value": 79786,
                "label": "Roseau",
                "label_ar": "روسو",
                "label_fr": "Roseau"
            },
            {
                "value": 79787,
                "label": "Marigot",
                "label_ar": "ماريجوت",
                "label_fr": "Marigot"
            },
            {
                "value": 79785,
                "label": "Berekua",
                "label_ar": "بيريكوا",
                "label_fr": "Berekua"
            }
        ]
    },
    {
        "country": "São Tomé and Príncipe",
        "cities": [
            {
                "value": 79789,
                "label": "São Tomé",
                "label_ar": "ساو تومي",
                "label_fr": "São Tomé"
            },
            {
                "value": 79788,
                "label": "Neves",
                "label_ar": "نيفيز",
                "label_fr": "Neves"
            }
        ]
    },
    {
        "country": "Falkland Islands",
        "cities": [
            {
                "value": 79791,
                "label": "Stanley",
                "label_ar": "ستانلي",
                "label_fr": "Stanley"
            }
        ]
    },
    {
        "country": "Northern Mariana Islands",
        "cities": [
            {
                "value": 79792,
                "label": "Saipan",
                "label_ar": "سايبان",
                "label_fr": "Saipan"
            }
        ]
    },
    {
        "country": "East Timor",
        "cities": [
            {
                "value": 79793,
                "label": "Dili",
                "label_ar": "ديلي",
                "label_fr": "Dili"
            }
        ]
    },
    {
        "country": "Bonaire",
        "cities": [
            {
                "value": 79796,
                "label": "Dorp Nikiboko",
                "label_ar": "دورب نيكيبوكو",
                "label_fr": "Dorp Nikiboko"
            },
            {
                "value": 79795,
                "label": "Kralendijk",
                "label_ar": "كرالينديك",
                "label_fr": "Kralendijk"
            }
        ]
    },
    {
        "country": "American Samoa",
        "cities": [
            {
                "value": 79797,
                "label": "American Samoa",
                "label_ar": "ساموا الأمريكية",
                "label_fr": "Samoa américaines"
            },
            {
                "value": 79798,
                "label": "Pago Pago",
                "label_ar": "باجو باجو",
                "label_fr": "Pago Pago"
            }
        ]
    },
    {
        "country": "Federated States of Micronesia",
        "cities": [
            {
                "value": 79799,
                "label": "Yap",
                "label_ar": "ياب",
                "label_fr": "Japper"
            }
        ]
    },
    {
        "country": "Guyana",
        "cities": [
            {
                "value": 79801,
                "label": "Georgetown",
                "label_ar": "جورج تاون",
                "label_fr": "Georgetown"
            },
            {
                "value": 79802,
                "label": "New Amsterdam",
                "label_ar": "أمستردام الجديدة",
                "label_fr": "New Amsterdam"
            },
            {
                "value": 79803,
                "label": "Linden",
                "label_ar": "الزيزفون",
                "label_fr": "Tilleul"
            }
        ]
    },
    {
        "country": "Honduras",
        "cities": [
            {
                "value": 79805,
                "label": "San Pedro Sula",
                "label_ar": "سان بيدرو سولا",
                "label_fr": "San Pedro Sula"
            },
            {
                "value": 79821,
                "label": "Sula",
                "label_ar": "سولا",
                "label_fr": "Sula"
            },
            {
                "value": 79810,
                "label": "Coxen Hole",
                "label_ar": "كوكسين هول",
                "label_fr": "Trou de Coxen"
            },
            {
                "value": 79822,
                "label": "El Barro",
                "label_ar": "البارو",
                "label_fr": "El Barro"
            },
            {
                "value": 79817,
                "label": "Comayagua",
                "label_ar": "كوماياغوا",
                "label_fr": "Comayagua"
            },
            {
                "value": 79818,
                "label": "Nacaome",
                "label_ar": "ناكومي",
                "label_fr": "Nacaome"
            },
            {
                "value": 79823,
                "label": "El Paraiso",
                "label_ar": "إل بارايسو",
                "label_fr": "El Paraiso"
            },
            {
                "value": 79814,
                "label": "La Hacienda",
                "label_ar": "لا هاسيندا",
                "label_fr": "La Hacienda"
            },
            {
                "value": 79812,
                "label": "San Antonio de Flores",
                "label_ar": "سان انطونيو دي فلوريس",
                "label_fr": "San Antonio de Flores"
            },
            {
                "value": 79807,
                "label": "La Ceiba",
                "label_ar": "لا سيبا",
                "label_fr": "La Ceiba"
            },
            {
                "value": 79816,
                "label": "Choloma",
                "label_ar": "تشولوما",
                "label_fr": "Cholome"
            },
            {
                "value": 79804,
                "label": "Tegucigalpa",
                "label_ar": "تيغوسيغالبا",
                "label_fr": "Tegucigalpa"
            },
            {
                "value": 79811,
                "label": "El Progreso",
                "label_ar": "البروغريسو",
                "label_fr": "El Progreso"
            },
            {
                "value": 79820,
                "label": "Puerto Lempira",
                "label_ar": "بويرتو لمبيرا",
                "label_fr": "Puerto Lempira"
            },
            {
                "value": 79819,
                "label": "Pinalejo",
                "label_ar": "بيناليخو",
                "label_fr": "Pinalejo"
            },
            {
                "value": 79808,
                "label": "Santa Barbara",
                "label_ar": "سانتا باربارا",
                "label_fr": "Santa Barbara"
            },
            {
                "value": 79815,
                "label": "Comayaguela",
                "label_ar": "كوماياغويلا",
                "label_fr": "Comayaguela"
            },
            {
                "value": 79813,
                "label": "Piraera",
                "label_ar": "بييرارا",
                "label_fr": "Piraera"
            },
            {
                "value": 79806,
                "label": "Morazan",
                "label_ar": "مورازان",
                "label_fr": "Morazan"
            },
            {
                "value": 79809,
                "label": "Copán",
                "label_ar": "كوبان",
                "label_fr": "Copán"
            }
        ]
    },
    {
        "country": "Nicaragua",
        "cities": [
            {
                "value": 79830,
                "label": "Granada",
                "label_ar": "غرناطة",
                "label_fr": "Grenade"
            },
            {
                "value": 79834,
                "label": "Masaya",
                "label_ar": "مسايا",
                "label_fr": "Masaya"
            },
            {
                "value": 79825,
                "label": "León",
                "label_ar": "ليون",
                "label_fr": "León"
            },
            {
                "value": 79835,
                "label": "Matagalpa",
                "label_ar": "ماتاجالبا",
                "label_fr": "Matagalpa"
            },
            {
                "value": 79831,
                "label": "Bluefields",
                "label_ar": "بلوفيلدز",
                "label_fr": "Bluefields"
            },
            {
                "value": 79832,
                "label": "Chinandega",
                "label_ar": "تشينانديجا",
                "label_fr": "Chinandega"
            },
            {
                "value": 79824,
                "label": "Managua",
                "label_ar": "ماناغوا",
                "label_fr": "Managua"
            },
            {
                "value": 79828,
                "label": "Ocotal",
                "label_ar": "أوكوتال",
                "label_fr": "Ocotal"
            },
            {
                "value": 79837,
                "label": "El Panama",
                "label_ar": "بنما",
                "label_fr": "El Panama"
            },
            {
                "value": 79833,
                "label": "Esteli",
                "label_ar": "إستيلي",
                "label_fr": "Esteli"
            },
            {
                "value": 79829,
                "label": "San Juan del Sur",
                "label_ar": "سان خوان ديل سور",
                "label_fr": "San Juan del Sur"
            },
            {
                "value": 79826,
                "label": "Los Arados",
                "label_ar": "لوس أرادوس",
                "label_fr": "Los Arados"
            },
            {
                "value": 79836,
                "label": "Jinotega",
                "label_ar": "جينوتيغا",
                "label_fr": "Jinotega"
            },
            {
                "value": 79827,
                "label": "Rivas",
                "label_ar": "ريفاس",
                "label_fr": "Rivas"
            }
        ]
    },
    {
        "country": "El Salvador",
        "cities": [
            {
                "value": 79863,
                "label": "Ahuachapan",
                "label_ar": "أهواشابان",
                "label_fr": "Ahuachapan"
            },
            {
                "value": 79849,
                "label": "Zaragoza",
                "label_ar": "سرقسطة",
                "label_fr": "Saragosse"
            },
            {
                "value": 79858,
                "label": "Usulutan",
                "label_ar": "أوسولوتان",
                "label_fr": "Usuloutan"
            },
            {
                "value": 79838,
                "label": "San Salvador",
                "label_ar": "سان سلفادور",
                "label_fr": "San Salvador"
            },
            {
                "value": 79840,
                "label": "La Libertad",
                "label_ar": "لا ليبرتاد",
                "label_fr": "La Libertad"
            },
            {
                "value": 79839,
                "label": "San Miguel",
                "label_ar": "سان ميغيل",
                "label_fr": "San Miguel"
            },
            {
                "value": 79862,
                "label": "Apaneca",
                "label_ar": "أبانيكا",
                "label_fr": "Apaneca"
            },
            {
                "value": 79857,
                "label": "San Marcos",
                "label_ar": "سان ماركوس",
                "label_fr": "San Marcos"
            },
            {
                "value": 79860,
                "label": "Delgado",
                "label_ar": "ديلجادو",
                "label_fr": "Delgado"
            },
            {
                "value": 79859,
                "label": "Ozatlan",
                "label_ar": "أوزاتلان",
                "label_fr": "Ozatlan"
            },
            {
                "value": 79850,
                "label": "Santa Ana",
                "label_ar": "سانتا آنا",
                "label_fr": "Santa Ana"
            },
            {
                "value": 79847,
                "label": "Nuevo Cuscatlan",
                "label_ar": "نويفو كوسكاتلان",
                "label_fr": "Nuevo Cuscatlan"
            },
            {
                "value": 79856,
                "label": "Coatepeque",
                "label_ar": "كوتيبيك",
                "label_fr": "Coatepeque"
            },
            {
                "value": 79845,
                "label": "Botoncillal El Botoncillo",
                "label_ar": "Botoncillal El Botoncillo",
                "label_fr": "Botoncillal El Botoncillo"
            },
            {
                "value": 79841,
                "label": "Ilopango",
                "label_ar": "إيلوبانغو",
                "label_fr": "Ilopango"
            },
            {
                "value": 79865,
                "label": "Lourdes",
                "label_ar": "لورد",
                "label_fr": "Lourdes"
            },
            {
                "value": 79842,
                "label": "Antiguo Cuscatlan",
                "label_ar": "أنتيجو كوسكاتلان",
                "label_fr": "Antiguo Cuscatlan"
            },
            {
                "value": 79853,
                "label": "Santiago Texacuangos",
                "label_ar": "سانتياغو تكساكوانجوس",
                "label_fr": "Santiago Texacuangos"
            },
            {
                "value": 79843,
                "label": "Soyapango",
                "label_ar": "سويابانجو",
                "label_fr": "Soyapango"
            },
            {
                "value": 79846,
                "label": "Mejicanos",
                "label_ar": "Mejicanos",
                "label_fr": "Mejicanos"
            },
            {
                "value": 79864,
                "label": "Sonsonate",
                "label_ar": "سونسونيت",
                "label_fr": "Sonsonate"
            },
            {
                "value": 79866,
                "label": "Colon",
                "label_ar": "القولون",
                "label_fr": "Côlon"
            },
            {
                "value": 79852,
                "label": "Colonia Escalon",
                "label_ar": "كولونيا اسكالون",
                "label_fr": "Colonia Escalon"
            },
            {
                "value": 79848,
                "label": "San Jose Villanueva",
                "label_ar": "سان خوسيه فيلانويفا",
                "label_fr": "San Jose Villanueva"
            },
            {
                "value": 79844,
                "label": "Santa Tecla",
                "label_ar": "سانتا تيكلا",
                "label_fr": "Santa Tecla"
            },
            {
                "value": 79851,
                "label": "Gigante",
                "label_ar": "جيغانتي",
                "label_fr": "Gigante"
            },
            {
                "value": 79861,
                "label": "Guazapa",
                "label_ar": "Guazapa",
                "label_fr": "Guazapa"
            },
            {
                "value": 79854,
                "label": "Ayutuxtepeque",
                "label_ar": "أيوتوكستيبيك",
                "label_fr": "Ayutuxtepeque"
            },
            {
                "value": 79855,
                "label": "Apopa",
                "label_ar": "أبوبا",
                "label_fr": "Apopa"
            },
            {
                "value": 79867,
                "label": "Cuscatancingo",
                "label_ar": "Cuscatancingo",
                "label_fr": "Cuscatancingo"
            }
        ]
    },
    {
        "country": "Andorra",
        "cities": [
            {
                "value": 79878,
                "label": "Soldeu",
                "label_ar": "سولديو",
                "label_fr": "Soldeu"
            },
            {
                "value": 79877,
                "label": "Llorts",
                "label_ar": "يضحك",
                "label_fr": "Llorts"
            },
            {
                "value": 79879,
                "label": "Sispony",
                "label_ar": "سيسبوني",
                "label_fr": "Sispony"
            },
            {
                "value": 79871,
                "label": "Engordany",
                "label_ar": "إنجورداني",
                "label_fr": "Engordany"
            },
            {
                "value": 79868,
                "label": "Andorra la Vella",
                "label_ar": "أندورا لا فيلا",
                "label_fr": "Andorre-la-Vieille"
            },
            {
                "value": 79869,
                "label": "Sant Juliàde Lòria",
                "label_ar": "سانت جولياد لوريا",
                "label_fr": "Sant Juliàde Lòria"
            },
            {
                "value": 79876,
                "label": "Canillo",
                "label_ar": "كانيلو",
                "label_fr": "Canillo"
            },
            {
                "value": 79872,
                "label": "Encamp",
                "label_ar": "إنكامب",
                "label_fr": "Camper"
            },
            {
                "value": 79873,
                "label": "Ordino",
                "label_ar": "أوردينو",
                "label_fr": "Ordino"
            },
            {
                "value": 79870,
                "label": "Escaldes-Engordany",
                "label_ar": "إسكالدس إنجورداني",
                "label_fr": "Escaldes-Engordany"
            },
            {
                "value": 79874,
                "label": "La Massana",
                "label_ar": "لا ماسانا",
                "label_fr": "La Massana"
            },
            {
                "value": 79875,
                "label": "Santa Coloma",
                "label_ar": "سانتا كولوما",
                "label_fr": "Santa Coloma"
            }
        ]
    },
    {
        "country": "Myanmar [Burma]",
        "cities": [
            {
                "value": 79882,
                "label": "Kyauktada",
                "label_ar": "كياوكتادا",
                "label_fr": "Kyauktada"
            },
            {
                "value": 79883,
                "label": "Inya",
                "label_ar": "إينيا",
                "label_fr": "Inya"
            },
            {
                "value": 79884,
                "label": "Mandalay",
                "label_ar": "ماندالاي",
                "label_fr": "Mandalay"
            },
            {
                "value": 79880,
                "label": "Hlaing",
                "label_ar": "هلينج",
                "label_fr": "Hlaing"
            },
            {
                "value": 79881,
                "label": "Yangon",
                "label_ar": "يانجون",
                "label_fr": "Yangon"
            },
            {
                "value": 79885,
                "label": "Wagan",
                "label_ar": "واغان",
                "label_fr": "Wagan"
            }
        ]
    },
    {
        "country": "Sri Lanka",
        "cities": [
            {
                "value": 79914,
                "label": "Nattandiya Town",
                "label_ar": "بلدة ناتانديا",
                "label_fr": "Ville de Nattandiya"
            },
            {
                "value": 79913,
                "label": "Gampaha",
                "label_ar": "جامباها",
                "label_fr": "Gampaha"
            },
            {
                "value": 79903,
                "label": "Dehiwala-Mount Lavinia",
                "label_ar": "دهيوالا ماونت لافينيا",
                "label_fr": "Dehiwala-Mount Lavinia"
            },
            {
                "value": 79897,
                "label": "Sri Jayewardenepura Kotte",
                "label_ar": "سري جايواردنابورا كوتي",
                "label_fr": "Sri Jayewardenepura Kotte"
            },
            {
                "value": 79894,
                "label": "Kaduwela",
                "label_ar": "كادويلا",
                "label_fr": "Kaduwela"
            },
            {
                "value": 79910,
                "label": "Kandana",
                "label_ar": "كندنا",
                "label_fr": "Kandana"
            },
            {
                "value": 79896,
                "label": "Nugegoda",
                "label_ar": "نوجيجودا",
                "label_fr": "Nugegoda"
            },
            {
                "value": 79890,
                "label": "Makola South",
                "label_ar": "جنوب ماكولا",
                "label_fr": "Makola Sud"
            },
            {
                "value": 79907,
                "label": "Moratuwa",
                "label_ar": "موراتوا",
                "label_fr": "Moratuwa"
            },
            {
                "value": 79918,
                "label": "Talapathpitiya",
                "label_ar": "Talapathpitiya",
                "label_fr": "Talapathpitiya"
            },
            {
                "value": 79908,
                "label": "Badulla",
                "label_ar": "بادولا",
                "label_fr": "Badulla"
            },
            {
                "value": 79892,
                "label": "Maharagama",
                "label_ar": "ماهاراغاما",
                "label_fr": "Maharagama"
            },
            {
                "value": 79912,
                "label": "Rajagiriya",
                "label_ar": "راجاجيريا",
                "label_fr": "Rajagiriya"
            },
            {
                "value": 79900,
                "label": "Orugodawatta",
                "label_ar": "أوروغوداواتا",
                "label_fr": "Orugodawatta"
            },
            {
                "value": 79909,
                "label": "Padukka",
                "label_ar": "بادوكا",
                "label_fr": "Padukka"
            },
            {
                "value": 79905,
                "label": "Negombo",
                "label_ar": "نيجومبو",
                "label_fr": "Negombo"
            },
            {
                "value": 79915,
                "label": "Matale",
                "label_ar": "ماتالي",
                "label_fr": "Matale"
            },
            {
                "value": 79921,
                "label": "Gangodawila North",
                "label_ar": "جانجوداويلا الشمالية",
                "label_fr": "Gangodawila Nord"
            },
            {
                "value": 79886,
                "label": "Colombo",
                "label_ar": "كولومبو",
                "label_fr": "Colombo"
            },
            {
                "value": 79916,
                "label": "Peradeniya",
                "label_ar": "بيرادينيا",
                "label_fr": "Peradeniya"
            },
            {
                "value": 79902,
                "label": "Piliyandala",
                "label_ar": "بيلياندالا",
                "label_fr": "Piliyandala"
            },
            {
                "value": 79898,
                "label": "Homagama",
                "label_ar": "Homagama",
                "label_fr": "Homagama"
            },
            {
                "value": 79911,
                "label": "Hekitta",
                "label_ar": "هيكيتا",
                "label_fr": "Hekitta"
            },
            {
                "value": 79904,
                "label": "Ragama",
                "label_ar": "راجاما",
                "label_fr": "Ragama"
            },
            {
                "value": 79906,
                "label": "Boralesgamuwa South",
                "label_ar": "جنوب بورليسغاموا",
                "label_fr": "Boralesgamuwa Sud"
            },
            {
                "value": 79888,
                "label": "Kandy",
                "label_ar": "كاندي",
                "label_fr": "Kandy"
            },
            {
                "value": 79919,
                "label": "Katunayaka",
                "label_ar": "كاتوناياكا",
                "label_fr": "Katunayaka"
            },
            {
                "value": 79920,
                "label": "Kelaniya",
                "label_ar": "كيلانيا",
                "label_fr": "Kelaniya"
            },
            {
                "value": 79887,
                "label": "Katubedda",
                "label_ar": "كاتوبيدا",
                "label_fr": "Katubedda"
            },
            {
                "value": 79889,
                "label": "Pannipitiya",
                "label_ar": "بانيبيتيا",
                "label_fr": "Pannipitiya"
            },
            {
                "value": 79922,
                "label": "Biyagama",
                "label_ar": "بياغاما",
                "label_fr": "Biyagama"
            },
            {
                "value": 79917,
                "label": "Eppawala",
                "label_ar": "اباوالا",
                "label_fr": "Eppawala"
            },
            {
                "value": 79891,
                "label": "Wattala",
                "label_ar": "واتالا",
                "label_fr": "Wattala"
            },
            {
                "value": 79893,
                "label": "Wellampitiya",
                "label_ar": "ويلامبيتيا",
                "label_fr": "Wellampitiya"
            },
            {
                "value": 79899,
                "label": "Dehiwala",
                "label_ar": "دهيوالا",
                "label_fr": "Dehiwala"
            },
            {
                "value": 79901,
                "label": "Kohuwala",
                "label_ar": "كوهوالا",
                "label_fr": "Kohuwala"
            },
            {
                "value": 79895,
                "label": "Battaramulla South",
                "label_ar": "باتارامولا الجنوبية",
                "label_fr": "Battaramulla Sud"
            }
        ]
    },
    {
        "country": "Haiti",
        "cities": [
            {
                "value": 79927,
                "label": "Petionville",
                "label_ar": "بيتيونفيل",
                "label_fr": "Pétionville"
            },
            {
                "value": 79926,
                "label": "Carrefour",
                "label_ar": "كارفور",
                "label_fr": "Carrefour"
            },
            {
                "value": 79932,
                "label": "Turgeau",
                "label_ar": "تورغو",
                "label_fr": "Turgeau"
            },
            {
                "value": 79933,
                "label": "Duverger",
                "label_ar": "دوفيرجر",
                "label_fr": "Duverger"
            },
            {
                "value": 79929,
                "label": "Jacmel",
                "label_ar": "جاكميل",
                "label_fr": "Jacmel"
            },
            {
                "value": 79931,
                "label": "Prince",
                "label_ar": "أمير",
                "label_fr": "Prince"
            },
            {
                "value": 79924,
                "label": "Cap-Haïtien",
                "label_ar": "كاب هايتيان",
                "label_fr": "Cap-Haïtien"
            },
            {
                "value": 79928,
                "label": "Moise",
                "label_ar": "مويس",
                "label_fr": "Moise"
            },
            {
                "value": 79930,
                "label": "Masseau",
                "label_ar": "ماسو",
                "label_fr": "Masseau"
            },
            {
                "value": 79923,
                "label": "Port-au-Prince",
                "label_ar": "بورت أو برنس",
                "label_fr": "Port-au-Prince"
            },
            {
                "value": 79925,
                "label": "Delmar",
                "label_ar": "ديل مار",
                "label_fr": "Delmar"
            }
        ]
    },
    {
        "country": "Trinidad and Tobago",
        "cities": [
            {
                "value": 79936,
                "label": "Princes Town",
                "label_ar": "مدينة الأمراء",
                "label_fr": "Princes Town"
            },
            {
                "value": 79958,
                "label": "Morvant",
                "label_ar": "مورفانت",
                "label_fr": "Morvant"
            },
            {
                "value": 79968,
                "label": "Saint Augustine",
                "label_ar": "القديس أوغسطين",
                "label_fr": "Saint Augustin"
            },
            {
                "value": 79960,
                "label": "Saint Clair",
                "label_ar": "سانت كلير",
                "label_fr": "Saint Clair"
            },
            {
                "value": 79948,
                "label": "Cunupia",
                "label_ar": "كونوبيا",
                "label_fr": "Cunupia"
            },
            {
                "value": 79967,
                "label": "Santa Cruz",
                "label_ar": "سانتا كروز",
                "label_fr": "Santa Cruz"
            },
            {
                "value": 79964,
                "label": "Caroni",
                "label_ar": "كاروني",
                "label_fr": "Caroni"
            },
            {
                "value": 79937,
                "label": "Piarco",
                "label_ar": "بياركو",
                "label_fr": "Piarco"
            },
            {
                "value": 79975,
                "label": "Freeport",
                "label_ar": "ميناء حر",
                "label_fr": "Freeport"
            },
            {
                "value": 79949,
                "label": "Curepe",
                "label_ar": "كوريبي",
                "label_fr": "Curepe"
            },
            {
                "value": 79945,
                "label": "Diego Martin",
                "label_ar": "دييغو مارتن",
                "label_fr": "Diego Martin"
            },
            {
                "value": 79969,
                "label": "Golden Lane",
                "label_ar": "جولدن لين",
                "label_fr": "Golden Lane"
            },
            {
                "value": 79950,
                "label": "Roxborough",
                "label_ar": "روكسبورو",
                "label_fr": "Roxborough"
            },
            {
                "value": 79962,
                "label": "Carenage",
                "label_ar": "كاريناج",
                "label_fr": "Carenage"
            },
            {
                "value": 79977,
                "label": "Sangre Grande",
                "label_ar": "سانجر غراندي",
                "label_fr": "Sangre Grande"
            },
            {
                "value": 79943,
                "label": "Debe",
                "label_ar": "ديبي",
                "label_fr": "Debe"
            },
            {
                "value": 79980,
                "label": "Petit Valley",
                "label_ar": "وادي بيتي",
                "label_fr": "Petite Vallée"
            },
            {
                "value": 79942,
                "label": "Fyzabad",
                "label_ar": "فيزاباد",
                "label_fr": "Fyzabad"
            },
            {
                "value": 79974,
                "label": "Valsayn",
                "label_ar": "فالسين",
                "label_fr": "Valsayn"
            },
            {
                "value": 79941,
                "label": "Maraval",
                "label_ar": "مارفال",
                "label_fr": "Maraval"
            },
            {
                "value": 79954,
                "label": "California",
                "label_ar": "كاليفورنيا",
                "label_fr": "Californie"
            },
            {
                "value": 79966,
                "label": "Tunapuna",
                "label_ar": "تونابونا",
                "label_fr": "Tunapuna"
            },
            {
                "value": 79972,
                "label": "Saint James",
                "label_ar": "جيمس قديس",
                "label_fr": "Saint James"
            },
            {
                "value": 79935,
                "label": "San Fernando",
                "label_ar": "سان فرناندو",
                "label_fr": "San Fernando"
            },
            {
                "value": 79956,
                "label": "Siparia",
                "label_ar": "سيباريا",
                "label_fr": "Siparia"
            },
            {
                "value": 79944,
                "label": "Couva",
                "label_ar": "كوفا",
                "label_fr": "Couva"
            },
            {
                "value": 79963,
                "label": "Ward of Tacarigua",
                "label_ar": "وارد تاكاريجوا",
                "label_fr": "Quartier de Tacarigua"
            },
            {
                "value": 79961,
                "label": "Laventille",
                "label_ar": "لافينتيل",
                "label_fr": "Laventille"
            },
            {
                "value": 79946,
                "label": "Chaguanas",
                "label_ar": "شاغوانا",
                "label_fr": "Chaguanas"
            },
            {
                "value": 79970,
                "label": "Scarborough",
                "label_ar": "سكاربورو",
                "label_fr": "Scarborough"
            },
            {
                "value": 79953,
                "label": "Saint Joseph",
                "label_ar": "القديس يوسف",
                "label_fr": "Saint Joseph"
            },
            {
                "value": 79951,
                "label": "San Juan",
                "label_ar": "سان خوان",
                "label_fr": "San Juan"
            },
            {
                "value": 79955,
                "label": "Marabella",
                "label_ar": "مارابيلا",
                "label_fr": "Marabella"
            },
            {
                "value": 79957,
                "label": "Gasparillo",
                "label_ar": "جاسباريلو",
                "label_fr": "Gasparillo"
            },
            {
                "value": 79938,
                "label": "Rio Claro",
                "label_ar": "ريو كلارو",
                "label_fr": "Rio Claro"
            },
            {
                "value": 79981,
                "label": "El Dorado",
                "label_ar": "الدورادو",
                "label_fr": "El Dorado"
            },
            {
                "value": 79939,
                "label": "Port of Spain",
                "label_ar": "ميناء اسبانيا",
                "label_fr": "Port d'Espagne"
            },
            {
                "value": 79971,
                "label": "Moriah",
                "label_ar": "موريا",
                "label_fr": "Moriah"
            },
            {
                "value": 79978,
                "label": "Cumuto",
                "label_ar": "كوموتو",
                "label_fr": "Cumuto"
            },
            {
                "value": 79982,
                "label": "Phoenix Park",
                "label_ar": "فينيكس بارك",
                "label_fr": "Phoenix Park"
            },
            {
                "value": 79952,
                "label": "Arouca",
                "label_ar": "اروكا",
                "label_fr": "Arouca"
            },
            {
                "value": 79940,
                "label": "Victoria",
                "label_ar": "فيكتوريا",
                "label_fr": "Victoria"
            },
            {
                "value": 79976,
                "label": "Claxton Bay",
                "label_ar": "خليج كلاكستون",
                "label_fr": "Claxton Bay"
            },
            {
                "value": 79973,
                "label": "Carapichaima",
                "label_ar": "Carapichaima",
                "label_fr": "Carapichaima"
            },
            {
                "value": 79959,
                "label": "Barataria",
                "label_ar": "باراتاريا",
                "label_fr": "Barataria"
            },
            {
                "value": 79934,
                "label": "Arima",
                "label_ar": "أريما",
                "label_fr": "Arima"
            },
            {
                "value": 79979,
                "label": "Woodbrook",
                "label_ar": "وودبروك",
                "label_fr": "Woodbrook"
            },
            {
                "value": 79947,
                "label": "Penal",
                "label_ar": "جزائي",
                "label_fr": "Pénal"
            },
            {
                "value": 79965,
                "label": "Lopinot",
                "label_ar": "لوبينوت",
                "label_fr": "Lopinot"
            }
        ]
    },
    {
        "country": "Laos",
        "cities": [
            {
                "value": 79983,
                "label": "Vientiane",
                "label_ar": "فينتيان",
                "label_fr": "Vientiane"
            }
        ]
    },
    {
        "country": "Uruguay",
        "cities": [
            {
                "value": 79997,
                "label": "Canelones",
                "label_ar": "كانيلونس",
                "label_fr": "Canelones"
            },
            {
                "value": 79999,
                "label": "San Carlos",
                "label_ar": "سان كارلوس",
                "label_fr": "San Carlos"
            },
            {
                "value": 79984,
                "label": "Montevideo",
                "label_ar": "مونتيفيديو",
                "label_fr": "Montevideo"
            },
            {
                "value": 79994,
                "label": "Union",
                "label_ar": "اتحاد",
                "label_fr": "syndicat"
            },
            {
                "value": 79998,
                "label": "La Paz",
                "label_ar": "لاباز",
                "label_fr": "La Paz"
            },
            {
                "value": 79993,
                "label": "Mercedes",
                "label_ar": "مرسيدس",
                "label_fr": "Mercedes"
            },
            {
                "value": 79991,
                "label": "Toledo",
                "label_ar": "توليدو",
                "label_fr": "Toledo"
            },
            {
                "value": 80001,
                "label": "Punta del Este",
                "label_ar": "بونتا ديل إستي",
                "label_fr": "Punta del Este"
            },
            {
                "value": 79990,
                "label": "Tacuarembó",
                "label_ar": "تاكواريمبو",
                "label_fr": "Tacuarembó"
            },
            {
                "value": 79986,
                "label": "Barra de Carrasco",
                "label_ar": "بارا دي كاراسكو",
                "label_fr": "Barra de Carrasco"
            },
            {
                "value": 79996,
                "label": "Maldonado",
                "label_ar": "مالدونادو",
                "label_fr": "Maldonado"
            },
            {
                "value": 79995,
                "label": "Florida",
                "label_ar": "فلوريدا",
                "label_fr": "Floride"
            },
            {
                "value": 79989,
                "label": "Las Piedras",
                "label_ar": "لاس بيدراس",
                "label_fr": "Las Piedras"
            },
            {
                "value": 79987,
                "label": "Paysandú",
                "label_ar": "بايساندو",
                "label_fr": "Paysandú"
            },
            {
                "value": 80000,
                "label": "Durazno",
                "label_ar": "دورازنو",
                "label_fr": "Durazno"
            },
            {
                "value": 79988,
                "label": "Salto",
                "label_ar": "سالتو",
                "label_fr": "Salto"
            },
            {
                "value": 79992,
                "label": "Colonia del Sacramento",
                "label_ar": "كولونيا ديل ساكرامنتو",
                "label_fr": "Colonia del Sacramento"
            },
            {
                "value": 79985,
                "label": "La Floresta",
                "label_ar": "لا فلوريستا",
                "label_fr": "La Floresta"
            }
        ]
    },
    {
        "country": "Eritrea",
        "cities": [
            {
                "value": 80002,
                "label": "Asmara",
                "label_ar": "أسمرة",
                "label_fr": "Asmara"
            }
        ]
    },
    {
        "country": "Cuba",
        "cities": [
            {
                "value": 80010,
                "label": "Cienfuegos",
                "label_ar": "سيينفويغوس",
                "label_fr": "Cienfuegos"
            },
            {
                "value": 80007,
                "label": "Matanzas",
                "label_ar": "ماتانزاس",
                "label_fr": "Matanzas"
            },
            {
                "value": 80018,
                "label": "Guantánamo",
                "label_ar": "غوانتنامو",
                "label_fr": "Guantánamo"
            },
            {
                "value": 80013,
                "label": "Ciego de Ávila",
                "label_ar": "Ciego de vila",
                "label_fr": "Ciego de Ávila"
            },
            {
                "value": 80008,
                "label": "Villa",
                "label_ar": "فيلا",
                "label_fr": "Villa"
            },
            {
                "value": 80012,
                "label": "Holguín",
                "label_ar": "هولغوين",
                "label_fr": "Holguín"
            },
            {
                "value": 80017,
                "label": "Las Tunas",
                "label_ar": "لاس توناس",
                "label_fr": "Las Tunas"
            },
            {
                "value": 80006,
                "label": "La Habana",
                "label_ar": "لا هابانا",
                "label_fr": "La Habana"
            },
            {
                "value": 80019,
                "label": "Varadero",
                "label_ar": "فاراديرو",
                "label_fr": "Varadero"
            },
            {
                "value": 80011,
                "label": "Santiago de Cuba",
                "label_ar": "سانتياغو دي كوبا",
                "label_fr": "Santiago de Cuba"
            },
            {
                "value": 80014,
                "label": "Pinar del Río",
                "label_ar": "بينار ديل ريو",
                "label_fr": "Pinar del Río"
            },
            {
                "value": 80009,
                "label": "Bayamo",
                "label_ar": "بايامو",
                "label_fr": "Bayamo"
            },
            {
                "value": 80005,
                "label": "Habana",
                "label_ar": "هابانا",
                "label_fr": "Habana"
            },
            {
                "value": 80004,
                "label": "Havana",
                "label_ar": "هافانا",
                "label_fr": "La Havane"
            },
            {
                "value": 80016,
                "label": "Camagüey",
                "label_ar": "كاماغوي",
                "label_fr": "Camagüey"
            },
            {
                "value": 80015,
                "label": "Sancti Spíritus",
                "label_ar": "سانكتي سبيريتوس",
                "label_fr": "Sancti Spíritus"
            }
        ]
    },
    {
        "country": "Saint Helena",
        "cities": [
            {
                "value": 80021,
                "label": "Jamestown",
                "label_ar": "جيمستاون",
                "label_fr": "Jamestown"
            },
            {
                "value": 80020,
                "label": "Tristan Da Cunha",
                "label_ar": "تريستان دا كونها",
                "label_fr": "Tristan Da Cunha"
            }
        ]
    },
    {
        "country": "Christmas Island",
        "cities": [
            {
                "value": 80022,
                "label": "Flying Fish Cove",
                "label_ar": "تحلق فيش كوف",
                "label_fr": "Flying Fish Cove"
            }
        ]
    },
    {
        "country": "Ethiopia",
        "cities": [
            {
                "value": 80025,
                "label": "Jijiga",
                "label_ar": "جيجيجا",
                "label_fr": "Jijiga"
            },
            {
                "value": 80024,
                "label": "Awasa",
                "label_ar": "أواسا",
                "label_fr": "Awasa"
            },
            {
                "value": 80023,
                "label": "Addis Ababa",
                "label_ar": "اديس ابابا",
                "label_fr": "Addis Ababa"
            }
        ]
    }
]